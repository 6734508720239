import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { WorkInProgressService } from 'projects/admin/src/app/admin-proxy/platform-app-management/rcm/platform-management/work-in-progress';
import { WorkInProgressDTO } from 'projects/admin/src/app/admin-proxy/platform-app-management/rcm/platform-management/work-in-progress/dto';
import { defaultGuid } from 'projects/shared/src/app/enums/allenums.enum';
// import { defaultGuid } from 'projects/admin/src/app/document-perviewer/document-perviewer.component';
import { Subscription } from 'rxjs';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-scheduled-task-list',
  templateUrl: './scheduled-task-list.component.html',
  styleUrls: ['./scheduled-task-list.component.scss'],
})
export class ScheduledTaskListComponent implements OnInit {
  dataSource: MatTableDataSource<any> = new MatTableDataSource<any>([]);
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  wipData: WorkInProgressDTO;
  isLoading: boolean;
  userId: string;
  $subscription: Subscription[] = [];
  arrDisplayedColumns: string[] = [
    'options',
    'defaultDocumentId',
    'wipId',
    'defaultPatientId',
    'patientName',
    'assignedByName',
    'scheduledDate',
    'assignedDate',
    //'comments',
    'wipStatus',

  ];
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { userId: string, tableColumns: string[] } = { userId: defaultGuid, tableColumns: [] },
    private wipService: WorkInProgressService) { }

  ngOnInit(): void {
    this.userId = this.data?.userId ?? defaultGuid;
    if (this.data?.tableColumns?.length !== 0) {
      this.arrDisplayedColumns?.splice(0, 0, this.data?.tableColumns?.[0]);
      this.arrDisplayedColumns?.splice(this.arrDisplayedColumns.length - 1, 1);
    }
    this.getScheduledTaskList();
  }

  getScheduledTaskList() {
    this.isLoading = true;
    this.dataSource = new MatTableDataSource([]);
    this.dataSource.paginator = this.paginator;
    const scheduledTaskList = this.wipService
      .scheduledTaskListByUserId(this.userId)
      .subscribe(
        (response) => {
          this.isLoading = false;
          this.dataSource = new MatTableDataSource(response);
          this.dataSource.paginator = this.paginator;
        },
        (err: HttpErrorResponse) => {
          this.dataSource = new MatTableDataSource([]);
          this.isLoading = false;
          const data: HttpErrorResponse = err;
          Swal.fire({
            icon: 'info',
            text: data?.error?.error?.message,
          });
        }
      );
    this.$subscription.push(scheduledTaskList);
  }

  moveToBoard(wipId: string) {
    const wipDetail = this.wipService.get(wipId).subscribe(
      (response) => {
        this.wipData = response;


        const reScheduleDetail = this.wipService
          .update(this.wipData.id, this.wipData)
          .subscribe(
            (response) => {
              this.getScheduledTaskList();
            },
            (err) => {
              const data: HttpErrorResponse = err;
              Swal.fire({
                icon: 'info',
                text: data?.error?.error?.message,
              });
            }
          );
      },
      (err) => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      }
    );
  }
}
