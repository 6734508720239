import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatDialog, MAT_DIALOG_DATA, MatDialogConfig } from '@angular/material/dialog';
import { HttpErrorResponse } from '@angular/common/http';
import Swal from 'sweetalert2';
import { AddPracticeNotesComponent } from '../add-practice-notes/add-practice-notes.component';
import { defaultGuid } from 'projects/shared/src/app/enums/allenums.enum';
import { Observable, Subscription } from 'rxjs';
import { PracticeManagementService } from '../patient-proxy/patient/practice-management';
import { ActiveStatus } from 'projects/admin/src/app/admin-proxy/platform-app-management/rcm/platform-management/localization/enumeration/active-status.enum';
import { ToastrService } from 'ngx-toastr';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DatePipe } from '@angular/common';
@Component({
  selector: 'app-practice-management-notes',
  templateUrl: './practice-management-notes.component.html',
  styleUrls: ['./practice-management-notes.component.scss']
})
export class PracticeManagementNotesComponent implements OnInit {
  isLoading: boolean;
  ischeckPracticeId: boolean;
  dtOptions: any;
  searchForm: FormGroup;
  $subscription: any;
  @Input() sessionPracticeId: any;
  tableData$: Subscription;
  practiceNotesId: string;
  Physiciandetails: any;
  constructor(
    private fb: FormBuilder,
    private dialog: MatDialog,
    private practiceManagementService: PracticeManagementService,
    private toastr: ToastrService,
    private snackBar: MatSnackBar,
    private datepipe:DatePipe
  ) { }

  ngOnInit(): void {
    this.dtOptions = {
      responsive: true,
      paging: false,
      scrollCollapse: true,
      scrollY: '410px',
      scrollX: true,
      dom: 'Bfrtip',
      language: {
        emptyTable: 'No records',
        info: 'Total : _MAX_ records',
        infoEmpty: 'Total : _MAX_ records',
        infoFiltered: '(filtered : _TOTAL_ records)',
        loadingRecords: 'Loading...',
        zeroRecords: 'No matching records',
      },
      buttons: [
        {
          extend: 'excel',
          text:'Export',
          filename: 'Notes Info',
          exportOptions: {
            columns: ':visible:not(:first-child,:eq(0)) ',
          },
        },
      ]
    };
    this.practiceNotesId = this.sessionPracticeId;
    this.searchForm = this.fb.group({
      txtSearchdate: new FormControl(''),
    });
    if (this.practiceNotesId) {
      this.getPracticeNotes();
    } else {
      this.ischeckPracticeId = true;
    }

    this.dtOptions.buttons[0].filename =
    'Notes Info' + '-' + this.datepipe.transform(new Date(), 'MM-dd-yyyy');
  }

  //GET Reminder List In Table
  getPracticeNotes() {
    this.isLoading = true;
    this.tableData$?.unsubscribe();
    const getNotestbleData = this.practiceManagementService
      .getPracticeNotesByPracticeIdAndFilter(this.practiceNotesId, ActiveStatus.Active)
      .subscribe(
        (response) => {
          this.Physiciandetails = response;
          this.isLoading = false;
        },
        (err) => {
          this.isLoading = false;
          this.Physiciandetails = [];
          const data: HttpErrorResponse = err;
          // Swal.fire({
          //   icon: 'info',
          //   text: data?.error?.error?.message,
          // });
        }
      );
    this.tableData$ = getNotestbleData;
  }

  //To Add Open Note Modal
  openAddNotes() {
    const config: MatDialogConfig = {
      disableClose: true,
      minHeight: '30vh',
      minWidth: '35vw',
      data: {
        notesId: defaultGuid,
        practiceNoteId: this.practiceNotesId,
        addnotes: false,
        notesTitle: "Add Notes"
      },
    };
    const dialogRef = this.dialog.open(AddPracticeNotesComponent, config);

    const closeDialog = dialogRef.afterClosed().subscribe(
      () => {
        this.getPracticeNotes();
      },
      (err) => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      }
    );
    this.$subscription.push(closeDialog);
  }

  //To Update Open Note Modal
  updateNotes(prNoteId: string, txtDescription: string) {
    const config: MatDialogConfig = {
      disableClose: true,
      minHeight: '30vh',
      minWidth: '35vw',
      data: {
        notesId: prNoteId,
        practiceNoteId: this.practiceNotesId,
        addnotes: false,
        notesTitle: "Update Notes",
        textNotes: txtDescription,
      },
    };
    const dialogRef = this.dialog.open(AddPracticeNotesComponent, config);

    const closeDialog = dialogRef.afterClosed().subscribe(
      () => {
        this.getPracticeNotes();
      },
      (err) => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      }
    );
    this.$subscription.push(closeDialog);
  }

  ///Delete Practice Notes
  deletePracticeNotes(noteId: string) {
    Swal.fire({
      title: 'Are you sure you want to Delete?',
      text: 'You won\'t be able to retrieve this data!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#34c38f',
      cancelButtonColor: '#f46a6a',
      confirmButtonText: 'Yes, delete it!'
    }).then(result => {
      if (result.value) {
        this.practiceManagementService.deleteNoteByNoteId(noteId).subscribe(response => {
          this.toastr.success('Deleted Successfully', 'Success');
          this.getPracticeNotes();
        }, err => {
          const data: HttpErrorResponse = err;
          Swal.fire({
            icon: 'info',
            text: data?.error?.error?.message,
          });
        });
      }
    });
  }

  onRowDoubleClickV1(textToCopy) {
    this.copyTextToClipboard(textToCopy);
    this.showNotification('Text copied: ' + textToCopy);
  }
  
  showNotification(message: string): void {
    this.snackBar.open(message, 'Close', {
      duration: 3000, // Duration in milliseconds
      horizontalPosition: 'end', // Positioning the notification horizontally
      verticalPosition: 'bottom', // Positioning the notification vertically
    });
  }
  
  copyTextToClipboard(text: string): void {
    const textArea = document.createElement('textarea');
    textArea.value = text;
    document.body.appendChild(textArea);
    textArea.select();
    document.execCommand('Copy');
    textArea.remove();
  }

  getFormattedDate(date: string): string {
    return date ? new Date(date).toLocaleDateString('en-US') : '-';
  }

  getFormattedDateTime(date: string): string {
    if (date) {
      const formattedDate = new Date(date);
      const options: Intl.DateTimeFormatOptions = {
        year: 'numeric', month: '2-digit', day: '2-digit',
        hour: 'numeric', minute: '2-digit', second: '2-digit',
        hour12: true, // 12-hour format with AM/PM
        timeZone: 'America/Chicago'
      };
      return formattedDate.toLocaleString('en-US', options);
    } else {
      return '-';
    }
  }
  
  
  

}
