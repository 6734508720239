<mat-dialog-content class="mat-typography">
  <div class="row">
    <div>
      <h2 class="customThemeClass mr-4 ml-3">Add Workings Hours And Area</h2>
    </div>

  </div>

  <form [formGroup]="AddWorkForm">


    <table>
      <!-- sunday -->

      <!-- <div class="col-11 mr-2" style="display: flex;">
              SUN : <mat-slide-toggle (click)="sundayStatus(AddWorkForm.status)" formControlName="acceptTerms">
                {{sunday}}</mat-slide-toggle>
            </div> -->
      <div >
        <tr>
          <td>
            <div class="row">
              <div class="col-12 mr-2" style="display: flex;">
                SUN <div class="ml-2">:</div>
                <mat-slide-toggle class="ml-5" (click)="sundayStatus(AddWorkForm.status)" formControlName="acceptTerms">
                  {{sunday}}</mat-slide-toggle>
              </div>
            </div>
          </td>
          <span *ngFor="let ticket of t.controls; let i = index; last as isLast">
            <span [formGroup]="ticket" *ngIf="sundayLeaveHide">
              <td>
                <div class="row">
                  <mat-form-field *ngIf="covShow" class="col-6">
                    <mat-label>Coverage Area</mat-label>
                    <mat-select maxlength="40" (selectionChange)="onChange($event.value)" formControlName="drpCoverage">
                      <mat-option *ngFor="let coverage of drpCoverage" [value]="coverage.coverageAreaType">
                        {{ coverage.coverageAreaType }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>

                  <mat-form-field *ngIf="workShow" class="col-6">
                    <mat-label>Work Option</mat-label>
                    <mat-select multiple maxlength="40" (selectionChange)="onChangeWork($event.value)" formControlName="drpWorkOptn">
                      <mat-option *ngFor="let work of drpWorkOptn" [value]="work.workingWeek">
                        {{ work.workingWeek }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
              </td>
              <td>
              
                  <mat-form-field *ngIf="atShow" class="col-6">
                    <mat-label>Start At</mat-label>
                      <mat-select maxlength="40"  formControlName="drpStartAt">
                        <mat-option *ngFor="let at of drpStartAt" [value]="at">
                          {{ at.startTime }}
                        </mat-option>
                      </mat-select>
                  </mat-form-field>


                  <mat-form-field *ngIf="atShow" class="col-6">
                    <mat-label>Start End</mat-label>
                    <mat-select  formControlName="drpStartEnd" >
                      <mat-option *ngFor="let data of AddWorkForm?.get('sundayForm').value[i].drpStartAt.endTimes"  [value]="data.endTime">
                        {{data.endTime}}</mat-option>
                    </mat-select>
                  </mat-form-field>
                
                  
                <!-- </div> -->
              </td>

              <td>
                <div class="row">

                  <mat-form-field  class="col-6">
                    <mat-label>Select Coverage</mat-label>
                    <mat-select  formControlName="drpSelCov">
                    </mat-select>
                  </mat-form-field>
                  <div  class="col-6" style="margin-top: 20px;">
                    <span class="customThemeClass" style="cursor: pointer;" *ngIf="isLast" (click)="addAccordion()"
                      ><b>Add Sun More </b></span>

                  </div>
                 
                </div>
              </td>

            </span>
          </span>

        </tr>
      </div>
      <!-- Monday -->
      <div>
        <tr>

          <td>
            <div class="row">
              <div class="col-12 mr-2" style="display: flex;">
                MON <div class="ml-1">:</div>
                <mat-slide-toggle class="ml-5" (click)="MondayStatus(AddWorkForm.status)"
                  formControlName="mondayWorkStatus">
                  {{Monday}}</mat-slide-toggle>
              </div>
            </div>
          </td>
          <span *ngFor="let ticket of b.controls; let i = index;  last as isLast">
            <span [formGroup]="ticket" *ngIf="mondayLeaveHide">
              <td>

                <div class="row">
                  <mat-form-field class="col-6">
                    <mat-label>Coverage Area</mat-label>
                    <mat-select maxlength="40" (selectionChange)="onChange($event.value)" formControlName="drpMonCov">
                      <mat-option *ngFor="let coverage of drpCoverage" [value]="coverage.shortCodeId">
                        {{ coverage.coverageAreaType }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>

                  <mat-form-field class="col-6">
                    <mat-label>Work Option</mat-label>
                    <mat-select multiple maxlength="40" (selectionChange)="onChangeWork($event.value)" formControlName="drpMonWork">
                      <mat-option *ngFor="let work of drpWorkOptn" [value]="work.shortCodeId">
                        {{ work.workingWeek }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>


                </div>
              </td>
              <td>
                <div class="row">
                  <mat-form-field class="col-6">
                    <mat-label>Start At</mat-label>
                    <mat-select maxlength="40"  formControlName="drpMonStartAt">
                      <mat-option *ngFor="let at of drpStartAt" [value]="at">
                      {{ at.startTime }}
                    </mat-option>
                    </mat-select>
                  </mat-form-field>
                  <mat-form-field class="col-6">
                    <mat-label>Start End</mat-label>
                    <mat-select (selectionChange)="onEndChanges($event.value)" formControlName="drpMonStartEnd" >
                      <mat-option *ngFor="let data of AddWorkForm?.get('mondayForm').value[i].drpMonStartAt.endTimes"  [value]="data.endTimeShortCodeId">
                        {{data.endTime}}</mat-option>
                    </mat-select>
                  </mat-form-field>

                </div>
              </td>
              <td>
                <div class="row">

                  <mat-form-field class="col-6">
                    <mat-label>Select Coverage</mat-label>
                    <mat-select formControlName="drpMonSelCov" #singleSelect>
                    </mat-select>
                  </mat-form-field>
                  <div class="col-6" style="margin-top: 20px;">
                    <span class="customThemeClass" style="cursor: pointer;"  *ngIf="isLast" (click)="addMonday()"
                      ><b>Add Mon More </b></span>

                  </div>
                </div>
              </td>
            </span>
          </span>
        </tr>
      </div>
      <!-- Tuesday -->
      <div>
        <tr>

          <td>
            <div class="row">
              <div class="col-11 mr-2" style="display: flex;">
                TUE <div class="ml-2 margin-right">:</div>
                <mat-slide-toggle class="ml-5" (click)="tuesdayStatus(AddWorkForm.status)"
                  formControlName="tuesdayWorkStatus">
                  {{Tuesday}}</mat-slide-toggle>
              </div>
            </div>
          </td>
          <span *ngFor="let ticket of d.controls; let i = index; last as isLast">
            <span [formGroup]="ticket" *ngIf="tuesdayLeaveHide">
              <td>
                <div class="row">
                  <mat-form-field class="col-6">
                    <mat-label>Coverage Area</mat-label>
                  
                    <mat-select maxlength="40" formControlName="drpTueCov">
                      <mat-option *ngFor="let coverage of drpCoverage" [value]="coverage.shortCodeId">
                        {{ coverage.coverageAreaType }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                  <mat-form-field class="col-6">
                    <mat-label>Work Option</mat-label>
                   
                    <mat-select multiple maxlength="40" (selectionChange)="onChangeWork($event.value)" formControlName="drpTueWork">
                      <mat-option *ngFor="let work of drpWorkOptn" [value]="work.shortCodeId">
                        {{ work.workingWeek }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>


                </div>
              </td>
              <td>
                <div class="row">

                  <mat-form-field class="col-6">
                    <mat-label>Start At</mat-label>
                    
                    <mat-select maxlength="40"  formControlName="drpTueStartAt">
                      <mat-option *ngFor="let at of drpStartAt" [value]="at">
                        {{ at.startTime }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                  <mat-form-field class="col-6">
                    <mat-label>Start End</mat-label>
                
                    <mat-select (selectionChange)="onEndChanges($event.value)" formControlName="drpTueStartEnd" >
                      <mat-option *ngFor="let data of AddWorkForm?.get('tuesForm').value[i].drpTueStartAt.endTimes"  [value]="data.endTimeShortCodeId">
                        {{data.endTime}}</mat-option>
                    </mat-select>
                  </mat-form-field>

                </div>
              </td>
              <td>
                <div class="row">

                  <mat-form-field class="col-6">
                    <mat-label>Select Coverage</mat-label>
                    <mat-select formControlName="drpTueSelCov" #singleSelect>
                    </mat-select>
                  </mat-form-field>
                  <div class="col-6" style="margin-top: 20px;">
                    <span class="customThemeClass" style="cursor: pointer;" *ngIf="isLast" (click)="addTuesday()"
                      ><b>Add Tue More </b></span>

                  </div>
                </div>
              </td>
            </span>
          </span>
        </tr>
      </div>
      <!-- Wednesday -->
      <div>
        <tr>

          <td>
            <div class="row">
              <div class="col-12 mr-2 " style="display: flex;">
                WED <div class="ml-1 mar-right">:</div>
                <mat-slide-toggle class=" ml-5" (click)="wednesStatus(AddWorkForm.status)"
                  formControlName="wednesWorkStatus">
                  {{Wednesday}}</mat-slide-toggle>
              </div>
            </div>
          </td>
          <span *ngFor="let ticket of h.controls; let i = index;last as isLast">
            <span [formGroup]="ticket" *ngIf="wednesdayLeaveHide">
              <td>
                <div class="row">
                  <mat-form-field class="col-6">
                    <mat-label>Coverage Area</mat-label>
                    
                    <mat-select maxlength="40" formControlName="drpWedCov">
                      <mat-option *ngFor="let coverage of drpCoverage" [value]="coverage.shortCodeId">
                        {{ coverage.coverageAreaType }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                  <mat-form-field class="col-6">
                    <mat-label>Work Option</mat-label>
                   
                    <mat-select multiple maxlength="40" (selectionChange)="onChangeWork($event.value)" formControlName="drpWedWork">
                      <mat-option *ngFor="let work of drpWorkOptn" [value]="work.shortCodeId">
                        {{ work.workingWeek }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>


                </div>
              </td>
              <td>
                <div class="row">

                  <mat-form-field class="col-6">
                    <mat-label>Start At</mat-label>
                   
                    <mat-select maxlength="40"  formControlName="drpWedStartAt">
                      <mat-option *ngFor="let at of drpStartAt" [value]="at">
                        {{ at.startTime }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                  <mat-form-field class="col-6">
                    <mat-label>Start End</mat-label>
                    
                    <mat-select (selectionChange)="onEndChanges($event.value)" formControlName="drpWedStartEnd" >
                      <mat-option *ngFor="let data of AddWorkForm?.get('wdnesdayForm').value[i].drpWedStartAt.endTimes"  [value]="data.endTimeShortCodeId">
                        {{data.endTime}}</mat-option>
                    </mat-select>
                  </mat-form-field>

                </div>
              </td>
              <td>
                <div class="row">

                  <mat-form-field class="col-6">
                    <mat-label>Select Coverage</mat-label>
                    <mat-select formControlName="drpWedSelCov" #singleSelect>
                    </mat-select>
                  </mat-form-field>
                  <div class="col-6" style="margin-top: 20px;">
                    <span class="customThemeClass" style="cursor: pointer;" *ngIf="isLast" (click)="addWednesday()"
                      ><b>Add Wed More </b></span>

                  </div>
                </div>
              </td>
            </span>
          </span>
        </tr>
      </div>
      <!-- Thursday -->
      <div >
        <tr>

          <td>
            <div class="row">
              <div class="col-11 mr-2" style="display: flex;">
                THU <div class="ml-1 ma-right">:</div>
                <mat-slide-toggle class="ml-5" (click)="thursStatus(AddWorkForm.status)"
                  formControlName="thursWorkStatus">
                  {{Thursday}}</mat-slide-toggle>
              </div>
            </div>
          </td>
          <span *ngFor="let ticket of j.controls; let i = index;last as isLast">
            <span [formGroup]="ticket" *ngIf="thursdayLeaveHide">
              <td>
                <div class="row">
                  <mat-form-field class="col-6">
                    <mat-label>Coverage Area</mat-label>
                    
                    <mat-select maxlength="40" formControlName="drpThursCov">
                      <mat-option *ngFor="let coverage of drpCoverage" [value]="coverage.coverageAreaType">
                        {{ coverage.coverageAreaType }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                  <mat-form-field class="col-6">
                    <mat-label>Work Option</mat-label>
                    
                    <mat-select multiple maxlength="40" (selectionChange)="onChangeWork($event.value)" formControlName="drpThursWork">
                      <mat-option *ngFor="let work of drpWorkOptn" [value]="work.workingWeek">
                        {{ work.workingWeek }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>


                </div>
              </td>
              <td>
                <div class="row">

                  <mat-form-field class="col-6">
                    <mat-label>Start At</mat-label>
                    
                    <mat-select maxlength="40"  formControlName="drpThursStartAt">
                      <mat-option *ngFor="let at of drpStartAt" [value]="at">
                        {{ at.startTime }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                  <mat-form-field class="col-6">
                    <mat-label>Start End</mat-label>
                   
                    <mat-select (selectionChange)="onEndChanges($event.value)" formControlName="drpThursStartEnd" >
                      <mat-option *ngFor="let data of AddWorkForm?.get('thursdayForm').value[i].drpThursStartAt.endTimes"  [value]="data.endTime">
                        {{data.endTime}}</mat-option>
                    </mat-select>
                  </mat-form-field>

                </div>
              </td>
              <td>
                <div class="row">

                  <mat-form-field class="col-6">
                    <mat-label>Select Coverage</mat-label>
                    <mat-select formControlName="drpThursSelCov" #singleSelect>
                    </mat-select>
                  </mat-form-field>
                  <div class="col-6" style="margin-top: 20px;">
                    <span class="customThemeClass" style="cursor: pointer;" (click)="addThursday()"
                      ><b>Add THU More </b></span>

                  </div>
                </div>
              </td>
            </span>
          </span>
        </tr>
      </div>
      <!-- Friday -->
      <div>
        <tr>

          <td>
            <div class="row">
              <div class="col-11 mr-2" style="display: flex;">
                FRI <div class="ml-1 m-r">:</div>
                <mat-slide-toggle class="ml-5" (click)="fridayStatus(AddWorkForm.status)"
                  formControlName="fridayWorkStatus">
                  {{Friday}}</mat-slide-toggle>
              </div>
            </div>
          </td>
          <span *ngFor="let ticket of l.controls; let i = index;last as isLast">
            <span [formGroup]="ticket" *ngIf="fridayLeaveHide">
              <td>
                <div class="row">
                  <mat-form-field class="col-6">
                    <mat-label>Coverage Area</mat-label>
                    
                    <mat-select maxlength="40" formControlName="drpFriCov">
                      <mat-option *ngFor="let coverage of drpCoverage" [value]="coverage.shortCodeId">
                        {{ coverage.coverageAreaType }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                  <mat-form-field class="col-6">
                    <mat-label>Work Option</mat-label>
                    
                    <mat-select multiple maxlength="40" (selectionChange)="onChangeWork($event.value)" formControlName="drpFriWork">
                      <mat-option *ngFor="let work of drpWorkOptn" [value]="work.shortCodeId">
                        {{ work.workingWeek }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>


                </div>
              </td>
              <td>
                <div class="row">

                  <mat-form-field class="col-6">
                    <mat-label>Start At</mat-label>
                    
                    <mat-select maxlength="40"  formControlName="drpFriStartAt">
                      <mat-option *ngFor="let at of drpStartAt" [value]="at">
                        {{ at.startTime }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                  <mat-form-field class="col-6">
                    <mat-label>Start End</mat-label>
                   
                    <mat-select (selectionChange)="onEndChanges($event.value)" formControlName="drpFriStartEnd" >
                      <mat-option *ngFor="let data of AddWorkForm?.get('fridayForm').value[i].drpFriStartAt.endTimes"  [value]="data.endTimeShortCodeId">
                        {{data.endTime}}</mat-option>
                    </mat-select>
                  </mat-form-field>

                </div>
              </td>
              <td>
                <div class="row">

                  <mat-form-field class="col-6">
                    <mat-label>Select Coverage</mat-label>
                    <mat-select formControlName="drpFriSelCov" #singleSelect>
                    </mat-select>
                  </mat-form-field>
                  <div class="col-6" style="margin-top: 20px;">
                    <span class="customThemeClass" style="cursor: pointer;" *ngIf="isLast" (click)="addFriday()"
                      ><b>Add Fri More </b></span>

                  </div>
                </div>
              </td>
            </span>
          </span>
        </tr>
      </div>

      <!-- Saturday -->
      <div >
        <tr>

          <td>
            <div class="row">
              <div class="col-11 mr-2" style="display: flex;">
                SAT <div class="ml-1 m-rt">:</div>
                <mat-slide-toggle class="ml-5" (click)="saturdayStatus(AddWorkForm.status)"
                  formControlName="saturdayWorkStatus">
                  {{Saturday}}</mat-slide-toggle>
              </div>
            </div>
          </td>
          <span *ngFor="let ticket of n.controls; let i = index;last as isLast">
            <span [formGroup]="ticket" *ngIf="saturdayLeaveHide">
              <td>
                <div class="row">
                  <mat-form-field class="col-6">
                    <mat-label>Coverage Area</mat-label>
                    
                    <mat-select maxlength="40" formControlName="drpSatCov">
                      <mat-option *ngFor="let coverage of drpCoverage" [value]="coverage.shortCodeId">
                        {{ coverage.coverageAreaType }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                  <mat-form-field class="col-6">
                    <mat-label>Work Option</mat-label>
                    
                    <mat-select maxlength="40" (selectionChange)="onChangeWork($event.value)" formControlName="drpSatWork">
                      <mat-option *ngFor="let work of drpWorkOptn" [value]="work.shortCodeId">
                        {{ work.workingWeek }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>


                </div>
              </td>
              <td>
                <div class="row">

                  <mat-form-field class="col-6">
                    <mat-label>Start At</mat-label>
                   
                    <mat-select maxlength="40"  formControlName="drpSatStartAt">
                      <mat-option *ngFor="let at of drpStartAt" [value]="at">
                        {{ at.startTime }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                  <mat-form-field class="col-6">
                    <mat-label>Start End</mat-label>
                   
                    <mat-select (selectionChange)="onEndChanges($event.value)" formControlName="drpSatStartEnd" >
                      <mat-option *ngFor="let data of AddWorkForm?.get('satdayForm')?.value[i]?.drpSatStartAt?.endTimes"  [value]="data.endTimeShortCodeId">
                        {{data.endTime}}</mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
              </td>
              <td>
                <div class="row">

                  <mat-form-field class="col-6">
                    <mat-label>Select Coverage</mat-label>
                    <mat-select formControlName="drpSatSelCov" #singleSelect>
                    </mat-select>
                  </mat-form-field>
                  <div class="col-6" style="margin-top: 20px;">
                    <span class="customThemeClass" style="cursor: pointer;" *ngIf="isLast"(click)="addSatday()"
                      ><b>Add Sat More </b></span>

                  </div>
                </div>
              </td>
            </span>
          </span>
        </tr>
      </div>

    </table>
    <br>
    <div class="row">
    <div class="col-2 mt-3"> <b>Preferred Lunch Break</b></div>
          <mat-form-field class="col-4">
            <mat-label>From</mat-label>
            <mat-select (selectionChange)="LunchChanges($event.value)" formControlName="drpFromLunch" #singleSelect>
                <mat-option *ngFor="let data of drpFromLunch"  [value]="data.shortCodeId">
                  {{data.lunchBreakHoursStart}}</mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field class="col-4">
            <mat-label>To</mat-label>
            <input autocomplete="new-txtToLunch" matInput maxlength="40" formControlName="txtToLunch"
                placeholder="To" type="text" />
          </mat-form-field>
</div>
  </form>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button class="buttonColor">Save</button>
  <button class="buttonCancelColor" mat-button mat-dialog-close>Close</button>
</mat-dialog-actions>
