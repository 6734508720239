import { MasterRulesDTO } from '../admin-proxy/platform-app-management/rcm/platform-management/tenants/dto/models';
import { ProductNotificationDTO } from '../warehouse-proxy/rcm/warehouse-management/warehouse/dto/models';


//! Product Notification Class For Instance Generator
export class ProductNotification {
  id: number;
  titleMsg: string;
  ProductNotification: ProductNotificationDTO | MasterRulesDTO;
  timeout: number;
  constructor(id: number, titleMsg: string, ProductNotification: ProductNotificationDTO | MasterRulesDTO, timeout: number) {
    this.id = id;
    this.titleMsg = titleMsg;
    this.ProductNotification = ProductNotification;
    this.timeout = timeout;
  }
}
