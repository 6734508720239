import { CommonService } from './../../../../shared/src/app/services/common.service';
import { Component, OnInit } from '@angular/core';
import { MmOrderService } from '../order-proxy/order-management/order-optimization/mm-order.service';
import { Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { defaultGuid } from 'projects/shared/src/app/enums/allenums.enum';
import {
  clsSaleOrderDTOforBilling,
  PrintTicketDetails,
  SaleOrderItemforBilingDTO,
} from '../order-proxy/order-management/order-optimization/dto';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { PatientManualVerificationTabComponent } from 'projects/patient/src/app/patient-manual-verification-tab/patient-manual-verification-tab.component';
import { PatientAuthSaveUpdateFormComponent } from 'projects/patient/src/app/patient-auth-save-update-form/patient-auth-save-update-form.component';
import { LoaderService } from 'projects/shared/src/app/services/loader-service';
import { ProductHcpcCodeService } from 'projects/inventory/src/app/item-proxy/item-management/optimization/product-hcpc-code.service';
import { Title } from '@angular/platform-browser';
import { CommonDoumentViewerForMedicareComponent } from 'projects/shared/src/app/components/common-doument-viewer-for-medicare/common-doument-viewer-for-medicare.component';
import { PatientCommonNotesComponent } from 'projects/shared/src/app/components/patient-common-notes/patient-common-notes.component';
import { HttpErrorResponse } from '@angular/common/http';
import Swal from 'sweetalert2';
import { ViewPatientLedgerComponent } from 'projects/admin/src/app/view-patient-ledger/view-patient-ledger.component';
import { ShippingListService } from '../order-proxy/order-management/order-optimization/shipping-list.service';
import { AddNoteTabComponent } from 'projects/patient/src/app/add-note-tab/add-note-tab.component';
import { MachineSettingsModalComponent } from '../machine-settings-modal/machine-settings-modal.component';
import { RentalMonthlyCalculationComponent } from '../rental-monthly-calculation/rental-monthly-calculation.component';
import { OrderHistoryModalComponent } from '../order-history-modal/order-history-modal.component';

@Component({
  selector: 'app-sale-order-view',
  templateUrl: './sale-order-view.component.html',
  styleUrls: ['./sale-order-view.component.scss'],
})
export class SaleOrderViewComponent implements OnInit {
  step:boolean = false;
  tableBody = productDataArray;
  orderId: string;
  patientId: string;
  isLoaded: boolean;
  isDataLoaded: boolean;
  spinnerEnabled: boolean;
  rentalAllowable = 0;
  monthlyAmounts = 0;
  nextTwelveMonths = [];
  defaultGuid = defaultGuid;
  ltHCPCS: string = '';
  ltRXDocument: number[] = [];
  shippingFee:string;
  printTicketData: PrintTicketDetails;
  orderDetails: clsSaleOrderDTOforBilling;
  orderItemDetails: SaleOrderItemforBilingDTO[] = [];
  subscription$: Subscription[] = [];
  dtItemTableOptions: any = {
    responsive: true,
    paging: false,
    scrollX: true,
    searching: false,
    order: [],
    buttons: [
      {
        extend: 'excel',
        text: 'Export', // Change the text to 'Export'
        filename: 'Order Items',
      },
    ],
    language: {
      emptyTable: 'No records',
      info: 'Total : _MAX_ records',
      infoEmpty: 'Total : _MAX_ records',
      infoFiltered: '(filtered : _TOTAL_ records)',
      loadingRecords: 'Loading...',
      zeroRecords: 'No matching records',
    },
  };

  constructor(
    private orderService: MmOrderService,
    private activatedRoute: ActivatedRoute,
    private dialog: MatDialog,
    private loaderService: LoaderService,
    private commonService: CommonService,
    private hcpcCodeService: ProductHcpcCodeService,
    private title: Title,
    private shippingListService: ShippingListService
  ) {
    this.loaderService.getLoaderState().subscribe((isPageLoaded) => {
      this.isDataLoaded = isPageLoaded;
    });
  }

  ngOnInit() {
    // this.getOrderDetails(orderId);
    this.title.setTitle('Order View | Qsecure');
    const activateRoute = this.activatedRoute.paramMap.subscribe(
      (response) => {
        this.orderId = response?.get('orderId') ?? defaultGuid;
        this.patientId = response?.get('patientId') ?? defaultGuid;
      },
      (err) => {}
    );
    this.subscription$.push(activateRoute);

    this.getOrderDetails();
  }

  getOrderDetails() {
    this.isLoaded = false;
    this.isDataLoaded = true;
    const orderDetails = this.orderService
      .getBillingOrderDetailsforOrderIdByGOrderId(this.orderId)
      .subscribe(
        (response) => {
          if (response != null) {
            response.orderDate = this.commonService.getFormattedDateZone(
              response.orderDate
            );
            response.rxDate = this.commonService.getFormattedDateZone(
              response.rxDate
            );
            response.dateOfBirth = this.commonService.getFormattedDateZone(
              response.dateOfBirth
            );
            response.dos = this.commonService.getFormattedDateZone(
              response.dos
            );
            response.createdDate = this.commonService.getFormattedDateZone(
              response.ordercreateddate
            );
            response.ordercreateddate =
              this.commonService.getFormattedDateTimeZone(
                response.ordercreateddate
              );
            response.modifiedDate = this.commonService.getFormattedDateTimeZone(
              response.modifiedDate
            );

            response.verificationDate = this.commonService.getFormattedDateTimeZone(
              response.verificationDate
            );
            response.secondaryVerificationVerifiedOn =
              this.commonService.getFormattedDateTimeZone(
                response.secondaryVerificationVerifiedOn
              );
            response.scheduleDate = this.commonService.getFormattedDateTimeZone(
              response.scheduleDate
            );
          }
          this.orderDetails = response;
          this.orderItemDetails = this.orderDetails?.ltOrderItemDetails ?? [];
          this.shippingFee = response?.shippingFee

          this.isLoaded = true;
          this.isDataLoaded = false;
        },
        (err) => {
          this.isLoaded = true;
          this.isDataLoaded = false;
        }
      );

    this.subscription$.push(orderDetails);
  }

  showHCPCS(productId: string) {
    this.spinnerEnabled = true;
    this.ltHCPCS = '';
    const hcpcDetails = this.hcpcCodeService
      .getProductHCPCCodeListByID(productId)
      .subscribe(
        (response) => {
          if (response.length > 1) {
            const hcpcCodes = response
              ?.map((item) => item.hcpcCode)
              .filter(Boolean); // Filter out null or undefined values
            this.ltHCPCS = hcpcCodes.join(', ');
          }
          this.spinnerEnabled = false;
        },
        (err) => {
          this.ltHCPCS = '';
          this.spinnerEnabled = false;
        }
      );
    this.subscription$.push(hcpcDetails);
  }

  openVerification(policyId: string, verificationId: string) {
    const dialogRef = this.dialog.open(PatientManualVerificationTabComponent, {
      disableClose: true,
      data: {
        patientId: this.patientId,
        verificationId: verificationId,
        policyId: policyId,
        savehidden: true,
        isViewVerification: true,
        documentId:this.orderDetails?.inboundId
      },
    });
    const closeDialog = dialogRef.afterClosed().subscribe((result) => {
      if (result == 'Saved') {
        this.dialog.closeAll();
      }

      result !== '' &&
        result !== null &&
        result !== undefined &&
        this.dialog.closeAll();
    });
    this.subscription$.push(closeDialog);
  }

  viewAuthorization(authorizationId: string, priVerificationId: string) {
    const dialogRef = this.dialog.open(PatientAuthSaveUpdateFormComponent);
    const componentInstance = dialogRef.componentInstance;
    componentInstance.patientId = this.patientId;
    componentInstance.verificationId = priVerificationId;
    componentInstance.docId = defaultGuid;
    componentInstance.faxId = '';
    componentInstance.isView = false;
    componentInstance.isSupplies = true;
    componentInstance.authorizationId = authorizationId;
    componentInstance.chartId = this.orderDetails?.chartId;
  }
  viewPatientDocument() {
    const url =
      '/#/view_document_tiff/' + [this.orderDetails?.inboundId] + '/' + true;
    window.open(url, '_blank');
  }
  /* #region Machine Settings */

  openMachineSettings(
    productId: string,
    categoryName: string,
    machineSettings: any
  ) {
    // MachineSettingsModalComponent

    const config: MatDialogConfig = {
      disableClose: true,
      data: {
        productId: productId,
        machineSetting: machineSettings,
        categoryName: categoryName,
        isSettingsView: true,
      },
    };
    const dialogRef = this.dialog.open(MachineSettingsModalComponent, config);
  }
  /* #endregion */

  /* #region View RX Document Selection */
  viewRXDocument() {
    const config: MatDialogConfig = {
      width: '100vw',
      height: '100vh',
      maxWidth: '100vw',
      maxHeight: '100vh',
      panelClass: 'fullscreen-dialog-panel',
      disableClose: true,
      data: {
        previewList: this.orderDetails?.rxDocuments ?? [],
        docId: this.orderDetails?.inboundId ?? defaultGuid,
        isMedicare: false,
        isView: false,
      },
    };
    const dialogRef = this.dialog.open(
      CommonDoumentViewerForMedicareComponent,
      config
    );

    const closeDialog = dialogRef
      .afterClosed()
      .subscribe((selectedFileDetails: any) => {
        this.ltRXDocument = selectedFileDetails;
      });
    this.subscription$.push(closeDialog);
  }
  /* #endregion */

  //#region Rental
  openRentalDetails() {
    this.generateNextTwelveMonths();
    const dialogRef = this.dialog.open(RentalMonthlyCalculationComponent, {
      disableClose: true,
      // maxWidth: '75%',
      minWidth: '52vw',
      minHeight: '50%',
      data: {
        balanceAmount: this.orderDetails?.rentalAllowable,
        monthlyAmount: this.monthlyAmounts,
        totalDate: this.orderDetails.rentalDetails,
        orderId: this.orderId,
        orderDate: this.orderDetails?.orderDate,
        months: this.orderDetails?.rentalMonth,
      },
    });
  }

  generateNextTwelveMonths() {
    const startDateString = String(this.orderDetails?.orderDate);
    const startDate = new Date(startDateString);
    this.monthlyAmounts =
      this.orderDetails?.estAmountDue / this.orderDetails?.rentalMonth;

    if (!isNaN(startDate.getTime())) {
      // Check if the conversion to Date was successful
      for (let i = 0; i < this.orderDetails?.rentalMonth; i++) {
        const nextMonth = new Date(startDate);
        nextMonth.setMonth(startDate.getMonth() + i);

        const dataObject = {
          date: nextMonth,
          balanceAmount: this.orderDetails?.estAmountDue,
          monthlyAmount: this.monthlyAmounts.toFixed(2),
        };

        this.nextTwelveMonths.push(dataObject);
      }
    } else {
    }
  }

  //#endregion

  //#region Order History
  //To Open the Order History Popup
  getOrderHistory() {
    const config: MatDialogConfig = {
      height: '70vh',
      width: '40vw',
      disableClose: true,
      data: {
        orderId: this.orderId,
        mmOrderId: this.orderDetails?.orderNo,
        patientName: this.orderDetails?.patientFullName,
        chartId: this.orderDetails?.chartId,
      },
    };
    const dialogRef = this.dialog.open(OrderHistoryModalComponent, config);
  }
  //#endregion

  //View Patient Note
  openViewNotesPopup() {
    const config: MatDialogConfig = {
      disableClose: true,
      width: '70vw',
      maxWidth: '70vw',
      height: '40vw',
      maxHeight: '40vw',
      data: {
        patientId: this.patientId,
        pageType: 'Patient Note',
        isAddVisible: false,
        defaultFaxId: this.orderDetails?.defaultFaxId ?? '',
        docId: this.orderDetails?.inboundId ?? defaultGuid,
      },
    };
    const dialogRef = this.dialog.open(PatientCommonNotesComponent, config);

    dialogRef.afterClosed().subscribe(
      () => {},
      (err: HttpErrorResponse) => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      }
    );
  }

  //To View the Patient Ledger
  viewPatientLedgerPopup() {
    this.dialog.open(ViewPatientLedgerComponent, {
      width: '100vw',
      height: '100vh',
      maxWidth: '100vw',
      maxHeight: '100vh',
      panelClass: 'fullscreen-dialog-panel',
      disableClose: true,
      data: {
        patientId: this.patientId,
        chartNo: this.orderDetails?.chartId ?? '',
      },
    });
    this.dialog.afterAllClosed.subscribe((resposne) => {});
  }

  //Method to call the Print Ticket Data Details
  getPrintTicketDetails() {
    const shippingTicketDetails = this.shippingListService
      .orderTicketDetailsV1ByOrderId(this.orderId)
      .subscribe(
        (response) => {
          this.printTicketData = response ?? null;
          this.commonService.getPrintTicket(this.printTicketData);
        },
        (err) => {}
      );

    this.subscription$.push(shippingTicketDetails);
  }

  openAddNotesPopUp() {
    const config: MatDialogConfig = {
      disableClose: true,
      minHeight: '30vh',
      minWidth: '50vw',
      data: {
        noteType: 'patient', // 'Order Note',
        notesId: '00000000-0000-0000-0000-000000000000',
        patientId: this.patientId,
        defaultFaxId: this.orderDetails?.defaultFaxId ?? '',
        isCallNote: false,
        isPending: false,
        chartNo: this.orderDetails?.chartId ?? '',
        docId: this.orderDetails?.inboundId ?? defaultGuid,
        inboundStatusShortCodeId: defaultGuid,
        addnotes: false,
      },
    };
    const dialogRef = this.dialog.open(AddNoteTabComponent, config);
  }
  isEmpty(value: any): boolean {
    return (
      value === null ||
      value === undefined ||
      value === '' ||
      value === defaultGuid
    );
  }
}
const productDataArray: any[] = [
  {
    productCode: 'P001',
    productName: 'Wheelchair',
    manufacturer: 'Mobility Corp',
    hcpcs: 'E1234',
    qty: 10,
  },
  {
    productCode: 'P002',
    productName: 'Oxygen Cylinder',
    manufacturer: 'LifeCare Inc.',
    hcpcs: 'E0431',
    qty: 15,
  },
  {
    productCode: 'P003',
    productName: 'Walker',
    manufacturer: 'WalkEase Ltd.',
    hcpcs: 'E0143',
    qty: 20,
  },
  {
    productCode: 'P004',
    productName: 'Hospital Bed',
    manufacturer: 'CareCo',
    hcpcs: 'E0250',
    qty: 5,
  },
  {
    productCode: 'P005',
    productName: 'Crutches',
    manufacturer: 'HealthLine',
    hcpcs: 'E0114',
    qty: 12,
  },
  // Add more objects for additional data
];
