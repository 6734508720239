import { DatePipe } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CalendarService } from 'projects/on-time-schedules/src/app/onTime-proxy/rcm/on-time-management/on-time-schedule/calendar.service';

@Component({
  selector: 'app-calendar-view',
  templateUrl: './calendar-view.component.html',
  styleUrls: ['./calendar-view.component.scss'],
  providers:[DatePipe]
})
export class CalendarViewComponent implements OnInit {
  userName: string = '';
  roleName: string = '';
  calendarId: string = '';
  calendarDetailsArray : any[]=[];
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { calendarId: string },
    private calendarService: CalendarService,
    private datepipe: DatePipe

  ) { }

  ngOnInit(): void {
    this.calendarId = this.data?.calendarId;
    this.calendarId != null && this.calendarId != undefined && this.calendarId != "" &&
    this.getCalendarById(this.calendarId);
  }

  //Get calendar datas by id
  getCalendarById(calendarId: string){
    this.calendarService.get(calendarId).subscribe(response => {
      this.calendarDetailsArray = [];
      response && response?.calendarDetails != null && response?.calendarDetails.length !== 0 &&
      response?.calendarDetails.forEach((element, tableIndex) => {
        this.calendarDetailsArray.push({
          id: response?.id,
          userName: response?.assignedToName,
          userId: response?.userId,
          roleId: response?.roleId,
          roleName: response?.roleName,
          createdDateTime: element?.createdDate === null ? "" : this.datepipe.transform(element?.createdDate, 'MMMM d, y, h:mm:ss a') ?? "",
          calendarCode: element?.calendarCode,
          title: element?.title,
          description: element?.description == null  || element?.description == undefined || element?.description == "" ? "None" : element?.description,
          scheduledDate : element?.fromTime === null ? "" : this.datepipe.transform(element?.fromTime, 'MMMM d, y') ?? "",
          fromTime: element?.fromTime === null || undefined ? "" : this.datepipe.transform(element?.fromTime, 'h:mm a') ?? "",
          toTime: element?.toTime === null || undefined  ? "" : this.datepipe.transform(element?.toTime, 'h:mm a'),
          index: (tableIndex % 2) == 0 ? 0 : 1,
          status: element?.status
        });
        this.userName = response?.userName,
        this.roleName = response?.roleName
      });
    });
  }
}
