<div class="container-fluid">
    <div class="row bg">
        <div class="col-12">
            <div class="d-flex align-items-center justify-content-between">
                <h3 class="mb-0 font-size-18"><b>Calendar Lists</b></h3>
                <div class="page-title-box page-title-right pt-1 pb-0" style="font-size: initial" style="top:3px">
                    <ol class="breadcrumbs m-0">
                        <li class="ml-2 mt-2 breadcrumb-item">Admin</li>
                        <li class="mt-2 breadcrumb-item muted">Calendar Lists</li>
                    </ol>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="col-12">
    <div class="card">
        <div class="card-body">
            <form [formGroup]="calendarSearchForm">
                <div class="row">
                    <mat-form-field class="col-6">
                        <mat-label>Select Role</mat-label>
                        <mat-select #roleSelect multiple formControlName="txtRoleName" [(value)]="roleName">
                            <mat-option>
                                <ngx-mat-select-search placeholderLabel="Search Roles"
                                    noEntriesFoundLabel="No Records found" formControlName="txtRoleNameSearch">
                                </ngx-mat-select-search>
                            </mat-option>
                            <mat-option #allRolesSelected [value]="0" (click)="toggleAllRolesSelection()">Select All
                                Roles</mat-option>
                            <mat-option *ngFor="let roles of filterRoles | async" [value]="roles.id">
                                {{ roles.name }}
                            </mat-option>
                        </mat-select>
                        <button mat-button (click)="roleName='';$event.stopPropagation()" *ngIf="roleName" matSuffix
                            (click)="clearRoleName()" mat-icon-button >
                            <mat-icon>close</mat-icon>
                        </button>
                    </mat-form-field>
                    <mat-form-field class="col-6">
                        <mat-label>Select User</mat-label>
                        <mat-select #userSelect multiple formControlName="txtUserName" [(value)]="userName">
                            <mat-option>
                                <ngx-mat-select-search placeholderLabel="Search Users"
                                    noEntriesFoundLabel="No Records found" formControlName="txtUserNameSearch">
                                </ngx-mat-select-search>
                            </mat-option>
                            <mat-option #allUsersSelected [value]="0" (click)="toggleAllUsersSelection()">Select All
                                Users</mat-option>
                            <mat-option *ngFor="let users of filterUsers | async" [value]="users.id">
                                {{ users.name }}
                            </mat-option>
                        </mat-select>
                        <button mat-button (click)="userName='';$event.stopPropagation()" *ngIf="userName" matSuffix
                            (click)="clearUserName()" mat-icon-button >
                            <mat-icon>close</mat-icon>
                        </button>
                    </mat-form-field>
                    <mat-form-field class="col-6">
                        <mat-label>Select Date Range</mat-label>
                        <input autocomplete="new-txtFromDate" matInput type="text" name="daterange" ngxDaterangepickerMd [(ngModel)]="selectedRange"
                            [showCustomRangeLabel]="true" [alwaysShowCalendars]="true" [linkedCalendars]="false"
                            [ranges]="ranges" [showClearButton]="true" [locale]="calendarLocale"
                            [placeholder]="calendarPlaceholder" [keepCalendarOpeningWithRange]="true"
                            [minDate]="minDate" [maxDate]="maxDate" formControlName="txtFromDate" />
                        <!-- <mat-icon matSuffix>date_range</mat-icon> -->
                    </mat-form-field>
                    <div class="col-4" style='top:3px'>
                        <button mat-button class="buttonColor mb-2 mr-2" (click)="search()">Search
                        </button>
                        <button mat-button class="resetclr" (click)="reset()">Reset
                        </button>
                    </div>

                </div>
            </form>
        </div>
    </div>
</div>
<br>
<div class="col-12 table-responsive" style="margin-top: -15px;">
    <!-- <div [hidden]="!isLoading"
        style="display: flex; justify-content: center; align-items: center; background: transparent; color: lightseagreen;">
        <mat-progress-bar mode="indeterminate" value='66' diameter="45" class="lightseagreen">
        </mat-progress-bar>
    </div> -->
    <table mat-table class="table table-bordered" [dataSource]="dataSource" matSort>
        <ng-container matColumnDef="sno">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                S.No
            </th>
            <td mat-cell *matCellDef="let datas">
                {{ datas?.sno }}
            </td>
        </ng-container>
        <ng-container matColumnDef="calendarCode">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                Calendar Id
            </th>
            <td mat-cell *matCellDef="let datas">
                <ng-container>
                    {{ datas?.calendarCode }}
                </ng-container>
            </td>
        </ng-container>
        <ng-container matColumnDef="userName">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                UserName
            </th>
            <td mat-cell *matCellDef="let datas">
                {{ datas?.userName }}
            </td>
        </ng-container>
        <ng-container matColumnDef="title">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                Title
            </th>
            <td mat-cell *matCellDef="let datas">
                {{ datas?.title }}
            </td>
        </ng-container>
        <ng-container matColumnDef="scheduledDate">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                Scheduled Date
            </th>
            <td mat-cell *matCellDef="let datas">
                {{ datas?.scheduledDate }}
            </td>
        </ng-container>
        <ng-container matColumnDef="scheduledTime">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                Scheduled Time
            </th>
            <td mat-cell *matCellDef="let datas">
                {{ datas?.scheduledTime }}
            </td>
        </ng-container>
        <ng-container matColumnDef="status">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                Status
            </th>
            <td mat-cell *matCellDef="let datas">
                {{ datas?.status }}
            </td>
        </ng-container>
        <ng-container matColumnDef="createdDate">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                Created Date
            </th>
            <td mat-cell *matCellDef="let datas">
                {{ datas?.createdDate }}
            </td>
        </ng-container>

        <ng-container matColumnDef="options">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                Action
            </th>
            <td mat-cell *matCellDef="let datas">
                <a class="eyeCursorclass" target="_blank">
                    <i class="fa fa-eye pointer customThemeClass mr-5" (click)="viewCalendar(datas?.id)"></i> </a>
                &nbsp;
            </td>
        </ng-container>

        <ng-container matColumnDef="noRecords">
            <td class="text-align-center" mat-footer-cell *matFooterCellDef colspan="8">
                <div *ngIf="!isLoading">
                    {{"No Records Found"}}
                </div>
                <div [hidden]="!isLoading">
                    <mat-spinner [diameter]="220"></mat-spinner>
                </div>
            </td>
        </ng-container>
        <ng-container matColumnDef="paginator">
            <td mat-footer-cell *matFooterCellDef colspan="12">
                <mat-paginator class="table table-responsive mat-table-class" #MatPaginator
                    [length]="dataSource?.data?.length" [pageSize]="10" [pageIndex]="0" showFirstLastButtons
                    [pageSizeOptions]="[5, 10, 25, 100]">
                </mat-paginator>
            </td>
        </ng-container>

        <tr mat-footer-row *matFooterRowDef="['noRecords']"
            [ngClass]="{ active: dataSource && dataSource.data && dataSource?.data?.length !== 0 }"></tr>
        <tr mat-header-row *matHeaderRowDef="tableColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: tableColumns"></tr>
        <tr mat-footer-row *matFooterRowDef="['paginator']"></tr>
    </table>
</div>
