<!-- <h2 mat-dialog-title>Add Item</h2> -->
<div class="row">
  <div class="col-lg-6">
    <!-- Modal Title Start  -->
    <ng-container *ngIf="
        itemId === '00000000-0000-0000-0000-000000000000';
        else elseTitleTemplate
      ">
      <h2>Add Item</h2>
    </ng-container>
    <ng-template #elseTitleTemplate>
      <h2>Edit Item</h2>
    </ng-template>
    <!-- Modal Title End  -->

  </div>
</div>
<mat-dialog-content class="mat-typography">
  <!-- Form Start -->
  <form [formGroup]="itemForm">
    <div class="row" formGroupName="itemData">
      <div class="col-lg-6">
        <div class="row">
          <div class="col-md-6">
            <h4 class="customThemeClass"><b>Item</b></h4>
          </div>
        </div>
        <mat-card-content>
          <div class="row">
            <mat-form-field class="col-6">
              <mat-label>Item Id / HCPC Code<span class="asterisk">*</span></mat-label>
              <mat-select formControlName="txtItenId" (selectionChange)="onChange($event.value)" [(value)]="txtItem"
                #singleSelect>
                <mat-option>
                  <ngx-mat-select-search placeholderLabel="Search" noEntriesFoundLabel="No Matching found"
                    formControlName="txtSearchFilter"></ngx-mat-select-search>
                </mat-option>
                <mat-option *ngFor="let code of filteredItemTypes | async" [value]="code">
                  {{ code.productNames }}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="
                  itemForm?.get('itemData.txtItenId')?.touched &&
                  itemForm?.get('itemData.txtItenId')?.errors?.required
                ">
                Item Id is a required field!
              </mat-error>
              <button mat-button (click)="clearItemType()" (click)="txtItem = ''; $event.stopPropagation()"
                *ngIf="txtItem" matSuffix mat-icon-button >
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field>

            <mat-form-field class="col-6">
              <mat-label>Name<span class="asterisk">*</span></mat-label>
              <input autocomplete="new-txtItemName" matInput formControlName="txtItemName" type="text" readonly />

              <mat-error *ngIf="
                  itemForm?.get('itemData.txtItemName')?.touched &&
                  itemForm?.get('itemData.txtItemName')?.errors?.required
                ">
                Name is a required field!
              </mat-error>
            </mat-form-field>

            <mat-form-field class="col-6">
              <mat-label>Brand Name </mat-label>
              <input autocomplete="new-txtBrandName" formControlName="txtBrandName" matInput type="text" readonly />
            </mat-form-field>

            <mat-form-field class="col-6">
              <mat-label>Category </mat-label>
              <input autocomplete="new-txtCategoryName" formControlName="txtCategoryName" matInput type="text" readonly />
            </mat-form-field>
          </div>
          <div class="row">
            <mat-form-field class="col-6">
              <mat-label>Description</mat-label>
              <input autocomplete="new-txtItemDescription" matInput formControlName="txtItemDescription" type="text" readonly />
            </mat-form-field>
            <mat-form-field class="col-6">
              <mat-label>Branch</mat-label>
              <mat-select [(value)]="location" formControlName="drpLocation">
                <mat-option *ngFor="let location of drpLocationTypes" [value]="location.organizationUnitName">
                  {{ location.organizationUnitName }}
                </mat-option>
              </mat-select>
              <button mat-button (click)="clearLocation()" (click)="location = ''; $event.stopPropagation()"
                *ngIf="location" matSuffix mat-icon-button >
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field>
          </div>
          <!-- Item Follow Up Details -->
          <!-- <div class="row">
            <mat-form-field class="col-6">
              <mat-label>Follow Up Duration<span class="asterisk">*</span>
              </mat-label>
              <input matInput formControlName="txtFollowUpDuration" type="text" />
              <mat-error
                *ngIf="itemForm?.get('itemData.txtFollowUpDuration')?.touched && itemForm?.get('itemData.txtFollowUpDuration')?.errors?.required">
                Follow Up Duration is required!
              </mat-error>
              <mat-error
                *ngIf="
              itemForm?.get('itemData.txtFollowUpDuration')?.touched && itemForm?.get('itemData.txtFollowUpDuration')?.errors?.msg">
                {{itemForm?.get('itemData.txtFollowUpDuration').errors?.msg }}
              </mat-error>
              <mat-error
                *ngIf="
              itemForm?.get('itemData.txtFollowUpDuration')?.touched && itemForm?.get('itemData.txtFollowUpDuration')?.errors?.max?.max">
                Maximum Value is 99
              </mat-error>
            </mat-form-field>
            <div class="col-6 mt-3">
              <mat-slide-toggle formControlName="chkIsFollowUpNeeded" class="customThemeClass toggle"
                (change)="statusChange(itemForm)">
                <b>Is Follow Needed</b>
              </mat-slide-toggle>
            </div>
          </div> -->
          <!-- Item Follow Up Details -->


        </mat-card-content>
      </div>
      <div class="col-lg-6">
        <div class="row">
          <div class="col-md-6">
            <h4 class="customThemeClass"><b>Note</b></h4>
          </div>
        </div>
        <mat-card-content>
          <div class="row">
            <mat-form-field class="col-6">
              <mat-label>Note</mat-label>
              <textarea rows="1" matInput maxlength="1000"[(ngModel)]="notesText"
              (input)="updateCharacterCount()" formControlName="txtNote"></textarea> <div class="char-count">
                {{ notesText?.length }} / 5000
              </div>
            </mat-form-field>

            <mat-form-field class="col-6">
              <mat-label>Size</mat-label>
              <mat-select formControlName="drpSize" [(value)]="size">
                <mat-option *ngFor="let size of drpSize" [value]="size.itemSize">
                  {{ size.itemSize }}
                </mat-option>
              </mat-select>
              <button mat-button (click)="clearSize()" (click)="size = ''; $event.stopPropagation()" *ngIf="size"
                matSuffix mat-icon-button >
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field>

            <mat-form-field class="col-6">
              <mat-label>Insurance Name<span class="asterisk">*</span></mat-label>
              <mat-select formControlName="drpInsurance" (selectionChange)="onChangeIns($event)">
                <mat-option *ngFor="let insurance of drpPolicies" [value]="insurance.insuranceName">
                  {{
                  insurance.insuranceName + " / " + insurance.insuranceType
                  }}
                </mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field class="col-6">
              <mat-label>Billing Type <span class="asterisk">*</span>
              </mat-label>
              <mat-select formControlName="drpBillingType" (selectionChange)="onChanges($event)"
                [(value)]="billingType">
                <mat-option *ngFor="let type of drpBillingType" [value]="type.billingTypeName">
                  {{ type.billingTypeName }}
                </mat-option>
              </mat-select>
              <button mat-button (click)="clearBillingType()" (click)="billingType = ''; $event.stopPropagation()"
                *ngIf="billingType" matSuffix mat-icon-button >
                <mat-icon>close</mat-icon>
              </button>
              <mat-error *ngIf="
                  itemForm?.get('itemData.drpBillingType')?.touched &&
                  itemForm?.get('itemData.drpBillingType')?.errors?.required
                ">
                Billing Type is required!
              </mat-error>
            </mat-form-field>

            <mat-form-field *ngIf="hideRentalFields" class="col-6">
              <mat-label>Delivery Date <span class="asterisk">*</span>
              </mat-label>
              <input autocomplete="new-txtDeliveryDate" matInput maxlength="40" placeholder="MM/DD/YYYY" formControlName="txtDeliveryDate"
                [matDatepicker]="myPicker3" [min]="minDate" />
              <mat-datepicker-toggle matSuffix [for]="myPicker3"></mat-datepicker-toggle>
              <mat-datepicker #myPicker3></mat-datepicker>
              <mat-error *ngIf="
                  itemForm?.get('itemData.txtDeliveryDate')?.touched &&
                  itemForm?.get('itemData.txtDeliveryDate')?.errors
                ">
                Enter Valid Date!
              </mat-error>
              <mat-error *ngIf="
                  itemForm?.get('itemData.txtDeliveryDate')?.touched &&
                  itemForm?.get('itemData.txtDeliveryDate')?.errors?.required
                ">
                Delivery date Type is required!
              </mat-error>
            </mat-form-field>

            <mat-form-field *ngIf="hideRentalFields" class="col-6">
              <mat-label>Billed Interval <span class="asterisk">*</span>
              </mat-label>
              <mat-select formControlName="drpBilledInterval">
                <mat-option *ngFor="let billedName of drpBilingInterval" [value]="billedName.billedIntervalName">
                  {{ billedName.billedIntervalName }}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="
                  itemForm?.get('itemData?.drpBilledInterval')?.touched &&
                  itemForm?.get('itemData?.drpBilledInterval')?.errors?.required
                ">
                Billed Interval is required!
              </mat-error>
              <button mat-button (click)="clearBilledInterval()" (click)="billedInterval = ''; $event.stopPropagation()"
                *ngIf="billedInterval" matSuffix mat-icon-button >
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field>

            <mat-form-field *ngIf="hideRentalFields" class="col-6">
              <mat-label>Times To Bill <span class="asterisk">*</span>
              </mat-label>
              <input autocomplete="new-txtTimesToBill" matInput formControlName="txtTimesToBill" type="text" />
              <mat-error *ngIf="
                  itemForm?.get('itemData.txtTimesToBill')?.touched &&
                  itemForm?.get('itemData.txtTimesToBill')?.errors?.required
                ">
                Times to Bill is required!
              </mat-error>
            </mat-form-field>

            <div class="col-6 mt-3">
              <mat-slide-toggle formControlName="chkAutoClaim" class="customThemeClass toggle"
                (change)="statusChange(itemForm)">
                <b>Auto Claim</b>
              </mat-slide-toggle>
            </div>
          </div>
        </mat-card-content>
      </div>
    </div>

    <div class="row" formGroupName="itemPurchase">
      <div class="col-lg-12">
        <div class="row">
          <div class="col-md-6">
            <h4 class="customThemeClass">
              <b>Tricare 2017 Purchase Pricing</b>
            </h4>
          </div>
        </div>
        <mat-card-content>
          <div class="row">
            <div class="col-md-2">
              <br />
              <mat-checkbox formControlName="chkPricing">Special Pricing</mat-checkbox>
            </div>
            <div class="col-md-2">
              <br />
              <mat-checkbox formControlName="chkTaxable">Taxable</mat-checkbox>
            </div>
            <div class="col-md-2">
              <br />
              <mat-checkbox formControlName="chkPriceOveride">Price Override</mat-checkbox>
            </div>
            <div class="col-md-2">
              <br />
              <mat-checkbox formControlName="chkTaxRate">Special Tax Rate</mat-checkbox>
            </div>

            <div class="col-md-4">
              <br />
              <mat-checkbox formControlName="chkConvertPurchase">Manual Convert to Purchase</mat-checkbox>
            </div>
            <div class="col-md-2">
              <br />
              <mat-checkbox formControlName="chkAbn">ABN</mat-checkbox>
            </div>
            <div class="col-md-2">
              <br />
              <mat-checkbox formControlName="chkAbnUpgrade">ABN Upgrade</mat-checkbox>
            </div>
            <div class="col-md-2">
              <br />
              <mat-checkbox formControlName="chkCbOverride">CB Override</mat-checkbox>
            </div>
            <div class="col-md-2">
              <br />
              <mat-checkbox formControlName="chkAssignment">Accept Assignment</mat-checkbox>
            </div>

            <mat-form-field class="col-2">
              <mat-label>Allow</mat-label>
              <input autocomplete="new-txtAllow" matInput formControlName="txtAllow" type="text" />
              <span matPrefix>$</span>
              <mat-error *ngIf="
                  itemForm?.get('itemPurchase.txtAllow')?.touched &&
                  itemForm?.get('itemPurchase.txtAllow')?.errors?.number
                ">
                Enter only numbers!
              </mat-error>
            </mat-form-field>
            <mat-form-field class="col-2">
              <mat-label>BQty</mat-label>
              <input autocomplete="new-txtBQty" matInput maxlength="40" min="1" formControlName="txtBQty" maxlength="4" type="text" />
              <mat-error *ngIf="
                  itemForm?.get('itemPurchase.txtBQty')?.touched &&
                  itemForm?.get('itemPurchase.txtBQty')?.errors?.number
                ">
                Enter only Numbers!
              </mat-error>
            </mat-form-field>
            <mat-form-field class="col-2">
              <mat-label>Price Code</mat-label>
              <mat-select maxlength="40" [(value)]="priceCode" formControlName="drpPriceCode">
                <mat-option *ngFor="let priceCode of drpPriceCode" [value]="priceCode.priceCodeType">
                  {{ priceCode.priceCodeType }}
                </mat-option>
              </mat-select>
              <button mat-button (click)="clearPriceCode()" (click)="priceCode = ''; $event.stopPropagation()"
                *ngIf="priceCode" matSuffix mat-icon-button >
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field>
            <mat-form-field class="col-2">
              <mat-label>Price Option</mat-label>
              <mat-select maxlength="40" [(value)]="priceOption" formControlName="drpPriceOption">
                <mat-option *ngFor="let option of drpPriceOption" [value]="option?.shortCodeId">
                  {{ option?.priceOptionType }}
                </mat-option>
              </mat-select>
              <button mat-button (click)="clearPriceOption()" (click)="priceOption = ''; $event.stopPropagation()"
                *ngIf="priceOption" matSuffix mat-icon-button >
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field>
            <!-- Modifiers 1 -->

            <mat-form-field class="col-2">
              <mat-label> Modifiers 1 </mat-label>
              <input autocomplete="new-txtModifiers1" matInput maxlength="4" formControlName="txtModifiers1" pattern="[a-zA-Z0-9]*" type="text" />
              <mat-error *ngIf="
                  itemForm?.get('itemPurchase.txtModifiers1')?.touched &&
                  itemForm?.get('itemPurchase.txtModifiers1')?.errors?.pattern
                ">
                Enter only AlphaNumeric!
              </mat-error>
            </mat-form-field>

            <!-- Modifiers 2 -->
            <mat-form-field class="col-2">
              <mat-label> Modifiers 2 </mat-label>
              <input autocomplete="new-txtModifiers2" maxlength="4" formControlName="txtModifiers2" matInput type="text" pattern="[a-zA-Z0-9]*" />
              <mat-error *ngIf="
                  itemForm?.get('itemPurchase.txtModifiers2')?.touched &&
                  itemForm?.get('itemPurchase.txtModifiers2')?.errors?.pattern
                ">
                Enter Only Alphanumeric Only!
              </mat-error>
            </mat-form-field>

            <!-- Modifiers 3 -->
            <mat-form-field class="col-2">
              <mat-label> Modifiers 3 </mat-label>
              <input autocomplete="new-txtModifiers3" matInput maxlength="4" formControlName="txtModifiers3" pattern="[a-zA-Z0-9]*" type="text" />
              <mat-error *ngIf="
                  itemForm?.get('itemPurchase.txtModifiers3')?.touched &&
                  itemForm?.get('itemPurchase.txtModifiers3')?.errors?.pattern
                ">
                Enter only AlphaNumeric!
              </mat-error>
            </mat-form-field>
            <!-- Modifiers 4 -->
            <mat-form-field class="col-2">
              <mat-label> Modifiers 4 </mat-label>
              <input autocomplete="new-txtModifiers4" matInput maxlength="4" formControlName="txtModifiers4" pattern="[a-zA-Z0-9]*" type="text" />
              <mat-error *ngIf="
                  itemForm?.get('itemPurchase.txtModifiers4')?.touched &&
                  itemForm?.get('itemPurchase.txtModifiers4')?.errors?.pattern
                ">
                Enter only AlphaNumeric!
              </mat-error>
            </mat-form-field>
            <mat-form-field class="col-2">
              <mat-label>Charge <span class="asterisk">*</span></mat-label>
              <span matPrefix>$ &nbsp;</span>
              <input autocomplete="new-txtPrice" matInput maxlength="40" step="0.01sa" formControlName="txtPrice" minlength="1" maxlength="15"
                type="text" />
              <mat-error *ngIf="
                  itemForm?.get('itemPurchase.txtPrice')?.touched &&
                  itemForm?.get('itemPurchase.txtPrice')?.errors?.required && !itemForm?.get('itemPurchase.txtPrice')?.errors?.msg
                ">
                Charge is a required field!
              </mat-error>
              <mat-error
                *ngIf="itemForm?.get('itemPurchase.txtPrice')?.touched && itemForm?.get('itemPurchase.txtPrice')?.errors?.msg">
                {{itemForm?.get('itemPurchase.txtPrice')?.errors?.msg}}
              </mat-error>
            </mat-form-field>

            <mat-form-field class="col-2">
              <mat-label>Units <span class="asterisk">*</span></mat-label>
              <input autocomplete="new-txtQty" matInput maxlength="40" min="1" formControlName="txtQty" maxlength="4" type="text"
                (ngModelChange)="caluculate()" />
              <mat-error *ngIf="
                  itemForm?.get('itemPurchase.txtQty')?.touched &&
                  itemForm?.get('itemPurchase.txtQty')?.errors?.required
                ">
                Units is a required field!
              </mat-error>
              <mat-error *ngIf="
                  itemForm?.get('itemPurchase.txtQty')?.touched &&
                  itemForm?.get('itemPurchase.txtQty')?.errors?.number
                ">
                Enter only Numbers!
              </mat-error>
            </mat-form-field>
            <p class="mt-3">
              <b class="col-2 customThemeClass">Total Price:</b> {{ "$ "
              }}{{ caluculate() | number: "1.2-2" }}
            </p>
          </div>
        </mat-card-content>
      </div>
    </div>

    <div class="row mt-5" formGroupName="itemTaxInfo">
      <div class="col-lg-6">
        <div class="row">
          <div class="col-md-6">
            <h4 class="customThemeClass"><b>Tax Info</b></h4>
          </div>
        </div>
        <mat-card-content>
          <div class="row">
            <mat-form-field class="col-4">
              <mat-label>Tax Rate</mat-label>
              <input autocomplete="new-txtTaxRate" matInput formControlName="txtTaxRate" type="text" />
            </mat-form-field>
            <mat-form-field class="col-4">
              <mat-label>Tax Zone</mat-label>
              <mat-select maxlength="40" formControlName="drpTaxZone">
                <mat-option *ngFor="let zone of drpTaxZone" [value]="zone.taxZoneTypeShortCodeId">
                  {{ zone.taxZoneType }}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field class="col-4">
              <mat-label>Non Tax Reason</mat-label>
              <mat-select maxlength="40" [(value)]="taxReason" formControlName="drpTaxReason">
                <mat-option *ngFor="let reason of drpTaxReason" [value]="reason.shortCodeId">
                  {{ reason.nonTaxReasonType }}
                </mat-option>
              </mat-select>
              <button mat-button (click)="clearTaxReason()" (click)="taxReason = ''; $event.stopPropagation()"
                *ngIf="taxReason" matSuffix mat-icon-button >
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field>
          </div>
        </mat-card-content>
      </div>
      <div class="col-lg-6">
        <div class="row">
          <div class="col-md-6">
            <h4 class="customThemeClass"><b>Ordering</b></h4>
          </div>
        </div>
        <mat-card-content>
          <div class="row">
            <mat-form-field class="col-6">
              <mat-label>Ordering Units</mat-label>
              <input autocomplete="new-txtOrderQty" matInput formControlName="txtOrderQty" maxlength="2" type="text" />
              <mat-error *ngIf="
                  itemForm?.get('itemTaxInfo.txtOrderQty')?.touched &&
                  itemForm?.get('itemTaxInfo.txtOrderQty')?.errors?.number
                ">
                Enter only Numbers!
              </mat-error>
            </mat-form-field>
            <mat-form-field class="col-6">
              <mat-label>Billing Units</mat-label>
              <input autocomplete="new-txtBillQty" matInput formControlName="txtBillQty" maxlength="2" type="text" />
              <mat-error *ngIf="
                  itemForm?.get('itemTaxInfo.txtBillQty')?.touched &&
                  itemForm?.get('itemTaxInfo.txtBillQty')?.errors?.number
                ">
                Enter only Numbers!
              </mat-error>
            </mat-form-field>
          </div>
        </mat-card-content>
      </div>
    </div>

    <div class="row" formGroupName="itemDates">
      <div class="col-lg-6">
        <div class="row">
          <div class="col-md-6">
            <h4 class="customThemeClass"><b>Dates</b></h4>
          </div>
        </div>
        <mat-card-content>
          <div class="row">
            <div class="col-md-5">
              <br />
              <mat-checkbox formControlName="chkSupply">Days Of Supply Override</mat-checkbox>
            </div>
            <div class="col-md-7">
              <br />
              <mat-checkbox formControlName="chkSplit">Span Date Split Billing Enabled</mat-checkbox>
            </div>
            <mat-form-field class="col-4">
              <mat-label>Days Supply</mat-label>
              <input autocomplete="new-txtDaysSupply" matInput maxlength="40" placeholder="MM/DD/YYYY" formControlName="txtDaysSupply"
                [matDatepicker]="myPicker1" />
              <mat-datepicker-toggle matSuffix [for]="myPicker1"></mat-datepicker-toggle>
              <mat-datepicker #myPicker1></mat-datepicker>
              <mat-error *ngIf="
                  itemForm?.get('itemDates.txtDaysSupply')?.touched &&
                  itemForm?.get('itemDates.txtDaysSupply')?.errors
                ">
                Enter Valid Date!
              </mat-error>
            </mat-form-field>

            <mat-form-field class="col-4">
              <mat-label>Original DOS <span class="asterisk">*</span></mat-label>
              <input autocomplete="new-txtOriginalDOS" matInput maxlength="40" placeholder="MM/DD/YYYY" formControlName="txtOriginalDOS"
                [matDatepicker]="myPicker2" [min]="minDate" />
              <mat-datepicker-toggle matSuffix [for]="myPicker2"></mat-datepicker-toggle>
              <mat-datepicker #myPicker2></mat-datepicker>
              <mat-error *ngIf="
                  itemForm?.get('itemDates.txtOriginalDOS')?.touched &&
                  itemForm?.get('itemDates.txtOriginalDOS')?.errors
                ">
                Enter Valid Date!
              </mat-error>
            </mat-form-field>

            <mat-form-field class="col-4">
              <mat-label>DOS to</mat-label>
              <input autocomplete="new-txtDosTo" matInput maxlength="40" placeholder="MM/DD/YYYY" formControlName="txtDosTo"
                [matDatepicker]="myPicker3" />
              <mat-datepicker-toggle matSuffix [for]="myPicker3"></mat-datepicker-toggle>
              <mat-datepicker #myPicker3></mat-datepicker>
              <mat-error *ngIf="
                  itemForm?.get('itemDates.txtDosTo')?.touched &&
                  itemForm?.get('itemDates.txtDosTo')?.errors
                ">
                Enter Valid Date!
              </mat-error>
            </mat-form-field>
            <mat-form-field class="col-4">
              <mat-label>Pre Billing</mat-label>
              <input autocomplete="new-txtPreBill" matInput maxlength="40" placeholder="MM/DD/YYYY" formControlName="txtPreBill"
                [matDatepicker]="myPicker4" />
              <mat-datepicker-toggle matSuffix [for]="myPicker4"></mat-datepicker-toggle>
              <mat-datepicker #myPicker4></mat-datepicker>
              <mat-error *ngIf="
                  itemForm?.get('itemDates.txtPreBill')?.touched &&
                  itemForm?.get('itemDates.txtPreBill')?.errors
                ">
                Enter Valid Date!
              </mat-error>
            </mat-form-field>
            <mat-form-field class="col-4">
              <mat-label>Next Billing</mat-label>
              <input autocomplete="new-txtNextBill" matInput maxlength="40" placeholder="MM/DD/YYYY" formControlName="txtNextBill"
                [matDatepicker]="myPicker5" />
              <mat-datepicker-toggle matSuffix [for]="myPicker5"></mat-datepicker-toggle>
              <mat-datepicker #myPicker5></mat-datepicker>
              <mat-error *ngIf="
                  itemForm?.get('itemDates.txtNextBill')?.touched &&
                  itemForm?.get('itemDates.txtNextBill')?.errors
                ">
                Enter Valid Date!
              </mat-error>
            </mat-form-field>
            <mat-form-field class="col-4">
              <mat-label>Next Period</mat-label>
              <input autocomplete="new-txtNextPeriod" matInput maxlength="40" placeholder="MM/DD/YYYY" formControlName="txtNextPeriod"
                [matDatepicker]="myPicker6" />
              <mat-datepicker-toggle matSuffix [for]="myPicker6"></mat-datepicker-toggle>
              <mat-datepicker #myPicker6></mat-datepicker>
              <mat-error *ngIf="
                  itemForm?.get('itemDates.txtNextPeriod')?.touched &&
                  itemForm?.get('itemDates.txtNextPeriod')?.errors
                ">
                Enter Valid Date!
              </mat-error>
            </mat-form-field>
          </div>
        </mat-card-content>
      </div>
      <div class="col-lg-6">
        <div class="row">
          <div class="col-md-6">
            <h4 class="customThemeClass"><b>Insurance</b></h4>
          </div>
        </div>
        <mat-card-content>
          <div class="row">
            <div class="col-md-4">
              <br />
              <mat-checkbox formControlName="chkPrimary">Primary</mat-checkbox>
            </div>
            <div class="col-md-4">
              <br />
              <mat-checkbox formControlName="chkSecondary">Secondary</mat-checkbox>
            </div>
            <div class="col-md-4">
              <br />
              <mat-checkbox formControlName="chkTertiary">Tertiary</mat-checkbox>
            </div>
            <div class="col-md-4">
              <br />
              <mat-checkbox formControlName="chkPatient">Patient</mat-checkbox>
            </div>
            <mat-form-field class="col-4">
              <mat-label>Coverage Type</mat-label>
              <input autocomplete="new-txtCoverageType" matInput maxlength="40" formControlName="txtCoverageType" type="text" />
            </mat-form-field>
            <mat-form-field class="col-4">
              <mat-label>Claim Submission</mat-label>
              <input autocomplete="new-txtSubmission" matInput maxlength="40" formControlName="txtSubmission" type="text" />
            </mat-form-field>
            <mat-form-field class="col-4">
              <mat-label>Next DOS</mat-label>
              <input autocomplete="new-txtNextSDos" matInput maxlength="40" placeholder="MM/DD/YYYY" formControlName="txtNextSDos"
                [matDatepicker]="myPicker7" />
              <mat-datepicker-toggle matSuffix [for]="myPicker7"></mat-datepicker-toggle>
              <mat-datepicker #myPicker7></mat-datepicker>
              <mat-error *ngIf="
                  itemForm?.get('itemDates.txtNextSDos')?.touched &&
                  itemForm?.get('itemDates.txtNextSDos')?.errors
                ">
                Enter Valid Date!
              </mat-error>
            </mat-form-field>
          </div>
        </mat-card-content>
      </div>
    </div>

    <div class="row" formGroupName="itemClaimAttatchment">
      <div class="col-lg-6">
        <div class="row">
          <div class="col-md-6">
            <h4 class="customThemeClass"><b>EClaimAttachment</b></h4>
          </div>
        </div>
        <mat-card-content>
          <div class="row">
            <mat-form-field class="col-4">
              <mat-label>Attachment No</mat-label>
              <input autocomplete="new-txtAttatchment" matInput maxlength="40" formControlName="txtAttatchment" type="text" />
              <mat-error *ngIf="
                  itemForm?.get('itemClaimAttatchment.txtAttatchment')?.touched &&
                  itemForm?.get('itemClaimAttatchment.txtAttatchment')?.errors
                    ?.number
                ">
                Enter only numbers!
              </mat-error>
            </mat-form-field>
            <div class="col-md-8">
              <br />
              <mat-checkbox formControlName="chkIncludeEclaim">Include EClaims Attachment</mat-checkbox>
            </div>

            <mat-form-field class="col-4">
              <mat-label>Type Code</mat-label>
              <mat-select maxlength="40" [(value)]="eClaimType" formControlName="drpEClaimType">
                <mat-option *ngFor="let Ecode of drpEClaimType" [value]="Ecode.shortCodeId">
                  {{ Ecode.typeCodeType }}
                </mat-option>
              </mat-select>
              <button mat-button (click)="clearEClaimType()" (click)="eClaimType = ''; $event.stopPropagation()"
                *ngIf="eClaimType" matSuffix mat-icon-button >
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field>
            <mat-form-field class="col-4">
              <mat-label>Trans Code</mat-label>
              <mat-select maxlength="40" [(value)]="trans" formControlName="drpTrans">
                <mat-option *ngFor="let trans of drpTrans" [value]="trans.shortCodeId">
                  {{ trans.transCodeType }}
                </mat-option>
              </mat-select>
              <button mat-button (click)="clearTrans()" (click)="trans = ''; $event.stopPropagation()" *ngIf="trans"
                matSuffix mat-icon-button >
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field>
            <mat-form-field class="col-4">
              <mat-label>ESPDT Indicator</mat-label>
              <mat-select maxlength="40" [(value)]="espdtReferral" formControlName="drpEspdtReferral">
                <mat-option *ngFor="let espdt of drpEspdtReferral" [value]="espdt.shortCodeId">
                  {{ espdt.espdtIndicatorType }}
                </mat-option>
              </mat-select>
              <button mat-button (click)="clearEspdtReferral()" (click)="espdtReferral = ''; $event.stopPropagation()"
                *ngIf="espdtReferral" matSuffix mat-icon-button >
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field>
          </div>
        </mat-card-content>
      </div>
      <div class="col-lg-6">
        <div class="row">
          <div class="col-md-6">
            <h4 class="customThemeClass"><b>Item Claim Note</b></h4>
          </div>
        </div>
        <mat-card-content>
          <div class="row">
            <mat-form-field class="col-4">
              <mat-label>Type</mat-label>
              <mat-select maxlength="40" [(value)]="itemClaimType" formControlName="drpItemClaimType">
                <mat-option *ngFor="let claim of drpItemClaimType" [value]="claim.shortCodeId">
                  {{ claim.claimNoteType }}
                </mat-option>
              </mat-select>
              <button mat-button (click)="clearItemClaimType()" (click)="itemClaimType = ''; $event.stopPropagation()"
                *ngIf="itemClaimType" matSuffix mat-icon-button >
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field>
            <mat-form-field class="col-4">
              <mat-label>Units</mat-label>
              <input autocomplete="new-txtQuantity" maxlength="2" matInput formControlName="txtQuantity" />
              <mat-error *ngIf="
                  itemForm?.get('itemClaimAttatchment.txtQuantity')?.touched &&
                  itemForm?.get('itemClaimAttatchment.txtQuantity')?.errors
                    ?.number
                ">
                Enter only numbers!
              </mat-error>
            </mat-form-field>
            <mat-form-field class="col-4">
              <mat-label>Additonal Modifiers</mat-label>
              <input autocomplete="new-txtAdditional" matInput maxlength="40" appmask="AA-AA-AA" formControlName="txtAdditional" type="text" />
              <mat-error *ngIf="
                  itemForm?.get('itemClaimAttatchment.txtAdditional')?.touched &&
                  itemForm?.get('itemClaimAttatchment.txtAdditional')?.errors
                    ?.sAlphabets
                ">
                Enter only Alphabets!
              </mat-error>
            </mat-form-field>

            <div class="col-5">
              <br />
              <mat-checkbox formControlName="chkDiagnosis9">Diagnosis Codes-ICD-9</mat-checkbox>
            </div>
            <div class="col-4">
              <br />
              <mat-checkbox formControlName="chkDiagnosis10">Diagnosis Codes-ICD-10</mat-checkbox>
            </div>
          </div>
        </mat-card-content>
      </div>
    </div>
  </form>
  <!-- Form End -->
</mat-dialog-content>
<mat-dialog-actions align="end">
  <!-- Modal Action Buttons Start -->
  <button *ngIf="orderStatus" mat-button class="buttonColor"
    [disabled]="saveButtonHide || !(!itemForm.invalid && itemForm.dirty)" (click)="getOrderDetailsForSave()">
    Save
    <mat-icon *ngIf="isShowSpinner">
      <mat-spinner class="spinner-border spinner-border-sm" diameter="20">
      </mat-spinner>
    </mat-icon>
  </button>
  <button class="buttonCancelColor" mat-button mat-dialog-close>Close</button>
  <!-- Modal Action Buttons  End -->
</mat-dialog-actions>
