import { RestService } from '@abp/ng.core';
import { Injectable } from '@angular/core';
import { CreateUpdateDiagnosisCode10DTO, CreateUpdateDiagnosisCode9DTO, DiagnosisCode10DTO, DiagnosisCode9DTO } from '../../dropdown-proxy/dropdown-management/dropdowns/dto/patient/models';


@Injectable({
  providedIn: 'root',
})
export class PatientICDCodesService {
  apiName = 'Default';

  getDiagnosisCode10DatasBySDiagnosisCode10 = (sDiagnosisCode10: string) =>
    this.restService.request<any, DiagnosisCode10DTO>({
      method: 'GET',
      url: '/api/app/getDiagnosisCode10Datas',
      params: { sDiagnosisCode10 },
    },
    { apiName: this.apiName });

  getPatientICDCode10ByInput = (input: CreateUpdateDiagnosisCode10DTO) =>
    this.restService.request<any, DiagnosisCode10DTO[]>({
      method: 'GET',
      url: '/api/app/patientDocument/patientICDCode10',
      params: { diagnosisCodeTypeDataId: input.diagnosisCodeTypeDataId, diagnosisCode10ShortCodeId: input.diagnosisCode10ShortCodeId, diagnosisCode: input.diagnosisCode, diagnosisCodeTypeData: input.diagnosisCodeTypeData, isActive: input.isActive, isExist: input.isExist, isBillable: input.isBillable },
    },
    { apiName: this.apiName });

  getPatientICDCode9ByInput = (input: CreateUpdateDiagnosisCode9DTO) =>
    this.restService.request<any, DiagnosisCode9DTO[]>({
      method: 'GET',
      url: '/api/app/patientDocument/patientICDCode9',
      params: { diagnosisCodeTypeDataId: input.diagnosisCodeTypeDataId, diagnosisCode9ShortCodeId: input.diagnosisCode9ShortCodeId, diagnosisCode: input.diagnosisCode, diagnosisCodeTypeData: input.diagnosisCodeTypeData, isActive: input.isActive, isExist: input.isExist },
    },
    { apiName: this.apiName });

  constructor(private restService: RestService) {}
}
