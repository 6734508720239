<div class="row">
  <div class="col-lg-6">
    <h2>Notes</h2>
  </div>
</div>
<mat-dialog-content class="mat-typography">
  <mat-card-content>
    <div class="row mt-1">
      <div class="col-lg-12">
        <form [formGroup]="notesForm">
          <div class="table table-responsive">
            <table mat-table [dataSource]="dataSource" #noteTbSort="matSort" matSort class="mat-elevation-z8">
              <ng-container matColumnDef="defaultNotesId">
                <th class="header" mat-header-cell mat-sort-header *matHeaderCellDef>
                  <mat-form-field class="filter">
                    <input autocomplete="new-defaultNotesId" matInput formControlName="defaultNotesId" />
                    <mat-placeholder>Note Id</mat-placeholder>
                  </mat-form-field>
                </th>
                <td mat-cell *matCellDef="let element">
                  <span matTooltip="{{element?.defaultNotesId}}" matTooltipClass="custom-tooltip-font"> {{
                    element?.defaultNotesId }}</span>
                </td>
              </ng-container>
              <!-- Created By  Column -->
              <ng-container matColumnDef="createdBy">
                <th class="header" mat-header-cell mat-sort-header *matHeaderCellDef>
                  <mat-form-field class="filter">
                    <input autocomplete="new-createdBy" matInput formControlName="createdBy" />
                    <mat-placeholder>Created By</mat-placeholder>
                  </mat-form-field>
                </th>
                <td mat-cell *matCellDef="let element"> {{ element?.createdBy }}</td>
              </ng-container>

              <!-- Date Created -->
              <ng-container matColumnDef="dateCreated">
                <th class="header" fxFlex="200" mat-header-cell mat-sort-header *matHeaderCellDef>
                  <mat-form-field class="filter">
                    <mat-label> Date Created </mat-label>
                    <input autocomplete="new-dateCreated" matInput formControlName="dateCreated" placeholder="(MM/DD/YYYY)" />
                    <mat-error
                      *ngIf=" notesForm?.get('dateCreated')?.touched && notesForm?.get('dateCreated')?.errors?.dateVaidator">
                      Enter Valid date
                    </mat-error>
                  </mat-form-field>
                </th>
                <td fxFlex="200" mat-cell *matCellDef="let element"> <span class="ellipsis .cut-text">
                    {{ element?.dateCreated| date:'MM/dd/yyyy h:mm a' :'en_US' }} </span>
                </td>
              </ng-container>



              <!-- Actual Date -->
              <ng-container matColumnDef="actualDate">
                <th class="header  w-175" mat-header-cell mat-sort-header *matHeaderCellDef>
                  <mat-form-field class="filter">
                    <mat-label>Actual Date </mat-label>
                    <input autocomplete="new-actualDate" matInput formControlName="actualDate" placeholder="(MM/DD/YYYY)" />
                    <mat-error
                      *ngIf=" notesForm?.get('actualDate')?.touched && notesForm?.get('actualDate')?.errors?.dateVaidator">
                      Enter Valid date
                    </mat-error>
                  </mat-form-field>
                </th>
                <td mat-cell *matCellDef="let element">{{ element?.actualDate| date:'MM/dd/yyyy h:mm a' :'en_US' }}</td>
              </ng-container>
              <!-- Note Type -->
              <ng-container matColumnDef="noteType">

                <th class="header" mat-header-cell mat-sort-header *matHeaderCellDef>
                  <mat-form-field class="filter">
                    <input autocomplete="new-noteType" matInput formControlName="noteType" />
                    <mat-placeholder>Note Type</mat-placeholder>
                  </mat-form-field>
                </th>
                <td mat-cell *matCellDef="let element">{{ element?.noteType }}</td>
              </ng-container>
              <!-- Reason -->
              <ng-container matColumnDef="noteReason">

                <th class="header" mat-header-cell mat-sort-header *matHeaderCellDef>
                  <mat-form-field class="filter">
                    <input autocomplete="new-noteReason" matInput formControlName="noteReason" />
                    <mat-placeholder>Reason</mat-placeholder>

                  </mat-form-field>
                </th>
                <td mat-cell *matCellDef="let element">
                  <div class='warppable'>
                    {{element?.noteReason}}
                  </div>

                </td>
              </ng-container>
              <!--  Date Needed -->
              <ng-container matColumnDef="dateNeeded">

                <th class="header" class="w-125" mat-header-cell mat-sort-header *matHeaderCellDef>
                  <mat-form-field class="filter">
                    <mat-label> Date Needed </mat-label>
                    <input autocomplete="new-dateNeeded" matInput formControlName="dateNeeded" placeholder="(MM/DD/YYYY)" />
                    <mat-error
                      *ngIf=" notesForm?.get('dateNeeded')?.touched && notesForm?.get('dateNeeded')?.errors?.dateVaidator">
                      Enter Valid date
                    </mat-error>
                  </mat-form-field>
                </th>
                <td mat-cell *matCellDef="let element">{{ element?.dateNeeded | date:'MM/dd/yyyy h:mm a' :'en_US'}}</td>
              </ng-container>

              <ng-container matColumnDef="subject">
                <th class="header" mat-header-cell mat-sort-header *matHeaderCellDef>
                  <mat-form-field class="filter">
                    <input autocomplete="new-subject" matInput formControlName="subject" />
                    <mat-placeholder> Subject
                    </mat-placeholder>
                  </mat-form-field>
                </th>
                <td mat-cell *matCellDef="let element">{{ element?.subject }}</td>
              </ng-container>
              <!--  Status -->
              <ng-container matColumnDef="status">
                <th class="header" mat-header-cell mat-sort-header *matHeaderCellDef>
                  <mat-form-field class="filter">
                    <input autocomplete="new-status" matInput formControlName="status" />
                    <mat-placeholder>Status</mat-placeholder>
                  </mat-form-field>
                </th>
                <td mat-cell *matCellDef="let element">{{ element?.status }}</td>
              </ng-container>
              <ng-container matColumnDef="Options">
                <th class="header" mat-header-cell  *matHeaderCellDef>Options</th>
                <td mat-cell *matCellDef="let element" class="text-center">
                  <a *ngIf='isShownUpdateIconForNotes' (click)="ViewNotes(element?.notesId)"
                    matTooltip="Click To View Notes">
                    <i class="fa fa-eye pointer editBtnColor pl-3"></i>
                  </a>
                </td>
              </ng-container>

              <ng-container matColumnDef="noRecords">
                <td class="text-align-center" mat-footer-cell *matFooterCellDef colspan="9">
                  <div *ngIf="!isLoading">
                    {{"No Records Found"}}
                  </div>
                  <div [hidden]="!isLoading">
                    <mat-spinner [diameter]="220"></mat-spinner>
                  </div>
                </td>
              </ng-container>
              <ng-container matColumnDef="paginator">
                <td mat-footer-cell *matFooterCellDef colspan="9">
                  <mat-paginator class="table table-responsive" #MatPaginator [length]="dataSource?.data?.length"
                    [pageSize]="10" [pageIndex]="0" showFirstLastButtons [pageSizeOptions]="[5, 10, 25, 100]">
                  </mat-paginator>
                </td>
              </ng-container>
              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
              <tr mat-footer-row *matFooterRowDef="['noRecords']"
                [ngClass]="{ active: dataSource && dataSource.data && dataSource?.data?.length !== 0 }">
              </tr>
              <tr mat-footer-row *matFooterRowDef="['paginator']"></tr>
            </table>
          </div>
        </form>
      </div>
    </div>
  </mat-card-content>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button class="buttonCancelColor" mat-button mat-dialog-close>Close</button>
</mat-dialog-actions>
