<div class="container-fluid">

  <div class="row bg">
    <div class="col-12">
      <div class="d-flex align-items-center justify-content-between">
        <h3 class="font-weight-bolder mb-0">
          <b class="header-class">Patients List</b>
        </h3>

        <div class="page-title-box page-title-right pt-3 pb-0">
          <ol class="breadcrumb m-0">
            <!-- <div class="input-group">
              <input
                type="text"
                class="form-control"
                placeholder="Username"
                [hidden]="dateRangStatus != true"
                aria-label="Username"
                aria-describedby="basic-addon1"
                name="daterange"
                ngxDaterangepickerMd
                [(ngModel)]="selectedRange"
                id="calendar-input"
                [showCustomRangeLabel]="true"
                [alwaysShowCalendars]="true"
                [linkedCalendars]="true"
                [ranges]="ranges"
                [showClearButton]="true"
                [locale]="calendarLocale"
                [placeholder]="calendarPlaceholder"
                [keepCalendarOpeningWithRange]="true"
                [minDate]="minDate"
                [maxDate]="maxDate"
                (datesUpdated)="datesUpdateds($event)"
                autocomplete="new-daterange"
              />
            </div> -->
            <form [formGroup]="branchForm">
              <mat-form-field class="pr-3 mt-3">
                <mat-label>Branch</mat-label>
                <mat-select #selectBranch formControlName="drpBranch" multiple [(value)]="organizationUnitName"
                  (selectionChange)="onBranchSelectionChange($event)">
                  <mat-option>
                    <ngx-mat-select-search placeholderLabel="Search" noEntriesFoundLabel="No Matches found"
                      formControlName="txtBranch"></ngx-mat-select-search>
                  </mat-option>

                  <mat-option #selectAllBranch (click)="allBranchSelect()">All</mat-option>

                  <mat-option *ngFor="let branch of brnachList | async" [value]="branch.id">
                    {{ branch.organizationUnitName }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </form>

            <!-- <li>
            <span class="input-group-text bg-color mr-2" id="basic-addon1">
              <label style="margin: auto" for="calendar-input">
                <i class="fa fa-filter cursor" aria-hidden="true"></i>
              </label>
            </span>
          </li> -->
            <li>
              <button matTooltip="Click To Add New Patient" mat-button class="mr-2  mt-3 buttonColor font-size-10"
                (click)="newPatient()" routerLink="patientSearch/patientCreateEditTab" routerLinkActive="active">
                <i class="fa fa-plus "></i>
              </button>
            </li>
            <!-- <li>
              <button matTooltip="Click To Add Note" mat-button class="mr-2  mt-3 buttonColor font-size-10"
                (click)="openAddNotesPopUp()" [disabled]="!selectedPatient">
                <i class="fa fa-pencil-square-o"></i>
              </button>
            </li>
            <li>
              <button matTooltip="Click To View Notes" mat-button class="mr-2  mt-3 buttonColor font-size-10"
                (click)="openViewNotesPopup()" [disabled]="!selectedPatient">
                <i class="fa fa-eye"></i>
              </button>
            </li> -->
          </ol>
        </div>
      </div>
    </div>
  </div>

  <div class="card card-body mt-3">
    <div class="row" *ngIf="!isLoading">
      <div class="col-12">
        <div *ngIf="!isLoading" class="table table-responsive">
          <table id="tbl" datatable [dtOptions]="dtOptions" style="width: 100%" class="row-border hover">
            <thead>
              <tr>
                <th class="text-center">Options</th>
                <th class="text-center">ChartNo</th>
                <th class="text-center">Patient Name</th>
                <th class="text-center">HomeNo</th>
                <th class="text-center">Mobile No</th>
                <th class="text-center">DOB</th>
                <th class="text-center">Email</th>
                <th class="text-center">Primary Ins Name</th>
                <th class="text-center">Ordering</th>
                <th class="text-center">CSR</th>
                <th class="text-center">Setup Date</th>
                <th class="text-center">Mask Date</th>
              </tr>
            </thead>
            <tbody>
              <tr [ngClass]="
                  tblItemSelectedRowId === data.patientId
                    ? 'highlighted'
                    : ''
                " *ngFor="let data of patientListDataArray"
                (click)="tblItemSelectedRow(data.patientId,data.documentId,data.chartId)">
                <td class="text-center">
                  <mat-icon style="cursor: pointer !important" [matMenuTriggerFor]="menu">more_vert</mat-icon>
                  <mat-menu class="custom-mat-menu" #menu="matMenu">
                    <button mat-menu-item [disabled]="data.patientId === defaultGuid" (click)="openAddNotesPopUp()">Add Patient Note</button>
                    <button mat-menu-item [disabled]="data.patientId === defaultGuid" (click)="openViewNotesPopup()">View Patient Notes</button>




                    <a [routerLink]="'/view_document_tiff/' + data.docId +'/'+ true" target="_blank" class="preview-link">
                      <button mat-menu-item >View Document Images</button>
                    </a>


                    <button mat-menu-item (click)="viewPatientLedger(data.patientId)">View Patient Ledger</button>
                    <button [disabled]="true" mat-menu-item>Email supplies Reminder</button>
                    <mat-divider></mat-divider>
                    <a [routerLink]="'/orders/saleOrder/' + data.patientId"
                      target="_blank" class="preview-link">
                      <button mat-menu-item>Start New Order</button>
                    </a>
                    <mat-divider></mat-divider>
                    <a [routerLink]="'/settings/calender/' + data.patientId "
                      target="_blank" class="preview-link">
                    <button mat-menu-item>Schedule Appointment</button>
                    </a>
                    <mat-divider></mat-divider>

                    <button mat-menu-item
                      (click)="openNewVerification(data.patientId,false)">Ondemand Verification Request</button>
                    <mat-divider></mat-divider>

                  <button mat-menu-item
                      (click)="openNewVerification(data.patientId,true)">Online Verification</button>
                    <mat-divider></mat-divider>
                    <button mat-menu-item [disabled]="data.isMedicarePolicy===1 || data.documentId === defaultGuid" (click)="checkListModel(data)">New Checklist</button>
                    <mat-divider></mat-divider>
                    <button [disabled]="true" mat-menu-item>Text Conversation</button>
                  </mat-menu>

                  <div>
                    <mat-form-field style="display: none">
                      <input matInput [matDatepicker]="datepicker" placeholder="Choose a date"
                        (dateChange)="onDateSelected($event)" />
                      <mat-datepicker-toggle matSuffix [for]="datepicker"></mat-datepicker-toggle>
                      <mat-datepicker #datepicker [xPosition]="'before'" [yPosition]="'above'"></mat-datepicker>
                    </mat-form-field>
                  </div>
                </td>
                <td class="text-center">
                  <a [routerLink]="'/patientCreateEditTab/' + data.patientId + '/' + data.documentId" target="_blank"
                  [title]="data.chartId" class="preview-link">{{data?.chartId ? data?.chartId : '-'}}
                  </a>
                </td>

                <td class="text-center">
                    {{ data?.patientName?data?.patientName:'-' }}</td>
                <td class="text-center">{{ data?.homePhone?data?.homePhone:'_' }}</td>
                <td class="text-center">{{ data?.mobile?data?.mobile:'_' }}</td>
                <td class="text-center">{{ data?.dob ? (data?.dob | date : "MM/dd/yyyy" : "en_US") : '_' }}</td>
                <td class="text-center">{{ data?.emailId?data?.emailId:'_' }}</td>
                <td class="text-center">{{ data?.primaryInsuranceName?data?.primaryInsuranceName:'_' }}</td>
                <td class="text-center">{{ data?.ordering?data?.ordering:'_' }}</td>
                <td class="text-center">{{ data?.csrName?data?.csrName :'_'}}</td>
                <td class="text-center">{{ data?.setupDate?(data?.setupDate | date : "MM/dd/yyyy" : "en_US"):'_'}}
                </td>
                <td class="text-center">{{ data?.maskDate?(data?.maskDate | date : "MM/dd/yyyy" : "en_US"):'_' }}
                </td>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <th class="text-center">#</th>
                <th class="text-center">
                  <input matInput class="textbox-border-class" type="text" placeholder="Chart No"
                    name="search-ChartNo" />
                </th>
                <th class="text-center">
                  <input matInput type="text" class="textbox-border-class" placeholder="PatientName"
                    name="search-PatientName" />
                </th>
                <th class="text-center">
                  <input matInput type="text" class="textbox-border-class" placeholder="HomeNo" name="search-HomeNo" />
                </th>
                <th class="text-center">
                  <input matInput type="text" class="textbox-border-class" placeholder="MobileNo"
                    name="search-MobileNo" />
                </th>
                <th class="text-center">
                  <input matInput class="textbox-border-class" type="text" placeholder="DOB" name="search-DOB" />
                </th>
                <th class="text-center">
                  <input matInput class="textbox-border-class" type="text" placeholder="Email" name="search-Email" />
                </th>
                <th class="text-center">
                  <input matInput class="textbox-border-class" type="text" placeholder="PrimaryInsName"
                    name="search-PrimaryInsName" />
                </th>
                <th class="text-center">
                  <input matInput class="textbox-border-class" type="text" placeholder="Ordering"
                    name="search-Ordering" />
                </th>
                <th class="text-center">
                  <input matInput class="textbox-border-class" type="text" placeholder="CSR" name="search-CSR" />
                </th>
                <th class="text-center">
                  <input matInput class="textbox-border-class" type="text" placeholder="SetupDate"
                    name="search-SetupDate" />
                </th>
                <th class="text-center">
                  <input matInput class="textbox-border-class" type="text" placeholder="MaskDate"
                    name="search-MaskDate" />
                </th>
              </tr>
            </tfoot>
          </table>
          <!-- <app-patient-list-table>

          </app-patient-list-table> -->
        </div>
      </div>
    </div>
    <div class="row" *ngIf="isLoading">
      <div class="col-12">
        <div class="d-flex justify-content-center" *ngIf="isLoading">
          <img class="img-responsive pb-2 mr-1" src="assets/gifs/Infinity-loader.gif" />
        </div>
      </div>
    </div>
  </div>


</div>
