<div class="row">
    <div class="col-lg-6">
    <h2>Eligibility History</h2>
    </div>
    <!-- <div class="col-lg-6 text-right">
     <button class="text-right close"  aria-label="Close" mat-dialog-close> <b> X </b>
         
      </button>
     
    </div> -->
  </div>
<mat-dialog-content class="mat-typography">
    <app-table  (viewButton)="ViewNotes($event)"  [isLoading]="isLoading" (deleteButton)="deleteInviteUsers($event)" 
    [strPageType]="strPageType">
    </app-table>
</mat-dialog-content>
<mat-dialog-actions align="end">
  
  <button class="buttonCancelColor" mat-button mat-dialog-close>Close</button>
 <!-- <button mat-button [mat-dialog-close]="true" cdkFocusInitial>Install</button> --> 
</mat-dialog-actions>

