
import { PagedResultDto, RestService } from '@abp/ng.core';
import { Injectable } from '@angular/core';
import { PatientDTO } from '../../patient-management/patient/dto/models';
import { CreateUploadFilesDTO, SearchUploadFilesDTO, UpdateUploadFilesDTO, UploadFilesDTO } from '../upload-file/dto/models';
import { AssignUserDTO, BatchFileUploadsDTO, CreateUpdateBatchFileUploadsDTO, CreateUpdateFileUploadsDTO, FileUploadsWithBatchIdDTO } from './dto/models';

@Injectable({
  providedIn: 'root',
})
export class FileUploadService {
  apiName = 'platformManagement';

  getPatientDocumentByBlobNameByBlobName = (blobName: string) =>
    this.restService.request<any, string>({
      method: 'GET',
      responseType: 'text',
      url: '/api/PlatformApp/upload-files/get-patient-document-by-blob-name',
      params: { blobName },
    },
      { apiName: this.apiName, skipHandleError: true });

  getAssignnedFilesByUserId = (userId: string) =>
    this.restService.request<any, UploadFilesDTO[]>({
      method: 'GET',
      url: `/api/PlatformApp/upload-files/get-assignned-files/${userId}`,
    },
      { apiName: this.apiName, skipHandleError: true });

  getUploadedFileByIdByFileId = (fileId: string) =>
    this.restService.request<any, UploadFilesDTO>({
      method: 'GET',
      url: `/api/PlatformApp/upload-files/get-uploaded-file-by-id/${fileId}`,
    },
      { apiName: this.apiName, skipHandleError: true });

  getUploadedFileList = () =>
    this.restService.request<any, UploadFilesDTO[]>({
      method: 'GET',
      url: '/api/PlatformApp/upload-files/get-uploaded-file-list',
    },
      { apiName: this.apiName, skipHandleError: true });

  updateFileDetailsByFileIdAndOUpdateFileUploads = (fileId: string, oUpdateFileUploads: UpdateUploadFilesDTO) =>
    this.restService.request<any, UploadFilesDTO>({
      method: 'PUT',
      url: `/api/PlatformApp/upload-files/update-file-details/${fileId}`,
      body: oUpdateFileUploads,
    },
      { apiName: this.apiName, skipHandleError: true });

  updateSortedDataByFileId = (fileId: string) =>
    this.restService.request<any, UploadFilesDTO>({
      method: 'PUT',
      url: `/api/PlatformApp/upload-files/update-sorted-data/${fileId}`,
    },
      { apiName: this.apiName, skipHandleError: true });

  updateSortedIndexByFileIdAndSortedFileIdAndDocumentType = (fileId: string, sortedFileId: string, documentType: string) =>
    this.restService.request<any, UploadFilesDTO>({
      method: 'PUT',
      url: '/api/PlatformApp/upload-files/update-sorted-index',
      params: { fileId, sortedFileId, documentType },
    },
      { apiName: this.apiName, skipHandleError: true });

  uploadFileByOCreateFileUploads = (oCreateFileUploads: CreateUploadFilesDTO) =>
    this.restService.request<any, UploadFilesDTO>({
      method: 'POST',
      url: '/api/PlatformApp/upload-files/upload-file',
      body: oCreateFileUploads,
    },
      { apiName: this.apiName, skipHandleError: true });


  searchPatientsByInput = (input: SearchUploadFilesDTO) =>
    this.restService.request<any, UploadFilesDTO[]>({
      method: 'POST',
      url: `/api/PlatformApp/upload-files/search-files`,
      body: input,
    },
      { apiName: this.apiName, skipHandleError: true });


  fileBatchUploadByBatchFileUploadsInput = (batchFileUploadsInput: CreateUpdateBatchFileUploadsDTO) =>
    this.restService.request<any, BatchFileUploadsDTO>({
      method: 'POST',
      url: '/api/PlatformApp/file-upload/file-batch-upload',
      body: batchFileUploadsInput,
    },
      { apiName: this.apiName, skipHandleError: true });

  updateFileOnBatchByBatchIdAndFileIdAndFileUploadsInput = (batchId: string, fileId: string, fileUploadsInput: CreateUpdateFileUploadsDTO) =>
    this.restService.request<any, FileUploadsWithBatchIdDTO>({
      method: 'PUT',
      url: '/api/PlatformApp/file-upload/update-file-on-batch',
      params: { batchId, fileId },
      body: fileUploadsInput,
    },
      { apiName: this.apiName, skipHandleError: true });

  getFileBatchByIdByBatchId = (batchId: string) =>
    this.restService.request<any, BatchFileUploadsDTO>({
      method: 'GET',
      url: `/api/PlatformApp/file-upload/get-file-batch-by-id/${batchId}`,
    },
      { apiName: this.apiName, skipHandleError: true });

  updateApprovedPatientIdByBatchIdAndFileIdAndPatientId = (batchId: string, fileId: string, patientId: string) =>
    this.restService.request<any, BatchFileUploadsDTO>({
      method: 'PUT',
      url: '/api/PlatformApp/file-upload/update-approved-patient-id',
      params: { batchId, fileId, patientId },
    },
      { apiName: this.apiName, skipHandleError: true });

  updateExtractedDetailsOnFileByBatchIdAndFileIdAndExtractedDataInput = (batchId: string, fileId: string, extractedDataInput: PatientDTO) =>
    this.restService.request<any, FileUploadsWithBatchIdDTO>({
      method: 'PUT',
      url: '/api/PlatformApp/file-upload/update-extracted-details-on-file',
      params: { batchId, fileId },
      body: extractedDataInput,
    },
      { apiName: this.apiName, skipHandleError: true });

  searchFileBySBatchCodeAndSTypeAndSUploadFromAndDtCreatedDateAndSUploadByAndIFileCountAndSBatchStatus = (sBatchCode: string, sType: string, sUploadFrom: string, dtCreatedDate: string, sUploadBy: string, iFileCount: number, sBatchStatus: string) =>
    this.restService.request<any, PagedResultDto<BatchFileUploadsDTO>>({
      method: 'POST',
      url: '/api/PlatformApp/file-upload/search-file',
      params: { sBatchCode, sType, sUploadFrom, dtCreatedDate, sUploadBy, iFileCount, sBatchStatus },
    },
      { apiName: this.apiName, skipHandleError: true });

  getFileBatchListByDtStartDateAndDtEndDateAndSStatus = (dtStartDate: string, dtEndDate: string, sStatus: string = "ALL") =>
    this.restService.request<any, BatchFileUploadsDTO[]>({
      method: 'GET',
      url: '/api/PlatformApp/file-upload/get-file-batch-list',
      params: { dtStartDate, dtEndDate, sStatus },
    },
      { apiName: this.apiName, skipHandleError: true });

  updateArchiveFileBatchStatusByBatchIdAndArchived = (batchId: string, archived: boolean = true) =>
    this.restService.request<any, BatchFileUploadsDTO>({
      method: 'PUT',
      url: `/api/PlatformApp/file-upload/update-archive-file-batch-status/${batchId}`,
      params: { archived },
    },
      { apiName: this.apiName, skipHandleError: true });

  deleteBatchByBatchId = (batchId: string) =>
    this.restService.request<any, string>({
      method: 'DELETE',
      responseType: 'text',
      url: `/api/PlatformApp/file-upload/delete-batch/${batchId}`,
    },
      { apiName: this.apiName, skipHandleError: true });

  deleteFileByBatchIdAndFileId = (batchId: string, fileId: string) =>
    this.restService.request<any, BatchFileUploadsDTO>({
      method: 'DELETE',
      url: '/api/PlatformApp/file-upload/delete-file',
      params: { batchId, fileId },
    },
      { apiName: this.apiName, skipHandleError: true });



  updateAssignedUserInBatchByLstAssignUsers = (lstAssignUsers: AssignUserDTO[]) =>
    this.restService.request<any, FileUploadsWithBatchIdDTO>({
      method: 'PUT',
      url: '/api/PlatformApp/file-upload/update-assigned-user-in-batch',
      body: lstAssignUsers,
    },
      { apiName: this.apiName, skipHandleError: true });

  searchFilesInBatchByBatchIdAndSDefaultBatchIdAndSFileNameAndSAssignedToAndSAssignedByAndDtAssignedTimeAndSStatus = (batchId: string, sDefaultBatchId: string, sFileName: string, sAssignedTo: string, sAssignedBy: string, dtAssignedTime: string, sStatus: string) =>
    this.restService.request<any, FileUploadsWithBatchIdDTO[]>({
      method: 'POST',
      url: '/api/PlatformApp/file-upload/search-files-in-batch',
      params: { batchId, sDefaultBatchId, sFileName, sAssignedTo, sAssignedBy, dtAssignedTime, sStatus },
    },
      { apiName: this.apiName, skipHandleError: true });


  constructor(private restService: RestService) { }
}
