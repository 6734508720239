<div class="container-fluid">
  <!-- <div class="row bg"> -->
  <div class="col-12">
    <div class="d-flex align-item-center justify-content-between m-0">
      <h3 class="mt-2 mb-0 font-size-18"><b>Stock</b></h3>
      <div class=" page-title-box  page-title-right mt-1 mb-0">
        <ol class="breadcrumbs m-0">
          <li class="mr-3">
            <button matTooltip="Click To Goback to StockList" mat-button class="mt-0 btn btn-sm lightSeaGreen-dot"
              (click)="backToStock()">
              <i class="fa fa-backward mr-1" ></i> Back To Stock List
            </button>
          </li>
          <li class="breadcrumb-item">Inventory</li>
          <li class="breadcrumb-item">Stock</li>
        </ol>
      </div>
    </div>
  </div>

</div>

<div class="row">
  <div class="col-xl-2 pr-0">
    <div class="card card-body margin">
      <div class="table-responsive mt-2">
        <div class="text-center">
          <span style="font-size: larger;"><b class="colored-heading">Product Details </b></span>
        </div>
        <hr>
        <table class="table table-centered">
          <tbody>
            <tr>
              <td>
                <h4 class="mb-0 top text-center"><b class="customThemeClass">Product Name </b> </h4>
                <h4 class="btm text-center"> <b> {{productName}} </b> </h4>
              </td>
            </tr>
            <hr>

            <tr>
              <td>
                <h4 class="mb-0 top text-center"> <b class="customThemeClass"> Brand Name </b></h4>
                <h4 class="btm text-center"> <b>{{brandName}}</b> </h4>
              </td>
            </tr>
            <hr>

            
            <tr>
              <td>
                <h4 class="mb-0 top text-center"> <b class="customThemeClass">Product Category</b></h4>
                <h4 class="btm text-center"> <b> {{category}}</b></h4>
              </td>
            </tr>
            <hr>
            <tr>
              <td>
                <h4 class="mb-0 top text-center"> <b class="customThemeClass">Manufacturer</b></h4>
                <h4 class="btm  text-center"> <b> {{vendor}} </b> </h4>
              </td>
            </tr>
            <hr>

            <tr>
              <td>
                <h4 class="mb-0 top  text-center"><b class="customThemeClass">Lot No</b> </h4>
                <h4 class="btm  text-center"> <b> {{LotNo}} </b></h4>
              </td>
            </tr>
            <hr>
            <tr>
              <td>
                <h4 class="mb-0 top text-center"><b class="customThemeClass">Status</b> </h4>
                <h4 class="btm  text-center"> <b> {{Status}} </b></h4>
              </td>
            </tr>
            <hr>
            <tr>
              <td>
                <h4 class="mb-0 top text-center"><b class="customThemeClass">Units</b> </h4>
                <h4 class="btm  text-center"> <b> {{quantity}} </b></h4>
              </td>
            </tr>
            <hr>
            <tr>
              <td>
                <h4 class="mb-0 top text-center"><b class="customThemeClass">Balance Units</b> </h4>
                <h4 class="btm  text-center"> <b> {{BalQty}} </b></h4>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>

  <div class="col-xl-10">
    <div class="card card-body mar">

      <div [ngClass]="{data:show === true}">
        <h3 class="customThemeClass mt-2"><b>Stock View</b></h3>
        <!-- <mat-accordion >
          <mat-expansion-panel [expanded]="step">
            <mat-expansion-panel-header>
              <mat-panel-title class="customThemeClass">
                <ng-container>
                  <b>Add Roles</b>
                </ng-container>
              </mat-panel-title>
            </mat-expansion-panel-header> -->
        <form [formGroup]="stockForm">
          <mat-form-field class="col-6" *ngIf="showSerial">
            <mat-label> Product Serial No </mat-label>
            <input autocomplete="new-txtSerailNo" matInput maxlength="40" formControlName="txtSerailNo" type="text" pattern="[a-zA-Z0-9]*" />
            <mat-error *ngIf="
          stockForm?.get('txtSerailNo')?.touched &&
          stockForm?.get('txtSerailNo')?.errors?.pattern
        ">
              Enter only Alphanumeric!
            </mat-error>
          </mat-form-field>
          <mat-form-field class="col-6" *ngIf="!showSerial">
            <mat-label> Product Serial No </mat-label>
            <input autocomplete="new-txtSerailNo" readonly matInput maxlength="40" formControlName="txtSerailNo" type="text" pattern="[a-zA-Z0-9]*" />
            <mat-error *ngIf="
          stockForm?.get('txtSerailNo')?.touched &&
          stockForm?.get('txtSerailNo')?.errors?.pattern
        ">
              Enter only Alphanumeric!
            </mat-error>
          </mat-form-field>

          <mat-form-field class="col-6">
            <mat-label> Order No </mat-label>
            <input autocomplete="new-txtOrderNo" readonly matInput maxlength="40" formControlName="txtOrderNo" type="text" />
          </mat-form-field>

          <mat-form-field class="col-6">
            <mat-label> Status </mat-label>
            <input autocomplete="new-txtStatus" readonly matInput maxlength="40" formControlName="txtStatus" type="text" />
          </mat-form-field>

          <mat-form-field class="col-6">
            <mat-label> Sold Date </mat-label>
            <!-- <input readonly matInput maxlength="40" formControlName="txtSoldDate" type="text" /> -->
            <input autocomplete="new-txtSoldDate" readonly formControlName="txtSoldDate" matInput maxlength="40" type="text" placeholder="(MM/DD/YYYY)"
              [matDatepicker]="txtSoldDate" />
            <!-- <mat-datepicker-toggle matSuffix [for]="txtSoldDate"> </mat-datepicker-toggle> -->
            <mat-datepicker #txtSoldDate> </mat-datepicker>
          </mat-form-field>
        </form>
        <br />
        <div class="col-7">
          <div class="text-lg-right center">
            <button *ngIf="showSave" mat-button class="buttonColor  mr-2" (click)="saveStock()"> Save
              <mat-icon *ngIf='isShowSpinner'>
                <mat-spinner class="spinner-border spinner-border-sm" diameter="20"> </mat-spinner>
              </mat-icon>
            </button>
            <!-- <button mat-button class="buttonColor  mr-2 resetclr" (click)="reset()"> Reset</button> -->
          </div>
        </div>
        <!-- </mat-expansion-panel>
        </mat-accordion> -->
      </div>

      <div class="col-12">
        <div class="d-flex align-item-center justify-content-between m-0">
          <h3 class="customThemeClass ml-1"><b>Stock List</b></h3>
          <div class=" page-title-box  page-title-right mt-1 mb-0 ml-5">
            <ol class="breadcrumbs m-0">
              <li>
                <button
                [disabled]="(dataSource && dataSource?.data && dataSource?.data?.length === 0)"
                (click)="exportTableData()" mat-button class="btn btn-sm lightSeaGreen-dot">
                  <i class="fa fa-file-import"></i> Export
                </button>
              </li>
              <li>
                <input id="file-upload" [hidden]="true" (change)="importItems(uploadFileReference?.files)"
                  #uploadFileReference type="file" accept=".xlsx" />
                <button (click)="uploadFileReference.click()" mat-button class="btn btn-sm lightSeaGreen-dot">
                  <i class="fa fa-file-import pl-2 pr-2"></i> Import Serial No
                </button>
              </li>
              <li>
                <input type="file" hidden #inputFiles (change)="importItemsFormFile(inputFiles?.files)" accept=".xlsx"
                  [multiple]="false" />
                <button (click)="downloadTemplate()" mat-button class="btn btn-sm lightSeaGreen-dot">
                  <i class="fa fa-cloud-download pl-2 pr-2"></i>Download Template
                </button>
              </li>
              <!-- <li class="breadcrumb-item">Inventory</li>
              <li class="breadcrumb-item">Stock</li> -->
            </ol>
          </div>
        </div>
      </div>

      <div class="row mr-3">
        <div class="col-12">
          <!-- <div [hidden]="!isLoading"
            style="display: flex; justify-content: center; align-items: center; background: transparent">
            <mat-progress-spinner mode="indeterminate" value="66" diameter="45" class="lightseagreen">
            </mat-progress-spinner>
          </div> -->
          <table mat-table class="table ml-4" matSort [dataSource]="dataSource">
            <form style="display: flex" [formGroup]="stockSearchForm">
              <ng-container matColumnDef="sno">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>S.No</th>
                <td mat-cell *matCellDef="let data; let i = index">
                  {{ i+1 }}
                </td>
              </ng-container>
              <ng-container matColumnDef="productNumber">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                  <!-- <mat-form-field class="filter">
                  <input matInput formControlName="productNumberSearch" />
                  <mat-placeholder>Product Serial No</mat-placeholder>
                </mat-form-field> -->Product Serial No
                </th>
                <td mat-cell *matCellDef="let data">
                  <ng-container>
                    {{ data.serialNo }}
                  </ng-container>
                </td>
              </ng-container>
              <ng-container matColumnDef="orderId">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                  <!-- <mat-form-field class="filter">
                  <input matInput (keypress)="numberOnly($event)" formControlName="orderIdSearch" />
                  <mat-placeholder>Order ID</mat-placeholder>
                </mat-form-field> -->Order ID
                </th>
                <td mat-cell *matCellDef="let data">
                  <ng-container>
                    {{ data.defaultOrderId }}
                  </ng-container>
                </td>
              </ng-container>
              <ng-container matColumnDef="status">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                  <!-- <mat-form-field class="filter">
                  <input matInput (keypress)="numberOnly($event)" formControlName="statusSearch" />
                  <mat-placeholder>Status</mat-placeholder>
                </mat-form-field> -->Status
                </th>
                <td mat-cell *matCellDef="let data">
                  <ng-container>
                    {{ data.status }}
                  </ng-container>
                </td>
              </ng-container>

              <ng-container matColumnDef="soldDate">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                  <!-- <mat-form-field class="filter">
                  <input matInput formControlName="soldDateSearch" />
                  <mat-placeholder>Sold Date</mat-placeholder>
                </mat-form-field> -->Sold Date
                </th>
                <td mat-cell *matCellDef="let data">
                  <ng-container>
                    {{ data.soldDate | date: 'MM/dd/yyyy h:mm a':'en_US' }}
                  </ng-container>
                </td>
              </ng-container>
              <ng-container matColumnDef="Options">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Options</th>
                <td mat-cell *matCellDef="let data">
                  <a *ngIf="data.status == 'UnSold'" class="editBtnColor" (click)="ViewItem(data)">
                    <i class="fa fa-pencil editBtnColor mr-3" matTooltip="Click To Edit" ></i>
                  </a>
                  &nbsp;
                  <!-- <a class="deleteBtnColor" (click)="deleteItem(data)">
                    <i class="fa fa-trash-o" matTooltip="Click To Delete" ></i>
                  </a> -->
                </td>
              </ng-container>
              <ng-container matColumnDef="noRecords">
                <td class="text-align-center" mat-footer-cell *matFooterCellDef colspan="9">
                  <div *ngIf="!isShowSpinner">
                    {{"No Records Found"}}
                  </div>
                  <div [hidden]="!isShowSpinner">
                    <mat-spinner [diameter]="220"></mat-spinner>
                  </div>
                </td>
              </ng-container>
              <ng-container matColumnDef="paginator">
                <td mat-footer-cell *matFooterCellDef colspan="8">
                  <mat-paginator class="table table-responsive mat-table-class" #MatPaginator
                    [length]="dataSource?.data?.length" [pageSize]="10" [pageIndex]="0" showFirstLastButtons
                    [pageSizeOptions]="[5, 10, 25, 100]">
                  </mat-paginator>
                </td>
              </ng-container>
            </form>
            <tr mat-footer-row *matFooterRowDef="['noRecords']"
              [ngClass]="{ active: dataSource && dataSource?.data && dataSource?.data?.length !== 0 }"></tr>
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
            <tr mat-footer-row *matFooterRowDef="['paginator']"></tr>
          </table>
        </div>
      </div>
    </div>
  </div>

</div>
