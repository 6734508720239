import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { Title } from '@angular/platform-browser';
import { defaultGuid } from 'projects/shared/src/app/enums/allenums.enum';
import { PatientDocumentService } from 'projects/patient/src/app/patient-proxy/patient-optimization';
import { InboundDocumentService } from '../admin-proxy/platform-app-management/rcm/platform-management/inbound/inbound-document.service';

@Component({
  selector: 'app-view-document-check-list-tiff-viewer',
  templateUrl: './view-document-check-list-tiff-viewer.component.html',
  styleUrls: ['./view-document-check-list-tiff-viewer.component.scss'],
})
export class ViewDocumentCheckListTiffViewerComponent implements OnInit {
  defaultFaxId: string = '-';
  isShowSpinner:boolean = false;
  docId: string;
  defaultPatientId: string = '-';
  chartId: string = '-';
  patientName: string = '-';
  isLoading = false;
  subscription$: Subscription[] = [];
  blobName:string = '';
  strPdfString: string = '';
  isInbound:any;
  documentName:string = '';
  constructor(
    private activatedRoute: ActivatedRoute,
    private toastr: ToastrService,
    private title : Title,
    private patientDocumentService: PatientDocumentService,
    private inboundDocumentService: InboundDocumentService,
  ) {}

  ngOnInit(): void {
    this.title.setTitle('Qsecure | View Document Image');
    this.isLoading = true;
    const activatedRoute = this.activatedRoute.paramMap.subscribe(
      (response) => {
        this.docId = response.get('docId');
        this.isInbound = response.get('isInbound');
        this.isLoading = false;
      },
      (err) => {
        const data: HttpErrorResponse = err;
        this.toastr.error(data?.error?.error?.message, 'Error');
        this.isLoading = false;
      }
    );
    this.subscription$.push(activatedRoute);
  }

  ngAfterViewInit() {}

  ngOnDestroy(): void {
    this.subscription$?.forEach((sub) => {
      sub && sub?.unsubscribe();
    });
  }

  BindHearderText(event: any) {
    this.defaultPatientId = event?.defaultPatientId || '-';
    this.patientName = event?.fullName || '-';
    this.chartId = event?.chartId || '-';
    this.defaultFaxId = event?.defaultFaxId || '-';
    this.blobName = event?.blobName || '';
    // this.isInbound = event?.isInbound;
    // console.log(this.isInbound)
  }
  isEmpty(value: any): boolean {
    return (
      value === null ||
      value === undefined ||
      value === '' ||
      value === defaultGuid
    );
  }

  // #region Patient Document download
patientDocPdfDownload() {
  this.isShowSpinner = true;
  const handleResponse = (response: any) => {
      if (typeof response === 'string' && response) {
          this.strPdfString = String(response);
          // Decode base64 string to binary data
          const byteCharacters = atob(this.strPdfString);
          const byteNumbers = new Array(byteCharacters.length);
          for (let i = 0; i < byteCharacters.length; i++) {
              byteNumbers[i] = byteCharacters.charCodeAt(i);
          }
          const byteArray = new Uint8Array(byteNumbers);

          const blob = new Blob([byteArray], { type: "application/pdf" });
          const fileName = this.chartId + '-' + this.patientName;
          // Create a link element
          const link = document.createElement('a');
          link.href = window.URL.createObjectURL(blob);
          link.download = fileName;

          // Append the link to the body
          document.body.appendChild(link);

          // Programmatically click the link to trigger the download
          link.click();

          // Remove the link from the DOM
          document.body.removeChild(link);
      }
      this.isShowSpinner = false;
  };

  // if (this.isInbound === false || this.isInbound === undefined || this.isInbound === 'false') {
  //     this.patientDocumentService.getPatientDocumentByBlobIDByBlobName(this.blobName)
  //         .subscribe(handleResponse, (err: HttpErrorResponse) => {
  //             this.isShowSpinner = false;
  //             console.error('Error downloading the document', err);
  //         });
  // } else {
  //     this.inboundDocumentService.downloadInboundDocumentByBlobName(this.blobName)
  //         .subscribe(handleResponse, (err: HttpErrorResponse) => {
  //             this.isShowSpinner = false;
  //             console.error('Error downloading the document', err);
  //         });
  // }
  this.inboundDocumentService.getPatientDocumentByPathByDocumentId(this.docId)
  .subscribe(handleResponse, (err: HttpErrorResponse) => {
    this.isShowSpinner=false;
      console.error('Error downloading the document', err);
  });
}
viewPatientBlobPDF(){
  const url = '/#/patient_preview_pdf_document/' + this.docId + '/' + this.blobName + '/' + this.isInbound;
  window.open(url, '_blank');
}
}
