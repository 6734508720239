import { ListService } from '@abp/ng.core';
import { DatePipe } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Title } from '@angular/platform-browser';
import { ItemExportDatas, ItemExportDTO } from 'projects/patient/src/app/patient-proxy/patient/dto/models';
import { ExportType } from 'projects/patient/src/app/patient-proxy/patient/enumeration-data/export-type.enum';
import { ReportService } from 'projects/patient/src/app/patient-proxy/patient/report.service';
import { TableService } from 'projects/shared/src/app/table.service';
import { DateValidator } from 'projects/shared/src/app/validations/date-validator';
import { Subscription } from 'rxjs';
import Swal from 'sweetalert2';
import { ToastrService } from "ngx-toastr";
import { Status } from '../item-proxy/item-management/items';
import { CreateUpdateProductCategoryDTO, ProductCategorySearchDTO } from '../item-proxy/item-management/items/dto/models';
import { ProductCategoryService } from '../item-proxy/item-management/items/product-category.service';

@Component({
  selector: 'app-product-category',
  templateUrl: './product-category.component.html',
  styleUrls: ['./product-category.component.scss'],
  providers: [ListService, DatePipe]
})
export class ProductCategoryComponent implements OnInit , OnDestroy{

  productCategoryForm: FormGroup;
  productCategorySearchForm: FormGroup;
  categoryId: string = "";
  isLoading: boolean = false;
  isShowStatus: boolean = false;
  step: boolean = false;
  productCategoryTableData: any[] = [];
  status: string = '';
  tableStatus: string = '';
  subscription$: Subscription[] = [];
  dataSource: MatTableDataSource<any>;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  displayedColumns: string[] = [ 'productCategoryName', 'createdDate', 'status', 'options',];

  constructor(
    private formBuilder: FormBuilder,
    public title: Title,
    public list: ListService,
    public productCategoryService: ProductCategoryService,
    private datepipe: DatePipe,
    private dateValidator: DateValidator,
    private Table: TableService,
    private reportService: ReportService,
    private toastr: ToastrService,
  ) { }

  ngOnInit(): void {
    //Product Category Form & Validations
    this.productCategoryForm = this.formBuilder.group({
      txtProductCategoryName: new FormControl(null, Validators.required),
      txtProductCategoryStatus: new FormControl(""),
    });
    //Product Category Search Form
    this.productCategorySearchForm = this.formBuilder.group({
      txtProductCategoryName: new FormControl(null),
      txtProductCategoryStatus: new FormControl(""),
      productCategoryCreatedOn: new FormControl("", this.dateValidator.dateVaidator),
      txtStatus: new FormControl(""),
    });
    //Set Title
    this.title.setTitle("Qsecure | Product Category");

    //Get Table Search formcontrol values
    const ValueChanges = this.productCategorySearchForm.valueChanges.subscribe((value: ProductCategory) => {
      let filter: ProductCategorySearchDTO = {
        productCategoryName: value?.txtProductCategoryName != null || undefined ? value?.txtProductCategoryName.trim().toLowerCase() : value?.txtProductCategoryName,
        status: value?.txtStatus != null || undefined ? value?.txtStatus.trim().toLowerCase() : value?.txtStatus,
        createdDate: value?.productCategoryCreatedOn,
      };
      if (this.productCategorySearchForm.valid) {
        this.dataSource.filter = JSON.stringify(filter);
        this.searchProductCategory(filter);
      }
    });
    this.subscription$.push(ValueChanges);
    //
    const prodValueChanges = this.productCategoryForm.valueChanges.subscribe(() => {
      const txtProductCategoryName: AbstractControl = this.productCategoryForm?.get('txtProductCategoryName');
      if (txtProductCategoryName.dirty) {
        txtProductCategoryName?.value?.trim() === "" && txtProductCategoryName?.setErrors({ required: true })
        txtProductCategoryName?.markAsDirty();
        txtProductCategoryName?.markAsTouched();
      }
    })
    this.subscription$.push(prodValueChanges);
    //
    this.getTableData();
  }

  ngOnDestroy(): void {
    this.subscription$?.forEach((sub) => {
      sub && sub?.unsubscribe();
    });
  }

  //Save & update Product Category
  saveProductCategory() {
    let productCategoryDetails: CreateUpdateProductCategoryDTO = {
      productCategoryName: this.productCategoryForm.getRawValue().txtProductCategoryName,
      status: this.status === "Active" ? Status.Active : Status.Inactive
    }
    if ((this.categoryId === "" || this.categoryId === undefined || this.categoryId === null)) {
      this.productCategoryService.create(productCategoryDetails).subscribe(response => {
        this.categoryId = response.id;
        // Swal.fire({ title: 'Success', html: 'Saved Successfully', icon: 'success', timer: 3000, timerProgressBar: true });
        this.toastr.success('Saved Successfully','Success')
        this.getTableData();
        this.resetProductCategory();
      }, err => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      });
    }
    else {
      this.productCategoryService.update(this.categoryId, productCategoryDetails).subscribe(response => {
        // Swal.fire({ title: 'Success', html: 'Updated Successfully', icon: 'success', timer: 3000, timerProgressBar: true });
        this.toastr.success('Updated Successfully','Success')
        this.getTableData();
        this.resetProductCategory();
      }, err => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      });
    }
    this.categoryId = "";
  }


  //Get Table Datas
  getTableData() {
    this.isLoading = true;
    this.productCategoryTableData = [];
    const productCategoryList = query => this.productCategoryService.getList(query);
    const prodCategoryList = this.list.hookToQuery(productCategoryList).subscribe(response => {
      response && response?.items?.forEach((element, tableIndex) => {
        if (element) {
          this.productCategoryTableData.push({
            sno: tableIndex + 1,
            productCategoryName: element?.productCategoryName,
            productCategoryStatus: element?.status,
            createdDate: element?.creationTime === null ? "" : this.datepipe.transform(element?.creationTime, 'MM/dd/yyyy') ?? "",
            id: element?.id
          });
        }
      })
      this.isLoading = false;
      this.dataSource = new MatTableDataSource(this.productCategoryTableData);
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
    }, err => {
      const data: HttpErrorResponse = err;
      // Swal.fire({
      //   icon: 'error',
      //   text: data?.error?.error?.message,
      // });
      this.isLoading = false;
    });
    this.subscription$.push(prodCategoryList);
  }

  //View Product Category By Id
  ViewProductCategory(id: string) {
    this.isShowStatus = true;
    const prodCategoryGetList = this.productCategoryService.get(id).subscribe(response => {
      this.productCategoryForm.patchValue({
        txtProductCategoryName: response?.productCategoryName
      });
      this.categoryId = response.id;
      this.status = response.status;
    }, err => {
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    });
    this.subscription$.push(prodCategoryGetList);
  }

  //Delete Product Category By Id
  deleteProductCategory(id: string) {
    Swal.fire({
      title: 'Are you sure you want to Delete?',
      text: 'You won\'t be able to retrieve this data!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#34c38f',
      cancelButtonColor: '#f46a6a',
      confirmButtonText: 'Yes, delete it!'
    }).then(result => {
      if (result.value) {
        const prodCategoryDelete = this.productCategoryService.delete(id).subscribe(response => {
          this.productCategoryTableData.forEach(element => {
            if (id == element.id) {
              let index = this.productCategoryTableData.indexOf(element, 0)
              this.productCategoryTableData.splice(index, 1);
              // Swal.fire({ title: 'Success', html: 'Deleted Successfully', icon: 'success', timer: 3000, timerProgressBar: true });
              this.toastr.success('Deleted Successfully','Success')
            }
          });
          this.dataSource = new MatTableDataSource(this.productCategoryTableData);
          this.dataSource.sort = this.sort;
          this.dataSource.paginator = this.paginator;
          this.resetProductCategory();
        }, err => {
          const data: HttpErrorResponse = err;
          Swal.fire({
            icon: 'info',
            text: data?.error?.error?.message,
          });
        });
        this.subscription$.push(prodCategoryDelete);
      }
    });
  }

  //Search Product Category
  searchProductCategory(filter: ProductCategorySearchDTO) {
    this.productCategoryTableData = [];
    const searchProdCategory = this.productCategoryService.searchProductCategoryByInput(filter).subscribe(response => {
      response?.items.forEach((element, tableIndex) => {
        this.productCategoryTableData.push({
          sno: tableIndex + 1,
          productCategoryName: element?.productCategoryName,
          productCategoryStatus: element?.status,
          createdDate: element?.creationTime === null ? "" : this.datepipe.transform(element?.creationTime, 'MM/dd/yyyy') ?? "",
          id: element?.id
        });
      })
      this.dataSource = new MatTableDataSource(this.productCategoryTableData);
      this.dataSource.sort = this.sort;
      setTimeout(() => this.dataSource.paginator = this.paginator);
    })
    this.subscription$.push(searchProdCategory);
  }

  //Product Category Status change
  statusChange(event: MatSlideToggleChange, data: any) {
    if (event.checked == true) {
      Swal.fire({
        title: 'Do you want to activate?',
        text: 'By Changing Status, this Coverage Area will be activated',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#34c38f',
        cancelButtonColor: '#f46a6a',
        confirmButtonText: 'Yes, Activate it!'
      }).then(result => {
        if (result.value) {
          let productCategoryDetails: CreateUpdateProductCategoryDTO = {
            productCategoryName: data?.productCategoryName,
            status: Status.Active
          }
          if (data?.id && data?.id !== "" && data?.id !== null && data?.id !== undefined) {
            this.productCategoryService.update(data?.id, productCategoryDetails).subscribe(response => {
              // Swal.fire({ title: 'Success', html: 'Activated Successfully', icon: 'success', timer: 3000, timerProgressBar: true })
              this.toastr.success('Activated Successfully','Success')
              this.getTableData();
            }, (err) => {
              const data: HttpErrorResponse = err;
              Swal.fire({
                icon: 'info',
                text: data?.error?.error?.message,
              });
            })
          }
        }
        else {
          this.getTableData();
        }
      });
    }
    else
      if (event.checked == false) {
        Swal.fire({
          title: 'Do you want to deactivate?',
          text: 'By Changing Status, this Coverage Area will be deactivated',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#34c38f',
          cancelButtonColor: '#f46a6a',
          confirmButtonText: 'Yes, deactivate it!'
        }).then(result => {
          if (result.value) {
            let productCategoryDetails: CreateUpdateProductCategoryDTO = {
              productCategoryName: data?.productCategoryName,
              status: Status.Inactive
            }
            if (data?.id && data?.id !== "" && data?.id !== null && data?.id !== undefined) {
              this.productCategoryService.update(data?.id, productCategoryDetails).subscribe(response => {
                // Swal.fire({ title: 'Success', html: 'Deactivated Successfully', icon: 'success', timer: 3000, timerProgressBar: true })
                this.toastr.success('Deactivated Successfully','Success')
                this.getTableData();
              }, (err) => {
                const data: HttpErrorResponse = err;
                Swal.fire({
                  icon: 'info',
                  text: data?.error?.error?.message,
                });
              })
            }
          }
          else {
            this.getTableData();
          }
        });
      }
  }

  //  //Product Category Status change in table
  //  toggleChange(event: MatSlideToggleChange){
  //   this.tableStatus = event.checked == true ? Status.Active : Status.Inactive;
  //   this.tableStatus === Status.Active && this.productCategoryForm.controls['txtProductCategoryName'].enable();
  //   this.tableStatus === Status.Inactive &&  this.productCategoryForm.controls['txtProductCategoryName'].disable();
  // }

  //Reset Product Category Form
  resetProductCategory() {
    this.categoryId = "";
    this.isShowStatus = false;
    this.productCategoryForm.reset();
    this.productCategoryForm.markAsUntouched();
    this.productCategoryForm.enable();
    this.productCategoryForm.updateValueAndValidity();
    this.productCategoryForm.patchValue({
      txtProductCategoryName: " "
    });
  }

  //export excel
  exportTableData() {
    let datas: ItemExportDatas[] = []
    this.dataSource?.data?.forEach(element => {

      datas.push({
        categoryName: element?.productCategoryName,
        categoryCreatedDate: element?.createdDate === null ? "" : this.datepipe.transform(element?.createdDate, 'MM/dd/yyyy') ?? "",
        categoryStatus: element?.productCategoryStatus
      });
    })
    let itemExport: ItemExportDTO = {
      columns: ["Product Category Name", "Created Date", "Status"],
      datas: datas
    };
    const exportProdCategory = this.reportService.exportItemReportsByExportTypeAndExportDatas(ExportType.ProductCategoryReports, itemExport).subscribe(response => {
      if (response && response?.length !== 0) {
        let filelocation: String = String("data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64," + response);
        let link = document.createElement("a");
        link.download = ("Product-Category") + ".xlsx";
        link.href = filelocation.toString();
        link.click();
        // Swal.fire({ title: 'Success', html: 'Exported Successfully', icon: 'success', timer: 3000, timerProgressBar: true })
        this.toastr.success('Exported Successfully','Success')
      }
    }, err => {
      const data: HttpErrorResponse = err;
      let errorMesg: string = data?.error;
      if (errorMesg?.includes('Sorry!! There is no data in table to export')) {
        errorMesg = 'Sorry!! There is no data in table to export';
      }
      else {
        errorMesg = data?.error?.error?.message;
      }
      // Swal.fire({
      //   icon: 'info',
      //   text: errorMesg
      // });
      this.toastr.info(errorMesg)
    });
    this.subscription$.push(exportProdCategory);
  }
}

export interface ProductCategory {
  txtProductCategoryName: string;
  txtStatus: string;
  productCategoryCreatedOn: string;

}
