import { mapEnumToOptions } from '@abp/ng.core';
// Old 
// export enum DisapprovedStatus {
//   Description = 0,
//   FaceToFace = 1,
//   SleepStudy = 2,
//   Prescription = 3,
//   DownloadReport = 4,
//   InsuranceIssue = 5,
//   Other = 6,
// }

// New Proxy
export enum DisapprovedStatus {
  FaceToFace = 0,
  SleepStudy = 1,
  Prescription = 2,
  DownloadReport = 3,
  InsuranceIssue = 4,
  Other = 5,
}

// function mapEnumToReadableOptions(enumObject: any): { key: string, value: number }[] {
//   return Object.keys(enumObject)
//     .filter(key => isNaN(Number(key))) // Filter out numeric keys
//     .map(key => ({
//       key: key.replace(/([a-z])([A-Z])/g, '$1 $2'), // Add spaces before capital letters
//       value: enumObject[key]
//     }));
// }

function mapEnumToReadableOptions(enumObject: any): {
  displayName: string; key: string, value: number 
}[] {
  return Object.keys(enumObject)
    .filter(key => isNaN(Number(key))) // Filter out numeric keys
    .map(key => ({
      displayName: key.replace(/([a-z])([A-Z])/g, '$1 $2'), // Add spaces before capital letters
      key: key, // Add spaces before capital letters
      value: enumObject[key]
    }))
    .sort((a, b) => a.key.localeCompare(b.key)); // Sort alphabetically by key
}

// export const disapprovedStatusOptions = mapEnumToOptions(DisapprovedStatus);

export const disapprovedStatusOptions = mapEnumToReadableOptions(DisapprovedStatus);
