<table
  datatable
  [dtOptions]="dtShippingOptions"
  class="row-border hover w-100 display"
>
  <thead>
    <tr>
      <th class="text-center"><mat-icon>apps</mat-icon></th>
      <th [matTooltip]="'Location'">Location</th>
      <th [matTooltip]="'Ticket Id'" class="text-right">Ticket Id</th>
      <th [matTooltip]="'Chart No'">Chart No</th>
      <th [matTooltip]="'Name'">Name</th>
      <th [matTooltip]="'Insurance/Cash'">Insurance/Cash</th>
      <th [matTooltip]="'Supplies Date'">Supplies Date</th>
      <th [matTooltip]="'Disapproved By'">Disapproved By</th>
      <th [matTooltip]="'Disapproved On'">Disapproved On</th>
    </tr>
  </thead>
  <tbody>
    <tr
      [ngClass]="
        this.tblShpSelectedRowId === data.orderId ? 'row-highlighted' : ''
      "
      (click)="tblShpSelectedRow(data.orderId, data)"
      *ngFor="let data of tableData"
    >
      <td
        class="dt-control text-center"
        (click)="handleTableCellClick($event, data.shippingDisapproveNotes)"
      ></td>
      <td [matTooltip]="data?.state || ''">
        {{ data.state }}
      </td>
      <td [matTooltip]="data?.mmRefereceOrderId || '-'" class="text-right">
        <a
          class="preview-link"
          [class.disabled]="isEmpty(data.chartId) || isEmpty(data.patientId)"
          [routerLink]="
            '/orders/saleOrder/' +
            data.patientId +
            '/' +
            data.orderId +
            '/' +
            '0'
          "
          target="_blank"
          >{{ data?.mmRefereceOrderId ? data?.mmRefereceOrderId : "-" }}
        </a>
      </td>
      <td [matTooltip]="data?.chartno || '-'">
        <a
          class="preview-link"
          [class.disabled]="isEmpty(data.chartId) || isEmpty(data.patientId)"
          [routerLink]="
            '/patientCreateEditTab/' + data.patientId
          "
          target="_blank"
          >{{ data?.chartno ? data?.chartno : "-" }}
        </a>
      </td>
      <td [matTooltip]="data?.fullName || '-'">{{ data.fullName }}</td>
      <td
        [matTooltip]="
          data.isCashOrder
            ? 'Cash'
            : data.priPlanName === null || data.priPlanName === ''
            ? '-'
            : data.priPlanName
        "
      >
        <!-- {{ data.priPlanName }} -->

        {{
          data.isCashOrder
            ? "Cash"
            : data.priPlanName === null || data.priPlanName === ""
            ? "-"
            : data.priPlanName
        }}
      </td>
      <td [matTooltip]="data?.suppliesdate">
        {{ data?.suppliesdate }}
      </td>
      <td [matTooltip]="data?.disapprovedBy || '-'">
        {{ data.disapprovedBy }}
      </td>
      <td [matTooltip]="data?.disapproveddate">
        {{ data?.disapproveddate }}
      </td>
    </tr>
  </tbody>
  <!-- <tfoot>
    <tr>
      <th></th>
      <th>
        <input
          matInput
          class="textbox-border-class"
          type="text"
          placeholder="Location"
        />
      </th>
      <th>
        <input
          matInput
          class="textbox-border-class"
          type="text"
          placeholder="Ticket Id"
        />
      </th>
      <th>
        <input
          matInput
          class="textbox-border-class"
          type="text"
          placeholder="Chart No"
        />
      </th>
      <th>
        <input
          matInput
          class="textbox-border-class"
          type="text"
          placeholder="Name"
        />
      </th>
      <th>
        <input
          matInput
          class="textbox-border-class"
          type="text"
          placeholder="Insurance"
        />
      </th>
      <th>
        <input
          matInput
          class="textbox-border-class"
          type="text"
          placeholder="Supplies Date"
        />
      </th>
      <th>
        <input
          matInput
          class="textbox-border-class"
          type="text"
          placeholder="Disapproved By"
        />
      </th>
      <th>
        <input
          matInput
          class="textbox-border-class"
          type="text"
          placeholder="Disapproved On"
        />
      </th>
    </tr>
  </tfoot> -->
</table>
