import { RestService } from '@abp/ng.core';
import { Injectable } from '@angular/core';
import type { CreateUpdateInitialPatientDocumentDTO, InitialPatientDocumentDTO } from '../patient/models';

@Injectable({
  providedIn: 'root',
})
export class FaxBinService {
  apiName = 'Default';

  initialFileUploadByInput = (input: CreateUpdateInitialPatientDocumentDTO) =>
    this.restService.request<any, InitialPatientDocumentDTO>({
      method: 'POST',
      url: '/api/app/patientDocument/initialFileUpload',
      body: input,
    },
     { apiName: this.apiName, skipHandleError: true });

  constructor(private restService: RestService) {}
}
