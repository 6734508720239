import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, Input, OnInit } from '@angular/core';
import {
  FormGroup,
  FormControl,
  FormBuilder,
  Validators,
} from '@angular/forms';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { RegionDropdownService } from 'projects/patient/src/app/dropdown-proxy/dropdown-management/dropdowns/region-dropdown-service/region-dropdown.service';
import {
  RegionCountriesDTO,
  RegionStatesDTO,
  RegionCitiesDTO,
} from 'projects/patient/src/app/dropdown-proxy/dropdown-management/dropdowns/dto';
import {
  DiagnosisCode10DTO,
  DiagnosisCode9DTO,
  GenderDTO,
  PatientMasterDropdownDTO,
  PayorLevelDTO,
  PlaceOfServiceDTO,
  RelationShipWithoutSelfDTO,
  RelationShipWithSelfDTO,
  SuffixDTO,
} from 'projects/patient/src/app/dropdown-proxy/dropdown-management/dropdowns/dto/patient';
import { planInterface } from 'projects/patient/src/app/patient-add-policy/patient-add-policy.component';
import { from, Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { ClaimProcessService } from '../claim-proxy/claim-processing-management/rcm/claim-processing-management/x12';
import {
  ClaimBatchProcessRequestDTO,
  ClaimDetailsDTO,
  InsuredDetailDTO,
  PatientDetailsDTO,
  PayerDetailsDTO,
  ProviderDetailsDTO,
  ReferringProviderDetailsDTO,
  RenderingingProviderDetailsDTO,
} from '../claim-proxy/claim-processing-management/rcm/claim-processing-management/x12/dto/models';
import { claimProcessMethod } from '../billing-proxy/billing-management/billing/claim-process-method.enum';
import { ClaimInformationsService } from '../billing-proxy/billing-management/posting';
import { ClaimDTO } from '../billing-proxy/billing-management/posting/dto/models';
import { PatientDropdowns } from 'projects/patient/src/app/dropdown-proxy/dropdown-management/dropdowns/patient-dropdowns.enum';
import { PatientMasterDropdownService } from 'projects/patient/src/app/dropdown-proxy/dropdown-management/dropdowns/master-dropdown-service';
import { ListService, PagedAndSortedResultRequestDto } from '@abp/ng.core';
import { MasterProviderService } from 'projects/admin/src/app/warehouse-proxy/inventory';
import {
  DoctorService,
  PatientService,
  PayerService,
} from 'projects/patient/src/app/patient-proxy/patient';
import { PostingItemPopupComponent } from '../posting-item-popup/posting-item-popup.component';
import { MasterProviderDTO } from 'projects/order/src/app/order-proxy/order-management/inventory/dto/models';
import { defaultCountry } from 'projects/shared/src/app/enums/allenums.enum';
import {
  PatientDTO,
  PersonalDTO,
  PolicyDTO,
} from 'projects/patient/src/app/patient-proxy/patient/dto';

@Component({
  selector: 'app-claim-view-report',
  templateUrl: './claim-view-report.component.html',
  styleUrls: ['./claim-view-report.component.scss'],
  providers: [ListService],
})
export class ClaimViewReportComponent implements OnInit {
  saveButtonHide: boolean = false;
  patientDetailsForm: FormGroup;
  totalValue: any;
  messageValue: any;
  finalValue: any;
  strClaimAddress: string = '';
  BirthdateMaxDate: Date;
  BirthdateMinDate: Date;
  suffixName: string = '';
  gender: string = '';
  drpGenderLoop: GenderDTO[] = [];
  drpPlan: planInterface[] = [];
  drpProviders: MasterProviderDTO[] = [];
  phoneResCode: string = '';
  ResponsibleCountry: string = '';
  ResponsibleState: string = '';
  ResponsibleCity: string = '';
  BillingCountry: string = '';
  BillingState: string = '';
  BillingCity: string = '';
  payor: string = '';
  provider: string = '';
  relationship: string = '';
  RenderingDoctor: string = '';
  ReferringDoctor: string = '';
  txtIcd9: string[] = [];
  totalItemQty: string = '';
  totalItemPrice: string = '';
  txtIcd10: string[] = [];
  relationShipWithoutSelves: RelationShipWithoutSelfDTO[] = [];
  generalPlaceOfService: string = '';
  drpEmerCountry: RegionCountriesDTO[] = [];
  drpResCountry: RegionCountriesDTO[] = [];
  drpEmerStates: RegionStatesDTO[] = [];
  drpEmerCities: RegionCitiesDTO[] = [];
  drpResStates: RegionStatesDTO[] = [];
  countryPhoneCode: string = '';
  drpResCities: RegionCitiesDTO[] = [];
  Country: string = '';
  responsibleRelationship: RelationShipWithSelfDTO[] = [];
  drpPlaceOfSerivce: PlaceOfServiceDTO[] = [];
  drpSuffixes: SuffixDTO[] = [];
  filterPayor:
    | Observable<{ id: string; name: string; payerCode: string }[]>
    | undefined;
  public filteredInsuranceTypes: Observable<planInterface[]> | undefined;
  drpDiagnosisICD9: DiagnosisCode9DTO[] = [];
  drpDiagnosisICD10: DiagnosisCode10DTO[] = [];
  public filteredIcd9Types: Observable<any[]>;
  public filteredIcd10Types: Observable<any[]>;
  orderStatus: boolean = true;
  public filteredResponsibleCountries:
    | Observable<RegionCountriesDTO[]>
    | undefined;
  public filteredResponsibleStates: Observable<RegionStatesDTO[]> | undefined;
  public filteredResponsibleCities: Observable<RegionCitiesDTO[]> | undefined;
  public filteredBillingCountries: Observable<RegionCountriesDTO[]> | undefined;
  public filteredBillingStates: Observable<RegionStatesDTO[]> | undefined;
  public filteredBillingCities: Observable<RegionCitiesDTO[]> | undefined;

  public filteredRenDoctorTypes: Observable<doctorDTO[]> | undefined;
  public filteredRefDoctorTypes: Observable<doctorDTO[]> | undefined;
  claimId: string = '';
  payorLevel: PayorLevelDTO[] = [];
  drpPayerList: { id: string; name: string; payerCode: string }[] = [];
  payorlevel: string = '';
  drpDoctor: doctorDTO[] = [];
  tblPostReponse: any[] = [];
  claimResponse: ClaimDTO = null;
  selectedProvider: MasterProviderDTO = null;
  policyNo: string;
  ptName: string;
  ptAddress: string;
  ptZipCode: string;
  ptDob: string;
  public filteredPayerTypes:
    | Observable<
      {
        displayName?: string;
        payerId?: string;
        payerName?: string;
        id?: string;
      }[]
    >
    | undefined;
  strPayers: string = '';
  drpPayers: {
    displayName?: string;
    payerId?: string;
    payerName?: string;
    id?: string;
  }[] = [];
  @Input() patientId: string = '';

  constructor(
    private formBuilder: FormBuilder,
    private claimProcessService: ClaimProcessService,
    private claimInformationsService: ClaimInformationsService,
    private regionDropdownService: RegionDropdownService,
    private patientDropdownService: PatientMasterDropdownService,
    private providerList: MasterProviderService,
    private list: ListService,
    private region: RegionDropdownService,
    private payerService: PayerService,
    private doctorService: DoctorService,
    private dialog: MatDialog,
    private patientService: PatientService,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      claimId: string;
      ClaimDetails: any[];
      patientId: string;
    }
  ) { }

  ngOnInit(): void {
    this.patientId = this.data?.patientId ?? "";
    this.claimId = this.data?.claimId ?? '';
    this.data.ClaimDetails = this.data?.ClaimDetails ?? null;

    this.BirthdateMinDate = new Date();
    this.BirthdateMinDate.setFullYear(this.BirthdateMinDate.getFullYear() - 120);
    this.patientDetailsForm = this.formBuilder.group({
      chkMedicare: new FormControl(''),
      chkMedicaid: new FormControl(''),
      chkChampva: new FormControl(''),
      chkTrciare: new FormControl(''),
      chkGroupPlan: new FormControl(''),
      chkFeca: new FormControl(''),
      chkOther: new FormControl(''),
      chkAutoAccident: new FormControl(''),
      txtReservedForNucc: new FormControl(''),
      txtReservedNucc: new FormControl(''),
      txtInsrdFullName: new FormControl('', Validators.required),
      txtPolicyGroup: new FormControl(''),
      txtOtherInsuredPolicy: new FormControl(''),
      txtInsPlanName: new FormControl(''),
      txtInsAddress: new FormControl(''),
      chkEmployment: new FormControl(''),
      chkOutsideLab: new FormControl(''),
      chkOtherAccident: new FormControl(''),
      chkClaimCodes: new FormControl(''),
      chkEin: new FormControl(''),
      chkSsn: new FormControl(''),
      txtSigned: new FormControl(''),
      txtDate: new FormControl(''),
      txtFromDate: new FormControl(''),
      txtToDate: new FormControl(''),
      txt17a: new FormControl(''),
      txtCharges: new FormControl(''),
      txtHosFromDate: new FormControl(''),
      txtHosToDate: new FormControl(''),
      txtAdditionalClaimInfo: new FormControl(''),
      txtIcdTen1: new FormControl(''),
      txtIcdTen2: new FormControl(''),
      txtIcdTen3: new FormControl(''),
      txtIcdTen4: new FormControl(''),
      txtIcdTen5: new FormControl(''),
      txtIcdTen6: new FormControl(''),
      txtIcdTen7: new FormControl(''),
      txtIcdTen8: new FormControl(''),
      txtIcdTen9: new FormControl(''),
      txtIcdTen10: new FormControl(''),
      txtIcdTen11: new FormControl(''),
      txtIcdTen12: new FormControl(''),
      txtResubmissionCode: new FormControl(''),
      txtOriginalRefNo: new FormControl(''),
      txtFederalTaxIdNumber: new FormControl(''),
      txtPriorNumber: new FormControl(''),
      txtPtAccountNumber: new FormControl(''),
      chkAcceptAssignment: new FormControl(''),
      txtTotalCharge: new FormControl('', Validators.required),
      txtAmountPaid: new FormControl(''),
      txtRsvd: new FormControl(''),
      txtPhysicianSigned: new FormControl(''),
      txtPhysicianSignedDate: new FormControl(''),
      txtServiceInfo: new FormControl('', Validators.required),
      txtServiceInfoA: new FormControl(''),
      txtServiceInfoB: new FormControl(''),
      txtBillingProvider: new FormControl(''),
      txtBillingProviderA: new FormControl(''),
      txtBillingProviderB: new FormControl(''),
      txtFullName: new FormControl('', Validators.required),
      txtFirstName: new FormControl('', Validators.required),
      txtMiddleName: new FormControl(''),
      txtLastName: new FormControl('', Validators.required),
      drpSuffix: new FormControl(''),
      drpGender: new FormControl('', Validators.required),
      txtTelephone: new FormControl(''),
      txtInsTelephone: new FormControl(''),
      drpCounty: new FormControl(''),
      txtBillingPostalCode: new FormControl('', Validators.required),
      txtBillingPhone: new FormControl(''),
      txtAddress: new FormControl('', Validators.required),
      txtSSN: new FormControl(''),
      txtBillingState: new FormControl(null, Validators.required),
      txtBillingCountry: new FormControl(null),
      txtBillingCity: new FormControl(null, Validators.required),
      txtBillingCountryFilter: new FormControl(''),
      txtBillingStateFilter: new FormControl(''),
      txtBillingCityFilter: new FormControl(''),
      txtInsBirthdate: new FormControl(''),
      drpInsGender: new FormControl(''),
      txtResponsibleCity: new FormControl(null),
      txtResponsibleState: new FormControl(null),
      txtRespPostalCode: new FormControl(null),
      txtResponsibleCountry: new FormControl(null),
      txtResponsibleCountryFilter: new FormControl(''),
      txtResponsibleStateFilter: new FormControl(''),
      txtResponsibleCityFilter: new FormControl(''),
      drpPayerLevel: new FormControl('', Validators.required),
      txtInsuredPolicyId: new FormControl('', Validators.required),
      txtProvider: new FormControl('', Validators.required),
      drpDiag9: new FormControl(''),
      txtIcd9Filter: new FormControl(''),
      drpDiag10ID: new FormControl('', Validators.required),
      txtIcd10Filter: new FormControl(''),
      txtBirthdate: new FormControl('', Validators.required),
      drpResponsibleRelationshipType: new FormControl('', Validators.required),
      drpGeneralPlaceOfService: new FormControl('', Validators.required),
      txtRenderingDoctor: new FormControl(''),
      txtReferringDoctor: new FormControl('', Validators.required),
      txtRenDoctorFilter: new FormControl(''),
      txtRefDoctorFilter: new FormControl(''),
      txtPolicyInfoPayor: new FormControl(null, Validators.required),
      txtPayerFilter: new FormControl(''),
      txtCurrentIllnessDate: new FormControl(''),
      txtOtherDate: new FormControl(''),
    });
    this.getDropdown();

    //patientGet
    if (this.patientId && this.patientId !== '') {
      this.patientService.get(this.patientId).subscribe((response) => {
        const primaryInsuranceVerifies =
          response?.insuranceVerifications?.primaryInsuranceVerifies;
        this.patientDetailsForm.patchValue({
          txtInsPlanName: primaryInsuranceVerifies?.planName ?? '',
        });
      });
    }

  }
  valueChange(value: any) { }
  // get dropdown
  getDropdown() {
    this.drpDiagnosisICD9 = [];
    this.drpDiagnosisICD10 = [];
    this.drpProviders = [];
    const patientDropdownInput: PatientDropdowns[] = [
      PatientDropdowns?.DiagnosisCode9,
      PatientDropdowns?.DiagnosisCode10,
      PatientDropdowns?.PayorLevels,
      PatientDropdowns?.Genders,
      PatientDropdowns?.RelationShipWithSelf,
      PatientDropdowns?.Suffixes,
      PatientDropdowns?.InsuranceTypes,
      PatientDropdowns?.PayorLevels,
      PatientDropdowns.PlaceOfServices,
      PatientDropdowns.Suffixes,
    ];
    this.patientDropdownService
      .getPatientDropdownsByInput(patientDropdownInput)
      .subscribe((stateResponse) => {
        let response: PatientMasterDropdownDTO = stateResponse;
        this.drpDiagnosisICD9 = response?.diagnosisCodes9 ?? [];
        this.drpDiagnosisICD10 = response?.diagnosisCodes10 ?? [];
        this.payorLevel = response?.payorLevels ?? [];
        this.drpSuffixes = response?.suffixes ?? [];
        this.drpGenderLoop = response?.genders ?? [];
        this.responsibleRelationship = response?.relationShipWithSelves ?? [];
        this.drpPlaceOfSerivce = response?.placeOfServices ?? [];

        this.filteredIcd9Types = this.patientDetailsForm
          .get('txtIcd9Filter')
          .valueChanges.pipe(
            startWith(''),
            map((value) =>
              this.drpDiagnosisICD9?.filter((option) =>
                option?.diagnosisCodeTypeData
                  ?.toLowerCase()
                  ?.includes(value?.toLowerCase() ?? '')
              )
            )
          );
        this.filteredIcd10Types = this.patientDetailsForm
          .get('txtIcd10Filter')
          .valueChanges.pipe(
            startWith(''),
            map((value) =>
              this.drpDiagnosisICD10?.filter((option) =>
                option?.diagnosisCodeTypeData
                  ?.toLowerCase()
                  ?.includes(value?.toLowerCase() ?? '')
              )
            )
          );
        this.providerList
          .getList(new PagedAndSortedResultRequestDto())
          .subscribe((response) => {
            response?.items?.forEach((element) => {
              this.drpProviders?.push({
                id: element?.id,
                shortCodeId: element?.shortCodeId,
                fullName: element?.fullName,
                npi: element?.npi,
                fax: element?.fax,
                homeAddress: element?.homeAddress,
                phone: element?.phone,
                state: element?.state,
                zip: element?.zip,
                city: element?.city,
                isActive: element?.isActive,
                isAppoinments: element?.isAppoinments,
                isBillingProvider: element?.isBillingProvider,
                isSignatureOnFile: element?.isSignatureOnFile,
                isRenderingProvider: element?.isRenderingProvider,
                description: element?.description,
                mmTaxId: element?.mmTaxId,
                prefixCode: '',
              });
            });
            this.claimId && this.getClaimInfoByClaimId(this.claimId);
          });
      });

    this.payerService.getList(new PagedAndSortedResultRequestDto()).subscribe(
      (response) => {
        this.drpPayers = [];
        response?.items?.forEach((element) => {
          this.drpPayers?.push({
            id: element?.id ?? '',
            displayName: element?.payerId + ' / ' + element?.payerName,
            payerId: element?.payerId ?? '',
            payerName: element?.payerName,
            // payorId: element?.shortCodeId
          });
        });
        this.filteredPayerTypes = this.patientDetailsForm
          .get('txtPayerFilter')
          ?.valueChanges.pipe(
            startWith(''),
            map((value) =>
              this.drpPayers?.filter((option?: any) =>
                option?.displayName
                  ?.toLowerCase()
                  ?.includes(value?.toLowerCase() ?? '')
              )
            )
          );
      },
      (err) => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      }
    );

    this.region
      .getAllCountriesByInput(new PagedAndSortedResultRequestDto())
      .subscribe(
        (response) => {
          this.drpEmerCountry = response?.items ?? [];
          this.drpResCountry = response?.items ?? [];

          this.filteredResponsibleCountries = this.patientDetailsForm
            .get('txtResponsibleCountryFilter')
            .valueChanges.pipe(
              startWith(''),
              map((value) =>
                this.drpResCountry.filter((option) =>
                  option?.countryName
                    ?.toLowerCase()
                    ?.includes(value?.toLowerCase() ?? '')
                )
              )
            );
        },
        (err) => {
          const data: HttpErrorResponse = err;
          Swal.fire({
            icon: 'info',
            text: data?.error?.error?.message,
          });
        }
      );
    this.payerService.getList(new PagedAndSortedResultRequestDto()).subscribe(
      (response) => {
        this.drpPayerList = [];
        response?.items?.forEach((element) => {
          this.drpPayerList.push({
            id: element?.id ?? '',
            name: element?.payerId + ' / ' + element?.payerName,
            payerCode: element?.payerId ?? '',
          });
        });
        this.filterPayor = this.patientDetailsForm
          .get('txtPayerFilter')
          ?.valueChanges.pipe(
            startWith(''),
            map((value) =>
              this.drpPayerList?.filter((option) =>
                option?.name
                  ?.toLowerCase()
                  ?.includes(value?.toLowerCase() ?? '')
              )
            )
          );
      },
      (err) => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      }
    );
    this.drpDoctor = [];
    this.doctorService
      .getList(new PagedAndSortedResultRequestDto())
      .pipe(map((e) => e.items))
      .subscribe(
        (response) => {
          response?.forEach((element) => {
            this.drpDoctor.push({
              fLname:
                element?.firstName ??
                '' + ' ' + element?.middleName ??
                '' + ' ' + element?.lastName ??
                '',
              LTname: element?.lastName ?? '',
              Npi: element?.npiNumber,
              mdName: element?.middleName,
              fTname:
                element['npiNumber'] +
                ' / ' +
                element?.firstName +
                ' ' +
                element?.middleName +
                ' ' +
                element?.lastName,

              id: element?.id ?? '',
            });
          });
          this.filteredRenDoctorTypes = this.patientDetailsForm
            .get('txtRenDoctorFilter')
            ?.valueChanges.pipe(
              startWith(''),
              map((value) =>
                this.drpDoctor?.filter((option) =>
                  option?.fLname
                    ?.toLowerCase()
                    ?.includes(value?.toLowerCase() ?? '')
                )
              )
            );
          this.filteredRefDoctorTypes = this.patientDetailsForm
            .get('txtRefDoctorFilter')
            ?.valueChanges.pipe(
              startWith(''),
              map((value) =>
                this.drpDoctor?.filter((option) =>
                  option?.fLname
                    ?.toLowerCase()
                    ?.includes(value?.toLowerCase() ?? '')
                )
              )
            );
        },
        (err) => {
          const data: HttpErrorResponse = err;
          Swal.fire({
            icon: 'info',
            text: data?.error?.error?.message,
          });
        }
      );
  }

  /// view item page
  ViewPosting(data: any) {
    const dialogRef = this.dialog.open(PostingItemPopupComponent, {
      disableClose: true,
      data: { data: data },
    });
    dialogRef.afterClosed().subscribe(
      () => {
        this.claimId && this.getClaimInfoByClaimId(this.claimId);
      },
      (err) => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      }
    );
  }

  onPolicyNoChange(searchValue: string): void {
    this.policyNo = searchValue;
  }
  onPtNameChange(searchValue: string): void {
    this.ptName = searchValue;
  }
  onPtAddressChange(searchValue: string): void {
    this.ptAddress = searchValue;
  }
  onPtZipCodeChange(searchValue: string): void {
    this.ptZipCode = searchValue;
  }
  onPtDobChange(searchValue: string): void {
    this.ptDob = searchValue;
  }

  sPtMiddleName: string = '';
  // get Claim Info details By Claim Id
  private getClaimInfoByClaimId(claimId: string) {
    this.claimInformationsService.get(claimId).subscribe(
      (response) => {
        this.selectedProvider =
          this.drpProviders?.filter(
            (v) =>
              v?.shortCodeId ===
              response?.saleOrder?.patients?.personals?.providerId
          )[0] ?? null;
        const claimResponse: ClaimDTO = response;
        const patientData: PatientDTO =
          claimResponse?.saleOrder?.patients ?? null;

        const patPersonal: PersonalDTO = patientData?.personals ?? null;
        this.claimResponse = response;
        const policyDetails: PolicyDTO = patientData?.policies?.primaryPolicy;

        const referDoctor: doctorDTO =
          this.drpDoctor?.filter(
            (e) => e?.fTname === patientData?.clinicals?.referringProviderDoctor
          )[0] ?? null;

        patPersonal.middleName = patPersonal?.middleName ?? '';
        if (patPersonal?.middleName != '') {
          this.sPtMiddleName = ',' + patPersonal?.middleName;
        }


        this.selectedProvider.homeAddress =
          this.selectedProvider?.homeAddress ?? '';
        this.selectedProvider.city = this.selectedProvider?.city ?? '';
        this.selectedProvider.state = this.selectedProvider?.state ?? '';
        this.selectedProvider.zip = this.selectedProvider?.zip ?? '';
        const providerAddress =
          this.selectedProvider?.homeAddress != ''
            ? ',' + this.selectedProvider?.homeAddress
            : '';
        const providerCity =
          this.selectedProvider?.city != ''
            ? ',' + this.selectedProvider?.city
            : '';
        const providerState =
          this.selectedProvider?.state != ''
            ? ',' + this.selectedProvider?.state
            : '';
        const providerZipCode =
          this.selectedProvider?.zip != ''
            ? ',' + this.selectedProvider?.zip
            : '';
        const icd10Code: string[] =
          patientData?.clinicals?.diagnosisCode10 ?? [];
        this.strClaimAddress =
          patientData?.insuranceVerifications?.primaryInsuranceVerifies
            ?.claimAddress ?? '';

        this.patientDetailsForm.patchValue({
          txtFullName:
            `${patPersonal?.lastName ?? ''}` +
            ', ' +
            `${patPersonal?.firstName ?? ''}` +
            `${this.sPtMiddleName ?? ''}`,
          txtFirstName: patPersonal?.firstName,
          txtMiddleName: patPersonal?.middleName,
          txtLastName: patPersonal?.lastName,
          drpSuffix: patPersonal?.suffix,
          drpGender: patientData?.clinicals?.genderType,
          drpCounty: patientData?.addresses[0]?.county,
          txtBillingPostalCode: patientData?.addresses[0]?.postalCode,
          txtBillingPhone: patientData?.addresses[0]?.phone,
          txtAddress: patientData?.addresses[0]?.address,
          txtAddress2: patientData?.addresses[0]?.address,
          txtResponsibleCountry: patientData?.addresses[0]?.country,
          txtSSN: patPersonal?.ssn,
          txtPolicyGroup:
            patientData?.insuranceVerifications?.primaryInsuranceVerifies
              ?.groupName ?? '',
          drpPayerLevel: policyDetails?.payorLevel ?? "",
          txtInsuredPolicyId: policyDetails?.policyNo ?? "",
          txtPolicyInfoPayor: policyDetails?.payorId ?? "",
          txtInsrdFullName: `${policyDetails?.lastName ?? ''}, ${policyDetails?.fisrtName ?? ''}, ${policyDetails?.middleName ?? ''}`,
          txtInsBirthdate: policyDetails?.birthdate ?? "",
          drpInsGender: policyDetails?.gender ?? "",
          txtInsAddress: policyDetails?.address1 ?? "",
          txtRespPostalCode: policyDetails?.postalCode ?? "",
          txtInsPlanName: policyDetails?.payorId ?? '',
          txtProvider: patPersonal?.providerId ?? "",
          drpDiag9: patientData?.clinicals?.diagnosisCode9 ?? "",
          drpDiag10ID: patientData?.clinicals?.diagnosisCode10 ?? "",
          txtBirthdate: patPersonal?.dateOfBirth ?? "",
          txtServiceInfo: claimResponse?.saleOrder?.orders?.placeOfServiceId ?? "",
          drpResponsibleRelationshipType:
            patientData?.contacts[0]?.relationshipId ?? "",
          drpGeneralPlaceOfService:
            claimResponse?.saleOrder?.orders?.placeOfServiceId ?? "",
          txtReferringDoctor: referDoctor?.id ?? "",
          txtRenderingDoctor: patientData?.clinicals?.renderingProviderDoctor ?? "",
          txtFederalTaxIdNumber: this.selectedProvider?.mmTaxId ?? "",
          chkSsn: policyDetails?.alwaysCrossover === 1,
          chkEin: policyDetails?.alwaysCrossover === 0,
          txtPtAccountNumber: patPersonal?.accountNumber ?? "",
          txtPriorNumber:
            patientData?.authorizations?.primaryAuthorizations?.authId ?? "",
          txtTotalCharge:
            '$' + claimResponse?.saleOrder?.shippingCheckList?.totalItemPrice ?? "",
          txtPhysicianSigned: this.selectedProvider?.fullName ?? "",
          txtDate:
            claimResponse?.saleOrder?.items[
              claimResponse?.saleOrder?.items.length - 1
            ]?.originalDos ?? "",
          txtPhysicianSignedDate:
            claimResponse?.saleOrder?.items[
              claimResponse?.saleOrder?.items.length - 1
            ]?.originalDos ?? "",
          txtBillingProvider:
            this.selectedProvider?.fullName +
            providerAddress +
            providerCity +
            providerState +
            providerZipCode,
          txtBillingProviderA: this.selectedProvider?.npi,
          txtIcdTen1:
            icd10Code?.length > 1 || icd10Code?.length == 1
              ? this.drpDiagnosisICD10?.filter(
                (x) => x?.diagnosisCode10ShortCodeId === icd10Code[0]
              )[0]?.diagnosisCode
              : '',
          txtIcdTen2:
            icd10Code?.length > 2 || icd10Code?.length == 2
              ? this.drpDiagnosisICD10?.filter(
                (x) => x?.diagnosisCode10ShortCodeId === icd10Code[1]
              )[0]?.diagnosisCode
              : '',
          txtIcdTen3:
            icd10Code?.length > 3 || icd10Code?.length == 3
              ? this.drpDiagnosisICD10?.filter(
                (x) => x?.diagnosisCode10ShortCodeId === icd10Code[2]
              )[0]?.diagnosisCode
              : '',
          txtIcdTen4:
            icd10Code?.length > 4 || icd10Code?.length == 4
              ? this.drpDiagnosisICD10?.filter(
                (x) => x?.diagnosisCode10ShortCodeId === icd10Code[3]
              )[0]?.diagnosisCode
              : '',
          txtIcdTen5:
            icd10Code?.length > 5 || icd10Code?.length == 5
              ? this.drpDiagnosisICD10?.filter(
                (x) => x?.diagnosisCode10ShortCodeId === icd10Code[4]
              )[0]?.diagnosisCode
              : '',
          txtIcdTen6:
            icd10Code?.length > 6 || icd10Code?.length == 6
              ? this.drpDiagnosisICD10?.filter(
                (x) => x?.diagnosisCode10ShortCodeId === icd10Code[5]
              )[0]?.diagnosisCode
              : '',
          txtIcdTen7:
            icd10Code?.length > 7 || icd10Code?.length == 7
              ? this.drpDiagnosisICD10?.filter(
                (x) => x?.diagnosisCode10ShortCodeId === icd10Code[6]
              )[0]?.diagnosisCode
              : '',
          txtIcdTen8:
            icd10Code?.length > 8 || icd10Code?.length == 8
              ? this.drpDiagnosisICD10?.filter(
                (x) => x?.diagnosisCode10ShortCodeId === icd10Code[7]
              )[0]?.diagnosisCode
              : '',
          txtIcdTen9:
            icd10Code?.length > 9 || icd10Code?.length == 9
              ? this.drpDiagnosisICD10?.filter(
                (x) => x?.diagnosisCode10ShortCodeId === icd10Code[8]
              )[0]?.diagnosisCode
              : '',
          txtIcdTen10:
            icd10Code?.length > 10 || icd10Code?.length == 10
              ? this.drpDiagnosisICD10?.filter(
                (x) => x?.diagnosisCode10ShortCodeId === icd10Code[9]
              )[0]?.diagnosisCode
              : '',
          txtIcdTen11:
            icd10Code?.length > 11 || icd10Code?.length == 11
              ? this.drpDiagnosisICD10?.filter(
                (x) => x?.diagnosisCode10ShortCodeId === icd10Code[10]
              )[0]?.diagnosisCode
              : '',
          txtIcdTen12:
            icd10Code?.length > 12 || icd10Code?.length == 12
              ? this.drpDiagnosisICD10?.filter(
                (x) => x?.diagnosisCode10ShortCodeId === icd10Code[11]
              )[0]?.diagnosisCode
              : '',
        });
        const txtResState = policyDetails?.state ?? '';
        const txtResCity = policyDetails?.city ?? '';
        const txtBillCity = patientData?.addresses[0]?.city ?? '';
        const txtBillState = patientData?.addresses[0]?.state ?? '';
        if (this.asyncOnChangeResState(defaultCountry.US)) {
          this.patientDetailsForm.patchValue({
            txtResponsibleState: txtResState ?? '',
            txtInsTelephone: `${policyDetails?.phone}`,
          });
        }
        txtResState &&
          this.asyncOnChangeResCity(txtResState, defaultCountry.US) &&
          this.patientDetailsForm.patchValue({
            txtResponsibleCity: txtResCity ?? '',
          });
        if (this.asyncOnChangeBillState(defaultCountry.US)) {
          this.patientDetailsForm.patchValue({
            txtBillingState: txtBillState ?? '',
            //  txtTelephone: `${patientData?.addresses[0]?.phone}`,
          });
        }
        txtBillState &&
          this.asyncOnChangeBillCity(txtBillState, defaultCountry.US) &&
          this.patientDetailsForm.patchValue({
            txtBillingCity: txtBillCity ?? '',
          });
        this.BillingState = txtBillState ?? '';
        this.BillingCity = txtBillCity ?? '';
        this.tblPostReponse = [];
        const initItmQtyValue = 0;
        this.totalItemQty = String(
          response?.lstPostingCharges?.reduce((prvsValue, curValue) => {
            return prvsValue + +curValue?.units;
          }, initItmQtyValue)
        );
        this.totalItemQty = this.totalItemPrice?.includes('.')
          ? String(Number(this.totalItemQty)?.toFixed(0))
          : this.totalItemQty;
        const initItmTotlValue = 0;
        this.totalItemPrice = String(
          response?.lstPostingCharges?.reduce((prvsValue, curValue) => {
            return prvsValue + +curValue?.total;
          }, initItmTotlValue)
        );
        this.totalItemPrice = this.totalItemPrice?.includes('.')
          ? String(Number(this.totalItemPrice)?.toFixed(2))
          : this.totalItemPrice;
        response?.lstPostingCharges?.forEach((element) => {
          const diagonsis9: DiagnosisCode9DTO[] =
            this.drpDiagnosisICD9?.filter(
              (e, index) =>
                e?.diagnosisCodeTypeData === element?.diagnosisCode9[index]
            ) ?? [];
          const diagonsis10: DiagnosisCode10DTO[] =
            this.drpDiagnosisICD10?.filter(
              (e, index) =>
                e?.diagnosisCodeTypeData === element?.diagnosisCode10[index]
            ) ?? [];
          this.tblPostReponse?.push({
            itemId: element?.itemId ?? '00000000-0000-0000-0000-000000000000',
            productId:
              element?.productId ?? '00000000-0000-0000-0000-000000000000',
            itemSize: element?.itemSize,
            cpt: '',
            dos:
              element?.dos === null
                ? ''
                : new Date(element?.dos)?.toLocaleDateString('en-US') ?? '',
            diagnosis9: diagonsis9,
            diagnosis10: diagonsis10,
            item: element?.item,
            code: element?.code,
            insurance: element?.insurance,
            provider: element?.provider,
            checkNo: element?.checkNo,
            m1: element?.modifier1,
            m2: element?.modifier2,
            m3: element?.modifier3,
            m4: element?.modifier4,
            dx1: element?.dxPointer1,
            dx2: element?.dxPointer2,
            dx3: element?.dxPointer3,
            dx4: element?.dxPointer4,
            placeOfService:
              this.drpPlaceOfSerivce?.filter(
                (e) =>
                  e?.placeOfServiceCode ===
                  claimResponse?.saleOrder?.orders?.placeOfServiceId
              )[0]?.placeOfServiceType ?? '',
            pos: element?.pos,
            tos: element?.tos,
            dateFrom: element?.dateFrom,
            dateTo: element?.dateTo,
            fee: (+element?.fee ?? 0).toFixed(2),
            units: element?.units,
            copay: element?.copay,
            patOther: element?.other,
            total: element?.total,
            document: element?.document,
            statement: element?.statementNote,
            patientResp: element?.patientResp,
            id: element?.postingChargesId,
            orderId: response?.orderId,
            claimId: response?.id,
            patientId: response?.patientId,
            postingId: element?.postingChargesId,
            codes: element?.code,
            mfee: ((+element?.fee ?? 0) * (+element?.units ?? 0)).toFixed(2),
            ddescription: element?.description,
            renderingProviderNpi: this.selectedProvider?.npi,
          });
        });
      },
      (err) => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      }
    );
  }
  // responsible state, city
  async asyncOnChangeResState(value: string | null | undefined) {
    let arrState: Promise<{ states: any[]; countryCode: string }> = null;
    this.patientDetailsForm.patchValue({
      txtResponsibleCity: null,
      txtResponsibleState: null,
    });
    this.drpResCities = [];
    this.drpResStates = [];
    value !== null &&
      value !== undefined &&
      this.regionDropdownService
        .getAllStatesByCountryNameByCountryShortName(value)
        .subscribe(
          (response) => {
            this.drpResStates = response?.items;
            this.countryPhoneCode =
              this.drpResStates?.filter((s) => s?.countryShortName === value)[0]
                ?.countryPhoneCode ?? '';
            setTimeout(() => {
              arrState = new Promise((resolve, reject) =>
                resolve({
                  states: response?.items,
                  countryCode: this.countryPhoneCode,
                })
              );
            }, 1000);
            this.filteredResponsibleStates = this.patientDetailsForm
              .get('txtResponsibleStateFilter')
              .valueChanges.pipe(
                startWith(''),
                map((value) =>
                  this.drpResStates.filter((option) =>
                    option?.stateName
                      ?.toLowerCase()
                      ?.includes(value?.toLowerCase() ?? '')
                  )
                )
              );
          },
          (err) => {
            const data: HttpErrorResponse = err;
            Swal.fire({
              icon: 'info',
              text: data?.error?.error?.message,
            });
          }
        );
    return arrState;
  }
  // billing  state, city
  async asyncOnChangeBillState(value: string | null | undefined) {
    let arrState: Promise<{ states: any[]; countryCode: string }> = null;
    this.patientDetailsForm.patchValue({
      txtBillingCity: null,
      txtBillingState: null,
    });
    this.drpResCities = [];
    this.drpResStates = [];
    value !== null &&
      value !== undefined &&
      this.regionDropdownService
        .getAllStatesByCountryNameByCountryShortName(value)
        .subscribe(
          (response) => {
            this.drpResStates = response?.items;
            this.countryPhoneCode =
              this.drpResStates?.filter((s) => s?.countryShortName === value)[0]
                ?.countryPhoneCode ?? '';
            setTimeout(() => {
              arrState = new Promise((resolve, reject) =>
                resolve({
                  states: response?.items,
                  countryCode: this.countryPhoneCode,
                })
              );
            }, 1000);
            this.filteredBillingStates = this.patientDetailsForm
              .get('txtBillingStateFilter')
              .valueChanges.pipe(
                startWith(''),
                map((value) =>
                  this.drpResStates.filter((option) =>
                    option?.stateName
                      ?.toLowerCase()
                      ?.includes(value?.toLowerCase() ?? '')
                  )
                )
              );
          },
          (err) => {
            const data: HttpErrorResponse = err;
            Swal.fire({
              icon: 'info',
              text: data?.error?.error?.message,
            });
          }
        );
    return arrState;
  }
  async asyncOnChangeResCity(
    state: string | null | undefined,
    country: string
  ) {
    let arrCity: Promise<any[]> = null;
    this.patientDetailsForm.patchValue({
      txtResponsibleCity: null,
    });
    country =
      country == ''
        ? this.patientDetailsForm.value.txtResponsibleCountry ==
          (null || undefined)
          ? ''
          : this.patientDetailsForm.value.txtResponsibleCountry
        : country;
    this.drpResCities = [];
    state !== null &&
      state !== undefined &&
      this.regionDropdownService
        .getAllCitiesByCountryAndStateNameByCountryShortNameAndStateShortName(
          country,
          state
        )
        .subscribe(
          (response) => {
            this.drpResCities = response?.items;
            setTimeout(() => {
              arrCity = new Promise((resolve, reject) =>
                resolve(response?.items)
              );
            }, 100);
            this.filteredResponsibleCities = this.patientDetailsForm
              .get('txtResponsibleCityFilter')
              .valueChanges.pipe(
                startWith(''),
                map((value) =>
                  this.drpResCities?.filter((option) =>
                    option?.cityName
                      ?.toLowerCase()
                      ?.includes(value?.toLowerCase() ?? '')
                  )
                )
              );
          },
          (err) => {
            const data: HttpErrorResponse = err;
            Swal.fire({
              icon: 'info',
              text: data?.error?.error?.message,
            });
          }
        );
    return arrCity;
  }
  async asyncOnChangeBillCity(
    state: string | null | undefined,
    country: string
  ) {
    let arrCity: Promise<any[]> = null;
    this.patientDetailsForm.patchValue({
      txtBillingCity: null,
    });
    country =
      country == ''
        ? this.patientDetailsForm.value.txtResponsibleCountry ==
          (null || undefined)
          ? ''
          : this.patientDetailsForm.value.txtResponsibleCountry
        : country;
    this.drpResCities = [];
    state !== null &&
      state !== undefined &&
      this.regionDropdownService
        .getAllCitiesByCountryAndStateNameByCountryShortNameAndStateShortName(
          country,
          state
        )
        .subscribe(
          (response) => {
            this.drpResCities = response?.items;
            setTimeout(() => {
              arrCity = new Promise((resolve, reject) =>
                resolve(response?.items)
              );
            }, 100);
            this.filteredBillingCities = this.patientDetailsForm
              .get('txtBillingCityFilter')
              .valueChanges.pipe(
                startWith(''),
                map((value) =>
                  this.drpResCities?.filter((option) =>
                    option?.cityName
                      ?.toLowerCase()
                      ?.includes(value?.toLowerCase() ?? '')
                  )
                )
              );
          },
          (err) => {
            const data: HttpErrorResponse = err;
            Swal.fire({
              icon: 'info',
              text: data?.error?.error?.message,
            });
          }
        );
    return arrCity;
  }

  // Patient Details
  savePatientDetails() {
    this.saveButtonHide = true;
    const pFormValue: any = this.patientDetailsForm.value;
    let inputClaimDetails: ClaimDetailsDTO = this.data?.ClaimDetails?.filter(
      (e) => e?.claimId === this.claimId
    )[0] ?? null;

    const payerObj = this.drpPayers?.filter(
      (p) => p?.displayName === pFormValue?.txtPolicyInfoPayor
    )[0];
    const sProviderDetails: MasterProviderDTO =
      this.drpProviders.filter(
        (e) => e?.shortCodeId === pFormValue?.txtProvider
      )[0] ?? null;
    const insuredPatientName = String(pFormValue?.txtInsrdFullName).split(',');

    const insuredDetails: InsuredDetailDTO = {
      insuredId: pFormValue?.txtInsuredPolicyId,
      insuredLastName: ((insuredPatientName[0]?.trim()) ?? ""),
      insuredFirstName: ((insuredPatientName[1]?.trim()) ?? ""),
      insuredMiddleName: ((insuredPatientName[2]?.trim()) ?? ""),
      insuredDob: pFormValue?.txtInsBirthdate === '' ? null : pFormValue?.txtInsBirthdate,
      insuredGenderType: pFormValue?.drpInsGender,
      insuredAddress: pFormValue?.txtInsAddress,
      insuredCity: pFormValue?.txtResponsibleCity,
      insuredState: pFormValue?.txtResponsibleState,
      insuredZipCode: pFormValue?.txtRespPostalCode,
      insuredTelephone: pFormValue?.txtInsTelephone,
      insuredGroupName: pFormValue?.txtPolicyGroup,
      insuredPlanName: pFormValue?.txtPolicyInfoPayor,
      insuredPhoneNumber: pFormValue?.txtInsTelephone,
      payorLevel: pFormValue?.payorLevel
    };
    const renderingProviderDetails: RenderingingProviderDetailsDTO = {
      renderingProviderDoctorFirstName: sProviderDetails.fullName,
      renderingProviderDoctorLastName: '',
      renderingProviderDoctorMiddleName: '',
      renderingProviderDoctorNpi: sProviderDetails.npi,
    };
    const referDoctor: doctorDTO =
      this.drpDoctor?.filter(
        (e) => e?.id === pFormValue?.txtReferringDoctor
      )[0] ?? null;

    const referringProviderDetails: ReferringProviderDetailsDTO = {
      referrringProviderDoctorFirstName: referDoctor?.fLname ?? '',
      referrringProviderDoctorLastName: referDoctor?.LTname ?? '',
      referrringProviderDoctorMiddleName: referDoctor?.mdName ?? '',
      referrringProviderDoctorNpi: referDoctor?.Npi ?? '',
    };

    const payerDetails: PayerDetailsDTO = {
      payerName: payerObj?.payerName ?? '',
      payerId: payerObj?.payerId ?? '',
      policyNo: pFormValue?.txtPolicyInfoPayor,
      payerAddressLine1: inputClaimDetails?.payerDetails?.payerAddressLine1 ?? '',
      payerAddressLine2: inputClaimDetails?.payerDetails?.payerAddressLine2 ?? '',
    };
    const ptName: string[] = pFormValue?.txtFullName.split(',');
    const patientDetails: PatientDetailsDTO = {
      patientMiddleName: ptName?.length == 3 || ptName?.length > 3 ? ptName[2] : '',
      patientLastName: ptName?.length == 1 || ptName?.length > 1 ? ptName[0] : '',
      patientFirstName: ptName?.length == 2 || ptName?.length > 2 ? ptName[1] : '',
      patientDob: pFormValue?.txtBirthdate,
      patientGenderType: pFormValue?.drpGender,
      patientIndividualRelationShipCode: ((pFormValue?.drpResponsibleRelationshipType ?? "") != '') ? pFormValue?.drpResponsibleRelationshipType : '01',
      patientAddress: pFormValue?.txtAddress,
      patientCity: pFormValue?.txtBillingCity,
      patientState: pFormValue?.txtBillingState,
      patientZipCode: pFormValue?.txtBillingPostalCode,
      patientRelationShipPersonGenderType: pFormValue?.drpGender,
      authNumber: pFormValue?.txtPriorNumber,
      isSSN: pFormValue?.chkEin === true ? 0 : 1,
    };

    inputClaimDetails = {
      claimId: inputClaimDetails?.claimId,
      claimNumber: inputClaimDetails?.claimNumber,
      clearingHouseType: inputClaimDetails?.clearingHouseType,
      totalItemChargeAmount: inputClaimDetails?.totalItemChargeAmount,
      placeOfServiceCode: pFormValue?.txtServiceInfo,
      diagnosisCode10Id: pFormValue?.drpDiag10ID,
      payerDetails: payerDetails,
      patientDetails: patientDetails,
      itemDetails: inputClaimDetails?.itemDetails,
      referringProviderDetails: referringProviderDetails,
      renderingProviderDetails: renderingProviderDetails,
      insuredDetails: insuredDetails,
    };

    const selectedProvider: ProviderDetailsDTO = {
      providerId: sProviderDetails?.id,
      providerFullName: sProviderDetails?.fullName,
      providerLastName: sProviderDetails?.lastName,
      providerFirstName: sProviderDetails?.firstName,
      providerNpi: sProviderDetails?.npi,
      providerPhoneNumber: sProviderDetails?.phone,
      providerAddress: sProviderDetails?.homeAddress,
      providerCity: sProviderDetails?.city,
      providerState: sProviderDetails?.state,
      providerZipCode: sProviderDetails?.zip,
      providerTaxId: pFormValue?.txtFederalTaxIdNumber,
    };

    let modifyClaimDetails: ClaimDetailsDTO[] = [];

    this.data?.ClaimDetails?.forEach((e) => {
      if (e?.claimId === this.claimId) {
        modifyClaimDetails?.push(inputClaimDetails);
      } else {
        modifyClaimDetails?.push(e);
      }
    });
    const input: ClaimBatchProcessRequestDTO = {
      providerDetails: selectedProvider,
      claimDetails: modifyClaimDetails,
      claimProcessMethod: claimProcessMethod?.Zermid,
    };

    this.claimProcessService.updateClaimByInput(input).subscribe(
      (response) => {
        this.saveButtonHide = false;
        Swal.fire({
          title: 'Success',
          html: 'Updated Successfully',
          icon: 'success',
          timer: 3000,
          timerProgressBar: true,
        }).finally(() => { });
        this.dialog.closeAll();
      },
      (err) => {
        this.saveButtonHide = false;
        const data: HttpErrorResponse = err;
        data && this.captureErrorMsg(data);
      }
    );
  }
  //dropdown clear
  clearSuffix() {
    this.suffixName = '';
    this.patientDetailsForm.patchValue({ drpSuffix: '' });
  }
  clearGender() {
    this.gender = '';
    this.patientDetailsForm.patchValue({ drpGender: '' });
  }
  clearInsGender() {
    this.gender = '';
    this.patientDetailsForm.patchValue({ drpInsGender: '' });
  }

  clearResponsibleCountry() {
    this.phoneResCode = '';
    this.ResponsibleCountry = '';
    this.patientDetailsForm.patchValue({ txtResponsibleCountry: '' });
  }
  clearResponsibleState() {
    this.ResponsibleState = '';
    this.patientDetailsForm.patchValue({ txtResponsibleState: '' });
  }
  clearResponsibleCity() {
    this.ResponsibleCity = '';
    this.patientDetailsForm.patchValue({ txtResponsibleCity: '' });
  }
  clearBillingState() {
    this.BillingState = '';
    this.patientDetailsForm.patchValue({ txtBillingState: '' });
  }
  clearBillingCity() {
    this.BillingCity = '';
    this.patientDetailsForm.patchValue({ txtBillingCity: '' });
  }
  clearPayorlevel() {
    this.payorlevel = '';
    this.patientDetailsForm.patchValue({ drpPayerName: '' });
  }
  clearPayor() {
    this.payor = '';
    this.patientDetailsForm.patchValue({ drpPayorName: '' });
  }
  clearIcd9() {
    this.txtIcd9 = [];
    this.patientDetailsForm.patchValue({ txtDiagnosisCodes9: [] });
  }
  clearIcd10() {
    this.txtIcd10 = [];
    this.patientDetailsForm.patchValue({ txtdiagnosisCode10Id: [] });
  }
  clearProvider() {
    this.provider = '';
    this.patientDetailsForm.patchValue({ txtProvider: '' });
  }
  clearGeneralPlaceOfService() {
    this.generalPlaceOfService = '';
    this.patientDetailsForm.patchValue({
      drpGeneralPlaceOfService: null,
    });
  }
  clearRelationship() {
    this.relationship = '';
    this.patientDetailsForm.patchValue({ drpResponsibleRelationshipType: '' });
  }
  clearRenderingDoctor() {
    this.RenderingDoctor = '';
    this.patientDetailsForm.patchValue({ txtRenderingDoctor: '' });
  }
  clearReferringDoctor() {
    this.ReferringDoctor = '';
    this.patientDetailsForm.patchValue({ txtReferringDoctor: '' });
  }
  clearPayers() {
    this.patientDetailsForm.patchValue({ txtPolicyInfoPayor: '' });
  }

  captureErrorMsg(data: HttpErrorResponse) {
    try {
      if (typeof data?.error === 'object') {
        const valditionerror: { message: string }[] =
          data?.error?.error?.validationErrors ?? [];
        if (
          valditionerror &&
          valditionerror[0] &&
          valditionerror[0]?.message?.includes('is required')
        ) {
          this.totalValue = [];
          valditionerror.forEach((val: { message: any }) => {
            this.messageValue = val.message;
            this.totalValue?.push(this.messageValue);
          });
          Swal.fire({
            icon: 'info',
            html: this.totalValue.join('\n'),
          });
          return;
        }
        if (valditionerror && valditionerror[0] && valditionerror[0]?.message?.includes('minimum') || valditionerror[0]?.message?.includes('maximum')) {
          this.totalValue = [];
          valditionerror.forEach((val: { message: any }) => {
            this.messageValue = val.message;
            this.totalValue?.push(this.messageValue);
          });
          Swal.fire({
            icon: 'info',
            html: this.totalValue.join('\n'),
          });
          return;
        }
      }
      if (typeof data?.error === 'string') {
        if (
          data?.error?.includes(
            'Your trial has ended! If you would like to continue using EdiFabric, please head on to https://www.edifabric.com/pricing.html to purchase it.'
          )
        ) {
          Swal.fire({
            icon: 'info',
            text: 'Your trial has ended!. Please contact admin to purchase EdiFabric.',
          });
          return;
        }
        const modifiedError = JSON.parse(data?.error);
        const valditionerror: { message: string }[] =
          modifiedError?.error?.validationErrors ?? [];
        if (valditionerror[0]?.message?.includes('is required')) {
          this.totalValue = [];
          valditionerror?.forEach((val: { message: any }) => {
            this.messageValue = val?.message;
            this.totalValue?.push(this.messageValue);
          });
          Swal.fire({
            icon: 'info',
            html: this.totalValue.join('\n'),
          });
        }
        return;
      }
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    } catch (error) {
      console.warn(error);
    }
  }
}
export interface doctorDTO {
  fLname: string;
  id: string;
  fTname: string;
  LTname: string;
  Npi: string;
  mdName: string;
}
