import type {
  CreateUpdateNewProductDetailsDTO,
  CreateUpdateProductHcpcCodeDTO,
  ItemCodedetails,
  ProdcutDetailsforStock,
  ProductDataDTO,
  ProductDetailsDTO,
  ProductDetailsSearchDTO,
  SearchProductStockDetailsDTO,
  ZeroStockProductDetailsDTO,
} from './dto/models';
import { RestService } from '@abp/ng.core';
import type {
  PagedAndSortedResultRequestDto,
  PagedResultDto,
} from '@abp/ng.core';
import { Injectable } from '@angular/core';
import { CreateUpdateProductDetailsDTO } from '../items/dto/models';
import { Status } from '../items';

@Injectable({
  providedIn: 'root',
})
export class ProductDetailsService {
  apiName = 'ItemManagement';
  create = (input: CreateUpdateProductDetailsDTO) =>
    this.restService.request<any, ProductDetailsDTO>(
      {
        method: 'POST',
        url: '/api/ItemApp/product-details',
        body: input,
      },
      { apiName: this.apiName }
    );

  delete = (id: string) =>
    this.restService.request<any, void>(
      {
        method: 'DELETE',
        url: `/api/ItemApp/product-details/${id}`,
      },
      { apiName: this.apiName }
    );

  get = (id: string) =>
    this.restService.request<any, ProductDetailsDTO>(
      {
        method: 'GET',
        url: `/api/ItemApp/product-details/${id}`,
      },
      { apiName: this.apiName }
    );

  getList = (input: PagedAndSortedResultRequestDto) =>
    this.restService.request<any, PagedResultDto<ProductDetailsDTO>>(
      {
        method: 'GET',
        url: '/api/ItemApp/product-details',
        params: {
          skipCount: input.skipCount,
          maxResultCount: input.maxResultCount,
          sorting: input.sorting,
        },
      },
      { apiName: this.apiName }
    );
  getListV1 = (input: PagedAndSortedResultRequestDto) =>
    this.restService.request<any, PagedResultDto<ProductDataDTO>>(
      {
        method: 'GET',
        url: '/api/ItemApp/product-details/async-v1',
        params: {
          skipCount: input.skipCount,
          maxResultCount: input.maxResultCount,
          sorting: input.sorting,
        },
      },
      { apiName: this.apiName }
    );
  // getListV2 = () =>
  //   this.restService.request<any, ProductDetailsDTO[]>(
  //     {
  //       method: 'GET',
  //       url: '/api/ItemApp/product-details/async-v2',
  //     },
  //     { apiName: this.apiName }
  //   );


    getListV2 = (pageno: number, pageoff: number, manufactureIds: string[], categoryIds: string[]) =>
      this.restService.request<any, PagedResultDto<ProductDetailsDTO>>({
        method: 'GET',
        url: '/api/ItemApp/product-details/async-v2',
        params: { pageno, pageoff, manufactureIds, categoryIds },
      },
      { apiName: this.apiName });

      getProductsListAsync = (pageNo: number, pageOffset: number) =>
        this.restService.request<any, PagedResultDto<ProductDetailsDTO>>({
          method: 'GET',
          url: '/api/ItemApp/product-details/products-list',
          params: { pageNo, pageOffset },
        },
        { apiName: this.apiName });





  getSearchProductByInput = (input: ProductDetailsSearchDTO) =>
    this.restService.request<any, PagedResultDto<ProductDetailsDTO>>(
      {
        method: 'GET',
        url: '/api/ItemApp/product-details/search-product',
        params: {
          productCode: input.productCode,
          productDescription: input.productDescription,
          hcpcCode: input.hcpcCode,
          mainProductName: input.mainProductName,
          minimumQuantity: input.minimumQuantity,
          availableQuantity: input.availableQuantity,
          status: input.status,
          tenantId: input.tenantId,
          dtFromDate: input.dtFromDate,
          dtToDate: input.dtToDate,
        },
      },
      { apiName: this.apiName }
    );

  update = (id: string, input: CreateUpdateProductDetailsDTO) =>
    this.restService.request<any, ProductDetailsDTO>(
      {
        method: 'PUT',
        url: `/api/ItemApp/product-details/${id}`,
        body: input,
      },
      { apiName: this.apiName }
    );
  getProductName = () =>
    this.restService.request<any, ProductDetailsDTO[]>(
      {
        method: 'GET',
        url: '/api/ItemApp/product-details/get-product-name',
      },
      { apiName: this.apiName }
    );
  getProductsList = () =>
    this.restService.request<any, ProductDetailsDTO[]>(
      {
        method: 'GET',
        url: '/api/ItemApp/product-details/get-product-name',
      },
      { apiName: this.apiName }
    );

  getProductDetailsforSaleOrder = () =>
    this.restService.request<any, PagedResultDto<SearchProductStockDetailsDTO>>(
      {
        method: 'GET',
        url: '/api/ItemApp/product-details/get-product-detailsfor-sale-order',
      },
      { apiName: this.apiName }
    );
  getZeroProductsList = () =>
    this.restService.request<any, ZeroStockProductDetailsDTO[]>(
      {
        method: 'GET',
        url: '/api/ItemApp/product-stock-details/get-zero-stock-products',
      },
      { apiName: this.apiName }
    );

  getProductListforStock = () =>
    this.restService.request<any, ProdcutDetailsforStock[]>(
      {
        method: 'GET',
        url: '/api/ItemApp/product-details/get-product-namefor-stock',
      },
      { apiName: this.apiName }
    );
  updateProductActiveStatus = (productId: string, status: Status) =>
    this.restService.request<any, ProductDetailsDTO>(
      {
        method: 'PUT',
        url: `/api/ItemApp/product-details/update-status/${productId}?sStatus=${status}`,
      },
      { apiName: this.apiName }
    );
  getActiveProductCategory = () =>
    this.restService.request<any, any>(
      {
        method: 'GET',
        url: '/api/ItemApp/product-category-details/active-category',
      },
      { apiName: this.apiName }
    );
    updateStatusV1ByGProductIdAndSStatus = (gProductId: string, sStatus: Status) =>
    this.restService.request<any, ProductDetailsDTO>({
      method: 'PUT',
      url: `/api/ItemApp/product-details/update-status-v1/${gProductId}`,
      params: { sStatus },
    },
    { apiName: this.apiName });

    getListUnitCost = () =>
      this.restService.request<any, CreateUpdateNewProductDetailsDTO[]>({
        method: 'GET',
        url: '/api/ItemApp/product-details/unit-cost',
      },
      { apiName: this.apiName });

      getListProductDetailsWonance = (pageNo:number,pageOffset:number) =>
        this.restService.request<any, any>({
          method: 'GET',
          url: '/api/ItemApp/product-details/product-details-wonance',
          params:{pageNo:pageNo,pageOffset:pageOffset},
        },
        { apiName: this.apiName });

        updateUnitCostByGProductIdAndUnitCost = (gProductId: string, unitCost: number) =>
          this.restService.request<any, CreateUpdateNewProductDetailsDTO>({
            method: 'PUT',
            url: `/api/ItemApp/product-details/unit-cost/${gProductId}`,
            params: { unitCost },
          },
          { apiName: this.apiName });

      updateUnitCostByGProductIdAndUnitCostAndHcpcinput = (gProductId: string, unitCost: number, hcpcinput: CreateUpdateProductHcpcCodeDTO[]) =>
        this.restService.request<any, CreateUpdateNewProductDetailsDTO>({
          method: 'PUT',
          url: `/api/ItemApp/product-details/unit-cost/${gProductId}`,
          params: { unitCost },
          body: hcpcinput,
        },
        { apiName: this.apiName });

      getListUnitCostByInput = (input: string) =>
        this.restService.request<any, CreateUpdateNewProductDetailsDTO[]>({
          method: 'GET',
          url: '/api/ItemApp/product-details/unit-cost',
          params: { input },
        },
        { apiName: this.apiName });

        getListUnitCostBySearchAndInput = (Search: string, input: string) =>
          this.restService.request<any, CreateUpdateNewProductDetailsDTO[]>({
            method: 'GET',
            url: '/api/ItemApp/product-details/unit-cost',
            params: { search: Search, input },
          },
          { apiName: this.apiName });

      getProductDetailsByCategoryid = (categoryid: string) =>
        this.restService.request<any, ItemCodedetails[]>({
          method: 'GET',
          url: '/api/ItemApp/product-details/get-product-details',
          params: { categoryid },
        },
        { apiName: this.apiName });

  // /api/ItemApp/product-details/update-status/{gProductId}
  constructor(private restService: RestService) {}
}
