import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CoverageAreaTypeDTO, LunchBreakHoursStartDTO, OnTimeMasterDropdownDTO, WorkingHoursDTO, WorkingWeeksDTO } from 'projects/patient/src/app/dropdown-proxy/dropdown-management/dropdowns/dto/on-time-schedule/models';
import { OnTimeMasterDropdownService } from 'projects/patient/src/app/dropdown-proxy/dropdown-management/dropdowns/master-dropdown-service/on-time-master-dropdown.service';
import { OnTimeDropdowns } from 'projects/patient/src/app/dropdown-proxy/dropdown-management/dropdowns/on-time-dropdowns.enum';

import Swal from 'sweetalert2';

import { CreateUpdateUserWorkingHoursDTO } from '../onTime-proxy/rcm/on-time-management/on-time-schedule/dto/models';
import { UserWorkingHoursService } from '../onTime-proxy/rcm/on-time-management/on-time-schedule/user-working-hours.service';
import { ToastrService } from "ngx-toastr";


@Component({
  selector: 'app-add-working-hours',
  templateUrl: './add-working-hours.component.html',
  styleUrls: ['./add-working-hours.component.scss']
})
export class AddWorkingHoursComponent implements OnInit {
  sundayLeaveHide: boolean;
  mondayLeaveHide: boolean;
  tuesdayLeaveHide: boolean;
  wednesdayLeaveHide: boolean;
  thursdayLeaveHide: boolean;
  fridayLeaveHide: boolean;
  saturdayLeaveHide: boolean;
  AddWorkForm: FormGroup;
  drpCoverage: CoverageAreaTypeDTO[];
  drpWorkOptn: WorkingWeeksDTO[];
  drpStartAt: any[] = [];
  constructor(public notesDialogRef: MatDialogRef<AddWorkingHoursComponent>,
    private onTimeDropdown: OnTimeMasterDropdownService,
    @Inject(MAT_DIALOG_DATA) public data: { userId: string, addWorkingId: string },
    private formBuilder: FormBuilder,
    private toastr: ToastrService,
    private onTimeUserService: UserWorkingHoursService) { }
  sunday: string = "Leave";
  Monday: string = "Leave";
  Tuesday: string = "Leave";
  Wednesday: string = "Leave";
  Thursday: string = "Leave";
  Friday: string = "Leave";
  Saturday: string = "Leave";
  covShow: boolean = false;
  workShow: boolean = false;
  atShow: boolean = false;
  endShow: boolean = false;
  SelShow: boolean = false;
  workingHrsId: string = "";
  ngOnInit(): void {
    this.AddWorkForm = this.formBuilder.group({
      acceptTerms: new FormControl(""),
      drpCountry: new FormControl(""),
      mondayWorkStatus: new FormControl(""),
      saturdayWorkStatus: new FormControl(""),
      tuesdayWorkStatus: new FormControl(""),
      wednesWorkStatus: new FormControl(""),
      thursWorkStatus: new FormControl(""),
      fridayWorkStatus: new FormControl(""),
      drpFromLunch: new FormControl(""),
      txtToLunch: new FormControl(""),

      sundayForm: new FormArray([]),
      mondayForm: new FormArray([]),
      tuesForm: new FormArray([]),
      wdnesdayForm: new FormArray([]),
      thursdayForm: new FormArray([]),
      fridayForm: new FormArray([]),
      satdayForm: new FormArray([]),
    })
    if (this.data.addWorkingId != null && this.data.addWorkingId != "") {
      this.getAddHoursById()
    }
    this.getDropDown();
  }

  /// dropdown list
  coverage: string = "";
  drpStartEnd: WorkingHoursDTO[];
  drpFromLunch: LunchBreakHoursStartDTO[];
  getDropDown() {
    let onTimeDropdownInput: OnTimeDropdowns[];
    onTimeDropdownInput = [
      OnTimeDropdowns.CoverageAreaType,
      OnTimeDropdowns.WorkingWeeks,
      OnTimeDropdowns.WorkingHoursStart,
      OnTimeDropdowns.LunchBreakHoursStart,
    ]
    this.onTimeDropdown.getOnTimeDropdownsByInput(onTimeDropdownInput).subscribe((stateResponse) => {
      const response: OnTimeMasterDropdownDTO = stateResponse;
      this.drpCoverage = response?.coverageAreaTypes;
      this.drpWorkOptn = response?.workingWeeks;
      this.drpFromLunch = response?.lunchBreakHoursStarts
      // this.drpStartAt = response?.workingHoursStarts;
    }, err => {
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    });
    this.onTimeDropdown.getWorkTimings().subscribe((stateResponse) => {
      this.drpStartAt = stateResponse.workingTimes

      // stateResponse.workingTimes.forEach(ele => {
      // this.drpStartAt.push({
      //   startAt: ele?.startTime
      // })
      // })
    })

  }
  getSundayValues() {
    return this.AddWorkForm.value.sundayForm
  }

  getMondayValues() {
    return this.AddWorkForm.value.mondayForm
  }

  getTuesdayValues() {
    return this.AddWorkForm.value.tuesForm
  }

  getWednesdayValues() {
    return this.AddWorkForm.value.wdnesdayForm
  }

  getThursdayValues() {
    return this.AddWorkForm.value.thursdayForm
  }

  getFridayValues() {
    return this.AddWorkForm.value.fridayForm
  }

  getSaturdayValues() {
    return this.AddWorkForm.value.satdayForm
  }
  // get f() { return this.AddWorkForm.controls; }
  sundayArr: any[] = [];
  mondayArr: any[] = [];
  tuesdayArr: any[] = [];
  wednesdayArr: any[] = [];
  thursdayArr: any[] = [];
  fridayArr: any[] = [];
  satdayArr: any[] = [];
  // ! save functionlity
  getWorkingSave() {
    let AddWorkingHours: CreateUpdateUserWorkingHoursDTO;

    const sundayValues = this.getSundayValues();
    const mondayValues = this.getMondayValues();
    const tuesdayValues = this.getTuesdayValues();
    const wednesdayValues = this.getWednesdayValues();
    const thursdayValues = this.getThursdayValues();
    const fridayValues = this.getFridayValues();
    const saturdayValues = this.getSaturdayValues();
    this.sundayArr = [];
    sundayValues.forEach(element => {
      this.sundayArr.push({
        coverageArea: element?.drpCoverage,
        weekOption: element?.drpWorkOptn,
        startAt: element?.drpStartAt?.startTime,
        endAt: element?.drpStartEnd,
        coverage: element?.drpStartEnd,
      })
    });

    this.mondayArr = [];
    mondayValues.forEach(element => {
      this.mondayArr.push({
        coverageArea: element?.drpMonCov,
        weekOption: element?.drpMonWork,
        startAt: element?.drpMonStartAt?.startTimeShortCodeId,
        endAt: element?.drpMonStartEnd,
        coverage: element?.drpMonSelCov,
      })
    });

    this.tuesdayArr = [];
    tuesdayValues.forEach(element => {
      this.tuesdayArr.push({
        coverageArea: element?.drpTueCov,
        weekOption: element?.drpTueWork,
        startAt: element?.drpTueStartAt?.startTimeShortCodeId,
        endAt: element?.drpTueStartEnd,
        coverage: element?.drpTueSelCov,

      })
    });

    this.wednesdayArr = [];
    wednesdayValues.forEach(element => {
      this.wednesdayArr.push({
        coverageArea: element?.drpWedCov,
        weekOption: element?.drpWedWork,
        startAt: element?.drpWedStartAt?.startTimeShortCodeId,
        endAt: element?.drpWedStartEnd,
        coverage: element?.drpWedSelCov,

      })
    });

    this.thursdayArr = [];
    thursdayValues.forEach(element => {
      this.thursdayArr.push({
        coverageArea: element?.drpThursCov,
        weekOption: element?.drpThursWork,
        startAt: element?.drpThursStartAt?.startTimeShortCodeId,
        endAt: element?.drpThursStartEnd,
        coverage: element?.drpThursSelCov,

      })
    });

    this.fridayArr = [];
    fridayValues.forEach(element => {
      this.fridayArr.push({
        coverageArea: element?.drpFriCov,
        weekOption: element?.drpFriWork,
        startAt: element?.drpFriStartAt?.startTimeShortCodeId,
        endAt: element?.drpFriStartEnd,
        coverage: element?.drpFriSelCov,

      })
    });

    this.satdayArr = [];
    saturdayValues.forEach(element => {
      this.satdayArr.push({
        coverageArea: element?.drpSatCov,
        weekOption: element?.drpSatWork,
        startAt: element?.drpSatStartAt?.startTimeShortCodeId,
        endAt: element?.drpSatStartEnd,
        coverage: element?.drpSatSelCov,

      })
    });


    AddWorkingHours = {
      userId: this.data.userId,
      userName: "",
      userSundayWorkingHours: {
        isWorking: this.AddWorkForm.value.acceptTerms === true ? 1 : 0,
        userWorkDetails: this.sundayArr
      },
      userMondayWorkingHours: {
        isWorking: this.AddWorkForm.value.mondayWorkStatus === true ? 1 : 0,
        userWorkDetails: this.mondayArr
      },
      userTuesdayWorkingHours: {
        isWorking: this.AddWorkForm.value.tuesdayWorkStatus === true ? 1 : 0,
        userWorkDetails: this.tuesdayArr
      },
      userWednesdayWorkingHours: {
        isWorking: this.AddWorkForm.value.wednesWorkStatus === true ? 1 : 0,
        userWorkDetails: this.wednesdayArr
      },
      userThursdayWorkingHours: {
        isWorking: this.AddWorkForm.value.thursWorkStatus === true ? 1 : 0,
        userWorkDetails: this.thursdayArr
      },
      userFridayWorkingHours: {
        isWorking: this.AddWorkForm.value.fridayWorkStatus === true ? 1 : 0,
        userWorkDetails: this.fridayArr
      },
      userSaturdayWorkingHours: {
        isWorking: this.AddWorkForm.value.saturdayWorkStatus === true ? 1 : 0,
        userWorkDetails: this.satdayArr
      },
      userLunchBreakTimings: {
        fromTime: this.AddWorkForm.value.drpFromLunch,
        toTime: this.AddWorkForm.value.txtToLunch
      }
    }
    if (this.data.addWorkingId === "" || this.data.addWorkingId === null || this.data.addWorkingId === undefined) {
      this.onTimeUserService.create(AddWorkingHours).subscribe(response => {
        // Swal.fire({ title: 'Success', html: 'Saved Successfully', icon: 'success', timer: 3000, timerProgressBar: true });
        this.toastr.success('Saved Successfully','Success')
        this.workingHrsId = response.id
      }, err => {
        const data: HttpErrorResponse = err;

        // Swal.fire({
        //   icon: 'info',
        //   html: data?.error?.error?.message,

        // })
        this.toastr.info(data?.error?.error?.message)
      });
    }
    else {
      this.onTimeUserService.update(this.data.addWorkingId, AddWorkingHours).subscribe(response => {
        // this.patientsId = response.id
        // Swal.fire({ title: 'Success', html: 'Saved Successfully', icon: 'success', timer: 3000, timerProgressBar: true });
        this.toastr.success('Saved Successfully','Success')
      }, err => {
        const data: HttpErrorResponse = err;
        // Swal.fire({
        //   icon: 'info',
        //   html: data?.error?.error?.message,
        // })
        this.toastr.info(data?.error?.error?.message)
      });

    }

  }


  // get Add Hours byid
  getAddHoursById() {

    this.onTimeUserService.get(this.data.addWorkingId).subscribe(response => {
      this.covShow = true;
      this.workShow = true;
      this.atShow = true;
      this.endShow = true;
      this.SelShow = true;
      const sundaySavedArr: any[] = [];
      const mondaySavedArr: any[] = [];
      const tuesdaySavedArr: any[] = [];
      const fridaySavedArr: any[] = [];
      const wednesdaySavedArr: any[] = [];
      const saturdaySavedArr: any[] = [];
      const thursSavedArr: any[] = [];
      this.AddWorkForm.patchValue({
        acceptTerms: response?.userSundayWorkingHours?.isWorking == 1 ? true : false,
        mondayWorkStatus: response?.userMondayWorkingHours?.isWorking == 1 ? true : false,
        saturdayWorkStatus: response?.userSaturdayWorkingHours?.isWorking == 1 ? true : false,
        tuesdayWorkStatus: response?.userTuesdayWorkingHours?.isWorking == 1 ? true : false,
        wednesWorkStatus: response?.userWednesdayWorkingHours?.isWorking == 1 ? true : false,
        thursWorkStatus: response?.userThursdayWorkingHours?.isWorking == 1 ? true : false,
        fridayWorkStatus: response?.userFridayWorkingHours?.isWorking == 1 ? true : false,
        drpFromLunch: response?.userLunchBreakTimings?.fromTime,
        txtToLunch: response?.userLunchBreakTimings?.toTime
      })

      if (response?.userSundayWorkingHours?.isWorking == 1) {
        this.sunday = "Working"
        this.sundayLeaveHide = true;
      }
      if (response?.userSundayWorkingHours?.isWorking == 0) {
        this.sunday = "Leave"
        this.sundayLeaveHide = false;
      }


      if (response?.userMondayWorkingHours?.isWorking == 1) {
        this.Monday = "Working"
        this.mondayLeaveHide = true;
      }
      if (response?.userMondayWorkingHours?.isWorking == 0) {
        this.Monday = "Leave"
        this.mondayLeaveHide = false;
      }


      if (response?.userTuesdayWorkingHours?.isWorking == 1) {
        this.Tuesday = "Working"
        this.tuesdayLeaveHide = true;
      }
      if (response?.userTuesdayWorkingHours?.isWorking == 0) {
        this.Tuesday = "Leave"
        this.tuesdayLeaveHide = false;
      }


      if (response?.userWednesdayWorkingHours?.isWorking == 1) {
        this.Wednesday = "Working"
        this.wednesdayLeaveHide = true;
      }
      if (response?.userWednesdayWorkingHours?.isWorking == 0) {
        this.Wednesday = "Leave"
        this.wednesdayLeaveHide = false;

      }


      if (response?.userThursdayWorkingHours?.isWorking == 1) {
        this.Thursday = "Working"
        this.thursdayLeaveHide = true;
      }
      if (response?.userThursdayWorkingHours?.isWorking == 0) {
        this.Thursday = "Leave"
        this.thursdayLeaveHide = false;
      }


      if (response?.userFridayWorkingHours?.isWorking == 1) {
        this.Friday = "Working"
        this.fridayLeaveHide = false;
      }
      if (response?.userFridayWorkingHours?.isWorking == 0) {
        this.Friday = "Leave"
        this.fridayLeaveHide = true;
      }


      if (response?.userSaturdayWorkingHours?.isWorking == 1) {
        this.Saturday = "Working"
        this.saturdayLeaveHide = false;
      }
      if (response?.userSaturdayWorkingHours?.isWorking == 0) {
        this.Saturday = "Leave"
        this.saturdayLeaveHide = true;
      }

      response?.userSundayWorkingHours?.userWorkDetails.map(d => {
        const startTimes = this.drpStartAt?.filter(value => {
          return value && value?.startTimeShortCodeId === d?.startAt
        })[0];

        const newDemoData = { drpCoverage: d?.coverageArea, drpWorkOptn: d?.weekOption, drpStartAt: startTimes, drpStartEnd: d?.endAt, drpSelCov: d?.officeDetails }
        sundaySavedArr.push(newDemoData)
        const demo = this.addAccordion();
        this.getSundayValues().push(demo);
      })

      response?.userMondayWorkingHours?.userWorkDetails.map(d => {
        const startTimes = this.drpStartAt?.filter(value => {
          return value && value?.startTimeShortCodeId === d?.startAt
        })[0];
        const newDemoData = { drpMonCov: d?.coverageArea, drpMonWork: d?.weekOption, drpMonStartAt: startTimes, drpMonStartEnd: d?.endAt, drpMonSelCov: d?.officeDetails }
        mondaySavedArr.push(newDemoData)
        const demo = this.addMonday();
        this.getMondayValues().push(demo);
      })

      response?.userTuesdayWorkingHours?.userWorkDetails.map(d => {
        const startTimes = this.drpStartAt?.filter(value => {
          return value && value?.startTimeShortCodeId === d?.startAt
        })[0];
        const newDemoData = { drpTueCov: d?.coverageArea, drpTueWork: d?.weekOption, drpTueStartAt: startTimes, drpTueStartEnd: d?.endAt, drpTueSelCov: d?.officeDetails }
        tuesdaySavedArr.push(newDemoData)
        const demo = this.addTuesday();
        this.getTuesdayValues().push(demo);
      })

      response?.userWednesdayWorkingHours?.userWorkDetails.map(d => {
        const startTimes = this.drpStartAt?.filter(value => {
          return value && value?.startTimeShortCodeId === d?.startAt
        })[0];
        const newDemoData = { drpWedCov: d?.coverageArea, drpWedWork: d?.weekOption, drpWedStartAt: startTimes, drpWedStartEnd: d?.endAt, drpWedSelCov: d?.officeDetails }
        wednesdaySavedArr.push(newDemoData)
        const demo = this.addWednesday();
        this.getWednesdayValues().push(demo);
      })

      response?.userThursdayWorkingHours?.userWorkDetails.map(d => {
        const startTimes = this.drpStartAt?.filter(value => {
          return value && value?.startTimeShortCodeId === d?.startAt
        })[0];
        const newDemoData = { drpThursCov: d?.coverageArea, drpThursWork: d?.weekOption, drpThursStartAt: startTimes, drpThursStartEnd: d?.endAt, drpThursSelCov: d?.officeDetails }
        thursSavedArr.push(newDemoData)
        const demo = this.addThursday();
        this.getThursdayValues().push(demo);
      })

      response?.userFridayWorkingHours?.userWorkDetails.map(d => {
        const startTimes = this.drpStartAt?.filter(value => {
          return value && value?.startTimeShortCodeId === d?.startAt
        })[0];
        const newDemoData = { drpFriCov: d?.coverageArea, drpFriWork: d?.weekOption, drpFriStartAt: startTimes, drpFriStartEnd: d?.endAt, drpFriSelCov: d?.officeDetails }
        fridaySavedArr.push(newDemoData)
        const demo = this.addFriday();
        this.getFridayValues().push(demo);
      })

      response?.userSaturdayWorkingHours?.userWorkDetails.map(d => {
        const startTimes = this.drpStartAt?.filter(value => {
          return value && value?.startTimeShortCodeId === d?.startAt
        })[0];
        const newDemoData = { drpSatCov: d?.coverageArea, drpSatWork: d?.weekOption, drpSatStartAt: startTimes, drpSatStartEnd: d?.endAt, drpSatSelCov: d?.officeDetails }
        saturdaySavedArr.push(newDemoData)
        const demo = this.addSatday();
        this.getSaturdayValues().push(demo);
      })
      this.AddWorkForm.patchValue({ sundayForm: sundaySavedArr });
      this.AddWorkForm.patchValue({ mondayForm: mondaySavedArr });
      this.AddWorkForm.patchValue({ tuesForm: tuesdaySavedArr });
      this.AddWorkForm.patchValue({ wdnesdayForm: wednesdaySavedArr });
      this.AddWorkForm.patchValue({ thursdayForm: thursSavedArr });
      this.AddWorkForm.patchValue({ fridayForm: fridaySavedArr })
      this.AddWorkForm.patchValue({ satdayForm: saturdaySavedArr })
    }, err => {
      const data: HttpErrorResponse = err;

      // Swal.fire({
      //   icon: 'info',
      //   html: data?.error?.error?.message,

      // })
      this.toastr.info(data?.error?.error?.message)
    })
  }
  LunchChanges(event) {

    this.onTimeDropdown.getLunchBreakEndTimingsBySLunchBreakStartShortCodeId(event).subscribe(stateResponse => {
      this.AddWorkForm.patchValue({
        txtToLunch: stateResponse
      })
    })
  }

  // demographics form control return
  // demo() {
  //   return this.AddWorkForm?.get("Demographics") as FormArray
  // }
  // newDemo(): FormGroup {
  //   return this.formBuilder.group({
  //     drpCoverage: new FormControl(""),
  //     drpWorkOptn: new FormControl(""),
  //     drpStartAt: new FormControl(""),
  //     drpStartEnd: new FormControl(""),
  //     drpSelCov: new FormControl(""),
  //     acceptTerms: new FormControl(""),
  //   })
  // }
  // sundayForm input values as array
  get f() { return this.AddWorkForm.controls; }
  get t() { return this.f.sundayForm as FormArray; }

  // mondayForm input values as array
  get a() { return this.AddWorkForm.controls; }
  get b() { return this.f.mondayForm as FormArray; }

  // tuesdayForm input values as array
  get c() { return this.AddWorkForm.controls; }
  get d() { return this.c.tuesForm as FormArray; }

  // wednesdaydayForm input values as array
  get g() { return this.AddWorkForm.controls; }
  get h() { return this.g.wdnesdayForm as FormArray; }

  // thursdayForm input values as array
  get i() { return this.AddWorkForm.controls; }
  get j() { return this.i.thursdayForm as FormArray; }

  // fridayForm input values as array
  get k() { return this.AddWorkForm.controls; }
  get l() { return this.i.fridayForm as FormArray; }

  // saturdayForm input values as array
  get m() { return this.AddWorkForm.controls; }
  get n() { return this.m.satdayForm as FormArray; }

  // SundY values push into array
  addAccordion() {

    this.t.push(this.formBuilder.group({
      drpCoverage: new FormControl(""),
      drpWorkOptn: new FormControl(""),
      drpStartAt: new FormControl(""),
      drpStartEnd: new FormControl(""),
      drpSelCov: new FormControl(""),
      // acceptTerms: new FormControl(""),
    }));

  }

  addMonday() {

    this.b.push(this.formBuilder.group({
      drpMonCov: new FormControl(""),
      drpMonWork: new FormControl(""),
      drpMonStartAt: new FormControl(""),
      drpMonStartEnd: new FormControl(""),
      drpMonSelCov: new FormControl(""),

    }));

  }

  addTuesday() {

    this.d.push(this.formBuilder.group({
      drpTueCov: new FormControl(""),
      drpTueWork: new FormControl(""),
      drpTueStartAt: new FormControl(""),
      drpTueStartEnd: new FormControl(""),
      drpTueSelCov: new FormControl(""),

    }));

  }

  addWednesday() {

    this.h.push(this.formBuilder.group({
      drpWedCov: new FormControl(""),
      drpWedWork: new FormControl(""),
      drpWedStartAt: new FormControl(""),
      drpWedStartEnd: new FormControl(""),
      drpWedSelCov: new FormControl(""),

    }));

  }

  addThursday() {
    this.j.push(this.formBuilder.group({
      drpThursCov: new FormControl(""),
      drpThursWork: new FormControl(""),
      drpThursStartAt: new FormControl(""),
      drpThursStartEnd: new FormControl(""),
      drpThursSelCov: new FormControl(""),

    }));

  }

  addFriday() {
    this.l.push(this.formBuilder.group({
      drpFriCov: new FormControl(""),
      drpFriWork: new FormControl(""),
      drpFriStartAt: new FormControl(""),
      drpFriStartEnd: new FormControl(""),
      drpFriSelCov: new FormControl(""),

    }));

  }

  addSatday() {
    this.n.push(this.formBuilder.group({
      drpSatCov: new FormControl(""),
      drpSatWork: new FormControl(""),
      drpSatStartAt: new FormControl(""),
      drpSatStartEnd: new FormControl(""),
      drpSatSelCov: new FormControl(""),

    }));

  }
  // ! sunday status
  sundayStatus(status) {
    if (this.AddWorkForm.value.acceptTerms == true) {
      this.sunday = "Leave"
      this.sundayLeaveHide = false;
      this.AddWorkForm.value.sundayForm = []
      this.t.removeAt
    }
    if (this.AddWorkForm.value.acceptTerms == false) {
      this.covShow = true
      this.sunday = "Working"
      this.sundayLeaveHide = true;
      if (this.AddWorkForm.value.sundayForm.length === 0 && this.AddWorkForm.value.acceptTerms == "") {
        this.addAccordion()

      }
    }

  }

  // !Monday status
  MondayStatus(status) {
    if (this.AddWorkForm.value.mondayWorkStatus == true) {
      this.Monday = "Leave"
      this.mondayLeaveHide = false;
    }
    if (this.AddWorkForm.value.mondayWorkStatus == false) {
      this.Monday = "Working"
      this.mondayLeaveHide = true;
      if (this.AddWorkForm.value.mondayForm.length === 0 && this.AddWorkForm.value.mondayWorkStatus == "") {
        this.addMonday()
      }
    }

  }

  // ! Tuesday status
  tuesdayStatus(status) {
    if (this.AddWorkForm.value.tuesdayWorkStatus == true) {
      this.Tuesday = "Leave"
      this.tuesdayLeaveHide = false;
    }
    if (this.AddWorkForm.value.tuesdayWorkStatus == false) {
      this.Tuesday = "Working"
      this.tuesdayLeaveHide = true;
      if (this.AddWorkForm.value.tuesForm.length === 0 && this.AddWorkForm.value.tuesdayWorkStatus == "") {
        this.addTuesday()

      }
    }

  }

  // ! Friday status
  fridayStatus(status) {
    if (this.AddWorkForm.value.fridayWorkStatus == true) {
      this.Friday = "Leave"
      this.fridayLeaveHide = false;
    }
    if (this.AddWorkForm.value.fridayWorkStatus == false) {
      this.Friday = "Working"
      this.fridayLeaveHide = true;
      if (this.AddWorkForm.value.fridayForm.length === 0 && this.AddWorkForm.value.fridayWorkStatus == "") {
        this.addFriday()

      }
    }

  }

  // ! saturday status
  saturdayStatus(status) {
    if (this.AddWorkForm.value.saturdayWorkStatus == true) {
      this.Saturday = "Leave"
      this.saturdayLeaveHide = false;
    }
    if (this.AddWorkForm.value.saturdayWorkStatus == false) {
      this.Saturday = "Working"
      this.saturdayLeaveHide = true;
      if (this.AddWorkForm.value.satdayForm.length === 0 && this.AddWorkForm.value.saturdayWorkStatus == "") {
        this.addSatday()

      }
    }

  }

  // ! wednes status
  wednesStatus(status) {
    if (this.AddWorkForm.value.wednesWorkStatus == true) {
      this.Wednesday = "Leave"
      this.wednesdayLeaveHide = false;
    }
    if (this.AddWorkForm.value.wednesWorkStatus == false) {
      this.Wednesday = "Working"
      this.wednesdayLeaveHide = true;
      if (this.AddWorkForm.value.wdnesdayForm.length === 0 && this.AddWorkForm.value.wednesWorkStatus == "") {
        this.addWednesday()

      }
    }

  }

  // ! thurs status
  thursStatus(status) {
    if (this.AddWorkForm.value.thursWorkStatus == true) {
      this.Thursday = "Leave"
      this.thursdayLeaveHide = false;
    }
    if (this.AddWorkForm.value.thursWorkStatus == false) {
      this.Thursday = "Working"
      this.thursdayLeaveHide = true;
      if (this.AddWorkForm.value.thursdayForm.length === 0 && this.AddWorkForm.value.thursWorkStatus == "") {
        this.addThursday()
      }
    }

  }

  onChange($event) {
    this.workShow = true


  }
  onChangeWork($event) {
    this.atShow = true


  }
  onAtChange($event, i) {


    this.endShow = true

  }
  onEndChange($event, i) {

    this.SelShow = true
  }
  onEndChanges($event) {

    this.SelShow = true
  }
  clearCoverage() {
    this.coverage = '';
    this.AddWorkForm.patchValue({ drpCoverage: "" });
  }
}
