import { Injectable } from "@angular/core";

import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ConfirmationDialogComponent } from "./confirmation-dialog/confirmation-dialog.component";

@Injectable({
  providedIn: 'root'
})
export class ConfirmationDialogService {
  constructor(private modalService: NgbModal) {}

  public confirm(
    title: string,
    message: string,
    btnSaveText: string = "Save",
    btnCancelText: string = "Cancel",
    dialogSize = "md"
  ): Promise<boolean> {
    const modalRef = this.modalService.open(ConfirmationDialogComponent, {
      size: dialogSize
    });
    Promise.resolve(null).then(() => {
      modalRef.componentInstance.title = title;
      modalRef.componentInstance.message = message;
      modalRef.componentInstance.btnOkText = btnSaveText;
      modalRef.componentInstance.btnCancelText = btnCancelText;
    });
    return modalRef.result;
  }
}
