<div class="container-fluid  mt-3">
  <div class="row bg">
    <div class="col-12">
       <div class="d-flex align-items-center justify-content-between">
        <h3 class="font-weight-bolder mb-0">
          <b class="customThemeClass">List / Master Ins Verification</b>
        </h3>
        <!-- <div class="page-title-box page-title-right pt-3 pb-0">
          <ol class="breadcrumb m-0">
            <form [formGroup]="branchForm" style="background-color: white;">
              <mat-form-field class="pr-3 mt-3">
                <mat-label>Branch</mat-label>
                <mat-select #selectBranch formControlName="drpBranch" multiple [(value)]="organizationUnitName"
                  >
                  <mat-option>
                    <ngx-mat-select-search placeholderLabel="Search" noEntriesFoundLabel="No Matches found"
                      formControlName="txtBranch"></ngx-mat-select-search>
                  </mat-option>
                 <mat-option  [value]="'selectAll'">Select All</mat-option>
                  <mat-option [value]="'DeselectAll'">Deselect All</mat-option> -->

                  <!-- <mat-option *ngFor="let branch of brnachList | async" [value]="branch.id">
                    {{ branch.organizationUnitName }}
                  </mat-option> -->
                   <!-- <mat-option>Houston</mat-option>
                  <mat-option>Branch</mat-option>
                </mat-select>
              </mat-form-field>
            </form>
          </ol>
        </div> -->
      </div>
    </div>
  </div>
  <div class="card mt-2">
    <div class="row">
      <div class="col-xl-8">
        <ul class="card card-body" ngbNav #nav="ngbNav" justify="justified" class="alert-success nav nav-pills nav-fill">
        <li ngbNavItem="FaxProcess" [class.selected-tab]="nav.activeId === 'FaxProcess'">
          <a ngbNavLink (click)="changeTab('FaxProcess')"><b>Fax Process</b></a>
        </li>
        <li ngbNavItem="On-Demand" [class.selected-tab]="nav.activeId === 'On-Demand'">
          <a ngbNavLink (click)="changeTab('On-Demand')"><b>On-Demand</b></a>
        </li>
        <li ngbNavItem="Authorization" [class.selected-tab]="nav.activeId === 'Authorization'">
          <a ngbNavLink (click)="changeTab('Authorization')"><b>Authorization</b></a>
        </li>
        <li ngbNavItem="VerificationIssues" [class.selected-tab]="nav.activeId === 'VerificationIssue'">
          <a ngbNavLink (click)="changeTab('VerificationIssues')"><b>Verification Issue</b></a>
        </li>
      </ul>
      </div>
      <div class="col-xl-4">
        <ul class="card card-body" ngbNav #nav="ngbNav" justify="justified" class="alert-success nav nav-pills nav-fill"
        style="justify-content: end;padding-right: 23px;">
          <li *ngIf="activeTab !== 'Authorization'">
            <a [routerLink]="'/do_verification/' + activeTabId "
            target="_blank" class="preview-link">

          <button   mat-button class="buttonColor" style="margin-top: 4%" [disabled]="!isDoVerification"><mat-icon>verified_user</mat-icon> Do Verification</button>
        </a>
        </li>
        <li *ngIf="activeTab === 'Authorization'">
          <a [routerLink]="'/doAuthorization'"
          target="_blank" class="preview-link">

        <button  mat-button class="buttonColor" style="margin-top: 4%" [disabled]="!isDoVerification"><mat-icon>verified_user</mat-icon> Do Authorization</button>
      </a>
      </li>
        </ul>
        </div>
    </div>
      <div class="row">
        <div class="col-12">
          <div class="card-body"style="border-top: 3px solid rgb(201, 201, 201)">
            <div class="row" *ngIf="!isLoading">
              <div class="col-12">
                <div *ngIf="activeTab === 'FaxProcess'" class="container-fluid">
                <app-miverification-list (isChecked)="onRowSelected($event)" (lstId)="selectedList($event)"(isLoadTbl)="changeTab('FaxProcess')"
                *ngIf="!isLoading"
                [DataList]="FaxProcessList"
                [activeTab]="activeTab"
                ></app-miverification-list>
                </div>
                <div *ngIf="activeTab === 'On-Demand'" class="container-fluid">
                <app-miverification-list (isChecked)="onRowSelected($event)" (lstId)="selectedList($event)" (isLoadTbl)="changeTab('On-Demand')"
                *ngIf="!isLoading"
                [DataList]="OnDemandList"
                [activeTab]="activeTab"
                ></app-miverification-list>
                </div>
                <div *ngIf="activeTab === 'Authorization'" class="container-fluid">
                <app-miverification-list (isChecked)="onRowSelected($event)" (lstId)="selectedList($event)"(isLoadTbl)="changeTab('Authorization')"
                *ngIf="!isLoading"
                [DataList]="AuthorizationList"
                [activeTab]="activeTab"
                ></app-miverification-list>
                </div>
                <div *ngIf="activeTab === 'VerificationIssues'" class="container-fluid">
                <app-miverification-list (isChecked)="onRowSelected($event)" (lstId)="selectedList($event)"(isLoadTbl)="changeTab('VerificationIssues')"
                *ngIf="!isLoading"
                [DataList]="VerificationIssuesList"
                [activeTab]="activeTab"
                ></app-miverification-list>
                </div>
            </div>
          </div>
          <div class="row" *ngIf="isLoading">
          <div class="col-12">
            <div class="d-flex justify-content-center">
              <img class="img-responsive pb-2 mr-1" src="assets/gifs/Infinity-loader.gif" />
            </div>
          </div>
        </div>
        </div>
      </div>
      </div>
  </div>
</div>
