import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { OrganizationUnitService } from '../../admin-proxy/platform-app-management/rcm/platform-management/organization-units';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { noWhitespaceValidator } from 'projects/patient/src/app/add-note-tab/add-note-tab.component';
import Swal from 'sweetalert2';
import { CreateUpdateFaxUnitDTO, OrganizationUnitDTO } from '../../admin-proxy/platform-app-management/rcm/platform-management/organization-units/dto/models';
import { ToastrService } from 'ngx-toastr';
import { HttpErrorResponse } from '@angular/common/http';
import { MatAccordion } from '@angular/material/expansion';
// import { defaultGuid } from '../../document-perviewer/document-perviewer.component';
import emailMask from 'text-mask-addons/dist/emailMask';
import { TenantsService } from '../../admin-proxy/platform-app-management/rcm/platform-management/tenant/tenants.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { DataTableDirective } from 'angular-datatables';
import { TableService } from 'projects/shared/src/app/table.service';
import { UserProfileDTO } from '../../admin-proxy/platform-app-management/rcm/platform-management/user/models';
import { CommunicationService } from 'projects/shared/src/app/services/communication.service';
import { DatePipe } from '@angular/common';
import { CommonService } from 'projects/shared/src/app/services/common.service';
import { defaultGuid } from 'projects/shared/src/app/enums/allenums.enum';
@Component({
  selector: 'app-fax-email-table',
  templateUrl: './fax-email-table.component.html',
  styleUrls: ['./fax-email-table.component.scss']
})
export class FaxEmailTableComponent implements OnInit {
  @Input() tableFax: any;
  @Input() faxStatusView: number;

  faxDetails: any;
  panelOpenState = false;
  panelOpenState1 = false;
  panelOpenState2 = false;
  isActive: number;
  addWorkingButton: boolean = false
  saveButtonHide: boolean = false;
  isShowSpinner: boolean = false;
  isLoading: boolean=false;
  faxid: string = '';
  tenantId: string = '';
  FaxForm: FormGroup;
  faxName: any;
  param: number;
  param1: number;
  param2: number;
  selectedOption: string;
  isConfirmBranchLocation: boolean = false;
  step: boolean = false;
  seletedBranchValue: string = defaultGuid;
  filteredBranchTypeList: OrganizationUnitDTO[];
  lstBranch: OrganizationUnitDTO[] = [];
  @Input() clientId: string = '';
  @ViewChild('secondAccordion') secondAccordion: MatAccordion;
  @ViewChild(DataTableDirective, { static: false })
  datatableElement: DataTableDirective;
  seletedFaxValue: number;
  emailMask: any;
  hide = true;
  inActiveHide: boolean = true;
  loginResponse: UserProfileDTO;

  BranchTypeControl = new FormControl();
  branchDrpData: any;
  drpBranchType: any;
  dtFaxEmailListOptions: any = {
    responsive: true,
    // stateSave: true,
    paging: false,
    dom: 'Bfrtip',
    language: {
      emptyTable: 'No records',
      info: 'Total : _MAX_ records',
      infoEmpty: 'Total : _MAX_ records',
      infoFiltered: '(filtered : _TOTAL_ records)',
      loadingRecords: 'Loading...',
      zeroRecords: 'No matching records',
    },
    scrollCollapse: true,
    scrollY: '300px',
    scrollX: true,
    fixedHeader: true, // Fix the header
    columnDefs: [
      { targets: [0], orderable: false },
      { targets: [9,10,11,12], visible: false },
    ],
    search: {
      smart: false,
    },
    buttons: [
      {
        extend: 'excel',
        text: 'Export',
        filename: 'Fax/Email List',
        exportOptions: {
          columns: ':visible:not(:first-child) ',
        },
      },
      {
        extend: 'colvis',
        columns: ':not(:eq(0), :eq(1), :eq(2),:eq(-1))',
      }
    ],
  };
  toggle() {
    this.panelOpenState = true;
  }
  toggleV2() {
    this.panelOpenState = false;
  }

  constructor(private branchService: OrganizationUnitService, private formBuilder: FormBuilder, private toastr: ToastrService, private officialService: TenantsService, private snackBar: MatSnackBar, private Table: TableService,
    private communicationService: CommunicationService, private datepipe: DatePipe , private commonService : CommonService
  ) { }


  ngOnInit(): void {
    this.dtFaxEmailListOptions.buttons[0].filename =
    'Fax Email List' + '-' + this.datepipe.transform(new Date(), 'MM-dd-yyyy');
    this.tenantId = localStorage.getItem('tenantId') ?? '';

    this.FaxForm = this.formBuilder.group({
      drpBranchType: new FormControl("", [Validators.required]),
      drpFaxType: new FormControl("", [Validators.required]),
      txtPassword: new FormControl("", [Validators.required]),
      txtEmail: new FormControl("", [Validators.required]),
      //  txtFaxNumber: new FormControl("",[this.checkFaxNoUniqness()]),
      txtFaxNumber: new FormControl(""),
      txtBranchType: new FormControl(""),
      txtFaxName: new FormControl(""),
      txtEmailPort: new FormControl("", [Validators.required]),
      txtEmailProtocol: new FormControl("", [Validators.required]),
      txtEmailHost: new FormControl("", [Validators.required, noWhitespaceValidator]),
    });
    this.loadDropdown();
    this.emailMask = emailMask;
    this.faxDetails = this.tableFax;
    this.fnselectedFaxOption();

    //Branch Search Type
    this.BranchTypeControl.valueChanges.subscribe((searchTerm) => {
      searchTerm = searchTerm.toLowerCase();
      this.filteredBranchTypeList = this.drpBranchType.filter((dx) =>
        dx.organizationUnitName.toLowerCase().includes(searchTerm)
      );
    });
  }

  ngAfterViewInit(): void {
    if (this.datatableElement && this.datatableElement.dtInstance) {
      this.refreshDataTable();
    } else {
      setTimeout(() => this.refreshDataTable(), 500);
    }
  }

  refreshDataTable(): void {
    this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
      // Clear search input values
      $('input', dtInstance.table(0).footer()).val('');
      // Redraw the DataTable to load all records
      dtInstance.search('').columns().search('').draw();
      // Attach the event listener for filtering
      dtInstance.columns().every(function () {
        const that = this;
        $('input', this.footer()).on('keyup change', function () {
          if (that.search() !== this['value']) {
            that.search(this['value']).draw();
          }
        });
      });
    });
  }



  loadDropdown() {
    const getBranchList = this.branchService
      .getBranchList(this.tenantId)
      .subscribe((response) => {
        this.lstBranch = response;
        this.filteredBranchTypeList = [...this.lstBranch];
        this.drpBranchType=this.lstBranch;
      });
  }
  // getOrganizationUnitName(branchId: string, branchName: string): string {
  //   const selectedBranch = this.filteredBranchTypeList.find(branch => branch.id === branchId);
  //   return selectedBranch ? selectedBranch.organizationUnitName : branchName;
  // }
  saveFax() {
    this.isShowSpinner=true;
    this.isLoading=true;
    if (this.FaxForm.value.txtFaxName !== null) {
      this.faxName = (this.FaxForm.value.txtFaxName).trim() || null;
    } else {
      this.faxName = null;
    }
    if (this.faxName === "") {
      this.isShowSpinner=false;
      Swal.fire({
        title: 'Invalid Fax Name!',
        // text: 'By Changing Status, this branch will be deactivated',
        icon: 'warning',
        confirmButtonColor: '#34c38f',
        confirmButtonText: 'OK'
      }).then(result => {
        this.saveButtonHide = false;
        this.isShowSpinner=false;
      })
    } else {
      this.saveButtonHide = true;
      if ((this.faxid == null || this.faxid == "" || this.faxid == undefined)) {
        let fax: CreateUpdateFaxUnitDTO = {
          branchId: this.FaxForm.value.drpBranchType,
          isFax: this.FaxForm.value.drpFaxType,
          mailId: this.FaxForm.value.txtEmail.toLowerCase(),
          tenantId: this.clientId,
          isActive: 1,
          faxName: this.FaxForm.value.txtFaxName,
          faxNumber: this.FaxForm.value.txtFaxNumber,
          mailPassword: this.FaxForm.value.txtPassword,
          portNumber: this.FaxForm.value.txtEmailPort,
          serverProtocol: this.FaxForm.value.txtEmailProtocol,
          hostName: this.FaxForm.value.txtEmailHost,
        };
        this.branchService.createFax(fax).subscribe(response => {
          this.isShowSpinner=false;
          this.faxReset();
          if (this.selectedOption === 'All') {
            this.param1 = 0
          } else if (this.selectedOption === 'Active') { this.param1 = 1; } else {
            this.param1 = 2;
          }
          if(this.faxDetails.length===0){
           this.communicationService.triggerLoadFaxList(this.param1);
          }else{
            if(this.selectedOption !== 'Inactive'){
          const userTimezonelastModificationTime = this.commonService.getFormattedDateTimeZone(response.lastModificationTime);
          response.lastModificationTime = userTimezonelastModificationTime;
          const userTimezonecreationTime = this.commonService.getFormattedDateTimeZone(response.createdDate);
          response.createdDate = userTimezonecreationTime;
          // this.faxDetails.push(response);
          this.faxDetails.unshift(response);
          // this.faxDetails = this.faxDetails.map((data) => {
          //   return {
          //     ...data,
          //     createdDate: this.commonService.getFormattedDateTimeZone(
          //       data.createdDate
          //     ),
          //     lastModificationTime: this.commonService.getFormattedDateTimeZone(
          //       data.lastModificationTime
          //     ),
          //   };
          // });
            }
          }
          this.toastr.success('Saved Successfully', 'Success')
          this.saveButtonHide = false;
          this.isLoading = false;
          // this.FaxStatusFilter(this.param1);--
        }, err => {
          this.isShowSpinner=false;
          this.saveButtonHide = false;
          this.isLoading = false;
          const data: HttpErrorResponse = err;
          Swal.fire({
            icon: 'info',
            text: data?.error?.error?.message,
          });
        });
      }
      else {
        let fax: CreateUpdateFaxUnitDTO = {
          branchId: this.FaxForm.value.drpBranchType,
          isFax: this.FaxForm.value.drpFaxType,
          mailId: this.FaxForm.value.txtEmail.toLowerCase(),
          tenantId: this.clientId,
          isActive: this.isActive,
          faxName: this.FaxForm.value.txtFaxName??'',
          faxNumber: this.FaxForm.value.txtFaxNumber,
          mailPassword: this.FaxForm.value.txtPassword,
          portNumber: this.FaxForm.value.txtEmailPort,
          serverProtocol: this.FaxForm.value.txtEmailProtocol,
          hostName: this.FaxForm.value.txtEmailHost,
        };
        this.branchService.updateFax(this.faxid, fax).subscribe(response => {
          this.isShowSpinner=false;

          this.isConfirmBranchLocation = false;
          //}, err => { });

          this.faxReset(); if (this.selectedOption === 'All') {
            this.param1 = 0
          } else if (this.selectedOption === 'Active') { this.param1 = 1; } else {
            this.param1 = 2;
          }
          // let index = this.faxDetails.findIndex(d => d.id === response.id);
          // this.faxDetails.splice(index,1);
          // this.faxDetails.push(response);
          const index = this.faxDetails.findIndex(
            (obj) => obj.id === response?.id
          );
          if (index !== -1) {
            this.faxDetails[index] = response; //Replace the Object
            const objectToMove = this.faxDetails.splice(index, 1)[0]; // Remove and get the object
            const userTimezonelastModificationTime = this.commonService.getFormattedDateTimeZone(response.lastModificationTime);
            response.lastModificationTime = userTimezonelastModificationTime;
            const userTimezonecreationTime = this.commonService.getFormattedDateTimeZone(response.createdDate);
            response.createdDate = userTimezonecreationTime;
            this.faxDetails.unshift(objectToMove);
            // this.faxDetails = this.faxDetails.map((data) => {
            //   return {
            //     ...data,
            //     createdDate: this.commonService.getFormattedDateTimeZone(
            //       data.createdDate
            //     ),
            //     lastModificationTime: this.commonService.getFormattedDateTimeZone(
            //       data.lastModificationTime
            //     ),
            //   };
            // });
          }
          this.isLoading = false;

          this.toastr.success('Updated Successfully', 'Success')
          this.saveButtonHide = false;
          this.isLoading = false;
          // this.branchStatusFilter(this.param);
          // this.selectedOption="Active";
          //this.communicationService.triggerLoadFaxList(this.param1);
          // this.FaxStatusFilter(this.param1);--C
          this.faxid = '';
        }, err => {
          this.isShowSpinner=false;
          this.saveButtonHide = false;
          this.isLoading = false;
          this.isConfirmBranchLocation = false;
          const data: HttpErrorResponse = err;
          Swal.fire({
            icon: 'info',
            text: data?.error?.error?.message,
          });
        });
      }
    }
  }


  getFaxById(id: string) {
    this.faxid == '' ? this.addWorkingButton = true : this.addWorkingButton = false
    this.branchService.getFax(id).subscribe(data => {
      this.tenantId = this.tenantId,
        this.isActive = data?.isActive,
        this.faxid = data?.id ?? '';

      this.FaxForm.patchValue({
        drpBranchType: data?.branchId,
        drpFaxType: data?.isFax,
        txtFaxName: data?.faxName === '' ? null : data?.faxName,
        txtEmail: data?.mailId,
        txtFaxNumber: data?.faxNumber,
        txtPassword: data?.mailPassword,
        txtEmailPort: data?.portNumber,
        txtEmailProtocol: data?.serverProtocol,
        txtEmailHost: data?.hostName,
      });
    })
  }

  ViewFax(event: { id: string; }) {
    window.scrollTo({ top: 0, behavior: 'smooth' });    // Scroll to the top of the page
    this.step = !this.step;
    this.secondAccordion.id;
    this.panelOpenState = true;
    this.getFaxById(event.id);
  }

  faxReset() {
    this.FaxForm.patchValue({
      drpBranchType: "",
      drpFaxType: "",
      txtEmail: "",
      txtPassword: "",
      txtEmailPort: "",
      txtEmailProtocol: "",
      txtEmailHost: "",
      txtFaxNumber: "",
      txtFaxName: "",
    })
    this.faxid = '';
    this.FaxForm.reset();
    this.FaxForm.markAsUntouched();
    this.FaxForm.updateValueAndValidity();
    // this.getCompanyName(this.loginResponse);
    this.addWorkingButton = false;
  }

  onReferenceChange() {
    this.seletedFaxValue = this.FaxForm.get('drpFaxType').value;
  }

  onRowDoubleClick(textToCopy) {
    this.copyTextToClipboard(textToCopy);
    this.showNotification('Text copied: ' + textToCopy);
  }

  showNotification(message: string): void {
    this.snackBar.open(message, 'Close', {
      duration: 3000, // Duration in milliseconds
      horizontalPosition: 'end', // Positioning the notification horizontally
      verticalPosition: 'bottom', // Positioning the notification vertically
    });
  }

  copyTextToClipboard(text: string): void {
    const textArea = document.createElement('textarea');
    textArea.value = text;
    document.body.appendChild(textArea);
    textArea.select();
    document.execCommand('Copy');
    textArea.remove();
  }

  faxStatusChange(event: MatSlideToggleChange, data: any) {

    if (event.checked == true) {
      Swal.fire({
        title: 'Do you want to activate?',
        text: 'By Changing Status, this Fax/Email will be activated',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#34c38f',
        cancelButtonColor: '#f46a6a',
        confirmButtonText: 'Yes, Activate it!'
      }).then(result => {
        if (result.value) {
        this.isLoading=true;
          if (data?.id && data?.id !== "" && data?.id !== null && data?.id !== undefined) {
            this.branchService.updateFaxEnableDisable(data?.id).subscribe(response => {
              // Swal.fire({ title: 'Success', html: 'Activated Successfully', icon: 'success', timer: 3000, timerProgressBar: true })

              if (this.selectedOption === 'All') {
                this.param1 = 0
              } else if (this.selectedOption === 'Active') { this.param1 = 1; } else {
                this.param1 = 2;
              }
              if(this.selectedOption !=='All'){
                let index = this.faxDetails.findIndex(d => d.id === data?.id);
                this.faxDetails.splice(index,1);
              }else{
                this.faxDetails.find(item => item.id == data?.id).status = 'Active';
              }
              this.toastr.success('Activated Successfully', 'Success')
              this.isLoading=false;
              // this.branchStatusFilter(this.param);
              // this.selectedOption="Active";
              // this.FaxStatusFilter(this.param1);--
             // this.communicationService.triggerLoadFaxList(this.param1);
            }, err => {
              const data: HttpErrorResponse = err;
              this.isLoading=false;
              Swal.fire({
                icon: 'info',
                text: data?.error?.error?.message,
              });
            });
          }
        }
        else {
          event.source.checked = false;
        }
      });
    }
    else
      if (event.checked == false) {
        Swal.fire({
          title: 'Do you want to deactivate?',
          text: 'By Changing Status, this  Fax/Email will be deactivated',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#34c38f',
          cancelButtonColor: '#f46a6a',
          confirmButtonText: 'Yes, deactivate it!'
        }).then(result => {
          if (result.value) {
            if (data?.id && data?.id !== "" && data?.id !== null && data?.id !== undefined) {
              this.branchService.updateFaxEnableDisable(data?.id).subscribe(response => {

                // Swal.fire({ title: 'Success', html: 'Deactivated Successfully', icon: 'success', timer: 3000, timerProgressBar: true })
                if (this.selectedOption === 'All') {
                  this.param1 = 0
                } else if (this.selectedOption === 'Active') { this.param1 = 1; } else {
                  this.param1 = 2;
                }
                if(this.selectedOption !=='All'){
                  let index = this.faxDetails.findIndex(d => d.id === data?.id);
                  this.faxDetails.splice(index,1);
                }else{
                  this.faxDetails.find(item => item.id == data?.id).status = 'Inactive';
                }
                this.isLoading=false;
                this.toastr.success('Deactivated Successfully', 'Success')
                // this.FaxStatusFilter(this.param1);--
               // this.communicationService.triggerLoadFaxList(this.param1);
              }, err => {
                const data: HttpErrorResponse = err;
                Swal.fire({
                  icon: 'info',
                  text: data?.error?.error?.message,
                });
              });
            }

          }
          else {
            event.source.checked = true;
          }
        });
      }
  }

  FaxStatusFilterv1(viewstsid:number){
    this.communicationService.triggerLoadFaxList(viewstsid);
  }

  fnselectedFaxOption() {
    if (this.faxStatusView == 0) {
      this.selectedOption = "All";
    } else if (this.faxStatusView == 1) {
      this.selectedOption = "Active";
    } else if (this.faxStatusView == 2) {
      this.selectedOption = "InActive";
    }
  }
}
