<div class="container-fluid">
  <!-- Headers -->
  <div class="row">
    <div class="col-lg-12">
      <div class="d-flex align-items-center justify-content-between">
        <h3 class="mb-0 header-text">
          Patient Ledger
          <!-- {{ data.chartNo ? "-" + " " + data.chartNo : data.chartNo }} -->
        </h3>

        <div class="page-title-box page-title-right mb-2">
          <button mat-dialog-close mat-button class="closebtn">
            <i class="fa fa-times" style="margin-top: -3px"></i> Cancel
          </button>
        </div>
      </div>
    </div>
    <div class="col-lg-12">
      <mat-divider></mat-divider>
    </div>
  </div>

  <!-- Content-->
  <div class="row mt-2">
    <!--Row 1-->
    <div class="col-lg-12">
      <div class="card card-body px-2 py-2">
        <div class="d-flex justify-content-center" *ngIf="!loadTable">
          <img
            class="img-responsive pb-2 mr-1"
            src="assets/gifs/Infinity-loader.gif"
          />
        </div>

        <div class="row bg mt-1" *ngIf="loadTable">
          <div class="col-10 d-flex justify-content-between cutompoint">
            <h3 class="font-weight-bolder">
              Chart No :
              <span class="app-custom-color font-weight-bolder">{{
                patientCharNo || "--"
              }}</span>
            </h3>
            <h3 class="font-weight-bolder">
              Patient Name :
              <span class="app-custom-color font-weight-bolder">{{
                patientName || "--"
              }}</span>
            </h3>
            <h3 class="font-weight-bolder">
              CSR Name :
              <span class="app-custom-color font-weight-bolder">{{
                csrName || "--"
              }}</span>
            </h3>
            <h3 class="font-weight-bolder mr-3">
              Email :
              <span class="app-custom-color font-weight-bolder">{{
                patientEmail || "--"
              }}</span>
            </h3>
            <!-- <h3 class="font-weight-bolder mr-3">Next Call  : <span
                class="app-custom-color font-weight-bolder">{{patientNextCallDate | date : "MM/dd/yyyy" : "en_US"
                || '--'}}</span></h3>       -->
          </div>
        </div>

        <div class="table table-responsive" *ngIf="loadTable">
          <table
            datatable
            [dtOptions]="dtInboundOptions"
            class="row-border hover w-100 display"
            id="inboundTable"
          >
            <thead>
              <tr>
                <th [matTooltip]="'Options'" class="cutomtblWidth">
                  <mat-icon>apps</mat-icon>
                </th>
                <th [matTooltip]="'Ticket Id'">Ticket Id</th>
                <th [matTooltip]="'Supplies Date'">Supplies Date</th>
                <!-- <th [matTooltip]="'Chart No'">Chart No</th> -->
                <!-- <th [matTooltip]="'Patient Name'">Patient Name</th> -->
                <th [matTooltip]="'Insurance/Cash'">Insurance/Cash</th>
                <th [matTooltip]="'Order Status'" class="text-center">
                  Order Status
                </th>
                <!-- <th [matTooltip]="'CSR'">CSR</th> -->
              </tr>
            </thead>
            <tbody>
              <tr
                [ngClass]="
                  this.tblSelectedOrderId === data.id ? 'row-highlighted' : ''
                "
                (click)="fnSelectedRow(data.id, data)"
                *ngFor="let data of ledgerTableData"
              >
                <td class="cutomtblWidth">
                  <mat-icon
                    class="pointer"
                    [matTooltip]="'Open Options'"
                    [matMenuTriggerFor]="menu"
                    >more_vert</mat-icon
                  >
                  <mat-menu class="custom-mat-menu" #menu="matMenu">
                    <!-- <button mat-menu-item [disabled]="true">
                      Copy Saleorder
                    </button> -->

                    <button (click)="copyOrder(data?.patientId, data?.id)" mat-menu-item [disabled]="isEmpty(data?.patientId)">
                      Copy Sale Order
                    </button>

                    <button
                      mat-menu-item
                      [disabled]="
                        tblSelectedOrderId == defaultGuid ||
                        priVerificationId === defaultGuid ||
                        isCashOrder
                      "
                      (click)="openViewVerificationModal('Primary')"
                    >
                      Primary Insurance
                    </button>

                    <button
                      mat-menu-item
                      [disabled]="
                        tblSelectedOrderId == defaultGuid ||
                        secVerificationId === defaultGuid ||
                        isCashOrder
                      "
                      (click)="openViewVerificationModal('secondary')"
                    >
                      Secondary Insurance
                    </button>

                    <a
                      [class.disabled]="
                        tblSelectedOrderId == defaultGuid ||
                        data.checkListId === defaultGuid ||
                        isCashOrder
                      "
                      target="_blank"
                      class="preview-link"
                      [routerLink]="
                        '/view_checklist/' +
                        data.patientId +
                        '/' +
                        true +
                        '/' +
                        true +
                        '/' +
                        data.checkListId
                      "
                    >
                      <button mat-menu-item>Check List</button>
                    </a>

                    <button mat-menu-item (click)="getPrintTicketDetails()">
                      FSA
                    </button>

                    <button
                      mat-menu-item
                      [disabled]="data?.patientId === defaultGuid"
                      (click)="openAddNotesPopUp(data)"
                    >
                      Add Patient Note
                    </button>

                    <button
                      mat-menu-item
                      [disabled]="data?.patientId === defaultGuid"
                      (click)="openViewNotesPopup(data.patientId)"
                    >
                      View Patient Notes
                    </button>

                    <a
                      [class.disabled]="
                        isEmpty(data?.docId) || data?.isSorted === false
                      "
                      [routerLink]="
                        '/view_document_tiff/' + data?.docId + '/' + true
                      "
                      target="_blank"
                      class="preview-link"
                    >
                      <button
                        [disabled]="
                          isEmpty(data?.docId) || data?.isSorted === false
                        "
                        mat-menu-item
                      >
                        View Document Images
                      </button>
                    </a>
                  </mat-menu>
                </td>

                <td [matTooltip]="data?.mmRefereceOrderId || '-'">
                  <a
                    class="preview-linkV1"
                    [class.disabled]="
                      isEmpty(data.patientId) || isEmpty(data.id)
                    "
                    [routerLink]="
                      '/orders/saleOrder/' +
                      data.patientId +
                      '/' +
                      data.id +
                      '/' +
                      '0'
                    "
                    target="_blank"
                  >
                    {{ data?.mmRefereceOrderId || "-" }}
                  </a>
                </td>

                <!-- <td
                  [matTooltip]="
                    data.mmRefereceOrderId == 0 ? '-' : data.mmRefereceOrderId
                  "
                >
                  {{
                    data.mmRefereceOrderId == 0 ? "-" : data.mmRefereceOrderId
                  }}
                </td> -->
                <td [matTooltip]="data.suppliesDate">
                  {{ data.suppliesDate }}
                </td>
                <!-- <td [matTooltip]="data.chartNo">{{ data.chartNo }}</td>
                <td [matTooltip]="data.patientName">{{ data.patientName }}</td> -->
                <td
                  [matTooltip]="
                    data.cashOrder
                      ? 'Cash'
                      : data.policyName === null || data.policyName === ''
                      ? '-'
                      : data.policyName
                  "
                >
                  {{
                    data.cashOrder
                      ? "Cash"
                      : data.policyName === null || data.policyName === ""
                      ? "-"
                      : data.policyName
                  }}
                </td>

                <!-- <td
                  [matTooltip]="
                    data.orderStatusName ? data.orderStatusName : '-'
                  "
                >
                  {{ data.orderStatusName ? data.orderStatusName : "-" }}
                </td> -->

                <!-- <td class="text-center">
                  <div class="d-flex align-items-center justify-content-between">
                    <span
                      [matTooltip]="data?.orderStatusName || '-'"
                      [style.color]="updateTextColor(data?.bgColor)"
                      [style.backgroundColor]="data?.bgColor"
                      class="rounded-border ml-1 w-75 p-2 claim-status-label"
                    >
                      {{ data?.orderStatusName || "-" }}
                    </span>
                  </div>
                </td> -->

                <td class="text-center">
                  <div
                    class="d-flex align-items-center justify-content-between"
                  >
                    <span
                      [matTooltip]="data?.orderStatusName || '-'"
                      [style.color]="updateTextColor(data?.orderStatusName)"
                      [style.backgroundColor]="
                        getStatusColor(data?.orderStatusName)
                      "
                      class="rounded-border ml-1 w-75 p-2 claim-status-label"
                    >
                      {{ data?.orderStatusName || "-" }}
                    </span>
                  </div>
                </td>

                <!-- <td [matTooltip]="data.csrName ? data.csrName : '-'">
                  {{ data.csrName ? data.csrName : "-" }}
                </td> -->
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>

    <!-- CSR Details -->
    <!-- <div class="col-lg-2">
      <div class="card elevated-div card-body py-0">
        <form [formGroup]="patientLedgerForm" class="mt-2">
          <mat-form-field class="w-100">
            <mat-label>CSR</mat-label>
            <input
              readonly
              class="dd-text"
              matInput
              maxlength="40"
              formControlName="txtCSR"
              type="text"
              placeholder="CSR"
              autocomplete="off"
            />
          </mat-form-field>
          <mat-form-field class="w-100">
            <mat-label>Phone</mat-label>
            <input
              matInput
              class="dd-text"
              readonly
              maxlength="40"
              formControlName="txtPhone"
              type="text"
              value="+1875157554"
              placeholder="Phone"
              autocomplete="off"
            />
          </mat-form-field>
          <mat-form-field class="w-100">
            <mat-label>Email</mat-label>
            <input
              matInput
              readonly
              class="dd-text"
              maxlength="40"
              formControlName="txtEmail"
              type="text"
              placeholder="Email"
              autocomplete="off"
            />
          </mat-form-field>
          <mat-form-field class="w-100">
            <mat-label>Next Call</mat-label>
            <input
              matInput
              readonly
              class="dd-text"
              maxlength="40"
              formControlName="txtNextCall"
              type="text"
              placeholder="Next Call"
              autocomplete="off"
            />
          </mat-form-field>
          <mat-form-field class="w-100">
            <mat-label>Billing</mat-label>
            <textarea
              readonly
              formControlName="txtBillingNotes"
              rows="3"
              class="dd-text"
              placeholder="Billing"
              maxlength="5000"
              matInput
            ></textarea>
          </mat-form-field>
          <mat-form-field class="w-100">
            <mat-label>Shipping</mat-label>
            <textarea
              readonly
              formControlName="txtShippingNotes"
              rows="3"
              class="dd-text"
              placeholder="Shipping"
              maxlength="5000"
              matInput
            ></textarea>
          </mat-form-field>
        </form>
      </div>
    </div> -->

    <!-- CSR Details V1-->
    <div class="col-lg-12 mt-3">
      <div class="card card-body px-1 py-1">
        <!-- <div class="card elevated-div card-body py-0"> -->
        <form [formGroup]="patientLedgerForm">
          <!-- Next Call Date Field -->
          <mat-form-field class="col-2">
            <mat-label>Next Call Date </mat-label>
            <input
              type="text"
              matInput
              autocomplete="new-txtNextCalldate"
              maxlength="10"
              [matDatepicker]="datepicker"
              placeholder="MM/DD/YYYY"
              [min]="minDate"
              formControlName="txtNextCalldate"
              (keydown)="addSlashToDate($event)"
              [readonly]="ledgerTableData.length === 0"
            />
            <!-- [readonly]="!patientLedgerForm.get('txtNextCalldate').value" -->
            <mat-datepicker-toggle
              matSuffix
              [for]="datepicker"
              [attr.aria-label]="customLabel"
              [disabled]="ledgerTableData.length === 0"
            ></mat-datepicker-toggle>
            <!-- [disabled]="!patientLedgerForm.get('txtNextCalldate').value" -->
            <mat-datepicker #datepicker></mat-datepicker>
            <mat-error
              *ngIf="
                patientLedgerForm.get('txtNextCalldate').touched &&
                patientLedgerForm.get('txtNextCalldate').hasError('required')
              "
            >
              Next Call Date is required/Invalid!
            </mat-error>
            <mat-error
              *ngIf="
                patientLedgerForm.get('txtNextCalldate').touched &&
                patientLedgerForm
                  .get('txtNextCalldate')
                  .hasError('minDateError')
              "
            >
              Next Call Date cannot be before today!
            </mat-error>
            <mat-error
              *ngIf="
                patientLedgerForm.get('txtNextCalldate').touched &&
                patientLedgerForm.get('txtNextCalldate').hasError('invalidYear')
              "
            >
              Enter Valid date MM/DD/YYYY ..!
            </mat-error>
          </mat-form-field>
          <mat-icon
            matSuffix
            class="char-count"
            [ngClass]="{ 'disabled-link': patientLedgerForm.invalid }"
            (click)="onDateChange()"
            matTooltip="Update Next Call Date"
          >
            check_circle
          </mat-icon>

          <!-- Next Call Date Field -->
          <!-- <mat-form-field class="col-2">
            <mat-label>Next Call Date </mat-label>
            <input
            type="text"
              matInput
              autocomplete="new-txtNextCalldate"
              maxlength="10"
              [matDatepicker]="datepicker"
              placeholder="MM/DD/YYYY"
              [min]="minDate"
              formControlName="txtNextCalldate"
              (keydown)="addSlashToDate($event)"
            />
            <mat-datepicker-toggle
              matSuffix
              [for]="datepicker"
              [attr.aria-label]="customLabel"
            ></mat-datepicker-toggle>
            <mat-datepicker #datepicker></mat-datepicker>
              <mat-error
                *ngIf="
                patientLedgerForm.get('txtNextCalldate').touched &&
                patientLedgerForm.get('txtNextCalldate').hasError('required')
                "
              >
                Next Call Date is required/Invalid!
              </mat-error>
              <mat-error
                *ngIf="
                patientLedgerForm.get('txtNextCalldate').touched &&
                patientLedgerForm.get('txtNextCalldate').hasError('minDateError')
                "
              >
                Next Call Date cannot be before today!
              </mat-error>
              <mat-error
                *ngIf="
                patientLedgerForm.get('txtNextCalldate').touched &&
                patientLedgerForm.get('txtNextCalldate').hasError('invalidDate')
                "
              >
                Enter Valid date MM/DD/YYYY ..!
              </mat-error>
          </mat-form-field>
          <mat-icon matSuffix class="char-count" (click)="onDateChange()">check_circle</mat-icon> -->
          <!-- <mat-form-field class="col-2">
            <mat-label>Next Call Date</mat-label>
            <input autocomplete="new-txtNextCalldate" formControlName="txtNextCalldate" matInput maxlength="10"
                type="text" placeholder="(MM/DD/YYYY)" [matDatepicker]="txtNextCalldate" (keydown)="addSlashToDate($event)"/>
            <mat-datepicker-toggle matSuffix [for]="txtNextCalldate"></mat-datepicker-toggle>
            <mat-datepicker #txtNextCalldate></mat-datepicker>
          </mat-form-field>
          <mat-icon matSuffix class="char-count" (click)="onDateChange()">check_circle</mat-icon> -->
          <!-- Billing Text Field -->
          <mat-form-field class="col-5">
            <mat-label>Billing</mat-label>
            <textarea
              readonly
              formControlName="txtBillingNotes"
              rows="6"
              class="dd-text reSize"
              placeholder="Billing"
              maxlength="5000"
              matInput
            ></textarea>
          </mat-form-field>

          <!-- Shipping Text Field -->
          <mat-form-field class="col-5">
            <mat-label>Shipping</mat-label>
            <textarea
              readonly
              formControlName="txtShippingNotes"
              rows="6"
              class="dd-text reSize"
              placeholder="Shipping"
              maxlength="5000"
              matInput
            ></textarea>
          </mat-form-field>
        </form>
        <!-- </div> -->
      </div>
    </div>
    <!--Row 2-->
    <!-- <div class="col-lg-12">
      <div class="card card-body elevated-div mt-1">
        <div class="row">

          <button
            mat-raised-button
            class="mx-2 buttonColor"
            (click)="getPrintTicketDetails()"
          >
            <mat-icon>search</mat-icon>Print Ticket
          </button>

          <button
            mat-raised-button
            class="mx-2 buttonColor"
            [disabled]="
              tblSelectedOrderId == defaultGuid ||
              priVerificationId === defaultGuid
            "
            (click)="openViewVerificationModal('Primary')"
          >
            <mat-icon> bubble_chart</mat-icon>Primary Ins
          </button>

          <button
            mat-raised-button
            class="mx-2 buttonColor"
            [disabled]="
              tblSelectedOrderId == defaultGuid ||
              secVerificationId === defaultGuid
            "
            (click)="openViewVerificationModal('secondary')"
          >
            <mat-icon class="algin-icon"> linear_scale</mat-icon>Secondary Ins
          </button>
          <a
            mat-raised-button
            class="mx-2 buttonColor preview-link"
            [disabled]="
              tblSelectedOrderId == defaultGuid || checkListId === defaultGuid
            "
            target="_blank"
            [routerLink]="
              '/view_checklist/' +
              data.patientId +
              '/' +
              true +
              '/' +
              true +
              '/' +
              checkListId
            "
          >
            <mat-icon>check</mat-icon>Check List
          </a>
        </div>
      </div>
    </div> -->
    <!-- Row 3-->
    <div class="col-lg-2 mt-3">
      <div class="card mb-2 elevated-div content">
        <div class="table-responsive">
          <table datatable [dtOptions]="dtApprovalOptions" class="tbl hover row-border hover w-100 display">
            <thead>
              <tr>
                <th>Approval No</th>
                <th>Amount</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let data of authTableData">
                <td [matTooltip]="data.authId">
                  {{ data.approvalNo ? data.approvalNo : "-" }}
                </td>
                <td [matTooltip]="data.amount">{{ data.amount }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>

    <div class="col-lg-10 mt-3">
      <div class="card card-body px-2 py-2">
        <div class="d-flex justify-content-center" *ngIf="!isTableLoad">
          <img
            class="img-responsive pb-2 mr-1"
            src="assets/gifs/Infinity-loader.gif"
          />
        </div>
        <div class="table-responsive">
          <app-view-ledager
            [tableData]="itemTableData"
            *ngIf="isTableLoad"
          ></app-view-ledager>

          <!-- <table
            datatable
            [dtOptions]="dtItemOptions"
            *ngIf="isTableLoad"
            class="table row-border hover w-100 display" id="itemTable"
          >
            <thead>
              <tr>
                <th [matTooltip]="'Prod Code'">Product Code</th>
                <th [matTooltip]="'Description'">Description</th>
                <th [matTooltip]="'Size'">Size</th>
                <th [matTooltip]="'Qty'" class="text-right">Quantity</th>
                <th [matTooltip]="'Back Order Since'">Back Order Since</th>
                <th [matTooltip]="'Serial/Lot No'">Serial/Lot No</th>
                <th [matTooltip]="'Tracking'">Tracking</th>
                <th [matTooltip]="'Shipping Date'">Shipping Date</th>
                <th [matTooltip]="'Packed'">Packed</th>
              </tr>
            </thead>
            <tbody>
              <tr
                (click)="tblItemSelectedRow(data.mmOrderItemId, data)"
                [ngClass]="
                  this.tblItemSelectedRowId === data.mmOrderItemId
                    ? 'row-highlighted'
                    : ''
                "
                *ngFor="let data of itemTableData"
              >
                <td [matTooltip]="data?.productCode">
                  {{ data?.productCode }}
                </td>

                <td
                  [matTooltip]="
                    data?.productDescription ? data?.productDescription : ''
                  "
                >
                  {{
                    data?.productDescription
                      ? data?.productDescription
                      : data?.description
                  }}
                </td>
                <td [matTooltip]="data.sizeName ? data.sizeName : '-'">
                  {{ data.sizeName ? data.sizeName : "-" }}
                </td>

                <td class="text-right" [matTooltip]="data.qty ? data.qty : '-'">
                  {{ data.qty }}
                </td>
                <td
                  [matTooltip]="
                    isDefaultDate(data.backOrderedSince)
                      ? '-'
                      : (data.backOrderedSince | date : 'MM/dd/yyyy' : 'en_US')
                  "
                >
                  {{
                    isDefaultDate(data.backOrderedSince)
                      ? "-"
                      : (data.backOrderedSince | date : "MM/dd/yyyy" : "en_US")
                  }}
                </td>
                <td [matTooltip]="data.serialLotName || '-'">
                  {{ data.serialLotName }}
                </td>
                <td [matTooltip]="data.trackingNumber || '-'">
                  {{ data.trackingNumber ? data.trackingNumber : "-" }}
                </td>

                <td
                  [matTooltip]="
                    isDefaultDate(data.shippingDate)
                      ? '-'
                      : (data.shippingDate | date : 'MM/dd/yyyy' : 'en_US')
                  "
                >
                  {{
                    isDefaultDate(data.shippingDate)
                      ? "-"
                      : (data.shippingDate | date : "MM/dd/yyyy" : "en_US")
                  }}
                </td>
                <td [matTooltip]="data.packedby || '-'">
                  {{ data.packedby }}
                </td>
              </tr>
            </tbody>
          </table> -->
        </div>
      </div>
    </div>

    <!-- <div class="col-lg-2 mt-1">
      <div class="card elevated-div card-body p-0 mb-0">
        <div *ngIf="loadhcpcTbl" class="table-container">
          <table class="table table-bordered mb-0">
            <thead>
              <tr>
                <th class="custom-th">HCPC</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let data of hcpcTableData">
                <td>{{ data.hcpcCode }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div> -->
  </div>
</div>
