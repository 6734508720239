import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import {
  PatientPersonalService,
  PatientPolicyService,
} from '../patient-proxy/patient-optimization';
import { FormGroup } from '@angular/forms';
import { ProviderNPIdetailsDTO } from '../patient-proxy/patient-optimization/dto';
import { ProductDetailsService } from 'projects/inventory/src/app/item-proxy/item-management/optimization';
import { HttpErrorResponse } from '@angular/common/http';
import Swal from 'sweetalert2';
import { concatMap } from 'rxjs/operators';
import {
  inputs,
  outputs,
} from '@syncfusion/ej2-angular-buttons/src/button/button.component';
import { Title } from '@angular/platform-browser';
import { TableService } from 'projects/shared/src/app/table.service';
import { LoaderService } from '../../../../shared/src/app/services/loader-service';

@Component({
  selector: 'app-patient-authorization-details',
  templateUrl: './patient-authorization-details.component.html',
  styleUrls: ['./patient-authorization-details.component.scss'],
})
export class PatientAuthorizationDetailsComponent implements OnInit {
  ltWipId: string[] = [];
  step: boolean = false;
  isSupplies: boolean = false;
  ltProducts: any;
  tabDatas: any;
  ltPatientInfo: any[] = [];
  @Input() patientData: string;
  @Input() referenceData: string;
  subscription$: Subscription[] = [];
  constructor(
    private router: ActivatedRoute,
    private personalService: PatientPersonalService,
    private patientPolicyService: PatientPolicyService,
    private productDetailsService: ProductDetailsService,
    private routerService: Router,
    private title: Title,
    private table: TableService,
    private loaderService: LoaderService
  ) {}
  isLoading: any;
  ngOnInit(): void {
    this.title.setTitle('Qsecure | Alloted Authorization');
    this.loaderService.getLoaderState().subscribe((isLoading) => {
      this.isLoading = isLoading;
    });
    const activatedRoute = this.router.paramMap.subscribe(
      (response) => {
        const tabValue = response.get('Id');
        if (tabValue == 'newSetup') {
          this.tabDatas = 0;
        } else if (tabValue == 'supplies') {
          this.tabDatas = 1;
          this.isSupplies = true;
        }
      },
      (err) => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      }
    );

    this.getPatientAuthDetails();
  }

  //Load Dropdowns
  loadDropdowns() {
    //Products Dropdown data
    const getProduct = this.productDetailsService
      .getProductDetailsforSaleOrder()
      .subscribe(
        (response) => {
          this.ltProducts = response.items ?? [];
        },
        (err) => {
          const data: HttpErrorResponse = err;
          Swal.fire({
            icon: 'info',
            text: data?.error?.error?.message,
          });
        }
      );
    this.subscription$.push(getProduct);
  }

  //Get Basic Details
  getPatientAuthDetails() {

    this.loaderService.showLoader();
    this.personalService.getPatientBasicDetails(this.tabDatas).subscribe(
      (response) => {
        this.loaderService.hideLoader();
        this.ltPatientInfo = response;
      },
      (err) => {
        this.loaderService.hideLoader();
        Swal.fire({
          icon: 'error',
          text: 'Something Went Wrong!',
        });
        this.redirectToAuthList();
      }
    );
  }

  redirectToAuthList() {
    this.routerService.navigate(['authorization-list/']);
  }
}
