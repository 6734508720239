import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-text-conversation-csr',
  templateUrl: './text-conversation-csr.component.html',
  styleUrls: ['./text-conversation-csr.component.scss']
})
export class TextConversationCsrComponent implements OnInit {
  selectedRow: number = null;
  constructor() { }

  ngOnInit(): void {
  }

  selectRow(rowNumber: number): void {
    if (this.selectedRow === rowNumber) {
      this.selectedRow = null; // Deselect the row if it's already selected
    } else {
      this.selectedRow = rowNumber; // Select the clicked row

    }
  }
}
