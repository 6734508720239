import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';

@Component({
  selector: 'app-topbar',
  templateUrl: './topbar.component.html',
  styleUrls: ['./topbar.component.scss']
})
export class TopbarComponent implements OnInit {
  @Output() mobileMenuButtonClicked = new EventEmitter();
  constructor( private router: Router) { }

  ngOnInit(): void {
    this.toggleMobileMenu();
  }

  /**
 * Toggle the menu bar when having mobile screen
 */
   toggleMobileMenu(event?: any) {
    if (event) {
      event.preventDefault();
    }
    this.mobileMenuButtonClicked.emit();
  }

  /**
   * Logout the user
   */
  logout() {
    // this.authService.logout();
    // this.router.navigate(['/account/login']);
  }
}
