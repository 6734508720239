<br />
<div class="card card-body">
  <div class="row">
    <div class="col-md-10">
    </div>
    <div class="col-2">
      <app-toggle-switch *ngIf="showDelete" [(chkValue)]='chkStatus' [onText]="'Inactive'" [offText]="'Active'"
        class="mgnr" (changed)="change($event)" (click)="$event.stopPropagation()">
      </app-toggle-switch>
    </div>
  </div>

  <!-- <mat-accordion>
        <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
            <mat-expansion-panel-header>
                <mat-panel-title class="customThemeClass">
                    <b>Add Official Details</b>
                </mat-panel-title>

            </mat-expansion-panel-header> -->
  <form [formGroup]="UserForm">
    <div class="row">
      <div class="col-lg-12">
        <mat-card-content>
          <div class="row">
            <input autocomplete="new-imgLogo" hidden formControlName="imgLogo" size="20" accept="image/jpeg" [multiple]="false"
              (change)="onFileSelected(fileInput.files[0])" #fileInput type="file" id="file">
            <div class="col-3">
              <div class="image-container">
                <img class="profile-image" [src]="fileAs64Value" title="Profile" alt="">
                <div class="change-image-icon-container">
                  <a class="image-icon-container" matTooltip="Change Logo" (click)="fileInput.click()"
                    aria-label="Change profile picture." target="_top">
                    <svg class="icon-container" enable-background="new 0 0 24 24" focusable="false" height="22"
                      viewBox="0 0 24 24" width="22">
                      <path style="fill: #9c9c9c"
                        d="M20 5h-3.17L15 3H9L7.17 5H4c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V7c0-1.1-.9-2-2-2zm0 14H4V7h16v12zM12 9c-2.21 0-4 1.79-4 4s1.79 4 4 4 4-1.79 4-4-1.79-4-4-4z">
                      </path>
                    </svg>
                  </a>
                </div>
              </div>
              <ng-container *ngIf="showErrorForFileType">
                <span class="asterisk">{{ 'Select Only Image type of JPEG' }}</span>
              </ng-container>
              <ng-container *ngIf="showErrorBasedOnSize">
                <span class="asterisk">{{ 'Select Image`s size of 5mb or Less' }}</span>
              </ng-container>
            </div>

            <mat-form-field class="col-3">
              <mat-label> Company Name <span class="asterisk">*</span></mat-label>

              <!-- <input oninput="this.value = this.value.replace(/[^0-9.]/g, '').
              replace(/(\..*?)\..*/g, '$1').replace(/^(.*\.\d\d).*$|(\..*)$/g, '$1$2');"/> -->
              <input autocomplete="new-txtCompanyName" matInput  formControlName="txtCompanyName" type="text"
              oninput="this.value = this.value.charAt(0).toUpperCase() + this.value.slice(1); this.value = this.value.replace('^[a-zA-Z0-9_-]+(?:\s+[a-zA-Z0-9_-]+)*$', '').replace(/(\..*?)\..*/g, '$1').replace(/^(.*\.\d\d).*$|(\..*)$/g, '$1$2');"  />
              <mat-error *ngIf="UserForm?.get('txtCompanyName')?.touched &&
              UserForm?.get('txtCompanyName')?.errors?.required ">
                Company Name is a required field!
              </mat-error>
              <mat-error *ngIf="UserForm?.get('txtCompanyName')?.errors?.isTenantNameExists">
                Company Name is already taken!
              </mat-error>
              <!-- <mat-error *ngIf="UserForm?.get('txtCompanyName')?.errors?.pattern">
                Company Name may only contain letters, numbers, periods, hyphens, or underscores.
              </mat-error> -->

            </mat-form-field>
            <mat-form-field class="col-3">
              <mat-label> Email <span class="asterisk">*</span></mat-label>
              <input matInput maxlength="40" formControlName="txtEmail" type="text" autocomplete="new-txtEmail"
              pattern="[a-zA-Z0-9._!#$%&'()*+,-./:;<=>?@[\]^_`{|}~]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$"
                [textMask]="{ mask: emailMask }" />

              <mat-error *ngIf="UserForm?.get('txtEmail')?.touched &&
                                      UserForm?.get('txtEmail')?.errors?.required ">
                Email Address is a required field!
              </mat-error>
              <mat-error *ngIf="
                                      UserForm?.get('txtEmail')?.touched &&
                                      UserForm?.get('txtEmail')?.errors?.pattern
                                      ">
                Please Enter a Valid Email Address
              </mat-error>
              <mat-error *ngIf="UserForm?.get('txtEmail')?.errors?.isEmailAddressExists">
                Email Address is already taken!
              </mat-error>
            </mat-form-field>
            <mat-form-field class="col-3">
              <mat-label> Password <span *ngIf="!clientId" class="asterisk">*</span></mat-label>
              <input matInput maxlength="40" [type]="hide ? 'password' : 'text'" formControlName="txtPassword" autocomplete="new-password"
                pattern="(?=.*\d)(?=.*[a-z])(?=.*[$@$#!%*?^()&])(?=.*[A-Z]).{8,}" type="text" />
              <a class="font-size-20" mat-icon-button matSuffix (click)="hide = !hide">
                <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
              </a>
              <mat-error *ngIf="
                                      UserForm?.get('txtPassword')?.touched &&
                                      UserForm?.get('txtPassword')?.errors?.required
                    ">
                Password is a required field!
              </mat-error>

              <mat-error *ngIf="
                    UserForm?.get('txtPassword')?.touched &&
                    UserForm?.get('txtPassword')?.errors?.pattern
                  ">
                <!-- Passwords Must Contains at least one non alphanumeric character,one uppercase,one digit
                and at
                least 8 or more characters -->
                Password Must Contains ([A-Z],[a-z],[0-9],[!@#$%^&*])
              </mat-error>
            </mat-form-field>

            <mat-form-field class="col-3 mt-2">
              <mat-label> CEO </mat-label>
              <input autocomplete="new-txtCEO" matInput maxlength="40" formControlName="txtCEO" type="text" oninput="this.value = this.value.charAt(0).toUpperCase() + this.value.slice(1); this.value = this.value.replace('^[a-zA-Z0-9_-]+(?:\s+[a-zA-Z0-9_-]+)*$', '').replace(/(\..*?)\..*/g, '$1').replace(/^(.*\.\d\d).*$|(\..*)$/g, '$1$2');"/>
            </mat-form-field>

            <mat-form-field class="col-3 mt-2">
              <mat-label> Tax Id <span class="asterisk">*</span></mat-label>
              <input autocomplete="new-txtTaxId" matInput maxlength="15" formControlName="txtTaxId" pattern="^[a-zA-Z0-9!@#$%^&*()-_+=<>?/{}[\]|\.,;:'`~]*$" type="text" />
              <mat-error
                *ngIf=" UserForm?.get('txtTaxId')?.touched && UserForm?.get('txtTaxId')?.errors?.pattern">
                Enter valid Tax  Id
              </mat-error>
              <mat-error *ngIf="UserForm?.get('txtTaxId')?.errors?.isTaxIdExists">
                Tax  Id is already taken!
              </mat-error>
              <mat-error *ngIf="UserForm?.get('txtTaxId')?.touched &&
              UserForm?.get('txtTaxId')?.errors?.required ">
              Tax  Id is a required field!
                </mat-error>
            </mat-form-field>

            <mat-form-field class="col-3 mt-2">
              <mat-label> Telephone No</mat-label>
              <input autocomplete="new-txtTelephone" matInput maxlength="40" formControlName="txtTelephone" prefix="{{phoneCode}}-"
                mask="(000)-000-0000" type="text" />
              <mat-error *ngIf="
                            UserForm?.get('txtTelephone')?.touched &&
                            UserForm?.get('txtTelephone')?.errors
               ">
                Enter Minimum 10 Digits!
              </mat-error>
            </mat-form-field>

            <mat-form-field class="col-3 mt-2">
              <mat-label>Location</mat-label>
              <input autocomplete="new-txtLocation" matInput formControlName="txtLocation" type="text"
              oninput="this.value = this.value.charAt(0).toUpperCase() + this.value.slice(1); this.value = this.value.replace('^[a-zA-Z0-9_-]+(?:\s+[a-zA-Z0-9_-]+)*$', '').replace(/(\..*?)\..*/g, '$1').replace(/^(.*\.\d\d).*$|(\..*)$/g, '$1$2');" />
            </mat-form-field>

            <mat-form-field class="col-3">
              <mat-label> Address</mat-label>
              <textarea autocomplete="new-txtAddress" formControlName="txtAddress" rows="3" matInput maxlength="100"
              oninput="this.value = this.value.charAt(0).toUpperCase() + this.value.slice(1); this.value = this.value.replace('^[a-zA-Z0-9_-]+(?:\s+[a-zA-Z0-9_-]+)*$', '').replace(/(\..*?)\..*/g, '$1').replace(/^(.*\.\d\d).*$|(\..*)$/g, '$1$2');" ></textarea>
              <!-- <mat-error *ngIf="
              UserForm?.get('txtAddress')?.touched &&
              UserForm?.get('txtAddress')?.errors
               ">
                Invalid Address
              </mat-error> -->
            </mat-form-field>

            <mat-form-field class="col-3">
              <mat-label>Country</mat-label>
              <mat-select formControlName="drpCountry" (selectionChange)='onChangeState($event.value)'
                [(value)]="Country" #singleSelect>
                <mat-option>
                  <ngx-mat-select-search placeholderLabel="Search" noEntriesFoundLabel="No Matches found"
                    formControlName="txtCountryFilter"></ngx-mat-select-search>
                </mat-option>
                <mat-option *ngFor="let country of filteredCountries | async" [value]="country.countryShortName">
                  {{ country.countryName }}
                </mat-option>
              </mat-select>
              <button mat-button (click)="Country='';$event.stopPropagation()" *ngIf="Country" (click)="clearCountry()"
                matSuffix mat-icon-button>
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field>
            <mat-form-field class="col-3">
              <mat-label>State</mat-label>
              <mat-select formControlName="txtState" (selectionChange)='onChangeCity($event.value,"")' [(value)]="State"
                #singleSelect>
                <mat-option>
                  <ngx-mat-select-search placeholderLabel="Search" noEntriesFoundLabel="No Matches found"
                    formControlName="txtStateFilter"></ngx-mat-select-search>
                </mat-option>
                <mat-option *ngFor="let state of filteredStates | async" [value]="state.stateShortName">
                  {{ state.stateName }}
                </mat-option>
              </mat-select>
              <button mat-button (click)="State='';$event.stopPropagation()" *ngIf="State" (click)="clearState()"
                matSuffix mat-icon-button>
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field>
            <mat-form-field class="col-3">
              <mat-label>City</mat-label>
              <mat-select formControlName="txtCity" [(value)]="City" #singleSelect>
                <mat-option>
                  <ngx-mat-select-search placeholderLabel="Search" noEntriesFoundLabel="No Matches found"
                    formControlName="txtCityFilter"></ngx-mat-select-search>
                </mat-option>
                <mat-option *ngFor="let city of filteredCities | async" [value]="city.cityName">
                  {{ city.cityName }}
                </mat-option>
              </mat-select>
              <button mat-button (click)="City='';$event.stopPropagation()" *ngIf="City" matSuffix (click)="clearCity()"
                mat-icon-button>
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field>
            <mat-form-field class="col-2">
              <mat-label> Zip Code</mat-label>
              <input autocomplete="new-txtPostalCode" matInput maxlength="10" formControlName="txtPostalCode" pattern="[0-9-]*" type="text" class="zip-code-input"/>
              <mat-error
                *ngIf=" UserForm?.get('txtPostalCode')?.touched && UserForm?.get('txtPostalCode')?.errors?.pattern">
                Enter only Numbers!
              </mat-error>
            </mat-form-field>
            <!-- <mat-form-field class="col-3">
              <mat-label>Web Site</mat-label>
              <input autocomplete="new-txtWebSite" matInput maxlength="200" formControlName="txtWebSite" placeHolderCharacter="" type="text"  oninput="this.value = this.value.charAt(0).toUpperCase() + this.value.slice(1); this.value = this.value.replace('^[a-zA-Z0-9_-]+(?:\s+[a-zA-Z0-9_-]+)*$', '').replace(/(\..*?)\..*/g, '$1').replace(/^(.*\.\d\d).*$|(\..*)$/g, '$1$2');" >
            </mat-form-field> -->
            <mat-form-field class="col-3">
              <mat-label>Web Site</mat-label>
              <input autocomplete="new-txtWebSite" matInput maxlength="200" formControlName="txtWebSite" placeHolderCharacter="" type="text" minlength="8">
              <mat-error *ngIf=" UserForm?.get('txtWebSite')?.touched && UserForm?.get('txtWebSite')?.errors?.pattern">Please provide valid Website URL</mat-error>
            </mat-form-field>
            <div class="col-2">
              <br>
              <mat-checkbox formControlName="chkDocumentRule" *ngIf="false">Document Rule Check</mat-checkbox>
              <mat-checkbox formControlName="chkIsInventoryNeeded">Is Inventory Needed?</mat-checkbox>
            </div>
            <div class="col-5">
              <label id="example-radio-group-label">Select Clearing House</label><br>

              <mat-radio-group aria-label="Select an option" formControlName="txtClearingHouse">
                <mat-radio-button  [checked]="true"  [value]="1">Both</mat-radio-button>
                <mat-radio-button style="margin-left: 90px !important;" [value]="2">zermid</mat-radio-button>
                <mat-radio-button style="margin-left: 90px !important;" [value]="3">ChangeHealthCare</mat-radio-button>
              </mat-radio-group>
            </div>

            <div class="col-lg-6" style="margin-left: -14px !important;">
            <mat-form-field class="col-6" *ngIf="show">
              <mat-label>Delete Reason <span class="asterisk">*</span></mat-label>
              <input autocomplete="new-txtDltReason" matInput maxlength="200" formControlName="txtDltReason" placeHolderCharacter="" type="text"  oninput="this.value = this.value.charAt(0).toUpperCase() + this.value.slice(1); this.value = this.value.replace(/[^A-Za-z0-9.]/g, '').replace(/(\..*?)\..*/g, '$1').replace(/^(.*\.\d\d).*$|(\..*)$/g, '$1$2');" >

            </mat-form-field>
            <mat-error style="margin-left: 16px;" *ngIf="
            UserForm?.get('txtDltReason')?.value === null && show || ( UserForm?.get('txtDltReason')?.value == '' && show)
                  ">
                  Delete Reason is a required field!
            </mat-error>
            </div>
          </div>
        </mat-card-content>
      </div>
    </div>
    <br />
  </form>

  <br />
  <div class="row" *ngIf="saveShow">
    <div class="col-sm-12">
      <div class="text-lg-center">
        <!-- [disabled]="isShowSpinner || !(!noteForm?.invalid && noteForm?.dirty)" -->
        <!-- <button *ngIf='isShownSaveButton' [disabled]="(UserForm?.invalid && UserForm?.dirty && isEditDetails != true) || isShowSpinner||!isImageValid" mat-button
        class="buttonColor  mr-2" (click)="saveOfficial()"> Save
        <mat-icon *ngIf='isShowSpinner'>
          <mat-spinner class="spinner-border spinner-border-sm" diameter="20"> </mat-spinner>
        </mat-icon>
      </button> -->
        <button *ngIf='isShownSaveButton' [disabled]="!(!UserForm?.invalid && UserForm?.dirty) || isShowSpinner||!isImageValid" mat-button
          class="buttonColor  mr-2" (click)="saveOfficial()">
            {{ !clientId ? 'Save' : 'Update' }}
          <mat-icon *ngIf='isShowSpinner'>
            <mat-spinner class="spinner-border spinner-border-sm" diameter="20"> </mat-spinner>
          </mat-icon>
        </button>
        <button mat-button class="resetclr buttonColor mb-2 mr-2" (click)="reset()">Reset</button>
      </div>

    </div>
  </div>

  <div class="row" *ngIf="saveShow">
    <div class="col-sm-4">
    </div>
    <div class="col-sm-4">
      <div class="text-lg-center " *ngIf='isShowSpinner'>
        <div class="alert alert-success" role="alert">
          Please Wait,While Data is Saved!
        </div>
      </div>
    </div>
    <div class="col-sm-4">
    </div>
  </div>


  <div class="row" *ngIf="deleteShow">
    <div class="col-sm-12">
      <div class="text-lg-center">
        <button mat-button class="buttonColor  mr-2" [disabled]="UserForm?.get('txtDltReason')?.value === null ||  UserForm?.get('txtDltReason')?.value == ''"
          (click)="deleteOfficial()"> Delete</button>
        <button mat-button class="resetclr buttonColor mb-2 mr-2" (click)="reset()">Reset</button>

      </div>
    </div>
  </div>
</div>
<div class="row"></div>
