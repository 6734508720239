import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { Title } from '@angular/platform-browser';
import { MmOrderService } from '../order-proxy/order-management/order-optimization/mm-order.service';
import { Subscription } from 'rxjs';
import { CommunicationService } from 'projects/shared/src/app/services/communication.service';
import { CommonService } from 'projects/shared/src/app/services/common.service';

@Component({
  selector: 'app-override-list',
  templateUrl: './override-list.component.html',
  styleUrls: ['./override-list.component.scss'],
})
export class OverrideListComponent implements OnInit {
  frmOverrideList: FormGroup;
  isOrdersLoaded: boolean = true;
  ltOverrideOrders: any[] = [];
  dttblOptions: any = {};
  fromDate: any;
  toDate: any;
  subscription$: Subscription[] = [];
  constructor(
    private datepipe: DatePipe,
    private title: Title,
    private fb: FormBuilder,
    private mmOrderService: MmOrderService,
    private communicationService: CommunicationService,
    private commonService: CommonService,
  ) {
    const overrideList =
      this.communicationService.loadOverriddenOrdersMethodCall$.subscribe(
        () => {
          this.getOverrideList();
        }
      );
    this.subscription$.push(overrideList);
  }

  ngOnInit() {
    this.initializeForms();
    this.title.setTitle('Qsecure | Override Orders');
    const { firstDate, lastDate } = this.getFirstAndLastDatesOfCurrentMonth();
    this.fromDate = this.datepipe.transform(firstDate, 'MM/dd/yyyy');
    this.toDate = this.datepipe.transform(lastDate, 'MM/dd/yyyy');
    this.frmOverrideList.patchValue({
      txtDateValue: firstDate,
      txtDate: lastDate,
    });
    this.getOverrideList();
  }
  ngOnDestroy(): void {
    this.subscription$.forEach((sub) => {
      sub && sub?.unsubscribe();
    });
  }
  // Function to get the first and last dates of the current month
  getFirstAndLastDatesOfCurrentMonth(): { firstDate: Date; lastDate: Date } {
    const today = new Date();
    const firstDate = new Date(today.getFullYear(), today.getMonth(), 1);
    const lastDate = new Date(today.getFullYear(), today.getMonth() + 1, 0);
    return { firstDate, lastDate };
  }
  initializeForms() {
    this.frmOverrideList = this.fb.group({
      txtDateValue: new FormControl(''),
      txtDate: new FormControl(''),
    });
  }
  //To open the date picker
  openDatePicker(event: MatDatepickerInputEvent<Date>, dateType) {
    const startDate = this.frmOverrideList.get('txtDateValue').value;
    const endDate = this.frmOverrideList.get('txtDate').value;
    this.fromDate = this.datepipe.transform(startDate, 'MM/dd/yyyy');
    this.toDate = this.datepipe.transform(endDate, 'MM/dd/yyyy');
    if (this.fromDate && this.toDate) {
      this.getOverrideList();
    }
  }
  //To get the Override List Table
  getOverrideList() {
    this.isOrdersLoaded = false;
    const overRideOrdersList = this.mmOrderService
      .getOrderOverrideListByDtFromDateAndDtToDate(this.fromDate, this.toDate)
      .subscribe(
        (response) => {
          this.ltOverrideOrders = response;
          this.ltOverrideOrders = this.ltOverrideOrders.map((shipping) => {
            return {
              ...shipping,
              orderedDate: this.commonService.getFormattedDateZone(
                shipping.orderedDate
              ),
              overrideDate: this.commonService.getFormattedDateZone(
                shipping.overrideDate
              ),
            };
          });
          this.isOrdersLoaded = true;
        },
        (err) => {
          this.isOrdersLoaded = true;
        }
      );
    this.subscription$.push(overRideOrdersList);
  }
}
