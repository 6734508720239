import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[appNumericDirective]',
})
export class NumericDirectiveDirective {
  constructor() {}
  @HostListener('keypress', ['$event'])
  onKeyPress(event: KeyboardEvent): void {
    const keyCode = event.which || event.keyCode;

    // Allow: backspace, delete, tab, escape, enter
    if (
      [8, 9, 27, 13].indexOf(keyCode) !== -1 ||
      // Allow: Ctrl+A
      (keyCode === 65 && (event.ctrlKey || event.metaKey)) ||
      // Allow: home, end, left, right
      (keyCode >= 35 && keyCode <= 39)
    ) {
      // let it happen, don't do anything
      return;
    }

    // Ensure that it is a number and stop the keypress if not
    if ((keyCode < 48 || keyCode > 57) && (keyCode < 96 || keyCode > 105)) {
      event.preventDefault();
    }
  }
}
