import { HttpErrorResponse } from '@angular/common/http';
import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { PaymentViewComponent } from 'projects/admin/src/app/payment-view/payment-view.component';
import { RemindersService } from 'projects/order/src/app/reminder-message/reminders.service';
import {
  PatientExportDatas,
  PatientExportDTO
} from 'projects/patient/src/app/patient-proxy/patient/dto/models';
import { ExportType } from 'projects/patient/src/app/patient-proxy/patient/enumeration-data/export-type.enum';
import { ReportService } from 'projects/patient/src/app/patient-proxy/patient/report.service';
import { TableService } from 'projects/shared/src/app/table.service';
import { Subscription } from 'rxjs';
import Swal from 'sweetalert2';
import { AddInsurancePaymentComponent } from '../add-insurance-payment/add-insurance-payment.component';
import { BillingPaypalComponent } from '../billing-paypal/billing-paypal.component';
import { ClaimSearchService } from '../billing-proxy/billing-management/posting/claim-search.service';
import { ClaimDTO } from '../billing-proxy/billing-management/posting/dto/models';
import { ClaimInformationsService } from '../billing-proxy/billing-management/rcm/billing-management/posting/claim-informations.service';
import { PostingNotificationService } from '../billing-proxy/billing-management/rcm/billing-management/posting/posting-notification.service';
import { BillingReminderComponent } from '../billing-reminder/billing-reminder.component';
import { InsuranceType } from '../claim-proxy/claim-processing-management/rcm/billing-management/billing';
import { CreateUpdateInsurancePaymentDTO } from '../claim-proxy/claim-processing-management/rcm/billing-management/posting/dto/models';
import { InsurancePaymentService } from '../claim-proxy/claim-processing-management/rcm/claim-processing-management/x12/insurance-payment.service';
import { PrimaryPaymentComponent } from '../primary-payment/primary-payment.component';
import {
  claimAcceptedStatus,
  DocumentMaxandTypeLimits
} from './../../../../shared/src/app/enums/allenums.enum';
import { SearchClaimDTO } from './../billing-proxy/billing-management/posting/models';
import { SecondaryPaymentComponent } from './../secondary-payment/secondary-payment.component';
import { TeritaryPaymentComponent } from './../teritary-payment/teritary-payment.component';
import { ToastrService } from "ngx-toastr";

@Component({
  selector: 'app-manual-posting-history',
  templateUrl: './manual-posting-history.component.html',
  styleUrls: ['./manual-posting-history.component.scss'],
})
export class ManualPostingHistoryComponent implements OnInit, OnDestroy {
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  dataSource: MatTableDataSource<any> = new MatTableDataSource<any>([]);
  public arrDisplayedColumns: string[] = [];
  isLoading: boolean = true;
  @ViewChild('uploadFileReference', { static: false })
  uploadFileReference: ElementRef;
  checkForm: FormGroup;
  claimId: string = '';
  claimTableData: any[] = [];
  patientId: string = '';
  orderId: string = '';
  lblPatientName: string = '';
  lblclaimId: string = '';
  lblSaleOrderId: string = '';
  lblPatientId: string = '';
  userId: string = '';
  lblDOB: string = '';
  strClaimStatus: string = '';
  subscription$: Subscription[] = [];
  constructor(
    public tableService: TableService,
    public title: Title,
    public router: Router,
    public dialog: MatDialog,
    private fb: FormBuilder,
    private store: Store,
    private claimInformationService: ClaimInformationsService,
    private insurancePaymentService: InsurancePaymentService,
    private activatedRoute: ActivatedRoute,
    private claimSearchService: ClaimSearchService,
    private postingService: PostingNotificationService,
    private reminderService: RemindersService,
    private reportService: ReportService,
    private toastr: ToastrService,
  ) { }

  ngOnInit(): void {
    this.userId = localStorage.getItem('userId');
    this.checkForm = this.fb.group({
      defaultClaimId: new FormControl(''),
      saleorderId: new FormControl(''),
      patientId: new FormControl(''),
      patientName: new FormControl(''),
      totalAllowed: new FormControl(''),
      totalBilled: new FormControl(''),
      totalWriteOff: new FormControl(''),
      totalInsPaid: new FormControl(''),
      totalPatientBal: new FormControl(''),
      claimStatus: new FormControl(''),
    });

    this.arrDisplayedColumns = [
      'Options',
      'plan',
      'checkNo',
      'checkDate',
      'totalBilled',
      // 'totalAllowed',
      // "totalWriteOff",
      // "totalInsPaid",
      'totalPatientBal',
      'status',
      
    ];

    this.checkForm.valueChanges.subscribe((value: any) => {
      let filter: SearchClaimDTO = {
        checkIssueToDate: value?.checkIssueToDate?.trim()?.toLowerCase() ?? '',
        checkNo: value?.checkNo?.trim()?.toLowerCase() ?? '',
        payee: value?.payee?.trim()?.toLowerCase() ?? '',
        defaultClaimId: value?.defaultClaimId?.trim()?.toLowerCase() ?? '',
        patientName: value?.patientName?.trim()?.toLowerCase() ?? '',
        defaultSaleOrderId: value?.saleorderId?.trim()?.toLowerCase() ?? '',
        totalInsurancePaid: value?.totalInsPaid?.trim()?.toLowerCase() ?? '',
        totalAllowed: value?.totalAllowed?.trim()?.toLowerCase() ?? '',
        totalBilled: value?.totalBilled?.trim()?.toLowerCase() ?? '',
        totalWriteOff: value?.totalWriteOff?.trim()?.toLowerCase() ?? '',
        totalPatBal: value?.totalPatientBal?.trim()?.toLowerCase() ?? '',
        status: value?.claimStatus?.trim()?.toLowerCase() ?? '',
        claimId: this.claimId,
      };
      if (this.checkForm?.valid) {
        this.dataSource.filter = JSON.stringify(filter);
        this.searchClaimsBasedOnFeildsChanges(filter);
      }
    });
    this.activatedRoute?.paramMap?.subscribe((v) => {
      this.claimId = v?.get('id') ?? '';
      this.claimId && this.getTableData();
      this.claimId && this.getNotify();
      this.strClaimStatus = '';
    });
    this.activatedRoute?.queryParams?.subscribe((v) => {
      this.strClaimStatus = v?.id ?? 'All';
    });
  }

  // Payment Product  Status change
  paymentStatusChange(
    event: MatSlideToggleChange,
    insPaymentdata: CreateUpdateInsurancePaymentDTO,
    strInsPaymentId: string
  ) {
    strInsPaymentId = strInsPaymentId ?? '';
    if (event?.checked === true) {
      insPaymentdata.postingStatus = PostingStatus.Completed;
    } else {
      insPaymentdata.postingStatus = PostingStatus.Pending;
    }
    const productCategoryDetails: CreateUpdateInsurancePaymentDTO =
      insPaymentdata;
    this.insurancePaymentService
      .update(strInsPaymentId, productCategoryDetails)
      .subscribe(
        (response) => {
          // Swal.fire({
          //   title: 'Success',
          //   html: 'Status Changed Successfully',
          //   icon: 'success',
          //   timer: 3000,
          //   timerProgressBar: true,
          // });
          this.toastr.success('Status Changed Successfully','Success')
          this.getTableData();
        },
        (err) => {
          const data: HttpErrorResponse = err;
          Swal.fire({
            icon: 'info',
            text: data?.error?.error?.message,
          });
        }
      );
  }

  //// getNotifications from billing
  getNotify() {
    const getNotificationByClaimIdAndUserIdSub = this.postingService
      .getNotificationByClaimIdAndUserId(this.claimId, this.userId)
      .subscribe((response) => {
        response?.items?.forEach((element) => {
          let newDate = new Date(element?.completedOn);
          if (newDate > new Date()) {
            const titleMsg: string = 'Hi ' + element?.userName + ' 👋';
            const message: { patientName: string; subject: string } = {
              patientName: element?.patientName,
              subject: element?.subject,
            };
            const messageTimeOut: number = 1000000;
            this.reminderService.success(titleMsg, message, messageTimeOut);
          }
        });
      });
    this.subscription$.push(getNotificationByClaimIdAndUserIdSub);
  }

  //! initiate payment
  paymentInitiate(record: {
    payPalCheckOutOrderPaymentStatus: string;
    orderId: string;
    id: string;
  }) {
    if (
      record.payPalCheckOutOrderPaymentStatus === 'Initiate Payment' ||
      'Link Expired' ||
      null
    ) {
      const dialogRef = this.dialog.open(PaymentViewComponent, {
        disableClose: true,
        data: { orderId: record?.orderId, claimId: record?.id },
      });
      dialogRef.afterClosed().subscribe(() => {
        this.getTableData();
      });
    } else {
      const dialogRef = this.dialog.open(PaymentViewComponent, {
        disableClose: true,
        data: { orderId: record?.orderId, claimId: record?.id },
      });
      dialogRef.afterClosed().subscribe(() => {
        this.getTableData();
      });
    }
  }

  //! Invoice initiate payment
  paymentInvoiceInitiate(record: {
    payPalInvoicePaymentStatus: string;
    orderId: string;
    id: string;
  }) {
    if (record.payPalInvoicePaymentStatus === 'Initiate Payment') {
      const dialogRef = this.dialog.open(BillingPaypalComponent, {
        disableClose: true,
        data: { orderId: record?.orderId, claimId: record?.id },
      });
      dialogRef.afterClosed().subscribe(() => {
        this.getTableData();
      });
    } else {
      const dialogRef = this.dialog.open(BillingPaypalComponent, {
        disableClose: true,
        data: { orderId: record?.orderId, claimId: record?.id },
      });
      dialogRef.afterClosed().subscribe(() => {
        this.getTableData();
      });
    }
  }
  //! Load Patient Visits Table function
  loadClaimHistoryTable(response: ClaimDTO, insuranceType: InsuranceType) {
    this.isLoading = false;
    let claimStatus: string = '';
    let totalBilled: number = 0;
    let totalAllowed: number = 0;
    let totalWriteOff: number = 0;
    let totalInsPaid: number = 0;
    let totalPatientBal: number = 0;
    let dos: string = response?.lstPostingCharges[0]?.dos ?? '';
    if (insuranceType === InsuranceType.Primary) {
      claimStatus =
        response?.insurancePayments?.primaryInsurancePaymentDetails?.status ??
        '';
      totalBilled =
        response?.insurancePayments?.primaryInsurancePaymentDetails
          ?.totalBilled ?? 0;
      totalAllowed =
        response?.insurancePayments?.primaryInsurancePaymentDetails
          ?.totalAllowed ?? 0;
      totalWriteOff =
        response?.insurancePayments?.primaryInsurancePaymentDetails
          ?.totalWriteOff ?? 0;
      totalInsPaid =
        response?.insurancePayments?.primaryInsurancePaymentDetails
          ?.totalInsurancePaid ?? 0;
      totalPatientBal =
        response?.insurancePayments?.primaryInsurancePaymentDetails
          ?.totalPatientBal ?? 0;
    }
    if (insuranceType === InsuranceType.Secondary) {
      claimStatus =
        response?.insurancePayments?.secondaryInsurancePaymentDetails?.status ??
        '';
      totalBilled =
        response?.insurancePayments?.secondaryInsurancePaymentDetails
          ?.totalBilled ?? 0;
      totalAllowed =
        response?.insurancePayments?.secondaryInsurancePaymentDetails
          ?.totalAllowed ?? 0;
      totalWriteOff =
        response?.insurancePayments?.secondaryInsurancePaymentDetails
          ?.totalWriteOff ?? 0;
      totalInsPaid =
        response?.insurancePayments?.secondaryInsurancePaymentDetails
          ?.totalInsurancePaid ?? 0;
      totalPatientBal =
        response?.insurancePayments?.secondaryInsurancePaymentDetails
          ?.totalPatientBal ?? 0;
    }
    if (insuranceType === InsuranceType.Tertiary) {
      claimStatus =
        response?.insurancePayments?.tertiaryInsurancePaymentDetails?.status ??
        '';
      totalBilled =
        response?.insurancePayments?.tertiaryInsurancePaymentDetails
          ?.totalBilled ?? 0;
      totalAllowed =
        response?.insurancePayments?.tertiaryInsurancePaymentDetails
          ?.totalAllowed ?? 0;
      totalWriteOff =
        response?.insurancePayments?.tertiaryInsurancePaymentDetails
          ?.totalWriteOff ?? 0;
      totalInsPaid =
        response?.insurancePayments?.tertiaryInsurancePaymentDetails
          ?.totalInsurancePaid ?? 0;
      totalPatientBal =
        response?.insurancePayments?.tertiaryInsurancePaymentDetails
          ?.totalPatientBal ?? 0;
    }
    response.creationTime = response?.creationTime ?? '';
    response.lastModificationTime = response?.lastModificationTime ?? '';
    this.claimTableData?.push({
      saleorderId: response?.saleorderId ?? '',
      dos: dos === '' ? '' : new Date(dos)?.toLocaleDateString('en-US'),
      totalBilled: totalBilled ?? 0,
      totalAllowed: totalAllowed ?? 0,
      totalWriteOff: totalWriteOff ?? 0,
      totalInsPaid: totalInsPaid ?? 0,
      totalPatientBal: totalPatientBal ?? 0,
      creationTime:
        response?.creationTime === ''
          ? ''
          : new Date(response?.creationTime)?.toLocaleDateString('en-US') ?? '',
      lastFilled:
        response?.lastModificationTime === ''
          ? ''
          : new Date(response?.lastModificationTime)?.toLocaleDateString(
            'en-US'
          ) ?? '',
      claimId: response?.claimId ?? '',
      id: response?.id ?? '',
      processedCount: response?.processedCount ?? 0,
      readyCount: response?.readyCount ?? 0,
      holdCount: response?.holdCount ?? 0,
      crossOverCount: response?.crossOverCount ?? 0,
      defaultPatientId: response?.defaultPatientId ?? '',
      patientName: response?.patientName ?? '',
      payPalPaymentStatus: response?.saleOrder?.payPalPaymentStatus ?? '',
      orderId: response?.orderId ?? '',
      claimStatus: claimStatus ?? '',
      cashPayment: response?.saleOrder?.isCashPayment ?? 0,
      patientId: response?.patientId ?? '',
      InsuranceType:
        insuranceType.toString() ??
        claimAcceptedStatus.YetToProcess.toString() ??
        '',
    });
    this.dataSource = new MatTableDataSource(this.claimTableData);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  payment(
    patientId: string,
    orderId: string,
    claimId: string,
    insuranceType: string
  ) {
    if (insuranceType === InsuranceType.Primary) {
      const dialogRef = this.dialog.open(PrimaryPaymentComponent, {
        data: {
          patientId: patientId,
          orderId: orderId,
          claimId: claimId,
          insuranceType: insuranceType,
        },
        height: '80vh',
        width: '80vw',
        maxHeight: '80vh',
        maxWidth: '80vw',
      });
      dialogRef.afterClosed().subscribe(
        (result) => {
          this.getTableData();
        },
        (err) => {
          const data: HttpErrorResponse = err;
          Swal.fire({
            icon: 'info',
            text: data?.error?.error?.message,
          });
        }
      );
    }
    if (insuranceType === InsuranceType.Secondary) {
      const dialogRef = this.dialog.open(SecondaryPaymentComponent, {
        data: {
          patientId: patientId,
          orderId: orderId,
          claimId: claimId,
          insuranceType: insuranceType,
        },
        height: '80vh',
        width: '80vw',
        maxHeight: '80vh',
        maxWidth: '80vw',
      });
      dialogRef.afterClosed().subscribe(
        (result) => {
          this.getTableData();
        },
        (err) => {
          const data: HttpErrorResponse = err;
          Swal.fire({
            icon: 'info',
            text: data?.error?.error?.message,
          });
        }
      );
    }
    if (insuranceType === InsuranceType.Tertiary) {
      const dialogRef = this.dialog.open(TeritaryPaymentComponent, {
        data: {
          patientId: patientId,
          orderId: orderId,
          claimId: claimId,
          insuranceType: insuranceType,
        },
        height: '80vh',
        width: '80vw',
        maxHeight: '80vh',
        maxWidth: '80vw',
      });
      dialogRef.afterClosed().subscribe(
        (result) => {
          this.getTableData();
        },
        (err) => {
          const data: HttpErrorResponse = err;
          Swal.fire({
            icon: 'info',
            text: data?.error?.error?.message,
          });
        }
      );
    }
  }

  addReminder(value: {
    orderId: string;
    patientId: string;
    patientName: string;
    defaultPatientId: string;
    id: string;
  }) {
    const config: MatDialogConfig = {
      disableClose: true,
      data: {
        patientId: value?.patientId,
        orderId: value?.orderId,
        patientName: value?.patientName,
        defaultPatientId: value?.defaultPatientId,
        claimId: value.id,
      },
    };
    const dialogRef = this.dialog.open(BillingReminderComponent, config);

    dialogRef.afterClosed().subscribe(
      () => {
        this.getTableData();
      },
      (err) => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      }
    );
  }
  openPatientVisitNotes(id: string) { }

  // export table data Posting-History
  exportTableData() {
    let datas: PatientExportDatas[] = [];
    this.dataSource?.data?.forEach((element) => {
      datas.push({
        claimId: element?.claimId,
        saleOrderId: element?.saleorderId,
        patientDefaultId: element?.defaultPatientId,
        patientName: element?.patientName,
        totalBilled: '$ ' + String(element?.totalBilled),
        totalAllowed: '$ ' + String(element?.totalAllowed),
        totalWriteOff: '$ ' + String(element?.totalWriteOff),
        totalInsPaid: '$ ' + String(element?.totalInsPaid),
        totalPatBal: '$ ' + String(element?.totalPatientBal),
        billingStatus: '$ ' + element?.claimStatus,
      });
    });
    let patientsExport: PatientExportDTO = {
      columns: [
        'claimId',
        'Sale Order Id',
        'Patient Id',
        'Patient Name',
        'Total Billed',
        'Total Allowed',
        'Total Write off',
        'Total Ins Paid',
        'Total Patient Bal',
        'Status',
      ],
      datas: datas,
    };
    const exportPatientReportsSub = this.reportService
      .exportPatientReportsByExportTypeAndExportDatas(
        ExportType.ClaimHistoryReports,
        patientsExport
      )
      .subscribe(
        (response) => {
          if (response && response?.length !== 0) {
            let filelocation: String = String(
              'data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,' +
              response
            );
            let link = document.createElement('a');
            link.download = 'Posting-History' + '.xlsx';
            link.href = filelocation.toString();
            link.click();
            // Swal.fire({
            //   title: 'Success',
            //   html: 'Exported Successfully',
            //   icon: 'success',
            //   timer: 3000,
            //   timerProgressBar: true,
            // });
            this.toastr.success('Exported Successfully','Success')
          }
        },
        (err) => {
          const data: HttpErrorResponse = err;
          let errorMesg: string = data?.error;
          if (
            errorMesg?.includes('Sorry!! There is no data in table to export')
          ) {
            errorMesg = 'Sorry!! There is no data in table to export';
          } else {
            errorMesg = data?.error?.error?.message;
          }
          // Swal.fire({
          //   icon: 'info',
          //   text: errorMesg,
          // });
          this.toastr.info(errorMesg)
        }
      );

    this.subscription$.push(exportPatientReportsSub);
  }

  //! To get Searched  Values based on formfeild changes
  private searchClaimsBasedOnFeildsChanges(value: SearchClaimDTO) {
    this.isLoading = true;
    this.claimTableData = [];
    if (value && typeof value === 'object') {
      const searchClaimByInputSub = this.claimSearchService
        .searchClaimByInput(value)
        .subscribe(
          (response) => {
            this.dataSource = new MatTableDataSource(this.claimTableData);
            this.dataSource.paginator = this.paginator;
            this.dataSource.sort = this.sort;
            if (response) {
              const pry =
                response?.insurancePayments?.primaryInsurancePaymentDetails;
              const sec =
                response?.insurancePayments?.secondaryInsurancePaymentDetails;
              const tret =
                response?.insurancePayments?.tertiaryInsurancePaymentDetails;
              const data: ClaimDTO = response;
              pry &&
                pry?.lstItemPayment?.length !== 0 &&
                pry?.eobDocuments &&
                this.loadClaimHistoryTable(data, InsuranceType.Primary);
              sec &&
                sec?.lstItemPayment?.length !== 0 &&
                sec?.eobDocuments &&
                this.loadClaimHistoryTable(data, InsuranceType.Secondary);
              tret &&
                tret?.lstItemPayment?.length !== 0 &&
                tret?.eobDocuments &&
                this.loadClaimHistoryTable(data, InsuranceType.Tertiary);
            }
            this.isLoading = false;
          },
          (err) => {
            this.isLoading = false;
            const data: HttpErrorResponse = err;
            Swal.fire({
              icon: 'info',
              text: data?.error?.error?.message,
            });
            this.dataSource = new MatTableDataSource(this.claimTableData);
            this.dataSource.paginator = this.paginator;
            this.dataSource.sort = this.sort;
          }
        );
      this.subscription$.push(searchClaimByInputSub);
    }
  }

  /// posting table datas
  getTableData() {
    this.isLoading = true;
    this.claimTableData = [];
    this.dataSource = new MatTableDataSource(this.claimTableData);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    const getClaimIdSub = this.claimInformationService
      ?.get(this.claimId)
      .subscribe(
        (response) => {
          this.patientId = response?.patientId ?? '';
          this.orderId = response?.orderId ?? '';
          this.lblDOB = response?.patientDob ?? '';
          this.lblPatientName = response?.patientName ?? '';
          this.lblclaimId = response?.claimId ?? '';
          this.lblSaleOrderId = response?.saleorderId ?? '';
          this.lblPatientId =
            response?.saleOrder?.patients?.defaultPatientId ?? '';
          this.isLoading = false;
        },
        (err) => {
          this.isLoading = false;
          const data: HttpErrorResponse = err;
          this.isLoading = false;
          Swal.fire({
            icon: 'info',
            text: data?.error?.error?.message,
          });
        }
      );
    this.subscription$.push(getClaimIdSub);

    const getInsurancePaymentsByClaimIdSub = this.insurancePaymentService
      .getInsurancePaymentsByClaimId(this.claimId)
      .subscribe(
        (response) => {
          this.claimTableData = response ?? [];
          this.dataSource = new MatTableDataSource(this.claimTableData);
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
          this.isLoading = false;
        },
        (err) => {
          this.isLoading = false;
          this.claimTableData = [];
          this.dataSource = new MatTableDataSource(this.claimTableData);
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
          const data: HttpErrorResponse = err;
          this.isLoading = false;
          Swal.fire({
            icon: 'info',
            text: data?.error?.error?.message,
          });
        }
      );
    this.subscription$.push(getInsurancePaymentsByClaimIdSub);
  }

  //! Read From File. and upload to Backend For EDI parsing
  eftImportFormFile(value: any) {
    this.checkForm.reset();
    const fileList: FileList = value;
    let fileEOB: File = null;
    if (fileList?.length >= 3) {
      return;
    }
    for (let index = 0; index < fileList?.length; index++) {
      if (
        fileList[index]?.type === DocumentMaxandTypeLimits.documentTypeLimit
      ) {
        fileEOB = fileList?.item(index);
      }
    }
    if (!fileEOB) {
      this.uploadFileReference.nativeElement.value = null;
      return;
    }
    let fileEOBResultValue: string = '';
    let reader = new FileReader();
    reader.onload = () => {
      reader?.readAsDataURL(fileEOB);
      reader.onload = () => {
        fileEOBResultValue = reader?.result?.slice(28)?.toString();
      };
    };
  }
  //! Add New Posting Data With Eob
  addPosting(value?: string) {
    const matDialogConfig: MatDialogConfig = {
      data: { claimId: this.claimId, insurancePayment: value ?? '' },
    };
    const dialogRef = this.dialog.open(
      AddInsurancePaymentComponent,
      matDialogConfig
    );
    dialogRef.afterClosed().subscribe(
      (result) => {
        this.getTableData();
      },
      (err) => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      }
    );
    dialogRef.afterClosed().subscribe(
      (result) => {
        this.getTableData();
      },
      (err) => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      }
    );
  }

  //! Delete product brand details by id

  deleteInsurancePayments(strInsPaymentId: string) {
    strInsPaymentId = strInsPaymentId ?? '';
    Swal.fire({
      title: 'Are you sure you want to Delete?',
      text: "You won't be able to retrieve this data!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#34c38f',
      cancelButtonColor: '#f46a6a',
      confirmButtonText: 'Yes, delete it!',
    }).then((result) => {
      if (result.value) {
        const prodBandDelete = this.insurancePaymentService
          .delete(strInsPaymentId)
          .subscribe(
            (resposne) => {
              this.getTableData();
            },
            (err: HttpErrorResponse) => {
              const data: HttpErrorResponse = err;
              Swal.fire({
                icon: 'info',
                text: data?.error?.error?.message,
              });
            }
          );
        this.subscription$.push(prodBandDelete);
      }
    });
  }
  ngOnDestroy(): void {
    this.subscription$.forEach((sub) => {
      sub && sub?.unsubscribe();
    });
  }
}

export default interface billingList {
  id: string;
  cashPayment: number;
  defaultPatientId: string;
  patientName: string;
  payPalPaymentStatus: string;
  claimStatus: string;
  processedCount: number;
  readyCount: number;
  holdCount: number;
  crossOverCount: number;
  saleorderId: string;
  dos: string;
  totalAllowed: number;
  totalBilled: number;
  totalWriteOff: number;
  totalInsPaid: number;
  totalPatientBal: number;
  lastFilled: string;
  patientId: string;
  orderId: string;
  claimId: string;
  creationTime: string;
  InsuranceType: string;
}

export const enum PostingStatus {
  Completed = 'Posting Completed',
  Pending = 'Posting Pending',
}
