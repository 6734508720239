<div class="account-pages my-5 pt-sm-5">
  <!-- <div class="container">
        <div class="row justify-content-center">
            <div class="col-md-8 col-lg-6 col-xl-5">
                <div class="card">
                    <div class="row">
                        <div class="col-6">
                            <h6 class="align">TENANT</h6>
                        </div>
                        <div class="col-6 text-right">
                            <button type="button" class="switch btn btn-outline-primary btn-sm">Switch</button>
                        </div>
                    </div>
                    <h6 class="algn">RND</h6>

                </div>
            </div>
        </div>
    </div> -->
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-md-8 col-lg-6 col-xl-5">
        <div class="card overflow-hidden">
          <div class="card-body pt-0">
            <div class="p-2">
              <div class="col-12 text-center pt-4">
                <div class="row">
                  <div class="col-12 text-center">
                    <img src="assets/images/rnd.jpg" alt="" height="100" />
                  </div>
                </div>
              </div>
              <h4 class="text-center btm">Welcome To Qsecure</h4>
              <label class="item"
                >Type Your Email here!! We send password reset link to your mail
                id!</label
              >
              <form [formGroup]="forgotForm">
                <div class="col-12 text-center">
                  <input
                    autocomplete="new-txtMail"
                    type="text"
                    pattern="[a-zA-Z0-9._!#$%&'()*+,-./:;<=>?@[\]^_`{|}~]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$"
                    [textMask]="{ mask: emailMask }"
                    formControlName="txtMail"
                    class="form-control"
                  />
                </div>
                <div class="row">
                  <div class="col-6 mt-4 link text-left">
                    <!-- <p class="ml-2" (click)="backToLogin()">&larr;Back</p> -->
                    <i [matTooltip]="'Back to Login'"
                      class="fa fa-arrow-circle-left fa-2x ml-3"
                      (click)="backToLogin()"
                    ></i>
                  </div>
                  <div class="col-6 mt-4 text-right">
                    <button
                      class="btn buttonColor text-clr"
                      [disabled]="forgotForm.invalid || isValidEmail"
                      type="submit"
                      (click)="submit()"
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
