
    <mat-accordion>
        <mat-expansion-panel [expanded]="panelOpenState" (afterExpand)="toggle()" (closed)="toggleV2()">
            <mat-expansion-panel-header>
                <mat-panel-title class="customThemeClass">
                    <ng-container>
                        <b>
                            <ng-container *ngIf="
                        notesId === '';
                        then thenTitleTemplate;
                        else elseTitleTemplate
                      "></ng-container>
                            <ng-template #thenTitleTemplate> Add Note Type </ng-template>
                            <ng-template #elseTitleTemplate> Edit Note Type </ng-template>
                        </b>
                        <!-- <b>Add Note Type</b> -->
                    </ng-container>
                    <!-- <ng-template #elseTitleTemplate>
                  <b>Edit Note Type</b>
                </ng-template> -->
                </mat-panel-title>
            </mat-expansion-panel-header>
            <form [formGroup]="notesForm">
                <div class="col-lg-12">
                    <mat-card-content>
                        <div class="row">
                            <!-- Notes Type-->
                            <mat-form-field class="col-6">
                                <mat-label>
                                    Notes Type <span class="asterisk">*</span>
                                </mat-label>
                                <input autocomplete="new-txtNoteType" matInput maxlength="40"
                                    formControlName="txtNoteType" type="text" />
                                <!-- <mat-select
                        maxlength="40"
                        [(value)]="notetype"
                        formControlName="drpNoteType"
                      >
                        <mat-option
                          *ngFor="let note of drpNoteType"
                          [value]="note.key"
                        >
                          {{ note.value }}
                        </mat-option>
                      </mat-select> -->
                                <!--
                      <button
                        matSuffix
                        mat-icon-button
                        (click)="notetype = ''; $event.stopPropagation()"
                        *ngIf="notetype"
                        (click)="clearNoteType()"
                      >
                        <mat-icon>close</mat-icon>
                      </button> -->
                                <mat-error class="spanAlignment" *ngIf="
                          notesForm?.get('drpNoteType')?.touched &&
                          notesForm?.get('drpNoteType')?.errors?.required
                        ">
                                    Note Type is a required field!
                                </mat-error>
                            </mat-form-field>
                            <div class="col-6">
                                <button mat-button class="buttonColor mb-2 mr-2" [disabled]="
                            saveButtonHide || !(!notesForm.invalid && notesForm.dirty)
                          " (click)="saveNotes()">
                                    Save
                                    <mat-icon *ngIf="isShowSpinner">
                                        <mat-spinner class="spinner-border spinner-border-sm"
                                            diameter="100"></mat-spinner>
                                    </mat-icon>
                                </button>
                                <button mat-button class="resetclr buttonColor mb-2 mr-2" (click)="resetNotes()">
                                    Reset
                                </button>
                            </div>
                        </div>
                    </mat-card-content>
                </div>
            </form>
            <br />
        </mat-expansion-panel>
    </mat-accordion>
    <br>
    <div class="card card-body">
        <div class="row">
                <div class="col-12" *ngIf="isLoading">
                    <div class="d-flex justify-content-center" *ngIf="isLoading">
                        <img class="img-responsive pb-2 mr-1" src="assets/gifs/Infinity-loader.gif" />
                    </div>
                </div>
            <div class="col-12 mt-3" *ngIf="!isLoading">
                <div class="table table-responsive">
                    <!-- <app-inventory-notes-table [tableData]="NotesTableDate"></app-inventory-notes-table> -->
                    <table id="tbl" datatable [dtOptions]="dtOptions" class="tbl hover row-border hover w-100 display">
                        <thead>
                            <tr>
                                <th class="cutomWidth"><mat-icon>apps</mat-icon></th>
                                <th [matTooltip]="'Status'">Status</th>
                                <th [matTooltip]="'Note Type'">Note Type</th>
                                <th [matTooltip]="'Created By'">Created By</th>
                                <th [matTooltip]="'Created Date'" class="text-right">Created Date</th>
                                <th [matTooltip]="'Modify By'">Modify By</th>
                                <th [matTooltip]="'Modified Date'" class="text-right"> Modified Date </th>                                
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let data of NotesTableDate" [ngClass]="{stat_highlight:data.isStat == true}">
                                <td class="cutomWidth">
                                    <a  [class.disabled]="data?.isActive != true" class="editBtnColor" (click)="viewNotes(data?.id)">
                                        <i (click)="toggleV2()"  class="fa fa-pencil editBtn mr-1"
                                            matTooltip="Click To Edit Notes Type"></i>
                                    </a>
                                    <!-- &nbsp;
                                    <a class="deleteBtnColor" (click)="deleteNotes(data?.id)">
                                        <i class="fa fa-trash-o dltBtnColor" matTooltip="Click To Delete"></i>
                                    </a> -->
                                </td>

                                <td [matTooltip]="data?.isActive ? 'Active' : 'Inactive' || '-'">
                                    <ng-container *ngIf="data?.isActive==1">
                                        <mat-slide-toggle (change)="statusChange($event, data)" [checked]='true'
                                            class="ml-2">
                                            {{ data?.isActive ? 'Active' : 'Inactive' }}</mat-slide-toggle>
                                    </ng-container>
                                    <ng-container *ngIf="data?.isActive==0">
                                        <mat-slide-toggle (change)="statusChange($event, data)" [checked]='false'
                                            class="ml-2">
                                            {{ data?.isActive ? 'Active' : 'Inactive' }}</mat-slide-toggle>
                                    </ng-container>
                                </td>

                                <td [matTooltip]="data?.noteType || '-'"
                                    (dblclick)="onRowDoubleClickV1(data?.noteType || '-')">{{
                                    data?.noteType || '-' }}</td>
                                    <td [matTooltip]="data?.createdBy || '-'"
                                    (dblclick)="onRowDoubleClickV1(data?.createdBy || '-')">{{
                                    data?.createdBy || '-' }}</td>

                                <td [matTooltip]="data?.creationTime"
                                    (dblclick)="onRowDoubleClickV1(data?.creationTime)" class="text-right">
                                    {{ data?.creationTime }}
                                </td>
                                <td [matTooltip]="data?.modifyBy|| '-'"
                                    (dblclick)="onRowDoubleClickV1(data?.modifyBy || '-')">{{
                                    data?.modifyBy || '-' }}</td>

                                <td [matTooltip]="data?.lastModificationTime"
                                    (dblclick)="onRowDoubleClickV1(data?.lastModificationTime)" class="text-right">
                                    {{ data?.lastModificationTime }}
                                </td>
                                
                            </tr>
                        </tbody>
                        <tfoot>
                            <tr>
                                <th class="cutomWidth"><mat-icon>apps</mat-icon></th>
                                <th><mat-icon>no_encryption</mat-icon></th>
                                <!-- <th>
                                    <input matInput class="textbox-border-class" type="text"
                                        placeholder="Status" name="search-Status" />
                                </th> -->

                                <th>
                                    <input matInput class="textbox-border-class" type="text" placeholder="Note Type"
                                        name="search-Notetype" />
                                </th>
                                <th>
                                  <input matInput class="textbox-border-class" type="text" placeholder="Created By"
                                      name="search-Created By" />
                                </th>
                                <th>
                                    <input matInput class="textbox-border-class text-right" type="text"
                                        placeholder="Created Date" name="search-Created Date" />
                                </th>
                                <th>
                                  <input matInput class="textbox-border-class" type="text" placeholder="Modify By"
                                      name="search-Modify By" />
                                </th>
                                <th>
                                    <input matInput class="textbox-border-class text-right" type="text"
                                        placeholder="Modified Date" name="search-Modified Date" />
                                </th>
                        </tfoot>
                    </table>
                </div>
            </div>
        </div>
    </div>
<br>
