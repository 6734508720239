import { PagedAndSortedResultRequestDto } from '@abp/ng.core';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, FormGroupDirective, NgForm, Validators } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { MatSelect } from '@angular/material/select';
import { Store } from '@ngxs/store';
import { TableService } from 'projects/shared/src/app/table.service';
import { NumberValidators } from 'projects/shared/src/app/validations/number-validator';
import { TextValidators } from 'projects/shared/src/app/validations/text-validator';
import { Observable, Subscription } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import Swal from 'sweetalert2';
import emailMask from 'text-mask-addons/dist/emailMask';

import { RegionCitiesDTO, RegionCountriesDTO, RegionStatesDTO } from '../dropdown-proxy/dropdown-management/dropdowns/dto/models';
import { PatientMasterDropdownDTO, RelationShipWithoutSelfDTO, RelationShipWithSelfDTO } from '../dropdown-proxy/dropdown-management/dropdowns/dto/patient/models';
import { PatientMasterDropdownService } from '../dropdown-proxy/dropdown-management/dropdowns/master-dropdown-service/patient-master-dropdown.service';
import { PatientDropdowns } from '../dropdown-proxy/dropdown-management/dropdowns/patient-dropdowns.enum';
import { RegionDropdownService } from '../dropdown-proxy/dropdown-management/dropdowns/region-dropdown-service/region-dropdown.service';
import { PatientService } from '../patient-proxy/patient';
import { AddressDTO, ContactDTO, PatientDTO, PersonalDTO } from '../patient-proxy/patient/dto';
import { getPatient, updatePatientIntake } from '../patient-store/patient.action';


export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}

@Component({
  selector: 'app-patient-contact',
  templateUrl: './patient-contact.component.html',
  styleUrls: ['./patient-contact.component.scss']
})
export class PatientContactComponent implements OnInit, OnDestroy {
  // contactsForm: FormGroup;
  @Output() patientContact: EventEmitter<string> = new EventEmitter();
  contactsForm: FormGroup = new FormGroup({});
  EmergencyCountry: string = '';
  EmergencyState: string = '';
  EmergencyCity: string = '';
  ResponsibleCountry: string = '';
  ResponsibleState: string = '';
  ResponsibleCity: string = '';
  emailMask: any;
  emrRelationship: string = "";
  resRelationship: string = "";
  countys: string = "";
  county: string = "";
  state: string = "";
  phoneEmerCode: string = "";
  phoneResCode: string = "";

  CountryName: string = "";
  StateName: string = "";
  StateName1: string = "";
  CountryName1: string = "";
  isShowSpinner: boolean = false;

  isShownSaveButton: boolean = true;
  isShownUpdateIconForPatient: boolean = true;
  isPatientCreatePermission: boolean = false;
  isPatientUpdatePermission: boolean = false;
  saveButtonHide: boolean;

  public filteredEmergencyCountries: Observable<RegionCountriesDTO[]> | undefined;
  public filteredEmergencyStates: Observable<RegionStatesDTO[]> | undefined;
  public filteredEmergencyCities: Observable<RegionCitiesDTO[]> | undefined;
  public filteredResponsibleCountries: Observable<RegionCountriesDTO[]> | undefined;
  public filteredResponsibleStates: Observable<RegionStatesDTO[]> | undefined;
  public filteredResponsibleCities: Observable<RegionCitiesDTO[]> | undefined;

  @Output() nextTabMoveOnSaveEmitter: EventEmitter<string> = new EventEmitter<string>();
  @Output() saveContactFormState: EventEmitter<any> = new EventEmitter<any>();


  @Input() patientId: string = '';
  @Output() patientIdEmitter: EventEmitter<PatientDTO> = new EventEmitter<PatientDTO>(null);
  // patientId:string="33e2440b-9e54-a558-f359-39f9c9910f7f"
  //@Select(PatientState.getPatientList) Patient$: Observable<PatientDTO>;

  matcher = new MyErrorStateMatcher();
  drpCustomCountry: customCountryDTO[] = [];
  drpCustomState$: Observable<customStateDTO[]>;
  drpCustomCity$: Observable<customCityDTO[]>;
  drpCustomstate: customStateDTO[] = [];
  drpCustomCity: customCityDTO[] = [];

  relationship: string;
  relationships: string;
  isPatientLockedByCurrentUser: boolean = true;
  strLoginUserId: string = '';
  subscription$: Subscription[] = [];

  constructor(private store: Store, private formBuilder: FormBuilder, private textValidators: TextValidators,
    private numberValidators: NumberValidators,
    public Table: TableService, private region: RegionDropdownService,
    private patientDropdownService: PatientMasterDropdownService,
    private patientService: PatientService
  ) { }


  ngOnInit(): void {
    this.Table.getLoginUserId().subscribe(val => {
      this.strLoginUserId = val;
    })
    const patientCreateSubscription = this.Table.getPatientCreate().pipe().subscribe(value => {
      this.isPatientCreatePermission = value;
      if (this.isPatientCreatePermission == true) {
        this.isShownSaveButton = true;
      }
      else {
        this.isShownSaveButton = false;
      }
    });
    this.subscription$.push(patientCreateSubscription)
    //
    this.contactsForm = this.formBuilder.group({
      drpEmergencyRelationshipType: new FormControl(null),
      txtEmergencyFirstName: new FormControl("", [this.textValidators.isTextCheck]),
      txtEmergencyMiddleName: new FormControl("", [this.textValidators.isTextCheck]),
      txtEmergencyLastName: new FormControl("", [this.textValidators.isTextCheck]),
      txtEmergencyAddress: new FormControl(""),
      txtEmergencyCity: new FormControl(null),
      txtEmergencyState: new FormControl(null),
      txtEmergencyCounty: new FormControl("", this.textValidators.isTextCheck),
      txtEmergencyCountry: new FormControl(null),
      txtEmergencyPostalCode: new FormControl(""),
      txtEmergencyPhone: new FormControl("", this.numberValidators.isNumberCheck),
      txtEmergencyMobile: new FormControl("", this.numberValidators.isNumberCheck),
      txtEmergencyEmail: new FormControl("", Validators.email),
      txtEmergencyFax: new FormControl("", this.numberValidators.isNumberCheck),
      drpResponsibleRelationshipType: new FormControl(null),
      txtResponsibleFirstName: new FormControl("", [this.textValidators.isTextCheck]),
      txtResponsibleMiddleName: new FormControl("", [this.textValidators.isTextCheck]),
      txtResponsibleLastName: new FormControl("", [this.textValidators.isTextCheck]),
      txtResponsibleAddress: new FormControl(""),
      txtResponsibleCity: new FormControl(null),
      txtResponsibleState: new FormControl(null),
      txtResponsibleCountry: new FormControl(null),
      txtResponsibleCounty: new FormControl("", this.textValidators.isTextCheck),
      txtResponsiblePostalCode: new FormControl(""),
      txtResponsiblePhone: new FormControl("", [this.numberValidators.isNumberCheck]),
      txtResponsibleMobile: new FormControl("", this.numberValidators.isNumberCheck),
      txtResponsibleEmail: new FormControl("", Validators.email),
      txtResponsibleFax: new FormControl("", this.numberValidators.isNumberCheck),
      txtEmergencyCountryFilter: new FormControl(""),
      txtEmergencyStateFilter: new FormControl(""),
      txtEmergencyCityFilter: new FormControl(""),
      txtResponsibleCountryFilter: new FormControl(""),
      txtResponsibleStateFilter: new FormControl(""),
      txtResponsibleCityFilter: new FormControl(""),
    });
    this.emailMask = emailMask;
    this.getDropdown();
    this.getPatientDetailsByID();


    if (this.contactsForm.valid || this.contactsForm.dirty) {
      this.saveContactFormState.emit(this.contactsForm);
    }
    // this.patientContact.emit(this.contactsForm.value);
    //!why?????
    if (this.patientId !== "" && this.patientId !== null && this.patientId !== undefined) {
      const countryTableSubscription = this.Table.getCountryTable().subscribe(value => {
        const selPatientValue: PatientDTO = value;
        if (selPatientValue && selPatientValue?.contacts && selPatientValue?.contacts[0]) {
          this.CountryName = selPatientValue?.contacts[0]?.country;
          this.StateName = selPatientValue?.contacts[0]?.state;
          this.CountryName1 = selPatientValue?.contacts[1]?.country;
          this.StateName1 = selPatientValue?.contacts[1]?.state;
          this.CountryName != null && this.CountryName !== "" && this.CountryName != undefined && this.onChangeEmerState(this.CountryName)
          this.StateName != null && this.StateName !== "" && this.StateName != undefined && this.onChangeEmerCity(this.StateName, this.CountryName)
          this.CountryName1 != null && this.CountryName1 !== "" && this.CountryName1 != undefined && this.onChangeResState(this.CountryName1)
          this.StateName1 != null && this.StateName1 !== "" && this.StateName1 != undefined && this.onChangeResCity(this.StateName1, this.CountryName1)
        }
      }, err => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      })
      this.subscription$.push(countryTableSubscription)
    }
  }


  ngOnDestroy(): void {
    this.subscription$?.forEach((sub) => {
      sub && sub?.unsubscribe();
    });
  }


  // clear dropdown
  clearRelationship() {
    this.relationship = '';
    this.contactsForm.patchValue({ drpEmergencyRelationshipType: "" });
  }
  clearState() {
    this.state = '';
    this.contactsForm.patchValue({ drpEmergencyRelationshipType: "" });
  }
  clearCounty() {
    this.county = '';
    this.contactsForm.patchValue({ txtEmergencyCounty: "" });
  }
  clearRelationships() {
    this.relationships = '';
    this.contactsForm.patchValue({ drpResponsibleRelationshipType: "" });
  }

  clearCountys() {
    this.countys = '';
    this.contactsForm.patchValue({ txtResponsibleCounty: "" });
  }

  //only number and alpha
  numberAndAlphaOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    var inp = String.fromCharCode(event.keyCode);
    if (charCode > 31 && (charCode < 48 || charCode > 57) && (charCode >= 15 && charCode <= 64)) {
      return false;
    }
    return true;
  }


  responsibleRelationship: RelationShipWithSelfDTO[] = [];
  relationShipWithoutSelves: RelationShipWithoutSelfDTO[] = [];
  // countries: CountryDTO[] = [];
  // drpCity: CityDTO[] = [];
  // drpState: StateDTO[] = [];
  // drpCounty: CountyDTO[] = [];
  getDropdown() {
    let patientDropdownInput: PatientDropdowns[];
    patientDropdownInput = [
      PatientDropdowns.RelationShipWithSelf,
      PatientDropdowns.RelationShipWithoutSelf
    ]
    const patientDropdownSubscription = this.patientDropdownService.getPatientDropdownsByInput(patientDropdownInput).subscribe((stateResponse) => {
      let response: PatientMasterDropdownDTO = stateResponse
      this.relationShipWithoutSelves = response?.relationShipWithoutSelves;
      this.responsibleRelationship = response?.relationShipWithSelves;
      // this.countries = response?.countries;
      // this.drpCity = response?.cities;
      // this.drpState = response?.states;
      // this.drpCounty = response?.counties;
    }, err => {
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    })

    this.subscription$.push(patientDropdownSubscription)
    this.emerCountryDropdown();
    this.resCountryDropdown();
  }

  drpEmerCountry: any[] = [];
  drpResCountry: any[] = [];
  drpEmerStates: any[] = [];
  drpEmerCities: any[] = [];
  drpResStates: any[] = [];
  drpResCities: any[] = [];

  @ViewChild('singleSelect') singleSelect: MatSelect;

  //Emergency Country Dropdown
  emerCountryDropdown() {
    this.drpEmerCountry = [];
    this.drpEmerStates = [];
    this.drpEmerCities = [];
    const getCountrySubscription = this.region.getAllCountriesByInput(new PagedAndSortedResultRequestDto).subscribe(response => {
      this.drpEmerCountry = response?.items;


      this.filteredEmergencyCountries = this.contactsForm?.get("txtEmergencyCountryFilter").valueChanges
        .pipe(
          startWith(''),
          map(value => this.drpEmerCountry.filter(option => option?.countryName?.toLowerCase()?.includes(value?.toLowerCase() ?? "")))
        );
    }, err => {
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    });
    this.subscription$.push(getCountrySubscription)
  }
  onChangeEmerState(value: string | null | undefined) {
    this.contactsForm.patchValue({
      txtEmergencyCity: null,
      txtEmergencyState: null
    });
    this.drpEmerStates = [];
    this.drpEmerCities = [];
    if (value !== null && value !== undefined) {
      const emerStateSubscription = this.region.getAllStatesByCountryNameByCountryShortName(value).subscribe(response => {
        this.drpEmerStates = response?.items;
        if (response?.items?.length === 0) {
          this.phoneEmerCode = "";
          this.contactsForm.controls['txtEmergencyFax'].disable();
          this.contactsForm.controls['txtEmergencyPhone'].disable();
          this.contactsForm.controls['txtEmergencyMobile'].disable();
        } else {
          this.contactsForm.controls['txtEmergencyFax'].enable();
          this.contactsForm.controls['txtEmergencyPhone'].enable();
          this.contactsForm.controls['txtEmergencyMobile'].enable();
          this.phoneEmerCode = response?.items[0]?.countryPhoneCode;
        }
        this.filteredEmergencyStates = this.contactsForm?.get("txtEmergencyStateFilter").valueChanges
          .pipe(
            startWith(''),
            map(value => this.drpEmerStates.filter(option => option?.stateName?.toLowerCase()?.includes(value?.toLowerCase() ?? "")))
          );
      }, err => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      });
      this.subscription$.push(emerStateSubscription)
    }


    return this.drpEmerCountry  && this.drpEmerCountry?.length !== 0;

  }
  onChangeEmerCity(state: string | null | undefined, country: string) {
    this.contactsForm.patchValue({
      txtEmergencyCity: null,
    });
    country = (country == "") ? (this.contactsForm.value.txtEmergencyCountry == (null || undefined) ? "" : (this.contactsForm.value.txtEmergencyCountry)) : country;
    this.drpEmerCities = [];
    if (state !== null && state !== undefined) {
      const getEmercitySubscription = this.region.getAllCitiesByCountryAndStateNameByCountryShortNameAndStateShortName(country, state).subscribe(response => {
        this.drpEmerCities = response?.items;
        this.drpEmerStates = response?.items;

        this.filteredEmergencyCities = this.contactsForm?.get("txtEmergencyCityFilter").valueChanges
          .pipe(
            startWith(''),
            map(value => this.drpEmerCities.filter(option => option?.cityName?.toLowerCase()?.includes(value?.toLowerCase() ?? "")))
          );
      }, err => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      });
      this.subscription$.push(getEmercitySubscription)
    }
  }

  clearEmergencyCountry() {
    this.phoneEmerCode = "";
    this.contactsForm.patchValue({ txtEmergencyCountry: "" });
    this.EmergencyCountry = '';
  }
  clearEmergencyState() {
    this.EmergencyState = '';
    this.contactsForm.patchValue({ txtEmergencyState: "" });
  }
  clearEmergencyCity() {
    this.EmergencyCity = '';
    this.contactsForm.patchValue({ txtEmergencyCity: "" });

  }
  clearResponsibleCountry() {
    this.phoneResCode = "";
    this.ResponsibleCountry = '';
    this.contactsForm.patchValue({ txtResponsibleCountry: "" });

  }
  clearResponsibleState() {
    this.ResponsibleState = '';
    this.contactsForm.patchValue({ txtResponsibleState: "" });

  }
  clearResponsibleCity() {
    this.ResponsibleCity = '';
    this.contactsForm.patchValue({ txtResponsibleCity: "" });

  }

  //responsible country
  resCountryDropdown() {

    this.drpResCountry = [];
    this.drpResCities = [];
    this.drpResStates = [];
    const resCountry = this.region.getAllCountriesByInput(new PagedAndSortedResultRequestDto).subscribe(response => {
      this.drpResCountry = response?.items;


      this.filteredResponsibleCountries = this.contactsForm?.get("txtResponsibleCountryFilter").valueChanges
        .pipe(
          startWith(''),
          map(value => this.drpResCountry.filter(option => option?.countryName?.toLowerCase()?.includes(value?.toLowerCase() ?? "")))
        );
    }, err => {
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    });
    this.subscription$.push(resCountry)
  }


  //res state, city
  onChangeResState(value: string | null | undefined) {
    this.contactsForm.patchValue({
      txtResponsibleCity: null,
      txtResponsibleState: null
    });
    this.drpResCities = [];
    this.drpResStates = [];
    if (value !== null && value !== undefined) {
      const resState = this.region.getAllStatesByCountryNameByCountryShortName(value).subscribe(response => {
        this.drpResStates = response?.items;
        if (response?.items?.length=== 0) {
          this.phoneResCode = "";
          this.contactsForm.controls['txtResponsibleFax'].disable();
          this.contactsForm.controls['txtResponsiblePhone'].disable();
          this.contactsForm.controls['txtResponsibleMobile'].disable();
        } else {
          this.contactsForm.controls['txtResponsibleFax'].enable();
          this.contactsForm.controls['txtResponsiblePhone'].enable();
          this.contactsForm.controls['txtResponsibleMobile'].enable();
          this.phoneResCode = response?.items[0]?.countryPhoneCode;
        }
        this.filteredResponsibleStates = this.contactsForm?.get("txtResponsibleStateFilter").valueChanges
          .pipe(
            startWith(''),
            map(value => this.drpResStates.filter(option => option?.stateName?.toLowerCase()?.includes(value?.toLowerCase() ?? "")))
          );
      }, err => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      })
      this.subscription$.push(resState)
    }

  }

  onChangeResCity(state: string | null | undefined, country: string) {
    this.contactsForm.patchValue({
      txtResponsibleCity: null
    });
    country = (country == "") ? (this.contactsForm.value.txtResponsibleCountry == (null || undefined) ? "" : (this.contactsForm.value.txtResponsibleCountry)) : country;
    this.drpResCities = [];
    if (state !== null && state !== undefined) {
      const resCity = this.region.getAllCitiesByCountryAndStateNameByCountryShortNameAndStateShortName(country, state).subscribe(response => {
        this.drpResCities = response?.items;
        this.filteredResponsibleCities = this.contactsForm?.get("txtResponsibleCityFilter").valueChanges
          .pipe(
            startWith(''),
            map(value => this.drpResCities.filter(option => option?.cityName?.toLowerCase()?.includes(value?.toLowerCase() ?? "")))
          );
      }, err => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      })
      this.subscription$.push(resCity)
    }
  }

  trackby(value: string, index: string) {
    return index;
  }
  getPatientDetailsForSave() {
    let personalDetails: PatientDTO;
    if (this.patientId !== "" && this.patientId !== null && this.patientId !== undefined) {
      // const store = this.store.dispatch(new getPatient(this.patientId)).subscribe(response => {
      const store = this.patientService.get(this.patientId).subscribe(response => {
        personalDetails = response;
        this.saveContact(personalDetails);
      }, err => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      })
      this.subscription$.push(store);
    }
  }
  organizationUnitId = null;
  saveContact(response: PatientDTO) {
    this.isShowSpinner = true;
    this.saveButtonHide = true;
    // this.Table.getOrganizationUnitId().subscribe(value => {
    //   this.organizationUnitId = value;
    // });
    // this.organizationUnitId = localStorage.getItem('initialOrganizationUnitId');
    let contactAddress: PatientDTO;

    contactAddress = {
      personals: response?.personals,
      addresses: response?.addresses,
      contacts: [
        {
          id: this.patientId,
          relationshipId: (this.contactsForm.value.drpEmergencyRelationshipType == (null || undefined) ? "" : (this.contactsForm.value.drpEmergencyRelationshipType)),
          contactType: 0,
          firstName: this.contactsForm?.get("txtEmergencyFirstName").value,
          middleName: this.contactsForm?.get("txtEmergencyMiddleName").value,
          lastName: this.contactsForm?.get("txtEmergencyLastName").value,
          address: this.contactsForm?.get("txtEmergencyAddress").value,
          city: this.contactsForm?.get("txtEmergencyCity").value,
          state: this.contactsForm?.get("txtEmergencyState").value,
          country: this.contactsForm?.get("txtEmergencyCountry").value,
          county: this.contactsForm?.get("txtEmergencyCounty").value,
          postalCode: this.contactsForm?.get("txtEmergencyPostalCode").value,
          phone: this.contactsForm?.get("txtEmergencyPhone").value,
          fax: this.contactsForm?.get("txtEmergencyFax").value,
          mobile: this.contactsForm?.get("txtEmergencyMobile").value,
          emailAddress: this.contactsForm?.get("txtEmergencyEmail").value,
          // createdByUserId: 1,
        },
        {
          id: this.patientId,
          relationshipId: (this.contactsForm.value.drpResponsibleRelationshipType == (null || undefined) ? "" : (this.contactsForm.value.drpResponsibleRelationshipType)),
          contactType: 1,
          firstName: this.contactsForm?.get("txtResponsibleFirstName").value,
          middleName: this.contactsForm?.get("txtResponsibleMiddleName").value,
          lastName: this.contactsForm?.get("txtResponsibleLastName").value,
          address: this.contactsForm?.get("txtResponsibleAddress").value,
          city: this.contactsForm?.get("txtResponsibleCity").value,
          state: this.contactsForm?.get("txtResponsibleState").value,
          country: this.contactsForm?.get("txtResponsibleCountry").value,
          county: this.contactsForm?.get("txtResponsibleCounty").value,
          postalCode: this.contactsForm?.get("txtResponsiblePostalCode").value,
          phone: this.contactsForm?.get("txtResponsiblePhone").value,
          fax: this.contactsForm?.get("txtResponsibleFax").value,
          mobile: this.contactsForm?.get("txtResponsibleMobile").value,
          emailAddress: this.contactsForm?.get("txtResponsibleEmail").value.toLowerCase(),
          // createdByUserId: 1,
        }
      ],
      clinicals: response?.clinicals,
      insurances: response?.insurances,
      notes: response?.notes,
      policies: response?.policies,
      defaultPatientId: response?.defaultPatientId,
      insuranceVerifications: response?.insuranceVerifications,
      authorizations: response?.authorizations,
      patientDocuments: response?.patientDocuments,
      organizationUnitId: response?.organizationUnitId,
      isAutoIntake: response?.isAutoIntake,
      autoIntakeDocumentDetails: response?.autoIntakeDocumentDetails,
      chartId: response?.chartId,
      billingDetails: response?.billingDetails,
      patientLock: response?.patientLock,
      isSorted: response?.isSorted,
      fileId: response?.fileId,
      // fileId:null,

    };

    this.patientId !== "" && this.store.dispatch(new updatePatientIntake(this.patientId, contactAddress)).subscribe(response => {
      let patientSaved: PatientDTO = response?.patientState?.selectedPatient;
      this.patientIdEmitter.emit(patientSaved);
      Swal.fire({ title: 'Success', html: 'Saved Successfully', icon: 'success', timer: 3000, timerProgressBar: true }).then(result => {
        if (result.value) {
          //!change next tab
          this.nextTabMoveOnSaveEmitter.emit("Contact");
        }
        this.isShowSpinner = false;
      }, err => { });
      this.saveButtonHide = false;
      this.isShowSpinner = false;
      this.saveContactFormState.emit(this.contactsForm.value);

    }, err => {
      this.saveButtonHide = false;
      this.isShowSpinner = false;
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    }, () => {
    });

  }




  //! Personal Details Get By ID Functions
  getPatientDetailsByID() {
    const patientUpdate = this.Table.getPatientUpdate().subscribe(value => {
      this.isPatientUpdatePermission = value;
      if (this.isPatientUpdatePermission == true) {
        this.isShownUpdateIconForPatient = true;
      }
      else {
        this.isShownUpdateIconForPatient = false;
      }
    }, err => {
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    });
    this.subscription$.push(patientUpdate)
    //
    if ((this.patientId !== "" && this.patientId !== null && this.patientId !== undefined)) {
      const store = this.patientService.get(this.patientId).subscribe(response => {
        const selectedPatient: PatientDTO = response;
        //Patient is locked & userId is not equal to loginUserId or patient is unlocked
        this.isPatientLockedByCurrentUser = (selectedPatient?.patientLock?.isLocked == true &&
          selectedPatient?.patientLock?.userId == this.strLoginUserId) ||
          (selectedPatient?.patientLock?.isLocked == false) ? true : false;
        selectedPatient?.contacts;
        let EmrgcyAddress: ContactDTO = selectedPatient?.contacts[0];
        let RespbleAddress: ContactDTO = selectedPatient?.contacts[1];
        this.EmergencyCountry = EmrgcyAddress?.country ?? "";
        this.ResponsibleCountry = RespbleAddress?.country ?? "";
        this.ResponsibleCountry != null && this.ResponsibleCountry !== "" && this.ResponsibleCountry != undefined && this.onChangeRespState(this.ResponsibleCountry) && this.contactsForm.patchValue({
          txtResponsibleCountry: this.ResponsibleCountry,
        });
        this.EmergencyCountry != null && this.EmergencyCountry !== "" && this.EmergencyCountry != undefined && this.onChangeEmerState(this.EmergencyCountry) && this.contactsForm.patchValue({
          txtEmergencyCountry: this.EmergencyCountry,
        });



        selectedPatient.contacts[0].id !== null && selectedPatient.contacts[0].id !== null && selectedPatient.contacts[0].id !== null && this.contactsForm.patchValue({

          drpEmergencyRelationshipType: EmrgcyAddress.relationshipId,
          txtEmergencyFirstName: EmrgcyAddress.firstName,
          txtEmergencyMiddleName: EmrgcyAddress.middleName,
          txtEmergencyLastName: EmrgcyAddress.lastName,
          txtEmergencyAddress: EmrgcyAddress.address,
          txtEmergencyCity: EmrgcyAddress.city === "" || undefined ? null : EmrgcyAddress.city,
          txtEmergencyState: EmrgcyAddress.state === "" || undefined ? null : EmrgcyAddress.state,
          txtEmergencyCounty: EmrgcyAddress.county,
          txtEmergencyCountry: EmrgcyAddress.country === "" || undefined ? null : EmrgcyAddress.country,
          txtEmergencyPostalCode: EmrgcyAddress.postalCode,
          txtEmergencyPhone: EmrgcyAddress.phone,
          txtEmergencyMobile: EmrgcyAddress.mobile,
          txtEmergencyEmail: EmrgcyAddress.emailAddress,
          txtEmergencyFax: EmrgcyAddress.fax,
          drpResponsibleRelationshipType: RespbleAddress.relationshipId,
          txtResponsibleFirstName: RespbleAddress.firstName,
          txtResponsibleMiddleName: RespbleAddress.middleName,
          txtResponsibleLastName: RespbleAddress.lastName,
          txtResponsibleAddress: RespbleAddress.address,
          txtResponsibleCity: RespbleAddress.city === "" || undefined ? null : RespbleAddress.city,
          txtResponsibleState: RespbleAddress.state === "" || undefined ? null : RespbleAddress.state,
          txtResponsibleCountry: RespbleAddress.country === "" || undefined ? null : RespbleAddress.country,
          txtResponsibleCounty: RespbleAddress.county,
          txtResponsiblePostalCode: RespbleAddress.postalCode,
          txtResponsiblePhone: RespbleAddress.phone,
          txtResponsibleMobile: RespbleAddress.mobile,
          txtResponsibleEmail: RespbleAddress.emailAddress,
          txtResponsibleFax: RespbleAddress.fax,
        });
      }, err => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      })
      this.subscription$.push(store);
    }

  }


  //! Personal Address Copy Function
  copyPatientInfo(value?: 1 | 2, value1?: string) {
    if (value === 1) {
      // const copyPatient = this.store.dispatch(new getPatient(this.patientId)).subscribe(responseData => {
      const copyPatient = this.patientService.get(this.patientId).subscribe(responseData => {
        let response: PersonalDTO = responseData?.personals;
        let address: AddressDTO = responseData?.addresses[0];
        this.contactsForm.patchValue({
          txtEmergencyFirstName: response?.firstName,
          txtEmergencyMiddleName: response?.middleName,
          txtEmergencyLastName: response?.lastName,
          txtEmergencyAddress: address.address,
          txtEmergencyCity: address?.city === "" || address?.city === undefined ? null : address?.city,
          txtEmergencyState: address?.state === "" || address?.state === undefined ? null : address?.state,
          txtEmergencyCounty: address?.county === "" ? null : address?.county,
          txtEmergencyCountry: address?.country === "" || address?.country === undefined ? null : address?.country,
          txtEmergencyPostalCode: address?.postalCode,
          txtEmergencyPhone: address?.phone,
          txtEmergencyMobile: address?.mobile,
          //   txtEmergencyEmail: address?.emailId,
          txtEmergencyEmail: response?.emailId,
          txtEmergencyFax: address?.fax,
        });
        this.onChangeEmergState(this.contactsForm.value.txtEmergencyCountry);
      }, err => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      });
      this.subscription$.push(copyPatient)
    }


    value1 === this.responsibleRelationship.filter(value => { return value && value.relationType === 'Self' })[0].relationShipCode && value === 2 && this.patientService.get(this.patientId).subscribe(responseData => {
      let response: PersonalDTO = responseData?.personals;
      let address: AddressDTO = responseData?.addresses[0];
      this.contactsForm.patchValue({
        txtResponsibleFirstName: response?.firstName,
        txtResponsibleMiddleName: response?.middleName,
        txtResponsibleLastName: response?.lastName,
        txtResponsibleAddress: address.address,
        txtResponsibleCity: address?.city === "" || address?.city === undefined ? null : address?.city,
        txtResponsibleState: address?.state === "" || address?.city === undefined ? null : address?.state,
        txtResponsibleCountry: address?.country === "" ? null : address?.country,
        txtResponsibleCounty: address?.county === "" || address?.city === undefined ? null : address?.county,
        txtResponsiblePostalCode: address?.postalCode,
        txtResponsiblePhone: address?.phone,
        txtResponsibleMobile: address?.mobile,
        // txtResponsibleEmail: address?.emailId,
        txtResponsibleEmail: response?.emailId,
        txtResponsibleFax: address?.fax,
      });
      this.onChangeRespState(this.contactsForm.value.txtResponsibleCountry);
    }, err => {
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    });

  }

  onChangeEmergState(value: string | null | undefined) {
    this.contactsForm.patchValue({
      txtEmergencyCity: null,
      txtEmergencyState: null
    });
    this.drpEmerStates = [];
    this.drpEmerCities = [];
    if (value !== null && value !== undefined) {
      const emerState = this.region.getAllStatesByCountryNameByCountryShortName(value).subscribe(response => {
        this.drpEmerStates = response?.items;
        if (response?.items?.length=== 0) {
          this.phoneEmerCode = "";
          this.contactsForm.controls['txtEmergencyFax'].disable();
          this.contactsForm.controls['txtEmergencyPhone'].disable();
          this.contactsForm.controls['txtEmergencyMobile'].disable();
        } else {
          this.contactsForm.controls['txtEmergencyFax'].enable();
          this.contactsForm.controls['txtEmergencyPhone'].enable();
          this.contactsForm.controls['txtEmergencyMobile'].enable();
          this.phoneEmerCode = response?.items[0]?.countryPhoneCode;
        }
        this.filteredEmergencyStates = this.contactsForm?.get("txtEmergencyStateFilter").valueChanges
          .pipe(
            startWith(''),
            map(value => this.drpEmerStates.filter(option => option?.stateName?.toLowerCase()?.includes(value?.toLowerCase() ?? "")))
          );
        // this.store.dispatch(new getPatient(this.patientId)).subscribe(responseData => {
        this.patientService.get(this.patientId).subscribe(responseData => {
          let response: PersonalDTO = responseData?.personals;
          let address: AddressDTO = responseData?.addresses[0];
          this.contactsForm.patchValue({
            txtEmergencyState: address?.state === "" || address?.state === undefined ? null : address?.state,
            txtEmergencyCountry: address?.country === "" || address?.country === undefined ? null : address?.country,
          });
          this.onChangeEmergencyCity(this.contactsForm.value.txtEmergencyState, this.contactsForm.value.txtEmergencyCountry);
        })
      }, err => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      });
      this.subscription$.push(emerState)
    }
  }

  onChangeEmergencyCity(state: string | null | undefined, country: string) {
    this.contactsForm.patchValue({
      txtEmergencyCity: null,
    });
    country = (country == "") ? (this.contactsForm.value.txtEmergencyCountry == (null || undefined) ? "" : (this.contactsForm.value.txtEmergencyCountry)) : country;
    this.drpEmerCities = [];
    if (state !== null && state !== undefined) {
      const emerCity = this.region.getAllCitiesByCountryAndStateNameByCountryShortNameAndStateShortName(country, state).subscribe(response => {
        this.drpEmerCities = response?.items;
        this.drpEmerStates = response?.items;
        this.filteredEmergencyCities = this.contactsForm?.get("txtEmergencyCityFilter").valueChanges
          .pipe(
            startWith(''),
            map(value => this.drpEmerCities.filter(option => option?.cityName?.toLowerCase()?.includes(value?.toLowerCase() ?? "")))
          );
        this.patientService.get(this.patientId).subscribe(responseData => {
          let response: PersonalDTO = responseData?.personals;
          let address: AddressDTO = responseData?.addresses[0];
          this.contactsForm.patchValue({
            txtEmergencyState: address?.state === "" || address?.state === undefined ? null : address?.state,
            txtEmergencyCountry: address?.country === "" || address?.country === undefined ? null : address?.country,
            txtEmergencyCity: address?.city === "" || address?.city === undefined ? null : address?.city,
          });
        })
      }, err => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      });
      this.subscription$.push(emerCity);
    }
  }

  onChangeRespState(value: string | null | undefined) {
    this.contactsForm.patchValue({
      txtResponsibleCity: null,
      txtResponsibleState: null
    });
    this.drpResCities = [];
    this.drpResStates = [];
    if (value !== null && value !== undefined) {
      const respState = this.region.getAllStatesByCountryNameByCountryShortName(value).subscribe(response => {
        this.drpResStates = response?.items;
        if (response?.items?.length=== 0) {
          this.phoneResCode = "";
          this.contactsForm.controls['txtResponsibleFax'].disable();
          this.contactsForm.controls['txtResponsiblePhone'].disable();
          this.contactsForm.controls['txtResponsibleMobile'].disable();
        } else {
          this.contactsForm.controls['txtResponsibleFax'].enable();
          this.contactsForm.controls['txtResponsiblePhone'].enable();
          this.contactsForm.controls['txtResponsibleMobile'].enable();
          this.phoneResCode = response?.items[0]?.countryPhoneCode;
        }
        this.filteredResponsibleStates = this.contactsForm?.get("txtResponsibleStateFilter").valueChanges
          .pipe(
            startWith(''),
            map(value => this.drpResStates.filter(option => option?.stateName?.toLowerCase()?.includes(value?.toLowerCase() ?? "")))
          );
        this.patientService.get(this.patientId).subscribe(responseData => {
          let response: PersonalDTO = responseData?.personals;
          let address: AddressDTO = responseData?.addresses[0];
          this.contactsForm.patchValue({
            txtResponsibleState: address?.state === "" || address?.city === undefined ? null : address?.state,
            txtResponsibleCountry: address?.country === "" ? null : address?.country,
          });
          this.onChangeRespCity(this.contactsForm.value.txtResponsibleState, this.contactsForm.value.txtResponsibleCountry);
        })
      }, err => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      });
      this.subscription$.push(respState)
    }
    return this.drpResCountry  && this.drpResCountry?.length !== 0;
  }

  onChangeRespCity(state: string | null | undefined, country: string) {
    this.contactsForm.patchValue({
      txtResponsibleCity: null,
    });

    country = (country == "") ? (this.contactsForm.value.txtEmergencyCountry == (null || undefined) ? "" : (this.contactsForm.value.txtEmergencyCountry)) : country;
    this.drpResCities = [];
    if (state !== null && state !== undefined) {
      const respCity = this.region.getAllCitiesByCountryAndStateNameByCountryShortNameAndStateShortName(country, state).subscribe(response => {
        this.drpResCities = response?.items;
        this.filteredResponsibleCities = this.contactsForm?.get("txtResponsibleCityFilter").valueChanges
          .pipe(
            startWith(''),
            map(value => this.drpResCities.filter(option => option?.cityName?.toLowerCase()?.includes(value?.toLowerCase() ?? "")))
          );
          this.patientService.get(this.patientId).subscribe(responseData => {
          let response: PersonalDTO = responseData?.personals;
          let address: AddressDTO = responseData?.addresses[0];
          this.contactsForm.patchValue({
            txtResponsibleCity: address?.city === "" || address?.city === undefined ? null : address?.city,
            txtResponsibleState: address?.state === "" || address?.city === undefined ? null : address?.state,
            txtResponsibleCountry: address?.country === "" ? null : address?.country,

          });
        })
      }, err => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      });
      this.subscription$.push(respCity)
    }
  }


  // statesChanged(country: number) {
  //   let data: customStateDTO[] = [];
  //   this.drpCustomState$.pipe(map(value => {
  //     value.filter(element => { element.country_id === country && data.push(element) });
  //     return data;
  //   })).subscribe(value => {
  //     this.drpCustomstate = value;
  //   })
  // }
  // cityChanged(state: number) {
  //   let data: customCityDTO[] = [];
  //   this.drpCustomCity$.pipe(map(value => {
  //     value.filter(element => { element.state_id === state && data.push(element) });
  //     return data;
  //   })).subscribe(value => {
  //     this.drpCustomCity = value;
  //   })
  // }
}


export interface customStateDTO {

  id: number;
  name: string;
  state_code: string;
  country_id: number;
  country_code: string;
  latitude: string;
  longitude: string;

}

export interface customCityDTO {

  id: number,
  name: string,
  state_id: number,
  state_code: string,
  country_id: number,
  country_code: string,
  latitude: string,
  longitude: string

}
export interface customCountryDTO { id: number, name: string }
