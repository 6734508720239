import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { DocumentMaxandTypeLimits } from 'projects/shared/src/app/enums/allenums.enum';
import { PDFDocument } from 'pdf-lib';
import Swal from 'sweetalert2';
import { TableService } from 'projects/shared/src/app/table.service';
import { CreateUpdatepatientTemplateDTO, PatientTemplateService } from 'projects/admin/src/app/admin-proxy/platform-app-management/rcm/platform-management/patient-template';
import { ToastrService } from 'ngx-toastr';
import { HttpErrorResponse } from '@angular/common/http';
import { Subscription } from 'rxjs';
import { PagedAndSortedResultRequestDto } from '@abp/ng.core';
import { CommunicationService } from 'projects/shared/src/app/services/communication.service';
import { IFormFile } from '../patient-proxy/microsoft/asp-net-core/http';
import { Title } from '@angular/platform-browser';
import { CommonService } from 'projects/shared/src/app/services/common.service';

@Component({
  selector: 'app-template-creation',
  templateUrl: './template-creation.component.html',
  styleUrls: ['./template-creation.component.scss']
})
export class TemplateCreationComponent implements OnInit {

  chkdisableButton: boolean = false;
  templateUploadForm: FormGroup;
  showErrorForFileType: boolean = false;
  showErrorBasedOnMaxSize: boolean = false;
  arrSelectedDocuments: any[] = [];
  newFilesForUpload: File[] = [];
  selectedFileCount: number = 0;
  documentMaxNoFileLimit = DocumentMaxandTypeLimits.documentMaxNoFileLimit;
  showErrorForFileLength: boolean = false;
  totalSizeforTable: any;
  AddMoreFileSize: any;
  disableMainFile: boolean = false;
  isRefresh = false;
  addDocumentbinModalTitle: string = 'Upload Template Document';
  strPdfString: string | null = null; // Initialize to null
  templateFileName: string;
  templateDataList: any;
  isLoading: boolean = false;
  tableData$: Subscription;
  constructor(private fb: FormBuilder, private table: TableService,
    private commonService : CommonService,
    private title : Title, private toastr: ToastrService, private patientTemplateServices: PatientTemplateService, private communicationService: CommunicationService) {
    this.communicationService.functionReloadTemplate$.subscribe((statusView) => {
      this.getTemplateList();
    });
  }

  ngOnInit(): void {
    this.title.setTitle('Qsecure | Template');
    this.templateUploadForm = this.fb.group({
      documentFile: new FormControl(null, [Validators.required]),
    });
    this.table.setSelectedDocumentList(this.arrSelectedDocuments);
    this.getTemplateList();
  }


  //GET Template List In Table
  getTemplateList() {
    this.isLoading = true;
    this.tableData$?.unsubscribe();
    const getRemindertbleData = this.patientTemplateServices
      .getList(new PagedAndSortedResultRequestDto)
      .subscribe(
        (response) => {
          this.templateDataList = response?.items;
          this.templateDataList = this.templateDataList.map((data) => {
            return {
              ...data,
              creationTime: this.commonService.getFormattedDateTimeZone(
                data.creationTime
              ),
            };
          });
          this.isLoading = false;
        },
        (err) => {
          this.isLoading = false;
          this.templateDataList = [];
          const data: HttpErrorResponse = err;
          Swal.fire({
            icon: 'info',
            text: data?.error?.error?.message,
          });
        }
      );
    this.tableData$ = getRemindertbleData;
  }


  // On Template Select Changes
  onFileChange(event: any): void {
    const files: FileList = event?.target?.files;
    if (files.length === 0) {
      this.showErrorForFileType = false;
      this.showErrorBasedOnMaxSize = false;
      this.showErrorForFileLength = false;
      this.arrSelectedDocuments = [];
      // this.filesForUpload = [];
      this.newFilesForUpload = [];
      this.chkdisableButton = false;
      this.selectedFileCount = 0;
      return;
    }

    if (this.calculateTotalSize(files)) {
      this.showErrorForFileType = false;
      this.showErrorBasedOnMaxSize = false;
      this.showErrorForFileLength = false;
      this.arrSelectedDocuments = [];
      // this.filesForUpload = files;
      this.newFilesForUpload = [];
      this.mergeFileLists(files);
      this.chkdisableButton = true;
      this.selectedFileCount = files.length;
      this.totalSizeforTable = this.calculateTotalSizeValue(files);
      const totalFiles = files.length;
      let filesProcessed = 0;
      if (files && files.length > 0) {
        let fileResult: {
          fileName: string;
          fileType: string;
          fileSize: string;
          pageCount?: number;
        }[] = [];
        const fileList: File[] = [];
        const onCompleteProcessing = () => {
          filesProcessed++;
          if (filesProcessed === totalFiles) {
            this.chkdisableButton = false;
          }
        };
        for (let i = 0; i < files.length; i++) {
          if (files[i].type !== DocumentMaxandTypeLimits.documentTypeLimit) {
            this.showErrorForFileType = true;
            this.showErrorBasedOnMaxSize = false;
            this.showErrorForFileLength = false;
            this.arrSelectedDocuments = [];
            this.chkdisableButton = false;
            this.selectedFileCount = 0;
            return;
          }

          fileList.push(files[i]);
          const fileName: string = files[i].name;
          const m1fileName: string = fileName.replace('.pdf', '');
          const m2fileName: string = m1fileName.replace('.pdf', '');

          const getPDFPageCount = async (file: File) => {
            try {
              const pdfBytes = await file.arrayBuffer();
              const pdfDoc = await PDFDocument.load(pdfBytes, { ignoreEncryption: true }); // Add { ignoreEncryption: true }
              const pages = pdfDoc.getPages();
              return pages.length;
            } catch (error) {
              console.warn(error);
              return 0; // Return a default value or handle the error as per your requirement
            }
          };

          getPDFPageCount(files[i]).then((pageCount) => {
            fileResult.push({
              fileName: m2fileName,
              fileType: files[i].type === 'application/pdf' ? 'PDF' : 'N/A',
              fileSize: this.convertBytesToReadable(files[i].size),
              pageCount: pageCount,
            });
            onCompleteProcessing();
          });
        }

        this.arrSelectedDocuments = fileResult;
        this.disableMainFile = true;
      }
      // event.target.value = null;
    } else {
      this.showErrorForFileType = false;
      this.showErrorBasedOnMaxSize = true;
      this.showErrorForFileLength = false;
      this.arrSelectedDocuments = [];
      this.chkdisableButton = false;
      this.selectedFileCount = 0;
      // event.target.value = null;
      return;
    }

  }

  //Template File Size Calculating
  calculateTotalSize(files) {
    let totalSize = 0;
    let status = false;
    let maxTotalSize = DocumentMaxandTypeLimits.TemplateUploadMaxSize;
    for (const key in files) {
      if (files.hasOwnProperty(key)) {
        totalSize += files[key].size;
      }
    }
    if (totalSize <= maxTotalSize) {
      status = true;
    }
    return status;
  }

  mergeFileLists(newFiles) {
    for (let i = 0; i < newFiles.length; i++) {
      const file = newFiles[i];
      // Check if the file already exists in the array
      const exists = this.newFilesForUpload.some(
        (existingFile) =>
          existingFile.name === file.name && existingFile.size === file.size
      );
      if (!exists) {
        this.newFilesForUpload.push(file);
        // this.toastr.success(`File "${file.name}" Added.`, 'Success' );
      }
      // else {
      //   this.toastr.warning(`File "${file.name}" already exists.`, 'Warning' );
      // }
    }
  }

  calculateTotalSizeValue(files) {
    this.AddMoreFileSize = 0;
    let totalSize = 0;
    for (const key in files) {
      if (files.hasOwnProperty(key)) {
        totalSize += files[key].size;
      }
    }
    this.AddMoreFileSize = totalSize;
    return this.convertBytesToReadable(totalSize);
  }

  convertBytesToReadable(bytes) {
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    if (bytes == 0) return '0 Byte';
    if (isNaN(bytes)) return '0 Bytes';
    const i = Math.floor(Math.log(bytes) / Math.log(1024));
    return parseFloat((bytes / Math.pow(1024, i)).toFixed(2)) + ' ' + sizes[i];
  }


//Save Template Function #region
  savefunction() {
    this.chkdisableButton = true;
    let file: File[];
    (file = this.newFilesForUpload),
      this.patientTemplateServices.createManualUploadTemplateByFileV1(file).subscribe(
        (response) => {
          this.resetInboundDocumentForm();
          this.getTemplateList();
          this.chkdisableButton = false;
          if (response?.errors !== "") {
            Swal.fire({
              icon: 'info',
              text: response?.errors,
            });
          }
          if (response?.dto !== null){
            this.toastr.success('Template has been successfully Uploaded');
          }
        },
        (err) => {
          const data: HttpErrorResponse = err;
          Swal.fire({
            icon: 'info',
            text: data?.error?.error?.message,
          });
          this.chkdisableButton = false;
          this.getTemplateList();
        }
      );
  }
// # end region

//Delete Template Function #region
  deleteDocument(e) {
    Swal.fire({
      title: 'Are you sure you want to Delete?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#34c38f',
      cancelButtonColor: '#f46a6a',
      confirmButtonText: 'Yes, delete it!',
    }).then((result) => {
      if (result.value) {
        // Find index of document in arrSelectedDocuments
        const index = this.arrSelectedDocuments.indexOf(e);
        if (index !== -1) {
          // Remove document from arrSelectedDocuments
          this.arrSelectedDocuments.splice(index, 1);
          this.selectedFileCount--;

          // Find index of file in filesForUpload
          let indexToRemove = -1;
          // for (let i = 0; i < this.filesForUpload.length; i++) {
          //   if (this.filesForUpload[i].name === e.fileName + '.pdf') {
          //     indexToRemove = i;
          //     break;
          //   }
          // }
          for (let i = 0; i < this.newFilesForUpload.length; i++) {
            if (this.newFilesForUpload[i].name === e.fileName + '.pdf') {
              indexToRemove = i;
              break;
            }
          }

          if (indexToRemove > -1 && indexToRemove < this.newFilesForUpload.length) {
            this.newFilesForUpload.splice(indexToRemove, 1);
          }
        }
      }
      if (this.arrSelectedDocuments.length === 0) {
        this.templateUploadForm.patchValue({
          documentFile: null,
        });
      }
      this.totalSizeforTable = this.calculateTotalSizeValue(
        this.newFilesForUpload
      );
    });
  }
//#endregion

// Reset Tmplate #region
  resetInboundDocumentForm() {
    this.templateUploadForm.reset({
      documentFile: null,
      txtComments: '',
      drpBranchType: null,
    });
    // this.selectedFileUploadsData = null;
    this.showErrorForFileType = false;
    this.showErrorForFileLength = false;
    // this.showErrorBasedOnSize = false;
    this.showErrorBasedOnMaxSize = false;
    this.arrSelectedDocuments = [];
    this.newFilesForUpload = [];
    this.chkdisableButton = false;
    this.addDocumentbinModalTitle = 'Upload Template Document';
    this.table.setSelectedDocumentList(this.arrSelectedDocuments);
    this.selectedFileCount = 0;
    this.disableMainFile = false;
  }
//#endregion
  async getPDFPageCount(file: File): Promise<number> {
    try {
      const pdfBytes = await file.arrayBuffer();
      const pdfDoc = await PDFDocument.load(pdfBytes, {
        ignoreEncryption: true,
      });
      const pages = pdfDoc.getPages();
      return pages.length;
    } catch (error) {
      console.warn(error);
      return 0; // Return a default value or handle the error as per your requirement
    }
  }
}
