<div class="row bg">
  <div class="col-12">
    <div class="d-flex align-item-center justify-content-between">
      <h3 class="font-weight-bolder mb-2 mt-2">
        <b class="customThemeClass">Inventory Management / Bulk Update UnitCost & Allowable</b>
      </h3>
      <div class="page-title-box page-title-right mt-1 mb-0">
        <ol class="breadcrumbs m-0"></ol>
      </div>
    </div>
  </div>
</div>

<div class="row">
  <div class="col-12 mb-4">
    <div class="card">
      <div class="card-header d-flex justify-content-between align-items-center">
        <span class="header-text">Bulk Update List</span>
        <button class="btn btn-primary" (click)="onAddButtonClick()">Add New Item</button>
      </div>
      <div class="card-body p-1">
        <div class="d-flex justify-content-center" *ngIf="!loadTable">
          <!-- <mat-spinner></mat-spinner> -->
          <img
            class="img-responsive pb-2 mr-1"
            src="assets/gifs/Infinity-loader.gif"
            alt="Loading spinner"
          />
        </div>
        <div *ngIf="loadTable" class="table table-responsive">
          <!-- Table Start -->
          <app-bulk-update-unit-cost-and-allowable-table
            [tableData]="tableData"
          ></app-bulk-update-unit-cost-and-allowable-table>
        </div>
        <!-- Table End -->
      </div>
    </div>
  </div>
</div>
