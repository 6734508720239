<!-- <h2 mat-dialog-title>Insurance Verification</h2> -->
<div class="row">
  <div class="col-lg-6">
    <h2>Insurance Verification</h2>
  </div>
  <!-- <div class="col-lg-6 text-right">
    <button class="text-right close" aria-label="Close" mat-dialog-close> <b> X </b>
    </button>
  </div> -->
</div>
<mat-dialog-content class="mat-typography"><br>
  <form [formGroup]="verificationForm">

    <div class="row">
      <div class="col-lg-4">
        <div class="row">
          <div class="col-md-12">
            <h4 class="customThemeClass"><b>Patient ID: {{patientids}}</b></h4>
          </div>
          <!-- <div class="col-md-6">
      </div> -->
        </div>
        <mat-card-content>
          <div class="row">
            <!-- <mat-form-field class="col-6">
          <mat-label> Chart # <span class="asterisk">*</span></mat-label>
          <mat-select maxlength="40" formControlName="drpChart">
            <mat-option *ngFor="let chart of drpChart" [value]="chart.chartId">
              {{chart.chartType}}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="
          verificationForm?.get('drpChart')?.touched &&
          verificationForm?.get('drpChart')?.errors?.required
          ">
            Chart is a required field!
          </mat-error>
        </mat-form-field> -->
            <mat-form-field class="col-6">
              <mat-label>Patient Name</mat-label>
              <input autocomplete="new-txtName" formControlName="txtName" maxlength="40" matInput type="text">
              <mat-error *ngIf="
          verificationForm?.get('txtName')?.touched &&
          verificationForm?.get('txtName')?.errors?.sAlphabets
          ">
                Enter only Alphabets!
              </mat-error>
            </mat-form-field>
            <!-- <mat-form-field class="col-6">
          <mat-label> Address</mat-label>
          <textarea formControlName="txtAddress" maxlength="100" matInput rows="3"></textarea>
          <mat-error *ngIf="
          verificationForm?.get('txtAddress')?.touched &&
          verificationForm?.get('txtAddress')?.errors?.sAlphabets
          ">
            Enter only Alphabets!
          </mat-error>
        </mat-form-field> -->
            <mat-form-field class="col-6">
              <mat-label>Date Of Birth <span class="asterisk">*</span></mat-label>
              <input autocomplete="new-txtDateOfBirth" formControlName="txtDateOfBirth" matInput maxlength="40" placeholder="MM/DD/YYYY"
                [matDatepicker]="txtDateOfBirth" [min]="BirthdateMinDate">
              <mat-datepicker-toggle matSuffix [for]="txtDateOfBirth">
              </mat-datepicker-toggle>
              <mat-datepicker #txtDateOfBirth></mat-datepicker>
              <mat-error *ngIf=" verificationForm?.get('txtDateOfBirth')?.touched &&
              verificationForm?.get('txtDateOfBirth')?.errors">
                Enter Valid Date!
              </mat-error>
              <mat-error *ngIf="
          verificationForm?.get('txtDateOfBirth')?.touched &&
          verificationForm?.get('txtDateOfBirth')?.errors?.required
          ">
                Date Of Birth is a required field!
              </mat-error>

            </mat-form-field>
            <mat-form-field class="col-12">
              <mat-label>Comments/Notes</mat-label>
              <textarea autocomplete="new-txtCommentsNotes" maxlength="1000" matInput [(ngModel)]="notesText"
              (input)="updateCharacterCount()" formControlName="txtCommentsNotes" rows="5"></textarea>  <div class="char-count">
                {{ notesText?.length }} / 5000
              </div>
              <!-- <mat-error *ngIf="
          verificationForm?.get('txtCommentsNotes')?.touched &&
          verificationForm?.get('txtCommentsNotes')?.errors?.sAlphabets
          ">
            Enter only Alphabets!
          </mat-error> -->
            </mat-form-field>

          </div>
        </mat-card-content>
      </div>
      <div class="col-lg-8">
        <div class="row">
          <div class="col-md-6">
            <h4 class="customThemeClass"><b>Lookup Patient Ins</b></h4>
          </div>
          <!-- <div class="col-md-6 text-right">
            <button mat-button class="buttonColor mr-1 font-size-13 btn-sm" (click)="openPolicyModal()">
              <i class="mdi mdi-plus font-size-16 mr-1"></i>Add Policy</button>
          </div> -->
        </div>
        <mat-card-content>
          <div class="row">

            <mat-form-field class="col-3">
              <mat-label> Plan <span class="asterisk">*</span></mat-label>
              <mat-select (valueChange)="valueChangeLoadDropdown($event)" [(value)]="plan" formControlName="txtPlan"
                maxlength="40">
                <mat-option *ngFor="let payor of payorLevel" [value]="payor.payorLevelName">
                  {{ payor.payorLevelName }}
                </mat-option>
              </mat-select>
              <button mat-button (click)="clearPlan()" (click)="plan=undefined;$event.stopPropagation()" *ngIf="plan"
                matSuffix mat-icon-button >
                <mat-icon>close</mat-icon>
              </button>
              <mat-error *ngIf="
          verificationForm?.get('txtPlan')?.touched &&
          verificationForm?.get('txtPlan')?.errors?.required
          ">
                Plan is a required field!
              </mat-error>
            </mat-form-field>
            <mat-form-field class="col-3">
              <mat-label> Plan Name <span class="asterisk">*</span></mat-label>
              <input autocomplete="new-txtPlanName" formControlName="txtPlanName" matInput type="text">
              <mat-error *ngIf="
          verificationForm?.get('txtPlanName')?.touched &&
          verificationForm?.get('txtPlanName')?.errors?.required
          ">
                Plan Name is a required field!
              </mat-error>
              <mat-error *ngIf="
          verificationForm?.get('txtPlanName')?.touched &&
          verificationForm?.get('txtPlanName')?.errors?.sAlphabets
          ">
                Enter only Alphabets!
              </mat-error>
            </mat-form-field>
            <mat-form-field class="col-3">
              <mat-label> Plan Phone</mat-label>
              <input autocomplete="new-txtPlanPhone" formControlName="txtPlanPhone" maxlength="20" prefix="+1-" mask="(000)-000-0000" matInput
                type="text">
              <mat-error *ngIf="
                      verificationForm?.get('txtPlanPhone')?.touched &&
                      verificationForm?.get('txtPlanPhone')?.errors
                      ">
                Enter Minimum 10 Digits!
              </mat-error>
            </mat-form-field>
            <mat-form-field class="col-3">
              <mat-label>Policy #<span class="asterisk">*</span></mat-label>
              <input autocomplete="new-txtPolicy" formControlName="txtPolicy" maxlength="40" matInput type="text">
              <mat-error *ngIf="
          verificationForm?.get('txtPolicy')?.touched &&
          verificationForm?.get('txtPolicy')?.errors?.required
          ">
                Policy is a required field!
              </mat-error>
              <mat-error *ngIf="
          verificationForm?.get('txtPolicy')?.touched &&
          verificationForm?.get('txtPolicy')?.errors?.sAlphabets
          ">
                Enter only Alphabets!
              </mat-error>
            </mat-form-field>
            <mat-form-field class="col-3">
              <mat-label>Group #</mat-label>
              <input autocomplete="new-txtGroup" formControlName="txtGroup" maxlength="40" matInput type="text">
              <mat-error *ngIf="
          verificationForm?.get('txtGroup')?.touched &&
          verificationForm?.get('txtGroup')?.errors?.pattern
          ">
                Enter Only Alphanumeric !
              </mat-error>
            </mat-form-field>
            <mat-form-field class="col-3">
              <mat-label>Effective Date <span class="asterisk">*</span></mat-label>
              <input autocomplete="new-txtEffectiveDate" formControlName="txtEffectiveDate" matInput maxlength="40" placeholder="MM/DD/YYYY"
                [matDatepicker]="txtEffectiveDate">
              <mat-datepicker-toggle matSuffix [for]="txtEffectiveDate">
              </mat-datepicker-toggle>
              <mat-datepicker #txtEffectiveDate></mat-datepicker>
              <mat-error *ngIf=" verificationForm?.get('txtEffectiveDate')?.touched &&
                     verificationForm?.get('txtEffectiveDate')?.errors">
                Enter Valid Date!
              </mat-error>
              <mat-error *ngIf="
          verificationForm?.get('txtEffectiveDate')?.touched &&
          verificationForm?.get('txtEffectiveDate')?.errors?.required
          ">
                Effective Date is a required field!
              </mat-error>
            </mat-form-field>
            <mat-form-field class="col-3">
              <mat-label>Type<span class="asterisk">*</span></mat-label>
              <!-- <input formControlName="txtType" matInput type="text"> -->
              <mat-select maxlength="40" formControlName="txtType" [(value)]="type">
                <!-- <mat-option *ngFor="let insurance of drpPlan" [value]="insurance.typeOfPlanName">
                  {{ insurance.typeOfPlanName }}
                </mat-option> -->
                <mat-option *ngFor="let payor of drpYearType" [value]="payor.shortCodeId">
                  {{ payor.yearType }}
                </mat-option>
              </mat-select>
              <button mat-button (click)="clearType()" (click)="type=undefined;$event.stopPropagation()" *ngIf="type"
                matSuffix mat-icon-button >
                <mat-icon>close</mat-icon>
              </button>
              <mat-error *ngIf="
          verificationForm?.get('txtType')?.touched &&
          verificationForm?.get('txtType')?.errors?.required
          ">
                Type is a required field!
              </mat-error>
            </mat-form-field>
            <mat-form-field class="col-3">
              <mat-label>Fiscal Year Start</mat-label>
              <input autocomplete="new-txtFascalYearStart" formControlName="txtFascalYearStart" matInput maxlength="40" placeholder="MM/DD/YYYY"
                [matDatepicker]="txtFascalYearStart">
              <mat-datepicker-toggle matSuffix [for]="txtFascalYearStart">
              </mat-datepicker-toggle>
              <mat-datepicker #txtFascalYearStart></mat-datepicker>
              <mat-error *ngIf=" verificationForm?.get('txtFascalYearStart')?.touched &&
                     verificationForm?.get('txtFascalYearStart')?.errors">
                Enter Valid Date!
              </mat-error>
            </mat-form-field>
            <mat-form-field class="col-3">
              <mat-label>End</mat-label>
              <input autocomplete="new-txtEnd" formControlName="txtEnd" maxlength="40" matInput placeholder="MM/DD/YYYY" [matDatepicker]="txtEnd">
              <mat-datepicker-toggle matSuffix [for]="txtEnd">
              </mat-datepicker-toggle>
              <mat-datepicker #txtEnd></mat-datepicker>
              <mat-error *ngIf=" verificationForm?.get('txtEnd')?.touched &&
                     verificationForm?.get('txtEnd')?.errors">
                Enter Valid Date!
              </mat-error>
            </mat-form-field>
            <div class="col-md-3">
              <br>
              <mat-checkbox formControlName="chkActive">Active</mat-checkbox>
            </div>
            <!-- <div class="col-9">
          <br>
          <button mat-button class="buttonColor mb-2 mr-2">Submit For Verification</button>
          <button mat-button class="buttonColor mb-2 mr-2">Submit For Online Verification</button>
        </div> -->
          </div>
        </mat-card-content>
      </div>
    </div>
    <br>
    <div class="row">
      <div class="col-lg-12">
        <mat-card-content>
          <div class="row">
            <div class="col-md-3">
              <br>
              <mat-checkbox formControlName="chkMedicare">Medicare CMN on file?</mat-checkbox>
            </div>
            <div class="col-md-3">
              <br>
              <mat-checkbox formControlName="chkPatient">Is Patient Home Health Episode?</mat-checkbox>
            </div>
            <div class="col-md-6">
              <br>
              <mat-checkbox formControlName="chkFamilyDeductible">Does Family Deductible cover Individual Deductible?
              </mat-checkbox>
            </div>
            <mat-form-field class="col-3">
              <mat-label>CMN Code</mat-label>
              <input autocomplete="new-txtCmnCode" matInput maxlength="40" formControlName="txtCmnCode" type="text">
              <mat-error *ngIf="
          verificationForm?.get('txtCmnCode')?.touched &&
          verificationForm?.get('txtCmnCode')?.errors?.sAlphabets
          ">
                Enter only Alphabets!
              </mat-error>
            </mat-form-field>
            <mat-form-field class="col-3">
              <mat-label> Type Of Plan<span class="asterisk">*</span></mat-label>
              <mat-select maxlength="40" formControlName="drpTypeOfPlan" [(value)]="typeOfPlan">
                <!-- <mat-option *ngFor="let plan of drpPlan" [value]="plan.typeOfPlanId">
              {{plan.typeOfPlanName}}
            </mat-option> -->
                <mat-option *ngFor="let payor of drpPlanType" [value]="payor.planType">
                  {{ payor.planType }}
                </mat-option>
              </mat-select>
              <button mat-button (click)="clearTypeOfPlan()" (click)="typeOfPlan=undefined;$event.stopPropagation()"
                *ngIf="typeOfPlan" matSuffix mat-icon-button >
                <mat-icon>close</mat-icon>
              </button>
              <mat-error *ngIf="
          verificationForm?.get('drpTypeOfPlan')?.touched &&
          verificationForm?.get('drpTypeOfPlan')?.errors?.required
          ">
                Type is a required field!
              </mat-error>
            </mat-form-field>
            <mat-form-field class="col-3">
              <mat-label>DEO</mat-label>
              <input autocomplete="new-txtDeo" matInput maxlength="40" formControlName="txtDeo" type="text">
              <mat-error *ngIf="
          verificationForm?.get('txtDeo')?.touched &&
          verificationForm?.get('txtDeo')?.errors?.number
          ">
                Enter only Numbers!
              </mat-error>
            </mat-form-field>
            <mat-form-field class="col-3">
              <mat-label> Co Insurance </mat-label>
              <input autocomplete="new-txtCoInsurance" matInput maxlength="40" formControlName="txtCoInsurance" type="text">

            </mat-form-field>
          </div>
        </mat-card-content>
      </div>
    </div>
    <br>
    <div class="row">
      <div class="col-lg-12">
        <div class="row">
          <div class="col-md-6">
            <h4 class="customThemeClass"><b>Insurance Benefit Info</b></h4>
          </div>
          <div class="col-md-6">
          </div>
        </div>
        <mat-card-content>
          <div class="row">
            <div class="col-md-3">
            </div>
            <div class="col-md-3">
              <br>
              <mat-checkbox formControlName="chkIndividual">Individual</mat-checkbox>
            </div>
            <div class="col-md-3">
              <br>
              <mat-checkbox formControlName="chkFamily">Family</mat-checkbox>
            </div>
            <div class="col-md-3">
              <br>
              <mat-checkbox formControlName="chkOutOfNetwork">OutofNetwork</mat-checkbox>
            </div>

            <div class="col-3">
              <br>
              <p>What is Plan Deductible?</p>
            </div>
            <mat-form-field class="col-3">
              <mat-label></mat-label>
              <input autocomplete="new-txtDeductibleIndividual" matInput maxlength="40" formControlName="txtDeductibleIndividual" type="text">
              <!-- <mat-error *ngIf="
          verificationForm?.get('txtDeductibleIndividual')?.touched &&
          verificationForm?.get('txtDeductibleIndividual')?.errors?.sAlphabets
          ">
            Enter only Alphabets!
          </mat-error> -->
            </mat-form-field>
            <mat-form-field class="col-3">
              <mat-label></mat-label>
              <input autocomplete="new-txtDeductibleOutOfNetwork" matInput maxlength="40" formControlName="txtDeductibleOutOfNetwork" type="text">
              <!-- <mat-error *ngIf="
          verificationForm?.get('txtDeductibleOutOfNetwork')?.touched &&
          verificationForm?.get('txtDeductibleOutOfNetwork')?.errors?.sAlphabets
          ">
            Enter only Alphabets!
          </mat-error> -->
            </mat-form-field>
            <mat-form-field class="col-3">
              <mat-label></mat-label>
              <input autocomplete="new-txtDeductibleFamily" matInput maxlength="40" formControlName="txtDeductibleFamily" type="text">
              <!-- <mat-error *ngIf="
          verificationForm?.get('txtDeductibleFamily')?.touched &&
          verificationForm?.get('txtDeductibleFamily')?.errors?.sAlphabets
          ">
            Enter only Alphabets!
          </mat-error> -->
            </mat-form-field>
            <div class="col-3">
              <br>
              <p>How much of Deductible has been met?</p>
            </div>
            <mat-form-field class="col-3">
              <mat-label></mat-label>
              <input autocomplete="new-txtDeductibleMetIndividual" matInput maxlength="40" formControlName="txtDeductibleMetIndividual" type="text">
              <!-- <mat-error *ngIf="
          verificationForm?.get('txtDeductibleMetIndividual')?.touched &&
          verificationForm?.get('txtDeductibleMetIndividual')?.errors?.sAlphabets
          ">
            Enter only Alphabets!
          </mat-error> -->
            </mat-form-field>
            <mat-form-field class="col-3">
              <mat-label></mat-label>
              <input autocomplete="new-txtDeductibleMetOutOfNetwork" matInput maxlength="40" formControlName="txtDeductibleMetOutOfNetwork" type="text">
              <!-- <mat-error *ngIf="
          verificationForm?.get('txtDeductibleMetOutOfNetwork')?.touched &&
          verificationForm?.get('txtDeductibleMetOutOfNetwork')?.errors?.sAlphabets
          ">
            Enter only Alphabets!
          </mat-error> -->
            </mat-form-field>
            <mat-form-field class="col-3">
              <mat-label></mat-label>
              <input autocomplete="new-txtDeductibleMetFamily" matInput maxlength="40" formControlName="txtDeductibleMetFamily" type="text">
              <!-- <mat-error *ngIf="
          verificationForm?.get('txtDeductibleMetFamily')?.touched &&
          verificationForm?.get('txtDeductibleMetFamily')?.errors?.sAlphabets
          ">
            Enter only Alphabets!
          </mat-error> -->
            </mat-form-field>
            <div class="col-3">
              <br>
              <p>Payable at what percent of the allowed amount?</p>
            </div>
            <mat-form-field class="col-3">
              <mat-label></mat-label>
              <mat-select maxlength="40" formControlName="drpPayableIndividual" (ngModelChange)="onSelection($event)" [(value)]="payableIndividual">
                <mat-option *ngFor="let payable of drpPayable" [value]="payable.payablePercentType">
                  {{payable.payablePercentType}}
                </mat-option>
              </mat-select>
              <button mat-button (click)="clearPayableIndividual()"
                (click)="payableIndividual=undefined;$event.stopPropagation()" *ngIf="payableIndividual" matSuffix
                mat-icon-button >
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field>
            <mat-form-field class="col-3">
              <mat-label></mat-label>
              <mat-select maxlength="40" formControlName="drpPayableOutOfNetwork" [(value)]="payableOutOfNetwork"
                (click)="clearPayableOutOfNetwork()">
                <mat-option *ngFor="let payable of drpPayable" [value]="payable.payablePercentType">
                  {{payable.payablePercentType}}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field class="col-3">
              <mat-label></mat-label>
              <mat-select maxlength="40" formControlName="drpPayableFamily">
                <mat-option *ngFor="let payable of drpPayable" [value]="payable.payablePercentType">
                  {{payable.payablePercentType}}
                </mat-option>
              </mat-select>
            </mat-form-field>

            <div class="col-3">
              <br>
              <p>Out-of-pocket expense?</p>
            </div>
            <mat-form-field class="col-3">
              <mat-label></mat-label>
              <input autocomplete="new-txtExpenseIndividual" maxlength="40" formControlName="txtExpenseIndividual" matInput type="text">
              <!-- <mat-error *ngIf="
          verificationForm?.get('txtExpenseIndividual')?.touched &&
          verificationForm?.get('txtExpenseIndividual')?.errors?.sAlphabets
          ">
            Enter only Alphabets!
          </mat-error> -->
            </mat-form-field>
            <mat-form-field class="col-3">
              <mat-label></mat-label>
              <input autocomplete="new-txtExpenseOutOfNetwork" maxlength="40" formControlName="txtExpenseOutOfNetwork" matInput type="text">
              <!-- <mat-error *ngIf="
          verificationForm?.get('txtExpenseOutOfNetwork')?.touched &&
          verificationForm?.get('txtExpenseOutOfNetwork')?.errors?.sAlphabets
          ">
            Enter only Alphabets!
          </mat-error> -->
            </mat-form-field>
            <mat-form-field class="col-3">
              <mat-label></mat-label>
              <input autocomplete="new-txtExpenseFamily" maxlength="40" formControlName="txtExpenseFamily" matInput type="text">
              <!-- <mat-error *ngIf="
          verificationForm?.get('txtExpenseFamily')?.touched &&
          verificationForm?.get('txtExpenseFamily')?.errors?.sAlphabets
          ">
            Enter only Alphabets!
          </mat-error> -->
            </mat-form-field>
            <div class="col-3">
              <br>
              <p>How much Out-of-pocket expense has been met?</p>
            </div>
            <mat-form-field class="col-3">
              <mat-label></mat-label>
              <input autocomplete="new-txtExpenseMetIndividual" maxlength="40" formControlName="txtExpenseMetIndividual" matInput type="text">
              <!-- <mat-error *ngIf="
          verificationForm?.get('txtExpenseMetIndividual')?.touched &&
          verificationForm?.get('txtExpenseMetIndividual')?.errors?.sAlphabets
          ">
            Enter only Alphabets!
          </mat-error> -->
            </mat-form-field>
            <mat-form-field class="col-3">
              <mat-label></mat-label>
              <input autocomplete="new-txtExpenseMetOutOfNetwork" maxlength="40" formControlName="txtExpenseMetOutOfNetwork" matInput type="text">
              <!-- <mat-error *ngIf="
          verificationForm?.get('txtExpenseMetOutOfNetwork')?.touched &&
          verificationForm?.get('txtExpenseMetOutOfNetwork')?.errors?.sAlphabets
          ">
            Enter only Alphabets!
          </mat-error> -->
            </mat-form-field>
            <mat-form-field class="col-3">
              <mat-label></mat-label>
              <input autocomplete="new-txtExpenseMetFamily" maxlength="40" formControlName="txtExpenseMetFamily" matInput type="text">
              <!-- <mat-error *ngIf="
          verificationForm?.get('txtExpenseMetFamily')?.touched &&
          verificationForm?.get('txtExpenseMetFamily')?.errors?.sAlphabets
          ">
            Enter only Alphabets!
          </mat-error> -->
            </mat-form-field>
            <div class="col-3">
              <br>
              <p>Does Out-of-pocket expense include the deductible?</p>
            </div>
            <div class="col-md-3">
              <br>
              <mat-checkbox formControlName="chkExpenseIndividual">Individual</mat-checkbox>
            </div>
            <div class="col-md-3">
              <br>
              <mat-checkbox formControlName="chkExpenseFamily">Family</mat-checkbox>
            </div>
            <div class="col-md-3">
              <br>
              <mat-checkbox formControlName="chkExpenseOutOfNetwork">Out Of Network</mat-checkbox>
            </div>
            <!-- Newly Added -->
            <div class="col-md-3">
              <br>
              <mat-checkbox formControlName="chkIsBlueCardEligible"> Is BlueCard Eligible </mat-checkbox>
            </div>
            <div class="col-md-3">
              <br>
              <mat-checkbox formControlName="chkIsDirectBill">Is DirectBill </mat-checkbox>
            </div>
            <div class="col-md-3">
              <br>
              <mat-checkbox formControlName="chkIsPreAuthNeeded">Is PreAuth Needed </mat-checkbox>
            </div>
            <div class="col-md-3">
              <br>
              <mat-checkbox formControlName="chkIsClaimSubmit">Is Claim Submit </mat-checkbox>
            </div>
            <div class="col-md-3">
              <br>
              <mat-checkbox formControlName="chkIsReferenceNumberAvailable">Is ReferenceNumber Available</mat-checkbox>
            </div>
            <div class="col-md-3">
              <br>
              <mat-checkbox formControlName="txtReferenceNumber">Is this reference number </mat-checkbox>
            </div>
            <mat-form-field class="col-3">
              <mat-label>Reference Number</mat-label>
              <input autocomplete="new-txtReferenceNumber" formControlName="txtReferenceNumber" maxlength="40" matInput type="text">
            </mat-form-field>
            <mat-form-field class="col-3">
              <mat-label>Resposponsible Payment Percentage</mat-label>
              <input autocomplete="new-txtPatientResposponsiblePaymentPercentage" formControlName="txtPatientResposponsiblePaymentPercentage" maxlength="40" matInput type="text">
            </mat-form-field>

            <!--  -->

            <div class="col-3">
              <br>
              <mat-checkbox formControlName="chkPrecertRequired">Is Precert Required?</mat-checkbox>
            </div>
            <mat-form-field class="col-md-3">
              <mat-label>If yes, Phone # to call</mat-label>
              <input autocomplete="new-txtPhoneCall" prefix="+1-" mask="(000)-000-0000" maxlength="40" formControlName="txtPhoneCall" matInput
                type="text">
              <mat-error *ngIf="
          verificationForm?.get('txtPhoneCall')?.touched &&
          verificationForm?.get('txtPhoneCall')?.errors
          ">
                Enter only Numbers!
              </mat-error>
            </mat-form-field>
            <mat-form-field class="col-md-3">
              <mat-label>PCP</mat-label>
              <input autocomplete="new-txtPcp" formControlName="txtPcp" matInput type="text">
              <mat-error *ngIf="
          verificationForm?.get('txtPcp')?.touched &&
          verificationForm?.get('txtPcp')?.errors?.sAlphabets
          ">
                Enter only Alphabets!
              </mat-error>
            </mat-form-field>
            <div class="col-3">
              <br>
              <mat-checkbox formControlName="chkReferralRequired">Is a referral required?</mat-checkbox>
            </div>
            <div class="col-3">
              <br>
              <mat-checkbox formControlName="chkPredetermination">Predetermination</mat-checkbox>
            </div>
            <mat-form-field class="col-md-3">
              <mat-label>Pre.Notes</mat-label>
              <input autocomplete="new-txtPreNotes" formControlName="txtPreNotes" matInput type="text" maxlength="1000">
              <!-- <mat-error *ngIf="
          verificationForm?.get('txtPreNotes')?.touched &&
          verificationForm?.get('txtPreNotes')?.errors?.sAlphabets
          ">
                Enter only Alphabets!
              </mat-error> -->
            </mat-form-field>
            <mat-form-field class="col-3">
              <mat-label>Lifetime Max Met</mat-label>
              <input autocomplete="new-txtLifetimeMaxMet" formControlName="txtLifetimeMaxMet" matInput type="text">
              <!-- <mat-error *ngIf="
          verificationForm?.get('txtLifetimeMaxMet')?.touched &&
          verificationForm?.get('txtLifetimeMaxMet')?.errors?.sAlphabets
          ">
            Enter only Alphabets!
          </mat-error> -->
            </mat-form-field>
            <mat-form-field class="col-3">
              <mat-label>Lifetime Max</mat-label>
              <input autocomplete="new-txtLifetimeMax" formControlName="txtLifetimeMax" matInput type="text">
              <!-- <mat-error *ngIf="
          verificationForm?.get('txtLifetimeMax')?.touched &&
          verificationForm?.get('txtLifetimeMax')?.errors?.sAlphabets
          ">
            Enter only Alphabets!
          </mat-error> -->
            </mat-form-field>

            <div class="col-md-3">
              <br>
              <mat-checkbox formControlName="chkDmePartsCovered"> DME Parts Covered</mat-checkbox>
            </div>
            <div class="col-md-3">
              <br>
              <mat-checkbox formControlName="chkDmeReplacementUnit"> DME Replacement Unit</mat-checkbox>
            </div>
            <mat-form-field class="col-3">
              <mat-label>DME Limit Met</mat-label>
              <input autocomplete="new-txtDmeLimitMet" formControlName="txtDmeLimitMet" maxlength="40" matInput type="text">
              <!-- <mat-error *ngIf="
          verificationForm?.get('txtDmeLimitMet')?.touched &&
          verificationForm?.get('txtDmeLimitMet')?.errors?.sAlphabets
          ">
            Enter only Alphabets!
          </mat-error> -->
            </mat-form-field>
            <mat-form-field class="col-3">
              <mat-label>DME Annual Limit</mat-label>
              <input autocomplete="new-txtDmeAnnualLimit" formControlName="txtDmeAnnualLimit" maxlength="40" matInput type="text">
              <!-- <mat-error *ngIf="
          verificationForm?.get('txtDmeAnnualLimit')?.touched &&
          verificationForm?.get('txtDmeAnnualLimit')?.errors?.sAlphabets
          ">
            Enter only Alphabets!
          </mat-error> -->
            </mat-form-field>


            <mat-form-field class="col-3">
              <mat-label>Requested By</mat-label>
              <input autocomplete="new-txtRequestedBy" formControlName="txtRequestedBy" maxlength="40" matInput type="text">
              <mat-error *ngIf="
          verificationForm?.get('txtRequestedBy')?.touched &&
          verificationForm?.get('txtRequestedBy')?.errors?.sAlphabets
          ">
                Enter only Alphabets!
              </mat-error>
            </mat-form-field>
            <mat-form-field class="col-3">
              <mat-label>Primary Payor</mat-label>
              <input autocomplete="new-txtPrimaryPayor" formControlName="txtPrimaryPayor" maxlength="40" matInput type="text">
              <mat-error *ngIf="
          verificationForm?.get('txtPrimaryPayor')?.touched &&
          verificationForm?.get('txtPrimaryPayor')?.errors?.sAlphabets
          ">
                Enter only Alphabets!
              </mat-error>
            </mat-form-field>
            <mat-form-field class="col-3">
              <mat-label>Call tracking number / Reference #</mat-label>
              <input autocomplete="new-txtCallTrackingNumber" formControlName="txtCallTrackingNumber" maxlength="40" matInput type="text">
              <!-- <mat-error *ngIf="
          verificationForm?.get('txtCallTrackingNumber')?.touched &&
          verificationForm?.get('txtCallTrackingNumber')?.errors?.sAlphabets
          ">
            Enter only Alphabets!
          </mat-error> -->
            </mat-form-field>
            <mat-form-field class="col-3">
              <mat-label>Contact (phone/Web)</mat-label>
              <input autocomplete="new-txtContact" formControlName="txtContact" matInput maxlength="15" type="text">
              <mat-error *ngIf="
          verificationForm?.get('txtContact')?.touched &&
          verificationForm?.get('txtContact')?.errors?.number
          ">
                Enter only Numbers!
              </mat-error>
            </mat-form-field>

            <!-- <mat-form-field class="col-3">
                      <mat-label>Effective Date</mat-label>
                      <input matInput type="text">
                  </mat-form-field>
                  <mat-form-field class="col-3">
                      <mat-label>Type of Policy</mat-label>
                      <input matInput type="text">
                  </mat-form-field>
                  <mat-form-field class="col-3">
                      <mat-label>Policy #</mat-label>
                      <input matInput type="text">
                  </mat-form-field>
                  <mat-form-field class="col-3">
                      <mat-label>Group #</mat-label>
                      <input matInput type="text">
                  </mat-form-field> -->
            <mat-form-field class="col-3">
              <mat-label>Policy Holder Name</mat-label>
              <input autocomplete="new-txtPolicyHolderName" formControlName="txtPolicyHolderName" matInput maxlength="40" type="text">
              <mat-error *ngIf="
                      verificationForm?.get('txtPolicyHolderName')?.touched &&
                      verificationForm?.get('txtPolicyHolderName')?.errors?.sAlphabets
                      ">
                Enter only Alphabets!
              </mat-error>
            </mat-form-field>
            <mat-form-field class="col-3">
              <mat-label>Policy Holder DOB</mat-label>
              <input autocomplete="new-txtPolicyHolderDob" formControlName="txtPolicyHolderDob" matInput maxlength="40" placeholder="MM/DD/YYYY"
                [matDatepicker]="txtPolicyHolderDob">
              <mat-datepicker-toggle matSuffix [for]="txtPolicyHolderDob">
              </mat-datepicker-toggle>
              <mat-datepicker #txtPolicyHolderDob></mat-datepicker>
              <mat-error *ngIf=" verificationForm?.get('txtPolicyHolderDob')?.touched &&
              verificationForm?.get('txtPolicyHolderDob')?.errors">
                Enter Valid Date!
              </mat-error>
            </mat-form-field>
            <mat-form-field class="col-3">
              <mat-label>Claim Address</mat-label>
              <textarea autocomplete="new-txtClaimAddress" rows="3" maxlength="60" formControlName="txtClaimAddress" matInput></textarea>
              <!-- <mat-error *ngIf="
          verificationForm?.get('txtClaimAddress')?.touched &&
          verificationForm?.get('txtClaimAddress')?.errors?.sAlphabets
          ">
            Enter only Alphabets!
          </mat-error> -->
            </mat-form-field>
            <div class="col-3">
              <br>
              <mat-checkbox formControlName="chkNetwork">Network (YES / NO)</mat-checkbox>
            </div>
            <mat-form-field class="col-3">
              <mat-label>PLan COB</mat-label>
              <input autocomplete="new-txtPlanCob" formControlName="txtPlanCob" matInput maxlength="40" placeholder="MM/DD/YYYY"
                [matDatepicker]="txtPlanCob">
              <mat-datepicker-toggle matSuffix [for]="txtPlanCob">
              </mat-datepicker-toggle>
              <mat-datepicker #txtPlanCob></mat-datepicker>
              <mat-error *ngIf=" verificationForm?.get('txtPlanCob')?.touched &&
              verificationForm?.get('txtPlanCob')?.errors">
                Enter Valid Date!
              </mat-error>
            </mat-form-field>
            <mat-form-field class="col-3">
              <mat-label>Ded (Total) </mat-label>
              <input autocomplete="new-txtDedTotal" formControlName="txtDedTotal" maxlength="40" matInput type="text">
              <!-- <mat-error *ngIf="
          verificationForm?.get('txtDedTotal')?.touched &&
          verificationForm?.get('txtDedTotal')?.errors?.sAlphabets
          ">
            Enter only Alphabets!
          </mat-error> -->
            </mat-form-field>
            <mat-form-field class="col-3">
              <mat-label>Ded (Met)</mat-label>
              <input autocomplete="new-txtDedMet" formControlName="txtDedMet" maxlength="40" matInput type="text">
              <!-- <mat-error *ngIf="
          verificationForm?.get('txtDedMet')?.touched &&
          verificationForm?.get('txtDedMet')?.errors?.sAlphabets
          ">
            Enter only Alphabets!
          </mat-error> -->
            </mat-form-field>
            <mat-form-field class="col-3">
              <mat-label>OOP (Total)</mat-label>
              <input autocomplete="new-txtOopTotal" formControlName="txtOopTotal" maxlength="40" matInput type="text">
              <!-- <mat-error *ngIf="
          verificationForm?.get('txtOopTotal')?.touched &&
          verificationForm?.get('txtOopTotal')?.errors?.sAlphabets
          ">
            Enter only Alphabets!
          </mat-error> -->
            </mat-form-field>
            <mat-form-field class="col-3">
              <mat-label>OOP (Met)</mat-label>
              <input autocomplete="new-txtOopMet" formControlName="txtOopMet" maxlength="40" matInput type="text">
              <!-- <mat-error *ngIf="
          verificationForm?.get('txtOopMet')?.touched &&
          verificationForm?.get('txtOopMet')?.errors?.sAlphabets
          ">
            Enter only Alphabets!
          </mat-error> -->
            </mat-form-field>
            <mat-form-field class="col-3">
              <mat-label>OOP (Include or exclude deductible)</mat-label>
              <input autocomplete="new-txtOopDeductible" formControlName="txtOopDeductible" maxlength="40" matInput type="text">
              <!-- <mat-error *ngIf="
          verificationForm?.get('txtOopDeductible')?.touched &&
          verificationForm?.get('txtOopDeductible')?.errors?.sAlphabets
          ">
            Enter only Alphabets!
          </mat-error> -->
            </mat-form-field>
            <mat-form-field class="col-3">
              <mat-label>DME Coverage</mat-label>
              <input autocomplete="new-txtDmeCoverage" formControlName="txtDmeCoverage" maxlength="40" matInput type="text">
              <!-- <mat-error *ngIf="
          verificationForm?.get('txtDmeCoverage')?.touched &&
          verificationForm?.get('txtDmeCoverage')?.errors?.sAlphabets
          ">
            Enter only Alphabets!
          </mat-error> -->
            </mat-form-field>
            <mat-form-field class="col-3">
              <mat-label>DME Max</mat-label>
              <input autocomplete="new-txtDmeMax" formControlName="txtDmeMax" maxlength="40" matInput type="text">
              <!-- <mat-error *ngIf="
              verificationForm?.get('txtDmeMax')?.touched &&
              verificationForm?.get('txtDmeMax')?.errors?.sAlphabets
              ">
            Enter only Alphabets!
          </mat-error> -->
            </mat-form-field>
            <mat-form-field class="col-3">
              <mat-label>HCPC(S)</mat-label>
              <input autocomplete="new-txtHcpcs" formControlName="txtHcpcs" maxlength="40" matInput type="text">
              <mat-error *ngIf="
              verificationForm?.get('txtHcpcs')?.touched &&
              verificationForm?.get('txtHcpcs')?.errors?.number
              ">
                Enter only Number!
              </mat-error>
            </mat-form-field>
            <mat-form-field class="col-3">
              <mat-label>Coverage criteria</mat-label>
              <input autocomplete="new-txtCoverageCriteria" formControlName="txtCoverageCriteria" maxlength="40" matInput type="text">
              <mat-error *ngIf="
              verificationForm?.get('txtCoverageCriteria')?.touched &&
              verificationForm?.get('txtCoverageCriteria')?.errors?.sAlphabets
              ">
                Enter only Alphabets!
              </mat-error>
            </mat-form-field>
            <mat-form-field class="col-3">
              <mat-label>Unit limits</mat-label>
              <input autocomplete="new-txtQtyLimits" formControlName="txtQtyLimits" maxlength="40" matInput type="text">
              <mat-error *ngIf="
              verificationForm?.get('txtQtyLimits')?.touched &&
              verificationForm?.get('txtQtyLimits')?.errors?.sAlphabets
              ">
                Enter only Alphabets!
              </mat-error>
            </mat-form-field>
            <mat-form-field class="col-3">
              <mat-label>Frequency</mat-label>
              <input autocomplete="new-txtFrequency" formControlName="txtFrequency" maxlength="40" matInput type="text">
              <mat-error *ngIf="
              verificationForm?.get('txtFrequency')?.touched &&
              verificationForm?.get('txtFrequency')?.errors?.sAlphabets
              ">
                Enter only Alphabets!
              </mat-error>
            </mat-form-field>
            <mat-form-field class="col-3">
              <mat-label>Rent (How many months)or Purchase?</mat-label>
              <input autocomplete="new-txtRent" formControlName="txtRent" maxlength="40" matInput type="text">
              <mat-error *ngIf="
              verificationForm?.get('txtRent')?.touched &&
              verificationForm?.get('txtRent')?.errors?.sAlphabets
              ">
                Enter only Alphabets!
              </mat-error>
            </mat-form-field>
            <mat-form-field class="col-6">
              <mat-label>PA required: Purchase or rental upfront or after what months?</mat-label>
              <input autocomplete="new-txtPaRequired" formControlName="txtPaRequired" maxlength="40" matInput type="text">
              <mat-error *ngIf="
              verificationForm?.get('txtPaRequired')?.touched &&
              verificationForm?.get('txtPaRequired')?.errors?.sAlphabets
              ">
                Enter only Alphabets!
              </mat-error>
            </mat-form-field>
            <mat-form-field class="col-3">
              <mat-label>Codes that require PA</mat-label>
              <input autocomplete="new-txtCodesRequirePa" formControlName="txtCodesRequirePa" maxlength="40" matInput type="text">
              <mat-error *ngIf="
              verificationForm?.get('txtCodesRequirePa')?.touched &&
              verificationForm?.get('txtCodesRequirePa')?.errors?.sAlphabets
              ">
                Enter only Alphabets!
              </mat-error>
            </mat-form-field>
            <mat-form-field class="col-3">
              <mat-label>Same or Similar Info</mat-label>
              <input autocomplete="new-txtSameOrSimilarInfo" formControlName="txtSameOrSimilarInfo" maxlength="40" matInput type="text">
              <mat-error *ngIf="
          verificationForm?.get('txtSameOrSimilarInfo')?.touched &&
          verificationForm?.get('txtSameOrSimilarInfo')?.errors?.sAlphabets
          ">
                Enter only Alphabets!
              </mat-error>
            </mat-form-field>
            <mat-form-field class="col-3">
              <mat-label>Spoke with</mat-label>
              <input autocomplete="new-txtSpokeWith" formControlName="txtSpokeWith" maxlength="40" matInput type="text">
              <mat-error *ngIf="
              verificationForm?.get('txtSpokeWith')?.touched &&
              verificationForm?.get('txtSpokeWith')?.errors?.sAlphabets
              ">
                Enter only Alphabets!
              </mat-error>
            </mat-form-field>
            <mat-form-field class="col-3">
              <mat-label>Prepared By</mat-label>
              <input autocomplete="new-drpPreparedBy" formControlName="drpPreparedBy" maxlength="40" matInput type="text">
              <mat-error *ngIf="
              verificationForm?.get('drpPreparedBy')?.touched &&
              verificationForm?.get('drpPreparedBy')?.errors?.sAlphabets
              ">
                Enter only Alphabets!
              </mat-error>
            </mat-form-field>
            <mat-form-field class="col-3">
              <mat-label>Date Verified</mat-label>
              <input autocomplete="new-txtDateVerified" formControlName="txtDateVerified" matInput maxlength="40" placeholder="MM/DD/YYYY"
                [matDatepicker]="txtDateVerified">
              <mat-datepicker-toggle matSuffix [for]="txtDateVerified">
              </mat-datepicker-toggle>
              <mat-datepicker #txtDateVerified></mat-datepicker>
              <mat-error *ngIf=" verificationForm?.get('txtDateVerified')?.touched &&
                     verificationForm?.get('txtDateVerified')?.errors">
                Enter Valid Date!
              </mat-error>
            </mat-form-field>
            <mat-form-field class="col-3">
              <mat-label>Verified By</mat-label>
              <input autocomplete="new-drpVerifiedBy" formControlName="drpVerifiedBy" maxlength="40" matInput type="text">
              <mat-error *ngIf="
              verificationForm?.get('drpVerifiedBy')?.touched &&
              verificationForm?.get('drpVerifiedBy')?.errors?.sAlphabets
              ">
                Enter only Alphabets!
              </mat-error>
            </mat-form-field>
            <mat-form-field class="col-3">
              <mat-label>Prepared On</mat-label>
              <input autocomplete="new-txtPreparedOn" formControlName="txtPreparedOn" matInput maxlength="40" placeholder="MM/DD/YYYY"
                [matDatepicker]="txtPreparedOn">
              <mat-datepicker-toggle matSuffix [for]="txtPreparedOn">
              </mat-datepicker-toggle>
              <mat-datepicker #txtPreparedOn></mat-datepicker>
              <mat-error *ngIf=" verificationForm?.get('txtPreparedOn')?.touched &&
                     verificationForm?.get('txtPreparedOn')?.errors">
                Enter Valid Date!
              </mat-error>
            </mat-form-field>
            <mat-form-field class="col-3">
              <mat-label>Comments To Tech</mat-label>
              <input autocomplete="new-txtcommentsToTech" formControlName="txtcommentsToTech" maxlength="1000" matInput type="text">
              <!-- <mat-error *ngIf="
              verificationForm?.get('txtcommentsToTech')?.touched &&
              verificationForm?.get('txtcommentsToTech')?.errors?.sAlphabets
              ">
                Enter only Alphabets!
              </mat-error> -->
            </mat-form-field>
            <mat-form-field class="col-3">
              <mat-label>Reason</mat-label>
              <input autocomplete="new-txtReason" formControlName="txtReason" maxlength="40" matInput type="text">

            </mat-form-field>
            <mat-form-field class="col-3">
              <mat-label>Verification Method</mat-label>
              <input autocomplete="new-txtVerfnAMethod" formControlName="txtVerfnAMethod" maxlength="40" matInput type="text">
              <mat-error *ngIf="
                  verificationForm?.get('txtVerfnAMethod')?.touched &&
                  verificationForm?.get('txtVerfnAMethod')?.errors?.sAlphabets
                  ">
                Enter only Alphabets!
              </mat-error>
            </mat-form-field>
            <div class="col-9">
              <mat-radio-group formControlName="rbtnVerificationStatus">
                <br>
                <mat-radio-button class="mb-2 mr-2" value="Done">Done</mat-radio-button> &nbsp;&nbsp;
                <mat-radio-button class="mb-2 mr-2" value="Pending">Pending</mat-radio-button> &nbsp;&nbsp;
                <mat-radio-button class="mb-2 mr-2" value="UnableToVerify">Unable to Verify</mat-radio-button>
              </mat-radio-group>
            </div>

            <!-- <div class="col-9">
                  <input type="radio" id="exampleRadios1" value="1" formControlName="rbtnDone"> Done
                  <input type="radio" id="exampleRadios2" value="2" formControlName="rbtnPending"> Pending
                  </div> -->
            <!-- <div class="custom-control custom-radio mb-3" formControlName="rbtnDone">
                      <input type="radio" id="customRadio1" name="customRadio" class="custom-control-input">
                      <label class="custom-control-label" for="customRadio1">Done</label>
                  </div>
                  <div class="custom-control custom-radio custom-control">
                      <input type="radio" id="customRadio2" name="customRadio" class="custom-control-input">
                      <label class="custom-control-label" for="customRadio2">Pending</label>
                  </div>
                  <div class="custom-control custom-radio custom-control">
                      <input type="radio" id="customRadio3" name="customRadio" class="custom-control-input">
                      <label class="custom-control-label" for="customRadio3">Unable to Verify</label>
                  </div> -->
          </div>
        </mat-card-content>

      </div>
    </div>
  </form>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button *ngIf="orderStatus"  type="button" mat-button class="buttonColor"
    [disabled]=" saveButtonHide ||!(!verificationForm.invalid && (verificationForm.dirty ))"
    (click)=" getOrderDetailsForSave()">Save
    <mat-icon *ngIf='isShowSpinner'>
      <mat-spinner class="spinner-border spinner-border-sm" diameter="20"> </mat-spinner>
    </mat-icon>
  </button>
  <button mat-button class="resetclr" (click)="clearData()">Reset</button>
  <button class="buttonCancelColor" mat-button mat-dialog-close>Close</button>
</mat-dialog-actions>
