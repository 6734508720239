<div class="container-fluid">
  <!-- Headers -->
  <div class="row">
    <div class="col-lg-12">
      <div class="d-flex align-items-center justify-content-between">
        <h3 class="font-weight-bolder mb-0">
          Navigation / Practice Management
        </h3>

        <div class="page-title-box page-title-right">
          <ol class="breadcrumbs m-0 align-items-center"></ol>
        </div>
      </div>
    </div>
  </div>

  <!-- Page Content -->
  <div class="row">
    <div class="col-lg-4">
      <div class="row">
        <div class="col-lg-12">
          <div class="card elevated-div">
            <div class="card-header">
              <h3 class="header-text mb-0">Practice Management</h3>
            </div>
            <div class="card-body">
              <form [formGroup]="practiceForm">
                <!-- Practice Name -->
                <mat-form-field class="col-6">
                  <mat-label>
                    Practice Name <span class="asterisk">*</span></mat-label
                  >
                  <input
                    matInput
                    maxlength="40"
                    formControlName="txtPracticeName"
                    type="text"
                  />
                  <mat-error
                    class="spanAlignment"
                    *ngIf="
                      practiceForm?.get('txtPracticeName')?.touched &&
                      practiceForm?.get('txtPracticeName')?.errors?.required
                    "
                  >
                    Practice Name is a required field!
                  </mat-error>
                </mat-form-field>

                <!--Practice Address -->
                <mat-form-field class="col-6">
                  <mat-label>
                    Practice Address <span class="asterisk">*</span></mat-label
                  >
                  <input
                    matInput
                    maxlength="250"
                    formControlName="txtPracticeAddress"
                    type="text"
                  />
                  <mat-error
                    class="spanAlignment"
                    *ngIf="
                      practiceForm?.get('txtPracticeAddress')?.touched &&
                      practiceForm?.get('txtPracticeAddress')?.errors?.required
                    "
                  >
                    Practice Address is a required field!
                  </mat-error>
                </mat-form-field>

                <!--Country -->
                <mat-form-field class="col-3 mt-1">
                  <mat-label>Country</mat-label>
                  <mat-select formControlName="txtCountry" #singleSelect>
                    <mat-option>
                      <ngx-mat-select-search
                        placeholderLabel="Search"
                        noEntriesFoundLabel="No Matches found"
                        formControlName="txtCountryFilter"
                      ></ngx-mat-select-search>
                    </mat-option>
                  </mat-select>
                </mat-form-field>
                <!-- State -->
                <mat-form-field class="col-3 mt-1">
                  <mat-label>State</mat-label>
                  <mat-select formControlName="txtState" #singleSelect>
                    <mat-option>
                      <ngx-mat-select-search
                        placeholderLabel="Search"
                        noEntriesFoundLabel="No Matches found"
                        formControlName="txtStateFilter"
                      ></ngx-mat-select-search>
                    </mat-option>
                  </mat-select>
                </mat-form-field>
                <!-- City -->
                <mat-form-field class="col-3 mt-1">
                  <mat-label>City</mat-label>
                  <mat-select formControlName="txtCity" #singleSelect>
                    <mat-option>
                      <ngx-mat-select-search
                        placeholderLabel="Search"
                        noEntriesFoundLabel="No Matches found"
                        formControlName="txtCityFilter"
                      ></ngx-mat-select-search>
                    </mat-option>
                  </mat-select>
                </mat-form-field>
                <!-- Zip Code -->
                <mat-form-field class="col-3 mt-1">
                  <mat-label> Zip Code </mat-label>
                  <input
                    matInput
                    maxlength="10"
                    formControlName="txtPostalCode"
                    pattern="[0-9]*"
                    type="text"
                  />
                  <mat-error
                    *ngIf="
                      practiceForm?.get('txtPostalCode')?.touched &&
                      practiceForm?.get('txtPostalCode')?.errors?.pattern
                    "
                  >
                    Enter only Numbers!
                  </mat-error>
                </mat-form-field>
                <!-- Phone No -->
                <mat-form-field class="col-3 mt-1">
                  <mat-label> Phone No </mat-label>
                  <input
                    matInput
                    maxlength="40"
                    formControlName="txtPhoneNo"
                    type="text"
                    mask="(000)-000-0000"
                    prefix="{{ phoneCode }}-"
                  />
                </mat-form-field>
                <!-- Fax No -->
                <mat-form-field class="col-3 mt-1">
                  <mat-label> Fax</mat-label>
                  <input
                    formControlName="txtFaxNo"
                    matInput
                    maxlength="40"
                    prefix="{{ phoneCode }}-"
                    mask="(000)-000-0000"
                    type="text"
                    autocomplete="new-txtFaxNo"
                  />
                </mat-form-field>
                <!-- Website -->
                <mat-form-field class="col-6 mt-1">
                  <mat-label>
                    Practice Website <span class="asterisk">*</span></mat-label
                  >
                  <input
                    matInput
                    maxlength="50"
                    formControlName="txtPracticeWebsite"
                    type="text"
                  />
                  <mat-error
                    class="spanAlignment"
                    *ngIf="
                      practiceForm?.get('txtPracticeWebsite')?.touched &&
                      practiceForm?.get('txtPracticeWebsite')?.errors?.required
                    "
                  >
                    Practice Website is a required field!
                  </mat-error>
                </mat-form-field>

                <!-- Practice Manager -->
                <mat-form-field class="col-3 mt-1">
                  <mat-label>
                    Practice Manager <span class="asterisk">*</span></mat-label
                  >
                  <input
                    matInput
                    maxlength="50"
                    formControlName="txtPracticeManager"
                    type="text"
                  />
                  <mat-error
                    class="spanAlignment"
                    *ngIf="
                      practiceForm?.get('txtPracticeManager')?.touched &&
                      practiceForm?.get('txtPracticeManager')?.errors?.required
                    "
                  >
                    Practice Manager is a required field!
                  </mat-error>
                </mat-form-field>

                <!-- Practice Email -->
                <mat-form-field class="col-5 mt-1">
                  <mat-label>
                    Practice Email <span class="asterisk">*</span></mat-label
                  >
                  <input
                    matInput
                    pattern="[a-zA-Z0-9._!#$%&'()*+,-./:;<=>?@[\]^_`{|}~]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$"
                    [textMask]="{ mask: emailMask }"
                    maxlength="40"
                    type="text"
                    formControlName="txtPracticeEmail"
                  />
                  <mat-error
                    class="spanAlignment"
                    *ngIf="
                      practiceForm?.get('txtPracticeEmail')?.touched &&
                      practiceForm?.get('txtPracticeEmail')?.errors?.pattern
                    "
                  >
                    Enter a valid email!
                  </mat-error>
                </mat-form-field>

                <!-- Account Manager  -->
                <mat-form-field class="col-4 mt-1">
                  <mat-label>
                    Account Manager <span class="asterisk">*</span></mat-label
                  >
                  <mat-select formControlName="drpAccountManager" #singleSelect>
                    <mat-option>
                      <ngx-mat-select-search
                        placeholderLabel="Search"
                        noEntriesFoundLabel="No Matches found"
                        formControlName="txtAccountManager"
                      ></ngx-mat-select-search>
                    </mat-option>
                  </mat-select>
                </mat-form-field>

                <!-- Medicare IDTF-->
                <mat-checkbox
                  class="col-4 mt-1"
                  formControlName="chkMedicareIDTF"
                >
                  <p class="text-wrap mb-0">Medicare IDTF</p>
                </mat-checkbox>

                <!--Outsource Referrals -->
                <mat-checkbox
                  class="col-8"
                  formControlName="chkOutsourceReferrals"
                >
                  <p class="text-wrap mb-0">
                    Is this location that outsource referrals
                  </p>
                </mat-checkbox>
                <!-- Comments -->
                <mat-form-field class="col-12 mt-1">
                  <mat-label>Comments</mat-label>
                  <textarea
                    matInput
                    rows="4"
                    autocomplete="new-txtComments"
                    maxlength="5000"
                    formControlName="txtComments"
                    [(ngModel)]="notesText"
                    (input)="updateCharacterCount(5000)"
                    type="text"
                  ></textarea>
                  <div class="char-count">{{ notesText?.length }} / 5000</div>
                </mat-form-field>
                <div
                  class="row d-flex align-items-center justify-content-center"
                >
                  <button class="buttonColor" mat-raised-button>Save</button>
                </div>

                <mat-divider class="my-3"></mat-divider>
                <div class="row">
                  <h3 class="col-3 header-text mb-0">Reporting</h3>

                  <!-- <div class="input-group mb-3">
                    <input
                      type="text"
                      class="form-control"
                      [ngStyle]="{
                        'border-color':
                          dateRangStatus == true ? '#bdb4b4' : 'white'
                      }"
                      aria-describedby="basic-addon1"
                      name="daterange"
                      ngxDaterangepickerMd
                      [(ngModel)]="selectedRange"
                      id="calendar-input"
                      [showCustomRangeLabel]="true"
                      [alwaysShowCalendars]="true"
                      [linkedCalendars]="true"
                      [ranges]="ranges"
                      [showClearButton]="true"
                      [locale]="calendarLocale"
                      [placeholder]="calendarPlaceholder"
                      [keepCalendarOpeningWithRange]="true"
                      [minDate]="minDate"
                      [maxDate]="maxDate"
                      (datesUpdated)="datesUpdateds($event)"
                    />
                    <span class="input-group-text bg-color" id="basic-addon1">
                      <label style="margin: auto" for="calendar-input"
                        ><i
                          class="fa fa-filter cursor"
                          aria-hidden="true"
                        ></i></label
                    ></span>
                  </div> -->
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-lg-8">
      <div class="row">
        <div class="col-xl-12 pb-2">
          <div class="card elevated-div">
            <ul
              class="card-title"
              ngbNav
              #nav="ngbNav"
              justify="justified"
              class="alert-success nav nav-pills nav-fill"
              style="border-bottom: 2px solid rgb(201, 201, 201)"
            >
              <li ngbNavItem="physicians">
                <a
                  [ngClass]="activeTab == 'physicians' ? 'active' : ''"
                  ngbNavLink
                  (click)="changeTab('physicians')"
                  ><b>Physicians</b></a
                >
              </li>
              <li ngbNavItem="requirements">
                <a
                  [ngClass]="activeTab == 'requirements' ? 'active' : ''"
                  ngbNavLink
                  (click)="changeTab('requirements')"
                  id="tab-3"
                  ><b>Requirements</b></a
                >
              </li>

              <li ngbNavItem="moreinfo">
                <a
                  [ngClass]="activeTab == 'moreinfo' ? 'active' : ''"
                  ngbNavLink
                  id="tab-3"
                  ><b>More Info</b></a
                >
              </li>
              <li ngbNavItem="notes">
                <a
                  [ngClass]="activeTab == 'notes' ? 'active' : ''"
                  ngbNavLink
                  id="tab-3"
                  ><b>Notes</b></a
                >
              </li>
              <li ngbNavItem="faxLog">
                <a
                  [ngClass]="activeTab == 'faxLog' ? 'active' : ''"
                  ngbNavLink
                  id="tab-3"
                  ><b>Fax Log</b></a
                >
              </li>

              <li ngbNavItem="faxNumbers">
                <a
                  [ngClass]="activeTab == 'faxNumbers' ? 'active' : ''"
                  ngbNavLink
                  id="tab-3"
                  ><b>Fax Numbers</b></a
                >
              </li>
            </ul>
            <div class="card-body mb-0">
              <!-- Demographics -->
              <div *ngIf="activeTab === 'physicians'">
                <div class="row">
                  <!-- <h3 class="header-text ml-2">Physicians</h3> -->

                  <div class="table p-2 mb-0 table-responsive">
                    <table datatable class="row-border hover">
                      <thead>
                        <tr>
                          <th>Physicians</th>
                          <th>NPI</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Doctor 1</td>
                          <td>9755245871</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <!-- Plans -->
              <div *ngIf="activeTab === 'requirements'"></div>
              <!-- More Info -->
              <div *ngIf="activeTab === 'moreinfo'"></div>
              <!-- Notes -->
              <div *ngIf="activeTab === 'notes'"></div>
              <!-- Fax Log -->
              <div *ngIf="activeTab === 'faxlog'"></div>
              <!-- Fax Numbers -->
              <div *ngIf="activeTab === 'faxNumbers'"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
