import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { filter, map, startWith, switchMap } from 'rxjs/operators';
import {
  PickUpExchangeListDTO,
  PickUpExchangeSearchDTO,
} from '../order-proxy/order-management/order-optimization/dto';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { Observable, Subscription } from 'rxjs';
import { PickUpExchangeService } from '../order-proxy/order-management/order-optimization/pick-up-exchange.service';
import { HttpErrorResponse } from '@angular/common/http';
import Swal from 'sweetalert2';
import { CookieService } from 'ngx-cookie-service';
import { MatDialog } from '@angular/material/dialog';
import { PickupExchangeUploadTabComponent } from '../pickup-exchange-upload-tab/pickup-exchange-upload-tab.component';
import { Title } from '@angular/platform-browser';
import { MatOption } from '@angular/material/core';
import { MatSelect } from '@angular/material/select';
import { OrganizationUnitService } from 'projects/admin/src/app/admin-proxy/platform-app-management/rcm/platform-management/organization-units/organization-unit.service';

@Component({
  selector: 'app-pickup-exchange-list',
  templateUrl: './pickup-exchange-list.component.html',
  styleUrls: ['./pickup-exchange-list.component.scss'],
})
export class PickupExchangeListComponent implements OnInit {
  pickUpForm: FormGroup;
  isLoading: boolean;
  dataSource: MatTableDataSource<PickUpExchangeListDTO> =
    new MatTableDataSource<PickUpExchangeListDTO>([]);
  arrDisplayedColumns: string[] = [
    'defaultExchangeId',
    'defaultPatientId',
    'defaultSaleOrderId',
    'patientName',
    'branch',
    'pickupOrderedDate',
    'pickupStatus',
    'pickupCompletedDate',
    //'options',
    'action',
  ];
  @ViewChild(MatSort, { static: false }) sort: MatSort = new MatSort();
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  subscription$: Subscription[] = [];
  @ViewChild('selectBranch') selectBranch: MatSelect;
  @ViewChild('selectAllBranch') private selectAllBranch: MatOption;
  branchValue : string[] =[];
  public brnachList: Observable<Branch[]> | undefined;
  public drpBranch: Branch[] = [];
  tenantId: string = "";
  branchForm: FormGroup;
  organizationUnitName: string;

  constructor(
    private PickupExchangeService: PickUpExchangeService,
    private formBuilder: FormBuilder,
    private cookieService: CookieService,
    public dialog: MatDialog,
    public title: Title,
    private branchService : OrganizationUnitService
  ) {}

  ngOnInit(): void {
    this.tenantId = localStorage.getItem("tenantId") ?? "";
    this.createPickupExchangeSearchForm();
    this.getExchangeListData();
    this.title.setTitle("Qsecure | Exchange List");

    this.branchForm = this.formBuilder.group({
      drpBranch : new FormControl(''),
      txtBranch : new FormControl(''),
    });

    this.getBranchList();
  }
  uploadDoc(element) {
    const dialogRef = this.dialog.open(PickupExchangeUploadTabComponent, {
      disableClose: true,
      data: {
        pickupExchangeId: element.pickupId,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      // Handle the result after the dialog is closed (if needed)
      // For example, you can trigger the table reload here
      if (result === 'saved') {
        this.getExchangeListData();
      }
    });
  }
  createPickupExchangeSearchForm() {
    this.pickUpForm = this.formBuilder.group({
      defaultPatientId: new FormControl(''),
      defaultExchangeId: new FormControl(''),
      defaultSaleOrderId: new FormControl(''),
      patientName: new FormControl(''),
      pickupOrderedDate: new FormControl(''),
      pickupStatus: new FormControl(''),
      pickupCompletedDate: new FormControl(''),
      chkPickupCompleted: new FormControl(''),
    });
  }
  ViewOrder() {
    this.cookieService.set('orderStatus', 'false');
  }

  downloadReturnDocument(pickupExchangeId: string) {

  }

  getExchangeListData() {
    const exchangePickuplist = this.pickUpForm.valueChanges
      .pipe(
        startWith({
          defaultExchangeId: '',
          defaultPatientId: '',
          defaultSaleOrderId: '',
          patientName: '',
          pickupOrderedDate: '',
          pickupStatus: '',
          pickupCompletedDate: '',
        }),
        filter((x) => x && this.pickUpForm.valid),
        map((response) => {
          this.isLoading = true;
          this.setTableData([]);
          const searchData: PickUpExchangeSearchDTO = {
            PatientId: String(response?.defaultPatientId)?.trim() ?? '',
            orderidId: String(response?.defaultSaleOrderId)?.trim() ?? '',
            PickupExchangeId: String(response?.defaultExchangeId)?.trim() ?? '',
            Patientname: String(response?.patientName)?.trim() ?? '',
            Orderdate: String(response?.pickupOrderedDate)?.trim() ?? '',
            Pickupstatus: String(response?.pickupStatus)?.trim() ?? '',
            Completeddate: String(response?.pickupCompletedDate)?.trim() ?? '',
          };

          return searchData;
        }),
        switchMap((x) =>
          this.PickupExchangeService.getPickUpExchangeList(
            x.PickupExchangeId,
            x.PatientId,
            x.orderidId,
            x.Patientname,
            x.Orderdate,
            x.Pickupstatus,
            x.Completeddate
          )
        )
      )
      .subscribe(
        (response) => {

          this.isLoading = false;
          const responseData: PickUpExchangeListDTO[] = response ?? [];
          this.setTableData(responseData);
        },
        (err) => {
          this.isLoading = false;
          this.setTableData([]);
          const data: HttpErrorResponse = err;
          Swal.fire({
            icon: 'info',
            text: data?.error?.error?.message,
          });
        }
      );
    this.subscription$.push(exchangePickuplist);
  }

  setTableData(tableData: PickUpExchangeListDTO[] = []) {
    this.dataSource = new MatTableDataSource(tableData);
    this.sort.disableClear = true;
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }

  allBranchSelect(){
    if(this.selectAllBranch.selected){
      this.selectBranch.options.forEach((item: MatOption) => item.select());
    }
    else{
      this.selectBranch.options.forEach((item: MatOption) => item.deselect());
    }
  }
  getBranchList(){
    const getBranchList = this.branchService.getBranchList(this.tenantId).subscribe(response => {
      response?.forEach(element => {
        this.drpBranch.push({
          id: element.id,
          organizationUnitName: element.organizationUnitName,
        })
      });

      this.brnachList = this.branchForm
        ?.get('txtBranch')
        ?.valueChanges?.pipe(
          startWith(''),
          map((value) => 
            this.drpBranch?.filter((option) =>
              option?.organizationUnitName
                ?.toLowerCase()
                ?.includes(value?.toLowerCase() ?? '')
            )
          )
        );


    }, err =>{
      const data : HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    });
    this.subscription$.push(getBranchList);
  }
}

export interface Branch{
  id?: string;
  organizationUnitName?: string;
}
