import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  NgZone,
  OnInit,
  Output,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import {
  AbstractControl,
  AsyncValidatorFn,
  FormBuilder,
  FormControl,
  FormGroup,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import {
  AddNoteTabComponent,
  noWhitespaceValidator,
} from '../add-note-tab/add-note-tab.component';
import { TextValidators } from 'projects/shared/src/app/validations/text-validator';
import {
  RegionStatesDTO,
  RegionCitiesDTO,
  RegionCountriesDTO,
} from '../dropdown-proxy/dropdown-management/dropdowns/dto';
import { Observable, Subject, Subscription, of } from 'rxjs';
import { RegionDropdownService } from '../dropdown-proxy/rcm/dropdown-management/dropdowns/region-dropdown-service/region-dropdown.service';
import {
  map,
  startWith,
  tap,
  debounceTime,
  filter,
  timeout,
} from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import Swal from 'sweetalert2';
import { Branch } from '../patient-search-tab/patient-search-tab.component';
import { InboundDocumentService } from 'projects/admin/src/app/admin-proxy/platform-app-management/rcm/platform-management/inbound';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
// import { defaultGuid } from 'projects/admin/src/app/document-perviewer/document-perviewer.component';
import {
  DiagnosisCode10DTO,
  GenderDTO,
  MachineTypeDTO,
  MaritalStatusDTO,
  PatientMasterDropdownDTO,
  SymptomsDTO,
} from '../dropdown-proxy/dropdown-management/dropdowns/dto/patient';
import {
  DiagnosisCode10Service,
  PatientDropdowns,
} from '../dropdown-proxy/dropdown-management/dropdowns';
import { PatientMasterDropdownService } from '../dropdown-proxy/dropdown-management/dropdowns/master-dropdown-service';
import emailMask from 'text-mask-addons/dist/emailMask';
import { ListService, PagedAndSortedResultRequestDto } from '@abp/ng.core';
import {
  SortingShortCode,
  TextAnnotation,
  defaultCountry,
  defaultGuid,
  sotingSelectedCSS,
  tiffPointerMode,
} from 'projects/shared/src/app/enums/allenums.enum';
import { PayerDetailService } from '../patient-proxy/patient/payer-detail.service';
import { MasterProviderService } from 'projects/admin/src/app/warehouse-proxy/inventory';
import { OrganizationUnitService } from 'projects/admin/src/app/admin-proxy/platform-app-management/rcm/platform-management/organization-units';
import {
  AnnotatedDTO,
  CreateUpdateNewsetupDemographicsDTO,
  DocumentClassificationDto,
  NewPatientDTO,
  SortedDocumentGroupDTO,
  plansDTO,
  policyInsurance,
} from 'projects/admin/src/app/admin-proxy/platform-app-management/rcm/platform-management/inbound/dto/models';
import { ToastrService } from 'ngx-toastr';
import { DocumentCheckListComponent } from 'projects/admin/src/app/document-check-list/document-check-list.component';
import {
  AddressService,
  PatientDocumentService,
  PatientPersonalService,
} from '../patient-proxy/patient-optimization';
import { DoctorService, PatientSearchService } from '../patient-proxy/patient';
import { doctorInterface } from '../patient-clinical-tab/patient-clinical-tab.component';
import { CommunicationService } from 'projects/shared/src/app/services/communication.service';
import { PatientDoctorComponent } from '../patient-doctor/patient-doctor.component';
import { ActivatedRoute, Router } from '@angular/router';
import {
  Complainces,
  InitialFaceToFaces,
  Prescriptions,
  SaveDocumentDto,
  SleepStudies,
  sortedDocumentDetailsDTO,
} from 'projects/admin/src/app/admin-proxy/platform-app-management/rcm/platform-management/sorting/dto';
import { SortType } from 'projects/admin/src/app/admin-proxy/platform-app-management/rcm/platform-management/localization/enumeration';
import { DocumentsService } from '../patient-proxy/controllers';
import { NewsetupMovetoComponent } from '../newsetup-moveto/newsetup-moveto.component';
import { InventoryTransferStatusService } from 'projects/inventory/src/app/item-proxy/item-management/optimization/inventory-transfer-status.service';
// import { Console } from 'console';
import { PatientIcd10Component } from '../patient-icd10/patient-icd10.component';
import { CreateUpdateChecklistSortingDTO } from '../patient-proxy/patient-optimization/dto/models';
import { DomSanitizer } from '@angular/platform-browser';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MmOrderService } from 'projects/order/src/app/order-proxy/order-management/order-optimization/mm-order.service';
import { JoinFaxComponent } from 'projects/admin/src/app/join-fax/join-fax.component';
import { DatePipe } from '@angular/common';
import { Title } from '@angular/platform-browser';
import { PatientSearchNewsetupComponent } from './patient-search-newsetup/patient-search-newsetup.component';
import { PatientCommonNotesComponent } from 'projects/shared/src/app/components/patient-common-notes/patient-common-notes.component';
import { SendToAnotherUserComponent } from 'projects/admin/src/app/send-to-another-user/send-to-another-user.component';
import { LoaderService } from 'projects/shared/src/app/services/loader-service';
import { DocumentBinService } from 'projects/admin/src/app/admin-proxy/platform-app-management/rcm/platform-management/document-bin/document-bin.service';
import {
  CreateUpdateDocumentBinDTO,
  ValidateDocumentBinDTO,
} from 'projects/admin/src/app/admin-proxy/platform-app-management/rcm/platform-management/document-bin/dto/models';
import { MatSelect } from '@angular/material/select';
import { ProductCategoryDetailsService } from 'projects/inventory/src/app/item-proxy/item-management/optimization/product-category-details.service';
@Component({
  selector: 'app-newsetup-modal',
  templateUrl: './newsetup-modal.component.html',
  styleUrls: ['./newsetup-modal.component.scss'],
  providers: [ListService, DatePipe],
})
export class NewsetupModalComponent implements OnInit {
  isDocumentCheckList: boolean = false;
  txtPatientFullName: string;
  txtChartV1: string;
  isSortingShow: string = 'Pending';
  isDocumentCheckListShow: string = 'Pending';
  dtOptions: any;
  strPdfString: string = '';
  pdfViewString: string = '';
  isHideTiffViewer: boolean = false;
  tiffPageNo: number = 0;
  isUniq: boolean = true;
  isUniqV2: boolean = true;
  isUniqV1: boolean = true;
  patientChartApiSubscription: Subscription;
  referralDropdown: Subscription;
  physicianDropdown: Subscription;
  disableAnnotation: boolean = false;
  priPayerDetails: Subscription;
  secPayerDetails: Subscription;
  terPayerDetails: Subscription;
  referralPractice: any;
  physicianDrpdwn: any;
  checkDateExist: Subscription;
  showPdfViwer: boolean = false;
  sotingSelectedCSS = sotingSelectedCSS;
  selectedOption: sotingSelectedCSS = sotingSelectedCSS.Total;
  document_shortCode: any;
  ReferralId: string = defaultGuid;
  payerId: string = defaultGuid;
  secPayerId: string = defaultGuid;
  terPayerId: string = defaultGuid;
  ReferralName: string;
  PhysicianName: string;
  TotalAPICallDone: boolean = false;
  PendingAPICallDone: boolean = false;
  iPhysicianLoading: boolean = false;
  pendingTimeout: any;
  isTotalAPIcallGoingOn: boolean = true;
  isTotalAPIcallGoingOnCount: number = 0;
  isDocumentLoadBefore: boolean = false;
  isMoved: boolean = false;
  isPalnTabAPICalls: boolean = false;
  isPlanSpinnerMoveVerification: boolean = false;
  isdrpLoading: boolean = false;
  isSecdrpLoading: boolean = false;
  isTerdrpLoading: boolean = false;
  isDemoTabAPICalls: boolean = false;
  notLoadSort: boolean = false;
  isDemographicsDoneV1: boolean;
  loadInboundDocumentIsPrevoiusCalled: boolean = false;
  VeryFirstTime: boolean = true;
  defaultFaxIdV1: string;
  documentIdV1: string;
  isViewDocument: boolean = true;
  drpFacilityList: any;
  isOrderPageLoading: boolean;
  exisingPatient: boolean = false;
  demoNotes: string = '';
  planNotes: string = '';
  LastSortingPageNo: number = null;
  LastSortingShortCode: string = null;
  isLoadOverAllSortingAPI: boolean = true;
  isSaveSortingButton: boolean = false;
  swalOpened: boolean = false;
  // currentShortCode: string = '';
  annotationModeFlag: number = 1;
  annotationModeFlagOld: number = 1;
  cursorStyle = 'crosshair';
  // cursorStyleCSS: string = '';
  tiffPointerMode = tiffPointerMode;
  inboundShortCode: string;
  documentBinId: string = defaultGuid;
  refreshButtonClicked: boolean = false;
  TiffNotLoadingError: boolean = true;
  DocumentBinSaveBtn: boolean = false;
  removeSorting: number[] = [];
  filteredCategoryDetails: Observable<string[]> | undefined;
  @ViewChild('citySelect') citySelect: MatSelect; //City Dropdown
  // actualPageNo: any;
  @HostListener('input', ['$event']) onInput(event: Event) {
    const input = event.target as HTMLInputElement;
    // Check if the input field has the class 'zip-code-input'
    if (input.classList.contains('zip-code-input')) {
      let formattedValue = input.value.replace(/[^0-9]/g, '');

      if (formattedValue.length > 5) {
        formattedValue =
          formattedValue.slice(0, 5) + '-' + formattedValue.slice(5);
      }

      input.value = formattedValue;
    }
  }
  checkValidInputCase = 0;
  isLoading = false;
  birthdate: Date;
  birthdateV1: string;
  RxDate: Date;
  RxDateV1: string;
  isShowPlanSpinner: boolean = false;
  isHidePlanSpinner: boolean = false;
  isPlanSpinner: boolean = false;
  isPlanReadySpinner: boolean = false;
  @ViewChildren('myInputRefs') myInputRefs: QueryList<ElementRef>;
  @ViewChildren('txtDocBin') txtDocBin: QueryList<ElementRef>;
  @Output() reloadTableEvent = new EventEmitter(); //Event Emitter to reload the table
  @Output() reloadTreeEvent = new EventEmitter(); //Event Emitter to reload the tree
  @Output() selectNode = new EventEmitter();
  patientId: string = defaultGuid;
  defaultGuid = defaultGuid;
  // activeTab: string = 'document'; //'document';
  activeTab: string | null = null;
  fileUploadsInput: sortedDocumentDetailsDTO;
  initialFaceToFaces: InitialFaceToFaces;
  sleepStudies: SleepStudies;
  prescriptions: Prescriptions;
  complainces: Complainces;
  arrowButtonClicked = false;
  isDemoCompleted: boolean = false;
  strSelectedPdfPath: string = '';
  strSelectedPdfPathCopy: string = '';
  LastClick: any;
  pdfheight: string = '100%';
  demoForm: FormGroup;
  planForm: FormGroup;
  notesForm: FormGroup;
  patientSearchForm: FormGroup;
  isShowSpinner: boolean = false;
  isPlanShowSpinner: boolean = false;
  isExistDocChecked: boolean = false;
  isExistDocMessage: boolean = false;
  gender: string = '';
  drpGenderLoop: GenderDTO[] = [];
  phoneCode: string = '';
  saveButtonDemo: boolean = false;
  isplanSave: boolean = false;
  isMovetoDisable: boolean = false;
  notesText = '';
  notesTextV1 = '';
  BirthdateMaxDate: Date;
  BirthdateMinDate: Date;
  RxdateMin: Date;
  RxdateMax: Date;
  drpProviders: any[] = [];
  drpCountries: RegionCountriesDTO[] = [];
  drpStates: RegionStatesDTO[] = [];
  drpCities: any[] = [];
  public drpBranch: Branch[] = [];
  public filteredCountries: Observable<RegionCountriesDTO[]> | undefined;
  public filteredStates: Observable<RegionStatesDTO[]> | undefined;
  public filteredCities: any;
  filteredPatients: Observable<{ guid: string; idAndNameAndDOB: string }[]> =
    of([]);
  lstAllPatientIds: { guid: string; idAndNameAndDOB: string }[] = [];
  country: string = '';
  State: string = '';
  City: string = '';
  provider: string = '';
  isSaveBtnDisabled: boolean = false;
  organizationUnitName: string;
  PrimarypanelOpenState: boolean = true;
  SecpanelOpenState: boolean = false;
  TerpanelOpenState: boolean = false;
  secondaryValidator: boolean = false;
  tertiaryValidator: boolean = false;
  emailMask: any;
  selectPayorList: any;
  ltSecondaryPayers: any;
  ltTertiaryPayers: any;

  tenantId: string = defaultGuid;
  lstDoctor = [];
  lstServiceType: any;
  ltCategories: any;
  documentTypes = [];
  lstChartNos: any;

  // pageCount = 0;
  currentPage: number = 1;
  idSelected: number;
  // totalPages: number = 0;
  pdfPages: number = 0;
  documentId: string;
  blobName: string;
  branchId: string;
  inboundStatusId: string;
  defaultFaxId: string;
  statusId: string;
  isNewSetupDone: string;
  saveDocumentDetails: SaveDocumentDto[] = [];
  selectedNode: any;
  filteredReferralDoctors: Observable<doctorInterface[]> | undefined; //Filtered Referring Provider Doctor Types
  filteredPhysicians: Observable<doctorInterface[]> | undefined; //Filtered Referring Provider Doctor Types
  filteredServiceType: any;
  filteredDoctors: Observable<doctorInterface[]> | undefined; //Filtered Referring Provider Doctor Types
  filteredRxDoctors: Observable<doctorInterface[]> | undefined; //Filtered Referring Provider Doctor Types
  public branchList: Observable<Branch[]> | undefined;
  public filteredPrimaryPayors: Observable<string[]> | undefined;
  primaryPayorId: any;
  public filteredSecondaryPayors: Observable<string[]> | undefined;
  secondaryPayorId: any;
  public filteredTertiaryPayors: Observable<string[]> | undefined;
  tertiaryPayorId: any;
  public filteredFacility: Observable<string[]> | undefined;
  facilityId: any;
  private closeDialogSubject = new Subject<void>();
  closeDialog$ = this.closeDialogSubject.asObservable();
  overAllPages: number = 0;
  overAllPagesBG: number = 0;
  sortedPages: number = 0;
  pendingPages: number = 0;
  // sortedPagesList = [];
  // txtSeriveTypeFilterControl = new FormControl();
  isMedicareList: any;
  isLoadSortingView: boolean = true;
  SortingShortCode = SortingShortCode;
  shortCodeList: any;
  documentDetails: DocumentClassificationDto;
  isSortingStart = false;
  isOrginalPDF = true;
  drpSymptoms: any[] = [];
  drpMachines: MachineTypeDTO[] = [];
  filteredSymptoms: Observable<SymptomsDTO[]> | undefined;
  filteredDiagnosiss: Observable<DiagnosisCode10DTO[]> | undefined;
  filteredRxDiagnosiss: Observable<DiagnosisCode10DTO[]> | undefined;
  filteredMachines: Observable<MachineTypeDTO[]> | undefined;
  drpDiagnosisICD10: DiagnosisCode10DTO[] = [];
  isDisabled = true;
  isSaveDisabled = false;
  private saveSubject: Subject<void> = new Subject<void>();
  subscription$: Subscription[] = [];
  isDemographicsDone: string;
  city: any;
  saveAnnotationAPICall: Subscription;
  loadInboundDocumentAPICall$: Subscription;
  SortedloadInboundDocumentAPICall$: Subscription;
  PendingloadInboundDocumentAPICall$: Subscription;
  @ViewChild('canvas', { static: true }) canvasRef!: ElementRef;
  ctx!: CanvasRenderingContext2D;
  pageNumber: number = 1;
  totalPages: number = 0;
  // readonly DEFAULT_ZOOM_LEVEL = 1;
  // readonly MAX_ZOOM_LEVEL = 2.5;
  // readonly MIN_ZOOM_LEVEL = 0.5;

  readonly DEFAULT_ZOOM_LEVEL = 0.7;
  readonly MAX_ZOOM_LEVEL = this.DEFAULT_ZOOM_LEVEL + 4;
  readonly MIN_ZOOM_LEVEL = 0.3;

  zoomLevel: number = this.DEFAULT_ZOOM_LEVEL; // Default Size
  zoomLevelReSize: number = this.DEFAULT_ZOOM_LEVEL;
  prevZoomLevel: number;
  existingDocumentImageList: any = [];
  TotalDocumentImageList: any = [];
  SortedDocumentImageList: any = [];
  PendingDocumentImageList: any = [];
  selectedExistingDocument: any = null;
  loadingMessage: string = 'Loading ...';
  isDrawing: boolean = false;
  startX: number = 0;
  startY: number = 0;
  lineColor: string = '#F6BE00'; // '#FFFF00';
  textColor: string = '#0000FF';
  savedState: ImageData | null = null;
  textAnnotations: TextAnnotation[] = [];
  isMouseDown: boolean = false;
  isMouseMove: boolean = false;
  isMouseUp: boolean = false;
  isMouseLeave: boolean = false;

  rectWidth: number;
  rectHeight: number;
  currentArrayIndex: number = 0;
  TiffError: boolean = true;
  SortSaveError: boolean = true;
  readonly DEFAULT_LAZY_LOADING = 10;
  from: number = 1;
  to: number = this.DEFAULT_LAZY_LOADING;
  readonly DEFAULT_LAZY_LOADING_API = 10; // If change the Value API side also need to change 10 to X
  // readonly DEFAULT_LAZY_LOADING_Index = 9;
  pendingFrom: number = 0;
  pendingTo: number = this.DEFAULT_LAZY_LOADING_API; // If change the Value API side also need to change 10 to X
  sortedFrom: number = 0; // Index based
  sortedTo: number = this.DEFAULT_LAZY_LOADING_API; // If change the Value API side also need to change 10 to X

  ProviderControl = new FormControl();
  drpProviderSearch: any;
  drpTitleLoop: any;
  // drpMartialList:any;
  drpMartialList: MaritalStatusDTO[] = [];
  isSortingV1: any;
  isSortingV2: boolean = false;
  validateEmailSubs: Subscription;
  loadloadOverAllSortingAPICall$: Subscription;
  mstDocumentBinForm: FormGroup;
  docOverallPages: number = 0;
  docSortedPages: number = 0;
  docUnSortedPages: number = 0;
  documentName: string = '';
  isDownloading: boolean = false;
  funcListener: any;
  constructor(
    private loaderService: LoaderService,
    private activatedRoute: ActivatedRoute,
    private formBuilder: FormBuilder,
    private tranferStatusService: InventoryTransferStatusService,
    private textValidators: TextValidators,
    private regionDropdownService: RegionDropdownService,
    private inboundDocumentService: InboundDocumentService,
    private patientDropdownService: PatientMasterDropdownService,
    private payerDetailService: PayerDetailService,
    public PatientAddressService: AddressService,
    private providerService: MasterProviderService,
    private branchService: OrganizationUnitService,
    private patientPersonalService: PatientPersonalService,
    private doctorService: DoctorService,
    private title: Title,
    public list: ListService,
    private datepipe: DatePipe,
    private communicationService: CommunicationService,
    private toastr: ToastrService,
    private documentsService: DocumentsService,
    private matDialog: MatDialog,
    private dialog: MatDialog,
    private matDoctorDialog: MatDialog,
    private matIcd10Dialog: MatDialog,
    private patientSearchService: PatientSearchService,
    private diagnosis10: DiagnosisCode10Service,
    public router: Router,
    private cd: ChangeDetectorRef,
    private patientDocumentService: PatientDocumentService,
    private sanitizer: DomSanitizer,
    private snackBar: MatSnackBar,
    private mmOrderService: MmOrderService,
    private documentBinService: DocumentBinService,
    private _snackBar: MatSnackBar,
    public inboundDocUploadService: InboundDocumentService,
    private categoryService: ProductCategoryDetailsService,
  ) {
    this.loaderService.getLoaderState().subscribe((isOrderPageLoading) => {
      this.isOrderPageLoading = isOrderPageLoading;
    });
    this.emailMask = emailMask;
    this.communicationService.functionPatchPatientDetails$.subscribe(
      (patientIdInput) => {
        this.loadPatientFormV1(patientIdInput);
      }
    );

    //Function for Move to Verfication/Confirmation while Patinet Save Demographics
    this.communicationService.functionNewSetup$.subscribe((isDocument) => {
      if (isDocument === true) {
        this.saveDemographics(true);
      }
    });

    //Redirect Function for after Save Join Fax and Move to Bin
    this.communicationService.functionMoveToNewSetup$.subscribe(() => {
      this.redirectDahboard();
    });

    //Save Plan Function for for Open Document check list popup
    this.communicationService.functionNewSetupPlanSave$.subscribe(
      (isDocument) => {
        if (isDocument === true) {
          this.isDocumentCheckListShow = 'Completed';
          this.savePlans(false);
        }
      }
    );

    this.funcListener = this.func.bind(this);
    window.addEventListener('storage', this.funcListener);
  }

  ngOnInit(): void {
    this.title.setTitle('Qsecure | New Setup');
    const activatedRoute = this.activatedRoute.paramMap.subscribe(
      (response) => {
        this.documentId = response.get('docId') ?? defaultGuid;
        if (response.get('selectedNode')) {
        this.selectedNode = JSON.parse(decodeURIComponent(response.get('selectedNode')));
        }else{
          this.selectedNode=null;
        }

        // this.selectedNode = response.get('selectedNode') ?? defaultGuid;
        this.isSortingV1 = response.get('isSorting') ?? false;
        this.inboundShortCode = response.get('inboundShortCode') ?? '';
      },
      (err) => {
        const data: HttpErrorResponse = err;
        this.toastr.error(data?.error?.error?.message, 'Error');
      }
    );
    this.subscription$.push(activatedRoute);
    this.tenantId = localStorage.getItem('tenantId') ?? '';
    this.intializeForms();
    this.loadPatientDemoForm(this.documentId, false);
    this.searchPatient('');
    this.loadDropdowns();
    this.loadValidDates();
    this.getDropdown();
    this.getPhysicianDrpdwn();
    this.getTitledrp();
    // this.getReferralDrpdwn();
    this.saveSubject.pipe(debounceTime(1000)).subscribe(() => {
      this.isSaveDisabled = false;
      this.isDisabled = true;
      this.isSaveSortingButton = true;
      this.saveDocument();
    });
    // this.txtSeriveTypeFilterControl.valueChanges.subscribe(
    //   (dxTypeFilterText) => {
    //     dxTypeFilterText = dxTypeFilterText.toLowerCase();
    //     this.filteredServiceType = this.lstServiceType.filter((dx) =>
    //       dx.categoryName.toLowerCase().includes(dxTypeFilterText)
    //     );
    //   }
    // );

    //Provider DropDowm Search
    this.ProviderControl.valueChanges.subscribe((FilterText) => {
      FilterText = FilterText.toLowerCase();
      this.drpProviders = this.drpProviderSearch.filter((dx) =>
        dx.name.toLowerCase().includes(FilterText)
      );
    });
    // Add keydown event listener
    window.addEventListener('keydown', this.handleKeyDown.bind(this));
  }

  // this.route.paramMap.subscribe((params) => {
  //   const selectedNodeString = params.get('selectedNode');
  //   if (selectedNodeString) {

  //     console.log(this.selectedNode); // Use it as needed
  //   }
  // });

  ngOnDestroy(): void {
    if (this.loadloadOverAllSortingAPICall$) {
      this.loadloadOverAllSortingAPICall$.unsubscribe();
    }
    if (this.checkDateExist) {
      this.checkDateExist.unsubscribe();
    }
    if (this.priPayerDetails) {
      this.priPayerDetails.unsubscribe();
    }
    if (this.secPayerDetails) {
      this.secPayerDetails.unsubscribe();
    }
    if (this.terPayerDetails) {
      this.terPayerDetails.unsubscribe();
    }
    if (this.patientChartApiSubscription) {
      this.patientChartApiSubscription.unsubscribe();
    }
    if (this.referralDropdown) {
      this.referralDropdown.unsubscribe();
    }
    if (this.physicianDropdown) {
      this.physicianDropdown.unsubscribe();
    }

    if (this.saveAnnotationAPICall) {
      this.saveAnnotationAPICall.unsubscribe();
    }
    if (this.loadInboundDocumentAPICall$) {
      this.loadInboundDocumentAPICall$.unsubscribe();
    }
    if (this.PendingloadInboundDocumentAPICall$) {
      this.PendingloadInboundDocumentAPICall$.unsubscribe();
    }
    if (this.SortedloadInboundDocumentAPICall$) {
      this.SortedloadInboundDocumentAPICall$.unsubscribe();
    }
    if (this.validateEmailSubs) {
      this.validateEmailSubs.unsubscribe();
    }
    this.subscription$.forEach((sub) => {
      sub && sub?.unsubscribe();
    });
    // Remove keydown event listener
    window.removeEventListener('keydown', this.handleKeyDown.bind(this));
  }

  @HostListener('window:beforeunload', ['$event'])
  unloadNotification($event: any): void {
    // Check if there are unsaved changes (you'll need to implement this logic)
    if (this.isSortingStart || this.textAnnotations.length !== 0) {
      $event.returnValue = true;
    }
  }

  //GET Ttile Drop Down
  getTitledrp() {
    this.drpTitleLoop = [
      { titleName: 'Mr' },
      { titleName: 'Mrs' },
      { titleName: 'Ms' },
      // { titleName: 'Miss'},
    ];
  }

  getPhysicianDrpdwn() {
    if (this.physicianDropdown) {
      this.physicianDropdown.unsubscribe();
      this.iPhysicianLoading = false;
    }
    this.iPhysicianLoading = true;
    this.filteredPhysicians = null;
    this.physicianDropdown = this.doctorService.
      // getPhysicianDropdown('', '')
      getAllPhysiciansByPracticeIDAndFullNameAndGDoctorAndSSearch('', '', '', '')
      .subscribe(
        (response) => {
          this.iPhysicianLoading = false;
          this.physicianDrpdwn = response;
          //Primary Doctor Dropdown
          this.filteredPhysicians = this.demoForm
            ?.get('txtPhysicianFilter')
            ?.valueChanges.pipe(
              startWith(''),
              map((value) =>
                this.physicianDrpdwn?.filter((option) =>
                  option?.doctorName
                    ?.toLowerCase()
                    ?.includes(value?.toLowerCase() ?? '')
                )
              )
            );
        },
        (err) => {
          this.iPhysicianLoading = false;
          const data: HttpErrorResponse = err;
          this.toastr.error(data?.error?.error?.message);
        }
      );
  }
  policyDisabled: boolean = false;
  getDropdown(): void {
    let patientDropdownInput: PatientDropdowns[];
    patientDropdownInput = [PatientDropdowns.MaritalStatus];
    const patientDropdown = this.patientDropdownService
      .getPatientDropdownsByInput(patientDropdownInput)
      .subscribe(
        (response) => {
          if (response && response.maritalStatuses) {
            this.drpMartialList = response?.maritalStatuses;
          }
        },
        (err) => {
          const data: HttpErrorResponse = err;
          this.toastr.error(data?.error?.error?.message, 'Error');
        },
        () => {}
      );
  }
  cashCash: any = false;
  changeCashCase(isLoad: boolean) {
    this.cashCash = this.planForm.value.chkCashPayment;
    if (this.cashCash === true) {
      let provider = this.planForm.value.txtProvider;
      // this.planForm.reset();
      this.initializePlanForm();

      this.planForm?.patchValue({
        chkCashPayment: true,
        drpBranch: this.branchId,
        txtProvider: provider,
      });
      this.planForm?.controls?.drpPrimaryPayor?.setValidators([
        Validators.nullValidator,
      ]);
      this.planForm?.controls?.txtPrimaryPolicyNo?.setValidators([
        Validators.nullValidator,
      ]);
      // this.planForm?.controls?.txtPrimaryGroupNo?.setValidators([
      //   Validators.nullValidator,
      // ]);
      this.planForm?.controls?.txtProvider?.setValidators([
        Validators.nullValidator,
      ]);
      this.planForm?.controls?.drpPrimaryPayor?.updateValueAndValidity();
      this.planForm?.controls?.txtPrimaryPolicyNo?.updateValueAndValidity();
      // this.planForm?.controls?.txtPrimaryGroupNo?.updateValueAndValidity();
      this.planForm?.controls?.txtProvider?.updateValueAndValidity();
    } else {
      let provider = this.planForm.value.txtProvider;
      let patient = this.patientSearchForm.value.drpSearchPatient;
      this.initializePlanForm();
      // if (this.isDemographicsDone === 'true') {
      //   this.loadPatientDemoForm(this.documentId);
      // }
      this.planForm?.patchValue({
        chkCashPayment: false,
        drpBranch: this.branchId,
        txtProvider: provider,
      });
      this.patientSearchForm.patchValue({
        drpSearchPatient: this.patientId,
      });
    }
  }
  clearReferralDoctorsList(event: Event) {
    event.stopPropagation();
    this.demoForm?.patchValue({
      drpReferral: '',
      drpPhysician: '',
    });
    this.getReferralDrpdwn();
    this.getPhysicianDrpdwn();
  }

  clearPhysicianDoctorsList(event: Event) {
    event.stopPropagation();
    this.demoForm?.patchValue({
      drpPhysician: '',
    });
  }
  clearServiceType(event: Event) {
    event.stopPropagation();
    this.demoForm?.patchValue({
      drpServiceType: '',
    });
  }
  ClearSearchPatient(event: Event) {
    event.stopPropagation();
    this.patientSearchForm.patchValue({
      drpSearchPatient: '',
    });
    this.searchPatient('');
  }

  //To Initialize the Forms
  intializeForms() {
    this.demoForm = this.formBuilder.group({
      drpTitle: new FormControl(''),
      drpMartialSts: new FormControl(''),
      txtSSNNo: new FormControl('', [Validators.pattern(/^\d+$/)]),
      txtEmergencyNo: new FormControl(''),

      tglStat: new FormControl(),
      txtChart: new FormControl(''),
      txtFirstName: new FormControl('', [
        // this.textValidators.isTextCheck,
        Validators.required,
        noWhitespaceValidator,
      ]),
      txtMiddleName: new FormControl(''),
      txtLastName: new FormControl('', [
        // this.textValidators.isTextCheck,
        Validators.required,
        noWhitespaceValidator,
      ]),
      drpGender: new FormControl('', [Validators.required]),
      txtBirthdate: new FormControl(null, [Validators.required]),
      txtRXdate: new FormControl(null),
      txtEmail: new FormControl(''),
      txtAddress: new FormControl(''),
      txtCountry: new FormControl(''),
      txtCountryFilter: new FormControl(''),
      txtState: new FormControl(''),
      txtStateFilter: new FormControl(''),
      txtCity: new FormControl(''),
      txtCityFilter: new FormControl(''),
      txtPostalCode: new FormControl(''),
      txtHomeNo: new FormControl(''),
      txtCellNo: new FormControl(''),
      txtWorkNo: new FormControl(''),
      chkStat: new FormControl(''),
      // txtComments: new FormControl(''),
      drpReferral: new FormControl(''),
      txtReferralFilter: new FormControl(''),
      drpPhysician: new FormControl(''),
      drpServiceType: new FormControl(''),
      txtPhysicianFilter: new FormControl(''),
      txtCategoryFilter: new FormControl(''),
      // txtSeriveTypeFilter: new FormControl(''),
    });
    this.planForm = this.formBuilder.group({
      drpPrimaryPayor: new FormControl(null, [Validators.required]),
      primaryPayor: new FormControl(''),
      txtPrimaryPolicyNo: new FormControl(null, [
        Validators.required,
        noWhitespaceValidator,
      ]),
      // txtPrimaryGroupNo: new FormControl(null, [
      //   Validators.required,
      //   noWhitespaceValidator,
      // ]),
      txtPrimaryGroupNo: new FormControl(''),
      drpSecondaryPayor: new FormControl(''),
      secondaryPayor: new FormControl(''),
      txtSecondaryPolicyNo: new FormControl(''),
      txtSecondaryGroupNo: new FormControl(''),

      drpTertiaryPayor: new FormControl(''),
      tertiaryPayor: new FormControl(''),
      txtTertiaryPolicyNo: new FormControl(''),
      txtTertiaryGroupNo: new FormControl(''),

      chkCashPayment: new FormControl(false),

      txtProvider: new FormControl('', [Validators.required]),
      txtProviderSearch: new FormControl(''),
      drpFacility: new FormControl(''),
      selectFacility: new FormControl(''),

      drpBranch: new FormControl(this.branchId),
      selectBranch: new FormControl(''),
    });

    this.patientSearchForm = this.formBuilder.group({
      drpSearchPatient: new FormControl(null, [Validators.required]),
      txtPatientFilter: new FormControl(''),
      drpSearchStatus: new FormControl(''),
    });

    this.notesForm = this.formBuilder.group({
      txtCommentsDemo: new FormControl(''),
      txtCommentsPlan: new FormControl(''),
    });

    this.mstDocumentBinForm = this.formBuilder.group({
      txtBinName: new FormControl(
        '',
        [Validators.required, noWhitespaceValidator],
        this.checkDocumentBinUniqness()
      ),
    });
  }
  initializePlanForm() {
    this.planForm = this.formBuilder.group({
      drpPrimaryPayor: new FormControl(null, [Validators.required]),
      primaryPayor: new FormControl(''),
      txtPrimaryPolicyNo: new FormControl(null, [
        Validators.required,
        noWhitespaceValidator,
      ]),
      // txtPrimaryGroupNo: new FormControl(null, [
      //   Validators.nullValidator,
      //   noWhitespaceValidator,
      // ]),
      txtPrimaryGroupNo: new FormControl(''),

      drpSecondaryPayor: new FormControl(''),
      secondaryPayor: new FormControl(''),
      txtSecondaryPolicyNo: new FormControl(''),
      txtSecondaryGroupNo: new FormControl(''),

      drpTertiaryPayor: new FormControl(''),
      tertiaryPayor: new FormControl(''),
      txtTertiaryPolicyNo: new FormControl(''),
      txtTertiaryGroupNo: new FormControl(''),

      chkCashPayment: new FormControl(false),

      txtProvider: new FormControl('', [Validators.required]),
      txtProviderSearch: new FormControl(''),
      drpFacility: new FormControl(''),
      selectFacility: new FormControl(''),

      drpBranch: new FormControl(this.branchId),
      selectBranch: new FormControl(''),
    });
  }

  maxLengthValidator(maxLength: number): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const value = control.value;
      if (value && value.toString().replace('.', '').length > maxLength) {
        // return { 'maxLengthExceeded': true };
        return { invalid: true };
      }
      return null;
    };
  }

  noEValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      const value = control.value;
      if (value === null) {
        // return { 'invalidInput': true };
        return { invalid: true };
      }
      return null; // Validation passed
    };
  }

  trailingZerosValidator(
    control: AbstractControl
  ): { [key: string]: any } | null {
    const value = control.value;
    if (
      value &&
      value.toString().includes('.') &&
      value.toString().split('.')[1].length > 2
    ) {
      // control.setErrors({ 'trailingZeros': true }); // Set the control as invalid
      control.setErrors({ invalid: true });
      // return { 'trailingZeros': true };
      return { invalid: true };
    }
    return null;
  }

  onKeyDown(event: KeyboardEvent): void {
    let restrictedKeyCodes: number[] = [
      69, 109, 107, 106, 111, 189, 187, 40, 38,
    ]; // ,190,110
    if (restrictedKeyCodes.includes(event.keyCode)) {
      event.preventDefault(); // Prevent 'e' from being entered
    }
  }
  //To Validate the Patient Email after focusout
  validateEmail() {
    this.saveButtonDemo = true;
    if (this.validateEmailSubs) {
      this.validateEmailSubs.unsubscribe();
    }
    this.validateEmailSubs = this.inboundDocumentService
      .validatePatientEmailByGPatientIdAndEmailId(
        this.patientId,
        this.demoForm.value.txtEmail.toLowerCase()
      )
      .subscribe(
        (response) => {
          if (response) {
            // this.demoForm.get('txtEmail').setErrors({ invalidEmail: true });

            const errors = this.demoForm.get('txtEmail').errors
              ? { ...this.demoForm.get('txtEmail').errors }
              : {};
            delete errors['invalidEmail'];
            this.demoForm
              .get('txtEmail')
              .setErrors(Object.keys(errors).length ? errors : null);
            if (!errors['required']) {
              // Don't set error if email is required
              this.demoForm.get('txtEmail').setErrors({
                ...this.demoForm.get('txtEmail').errors,
                invalidEmail: true,
              });
            }
          } else {
            // Remove invalidEmail error if present
            const errors = this.demoForm.get('txtEmail').errors
              ? { ...this.demoForm.get('txtEmail').errors }
              : {};
            delete errors['invalidEmail'];
            this.demoForm
              .get('txtEmail')
              .setErrors(Object.keys(errors).length ? errors : null);

            // this.demoForm.get('txtEmail').setErrors({ invalidEmail: false });
          }
          this.saveButtonDemo = false;
        },
        (err) => {}
      );
  }

  searchPatient(value: any) {
    this.loadPatientChartNos(value);
  }

  //To Load the Chart No of the Patients
  loadPatientChartNos(searchParameter) {
    if (this.patientChartApiSubscription) {
      this.patientChartApiSubscription.unsubscribe();
    }
    this.patientChartApiSubscription = this.patientSearchService
      .getNewSetupPatientSearch(searchParameter, this.patientId)
      .subscribe(
        (response) => {
          this.lstChartNos = response;
          return this.lstChartNos;
        },
        (err) => {}
      );
  }
  //To Load the Dropdowns
  loadDropdowns() {
    //Gender Dropdown Binding
    const patientDropdownInput: PatientDropdowns[] = [PatientDropdowns.Genders];
    const patientDropdown = this.patientDropdownService
      .getPatientDropdownsByInput(patientDropdownInput)
      .subscribe(
        (stateResponse) => {
          let response: PatientMasterDropdownDTO = stateResponse;
          this.drpGenderLoop = response?.genders ?? [];
        },
        (err) => {
          const data: HttpErrorResponse = err;
          this.toastr.error(data?.error?.error?.message, 'Error');
        }
      );
    this.subscription$.push(patientDropdown);

    // const SeriviceTypeDropdown = this.tranferStatusService
    //   .ServiceTypeDetailsV1()
    //   .subscribe(
    //     (stateResponse) => {
    //       this.lstServiceType = stateResponse;
    //       // this.filteredServiceType = this.lstServiceType;
    //       this.filteredServiceType = this.lstServiceType.sort((a, b) =>
    //         a.categoryName.localeCompare(b.categoryName)
    //       );
    //     },
    //     (err) => {
    //       const data: HttpErrorResponse = err;
    //       this.toastr.error(data?.error?.error?.message, 'Error');
    //     }
    //   );
    // this.subscription$.push(SeriviceTypeDropdown);

    const SeriviceTypeDropdown = this.categoryService
    .getActiveCategoryList(new PagedAndSortedResultRequestDto())
    .subscribe(
      (response) => {
        this.ltCategories = response?.items;
        this.filteredCategoryDetails = this.demoForm.get('txtCategoryFilter')
          ?.valueChanges.pipe(
            startWith(''),
            map((value) =>
              this.ltCategories?.filter((option) =>
                option?.productCategoryName
                  ?.toLowerCase()
                  ?.includes(value?.toLowerCase() ?? '')
              )
            )
          );
      },
      (err) => {}
    );
  this.subscription$.push(SeriviceTypeDropdown);

    this.getCountryDropdown();
    // this.getPhysicianDrpdwn();
    // this.getReferralDrpdwn();
    this.patientSearchForm.patchValue({
      drpSearchPatient: this.patientId,
    });
  }
  planDropDowns() {
    //Get Provider list
    const providerList = this.providerService
      .getListV1(new PagedAndSortedResultRequestDto())
      .subscribe((response) => {
        response?.items?.forEach((element) => {
          this.drpProviders.push({
            id: element?.id,
            name: element?.fullName,
            shortCodeId: element?.shortCodeId,
            fullName: element?.fullName,
            npi: element?.npi,
          });
        });
        this.drpProviders.sort((a, b) => a.name.localeCompare(b.name));
        this.drpProviderSearch = this.drpProviders;
      });
    this.subscription$.push(providerList);

    //   const providerList = this.providerService.drpMasterProvider()
    //   .subscribe((response) => {
    //     response?.items?.forEach((element) => {
    //       this.drpProviders.push({
    //         id: element?.id,
    //         name: element?.fullName,
    //         shortCodeId: element?.shortCodeId,
    //         fullName: element?.fullName,
    //         npi: element?.npi,
    //       });
    //     });
    //     this.drpProviderSearch = this.drpProviders;
    //   });
    // this.subscription$.push(providerList);

    this.getPrimaryPayerList();
    this.facilityDropdown();
    this.getSecPayerList();
    this.getTerPayerList();

    //Get branch list
    const getBranchList = this.branchService
      .getBranchList(this.tenantId)
      .subscribe(
        (response) => {
          response?.forEach((element) => {
            this.drpBranch.push({
              id: element.id,
              organizationUnitName: element.organizationUnitName,
            });
          });

          this.branchList = this.planForm
            ?.get('selectBranch')
            ?.valueChanges?.pipe(
              startWith(''),
              map((value) =>
                this.drpBranch?.filter((option) =>
                  option?.organizationUnitName
                    ?.toLowerCase()
                    ?.includes(value?.toLowerCase() ?? '')
                )
              )
            );
        },
        (err) => {
          const data: HttpErrorResponse = err;
          this.toastr.error(data?.error?.error?.message, 'Error');
        }
      );
    this.subscription$.push(getBranchList);
  }
  cityDrpDownMessage() {
    if (
      this.demoForm.value.txtState === '' ||
      this.demoForm.value.txtState === null ||
      this.demoForm.value.txtState === undefined
    ) {
      return 'No Matches found';
    } else if (
      this.demoForm.value.txtCityFilter === '' ||
      this.demoForm.value.txtCityFilter === null ||
      this.demoForm.value.txtCityFilter === undefined
        ? this.demoForm.value.txtCityFilter !== '' &&
          this.demoForm.value.txtCityFilter !== null &&
          this.demoForm.value.txtCityFilter !== undefined
        : this.demoForm.value.txtCityFilter.trim().length > 40
    ) {
      return 'maximum 40 characters only allowed';
    } else {
      return 'Enter a City Name';
    }
  }
  drpDownMessage() {
    if (this.isdrpLoading === false) {
      return 'No Matches found';
    } else {
      return 'Loading...';
    }
  }
  drpDownMessageV1() {
    if (this.isSecdrpLoading === false) {
      return 'No Matches found';
    } else {
      return 'Loading...';
    }
  }
  drpDownMessageV2() {
    if (this.isTerdrpLoading === false) {
      return 'No Matches found';
    } else {
      return 'Loading...';
    }
  }
  getPrimaryPayerList() {
    //Get Payer list
    this.isdrpLoading = true;
    let eligibilityMode = 4;
    this.payerDetailService
      .getdrpPayerByIModeV1(eligibilityMode, '', '')
      .subscribe(
        (response) => {
          this.selectPayorList = response;
          this.isdrpLoading = false;
          this.selectPayorList = response.map((x) => {
            x['payerShortCodeList'] =
              (x?.payerCode === null ||
              x?.payerCode === '' ||
              (x?.payerCode).toLowerCase() == 'null'
                ? '-'
                : x?.payerCode) +
              ' ' +
              '/' +
              ' ' +
              x?.payerName +
              ' ' +
              '/' +
              ' ' +
              (x?.code === null ||
              x?.code === '' ||
              (x?.code).toLowerCase() == 'null'
                ? '-'
                : x?.code);
            return x;
          });

          this.filteredPrimaryPayors = this.planForm
            .get('primaryPayor')
            ?.valueChanges.pipe(
              startWith(''),
              map((value) =>
                this.selectPayorList?.filter((option) =>
                  option?.payerShortCodeList
                    ?.toLowerCase()
                    ?.includes(value?.toLowerCase() ?? '')
                )
              )
            );

          // this.filteredFacility = this.planForm
          //   .get('selectFacility')
          //   ?.valueChanges.pipe(
          //     startWith(''),
          //     map((value) =>
          //       this.selectPayorList?.filter((option) =>
          //         option?.payerShortCodeList
          //           ?.toLowerCase()
          //           ?.includes(value?.toLowerCase() ?? '')
          //       )
          //     )
          //   );
        },
        (err) => {
          this.isdrpLoading = true;
        }
      );
  }
  getSecPayerList() {
    this.isSecdrpLoading = true;
    let eligibilityMode = 4;
    this.payerDetailService
      .getdrpPayerByIModeV1(eligibilityMode, '', '')
      .subscribe((response) => {
        this.ltSecondaryPayers = response;
        this.isSecdrpLoading = false;
        this.ltSecondaryPayers = response.map((x) => {
          x['payerShortCodeList'] =
            (x?.payerCode === null ||
            x?.payerCode === '' ||
            (x?.payerCode).toLowerCase() == 'null'
              ? '-'
              : x?.payerCode) +
            ' ' +
            '/' +
            ' ' +
            x?.payerName +
            ' ' +
            '/' +
            ' ' +
            (x?.code === null ||
            x?.code === '' ||
            (x?.code).toLowerCase() == 'null'
              ? '-'
              : x?.code);
          return x;
        });
        this.filteredSecondaryPayors = this.planForm
          .get('secondaryPayor')
          ?.valueChanges.pipe(
            startWith(''),
            map((value) =>
              this.ltSecondaryPayers?.filter((option) =>
                option?.payerShortCodeList
                  ?.toLowerCase()
                  ?.includes(value?.toLowerCase() ?? '')
              )
            )
          );
      });
  }
  getTerPayerList() {
    this.isTerdrpLoading = true;
    let eligibilityMode = 4;
    this.payerDetailService
      .getdrpPayerByIModeV1(eligibilityMode, '', '')
      .subscribe((response) => {
        this.ltTertiaryPayers = response;
        this.isTerdrpLoading = false;
        this.ltTertiaryPayers = response.map((x) => {
          x['payerShortCodeList'] =
            (x?.payerCode === null ||
            x?.payerCode === '' ||
            (x?.payerCode).toLowerCase() == 'null'
              ? '-'
              : x?.payerCode) +
            ' ' +
            '/' +
            ' ' +
            x?.payerName +
            ' ' +
            '/' +
            ' ' +
            (x?.code === null ||
            x?.code === '' ||
            (x?.code).toLowerCase() == 'null'
              ? '-'
              : x?.code);
          return x;
        });

        this.filteredTertiaryPayors = this.planForm
          .get('tertiaryPayor')
          ?.valueChanges.pipe(
            startWith(''),
            map((value) =>
              this.ltTertiaryPayers?.filter((option) =>
                option?.payerShortCodeList
                  ?.toLowerCase()
                  ?.includes(value?.toLowerCase() ?? '')
              )
            )
          );
      });
  }
  facilityDropdown() {
    const patientDropdownInput: PatientDropdowns[] = [
      PatientDropdowns.Facilities,
    ];
    const patientDropdown = this.patientDropdownService
      .getPatientDropdownsByInput(patientDropdownInput)
      .subscribe(
        (facilityResponse) => {
          let response: PatientMasterDropdownDTO = facilityResponse;
          this.drpFacilityList = response?.facilities ?? [];
          this.filteredFacility = this.planForm
            .get('selectFacility')
            ?.valueChanges.pipe(
              startWith(''),
              map((value) =>
                this.drpFacilityList?.filter((option) =>
                  option?.name
                    ?.toLowerCase()
                    ?.includes(value?.toLowerCase() ?? '')
                )
              )
            );
        },
        (err) => {
          const data: HttpErrorResponse = err;
          this.toastr.error(data?.error?.error?.message);
        }
      );
    this.subscription$.push(patientDropdown);
  }
  payerSearch(value: string, eventKey?: string) {
    if (
      eventKey !== 'ArrowDown' &&
      eventKey !== 'ArrowUp' &&
      eventKey !== 'ArrowRight' &&
      eventKey !== 'ArrowLeft'
    ) {
      //Get Payer list
      this.isdrpLoading = true;
      if (this.priPayerDetails) {
        this.priPayerDetails.unsubscribe();
      }
      let eligibilityMode = 4;
      this.priPayerDetails = this.payerDetailService
        .getdrpPayerByIModeV1(eligibilityMode, value, this.payerId)
        .subscribe((response) => {
          this.selectPayorList = response;
          this.isdrpLoading = false;
          this.selectPayorList = response.map((x) => {
            x['payerShortCodeList'] =
              (x?.payerCode === null ||
              x?.payerCode === '' ||
              (x?.payerCode).toLowerCase() == 'null'
                ? '-'
                : x?.payerCode) +
              ' ' +
              '/' +
              ' ' +
              x?.payerName +
              ' ' +
              '/' +
              ' ' +
              (x?.code === null ||
              x?.code === '' ||
              (x?.code).toLowerCase() == 'null'
                ? '-'
                : x?.code);
            return x;
          });

          this.filteredPrimaryPayors = this.planForm
            .get('primaryPayor')
            ?.valueChanges.pipe(
              startWith(''),
              map((value) =>
                this.selectPayorList?.filter((option) =>
                  option?.payerShortCodeList
                    ?.toLowerCase()
                    ?.includes(value?.toLowerCase() ?? '')
                )
              )
            );
        });
    }
  }
  payerSearchV1(value: string, eventKey?: string) {
    if (
      eventKey !== 'ArrowDown' &&
      eventKey !== 'ArrowUp' &&
      eventKey !== 'ArrowRight' &&
      eventKey !== 'ArrowLeft'
    ) {
      this.isSecdrpLoading = true;
      //Get Payer list
      if (this.secPayerDetails) {
        this.secPayerDetails.unsubscribe();
      }
      let eligibilityMode = 4;
      this.secPayerDetails = this.payerDetailService
        .getdrpPayerByIModeV1(eligibilityMode, value, this.secPayerId)
        .subscribe((response) => {
          this.isSecdrpLoading = false;
          this.ltSecondaryPayers = response;
          this.ltSecondaryPayers = response.map((x) => {
            x['payerShortCodeList'] =
              (x?.payerCode === null ||
              x?.payerCode === '' ||
              (x?.payerCode).toLowerCase() == 'null'
                ? '-'
                : x?.payerCode) +
              ' ' +
              '/' +
              ' ' +
              x?.payerName +
              ' ' +
              '/' +
              ' ' +
              (x?.code === null ||
              x?.code === '' ||
              (x?.code).toLowerCase() == 'null'
                ? '-'
                : x?.code);
            return x;
          });

          this.filteredSecondaryPayors = this.planForm
            .get('secondaryPayor')
            ?.valueChanges.pipe(
              startWith(''),
              map((value) =>
                this.ltSecondaryPayers?.filter((option) =>
                  option?.payerShortCodeList
                    ?.toLowerCase()
                    ?.includes(value?.toLowerCase() ?? '')
                )
              )
            );
        });
    }
  }
  payerSearchV2(value: string, eventKey?: string) {
    if (
      eventKey !== 'ArrowDown' &&
      eventKey !== 'ArrowUp' &&
      eventKey !== 'ArrowRight' &&
      eventKey !== 'ArrowLeft'
    ) {
      this.isTerdrpLoading = true;
      //Get Payer list
      if (this.terPayerDetails) {
        this.terPayerDetails.unsubscribe();
      }
      let eligibilityMode = 4;
      this.terPayerDetails = this.payerDetailService
        .getdrpPayerByIModeV1(eligibilityMode, value, this.terPayerId)
        .subscribe((response) => {
          this.ltTertiaryPayers = response;
          this.isTerdrpLoading = false;
          this.ltTertiaryPayers = response.map((x) => {
            x['payerShortCodeList'] =
              (x?.payerCode === null ||
              x?.payerCode === '' ||
              (x?.payerCode).toLowerCase() == 'null'
                ? '-'
                : x?.payerCode) +
              ' ' +
              '/' +
              ' ' +
              x?.payerName +
              ' ' +
              '/' +
              ' ' +
              (x?.code === null ||
              x?.code === '' ||
              (x?.code).toLowerCase() == 'null'
                ? '-'
                : x?.code);
            return x;
          });

          this.filteredTertiaryPayors = this.planForm
            .get('tertiaryPayor')
            ?.valueChanges.pipe(
              startWith(''),
              map((value) =>
                this.ltTertiaryPayers?.filter((option) =>
                  option?.payerShortCodeList
                    ?.toLowerCase()
                    ?.includes(value?.toLowerCase() ?? '')
                )
              )
            );
        });
    }
  }
  //To get the Chart Id for the new patient
  getChartId() {
    const charIdMethod = this.inboundDocUploadService
      .generateChartId(this.branchId)
      .subscribe(
        (response) => {
          this.demoForm.patchValue({
            txtChart: response,
          });
          this.txtChartV1 = response;
        },
        (err) => {}
      );
    this.subscription$.push(charIdMethod);
  }
  //To set the Valid Dates for Birth Date field
  loadValidDates() {
    this.BirthdateMaxDate = new Date();
    this.BirthdateMaxDate.setDate(this.BirthdateMaxDate.getDate());
    this.RxdateMax = this.BirthdateMaxDate;
    this.BirthdateMinDate = new Date();
    this.BirthdateMinDate.setFullYear(
      this.BirthdateMinDate.getFullYear() - 120
    );
    var oneYearAgo = new Date();
    oneYearAgo.setFullYear(this.BirthdateMaxDate.getFullYear() - 1);
    this.RxdateMin = oneYearAgo;
  }

  //Whitespace Validator
  public noWhitespaceValidator(control: FormControl) {
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { whitespace: true };
  }

  //Update Character Count for TextArea
  updateCharacterCount(maxlength: number) {
    // Ensure the text length does not exceed the maximum length
    if (this.notesText?.length > maxlength) {
      this.notesText = this.notesText?.substr(0, maxlength);
    }
  }
  clearPrimaryPolicy(event: Event) {
    event.stopPropagation();
    this.primaryPayorId = this.planForm.value?.drpPrimaryPayor;
    this.planForm.patchValue({
      drpPrimaryPayor: '',
    });
    this.getPrimaryPayerList();
  }
  //Secondary Policy Change
  onSecondaryPolicyChange() {
    this.secondaryPayorId = this.planForm.value?.drpSecondaryPayor;
    this.checkPayorUniqnessV1(this.secondaryPayorId);
    if (
      (this.planForm.value?.drpSecondaryPayor !== null &&
        this.planForm.value?.drpSecondaryPayor !== '') ||
      (this.planForm.value?.txtSecondaryPolicyNo !== null &&
        this.planForm.value?.txtSecondaryPolicyNo !== '') ||
      (this.planForm.value?.txtSecondaryGroupNo !== null &&
        this.planForm.value?.txtSecondaryGroupNo !== '')
    ) {
      this.planForm?.controls?.drpSecondaryPayor?.setValidators([
        Validators.required,
      ]);
      this.planForm?.controls?.txtSecondaryPolicyNo?.setValidators([
        Validators.required,
        noWhitespaceValidator,
      ]);
      // this.planForm?.controls?.txtSecondaryGroupNo?.setValidators([
      //   Validators.required,
      //   noWhitespaceValidator,
      // ]);
      this.secondaryValidator = true;
    } else {
      this.planForm?.controls?.drpSecondaryPayor?.setValidators([
        Validators.nullValidator,
      ]);
      this.planForm?.controls?.txtSecondaryPolicyNo?.setValidators([
        Validators.nullValidator,
      ]);
      // this.planForm?.controls?.txtSecondaryGroupNo?.setValidators([
      //   Validators.nullValidator,
      // ]);
      this.secondaryValidator = false;
    }
    this.planForm?.controls?.drpSecondaryPayor?.updateValueAndValidity();
    this.planForm?.controls?.txtSecondaryPolicyNo?.updateValueAndValidity();
    // this.planForm?.controls?.txtSecondaryGroupNo?.updateValueAndValidity();
  }
  clearSecondaryPolicy(event: Event) {
    event.stopPropagation();
    this.secondaryPayorId = this.planForm.value?.drpSecondaryPayor;
    this.planForm.patchValue({
      drpSecondaryPayor: '',
    });
    this.onSecondaryPolicyChange();
    this.getSecPayerList();
  }
  //On Tertiary Policy Change
  onTertiaryPolicyChange() {
    this.tertiaryPayorId = this.planForm.value?.drpTertiaryPayor;
    this.getTerPayerList();
    this.checkPayorUniqnessV2(this.tertiaryPayorId);
    if (
      (this.planForm.value?.drpTertiaryPayor !== null &&
        this.planForm.value?.drpTertiaryPayor !== '') ||
      (this.planForm.value?.txtTertiaryPolicyNo !== null &&
        this.planForm.value?.txtTertiaryPolicyNo !== '') ||
      (this.planForm.value?.txtTertiaryGroupNo !== null &&
        this.planForm.value?.txtTertiaryGroupNo !== '')
    ) {
      this.planForm?.controls?.drpTertiaryPayor?.setValidators([
        Validators.required,
      ]);
      this.planForm?.controls?.txtTertiaryPolicyNo?.setValidators([
        Validators.required,
        noWhitespaceValidator,
      ]);
      // this.planForm?.controls?.txtTertiaryGroupNo?.setValidators([
      //   Validators.required,
      //   noWhitespaceValidator,
      // ]);
      this.tertiaryValidator = true;
    } else {
      this.planForm?.controls?.drpTertiaryPayor?.setValidators([
        Validators.nullValidator,
      ]);
      this.planForm?.controls?.txtTertiaryPolicyNo?.setValidators([
        Validators.nullValidator,
      ]);
      // this.planForm?.controls?.txtTertiaryGroupNo?.setValidators([
      //   Validators.nullValidator,
      // ]);
      this.tertiaryValidator = false;
    }
    this.planForm?.controls?.drpTertiaryPayor?.updateValueAndValidity();
    this.planForm?.controls?.txtTertiaryPolicyNo?.updateValueAndValidity();
    // this.planForm?.controls?.txtTertiaryGroupNo?.updateValueAndValidity();
  }
  //To CLear the Tertiary Policy
  clearTertiaryPolicy(event: Event) {
    event.stopPropagation();
    this.tertiaryPayorId = this.planForm.value?.drpTertiaryPayor;
    this.planForm.patchValue({
      drpTertiaryPayor: '',
    });
    this.onTertiaryPolicyChange();
  }
  //On Facility Change
  OnFacilityChange(event: any) {
    const payorList = event.value;
    let splitValue = payorList.split('/');
    this.facilityId = splitValue[0];
  }
  //Country Dropdown
  getCountryDropdown() {
    this.drpCountries = [];
    this.country = defaultCountry.US;
    const getCountrySubscription = this.regionDropdownService
      .getAllCountriesByInput(new PagedAndSortedResultRequestDto())
      .pipe(
        map((response) => {
          this.drpCountries = response?.items ?? [];
          this.phoneCode =
            '+' +
              response?.items?.filter(
                (x) => x.countryShortName === defaultCountry.US
              )?.[0]?.countryPhoneCode ?? '';
          return response;
        }),
        tap((x) => {
          this.country = defaultCountry.US;

          this.demoForm?.patchValue(
            { txtCountry: defaultCountry.US },
            { emitEvent: true }
          );
        }),
        map((x) => {
          this.onChangeState(defaultCountry.US);
        })
      )
      .subscribe(
        (response) => {
          this.filteredCountries = this.demoForm
            .get('txtCountryFilter')
            .valueChanges.pipe(
              startWith(''),
              map((value) =>
                this.drpCountries.filter((option) =>
                  option?.countryName
                    ?.toLowerCase()
                    ?.includes(value?.toLowerCase() ?? '')
                )
              )
            );
        },
        (err) => {
          const data: HttpErrorResponse = err;
          this.toastr.error(data?.error?.error?.message, 'Error');
        }
      );
    this.subscription$.push(getCountrySubscription);
  }
  // On Change State Dropdown
  onChangeState(value: string | null | undefined): boolean {
    this.demoForm.patchValue({
      txtState: null,
      txtCity: null,
    });
    this.filteredCities = null;
    this.drpStates = [];
    this.drpCities = [];
    value !== null &&
      value !== undefined &&
      this.regionDropdownService
        .getAllStatesByCountryNameByCountryShortName(value)
        .subscribe(
          (response) => {
            this.drpStates = response?.items ?? [];
            this.phoneCode = response?.items[0]?.countryPhoneCode;
            this.filteredStates = this.demoForm
              ?.get('txtStateFilter')
              ?.valueChanges.pipe(
                startWith(''),
                map((value) =>
                  this.drpStates.filter((option) =>
                    option?.stateName
                      ?.toLowerCase()
                      ?.includes(value?.toLowerCase() ?? '')
                  )
                )
              );
          },
          (err) => {
            const data: HttpErrorResponse = err;
            this.toastr.error(data?.error?.error?.message, 'Error');
          }
        );
    return this.drpStates.length !== 0;
  }
  //On Change City Dropdown
  onChangeCity(state: string | null | undefined, country: string): boolean {
    this.demoForm.patchValue({
      txtCity: null,
    });
    this.drpCities = [];
    state !== null &&
      state !== undefined &&
      this.regionDropdownService
        .getAllCitiesByCountryAndStateNameByCountryShortNameAndStateShortName(
          country,
          state
        )
        .subscribe(
          (response) => {
            this.drpCities = response?.items;
            this.filteredCities = this.demoForm
              .get('txtCityFilter')
              .valueChanges.pipe(
                startWith(''),
                map((value) =>
                  this.drpCities.filter((option) =>
                    option?.cityName
                      ?.toLowerCase()
                      ?.includes(value?.toLowerCase() ?? '')
                  )
                )
              );
          },
          (err) => {
            const data: HttpErrorResponse = err;
            this.toastr.error(data?.error?.error?.message, 'Error');
          }
        );
    return this.drpCities.length !== 0;
  }
  onKeyPress(event: KeyboardEvent) {
    const isAlphabet = /^[a-zA-Z]$/.test(event.key);
    if (!isAlphabet) {
      event.preventDefault(); // Prevent the input if the key is not an alphabet
    }
  }
  onCityKeyPress(event: KeyboardEvent) {
    const isAlphabetOrSpace = /^[a-zA-Z\s]$/.test(event.key);
    if (!isAlphabetOrSpace) {
      event.preventDefault(); // Prevent the input if the key is not an alphabet or space
    }
  }
  focusOut() {
    const searchValue = this.demoForm.get('txtCityFilter').value;
    const stateValue = this.demoForm.get('txtState').value;
    let noEntriesFound =
      this.drpCities?.filter((a) =>
        a.cityName
          ?.trim()
          ?.toLowerCase()
          ?.includes(searchValue?.trim()?.toLowerCase())
      ).length == 0
        ? true
        : false;
    if (
      noEntriesFound &&
      searchValue &&
      searchValue.length <= 40 &&
      stateValue !== undefined &&
      stateValue !== null &&
      stateValue !== ''
    ) {
      Swal.fire({
        title: 'City not registered in Google Api',
        text: 'Do you want to include this city?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#D3D3D3',
        confirmButtonText: 'Add',
      }).then((result) => {
        if (result.isConfirmed) {
          this.addNewCity(searchValue);
        }
      });
    } else {
      if (searchValue && noEntriesFound) {
        this.citySelect.close();
      }
    }
  }

  addNewCity(cityValue: string) {
    if (
      cityValue !== undefined &&
      cityValue !== null &&
      cityValue !== '' &&
      cityValue.length <= 40
    ) {
      const newCityName = cityValue.trim().toLowerCase();
      var alreadyAdd = this.drpCities?.filter(
        (obj) => obj.cityName.trim().toLowerCase() === newCityName
      );
      if (alreadyAdd.length === 0) {
        let newCity = {
          cityName: cityValue.trim(),
          cityId: 1,
        };

        this.drpCities?.push(newCity);
        this.drpCities.sort((a, b) => a.cityName.localeCompare(b.cityName));
        this.demoForm.patchValue({
          txtCity: cityValue,
        });
        this.citySelect.open();
        setTimeout(() => {
          this.citySelect.close();
        }, 100);
      }
    }
  }
  //Clear State dropdown
  clearCountry() {
    this.country = '';
    this.State = '';

    this.demoForm.patchValue({ txtCountry: '', txtState: '' });
    this.onChangeState('');
  }
  //Clear State dropdown
  clearState() {
    this.State = '';
    this.city = '';
    this.demoForm.patchValue({ txtState: '', txtCity: '' });
    this.onChangeCity('', '');
  }
  //Clear City dropdown
  clearCity() {
    // const objWithIdIndex = this.drpCities.findIndex((obj) => obj.cityId === 1);
    // if(objWithIdIndex !== -1){
    // setTimeout(() => {
    // this.drpCities.splice(objWithIdIndex, 1);
    // }, 100);
    // }
    this.City = '';
    this.demoForm.patchValue({ txtCity: '' });
  }

  onSelectPrimaryPayor(event: any) {
    const payorList = event.value;
    let splitValue = payorList.split('/');
    this.primaryPayorId = splitValue[0];
    this.checkPayorUniqness(this.primaryPayorId);
  }
  checkPayorUniqness(payerId: string) {
    if (payerId !== '' && payerId !== defaultGuid) {
      this.PatientAddressService.getPayorCheckAvailablity(payerId).subscribe(
        (response) => {
          this.isUniq = response;
        },
        (err) => {
          const data: HttpErrorResponse = err;
          this.toastr.error(data?.error?.error?.message);
        }
      );
    }
  }
  checkPayorUniqnessV1(payerId: string) {
    if (payerId !== '' && payerId !== defaultGuid) {
      this.PatientAddressService.getPayorCheckAvailablity(payerId).subscribe(
        (response) => {
          this.isUniqV1 = response;
        },
        (err) => {
          const data: HttpErrorResponse = err;
          this.toastr.error(data?.error?.error?.message);
        }
      );
    }
  }
  checkPayorUniqnessV2(payerId: string) {
    if (payerId !== '' && payerId !== defaultGuid) {
      this.PatientAddressService.getPayorCheckAvailablity(payerId).subscribe(
        (response) => {
          this.isUniqV2 = response;
        },
        (err) => {
          const data: HttpErrorResponse = err;
          this.toastr.error(data?.error?.error?.message);
        }
      );
    }
  }
  //Clear Provider Dropdown
  clearProvider() {
    this.provider = '';
    this.planForm?.patchValue({ txtProvider: '' });
  }

  reloadPhysican(event, searchReferral) {
    const ignoredKeys = ['ArrowUp', 'ArrowDown', 'ArrowLeft', 'ArrowRight'];
    // Ignore arrow keys
    if (ignoredKeys.includes(event.key)) {
      console.log('ignore arrow keys');
      return;
    }

    const value = event ? event.target.value : '';
    console.log('value', value);

    if (value.length >= 3 || value.length != '' || value.length != null ) {
      setTimeout(() => {
        this.iPhysicianLoading = true;
      }, 100);

      if (searchReferral !== true) {
        this.demoForm.patchValue({
          drpPhysician: '',
        });
      }
      const physicianValue = this.demoForm.value.drpReferral || defaultGuid;
      if (this.physicianDropdown) {
        this.physicianDropdown.unsubscribe();
        this.iPhysicianLoading = false;
      }
      this.filteredPhysicians = null;
      this.physicianDropdown = this.doctorService.
        // .getPhysicianDropdown(
        //   demo.drpReferral === null ? '' : demo.drpReferral,
        //   value
        // )
        getAllPhysiciansByPracticeIDAndFullNameAndGDoctorAndSSearch(physicianValue, value, '', '')
        .subscribe(
          (response) => {
            setTimeout(() => {
              this.iPhysicianLoading = false;
            }, 100);
            this.physicianDrpdwn = response;
            this.lstDoctor = response;
            //Primary Doctor Dropdown
            this.filteredPhysicians = this.demoForm
              ?.get('txtPhysicianFilter')
              ?.valueChanges.pipe(
                startWith(''),
                map((value) =>
                  this.physicianDrpdwn?.filter((option) =>
                    option?.doctorName
                      ?.toLowerCase()
                      ?.includes(value?.toLowerCase() ?? '')
                  )
                )
              );
          },
          (err) => {
            setTimeout(() => {
              this.iPhysicianLoading = false;
            }, 100);
            const data: HttpErrorResponse = err;
            this.toastr.error(data?.error?.error?.message);
          }
        );
    }
  }
  checkExistDocument(Value) {
    this.isExistDocChecked = false;
    let demo = this.demoForm.value;
    if (
      demo.txtBirthdate !== undefined &&
      demo.txtBirthdate !== null &&
      demo.txtBirthdate !== '' &&
      demo.txtLastName !== '' &&
      demo.txtLastName !== undefined &&
      demo.txtLastName !== null
    ) {
      if (this.checkDateExist) {
        this.checkDateExist.unsubscribe();
      }
      this.isExistDocMessage = true;
      this.checkDateExist = this.inboundDocumentService
        .checkExistDocument(
          this.documentId,
          Value,
          this.getFormattedDate(demo.txtBirthdate)
        )
        .subscribe(
          (response) => {
            let patientList = response ?? [];

            let oldPatientExist = patientList?.some((a) => !a.isInFaxQueue);
            let faxBinPatientExists = patientList?.some((a) => a.isInFaxQueue);
            let swalText =
              oldPatientExist && !faxBinPatientExists
                ? 'Similiar Patient Exists in Patients'
                : !oldPatientExist && faxBinPatientExists
                ? 'Similiar Patient has Fax in Queue'
                : oldPatientExist && faxBinPatientExists
                ? 'Similiar Patient exist in Patients List and Fax Queue'
                : '';

            if (oldPatientExist || faxBinPatientExists) {
              Swal.fire({
                title: swalText,
                text: 'Do you want to merge?',
                icon: 'info',
                showCancelButton: true,
                showConfirmButton: faxBinPatientExists ? true : false,
                showDenyButton: oldPatientExist ? true : false,
                confirmButtonText: 'Join Fax',
                denyButtonText: 'Similiar Patient List',
                cancelButtonText: 'Cancel',
                confirmButtonColor: 'lighseagreen',
                denyButtonColor: 'lightseagreen',
                //cancelButtonColor:'lightseagreen',
                buttonsStyling: true,
                allowOutsideClick: false,
              }).then((result) => {
                if (result.isConfirmed) {
                  if (this.checkDateExist) {
                    this.checkDateExist.unsubscribe();
                  }
                  this.isExistDocChecked = true;
                  this.isExistDocMessage = false;
                  let faxPatientList = response?.filter((a) => a.isInFaxQueue);
                  const dialogRef = this.dialog.open(JoinFaxComponent, {
                    // minWidth: '60vw',
                    disableClose: true,
                    data: {
                      faxID: this.defaultFaxId,
                      docID: this.documentId,
                      branchID: this.branchId,
                      // documentName: this.blobName,
                      documentName: this.documentName,
        // documentName: null,
        selectedNode: this.selectedNode,
                      // lname:Value,
                      // dob:this.getFormattedDate(demo.txtBirthdate)
                      docList: faxPatientList,
                    },
                  });
                  dialogRef.afterClosed().subscribe((a) => {
                    if (a) {
                      window.close();
                    }
                  });
                  // } else {
                  //   this.isExistDocChecked = true;
                  //   this.isExistDocMessage = false;
                  // }
                } else if (result.isDenied) {
                  if (this.checkDateExist) {
                    this.checkDateExist.unsubscribe();
                  }
                  this.isExistDocChecked = true;
                  this.isExistDocMessage = false;
                  this.openSearchMdl();
                  let oldPatientList = response?.filter((a) => !a.isInFaxQueue);
                  this.communicationService.triggerPatientSearchData(
                    oldPatientList
                  );
                } else {
                  if (this.checkDateExist) {
                    this.checkDateExist.unsubscribe();
                  }
                  this.isExistDocChecked = true;
                  this.isExistDocMessage = false;
                }
              });
            } else {
              if (this.checkDateExist) {
                this.checkDateExist.unsubscribe();
              }
              this.isExistDocChecked = true;
              this.isExistDocMessage = false;
            }
          },
          (err) => {
            this.isExistDocMessage = false;
            const data: HttpErrorResponse = err;
            // this.toastr.error(data?.error?.error?.message);
          }
        );
    }
  }

  isAlphanumeric(key: string): boolean {
    const regex = /^[a-z0-9]$/i;
    return regex.test(key);
  }

  checkExistDocumentV1(Value) {
    this.isExistDocChecked = false;
    //value.length allowed ,date select using date picker the length undefined
    if (
      (Value.length === undefined || Value.length >= 8) &&
      this.getFormattedDate(Value) !== 'Invalid Date'
    ) {
      let demo = this.demoForm.value;
      if (
        demo.txtLastName !== undefined &&
        demo.txtLastName !== null &&
        demo.txtLastName !== '' &&
        demo.txtBirthdate !== '' &&
        demo.txtBirthdate !== undefined &&
        demo.txtBirthdate !== null
      ) {
        if (this.checkDateExist) {
          this.checkDateExist.unsubscribe();
        }
        this.isExistDocMessage = true;
        this.checkDateExist = this.inboundDocumentService
          .checkExistDocument(
            this.documentId,
            demo.txtLastName,
            this.getFormattedDate(Value)
          )
          .subscribe(
            (response) => {
              this.isExistDocMessage = false;
              let patientList = response ?? [];

              let oldPatientExist = patientList?.some((a) => !a.isInFaxQueue);
              let faxBinPatientExists = patientList?.some(
                (a) => a.isInFaxQueue
              );

              let swalText =
                oldPatientExist && !faxBinPatientExists
                  ? 'Similiar Patient Exists in Patients'
                  : !oldPatientExist && faxBinPatientExists
                  ? 'Similiar Patient has Fax in Queue'
                  : oldPatientExist && faxBinPatientExists
                  ? 'Similiar Patient exist in Patients List and Fax Queue'
                  : '';

              if (
                (oldPatientExist || faxBinPatientExists) &&
                !this.swalOpened
              ) {
                this.swalOpened = true;
                Swal.fire({
                  title: swalText,
                  text: 'Do you want to merge?',
                  icon: 'info',
                  showCancelButton: true,
                  showConfirmButton: faxBinPatientExists ? true : false,
                  showDenyButton: oldPatientExist ? true : false,
                  confirmButtonText: 'Join Fax',
                  denyButtonText: 'Similiar Patient List',
                  cancelButtonText: 'Cancel',
                  confirmButtonColor: 'lighseagreen',
                  denyButtonColor: 'lightseagreen',
                  allowOutsideClick: false,
                  buttonsStyling: true,
                }).then((result) => {
                  if (result.isConfirmed) {
                    if (this.checkDateExist) {
                      this.checkDateExist.unsubscribe();
                    }
                    this.swalOpened = false;
                    this.isExistDocChecked = true;
                    this.isExistDocMessage = false;
                    let faxPatientList = response?.filter(
                      (a) => a.isInFaxQueue
                    );
                    const dialogRef = this.dialog.open(JoinFaxComponent, {
                      minWidth: '60vw',
                      disableClose: true,
                      data: {
                        faxID: this.defaultFaxId,
                        docID: this.documentId,
                        branchID: this.branchId,
                        // documentName: this.blobName,
                        documentName: this.documentName,
        // documentName: null,
        selectedNode: this.selectedNode,
                        // lname:Value,
                        // dob:this.getFormattedDate(demo.txtBirthdate)
                        docList: faxPatientList,
                      },
                    });
                    dialogRef.afterClosed().subscribe((a) => {
                      if (a) {
                        window.close();
                      }
                    });
                  } else if (result.isDenied) {
                    if (this.checkDateExist) {
                      this.checkDateExist.unsubscribe();
                    }
                    this.swalOpened = false;
                    this.isExistDocChecked = true;
                    this.isExistDocMessage = false;
                    this.openSearchMdl();
                    let oldPatientList = response?.filter(
                      (a) => !a.isInFaxQueue
                    );
                    this.communicationService.triggerPatientSearchData(
                      oldPatientList
                    );
                  } else {
                    if (this.checkDateExist) {
                      this.checkDateExist.unsubscribe();
                    }
                    this.swalOpened = false;
                    this.isExistDocChecked = true;
                    this.isExistDocMessage = false;
                  }
                });
              } else {
                if (this.checkDateExist) {
                  this.checkDateExist.unsubscribe();
                }
                this.swalOpened = false;
                this.isExistDocChecked = true;
                this.isExistDocMessage = false;
              }
            },
            (err) => {
              this.isExistDocMessage = false;
              this.swalOpened = false;
              const data: HttpErrorResponse = err;
              // this.toastr.error(data?.error?.error?.message);
            }
          );
      }
    }
  }
  getFormattedDate(date: any): string {
    return date
      ? new Date(date).toLocaleString('en-US', {
          month: '2-digit',
          day: '2-digit',
          year: 'numeric',
        })
      : '';
  }
  getReferralDrpdwn() {
    const referralDropdowns = this.doctorService
      .getPracticeDropdown('', '')
      .subscribe(
        (response) => {
          this.referralPractice = response;
          //Referring Provider Doctor Dropdown
          this.filteredReferralDoctors = this.demoForm
            ?.get('txtReferralFilter')
            ?.valueChanges.pipe(
              startWith(''),
              map((value) =>
                this.referralPractice?.filter((option) =>
                  option?.practiceName
                    ?.toLowerCase()
                    ?.includes(value?.toLowerCase() ?? '')
                )
              )
            );
        },
        (err) => {
          const data: HttpErrorResponse = err;
          this.toastr.error(data?.error?.error?.message);
          this.subscription$.push(referralDropdowns);
        }
      );
  }

  referralSearchV1(value) {
    if (this.referralDropdown) {
      this.referralDropdown.unsubscribe();
    }
    this.referralDropdown = this.doctorService
      .getPracticeDropdown(value, this.ReferralId)
      .subscribe(
        (response) => {
          this.referralPractice = response;
          //Referring Provider Doctor Dropdown
          this.filteredReferralDoctors = this.demoForm
            ?.get('txtReferralFilter')
            ?.valueChanges.pipe(
              startWith(''),
              map((value) =>
                this.referralPractice?.filter((option) =>
                  option?.practiceName
                    ?.toLowerCase()
                    ?.includes(value?.toLowerCase() ?? '')
                )
              )
            );
        },
        (err) => {
          const data: HttpErrorResponse = err;
          this.toastr.error(data?.error?.error?.message);
        }
      );
  }
  changeTab(tab: string) {
    if (this.VeryFirstTime === true) {
      this.VeryFirstTime = false;
      let demographicsTab = document.getElementById('demographics');
      if (demographicsTab) {
        let anchorElement = demographicsTab.querySelector('a.nav-link.active');
        if (anchorElement) {
          anchorElement.classList.remove('active'); // Remove the 'active' class
        }
      }
    }

    if (this.activeTab === tab) {
      return;
    }
    this.activeTab = tab;
    if (this.activeTab === 'demographics') {
      if (!this.isDemoTabAPICalls) {
        this.isDemoTabAPICalls = true;
        this.notLoadSort = true;

        if (
          this.patientId == defaultGuid ||
          this.patientId == null ||
          this.patientId == '0'
        ) {
          this.getChartId();
        }
        if (this.isDemographicsDoneV1 === true) {
          this.loadPatientDemoForm(this.documentId, false);
        }
      }
    } else if (this.activeTab === 'plans') {
      if (!this.isPalnTabAPICalls) {
        this.isPalnTabAPICalls = true;
        this.planDropDowns();
        // this.changeCashCase(false);
      }
    } else if (this.activeTab === 'document') {
      if (!this.isDocumentLoadBefore) {
        this.isDocumentLoadBefore = true;
        this.loadOverAllSortingAPI(1);
      }
    }
  }

  displayErrprMessage() {
    // this.files = Object.keys(pFileList).map(key => pFileList[key]);
    this._snackBar.open(
      'Checking, if patient has existing fax in bin!',
      'Close',
      {
        duration: 2000,
      }
    );
  }

  openCheckListModel() {
    const dialogRef = this.matDialog.open(DocumentCheckListComponent, {
      // height: '75%',
      width: '80%',
      disableClose: true,
    });
  }
  //Save Or Update New Setup Details
  saveNewSetupDetails() {
    const formValue = this.demoForm.value;
    this.isSaveBtnDisabled = true;
    let secondaryPayorDetails: policyInsurance;
    let tertiaryPayorDetails: policyInsurance;
    //Secondary Policy Details
    if (
      formValue?.drpSecondaryPayor === '' &&
      formValue?.txtSecondaryPolicyNo === '' &&
      formValue?.txtSecondaryGroupNo === ''
    ) {
      secondaryPayorDetails = null;
    } else {
      secondaryPayorDetails = {
        payerId: formValue?.drpSecondaryPayor,
        policyNo: formValue?.txtSecondaryPolicyNo,
        groupNo: formValue?.txtSecondaryGroupNo,
        payorName: '',
        payorId: '',
      };
    }

    if (
      formValue?.drpTertiaryPayor === '' &&
      formValue?.txtTertiaryGroupNo === '' &&
      formValue?.txtTertiaryPolicyNo === ''
    ) {
      tertiaryPayorDetails = null;
    } else {
      tertiaryPayorDetails = {
        payerId: formValue?.drpTertiaryPayor,
        policyNo: formValue?.txtTertiaryPolicyNo,
        groupNo: formValue?.txtTertiaryGroupNo,
        payorName: '',
        payorId: '',
      };
    }
    let homeNo = null;
    let workNo = null;
    let cellNo = null;
    if (formValue?.txtHomeNo) {
      homeNo =
        formValue?.txtHomeNo.length === 10
          ? formValue?.txtHomeNo.slice(0, 3) +
            '-' +
            formValue?.txtHomeNo.slice(3, 6) +
            '-' +
            formValue?.txtHomeNo.slice(6, 15)
          : formValue?.txtHomeNo;
    }
    if (formValue?.txtWorkNo) {
      workNo =
        formValue?.txtWorkNo.length === 10
          ? formValue?.txtWorkNo.slice(0, 3) +
            '-' +
            formValue?.txtWorkNo.slice(3, 6) +
            '-' +
            formValue?.txtWorkNo.slice(6, 15)
          : formValue?.txtWorkNo;
    }
    if (formValue?.txtCellNo) {
      cellNo =
        formValue?.txtCellNo.length === 10
          ? formValue?.txtCellNo.slice(0, 3) +
            '-' +
            formValue?.txtCellNo.slice(3, 6) +
            '-' +
            formValue?.txtCellNo.slice(6, 15)
          : formValue?.txtCellNo;
    }
    let patientDetails: NewPatientDTO = {
      chartId: formValue?.txtChart,
      patientId: this.patientId,
      defaultPatientId: '',
      firstName: formValue?.txtFirstName ?? '',
      middleName: formValue?.txtMiddleName ?? '',
      lastName: formValue?.txtLastName ?? '',
      gender: formValue?.drpGender ?? '',
      documentId: this.documentId ?? defaultGuid,
      birthDate: formValue?.txtBirthdate ?? '',
      comment: formValue?.txtComments ?? '',
      emailId: formValue?.txtEmail.toLowerCase() ?? '',
      address: formValue?.txtAddress ?? '',
      postalCode: formValue?.txtPostalCode ?? '',
      city: formValue?.txtCity ?? '',
      state: formValue?.txtState ?? '',
      country: formValue?.txtCountry ?? '',
      homePhoneNo: homeNo,
      workPhoneNo: workNo,
      cellNo: cellNo,
      isStat: formValue?.tglStat == true ? 1 : 0,

      referringProviderDoctor: formValue?.drpPhysician.split('/')[0] ?? '',
      serviceType: formValue?.drpServiceType ?? defaultGuid,
      Refferal: formValue?.drpReferral ?? defaultGuid,
      facility: formValue?.drpFacility ?? defaultGuid,

      providerId: formValue?.txtProvider ?? '',
      location:
        formValue?.drpBranch == ''
          ? defaultGuid
          : formValue?.drpBranch ?? defaultGuid,
      primary: {
        payerId: formValue?.drpPrimaryPayor ?? defaultGuid,
        policyNo: formValue?.txtPrimaryPolicyNo ?? '',
        groupNo: formValue?.txtPrimaryGroupNo ?? '',
        payorName: '',
        payorId: '',
      },
      secondary: secondaryPayorDetails,
      tertiary: tertiaryPayorDetails,
    };
    this.inboundDocumentService.inserNewPatient(patientDetails).subscribe(
      (response) => {
        this.isSaveBtnDisabled = false;
        this.toastr.success('Saved Successfully', 'Success');
        this.communicationService.triggerFunctionCall(this.selectedNode);
        this.isSaveBtnDisabled = true;
        this.isNewSetupDone = 'true';
        this.changeTab('document');
        let documents = <HTMLButtonElement>document.getElementById('documents');
        documents.classList.add('active');
      },
      (err) => {
        this.isSaveBtnDisabled = false;
        this.toastr.error(err.error.error.message, 'Error');
        this.communicationService.triggerFunctionCall(this.selectedNode);
      }
    );
  }
  loadPatientDemoForm(docId: string, isReset: boolean) {
    this.isExistDocChecked = true;
    const getPatientDetails = this.inboundDocumentService
      .getPatientDemographicsDetails(docId)
      .subscribe(
        (response) => {
          console.log(response);
          if (response?.isDocumentCheckList === true) {
            this.isDocumentCheckListShow = 'Completed';
          } else {
            this.isDocumentCheckListShow = 'Pending';
          }

          if (response?.isSorted === true) {
            this.isSortingShow = 'Completed';
          } else {
            this.isSortingShow = 'Pending';
          }
          if (
            response.inboundstatusShortCode !== null &&
            response.inboundstatusShortCode !== '' &&
            response.inboundstatusShortCode !== undefined
          ) {
            let existShortcode = response.inboundstatusShortCode.filter(
              (s) => s === this.inboundShortCode
            );
            if (existShortcode.length === 0) {
              this.isMovetoDisable = true;
            } else {
              this.isMovetoDisable = false;
            }
          }
          (this.txtPatientFullName =
            response?.firstName + response?.middleName + response?.lastName),
            // this.currentShortCode = response.currentShortCode;
            (this.patientId = response?.patientId);
          this.isDocumentCheckList = response?.isDocumentCheckList;
          this.blobName = response?.blobName || 'no.pdf';
          this.loadInboundDocument(this.from, this.to); // After Load Once BlobName set
          this.defaultFaxId = response?.defaultFaxId || defaultGuid;
          this.documentName = response?.documentName || '';
          console.log('this.documentName');
          console.log(this.documentName);
          this.inboundStatusId = response?.inboundStatusId || defaultGuid;
          this.branchId = response?.location || defaultGuid;
          this.defaultFaxIdV1 = response?.defaultFaxId || defaultGuid;
          this.documentIdV1 = docId;
          if (!this.isEmpty(response.patientId)) {
            this.isViewDocument = false;
          }
          // this.getChartId();
          // this.isStat = response.isStat ?? '0';
          this.isNewSetupDone =
            response.isNewSetupDone === true ? 'true' : 'false';
          if (this.isNewSetupDone === 'true' && this.notLoadSort !== true) {
            this.activeTab = '';
            // setTimeout(() => {
            this.changeTab('document');
            // }, 100); // Adjust the delay as needed
          } else {
            this.changeTab('demographics');
          }

          this.isDemographicsDone = response.isDemographicsDone ?? 'false';
          this.isDemographicsDoneV1 =
            response.isDemographicsDone === true ? true : false;
          this.isDemoCompleted = response.isDemographicsDone ?? 'false';
          // this.country = response?.country ?? defaultCountry.US;
          // this.State = response?.state;
          // this.onChangeCity(this.State, this.country);
          this.country =
            response?.country === null || response?.country === ''
              ? defaultCountry.US
              : response?.country;
          this.city = response?.city;
          (this.city != null || this.city !== undefined) &&
            this.onChangeState(this.country);
          this.State = response?.state;
          (this.State != null || this.State != undefined) &&
            this.onChangeCity(this.State, this.country);
          (this.patientId = response?.patientId ?? defaultGuid),
            this.loadPatientChartNos('');
          this.patientSearchForm.patchValue({
            drpSearchPatient: response?.patientId ?? defaultGuid,
          });
          // if (
          //   response?.birthDate !== null &&
          //   response?.birthDate !== undefined
          // ) {
          //   this.birthdate = new Date(response?.birthDate);
          //   this.birthdate.setDate(this.birthdate.getDate());
          //   this.birthdateV1 = this.birthdate.toISOString().split('T')[0];
          //   this.demoForm.get('txtBirthdate').setValue(this.birthdateV1);
          // }
          // if (response?.rxDate !== null && response?.rxDate !== undefined) {
          //   this.RxDate = new Date(response?.rxDate);
          //   this.RxDate.setDate(this.RxDate.getDate());
          //   this.RxDateV1 = this.RxDate.toISOString().split('T')[0];
          //   this.demoForm.get('txtRXdate').setValue(this.RxDateV1);
          // }
          this.ReferralId = response?.refferalId;
          this.referralSearchV1('');
          this.demoForm.patchValue({
            drpReferral: response?.refferalId,
          });
          this.reloadPhysican('', false);
          this.txtChartV1 = response?.chartId;
          this.demoForm.patchValue({
            drpTitle: response?.title,
            drpMartialSts: response?.maritalStatus,
            txtSSNNo: response?.ssn,
            txtEmergencyNo: response?.emergencyContact,
            tglStat: response?.isStat === 1 ? true : false,
            txtChart: response?.chartId,
            txtFirstName: response?.firstName,
            txtMiddleName: response?.middleName,
            txtLastName: response?.lastName,
            drpGender: response?.gender,
            txtBirthdate: response?.birthDate,
            txtRXdate: response?.rxDate,
            txtEmail: response?.emailId || '',
            txtAddress: response?.address,
            txtCountry:
              response?.country === null || response?.country === ''
                ? defaultCountry.US
                : response?.country,
            txtState: response?.state,
            txtCity: response?.city,
            txtPostalCode: response?.postalCode,
            txtHomeNo: response?.homePhoneNo,
            txtCellNo: response?.cellNo,
            txtWorkNo: response?.workPhoneNo,
            // txtComments: response?.demographicsComment,
            drpServiceType: response?.serviceTypeId,
            drpReferral: response?.refferalId,
            drpPhysician:
              response?.referringProviderDoctorId !== null
                ? response?.referringProviderDoctorId +
                  '/' +
                  response?.referringProviderDoctor
                : null,
          });
          this.notesForm.patchValue({
            txtCommentsDemo: response?.demographicsComment,
            txtCommentsPlan: response?.planComment,
          });
          this.birthdate = response?.birthDate;
          if (response?.primary !== null) {
            this.payerId = response?.primary?.payerId;
            this.payerSearch('');
            this.planForm.patchValue({
              drpPrimaryPayor:
                response?.primary == null ? '' : response?.primary?.payerId,
              txtPrimaryPolicyNo:
                response?.primary == null ? '' : response?.primary?.policyNo,
              txtPrimaryGroupNo:
                response?.primary == null ? '' : response?.primary?.groupNo,
            });
          }
          if (response?.secondary !== null) {
            this.secPayerId = response?.secondary?.payerId;
            this.payerSearchV1('');
            this.planForm.patchValue({
              drpSecondaryPayor:
                response?.secondary == null ? '' : response?.secondary?.payerId,
              txtSecondaryPolicyNo:
                response?.secondary == null
                  ? ''
                  : response?.secondary?.policyNo,
              txtSecondaryGroupNo:
                response?.secondary == null ? '' : response?.secondary?.groupNo,
            });
          }
          if (response?.tertiary !== null) {
            this.terPayerId = response?.tertiary?.payerId;
            this.payerSearchV2('');
            this.planForm.patchValue({
              drpTertiaryPayor:
                response?.tertiary == null ? '' : response?.tertiary?.payerId,
              txtTertiaryPolicyNo:
                response?.tertiary == null ? '' : response?.tertiary?.policyNo,
              txtTertiaryGroupNo:
                response?.tertiary == null ? '' : response?.tertiary?.groupNo,
            });
          }
          this.planForm.patchValue({
            chkCashPayment: response?.isCashPayment === 1 ? true : false,
            txtProvider: response?.provider,
            drpFacility: response?.facility,
            drpBranch: response?.location,
          });
          if (response.isCashPayment === 1) {
            this.changeCashCase(false);
          }
          if (
            isReset === true &&
            (response.chartId === null || response.chartId === '')
          ) {
            this.getChartId();
          }
        },
        (err) => {
          this.loadInboundDocument(this.from, this.to);
          const data: HttpErrorResponse = err;
          // this.toastr.error(data?.error?.error?.message, 'Error');
          console.warn(data?.error?.error?.message);
        }
      );
  }

  //Patient Search V1  -- #Start Region
  loadPatientFormV1(patientIdInput: any) {
    this.isExistDocChecked = false;
    this.patientId = patientIdInput ?? defaultGuid;
    // this.patientId =
    //   this.patientSearchForm.value.drpSearchPatient ?? defaultGuid;
    this.searchPatient('');
    if (
      this.patientId != defaultGuid &&
      this.patientId != null &&
      this.patientId != '0'
    ) {
      const getPatientDetails = this.inboundDocumentService
        .getPatientDetail(this.patientId)
        .subscribe(
          (response) => {
            this.exisingPatient = true;
            this.defaultFaxIdV1 = response?.defaultFaxId;
            this.documentIdV1 = response?.documentId;
            this.isViewDocument = false;
            this.country =
              response?.country === null || response?.country === ''
                ? defaultCountry.US
                : response?.country;
            this.city = response?.city;
            (this.city != null || this.city !== undefined) &&
              this.onChangeState(this.country);
            this.State = response?.state;
            (this.State != null || this.State != undefined) &&
              this.onChangeCity(this.State, this.country);
            this.demoForm.reset();
            this.planForm.reset();
            this.txtChartV1 = response?.chartId;
            this.demoForm.patchValue({
              drpTitle: response?.title,
              drpMartialSts: response?.maritalStatus,
              txtSSNNo: response?.ssn,
              txtEmergencyNo: response?.emergencyContact,
              txtChart: response?.chartId,
              txtFirstName: response?.firstName,
              txtMiddleName: response?.middleName,
              txtLastName: response?.lastName,
              drpGender: response?.gender,
              txtBirthdate: response?.dob,
              txtEmail: response?.emailId || '',
              txtAddress: response?.address,
              txtRXdate: response?.rxDate,
              txtCountry:
                response?.country === null || response?.country === ''
                  ? defaultCountry.US
                  : response?.country,
              txtState: response?.state,
              txtCity: response?.city,
              txtPostalCode: response?.postalCode,
              txtHomeNo: response?.homePhoneNo,
              txtCellNo: response?.cellNo,
              txtWorkNo: response?.workPhoneNo,
              // txtComments: response?.comment,
              drpReferral: response?.refferalId,
              drpPhysician:
                response?.referringProviderDoctorId !== null
                  ? response?.referringProviderDoctorId +
                    '/' +
                    response?.referringProviderDoctor
                  : null,
              drpServiceType: response?.serviceTypeId,
            });
            this.notesForm.patchValue({
              txtCommentsDemo: response?.demographicsComment,
              txtCommentsPlan: response?.planComment,
            });
            // this.birthdate = new Date(response?.dob);
            // this.birthdate.setDate(this.birthdate.getDate());
            // this.birthdateV1 = this.birthdate.toISOString().split('T')[0];
            // if (response?.dob !== null) {
            //   this.demoForm.get('txtBirthdate').setValue(this.birthdateV1);
            // }
            this.dialog.closeAll();
            let demo = this.demoForm.value;
            if (
              demo.txtLastName !== undefined &&
              demo.txtLastName !== null &&
              demo.txtLastName !== '' &&
              this.getFormattedDate(demo.txtBirthdate) !== 'Invalid Date'
            ) {
              if (this.checkDateExist) {
                this.checkDateExist.unsubscribe();
              }
              this.isExistDocMessage = true;
              this.checkDateExist = this.inboundDocumentService
                .checkExistDocument(
                  this.documentId,
                  demo.txtLastName,
                  this.getFormattedDate(response.dob)
                )
                .subscribe(
                  (response) => {
                    let patientList = response ?? [];

                    let oldPatientExist = false;
                    let faxBinPatientExists = patientList?.some(
                      (a) => a.isInFaxQueue
                    );

                    let swalText =
                      oldPatientExist && !faxBinPatientExists
                        ? 'Similiar Patient Exists in Patients'
                        : !oldPatientExist && faxBinPatientExists
                        ? 'Similiar Patient has Fax in Queue'
                        : oldPatientExist && faxBinPatientExists
                        ? 'Similiar Patient exist in Patients List and Fax Queue'
                        : '';

                    if (faxBinPatientExists) {
                      Swal.fire({
                        title: swalText,
                        text: 'Do you want to merge?',
                        icon: 'info',
                        showCancelButton: true,
                        showConfirmButton: faxBinPatientExists ? true : false,
                        showDenyButton: oldPatientExist ? true : false,
                        confirmButtonText: 'Join Fax',
                        denyButtonText: 'Similiar Patient List',
                        cancelButtonText: 'Cancel',
                        confirmButtonColor: 'lighseagreen',
                        denyButtonColor: 'lightseagreen',
                        allowOutsideClick: false,
                        buttonsStyling: true,
                      }).then((result) => {
                        if (result.isConfirmed) {
                          this.isExistDocChecked = true;
                          this.isExistDocMessage = false;
                          let faxPatientList = response?.filter(
                            (a) => a.isInFaxQueue
                          );
                          const dialogRef = this.dialog.open(JoinFaxComponent, {
                            minWidth: '60vw',
                            disableClose: true,
                            data: {
                              faxID: this.defaultFaxId,
                              docID: this.documentId,
                              branchID: this.branchId,
                              documentName: this.documentName,
        // documentName: null,
        selectedNode: this.selectedNode,
                              // documentName: this.blobName,
                              // lname:Value,
                              // dob:this.getFormattedDate(demo.txtBirthdate)
                              docList: faxPatientList,
                            },
                          });
                          dialogRef.afterClosed().subscribe((a) => {
                            if (a) {
                              window.close();
                            }
                          });
                        } else if (result.isDenied) {
                          this.isExistDocChecked = true;
                          this.isExistDocMessage = false;
                          this.openSearchMdl();
                          let oldPatientList = response?.filter(
                            (a) => !a.isInFaxQueue
                          );
                          this.communicationService.triggerPatientSearchData(
                            oldPatientList
                          );
                        } else {
                          this.isExistDocChecked = true;
                          this.isExistDocMessage = false;
                        }
                      });
                    } else {
                      this.isExistDocChecked = true;
                      this.isExistDocMessage = false;
                    }
                  },
                  (err) => {
                    const data: HttpErrorResponse = err;
                    this.toastr.error(data?.error?.error?.message);
                  }
                );
            }
            this.planForm.patchValue({
              chkCashPayment: response?.isCashPayment === 1 ? true : false,
              txtProvider: response?.providerId,
              drpFacility: response?.facility,
              drpBranch: response?.location,
            });
            this.changeCashCase(false);
            //chech null because check insurance availabilty error occured
            if (response?.primary !== null) {
              this.payerId = response?.primary?.payerId;
              this.payerSearch('');
              this.secPayerId = response?.secondary?.payerId;
              this.payerSearchV1('');
              this.terPayerId = response?.tertiary?.payerId;
              this.payerSearchV2('');
              this.planForm.patchValue({
                drpPrimaryPayor:
                  response?.primary == null ? '' : response?.primary?.payerId,
                txtPrimaryPolicyNo:
                  response?.primary == null ? '' : response?.primary?.policyNo,
                txtPrimaryGroupNo:
                  response?.primary == null ? '' : response?.primary?.groupNo,
              });
            }
            if (response?.secondary !== null) {
              this.planForm.patchValue({
                drpSecondaryPayor:
                  response?.secondary == null
                    ? ''
                    : response?.secondary?.payerId,
                txtSecondaryPolicyNo:
                  response?.secondary == null
                    ? ''
                    : response?.secondary?.policyNo,
                txtSecondaryGroupNo:
                  response?.secondary == null
                    ? ''
                    : response?.secondary?.groupNo,
              });
              this.onSecondaryPolicyChange();
            }
            if (response?.tertiary !== null) {
              this.planForm.patchValue({
                drpTertiaryPayor:
                  response?.tertiary == null ? '' : response?.tertiary?.payerId,
                txtTertiaryPolicyNo:
                  response?.tertiary == null
                    ? ''
                    : response?.tertiary?.policyNo,
                txtTertiaryGroupNo:
                  response?.tertiary == null ? '' : response?.tertiary?.groupNo,
              });
              this.onTertiaryPolicyChange();
            }
          },
          (err) => {
            // this.demoForm.reset();
            this.toastr.error('Something Went Wrong!');
          }
        );
      this.subscription$.push(getPatientDetails);
    } else {
      this.getChartId();
      this.demoForm.reset();
      this.initializePlanForm();
    }
  }

  resetPatientDetails() {
    this.exisingPatient = false;
    if (this.checkDateExist) {
      this.checkDateExist.unsubscribe();
    }
    this.isExistDocChecked = true;
    this.isExistDocMessage = false;
    this.demoForm.reset();
    this.planForm.reset();
    this.notesForm.reset();
    this.isViewDocument = true;
    this.loadPatientDemoForm(this.documentId, true);
  }
  //Patient Search V1  -- [Deepak 20/04/2024] #End Region
  openAddNotesPopUp(
    defaultFaxId: string,
    patientId: string,
    docId: string,
    isCallNote: boolean = false,
    statusId: string
  ) {
    const config: MatDialogConfig = {
      disableClose: true,
      minHeight: '30vh',
      minWidth: '50vw',
      data: {
        // claimId: '00000000-0000-0000-0000-000000000000',
        noteType: 'Patient Note', // 'Order Note',
        notesId: '00000000-0000-0000-0000-000000000000',
        // orderId: '0b50be04-6c75-92d3-97c6-3a0e17ee6a18',
        patientId: patientId,
        defaultFaxId: defaultFaxId,
        isCallNote: isCallNote,
        isPending: false,
        docId: docId,
        inboundStatusShortCodeId: this.patientSearchForm.value.drpSearchStatus,
        selectedNode: defaultGuid,
        statusId: statusId,
      },
    };
    this.patientSearchForm = this.formBuilder.group({
      drpSearchStatus: new FormControl(''),
    });
    const dialogRef = this.matDialog.open(AddNoteTabComponent, config);
    const closeDialog = dialogRef.afterClosed().subscribe(
      () => {
        // this.getReminderTableData();
      },
      (err) => {
        const data: HttpErrorResponse = err;
        this.toastr.error(data?.error?.error?.message, 'Error');
      }
    );
    this.subscription$.push(closeDialog);
  }
  //Open Referral Doctors Search Popup
  openDoctorModal(doctorId?: string) {
    const dialogRef = this.matDoctorDialog.open(PatientDoctorComponent, {
      disableClose: true,
      data: {
        doctorId: doctorId,
        practiceId: this.demoForm.value?.drpReferral,
      },
    });
    const closeDialog = dialogRef.afterClosed().subscribe(
      () => {
        // this.getPhysicianDrpdwn();
        // this.getReferralDrpdwn();
        this.reloadPhysican('', true);
      },
      (err) => {
        const data: HttpErrorResponse = err;
        this.toastr.error(data?.error?.error?.message, 'Error');
      }
    );
    this.subscription$.push(closeDialog);
  }

  check() {
    // // "document.shortCode == SortingShortCode.All || isDisabled || TiffError || document_shortCode === document.shortCode || this.overAllPagesBG === 0 || !(isTotalAPIcallGoingOnCount > 1)"
    // // console.log('this.isDisabled = ' + this.isDisabled)
    // // console.log('this.TiffError = ' + this.TiffError)
    // // console.log('this.isTotalAPIcallGoingOnCount = ' + this.isTotalAPIcallGoingOnCount)
    // console.log(
    //   'this.saveDocumentDetails?.length = ' + this.saveDocumentDetails?.length
    // );
    // console.log('this.overAllPages = ' + this.overAllPages);
    // console.log('this.overAllPagesBG = ' + this.overAllPagesBG);
    // console.log('this.SortedDocumentImageList = ');
    // console.log(this.SortedDocumentImageList);
    // console.log('this.PendingDocumentImageList = ');
    // console.log(this.PendingDocumentImageList);
    // console.log('this.documentTypes = ');
    // console.log(this.documentTypes);
    // // || this.overAllPages != this.overAllPagesBG
    // console.log('this.textAnnotations');
    // console.log(this.textAnnotations);
    // console.log(this.convertCanvasToBase64());
    // console.log('this.saveDocumentDetails');
    // console.log(this.saveDocumentDetails);
  }
  /// for enter number only
  numberOnly(event: { which: any; keyCode: any }): boolean {
    this.check();
    const charCode = event?.which ? event?.which : event?.keyCode;
    if (
      charCode > 31 &&
      (charCode < 48 || charCode > 57) &&
      (charCode < 44 || charCode > 45)
    ) {
      return false;
    }
    return true;
  }

  checkValidInput(input: string): boolean {
    // Your validation logic goes here
    // Return true if the input is valid, otherwise return false

    // For example, let's check if the input is a number

    if (input === '0') {
      this.checkValidInputCase = 0;
      // If none of the cases match, return false
      return false;
    } else if (/^[1-9]\d*$/.test(input) && Number(input)) {
      // if (/^\d+$/.test(input) && Number(input)) {
      // Case 1: Single number
      this.checkValidInputCase = 1;
      return true;
    } else if (/^\d+(,\d+)*$/.test(input)) {
      // else if (/^\d+([1-9]\d*)*(,\d+([1-9]\d*)*)*$/.test(input)) {
      // Case 2: Comma-separated list of numbers
      this.checkValidInputCase = 2;
      return true;
    } else if (/^\d+-\d+$/.test(input)) {
      // else if (/^\d+([1-9]\d*)*-(\d+([1-9]\d*)*)*$/.test(input)) {
      // Case 3: Range of numbers without 0 (e.g., 1-5)
      this.checkValidInputCase = 3;
      const [start, end] = input.split('-').map(Number);
      return !isNaN(start) && !isNaN(end) && start <= end;
    } else if (input === '') {
      this.checkValidInputCase = 4;
      return true;
    }

    this.checkValidInputCase = 0;
    // If none of the cases match, return false
    return false;

    // return !isNaN(Number(input));
  }

  //Page Number Already Exists In the Document List Validation Method
  isPageNumberAlreadyExists(pageNumber: number): boolean {
    return this.documentTypes.some((document) => {
      return document.documentPages.some(
        (page) => page.pageNumber === pageNumber
      );
    });
  }

  //Page Number Already Exists In the Document List Validation Method
  isPageNumberAlreadyExistsV1(pageNumber: number): boolean {
    return this.saveDocumentDetails.some(
      (document) => document.pageNumber === pageNumber
    );
  }

  // getSortedPagesTooltip(): string {
  //   return this.sortedPagesList.join(', ');
  // }

  private saveDocument() {
    try {
      this.isDisabled = true;
      let createUpdateChecklistSortingDTO: CreateUpdateChecklistSortingDTO = {
        lstSorting: this.saveDocumentDetails,
        lstRemoveSorting: this.removeSorting || [],
        initialFaceToFaces: null,
        sleepStudies: null,
        prescriptions: null,
        complainces: null,
        isInitialFaceToFaceDataAvailable: 0,
        isSleepStudyDataAvailable: 0,
        isPrescriptionDataAvailable: 0,
        isComplainceDataAvailable: 0,
        isVerificationDataAvailable: 0,
        isRuleCheck: 0,
        isRulesNeeded: 0,
        isCompSA: 0,
        dxType: null,
        setupType: null,
        percentageOfCompletion: 0,
        initialFaceToFacePendingValidationCount: 0,
        sleepStudyPendingValidationCount: 0,
        prescriptionPendingValidationCount: 0,
        compliancePendingValidationCount: 0,
        isApproved: true,
        patientId: this.patientId,
        documentId: this.documentId,
        bCompleteSorting: true,
      };

      // return false;
      const docDetails = this.patientDocumentService
        .checklistwithSortingUpdateByInput(createUpdateChecklistSortingDTO)
        .subscribe(
          (response) => {
            this.toastr.success('Document Sorted Successfully');
            this.isSortingShow = 'Completed';
            this.saveDocumentDetails = [];
            this.isSortingStart = false;
            this.isDisabled = false;
            this.isSaveSortingButton = false;
            this.loaderService.hideLoader();
            this.removeSorting = [];
          },
          (err) => {
            const data: HttpErrorResponse = err;
            this.saveDocumentDetails = [];
            // this.sortingDocView();
            this.loadOverAllSortingAPI();
            this.toastr.error(data?.error?.error?.message, 'Error');
            this.loaderService.hideLoader();
          }
        );
    } catch (error) {
      console.error('Error:', error);
      this.isDisabled = false;
      this.isSaveDisabled = false;
      this.isSortingStart = false; // Enable the button when the operation completes (or encounters an error)
      this.loaderService.hideLoader();
    } finally {
      // this.saveDocumentDetails = [];
    }
  }

  saveSortedDetails() {
    try {
      if (!this.isDisabled) {
        this.isDisabled = true;
        this.isSaveSortingButton = true;
        this.loaderService.showLoader();
        this.saveSubject.next();
      }
      localStorage.removeItem('checkisSortingData');
      localStorage.setItem('checkisSortingData', 'NewisSorting');
    } catch (error) {
      console.error('Error:', error);
    } finally {
      // Optionally, you can reset isDisabled after a certain period
      setTimeout(() => {
        this.isDisabled = false;
      }, 5000); // 5000 milliseconds (adjust as needed)
    }
  }

  newSetupMoveTo() {
    const dialogRef = this.matDialog.open(NewsetupMovetoComponent, {
      minHeight: '33vh',
      minWidth: '52vw',
      disableClose: true,
      data: {
        docId: this.documentId,
        inboundStatusId: this.inboundStatusId,
        moveToId: 1,
        selectedNode: this.selectedNode,
        isNewSetup: true,
        currentShortCode: this.inboundShortCode,
      },
    });
    dialogRef.afterClosed().subscribe((a) => {
      // this.redirectDahboard();
    });
  }

  //DemoGraphics Move to Verfication/Confirmation
  // movetoVerfication() {
  //   if (this.isDocumentCheckList === false) {
  //     this.checkListModel(true);
  //   } else {
  //     this.saveDemographics(true);
  //   }
  // }
  movetoVerfication() {
    this.checkListModel(true);
  }
  // //Save Plan
  // savePlanV1() {
  //   if (this.isDocumentCheckList === false) {
  //     this.checkListModel(false);
  //   } else {
  //     this.savePlans(false);
  //   }
  // }

  //Save Plan
  savePlanV1() {
    this.checkListModel(false);
  }

  //Plan Move to Verfication/Confirmation
  planmovetoVerfication() {
    Swal.fire({
      title: 'Are you sure you want Move to Verification without Saving Plan?',
      // text: 'You won\'t be able to retrieve this data!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#34c38f',
      cancelButtonColor: '#f46a6a',
      confirmButtonText: 'Yes, Move to Verification!',
    }).then((result) => {
      if (result.value) {
        this.movetoVerfication();
      }
    });
  }

  //Save DemoGraphics function
  saveDemographics(isMoveToVerification: boolean) {
    if (isMoveToVerification) {
      this.isHidePlanSpinner = true;
      this.isPlanSpinnerMoveVerification = true;
    } else {
      this.isShowPlanSpinner = true;
    }
    // this.isShowSpinner=true;
    this.saveButtonDemo = true;
    const formValue = this.demoForm.value;
    const notesformValue = this.notesForm.value;
    const formattedDate = this.datepipe.transform(
      this.demoForm.value.txtBirthdate,
      'yyyy-MM-dd'
    );
    if (this.isSortingV1 === 'true') {
      this.isSortingV2 = true;
    } else {
      this.isSortingV2 = false;
    }
    let homeNo = null;
    let workNo = null;
    let cellNo = null;
    let emergencyContact = null;
    if (formValue?.txtHomeNo) {
      homeNo =
        formValue?.txtHomeNo.length === 10
          ? formValue?.txtHomeNo.slice(0, 3) +
            '-' +
            formValue?.txtHomeNo.slice(3, 6) +
            '-' +
            formValue?.txtHomeNo.slice(6, 15)
          : formValue?.txtHomeNo;
    }
    if (formValue?.txtWorkNo) {
      workNo =
        formValue?.txtWorkNo.length === 10
          ? formValue?.txtWorkNo.slice(0, 3) +
            '-' +
            formValue?.txtWorkNo.slice(3, 6) +
            '-' +
            formValue?.txtWorkNo.slice(6, 15)
          : formValue?.txtWorkNo;
    }
    if (formValue?.txtCellNo) {
      cellNo =
        formValue?.txtCellNo.length === 10
          ? formValue?.txtCellNo.slice(0, 3) +
            '-' +
            formValue?.txtCellNo.slice(3, 6) +
            '-' +
            formValue?.txtCellNo.slice(6, 15)
          : formValue?.txtCellNo;
    }
    if (formValue?.txtEmergencyNo) {
      emergencyContact =
        formValue?.txtEmergencyNo.length === 10
          ? formValue?.txtEmergencyNo.slice(0, 3) +
            '-' +
            formValue?.txtEmergencyNo.slice(3, 6) +
            '-' +
            formValue?.txtEmergencyNo.slice(6, 15)
          : formValue?.txtEmergencyNo;
    }
    const chkIsNewCity =
      this.drpCities?.filter((x) => x?.cityName === formValue?.txtCity)?.[0]
        ?.cityId ?? null;

    const facilityId = formValue?.drpReferral;
    const selectedFacilityName = this.referralPractice.find(facility => facility.id === facilityId);
    let demoDetails: CreateUpdateNewsetupDemographicsDTO = {
      title: formValue?.drpTitle,
      maritalStatus: formValue?.drpMartialSts,
      ssn: formValue?.txtSSNNo,
      emergencyContact: emergencyContact,
      chartId: formValue?.txtChart,
      patientId: this.patientId,
      firstName: formValue?.txtFirstName ?? '',
      middleName: formValue?.txtMiddleName ?? '',
      lastName: formValue?.txtLastName ?? '',
      gender: formValue?.drpGender ?? '',
      documentId: this.documentId ?? defaultGuid,
      birthDate: formattedDate ?? '',
      // comment: formValue?.txtComments ?? '',
      comment: notesformValue?.txtCommentsDemo ?? '',
      emailId: formValue?.txtEmail?.toLowerCase() ?? '',
      address: formValue?.txtAddress ?? '',
      postalCode: formValue?.txtPostalCode ?? '',
      isNewCity: chkIsNewCity == 1 ? true : false,
      city: formValue?.txtCity ?? '',
      state: formValue?.txtState ?? '',
      country: formValue?.txtCountry ?? '',
      homePhoneNo: homeNo,
      workPhoneNo: workNo,
      cellNo: cellNo,
      isStat: formValue?.tglStat == true ? 1 : 0,
      referringProviderDoctor:
        formValue?.drpPhysician !== '' &&
        formValue?.drpPhysician !== null &&
        formValue?.drpPhysician !== undefined &&
        formValue?.drpPhysician !== 'null/null' &&
        formValue?.drpPhysician !== '/undefined'
          ? formValue?.drpPhysician.split('/')[1] +
            '/' +
            formValue?.drpPhysician.split('/')[2]
          : '',
      referringProviderDoctorId:
        formValue?.drpPhysician !== '' &&
        formValue?.drpPhysician !== null &&
        formValue?.drpPhysician !== undefined &&
        formValue?.drpPhysician !== 'null/null' &&
        formValue?.drpPhysician !== '/undefined'
          ? formValue?.drpPhysician.split('/')[0] ?? defaultGuid
          : defaultGuid,
      serviceTypeId:
        formValue?.drpServiceType !== ''
          ? formValue?.drpServiceType
          : defaultGuid,
      // refferal:
      //   formValue?.drpReferral !== ''
      //     ? (formValue?.drpReferral).split('/')[1]
      //     : '',
      // refferal:
      // formValue?.drpReferral?.firstName ?? '' +
      // formValue?.drpReferral?.middleName ?? '' +
      // formValue?.drpReferral?.lastName ?? '',
      refferal: selectedFacilityName?.practiceName || '',
      // formValue?.drpReferral !== '' && formValue?.drpReferral !== null && formValue?.drpReferral !== undefined
      //   ? formValue?.drpReferral.split('/')[1]
      //   : '',
      // refferalId:
      //   formValue?.drpReferral !== ''
      //     ? (formValue?.drpReferral).split('/')[0]
      //     : defaultGuid,
      refferalId:
        formValue?.drpReferral !== '' &&
        formValue?.drpReferral !== null &&
        formValue?.drpReferral !== undefined
          ? formValue?.drpReferral ?? defaultGuid
          : defaultGuid,
      // facility: formValue?.drpFacility ?? defaultGuid,
      rxDate: this.datepipe.transform(
        this.demoForm.value.txtRXdate,
        'yyyy-MM-dd'
      ),
      isMoveVerification: isMoveToVerification,
      isSorting: this.isSortingV2,
    };
    // return false;
    this.inboundDocumentService
      .updatePatientDemographicsNewByDemographicsInput(demoDetails)
      .subscribe(
        (response) => {
          var lstSavedCity = this.drpCities?.filter(
            (x) => x?.cityName === (formValue?.txtCity).trim()
          );
          if (lstSavedCity.length > 0) {
            lstSavedCity.forEach((element) => {
              element.cityId = null;
            });
          }

          // this.currentShortCode = response.currentShortCode;
          if (isMoveToVerification === true) {
            this.toastr.success(
              'Saved Successfully and Moved to Verification/Confirmation',
              'Success'
            );
            this.exisingPatient = false;
            this.isPlanSpinnerMoveVerification = false;
            this.isHidePlanSpinner = false;
          } else {
            this.toastr.success('Saved Successfully', 'Success');
            this.isShowPlanSpinner = false;
            this.exisingPatient = false;
          }
          localStorage.removeItem('checkNewTabData');
          localStorage.setItem('checkNewTabData', 'NewTabDataSaved');
          // this.defaultFaxIdV1 = response?.defaultFaxId;
          this.documentIdV1 = this.documentId;
          this.isViewDocument = false;
          // this.isShowSpinner=false;
          this.saveButtonDemo = false;
          this.isDemoCompleted = true;
          this.patientId = response?.patientId ?? defaultGuid;
          if (isMoveToVerification === false) {
            this.changeTab('plans');
            this.activeTab = 'plans';
          } else {
            this.redirectDahboard();
            // this.router.navigate(['dashboard']);
            // this.communicationService.triggerFunctionCall(this.selectedNode);
            // this.reloadTableEvent.emit();
            // this.selectNode.emit({ node: this.selectedNode });
            // window.close();
          }
        },
        (err) => {
          // const data: HttpErrorResponse = err;
          // this.toastr.error(data?.error?.error?.message, 'Error');
          const data: HttpErrorResponse = err;
          Swal.fire({
            icon: 'error',
            text: data?.error?.error?.message,
            allowOutsideClick: false,
          });
          this.saveButtonDemo = false;
          this.isShowPlanSpinner = false;
          this.isHidePlanSpinner = false;
        }
      );
    // this.isShowSpinner=false;
  }

  redirectDahboard() {
    localStorage.removeItem('checkNewTabData');
    localStorage.setItem('checkNewTabData', 'NewTabDataSaved');
    this.router.navigate(['dashboard']);
    this.communicationService.triggerFunctionCall(this.selectedNode);
    this.reloadTableEvent.emit();
    this.selectNode.emit({ node: this.selectedNode });
    window.close();
  }
  MoveToVerification() {
    this.router.navigate(['dashboard']);
    this.communicationService.triggerFunctionCall(this.selectedNode);
    this.reloadTableEvent.emit();
    this.selectNode.emit({ node: this.selectedNode });
    window.close();
  }
  savePlans(status: boolean) {
    this.isMovetoDisable = true;
    this.isPlanSpinner = true;
    this.isPlanReadySpinner = true;
    const formValue = this.planForm.value;
    let secondaryPayorDetails: policyInsurance;
    let tertiaryPayorDetails: policyInsurance;
    //Secondary Policy Details
    if (
      formValue?.drpSecondaryPayor === '' &&
      formValue?.txtSecondaryPolicyNo === '' &&
      formValue?.txtSecondaryGroupNo === ''
    ) {
      secondaryPayorDetails = null;
    } else {
      secondaryPayorDetails = {
        payerId: formValue?.drpSecondaryPayor,
        policyNo: formValue?.txtSecondaryPolicyNo,
        groupNo: formValue?.txtSecondaryGroupNo,
        payorName: '',
        payorId: '',
      };
    }

    if (
      formValue?.drpTertiaryPayor === '' &&
      formValue?.txtTertiaryGroupNo === '' &&
      formValue?.txtTertiaryPolicyNo === ''
    ) {
      tertiaryPayorDetails = null;
    } else {
      tertiaryPayorDetails = {
        payerId: formValue?.drpTertiaryPayor,
        policyNo: formValue?.txtTertiaryPolicyNo,
        groupNo: formValue?.txtTertiaryGroupNo,
        payorName: '',
        payorId: '',
      };
    }
    const notesformValue = this.notesForm.value;
    let planDetails: plansDTO = {
      facility: formValue?.drpFacility ?? defaultGuid,
      documentId: this.documentId ?? defaultGuid,
      patientId: this.patientId,

      providerId:
        formValue?.txtProvider === '' ? defaultGuid : formValue?.txtProvider,
      locationId:
        formValue?.drpBranch == ''
          ? defaultGuid
          : formValue?.drpBranch ?? defaultGuid,
      primary: {
        payerId: formValue?.drpPrimaryPayor ?? defaultGuid,
        policyNo: formValue?.txtPrimaryPolicyNo ?? '',
        groupNo: formValue?.txtPrimaryGroupNo ?? '',
        payorName: '',
        payorId: '',
      },
      secondary: secondaryPayorDetails,
      tertiary: tertiaryPayorDetails,
      isCashPayment: formValue?.chkCashPayment == true ? 1 : 0,
      comment: notesformValue?.txtCommentsPlan ?? '',
    };
    this.inboundDocumentService.savePlans(planDetails).subscribe(
      (response) => {
        // this.currentShortCode = response.currentShortCode;
        this.isMovetoDisable = true;
        this.toastr.success('Saved and Moved to Verification Successfully');
        this.exisingPatient = false;
        this.isPlanSpinner = false;
        this.isPlanReadySpinner = false;
        // this.isDocumentCheckListShow = 'Completed';
        localStorage.removeItem('checkNewTabData');
        localStorage.setItem('checkNewTabData', 'NewTabDataSaved');
        // this.isPlanShowSpinner = false;
        if (status === false) {
          this.changeTab('document');
          this.activeTab = 'document';
          this.communicationService.triggerFunctionCall(this.selectedNode);
        } else {
          this.router.navigate(['dashboard']);
          this.communicationService.triggerFunctionCall(this.selectedNode);
          this.reloadTableEvent.emit();
          this.selectNode.emit({ node: this.selectedNode });
          window.close();
        }
      },
      (err) => {
        // const data: HttpErrorResponse = err;
        // this.toastr.error(data?.error?.error?.message, 'Error');
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'error',
          text: data?.error?.error?.message,
          allowOutsideClick: false,
        });
        this.isplanSave = false;
        this.isMovetoDisable = false;
        this.isPlanSpinner = false;
        this.isPlanReadySpinner = false;
      }
    );
  }
  // OpenModel() {}

  isEmpty(value: any): boolean {
    return (
      value === null ||
      value === undefined ||
      value === '' ||
      value === defaultGuid
    );
  }

  loadExistingImage(docDetails: any) {
    this.loadTiffImageV1(docDetails);
    // this.actualPageNo = docDetails.pageNo;
    // this.pageNumber = docDetails.pageNo;
  }
  // Function to zoom in
  zoomIn() {
    if (this.zoomLevel < this.MAX_ZOOM_LEVEL) {
      this.prevZoomLevel = this.zoomLevel;
      this.zoomLevel = Math.round((this.zoomLevel + 0.1) * 100) / 100; // Round to two decimal places
      this.zoomLevelReSize = this.zoomLevel;
      this.redrawAnnotationsZoom(); // Redraw image with new zoom level
    }
  }

  // Function to zoom out
  zoomOut() {
    if (this.zoomLevel > this.MIN_ZOOM_LEVEL) {
      this.prevZoomLevel = this.zoomLevel;
      this.zoomLevel = Math.round((this.zoomLevel - 0.1) * 100) / 100; // Round to two decimal places
      this.zoomLevelReSize = this.zoomLevel;
      this.redrawAnnotationsZoom(); // Redraw image with new zoom level
    }
  }

  previousImage() {
    if (this.mustSaveAnnotations()) {
      this.currentArrayIndex--;
      if (0 < this.currentArrayIndex) {
        this.pageNumber = this.currentArrayIndex + 1;
        this.loadExistingImage(
          this.existingDocumentImageList?.[this.currentArrayIndex]
        );
      } else {
        this.firstImage();
      }
    }
  }

  nextImage() {
    if (this.mustSaveAnnotations()) {
      this.currentArrayIndex++;
      if (this.currentArrayIndex < this.existingDocumentImageList.length) {
        this.pageNumber = this.currentArrayIndex + 1;
        this.loadExistingImage(
          this.existingDocumentImageList?.[this.currentArrayIndex]
        );
      } else {
        this.lastImage();
      }
    }
  }

  firstImage() {
    if (this.mustSaveAnnotations()) {
      this.currentArrayIndex = 0;
      this.pageNumber = this.currentArrayIndex + 1;
      this.loadExistingImage(
        this.existingDocumentImageList?.[this.currentArrayIndex]
      );
    }
  }

  lastImage() {
    if (this.mustSaveAnnotations()) {
      this.currentArrayIndex = this.existingDocumentImageList.length - 1;
      this.pageNumber = this.currentArrayIndex + 1;
      this.loadExistingImage(
        this.existingDocumentImageList?.[this.currentArrayIndex]
      );
    }
  }

  // Function to navigate to a specific page
  goToPage() {
    if (this.mustSaveAnnotations()) {
      if (this.existingDocumentImageList.length === 0) {
        this.TiffError = true;
        this.loadingMessage = 'Images has not found';
        setTimeout(() => {
          this.clearCanvas();
        }, 500);
      } else if (this.pageNumber <= 0) {
        this.pageNumber = 1;
        this.firstImage();
      } else if (this.pageNumber >= 1 && this.pageNumber <= this.totalPages) {
        this.currentArrayIndex = this.pageNumber - 1;
        this.loadExistingImage(
          this.existingDocumentImageList[this.currentArrayIndex]
        );
      } else {
        this.pageNumber = this.totalPages;
        this.lastImage();
      }
    } else {
      this.pageNumber =
        this.existingDocumentImageList[this.currentArrayIndex].pageNo;
    }
  }

  mustSaveAnnotations(): boolean {
    if (this.textAnnotations.length > 0) {
      const confirmation = confirm(
        'Do you want to leave/move this page without saving annotation?'
      );
      if (confirmation) {
        this.clrAllAnnotation();
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  }

  onMouseDown(event: MouseEvent) {
    // WithOutRotatingAnnotation
    if (this.TiffError) {
      return;
    }
    if (!this.isLoading) {
      event.preventDefault(); // Prevent default right-click menu
      const canvas = this.canvasRef.nativeElement;
      this.ctx = canvas.getContext('2d');
      const rect = this.canvasRef.nativeElement.getBoundingClientRect();
      this.startX = event.clientX - rect.left;
      this.startY = event.clientY - rect.top;
      this.isDrawing = true;
      this.isMouseDown = true;
      if (this.annotationModeFlag === this.tiffPointerMode.grab) {
      } else {
        this.savedState = this.ctx.getImageData(
          0,
          0,
          canvas.width,
          canvas.height
        );
      }
    }
  }

  onMouseMoveVVV(event: MouseEvent) {
    // WithOutRotating
    const canvas = this.canvasRef.nativeElement;
    const ctx = canvas.getContext('2d');

    // Get the bounding rectangle of the canvas
    const rect = canvas.getBoundingClientRect();

    // Calculate current mouse position relative to the canvas
    let clickX = (event.clientX - rect.left) / this.zoomLevel;
    let clickY = (event.clientY - rect.top) / this.zoomLevel;

    this.checkAnnotationMouseOver(clickX, clickY);
    clickX = clickX * this.zoomLevel;
    clickY = clickY * this.zoomLevel;

    if (this.TiffError || this.isLoading) {
      return;
    }

    if (this.annotationModeFlag === this.tiffPointerMode.grab) {
      if (!this.isDrawing) return;

      // // Clear the previous highlight
      // if (this.savedState !== null) {
      //     ctx.putImageData(this.savedState, 0, 0);
      // }

      this.highlightPoint(ctx, clickX, clickY);
    } else if (this.annotationModeFlag === this.tiffPointerMode.crosshair) {
      if (!this.isDrawing || !this.isMouseDown) return;

      this.isMouseMove = true;

      // Calculate width and height of the rectangle
      const rectWidth = clickX - this.startX;
      const rectHeight = clickY - this.startY;

      // Clear the previous rectangle
      if (this.savedState !== null) {
        ctx.putImageData(this.savedState, 0, 0);
      }

      // Draw the rectangle for the current move
      ctx.strokeStyle = this.lineColor;
      ctx.lineWidth = 3;
      ctx.strokeRect(this.startX, this.startY, rectWidth, rectHeight);
    }
  }

  highlightedPoints: { x: number; y: number }[] = [];
  // savedState1: ImageData | null = null;
  actionStack: { type: string; point: { x: number; y: number } }[] = [];
  baseImageState: ImageData | null = null;

  // Add this method to check if a point is already highlighted with some tolerance
  isPointHighlighted(x: number, y: number): boolean {
    const tolerance = 10; // Define a tolerance range
    return this.highlightedPoints.some(
      (point) =>
        Math.abs(point.x - x) < tolerance && Math.abs(point.y - y) < tolerance
    );
  }

  highlightPoint(ctx: CanvasRenderingContext2D, x: number, y: number) {
    // Check if the point is already highlighted
    if (this.isPointHighlighted(x, y)) {
      return;
    }

    // Save the base image state if not already saved
    if (this.baseImageState === null) {
      this.baseImageState = ctx.getImageData(
        0,
        0,
        ctx.canvas.width,
        ctx.canvas.height
      );
    }

    // // Save the canvas state
    // if (this.savedState === null) {
    // this.savedState = ctx.getImageData(0, 0, ctx.canvas.width, ctx.canvas.height);
    // } else {
    // ctx.putImageData(this.savedState, 0, 0);
    // }

    // Draw a semi-transparent circle at the mouse pointer location
    ctx.fillStyle = 'rgba(255, 255, 0, 0.2)'; // Yellow highlight with 30% opacity
    ctx.beginPath();
    ctx.arc(x, y, 10, 0, 2 * Math.PI); // 10 is the radius of the highlight circle
    ctx.fill();

    // Add the point to the list of highlighted points and the action stack
    const point = { x, y };
    this.highlightedPoints.push(point);
    this.actionStack.push({ type: 'highlight', point });
  }

  handleKeyDown(event: KeyboardEvent) {
    if (event.ctrlKey && event.key === 'z') {
      this.onUndoButtonClick();
    }
  }

  // Example methods to handle undo and delete actions
  onUndoButtonClick() {
    const canvas = this.canvasRef.nativeElement;
    const ctx = canvas.getContext('2d');
    this.undoLastAction(ctx);
  }

  undoLastAction(ctx: CanvasRenderingContext2D) {
    if (this.actionStack.length === 0) {
      return;
    }

    const lastAction = this.actionStack.pop();
    if (lastAction && lastAction.type === 'highlight') {
      // Remove the last highlighted point
      this.highlightedPoints.pop();

      // Redraw the annotations
      this.redrawAnnotations(ctx);
    }
  }

  redrawAnnotations(ctx: CanvasRenderingContext2D) {
    if (this.baseImageState) {
      // Restore the base image state
      ctx.putImageData(this.baseImageState, 0, 0);

      // Redraw all remaining highlights
      this.highlightedPoints.forEach((point) => {
        ctx.fillStyle = 'rgba(255, 255, 0, 0.2)';
        ctx.beginPath();
        ctx.arc(point.x, point.y, 10, 0, 2 * Math.PI);
        ctx.fill();
      });
    }
  }

  onMouseUpVVV(event: MouseEvent) {
    // WithOutRotate
    if (this.TiffError) {
      return;
    }
    if (!this.isLoading) {
      if (!this.isDrawing) {
        return;
      }
      if (this.annotationModeFlag === this.tiffPointerMode.grab) {
      } else {
        // Get the canvas and its context
        const canvas = this.canvasRef.nativeElement;
        this.ctx = canvas.getContext('2d');

        // Get the bounding rectangle of the canvas
        const rect = canvas.getBoundingClientRect();

        // Calculate current mouse position relative to the canvas
        const x = event.clientX - rect.left;
        const y = event.clientY - rect.top;

        // Calculate width and height of the rectangle
        const rectWidth = x - this.startX;
        const rectHeight = y - this.startY;

        // Save the current canvas state
        const currentState = this.ctx.getImageData(
          0,
          0,
          canvas.width,
          canvas.height
        );

        if (this.savedState !== null) {
          // Redraw the previously saved canvas state
          this.ctx.putImageData(this.savedState, 0, 0);
        }

        // Draw the rectangle for the current move
        this.ctx.strokeStyle = this.lineColor;
        this.ctx.lineWidth = 3;
        this.ctx.strokeRect(this.startX, this.startY, rectWidth, rectHeight);
        this.rectWidth = rectWidth;
        this.rectHeight = rectHeight;

        // Save the changes for the current move
        this.savedState = this.ctx.getImageData(
          0,
          0,
          canvas.width,
          canvas.height
        );
      }
      this.isDrawing = false;
    }
    this.isDrawing = false;
    // this.isMouseMove = false;
  }

  onMouseLeave(event: MouseEvent) {
    // WithOutRotating
    if (this.TiffError) {
      return;
    }
    if (!this.isDrawing) return;
    if (!this.isLoading && !this.isMouseDown && !this.isMouseMove) {
      this.isDrawing = false;
      return;
    } else {
      // this.onMouseUp(event);
      this.onMouseUpVVV(event);
      this.addText(event);
    }
    this.isDrawing = false;
  }

  addText(event: MouseEvent) {
    // WithoutRotating
    if (this.annotationModeFlag === this.tiffPointerMode.help) {
      this.deleteTextV4(event);
    }
    if (this.TiffError) {
      return;
    }
    this.isDrawing = false;
    if (!this.isLoading && this.isMouseMove) {
      this.isMouseMove = false;
      const rect = this.canvasRef.nativeElement.getBoundingClientRect();
      const textX = (event.clientX - rect.left) / this.zoomLevel; // Adjusted for zoom
      const textY = (event.clientY - rect.top) / this.zoomLevel; // Adjusted for zoom

      let text = prompt('Enter text:');
      if (text === '' || text) {
        text = text.trim();
        if (text === '') {
          text = '.';
        }
        // Create a new TextAnnotation object and add it to the textAnnotations array
        const newAnnotation: TextAnnotation = {
          text: this.isEmpty(text) ? '.' : text,
          x: textX,
          y: textY,
          rectWidth: this.rectWidth / this.zoomLevel,
          rectHeight: this.rectHeight / this.zoomLevel,
        };
        this.textAnnotations.push(newAnnotation);
      }
      // Redraw all annotations
      this.redrawAnnotationsZoom();
    }
  }

  deleteTextV4(event: MouseEvent) {
    // WithOutRotate
    const rect = this.canvasRef.nativeElement.getBoundingClientRect();
    const clickX = (event.clientX - rect.left) / this.zoomLevel; // Adjusted for zoom
    const clickY = (event.clientY - rect.top) / this.zoomLevel; // Adjusted for zoom

    // for (let i = 0; i < this.textAnnotations.length; i++) {
    // Loop in reverse
    for (let i = this.textAnnotations.length - 1; i >= 0; i--) {
      const annotation = this.textAnnotations[i];
      const fontSize = 25; // Assuming fixed font size, adjust as needed
      const textHeight = fontSize; // Adjust as needed for the actual text height

      // Set up the canvas context to measure text width
      const canvas = this.canvasRef.nativeElement;
      const ctx = canvas.getContext('2d');
      ctx.font = `${fontSize}px Arial`;
      const textWidth = ctx.measureText(annotation.text).width * this.zoomLevel;

      // Adjust the bounding box to consider the text above the rectangle
      // const left = annotation.x - Math.max(annotation.rectWidth, textWidth);
      const left = annotation.x - annotation.rectWidth;
      // const right = annotation.x + Math.max((annotation.rectWidth* this.zoomLevel), textWidth);
      // const right = annotation.x + textWidth;
      const right = annotation.x + textWidth * 0.9;
      // const right = annotation.x ;
      // const right = annotation.rectWidth + Math.max(annotation.x, textWidth);
      // const right =  annotation.x + Math.max(annotation.rectWidth, textWidth);

      const top = annotation.y - annotation.rectHeight - textHeight; // Include text height above the rectangle
      const bottom = annotation.y - annotation.rectHeight;

      // console.log(`Annotation ${i} boundaries: left=${left}, right=${right}, top=${top}, bottom=${bottom}`);
      // console.log(`Annotation ${i} x >= left=>${clickX} >= ${left}, x <= right =>${clickX} <= ${right}`);

      if (
        clickX >= left &&
        clickX <= right &&
        clickY >= top &&
        clickY <= bottom
      ) {
        // console.log(`Deleting annotation at index ${i}: ${annotation.text}`);
        // console.log('textWidth: ', textWidth);
        // console.log('annotation.rectWidth: ', annotation.rectWidth);
        // console.log('Math.max(annotation.rectWidth, textWidth): ', Math.max(annotation.rectWidth, textWidth));
        // this.textAnnotations.splice(i, 1); // Remove the annotation
        // this.redrawAnnotationsZoom(); // Redraw all annotations

        Swal.fire({
          title: 'Are you sure you want to delete this text?',
          text: 'This Annotation will be removed!',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#34c38f',
          cancelButtonColor: '#f46a6a',
          confirmButtonText: 'Yes, delete it!',
        }).then((result) => {
          if (result.value) {
            this.textAnnotations.splice(i, 1);
            this.redrawAnnotationsZoom();
          }
        });

        break;
      } else {
        console.log('Annotation Else');
      }
    }
    this.annotationMode(this.tiffPointerMode.crosshair);
    // this.annotationModeFlag=this.annotationModeFlagOld;
  }

  checkAnnotationMouseOver(clickX, clickY) {
    if (this.textAnnotations.length > 0) {
      for (let i = this.textAnnotations.length - 1; i >= 0; i--) {
        const annotation = this.textAnnotations[i];
        const fontSize = 25; // Assuming fixed font size, adjust as needed
        const textHeight = fontSize; // Adjust as needed for the actual text height

        // Set up the canvas context to measure text width
        const canvas = this.canvasRef.nativeElement;
        const ctx = canvas.getContext('2d');
        ctx.font = `${fontSize}px Arial`;
        const textWidth =
          ctx.measureText(annotation.text).width * this.zoomLevel;

        // Adjust the bounding box to consider the text above the rectangle
        // const left = annotation.x - Math.max(annotation.rectWidth, textWidth);
        const left = annotation.x - annotation.rectWidth;
        // const right = annotation.x + Math.max((annotation.rectWidth* this.zoomLevel), textWidth);
        // const right = annotation.x + textWidth;
        const right = annotation.x + textWidth * 0.9;
        // const right = annotation.x ;
        // const right = annotation.rectWidth + Math.max(annotation.x, textWidth);
        // const right =  annotation.x + Math.max(annotation.rectWidth, textWidth);

        const top = annotation.y - annotation.rectHeight - textHeight; // Include text height above the rectangle
        const bottom = annotation.y - annotation.rectHeight;

        // console.log(`Annotation ${i} boundaries: left=${left}, right=${right}, top=${top}, bottom=${bottom}`);
        // console.log(
        //   `Annotation ${i} x >= left=>${clickX} >= ${left}, x <= right =>${clickX} <= ${right}`
        // );

        if (
          clickX >= left &&
          clickX <= right &&
          clickY >= top &&
          clickY <= bottom
        ) {
          // console.log(`Deleting annotation at index ${i}: ${annotation.text}`);
          // this.annotationModeFlag = 3;
          this.annotationMode(this.tiffPointerMode.help);
          break;
        } else {
          // console.log('Annotation Else');

          this.annotationModeFlag = this.annotationModeFlagOld;
          this.annotationMode(this.annotationModeFlag);
          // this.annotationMode(this.tiffPointerMode.crosshair);
        }
      }
    }
    // else {
    //   // this.annotationMode(this.tiffPointerMode.crosshair);
    // }
  }

  loadTiffImageV1(docDetails: any) {
    if (docDetails && docDetails.file) {
      // this.zoomLevel = this.DEFAULT_ZOOM_LEVEL;
      const canvas = this.canvasRef.nativeElement;
      this.ctx = canvas.getContext('2d');
      const img = new Image();
      img.onload = () => {
        const imageWidth = img.width * this.zoomLevel;
        const imageHeight = img.height * this.zoomLevel;
        canvas.width = imageWidth;
        canvas.height = imageHeight;
        this.ctx.drawImage(img, 0, 0, imageWidth, imageHeight);
      };
      img.src = 'data:image/tiff;base64,' + docDetails.file;

      this.tiffPageNo = docDetails.pageNo || 0;
      this.selectedExistingDocument = docDetails;
      this.TiffError = false;
      this.loadingMessage = '';
    } else {
      this.TiffError = true;
      this.loadingMessage = 'Images has not found/Empty';
    }
  }

  redrawAnnotationsZoom() {
    // withOutRotate
    if (!this.isLoading) {
      const canvas = this.canvasRef.nativeElement;
      this.ctx = canvas.getContext('2d');
      this.ctx.clearRect(0, 0, canvas.width, canvas.height);
      const img = new Image();
      img.onload = () => {
        // Calculate the new image dimensions based on the zoom level
        const imageWidth = Math.round(img.width * this.zoomLevel);
        const imageHeight = Math.round(img.height * this.zoomLevel);

        canvas.width = imageWidth;
        canvas.height = imageHeight;
        this.ctx.drawImage(img, 0, 0, imageWidth, imageHeight);

        this.textAnnotations.forEach((annotation) => {
          const fontSize = Math.round(25 * this.zoomLevel);
          this.ctx.font = `${fontSize}px Arial`;

          const adjustedX = Math.round(annotation.x * this.zoomLevel);
          const adjustedY = Math.round(annotation.y * this.zoomLevel);

          this.ctx.fillStyle = this.textColor;
          this.ctx.fillText(
            annotation.text,
            adjustedX - Math.round(annotation.rectWidth * this.zoomLevel),
            adjustedY -
              Math.round((annotation.rectHeight + 10) * this.zoomLevel)
          );

          this.ctx.strokeStyle = this.lineColor;
          this.ctx.lineWidth = 3;
          this.ctx.strokeRect(
            adjustedX - Math.round(annotation.rectWidth * this.zoomLevel),
            adjustedY - Math.round(annotation.rectHeight * this.zoomLevel),
            Math.round(annotation.rectWidth * this.zoomLevel),
            Math.round(annotation.rectHeight * this.zoomLevel)
          );

          this.savedState = this.ctx.getImageData(
            0,
            0,
            canvas.width,
            canvas.height
          );
        });

        // Save the current zoom level for the next redraw
        this.prevZoomLevel = this.zoomLevel;
      };
      img.src = 'data:image/tiff;base64,' + this.selectedExistingDocument.file;
    }
  }

  rotationAngle = 0;
  prevRotationAngle = 0;

  rotateClockwise() {
    this.rotationAngle = (this.rotationAngle + 90) % 360;
    this.redrawAnnotationsZoom();
  }

  rotateCounterclockwise() {
    this.rotationAngle = (this.rotationAngle - 90 + 360) % 360;
    this.redrawAnnotationsZoom();
  }

  convertCanvasToBase64() {
    const canvas = this.canvasRef.nativeElement;
    return canvas.toDataURL('image/tiff');
  }

  saveAnnotation() {
    this.loaderService.showLoader();
    this.clrAllZoomForSave();
    this.disableAnnotation = true;
    if (this.saveAnnotationAPICall) {
      this.saveAnnotationAPICall.unsubscribe();
    }
    try {
      setTimeout(() => {
        const base64Content = this.convertCanvasToBase64().split(',')[1];
        const annotatedDTO: AnnotatedDTO = {
          file: base64Content,
          pageNo: this.pageNumber,
          id: this.documentId,
        };
        this.saveAnnotationAPICall = this.inboundDocumentService
          .addAnnotationByDocumentIdByDto(annotatedDTO)
          .subscribe(
            (response) => {
              this.existingDocumentImageList[this.currentArrayIndex].file =
                base64Content;
              this.clrAllAnnotation();
              this.goToPage();
              this.zoomLevel = this.zoomLevelReSize;
              this.toastr.success(response, 'Success');
              this.disableAnnotation = false;
              this.loaderService.hideLoader();
            },
            (error) => {
              this.handleError(error);
            }
          );
      }, 1000);
    } catch (error) {
      this.handleError(error);
    }
  }

  private handleError(error: any) {
    const data: HttpErrorResponse = error;
    this.toastr.error(data?.error?.error?.message, 'Error');
    this.disableAnnotation = true;
    this.loaderService.hideLoader();
  }

  clearAllAnnotation() {
    Swal.fire({
      title: 'Clear All Annotation?',
      text: 'Are you sure you want to delete all annotation?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#34c38f',
      cancelButtonColor: '#f46a6a',
      confirmButtonText: 'Yes, Clear All!',
      buttonsStyling: true,
      allowOutsideClick: false,
    }).then((result) => {
      if (result.isConfirmed) {
        this.clrAllAnnotation();
        this.zoomLevel = this.zoomLevelReSize;
      }
    });
  }

  clrAllZoomForSave() {
    // this.zoomLevel = this.DEFAULT_ZOOM_LEVEL;
    this.zoomLevel = 1;
    this.redrawAnnotationsZoom();
  }

  clrAllAnnotation() {
    this.zoomLevel = this.DEFAULT_ZOOM_LEVEL;
    this.textAnnotations = [];
    this.redrawAnnotationsZoom();
  }

  fnRefreshButtonClicked() {
    this.refreshButtonClicked = true;
    this.loadInboundDocument(1, this.DEFAULT_LAZY_LOADING_API);
  }

  loadInboundDocument(from: number, to: number) {
    if (
      !this.loadInboundDocumentIsPrevoiusCalled ||
      this.refreshButtonClicked
    ) {
      this.loadInboundDocumentIsPrevoiusCalled = true;
      this.isLoading = true;
      this.isDisabled = true;
      let pageNo = 1;
      if (this.loadInboundDocumentAPICall$) {
        this.loadInboundDocumentAPICall$?.unsubscribe();
      }
      const loadInboundDocument = this.inboundDocumentService
        .getTIFFImageBase64v1ByGIdAndFromAndTo(this.documentId, this.from, 1)
        .subscribe(
          (response) => {
            this.totalPages = response?.files?.length || 0;
            this.overAllPagesBG = response?.totalPagecount || 0;
            this.overAllPages = response?.totalPagecount || 0;

            if (response && this.totalPages > 0) {
              this.TotalDocumentImageList = response.files.map((a) => {
                return {
                  ...a,
                  src: this.sanitizer.bypassSecurityTrustResourceUrl(
                    `data:image/tiff;base64, ${a.file}`
                  ),
                  pageNo: a.pageNo,
                };
              });
              this.selectedExistingDocument = this.TotalDocumentImageList?.[0];
              this.existingDocumentImageList = this.TotalDocumentImageList;
              this.loadTiffImageV1(this.existingDocumentImageList?.[0]);
              this.loadInboundDocumentLoadMoreInitial(this.from, 1);
            }
            this.isLoading = false;
            this.TiffNotLoadingError = false;
            this.TiffError = false;
            this.SortSaveError = false;
            this.refreshButtonClicked = false;
            this.isHideTiffViewer = false;
          },
          (err) => {
            this.TiffNotLoadingError = true;
            if (this.refreshButtonClicked) {
              this.refreshButtonClicked = false;
            }

            this.handleErrorResponse(err);
            this.isHideTiffViewer = true;
            if (this.isHideTiffViewer && !this.isEmpty(this.blobName)) {
              this.loadInboundDocumentPDF();
              this.loadOverAllSortingAPI();
            } else {
              console.error('File name  = ' + this.blobName);
            }
          }
        );
      this.loadInboundDocumentAPICall$ = loadInboundDocument;
    }
  }

  //Intialy to Load If less than 10 Tiff Images
  loadInboundDocumentLoadMoreInitial(from: number, to: number) {
    this.isTotalAPIcallGoingOn = true;
    this.from = to + 1;
    this.to = to + this.DEFAULT_LAZY_LOADING;
    this.loadMoreTiffImage();
  }
  //This function Call when Tiff Image More than 10 Images
  loadInboundDocumentLoadMore(from: number, to: number) {
    this.isTotalAPIcallGoingOn = true;
    if (this.overAllPagesBG >= this.to) {
      this.from = to + 1;
      this.to = to + this.DEFAULT_LAZY_LOADING;
      this.loadMoreTiffImage();
    } else {
      this.isTotalAPIcallGoingOn = false;
      this.loadOverAllSortingAPI();
      this.isDisabled = false;
    }
  }
  //To Bind Tiff Images
  loadMoreTiffImage() {
    if (this.loadInboundDocumentAPICall$) {
      this.loadInboundDocumentAPICall$?.unsubscribe();
    }
    const loadInboundDocument = this.inboundDocumentService
      .getTIFFImageBase64v1ByGIdAndFromAndTo(
        this.documentId,
        this.from,
        this.to
      )
      .subscribe(
        (response) => {
          let totalPagesLoadMore = response.files.length;
          if (response && totalPagesLoadMore > 0) {
            // Assuming response is an array of items to be pushed into the list
            response.files.forEach((item) => {
              const newItem = {
                ...item,
                src: this.sanitizer.bypassSecurityTrustResourceUrl(
                  `data:image/tiff;base64, ${item.file}`
                ),
                pageNo: item.pageNo,
              };
              this.TotalDocumentImageList.push(newItem);
              item = null;
            });
            this.overAllPages = this.TotalDocumentImageList.length;
            if (this.selectedOption === this.sotingSelectedCSS.Total) {
              this.totalPages = this.TotalDocumentImageList.length;
              this.existingDocumentImageList = this.TotalDocumentImageList;
            }
          }
          this.isDisabled = false;
          this.loadInboundDocumentLoadMore(this.from, this.to);
        },
        (err) => {
          this.isDisabled = false;
          this.isTotalAPIcallGoingOn = false;
          // this.handleErrorResponse(err);
          this.loadOverAllSortingAPI(); // If 21 to 30 Case - Round Off 0
        }
      );
    this.loadInboundDocumentAPICall$ = loadInboundDocument;
  }

  // 0 - Fully Doc Loaded
  // 1 - Not Fully loaded
  loadOverAllSortingAPI(flag: number = 0) {
    // this.sortingDocView()
    // this.isLoadSortingView = true;
    this.isLoadOverAllSortingAPI = true;
    if (this.loadloadOverAllSortingAPICall$) {
      this.loadloadOverAllSortingAPICall$?.unsubscribe();
    }
    // if(flag === 1){
    //   this.isTotalAPIcallGoingOnCount++
    //   // console.log('this.isTotalAPIcallGoingOnCount Flag')
    //   // console.log(this.isTotalAPIcallGoingOnCount)
    // }
    // // console.log('this.isTotalAPIcallGoingOnCount withoutFlag')
    // //   console.log(this.isTotalAPIcallGoingOnCount)
    // setTimeout(() => {
    //   this.isTotalAPIcallGoingOnCount++
    // }, 1000);
    const loadInboundDocument = this.inboundDocumentService
      // .getSortedDocumentPagesv2ByGDocumentId(this.documentId)
      .getSortedPatientDocumentsByGPatientDocId(this.documentId)
      .subscribe(
        (response) => {
          let noPagesCount =
            response?.sortedDocuments.length + response?.pending.length;
          this.docSortedPages = response?.sortedDocuments.length;
          this.docUnSortedPages = response?.pending.length;
          this.docOverallPages = noPagesCount;

          this.documentTypes = response?.ltDocuments || [];
          this.isLoadSortingView = false;
          if (!this.isTotalAPIcallGoingOn) {
            this.PendingDocumentImageList = this.TotalDocumentImageList.filter(
              (dx) => response.pending.includes(dx.pageNo)
            );

            if (this.selectedOption === this.sotingSelectedCSS.Pending) {
              this.loadPendingDocument();
            }
            this.SortedDocumentImageList = this.TotalDocumentImageList.filter(
              (dx) => response.sortedDocuments.includes(dx.pageNo)
            );

            if (this.selectedOption === this.sotingSelectedCSS.Sorted) {
              this.loadSortedDocument();
            }

            // Function to add docPageContent to documentTypesV1
            this.documentTypes.forEach((docType) => {
              if (docType.docPage) {
                docType.docPageContent = this.TotalDocumentImageList.filter(
                  (doc) => docType.docPage.includes(doc.pageNo)
                );
              } else {
                docType.docPageContent = [];
              }
            });
          }
          // if (flag === 1 || flag === 0) {
          //   console.log(flag + "---------------------------------------------------------------")
          //   this.isTotalAPIcallGoingOnCount++;
          // }
          this.isTotalAPIcallGoingOnCount++;
          setTimeout(() => {
            this.isTotalAPIcallGoingOnCount++;
          }, 500);
        },
        (err) => {
          this.isLoadSortingView = false;
          if (flag === 1) {
            this.isTotalAPIcallGoingOnCount++;
          }
          // this.handleErrorResponse(err);
          setTimeout(() => {
            this.isTotalAPIcallGoingOnCount++;
          }, 500);
        }
      );
    this.loadloadOverAllSortingAPICall$ = loadInboundDocument;
  }

  loadTotalDocument() {
    this.isTotalAPIcallGoingOnCount++;
    if (this.mustSaveAnnotations()) {
      this.document_shortCode = 'ABC';
      this.tiffPageNo = 1;
      this.pageNumber = 1;
      this.currentArrayIndex = 0;
      this.isLoading = true;
      this.totalPages = this.TotalDocumentImageList.length;
      if (this.totalPages > 0) {
        this.existingDocumentImageList = this.TotalDocumentImageList;
        this.loadTiffImageV1(this.existingDocumentImageList?.[0]);
      } else {
        this.TiffError = true;
        this.TotalDocumentImageList = [];
        this.loadingMessage = 'Total Images has not found';
        setTimeout(() => {
          this.clearCanvas();
        }, 500);
      }
      this.isLoading = false;
      this.selectedOption = sotingSelectedCSS.Total;
    } else {
      this.pageNumber =
        this.existingDocumentImageList[this.currentArrayIndex].pageNo;
    }
  }

  loadPendingDocument() {
    if (!this.isLoadSortingView) {
      if (this.mustSaveAnnotations()) {
        this.document_shortCode = 'ABC';
        this.tiffPageNo = 1;
        this.pageNumber = 1;
        this.currentArrayIndex = 0;
        this.isLoading = true;
        this.totalPages = this.PendingDocumentImageList.length;
        if (this.totalPages > 0) {
          this.existingDocumentImageList = this.PendingDocumentImageList;
          this.loadTiffImageV1(this.existingDocumentImageList?.[0]);
        } else {
          this.TiffError = true;
          this.PendingDocumentImageList = [];
          this.loadingMessage = 'Unsorted Images has not found';
          setTimeout(() => {
            this.clearCanvas();
          }, 500);
        }
        this.isLoading = false;
      } else {
        this.pageNumber =
          this.existingDocumentImageList[this.currentArrayIndex].pageNo;
      }
    } else {
      this.TiffError = true;
      this.loadingMessage = 'Loading Unsorted Images...';
      setTimeout(() => {
        this.clearCanvas();
      }, 500);
    }
    this.selectedOption = sotingSelectedCSS.Pending;
  }

  loadSortedDocument() {
    if (!this.isLoadSortingView) {
      if (this.mustSaveAnnotations()) {
        this.document_shortCode = 'ABC';
        this.tiffPageNo = 1;
        this.pageNumber = 1;
        this.currentArrayIndex = 0;
        this.isLoading = true;
        this.totalPages = this.SortedDocumentImageList.length;
        if (this.totalPages > 0) {
          this.existingDocumentImageList = this.SortedDocumentImageList;
          this.loadTiffImageV1(this.existingDocumentImageList?.[0]);
        } else {
          this.TiffError = true;
          this.SortedDocumentImageList = [];
          this.loadingMessage = 'Sorted Images has not found';
          setTimeout(() => {
            this.clearCanvas();
          }, 500);
        }
        this.isLoading = false;
      } else {
        this.pageNumber =
          this.existingDocumentImageList[this.currentArrayIndex].pageNo;
      }
    } else {
      this.TiffError = true;
      this.loadingMessage = 'Loading Sorted Images...';
      setTimeout(() => {
        this.clearCanvas();
      }, 500);
    }
    this.selectedOption = sotingSelectedCSS.Sorted;
  }

  loadBinDocument(shortCode: string, pagecount: number) {
    if (this.mustSaveAnnotations()) {
      this.tiffPageNo = 1;
      this.pageNumber = 1;
      this.currentArrayIndex = 0;
      if (pagecount !== 0) {
        const filteredDocuments = this.documentTypes.filter(
          (doc) => doc.shortCode === shortCode
        );
        // Extract docPageContent
        const docPageContent = filteredDocuments.map(
          (doc) => doc.docPageContent
        );
        this.isLoading = true;
        this.totalPages = docPageContent[0]?.length || 0;
        if (this.totalPages > 0) {
          this.existingDocumentImageList = docPageContent[0].map((a) => {
            return {
              ...a,
              src: this.sanitizer.bypassSecurityTrustResourceUrl(
                `data:image/tiff;base64, ${a.file}`
              ),
              pageNo: a.pageNo,
            };
          });
          this.loadTiffImageV1(this.existingDocumentImageList?.[0]);
        } else {
          this.clearCanvas();
          this.TiffError = true;
          this.loadingMessage = 'Images has not found';
        }
        this.isLoading = false;
      } else {
        this.TiffError = true;
        this.loadingMessage = 'Images has not found';
        setTimeout(() => {
          this.clearCanvas();
        }, 500);
      }
      this.document_shortCode = shortCode;
      this.selectedOption = sotingSelectedCSS.Bin;
    } else {
      this.pageNumber =
        this.existingDocumentImageList[this.currentArrayIndex].pageNo;
    }
  }

  setDateValue = (dateValue: any) => {
    return dateValue ? new Date(dateValue).toISOString().substring(0, 10) : '';
  };

  setDateValueV1 = (dateValue: any) => {
    if (!dateValue) return '';

    const utcDate = new Date(dateValue);
    const cstOffset = -5; // Central Time offset is UTC-5 -- (GMT-5 time in Austin, TX, USA)

    // Calculate UTC time with the Central Time offset
    const cstTime = new Date(utcDate.getTime() + cstOffset * 60 * 60 * 1000);

    // Format the date in YYYY-MM-DD format
    return cstTime.toISOString().substring(0, 10);
  };

  setDateValueV2 = (dateValue: any) => {
    if (!dateValue) return '';
    return this.datepipe.transform(dateValue, 'yyyy-MM-dd');
  };

  private handleErrorResponse(err: any) {
    const data: HttpErrorResponse = err;
    // console.warn(data?.error?.error?.message);
    this.loadingMessage = 'TIFF Image conversion has been In Progress.';
    this.TiffError = true;
    this.SortSaveError = true;
    this.isLoading = false;
    this.isDisabled = false;
  }

  addSlashToDate(event: Event): void {
    const input = event.target as HTMLInputElement;
    const inputValue = input.value;
    input.value = inputValue
      .replace(/^(\d{2})(\d{1,2})/, '$1/$2')
      .replace(/^(\d{2}\/\d{2})(\d+)/, '$1/$2')
      .replace(/[^\d\/]/g, '');
  }

  loadInboundDocumentPDF() {
    // if(this.isEmpty(this.strPdfString)) {
    if (this.strPdfString === '') {
      this.showPdfViwer = false;
      this.inboundDocumentService
        // .downloadInboundDocumentByBlobName(this.data.blobName)
        .downloadInboundDocumentByBlobName(this.blobName)
        .subscribe(
          (response) => {
            if (
              typeof response === 'string' &&
              response &&
              response !== null &&
              response?.length !== 0
            ) {
              this.strPdfString = String(response);
            }
            this.showPdfViwer = true;
          },
          (err) => {
            this.strSelectedPdfPath = '';
            this.showPdfViwer = false;

            const data: HttpErrorResponse = err;
            this.toastr.error(data?.error?.error?.message);
          }
        );
    }
  }

  pushorUpdateIntoSaveArray(pageNumber: number, shortCode: string) {
    // console.log('pushorUpdateIntoSaveArray API called');
    if (
      this.LastSortingPageNo === pageNumber &&
      this.LastSortingShortCode === shortCode
    ) {
      this.showNotification('Already Sorted');
    }
    this.LastSortingPageNo = pageNumber;
    this.LastSortingShortCode = shortCode;
    let documentDetails: SortedDocumentGroupDTO;
    documentDetails = this.documentTypes.find(
      (value) => value.shortCode === shortCode
    );
    let ctDoc = this.existingDocumentImageList.find(
      (value) => value.pageNo === pageNumber
    );

    let existingIndex = this.saveDocumentDetails.findIndex(
      (item) => item.pageNumber === ctDoc.pageNo
    );
    if (ctDoc.pageNo != 0) {
      if (existingIndex !== -1) {
        this.saveDocumentDetails[existingIndex].documentTypeId =
          documentDetails.documentTypeId;
        this.saveDocumentDetails[existingIndex].documentType =
          documentDetails.documentType;
      } else {
        let SortDocumentDto: SaveDocumentDto = {
          patientId: this.patientId,
          sortType: SortType.Manual,
          documentTypeId: documentDetails.documentTypeId,
          documentType: documentDetails.documentType,
          pageNumber: ctDoc.pageNo,
          inboundDocumentId: this.documentId,
        };
        this.saveDocumentDetails.push(SortDocumentDto);
      }
    }

    // this.UpdateSorting_Pending_BinArray(ctDoc.pageNo, shortCode);
    // this.showNotification('Sorting has been temporarily saved !');
  }

  UpdateSorting_Pending_BinArray(pageNo: number, shortCode: string) {
    let existingPendingIndex = this.PendingDocumentImageList.findIndex(
      (item) => item.pageNo === pageNo
    );
    let removedPendItem = [];
    if (existingPendingIndex !== -1) {
      // Remove item from PendingDocumentImageList and add it to SortedDocumentImageList
      removedPendItem = this.PendingDocumentImageList.splice(
        existingPendingIndex,
        1
      )[0];
      this.SortedDocumentImageList.push(removedPendItem);
      this.sortedPages = this.SortedDocumentImageList.length;
      this.pendingPages = this.PendingDocumentImageList.length;
      let existingBinIndex = this.documentTypes.findIndex(
        (value) => value.shortCode === shortCode
      );
      if (!this.documentTypes[existingBinIndex].docPageContent) {
        this.documentTypes[existingBinIndex].docPageContent = [];
        this.documentTypes[existingBinIndex].pageCount = 0;
      }
      // Push removedItem into docPageContent and update pageCount
      this.documentTypes[existingBinIndex].docPageContent.push(removedPendItem);
      this.documentTypes[existingBinIndex].pageCount =
        this.documentTypes[existingBinIndex].docPageContent.length;
    }

    let BinCount = 0;
    this.documentTypes.forEach((document) => {
      // Check if the document has a docPageContent array and if it does, remove items based on page number
      if (document.docPageContent && document.docPageContent.length > 0) {
        document.docPageContent.forEach((content, index) => {
          // Check if the page number exists in docPageContent, if so, remove it
          const pageIndex = document.docPageContent.findIndex(
            (content) => content.pageNo === pageNo
          );
          if (pageIndex !== -1) {
            BinCount++;
            // Remove the item from docPageContent
            document.docPageContent.splice(pageIndex, 1);
            // Update the pageCount accordingly
            document.pageCount = document.docPageContent.length;
          }
        });
      }
    });

    if (BinCount !== 0) {
      const findPageinTotal = this.TotalDocumentImageList.find(
        (content) => content.pageNo === pageNo
      );

      let existingBinIndex = this.documentTypes.findIndex(
        (value) => value.shortCode === shortCode
      );
      if (!this.documentTypes[existingBinIndex].docPageContent) {
        this.documentTypes[existingBinIndex].docPageContent = [];
        this.documentTypes[existingBinIndex].pageCount = 0;
      }
      // Push removedItem into docPageContent and update pageCount
      this.documentTypes[existingBinIndex].docPageContent.push(findPageinTotal);
      this.documentTypes[existingBinIndex].pageCount =
        this.documentTypes[existingBinIndex].docPageContent.length;
    }
  }
  manualGoToPage() {
    if (this.mustSaveAnnotations()) {
      if (this.existingDocumentImageList.length === 0) {
        this.TiffError = true;
        this.loadingMessage = 'Images has not found';
        setTimeout(() => {
          this.clearCanvas();
        }, 500);
      } else if (this.pageNumber <= 0) {
        this.pageNumber = 1;
        this.firstImage();
      } else if (this.pageNumber >= 1 && this.pageNumber <= this.totalPages) {
        this.currentArrayIndex = this.pageNumber - 1;
        this.loadExistingImage(
          this.existingDocumentImageList[this.currentArrayIndex]
        );
      } else {
        this.pageNumber = this.totalPages;
        this.lastImage();
      }
    } else {
      // this.pageNumber =
      //   this.existingDocumentImageList[this.currentArrayIndex].pageNo;
      this.nextImage();
    }
  }
  revertAllV1(inputValue: any) {
    console.log('this.pageNumber = ' + this.pageNumber);
    let removePageNo = 0;
    if (this.existingDocumentImageList.length > 0) {
      removePageNo = this.existingDocumentImageList[this.pageNumber - 1].pageNo;

      let existingSortedIndex = this.SortedDocumentImageList.findIndex(
        (item) => item.pageNo === removePageNo
      );
      let removedSortItem = [];
      if (existingSortedIndex !== -1) {
        // Remove item from PendingDocumentImageList and add it to SortedDocumentImageList
        removedSortItem = this.SortedDocumentImageList.splice(
          existingSortedIndex,
          1
        )[0];
        this.PendingDocumentImageList.push(removedSortItem);
        this.sortedPages = this.SortedDocumentImageList.length;
        this.pendingPages = this.PendingDocumentImageList.length;
      }

      this.documentTypes.forEach((document) => {
        // Check if the document has a docPageContent array and if it does, remove items based on page number
        if (document.docPageContent && document.docPageContent.length > 0) {
          document.docPageContent.forEach((content, index) => {
            // Check if the page number exists in docPageContent, if so, remove it
            const pageIndex = document.docPageContent.findIndex(
              // (content) => content.pageNo === this.actualPageNo
              (content) => content.pageNo === removePageNo
            );
            if (pageIndex !== -1) {
              // Remove the item from docPageContent
              document.docPageContent.splice(pageIndex, 1);
              // Update the pageCount accordingly
              document.pageCount = document.docPageContent.length;
            }
          });
        }
      });

      const exPageIndex = this.existingDocumentImageList.findIndex(
        // (content) => content.pageNo === this.actualPageNo
        (content) => content.pageNo === removePageNo
      );

      if (exPageIndex !== -1) {
        // Remove the item from docPageContent
        this.existingDocumentImageList.splice(exPageIndex, 1);
        if (!this.removeSorting.includes(removePageNo)) {
          this.removeSorting.push(removePageNo);
        }
      }

      this.totalPages = this.existingDocumentImageList.length;
      if (this.totalPages !== 0 && this.totalPages > exPageIndex) {
        this.loadExistingImage(this.existingDocumentImageList[exPageIndex]);
      } else {
        // this.totalPages = this.existingDocumentImageList.length;
        // if(this.totalPages > 0){
        // this.firstImage();
        // }else{
        this.TiffError = true;
        this.loadingMessage = 'Images has not found';
        setTimeout(() => {
          this.clearCanvas();
        }, 500);
        // }
      }
    }
  }
  revertAll(inputValue: any) {
    // console.log('this.actualPageNo')
    // console.log(this.actualPageNo)
    // console.log('this.pageNumber')
    // console.log(this.pageNumber)
    // console.log('inputValue')
    // console.log(inputValue)

    // console.log('this.existingDocumentImageList');
    // console.log(this.existingDocumentImageList);

    // console.log('this.saveDocumentDetails');
    // console.log(this.saveDocumentDetails);

    // console.log('this.documentTypes');
    // console.log(this.documentTypes);

    let removePageNo = 0;
    if (this.existingDocumentImageList.length > 0) {
      removePageNo = this.existingDocumentImageList[this.pageNumber - 1].pageNo;
      const exPageIndex = this.existingDocumentImageList.findIndex(
        // (content) => content.pageNo === this.actualPageNo
        (content) => content.pageNo === removePageNo
      );
      if (exPageIndex !== -1) {
        // Remove the item from docPageContent
        this.existingDocumentImageList.splice(exPageIndex, 1);
        if (!this.removeSorting.includes(removePageNo)) {
          this.removeSorting.push(removePageNo);
        }
      }
      this.totalPages = this.existingDocumentImageList.length;
      if (this.totalPages !== 0 && this.totalPages > exPageIndex) {
        this.loadExistingImage(this.existingDocumentImageList[exPageIndex]);
      } else {
        // this.totalPages = this.existingDocumentImageList.length;
        // if(this.totalPages > 0){
        // this.firstImage();
        // }else{
        this.TiffError = true;
        this.loadingMessage = 'Images has not found';
        setTimeout(() => {
          this.clearCanvas();
        }, 500);
        // }
      }
      // this.manualGoToPage();

      const sdPageIndex = this.saveDocumentDetails.findIndex(
        // (content) => content.pageNumber === this.actualPageNo
        (content) => content.pageNumber === removePageNo
      );
      if (sdPageIndex !== -1) {
        // Remove the item from docPageContent
        this.saveDocumentDetails.splice(sdPageIndex, 1);
        // Update the pageCount accordingly
        // document.pageCount = document.docPageContent.length;
      }

      this.documentTypes.forEach((document) => {
        // Check if the document has a docPageContent array and if it does, remove items based on page number
        if (document.docPageContent && document.docPageContent.length > 0) {
          document.docPageContent.forEach((content, index) => {
            // Check if the page number exists in docPageContent, if so, remove it
            const pageIndex = document.docPageContent.findIndex(
              // (content) => content.pageNo === this.actualPageNo
              (content) => content.pageNo === removePageNo
            );
            if (pageIndex !== -1) {
              // Remove the item from docPageContent
              document.docPageContent.splice(pageIndex, 1);
              // Update the pageCount accordingly
              document.pageCount = document.docPageContent.length;
            }
          });
        }
      });

      let existingSortedIndex = this.SortedDocumentImageList.findIndex(
        (item) => item.pageNo === removePageNo
      );
      let removedSortItem = [];
      if (existingSortedIndex !== -1) {
        // Remove item from PendingDocumentImageList and add it to SortedDocumentImageList
        removedSortItem = this.SortedDocumentImageList.splice(
          existingSortedIndex,
          1
        )[0];
        this.PendingDocumentImageList.push(removedSortItem);
        this.sortedPages = this.SortedDocumentImageList.length;
        this.pendingPages = this.PendingDocumentImageList.length;
      }

      // this.nextImage();
    } else {
      // this.firstImage();
      this.TiffError = true;
      this.loadingMessage = 'Images has not found';
      setTimeout(() => {
        this.clearCanvas();
      }, 500);
    }

    // this.previousImage();
    // this.goToPage()
    // console.log('this.currentArrayIndex' + this.currentArrayIndex)
    // this.loadExistingImage(this.existingDocumentImageList[this.currentArrayIndex])
    // console.log('--this.actualPageNo--')
    // console.log(this.actualPageNo)
    // console.log('this.pageNumber --')
    // console.log(this.pageNumber)
    // console.log('--inputValue--')
    // console.log(inputValue)

    // console.log('--this.saveDocumentDetails--');
    // console.log(this.saveDocumentDetails);

    // console.log('--this.documentTypes--');
    // console.log(this.documentTypes);

    // console.log('--this.existingDocumentImageList--')
    // console.log(this.existingDocumentImageList)
  }

  async moveDocument(inputValue: any, document: any) {
    if (this.isTotalAPIcallGoingOnCount > 1) {
      if (
        this.overAllPages === this.overAllPagesBG &&
        this.overAllPagesBG !== 0
      ) {
        this.docUnSortedPages = this.PendingDocumentImageList?.length;
        if (this.docUnSortedPages === this.PendingDocumentImageList?.length) {
          if (this.PendingDocumentImageList?.length >= 1) {
            this.docUnSortedPages = this.docUnSortedPages - 1;
          }
        }
        let len = this.existingDocumentImageList.length;
        this.isMoved = true;
        this.isSortingStart = true;
        this.isDisabled = true;
        try {
          if (document.shortCode !== SortingShortCode.All) {
            var split = [];
            if (this.checkValidInput(inputValue)) {
              if (this.checkValidInputCase === 1) {
                split.push(Number(inputValue));
              } else if (this.checkValidInputCase === 2) {
                split = inputValue?.split(',');
                split = split.map((a) => Number(a));
              } else if (this.checkValidInputCase === 3) {
                let splitwithHyphen = inputValue?.split('-');
                for (
                  var i = Number(splitwithHyphen[0]);
                  i <= Number(splitwithHyphen[1]);
                  i++
                ) {
                  split.push(Number(i));
                }
              } else if (this.checkValidInputCase === 4) {
                split.push(this.pageNumber);
              } else {
                split = [];
              }
              let uniqueArray = [...new Set(split)];
              if (
                uniqueArray.every(
                  (num) =>
                    num > 0 && num <= this.existingDocumentImageList.length
                )
              ) {
                let PageArray = [];
                for (const x of uniqueArray) {
                  let page = this.existingDocumentImageList[x - 1].pageNo;
                  this.pageNumber = x + 1;
                  PageArray.push(page);
                  if (x <= len) {
                    this.pushorUpdateIntoSaveArray(page, document.shortCode);
                    const indexRe = this.removeSorting.indexOf(page); // Find the index of the value to be removed
                    if (indexRe !== -1) {
                      this.removeSorting.splice(indexRe, 1); // Remove the value at the found index
                    }
                  } else {
                    this.showNotification(
                      'Please enter valid input - Zero not allowed, or less than zero not allowed, or maximum allowed ' +
                        len
                    );
                  }
                }
                for (const p of PageArray) {
                  this.UpdateSorting_Pending_BinArray(p, document.shortCode);
                }
                PageArray = [];
                let uploadTiffLoadedIndex = this.documentTypes.findIndex(
                  (value) => value.shortCode === this.document_shortCode
                );
                if (uploadTiffLoadedIndex !== -1) {
                  this.existingDocumentImageList =
                    this.documentTypes[uploadTiffLoadedIndex].docPageContent;
                }
                this.totalPages = this.existingDocumentImageList.length;
                if (this.pageNumber > this.totalPages) {
                  this.pageNumber = this.totalPages;
                }

                if (this.pageNumber === 0) {
                  this.TiffError = true;
                  this.loadingMessage = 'Images has not found';
                  setTimeout(() => {
                    this.clearCanvas();
                  }, 500);
                }

                if (
                  this.selectedOption === sotingSelectedCSS.Sorted ||
                  this.selectedOption === sotingSelectedCSS.Total
                ) {
                  // this.pageNumber=uniqueArray.length+1;
                  // this.pageNumber++;
                  this.goToPage();
                } else {
                  this.goToPage();
                }
              } else {
                this.showNotification(
                  'Please enter valid input - Zero not allowed, or less than zero not allowed, or maximum allowed ' +
                    len
                );
              }
              this.arrowButtonClicked = true;
            } else {
              this.toastr.error('Please enter vaild input');
            }
          }
        } catch (error) {
          console.error('Error:', error);
        } finally {
          this.isDisabled = false;
          this.myInputRefs.forEach((inputRef) => {
            inputRef.nativeElement.value = '';
          });
          this.cd.detectChanges();
        }
      } else {
        this.showNotification('Please Wait Document is Loading ..... ');
      }
    } else {
      this.showNotification('Please Wait pages count is loading ..... ');
    }
  }

  clearCanvas() {
    const canvas = this.canvasRef.nativeElement;
    this.ctx = canvas.getContext('2d');
    this.ctx.clearRect(0, 0, canvas.width, canvas.height);
    this.selectedExistingDocument = null;
    this.existingDocumentImageList = [];
    this.pageNumber = 0;
    this.totalPages = 0;
  }

  showNotification(message: string): void {
    this.snackBar.open(message, 'Close', {
      duration: 3000, // Duration in milliseconds
      horizontalPosition: 'end', // Positioning the notification horizontally
      verticalPosition: 'top', // Positioning the notification vertically
    });
  }

  //Patient Search Open Modal [By-Deepak] - 04/20/2024]
  openSearchMdl() {
    const config: MatDialogConfig = {
      disableClose: true,
      data: {
        patientIDV1: this.patientId,
      },
    };
    const dialogRef = this.dialog.open(PatientSearchNewsetupComponent, config);

    const closeDialog = dialogRef.afterClosed().subscribe(
      () => {},
      (err) => {
        const data: HttpErrorResponse = err;
        this.toastr.error(data?.error?.error?.message);
      }
    );
  }

  //View Patient Note [By-Deepak] - 04/20/2024]
  openViewNotesPopup() {
    const config: MatDialogConfig = {
      disableClose: true,
      width: '70vw',
      maxWidth: '70vw',
      height: '40vw',
      maxHeight: '40vw',
      data: {
        patientId: this.patientId,
        pageType: 'Patient Note',
        isAddVisible: false,
        defaultFaxId: this.defaultFaxId,
        docId: this.documentId,
      },
    };
    const dialogRef = this.dialog.open(PatientCommonNotesComponent, config);

    dialogRef.afterClosed().subscribe(
      () => {},
      (err: HttpErrorResponse) => {
        const data: HttpErrorResponse = err;
        this.toastr.error(data?.error?.error?.message);
      }
    );
  }

  //View Patient Document [By-Deepak] - 04/20/2024]
  viewPatientDocument() {
    const url =
      '/#/patient_preview_tiff_document/' +
      [this.defaultFaxIdV1] +
      '/' +
      [this.documentIdV1];
    window.open(url, '_blank');
  }

  //Map User [By-Deepak - 04/20/2024]
  OpenMapUser() {
    const vconfig: MatDialogConfig = {
      disableClose: true,
      minHeight: '30vh',
      minWidth: '50vw',
      data: {
        docId: this.documentId,
      },
    };
    const dialogRef = this.dialog.open(SendToAnotherUserComponent, vconfig);
    const closeDialog = dialogRef.afterClosed().subscribe(
      (patientNotes: any) => {
        // const vpatientNotes = JSON.parse(patientNotes);
        // if (vpatientNotes) {
        //   data.patientNotes = vpatientNotes.notes;
        //   data.csrName = vpatientNotes.csrName;
        //   this.collapseAllRows();
        // }
      },
      (err) => {
        const data: HttpErrorResponse = err;
        this.toastr.error(data?.error?.error?.message);
      }
    );
    this.subscription$.push(closeDialog);
  }

  //join Fax [By-Deepak - 04/23/2024]
  joinFaxModal() {
console.log("this.blobName", this.blobName);
console.log("this.selectedNode");
console.log(this.selectedNode);

    const dialogRef = this.dialog.open(JoinFaxComponent, {
      disableClose: true,
      data: {
        faxID: this.defaultFaxId,
        docID: this.documentId,
        branchID: this.branchId,
        documentName: this.documentName,
        // documentName: null,
        selectedNode: this.selectedNode,
        isNewSetup: true,


      },
    });
    dialogRef.afterClosed().subscribe((a) => {
      if (a) {
        window.close();
      }
      // this.redirectDahboard();
    });
  }

  //Document CheckList Popup[By-Deepak - 04/24/204]
  checkListModel(isPlan: boolean) {
    const dialogRef = this.dialog.open(DocumentCheckListComponent, {
      height: '75%',
      width: '80%',
      disableClose: true,
      data: {
        documentId: this.documentId,
        faxId: this.defaultFaxId,
        isNewSetup: isPlan,
        isPlanSave: true,
      },
    });

    const closeDialog = dialogRef.afterClosed().subscribe(
      (isDocument: any) => {
        // this.saveDemographics(true);
        // this.redirectDahboard();
      },
      (err) => {
        const data: HttpErrorResponse = err;
        console.warn(data?.error?.error?.message);
      }
    );
  }

  saveDocumentBin() {
    this.DocumentBinSaveBtn = true;
    let text = this.mstDocumentBinForm.value.txtBinName.trim();
    let documentBin: CreateUpdateDocumentBinDTO = {
      documentType: text.trim(),
      isDefault: false,
      TenantId: localStorage.getItem('tenantId').trim() ?? defaultGuid,
    };

    this.documentBinService.create(documentBin).subscribe(
      (response) => {
        this.toastr.success('Bin Name Saved Successfully');
        this.mstDocumentBinForm.reset();
        // this.loadOverAllSortingAPI();

        const loadInboundDocument = this.inboundDocumentService
          // .getSortedDocumentPagesv2ByGDocumentId(this.documentId)
          .getSortedPatientDocumentsByGPatientDocId(this.documentId)
          .subscribe((response) => {
            response?.ltDocuments.forEach((element) => {
              let existingBinIndex = this.documentTypes.findIndex(
                (value) => value.documentTypeId === element.documentTypeId
              );
              if (existingBinIndex === -1) {
                element.docPageContent = [];
                this.documentTypes.push(element);
              }
            });
            this.documentTypes.sort((a, b) =>
              a.documentType.localeCompare(b.documentType)
            );
          });
        this.DocumentBinSaveBtn = false;
      },
      (err) => {
        const data: HttpErrorResponse = err;
        this.toastr.error(data?.error?.error?.message, 'Error');
        this.DocumentBinSaveBtn = false;
      }
    );
  }

  annotationMode(flag: number) {
    this.annotationModeFlag = flag;
    switch (flag) {
      case tiffPointerMode.crosshair:
        this.cursorStyle = 'crosshair';
        this.annotationModeFlagOld = flag;
        break;
      case tiffPointerMode.text:
        this.cursorStyle = 'text';
        this.annotationModeFlagOld = flag;
        break;
      case tiffPointerMode.help:
        this.cursorStyle = 'help';
        // this.annotationModeFlagOld = this.annotationModeFlagOld;
        break;
      case tiffPointerMode.grab:
        this.cursorStyle = 'grab';
        this.annotationModeFlagOld = flag;
        break;
      case tiffPointerMode.none:
        this.cursorStyle = 'none';
        this.annotationModeFlagOld = flag;
        // this.cursorStyleCSS = 'circularcursor';
        break;
      default:
        this.cursorStyle = 'crosshair'; //'default';
        this.annotationModeFlagOld = flag;
        break;
    }
  }

  //Check Add Document Bin Uniqness
  checkDocumentBinUniqness(): AsyncValidatorFn {
    return (
      control: AbstractControl
    ): Observable<{ [key: string]: any } | null> => {
      const gid = this.documentBinId ? this.documentBinId : null;
      const input: ValidateDocumentBinDTO = {
        id: gid,
        documentType:
          String(control?.value ?? '')
            ?.toLowerCase()
            ?.trim() ?? '',
      };
      return this.documentBinService.addDocumentBinCheckByValidate(input).pipe(
        tap((x) => control?.setErrors({ isDocumentBinExists: true })),
        debounceTime(300),
        map((response) =>
          response === true
            ? { isDocumentBinExists: true }
            : control?.setErrors(null) == null
            ? null
            : null
        )
      );
    };
  }

  //#region Patient Document PDF Download
  patientDocPdfDownload() {
    this.isDownloading = true;
    this.inboundDocumentService
      .downloadInboundDocumentByBlobName(this.blobName)
      .subscribe(
        (response) => {
          if (
            typeof response === 'string' &&
            response &&
            response !== null &&
            response.length !== 0
          ) {
            this.strPdfString = String(response);
            // Decode base64 string to binary data
            const byteCharacters = atob(this.strPdfString);
            const byteNumbers = new Array(byteCharacters.length);
            for (let i = 0; i < byteCharacters.length; i++) {
              byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);

            const blob = new Blob([byteArray], { type: 'application/pdf' });
            // const fileName = this.defaultFaxId;
            const fileName = this.txtChartV1 + '-' + this.txtPatientFullName;
            // Create a link element
            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = fileName;

            // Append the link to the body
            document.body.appendChild(link);

            // Programmatically click the link to trigger the download
            link.click();

            // Remove the link from the DOM
            document.body.removeChild(link);
          }
          this.isDownloading = false;
        },
        (err: HttpErrorResponse) => {
          this.isDownloading = false;
          console.error('Error downloading the document', err);
        }
      );
  }
  // #endregion Patient Document PDF Download

  //#region View Patient Document as PDF
  // fileURL = null;

  // viewPatientBlobPDF() {
  //   this.inboundDocumentService.downloadInboundDocumentByBlobName(this.blobName)
  //     .subscribe((response) => {
  //       if (typeof response === 'string' && response.length > 0) {
  //         this.pdfViewString = response;
  //         const byteCharacters = atob(this.pdfViewString);
  //         const byteNumbers = Array.from(byteCharacters, char => char.charCodeAt(0));
  //         const byteArray = new Uint8Array(byteNumbers);
  //         const blob = new Blob([byteArray], { type: "application/pdf" });
  //         this.fileURL = URL.createObjectURL(blob);
  //         window.open(this.fileURL);
  //       }
  //     }, (error) => {
  //       console.error('Error downloading document:', error);
  //     });
  // }

  viewPatientBlobPDF() {
    const url =
      '/#/patient_preview_pdf_document/' +
      this.documentId +
      '/' +
      this.blobName;
    window.open(url, '_blank');
  }
  // // #endregion View Patient Document as PDF
  func(event): void {
    if (event.key !== 'checkNewTabData') return;
    if (event.newValue === 'NewServiceDrpDataSaved') {
      this.lstServiceType = [];
      this.filteredServiceType = [];
      this.ltCategories = [];
      this.filteredCategoryDetails = of([]);
      // const serviceTypeDropdown = this.tranferStatusService
      //   .ServiceTypeDetailsV1()
      //   .subscribe(
      //     (stateResponse) => {
      //       this.lstServiceType = stateResponse;
      //       this.filteredServiceType = this.lstServiceType.sort((a, b) =>
      //         a.categoryName.localeCompare(b.categoryName)
      //       );
      //     },
      //     (err: HttpErrorResponse) => {
      //       this.toastr.error(err?.error?.error?.message, 'Error');
      //     }
      //   );
      // this.subscription$.push(serviceTypeDropdown);

      const SeriviceTypeDropdown = this.categoryService
      .getActiveCategoryList(new PagedAndSortedResultRequestDto())
      .subscribe(
        (response) => {
          this.ltCategories = response?.items;
          this.filteredCategoryDetails = this.demoForm.get('txtCategoryFilter')
            ?.valueChanges.pipe(
              startWith(''),
              map((value) =>
                this.ltCategories?.filter((option) =>
                  option?.productCategoryName
                    ?.toLowerCase()
                    ?.includes(value?.toLowerCase() ?? '')
                )
              )
            );
        },
        (err) => {}
      );
    this.subscription$.push(SeriviceTypeDropdown);
    } else if (event.newValue === 'NewProductDrpDataSaved') {
      this.drpProviderSearch = [];
      this.drpProviders = [];
      const providerList = this.providerService
        .getListV1(new PagedAndSortedResultRequestDto())
        .subscribe((response) => {
          response?.items?.forEach((element) => {
            this.drpProviders.push({
              fullName: element?.fullName,
              id: element?.id,
              name: element?.fullName,
              npi: element?.npi,
              shortCodeId: element?.shortCodeId,
            });
          });
          this.drpProviders.sort((a, b) => a.name.localeCompare(b.name));
          this.drpProviderSearch = this.drpProviders;
        });
      this.subscription$.push(providerList);
    }
  }

  clearMaritialSts(){
    this.demoForm.patchValue({
      drpMartialSts: null,
    });
  }
  clearLocation(){
    this.planForm.patchValue({
      drpBranch: null,
    });
  }
}
