import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Title } from '@angular/platform-browser';
import { TableService } from 'projects/shared/src/app/table.service';
import Swal from 'sweetalert2';
import { ERAPostingComponent } from '../era-posting/era-posting.component';




@Component({
  selector: 'app-edit-payment',
  templateUrl: './edit-payment.component.html',
  styleUrls: ['./edit-payment.component.scss']
})
export class EditPaymentComponent implements OnInit {
  public strPageType: string = "editPayment";

  constructor(private title: Title, private tableService: TableService,public dialog: MatDialog,) { }

  ngOnInit(): void {
    // this.title.setTitle("Qsecure | Edit Payment");
this. getTableData()
  }
  getTableData() {
    const tableDummyData: paymentList[] = [
      {
        id: "1",
        payment: "string",
        posted: "string",
        user: "string",
        code: "string",
        description: "string",
        checkNo: "string",
        fee: "string",
        unapplied: "string",

      }


    ];
    this.tableService.setEditPaymentsDataTable(tableDummyData);
  }
  ViewClaim(id:string){
    const dialogRef = this.dialog.open(ERAPostingComponent);

    dialogRef.afterClosed().subscribe(() => {

    }, err => {
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    });
  }
}
export interface paymentList {
  id: string,
  payment: string,
  posted: string,
  user: string,
  code: string,
  description: string,
  checkNo: string,
  fee: string,
  unapplied: string,

}
