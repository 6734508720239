import type { CreateUpdateLeaveApprovalDTO, LeaveApprovalDTO, SearchLeaveApprovalDTO } from './dto/models';
import { RestService } from '@abp/ng.core';
import type { PagedAndSortedResultRequestDto, PagedResultDto } from '@abp/ng.core';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class LeaveApprovalService {
  apiName = 'OnTimeManagement';

  create = (input: CreateUpdateLeaveApprovalDTO) =>
    this.restService.request<any, LeaveApprovalDTO>({
      method: 'POST',
      url: '/api/OnTimeApp/leave-approval',
      body: input,
    },
     { apiName: this.apiName, skipHandleError: true });

  delete = (id: string) =>
    this.restService.request<any, void>({
      method: 'DELETE',
      url: `/api/OnTimeApp/leave-approval/${id}`,
    },
     { apiName: this.apiName, skipHandleError: true });

  get = (id: string) =>
    this.restService.request<any, LeaveApprovalDTO>({
      method: 'GET',
      url: `/api/OnTimeApp/leave-approval/${id}`,
    },
     { apiName: this.apiName, skipHandleError: true });

  getList = (input: PagedAndSortedResultRequestDto) =>
    this.restService.request<any, PagedResultDto<LeaveApprovalDTO>>({
      method: 'GET',
      url: '/api/OnTimeApp/leave-approval',
      params: { skipCount: input.skipCount, maxResultCount: input.maxResultCount, sorting: input.sorting },
    },
     { apiName: this.apiName, skipHandleError: true });

  update = (id: string, input: CreateUpdateLeaveApprovalDTO) =>
    this.restService.request<any, LeaveApprovalDTO>({
      method: 'PUT',
      url: `/api/OnTimeApp/leave-approval/${id}`,
      body: input,
    },
     { apiName: this.apiName, skipHandleError: true });

  searchLeaveApprovalByInput = (input: SearchLeaveApprovalDTO) =>
    this.restService.request<any, PagedResultDto<LeaveApprovalDTO>>({
      method: 'GET',
      url: '/api/OnTimeApp/leave-approval/search-leave-approval',
      params: { name: input.name, designation: input.designation, leaveType: input.leaveType, fromDate: input.fromDate, toDate: input.toDate, comments: input.comments, status: input.status },
    },
     { apiName: this.apiName, skipHandleError: true });

  constructor(private restService: RestService) {}
}
