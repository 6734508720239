<div class="row">
    <div class="col-lg-6">
        <h2>Eligibility History</h2>
    </div>
    <!-- <div class="col-lg-6 text-right">
     <button class="text-right close"  aria-label="Close" mat-dialog-close> <b> X </b>

      </button>

    </div> -->
</div>
<mat-dialog-content class="mat-typography">
    <div class="row mr-3">
        <div class="col-12">
            <div [hidden]="!isLoading"
                style="display: flex; justify-content: center; align-items: center; background: transparent">
                <mat-progress-spinner mode="indeterminate" value="66" diameter="45" class="lightseagreen">
                </mat-progress-spinner>
            </div>

            <table mat-table class="table ml-4" matSort [dataSource]="dataSource">
                <form style="display: flex" [formGroup]="documentEligibilitySearchForm">
                    <ng-container matColumnDef="sno">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>S.No</th>
                        <td mat-cell *matCellDef="let data; let i = index">
                            {{i+1}}
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="plan">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                            Plan
                        </th>
                        <td mat-cell *matCellDef="let data">
                            <ng-container>
                                {{data?.plan}}
                            </ng-container>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="planName">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                            Plan Name
                        </th>
                        <td mat-cell *matCellDef="let data">
                            <ng-container>
                                {{data?.planName}}
                            </ng-container>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="policy">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                            Policy No
                        </th>
                        <td mat-cell *matCellDef="let data">
                            <ng-container>
                                {{data?.policy}}
                            </ng-container>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="type">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                            Verify Type
                        </th>
                        <td mat-cell *matCellDef="let data">
                            <ng-container>
                                {{data?.verficationMethod}}
                            </ng-container>
                        </td>
                    </ng-container>


                    <ng-container matColumnDef="status">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                            Status
                        </th>
                        <td mat-cell *matCellDef="let data">
                            <ng-container>
                                {{data?.verificationStatus}}
                            </ng-container>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="options">
                        <th class="header" mat-header-cell *matHeaderCellDef>
                            Options
                        </th>
                        <td class="align" mat-cell *matCellDef="let element">
                            <a class="editBtnColor cursor">
                                <i class="fa fa-eye" (click)="verificationView(element)" ></i>
                            </a>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="noRecords">
                        <td class="text-align-center" mat-footer-cell *matFooterCellDef colspan="17">
                            No Records Found
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="paginator">
                        <td mat-footer-cell *matFooterCellDef colspan="17">
                            <mat-paginator class="table table-responsive mat-table-class" #MatPaginator
                                [length]="dataSource?.data?.length" [pageSize]="10" [pageIndex]="0" showFirstLastButtons
                                [pageSizeOptions]="[5, 10, 25, 100]">
                            </mat-paginator>
                        </td>
                    </ng-container>

                </form>
                <tr mat-footer-row *matFooterRowDef="['noRecords']"
                    [ngClass]="{ active: dataSource && dataSource?.data && dataSource?.data?.length !== 0 }">
                </tr>
                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
                <tr mat-footer-row *matFooterRowDef="['paginator']"></tr>
            </table>
        </div>
    </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button class="buttonCancelColor" mat-button mat-dialog-close>Close</button>
    <!-- <button mat-button [mat-dialog-close]="true" cdkFocusInitial>Install</button> -->
</mat-dialog-actions>
