<div class="container-fluid">
    <div class="row bg">
        <div class="col-12">
            <div class="d-flex align-items-center justify-content-between">
                <h3 class="font-weight-bolder mb-2 mt-2">
                    <b class="customThemeClass">List / Billing Dept / New Insurance Review</b>
                  </h3>
            </div>
        </div>
    </div>
    <div class="card card-body">
        <div class="row" *ngIf="isLoading">
            <div class="col-12">
                <div class="d-flex justify-content-center" *ngIf="isLoading">
                    <img class="img-responsive pb-2 mr-1" src="assets/gifs/Infinity-loader.gif" />
                </div>
            </div>
        </div>
        <div class="row" *ngIf="!isLoading">
            <div class="col-12 iconcustom">
                <div class="page-title-box page-title-right pb-0">
                    <i class="fa fa-check checkicon"></i><span class="ml-2">Completed</span>
                    <i class="fa fa-eye eyeicon ml-2"></i><span class="ml-2">View Insurance</span>
                    <i class="fa fa-exclamation expicon ml-2"></i><span class="ml-2">Expired</span>
                    <i class="fa fa-clock-o pendingicon ml-2"></i><span class="ml-2">Pending</span>
                    <i class="fa fa-file-o fileicon ml-2"></i><span class="ml-2">File Missing</span>
                    <i class="fa fa-times cansicon ml-2"></i><span class="ml-2">Cancelled</span>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <div *ngIf="!isLoading" class="table table-responsive">
                    <app-patient-new-insurance-table [tableData]="newInsuranceListdata"></app-patient-new-insurance-table>
                </div>
            </div>
        </div>
    </div>
</div>