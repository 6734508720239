<div class="container-fluid">
  <div class="row bg">
    <div class="col-12">
      <div class="d-flex align-items-center justify-content-between mb-0">
        <h3 class="font-weight-bolder mb-2 mt-2">
          <b class="customThemeClass">Inventory Management / Insurance Allowables List</b>
        </h3>

        <div class="page-title-box page-title-right">
          <ol class="breadcrumbs m-0 align-items-center">
            <!-- (click)="exportTableData()" -->

            <!-- <li class="breadcrumb-item ">Inventory</li>
            <li class="breadcrumb-item ">Product Allowables List</li> -->
          </ol>
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-12">
      <!-- <div class="card card-body"> -->
      <mat-accordion>
        <mat-expansion-panel [expanded]="step">
          <mat-expansion-panel-header class="pl-4">
            <mat-panel-title class="header-text mt-3">
              <!-- <ng-container> Add Insurance Allowables</ng-container><br /><br /> -->
              <ng-container
                *ngIf="insuranceAllowablesId === ''; else elseTitleTemplate"
              >
                <b class="text-small"
                  >Add Insurance Allowables
                </b> </ng-container
              ><br /><br />
              <ng-template #elseTitleTemplate>
                <b>Edit Insurance Allowables </b>
              </ng-template>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <mat-card-content>
            <form [formGroup]="allowablesForm">
              <div class="row">
                <div class="col-lg-12">
                  <mat-card-content>
                    <div class="row">
                      <div class="col-lg-12">
                        <mat-form-field class="col-4">
                          <mat-label
                            >Insurance Category<span class="asterisk">*</span>
                          </mat-label>
                          <mat-select
                            class="dd-text"
                            [disabled]="checkAllowableValidation"
                            formControlName="txtInsCategory"
                          >
                            <mat-option>
                              <ngx-mat-select-search
                                placeholderLabel="Search"
                                noEntriesFoundLabel="No Matches found"
                                formControlName="filterCategory"
                              ></ngx-mat-select-search>
                            </mat-option>
                            <mat-option
                              *ngFor="let provider of filterInsCategory | async"
                              [value]="provider.id"
                            >
                              {{ provider.insuranceCategory }}
                            </mat-option>
                          </mat-select>
                          <a [matTooltip]="'Add Insurance Category'" matSuffix style="margin-left: 10px;"
                  (click)="$event.stopPropagation(); openAddInsuranceCategoryModal() ">
                  <img class="img-responsive" src="assets/Billing Icons/addIcon.png" height="28" /></a>

                          <!-- <button
                            mat-button
                            (click)="
                              product = undefined; $event.stopPropagation()
                            "
                            *ngIf="product"
                            matSuffix
                            (click)="clearMainProduct()"
                            mat-icon-button
                          >
                            <mat-icon>close</mat-icon>
                          </button> -->
                          <mat-error
                            *ngIf="
                              allowablesForm?.get('txtInsCategory')?.touched &&
                              allowablesForm?.get('txtInsCategory')?.errors
                                ?.required
                            "
                          >
                            Insurance Category is a required field!
                          </mat-error>
                        </mat-form-field>

                        <mat-form-field class="col-4">
                          <mat-label
                            >Hcpc Code<span class="asterisk">*</span>
                          </mat-label>
                          <mat-select
                            class="dd-text"
                            [disabled]="checkAllowableValidation"
                            formControlName="txtHcpcCode"
                            multiple
                          >
                            <mat-option>
                              <ngx-mat-select-search
                                placeholderLabel="Search"
                                noEntriesFoundLabel="No Matches found"
                                formControlName="filterHcpcCode"
                              ></ngx-mat-select-search>
                            </mat-option>
                            <mat-option
                              *ngFor="let provider of filterProducts | async"
                              [value]="provider.hcpcCode"
                            >
                              {{ provider.hcpcCode }}
                            </mat-option>
                          </mat-select>
                          <mat-error
                            *ngIf="
                              allowablesForm?.get('txtHcpcCode')?.touched &&
                              allowablesForm?.get('txtHcpcCode')?.errors
                                ?.required
                            "
                          >
                            HCPC code is a required field!
                          </mat-error>
                        </mat-form-field>
                        <mat-form-field class="col-4">
                          <mat-label>
                            Maximum Quantity
                            <span class="asterisk">*</span></mat-label
                          >
                          <input
                            class="dd-text"
                            autocomplete="new-txtMaxQty"
                            matInput
                            maxlength="4"
                            formControlName="txtMaxQty"
                            (keypress)="numberOnly($event)"
                            (paste)="numberOnly($event)"
                            type="text"
                          />
                          <mat-error
                            *ngIf="
                              allowablesForm?.get('txtMaxQty')?.touched &&
                              allowablesForm?.get('txtMaxQty')?.errors?.required
                            "
                          >
                            Maximum Quantity is a required field!
                          </mat-error>
                          <mat-error
                            *ngIf="
                              allowablesForm
                                .get('txtMaxQty')
                                ?.hasError('maxValidation')
                            "
                            >Maximum quantity should be greater than Minimum
                            Quantity</mat-error
                          >
                        </mat-form-field>
                        <mat-form-field class="col-4">
                          <mat-label>
                            Minimum Quantity
                            <span class="asterisk">*</span></mat-label
                          >
                          <input
                            class="dd-text"
                            autocomplete="new-txtMinQty"
                            matInput
                            maxlength="4"
                            (keypress)="numberOnly($event)"
                            (paste)="numberOnly($event)"
                            formControlName="txtMinQty"
                            type="text"
                          />
                          <mat-error
                            *ngIf="
                              allowablesForm?.get('txtMinQty')?.touched &&
                              allowablesForm?.get('txtMinQty')?.errors?.required
                            "
                          >
                            Minimum Quantity is a required field!
                          </mat-error>
                          <mat-error
                            *ngIf="
                              allowablesForm
                                .get('txtMinQty')
                                .hasError('minValidation')
                            "
                            >Minimum quantity should be less than Maximum
                            Quantity</mat-error
                          >
                          <!-- <span class="text-danger fw-bolder" *ngIf="minValidation">Minumum quantity should be less than Maximum Quantity</span> -->
                        </mat-form-field>

                        <mat-form-field class="col-4">
                          <mat-label>
                            Allowable($)
                            <span class="asterisk">*</span></mat-label>
                          <input
                            class="dd-text"
                            formControlName="txtAllowables"
                            autocomplete="new-txtAllowables"
                            pattern="^\d*(\.\d{0,2})?$"
                            (keypress)="numbersAndfloatOnly($event)"
                            (paste)="numbersAndfloatOnly($event)"
                            matInput
                            maxlength="10" type="text"/>
                          <mat-error *ngIf="allowablesForm?.get('txtAllowables')?.touched && allowablesForm?.get('txtAllowables')?.errors?.required">
                            Allowable Amount is a required field!
                          </mat-error>
                          <mat-error *ngIf="
                           allowablesForm?.get('txtAllowables')?.errors?.pattern">
                            Allowable Amount after decimal two digit only allowed!
                          </mat-error>
                        </mat-form-field>

                        <mat-form-field class="col-4">
                          <mat-label>
                            Quantity Days
                            <span class="asterisk">*</span></mat-label>
                          <input
                            class="dd-text"
                            autocomplete="new-txtQtyDays"
                            matInput
                            maxlength="4"
                            formControlName="txtQtyDays"
                            (keypress)="numberOnly($event)"
                            (paste)="numberOnly($event)"
                            type="text"
                          />
                          <mat-error
                            *ngIf="
                              allowablesForm?.get('txtQtyDays')?.touched &&
                              allowablesForm?.get('txtQtyDays')?.errors
                                ?.required
                            "
                          >
                            Quantity Days is a required field!
                          </mat-error>
                        </mat-form-field>
                      </div>
                    </div>
                  </mat-card-content>
                </div>
              </div>
            </form>
            <div class="col-12 mt-2 text-center">
              <!-- <button
                mat-button
                class="buttonColor mr-2"
                [disabled]="
                  !(!allowablesForm.invalid && allowablesForm.dirty) ||
                  saveButtonHide
                "
                (click)="saveAllowables()"
              >
                Save
              </button> -->
              <button
                mat-button
                class="buttonColor mr-2"
                [disabled]= "saveButtonHide || !!allowablesForm.invalid"
                 (click)="saveAllowables()">
                {{buttonName}}
              </button>
              <button
                mat-button
                (click)="resetForm()"
                class="resetclr buttonColor"
              >
                Reset
              </button>
            </div>
          </mat-card-content>
        </mat-expansion-panel>
      </mat-accordion>
      <!-- </div> -->

      <div class="mt-2 card">
        <!-- <div class="card card-body"> -->
        <!-- <div class="row "> -->
        <!-- <div class="card"> -->
        <div class="card-header header-text">Insurance Allowable List</div>
        <div class="card-body">
          <div class="d-flex justify-content-center" *ngIf="!loadTable">
            <!-- <mat-spinner></mat-spinner> -->
            <img
              class="img-responsive pb-2 mr-1"
              src="assets/gifs/Infinity-loader.gif"
            />
          </div>
          <div *ngIf="loadTable" class="table table-responsive">
            <app-insurance-allowables-table
              [insuranceTableData]="insuranceTableData"
            ></app-insurance-allowables-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
