import { ListService, PagedAndSortedResultRequestDto } from '@abp/ng.core';
import { DatePipe } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatAccordion } from '@angular/material/expansion';
import { MatRadioChange } from '@angular/material/radio';
import { DomSanitizer } from '@angular/platform-browser';
import { MasterProviderService } from 'projects/order/src/app/order-proxy/order-management/inventory/master-provider.service';
import { TableService } from 'projects/shared/src/app/table.service';
import { NumberValidators } from 'projects/shared/src/app/validations/number-validator';
import { TextValidators } from 'projects/shared/src/app/validations/text-validator';
import { Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { ToastrService } from 'ngx-toastr';
import {
  PatientMasterDropdownDTO,
  PayorLevelDTO,
} from '../dropdown-proxy/dropdown-management/dropdowns/dto/patient/models';
import { PatientMasterDropdownService } from '../dropdown-proxy/dropdown-management/dropdowns/master-dropdown-service/patient-master-dropdown.service';
import { PatientDropdowns } from '../dropdown-proxy/dropdown-management/dropdowns/patient-dropdowns.enum';
import { PverifyService } from '../patient-proxy/controllers/pverify.service';
import { ZermidVerificationEligibilityService } from '../patient-proxy/controllers/zermid-verification-eligibility.service';
import {
  CreateUpdatePverifyDTO,
  CreateUpdateZermidVerificationEligibilityDTO,
  PersonalDTO,
  PoliciesDTO,
} from '../patient-proxy/patient/dto/models';
import { PatientService } from '../patient-proxy/patient/patient.service';
import { PayerService } from '../patient-proxy/patient/payer.service';
import { PverifyPayersService } from '../patient-proxy/patient/pverify-payers.service';

@Component({
  selector: 'app-patient-pverify',
  templateUrl: './patient-pverify.component.html',
  styleUrls: ['./patient-pverify.component.scss'],
  providers: [DatePipe, ListService],
})
export class PatientPverifyComponent implements OnInit, OnDestroy {
  isLoading: boolean = false;
  eligibilityMedicareForm: FormGroup;
  // patientId: string = "";
  public strPageType: string = 'pverifyEligibilityMedicare';
  public strPageType1: string = 'pverifyDeductibleBenefits';
  public strPageType2: string = 'pverifyCoInsuranceBenefits';
  isShown: boolean = false;
  lblState: string = '';
  lblSubscriberRelationship: string = '';
  lblCity: string = '';
  lblZip: string = '';
  lblSubscriberName: string = '';
  lblMemberId: string = '';
  lblPatientDob: string = '';
  lblPatientGender: string = '';
  lblPatientAddress: string = '';
  lblPlanEndDate: string = '';
  lblPlanBeginDate: string = '';
  lblEligibilityDate: string = '';
  body: any;
  lblIDInNet: string = '';
  lblIDOutNet: string = '';
  lblIDOOPInNet: string = '';
  lblIDOOPOutNet: string = '';
  lblFDInNet: string = '';
  lblFDOutNet: string = '';
  lblFOOPInNet: string = '';
  lblFOOPOutNet: string = '';
  lblCPInNet: string = '';
  lblCPOutNet: string = '';

  lblIRemainingInNet: string = '';
  lblIRemainingOutNet: string = '';
  lblIOOPRemainingInNet: string = '';
  lblIOOPRemainingOutNet: string = '';
  lblDRemainingInNet: string = '';
  lblDRemainingOutNet: string = '';
  lblDOOPRemainingInNet: string = '';
  lblDOOPRemainingOutNet: string = '';
  KisshtHtml;
  lblFRemainingInNet: string = '';
  lblFRemainingOutNet: string = '';
  lblFOOPRemainingInNet: string = '';
  lblFOOPRemainingOutNet: string = '';
  coverageSummaryTableData: any[] = [];
  deductibelBenefitsTableData: any[] = [];
  coInsuranceBenefitsTableData: any[] = [];
  otherServiceTableData: any[] = [];
  eligibilityAlerts: any[] = [];
  payorLevel: PayorLevelDTO[] = [];
  drpProviders: any[] = [];
  drpPlan: any[] = [];
  drpPayerId: any[] = [];
  pverifyName: boolean = true;
  pverifyId: boolean = true;
  zermidName: boolean = false;
  subscription$: Subscription[] = [];
  constructor(
    private planService: PverifyPayersService,
    private formBuilder: FormBuilder,
    private patientService: PatientService,
    private payerService: PayerService,
    private pVerifyService: PverifyService,
    private toastr: ToastrService,
    private datePipe: DatePipe,
    private zermidService: ZermidVerificationEligibilityService,
    private Table: TableService,
    private text: TextValidators,
    private sanitizer: DomSanitizer,
    private number: NumberValidators,
    private providerList: MasterProviderService,
    public list: ListService,
    private patientDropdownService: PatientMasterDropdownService,
    public dialogRef: MatDialogRef<PatientPverifyComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { patientId: string }
  ) {}

  @ViewChild(MatAccordion) accordion: MatAccordion;

  ngOnInit(): void {
    this.getPolicyDropdown();
    //  this.planDropdown();

    // ! Formgroups with validations
    this.eligibilityMedicareForm = this.formBuilder.group({
      txtNpi: new FormControl('', [
        this.number.isNumberCheck,
        Validators.required,
      ]),
      txtProviderName: new FormControl('', [
        this.text.isTextCheck,
        Validators.required,
      ]),
      txtEligibilityDate: new FormControl(new Date(), [Validators.required]),
      txtFirstName: new FormControl('', [
        this.text.isTextCheck,
        Validators.required,
      ]),
      txtLastName: new FormControl('', [
        this.text.isTextCheck,
        Validators.required,
      ]),
      txtSuffix: new FormControl(''),
      txtMemeberId: new FormControl('', [Validators.required]),
      txtDob: new FormControl('', [Validators.required]),
      drpPayor: new FormControl('', [Validators.required]),
      txtPayerName: new FormControl('', [Validators.required]),
      txtPayerId: new FormControl('', [Validators.required]),
      rbtnPolicyType: new FormControl(''),
      txtZermidPayerName: new FormControl(''),
      txtZermidPayerId: new FormControl(''),
    });
  }

  ngOnDestroy(): void {
    this.subscription$?.forEach((sub) => {
      sub && sub?.unsubscribe();
    });
  }
  getPolicyDropdown() {
    let patientDropdownInput: PatientDropdowns[];
    patientDropdownInput = [PatientDropdowns.PayorLevels];
    const patientDropdown = this.patientDropdownService
      .getPatientDropdownsByInput(patientDropdownInput)
      .subscribe(
        (stateResponse) => {
          const response: PatientMasterDropdownDTO = stateResponse;
          this.payorLevel = response.payorLevels;
          // this.getPatientDetailsById();
          // this.drpProviders = response?.lstProvider
          this.planDropdown();
        },
        (err) => {
          const data: HttpErrorResponse = err;
          Swal.fire({
            icon: 'info',
            text: data?.error?.error?.message,
          });
        }
      );
    this.subscription$.push(patientDropdown);
    //
    const providerList = (query: any) => this.providerList.getList(query);
    const providerDropdown = this.list.hookToQuery(providerList).subscribe(
      (response) => {
        response?.items?.forEach((element) => {
          this.drpProviders.push({
            id: element.id,
            name: element?.fullName,
            shortCodeId: element?.shortCodeId,
            fullName: element?.fullName,
            npi: element?.npi,
            lastName: element?.lastName,
            // providerId: element?.providerId,
          });
        });
        this.getPatientDetailsById();
      },
      (err) => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      }
    );
    this.subscription$.push(providerDropdown);
    //
    const payerDropdown = this.payerService
      .getList(new PagedAndSortedResultRequestDto())
      .subscribe((response) => {
        this.drpPayerId = [];
        response?.items?.forEach((element) => {
          this.drpPayerId.push({
            id: element.id,
            name: element?.payerId + ' / ' + element?.payerName,
            payerCode: element?.payerId,
            // code: element?.payerCode,
            // payorId: element.shortCodeId
          });
        });
      });
    this.subscription$.push(payerDropdown);
  }
  //
  planDropdown(): void {
    const planDropdown = this.planService
      .getList(new PagedAndSortedResultRequestDto())
      .subscribe(
        (response) => {
          this.drpPlan = [];
          response?.items?.forEach((element) => {
            this.drpPlan.push({
              id: element.id,
              name: element?.payerName,
              code: element?.payerCode,
              payorId: element.shortCodeId,
              payerCode: element.payerCode,
            });
          });
        },
        (err) => {
          const data: HttpErrorResponse = err;
          Swal.fire({
            icon: 'info',
            text: data?.error?.error?.message,
          });
        }
      );
    this.subscription$.push(planDropdown);
  }
  policyGuid: string = '';

  valueChangeLoadDropdown(type: string) {
    const storeSubscription = this.patientService
      .get(this.data?.patientId)
      .subscribe(
        (response) => {
          const savedPolicy: PoliciesDTO = response?.policies;
          let policy: string = '';
          let Insurance: string = '';
          let payerName: string = '';
          let payerId: string = '';
          let zermidPayerId: string = '';
          if (
            savedPolicy?.primaryPolicy != null &&
            savedPolicy?.primaryPolicy?.payorLevel === type
          ) {
            policy = savedPolicy?.primaryPolicy?.policyNo ?? '';
            Insurance = this.drpPlan.filter((val) => {
              return val?.name === savedPolicy?.primaryPolicy?.insuranceType;
            })[0]?.payerCode;
            payerName = savedPolicy?.primaryPolicy?.insuranceType ?? '';
            payerId = this.drpPayerId.filter((val) => {
              return val?.name == savedPolicy?.primaryPolicy?.payorId;
            })[0]?.name;
            this.policyGuid = savedPolicy?.primaryPolicy?.policiesId ?? '';
            zermidPayerId = this.drpPayerId.filter((val) => {
              return val?.name == savedPolicy?.primaryPolicy?.payorId;
            })[0]?.payerCode;
          }
          if (
            savedPolicy?.secondaryPolicy != null &&
            savedPolicy?.secondaryPolicy?.payorLevel === type
          ) {
            policy = savedPolicy?.secondaryPolicy?.policyNo ?? '';
            Insurance = this.drpPlan.filter((val) => {
              return val?.name === savedPolicy?.secondaryPolicy?.insuranceType;
            })[0]?.payerCode;
            payerName = savedPolicy?.secondaryPolicy?.insuranceType ?? '';
            payerId = this.drpPayerId.filter((val) => {
              return val?.name == savedPolicy?.secondaryPolicy?.payorId;
            })[0]?.name;
            zermidPayerId = this.drpPayerId.filter((val) => {
              return val?.name == savedPolicy?.secondaryPolicy?.payorId;
            })[0]?.payerCode;

            this.policyGuid = savedPolicy?.secondaryPolicy?.policiesId ?? '';
          }
          if (
            savedPolicy?.tertiaryPolicy != null &&
            savedPolicy?.tertiaryPolicy?.payorLevel === type
          ) {
            policy = savedPolicy?.tertiaryPolicy?.policyNo ?? '';
            Insurance =
              this.drpPlan.filter((val) => {
                return val?.name === savedPolicy?.tertiaryPolicy?.insuranceType;
              })[0]?.payerCode ?? '';
            payerName = savedPolicy?.tertiaryPolicy?.insuranceType ?? '';
            payerId = this.drpPayerId.filter((val) => {
              return val?.name == savedPolicy?.secondaryPolicy?.payorId;
            })[0]?.name;
            zermidPayerId = this.drpPayerId.filter((val) => {
              return val?.name == savedPolicy?.tertiaryPolicy?.payorId;
            })[0]?.payerCode;

            this.policyGuid = savedPolicy?.tertiaryPolicy?.policiesId ?? '';
          }
          this.eligibilityMedicareForm.patchValue({
            txtMemeberId: policy,
            txtPayerId: Insurance,
            txtPayerName: payerName,
            txtZermidPayerName: payerId,
            txtZermidPayerId: zermidPayerId,
          });
        },
        (err) => {
          const data: HttpErrorResponse = err;
          Swal.fire({
            icon: 'info',
            text: data?.error?.error?.message,
          });
        }
      );
    this.subscription$.push(storeSubscription);
    this.eligibilityMedicareForm.disable();
  }
  // ! Pverify eligibility summary details function
  verify() {
    this.isLoading = true;

    const pVerifyDetails: CreateUpdatePverifyDTO = {
      planType: this.eligibilityMedicareForm?.value?.drpPayor,
      payerCode: this.eligibilityMedicareForm?.value?.txtPayerId,
      payerName: this.eligibilityMedicareForm?.value?.txtPayerName,
      providerLastName: this.eligibilityMedicareForm?.value?.txtProviderName,
      providerNpi: this.eligibilityMedicareForm?.value?.txtNpi,
      patientFirstName: this.eligibilityMedicareForm?.value?.txtFirstName,
      patientDob:
        this.eligibilityMedicareForm?.value?.txtDob === ''
          ? ''
          : this.datePipe.transform(
              this.eligibilityMedicareForm?.value?.txtDob,
              'MM/dd/yyyy'
            ) ?? '',
      patientLastName: this.eligibilityMedicareForm?.value?.txtLastName,
      memberID: this.eligibilityMedicareForm?.value?.txtMemeberId,
      dateOfService:
        this.eligibilityMedicareForm?.value?.txtEligibilityDate === ''
          ? ''
          : this.datePipe.transform(
              this.eligibilityMedicareForm?.value?.txtEligibilityDate,
              'MM/dd/yyyy'
            ) ?? '',
      referenceId: '',
      location: '',
      practiceTypeCode: '18',
      includeTextResponse: true,
      patientId: this.data?.patientId,
      policyId: this.policyGuid,
    };

    this.pVerifyService
      .getPverifyDetailsByInput(pVerifyDetails)
      .pipe(
        map((response) => {
          if (response?.apiResponseMessage !== 'Processed') {
            // Swal.fire({ title: 'Warning', html: 'Requested service not registered for the client', icon: 'warning', timer: 3000, timerProgressBar: true });
            this.toastr.warning(
              'Requested service not registered for the client',
              'Warning'
            );
          }
          // else{

          if (response.apiResponseCode != null) {
            this.isLoading = false;
            this.isShown = true;
            this.coverageSummaryTableData = [];
            this.lblSubscriberRelationship =
              response?.planCoverageSummary?.subscriberRelationship ?? '';

            this.lblSubscriberName =
              response?.demographicInfo?.subscriber?.fullName ?? '';
            this.lblState =
              response?.demographicInfo?.subscriber?.state === null
                ? ''
                : response?.demographicInfo?.subscriber?.state ?? '';
            this.lblCity =
              response?.demographicInfo?.subscriber?.city === null
                ? ''
                : response?.demographicInfo?.subscriber?.city ?? '';
            this.lblZip =
              response?.demographicInfo?.subscriber?.zip === null
                ? ''
                : response?.demographicInfo?.subscriber?.zip ?? '';

            this.lblIDInNet =
              response?.hbpC_Deductible_OOP_Summary.individualDeductibleInNet
                ?.value ?? '';
            this.lblIDOutNet =
              response?.hbpC_Deductible_OOP_Summary?.individualDeductibleOutNet
                ?.value ?? '';
            this.lblIDOOPInNet =
              response?.hbpC_Deductible_OOP_Summary?.individualOOP_InNet
                ?.value ?? '';
            this.lblIDOOPOutNet =
              response?.hbpC_Deductible_OOP_Summary?.individualOOP_OutNet
                ?.value ?? '';
            this.lblFDInNet =
              response?.hbpC_Deductible_OOP_Summary?.familyDeductibleInNet
                ?.value ?? '';
            this.lblFDOutNet =
              response?.hbpC_Deductible_OOP_Summary?.familyDeductibleOutNet
                ?.value ?? '';
            this.lblFOOPInNet =
              response?.hbpC_Deductible_OOP_Summary?.familyOOPInNet?.value ??
              '';
            this.lblFOOPOutNet =
              response?.hbpC_Deductible_OOP_Summary?.familyOOPOutNet?.value ??
              '';

            this.lblIRemainingInNet =
              response?.hbpC_Deductible_OOP_Summary
                ?.individualDeductibleRemainingInNet?.value ?? '';
            this.lblIRemainingOutNet =
              response?.hbpC_Deductible_OOP_Summary
                ?.individualDeductibleRemainingOutNet?.value ?? '';
            this.lblIOOPRemainingInNet =
              response?.hbpC_Deductible_OOP_Summary?.individualOOPRemainingInNet
                ?.value ?? '';
            this.lblIOOPRemainingOutNet =
              response?.hbpC_Deductible_OOP_Summary
                ?.individualOOPRemainingOutNet?.value ?? '';
            this.lblDRemainingInNet =
              response?.hbpC_Deductible_OOP_Summary
                ?.familyDeductibleRemainingInNet?.value ?? '';
            this.lblDRemainingOutNet =
              response?.hbpC_Deductible_OOP_Summary
                ?.familyDeductibleRemainingOutNet?.value ?? '';
            this.lblDOOPRemainingInNet =
              response?.hbpC_Deductible_OOP_Summary?.familyOOPRemainingInNet
                ?.value ?? '';
            this.lblDOOPRemainingOutNet =
              response?.hbpC_Deductible_OOP_Summary?.familyOOPRemainingOutNet
                ?.value ?? '';
            this.lblFOOPRemainingInNet =
              response?.hbpC_Deductible_OOP_Summary?.familyOOPRemainingInNet
                ?.value ?? '';
            this.lblFOOPRemainingOutNet =
              response?.hbpC_Deductible_OOP_Summary?.familyOOPRemainingOutNet
                ?.value ?? '';
            this.lblFRemainingOutNet =
              response?.hbpC_Deductible_OOP_Summary
                ?.familyDeductibleRemainingOutNet?.value ?? '';
            this.lblFRemainingInNet =
              response?.hbpC_Deductible_OOP_Summary
                ?.familyDeductibleRemainingInNet?.value ?? '';
            (this.lblCPInNet =
              response?.specialistOfficeSummary?.coInsInNet?.value ?? ''),
              (this.lblCPOutNet =
                response?.specialistOfficeSummary?.coPayInNet?.value ?? ''),
              (this.lblMemberId =
                response?.demographicInfo?.subscriber?.identification[2]
                  ?.code ?? '');
            this.lblPatientDob = pVerifyDetails?.patientDob;
            this.lblPatientGender =
              response?.demographicInfo?.subscriber?.gender_R === null
                ? ''
                : response?.demographicInfo?.subscriber?.gender_R ?? '';
            this.lblPatientAddress =
              response?.demographicInfo?.subscriber?.address1 === null
                ? ''
                : response?.demographicInfo?.subscriber?.address1 ?? '';
            // this.lblFDInNet = response.specialistOfficeSummary?.familyDeductibleInNet?.value,
            //   this.lblIDInNet = response.specialistOfficeSummary?.individualDeductibleInNet?.value,
            //   this.lblInsNet = response.specialistOfficeSummary?.coInsInNet?.value
            // this.lblPayNet = response.specialistOfficeSummary?.coPayInNet?.value,

            if (
              response?.demographicInfo?.subscriber?.date !== null ||
              response?.demographicInfo?.subscriber?.date !== null
            ) {
              (this.lblEligibilityDate =
                response?.demographicInfo?.subscriber?.date[0]?.date ?? ''),
                (this.lblPlanBeginDate =
                  response?.demographicInfo?.subscriber?.date[1]?.date ?? '');
              this.lblPlanEndDate =
                response?.demographicInfo?.subscriber?.date[2]?.date ?? '';
            }

            this.coverageSummaryTableData.push({
              insurance:
                response?.planCoverageSummary?.planName?.toLocaleLowerCase(),
              coverage: response?.planCoverageSummary?.status,
              date: response?.planCoverageSummary?.effectiveDate,
              expDate: response?.planCoverageSummary?.expiryDate,
              groupNo: response?.planCoverageSummary?.groupNumber,
              groupName: response?.planCoverageSummary?.groupName,
            });
            // ! Coverage Summary Table Binding
            this.Table.setCoverageSummaryTable(this.coverageSummaryTableData);
            const coverageData: any[] = [];
            const coBenefitData: any[] = [];
            const deductibleData: any[] = [];
            const coInsuranceData: any[] = [];
            response?.serviceDetails.forEach((resp) => {
              resp?.eligibilityDetails.forEach((element) => {
                element?.message?.length != 0 &&
                  this.eligibilityAlerts.push(element);
              });

              resp?.serviceName === 'Health Benefit Plan Coverage' &&
                coverageData.push(resp);
              resp?.serviceName === 'Emergency Services' &&
                coBenefitData.push(resp);
            });

            coverageData.forEach((resp) => {
              resp?.eligibilityDetails.forEach((deductibleResp) => {
                deductibleResp?.eligibilityOrBenefit === 'Deductible' &&
                  deductibleData.push(deductibleResp);
              });
            });

            coBenefitData.forEach((coResp) => {
              coResp?.eligibilityDetails.forEach((coBenefitResp) => {
                coBenefitResp?.eligibilityOrBenefit === 'Co-Payment' &&
                  coInsuranceData.push(coBenefitResp);
              });
              this.coInsuranceBenefitsTableData = [];
              this.otherServiceTableData = response?.serviceDetails;
              coInsuranceData.forEach((element) => {
                this.coInsuranceBenefitsTableData.push({
                  type: element?.eligibilityOrBenefit,
                  amount: element?.monetaryAmount,
                });
              });
            });
            // ! CoInsurance Table Binding
            this.Table.setCoBenefitTable(this.coInsuranceBenefitsTableData);
            return deductibleData;
          } else {
            Swal.fire(response?.apiResponseMessage);
          }

          // }
        })
      )
      .subscribe(
        (resp) => {
          this.deductibelBenefitsTableData = [];
          resp?.forEach((element) => {
            this.deductibelBenefitsTableData.push({
              type: element?.eligibilityOrBenefit,
              baseAmount: element?.monetaryAmount,
              remaining: element?.monetaryAmount,
              planDates: element?.date,
            });
          });
          // ! Deductible Benefit Table Binding
          this.Table.setDeductibleBenefitTable(
            this.deductibelBenefitsTableData
          );

          this.isLoading = false;
        },
        (err) => {
          this.isLoading = false;

          const data: HttpErrorResponse = err;
          Swal.fire({
            icon: 'info',
            text: data?.error?.error?.message,
          });
        }
      );
  }

  // ZERMID VERIFY
  zermidVerify() {
    this.isLoading = true;

    const zermidDetails: CreateUpdateZermidVerificationEligibilityDTO = {
      planType: this.eligibilityMedicareForm?.value?.drpPayor,
      zermidPayerId: this.eligibilityMedicareForm?.value?.txtZermidPayerId,
      zermidPayerName: this.eligibilityMedicareForm?.value?.txtZermidPayerName,
      providerLastName: this.eligibilityMedicareForm?.value?.txtProviderName,
      providerNpi: this.eligibilityMedicareForm?.value?.txtNpi,
      patientFirstName: this.eligibilityMedicareForm?.value?.txtFirstName,
      patientDob:
        this.eligibilityMedicareForm?.value?.txtDob === ''
          ? ''
          : this.datePipe.transform(
              this.eligibilityMedicareForm?.value?.txtDob,
              'YYYYMMdd'
            ) ?? '',
      patientLastName: this.eligibilityMedicareForm?.value?.txtLastName,
      memberId: this.eligibilityMedicareForm?.value?.txtMemeberId,
      patientId: this.data.patientId,
      policyId: this.policyGuid,
    };

    this.zermidService
      .getZermidVerificationEligibilityDetailsByInput(zermidDetails)
      .subscribe(
        (response) => {
          this.isLoading = false;
          // this.body = response
          this.KisshtHtml = this.sanitizer.bypassSecurityTrustHtml(response);
        },
        () => {
          this.isLoading = false;
          Swal.fire({
            icon: 'info',
            text: 'Failed at Payer, Please Check the details and resubmit',
          });
          this.isLoading = false;
        }
      );
  }

  // this.drpPriceCode.filter(value => { return value?.priceCodeId === element?.priceCode })[0]?.priceCodeType,
  // ! Get Patient Details By Id Function
  getPatientDetailsById() {
    if (
      this.data.patientId !== '' &&
      this.data.patientId !== null &&
      this.data.patientId !== undefined
    ) {
      const storeSubscription = this.patientService
        .get(this.data?.patientId)
        .subscribe(
          (response) => {
            const selectedPatientPersonal: PersonalDTO = response?.personals;
            selectedPatientPersonal !== null &&
              selectedPatientPersonal !== undefined &&
              this.eligibilityMedicareForm.patchValue({
                txtFirstName: selectedPatientPersonal.firstName,
                txtLastName: selectedPatientPersonal.lastName,
                txtSuffix: selectedPatientPersonal.suffix,
                txtDob:
                  selectedPatientPersonal.dateOfBirth === null
                    ? ''
                    : selectedPatientPersonal.dateOfBirth,
                // txtEligibilityDate: selectedPatientPersonal.auditTrialDateCreated === null ? "" : selectedPatientPersonal.auditTrialDateCreated,
                // txtMemeberId: "123456789A",
                txtNpi: this.drpProviders.filter((val) => {
                  return (
                    val?.shortCodeId === selectedPatientPersonal?.providerId
                  );
                })[0]?.npi,

                txtProviderName: this.drpProviders.filter((val) => {
                  return (
                    val?.shortCodeId === selectedPatientPersonal?.providerId
                  );
                })[0]?.lastName,
              });
            this.drpProviders.filter((val) => {
              return val?.shortCodeId === selectedPatientPersonal?.providerId;
            })[0]?.npi;
          },
          (err) => {
            const data: HttpErrorResponse = err;
            Swal.fire({
              icon: 'info',
              text: data?.error?.error?.message,
            });
          }
        );
      this.subscription$.push(storeSubscription);
    }
  }
  // ! to get verify option
  onChangeGetInsuranceVerify($event: MatRadioChange) {
    if ($event.value === 'Zermid') {
      this.pverifyName = false;
      this.pverifyId = false;
      this.zermidName = true;
    }
    if ($event.value === 'PVerify') {
      this.pverifyName = true;
      this.pverifyId = true;
      this.zermidName = false;
    }
  }

  // ! Reset Pverify form function
  resetPverify() {
    this.eligibilityMedicareForm.patchValue({
      txtNpi: '',
      txtProviderName: '',
      txtEligibilityDate: '',
      txtFirstName: '',
      txtLastName: '',
      txtSuffix: '',
      txtMemeberId: '',
      txtDob: '',
    });
    this.eligibilityMedicareForm.markAsUntouched();
    this.eligibilityMedicareForm.updateValueAndValidity();
  }
}
