<!-- Component Start -->
<div class="col-12" style="margin-bottom: -10px">
    <div class="card card-body ">
        <!-- Accordion Form Start -->
        <mat-accordion>
            <mat-expansion-panel [expanded]="step" (afterExpand)="toggle()" (closed)="toggleV2()">
                <mat-expansion-panel-header>
                    <mat-panel-title class="customThemeClass">
                        <ng-container *ngIf="mstModifierId === ''; else elseTitleTemplate">
                            <b>Add Modifier</b>
                        </ng-container>
                        <ng-template #elseTitleTemplate>
                            <b>Edit Modifier</b>
                        </ng-template>
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <form [formGroup]="mstModifyrForm">
                    <div class="">
                        <mat-card-content>
                            <div class="row">
                                <!-- <mat-form-field class="col-6">
                                    <mat-label>Select Modifier</mat-label>
                                    <mat-select formControlName="drpMstModifyr" [(value)]="txtModifier" #singleSelect>
                                        <mat-option>
                                            <ngx-mat-select-search placeholderLabel="Search"
                                                noEntriesFoundLabel="No Matches found"
                                                formControlName="drpMstModifyrFilter"></ngx-mat-select-search>
                                        </mat-option>
                                        <mat-option *ngFor="let Modifier of filteredModifiers | async"
                                            [value]="Modifier.modifierName">
                                            {{ Modifier.modifierName }}
                                        </mat-option>
                                    </mat-select>
                                    <button mat-button (click)="txtModifier=undefined;$event.stopPropagation()"
                                        *ngIf="txtModifier" (click)="clearMstModifier()" matSuffix mat-icon-button>
                                        <mat-icon>close</mat-icon>
                                    </button>
                                </mat-form-field> -->
                                <mat-form-field class="col-4">
                                    <mat-label>Modifier<span class="asterisk">*</span></mat-label>
                                    <input autocomplete="new-txtNewMstModifyr" matInput
                                        formControlName="txtNewMstModifyr" placeholder="Enter Modifier" maxlength="80"
                                        type="text" />
                                    <mat-error class="spanAlignment" *ngIf="mstModifyrForm?.get('txtNewMstModifyr')?.touched">
                                        <ng-container *ngIf="mstModifyrForm?.get('txtNewMstModifyr')?.hasError('required')">
                                            Modifier is a required field!
                                        </ng-container>
                                        
                                        <ng-container *ngIf="mstModifyrForm?.get('txtNewMstModifyr')?.hasError('whitespace')">
                                            Modifier cannot be just whitespace!
                                        </ng-container>
                                    </mat-error>

                                </mat-form-field>

                                <mat-form-field class="col-4">
                                    <mat-label>Description<span class="asterisk">*</span></mat-label>
                                    <input autocomplete="new-txtDescription" matInput
                                        formControlName="txtDescription" placeholder="Enter Description" maxlength="80"
                                        type="text" />
                                    <mat-error class="spanAlignment" *ngIf="mstModifyrForm?.get('txtDescription')?.touched">
                                        <ng-container *ngIf="mstModifyrForm?.get('txtDescription')?.hasError('required')">
                                            Description is a required field!
                                        </ng-container>
                                        
                                        <ng-container *ngIf="mstModifyrForm?.get('txtDescription')?.hasError('whitespace')">
                                            Description cannot be just whitespace!
                                        </ng-container>
                                    </mat-error>
                                </mat-form-field>

                            <div class="col-4 mt-3">
                                <button mat-button class="buttonColor mr-2 dd-text p-2"
                                    [disabled]="saveButtonHide||!(!mstModifyrForm.invalid && mstModifyrForm.dirty)"
                                    (click)="saveMstModifier()">
                                    Save
                                    <mat-icon *ngIf='saveButtonHide'>
                                        <mat-spinner class="spinner-border spinner-border-sm" diameter="20"> </mat-spinner>
                                    </mat-icon>
                                </button>
                                <button mat-button class="resetclr buttonColor dd-text p-2 mr-2" (click)="resetMstModifier()">
                                    Reset
                                </button>
                            </div>
                        </div>
                        </mat-card-content>
                    </div>
                </form>
            </mat-expansion-panel>
        </mat-accordion>
    </div>
    <!-- Accordion Form Start -->
</div>

<div class="col-12">
    <div class="card card-body">
        <!-- Table Start -->
        <div class="table-responsive">
            <table id="tblModifierList" datatable [dtOptions]="dtModifierTableOptions"
                class="row-border hover w-100 display dataTables_scroll">
                <thead>
                    <tr>
                        <th class="custom-width"><mat-icon>apps</mat-icon></th>
                        <th [matTooltip]="'Status'">Status</th>
                        <th [matTooltip]="'Modifier Name'">Modifier Name</th>
                        <th [matTooltip]="'Description'">Description</th>
                        <th [matTooltip]="'Created By'">Created By</th>
                        <th class="text-right" [matTooltip]="'Created Date'" >Created Date</th>
                        <th [matTooltip]="'Modified By'">Modified By</th>
                        <th class="text-right" [matTooltip]="'Modified Date'" >Modified Date</th>
                        
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let data of modifierTbldata">
                        <td class="custom-width">
                            <ng-container>
                                <a class="editBtnColor" (click)="viewMstModifier(data?.id)" matTooltip="Click To Edit" [class.disabled]="!data?.active">
                                    <i class="fa fa-pencil pointer editBtn mr-1"></i>
                                </a>
                                <!-- &nbsp;
                                <a class="deleteBtnColor" (click)="deleteMstModifier(data?.id)">
                                    <i class="fa fa-trash-o" matTooltip="Click To Delete"></i>
                                </a> -->
                            </ng-container>
                        </td>
                        <td>
                            <ng-container *ngIf="data?.active">
                                <mat-slide-toggle (change)="statusChange($event, data)" [checked]='true'
                                    class="ml-2">
                                    {{ data?.active ? 'Active' : 'Inactive' }}</mat-slide-toggle>
                            </ng-container>
                            <ng-container *ngIf="!data?.active">
                                <mat-slide-toggle (change)="statusChange($event, data)" [checked]='false'
                                    class="ml-2">
                                    {{ data?.active ? 'Active' : 'Inactive' }}</mat-slide-toggle>
                            </ng-container>
                        </td>
                        <td (dblclick)="onRowDoubleClickV1(data?.mmRefId || '-')"
                            [matTooltip]="data?.mmRefId || '-'"> 
                            {{ data?.mmRefId || '-' }} 
                        </td>
                        <td (dblclick)="onRowDoubleClickV1(data?.description || '-')"
                             [matTooltip]="data?.description || '-'"> 
                            {{ data?.description || '-' }} 
                        </td>
                        <td [matTooltip]="data?.createdBy || '-'"
                        (dblclick)="onRowDoubleClickV1(data?.createdBy || '-')">
                            {{ data?.createdBy || '-' }}
                        </td>

                        <td class="text-right" [matTooltip]="data?.creationTime"
                            (dblclick)="onRowDoubleClickV1(data?.creationTime)">
                            {{ data?.creationTime }}
                        </td>
                        <td [matTooltip]="data?.modifiedBy|| '-'"
                            (dblclick)="onRowDoubleClickV1(data?.modifiedBy || '-')">{{
                            data?.modifiedBy || '-' }}</td>

                        <td class="text-right" [matTooltip]="data?.lastModificationTime"
                            (dblclick)="onRowDoubleClickV1(data?.lastModificationTime)" >
                            {{ data?.lastModificationTime }}
                        </td>
                    </tr>
                </tbody>
                <tfoot>
                    <tr>
                        <th class="custom-width"><mat-icon>apps</mat-icon></th>
                        <th>
                            <input matInput class="textbox-border-class" type="text" placeholder="Status"
                                name="search-Status" />
                        </th>
                        <th>
                            <input matInput class="textbox-border-class" type="text" placeholder="Modifier Name"
                                name="search-ModifierName" />
                        </th>
                        <th>
                            <input matInput class="textbox-border-class" type="text" placeholder="Description"
                                name="search-description" />
                        </th>
                        <th>
                          <input matInput class="textbox-border-class" type="text" placeholder="Created By"
                              name="search-CreatedBy" />
                      </th>
                      <th>
                          <input matInput class="textbox-border-class text-right" type="text"
                                placeholder="CreatedDate" name="search-Created Date" />
                       </th>
                       <th>
                          <input matInput class="textbox-border-class" type="text" placeholder="Modified By"
                              name="search-ModifiedBy" />
                      </th>
                        <th>
                            <input matInput class="textbox-border-class text-right" type="text"
                                placeholder="Modified Date" name="search-ModifiedDate" />
                        </th>
                    </tr>
                </tfoot>
            </table>
        </div>
    </div>
</div>
<!-- Table End -->
