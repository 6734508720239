<br />
<div class="card card-body">
  <!-- <div class="list-box"> -->
  <form [formGroup]="ClinicalForm">
    <div class="row" formGroupName="ClinicalGeneral">
      <div class="col-lg-6">
        <div class="row">
          <div class="col-md-6">
            <h4 class="customThemeClass"><b>General</b></h4>
          </div>
        </div>
        <mat-card-content>
          <div class="row">
            <div class="col-6">
              <br />
              <mat-checkbox formControlName="txtGeneralCondition"> Infectious Condition</mat-checkbox>
            </div>
            <mat-form-field class="col-6">
              <mat-label>Gender<span class="asterisk">*</span></mat-label>
              <mat-select formControlName="drpGeneralGender" [(value)]="gender" maxlength="40">
                <mat-option *ngFor="let test of drpGenderLoop" [value]="test.genderShortCode">
                  {{ test.genderName }}
                </mat-option>
              </mat-select>
              <button mat-button (click)="gender='';$event.stopPropagation()" *ngIf="gender" matSuffix
                (click)="clearGender()" mat-icon-button >
                <mat-icon>close</mat-icon>
              </button>
              <mat-error *ngIf="
               ClinicalForm?.get('ClinicalGeneral.drpGeneralGender')?.touched &&
                ClinicalForm?.get('ClinicalGeneral.drpGeneralGender')?.errors?.required
                ">
                Gender is a required field!
              </mat-error>
            </mat-form-field>
            <div class="col-6">
              <br />
              <mat-checkbox formControlName="txtAirborne">Airborne</mat-checkbox>
            </div>
            <mat-form-field class="col-3">
              <mat-label>Height(In Feet)</mat-label>
              <input autocomplete="new-txtGeneralHeightFeet" matInput type="text" formControlName="txtGeneralHeightFeet" pattern="[0-9]" maxlength="1"
                minlength="1" />
              <!-- mask="0'"  -->

              <!-- <span matPrefix>''</span> -->
              <!-- mask="00''" -->
              <!-- value="''" -->

              <mat-error *ngIf="
                ClinicalForm?.get('ClinicalGeneral.txtGeneralHeightFeet')?.touched &&
                ClinicalForm?.get('ClinicalGeneral.txtGeneralHeightFeet')?.errors
              ">
                Enter Minimum 1 Digit!
              </mat-error>
              <mat-error *ngIf="
            ClinicalForm?.get('ClinicalGeneral.txtGeneralHeightFeet')?.touched &&
            ClinicalForm?.get('ClinicalGeneral.txtGeneralHeightFeet')?.errors?.number
          ">
                Enter only Numbers!
              </mat-error>

            </mat-form-field>
            <mat-form-field class="col-3">
              <mat-label>Height(In Inches)</mat-label>
              <input autocomplete="new-txtGeneralHeightInches" matInput type="text" formControlName="txtGeneralHeightInches" maxlength="2" />

              <!-- <span matPrefix>''</span> -->
              <!-- mask="00''" -->
              <!-- value="''" -->

              <mat-error *ngIf="
            ClinicalForm?.get('ClinicalGeneral.txtGeneralHeightInches')?.touched &&
            ClinicalForm?.get('ClinicalGeneral.txtGeneralHeightInches')?.errors">
                Inches should be less than 11 Only allowed!
              </mat-error>
              <mat-error *ngIf="
          ClinicalForm?.get('ClinicalGeneral.txtGeneralHeightInches')?.touched &&
          ClinicalForm?.get('ClinicalGeneral.txtGeneralHeightInches')?.errors?.number
        ">
                Enter only Numbers!
              </mat-error>

            </mat-form-field>
            <div class="col-6">
              <br />
              <mat-checkbox formControlName="txtContact">Contact</mat-checkbox>
            </div>

            <mat-form-field class="col-6">
              <mat-label>Weight(lbs)</mat-label>
              <input autocomplete="new-txtGeneralWeight" matInput (keypress)="numberOnly($event)" formControlName="txtGeneralWeight" maxlength="3"
                type="text" />
              <mat-error *ngIf="
                ClinicalForm?.get('ClinicalGeneral.txtGeneralWeight')?.touched &&
                ClinicalForm?.get('ClinicalGeneral.txtGeneralWeight')?.errors?.number
              ">
                Enter only Numbers!
              </mat-error>
            </mat-form-field>

            <div class="col-6">
              <br />
              <mat-checkbox formControlName="txtDroplet">Droplet</mat-checkbox>
            </div>

            <mat-form-field class="col-6">
              <mat-label>DOD</mat-label>
              <!-- <input matInput formControlName="txtGeneralDod" maxlength="40" type="text" /> -->
              <input autocomplete="new-txtGeneralDod" formControlName="txtGeneralDod" placeholder="MM/DD/YYYY" matInput [matDatepicker]="myPicker2"
                maxlength="40" />
              <mat-datepicker-toggle matSuffix [for]="myPicker2"> </mat-datepicker-toggle>
              <mat-datepicker #myPicker2></mat-datepicker>
              <mat-error *ngIf="
                ClinicalForm?.get('ClinicalGeneral.txtGeneralDod')?.touched &&
                ClinicalForm?.get('ClinicalGeneral.txtGeneralDod')?.errors
              ">
                Enter Valid Date!
              </mat-error>
            </mat-form-field>

            <mat-form-field class="col-6 pt-1">
              <mat-label>Functional Ability</mat-label>
              <input autocomplete="new-txtGeneralFunctionalAbility" matInput formControlName="txtGeneralFunctionalAbility" maxlength="40" type="text" />
            </mat-form-field>
            <!-- <ng-select class="col-6" formControlName="txtGeneralPractioner" placeholder="Practitioner" maxlength="40">
              <ng-option *ngFor="let doctor of drpDoctor" [value]="doctor.id">
                {{ doctor.name }}</ng-option>
            </ng-select> -->


            <mat-form-field class="col-6">
              <mat-label>Practitioner</mat-label>
              <mat-select formControlName="txtGeneralPractioner" [(value)]="Practitioner" #singleSelect>
                <mat-option>
                  <ngx-mat-select-search placeholderLabel="Search" noEntriesFoundLabel="No Matching found"
                    formControlName="txtPractitionerSearchFilter"></ngx-mat-select-search>
                </mat-option>
                <mat-option *ngFor="let doctor of filteredPractionerTypes | async" [value]="doctor.id">
                  {{ doctor.name }}
                </mat-option>
              </mat-select>
              <button mat-button (click)="Practitioner='';$event.stopPropagation()" *ngIf="Practitioner"
                (click)="clearPractitionerDoctor()" matSuffix mat-icon-button >
                <mat-icon>close</mat-icon>
              </button>

            </mat-form-field>





          </div>
        </mat-card-content>
      </div>
      <div class="col-lg-6">
        <div class="row">
          <div class="col-md-6">
            <h4 class="customThemeClass"><b>Ordering Doctor</b></h4>
          </div>
          <div class="col-md-6 text-right">
            <!-- <button type="button"
          *ngIf="ClinicalForm?.get('ClinicalGeneral.txtOrderingDoctor')?.value!==null"
            class="buttonColor btn-sm ml-2 mr-2"
            (click)="openDoctorModal(ClinicalForm?.get('ClinicalGeneral.txtOrderingDoctor')?.value)">
            <i class="fa fa-pen"></i>
          </button> -->
            <button *ngIf='isPatientLockedByCurrentUser' type="button" class="buttonColor btn-sm"
              (click)="openDoctorModal()">
              <i class="fa fa-plus" matTooltip="Click to Add Doctor"></i>
            </button>
          </div>
        </div>
        <mat-card-content>
          <div class="row">
            <!-- <ng-select class="col-12" formControlName="txtOrderingDoctor" placeholder="Ordering Doctor" maxlength="40">
            <ng-option *ngFor="let doctor of drpDoctor" [value]="doctor.id">
              {{ doctor.name }}
            </ng-option>
          </ng-select> -->
            <mat-form-field class="col-12">
              <mat-label>Ordering Doctor</mat-label>
              <mat-select formControlName="txtOrderingDoctor" [(value)]="OrderingDoctor" #singleSelect>
                <mat-option>
                  <ngx-mat-select-search placeholderLabel="Search" noEntriesFoundLabel="No Matching found"
                    formControlName="txtOrdSearchFilter"></ngx-mat-select-search>
                </mat-option>
                <mat-option *ngFor="let doctor of filteredOrderingDoctorTypes | async" [value]="doctor.name">
                  {{ doctor.name }}
                </mat-option>
              </mat-select>
              <button mat-button (click)="OrderingDoctor='';$event.stopPropagation()" *ngIf="OrderingDoctor"
                (click)="clearOrderingDoctor()" matSuffix mat-icon-button >
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field>
          </div>
        </mat-card-content>
        <br />
        <div class="row">
          <div class="col-md-6">
            <h4 class="customThemeClass"><b>Primary Doctor</b></h4>
          </div>
          <div class="col-md-6 text-right">
            <button mat-button class="buttonColor mr-1 font-size-12 btn-sm" (click)='sameAsOrderDoctor()'>
              <!-- <i class="bx bx-copy-alt font-size-16 mr-1"></i> -->
              <i class="fa fa-clone mr-1" ></i>
              Same as Ordering Doctor
            </button>
          </div>
        </div>
        <mat-card-content>
          <div class="row">
            <!-- <ng-select class="col-12" formControlName="txtPrimaryDoctor" placeholder="Primary Doctor" maxlength="40">
            <ng-option *ngFor="let doctor of drpDoctor" [value]="doctor.id">
              {{ doctor.name }}</ng-option>
          </ng-select> -->
            <mat-form-field class="col-12">
              <mat-label>Primary Doctor</mat-label>
              <mat-select formControlName="txtPrimaryDoctor" [(value)]="PrimaryDoctor" #singleSelect>
                <mat-option>
                  <ngx-mat-select-search placeholderLabel="Search" noEntriesFoundLabel="No Matching found"
                    formControlName="txtPrimaryDoctorSearchFilter"></ngx-mat-select-search>
                </mat-option>
                <mat-option *ngFor="let doctor of filteredPrimaryDoctorTypes | async" [value]="doctor.name">
                  {{ doctor.name }}
                </mat-option>
              </mat-select>
              <button mat-button (click)="PrimaryDoctor='';$event.stopPropagation()" *ngIf="PrimaryDoctor"
                (click)="clearPrimaryDoctor()" matSuffix mat-icon-button >
                <mat-icon>close</mat-icon>
              </button>

            </mat-form-field>
          </div>
        </mat-card-content>
        <br />
        <div class="row">
          <div class="col-md-4">
            <h4 class="customThemeClass"><b>Marketing Rep</b></h4>
          </div>
          <div class="col-md-8 text-right">
            <button mat-button (click)='sameAsReferalDoctor()' class="buttonColor mr-1 font-size-12 btn-sm">
              <!-- <i class="bx bx-copy-alt font-size-16 mr-1"></i> -->
              <i class="fa fa-clone mr-1" ></i>
              Same as Referral
            </button>
          </div>
        </div>
        <mat-card-content>
          <div class="row">
            <!-- <ng-select class="col-12" formControlName="txtMarketingRep" placeholder="Marketing Rep" maxlength="40">
            <ng-option *ngFor="let doctor of drpDoctor" [value]="doctor.id">
              {{ doctor.name }}</ng-option>
          </ng-select> -->
            <mat-form-field class="col-12">
              <mat-label>Marketing Rep</mat-label>
              <mat-select formControlName="txtMarketingRep" [(value)]="MarRepDoctor" #singleSelect>
                <mat-option>
                  <ngx-mat-select-search placeholderLabel="Search" noEntriesFoundLabel="No Matches found"
                    formControlName="txtMarRepSearchFilter"></ngx-mat-select-search>
                </mat-option>
                <mat-option *ngFor="let doctor of filteredMarRepDoctorTypes | async" [value]="doctor.id">
                  {{ doctor.name }}
                </mat-option>
              </mat-select>
              <button mat-button (click)="MarRepDoctor='';$event.stopPropagation()" *ngIf="MarRepDoctor"
                (click)="clearMarRepDoctor()" matSuffix mat-icon-button >
                <mat-icon>close</mat-icon>
              </button>

            </mat-form-field>
          </div>
        </mat-card-content>
      </div>
    </div>

    <br />
    <div class="row" formGroupName="ClinicalGeneral">
      <div class="col-lg-6">
        <div class="row">
          <div class="col-md-6">
            <h4 class="customThemeClass"><b>Marketing Referral</b></h4>
          </div>
          <div class="col-md-6 text-right">
            <!-- <button type="button" *ngIf="ClinicalForm?.get('ClinicalGeneral.txtReferralDoctor')?.value!==null"
            class="buttonColor btn-sm ml-2 mr-2"
            (click)="openDoctorModal(ClinicalForm?.get('ClinicalGeneral.txtReferralDoctor')?.value)">
            <i class="fa fa-pen"></i>
          </button> -->
            <button *ngIf='isPatientLockedByCurrentUser' type="button" class="buttonColor btn-sm"
              (click)="openDoctorModal()">
              <i class="fa fa-plus" matTooltip="Click to Add Doctor"></i>
            </button>
          </div>
        </div>
        <mat-card-content>
          <div class="row">
            <!-- <mat-form-field class="col-6">
            <mat-label>Type</mat-label>
            <mat-select formControlName="txtReferralType">
              <mat-option *ngFor="let test of drpMarketingtypeLoop" [value]="test.marketingReferralTypeShortCodeId">
                {{ test.marketingReferralTypeName }}
              </mat-option>
            </mat-select>
          </mat-form-field> -->
            <!-- <ng-select class="col-6" formControlName="txtReferralType" placeholder="Type" maxlength="40">
              <ng-option *ngFor="let test of drpMarketingtypeLoop" [value]="test.marketingReferralShortCodeId">
                {{ test.marketingReferralTypeName }}</ng-option>
            </ng-select> -->

            <mat-form-field class="col-6 mt-1">
              <mat-label>Type</mat-label>
              <mat-select formControlName="txtReferralType" [(value)]="referingType" maxlength="40">
                <mat-option *ngFor="let test of drpMarketingtypeLoop" [value]="test.marketingReferralShortCodeId">
                  {{ test.marketingReferralTypeName }}
                </mat-option>
              </mat-select>
              <button mat-button (click)="referingType='';$event.stopPropagation()" *ngIf="referingType"
                (click)="clearreferingType()" matSuffix mat-icon-button >
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field>



            <mat-form-field class="col-6">
              <mat-label>Facility</mat-label>
              <mat-select formControlName="txtReferralFacility" [(value)]="facility" maxlength="40">
                <mat-option *ngFor="let facility of drpFacility" [value]="facility.facilityShortCodeId">
                  {{ facility.facilityName }}
                </mat-option>
              </mat-select>
              <button mat-button (click)="facility='';$event.stopPropagation()" *ngIf="facility" matSuffix
                (click)="clearFacility()" mat-icon-button >
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field>

            <mat-form-field class="col-6 pb-1">
              <mat-label>Contact</mat-label>
              <input autocomplete="new-txtReferringContact" formControlName="txtReferringContact" maxlength="10" matInput type="text" />
              <mat-error *ngIf="
            ClinicalForm?.get('ClinicalGeneral.txtReferringContact')?.touched &&
            ClinicalForm?.get('ClinicalGeneral.txtReferringContact')?.errors?.number
          ">
                Enter only Numbers!
              </mat-error>
            </mat-form-field>
            <!-- <ng-select class="col-6" formControlName="txtReferralDoctor" placeholder="Referral Doctor" maxlength="40">
            <ng-option *ngFor="let doctor of drpDoctor" [value]="doctor.id">
              {{ doctor.name }}</ng-option>
          </ng-select> -->
            <mat-form-field class="col-6">
              <mat-label>Doctor</mat-label>
              <mat-select formControlName="txtReferralDoctor" [(value)]="MarketingDoctor" #singleSelect>
                <mat-option>
                  <ngx-mat-select-search placeholderLabel="Search" noEntriesFoundLabel="No Matches found"
                    formControlName="txtMarDoctorFilter"></ngx-mat-select-search>
                </mat-option>
                <mat-option *ngFor="let doctor of filteredMarDoctorTypes | async" [value]="doctor.id">
                  {{ doctor.name }}
                </mat-option>
              </mat-select>
              <button mat-button (click)="MarketingDoctor='';$event.stopPropagation()" *ngIf="MarketingDoctor"
                (click)="clearMarketingDoctor()" matSuffix mat-icon-button >
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field>
          </div>
        </mat-card-content>
      </div>
      <!-- <div class="col-lg-4">
        <div class="row">
          <div class="col-md-6">
            <h4 class="customThemeClass"><b>Rendering Provider</b></h4>
          </div>
          <div class="col-md-6"></div>
        </div>
        <mat-card-content>
          <div class="row">
            <mat-form-field class="col-6 mt-1">
              <mat-label>Type</mat-label>
              <mat-select formControlName="txtRenderingType" [(value)]="renderingType" maxlength="40">
                <mat-option *ngFor="let test of drpRenderingTypeLoop" [value]="test.renderingProviderShortCodeId">
                  {{ test.renderingProviderName }}
                </mat-option>
              </mat-select>
              <button mat-button (click)="renderingType='';$event.stopPropagation()" *ngIf="renderingType"
                (click)="clearRenderingType()" matSuffix mat-icon-button >
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field> -->
      <!-- <ng-select formControlName="txtRenderingDoctor" class="col-6" placeholder="Rendering Doctor" maxlength="40">
            <ng-option *ngFor="let doctor of drpDoctor" [value]="doctor.id">
              {{ doctor.name }}</ng-option>
          </ng-select> -->
      <!-- <mat-form-field class="col-6">
              <mat-label>Doctor</mat-label>
              <mat-select formControlName="txtRenderingDoctor" [(value)]="RenderingDoctor" #singleSelect>
                <mat-option>
                  <ngx-mat-select-search placeholderLabel="Search" noEntriesFoundLabel="No Matches found"
                    formControlName="txtRenDoctorFilter"></ngx-mat-select-search>
                </mat-option>
                <mat-option *ngFor="let doctor of filteredRenDoctorTypes | async" [value]="doctor.name">
                  {{ doctor.name }}
                </mat-option>
              </mat-select>
              <button mat-button (click)="RenderingDoctor='';$event.stopPropagation()" *ngIf="RenderingDoctor"
                (click)="clearRenderingDoctor()" matSuffix mat-icon-button >
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field> -->

      <!-- Provider  -->
      <!-- <mat-form-field class="col-6">
              <mat-label>Provider</mat-label>
              <mat-select maxlength="40" [(value)]="provider" formControlName="txtRenDoctorFilter">
                <mat-option *ngFor="let providers of drpProviders" [value]="providers.shortCodeId">
                  {{ providers.name }}
                </mat-option>
              </mat-select>
              <button mat-button (click)="provider='';$event.stopPropagation()" *ngIf="provider" matSuffix
                (click)="clearProvider()" mat-icon-button >
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field> -->
      <!--  -->
      <!-- <mat-form-field class="col-6">
              <mat-label>Facility</mat-label>
              <mat-select formControlName="txtRenderingFacility" [(value)]="renderingFacility" maxlength="40">
                <mat-option *ngFor="let facility of drpFacility" [value]="facility.facilityShortCodeId">
                  {{ facility.facilityName }}
                </mat-option>
              </mat-select>
              <button mat-button (click)="renderingFacility='';$event.stopPropagation()"
                (click)="clearRenderingFacility()" *ngIf="renderingFacility" matSuffix mat-icon-button
                >
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field> -->
      <!-- </div>
        </mat-card-content>
      </div> -->
      <div class="col-lg-6">
        <div class="row">
          <div class="col-md-6">
            <h4 class="customThemeClass"><b>Referring Provider</b></h4>
          </div>
          <div class="col-md-6"></div>
        </div>
        <mat-card-content>
          <div class="row">
            <mat-form-field class="col-6 mt-1">
              <mat-label>Type</mat-label>
              <mat-select formControlName="txtReferringType" [(value)]="referringType" maxlength="40">
                <mat-option *ngFor="let test of drpRenderingTypeLoop" [value]="test.renderingProviderShortCodeId">
                  {{ test.renderingProviderName }}
                </mat-option>
              </mat-select>
              <button mat-button (click)="referringType='';$event.stopPropagation()" *ngIf="referringType"
                (click)="clearReferringType()" matSuffix mat-icon-button >
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field>

            <!-- <ng-select class="col-6" formControlName="txtReferringDoctor" placeholder="Referring Doctor" maxlength="40">
            <ng-option *ngFor="let doctor of drpDoctor" [value]="doctor.id">
              {{ doctor.name }}</ng-option>
          </ng-select> -->
            <mat-form-field class="col-6">
              <mat-label>Doctor</mat-label>
              <mat-select formControlName="txtReferringDoctor" [(value)]="ReferringDoctor" #singleSelect>
                <mat-option>
                  <ngx-mat-select-search placeholderLabel="Search" noEntriesFoundLabel="No Matches found"
                    formControlName="txtRefDoctorFilter"></ngx-mat-select-search>
                </mat-option>
                <mat-option *ngFor="let doctor of filteredRefDoctorTypes | async" [value]="doctor.name">
                  {{ doctor.name }}
                </mat-option>
              </mat-select>
              <button mat-button (click)="ReferringDoctor='';$event.stopPropagation()" *ngIf="ReferringDoctor"
                (click)="clearReferringDoctor()" matSuffix mat-icon-button >
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field>
            <mat-form-field class="col-6">
              <mat-label>Facility</mat-label>
              <mat-select [(value)]="referringFacility" formControlName="txtReferringFacility">
                <mat-option *ngFor="let facility of drpFacility" [value]="facility.facilityShortCodeId">
                  {{ facility.facilityName }}
                </mat-option>
              </mat-select>
              <button mat-button (click)="referringFacility='';$event.stopPropagation()"
                (click)="clearReferringFacility()" *ngIf="referringFacility" matSuffix mat-icon-button
                >
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field>
          </div>
        </mat-card-content>
      </div>
    </div>
    <br />
    <div class="row" formGroupName="ClinicalDiagnosisGroup">
      <div class="col-lg-6">
        <div class="row">
          <div class="col-md-9">
            <!-- <h4  class="customThemeClass"><b>Diagnosis Codes-ICD-9 <span
                  class="asterisk">*</span></b>
            </h4> -->
            <h4 class="customThemeClass"><b>Diagnosis Codes-ICD-9</b></h4>
          </div>
          <div class="col-md-3 text-right">
            <button *ngIf='isPatientLockedByCurrentUser' type="button" class="buttonColor btn-sm"
              (click)="openIcd9Modal()">
              <i class="fa fa-plus" matTooltip="Click to Add DiagnosisCodes-9"></i>
            </button>
          </div>
        </div>
        <mat-card-content>
          <div class="row">
            <!-- <ng-select class="col-12" (change)='onIcd9Change($event)' formControlName="txtDiagnosisCodes9"
            placeholder="Diagnosis Code-ICD-9" maxlength="40">
            <ng-option *ngFor="let diagnosis of drpDiagnosisICD9" [value]="diagnosis.diagnosisCode9ShortCodeId">
              {{ diagnosis.diagnosisCodeTypeData }}</ng-option>
          </ng-select> -->
            <mat-form-field class="col-12">
              <mat-label>Diagnosis Code ICD-9</mat-label>
              <mat-select multiple formControlName="txtDiagnosisCodes9" [(value)]="Icd9" #singleSelect>
                <mat-option>
                  <ngx-mat-select-search placeholderLabel="Search" noEntriesFoundLabel="No Matches found"
                    formControlName="txtIcd9Filter"></ngx-mat-select-search>
                </mat-option>
                <!-- <cdk-virtual-scroll-viewport itemSize="5" [style.height.px]=5*38>
                  <mat-option *cdkVirtualFor="let diagnosis9 of filteredIcd9Types | async"
                    [value]="diagnosis9.diagnosisCode9ShortCodeId">
                    {{ diagnosis9.diagnosisCodeTypeData }}
                  </mat-option>
                </cdk-virtual-scroll-viewport> -->
                <mat-option *ngFor="let diagnosis9 of filteredIcd9Types | async"
                  [value]="diagnosis9?.diagnosisCode9ShortCodeId">
                  {{ diagnosis9?.diagnosisCodeTypeData }}
                </mat-option>
              </mat-select>
              <button mat-button (click)="Icd9=[];$event.stopPropagation()" *ngIf="Icd9?.length!==0" matSuffix
                (click)="clearIcd9()" mat-icon-button >
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field>
            <!-- <mat-form-field class="col-12">
              <mat-label>Diagnosis Code-ICD-10</mat-label>
              <mat-select multiple formControlName="txtdiagnosisCode10Id2"
                [(value)]="txt2Icd10" #singleSelect>
                <mat-option>
                  <ngx-mat-select-search placeholderLabel="Search" noEntriesFoundLabel="No Matches found"
                    formControlName="txtIcd10Filter2"></ngx-mat-select-search>
                </mat-option>
                <mat-option *ngFor="let diagnosis of filtered2Icd10Types | async"
                  [value]="diagnosis.diagnosisCode10ShortCodeId">
                  {{ diagnosis.diagnosisCodeTypeData }}
                </mat-option>
              </mat-select>
              <button mat-button (click)="txt2Icd10='';$event.stopPropagation()" *ngIf="txt2Icd10" matSuffix
                (click)="cleartxt2Icd10()" mat-icon-button >
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field> -->
            <!-- <mat-error *ngIf="
                ClinicalForm?.get('ClinicalDiagnosisGroup.txtDiagnosisCodes9')?.touched &&
                ClinicalForm?.get('ClinicalDiagnosisGroup.txtDiagnosisCodes9')?.errors
              ">
              &nbsp; &nbsp; Diagnosis Code ICD-9 is a required field
            </mat-error> -->
          </div>
        </mat-card-content>
      </div>
      <div class="col-lg-6">
        <div class="row">
          <div class="col-md-6">
            <!-- <h4 *ngIf='!isIcd10Shown' class="customThemeClass"><b>Diagnosis Codes-ICD-10 <span
                  class="asterisk">*</span></b></h4> -->
            <h4 class="customThemeClass"><b>Diagnosis Codes-ICD-10</b> <span class="asterisk">*</span></h4>
          </div>
          <div class="col-md-6 text-right">
            <button *ngIf='isPatientLockedByCurrentUser' type="button" class="buttonColor btn-sm btn-circle"
              (click)="openIcd10Modal()">
              <i class="fa fa-plus" matTooltip="Click to Add DiagnosisCodes-10"></i>
            </button>
          </div>
        </div>
        <mat-card-content>
          <div class="row">
            <!-- <ng-select class="col-4" (change)='onIcd10Change1($event)' formControlName="txtdiagnosisCode10Id1"
            placeholder="Diagnosis Code-ICD-10" maxlength="40">
            <ng-option *ngFor="let diagnosis of drpDiagnosisICD10" [value]="diagnosis.diagnosisCode10ShortCodeId">
              {{ diagnosis.diagnosisCodeTypeData }}</ng-option>
          </ng-select> -->
            <mat-form-field class="col-12">
              <mat-label>Diagnosis Code-ICD-10</mat-label>
              <mat-select multiple formControlName="txtdiagnosisCode10Id1" [(value)]="txt1Icd10" #singleSelect>
                <mat-option>
                  <ngx-mat-select-search placeholderLabel="Search" noEntriesFoundLabel="No Matches found"
                    formControlName="txtIcd10Filter1"></ngx-mat-select-search>
                </mat-option>
                <!-- <cdk-virtual-scroll-viewport itemSize="5" [style.height.px]=5*38> -->
                <mat-option *ngFor="let diagnosis of filtered1Icd10Types | async"
                  [value]="diagnosis.diagnosisCode10ShortCodeId">
                  {{ diagnosis.diagnosisCodeTypeData }}
                </mat-option>
                <!-- </cdk-virtual-scroll-viewport> -->
              </mat-select>
              <button mat-button (click)="txt1Icd10=[];$event.stopPropagation()" *ngIf="txt1Icd10" matSuffix
                (click)="onIcd10Change1($event?.value)" (click)="cleartxt1Icd10()" mat-icon-button >
                <mat-icon>close</mat-icon>
              </button>

              <mat-error *ngIf="
                ClinicalForm?.get('ClinicalDiagnosisGroup.txtdiagnosisCode10Id1')?.touched &&
                  ClinicalForm?.get('ClinicalDiagnosisGroup.txtdiagnosisCode10Id1')?.errors
              ">
                Diagnosis Code-ICD-10 is a required field!
              </mat-error>
            </mat-form-field>
            <!-- <ng-select class="col-4" (change)='onIcd10Change2($event)' formControlName="txtdiagnosisCode10Id2"
            placeholder="Diagnosis Code-ICD-10" maxlength="40">
            <ng-option *ngFor="let diagnosis of drpDiagnosisICD10" [value]="diagnosis.diagnosisCode10ShortCodeId">
              {{ diagnosis.diagnosisCodeTypeData }}</ng-option>
          </ng-select> -->

            <!-- <ng-select class="col-4" (change)='onIcd10Change3($event)' formControlName="txtdiagnosisCode10Id3"
            placeholder="Diagnosis Code-ICD-10" maxlength="40">
            <ng-option *ngFor="let diagnosis of drpDiagnosisICD10" [value]="diagnosis.diagnosisCode10ShortCodeId">
              {{ diagnosis.diagnosisCodeTypeData }}</ng-option>
          </ng-select> -->
            <!-- <mat-form-field class="col-12">
              <mat-label>Diagnosis Code-ICD-10</mat-label>
              <mat-select formControlName="txtdiagnosisCode10Id3" (selectionChange)='onIcd10Change3($event.value)'
                [(value)]="txt3Icd10" #singleSelect>
                <mat-option>
                  <ngx-mat-select-search placeholderLabel="Search" noEntriesFoundLabel="No Matches found"
                    formControlName="txtIcd10Filter3"></ngx-mat-select-search>
                </mat-option>
                <mat-option *ngFor="let diagnosis of filtered3Icd10Types | async"
                  [value]="diagnosis.diagnosisCode10ShortCodeId">
                  {{ diagnosis.diagnosisCodeTypeData }}
                </mat-option>
              </mat-select>
              <button mat-button (click)="txt3Icd10='';$event.stopPropagation()" *ngIf="txt3Icd10" matSuffix
                (click)="cleartxt3Icd10()" mat-icon-button >
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field> -->
          </div>
        </mat-card-content>
      </div>
    </div>
    <br />
    <div class="row" formGroupName="ClinicalAppointmentGroup">
      <div class="col-lg-12">
        <div class="row">
          <div class="col-md-6">
            <h4 class="customThemeClass"><b>Appointments</b></h4>
          </div>
          <div class="col-md-6"></div>
        </div>
        <mat-card-content>
          <div class="row">
            <mat-form-field class="col-3">
              <mat-label>Appointment Date</mat-label>
              <input autocomplete="new-txtAppointmentdate" formControlName="txtAppointmentdate" placeholder="(MM/DD/YYYY)" matInput
                [matDatepicker]="myPicker1" maxlength="40" />
              <mat-datepicker-toggle matSuffix [for]="myPicker1"> </mat-datepicker-toggle>
              <mat-datepicker #myPicker1></mat-datepicker>
              <mat-error *ngIf="
                ClinicalForm?.get('ClinicalAppointmentGroup.txtAppointmentdate')?.touched &&
                ClinicalForm?.get('ClinicalAppointmentGroup.txtAppointmentdate')?.errors
              ">
                Enter Valid Date!
              </mat-error>
            </mat-form-field>
            <mat-form-field class="col-2">
              <mat-label>Branch</mat-label>
              <input autocomplete="new-txtAppointmentBranch" formControlName="txtAppointmentBranch" matInput type="text" maxlength="40" />
            </mat-form-field>
            <mat-form-field class="col-2">
              <mat-label>Description</mat-label>
              <textarea formControlName="txtAppointmentDescription" rows="1" maxlength="400" matInput></textarea>
            </mat-form-field>
            <mat-form-field class="col-2">
              <mat-label>Practitioner</mat-label>
              <input autocomplete="new-txtAppointmentPractioner" formControlName="txtAppointmentPractioner" maxlength="40" matInput type="text" />
            </mat-form-field>
            <mat-form-field class="col-3">
              <mat-label>Status</mat-label>
              <input autocomplete="new-txtAppointmentStatus" formControlName="txtAppointmentStatus" maxlength="40" matInput type="text" />
            </mat-form-field>

            <mat-form-field class="col-3">
              <mat-label>Prescription Date</mat-label>
              <input autocomplete="new-txtPrescriptionDate" formControlName="txtPrescriptionDate" placeholder="(MM/DD/YYYY)" matInput
                [matDatepicker]="txtPrescriptionDate" maxlength="40" />
              <mat-datepicker-toggle matSuffix [for]="txtPrescriptionDate"> </mat-datepicker-toggle>
              <mat-datepicker #txtPrescriptionDate></mat-datepicker>
              <mat-error *ngIf="
                ClinicalForm?.get('ClinicalAppointmentGroup.txtPrescriptionDate')?.touched &&
                ClinicalForm?.get('ClinicalAppointmentGroup.txtPrescriptionDate')?.errors
              ">
                Enter Valid Date!
              </mat-error>
            </mat-form-field>

            <div class="col-6">
              <br />
              <mat-checkbox formControlName="chkDoctorSign">Doctor Signed</mat-checkbox>
            </div>

          </div>
        </mat-card-content>
      </div>
    </div>
    <br />

  </form>

  <div class="row">
    <div class="col-sm-12">
      <div class="text-lg-center">
        <button *ngIf='(isShownSaveButton || isShownUpdateIconForPatient) && (isPatientLockedByCurrentUser)'
          (click)="getPatientDetailsForSave()" mat-button [disabled]="saveButtonHide|| !(!ClinicalForm.invalid)"
          class="buttonColor mb-2 mr-2">
          Save
          <mat-icon *ngIf='isShowSpinner'>
            <mat-spinner class="spinner-border spinner-border-sm" diameter="20"> </mat-spinner>
          </mat-icon>
        </button>
      </div>
    </div>
  </div>
  <ng-template #doctorModal let-modal>
    <div class="modal-header">
      <h4 class="modal-title my-3" id="modal-basic-title">
        <b>Add Doctor</b>
      </h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span >&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <ng-container>
        <!-- <div class="d-flex"> -->
        <ul ngbNav #nav="ngbNav" class="nav-pills" orientation="horizontal">
          <li ngbNavItem="Verify with NPI">
            <a ngbNavLink>Verify with NPI</a>
            <ng-template ngbNavContent>
              <!-- <app-patient-npi-doctor></app-patient-npi-doctor> -->
            </ng-template>
          </li>
          <li ngbNavItem="Verify without NPI">
            <a ngbNavLink>Verify without NPI</a>
            <ng-template ngbNavContent>
              <!-- <app-patient-doctor></app-patient-doctor> -->
            </ng-template>
          </li>

        </ul>

        <div [ngbNavOutlet]="nav"></div>
        <!-- </div> -->
        <!-- <ngb-tabset justify="justified" type="pills">
        <ngb-tab id="tab-1" title="Verify with NPI" class="">
          <ng-template ngbTabContent class="nav-item">
            <ng-template [ngTemplateOutlet]="VerifyWithNPI"> </ng-template>
          </ng-template>
        </ngb-tab>
        <ngb-tab id="tab-2" title="Verify without NPI" class="">
          <ng-template ngbTabContent class="nav-item">
            <ng-template [ngTemplateOutlet]="VerifyWithoutNPI"> </ng-template>
          </ng-template>
        </ngb-tab>
      </ngb-tabset> -->
      </ng-container>
      <ng-template #VerifyWithNPI>
        <!-- <app-patient-npi-doctor></app-patient-npi-doctor> -->
      </ng-template>
      <ng-template #VerifyWithoutNPI>
        <!-- <app-patient-doctor></app-patient-doctor> -->
      </ng-template>
    </div>
  </ng-template>

  <ng-template #icd9Modal let-modal>
    <div class="modal-header">
      <h4 class="modal-title my-3" id="modal-basic-title">
        <b>Add Diagnosis Codes ICD-9 </b>
      </h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span >&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <!-- <app-patient-icd9></app-patient-icd9> -->
    </div>
  </ng-template>

  <ng-template #icd10Modal let-modal>
    <div class="modal-header">
      <h4 class="modal-title my-3" id="modal-basic-title">
        <b>Add Diagnosis Codes ICD-10</b>
      </h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span >&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <!-- <app-patient-icd10></app-patient-icd10> -->
    </div>
  </ng-template>
  <!-- </div> -->
</div>
