<div class="container-fluid mt-2">
  <div class="row bg">
    <div class="col-12">
      <div class="d-flex align-items-center justify-content-between">
        <h3 class="font-weight-bolder mb-0">
          <b class="customThemeClass page-header-breadcrumb"
            >List / Billing Dept / Billing</b
          >
        </h3>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-12 mb-3">
      <div class="card mt-2">
        <div class="row">
          <div class="col-xl-12">
            <ul
              class="card card-body"
              ngbNav
              #nav="ngbNav"
              justify="justified"
              class="alert-success nav nav-pills nav-fill"
              style="border-bottom: 3px solid rgb(201, 201, 201)"
            >
              <li
                ngbNavItem="INVOICES"
                [class.selected-tab]="nav.activeId === nikoBillingMenu.INVOICES"
              >
                <a
                  ngbNavLink
                  (click)="selectTab(nikoBillingMenu.INVOICES)"
                  class="tab-header"
                  >Invoices</a
                >
              </li>
              <li
                ngbNavItem="Payments"
                [class.selected-tab]="nav.activeId === nikoBillingMenu.PAYMENTS"
              >
                <a
                  ngbNavLink
                  (click)="selectTab(nikoBillingMenu.PAYMENTS)"
                  class="tab-header"
                  >Payments</a
                >
              </li>
              <li
                ngbNavItem="Denials"
                [class.selected-tab]="nav.activeId === nikoBillingMenu.DENIALS"
              >
                <a
                  ngbNavLink
                  (click)="selectTab(nikoBillingMenu.DENIALS)"
                  class="tab-header"
                  >Denials</a
                >
              </li>
              <li
                ngbNavItem="EOB_ERA"
                [class.selected-tab]="nav.activeId === nikoBillingMenu.EOB_ERA"
              >
                <a
                  ngbNavLink
                  (click)="selectTab(nikoBillingMenu.EOB_ERA)"
                  class="tab-header"
                  >EOB/ERA</a
                >
              </li>
              <li
                ngbNavItem="Deposits"
                [class.selected-tab]="nav.activeId === nikoBillingMenu.DEPOSITS"
              >
                <a
                  ngbNavLink
                  (click)="selectTab(nikoBillingMenu.DEPOSITS)"
                  class="tab-header"
                  >Deposits</a
                >
              </li>
            </ul>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <div class="card-body py-1 px-3">
              <div *ngIf="activeTab === nikoBillingMenu.INVOICES">
                <app-billing-invoice></app-billing-invoice>
              </div>
              <div *ngIf="activeTab === nikoBillingMenu.PAYMENTS">
                <app-billing-payments></app-billing-payments>
              </div>
              <div *ngIf="activeTab === nikoBillingMenu.DENIALS">
                <app-billing-denials></app-billing-denials>
              </div>
              <div *ngIf="activeTab === nikoBillingMenu.EOB_ERA">
                <app-billing-eob-era></app-billing-eob-era>
              </div>
              <div *ngIf="activeTab === nikoBillingMenu.DEPOSITS">
                <app-billing-deposits></app-billing-deposits>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
