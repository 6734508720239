import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { ActivatedRoute, Route, Router } from '@angular/router';
import { OAuthService } from 'angular-oauth2-oidc';
import Swal from 'sweetalert2';

import { AccountService } from '../admin-proxy/platform-app-management/rcm/platform-management/reset-password/account.service';
import { PasswordValidation } from '../confirmedvalidator';

// import { AccountService } from '@proxy/volo/abp/account/account.service';


@Component({
  selector: 'app-update-password',
  templateUrl: './update-password.component.html',
  styleUrls: ['./update-password.component.scss']
})
export class UpdatePasswordComponent implements OnInit {
  tokenId: string = "";
  emailId: string = "";
  tenantId: string = "";
  password='';
  confirmedPassword='';
  showPassword: boolean = false;
  showConfirmPassword: boolean = false;
  constructor(private route: ActivatedRoute, private oauthService: OAuthService, private formBuilder: FormBuilder, private forgotService: AccountService,private router: Router) { }
  resetForm: FormGroup;
  ngOnInit(): void {
    this.resetForm = this.formBuilder.group({
      // txtPassword: new FormControl("",[
      //   Validators.required,
      //   ]),
      // txtPassword: new FormControl("", [Validators.required, Validators.pattern('(?=.*\d)(?=.*[a-z])(?=.*[$@$#!%*?^()&])(?=.*[A-Z]).{8,}')]),
      txtPassword: new FormControl("", [Validators.required]),
      txtConfirmPassword: new FormControl("",[Validators.required])
      //txtRepeatPassword: new FormControl(""),
    },{ validator: this.passwordMatchValidator });

    this.tokenId = this.route?.snapshot?.queryParamMap?.get("t")??"";
    this.emailId = this.route?.snapshot?.queryParamMap?.get("uid")??"";
    this.tenantId = this.route?.snapshot?.queryParamMap?.get("tid")??"";
    this.linkExperiedCheck();
  }

  passwordMatchValidator(form: FormGroup) {
    const password = form.get('txtPassword').value;
    const confirmPassword = form.get('txtConfirmPassword').value;

    if (password !== confirmPassword) {
      form.get('txtConfirmPassword').setErrors({ passwordMismatch: true });
    } else {
      form.get('txtConfirmPassword').setErrors(null);
    }
  }

  togglePasswordVisibility() {
    this.showPassword = !this.showPassword;
  }

  confirmPasswordPasswordVisibility() {
    this.showConfirmPassword = !this.showConfirmPassword;
  }


  // togglePasswordVisibility() {
  //   this.showPassword = !this.showPassword;
  // }
  // confirmPasswordPasswordVisibility() {
  //   this.showConfirmPassword = !this.showConfirmPassword;
  // }
   //! save function
  submit() {
    let datas = {
      userId: this.emailId,
      token: this.tokenId,
      newPassword: this.resetForm.value.txtPassword,
      tenantId: this.tenantId
    }
    this.forgotService.resetPasswordByInput(datas).subscribe(response => {
      Swal.fire({
        icon: 'success',
        text: 'Password Updated Successfully',
        timer: 20000, timerProgressBar: true
      }).then((result => {
        this.oauthService.loadDiscoveryDocumentAndLogin();
      }))
    }, err => {
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'warning',
        text: data?.error?.error?.message,
        timer: 30000, timerProgressBar: true
      }).then((result => {
        this.oauthService.loadDiscoveryDocumentAndLogin();
      }))
    });
    // this.router.navigate(['updatePassword'])
  }
  // back button
  backToLogin() {
    this.oauthService.loadDiscoveryDocumentAndLogin();
  }

  linkExperiedCheck() {
    let datas = {
      userId: this.emailId,
      token: this.tokenId,
      newPassword: '',
      tenantId: this.tenantId
    };
    this.forgotService.passwordTokcenValidationByInput(datas).subscribe(response => {

    }, err => {
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'warning',
        text: data?.error?.error?.message,
        timer: 30000, timerProgressBar: true
      }).then((result => {
        this.oauthService.loadDiscoveryDocumentAndLogin();
      }))
    });
  }
}