<br />
<div class="card card-body">
  <form [formGroup]="documentForm">
    <mat-card-content>
      <mat-card-content class="col-12">
        <div class="row">
          <h4 class="col-12 customThemeClass" style="margin-top: -20px">
            <b>Add Document Info</b>
          </h4>
        </div>
        <div class="row">
          <div class="col-12" *ngIf="showErrorForFileType">
            <span class="asterisk">{{ "Select Only PDF`s" }}</span>
          </div>
          <div class="col-12" *ngIf="showErrorForFileLength">
            <span class="asterisk">{{ "Select Only 10 files" }}</span>
          </div>
        </div>

        <div class="row">
          <div class="col-6">
            <label for="file-upload" (click)="resetDocumentForm()"
              class="col-lg-6 btnclr waves-effect font-size-12 pt-2 pb-2">
              <i class="fa fa-cloud-upload"></i> Browse<span class="asterisk">
                *
              </span>
            </label>
            <input [hidden]="isDocumentForButtonDisable" id="file-upload" formControlName="documentFile"
              [multiple]="true" #UploadFeildReference (change)="getfilename(UploadFeildReference.files)"
              accept="application/pdf" [ngClass]="{ hint: showErrorForExtension }" type="file" />
          </div>
        </div>
        <div class="row pt-1">
          <mat-form-field class="col-3">
            <mat-label>Document Upload Type <span class="asterisk">*</span>
            </mat-label>
            <mat-select (selectionChange)="documentTypeDropdownChange()" formControlName="drpDocumentUploadType"
              maxlength="40">
              <mat-option *ngFor="let uploadType of drpDocumentUploadType" [value]="uploadType.sortingType">
                {{ uploadType.sortingType }}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="
                documentForm?.get('drpDocumentUploadType')?.touched &&
                documentForm?.get('drpDocumentUploadType')?.errors?.required
              ">
              Document Upload Type is a required field!
            </mat-error>
          </mat-form-field>
          <mat-form-field class="col-3">
            <mat-label>Document Type
              <span *ngIf="
                  documentForm?.get('drpDocumentUploadType')?.value ===
                  'Manual Sorting'
                " class="asterisk">*</span>
            </mat-label>
            <mat-select formControlName="drpDocumentType" maxlength="40">
              <mat-option *ngFor="let document of drpDocumentTypeLoop" [value]="document.documentType">
                {{ document.documentType }}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="
                documentForm?.get('drpDocumentType')?.touched &&
                documentForm?.get('drpDocumentType')?.errors?.required
              ">
              Document Type is a required field!
            </mat-error>
          </mat-form-field>
          <mat-form-field class="col-3">
            <mat-label>Review Status</mat-label>
            <mat-select formControlName="drpReviewStatus" maxlength="40">
              <mat-option *ngFor="let reviewstatus of drpReviewStatusLoop"
                [value]="reviewstatus.reviewStatusShortCodeId">
                {{ reviewstatus.reviewStatus }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field class="col-3">
            <mat-label>Review Reason</mat-label>
            <mat-select formControlName="drpReviewReason" maxlength="40">
              <mat-option *ngFor="let reviewstatus of drpReviewReasonLoop"
                [value]="reviewstatus.reviewReasonShortCodeId">
                {{ reviewstatus.reviewReason }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="row">
          <mat-form-field class="col-3">
            <mat-label>Document Start Date
              <ng-container *ngIf="documentForm?.get('drpDocumentType')?.value === 'RX' ||
                    documentForm?.get('drpDocumentType')?.value ==='FACE TO FACE'; else elseStartDateReqTemplate">
                <span class="asterisk">*</span>

              </ng-container>
              <ng-template #elseStartDateReqTemplate>
              </ng-template>
            </mat-label>
            <input autocomplete="new-txtDocumentStartDate" formControlName="txtDocumentStartDate" placeholder="(MM/DD/YYYY)" matInput maxlength="40"
              [ngxMatDatetimePicker]="txtDocumentStartDate" />
            <mat-datepicker-toggle matSuffix [for]="$any(txtDocumentStartDate)"></mat-datepicker-toggle>
            <ngx-mat-datetime-picker #txtDocumentStartDate [showSpinners]="true" [showSeconds]="true" [stepHour]="1"
              [stepMinute]="1" [stepSecond]="1" [touchUi]="false" [color]="'primary'" [enableMeridian]="true">
              <ng-template>
                <span>OK</span>
              </ng-template>
            </ngx-mat-datetime-picker>
            <ng-container *ngIf="documentForm?.get('drpDocumentType')?.value === 'RX' ||
  documentForm?.get('drpDocumentType')?.value ==='FACE TO FACE'; else elseStartDateValTemplate">
              <mat-error *ngIf="
  documentForm?.get('txtDocumentStartDate')?.touched &&
  documentForm?.get('txtDocumentStartDate')?.errors?.required
">
                Document Start Date is a required field!
              </mat-error>
            </ng-container>

            <ng-template #elseStartDateValTemplate>

            </ng-template>
            <mat-error *ngIf="
            documentForm?.get('txtDocumentStartDate')?.touched &&
            documentForm?.get('txtDocumentStartDate')?.errors
              ?.matDatetimePickerParse
          ">
              Enter Valid Date!
            </mat-error>
          </mat-form-field>
          <mat-form-field class="col-3">
            <mat-label>Document End Date
              <ng-container *ngIf="
                  documentForm?.get('drpDocumentType')?.value === 'RX' ||
                    documentForm?.get('drpDocumentType')?.value ===
                      'FACE TO FACE';
                  else elseEndDateReqTemplate
                ">
                <span class="asterisk">*</span>
              </ng-container>
              <ng-template #elseEndDateReqTemplate>

              </ng-template>
            </mat-label>
            <input autocomplete="new-txtDocumentEndDate" formControlName="txtDocumentEndDate" placeholder="(MM/DD/YYYY)" matInput maxlength="40"
              [ngxMatDatetimePicker]="txtDocumentEndDate" />
            <mat-datepicker-toggle matSuffix [for]="$any(txtDocumentEndDate)"></mat-datepicker-toggle>
            <ngx-mat-datetime-picker #txtDocumentEndDate [showSpinners]="true" [showSeconds]="true" [stepHour]="1"
              [stepMinute]="1" [stepSecond]="1" [touchUi]="false" [color]="'primary'" [enableMeridian]="true">
              <ng-template>
                <span>OK</span>
              </ng-template>
            </ngx-mat-datetime-picker>
            <ng-container *ngIf="
                documentForm?.get('drpDocumentType')?.value === 'RX' ||
                  documentForm?.get('drpDocumentType')?.value ===
                    'FACE TO FACE';
                else elseEndDateValTemplate
              ">
              <mat-error *ngIf="
              documentForm?.get('txtDocumentEndDate')?.touched &&
              documentForm?.get('txtDocumentEndDate')?.errors?.required
            ">
                Document End Date is a required field!
              </mat-error>
            </ng-container>
            <ng-template #elseEndDateValTemplate>

            </ng-template>

            <mat-error *ngIf="
                documentForm?.get('txtDocumentEndDate')?.touched &&
                documentForm?.get('txtDocumentEndDate')?.errors
                  ?.matDatetimePickerParse
              ">
              Enter Valid Date!
            </mat-error>
          </mat-form-field>

          <mat-form-field class="col-6">
            <mat-label>Description</mat-label>
            <textarea formControlName="txtNote" rows="1" matInput maxlength="400"></textarea>
          </mat-form-field>
          <button *ngIf="isPatientLockedByCurrentUser" mat-button [hidden]="isDocumentEditSaveButtonHidden" [disabled]="
              documentForm.invalid ||
              (documentForm?.get('drpDocumentUploadType')?.value ===
                'Manual Sorting' &&
                documentForm?.get('drpDocumentType')?.value === null) ||
              isShowSpinner
            " (click)="upload(UploadFeildReference.files)"
            class="btn buttonColor btnsave waves-effect font-size-12 btn-sm mr-2 ml-3">
            Save
            <mat-icon *ngIf="isShowSpinner" class="mr-2 mb-1 ml-2">
              <mat-spinner class="spinner-border spinner-border-sm" diameter="120">
              </mat-spinner>
            </mat-icon>
          </button>
          <button mat-button (click)="resetDocumentForm()" class="resetclr waves-effect font-size-12 btn-sm mr-2 ml-3">
            Reset
          </button>
        </div>
      </mat-card-content>
      <div class="col-12">
        <div *ngIf="show" [hidden]="isDocumentEditSaveButtonHidden"
          class="alert alert-secondary mt-2 pb-0 pr-0 pl-0 pt-2 p1">
          <label for="">
            <i class="bx bxs-info-circle font-size-16 align-middle mr-2"></i>
            Selected Document(s) Count:{{ filesname.length }}</label>
        </div>
        <div *ngIf="filesname !== [] && filesname.length !== 0" class="table-bordered">
          <div *ngIf="filesname !== [] && filesname.length !== 0">
            <div class="lbl customThemeClass" *ngIf="filesname !== [] && filesname.length !== 0">
              <b>Documents To be Uploaded</b>
            </div>
            <div class="bgr mb-1" *ngFor="let item of filesname">
              <i class="fa fa-file-pdf-o"></i>
              {{ item }}
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="isShowProcessBar" style="
          display: flex;
          justify-content: center;
          align-items: center;
          background: transparent;
        ">
      </div>
      <ng-container *ngIf="
          arrMergeDocumentList !== [] && arrMergeDocumentList?.length !== 0
        ">
        <label class="customThemeClass mt-1" *ngIf="
            arrMergeDocumentList !== [] && arrMergeDocumentList?.length !== 0
          ">
          Uploaded Document(s) Count:{{ arrMergeDocumentList?.length }}
        </label>
        <div *ngIf="
            arrMergeDocumentList !== [] && arrMergeDocumentList?.length !== 0
          ">
          <table mat-table #tableRef [dataSource]="mergeDataSource" class="mat-elevation-z8">
            <ng-container matColumnDef="OrderId">
              <th mat-header-cell *matHeaderCellDef>Document Type</th>
              <td mat-cell *matCellDef="let datas; let i = index">
                <mat-form-field>
                  <mat-select placeholder="Document Type" ngModelOptions [(value)]="datas.documentType">
                    <mat-option *ngFor="let type of drpDocumentTypeLoop" [value]="type.documentType">
                      {{ type.documentType }}
                    </mat-option>
                  </mat-select>
                  <button mat-button (click)="selection.deselect(datas)"
                    (click)="datas.documentType = ''; $event.stopPropagation()" *ngIf="datas.documentType" matSuffix
                    mat-icon-button>
                    <mat-icon>close</mat-icon>
                  </button>
                </mat-form-field>
              </td>
            </ng-container>

            <ng-container matColumnDef="ItemId">
              <th class="header" mat-header-cell *matHeaderCellDef>
                File Name
              </th>
              <td mat-cell *matCellDef="let element">{{ element.fileName }}</td>
            </ng-container>
            <ng-container matColumnDef="ItemName">
              <th class="header" mat-header-cell *matHeaderCellDef>
                Document Date
              </th>

              <td mat-cell *matCellDef="let element">
                {{
                element?.documentStartDate | date: "MM/dd/yyyy h:mm a":"en_US"
                }}
                -
                {{
                element?.documentEndDate | date: "MM/dd/yyyy h:mm a":"en_US"
                }}
              </td>
            </ng-container>

            <ng-container matColumnDef="options">
              <th class="header" mat-header-cell *matHeaderCellDef>Options</th>
              <td mat-cell *matCellDef="let documentList">

                <a class="editBtnColor">
                  <i class="fa fa-pencil mr-3 pointer customThemeClass" (click)="
                      viewPdfFile(
                        documentList.blobName,
                        documentList.patientDocumentID
                      )
                    "></i>
                </a>
                <a class="editBtnColor">
                  <i class="fa fa-cloud-download mr-3 pointer customThemeClass" (click)="
                      downloadFile(documentList.blobName, documentList.fileName)
                    "></i>
                </a>
                <a class="editBtnColor">
                  <i *ngIf="isShownDocumentDeleteIcon" class="fa fa-trash pointer asterisk"
                    (click)="deleteFileById(documentList.patientDocumentID)"></i>
                </a>
              </td>
            </ng-container>

            <ng-container matColumnDef="noRecords">
              <td class="text-align-center" mat-footer-cell *matFooterCellDef colspan="9">
                <div *ngIf="!isShowProcessBar">
                  {{ "No Records Found" }}
                </div>
                <div [hidden]="!isShowProcessBar">
                  <mat-spinner [diameter]="220"></mat-spinner>
                </div>
              </td>
            </ng-container>

            <ng-container matColumnDef="select">
              <th mat-header-cell *matHeaderCellDef></th>
              <td mat-cell *matCellDef="let element">
                <mat-checkbox [disabled]="!element.documentType" (click)="$event.stopPropagation()"
                  (change)="$event ? selection.toggle(element) : null" [checked]="selection.isSelected(element)">
                </mat-checkbox>
              </td>
            </ng-container>
            <ng-container matColumnDef="paginator">
              <td mat-footer-cell *matFooterCellDef colspan="5">
                <div class="row">
                  <div class="col-3">
                    <button class="buttonColor mt-2" *ngIf="!selection.isEmpty()"
                      (click)="mergeAndUpdateSelectedDocuments()" mat-button>
                      Merge Selected {{ selection.selected.length }} Documents
                      <mat-icon class="mr-2 ml-2" *ngIf="isShowSpinner">
                        <mat-spinner class="spinner-border spinner-border-sm" diameter="20">
                        </mat-spinner>
                      </mat-icon>
                    </button>
                  </div>
                  <div class="col-9">
                    <mat-paginator class="table table-responsive mat-table-class" #mergePaginator
                      [length]="mergeDataSource?.data?.length" [pageSize]="10" [pageIndex]="0" showFirstLastButtons
                      [pageSizeOptions]="[5, 10, 25, 100]">
                    </mat-paginator>
                  </div>
                </div>
              </td>
            </ng-container>
            <tr mat-footer-row *matFooterRowDef="['noRecords']" [ngClass]="{
                active:
                  mergeDataSource &&
                  mergeDataSource?.data &&
                  mergeDataSource?.data?.length !== 0
              }"></tr>
            <tr mat-header-row *matHeaderRowDef="arrDisplayedMergeColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: arrDisplayedMergeColumns"></tr>
            <tr mat-footer-row *matFooterRowDef="['paginator']"></tr>
          </table>
        </div>
      </ng-container>
      <!-- Uploaded Documents List  -->
      <ng-container >
        <label class="customThemeClass mt-1">
          Uploaded Document(s) Count:{{ arrUnsortedDocumentList?.length || 0 }}
        </label>
        <div class="col-12 table-responsive">
          <table mat-table class="table" [dataSource]="dataSource" matSort>
            <form [formGroup]="documentSearchForm" style="display: flex">
              <ng-container matColumnDef="OrderId">
                <th class="header" mat-header-cell *matHeaderCellDef>
                  <mat-form-field class="filter">
                    <input autocomplete="new-documentType" matInput formControlName="documentType" type="text" />
                    <mat-placeholder class="center">
                      Document Type</mat-placeholder>
                  </mat-form-field>
                </th>
                <td mat-cell *matCellDef="let element">
                  {{ element.documentType }}
                </td>
              </ng-container>
              <ng-container matColumnDef="ItemId">
                <th class="header" mat-header-cell *matHeaderCellDef>
                  <mat-form-field class="filter">
                    <input autocomplete="new-fileName" matInput formControlName="fileName" type="text" />
                    <mat-placeholder class="center"> File Name</mat-placeholder>
                  </mat-form-field>
                </th>
                <td mat-cell *matCellDef="let element">
                  {{ element.fileName }}
                </td>
              </ng-container>
              <ng-container matColumnDef="documentStartDate">
                <th class="header" mat-header-cell *matHeaderCellDef>
                  <mat-form-field class="filter">
                    <mat-label> Document Start Date </mat-label>
                    <input autocomplete="new-documentStartDate" matInput formControlName="documentStartDate" placeholder="(MM/DD/YYYY)" />
                    <mat-error *ngIf="
                        documentSearchForm?.get('documentStartDate')?.touched &&
                        documentSearchForm?.get('documentStartDate')?.errors
                          ?.dateVaidator
                      ">
                      Enter Valid date Only
                    </mat-error>
                  </mat-form-field>
                </th>
                <td mat-cell *matCellDef="let element">
                  {{
                  element?.documentStartDate
                  | date: "MM/dd/yyyy h:mm a":"en_US"
                  }}
                </td>
              </ng-container>
              <ng-container matColumnDef="documentEndDate">
                <th class="header" mat-header-cell *matHeaderCellDef>
                  <mat-form-field class="filter">
                    <mat-label> Document End Date </mat-label>
                    <input autocomplete="new-documentEndDate" matInput formControlName="documentEndDate" placeholder="(MM/DD/YYYY)" />
                    <mat-error *ngIf="
                        documentSearchForm?.get('documentEndDate')?.touched &&
                        documentSearchForm?.get('documentEndDate')?.errors
                          ?.dateVaidator
                      ">
                      Enter Valid date Only
                    </mat-error>
                  </mat-form-field>
                </th>
                <td mat-cell *matCellDef="let element">
                  {{
                  element?.documentEndDate | date: "MM/dd/yyyy h:mm a":"en_US"
                  }}
                </td>
              </ng-container>

              <ng-container matColumnDef="documentStatus">
                <th class="header" mat-header-cell *matHeaderCellDef>
                  <div class="header">Document Status</div>
                </th>
                <td mat-cell *matCellDef="let element">
                  {{ element?.docStatus }}
                </td>
              </ng-container>
              <ng-container matColumnDef="options">
                <th class="header" mat-header-cell *matHeaderCellDef>
                  Options
                </th>
                <td mat-cell *matCellDef="let documentList">
                  <a class="editBtnColor">
                    <i class="fa fa-pencil mr-3 pointer customThemeClass" (click)="
                        viewPdfFile(
                          documentList.blobName,
                          documentList.patientDocumentID
                        )
                      "></i>
                  </a>
                  <a class="editBtnColor">
                    <i class="fa fa-cloud-download mr-3 pointer customThemeClass" (click)="
                        downloadFile(
                          documentList.blobName,
                          documentList.fileName
                        )
                      "></i>
                  </a>
                  <a class="editBtnColor">
                    <i *ngIf="isShownDocumentDeleteIcon" class="fa fa-trash pointer asterisk"
                      (click)="deleteFileById(documentList.patientDocumentID)"></i>
                  </a>
                </td>
              </ng-container>

              <ng-container matColumnDef="noRecords">
                <td class="text-align-center" mat-footer-cell *matFooterCellDef colspan="6">
                  <div *ngIf="!isShowProcessBar">
                    {{ "No Records Found" }}
                  </div>
                  <div [hidden]="!isShowProcessBar">
                    <mat-spinner [diameter]="220"></mat-spinner>
                  </div>
                </td>
              </ng-container>
              <ng-container matColumnDef="paginator">
                <td mat-footer-cell *matFooterCellDef colspan="6">
                  <mat-paginator class="table table-responsive mat-table-class" #paginator
                    [length]="dataSource?.data?.length" [pageSize]="10" [pageIndex]="0" showFirstLastButtons
                    [pageSizeOptions]="[5, 10, 25, 100]">
                  </mat-paginator>
                </td>
              </ng-container>
            </form>
            <tr mat-footer-row *matFooterRowDef="['noRecords']" [ngClass]="{
                active:
                  dataSource &&
                  dataSource.data &&
                  dataSource?.data?.length !== 0
              }"></tr>
            <tr mat-header-row *matHeaderRowDef="arrDisplayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: arrDisplayedColumns"></tr>
            <tr mat-footer-row *matFooterRowDef="['paginator']"></tr>
          </table>
        </div>
      </ng-container>
      <div class="col-12" *ngIf="strSelectedPdfPath">
        <div class="container-fluid mt-5">
          <ngx-extended-pdf-viewer [textLayer]="true" [showHandToolButton]="true" [src]="strSelectedPdfPath"
            [height]="'70vh'" [useBrowserLocale]="false" [delayFirstView]="1000" [showFindButton]="false">
          </ngx-extended-pdf-viewer>
        </div>
      </div>
    </mat-card-content>
  </form>
</div>
