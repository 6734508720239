import { HttpErrorResponse, HttpEventType } from '@angular/common/http';
import {
  Component,
  ElementRef,
  Inject,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { DocumentMaxandTypeLimits } from 'projects/shared/src/app/enums/allenums.enum';
import { TableService } from 'projects/shared/src/app/table.service';
import { PDFDocument } from 'pdf-lib';
import Swal from 'sweetalert2';
import { InboundDocumentService } from '../admin-proxy/platform-app-management/rcm/platform-management/inbound';
import { InboundDocumentDTO } from '../admin-proxy/platform-app-management/rcm/platform-management/inbound/dto';
import { ToastrService } from 'ngx-toastr';
import { OrganizationUnitService } from '../admin-proxy/platform-app-management/rcm/platform-management/organization-units';
import { OrganizationUnitDTO } from '../admin-proxy/platform-app-management/rcm/platform-management/organization-units/dto';
import { CommunicationService } from '../../../../shared/src/app/services/communication.service';
import { Observable } from 'rxjs/Rx';
@Component({
  selector: 'app-add-fax-documents',
  templateUrl: './add-fax-documents.component.html',
  styleUrls: ['./add-fax-documents.component.scss'],
})
export class AddFaxDocumentsComponent implements OnInit {
  tenantId: string = '';
  @ViewChild('AddfileInput') AddfileInput!: ElementRef;
  lstBranch: OrganizationUnitDTO[] = [];
  // seletedBranchValue: string = defaultGuid;
  branchFilterControl = new FormControl();
  fileId: string = '';
  addDocumentbinModalTitle: string = 'Upload Inbound Documents';
  inboundUploadForm: FormGroup;
  showErrorBasedOnMaxSize: boolean = false;
  // showErrorBasedOnSize: boolean = false;
  showErrorForFileType: boolean = false;
  showErrorForFileLength: boolean = false;
  selectedFileUploadsData: Partial<InboundDocumentDTO> | null = null;
  arrSelectedDocuments: any[] = [];
  arrPageCount: number[] = [];
  noErrors: boolean = false;
  chkdisableButton: boolean = false;
  isDisable = false;
  isRefresh = false;
  filteredBranchTypeList: OrganizationUnitDTO[];
  selectedFileCount: number = 0;
  totalSizeforTable: any;
  files:any;
  isProcessBarShow:boolean=true;
  // filesForUpload: any;
  newFilesForUpload: File[] = [];
  // newFilesForUpload: FileList = new DataTransfer().files;
  documentMaxNoFileLimit = DocumentMaxandTypeLimits.documentMaxNoFileLimit;
  AddMoreFileSize: any;
  width : any=1;
  dtManualUploadListOptions: any = {
    responsive: true,
    paging: false,
    searching: false,
    ordering: false,
    info: false,
    scrollCollapse: true,
    scrollY: '500px',
    scrollX: true,
  };
  disableMainFile: boolean = false;
  notificationText: boolean=false;
  filesProcessedV1: any=1;

  //@Input() selectedNode: any;
  constructor(
    private fb: FormBuilder,
    private table: TableService,
    public dialog: MatDialog,
    private branchService: OrganizationUnitService,
    public inboundDocUploadService: InboundDocumentService,
    private toastr: ToastrService,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      selectedNode: any;
    } = {
      selectedNode: null,
    },
    private communicationService: CommunicationService
  ) {
    // this.runProgressBar();
  }

  ngOnInit(): void {
    this.calculateScrollY();
    window.addEventListener('resize', this.calculateScrollY);

    this.inboundUploadForm = this.fb.group({
      documentFile: new FormControl(null),
      drpBranchType: new FormControl('', [Validators.required]),
      chkStat: new FormControl(''),
      addMoreFile: new FormControl(''),
    });

    this.tenantId = localStorage.getItem('tenantId') ?? '';
    this.loadDropdown();
    this.table.setSelectedDocumentList(this.arrSelectedDocuments);
  }

  calculateScrollY = () => {
    const screenHeight =
      window.innerHeight ||
      document.documentElement.clientHeight ||
      document.body.clientHeight;
    const availableSpacePercentage = 0.2; // Adjust the percentage as needed
    this.dtManualUploadListOptions.scrollY =
      screenHeight * availableSpacePercentage;
  };

  calculateTotalSize(files) {
    let totalSize = 0;
    let status = false;
    let maxTotalSize = DocumentMaxandTypeLimits.ManualUploadMaxSize;
    for (const key in files) {
      if (files.hasOwnProperty(key)) {
        totalSize += files[key].size;
      }
    }
    if (totalSize <= maxTotalSize) {
      status = true;
    }
    return status;
  }

  calculateTotalSizeForMore(files,AddMoreFile) {
    let totalSize = 0;
    let status = false;
    let maxTotalSize = DocumentMaxandTypeLimits.ManualUploadMaxSize;
    for (const key in files) {
      if (files.hasOwnProperty(key)) {
        totalSize += files[key].size;
      }
    }
    for (const key in AddMoreFile) {
      if (AddMoreFile.hasOwnProperty(key)) {
        totalSize += AddMoreFile[key].size;
      }
    }
    if (totalSize <= maxTotalSize) {
      status = true;
    }
    return status;
  }

  calculateTotalSizeValue(files) {
    this.AddMoreFileSize = 0;
    let totalSize = 0;
    for (const key in files) {
      if (files.hasOwnProperty(key)) {
        totalSize += files[key].size;
      }
    }
    this.AddMoreFileSize = totalSize;
    return this.convertBytesToReadable(totalSize);
  }

  AddcalculateTotalSizeValue(files) {
    if(this.AddMoreFileSize===undefined||this.AddMoreFileSize===null){
      this.AddMoreFileSize=0;
    }
    let totalSize = 0;
    for (const key in files) {
      if (files.hasOwnProperty(key)) {
        totalSize += files[key].size;
      }
    }
    this.AddMoreFileSize += totalSize;
    return this.convertBytesToReadable(this.AddMoreFileSize);
  }

  convertBytesToReadable(bytes) {
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    if (bytes == 0) return '0 Byte';
    if (isNaN(bytes)) return '0 Bytes';
    const i = Math.floor(Math.log(bytes) / Math.log(1024));
    return parseFloat((bytes / Math.pow(1024, i)).toFixed(2)) + ' ' + sizes[i];
  }

  deleteDocument(e) {
    Swal.fire({
      title: 'Are you sure you want to Delete?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#34c38f',
      cancelButtonColor: '#f46a6a',
      confirmButtonText: 'Yes, delete it!',
    }).then((result) => {
      if (result.value) {
        // Find index of document in arrSelectedDocuments
        const index = this.arrSelectedDocuments.indexOf(e);
        if (index !== -1) {
          // Remove document from arrSelectedDocuments
          this.arrSelectedDocuments.splice(index, 1);
          this.selectedFileCount--;

          // Find index of file in filesForUpload
          let indexToRemove = -1;
          // for (let i = 0; i < this.filesForUpload.length; i++) {
          //   if (this.filesForUpload[i].name === e.fileName + '.pdf') {
          //     indexToRemove = i;
          //     break;
          //   }
          // }
          for (let i = 0; i < this.newFilesForUpload.length; i++) {
            if (this.newFilesForUpload[i].name === e.fileName + '.pdf') {
              indexToRemove = i;
              break;
            }
          }

          if (indexToRemove > -1 && indexToRemove < this.newFilesForUpload.length) {
            this.newFilesForUpload.splice(indexToRemove, 1);
          }

          // Remove file from filesForUpload if found
          // if (indexToRemove !== -1) {
          //   const newFileList = new DataTransfer();
          //   for (let i = 0; i < this.filesForUpload.length; i++) {
          //     if (i !== indexToRemove) {
          //       newFileList.items.add(this.filesForUpload[i]);
          //     }
          //   }
          //   const updatedFiles: FileList = newFileList.files;
          //   this.filesForUpload = updatedFiles;
          // }


          // Remove file from newFilesForUpload if found
        //   if (indexToRemove !== -1) {
        //     const updatedFilesList = new DataTransfer();
        //     for (let i = 0; i < this.newFilesForUpload.length; i++) {
        //         if (i !== indexToRemove) {
        //             updatedFilesList.items.add(this.newFilesForUpload[i]);
        //         }
        //     }
        //     this.newFilesForUpload = updatedFilesList.files;

        // }


        }
      }
      if (this.arrSelectedDocuments.length === 0) {
        this.inboundUploadForm.patchValue({
          documentFile: null,
        });
      }
      this.totalSizeforTable = this.calculateTotalSizeValue(
        this.newFilesForUpload
      );
    });
  }

  resetInboundDocumentForm() {
    this.inboundUploadForm.reset({
      documentFile: null,
      txtComments: '',
      drpBranchType: null,
    });
    this.selectedFileUploadsData = null;
    this.showErrorForFileType = false;
    this.showErrorForFileLength = false;
    // this.showErrorBasedOnSize = false;
    this.showErrorBasedOnMaxSize = false;
    this.arrSelectedDocuments = [];
    this.newFilesForUpload = [];
    this.chkdisableButton = false;
    this.addDocumentbinModalTitle = 'Upload Inbound Documents';
    this.table.setSelectedDocumentList(this.arrSelectedDocuments);
    this.selectedFileCount = 0;
    this.disableMainFile = false;
    this.inboundUploadForm.markAsUntouched();
    this.inboundUploadForm.updateValueAndValidity();
  }

  mergeFileLists(newFiles) {
    for (let i = 0; i < newFiles.length; i++) {
      const file = newFiles[i];
      // Check if the file already exists in the array
      const exists = this.newFilesForUpload.some(
        (existingFile) =>
          existingFile.name === file.name && existingFile.size === file.size
      );
      if (!exists) {
        this.newFilesForUpload.push(file);
        // this.toastr.success(`File "${file.name}" Added.`, 'Success' );
      }
      // else {
      //   this.toastr.warning(`File "${file.name}" already exists.`, 'Warning' );
      // }
    }
  }

  addMoreFile(event: any,isDrop:boolean) {
    // this.runProgressBar();
    if(isDrop){
      this.files = event.dataTransfer.files;
      this.inboundUploadForm.value.documentFile=this.files;
    }else
    {
    this.files = event?.target?.files;
    }
    // event.value = '';
    if (this.calculateTotalSizeForMore(this.newFilesForUpload,this.files)) {
      // this.showErrorForFileType = false;
      // this.showErrorBasedOnMaxSize = false;
      // this.showErrorForFileLength = false;
      // this.arrSelectedDocuments = [];

      // this.filesForUpload = files;
      // this.mergeFileLists(files);
      this.chkdisableButton = true;
      this.notificationText=true;
      // this.selectedFileCount = this.newFilesForUpload.length;
      this.totalSizeforTable = this.AddcalculateTotalSizeValue(this.files);
      const totalFiles = this.files.length;
      let filesProcessed = 0;
      if (this.files && this.files.length > 0) {
        let fileResult: {
          fileName: string;
          fileType: string;
          fileSize: string;
          pageCount?: number;
        }[] = [];
        const fileList: File[] = [];
        const onCompleteProcessing = () => {
          filesProcessed++;
          this.filesProcessedV1++;
          Observable.timer(0,totalFiles)
          .takeWhile(()=>
             this.isWidthWithinLimit(filesProcessed,totalFiles)
             )
          .subscribe(()=>{
            this.isProcessBarShow = true;
            this.width=(this.filesProcessedV1/totalFiles)*100;
          })
          if (filesProcessed === totalFiles) {
            this.chkdisableButton = false;
            this.notificationText=false;
          }
        };
        for (let i = 0; i < this.files.length; i++) {
          if (this.files[i].type !== DocumentMaxandTypeLimits.documentTypeLimit) {
            this.showErrorForFileType = true;
            this.showErrorBasedOnMaxSize = false;
            this.showErrorForFileLength = false;
            this.arrSelectedDocuments = [];
            this.chkdisableButton = false;
            this.notificationText=false;
            this.selectedFileCount = 0;
            return;
          }

          // fileList.push(files[i]);
          // this.newFilesForUpload.push(files[i]);
          // this.mergeFileLists(files[i]);

          const exists = this.newFilesForUpload.some(
            (existingFile) =>
              existingFile.name === this.files[i].name && existingFile.size === this.files[i].size
          );
          if (!exists) {
            this.newFilesForUpload.push(this.files[i]);
            // this.toastr.success(`File "${files[i].name}" Added.`, 'Success' );
          }
          else {
            this.toastr.warning(`File "${this.files[i].name}" already exists.`, 'Warning' );
          }

          const fileName: string = this.files[i].name;
          const m1fileName: string = fileName.replace('.pdf', '');
          const m2fileName: string = m1fileName.replace('.pdf', '');

          // const getPDFPageCount = async (file: File) => {
          //   const pdfDoc = await PDFDocument.load(await file.arrayBuffer(),{ ignoreEncryption: true });
          //   // const pdfDoc = await PDFDocument.load(pdfBytes, { ignoreEncryption: true });
          //   const pages = pdfDoc.getPages();
          //   return pages.length;
          // };

          const getPDFPageCount = async (file: File) => {
            try {
              const pdfBytes = await file.arrayBuffer();
              const pdfDoc = await PDFDocument.load(pdfBytes, { ignoreEncryption: true }); // Add { ignoreEncryption: true }
              const pages = pdfDoc.getPages();
              return pages.length;
            } catch (error) {
              console.warn(error);
              return 0; // Return a default value or handle the error as per your requirement
            }
          };

          const pdfBytes =
          getPDFPageCount(this.files[i]).then((pageCount) => {
            // Check if a file with the same name and size exists in arrSelectedDocuments
            const fileExists = this.arrSelectedDocuments.some(
              (existingFile) =>
                existingFile.fileName === m2fileName &&
                existingFile.fileSize === this.convertBytesToReadable(this.files[i].size) &&
                existingFile.pageCount ===pageCount
            );

            // If the file doesn't already exist, push it into arrSelectedDocuments
            if (!fileExists) {
              this.arrSelectedDocuments.push({
                fileName: m2fileName,
                fileType: this.files[i].type === 'application/pdf' ? 'PDF' : 'N/A',
                fileSize: this.convertBytesToReadable(this.files[i].size),
                pageCount: pageCount,
              });
            }
            onCompleteProcessing();
          }).catch((error) => {
            // Handle any errors that occurred during loading the PDF document
            console.warn(error);
            onCompleteProcessing();
          });;
        }
        this.selectedFileCount = this.newFilesForUpload.length;
      }
    } else {
      // this.showErrorForFileType = false;
      // this.showErrorBasedOnMaxSize = true;
      // this.showErrorForFileLength = false;
      // // this.arrSelectedDocuments = [];
      // this.chkdisableButton = false;
      // // this.selectedFileCount = 0;
      this.toastr.warning('Last selected files size limit exceeded', 'Warning' );
      return;
    }
  }

  onFileChange(event: any): void {
    // this.runProgressBar();
    this.width=1;
    const files = event?.target?.files;

    if (files.length === 0) {
      this.showErrorForFileType = false;
      this.showErrorBasedOnMaxSize = false;
      this.showErrorForFileLength = false;
      this.arrSelectedDocuments = [];
      // this.filesForUpload = [];
      this.newFilesForUpload = [];
      this.chkdisableButton = false;
      this.notificationText=false;
      this.selectedFileCount = 0;
      return;
    }

    if (this.calculateTotalSize(files)) {
      this.showErrorForFileType = false;
      this.showErrorBasedOnMaxSize = false;
      this.showErrorForFileLength = false;
      this.arrSelectedDocuments = [];
      // this.filesForUpload = files;
      this.newFilesForUpload = [];
      this.mergeFileLists(files);
      this.chkdisableButton = true;
      this.notificationText=true;
      this.selectedFileCount = files.length;
      this.totalSizeforTable = this.calculateTotalSizeValue(files);
      const totalFiles = files.length;
      let filesProcessed = 0;
      if (files && files.length > 0) {
        let fileResult: {
          fileName: string;
          fileType: string;
          fileSize: string;
          pageCount?: number;
        }[] = [];
        const fileList: File[] = [];
        const onCompleteProcessing = () => {
          filesProcessed++;
          this.filesProcessedV1++;
          Observable.timer(0,totalFiles)
          .takeWhile(()=>
             this.isWidthWithinLimit(filesProcessed,totalFiles)
             )
          .subscribe(()=>{
            this.isProcessBarShow = true;
            this.width=(this.filesProcessedV1/totalFiles)*100;
          })
          if (filesProcessed === totalFiles) {
            this.chkdisableButton = false;
            this.notificationText=false;
          }
        };
        for (let i = 0; i < files.length; i++) {
          if (files[i].type !== DocumentMaxandTypeLimits.documentTypeLimit) {
            this.showErrorForFileType = true;
            this.showErrorBasedOnMaxSize = false;
            this.showErrorForFileLength = false;
            this.arrSelectedDocuments = [];
            this.chkdisableButton = false;
            this.notificationText=false;
            this.selectedFileCount = 0;
            return;
          }
          fileList.push(files[i]);
          const fileName: string = files[i].name;
          const m1fileName: string = fileName.replace('.pdf', '');
          const m2fileName: string = m1fileName.replace('.pdf', '');

          // const getPDFPageCount = async (file: File) => {
          //   const pdfDoc = await PDFDocument.load(await file.arrayBuffer());
          //   const pages = pdfDoc.getPages();
          //   return pages.length;
          // };

          const getPDFPageCount = async (file: File) => {
            try {
              const pdfBytes = await file.arrayBuffer();
              const pdfDoc = await PDFDocument.load(pdfBytes, { ignoreEncryption: true }); // Add { ignoreEncryption: true }
              const pages = pdfDoc.getPages();
              return pages.length;
            } catch (error) {
              console.warn(error);
              return 0; // Return a default value or handle the error as per your requirement
            }
          };

          getPDFPageCount(files[i]).then((pageCount) => {
            fileResult.push({
              fileName: m2fileName,
              fileType: files[i].type === 'application/pdf' ? 'PDF' : 'N/A',
              fileSize: this.convertBytesToReadable(files[i].size),
              pageCount: pageCount,
            });
            onCompleteProcessing();
          });
        }

        this.arrSelectedDocuments = fileResult;
        this.disableMainFile = true;
      }
      // event.target.value = null;
    } else {
      this.showErrorForFileType = false;
      this.showErrorBasedOnMaxSize = true;
      this.showErrorForFileLength = false;
      this.arrSelectedDocuments = [];
      this.chkdisableButton = false;
      this.notificationText=false;
      this.selectedFileCount = 0;
      // event.target.value = null;
      return;
    }
    // event.value = '';

  //   // Clone the file input element
  // const inputClone = event.target.cloneNode(true);
  // // Replace the original input element with the clone
  // event.target.parentNode.replaceChild(inputClone, event.target);
  // // Reset the original input element (to allow selecting the same file again)
  // inputClone.value = null;

  }

  // onFileChangeBk(event: any): void {
  //   const files: FileList = event?.target?.files;
  //   if (files.length === 0) {
  //     this.showErrorForFileType = false;
  //     this.showErrorBasedOnMaxSize = false;
  //     this.showErrorForFileLength = false;
  //     this.arrSelectedDocuments = [];
  //     // this.filesForUpload = []; // Enable Back
  //     this.chkdisableButton = false;
  //     this.selectedFileCount = 0;
  //     return;
  //   }
  //   if (this.calculateTotalSize(files)) {
  //     this.showErrorForFileType = false;
  //     this.showErrorBasedOnMaxSize = false;
  //     this.showErrorForFileLength = false;
  //     this.arrSelectedDocuments = [];
  //     // this.filesForUpload = files;
  //     this.mergeFileLists(files);
  //     this.chkdisableButton = true;
  //     this.selectedFileCount = files.length;
  //     this.totalSizeforTable = this.calculateTotalSizeValue(files);
  //     const totalFiles = files.length;
  //     let filesProcessed = 0;
  //     if (files && files.length > 0) {
  //       let fileResult: {
  //         fileName: string;
  //         fileType: string;
  //         fileSize: string;
  //         pageCount?: number;
  //       }[] = [];
  //       const fileList: File[] = [];
  //       const onCompleteProcessing = () => {
  //         filesProcessed++;
  //         if (filesProcessed === totalFiles) {
  //           this.chkdisableButton = false;
  //         }
  //       };
  //       for (let i = 0; i < files.length; i++) {
  //         if (files[i].type !== DocumentMaxandTypeLimits.documentTypeLimit) {
  //           this.showErrorForFileType = true;
  //           this.showErrorBasedOnMaxSize = false;
  //           this.showErrorForFileLength = false;
  //           this.arrSelectedDocuments = [];
  //           this.chkdisableButton = false;
  //           this.selectedFileCount = 0;
  //           return;
  //         }

  //         fileList.push(files[i]);
  //         const fileName: string = files[i].name;
  //         const m1fileName: string = fileName.replace('.pdf', '');
  //         const m2fileName: string = m1fileName.replace('.pdf', '');

  //         const getPDFPageCount = async (file: File) => {
  //           const pdfDoc = await PDFDocument.load(await file.arrayBuffer());
  //           const pages = pdfDoc.getPages();
  //           return pages.length;
  //         };

  //         getPDFPageCount(files[i]).then((pageCount) => {
  //           fileResult.push({
  //             fileName: m2fileName,
  //             fileType: files[i].type === 'application/pdf' ? 'PDF' : 'N/A',
  //             fileSize: this.convertBytesToReadable(files[i].size),
  //             pageCount: pageCount,
  //           });
  //           onCompleteProcessing();
  //         });
  //       }

  //       this.arrSelectedDocuments = fileResult;
  //     }
  //   } else {
  //     this.showErrorForFileType = false;
  //     this.showErrorBasedOnMaxSize = true;
  //     this.showErrorForFileLength = false;
  //     this.arrSelectedDocuments = [];
  //     this.chkdisableButton = false;
  //     this.selectedFileCount = 0;
  //     return;
  //   }
  // }
  savefuncation() {
    this.chkdisableButton = true;
    let file: File[];
    let branchId: string;
    let isStat: number;
    (file = this.newFilesForUpload), //filesForUpload;
      (branchId = this.inboundUploadForm.value?.drpBranchType);
    isStat = this.inboundUploadForm.value.chkStat === true ? 1 : 0;

      // .createManualUploadV1ByFileAndBranchIdAndIsStatV3(file, branchId, isStat)
    this.inboundDocUploadService
      .createManualUploadV1ByFileAndBranchIdAndIsStatV3(file, branchId, isStat)
      .subscribe(
        (response) => {
        this.resetInboundDocumentForm();
      //  this.toastr.success('Fax Manual uploaded has been successfully completed','success');
          this.toastr.success(
            'Total Files: <span>' + response.totalFile + '</span><br>' +
           '<span> Uploaded: '+ response.uploaded + '/' + response.totalFile + '</span><br>'+
           'Files Uploaded Successfully',
            'Success',
            { "closeButton": true, "timeOut": 5000, "extendedTimeOut": 2000,enableHtml: true  }
        );
          this.dialog.closeAll();
          this.communicationService.triggerFunctionCall(this.data.selectedNode);
          this.chkdisableButton = false;
        },
        (err) => {
          const data: HttpErrorResponse = err;
          this.dialog.closeAll();
          Swal.fire({
            icon: 'info',
            text: data?.error?.error?.message,
          });
          this.chkdisableButton = false;
          this.communicationService.triggerFunctionCall(this.data.selectedNode);
        }
      );
  }


  loadDropdown() {
    const getBranchList = this.branchService
      .getBranchList(this.tenantId)
      .subscribe((response) => {
        this.lstBranch = response;
        this.filteredBranchTypeList = [...this.lstBranch];
        this.inboundUploadForm.patchValue({
          drpBranchType: this.data.selectedNode?.branchId || null,
        });
      });
  }
  onDragOver(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
    // this.highlightDropArea(true);
  }

  onDragLeave(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
    // this.highlightDropArea(false);
  }
  // runProgressBar(){
  //   Observable.timer(0,100)
  //   .takeWhile(()=>
  //      this.isWidthWithinLimit()
  //      )
  //   .subscribe(()=>{
  //     this.width=this.width+1;
  //     // console.log(this.width);
  //   })

  // }

  isWidthWithinLimit(filesProcessed,totalFiles){
    if(filesProcessed===totalFiles){
      this.isProcessBarShow = false;
      this.filesProcessedV1=1;
      this.width=1;
      return false;
    }else{
      this.isProcessBarShow = true;
      return true;
    }
  }
}

