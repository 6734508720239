import { error } from './../../../../admin/src/app/official/official.component';
import { defaultGuid } from './../../../../shared/src/app/enums/allenums.enum';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { Title } from '@angular/platform-browser';
import { ToastrService } from 'ngx-toastr';
import { noWhitespaceValidator } from 'projects/patient/src/app/add-note-tab/add-note-tab.component';
import { CreateUpdateInsuranceCategoryDTO } from 'projects/patient/src/app/patient-proxy/patient-optimization/dto';
import { InsuranceCategoryService } from 'projects/patient/src/app/patient-proxy/patient-optimization/insurance-category.service';
import { Subscription } from 'rxjs';
import Swal from 'sweetalert2';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DataTableDirective } from 'angular-datatables';
import { CommunicationService } from 'projects/shared/src/app/services/communication.service';
import { DatePipe } from '@angular/common';
@Component({
  selector: 'app-master-mminsurance-table',
  templateUrl: './master-mminsurance-table.component.html',
  styleUrls: ['./master-mminsurance-table.component.scss']
})
export class MasterMminsuranceTableComponent implements OnInit {

  @Input() tableData: any;
  insuranceDetailsData: any;
  step: boolean = false;
  InsCategoryId = defaultGuid;
  defaultGuid=defaultGuid;
  isLoading: boolean = false;
  isSaveButton:boolean =false;
  insuranceDetailsForm: FormGroup;
  subscription$: Subscription[] = [];
  @ViewChild(DataTableDirective, { static: false })
  datatableElement: DataTableDirective;
  dtFrequentItemTableOptions = {
    responsive: true,
    paging: false,
    dom: 'Bfrtip',
    language: {
      emptyTable: 'No records',
      info: 'Total : _MAX_ records',
      infoEmpty: 'Total : _MAX_ records',
      infoFiltered: '(filtered : _TOTAL_ records)',
      loadingRecords: 'Loading...',
      zeroRecords: 'No matching records',
    },
    scrollCollapse: true,
    scrollY: '400px',
    scrollX: true,
    fixedHeader: true,
    columnDefs: [
      { targets: [0], orderable: false },
      { targets: [3,4,5,6,7,8,10,11,12,13,14,15,16,18,20], visible: false },

    ],
    search: {
      smart: false,
    },
    buttons: [
      {
        extend: 'excel',
        text: 'Export',
        filename: 'MM Insurance Group',
        exportOptions: {
          columns: ':visible:not(:first-child,:eq(0)) ',
        },
      },
      {
        extend: 'colvis',
        columns: ':not(:eq(0), :eq(1))',
      }
    ],
  };

  constructor(
    private formBuilder: FormBuilder,
    private insuranceCategoryService: InsuranceCategoryService,
    private toastr: ToastrService,
    public title: Title,
    private snackBar: MatSnackBar,
    private communicationService: CommunicationService,
    private datepipe: DatePipe,
  ) { }

  ngOnInit(): void {
    this.insuranceDetailsData = this.tableData;
    this.initializeForms();

    this.dtFrequentItemTableOptions.buttons[0].filename =
    'MM Insurance Group' + '-' + this.datepipe.transform(new Date(), 'MM-dd-yyyy');
  }
  onKeyPress(event: KeyboardEvent) {
    const isNumber = /[0-9]/.test(event.key);
    if (!isNumber) {
      event.preventDefault(); // Prevent the input if the key is not a number
    }
  }
  onKeyPressV1(event: KeyboardEvent) {
    const isNumber = /[0-9.]/.test(event.key);
    if (!isNumber) {
      event.preventDefault(); // Prevent the input if the key is not a number
    }
  }
  numbersAndfloatOnly(event: any) {
    const allowedChars = '0123456789.';
    const inputChar = event.key;

    if (event.key === '.' && event.currentTarget.value.includes('.')) {
      // Allow only one decimal point
      event.preventDefault();
    } else if (allowedChars.indexOf(inputChar) === -1) {
      // Prevent input of characters other than numbers and dot
      event.preventDefault();
    }
  }

  onPaste(event: ClipboardEvent) {

    const clipboardData = event.clipboardData;
    const pastedData = clipboardData.getData('text');
    const isNumber = /^\d+$/.test(pastedData); //Should contain only whole numbers

    if (!isNumber) {
      event.preventDefault(); // Prevent the input if the key is not a number
    }
  }
    // //After View Method
    ngAfterViewInit(): void {
      this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
        dtInstance.columns().every(function () {
          const that = this;
          $('input', this.footer()).on('keyup change', function () {
            if (that.search() !== this['value']) {
              that.search(this['value']).draw();
            }
          });
        });
      });
    }

  initializeForms() {
    this.insuranceDetailsForm = this.formBuilder.group({
      txtname: new FormControl('', [Validators.required, noWhitespaceValidator]),
      maskCallIncrement: new FormControl('', [Validators.required]),
      suppliesCallIncrement: new FormControl('', [Validators.required]),
      orderMachinePoints: new FormControl('', [Validators.required]),
      disabled: new FormControl(false),
      defaultDeduct: new FormControl('', [Validators.required]),
      precertRequired: new FormControl(false),
      hideAllowed: new FormControl(false),
      requriedFunctionality: new FormControl(false),
      NoallowedAmount: new FormControl(false),
      requiresPreAuth: new FormControl(false),
      isMedicare: new FormControl(false),
      complianceMachine: new FormControl('', [Validators.required]),
      requriedMachineCompliance: new FormControl(false),
      assignPatient: new FormControl(false),
      electronicVerification: new FormControl(false),
      docChecklistRequired: new FormControl(false),
      autoReminder: new FormControl(false),
    });
  }

  reset() {
    this.insuranceDetailsForm.patchValue({
      txtname: '',
      maskCallIncrement: '',
      suppliesCallIncrement: '',
      orderMachinePoints: '',
      disabled: false,
      defaultDeduct: '',
      precertRequired: false,
      hideAllowed: false,
      requriedFunctionality: false,
      NoallowedAmount: false,
      requiresPreAuth: false,
      isMedicare: false,
      complianceMachine: '',
      requriedMachineCompliance: false,
      assignPatient: false,
      electronicVerification: false,
      docChecklistRequired: false,
      autoReminder: false,
    });
    this.InsCategoryId = defaultGuid;
    this.insuranceDetailsForm.reset();
    this.insuranceDetailsForm.markAsUntouched();
    this.insuranceDetailsForm.updateValueAndValidity();
    this.initializeForms();
  }

  saveInsCategory(){
    this.isSaveButton=true;
    const InsForm =this.insuranceDetailsForm.value;
    let InsCateg: CreateUpdateInsuranceCategoryDTO = {
        insuranceCategory:InsForm.txtname,
        orderMachinePoints: InsForm.orderMachinePoints,
        disabled:InsForm.disabled,
        defaultDeductible: InsForm.defaultDeduct,
        preCertRequired: InsForm.precertRequired,
        hideAllowed: InsForm.hideAllowed,
        maskCallIncrement: InsForm.maskCallIncrement,
        suppliesCallIncrement: InsForm.suppliesCallIncrement,
        requiresFunctionalityQuestions: InsForm.requriedFunctionality,
        noAllowedAmount: InsForm.NoallowedAmount,
        requiresPreAuthorization: InsForm.requiresPreAuth,
        isMedicare: InsForm.isMedicare,
        complianceMachinePoints: InsForm.complianceMachine,
        requiresMachineCompliance: InsForm.requriedMachineCompliance,
        assignPatientsToCustomerService:InsForm.assignPatient,
        electronicVerification:InsForm.electronicVerification,
        docCheckListRequired: InsForm.docChecklistRequired,
        autoreminder: InsForm.autoReminder,
    };
    if(this.InsCategoryId == null || this.InsCategoryId == "" || this.InsCategoryId == undefined ||this.InsCategoryId==defaultGuid) {
      this.insuranceCategoryService.create(InsCateg).subscribe(response => {
        this.toastr.success('Saved Successfully','Success')
        this.isSaveButton=false;
        this.toggleV2();
        this.reset();
        this.communicationService.triggerMastermmInsuranceGetMethodCall();
        // this.statusFilter();--Replace
      }, err => {
        this.isSaveButton=false;
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      });
    } else {
      this.insuranceCategoryService.update(this.InsCategoryId, InsCateg).subscribe(response => {
        this.toastr.success('Updated Successfully','Success')
        this.isSaveButton=false;
        this.reset();
        this.communicationService.triggerMastermmInsuranceGetMethodCall();
       // this.statusFilter();--Replace
        this.toggleV2();
      }, err => {
        this.isSaveButton=false;
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      });
    }
  }

  InsCategGetId(id:string){
    window.scrollTo({ top: 0, behavior: 'smooth' });
      this.step=true;
    const getById = this.insuranceCategoryService.get(id).subscribe(response => {
    this.insuranceDetailsForm.patchValue ({
      txtname: response.insuranceCategory,
      maskCallIncrement:response.maskCallIncrement,
      suppliesCallIncrement:response.suppliesCallIncrement,
      orderMachinePoints:response.orderMachinePoints,
      disabled:response.disabled,
      defaultDeduct:response.defaultDeductible,
      precertRequired:response.preCertRequired,
      hideAllowed:response.hideAllowed,
      requriedFunctionality:response.requiresFunctionalityQuestions,
      NoallowedAmount:response.noAllowedAmount,
      requiresPreAuth:response.requiresPreAuthorization,
      isMedicare:response.isMedicare,
      complianceMachine:response.complianceMachinePoints,
      requriedMachineCompliance:response.requiresMachineCompliance,
      assignPatient:response.assignPatientsToCustomerService,
      electronicVerification:response.electronicVerification,
      docChecklistRequired:response.docCheckListRequired,
      autoReminder:response.autoreminder,
    });
    this.InsCategoryId=response.id;
  });
  }
  statusChange(event: MatSlideToggleChange,id:string) {
    if (event.checked == true) {
      Swal.fire({
        title: 'Do you want to activate?',
        text: 'By Changing Status, this Insurance will be activated',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#34c38f',
        cancelButtonColor: '#f46a6a',
        confirmButtonText: 'Yes, Activate it!'
      }).then(result => {
        if (result.value) {
          if (id && id !== "" && id !== null && id !== undefined) {
            this.insuranceCategoryService.enableDisableInsuranceCatogoryById(id).subscribe(response => {
              this.toastr.success('Activated Successfully', 'Success');
              this.communicationService.triggerMastermmInsuranceGetMethodCall();
             // this.statusFilter();--Replace
            }, err => {
              if(err?.error.text==='Updated Successfully'){
                this.toastr.success('Activated Successfully', 'Success');
                this.communicationService.triggerMastermmInsuranceGetMethodCall();
              }else{
              event.source.checked = false;
              // this.toastr.success('Activated Successfully', 'Success')
              const data: HttpErrorResponse = err?.error;
              Swal.fire({
                icon: 'info',
                text: data?.error?.message,
              });
            }
            });
          }
        }
        else {
          event.source.checked = false;
        }
      });
    }
    else if (event.checked == false) {
        Swal.fire({
          title: 'Do you want to deactivate?',
          text: 'By Changing Status, this Insurance will be deactivated',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#34c38f',
          cancelButtonColor: '#f46a6a',
          confirmButtonText: 'Yes, deactivate it!'
        }).then(result => {
          if (result.value) {
            if (id && id !== "" && id !== null && id !== undefined) {
              this.insuranceCategoryService.enableDisableInsuranceCatogoryById(id).subscribe(response => {
                this.toastr.success('Deactivated Successfully', 'Success');
                this.communicationService.triggerMastermmInsuranceGetMethodCall();
                // this.statusFilter();--Replace
              }, err => {
                const data: HttpErrorResponse = err?.error;
                if(err?.error.text==='Updated Successfully'){
                  this.toastr.success('Deactivated Successfully', 'Success');
                  this.communicationService.triggerMastermmInsuranceGetMethodCall();
                }else{
                event.source.checked = true;
                Swal.fire({
                  icon: 'info',
                  text: data?.error?.message,
                });
              }
                // this.toastr.success('Deactivated Successfully', 'Success')
              });

            }

          }
          else {
            event.source.checked = true;
          }
        });
      }
  }

  toggle() {
    this.step = true;
  }
  toggleV2() {
    this.step = false;
  }

    //Copy Text While Duble on table Row
    onRowDoubleClickV1(textToCopy) {
      this.copyTextToClipboard(textToCopy);
      this.showNotification('Text copied: ' + textToCopy);
    }

    showNotification(message: string): void {
      this.snackBar.open(message, 'Close', {
        duration: 3000, // Duration in milliseconds
        horizontalPosition: 'end', // Positioning the notification horizontally
        verticalPosition: 'bottom', // Positioning the notification vertically
      });
    }

    copyTextToClipboard(text: string): void {
      const textArea = document.createElement('textarea');
      textArea.value = text;
      document.body.appendChild(textArea);
      textArea.select();
      document.execCommand('Copy');
      textArea.remove();
    }
}
