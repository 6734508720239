<!-- <button mat-raised-button (click)="test()">Test</button> -->
<div>
  <table
    datatable
    class="table row-border hover w-100 display"
    [dtOptions]="requisitionItemsListOptions"
  >
    <thead>
      <tr>
        <th><mat-icon>apps</mat-icon></th>
        <th [matTooltip]="'Product Code'">Product Code</th>
        <th [matTooltip]="'Description'">Description</th>
        <th [matTooltip]="'Location Bin'">Location Bin</th>
        <th [matTooltip]="'Serial Lot No'" class="text-right custom-width">
          <span class="asterisk">*</span> Serial Lot No
        </th>
        <th [matTooltip]="'Requested Qty'" class="text-right">Requested Qty</th>
        <th [matTooltip]="'Balance Qty'" class="text-right">Balance Qty</th>
        <th [matTooltip]="'Qty Received'" class="text-right custom-width">Qty Received</th>
        <th [matTooltip]="'Tracking Number'" class="text-right custom-width"><span class="asterisk">*</span>
          Tracking Number
        </th>
        <th [matTooltip]="'Unit Cost ($)'" class="text-right">Unit Cost ($)</th>
        <th [matTooltip]="'Shipment Comment'">Shipment Comment</th>
        <th [matTooltip]="'Back Ordered Since'">Back Ordered Since</th>
        <th [matTooltip]="'Processed By'">Processed By</th>
        <th [matTooltip]="'Processed Date'">Processed Date</th>
      </tr>
    </thead>
    <tbody>
      <tr
        [ngClass]="data.receivedQuantity > 0 ? 'bg-lightseagreen' : ''"
        *ngFor="let data of requisitionItemsList; let i = index"
      >
        <!-- Options -->
        <td>
          <ng-container
            *ngIf="
              reqStatusShortCodeId === reqStatusShortCodeIds.orderedDirect &&
              data.receivedQuantity <= 0
            "
          >

            <a  [class.disabled]="isUpdate" class="editBtnColor" (click)="toggleEdit(i, data)" *ngIf="editableRowIndex !== i">
              <i class="fa fa-pencil editBtn mr-1" matTooltip="Click To Edit"></i>
            </a>

          <mat-icon class="icon-success pointer" matTooltip="Click To Update"  *ngIf="editableRowIndex === i"
            [class.disabled]="isSaveButtonEnabledV2(data.requestItemId)||receivedQtyNumber ===0||
            data.txtReceivedQty==='' || isUpdate" (click)="toggleEdit(i, data)">check_circle</mat-icon>

          <mat-icon class="icon-danger pointer" (click)="cancel()" mat-icon-button *ngIf="editableRowIndex === i">clear</mat-icon>
          </ng-container>
          <ng-container *ngIf="data.receivedQuantity > 0"
            ><p class="text-danger blinking-text dd-text pt-2">
              Completed
            </p></ng-container
          >
        </td>
        <!-- Product Code -->
        <td [matTooltip]="data?.productCode || '-'">{{ data.productCode || '-'}}</td>
        <!-- Description -->
        <td [matTooltip]="data?.productDescription || '-'">
          {{ data.productDescription || '-'}}
        </td>
        <!-- Bin Location -->
        <td [matTooltip]="data?.binLocation || '-'">
          {{ data.binLocation || '-'}}
        </td>
        <!-- Serial Lot Number-->
        <td class=""
          [matTooltip]="data?.serialLotNumber || '-'"
          *ngIf="editableRowIndex !== i; else editSerialNo"
        >
          {{ data.serialLotNumber || '-'}}
        </td>
        <ng-template #editSerialNo >
          <td [matTooltip]="data?.serialLotNumber || '-'" class="custom-width">
            <input
              type="text"
              #inputSerialLotNumber="ngModel"
              maxlength="20"
              [(ngModel)]="data.txtSerialNo"
              onkeypress="return (event.charCode >= 65 && event.charCode <= 90)
              || (event.charCode >= 97 && event.charCode <= 122) || (event.charCode >= 48 && event.charCode <= 57)"
              class="textbox-border-class dd-text"
            />
          </td>
        </ng-template>
        <!-- Received Quantity -->
        <td [matTooltip]="data?.thisOrder || '-'" class="">
          {{ data.thisOrder || '-'}}
        </td>
        <!-- Balance Quantity -->
        <td [matTooltip]="data?.balanceQuantity || '0'" class="">
          {{ data.balanceQuantity || '0'}}
        </td>
        <td  class="custom-width"
          [matTooltip]="data?.receivedQuantity || '1'"
          *ngIf="editableRowIndex !== i; else editReceivedQty"
        >
          {{ data.receivedQuantity===0?1:data.receivedQuantity }}
        </td>
        <ng-template #editReceivedQty>
          <td [matTooltip]="data?.txtReceivedQty || '1'" class="custom-width">
            <input
              #inputReceivedQty="ngModel"
              maxlength="10"
              type="text"
              (keypress)="onKeyPress($event)"
              [(ngModel)]="data.txtReceivedQty"
              class="textbox-border-class dd-text"
            />
            <div
              class="text-danger dd-text text-error colErrorMsg"
              *ngIf="inputReceivedQty.value > data.balanceQuantity"
            >
              Received Quantity will not be greater than Balance Quantity
            </div>
            <div
              class="text-danger dd-text text-error colErrorMsg"
              *ngIf="receivedQtyNumber ===0"
            >
            Received Quantity Need to more than zero
            </div>
          </td>
        </ng-template>
        <!-- Tracking Number -->
        <td
        class="custom-width"
        *ngIf="editableRowIndex !== i; else editTrackingNumber"
      >

      <!-- href="https://tools.usps.com/go/TrackConfirmAction_input?strOrigTrackNum={{data.tracking}}" -->
      <a
      href="https://www.ups.com/track?loc=en_US&requester=QUIC&tracknum={{data.tracking}}/"
      target="_blank" class="preview-link"
      [matTooltip]="'Click here to Track'">
          {{
            data.tracking
          }}
        </a>
      </td>
        <ng-template #editTrackingNumber>
          <td class="">
            <input
              type="text"
              maxlength="20"
              [(ngModel)]="data.txtTrackingNumber"
              #inputTrackingNumber
              class="textbox-border-class dd-text"
            />
          </td>
        </ng-template>
        <!-- Unit Cost -->
        <td [matTooltip]="data?.unitCost || '0'">
          {{ data.unitCost || '0'}}
        </td>
        <!-- Shipment Comment -->
        <td [matTooltip]="data?.shipmentComment || '-'">
          {{ data.shipmentComment || '-'}}
        </td>
        <!-- BackOrdered Since -->
        <td
          [matTooltip]="
            data?.backOrderSince
              ? (data?.backOrderSince | date : 'MM/dd/yyyy' : 'en_US')
              : ''
          "
        >
          <ng-container *ngIf="data?.backOrderSince != '0001-01-01T00:00:00Z'">
            {{ data?.backOrderSince | date : "MM/dd/yyyy" : "en_US" }}
          </ng-container>
        </td>
        <!-- Processed By -->
        <td [matTooltip]="data?.processedBy || '-'">{{ data.processedBy || '-'}}</td>
        <!-- Processed Date-->

        <td class="text-center">
          <span class="modifiedDate" [matTooltip]="data?.processedDate || '-'">{{
            data?.processedDate || '-'}}</span>
        </td>
      </tr>
    </tbody>

    <!-- Footer -->
    <!-- <tfoot>
      <tr>
        <th class="text-center">#</th>

        <th class="text-center">
          <input
            matInput
            class="textbox-border-class"
            type="text"
            placeholder="Product Code"
            name="search-productCode"
          />
        </th>
        <th class="text-center">
          <input
            matInput
            type="text"
            class="textbox-border-class"
            placeholder="Description"
            name="search-description"
          />
        </th>
        <th class="text-center">
          <input
            matInput
            type="text"
            class="textbox-border-class"
            placeholder="Location Bin"
            name="search-locationBin"
          />
        </th>
        <th class="text-center">
          <input
            matInput
            type="text"
            class="textbox-border-class"
            placeholder="Serial/Lot No"
            name="search-serialLotNo"
          />
        </th>
        <th class="text-center">
          <input
            matInput
            class="textbox-border-class"
            type="text"
            placeholder="Requested Quantity"
            name="search-requestedQuantity"
          />
        </th>
        <th class="text-center">
          <input
            matInput
            class="textbox-border-class"
            type="text"
            placeholder="Balance Quantity"
            name="search-balanceQuantity"
          />
        </th>
        <th class="text-center">
          <input
            matInput
            class="textbox-border-class"
            type="text"
            placeholder="Received Quantity"
            name="search-receivedQuantity"
          />
        </th>
        <th class="text-center">
          <input
            matInput
            class="textbox-border-class"
            type="text"
            placeholder="Tracking Number"
            name="search-trackingNumber"
          />
        </th>
        <th class="text-center">
          <input
            matInput
            class="textbox-border-class"
            type="text"
            placeholder="Unit Cost"
            name="search-unitCost"
          />
        </th>
        <th class="text-center">
          <input
            matInput
            class="textbox-border-class"
            type="text"
            placeholder="Shipment Comment"
            name="search-shipmentComment"
          />
        </th>
        <th class="text-center">
          <input
            matInput
            class="textbox-border-class"
            type="text"
            placeholder="Back Ordered Since"
            name="search-backOrderedSince"
          />
        </th>

        <th class="text-center">
          <input
            matInput
            class="textbox-border-class"
            type="text"
            placeholder="Processed By"
            name="search-processedBy"
          />
        </th>
        <th class="text-center">
          <input
            matInput
            class="textbox-border-class"
            type="text"
            placeholder="Processed Date"
            name="search-processedDate"
          />
        </th>
      </tr>
    </tfoot> -->
  </table>
</div>
