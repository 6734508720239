<div class="container-fluid">

    <div class="row bg">
      <div class="col-12">
        <div class="d-flex align-items-center justify-content-between">
          <h3 class="font-weight-bolder mb-0">
            <b class="header-class">Inbound</b>
          </h3>
          
          <div class="page-title-box page-title-right">
            <ol class=" breadcrumb m-0" style="background-color:white !important;">
              <li>
                <div class="row mr-4 pt-4" style="display: flex; justify-content: flex-end;">
                  
                  <mat-radio-group (change)="onRadioChange($event)">
                    <mat-radio-button class="active" value="{{InboundSortedfilter.All}}"><b>{{inboundSortedfilterText[0]}}</b></mat-radio-button>
                    <mat-radio-button class="active p-2" value="{{InboundSortedfilter.Sorted}}"><b>{{inboundSortedfilterText[1]}}</b></mat-radio-button>
                    <mat-radio-button class="active"  [checked]="true"  value="{{InboundSortedfilter.Unsorted}}"><b>{{inboundSortedfilterText[2]}}</b></mat-radio-button>
                  </mat-radio-group>
                </div>
              </li>

              <li>
                <form [formGroup]="inboundForm">
                  <mat-form-field class="mr-4 pt-4 dd-text">
                    <mat-label>Uploaded Date Range</mat-label>
                    <mat-date-range-input [rangePicker]="picker">
                      <input
                        matStartDate
                        matInput
                        placeholder="Start date"
                        formControlName="startDate"
                        [max]="maxDate"
                      />
                
                      <input
                        matEndDate
                        matInput
                        placeholder="End date"
                        formControlName="endDate"
                        [max]="maxDate"
                        (dateChange)="onDateRangeChange()"
                      />
                    </mat-date-range-input>
                    <mat-datepicker-toggle
                      matSuffix
                      [for]="picker"
                    ></mat-datepicker-toggle>
                    <mat-date-range-picker #picker></mat-date-range-picker>
                  </mat-form-field>
                </form>
                
                
              </li>

              <li >
                <a [routerLink]="'/patient-list'" class="preview-link">
                  <!-- target="_blank" -->
                  <button matTooltip="Click Go To Patient List" mat-button class="buttonColor font-size-10 mt-4 ">
                    Back To Patient List
                  </button>
                </a>
              </li>


              <li *ngIf="isAllowDoSorting">
                <a [routerLink]="'/batch_sorting'" class="preview-link">
                  <!-- target="_blank" -->
                  <button matTooltip="Click Go To Do Sorting" mat-button class="buttonColor font-size-10 mt-4 ml-2">
                    <img class="img-responsive mb-1 mr-1" height="20" src="../assets/patient_list_icons/Do_Sorting.png" /> Do Sorting
                  </button>
                </a>
              </li>
  
            </ol>
          </div>

        </div>
      </div>
    </div>
  
    <div class="card card-body mt-3 p-2">
      <div class="row" *ngIf="!isLoading">
        <div class="col-12">
          <div *ngIf="!isLoading" class="table table-responsive">
            <app-inbound-sorting-pending-table [tableData]="inboundSortingPendingListDataArray" (enableDoSortingBtn)="getLockedCount()" (reloadTable)="getTableData()">
            </app-inbound-sorting-pending-table>
          </div>
        </div>
      </div>
      <div class="row" *ngIf="isLoading">
        <div class="col-12">
          <div class="d-flex justify-content-center" *ngIf="isLoading">
            <img class="img-responsive pb-2 mr-1" src="assets/gifs/Infinity-loader.gif" />
          </div>
        </div>
      </div>
    </div>
  </div>