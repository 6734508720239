import { DatePipe } from '@angular/common';
import {
  Component,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatDatepicker } from '@angular/material/datepicker';
import { MmOrderService } from '../order-proxy/order-management/order-optimization/mm-order.service';
import { Subscription } from 'rxjs';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { CommunicationService } from 'projects/shared/src/app/services/communication.service';
import { Title } from '@angular/platform-browser';
import { CommonService } from 'projects/shared/src/app/services/common.service';
import { LoaderService } from 'projects/shared/src/app/services/loader-service';
import { defaultGuid } from 'projects/shared/src/app/enums/allenums.enum';
import { PagedAndSortedResultRequestDto } from '@abp/ng.core';
import { MmOrderReturnExchangeItemConditionService } from '../order-proxy/order-management/order-optimization/mm-order-return-exchange-item-condition.service';
import { MmOrderItemReturnService } from '../order-proxy/order-management/order-optimization/mm-order-item-return.service';
import { clsPickupOrderReturn } from '../order-proxy/order-management/order-optimization/dto';
import { defaultTablePageOffset } from 'projects/shared/src/app/data/common-data';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-exchange-list',
  templateUrl: './exchange-list.component.html',
  styleUrls: ['./exchange-list.component.scss'],
})
export class ExchangeListComponent implements OnDestroy, OnInit {
  frmExchangeListForm: FormGroup;
  ltExchangeOrders: any = [];
  ltExchangeOrderItems: any = [];
  ltNewOrderItems: any = [];
  loadExchangeTable: boolean = true;
  paginationInfo: any;
  currentPage: any = 1;
  totalPages: any = 1;
  pageOffset: number = defaultTablePageOffset;
  fromDate: any;
  toDate: any;
  exchangeOrderId: number;
  newOrderId: number;
  pageLoading: boolean = false;
  subscription$: Subscription[] = [];
  @ViewChild('picker') picker: MatDatepicker<Date>;
  @ViewChild('exchangeItemsDialog')
  exchangeItemsDialogTemplate!: TemplateRef<any>;
  dialogRef: MatDialogRef<any> | null = null;
  dtExchangeOrderTableOptions: any = {
    responsive: true,
    dom: 'Bfrtip',
    search: {
      smart: false, // Set to "none" for full exact match
    },
    language: {
      emptyTable: 'No records',
      info: 'Total : _MAX_ records',
      infoEmpty: 'Total : _MAX_ records',
      infoFiltered: '(filtered : _TOTAL_ records)',
      loadingRecords: 'Loading...',
      zeroRecords: 'No matching records',
    },
    paging: false,
    scrollCollapse: true,
    scrollY: '60vh',
    scrollX: true,
    buttons: [],
  };
  dtNewOrderTableOptions: any = {
    responsive: true,
    dom: 'Bfrtip',
    search: {
      smart: false, // Set to "none" for full exact match
    },
    language: {
      emptyTable: 'No records',
      info: 'Total : _MAX_ records',
      infoEmpty: 'Total : _MAX_ records',
      infoFiltered: '(filtered : _TOTAL_ records)',
      loadingRecords: 'Loading...',
      zeroRecords: 'No matching records',
    },
    paging: false,
    scrollCollapse: true,
    scrollY: '60vh',
    scrollX: true,
    // info: true,
    buttons: [],
  };
  // @ViewChild('myDialog') myDialogTemplate!: TemplateRef<any>;
  // dialogRef: MatDialogRef<any> | null = null;
  isPickup: boolean = false;
  constructor(
    private fb: FormBuilder,
    private datepipe: DatePipe,
    private mmOrderService: MmOrderService,
    private dialog: MatDialog,
    private communicationService: CommunicationService,
    private commonService: CommonService,
    private title: Title,
    private loaderService: LoaderService,
    private returnExchangeConditionService: MmOrderReturnExchangeItemConditionService,
    private mmOrderItemReturnService: MmOrderItemReturnService,
    private toastr: ToastrService
  ) {
    const comService =
      this.communicationService.exchangeItemsListMethodCall$.subscribe(
        (orderIds) => {
          this.getExchangeItemsList(
            orderIds.originalOrderId,
            orderIds.newOrderId,
            orderIds.isPickup,
            orderIds.originalOrderNo,
            orderIds.newOrderNo
          );
          this.isPickup = orderIds?.isPickup ?? false;
        }
      );
    this.subscription$.push(comService);

    const loader = this.loaderService
      .getLoaderState()
      .subscribe((isOrderPageLoading) => {
        this.pageLoading = isOrderPageLoading;
      });

    this.subscription$.push(loader);
  }

  ngOnInit() {
    this.initializeForms();
    this.loadDropdowns();
    this.title.setTitle('Exchange List | Qsecure');
    const { firstDate, lastDate } = this.getFirstAndLastDatesOfCurrentMonth();

    this.frmExchangeListForm.patchValue({
      txtDateValue: firstDate,
      txtDate: lastDate,
    });
    this.onDateRangeChange();
  }
  ngOnDestroy(): void {
    this.subscription$.forEach((sub) => {
      sub && sub?.unsubscribe();
    });
  }

  initializeForms() {
    this.frmExchangeListForm = this.fb.group({
      txtDateValue: new FormControl(''),
      txtDate: new FormControl(''),
    });
  }

  onDateRangeChange() {
    const startDate = this.frmExchangeListForm.get('txtDateValue').value;
    const endDate = this.frmExchangeListForm.get('txtDate').value;
    this.fromDate = this.datepipe.transform(startDate, 'MM/dd/yyyy');
    this.toDate = this.datepipe.transform(endDate, 'MM/dd/yyyy');
    if (startDate != null && endDate != null) {
      this.getExchangeOrdersList();
    }
  }

  // Function to get the first and last dates of the current month
  getFirstAndLastDatesOfCurrentMonth(): { firstDate: Date; lastDate: Date } {
    const today = new Date();
    const firstDate = new Date(today.getFullYear(), today.getMonth(), 1);
    const lastDate = new Date(today.getFullYear(), today.getMonth() + 1, 0);
    return { firstDate, lastDate };
  }
  //To get the Exchange orders List
  getExchangeOrdersList() {
    this.loadExchangeTable = false;
    const exchangeItems = this.mmOrderService
      .getExchangeItemsListV1ByDtFromDateAndDtToDateAndPageNoAndPageOffset(
        this.fromDate,
        this.toDate,
        this.currentPage,
        this.pageOffset
      )
      .subscribe(
        (response) => {
          console.log(response);
          this.ltExchangeOrders = response?.items ?? [];

          this.totalPages =
            Math.ceil(response?.totalCount / this.pageOffset) == 0
              ? 1
              : Math.ceil(response?.totalCount / this.pageOffset);

          this.paginationInfo = {
            startRecord: (this.currentPage - 1) * this.pageOffset + 1,
            endRecord: Math.min(
              this.currentPage * this.pageOffset,
              response?.totalCount ?? 0
            ),
            overallTableCount: response?.totalCount ?? 0,
          };

          console.log(this.ltExchangeOrders);
          this.ltExchangeOrders = this.ltExchangeOrders.map((order) => {
            return {
              ...order,
              originalOrderDate: this.commonService.getFormattedDateZone(
                order.originalOrderDate
              ),
              originalOrderDOS: this.commonService.getFormattedDateZone(
                order.originalOrderDOS
              ),
              newOrderDate: this.commonService.getFormattedDateZone(
                order.newOrderDate
              ),
              newOrderDOS: this.commonService.getFormattedDateZone(
                order.newOrderDOS
              ),
              originalOrderCreatedDate:
                this.commonService.getFormattedDateTimeZone(
                  order.originalOrderCreatedDate
                ),
              originalOrderModifiedDate:
                this.commonService.getFormattedDateTimeZone(
                  order.originalOrderModifiedDate
                ),
              newOrderCreatedDate: this.commonService.getFormattedDateTimeZone(
                order.newOrderCreatedDate
              ),
              newOrderModifiedDate: this.commonService.getFormattedDateTimeZone(
                order.newOrderModifiedDate
              ),
              exchangeDate: this.commonService.getFormattedDateZone(
                order.exchangeDate
              ),
            };
          });

          // ltExchangeOrders
          console.log(this.ltExchangeOrders);
          this.loadExchangeTable = true;
        },
        (err) => {
          this.loadExchangeTable = true;
        }
      );
    this.subscription$.push(exchangeItems);
  }
  newOrderGuid: string = defaultGuid;
  exchangeOrderGuid: string = defaultGuid;
  defaultGuid = defaultGuid;
  ltItemReturnCondition: any[] = [];
  //To get the Exchange Items List
  getExchangeItemsList(
    originalOrderId: string,
    newOrderId: string,
    isPickup: boolean,
    originalOrderNo: string,
    newOrderNo: string
  ) {
    console.log(newOrderId);
    console.log(newOrderNo);
    this.pageLoading = true;
    const exchangeItemsList = this.mmOrderService
      .getExchangedItemsDetailsByIdV1ByOriginalOrderIdAndNewOrderIdAndIsPickup(
        originalOrderId,
        newOrderId,
        isPickup
      )
      .subscribe(
        (response) => {
          this.ltExchangeOrderItems = response?.originalItemList ?? [];

          if (isPickup) {
            this.ltExchangeOrderItems = this.ltExchangeOrderItems.map(
              (order) => {
                return {
                  ...order,
                  returnTempConditionId: this.defaultGuid,
                };
              }
            );
          }

          this.ltNewOrderItems = response?.newItemList ?? [];
          this.newOrderId = Number(newOrderNo);
          this.exchangeOrderId = Number(originalOrderNo);
          this.newOrderGuid = newOrderId;
          this.exchangeOrderGuid = originalOrderId;
          this.dialogRef = this.dialog.open(this.exchangeItemsDialogTemplate, {
            disableClose: true,
          });
          this.pageLoading = false;
        },
        (err) => {
          this.pageLoading = false;
        }
      );
    this.subscription$.push(exchangeItemsList);
  }
  //To close the Dialog
  closeDialog(): void {
    if (this.dialogRef) {
      this.dialogRef.close();
    }
  }
  loadDropdowns() {
    const returnCondition = this.returnExchangeConditionService
      .getList(new PagedAndSortedResultRequestDto())
      .subscribe(
        (response) => {
          this.ltItemReturnCondition = response?.items ?? [];
        },
        (err) => {}
      );

    this.subscription$.push(returnCondition);
  }
  updatePickupItems(data: any) {
    let inputParams: clsPickupOrderReturn = {
      gOrderId: this.exchangeOrderGuid,
      gOrderItemId: data?.orderItemId,
      gExchangeConditionId: data?.returnTempConditionId,
    };
    this.mmOrderItemReturnService
      .updateReturnPickupOrderByInput(inputParams)
      .subscribe(
        (response) => {
          data.exchangeConditionId = data?.returnTempConditionId;
          data.exchangeConditionName = this.ltItemReturnCondition.find(
            (x) => x.id == data.returnTempConditionId
          )?.description;
          console.log(data.exchangeConditionName);
          console.log(
            this.ltItemReturnCondition.find(
              (x) => x.id == data.returnTempConditionId
            )
          );
          if (
            this.ltExchangeOrderItems.every(
              (item) =>
                item.exchangeConditionId != defaultGuid &&
                item.exchangeConditionId != null
            )
          ) {
            this.toastr.success('Pickup Completed Successfully');
            this.closeDialog();
            this.getExchangeOrdersList();
          } else {
            this.toastr.success('Item Returned Successfully');
          }
        },
        (err) => {}
      );
  }
  changePage(pageNo: number) {
    if (pageNo != this.currentPage && pageNo > 0 && pageNo <= this.totalPages) {
      this.currentPage = pageNo;
      this.getExchangeOrdersList();
    }
  }
}
