<table
  datatable
  [dtOptions]="dtClaimTableOptions"
  class="row-border hover w-100 display"
>
  <thead>
    <tr>
      <th>
        <mat-checkbox
          (click)="onUserSelectionChange($event)"
          (change)="$event ? masterToggle() : null"
          [checked]="selection.hasValue() || checkAll"
          [(ngModel)]="checkAll"
          [disabled]="allCheckboxdisabled"
          [ngClass]="allCheckboxdisabled ? 'mat-my-fill-margin' : ''"
          style="margin-right: 3px"
        >
        </mat-checkbox>
      </th>
      <th class="text-right" [matTooltip]="'Ticket Id'">Ticket Id</th>
      <th [matTooltip]="'Chart No'">Chart No</th>
      <th [matTooltip]="'Patient Name'">Patient Name</th>
      <th [matTooltip]="'Provider Name'">Provider Name</th>
      <th [matTooltip]="'Insurance Rank'">Insurance Rank</th>
      <th [matTooltip]="'Branch'">Branch</th>
      <th class="text-right" [matTooltip]="'DOS'">DOS</th>
      <th class="text-right" [matTooltip]="'Total Billed ($)'">
        Total Billed ($)
      </th>
      <th class="text-right" [matTooltip]="'Total Allowed ($)'">
        Total Allowed ($)
      </th>
      <th [matTooltip]="'Insurance Name'">Insurance Name</th>
      <th [matTooltip]="'Status'">Status</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let data of lstClaimProcessList">
      <td class="checkbox-and-link">
        <!--
class="custom-disabled-checkbox" -->
        <mat-checkbox
        [ngClass]="data.claimId == defaultGuid ? 'mat-my-fill-margin' : ''"
          [disabled]="data.claimId == defaultGuid"
          [checked]="selection.isSelected(data)"
          [(ngModel)]="data.selected"
          (change)="onCheckboxChange($event, data.claimId)"
          (click)="$event.stopPropagation()"
        ></mat-checkbox>

        <button
          class="buttonColor ml-2"
          mat-raised-button
          (click)="
            openClaimForm(
              data.mmOrderId,
              data.patientId,
              data.mmRefereceOrderId,
              data.claimId,
              data.chartNo,
              data.fullName
            )
          "
          type="button"
        >
          Claim Form
        </button>
        <!-- <a class="ml-2" [matTooltip]="'Click to View'"
                    (click)="openClaimForm(data.mmOrderId,data.patientId,data.mmRefereceOrderId, data.claimId)">
                    <img class="img-responsive pr-1 mb-1 cursor" src="assets/Claim Page Icons/Vector.png" alt=""
                        height="17" />
                </a> -->
      </td>
      <td class="text-right" [matTooltip]="data?.mmRefereceOrderId || '-'">
        <a
          class="preview-link"
          [class.disabled]="isEmpty(data.patientId) || isEmpty(data.mmOrderId)"
          [routerLink]="
            '/orders/saleOrder/' +
            data.patientId +
            '/' +
            data.mmOrderId +
            '/' +
            '0'
          "
          target="_blank"
        >
          {{ data?.mmRefereceOrderId || "-" }}
        </a>
      </td>
      <td>
        <a
          [class.disabled]="isEmpty(data.patientId)"
          matTooltip="Click to View Patient"
          class="eyeCursorclass"
          [routerLink]="'/patientCreateEditTab/' + data?.patientId"
          target="_blank"
        >
          {{ data.chartNo }}
        </a>
      </td>
      <td [matTooltip]="data?.fullName || '-'">{{ data?.fullName || "-" }}</td>
      <td [matTooltip]="data?.providerName || '-'">
        {{ data?.providerName || "-" }}
      </td>
      <td [matTooltip]="data?.payorlevel || '-'">
        {{ data?.payorlevel || "-" }}
      </td>
      <td [matTooltip]="data?.location || '-'">{{ data?.location || "-" }}</td>
      <td
        class="text-right"
        [matTooltip]="
          data?.dos
        "
      >
        {{ data?.dos }}
      </td>
      <td [matTooltip]="data?.totalBilled || '-'" class="text-right">
        {{ data?.totalBilled || "-" }}
      </td>
      <td [matTooltip]="data?.totalAllowed || '-'" class="text-right">
        {{ data?.totalAllowed || "-" }}
      </td>
      <td [matTooltip]="data?.insuranceName || '-'">
        {{ data?.insuranceName || "-" }}
      </td>
      <td [matTooltip]="data?.status || '-'">
        {{ data?.status || "-" }}
        <!-- <ng-container
          matTooltip="Fill Claim Form"
          *ngIf="data.claimId == defaultGuid || data.claimId == null"
          >Fill Claim Form</ng-container
        >
        <ng-container
          matTooltip="Ready To Process"
          *ngIf="data.claimId !== defaultGuid && data.claimId !== null"
          >Ready To Process</ng-container
        > -->
      </td>
    </tr>
  </tbody>
  <tfoot>
    <tr>
      <th>
        <mat-icon>apps</mat-icon>
      </th>
      <th class="text-right">
        <input
          matInput
          class="textbox-border-class"
          type="text"
          placeholder="Ticket Id"
          name="search-TicketId"
        />
      </th>
      <th>
        <input
          matInput
          class="textbox-border-class"
          type="text"
          placeholder="Chart No"
          name="search-ChartNo"
        />
      </th>
      <th>
        <input
          matInput
          class="textbox-border-class"
          type="text"
          placeholder="Patient Name"
          name="search-PatientName"
        />
      </th>
      <th>
        <input
          matInput
          class="textbox-border-class"
          type="text"
          placeholder="Provider Name"
          name="search-ProviderName"
        />
      </th>

      <th>
        <input
          matInput
          class="textbox-border-class"
          type="text"
          placeholder="Insurance Rank"
          name="search-InsuranceRank"
        />
      </th>

      <th>
        <input
          matInput
          class="textbox-border-class"
          type="text"
          placeholder="Branch"
          name="search-Branch"
        />
      </th>
      <th class="text-right">
        <input
          matInput
          class="textbox-border-class"
          type="text"
          placeholder="DOS"
          name="search-DOS"
        />
      </th>
      <th class="text-right">
        <input
          matInput
          class="textbox-border-class"
          type="text"
          placeholder="Total Billed"
          name="Total Billed"
        />
      </th>
      <th class="text-right">
        <input
          matInput
          class="textbox-border-class"
          type="text"
          placeholder="Total Allowed"
          name="Total Allowed"
        />
      </th>
      <th>
        <input
          matInput
          class="textbox-border-class"
          type="text"
          placeholder="Insurance Name"
          name="InsuranceName"
        />
      </th>
      <th>
        <input
          matInput
          class="textbox-border-class"
          type="text"
          placeholder="Status"
          name="Status"
        />
      </th>
    </tr>
  </tfoot>
</table>
