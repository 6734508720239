<!-- <div class="row">
    <div calss="col-12"> -->
<!-- <div class="table-responsive"> -->
<table class="table table-centered table-borderless mb-0">
    <tbody class="text-left textstart">
        <tr>
            <td class="pb-2 pt-2">
                <strong>Chart ID</strong>
            </td>
            <td class="pb-2 pt-2">
                21545155369
            </td>
        </tr>

        <tr>
            <td class="pb-2 pt-2">
                <strong>Patient Name</strong>
            </td>

            <td class="pb-2 pt-2">
                Aubrey Lucas
            </td>
        </tr>

        <tr>
            <td class="pb-2 pt-2">
                <strong>DOB</strong>
            </td>

            <td class="pb-2 pt-2">
                04/10/2024
            </td>
        </tr>

        <tr>
            <td class="pb-2 pt-2">
                <strong>Email</strong>
            </td>

            <td class="pb-2 pt-2">
                aubreylucasgmail.com
            </td>
        </tr>

        <tr>
            <td class="pb-2 pt-2">
                <strong>Gender</strong>
            </td>

            <td class="pb-2 pt-2">
                Male
            </td>
        </tr>

        <tr>
            <td class="pb-2 pt-2">
                <strong>Phone Number</strong>
            </td>

            <td class="pb-2 pt-2">
                1+(523)-425-5362
            </td>
        </tr>
    </tbody>
</table>
<!-- </div> -->
<!-- </div> -->
<div class="col-12 mt-1" style="border-top: 2px solid rgb(201, 201, 201)">
    <mat-divider></mat-divider>
</div>

<div class="row">
    <div class="col-lg-12">
        <h3 class="font-weight-bolder mb-0 customThemeClass">
            Billing Details
        </h3>
        <div class="table-responsive mt-2">
            <table class="table table-centered">
                <tbody>
                    <tr>
                        <td>
                            <p class="mb-0 text-center"> Patient Balance</p>
                        </td>
                        <td>
                            <p class="mb-0 text-center colored-heading"> {{100 | currency : "USD" : "symbol"}}</p>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <p class="mb-0 text-center">Inurance Balance</p>
                        </td>
                        <td>
                            <p class="mb-0 text-center colored-heading"> {{15 | currency : "USD" : "symbol"}}</p>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <p class="mb-0 text-center">Recoop Amount</p>
                        </td>
                        <td>
                            <p class="mb-0 text-center colored-heading"> {{10 | currency : "USD" : "symbol"}}</p>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <p class="mb-0 text-center">Total Amount</p>
                        </td>
                        <td>
                            <p class="mb-0 text-center colored-heading"> {{200 | currency : "USD" : "symbol"}}</p>
                        </td>
                    </tr>
                    <tr>
                </tbody>
            </table>
        </div>
    </div>
</div>

<div class="col-12 mt-1" style="border-top: 2px solid rgb(201, 201, 201)">
    <mat-divider></mat-divider>
</div>

<div class="d-flex justify-content-between mt-1">
    <div class="page-title-box page-title-left pb-0">
        <h3 class="font-weight-bolder mb-0 customThemeClass">
            Recently added Notes
        </h3>
    </div>
    <div class="page-title-box page-title-right pb-0">
        <button mat-button class="buttonColor"><mat-icon>remove_red_eye</mat-icon> View Notes</button>
    </div>
</div>

<div class="mt-3">
    <span class="customfontSize ">John Wick - 01/10/2024 02:46:16 - Text Conversation
        - Lorem Ipsum is simply dummy text of the printing</span>
</div>
<mat-divider></mat-divider>
<div class="mt-3">
    <span class="customfontSize">John Wick - 01/10/2024 02:46:16 - Text Conversation
        - Lorem Ipsum is simply dummy text of the printing</span>
</div>
<mat-divider></mat-divider>
<div class="mt-3">
    <span class="customfontSize">John Wick - 01/10/2024 02:46:16 - Text Conversation
        - Lorem Ipsum is simply dummy text of the printing</span>
</div>

<div class="col-12 mt-1" style="border-top: 2px solid rgb(201, 201, 201)">
    <mat-divider></mat-divider>
</div>
<div class="d-flex justify-content-between mt-1">
    <div class="page-title-box page-title-left pb-0">
        <h3 class="font-weight-bolder mb-0 customThemeClass">
            Shared Image
        </h3>
    </div>
</div>

<div class="mt-3">
    <div class="d-flex justify-content-between mt-1">
        <img src="assets/images/textconversation/img1.jpg" alt="" class="sea-green-icon-ins mr-1">
        <img src="assets/images/textconversation/img2.jpg" alt="" class="sea-green-icon-ins mr-1">
    </div>
</div>

<div class="mt-3">
    <div class="d-flex justify-content-between mt-1">
        <img src="assets/images/textconversation/img3.jpg" alt="" class="sea-green-icon-ins mr-1">
        <img src="assets/images/textconversation/img4.jpg" alt="" class="sea-green-icon-ins mr-1">
    </div>
</div>