<mat-accordion>
    <mat-expansion-panel [expanded]="panelOpenState"(toggle)="(panelOpenState)" (afterExpand)="toggle()" (closed)="toggleV2()">
        <mat-expansion-panel-header>
            <mat-panel-title class="customThemeClass">
                <ng-container>
                    <b>
                        <ng-container *ngIf="
                    orderId === '';
                    then thenTitleTemplate;
                    else elseTitleTemplate"></ng-container>
                        <ng-template #thenTitleTemplate> Add Order Description </ng-template>
                        <ng-template #elseTitleTemplate> Edit Order Description </ng-template>
                    </b>
  
                </ng-container>
            </mat-panel-title>
        </mat-expansion-panel-header>
        <form [formGroup]="orderform">
            <div class="col-lg-12">
                <mat-card-content>
                    <div class="row">                      
                        <mat-form-field class="col-6">
                            <mat-label>
                              Order Description <span class="asterisk">*</span>
                            </mat-label>
                            <input autocomplete="new-txtShipppingDescription" appearance="inline" matInput maxlength="40"formControlName="txtShipppingDescription" type="text"/>  
                                <mat-error *ngIf="orderform?.get('txtShipppingDescription')?.touched && orderform?.get('txtShipppingDescription')?.errors">
                                    Enter only characters!
                                </mat-error>
                        </mat-form-field>
                        <div class="col-6">
                            <button mat-button class="buttonColor mb-2 mr-2"
                            [disabled]="saveButtonHide || !!orderform.invalid" (click)="saveOrderDescription()">
                            {{ orderId === '' ? 'Save' : 'Update' }}
                          <mat-icon *ngIf="isShowSpinner">
                              <mat-spinner class="spinner-border spinner-border-sm" diameter="90">
                              </mat-spinner>
                          </mat-icon>
                            </button>
                            <button mat-button class="buttonColor mr-2 resetclr" (click)="resetOrderShipping()">
                                Reset
                              </button>
                        </div>
                    </div>
                </mat-card-content>
            </div>
        </form>
        <br />
    </mat-expansion-panel>
  </mat-accordion>
  

<div class="card card-body mt-3">
    <div class="row">
            <div class="col-12" *ngIf="isLoading">
                <div class="d-flex justify-content-center" *ngIf="isLoading">
                    <img class="img-responsive pb-2 mr-1" src="assets/gifs/Infinity-loader.gif" />
                </div>
            </div>
        <div class="col-12 mt-3" *ngIf="!isLoading">
            <div class="table table-responsive">               
                <table id="tbl" datatable [dtOptions]="dtOptions" class="tbl hover row-border hover w-100 display">
                    <thead>
                        <tr>
                            <th class="cutomWidth"><mat-icon>apps</mat-icon></th>      
                            <th [matTooltip]="'Description'">Description</th>                       
                            <th [matTooltip]="'Created / Modified Date'">Created / Modified Date</th>                                                                    
                        </tr>
                        </thead>
                        <tbody>
                        <tr *ngFor="let data of descriptionData">
                            <td class="cutomWidth">
                                <a class="editBtnColor text-left" (click)="viewDescription(data?.id)">
                                    <i class="fa fa-pencil editBtn mr-1" (click)="toggle()"
                                    matTooltip="Click To Edit Order Description"></i>
                                </a>
                            </td>
                            <td (dblclick)="onRowDoubleClickV1(data?.description || '-')">{{ data?.description }}</td>  

                            <td [matTooltip]="data?.lastModificationTime" class="text-left">                                
                                {{ data?.lastModificationTime }}
                            </td>                          
                            <!-- <td [matTooltip]="getFormattedDate(data?.lastModificationTime !=null ? data?.lastModificationTime : data?.creationTime)" class="text-left">                                
                                {{ getFormattedDate(data?.lastModificationTime !=null ? data?.lastModificationTime : data?.creationTime) }}
                            </td>    -->
                      
                        </tr>
                    </tbody>
                    <tfoot>
                        <tr>
                            <th><mat-icon>apps</mat-icon></th>
                            <th>
                                <input matInput class="textbox-border-class" type="text" placeholder="Description"
                                    name="search-Status" />
                            </th>
                            <th>
                                <input matInput class="textbox-border-class" type="text" placeholder="Created / Modified Date"
                                    name="search-DocumentBinName" />
                            </th>                           
                    </tfoot>
                </table>
            </div>
        </div>
    </div>
</div>