<div class="container-fluid">
  <!-- Headers -->
  <div class="row">
    <div class="col-12 d-flex justify-content-between">
      <h3 class="font-weight-bolder mb-2 mt-2">
        <b class="customThemeClass">Inventory Management / Purchase Order</b>
      </h3>
    </div>
  </div>

  <div class="card">
    <!-- Tab Headers -->
    <div class="row">
      <div class="col-12">
        <ul
          class="card card-body"
          ngbNav
          #nav="ngbNav"
          justify="justified"
          class="alert-success nav nav-pills nav-fill"
        >
          <!-- [class.selected-tab]="nav.activeId === 'Summary'" -->
          <!-- Summary -->
          <li ngbNavItem="Summary">
            <a
              [ngClass]="activeTab == 'Summary' ? 'active' : ''"
              ngbNavLink
              (click)="changeTab('Summary')"
              ><b class="small-text">Summary</b></a
            >
          </li>
          <!-- Purchase Order -->
          <li
            ngbNavItem="PurchaseOrder"
            [class.selected-tab]="nav.activeId === 'PurchaseOrder'"
          >
            <a
              [ngClass]="activeTab == 'PurchaseOrder' ? 'active' : ''"
              ngbNavLink
              (click)="changeTab('PurchaseOrder')"
              ><b class="small-text">Purchase Order</b></a
            >
          </li>
        </ul>
      </div>
    </div>
    <!-- Tab Content -->
    <div class="row">
      <div class="col-12">
        <div
          class="card-body p-3"
          style="border-top: 3px solid rgb(201, 201, 201)"
        >
          <!-- New Setup -->
          <div *ngIf="activeTab === 'Summary'">
            <div>
              <div
                class="d-flex justify-content-center"
                *ngIf="!isSummaryTableLoaded"
              >
                <img
                  class="img-responsive pb-2 mr-1"
                  src="assets/gifs/Infinity-loader.gif"
                />
              </div>
              <div class="table table-responsive" *ngIf="isSummaryTableLoaded">
                <app-purchase-order-summary-table
                  [ltSummaryData]="ltSummaryData"
                ></app-purchase-order-summary-table>
              </div>
            </div>
          </div>
          <!-- New Setup Pending -->
          <div *ngIf="activeTab === 'PurchaseOrder'">
            <!-- <p class="header-text">Manufacturer List</p> -->
            <form [formGroup]="POForm">
              <mat-form-field class="col-3 dd-text">
                <mat-label class="dd-text"> Manufacturers </mat-label>
                <mat-select
                  (selectionChange)="getPurchaseOrders()"
                  formControlName="drpManufacturer"
                >
                  <mat-option>
                    <ngx-mat-select-search
                      placeholderLabel="Search"
                      noEntriesFoundLabel="No Matches found"
                      formControlName="txtManufacturerFilter"
                    ></ngx-mat-select-search>
                  </mat-option>

                  <mat-option
                    *ngFor="let vendors of filteredVendorDetails | async"
                    [value]="vendors.id"
                  >
                    {{ vendors.vendorName }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <!-- <button
                (click)="getPurchaseOrders()  "
                mat-raised-button
                class="buttonColor small-text py-2"
              >
                Get Orders
              </button> -->
            </form>
            <div>
              <div
                class="d-flex justify-content-center"
                *ngIf="!isPOTableLoaded"
              >
                <img
                  class="img-responsive pb-2 mr-1"
                  src="assets/gifs/Infinity-loader.gif"
                />
              </div>
              <div class="table table-responsive" *ngIf="isPOTableLoaded">
                <app-purchase-order-table
                  [ltPurchaseOrders]="ltPurchaseOrders"
                  [manufacturerName]="manufacturerName"
                ></app-purchase-order-table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
