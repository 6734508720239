<div class="container-fluid">
  <div class="row bg">
    <div class="col-12">
      <div class="d-flex align-items-center justify-content-between">
        <h2 class="font-weight-bolder mb-0">
          <b class="header-class">Schedule</b>
        </h2>
        <div class=" page-title-box page-title-right">
          <!-- <button mat-button class="buttonColor customButton mb-2 mr-2" (click)="openAddScheduleModal()"><mat-icon>add</mat-icon>Add Schedule</button> -->
        </div>
      </div>
    </div>
  </div>

<!-- <div class="card card-body ml-2"> -->
  <!-- Design issue overlap fixed By Manikandan 2024-01-10 -->
  <!-- <div class="d-flex"> -->
    <!-- <div class="col-12">
      <b>Information Status:</b>
      <span class="ml-2 align-items-center">
        Scheduled <span class="dot sea-dot ml-2"></span>
      </span>
      <span class="ml-2 align-items-center">
        Rescheduled <span class="dot orange-dot ml-2"></span>
      </span>
      <span class="ml-2 align-items-center">
        Completed <span class="dot green-dot ml-2"></span>
      </span>
      <span class="ml-2 align-items-center">
        Cancelled <span class="dot red-dot ml-2"></span>
      </span>
    </div> -->
  <!-- </div> -->

  <div class="ml-2 mr-2 mt-3">


    <ejs-schedule #scheduleObj height="650px" [selectedDate]="selectedDate" [eventSettings]="eventSettings"
      currentView="Week" (popupOpen)="onPopupOpen($event)" (actionComplete)="onActionComplete($event)"
      (eventRendered)="applyCategoryColor($event)" (eventClick)="onEventClick($event)" [showQuickInfo]="showQuickInfo"
      (cellClick)="onCellClick($event)"  [allowDragAndDrop]="false"
      [allowResizing]="false" >
      <!-- (actionBegin)="onActionBegin($event)" -->
      <!-- <e-views>
        <e-view option="Day" [timeScale]="{ enable: true }"></e-view>
        <e-view option="Week" [timeScale]="{ enable: true }"></e-view>
        <e-view option="WorkWeek" [timeScale]="{ enable: true }"></e-view>
        <e-view option="Month" [timeScale]="{ enable: true }"></e-view>
      </e-views> -->
      <e-resources>
        <e-resource *ngIf="show" field="OwnerId" title="Status" name="Schedule" [dataSource]="roomDataSource"
          textField='OwnerText' idField='OwnerText' >
          <!-- [(ngModel)]="selectedOption" -->
        </e-resource>
      </e-resources>

      <!-- <e-resources>
        <e-resource *ngIf="show" field="OwnerId" title="Status" name="Schedule" [dataSource]="roomDataSource"
            textField='OwnerText' idField='OwnerText' [allowMultiple]="true" [filterType]="'Menu'">
        </e-resource>
    </e-resources> -->


      <!-- <ng-template #eventSettingsTemplate let-data>
        <div>
          <div class="subject">{{data?.Subject}}</div>
          <div class="time">
            Status: {{data?.status}}
          </div>
        </div>
      </ng-template> -->
      <!-- <ng-template #groupHeaderTooltipTemplate let-data>
        <div class='template-wrap'>
          <div class="res-text">Name: {{data?.resourceData?.OwnerText}} </div>
        </div>
      </ng-template> -->
    </ejs-schedule>

    <!--
      <ejs-schedule #scheduleObj height="650px" [selectedDate]="selectedDate" [eventSettings]="eventSettings"
        currentView="Week" (popupOpen)="onPopupOpen($event)" (actionComplete)="onActionComplete($event)"
        (eventRendered)="applyCategoryColor($event)" (eventClick)="onEventClick($event)" [showQuickInfo]="showQuickInfo"
        (cellClick)="onCellClick($event)">

        <! -- Other schedule settings and configurations -->

        <!-- <ng-template #eventTemplate let-data>
          <div class="custom-event-template">
            <div>{{ data.Subject }}</div>
            <div>{{ formatDate(data.StartTime) }} - {{ formatDate(data.EndTime) }}</div>
          </div>
        </ng-template> -->

        <!-- <e-resources>
          <e-resource field="isAllDay" title="All Day"></e-resource>
      </e-resources> - ->

      </ejs-schedule> -->
  </div>
</div>
<!-- </div> -->
