

export class addReminder {
  static readonly type = '[ReminderDTO] Add]';
  constructor(public readonly payload?: any) {// payload: ReminderDTO
   }
}




export class updateReminder {
  static readonly type = '[ReminderDTO] updateReminder]';
  constructor(public id:string, public readonly payload?: any) { // payload: ReminderDTO
  }
}





export class getReminder {
  static readonly type = '[ReminderDTO] getReminder]';
  constructor(public id:string) { }
}
