import { Component, OnInit, ViewChild } from '@angular/core';
import { dtInboundOptions, inboundTableData } from '../admin-dashboard/dashboard-data';
import { DataTableDirective } from 'angular-datatables';

@Component({
  selector: 'app-view-notes',
  templateUrl: './view-notes.component.html',
  styleUrls: ['./view-notes.component.scss']
})
export class ViewNotesComponent implements OnInit {
  inboundTableData = inboundTableData;
  dtInboundOptions = dtInboundOptions;
  notesData :any;
  @ViewChild(DataTableDirective, { static: false })
  datatableElement: DataTableDirective;
  myTable: DataTables.Api;
  constructor() { }

  ngOnInit(): void {
  }
  ngAfterViewInit(): void {
    this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.columns().every(function () {

        const that = this;
        $('input', this.footer()).on('keyup change', function () {

          if (that.search() !== this['value']) {
            that.search(this['value']).draw();
          }
        });
      });
    });
  }


  //Row Click Event UnProcessed Table
  handleTableCellClick(e: Event, notesData: any): void {

    this.notesData = notesData
    const target = e.target as HTMLElement;
    const tr = target.closest('tr');
    this.getDataTableData(tr, notesData);
  }
    //DataTable Data Get Method and Toggle Notes Row in UnProcessed Table
    async getDataTableData(tr: any, notesData: any): Promise<void> {
      this.myTable = await this.datatableElement.dtInstance;


      if (tr) {
        const row = this.myTable.row(tr);

        if (row.child.isShown()) {
          // This row is already open - close it
          row.child.hide();
        } else {
          // Open this row
          row.child(this.format(row.data(), notesData)).show();
        }
      }
      // Now you can use the dataTable object to get data, for example:
      // const data = this.myTable.rows().data().toArray();

    }
    format(d: any, notesData: any) {

      let returnString: string = '';
      notesData.forEach(function (value) {
        returnString += '<tr class="border-bottom"><td>' + value + '</td></tr>';
      });
      return returnString;
      // (
      //   '<tr class="border-bottom"><td>' +
      //   'There is a language course organised at the training centre. Your unit can send one soldier to attend this course. You have been tasked with giving a briefing presenting two possible candidates' +
      //   '</td></tr>' +
      //   '<tr class="border-bottom"><td>' +
      //   'Let me start with Candidate A and his strengths. Captain Nowak is a very experienced officer who has participated in a few missions abroad and cooperated with our foreign allies. Therefore, he has plenty of practical experience in real life communication in English and working in this language' +
      //   '</td></tr>'
      // );
    }
}
