import { mapEnumToOptions } from '@abp/ng.core';

export enum InsuranceType {
  Primary = 'Primary',
  Secondary = 'Secondary',
  Tertiary = 'Tertiary',
}
export enum InsuranceTypeV1 {
  primary = 'primary',
  secondary = 'secondary',
  tertiary = 'tertiary',
}
export const insuranceTypeOptions = mapEnumToOptions(InsuranceType);
