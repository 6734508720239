<mat-dialog-content class="mat-typography">
  <div class="row">
    <div class="col-lg-6">
      <h2 class="margin"> Search By Date Range</h2>
    </div>
    <div class="col-lg-6 text-right">
      <button class="text-right close" aria-label="Close" [mat-dialog-close]="dateRangeVerificationData"><b> X
        </b></button>
    </div>
  </div>
  <hr />

  <mat-card-content>
    <div class="row">
      <form [formGroup]="searchForm">
        <mat-form-field class="col-lg-6" class="left" class="mat-calendar  ng-tns-c281-12 ng-trigger ng-trigger-fadeInCalendar">
          <mat-label>Enter a date range</mat-label>
          <mat-date-range-input [rangePicker]="picker">
            <input autocomplete="new-txtFromDate" matStartDate formControlName="txtFromDate" placeholder="Start date">
            <input autocomplete="new-txtToDate" matEndDate formControlName="txtToDate" placeholder="End date">
          </mat-date-range-input>
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-date-range-picker #picker></mat-date-range-picker>
          <mat-error *ngIf="txtFromDate?.hasError('matStartDateInvalid')">Invalid start date</mat-error>
          <mat-error *ngIf="txtToDate?.hasError('matEndDateInvalid')">Invalid end date</mat-error>
          <mat-error
          *ngIf=" searchForm?.get('txtFromDate')?.touched &&
          searchForm?.get('txtFromDate')?.errors?.required">
          Date is a required field!
        </mat-error>
        <mat-error
        *ngIf=" searchForm?.get('txtToDate')?.touched &&
        searchForm?.get('txtToDate')?.errors?.required ">
        Date is a required field!
      </mat-error>
        </mat-form-field>
      </form>
      <div class="col-lg-6">
        <button mat-button [disabled]="searchForm.invalid" class="buttonColor mb-2 mr-2"
          (click)="searchFilter()">Search</button>
        <button mat-button class="resetclr buttonColor mb-2 mr-2" (click)="resetFilter()">Reset</button>
      </div>
    </div>

  </mat-card-content>
</mat-dialog-content>
