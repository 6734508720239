<mat-card class="mr-3 mt-3">
    <!-- <div class="card card-body"> -->
    <!-- Header Section -->
    <div class="customThemeClass">
        <div class="row" *ngIf="!isLoading">
            <div class="col-md-10">
                <mat-radio-group [(ngModel)]="selectedOption" (change)="GetReminderFilterList()">
                    <mat-radio-button class="Reminders pl-3" [value]="0"><span class="fontresize">All Reminders</span></mat-radio-button>
                    <mat-radio-button class="Reminders" [value]="1"><span class="fontresize">My Reminders</span> </mat-radio-button>
                    <mat-radio-button class="Reminders" [value]="2"><span class="fontresize">Assigned To</span> </mat-radio-button>
                    <mat-radio-button class="Reminders" [value]="3"><span class="fontresize">Completed</span> </mat-radio-button>
                    <mat-radio-button class="Reminders" [value]="4"><span class="fontresize">Priority</span> </mat-radio-button>
                </mat-radio-group>
            </div>
            <div class="col-md-2 text-right">
                <button mat-button class="buttonColor" (click)="OpenAddReminderMdl(true)">
                    <i class="fa fa-plus mr-1"></i> Add Reminder
                </button>
            </div>
        </div>
    </div>
    <!-- Table Section -->
    <div class="card card-body">
        <!-- Loader Section -->
        <div class="row">
            <div class="col-12" *ngIf="isLoading">
                <div class="d-flex justify-content-center" *ngIf="isLoading">
                    <img class="img-responsive pb-2 mr-1" src="assets/gifs/Infinity-loader.gif" />
                </div>
            </div>

            <div class="col-12" *ngIf="!isLoading">
                <app-patient-reminder-v1 [vDescription]="vDescription" [tableData]="remindertbldata"></app-patient-reminder-v1>
            </div>
        </div>
    </div>
</mat-card>