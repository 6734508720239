<mat-accordion>
  <mat-expansion-panel
    (toggle)="(step)"
    [expanded]="step"
    (afterExpand)="toggle()"
    (closed)="toggleV2()"
  >
    <mat-expansion-panel-header>
      <mat-panel-title class="customThemeClass">
        <ng-container *ngIf="roleId === emptyguid; else elseTitleTemplate">
          <b>Add Roles</b>
        </ng-container>
        <ng-template #elseTitleTemplate>
          <b>Edit Roles</b>
        </ng-template>
      </mat-panel-title>
    </mat-expansion-panel-header>

    
    <form [formGroup]="roleForm">
      <mat-form-field class="col-6">
        <mat-label> Role Name <span class="asterisk">*</span></mat-label>
        <input
          autocomplete="new-txtRoleName"
          matInput
          maxlength="40"
          formControlName="txtRoleName"
          type="text"
          pattern="[A-Za-z]+(\s[A-Za-z]+)*"
          title="Only alphabetic characters are allowed"
        />
        <mat-error
          *ngIf="
            roleForm?.get('txtRoleName')?.touched &&
            roleForm?.get('txtRoleName')?.errors?.required
          "
        >
          Role Name is a required field!
        </mat-error>
        <mat-error
          *ngIf="
            roleForm?.get('txtRoleName')?.touched &&
            roleForm?.get('txtRoleName')?.hasError('pattern')
          "
        >
          Only Alphabetic Characters allowed!
        </mat-error>
      </mat-form-field>
    </form>
    <br />
    <div class="col-7">
      <div class="text-lg-right center">
        <button
          *ngIf="isShownSaveButton"
          [disabled]="saveButtonHide || roleForm.invalid"
          mat-button
          class="buttonColor mr-2"
          (click)="saveRole()"
        >
          {{ roleId === emptyguid ? "Save" : "Update" }}
          <mat-icon *ngIf="isShowSpinner">
            <mat-spinner class="spinner-border spinner-border-sm" diameter="20">
            </mat-spinner>
          </mat-icon>
        </button>
        <button mat-button class="buttonColor mr-2 resetclr" (click)="reset()">
          Reset
        </button>
      </div>
    </div>
  </mat-expansion-panel>
</mat-accordion>
<br />
<div class="row">
  <div class="col-12 mt-3">
    <div class="col-12">
      <div class="d-flex justify-content-center" *ngIf="isLoading">
        <img class="img-responsive pb-2 mr-1" src="assets/gifs/Infinity-loader.gif" />
      </div>
    </div>
    <div class="table table-responsive" *ngIf="!isLoading">
      <table
        id="tblrole"
        datatable
        [dtOptions]="dtRoleTableOptions"
        class="tbl hover row-border hover w-100 display"
      >
        <thead>
          <tr>
            <th class="customWidth"><mat-icon>apps</mat-icon></th>
            <th [matTooltip]="'Status'">Status</th>
            <th [matTooltip]="'Role Name'">Role Name</th>
            <th [matTooltip]="'Created By'">Created By</th>
            <th [matTooltip]="'Modified Date'" class="text-right">
              Modified Date
            </th>
            <th [matTooltip]="'Modify By'">Modify By</th>
            <th [matTooltip]="'Modified Date'" class="text-right">
              Created/Modified Date
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            *ngFor="let data of roleTableData"
            [ngClass]="{ stat_highlight: data.isStat == true }"
          >
            <td class="customWidth">
              <ng-container>
                <a
                  *ngIf="isShownclientPermission"
                  [disabled]="
                    data?.isAdmin === true || data?.status === 'Inactive'
                  "
                  matTooltip="Click To Assign Permission for Role"
                  (click)="ViewPermissions(data.roleDisplayName, data.name)"
                  mat-icon-button
                >
                  <img
                    height="21"
                    class="img-responsive mr-1"
                    src="assets/Settings Page Icons/Permissions.png"
                /></a>
              </ng-container>
              <ng-container>
                <a
                  (click)="data?.isMute || ViewRole(data)"
                  *ngIf="data?.isAdmin === false && data?.status === 'Active'"
                  [ngClass]="{ 'disabled-link': data?.isMute }"
                  matTooltip="Click To View Role"
                >
                  <i class="fa fa-pencil pointer editBtnColor mr-3"></i>
                </a>
              </ng-container>
            </td>

            <td>
              <ng-container *ngIf="data?.isAlreadyInUse == false">
                <mat-slide-toggle
                  (change)="statusChange($event, data)"
                  [checked]="data?.status === 'Active'"
                  [disabled]="data?.isAdmin === true"
                  class="ml-2"
                >
                  <span [matTooltip]="data?.status || '-'">{{
                    data?.status
                  }}</span>
                </mat-slide-toggle>
              </ng-container>
              <ng-container *ngIf="data?.isAlreadyInUse == true">
                <span [matTooltip]="'In Use'">In Use</span>
              </ng-container>
            </td>

            <td
              [matTooltip]="data.roleDisplayName || '-'"
              (dblclick)="onRowDoubleClickV1(data?.roleDisplayName || '-')"
            >
              {{ data.roleDisplayName || '-' }}
            </td>
            <td
            [matTooltip]="data.modifiedBy ||'-'"
            (dblclick)="onRowDoubleClickV1(data?.modifiedBy || '-')"
          >
            {{
              data.modifiedBy || '-'
            }}
          </td>
            <td
              [matTooltip]="data?.modifyTime"
              (dblclick)="
                onRowDoubleClickV1(data?.modifyTime)
              "
              class="text-right"
            >
              {{ data?.modifyTime }}
            </td>
            <td
            [matTooltip]="data.createdBy || '-'"
            (dblclick)="onRowDoubleClickV1(data?.createdBy || '-')"
          >
            {{
              data.createdBy || '-'
            }}
          </td>
            <td
              [matTooltip]="data?.creationTime"
              (dblclick)="
                onRowDoubleClickV1(data?.creationTime)
              "
              class="text-right"
            >
              {{ data?.creationTime }}
            </td>
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <th class="customWidth"><mat-icon>apps</mat-icon></th>
            <th>
              <input
                matInput
                type="text"
                class="textbox-border-class"
                placeholder="Status"
                name="search-Status"
              />
            </th>
            <th>
              <input
                matInput
                type="text"
                class="textbox-border-class"
                placeholder="Role Name"
                name="search-RoleName"
              />
            </th>
            <th class="text-right">
              <input
                matInput
                class="textbox-border-class"
                type="text"
                placeholder="Created By"
                name="search-Created By"
              />
            </th>
            <th class="text-right">
              <input
                matInput
                class="textbox-border-class"
                type="text"
                placeholder="Modified Date"
                name="search-Modified Date"
              />
            </th>
            <th class="text-right">
              <input
                matInput
                class="textbox-border-class"
                type="text"
                placeholder="Modify By"
                name="search-Modify By"
              />
            </th>
            <th class="text-right">
              <input
                matInput
                class="textbox-border-class"
                type="text"
                placeholder="Created/Modified Date"
                name="search-Modified Date"
              />
            </th>
          </tr>
        </tfoot>
      </table>
    </div>
  </div>
</div>
