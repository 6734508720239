<table id="tblOtherReferral" datatable [dtOptions]="dtOptionsOtherReferal"
    class="tbl hover row-border hover w-100 display">
    <thead>
        <tr>
            <th class="table-header custom-width"><mat-icon>wrap_text</mat-icon></th>
            <th class="table-header custom-width"><mat-icon>apps</mat-icon></th>
            <th class="table-header" [matTooltip]="'Name'">Name</th>
            <th class="table-header" [matTooltip]="'Added On'">Added On</th>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let data of otherReferralTableList">
            <td class="table-data custom-width">
            <mat-icon class="pointer toggle-icon" (click)="getAddressDetatils($event, data)">arrow_right</mat-icon>
            </td>
            <td class="table-data custom-width">
                <i class="fa fa-trash deleteButton" matTooltip="Click to Delete Address"
                    (click)="deleteReferralAddress(data)"></i>
            </td>
            <td class="table-data" [matTooltip]="data?.practiceName || '-'">
                {{data?.practiceName || '-'}}
            </td>
            <td class="table-data" [matTooltip]="(data?.creationName +','+ data?.creationTime) || '-'">
                {{ (data?.creationName +','+ data?.creationTime) || '-' }}
            </td>
        </tr>
    </tbody>
</table>