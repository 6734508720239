<mat-dialog-content class="mat-typography">
  <div class="row">
    <div class="col-lg-6">
      <h2>{{ authModalTitle }}</h2>
    </div>
  </div>
  <mat-card-content>
    <form [formGroup]="authorizationForm">
      <mat-radio-group *ngIf="isStatusShow" class="rg pt-3" (change)="onChangeGetAuth($event)"
        formControlName="rbtnPolicyType">
        <mat-radio-button [checked]='true' value="Primary">Primary</mat-radio-button>&nbsp;&nbsp;&nbsp;
        <mat-radio-button value="Secondary">Secondary</mat-radio-button>&nbsp;&nbsp;&nbsp;
        <mat-radio-button value="Tertiary">Tertiary</mat-radio-button>
      </mat-radio-group>
      <div class="row">
        <mat-form-field class="col-2">
          <mat-label> Plan <span class="asterisk">*</span></mat-label>
          <mat-select (selectionChange)="valueChangeLoadDropdown($event)" [(value)]="plan" formControlName="txtPlan"
            maxlength="40">
            <mat-option *ngFor="let policy of drpPlan" [value]="policy.typeOfPlanName">
              {{policy.typeOfPlanName}}
            </mat-option>
          </mat-select>
          <button mat-button (click)="clearPlan()" (click)="plan=undefined;$event.stopPropagation()" *ngIf="plan"
            matSuffix mat-icon-button >
            <mat-icon>close</mat-icon>
          </button>
          <mat-error *ngIf="
        authorizationForm?.get('txtPlan')?.touched &&
        authorizationForm?.get('txtPlan')?.errors?.required
        ">
            Plan is a required field!
          </mat-error>
        </mat-form-field>
        <mat-form-field class="col-2">
          <mat-label> Auth ID <span class="asterisk">*</span></mat-label>
          <input autocomplete="new-txtAuthId" matInput formControlName="txtAuthId" maxlength="15" type="text">
          <mat-error *ngIf="
        authorizationForm?.get('txtAuthId')?.touched &&
        authorizationForm?.get('txtAuthId')?.errors?.required
        ">
            Auth ID is a required field!
          </mat-error>
        </mat-form-field>
        <!-- <mat-form-field class="col-2">
          <mat-label>Auth Visits </mat-label>
          <mat-select formControlName="drpAuthVisit" [(value)]="authVisit">
            <mat-option *ngFor="let visit of drpAuthVisit" [value]="visit.authVisitType">
              {{visit.authVisitType}}
            </mat-option>
          </mat-select>
          <button mat-button (click)="clearAuthVisit()" (click)="authVisit=undefined;$event.stopPropagation()"
            *ngIf="authVisit" matSuffix mat-icon-button >
            <mat-icon>close</mat-icon>
          </button>
        </mat-form-field> -->

        <mat-form-field class="col-2">
          <mat-label> Auth Visit</mat-label>
          <input autocomplete="new-drpAuthVisit" matInput formControlName="drpAuthVisit" maxlength="15" type="text" />
        </mat-form-field>

        <mat-form-field class="col-2">
          <mat-label>Auth Amount </mat-label>
          <input autocomplete="new-txtAuthAmount" matInput minlength="1" maxlength="15" prefix="$" mask="999999999999999" placeHolderCharacter=""
            formControlName="txtAuthAmount" type="text">
          <mat-error *ngIf="
        authorizationForm?.get('txtAuthAmount')?.touched &&
        authorizationForm?.get('txtAuthAmount')?.errors?.required
        ">
            Auth Amount is a required field!
          </mat-error>
          <mat-error *ngIf="
        authorizationForm?.get('txtAuthAmount')?.touched &&
        authorizationForm?.get('txtAuthAmount')?.errors?.number
        ">
            Enter only numbers!
          </mat-error>
        </mat-form-field>
        <mat-form-field class="col-2">
          <mat-label>Start Date</mat-label>
          <input autocomplete="new-txtStartDate" matInput formControlName="txtStartDate" maxlength="40" placeholder="(MM/DD/YYYY)"
            [formControl]="authorizationForm.controls.txtStartDate" [matDatepicker]="myPicker">
          <mat-datepicker-toggle matSuffix [for]="myPicker">
          </mat-datepicker-toggle>
          <mat-datepicker #myPicker></mat-datepicker>
          <mat-error *ngIf="
        authorizationForm?.get('txtStartDate')?.touched && authorizationForm?.get('txtStartDate')?.errors
        ">
            Enter Valid Date!
          </mat-error>
        </mat-form-field>
        <mat-form-field class="col-2">
          <mat-label>End Date</mat-label>
          <input autocomplete="new-txtEndDate" matInput formControlName="txtEndDate" maxlength="40" placeholder="(MM/DD/YYYY)"
            [formControl]="authorizationForm.controls.txtEndDate" [min]="authorizationForm.controls.txtStartDate.value"
            [matDatepicker]="myPicker1">
          <mat-datepicker-toggle matSuffix [for]="myPicker1">
          </mat-datepicker-toggle>
          <mat-datepicker #myPicker1></mat-datepicker>
          <mat-error *ngIf="
        authorizationForm?.get('txtEndDate')?.touched && authorizationForm?.get('txtEndDate')?.errors
        ">
            Enter Valid Date!
          </mat-error>
        </mat-form-field>
        <mat-form-field class="col-2">
          <mat-label>Category</mat-label>
          <mat-select maxlength="40" formControlName="drpCategory" [(value)]="category">
            <mat-option *ngFor="let category of drpCategory" [value]="category.authCategoryType">
              {{category.authCategoryType}}
            </mat-option>
          </mat-select>
          <button mat-button (click)="clearCategory()" (click)="category=undefined;$event.stopPropagation()"
            *ngIf="category" matSuffix mat-icon-button >
            <mat-icon>close</mat-icon>
          </button>
        </mat-form-field>
        <mat-form-field class="col-2">
          <mat-label>Item</mat-label>
          <mat-select multiple formControlName="drpItem" [(value)]="item" maxlength="40">
            <mat-option *ngFor="let item of drpAuthItem" [value]="item.itemId">
              {{item.itemIdOrBarCode+ "/"+ item.name}}
            </mat-option>
          </mat-select>
          <button mat-button (click)="clearItem()" (click)="item=undefined;$event.stopPropagation()" *ngIf="item"
            matSuffix mat-icon-button >
            <mat-icon>close</mat-icon>
          </button>
        </mat-form-field>
        <mat-form-field class="col-2">
          <mat-label>Note </mat-label>
          <textarea rows="1" maxlength="5000" matInput [(ngModel)]="notesText"
          (input)="updateCharacterCount()" formControlName="txtNote"></textarea> <div class="char-count">
            {{ notesText?.length }} / 5000  
          </div>

        </mat-form-field>
        <mat-checkbox class="col-2 pt-3" formControlName="chkCompleted">Completed</mat-checkbox>
        <!-- <div class="col-md-2">
          <div class=" chk custom-control custom-checkbox mb-3">
            <br>
            <mat-checkbox formControlName="chkCompleted">Completed</mat-checkbox>
          </div>
        </div>
        <br> -->
        <mat-form-field class="col-2">
          <mat-label>Completed By</mat-label>
          <input autocomplete="new-txtCompletedBy" matInput maxlength="40" formControlName="txtCompletedBy" type="text">
          <mat-error *ngIf="
            authorizationForm?.get('txtCompletedBy')?.touched &&
            authorizationForm?.get('txtCompletedBy')?.errors?.sAlphabets
          ">
            Enter only Alphabets!
          </mat-error>
        </mat-form-field>
        <mat-form-field class="col-2">
          <mat-label> Units</mat-label>
          <input autocomplete="new-txtUnits" matInput formControlName="txtUnits" maxlength="15" type="text" />
        </mat-form-field>
        <mat-form-field class="col-2">
          <mat-label> Used </mat-label>
          <input autocomplete="new-txtUsed" matInput formControlName="txtUsed" maxlength="15" type="text" />
        </mat-form-field>
        <mat-form-field class="col-2">
          <mat-label>Sale/Rent</mat-label>
          <mat-select maxlength="40" [(value)]="sale" formControlName="txtSaleOrRent">
            <mat-option *ngFor="let data of drpSaleOrRents" [value]="data.saleOrRent">
              {{ data.saleOrRent }}
            </mat-option>
          </mat-select>
          <button mat-button (click)="sale = undefined; $event.stopPropagation()" *ngIf="sale" matSuffix
            (click)="clearSale()" mat-icon-button >
            <mat-icon>close</mat-icon>
          </button>
        </mat-form-field>
        <mat-form-field class="col-2">
          <mat-label>Codes</mat-label>
          <mat-select maxlength="40" [(value)]="itemCodes" formControlName="txtItemCodes">
            <mat-option *ngFor="let data of drpItemCdoes" [value]="data">
              {{ data }}
            </mat-option>
          </mat-select>
          <button mat-button (click)="itemCodes = undefined; $event.stopPropagation()" *ngIf="itemCodes" matSuffix
            (click)="clearItemCodes()" mat-icon-button >
            <mat-icon>close</mat-icon>
          </button>
        </mat-form-field>
        <mat-checkbox class="col-2 pt-3" formControlName="txtExpired">Expired</mat-checkbox>
        <mat-checkbox class="col-2 pt-3" formControlName="chkFollowUp">FollowUp</mat-checkbox>

        <mat-form-field *ngIf="Show" class="col-2">
          <mat-label>Reason</mat-label>
          <input autocomplete="new-txtReason" matInput maxlength="100" formControlName="txtReason" type="text">
        </mat-form-field>
      </div>
      <div *ngIf="isStatusShow" class="row col-12">
        <hr>
        <div class="col-2">
          <h3 class="customThemeClass"><b>Status <span class="asterisk">*</span></b></h3>
        </div>
        <div class="col-7">
          <mat-radio-group (change)="onChangeGetReason($event)" formControlName="rbtnAuth"
            class="col-sm-7 radio mt-2 mr-2 txt-left">
            <mat-radio-button class="mr-2" value="Approved">Approve</mat-radio-button>
            <mat-radio-button class="mr-2" value="Disapproved">Disapprove</mat-radio-button>
          </mat-radio-group>
        </div>
        <div class="col-sm-3">
          <div class="text-lg-right">
            <button *ngIf="orderStatus" mat-button class="buttonColor  mb-2 mr-2"
              (click)="getOrderDetailsForSave(this.authorizationForm.value.rbtnAuth)"
              [disabled]="!(!authorizationForm.invalid && (authorizationForm.dirty ))">Save</button>
            <button mat-button class="resetclr mb-2 mr-2" (click)="clearData()">Reset</button>
          </div>
        </div>
      </div>
    </form>
  </mat-card-content>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <div *ngIf="!isStatusShow">
    <button *ngIf="orderStatus" mat-button class="buttonColor" (click)="getOrderDetailsForSave(null)"
      [disabled]=" saveButtonHide || !(!authorizationForm.invalid && (authorizationForm.dirty ))">Save
      <mat-icon *ngIf='isShowSpinner'>
        <mat-spinner class="spinner-border spinner-border-sm" diameter="20"> </mat-spinner>
      </mat-icon>
    </button>
    <button mat-button class="resetclr" (click)="clearData()">Reset</button>
    <button class="buttonCancelColor" mat-button mat-dialog-close>Close</button>
  </div>
</mat-dialog-actions>
