import type {
  CreateUpdateWorkInProgressDTO,
  WIPList,
  WorkInProgressDTO,
  clsWipSearchDTO,
  clsWipStatus,
  clsAuthSearchDTO,
  WIPHistoryDTO,
} from './dto/models';
import { RestService } from '@abp/ng.core';
import type {
  PagedAndSortedResultRequestDto,
  PagedResultDto,
} from '@abp/ng.core';
import { Injectable } from '@angular/core';
import { wipUserTaskFilter } from './wip-user-task-filter.enum';

@Injectable({
  providedIn: 'root',
})
export class WorkInProgressService {
  apiName = 'platformManagement';

  create = (workInProgressDetails: CreateUpdateWorkInProgressDTO) =>
    this.restService.request<any, WorkInProgressDTO>(
      {
        method: 'POST',
        url: '/api/PlatformApp/work-in-progress',
        body: workInProgressDetails,
      },
      { apiName: this.apiName }
    );

  delete = (id: string) =>
    this.restService.request<any, void>(
      {
        method: 'DELETE',
        url: `/api/PlatformApp/work-in-progress/${id}`,
      },
      { apiName: this.apiName }
    );

  get = (id: string) =>
    this.restService.request<any, WorkInProgressDTO>(
      {
        method: 'GET',
        url: `/api/PlatformApp/work-in-progress/${id}`,
      },
      { apiName: this.apiName }
    );

  getList = (input: PagedAndSortedResultRequestDto) =>
    this.restService.request<any, PagedResultDto<WorkInProgressDTO>>(
      {
        method: 'GET',
        url: '/api/PlatformApp/work-in-progress',
        params: {
          skipCount: input.skipCount,
          maxResultCount: input.maxResultCount,
          sorting: input.sorting,
        },
      },
      { apiName: this.apiName }
    );

  update = (id: string, workInProgressDetails: CreateUpdateWorkInProgressDTO) =>
    this.restService.request<any, WorkInProgressDTO>(
      {
        method: 'PUT',
        url: `/api/PlatformApp/work-in-progress/${id}`,
        body: workInProgressDetails,
      },
      { apiName: this.apiName }
    );

  wipTaskListByUserId = () =>
    this.restService.request<any, WIPList[]>(
      {
        method: 'POST',
        url: '/api/PlatformApp/work-in-progress/wip-task-list-by-user-id',
      },
      { apiName: this.apiName }
    );

  wipTaskList = (userId: string, wipSearch: clsWipSearchDTO) =>
    this.restService.request<any, WIPList[]>(
      {
        method: 'POST',
        url: `/api/PlatformApp/work-in-progress/wip-task-list/${userId}`,
        body: wipSearch,
      },
      { apiName: this.apiName }
    );
  wipTaskListByUserIdAndFilterAndWipSearch = (
    userId: string,
    filter: wipUserTaskFilter,
    wipSearch: clsWipSearchDTO
  ) =>
    this.restService.request<any, WIPList[]>(
      {
        method: 'POST',
        url: `/api/PlatformApp/work-in-progress/wip-task-list/${userId}`,
        params: { filter },
        body: wipSearch,
      },
      { apiName: this.apiName }
    );
  scheduledTaskListByUserId = (userId: string) =>
    this.restService.request<any, WIPList[]>(
      {
        method: 'GET',
        url: `/api/PlatformApp/work-in-progress/get-scheduled-task-list-by-user/${userId}`,
      },
      { apiName: this.apiName }
    );
  updateWipStatus = (wipId: string) =>
    this.restService.request<any, string>(
      {
        method: 'PUT',
        url: `/api/PlatformApp/work-in-progress/update-wIPStatus/${wipId}`,
      },
      { apiName: this.apiName }
    );

  getWIPHistoryDetails = (WIPID: string) =>
    this.restService.request<any, WIPHistoryDTO[]>(
      {
        method: 'GET',
        url: `/api/PlatformApp/w-iPHistory/get-wip-history/${WIPID}`,
      },
      { apiName: this.apiName }
    );
  getWipStatus = () =>
    this.restService.request<any, clsWipStatus[]>(
      {
        method: 'GET',
        url: `/api/PlatformApp/work-in-progress/get-wip-status`,
      },
      { apiName: this.apiName }
    );

    getAllWipStatus = () =>
    this.restService.request<any, clsWipStatus[]>(
      {
        method: 'GET',
        url: `/api/PlatformApp/work-in-progress/get-wip-status`,
      },
      { apiName: this.apiName }
    );

  getAuthNewSetup = (AuthSearch: clsAuthSearchDTO) =>
    this.restService.request<any, WIPList[]>(
      {
        method: 'POST',
        url: `/api/PlatformApp/work-in-progress/auth-wip-task-list`,
        body: AuthSearch,
      },
      { apiName: this.apiName }
    );

  updateAuthNewSetupWipLock = (wipId: any) =>
    this.restService.request<any, WIPList>(
      {
        method: 'POST',
        url: `/api/PlatformApp/work-in-progress/update-wIPLock/${wipId}`,
      },
      { apiName: this.apiName }
    );

  constructor(private restService: RestService) {}
}
