<form [formGroup]="eobSearchForm">
  <!-- Payer -->
  <mat-form-field class="col-1_5" style="max-width: 100px !important">
    <mat-label class="drpLabel"
      >Payer
      <ng-container *ngIf="ltSelectedPayorList?.length > 0"
        >({{ ltSelectedPayorList?.length }})</ng-container
      >
    </mat-label>
    <mat-select
      multiple
      [(ngModel)]="ltSelectedPayorList"
      (closed)="handlePayorSelectionChange(eobSearchForm.get('drpPayer').value)"
      (selectionChange)="onSelectionPayorChange($event)"
      formControlName="drpPayer"
    >
      <mat-option style="font-size: 12px">
        <ngx-mat-select-search
          placeholderLabel="Search"
          noEntriesFoundLabel="No Matches found"
          hideClearSearchButton="true"
          (keyup)="searchPayor($event.target.value)"
          formControlName="txtPayerFilter"
        ></ngx-mat-select-search>
      </mat-option>

      <mat-option
        style="font-size: 12px"
        *ngIf="!payorSelectAllOption"
        [value]="'selectAll'"
        class="drpOptionFontSize"
        >Select All</mat-option
      >
      <mat-option
        style="font-size: 12px"
        *ngIf="payorSelectAllOption"
        [value]="'DeselectAll'"
        class="drpOptionFontSize"
        >Deselect All</mat-option
      >

      <mat-option
        style="font-size: 12px"
        [value]="data.payerId"
        [ngClass]="{ inActiveRecord: data?.bActive == false }"
        [matTooltip]="
          data?.bActive == false ? 'Inactive Record' : data?.payerName
        "
        *ngFor="let data of filteredPayors | async"
        >{{ data.payerName }}</mat-option
      >
    </mat-select>

    <button
      mat-icon-button
      matSuffix
      *ngIf="ltSelectedPayorList?.length > 0"
      (click)="
        ltSelectedPayorList = [];
        payorSelectAllOption = false;
        eobSearchForm.get('drpPayer').setValue([]);
        buildFilterParams();
        $event.stopPropagation()
      "
    >
      <mat-icon>close</mat-icon>
    </button>
  </mat-form-field>

  <!-- Status-->
  <mat-form-field class="col-2" style="max-width: 100px !important">
    <mat-label class="drpLabel">Status</mat-label>
    <mat-select multiple (selectionChange)="onSelectionStatusChange($event)"
      formControlName="drpStatus" (closed)="handleStsCloseDrpdown(eobSearchForm.get('drpStatus').value)"
    >
      <mat-option style="font-size: 12px">
        <ngx-mat-select-search
          placeholderLabel="Search"
          noEntriesFoundLabel="No Matches found"
          hideClearSearchButton="true"
          formControlName="txtStatusFilter"
        ></ngx-mat-select-search>
      </mat-option>
      <mat-option *ngIf="!statusSelectAllOption" [value]="'selectAll'" class="drpOptionFontSize">Select All</mat-option>
      <mat-option *ngIf="statusSelectAllOption" [value]="'DeselectAll'" class="drpOptionFontSize">Deselect All</mat-option>
      <mat-option style="font-size: 12px" *ngFor="let data of filteredStatuses | async" [value]="data.statusId">
        {{ data.statusName }}
      </mat-option>
    </mat-select>
    <button
      mat-button
      *ngIf="
        eobSearchForm.get('drpStatus').value != '' &&
        eobSearchForm.get('drpStatus').value != null &&
        eobSearchForm.get('drpStatus').value != undefined
      "
      (click)="clearStatus(); $event.stopPropagation(); buildFilterParams()"
      matSuffix
      mat-icon-button
    >
      <mat-icon style="font-size: 16px !important">close</mat-icon>
    </button>
  </mat-form-field>

  <!-- EFT/Check Number -->
  <mat-form-field class="col-1_5 drpLabel" style="min-width: 130px !important">
    <input
      matInput
      (change)="buildFilterParams()"
      formControlName="txtCheckNumber"
      placeholder="EFT/Check Number"
      maxlength="75"
  /></mat-form-field>

  <!-- Amount -->
  <mat-form-field class="drpLabel col-1_5 ml-3">
    <input
      matInput
      (change)="buildFilterParams()"
      formControlName="txtAmount"
      placeholder="Amount"
      type="text"
      (keypress)="commonService.onKeyPressDecimal($event)"
      maxlength="15"
  /></mat-form-field>

  <!-- Remittance Date -->
  <mat-form-field class="col-1_5 dd-text customWidth ml-3">
    <mat-label class="drpLabel">Remittance Date</mat-label>
    <mat-date-range-input [rangePicker]="pickerRemittance">
      <input
        matStartDate
        (keydown)="addSlashToDate($event)"
        matInput
        formControlName="txtRemittanceStartDate"
        placeholder="Start Date"
        maxlength="10"
      />

      <input
        matEndDate
        matInput
        (dateChange)="onRemittanceDateChange($event)"
        (keydown)="addSlashToDate($event)"
        formControlName="txtRemittanceEndDate"
        placeholder="End date"
        maxlength="10"
      />
    </mat-date-range-input>
    <mat-datepicker-toggle
      matSuffix
      [for]="pickerRemittance"
    ></mat-datepicker-toggle>
    <mat-date-range-picker #pickerRemittance></mat-date-range-picker>
    <button
      mat-button
      *ngIf="
        (eobSearchForm.get('txtRemittanceStartDate').value != '' &&
          eobSearchForm.get('txtRemittanceStartDate').value != null &&
          eobSearchForm.get('txtRemittanceStartDate').value != undefined) ||
        (eobSearchForm.get('txtRemittanceEndDate').value != '' &&
          eobSearchForm.get('txtRemittanceEndDate').value != null &&
          eobSearchForm.get('txtRemittanceEndDate').value != undefined)
      "
      (click)="
        clearRemittanceDate(); $event.stopPropagation(); buildFilterParams()
      "
      matSuffix
      mat-icon-button
    >
      <mat-icon style="font-size: 16px !important">close</mat-icon>
    </button>
  </mat-form-field>

  <!-- Created Date -->
  <mat-form-field class="col-1_5 dd-text customWidth ml-3">
    <mat-label class="drpLabel">Created Date</mat-label>
    <mat-date-range-input [rangePicker]="picker">
      <input
        matStartDate
        matInput
        (keydown)="addSlashToDate($event)"
        placeholder="Start date"
        formControlName="txtCreatedStartDate"
      />

      <input
        matEndDate
        (dateChange)="onCreatedDateChange($event)"
        (keydown)="addSlashToDate($event)"
        matInput
        placeholder="End date"
        formControlName="txtCreatedEndDate"
      />
    </mat-date-range-input>
    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
    <mat-date-range-picker #picker></mat-date-range-picker>
    <button
      mat-button
      *ngIf="
        (eobSearchForm.get('txtCreatedStartDate').value != '' &&
          eobSearchForm.get('txtCreatedStartDate').value != null &&
          eobSearchForm.get('txtCreatedStartDate').value != undefined) ||
        (eobSearchForm.get('txtCreatedEndDate').value != '' &&
          eobSearchForm.get('txtCreatedEndDate').value != null &&
          eobSearchForm.get('txtCreatedEndDate').value != undefined)
      "
      (click)="
        clearCreatedDate(); $event.stopPropagation(); buildFilterParams()
      "
      matSuffix
      mat-icon-button
    >
      <mat-icon style="font-size: 16px !important">close</mat-icon>
    </button>
  </mat-form-field>
  <button
    type="button"
    (click)="resetForm()"
    matTooltip="Reset"
    mat-raised-button
    class="reset-icon-button ml-4 mat-raised-small-button"
  >
    <mat-icon class="fg-white">refresh</mat-icon>
  </button>
</form>
<div class="d-flex justify-content-center" *ngIf="isLoading">
  <img class="img-responsive pb-2 mr-1" src="assets/gifs/Infinity-loader.gif" />
</div>

<table
  *ngIf="!isLoading"
  datatable
  [dtOptions]="eobTable"
  class="row-border hover w-100 display dataTables_scroll"
>
  <thead>
    <tr>
      <th [matTooltip]="'Options'"><mat-icon>apps</mat-icon></th>
      <th [matTooltip]="'EFT/Check Number'">EFT/Check Number</th>
      <th [matTooltip]="'Payer ID'">Payer ID</th>
      <th [matTooltip]="'Payer'">Payer</th>
      <th [matTooltip]="'Amount($)'">Amount($)</th>
      <th [matTooltip]="'Payment Method'">Payment Method</th>
      <th [matTooltip]="'Remittance Date'">Remittance Date</th>
      <th [matTooltip]="'Created By'">Created By</th>
      <th [matTooltip]="'Created Date'">Created Date</th>
      <th [matTooltip]="'Last Modified By'">Last Modified By</th>
      <th [matTooltip]="'Last Modified Date'">Last Modified Date</th>
      <th [matTooltip]="'Status'">Status</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let data of inBoundTableData">
      <td>
        <mat-icon
          class="pointer toggle-icon"
          (click)="getTotalInboundData($event, data)"
          >add</mat-icon
        >

        <mat-icon style="cursor: pointer !important" [matMenuTriggerFor]="menu"
          >more_vert</mat-icon
        >
        <mat-menu class="custom-mat-menu" #menu="matMenu">
          <button
            mat-menu-item
            [disabled]="data.sEOBBlob == '' || data.sEOBBlob == null"
            (click)="downloadEOB(data.sEOBBlob, data.sCheckNumber)"
          >
            Download EOB
          </button>
          <button
            mat-menu-item
            [disabled]="data.sERABlob == '' || data.sERABlob == null"
            (click)="downloadEOB(data.sERABlob, data.sCheckNumber)"
          >
            Download ERA
          </button>
        </mat-menu>
      </td>
      <td
        (dblclick)="onRowDoubleClick(data.sCheckNumber || '-')"
        [matTooltip]="data.sCheckNumber || '-'"
      >
        <!-- {{ data.sCheckNumber || "-" }} -->
        <a
          [routerLink]="
            '/patient_preview_document/' +
            data.sEOBBlob +
            '/' +
            data.sCheckNumber +
            '/' +
            2
          "
          target="_blank"
          class="preview-link"
        >
          {{ data?.sCheckNumber || "-" }}
        </a>
      </td>
      <td
        (dblclick)="onRowDoubleClick(data.sPayerCode || '-')"
        [matTooltip]="data.sPayerCode || '-'"
      >
        {{ data.sPayerCode || "-" }}
      </td>
      <td
        (dblclick)="onRowDoubleClick(data.sPayerName || '-')"
        [matTooltip]="data.sPayerName || '-'"
      >
        {{ data.sPayerName || "-" }}
      </td>

      <td
        (dblclick)="onRowDoubleClick(data.dCheckAmount || '-')"
        class="text-right"
        [matTooltip]="data.dCheckAmount || '-'"
      >
        {{ data.dCheckAmount || "-" }}
      </td>
      <td
        (dblclick)="onRowDoubleClick(data.paymentMethodName || '-')"
        [matTooltip]="data.paymentMethodName || '-'"
      >
        {{ data.paymentMethodName || "-" }}
      </td>
      <td
        (dblclick)="onRowDoubleClick(data.dtRemitance || '-')"
        [matTooltip]="data.dtRemitance || '-'"
      >
        {{ data.dtRemitance || "-" }}
      </td>
      <td
        (dblclick)="onRowDoubleClick(data.createdByName || '-')"
        [matTooltip]="data.createdByName || '-'"
      >
        {{ data.createdByName || "-" }}
      </td>
      <td
        (dblclick)="onRowDoubleClick(data.dtCreated || '-')"
        [matTooltip]="data.dtCreated || '-'"
      >
        {{ data.dtCreated || "-" }}
      </td>
      <td [matTooltip]="data.modifiedByName || '-'">
        {{ data.modifiedByName || "-" }}
      </td>
      <td [matTooltip]="data.dtModified || '-'">
        {{ data.dtModified || "-" }}
      </td>

      <td
        (dblclick)="onRowDoubleClick(data.eobStatusName || '-')"
        [matTooltip]="data.eobStatusName"
        class="text-center"
      >
        <div class="d-flex align-items-center">
          <span
            *ngIf="data.eobStatusName === 'Open'"
            [ngStyle]="{
              'background-color': '#16aeff',
              color: '#ffffff'
            }"
            class="rounded-border ml-1 w-75 p-1 claim-status-label"
          >
            {{ data.eobStatusName }}
          </span>
          <span
            *ngIf="data.eobStatusName === 'Processed'"
            [ngStyle]="{
              'background-color': '#7e7474',
              color: '#ffffff'
            }"
            class="rounded-border ml-1 w-75 p-1 claim-status-label"
          >
            {{ data.eobStatusName }}
          </span>
        </div>
      </td>
    </tr>
  </tbody>
</table>
<app-loader-component *ngIf="isDataLoaded"></app-loader-component>
