<br />
<div class="card card-body">
  <!-- <div class="list-box"> -->
  <div class="mt-0">
    <mat-card-content>
      <div class="row mar-top">
        <div class="col-6">
          <div class="text-left">
            <h4 class="customThemeClass"><b>Notes</b></h4>
          </div>
        </div>
        <div class="col-6">
          <div class="text-right">
            <!-- <button type="button" (click)="openNoteModal()"
            class="btn btn-primary buttonColor  waves-effect waves-light mb-2">
            <i class="mdi mdi-plus mr-1"></i>Add New Note
          </button> -->
            <button *ngIf='isPatientLockedByCurrentUser' mat-button (click)="openNoteModal()"
              class="buttonColor font-size-14">
              <!-- <i class="mdi mdi-plus mr-1"></i> -->
              <i class="fa fa-plus mr-1"></i>
              Add Note
            </button>
          </div>
        </div>
      </div>

      <!-- <app-table [strPageType]="strPageType" (viewButton)="ViewNotes($event)" (deleteButton)="deleteNote($event)"></app-table> -->
      <div class="row mt-1">
        <div class="col-lg-12">
          <form [formGroup]="notesForm">
            <!-- <div [hidden]="!isLoading"
              style="display: flex; justify-content: center; align-items: center; background: transparent;">
              <mat-progress-bar mode="indeterminate" value='66' diameter="45" class="lightseagreen">
              </mat-progress-bar>
            </div> -->
            <div class="table table-responsive">

              <table mat-table [dataSource]="dataSource"   #noteTbSort="matSort"  matSort class="mat-elevation-z8">
                <!-- Note Id Column -->
                <ng-container matColumnDef="defaultNotesId">
                  <th class="header" mat-header-cell mat-sort-header *matHeaderCellDef>
                    <mat-form-field class="filter">
                      <input autocomplete="new-defaultNotesId" matInput formControlName="defaultNotesId" />
                      <mat-placeholder>Note Id</mat-placeholder>
                    </mat-form-field>
                  </th>
                  <td mat-cell *matCellDef="let element">
                    <span matTooltip="{{element?.defaultNotesId}}" matTooltipClass="custom-tooltip-font"> {{
                      element?.defaultNotesId }}</span>
                  </td>
                </ng-container>
                <!-- Created By  Column -->
                <ng-container matColumnDef="createdBy">
                  <th class="header" mat-header-cell mat-sort-header *matHeaderCellDef>
                    <mat-form-field class="filter">
                      <input autocomplete="new-createdBy" matInput formControlName="createdBy" />
                      <mat-placeholder>Created By</mat-placeholder>
                    </mat-form-field>
                  </th>
                  <td mat-cell *matCellDef="let element"> {{ element?.createdBy }}</td>
                </ng-container>

                <!-- Date Created -->
                <ng-container matColumnDef="dateCreated">
                  <th class="header" fxFlex="200" mat-header-cell mat-sort-header *matHeaderCellDef>
                    <mat-form-field class="filter">
                      <mat-label> Date Created </mat-label>
                      <input autocomplete="new-dateCreated" matInput formControlName="dateCreated" placeholder="(MM/DD/YYYY)" />
                      <mat-error
                        *ngIf=" notesForm?.get('dateCreated')?.touched && notesForm?.get('dateCreated')?.errors?.dateVaidator">
                        Enter Valid date
                      </mat-error>
                    </mat-form-field>
                  </th>
                  <td fxFlex="200" mat-cell *matCellDef="let element"> <span class="ellipsis .cut-text">
                      {{ element?.dateCreated| date:'MM/dd/yyyy h:mm a' :'en_US'}} </span>
                  </td>
                </ng-container>
                <!-- Actual Date -->
                <ng-container matColumnDef="actualDate">
                  <th class="header  w-175" mat-header-cell mat-sort-header *matHeaderCellDef>
                    <mat-form-field class="filter">
                      <mat-label>Actual Date </mat-label>
                      <input autocomplete="new-actualDate" matInput formControlName="actualDate" placeholder="(MM/DD/YYYY)" />
                      <mat-error
                        *ngIf=" notesForm?.get('actualDate')?.touched && notesForm?.get('actualDate')?.errors?.dateVaidator">
                        Enter Valid date
                      </mat-error>
                    </mat-form-field>
                  </th>
                  <td mat-cell *matCellDef="let element">
                    {{ element?.actualDate| date:'MM/dd/yyyy h:mm a' :'en_US'}}
                  </td>
                </ng-container>

                <!-- Reason -->
                <ng-container matColumnDef="noteReason">

                  <th class="header" mat-header-cell mat-sort-header *matHeaderCellDef>
                    <mat-form-field class="filter">
                      <input autocomplete="new-noteReason" matInput formControlName="noteReason" />
                      <mat-placeholder>Reason</mat-placeholder>
                    </mat-form-field>
                  </th>
                  <td mat-cell *matCellDef="let element">
                    <div class='warppable'>
                      {{element?.noteReason}}
                    </div>
                  </td>
                </ng-container>
                <!--  Date Needed -->
                <ng-container matColumnDef="dateNeeded">

                  <th class="header" class="w-125" mat-header-cell mat-sort-header *matHeaderCellDef>
                    <mat-form-field class="filter">
                      <mat-label> Date Needed </mat-label>
                      <input autocomplete="new-dateNeeded" matInput formControlName="dateNeeded" placeholder="(MM/DD/YYYY)" />
                      <mat-error
                        *ngIf=" notesForm?.get('dateNeeded')?.touched && notesForm?.get('dateNeeded')?.errors?.dateVaidator">
                        Enter Valid date
                      </mat-error>
                    </mat-form-field>
                  </th>
                  <td mat-cell *matCellDef="let element">
                    {{ element?.dateNeeded| date:'MM/dd/yyyy h:mm a' :'en_US'}}
                    </td>
                </ng-container>
                <!--  Status -->
                <ng-container matColumnDef="status">
                  <th class="header" mat-header-cell mat-sort-header *matHeaderCellDef>
                    <mat-form-field class="filter">
                      <input autocomplete="new-status" matInput formControlName="status" />
                      <mat-placeholder>Status</mat-placeholder>
                    </mat-form-field>
                  </th>
                  <td mat-cell *matCellDef="let element">{{ element?.status }}</td>
                </ng-container>

                <ng-container matColumnDef="subject">
                  <th class="header" mat-header-cell mat-sort-header *matHeaderCellDef>
                    <mat-form-field class="filter">
                      <input autocomplete="new-subject" matInput formControlName="subject" />
                      <mat-placeholder> Subject
                      </mat-placeholder>
                    </mat-form-field>
                  </th>
                  <td mat-cell *matCellDef="let element">{{ element?.subject }}</td>
                </ng-container>
                <ng-container matColumnDef="Options">
                  <th class="header" mat-header-cell  *matHeaderCellDef>Options</th>
                  <td mat-cell *matCellDef="let element" class="text-center">
                    <a *ngIf='isShownUpdateIconForNotes' (click)="ViewNotes(element?.notesId)"
                      matTooltip="Click To View Notes">
                      <i class="fa fa-eye pointer editBtnColor pl-3"></i>
                    </a>
                    <!-- <a *ngIf='isDeleteForNotes' (click)="deleteNotes(element?.notesId)"
                      matTooltip="Click To Delete Notes">
                      <i class="fa fa-trash pointer color deleteBtnColor pl-3" ></i>
                    </a> -->


                  </td>
                </ng-container>
                <!-- Note Type -->
                <ng-container matColumnDef="noteType">

                  <th class="header" mat-header-cell mat-sort-header *matHeaderCellDef>
                    <mat-form-field class="filter">
                      <input autocomplete="new-noteType" matInput formControlName="noteType" />
                      <mat-placeholder>Note Type</mat-placeholder>
                    </mat-form-field>
                  </th>
                  <td mat-cell *matCellDef="let element">{{ element?.noteType }}</td>
                </ng-container>
                <ng-container matColumnDef="noRecords">
                  <td class="text-align-center" mat-footer-cell *matFooterCellDef colspan="10">
                    <div *ngIf="!isLoading">
                      {{"No Records Found"}}
                    </div>
                    <div [hidden]="!isLoading">
                      <mat-spinner [diameter]="220"></mat-spinner>
                    </div>
                  </td>
                </ng-container>
                <ng-container matColumnDef="paginator">
                  <td mat-footer-cell *matFooterCellDef colspan="10">
                    <mat-paginator class="table table-responsive" #MatPaginator [length]="dataSource?.data?.length"
                      [pageSize]="10" [pageIndex]="0" showFirstLastButtons [pageSizeOptions]="[5, 10, 25, 100]">
                    </mat-paginator>
                  </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
                <tr mat-footer-row *matFooterRowDef="['noRecords']"
                  [ngClass]="{ active: dataSource && dataSource.data && dataSource?.data?.length !== 0 }"></tr>
                <tr mat-footer-row *matFooterRowDef="['paginator']"></tr>
              </table>
            </div>
          </form>
        </div>
      </div>
    </mat-card-content>

  </div>
  <!-- </div> -->
</div>
