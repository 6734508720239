import { ListService, PagedAndSortedResultRequestDto } from '@abp/ng.core';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { DatePipe } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import {
  Component,
  EventEmitter,
  HostListener,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { MatSelect, MatSelectChange } from '@angular/material/select';
import { Store } from '@ngxs/store';
import { TableService } from 'projects/shared/src/app/table.service';
import { NumberValidators } from 'projects/shared/src/app/validations/number-validator';
import { TextValidators } from 'projects/shared/src/app/validations/text-validator';
import { Observable, Subscription } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import Swal from 'sweetalert2';

import {
  RegionCitiesDTO,
  RegionCountriesDTO,
  RegionStatesDTO,
} from '../dropdown-proxy/dropdown-management/dropdowns/dto/models';
import {
  ClaimFillingCodeDTO,
  GenderDTO,
  InsuranceTypeDTO,
  PatientMasterDropdownDTO,
  PayorLevelDTO,
  RelationShipWithoutSelfDTO,
} from '../dropdown-proxy/dropdown-management/dropdowns/dto/patient/models';
import { PatientMasterDropdownService } from '../dropdown-proxy/dropdown-management/dropdowns/master-dropdown-service/patient-master-dropdown.service';
import { PatientDropdowns } from '../dropdown-proxy/dropdown-management/dropdowns/patient-dropdowns.enum';
import { RegionDropdownService } from '../dropdown-proxy/dropdown-management/dropdowns/region-dropdown-service/region-dropdown.service';
import { PatientEligibilityHistoryComponent } from '../patient-eligibility-history/patient-eligibility-history.component';
import { PatientService } from '../patient-proxy/patient';
import {
  AddressDTO,
  CreateUpdatePatientIntakeDTO,
  PatientDTO,
  PayerDTO,
  PersonalDTO,
  PoliciesDTO,
  PolicyDTO,
} from '../patient-proxy/patient/dto/models';
import { PayerService } from '../patient-proxy/patient/payer.service';
import { PverifyPayersService } from '../patient-proxy/patient/pverify-payers.service';
import { PatientPverifyComponent } from '../patient-pverify/patient-pverify.component';
import { updatePatientIntake } from '../patient-store/patient.action';
import { PatientVerificationComponent } from '../patient-verification/patient-verification.component';
import { ToastrService } from "ngx-toastr";
export interface Test {
  value: string;
  viewValue: string;
}
@Component({
  selector: 'app-patient-add-policy',
  templateUrl: './patient-add-policy.component.html',
  styleUrls: ['./patient-add-policy.component.scss'],
  providers: [ListService, DatePipe],
})
export class PatientAddPolicyComponent implements OnInit, OnDestroy {
  @HostListener('input', ['$event']) onInput(event: Event) {
    const input = event.target as HTMLInputElement;

    // Check if the input field has the class 'zip-code-input'
    if (input.classList.contains('zip-code-input')) {
        let formattedValue = input.value.replace(/[^0-9]/g, '');

        if (formattedValue.length > 5) {
            formattedValue = formattedValue.slice(0, 5) + '-' + formattedValue.slice(5);
        }

        input.value = formattedValue;
    }
}
  @Output() patientIdEmitter: EventEmitter<PatientDTO> =
    new EventEmitter<PatientDTO>();
  policyModalTitle: string = '';
  payorLevel: PayorLevelDTO[] = [];
  relationshipLoop: RelationShipWithoutSelfDTO[] = [];
  drpclaimLoop: ClaimFillingCodeDTO[] = [];
  drpgenderLoop: GenderDTO[] = [];
  drpInsurance: InsuranceTypeDTO[] = [];
  policyForm: FormGroup;
  @Input() patientId: string = '';
  orderID: string = '';
  drpPlan: planInterface[] = [];
  drpAssignment: any[];
  BirthdateMaxDate: Date;
  phoneCode: string = '';
  empPhoneCode: string = '';
  organizationUnitId: string = '';

  tblPolicesReponse: any[] = [];
  CountryName: string = '';
  StateName: string = '';
  CountryEmpName: string = '';
  StateEmpName: string = '';
  drpPayerList: PayerDTO[] = [];
  payorlevel: string;
  payorInsurance: string;
  relationship: string;
  claimFillingCode: string;
  assignment: string;
  gender: string;
  drpPayerId: { id: string; name: string; payerCode: string }[] = [];
  BirthdateMinDate: Date;
  policiesId: string = '00000000-0000-0000-0000-000000000000';
  saveButtonHide: boolean;
  isShowSpinner: boolean = false;
  viewport1: CdkVirtualScrollViewport;
  viewport2: CdkVirtualScrollViewport;
  // isShownManualEligibilitycheckButton: boolean = true;
  subscription$: Subscription[] = [];

  constructor(
    public dialog: MatDialog,
    private listService: ListService,
    private formBuilder: FormBuilder,
    private store: Store,
    private textValidators: TextValidators,
    private numberValidators: NumberValidators,
    private Table: TableService,
    private planService: PverifyPayersService,
    private toastr: ToastrService,
    public policyDialogRef: MatDialogRef<PatientAddPolicyComponent>,
    private region: RegionDropdownService,
    private payerService: PayerService,
    private patientDropdownService: PatientMasterDropdownService,
    private patientService: PatientService,
    @Inject(MAT_DIALOG_DATA)
    public data: { patientId: string; policyId?: PolicyDTO }
  ) { }

  isOrderCreatePermission: boolean = true;
  isOrderViewPermission: boolean = true;
  isShownButton: boolean = true;
  isShownEligibilityButtton: boolean = true;

  isShownPlanCreateButton: boolean = true;
  isShownPlanViewDropdown: boolean = true;

  sPolicyAddedName: string = '';
  sPolicyLastEditedName: string = '';
  myDate = new Date().toLocaleString('en-US');
  sLoginUserName: string = '';
  isPatientLockedByCurrentUser: boolean = true;
  strLoginUserId: string = '';

  ngOnDestroy(): void {
    this.subscription$?.forEach((sub) => {
      sub && sub?.unsubscribe();
    });
  }

  ngOnInit(): void {
    const tableSubscription = this.Table.getLoginUserId().subscribe((val) => {
      this.strLoginUserId = val;
    });
    this.subscription$.push(tableSubscription);
    //
    // this.organizationUnitId = localStorage.getItem('initialOrganizationUnitId');
    this.getPolicyDropdown();
    this.countryDropdown();
    this.countryEmpDropdown();
    const organizationUnitId = this.Table.getOrganizationUnitId().subscribe(
      (val) => {
        this.organizationUnitId = val;
      },
      (err) => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      }
    );
    this.subscription$.push(organizationUnitId);
    //
    const getLoginSubscription = this.Table.getLoginUserName().subscribe(
      (val) => {
        this.sLoginUserName = val + ' ' + 'on' + ' ' + this.myDate;
      },
      (err) => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      }
    );
    this.subscription$.push(getLoginSubscription);
    //
    // this.Table.getLoginUserName().subscribe( val => {
    //   this.sLoginUserName = val + " " + "on" + " " + this.datePipe.transform(new Date().toLocaleTimeString('en-US'), 'MMMM d, y HH:mm a');
    // });

    const getPlanCreateSubscription = this.Table.getPlanCreate().subscribe(
      (isPlanCreatePermission) => {
        if (isPlanCreatePermission == false) {
          this.isShownPlanCreateButton = false;
        }
      },
      (err) => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      }
    );
    this.subscription$.push(getPlanCreateSubscription);
    //
    const getPlanViewSubscription = this.Table.getPlanView().subscribe(
      (isPlanViewPermission) => {
        if (isPlanViewPermission == false) {
          this.isShownPlanViewDropdown = false;
        }
      },
      (err) => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      }
    );
    this.subscription$.push(getPlanViewSubscription);
    //
    this.patientId = this.data?.patientId;
    const getOrderCreateSubscription = this.Table.getOrderCreate().subscribe(
      (value) => {
        this.isOrderCreatePermission = value;
        if (this.isOrderCreatePermission == true) {
          this.isShownButton = true;
        } else {
          this.isShownButton = false;
        }
      },
      (err) => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      }
    );
    this.subscription$.push(getOrderCreateSubscription);
    //
    const orderViewSubscription = this.Table.getOrderView().subscribe(
      (value) => {
        this.isOrderViewPermission = value;
        if (this.isOrderViewPermission == true) {
          this.isShownEligibilityButtton = true;
        } else {
          this.isShownEligibilityButtton = false;
        }
      },
      (err) => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      }
    );
    this.subscription$.push(orderViewSubscription);
    //
    this.BirthdateMinDate = new Date();
    this.BirthdateMinDate.setFullYear(
      this.BirthdateMinDate.getFullYear() - 120
    );
    this.policyForm = this.formBuilder.group({
      txtInsuranceFilter: new FormControl(''),
      txtPayerFilter: new FormControl(''),
      txtInsuredLastName: new FormControl('', [
        Validators.required,
        this.textValidators.isTextCheck,
      ]),
      txtInsuredFirst: new FormControl('', [
        Validators.required,
        this.textValidators.isTextCheck,
      ]),
      txtInsuredMiddle: new FormControl('', [this.textValidators.isTextCheck]),
      txtIncuredEmployerAddress1: new FormControl(''),
      txtIncuredEmployerAddress2: new FormControl(''),
      txtAddress1: new FormControl(''),
      txtInsuredCity: new FormControl(null),
      txtInsuredState: new FormControl(null),
      txtInsuredCountry: new FormControl(null),
      drpGender: new FormControl(null),
      txtInsuredBirthDate: new FormControl(''),
      txtInsuredCountryFilter: new FormControl(''),
      txtInsuredStateFilter: new FormControl(''),
      txtInsuredCityFilter: new FormControl(''),
      txtEmployerCountryFilter: new FormControl(''),
      txtEmployerStateFilter: new FormControl(''),
      txtEmployerCityFilter: new FormControl(''),
      drpPayor: new FormControl(null, [Validators.required]),
      drpInsurance: new FormControl(null, [Validators.required]),
      txtCoverage: new FormControl(''),
      txtInsuranceId: new FormControl(''),
      txtPoyarContact: new FormControl(''),
      txtPolicyInfoStartDate: new FormControl(''),
      txtPolicyInfoEndDate: new FormControl(''),
      txtPolicyInfoPayPct: new FormControl(0, [Validators.min(0)]),
      txtPolicyInfoDeductible: new FormControl(''),
      txtPolicyInfoDeductibleMet: new FormControl(''),
      txtPolicyInfoOutOfPocket: new FormControl(''),
      txtPolicyInfoOutOfPocketMet: new FormControl(''),
      txtPolicyInfoPolicyNo: new FormControl('', Validators.required),
      txtPolicyInfoGroupNo: new FormControl(''),
      txtPolicyInfoGroupName: new FormControl(''),
      txtPolicyUser3: new FormControl(''),
      txtPolicyUser4: new FormControl(''),
      drpRelationship: new FormControl(null),
      txtPolicyInfoPayorId: new FormControl(null, Validators.required),
      drpClaimfilling: new FormControl(null),
      txtPolicyInfoEmployerdId: new FormControl(''),
      txtPolicyInfoAdditionalPtId: new FormControl(''),
      txtPolicyInfoAgencyClaimNumber: new FormControl(''),
      txtPolicyInfoCarrierId: new FormControl(''),
      txtPolicyUser1: new FormControl(''),
      txtPolicyUser2: new FormControl(''),

      // txtPaypct:new FormControl("", [this.text.isTextCheck]),
      txtPolicyInfoTPLName: new FormControl(''),
      txtPolicyInfoTPLCode: new FormControl(''),
      txtBox10: new FormControl(''),
      txtBox19: new FormControl(''),
      chkAutocrossover: new FormControl(0),
      txtInsuredEmployerContact: new FormControl(''),
      chkCopypatientinfo: new FormControl(0),
      txtAddress2: new FormControl(''),

      txtInsuredSuffix: new FormControl(''),
      txtInsuredSsn: new FormControl(''),
      txtInsuredPostalCode: new FormControl(''),
      txtInsuredPhone: new FormControl(''),
      txtInsuredMobile: new FormControl(''),
      txtInsuredEmployerPostalCode: new FormControl(''),
      txtInsuredEmployerPhone: new FormControl(''),
      txtIncuredEmployer: new FormControl(''),
      txtIncuredEmployerContact: new FormControl(''),
      txtIncuredEmployerCity: new FormControl(null),
      txtIncuredEmployerState: new FormControl(null),
      txtIncuredEmployerCountry: new FormControl(null),
      drpInsured: new FormControl(''),
      drpAssignment: new FormControl(null),
      txtCopay: new FormControl(''),
      txtNote: new FormControl(null),
    });
    this.BirthdateMaxDate = new Date();
    this.BirthdateMaxDate.setDate(this.BirthdateMaxDate.getDate() - 1);

    const getPateitntOrderSubscription = this.Table.getpatientOrder().subscribe(
      (value) => {
        this.orderID = value;
      },
      (err) => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      }
    );
    this.subscription$.push(getPateitntOrderSubscription);
    //
    this.BirthdateMaxDate = new Date();
    this.BirthdateMaxDate.setDate(this.BirthdateMaxDate.getDate() + 0);
    if (
      this.data?.policyId != null &&
      this.data?.policyId != undefined &&
      this.data?.policyId.id !== '' &&
      this.data?.policyId.id !== '00000000-0000-0000-0000-000000000000'
    ) {
      this.getPolicyById(this.data?.policyId);
      this.patientId = this.data?.patientId;
      this.policiesId = this.data?.policyId.id ?? '';
    } else {
      // this.isShownManualEligibilitycheckButton = false;
      this.patientId = this.data?.patientId;
      this.policyModalTitle = 'Add Policy';
    }
    if (
      this.patientId !== '' &&
      this.patientId !== null &&
      this.patientId !== undefined
    ) {
      const storeSubscription = this.patientService
        .get(this.patientId)
        .subscribe((response) => {
          // Patient is locked & userId is not equal to loginUserId or patient is unlocked
          this.isPatientLockedByCurrentUser =
            (response?.patientLock?.isLocked == true &&
              response?.patientLock?.userId == this.strLoginUserId) ||
              response?.patientLock?.isLocked == false
              ? true
              : false;
        });
      this.subscription$.push(storeSubscription);
    }
    //
    this.BirthdateMinDate = new Date();
    this.BirthdateMinDate.setFullYear(
      this.BirthdateMinDate.getFullYear() - 120
    );
    //  this. data?.policyId != null && this. data?.policyId != undefined &&  this.getPolicyDatas(this.patientId);
  }

  viratulScrollModalClosed() {
    if (this.viewport1) {
      this.viewport1.scrollToIndex(0);
      this.viewport1.checkViewportSize();
    }
    if (this.viewport2) {
      this.viewport2.scrollToIndex(0);
      this.viewport2.checkViewportSize();
    }
  }

  // clear Dropdown

  clearPayorlevel() {
    this.payorlevel = '';
    this.policyForm.patchValue({ drpPayor: '' });
  }

  clearPayorInsurance() {
    this.payorInsurance = '';
    this.policyForm.patchValue({ drpInsurance: '' });
  }

  clearRelationship() {
    this.relationship = '';
    this.policyForm.patchValue({ drpRelationship: '' });
  }
  clearClaimFillingCode() {
    this.claimFillingCode = '';
    this.policyForm.patchValue({ drpClaimfilling: '' });
  }

  clearAssignment() {
    this.assignment = '';
    this.policyForm.patchValue({ drpAssignment: '' });
  }

  clearGender() {
    this.gender = '';
    this.policyForm.patchValue({ drpGender: '' });
  }

  drpCountry: RegionCountriesDTO[] = [];
  drpStates: RegionStatesDTO[] = [];
  drpCities: RegionCitiesDTO[] = [];
  drpEmpCountry: RegionCountriesDTO[] = [];
  drpEmpStates: RegionStatesDTO[] = [];
  drpEmpCities: RegionCitiesDTO[] = [];
  public filteredInsuredCountries: Observable<RegionCountriesDTO[]> | undefined;
  public filteredInsuredStates: Observable<RegionStatesDTO[]> | undefined;
  public filteredInsuredCities: Observable<RegionCitiesDTO[]> | undefined;
  public filteredEmployerCountries:
    | Observable<RegionCountriesDTO[]>
    | undefined;
  public filteredEmployerStates: Observable<RegionStatesDTO[]> | undefined;
  public filteredEmployerCities: Observable<RegionCitiesDTO[]> | undefined;
  // Country Dropdown
  countryDropdown() {
    this.drpCountry = [];
    const countryDropdownSubscription = this.region
      .getAllCountriesByInput(new PagedAndSortedResultRequestDto())
      .subscribe(
        (response) => {
          //
          this.drpCountry = response?.items ?? [];
          this.filteredInsuredCountries = this.policyForm
            .get('txtInsuredCountryFilter')
            ?.valueChanges.pipe(
              startWith(''),
              map((value) =>
                this.drpCountry.filter((option) =>
                  option?.countryName
                    ?.toLowerCase()
                    ?.includes(value?.toLowerCase() ?? '')
                )
              )
            );
        },
        (err) => {
          const data: HttpErrorResponse = err;
          Swal.fire({
            icon: 'info',
            text: data?.error?.error?.message,
          });
        }
      );
    this.subscription$.push(countryDropdownSubscription);
  }
  countryEmpDropdown() {
    this.drpEmpCountry = [];
    const emerCountrySubscription = this.region
      .getAllCountriesByInput(new PagedAndSortedResultRequestDto())
      .subscribe(
        (response) => {
          this.drpEmpCountry ?? [];
          this.filteredEmployerCountries = this.policyForm
            .get('txtEmployerCountryFilter')
            ?.valueChanges.pipe(
              startWith(''),
              map((value) =>
                this.drpEmpCountry.filter((option) =>
                  option?.countryName
                    ?.toLowerCase()
                    ?.includes(value?.toLowerCase() ?? '')
                )
              )
            );
        },
        (err) => {
          const data: HttpErrorResponse = err;
          Swal.fire({
            icon: 'info',
            text: data?.error?.error?.message,
          });
        }
      );
    this.subscription$.push(emerCountrySubscription);
  }
  // state city
  onChangeCountry(value: string | null | undefined): boolean {
    this.policyForm.patchValue({
      txtInsuredCity: null,
      txtInsuredState: null,
    });
    this.drpStates = [];
    if (value !== null && value !== undefined) {
      const stateSubscription = this.region
        .getAllStatesByCountryNameByCountryShortName(value)
        .subscribe(
          (response) => {
            this.drpStates = response?.items ?? [];
            if (response?.items?.length === 0) {
              this.phoneCode = '';
            } else {
              this.phoneCode = this.drpStates[0]?.countryPhoneCode;
            }
            this.filteredInsuredStates = this.policyForm
              .get('txtInsuredStateFilter')
              ?.valueChanges.pipe(
                startWith(''),
                map((value) =>
                  this.drpStates.filter((option) =>
                    option?.stateName
                      ?.toLowerCase()
                      ?.includes(value?.toLowerCase() ?? '')
                  )
                )
              );
          },
          (err) => {
            const data: HttpErrorResponse = err;
            Swal.fire({
              icon: 'info',
              text: data?.error?.error?.message,
            });
          }
        );
      this.subscription$.push(stateSubscription);
    }
    return this.drpStates && this.drpStates.length !== 0;
  }
  onChangeState(state: string | null | undefined, country: string): boolean {
    this.drpCities = [];
    this.policyForm.patchValue({
      txtInsuredCity: null,
    });
    country =
      country == ''
        ? this.policyForm.value.txtInsuredCountry == (null || undefined)
          ? ''
          : this.policyForm.value.txtInsuredCountry
        : country;
    if (state !== null && state !== undefined) {
      const stateSubscription = this.region
        .getAllCitiesByCountryAndStateNameByCountryShortNameAndStateShortName(
          country,
          state
        )
        .subscribe(
          (response) => {
            this.drpCities = response?.items ?? [];
            this.filteredInsuredCities = this.policyForm
              .get('txtInsuredCityFilter')
              ?.valueChanges.pipe(
                startWith(''),
                map((value) =>
                  this.drpCities.filter((option) =>
                    option?.cityName
                      ?.toLowerCase()
                      ?.includes(value?.toLowerCase() ?? '')
                  )
                )
              );
          },
          (err) => {
            const data: HttpErrorResponse = err;
            Swal.fire({
              icon: 'info',
              text: data?.error?.error?.message,
            });
          }
        );
      this.subscription$.push(stateSubscription);
    }
    return this.drpCities && this.drpCities.length !== 0;
  }

  // emp state city
  onChangeEmpState(value: string | null | undefined): boolean {
    this.drpEmpStates = [];
    this.policyForm.patchValue({
      txtIncuredEmployerCity: null,
      txtIncuredEmployerState: null,
    });
    if (value !== null && value !== undefined) {
      const emptState = this.region
        .getAllStatesByCountryNameByCountryShortName(value)
        .subscribe(
          (response) => {
            this.drpEmpStates = response?.items ?? [];
            if (response?.items?.length === 0) {
              this.empPhoneCode = '';
            } else {
              this.empPhoneCode = response?.items[0]?.countryPhoneCode ?? '';
            }
            this.filteredEmployerStates = this.policyForm
              .get('txtEmployerStateFilter')
              ?.valueChanges.pipe(
                startWith(''),
                map((value) =>
                  this.drpEmpStates.filter((option) =>
                    option?.stateName
                      ?.toLowerCase()
                      ?.includes(value?.toLowerCase() ?? '')
                  )
                )
              );
          },
          (err) => {
            const data: HttpErrorResponse = err;
            Swal.fire({
              icon: 'info',
              text: data?.error?.error?.message,
            });
          }
        );
      this.subscription$.push(emptState);
    }

    return this.drpEmpStates && this.drpEmpStates.length !== 0;
  }

  onChangeEmpCity(state: string | null | undefined, country: string): boolean {
    this.policyForm.patchValue({
      txtIncuredEmployerCity: null,
    });
    country =
      country == ''
        ? this.policyForm.value.txtIncuredEmployerCountry == (null || undefined)
          ? ''
          : this.policyForm.value.txtIncuredEmployerCountry
        : country;
    this.drpEmpCities = [];
    if (state !== null && state !== undefined) {
      const empCity = this.region
        .getAllCitiesByCountryAndStateNameByCountryShortNameAndStateShortName(
          country,
          state
        )
        .subscribe(
          (response) => {
            this.drpEmpCities = response?.items ?? [];
            this.filteredEmployerCities = this.policyForm
              .get('txtInsuredCityFilter')
              ?.valueChanges.pipe(
                startWith(''),
                map((value) =>
                  this.drpEmpCities.filter((option) =>
                    option?.cityName
                      ?.toLowerCase()
                      ?.includes(value?.toLowerCase() ?? '')
                  )
                )
              );
          },
          (err) => {
            const data: HttpErrorResponse = err;
            Swal.fire({
              icon: 'info',
              text: data?.error?.error?.message,
            });
          }
        );
      this.subscription$.push(empCity);
    }
    return this.drpEmpCities && this.drpEmpCities.length !== 0;
  }

  onChange(value: MatSelectChange) {
    const payorShortCodeId: string = this.drpPlan?.filter((v) => {
      return v && v?.name === value.value;
    })[0]?.payorId;
    const payorId = this.drpPayerList?.filter((v) => {
      return v && v?.payerShortCodeId === payorShortCodeId;
    })[0]?.payerId;
    if (payorShortCodeId && payorId) {
      this.policyForm.patchValue({
        txtPolicyInfoPayorId: payorId,
      });
    }
  }

  InsuredState: string = '';
  InsuredCity: string = '';
  InsuredCountry: string = '';
  EmployerState: string = '';
  EmployerCity: string = '';
  EmployerCountry: string = '';
  Insurance: string = '';
  Payers: string = '';

  clearInsuredCountry() {
    this.InsuredCountry = '';
    this.policyForm.patchValue({ txtInsuredCountry: '' });
  }
  clearInsuredState() {
    this.InsuredState = '';
    this.policyForm.patchValue({ txtInsuredState: '' });
  }
  clearInsuredCity() {
    this.InsuredCity = '';
    this.policyForm.patchValue({ txtInsuredCity: '' });
  }
  clearEmployerCountry() {
    this.EmployerCountry = '';
    this.policyForm.patchValue({ txtIncuredEmployerCountry: '' });
  }
  clearEmployerState() {
    this.EmployerState = '';
    this.policyForm.patchValue({ txtIncuredEmployerState: '' });
  }
  clearEmployerCity() {
    this.EmployerCity = '';
    this.policyForm.patchValue({ txtIncuredEmployerCity: '' });
  }
  clearInsurance() {
    this.Insurance = '';
    this.policyForm.patchValue({ drpInsurance: '' });
  }
  clearPayers() {
    this.Payers = '';
    this.policyForm.patchValue({ txtPolicyInfoPayorId: '' });
  }
  public filteredInsuranceTypes: Observable<planInterface[]> | undefined;
  public filteredPayerTypes:
    | Observable<{ id: string; name: string; payerCode: string }[]>
    | undefined;
  @ViewChild('singleSelect') singleSelect: MatSelect;
  planDropdown(): void {
    const planDropdownSubscription = this.planService
      .getList(new PagedAndSortedResultRequestDto())
      .subscribe(
        (response) => {
          this.drpPlan = [];
          response?.items?.forEach((element) => {
            this.drpPlan.push({
              id: element.id ?? '',
              name: element?.payerName ?? '',
              code: element?.payerCode ?? '',
              payorId: element.shortCodeId ?? '',
            });
          });
          this.filteredInsuranceTypes = this.policyForm
            .get('txtInsuranceFilter')
            ?.valueChanges.pipe(
              startWith(''),
              map((value) =>
                this.drpPlan.filter((option) =>
                  option?.name
                    ?.toLowerCase()
                    ?.includes(value?.toLowerCase() ?? '')
                )
              )
            );
        },
        (err) => {
          const data: HttpErrorResponse = err;
          Swal.fire({
            icon: 'info',
            text: data?.error?.error?.message,
          });
        }
      );
    this.subscription$.push(planDropdownSubscription);
    //
    const payerDropdownSubscription = this.payerService
      .getList(new PagedAndSortedResultRequestDto())
      .subscribe(
        (response) => {
          this.drpPayerId = [];
          response?.items?.forEach((element) => {
            this.drpPayerId.push({
              id: element.id ?? '',
              name: element?.payerId + ' / ' + element?.payerName,
              payerCode: element?.payerId ?? '',
              // code: element?.payerCode,
              // payorId: element.shortCodeId
            });
          });
          this.filteredPayerTypes = this.policyForm
            .get('txtPayerFilter')
            ?.valueChanges.pipe(
              startWith(''),
              map((value) =>
                this.drpPayerId?.filter((option) =>
                  option?.name
                    ?.toLowerCase()
                    ?.includes(value?.toLowerCase() ?? '')
                )
              )
            );
        },
        (err) => {
          const data: HttpErrorResponse = err;
          Swal.fire({
            icon: 'info',
            text: data?.error?.error?.message,
          });
        }
      );
    // this.subscription$.push(payerDropdownSubscription) `
    //
    const patientList = (query) => this.payerService.getList(query);
    const listSubscription = this.listService
      ?.hookToQuery(patientList)
      ?.subscribe(
        (response) => {
          this.drpPayerList = [];
          if (response && response?.items && response?.items) {
            response?.items?.forEach((element) => {
              this.drpPayerList.push({
                id: element?.id,
                payerId: element.payerId,
                payerName: element.payerName,
                payerShortCodeId: element.payerShortCodeId,
              });
            });
          }
        },
        (err: HttpErrorResponse) => {
          const data: HttpErrorResponse = err;
          Swal.fire({
            icon: 'info',
            text: data?.error?.error?.message,
          });
        }
      );
    this.subscription$.push(listSubscription);
  }
  openPlanModel() {
    // const dialogRef = this.dialog.open(PatientAddPlanComponent, { disableClose: true });
    // dialogRef.afterClosed().subscribe(() => {
    //   this.planDropdown();
    // }, err => {
    //   const data: HttpErrorResponse = err;
    //   Swal.fire({
    //     icon: 'error',
    //     text: data?.error?.error?.message,
    //   });
    // });
  }
  getPolicyDatas() { }

  getPolicyDropdown() {
    let patientDropdownInput: PatientDropdowns[];
    patientDropdownInput = [
      PatientDropdowns.PayorLevels,
      PatientDropdowns.RelationShipWithSelf,
      PatientDropdowns.ClaimFillingCodes,
      PatientDropdowns.Genders,
      PatientDropdowns.InsuranceTypes,
      PatientDropdowns.Assignments,
    ];
    const patientDropdownSubscription = this.patientDropdownService
      .getPatientDropdownsByInput(patientDropdownInput)
      .subscribe(
        (stateResponse) => {
          const response: PatientMasterDropdownDTO = stateResponse;
          this.payorLevel = response?.payorLevels;
          this.relationshipLoop = response?.relationShipWithSelves;
          this.drpclaimLoop = response?.claimFillingCodes;
          this.drpgenderLoop = response?.genders;
          this.drpInsurance = response?.insuranceTypes;
          this.drpAssignment = response?.lstAssignment;
          this.planDropdown();
        },
        (err) => {
          const data: HttpErrorResponse = err;
          Swal.fire({
            icon: 'info',
            text: data?.error?.error?.message,
          });
        }
      );
    this.subscription$.push(patientDropdownSubscription);
    //
    this.data?.patientId != null &&
      this.data?.patientId != '' &&
      this.getVerificationStatus();
  }

  getPatientDetailsForPolicySave() {
    let personalDetails: PatientDTO;
    const patientGet = this.patientService.get(this.patientId).subscribe(
      (response) => {
        personalDetails = response;
        this.tblPolicesReponse = [];
        if (personalDetails.policies) {
          const savedData = {
            primaryPolicy: personalDetails.policies.primaryPolicy,
            secondaryPolicy: personalDetails.policies.secondaryPolicy,
            tertiaryPolicy: personalDetails.policies.tertiaryPolicy,
          };
          return this.savepolicy(personalDetails, savedData);
        }
      },
      (err) => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      }
    );
    this.subscription$.push(patientGet);
  }

  getPolicyById(selectedPolicyid: PolicyDTO): void {
    this.policyModalTitle = 'Edit Policy';
    this.sPolicyAddedName = selectedPolicyid?.added ?? '';
    this.sPolicyLastEditedName = selectedPolicyid?.lastEdit ?? '';
    this.CountryName = selectedPolicyid?.country ?? '';
    this.StateName = selectedPolicyid?.state ?? '';
    this.CountryEmpName = selectedPolicyid?.employerCountry ?? '';
    this.StateEmpName = selectedPolicyid?.employerSate ?? '';
    this.policiesId = selectedPolicyid?.policiesId ?? '';
    //  getSet
    this.CountryName != null &&
      this.CountryName !== '' &&
      this.CountryName != undefined &&
      this.onChangeCountry(this.CountryName) &&
      this.policyForm.patchValue({
        txtInsuredCountry: this.CountryName,
      });
    this.StateName != null &&
      this.StateName !== '' &&
      this.StateName != undefined &&
      this.onChangeState(this.StateName, this.CountryName) &&
      this.policyForm.patchValue({
        txtInsuredState: this.StateName,
        txtInsuredCity:
          selectedPolicyid?.city == '' || undefined
            ? null
            : selectedPolicyid?.city,
      });
    this.CountryEmpName != null &&
      this.CountryEmpName !== '' &&
      this.CountryEmpName != undefined &&
      this.onChangeEmpState(this.CountryEmpName) &&
      this.policyForm.patchValue({
        txtIncuredEmployerCountry: this.CountryEmpName,
      });
    this.StateEmpName != null &&
      this.StateEmpName !== '' &&
      this.StateEmpName != undefined &&
      this.onChangeEmpCity(this.StateEmpName, this.CountryEmpName) &&
      this.policyForm.patchValue({
        txtIncuredEmployerState: this.StateEmpName,
        txtIncuredEmployerCity:
          selectedPolicyid?.employerCity === '' || undefined
            ? null
            : selectedPolicyid?.employerCity,
      });
    this.drpPlan.filter((e) => {
      if (e?.name?.trim() === selectedPolicyid?.insuranceType) {
      }
    });

    this.policyForm.patchValue({
      drpPayor: selectedPolicyid?.payorLevel,
      drpInsurance: selectedPolicyid?.insuranceType?.trim() ?? '',
      txtCoverage: selectedPolicyid?.coverageType,
      txtInsuranceId: selectedPolicyid?.insuranceId,
      txtPoyarContact: selectedPolicyid?.payorContact,
      txtPolicyInfoStartDate:
        selectedPolicyid?.startDate == null
          ? ''
          : new Date(selectedPolicyid?.startDate),
      txtPolicyInfoEndDate:
        selectedPolicyid?.endDate == null
          ? ''
          : new Date(selectedPolicyid?.endDate),

      txtPolicyInfoEmployerdId: selectedPolicyid?.employerId,
      txtPolicyInfoPayPct: selectedPolicyid?.payPct,
      txtPolicyInfoDeductible: selectedPolicyid?.deductible,
      txtPolicyInfoDeductibleMet: selectedPolicyid?.deductibleMet,
      txtPolicyInfoOutOfPocket: selectedPolicyid?.outOfPocketExpense,
      txtPolicyInfoOutOfPocketMet: selectedPolicyid?.outOfPocketExpenseMet,
      txtPolicyInfoPolicyNo: selectedPolicyid?.policyNo,
      txtPolicyInfoGroupNo: selectedPolicyid?.groupNo,
      txtPolicyInfoGroupName: selectedPolicyid?.groupName,
      txtPolicyUser1: selectedPolicyid?.user1,
      txtPolicyUser2: selectedPolicyid?.user2,
      txtPolicyUser3: selectedPolicyid?.user3,
      txtPolicyUser4: selectedPolicyid?.user4,
      drpRelationship: selectedPolicyid?.relationShipType,
      txtPolicyInfoPayorId: selectedPolicyid?.payorId,
      txtPolicyInfoAdditionalPtId: selectedPolicyid?.additionalPtId,
      drpClaimfilling: selectedPolicyid?.claimFillingCode,
      txtPolicyInfoTPLName: selectedPolicyid?.tplName,
      txtPolicyInfoTPLCode: selectedPolicyid?.tplCode,
      txtPolicyInfoAgencyClaimNumber:
        selectedPolicyid?.propertyAndCasualtyAgency,
      txtPolicyInfoCarrierId: selectedPolicyid?.carrierId,
      chkAutocrossover: +selectedPolicyid?.alwaysCrossover,
      chkCopypatientinfo: +(selectedPolicyid?.copyPatientInfo || '0'),
      txtInsuredLastName: selectedPolicyid?.lastName,
      txtInsuredFirst: selectedPolicyid?.fisrtName,
      txtInsuredMiddle: selectedPolicyid?.middleName,
      txtInsuredSuffix: selectedPolicyid?.suffix,
      txtInsuredBirthDate:
        selectedPolicyid?.birthdate == null
          ? ''
          : new Date(selectedPolicyid?.birthdate),
      drpGender: selectedPolicyid?.gender,
      txtInsuredSsn: selectedPolicyid?.ssn,
      txtAddress1: selectedPolicyid?.address1,
      txtAddress2: selectedPolicyid?.address2,
      txtInsuredCity:
        selectedPolicyid?.city == '' || undefined
          ? null
          : selectedPolicyid?.city,
      txtInsuredState:
        selectedPolicyid?.state == '' || undefined
          ? null
          : selectedPolicyid?.state,
      txtInsuredCountry:
        selectedPolicyid?.country == '' || undefined
          ? null
          : selectedPolicyid?.country,
      txtInsuredPostalCode: selectedPolicyid?.postalCode,
      txtInsuredPhone: selectedPolicyid?.phone,
      txtInsuredMobile: selectedPolicyid?.mobile,
      txtIncuredEmployer: selectedPolicyid?.employer,
      txtInsuredEmployerContact: selectedPolicyid?.employerContact,
      txtIncuredEmployerAddress1: selectedPolicyid?.employerAddress1,
      txtIncuredEmployerAddress2: selectedPolicyid?.employerAddress2,
      txtIncuredEmployerCity:
        selectedPolicyid?.employerCity === '' || undefined
          ? null
          : selectedPolicyid?.employerCity,
      txtIncuredEmployerState:
        selectedPolicyid?.employerSate === '' || undefined
          ? null
          : selectedPolicyid?.employerSate,
      txtIncuredEmployerCountry:
        selectedPolicyid?.employerCountry === '' || undefined
          ? null
          : selectedPolicyid?.employerCountry,
      txtInsuredEmployerPostalCode: selectedPolicyid?.employerPostalCode,
      txtInsuredEmployerPhone: selectedPolicyid?.employerPhone,
      createdByUserId: 0,
      txtBox10: selectedPolicyid?.box10d,
      txtBox19: selectedPolicyid?.box19,
      drpInsured: selectedPolicyid?.insuredId,
      drpAssignment: selectedPolicyid?.assignmentId,
      txtCopay: selectedPolicyid?.copay,
      txtNote: selectedPolicyid?.note,
    });

  }

  savepolicy(response, savedData: PoliciesDTO) {
    this.isShowSpinner = true;
    this.saveButtonHide = true;
    const payorLevelName = this.payorLevel.map((v) => v.payorLevelName);
    if (this.policyForm.value.drpPayor === payorLevelName[0]) {
      savedData.primaryPolicy = {
        policiesId:
          this.policiesId === '00000000-0000-0000-0000-000000000000' ||
            undefined
            ? '00000000-0000-0000-0000-000000000000'
            : savedData?.primaryPolicy?.policiesId,
        payorLevel: this.policyForm.value.drpPayor,
        insuranceType: this.policyForm.value.drpInsurance,
        coverageType: this.policyForm.value.txtCoverage,
        insuranceId:
          this.policyForm.value.txtInsuranceId == ''
            ? 0
            : +this.policyForm.value.txtInsuranceId,

        employerId: this.policyForm.value.txtPolicyInfoEmployerdId,
        payorContact: this.policyForm.value.txtPoyarContact,
        startDate: this.policyForm.value.txtPolicyInfoStartDate,
        endDate: this.policyForm.value.txtPolicyInfoEndDate,
        payPct: String(this.policyForm.value.txtPolicyInfoPayPct),
        deductible: this.policyForm.value.txtPolicyInfoDeductible,
        deductibleMet: this.policyForm.value.txtPolicyInfoDeductibleMet,
        outOfPocketExpense: this.policyForm.value.txtPolicyInfoOutOfPocket,
        outOfPocketExpenseMet:
          this.policyForm.value.txtPolicyInfoOutOfPocketMet,
        policyNo: this.policyForm.value.txtPolicyInfoPolicyNo,
        groupNo: this.policyForm.value.txtPolicyInfoGroupNo,
        groupName: this.policyForm.value.txtPolicyInfoGroupName,
        user1: this.policyForm.value.txtPolicyUser1,
        user2: this.policyForm.value.txtPolicyUser2,
        user3: this.policyForm.value.txtPolicyUser3,
        user4: this.policyForm.value.txtPolicyUser4,
        relationShipType: this.policyForm.value.drpRelationship,
        payorId: this.policyForm.value.txtPolicyInfoPayorId,
        additionalPtId: this.policyForm.value.txtPolicyInfoAdditionalPtId,
        claimFillingCode: this.policyForm.value.drpClaimfilling,
        tplName: this.policyForm.value.txtPolicyInfoTPLName,
        tplCode: this.policyForm.value.txtPolicyInfoTPLCode,
        propertyAndCasualtyAgency:
          this.policyForm.value.txtPolicyInfoAgencyClaimNumber,
        carrierId: this.policyForm.value.txtPolicyInfoCarrierId,
        alwaysCrossover: +this.policyForm.value.chkAutocrossover,
        copyPatientInfo:
          this.policyForm.value.chkCopypatientinfo === true ? '1' : '0',
        lastName: this.policyForm.value.txtInsuredLastName,
        fisrtName: this.policyForm.value.txtInsuredFirst,
        middleName: this.policyForm.value.txtInsuredMiddle,
        suffix: this.policyForm.value.txtInsuredSuffix,
        birthdate: this.policyForm.value.txtInsuredBirthDate,
        gender: this.policyForm.value.drpGender,
        ssn: this.policyForm.value.txtInsuredSsn,
        address1: this.policyForm.value.txtAddress1,
        address2: this.policyForm.value.txtAddress2,
        city:
          this.policyForm.value.txtInsuredCity === null || undefined
            ? ''
            : this.policyForm.value.txtInsuredCity,
        state:
          this.policyForm.value.txtInsuredState === null || undefined
            ? ''
            : this.policyForm.value.txtInsuredState,
        country:
          this.policyForm.value.txtInsuredCountry === null || undefined
            ? ''
            : this.policyForm.value.txtInsuredCountry,
        postalCode: this.policyForm.value.txtInsuredPostalCode,
        phone: this.policyForm.value.txtInsuredPhone,
        mobile: this.policyForm.value.txtInsuredMobile,
        employer: this.policyForm.value.txtIncuredEmployer,
        employerContact: this.policyForm.value.txtInsuredEmployerContact,
        employerAddress1: this.policyForm.value.txtIncuredEmployerAddress1,
        employerAddress2: this.policyForm.value.txtIncuredEmployerAddress2,
        employerCity:
          this.policyForm.value.txtIncuredEmployerCity === null || undefined
            ? ''
            : this.policyForm.value.txtIncuredEmployerCity,
        employerSate:
          this.policyForm.value.txtIncuredEmployerState === null || undefined
            ? ''
            : this.policyForm.value.txtIncuredEmployerState,
        employerCountry:
          this.policyForm.value.txtIncuredEmployerCountry === null || undefined
            ? ''
            : this.policyForm.value.txtIncuredEmployerCountry,
        employerPostalCode: this.policyForm.value.txtInsuredEmployerPostalCode,
        employerPhone: this.policyForm.value.txtInsuredEmployerPhone,
        box10d: this.policyForm.value.txtBox10,
        box19: this.policyForm.value.txtBox19,
        insuredId: this.policyForm.value.drpInsured,
        assignmentId: this.policyForm.value.drpAssignment,
        copay: this.policyForm.value.txtCopay,
        note: this.policyForm.value.txtNote,
        added: savedData?.primaryPolicy?.added,
        lastEdit: this.sLoginUserName,
      };
    } else if (this.policyForm.value.drpPayor === payorLevelName[1]) {
      savedData.secondaryPolicy = {
        policiesId:
          this.policiesId === '00000000-0000-0000-0000-000000000000' ||
            undefined
            ? '00000000-0000-0000-0000-000000000000'
            : savedData?.secondaryPolicy?.policiesId,
        payorLevel: this.policyForm.value.drpPayor,
        insuranceType: this.policyForm.value.drpInsurance,
        coverageType: this.policyForm.value.txtCoverage,
        insuranceId:
          this.policyForm.value.txtInsuranceId == ''
            ? 0
            : +this.policyForm.value.txtInsuranceId,
        payorContact: this.policyForm.value.txtPoyarContact,
        employerId: this.policyForm.value.txtPolicyInfoEmployerdId,

        startDate: this.policyForm.value.txtPolicyInfoStartDate,
        endDate: this.policyForm.value.txtPolicyInfoEndDate,
        payPct: String(this.policyForm.value.txtPolicyInfoPayPct),
        deductible: this.policyForm.value.txtPolicyInfoDeductible,
        deductibleMet: this.policyForm.value.txtPolicyInfoDeductibleMet,
        outOfPocketExpense: this.policyForm.value.txtPolicyInfoOutOfPocket,
        outOfPocketExpenseMet:
          this.policyForm.value.txtPolicyInfoOutOfPocketMet,
        policyNo: this.policyForm.value.txtPolicyInfoPolicyNo,
        groupNo: this.policyForm.value.txtPolicyInfoGroupNo,
        groupName: this.policyForm.value.txtPolicyInfoGroupName,
        user1: this.policyForm.value.txtPolicyUser1,
        user2: this.policyForm.value.txtPolicyUser2,
        user3: this.policyForm.value.txtPolicyUser3,
        user4: this.policyForm.value.txtPolicyUser4,
        relationShipType: this.policyForm.value.drpRelationship,
        payorId: this.policyForm.value.txtPolicyInfoPayorId,
        additionalPtId: this.policyForm.value.txtPolicyInfoAdditionalPtId,
        claimFillingCode: this.policyForm.value.drpClaimfilling,
        tplName: this.policyForm.value.txtPolicyInfoTPLName,
        tplCode: this.policyForm.value.txtPolicyInfoTPLCode,
        propertyAndCasualtyAgency:
          this.policyForm.value.txtPolicyInfoAgencyClaimNumber,
        carrierId: this.policyForm.value.txtPolicyInfoCarrierId,
        alwaysCrossover: +this.policyForm.value.chkAutocrossover,
        copyPatientInfo:
          this.policyForm.value.chkCopypatientinfo === true ? '1' : '0',
        lastName: this.policyForm.value.txtInsuredLastName,
        fisrtName: this.policyForm.value.txtInsuredFirst,
        middleName: this.policyForm.value.txtInsuredMiddle,
        suffix: this.policyForm.value.txtInsuredSuffix,
        birthdate: this.policyForm.value.txtInsuredBirthDate,
        gender: this.policyForm.value.drpGender,
        ssn: this.policyForm.value.txtInsuredSsn,
        address1: this.policyForm.value.txtAddress1,
        address2: this.policyForm.value.txtAddress2,
        city:
          this.policyForm.value.txtInsuredCity === null || undefined
            ? ''
            : this.policyForm.value.txtInsuredCity,
        state:
          this.policyForm.value.txtInsuredState === null || undefined
            ? ''
            : this.policyForm.value.txtInsuredState,
        country:
          this.policyForm.value.txtInsuredCountry === null || undefined
            ? ''
            : this.policyForm.value.txtInsuredCountry,
        postalCode: this.policyForm.value.txtInsuredPostalCode,
        phone: this.policyForm.value.txtInsuredPhone,
        mobile: this.policyForm.value.txtInsuredMobile,
        employer: this.policyForm.value.txtIncuredEmployer,
        employerContact: this.policyForm.value.txtInsuredEmployerContact,
        employerAddress1: this.policyForm.value.txtIncuredEmployerAddress1,
        employerAddress2: this.policyForm.value.txtIncuredEmployerAddress2,
        employerCity:
          this.policyForm.value.txtIncuredEmployerCity === null || undefined
            ? ''
            : this.policyForm.value.txtIncuredEmployerCity,
        employerSate:
          this.policyForm.value.txtIncuredEmployerState === null || undefined
            ? ''
            : this.policyForm.value.txtIncuredEmployerState,
        employerCountry:
          this.policyForm.value.txtIncuredEmployerCountry === null || undefined
            ? ''
            : this.policyForm.value.txtIncuredEmployerCountry,
        employerPostalCode: this.policyForm.value.txtInsuredEmployerPostalCode,
        employerPhone: this.policyForm.value.txtInsuredEmployerPhone,
        box10d: this.policyForm.value.txtBox10,
        box19: this.policyForm.value.txtBox19,
        insuredId: this.policyForm.value.drpInsured,
        assignmentId: this.policyForm.value.drpAssignment,
        copay: this.policyForm.value.txtCopay,
        note: this.policyForm.value.txtNote,
        added: savedData?.secondaryPolicy?.added,
        lastEdit: this.sLoginUserName,
      };
    } else if (this.policyForm.value.drpPayor === payorLevelName[2]) {
      savedData.tertiaryPolicy = {
        policiesId:
          this.policiesId === '00000000-0000-0000-0000-000000000000' ||
            undefined
            ? '00000000-0000-0000-0000-000000000000'
            : savedData?.tertiaryPolicy?.policiesId,
        payorLevel: this.policyForm.value.drpPayor,
        insuranceType: this.policyForm.value.drpInsurance,
        coverageType: this.policyForm.value.txtCoverage,
        insuranceId:
          this.policyForm.value.txtInsuranceId == ''
            ? 0
            : +this.policyForm.value.txtInsuranceId,
        payorContact: this.policyForm.value.txtPoyarContact,
        employerId: this.policyForm.value.txtPolicyInfoEmployerdId,

        startDate: this.policyForm.value.txtPolicyInfoStartDate,
        endDate: this.policyForm.value.txtPolicyInfoEndDate,
        payPct: String(this.policyForm.value.txtPolicyInfoPayPct),
        deductible: this.policyForm.value.txtPolicyInfoDeductible,
        deductibleMet: this.policyForm.value.txtPolicyInfoDeductibleMet,
        outOfPocketExpense: this.policyForm.value.txtPolicyInfoOutOfPocket,
        outOfPocketExpenseMet:
          this.policyForm.value.txtPolicyInfoOutOfPocketMet,
        policyNo: this.policyForm.value.txtPolicyInfoPolicyNo,
        groupNo: this.policyForm.value.txtPolicyInfoGroupNo,
        groupName: this.policyForm.value.txtPolicyInfoGroupName,
        user1: this.policyForm.value.txtPolicyUser1,
        user2: this.policyForm.value.txtPolicyUser2,
        user3: this.policyForm.value.txtPolicyUser3,
        user4: this.policyForm.value.txtPolicyUser4,
        relationShipType: this.policyForm.value.drpRelationship,
        payorId: this.policyForm.value.txtPolicyInfoPayorId,
        additionalPtId: this.policyForm.value.txtPolicyInfoAdditionalPtId,
        claimFillingCode: this.policyForm.value.drpClaimfilling,
        tplName: this.policyForm.value.txtPolicyInfoTPLName,
        tplCode: this.policyForm.value.txtPolicyInfoTPLCode,
        propertyAndCasualtyAgency:
          this.policyForm.value.txtPolicyInfoAgencyClaimNumber,
        carrierId: this.policyForm.value.txtPolicyInfoCarrierId,
        alwaysCrossover: +this.policyForm.value.chkAutocrossover,
        copyPatientInfo:
          this.policyForm.value.chkCopypatientinfo === true ? '1' : '0',
        lastName: this.policyForm.value.txtInsuredLastName,
        fisrtName: this.policyForm.value.txtInsuredFirst,
        middleName: this.policyForm.value.txtInsuredMiddle,
        suffix: this.policyForm.value.txtInsuredSuffix,
        birthdate: this.policyForm.value.txtInsuredBirthDate,
        gender: this.policyForm.value.drpGender,
        ssn: this.policyForm.value.txtInsuredSsn,
        address1: this.policyForm.value.txtAddress1,
        address2: this.policyForm.value.txtAddress2,
        city:
          this.policyForm.value.txtInsuredCity === null || undefined
            ? ''
            : this.policyForm.value.txtInsuredCity,
        state:
          this.policyForm.value.txtInsuredState === null || undefined
            ? ''
            : this.policyForm.value.txtInsuredState,
        country:
          this.policyForm.value.txtInsuredCountry === null || undefined
            ? ''
            : this.policyForm.value.txtInsuredCountry,
        postalCode: this.policyForm.value.txtInsuredPostalCode,
        phone: this.policyForm.value.txtInsuredPhone,
        mobile: this.policyForm.value.txtInsuredMobile,
        employer: this.policyForm.value.txtIncuredEmployer,
        employerContact: this.policyForm.value.txtInsuredEmployerContact,
        employerAddress1: this.policyForm.value.txtIncuredEmployerAddress1,
        employerAddress2: this.policyForm.value.txtIncuredEmployerAddress2,
        employerCity:
          this.policyForm.value.txtIncuredEmployerCity === null || undefined
            ? ''
            : this.policyForm.value.txtIncuredEmployerCity,
        employerSate:
          this.policyForm.value.txtIncuredEmployerState === null || undefined
            ? ''
            : this.policyForm.value.txtIncuredEmployerState,
        employerCountry:
          this.policyForm.value.txtIncuredEmployerCountry === null || undefined
            ? ''
            : this.policyForm.value.txtIncuredEmployerCountry,
        employerPostalCode: this.policyForm.value.txtInsuredEmployerPostalCode,
        employerPhone: this.policyForm.value.txtInsuredEmployerPhone,
        box10d: this.policyForm.value.txtBox10,
        box19: this.policyForm.value.txtBox19,
        insuredId: this.policyForm.value.drpInsured,
        assignmentId: this.policyForm.value.drpAssignment,
        copay: this.policyForm.value.txtCopay,
        note: this.policyForm.value.txtNote,
        added: savedData?.tertiaryPolicy?.added,
        lastEdit: this.sLoginUserName,
      };
    }
    let insVerification: CreateUpdatePatientIntakeDTO;
    const verifyData = savedData;
    insVerification = {
      personals: response?.personals,
      addresses: response?.addresses,
      contacts: response?.contacts,
      clinicals: response?.clinicals,
      insurances: response?.insurances,
      notes: response?.notes,
      policies: {
        primaryPolicy: verifyData?.primaryPolicy,
        secondaryPolicy: verifyData?.secondaryPolicy,
        tertiaryPolicy: verifyData?.tertiaryPolicy,
      },
      defaultPatientId: response?.defaultPatientId,
      organizationUnitId: response?.organizationUnitId,
      isAutoIntake: response?.isAutoIntake,
      autoIntakeDocumentDetails: response?.autoIntakeDocumentDetails,
      chartId: response?.chartId,
      patientLock: response?.patientLock,
      // fileId: response?.fileId,
    };
    this.patientId !== '' &&
      this.store
        .dispatch(new updatePatientIntake(this.patientId, insVerification))
        .subscribe(
          (response) => {
            const patientSaved = response?.patientState?.selectedPatient;
            this.patientId = response?.patientState?.selectedPatient?.id;
            // Swal.fire({
            //   title: 'Success',
            //   html: 'Saved Successfully',
            //   icon: 'success',
            //   timer: 3000,
            //   timerProgressBar: true,
            // });
            this.toastr.success('Saved Successfully','Success')
            this.Table.setheaderTable(this.patientId);
            this.policyDialogRef.close(patientSaved);
            this.Table.setheaderTable(this.patientId);
            this.saveButtonHide = false;
            this.isShowSpinner = false;
            // this.dialog.closeAll();
          },
          (err) => {
            this.saveButtonHide = false;
            this.isShowSpinner = false;
            const data: HttpErrorResponse = err;
            Swal.fire({
              icon: 'info',
              text: data?.error?.error?.message,
            });
          }
        );
  }

  statusType: string = '';
  lastChecked: string = '';
  checkedBy: string = '';
  method: string = '';
  verifiedType: string = '';

  getVerificationStatus() {
    // const storeVerification = this.store.dispatch(new getPatient(this. data?.patientId)).subscribe(response => {
    const storeSubscription = this.patientService
      .get(this.data?.patientId)
      .subscribe((response) => {
        const savedPolicy = response?.policies;
        const savedVerification = response?.insuranceVerifications;

        if (
          savedPolicy?.primaryPolicy != null &&
          savedPolicy?.primaryPolicy?.payorLevel ===
          savedVerification?.primaryInsuranceVerifies?.plan
        ) {
          this.statusType =
            savedVerification?.primaryInsuranceVerifies?.state ?? '';
          this.lastChecked =
            savedVerification?.primaryInsuranceVerifies?.dateVerified == null
              ? ''
              : new Date(
                savedVerification?.primaryInsuranceVerifies?.dateVerified
              )?.toLocaleDateString('en-US');
          this.checkedBy =
            savedVerification?.primaryInsuranceVerifies?.verifiedBy ?? '';
          this.method =
            savedVerification?.primaryInsuranceVerifies?.verifiedMethod ?? '';
          this.verifiedType =
            savedVerification?.primaryInsuranceVerifies?.verifiedTypeMethod ??
            '';
        }
        if (
          savedPolicy?.secondaryPolicy != null &&
          savedPolicy?.secondaryPolicy?.payorLevel ===
          savedVerification?.secondaryInsuranceVerifies?.plan
        ) {
          this.statusType =
            savedVerification?.secondaryInsuranceVerifies?.state ?? '';
          this.lastChecked =
            savedVerification?.secondaryInsuranceVerifies?.dateVerified == null
              ? ''
              : new Date(
                savedVerification?.secondaryInsuranceVerifies?.dateVerified
              )?.toLocaleDateString('en-US');
          this.checkedBy =
            savedVerification?.secondaryInsuranceVerifies?.verifiedBy ?? '';
          this.method =
            savedVerification?.secondaryInsuranceVerifies?.verifiedMethod ?? '';
          this.verifiedType =
            savedVerification?.secondaryInsuranceVerifies?.verifiedTypeMethod ??
            '';
        }
        if (
          savedPolicy.tertiaryPolicy != null &&
          savedPolicy.tertiaryPolicy.payorLevel ===
          savedVerification?.tertiaryInsuranceVerifies?.plan
        ) {
          this.statusType = savedVerification?.tertiaryInsuranceVerifies?.state;
          this.lastChecked =
            savedVerification?.tertiaryInsuranceVerifies?.dateVerified == null
              ? ''
              : new Date(
                savedVerification?.tertiaryInsuranceVerifies?.dateVerified
              )?.toLocaleDateString('en-US');
          this.checkedBy =
            savedVerification?.tertiaryInsuranceVerifies?.verifiedBy ?? '';
          this.method =
            savedVerification?.tertiaryInsuranceVerifies?.verifiedMethod ?? '';
          this.verifiedType =
            savedVerification?.tertiaryInsuranceVerifies?.verifiedTypeMethod ??
            '';
        }
      });
    this.subscription$.push(storeSubscription);
  }

  // only number and alpha
  numberAndAlphaOnly(event): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    const inp = String.fromCharCode(event.keyCode);
    if (
      charCode > 31 &&
      (charCode < 48 || charCode > 57) &&
      charCode >= 15 &&
      charCode <= 64
    ) {
      return false;
    }
    return true;
  }
  openVerificationModal() {
    const dialogRef = this.dialog.open(PatientVerificationComponent, {
      disableClose: true,
      data: { patientId: this.patientId },
    });

    const closeDialog = dialogRef.afterClosed().subscribe((result) => {
      result !== '' &&
        result !== null &&
        result !== undefined &&
        this.policyDialogRef.close(result);
    });
    this.subscription$.push(closeDialog);
  }

  openPverifyModal() {
    const dialogRef = this.dialog.open(PatientPverifyComponent, {
      disableClose: true,
      data: { patientId: this.patientId },
    });

    const closeDialog = dialogRef.afterClosed().subscribe(() => { });
    this.subscription$.push(closeDialog);
  }

  openEligibilityHistoryModal() {
    const dialogRef = this.dialog.open(PatientEligibilityHistoryComponent, {
      disableClose: true,
      data: { patientId: this.patientId },
    });
    const closeDialog = dialogRef.afterClosed().subscribe((result) => {
      result !== '' &&
        result !== null &&
        result !== undefined &&
        this.policyDialogRef.close(result);
    });
    this.subscription$.push(closeDialog);
  }

  // ! Copy Patient Info
  copyPatient() {
    this.patientId = this.patientId ?? '';
    if (this.patientId !== '') {
      const copyPatient = this.patientService.get(this.patientId).subscribe(
        (response) => {
          // const selectedPatient: PatientDTO = response?.patientState?.selectedPatient;
          const selectedPatientPersonal: PersonalDTO = response?.personals;
          const selectedPatientBAddress: AddressDTO = response?.addresses[0];
          const selectedPatientDAddress: AddressDTO = response?.addresses[1];
          this.CountryName = selectedPatientBAddress?.country ?? '';
          this.StateName = selectedPatientBAddress?.state ?? '';
          this.CountryName != null &&
            this.CountryName !== '' &&
            this.CountryName != undefined &&
            this.onChangeCountry(this.CountryName);
          this.StateName != null &&
            this.StateName !== '' &&
            this.StateName != undefined &&
            this.onChangeState(this.StateName, this.CountryName);
          selectedPatientPersonal !== null &&
            selectedPatientPersonal !== undefined &&
            this.policyForm.patchValue({
              txtPolicyUser1: selectedPatientPersonal?.user1,
              txtPolicyUser2: selectedPatientPersonal?.user2,
              txtPolicyUser3: selectedPatientPersonal?.user3,
              txtPolicyUser4: selectedPatientPersonal?.user4,
              txtInsuredFirst: selectedPatientPersonal?.firstName,
              txtInsuredMiddle: selectedPatientPersonal?.middleName,
              txtInsuredLastName: selectedPatientPersonal?.lastName,
              txtInsuredSuffix: selectedPatientPersonal?.suffix,
              txtInsuredSsn: selectedPatientPersonal?.ssn,
              txtAddress1: selectedPatientBAddress?.address,
              txtAddress2: selectedPatientDAddress.address,
              txtInsuredBirthDate:
                selectedPatientPersonal?.dateOfBirth == null
                  ? ''
                  : selectedPatientPersonal?.dateOfBirth,
              txtInsuredCity: selectedPatientBAddress?.city,
              txtInsuredState: selectedPatientBAddress?.state,
              txtInsuredCountry: selectedPatientBAddress?.country, // == "" ? null : selectedPatientBAddress?.country,
              txtInsuredPostalCode: selectedPatientBAddress?.postalCode,
              txtInsuredPhone: selectedPatientBAddress?.phone,
              txtInsuredMobile: selectedPatientBAddress?.mobile,
            });

          this.policyForm.patchValue({
            drpGender: response?.clinicals?.genderType,
          });
        },
        (err) => {
          const data: HttpErrorResponse = err;
          Swal.fire({
            icon: 'info',
            text: data?.error?.error?.message,
          });
        }
      );
      this.subscription$.push(copyPatient);
    }
  }
}
export interface planInterface {
  id: string;
  name: string;
  code: string;
  payorId: string;
}
