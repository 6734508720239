import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { defaultGuid } from '../../medicare-check-list/medicare-check-list.component';
import {
  MatDialog,
  MatDialogConfig,
  MatDialogRef,
} from '@angular/material/dialog';
import { FormGroup } from '@angular/forms';
import { PatientPersonalService } from '../../patient-proxy/patient-optimization';
import moment, { Moment } from 'moment';
import { ToastrService } from 'ngx-toastr';
import { CalenderScheduleComponent } from 'projects/schedule/src/app/calender-schedule/calender-schedule.component';
import { LocaleConfig } from 'ngx-daterangepicker-material';
import { Observable, Subject, Subscription } from 'rxjs';
import { MatSelect } from '@angular/material/select';
import { PatientAddPolicyComponent } from '../../patient-add-policy/patient-add-policy.component';
import {
  CreateUpdatePatientNextCallDTO,
  NoteType,
  PatientPolicyDTO,
} from '../../patient-proxy/patient-optimization/dto';
import { AddNoteTabComponent } from '../../add-note-tab/add-note-tab.component';
import { HttpErrorResponse } from '@angular/common/http';
import Swal from 'sweetalert2';
import { PatientCommonNotesComponent } from 'projects/shared/src/app/components/patient-common-notes/patient-common-notes.component';
import { DocumentSortingComponent } from 'projects/admin/src/app/document-sorting/document-sorting.component';
import { ViewPatientLedgerComponent } from 'projects/admin/src/app/view-patient-ledger/view-patient-ledger.component';
import { AdminAddItemModalComponent } from 'projects/admin/src/app/admin-add-item-modal/admin-add-item-modal.component';
import { PatientManualVerificationTabComponent } from '../../patient-manual-verification-tab/patient-manual-verification-tab.component';
import {
  MatDatepicker,
  MatDatepickerInputEvent,
} from '@angular/material/datepicker';
import { DataTableDirective } from 'angular-datatables';
import { PatientAddPolicyTabComponent } from '../../patient-add-policy-tab/patient-add-policy-tab.component';
import { NewVerificationComponent } from '../../newVerification/newVerification.component';
import { DocumentCheckListComponent } from 'projects/admin/src/app/document-check-list/document-check-list.component';
import { CommunicationService } from 'projects/shared/src/app/services/communication.service';
import { DatepickerDialogComponent } from './datepicker-dialog.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { OndemandOnlineVerificationComponent } from '../../ondemand-online-verification/ondemand-online-verification.component';
import { PatientCommonDocumentMedicareUploadTabComponent } from 'projects/shared/src/app/components/patient-common-document-medicare-upload-tab/patient-common-document-medicare-upload-tab.component';
import { DatePipe } from '@angular/common';
import { InboundDocumentService } from 'projects/admin/src/app/admin-proxy/platform-app-management/rcm/platform-management/inbound/inbound-document.service';

@Component({
  selector: 'app-patient-management-table',
  templateUrl: './patient-management-table.component.html',
  styleUrls: ['./patient-management-table.component.scss'],
})
export class PatientManagementTableComponent implements OnInit {
  @HostBinding('style.font') font =
    '400 13px/20px Roboto, "Helvetica Neue", sans-serif';
  @HostBinding('style.letter-spacing') letterSpacing = 'normal';
  @Output() isLoadTbl? = new EventEmitter<void>();
  @Input() tableData: any;
  defaultGuid = defaultGuid;
  tblItemSelectedRowId: string;
  strPdfString:string;
  dtPatientManagementOptions: any = {
    responsive: true,
    // stateSave: true,
    paging: false,
    searching: true,
    dom: 'Bfrtip',
    language: {
      emptyTable: 'No records',
      info: 'Total : _MAX_ records',
      infoEmpty: 'Total : _MAX_ records',
      infoFiltered: '(filtered : _TOTAL_ records)',
      loadingRecords: 'Loading...',
      zeroRecords: 'No matching records',
    },
    scrollCollapse: true,
    scrollY: '500px',
    scrollX: true,
    fixedHeader: true, // Fix the header
    fixedColumns: {
      leftColumns: 3, // Number of columns to fix on the left
      rightColumns: 1, // Number of columns to fix on the right
    },
    columnDefs: [
      { targets: [0], orderable: false },
      { targets: [13,14,15,16], visible: false },
      { targets: [0, 1, 2, -1], className: 'dt-fixed-column' }, // Add class to make the first three and last column fixed
      {
        targets: [5, 6,7,8,9],
        type: 'date',
        render: function (data, type, row) {
          // Assuming data is in the format MM/DD/YYYY
          if (type === 'sort' || type === 'type') {
            var dateParts = data.split('/');
            return new Date(dateParts[2], dateParts[0] - 1, dateParts[1]);
          } else {
            return data;
          }
        },
      },
    ],
    search: {
      smart: false,
    },
    buttons: [
      {
        extend: 'excel',
        text: 'Export',
        filename: 'Patient Management',
        exportOptions: {
          columns: ':visible:not(:first-child) ',
        },
      },
      {
        extend: 'colvis',
        columns: ':not(:eq(0), :eq(1), :eq(2), :eq(-1))', // Exclude first three and last columns from the column visibility toggle
      },
      // {
      //   text: 'Show Default Column',
      //   action: function (e, dt, node, config) {
      //     dt.columns(':not(:lt(3),:last-child)').visible(false);
      //   },
      // },
      // {
      //   text: 'Show All Column',
      //   action: function (e, dt, node, config) {
      //     dt.columns().visible(true);
      //   },
      // },
    ],
  };
  PatientDetails: any;
  tblShpSelectedRowId: string;
  CallLogDetails: any;
  notesData = [];
  // @ViewChild('picker') picker: MatDatepicker<Date>;
  // @ViewChild('datepickerInput') datepickerInput: ElementRef;
  @ViewChild('datepicker') datepicker: MatDatepicker<any>;
  @ViewChild(DataTableDirective, { static: false })
  datatableElement: DataTableDirective;
  customDatepickerClass = 'custom-datepicker';
  itemTableData: any = [];
  hcpcTableData: any = [];
  isOpenfields: boolean = false;
  showFilter: boolean = true;
  showDatepicker = false;
  selectedRange: { startDate: Moment; endDate: Moment };
  ranges: any;
  minDate: Moment;
  maxDate: Moment;
  fromDate: any = '';
  toDate: any = '';
  selectedOption = 'option1';
  calendarLocale: LocaleConfig;
  calendarPlaceholder: string = '';
  alwaysShowCalendars: boolean;
  dateRangStatus: boolean = false;
  organizationUnitName: string = '';
  showDatePicker: boolean = false;
  @ViewChild(CalenderScheduleComponent)
  CalenderScheduleComponent: CalenderScheduleComponent;
  dtTrigger: Subject<any> = new Subject<any>();

  public drpBranch: Branch[] = [];
  public branchList: Observable<Branch[]> | undefined;
  @ViewChild('selectBranch') selectBranch: MatSelect;
  branchForm: FormGroup;
  tenantId: string;
  notesText: string = '';
  isLoaded = false;
  pageType: NoteType = NoteType.general;
  public policyDialogRef: MatDialogRef<PatientAddPolicyComponent>;
  subscription$: Subscription[] = [];
  chartId: string;
  documentId: string;
  patientId: string;
  callReason: any;
  selectedBranchList: string[] = null;
  selectedCSRList: string[] = null;
  nextCallDate: string = null;
  lastMaskProcessDate: string = null;
  policyId: any;
  verifyStatus: any;
  verifyId: any;
  @Output() nevigatePage? = new EventEmitter<number>();
  @Output() getVisibleColumns? =   new EventEmitter<number[]>();
  @Input() currentPage: any = 1;
  @Input() totalPages: any = 1;
  @Input() visibleColumns: number[] ;
  constructor(
    private patientPersonalService: PatientPersonalService,
    private toastr: ToastrService,
    // private PatientPersonalService: PatientPersonalService,
    private dialog: MatDialog,
    private communicationService: CommunicationService,
    private snackBar: MatSnackBar,
    private datepipe:DatePipe,
    private inboundDocumentService: InboundDocumentService,
  ) {
    this.ranges = {
      Today: [moment(), moment()],
      Yesterday: [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
      'Current week': [moment().startOf('isoWeek'), moment().endOf('isoWeek')],
      'Last 7 Days': [moment().subtract(6, 'days'), moment()],
      'Last 30 Days': [moment().subtract(29, 'days'), moment()],
      'This Month': [moment().startOf('month'), moment().endOf('month')],
      'Last Month': [
        moment().subtract(1, 'month').startOf('month'),
        moment().subtract(1, 'month').endOf('month'),
      ],
    };
  }
  tableData$: Subscription;
  @Input() defaultSelectedRow: string;
  @Output() rowSelected = new EventEmitter<string>();
  ngOnInit() {
    this.PatientDetails = this.tableData;
    this.donotCallReason();
    this.calculateScrollY();
    window.addEventListener('resize', this.calculateScrollY);

    this.dtPatientManagementOptions.buttons[0].filename =
    'Patient Management' + '-' + this.datepipe.transform(new Date(), 'MM-dd-yyyy');
  }
  //After View Method
  ngAfterViewInit(): void {
    if (this.datatableElement && this.datatableElement.dtInstance) {
      this.refreshDataTable();
    } else {
      // If dtInstance is not available yet, wait for a short time and try again
      setTimeout(() => this.refreshDataTable(), 500);
    }
  }

  onRowDoubleClick(textToCopy) {
    this.copyTextToClipboard(textToCopy);
    this.showNotification('Text copied: ' + textToCopy);
  }

  showNotification(message: string): void {
    this.snackBar.open(message, 'Close', {
      duration: 3000, // Duration in milliseconds
      horizontalPosition: 'end', // Positioning the notification horizontally
      verticalPosition: 'bottom', // Positioning the notification vertically
    });
  }

  changePage(isNext:boolean){
    if(isNext)
      this.currentPage++;
    else 
      this.currentPage--;
    this.nevigatePage.emit(this.currentPage);
  }

  fnGotoPage(){
    let pageValue = $("#txtGotoPage").val();
    let pageno = parseInt(pageValue.toString());
    if(pageno > 0 && this.currentPage !=  pageno && pageno <= this.totalPages)
    {
      this.nevigatePage.emit(pageno);
    }
  }


  fnColumnSelectionClick(){
    $(".buttons-columnVisibility").on('click', (event) => this.fnGetColumnVisiblity("ONE"));
  }

  fnGetColumnVisiblity(event){
    
    let visibleColumns = [];
    if(event == "ALL")
    {
      this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => { 
        dtInstance.columns().visible(true);
      });
    }
    else 
    { 
      this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => { 
        if(event == "DEFAULT")
          dtInstance.columns(':not(:lt(3),:last-child)').visible(false);

        var index = 0;
        dtInstance.columns().every(function () {
          const that = this;
          if(this.visible())
            visibleColumns.push(index)
          index++; 
        });
      });
    } 

    this.getVisibleColumns.emit(visibleColumns);
  }

  addPageNavigationControls(){
    let buttonsElement =  $(".dt-buttons")[0];

    $("<button class='dt-button' id='btnDefaultClms' type='button'><span>Show Default Column</span></button>").appendTo(buttonsElement);
    $("<button class='dt-button' id='btnAllClms' type='button'><span>Show All Column</span></button>").appendTo(buttonsElement);
    
    $("<span> : <span>").appendTo(buttonsElement);
    if(this.currentPage == 1)
    {
      $("<button disabled title='First Page' class='dt-button' id='btnFirstPage' type='button'><span>&lt;&lt;</span></button>").appendTo(buttonsElement);
      $("<button disabled class='dt-button' id='btnPrevPage' type='button'><span>&lt;</span></button>").appendTo(buttonsElement);
    }
    else 
    {
      $("<button title='First Page' class='dt-button' id='btnFirstPage' type='button'><span>&lt;&lt;</span></button>").appendTo(buttonsElement);
      $("<button title='Previous Page' class='dt-button' id='btnPrevPage' type='button'><span>&lt;</span></button>").appendTo(buttonsElement);
     }

    $("<span> Page <span>").appendTo(buttonsElement);
    $("<input style='width:50px;' id='txtGotoPage' value='" + this.currentPage + "' type='text'/>").appendTo(buttonsElement);
    $("<span> of " + this.totalPages + " <span>").appendTo(buttonsElement);
    $("<button  class='dt-button' id='btnGoToPage'   type='button'><span>Go to</span></button>").appendTo(buttonsElement);
    
    if(this.currentPage >= this.totalPages)
    {
      $("<button disabled class='dt-button' id='btnNextPage'   type='button'><span>&gt;</span></button>").appendTo(buttonsElement);
      $("<button disabled title='Last Page'  class='dt-button' id='btnLastPage'  type='button'><span>&gt;&gt</span></button>").appendTo(buttonsElement);
    }
    else
    {
       $("<button title='Next Page'  class='dt-button' id='btnNextPage'  type='button'><span>&gt;</span></button>").appendTo(buttonsElement);
       $("<button title='Last Page'  class='dt-button' id='btnLastPage'  type='button'><span>&gt;&gt</span></button>").appendTo(buttonsElement);
    }

    $('#btnNextPage').on('click', (event) => this.changePage(true));
    $('#btnPrevPage').on('click', (event) => this.changePage(false));
    $(".buttons-colvis").on('click', (event) => this.fnColumnSelectionClick());
    $('#btnDefaultClms').on('click', (event) => this.fnGetColumnVisiblity("DEFAULT"));
    $('#btnAllClms').on('click', (event) => this.fnGetColumnVisiblity("ALL"));
    $('#btnGoToPage').on('click', (event) => this.fnGotoPage());
    $('#btnFirstPage').on('click', (event) => this.nevigatePage.emit(1));
    $('#btnLastPage').on('click', (event) => this.nevigatePage.emit(this.totalPages));
   }
  copyTextToClipboard(text: string): void {
    const textArea = document.createElement('textarea');
    textArea.value = text;
    document.body.appendChild(textArea);
    textArea.select();
    document.execCommand('Copy');
    textArea.remove();
  }

  openDatepicker(patientId: string, nextcallDate: string) {
    const dialogRef = this.dialog.open(DatepickerDialogComponent, {
      disableClose: true,
      // minHeight: '20vh',
      // minWidth: '80vw',
      data: {
        patientId: patientId,
        tenantId: this.tenantId,
        defaultDate: nextcallDate,
      },
    });

    dialogRef.afterClosed().subscribe(() => {
      // Handle any actions after the dialog is closed

      // this.communicationService.triggerNextCallUpdateUpdateGetPatientMnt();
      // this.communicationService.triggerpatientList(patientId);
      this.rowSelected.emit(patientId);

      // setTimeout(() => this.communicationService.triggerpatientList(patientId), 500);

      setTimeout(
        () =>
          this.communicationService.triggerNextCallUpdateUpdateGetPatientMnt(),
        500
      );
    });
  }

  //After View Method
  refreshDataTable(): void {
    this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
      // Clear search input values
      $('input', dtInstance.table(0).footer()).val('');
      this.addPageNavigationControls();
      // Redraw the DataTable to load all records
      dtInstance.search('').columns().search('').draw();

      // Attach the event listener for filtering
      dtInstance.columns().every(function () {
        const that = this;
        $('input', this.footer()).on('keyup change', function () {
          if (that.search() !== this['value']) {
            that.search(this['value']).draw();
          }
        });
      });
    });
  }
  ngOnDestroy(): void {
    this.subscription$?.forEach((sub) => {
      sub && sub?.unsubscribe();
    });
    this.dtTrigger.unsubscribe();
    window.removeEventListener('resize', this.calculateScrollY);
  }

  calculateScrollY = () => {
    const screenHeight =
      window.innerHeight ||
      document.documentElement.clientHeight ||
      document.body.clientHeight;
    const availableSpacePercentage = 0.4; // Adjust the percentage as needed
    this.dtPatientManagementOptions.scrollY =
      screenHeight * availableSpacePercentage;
  };

  onDateSelected(event: MatDatepickerInputEvent<Date>) {
    // Additional logic when a date is selected
    // let nextCall: CreateUpdatePatientNextCallDTO = {
    //   patientId: this.patientId,
    //   nextCallDate: new Date(event.value).toISOString(),
    //   isNextCall: true,
    //   tenantId: this.tenantId,
    // };
    // const netCallDate = this.patientPersonalService
    //   .patientNextCall(nextCall)
    //   .subscribe((response) => {
    //     this.toastr.success('Save Successfully', 'Success');
    //     this.communicationService.triggerNextCallUpdateUpdateGetPatientMnt();
    //     this.rowSelected.emit(response?.patientId);
    //   });
    // this.picker.close();
  }
  tblItemSelectedRow(patientId: string, documentId: string, chartId: string) {
    this.defaultSelectedRow = 'N/A';
    this.tblItemSelectedRowId = patientId;
    // this.tblItemSelectedRowId === patientId ? '' : patientId;
    if (this.patientId !== patientId) {
      this.patientId = patientId;
      this.documentId = documentId;
      this.chartId = chartId;
      this.communicationService.triggerpatientList(patientId);
    }
  }
  getHCPCCodes(id: string) {
    this.hcpcTableData =
      this.hcpcTableData.length > 0 ? [] : this.hcpcTableData;

    this.hcpcTableData = [
      { code: 'A4520' },
      { code: 'TD51245' },
      { code: 'AC54621' },
      { code: 'AC54621' },
      { code: 'AC54621' },
      { code: 'AC54621' },
    ];
  }
  openAddNotesPopUp() {
    const config: MatDialogConfig = {
      disableClose: true,
      minHeight: '30vh',
      minWidth: '50vw',
      data: {
        noteType: 'patient', // 'Order Note',
        notesId: '00000000-0000-0000-0000-000000000000',
        // orderId: '0b50be04-6c75-92d3-97c6-3a0e17ee6a18',
        patientId: this.patientId,
        defaultFaxId: this.chartId,
        isCallNote: false,
        isPending: false,
        docId: this.documentId,
        inboundStatusShortCodeId: defaultGuid,
        selectedNode: defaultGuid,
        addnotes: true,
      },
    };
    const dialogRef = this.dialog.open(AddNoteTabComponent, config);

    const closeDialog = dialogRef.afterClosed().subscribe(
      () => {
        // this.getReminderTableData();
      },
      (err) => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      }
    );
    this.subscription$.push(closeDialog);
  }

  // ScheduleAppointmentModal(data){

  //   this.tableService.setCalenderPopup(data)
  //   this.router.navigate(['settings/calender'])
  // // this.CalenderScheduleComponent.onPopupOpen(data)
  // }
  openViewNotesPopup() {
    const config: MatDialogConfig = {
      disableClose: true,
      width: '70vw',
      maxWidth: '70vw',
      height: '40vw',
      maxHeight: '40vw',
      data: {
        patientId: this.patientId,
        pageType: this.pageType,
        isAddVisible: false,
      },
    };
    const dialogRef = this.dialog.open(PatientCommonNotesComponent, config);

    dialogRef.afterClosed().subscribe(
      () => {
        //this.getReminderTableData();
      },
      (err: HttpErrorResponse) => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      }
    );
  }
  viewDocument(
    patientId: string,
    documentId: string,
    defaultDocumentId: string,
    isSorted: boolean
  ) {
    const dialogRef = this.dialog.open(DocumentSortingComponent, {
      width: '100vw',
      height: '100vh',
      maxWidth: '100vw',
      maxHeight: '100vh',
      panelClass: 'fullscreen-dialog-panel',
      disableClose: true,
      data: {
        // Add any data you want to pass to the dialog here
        // For example, you can pass patientId and documentId
        patientId: patientId,
        documentId: documentId,
        defaultDocumentId: defaultDocumentId,
        isSorted: isSorted,
      },
    });

    dialogRef.afterClosed().subscribe(() => {
      // Handle the dialog close event here if needed
    });
  }
  viewPatientLedger(patientId: string) {
    this.dialog.open(ViewPatientLedgerComponent, {
      width: '100vw',
      height: '100vh',
      maxWidth: '100vw',
      maxHeight: '100vh',
      panelClass: 'fullscreen-dialog-panel',
      disableClose: true,
      data: {
        patientId: patientId,
      },
    });
    this.dialog.afterAllClosed.subscribe(() => {});
  }
  openAddItemModal() {
    const dialogRef = this.dialog.open(AdminAddItemModalComponent, {
      disableClose: true,

      data: { patientsId: defaultGuid },
    });
    dialogRef.afterClosed().subscribe(() => {
      // this.getPatientSIdFormPatientSDetails(defaultGuid);
    });
  }
  //Toggle Filter Div
  toggleFilter() {
    this.showFilter = !this.showFilter;
  }
  openVerificationModal(policy) {
    const dialogRef = this.dialog.open(PatientManualVerificationTabComponent, {
      disableClose: true,
      data: { patientId: policy.patientId, policyId: policy.policyId },
    });

    const closeDialog = dialogRef.afterClosed().subscribe(() => {
      // result !== '' &&
      //   result !== null &&
      //   result !== undefined &&
      //   this.policyDialogRef.close(result);
      // this.policyId !== defaultGuid && this.getVerificationStatus();
    });
    this.subscription$.push(closeDialog);
  }

  ViewPolicy(policy: PatientPolicyDTO) {
    const dialogRef = this.dialog.open(PatientAddPolicyTabComponent, {
      data: {
        patientId: policy.patientId,
        policyId: policy.policyId,
        wipId: policy.wipId ?? defaultGuid,
      },
    });
    const aftClosed = dialogRef.afterClosed().subscribe(
      () => {
        // this.patientIdEmitter.next(this.patientId);
        // this.patientId !== defaultGuid && this.getTableData();
      },
      (err) => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      }
    );
    this.subscription$.push(aftClosed);
  }

  // openPverifyModal(data) {

  //   const dialogRef = this.dialog.open(PatientPverifyComponent, {
  //     disableClose: true,
  //     data: { patientId: "7716cab4-407f-795a-5c25-3a0ec74e6765"},
  //   });

  //   const closeDialog = dialogRef.afterClosed().subscribe(() => { });
  //   this.subscription$.push(closeDialog);
  // }
  //! open policy modal using config
  openPverifyModal(data) {
    this.subscription$.push();
  }
  assignDoNotCall(patientId: string, reasonId: string) {
    this.patientPersonalService
      .assignDoNotCallReasonByPatientIDAndAssignDoNotCallReasonV1(
        patientId,
        reasonId
      )
      .subscribe(() => {
        this.toastr.success('Save Successfully', 'Success');
      });
  }
  openNewVerification(patientId: string, isOnline: boolean) {
    const config: MatDialogConfig = {
      disableClose: true,
      data: {
        patientId: patientId,
        isOnline: isOnline,
      },
    };
    const dialogRef = this.dialog.open(NewVerificationComponent, config);

    const closeDialog = dialogRef.afterClosed().subscribe(
      () => {
        // this.getReminderTableData();
      },
      (err) => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      }
    );
    this.subscription$.push(closeDialog);
  }
  checkListModel(data) {
    const dialogRef = this.dialog.open(DocumentCheckListComponent, {
      // height: '75%',
      width: '80%',
      disableClose: true,
      data: {
        // Add any data you want to pass to the dialog here
        // For example, you can pass patientId and documentId

        documentId: data.documentId,
        faxId: data.faxId || 'N/A',
      },
    });

    // Subscribe to the closed event
    dialogRef.afterClosed().subscribe(() => {
      // Call the addNotes function directly
      dialogRef.componentInstance.addNotes();
    });
  }
  isEmpty(value: any): boolean {
    return value === null || value === undefined || value === '';
  }

  donotCallReason() {
    this.patientPersonalService.getdonotCallReason(1).subscribe(
      (response) => {
        this.callReason = response.items;
      },
      (error) => {
        console.error('Error fetching call reason details:', error);
      }
    );
  }
  ondemandOnlineVerification(policy:any){
    if (policy.priPolicyId === defaultGuid) {
      if (policy.secPolicyId === defaultGuid) {
        this.policyId = policy.secPolicyId;
      } else {
        this.policyId = policy.terPolicyId;
      }
    } else {
      this.policyId = policy.priPolicyId;
    }
    if (policy.priVerificationId === defaultGuid) {
      if (policy.secVerificationId === defaultGuid) {
        this.verifyId = policy.secVerificationId;
      } else {
        this.verifyId = policy.terVerificationId;
      }
    } else {
      this.verifyId = policy.priVerificationId;
    }
    if (policy.priVerificationStatus === null ||policy.priVerificationStatus === '' ) {
      if (policy.secVerificationStatus === null ||policy.secVerificationStatus === '') {
        this.verifyStatus= policy.secVerificationStatus;
      } else {
        this.verifyStatus = policy.terVerificationStatus;
      }
    } else {
      this.verifyStatus = policy.priVerificationStatus;
    }
    const dialogRef = this.dialog.open(OndemandOnlineVerificationComponent, {
      data: {
        patientId: policy.patientId,
        policyId: this.policyId??defaultGuid,
        documentId: policy.docId,
        wipId: policy.wipId ?? defaultGuid,
        // selectedNode: this.selectedNode,
        verificationId: this.verifyId,
        verificationStatus:this.verifyStatus,

      },
    });
    const aftClosed = dialogRef.afterClosed().subscribe(
      (result) => {
        // this.patientIdEmitters.next(policy.patientId);
        this.isLoadTbl.emit();
        // policy.patientId !== defaultGuid && this.getTableData();
      },
      (err) => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      }
    );
    this.subscription$.push(aftClosed);
  }
  //To add inbound documents manually
  addDocumentType(patientId: string, wipId: string) {
    const dialogData = {
      constructorpatientId: patientId ?? defaultGuid, // Replace with your actual patientId value
      constructortenantId: this.tenantId ?? defaultGuid,       // Replace with your actual tendid value
      selectedWipId: wipId ?? defaultGuid,
      isPatientList: true,
      isPatientDisable:true
    };
    const dialogRef = this.dialog.open(PatientCommonDocumentMedicareUploadTabComponent, {
      minHeight: '33vh',
      minWidth: '75vw',
      data: dialogData,
      disableClose: true
    });
  }

   //#region Patient Document PDF Download
 patientDocPdfDownload(data:any) {
  this.inboundDocumentService.downloadInboundDocumentByBlobName(data?.blobname)
  .subscribe(
    (response) => {
        if (
          typeof response === 'string' &&
          response &&
          response !== null &&
          response.length !== 0
        ) {
          this.strPdfString = String(response);
          // Decode base64 string to binary data
          const byteCharacters = atob(this.strPdfString);
          const byteNumbers = new Array(byteCharacters.length);
          for (let i = 0; i < byteCharacters.length; i++) {
              byteNumbers[i] = byteCharacters.charCodeAt(i);
          }
          const byteArray = new Uint8Array(byteNumbers);

          const blob = new Blob([byteArray], { type: "application/pdf" });
          // const fileName = this.defaultFaxId;
          const fileName = data?.chartId + '-' + data?.patientName;
          // Create a link element
          const link = document.createElement('a');
          link.href = window.URL.createObjectURL(blob);
          link.download = fileName;

          // Append the link to the body
          document.body.appendChild(link);

          // Programmatically click the link to trigger the download
          link.click();

          // Remove the link from the DOM
          document.body.removeChild(link);
        }
      },
      (err: HttpErrorResponse) => {
        console.error('Error downloading the document', err);
      }
    );
  }
// #endregion Patient Document PDF Download

}
export interface Branch {
  id?: string;
  organizationUnitName?: string;
}
