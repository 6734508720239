import { mapEnumToOptions } from '@abp/ng.core';

export enum ResupplyStausList {
  CurrentSupplies = 0,
  OverDueSupplies = 1,
  InactiveSupplies = 2,
  ArchiveSupplies = 3,
  Above90Days=4,
}

export const resupplyStausListOptions = mapEnumToOptions(ResupplyStausList);
