<div class="py-2" style="border: 2px">
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-5 d-inline-flex align-items-center">
        <!-- <h2 class="mb-0"><b>Master / Document Bin</b></h2> -->
        <h3 class="font-weight-bolder mb-3">
          <b class="customThemeClass">Master / Document Bin</b>
        </h3>
      </div>
      <div class="col-md-7">
        <div class="d-flex flex-row-reverse" style="margin-right: 8%;">
          <mat-radio-group [(ngModel)]="selectedOption" (change)="getDocumentBinList()">
            <mat-radio-button [value]="0" class="active"><b>All</b></mat-radio-button>
            <mat-radio-button [value]="1" class="active p-2" [checked]="true"><b>Active</b></mat-radio-button>
            <mat-radio-button [value]="2" class="active"><b>Inactive</b></mat-radio-button>
          </mat-radio-group>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="card card-body">

 

  <app-master-document-bin-table [tableData]="documnetDataList" *ngIf="!isShowSpinner"></app-master-document-bin-table>
  <div class="row" *ngIf="isShowSpinner">
    <div class="col-12">
      <div class="d-flex justify-content-center" *ngIf="isShowSpinner">
        <img class="img-responsive pb-2 mr-1" src="assets/gifs/Infinity-loader.gif" />
      </div>
    </div>
  </div>

  <!-- <mat-accordion>
    <mat-expansion-panel (toggle)="(step)" [expanded]="step" (afterExpand)="toggle()" (closed)="toggleV2()">
      <mat-expansion-panel-header>
        <mat-panel-title class="customThemeClass">
          <ng-container *ngIf="documentBinId === defaultGuid; else elseTitleTemplate">
            <b>Add New Bin</b>
          </ng-container>
          <ng-template #elseTitleTemplate>
            <b>Edit Bin</b>
          </ng-template>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <form [formGroup]="mstDocumentBinForm">
        <div class="">
          
          <div class="row">
            <mat-form-field class="col-3">
              <mat-label>Bin Name<span class="asterisk">*</span></mat-label>
              <input matInput formControlName="txtBinName" placeholder="Enter Document Bin Name" maxlength="50"
                autocomplete="new-txtBinName" type="text" />
              <mat-error *ngIf="
                mstDocumentBinForm?.get('txtBinName')?.touched &&
                mstDocumentBinForm?.get('txtBinName')?.errors?.required
              ">
                Bin Name is a required field!
              </mat-error>
              <mat-error *ngIf="mstDocumentBinForm?.get('txtBinName')?.errors?.isDocumentBinExists">
                Bin Name is already taken!
              </mat-error>
            </mat-form-field>
            <button style="height: 35px" mat-button class="buttonColor mt-1 mr-1" (click)="saveBin()" [disabled]="
              saveButtonHide ||
              !(!mstDocumentBinForm.invalid && mstDocumentBinForm.dirty)
            ">
              Save
              <mat-icon *ngIf="isShowSpinner">
                <mat-spinner class="spinner-border spinner-border-sm" diameter="90">
                </mat-spinner>
              </mat-icon>
            </button>
            <button mat-button style="height: 35px" class="resetclr bg-danger buttonColor mt-1"
              (click)="resetBinForm()">
              Reset
            </button>
          </div>
          
        </div>
      </form>
      <br />
    </mat-expansion-panel>
  </mat-accordion>
  <br>
  <div class="card card-body">
    <div class="row" *ngIf="!isShowSpinner">
      <div class="col-12">
        <div *ngIf="!isShowSpinner" class="table table-responsive">
          <table id="tbl" datatable [dtOptions]="dtOptions" style="width: 100%" class="row-border hover">
            <thead>
              <tr>
                <th class="text-center">Option</th>
                <th class="text-center">Document Bin Name</th>
                <th class="text-center">Created Date</th>
                <th class="text-center">Status</th>
              </tr>
            </thead>
            <tbody *ngIf="!isShowSpinner">
              <tr *ngFor="let data of documnetDataList " [ngClass]="{stat_highlight:data.isStat == true}">
                <td class="text-center">
                  <a class="eyeCursorclass" (click)="viewDocumentBinById(data?.id)">
                    <i class="fa fa-pencil editBtnColor mr-1" matTooltip="Click To Edit"></i>
                  </a>
                  &nbsp;
               
                </td>
                <td class="text-center">
                  {{ data?.documentType }}
                </td>
                <td class="text-center">
                  {{ data?.creationTime | date : "MM/dd/yyyy" : "en_US" }}
                </td>
                <td class="text-center">
                  <ng-container *ngIf="data?.isAlreadyInUse==false">
                    <mat-slide-toggle (change)="statusChange($event, data)" [checked]='true' class="ml-2">
                      {{data?.status}}</mat-slide-toggle>
                  </ng-container>
                  <ng-container *ngIf="data?.isAlreadyInUse==true">
                    In Use
                  </ng-container>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div> -->
</div>