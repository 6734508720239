<!-- <h2 mat-dialog-title>Add Diagnosis Code ICD - 10</h2> -->
<!-- <div class="row">
  <div class="col-lg-6">
  <h2>Add Diagnosis Code ICD - 10</h2>
  </div>
  <div class="col-lg-6 text-right">
   <button class="text-right close"  aria-label="Close" mat-dialog-close> <b> X </b>
    </button>
  </div>
</div> -->
<!-- <mat-dialog-content class="mat-typography">
  <div class="row">
    <div class="col-lg-6">
      <h2>Add Diagnosis Code ICD - 10</h2>
    </div>
    <div class="col-lg-6 text-right">
      <button class="text-right close" aria-label="Close" mat-dialog-close><b> X </b></button>
    </div>
  </div>

  <div class="row">
    <div class="col-lg-12">
      <form [formGroup]="ICDCode10Form">
        <mat-card-content>
          <div class="row">
            <!-- &nbsp; &nbsp; -->
            <!-- <mat-form-field class="col-6">
              <mat-label>ICD code / Name <span class="asterisk">*</span></mat-label>
              <input
                matInput
                type="text"
                maxlength="40"
                [(ngModel)]="txtICDCode"
                formControlName="txtICDCode"
              />
            </mat-form-field>
            <div class="col-6">
              <button
                mat-button
                [disabled]="ICDCode10Form.invalid"
                (click)="SearchICDCode10()"
                class="buttonColor mb-2 mr-2"
              >
                Search
              </button>
              <button mat-button (click)="Reset()" class="resetclr buttonColor mb-2 mr-2">Reset</button>
            </div>
          </div>
        </mat-card-content>
      </form>
    </div>
  </div> -->
  <!-- <div class="row">
    <div class="col-lg-12">
      <div class="table-responsive mb-0">
        <app-table [strPageType]="strPageType" (viewButton)="AddICD10($event)"></app-table>
        </div> -->
        <!-- <table
            id="basic-datatable"
            class="table table-bordered dt-responsive nowrap dataTable no-footer dtr-inline"
          >
            <thead>
              <tr>
                <th scope="col">S.NO</th>
                <th scope="col">Code</th>
                <th scope="col">Description</th>
                <th scope="col">Action</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let data of icd10TableData; let i = index">
                <td>
                  <p class="text-truncate font-size-14">
                    {{ i + 1 }}
                  </p>
                </td>
                <td>
                  <h5 class="text-truncate font-size-14">
                    <a href="javascript: void(0);" class="text-dark">
                      {{ data.DiagnosisCode }}
                    </a>
                  </h5>
                </td>
                <td>
                  <h5 class="text-truncate font-size-14">
                    <a href="javascript: void(0);" class="text-dark">
                      {{ data.DiagnsoisCodeData }}
                    </a>
                  </h5>
                </td>
                <td>
                  <div *ngIf="data.isExists; then content; else other_content">here is ignored</div>
                  <ng-template #content>Already Exists</ng-template>
                  <ng-template #other_content
                    ><a class="eyeCursorclass" (click)="AddICD10(data)">
                      <i class="mdi mdi-account-plus mdi-18px"></i>
                    </a>
                  </ng-template>
                </td>
              </tr>
            </tbody>
          </table> -->

    <!-- </div>
  </div>
</mat-dialog-content>  -->

<mat-dialog-content class="mat-typography">
  <div class="row">
    <div class="col-lg-6">
      <h2>Add Diagnosis Code ICD - 10</h2>
    </div>
    <!-- <div class="col-lg-6 text-right">
      <button class="text-right close" aria-label="Close" mat-dialog-close><b> X </b></button>
    </div> -->
  </div>

  <div class="row">
    <div class="col-lg-12">
      <mat-card-content>
        <form [formGroup]="ICDCode10Form">
          <div class="row">
            <mat-form-field class="col-6">
              <mat-label>ICD code / Name <span class="asterisk">*</span></mat-label>
              <input autocomplete="new-txtICDCode" matInput type="text" maxlength="40" [(ngModel)]="txtICDCode" formControlName="txtICDCode" />

            </mat-form-field>
            <div class="col-6">
              <button mat-button [disabled]="ICDCode10Form.invalid || !txtICDCode" (click)="SearchICDCode10()"
                class="buttonColor mb-2 mr-2">
                Search
              </button>
              <!-- <button mat-button (click)="Reset()" class="resetclr mb-2 mr-2">Reset</button> -->
            </div>
          </div>
        </form>
      </mat-card-content>
    </div>

    <div class="col-lg-12">
      <div class="table-responsive mb-0">
        <app-table [strPageType]="strPageType" [isLoading]="isLoading" (viewButton)="AddICD10($event)"></app-table>
        <!-- <table class="table table-bordered dt-responsive nowrap dataTable no-footer dtr-inline">
            <thead>
              <tr>
                <th scope="col">S.NO</th>
                <th scope="col">Code</th>
                <th scope="col">Description</th>
                <th scope="col">Action</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let data of icd9TableData; let i = index">
                <td>
                  <p class="text-truncate font-size-14">
                    {{ i + 1 }}
                  </p>
                </td>
                <td>
                  <h5 class="text-truncate font-size-14">
                    <a href="javascript: void(0);" class="text-dark">
                      {{ data.DiagnosisCode }}
                    </a>
                  </h5>
                </td>

                <td>
                  <h5 class="text-truncate font-size-14">
                    <a href="javascript: void(0);" class="text-dark">
                      {{ data.DiagnsoisCodeData }}
                    </a>
                  </h5>
                </td>
                <td>
                  <div *ngIf="data.isExists; then content; else other_content">here is ignored</div>
                  <ng-template #content>Already Exists</ng-template>
                  <ng-template #other_content
                    ><a class="eyeCursorclass" (click)="AddICD9(data)">
                      <i class="mdi mdi-account-plus mdi-18px"></i>
                    </a>
                  </ng-template>
                </td>
              </tr>
            </tbody>
          </table> -->
      </div>
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end">

  <button class="buttonCancelColor" mat-button mat-dialog-close>Close</button>
 <!-- <button mat-button [mat-dialog-close]="true" cdkFocusInitial>Install</button> -->
</mat-dialog-actions>
