import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { CreateUpdateDeliveryTicketDTO } from 'projects/order/src/app/order-proxy/order-management/order/dto/models';
import { SaleorderService } from 'projects/order/src/app/order-proxy/order-management/order/saleorder.service';
import { getOrder } from 'projects/order/src/app/order-store/order.action';
import { Subscription } from 'rxjs';
import Swal from 'sweetalert2';
import { ToastrService } from "ngx-toastr";
import emailMask from 'text-mask-addons/dist/emailMask';
const defaultUrl = "../../../assets/images/user.png"

@Component({
  selector: 'app-shipping-ticket',
  templateUrl: './shipping-ticket.component.html',
  styleUrls: ['./shipping-ticket.component.scss']
})
export class ShippingTicketComponent implements OnInit, OnDestroy {
  emailMask: any;
  addrCtrl = new FormControl();
  orderId: string = "";
  displayedColumns: string[] = [  'name', 'phn', 'date', 'hcpc', 'Status','Options',];
  dataSource: MatTableDataSource<any> = new MatTableDataSource<any>([]);
  userSearchForm: FormGroup;
  patientName: string = "";
  location: string = "";
  Address: string = "";
  // variable declarations
  shippingTicketForm: FormGroup;
  subscription$: Subscription[] = [];


  constructor(
    private formBuilder: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private store: Store,
    private orderService: SaleorderService,
    private toastr: ToastrService,
    public router: Router
  ) { }

  ngOnInit(): void {
    this.shippingTicketForm = this.formBuilder.group({
      txtPatientName: new FormControl(""),
      drpLocation: new FormControl(""),
      txtAddress: new FormControl(""),
      txtsaleOrderId: new FormControl(""),
      txtAccountNumber: new FormControl(""),
      txtDateOfBirth: new FormControl(""),
      txtLandLine: new FormControl(""),
      txtPhoneNo: new FormControl(""),
      txtMinPress: new FormControl("", [Validators.max(99999)]),
      txtEstAllowable: new FormControl("", [Validators.max(9999999999)]),
      txtTotalAccesories: new FormControl(""),
      txtShippingFee: new FormControl(""),
      txtAmountPadi: new FormControl(""),
      txtBalance: new FormControl(""),
      txtTechnician: new FormControl(""),
      txtPhysician: new FormControl(""),
      txtReferalPractice: new FormControl(""),
      txtBillingEmailAddress: new FormControl(""),
      txtSubject: new FormControl(""),
      txtRate: new FormControl("", [Validators.max(99999)]),
      txtSetVI: new FormControl("", [Validators.max(99999)]),
      txtIPAPMax: new FormControl("", [Validators.max(99999)]),
      txtIPAPMin: new FormControl("", [Validators.max(99999)]),
      txtRRBPM: new FormControl("", [Validators.max(99999)]),
      txtCFICX: new FormControl("", [Validators.max(99999)]),
      txtSetPress: new FormControl("", [Validators.max(99999)]),
      txtBIFlex: new FormControl("", [Validators.max(99999)]),
      txtRampTime: new FormControl("", [Validators.max(99999)]),
      txtRampPress: new FormControl("", [Validators.max(99999)]),
      txtEPAP: new FormControl("", [Validators.max(99999)]),
      txtIPAP: new FormControl("", [Validators.max(99999)]),
      txtSetting: new FormControl("", [Validators.max(99999)]),
      txtMaxPress: new FormControl("", [Validators.max(99999)]),
      txtSunrise: new FormControl(""),
      txtDue: new FormControl(""),
      txtService: new FormControl(""),
      txtTollFreeNum: new FormControl(""),
      txtSignature: new FormControl(""),
      txtDate: new FormControl(""),
      txtComments: new FormControl(""),
      txtserialNo: new FormControl("")
    })
    this.activatedRoute.paramMap.subscribe((response) => {
      this.orderId = response?.get('orderId') ?? '';
    });
    this.emailMask = emailMask;
    this.getLogo();
    this.getOrderById();
  }

  ngOnDestroy(): void {
    this.subscription$?.forEach((sub) => {
      sub && sub?.unsubscribe();
    });
  }


  itemTable: any[] = [];
  saleOrderId: string = '';
  insurance: string = '';
  dob: string = '';

  /// get initial patch datas
  getOrderById() {
    const ordetGet = this.orderService.get(this.orderId).subscribe(orderResponse => {
      // this.store.dispatch(new getOrder(this.orderId)).subscribe(orderResponse => {
      let patientResponse = orderResponse;
      this.patientName = patientResponse?.patients?.personals?.fullName;
      this.location = patientResponse?.patients?.addresses[1]?.country + ',' + patientResponse?.patients?.addresses[1]?.state + ',' + patientResponse?.patients?.addresses[1]?.city;
      this.Address = patientResponse?.shippingCheckList.deliveryLocation;
      this.insurance = patientResponse?.patients?.policies?.primaryPolicy?.insuranceType;
      this.dob = patientResponse?.patients?.personals?.dateOfBirth == null ? undefined : new Date(patientResponse?.patients?.personals?.dateOfBirth)?.toLocaleDateString("en-US")
      this.saleOrderId = patientResponse?.saleorderId;
      if (patientResponse?.deliveryTicket === null) {
        this.shippingTicketForm.patchValue({
          txtsaleOrderId: patientResponse?.patients?.defaultPatientId,
          txtAccountNumber: patientResponse?.patients?.personals?.accountNumber,
          txtDateOfBirth: patientResponse?.shippingCheckList?.defaultTicketId,
          txtBillingEmailAddress: patientResponse?.patients.personals.emailId,
          txtLandLine: patientResponse?.patients?.addresses[1]?.mobile,
          txtPhoneNo: patientResponse?.patients?.addresses[1]?.phone,
          txtTechnician: patientResponse?.shippingCheckList?.userName,
          txtPhysician: patientResponse?.patients?.clinicals?.primaryDoctor,
          txtReferalPractice: patientResponse?.patients?.clinicals?.referringProviderDoctor
        })
        this.itemTable = [];
        patientResponse?.items?.forEach(value => {
          this.itemTable.push({

            productName: value?.name,
            brandName: value?.brandName,
            categoryName: value?.categoryName,
            qty: value?.qty,
            price: value?.charge,
            lotId: value?.id,
            orderId: orderResponse?.id,
            productLotDetails: value?.productLotDetails,
            productId: value.productId,
            itemId: value?.itemId
            // lotNo: value?.l

          })
        })
      } else {
        this.shippingTicketForm.patchValue({
          txtLandLine: patientResponse?.patients?.addresses[1]?.mobile,
          txtPhoneNo: patientResponse?.patients?.addresses[1]?.phone,
          txtTechnician: patientResponse?.shippingCheckList?.userName,
          txtPhysician: patientResponse?.patients?.clinicals?.primaryDoctor,
          txtReferalPractice: patientResponse?.patients?.clinicals?.referringProviderDoctor,
          txtsaleOrderId: patientResponse?.patients?.defaultPatientId,
          txtAccountNumber: patientResponse?.patients?.personals?.accountNumber,
          txtDateOfBirth: patientResponse?.patients?.personals?.dateOfBirth,
          txtBillingEmailAddress: patientResponse?.patients.personals.emailId,
          txtMinPress: patientResponse?.deliveryTicket?.minimumPressure,
          txtEstAllowable: patientResponse?.deliveryTicket?.estAllowables,
          txtTotalAccesories: patientResponse?.deliveryTicket?.totalAccessories,
          txtShippingFee: patientResponse?.deliveryTicket?.shippingFee,
          txtAmountPadi: patientResponse?.deliveryTicket?.amountPaid,
          txtBalance: patientResponse?.deliveryTicket?.balance,
          // txtTechnician: patientResponse?.deliveryTicket?.technician,
          // txtPhysician: patientResponse?.deliveryTicket?.physician,
          // txtReferalPractice: patientResponse?.deliveryTicket?.referralPractice,
          txtSubject: patientResponse?.deliveryTicket?.commentsToPatient,
          txtRate: patientResponse?.deliveryTicket?.rate,
          txtSetVI: patientResponse?.deliveryTicket?.setVi,
          txtIPAPMax: patientResponse?.deliveryTicket?.ipapMax,
          txtIPAPMin: patientResponse?.deliveryTicket?.ipapMin,
          txtRRBPM: patientResponse?.deliveryTicket?.rrbpm,
          txtCFICX: patientResponse?.deliveryTicket?.cficx,
          txtSetPress: patientResponse?.deliveryTicket?.setPressure,
          txtBIFlex: patientResponse?.deliveryTicket?.biflex,
          txtRampTime: patientResponse?.deliveryTicket?.rampTime,
          txtRampPress: patientResponse?.deliveryTicket?.rampPressure,
          txtEPAP: patientResponse?.deliveryTicket?.epap,
          txtIPAP: patientResponse?.deliveryTicket?.ipap,
          txtSetting: patientResponse?.deliveryTicket?.setting,
          txtMaxPress: patientResponse?.deliveryTicket?.maximumPressure,
          txtSunrise: patientResponse?.deliveryTicket?.sunriseBalance,
          txtDue: patientResponse?.deliveryTicket?.estAmountDue,
          txtService: patientResponse?.deliveryTicket?.customerServiceNumber,
          txtTollFreeNum: patientResponse?.deliveryTicket?.tollFreeNumber,
          txtSignature: patientResponse?.deliveryTicket?.patientSignature,
          txtDate: patientResponse?.deliveryTicket?.date,
          txtComments: patientResponse?.deliveryTicket?.commentsToPatient
        })
        this.itemTable = [];
        patientResponse?.items?.forEach(value => {
          this.itemTable.push({

            productName: value?.name,
            brandName: value?.brandName,
            categoryName: value?.categoryName,
            qty: value?.qty,
            price: value?.charge,
            lotId: value?.id,
            orderId: orderResponse?.id,
            productLotDetails: value?.productLotDetails,
            productId: value.productId,
            itemId: value?.itemId
            // lotNo: value?.l

          })
        })
      }


    }, err => {
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    }, () => { });
    this.subscription$.push(ordetGet);
  }

  /// back to stock view view page
  backToPack() {
    this.router.navigate(['orders/shippingTicketView/' + this.orderId.toString()])
  }
  tenantLogo: any;
  fileAs64Value: string = defaultUrl;
  //// logo get from api
  getLogo() {
    const getTenantLogo = this.orderService.getTenantLogo().subscribe(response => {

      // this.tenantLogo = response
      if (response) {
        this.fileAs64Value = String("data:image/jpeg;base64," + response);
      } else {
        this.fileAs64Value = defaultUrl;
      }
    })
    this.subscription$.push(getTenantLogo);
  }

  /// ticket submit(save)
  submit() {
    this.store.dispatch(new getOrder(this.orderId)).subscribe(orderResponse => {
      let patientResponse = orderResponse?.orderState?.selectedOrder;
      let CreateUpdateShipping: CreateUpdateDeliveryTicketDTO = {
        defaultOrderId: patientResponse?.shippingCheckList?.defaultOrderId,
        defaultTicketId: patientResponse?.shippingCheckList?.defaultTicketId,
        defaultPatientId: patientResponse?.shippingCheckList?.defaultPatientId,
        patientFullName: patientResponse?.shippingCheckList?.patientName,
        patientDob: this.shippingTicketForm.value.txtDateOfBirth,
        patientAddress: "",
        homePhoneNumber: "",
        workPhoneNumber: "",
        mobileNumber: "",
        emailId: this.shippingTicketForm.value.txtBillingEmailAddress.toLowerCase(),
        accountNumber: this.shippingTicketForm.value.txtAccountNumber,
        primaryInsuranceName: patientResponse?.shippingCheckList.patientName,
        minimumPressure: this.shippingTicketForm.value.txtMinPress,
        maximumPressure: this.shippingTicketForm.value.txtMaxPress,
        setting: this.shippingTicketForm.value.txtSetting,
        ipap: this.shippingTicketForm.value.txtIPAP,
        epap: this.shippingTicketForm.value.txtEPAP,
        rampPressure: this.shippingTicketForm.value.txtRampPress,
        rampTime: this.shippingTicketForm.value.txtRampTime,
        biflex: this.shippingTicketForm.value.txtBIFlex,
        setPressure: this.shippingTicketForm.value.txtSetPress,
        cficx: this.shippingTicketForm.value.txtCFICX,
        rrbpm: this.shippingTicketForm.value.txtRRBPM,
        ipapMin: this.shippingTicketForm.value.txtIPAPMin,
        ipapMax: this.shippingTicketForm.value.txtIPAPMax,
        setVi: this.shippingTicketForm.value.txtSetVI,
        rate: this.shippingTicketForm.value.txtRate,
        technician: this.shippingTicketForm.value.txtTechnician,
        physician: this.shippingTicketForm.value.txtPhysician,
        referralPractice: this.shippingTicketForm.value.txtReferalPractice,
        estAllowables: this.shippingTicketForm.value.txtEstAllowable,
        totalAccessories: this.shippingTicketForm.value.txtTotalAccesories,
        shippingFee: this.shippingTicketForm.value.txtShippingFee,
        sunriseBalance: this.shippingTicketForm.value.txtSunrise,
        estAmountDue: this.shippingTicketForm.value.txtDue,
        amountPaid: this.shippingTicketForm.value.txtAmountPadi,
        balance: this.shippingTicketForm.value.txtBalance,
        commentsToPatient: this.shippingTicketForm.value.txtComments,

        customerServiceNumber: this.shippingTicketForm.value.txtService,
        tollFreeNumber: this.shippingTicketForm.value.txtTollFreeNum,
        patientSignature: this.shippingTicketForm.value.txtSignature,
        date: this.shippingTicketForm.value.txtDate,
      }
      this.orderService.updateDeliveryTicketByOrderIdAndInput(this.orderId, CreateUpdateShipping).subscribe(() => {

        // Swal.fire({ title: 'Success', html: 'Saved Successfully', icon: 'success', timer: 3000, timerProgressBar: true });
        this.toastr.success('Saved Successfully','Success')
        // this.router.navigate(['orders/shippingTicketList']);
      }, err => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      });
    })
  }

}
