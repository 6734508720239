<div class="fluid-container">
  <div class="row">
    <div class="col-12">
      <div class="d-flex align-items-center justify-content-between m-0">
        <h4 class="font-size-18 m-3"><b>Inventory / Product List</b></h4>
        <div class="page-title-box page-title-right pt-2 pb-0">
          <!-- <ol class="breadcrumbs m-0">
            <li class="ml-2 mt-2 breadcrumb-item">Inventory</li>
            <li class="mt-2 breadcrumb-item muted">Product</li>
          </ol> -->
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-xl-12 pr-4 pl-5">
      <ul
        ngbNav
        #nav="ngbNav"
        justify="justified"
        class="alert alert-success nav nav-pills nav-fill"
      >
        <li ngbNavItem="MainProduct" class="nav-item">
          <a ngbNavLink>Main Product</a>
          <ng-template ngbNavContent>
            <!-- <app-product-tab> </app-product-tab> -->
          </ng-template>
        </li>
        <li ngbNavItem="ResupplyProduct" class="nav-item">
          <a ngbNavLink>Resupply Product</a>
          <ng-template ngbNavContent>
            <app-resupply-product-tab> </app-resupply-product-tab>
          </ng-template>
        </li>

      </ul>
      <div [ngbNavOutlet]="nav"></div>
    </div>
  </div>
</div>
