import type { CreateUpdateDaySheetDTO, DaySheetDTO } from './dto/models';
import { RestService } from '@abp/ng.core';
import type { PagedAndSortedResultRequestDto, PagedResultDto } from '@abp/ng.core';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class DaySheetService {
  apiName = 'billingManagement';

  create = (input: CreateUpdateDaySheetDTO) =>
    this.restService.request<any, DaySheetDTO>({
      method: 'POST',
      url: '/api/billingApp/day-sheet',
      body: input,
    },
     { apiName: this.apiName, skipHandleError: true });

  delete = (id: string) =>
    this.restService.request<any, void>({
      method: 'DELETE',
      url: `/api/billingApp/day-sheet/${id}`,
    },
     { apiName: this.apiName, skipHandleError: true });

  get = (id: string) =>
    this.restService.request<any, DaySheetDTO>({
      method: 'GET',
      url: `/api/billingApp/day-sheet/${id}`,
    },
     { apiName: this.apiName, skipHandleError: true });

  getList = (input: PagedAndSortedResultRequestDto) =>
    this.restService.request<any, PagedResultDto<DaySheetDTO>>({
      method: 'GET',
      url: '/api/billingApp/day-sheet',
      params: { skipCount: input.skipCount, maxResultCount: input.maxResultCount, sorting: input.sorting },
    },
     { apiName: this.apiName, skipHandleError: true });

  update = (id: string, input: CreateUpdateDaySheetDTO) =>
    this.restService.request<any, DaySheetDTO>({
      method: 'PUT',
      url: `/api/billingApp/day-sheet/${id}`,
      body: input,
    },
     { apiName: this.apiName, skipHandleError: true });

  searchDaySheetByDtDaySheetDateAndSDescription = (dtDaySheetDate: string, sDescription: string) =>
    this.restService.request<any, DaySheetDTO[]>({
      method: 'POST',
      url: '/api/billingApp/day-sheet/search-day-sheet',
      params: { dtDaySheetDate, sDescription },
    },
     { apiName: this.apiName, skipHandleError: true });

  constructor(private restService: RestService) {}
}
