<div class="container-fluid">
  <!-- Headers -->
  <div class="row">
    <div class="col-12 px-0">
      <div class="d-flex justify-content-between">
        <h1 class="header-text mb-0 d-flex align-items-center">
          Return Reason
        </h1>
        <button mat-icon-button mat-dialog-close class="close-button">
          <mat-icon>close</mat-icon>
        </button>
      </div>
    </div>
  </div>

  <!-- Content -->
  <div class="row">
    <div class="col-12 px-0">
      <div class="card card-body p-0">
        <form [formGroup]="returnForm">
          <!-- Return Reason -->
          <mat-form-field class="col-4">
            <mat-label>Return Reason<span class="asterisk">*</span></mat-label>
            <mat-select formControlName="drpReturnReason">
              <mat-option
                [value]="data.id"
                *ngFor="let data of ltReturnReasons"
                >{{ data.description }}</mat-option
              >
            </mat-select>
          </mat-form-field>
          <!-- Description-->
          <mat-form-field class="col-12">
            <mat-label>Description<span class="asterisk">*</span></mat-label>
            <textarea
              matInput
              [(ngModel)]="descriptionNotes"
              (input)="updateCharacterCount(5000)"
              maxlength="5000"
              formControlName="txtDescription"
              rows="4"
            >
            </textarea>
            <div class="char-count">{{ descriptionNotes?.length }} / 5000</div>
            <mat-error
              *ngIf="
                returnForm?.get('txtDescription')?.touched &&
                returnForm?.get('txtDescription')?.errors
              "
            >
              Description is required
            </mat-error>
          </mat-form-field>
        </form>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-12 d-flex justify-content-center mt-2">
      <button
        type="button"
        (click)="saveReturnOrder()"
        mat-raised-button
        [disabled]="!(!returnForm?.invalid && returnForm?.dirty)"
        class="buttonColor mr-2"
      >
        Save
      </button>
      <button
        mat-raised-button
        (click)="resetForm()"
        type="button"
        class="resetclr"
      >
        Reset
      </button>
    </div>
  </div>
</div>
