<div class="card card-body">
  <!-- <div class="list-box"> -->

  <div class="row">
    <div class="col-lg-12">
      <!-- <div class="row">
      <div class="col-md-6"> -->

      <!-- </div>
    </div> -->
      <form [formGroup]="NpiDoctorForm">
        <mat-card-content>
          <!-- <br /> -->
          <div class="row">
            <div class="col-6 text-left">
              <h2 class="header"><b>NPI Search</b></h2>
            </div>

            <div class="col-6">
              <div class="ml-auto">
                <div class="d-flex justify-content-end align-items-center">
                  <span class="font-size-10 text-right mr-2 mb-4"><b>Medicare</b></span>
                  <app-toggle-switch [onText]="'Yes'" [offText]="'No'" class="mgnr" (changed)="change($event)">
                  </app-toggle-switch>
                </div>
              </div>
            </div>
            <!-- <div class="col-2" style="text-align: right;">
              <h4 style="padding-top: 4%;">Medicare</h4>
            </div>
            <div class="col-3" id="toggle">
              <app-toggle-switch [onText]="'Yes'" [offText]="'No'" class="mgnr" (changed)="change($event)">
              </app-toggle-switch>
            </div> -->
          </div>
          <!-- &nbsp; &nbsp; -->
          <div class="row">
            <mat-form-field class="col-3">
              <mat-label>NPI Number</mat-label>
              <input autocomplete="new-txtNpiNumber" minlength="10" maxlength="10"  
                formControlName="txtNpiNumber" matInput type="text" />
                <mat-error *ngIf="
            NpiDoctorForm?.get('txtNpiNumber')?.touched &&
            NpiDoctorForm?.get('txtNpiNumber')?.errors?.minlength">
            NPI must be 10 digits!
              </mat-error>
              <!-- <mat-error *ngIf="
                NpiDoctorForm?.get('txtNpiNumber')?.touched &&
                NpiDoctorForm?.get('txtNpiNumber')?.errors?.number
              ">
                Enter only Numbers!
              </mat-error> -->
            </mat-form-field>
            <mat-form-field class="col-3">
              <mat-label>First Name</mat-label>
              <input autocomplete="new-txtFirstName" matInput type="text" minlength="2" maxlength="40"
                [(ngModel)]="txtFirstName" formControlName="txtFirstName" />
              <mat-error *ngIf="
                NpiDoctorForm?.get('txtFirstName')?.touched &&
                NpiDoctorForm?.get('txtFirstName')?.errors?.sAlphabets
              ">
                Enter only Alphabets!
              </mat-error>
              <mat-error *ngIf="
            NpiDoctorForm?.get('txtFirstName')?.touched &&
            NpiDoctorForm?.get('txtFirstName')?.errors?.minlength">
                Enter Minimum 2 Letters!
              </mat-error>
            </mat-form-field>
            <!-- <mat-form-field class="col-3">
            <mat-label>First Name Alias</mat-label>
            <input matInput type="text" maxlength="40"
            formControlName="txtUserFirstNameAlias" />
            <mat-error
            *ngIf="
              NpiDoctorForm?.get('txtUserFirstNameAlias')?.touched &&
              NpiDoctorForm?.get('txtUserFirstNameAlias')?.errors?.sAlphabets
            ">
            Enter only Alphabets!
          </mat-error>
          </mat-form-field> -->
            <mat-form-field class="col-3">
              <mat-label>Last Name</mat-label>
              <input autocomplete="new-txtLastName" matInput type="text" maxlength="40" [(ngModel)]="txtLastName"
                formControlName="txtLastName" />
              <mat-error *ngIf="
                NpiDoctorForm?.get('txtLastName')?.touched &&
                NpiDoctorForm?.get('txtLastName')?.errors?.sAlphabets
              ">
                Enter only Alphabets!
              </mat-error>
            </mat-form-field>
            <mat-form-field class="col-3">
              <mat-label>City</mat-label>
              <input autocomplete="new-txtCity" matInput type="text" maxlength="40" [(ngModel)]="txtCity"
                formControlName="txtCity" />
              <mat-error *ngIf="
                NpiDoctorForm?.get('txtCity')?.touched &&
                NpiDoctorForm?.get('txtCity')?.errors?.sAlphabets
              ">
                Enter only Alphabets!
              </mat-error>
            </mat-form-field>
            <mat-form-field class="col-3">
              <mat-label>Postal Code</mat-label>
              <input autocomplete="new-txtPosatlCode" matInput maxlength="10"
                            formControlName="txtPosatlCode" pattern="[0-9-]*" type="text" class="zip-code-input" />
              <!-- <input autocomplete="new-txtPosatlCode" matInput type="text" maxlength="15" class="zip-code-input"
                 pattern="[0-9-]*"  formControlName="txtPosatlCode" /> -->
              <!-- <mat-error *ngIf="
                  NpiDoctorForm?.get('txtPosatlCode')?.touched &&
                  NpiDoctorForm?.get('txtPosatlCode')?.errors?.number
          ">
                Enter only Numbers!
              </mat-error> -->
              <mat-error
                            *ngIf=" NpiDoctorForm?.get('txtPosatlCode')?.touched && NpiDoctorForm?.get('txtPosatlCode')?.errors?.pattern">
                            Enter only Numbers!
                          </mat-error>
                          <mat-error *ngIf="NpiDoctorForm?.get('txtPosatlCode')?.touched &&
                          NpiDoctorForm?.get('txtPosatlCode')?.errors?.required ">
                            <!-- ZipCode is a required field! -->
                          </mat-error>
            </mat-form-field>
            <div class="col-3">
              <br />

              <button mat-button (click)="SearchNpiDoctorsAbp()" [disabled]="

                (!(!NpiDoctorForm.invalid && (NpiDoctorForm.dirty ))) ||
                (!txtFirstName &&
                  !txtLastName &&
                  !(txtNpiNumber?.length===0 ||txtNpiNumber?.length===10 )  &&
                  !txtPosatlCode &&
                  !txtState &&
                  !txtCity)
                " class="buttonColor mb-2 mr-2">
                Search
              </button>
              <button (click)="ResetNpiDoctors()" mat-button class="buttonResetColor mb-2 mr-2">
                Reset
              </button>
            </div>
          </div>
        </mat-card-content>
      </form>
    </div>
  </div>
  <mat-divider></mat-divider>
  <div *ngIf='isShown' class="row  mt-3">
    <div class="col-lg-12">
      <!-- <div [hidden]="!isLoading">
        <mat-spinner [diameter]="220"></mat-spinner>
      </div> -->
      <!-- Loader Section -->
      <!-- <div class="col-12" *ngIf="isLoading">
        <mat-spinner [diameter]="220"></mat-spinner>
      </div> -->
      <!-- Loader Section -->
      <div class="row" *ngIf="isLoading">
        <div class="col-12">
          <div class="d-flex justify-content-center" *ngIf="isLoading">
            <img class="img-responsive pb-2 mr-1" src="assets/gifs/Infinity-loader.gif" />
          </div>
        </div>
      </div>
      <div class="col-12" *ngIf="!isLoading">
        <div class=" table table-responsive">
          <!-- <div *ngIf="!isLoading">
          <mat-spinner [diameter]="220"></mat-spinner>
        </div>
        <div *ngIf="isLoading" class=" table table-responsive"> -->
          <!-- <app-table [strPageType]="strPageType" (viewButton)="AddNpiDoctor($event)"></app-table> -->
          <!-- <table mat-table [dataSource]="dataSource" class="mat-elevation-z8"> -->
          <!-- <ng-container matColumnDef="S.NO">
              <th mat-header-cell *matHeaderCellDef>S.NO</th>
              <td class="text-left" mat-cell *matCellDef="let element; let i = index">{{ i + 1 }}</td>
            </ng-container> -->
          <!-- <ng-container matColumnDef="NPI Number">
            <th mat-header-cell *matHeaderCellDef>NPI </th>
            <td class="text-left" mat-cell *matCellDef="let element"> -->

          <!-- <a
              style="cursor: pointer"

              matTooltip="Click To View NPI Details"
             (click)="viewNpidetails()"
            >
              {{ element.NpiNumber }}</a> -->
          <!-- {{ element.NpiNumber }} -->
          <!-- {{ element.NpiNumber }} -->
          <!-- </td>
          </ng-container>
          <ng-container matColumnDef="Doctor Name">
            <th mat-header-cell *matHeaderCellDef>Doctor </th>
            <td class="text-left" mat-cell *matCellDef="let element">{{ element.FullName }}</td>
          </ng-container>
          <ng-container matColumnDef="Address">
            <th mat-header-cell *matHeaderCellDef>Address</th>
            <td class="text-left" mat-cell *matCellDef="let element">{{ element.Address1 }}</td>
          </ng-container>
          <ng-container matColumnDef="Fax No">
            <th mat-header-cell *matHeaderCellDef>Fax </th>
            <td class="text-left" mat-cell *matCellDef="let element">{{element.faxNo ? element.faxNo : '-'}}</td>
          </ng-container>
          <ng-container matColumnDef="Country">
            <th mat-header-cell *matHeaderCellDef>Country</th>
            <td class="text-left" mat-cell *matCellDef="let element">{{ element.Country }}</td>
          </ng-container>
          <ng-container matColumnDef="State">
            <th mat-header-cell *matHeaderCellDef>State</th>
            <td class="text-left" mat-cell *matCellDef="let element">{{ element.State }}</td>
          </ng-container>
          <ng-container matColumnDef="City">
            <th mat-header-cell *matHeaderCellDef>City</th>
            <td class="text-left" mat-cell *matCellDef="let element">{{ element.City }}</td>
          </ng-container>
          <ng-container matColumnDef="Postal Code">
            <th mat-header-cell *matHeaderCellDef>Postal Code</th>
            <td class="text-left" mat-cell *matCellDef="let element">{{ element.PostalCode }}</td>
          </ng-container>
          <ng-container matColumnDef="Action">
            <th mat-header-cell *matHeaderCellDef>Action</th>
            <td mat-cell *matCellDef="let element">
              <div *ngIf="element.isExists; then content; else other_content">here is ignored</div>
              <ng-template #content>Already Exists</ng-template>
              <ng-template #other_content><a target="_blank" (click)="AddNpiDoctor(element)">
                  <i class="fa fa-plus pointer mr-5"></i>
                </a>
              </ng-template>
            </td>
          </ng-container>
          <ng-container matColumnDef="noRecords">
            <td class="text-align-center" mat-footer-cell *matFooterCellDef colspan="9">
              No Records Found
            </td>
          </ng-container>
          <ng-container matColumnDef="paginator">
            <td mat-footer-cell *matFooterCellDef colspan="9">
              <mat-paginator class="table table-responsive" #MatPaginator [length]="dataSource?.filteredData?.length"
                [pageSize]="10" [pageIndex]="0" showFirstLastButtons [pageSizeOptions]="[5, 10, 25, 100]">
              </mat-paginator>
            </td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
          <tr mat-footer-row *matFooterRowDef="['noRecords']"
            [ngClass]="{ active: dataSource && dataSource.filteredData && dataSource.filteredData.length !== 0 }">
          </tr>
          <tr mat-footer-row *matFooterRowDef="['paginator']"></tr>
        </table> -->
          <app-patient-npi-doctor-table [tableData]="doctorTableData" [npiPracticeID]="npiPracticeID" [isMedicare]="isMedicare"></app-patient-npi-doctor-table>
          <!-- 
        <table id="tbl" datatable [dtOptions]="dtOptions" style="width: 100%" class="hover nowrap_th_td">
          <thead>
              <tr>

                  <th class="text-center">NPI</th>
                  <th class="text-center">Physicians</th>
                  <th class="text-center">FAX</th>
                  <th class="text-center">Address1</th>
                  <th class="text-center">City</th>
                  <th class="text-center">State</th>
                  <th class="text-center">Country</th>
                  <th class="text-center">Postal Code</th>
                  <th class="text-center">Action</th>
              </tr>
          </thead>
          <tbody>
              <tr *ngFor="let data of doctorTableData">
                  <td class="text-center">{{ data.NpiNumber || '-' }}</td>
                  <td class="text-center">{{ data.FullName || '-' }}</td>
                  <td class="text-center">{{ data.faxNo || '-' }}</td>
                  <td class="text-center">{{ data.Address || '-' }}</td>
                  <td class="text-center">{{ data.City || '-' }}</td>
                  <td class="text-center">{{ data.State || '-' }}</td>
                  <td class="text-center">{{ data.Country || '-' }}</td>
                  <td class="text-center">{{ data.PostalCode || '-' }}</td>
                  <td mat-cell *matCellDef="let element">
                    <div *ngIf="data.isExists; then content; else other_content">here is ignored</div>
                    <ng-template #content>Already Exists</ng-template>
                    <ng-template #other_content><a target="_blank" (click)="AddNpiDoctor(element)">
                        <i class="fa fa-plus pointer mr-5"></i>
                      </a>
                    </ng-template>
                  </td>

              </tr>
          </tbody>
      </table> -->

          <!--
      <table
        id="basic-datatable"
        class="table table-bordered dt-responsive nowrap dataTable no-footer dtr-inline"
      >

        <thead>
          <tr>
            <th scope="col">S.NO</th>
            <th scope="col">NPI Number</th>
            <th scope="col">Doctor Name</th>
            <th scope="col">Address</th>
            <th scope="col">City</th>
            <th scope="col">State</th>
            <th scope="col">Country</th>
            <th scope="col">Postal Code</th>
            <th scope="col">Action</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let data of npiTableData; let i = index">
            <td>
              <p class="text-truncate font-size-14">
                {{ i + 1 }}
              </p>
            </td>
            <td>
              <h5 class="text-truncate font-size-14">
                <a href="javascript: void(0);" class="text-dark">
                  {{ data.NpiNumber }}
                </a>
              </h5>
            </td>

            <td>
              <h5 class="text-truncate font-size-14">
                <a href="javascript: void(0);" class="text-dark">
                  {{ data.FullName }}
                </a>
              </h5>
            </td>
            <td>
              <h5 class="text-truncate font-size-14">
                <a href="javascript: void(0);" class="text-dark">
                  {{ data.Address1 }}
                </a>
              </h5>
            </td>
            <td>
              <h5 class="text-truncate font-size-14"></h5>
            </td>

            <td>
              <h5 class="text-truncate font-size-14">
                {{ data.State }}
              </h5>
            </td>
            <td>
              <h5 class="text-truncate font-size-14">
                {{ data.Country }}
              </h5>
            </td>
            <td>
              <h5 class="text-truncate font-size-14">
                {{ data.PostalCode }}
              </h5>
            </td>
            <td>
              <div *ngIf="data.isExists; then content; else other_content">here is ignored</div>
              <ng-template #content>Already Exists</ng-template>
              <ng-template #other_content
                ><a class="eyeCursorclass" (click)="AddNpiDoctor(data)">
                  <i class="mdi mdi-account-plus mdi-18px"></i>
                </a>
              </ng-template>
            </td>
          </tr>
        </tbody>
      </table> -->
        </div>
      </div>
    </div>
  </div>
</div>
<!-- </div> -->