<table id="tblcalenderList" datatable [dtOptions]="dtCalenderTableOptions"
    class="tbl hover row-border hover w-100 display">
    <thead>
        <tr>
            <th><mat-icon> wrap_text</mat-icon></th>
            <th [matTooltip]="'Options'"><mat-icon>apps</mat-icon></th>
            <th [matTooltip]="'Chart No'">Chart No</th>
            <th [matTooltip]="'Patient Name'">Patient Name</th>
            <th [matTooltip]="'Title'">Title</th>
            <th [matTooltip]="'Technician Name'">Technician Name</th>
            <th [matTooltip]="'Mode of Communication'" class="customWidth">Mode of Communication</th>
            <th [matTooltip]="'Scheduled Date'" class="text-center">Scheduled Date</th>
            <th [matTooltip]="'Scheduled Time'">Scheduled Time</th>
            <th [matTooltip]="'Location'">Location</th>
            <th [matTooltip]="'Contact Number'">Contact Number</th>
            <th [matTooltip]="'	Status'">Status</th>
            <th [matTooltip]="'	Type'">Type</th>
            <th [matTooltip]="'Created By'">Created By</th>
            <th class="text-right" [matTooltip]="'Created Date'">Created Date</th>
            <th [matTooltip]="'Modify By'">Modify By</th>
            <th class="text-right" [matTooltip]="'Modified Date'">Modified Date</th>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let data of calendarDataList">
        <td [matTooltip]="'Expand to View Description'" class="dt-control"
            (click)="handleTableCellClick($event, data?.description)">
        </td>
            <td>
                <a class="ml-2" (click)="openHistory(data?.scheduleId)" [matTooltip]="'Click to View'">
                    <img class="img-responsive pr-1 mb-1 cursor pointer" src="assets/Claim Page Icons/status.png" alt=""
                        height="17" />
                </a>
            </td>

            <td (dblclick)="onRowDoubleClickV1(data?.chartNo || '-')">
                <a [class.disabled]="isEmpty(data?.chartNo) || isEmpty(data?.patientId)"
                    class="preview-link" [routerLink]="'/patientCreateEditTab/' + data?.patientId"
                    target="_blank" [matTooltip]="data?.chartNo || '-'">{{
                    data?.chartNo || '-'}}</a>
            </td>

            <td [matTooltip]="data?.patientFullName || '-'"
                (dblclick)="onRowDoubleClickV1(data?.patientFullName || '-')">
                {{ (data?.patientFullName !== null && data?.patientFullName !== '') ?
                data?.patientFullName: '-'
                }}

            </td>

            <td [matTooltip]="data?.title || '-'"
                (dblclick)="onRowDoubleClickV1(data?.title || '-')">
                {{ (data?.title !== null && data?.title !== '') ?
                data?.title: '-'
                }}
            </td>

            <td [matTooltip]="data?.assignedToName|| '-'" (dblclick)="onRowDoubleClickV1(data?.assignedToName || '-')">
                {{ (data?.assignedToName !== null && data?.assignedToName !== '') ? data?.assignedToName: '-'
                }}
            </td>
            <td class="customWidth">

                <ng-container *ngFor="let dataV1 of data?.modeCode">
                    <span *ngIf="dataV1 === 1">
                        <i class="fa fa-check-circle checkicon" *ngIf="data?.isMessageSent === 1"
                            [matTooltip]="'SMS Sent Successfully'"></i>

                        <i class="fa fa-times cansicon" *ngIf="data?.isMessageSent === 0"
                            [matTooltip]="'SMS Sent UnSuccessfull'"></i>
                        SMS
                    </span>

                    <span *ngIf="dataV1 === 2">
                        <i class="fa fa-check-circle checkicon" *ngIf="data?.isMailSent === 1"
                            [matTooltip]="'Mail Sent Successfully'"></i>

                        <i class="fa fa-times cansicon" *ngIf="data?.isMailSent === 0"
                            [matTooltip]="'Mail Sent UnSuccessfull'"></i>

                        Mail
                    </span>

                    <span *ngIf="dataV1 === 3">
                        <i class="fa fa-check-circle checkicon" *ngIf="data?.isWhatsappSent === 1"
                            [matTooltip]="'Sent Successfully'"></i>

                        <i class="fa fa-times cansicon" *ngIf="data?.isWhatsappSent === 0 || data?.isWhatsappSent === null"
                            [matTooltip]="'Sent UnSuccessfull'"></i>

                        WhatsApp
                    </span>

                    <span *ngIf="dataV1 === 4">
                        <i class="fa fa-bell-slash donotDitrub"></i>

                        Do Not Disturb
                    </span>
                </ng-container>
            </td>

            <!-- <td [matTooltip]="getFormattedDateZone(data?.fromTime)"
                (dblclick)="onRowDoubleClickV1(getFormattedDateZone(data?.fromTime))"
                class="text-center">
                {{ getFormattedDateZone(data?.fromTime) }}
            </td> -->

            <td [matTooltip]="data?.fromDate" (dblclick)="onRowDoubleClickV1(data?.fromDate)" class="text-center">
                {{ data?.fromDate }}
            </td>

            <!-- <td [matTooltip]="getFormattedTimeZone(data?.fromTime)"
                (dblclick)="onRowDoubleClickV1(getFormattedTimeZone(data?.fromTime))">
                {{ getFormattedTimeZone(data?.fromTime) }}
            </td> -->

            <td [matTooltip]="data?.fromTime" (dblclick)="onRowDoubleClickV1(data?.fromTime)">
                {{ data?.fromTime }}
            </td>

            <td [matTooltip]="data?.locationName || '-'"
                (dblclick)="onRowDoubleClickV1(data?.locationName || '-')">
                {{ (data?.locationName!== null && data?.locationName!== '') ?
                data?.locationName : '-' }}
            </td>
            <td [matTooltip]="data?.cellNo || '-'"
                (dblclick)="onRowDoubleClickV1(data?.cellNo || '-')"
                [style.color]="getStatusColor(data?.cellNo)">
                {{ (data?.cellNo !== null && data?.cellNo !== '') ?
                data?.cellNo : '-' }}
            </td>
            <!-- <td [matTooltip]="data?.calendarDetails[0]?.status || '-'"
                (dblclick)="onRowDoubleClickV1(data?.calendarDetails[0]?.status || '-')"
                [style.color]="getStatusColor(data?.calendarDetails[0]?.status)">
                {{ (data?.calendarDetails[0]?.status !== null && data?.calendarDetails[0]?.status !== '') ?
                data?.calendarDetails[0]?.status : '-' }}
            </td> -->

            <!-- <td [matTooltip]="data?.statusName || '-'"
            (dblclick)="onRowDoubleClickV1(data?.statusName || '-')">
            <div class="status-tag critical-low" [style.background]="getStatusBackgroundColor(data?.statusName)">
                <p class="status-tag__txt bac-l-stack-xs" [style.color]="getStatusColor(data?.statusName)">{{ (data?.statusName !== null && data?.statusName !== '') ?
            data?.statusName : '-' }}</p>
            </div>
            </td> -->
            <!-- [ngStyle]="{'background-color': type?.colorCode}" [style.color]="updateTextColor(type?.colorCode)" -->

            <td class="text-center">
                <div class="d-flex align-items-center justify-content-between">
                    <span
                        *ngIf="data?.statusName && data?.statusName !== '-'"
                        [matTooltip]="data?.statusName"
                        [style.color]="updateTextColor(data?.colorCode)"
                        [ngStyle]="{'background-color': data?.colorCode}"
                        class="rounded-border ml-1 p-1 claim-status-label">
                        {{ data?.statusName }}
                    </span>
                    <span *ngIf="!data?.statusName || data?.statusName === '-'">-</span>
                </div>
            </td>

            <td [matTooltip]="data?.type || '-'" (dblclick)="onRowDoubleClickV1(data?.type || '-')">
                    {{ (data?.type !== null && data?.type !== '') ?data?.type : '-' }}
            </td>

            <td [matTooltip]="data?.assignedByName || '-'" (dblclick)="onRowDoubleClickV1(data?.assignedByName || '-')">{{
                data?.assignedByName || '-' }}</td>

            <!-- <td class="text-right" [matTooltip]="getFormattedDateTimeZone(data?.creationTime)"
                (dblclick)="onRowDoubleClickV1(getFormattedDateTimeZone(data?.creationTime))">
                {{ getFormattedDateTimeZone(data?.creationTime) }}
            </td> -->

            <td class="text-right" [matTooltip]="data?.creationTime"
            (dblclick)="onRowDoubleClickV1(data?.creationTime)">
                {{ data?.creationTime }}
            </td>

            <td [matTooltip]="data?.modifiedByName|| '-'" (dblclick)="onRowDoubleClickV1(data?.modifiedByName || '-')">{{
                data?.modifiedByName || '-' }}</td>

            <!-- <td class="text-right" [matTooltip]="getFormattedDateTimeZone(data?.lastModifiedTime)"
                (dblclick)="onRowDoubleClickV1(getFormattedDateTimeZone(data?.lastModifiedTime))">
                {{ getFormattedDateTimeZone(data?.lastModifiedTime) }}
            </td> -->
            <td class="text-right" [matTooltip]="data?.lastModifiedTime" (dblclick)="onRowDoubleClickV1(data?.lastModifiedTime)">
                {{ data?.lastModifiedTime }}
            </td>
        </tr>
    </tbody>
    <tfoot>
        <tr>
            <th><mat-icon> wrap_text</mat-icon></th>
            <th><mat-icon>apps</mat-icon></th>
            <th>
                <input matInput class="textbox-border-class" type="text" placeholder="Chart No" name="search-ChartNo" />
            </th>
            <th>
                <input matInput type="text" class="textbox-border-class" placeholder="Patient Name"
                    name="search-PatientName" />
            </th>
            <th>
                <input matInput class="textbox-border-class" type="text" placeholder="Title" name="search-Title" />
            </th>
            <th>
                <input matInput class="textbox-border-class" type="text" placeholder="Technician Name"
                    name="search-TechnicianName" />
            </th>
            <th class="customWidth">
                <input matInput class="textbox-border-class" type="text" placeholder="Mode of Communication"
                    name="search-ModeofCommunication" />
            </th>
            <th>
                <input matInput type="text" class="textbox-border-class text-center" placeholder="Scheduled Date"
                    name="search-Scheduled Date" />
            </th>
            <th>
                <input matInput type="text" class="textbox-border-class" placeholder="Scheduled Time"
                    name="search-Scheduled Time" />
            </th>
            <th>
                <input matInput type="text" class="textbox-border-class" placeholder="Location"
                    name="search-Location" />
            </th>
            <th>
                <input matInput type="text" class="textbox-border-class" placeholder="Contact Number"
                    name="search-ContactNumber" />
            </th>
            <th>
                <input matInput type="text" class="textbox-border-class" placeholder="Status" name="search-Status" />
            </th>
            <th>
                <input matInput type="text" class="textbox-border-class" placeholder="Type" name="search-Type" />
            </th>
            <th>
                <input matInput class="textbox-border-class" type="text" placeholder="Created By"
                    name="search-Created By" />
            </th>
            <th class="text-right">
                <input matInput class="textbox-border-class " type="text" placeholder="Created Date"
                    name="search-Created Date" />
            </th>
            <th>
                <input matInput class="textbox-border-class" type="text" placeholder="Modify By"
                    name="search-Modify By" />
            </th>
            <th class="text-right">
                <input matInput class="textbox-border-class " type="text" placeholder="Modified Date"
                    name="search-Modified Date" />
            </th>
        </tr>
    </tfoot>
</table>

<p class="statusCls" *ngIf="statuslabel"><i class="fa fa-info-circle mr-2"></i>Showing All Schedules Except Checked Out Status</p>