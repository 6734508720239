<div class="container-fluid">
    <h2 class="font-weight-bolder mt-3">
        <b class="header-class">Email/Print Letter</b>
    </h2>
    <div class="card">
        <div class="card-body custom-editor">
            <form [formGroup]="EmailForm">
              <mat-form-field class="col-4">
                <mat-label>Template Search<span class="asterisk">*</span></mat-label>
                <mat-select
                (selectionChange)="loadTemplateInDefault()"
                  formControlName="drpTemplate"
                  #singleSelect
                >
                  <mat-option>
                    <ngx-mat-select-search
                      placeholderLabel="Search"
                      noEntriesFoundLabel="No Matches found"
                      formControlName="txtTemplate"
                    ></ngx-mat-select-search>
                  </mat-option>

                  <mat-option
                    *ngFor="let template of filteredTemplate | async"
                    [value]="(template.templateName).toLowerCase()"
                  >
                    {{ template.templateName }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
                <mat-form-field class="col-3">
                  <!-- pattern="[a-z0-9._!#$%&'()*+,-./:;<=>?@[\]^_`{|}~]+@[a-z0-9.-]+\.[a-z]{2,3}$" -->
                  <mat-label> To Email Address<span class="asterisk">*</span></mat-label>
                  <input autocomplete="new-txtEmail" matInput maxlength="40" formControlName="txtEmail" type="text"
                  pattern="[a-zA-Z0-9._!#$%&'()*+,-./:;<=>?@[\]^_`{|}~]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$" [textMask]="{ mask: emailMask }" />

                  <mat-error *ngIf="EmailForm?.get('txtEmail')?.touched && EmailForm?.get('txtEmail')?.errors?.required ">
                    Email Address is a required field!
                  </mat-error>
                  <mat-error *ngIf="EmailForm?.get('txtEmail')?.touched && EmailForm?.get('txtEmail')?.errors?.pattern">
                    Please Enter a Valid Email Address
                  </mat-error>
                </mat-form-field>

                <mat-form-field class="col-4">
                  <mat-label>Subject <span class="asterisk">*</span></mat-label>
                  <input autocomplete="new-txtSubject" matInput maxlength="100" formControlName="txtSubject" type="text">
                </mat-form-field>
                <!-- [disabled]="!(EmailForm.get('txtEmail').valid && EmailForm.get('editorContent').valid) || saveButtonHide" -->
                <button mat-button class="buttonColor mb-2 mr-2" (click)="fnSendEmail()"
                  [disabled]="EmailForm.invalid  || saveButtonHide">Send</button>

                <angular-editor formControlName="editorContent" [config]="config"></angular-editor>

              </form>
        </div>
    </div>
</div>
