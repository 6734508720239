import { mapEnumToOptions } from '@abp/ng.core';

export enum assignedTeamType {
  intakeTeam = 0,
  verificationTeam = 1,
  authorizationTeam = 2,
  orderTeam = 3,
  shippingTeam = 4,
  warehouseTeam = 5,
  billingTeam = 6,
  claimTeam = 7,
}

export const assignedTeamTypeOptions = mapEnumToOptions(assignedTeamType);
