import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PatientTemplateService } from 'projects/admin/src/app/admin-proxy/platform-app-management/rcm/platform-management/patient-template';
import { Subscription } from 'rxjs';
import Swal from 'sweetalert2';
import { PDFDocument } from 'pdf-lib';
@Component({
  selector: 'app-view-template',
  templateUrl: './view-template.component.html',
  styleUrls: ['./view-template.component.scss']
})
export class ViewTemplateComponent implements OnInit {

  blobName: string;
  isPdfLoad: boolean = false;
  viewTemplate: Subscription
  strPdfString: any = null;
  pdfheight: string = '100%';
  constructor(
    private patientTemplateServices: PatientTemplateService,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      blobName: string;
    } = {
        blobName: '',
      },) { }

  ngOnInit(): void {
    this.blobName = this.data?.blobName
    this.getTemplateView();
  }

  //View Template In Modal
  getTemplateView() {
    this.isPdfLoad = true;
    if (this.viewTemplate) {
      this.viewTemplate.unsubscribe();
    }
    const getViewTemplateData = this.patientTemplateServices
      .getMailTemplateByBlobIDByBlobName(this.blobName)
      .subscribe(
        (response) => {
          this.strPdfString = response;
        },
        (err) => {
          this.isPdfLoad = false;
          const data: HttpErrorResponse = err;
          Swal.fire({
            icon: 'info',
            text: data?.error?.error?.message,
          });
        }
      );
    this.viewTemplate = getViewTemplateData;
  }

}
