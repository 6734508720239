import type { CreateUpdatePickupWorkingHoursDTO, GetOfficesDTO, PickupWorkingHoursDTO, SearchPickupDTO } from './dto/models';
import { RestService } from '@abp/ng.core';
import type { PagedAndSortedResultRequestDto, PagedResultDto } from '@abp/ng.core';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class PickupWorkingHoursService {
  apiName = 'OnTimeManagement';

  create = (input: CreateUpdatePickupWorkingHoursDTO) =>
    this.restService.request<any, PickupWorkingHoursDTO>({
      method: 'POST',
      url: '/api/OnTimeApp/pickup-working-hours',
      body: input,
    },
     { apiName: this.apiName, skipHandleError: true });

  delete = (id: string) =>
    this.restService.request<any, void>({
      method: 'DELETE',
      url: `/api/OnTimeApp/pickup-working-hours/${id}`,
    },
     { apiName: this.apiName, skipHandleError: true });

  get = (id: string) =>
    this.restService.request<any, PickupWorkingHoursDTO>({
      method: 'GET',
      url: `/api/OnTimeApp/pickup-working-hours/${id}`,
    },
     { apiName: this.apiName, skipHandleError: true });

  getList = (input: PagedAndSortedResultRequestDto) =>
    this.restService.request<any, PagedResultDto<PickupWorkingHoursDTO>>({
      method: 'GET',
      url: '/api/OnTimeApp/pickup-working-hours',
      params: { skipCount: input.skipCount, maxResultCount: input.maxResultCount, sorting: input.sorting },
    },
     { apiName: this.apiName, skipHandleError: true });

  update = (id: string, input: CreateUpdatePickupWorkingHoursDTO) =>
    this.restService.request<any, PickupWorkingHoursDTO>({
      method: 'PUT',
      url: `/api/OnTimeApp/pickup-working-hours/${id}`,
      body: input,
    },
     { apiName: this.apiName, skipHandleError: true });

  getWorkingOfficeBasedOnTimingsByInput = (input: GetOfficesDTO) =>
    this.restService.request<any, GetOfficesDTO>({
      method: 'GET',
      url: '/api/OnTimeApp/pickup-working-hours/get-working-office-based-on-timings',
      params: { weekDays: input.weekDays, startsAt: input.startsAt, endsAt: input.endsAt, officeDetails: input.officeDetails },
    },
     { apiName: this.apiName, skipHandleError: true });

  searchPickupByInput = (input: SearchPickupDTO) =>
    this.restService.request<any, PagedResultDto<PickupWorkingHoursDTO>>({
      method: 'GET',
      url: '/api/OnTimeApp/pickup-working-hours/search-pickup',
      params: { respiratoryTherapistName: input.respiratoryTherapistName, pickupDetails: input.pickupDetails, createdDate: input.createdDate, status: input.status },
    },
     { apiName: this.apiName, skipHandleError: true });

  constructor(private restService: RestService) {}
}
