import { Component, Inject, OnInit } from '@angular/core';
import { PagedAndSortedResultRequestDto } from '@abp/ng.core';
import { FormBuilder, FormControl, FormGroup, MaxLengthValidator, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ProductBrandDetailsService, ProductCategoryDetailsService, ProductDetailsService, ProductStockDetailsService, VendorDetailsService } from '../item-proxy/item-management/optimization';
import { map, startWith } from 'rxjs/operators';
import { DatePipe } from '@angular/common';
import { Subscription } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import Swal from 'sweetalert2';
import { HttpErrorResponse } from '@angular/common/http';
import { CreateUpdateProductStockDetailsDTO } from '../item-proxy/item-management/optimization/dto';
import { ProductType } from '../item-proxy/item-management/items';
import { NumberValidators } from 'projects/shared/src/app/validations/number-validator';
import { Router } from '@angular/router';

@Component({
  selector: 'app-add-stock',
  templateUrl: './add-stock.component.html',
  styleUrls: ['./add-stock.component.scss'],
  providers: [DatePipe],
})
export class AddStockComponent implements OnInit {
  stockForm: FormGroup;
  filterMainProduct: any;
  drpProductName: any[] = [];
  drpProductBrand: any[] = [];
  drpVendorName: any[] = [];
  drpCategoryName: any[] = [];
  filterCategoryName: any;
  filterVendorName: any;
  filterProductBrand: any;
  stockId: string = "";
  price: number;
  subscription$: Subscription[] = [];
  constructor( private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<AddStockComponent>,
              private productDetailsService: ProductDetailsService,
              public datepipe: DatePipe,
              private productDetailsBrand: ProductBrandDetailsService,
              private vendordetails: VendorDetailsService,
              private categoryDetails: ProductCategoryDetailsService,
              private stockDetails: ProductStockDetailsService,
              private toastr: ToastrService,
              private numberValidators: NumberValidators,
              public router: Router,


     @Inject(MAT_DIALOG_DATA) public data: { productId: string, supplyId: string }) { }

  ngOnInit(): void {
    this.stockId = this.data?.productId
    this.stockForm = this.formBuilder.group({
      soldQuantity : new FormControl(""),
      txtMainProduct: new FormControl("", [Validators.required]),
      txtMainProductFilter: new FormControl(""),
      // txtBrandName: new FormControl("",[Validators.required]),
      txtbrandFilter: new FormControl(""),
      txtDescription: new FormControl("",),
      txtVendorName:  new FormControl("", [Validators.required]),
      txtCategoryName:  new FormControl("", [Validators.required]),
      txtCategoryFilter: new FormControl(""),
      txtVendorFilter: new FormControl(""),
      txtProductCode: new FormControl(""),
      txtLotNo: new FormControl("",
        ),
      txtQty: new FormControl("",[
        Validators.pattern('^0*[1-9]+[0-9]*$'),
        this.numberValidators.isNumberCheck,
        Validators.min(1),
      ]),
      txtPrice: new FormControl("",Validators.required),
      txtPurchasedate: new FormControl(""),
      txtProductType:new FormControl("")

    });
    this.getDropDown()
    this.getStockListDetails()

  }

    /// save and update stock
  saveStock() {
    if (this.stockId !== "" && this.stockId !== null && this.stockId !== undefined) {

      let stockDetails: CreateUpdateProductStockDetailsDTO = {
        productId: this.stockForm.value.txtMainProduct,
        // productType: ProductType.All,
        // brandId: this.stockForm.value.txtBrandName,
        categoryId: this.stockForm.value.txtCategoryName,
        vendorId: this.stockForm.value.txtVendorName,
        lotNo: this.stockForm.value.txtLotNo,
        pricePerUnit: this.stockForm.value.txtPrice,
        quantity: this.stockForm.value.txtQty,
        // soldQuantity: this.stockForm.value.soldQuantity == '' ? 0 : this.stockForm.value.soldQuantity ?? 0,
        soldQuantity: 0,
        productSerialNumberDetails: []
      }
      this.price = this.stockForm.value.txtPrice
      this.stockDetails.update(this.stockId, stockDetails).subscribe(response => {
        // Swal.fire({ title: 'Success', html: 'Updated Successfully', icon: 'success', timer: 3000, timerProgressBar: true });
        this.toastr.success('Updated Successfully','Success')
        this.dialogRef.close()
        this.getDropDown();
        // this.getTableData()
        // this.reset();
      }, err => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        }).then((result)=>{
           if(data?.error?.error?.message == 'The minmum quantity is reached.'){
          this.dialogRef.close()
        }
        })
      });

    }
    else {
      let stockDetail: CreateUpdateProductStockDetailsDTO = {
        productId: this.stockForm.value.txtMainProduct,
        // productType: ProductType.All,
        // brandId: this.stockForm.value.txtBrandName,
        categoryId: this.stockForm.value.txtCategoryName,
        vendorId: this.stockForm.value.txtVendorName,
        lotNo: this.stockForm.value.txtLotNo,
        pricePerUnit: this.stockForm.value.txtPrice,
        quantity: this.stockForm.value.txtQty,
        // soldQuantity: this.stockForm.value.soldQuantity == '' ? 0 : this.stockForm.value.soldQuantity ?? 0,
        soldQuantity: 0,
        productSerialNumberDetails: []
      }
      this.stockDetails.create(stockDetail).subscribe(response => {
        // Swal.fire({ title: 'Success', html: 'Saved Successfully', icon: 'success', timer: 3000, timerProgressBar: true });
        this.toastr.success('Saved Successfully','Success')

        this.getDropDown();
        this.dialogRef.close()
        // this.getTableData()
        // this.reset();
      }, err => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        }).then((result)=>{
           if(data?.error?.error?.message == 'The minmum quantity is reached.'){
          this.dialogRef.close()
        }
        })
      });
    }
  }
  updateSerialNo(){
    this.router.navigate(['inventory/stock-view/' + [this.data.productId.toString()] ])
    this.dialogRef.close()

  }
  getStockListDetails(){
    const getStock = this.stockDetails.get(this.data.productId).subscribe(res =>{
      this.stockForm.patchValue({
        soldQuantity:res?.soldQuantity,
        txtMainProduct: res?.productId,
        // txtBrandName: res?.brandId,
        txtVendorName: res?.vendorId,
        txtCategoryName: res?.categoryId,
        txtQty: res?.quantity,
        txtLotNo: res?.lotNo,
        txtPrice: res?.pricePerUnit,
        txtProductType: res?.ProductTypes == 'MainProduct' ? 1 : 2
      })
    })
  }

  getDropDown(){

      this.stockForm.value.txtProductType = this.stockForm.value.txtProductType ? this.stockForm.value.txtProductType : 1
    if(this.stockForm.value.txtProductType == 1){
      const productName =  this.productDetailsService.getProductName().subscribe( res => {
        // this.drpProductName = res.map((x)=>{
        //   x.ProductTypes == 'MainProduct' ? x : []
        // })
        this.filterMainProduct = this.stockForm?.get("txtMainProductFilter").valueChanges
          .pipe(
            startWith(''),
            map(value => this.drpProductName.filter(option => option?.mainProductName?.toLowerCase()?.includes(value?.toLowerCase() ?? "")))
          );

      })
      this.subscription$.push(productName);
    } else if(this.stockForm.value.txtProductType == 2){
      const productName =  this.productDetailsService.getProductName().subscribe( res => {
        this.drpProductName = res.map((x)=>{
          //x.ProductTypes == 'ResupplyProduct ' ? x : []
        })
        this.filterMainProduct = this.stockForm?.get("txtMainProductFilter").valueChanges
          .pipe(
            startWith(''),
            map(value => this.drpProductName.filter(option => option?.mainProductName?.toLowerCase()?.includes(value?.toLowerCase() ?? "")))
          );

      })
      this.subscription$.push(productName);
    }

    const brandName = this.productDetailsBrand.getList(new PagedAndSortedResultRequestDto).subscribe(res =>{

      this.drpProductBrand = res.items

      this.filterProductBrand = this.stockForm?.get("txtbrandFilter").valueChanges
      .pipe(
        startWith(''),
        map(value => this.drpProductBrand.filter(option => option?.status == 'Active' ))
      );

    })
    this.subscription$.push(brandName);

    const vendorName = this.vendordetails.getList(new PagedAndSortedResultRequestDto).subscribe(res =>{

      this.drpVendorName = res.items

      this.filterVendorName = this.stockForm?.get("txtVendorFilter").valueChanges
      .pipe(
        startWith(''),
        map(value => this.drpVendorName.filter(option => option?.status == 'Active' ))
      );

    })
    this.subscription$.push(vendorName);

    const categoryName = this.categoryDetails.getList(new PagedAndSortedResultRequestDto).subscribe(res =>{

      this.drpCategoryName = res.items

      this.filterCategoryName = this.stockForm?.get("txtCategoryFilter").valueChanges
      .pipe(
        startWith(''),
        map(value => this.drpCategoryName.filter(option => option?.status == 'Active' ))
      );

    })
    this.subscription$.push(categoryName);

  }
  changeProduct(e){
    this.stockForm.value.txtProductType = this.stockForm.value.txtProductType ? this.stockForm.value.txtProductType : e
   this.getDropDown()
  }
    /// based on product qty view
    qtyNeedToAddInStock;
    onChanges(event) {
      if (event?.value?.qtyNeedToAddInStock > 0) {
        this.qtyNeedToAddInStock = 'Only' + " " + event?.value?.qtyNeedToAddInStock + " " + 'and less quantities allowed for this' + " " + event?.value?.productName
      } else {
        this.qtyNeedToAddInStock = 'Please raise purchase order or check product received status for this' + " " + event?.value?.productName
      }

    }

    brand: string = '';
    // clearbrand() {
    //   this.brand = '';
    //   this.stockForm.patchValue({ txtBrandName: "" });
    // }

    Status: string = '';
    clearStatus() {
      this.Status = '';
      // this.vSearchForm.patchValue({ drpStatus: "" });
    }


    Sbrand: string = '';
    clearSbrand() {
      this.Sbrand = '';
      this.stockForm.patchValue({ drpBrand: "" });
    }


    Product: string = '';
    clearProduct() {
      this.Product = '';
      // this.vSearchForm.patchValue({ drpProduct: "" });
    }
    status: string = '';
    clearstatus() {
      this.status = '';
      this.stockForm.patchValue({ txtStatus: "" });
    }
    category: string = '';
    clearCategory() {
      this.category = '';
      this.stockForm.patchValue({ txtCategoryName: "" });
    }
    MainProduct: string = '';
    clearMainProduct() {
      this.MainProduct = '';
      this.qtyNeedToAddInStock = '';
      this.stockForm.patchValue({ txtMainProduct: "" });
    }
    provider: string = '';
    clearsupplyVendor() {
      this.provider = '';
      this.stockForm.patchValue({ txtVendorName: "" });
    }


}
