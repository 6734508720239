import { PagedResultDto, RestService } from '@abp/ng.core';
import { Injectable } from '@angular/core';
import type {
  ClaimItemHcpcCode,
  ClaimListDTO,
  ClaimNoDTO,
  ClaimOrderPatientDTO,
  InputWaystartStatusListDto,
  PatientClaimInfoDTO,
  PatientTabClaimListDTO,
  ProviderFileCountDTO,
  WaystartStatusListDto,
} from '../dto/models';
import { InsuranceType } from 'projects/order/src/app/order-proxy/patient/enumeration-data/insurance-type.enum';

@Injectable({
  providedIn: 'root',
})
export class ClaimProcessingService {
  apiName = 'billingManagement';

  getClaimPendingStatusByPatientIDAndOrderID = (
    patientID: string,
    OrderID: string
  ) =>
    this.restService.request<any, string>(
      {
        method: 'GET',
        responseType: 'text',
        url: '/api/billingApp/claim-processing/claim-pending-status',
        params: { patientID, orderID: OrderID },
      },
      { apiName: this.apiName }
    );

  getClaimProcessingListByDtFromDateAndDtToDateAndSProviderAndPlanType = (
    dtFromDate: string,
    dtToDate: string,
    sProvider: string,
    planType: InsuranceType
  ) =>
    this.restService.request<any, ClaimListDTO[]>(
      {
        method: 'GET',
        url: '/api/billingApp/claim-processing/claim-processing-list',
        params: { dtFromDate, dtToDate, sProvider, planType },
      },
      { apiName: this.apiName }
    );

  getClaimProcessingListByDtFromDateAndDtToDateAndSProviderAndSRank = (
    dtFromDate: string,
    dtToDate: string,
    sProvider: string,
    sRank: string
  ) =>
    this.restService.request<any, any>(
      {
        method: 'GET',
        url: '/api/billingApp/claim-processing/claim-processing-list',
        params: { dtFromDate, dtToDate, sProvider, sRank },
      },
      { apiName: this.apiName }
    );

  getPatientDetailsforClaimByOrderId = (OrderId: string) =>
    this.restService.request<any, PatientClaimInfoDTO>(
      {
        method: 'GET',
        url: `/api/billingApp/claim-processing/get-patient-detailsfor-claim/${OrderId}`,
      },
      { apiName: this.apiName }
    );
  getPatientDetailsforClaimByOrderIdDetails = (
    OrderId: string,
    gClaimId: string
  ) =>
    this.restService.request<any, PatientClaimInfoDTO>(
      {
        method: 'GET',
        url: `/api/billingApp/claim-processing/get-patient-detailsfor-claim`,
        params: { OrderId, gClaimId },
      },
      { apiName: this.apiName }
    );

  getMasterProviderWithFileCount = () =>
    this.restService.request<any, ProviderFileCountDTO[]>(
      {
        method: 'GET',
        url: '/api/billingApp/claim-processing/master-provider-with-file-count',
      },
      { apiName: this.apiName }
    );

  getClaimIdList = (sParams: string) =>
    this.restService.request<any, ClaimNoDTO[]>(
      {
        method: 'GET',
        url: '/api/billingApp/claim-processing/claim-id-list',
        params: { sParams },
      },
      { apiName: this.apiName }
    );

  loadPatientOrderDetailsByGClaimId = (gClaimId: string) =>
    this.restService.request<any, ClaimOrderPatientDTO>(
      {
        method: 'POST',
        url: `/api/billingApp/claim-processing/load-patient-order-details/${gClaimId}`,
      },
      { apiName: this.apiName }
    );

    getClaimItemHCPCListByGClaimId = (gClaimId: string) =>
      this.restService.request<any, ClaimItemHcpcCode[]>({
        method: 'GET',
        url: `/api/billingApp/claim-processing/claim-item-hCPCList/${gClaimId}`,
      },
      { apiName: this.apiName });
      loadPatientClaimDetailsByPatientId = (gPatientId: string) =>
        this.restService.request<any, any>(
          {
            method: 'POST',
            url: `/api/billingApp/claim-processing/load-claim-list-details/${gPatientId}`,
          },
          { apiName: this.apiName }
        );


        loadClaimListDetailsByGPatientIdAndIsPaid = (gPatientId: string, isPaid: boolean) =>
          this.restService.request<any, PatientTabClaimListDTO[]>({
            method: 'POST',
            url: `/api/billingApp/claim-processing/load-claim-list-details/${gPatientId}`,
            params: { isPaid },
          },
          { apiName: this.apiName });

          getWayStarStatusForMyQueueByInput = (input: InputWaystartStatusListDto) =>
            this.restService.request<any, WaystartStatusListDto[]>({
              method: 'POST',
              url: '/api/billingApp/claim-processing/get-way-star-status-for-my-queue',
              body: input,
            },
            { apiName: this.apiName });

  constructor(private restService: RestService) {}
}
