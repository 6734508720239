import type { CalendarDTO, CreateUpdateCalendarDTO, CreateUpdateCalendarDetailsDTO, ScheduleDTO, SearchCalendarDTO } from './dto/models';
import { RestService } from '@abp/ng.core';
import type { PagedAndSortedResultRequestDto, PagedResultDto } from '@abp/ng.core';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class CalendarService {
  apiName = 'OnTimeManagement';

  create = (input: CreateUpdateCalendarDTO) =>
  this.restService.request<any, CalendarDTO>({
    method: 'POST',
    url: '/api/OnTimeApp/calendar',
    body: input,
  },
  { apiName: this.apiName });

  delete = (id: string) =>
    this.restService.request<any, void>({
      method: 'DELETE',
      url: `/api/OnTimeApp/calendar/${id}`,
    },
     { apiName: this.apiName, skipHandleError: true });

  get = (id: string) =>
    this.restService.request<any, CalendarDTO>({
      method: 'GET',
      url: `/api/OnTimeApp/calendar/${id}`,
    },
     { apiName: this.apiName, skipHandleError: true });

  getList = (input: PagedAndSortedResultRequestDto) =>
    this.restService.request<any, PagedResultDto<CalendarDTO>>({
      method: 'GET',
      url: '/api/OnTimeApp/calendar',
      params: { skipCount: input.skipCount, maxResultCount: input.maxResultCount, sorting: input.sorting },
    },
     { apiName: this.apiName, skipHandleError: true });

  update = (id: string, input: CreateUpdateCalendarDTO) =>
    this.restService.request<any, CalendarDTO>({
      method: 'PUT',
      url: `/api/OnTimeApp/calendar/${id}`,
      body: input,
    },
     { apiName: this.apiName, skipHandleError: true });

  deleteCalendarByIdAndCalendarDetailsId = (id: string, calendarDetailsId: string) =>
    this.restService.request<any, CalendarDTO>({
      method: 'DELETE',
      url: `/api/OnTimeApp/calendar/${id}/delete-calendar/${calendarDetailsId}`,
    },
     { apiName: this.apiName, skipHandleError: true });

     getCalendarByUserId = (userId: string) =>
     this.restService.request<any, PagedResultDto<CalendarDTO>>({
       method: 'GET',
       url: `/api/OnTimeApp/calendar/get-calendar/${userId}`,
     },
     { apiName: this.apiName });

  searchCalendarByInput = (input: SearchCalendarDTO) =>
    this.restService.request<any, PagedResultDto<CalendarDTO>>({
      method: 'GET',
      url: '/api/OnTimeApp/calendar/search-calendar',
      params: { roles: input.roles, users: input.users, fromDate: input.fromDate, toDate: input.toDate },
    },
     { apiName: this.apiName, skipHandleError: true });

  updateRescheduleByIdAndInput = (id: string, input: CreateUpdateCalendarDetailsDTO) =>
    this.restService.request<any, CalendarDTO>({
      method: 'PUT',
      url: `/api/OnTimeApp/calendar/${id}/update-reschedule`,
      body: input,
    },
     { apiName: this.apiName, skipHandleError: true });

     getScheduleDetailsByPatientID = (PatientID: string) =>
     this.restService.request<any, ScheduleDTO>({
       method: 'GET',
       url: '/api/OnTimeApp/calendar/schedule-details',
       params: { patientID: PatientID },
     },
     { apiName: this.apiName });

  constructor(private restService: RestService) {}
}
