import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { OrderCreateEditComponent } from './order-create-edit/order-create-edit.component';
import { OrderComponent } from './order/order.component';

const routes: Routes = [
  {
    path: 'orders/saleorderCreateEdit', component: OrderCreateEditComponent
  },
  {
    path: '',
    redirectTo: 'orders',
    pathMatch: 'full'

  },
  {
    path: 'order',
    children: [
      {
        path: '',
        redirectTo: 'order/:id',
        pathMatch: 'full'
      },
      {
        path: 'order/:id',
        component: OrderComponent
      },
      {
        path: 'order',
        component: OrderComponent
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
