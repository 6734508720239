import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import Swal from 'sweetalert2';
import { PagedAndSortedResultRequestDto, PagedResultRequestDto } from '@abp/ng.core';
import { SmsDetailsDTO } from '../order-proxy/order-management/order/dto/models';
import { NotificationService } from '../order-proxy/order-management/order/notification.service';
import { PatientService } from 'projects/patient/src/app/patient-proxy/patient/patient.service';
import { SmsNotificationService } from '../order-proxy/order-management/order/sms-notification.service';
import { RegionDropdownService } from 'projects/patient/src/app/dropdown-proxy/dropdown-management/dropdowns/region-dropdown-service';
import { ToastrService } from "ngx-toastr";

@Component({
  selector: 'app-message',
  templateUrl: './message.component.html',
  styleUrls: ['./message.component.scss']
})
export class MessageComponent implements OnInit, OnDestroy {
  @Input() patientId: string = '';
  smsConversationList: SmsDetailsDTO[] = [];
  @Input() orderStatus: boolean = true;
  subscription$: Subscription[] = [];
  patientName: string = "";
  patientPhoneNo: string = '';
  messageForm: FormGroup;
  isInSMSPorgress: boolean = false;
  countryCode: string = "";
  constructor(
    private formbuilder: FormBuilder,
    private notificationService: NotificationService,
    private patientServcie: PatientService,
    private dropdown: RegionDropdownService,
    private toastr: ToastrService,
    private smsService: SmsNotificationService
  ) { }
  ngOnInit(): void {
    this.messageForm = this.formbuilder.group({
      txtMessage: new FormControl(""),
    })
    this.patientId && this.patientId !== "" && this.patientId !== null && this.patientId !== undefined && this.getPatientDetails();
    this.getSMSmessage();

  }

  ngOnDestroy(): void {
    this.subscription$?.forEach((sub) => {
      sub && sub?.unsubscribe();
    });
  }

  getPatientDetails() {
    const patientGet = this.patientServcie.get(this.patientId).subscribe(response => {
      this.patientName = ((response?.personals?.firstName ?? "") + " " + (response?.personals?.lastName ?? ""));
      this.patientPhoneNo = response?.addresses[0]?.phone;
      this.getcountryCode(response.addresses[0].country)
    });
    this.subscription$.push(patientGet);
    this.getSMSmessage();
  }
  private getSMSmessage() {
    const getSms = this.smsService.getList(new PagedResultRequestDto).subscribe(response => {
      if (response && response?.items && response?.items[0] && response?.items[0]?.smsDetails) {
        const smsList: SmsDetailsDTO[] = response?.items[0]?.smsDetails ?? [];
        this.smsConversationList = smsList ?? [];
      }
    });
    this.subscription$.push(getSms);
  }

  getcountryCode(value: string): string {
    let strCountryPhoneCode = "";
    if (value && typeof value === "string") {
      const getCountry = this.dropdown.getAllCountriesByInput(new PagedAndSortedResultRequestDto).subscribe(response => {
        if (response && response?.items) {
          strCountryPhoneCode = response?.items?.filter(c => c?.countryShortName === value)[0]?.countryPhoneCode ?? "";
        }
        this.countryCode = strCountryPhoneCode;
      })
      this.subscription$.push(getCountry);
    }
    return strCountryPhoneCode ?? '';
  }
  sendSMSToPatient() {
    this.isInSMSPorgress = true;

    const patientId: string = this.patientId ?? "";
    const sMessage: string = this.messageForm.value.txtMessage ?? "";
    const sendSms = this.notificationService.sendSmsToPatient(patientId, sMessage).subscribe(response => {
      // Swal.fire({ title: 'Success', html: 'Message Sent Successfully', icon: 'success', timer: 3000, timerProgressBar: true });
      this.toastr.success('Message Sent Successfully','Success')
      this.resetForm();
      this.isInSMSPorgress = false;
      this.getSMSmessage();

    }, error => {
      const data: HttpErrorResponse = error;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
      this.isInSMSPorgress = false;
      this.getSMSmessage();

    });
    this.subscription$.push(sendSms);
  }
  resetForm() {
    this.messageForm.patchValue({
      txtMessage: "",
    })

  }
}

