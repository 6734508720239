<table
  id="{{ strPageType }}"
  mat-table
  class="table table-bordered"
  matSort
  [dataSource]="dataSource"
>
  <ng-container [ngSwitch]="strPageType">
    <ng-container *ngSwitchCase="'orders'">
      <ng-container matColumnDef="saleOrderId">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Order ID</th>
        <td mat-cell *matCellDef="let data">
          {{ data?.saleOrderId }}
        </td>
      </ng-container>
      <ng-container matColumnDef="totalPrice">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Total Price</th>
        <td mat-cell *matCellDef="let datas">
          {{ "$ " + datas?.totalCharge }}
        </td>
      </ng-container>
      <ng-container matColumnDef="itemNames">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Item</th>
        <td mat-cell *matCellDef="let data">
          <ng-container>
            {{ data?.items }}
          </ng-container>
        </td>
      </ng-container>
      <ng-container matColumnDef="quantity">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Total Units</th>
        <!-- Total Qty -->
        <td mat-cell *matCellDef="let data">
          {{ data?.totalQuantity }}
        </td>
      </ng-container>
      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Status</th>
        <td mat-cell *matCellDef="let data">
          <ng-container [ngSwitch]="data?.status">
            <ng-container *ngSwitchCase="'completed'"
              >Order Completed</ng-container
            >
            <ng-container *ngSwitchCase="'itemPending'"
              >Item Pending</ng-container
            >
            <ng-container *ngSwitchCase="'orderVoided'"
              >Order Voided</ng-container
            >
            <ng-container *ngSwitchCase="'authorizationPending'"
              >Authorization Pending</ng-container
            >
            <ng-container *ngSwitchCase="'orderPending'"
              >Order Pending</ng-container
            >
            <ng-container *ngSwitchDefault>Ready For Order</ng-container>
          </ng-container>
        </td>
      </ng-container>

      <ng-container matColumnDef="dateOfService">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>DOS</th>
        <td mat-cell *matCellDef="let data; let index = i">
          <ng-container *ngIf="data?.dateOfService != '0001-01-01T00:00:00'">
            {{ data?.dateOfService | date : "MM/dd/yyyy" : "en_US" }}
          </ng-container>
        </td>
      </ng-container>
      <ng-container matColumnDef="createdDate">
        <th mat-header-cell *matHeaderCellDef>Created Date</th>
        <td mat-cell *matCellDef="let data">
          {{ data?.creationTime | date : "MM/dd/yyyy" : "en_US" }}
        </td>
      </ng-container>
      <ng-container matColumnDef="Options">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Options</th>
        <td mat-cell *matCellDef="let data">
          <a class="eyeCursorclass">
            <i
              (click)="viewClick.next(data)"
              target="_blank"
              class="fa fa-pencil mr-5 pencilColor"
            ></i>
          </a>
        </td>
      </ng-container>
      <ng-container matColumnDef="noRecords">
        <td
          class="text-align-center"
          mat-footer-cell
          *matFooterCellDef
          colspan="9"
        >
          <div *ngIf="!isLoading">
            {{ "No Records Found" }}
          </div>
          <div [hidden]="!isLoading">
            <mat-spinner [diameter]="220"></mat-spinner>
          </div>
        </td>
      </ng-container>
      <ng-container matColumnDef="paginator">
        <td mat-footer-cell *matFooterCellDef colspan="9">
          <mat-paginator
            class="table table-responsive mat-table-class"
            #MatPaginator
            [length]="dataSource?.data?.length"
            [pageSize]="10"
            [pageIndex]="0"
            showFirstLastButtons
            [pageSizeOptions]="[5, 10, 25, 100]"
          >
          </mat-paginator>
        </td>
      </ng-container>
    </ng-container>
    <ng-container *ngSwitchCase="'template'">
      <ng-container matColumnDef="templateId">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Order ID</th>
        <td mat-cell *matCellDef="let data">
          {{ data?.saleOrderId }}
        </td>
      </ng-container>
      <ng-container matColumnDef="totalPrice">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Total Price</th>
        <td mat-cell *matCellDef="let datas">
          {{ "$ " + datas?.totalCharge }}
        </td>
      </ng-container>
      <ng-container matColumnDef="itemNames">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Item</th>
        <td mat-cell *matCellDef="let data">
          <ng-container>
            {{ data?.items }}
          </ng-container>
        </td>
      </ng-container>
      <ng-container matColumnDef="quantity">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Total Units</th>
        <td mat-cell *matCellDef="let data">
          {{ data?.totalQuantity }}
        </td>
      </ng-container>
      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Status</th>
        <td mat-cell *matCellDef="let data">
          <ng-container [ngSwitch]="data?.status">
            <ng-container *ngSwitchCase="'completed'"
              >Order Completed</ng-container
            >
            <ng-container *ngSwitchCase="'itemPending'"
              >Item Pending</ng-container
            >
            <ng-container *ngSwitchCase="'orderVoided'"
              >Order Voided</ng-container
            >
            <ng-container *ngSwitchCase="'authorizationPending'"
              >Authorization Pending</ng-container
            >
            <ng-container *ngSwitchCase="'orderPending'"
              >Order Pending</ng-container
            >
            <ng-container *ngSwitchDefault>Ready For Order</ng-container>
          </ng-container>
        </td>
      </ng-container>

      <ng-container matColumnDef="dateOfService">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>DOS</th>
        <td mat-cell *matCellDef="let data; let index = i">
          <ng-container *ngIf="data?.dateOfService != '0001-01-01T00:00:00'">
            {{ data?.dateOfService | date : "MM/dd/yyyy" : "en_US" }}
          </ng-container>
        </td>
      </ng-container>
      <ng-container matColumnDef="createdDate">
        <th mat-header-cell *matHeaderCellDef>Created Date</th>
        <td mat-cell *matCellDef="let data">
          {{ data?.creationTime | date : "MM/dd/yyyy" : "en_US" }}
        </td>
      </ng-container>

      <ng-container matColumnDef="noRecords">
        <td
          class="text-align-center"
          mat-footer-cell
          *matFooterCellDef
          colspan="9"
        >
          <div *ngIf="!isLoading">
            {{ "No Records Found" }}
          </div>
          <div [hidden]="!isLoading">
            <mat-spinner [diameter]="220"></mat-spinner>
          </div>
        </td>
      </ng-container>
      <ng-container matColumnDef="paginator">
        <td mat-footer-cell *matFooterCellDef colspan="9">
          <mat-paginator
            class="table table-responsive mat-table-class"
            #MatPaginator
            [length]="dataSource?.data?.length"
            [pageSize]="10"
            [pageIndex]="0"
            showFirstLastButtons
            [pageSizeOptions]="[5, 10, 25, 100]"
          >
          </mat-paginator>
        </td>
      </ng-container>
    </ng-container>
    <ng-container *ngSwitchCase="'policy'" >
      <ng-container matColumnDef="payorLevel">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Payor Level</th>
        <td mat-cell *matCellDef="let data">
          {{ data?.payorLevel | uppercase }}
        </td>
      </ng-container>
      <ng-container matColumnDef="payorId">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Payor Name</th>
        <td mat-cell *matCellDef="let data" [matTooltip]="getTooltip(data)">
          <div class="ellipsis">
            {{ data?.payorId + " " + "/" + " " + data?.payorName }}
          </div>
        </td>
      </ng-container>
      <ng-container matColumnDef="planType">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Plan Type</th>
        <td mat-cell *matCellDef="let data">
          <ng-container *ngIf="data.typeofPlan">
            {{ data?.typeofPlan }}</ng-container
          >
          <ng-container *ngIf="!data.typeofPlan">-</ng-container>
        </td>
      </ng-container>
      <ng-container matColumnDef="policyNo">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Policy No</th>
        <td mat-cell *matCellDef="let data">
          {{ data?.policyNo }}
        </td>
      </ng-container>
      <ng-container matColumnDef="note">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Note</th>
        <td
          mat-cell
          *matCellDef="let data"
          [matTooltip]="getTooltipNotes(data)"
        >
          <ng-container *ngIf="data?.note; else emptyBlock">
            <div class="ellipsis">{{ data?.note }}</div>
          </ng-container>

          <ng-template #emptyBlock> - </ng-template>
        </td>
      </ng-container>

      <!-- 28SW-3105-0103.0 -->
      <ng-container matColumnDef="eligibilityType">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          Eligibility Type
        </th>
        <td mat-cell *matCellDef="let data">
          <ng-container *ngIf="data?.verificationMethod; else elseBlock">
            {{ data?.verificationType }}
          </ng-container>
          <ng-template #elseBlock> - </ng-template>
        </td>
      </ng-container>
      <ng-container matColumnDef="eligibilityMode">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          Eligibility Mode
        </th>
        <td mat-cell *matCellDef="let data">
          <!-- {{ verify?.policy }} -->
          <ng-container [ngSwitch]="data?.eligibilityMode">
            <ng-container *ngSwitchCase="'1'">pVerify</ng-container>
            <ng-container *ngSwitchCase="'2'">Zermid</ng-container>
            <ng-container *ngSwitchCase="'3'">ChangeHealthCare</ng-container>
            <ng-container *ngSwitchDefault> - </ng-container>
          </ng-container>
        </td>
      </ng-container>
      <ng-container matColumnDef="eligibility">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          Eligibility Status
        </th>
        <td mat-cell *matCellDef="let data">
          <ng-container *ngIf="data?.isEligibilityDone; else elseBlock">
            {{
              data?.isEligibilityDone.charAt(0).toUpperCase() +
                data?.isEligibilityDone.slice(1)
            }}
          </ng-container>
          <ng-template #elseBlock> - </ng-template>
        </td>
      </ng-container>
      <ng-container matColumnDef="precert">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Precert</th>
        <!-- <td mat-cell *matCellDef="let data">
          <ng-container *ngIf="data?.isPrecertRequired === 0 || data?.isPrecertRequired === null; else yesBlock">
            No
          </ng-container>
          <ng-template #yesBlock>
            -
          </ng-template>
        </td> -->
        <td mat-cell *matCellDef="let data">
          <ng-container
            *ngIf="
              data?.isEligibilityDone == 'done' && data?.isPrecertRequired == 0
            "
          >
            No
          </ng-container>
          <ng-container *ngIf="data?.isEligibilityDone == 'pending'">
            -
          </ng-container>
          <ng-container
            *ngIf="
              data?.isEligibilityDone == 'done' && data?.isPrecertRequired == 1
            "
          >
            yes
          </ng-container>
        </td>
      </ng-container>
      <ng-container matColumnDef="allowedPercentage">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Allowed %</th>
        <td mat-cell *matCellDef="let data">
          <ng-container *ngIf="data?.allowedPercentage; else emptyBlock">
            {{ data?.allowedPercentage }}
          </ng-container>
          <ng-template #emptyBlock> - </ng-template>
        </td>
      </ng-container>
      <!-- 28SW-3105-0103.0 -->
      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Status</th>
        <td mat-cell *matCellDef="let data">
          <ng-container>
            <mat-slide-toggle
              *ngIf="data.isActive != false"
              matTooltip="Click to Change Status"
              (change)="statusChange.next(data?.id)"
              [checked]="data?.isActive"
            >
              Active
            </mat-slide-toggle></ng-container
          >
          <ng-container>
            <mat-slide-toggle
              *ngIf="data.isActive == false"
              matTooltip="Click to Change Status"
              (change)="statusChange.next(data?.id)"
              [checked]="data?.isActive"
            >
              InActive
            </mat-slide-toggle></ng-container
          >
        </td>
      </ng-container>
      <ng-container matColumnDef="Options">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Options</th>
        <td mat-cell *matCellDef="let data">
          <a
            matTooltip="Click to View"
            *ngIf="data.isActive != false"
            (click)="viewClick.next(data?.id)"
            class="eyeCursorclass"
            target="_blank"
            ><i class="fa fa-pencil mr-3"></i
          ></a>
          <a
            matTooltip="Click to Delete"
            (click)="deleteClick.next(data?.id)"
            class="deleteBtnColor"
          >
            <i class="fa fa-trash"></i>
          </a>
        </td>
      </ng-container>
      <ng-container matColumnDef="OptionsForInsurance">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Options</th>
        <td mat-cell *matCellDef="let data">
          <!--         // Medicare Task Hold 28SW-1218-0101.0 - By Manikandan
          <a matTooltip="Click to View Medicare" *ngIf="data.isActive != false && data.payorName.toLowerCase().includes('medicare') == true" (click)="documnetViewerSideBySide(data?.id)" class="eyeCursorclass" target="_blank"><i
              class="fa fa-list-alt mr-3"></i></a> -->

          <a
            matTooltip="Click to View"
            *ngIf="data.isActive != false"
            (click)="viewClick.next(data?.id)"
            class="eyeCursorclass"
            target="_blank"
            ><i class="fa fa-pencil mr-3"></i
          ></a>

          <a
            matTooltip="Click to Delete"
            (click)="deleteClick.next(data?.id)"
            class="deleteBtnColor"
          >
            <i class="fa fa-trash"></i>
          </a>
        </td>
      </ng-container>
      <ng-container matColumnDef="noRecords">
        <td
          class="text-align-center"
          mat-footer-cell
          *matFooterCellDef
          colspan="12"
        >
          <div *ngIf="!isLoading">
            {{ "No Records Found" }}
          </div>
          <div [hidden]="!isLoading">
            <mat-spinner [diameter]="220"></mat-spinner>
          </div>
        </td>
      </ng-container>
      <ng-container matColumnDef="paginator">
        <td mat-footer-cell *matFooterCellDef colspan="12">
          <mat-paginator
            class="table table-responsive mat-table-class"
            #MatPaginator
            [length]="dataSource?.data?.length"
            [pageSize]="10"
            [pageIndex]="0"
            showFirstLastButtons
            [pageSizeOptions]="[5, 10, 25, 100]"
          >
          </mat-paginator>
        </td>
      </ng-container>
    </ng-container>
    <ng-container *ngSwitchCase="'patientAuthorization'">
      <ng-container matColumnDef="authId">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Auth Id</th>
        <td mat-cell *matCellDef="let data">
          {{ data?.authId == "" || data?.authId == null ? "-" : data?.authId }}
        </td>
      </ng-container>
      <ng-container matColumnDef="authBeginDate">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          Auth Begin Date
        </th>
        <td mat-cell *matCellDef="let data">
          {{ data?.startDate | date : "MM/dd/yyyy" : "en_US" }}
        </td>
      </ng-container>
      <ng-container matColumnDef="authEndDate">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Auth End Date</th>
        <td mat-cell *matCellDef="let data">
          {{ data?.endDate | date : "MM/dd/yyyy" : "en_US" }}
        </td>
      </ng-container>
      <ng-container matColumnDef="authStatus">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Auth Status</th>
        <td mat-cell *matCellDef="let data">
          {{
            data?.authStatusName == "" || data?.authStatusName == null
              ? "-"
              : data?.authStatusName
          }}
        </td>
      </ng-container>
      <ng-container matColumnDef="isFollowUp">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Followup</th>
        <td mat-cell *matCellDef="let data">
          {{ data?.isFollowUp == 0 ? "No" : "Yes" }}
        </td>
      </ng-container>
      <ng-container matColumnDef="units">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Units</th>
        <td mat-cell *matCellDef="let data">
          {{ data?.units == "" || data?.units == null ? "-" : data?.units }}
        </td>
      </ng-container>
      <ng-container matColumnDef="planName">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Plan Name</th>
        <td mat-cell *matCellDef="let data">
          {{
            data?.planName == "" || data?.planName == null
              ? "-"
              : data?.planName
          }}
        </td>
      </ng-container>
      <ng-container matColumnDef="planType">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Plan Type</th>
        <td mat-cell *matCellDef="let data">
          {{
            data?.planType == "" || data?.planType == null
              ? "-"
              : data?.planType
          }}
        </td>
      </ng-container>
      <ng-container matColumnDef="reference">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Reference</th>
        <td mat-cell *matCellDef="let data">
          {{
            data?.reference == "" || data?.reference == null
              ? "-"
              : data?.reference
          }}
        </td>
      </ng-container>
      <ng-container matColumnDef="options">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Options</th>
        <td mat-cell *matCellDef="let data">
          <a
            (click)="viewClick.next(data?.id)"
            matTooltip="Click To View Auth Details"
          >
            <i class="fa fa-eye pointer editBtnColor pl-3"></i>
          </a>
        </td>
      </ng-container>
      <ng-container matColumnDef="noRecords">
        <td
          class="text-align-center"
          mat-footer-cell
          *matFooterCellDef
          colspan="12"
        >
          <div *ngIf="!isLoading">
            {{ "No Records Found" }}
          </div>
          <div [hidden]="!isLoading">
            <mat-spinner [diameter]="220"></mat-spinner>
          </div>
        </td>
      </ng-container>
      <ng-container matColumnDef="paginator">
        <td mat-footer-cell *matFooterCellDef colspan="12">
          <mat-paginator
            class="table table-responsive mat-table-class"
            #MatPaginator
            [length]="dataSource?.data?.length"
            [pageSize]="10"
            [pageIndex]="0"
            showFirstLastButtons
            [pageSizeOptions]="[5, 10, 25, 100]"
          >
          </mat-paginator>
        </td>
      </ng-container>
    </ng-container>
    <ng-container *ngSwitchCase="'patient-notes'">
      <ng-container matColumnDef="defaultNotesId">
        <th mat-header-cell class="header" *matHeaderCellDef mat-sort-header>
          Note Id
        </th>
        <td mat-cell *matCellDef="let element">
          <span
            matTooltip="{{ element?.defaultNotesId }}"
            matTooltipClass="custom-tooltip-font"
          >
            {{ element?.defaultNotesId }}</span
          >
        </td>
      </ng-container>
      <!-- Created By  Column -->
      <ng-container matColumnDef="createdBy">
        <th mat-header-cell class="header" *matHeaderCellDef mat-sort-header>
          Created By
        </th>
        <td mat-cell *matCellDef="let element">{{ element?.createdBy }}</td>
      </ng-container>
      <!-- Date Created -->
      <ng-container matColumnDef="dateCreated">
        <th mat-header-cell class="header" *matHeaderCellDef mat-sort-header>
          Date Created
        </th>
        <td mat-cell *matCellDef="let element">
          <span class="ellipsis .cut-text">
            {{ element?.dateCreated | date : "MM/dd/yyyy h:mm a" : "en_US" }}
          </span>
        </td>
      </ng-container>
      <!-- Actual Date -->
      <ng-container matColumnDef="subject">
        <th mat-header-cell class="header" *matHeaderCellDef mat-sort-header>
          Actual Date
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element?.subject }}
        </td>
      </ng-container>
      <!-- Note Type -->
      <ng-container matColumnDef="noteType">
        <th mat-header-cell class="header" *matHeaderCellDef mat-sort-header>
          Note Type
        </th>
        <td mat-cell *matCellDef="let element">{{ element?.noteType }}</td>
      </ng-container>
      <!-- Reason -->
      <ng-container matColumnDef="noteReason">
        <th mat-header-cell class="header" *matHeaderCellDef mat-sort-header>
          Reason
        </th>
        <td mat-cell *matCellDef="let element">
          <div class="warppable">
            {{ element?.noteReason }}
          </div>
        </td>
      </ng-container>
      <!--  Date Needed -->
      <ng-container matColumnDef="dateNeeded">
        <th mat-header-cell class="header" *matHeaderCellDef mat-sort-header>
          Date Needed
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element?.dateNeeded | date : "MM/dd/yyyy" : "en_US" }}
        </td>
      </ng-container>
      <!--  Status -->
      <ng-container matColumnDef="status">
        <th mat-header-cell class="header" *matHeaderCellDef mat-sort-header>
          Status
        </th>
        <td mat-cell *matCellDef="let element">{{ element?.status }}</td>
      </ng-container>
      <ng-container matColumnDef="Options">
        <th mat-header-cell class="header" *matHeaderCellDef>Options</th>
        <td mat-cell *matCellDef="let element" class="text-center">
          <a
            (click)="viewClick.next(element?.id)"
            *ngIf="crudPermisson?.update"
            matTooltip="Click To View Notes"
          >
            <i class="fa fa-eye pointer editBtnColor pl-3"></i>
          </a>
          <a
            (click)="deleteClick.next(element?.id)"
            *ngIf="crudPermisson?.delete"
            matTooltip="Click To Delete Notes"
          >
            <i class="fa fa-trash pointer color deleteBtnColor pl-3"></i>
          </a>
        </td>
      </ng-container>
      <ng-container matColumnDef="noRecords">
        <td
          class="text-align-center"
          mat-footer-cell
          *matFooterCellDef
          colspan="9"
        >
          <div *ngIf="!isLoading">
            {{ "No Records Found" }}
          </div>
          <div *ngIf="isLoading">
            <mat-spinner [diameter]="220"></mat-spinner>
          </div>
        </td>
      </ng-container>
      <ng-container matColumnDef="paginator">
        <td mat-footer-cell *matFooterCellDef colspan="9">
          <mat-paginator
            class="table table-responsive mat-table-class"
            #MatPaginator
            [length]="dataSource?.data?.length"
            [pageSize]="10"
            [pageIndex]="0"
            showFirstLastButtons
            [pageSizeOptions]="[5, 10, 25, 100]"
          >
          </mat-paginator>
        </td>
      </ng-container>
    </ng-container>
    <ng-container *ngSwitchCase="'eligiblityHistory'">
      <!-- <ng-container matColumnDef="Sno">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>S.No</th>
        <td mat-cell *matCellDef="let element; let i = index">{{ i + 1 }}</td>
      </ng-container> -->
      <ng-container matColumnDef="plan">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Payor Level</th>
        <td mat-cell mat-cell *matCellDef="let verify">
          <ng-container [ngSwitch]="verify?.plan">
            <ng-container *ngSwitchCase="'primary'">Primary</ng-container>
            <ng-container *ngSwitchCase="'secondary'">Secondary</ng-container>
            <ng-container *ngSwitchCase="'tertiary'">Tertiary</ng-container>
            <ng-container *ngSwitchDefault> {{ verify?.plan }} </ng-container>
          </ng-container>
        </td>
      </ng-container>
      <ng-container matColumnDef="planName">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Payor Name</th>
        <td mat-cell *matCellDef="let verify" [matTooltip]="verify?.planName">
          {{ verify?.planName }}
        </td>
      </ng-container>
      <ng-container matColumnDef="planType">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Plan Type</th>
        <td mat-cell *matCellDef="let verify" [matTooltip]="verify?.planName">
          <ng-container *ngIf="verify.typeOfPlan">
            {{ verify?.typeOfPlan }}</ng-container
          >
          <ng-container *ngIf="!verify.typeOfPlan">-</ng-container>
        </td>
      </ng-container>
      <ng-container matColumnDef="policy">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Policy No</th>
        <td mat-cell *matCellDef="let verify">{{ verify?.policy }}</td>
      </ng-container>
      <ng-container matColumnDef="verificationStatus">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Status</th>
        <td mat-cell *matCellDef="let verify">
          <ng-container [ngSwitch]="verify?.verificationStatus">
            <ng-container *ngSwitchCase="'done'">Done</ng-container>
            <ng-container *ngSwitchCase="'pending'">Pending</ng-container>
            <ng-container *ngSwitchCase="'unabletoverify'"
              >Unable To Verify</ng-container
            >
            <ng-container *ngSwitchDefault>
              {{ verify?.verificationStatus }}
            </ng-container>
          </ng-container>
        </td>
      </ng-container>
      <ng-container matColumnDef="eligibilityMode">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          Eligibility Mode
        </th>
        <td mat-cell *matCellDef="let verify">
          <!-- {{ verify?.policy }} -->
          <ng-container [ngSwitch]="verify?.eligibilityMode">
            <ng-container *ngSwitchCase="'1'">pVerify</ng-container>
            <ng-container *ngSwitchCase="'2'">Zermid</ng-container>
            <ng-container *ngSwitchCase="'3'">ChangeHealthCare</ng-container>
          </ng-container>
        </td>
      </ng-container>

      <ng-container matColumnDef="verifiedBy">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Verified By</th>
        <td mat-cell *matCellDef="let verify">{{ verify?.verifiedBy }}</td>
      </ng-container>
      <ng-container matColumnDef="verifiedByUser">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Verified By</th>
        <td mat-cell *matCellDef="let verify">{{ verify?.verifiedByUser }}</td>
      </ng-container>

      <ng-container matColumnDef="verifiedMethod">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          Verified Method
        </th>
        <td mat-cell *matCellDef="let verify">
          {{ verify.vMethod === 0 ? 'Manual' : 'Electronic' }}
        </td>
      </ng-container>


      <ng-container matColumnDef="dateVerified">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Date Verified</th>
        <td mat-cell *matCellDef="let verify">
          {{ verify?.verifiedOn | date : "MM/dd/yyyy h:mm a" : "en_US" }}
        </td>
      </ng-container>

      <ng-container matColumnDef="Options">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Options</th>
        <td mat-cell *matCellDef="let verify">
          <a
            (click)="viewClick.next(verify)"
            *ngIf="crudPermisson?.update"
            matTooltip="Click To View"
            class="eyeCursorclass"
            target="_blank"
          >
            <i class="fa fa-pencil mr-2"></i>
          </a>
          <a
            (click)="deleteClick.next(verify)"
            *ngIf="crudPermisson?.delete"
            matTooltip="Click To Delete"
          >
            <i class="fa fa-trash pointer color deleteBtnColor pl-3"></i>
          </a>
        </td>
      </ng-container>
      <ng-container matColumnDef="noRecords">
        <td
          class="text-align-center"
          mat-footer-cell
          *matFooterCellDef
          colspan="12"
        >
          <div *ngIf="!isLoading">
            {{ "No Records Found" }}
          </div>
          <div [hidden]="!isLoading">
            <mat-spinner [diameter]="220"></mat-spinner>
          </div>
        </td>
      </ng-container>
      <ng-container matColumnDef="paginator">
        <td mat-footer-cell *matFooterCellDef colspan="12">
          <mat-paginator
            class="table table-responsive mat-table-class"
            #MatPaginator
            [length]="dataSource?.data?.length"
            [pageSize]="10"
            [pageIndex]="0"
            showFirstLastButtons
            [pageSizeOptions]="[5, 10, 25, 100]"
          >
          </mat-paginator>
        </td>
      </ng-container>
    </ng-container>
    <ng-container *ngSwitchCase="'reminder'">
      <!-- <ng-container matColumnDef="S.No">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>S.No</th>
        <td mat-cell *matCellDef="let element; let i = index">{{ i + 1 }}</td>
      </ng-container> -->
      <ng-container matColumnDef="reminderSubject">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Subject</th>
        <td mat-cell mat-cell *matCellDef="let reminder">
          {{ reminder?.subject }}
        </td>
      </ng-container>
      <ng-container matColumnDef="reminderDate">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          Scheduled Date
        </th>
        <td mat-cell *matCellDef="let reminder">
          {{ reminder?.reminderDate | date : "MM/dd/yyyy" : "en_US" }}
        </td>
      </ng-container>
      <ng-container matColumnDef="createdDate">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Created Date</th>
        <td mat-cell *matCellDef="let reminder">
          {{ reminder?.creationTime | date : "MM/dd/yyyy" : "en_US" }}
        </td>
      </ng-container>

      <ng-container matColumnDef="options">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Options</th>
        <td mat-cell *matCellDef="let reminder">
          <a *ngIf="crudPermisson?.update">
            <i
              matTooltip="Click To View"
              class="eyeCursorclass"
              (click)="viewClick.next(reminder)"
              class="fa fa-pencil mr-2"
            ></i>
          </a>
          <a *ngIf="crudPermisson?.delete">
            <i
              (click)="deleteClick.next(reminder)"
              matTooltip="Click To Delete"
              class="fa fa-trash pointer color deleteBtnColor pl-3"
            ></i>
          </a>
        </td>
      </ng-container>
      <ng-container matColumnDef="noRecords">
        <td
          class="text-align-center"
          mat-footer-cell
          *matFooterCellDef
          colspan="6"
        >
          <div *ngIf="!isLoading">
            {{ "No Records Found" }}
          </div>
          <div [hidden]="!isLoading">
            <mat-spinner [diameter]="220"></mat-spinner>
          </div>
        </td>
      </ng-container>
      <ng-container matColumnDef="paginator">
        <td mat-footer-cell *matFooterCellDef colspan="6">
          <mat-paginator
            class="table table-responsive mat-table-class"
            #MatPaginator
            [length]="dataSource?.data?.length"
            [pageSize]="10"
            [pageIndex]="0"
            showFirstLastButtons
            [pageSizeOptions]="[5, 10, 25, 100]"
          >
          </mat-paginator>
        </td>
      </ng-container>
    </ng-container>
    <ng-container *ngSwitchDefault>
      <ng-container matColumnDef="noRecords">
        <td
          class="text-align-center"
          mat-footer-cell
          *matFooterCellDef
          colspan="9"
        >
          <div *ngIf="!isLoading">
            {{ "No Records Found" }}
          </div>
          <div [hidden]="!isLoading">
            <mat-spinner [diameter]="220"></mat-spinner>
          </div>
        </td>
      </ng-container>
      <ng-container matColumnDef="paginator">
        <td mat-footer-cell *matFooterCellDef colspan="5">
          <mat-paginator
            class="table table-responsive mat-table-class"
            #MatPaginator
            [length]="dataSource?.data?.length"
            [pageSize]="10"
            [pageIndex]="0"
            showFirstLastButtons
            [pageSizeOptions]="[5, 10, 25, 100]"
          >
          </mat-paginator>
        </td>
      </ng-container>
    </ng-container>
  </ng-container>

  <tr
    mat-footer-row
    *matFooterRowDef="['noRecords']"
    [ngClass]="{
      active: dataSource && dataSource?.data && dataSource?.data?.length !== 0
    }"
  ></tr>
  <tr mat-header-row *matHeaderRowDef="arrDisplayedColumns" ></tr>
  <tr mat-row *matRowDef="let row; columns: arrDisplayedColumns" [ngClass]="getRowClass(row)"></tr>
  <tr mat-footer-row *matFooterRowDef="['paginator']"></tr>
</table>
