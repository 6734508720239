import type { CreateUpdateMoreInfoDTO, CreateUpdateNoteDTO, CreateUpdatePracticeManagementDTO, Note, NoteDTO, PracticeManagementDTO, SearchPracticeDTO, FaxNumbersDTO, CreateUpdateFaxNumbersDTO, MoreInfoDTO, CreateUpdateRequirementsDTO, GetPracticeDTO } from './dto/models';
import { RestService } from '@abp/ng.core';
import type { PagedAndSortedResultRequestDto, PagedResultDto } from '@abp/ng.core';
import { Injectable } from '@angular/core';
import { ActiveStatus } from 'projects/admin/src/app/admin-proxy/platform-app-management/rcm/platform-management/localization/enumeration/active-status.enum';
import { PatientFaxReportDTO, PhysiciansFaxReportDTO, PracticeManagement, RequirementsDTO } from './models';
import { DoctorDTO } from '../dto';


@Injectable({
  providedIn: 'root',
})
export class PracticeManagementService {
  apiName = 'Default';

  create = (input: CreateUpdatePracticeManagementDTO) =>
    this.restService.request<any, PracticeManagementDTO>({
      method: 'POST',
      url: '/api/app/practice-management',
      body: input,
    },
      { apiName: this.apiName });

  delete = (id: string) =>
    this.restService.request<any, void>({
      method: 'DELETE',
      url: `/api/app/practice-management/${id}`,
    },
      { apiName: this.apiName });

  get = (id: string) =>
    this.restService.request<any, PracticeManagementDTO>({
      method: 'GET',
      url: `/api/app/practice-management/${id}`,
    },
      { apiName: this.apiName });

  getList = (input: PagedAndSortedResultRequestDto) =>
    this.restService.request<any, PagedResultDto<PracticeManagementDTO>>({
      method: 'GET',
      url: '/api/app/practice-management',
      params: { skipCount: input.skipCount, maxResultCount: input.maxResultCount, sorting: input.sorting },
    },
      { apiName: this.apiName });

  update = (id: string, input: CreateUpdatePracticeManagementDTO) =>
    this.restService.request<any, PracticeManagementDTO>({
      method: 'PUT',
      url: `/api/app/practice-management/${id}`,
      body: input,
    },
      { apiName: this.apiName });

  createPhysicianByIdAndSFirstNameAndSLastNameAndSNpi = (id: string, sFirstName: string, sLastName: string, sNpi: string) =>
    this.restService.request<any, PracticeManagementDTO>({
      method: 'POST',
      url: `/api/app/practice-management/${id}/create-physician`,
      params: { sFirstName, sLastName, sNpi },
    },
      { apiName: this.apiName });

  getReferralPracticeReportByIdAndDtStartDateAndDtEndDate = (id: string, dtStartDate: string, dtEndDate: string) =>
    this.restService.request<any, PracticeManagementDTO>({
      method: 'GET',
      url: `/api/app/practice-management/${id}/get-referral-practice-report`,
      params: { dtStartDate, dtEndDate },
    },
      { apiName: this.apiName });

  searchPracticeByInput = (input: SearchPracticeDTO) =>
    this.restService.request<any, PagedResultDto<PracticeManagementDTO>>({
      method: 'GET',
      url: '/api/app/practice-management/search-practice',
      params: { practiceName: input.practiceName, city: input.city, state: input.state, zipCode: input.zipCode, phoneNo: input.phoneNo, faxNo: input.faxNo, manager: input.manager, createdDate: input.createdDate, status: input.status },
    },
      { apiName: this.apiName });

  createNotesByInput = (input: CreateUpdateNoteDTO) =>
    this.restService.request<any, Note>({
      method: 'POST',
      url: '/api/app/practice-management/create-notes',
      body: input,
    },
      { apiName: this.apiName });

  updateNoteByIdAndInput = (id: string, input: CreateUpdateNoteDTO) =>
    this.restService.request<any, NoteDTO>({
      method: 'PUT',
      url: `/api/app/practice-management/${id}/note`,
      body: input,
    },
      { apiName: this.apiName });

  getPracticeNotesByPracticeIdAndFilter = (practiceId: string, filter: ActiveStatus) =>
    this.restService.request<any, NoteDTO[]>({
      method: 'GET',
      url: `/api/app/practice-management/practice-notes/${practiceId}`,
      params: { filter },
    },
      { apiName: this.apiName });

  deleteNoteByNoteId = (noteId: string) =>
    this.restService.request<any, void>({
      method: 'DELETE',
      url: `/api/app/practice-management/note/${noteId}`,
    },
      { apiName: this.apiName });

  updateMoreInfoByPracticeIdAndInput = (practiceId: string, input: CreateUpdateMoreInfoDTO) =>
    this.restService.request<any, PracticeManagement>({
      method: 'PUT',
      url: `/api/app/practice-management/more-info/${practiceId}`,
      body: input,
    },
      { apiName: this.apiName });

  getPracticeFaxNumbersByPracticeIdAndFilter = (practiceId: string, filter: ActiveStatus) =>
    this.restService.request<any, FaxNumbersDTO[]>({
      method: 'GET',
      url: `/api/app/practice-management/practice-fax-numbers/${practiceId}`,
      params: { filter },
    },
      { apiName: this.apiName });

  createFaxNumbersByInput = (input: CreateUpdateFaxNumbersDTO) =>
    this.restService.request<any, FaxNumbersDTO[]>({
      method: 'POST',
      url: '/api/app/practice-management/create-fax-numbers',
      body: input,
    },
      { apiName: this.apiName });

    updateFaxNumbersByIdAndInput = (id: string, input: CreateUpdateFaxNumbersDTO) =>
    this.restService.request<any, FaxNumbersDTO>({
      method: 'PUT',
      url: `/api/app/practice-management/${id}/fax-numbers`,
      body: input,
    },
    { apiName: this.apiName });

    deleteFaxNumbersByFaxid = (faxid: string) =>
    this.restService.request<any, void>({
      method: 'DELETE',
      url: '/api/app/practice-management/fax-numbers',
      params: { faxid },
    },
    { apiName: this.apiName });

    getMoreInfoByPracticeIdByPracticeId = (practiceId: string) =>
    this.restService.request<any, MoreInfoDTO[]>({
      method: 'GET',
      url: `/api/app/practice-management/more-info-by-practice-id/${practiceId}`,
    },
    { apiName: this.apiName });

    practiceDocGetList = (iPracticeId: string) =>
    this.restService.request<any, DoctorDTO[]>({
      method: 'POST',
      url: `/api/app/doctor/practice-doc-get-list/${iPracticeId}`,
    },
    { apiName: this.apiName });

    docGetList = (iDoctorId: string) =>
    this.restService.request<any, DoctorDTO>({
      method: 'POST',
      url: `/api/app/doctor/doc-get-list/${iDoctorId}`,
    },
    { apiName: this.apiName });

    activeInactiveFaxNumbersByFaxid = (faxid: string) =>
    this.restService.request<any, string>({
      method: 'POST',
      responseType: 'text',
      url: '/api/app/practice-management/active-inactive-fax-numbers',
      params: { faxid },
    },
    { apiName: this.apiName });

    getRequirementInfoByPracticeIdByPracticeId = (practiceId: string) =>
    this.restService.request<any, RequirementsDTO[]>({
      method: 'GET',
      url: `/api/app/practice-management/requirement-info-by-practice-id/${practiceId}`,
    },
    { apiName: this.apiName });

    updateRequirementInfoByPracticeIdAndInput = (practiceId: string, input: CreateUpdateRequirementsDTO) =>
    this.restService.request<any, PracticeManagement>({
      method: 'PUT',
      url: `/api/app/practice-management/requirement-info/${practiceId}`,
      body: input,
    },
    { apiName: this.apiName });

    enableDisablePracticeById = (id: string) =>
    this.restService.request<any, string>({
      method: 'POST',
      responseType: 'text',
      url: `/api/app/practice-management/${id}/enable-disable-practice`,
    },
    { apiName: this.apiName });

    physiciansFaxReportByIMonthAndIYear = (iMonth: number, iYear: number) =>
    this.restService.request<any, PhysiciansFaxReportDTO[]>({
      method: 'POST',
      url: '/api/app/practice-management/physicians-fax-report',
      params: { iMonth, iYear },
    },
    { apiName: this.apiName });

    patientFaxReportByIMonthAndIYear = (iMonth: number, iYear: number) =>
    this.restService.request<any, PatientFaxReportDTO[]>({
      method: 'POST',
      url: '/api/app/practice-management/patient-fax-report',
      params: { iMonth, iYear },
    },
    { apiName: this.apiName });

    getDropPracticeByPNameAndSPractice = (pName: string, sPractice: string[]) =>
      this.restService.request<any, GetPracticeDTO[]>({
        method: 'POST',
        url: '/api/app/practice-management/get-drop-practice',
        params: { pName },
        body:  sPractice ,
      },
      { apiName: this.apiName });
  constructor(private restService: RestService) { }
}
