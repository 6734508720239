import { DatePipe } from '@angular/common';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { DataTableDirective } from 'angular-datatables';
import { defaultGuid } from 'projects/shared/src/app/enums/allenums.enum';
import { CommunicationService } from 'projects/shared/src/app/services/communication.service';

@Component({
  selector: 'app-shipping-disapproved-orders-table',
  templateUrl: './shipping-disapproved-orders-table.component.html',
  styleUrls: ['./shipping-disapproved-orders-table.component.scss'],
})
export class ShippingDisapprovedOrdersTableComponent implements OnInit {
  tblShpSelectedRowId: any;
  isCashOrder: boolean = false;
  @Input() tableData: any;
  dtShippingOptions: any = {
    responsive: true,
    paging: false,
    dom: 'Bfrtip',
    scrollCollapse: true,
    scrollY: '300px',
    scrollX: true,
    search: {
      smart: false,
    },
    order: [],
    buttons: [
      {
        extend: 'excel',
        text: 'Export', // Change the text to 'Export'
        filename: 'Shipping Disapproved List',
        exportOptions: {
          // columns: [1, 2, 3, 4, 5, 6, 7, 8],
          columns: ':visible:not(:first-child) ',
        },
      },
      {
        extend: 'colvis',
        columns: ':not(:eq(0), :eq(1), :eq(2),:eq(3), :eq(-1))', // Exclude first three and last columns from the column visibility toggle
      },
    ],
    language: {
      emptyTable: 'No records',
      info: 'Total : _MAX_ records',
      infoEmpty: 'Total : _MAX_ records',
      infoFiltered: '(filtered : _TOTAL_ records)',
      loadingRecords: 'Loading...',
      zeroRecords: 'No matching records',
    },
    columnDefs: [
      { targets: 0, orderable: false }, // Targets the first column (index 0)
      // Add other columnDefs as needed
      { targets: [0, 1, 2, 3, -1], className: 'dt-fixed-column' },
    ],
  };
  selectedShippingData: any;
  @ViewChild(DataTableDirective, { static: false })
  datatableElement: DataTableDirective;
  myTable: DataTables.Api;
  constructor(
    private communicationService: CommunicationService,
    private datePipe: DatePipe
  ) {}

  ngOnInit(): void {
    this.dtShippingOptions.buttons[0].filename =
      'Shipping Disapproved List' +
      '-' +
      this.datePipe.transform(new Date(), 'MM-dd-yyyy');
    this.calculateScrollY();
    window.addEventListener('resize', this.calculateScrollY);
    this.tblShpSelectedRow(this.tableData[0].orderId, this.tableData[0]);
  }
  ngOnDestroy(): void {
    window.removeEventListener('resize', this.calculateScrollY);
  }
  //After View Method
  ngAfterViewInit(): void {
    if (this.datatableElement && this.datatableElement.dtInstance) {
      this.refreshDataTable();
    } else {
      // If dtInstance is not available yet, wait for a short time and try again
      setTimeout(() => this.refreshDataTable(), 500);
    }
  }
  isEmpty(value: any): boolean {
    return (
      value === null ||
      value === undefined ||
      value === '' ||
      value === defaultGuid
    );
  }
  tblShpSelectedRow(id: string, data) {
    this.selectedShippingData = data;
    this.tblShpSelectedRowId = id;
    this.isCashOrder = data.isCashOrder;
    // this.tblShpSelectedRowId === id ? defaultGuid : id;
    // this.tblShpSelectedRowId != id &&
    this.communicationService.triggerShippingDisapprovedItemsList(id);
    // this.tblShpSelectedRowId != id &&
    this.communicationService.triggerShippingBillingNotesDetails(id);
  }

  calculateScrollY = () => {
    const screenHeight =
      window.innerHeight ||
      document.documentElement.clientHeight ||
      document.body.clientHeight;
    const availableSpacePercentage = 0.3; // Adjust the percentage as needed
    this.dtShippingOptions.scrollY = screenHeight * availableSpacePercentage;
  };

  refreshDataTable(): void {
    this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
      // Clear search input values
      $('input', dtInstance.table(0).footer()).val('');

      // Redraw the DataTable to load all records
      dtInstance.search('').columns().search('').draw();

      // Attach the event listener for filtering
      dtInstance.columns().every(function () {
        const that = this;
        $('input', this.footer()).on('keyup change', function () {
          if (that.search() !== this['value']) {
            that.search(this['value']).draw();
          }
        });
      });
    });
  }

  //Row Click Event UnProcessed Table
  handleTableCellClick(e: Event, notesData: any): void {
    notesData = notesData == null || notesData == undefined ? '' : notesData;
    const target = e.target as HTMLElement;
    const tr = target.closest('tr');
    this.getDataTableData(tr, notesData);
  }

  //DataTable Data Get Method and Toggle Notes Row in UnProcessed Table
  async getDataTableData(tr: any, notesData: any): Promise<void> {
    this.myTable = await this.datatableElement.dtInstance;

    if (tr) {
      const row = this.myTable.row(tr);

      if (row.child.isShown()) {
        // This row is already open - close it
        row.child.hide();
      } else {
        // Open this row
        row.child(this.format(row.data(), notesData)).show();
      }
    }
  }

  //Add Dynamic Notes Row in UnProcessed Table
  format(d: any, notesData: any) {
    let returnString: string = '';

    returnString +=
      '<tr class="border-bottom" style="white-space: pre-wrap !important;"><td>' +
      notesData +
      '</td></tr>';

    return returnString;
  }
}
