import type { CreateUpdateInvoiceDTO, InvoiceDTO, InvoiceViewDTO } from './dto/models';
import { RestService } from '@abp/ng.core';
import type { PagedAndSortedResultRequestDto, PagedResultDto } from '@abp/ng.core';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class InvoiceDetailsService {
  apiName = 'orderManagement';

  create = (input: CreateUpdateInvoiceDTO) =>
    this.restService.request<any, InvoiceDTO>({
      method: 'POST',
      url: '/api/orderApp/invoice-details',
      body: input,
    },
    { apiName: this.apiName });

  delete = (id: string) =>
    this.restService.request<any, void>({
      method: 'DELETE',
      url: `/api/orderApp/invoice-details/${id}`,
    },
    { apiName: this.apiName });

  get = (id: string) =>
    this.restService.request<any, InvoiceDTO>({
      method: 'GET',
      url: `/api/orderApp/invoice-details/${id}`,
    },
    { apiName: this.apiName });

  getList = (input: PagedAndSortedResultRequestDto) =>
    this.restService.request<any, PagedResultDto<InvoiceDTO>>({
      method: 'GET',
      url: '/api/orderApp/invoice-details',
      params: { skipCount: input.skipCount, maxResultCount: input.maxResultCount, sorting: input.sorting },
    },
    { apiName: this.apiName });

  update = (id: string, input: CreateUpdateInvoiceDTO) =>
    this.restService.request<any, InvoiceDTO>({
      method: 'PUT',
      url: `/api/orderApp/invoice-details/${id}`,
      body: input,
    },
    { apiName: this.apiName });
    getInvoiceBySaleOrderIdByGSaleOrdeId = (gSaleOrdeId: string) =>
    this.restService.request<any, PagedResultDto<InvoiceDTO>>({
      method: 'GET',
      url: `/api/orderApp/invoice-details/get-invoice-by-sale-order-id/${gSaleOrdeId}`,
    },
    { apiName: this.apiName });
  getInvoiceDetailsByIdByGInvoiceId = (gInvoiceId: string) =>
    this.restService.request<any, InvoiceViewDTO>({
      method: 'GET',
      url: `/api/orderApp/invoice-details/get-invoice-details-by-id/${gInvoiceId}`,
    },
    { apiName: this.apiName });
    searchInvoiceBySSaleOrdeIdAndSPatientIdAndSPatientNameAndSInvoiceCodeAndSInvoiceTypeAndDtCreatedDateAndDtStartDateAndDtEndDate = (sSaleOrdeId: string, sPatientId: string, sPatientName: string, sInvoiceCode: string, sInvoiceType: string, dtCreatedDate: string, dtStartDate: string, dtEndDate: string) =>
    this.restService.request<any, PagedResultDto<InvoiceDTO>>({
      method: 'POST',
      url: '/api/orderApp/invoice-details/search-invoice',
      params: { sSaleOrdeId, sPatientId, sPatientName, sInvoiceCode, sInvoiceType, dtCreatedDate, dtStartDate, dtEndDate },
    },
    { apiName: this.apiName });
    getInvoiceBySaleOrderId = (orderId: string) =>
    this.restService.request<any, InvoiceDTO[]>(
      {
        method: 'GET',
        url: `/api/orderApp/invoice-details/get-invoice-by-sale-order-id/${orderId}`,
      },
      { apiName: this.apiName }
    );
  constructor(private restService: RestService) {}
}
