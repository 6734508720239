<div class="container-fluid">
  <div class="row">
    <div class="col-12 px-0">
      <div class="d-flex justify-content-between mb-2">
        <h2 class="header-text mb-0 d-flex align-items-center">Override</h2>
        <button mat-icon-button mat-dialog-close class="close-button">
          <mat-icon>close</mat-icon>
        </button>
      </div>
    </div>
  </div>


  <div class="row">
    <div class="col-12 px-0">
      <div class="card card-body p-0">
        <form [formGroup]="overrideForm">
          <!-- Override Type -->
          <mat-form-field class="col-4">
            <mat-label>Override Type<span class="asterisk">*</span></mat-label>
            <input
              matInput
              readonly
              type="text"
              formControlName="txtOverrideType"
            />
          </mat-form-field>
          <!-- Override Notes-->
          <mat-form-field class="col-12">
            <mat-label>Override Notes<span class="asterisk">*</span></mat-label>
            <textarea
              matInput
              [(ngModel)]="overrideNotes"
              (input)="updateCharacterCount(5000)"
              maxlength="5000"
              formControlName="txtOverrideNotes"
              rows="4"
            >
            </textarea>
            <div class="char-count">{{ overrideNotes?.length }} / 5000</div>
            <mat-error
              *ngIf="
                overrideForm?.get('txtOverrideNotes')?.touched &&
                overrideForm?.get('txtOverrideNotes')?.errors
              "
            >
              Override Note is required
            </mat-error>
          </mat-form-field>
        </form>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-12 d-flex flex-row-reverse mt-2">
      <!-- <button
        type="button"
        (click)="saveUpdateOrder(false)"
        mat-raised-button
        class="buttonColor"
      >
        <img
          class="img-responsive pr-1 mb-1"
          src="assets/Order Page Icons/Skip override.png"
          alt=""
          height="17"
        />
        Skip & Move to Shipping
      </button> -->
      <button
        mat-raised-button
        [disabled]="!(!overrideForm?.invalid && overrideForm?.dirty)"
        (click)="saveUpdateOrder(true)"
        type="button"
        class="buttonColor mr-2"
      >
        <img
          class="img-responsive pr-1 mb-1"
          src="assets/Order Page Icons/Override.png"
          alt=""
          height="17"
        />
        Override
      </button>
    </div>
  </div>
</div>
