import { RestService } from '@abp/ng.core';
import type { PagedAndSortedResultRequestDto, PagedResultDto } from '@abp/ng.core';
import { Injectable } from '@angular/core';
import type { CreateUpdateMmModifierDTO, MmModifierDTO, modifiersDTO } from '../dto/models';

@Injectable({
  providedIn: 'root',
})
export class MmModifierService {
  apiName = 'billingManagement';

  create = (input: CreateUpdateMmModifierDTO) =>
    this.restService.request<any, MmModifierDTO>({
      method: 'POST',
      url: '/api/billingApp/mm-modifier',
      body: input,
    },
    { apiName: this.apiName });

  delete = (id: string) =>
    this.restService.request<any, void>({
      method: 'DELETE',
      url: `/api/billingApp/mm-modifier/${id}`,
    },
    { apiName: this.apiName });

  get = (id: string) =>
    this.restService.request<any, MmModifierDTO>({
      method: 'GET',
      url: `/api/billingApp/mm-modifier/${id}`,
    },
    { apiName: this.apiName });

  getAllModifiers = () =>
    this.restService.request<any, MmModifierDTO[]>({
      method: 'GET',
      url: '/api/billingApp/mm-modifier/modifiers',
    },
    { apiName: this.apiName });

  getAllModifiersActiveList = () =>
    this.restService.request<any, MmModifierDTO[]>({
      method: 'GET',
      url: '/api/billingApp/mm-modifier/modifiers-active-list',
    },
    { apiName: this.apiName });

  getModifiers = (input: PagedAndSortedResultRequestDto) =>
    this.restService.request<any, PagedResultDto<MmModifierDTO>>({
      method: 'GET',
      url: '/api/billingApp/mm-modifier',
      params: { skipCount: input.skipCount, maxResultCount: input.maxResultCount, sorting: input.sorting },
    },
    { apiName: this.apiName });

  getModifiersList = (pageNo: number, pageOffset: number) =>
    this.restService.request<any, PagedResultDto<modifiersDTO>>({
      method: 'GET',
      url: '/api/billingApp/mm-modifier/modifiers-list',
      params: { pageNo, pageOffset },
    },
    { apiName: this.apiName });

  update = (id: string, input: CreateUpdateMmModifierDTO) =>
    this.restService.request<any, MmModifierDTO>({
      method: 'PUT',
      url: `/api/billingApp/mm-modifier/${id}`,
      body: input,
    },
    { apiName: this.apiName });

  updateMmModifiers = () =>
    this.restService.request<any, void>({
      method: 'PUT',
      url: '/api/billingApp/mm-modifier/update-mm-modifiers',
    },
    { apiName: this.apiName });

  constructor(private restService: RestService) {}
}
