//#region Import
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { SearchFaxFinder } from 'projects/shared/src/app/enums/allenums.enum';
import { InboundDocumentService } from '../../admin-proxy/platform-app-management/rcm/platform-management/inbound/inbound-document.service';
import { DatePipe } from '@angular/common';
import { Subscription } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DataTableDirective } from 'angular-datatables';

//#endregion

@Component({
  selector: 'app-fax-finder-lazy-loading',
  templateUrl: './fax-finder-lazy-loading.component.html',
  styleUrls: ['./fax-finder-lazy-loading.component.scss'],
})
export class FaxFinderLazyLoadingComponent implements OnInit {
  //#region Variables Initialization Declaration
  @ViewChild(DataTableDirective, { static: false })
  datatableElement: DataTableDirective;
  @ViewChild('tableContainer') tableContainer: ElementRef;
  @ViewChild('faxIdInput') faxIdInput!: ElementRef;
  @ViewChild('patientNameInput') patientNameInput!: ElementRef;
  @ViewChild('chartNoInput') chartNoInput!: ElementRef;
  @ViewChild('dateOfBirthInput') dateOfBirthInput!: ElementRef;
  @ViewChild('referralSourceInput') referralSourceInput!: ElementRef;
  @ViewChild('locationInput') locationInput!: ElementRef;
  @ViewChild('folderInput') folderInput!: ElementRef;
  @ViewChild('insuranceInput') insuranceInput!: ElementRef;

  dtOptions: DataTables.Settings = {};
  dataTable: any;
  faxFinderDataList: any[] = [];
  start: number = 1;
  end: number = 10;
  isLoading: boolean = false;
  private faxFinderAPICall: Subscription;
  scrollPosition: number;
  IsFaxAvailable: boolean = true;
  fgFaxFinder: FormGroup;
  BirthdateMaxDate: Date;
  BirthdateMinDate: Date;
  FaxIdInput: any;
  ChartNoInput: any;
  PatientNameInput: any;
  txtBirthdateInput: any;
  onWheel = false;
  //#endregion

  constructor(
    private inboundDocService: InboundDocumentService,
    private toastr: ToastrService,
    private elementRef: ElementRef,
    private fb: FormBuilder,
    private snackBar: MatSnackBar
  ) {}

  ngOnInit() {
    this.initializeForms();
    this.dtOptions = {
      dom: 'lrti',
      language: {
        emptyTable: 'No records',
        info: 'Total : _MAX_ records',
        infoEmpty: 'Total : _MAX_ records',
        infoFiltered: '(filtered : _TOTAL_ records)',
        loadingRecords: 'Loading...',
        zeroRecords: 'No matching records',
      },
      responsive: true,
      processing: true,
      paging: false,
      info: true,
      scrollCollapse: true,
      scrollY: '250px',
      data: [],

      columns: [
        {
          title: 'Fax Id',
          data: 'faxId',
          render: function (data, type, row) {
            if (type === 'display') {
              return (
                '<div class="copy-text" data-copy-text="' +
                data +
                '">' +
                (data || '-') +
                '</div>'
              );
            }
            return data || '-';
          },
        },
        {
          title: 'Patient Name',
          data: 'patientName',
          render: function (data, type, row) {
            if (type === 'display') {
              return (
                '<div class="copy-text" data-copy-text="' +
                data +
                '">' +
                (data || '-') +
                '</div>'
              );
            }
            return data || '-';
          },
        },
        {
          title: 'Chart No',
          data: 'chartId',
          render: function (data, type, row) {
            if (type === 'display') {
              return (
                '<div class="copy-text" data-copy-text="' +
                data +
                '">' +
                (data || '-') +
                '</div>'
              );
            }
            return data || '-';
          },
        },
        {
          title: 'DOB',
          data: 'dob',
          defaultContent: '',
          render: function (data, type) {
            if (type === 'display') {
              if (!data) {
                // If data is null or undefined, return '-'
                return '-';
              }

              // Parse the ISO 8601 date string
              const isoDate = new Date(data);

              // Get the components of the date (month, day, and year)
              const month = String(isoDate.getMonth() + 1).padStart(2, "0"); // Months are zero-based
              const day = String(isoDate.getDate()).padStart(2, "0");
              const year = isoDate.getFullYear();

              // Format the date as mm/dd/yyyy
              const formattedDate = `${month}/${day}/${year}`;

              // Return the formatted date within a copy-text div
              return (
                '<div class="copy-text" data-copy-text="' +
                formattedDate +
                '">' +
                (formattedDate || '-') +
                '</div>'
              );
            }
            return data || '-';
          }
        },
        {
          title: 'Referral Source Name',
          data: 'referalsourceName',
          render: function (data, type, row) {
            if (type === 'display') {
              return (
                '<div class="copy-text" data-copy-text="' +
                data +
                '">' +
                (data || '-') +
                '</div>'
              );
            }
            return data || '-';
          },
        },
        {
          title: 'Location',
          data: 'locationName',
          render: function (data, type, row) {
            if (type === 'display') {
              return (
                '<div class="copy-text" data-copy-text="' +
                data +
                '">' +
                (data || '-') +
                '</div>'
              );
            }
            return data || '-';
          },
        },
        {
          title: 'Folder',
          data: 'inboundStatus',
          render: function (data, type, row) {
            if (type === 'display') {
              return (
                '<div class="copy-text" data-copy-text="' +
                data +
                '">' +
                (data || '-') +
                '</div>'
              );
            }
            return data || '-';
          },
        },
        {
          title: 'Insurance',
          data: 'payorName',
          render: function (data, type, row) {
            if (type === 'display') {
              return (
                '<div class="copy-text" data-copy-text="' +
                data +
                '">' +
                (data || '-') +
                '</div>'
              );
            }
            return data || '-';
          },
        },
      ],
      columnDefs: [{ targets: [0, 1, 2, -1], className: 'dt-fixed-column' },{ type: 'date', targets: [3] },],
      "initComplete": function(settings, json) {
        $(".thealert").html("Done"); // Set "Done" message after initialization
      }

    };

  //   const faxfinderTable = $('#faxfindertbl').DataTable();

  // // Check if DataTables is already initialized on the table
  // if (!faxfinderTable || !faxfinderTable.settings()[0]) {
  //   $('#faxfindertbl')
  //     .on('preXhr.dt', function (e, settings, data) {
  //       $(".thealert").html("Loading"); // Set "Loading" message before AJAX request
  //     })
  //     .on('draw.dt', function () {
  //       $(".thealert").html("Done"); // Set "Done" message after data redrawn
  //     })
  //     .DataTable(this.dtOptions);
  // }

    this.loadDefault();
    this.loadValidDates();
  }

  ngOnDestroy(): void {
    if (this.faxFinderAPICall) {
      this.faxFinderAPICall.unsubscribe();
    }
  }

  ngAfterViewInit() {
    if (this.datatableElement) {
    this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
      this.dataTable = dtInstance;
    });
  }
    setTimeout(() => {
      this.refreshDataTable();
    }, 100);

    // window.addEventListener('wheel', this.onTableScroll, true);
    window.addEventListener('scroll', this.onTableScroll, true);

    window.addEventListener('wheel', this.onTableWheel, true);

    const tableElement: any = $(this.tableContainer.nativeElement).find(
      'table'
    );
    // this.dataTable = tableElement.DataTable(this.dtOptions);

    // Add click event listener to handle row selection
    tableElement.on('click', 'tbody tr', (event: any) => {
      const $row = $(event.currentTarget);
      const rowData = this.dataTable.row($row).data();

      tableElement
        .find('tbody tr.selected')
        .removeClass('selected')
        .css('background-color', '')
        .css('color', '');
      tableElement
        .find('tbody tr.selected-row')
        .removeClass('selected-row')
        .css('background-color', '')
        .css('color', '');

      // Apply background color to the clicked row
      $row.addClass('selected-row').css('background-color', 'lightseagreen');

      // If using DataTables row selection feature, handle it here
      if (
        this.dataTable &&
        this.dataTable.rows({ selected: true }).count() > 0
      ) {
        this.dataTable.rows().deselect();
      }
      this.dataTable.row($row).select();
      tableElement
        .find('tbody tr.selected')
        .removeClass('selected')
        .css('background-color', '')
        .css('color', '');
      $row.find('tbody tr.selected a').css('color', '');
      $row.css('background-color', 'lightseagreen').css('color', 'white');
      // $row.find('a').css('color', 'white');

    });

    document.addEventListener('dblclick', this.handleDocumentClick.bind(this));
  }

  handleDocumentClick(event: Event) {
    const target = event.target as HTMLElement;
    if (target.classList.contains('copy-text')) {
      const copy_text = target.getAttribute('data-copy-text');
      if (copy_text) {
        this.onRowDoubleClickV1(copy_text);
      }
    }
  }
  //To set the Valid Dates for Birth Date field
  loadValidDates() {
    this.BirthdateMaxDate = new Date();
    this.BirthdateMaxDate.setDate(this.BirthdateMaxDate.getDate());
    this.BirthdateMinDate = new Date();
    this.BirthdateMinDate.setFullYear(
      this.BirthdateMinDate.getFullYear() - 120
    );
    var oneYearAgo = new Date();
    oneYearAgo.setFullYear(this.BirthdateMaxDate.getFullYear() - 1);
    // this.RxdateMin = oneYearAgo;
  }

  onKeyPressSearch() {
    this.start=1;
    this.loadDefault();
  }

  resetFaxFinder() {
    this.fgFaxFinder.reset();
    this.start=1;
    this.loadDefault();
  }

  initializeForms() {
    this.fgFaxFinder = this.fb.group({
      FaxId: new FormControl(''),
      ChartNo: new FormControl(''),
      PatientName: new FormControl(''),
      txtBirthdate: new FormControl(''),
    });
  }

  onRowDoubleClickV1(textToCopy) {
    this.copyTextToClipboard(textToCopy);
    this.showNotification('Text copied: ' + textToCopy);
  }

  showNotification(message: string): void {
    this.snackBar.open(message, 'Close', {
      duration: 3000, // Duration in milliseconds
      horizontalPosition: 'end', // Positioning the notification horizontally
      verticalPosition: 'bottom', // Positioning the notification vertically
    });
  }

  copyTextToClipboard(text: string): void {
    const textArea = document.createElement('textarea');
    textArea.value = text;
    document.body.appendChild(textArea);
    textArea.select();
    document.execCommand('Copy');
    textArea.remove();
  }

  onTableWheel = (event: WheelEvent) => {
    if (event.deltaY === 0) return;
    this.onWheel = true;
  };

  onTableScroll = (event: Event) => {
    if (this.onWheel) {
      const container = event.target as HTMLElement;
      const scrollPosition = container.scrollTop + container.clientHeight;
      const isScrollAtBottom =
        scrollPosition >= container.scrollHeight - container.clientHeight - 50; //  - 100; // Check for exact bottom

      if (isScrollAtBottom && !this.isLoading && scrollPosition > 0) {
        // this.scrollPosition = container.scrollTop; //  - 50
        this.scrollPosition = scrollPosition - 50;
        this.loadMore();
      }
    }
  };

  loadMore() {
    this.onWheel = false;
    if (this.IsFaxAvailable) {
      this.isLoading = true;
      const container = this.tableContainer.nativeElement;
      const scrollPositionBeforeLoad = container.scrollTop;
      this.start++;
      if (this.faxFinderAPICall) {
        this.faxFinderAPICall.unsubscribe();
      }
      this.faxFinderAPICall = this.inboundDocService
        .getFaxDocumentDetailsByFilter(
          this.start,
          this.end,
          this.getFormattedDate(this.fgFaxFinder.value.txtBirthdate),
          this.fgFaxFinder.value.FaxId,
          this.fgFaxFinder.value.ChartNo,
          this.fgFaxFinder.value.PatientName
        )
        .subscribe(
          (response) => {
            this.IsFaxAvailable = response['bIsFaxAvailable'] || false;
            this.faxFinderDataList = [
              ...this.faxFinderDataList,
              ...response['ltFaxes'],
            ];
            if (this.dataTable) {
              this.dataTable.rows.add(response['ltFaxes'] || []).draw();
              const dataTableBody = this.elementRef.nativeElement.querySelector(
                'div.dataTables_scrollBody'
              );
              if (dataTableBody) {
                dataTableBody.scrollTop = this.scrollPosition;
              }
            }
            this.isLoading = false;
          },
          (error) => {
            const data: HttpErrorResponse = error;
            this.toastr.error(data?.error?.error?.message, 'Error');
            this.isLoading = false;
          }
        );
    }
  }

  // getFormattedDate(date: string): string {
  //   return date ? new Date(date).toLocaleDateString('en-US') : '-';
  // }

  getFormattedDate(date: string): string {
    if (!date) return null;

    const formattedDate = new Date(date);
    const year = formattedDate.getFullYear();
    const month = (formattedDate.getMonth() + 1).toString().padStart(2, '0');
    const day = formattedDate.getDate().toString().padStart(2, '0');

    return `${year}-${month}-${day}`;
  }

  loadDefault() {
    this.isLoading = true;
    if (this.faxFinderAPICall) {
      this.faxFinderAPICall.unsubscribe();
    }
    this.faxFinderAPICall = this.inboundDocService
      .getFaxDocumentDetailsByFilter(
        this.start,
        this.end,
        this.getFormattedDate(this.fgFaxFinder.value.txtBirthdate),
        this.fgFaxFinder.value.FaxId,
        this.fgFaxFinder.value.ChartNo,
        this.fgFaxFinder.value.PatientName
      )
      .subscribe(
        (response) => {
          this.faxFinderDataList = response['ltFaxes'];
          if (this.dataTable) {
            this.dataTable.clear();
            this.dataTable.rows.add(this.faxFinderDataList);
            this.dataTable.draw();
          }
          this.isLoading = false;
          setTimeout(() => {
            this.refreshDataTable();
          }, 100);
        },
        (error) => {
          const data: HttpErrorResponse = error;
          this.toastr.error(data?.error?.error?.message, 'Error');
          this.isLoading = false;
        }
      );
  }

  refreshDataTable(): void {
    // const table = $('#tableContainer table').DataTable(); // Initialize DataTable

    const table = this.dataTable;

    if (!table) {
      return;
    }

    // Clear search input values
    $('tfoot input').val('');


  //   @ViewChild('faxIdInput') faxIdInput!: ElementRef;
  // @ViewChild('patientNameInput') patientNameInput!: ElementRef;
  // @ViewChild('chartNoInput') chartNoInput!: ElementRef;
  // @ViewChild('dateOfBirthInput') dateOfBirthInput!: ElementRef;
  // @ViewChild('referralSourceInput') referralSourceInput!: ElementRef;
  // @ViewChild('locationInput') locationInput!: ElementRef;
  // @ViewChild('folderInput') folderInput!: ElementRef;
  // @ViewChild('insuranceInput') insuranceInput!: ElementRef;
    // Attach the event listeners for filtering
    $(this.faxIdInput.nativeElement).keyup(() => {
      table.column(0).search(this.faxIdInput.nativeElement.value).draw();
    });
    $(this.patientNameInput.nativeElement).keyup(() => {
      table.column(1).search(this.patientNameInput.nativeElement.value).draw();
    });
    $(this.chartNoInput.nativeElement).keyup(() => {
      table.column(2).search(this.chartNoInput.nativeElement.value).draw();
    });
    $(this.dateOfBirthInput.nativeElement).keyup(() => {
      table
        .column(3)
        .search(this.dateOfBirthInput.nativeElement.value)
        .draw();
    });
    $(this.referralSourceInput.nativeElement).keyup(() => {
      table
        .column(4)
        .search(this.referralSourceInput.nativeElement.value)
        .draw();
    });
    $(this.locationInput.nativeElement).keyup(() => {
      table
        .column(5)
        .search(this.locationInput.nativeElement.value)
        .draw();
    });
    $(this.folderInput.nativeElement).keyup(() => {
      table
        .column(6)
        .search(this.folderInput.nativeElement.value)
        .draw();
    });
    $(this.insuranceInput.nativeElement).keyup(() => {
      table
        .column(7)
        .search(this.insuranceInput.nativeElement.value)
        .draw();
    });

  }
}
