<div class="account-pages mt-5 mb-5">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-md-8 col-lg-6 col-xl-5">
        <div class="card overflow-hidden">
          <div class="card-body pt-0">
            <div></div>
            <div class="p-2">
              <div class="col-12 text-center pt-4">
                <!-- <div class="p-4"> -->
                <img src="assets/images/rnd.jpg" alt="" class="img-fluid" height="70" />
                <!-- </div> -->
              </div>
              <form class="form-horizontal" [formGroup]="userRegistrationForm">
                <mat-card-content>
                  <div class="col-12 pt-3 text-left registerClass">
                    <p> <b> Welcome to Qsecure!! </b>
                    </p>
                    <p> <b> Register here to continue... </b>
                    </p>
                  </div>
                  <p class="col-12 pt-1">
                    <mat-label> Client Name : {{tenantName}}</mat-label>
                  </p>
                  <mat-form-field class="col-12 pt-1">
                    <mat-label style="color: black !important;">Email <span class="asterisk">*</span> </mat-label>
                    <input autocomplete="new-txtUserName" matInput maxlength="40" type="text" placeholder="Enter Email"
                      formControlName="txtUserName" pattern="[a-zA-Z0-9._!#$%&'()*+,-./:;<=>?@[\]^_`{|}~]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$"
                      [textMask]="{ mask: emailMask }"
                    />
                    <mat-error *ngIf="
                      userRegistrationForm?.get('txtUserName')?.touched &&
                      userRegistrationForm?.get('txtUserName')?.errors?.required">
                      Username is required!
                    </mat-error>
                    <!-- <mat-error *ngIf="
                      userRegistrationForm?.get('txtUserName')?.touched &&
                      userRegistrationForm?.get('txtUserName')?.errors?.sAlphabets">
                      Enter only Alphabets!
                    </mat-error> -->
                    <mat-error *ngIf="
                      userRegistrationForm?.get('txtUserName')?.touched &&
                      userRegistrationForm?.get('txtUserName')?.errors?.required">
                      Email Address is a required field!
                    </mat-error>
                  </mat-form-field>
                  <mat-form-field class="col-12 pt-1">
                    <mat-label style="color: black !important;">Password <span class="asterisk">*</span> </mat-label>
                    <input autocomplete="new-password" matInput [type]="hide ? 'password' : 'text'" formControlName="txtPassword"
                      placeholder="Enter Password" pattern="(?=.*\d)(?=.*[a-z])(?=.*[$@$#!%*?^()&])(?=.*[A-Z]).{8,}" />
                    <button class="font-size-20" mat-icon-button matSuffix (click)="hide = !hide">
                      <mat-icon>{{ hide ? 'visibility_off' : 'visibility' }}</mat-icon>
                    </button>
                    <mat-error *ngIf="
                        userRegistrationForm?.get('txtPassword')?.touched &&
                        userRegistrationForm?.get('txtPassword')?.errors?.required">
                      Password is required!
                    </mat-error>
                    <mat-error *ngIf="
                        userRegistrationForm?.get('txtPassword')?.touched &&
                        userRegistrationForm?.get('txtPassword')?.errors?.pattern">
                      Passwords must contain at least one non alphanumeric character,one
                      uppercase,one digit and at least 8 or more characters
                    </mat-error>
                  </mat-form-field>
                </mat-card-content>

                <div class="col-12 pt-4">
                  <button mat-button class="buttonColor btn-block mb-2 mr-2" (click)="userRegister()"
                    [disabled]="userRegistrationForm.invalid" type="submit">
                    Register
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div class="mt-1 text-center">
          <p>
            © {{ year }} QSecure. Crafted with <i class="mdi mdi-heart text-danger"></i> by RND Softech
          </p>
        </div>
      </div>
    </div>
  </div>

  <!-- end container -->
</div>
