import { ListService } from '@abp/ng.core';
import { DatePipe } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { DashboardService } from 'projects/admin/src/app/admin-proxy/platform-app-management/rcm/platform-management/dashboard/dashboard.service';
import { UserService } from 'projects/admin/src/app/admin-proxy/platform-app-management/rcm/platform-management/user/user.service';
import { TableService } from 'projects/shared/src/app/table.service';
import { DateValidator } from 'projects/shared/src/app/validations/date-validator';
import { Subscription } from 'rxjs';
import Swal from 'sweetalert2';

import { AutoIntakeDocumentDetailsDTO, PatientDTO } from '../patient-proxy/patient/dto/models';
import { PatientSearchService } from '../patient-proxy/patient/patient-search.service';
import { SearchPracticeDTO } from '../patient-proxy/practice-management/dto/models';
import { PracticeManagementService } from '../patient-proxy/practice-management/practice-management.service';
import { getPatient } from '../patient-store/patient.action';

@Component({
  selector: 'app-referral-practice-list',
  templateUrl: './referral-practice-list.component.html',
  styleUrls: ['./referral-practice-list.component.scss'],
  providers: [ListService, DatePipe],
})
export class ReferralPracticeListComponent implements OnInit, OnDestroy {
  isLoading: boolean;
  isShowProgress: boolean;
  subscription$: Subscription[] = [];



  displayedColumns: string[] = ['options','Name', 'City', 'State', 'ZIP', 'PhoneNo', 'FaxNo', 'Manager', 'createdDate', 'Status', ];
  PracticeData: any[] = [];
  dataSource = new MatTableDataSource(this.PracticeData);
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild('MatPaginator', { static: false }) paginator: MatPaginator;
  referralForm: FormGroup;
  organizationUnitId = null;
  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    public list: ListService,
    public title: Title,
    private store: Store,
    public userProfileService: UserService,
    private tableService: TableService,
    private practiceService: PracticeManagementService,
    private datepipe: DatePipe,
    private dashboardService: DashboardService,
    private dateValidator: DateValidator,
  ) {
  }

  ngOnDestroy(): void {
    this.subscription$.forEach(sub => {
      sub && sub.unsubscribe();
    })
  }

  isPatientCreatePermission: boolean = false;
  isPatientViewPermission: boolean = false;
  isPatientUpdatePermission: boolean = false;



  isShownPatientTable: boolean = true;
  isShownSaveButton: boolean = true;
  isShownUpdateIconForPatient: boolean = true;
  strLoginUserId: string = '';
  strLoginUserName: string = '';
  tenantId: string = '';

  ngOnInit(): void {
    this.referralForm = this.formBuilder.group({
      City: new FormControl(""),
      State: new FormControl(""),
      ZIP: new FormControl(""),
      PhoneNo: new FormControl(""),
      FaxNo: new FormControl(""),
      Manager: new FormControl(""),
      Status: new FormControl(""),
      Name: new FormControl(""),
      createdDate: new FormControl("", this.dateValidator.dateVaidator),
    })

    const valueChanges: Subscription = this.referralForm.valueChanges.subscribe((value) => {
      const filter = {
        Name: value?.Name?.trim()?.toLowerCase(),
        City: value?.City?.trim()?.toLowerCase(),
        State: value?.State?.trim()?.toLowerCase(),
        ZIP: value?.ZIP?.trim()?.toLowerCase(),
        PhoneNo: value?.PhoneNo?.trim()?.toLowerCase(),
        FaxNo: value?.FaxNo?.trim()?.toLowerCase(),
        Manager: value?.Manager?.trim()?.toLowerCase(),
        Status: value?.Status?.trim()?.toLowerCase(),
        createdDate: value?.createdDate?.trim()?.toLowerCase()
      };
      if (this.referralForm.valid) {
        this.dataSource.filter = JSON.stringify(filter);
        this.filtertable(filter);
      }
    }, err => {
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    });
    this.subscription$.push(valueChanges);
    this.title.setTitle("Qsecure | Referral Practice List");
    this.dataSource = new MatTableDataSource([]);
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
    this.getTableData();
  }
  alphaOnly(event: { which: any; keyCode: any; }): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 65 || charCode > 90) && (charCode < 97 || charCode > 122)) {
      return false;
    }
    return true;
  }

  loadUserProfile() {
    const userProfile = this.dashboardService.userProfile().subscribe(response => {
      this.organizationUnitId = response.organizationUnitId;
    }, err => {
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    });
    this.subscription$.push(userProfile);
  }

  //// Load table datas
  getTableData() {
    this.isShowProgress = true;
    const practiceList = (query: any) => this.practiceService.getList(query);
    const getTableData = this.list.hookToQuery(practiceList).subscribe(response => {
      this.PracticeData = [];
      response && response.totalCount && response.items && response?.items?.forEach(element => {
        this.PracticeData.push({
          practiceName: element?.practiceName,
          practiceState: element?.practiceState,
          practiceCity: element?.practiceCity,
          practiceZipCode: element?.practiceZipCode,
          practicePhone: element?.practicePhone,
          practiceManager: element?.practiceManager,
          practiceFax: element?.practiceFax,
          createdDate: element?.creationTime,
          id: element?.id,
          status: element?.status
        });
      });
      this.isShowProgress = false;
      this.dataSource = new MatTableDataSource(this.PracticeData);
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
    }, error => {
      this.isShowProgress = false;
      const data: HttpErrorResponse = error;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
      this.dataSource = new MatTableDataSource(this.PracticeData);
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
    })
    this.subscription$.push(getTableData);
  }

  /// table search for practice table
  filtertable(filter) {
    const createdDate = filter.createdDate
    // const DateTime = createdDate === null ? "" : Intl.DateTimeFormat('en-US').format(new Date(createdDate));
    const searchData: SearchPracticeDTO = {
      practiceName: filter?.Name,
      city: filter?.City,
      state: filter?.State,
      zipCode: filter?.ZIP,
      phoneNo: filter?.PhoneNo,
      faxNo: filter?.FaxNo,
      manager: filter?.Manager,
      createdDate: filter.createdDate,
      status: filter?.Status,
    }

    this.PracticeData = [];
    const searchPractise = this.practiceService.searchPracticeByInput(searchData).subscribe(data => {
      this.PracticeData = [];
      data && data.items.forEach(element => {
        this.PracticeData.push({
          practiceName: element?.practiceName,
          practiceState: element?.practiceState,
          practiceCity: element?.practiceCity,
          practiceZipCode: element?.practiceZipCode,
          practicePhone: element?.practicePhone,
          practiceManager: element?.practiceManager,
          practiceFax: element?.practiceFax,
          createdDate: element?.creationTime,
          id: element?.id,
          status: element?.status
        });
        this.isLoading = false;
        this.dataSource = new MatTableDataSource(this.PracticeData);
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
      }, error => {
        this.isLoading = false;
        this.dataSource = new MatTableDataSource(this.PracticeData);
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
      })
    }, err => {
      // const data: HttpErrorResponse = err;
      // Swal.fire({
      //   icon: 'error',
      //   text: data?.error?.error?.message,
      // });
    })
    this.subscription$.push(searchPractise);
    this.dataSource = new MatTableDataSource(this.PracticeData);
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }

  // ! Nwe Practice add page
  newPractice() {
    this.tableService.setDocumentFormData(null);
    this.router.navigate(['referral-practice'])
  }

  // allow numbers only
  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  // ! View referral practice
  viewPractice(practiceId: string) {
    if ((practiceId !== "" && practiceId !== null && practiceId !== undefined)) {
      const practiceGet = this.practiceService.get(practiceId).subscribe(response => {
      }, err => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      })
      this.subscription$.push(practiceGet);
    }

    this.tableService.setDocumentFormData(null);
    this.router.navigate(['/referral-practice/' + practiceId]);
  }

  // ! Document State Clear on Exisiting  Patient
  // viewPatient(patientId: string) {

  //   (patientId !== "" && patientId !== null && patientId !== undefined) && this.store.dispatch(new getPatient(patientId)).subscribe(response => {

  //     const selectedPatient: PatientDTO = response?.patientState?.selectedPatient;
  //     this.tableService.setCountryTable(selectedPatient);

  //   }, err => {
  //     const data: HttpErrorResponse = err;
  //     Swal.fire({
  //       icon: 'error',
  //       text: data?.error?.error?.message,
  //     });
  //   })
  //   this.tableService.setDocumentFormData(null);
  //   this.router.navigate(['/patientViewEdit/' + patientId]);
  // }

}


