import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatAccordion } from '@angular/material/expansion';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { PatientsService } from 'projects/patient/src/app/patient-proxy/patient/patients.service';
import Swal from 'sweetalert2';
import { VerifyPatientAmountsComponent } from '../verify-patient-amounts/verify-patient-amounts.component';

@Component({
  selector: 'app-document-eligibility-history',
  templateUrl: './document-eligibility-history.component.html',
  styleUrls: ['./document-eligibility-history.component.scss']
})
export class DocumentEligibilityHistoryComponent implements OnInit {
  isLoading: boolean;
  documentEligibilitySearchForm: FormGroup;
  displayedColumns: string[] = [
   
    'plan',
    'planName',
    'policy',
    // 'blueCardEligible',
    // 'claimSubmit',
    // 'insuranceActive',
    // 'preAuthAvailable',
    // 'localBillAvailable',
    // 'familyCoinsurance',
    // 'familyDeductablePayment',
    // 'familyOutOfPocket',
    // 'individualCoinsurance',
    // 'individualDeductablePayment',
    // 'individualOutOfPocket',
    // 'paymentComments',
    'type',
    'status',
    'options',
    ];
  dataSource: MatTableDataSource<any> = new MatTableDataSource<any>([]);
  @ViewChild(MatAccordion) accordion: MatAccordion;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator

  constructor(
    private formBuilder: FormBuilder,
    private patientsService: PatientsService,
    public policyDialogRef: MatDialogRef<DocumentEligibilityHistoryComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { patientsId: string },
    public dialog: MatDialog,
  ) { }

  ngOnInit(): void {

    this.documentEligibilitySearchForm = this.formBuilder.group({
      productNumberSearch: new FormControl(""),
      orderIdSearch: new FormControl(""),
      statusSearch: new FormControl(""),
      soldDateSearch: new FormControl("")
    });
    this.getTableData();

  }

  ///// get table datas for eligiblity history table
  verifyTableData: any[] = [];
  getTableData() {
    this.patientsService.get(this.data.patientsId).subscribe((res) => {
      let response = res?.verifications;
      this.verifyTableData = [];
      const primaryIns = response?.primaryInsuranceVerifies;
      const secondaryIns = response?.secondaryInsuranceVerifies;
      const tertiaryIns = response?.tertiaryInsuranceVerifies;
      if (primaryIns) {
        this.verifyTableData.push({
          id: res?.id,
          verifiedTypeMethod: primaryIns?.verifiedTypeMethod,
          patientName: res?.demographics[0]?.patientName,
          dateOfBirth: res?.demographics[0]?.dateOfBirth,
          // patientId: verifyDetails.id,
          address: primaryIns?.address,
          callTrackingNumber: primaryIns?.callTrackingNumber,
          chart: primaryIns?.chart,
          claimAddress: primaryIns?.claimAddress,
          cmnCode: primaryIns?.cmnCode,
          codesRequirePa: primaryIns?.codesRequirePa,
          commentsOrNotes: primaryIns?.commentsOrNotes,
          contact: primaryIns?.contact,
          coverageCriteria: primaryIns?.coverageCriteria,
          dateVerified: primaryIns?.dateVerified,
          dateofBirth: primaryIns?.dateofBirth,
          dedMet: primaryIns?.dedMet,
          dedTotal: primaryIns?.dedTotal,
          deductibleMet: {
            individual: primaryIns?.deductibleMet?.individual,
            outOfNetwork: primaryIns?.deductibleMet?.outOfNetwork,
            family: primaryIns?.deductibleMet?.family,  
          },
          // 
          dmeAnnualLimit: primaryIns?.dmeAnnualLimit,
          dmeCoverage: primaryIns?.dmeCoverage,
          dmeLimitMet: primaryIns?.dmeLimitMet,
          dmeMax: primaryIns?.dmeMax,
          effectiveDate: primaryIns?.effectiveDate,
          end: primaryIns?.end,
          fascalYearStart: primaryIns?.fascalYearStart,
          frequency: primaryIns?.frequency,
          group: primaryIns?.groupName,
          hcpcs: primaryIns?.hcpcs,
          insuranceVerificationId: primaryIns?.insuranceVerificationId,
          isDmePartsCovered: primaryIns?.isDmePartsCovered,
          isDmeReplaceMentUnit: primaryIns?.isDmeReplaceMentUnit,
          isFamily: primaryIns?.isFamily,
          isFamilyDeductible: primaryIns?.isFamilyDeductible,
          isIndividual: primaryIns?.isIndividual,
          isMedicareCmn: primaryIns?.isMedicareCmn,
          isNetwork: primaryIns?.isNetwork,
          isOutOfNetwork: primaryIns?.isOutOfNetwork,
          isPatientHome: primaryIns?.isPatientHome,
          isPercertRequired: primaryIns?.isPercertRequired,
          isReferralRequired: primaryIns?.isReferralRequired,
          lifeTimeMax: primaryIns?.lifeTimeMax,
          lifeTimeMaxMet: primaryIns?.lifeTimeMaxMet,
          name: primaryIns?.name,
          oop: primaryIns?.oop,
          oopMet: primaryIns?.oopMet,
          oopTotal: primaryIns?.oopTotal,
          outOfPocketExpense: {
            individual: primaryIns?.outOfPocketExpense?.individual,
            outOfNetwork: primaryIns?.outOfPocketExpense?.outOfNetwork,
            family: primaryIns?.outOfPocketExpense?.family
          },
          outOfPocketExpenseMet: {
            individual: primaryIns?.outOfPocketExpenseMet?.individual,
            outOfNetwork: primaryIns?.outOfPocketExpenseMet?.outOfNetwork,
            family: primaryIns?.outOfPocketExpenseMet?.family
          },
          outOfPocketIncludeDeductible: {
            individual: primaryIns?.outOfPocketIncludeDeductible?.individual,
            outOfNetwork: primaryIns?.outOfPocketIncludeDeductible?.outOfNetwork,
            family: primaryIns?.outOfPocketIncludeDeductible?.family,
          },
          paRequired: primaryIns?.paRequired,
          payablePercent: {
            individual: primaryIns?.payablePercent?.individual,
            outOfNetwork: primaryIns?.payablePercent?.outOfNetwork,
            family: primaryIns?.payablePercent?.family
          },
          pcp: primaryIns?.pcp,
          phone: primaryIns?.phone,
          plan: primaryIns?.plan,
          planCob: primaryIns?.planCob,
          planDeductible: {
            individual: primaryIns?.planDeductible?.individual,
            outOfNetwork: primaryIns?.planDeductible?.outOfNetwork,
            family: primaryIns?.planDeductible?.family
          },
          planName: primaryIns?.planName,
          planPhone: primaryIns?.planPhone,
          policy: primaryIns?.policy,
          policyHolderDob: primaryIns?.policyHolderDob,
          policyHolderName: primaryIns?.policyHolderName,
          preNotes: primaryIns?.preNotes,
          preparedBy: primaryIns?.preparedBy,
          preparedOn: primaryIns?.preparedOn,
          primaryPayor: primaryIns?.primaryPayor,
          qtyLimits: primaryIns?.qtyLimits,
          rent: primaryIns?.rent,
          requestedBy: primaryIns?.requestedBy,
          sameOrSimilarInfo: primaryIns?.sameOrSimilarInfo,
          spokeWith: primaryIns?.spokeWith,
          typeOfPlan: primaryIns?.typeOfPlan,
          typeOfPolicy: primaryIns?.typeOfPolicy,
          verificationStatus: primaryIns?.verificationStatus,
          verifiedBy: primaryIns?.verifiedBy,
          deo: primaryIns?.deo,
          coInsurance: primaryIns?.coInsurance,

          commentsToTech: primaryIns?.commentsToTech,
          verifiedMethod: primaryIns?.verifiedMethod,
          isPredetermination: primaryIns?.isPredetermination,
          isActive: primaryIns?.isActive,
          // newly added
          isBlueCardEligible: primaryIns?.isBlueCardEligible,
          isDirectBill: primaryIns?.isDirectBill,
          isPreAuthNeeded: primaryIns?.isPreAuthNeeded,
          isClaimSubmit: primaryIns?.isClaimSubmit,
          patientResposponsiblePaymentPercentage: primaryIns?.patientResposponsiblePaymentPercentage,
          isReferenceNumberAvailable: primaryIns?.isReferenceNumberAvailable,
          referenceNumber: primaryIns?.referenceNumber,
        })
      }
      if (secondaryIns) {
        this.verifyTableData.push({
          verifiedTypeMethod: primaryIns?.verifiedTypeMethod,
          id: res?.id,
          patientName: res?.demographics[0]?.patientName,
          dateOfBirth: res?.demographics[0]?.dateOfBirth,
          address: secondaryIns?.address,
          callTrackingNumber: secondaryIns?.callTrackingNumber,
          chart: secondaryIns?.chart,
          claimAddress: secondaryIns?.claimAddress,
          cmnCode: secondaryIns?.cmnCode,
          codesRequirePa: secondaryIns?.codesRequirePa,
          commentsOrNotes: secondaryIns?.commentsOrNotes,
          contact: secondaryIns?.contact,
          coverageCriteria: secondaryIns?.coverageCriteria,
          dateVerified: secondaryIns?.dateVerified,
          dateofBirth: secondaryIns?.dateofBirth,
          dedMet: secondaryIns?.dedMet,
          dedTotal: secondaryIns?.dedTotal,
          deductibleMet: {
            individual: primaryIns?.deductibleMet?.individual,
            outOfNetwork: primaryIns?.deductibleMet?.outOfNetwork,
            family: primaryIns?.deductibleMet?.family,
          },
          dmeAnnualLimit: secondaryIns?.dmeAnnualLimit,
          dmeCoverage: secondaryIns?.dmeCoverage,
          dmeLimitMet: secondaryIns?.dmeLimitMet,
          dmeMax: secondaryIns?.dmeMax,
          effectiveDate: secondaryIns?.effectiveDate,
          end: secondaryIns?.end,
          fascalYearStart: secondaryIns?.fascalYearStart,
          frequency: secondaryIns?.frequency,
          group: secondaryIns?.groupName,
          hcpcs: secondaryIns?.hcpcs,
          insuranceVerificationId: secondaryIns?.insuranceVerificationId,
          isDmePartsCovered: secondaryIns?.isDmePartsCovered,
          isDmeReplaceMentUnit: secondaryIns?.isDmeReplaceMentUnit,
          isFamily: secondaryIns?.isFamily,
          isFamilyDeductible: secondaryIns?.isFamilyDeductible,
          isIndividual: secondaryIns?.isIndividual,
          isMedicareCmn: secondaryIns?.isMedicareCmn,
          isNetwork: secondaryIns?.isNetwork,
          isOutOfNetwork: secondaryIns?.isOutOfNetwork,
          isPatientHome: secondaryIns?.isPatientHome,
          isPercertRequired: secondaryIns?.isPercertRequired,
          isReferralRequired: secondaryIns?.isReferralRequired,
          lifeTimeMax: secondaryIns?.lifeTimeMax,
          lifeTimeMaxMet: secondaryIns?.lifeTimeMaxMet,
          name: secondaryIns?.name,
          oop: secondaryIns?.oop,
          oopMet: secondaryIns?.oopMet,
          oopTotal: secondaryIns?.oopTotal,
          outOfPocketExpense: {
            individual: secondaryIns?.outOfPocketExpense?.individual,
            outOfNetwork: secondaryIns?.outOfPocketExpense?.outOfNetwork,
            family: secondaryIns?.outOfPocketExpense?.family
          },
          outOfPocketExpenseMet: {
            individual: secondaryIns?.outOfPocketExpenseMet?.individual,
            outOfNetwork: secondaryIns?.outOfPocketExpenseMet?.outOfNetwork,
            family: secondaryIns?.outOfPocketExpenseMet?.family
          },
          outOfPocketIncludeDeductible: {
            individual: secondaryIns?.outOfPocketIncludeDeductible?.individual,
            outOfNetwork: secondaryIns?.outOfPocketIncludeDeductible?.outOfNetwork,
            family: secondaryIns?.outOfPocketIncludeDeductible?.family,
          },
          paRequired: secondaryIns?.paRequired,
          payablePercent: {
            individual: secondaryIns?.payablePercent?.individual,
            outOfNetwork: secondaryIns?.payablePercent?.outOfNetwork,
            family: secondaryIns?.payablePercent?.family
          },
          pcp: secondaryIns?.pcp,
          phone: secondaryIns?.phone,
          plan: secondaryIns?.plan,
          planCob: secondaryIns?.planCob,
          planDeductible: {
            individual: secondaryIns?.planDeductible?.individual,
            outOfNetwork: secondaryIns?.planDeductible?.outOfNetwork,
            family: secondaryIns?.planDeductible?.family
          },
          planName: secondaryIns?.planName,
          planPhone: secondaryIns?.planPhone,
          policy: secondaryIns?.policy,
          policyHolderDob: secondaryIns?.policyHolderDob,
          policyHolderName: secondaryIns?.policyHolderName,
          preNotes: secondaryIns?.preNotes,
          preparedBy: secondaryIns?.preparedBy,
          preparedOn: secondaryIns?.preparedOn,
          primaryPayor: secondaryIns?.primaryPayor,
          qtyLimits: secondaryIns?.qtyLimits,
          rent: secondaryIns?.rent,
          requestedBy: secondaryIns?.requestedBy,
          sameOrSimilarInfo: secondaryIns?.sameOrSimilarInfo,
          spokeWith: secondaryIns?.spokeWith,
          typeOfPlan: secondaryIns?.typeOfPlan,
          typeOfPolicy: secondaryIns?.typeOfPolicy,
          verificationStatus: secondaryIns?.verificationStatus,
          verifiedBy: secondaryIns?.verifiedBy,
          deo: secondaryIns?.deo,
          coInsurance: secondaryIns?.coInsurance,

          commentsToTech: secondaryIns?.commentsToTech,
          verifiedMethod: secondaryIns?.verifiedMethod,
          isPredetermination: secondaryIns?.isPredetermination,
          isActive: secondaryIns?.isActive,
          // newly added
          isBlueCardEligible: secondaryIns?.isBlueCardEligible,
          isDirectBill: secondaryIns?.isDirectBill,
          isPreAuthNeeded: secondaryIns?.isPreAuthNeeded,
          isClaimSubmit: secondaryIns?.isClaimSubmit,
          patientResposponsiblePaymentPercentage: secondaryIns?.patientResposponsiblePaymentPercentage,
          isReferenceNumberAvailable: secondaryIns?.isReferenceNumberAvailable,
          referenceNumber: secondaryIns?.referenceNumber,
        })
      }
      if (tertiaryIns) {
        this.verifyTableData.push({
          verifiedTypeMethod: primaryIns?.verifiedTypeMethod,
          id: res?.id,
          patientName: res?.demographics[0]?.patientName,
          dateOfBirth: res?.demographics[0]?.dateOfBirth,
          address: tertiaryIns?.address,
          callTrackingNumber: tertiaryIns?.callTrackingNumber,
          chart: tertiaryIns?.chart,
          claimAddress: tertiaryIns?.claimAddress,
          cmnCode: tertiaryIns?.cmnCode,
          codesRequirePa: tertiaryIns?.codesRequirePa,
          commentsOrNotes: tertiaryIns?.commentsOrNotes,
          contact: tertiaryIns?.contact,
          coverageCriteria: tertiaryIns?.coverageCriteria,
          dateVerified: tertiaryIns?.dateVerified,
          dateofBirth: tertiaryIns?.dateofBirth,
          dedMet: tertiaryIns?.dedMet,
          dedTotal: tertiaryIns?.dedTotal,
          deductibleMet: {
            individual: tertiaryIns?.deductibleMet?.individual,
            outOfNetwork: tertiaryIns?.deductibleMet?.outOfNetwork,
            family: tertiaryIns?.deductibleMet?.family,
          },
          dmeAnnualLimit: tertiaryIns?.dmeAnnualLimit,
          dmeCoverage: tertiaryIns?.dmeCoverage,
          dmeLimitMet: tertiaryIns?.dmeLimitMet,
          dmeMax: tertiaryIns?.dmeMax,
          effectiveDate: tertiaryIns?.effectiveDate,
          end: tertiaryIns?.end,
          fascalYearStart: tertiaryIns?.fascalYearStart,
          frequency: tertiaryIns?.frequency,
          group: tertiaryIns?.groupName,
          hcpcs: tertiaryIns?.hcpcs,
          insuranceVerificationId: tertiaryIns?.insuranceVerificationId,
          isDmePartsCovered: tertiaryIns?.isDmePartsCovered,
          isDmeReplaceMentUnit: tertiaryIns?.isDmeReplaceMentUnit,
          isFamily: tertiaryIns?.isFamily,
          isFamilyDeductible: tertiaryIns?.isFamilyDeductible,
          isIndividual: tertiaryIns?.isIndividual,
          isMedicareCmn: tertiaryIns?.isMedicareCmn,
          isNetwork: tertiaryIns?.isNetwork,
          isOutOfNetwork: tertiaryIns?.isOutOfNetwork,
          isPatientHome: tertiaryIns?.isPatientHome,
          isPercertRequired: tertiaryIns?.isPercertRequired,
          isReferralRequired: tertiaryIns?.isReferralRequired,
          lifeTimeMax: tertiaryIns?.lifeTimeMax,
          lifeTimeMaxMet: tertiaryIns?.lifeTimeMaxMet,
          name: tertiaryIns?.name,
          oop: tertiaryIns?.oop,
          oopMet: tertiaryIns?.oopMet,
          oopTotal: tertiaryIns?.oopTotal,
          outOfPocketExpense: {
            individual: tertiaryIns?.outOfPocketExpense?.individual,
            outOfNetwork: tertiaryIns?.outOfPocketExpense?.outOfNetwork,
            family: tertiaryIns?.outOfPocketExpense?.family
          },
          outOfPocketExpenseMet: {
            individual: tertiaryIns?.outOfPocketExpenseMet?.individual,
            outOfNetwork: tertiaryIns?.outOfPocketExpenseMet?.outOfNetwork,
            family: tertiaryIns?.outOfPocketExpenseMet?.family
          },
          outOfPocketIncludeDeductible: {
            individual: tertiaryIns?.outOfPocketIncludeDeductible?.individual,
            outOfNetwork: tertiaryIns?.outOfPocketIncludeDeductible?.outOfNetwork,
            family: tertiaryIns?.outOfPocketIncludeDeductible?.family,
          },
          paRequired: tertiaryIns?.paRequired,
          payablePercent: {
            individual: tertiaryIns?.payablePercent?.individual,
            outOfNetwork: tertiaryIns?.payablePercent?.outOfNetwork,
            family: tertiaryIns?.payablePercent?.family
          },
          pcp: tertiaryIns?.pcp,
          phone: tertiaryIns?.phone,
          plan: tertiaryIns?.plan,
          planCob: tertiaryIns?.planCob,
          planDeductible: {
            individual: tertiaryIns?.planDeductible?.individual,
            outOfNetwork: tertiaryIns?.planDeductible?.outOfNetwork,
            family: tertiaryIns?.planDeductible?.family
          },
          planName: tertiaryIns?.planName,
          planPhone: tertiaryIns?.planPhone,
          policy: tertiaryIns?.policy,
          policyHolderDob: tertiaryIns?.policyHolderDob,
          policyHolderName: tertiaryIns?.policyHolderName,
          preNotes: tertiaryIns?.preNotes,
          preparedBy: tertiaryIns?.preparedBy,
          preparedOn: tertiaryIns?.preparedOn,
          primaryPayor: tertiaryIns?.primaryPayor,
          qtyLimits: tertiaryIns?.qtyLimits,
          rent: tertiaryIns?.rent,
          requestedBy: tertiaryIns?.requestedBy,
          sameOrSimilarInfo: tertiaryIns?.sameOrSimilarInfo,
          spokeWith: tertiaryIns?.spokeWith,
          typeOfPlan: tertiaryIns?.typeOfPlan,
          typeOfPolicy: tertiaryIns?.typeOfPolicy,
          verificationStatus: tertiaryIns?.verificationStatus,
          verifiedBy: tertiaryIns?.verifiedBy,
          deo: tertiaryIns?.deo,
          coInsurance: tertiaryIns?.coInsurance,

          commentsToTech: tertiaryIns?.commentsToTech,
          verifiedMethod: tertiaryIns?.verifiedMethod,
          isPredetermination: tertiaryIns?.isPredetermination,
          isActive: tertiaryIns?.isActive,
          // newly added
          isBlueCardEligible: tertiaryIns?.isBlueCardEligible,
          isDirectBill: tertiaryIns?.isDirectBill,
          isPreAuthNeeded: tertiaryIns?.isPreAuthNeeded,
          isClaimSubmit: tertiaryIns?.isClaimSubmit,
          patientResposponsiblePaymentPercentage: tertiaryIns?.patientResposponsiblePaymentPercentage,
          isReferenceNumberAvailable: tertiaryIns?.isReferenceNumberAvailable,
          referenceNumber: tertiaryIns?.referenceNumber,

        })
      }
      this.isLoading = false;
      const arrTableData = this.verifyTableData;
      this.dataSource = new MatTableDataSource(arrTableData);
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
      // return verifyDetails;
    })
  }


  //// verification page view
  verificationView(ele) {
    const dialogRef = this.dialog.open(VerifyPatientAmountsComponent, {
      disableClose: true, data: { patientsId: ele?.id, response: ele }
    });
    dialogRef.afterClosed().subscribe(() => {

    }, err => {
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    });

  }


}
