import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-text-conversation-patient-details',
  templateUrl: './text-conversation-patient-details.component.html',
  styleUrls: ['./text-conversation-patient-details.component.scss']
})
export class TextConversationPatientDetailsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
