import { PagedAndSortedResultRequestDto } from '@abp/ng.core';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { MatSort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { ItemExportDatas, ItemExportDTO } from 'projects/patient/src/app/patient-proxy/patient/dto/models';
import { ExportType } from 'projects/patient/src/app/patient-proxy/patient/enumeration-data/export-type.enum';
import { ReportService } from 'projects/patient/src/app/patient-proxy/patient/report.service';
import { Subscription } from 'rxjs';
import Swal from 'sweetalert2';
import { AddAllowanceComponent } from '../add-allowance/add-allowance.component';

import { AddProductModalComponent } from '../add-product-modal/add-product-modal.component';
import { CreateUpdateMainProductDetailsDTO, SearchMainProductDTO, SearchResupplyProductDTO } from '../item-proxy/item-management/items/dto/models';
import { ProductService } from '../item-proxy/item-management/items/product.service';
import { Status } from '../item-proxy/item-management/items/status.enum';
import { ToastrService } from "ngx-toastr";

@Component({
  selector: 'app-inventory-product',
  templateUrl: './inventory-product.component.html',
  styleUrls: ['./inventory-product.component.scss']
})
export class InventoryProductComponent implements OnInit , OnDestroy {
  isLoading: boolean = false;
  mainProductForm: FormGroup;
  supplyProductForm: FormGroup;
  mainTableData: any[] = [];
  supplyTableData: any[] = [];
  optionStatus: string = "Add Product Allowables";
  MainProduct: MatTableDataSource<any> = new MatTableDataSource<any>([]);
  @ViewChild(MatSort, { static: false }) Mainsort: MatSort;
  @ViewChild(MatPaginator, { static: false }) MainPaginator: MatPaginator;
  @ViewChild(MatTable, { static: false }) MaintableRef: MatTable<any>;
  displayedColumns: string[] = [
    'Options',
    'Name',
    'minQty',
    'avaiQty',
    'Price',
    'productStatus',
    'status',
   
  ];
  dataSource: MatTableDataSource<any> = new MatTableDataSource<any>([]);
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild(MatPaginator, { static: false }) Paginator: MatPaginator;
  @ViewChild(MatTable, { static: false }) tableRef: MatTable<any>;
  arrDisplayedColumns: string[] = [
    'Options',
    'Name',
    'minQty',
    'avaiQty',
    'Price',
    'productStatus',
    'status',
   
  ];
  constructor(public dialog: MatDialog,
    private productService: ProductService,
    private formBuilder: FormBuilder,
    private title: Title,
    public router: Router,
    private reportService: ReportService,
    private toastr: ToastrService,
  ) { }
  isProgress: boolean;
  subscription$: Subscription[] = [];
  ngOnInit(): void {
    this.title.setTitle("Qsecure | Products");
    this.mainProductForm = this.formBuilder.group({
      Name: new FormControl(''),
      productCode: new FormControl(''),
      description: new FormControl(''),
      hcpcCode: new FormControl(''),
      createdDate: new FormControl(''),
      status: new FormControl(''),
      productStatus: new FormControl(''),
      minQty: new FormControl(''),
      avaiQty: new FormControl(''),
    });

    this.supplyProductForm = this.formBuilder.group({
      Name: new FormControl(''),
      productCode: new FormControl(''),
      description: new FormControl(''),
      hcpcCode: new FormControl(''),
      createdDate: new FormControl(''),
      status: new FormControl(''),
      productStatus: new FormControl(''),
      minQty: new FormControl(''),
      avaiQty: new FormControl(''),
    });

    // main valueChanges for filter
    const mainProductValueChanges = this.mainProductForm.valueChanges.subscribe((value) => {
      let filter = {
        Name: value.Name.trim().toLowerCase(),
        productCode: value.productCode.trim().toLowerCase(),
        description: value.description.trim().toLowerCase(),
        hcpcCode: value.hcpcCode.trim().toLowerCase(),
        status: value.status.trim().toLowerCase(),
        productStatus: value.productStatus.trim().toLowerCase(),
        minQty: value.minQty.trim().toLowerCase(),
        avaiQty: value.avaiQty.trim().toLowerCase(),
      };

      if (this.mainProductForm.valid) {
        this.MainProduct.filter = JSON.stringify(filter);
        this.filterMaintable(filter);
      }
    });
    this.subscription$.push(mainProductValueChanges);
    //
    // valueChanges for filter
    const SupplyProductValueChanges = this.supplyProductForm.valueChanges.subscribe((value) => {
      let filter = {
        Name: value.Name.trim().toLowerCase(),
        productCode: value.productCode.trim().toLowerCase(),
        description: value.description.trim().toLowerCase(),
        hcpcCode: value.hcpcCode.trim().toLowerCase(),
        status: value.status.trim().toLowerCase(),
        productStatus: value.productStatus.trim().toLowerCase(),
        minQty: value.minQty.trim().toLowerCase(),
        avaiQty: value.avaiQty.trim().toLowerCase(),


      };

      if (this.supplyProductForm.valid) {
        this.dataSource.filter = JSON.stringify(filter);
        this.filterSupplytable(filter);
      }
    });
    this.subscription$.push(SupplyProductValueChanges);
    //
    this.getMainTableData();
    this.getSupplyTableData();
  }

  ngOnDestroy(): void {
    this.subscription$?.forEach((sub) => {
      sub && sub?.unsubscribe();
    });
  }

  //Product Category Status change
  statusMainChange(event: MatSlideToggleChange, data: any) {
    if (event.checked == true) {
      Swal.fire({
        title: 'Do you want to activate?',
        text: 'By Changing Status, this Product will be activated',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#34c38f',
        cancelButtonColor: '#f46a6a',
        confirmButtonText: 'Yes, Activate it!'
      }).then(result => {
        if (result.value) {
          let CreateUpdateProduct: CreateUpdateMainProductDetailsDTO = {
            status: Status.Active,
            hcpcCode: data.hcpcCode,
            mainProductName: data.name,
            productCode: data.productCode,
            productDescription: data.productDescription,
            availableQuantity: data?.availableQuantity,
            minimumQuantity: data?.minimumQuantity,
            stockStatus: data?.stockStatus
          }
          if (data?.id && data?.id !== "" && data?.id !== null && data?.id !== undefined) {
            this.productService.updateMainProductByIdAndInput(data.id, CreateUpdateProduct).subscribe(response => {
              // Swal.fire({ title: 'Success', html: 'Activated Successfully', icon: 'success', timer: 3000, timerProgressBar: true })
              this.toastr.success('Activated Successfully','Success')
              this.getMainTableData();
            }, (err) => {
              const data: HttpErrorResponse = err;
              Swal.fire({
                icon: 'info',
                text: data?.error?.error?.message,
              });
            })
          }
        }
        else {
          this.getMainTableData();
        }
      });
    }
    else
      if (event.checked == false) {
        Swal.fire({
          title: 'Do you want to deactivate?',
          text: 'By Changing Status, this Product will be deactivated',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#34c38f',
          cancelButtonColor: '#f46a6a',
          confirmButtonText: 'Yes, deactivate it!'
        }).then(result => {
          if (result.value) {
            let CreateUpdateProduct: CreateUpdateMainProductDetailsDTO = {
              status: Status.Inactive,
              hcpcCode: data.hcpcCode,
              mainProductName: data.name,
              productCode: data.productCode,
              productDescription: data.productDescription,
              availableQuantity: data?.availableQuantity,
              minimumQuantity: data?.minimumQuantity,
              stockStatus: data?.stockStatus
            }
            if (data?.id && data?.id !== "" && data?.id !== null && data?.id !== undefined) {
              this.productService.updateMainProductByIdAndInput(data.id, CreateUpdateProduct).subscribe(response => {
                // Swal.fire({ title: 'Success', html: 'Deactivated Successfully', icon: 'success', timer: 3000, timerProgressBar: true })
                this.toastr.success('Deactivated Successfully','Success')
                this.getMainTableData();
              }, (err) => {
                const data: HttpErrorResponse = err;
                Swal.fire({
                  icon: 'info',
                  text: data?.error?.error?.message,
                });
              })
            }
          }
          else {
            this.getMainTableData();
          }
        });
      }
  }

  //Product Category Status change
  statusChanged(event: MatSlideToggleChange, data: any) {
    if (event.checked == true) {
      Swal.fire({
        title: 'Do you want to activate?',
        text: 'By Changing Status, this Product  will be activated',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#34c38f',
        cancelButtonColor: '#f46a6a',
        confirmButtonText: 'Yes, Activate it!'
      }).then(result => {
        if (result.value) {
          let productCategoryDetails = {


            mainProductId: data?.mainProductId,
            resupplyProductId: data?.supplyId,
            hcpcCode: data?.hcpcCode,
            resupplyProductName: data?.resupplyProductName,
            productCode: data?.productCode,
            productDescription: data?.productDescription,
            createdDate: data?.createdDate,
            status: Status.Active,
            availableQuantity: data?.availableQuantity,
            minimumQuantity: data?.minimumQuantity,
            stockStatus: data?.stockStatus
          }
          if (data?.id && data?.id !== "" && data?.id !== null && data?.id !== undefined) {
            this.productService.updateResupplyProductByInput(productCategoryDetails).subscribe(response => {
              // Swal.fire({ title: 'Success', html: 'Activated Successfully', icon: 'success', timer: 3000, timerProgressBar: true })
              this.toastr.success('Activated Successfully','Success')
              this.getSupplyTableData();
            }, (err) => {
              const data: HttpErrorResponse = err;
              Swal.fire({
                icon: 'info',
                text: data?.error?.error?.message,
              });
            })
          }
        }
        else {
          this.getSupplyTableData();
        }
      });
    }
    else
      if (event.checked == false) {
        Swal.fire({
          title: 'Do you want to deactivate?',
          text: 'By Changing Status, this Product will be deactivated',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#34c38f',
          cancelButtonColor: '#f46a6a',
          confirmButtonText: 'Yes, deactivate it!'
        }).then(result => {
          if (result.value) {
            let productCategoryDetails = {
              mainProductId: data?.mainProductId,
              resupplyProductId: data?.supplyId,
              hcpcCode: data?.hcpcCode,
              resupplyProductName: data?.resupplyProductName,
              productCode: data?.productCode,
              productDescription: data?.productDescription,
              createdDate: data?.createdDate,
              status: Status.Inactive,
              availableQuantity: data?.availableQuantity,
              minimumQuantity: data?.minimumQuantity,
              stockStatus: data?.stockStatus
            }
            if (data?.id && data?.id !== "" && data?.id !== null && data?.id !== undefined) {
              this.productService.updateResupplyProductByInput(productCategoryDetails).subscribe(response => {
                // Swal.fire({ title: 'Success', html: 'Deactivated Successfully', icon: 'success', timer: 3000, timerProgressBar: true })
                this.toastr.success('Deactivated Successfully','Success')
                this.getSupplyTableData();
              }, (err) => {
                const data: HttpErrorResponse = err;
                Swal.fire({
                  icon: 'info',
                  text: data?.error?.error?.message,
                });
              })
            }
          }
          else {
            this.getSupplyTableData();
          }
        });
      }
  }


  //! main product table data
  getMainTableData() {
    this.isLoading = true;
    const ProductGetList = this.productService.getList(new PagedAndSortedResultRequestDto).subscribe(response => {
      this.mainTableData = [];
      response?.items?.forEach(element => {
        this.mainTableData.push({
          name: element?.mainProductDetails?.mainProductName,
          productCode: element?.mainProductDetails?.productCode,
          productDescription: element?.mainProductDetails?.productDescription,
          hcpcCode: element?.mainProductDetails?.hcpcCode,
          id: element?.id,
          createdDate: element?.creationTime,
          mainProductStatus: element?.mainProductDetails?.status,
          status: element?.mainProductDetails?.status,
          minimumQuantity: element?.mainProductDetails?.minimumQuantity,
          availableQuantity: element?.mainProductDetails?.availableQuantity,
          stockStatus: element?.mainProductDetails?.stockStatus,
          allowablesId: element?.allowablesId
        })
        this.isLoading = false;
      })
      this.isLoading = false;
      this.MainProduct = new MatTableDataSource(this.mainTableData);
      this.MainProduct.sort = this.Mainsort;
      this.MainProduct.paginator = this.MainPaginator;
    })
    this.subscription$.push(ProductGetList);
    //
    this.isLoading = false;
  }

  //! supply product table data
  getSupplyTableData() {
    this.isProgress = true;
    const productGetList = this.productService.getList(new PagedAndSortedResultRequestDto).subscribe(response => {
      this.supplyTableData = [];
      response?.items?.forEach(element => {
        element?.resupplyProductDetails.forEach(data => {
          this.supplyTableData.push({
            name: data?.resupplyProductName,
            productCode: data?.productCode,
            productDescription: data?.productDescription,
            hcpcCode: data?.hcpcCode,
            id: element?.id,
            supplyId: data?.resupplyProductId,
            createdDate: element?.creationTime,
            resupplyProductStatus: data?.status,
            mainProductId: data?.mainProductId,
            resupplyProductName: data?.resupplyProductName,
            status: data?.status,
            minimumQuantity: data?.minimumQuantity,
            availableQuantity: data?.availableQuantity,
            stockStatus: data?.stockStatus,
            allowablesId: data?.allowablesId
          })
        })
      })
      this.isProgress = false;
      this.dataSource = new MatTableDataSource(this.supplyTableData);
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.Paginator;
      this.isProgress = false;
    })
    this.subscription$.push(productGetList);
  }



  //! filter main table
  filterMaintable(filter) {

    let filterInput: SearchMainProductDTO = {
      minimumQty: filter.minQty,
      availableQty: filter.avaiQty,
      stockStatus: filter.productStatus,
      status: filter.status,
      hcpcCode: filter.hcpcCode,
      mainProductName: filter.Name,
      createdDate: filter.createdDate
    }

    this.mainTableData = [];
    const mainProductSearch = this.productService.searchMainProductsByInput(filterInput).subscribe(data => {

      this.mainTableData = [];
      data?.items.forEach(element => {
        this.mainTableData.push({
          name: element?.mainProductDetails?.mainProductName,
          productCode: element?.mainProductDetails?.productCode,
          productDescription: element?.mainProductDetails?.productDescription,
          hcpcCode: element?.mainProductDetails?.hcpcCode,
          id: element?.id,
          createdDate: element?.creationTime,
          mainProductStatus: element?.mainProductDetails?.status,
          status: element?.mainProductDetails?.status,
          minimumQuantity: element?.mainProductDetails?.minimumQuantity,
          availableQuantity: element?.mainProductDetails?.availableQuantity,
          stockStatus: element?.mainProductDetails?.stockStatus
        });
      })
      this.MainProduct = new MatTableDataSource(this.mainTableData);
      this.MainProduct.sort = this.Mainsort;
      this.MainProduct.paginator = this.MainPaginator;
      this.isLoading = false;
    })
    this.subscription$.push(mainProductSearch);
  }

  //! filter supply table
  filterSupplytable(filter) {
    let filterInput: SearchResupplyProductDTO = {

      resupplyProductName: filter.Name,
      minimumQty: filter.minQty,
      availableQty: filter.avaiQty,
      stockStatus: filter.productStatus,
      status: filter.status,
      hcpcCode: filter.hcpcCode,
      createdDate: filter.createdDate
    }

    this.supplyTableData = [];
    const resupplyProductSearch = this.productService.searchResupplyProductsByInput(filterInput).subscribe(datas => {

      this.supplyTableData = [];
      datas?.items?.forEach(data => {
        this.supplyTableData.push({
          name: data?.resupplyProductName,
          productCode: data?.productCode,
          hcpcCode: data?.hcpcCode,
          productDescription: data?.productDescription,
          id: data?.mainProductId,
          supplyId: data?.resupplyProductId,
          createdDate: data?.createdDate,
          resupplyProductStatus: data?.status,
          status: data?.status,
          minimumQuantity: data?.minimumQuantity,
          availableQuantity: data?.availableQuantity,
          stockStatus: data?.stockStatus
        })
      })
      this.dataSource = new MatTableDataSource(this.supplyTableData);
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.Paginator;
      this.isLoading = false;
    })
    this.subscription$.push(resupplyProductSearch);
  }

  //! open popup modal
  openProductModal() {
    const dialogRef = this.dialog.open(AddProductModalComponent, {
      disableClose: true,
      data: {}
    });

    const closeModal =  dialogRef.afterClosed().subscribe(() => {

      this.getSupplyTableData()
      this.getMainTableData()
    });
    this.subscription$.push(closeModal);
  }

  //! view popup modal
  ViewProduct(data) {
    const dialogRef = this.dialog.open(AddProductModalComponent, {
      disableClose: true,
      data: { productId: data.id }
    });

    const closeModal =  dialogRef.afterClosed().subscribe(() => {
      this.getMainTableData()
    });
    this.subscription$.push(closeModal);
  }

  //!ViewSupplyProduct
  ViewSupplyProduct(data) {
    const dialogRef = this.dialog.open(AddProductModalComponent, {
      disableClose: true,
      data: { productId: data.id, supplyId: data.supplyId }
    });

    const closeModal =  dialogRef.afterClosed().subscribe(() => {
      this.getSupplyTableData()
    });
    this.subscription$.push(closeModal);
  }
  //! delete from supply product table
  DeleteSupplyProduct(data) {
    Swal.fire({
      title: 'Are you sure you want to Delete?',
      text: 'You won\'t be able to retrieve this data!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#34c38f',
      cancelButtonColor: '#f46a6a',
      confirmButtonText: 'Yes, delete it!'
    }).then(result => {
      if (result.value) {
        const deleteResupplyProduct = data && this.productService.deleteResupplyProductByMainProductIdAndResupplyProductId(data.id, data.supplyId).subscribe(resposne => {
          this.getSupplyTableData();
        }, err => {
          const data: HttpErrorResponse = err;
          Swal.fire({
            icon: 'info',
            text: data?.error?.error?.message,
          });
        });
        this.subscription$.push(deleteResupplyProduct);
      }
    });
  }
  //! delete from main product table
  DeleteProduct(data) {
    Swal.fire({
      title: 'Are you sure you want to Delete?',
      text: 'You won\'t be able to retrieve this data!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#34c38f',
      cancelButtonColor: '#f46a6a',
      confirmButtonText: 'Yes, delete it!'
    }).then(result => {
      if (result.value) {
        const deleteMainTableProduct = data && this.productService.delete(data.id).subscribe(resposne => {
          this.getMainTableData();
        }, err => {
          const data: HttpErrorResponse = err;
          Swal.fire({
            icon: 'info',
            text: data?.error?.error?.message,
          });
        });
        this.subscription$.push(deleteMainTableProduct);
      }
    });
  }

  ///// open product Allowables inventory/product-view
  viewAddProductModal(data) {
    const dialogRef = this.dialog.open(AddAllowanceComponent, {
      height: "65%", width: "70%",
      disableClose: true,

      data: { productId: data.id, productName: data.name, prodHpscCode: data.hcpcCode, allowablesId: data.allowablesId }
    });
    const closeModal =  dialogRef.afterClosed().subscribe(() => {
      this.getMainTableData()
    });
    this.subscription$.push(closeModal);
  }

  //! view popup modal
  ViewAddSupplyModalProduct(data) {
    const dialogRef = this.dialog.open(AddAllowanceComponent, {
      height: "65%", width: "70%",
      disableClose: true,
      data: { productId: data.supplyId, productName: data.name, prodHpscCode: data.hcpcCode, allowablesId: data.allowablesId, resupplyProductName: data.resupplyProductName }
    });
    const closeModal =  dialogRef.afterClosed().subscribe(() => {
      this.getSupplyTableData()
    });
    this.subscription$.push(closeModal);
  }

  //export excel Inventory-Product
  mainExportTableData() {
    let datas: ItemExportDatas[] = []
    this.MainProduct?.data?.forEach(element => {
      datas.push({
        productName: element?.name,
        minimumQty: String(element?.minimumQuantity),
        availableQty: String(element?.availableQuantity),
        hcpcCode: element?.hcpcCode,
        stockStatus: element?.stockStatus,
        productStatus: element?.status,
      });
    })
    let itemExport: ItemExportDTO = {
      columns: [" Main Product Name", "Minimum Qunatity", "Available Qunatity", "HCPC Code", "Stock Stauts", "Product Status"],
      datas: datas
    };
    const exportReport = this.reportService.exportItemReportsByExportTypeAndExportDatas(ExportType.MainProductReports, itemExport).subscribe(response => {
      if (response && response?.length !== 0) {
        let filelocation: String = String("data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64," + response);
        let link = document.createElement("a");
        link.download = ("Inventory-Product") + ".xlsx";
        link.href = filelocation.toString();
        link.click();
        // Swal.fire({ title: 'Success', html: 'Exported Successfully', icon: 'success', timer: 3000, timerProgressBar: true })
        this.toastr.success('Exported Successfully','Success')
      }
    }, err => {
      const data: HttpErrorResponse = err;
      let errorMesg: string = data?.error;
      if (errorMesg?.includes('Sorry!! There is no data in table to export')) {
        errorMesg = 'Sorry!! There is no data in table to export';
      }
      else {
        errorMesg = data?.error?.error?.message;
      }
      Swal.fire({
        icon: 'info',
        text: errorMesg
      });
    });
    this.subscription$.push(exportReport);
  }


  // resupply product export Resupply-Product
  resupplyExportTableData() {
    let datas: ItemExportDatas[] = []
    this.dataSource?.data?.forEach(element => {
      datas.push({
        productName: element?.name,
        minimumQty: String(element?.minimumQuantity),
        availableQty: String(element?.availableQuantity),
        hcpcCode: element?.hcpcCode,
        stockStatus: element?.stockStatus,
        productStatus: element?.status,
      });
    })
    let itemExport: ItemExportDTO = {
      columns: ["Resupply Product Name", "Minimum Qunatity", "Available Qunatity", "HCPC Code", "Stock Stauts", "Product Status"],
      datas: datas
    };
  const ResupplyExport =  this.reportService.exportItemReportsByExportTypeAndExportDatas(ExportType.ResupplyProductReports, itemExport).subscribe(response => {
      if (response && response?.length !== 0) {
        let filelocation: String = String("data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64," + response);
        let link = document.createElement("a");
        link.download = ("Resupply-Product") + ".xlsx";
        link.href = filelocation.toString();
        link.click();
        // Swal.fire({ title: 'Success', html: 'Exported Successfully', icon: 'success', timer: 3000, timerProgressBar: true })
        this.toastr.success('Exported Successfully','Success')
      }
    }, err => {
      const data: HttpErrorResponse = err;
      let errorMesg: string = data?.error;
      if (errorMesg?.includes('Sorry!! There is no data in table to export')) {
        errorMesg = 'Sorry!! There is no data in table to export';
      }
      else {
        errorMesg = data?.error?.error?.message;
      }
      // Swal.fire({
      //   icon: 'info',
      //   text: errorMesg
      // });
      this.toastr.info(errorMesg) 
    });
    this.subscription$.push(ResupplyExport);
  }
}
