<div class="container-fluid" style="padding-top: 1%">
  <div class="d-flex justify-content-center" *ngIf="isLoading">
    <!-- <mat-spinner></mat-spinner> -->
    <img
      class="img-responsive pb-2 mr-1"
      src="assets/gifs/Infinity-loader.gif"
    />
  </div>
  <div class="table table-responsive" *ngIf="!isLoading">
    <table
      id="tblPayor"
      datatable
      [dtOptions]="dtOptions"
      class="tbl hover row-border hover w-100 display"
    >
      <thead>
        <tr>
          <th>
            <mat-checkbox
              (click)="onUserSelectionChange($event)"
              (change)="$event ? masterToggle() : null"
              [checked]="selection.hasValue()"
              [indeterminate]="selection.hasValue() && !isAllSelected()"
              style="margin-right: 3px"
            >
            </mat-checkbox>
            <!-- <mat-icon>apps</mat-icon> -->
          </th>
          <th [matTooltip]="'Status'">Status</th>
          <th [matTooltip]="'Eligibility Mode'">Eligibility Mode</th>
          <th [matTooltip]="'Code'">Code</th>
          <th [matTooltip]="'Payor Code'">Payor Code</th>
          <th [matTooltip]="'Payor Name'">Payor Name</th>
          <th [matTooltip]="'Insurance Category'">Insurance Category</th>
          <th [matTooltip]="'Is Medicare'">Is Medicare</th>
          <th [matTooltip]="'Address'">Address</th>
          <th [matTooltip]="'State'">State</th>
          <th [matTooltip]="'City'">City</th>
          <th class="text-right" [matTooltip]="'Zip Code'">Zip Code</th>
          <th [matTooltip]="'Contact Name'">Contact Name</th>
          <th class="text-right" [matTooltip]="'Phone Number'">Phone Number</th>
          <th [matTooltip]="'WrtOff Code'">WrtOff Code</th>
          <th [matTooltip]="'Pmt Code'">Pmt Code</th>
          <th [matTooltip]="'Insurance Type Code'">Insurance Type Code</th>
          <th [matTooltip]="'Claim Filing Indicator'">
            Claim Filing Indicator
          </th>
          <th [matTooltip]="'Require CheckList'">Require CheckList</th>
          <th [matTooltip]="'Created By'">Created By</th>
          <th class="text-right" [matTooltip]="'Created Date'">Created Date</th>
          <th [matTooltip]="'Last Modified By'">Last Modified By</th>
          <th class="text-right" [matTooltip]="'Last Modified Date'">
            Last Modified Date
          </th>
          <!-- <th [matTooltip]="'Modify By'">Modify By</th>
          <th class="text-right" [matTooltip]="'Modified Date'">
            Modified Date
          </th> -->
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let data of payerTableData">
          <td>
            <mat-checkbox
              (change)="addRemovePayorId($event, data.id)"
              class="mr-2"
              [disabled]="data?.isActive === false"
              (click)="$event.stopPropagation()"
              [checked]="selection.isSelected(data)"
            >
            </mat-checkbox>
            <a
              class="eyeCursorclass"
              [class.disabled]="data?.isActive != true"
              target="_blank"
              (click)="editPayor(data)"
              [ngClass]="{ 'disabled-link': data?.isActive === false }"
              ><i class="fa fa-pencil editBtnColor"></i>
            </a>
          </td>
          <td>
            <ng-container *ngIf="data?.isActive === true">
              <mat-slide-toggle
                (change)="statusChange($event, data.id)"
                [checked]="true"
                class="ml-2"
              >
                {{ data?.isActive ? "Active" : "Inactive" }}
              </mat-slide-toggle>
            </ng-container>
            <ng-container *ngIf="data?.isActive === false">
              <mat-slide-toggle
                (change)="statusChange($event, data.id)"
                [checked]="false"
                class="ml-2"
                >{{ data?.isActive ? "Active" : "Inactive" }}
              </mat-slide-toggle>
            </ng-container>
          </td>
          <td
            (dblclick)="
              onRowDoubleClick(eligibiltyStatus(data?.eligibilityMode) || '-')
            "
            [matTooltip]="
              eligibiltyStatus(data?.eligibilityMode) === null
                ? '-'
                : eligibiltyStatus(data?.eligibilityMode)
            "
          >
            {{
              eligibiltyStatus(data?.eligibilityMode) === null
                ? "-"
                : eligibiltyStatus(data?.eligibilityMode)
            }}
          </td>
          <td
            (dblclick)="onRowDoubleClick(data?.code || '-')"
            [matTooltip]="
              data?.code == null ||
              data?.code == '' ||
              (data?.code).toLowerCase() == 'null'
                ? '-'
                : data?.code
            "
          >
            {{
              data?.code == null ||
              data?.code == "" ||
              (data?.code).toLowerCase() == "null"
                ? "-"
                : data?.code
            }}
          </td>
          <td
            (dblclick)="onRowDoubleClick(data?.payerCode || '-')"
            [matTooltip]="
              data?.payerCode == null ||
              data?.payerCode == '' ||
              (data?.payerCode).toLowerCase() == 'null'
                ? '-'
                : data?.payerCode
            "
          >
            {{
              data?.payerCode == null ||
              data?.payerCode == "" ||
              (data?.payerCode).toLowerCase() == "null"
                ? "-"
                : data?.payerCode
            }}
          </td>
          <td
            (dblclick)="onRowDoubleClick(data?.payerName || '-')"
            [matTooltip]="data?.payerName == null ? '-' : data?.payerName"
          >
            {{ data?.payerName == null ? "-" : data?.payerName }}
          </td>
          <td
            (dblclick)="onRowDoubleClick(data?.insuranceName || '-')"
            [matTooltip]="
              data?.insuranceName == null ? '-' : data?.insuranceName
            "
          >
            {{ data?.insuranceName == null ? "-" : data?.insuranceName }}
          </td>
          <!-- <td
            [matTooltip]="
              (data?.isMedicarePolicy == true ? 'Yes' : 'No') || '-'
            "
            (dblclick)="
              onRowDoubleClick(
                (data?.isMedicarePolicy == true ? 'Yes' : 'No') || '-'
              )
            "
          >
            {{
              data?.isMedicarePolicy === null
                ? "-"
                : data?.isMedicarePolicy == true
                ? "Yes"
                : "No"
            }}
          </td> -->
          <td>
            <ng-container *ngIf="data?.isMedicarePolicy === true">
              <mat-slide-toggle [checked]="true" class="ml-2" (change)="updateisMedicare($event, data.id)">
                {{ data?.isMedicarePolicy ? "Yes" : "No" }}
              </mat-slide-toggle>
            </ng-container>
            <ng-container *ngIf="data?.isMedicarePolicy === false || data?.isMedicarePolicy==null">
              <mat-slide-toggle
              (change)="updateisMedicare($event, data.id)"
                [checked]="false"
                class="ml-2"
                >{{ data?.isMedicarePolicy ? "Yes" : "No" }}
              </mat-slide-toggle>
            </ng-container>
          </td>

          <td
            (dblclick)="onRowDoubleClick(data?.address || '-')"
            [matTooltip]="data?.address == null ? '-' : data?.address"
          >
            {{ data?.address == null ? "-" : data?.address }}
          </td>
          <td
            (dblclick)="onRowDoubleClick(data?.state || '-')"
            [matTooltip]="data?.state == null ? '-' : data?.state"
          >
            {{ data?.state == null ? "-" : data?.state }}
          </td>
          <td
            (dblclick)="onRowDoubleClick(data?.city || '-')"
            [matTooltip]="data?.city == null ? '-' : data?.city"
          >
            {{ data?.city == null ? "-" : data?.city }}
          </td>
          <td
            class="text-right"
            (dblclick)="onRowDoubleClick(data?.zip || '-')"
            [matTooltip]="data?.zip == null ? '-' : data?.zip"
          >
            {{ data?.zip == null ? "-" : data?.zip }}
          </td>
          <td
            (dblclick)="onRowDoubleClick(data?.contactName || '-')"
            [matTooltip]="data?.contactName == null ? '-' : data?.contactName"
          >
            {{ data?.contactName == null ? "-" : data?.contactName }}
          </td>
          <td
            class="text-right"
            (dblclick)="onRowDoubleClick(data?.phoneNo || '-')"
            [matTooltip]="
              data?.phoneNo == null || data?.phoneNo == '' ? '-' : data?.phoneNo
            "
          >
            {{
              data?.phoneNo == null || data?.phoneNo == "" ? "-" : data?.phoneNo
            }}
          </td>
          <td
            (dblclick)="onRowDoubleClick(data?.wrtOffCode || '-')"
            [matTooltip]="data?.wrtOffCode == null ? '-' : data?.wrtOffCode"
          >
            {{ data?.wrtOffCode == null ? "-" : data?.wrtOffCode }}
          </td>
          <td
            (dblclick)="onRowDoubleClick(data?.pmtCode || '-')"
            [matTooltip]="data?.pmtCode == null ? '-' : data?.pmtCode"
          >
            {{ data?.pmtCode == null ? "-" : data?.pmtCode }}
          </td>
          <td
            (dblclick)="onRowDoubleClick(data?.insuranceTypeCode || '-')"
            [matTooltip]="
              data?.insuranceTypeCode == null ? '-' : data?.insuranceTypeCode
            "
          >
            {{
              data?.insuranceTypeCode == null ? "-" : data?.insuranceTypeCode
            }}
          </td>
          <td
            (dblclick)="onRowDoubleClick(data?.claimFilingIndicator || '-')"
            [matTooltip]="
              data?.claimFilingIndicator == null
                ? '-'
                : data?.claimFilingIndicator
            "
          >
            {{
              data?.claimFilingIndicator == null
                ? "-"
                : data?.claimFilingIndicator
            }}
          </td>
          <td
            [matTooltip]="
              (data?.requireCheckList == true ? 'Yes' : 'No') || '-'
            "
            (dblclick)="
              onRowDoubleClick(
                (data?.requireCheckList == true ? 'Yes' : 'No') || '-'
              )
            "
          >
            {{
              data?.requireCheckList === null
                ? "-"
                : data?.requireCheckList == true
                ? "Yes"
                : "No"
            }}
          </td>
          <td
            [matTooltip]="data?.createdBy || '-'"
            (dblclick)="onRowDoubleClick(data?.createdBy || '-')"
          >
            {{ data?.createdBy || "-" }}
          </td>
          <td
            [matTooltip]="data?.creationTime"
            (dblclick)="onRowDoubleClick(data?.creationTime)"
            class="text-right"
          >
            {{ data?.creationTime }}
          </td>

          <td
            [matTooltip]="data?.lastModifiedBy || '-'"
            (dblclick)="onRowDoubleClick(data?.lastModifiedBy || '-')"
          >
            {{ data?.lastModifiedBy || "-" }}
          </td>
          <td
            [matTooltip]="data?.lastModificationTime"
            (dblclick)="onRowDoubleClick(data?.lastModificationTime)"
            class="text-right"
          >
            {{ data?.lastModificationTime }}
          </td>
        </tr>
      </tbody>
      <tfoot>
        <tr>
          <th><mat-icon>apps</mat-icon></th>
          <th><mat-icon>no_encryption</mat-icon></th>
          <th>
            <input
              matInput
              class="textbox-border-class"
              type="text"
              placeholder="Eligibility Mode"
              name="search-EligibilityMode"
            />
          </th>
          <th>
            <input
              matInput
              class="textbox-border-class"
              type="text"
              placeholder="Code"
              name="search-Code"
            />
          </th>
          <th>
            <input
              matInput
              class="textbox-border-class"
              type="text"
              placeholder="Payor Code"
              name="search-PayorCode"
            />
          </th>
          <th>
            <input
              matInput
              class="textbox-border-class"
              type="text"
              placeholder="Payor Name"
              name="search-PayorName"
            />
          </th>
          <th>
            <input
              matInput
              class="textbox-border-class"
              type="text"
              placeholder="Insurance Category"
              name="search-InsuranceCategory"
            />
          </th>
          <th>
            <input
              matInput
              class="textbox-border-class"
              type="text"
              placeholder="Is Medicare"
              name="search-IsMedicare"
            />
          </th>
          <th>
            <input
              matInput
              class="textbox-border-class"
              type="text"
              placeholder="Address"
              name="search-Address"
            />
          </th>
          <th>
            <input
              matInput
              class="textbox-border-class"
              type="text"
              placeholder="State"
              name="search-State"
            />
          </th>
          <th>
            <input
              matInput
              class="textbox-border-class"
              type="text"
              placeholder="City"
              name="search-City"
            />
          </th>
          <th class="text-right">
            <input
              matInput
              class="textbox-border-class"
              type="text"
              placeholder="Zip Code"
              name="search-Zip Code"
            />
          </th>
          <th>
            <input
              matInput
              class="textbox-border-class"
              type="text"
              placeholder="Contact Name"
              name="search-Contact Name"
            />
          </th>
          <th class="text-right">
            <input
              matInput
              class="textbox-border-class"
              type="text"
              placeholder="Phone Number"
              name="search-PhoneNumber"
            />
          </th>
          <th>
            <input
              matInput
              class="textbox-border-class"
              type="text"
              placeholder="WrtOff Code"
              name="search-WrtOff Code"
            />
          </th>
          <th>
            <input
              matInput
              class="textbox-border-class"
              type="text"
              placeholder="Pmt Code"
              name="search-Pmt Code"
            />
          </th>
          <th>
            <input
              matInput
              class="textbox-border-class"
              type="text"
              placeholder="Insurance Type Code"
              name="search-Insurance Type Code"
            />
          </th>
          <th>
            <input
              matInput
              class="textbox-border-class"
              type="text"
              placeholder="Claim Filing Indicator"
              name="search-Claim Filing Indicator"
            />
          </th>
          <th>
            <input
              matInput
              class="textbox-border-class"
              type="text"
              placeholder="Require CheckList"
              name="search-Require CheckList"
            />
          </th>
          ?
          <th>
            <input
              matInput
              class="textbox-border-class"
              type="text"
              placeholder="Created By"
              name="search-Created By"
            />
          </th>
          <th class="text-right">
            <input
              matInput
              class="textbox-border-class"
              type="text"
              placeholder="Created Date"
              name="search-Created Date"
            />
          </th>
          <th>
            <input
              matInput
              class="textbox-border-class"
              type="text"
              placeholder="Modify By"
              name="search-Modify By"
            />
          </th>
          <th class="text-right">
            <input
              matInput
              class="textbox-border-class"
              type="text"
              placeholder="Modified Date"
              name="search-Modified Date"
            />
          </th>
        </tr>
      </tfoot>
    </table>
  </div>
</div>
