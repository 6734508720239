import { ListService } from '@abp/ng.core';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Store } from '@ngxs/store';
import { BillingDropdowns } from 'projects/patient/src/app/dropdown-proxy/dropdown-management/dropdowns/billing-dropdowns.enum';
import { BillingMasterDropdownDTO, PreCollectionStatusDTO } from 'projects/patient/src/app/dropdown-proxy/dropdown-management/dropdowns/dto/billing/models';
import { BillingMasterDropdownService } from 'projects/patient/src/app/dropdown-proxy/dropdown-management/dropdowns/master-dropdown-service/billing-master-dropdown.service';
import { DateValidator } from 'projects/shared/src/app/validations/date-validator';
import { TextValidators } from 'projects/shared/src/app/validations/text-validator';
import { Subscription } from 'rxjs';
import Swal from 'sweetalert2';

import { ClaimInformationsService } from '../billing-proxy/billing-management/posting/claim-informations.service';
import { ClaimDTO, PatientVisitNotesDTO } from '../billing-proxy/billing-management/posting/dto/models';
import { SearchPatientVisitNotesDTO } from '../billing-proxy/billing-management/rcm/billing-management/posting/models';
import { getBilling } from '../billing-store/billing.action';
import { updateBilling } from './../billing-store/billing.action';
import { ToastrService } from "ngx-toastr";


@Component({
  selector: 'app-visit-note',
  templateUrl: './visit-note.component.html',
  styleUrls: ['./visit-note.component.scss'],
  providers: [ListService]
})
export class VisitNoteComponent implements OnInit {
  isLoading: boolean = false;
  claimId: string = '';
  strVisitNotesId: string = '00000000-0000-0000-0000-000000000000';
  strVisitNotesModalTitle: string = 'Add Visit Notes ';
  BirthdateMinDate: Date;
  public visitNoteForm: FormGroup;
  public patientVisitNotesTableForm: FormGroup;
  drpPreCollectionStatus: PreCollectionStatusDTO[] = [];
  subscription$: Subscription[] = [];
  patientVisitNotesId: string = "00000000-0000-0000-0000-000000000000";
  tblPatientVisitNotesData: any[] = [];
  dataSource = new MatTableDataSource(this.tblPatientVisitNotesData);
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild('MatPaginator', { static: false }) paginator: MatPaginator;
  saveButtonHide: boolean;
  isShowSpinner: boolean = false;
  displayedColumns: string[] = [
    'options',
    'NoteType',
    'FollowUpDate',
    'ModifiedBy',
    'ModifiedOn',
    'PreCollectionStatus',
    'Priority',
   
  ];
  constructor(
    public list: ListService,
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<VisitNoteComponent>,
    private store: Store,
    private dateValidator: DateValidator,
    private textValidators: TextValidators,
    private claimInformationsService: ClaimInformationsService,
    private billingDropdownService: BillingMasterDropdownService,
    private toastr: ToastrService,
    @Inject(MAT_DIALOG_DATA) public data: { claimId: string; }
  ) { }

  ngOnInit(): void {
    this.claimId = this.data.claimId;
    this.createVisitNotesForm();
    this.createPatientVisitNotesTableForm();
    this.getAllPatientVisitNotes(this.claimId);
    this.getDropdowns();
    this.visitNotesTableFormValueChanges();
    this.BirthdateMinDate = new Date();
    this.BirthdateMinDate.setFullYear(this.BirthdateMinDate.getFullYear() - 120);
  }

  //! Table Form Value changes
  visitNotesTableFormValueChanges() {
    const valueChanges: Subscription = this.patientVisitNotesTableForm.valueChanges.subscribe((value: SearchPatientVisitNotesDTO) => {
      let tableFormValues: SearchPatientVisitNotesDTO = {
        claimId: this.claimId,
        noteType: value.noteType.trim().toLowerCase(),
        followUpDate: value.followUpDate.trim().toLowerCase(),
        modifiedBy: value.modifiedBy.trim().toLowerCase(),
        modifiedDate: value.modifiedDate.trim().toLowerCase(),
        precollectionStatus: value.precollectionStatus.trim().toLowerCase(),
      };
      if (this.patientVisitNotesTableForm.valid) {
        this.dataSource.filter = JSON.stringify(tableFormValues);
        this.searchPatientVisitNotes(tableFormValues);
      }
    });
    this.subscription$.push(valueChanges);
  }

  //! Search PatientVisit Notes
  searchPatientVisitNotes(tableFormValues) {
    let searchPatientVisitNoteRequest: SearchPatientVisitNotesDTO = {
      claimId: this.claimId,
      noteType: tableFormValues?.noteType,
      followUpDate: tableFormValues?.followUpDate,
      modifiedBy: tableFormValues?.modifiedBy,
      modifiedDate: tableFormValues?.modifiedDate,
      precollectionStatus: tableFormValues?.precollectionStatus
    }
    this.claimInformationsService.searchPatientVisitNotesByInput(searchPatientVisitNoteRequest).subscribe(response => {
      this.tblPatientVisitNotesData = [];
      response && response?.items?.forEach(element => {
        this.tblPatientVisitNotesData.push({
          noteType: element?.noteType,
          followUpDate: element?.followUpDate,
          followUpLocalDate: element?.followUpDate == null ? '' : new Date(element?.followUpDate)?.toLocaleDateString("en-US"),
          followUpDoneUntil: element?.followUpDoneUntil,
          enteredBy: element?.enteredBy,
          entryDate: element?.entryDate,
          isPriorityFollowUp: element?.isPriorityFollowUp,
          modifiedBy: element?.modifiedBy,
          modifiedDate: element?.modifiedDate,
          modifiedLocalDate: element?.modifiedDate == null ? '' : new Date(element?.modifiedDate)?.toLocaleDateString("en-US"),
          preCollectionStatus: element?.preCollectionStatus,
          patientVisitNotesId: element?.patientVisitNotesId,
          agingNoteForSixtyPlus: element?.agingNoteForSixtyPlus,
          isExcludeFollowUp: element?.isExcludeFollowUp,
          isReceiveBill: element?.isReceiveBill,
          isCollection: element?.isCollection,
          visitsNote: element?.visitsNote,
        });
      });
      this.dataSource = new MatTableDataSource(this.tblPatientVisitNotesData);
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
    });
  }

  //! PatientVisitNotesForm
  createVisitNotesForm() {
    this.visitNoteForm = this.fb.group({
      strNoteType: new FormControl(''),
      dtFollowUpDate: new FormControl(''),
      strEnteredBy: new FormControl('', this.textValidators.isTextCheck),
      dtModifiedDate: new FormControl(''),
      dtEntryDate: new FormControl(''),
      str60AgingNote: new FormControl(''),
      strModifiedBy: new FormControl('', this.textValidators.isTextCheck),
      dtFollowupDateUntill: new FormControl(''),
      chkExcludeFollowUp: new FormControl(''),
      chkPriorityFollowUp: new FormControl(''),
      chkReceiveBill: new FormControl(''),
      chkCollection: new FormControl(''),
      drpPreCollectionStatus: new FormControl(''),
      strNote: new FormControl(''),
    });
  }

  //! PatientVisitNotesTableForm
  createPatientVisitNotesTableForm() {
    this.patientVisitNotesTableForm = this.fb.group({
      noteType: '',
      followUpDate: new FormControl('', this.dateValidator.dateVaidator),
      priority: '',
      modifiedBy: '',
      modifiedDate: new FormControl('', this.dateValidator.dateVaidator),
      precollectionStatus: '',
    });
  }

  //! Load Billing Dropdowns
  getDropdowns() {
    let billingDropdownsInput: BillingDropdowns[];
    billingDropdownsInput = [BillingDropdowns.PreCollectionStatus];
    this.billingDropdownService.getBillingDropdownsByInput(billingDropdownsInput).subscribe(dropdownResponse => {
      let response: BillingMasterDropdownDTO = dropdownResponse;
      this.drpPreCollectionStatus = response.preCollectionStatuses;
    });
  }

  //! Get Claim Details for save
  getClaimDetailsForSave() {
    this.saveButtonHide = true;
    this.store.dispatch(new getBilling(this.claimId)).subscribe(
      response => {
        let selectedBillingData: ClaimDTO = response.billingState.selectedBilling;
        let currentVisitNote: PatientVisitNotesDTO[] = selectedBillingData?.lstPatientVisitNotes;
        if (this.patientVisitNotesId !== "00000000-0000-0000-0000-000000000000") {
          currentVisitNote = currentVisitNote.filter(data => { return data && data.patientVisitNotesId !== this.patientVisitNotesId })
          currentVisitNote = [...currentVisitNote, {
            patientVisitNotesId: this.strVisitNotesId,
            noteType: this.visitNoteForm.value.strNoteType,
            followUpDate: this.visitNoteForm.value.dtFollowUpDate,
            followUpDoneUntil: this.visitNoteForm.value.dtFollowupDateUntill,
            enteredBy: this.visitNoteForm.value.strEnteredBy,
            modifiedBy: this.visitNoteForm.value.strModifiedBy,
            modifiedDate: this.visitNoteForm.value.dtModifiedDate,
            entryDate: this.visitNoteForm.value.dtEntryDate,
            preCollectionStatus: this.visitNoteForm.value.drpPreCollectionStatus,
            agingNoteForSixtyPlus: this.visitNoteForm.value.str60AgingNote,
            visitsNote: this.visitNoteForm.value.strNote,
            isExcludeFollowUp: this.visitNoteForm.value.chkExcludeFollowUp ? 1 : 0,
            isPriorityFollowUp: this.visitNoteForm.value.chkPriorityFollowUp ? 1 : 0,
            isReceiveBill: this.visitNoteForm.value.chkReceiveBill ? 1 : 0,
            isCollection: this.visitNoteForm.value.chkCollection ? 1 : 0,
          }]
        } else {
          currentVisitNote = [...currentVisitNote, {
            patientVisitNotesId: this.strVisitNotesId,
            noteType: this.visitNoteForm.value.strNoteType,
            followUpDate: this.visitNoteForm.value.dtFollowUpDate,
            followUpDoneUntil: this.visitNoteForm.value.dtFollowupDateUntill,
            enteredBy: this.visitNoteForm.value.strEnteredBy,
            modifiedBy: this.visitNoteForm.value.strModifiedBy,
            modifiedDate: this.visitNoteForm.value.dtModifiedDate,
            entryDate: this.visitNoteForm.value.dtEntryDate,
            preCollectionStatus: this.visitNoteForm.value.drpPreCollectionStatus,
            agingNoteForSixtyPlus: this.visitNoteForm.value.str60AgingNote,
            visitsNote: this.visitNoteForm.value.strNote,
            isExcludeFollowUp: this.visitNoteForm.value.chkExcludeFollowUp ? 1 : 0,
            isPriorityFollowUp: this.visitNoteForm.value.chkPriorityFollowUp ? 1 : 0,
            isReceiveBill: this.visitNoteForm.value.chkReceiveBill ? 1 : 0,
            isCollection: this.visitNoteForm.value.chkCollection ? 1 : 0,
          }]
        }
        selectedBillingData = {

          lstPatientVisitNotes: currentVisitNote,
          lstClaimInfo: selectedBillingData?.lstClaimInfo,
          lstPostingCharges: selectedBillingData?.lstPostingCharges,
          insurancePayments: selectedBillingData?.insurancePayments,
          saleOrder: selectedBillingData?.saleOrder,
          lstClearingHouseUpdates: selectedBillingData?.lstClearingHouseUpdates,
          patientId: selectedBillingData?.patientId,
          defaultPatientId: selectedBillingData?.defaultPatientId,
          patientName: selectedBillingData?.patientName,
          patientDob: selectedBillingData?.patientDob,
          orderId: selectedBillingData?.orderId,
          saleorderId: selectedBillingData?.saleorderId,
          totalPatientBal: selectedBillingData?.totalPatientBal,
          readyCount: selectedBillingData?.readyCount,
          processedCount: selectedBillingData?.processedCount,
          crossOverCount: selectedBillingData?.crossOverCount,
          holdCount: selectedBillingData?.holdCount,
          patientResponsibilityCount: selectedBillingData?.patientResponsibilityCount,
          patientResponsibilityAfterInsCount: selectedBillingData?.patientResponsibilityAfterInsCount,
          claimStatus: selectedBillingData?.claimStatus,
          claimId: selectedBillingData?.claimId,
          totalBilled: selectedBillingData?.totalBilled,
          totalInsBal: selectedBillingData?.totalInsBal,
          lstReminders: selectedBillingData?.lstReminders,
          claimProcessMethod: selectedBillingData?.claimProcessMethod,
          isVoided: selectedBillingData?.isVoided,

        };
        this.saveVisitNotes(selectedBillingData);
      },
      err => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      }
    );
  }

  //! Save Patient Visit Notes
  saveVisitNotes(selectedBillingData: ClaimDTO) {
    this.store.dispatch(new updateBilling(this.claimId, selectedBillingData)).subscribe(
      response => {
        // Swal.fire({
        //   title: 'Success',
        //   html: 'Saved Successfully',
        //   icon: 'success',
        //   timer: 3000,
        //   timerProgressBar: true,
        // });
        this.toastr.success('Saved Successfully','Success')
        this.dialogRef.close();
        this.saveButtonHide = false;
        this.isShowSpinner = false;
      },
      err => {
        this.saveButtonHide = false;
        this.isShowSpinner = false;
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      }
    );
  }

  //! Load All Patient Visit Notes
  getAllPatientVisitNotes(claimId) {
    this.isLoading = true;
    this.store.dispatch(new getBilling(claimId)).subscribe(response => {
      let selectedBillingData: ClaimDTO = response?.billingState?.selectedBilling;
      this.tblPatientVisitNotesData = [];
      selectedBillingData && selectedBillingData?.lstPatientVisitNotes != null &&
        selectedBillingData?.lstPatientVisitNotes.forEach(element => {
          this.tblPatientVisitNotesData.push({
            noteType: element?.noteType,
            followUpDate: element?.followUpDate,
            followUpLocalDate: element?.followUpDate == null ? '' : new Date(element?.followUpDate)?.toLocaleDateString("en-US"),
            followUpDoneUntil: element?.followUpDoneUntil,
            enteredBy: element?.enteredBy,
            entryDate: element?.entryDate,
            isPriorityFollowUp: element?.isPriorityFollowUp,
            modifiedBy: element?.modifiedBy,
            modifiedDate: element?.modifiedDate,
            modifiedLocalDate: element?.modifiedDate == null ? '' : new Date(element?.modifiedDate)?.toLocaleDateString("en-US"),
            preCollectionStatus: element?.preCollectionStatus,
            patientVisitNotesId: element?.patientVisitNotesId,
            agingNoteForSixtyPlus: element?.agingNoteForSixtyPlus,
            isExcludeFollowUp: element?.isExcludeFollowUp,
            isReceiveBill: element?.isReceiveBill,
            isCollection: element?.isCollection,
            visitsNote: element?.visitsNote,
          });
        });
      this.isLoading = false;
      this.dataSource = new MatTableDataSource(this.tblPatientVisitNotesData);
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
    });
  }

  //! View Patient Visit Notes
  viewPatientVisitNotes(datas: PatientVisitNotesDTO) {
    this.strVisitNotesModalTitle = "Edit Visit Notes"
    this.visitNoteForm.patchValue({
      strNoteType: datas?.noteType,
      dtFollowUpDate: datas?.followUpDate,
      strEnteredBy: datas?.enteredBy,
      dtModifiedDate: datas?.modifiedDate,
      dtEntryDate: datas?.entryDate,
      str60AgingNote: datas?.agingNoteForSixtyPlus,
      strModifiedBy: datas?.modifiedBy,
      dtFollowupDateUntill: datas?.followUpDoneUntil,
      chkExcludeFollowUp: datas?.isExcludeFollowUp,
      chkPriorityFollowUp: datas?.isPriorityFollowUp,
      chkReceiveBill: datas?.isReceiveBill,
      chkCollection: datas?.isCollection,
      drpPreCollectionStatus: datas?.preCollectionStatus,
      strNote: datas?.visitsNote,
    });
    this.patientVisitNotesId = datas?.patientVisitNotesId;
  }

  //! Reset Patient Visit Notes Form
  resetVisitNoteForm() {
    this.visitNoteForm.reset();
    this.visitNoteForm.updateValueAndValidity();
    this.visitNoteForm.markAsUntouched();
    this.strVisitNotesModalTitle = "Add Visits Notes"
    this.patientVisitNotesId = "00000000-0000-0000-0000-000000000000";
  }
}
