<table
  datatable
  [dtOptions]="dtExchangeItemOptions"
  class="row-border hover w-100 display"
>
  <thead>
    <tr>
      <th rowspan="2" matTooltip="Items">Items</th>
      <th rowspan="2" matTooltip="Chart Id">Chart Id</th>
      <th rowspan="2" matTooltip="Patient Name">Patient Name</th>
      <th rowspan="2" matTooltip="Pickup/Exchange Date">
        Pickup/Exchange Date
      </th>
      <th rowspan="2" matTooltip="Pickup/Status">Pickup/Status</th>
      <!-- <th rowspa matTooltip="Items"n="2">Pickup Status</th> -->
      <th
        colspan="5"
        matTooltip="Pickup/Exchange Order"
        class="text-center left-border"
      >
        Pickup/Exchange Order
      </th>
      <th colspan="5" matTooltip="New Order" class="text-center left-border">
        New Order
      </th>
    </tr>
    <tr>
      <th matTooltip="Ticket Id" class="left-border">Ticket Id</th>
      <th matTooltip="Order Date">Order Date</th>
      <th matTooltip="DOS">DOS</th>
      <th matTooltip="Created By">Created By</th>
      <th matTooltip="Created Date">Created Date</th>

      <th matTooltip="Ticket Id" class="left-border">Ticket Id</th>
      <th matTooltip="Order Date">Order Date</th>
      <th matTooltip="DOS">DOS</th>
      <th matTooltip="Created By">Created By</th>
      <th matTooltip="Created Date">Created Date</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let data of ltExchangeOrders">
      <td matTooltip="Click To View Items" class="text-center">
        <!-- <a (click)="getExchangeItemsList(data.exchangeId)" type="button"
          ><mat-icon class="fc-lightseagreen">remove_red_eye</mat-icon></a
        > -->

        <mat-icon
          mat-icon-button
          class="fc-lightseagreen"
          type="button"
          (click)="
            getExchangeItemsList(
              data.originalOrderId,
              data.newOrderId,
              data.originalOrderNo,
              data.newOrderNo,
              data.isPickup
            )
          "
        >
          remove_red_eye</mat-icon
        >
      </td>
      <!-- Chart No -->
      <td [matTooltip]="data?.chartId || '-'">
        <a
          class="preview-link"
          [class.disabled]="isEmpty(data.chartId) || isEmpty(data.patientId)"
          [routerLink]="
            '/patientCreateEditTab/' + data.patientId + '/' + data.documentId
          "
          target="_blank"
          >{{ data?.chartId ? data?.chartId : "-" }}
        </a>
      </td>
      <!-- Patient Name -->
      <td [matTooltip]="data?.patientName || '-'">{{ data.patientName }}</td>
      <!-- Exchange Date -->
      <td [matTooltip]="data?.exchangeDateString || '-'">{{ data.exchangeDateString }}</td>
      <!-- Pickup -->
      <td
        [matTooltip]="
          data?.isPickup
            ? 'Yes'
            : 'No' + '/' + data?.isPickupItemsReturned
            ? 'Completed'
            : 'Pending'
        "
      >
        {{ data.isPickup ? "Yes" : "No" }}
        <ng-container *ngIf="data.isPickup">
          {{ data?.isPickupItemsReturned ? " / Completed" : " / Pending" }}
        </ng-container>
        <!-- <button
          mat-icon-button
          class="buttonColor"
          matTooltip="Download Ticket"
          (click)="downloadTicket(data.patientId, data.originalOrderId)"
        >
          <mat-icon> remove_red_eye </mat-icon>
        </button> -->
      </td>
      <!--Pickup Status -->
      <!-- <td [matTooltip]="'Pending'">Pending</td> -->
      <!-- Original Ticket ID-->
      <td class="left-border" [matTooltip]="data?.originalOrderNo || '-'">
        <a
          class="preview-link"
          [class.disabled]="
            isEmpty(data.chartId) || isEmpty(data.originalOrderId)
          "
          [routerLink]="
            '/orders/saleOrder/' +
            data.patientId +
            '/' +
            data.originalOrderId +
            '/' +
            '0'
          "
          target="_blank"
          >{{ data?.originalOrderNo ? data?.originalOrderNo : "-" }}
        </a>
      </td>
      <!-- Original Order Date -->
      <td [matTooltip]="data?.originalOrderDate || '-'">
        {{ data?.originalOrderDate ? data?.originalOrderDate : "-" }}
      </td>

      <!-- Original DOS -->
      <td [matTooltip]="data?.originalOrderDOS || '-'">
        {{ data?.originalOrderDOS ? data?.originalOrderDOS : "-" }}
      </td>

      <!-- Original Order Created By -->
      <td [matTooltip]="data?.originalOrderCreatedByName || '-'">
        {{
          data?.originalOrderCreatedByName
            ? data?.originalOrderCreatedByName
            : "-"
        }}
      </td>

      <!-- Original Order Created Date -->
      <td [matTooltip]="data?.originalOrderCreatedDate || '-'">
        {{
          data?.originalOrderCreatedDate ? data?.originalOrderCreatedDate : "-"
        }}
      </td>

      <!-- New Order no -->
      <td class="left-border" [matTooltip]="data?.newOrderNo || '-'">
        <a
          class="preview-link"
          [class.disabled]="
            isEmpty(data.newOrderNo) || isEmpty(data.newOrderId)
          "
          [routerLink]="
            '/orders/saleOrder/' +
            data.patientId +
            '/' +
            data.newOrderId +
            '/' +
            '0'
          "
          target="_blank"
        >
          {{ data?.newOrderNo ? data?.newOrderNo : "-" }}
        </a>
      </td>

      <!-- New Order Date -->
      <td [matTooltip]="data?.newOrderDate || '-'">
        {{ data?.newOrderDate ? data?.newOrderDate : "-" }}
      </td>

      <!-- New Order DOS -->
      <td [matTooltip]="data?.newOrderDOS || '-'">
        {{ data?.newOrderDOS ? data?.newOrderDOS : "-" }}
      </td>

      <!-- New Order Created By -->
      <td [matTooltip]="data?.newOrderCreatedByName || '-'">
        {{ data?.newOrderCreatedByName ? data?.newOrderCreatedByName : "-" }}
      </td>

      <!-- New Order Created Date -->
      <td [matTooltip]="data?.newOrderCreatedDate || '-'">
        {{ data?.newOrderCreatedDate ? data?.newOrderCreatedDate : "-" }}
      </td>
    </tr>
  </tbody>
  <tfoot>
    <tr>
      <th>
        <!-- <input
        matInput
        class="textbox-border-class"
        type="text"
        placeholder="Items"
      /> -->
      </th>
      <th>
        <input
          matInput
          class="textbox-border-class"
          type="text"
          placeholder="Chart No"
        />
      </th>
      <th>
        <input
          matInput
          type="text"
          class="textbox-border-class"
          placeholder="Patient Name"
        />
      </th>
      <th>
        <input
          matInput
          type="text"
          class="textbox-border-class"
          placeholder="Pickup/Exchange Date"
        />
      </th>
      <th>
        <input
          matInput
          type="text"
          class="textbox-border-class"
          placeholder="Pickup/Status"
        />
      </th>
      <th>
        <input
          matInput
          type="text"
          class="text-center textbox-border-class"
          placeholder="Ticket ID"
        />
      </th>
      <th>
        <input
          matInput
          type="text"
          class="textbox-border-class"
          placeholder="Order Date"
        />
      </th>
      <th>
        <input
          matInput
          class="textbox-border-class"
          type="text"
          placeholder="DOS"
        />
      </th>
      <th>
        <input
          matInput
          class="textbox-border-class"
          type="text"
          placeholder="Created By"
          name="search-Created By"
        />
      </th>
      <th>
        <input
          matInput
          class="textbox-border-class"
          type="text"
          placeholder="Created Date"
          name="search-Created Date"
        />
      </th>
      <th>
        <input
          matInput
          type="text"
          class="text-center textbox-border-class"
          placeholder="Ticket ID"
        />
      </th>
      <th>
        <input
          matInput
          type="text"
          class="textbox-border-class"
          placeholder="Order Date"
        />
      </th>
      <th>
        <input
          matInput
          class="textbox-border-class"
          type="text"
          placeholder="DOS"
        />
      </th>
      <th>
        <input
          matInput
          class="textbox-border-class"
          type="text"
          placeholder="Created By"
          name="search-Created By"
        />
      </th>
      <th>
        <input
          matInput
          class="textbox-border-class"
          type="text"
          placeholder="Created Date"
          name="search-Created Date"
        />
      </th>
    </tr>
  </tfoot>
</table>
