import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Component, Inject, OnInit } from '@angular/core';
import emailMask from 'text-mask-addons/dist/emailMask';
import { ReportService } from 'projects/patient/src/app/patient-proxy/patient/report.service';
import { SummaryMailDTO } from 'projects/patient/src/app/patient-proxy/patient/dto/models';
import { HttpErrorResponse } from '@angular/common/http';
import Swal from 'sweetalert2';
import { SourceMDPatientService } from 'projects/patient/src/app/patient-proxy/source-mdpatient';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastrService } from "ngx-toastr";

@Component({
  selector: 'app-reports-mail-sender',
  templateUrl: './reports-mail-sender.component.html',
  styleUrls: ['./reports-mail-sender.component.scss']
})
export class ReportsMailSenderComponent implements OnInit {
  mailForm: FormGroup;
  emailMask: any = emailMask;
  sendButtonHide: boolean = false;
  constructor(
    private formBuilder: FormBuilder,
    private reportService: ReportService,
    private sourceMdService: SourceMDPatientService,
    private toastr: ToastrService,
    @Inject(MAT_DIALOG_DATA) public data: { reportType: string }) { }
  

  ngOnInit(): void {
    this.mailForm = this.formBuilder.group({
      txtUserName: new FormControl("", Validators.required),
      txtEmail: new FormControl("", [Validators.required]),
    })
  }
  sendEmailUsingNameAndEmailIds() {
    this.sendButtonHide = true;
    let ccMails: any[] = [];
    if (this.mailForm.value.txtEmail) {
      ccMails = this.mailForm.value?.txtCc?.split(";");
    }
    let summaryData: SummaryMailDTO[] = [];
    ccMails?.forEach(element => {
      summaryData.push(
        {
          userName: this.mailForm.value.txtUserName,
          email: element
        }
      )
    });
    if(this.data?.reportType == 'SourceMd'){
      this.sourceMdService.sendSummaryReportToMailByLstSummaryMail(summaryData).subscribe(response => {
        // Swal.fire({ title: 'Success', html: 'Mail Sent Successfully', icon: 'success', timer: 3000, timerProgressBar: true });
        this.toastr.success('Mail Sent Successfully','Success')
        this.resetMailForm();
        this.sendButtonHide = false;
      }, err => {
        this.sendButtonHide = false;
        this.resetMailForm();
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      });
    }
    else 
    if(this.data?.reportType == 'MISReports'){
      this.reportService.sendSummaryReportToMailByLstSummaryMail(summaryData).subscribe(response => {
        // Swal.fire({ title: 'Success', html: 'Mail Sent Successfully', icon: 'success', timer: 3000, timerProgressBar: true });
        this.toastr.success('Mail Sent Successfully','Success')
        this.resetMailForm();
        this.sendButtonHide = false;
      }, err => {
        this.sendButtonHide = false;
        this.resetMailForm();
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      });
    }
   
  }
  resetMailForm() {
    this.mailForm.patchValue({ txtUserName: "", txtEmail: "" });
    this.mailForm.reset();
  }
}
