import { RestService } from '@abp/ng.core';
import type { ListResultDto, PagedResultDto } from '@abp/ng.core';
import { Injectable } from '@angular/core';
import type { GetIdentityUsersInput, IdentityRoleDto, IdentityUserCreateDto, IdentityUserDto, IdentityUserUpdateDto, IdentityUserUpdateRolesDto } from '../../../identity/models';

@Injectable({
  providedIn: 'root',
})
export class MyUserService {
  apiName = 'platformManagement';

  create = (input: IdentityUserCreateDto) =>
    this.restService.request<any, IdentityUserDto>({
      method: 'POST',
      url: '/api/PlatformApp/my-user',
      body: input,
    },
     { apiName: this.apiName, skipHandleError: true });

  delete = (id: string) =>
    this.restService.request<any, void>({
      method: 'DELETE',
      url: `/api/PlatformApp/my-user/${id}`,
    },
     { apiName: this.apiName, skipHandleError: true });

  findByEmail = (email: string) =>
    this.restService.request<any, IdentityUserDto>({
      method: 'POST',
      url: '/api/PlatformApp/my-user/find-by-email',
      params: { email },
    },
     { apiName: this.apiName, skipHandleError: true });

  findByUsername = (userName: string) =>
    this.restService.request<any, IdentityUserDto>({
      method: 'POST',
      url: '/api/PlatformApp/my-user/find-by-username',
      params: { userName },
    },
     { apiName: this.apiName, skipHandleError: true });

  get = (id: string) =>
    this.restService.request<any, IdentityUserDto>({
      method: 'GET',
      url: `/api/PlatformApp/my-user/${id}`,
    },
     { apiName: this.apiName, skipHandleError: true });

  getAssignableRoles = () =>
    this.restService.request<any, ListResultDto<IdentityRoleDto>>({
      method: 'GET',
      url: '/api/PlatformApp/my-user/assignable-roles',
    },
     { apiName: this.apiName, skipHandleError: true });

  getList = (input: GetIdentityUsersInput) =>
    this.restService.request<any, PagedResultDto<IdentityUserDto>>({
      method: 'GET',
      url: '/api/PlatformApp/my-user',
      params: { filter: input.filter, sorting: input.sorting, skipCount: input.skipCount, maxResultCount: input.maxResultCount },
    },
     { apiName: this.apiName, skipHandleError: true });

  getRoles = (id: string) =>
    this.restService.request<any, ListResultDto<IdentityRoleDto>>({
      method: 'GET',
      url: `/api/PlatformApp/my-user/${id}/roles`,
    },
     { apiName: this.apiName, skipHandleError: true });

  update = (id: string, input: IdentityUserUpdateDto) =>
    this.restService.request<any, IdentityUserDto>({
      method: 'PUT',
      url: `/api/PlatformApp/my-user/${id}`,
      body: input,
    },
     { apiName: this.apiName, skipHandleError: true });

  updateRoles = (id: string, input: IdentityUserUpdateRolesDto) =>
    this.restService.request<any, void>({
      method: 'PUT',
      url: `/api/PlatformApp/my-user/${id}/roles`,
      body: input,
    },
     { apiName: this.apiName, skipHandleError: true });

  constructor(private restService: RestService) {}
}
