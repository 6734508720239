<!-- <h2 class="customThemeClass" mat-dialog-title>{{ notesModalTitle }}</h2>
<hr /> -->

<mat-dialog-content class="mat-typography">
  <div class="row">
    <div class="col-lg-6">
      <h2>{{ notesModalTitle }}</h2>
    </div>
    <!-- <div class="col-lg-6 text-right">
      <button class="text-right close" aria-label="Close" mat-dialog-close> <b> X </b>
      </button>
    </div> -->
  </div>
  <form [formGroup]="noteForm">
    <div class="modal-body">
      <div class="row">
        <div class="col-lg-6">
          <mat-card-content>
            <h4 class="customThemeClass"><b>General</b></h4>
            <div class="row">
              <mat-form-field class="col-4">
                <mat-label>Created By</mat-label>

                <input autocomplete="new-txtgeneralCreated" matInput formControlName="txtgeneralCreated" type="text" [(value)]="LoginUserName" maxlength="40"
                  readonly />
                <mat-error *ngIf="
                noteForm?.get('txtgeneralCreated')?.touched &&
                noteForm?.get('txtgeneralCreated')?.errors?.sAlphabets
              ">
                  Enter only Alphabets!
                </mat-error>
              </mat-form-field>
              <mat-form-field class="col-4">
                <mat-label>Date Created</mat-label>
                <input autocomplete="new-txtDateCreated" formControlName="txtDateCreated" placeholder="(MM/DD/YYYY)" matInput maxlength="40"
                  [ngxMatDatetimePicker]="txtDateCreated" readonly />
                <mat-datepicker-toggle matSuffix [for]="txtDateCreated" disabled></mat-datepicker-toggle>
                <ngx-mat-datetime-picker #txtDateCreated [showSpinners]="true" [showSeconds]="true" [stepHour]="1"
                  [stepMinute]="1" [stepSecond]="1" [touchUi]="false" [color]="'primary'" [enableMeridian]="true">
                  <ng-template>
                    <span>OK</span>
                  </ng-template>
                </ngx-mat-datetime-picker>
                <mat-error *ngIf="
              noteForm?.get('txtDateCreated')?.touched &&
              noteForm?.get('txtDateCreated')?.errors
            ">
                  Enter Valid Date!
                </mat-error>

              </mat-form-field>

              <mat-form-field class="col-4">
                <mat-label>Actual Date</mat-label>
                <input autocomplete="new-txtActualDate" formControlName="txtActualDate" placeholder="(MM/DD/YYYY)" matInput maxlength="40"
                  [ngxMatDatetimePicker]="txtActualDate" />
                <mat-datepicker-toggle matSuffix [for]="txtActualDate"></mat-datepicker-toggle>
                <ngx-mat-datetime-picker #txtActualDate [showSpinners]="true" [showSeconds]="true" [stepHour]="1"
                  [stepMinute]="1" [stepSecond]="1" [touchUi]="false" [color]="'primary'" [enableMeridian]="true">
                  <ng-template>
                    <span>OK</span>
                  </ng-template>
                </ngx-mat-datetime-picker>
              </mat-form-field>

            </div>
            <!-- <div class="row">
              <mat-form-field class="col-12">
                <mat-label>Address</mat-label>
                <textarea formControlName="txtAddress" maxlength="1000" rows="3" matInput></textarea>
              </mat-form-field>
            </div> -->
            <div class="row">

              <mat-form-field class="col-4">
                <mat-label>Assigned To</mat-label>
                <input autocomplete="new-txtAssignedTo" matInput formControlName="txtAssignedTo" type="text" maxlength="40" />
                <mat-error *ngIf="
                noteForm?.get('txtAssignedTo')?.touched &&
                noteForm?.get('txtAssignedTo')?.errors?.sAlphabets
              ">
                  Enter only Alphabets!
                </mat-error>
              </mat-form-field>
              <mat-form-field class="col-4">
                <mat-label>Date Needed</mat-label>
                <input autocomplete="new-txtDateNeeded" formControlName="txtDateNeeded" placeholder="(MM/DD/YYYY)" matInput maxlength="40"
                  [ngxMatDatetimePicker]="txtDateNeeded" />
                <mat-datepicker-toggle matSuffix [for]="txtDateNeeded"></mat-datepicker-toggle>
                <ngx-mat-datetime-picker #txtDateNeeded [showSpinners]="true" [showSeconds]="true" [stepHour]="1"
                  [stepMinute]="1" [stepSecond]="1" [touchUi]="false" [color]="'primary'" [enableMeridian]="true">
                  <ng-template>
                    <span>OK</span>
                  </ng-template>
                </ngx-mat-datetime-picker>
                <mat-error *ngIf=" noteForm?.get('txtDateNeeded')?.touched && noteForm?.get('txtDateNeeded')?.errors">
                  Enter Valid Date!
                </mat-error>
              </mat-form-field>

              <mat-form-field class="col-4">
                <mat-label>Status</mat-label>
                <mat-select formControlName="txtStatus" [(value)]="status" maxlength="40">
                  <mat-option *ngFor="let status of statuses" [value]="status.statusType">
                    {{ status.statusType }}
                  </mat-option>
                </mat-select>
                <button mat-button (click)="status=undefined;$event.stopPropagation()" (click)="clearStatus()"
                  *ngIf="status" matSuffix mat-icon-button >
                  <mat-icon>close</mat-icon>
                </button>
              </mat-form-field>
              <mat-form-field class="col-4">
                <mat-label>Date Complete</mat-label>
                <input autocomplete="new-txtDateComplete" matInput formControlName="txtDateComplete" placeholder="(MM/DD/YYYY)" [matDatepicker]="myPicker3"
                  maxlength="40" />
                <mat-datepicker-toggle matSuffix [for]="myPicker3"> </mat-datepicker-toggle>
                <mat-datepicker #myPicker3></mat-datepicker>
                <mat-error *ngIf="
                noteForm?.get('txtDateComplete')?.touched &&
                noteForm?.get('txtDateComplete')?.errors
              ">
                  Enter Valid Date!
                </mat-error>
              </mat-form-field>
              <mat-form-field class="col-4">
                <mat-label>Details Status</mat-label>
                <input autocomplete="new-txtSavedBy" matInput formControlName="txtSavedBy" type="text" maxlength="40" />
                <mat-error *ngIf="
                noteForm?.get('txtSavedBy')?.touched &&
                noteForm?.get('txtSavedBy')?.errors?.sAlphabets
              ">
                  Enter only Alphabets!
                </mat-error>
              </mat-form-field>
              <mat-form-field class="col-4">
                <mat-label>Date Saved</mat-label>
                <input autocomplete="new-txtDateSaved" matInput formControlName="txtDateSaved" placeholder="(MM/DD/YYYY)" [matDatepicker]="myPicker4"
                  maxlength="40" />
                <mat-datepicker-toggle matSuffix [for]="myPicker4"> </mat-datepicker-toggle>
                <mat-datepicker #myPicker4></mat-datepicker>
                <mat-error *ngIf="
                noteForm?.get('txtDateSaved')?.touched &&
                noteForm?.get('txtDateSaved')?.errors
              ">
                  Enter Valid Date!
                </mat-error>
              </mat-form-field>
              <mat-form-field class="col-4">
                <mat-label>Entered By</mat-label>
                <input autocomplete="new-txtEnteredBy" matInput formControlName="txtEnteredBy" type="text" maxlength="40" />
                <mat-error *ngIf="
                noteForm?.get('txtEnteredBy')?.touched &&
                noteForm?.get('txtEnteredBy')?.errors?.sAlphabets
              ">
                  Enter only Alphabets!
                </mat-error>
              </mat-form-field>
              <mat-form-field class="col-4">
                <mat-label>Modified By</mat-label>
                <input autocomplete="new-txtModifiedBy" matInput formControlName="txtModifiedBy" type="text" maxlength="40" />
                <mat-error *ngIf="
                noteForm?.get('txtModifiedBy')?.touched &&
                noteForm?.get('txtModifiedBy')?.errors?.sAlphabets
              ">
                  Enter only Alphabets!
                </mat-error>
              </mat-form-field>
              <div class="row col-4">
                <mat-checkbox formControlName="isChangeAlert" class="col-4 mt-3 pr-4">change Alert</mat-checkbox>
              </div>

              <mat-form-field class="col-4">
                <mat-label>Entry Date</mat-label>
                <input autocomplete="new-txtEntryDate" matInput formControlName="txtEntryDate" placeholder="(MM/DD/YYYY)" [matDatepicker]="myPicker5"
                  maxlength="40" />
                <mat-datepicker-toggle matSuffix [for]="myPicker5"> </mat-datepicker-toggle>
                <mat-datepicker #myPicker5></mat-datepicker>
                <mat-error *ngIf="
                noteForm?.get('txtEntryDate')?.touched &&
                noteForm?.get('txtEntryDate')?.errors
              ">
                  Enter Valid Date!
                </mat-error>
              </mat-form-field>

              <mat-form-field class="col-4">
                <mat-label>Modified Date</mat-label>
                <input autocomplete="new-txtModifiedDate" matInput formControlName="txtModifiedDate" placeholder="(MM/DD/YYYY)" [matDatepicker]="myPicker7"
                  maxlength="40" />
                <mat-datepicker-toggle matSuffix [for]="myPicker7"> </mat-datepicker-toggle>
                <mat-datepicker #myPicker7></mat-datepicker>
                <mat-error *ngIf="
                noteForm?.get('txtModifiedDate')?.touched &&
                noteForm?.get('txtModifiedDate')?.errors
              ">
                  Enter Valid Date!
                </mat-error>
              </mat-form-field>
              <mat-form-field class="col-4">
                <mat-label>Follow Up Date</mat-label>
                <input autocomplete="new-txtFollowUpDate" matInput formControlName="txtFollowUpDate" placeholder="(MM/DD/YYYY)" [matDatepicker]="myPicker6"
                  maxlength="40" />
                <mat-datepicker-toggle matSuffix [for]="myPicker6"> </mat-datepicker-toggle>
                <mat-datepicker #myPicker6></mat-datepicker>
                <mat-error *ngIf="
                noteForm?.get('txtFollowUpDate')?.touched &&
                noteForm?.get('txtFollowUpDate')?.errors
              ">
                  Enter Valid Date!
                </mat-error>
              </mat-form-field>

            </div>
          </mat-card-content>
        </div>

        <div class="col-lg-6">
          <mat-card-content>
            <h4 class="customThemeClass"><b>Patient</b></h4>
            <div class="row">
              <mat-form-field class="col-6">
                <mat-label>Note Type</mat-label>
                <!-- <mat-select formControlName="txtNotetype" placeholder="Bank" #singleSelect>
                <mat-option><mat-select-search formControlName="txtNoteSearchFilter"></mat-select-search></mat-option>
                <mat-option *ngFor="let bank of filteredBanks | async" [value]="bank">
                  {{bank.name}}
                </mat-option>
               </mat-select> -->
                <mat-select formControlName="txtNotetype" [(value)]="notesType" placeholder="Note Type" #singleSelect>
                  <mat-option>
                    <ngx-mat-select-search placeholderLabel="Search" noEntriesFoundLabel="No Matching found"
                      formControlName="txtNoteSearchFilter"></ngx-mat-select-search>
                  </mat-option>
                  <mat-option *ngFor="let note of filteredNoteTypes | async" [value]="note.notesType">
                    {{note.notesType}}
                  </mat-option>
                </mat-select>
                <button mat-button (click)="notesType=undefined;$event.stopPropagation()" *ngIf="notesType" matSuffix
                  (click)="clearNotesType()" mat-icon-button >
                  <mat-icon>close</mat-icon>
                </button>
              </mat-form-field>
              <mat-form-field class="col-6">
                <mat-label>Severity</mat-label>
                <mat-select formControlName="txtSeverity" [(value)]="severity" maxlength="40">
                  <mat-option *ngFor="let severity of drpseverity" [value]="severity.severityShortCodeId">
                    {{ severity.severityType }}
                  </mat-option>
                </mat-select>
                <button mat-button (click)="severity=undefined;$event.stopPropagation()" *ngIf="severity" matSuffix
                  (click)="clearSeverity()" mat-icon-button >
                  <mat-icon>close</mat-icon>
                </button>
              </mat-form-field>



            </div>

            <div class="row">
              <mat-form-field class="col-12">
                <mat-label>State</mat-label>
                <mat-select formControlName="txtState" [(value)]="state" (selectionChange)="valueChangeLoadDropdown()"
                  maxlength="40">
                  <mat-option *ngFor="let state of drpState" [value]="state.id">
                    {{ state.state }}
                  </mat-option>
                </mat-select>
                <button mat-button (click)="state=undefined;$event.stopPropagation()" *ngIf="state" matSuffix
                  (click)="clearState()" mat-icon-button >
                  <mat-icon>close</mat-icon>
                </button>
              </mat-form-field>

              <!-- <mat-form-field class="col-4">
                <mat-label>User 1</mat-label>
                <input matInput formControlName="txtUser1" type="text" maxlength="40" />
                <mat-error *ngIf="
                noteForm?.get('txtUser1')?.touched &&
                noteForm?.get('txtUser1')?.errors?.sAlphabets
              ">
                  Enter only Alphabets!
                </mat-error>
              </mat-form-field>
              <mat-form-field class="col-4">
                <mat-label>User 2</mat-label>
                <input matInput formControlName="txtUser2" type="text" maxlength="40" />
                <mat-error *ngIf="
                noteForm?.get('txtUser2')?.touched &&
                noteForm?.get('txtUser2')?.errors?.sAlphabets
              ">
                  Enter only Alphabets!
                </mat-error>
              </mat-form-field> -->
              <div class="row col-4">
                <mat-checkbox formControlName="chkDeactive" class="col-4 mt-3 ">Deactive Note</mat-checkbox>
              </div>
              <div class="row col-4">
                <mat-checkbox formControlName="isPaymentAlert" class="col-4 mt-3 ">Payment Alert
                </mat-checkbox>
              </div>

              <div class="row col-4">
                <mat-checkbox formControlName="chkAck" class="col-4 mt-3 ">Acknowledgement Required
                </mat-checkbox>
              </div>
            </div>


            <div class="row mt-2">
              <mat-form-field class="col-6">
                <mat-label>Locked By</mat-label>
                <input autocomplete="new-txtLockedBy" formControlName="txtLockedBy" matInput type="text" maxlength="40" />
                <mat-error *ngIf="
                noteForm?.get('txtLockedBy')?.touched &&
                noteForm?.get('txtLockedBy')?.errors?.sAlphabets
              ">
                  Enter only Alphabets!
                </mat-error>
              </mat-form-field>
              <mat-form-field class="col-6">
                <mat-label>Date Locked</mat-label>
                <input autocomplete="new-dateLocked" matInput formControlName="dateLocked" placeholder="(MM/DD/YYYY)" [matDatepicker]="myPicker8"
                  maxlength="40" />
                <mat-datepicker-toggle matSuffix [for]="myPicker8"> </mat-datepicker-toggle>
                <mat-datepicker #myPicker8></mat-datepicker>
                <mat-error *ngIf="
                noteForm?.get('dateLocked')?.touched &&
                noteForm?.get('dateLocked')?.errors
              ">
                  Enter Valid Date!
                </mat-error>
              </mat-form-field>

            </div>
            <div class="row">
              <mat-form-field class="col-12">
                <mat-label>Note Reason</mat-label>
                <textarea matInput formControlName="txtNotereason"[(ngModel)]="notesText"
                (input)="updateCharacterCount()" maxlength="5000"
                type="text"></textarea><div class="char-count">
                  {{ notesText?.length }} / 5000  
                </div>
                <!-- <mat-error *ngIf="
                noteForm?.get('txtNotereason')?.touched &&
                noteForm?.get('txtNotereason')?.errors?.sAlphabets
              ">
                  Enter only Alphabets!
                </mat-error> -->
              </mat-form-field>
            </div>
          </mat-card-content>
        </div>
        <div class="col-lg-12">
          <mat-card-content>
            <div class="row">
              <mat-form-field class="col-6">
                <mat-label>Subject</mat-label>
                <mat-select formControlName="txtSubject" [(value)]="subject" maxlength="40">
                  <mat-option *ngFor="let status of drpState" [value]="status.id">
                    {{ status.subject }}
                  </mat-option>
                </mat-select>
                <button mat-button (click)="clearSubject()" (click)="subject=undefined;$event.stopPropagation()"
                  *ngIf="subject" matSuffix mat-icon-button >
                  <mat-icon>close</mat-icon>
                </button>
              </mat-form-field>
              <mat-form-field class="col-6">
                <mat-label>Description</mat-label>
                <mat-select formControlName="txtDescription" [(value)]="description" maxlength="40">
                  <mat-option *ngFor="let state of drpState" [value]="state.id">
                    {{ state.description }}
                  </mat-option>
                </mat-select>
                <button mat-button (click)="description=undefined;$event.stopPropagation()" (click)="clearDescription()"
                  *ngIf="description" matSuffix mat-icon-button >
                  <mat-icon>close</mat-icon>
                </button>
              </mat-form-field>
            </div>
          </mat-card-content>
        </div>
      </div>
    </div>
  </form>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button *ngIf='notesId==="00000000-0000-0000-0000-000000000000"' mat-button class="buttonColor"
    [disabled]=" saveButtonHide || !(!noteForm.invalid && (noteForm.dirty ))" (click)="getPatientDetailsForSave()">Save
    <mat-icon *ngIf='isShowSpinner'>
      <mat-spinner class="spinner-border spinner-border-sm" diameter="20"> </mat-spinner>
    </mat-icon>
  </button>
  <button class="buttonCancelColor" mat-button mat-dialog-close>Close</button>
  <!-- <button mat-button [mat-dialog-close]="true" cdkFocusInitial>Install</button> -->
</mat-dialog-actions>
