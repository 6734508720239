import { ListService, PagedAndSortedResultRequestDto } from '@abp/ng.core';
import { DatePipe } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import {
  Component,
  Inject,
  OnDestroy,
  OnInit,
  ViewChild,
  ɵɵtrustConstantResourceUrl,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatAccordion } from '@angular/material/expansion';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { planInterface } from 'projects/patient/src/app/patient-add-policy/patient-add-policy.component';
import { PverifyPayersService } from 'projects/patient/src/app/patient-proxy/patient';
import { Observable, Subscription } from 'rxjs';
import { delay, map, startWith } from 'rxjs/operators';
import Swal from 'sweetalert2';
import {
  ProductAllowablesService,
  Status,
} from '../item-proxy/item-management/items';
import {
  CreateUpdateInsuranceValidityDTO,
  CreateUpdateProductAllowablesDTO,
} from '../item-proxy/item-management/items/dto/models';
import { ProductService } from '../item-proxy/item-management/items/product.service';
import { ToastrService } from 'ngx-toastr';
import {
  ProductAllowableDetailsService,
  ResupplyProductDetailsService,
} from '../item-proxy/item-management/optimization';
import {
  CreateUpdateProductAllowableDetailsDTO,
  PriceType,
} from '../item-proxy/item-management/optimization/dto';
import { PayerDetailService } from 'projects/patient/src/app/patient-proxy/patient/payer-detail.service';
import { PverifyPayersDTO } from 'projects/patient/src/app/patient-proxy/patient/dto';
import { NumberValidators } from 'projects/shared/src/app/validations/number-validator';

@Component({
  selector: 'app-add-allowance',
  templateUrl: './add-allowance.component.html',
  styleUrls: ['./add-allowance.component.scss'],
  providers: [DatePipe],
})
export class AddAllowanceComponent implements OnInit, OnDestroy {
  id: string = '';
  lotId: string = '';
  p1: string = '';
  tableData: any[] = [];
  drpInsurance: PverifyPayersDTO[];
  filteredInsuranceTypes: any;
  // display table columns
  displayedColumns: string[] = [
    'Options',
    'productNumber',
    'eligibleQuantity',
    'eligibleMonths',
    'allowablesAmount',
    'createdDate',
  ];
  dataSource: MatTableDataSource<any> = new MatTableDataSource<any>([]);
  productSearchForm: FormGroup;
  productForm: FormGroup;
  isLoading: boolean = false;
  isShowSpinner: boolean = false;
  @ViewChild(MatAccordion) accordion: MatAccordion;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  Insurance: string = '';
  drpPlan: planInterface[] = [];
  productId: string = '';
  allowId: string;
  productName: string = this.data?.prodHpscCode + ' / ' + this.data.productName;
  ruleId: string = '00000000-0000-0000-0000-000000000000';
  subscription$: Subscription[] = [];
  constructor(
    private formBuilder: FormBuilder,
    public router: Router,
    private activatedRoute: ActivatedRoute,
    private title: Title,
    private planService: PverifyPayersService,
    private productAllowablesService: ProductAllowablesService,
    private productService: ProductService,
    private resupplyService: ResupplyProductDetailsService,
    private datepipe: DatePipe,
    private toastr: ToastrService,
    private payerDetails: PayerDetailService,
    private numberValidators: NumberValidators,
    private allowablesDetails: ProductAllowableDetailsService,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      productId: string;
      productName: any;
      prodHpscCode: any;
      supplyId: string;
      allowablesId: string;
    }
  ) {}

  ngOnInit(): void {
    this.productForm = this.formBuilder.group({
      drpProductNameHpscCode: new FormControl('', Validators.required),
      drpInsurance: new FormControl('', Validators.required),
      txtEligibleQuantity: new FormControl('', [
        Validators.pattern('^0*[1-9]+[0-9]*$'),
        this.numberValidators.isNumberCheck,
        Validators.min(1),
      ]),
      txtEligibleMonths: new FormControl('', [
        Validators.pattern('^0*[1-9]+[0-9]*$'),
        this.numberValidators.isNumberCheck,
        Validators.min(1),
      ]),
      txtAllowableAmount: new FormControl('', [
        Validators.pattern('^0*[1-9]+[0-9]*$'),
        this.numberValidators.isNumberCheck,
        Validators.min(1),
      ]),
      txtEligbleDays: new FormControl(''),
      txtCreatedDate: new FormControl(''),
      txtStatus: new FormControl(''),
      txtInsuranceFilter: new FormControl(''),
    });
    this.productSearchForm = this.formBuilder.group({
      productNumberSearch: new FormControl(''),
      orderIdSearch: new FormControl(''),
      statusSearch: new FormControl(''),
      soldDateSearch: new FormControl(''),
    });
    this.title.setTitle('Qsecure | Product Allowance View');
    this.planDropdown();
    this.getTableData();
    // get by id funtionality based on main product and resupply product
    if (this.data.productId !== undefined && this.data.supplyId === undefined) {
      this.getAddMainAllowableProductById();
    } else if (this.data.supplyId !== undefined) {
      this.getSupplyMainAllowableProductById();
      // this.getTableData();
    }
  }

  ngOnDestroy(): void {
    this.subscription$?.forEach((sub) => {
      sub && sub?.unsubscribe();
    });
  }

  // plan dropdown implementation
  // public filteredInsuranceTypes: Observable<planInterface[]> | undefined;
  planDropdown(): void {
    const planDropdown = this.planService
      .getList(new PagedAndSortedResultRequestDto())
      .subscribe(
        (response) => {
          this.drpPlan = [];
          response?.items?.forEach((element) => {
            this.drpPlan.push({
              id: element.id ?? '',
              name: element?.payerName ?? '',
              code: element?.payerCode ?? '',
              payorId: element.shortCodeId ?? '',
            });
          });
          this.filteredInsuranceTypes = this.productForm
            ?.get('txtInsuranceFilter')
            ?.valueChanges.pipe(
              startWith(''),
              map((value) =>
                this.drpPlan.filter((option) =>
                  option?.name
                    ?.toLowerCase()
                    ?.includes(value?.toLowerCase() ?? '')
                )
              )
            );
        },
        (err) => {
          const data: HttpErrorResponse = err;
          Swal.fire({
            icon: 'info',
            text: data?.error?.error?.message,
          });
        }
      );
    this.subscription$.push(planDropdown);

    const pVerifyPayer = this.payerDetails
      .getPayersByClearingHouse()
      .subscribe((response) => {
        this.drpInsurance = [];
        response?.forEach((element) => {
          this.drpInsurance.push({
            id: element.id,
            payerName: element?.payerName,
            payerCode: element?.payerCode
          });
        });
        this.filteredInsuranceTypes = this.productForm
          ?.get('txtInsuranceFilter')
          ?.valueChanges.pipe(
            startWith(''),
            map((value) =>
              this.drpInsurance.filter((option) =>
                option?.payerName
                  ?.toLowerCase()
                  ?.includes(value?.toLowerCase() ?? '')
              )
            )
          );
      });
  }

  // save product allowables
  saveProduct() {

    if (
      this.allowId == null ||
      this.allowId == '' ||
      this.allowId == undefined
    ) {
      // let ProductAllowable: CreateUpdateProductAllowablesDTO = {
      //   productId: this.data.productId,
      //   productName: this.productName,
      //   insuranceValidities: [{
      //     insuranceId: this.productForm.value.drpInsurance?.id,
      //     insuranceName: this.productForm.value.drpInsurance?.name,
      //     eligibleQuantity: + (this.productForm.value.txtEligibleQuantity),
      //     eligibleMonths: + (this.productForm.value.txtEligibleMonths),
      //     eligibleDays: 0,
      //     status: Status.Active,
      //   }]
      // }
      let ProductAllowable: CreateUpdateProductAllowableDetailsDTO = {
        productId: this.data.productId,
        payerId: this.productForm.value.drpInsurance,
        quantity: this.productForm.value.txtEligibleQuantity,
        eligibleMonths: this.productForm.value.txtEligibleMonths,
        priceType: PriceType.Rental,
        allowableAmount: this.productForm.value.txtAllowableAmount,
      };
      this.allowablesDetails.create(ProductAllowable).subscribe(
        (response) => {
          this.id = response.id;
          // Swal.fire({ title: 'Success', html: 'Saved Successfully', icon: 'success', timer: 3000, timerProgressBar: true });
          this.toastr.success('Saved Successfully', 'Success');
          this.getTableData();
          this.resetProductAllowablesForm();
        },
        (err) => {
          const data: HttpErrorResponse = err;
          Swal.fire({
            icon: 'info',
            text: data?.error?.error?.message,
          });
        }
      );
    } else {
      // let ProductAllowable: CreateUpdateProductAllowablesDTO = {
      //   productId: this.data.productId,
      //   productName: this.productName,
      //   insuranceValidities: [{
      //     ruleId: this.ruleId,
      //     insuranceId: this.productForm.value.drpInsurance?.id,
      //     insuranceName: this.productForm.value.drpInsurance?.name,
      //     eligibleQuantity: + (this.productForm.value.txtEligibleQuantity),
      //     eligibleMonths: + (this.productForm.value.txtEligibleMonths),
      //     status: Status.Active,
      //   }]
      // }
      let ProductAllowable: CreateUpdateProductAllowableDetailsDTO = {
        productId: this.data.productId,
        payerId: this.productForm.value.drpInsurance,
        quantity: this.productForm.value.txtEligibleQuantity,
        eligibleMonths: this.productForm.value.txtEligibleMonths,
        priceType: PriceType.Rental,
        allowableAmount: this.productForm.value.txtAllowableAmount,
      };
      this.allowablesDetails.update(this.allowId, ProductAllowable).subscribe(
        (response) => {
          this.id = response.id;
          // Swal.fire({ title: 'Success', html: 'Updated Successfully', icon: 'success', timer: 3000, timerProgressBar: true });
          this.toastr.success('Updated Successfully', 'Success');
          this.getTableData();
          this.resetProductAllowablesForm();
        },
        (err) => {
          const data: HttpErrorResponse = err;
          Swal.fire({
            icon: 'info',
            text: data?.error?.error?.message,
          });
        }
      );
    }
  }

  //Product table view inside the Modal
  viewAllowables(data) {
    this.allowId = data.id;
    // const getInsurance = this.productAllowablesService.getInsuranceRules(data.id, data.ruleId).subscribe(response => {
    //   let insuranceDetails = this.drpPlan?.filter(value => {
    //     // return value && value?.id === response?.insuranceId
    //   })[0];

    const getAllowables = this.allowablesDetails.get(data.id).subscribe(
      (response) => {
        this.productForm.patchValue({
          drpProductNameHpscCode:
            response?.productName ??
            this.productForm.value.drpProductNameHpscCode,
          drpInsurance: response?.payerId,
          txtEligibleQuantity: response?.quantity,
          txtEligibleMonths: response?.eligibleMonths,
          txtAllowableAmount: response?.allowableAmount,
        });
      },
      (err) => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      }
    );
    // });
    this.subscription$.push(getAllowables);
  }
  // Only Numbers with Decimals
  keyPressNumbersDecimal(event: {
    which: any;
    keyCode: any;
    preventDefault: () => void;
  }) {
    var charCode = event?.which ? event?.which : event?.keyCode;
    if (charCode != 46 && charCode > 31 && (charCode < 48 || charCode > 57)) {
      event?.preventDefault();
      return false;
    }
    return true;
  }
  // reset form fields
  resetProductAllowablesForm() {
    this.productForm.patchValue({
      drpInsurance: '',
      txtEligibleQuantity: '',
      txtEligibleMonths: '',
      txtEligbleDays: '',
      txtCreatedDate: '',
      txtStatus: '',
      txtInsuranceFilter: '',
      txtAllowableAmount: '',
    });
    this.ruleId = '';
    this.productForm.markAsPristine();
    this.productForm?.markAsUntouched();
    this.productForm?.updateValueAndValidity();
  }

  // dropdown clear functionalities
  clearInsurance() {
    this.Insurance = '';
    this.productForm.patchValue({ drpInsurance: '' });
  }

  //// Get table data for add allowance popup modal table
  getTableData() {
    this.isLoading = true;
    this.tableData = [];
    this.data.allowablesId = this.data?.allowablesId ?? '';

    // if (this.data.allowablesId !== "" && this.data.allowablesId !== null && this.data.allowablesId !== undefined) {
    //   if (this.data?.allowablesId) {
    const allowableGet = this.allowablesDetails
      .getProductListByGProductId(this.data.productId)
      .subscribe(
        (response) => {
          response?.items?.forEach((element) => {
            this.tableData.push({
              id: element.id,
              insuranceName: element?.payerName,
              eligibleQuantity: element?.quantity,
              eligibleMonths: element?.eligibleMonths,
              createdDate:
                element?.creationTime === null
                  ? ''
                  : this.datepipe.transform(
                      element?.creationTime,
                      'MM/dd/yyyy'
                    ) ?? '',
              // ruleId: element?.ruleId,
              productName: element?.productName,
              allowableAmount: element?.allowableAmount,
            });
          });

          // this.tableData.push({
          //   id: response.id,
          //   // insuranceName: response?.insuranceName,
          //   eligibleQuantity: response?.quantity,
          //   eligibleMonths: response?.eligibleMonths,
          //   createdDate: response?.creationTime === null ? "" : this.datepipe.transform(response?.creationTime, 'MM/dd/yyyy') ?? "",
          //   // ruleId: response?.ruleId,
          //   productName: response?.productName,

          // })
          // })
          this.isLoading = false;
          this.dataSource = new MatTableDataSource(this.tableData);
          this.dataSource.sort = this.sort;
          this.dataSource.paginator = this.paginator;
        },
        (error) => {
          const data: HttpErrorResponse = error;
          this.isLoading = false;
          Swal.fire({
            icon: 'info',
            text: data?.error?.error?.message,
          });
          this.dataSource = new MatTableDataSource(this.tableData);
          this.dataSource.sort = this.sort;
          this.dataSource.paginator = this.paginator;
        }
      );
    this.subscription$.push(allowableGet);
    // }
    this.isLoading = false;
    // }
    // else {
    //   this.isLoading = true;
    //   const allowableGet = this.productAllowablesService.get(this.id).subscribe(response => {
    //     response?.insuranceValidities?.forEach(element => {
    //       this.tableData.push({
    //         id: response.id,
    //         insuranceName: element?.insuranceName,
    //         eligibleQuantity: element?.eligibleQuantity,
    //         eligibleMonths: element?.eligibleMonths,
    //         createdDate: response?.creationTime === null ? "" : this.datepipe.transform(response?.creationTime, 'MM/dd/yyyy') ?? "",
    //         ruleId: element?.ruleId,
    //         productName: response?.productName
    //       })
    //     })
    //     this.isLoading = false;
    //     this.dataSource = new MatTableDataSource(this.tableData);
    //     this.dataSource.sort = this.sort;
    //     this.dataSource.paginator = this.paginator;
    //   }, error => {
    //     this.isLoading = false;
    //     const data: HttpErrorResponse = error;
    //     Swal.fire({
    //       icon: 'info',
    //       text: data?.error?.error?.message,
    //     });
    //     this.dataSource = new MatTableDataSource(this.tableData);
    //     this.dataSource.sort = this.sort;
    //     this.dataSource.paginator = this.paginator;
    //   })
    //   this.subscription$.push(allowableGet);
    // }
  }

  viewMainProductName: string;
  viewResupplyProductName: string;
  viewMainHCPCcode: string;
  viewResupplyHCPCcode: string;

  // get Main add allowables form Inventroy Product
  getAddMainAllowableProductById() {
    this.productForm.patchValue({
      drpProductNameHpscCode:
        this.data.prodHpscCode + ' / ' + this.data.productName,
    });
  }

  //  get resupply add allowables form Inventroy Product
  getSupplyMainAllowableProductById() {
    const getResupplyProduct = this.resupplyService
      .getResupplyProductDetails(this.data.supplyId)
      .subscribe((response) => {
        this.productForm.patchValue({
          drpProductNameHpscCode:
            response?.hcpcCode + ' / ' + response?.resupplyProductName,
        });
      });
    this.subscription$.push(getResupplyProduct);
  }
}
