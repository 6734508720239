<br />
<div class="card card-body">
<!-- <div class="col-12"> -->
  <ul ngbNav #nav="ngbNav" justify="justified" class="alert alert-success nav nav-pills nav-fill">
    <li ngbNavItem="InviteUser"  id="inviteuser">
      <a ngbNavLink  class="nav-link" (click)="showUser()">User</a>
      <!-- <ng-template ngbNavContent>
        <app-invite-user [clientId]="clientId"></app-invite-user>
      </ng-template> -->
    </li>

    <li ngbNavItem="status" >
      <a ngbNavLink (click)="hideUser()">Invite User</a>
      <ng-template ngbNavContent>
        <app-invite-user [clientId]="clientId"></app-invite-user>
      </ng-template>
    </li>
  </ul>
  <div [ngbNavOutlet]="nav"></div>
<!-- </div> -->
<br />
<div class="card card-body" [hidden]="!userTab">
  <div class="radio-buttons">
    <mat-radio-button class="active"><b>All</b></mat-radio-button>
    <mat-radio-button class="active" [checked]="true"><b>Active</b></mat-radio-button>
    <mat-radio-button class="active"><b>InActive</b></mat-radio-button>
  </div>
  <mat-accordion>
      <!-- <button class="float-right btnsize" ngbNavItem="InviteUser" [disabled]="!clientId"  mat-button>
        <a ngbNavLink class="buttonColor">Invite User</a>
        <ng-template ngbNavContent>
          <app-invite-user [clientId]="clientId"></app-invite-user>
        </ng-template>
      </button><br /><br /><br />     -->

    <mat-expansion-panel [expanded]="panelOpenState">
      <mat-expansion-panel-header>
        <mat-panel-title class="customThemeClass">
          <ng-container *ngIf="userId===''; else elseTitleTemplate">
            <b>Add User</b>
          </ng-container>
          <ng-template #elseTitleTemplate>
            <b>Edit User</b>
          </ng-template>
        </mat-panel-title>

      </mat-expansion-panel-header>

      <form [formGroup]="roleForm">
        <div class="row">

          <div class="col-3">
            <div class="profile-img-wrap mb-2">
              <div class="profile-img ">
                <a matTooltip="Change Logo" (click)="fileInput.click()">
                  <img alt="" class="profile-image" [src]="fileAs64Value" />
                  <div class="change-image-icon-container">
                    <a class="image-icon-container" aria-label="Change profile picture." target="_top">
                      <svg class="icon-container" enable-background="new 0 0 24 24" focusable="false" height="22"
                        viewBox="0 0 24 24" width="22">
                        <path style="fill: #9c9c9c"
                          d="M20 5h-3.17L15 3H9L7.17 5H4c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V7c0-1.1-.9-2-2-2zm0 14H4V7h16v12zM12 9c-2.21 0-4 1.79-4 4s1.79 4 4 4 4-1.79 4-4-1.79-4-4-4z">
                        </path>
                      </svg>
                    </a>
                  </div>
                  <input hidden accept="image/jpeg, image/png" [multiple]="false" (change)="onFileSelected(fileInput.files[0])"
                    #fileInput type="file" id="file">
                    <ng-container *ngIf="showErrorForFileType">
                      <span class="asterisk">{{ 'Select Only Image type of JPEG' }}</span>
                    </ng-container>
                    <ng-container *ngIf="showErrorBasedOnSize">
                      <span class="asterisk">{{ 'Select Image`s size of 5mb or Less' }}</span>
                    </ng-container>
                </a>
              </div>
            </div>
          </div>
          <mat-form-field class="col-3 mt-4">
            <mat-label>Role Name <span class="asterisk">*</span></mat-label>
            <mat-select maxlength="40" [(value)]="role" (selectionChange)="onUserChange($event)"
              formControlName="txtRole">
              <mat-option *ngFor="let roles of drpRoles" [value]="roles.name">
                {{ roles.name }}

              </mat-option>
            </mat-select>
            <button mat-button (click)="clearRole()" (click)="role='';$event.stopPropagation()" *ngIf="role" matSuffix
              mat-icon-button>
              <mat-icon>close</mat-icon>
            </button>
            <mat-error *ngIf=" userForm?.get('txtRole')?.touched && userForm?.get('txtRole')?.errors?.required">
              Role Name is a required field!
            </mat-error>
          </mat-form-field>
        </div>
      </form>
      <form *ngIf="formHide" [formGroup]="userForm">
        <div class="row">
          <div [hidden]="true" class="col-3">
            <div class="profile-img-wrap mb-2">
              <div class="profile-img ">
                <a matTooltip="Change Logo" (click)="fileInput.click()">
                  <img alt="" class="profile-image" [src]="fileAs64Value" />
                  <div class="change-image-icon-container">
                    <a class="image-icon-container" aria-label="Change profile picture." target="_top">
                      <svg class="icon-container" enable-background="new 0 0 24 24" focusable="false" height="22"
                        viewBox="0 0 24 24" width="22">
                        <path style="fill: #9c9c9c"
                          d="M20 5h-3.17L15 3H9L7.17 5H4c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V7c0-1.1-.9-2-2-2zm0 14H4V7h16v12zM12 9c-2.21 0-4 1.79-4 4s1.79 4 4 4 4-1.79 4-4-1.79-4-4-4z">
                        </path>
                      </svg>
                    </a>
                  </div>
                  <input hidden accept="image/jpeg" [multiple]="false" (change)="onFileSelected(fileInput.files[0])"
                    #fileInput type="file" id="file">
                  <div class="col-3">
                    <ng-container *ngIf="showErrorForFileType">
                      <span class="asterisk">{{ 'Select Only Image type of JPEG' }}</span>
                    </ng-container>
                    <ng-container *ngIf="showErrorBasedOnSize">
                      <span class="asterisk">{{ 'Select Image`s size of 5mb or Less' }}</span>
                    </ng-container>
                  </div>
                </a>
              </div>
            </div>
          </div>

          <!-- <mat-form-field class="col-3">
                            <mat-label>Role Name <span class="asterisk">*</span></mat-label>
                            <mat-select maxlength="40" [(value)]="role" (selectionChange)="onUserChange($event)"
                                formControlName="txtRole">
                                <mat-option *ngFor="let roles of drpRoles" [value]="roles.name">
                                    {{ roles.name }}

                                </mat-option>
                            </mat-select>
                            <button mat-button (click)="clearRole()" (click)="role='';$event.stopPropagation()" *ngIf="role"
                                matSuffix mat-icon-button >
                                <mat-icon>close</mat-icon>
                            </button>
                            <mat-error
                                *ngIf=" userForm?.get('txtRole')?.touched && userForm?.get('txtRole')?.errors?.required">
                                Role Name is a required field!
                            </mat-error>
                        </mat-form-field> -->

          <mat-form-field class="col-3">
            <mat-label> User Name <span class="asterisk">*</span></mat-label>
            <input autocomplete="new-password" matInput maxlength="40" formControlName="txtUserName" type="text" oninput="this.value = this.value.charAt(0).toUpperCase() + this.value.slice(1); this.value = this.value.replace('^[a-zA-Z0-9_-]+(?:\s+[a-zA-Z0-9_-]+)*$', '').replace(/(\..*?)\..*/g, '$1').replace(/^(.*\.\d\d).*$|(\..*)$/g, '$1$2');" />
            <mat-error *ngIf="userForm?.get('txtUserName')?.touched &&
                            userForm?.get('txtUserName')?.errors?.required ">
              User Name is a required field!
            </mat-error>
             <mat-error *ngIf="userForm?.get('txtUserName')?.errors?.isUserNameExists">
              User Name is already taken!
            </mat-error>
            <!-- <mat-error *ngIf="userForm?.get('txtUserName')?.errors?.pattern?.requiredPattern">
              User Name may only contain letters, numbers, periods, hyphens, or underscores.
            </mat-error> -->
          </mat-form-field>


          <!-- <mat-form-field class="col-3">
                            <mat-label> Email ID <span class="asterisk">*</span></mat-label>
                            <input matInput maxlength="40" formControlName="txtEmail" type="text" />
                        </mat-form-field> -->
                        <!-- Without RT -->
          <mat-form-field class="col-3">
            <mat-label> Email <span class="asterisk">*</span></mat-label>
            <input autocomplete="new-txtEmail" matInput maxlength="40" formControlName="txtEmail" type="text"
            pattern="[a-zA-Z0-9._!#$%&'()*+,-./:;<=>?@[\]^_`{|}~]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$"
              [textMask]="{ mask: emailMask }" />

            <mat-error *ngIf="userForm?.get('txtEmail')?.touched &&
                            userForm?.get('txtEmail')?.errors?.required ">
              Email Address is a required field!
            </mat-error>
            <mat-error *ngIf="
                            userForm?.get('txtEmail')?.touched &&
                            userForm?.get('txtEmail')?.errors?.pattern
                                      ">
              Please Enter a Valid Email Address
            </mat-error>
            <mat-error *ngIf="userForm?.get('txtEmail')?.errors?.isEmailAddressExists">
              Email is already taken!
            </mat-error>
          </mat-form-field>
          <!-- <mat-form-field class="col-3">
                            <mat-label> Branch <span class="asterisk">*</span></mat-label>
                            <input matInput maxlength="40" formControlName="txtBranchName" type="text" />
                        </mat-form-field> -->
          <!-- <mat-form-field class="col-3">
                            <mat-label>Branch Name<span class="asterisk">*</span></mat-label>
                            <mat-select formControlName="txtBranchName">
                              <mat-option *ngFor="let organizations of drpOrganization" [value]="organizations.id">
                                {{ organizations.organizationUnitName }}
                              </mat-option>
                            </mat-select>
                            <mat-error *ngIf="userForm?.get('txtBranchName')?.touched &&
                            userForm?.get('txtBranchName')?.errors?.required ">
                              Branch Name is a required field!
                            </mat-error>
                          </mat-form-field> -->


                          <mat-form-field class="col-3">
                            <mat-label>Branch</mat-label>
                            <mat-select
                              #selectBranch
                              formControlName="drpBranch"
                              multiple
                              [(value)]="organizationUnitName"
                            >
                              <mat-option>
                                <ngx-mat-select-search
                                  placeholderLabel="Search"
                                  noEntriesFoundLabel="No Matches found"
                                  formControlName="txtBranch"
                                ></ngx-mat-select-search>
                              </mat-option>

                              <mat-option #selectAllBranch (click)="allBranchSelect()" value="0"
                                >All</mat-option
                              >

                              <mat-option
                                *ngFor="let branch of brnachList | async"
                                [value]="branch.id"
                              >
                                {{ branch.organizationUnitName }}
                              </mat-option>
                            </mat-select>
                          </mat-form-field>

          <!-- <mat-form-field class="col-3">
            <mat-label>Branch Name <span class="asterisk">*</span></mat-label>
            <mat-select maxlength="40" [(value)]="branch" formControlName="txtBranchName">
              <mat-option *ngFor="let organizations of drpOrganization" [value]="organizations.id">
                {{ organizations.organizationUnitName }}

              </mat-option>
            </mat-select>
            <button mat-button (click)="clearBranch()" (click)="branch='';$event.stopPropagation()" *ngIf="branch"
              matSuffix mat-icon-button>
              <mat-icon>close</mat-icon>
            </button>
            <mat-error
              *ngIf=" userForm?.get('txtBranchName')?.touched && userForm?.get('txtBranchName')?.errors?.required">
              Branch Name is a required field!
            </mat-error>
          </mat-form-field> -->




          <!-- <mat-form-field class="col-3">
                            <mat-label>Role Names <span class="asterisk">*</span></mat-label>
                            <mat-select formControlName="txtRole">
                              <mat-option *ngFor="let roles of drpRoles" [value]="roles.name">
                                {{ roles.name }}
                              </mat-option>
                            </mat-select>
                            <mat-error *ngIf="userForm?.get('txtRole')?.touched &&
                            userForm?.get('txtRole')?.errors?.required ">
                              Role Name is a required field!
                            </mat-error>
                          </mat-form-field> -->




          <mat-form-field class="col-3">
            <mat-label> Password <span class="asterisk">*</span> </mat-label>
            <input autocomplete="new-password" matInput maxlength="40" [type]="hide ? 'password' : 'text'" formControlName="txtPassword"
              pattern="(?=.*\d)(?=.*[a-z])(?=.*[$@$#!%*?^()&])(?=.*[A-Z]).{8,}" type="text" />
            <a class="font-size-20" mat-icon-button matSuffix (click)="hide = !hide">
              <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
            </a>
            <mat-error *ngIf="
                            userForm?.get('txtPassword')?.touched &&
                            userForm?.get('txtPassword')?.errors?.required
                    ">
              Password is a required field!
            </mat-error>
            <mat-error *ngIf="
                            userForm?.get('txtPassword')?.touched &&
                            userForm?.get('txtPassword')?.errors?.pattern
                  ">
              Passwords must contain at least one non alphanumeric character,one uppercase,one digit
              and at
              least 8 or more characters
            </mat-error>
          </mat-form-field>
          <mat-form-field class="col-3">
            <mat-label>Confirm Password  <span class="asterisk">*</span>
          </mat-label>
            <input autocomplete="new-password" matInput formControlName="txtConfirmPassword" id="txtConfirmPassword" type="password" />
            <mat-error *ngIf="
                            userForm?.get('txtConfirmPassword')?.touched &&
                            userForm?.get('txtConfirmPassword')?.errors?.required
                    ">
              Confirm Password is a required field!
            </mat-error>
            <mat-error *ngIf="userForm?.get('txtConfirmPassword').pristine ||
                            userForm?.get('txtConfirmPassword')?.errors?.MatchPassword">Password and Confirm Password
              does
              not match</mat-error>
          </mat-form-field>

        </div>
      </form>
      <!-- second Form Start -->
      <form *ngIf="form2Hide" [formGroup]="userForm2">
        <div class="row">

          <!-- old fields -->
          <!-- <mat-form-field class="col-3">
                            <mat-label>Role Name 2 <span class="asterisk">*</span></mat-label>
                            <mat-select maxlength="40" [(value)]="role" formControlName="txtRole2">
                                <mat-option *ngFor="let roles of drpRoles" [value]="roles.name">
                                    {{ roles.name }}

                                </mat-option>
                            </mat-select>
                            <button mat-button (click)="clearRole()" (click)="role='';$event.stopPropagation()" *ngIf="role"
                                matSuffix mat-icon-button >
                                <mat-icon>close</mat-icon>
                            </button>
                            <mat-error
                                *ngIf=" userForm2?.get('txtRole2')?.touched && userForm?.get('txtRole2')?.errors?.required">
                                Role Name is a required field!
                            </mat-error>
                        </mat-form-field> -->

          <mat-form-field class="col-3">
            <mat-label> User Name <span class="asterisk">*</span></mat-label>
            <input autocomplete="new-txtUserName2" matInput maxlength="40" formControlName="txtUserName2" type="text" oninput="this.value = this.value.charAt(0).toUpperCase() + this.value.slice(1); this.value = this.value.replace('^[a-zA-Z0-9_-]+(?:\s+[a-zA-Z0-9_-]+)*$', '').replace(/(\..*?)\..*/g, '$1').replace(/^(.*\.\d\d).*$|(\..*)$/g, '$1$2');" />
            <mat-error *ngIf="userForm2?.get('txtUserName2')?.touched &&
                                        userForm2?.get('txtUserName2')?.errors?.required ">
              User Name is a required field!
            </mat-error>
            <mat-error *ngIf="userForm2?.get('txtUserName2')?.errors?.isUserNameExists">
              User Name is already taken!
            </mat-error>
          </mat-form-field>
          <!-- <mat-form-field class="col-3">
                                        <mat-label> Email ID <span class="asterisk">*</span></mat-label>
                                        <input matInput maxlength="40" formControlName="txtEmail" type="text" />
                                    </mat-form-field> -->
                                    <!-- RT EMAIL -->
          <mat-form-field class="col-3">
            <mat-label> Email <span class="asterisk">*</span></mat-label>
            <input autocomplete="new-txtEmail2" matInput maxlength="40" formControlName="txtEmail2" type="text"
            pattern="[a-zA-Z0-9._!#$%&'()*+,-./:;<=>?@[\]^_`{|}~]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$"
              [textMask]="{ mask: emailMask }" />

            <mat-error *ngIf="userForm2?.get('txtEmail2')?.touched &&
                                        userForm2?.get('txtEmail2')?.errors?.required ">
              Email Address is a required field!
            </mat-error>
            <mat-error *ngIf="
                                        userForm2?.get('txtEmail2')?.touched &&
                                        userForm2?.get('txtEmail2')?.errors?.pattern
                                                  ">
              Please Enter a Valid Email Address
            </mat-error>
            <mat-error *ngIf="userForm2?.get('txtEmail2')?.errors?.isEmailAddressExists">
              Email is already taken!
            </mat-error>
          </mat-form-field>
          <!-- <mat-form-field class="col-3">
                                        <mat-label> Branch <span class="asterisk">*</span></mat-label>
                                        <input matInput maxlength="40" formControlName="txtBranchName" type="text" />
                                    </mat-form-field> -->
          <!-- <mat-form-field class="col-3">
                                        <mat-label>Branch Name<span class="asterisk">*</span></mat-label>
                                        <mat-select formControlName="txtBranchName">
                                          <mat-option *ngFor="let organizations of drpOrganization" [value]="organizations.id">
                                            {{ organizations.organizationUnitName }}
                                          </mat-option>
                                        </mat-select>
                                        <mat-error *ngIf="userForm?.get('txtBranchName')?.touched &&
                                        userForm?.get('txtBranchName')?.errors?.required ">
                                          Branch Name is a required field!
                                        </mat-error>
                                      </mat-form-field> -->


          <mat-form-field class="col-3">
            <mat-label>Branch Name <span class="asterisk">*</span></mat-label>
            <mat-select maxlength="40" [(value)]="branch" formControlName="txtBranchName2">
              <mat-option *ngFor="let organizations of drpOrganization" [value]="organizations.id">
                {{ organizations.organizationUnitName }}

              </mat-option>
            </mat-select>
            <button mat-button (click)="clearBranch()" (click)="branch='';$event.stopPropagation()" *ngIf="branch"
              matSuffix mat-icon-button>
              <mat-icon>close</mat-icon>
            </button>
            <mat-error
              *ngIf=" userForm2?.get('txtBranchName2')?.touched && userForm2?.get('txtBranchName2')?.errors?.required">
              Branch Name is a required field!
            </mat-error>
          </mat-form-field>

          <!-- <mat-form-field class="col-3">
                                        <mat-label>Role Names <span class="asterisk">*</span></mat-label>
                                        <mat-select formControlName="txtRole">
                                          <mat-option *ngFor="let roles of drpRoles" [value]="roles.name">
                                            {{ roles.name }}
                                          </mat-option>
                                        </mat-select>
                                        <mat-error *ngIf="userForm?.get('txtRole')?.touched &&
                                        userForm?.get('txtRole')?.errors?.required ">
                                          Role Name is a required field!
                                        </mat-error>
                                      </mat-form-field> -->
          <!-- Newly Added  -->
          <mat-form-field class="col-3">
            <mat-label> Phone No<span class="asterisk">*</span></mat-label>
            <input autocomplete="new-txtPhnNo" matInput prefix="+1-" mask="(000)-000-0000" maxlength="40" formControlName="txtPhnNo" type="text" />
            <mat-error *ngIf=" userForm2?.get('txtPhnNo')?.touched && userForm2?.get('txtPhnNo')?.errors?.required">
              Phone No is a required field!
            </mat-error>
          </mat-form-field>
          <mat-form-field class="col-3">
            <mat-label> Address 1 <span class="asterisk">*</span> </mat-label>
            <input autocomplete="new-txtAddress1" matInput maxlength="40" formControlName="txtAddress1" type="text" oninput="this.value = this.value.charAt(0).toUpperCase() + this.value.slice(1); this.value = this.value.replace('^[a-zA-Z0-9_-]+(?:\s+[a-zA-Z0-9_-]+)*$', '').replace(/(\..*?)\..*/g, '$1').replace(/^(.*\.\d\d).*$|(\..*)$/g, '$1$2');" />
            <mat-error
              *ngIf=" userForm2?.get('txtAddress1')?.touched && userForm2?.get('txtAddress1')?.errors?.required">
              Address1 is a required field!
            </mat-error>
          </mat-form-field>
          <mat-form-field class="col-3">
            <mat-label> Address 2 </mat-label>
            <input autocomplete="new-txtAddress2" matInput maxlength="40" formControlName="txtAddress2" type="text" oninput="this.value = this.value.charAt(0).toUpperCase() + this.value.slice(1); this.value = this.value.replace('^[a-zA-Z0-9_-]+(?:\s+[a-zA-Z0-9_-]+)*$', '').replace(/(\..*?)\..*/g, '$1').replace(/^(.*\.\d\d).*$|(\..*)$/g, '$1$2');" />
          </mat-form-field>

          <mat-form-field class="col-3">
            <mat-label>Country <span class="asterisk">*</span></mat-label>
            <mat-select formControlName="drpCountry" (selectionChange)='onChangeState($event.value)'
              [(value)]="Country">
              <!-- <mat-option>
                                <ngx-mat-select-search placeholderLabel="Search"
                                    noEntriesFoundLabel="No Matches found"
                                    formControlName="txtCountryFilter"></ngx-mat-select-search>
                            </mat-option> -->
              <mat-option *ngFor="let country of drpCountry" [value]="country.countryShortName">
                {{ country.countryName }}
              </mat-option>
            </mat-select>
            <button mat-button (click)="Country='';$event.stopPropagation()" *ngIf="Country" (click)="clearCountry()"
              matSuffix mat-icon-button>
              <mat-icon>close</mat-icon>
            </button>
            <mat-error *ngIf="userForm2?.get('drpCountry')?.touched &&
                        userForm2?.get('drpCountry')?.errors?.required ">
              Address line -1 is a required field!
            </mat-error>
          </mat-form-field>

          <mat-form-field class="col-3">
            <mat-label>State <span class="asterisk">*</span></mat-label>
            <mat-select formControlName="drpState" (selectionChange)='onChangeCity($event.value,"")' [(value)]="State"
              #singleSelect>
              <mat-option>
                <ngx-mat-select-search placeholderLabel="Search" noEntriesFoundLabel="No Matches found"
                  formControlName="drpStateFilter">
                </ngx-mat-select-search>
              </mat-option>
              <mat-option *ngFor="let state of filteredStates | async" [value]="state.stateShortName">
                {{ state.stateName }}
              </mat-option>
            </mat-select>
            <button mat-button (click)="State='';$event.stopPropagation()" *ngIf="State" (click)="clearState()"
              matSuffix mat-icon-button>
              <mat-icon>close</mat-icon>
            </button>
            <mat-error *ngIf="userForm2?.get('drpState')?.touched &&
                        userForm2?.get('drpState')?.errors?.required ">
              Address line -1 is a required field!
            </mat-error>
          </mat-form-field>



          <mat-form-field class="col-3">
            <mat-label>City <span class="asterisk">*</span></mat-label>
            <mat-select formControlName="drpCity" [(value)]="City" #singleSelect>
              <mat-option>
                <ngx-mat-select-search placeholderLabel="Search" noEntriesFoundLabel="No Matches found"
                  formControlName="drpCityFilter">
                </ngx-mat-select-search>
              </mat-option>
              <mat-option *ngFor="let city of filteredCities | async" [value]="city.cityName">
                {{ city.cityName }}
              </mat-option>
            </mat-select>
            <button mat-button (click)="City='';$event.stopPropagation()" *ngIf="City" matSuffix (click)="clearCity()"
              mat-icon-button>
              <mat-icon>close</mat-icon>
            </button>
            <mat-error *ngIf="userForm2?.get('drpCity')?.touched &&
                        userForm2?.get('drpCity')?.errors?.required ">
              City is a required field!
            </mat-error>
          </mat-form-field>


          <mat-form-field class="col-3">
            <mat-label> Zip Code<span class="asterisk">*</span> </mat-label>
            <input autocomplete="new-txtZipCode" matInput maxlength="9" formControlName="txtZipCode" type="text"  />
            <mat-error *ngIf=" userForm2?.get('txtZipCode')?.touched && userForm2?.get('txtZipCode')?.errors?.required">
              Zip Code is a required field!
            </mat-error>
          </mat-form-field>

          <mat-form-field class="col-3">
            <mat-label> Password <span class="asterisk">*</span></mat-label>
            <input autocomplete="new-password" matInput maxlength="40" [type]="hide ? 'password' : 'text'" formControlName="txtPassword2"
              pattern="(?=.*\d)(?=.*[a-z])(?=.*[$@$#!%*?^()&])(?=.*[A-Z]).{8,}" type="text" />
            <a class="font-size-20" mat-icon-button matSuffix (click)="hide = !hide">
              <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
            </a>
            <mat-error *ngIf="
                                        userForm2?.get('txtPassword2')?.touched &&
                                        userForm2?.get('txtPassword2')?.errors?.required
                                ">
              Password is a required field!
            </mat-error>
            <mat-error *ngIf="
                                        userForm2?.get('txtPassword2')?.touched &&
                                        userForm2?.get('txtPassword2')?.errors?.pattern
                              ">
              Passwords must contain at least one non alphanumeric character,one uppercase,one digit
              and at
              least 8 or more characters
            </mat-error>
          </mat-form-field>
          <mat-form-field class="col-3">
            <mat-label>Confirm Password<span class="asterisk">*</span></mat-label>
            <input autocomplete="new-password" matInput formControlName="txtConfirmPassword2" id="txtConfirmPassword2" type="password" />
            <mat-error *ngIf="
                                        userForm2?.get('txtConfirmPassword2')?.touched &&
                                        userForm2?.get('txtConfirmPassword2')?.errors?.required
                                ">
              Confirm Password is a required field!
            </mat-error>
            <mat-error *ngIf="userForm2?.get('txtConfirmPassword2').pristine ||
                                        userForm2?.get('txtConfirmPassword2')?.errors?.MatchPassword">Password and
              Confirm
              Password does
              not match</mat-error>
          </mat-form-field>

          <!-- Newly Added End -->


          <!-- <mat-form-field class="col-3">
                            <mat-label>Role Names <span class="asterisk">*</span></mat-label>
                            <mat-select formControlName="txtRole">
                              <mat-option *ngFor="let roles of drpRoles" [value]="roles.name">
                                {{ roles.name }}
                              </mat-option>
                            </mat-select>
                            <mat-error *ngIf="userForm?.get('txtRole')?.touched &&
                            userForm?.get('txtRole')?.errors?.required ">
                              Role Name is a required field!
                            </mat-error>
                          </mat-form-field> -->


        </div>
      </form>

      <!-- save -->
      <br />
      <div class="row" *ngIf="saveHide">
        <div class="col-sm-12">
          <div class="text-lg-center">
            <!--
              [disabled]="saveButtonHide ||  !(!userForm?.invalid && userForm?.dirty)"

            [disabled]="userId==='' ? saveButtonHide ||  !(!userForm?.invalid && userForm?.dirty) : saveButtonHide ||  !(!userForm?.invalid)"

             [disabled]="userId==='' ? saveButtonHide ||  !(!userForm?.invalid && userForm?.dirty) : saveButtonHide || userForm?.invalid || !userForm?.dirty

          -->
            <button *ngIf='isShownSaveButton' [disabled]="userId==='' ? userForm?.invalid : userForm?.invalid "
              mat-button class="buttonColor mr-2" (click)="saveOnTimeUser('firstForm')">  {{ userId === '' ? 'Save' : 'Update' }}
              <mat-icon *ngIf='isShowSpinner'>
                <mat-spinner class="spinner-border spinner-border-sm" diameter="20"> </mat-spinner>
              </mat-icon>
            </button>

            <button mat-button class="resetclr buttonColor mb-2 mr-2" (click)="reset('firstForm')">Reset</button>
          </div>
        </div>
      </div>
      <div class="row" *ngIf="saveHide2">
        <div class="col-sm-12">
          <div class="text-lg-center">
            <!-- <button *ngIf="addWorkingButton" mat-button class="buttonColor mr-2" (click)="openAddWorkingModel()">
              Add Working Hours</button>
            <button *ngIf="addWorkingButton" mat-button class="buttonColor mr-2" (click)="confirmUserLocation()">
              Confirm Location</button> -->

              <!-- [disabled]="userId==='' ? saveButtonHide ||  !(!userForm2?.invalid && userForm2?.dirty) : saveButtonHide || !userForm2?.invalid "
              mat-button class="buttonColor mr-2" (click)="saveOnTimeUser('firstForm')">  {{ userId === '' ? 'Save' : 'Update' }}
            [disabled]="saveButtonHide || !(!userForm2?.invalid && userForm2?.dirty )"
            -->

            <button *ngIf='isShownSaveButton' [disabled]="saveButtonHide || !(!userForm2?.invalid && userForm2?.dirty )"
              mat-button class="buttonColor mr-2" (click)="saveOnTimeUser('secondForm')"> {{ userId === '' ? 'Save' : 'Update' }}
              <mat-icon *ngIf='isShowSpinner'>
                <mat-spinner class="spinner-border spinner-border-sm" diameter="20"> </mat-spinner>
              </mat-icon>
            </button>
            <button mat-button class="resetclr buttonColor mb-2 mr-2" (click)="reset('secondForm')">Reset</button>
          </div>
        </div>
      </div>
    </mat-expansion-panel>
  </mat-accordion>
  <div class="row">
    <div>
      <div class="col-12" *ngIf="isConfirmBranchLocation " style="margin-top: 10px;">
        <h2>Confirm {{branchName}} User location</h2>
        <agm-map [latitude]="latitude" [longitude]="longitude" [zoom]="17" centered="true" [disableDefaultUI]="false"
          [zoomControl]="true">
          <agm-marker *ngFor="let m of markers; let i = index" [latitude]="latitude" [longitude]="longitude"
            [markerDraggable]="m.draggable" (dragEnd)="markerDragEnd($event)">
            <agm-info-window>
              <strong>InfoWindow content</strong>
            </agm-info-window>
          </agm-marker>
        </agm-map>

        <div class="row float-right mt-3">
          <div class="col-11 customThemeClass mb-2">
            <button mat-button class="buttonColor" [disabled]="saveButtonHide||userForm2?.invalid"
              (click)="saveOnTimeUser('secondForm')">
              Confirm location
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="col-12 table-responsive">
      <table mat-table class="table ml-4" matSort [dataSource]="dataSource" style="margin-left: 3px !important; border: 1px solid  #cdc7c7">
        <form style="display: flex" [formGroup]="UserFilterForm">
          <ng-container matColumnDef="sno"sticky >
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              <mat-label class="text-center"> S.No</mat-label>
            </th>
            <td mat-cell *matCellDef="let data; let i = index">
              {{ i+1 }}
            </td>
          </ng-container>
          <ng-container matColumnDef="userName"sticky>
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              <mat-form-field class="filter">
                <input autocomplete="new-userName" matInput (keydown.space)="$event.stopPropagation()" (click)="$event.stopPropagation()" formControlName="userName" />
                <mat-placeholder>UserName</mat-placeholder>
              </mat-form-field>
            </th>
            <td mat-cell *matCellDef="let data">
              <ng-container>
                {{data.name == 'admin' ? 'Admin' : data.name }}
                <mat-chip [disabled]="true" *ngIf="data?.isStatic && data?.name == 'admin' " selected>Static</mat-chip>
                <mat-chip [disabled]="true" *ngIf="data?.isDefault && data?.name == 'admin' " selected>Default</mat-chip>
              </ng-container>
            </td>
          </ng-container>

          <ng-container matColumnDef="phoneNumber">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              <mat-form-field class="filter">
                <input autocomplete="new-phoneNumber" matInput (keydown.space)="$event.stopPropagation()" (click)="$event.stopPropagation()" formControlName="phoneNumber" />
                <mat-placeholder>Phone Number</mat-placeholder>
              </mat-form-field>
            </th>
            <td mat-cell *matCellDef="let data">
              <ng-container>
                {{data.phoneNumber}}
              </ng-container>
            </td>
          </ng-container>
          <ng-container matColumnDef="email"sticky>
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              <mat-form-field class="filter">
                <input autocomplete="new-email" matInput (keydown.space)="$event.stopPropagation()" (click)="$event.stopPropagation()" formControlName="email" />
                <mat-placeholder>Email</mat-placeholder>
              </mat-form-field>
            </th>
            <td mat-cell *matCellDef="let data">
              <ng-container>
                {{data.email}}
              </ng-container>
            </td>
          </ng-container>
          <ng-container matColumnDef="roleName">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              <mat-form-field class="filter">
                <input autocomplete="new-roleName" matInput (keydown.space)="$event.stopPropagation()" (click)="$event.stopPropagation()" formControlName="roleName" />
                <mat-placeholder>Role</mat-placeholder>
              </mat-form-field>
            </th>
            <td mat-cell *matCellDef="let data">
              <ng-container>
                {{ data.role == 'admin' ? 'Admin' : data.role}}
              </ng-container>
            </td>
          </ng-container>
          <ng-container matColumnDef="branch">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              <mat-form-field class="filter">
                <input autocomplete="new-txtBranch" matInput (keydown.space)="$event.stopPropagation()" (click)="$event.stopPropagation()" formControlName="txtBranch" />
                <mat-placeholder>Branch </mat-placeholder>
              </mat-form-field>
            </th>
            <td mat-cell *matCellDef="let data">
              <ng-container>
                {{data.branch}}
              </ng-container>
            </td>
          </ng-container>
          <ng-container matColumnDef="createdDate">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              <mat-form-field class="filter">
                <mat-label> Created Date</mat-label>
                <input autocomplete="new-createdDate" matInput (keydown.space)="$event.stopPropagation()" (click)="$event.stopPropagation()" formControlName="createdDate" placeholder="(MM/DD/YYYY)" />
                <mat-error *ngIf="UserFilterForm?.get('createdDate')?.errors?.dateVaidator">
                  Enter Valid date
                </mat-error>
              </mat-form-field>
            </th>
            <td mat-cell *matCellDef="let data">
              <ng-container>
                {{data.createdDate}}
              </ng-container>
            </td>
          </ng-container>

          <ng-container matColumnDef="Status">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              <mat-label class="text-center">Status</mat-label>
            </th>
            <td mat-cell *matCellDef="let data">
              <ng-container *ngIf="data?.status==='Active'">
                <mat-slide-toggle (change)="statusChange($event, data)" [checked]='true' class="ml-2">
                  {{data?.status}}</mat-slide-toggle>
              </ng-container>
              <ng-container *ngIf="data?.status==='Inactive'">
                <mat-slide-toggle (change)="statusChange($event, data)" [checked]='false' class="ml-2">
                  {{data?.status}}</mat-slide-toggle>
              </ng-container>
            </td>
          </ng-container>


          <ng-container matColumnDef="Options">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              <mat-label class="text-center">Options</mat-label>
            </th>
            <td mat-cell *matCellDef="let data">
              <a class="eyeCursorclass" target="_blank"><i (click)="ViewUser(data)" *ngIf="data?.status==='Active'"
                  class="fa fa-pencil editBtnColor mr-1"></i>
              </a>
              &nbsp;
              <a class="deleteBtnColor" *ngIf="data?.status==='Active'" (click)="deleteUser(data)">
                <i class="fa fa-trash"></i>
              </a>
            </td>
          </ng-container>

          <ng-container matColumnDef="noRecords">
            <td class="text-align-center" mat-footer-cell *matFooterCellDef colspan="9">
              <div *ngIf="!isLoading">
                {{"No Records Found"}}
              </div>
              <div [hidden]="!isLoading">
                <mat-spinner class="table-spinner" [diameter]="220"></mat-spinner>
              </div>
            </td>
          </ng-container>

          <ng-container matColumnDef="paginator">
            <td mat-footer-cell *matFooterCellDef colspan="8">
              <mat-paginator class="table table-responsive mat-table-class" #MatPaginator
                [length]="dataSource?.data?.length" [pageSize]="10" [pageIndex]="0" showFirstLastButtons
                [pageSizeOptions]="[5, 10, 25, 100]">
              </mat-paginator>
            </td>
          </ng-container>
        </form>
        <tr mat-footer-row *matFooterRowDef="['noRecords']"
          [ngClass]="{ active: dataSource && dataSource?.data && dataSource?.data?.length !== 0 }"></tr>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        <tr mat-footer-row *matFooterRowDef="['paginator']"></tr>
      </table>
    </div>
  </div>
</div>
</div>
<div class="row"></div>
