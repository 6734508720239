import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
// import { defaultGuid } from 'projects/admin/src/app/document-perviewer/document-perviewer.component';
import { PatientBalanceService } from 'projects/billing/src/app/claim-proxy/claim-processing-management/rcm/billing-management/optimization/claim/patient-balance.service';
import { ClaimInfoDetailsService } from 'projects/billing/src/app/claim-proxy/claim-processing-management/rcm/claim-processing-management/claim-optimization';
import { MISReportsService } from 'projects/billing/src/app/claim-proxy/claim-processing-management/rcm/claim-processing-management/mis-reports';
import { claimStatus, claimStatusOptions } from 'projects/billing/src/app/claim-proxy/claim-processing-management/rcm/claim-processing-management/x12/enumeration-data';
import { orderStatus } from 'projects/order/src/app/order-proxy/order-management/order-optimization/order-status.enum';
import { PatientPersonalService } from 'projects/patient/src/app/patient-proxy/patient-optimization';
import { PatientSummaryDTO } from 'projects/patient/src/app/patient-proxy/patient-optimization/dto';
import { PatientStatus } from 'projects/patient/src/app/patient-proxy/patient/enumeration-data/patient-status.enum';
import { Subscription } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { CommonService } from '../../services/common.service';
import { CommunicationService } from 'projects/shared/src/app/services/communication.service';
import { defaultGuid } from '../../enums/allenums.enum';
@Component({
  selector: 'app-patient-common-summary-tab',
  templateUrl: './patient-common-summary-tab.component.html',
  styleUrls: ['./patient-common-summary-tab.component.scss'],
})
export class PatientCommonSummaryTabComponent implements OnInit, OnDestroy {
  @Input() patientId: string = defaultGuid;
  @Input() orderId: string = defaultGuid;
  @Input() lblDefaultOrderId: string = '';
  @Input() lblDefaultPatientId: string = '';
  @Input() isTemplate: boolean = false;

  lblPatientName: string = '';
  lblPatientBranchName: string = '';
  lblPtCurrentStatus: orderStatus | PatientStatus;
  lblPatientGender: string = '';
  lblPatientDOB: string = '';
  lblPatientAge: string = '';
  lblPatientHeight: string = '';
  lblBilledAmount: string = '';
  lblAllowedAmount: string = '';
  lblReceivedAmount: string = '';
  lblPendingAmount: string = '';
  iAdjustmentAmount: string = '';
  lblBillingStatus: string = '';
  lblthirtyDayPaidAmnt: string = '';
  lblsixtyDayPaidAmnt: string = '';
  lblninetyDayPaidAmnt: string = '';
  lblAboveNinetyDayPaidAmnt: string = '';

  lblPatientBalance:number;
  lblInsuranceRecoopAmount:number;
  lblInsuranceBalance:number;
  lblTotalAmount:number;
  subscription$: Subscription[] = [];
  patientBillingApiSubscription: Subscription;
  patientAgingApiSubscription:Subscription;
  lblThirtyDays:number;
  lblSixtyDays:number;
  lblNinetyDays:number;
  lblAboveNinetyDays:number;
  constructor(
    private patientPersonalService: PatientPersonalService,
    private claimInfoDetailsService: ClaimInfoDetailsService,
    private mISReportsService: MISReportsService,
    private patientBalanceService: PatientBalanceService,
    private commonService : CommonService,
    private communicationService: CommunicationService,

  ) {

  const patientSummary =
    this.communicationService.functionCallPatientSummary$.subscribe(
      () => {this.getPatientSummary();}
    );
  this.subscription$.push(patientSummary);
  }

  ngOnInit(): void {
    this.patientId = this.patientId === '' ? defaultGuid : this.patientId;
    this.orderId = this.orderId === '' ? defaultGuid : this.orderId;
    (this.patientId ?? defaultGuid) !== defaultGuid && this.getPatientSummary();

    this.getPatientBillingDetails();
    this.getPatientAgingWiseDetails();
  }
  // On Page Destroy
  ngOnDestroy(): void {
    this.subscription$?.forEach((sub) => {
      sub && sub?.unsubscribe();
    });
  }

  //To get Patient Summary
  getPatientSummary() {
    const patientSummaryDto: PatientSummaryDTO = {
      patientId: this.patientId,
      patientName: '',
      orderId: this.orderId ?? defaultGuid,
      defaultOrderId: '',
      defaultPatientId: '',
      chartId:'',
      currentStatus: '',
      dob: '',
      billedAmount: '0',
      receivedAmount: '0',
      pendingAmount: '0',
      billingStatus: '0',
      thirtyDayBilledAmnt: '0',
      sixtyDayBilledAmnt: '0',
      ninetyDayBilledAmnt: '0',
      aboveNinetyDayBilledAmnt: '0',
      gender: "",
      adjustmentAmount: ''
    };

    const result = this.patientPersonalService
      .patientSummaryByPatientSummaryDto(patientSummaryDto).pipe(
        map(response => {
          this.lblDefaultOrderId = response?.defaultOrderId ?? "";
          this.lblPatientBranchName = response?.locationName ?? "";
          // this.lblDefaultPatientId = response?.defaultPatientId ?? "";
          this.lblDefaultPatientId = response?.chartId ?? "";
          this.lblPatientName = response?.patientName ?? "";
          // this.lblPatientDOB = response?.formattedDOB;
          this.lblPatientDOB = this.formatDateDob(response?.formattedDOB);
          // this.lblPatientDOB = (response?.dob ?? '') === '' ? '' : new Date(response?.dob)?.toLocaleDateString('en-US');
          this.lblPtCurrentStatus = ((response?.defaultOrderId ?? "") === "") ? (PatientStatus[response?.currentStatus]) ?? "" ?? "" : (orderStatus[response?.currentStatus]) ?? "";
          this.lblPatientGender = response?.gender;
          this.lblPatientAge = response?.patientAge
          return response
        }),
        switchMap(x => this.mISReportsService.patientAgingSummaryByPatientId(x.patientId)),
        map(response => {
          this.lblthirtyDayPaidAmnt = response?.zeroToThirtyDays ?? "";
          this.lblsixtyDayPaidAmnt = response?.thirtyToSixtyDays ?? "";
          this.lblninetyDayPaidAmnt = response?.sixtyToNinetyDays ?? "";
          this.lblAboveNinetyDayPaidAmnt = response?.moreThanNinetyDays ?? "";
          return response
        }),
        switchMap(x => this.claimInfoDetailsService.getBillingStatusDetailsById(x.patientId)),
        map(x => {
          this.lblAllowedAmount = String(x?.iAllowedAmount) ?? "";
          this.lblBilledAmount = String(x?.iBilledAmount) ?? "";
          this.lblReceivedAmount = String(x?.iReceivedAmount) ?? "";
          this.lblPendingAmount = String(x?.iPendingAmount) ?? "";
          this.iAdjustmentAmount = String(x?.iAdjustmentAmount) ?? "";
          this.lblBillingStatus = (x?.sBillingStatus) ?? "";
          return x
        })).subscribe(x => {

        },
          (err) => {
            const data: HttpErrorResponse = err;
            // Swal.fire({
            //   icon: 'error',
            //   text: data?.error?.error?.message,
            // });
          })

    this.subscription$.push(result);
  }

  //GET Patient Billing Details
  getPatientBillingDetails() {
    if (this.patientBillingApiSubscription) {
      this.patientBillingApiSubscription.unsubscribe();
    }
    this.patientBillingApiSubscription = this.patientBalanceService
      .getBillingDetailsInPPByPatientId(this.patientId )
      .subscribe(
        (response) => {
          this.lblInsuranceBalance = response?.insuranceBalance || 0;
          this.lblPatientBalance = response?.patientBalance || 0;
          this.lblInsuranceRecoopAmount=response?.insuranceRecoopAmount || 0;

          this.lblTotalAmount = response?.totalBalance || 0;
        },
        (err) => {}
      );
  }
  //GET Aging Wise Details
  getPatientAgingWiseDetails() {
    if (this.patientAgingApiSubscription) {
      this.patientAgingApiSubscription.unsubscribe();
    }
    this.patientAgingApiSubscription = this.patientBalanceService
      .getAgingWiseAmountforPatientIdByPatientId(this.patientId )
      .subscribe(
        (response) => {
          this.lblThirtyDays = response?.thirtyDays || 0;
          this.lblSixtyDays = response?.sixtyDays || 0;
          this.lblNinetyDays = response?.ninetyDays || 0;
          this.lblAboveNinetyDays = response?.aboveNinetyDays || 0;
        },
        (err) => {}
      );
  }
  formatDateDob(dateString: string): string {
    const date = new Date(dateString);
    const utcDate = new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate());
    return utcDate.toLocaleDateString('en-US', { month: '2-digit', day: '2-digit', year: 'numeric' });
  }
}
