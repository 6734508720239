<header id="page-topbar">
  <div class="container-fluid">
    <div class="navbar-header">
        <div class="d-flex">
          <!-- LOGO -->
          <div class="navbar-brand-box">
            <a href="javascript:void(0);" class="logo logo-dark context-menu">
              <span class="logo-sm">
                <img src="assets/images/logo.svg" alt="" height="22">
              </span>
              <span class="logo-lg">
                <img src="assets/images/logo-dark.png" alt="" height="17">
              </span>
            </a>

            <a href="javascript:void(0);" class="logo logo-light context-menu">
              <span class="logo-sm">
                <img src="assets/images/logo-light.svg" alt="" height="22">
              </span>
              <span class="logo-lg">
                <!-- <img src="assets/images/logo-light.png" alt="" height="19"> -->
                <h1 class="my-3 text-primary "><b>Qsecure</b></h1>
              </span>
            </a>
          </div>

          <button type="button" class="btn btn-sm px-3 font-size-16 header-item" id="vertical-menu-btn"
            (click)="toggleMobileMenu($event)">
            <i class="fa fa-fw fa-bars"></i>
          </button>
          <!-- App Search-->
          <form class="app-search d-none d-lg-block">
            <div class="position-relative">
              <input type="text" class="form-control" placeholder="Search...">
              <span class="bx bx-search-alt"></span>
            </div>
          </form>

        </div>

        <div class="d-flex">

          <!-- <div class="dropdown d-inline-block" ngbDropdown> -->

          <div class="dropdown d-inline-block" ngbDropdown>
            <button type="button" class="btn header-item noti-icon" id="page-header-notifications-dropdown"
              ngbDropdownToggle>
              <i class="bx bx-bell bx-tada"></i>
              <span class="badge badge-danger badge-pill">3</span>
            </button>
            <div class="dropdown-menu dropdown-menu-lg dropdown-menu-right p-0" ngbDropdownMenu
              aria-labelledby="page-header-notifications-dropdown">
              <div class="p-3">
                <div class="row align-items-center">
                  <div class="col">
                    <h6 class="m-0"> Notifications </h6>
                  </div>
                  <div class="col-auto">
                    <a href="#!" class="small"> View All</a>
                  </div>
                </div>
              </div>
              <!-- <ngx-simplebar data-simplebar style="max-height: 230px;" class="h-100">
                <a href="" class="text-reset notification-item">
                  <div class="media">
                    <div class="avatar-xs mr-3">
                      <span class="avatar-title bg-primary rounded-circle font-size-16">
                        <i class="bx bx-cart"></i>
                      </span>
                    </div>
                    <div class="media-body">
                      <h6 class="mt-0 mb-1">Your order is placed</h6>
                      <div class="font-size-12 text-muted">
                        <p class="mb-1">If several languages coalesce the grammar
                        </p>
                        <p class="mb-0"><i class="mdi mdi-clock-outline"></i> 3 min
                          ago</p>
                      </div>
                    </div>
                  </div>
                </a>
                <a href="" class="text-reset notification-item">
                  <div class="media">
                    <img src="assets/images/users/avatar-1.jpg" class="mr-3 rounded-circle avatar-xs" alt="user-pic">
                    <div class="media-body">
                      <h6 class="mt-0 mb-1">James Lemire</h6>
                      <div class="font-size-12 text-muted">
                        <p class="mb-1">It will seem like simplified English.</p>
                        <p class="mb-0"><i class="mdi mdi-clock-outline"></i> 1
                          hours ago</p>
                      </div>
                    </div>
                  </div>
                </a>
                <a href="" class="text-reset notification-item">
                  <div class="media">
                    <div class="avatar-xs mr-3">
                      <span class="avatar-title bg-success rounded-circle font-size-16">
                        <i class="bx bx-badge-check"></i>
                      </span>
                    </div>
                    <div class="media-body">
                      <h6 class="mt-0 mb-1">Your item is shipped</h6>
                      <div class="font-size-12 text-muted">
                        <p class="mb-1">If several languages coalesce the grammar
                        </p>
                        <p class="mb-0"><i class="mdi mdi-clock-outline"></i> 3 min
                          ago</p>
                      </div>
                    </div>
                  </div>
                </a>

                <a href="" class="text-reset notification-item">
                  <div class="media">
                    <img src="assets/images/users/avatar-1.jpg" class="mr-3 rounded-circle avatar-xs" alt="user-pic">
                    <div class="media-body">
                      <h6 class="mt-0 mb-1">Salena Layfield</h6>
                      <div class="font-size-12 text-muted">
                        <p class="mb-1">As a skeptical Cambridge friend of mine
                          occidental.</p>
                        <p class="mb-0"><i class="mdi mdi-clock-outline"></i> 1
                          hours ago</p>
                      </div>
                    </div>
                  </div>
                </a>
              </ngx-simplebar> -->
              <div class="p-2 border-top">
                <a class="btn btn-sm btn-link font-size-14 btn-block text-center" href="javascript:void(0)">
                  <i class="mdi mdi-arrow-right-circle mr-1"></i> View More..
                </a>
              </div>
            </div>
          </div>
          <div class="dropdown d-inline-block" ngbDropdown>

            <button type="button" class="btn header-item" ngbDropdownToggle id="page-header-user-dropdown">

              <img class="rounded-circle header-profile-user" src="assets/images/users/avatar-1.jpg"
                alt="Header Avatar">
              <!-- <span class="d-none d-xl-inline-block ml-1">Henry</span> -->
              <!-- <i class="mdi mdi-chevron-down d-none d-xl-inline-block"></i> -->
            </button>
            <div class="dropdown-menu dropdown-menu-right" ngbDropdownMenu>

              <a class="dropdown-item text-danger cursor" (click)="logout()"><i
                  class="bx bx-power-off font-size-16 align-middle mr-1 text-danger"></i>
                Logout</a>
            </div>
          </div>

        </div>

    </div>
  </div>
</header>
