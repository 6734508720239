import { ListService, PagedAndSortedResultRequestDto } from '@abp/ng.core';
import { HttpErrorResponse } from '@angular/common/http';
import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatAccordion } from '@angular/material/expansion';

import { ActivatedRoute, Router } from '@angular/router';
import { NgbNav, NgbNavChangeEvent } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngxs/store';
import { SaleorderDTO } from 'projects/order/src/app/order-proxy/order-management/order/dto/models';
import { SaleorderService } from 'projects/order/src/app/order-proxy/order-management/order/saleorder.service';
import {
  ClinicalDTO,
  PatientDTO,
  PersonalDTO,
} from 'projects/order/src/app/order-proxy/order-management/rcm/patient-management/patient/dto';
import { getOrder } from 'projects/order/src/app/order-store/order.action';
import { ConfirmationDialogService } from 'projects/patient/src/app/confirmation-dialog.service';
import {
  RegionCitiesDTO,
  RegionCountriesDTO,
  RegionStatesDTO,
} from 'projects/patient/src/app/dropdown-proxy/dropdown-management/dropdowns/dto/models';
import {
  GenderDTO,
  PatientMasterDropdownDTO,
  PlaceOfServiceDTO,
  RelationShipWithoutSelfDTO,
  SuffixDTO,
} from 'projects/patient/src/app/dropdown-proxy/dropdown-management/dropdowns/dto/patient/models';
import { PatientMasterDropdownService } from 'projects/patient/src/app/dropdown-proxy/dropdown-management/dropdowns/master-dropdown-service/patient-master-dropdown.service';
import { PatientDropdowns } from 'projects/patient/src/app/dropdown-proxy/dropdown-management/dropdowns/patient-dropdowns.enum';
import { RegionDropdownService } from 'projects/patient/src/app/dropdown-proxy/dropdown-management/dropdowns/region-dropdown-service';
import { planInterface } from 'projects/patient/src/app/patient-add-policy/patient-add-policy.component';
import { PayerService } from 'projects/patient/src/app/patient-proxy/patient/payer.service';
import { PverifyPayersService } from 'projects/patient/src/app/patient-proxy/patient/pverify-payers.service';
import { getPatient } from 'projects/patient/src/app/patient-store/patient.action';
import { TableService } from 'projects/shared/src/app/table.service';
import { NumberValidators } from 'projects/shared/src/app/validations/number-validator';
import { Observable } from 'rxjs/internal/Observable';
import { map, startWith } from 'rxjs/operators';
import Swal from 'sweetalert2';

import {
  ClaimDTO,
  PostingChargesDTO,
} from '../billing-proxy/billing-management/posting/dto';
import {
  addBilling,
  getBilling,
  updateBilling,
} from '../billing-store/billing.action';
import { claimProcessMethod } from '../claim-proxy/claim-processing-management/rcm/billing-management/billing/claim-process-method.enum';
import {
  DiagnosisCode10DTO,
  DiagnosisCode9DTO,
} from 'projects/patient/src/app/dropdown-proxy/dropdown-management/dropdowns/dto/patient/models';
import { MasterProviderService } from 'projects/order/src/app/order-proxy/order-management/inventory/master-provider.service';
import {
  ClaimBatchProcessRequestDTO,
  ClaimDetailsDTO,
  InsuredDetailDTO,
  PatientDetailsDTO,
  PayerDetailsDTO,
  ProviderDetailsDTO,
  ReferringProviderDetailsDTO,
  RenderingingProviderDetailsDTO,
} from '../claim-proxy/claim-processing-management/rcm/claim-processing-management/x12/dto/models';
import { MasterProviderDTO } from 'projects/order/src/app/order-proxy/order-management/inventory/dto/models';
import { ClaimProcessService } from '../claim-proxy/claim-processing-management/rcm/claim-processing-management/x12';
import {
  defaultCountry,
  OrderBillingStatus,
} from 'projects/shared/src/app/enums/allenums.enum';
import { ToastrService } from "ngx-toastr";

@Component({
  selector: 'app-billing-create-edit',
  templateUrl: './billing-create-edit.component.html',
  styleUrls: ['./billing-create-edit.component.scss'],
  providers: [ListService],
})
export class BillingCreateEditComponent implements OnInit {
  @ViewChild('firstAccordion') firstAccordion: MatAccordion;
  @Output() saveClaimInfoFormState: EventEmitter<any> = new EventEmitter<any>();
  step: boolean = false;
  patientDetailsForm: FormGroup;
  savedClaimInfoData: any;
  savedPostingChargesData: any;
  @Input() claimOrderId: string = '00000000-0000-0000-0000-000000000000';
  claimForm: FormGroup;
  lblDefaultClaimId: string = '';
  lblDefaultSaleOrderId: string = '';

  postingForm: FormGroup;
  @Input() billingId: string = '';
  @Output() claimIdEmitter: EventEmitter<ClaimDTO> = new EventEmitter<ClaimDTO>(
    null
  );
  @Output() nextTabMoveOnSaveEmitter: EventEmitter<string> =
    new EventEmitter<string>(null);
  @ViewChild('nav') nav: NgbNav;
  defaultSaleOrderId: string = '';

  @Input() claimId: string = '00000000-0000-0000-0000-000000000000';
  @Input() orderId: string = '';
  // patientId: string = "";
  @Input() patientId = '';
  drpSuffixes: SuffixDTO[] = [];
  organizationUnitId = null;
  tenantId: string = '';
  patientName: string = '';
  patientDOB: string = '';
  defaultId: string = '';
  dos: string = '';
  tblPostReponse: any[] = [];
  listOrderId: string = '';
  listClaimId: string = '';
  listPatientId: string = '';
  currentOrderStatus: string = '';
  suffixName: string;
  gender: string;
  drpGenderLoop: GenderDTO[] = [];
  drpPlan: planInterface[] = [];
  drpProviders: any[] = [];
  phoneResCode: string = '';
  ResponsibleCountry: string = '';
  ResponsibleState: string = '';
  ResponsibleCity: string = '';
  Insurance: string;
  payor: string = '';
  BirthdateMaxDate: Date;
  BirthdateMinDate: Date;
  drpPlace: PlaceOfServiceDTO[] = [];
  relationships: string;
  provider: string = '';
  relationship: string;

  txtIcd9: string[] = [];
  txtIcd10: string[] = [];
  relationShipWithoutSelves: RelationShipWithoutSelfDTO[] = [];
  generalPlaceOfService: string;
  public filteredResponsibleCountries:
    | Observable<RegionCountriesDTO[]>
    | undefined;
  public filteredResponsibleStates: Observable<RegionStatesDTO[]> | undefined;
  public filteredResponsibleCities: Observable<RegionCitiesDTO[]> | undefined;

  filterPayor:
    | Observable<
      { id: string; name: string; payerCode: string; payerName: string }[]
    >
    | undefined;

  drpPayerId: {
    id: string;
    name: string;
    payerCode: string;
    payerName: any;
  }[] = [];
  public filteredInsuranceTypes: Observable<planInterface[]> | undefined;

  drpDiagnosisICD9: DiagnosisCode9DTO[];
  drpDiagnosisICD10: DiagnosisCode10DTO[];
  public filteredIcd9Types: Observable<any[]>;
  public filteredIcd10Types: Observable<any[]>;
  orderStatus: boolean = true;

  constructor(
    private Table: TableService,
    private formBuilder: FormBuilder,
    private number: NumberValidators,
    private confirmationDialogService: ConfirmationDialogService,
    public store: Store,
    private activatedRoute: ActivatedRoute,
    private region: RegionDropdownService,
    private saleOrderService: SaleorderService,
    private patientDropdownService: PatientMasterDropdownService,
    private payerService: PayerService,
    private planService: PverifyPayersService,
    private providerList: MasterProviderService,
    public list: ListService,
    private claimProcessService: ClaimProcessService,
    private router: Router,
    private toastr: ToastrService,
  ) { }
  ngOnInit(): void {
    this.activatedRoute.paramMap.subscribe(
      (response) => {
        this.orderId = response?.get('id') ?? '';
        if (this.orderId !== '') {
          this.saleOrderService.get(this.orderId).subscribe(
            (Res) => {
              const orderResp: SaleorderDTO = Res;
              this.patientId = orderResp?.patientId ?? '';
              this.lblDefaultSaleOrderId = orderResp?.saleorderId ?? '';
              this.lblDefaultClaimId = orderResp?.orderBilling?.defaultBillingId ?? '';
              orderResp.claimId =
                orderResp?.claimId === ''
                  ? '00000000-0000-0000-0000-000000000000'
                  : orderResp?.claimId ??
                  '00000000-0000-0000-0000-000000000000';
              this.claimId =
                orderResp?.claimId ?? '00000000-0000-0000-0000-000000000000';
              this.claimId != '00000000-0000-0000-0000-000000000000' &&
                this.getPostingTable();
              if (
                this.claimId &&
                this.claimId !== '00000000-0000-0000-0000-000000000000'
              ) {
                this.currentOrderStatusShow(this.claimId);
              } else {
                this.currentOrderStatus = orderResp?.orderBilling?.status;
                const orderBillstatus: [string, OrderBillingStatus][] =
                  Object.entries(OrderBillingStatus);
                this.orderStatus = orderBillstatus?.some(
                  (s) => (s[1] ?? '') === orderResp?.orderBilling?.status
                );
              }
              this.patientId !== '' &&
                this.getDetailsByPatientID(this.patientId);
            },
            (err) => {
              const data: HttpErrorResponse = err;
              Swal.fire({ icon: 'info', text: data?.error?.error?.message });
            }
          );
        }
        // get current id for current status
        // this.Table.getBillingData().subscribe(value => {
        //   value=value??"00000000-0000-0000-0000-000000000000";
        //   this.claimId = value!==""? value :  "00000000-0000-0000-0000-000000000000";
        //   setTimeout(() => {

        //   }, 400);
        // }, err => {
        //   const data: HttpErrorResponse = err;
        //   Swal.fire({
        //     icon: 'error',
        //     text: data?.error?.error?.message,
        //   });
        // });
        this.Table.setSelectedOrderId(this.orderId);
      },
      (err) => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      }
    );

    // this.Table.getBillingClaimListDataTable().subscribe(val => {
    //   this.orderId = val[0].orderId;
    // });
    this.createPatientForm();
    // this.Table.getOrderId().subscribe(val => {
    //   if (val?.data) { this.listOrderId = val?.data; }
    //   if (val?.claimId) { this.claimId = val?.claimId??"00000000-0000-0000-0000-000000000000"; }
    //   if (val?.pat) { this.listPatientId = val?.pat; }

    // }, err => {
    //   const data: HttpErrorResponse = err;
    //   Swal.fire({
    //     icon: 'error',
    //     text: data?.error?.error?.message,
    //   });
    // });

    // this.Table.getOrderId().subscribe(val => {

    //   this.orderId = val?.data;
    //   this.defaultSaleOrderId = val?.saleOrderId;
    //   this.patientId = val?.patientGuId;
    //   this.claimOrderId = val?.claimId;
    if (
      this.orderId &&
      this.orderId !== '' &&
      this.orderId !== null &&
      this.orderId !== undefined
    ) {
      this.doctorDropdown();
    }
    // }, err => {
    //   const data: HttpErrorResponse = err;
    //   Swal.fire({
    //     icon: 'error',
    //     text: data?.error?.error?.message,
    //   });
    // });

    if (
      this.claimOrderId &&
      this.claimOrderId !== '' &&
      this.claimOrderId !== null &&
      this.claimOrderId !== undefined &&
      this.claimOrderId !== '00000000-0000-0000-0000-000000000000'
    ) {
      this.getClaimById();
    }
    this.patientDetailsForm = this.formBuilder.group({
      txtFirstName: new FormControl(''),
      txtMiddleName: new FormControl(''),
      txtLastName: new FormControl(''),
      drpSuffix: new FormControl(''),
      drpGender: new FormControl(''),
      drpCountry: new FormControl(''),
      txtState: new FormControl(''),
      drpCounty: new FormControl(''),
      txtCity: new FormControl(''),
      txtBillingPostalCode: new FormControl(''),
      txtBillingPhone: new FormControl(''),
      txtAddress: new FormControl(''),
      txtAddress2: new FormControl(''),
      txtSSN: new FormControl(''),
      txtResponsibleCity: new FormControl(null),
      txtResponsibleState: new FormControl(null),
      txtResponsibleCountry: new FormControl(null),
      txtResponsibleCountryFilter: new FormControl(''),
      txtResponsibleStateFilter: new FormControl(''),
      txtResponsibleCityFilter: new FormControl(''),
      txtPayerName: new FormControl(''),
      txtPayorFilter: new FormControl(''),
      drpPayorName: new FormControl(''),
      drpInsurance: new FormControl(''),
      txtInsuranceFilter: new FormControl(''),
      txtPolicyInfoPolicyNo: new FormControl(''),
      txtNote: new FormControl(''),
      txtProvider: new FormControl(''),
      drpDiag9: new FormControl(''),
      txtIcd9Filter: new FormControl(''),
      drpDiag10ID: new FormControl(''),
      txtIcd10Filter: new FormControl(''),
      txtBirthdate: new FormControl(''),
      drpEmergencyRelationshipType: new FormControl(''),
      drpResponsibleRelationshipType: new FormControl(''),
      drpGeneralPlaceOfService: new FormControl(''),
      txtdiagnosisCode10Id: new FormControl(''),
    });
    this.getdropdowns();
    this.resCountryDropdown();
    this.getProviderDropdown();
    this.planDropdown();
    this.getPayerDropdown();
    if (
      this.claimOrderId &&
      this.claimOrderId !== '' &&
      this.claimOrderId !== null &&
      this.claimOrderId !== undefined &&
      this.claimOrderId !== '00000000-0000-0000-0000-000000000000'
    ) {
      // this.getBillingById();
    }

    this.BirthdateMinDate = new Date();
    this.BirthdateMinDate.setFullYear(
      this.BirthdateMinDate.getFullYear() - 120
    );
  }

  doctorDropdown(): void {
    if (
      this.orderId !== '' &&
      this.orderId !== null &&
      this.orderId !== undefined
    ) {
      this.store.dispatch(new getOrder(this.orderId)).subscribe(
        (response) => {
          const salectedOrders: SaleorderDTO = response?.orderState?.selectedOrder ?? null;
          const selectedPatient: ClinicalDTO = salectedOrders?.patients?.clinicals ?? null;
          if (selectedPatient) {
            this.claimForm.patchValue({
              drpReferringProvider:
                selectedPatient?.referringProviderDoctor === ''
                  ? null
                  : selectedPatient?.referringProviderDoctor,
              drpOrderingProvider:
                selectedPatient?.orderingDoctor === ''
                  ? null
                  : selectedPatient?.orderingDoctor,
              txtDiagnosisCodes9: (((selectedPatient?.diagnosisCode9?.length ?? 0) === 0) ? null : selectedPatient?.diagnosisCode9),
              txtdiagnosisCode10id: (((selectedPatient?.diagnosisCode10?.length ?? 0) === 0) ? null : selectedPatient?.diagnosisCode10),
              txtdiagnosisCode10Id2: (((selectedPatient?.diagnosisCode10?.length ?? 0) === 0) ? null : selectedPatient?.diagnosisCode10),
              txtdiagnosisCode10Id3: (((selectedPatient?.diagnosisCode10?.length ?? 0) === 0) ? null : selectedPatient?.diagnosisCode10),
            });
          }
        },
        (err) => {
          const data: HttpErrorResponse = err;
          Swal.fire({
            icon: 'info',
            text: data?.error?.error?.message,
          });
        }
      );
    }
  }

  // current order status show
  currentOrderStatusShow(claimId: string): void {
    claimId != null &&
      claimId != '' &&
      this.store.dispatch(new getBilling(claimId)).subscribe((response) => {
        this.currentOrderStatus =
          response?.billingState?.selectedBilling?.claimStatus;
      });
  }

  currentOrderStatuses() {
    this.orderId != null &&
      this.orderId != '' &&
      this.store
        .dispatch(new getOrder(this.orderId))
        .subscribe((response) => { });
  }
  changeNextTab(value: string) {
    value === 'claimInfo' && this.nav?.select('Posting');
    value === 'Posting' && this.nav?.select('patientVisit');
  }
  createPatientForm() {
    this.claimForm = this.formBuilder.group({
      drpReferringProvider: new FormControl(null),
      drpPcp: new FormControl(null),
      drpOrderingProvider: new FormControl(null),
      drpSupervisingProv: new FormControl(null),
      txtReferralDate: new FormControl(''),
      drpPcpLast: new FormControl(null),
      txtDiagnosisCodes9: new FormControl(''),
      txtdiagnosisCode10Id: new FormControl(''),
      drpCurrent: new FormControl(null),
      drpSimilar: new FormControl(null),
      drpMenstrual: new FormControl(null),
      txtInjury: new FormControl(''),
      drpFirstTreatment: new FormControl(null),
      chkPregnant: new FormControl(''),
      txtConsultDate: new FormControl(''),
      drpLastXray: new FormControl(null),
      drpXrayMaintaine: new FormControl(null),
      txtEstimatedDob: new FormControl(''),
      drpDisabledFrom: new FormControl(null),
      drpDisabledTo: new FormControl(null),
      drpManifest: new FormControl(null),
      drpHospitalizedFrom: new FormControl(null),
      drpHospitalizedTo: new FormControl(null),
      drpLastWorked: new FormControl(null),
      drpReturnToWork: new FormControl(null),
      drpPatCondn: new FormControl(null),
      txtAuth: new FormControl(''),
      txtCondn1: new FormControl(''),
      txtBlock10d: new FormControl(''),
      txtCondn2: new FormControl(''),
      drpBlock19: new FormControl(null),
      chkAutoAccident: new FormControl(''),
      chkAutoAccidentState: new FormControl(''),
      chkOtherAccident: new FormControl(''),
      chkEmployment: new FormControl(''),
      // rbtnClaim: new FormControl(""),
      txtIcd10Filter: new FormControl(null),
      txtIcd9Filter: new FormControl(null),
      drpResponsibleRelationshipType: new FormControl(''),
    });

    this.postingForm = this.formBuilder.group({
      drpDiag9: new FormControl([]),
      drpDiag10ID: new FormControl([]),
      drpCode: new FormControl('', [Validators.required]),
      txtDescription: new FormControl('', [Validators.required]),
      txtProvider: new FormControl(''),
      txtCheckNo: new FormControl('', [this.number.isNumberCheck]),
      drpModifiers1: new FormControl(''),
      drpModifiers2: new FormControl(''),
      drpModifiers3: new FormControl(''),
      drpModifiers4: new FormControl(''),
      drpDx1: new FormControl(''),
      drpDx2: new FormControl(''),
      drpDx3: new FormControl(''),
      drpDx4: new FormControl(''),
      drpPos: new FormControl('', [Validators.required]),
      drpTos: new FormControl(''),
      txtDateFrom: new FormControl(''),
      txtDateTo: new FormControl(''),
      txtFee: new FormControl('', [Validators.required]),
      txtUnits: new FormControl('', [
        Validators.required,
        this.number.isNumberCheck,
      ]),
      txtCopay: new FormControl(''),
      txtOther: new FormControl(''),
      txtTotal: new FormControl('', [
        Validators.required,
        this.number.isNumberCheck,
      ]),
      txtDocument: new FormControl(''),
      txtStatement: new FormControl(''),
      txtPatientResp: new FormControl(''),
      txtIcd10Filter: new FormControl(null),
      txtIcd9Filter: new FormControl(null),
    });

    // this.Table.getBillingClaimListDataTable().subscribe(val => {
    //   this.orderId = val.data;
    // }, err => {
    //   const data: HttpErrorResponse = err;
    //   Swal.fire({
    //     icon: 'error',
    //     text: data?.error?.error?.message,
    //   });
    // });
  }

  onSaveClaimInfoData(value: any) {
    this.savedClaimInfoData = value;
  }
  onSavePostingChargesData(value: any) {
    this.savedPostingChargesData = value;
  }
  beforeUnloadHander(event: any) {
    // this.PostCall();
    this.getConfirmationDialog(event);
    // return event.returnValue = "something..."
  }
  getConfirmationDialog(event: any) {
    event.returnValue = 'something....';
    if (
      (this.savedClaimInfoData && this.savedClaimInfoData.dirty) ||
      (this.savedPostingChargesData && this.savedPostingChargesData.dirty)
    ) {
      this.confirmationDialogService
        .confirm('Please confirm..', 'Do you want to save the changes ... ?')
        .then((confirmed) => {
          if (
            confirmed == true &&
            this.savedClaimInfoData &&
            this.savedClaimInfoData.dirty
          ) {
            this.saveClaimForm();
          } else {
            this.claimForm.reset();
          }
          if (
            confirmed == true &&
            this.savedPostingChargesData &&
            this.savedPostingChargesData.dirty
          ) {
            this.savePostingForm();
          } else {
            this.postingForm.reset();
          }
        })
        .catch((e) => e);
    }
  }
  showswal = true;
  onNavChange($event: NgbNavChangeEvent) {
    if (
      this.savedClaimInfoData &&
      this.showswal &&
      this.savedClaimInfoData.dirty
    ) {
      if ($event.nextId === 'Posting' || $event.nextId === 'patientVisit') {
        Swal.fire({
          title: 'Changes Has Been Done?',
          text: 'Would You Like To Save The Changes!',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#34c38f',
          cancelButtonColor: '#f46a6a',
          confirmButtonText: 'Yes!',
          cancelButtonText: 'No',
          allowOutsideClick: false,
        }).then((result) => {
          if (result.value) {
            this.showswal = false;
            this.saveClaimForm();
          } else {
            this.showswal = false;
            this.savedClaimInfoData = [];
          }
        });
      }
      this.showswal = true;
    }

    this.showswal = true;
    if (
      this.savedPostingChargesData &&
      this.showswal &&
      this.savedPostingChargesData.dirty
    ) {
      if ($event.nextId === 'claimInfo' || $event.nextId === 'patientVisit') {
        Swal.fire({
          title: 'Changes Has Been Done?',
          text: 'Would You Like To Save The Changes!',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#34c38f',
          cancelButtonColor: '#f46a6a',
          confirmButtonText: 'Yes!',
          cancelButtonText: 'No',
          allowOutsideClick: false,
        }).then((result) => {
          if (result.value) {
            this.showswal = false;
            this.savePostingForm();
          } else {
            this.showswal = false;
            this.savedPostingChargesData = [];
          }
        });
      }
      this.showswal = true;
    }
  }
  saveClaimForm() {
    if (this.savedClaimInfoData && this.savedClaimInfoData) {
      this.claimForm.patchValue({
        drpReferringProvider:
          this.savedClaimInfoData.controls.drpReferringProvider.value,
        drpPcp: this.savedClaimInfoData.controls.drpPcp.value,
        drpOrderingProvider:
          this.savedClaimInfoData.controls.drpOrderingProvider.value,
        drpSupervisingProv:
          this.savedClaimInfoData.controls.drpSupervisingProv.value,
        txtReferralDate:
          this.savedClaimInfoData.controls.txtReferralDate.value,
        drpPcpLast: this.savedClaimInfoData.controls.drpPcpLast.value,
        txtDiagnosisCodes9:
          this.savedClaimInfoData.controls.txtDiagnosisCodes9.value,
        txtdiagnosisCode10Id:
          this.savedClaimInfoData.controls.txtdiagnosisCode10Id.value,
        // txtdiagnosisCode10Id2: this.savedClaimInfoData['controls'].txtdiagnosisCode10Id2.value,
        // txtdiagnosisCode10Id3: this.savedClaimInfoData['controls'].txtdiagnosisCode10Id3.value,
        drpCurrent: this.savedClaimInfoData.controls.drpCurrent.value,
        drpSimilar: this.savedClaimInfoData.controls.drpSimilar.value,
        drpMenstrual: this.savedClaimInfoData.controls.drpMenstrual.value,
        txtInjury: this.savedClaimInfoData.controls.txtInjury.value,
        drpFirstTreatment:
          this.savedClaimInfoData.controls.drpFirstTreatment.value,
        chkPregnant: this.savedClaimInfoData.controls.chkPregnant.value,
        txtConsultDate:
          this.savedClaimInfoData.controls.txtConsultDate.value,
        drpLastXray: this.savedClaimInfoData.controls.drpLastXray.value,
        drpXrayMaintaine:
          this.savedClaimInfoData.controls.drpXrayMaintaine.value,
        txtEstimatedDob:
          this.savedClaimInfoData.controls.txtEstimatedDob.value,
        drpDisabledFrom:
          this.savedClaimInfoData.controls.drpDisabledFrom.value,
        drpDisabledTo: this.savedClaimInfoData.controls.drpDisabledTo.value,
        drpManifest: this.savedClaimInfoData.controls.drpManifest.value,
        drpHospitalizedFrom:
          this.savedClaimInfoData.controls.drpHospitalizedFrom.value,
        drpHospitalizedTo:
          this.savedClaimInfoData.controls.drpHospitalizedTo.value,
        drpLastWorked: this.savedClaimInfoData.controls.drpLastWorked.value,
        drpReturnToWork:
          this.savedClaimInfoData.controls.drpReturnToWork.value,
        drpPatCondn: this.savedClaimInfoData.controls.drpPatCondn.value,
        txtAuth: this.savedClaimInfoData.controls.txtAuth.value,
        txtCondn1: this.savedClaimInfoData.controls.txtCondn1.value,
        txtBlock10d: this.savedClaimInfoData.controls.txtBlock10d.value,
        txtCondn2: this.savedClaimInfoData.controls.txtCondn2.value,
        drpBlock19: this.savedClaimInfoData.controls.drpBlock19.value,
        chkAutoAccident:
          this.savedClaimInfoData.controls.chkAutoAccident.value,
        chkAutoAccidentState:
          this.savedClaimInfoData.controls.chkAutoAccidentState.value,
        chkOtherAccident:
          this.savedClaimInfoData.controls.chkOtherAccident.value,
        chkEmployment: this.savedClaimInfoData.controls.chkEmployment.value,
      });
    }
    this.showswal = false;
    this.saveDefaultClaimInfo();
    this.savedClaimInfoData = [];
  }
  savePostingForm() {
    if (this.savedPostingChargesData && this.savedPostingChargesData) {
      this.postingForm.patchValue({
        drpDiag9: this.savedPostingChargesData.controls.drpDiag9.value,
        drpDiag10ID: this.savedPostingChargesData.controls.drpDiag10ID.value,
        // drpDiag10ID2: this.savedPostingChargesData['controls'].drpDiag10ID2.value,
        // drpDiag10ID3: this.savedPostingChargesData['controls'].drpDiag10ID3.value,
        drpCode: this.savedPostingChargesData.controls.drpCode.value,
        txtDescription:
          this.savedPostingChargesData.controls.txtDescription.value,
        txtProvider: this.savedPostingChargesData.controls.txtProvider.value,
        txtCheckNo: this.savedPostingChargesData.controls.txtCheckNo.value,
        drpModifiers1:
          this.savedPostingChargesData.controls.drpModifiers1.value,
        drpModifiers2:
          this.savedPostingChargesData.controls.drpModifiers2.value,
        drpModifiers3:
          this.savedPostingChargesData.controls.drpModifiers3.value,
        drpModifiers4:
          this.savedPostingChargesData.controls.drpModifiers4.value,
        drpDx1: this.savedPostingChargesData.controls.drpDx1.value,
        drpDx2: this.savedPostingChargesData.controls.drpDx2.value,
        drpDx3: this.savedPostingChargesData.controls.drpDx3.value,
        drpDx4: this.savedPostingChargesData.controls.drpDx4.value,
        drpPos: this.savedPostingChargesData.controls.drpPos.value,
        drpTos: this.savedPostingChargesData.controls.drpTos.value,
        txtDateFrom: this.savedPostingChargesData.controls.txtDateFrom.value,
        txtDateTo: this.savedPostingChargesData.controls.txtDateTo.value,
        txtFee: this.savedPostingChargesData.controls.txtFee.value,
        txtUnits: this.savedPostingChargesData.controls.txtUnits.value,
        txtCopay: this.savedPostingChargesData.controls.txtCopay.value,
        txtOther: this.savedPostingChargesData.controls.txtOther.value,
        txtTotal: this.savedPostingChargesData.controls.txtTotal.value,
        txtStatement:
          this.savedPostingChargesData.controls.txtStatement.value,
        txtPatientResp:
          this.savedPostingChargesData.controls.txtPatientResp.value,
      });
    }
    this.showswal = false;
    this.getPatientDetailsForPostingSave();
    this.savedClaimInfoData = [];
  }
  getPatientDetailsForPostingSave() {
    let claimDetails: ClaimDTO;
    this.store.dispatch(new getBilling(this.claimId)).subscribe(
      (response) => {
        claimDetails = response?.billingState.selectedBilling;
        this.savePosting(claimDetails);
      },
      (err) => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      },
      () => { }
    );
  }
  saveDefaultClaimInfo() {
    // this.organizationUnitId = localStorage.getItem('initialOrganizationUnitId');
    this.tenantId = localStorage.getItem('tenantId');
    if (
      this.claimOrderId ||
      this.claimOrderId == null ||
      this.claimOrderId == undefined ||
      this.claimOrderId == '00000000-0000-0000-0000-000000000000'
    ) {
      if (
        this.orderId !== '' &&
        this.orderId !== null &&
        this.orderId !== undefined
      ) {
        this.store.dispatch(new getOrder(this.orderId)).subscribe(
          (response) => {
            const selectedOrder: SaleorderDTO =
              response?.orderState?.selectedOrder;
            const claimInfoData: ClaimDTO = {
              lstClaimInfo: [
                {
                  referringProvider:
                    this.claimForm.value.drpReferringProvider === null ||
                      undefined
                      ? ''
                      : this.claimForm.value.drpReferringProvider,
                  pcp:
                    this.claimForm.value.drpPcp === null || undefined
                      ? ''
                      : this.claimForm.value.drpPcp,
                  orderingProvider:
                    this.claimForm.value.drpOrderingProvider === null ||
                      undefined
                      ? ''
                      : this.claimForm.value.drpOrderingProvider,
                  supervisingProv:
                    this.claimForm.value.drpSupervisingProv === null ||
                      undefined
                      ? ''
                      : this.claimForm.value.drpSupervisingProv,
                  referralDate: this.claimForm.value.txtReferralDate,
                  pcpLastSeen:
                    this.claimForm.value.drpPcpLast === null || undefined
                      ? ''
                      : this.claimForm.value.drpPcpLast,
                  diagnosisCode9:
                    this.claimForm.value.txtDiagnosisCodes9 === ''
                      ? []
                      : this.claimForm.value.txtDiagnosisCodes9,
                  diagnosisCode10: this.claimForm.value.txtdiagnosisCode10,
                  currentIllness:
                    this.claimForm.value.drpCurrent === null || undefined
                      ? ''
                      : this.claimForm.value.drpCurrent,
                  similarIllness:
                    this.claimForm.value.drpSimilar === null || undefined
                      ? ''
                      : this.claimForm.value.drpSimilar,
                  lastMenstrual:
                    this.claimForm.value.drpMenstrual === null || undefined
                      ? ''
                      : this.claimForm.value.drpMenstrual,
                  injuryDate: this.claimForm.value.txtInjury,
                  firstTreatment:
                    this.claimForm.value.drpFirstTreatment === null || undefined
                      ? ''
                      : this.claimForm.value.drpFirstTreatment,
                  consultDate: this.claimForm.value.txtConsultDate,
                  lastXray:
                    this.claimForm.value.drpLastXray === null || undefined
                      ? ''
                      : this.claimForm.value.drpLastXray,
                  estimatedDob: this.claimForm.value.txtEstimatedDob,
                  disabledFrom:
                    this.claimForm.value.drpDisabledFrom === null || undefined
                      ? ''
                      : this.claimForm.value.drpDisabledFrom,
                  disabledTo:
                    this.claimForm.value.drpDisabledTo === null || undefined
                      ? ''
                      : this.claimForm.value.drpDisabledTo,
                  acuteManifest:
                    this.claimForm.value.drpManifest === null || undefined
                      ? ''
                      : this.claimForm.value.drpManifest,
                  hospitalizedFrom:
                    this.claimForm.value.drpHospitalizedFrom === null ||
                      undefined
                      ? ''
                      : this.claimForm.value.drpHospitalizedFrom,
                  hospitalizedTo:
                    this.claimForm.value.drpHospitalizedTo === null || undefined
                      ? ''
                      : this.claimForm.value.drpHospitalizedTo,
                  xraysMaintained:
                    this.claimForm.value.drpXrayMaintaine === null || undefined
                      ? ''
                      : this.claimForm.value.drpXrayMaintaine,
                  lastWorked:
                    this.claimForm.value.drpLastWorked === null || undefined
                      ? ''
                      : this.claimForm.value.drpLastWorked,
                  returnToWork:
                    this.claimForm.value.drpReturnToWork === null || undefined
                      ? ''
                      : this.claimForm.value.drpReturnToWork,
                  patConditionCode:
                    this.claimForm.value.drpPcp === null || undefined
                      ? ''
                      : this.claimForm.value.drpPcp,
                  auth: this.claimForm.value.txtAuth,
                  patConditionCode1: this.claimForm.value.txtCondn1,
                  block10d: this.claimForm.value.txtBlock10d,
                  patConditionCode2: this.claimForm.value.txtCondn2,
                  block19:
                    this.claimForm.value.drpBlock19 === null || undefined
                      ? ''
                      : this.claimForm.value.drpBlock19,
                  isPregnant: +this.claimForm.value.chkPregnant,
                  isAutoAccident: +this.claimForm.value.chkAutoAccident,
                  isAutoAccidentState:
                    +this.claimForm.value.chkAutoAccidentState,
                  isOtherAccident: +this.claimForm.value.chkOtherAccident,
                  isEmploymentRelated: +this.claimForm.value.chkEmployment,
                },
              ],
              lstPostingCharges: selectedOrder[0],
              // saleOrder:null,
              insurancePayments: {
                primaryInsurancePaymentDetails: null,
                secondaryInsurancePaymentDetails: null,
                tertiaryInsurancePaymentDetails: null,
              },
              saleOrder: selectedOrder,
              patientId: this.patientId,
              orderId: this.orderId,

              saleorderId: this.defaultSaleOrderId,
              defaultPatientId: this.defaultId,
              patientName: this.patientName,
              patientDob: this.patientDOB,
              readyCount: 0,
              processedCount: 0,
              crossOverCount: 0,
              holdCount: 0,
              patientResponsibilityCount: 0,
              patientResponsibilityAfterInsCount: 0,
              claimId: this.claimOrderId,
              lstClearingHouseUpdates: [],
              totalPatientBal: 0,
              lstPatientVisitNotes: [],
              totalBilled: 0,
              totalInsBal: 0,
              lstReminders: null,
              isVoided: false,
              claimProcessMethod: claimProcessMethod?.Manual,
            };
            this.saveClaimInfo(claimInfoData);
          },
          (err) => {
            const data: HttpErrorResponse = err;
            Swal.fire({
              icon: 'info',
              text: data?.error?.error?.message,
            });
          }
        );
      }
    } else {
      this.claimOrderId &&
        this.claimOrderId !== '' &&
        this.claimOrderId !== null &&
        this.claimOrderId !== undefined &&
        this.claimOrderId !== '00000000-0000-0000-0000-000000000000' &&
        this.store.dispatch(new getBilling(this.claimOrderId)).subscribe(
          (response) => {
            const selectedBill: ClaimDTO = response?.billingState.selectedBilling;
            const claimInfoData: ClaimDTO = {
              lstClaimInfo: [
                {
                  referringProvider:
                    this.claimForm.value.drpReferringProvider === null ||
                      undefined
                      ? ''
                      : this.claimForm.value.drpReferringProvider,
                  pcp:
                    this.claimForm.value.drpPcp === null || undefined
                      ? ''
                      : this.claimForm.value.drpPcp,
                  orderingProvider:
                    this.claimForm.value.drpOrderingProvider === null ||
                      undefined
                      ? ''
                      : this.claimForm.value.drpOrderingProvider,
                  supervisingProv:
                    this.claimForm.value.drpSupervisingProv === null ||
                      undefined
                      ? ''
                      : this.claimForm.value.drpSupervisingProv,
                  referralDate: this.claimForm.value.txtReferralDate,
                  pcpLastSeen:
                    this.claimForm.value.drpPcpLast === null || undefined
                      ? ''
                      : this.claimForm.value.drpPcpLast,
                  diagnosisCode9:
                    this.claimForm.value.txtDiagnosisCodes9 === ''
                      ? []
                      : this.claimForm.value.txtDiagnosisCodes9,
                  diagnosisCode10: this.claimForm.value.txtdiagnosisCode10,
                  currentIllness:
                    this.claimForm.value.drpCurrent === null || undefined
                      ? ''
                      : this.claimForm.value.drpCurrent,
                  similarIllness:
                    this.claimForm.value.drpSimilar === null || undefined
                      ? ''
                      : this.claimForm.value.drpSimilar,
                  lastMenstrual:
                    this.claimForm.value.drpMenstrual === null || undefined
                      ? ''
                      : this.claimForm.value.drpMenstrual,
                  injuryDate: this.claimForm.value.txtInjury,
                  firstTreatment:
                    this.claimForm.value.drpFirstTreatment === null || undefined
                      ? ''
                      : this.claimForm.value.drpFirstTreatment,
                  consultDate: this.claimForm.value.txtConsultDate,
                  lastXray:
                    this.claimForm.value.drpLastXray === null || undefined
                      ? ''
                      : this.claimForm.value.drpLastXray,
                  estimatedDob: this.claimForm.value.txtEstimatedDob,
                  disabledFrom:
                    this.claimForm.value.drpDisabledFrom === null || undefined
                      ? ''
                      : this.claimForm.value.drpDisabledFrom,
                  disabledTo:
                    this.claimForm.value.drpDisabledTo === null || undefined
                      ? ''
                      : this.claimForm.value.drpDisabledTo,
                  acuteManifest:
                    this.claimForm.value.drpManifest === null || undefined
                      ? ''
                      : this.claimForm.value.drpManifest,
                  hospitalizedFrom:
                    this.claimForm.value.drpHospitalizedFrom === null ||
                      undefined
                      ? ''
                      : this.claimForm.value.drpHospitalizedFrom,
                  hospitalizedTo:
                    this.claimForm.value.drpHospitalizedTo === null || undefined
                      ? ''
                      : this.claimForm.value.drpHospitalizedTo,
                  xraysMaintained:
                    this.claimForm.value.drpXrayMaintaine === null || undefined
                      ? ''
                      : this.claimForm.value.drpXrayMaintaine,
                  lastWorked:
                    this.claimForm.value.drpLastWorked === null || undefined
                      ? ''
                      : this.claimForm.value.drpLastWorked,
                  returnToWork:
                    this.claimForm.value.drpReturnToWork === null || undefined
                      ? ''
                      : this.claimForm.value.drpReturnToWork,
                  patConditionCode:
                    this.claimForm.value.drpPcp === null || undefined
                      ? ''
                      : this.claimForm.value.drpPcp,
                  auth: this.claimForm.value.txtAuth,
                  patConditionCode1: this.claimForm.value.txtCondn1,
                  block10d: this.claimForm.value.txtBlock10d,
                  patConditionCode2: this.claimForm.value.txtCondn2,
                  block19:
                    this.claimForm.value.drpBlock19 === null || undefined
                      ? ''
                      : this.claimForm.value.drpBlock19,
                  isPregnant: +this.claimForm.value.chkPregnant,
                  isAutoAccident: +this.claimForm.value.chkAutoAccident,
                  isAutoAccidentState:
                    +this.claimForm.value.chkAutoAccidentState,
                  isOtherAccident: +this.claimForm.value.chkOtherAccident,
                  isEmploymentRelated: +this.claimForm.value.chkEmployment,
                },
              ],
              lstPostingCharges: selectedBill?.lstPostingCharges,
              insurancePayments: {
                primaryInsurancePaymentDetails: null,
                secondaryInsurancePaymentDetails: null,
                tertiaryInsurancePaymentDetails: null,
              },
              saleOrder: selectedBill?.saleOrder,
              patientId: selectedBill?.patientId,
              orderId: selectedBill?.orderId,
              saleorderId: selectedBill?.saleorderId,
              defaultPatientId: selectedBill?.defaultPatientId,
              patientName: selectedBill?.patientName,
              patientDob: selectedBill?.patientDob,
              readyCount: 0,
              processedCount: 0,
              crossOverCount: 0,
              holdCount: 0,
              patientResponsibilityCount: 0,
              patientResponsibilityAfterInsCount: 0,
              claimId: selectedBill?.claimId,
              lstClearingHouseUpdates: selectedBill?.lstClearingHouseUpdates,
              totalPatientBal: selectedBill?.totalPatientBal,
              totalBilled: selectedBill?.totalBilled,
              totalInsBal: selectedBill?.totalInsBal,
              lstPatientVisitNotes: selectedBill?.lstPatientVisitNotes,
              lstReminders: selectedBill?.lstReminders,
              isVoided: selectedBill?.isVoided,
              claimProcessMethod: selectedBill?.claimProcessMethod,
            };
            this.saveClaimInfo(claimInfoData);
          },
          (err) => {
            const data: HttpErrorResponse = err;
            Swal.fire({
              icon: 'info',
              text: data?.error?.error?.message,
            });
          }
        );
    }
  }
  saveClaimInfo(claimInfoData: ClaimDTO) {
    if (
      this.claimOrderId != '' &&
      this.claimOrderId != null &&
      this.claimOrderId != undefined &&
      this.claimOrderId != '00000000-0000-0000-0000-000000000000'
    ) {
      this.store
        .dispatch(new updateBilling(this.claimOrderId, claimInfoData))
        .subscribe(
          (value) => {
            // Swal.fire({
            //   title: 'Success',
            //   html: 'Saved Successfully',
            //   icon: 'success',
            //   timer: 3000,
            //   timerProgressBar: true,
            // });
            this.toastr.success('Saved Successfully','Success')
            const claimDetail = value.billingState.selectedBilling;
            this.claimIdEmitter.emit(claimDetail);
            this.saveClaimInfoFormState.emit(this.claimForm.value);
            this.nextTabMoveOnSaveEmitter.emit('claimInfo');
          },
          (err) => {
            const data: HttpErrorResponse = err;
            Swal.fire({
              icon: 'info',
              text: data?.error?.error?.message,
            });
          }
        );
    } else {
      this.store.dispatch(new addBilling(claimInfoData)).subscribe(
        (value) => {
          // Swal.fire({
          //   title: 'Success',
          //   html: 'Saved Successfully',
          //   icon: 'success',
          //   timer: 3000,
          //   timerProgressBar: true,
          // });
          this.toastr.success('Saved Successfully','Success')
          const claimDetail = value.billingState.selectedBilling;
          this.claimIdEmitter.emit(claimDetail);
          this.saveClaimInfoFormState.emit(this.claimForm.value);
          this.nextTabMoveOnSaveEmitter.emit('claimInfo');
        },
        (err) => {
          const data: HttpErrorResponse = err;
          Swal.fire({
            icon: 'info',
            text: data?.error?.error?.message,
          });
        }
      );
    }
  }
  // ! save posting and charges
  postingChargesId: '00000000-0000-0000-0000-000000000000';
  savePosting(response: ClaimDTO) {
    let postingDetails: ClaimDTO;
    let currentPosting: PostingChargesDTO[] = response?.lstPostingCharges;
    // let selectedItem: itemInterface = this.drpitem?.filter(value => { return value && value?.id === this.itemForm?.get("itemData.txtItenId").value })[0];
    if (this.postingChargesId != '00000000-0000-0000-0000-000000000000') {
      currentPosting = currentPosting.filter((lstPostingCharges) => {
        return (
          lstPostingCharges &&
          lstPostingCharges.postingChargesId != this.postingChargesId
        );
      });
      currentPosting = [
        ...currentPosting,
        {
          itemId: '00000000-0000-0000-0000-000000000000',
          productId: '00000000-0000-0000-0000-000000000000',
          postingChargesId: this.postingChargesId,
          diagnosisCode9: this.postingForm.value.drpDiag9, // == "" ? [] : this.postingForm.value.drpDiag9,
          diagnosisCode10: this.postingForm.value.drpDiag10ID,
          item: this.postingForm.value.drpCode,
          insurance: this.postingForm.value.txtDescription,
          modifier1: this.postingForm.value.drpModifiers1,
          modifier2: this.postingForm.value.drpModifiers2,
          modifier3: this.postingForm.value.drpModifiers3,
          modifier4: this.postingForm.value.drpModifiers4,
          pos: this.postingForm.value.drpPos,
          tos: this.postingForm.value.drpTos,
          provider: this.postingForm.value.txtProvider,
          dateFrom: this.postingForm.value.txtDateFrom,
          dateTo: this.postingForm.value.txtDateTo,
          dxPointer1: this.postingForm.value.drpDx1,
          dxPointer2: this.postingForm.value.drpDx2,
          dxPointer3: '',
          dxPointer4: '',
          checkNo: this.postingForm.value.txtCheckNo,
          fee: this.postingForm.value.txtFee,
          units: this.postingForm.value.txtUnits,
          copay: this.postingForm.value.txtCopay,
          other: this.postingForm.value.txtOther,
          total: this.postingForm.value.txtTotal,
          document: this.postingForm.value.txtDocument,
          statementNote: this.postingForm.value.txtStatement,
          cpts: '',
          dos: new Date(this.dos)?.toLocaleDateString('en-US'),
          status: 'Ready',
          patientResp: 0,
          itemSize: this.postingForm.value.drpSize,
        },
      ];
    } else {
      currentPosting = [
        ...currentPosting,
        {
          itemId: '00000000-0000-0000-0000-000000000000',
          productId: '00000000-0000-0000-0000-000000000000',
          postingChargesId: this.postingChargesId,
          diagnosisCode9: this.postingForm.value.drpDiag9,
          diagnosisCode10: this.postingForm.value.drpDiag10ID,
          item: this.postingForm.value.drpCode,
          insurance: this.postingForm.value.txtDescription,
          modifier1: this.postingForm.value.drpModifiers1,
          modifier2: this.postingForm.value.drpModifiers2,
          modifier3: this.postingForm.value.drpModifiers3,
          modifier4: this.postingForm.value.drpModifiers4,
          pos: this.postingForm.value.drpPos,
          tos: this.postingForm.value.drpTos,
          provider: this.postingForm.value.txtProvider,
          dateFrom: this.postingForm.value.txtDateFrom,
          dateTo: this.postingForm.value.txtDateTo,
          dxPointer1: this.postingForm.value.drpDx1,
          dxPointer2: this.postingForm.value.drpDx2,
          dxPointer3: '',
          dxPointer4: '',
          checkNo: this.postingForm.value.txtCheckNo,
          fee: this.postingForm.value.txtFee,
          units: this.postingForm.value.txtUnits,
          copay: this.postingForm.value.txtCopay,
          other: this.postingForm.value.txtOther,
          total: this.postingForm.value.txtTotal,
          document: this.postingForm.value.txtDocument,
          statementNote: this.postingForm.value.txtStatement,
          cpts: '',
          dos: new Date(this.dos)?.toLocaleDateString('en-US'),
          status: 'Ready',
          patientResp: 0,
          itemSize: this.postingForm.value.drpSize,
        },
      ];
    }
    postingDetails = {
      lstClaimInfo: response.lstClaimInfo,
      lstPostingCharges: currentPosting,
      // insurancePayments:null,
      insurancePayments: {
        primaryInsurancePaymentDetails: {
          source: '',
          code: '',
          description: '',
          checkNo: '',
          date: '',
          amount: '',
          plansOfFiles: '',
          statementNote: '',
          lstItemPayment: [],
          paymentType: '',
          reason: '',
          eobDocuments: null,
          totalBilled: 0,
          totalAllowed: 0,
          totalDeduct: 0,
          totalCoInsurance: 0,
          totalWriteOff: 0,
          totalInsurancePaid: 0,
          totalInsBal: 0,
          totalPatientBal: 0,
        },
        secondaryInsurancePaymentDetails: {
          source: '',
          code: '',
          description: '',
          checkNo: '',
          date: '',
          amount: '',
          plansOfFiles: '',
          statementNote: '',
          lstItemPayment: [],
          paymentType: '',
          reason: '',
          eobDocuments: null,
          totalBilled: 0,
          totalAllowed: 0,
          totalDeduct: 0,
          totalCoInsurance: 0,
          totalWriteOff: 0,
          totalInsurancePaid: 0,
          totalInsBal: 0,
          totalPatientBal: 0,
        },
        tertiaryInsurancePaymentDetails: {
          source: '',
          code: '',
          description: '',
          checkNo: '',
          date: '',
          amount: '',
          plansOfFiles: '',
          statementNote: '',
          lstItemPayment: [],
          paymentType: '',
          reason: '',
          eobDocuments: null,
          totalBilled: 0,
          totalAllowed: 0,
          totalDeduct: 0,
          totalCoInsurance: 0,
          totalWriteOff: 0,
          totalInsurancePaid: 0,
          totalInsBal: 0,
          totalPatientBal: 0,
        },
      },
      saleOrder: response.saleOrder,
      patientId: response.patientId,
      orderId: response.orderId,
      saleorderId: response.saleorderId,
      defaultPatientId: response.defaultPatientId,
      patientName: response.patientName,
      patientDob: response.patientDob,
      readyCount: 0,
      processedCount: 0,
      crossOverCount: 0,
      holdCount: 0,
      patientResponsibilityCount: 0,
      patientResponsibilityAfterInsCount: 0,
      claimId: response.claimId,
      lstClearingHouseUpdates: [],
      totalPatientBal: 0,
      lstPatientVisitNotes: [],
      totalBilled: 0,
      totalInsBal: 0,
      lstReminders: null,
      isVoided: response?.isVoided,
      claimProcessMethod: response?.claimProcessMethod,
    };

    if (
      this.claimId != null &&
      this.claimId != '00000000-0000-0000-0000-000000000000'
    ) {
      this.store
        .dispatch(new updateBilling(this.claimId, postingDetails))
        .subscribe(
          (value) => {
            // Swal.fire({
            //   title: 'Success',
            //   html: 'Saved Successfully',
            //   icon: 'success',
            //   timer: 3000,
            //   timerProgressBar: true,
            // });
            this.toastr.success('Saved Successfully','Success')
            // this.savePostingChargesFormState.emit(this.postingForm.value);
            this.getPostingTable();
            this.postingChargesId = '00000000-0000-0000-0000-000000000000';
            this.nextTabMoveOnSaveEmitter.emit('Posting');
          },
          (err) => {
            const data: HttpErrorResponse = err;
            Swal.fire({
              icon: 'info',
              text: data?.error?.error?.message,
            });
          }
        );
    } else {
      this.store
        .dispatch(new updateBilling(this.listClaimId, postingDetails))
        .subscribe(
          (value) => {
            // Swal.fire({
            //   title: 'Success',
            //   html: 'Saved Successfully',
            //   icon: 'success',
            //   timer: 3000,
            //   timerProgressBar: true,
            // });
            this.toastr.success('Saved Successfully','Success')
            // this.savePostingChargesFormState.emit(this.postingForm.value);
            this.getPostingTable();
            this.postingChargesId = '00000000-0000-0000-0000-000000000000';
            this.nextTabMoveOnSaveEmitter.emit('Posting');
          },
          (err) => {
            const data: HttpErrorResponse = err;
            Swal.fire({
              icon: 'info',
              text: data?.error?.error?.message,
            });
          }
        );
    }
  }
  //// table initial load
  total: number;
  getPostingTable() {
    let postingDetails: ClaimDTO;
    this.store.dispatch(new getBilling(this.claimId)).subscribe(
      (response) => {
        postingDetails = response?.billingState?.selectedBilling;
        this.tblPostReponse = [];
        const original = this.dos;
        this.total = response?.billingState?.selectedBilling.totalFee;
        postingDetails?.lstPostingCharges?.forEach((element) => {
          if (
            element?.postingChargesId !== '00000000-0000-0000-0000-000000000000'
          ) {
            this.tblPostReponse.push({
              code: element?.item,
              cpt: '',
              dos:
                original == null
                  ? ''
                  : new Date(original)?.toLocaleDateString('en-US'),
              // dos: element?.lstPostingCharges[0].dos == null ? "" : new Date(element?.lstPostingCharges[0].dos)?.toLocaleDateString("en-US"),
              m1: element?.modifier1,
              m2: element?.modifier2,
              m3: element?.modifier3,
              m4: element?.modifier4,
              fee: element?.fee,
              diagnosis9: element?.diagnosisCode9,
              diagnosis10: element?.diagnosisCode10,
              provider: element?.provider,
            });
          }
        });
        this.Table.setPostTable(this.tblPostReponse);
      },
      (err) => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      },
      () => {
        // !
      }
    );
  }
  /// to get claimid
  claimDetailsAfterSave(value: ClaimDTO) {
    if (!(value === null || value === undefined || value?.id === ''))
      this.claimId = value?.id ?? '00000000-0000-0000-0000-000000000000';
    this.orderId = value?.orderId;
    if (
      this.claimId != null ||
      this.claimId != '00000000-0000-0000-0000-000000000000'
    ) {
      // this.Table.getOrderId().subscribe(val => {
      //   this.listOrderId = val.orderId;
      //   this.claimId = val.claimId;
      //   this.listPatientId = val.pat
      //
      // });
    }
  }
  // !patch claim detatis
  getClaimById() { }
  // get patient details for side bar
  //
  strLocation: string = '';
  strBillingAddress: string = '';
  strBillingState: string = '';
  strBillingCity: string = '';
  strBillingCountry: string = '';
  strPostalCode: string = '';
  strMobile: string = '';
  strEmailAddress: string = '';
  lblDefaultPatientId: string = '';
  lblLastUpdatedDateTime: string | Date;
  lblPatientName: string = '';
  lblPatientGender: string = '';
  lblPatientDOB: string = '';
  lblPatientHeight: string = '';
  lblBillingAmount: string = '';
  lblBillingStatus: string = '';
  lblPatientWeight: string = '';
  lblBranchOffice: string = '';
  lblAccountNumber: string = '';
  lblSsnNumber: string = '';
  forBlink: boolean = true;
  countryShortName: string = '';

  getDetailsByPatientID(patientId: string) {
    this.patientId !== '' &&
      this.store.dispatch(new getPatient(patientId)).subscribe(
        (responseData) => {
          const personalResponse: PersonalDTO =
            responseData?.patientState?.selectedPatient?.personals;
          const patientResponse: PatientDTO =
            responseData?.patientState?.selectedPatient;
          const clinicalResponse: ClinicalDTO =
            responseData?.patientState?.selectedPatient?.clinicals;
          this.lblLastUpdatedDateTime = patientResponse?.lastModificationTime;
          if (personalResponse !== null || personalResponse !== undefined) {
            this.lblDefaultPatientId =
              responseData?.patientState?.selectedPatient?.defaultPatientId?.toString();
            this.lblPatientName = personalResponse?.fullName;
            // this.lblPatientGender = clinicalResponse?.genderType == null ? "" : clinicalResponse?.genderType,
            (this.lblPatientGender =
              responseData?.patientState.selectedPatient?.clinicals
                ?.genderType === 'M'
                ? 'Male'
                : responseData?.patientState.selectedPatient?.clinicals
                  ?.genderType === 'F'
                  ? 'Female'
                  : responseData?.patientState.selectedPatient?.clinicals
                    ?.genderType === 'U'
                    ? 'Unknown'
                    : ''),
              (this.lblPatientDOB =
                personalResponse?.dateOfBirth == null
                  ? ''
                  : new Date(personalResponse?.dateOfBirth)?.toLocaleDateString(
                    'en-US'
                  ));
            this.lblPatientHeight =
              clinicalResponse?.height === 0
                ? ''
                : clinicalResponse?.height.toString();
            this.lblPatientWeight =
              clinicalResponse?.weight === 0
                ? ''
                : clinicalResponse?.weight.toString();
            //  this.lblBranchOffice = personalResponse?.branchOffice == null ? "" : personalResponse?.branchOffice
            this.lblAccountNumber = personalResponse?.accountNumber;
            this.lblSsnNumber = personalResponse?.ssn;
            this.patientId = responseData?.patientState?.selectedPatient?.id;
            this.lblBranchOffice = personalResponse?.branchOffice;
            if (patientResponse?.billingDetails != null) {
              this.lblBillingAmount = patientResponse?.billingDetails.amount;
              this.lblBillingStatus = patientResponse?.billingDetails.status;
            }
            if (patientResponse?.billingDetails?.status === 'Processed') {
              this.forBlink = false;
            }

            // this.lblBranchOffice = this.drpBranchOffices?.filter(value => { return value && value?.branchOfficeShortCodeId === personalResponse?.branchOffice })[0]?.branchOffice;
            // this.strLocation = this.drpLocationTypes?.filter(value => { return value && value?.locationTypeShortCodeId === patientResponse?.personals?.locationType })[0].locationType;
            // this.strLocation = this.drpLocationTypes?.filter(value => { return value && value?.locationTypeShortCodeId === patientResponse?.personals?.locationType })[0].locationType;
            this.strLocation = personalResponse?.locationType;
            this.strBillingAddress = patientResponse?.addresses[0].address;

            this.region
              .getAllCountriesByInput(new PagedAndSortedResultRequestDto())
              .subscribe(
                (response) => {
                  this.strBillingCountry = response?.items?.filter((value) => {
                    return (
                      value &&
                      value?.countryShortName ===
                      patientResponse?.addresses[0]?.country
                    );
                  })[0]?.countryName;
                },
                (err) => {
                  const data: HttpErrorResponse = err;
                  Swal.fire({
                    icon: 'info',
                    text: data?.error?.error?.message,
                  });
                }
              );

            patientResponse?.addresses[0]?.country != null &&
              patientResponse?.addresses[0]?.country !== '' &&
              patientResponse?.addresses[0]?.country != undefined &&
              this.region
                .getAllStatesByCountryNameByCountryShortName(
                  patientResponse?.addresses[0]?.country
                )
                .subscribe(
                  (response) => {
                    this.strBillingState = response?.items?.filter((value) => {
                      return (
                        value &&
                        value?.stateShortName ===
                        patientResponse?.addresses[0]?.state
                      );
                    })[0]?.stateName;
                  },
                  (err) => {
                    const data: HttpErrorResponse = err;
                    // Swal.fire({
                    //   icon: 'error',
                    //   text: data?.error?.error?.message,
                    // });
                  }
                );

            // this.strBillingState = patientResponse?.addresses[0].state; //this.drpState?.filter(value => { return value && value?.stateId === patientResponse?.addresses[0].state })[0]?.stateType;
            this.strBillingCity = patientResponse?.addresses[0].city; // this.drpCity?.filter(value => { return value && value?.cityId === patientResponse?.addresses[0].city })[0]?.cityType;
            // this.drpCountries?.filter(value => { return value && value?.countryTypeId === patientResponse?.addresses[0].country })[0]?.countryType;

            this.strPostalCode = patientResponse?.addresses[0]?.postalCode;
            this.strMobile = patientResponse?.addresses[0]?.mobile;
            // this.strEmailAddress = patientResponse?.addresses[0]?.emailId;
            this.strEmailAddress = patientResponse?.personals?.emailId;
          }
        },
        (err) => {
          const data: HttpErrorResponse = err;
          Swal.fire({
            icon: 'info',
            text: data?.error?.error?.message,
          });
        }
      );
  }

  responsibleRelationship: RelationShipWithoutSelfDTO[] = [];

  // dropdown functionality
  // ! Master Dropdown Funtion
  getdropdowns() {
    let patientDropdownInput: PatientDropdowns[];
    patientDropdownInput = [
      PatientDropdowns.Suffixes,
      PatientDropdowns.Genders,
      PatientDropdowns.PlaceOfServices,
      PatientDropdowns.RelationShipWithoutSelf,
    ];
    this.patientDropdownService
      .getPatientDropdownsByInput(patientDropdownInput)
      .subscribe(
        (stateResponse) => {
          const reponse: PatientMasterDropdownDTO = stateResponse;
          this.drpSuffixes = reponse?.suffixes;
          this.drpGenderLoop = reponse?.genders;
          this.drpPlace = reponse?.placeOfServices;
          this.responsibleRelationship = reponse?.relationShipWithoutSelves;
        },
        (err) => {
          const data: HttpErrorResponse = err;
          Swal.fire({
            icon: 'info',
            text: data?.error?.error?.message,
          });
        }
      );
  }

  drpEmerCountry: any[] = [];
  drpResCountry: any[] = [];
  drpEmerStates: any[] = [];
  drpEmerCities: any[] = [];
  drpResStates: any[] = [];
  drpResCities: any[] = [];
  Country: string = '';
  // responsible country
  resCountryDropdown() {
    this.patientDetailsForm.patchValue({ txtCountry: defaultCountry.US });
    this.onChangeResState(defaultCountry.US);
    this.Country = defaultCountry.US;
    this.drpResCountry = [];
    this.drpResCities = [];
    this.drpResStates = [];
    this.region
      .getAllCountriesByInput(new PagedAndSortedResultRequestDto())
      .subscribe(
        (response) => {
          this.drpResCountry = response?.items;

          this.filteredResponsibleCountries = this.patientDetailsForm
            .get('txtResponsibleCountryFilter')
            .valueChanges.pipe(
              startWith(''),
              map((value) =>
                this.drpResCountry.filter((option) =>
                  option?.countryName
                    ?.toLowerCase()
                    ?.includes(value?.toLowerCase() ?? '')
                )
              )
            );
        },
        (err) => {
          const data: HttpErrorResponse = err;
          Swal.fire({
            icon: 'info',
            text: data?.error?.error?.message,
          });
        }
      );
  }
  // res state, city
  onChangeResState(value: string | null | undefined) {
    this.patientDetailsForm.patchValue({
      txtResponsibleCity: null,
      txtResponsibleState: null,
    });
    this.drpResCities = [];
    this.drpResStates = [];
    value !== null &&
      value !== undefined &&
      this.region.getAllStatesByCountryNameByCountryShortName(value).subscribe(
        (response) => {
          this.drpResStates = response?.items;
          this.filteredResponsibleStates = this.patientDetailsForm
            .get('txtResponsibleStateFilter')
            .valueChanges.pipe(
              startWith(''),
              map((value) =>
                this.drpResStates.filter((option) =>
                  option?.stateName
                    ?.toLowerCase()
                    ?.includes(value?.toLowerCase() ?? '')
                )
              )
            );
        },
        (err) => {
          const data: HttpErrorResponse = err;
          Swal.fire({
            icon: 'info',
            text: data?.error?.error?.message,
          });
        }
      );
  }
  onChangeResCity(state: string | null | undefined, country: string) {
    this.patientDetailsForm.patchValue({
      txtResponsibleCity: null,
    });
    country =
      country == ''
        ? this.patientDetailsForm.value.txtResponsibleCountry ==
          (null || undefined)
          ? ''
          : this.patientDetailsForm.value.txtResponsibleCountry
        : country;
    this.drpResCities = [];
    state !== null &&
      state !== undefined &&
      this.region
        .getAllCitiesByCountryAndStateNameByCountryShortNameAndStateShortName(
          country,
          state
        )
        .subscribe(
          (response) => {
            this.drpResCities = response?.items;
            this.filteredResponsibleCities = this.patientDetailsForm
              .get('txtResponsibleCityFilter')
              .valueChanges.pipe(
                startWith(''),
                map((value) =>
                  this.drpResCities.filter((option) =>
                    option?.cityName
                      ?.toLowerCase()
                      ?.includes(value?.toLowerCase() ?? '')
                  )
                )
              );
          },
          (err) => {
            const data: HttpErrorResponse = err;
            Swal.fire({
              icon: 'info',
              text: data?.error?.error?.message,
            });
          }
        );
  }
  // get payor dropdown
  getPayerDropdown() {
    /// payor dropdown
    this.payerService
      .getList(new PagedAndSortedResultRequestDto())
      .subscribe((response) => {
        // this.drpPayerId = [];
        response?.items?.forEach((element) => {
          this.drpPayerId.push({
            id: element.id ?? '',
            name: element?.payerId + ' / ' + element?.payerName,
            payerCode: element?.payerId ?? '',
            payerName: element?.payerName,
          });
        });
        this.filterPayor = this.patientDetailsForm
          .get('txtPayorFilter')
          .valueChanges.pipe(
            startWith(''),
            map((value) =>
              this.drpPayerId.filter((option) =>
                option?.name
                  ?.toLowerCase()
                  ?.includes(value?.toLowerCase() ?? '')
              )
            )
          );
      });
  }

  planDropdown(): void {
    this.planService.getList(new PagedAndSortedResultRequestDto()).subscribe(
      (response) => {
        this.drpPlan = [];
        response?.items?.forEach((element) => {
          this.drpPlan.push({
            id: element.id ?? '',
            name: element?.payerName ?? '',
            code: element?.payerCode ?? '',
            payorId: element.shortCodeId ?? '',
          });
        });
        this.filteredInsuranceTypes = this.patientDetailsForm
          .get('txtInsuranceFilter')
          ?.valueChanges.pipe(
            startWith(''),
            map((value) =>
              this.drpPlan.filter((option) =>
                option?.name
                  ?.toLowerCase()
                  ?.includes(value?.toLowerCase() ?? '')
              )
            )
          );
      },
      (err) => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      }
    );
  }

  diagonosisDropdown() {
    this.drpDiagnosisICD9 = [];
    this.drpDiagnosisICD10 = [];
    let patientDropdownInput: PatientDropdowns[];
    patientDropdownInput = [
      PatientDropdowns.DiagnosisCode9,
      PatientDropdowns.DiagnosisCode10,
    ];
    this.patientDropdownService
      .getPatientDropdownsByInput(patientDropdownInput)
      .subscribe((stateResponse) => {
        const response: PatientMasterDropdownDTO = stateResponse;
        this.drpDiagnosisICD9 = response.diagnosisCodes9;
        this.drpDiagnosisICD10 = response.diagnosisCodes10;
        this.filteredIcd9Types = this.claimForm
          .get('txtIcd9Filter')
          .valueChanges.pipe(
            startWith(''),
            map((value) =>
              this.drpDiagnosisICD9.filter((option) =>
                option?.diagnosisCodeTypeData
                  ?.toLowerCase()
                  ?.includes(value?.toLowerCase() ?? '')
              )
            )
          );
        this.filteredIcd10Types = this.claimForm
          .get('txtIcd10Filter')
          .valueChanges.pipe(
            startWith(''),
            map((value) =>
              this.drpDiagnosisICD10.filter((option) =>
                option?.diagnosisCodeTypeData
                  ?.toLowerCase()
                  ?.includes(value?.toLowerCase() ?? '')
              )
            )
          );
      });
  }

  getProviderDropdown() {
    const providerList = (query: any) => this.providerList.getList(query);
    this.list.hookToQuery(providerList).subscribe((response) => {
      response?.items?.forEach((element) => {
        this.drpProviders.push({
          id: element.id,
          name: element?.fullName,
          shortCodeId: element?.shortCodeId,
          fullName: element?.fullName,
          npi: element?.npi,
        });
      });
    });
  }

  // Get by Billing for get patient information
  // getBillingById() {
  //   this.store.dispatch(new getBilling(this.claimOrderId)).subscribe(response => {
  //     let claimDetail: ClaimDTO = response?.billingState?.selectedBilling;
  //     let patienetDetail = response?.patientState.selectedPatient?.personals;
  //     let clinicalDetails = response?.patientState?.selectedPatient?.clinicals
  //     let addressDetail = response?.patientState.selectedPatient?.addresses;
  //     let primaryPolicyDetail = response?.patientState.selectedPatient?.policies?.primaryPolicy
  //     let lstClaimInfo: ClaimInfoDTO[] = claimDetail?.lstClaimInfo ?? null;
  //     if (lstClaimInfo && lstClaimInfo[0]) {
  //       let element = lstClaimInfo[0]
  //       this.patientDetailsForm.patchValue({
  //         txtFirstName: patienetDetail?.firstName,
  //         txtMiddleName: patienetDetail?.middleName,
  //         txtLastName: patienetDetail?.lastName,
  //         drpSuffix: patienetDetail?.suffix,
  //         txtSSN: patienetDetail?.ssn,
  //         drpGender: clinicalDetails?.genderType == "" ? null : clinicalDetails?.genderType,
  //         txtProvider: patienetDetail?.providerId,
  //         txtAddress: addressDetail[0]?.address,
  //         txtResponsibleCountry: addressDetail[0]?.country,
  //         txtResponsibleState: addressDetail[0]?.state,
  //         txtResponsibleCity: addressDetail[0]?.city,
  //         drpCounty: addressDetail[0]?.county,
  //         txtBillingPostalCode: addressDetail[0]?.postalCode,
  //         txtBillingPhone: addressDetail[0]?.phone,
  //         txtPayerName: primaryPolicyDetail?.payorLevel,
  //         drpPayorName: primaryPolicyDetail?.payorId,
  //         drpInsurance: primaryPolicyDetail?.insuranceType,
  //         txtPolicyInfoPolicyNo: primaryPolicyDetail?.policyNo,
  //         txtNote: primaryPolicyDetail?.note,
  //         drpDiag9: element?.diagnosisCode9,
  //         drpDiag10ID: element?.diagnosisCode10,
  //       })
  //     }
  //   }, err => {
  //     const data: HttpErrorResponse = err;
  //     Swal.fire({
  //       icon: 'error',
  //       text: data?.error?.error?.message,
  //     });
  //   });
  // }

  // Patient Details
  savePatientDetails() {
    const inputClaimDetails: ClaimDetailsDTO[] = [];
    const referringProviderDetails: ReferringProviderDetailsDTO = {
      referrringProviderDoctorFirstName: '',
      referrringProviderDoctorLastName: '',
      referrringProviderDoctorMiddleName: '',
      referrringProviderDoctorNpi: '',
    };
    const renderingProviderDetails: RenderingingProviderDetailsDTO = {
      renderingProviderDoctorFirstName: '',
      renderingProviderDoctorLastName: '',
      renderingProviderDoctorMiddleName: '',
      renderingProviderDoctorNpi: '',
    };
    const payerDetails: PayerDetailsDTO = {
      payerName: '',
      payerId: '',
      policyNo: '',
      payerAddressLine1: '',
      payerAddressLine2: '',
    };
    const patientDetails: PatientDetailsDTO = {
      patientLastName: '',
      patientFirstName: '',
      patientMiddleName: '',
      patientDob: '',
      patientGenderType: '',
      patientIndividualRelationShipCode: '',
      patientAddress: '',
      patientCity: '',
      patientState: '',
      patientZipCode: '',
      patientRelationShipPersonGenderType: '',
      authNumber: '',
      isSSN: 0,
    };
    const insuredDetails: InsuredDetailDTO = {
      insuredId: '',
      insuredLastName: '',
      insuredFirstName: '',
      insuredMiddleName: '',
      insuredDob: '',
      insuredGenderType: '',
      insuredAddress: '',
      insuredCity: '',
      insuredState: '',
      insuredZipCode: '',
      insuredTelephone: '',
      insuredPlanName: '',
      insuredGroupName: '',
      insuredPhoneNumber: '',
      payorLevel: '',
    };
    inputClaimDetails.push({
      claimId: '',
      claimNumber: '',
      clearingHouseType: '',
      totalItemChargeAmount: '',
      placeOfServiceCode: '',
      diagnosisCode10Id: [],
      payerDetails,
      patientDetails,
      itemDetails: [],
      referringProviderDetails,
      renderingProviderDetails,
      insuredDetails,
    });

    const sProviderDetails: MasterProviderDTO[] =
      this.drpProviders.filter(
        (e) => e?.shortCodeId === this.patientDetailsForm.value?.drpForms
      ) ?? null;
    const selectedProvider: ProviderDetailsDTO = sProviderDetails.map((e) => {
      return {
        providerFullName: e?.fullName,
        providerLastName: e?.lastName,
        providerFirstName: e?.firstName,
        providerNpi: e?.npi,
        providerPhoneNumber: e?.phone,
        providerAddress: e?.homeAddress,
        providerCity: e?.city,
        providerState: e?.state,
        providerZipCode: e?.zip,
        providerId: e?.id,
        providerTaxId:e?.mmTaxId,
      };
    })[0];

    const input: ClaimBatchProcessRequestDTO = {
      providerDetails: selectedProvider,
      claimDetails: inputClaimDetails,
      claimProcessMethod: claimProcessMethod?.Zermid,
    };
    this.claimProcessService.updateClaimByInput(input).subscribe((response) => {
      Swal.fire({
        title: 'Success',
        html: 'Updated Successfully',
        icon: 'success',
        timer: 3000,
        timerProgressBar: true,
      }).finally(() => { });
    });
  }

  // Reset Patinet Details
  reset() {
    this.patientDetailsForm.patchValue({
      txtFirstName: '',
      txtMiddleName: '',
      txtLastName: '',
      drpSuffix: '',
      txtSSN: '',
      drpGender: '',
      txtProvider: '',
      txtAddress: '',
      txtResponsibleCountry: '',
      txtResponsibleState: '',
      txtResponsibleCity: '',
      drpCounty: '',
      txtBillingPostalCode: '',
      txtBillingPhone: '',
      txtPayerName: '',
      drpPayorName: '',
      drpInsurance: '',
      txtPolicyInfoPolicyNo: '',
      txtNote: '',
      drpDiag9: '',
      drpDiag10ID: '',
      txtBirthdate: '',
      drpGeneralPlaceOfService: '',
    });
  }

  // dropdown clear
  clearSuffix() {
    this.suffixName = '';
    this.patientDetailsForm.patchValue({ drpSuffix: '' });
  }
  clearGender() {
    this.gender = '';
    this.patientDetailsForm.patchValue({ drpGeneralGender: '' });
  }
  clearResponsibleCountry() {
    this.phoneResCode = '';
    this.ResponsibleCountry = '';
    this.patientDetailsForm.patchValue({ txtResponsibleCountry: '' });
  }
  clearResponsibleState() {
    this.ResponsibleState = '';
    this.patientDetailsForm.patchValue({ txtResponsibleState: '' });
  }
  clearResponsibleCity() {
    this.ResponsibleCity = '';
    this.patientDetailsForm.patchValue({ txtResponsibleCity: '' });
  }
  clearInsurance() {
    this.Insurance = '';
    this.patientDetailsForm.patchValue({ drpInsurance: '' });
  }
  clearPayor() {
    this.payor = '';
    this.patientDetailsForm.patchValue({ drpPayorName: '' });
  }
  clearIcd9() {
    this.txtIcd9 = [];
    this.patientDetailsForm.patchValue({ txtDiagnosisCodes9: [] });
  }
  clearIcd10() {
    this.txtIcd10 = [];
    this.patientDetailsForm.patchValue({ txtdiagnosisCode10Id: [] });
  }
  clearProvider() {
    this.provider = '';
    this.patientDetailsForm.patchValue({ txtProvider: '' });
  }
  clearGeneralPlaceOfService() {
    this.generalPlaceOfService = '';
    this.patientDetailsForm.patchValue({
      drpGeneralPlaceOfService: null,
    });
  }
  clearRelationship() {
    this.relationship = '';
    this.patientDetailsForm.patchValue({ drpResponsibleRelationshipType: '' });
  }

  backToBillingList() {
    this.router.navigate(['billingList']);
  }

  clearRelationships() {
    this.relationships = '';
    this.patientDetailsForm.patchValue({ drpResponsibleRelationshipType: '' });
  }
}
