<table id="tblPatientList" datatable [dtOptions]="patientListOptions"
    class="row-border hover nowrap_th_td w-100 display">
    <thead>
        <tr>
            <th [matTooltip]="'Practice Name'">Practice Name</th>
            <th [matTooltip]="'Fax Number'">Fax Number</th>
            <th [matTooltip]="'Fax ID'">Fax ID</th>
            <th [matTooltip]="'Received Fax On'" class="text-center">Received Fax On</th>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let data of practiceListFax">
            <td [matTooltip]="data.practiceName || '-'" (dblclick)="onRowDoubleClickV1(data?.practiceName || '-')">{{
                data.practiceName || '-' }}</td>
            <td [matTooltip]="data.faxNumber || '-'" (dblclick)="onRowDoubleClickV1(data?.faxNumber || '-')">
                {{data.faxNumber || '-' }}</td>
            <td [matTooltip]="data.faxId || '-'" (dblclick)="onRowDoubleClickV1(data?.faxId || '-')">
                <a [routerLink]="'/patient_preview_tiff_document/'+ data.faxId+ '/' + data.id" target="_blank"
                    [class.disabled]="isEmpty(data.faxId)" class="preview-link">{{
                    data.faxId ?
                    data.faxId :
                    '-'}}</a>
            </td>
            <td [matTooltip]="getFormattedDate(data?.uplodedDate)"
                (dblclick)="onRowDoubleClickV1(getFormattedDate(data?.uplodedDate))" class="text-center">
                {{ getFormattedDate(data?.uplodedDate) }}
            </td>
        </tr>
    </tbody>
    <tfoot>
        <tr>
            <th>
                <input matInput class="textbox-border-class" type="text" placeholder="Practice Name"
                    name="search-PracticeName" />
            </th>
            <th>
                <input matInput type="text" class="textbox-border-class" placeholder="Fax Number"
                    name="search-FaxNumber" />
            </th>
            <th>
                <input matInput type="text" class="textbox-border-class" placeholder="Fax ID" name="search-FaxID" />
            </th>
            <th>
                <input matInput type="text" class="textbox-border-class text-center" placeholder="Received Fax On"
                    name="search-ReceivedFaxOn" />
            </th>
        </tr>
    </tfoot>

</table>