import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, Inject, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngxs/store';
import { TableService } from 'projects/shared/src/app/table.service';
import { NumberValidators } from 'projects/shared/src/app/validations/number-validator';
import { TextValidators } from 'projects/shared/src/app/validations/text-validator';
import { Subscription } from 'rxjs';
import Swal from 'sweetalert2';

import { ChartDTO, OrderMasterDropdownDTO, PayablePercentDTO, PlanTypeDTO, PreparedByDTO, TypeOfPlanDTO, VerifiedByDTO, YearTypeDTO } from '../dropdown-proxy/dropdown-management/dropdowns/dto/order/models';
import { PatientMasterDropdownDTO, PayorLevelDTO } from '../dropdown-proxy/dropdown-management/dropdowns/dto/patient/models';
import { OrderMasterDropdownService } from '../dropdown-proxy/dropdown-management/dropdowns/master-dropdown-service/order-master-dropdown.service';
import { PatientMasterDropdownService } from '../dropdown-proxy/dropdown-management/dropdowns/master-dropdown-service/patient-master-dropdown.service';
import { OrderDropdowns } from '../dropdown-proxy/dropdown-management/dropdowns/order-dropdowns.enum';
import { PatientDropdowns } from '../dropdown-proxy/dropdown-management/dropdowns/patient-dropdowns.enum';
import { PatientService } from '../patient-proxy/patient';
import { CreateUpdateInsuranceVerificationsDTO, PatientDTO, PatientLockDTO } from '../patient-proxy/patient/dto/models';
import { addVerification, getPatient } from '../patient-store/patient.action';
import { ToastrService } from "ngx-toastr";


// import { ChartDTO, MasterDropdownDTO, PayablePercentDTO, PreparedByDTO, TypeOfPlanDTO, VerifiedByDTO } from '@proxy/rcm/order-management/dropdown/dto/models';
// import { InsuranceBenefitInfoDTO, InsuranceVerifyDTO, OutOfPocketDeductibleDTO, PatientDTO, SaleorderDTO } from '@proxy/rcm/order-management/order/dto/models';
// import { InsuranceVerifyService } from '@proxy/rcm/order-management/order/insurance-verify.service';

interface Test {
  value: string;
  viewValue: string;
}
@Component({
  selector: 'app-patient-verification',
  templateUrl: './patient-verification.component.html',
  styleUrls: ['./patient-verification.component.scss']
})
export class PatientVerificationComponent implements OnInit , OnDestroy {
  BirthdateMaxDate: Date;
  BirthdateMinDate: Date;
  patientId: string = "";
  verificationForm: FormGroup;
  insuranceVerifyId: string = "";
  drpChart: ChartDTO[] = [];
  drpPlan: TypeOfPlanDTO[] = [];
  drpVerified: VerifiedByDTO[] = [];
  drpPrepared: PreparedByDTO[] = [];
  drpPayable: PayablePercentDTO[] = [];
  drpPolices: drpPolicesDTO[] = [];
  drpPolicies: any;
  payorLevel: PayorLevelDTO[];
  drpPlanType: PlanTypeDTO[] = [];
  drpYearType: YearTypeDTO[] = [];
  organizationUnitId: string = "";
  notesText ='';
  @Output() orderIdEmitter: EventEmitter<any> = new EventEmitter<any>();
  @Input() saveVerification: EventEmitter<any> = new EventEmitter<any>();
  // @Select(orderState.getOrderList) orders$: Observable<SaleorderDTO>;
  // orderData$: Observable<InsuranceVerifyDTO>
  verifyTableData: any[] = [];
  isShowSpinner: boolean = false;
  plan: string;
  type: string;
  typeOfPlan: string;
  payablePercentType: string;
  payablePercentType1: string;
  payablePercentType2: string;
  subscription$: Subscription[] = [];
  constructor(

    private formBuilder: FormBuilder, private Table: TableService,
    public verificationDialogRef: MatDialogRef<PatientVerificationComponent>,
    public eligibilityHistoryDialogReff: MatDialogRef<"">,
    private textValidators: TextValidators,
    private numberValidators: NumberValidators,
    private store: Store, public dialog: MatDialog,
    private patientDropdownService: PatientMasterDropdownService,
    private toastr: ToastrService,
    private orderDropdownService: OrderMasterDropdownService,
    // private insuranceVerifyService: InsuranceVerifyService,
    private patientService : PatientService,
    @Inject(MAT_DIALOG_DATA) public data: { patientId: string, record: string }) { }

  isPatientLockedByCurrentUser: boolean = true;
  strLoginUserId: string = '';

  ngOnInit(): void {

    const loginUserId = this.Table.getLoginUserId().subscribe(val => {
      this.strLoginUserId = val;
    })
    this.subscription$.push(loginUserId);
    //
    const organizationId = this.Table.getOrganizationUnitId().subscribe(value => {
      this.organizationUnitId = value;
    }, err => {
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    });
    this.subscription$.push(organizationId)
    //
    this.BirthdateMinDate = new Date();
    this.BirthdateMinDate.setFullYear(this.BirthdateMinDate.getFullYear() - 120);
    this.createVerificationForm();
    const savedRecord = this.data.record
    this.patientId = this.data.patientId
    if (savedRecord != null) {
      this.getVerifyById(this.data.record)
    }
    this.BirthdateMinDate = new Date();
    this.BirthdateMinDate.setFullYear(this.BirthdateMinDate.getFullYear() - 120);
  }

  ngOnDestroy(): void {
    this.subscription$?.forEach((sub) => {
      sub && sub?.unsubscribe();
    });
  }
  // clearDropdown
  updateCharacterCount() {
    // Ensure the text length does not exceed the maximum length
    if (this.notesText?.length > 5000) {
      this.notesText = this.notesText?.substr(0, 5000);
    }
  }
  clearPlan() {
    this.plan = '';
    this.verificationForm.patchValue({ txtPlan: "" });
  }
  clearType() {
    this.type = '';
    this.verificationForm.patchValue({ txtType: "" });

  }
  clearTypeOfPlan() {
    this.typeOfPlan = '';
    this.verificationForm.patchValue({ drpTypeOfPlan: "" });

  }
  clearPayablePercentType() {
    this.payablePercentType = '';
    this.verificationForm.patchValue({ drpPayableIndividual: "" });

  }
  clearPayablePercentType1() {
    this.payablePercentType1 = '';
    this.verificationForm.patchValue({ drpPayableFamily: "" });

  }
  clearPayablePercentType2() {
    this.payablePercentType2 = '';
    this.verificationForm.patchValue({ drpPayableOutOfNetwork: "" });

  }

  patchDummyData(orderDetail: PatientDTO) {

    // this.drpPolices = orderDetail.policies.map(value => {
    //   let data: drpPolicesDTO = {
    //     insuranceType: value.insuranceType,
    //     insuranceId: String(value.insuranceId),
    //     birthdate: value.birthdate,
    //     policyNo: value.policyNo,
    //     startDate: value.startDate,
    //     endDate: value.endDate,
    //     groupName: value.groupName,
    //     groupNo: value.groupNo,
    //     fisrtName: value.fisrtName,
    //     address1: value.address1
    //   }
    //   return data;
    // })

  }
  valueChangeLoadDropdown(type: string) {
    const storeSubscription = this.patientService.get(this.patientId).subscribe(response => {
      const savedPolicy = response?.policies;
      let primaryInsurance;
      let policy;
      let phone;
      let group;
      if (savedPolicy.primaryPolicy != null && savedPolicy.primaryPolicy.payorLevel === type) {
        primaryInsurance = savedPolicy.primaryPolicy.insuranceType;
        policy = savedPolicy.primaryPolicy.policyNo;
        // phone = savedPolicy.primaryPolicy.phone;
        // group = savedPolicy.primaryPolicy.groupName
      }
      if (savedPolicy.secondaryPolicy != null && savedPolicy.secondaryPolicy.payorLevel === type) {
        primaryInsurance = savedPolicy.secondaryPolicy.insuranceType;
        policy = savedPolicy.secondaryPolicy.policyNo;
        // phone = savedPolicy.secondaryPolicy.phone;
        // group = savedPolicy.secondaryPolicy.groupName
      }
      if (savedPolicy.tertiaryPolicy != null && savedPolicy.tertiaryPolicy.payorLevel === type) {
        primaryInsurance = savedPolicy.tertiaryPolicy.insuranceType;
        policy = savedPolicy.tertiaryPolicy.policyNo;
        // phone = savedPolicy.tertiaryPolicy.phone;
        // group = savedPolicy.tertiaryPolicy.groupName
      }

      this.verificationForm.patchValue({
        txtPlanName: primaryInsurance,
        txtPolicy: policy,
        txtPlanPhone: phone,
        txtGroup: group
      })
    }, err => {
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    })
    this.subscription$.push(storeSubscription)
  }

  getPatientDetailsForSave() {
    // let orderDetails: SaleorderDTO;
    // if (this.orderId !== "" && this.orderId !== null && this.orderId !== undefined) {
  }


  // ! Form Creation and Few Validations
  createVerificationForm() {
    this.verificationForm = this.formBuilder.group({
      //  txtChart: new FormControl("", [Validators.required]),
      txtName: new FormControl("", [this.textValidators.isTextCheck]),
      //  txtAddress: new FormControl(""),
      txtDateOfBirth: new FormControl("", [Validators.required]),
      txtCommentsNotes: new FormControl(""),
      txtPlan: new FormControl("", Validators.required),
      txtPlanName: new FormControl("", [Validators.required]),
      txtPlanPhone: new FormControl("", [this.numberValidators.isNumberCheck]),
      txtPolicy: new FormControl("", [Validators.required]),
      txtGroup: new FormControl(""),
      txtEffectiveDate: new FormControl("", [Validators.required]),
      txtType: new FormControl(null, [Validators.required]),
      txtFascalYearStart: new FormControl(""),
      txtEnd: new FormControl(""),
      chkMedicare: new FormControl(""),
      chkPatient: new FormControl(""),
      chkFamily: new FormControl(""),
      txtCmnCode: new FormControl("", [this.textValidators.isTextCheck]),
      drpTypeOfPlan: new FormControl(null, [Validators.required]),
      chkIndividual: new FormControl(""),
      chkOutOfNetwork: new FormControl(""),
      chkFamilyDeductible: new FormControl(""),
      txtDeductibleIndividual: new FormControl(""),
      txtDeductibleOutOfNetwork: new FormControl(""),
      txtDeductibleFamily: new FormControl(""),
      txtDeductibleMetIndividual: new FormControl(""),
      txtDeductibleMetOutOfNetwork: new FormControl(""),
      txtDeductibleMetFamily: new FormControl(""),
      drpPayableIndividual: new FormControl(null),
      drpPayableOutOfNetwork: new FormControl(null),
      drpPayableFamily: new FormControl(null),
      txtExpenseIndividual: new FormControl(""),
      txtExpenseOutOfNetwork: new FormControl(""),
      txtExpenseFamily: new FormControl(""),
      txtExpenseMetIndividual: new FormControl(""),
      txtExpenseMetOutOfNetwork: new FormControl(""),
      txtExpenseMetFamily: new FormControl(""),
      chkExpenseIndividual: new FormControl(""),
      chkExpenseOutOfNetwork: new FormControl(""),
      chkExpenseFamily: new FormControl(""),
      chkPrecertRequired: new FormControl(""),
      txtPhoneCall: new FormControl("", [this.numberValidators.isNumberCheck]),
      chkReferralRequired: new FormControl(""),
      txtPcp: new FormControl("", [this.textValidators.isTextCheck]),
      chkPredetermination: new FormControl(""),
      txtPreNotes: new FormControl(""),
      txtLifetimeMax: new FormControl(""),
      txtLifetimeMaxMet: new FormControl(""),
      chkDmePartsCovered: new FormControl(""),
      chkDmeReplacementUnit: new FormControl(""),
      txtDmeAnnualLimit: new FormControl(""),
      txtDmeLimitMet: new FormControl(""),
      txtRequestedBy: new FormControl("", [this.textValidators.isTextCheck]),
      txtPrimaryPayor: new FormControl("", [this.textValidators.isTextCheck]),
      txtContact: new FormControl("", [this.numberValidators.isNumberCheck]),
      txtCallTrackingNumber: new FormControl(""),
      txtPolicyHolderName: new FormControl("", [this.textValidators.isTextCheck]),
      txtPolicyHolderDob: new FormControl(""),
      chkNetwork: new FormControl(""),
      txtClaimAddress: new FormControl(""),
      txtPlanCob: new FormControl(""),
      txtDedTotal: new FormControl(""),
      txtDedMet: new FormControl(""),
      txtOopTotal: new FormControl(""),
      txtOopMet: new FormControl(""),
      txtOopDeductible: new FormControl(""),
      txtDmeCoverage: new FormControl(""),
      txtDmeMax: new FormControl(""),
      txtHcpcs: new FormControl("", [this.numberValidators.isNumberCheck]),
      txtState: new FormControl("", [this.textValidators.isTextCheck]),
      txtCoverageCriteria: new FormControl("", [this.textValidators.isTextCheck]),
      txtQtyLimits: new FormControl("", [this.textValidators.isTextCheck]),
      txtFrequency: new FormControl("", [this.textValidators.isTextCheck]),
      txtRent: new FormControl("", [this.textValidators.isTextCheck]),
      txtPaRequired: new FormControl("", [this.textValidators.isTextCheck]),
      txtCodesRequirePa: new FormControl("", [this.textValidators.isTextCheck]),
      txtSameOrSimilarInfo: new FormControl("", [this.textValidators.isTextCheck]),
      txtSpokeWith: new FormControl("", [this.textValidators.isTextCheck]),
      drpVerifiedBy: new FormControl("", [this.textValidators.isTextCheck]),
      txtDateVerified: new FormControl(""),
      drpPreparedBy: new FormControl("", [this.textValidators.isTextCheck]),
      txtPreparedOn: new FormControl(""),
      rbtnVerificationStatus: new FormControl("", [Validators.required]),
      txtDeo: new FormControl("", this.numberValidators.isNumberCheck),
      txtCoIns: new FormControl(""),
      txtCoInsurance: new FormControl(""),
      txtcommentsToTech: new FormControl(""),
      txtVerfnAMethod: new FormControl(""),
      chkActive: new FormControl(""),
      // newly added
      chkIsBlueCardEligible: new FormControl(""),
      chkIsDirectBill: new FormControl(""),
      chkIsPreAuthNeeded: new FormControl(""),
      chkIsClaimSubmit: new FormControl(""),
      chkIsReferenceNumberAvailable: new FormControl(""),
      chkClaimForHCPC: new FormControl(""),
      txtPatientResposponsiblePaymentPercentage: new FormControl(""),
      txtReferenceNumber: new FormControl(""),
      // rbtnPending:new FormControl(""),
      // rbtnUnableToVerify:new FormControl(""),
    })
    this.patientId! == "" && this.patientId !== undefined && this.patientId !== null && this.getDropdown();
    this.BirthdateMaxDate = new Date();
    this.BirthdateMaxDate.setDate(this.BirthdateMaxDate.getDate() - 1);
    this.BirthdateMinDate = new Date();
    this.BirthdateMinDate.setFullYear(this.BirthdateMinDate.getFullYear() - 120);
  }
  patientids: string;

  getDropdown() {
    let orderDropdownInput: OrderDropdowns[];
    orderDropdownInput = [
      OrderDropdowns.Charts,
      OrderDropdowns.TypeOfPlans,
      OrderDropdowns.PlanTypes,
      OrderDropdowns.PayablePercents,
      OrderDropdowns.YearTypes
    ]
    const orderDropdown = this.orderDropdownService.getOrderDropdownsByInput(orderDropdownInput).subscribe((stateResponse) => {
      const response: OrderMasterDropdownDTO = stateResponse;
      this.drpChart = response?.charts;
      this.drpPlan = response?.typeOfPlans;
      // this.drpVerified = response?.verifiedBies;
      // this.drpPrepared = response?.preparedBies;
      this.drpPayable = response?.payablePercents;
      this.drpPlanType = response?.planTypes;
      this.drpYearType = response?.yearTypes;

    }, err => {
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    });
    this.subscription$.push(orderDropdown);
    //
    setTimeout(() => {
      const storeSubscription = this.patientService.get(this.patientId).subscribe(response => {
        const patientLock: PatientLockDTO = response?.patientLock;
        // Patient is locked & userId is not equal to loginUserId
        this.isPatientLockedByCurrentUser = (patientLock?.isLocked == true &&
          patientLock?.userId == this.strLoginUserId) ||
          (patientLock?.isLocked == false) ? true : false;
        let patientDetails: PatientDTO;
        patientDetails = response
        this.patientids = response?.defaultPatientId;
        this.drpPolicies = patientDetails?.policies;
        this.verificationForm.patchValue({
          txtName: patientDetails?.personals?.fullName,
          txtAddress: patientDetails?.addresses?.length !== 0 ? patientDetails?.addresses[0]?.address : "",
          txtDateOfBirth: patientDetails?.personals?.dateOfBirth,
        })
      }, err => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      });
      this.subscription$.push(storeSubscription);
    }, 1000);

    let patientDropdownInput: PatientDropdowns[];
    patientDropdownInput = [PatientDropdowns.PayorLevels]
    const patietDropdown = this.patientDropdownService.getPatientDropdownsByInput(patientDropdownInput).subscribe((stateResponse) => {
      const response: PatientMasterDropdownDTO = stateResponse;
      this.payorLevel = response?.payorLevels;
      // this.drpYearType = response?.year;

    }, err => {
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    });
    this.subscription$.push(patietDropdown);
  }
  // ! Insurance Verify Save Function
  orderId: any
  saveVerify() {
    let personalDetails: PatientDTO;
    if (this.patientId !== "" && this.patientId !== null && this.patientId !== undefined) {
      this.patientService.get(this.patientId).subscribe(response => {
        personalDetails = response;
        // this.saveInsuranceVerify(personalDetails);
      }, err => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      })
    }
  }
  getTableData(orderId) {
   }

  insuranceVerificationId: string = "00000000-0000-0000-0000-000000000000";
  primaryInsuranceVerifies: any;
  secondaryInsuranceVerifies: any;
  tertiaryInsuranceVerifies: any;

  verifyInsurance() {
    let personalDetails: PatientDTO;
    this.patientService.get(this.patientId).subscribe(response => {
      personalDetails = response;
      this.verifyTableData = [];
      if (personalDetails.insuranceVerifications) {
        const savedData = {
          primaryInsuranceVerifies: personalDetails.insuranceVerifications.primaryInsuranceVerifies,
          secondaryInsuranceVerifies: personalDetails.insuranceVerifications.secondaryInsuranceVerifies,
          tertiaryInsuranceVerifies: personalDetails.insuranceVerifications.tertiaryInsuranceVerifies
        }
        return this.saveInsuranceVerify(personalDetails, savedData);
      }
    }, err => {
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    })
  }
  saveInsuranceVerify(response, savedData) {
    this.isShowSpinner = true;
    const payorLevelName = this.payorLevel.map(v => v.payorLevelName);
    if (this.verificationForm.value.txtPlan === payorLevelName[0]) {

      savedData.primaryInsuranceVerifies = {
        insuranceVerificationId: this.insuranceVerificationId,
        chart: this.verificationForm.value?.txtName,
        name: this.verificationForm.value?.txtName,
        address: this.verificationForm.value?.txtName,
        dateofBirth: this.verificationForm.value?.txtDateOfBirth,
        commentsOrNotes: this.verificationForm.value?.txtCommentsNotes,
        plan: this.verificationForm.value?.txtPlan,
        planName: this.verificationForm.value?.txtPlanName,
        planPhone: this.verificationForm.value?.txtPlanPhone,
        policy: this.verificationForm.value?.txtPolicy,
        groupName: this.verificationForm.value?.txtGroup,
        effectivedate: this.verificationForm.value?.txtEffectiveDate,
        typeOfPolicy: this.verificationForm.value?.txtType,
        fascalYearStart: this.verificationForm.value?.txtFascalYearStart,
        end: this.verificationForm.value?.txtEnd,
        isMedicareCmn: +this.verificationForm.value?.chkMedicare,
        isPatientHome: +this.verificationForm.value?.chkPatient,
        isFamilyDeductible: +this.verificationForm.value?.chkFamilyDeductible,
        cmnCode: this.verificationForm.value?.txtCmnCode,
        typeOfPlan: this.verificationForm.value?.drpTypeOfPlan,
        isIndividual: +this.verificationForm.value?.chkIndividual,
        isOutOfNetwork: +this.verificationForm.value?.chkOutOfNetwork,
        isFamily: +this.verificationForm.value?.chkFamily,
        planDeductible: {
          individual: this.verificationForm.value?.txtDeductibleIndividual,
          outOfNetwork: this.verificationForm.value?.txtDeductibleOutOfNetwork,
          family: this.verificationForm.value?.txtDeductibleFamily,
        },

        deductibleMet: {
          individual: this.verificationForm.value?.txtDeductibleMetIndividual,
          outOfNetwork: this.verificationForm.value?.txtDeductibleMetOutOfNetwork,
          family: this.verificationForm.value?.txtDeductibleMetFamily,
        },
        payablePercent: {
          individual: this.verificationForm.value?.drpPayableIndividual,
          outOfNetwork: this.verificationForm.value?.drpPayableOutOfNetwork,
          family: this.verificationForm.value?.drpPayableFamily,
        },
        outOfPocketExpense: {
          individual: this.verificationForm.value?.txtExpenseIndividual,
          outOfNetwork: this.verificationForm.value?.txtExpenseOutOfNetwork,
          family: this.verificationForm.value?.txtExpenseFamily,
        },
        outOfPocketExpenseMet: {
          individual: this.verificationForm.value?.txtExpenseMetIndividual,
          outOfNetwork: this.verificationForm.value?.txtExpenseMetOutOfNetwork,
          family: this.verificationForm.value?.txtExpenseMetFamily,
        },
        outOfPocketIncludeDeductible: {
          individual: +(this.verificationForm.value?.chkExpenseIndividual == (null || undefined) ? 0 : (this.verificationForm.value?.chkExpenseIndividual)),
          outOfNetwork: +(this.verificationForm.value?.chkExpenseOutOfNetwork == (null || undefined) ? 0 : (this.verificationForm.value?.chkExpenseOutOfNetwork)),
          family: +(this.verificationForm.value?.chkExpenseFamily == (null || undefined) ? 0 : (this.verificationForm.value?.chkExpenseFamily)),
        },
        isPercertRequired: +this.verificationForm.value?.chkPrecertRequired,
        phone: this.verificationForm.value?.txtPhoneCall,
        isReferralRequired: +this.verificationForm.value?.chkReferralRequired,
        pcp: this.verificationForm.value?.txtPcp,
        isPredetermination: +(this.verificationForm.value?.chkPredetermination == (null || undefined) ? 0 : (this.verificationForm.value?.chkPredetermination)),
        preNotes: this.verificationForm.value?.txtPreNotes,
        lifeTimeMax: this.verificationForm.value?.txtLifetimeMax,
        lifeTimeMaxMet: this.verificationForm.value?.txtLifetimeMaxMet,
        isDmePartsCovered: +this.verificationForm.value?.chkDmePartsCovered,
        isDmeReplaceMentUnit: +this.verificationForm.value?.chkDmeReplacementUnit,
        dmeAnnualLimit: this.verificationForm.value?.txtDmeAnnualLimit,
        dmeLimitMet: this.verificationForm.value?.txtDmeLimitMet,
        requestedBy: this.verificationForm.value?.txtRequestedBy,
        primaryPayor: this.verificationForm.value?.txtPrimaryPayor,
        contact: this.verificationForm.value?.txtContact,
        callTrackingNumber: this.verificationForm.value?.txtCallTrackingNumber,
        policyHolderName: this.verificationForm.value?.txtPolicyHolderName,
        policyHolderDob: this.verificationForm.value?.txtPolicyHolderDob,
        isNetwork: +this.verificationForm.value?.chkNetwork,
        claimAddress: this.verificationForm.value?.txtClaimAddress,
        planCob: this.verificationForm.value?.txtPlanCob,
        dedTotal: this.verificationForm.value?.txtDedTotal,
        dedMet: this.verificationForm.value?.txtDedMet,
        oopTotal: this.verificationForm.value?.txtOopTotal,
        oopMet: this.verificationForm.value?.txtOopMet,
        oop: this.verificationForm.value?.txtOopDeductible,
        dmeCoverage: this.verificationForm.value?.txtDmeCoverage,
        dmeMax: this.verificationForm.value?.txtDmeMax,
        hcpcs: this.verificationForm.value?.txtHcpcs,
        coverageCriteria: this.verificationForm.value?.txtCoverageCriteria,
        qtyLimits: this.verificationForm.value?.txtQtyLimits,
        frequency: this.verificationForm.value?.txtFrequency,
        rent: this.verificationForm.value?.txtRent,
        paRequired: this.verificationForm.value?.txtPaRequired,
        codesRequirePa: this.verificationForm.value?.txtCodesRequirePa,
        sameOrSimilarInfo: this.verificationForm.value?.txtSameOrSimilarInfo,
        spokeWith: this.verificationForm.value?.txtSpokeWith,
        verifiedBy: this.verificationForm.value?.drpVerifiedBy,
        dateVerified: this.verificationForm.value?.txtDateVerified,
        preparedBy: this.verificationForm.value?.drpPreparedBy,
        preparedOn: this.verificationForm.value?.txtPreparedOn,
        verificationStatus: this.verificationForm.value?.rbtnVerificationStatus,
        deo: this.verificationForm.value?.txtDeo,
        coIns: this.verificationForm.value?.txtCoIns,
        coInsurance: this.verificationForm.value?.txtCoInsurance,
        moveToAuthStatus: "",
        commentsToTech: this.verificationForm.value?.txtcommentsToTech,
        verifiedMethod: this.verificationForm.value?.txtVerfnAMethod,
        isActive: +this.verificationForm.value?.chkActive,
        // newly added
        isBlueCardEligible: +(this.verificationForm.value?.chkIsBlueCardEligible == (null || undefined) ? 0 : (this.verificationForm.value?.chkIsBlueCardEligible)),
        isDirectBill: + (this.verificationForm.value?.chkIsDirectBill == (null || undefined) ? 0 : (this.verificationForm.value?.chkIsDirectBill)),
        isPreAuthNeeded: +(this.verificationForm.value?.chkIsPreAuthNeeded == (null || undefined) ? 0 : (this.verificationForm.value?.chkIsPreAuthNeeded)),
        isClaimSubmit: +(this.verificationForm.value?.chkIsClaimSubmit == (null || undefined) ? 0 : (this.verificationForm.value?.chkIsClaimSubmit)),
        patientResposponsiblePaymentPercentage: String(this.verificationForm.value?.txtPatientResposponsiblePaymentPercentage),
        isReferenceNumberAvailable: +(this.verificationForm.value?.chkIsReferenceNumberAvailable == (null || undefined) ? 0 : (this.verificationForm.value?.chkIsClaimSubmit)),
        referenceNumber: this.verificationForm.value?.txtReferenceNumber
      }

    } else if (this.verificationForm.value.txtPlan === payorLevelName[1]) {

      savedData.secondaryInsuranceVerifies = {
        insuranceVerificationId: this.insuranceVerificationId,
        chart: this.verificationForm.value?.txtName,
        name: this.verificationForm.value?.txtName,
        address: this.verificationForm.value?.txtName,
        dateofBirth: this.verificationForm.value?.txtDateOfBirth,
        commentsOrNotes: this.verificationForm.value?.txtCommentsNotes,
        plan: this.verificationForm.value?.txtPlan,
        planName: this.verificationForm.value?.txtPlanName,
        planPhone: this.verificationForm.value?.txtPlanPhone,
        policy: this.verificationForm.value?.txtPolicy,
        groupName: this.verificationForm.value?.txtGroup,
        effectivedate: this.verificationForm.value?.txtEffectiveDate,
        typeOfPolicy: this.verificationForm.value?.txtType,
        fascalYearStart: this.verificationForm.value?.txtFascalYearStart,
        end: this.verificationForm.value?.txtEnd,
        isMedicareCmn: +this.verificationForm.value?.chkMedicare,
        isPatientHome: +this.verificationForm.value?.chkPatient,
        isFamilyDeductible: +this.verificationForm.value?.chkFamilyDeductible,
        cmnCode: this.verificationForm.value?.txtCmnCode,
        typeOfPlan: this.verificationForm.value?.drpTypeOfPlan,
        isIndividual: +this.verificationForm.value?.chkIndividual,
        isOutOfNetwork: +this.verificationForm.value?.chkOutOfNetwork,
        isFamily: +this.verificationForm.value?.chkFamily,
        planDeductible: {
          individual: this.verificationForm.value?.txtDeductibleIndividual,
          outOfNetwork: this.verificationForm.value?.txtDeductibleOutOfNetwork,
          family: this.verificationForm.value?.txtDeductibleFamily,
        },

        deductibleMet: {
          individual: this.verificationForm.value?.txtDeductibleMetIndividual,
          outOfNetwork: this.verificationForm.value?.txtDeductibleMetOutOfNetwork,
          family: this.verificationForm.value?.txtDeductibleMetFamily,
        },
        payablePercent: {
          individual: this.verificationForm.value?.drpPayableIndividual,
          outOfNetwork: this.verificationForm.value?.drpPayableOutOfNetwork,
          family: this.verificationForm.value?.drpPayableFamily,
        },
        outOfPocketExpense: {
          individual: this.verificationForm.value?.txtExpenseIndividual,
          outOfNetwork: this.verificationForm.value?.txtExpenseOutOfNetwork,
          family: this.verificationForm.value?.txtExpenseFamily,
        },
        outOfPocketExpenseMet: {
          individual: this.verificationForm.value?.txtExpenseMetIndividual,
          outOfNetwork: this.verificationForm.value?.txtExpenseMetOutOfNetwork,
          family: this.verificationForm.value?.txtExpenseMetFamily,
        },
        outOfPocketIncludeDeductible: {
          individual: +(this.verificationForm.value?.chkExpenseIndividual == (null || undefined) ? 0 : (this.verificationForm.value?.chkExpenseIndividual)),
          outOfNetwork: +(this.verificationForm.value?.chkExpenseOutOfNetwork == (null || undefined) ? 0 : (this.verificationForm.value?.chkExpenseOutOfNetwork)),
          family: +(this.verificationForm.value?.chkExpenseFamily == (null || undefined) ? 0 : (this.verificationForm.value?.chkExpenseFamily)),
        },
        isPercertRequired: +this.verificationForm.value?.chkPrecertRequired,
        phone: this.verificationForm.value?.txtPhoneCall,
        isReferralRequired: +this.verificationForm.value?.chkReferralRequired,
        pcp: this.verificationForm.value?.txtPcp,
        isPredetermination: +(this.verificationForm.value?.chkPredetermination == (null || undefined) ? 0 : (this.verificationForm.value?.chkPredetermination)),
        preNotes: this.verificationForm.value?.txtPreNotes,
        lifeTimeMax: this.verificationForm.value?.txtLifetimeMax,
        lifeTimeMaxMet: this.verificationForm.value?.txtLifetimeMaxMet,
        isDmePartsCovered: +this.verificationForm.value?.chkDmePartsCovered,
        isDmeReplaceMentUnit: +this.verificationForm.value?.chkDmeReplacementUnit,
        dmeAnnualLimit: this.verificationForm.value?.txtDmeAnnualLimit,
        dmeLimitMet: this.verificationForm.value?.txtDmeLimitMet,
        requestedBy: this.verificationForm.value?.txtRequestedBy,
        primaryPayor: this.verificationForm.value?.txtPrimaryPayor,
        contact: this.verificationForm.value?.txtContact,
        callTrackingNumber: this.verificationForm.value?.txtCallTrackingNumber,
        policyHolderName: this.verificationForm.value?.txtPolicyHolderName,
        policyHolderDob: this.verificationForm.value?.txtPolicyHolderDob,
        isNetwork: +this.verificationForm.value?.chkNetwork,
        claimAddress: this.verificationForm.value?.txtClaimAddress,
        planCob: this.verificationForm.value?.txtPlanCob,
        dedTotal: this.verificationForm.value?.txtDedTotal,
        dedMet: this.verificationForm.value?.txtDedMet,
        oopTotal: this.verificationForm.value?.txtOopTotal,
        oopMet: this.verificationForm.value?.txtOopMet,
        oop: this.verificationForm.value?.txtOopDeductible,
        dmeCoverage: this.verificationForm.value?.txtDmeCoverage,
        dmeMax: this.verificationForm.value?.txtDmeMax,
        hcpcs: this.verificationForm.value?.txtHcpcs,
        coverageCriteria: this.verificationForm.value?.txtCoverageCriteria,
        qtyLimits: this.verificationForm.value?.txtQtyLimits,
        frequency: this.verificationForm.value?.txtFrequency,
        rent: this.verificationForm.value?.txtRent,
        paRequired: this.verificationForm.value?.txtPaRequired,
        codesRequirePa: this.verificationForm.value?.txtCodesRequirePa,
        sameOrSimilarInfo: this.verificationForm.value?.txtSameOrSimilarInfo,
        spokeWith: this.verificationForm.value?.txtSpokeWith,
        verifiedBy: this.verificationForm.value?.drpVerifiedBy,
        dateVerified: this.verificationForm.value?.txtDateVerified,
        preparedBy: this.verificationForm.value?.drpPreparedBy,
        preparedOn: this.verificationForm.value?.txtPreparedOn,
        verificationStatus: this.verificationForm.value?.rbtnVerificationStatus,
        deo: this.verificationForm.value?.txtDeo,
        coIns: this.verificationForm.value?.txtCoIns,
        coInsurance: this.verificationForm.value?.txtCoInsurance,
        moveToAuthStatus: "",
        commentsToTech: this.verificationForm.value?.txtcommentsToTech,
        verifiedMethod: this.verificationForm.value?.txtVerfnAMethod,
        isActive: +this.verificationForm.value?.chkActive,
        // newly added
        isBlueCardEligible: +(this.verificationForm.value?.chkIsBlueCardEligible == (null || undefined) ? 0 : (this.verificationForm.value?.chkIsBlueCardEligible)),
        isDirectBill: + (this.verificationForm.value?.chkIsDirectBill == (null || undefined) ? 0 : (this.verificationForm.value?.chkIsDirectBill)),
        isPreAuthNeeded: +(this.verificationForm.value?.chkIsPreAuthNeeded == (null || undefined) ? 0 : (this.verificationForm.value?.chkIsPreAuthNeeded)),
        isClaimSubmit: +(this.verificationForm.value?.chkIsClaimSubmit == (null || undefined) ? 0 : (this.verificationForm.value?.chkIsClaimSubmit)),
        patientResposponsiblePaymentPercentage: String(this.verificationForm.value?.txtPatientResposponsiblePaymentPercentage),
        isReferenceNumberAvailable: +(this.verificationForm.value?.chkIsReferenceNumberAvailable == (null || undefined) ? 0 : (this.verificationForm.value?.chkIsClaimSubmit)),
        referenceNumber: this.verificationForm.value?.txtReferenceNumber
      }
    } else if (this.verificationForm.value.txtPlan === payorLevelName[2]) {

      savedData.tertiaryInsuranceVerifies = {
        insuranceVerificationId: this.insuranceVerificationId,
        chart: this.verificationForm.value?.txtName,
        name: this.verificationForm.value?.txtName,
        address: this.verificationForm.value?.txtName,
        dateofBirth: this.verificationForm.value?.txtDateOfBirth,
        commentsOrNotes: this.verificationForm.value?.txtCommentsNotes,
        plan: this.verificationForm.value?.txtPlan,
        planName: this.verificationForm.value?.txtPlanName,
        planPhone: this.verificationForm.value?.txtPlanPhone,
        policy: this.verificationForm.value?.txtPolicy,
        groupName: this.verificationForm.value?.txtGroup,
        effectivedate: this.verificationForm.value?.txtEffectiveDate,
        typeOfPolicy: this.verificationForm.value?.txtType,
        fascalYearStart: this.verificationForm.value?.txtFascalYearStart,
        end: this.verificationForm.value?.txtEnd,
        isMedicareCmn: +this.verificationForm.value?.chkMedicare,
        isPatientHome: +this.verificationForm.value?.chkPatient,
        isFamilyDeductible: +this.verificationForm.value?.chkFamilyDeductible,
        cmnCode: this.verificationForm.value?.txtCmnCode,
        typeOfPlan: this.verificationForm.value?.drpTypeOfPlan,
        isIndividual: +this.verificationForm.value?.chkIndividual,
        isOutOfNetwork: +this.verificationForm.value?.chkOutOfNetwork,
        isFamily: +this.verificationForm.value?.chkFamily,
        planDeductible: {
          individual: this.verificationForm.value?.txtDeductibleIndividual,
          outOfNetwork: this.verificationForm.value?.txtDeductibleOutOfNetwork,
          family: this.verificationForm.value?.txtDeductibleFamily,
        },

        deductibleMet: {
          individual: this.verificationForm.value?.txtDeductibleMetIndividual,
          outOfNetwork: this.verificationForm.value?.txtDeductibleMetOutOfNetwork,
          family: this.verificationForm.value?.txtDeductibleMetFamily,
        },
        payablePercent: {
          individual: this.verificationForm.value?.drpPayableIndividual,
          outOfNetwork: this.verificationForm.value?.drpPayableOutOfNetwork,
          family: this.verificationForm.value?.drpPayableFamily,
        },
        outOfPocketExpense: {
          individual: this.verificationForm.value?.txtExpenseIndividual,
          outOfNetwork: this.verificationForm.value?.txtExpenseOutOfNetwork,
          family: this.verificationForm.value?.txtExpenseFamily,
        },
        outOfPocketExpenseMet: {
          individual: this.verificationForm.value?.txtExpenseMetIndividual,
          outOfNetwork: this.verificationForm.value?.txtExpenseMetOutOfNetwork,
          family: this.verificationForm.value?.txtExpenseMetFamily,
        },
        outOfPocketIncludeDeductible: {
          individual: +(this.verificationForm.value?.chkExpenseIndividual == (null || undefined) ? 0 : (this.verificationForm.value?.chkExpenseIndividual)),
          outOfNetwork: +(this.verificationForm.value?.chkExpenseOutOfNetwork == (null || undefined) ? 0 : (this.verificationForm.value?.chkExpenseOutOfNetwork)),
          family: +(this.verificationForm.value?.chkExpenseFamily == (null || undefined) ? 0 : (this.verificationForm.value?.chkExpenseFamily)),
        },
        isPercertRequired: +this.verificationForm.value?.chkPrecertRequired,
        phone: this.verificationForm.value?.txtPhoneCall,
        isReferralRequired: +this.verificationForm.value?.chkReferralRequired,
        pcp: this.verificationForm.value?.txtPcp,
        isPredetermination: +(this.verificationForm.value?.chkPredetermination == (null || undefined) ? 0 : (this.verificationForm.value?.chkPredetermination)),
        preNotes: this.verificationForm.value?.txtPreNotes,
        lifeTimeMax: this.verificationForm.value?.txtLifetimeMax,
        lifeTimeMaxMet: this.verificationForm.value?.txtLifetimeMaxMet,
        isDmePartsCovered: +this.verificationForm.value?.chkDmePartsCovered,
        isDmeReplaceMentUnit: +this.verificationForm.value?.chkDmeReplacementUnit,
        dmeAnnualLimit: this.verificationForm.value?.txtDmeAnnualLimit,
        dmeLimitMet: this.verificationForm.value?.txtDmeLimitMet,
        requestedBy: this.verificationForm.value?.txtRequestedBy,
        primaryPayor: this.verificationForm.value?.txtPrimaryPayor,
        contact: this.verificationForm.value?.txtContact,
        callTrackingNumber: this.verificationForm.value?.txtCallTrackingNumber,
        policyHolderName: this.verificationForm.value?.txtPolicyHolderName,
        policyHolderDob: this.verificationForm.value?.txtPolicyHolderDob,
        isNetwork: +this.verificationForm.value?.chkNetwork,
        claimAddress: this.verificationForm.value?.txtClaimAddress,
        planCob: this.verificationForm.value?.txtPlanCob,
        dedTotal: this.verificationForm.value?.txtDedTotal,
        dedMet: this.verificationForm.value?.txtDedMet,
        oopTotal: this.verificationForm.value?.txtOopTotal,
        oopMet: this.verificationForm.value?.txtOopMet,
        oop: this.verificationForm.value?.txtOopDeductible,
        dmeCoverage: this.verificationForm.value?.txtDmeCoverage,
        dmeMax: this.verificationForm.value?.txtDmeMax,
        hcpcs: this.verificationForm.value?.txtHcpcs,
        coverageCriteria: this.verificationForm.value?.txtCoverageCriteria,
        qtyLimits: this.verificationForm.value?.txtQtyLimits,
        frequency: this.verificationForm.value?.txtFrequency,
        rent: this.verificationForm.value?.txtRent,
        paRequired: this.verificationForm.value?.txtPaRequired,
        codesRequirePa: this.verificationForm.value?.txtCodesRequirePa,
        sameOrSimilarInfo: this.verificationForm.value?.txtSameOrSimilarInfo,
        spokeWith: this.verificationForm.value?.txtSpokeWith,
        verifiedBy: this.verificationForm.value?.drpVerifiedBy,
        dateVerified: this.verificationForm.value?.txtDateVerified,
        preparedBy: this.verificationForm.value?.drpPreparedBy,
        preparedOn: this.verificationForm.value?.txtPreparedOn,
        verificationStatus: this.verificationForm.value?.rbtnVerificationStatus,
        deo: this.verificationForm.value?.txtDeo,
        coIns: this.verificationForm.value?.txtCoIns,
        coInsurance: this.verificationForm.value?.txtCoInsurance,
        moveToAuthStatus: "",
        commentsToTech: this.verificationForm.value?.txtcommentsToTech,
        verifiedMethod: this.verificationForm.value?.txtVerfnAMethod,
        isActive: +this.verificationForm.value?.chkActive,
        // newly added
        isBlueCardEligible: +(this.verificationForm.value?.chkIsBlueCardEligible == (null || undefined) ? 0 : (this.verificationForm.value?.chkIsBlueCardEligible)),
        isDirectBill: + (this.verificationForm.value?.chkIsDirectBill == (null || undefined) ? 0 : (this.verificationForm.value?.chkIsDirectBill)),
        isPreAuthNeeded: +(this.verificationForm.value?.chkIsPreAuthNeeded == (null || undefined) ? 0 : (this.verificationForm.value?.chkIsPreAuthNeeded)),
        isClaimSubmit: +(this.verificationForm.value?.chkIsClaimSubmit == (null || undefined) ? 0 : (this.verificationForm.value?.chkIsClaimSubmit)),
        patientResposponsiblePaymentPercentage: String(this.verificationForm.value?.txtPatientResposponsiblePaymentPercentage),
        isReferenceNumberAvailable: +(this.verificationForm.value?.chkIsReferenceNumberAvailable == (null || undefined) ? 0 : (this.verificationForm.value?.chkIsClaimSubmit)),
        referenceNumber: this.verificationForm.value?.txtReferenceNumber
      }
    }


    let insVerification: CreateUpdateInsuranceVerificationsDTO;
    const verifyData = savedData

    insVerification = {
      // personals: response.personals,
      // addresses: response.addresses,
      // contacts: response.contacts,
      // clinicals: response.clinicals,
      // insurances: response.insurances,
      // notes: response.notes,
      // policies: response.policies,
      // defaultPatientId: response.defaultPatientId,
      // insuranceVerifications: {
      primaryInsuranceVerifies: verifyData.primaryInsuranceVerifies,
      secondaryInsuranceVerifies: verifyData.secondaryInsuranceVerifies,
      tertiaryInsuranceVerifies: verifyData.tertiaryInsuranceVerifies,
      // },
      // authorizations: response.authorizations,
      // patientDocuments: response.patientDocuments,
      // organizationUnitId: response.organizationUnitId,
      // isAutoIntake: response.isAutoIntake,
      // autoIntakeDocumentDetails: response.autoIntakeDocumentDetails,
      // chartId: response.chartId


    }
    this.patientId !== "" && this.store.dispatch(new addVerification(this.patientId, insVerification)).subscribe(response => {
      // Swal.fire({ title: 'Success', html: 'Saved Successfully', icon: 'success', timer: 3000, timerProgressBar: true })
      this.toastr.success('Saved Successfully','Success')
      this.Table.setheaderTable(this.patientId);
      const patientSaved: CreateUpdateInsuranceVerificationsDTO = response?.patientState?.selectedPatient;
      this.verificationDialogRef.close(patientSaved);
      this.eligibilityHistoryDialogReff.close(patientSaved);
      this.patientId = response?.patientState?.selectedPatient?.id
      this.Table.setheaderTable(this.patientId);
      // this.dialog.closeAll();
      this.saveVerification.emit(this.verificationForm.value.rbtnVerificationStatus)
      this.isShowSpinner = false;
    }, err => {
      this.isShowSpinner = false;
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    });
  }



  getVerifyById(response) {
    this.insuranceVerificationId = response?.insuranceVerificationId;
    this.verificationForm.patchValue({
      insuranceVerificationId: response?.insuranceVerificationId,
      //  txtChart: response?.chart,
      // txtName: response?.name,
      // txtAddress: response?.address,
      // txtDateOfBirth: response?.dateofBirth,
      txtCommentsNotes: response?.commentsOrNotes,
      txtPlan: response?.plan,
      txtPlanName: response?.planName,
      txtPlanPhone: response?.planPhone,
      txtPolicy: response?.policy,
      txtGroup: response?.group,
      txtEffectiveDate: response?.effectivedate,
      txtType: response?.typeOfPolicy,
      txtFascalYearStart: response?.fascalYearStart,
      txtEnd: response?.end,
      chkMedicare: response?.isMedicareCmn,
      chkPatient: response?.isPatientHome,
      chkFamilyDeductible: response?.isFamilyDeductible,
      txtCmnCode: response?.cmnCode,
      drpTypeOfPlan: response?.typeOfPlan,
      chkIndividual: response?.isIndividual,
      chkOutOfNetwork: response?.isOutOfNetwork,
      chkFamily: response?.isFamily,
      txtDeductibleIndividual: response?.planDeductible?.individual,
      txtDeductibleOutOfNetwork: response?.planDeductible?.outOfNetwork,
      txtDeductibleFamily: response?.planDeductible?.family,
      txtDeductibleMetIndividual: response?.deductibleMet?.individual,
      txtDeductibleMetOutOfNetwork: response?.deductibleMet?.outOfNetwork,
      txtDeductibleMetFamily: response?.deductibleMet?.family,
      drpPayableIndividual: response?.payablePercent?.individual,
      drpPayableOutOfNetwork: response?.payablePercent?.outOfNetwork,
      drpPayableFamily: response?.payablePercent?.family,
      txtExpenseIndividual: response?.outOfPocketExpense?.individual,
      txtExpenseOutOfNetwork: response?.outOfPocketExpense?.outOfNetwork,
      txtExpenseFamily: response?.outOfPocketExpense?.family,
      txtExpenseMetIndividual: response?.outOfPocketExpenseMet?.individual,
      txtExpenseMetOutOfNetwork: response?.outOfPocketExpenseMet?.outOfNetwork,
      txtExpenseMetFamily: response?.outOfPocketExpenseMet?.family,
      chkExpenseIndividual: response?.outOfPocketIncludeDeductible?.individual,
      chkExpenseOutOfNetwork: response?.outOfPocketIncludeDeductible?.outOfNetwork,
      chkExpenseFamily: response?.outOfPocketIncludeDeductible?.family,
      chkPrecertRequired: response?.isPercertRequired,
      txtPhoneCall: response?.phone,
      chkReferralRequired: response?.isReferralRequired,
      txtPcp: response?.pcp,
      chkPredetermination: response?.isPredetermination,
      txtPreNotes: response?.preNotes,
      txtLifetimeMax: response?.lifeTimeMax,
      txtLifetimeMaxMet: response?.lifeTimeMaxMet,
      chkDmePartsCovered: response?.isDmePartsCovered,
      chkDmeReplacementUnit: response?.isDmeReplaceMentUnit,
      txtDmeAnnualLimit: response?.dmeAnnualLimit,
      txtDmeLimitMet: response?.dmeLimitMet,
      txtRequestedBy: response?.requestedBy,
      txtPrimaryPayor: response?.primaryPayor,
      txtContact: response?.contact,
      txtCallTrackingNumber: response?.callTrackingNumber,
      txtPolicyHolderName: response?.policyHolderName,
      txtPolicyHolderDob: response?.policyHolderDob,
      chkNetwork: response?.isNetwork,
      txtClaimAddress: response?.claimAddress,
      txtPlanCob: response?.planCob,
      txtDedTotal: response?.dedTotal,
      txtDedMet: response?.dedMet,
      txtOopTotal: response?.oopTotal,
      txtOopMet: response?.oopMet,
      txtOopDeductible: response?.oop,
      txtDmeCoverage: response?.dmeCoverage,
      txtDmeMax: response?.dmeMax,
      txtHcpcs: response?.hcpcs,
      txtCoverageCriteria: response?.coverageCriteria,
      txtQtyLimits: response?.qtyLimits,
      txtFrequency: response?.frequency,
      txtRent: response?.rent,
      txtPaRequired: response?.paRequired,
      txtCodesRequirePa: response?.codesRequirePa,
      txtSameOrSimilarInfo: response?.sameOrSimilarInfo,
      txtSpokeWith: response?.spokeWith,
      drpVerifiedBy: response?.verifiedBy,
      txtDateVerified: response?.dateVerified,
      drpPreparedBy: response?.preparedBy,
      txtPreparedOn: response?.preparedOn,
      rbtnVerificationStatus: response?.verificationStatus,
      txtDeo: response?.deo,
      txtCoIns: response?.coIns,
      txtCoInsurance: response?.coInsurance,
      txtcommentsToTech: response?.commentsToTech,
      txtVerfnAMethod: response?.verficationMethod,
      chkActive: response?.isActive,
      // newly added
      chkIsBlueCardEligible: response?.isBlueCardEligible,
      chkIsDirectBill: response?.isDirectBill,
      chkIsPreAuthNeeded: response?.isPreAuthNeeded,
      chkIsClaimSubmit: response?.isClaimSubmit,
      txtPatientResposponsiblePaymentPercentage: response?.patientResposponsiblePaymentPercentage,
      chkIsReferenceNumberAvailable: response?.isReferenceNumberAvailable,
      txtReferenceNumber: response?.referenceNumber
    })
    // this.notesId = response?.notesId;
    // this.notesModalTitle = 'Edit Notes';
    // });
  }
  // patch value for conInsurance
  onSelection(value) {
    this.verificationForm.patchValue({ txtCoInsurance: value });
  }

}
export interface drpPolicesDTO {
  insuranceType: string
  insuranceId: string
  birthdate: string
  policyNo: string
  startDate: string
  endDate: string
  groupName: string
  groupNo: string
  fisrtName: string
  address1: string
}
