import { RestService } from '@abp/ng.core';
import { Injectable } from '@angular/core';
import { statusDTO } from 'projects/order/src/app/order-proxy/order-management/inventory/dto';

@Injectable({
  providedIn: 'root'
})
export class StatusService {
  apiName: 'platFormManagement';
  getStatusList = () =>
  this.restService.request<any, statusDTO>({
    method: 'GET',
    url: '/api/PlatformApp/inbound-status',
  },
   { apiName: this.apiName });
 
  constructor(private restService: RestService) { }
}
