import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { TableService } from 'projects/shared/src/app/table.service';
import { Subscription } from 'rxjs';
import { delay } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { Title } from '@angular/platform-browser';
import { TenantsService } from '../admin-proxy/platform-app-management/rcm/platform-management/tenant/tenants.service';
import { ActiveStatus } from '../admin-proxy/platform-app-management/rcm/platform-management/localization/enumeration/active-status.enum';

@Component({
  selector: 'app-client',
  templateUrl: './client.component.html',
  styleUrls: ['./client.component.scss'],
})
export class ClientComponent implements OnInit {
  public strPageType: string = 'clientList';
  clientTableData: any[] = [];
  clientListData: any;
  @Input() chkValue: boolean = false;
  isShownViewButton: boolean = true;
  isShownSaveButton: boolean = true;
  userId: string;
  dataSource: MatTableDataSource<any> = new MatTableDataSource<any>([]);
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatTable, { static: false }) tableRef: MatTable<any>;
  public arrDisplayedColumns: string[] = [];
  clientSearchTableData: any[] = [];
  isLoading: boolean = true;
  clientSearchForm: FormGroup;
  ischeck: number;
  tabledatav1: any;
  constructor(
    private router: Router,
    private Table: TableService,
    private clientService: TenantsService,
    private formBuilder: FormBuilder,
    public title: Title
  ) { }

  ngOnInit(): void {
    this.arrDisplayedColumns = [
      'Options',
      'ClientCode',
      'name',
      'mail',
      'taxId',
      'status',
      'date',
    ];
    this.title.setTitle('Qsecure | Client List');

    //!create
    this.Table.getTenantCreate().subscribe(
      (value) => {
        if (value == true) {
          this.isShownSaveButton = true;
        } else {
          this.isShownSaveButton = false;
        }
      },
      (err) => {
        const data: HttpErrorResponse = err;
        // Swal.fire({
        //   icon: 'error',
        //   text: data?.error?.error?.message,
        // });
      }
    );

    //!view
    this.Table.getTenantView().subscribe(
      (value) => {
        if (value == true) {
          this.isShownViewButton = true;
        } else {
          this.isShownViewButton = false;
        }
      },
      (err) => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      }
    );
    this.ischeck = ActiveStatus.All;
    this.getTableList(this.ischeck);
    this.Table.setOrderId(null);
    this.clientSearchForm = this.formBuilder.group({
      ClientName: new FormControl(),
    });

    const valueChanges: Subscription =
      this.clientSearchForm.valueChanges.subscribe(
        (value) => {
          this.dataSource.filter = JSON.stringify(value.ClientName);
          this.filterClientTableData(value.ClientName);
        },
        (err) => {
          const data: HttpErrorResponse = err;
          Swal.fire({
            icon: 'info',
            text: data?.error?.error?.message,
          });
        }
      );
  }
  //! search official
  filterClientTableData(searchValue: string) {
    this.clientService.searchClientBySSearch(searchValue).subscribe(
      (response) => {
        this.clientSearchTableData = [];
        response?.tenantData.forEach((element) => {
          this.clientSearchTableData.push({
            code: element?.defaultTenantId,
            name: element?.tenantName,
            date:
              element?.creationTime == null
                ? undefined
                : new Date(element?.creationTime)?.toLocaleDateString('en-US'),
            mail: element?.adminEmailAddress,
            status: element?.status,
            id: element?.id,
            tenantId: element?.tenantId,
          });
        });
        const arrTableData = this.clientSearchTableData;
        this.dataSource = new MatTableDataSource(arrTableData);
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
      },
      (err) => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      }
    );
  }
  change(event: boolean) {
    if (event == true) {
      this.getTableList(ActiveStatus.Active);
    } else {
      this.getTableList(ActiveStatus.All);
    }
  }
  newClient() {
    this.router.navigate(['clientCreate']);
  }
  //! client list details
  getTableList(ischeck: number) {
    this.isLoading = true;
    this.clientService.getTenantListWithOfficialInformation(ischeck).subscribe(
      (response) => {
        this.clientTableData = [];
        response &&
          response?.forEach((element) => {
            this.clientTableData.push({
              code: element?.defaultTenantId,
              name: element?.tenantName,
              mail: element?.adminEmailAddress,
              taxId: element?.taxId,
              status: element.status,
              date:
                element?.creationTime == null
                  ? undefined
                  : new Date(element?.creationTime)?.toLocaleDateString(
                    'en-US'
                  ),
              tenantId: element?.tenantId,
              userId: element?.userId,
              creatorId: element?.creatorId,

            });
            this.clientListData = response;
            this.dataSource = new MatTableDataSource(this.clientTableData);
            this.dataSource.sort = this.sort;
            this.dataSource.paginator = this.paginator;
            this.isLoading = false;
          });
        this.isLoading = false;
      },
      (err) => {
        this.isLoading = false;
        const data: HttpErrorResponse = err;
        // Swal.fire({
        //   icon: 'error',
        //   text: data?.error?.error?.message,
        // });
      }
    );
  }

  getActiveTableList() {
    this.isLoading = true;
    this.clientService.getTenantListWithOfficialInformation(1).subscribe(
      (response) => {
        this.clientTableData = [];
        response &&
          response?.forEach((element) => {
            this.clientTableData.push({
              code: element?.defaultTenantId,
              name: element?.tenantName,
              mail: element?.adminEmailAddress,
              taxId: element?.taxId,
              status: element.status,
              date:
                element?.creationTime == null
                  ? undefined
                  : new Date(element?.creationTime)?.toLocaleDateString(
                    'en-US'
                  ),
              tenantId: element?.tenantId,
              userId: element?.userId,
              creatorId: element?.creatorId,

            });
            this.clientListData = response;
            this.dataSource = new MatTableDataSource(this.clientTableData);
            this.dataSource.sort = this.sort;
            this.dataSource.paginator = this.paginator;
            this.isLoading = false;
          });
        this.isLoading = false;
      },
      (err) => {
        this.isLoading = false;
        const data: HttpErrorResponse = err;
        // Swal.fire({
        //   icon: 'error',
        //   text: data?.error?.error?.message,
        // });
      }
    );
  }
  //! active switch list
  // getActiveTableList() {
  //   this.clientService
  //     .getTenantListWithOfficialInformation()
  //     .subscribe((response) => {
  //       this.clientTableData = [];
  //       response &&
  //         response?.forEach((element) => {
  //           if (element.status == 'Active') {
  //             this.clientTableData.push({
  //               code: element?.defaultTenantId,
  //               name: element?.tenantName,
  //               mail: element?.adminEmailAddress,
  //               status: element.status,
  //               date:
  //                 element?.creationTime == null
  //                   ? undefined
  //                   : new Date(element?.creationTime)?.toLocaleDateString(
  //                     'en-US'
  //                   ),
  //               id: element?.tenantId,
  //               userId: element?.userId,
  //               creatorId: element?.creatorId,
  //             });
  //           }
  //           this.clientListData = this.clientTableData;
  //         });

  //       this.dataSource = new MatTableDataSource(this.clientTableData);
  //       this.dataSource.sort = this.sort;
  //       this.dataSource.paginator = this.paginator;
  //     });
  // }
  //! edit record
  ViewClient(event: { userId: any; tenantId: string; status: any }) {
    // this.Table.setinactiveTable(event)
    this.Table.setOrderId(event.userId ? event.userId : this.userId);
    this.router.navigate(['clientView/' + event.tenantId], {
      fragment: event.status,
    });
  }
}
function dealy(
  arg0: number
): import('rxjs').OperatorFunction<
  import('../admin-proxy/platform-app-management/rcm/platform-management/tenants').TenantOfficialInformationDTO[],
  unknown
> {
  throw new Error('Function not implemented.');
}
