<mat-dialog-content class="mat-typography">
    <div class="row">
        <h2 class="col-12 pl-4 customThemeClass">WIP Details :
            <span style="text-align: right" class="headStyle text-danger">{{defaultWIPId}}
            </span>
        </h2>
    </div>
    <mat-divider></mat-divider>
    <form [formGroup]="scheduleTaskForm">
        <div class="row pt-3">
            <mat-form-field class="col-12">
                <mat-label>
                    Schedule Date <span class="asterisk">*</span></mat-label>
                <input [min]="scheduleMaxDate" matInput maxlength="40" formControlName="txtScheduleDate" type="text"
                    placeholder="(MM/DD/YYYY)" autocomplete="new-txtScheduleDate" matInput [matDatepicker]="txtScheduleDate" />
                <mat-datepicker-toggle matSuffix [for]="txtScheduleDate">
                </mat-datepicker-toggle>
                <mat-datepicker #txtScheduleDate> </mat-datepicker>
                <mat-error *ngIf="scheduleTaskForm?.get('txtScheduleDate')?.touched &&
            scheduleTaskForm?.get('txtScheduleDate')?.errors &&
             !(  scheduleMaxDate >= scheduleTaskForm?.get('txtScheduleDate')?.value) ">
                    Enter Valid date
                </mat-error>
                <mat-error *ngIf=" scheduleTaskForm?.get('txtScheduleDate')?.touched &&
              scheduleTaskForm?.get('txtScheduleDate')?.errors?.required">
                    Schedule Date is a required field!
                </mat-error>
                <mat-error *ngIf=" scheduleMaxDate >= scheduleTaskForm?.get('txtScheduleDate')?.value &&
     !( scheduleTaskForm?.get('txtScheduleDate')?.touched && scheduleTaskForm?.get('txtScheduleDate')?.errors?.required ) ">
                    Enter Future Dates Only!
                </mat-error>
            </mat-form-field>
            <mat-form-field class="col-12">
                <mat-label>Comments</mat-label>
                <!-- <input matInput formControlName="txtComment" type="text" maxlength="1000" autocomplete="off" /> -->
                <textarea formControlName="txtComment" type="text" rows="2" maxlength="1000" autocomplete="off" matInput></textarea>
            </mat-form-field>
        </div>
    </form>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <ng-container *ngIf="!scheduleTaskForm?.disabled">
        <button [disabled]="isShowSpinner || !!scheduleTaskForm?.invalid" mat-button class="buttonColor"
        (click)="updateScheduleDetails()">
        Save
        <mat-icon *ngIf="isShowSpinner">
            <mat-spinner class="spinner-border spinner-border-sm" diameter="20">
            </mat-spinner>
        </mat-icon>
    </button>
    </ng-container>

    <button class="buttonCancelColor" mat-button mat-dialog-close>Close</button>
</mat-dialog-actions>
