<div class="container-fluid">
  <!-- Headers -->
  <div class="row">
    <div class="col-lg-12">
      <div class="d-flex align-items-center justify-content-between">
        <h3 class="font-weight-bolder mb-2 mt-1">
          <b class="customThemeClass">List / Claim Dept / Claim Check List</b>
        </h3>

        <!-- <div class="page-title-box page-title-right"> -->
          <!-- <ol class="breadcrumbs m-0 align-items-center"> -->
            <!-- <form [formGroup]="claimChequeListForm">
              <div class="row d-flex justify-content-center align-items-center">
                <ng-container *ngIf="!filterValue">
                  <mat-form-field  class="pr-1">
                    <mat-label>Select Date Range<i class="fa fa-calendar ml-2" style="color:rgb(0, 0, 0)" matSuffix align="end"></i></mat-label>

                    <input autocomplete="new-txtDateValue" matInput  type="text" name="daterange" ngxDaterangepickerMd
                    [showCustomRangeLabel]="true" [alwaysShowCalendars]="true" [(ngModel)]="selectedRange"
                      [linkedCalendars]="false" [ranges]="ranges" [showClearButton]="false" [locale]="calendarLocale"
                       [keepCalendarOpeningWithRange]="true"  formControlName="txtDateValue"
                       (datesUpdated)="datesUpdateds($event)" [autoApply]="true"[readonly]="true" (keyup)="datesUpdateds($event)"
                      /> -->
                    <!-- <mat-error *ngIf="
                    claimChequeListForm?.get('txtDateValue')?.touched &&
                    claimChequeListForm?.get('txtDateValue')?.errors?.required
                    ">
                    Auth ID is a required field!
                  </mat-error> -->
                    <!-- <mat-date-range-input [rangePicker]="picker">
                      <input
                        matStartDate
                        matInput
                        placeholder="Start date"
                        formControlName="txtDateValue"
                      />
                      <input
                        matEndDate
                        matInput
                        placeholder="End date"
                        formControlName="txtDate"
                        (dateChange)="onDateRangeChange()"
                      />
                    </mat-date-range-input>
                    <mat-datepicker-toggle
                      matSuffix
                      [for]="picker"
                    ></mat-datepicker-toggle>
                    <mat-date-range-picker #picker></mat-date-range-picker> -->
                  <!-- </mat-form-field>
                  </ng-container>
                  <ng-container *ngIf="filterValue">

                  <mat-form-field  class="pr-3 dd-text">
                    <mat-label>Check No</mat-label>
                    <input autocomplete="new-txtTicketNo" matInput formControlName="txtTicketNo" maxlength="10"
                    (input)="sanitizeInput($event, 'txtTicketNo');handleInputChange($event)"/>
                  </mat-form-field>
              </ng-container>
                <div class="pr-3 dd-text ">
                  <app-check-filter-switch class="h-100" [onText]="'Check'" [offText]="'Date'"
                    (changed)="filterChange($event)">
                  </app-check-filter-switch>
                </div>
              </div>

            </form> -->
          <!-- </ol> -->
        <!-- </div> -->
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-12">
      <div class="card card-body p-2">
        <form [formGroup]="claimChequeListForm">
           <div class="row">
            <div class="col-12">
              <mat-radio-group  formControlName="isDateRange"> Search By
              <mat-radio-button class="radio pl-3" [checked]="true" [value]="true"><b><span class="fontresize">Date Range
                          </span></b></mat-radio-button>
              <mat-radio-button class="radio"  [value]="false"><b><span class="fontresize">Others
                          </span></b></mat-radio-button>
              </mat-radio-group>
            </div>
           </div>
           <mat-divider class="mb-1"></mat-divider>
           <div class="row" *ngIf="claimChequeListForm.get('isDateRange').value==true">
                <!-- <mat-form-field  class="pr-1 col-2">
                  <mat-label>Select Date Range<i class="fa fa-calendar ml-2" style="color:rgb(0, 0, 0)" matSuffix align="end"></i></mat-label>
                  <input autocomplete="new-txtDateValue" matInput  type="text" name="daterange" ngxDaterangepickerMd
                  [showCustomRangeLabel]="true" [alwaysShowCalendars]="true" [(ngModel)]="selectedRange"
                    [linkedCalendars]="false" [ranges]="ranges" [showClearButton]="false" [locale]="calendarLocale"
                     [keepCalendarOpeningWithRange]="true"  formControlName="txtDateValue"
                     (datesUpdated)="datesUpdateds($event)" [autoApply]="true" (keyup)="datesUpdateds($event)"
                    />
                    <mat-icon matSuffix>date_range  </mat-icon>
                </mat-form-field> -->
                <mat-form-field  class="pr-1 col-2">
                <mat-label>Select Date Range</mat-label>
                <mat-date-range-input [rangePicker]="picker">
                  <!-- <input matStartDate matInput [(ngModel)]="checkFromDate" placeholder="Start date"
                    formControlName="txtStartDateValue" (keydown)="addSlashToDate($event)" maxlength="10" />
                  <input matEndDate matInput (dateChange)="datesUpdateds($event)" [(ngModel)]="checkToDate"
                    placeholder="End date" formControlName="txtEndDateValue" (keydown)="addSlashToDate($event)"
                    maxlength="10" /> -->
                    <input
                    matStartDate
                    matInput
                    placeholder="Start date"
                    formControlName="txtStartDateValue"
                    maxlength="10"
                    (keydown)="addSlashToDate($event)"
                  />

                  <input
                    matEndDate
                    matInput
                    placeholder="End date"
                    formControlName="txtEndDateValue"
                    (dateChange)="onDateRangeChange()"
                    maxlength="10"
                    (keydown)="addSlashToDate($event)"
                  />
                </mat-date-range-input>
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-date-range-picker #picker></mat-date-range-picker>
                <!-- <mat-error *ngIf="claimChequeListForm.get('txtStartDateValue').hasError('required')">Start date is
                  required</mat-error>
                <mat-error
                  *ngIf="claimChequeListForm.get('txtStartDateValue').invalid && !claimChequeListForm.get('txtStartDateValue').hasError('required')">Invalid
                  start date</mat-error>

                <mat-error *ngIf="claimChequeListForm.get('txtEndDateValue').hasError('required')">End date is
                  required</mat-error>
                <mat-error
                  *ngIf="claimChequeListForm.get('txtEndDateValue').invalid && !claimChequeListForm.get('txtEndDateValue').hasError('required')">Invalid
                  end date</mat-error>
                <mat-error *ngIf="claimChequeListForm.hasError('dateRangeError')">From date must be less than or equal to
                  To date</mat-error> -->
                  <mat-error *ngIf="claimChequeListForm.get('txtStartDateValue')?.errors">
                    From date must be less than or equal to
                    To date</mat-error>

                    <mat-error *ngIf="claimChequeListForm.get('txtStartDateValue')?.errors">
                      From date must be less than or equal to
                      To date</mat-error>
                      <mat-error *ngIf="claimChequeListForm.get('txtStartDateValue').value > claimChequeListForm.get('txtEndDateValue').value">
                        From date must be less than or equal to
                        To date</mat-error>
              </mat-form-field>




                <div class=" all-icon" matTooltip="Search" style="padding-left: 15px;margin-top: 10px;">
          <i class="fa fa-search search align-items-center" (click)="loadChequeList()"
          [class.disabled]="claimChequeListForm?.get('txtStartDateValue')?.errors||claimChequeListForm?.get('txtEndDateValue')?.errors||
          claimChequeListForm?.get('txtStartDateValue')?.value===null||claimChequeListForm?.get('txtEndDateValue')?.value===null||
          claimChequeListForm?.get('txtStartDateValue')?.value===''||claimChequeListForm?.get('txtEndDateValue')?.value===''||
          claimChequeListForm?.get('txtStartDateValue')?.value===undefined|| claimChequeListForm?.get('txtEndDateValue')?.value===undefined"
           ></i>

                </div>
                &nbsp; &nbsp;
                <div class=" all-icon" matTooltip="Reset" style="margin-top: 10px;">
          <i class="fa fa-refresh reset align-items-center" (click)="reset()"></i>
                </div>
           </div>
           <div class="row" *ngIf="claimChequeListForm.get('isDateRange').value==false">
                <!--Chart No-->
               <mat-form-field class="form-field col-md-2 col-lg-2 col-xl-2">
                 <input matInput type="text" formControlName="chartNo" placeholder="Chart No"/>
               </mat-form-field>
               <!--Patient Name-->
               <mat-form-field class="form-field col-md-2 col-lg-2 col-xl-2">
                 <input matInput type="text" formControlName="patientName" placeholder="Patient Name" />
               </mat-form-field>
                 <!--Claim Number-->
                 <mat-form-field class="form-field col-md-2 col-lg-2 col-xl-2">
                  <input matInput type="text" formControlName="claimNumber" placeholder="Claim Number" />
                </mat-form-field>
                 <!--Check Number-->
                 <mat-form-field class="form-field col-md-2 col-lg-2 col-xl-2">
                  <input matInput type="text" formControlName="checkNumber" placeholder="Check Number" />
                </mat-form-field>
                <div class=" all-icon" matTooltip="Search"  style="padding-left: 15px;margin-top: 10px;">
                  <i class="fa fa-search search align-items-center" (click)="loadChequeListV1()"
                  [class.disabled]="otherSearchValidation"
                   ></i> </div>
                  &nbsp; &nbsp;
                  <div class=" all-icon" matTooltip="Reset" style="margin-top: 10px;">
                  <i class="fa fa-refresh reset align-items-center" (click)="resetV1()" ></i></div>
           </div>
        </form>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-12">
      <div class="card card-body p-2 mt-2">
        <div class="d-flex justify-content-center" *ngIf="!isClaimTableLoaded">
          <img
            class="img-responsive pb-2 mr-1"
            src="assets/gifs/Infinity-loader.gif"
          />
        </div>
        <div *ngIf="isClaimTableLoaded" class="table table-responsive">
          <app-claim-cheque-list-table
            [claimsTableData]="ltClaims"
          ></app-claim-cheque-list-table>
        </div>
      </div>
    </div>
  </div>
</div>
