<mat-dialog-content class="mat-typography">
    <div class="row">
      <div class="col-lg-6">
        <h1>Calendar Details</h1>
      </div>
    </div>
    <div class="row">
        <div class="col-lg-6">
            <h4><b class="heaading"><i class="fa fa-user" style="padding: 5px" ></i>User Name : </b> {{userName}}</h4>
        </div>
        <div class="col-lg-6">
            <h4><b class="heaading"><i class="fa fa-tasks" style="padding: 5px" ></i>Role Name : </b>{{roleName}}</h4>
        </div>
    </div>
    <div class="row">
        <div class="col-lg-6" *ngFor="let data of calendarDetailsArray">  
            <h4><b><i class="fa fa-calendar" style="padding: 2px" ></i> Calendar Id :</b> {{data?.calendarCode}}</h4>
            <h4><b style = "padding-left: 18px">Title :</b> {{data?.title}}</h4>
            <h4><b style = "padding-left: 18px">Description :</b> {{data?.description}}</h4>
            <h4><b style = "padding-left: 18px">Created DateTime :</b> {{data?.createdDateTime}}</h4>
            <h4><b style = "padding-left: 18px">Scheduled Date :</b> {{data?.scheduledDate}}</h4>
            <h4><b style = "padding-left: 18px">Scheduled Time : </b>{{data?.fromTime + ' - ' + data?.toTime}} </h4>
            <h4><b style = "padding-left: 18px">Status : </b>{{data?.status}} </h4>
            <mat-divider style="padding: 2%;"></mat-divider>
        </div>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button class="buttonCancelColor" mat-button mat-dialog-close>Close</button>
  </mat-dialog-actions>
