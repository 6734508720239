<mat-dialog-content class="mat-typography">
  <div class="row">
    <div class="col-lg-6">
      <h2 mat-dialog-title><B>Claim Info</B></h2>
    </div>
    <div class="col-lg-6 d-flex flex-row-reverse">
      <button
        type="button"
        mat-icon-button
        mat-dialog-close
        class="close-button"
      >
        <mat-icon>close</mat-icon>
      </button>
    </div>
  </div>

  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-body">
          <div class="table table-reponsive">
            <table datatable [dtOptions]="dtClaimInfoTableOptions">
              <thead>
                <tr>
                  <th>Code</th>
                  <th>M1</th>
                  <th>M2</th>
                  <th>M3</th>
                  <th>M4</th>
                  <th>POS</th>
                  <th>Description</th>
                  <th>Provider</th>
                  <th>Fee</th>
                  <th>Units</th>
                  <th>Plan</th>
                  <th>Status</th>
                  <th>Hold Reason</th>
                  <th>Hold Notes</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let procedure of procedureDataArray">
                  <td>{{ procedure.code }}</td>
                  <td>{{ procedure.m1 }}</td>
                  <td>{{ procedure.m2 }}</td>
                  <td>{{ procedure.m3 }}</td>
                  <td>{{ procedure.m4 }}</td>
                  <td>{{ procedure.pos }}</td>
                  <td>{{ procedure.description }}</td>
                  <td>{{ procedure.provider }}</td>
                  <td>{{ procedure.fee }}</td>
                  <td>{{ procedure.units }}</td>
                  <td>{{ procedure.plan }}</td>
                  <td>
                    <mat-select
                      #itemClaimStatus="ngModel"
                      [(ngModel)]="procedure.status"
                    >
                      <mat-option [value]="1">Hold</mat-option>
                      <mat-option [value]="2">Option B</mat-option>
                      <mat-option [value]="3">Option C</mat-option>
                    </mat-select>
                  </td>
                  <td>
                    <ng-container *ngIf="itemClaimStatus.value === 0">
                      <mat-select
                        #claimHoldReason="ngModel"
                        [(ngModel)]="procedure.holdReason"
                      >
                        <mat-option value="1">Option A</mat-option>
                        <mat-option value="2">Option B</mat-option>
                        <mat-option value="3">Option C</mat-option>
                      </mat-select></ng-container
                    >
                  </td>
                  <td>
                    <button (click)="openDialog()" mat-icon-button>
                      <!-- <mat-icon class="customThemeClass"> notes</mat-icon> -->

                      <img
                        class="img-responsive pr-2 mb-2"
                        src="assets/Opiton Icon/Patient/EditNotes.png"
                        alt=""
                        height="20"
                      />
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</mat-dialog-content>

<ng-template #myDialog>
  <div class="row">
    <div class="col-lg-6 d-flex align-items-center">
      <h2 mat-dialog-title><B>Notes</B></h2>
    </div>
    <div class="col-lg-6 d-flex flex-row-reverse">
      <button
        type="button"
        mat-icon-button
        (click)="closeDialog()"
        class="close-button"
      >
        <mat-icon>close</mat-icon>
      </button>
    </div>
  </div>

  <mat-dialog-content>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body p-0 pb-1">
            <mat-form-field class="col-12">
              <mat-label>Notes<span class="asterisk">*</span></mat-label>
              <textarea
                matInput
                rows="4"
                autocomplete="new-txtComments"
                maxlength="5000"
                type="text"
              ></textarea>
            </mat-form-field>

            <div class="text-center">
              <button mat-raised-button class="buttonColor mr-2">Save</button>
              <button mat-raised-button class="resetclr">Reset</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </mat-dialog-content>
  <!-- <mat-dialog-actions align="end">
    <button mat-raised-button color="warn" matDialogClose>Close</button>
  </mat-dialog-actions> -->
</ng-template>
