import { Component, Input, OnInit, Renderer2, ViewChild } from '@angular/core';
import { DataTableDirective } from 'angular-datatables';

@Component({
  selector: 'app-bulk-update-unit-cost-and-allowable-table',
  templateUrl: './bulk-update-unit-cost-and-allowable-table.component.html',
  styleUrls: ['./bulk-update-unit-cost-and-allowable-table.component.scss'],
})
export class BulkUpdateUnitCostAndAllowableTableComponent implements OnInit {
  @Input() tableData: any;
  dtBulkTableOptions: any = {
    dom: 'Bfrtip',
    responsive: true,
    paging: false,
    scrollX: true,
    scrollCollapse: true,
    scrollY: '400px',
    search: {
      smart: false,
    },
    language: {
      emptyTable: 'No records',
      info: 'Total : _MAX_ records',
      infoEmpty: 'Total : _MAX_ records',
      infoFiltered: '(filtered : _TOTAL_ records)',
      loadingRecords: 'Loading...',
      zeroRecords: 'No matching records',
    },
    order: [],
    buttons: [
      {
        extend: 'excel',
        text: 'Export',
        filename: 'Bulk Update UnitCost & Allowable',
        exportOptions: {
          columns: ':visible:not(:first-child)',
        },
      },
      {
        extend: 'colvis',
        columns: ':not(:eq(0), :eq(1), :eq(2), :eq(-1))',
      },
    ],
    columnDefs: [
      { targets: [0, 1, 2, -1], className: 'dt-fixed-column' },
    ],
  };

  @ViewChild(DataTableDirective, { static: false })
  datatableElement: DataTableDirective;

  @Input() currentPage: any = 1;
  @Input() totalPages: any = 1;

  constructor(private renderer: Renderer2) {}

  ngOnInit(): void {
    console.log(this.tableData);
  }

  ngAfterViewInit(): void {
    if (this.datatableElement && this.datatableElement.dtInstance) {
      this.refreshDataTable();
    } else {
      setTimeout(() => this.refreshDataTable(), 500);
    }
  }

  refreshDataTable(): void {
    this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
      $('input', dtInstance.table(0).footer()).val('');
      dtInstance.search('').columns().search('').draw();
      this.addPageNavigationControls();
      this.formatTable(this.tableData);
    });
  }

  addPageNavigationControls() {
    // Your existing pagination controls code
  }

  // formatTable(inboundData: any): HTMLElement {
  //   console.log('formatTable');
  //   const table = this.renderer.createElement('table');
  //   const thead = this.renderer.createElement('thead');
  //   const tbody = this.renderer.createElement('tbody');
  //   const thRow = this.renderer.createElement('tr');

  //   const headers = [
  //     { text: 'Product', key: 'product' },
  //     { text: 'HCPC', key: 'hcpc' },
  //     { text: 'Cost', key: 'cost' },
  //     { text: 'Insurance Category', key: 'insurance_category' },
  //     { text: 'Allowable', key: 'allowable' },
  //     { text: 'Modified Date', key: 'modified_date' },
  //   ];

  //   headers.forEach((header) => {
  //     const th = this.renderer.createElement('th');
  //     this.renderer.appendChild(th, document.createTextNode(header.text));
  //     this.renderer.appendChild(thRow, th);
  //   });

  //   this.renderer.appendChild(thead, thRow);
  //   this.renderer.appendChild(table, thead);
  //   this.renderer.setStyle(table, 'backgroundColor', '#fbfcfe');

  //   if (!Array.isArray(inboundData)) {
  //     inboundData = [inboundData];
  //   }

  //   inboundData.forEach((value: any, rowIndex: number) => {
  //     const trData = this.renderer.createElement('tr');

  //     headers.forEach((header) => {
  //       const td = this.renderer.createElement('td');
  //       const input = this.renderer.createElement('input');
  //       this.renderer.setAttribute(input, 'type', 'text');
  //       this.renderer.setAttribute(input, 'value', value[header.key] || '-');
  //       this.renderer.listen(input, 'change', (event) => {
  //         value[header.key] = (event.target as HTMLInputElement).value;
  //       });
  //       this.renderer.appendChild(td, input);
  //       this.renderer.appendChild(trData, td);
  //     });

  //     this.renderer.appendChild(tbody, trData);
  //   });

  //   this.renderer.appendChild(table, tbody);

  //   table.addEventListener('click', (event: MouseEvent) => {
  //     console.log('click');
  //     const target = event.target as HTMLElement;
  //     if (target.tagName === 'TD' && (target as HTMLTableCellElement).cellIndex !== 0) {
  //       const input = target.querySelector('input');
  //       if (input) {
  //         input.focus();
  //       }
  //     }
  //   });

  //   return table;
  // }

  formatTable(inboundData: any): HTMLElement {
    console.log('formatTable');
    const table = this.renderer.createElement('table');
    const thead = this.renderer.createElement('thead');
    const tbody = this.renderer.createElement('tbody');
    const thRow = this.renderer.createElement('tr');

    const headers = [
      { text: 'Product', key: 'product' },
      { text: 'HCPC', key: 'hcpc' },
      { text: 'Cost', key: 'cost' },
      { text: 'Insurance Category', key: 'insurance_category' },
      { text: 'Allowable', key: 'allowable' },
      { text: 'Modified Date', key: 'modified_date' },
    ];

    headers.forEach((header) => {
      const th = this.renderer.createElement('th');
      this.renderer.appendChild(th, document.createTextNode(header.text));
      this.renderer.appendChild(thRow, th);
    });

    this.renderer.appendChild(thead, thRow);
    this.renderer.appendChild(table, thead);
    this.renderer.setStyle(table, 'backgroundColor', '#fbfcfe');

    if (!Array.isArray(inboundData)) {
      inboundData = [inboundData];
    }

    inboundData.forEach((value: any, rowIndex: number) => {
      const trData = this.renderer.createElement('tr');

      headers.forEach((header) => {
        const td = this.renderer.createElement('td');
        const input = this.renderer.createElement('input');
        this.renderer.setAttribute(input, 'type', 'text');
        this.renderer.setAttribute(input, 'value', value[header.key] || '-');
        this.renderer.listen(input, 'change', (event) => {
          value[header.key] = (event.target as HTMLInputElement).value;
        });
        this.renderer.appendChild(td, input);
        this.renderer.appendChild(trData, td);
      });

      this.renderer.appendChild(tbody, trData);
    });

    this.renderer.appendChild(table, tbody);

    table.addEventListener('click', (event: MouseEvent) => {
      console.log('click');
      const target = event.target as HTMLElement;
      if (target.tagName === 'TD' && (target as HTMLTableCellElement).cellIndex !== 0) {
        const input = target.querySelector('input');
        if (input) {
          input.focus();
        }
      }
    });

    return table;
  }

  // Handle changes made in inline editing
  onEdit(item: any): void {
    console.log('Edited Item:', item);
    // Here you can make a service call to update the database or perform further actions
  }
}
