<div class="container-fluid">
  <div class="row bg mt-3">
    <div class="col-12">
      <div class="d-flex align-items-center justify-content-between mb-3">
        <!-- <h4 class="font-size-16 m-0"><b>Admin Steps /</b></h4> -->
        <h4 class="font-size-16 m-0 pt-2 pb-2 customThemeClass"><b>Settings </b></h4>
        <div class=" page-title-box page-title-right">
          <ol class="breadcrumbs m-0">
            <button *ngIf='clientLogin' mat-button class="buttonColor font-size-14" (click)="backtoClientList()" [matTooltip]="' Back To Client'">
              <i class="fa fa-backward mr-1"></i>
              Back To Client
            </button>
          </ol>

        </div>

      </div>
    </div>
  </div>
</div>
<div class="row pt-1">
</div>
<div class="col-12" style="margin-top: -23px;">
  <ul ngbNav #nav="ngbNav" justify="justified" class="alert alert-success nav nav-pills nav-fill">
    <li ngbNavItem="Official" class="nav-item" *ngIf="clientLogin && officialTabHideInUser">
      <a ngbNavLink>Official</a>
      <ng-template ngbNavContent>
        <app-official [chkStatus]='chkStatus' (nextTabMoveOnSaveEmitter)="changeNextTab($event)" [clientId]="clientId"
          (clientIdEmitter)="getClientIdOnSave($event)"></app-official>
      </ng-template>
    </li>

    <li ngbNavItem="Branch" [disabled]="!clientId" class="nav-item" *ngIf="branchTabHideInUser && !isTabHidden">
      <a ngbNavLink>Branch</a>
      <ng-template ngbNavContent>
          <!-- Loader Section -->
          <div class="row" *ngIf="isLoading">
            <div class="col-12">
                <div class="d-flex justify-content-center" *ngIf="isLoading">
                    <img class="img-responsive pb-2 mr-1" src="assets/gifs/Infinity-loader.gif" />
                </div>
            </div>
        </div>
        <app-branch [clientId]="clientId" (nextTabMoveOnSaveEmitter)="changeNextTab($event)" [tableBranch]="branchDetails" [viewstatus]="branchStatus" *ngIf="!isLoading"> </app-branch>
      </ng-template>
    </li>

    <!-- <li ngbNavItem="paymentSetup"   class="nav-item">
      <a ngbNavLink>Paypal Setup</a>
      <ng-template ngbNavContent>
        <app-payment-setup [clientId]="clientId" (nextTabMoveOnSaveEmitter)="changeNextTab($event)">
        </app-payment-setup>
      </ng-template>
    </li> -->

    <li ngbNavItem="paymentSetup" [disabled]="!clientId" *ngIf="!clientLogin && paypalTabHide && !isTabHidden"
      class="nav-item">
      <a ngbNavLink>Paypal Setup</a>
      <ng-template ngbNavContent>
        <app-payment-setup [clientId]="clientId" (nextTabMoveOnSaveEmitter)="changeNextTab($event)">
        </app-payment-setup>
      </ng-template>
    </li>

    <li ngbNavItem="Role" [disabled]="!clientId" *ngIf="isShownRolesViewTab && !isTabHidden">
      <a ngbNavLink>Role</a>
      <ng-template ngbNavContent>
        <app-role [clientId]="clientId" (nextTabMoveOnSaveEmitter)="changeNextTab($event)"></app-role>
      </ng-template>
    </li>
    <li ngbNavItem="User" [disabled]="!clientId" *ngIf="isShownViewIconForUser && isShownUsersTab && !isTabHidden"
      id="user">
      <a ngbNavLink>User</a>
      <ng-template ngbNavContent>
        <!-- <app-user [clientId]="clientId"></app-user> -->
        <app-UserNewComponent [clientId]="clientId"></app-UserNewComponent>
      </ng-template>
    </li>

    <!--  *ngIf="!clientLogin && paypalTabHide" -->
    <!-- <li ngbNavItem="ItemSupplies" [disabled]="!clientId" >
      <a ngbNavLink>Item Supplies</a>
      <ng-template ngbNavContent>
        <app-item-supplies [clientId]="clientId"></app-item-supplies>
      </ng-template>
    </li> -->
    <li ngbNavItem="InviteUser" [disabled]="!clientId" *ngIf="isShownViewIconForUser && isShownUsersTab"
      id="inviteuser">
      <a ngbNavLink>User</a>
      <ng-template ngbNavContent>
        <app-invite-user [clientId]="clientId"></app-invite-user>
      </ng-template>
    </li>

    <li ngbNavItem="status" [disabled]="!clientId" *ngIf="!clientLogin && officialTabHideInUser">
      <a ngbNavLink>Bin</a>
      <ng-template ngbNavContent>
        <app-status [clientId]="clientId"></app-status>
      </ng-template>
    </li>
    <li ngbNavItem="RuleMaster" [disabled]="!clientId" *ngIf="!clientLogin && officialTabHideInUser">
      <a ngbNavLink>Rule Master</a>
      <ng-template ngbNavContent>
        <app-rule-master [clientId]="clientId"></app-rule-master>
      </ng-template>
    </li>
  </ul>
  <div [ngbNavOutlet]="nav"></div>
</div>
<div class="row"></div>
