import { Component, Inject, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  ValidationErrors,
  Validators,
} from '@angular/forms';
import { noWhitespaceValidator } from 'projects/patient/src/app/add-note-tab/add-note-tab.component';
import emailMask from 'text-mask-addons/dist/emailMask';
import { CreateUpdateCalendarDTO } from 'projects/on-time-schedules/src/app/onTime-proxy/rcm/on-time-management/on-time-schedule/dto/models';
import {
  calender_status_code,
  calender_status_color_code,
  defaultGuid,
} from 'projects/shared/src/app/enums/allenums.enum';
import { OnTimeDropdowns } from 'projects/patient/src/app/dropdown-proxy/dropdown-management/dropdowns/on-time-dropdowns.enum';
import { OnTimeMasterDropdownService } from 'projects/patient/src/app/dropdown-proxy/dropdown-management/dropdowns/master-dropdown-service';
import { OnTimeMasterDropdownDTO } from 'projects/patient/src/app/dropdown-proxy/dropdown-management/dropdowns/dto/on-time-schedule/models';
import { OrganizationUnitService } from 'projects/admin/src/app/admin-proxy/platform-app-management/rcm/platform-management/organization-units';
import { Subscription, of } from 'rxjs';
import { MmOrderService } from 'projects/order/src/app/order-proxy/order-management/order-optimization/mm-order.service';
import { map, startWith, switchMap } from 'rxjs/operators';
import { UserService } from 'projects/admin/src/app/admin-proxy/platform-app-management/rcm/platform-management/user';
import { InventoryTransferStatusService } from 'projects/inventory/src/app/item-proxy/item-management/optimization/inventory-transfer-status.service';
import { PagedAndSortedResultRequestDto } from '@abp/ng.core';
import { ModeOfCommunicationService } from 'projects/admin/src/app/admin-proxy/platform-app-management/rcm/platform-management/communication-mode';
import { InboundDocumentService } from 'projects/admin/src/app/admin-proxy/platform-app-management/rcm/platform-management/inbound';
import { MAT_DIALOG_DATA, MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { MatSelectChange } from '@angular/material/select';
import { DashboardService } from 'projects/admin/src/app/admin-proxy/platform-app-management/rcm/platform-management/dashboard';
import { HttpErrorResponse } from '@angular/common/http';
import Swal from 'sweetalert2';
import { CommunicationService } from 'projects/shared/src/app/services/communication.service';
import { PatientSearchService } from 'projects/patient/src/app/patient-proxy/patient';
import { select, Timezone } from '@syncfusion/ej2-schedule';
import { RegionDropdownService } from 'projects/patient/src/app/dropdown-proxy/dropdown-management/dropdowns/region-dropdown-service';
import { MasterProviderService } from 'projects/admin/src/app/warehouse-proxy/inventory';
import { CalenderScheduleV2Component } from '../../calender-schedule-v2/calender-schedule-v2.component';
import { ScheduleEmailPrintLetterV1Component } from 'projects/admin/src/app/schedule-email-print-letter-v1/schedule-email-print-letter-v1.component';

@Component({
  selector: 'app-calendar-schedule-add-modal-v1-new',
  templateUrl: './calendar-schedule-add-modal-v1-new.component.html',
  styleUrls: ['./calendar-schedule-add-modal-v1-new.component.scss']
})
export class CalendarScheduleAddModalV1NewComponent implements OnInit {
  patientChartApiSubscription: Subscription;
  communicationApiSubscription: Subscription;
  technicianApiSubscription: Subscription;
  templateApiSubscription: Subscription;
  isShowSpinner: boolean = false;
  scheduleForm: FormGroup;
  lstFilterStatus: any;
  emailMask: any;
  patientDrpData: any;
  isStatusTouched: boolean = false;
  isUserLoading: boolean = false;
  drpBranchName: any;
  statusDrpData: any;
  branchDrpData: any;
  userDrpData: any;
  typeDrpData: any;
  resourceDet: any[] = [];
  defaultStatus: string;
  public modeDrpData: any | undefined;
  templateDrpData: any;
  userId: string = '';
  roleId: string = '';
  userName: string = '';
  roleName: string = '';
  tenantId: string = '';
  docId: string;
  patientId: string;
  patientIdViaURL: string;
  args: any;
  ModeSelectAllOption: boolean = false;
  selectedModeList: any[] = [];
  modeDescription: string;
  isPatientLoading: boolean = false;
  PatinetTypeControl = new FormControl();
  BranchTypeControl = new FormControl();
  UserTypeControl = new FormControl();
  TypeNameControl = new FormControl();
  TemplateTypeControl = new FormControl();
  ResourceTypeControl = new FormControl();
  drpPatientName: any;
  drpUserName: any;
  drpTypeName: any;
  drpTemplate: any;
  drpResource: any;
  public drpModeCommunication: Mode[] = [];
  isUsertypeTouched = false;
  calenderId: any;
  calendarDetailsId: any;
  calenderGuid: any;
  qGuid: string;
  isSave: string = 'Save';
  isHeaderTitle: string = 'Add Event';
  isDelete: boolean = false;
  isCellNoRequired: boolean = false;
  isEmailRequired: boolean = false;
  isTemplateRequired: boolean = false;
  testCellNo: any = '4259439438';
  testEmail: any = 'software@rndsoftech.com';
  scheduleTitle: string;
  subscription$: Subscription[] = [];
  phoneCode: string = '';
  TechnicianNew: string;
  minDate: Date;
  isUpdate: boolean = false;

  mytime: Date;
  constructor(
    private formBuilder: FormBuilder,
    private onTimeDropdown: OnTimeMasterDropdownService,
    private branchService: OrganizationUnitService,
    private mmOrderService: MmOrderService,
    private userService: UserService,
    private tranferStatusService: InventoryTransferStatusService,
    private modeOfCommunicationService: ModeOfCommunicationService,
    private inboundDocumentService: InboundDocumentService,
    private adminService: DashboardService,
    private communicationservice: CommunicationService,
    private patientSearchService: PatientSearchService,
    private regionservices: RegionDropdownService,
    private providerList: MasterProviderService,
    public scheduleDialogRef: MatDialogRef<CalenderScheduleV2Component>,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      patientId: string;
      patientIdViaURL: string;
      docId: string;
      scheduleData: any;
      calenderDetailId: string;
    } = {
        patientId: defaultGuid,
        patientIdViaURL: '',
        docId: defaultGuid,
        scheduleData: '',
        calenderDetailId: defaultGuid,
      }
  ) { }

  ngOnInit(): void {
    this.adminService.userProfile().subscribe((response) => {
      this.userId = response?.userId;
      this.userName = response?.userName;
    });

    this.emailMask = emailMask;
    this.formInitilization();
    // this.userId = localStorage.getItem('userId') ?? '';
    this.roleId = localStorage.getItem('roleId') ?? '';
    this.roleName = localStorage.getItem('roleName') ?? '';
    this.tenantId = localStorage.getItem('tenantId') ?? '';
    this.getStausDropDown();
    this.loadBrachDropdown();
    // this.getUserByTenant('','');
    this.getTypeDropDown();
    this.getResource();
    this.getCommuncationMode();

    if (this.data) {
      this.patientId = this.data?.patientId;
      this.patientIdViaURL = this.data?.patientIdViaURL;
      this.docId = this.data?.docId || defaultGuid;
      this.args = this.data?.scheduleData;
      this.calenderId = this.args?.data?.Id;
      this.calendarDetailsId = this.args?.data?.calendarDetailsId;
      this.calenderGuid = this.args?.data?.QGuid;
    }

    this.getPatient('', this.patientId);

    // console.log(this.args);
    //Patient Search Type
    this.PatinetTypeControl.valueChanges.subscribe((searchTerm) => {
      searchTerm = searchTerm.toLowerCase();
      this.patientDrpData = this.drpPatientName.filter((dx) =>
        dx.ddValue.toLowerCase().includes(searchTerm)
      );
    });

    //Branch Search Type
    this.BranchTypeControl.valueChanges.subscribe((searchTerm) => {
      searchTerm = searchTerm.toLowerCase();
      this.branchDrpData = this.drpBranchName.filter((dx) =>
        dx.organizationUnitName.toLowerCase().includes(searchTerm)
      );
    });

    //User Search Type
    this.UserTypeControl.valueChanges.subscribe((searchTerm) => {
      searchTerm = searchTerm.toLowerCase();
      this.userDrpData = this.drpUserName.filter((dx) =>
        dx.userDesignation.toLowerCase().includes(searchTerm)
      );
    });

    //Type Search Type
    this.TypeNameControl.valueChanges.subscribe((searchTerm) => {
      searchTerm = searchTerm.toLowerCase();
      this.typeDrpData = this.drpTypeName.filter((dx) =>
        dx.description.toLowerCase().includes(searchTerm)
      );
    });

    //Template Search Type
    // this.TemplateTypeControl.valueChanges.subscribe((searchTerm) => {
    //   searchTerm = searchTerm.toLowerCase();
    //   this.templateDrpData = this.drpTemplate.filter((dx) =>
    //     dx.templateCode.toLowerCase().includes(searchTerm)
    //   );
    // });

    //Resource Search Type
    this.ResourceTypeControl.valueChanges.subscribe((searchTerm) => {
      searchTerm = searchTerm.toLowerCase();
      this.resourceDet = this.drpResource.filter((dx) =>
        dx.resname.toLowerCase().includes(searchTerm)
      );
    });

    const startDate = new Date(this.args?.data?.StartTime);
    const endDate = new Date(this.args?.data?.EndTime);

    const starthours = startDate.getHours().toString().padStart(2, '0');
    const startminutes = startDate.getMinutes().toString().padStart(2, '0');
    const formattedStartTime = `${starthours}:${startminutes}`;

    const endhours = endDate.getHours().toString().padStart(2, '0');
    const endminutes = endDate.getMinutes().toString().padStart(2, '0');
    const formattedEndTime = `${endhours}:${endminutes}`;


    this.scheduleForm.controls.txtStartTime.patchValue(formattedStartTime);
    this.scheduleForm.controls.txtEndTime.patchValue(formattedEndTime);
    this.scheduleForm.controls.txtStartDate.patchValue(startDate);

    if (this.patientIdViaURL === null) {
      this.scheduleTitle = 'New Setup/Supplies Appointment';
    } else {
      this.scheduleTitle = 'New Setup Appointment';
    }
    this.scheduleForm.controls.txtTitle.patchValue(this.scheduleTitle);

    const scheduleData = this.args?.data?.QGuid;
    if (
      scheduleData !== undefined &&
      scheduleData !== defaultGuid &&
      scheduleData !== null
    ) {
      this.qGuid = this.args?.data?.QGuid;
      this.isDelete = true;
      this.fnloadScheduledata();
    } else {
      if (this.patientIdViaURL !== null) {
        this.getSelectedPatientDetailsV1(this.patientIdViaURL);
      }
      this.getUserByTenant('', '');
    }

    // this.minDate = new Date();
    const currentDate = new Date();
    const currentDateV1 = currentDate.toLocaleString('en-US', {
      timeZone: 'America/Chicago',
    });
    this.minDate = new Date(currentDateV1);
  }

  //Schedule Form Initilization
  formInitilization() {
    this.scheduleForm = this.formBuilder.group({
      txtTitle: new FormControl('', [
        Validators.required,
        noWhitespaceValidator,
      ]),
      txtStartDate: new FormControl('', [Validators.required]),
      txtEndDate: new FormControl(''),
      txtDescription: new FormControl(''),
      drpStatus: new FormControl('Scheduled', [Validators.required]),
      drpPatientName: new FormControl('', [Validators.required]),
      txtPatinetType: new FormControl(''),
      drpBranchName: new FormControl(''),
      txtBranchType: new FormControl(''),
      drpUserName: new FormControl('',[Validators.required]),
      txtUserType: new FormControl(''),
      drpTypeName: new FormControl(''),
      // drpTemplate: new FormControl(''),
      drpResource: new FormControl(''),
      drpModeCommunication: new FormControl('', [Validators.required]),
      txtHomeNo: new FormControl(''),
      txtCellNo: new FormControl(''),
      txtWorkNo: new FormControl(''),
      txtEmail: new FormControl(''),
      setupHome: new FormControl(''),
      txtModeType: new FormControl(''),
      txtTypeName: new FormControl(''),
      txtTemplateName: new FormControl(''),
      txtResourceName: new FormControl(''),
      txtStartTime: new FormControl('',[Validators.required]),
      txtEndTime: ['', [Validators.required]],
    });
  }

  //Patch Upadte Values
  fnloadScheduledata() {
    this.scheduleForm.markAsDirty();
    this.isUpdate = true;
    this.isSave = 'Update';
    this.isHeaderTitle = 'Update Event';
    const scheduleData = this.args?.data;
    this.getTemplate(scheduleData?.mode);
    this.getTypeDropDown();
    this.getPatient('', scheduleData?.Patient);
    this.getUserByTenant('', scheduleData?.user);
    // const selectedTemplate = this.getTypeObjectById(scheduleData?.templateId);
    this.scheduleForm.patchValue({
      txtTitle: scheduleData?.Subject,
      txtStartDate: scheduleData?.StartTime,
      txtEndDate: scheduleData?.EndTime,
      txtDescription: scheduleData?.Description,
      drpStatus: scheduleData?.status,
      drpPatientName: scheduleData?.Patient,
      drpBranchName: scheduleData?.Location,
      drpUserName: scheduleData?.user,
      // drpTypeName: scheduleData?.typeId,
      // drpTemplate: selectedTemplate,
      drpResource: scheduleData?.resource,
      drpModeCommunication: scheduleData?.mode,
      txtHomeNo: scheduleData?.phone,
      // txtCellNo: scheduleData?.cell,
      txtWorkNo: scheduleData?.work,
      // txtEmail: scheduleData?.email,
      setupHome: scheduleData?.setupInHome,
    });
    this.selectedModeList = scheduleData?.mode;
    // Reset validators for all form controls
    this.resetFormValidators();

    // Set validators based on selected modes
    this.setFormValidators(1);

    // Update value and validity for all form controls
    this.updateFormControlsValueAndValidity();
  }
  getTypeObjectById(id: number): any {
    if (this.templateDrpData) {
      return this.templateDrpData.find((temp) => temp.templateCode === id);
    } else {
      return null;
    }
  }
  /// Statuus Drop Down
  getStausDropDown() {
    let onTimeDropdownInput: OnTimeDropdowns[];
    onTimeDropdownInput = [OnTimeDropdowns.CalendarStatus];
    this.onTimeDropdown
      .getOnTimeDropdownsByInput(onTimeDropdownInput)
      .subscribe(
        (stateResponse) => {
          this.statusDrpData = stateResponse?.calendarStatuses;
          // console.log(this.statusDrpData);
        },
        (err) => { }
      );
  }

  //Branch Drop Down
  loadBrachDropdown() {
    const getBranchList = this.branchService
      // .getBranchList(this.tenantId)
      .getBranchListBasedOnSchedule(this.tenantId)
      .subscribe(
        (response) => {
          this.branchDrpData = response;
          this.drpBranchName = this.branchDrpData;
        },
        (err) => { }
      );
  }

  //Get Patient Drop Down
  getPatient(value: any, patientId: string) {
    this.isPatientLoading = true;
    if (this.patientChartApiSubscription) {
      this.patientChartApiSubscription.unsubscribe();
    }
    this.patientChartApiSubscription = this.mmOrderService
      .getPatientChartNoV3BySSearchParamsAndPatient(value, patientId)
      .subscribe(
        (response) => {
          this.isPatientLoading = false;
          this.patientDrpData = response;
          this.drpPatientName = this.patientDrpData;
        },
        (err) => {
          this.isPatientLoading = false;
        }
      );
  }

  //Get Patient Drop Down
  // getPatient(searchParameter: any) {
  //   this.isPatientLoading = true;
  //   if (this.patientChartApiSubscription) {
  //     this.patientChartApiSubscription.unsubscribe();
  //     this.isPatientLoading = false;
  //   }
  //   this.patientChartApiSubscription = this.patientSearchService
  //     .getNewSetupPatientSearch(searchParameter, this.patientId)
  //     .subscribe(
  //       (response) => {
  //         this.isPatientLoading = false;
  //         this.patientDrpData = response;
  //         this.drpPatientName = this.patientDrpData;
  //       },
  //       (err) => { this.isPatientLoading = false }
  //     );
  // }

  //GET USER DROP DOWN
  // getUserByTenant() {
  //   this.isUserLoading = true;
  //   const getUserList$ = of(null)
  //     .pipe(switchMap((x) => this.userService.getUsersByTenant()))
  //     .subscribe((response) => {
  //       this.isUserLoading = false;
  //       this.userDrpData = response;
  //       this.drpUserName = this.userDrpData;
  //     }, (err) => {
  //       this.isUserLoading = false;
  //     });
  // }
  getUserByTenant(searchValue: string, userId: string) {
    this.TechnicianNew = userId;
    this.isUserLoading = true;
    const getUserList$ = of(null)
      .pipe(
        switchMap((x) =>
          this.userService.getUsersByTenantV1BySParamsAndUser(
            searchValue,
            userId
          )
        )
      )
      .subscribe(
        (response) => {
          this.isUserLoading = false;
          this.userDrpData = response;
          this.drpUserName = this.userDrpData;
        },
        (err) => {
          this.isUserLoading = false;
        }
      );
  }

  searchTechnician(searchParameter) {
    if (this.technicianApiSubscription) {
      this.technicianApiSubscription.unsubscribe();
    }
    // this.isUserLoading = true;
    this.technicianApiSubscription = this.userService
      .getUsersByTenantV1BySParamsAndUser(searchParameter, this.TechnicianNew)
      .subscribe(
        (response) => {
          // this.isUserLoading = false;
          this.userDrpData = response;
          this.drpUserName = this.userDrpData;
        },
        (err) => {
          this.isUserLoading = false;
        }
      );
  }

  //Type Drop Down
  getTypeDropDown() {
    const scheduleData = this.args?.data;
    const TypeSearchSub$ = of(null)
      .pipe(switchMap((x) => this.inboundDocumentService.getAptclassList()))
      .subscribe((response) => {
        this.typeDrpData = response;
        this.drpTypeName = this.typeDrpData;

        const selectedTypeId = this.getTypeDrpObjectById(scheduleData?.typeId);
        this.scheduleForm.patchValue({
          drpTypeName: selectedTypeId,
        });
      });
  }

  getTypeDrpObjectById(id: number): any {
    if (this.typeDrpData) {
      return this.typeDrpData.find((type) => type.aptClassId === id);
    } else {
      return null;
    }
  }

  // getTypeDropDown() {
  //   const TypeSearchSub$ = of(null)
  //     .pipe(switchMap((x) => this.tranferStatusService.ServiceTypeDetails()))
  //     .subscribe((response) => {
  //       this.typeDrpData = response?.items;
  //       this.drpTypeName = this.typeDrpData;
  //     });
  // }

  //Resource Drop down Old
  // getResource() {
  //   this.resourceDet = [];
  //   this.resourceDet?.push({
  //     resname: 'Monitor Medical',
  //     resid: defaultGuid,
  //   });
  // }

  //Resource Drop down
  getResource() {
    const providerList = this.providerList
      .getList(new PagedAndSortedResultRequestDto())
      .subscribe((response) => {
        response?.items?.forEach((element) => {
          this.resourceDet.push({
            resid: element?.id,
            resname: element?.fullName,
          });

          this.resourceDet.sort((a, b) => a.resname.localeCompare(b.resname));
          this.drpResource = this.resourceDet;
        });
      });
    this.subscription$.push(providerList);
  }

  //GEt Communication Drop down
  getCommuncationMode() {
    const getBranchList = this.modeOfCommunicationService
      .getList(new PagedAndSortedResultRequestDto())
      .subscribe(
        (response) => {
          if (response?.items) {
            response?.items.forEach((element) => {
              this.drpModeCommunication.push({
                mode: element.mode,
                modeDescription: element.modeDescription,
              });
            });
          }
          this.modeDrpData = this.scheduleForm
            ?.get('txtModeType')
            ?.valueChanges?.pipe(
              startWith(''),
              map((value) =>
                this.drpModeCommunication?.filter((option) =>
                  option?.modeDescription
                    ?.toLowerCase()
                    ?.includes(value?.toLowerCase() ?? '')
                )
              )
            );
          // console.log(this.modeDrpData);
        },

        (err) => { }
      );
    // this.subscription$.push(getBranchList);
  }
  //GET Template DROP DOWN
  getTemplate(modeId: number[]) {
    const scheduleData = this.args?.data;
    if (this.templateApiSubscription) {
      this.templateApiSubscription.unsubscribe();
    }
    this.templateApiSubscription = this.inboundDocumentService
      .getListSMSTemplateId(modeId)
      .subscribe(
        (response) => {
          this.templateDrpData = response?.items;
          this.drpTemplate = this.templateDrpData;
          const selectedTemplate = this.getTypeObjectById(
            scheduleData?.templateId
          );
          // this.scheduleForm.patchValue({
          //   drpTemplate: selectedTemplate,
          // });
        },
        (err) => { }
      );
  }

  //Mode of Communication On change
  onSelectionUserChange(event: MatSelectChange): void {
    this.selectedModeList = event.value;

    // // Reset validators for all form controls
    // this.resetFormValidators();

    // // Set validators based on selected modes
    // this.setFormValidators(2);

    // // Update value and validity for all form controls
    // this.updateFormControlsValueAndValidity();

    // // Fetch templates based on selected modes
    // if (event.value.length === 0) {
    //   this.templateDrpData = [];
    // } else {
    //   this.getTemplate(this.selectedModeList);
    // }
  }

  private resetFormValidators(): void {
    this.scheduleForm?.controls?.txtCellNo?.setValidators([
      Validators.nullValidator,
    ]);
    this.scheduleForm?.controls?.txtEmail?.setValidators([
      Validators.nullValidator,
      Validators.pattern(
        "[a-z0-9._!#$%&'*+/=?^_`{|}~-]+@[a-z0-9.-]+\\.[a-z]{2,4}"
      ),
    ]);
    this.scheduleForm?.controls?.drpTemplate?.setValidators([
      Validators.nullValidator,
    ]);
  }

  private setFormValidators(flag: number): void {

    if (this.selectedModeList.includes(1)) {
      if (flag === 1) {
        this.scheduleForm.patchValue({
          txtCellNo: this.args?.data.cell,
        });
      } else if (flag === 2) {
        this.scheduleForm.patchValue({
          txtCellNo: this.testCellNo,
        });
      }
      this.isCellNoRequired = true;
      this.isTemplateRequired = true;

      this.scheduleForm?.controls?.txtCellNo?.setValidators([
        Validators.required,
      ]);
      this.scheduleForm?.controls?.drpTemplate?.setValidators([
        Validators.required,
      ]);
    } else {
      this.scheduleForm.patchValue({
        txtCellNo: '',
      });
      this.isCellNoRequired = false;
      this.isTemplateRequired = false;
    }

    if (this.selectedModeList.includes(2)) {

      if (flag === 1) {
        this.scheduleForm.patchValue({
          txtEmail: this.args?.data.email,
        });
      } else if (flag === 2) {
        this.scheduleForm.patchValue({
          txtEmail: this.testEmail,
        });
      }

      // this.scheduleForm.patchValue({
      //   txtEmail: this.testEmail,
      // });
      this.isEmailRequired = true;
      this.isTemplateRequired = true;

      this.scheduleForm?.controls?.txtEmail?.setValidators([
        Validators.required,
        Validators.pattern(
          "[a-z0-9._!#$%&'*+/=?^_`{|}~-]+@[a-z0-9.-]+\\.[a-z]{2,4}"
        ),
      ]);
      this.scheduleForm?.controls?.drpTemplate?.setValidators([
        Validators.required,
      ]);
    } else {
      this.scheduleForm.patchValue({
        txtEmail: '',
      });
      this.isEmailRequired = false;
    }
  }

  private updateFormControlsValueAndValidity(): void {
    this.scheduleForm?.controls?.txtCellNo?.updateValueAndValidity();
    this.scheduleForm?.controls?.txtEmail?.updateValueAndValidity();
    this.scheduleForm?.controls?.drpTemplate?.updateValueAndValidity();
  }

  isOptionDisabled(selectedModeId: number): boolean {
    if (this.selectedModeList?.length === 0) return false; // No Mode of Communication is selected, so no option is disabled
    if (this.selectedModeList?.includes(4)) {
      // If 'Do not Distrub is Selected' disable all other options
      return selectedModeId !== 4;
    } else {
      // If any other option is selected, disable 'Do not Distrub'
      return selectedModeId === 4;
    }
  }

  onSelectionPatientChange(event: MatSelectChange): void {
    this.getSelectedPatientDetailsV1(event?.value);
  }


  saveScheduleForm() {

    const scheduleStatus = this.scheduleForm.value.drpStatus;
    if (
      this.qGuid === undefined ||
      this.qGuid === null ||
      this.qGuid === defaultGuid
    ) {
      const scheduleFrom = this.scheduleForm.value;


      const startDate = scheduleFrom.txtStartDate;
      const startTime = scheduleFrom.txtStartTime;
  
      const startDateyy = new Date(startDate).getFullYear();
      const startDatemm = new Date(startDate).getMonth() + 1;
      const startDatedd = new Date(startDate).getDate();
  
      const startInterMedDt = new Date(startDatemm + '-' + startDatedd + '-' + startDateyy);
  
      startInterMedDt.setHours((startTime.split(' ')[0]).split(':')[0]);
      startInterMedDt.setMinutes((startTime.split(' ')[0]).split(':')[1]);
  
      const endDate = this.scheduleForm.value.txtStartDate;
      const endTime = this.scheduleForm.value.txtEndTime;
  
      const endDateyy = new Date(endDate).getFullYear();
      const endDatemm = new Date(endDate).getMonth() + 1;
      const endDatedd = new Date(endDate).getDate();
      const endInterMedDt = new Date(endDatemm + '-' + endDatedd + '-' + endDateyy);
  
      endInterMedDt.setHours((endTime.split(' ')[0]).split(':')[0]);
      endInterMedDt.setMinutes((endTime.split(' ')[0]).split(':')[1]);



      // Assume your local timezone as IST/UTC+05:30
      // let timezoneStart: Timezone = new Timezone();
      // let dateStart: Date = new Date(scheduleFrom?.txtStartDate);
      // let convertedStartDate: Date = timezoneStart.remove(
      //   dateStart,
      //   'America/Chicago'
      // );

      // let timezoneEnd: Timezone = new Timezone();
      // let dateEnd: Date = new Date(scheduleFrom?.txtEndDate);
      // let convertedEndDate: Date = timezoneEnd.remove(
      //   dateEnd,
      //   'America/Chicago'
      // );

       // Assume your local timezone as IST/UTC+05:30
      let timezoneStart: Timezone = new Timezone();
      let dateStart: Date = new Date(startInterMedDt);
      let convertedStartDate: Date = timezoneStart.remove(
        dateStart,
        'America/Chicago'
      );

      let timezoneEnd: Timezone = new Timezone();
      let dateEnd: Date = new Date(endInterMedDt);
      let convertedEndDate: Date = timezoneEnd.remove(
        dateEnd,
        'America/Chicago'
      );


      let txtCellNo: string;

      if (scheduleFrom.txtCellNo) {
        txtCellNo = '+1' + scheduleFrom.txtCellNo;
      } else {
        txtCellNo = '';
      }

      this.isShowSpinner = true;
      let calender: CreateUpdateCalendarDTO = {
        roleId: this.roleId,
        roleName: this.roleName,
        // assignedToId: this.userId,
        assignedToId: scheduleFrom?.drpUserName || null,
        assignedToName: this.userName,
        calendarDetails: [
          {
            title: scheduleFrom?.txtTitle,
            fromTime: convertedStartDate.toISOString(),
            toTime: convertedEndDate.toISOString(),
            status: scheduleFrom?.drpStatus,
            description: scheduleFrom?.txtDescription ?? '',
            locationId: scheduleFrom?.drpBranchName || null,
            patientId: scheduleFrom?.drpPatientName || defaultGuid,
            userID: scheduleFrom?.drpUserName || null,
            type: scheduleFrom?.drpTypeName?.description,
            typeId: scheduleFrom?.drpTypeName?.aptClassId,
            resource: scheduleFrom?.drpResource || null,
            homePhoneNo: scheduleFrom?.txtHomeNo,
            // cellNo: "+1" + scheduleFrom?.txtCellNo,
            cellNo: txtCellNo,
            workNo: scheduleFrom?.txtWorkNo,
            email: scheduleFrom?.txtEmail,
            setUpInHome: scheduleFrom?.setupHome || false,
            calendarCode: '',
            calendarId: this.args?.data?.Id || 0,
            startTimeZone: '',
            endTimeZone: '',
            // colorCode: this.colorCode(scheduleFrom?.drpStatus ?? ''),
            docId: this.docId || defaultGuid,
          },
        ],
        modeCode: scheduleFrom?.drpModeCommunication || [0],
        // templateCode: scheduleFrom?.drpTemplate?.templateCode,
        // templateContent: scheduleFrom?.drpTemplate?.template,
        isMailorMessageSend: true,
      };

      this.inboundDocumentService.createCalendarByInput(calender).subscribe(
        (response) => {
          this.isShowSpinner = false;
          this.scheduleDialogRef.close();
          this.communicationservice.triggerScheduleFunction();
        },
        (err) => {
          this.isShowSpinner = false;
          const data: HttpErrorResponse = err;
          Swal.fire({
            icon: 'info',
            text: data?.error?.error?.message,
          });
          this.communicationservice.triggerScheduleFunction();
        }
      );
    }

    else {
      const isModeoOfcommunicaion =
        this.scheduleForm.value.drpModeCommunication;
      if (isModeoOfcommunicaion.includes(4) || scheduleStatus.toLowerCase() === 'completed' || scheduleStatus.toLowerCase() === 'cancelled') {
        this.updateSchedule(false);
      } else {
        Swal.fire({
          icon: 'warning',
          title: 'Do you want to Send SMS/Email Again?',
          showCancelButton: true,
          confirmButtonText: 'Yes',
          denyButtonText: 'No',
          showDenyButton: true,
          customClass: {
            actions: 'my-actions',
            cancelButton: 'order-1 right-gap',
            confirmButton: 'order-2',
            denyButton: 'order-3',
          },
        }).then((result) => {
          if (result.isConfirmed) {
            this.updateSchedule(true);
          } else if (result.isDenied) {
            this.updateSchedule(false);
          } else {
            this.isShowSpinner = false;
          }
        });
      }


    }


  }

  openEmailSmsModal(key: number): void {
    const config: MatDialogConfig = {
      disableClose: true,
      width: '70vw',
      maxWidth: '70vw',
      // height: '80vh',
      // maxHeight: 'auto',
      // minHeight:'auto',
      panelClass: 'responsive-modal',
      data: {
        isSMSorMailCheck: key
      },
    };
    const dialogRef = this.dialog.open(
      ScheduleEmailPrintLetterV1Component,
      config
    );
    const closeDialog = dialogRef.afterClosed().subscribe(() => { });
  }

  //#region Save Schedule
  saveSchedule() {
    
    const startDate = this.scheduleForm.value.txtStartDate;

    const startTime = this.scheduleForm.value.txtStartTime;

    const startDateyy = new Date(startDate).getFullYear();
    const startDatemm = new Date(startDate).getMonth() + 1;
    const startDatedd = new Date(startDate).getDate();

    const startInterMedDt = new Date(startDatemm + '-' + startDatedd + '-' + startDateyy);

    startInterMedDt.setHours((startTime.split(' ')[0]).split(':')[0]);
    startInterMedDt.setMinutes((startTime.split(' ')[0]).split(':')[1]);

    const endDate = this.scheduleForm.value.txtStartDate;

    const endTime = this.scheduleForm.value.txtEndTime;

    const endDateyy = new Date(endDate).getFullYear();
    const endDatemm = new Date(endDate).getMonth() + 1;
    const endDatedd = new Date(endDate).getDate();
    const endInterMedDt = new Date(endDatemm + '-' + endDatedd + '-' + endDateyy);

    endInterMedDt.setHours((endTime.split(' ')[0]).split(':')[0]);
    endInterMedDt.setMinutes((endTime.split(' ')[0]).split(':')[1]);

    if (this.selectedModeList.includes(1)) {
      if (this.selectedModeList.length > 1) {
        this.openEmailSmsModal(3);
      } else {
        this.openEmailSmsModal(1);
      }
    } else if (this.selectedModeList.includes(2)) {
      this.openEmailSmsModal(2);
    } else {
      
      // let hoursStart: Date = new Date(this.scheduleForm?.value?.txtStartDate);
      // let hoursEnd: Date = new Date(this.scheduleForm?.value?.txtEndDate);

      let hoursStart: Date = new Date(startInterMedDt);
      let hoursEnd: Date = new Date(endInterMedDt);
  
      let startDateHours = hoursStart.getHours();
      let startDateMins = hoursStart.getMinutes();
  
      let endDateHours = hoursEnd.getHours();
      let endDateMins = hoursEnd.getMinutes();
      if (
        (startDateHours === 12 && startDateMins >= 0 && startDateMins <= 59) ||
        (endDateHours === 12 && endDateMins >= 1 && endDateMins <= 59) || (startDateHours < 12 && endDateHours > 12)
      ) {
        Swal.fire({
          icon: 'warning',
          title: 'Between 12 PM to 1 PM is Lunch time',
          text: 'Are You Sure Want to Schedule?',
          showCancelButton: true,
          cancelButtonColor: '#dc3741',
          confirmButtonText: 'Yes',
        }).then((result) => {
          if (result.value) {
            this.saveScheduleForm();
          }
        });
      } else {
        this.saveScheduleForm();
      }
    }
  }

  //Update Schedule
  private updateSchedule(isSmsEmailSend: boolean): void {
    const scheduleFrom = this.scheduleForm.value;

    
    const startDate = scheduleFrom.txtStartDate;
    const startTime = scheduleFrom.txtStartTime;

    const startDateyy = new Date(startDate).getFullYear();
    const startDatemm = new Date(startDate).getMonth() + 1;
    const startDatedd = new Date(startDate).getDate();

    const startInterMedDt = new Date(startDatemm + '-' + startDatedd + '-' + startDateyy);

    startInterMedDt.setHours((startTime.split(' ')[0]).split(':')[0]);
    startInterMedDt.setMinutes((startTime.split(' ')[0]).split(':')[1]);

    const endDate = this.scheduleForm.value.txtStartDate;
    const endTime = this.scheduleForm.value.txtEndTime;

    const endDateyy = new Date(endDate).getFullYear();
    const endDatemm = new Date(endDate).getMonth() + 1;
    const endDatedd = new Date(endDate).getDate();
    const endInterMedDt = new Date(endDatemm + '-' + endDatedd + '-' + endDateyy);

    endInterMedDt.setHours((endTime.split(' ')[0]).split(':')[0]);
    endInterMedDt.setMinutes((endTime.split(' ')[0]).split(':')[1]);


    // Assume your local timezone as IST/UTC+05:30
    let timezoneStart: Timezone = new Timezone();
    let dateStart: Date = new Date(startInterMedDt);
    let convertedStartDate: Date = timezoneStart.remove(
      dateStart,
      'America/Chicago'
    );
    // console.log(convertedStartDate);

    let timezoneEnd: Timezone = new Timezone();
    let dateEnd: Date = new Date(endInterMedDt);
    let convertedEndDate: Date = timezoneEnd.remove(dateEnd, 'America/Chicago');
    // console.log(convertedEndDate);
    let txtCellNo: string;

    if (scheduleFrom.txtCellNo) {
      txtCellNo = '+1' + scheduleFrom.txtCellNo;
    } else {
      txtCellNo = '';
    }

    this.isShowSpinner = true;
    let calender: CreateUpdateCalendarDTO = {
      roleId: this.roleId,
      roleName: this.roleName,
      // assignedToId: this.userId,
      assignedToId: scheduleFrom?.drpUserName || null,
      assignedToName: this.userName,
      calendarDetails: [
        {
          title: scheduleFrom?.txtTitle,
          // fromTime: scheduleFrom?.txtStartDate,
          fromTime: convertedStartDate.toISOString(),
          calendarDetailsId: this.calendarDetailsId,
          toTime: convertedEndDate.toISOString(),
          // toTime: scheduleFrom?.txtEndDate,
          status: scheduleFrom?.drpStatus ?? '',
          description: scheduleFrom?.txtDescription ?? '',
          locationId: scheduleFrom?.drpBranchName,
          patientId: scheduleFrom?.drpPatientName || defaultGuid,
          userID: scheduleFrom?.drpUserName,
          type: scheduleFrom?.drpTypeName?.description,
          typeId: scheduleFrom?.drpTypeName?.aptClassId,
          resource: scheduleFrom?.drpResource,
          homePhoneNo: scheduleFrom?.txtHomeNo,
          // cellNo: "+1" + scheduleFrom?.txtCellNo,
          cellNo: txtCellNo,
          workNo: scheduleFrom?.txtWorkNo,
          email: scheduleFrom?.txtEmail,
          setUpInHome: scheduleFrom?.setupHome || false,
          calendarCode: '',
          calendarId: this.args?.data?.Id || 0,
          startTimeZone: '',
          endTimeZone: '',
          // colorCode: this.colorCode(scheduleFrom?.drpStatus ?? ''),
          docId: this.docId || defaultGuid,
        },
      ],
      modeCode: scheduleFrom?.drpModeCommunication || [0],
      // templateCode: scheduleFrom?.drpTemplate?.templateCode,
      // templateContent: scheduleFrom?.drpTemplate?.template,
      isMailorMessageSend: isSmsEmailSend,
    };

    this.inboundDocumentService
      .updateCalendarByIdAndInput(this.qGuid, calender)
      .subscribe(
        (response) => {
          this.isShowSpinner = false;
          this.scheduleDialogRef.close();
          this.communicationservice.triggerScheduleFunction();
        },
        (err) => {
          this.isShowSpinner = false;
          const data: HttpErrorResponse = err;
          Swal.fire({
            icon: 'info',
            text: data?.error?.error?.message,
          });
          this.communicationservice.triggerScheduleFunction();
        }
      );
  }

  //#endregion
  colorCode(Status: string) {
    let scalender_status_color_code = calender_status_color_code;
    let scalender_status_code = calender_status_code;
    let returntext = calender_status_color_code.Scheduled;
    if (Status === scalender_status_code[1]) {
      returntext = calender_status_color_code.Scheduled;
    } else if (Status === scalender_status_code[2]) {
      returntext = calender_status_color_code.Rescheduled;
    } else if (Status === scalender_status_code[3]) {
      returntext = calender_status_color_code.Completed;
    } else if (Status === scalender_status_code[4]) {
      returntext = calender_status_color_code.Cancelled;
    } else {
      returntext = calender_status_color_code.Scheduled; // Unknown
    }
    return returntext;
  }
  resetSchedule() {
    this.scheduleForm.reset();
    this.selectedModeList = [];
  }

  // Delete Schedule
  deleteSchedule() {
    Swal.fire({
      title: 'Are you sure you want to Delete?',
      text: "You won't be able to retrieve this data!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#34c38f',
      cancelButtonColor: '#f46a6a',
      confirmButtonText: 'Yes, delete it!',
    }).then((result) => {
      if (result.value) {
        this.inboundDocumentService
          .deleteCalendarByIdAndCalendarDetailsId(
            this.qGuid,
            this.calendarDetailsId
          )
          .subscribe(
            (response) => {
              this.scheduleDialogRef.close();
              this.communicationservice.triggerScheduleFunction();
            },
            (err) => {
              this.scheduleDialogRef.close();
              const data: HttpErrorResponse = err;
              Swal.fire({
                icon: 'info',
                text: data?.error?.error?.message,
              });
              this.communicationservice.triggerScheduleFunction();
            }
          );
      }
    });
  }

  getSelectedPatientDetailsV1(patientId: string) {
    if (patientId !== null && patientId !== undefined && patientId !== '') {
      this.inboundDocumentService
        .getScheduleDetailsByPatientID(patientId)
        .subscribe(
          (response) => {
            // let addressParts: string[] = [];
            // if (response?.address) {
            //   addressParts = response?.address.split(",");
            // }
            // console.log(addressParts);

            // const country = addressParts[2] || '';

            // console.log("Country:", country);
            this.searchPatient('', patientId);
            this.scheduleForm.patchValue({
              drpPatientName: response?.patientId || '',
              drpBranchName: response?.locationId || '',
              drpTypeName: response?.type,
              drpResource: response?.resource,
              txtHomeNo: response?.homePhoneNo,
              // txtCellNo: response?.cellNo,
              txtWorkNo: response?.workNo,
              // txtEmail: response?.email,
              setupHome: response?.setUpInHome,
            });

            this.docId = response?.docId;
            // this.onChangeBillState(country);
          },
          (error) => {
            console.error('Error fetching patient details:', error);
            // this.docId = defaultGuid;
          }
        );
    }
  }

  // onChangeBillState(value: string | null | undefined) {

  //   if (value !== null && value !== undefined) {
  //     const stateDropdown = this.regionservices
  //       .getAllStatesByCountryNameByCountryShortName(value)
  //       .subscribe(
  //         (response) => {
  //           if (response?.items?.length == 0) {
  //             this.phoneCode = '1';
  //           } else {
  //             this.phoneCode = response?.items[0]?.countryPhoneCode ??'1';
  //           }
  //           console.log(this.phoneCode);
  //         },
  //         (err) => {
  //           this.phoneCode = '1';
  //           const data: HttpErrorResponse = err;
  //           Swal.fire({
  //             icon: 'info',
  //             text: data?.error?.error?.message,
  //           });
  //         }
  //       );
  //     this.subscription$.push(stateDropdown);
  //   }
  // }

  isDropdownOpen = false;
  onDropdownOpenChange(isOpen: boolean) {
    this.isDropdownOpen = isOpen;
    if (this.isDropdownOpen) {
      this.searchPatient('', this.patientId);
    }
  }


  searchPatient(searchParameter: any, patientId: string) {
    if (this.patientChartApiSubscription) {
      this.patientChartApiSubscription.unsubscribe();
      this.isPatientLoading = false;
    }
    this.isPatientLoading = true;
    this.patientChartApiSubscription = this.mmOrderService
      .getPatientChartNoV3BySSearchParamsAndPatient(
        searchParameter,
        patientId
      )
      .subscribe(
        (response) => {
          this.isPatientLoading = false;
          this.patientDrpData = response;
          this.drpPatientName = this.patientDrpData;
        },
        (err) => { }
      );
  }

  updateTextColor(colorCode: string): string {
    if (this.isValidColorCode(colorCode)) {
      return this.getContrastingTextColor(colorCode);
    } else {
      return '#000000'; // default to black if colorCode is invalid
    }
  }

  isValidColorCode(colorCode: string): boolean {
    // Check if colorCode is a valid hex color code
    const regex = /^#([0-9A-F]{3}){1,2}$/i;
    return regex.test(colorCode);
  }

  getContrastingTextColor(hexColor: string): string {
    if (!hexColor) return '#000000'; // default to black if no color provided

    // Remove the hash if present
    hexColor = hexColor.replace('#', '');

    // Convert to RGB
    const r = parseInt(hexColor.substring(0, 2), 16);
    const g = parseInt(hexColor.substring(2, 4), 16);
    const b = parseInt(hexColor.substring(4, 6), 16);

    // Calculate luminance
    const luminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255;

    // If luminance is high, return dark color (black), otherwise return light color (white)
    return luminance > 0.5 ? '#000000' : '#FFFFFF';
  }
}
export interface Mode {
  mode?: number;
  modeDescription?: string;
}
