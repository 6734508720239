<mat-card class="mr-3 mt-3">
  <mat-card-title class="customThemeClass">
    <div class="row">
      <div class="col-md-6">
        <h4 class="customThemeClass pt-2"><b>Authorization List</b></h4>
      </div>
    </div>
  </mat-card-title>
  <mat-divider class="bg mb-5"> </mat-divider>
  <mat-card-content>
    <!-- Loader Section -->
    <div class="col-12" *ngIf="isLoading">
      <div class="d-flex justify-content-center" *ngIf="isLoading">
        <img class="img-responsive pb-2 mr-1" src="assets/gifs/Infinity-loader.gif" />
      </div>
    </div>
    <div class="col-12 table-responsive mt-3" *ngIf="!isLoading">
      <!-- <app-shared-table
        [arrDisplayedColumns]="arrDisplayedColumns"
        [isLoading]="isLoading"
        (viewClick)="ViewAuthDetail($event)"
        [strPageType]="strPageType"
      >
      </app-shared-table> -->
      <app-patient-authorization-table [tableData]="tableAuthorizationDetails"
        (viewClick)="ViewAuthDetail($event)" (isLoadTable)="loadAuthTable()"></app-patient-authorization-table>

    </div>
  </mat-card-content>
</mat-card>
<!-- <button (click)="openDocumentViewer()" mat-raised-button>Test</button> -->

<ng-template #authViewerDialog class="mat-typography">
  <div class="row">
    <h3 class="col-lg-6 mt-2 custom-qsecure-color font-weight-bolder" mat-dialog-title>
      Authorization Details
    </h3>
    <!-- <div class="col-lg-6 text-right mb-2">
      <button
        class="col-lg-6"
        mat-button
        mat-dialog-close
        class="close-button"
      >
        <mat-icon>close</mat-icon>
      </button>
    </div> -->
    <div class="col-lg-6" style="text-align-last: right">
      <mat-icon mat-dialog-close (click)="close()">close</mat-icon>
    </div>
  </div>

  <!-- <div mat-dialog-content>
    <app-patient-auth-save-form [patientInfo]="patientInfo" [patientId]="patientId" [isView]="false"
      [isSupplies]="false"></app-patient-auth-save-form>
  </div> -->
  <!-- <div align="end" mat-dialog-actions>
    <button class="faxButtonColor mt-1 mr-1 float-right" mat-button>
      <mat-icon class="send-icon">send</mat-icon>Send Fax
    </button>
  </div> -->
</ng-template>
