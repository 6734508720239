import { anyasdasd } from './../../../../../../../../billing/src/app/claim-proxy/claim-processing-management/system/reflection/models';
import type {
  APIResponseDTO,
  AnnotatedDTO,
  AuthStatDTO,
  BinStstusDetailsDto,
  CreateUpdateInboundDocumentDTO,
  CreateUpdateNewsetupDemographicsDTO,
  CreateUpdateNewsetupPlansDTO,
  DemographicDTO,
  FaxFilter,
  FaxFinderDTO,
  FaxIdDTO,
  GetCalendarDTO,
  InboundDocsList,
  InboundDocumentDTO,
  InboundDocumentDetailsDto,
  InboundListDTO,
  InboundStatusDTO,
  LockDocListDTO,
  LockOutput,
  NewPatientDTO,
  NewsetupDemographicsDTO,
  NewsetupPlansDTO,
  PatientEntryTab,
  PatientInboundDetailDTO,
  PracticeFaxLogDTO,
  ProcessDocumentDetailsDTO,
  RXInputDTO,
  RXUploadFilesDTO,
  ResponseDemographicsDTO,
  SMSMailTemplateDTO,
  SearchCalendarDTO,
  SortedDocumentGroupDTO,
  SortingDocumentDetailsDTO,
  TFFPages,
  TFFPagesDTO,
  TFFPagesOutput,
  TIFFBase64DTO,
  TIFFDTO,
  UploadRxDocDTO,
  aptClasslistDTO,
  clsFaxFinders,
  clsMultipleDocUpload,
  clsSortedDocs,
  clsSortedDocsv1,
  clsSortedDocsv2,
  clsSortedDocuemnts,
  displayDocumentDetails,
  documentImagesDto,
  patientEntryRequestDto,
  plansDTO,
  sortedFaxDetails,
} from './dto/models';
import { RestService } from '@abp/ng.core';
import type {
  PagedAndSortedResultRequestDto,
  PagedResultDto,
} from '@abp/ng.core';
import { Injectable } from '@angular/core';
import { inboundDocumentStatus } from '../localization/enumeration/inbound-document-status.enum';
import { PatientPersonalDTO } from 'projects/patient/src/app/patient-proxy/patient-optimization/dto';
import { statusDTO } from 'projects/order/src/app/order-proxy/order-management/inventory/dto';
import { CreateUpdateInboundStatusDTO } from '../dropdowns/dto';
import { AnyMxRecord } from 'dns';
import { inboundStatusDTO } from '../organization-units/dto';
import {
  CalendarDTO,
  CreateUpdateCalendarDTO,
  ScheduleDTO,
} from 'projects/on-time-schedules/src/app/onTime-proxy/rcm/on-time-management/on-time-schedule/dto/models';
import type { IFormFile } from '../../../microsoft/asp-net-core/http/models';
import { Observable } from 'rxjs';
import { InboundSortedfilter } from 'projects/shared/src/app/enums/allenums.enum';
import { MyFaxfilter } from './my-faxfilter.enum';
import { RXDateReportDTO } from 'projects/order/src/app/order-proxy/order-management/order-optimization/dto/models';
import { SortingFilter } from '../localization/enumeration/sorting-filter.enum';
import { CalendarStatus } from 'projects/on-time-schedules/src/app/onTime-proxy/rcm/on-time-management/on-time-schedule/calendar-status.enum';
import { TAtFilter } from '../localization/enumeration/tat-filter.enum';

@Injectable({
  providedIn: 'root',
})
export class InboundDocumentService {
  apiName = 'platformManagement';
  getStatusList = (input: PagedAndSortedResultRequestDto) =>
    this.restService.request<any, PagedResultDto<statusDTO>>(
      {
        method: 'GET',
        url: '/api/PlatformApp/inbound-status',
      },
      { apiName: this.apiName }
    );

    getStatusDropdown = (input: PagedAndSortedResultRequestDto) =>
      this.restService.request<any, PagedResultDto<InboundStatusDTO>>(
        {
          method: 'GET',
          url: '/api/PlatformApp/inbound-status',
        },
        { apiName: this.apiName }
      );
  getStatusFilterList = (statusFilter: number) =>
    this.restService.request<any, any>(
      {
        method: 'GET',
        url: '/api/PlatformApp/inbound-status/inbound-status-list',
        params: { statusFilter },
      },
      { apiName: this.apiName }
    );

  createStatus = (input: CreateUpdateInboundStatusDTO) =>
    this.restService.request<any, any>(
      {
        method: 'POST',
        url: '/api/PlatformApp/inbound-status',
        body: input,
      },
      { apiName: this.apiName }
    );
  updateStatus = (id: string, input: CreateUpdateInboundStatusDTO) =>
    this.restService.request<any, any>(
      {
        method: 'PUT',
        url: `/api/PlatformApp/inbound-status/${id}`,
        body: input,
      },
      { apiName: this.apiName }
    );
  updateStatusV1 = (id: string, iNumber: number) =>
    this.restService.request<any, any>(
      {
        method: 'PUT',
        url: `/api/PlatformApp/inbound-status/update-status-order/${id}`,
        params: { iNumber },
      },
      { apiName: this.apiName }
    );
  getById = (id: string) =>
    this.restService.request<any, any>(
      {
        method: 'GET',
        url: `/api/PlatformApp/inbound-status/${id}`,
      },
      { apiName: this.apiName }
    );
  deleteInboundStatus = (id: string) =>
    this.restService.request<any, void>(
      {
        method: 'DELETE',
        url: `/api/PlatformApp/inbound-status/${id}`,
      },
      { apiName: this.apiName }
    );
  saveCallBack = (
    docId: string,
    days: string,
    notes: string,
    gNoteTypeId: string,
    sNoteType: string,
    currentShortCodeId:string
  ) =>
    this.restService.request<any, any>(
      {
        method: 'PUT',
        url: `/api/PlatformApp/inbound-document/call-back-status`,
        params: {
          documentId: docId,
          days: days,
          sNotes: notes,
          gNoteTypeId: gNoteTypeId,
          sNoteType: sNoteType,
          currentShortCode:currentShortCodeId
        },
      },
      { apiName: this.apiName }
    );
  updateEnableDisable = (id: string, input: any) =>
    this.restService.request<any, any>(
      {
        method: 'POST',
        url: `/api/PlatformApp/inbound-status/enable-disable-status/${id}?input=${input}`,
        // body: input,
      },
      { apiName: this.apiName, skipHandleError: true }
    );

  getInboundStatusFilter = (id: string) =>
    this.restService.request<any, PagedResultDto<inboundStatusDTO>>(
      {
        method: 'GET',
        url: `/api/PlatformApp/inbound-status/inbound-status-list?statusFilter=${id}`,
      },
      { apiName: this.apiName }
    );

  create = (inboundDetails: CreateUpdateInboundDocumentDTO) =>
    this.restService.request<any, InboundDocumentDTO>(
      {
        method: 'POST',
        url: '/api/PlatformApp/inbound-document',
        body: inboundDetails,
      },
      { apiName: this.apiName }
    );

  // delete = (id: string) =>
  //   this.restService.request<any, void>(
  //     {
  //       method: 'DELETE',
  //       url: `/api/PlatformApp/inbound-document/${id}`,
  //     },
  //     { apiName: this.apiName }
  //   );

  delete = (inBoundId: string) =>
    this.restService.request<any, void>(
      {
        method: 'DELETE',
        url: '/api/PlatformApp/inbound-document',
        params: { inBoundId },
      },
      { apiName: this.apiName }
    );

  get = (id: string) =>
    this.restService.request<any, InboundDocumentDTO>(
      {
        method: 'GET',
        url: `/api/PlatformApp/inbound-document/${id}`,
      },
      { apiName: this.apiName }
    );

  getList = (input: PagedAndSortedResultRequestDto) =>
    this.restService.request<any, PagedResultDto<InboundDocumentDTO>>(
      {
        method: 'GET',
        url: '/api/PlatformApp/inbound-document',
        params: {
          skipCount: input.skipCount,
          maxResultCount: input.maxResultCount,
          sorting: input.sorting,
        },
      },
      { apiName: this.apiName }
    );

  update = (id: string, inboundDetails: CreateUpdateInboundDocumentDTO) =>
    this.restService.request<any, InboundDocumentDTO>(
      {
        method: 'PUT',
        url: `/api/PlatformApp/inbound-document/${id}`,
        body: inboundDetails,
      },
      { apiName: this.apiName }
    );

  STATupdate = (id: string) =>
    this.restService.request<any, string>(
      {
        method: 'PUT',
        responseType: 'text',
        url: `/api/PlatformApp/inbound-document/update-document-stat/${id}`,
      },
      { apiName: this.apiName }
    );

  assignDocumentByIdAndInboundDetails = (
    id: string,
    inboundDetails: CreateUpdateInboundDocumentDTO
  ) =>
    this.restService.request<any, InboundDocumentDTO>(
      {
        method: 'POST',
        url: `/api/PlatformApp/inbound-document/${id}/assign-document`,
        body: inboundDetails,
      },
      { apiName: this.apiName }
    );

  downloadInboundDocumentByBlobName = (blobName: string) =>
    this.restService.request<any, string>(
      {
        method: 'POST',
        responseType: 'text',
        url: '/api/PlatformApp/inbound-document/download-inbound-document',
        params: { blobName },
      },
      { apiName: this.apiName }
    );

    getPatientDocumentByPathByDocumentId = (documentId: string) =>
      this.restService.request<any, number[]>({
        method: 'GET',
        url: `/api/PlatformApp/inbound-document/patient-document-by-path/${documentId}`,
      },
      { apiName: this.apiName });

    getPatientDocumentByBlobIDByBlobName = (blobName: string) =>
      this.restService.request<any, string>({
        method: 'GET',
        responseType: 'text',
        url: '/api/app/patient-document/patient-document-by-blob-iD',
        params: { blobName },
      },
      { apiName: this.apiName });

      // getPatientDocumentByBlobIDByBlobName = (blobName: string) =>
      //   this.restService.request<any, number[]>({
      //     method: 'GET',
      //     url: '/api/app/patient-document/patient-document-by-blob-iD',
      //     params: { blobName },
      //   },
      //   { apiName: this.apiName });

  // getInboundDocsList = (defaultDocumentId: string, documentName: string, patientName: string, dob: string,
  //   uploadedBy: string, uploadedDate: string, documentStatus: string, assignedTo: string, assignedBy: string, assignedDate: string) =>
  //   this.restService.request<any, InboundDocsList[]>({
  //     method: 'GET',
  //     url: '/api/PlatformApp/inbound-document/get-inbound-docs-list',
  //     params: { defaultDocumentId, documentName, patientName, dob, uploadedBy, uploadedDate, documentStatus, assignedTo, assignedBy, assignedDate },
  //   },
  //     { apiName: this.apiName });

  getInboundDocsList = (
    defaultDocument: string,
    defaultFax: string,
    documentName: string,
    uploadedType: string,
    uploadedBy: string,
    dtUploadedDate: string,
    patientName: string,
    dtDob: string,
    documentStatus: string,
    assignedTo: string,
    assignedBy: string,
    dtAssignedDate: string,
    fromDate: string,
    toDate: string,
    patientCount: string,
    faxNumber: string,
    faxName: string
  ) =>
    this.restService.request<any, InboundDocsList[]>(
      {
        method: 'GET',
        url: '/api/PlatformApp/inbound-document/get-inbound-docs-list',
        params: {
          defaultDocument,
          defaultFax,
          documentName,
          uploadedType,
          uploadedBy,
          dtUploadedDate,
          patientName,
          dtDob,
          documentStatus,
          assignedTo,
          assignedBy,
          dtAssignedDate,
          fromDate,
          toDate,
          patientCount,
          faxNumber,
          faxName,
        },
      },
      { apiName: this.apiName }
    );
  //   getInboundDocsListV1 = (defaultDocumentId: string, documentName: string, patientName: string, dob: string,
  //     uploadedBy: string, uploadedDate: string, documentStatus: string,assignedTo: string,assignedBy: string,assignedDate:string) =>
  //  this.restService.request<any, InboundDocsList[]>({
  //    method: 'GET',
  //    url: '/api/PlatformApp/inbound-document/get-inbound-docs-list-v1',
  //    params: { defaultDocumentId, documentName, patientName, dob, uploadedBy, uploadedDate, documentStatus,assignedTo,assignedBy,assignedDate },
  //  },
  //    { apiName: this.apiName });
  getInboundDocsListByStatus = (status: string) =>
    this.restService.request<any, InboundDocsList[]>(
      {
        method: 'GET',
        responseType: 'json',
        url: '/api/PlatformApp/inbound-document/get-archived-list-by-status',
        params: {
          status: status,
        },
      },
      { apiName: this.apiName }
    );
  inserNewPatient = (patientDto: NewPatientDTO) =>
    this.restService.request<any, PatientPersonalDTO>(
      {
        method: 'POST',
        responseType: 'json',
        url: `/api/PlatformApp/inbound-document/new-patient`,
        body: patientDto,
      },
      { apiName: this.apiName }
    );
  getPatientDetail = (patientId: string) =>
    this.restService.request<any, PatientInboundDetailDTO>(
      {
        method: 'GET',
        responseType: 'json',
        url: `/api/PlatformApp/inbound-document/get-patient-details/${patientId}`,
      },
      { apiName: this.apiName }
    );
  getPatientDemographicsDetails = (gDocument: string) =>
    this.restService.request<any, any>(
      {
        method: 'GET',
        responseType: 'json',
        url: `/api/PlatformApp/inbound-document/get-new-set-up-details`,
        params: { gDocument: gDocument },
      },
      { apiName: this.apiName }
    );
  getInitialAssignedDocuments = (inboundDocumentId: string) =>
    this.restService.request<any, InboundDocumentDetailsDto[]>(
      {
        method: 'GET',
        responseType: 'json',
        url: `/api/PlatformApp/inbound-document/get-initial-assigned-documents/${inboundDocumentId}`,
      },
      { apiName: this.apiName }
    );
  getProcessDocumentDetails = (
    documentId: string,
    pageCount: string,
    wipId: string,
    patientId: string,
    name: string,
    assignedDate: string,
    fromDate: string,
    toDate: string,
    assignedToName: string,
    documentStatus: string,
    processedDate: string
  ) =>
    this.restService.request<any, ProcessDocumentDetailsDTO[]>(
      {
        method: 'GET',
        url: '/api/PlatformApp/inbound-document/get-process-document-details',
        params: {
          documentId,
          wipId,
          patientId,
          name,
          assignedDate,
          fromDate,
          toDate,
          assignedToName,
          documentStatus,
          processedDate,
        },
      },
      { apiName: this.apiName }
    );

  getSortingDocumentDetails = (
    documentId: string,
    wipId: string,
    patientId: string,
    name: string,
    assignedDate: string,
    fromDate: string,
    toDate: string,
    assignedToName: string,
    documentStatus: string,
    sortedDate: string
  ) =>
    this.restService.request<any, SortingDocumentDetailsDTO[]>(
      {
        method: 'GET',
        url: '/api/PlatformApp/inbound-document/get-sorted-document-details',
        params: {
          documentId,
          wipId,
          patientId,
          name,
          assignedDate,
          fromDate,
          toDate,
          assignedToName,
          documentStatus,
          sortedDate,
        },
      },
      { apiName: this.apiName }
    );

  downloadInboundDocumentByDocumentId = (documentId: string) =>
    this.restService.request<any, string>(
      {
        method: 'POST',
        responseType: 'text',
        url: `/api/PlatformApp/inbound-document/download-inbound-document-by-document-id/${documentId}`,
      },
      { apiName: this.apiName }
    );
  approveInboundDocumentByGInboundDocumentId = (gInboundDocumentId: string) =>
    this.restService.request<any, string>(
      {
        method: 'POST',
        responseType: 'text',
        url: `/api/PlatformApp/inbound-document/approve-inbound-document/${gInboundDocumentId}`,
      },
      { apiName: this.apiName }
    );

  // getInboundFaxDocsByUserAndBranchAndInboundStatusAndMonthAndYear = (
  //   user: string[],
  //   branch: string[],
  //   inboundStatus: string,
  //   month: number,
  //   year: number
  // ) =>
  //   this.restService.request<any, PagedResultDto<InboundDocsList>>(
  //     {
  //       method: 'GET',
  //       url: '/api/PlatformApp/inbound-document/get-inbound-fax-docs',
  //       params: { user, branch, inboundStatus, month, year },
  //     },
  //     { apiName: this.apiName }
  //   );

  getInboundFaxDocsByUserAndBranchAndStatusCodeAndMonthAndYear = (
    user: string[],
    branch: string[],
    statusCode: string,
    month: number,
    year: number,
    pageNo: number,
    pageOffset: number
  ) =>
    this.restService.request<any, PagedResultDto<InboundDocsList>>(
      {
        method: 'GET',
        url: '/api/PlatformApp/inbound-document/get-inbound-fax-docs',
        params: { user, branch, statusCode, month, year, pageNo, pageOffset},
      },
      { apiName: this.apiName }
    );

  updateLocation = (inboundId: string, branchId: string) =>
    this.restService.request<any, string>(
      {
        method: 'PUT',
        url: `/api/PlatformApp/inbound-document/update-location?inBoundId=${inboundId}&branchId=${branchId}`,
      },
      { apiName: this.apiName }
    );
  // updateInboundStatus = (
  //   inboundId: string,
  //   statusShortCodeId: string,
  //   notes: string
  // ) =>
  //   this.restService.request<any, string>(
  //     {
  //       method: 'PUT',
  //       url: `/api/PlatformApp/inbound-document/inbound-status/${inboundId}?sShortCode=${statusShortCodeId}&sNotes=${notes}`,
  //     },
  //     { apiName: this.apiName }
  //   );

  updateInboundStatusByInboundDocumentIdAndSShortCodeAndSNotes = (
    inboundDocumentId: string,
    sShortCode: string,
    inboundStatusId: string,
    sNotes: string
  ) =>
    this.restService.request<any, APIResponseDTO>(
      {
        method: 'PUT',
        url: `/api/PlatformApp/inbound-document/inbound-status`,
        params: { inboundDocumentId, sShortCode, inboundStatusId, sNotes },
      },
      { apiName: this.apiName }
    );

  // updateInboundStatus = (inboundDocumentId: string, sShortCode: string, inboundStatusId: string, sNotes: string) =>
  // this.restService.request<any, APIResponseDTO>({
  //   method: 'PUT',
  //   url: `/api/PlatformApp/inbound-document/inbound-status`,
  //   params: { inboundDocumentId, sShortCode, inboundStatusId, sNotes},
  // },
  // { apiName: this.apiName });

  updateMarkconfirmed = (
    gInboundId: string,
    sNotes: string,
    gNoteTypeId: string,
    sNoteType: string
  ) =>
    this.restService.request<any, any>(
      {
        method: 'PUT',
        url: `/api/PlatformApp/inbound-document/update-confirmed-status`,
        params: {
          gInboundId: gInboundId,
          sNotes: sNotes,
          gNoteTypeId: gNoteTypeId,
          sNoteType: sNoteType,
        },
      },
      { apiName: this.apiName }
    );
  getDetailsWithBranchIdByGBranchIdAndGDocumentId = (
    gBranchId: string,
    gDocumentId: string
  ) =>
    this.restService.request<any, any>(
      {
        method: 'GET',
        url: '/api/PlatformApp/inbound-document/get-details-with-branch-id',
        params: { gBranchId, gDocumentId },
      },
      { apiName: this.apiName }
    );
  joinPreviousDocumentByGMasterInbounDocIdAndGNewFaxInboundDocId = (
    gMasterInbounDocId: string,
    gNewFaxInboundDocId: string,
    isJoin?:boolean
  ) =>
    this.restService.request<any, APIResponseDTO>(
      {
        method: 'POST',
        url: '/api/PlatformApp/inbound-document/join-previous-document',
        params: { gMasterInbounDocId, gNewFaxInboundDocId,isJoin },
      },
      { apiName: this.apiName }
    );
    multipleJoinPreviousDocument = (
      gMasterInbounDocId: string,
      lstFaxInboundDocId: string,
      isJoin?: boolean
    ) =>
      this.restService.request<any, APIResponseDTO>(
        {
          method: 'POST',
          url: '/api/PlatformApp/inbound-document/multiple-join-previous-document',
          params:{gMasterInbounDocId,isJoin},
          body:  lstFaxInboundDocId ,
        },
        { apiName: this.apiName }
      );

  getInboundDocumentImages = (inboundId: string) =>
    this.restService.request<any, documentImagesDto[]>(
      {
        method: 'POST',
        url: `/api/PlatformApp/inbound-document/view-document-image/${inboundId}`,
      },
      { apiName: this.apiName }
    );
  getSplitDocumentImages = (inboundId: string) =>
    this.restService.request<any, documentImagesDto[]>(
      {
        method: 'POST',
        url: `/api/PlatformApp/inbound-document/convert-pdf-to-image-file/${inboundId}`,
      },
      { apiName: this.apiName }
    );

  undoDeleteByInBoundId = (inBoundId: string) =>
    this.restService.request<any, string>(
      {
        method: 'POST',
        responseType: 'text',
        url: `/api/PlatformApp/inbound-document/undo-delete/${inBoundId}`,
      },
      { apiName: this.apiName }
    );

  getInboundPdfPage = (docId: string, pageNumber: number) =>
    this.restService.request<any, string>(
      {
        method: 'GET',
        responseType: 'text',
        url: `/api/PlatformApp/inbound-document/page-from-document/${docId}?pageNumber=${pageNumber}`,
      },
      { apiName: this.apiName }
    );
  splitInboundDocument = (documentId: string, pageNumbers: number[],isSplit?:boolean) =>
    this.restService.request<any, APIResponseDTO>(
      {
        method: 'POST',
        // responseType: 'text',
        url: `/api/PlatformApp/inbound-document/split-document/${documentId}`,
        params:{isSplit},
        body: pageNumbers,
      },
      { apiName: this.apiName }
    );

  // getSortedDocumentPagesByGDocumentId = (gDocumentId: string) =>
  // this.restService.request<any, SortedDocumentGroupDTO[]>({
  //   method: 'GET',
  //   url: `/api/PlatformApp/inbound-document/get-sorted-document-pages/${gDocumentId}`,
  // },
  // { apiName: this.apiName });

  getSortedDocumentPagesByGDocumentId = (gDocumentId: string) =>
    this.restService.request<any, clsSortedDocuemnts>(
      {
        method: 'GET',
        url: `/api/PlatformApp/inbound-document/get-sorted-document-pages/${gDocumentId}`,
      },
      { apiName: this.apiName }
    );

  getSortedDocumentPagesByGDocumentIdTiff = (gDocumentId: string) =>
    this.restService.request<any, clsSortedDocs>(
      {
        method: 'GET',
        url: `/api/PlatformApp/inbound-document/get-sorted-document-pages/${gDocumentId}`,
      },
      { apiName: this.apiName }
    );

  StatusFilter = (statusFilter: number) =>
    this.restService.request<any, any>(
      {
        method: 'GET',
        url: '/api/PlatformApp/inbound-status/inbound-status-list',
      },
      { apiName: this.apiName }
    );

  createManualUploadByInboundDetails = (inboundDetails: clsMultipleDocUpload) =>
    this.restService.request<any, InboundDocumentDTO[]>(
      {
        method: 'POST',
        url: '/api/PlatformApp/inbound-document/create-manual-upload',
        body: inboundDetails,
      },
      { apiName: this.apiName }
    );

  createManualUploadV1ByFileAndBranchIdAndIsStat = (
    file: File,
    branchId: string,
    isStat: number
  ) =>
    this.restService.request<any, InboundDocumentDTO[]>(
      {
        method: 'POST',
        url: `/api/PlatformApp/inbound-document/create-manual-upload-v1/${branchId}`,
        params: { isStat },
        body: file,
      },
      { apiName: this.apiName }
    );

  createManualUploadV1ByFileAndBranchIdAndIsStatV1(
    files: File[],
    branchId: string,
    isStat: number
  ): Observable<InboundDocumentDTO[]> {
    const formData = new FormData();
    for (let i = 0; i < files.length; i++) {
      formData.append('file', files[i]);
    }
    // formData.append('isStat', isStat.toString());
    return this.restService.request<any, InboundDocumentDTO[]>(
      {
        method: 'POST',
        url: `/api/PlatformApp/inbound-document/create-manual-upload-v1/${branchId}?isStat=${isStat}`,
        body: formData,
      },
      { apiName: this.apiName }
    );
  }
  createManualUploadV1ByFileAndBranchIdAndIsStatV3(
    files: File[],
    branchId: string,
    isStat: number
  ): Observable<any> {
    const formData = new FormData();
    for (let i = 0; i < files.length; i++) {
      formData.append('file', files[i]);
    }
    // formData.append('isStat', isStat.toString());
    return this.restService.request<any, any>(
      {
        method: 'POST',
        url: `/api/PlatformApp/inbound-document/create-manual-upload-v3/${branchId}?isStat=${isStat}`,
        body: formData,
      },
      { apiName: this.apiName }
    );
  }

  // createManualUploadV1ByFileAndBranchIdAndIsStatV1(files: File[], branchId: string, isStat: number): Observable<InboundDocumentDTO[]> {
  //   const formData = new FormData();

  //   for (let i = 0; i < files.length; i++) {
  //     formData.append('file', files[i]);
  //   }

  //   // You can append more form data if needed

  //   const url = `${this.apiUrl}/api/PlatformApp/inbound-document/create-manual-upload-v1/${branchId}?isStat=${isStat}`;

  //   return this.http.post<InboundDocumentDTO[]>(url, formData);
  // }

  checksortedNoExists = (iSortNumber: number) =>
    this.restService.request<any, any>(
      {
        method: 'POST',
        url: '/api/PlatformApp/inbound-status/check-number-sorted',
        params: { iSortNumber },
      },
      { apiName: this.apiName }
    );
  getPatientDocumentDetailsByGPatient = (gPatient: string) =>
    this.restService.request<any, displayDocumentDetails>(
      {
        method: 'GET',
        url: '/api/PlatformApp/inbound-document/get-patient-document-details',
        params: { gPatient },
      },
      { apiName: this.apiName }
    );

  saveDemographics = (gPatientDemo: DemographicDTO) =>
    this.restService.request<any, NewsetupDemographicsDTO>(
      {
        method: 'PUT',
        url: '/api/PlatformApp/inbound-document/update-patient-demographics',
        body: gPatientDemo,
      },
      { apiName: this.apiName }
    );
    updatePatientDemographicsNewByDemographicsInput = (demographicsInput: CreateUpdateNewsetupDemographicsDTO) =>
      this.restService.request<any, ResponseDemographicsDTO>({
        method: 'PUT',
        url: '/api/PlatformApp/inbound-document/update-patient-demographics-new',
        body: demographicsInput,
      },
      { apiName: this.apiName });
  savePlans = (gPatientPlans: plansDTO) =>
    this.restService.request<any, any>(
      {
        method: 'PUT',
        url: '/api/PlatformApp/inbound-document/update-patient-plans',
        body: gPatientPlans,
      },
      { apiName: this.apiName }
    );

  getVerificationStatusByStatusID = (statusID: string) =>
    this.restService.request<any, InboundListDTO[]>(
      {
        method: 'GET',
        url: '/api/PlatformApp/inbound-document/verification-status',
        params: { statusID },
      },
      { apiName: this.apiName }
    );

  sendMailtoPatientByPatientIDAndSContentAndSToAddress = (
    patientID: string,
    sContent: string,
    sToAddress: string
  ) =>
    this.restService.request<any, boolean>(
      {
        method: 'POST',
        url: '/api/PlatformApp/inbound-document/send-mailto-patient',
        params: { patientID, sContent, sToAddress },
      },
      { apiName: this.apiName }
    );

  createCalendarByInput = (input: CreateUpdateCalendarDTO) =>
    this.restService.request<any, CalendarDTO>(
      {
        method: 'POST',
        url: '/api/PlatformApp/inbound-document/calendar',
        body: input,
      },
      { apiName: this.apiName }
    );

  getCalendarByUserId = (userId: string) =>
    this.restService.request<any, PagedResultDto<CalendarDTO>>(
      {
        method: 'GET',
        url: `/api/PlatformApp/inbound-document/get-calendar/${userId}`,
      },
      { apiName: this.apiName }
    );

    getCalendarByUserIdAndLocationIdAndTypeId = (userId: string, locationId: string, typeId: string) =>
      this.restService.request<any, PagedResultDto<CalendarDTO>>({
        method: 'GET',
        url: '/api/PlatformApp/inbound-document/get-calendar',
        params: { userId, locationId, typeId },
      },
      { apiName: this.apiName });

    // getCalendarV1ByDto = (dto: GetCalendarDTO) =>
    //   this.restService.request<any, PagedResultDto<CalendarDTO>>({
    //     method: 'GET',
    //     url: '/api/PlatformApp/inbound-document/get-calendar-v1',
    //     params: { typeId: dto.typeId, userId: dto.userId, locationId: dto.locationId },
    //   },
    //   { apiName: this.apiName });

    getCalendarV1ByDto = (dto: GetCalendarDTO) =>
      this.restService.request<any, PagedResultDto<CalendarDTO>>({
        method: 'POST',
        url: '/api/PlatformApp/inbound-document/get-calendar-v1',
        body: dto,
      },
      { apiName: this.apiName });


  getScheduleDetailsByPatientID = (PatientID: string) =>
    this.restService.request<any, ScheduleDTO>(
      {
        method: 'GET',
        url: '/api/PlatformApp/inbound-document/schedule-details',
        params: { patientID: PatientID },
      },
      { apiName: this.apiName }
    );

  deleteCalendarByIdAndCalendarDetailsId = (
    id: string,
    calendarDetailsId: string
  ) =>
    this.restService.request<any, CalendarDTO>(
      {
        method: 'DELETE',
        url: `/api/PlatformApp/inbound-document/${id}/delete-calendar/${calendarDetailsId}`,
      },
      { apiName: this.apiName }
    );

  updateCalendarByIdAndInput = (id: string, input: CreateUpdateCalendarDTO) =>
    this.restService.request<any, CalendarDTO>(
      {
        method: 'PUT',
        url: `/api/PlatformApp/inbound-document/${id}/calendar`,
        body: input,
      },
      { apiName: this.apiName }
    );

  getFaxReportByIMonthAndIYear = (iMonth: number, iYear: number) =>
    this.restService.request<any, PagedResultDto<InboundDocsList>>(
      {
        method: 'GET',
        url: '/api/PlatformApp/inbound-document/get-fax-report',
        params: { iMonth, iYear },
      },
      { apiName: this.apiName }
    );

  // getFaxDocumentDetailsByFilter = (filter: FaxFilter) =>
  // this.restService.request<any, FaxFinderDTO[]>({
  //   method: 'GET',
  //   url: '/api/PlatformApp/inbound-document/get-fax-document-details',
  //   params: { chatId: filter.chatId, fromDate: filter.fromDate, toDate: filter.toDate },
  // },
  // { apiName: this.apiName });

  getFaxDocumentDetailsByFilter = (
    iPage: number,
    iSize: number,
    Dob: string,
    FaxId: string,
    chartId: string,
    patientName: string
  ) =>
    this.restService.request<any, clsFaxFinders>(
      {
        method: 'GET',
        url: '/api/PlatformApp/inbound-document/get-fax-document-details',
        params: { iPage, iSize, dob: Dob, faxId: FaxId, chartId, patientName },
      },
      { apiName: this.apiName }
    );
    getFaxDocumentDetailsNewByISkipAndITakeAndDobAndFaxIdAndChartIdAndPatientName = (iSkip: number, iTake: number, Dob: string, FaxId: string, chartId: string, patientName: string) =>
      this.restService.request<any, clsFaxFinders>({
        method: 'GET',
        url: '/api/PlatformApp/inbound-document/get-fax-document-details-new',
        params: { iSkip, iTake, dob: Dob, faxId: FaxId, chartId, patientName },
      },
      { apiName: this.apiName });

    getFaxDocumentDetailsNewByISkipAndITakeAndDobAndFaxIdAndChartIdAndPatientNameAndFaxStatus = (iSkip: number, iTake: number, Dob: string, FaxId: string, chartId: string, patientName: string, faxStatus: string[]) =>
      this.restService.request<any, clsFaxFinders>({
        method: 'GET',
        url: '/api/PlatformApp/inbound-document/get-fax-document-details-new',
        params: { iSkip, iTake, dob: Dob, faxId: FaxId, chartId, patientName, faxStatus },
      },
      { apiName: this.apiName });

  getTIFFImageBase64ByGId = (gId: string) =>
    this.restService.request<any, TIFFBase64DTO[]>(
      {
        method: 'GET',
        url: `/api/PlatformApp/inbound-document/t-iFFImage-base64/${gId}`,
      },
      { apiName: this.apiName }
    );
  getTIFFImageBase64v1ByGIdAndFromAndTo = (
    gId: string,
    from: number,
    to: number
  ) =>
    this.restService.request<any, TIFFDTO>(
      {
        method: 'GET',
        url: `/api/PlatformApp/inbound-document/t-iFFImage-base64v1/${gId}`,
        params: { from, to },
      },
      { apiName: this.apiName }
    );

  getFaxLogByIMonthAndIYearAndPracticeId = (
    iMonth: number,
    iYear: number,
    practiceId: string
  ) =>
    this.restService.request<any, PracticeFaxLogDTO[]>(
      {
        method: 'GET',
        url: `/api/PlatformApp/inbound-document/get-fax-log/${practiceId}`,
        params: { iMonth, iYear },
      },
      { apiName: this.apiName }
    );

  // searchCalendarByInput = (input: SearchCalendarDTO) =>
  // this.restService.request<any, PagedResultDto<CalendarDTO>>({
  //   method: 'GET',
  //   url: '/api/PlatformApp/inbound-document/search-calendar',
  //   params: { roles: input.roles, users: input.users, location: input.location, patient: input.patient, fromDate: input.fromDate, toDate: input.toDate, status: input.status },
  // },
  // { apiName: this.apiName });

  // searchCalendarByCalendarDTO = (calendarDTO: SearchCalendarDTO) =>
  // this.restService.request<any, PagedResultDto<CalendarDTO>>({
  //   method: 'GET',
  //   url: '/api/PlatformApp/inbound-document/search-calendar',
  //   params: { roles: calendarDTO.roles, users: calendarDTO.users, location: calendarDTO.location, patient: calendarDTO.patient, patientName: calendarDTO.patientName, locationName: calendarDTO.locationName, chartNo: calendarDTO.chartNo, fromDate: calendarDTO.fromDate, toDate: calendarDTO.toDate, status: calendarDTO.status },
  // },
  // { apiName: this.apiName });

  // searchCalendarByCalendarDTO = (calendarDTO: SearchCalendarDTO) =>
  //   this.restService.request<any, PagedResultDto<CalendarDTO>>(
  //     {
  //       method: 'GET',
  //       url: '/api/PlatformApp/inbound-document/search-calendar',
  //       params: {
  //         roles: calendarDTO.roles,
  //         users: calendarDTO.users,
  //         location: calendarDTO.location,
  //         patient: calendarDTO.patient,
  //         patientName: calendarDTO.patientName,
  //         locationName: calendarDTO.locationName,
  //         chartNo: calendarDTO.chartNo,
  //         fromDate: calendarDTO.fromDate,
  //         toDate: calendarDTO.toDate,
  //         status: calendarDTO.status,
  //       },
  //     },
  //     { apiName: this.apiName }
  //   );

  searchCalendarByCalendarDTO = (calendarDTO: SearchCalendarDTO) =>
    this.restService.request<any, PagedResultDto<CalendarDTO>>({
      method: 'POST',
      url: '/api/PlatformApp/inbound-document/search-calendar',
      body: calendarDTO,
    },
    { apiName: this.apiName });

  addAnnotationByDocumentIdByDto = (dto: AnnotatedDTO) =>
    this.restService.request<any, string>(
      {
        method: 'POST',
        responseType: 'text',
        url: '/api/PlatformApp/inbound-document/annotation-by-document-id',
        body: dto,
      },
    { apiName: this.apiName }
    );

  getSortedDetailsByFromDateAndToDateAndSortedfilter = (
    fromDate: string,
    toDate: string,
    sortedfilter: InboundSortedfilter
  ) =>
    this.restService.request<any, sortedFaxDetails[]>(
      {
        method: 'GET',
        url: '/api/PlatformApp/inbound-document/get-sorted-details',
        params: { fromDate, toDate, sortedfilter },
      },
      { apiName: this.apiName }
    );

  updateLockstatusByDocumentId = (documentId: string) =>
    this.restService.request<any, string>(
      {
        method: 'PUT',
        responseType: 'text',
        url: `/api/PlatformApp/inbound-document/update-lockstatus/${documentId}`,
      },
      { apiName: this.apiName }
    );

  getLockDocDetails = () =>
    this.restService.request<any, LockDocListDTO[]>(
      {
        method: 'GET',
        url: '/api/PlatformApp/inbound-document/lock-doc-details',
      },
      { apiName: this.apiName }
    );

  checkExistDocument = (documentId: string ,lname: string, Dob: string,) =>
    this.restService.request<any, any>(
      {
        method: 'GET',
        url: `/api/PlatformApp/inbound-document/patient-doc-details/${documentId}`,
        params: {  lname, Dob},
      },
      { apiName: this.apiName }
    );

  getSortedDocumentPagesv1ByGDocumentId = (gDocumentId: string) =>
    this.restService.request<any, clsSortedDocsv1>(
      {
        method: 'GET',
        url: `/api/PlatformApp/inbound-document/get-sorted-document-pagesv1/${gDocumentId}`,
      },
      { apiName: this.apiName }
    );

  getSortedDocumentDetailsForHellosignByGDocumentId = (gDocumentId: string) =>
    this.restService.request<any, clsSortedDocsv1>(
      {
        method: 'GET',
        url: `/api/PlatformApp/inbound-document/get-sorted-document-details-for-hellosign/${gDocumentId}`,
      },
      { apiName: this.apiName }
    );

  getAllSortedTIFFImageBase64LazyByGIdAndFromAndTo = (
    gId: string,
    from: number,
    to: number
  ) =>
    this.restService.request<any, TFFPagesDTO>(
      {
        method: 'GET',
        url: `/api/PlatformApp/inbound-document/sorted-tIFFImage-base64Lazy/${gId}`,
        params: { from, to },
      },
      { apiName: this.apiName }
    );

  getPendingTIFFImageBase64LazyByGIdAndFromAndTo = (
    gId: string,
    from: number,
    to: number
  ) =>
    this.restService.request<any, TFFPages[]>(
      {
        method: 'GET',
        url: `/api/PlatformApp/inbound-document/pending-tIFFImage-base64Lazy/${gId}`,
        params: { from, to },
      },
      { apiName: this.apiName }
    );

  getMyFaxDocsByFilter = (filter: MyFaxfilter, pageNo :number, pageOffset : number) =>
    this.restService.request<any, PagedResultDto<InboundDocsList>>(
      {
        method: 'GET',
        url: '/api/PlatformApp/inbound-document/get-my-fax-docs',
        params: { filter, pageNo, pageOffset  },
      },
      { apiName: this.apiName }
    );

  getOriginalTIFFImageBase64ByGIdAndPageNo = (gId: string, pageNo: number) =>
    this.restService.request<any, TIFFBase64DTO>(
      {
        method: 'GET',
        url: `/api/PlatformApp/inbound-document/original-tIFFImage-base64/${gId}`,
        params: { pageNo },
      },
      { apiName: this.apiName }
    );

  updatesCsrLockForFaxByGDocId = (gDocId: string) =>
    this.restService.request<any, LockOutput>(
      {
        method: 'PUT',
        url: `/api/PlatformApp/inbound-document/updates-csr-lock-for-fax/${gDocId}`,
      },
      { apiName: this.apiName }
    );

  getRXDocumentsByInputAndGPractitionerIdAndSPracEmailAddress = (
    input: RXInputDTO[],
    gPractitionerId: string,
    sPracEmailAddress: string
  ) =>
    this.restService.request<any, UploadRxDocDTO[]>(
      {
        method: 'GET',
        url: `/api/PlatformApp/inbound-document/get-rXDocuments/${gPractitionerId}`,
        params: { input, sPracEmailAddress },
      },
      { apiName: this.apiName }
    );

  sendRXDocumentsByRxData = (RxData: RXUploadFilesDTO) =>
    this.restService.request<any, RXDateReportDTO[]>(
      {
        method: 'POST',
        url: '/api/PlatformApp/inbound-document/send-rXDocuments',
        body: RxData,
      },
      { apiName: this.apiName }
    );
  validatePatientEmailByGPatientIdAndEmailId = (
    gPatientId: string,
    emailId: string
  ) =>
    this.restService.request<any, boolean>(
      {
        method: 'POST',
        url: '/api/PlatformApp/inbound-document/validate-patient-email',
        params: { gPatientId, emailId },
      },
      { apiName: this.apiName }
    );

    // getListSMSTemplateId = (Id: number[]) =>
    //   this.restService.request<any, PagedResultDto<SMSMailTemplateDTO>>({
    //     method: 'GET',
    //     url: `/api/PlatformApp/inbound-document/s-mSTemplate-id/${Id}`,
    //   },
    //   { apiName: this.apiName });

    getListSMSTemplateId = (smsTemplateType: number[]) =>
      this.restService.request<any, PagedResultDto<SMSMailTemplateDTO>>({
        method: 'GET',
        url: '/api/PlatformApp/inbound-document/s-mSTemplate-id',
        params: { smsTemplateType },
      },
      { apiName: this.apiName });

      getAptclassList = () =>
        this.restService.request<any, aptClasslistDTO[]>({
          method: 'GET',
          url: '/api/PlatformApp/inbound-document/aptclass-list',
        },
        { apiName: this.apiName });

        getSortedDocumentPagesv2ByGDocumentId = (gDocumentId: string) =>
          this.restService.request<any, clsSortedDocsv2>({
            method: 'GET',
            url: `/api/PlatformApp/inbound-document/get-sorted-document-pagesv2/${gDocumentId}`,
          },
          { apiName: this.apiName });

          getMedicareImageBase64ByGDocIdAndPageNos = (gDocId: string, pageNos: number[]) =>
            this.restService.request<any, TFFPagesOutput[]>({
              method: 'GET',
              url: `/api/app/patient-medicare-check-list-details/medicare-image-base64/${gDocId}`,
              params: { pageNos },
            },
            { apiName: this.apiName });

      getSortedPatientDocumentsByGPatientDocId = (gPatientDocId: string) =>
        this.restService.request<any, clsSortedDocsv2>({
          method: 'GET',
          url: `/api/PlatformApp/inbound-document/get-sorted-patient-documents/${gPatientDocId}`,
        },
        { apiName: this.apiName });

        sendRxDocWithPDF(gDocumentId:string,orderId:string,patientId:string,sPracEmailAddress:string,sPageNo:string,file:File[])
          : Observable<any> {
            const pdfFile = new FormData();
            for (let i = 0; i < file.length; i++) {
              pdfFile.append('pdfFile', file[i]);
            }
            // formData.append('isStat', isStat.toString());
            // return this.restService.request<any, any>(
              return  this.restService.request<any, string>(
            {
              method: 'POST',
              url: '/api/PlatformApp/inbound-document/send-rXDocuments-with-pDF',
              params:{gDocumentId,orderId,patientId,sPracEmailAddress,sPageNo},
              body:pdfFile
            },
          { apiName: this.apiName }
          );
        }

      lockTheFaxWithParamsByFaxIdAndIsLocked = (faxId: string, isLocked: boolean) =>
        this.restService.request<any, LockOutput>({
          method: 'PUT',
          url: `/api/PlatformApp/inbound-document/lock-the-fax-with-params/${faxId}`,
          params: { isLocked },
        },
        { apiName: this.apiName });
        generateChartId = (gBranchId: string) =>
          this.restService.request<any, string>({
            method: 'POST',
            responseType: 'text',
            url: `/api/PlatformApp/inbound-document/generate-chart-id/${gBranchId}`,
          },
          { apiName: this.apiName });

          getInboundFaxDocsForWonaceByRequestDto = (requestDto: patientEntryRequestDto) =>
            this.restService.request<any, PatientEntryTab>({
              method: 'POST',
              url: '/api/PlatformApp/inbound-document/get-inbound-fax-docs-for-wonace',
              body: requestDto,
            },
            { apiName: this.apiName });

          getFaxIdByFaxDocAndSearchfax = (faxDoc: string, searchfax: string[]) =>
            this.restService.request<any, FaxIdDTO[]>({
              method: 'POST',
              url: '/api/PlatformApp/inbound-document/get-fax-id',
              params: { faxDoc },
              body: searchfax,
            },
            { apiName: this.apiName });

        getAuthStatusListByANameAndSAuth = (aName: string, sAuth: string[]) =>
          this.restService.request<any, AuthStatDTO[]>({
            method: 'POST',
            url: '/api/PlatformApp/inbound-document/get-auth-status-list',
            params: { aName },
            body: sAuth,
          },
          { apiName: this.apiName });

  constructor(private restService: RestService) {}
}
