import { data } from 'jquery';
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { defaultGuid } from 'projects/shared/src/app/enums/allenums.enum';
import { OrderHistoryService } from '../order-proxy/order-management/order-optimization/order-history.service';
import { OrderHistoryDTO } from '../order-proxy/order-management/order-optimization/dto';
import { CommonService } from 'projects/shared/src/app/services/common.service';

@Component({
  selector: 'app-order-history-modal',
  templateUrl: './order-history-modal.component.html',
  styleUrls: ['./order-history-modal.component.scss'],
})
export class OrderHistoryModalComponent implements OnInit {
  orderHistory: OrderHistoryDTO[] = [];
  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      orderId: string;
      mmOrderId: string;
      patientName: string;
      chartId: string;
    } = {
      orderId: defaultGuid,
      mmOrderId: '',
      patientName: '',
      chartId: '',
    },
    private orderHistoryService: OrderHistoryService,
    private commonService: CommonService
  ) {}
  ngOnInit() {
    this.getOrderHistory();
  }

  getOrderHistory() {
    const orderHistory = this.orderHistoryService
      .getHistoryByIdByOrderId(this.data.orderId)
      .subscribe(
        (response) => {
          this.orderHistory = response ?? [];

          this.orderHistory = this.orderHistory.map((order) => {
            return {
              ...order,
              createdDateNew: this.commonService.getFormattedDateTimeZone(
                order.creationTime
              ),
            };
          });
        },
        (err) => {}
      );
  }
  timeLineDetails: any = [];
  //   timeLineDetails: any = [
  //     {
  //       headingName: 'Heading 1',
  //       subheading: 'Sub Heading 1',
  //     },
  //     {
  //       headingName: 'Heading 2',
  //       subheading: 'Sub Heading 2',
  //     },
  //     {
  //       headingName: 'Heading 3',
  //       subheading: 'Sub Heading 3',
  //     },
  //     {
  //       headingName: 'Heading 4',
  //       subheading: 'Sub Heading 4',
  //     },
  //   ];
}
