import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatAccordion } from '@angular/material/expansion';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { FileUploadService } from 'projects/admin/src/app/admin-proxy/platform-app-management/rcm/platform-management/file-upload';
import { AssignUserDTO, FileUploadsWithBatchIdDTO } from 'projects/admin/src/app/admin-proxy/platform-app-management/rcm/platform-management/file-upload/dto';
import { InboundDocumentService } from 'projects/admin/src/app/admin-proxy/platform-app-management/rcm/platform-management/inbound';
import { UploadFilesService } from 'projects/admin/src/app/admin-proxy/platform-app-management/rcm/platform-management/upload-file/upload-files.service';
import { UserService } from 'projects/admin/src/app/admin-proxy/platform-app-management/rcm/platform-management/user';
import { IdentityUserDto } from 'projects/admin/src/app/admin-proxy/platform-app-management/volo/abp/identity';
import { InsuranceType } from 'projects/billing/src/app/claim-proxy/claim-processing-management/rcm/billing-management/billing';
import { ClaimProcessService, ZermidClearingHouseService } from 'projects/billing/src/app/claim-proxy/claim-processing-management/rcm/claim-processing-management/x12';
import { UploadFileStatus } from 'projects/shared/src/app/enums/allenums.enum';
import { TableService } from 'projects/shared/src/app/table.service';
import { Subscription } from 'rxjs';
import Swal from 'sweetalert2';
import { ToastrService } from "ngx-toastr";

import { baseUrl } from '../../environments/environment';
import { DocumentService } from '../patient-proxy/patient/document.service';
import { FaxBinService } from '../patient-proxy/patient/fax-bin.service';
import { PickUpExchangeService } from 'projects/order/src/app/order-proxy/order-management/order-optimization/pick-up-exchange.service';


@Component({
  selector: 'app-user-task-list',
  templateUrl: './user-task-list.component.html',
  styleUrls: ['./user-task-list.component.scss']
})
export class UserTaskListComponent implements OnInit {
  strPageType: string = "userfaxBin";
  strSelectedPdfPath: string = "";
  organizationUnitId: string = "";
  arrUsersList: IdentityUserDto[] = [];
  userId: string = "";
  panelOpenState = false;
  isLoading: boolean;
  tenantId: string = "";
  subscription$: Subscription[] = [];

  @ViewChild(MatAccordion, { static: true }) matAccordion: MatAccordion;
  constructor(
    private title: Title,
    private table: TableService,
    public userService: UserService,
    public dialog: MatDialog,
    private faxBinService: FaxBinService,
    private fileUploadService: FileUploadService,
    private documentService: DocumentService,
    private toastr: ToastrService,
  ) { }

  ngOnInit(): void {
    this.userId = localStorage.getItem("userId") ?? "";
    this.tenantId = localStorage.getItem("tenantId") ?? "";
    this.organizationUnitId = localStorage.getItem("initialOrganizationUnitId") ?? "";
    this.table.setlstfaxBinDataTable([]);
    this.title.setTitle("Qsecure | User Fax Bin");
    this.userId !== undefined && this.userId !== "" && this.userId !== null && this.getFaxBinTableData();
    this.tenantId !== undefined && this.tenantId !== "" && this.tenantId !== null && this.getUsersList();
  }

  ngOnDestroy(): void {
    this.subscription$?.forEach((sub) => {
      sub && sub?.unsubscribe();
    });
  }

  private getUsersList() {
    const identityUser = this.userService.getIdentityUser().subscribe((response: IdentityUserDto[]) => {
      this.arrUsersList = response;
    }, err => {
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    })
    this.subscription$.push(identityUser);
  }

  private getFaxBinTableData() {
    this.isLoading = true;
    this.table.setlstfaxBinDataTable([]);
    const assignedFile = this.fileUploadService.getAssignnedFilesByUserId(this.userId).subscribe(response => {
      const faxBinDataTable: any[] = response;
      this.table.setlstfaxBinDataTable(faxBinDataTable.filter(v => v.uploadedFileStatus === UploadFileStatus.Approved));
      this.isLoading = false;
    }, err => {
      this.isLoading = false;
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    });
    this.subscription$.push(assignedFile);
  }



  processSelectedDocumentsFaxBin(value: FileUploadsWithBatchIdDTO[]) {



    const documentArrayToBeSaved: AssignUserDTO[] = [];
    value?.forEach(element => {
      const patientDocumentBlobDTO: AssignUserDTO = {
        // batchId: string;
        // fileId: string;
        // assignedTo: string;
        // assignedTime: string;
        assignedTime: new Date()?.toLocaleDateString("en-US"),
        assignedTo: element.assignedTo ?? "",
        fileId: element.fileId,
        batchId: element.fileBatchId,

      }
      documentArrayToBeSaved.push(patientDocumentBlobDTO)
    });

    const assignedUserBatch = this.fileUploadService.updateAssignedUserInBatchByLstAssignUsers(documentArrayToBeSaved).subscribe(value => {


      this.getFaxBinTableData();
      // Swal.fire({ title: 'Success', html: 'Saved Successfully', icon: 'success', timer: 3000, timerProgressBar: true });
      this.toastr.success('Saved Successfully','Success')
    }, err => {
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    })
    this.subscription$.push(assignedUserBatch);
  }

  deleteFileById(value: { id: string }) {

    Swal.fire({
      title: 'Are you sure you want to Delete?',
      text: 'You won\'t be able to retrieve this data!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#34c38f',
      cancelButtonColor: '#f46a6a',
      confirmButtonText: 'Yes, delete it!'
    }).then(result => {
      const deleteFile = value.id && this.faxBinService.deleteByDocumentId(value.id).subscribe(response => {
        this.getFaxBinTableData();

      }, err => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      });
      this.subscription$.push(deleteFile);
    });
  }

  previewDocumentById(value: { blobName: string }) {
    value && value?.blobName && window.open(baseUrl + '#/ViewDocumentPerviewByBlobId/' + value?.blobName + '/' + 1, "_blank");
  }

  downloadFile(value: { blobName: string }): void {
    if (value && value.blobName) {
      const downloadDocumentBlobName = this.documentService.getPatientDocumentByBlobIDByBlobName(value.blobName).subscribe(response => {
        const filelocation: String = String("data:application/pdf;base64," + response);
        const link = document.createElement("a");
        link.download = value.blobName;
        link.href = filelocation.toString();
        link.click();
      }, err => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      }, () => {
      })
      this.subscription$.push(downloadDocumentBlobName);
    }
    value && value?.blobName && window.open(baseUrl + '#/ViewDocumentPerviewByBlobId/' + value?.blobName + '/' + 1, "_blank");
  }

}
@Component({
  selector: 'app-document-Preview',
  template: `
  <mat-progress-spinner  *ngIf="isLoadSpinner">    </mat-progress-spinner>
  <ng-container *ngIf="strSelectedPdfPath"><ngx-extended-pdf-viewer
  [textLayer]="true" [showHandToolButton]="true"[src]="strSelectedPdfPath"
  [height]="'150vh'" [useBrowserLocale]="false"  [delayFirstView]='1000'>
  </ngx-extended-pdf-viewer></ng-container>`
  ,
})
export class DocumentPreviewComponent implements OnInit, OnDestroy {
  strSelectedPdfPath: string = '';
  strSelectedPdfPath64: string = '';
  claimId: string = '';
  addDocumentbinModalTitle: string = 'Document Preview';
  blobName: string = "";
  serviceName: number = 0;
  isLoadSpinner = true;

  subscription$: Subscription[] = [];
  constructor(
    private documentService: DocumentService,
    private activatedRoute: ActivatedRoute,
    private claimProcessService: ClaimProcessService,
    private zermidClearingHouseService: ZermidClearingHouseService,
    private uploadFilesService: UploadFilesService,
    private inboundDocumentService: InboundDocumentService,
    private pickupExchangeService: PickUpExchangeService

  ) { }

  ngOnDestroy(): void {
    this.subscription$?.forEach((sub) => {
      sub && sub?.unsubscribe();
    });
  }


  ngOnInit(): void {
    const activateRoute = this.activatedRoute.paramMap.subscribe((response) => {
      this.isLoadSpinner = true;

      this.blobName = response.get('blobName') ?? '';
      this.serviceName = Number(response.get('serviceName') || 0) ?? 0;
      this.claimId = response.get('sClaimNumber') ?? "";
    });
    this.subscription$.push(activateRoute);
    //
    this.strSelectedPdfPath = '';
    //  this.blobName = this.blobName?.replace(/(\!~!)/gi, ' ') ?? "";
    this.blobName && this.serviceName && this.getDetailsByBloabNameAndService();
  }

  getDetailsByBloabNameAndService() {
    this.strSelectedPdfPath = '';
    this.isLoadSpinner = true;
    switch (this.serviceName) {
      case 1:
        {
          const getDocument = this.documentService.getPatientDocumentByBlobIDByBlobName(this.blobName).subscribe(
            (response) => {
              if (typeof response === 'string' && response && response?.length !== 0) {
                this.strSelectedPdfPath = String('data:application/pdf;base64,' + response);
                this.isLoadSpinner = false;

              }
            }, err => {
              this.strSelectedPdfPath = '';
              this.isLoadSpinner = false;

              const data: HttpErrorResponse = err;
              Swal.fire({
                icon: 'info',
                text: data?.error?.error?.message,
              });
            });
          this.subscription$.push(getDocument);
        }
        break;
      case 2:
        {
          // this.blobName
          const getEobDocument = this.claimProcessService.getClaimEobDocumentsByClaimIdAndInsuranceType(this.claimId, InsuranceType.Primary).subscribe(response => {
            if (typeof response === 'string' && response && response !== null && response?.length !== 0) {
              this.strSelectedPdfPath = String('data:application/pdf;base64,' + (response));
              this.isLoadSpinner = false;

            }
          }, err => {
            this.strSelectedPdfPath = '';
            this.isLoadSpinner = false;

            const data: HttpErrorResponse = err;
            Swal.fire({
              icon: 'info',
              text: data?.error?.error?.message,
            });
          });
          this.subscription$.push(getEobDocument);
        }
        break;
      case 3:

        {
          const patientDocument = this.uploadFilesService.getPatientDocumentByBlobNameByBlobName(this.blobName).subscribe(response => {
            if (typeof response === 'string' && response && response !== null && response?.length !== 0) {
              this.strSelectedPdfPath = String('data:application/pdf;base64,' + response);
              this.isLoadSpinner = false;

            }
          }, err => {
            this.strSelectedPdfPath = '';
            this.isLoadSpinner = false;

            const data: HttpErrorResponse = err;
            Swal.fire({
              icon: 'info',
              text: data?.error?.error?.message,
            });
          });
          this.subscription$.push(patientDocument)
        }
        break;
      case 4:

        {
          this.zermidClearingHouseService.downloadEOBByBlobName(this.blobName).subscribe(response => {
            if (typeof response === 'string' && response && response !== null && response?.length !== 0) {
              this.strSelectedPdfPath = String('data:application/pdf;base64,' + response);
              this.isLoadSpinner = false;

            }
          }, err => {
            this.strSelectedPdfPath = '';
            this.isLoadSpinner = false;

            const data: HttpErrorResponse = err;
            Swal.fire({
              icon: 'info',
              text: data?.error?.error?.message,
            });
          });
        }
        break;
      case 5:
        {
          this.inboundDocumentService.downloadInboundDocumentByBlobName(this.blobName).subscribe(response => {
            if (typeof response === 'string' && response && response !== null && response?.length !== 0) {
              this.strSelectedPdfPath = String('data:application/pdf;base64,' + response);
              this.isLoadSpinner = false;
            }
          }, err => {
            this.strSelectedPdfPath = '';
            this.isLoadSpinner = false;

            const data: HttpErrorResponse = err;
            Swal.fire({
              icon: 'info',
              text: data?.error?.error?.message,
            });
          });
        }
        break;
      case 6:
          {
            this.pickupExchangeService.downloadPickUpDoc(this.blobName).subscribe(
              (response) => {
                
                if (
                  typeof response === 'string' &&
                  response &&
                  response !== null &&
                  response?.length !== 0
                ) {
                  this.strSelectedPdfPath = String(
                    'data:application/pdf;base64,' + response
                  );
                  this.isLoadSpinner = false;
                }
              },
              (err) => {
                this.strSelectedPdfPath = '';
                this.isLoadSpinner = false;
  
                const data: HttpErrorResponse = err;
                Swal.fire({
                  icon: 'info',
                  text: data?.error?.error?.message,
                });
              }
            );
          }
          break;
      default:
        this.isLoadSpinner = false;
        break;
    }

  }
}
export function dateFormatter(documentDate: string | Date): string {
  let resulteddocumentDate: string = "";
  if (typeof documentDate === "string") {
    const date = `${(new Date(documentDate))?.toLocaleDateString("en-US")}`;
    const hours = `${(new Date(documentDate))?.getHours()}`;
    const time = `${(new Date(documentDate))?.getMinutes()}`;
    resulteddocumentDate = date + "  " + hours + ":" + time;
  }
  return resulteddocumentDate;
}
