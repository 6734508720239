<div class="container-fluid">
      <!-- Headers -->
      <div class="row">
        <div class="col-md-5 d-inline-flex align-items-center">
          <h3 class="mb-1 customThemeClass"><b>Master / Size</b></h3>
        </div>
        <div class="col-md-7">
          <div class="d-flex flex-row-reverse" style="margin-right: 8%;">
            <mat-radio-group [(ngModel)]="selectedOption" (change)="loadSizeList()">
              <mat-radio-button [value]="0" class="active"><b>All</b></mat-radio-button>
              <mat-radio-button [value]="1" class="active p-2" [checked]="true"><b>Active</b></mat-radio-button>
              <mat-radio-button [value]="2" class="active"><b>Inactive</b></mat-radio-button>
            </mat-radio-group>
          </div>
        </div>
      </div>
  <div class="row">
    <div class="col-12">
        <!-- <div class="card card-body"> -->
            <div class="col-lg-12 pb-2 mt-2">
                <mat-accordion>
                  <mat-expansion-panel
                    [expanded]="step"
                    (afterExpand)="toggle()"
                    (closed)="toggleV2()"
                  >
                    <mat-expansion-panel-header>
                      <mat-panel-title class="customThemeClass">
                        <ng-container *ngIf="SizeId === '' else elseTitleTemplate">
                          <b class="pt-2 small-text">Add Size </b> </ng-container
                        ><br /><br />
                        <ng-template #elseTitleTemplate>
                          <b class="small-text">Edit Size </b>
                        </ng-template>
                      </mat-panel-title>
                    </mat-expansion-panel-header>
                    <mat-card-content>
                      <form [formGroup]="sizeForm">
                        <div class="row pb-2">
                          <!-- Size  Name -->
                          <mat-form-field class="col-4 dd-text">
                            <mat-label
                              >Size Name
                              <span class="asterisk">*</span></mat-label
                            >
                            <input
                              autocomplete="new-txtSize"
                              matInput
                              formControlName="txtSize"
                              placeholder="Enter Size Name"
                              type="text"
                              maxlength="40"
                            />
                            <mat-error
                              *ngIf="
                              sizeForm?.get('txtSize')?.touched &&
                              sizeForm?.get('txtSize')?.errors?.required
                              "
                            >
                            Size Name is a required field!
                            </mat-error>
                            <mat-error
                              *ngIf="
                              sizeForm?.get('txtSize')?.touched &&
                              sizeForm?.get('txtSize')?.errors
                                  ?.sAlphabets
                              "
                            >
                              Enter only Alphabets!
                            </mat-error>
                          </mat-form-field>




<!-- && sizeForm?.dirty -->
                        <!-- Buttons -->
                        <div class="col-6">
                          <div class="d-flex">
                            <button
                              mat-button
                              (click)="saveSizedetails()"
                              class="buttonColor mb-1 mr-2"
                              [disabled]="
                      !(
                        !sizeForm?.invalid
                      ) || saveButtonHide
                    "
                            >
                              Save
                            </button>
                            <button
                              mat-button
                              (click)="resetForm()"
                              class="resetclr buttonColor mb-1 mr-2"
                            >
                              Reset
                            </button>
                          </div>
                        </div>
                    </div>
                      </form>
                    </mat-card-content>
                  </mat-expansion-panel>
                </mat-accordion>
              </div>
        <!-- </div> -->
    </div>
  </div>

  <div class="col-lg-12 mt-2">


    <div class="card card-body p-1 px-0 py-2">
        <div class="col-12 content table-responsive">
          <div class="d-flex justify-content-center" *ngIf="!isLoadData">
            <img
              class="img-responsive pb-2 mr-1"
              src="assets/gifs/Infinity-loader.gif"
            />
          </div>
          <div class="table table-responsive" *ngIf="isLoadData">

            <app-size-table-list [sizeList]="sizeList" ></app-size-table-list>
          </div>
        </div>
    </div>
  </div>

</div>
