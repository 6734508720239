import { DatePipe } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatOption } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSelect } from '@angular/material/select';
import { MatSort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { Title } from '@angular/platform-browser';
import moment, { Moment } from 'moment';
import { LocaleConfig, LocaleService } from 'ngx-daterangepicker-material';
import { GetRoleListUsingTenantIdDto } from 'projects/admin/src/app/admin-proxy/platform-app-management/rcm/platform-management/role/models';
import { RoleService } from 'projects/admin/src/app/admin-proxy/platform-app-management/rcm/platform-management/role/role.service';
import { IdentityUserUsingTenantIdDto } from 'projects/admin/src/app/admin-proxy/platform-app-management/rcm/platform-management/user/models';
import { UserService } from 'projects/admin/src/app/admin-proxy/platform-app-management/rcm/platform-management/user/user.service';
import { CalendarService } from 'projects/on-time-schedules/src/app/onTime-proxy/rcm/on-time-management/on-time-schedule/calendar.service';
import { SearchCalendarDTO } from 'projects/on-time-schedules/src/app/onTime-proxy/rcm/on-time-management/on-time-schedule/dto/models';
import { TableService } from 'projects/shared/src/app/table.service';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { CalendarViewComponent } from '../calendar-view/calendar-view.component';

@Component({
  selector: 'app-calendar-list',
  templateUrl: './calendar-list.component.html',
  styleUrls: ['./calendar-list.component.scss'],
  providers:[DatePipe,LocaleService]
})
export class CalendarListComponent implements OnInit {
  clientId:string = '';
  roleName: string;
  userName: string = '';
  isLoading: boolean = false;
  calendarListTableData: any[] = [];
  tableColumns: string[] = [];
  dataSource: MatTableDataSource<any> = new MatTableDataSource<any>([]);
  calendarSearchForm: FormGroup;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatTable, { static: false }) tableRef: MatTable<any>;
  @ViewChild('allUsersSelected') private allUsersSelected: MatOption;
  @ViewChild('userSelect') userSelect: MatSelect;
  @ViewChild('allRolesSelected') private allRolesSelected: MatOption;
  @ViewChild('roleSelect') roleSelect: MatSelect;
  public filterRoles : Observable<any[]> | undefined;
  public filterUsers : Observable<any[]> | undefined;
  lstRoles : any[] = [];
  lstUsers : any[] = [];
  selectedRange: {startDate: Moment, endDate: Moment};
  ranges: any;
  minDate: Moment;
  maxDate: Moment;
  calendarLocale: LocaleConfig;
  calendarPlaceholder: string = '';
  constructor(
    private title: Title,
    private formBuilder: FormBuilder,
    private roleService: RoleService,
    private tableService: TableService,
    private userService: UserService,
    private calendarService: CalendarService,
    private datepipe: DatePipe,
    public dialog: MatDialog
  ) {
    this.calendarLocale = {
      customRangeLabel: 'Pick a date...',
      applyLabel: 'Apply',
      clearLabel: 'Clear',
      format: 'DD/MM/YYYY',
      daysOfWeek: ['su', 'mo', 'tu', 'we', 'th', 'fr', 'sa'],
      monthNames: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
      firstDay: 1
    };
    this.ranges = {
      'Today': [moment(), moment()],
      'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
      'Current week': [moment().startOf('isoWeek'), moment().endOf('isoWeek')],
      'Next weekend': [this.getNextSaturday(), this.getNextSunday()],
      'Next 3 days': [moment().add(1, 'days'), moment().add(3, 'days')],
      'Last 7 Days': [moment().subtract(6, 'days'), moment()],
      'Last 30 Days': [moment().subtract(29, 'days'), moment()],
     // 'Current day': [moment(), moment()],
     // 'Next 2 days': [moment().add(1, 'days'), moment().add(2, 'days')],
      'This Month': [moment().startOf('month'), moment().endOf('month')],
      'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
    };
   }

  ngOnInit(): void {
    this.title.setTitle("Qsecure | Calendar Lists");
    this.tableColumns = [ 'options', 'calendarCode', 'userName', 'title', 'scheduledDate', 'scheduledTime', 'status','createdDate',];
    const today = new Date();
    this.calendarSearchForm = this.formBuilder.group({
      txtRoleName: new FormControl(""),
      txtUserName:new FormControl(""),
      txtRoleNameSearch: new FormControl(""),
      txtUserNameSearch: new FormControl(""),
      txtFromDate: new FormControl(""),
      txtToDate: new FormControl("")
    });

    this.tableService.getclientLoginView().subscribe(val => {  this.clientId = val?.tenantId;})
    this.loadRoles();
    this.loadUsers();
  }

  loadRoles(){
    let roleList: GetRoleListUsingTenantIdDto = {
      tenantId: this.clientId
    }
    this.roleService.getRoleList(roleList).subscribe(response => {
      this.lstRoles = [];
      response && response?.forEach(element => {
        this.lstRoles.push({
          name: element?.Name,
          id: element?.Id,
          concurrencyStamp: element?.ConcurrencyStamp
        });
      });
      this.filterRoles = this.calendarSearchForm?.get("txtRoleNameSearch")?.valueChanges
      .pipe(startWith(''),
      map(value => this.lstRoles.filter(option => option?.name?.toLowerCase()?.includes(value?.toLowerCase() ?? ""))));
    }, err => {
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    })
  }

  loadUsers(){
    let identityUser: IdentityUserUsingTenantIdDto = {
      tenantId: this.clientId
    }
    this.userService.getUserList(identityUser).subscribe(response => {
      this.lstUsers = [];
      response && response?.forEach(element => {
        this.lstUsers.push({
          name: element?.userName,
          email: element?.email,
          role: element?.roleName,
          id: element?.id,
        });
      });
      this.filterUsers = this.calendarSearchForm?.get("txtUserNameSearch")?.valueChanges
      .pipe(startWith(''),
      map(value => this.lstUsers.filter(option => option?.name?.toLowerCase()?.includes(value?.toLowerCase() ?? ""))));
    }, err => {
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    })
  }

  search(){
    this.isLoading = true;
    let userNames : any[] = [];
    let roleNames: any[] = [];
    this.calendarSearchForm.value.txtUserName !== "" &&
    this.calendarSearchForm.value.txtUserName !== undefined &&
    this.calendarSearchForm.value.txtUserName !== null &&
    [this.calendarSearchForm.value.txtUserName].length !== 0 &&
    [this.calendarSearchForm.value.txtUserName].forEach(val => {
      val?.forEach(element => {
        element !== undefined && element !== 0 && userNames.push(element);
      });
    });

    this.calendarSearchForm.value.txtRoleName !== "" &&
    this.calendarSearchForm.value.txtRoleName !== undefined &&
    this.calendarSearchForm.value.txtRoleName !== null &&
    [this.calendarSearchForm.value.txtRoleName].length !== 0 &&
    [this.calendarSearchForm.value.txtRoleName].forEach(val => {
      val?.forEach(element => {
        element !== undefined && element !== 0 && roleNames.push(element);
      });
    });


    let calendarSearch : SearchCalendarDTO = {
      roles : roleNames,
      users: userNames,
      fromDate: this.selectedRange == null || "" || undefined ? null : this.selectedRange.startDate.format('YYYY-MM-DD'),
      toDate: this.selectedRange == null || "" || undefined ? null : this.selectedRange.endDate.format('YYYY-MM-DD')
    }
    this.calendarService.searchCalendarByInput(calendarSearch).subscribe(response => {
      this.calendarListTableData = [];
      response && response?.items?.forEach((element, tableIndex) => {
        if (element) {
          let fromTime = element?.calendarDetails[(element?.calendarDetails.length - 1) | 0]?.fromTime == null || undefined || "" ? "" :
          this.datepipe.transform(element?.calendarDetails[(element?.calendarDetails.length - 1) | 0]?.fromTime, 'HH:mm a');

          let toTime = element?.calendarDetails[(element?.calendarDetails.length - 1) | 0]?.toTime == null || undefined || "" ? "" :
          this.datepipe.transform(element?.calendarDetails[(element?.calendarDetails.length - 1) | 0]?.toTime, 'HH:mm a');

          let scheduledDate = element?.calendarDetails[(element?.calendarDetails.length - 1) | 0]?.fromTime == null || undefined || "" ? "" :
          this.datepipe.transform(element?.calendarDetails[(element?.calendarDetails.length - 1) | 0]?.fromTime, 'MMMM d, y');

          let createdDate = element?.calendarDetails[(element?.calendarDetails.length - 1) | 0]?.createdDate == null || undefined || "" ? "" :
          this.datepipe.transform(element?.calendarDetails[(element?.calendarDetails.length - 1) | 0]?.fromTime, 'MM/dd/yyyy');

          this.calendarListTableData.push({
            sno: tableIndex + 1,
            calendarCode:element?.calendarDetails[(element?.calendarDetails.length - 1) | 0]?.calendarCode,
            userName: element?.assignedToName,
            title: element?.calendarDetails[(element?.calendarDetails.length - 1) | 0]?.title,
            scheduledDate: scheduledDate,
            scheduledTime: fromTime + " - " + toTime,
            status: element?.calendarDetails[(element?.calendarDetails.length - 1) | 0]?.status,
            createdDate: createdDate,
            //element?.creationTime === null ? "" : this.datepipe.transform(element?.creationTime, 'MM/dd/yyyy') ?? "",
            id: element?.id
          });
        }
      })
      this.isLoading = false;
      this.dataSource = new MatTableDataSource(this.calendarListTableData);
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
    });
  }

  getNextSaturday() {
    const dayINeed = 6; // for Saturday
    const today = moment().isoWeekday();
    if (today <= dayINeed) {
      return moment().isoWeekday(dayINeed);
    } else {
      return moment().add(1, 'weeks').isoWeekday(dayINeed);
    }
  }

  getNextSunday() {
    const dayINeed = 7; // for Sunday
    const today = moment().isoWeekday();
    if (today <= dayINeed) {
      return moment().isoWeekday(dayINeed);
    } else {
      return moment().add(1, 'weeks').isoWeekday(dayINeed);
    }
  }

  viewCalendar(id: string){
    const dialogRef = this.dialog.open(CalendarViewComponent, {
      disableClose: true,
      // height: "80%",
       width:"80%",
      // maxHeight: '200px',
      // maxWidth: '600px',
      data: { calendarId: id }
    });
    dialogRef.afterClosed().subscribe(() => {
    });
  }

  reset(){
    this.calendarSearchForm.reset();
    this.search();
  }

  clearRoleName(){
    this.roleName = '';
    this.calendarSearchForm.patchValue({ txtRoleName: '' });
  }
  clearUserName(){
    this.userName = '';
    this.calendarSearchForm.patchValue({ txtUserName: '' });
  }

  toggleAllUsersSelection() {

    if (this.allUsersSelected.selected) {
      this.userSelect.options.forEach((item: MatOption) => item.select());
    }
    else {
      this.userSelect.options.forEach((item: MatOption) => item.deselect());
    }
  }

  toggleAllRolesSelection() {
    if (this.allRolesSelected.selected) {
      this.roleSelect.options.forEach((item: MatOption) => item.select());


    }
    else {
      this.roleSelect.options.forEach((item: MatOption) => item.deselect());
    }
  }

}
