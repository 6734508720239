import { mapEnumToOptions } from '@abp/ng.core';

export enum wipStatus {
  all = 'all',
  yetToStart = 'yetToStart',
  hold = 'hold',
  completed = 'completed',
  reAssigned='reAssigned',
  NewSetupAssigned = 'NewSetupAssigned',
  NewSetupPending = 'NewSetupPending',
  NewSetupInprogress = 'NewSetupPending',
  NewSetupCompleted = 'NewSetupCompleted',
  VerificationAssigned = 'VerificationAssigned',
  VerificationPending = 'VerificationPending',
  VerificationInprogress = 'VerificationInprogress',
  VerificationCompleted = 'VerificationCompleted',
  AuthorizationAssigned = 'AuthorizationAssigned',
  AuthorizationPending = 'AuthorizationPending',
  AuthorizationInprogress = 'AuthorizationInprogress',
  AuthorizationCompleted = 'AuthorizationCompleted'
}

export const wipStatusOptions = mapEnumToOptions(wipStatus);
