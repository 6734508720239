<div class="container-fluid">
  <div class="row">
    <div class="col-12">
      <div class="d-flex align-items-center justify-content-between">
        <h3 class="font-weight-bolder mb-0">
          <b class="customThemeClass page-header-breadcrumb">My Queue</b>
        </h3>

        <div class="col-6 page-title-box page-title-right pr-0">
          <div class="col-12 d-flex justify-content-end align-items-center pr-0">
            <mat-label class="pr-1 mt-1 bolder">Search:</mat-label>
            <input matInput type="text" class="col-4 mt-1 custom-search-input disabled" autocomplete="off"
              placeholder="Patient Name/Chart No/Insurance" readonly/>

            <button mat-button class="buttonColor mt-1 mx-2 disabled" disabled>
              <mat-icon>filter_alt</mat-icon>
              Filter
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Wizard Design -->
  <div class="row">
    <div class="col-12 d-flex justify-content-center align-items-center mt-1">
      <!-- Wizard Design -->
      <div class="col-8">
        <div class="wizard">
          <ul class="nav nav-tabs justify-content-center" id="myTab" role="tablist">
            <li class="nav-item flex-fill" role="presentation" data-bs-toggle="tooltip" data-bs-placement="top">
              <a class="nav-link rounded-circle mx-auto d-flex align-items-center justify-content-center cursor-pointer"
                id="step1-tab" data-bs-toggle="tab" (click)="selectWizard(myQueueWizard.DOCUMENTBIN)"
                [ngClass]="getActiveTabClass(myQueueWizard.DOCUMENTBIN)" role="tab" aria-controls="step1"
                aria-selected="true" matTooltip="Patient Entry">
                <img class="img-responsive" src="assets/Opiton Icon/Patient/DocumentBin.png" alt="" height="25" />
              </a>
              <p class="bolder mb-0 text-center">Patient Entry ({{patientEntryTabCount}})</p>
            </li>
            <li class="nav-item flex-fill" role="presentation" data-bs-toggle="tooltip" data-bs-placement="top">
              <a class="nav-link rounded-circle mx-auto d-flex align-items-center justify-content-center cursor-pointer"
                id="step2-tab" data-bs-toggle="tab" (click)="selectWizard(myQueueWizard.ORDERS)"
                [ngClass]="getActiveTabClass(myQueueWizard.ORDERS)" role="tab" aria-controls="step2"
                aria-selected="false" matTooltip="Orders">
                <img class="img-responsive" src="assets/Opiton Icon/Patient/Orders_Queue.png" alt="" height="25" />
              </a>
              <p class="bolder mb-0 text-center">Orders ({{orderTabCount}})</p>
            </li>
            <li class="nav-item flex-fill" role="presentation" data-bs-toggle="tooltip" data-bs-placement="top">
              <a class="nav-link rounded-circle mx-auto d-flex align-items-center cursor-pointer justify-content-center"
                id="step3-tab" data-bs-toggle="tab" role="tab" (click)="selectWizard(myQueueWizard.CLAIMS)"
                [ngClass]="getActiveTabClass(myQueueWizard.CLAIMS)" aria-controls="step3" aria-selected="false"
                matTooltip="Claims">
                <img class="img-responsive" src="assets/Opiton Icon/Patient/Claims.png" alt="" height="25" />
              </a>
              <p class="bolder mb-0 text-center">Claims ({{claimTabCount}})</p>
            </li>
            <li class="nav-item flex-fill" role="presentation" data-bs-toggle="tooltip" data-bs-placement="top">
              <a class="nav-link rounded-circle mx-auto d-flex align-items-center justify-content-center cursor-pointer"
                id="step4-tab" data-bs-toggle="tab" role="tab" (click)="selectWizard(myQueueWizard.INVOICE)"
                [ngClass]="getActiveTabClass(myQueueWizard.INVOICE)" aria-controls="step4"
                aria-selected="false" matTooltip="Invoice">
                <img class="img-responsive" src="assets/Opiton Icon/Patient/invoice1.png" alt="" height="25" />
              </a>
              <p class="bolder mb-0 text-center">Invoice ({{invoiceTabCount}})</p>
            </li>
            <li class="nav-item flex-fill" role="presentation" data-bs-toggle="tooltip" data-bs-placement="top">
              <a class="nav-link rounded-circle mx-auto d-flex align-items-center justify-content-center cursor-pointer"
                id="step4-tab" data-bs-toggle="tab" role="tab" (click)="selectWizard(myQueueWizard.DENIALMANAGEMENT)"
                [ngClass]="getActiveTabClass(myQueueWizard.DENIALMANAGEMENT)" aria-controls="step4"
                aria-selected="false" matTooltip="Denial Management">
                <img class="img-responsive" src="assets/Opiton Icon/Patient/Tick.png" alt="" height="25" />
              </a>
              <p class="bolder mb-0 text-center">Denial Management ({{denailTabCount}})</p>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
  <mat-divider class="my-3"></mat-divider>
  <!-- Table Content -->
  <!-- <div class="card card-body">

 </div> -->

  <app-document-bin-queue *ngIf="activeTab == myQueueWizard.DOCUMENTBIN" (patientEntryCount)="getPatiententryCount($event)"></app-document-bin-queue>

  <app-order-bin-queue *ngIf="activeTab == myQueueWizard.ORDERS"></app-order-bin-queue>

  <app-claim-bin-queue *ngIf="activeTab == myQueueWizard.CLAIMS"></app-claim-bin-queue>

  <app-invoice-bin-queue *ngIf="activeTab == myQueueWizard.INVOICE"></app-invoice-bin-queue>

 <app-denial-bin-queue *ngIf="activeTab == myQueueWizard.DENIALMANAGEMENT" (deineialCount)="getDeineialCount($event)"></app-denial-bin-queue>

</div>
