<!-- <div class="modal-header p-0">
  <h1 class="modal-title customThemeClass">Document Check List</h1>
  <button class="text-right buttonColor" (click)="onCloseClick()" >
    Skip
  </button>
  <button class="text-right close" aria-label="Close" (click)="onCloseClick()" [disabled]="closeBtnDisabled">
    <mat-icon >close</mat-icon>
  </button>
</div> -->
<div class="row align-items-center">
  <div class="col-lg-5">
    <h1 class="modal-title customThemeClass">Document Check List</h1>
  </div>
  <!-- <div class="col-lg-3" style="text-align: end" >
  <button  class="buttonColor" (click)="onCloseClick()" >
    Skip
  </button>
  </div> -->
  
  <div class="col-lg-7" style="text-align: right">
    <button  class="buttonColor dd-text p-2" mat-button (click)="skipDocChecklist()" [hidden]="isPlanSaveV1 !== true && isNewSetupV1 !== true" [disabled]="closeBtnDisabled">
      <i class="fa fa-fast-forward mr-1"></i> Skip
    </button>
    &nbsp; &nbsp;

    <button mat-button class="buttonColor dd-text p-2 mr-2" id="viewDocBtn" (click)="viewFaxDocument()" [disabled]="isEmpty(data?.documentId)">
      <img src="assets/images/NewSetup/GroupV1.png" alt=""class="sea-green-icon-ins mr-1">  View Document
    </button>
    &nbsp; &nbsp;
    <button class="text-right close" style="margin-top:5px;" aria-label="Close" (click)="onCloseClick()" [disabled]="closeBtnDisabled">
      <mat-icon >close</mat-icon>
    </button>
  </div>
</div>
<mat-dialog-content class="mat-typography">
  <div class="col-12 content table-responsive pt-2">
    <div class="row p-2">
      <div class="col-4">
        <h2 class="font-weight-bolder">Fax ID : <span class="customThemeClass font-weight-bolder">{{faxId || '--'}}</span></h2>
      </div>
      <div class="col-4">
        <h2 class="font-weight-bolder">Patient Name : <span class="customThemeClass font-weight-bolder">{{patientName || '--'}}</span></h2>
      </div>
      <div class="col-4">
        <h2 class="font-weight-bolder">Chart No : <span class="customThemeClass font-weight-bolder">{{chartID || '--'}}</span></h2>
      </div>
    </div>
    <div class="d-flex justify-content-center" *ngIf="!loadTable">
      <img class="img-responsive pb-2 mr-1" src="assets/gifs/Infinity-loader.gif" />
    </div>
    <table id="tblDocCheckListModel" *ngIf="loadTable" datatable [dtOptions]="dtInboundOptions"
      class="tbl hover row-border hover w-100">
      <thead>
        <tr>
          <th [matTooltip]="'Description'">Description</th>
          <th style="max-width: 0px;"[matTooltip]="'Optional'">Optional</th>
          <th style="max-width: 0px;"[matTooltip]="'Vaild'">Vaild</th>
          <th style="max-width: 18px;" [matTooltip]="'Type'">Type</th>
          <th [matTooltip]="'Pressure Settings'">Pressure Settings</th>
          <th style="max-width: 13px;"[matTooltip]="'Date'">Date</th>
          <th [matTooltip]="'Code'">Code</th>
          <th [matTooltip]="'Doctor Print Name'">Doctor Print Name</th>
          <th [matTooltip]="'Signature'">Signature</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let data of inboundTableData" (click)="selectRow(data)">
          <td title="{{data.description}}">{{ data.description }}</td>

          <td [ngClass]="{ 'highligh-row': data.isOptionalRequired == 0}">
            <mat-checkbox color="primary" [(ngModel)]="data.optinal" [disabled]="data.isOptionalRequired === 0 || isCheckUpdate"
              (change)="onCheckboxChangeV1(data,DocumentCheckListRows.Optional)"> </mat-checkbox>
          </td>

          <td class="isvalidColumn"[ngClass]="{ 'highligh-row': data.isValidRequired == 0}">
            <mat-checkbox [(ngModel)]="data.valid" color="primary" [disabled]="isCheckUpdate"
              (change)="onCheckboxChangeV1(data,DocumentCheckListRows.Vaild)">
            </mat-checkbox>

          </td>

          <!-- <td style="max-width: 18px;" [ngClass]="{ 'highligh-row': data.isTypeRequired == 0}">
            <mat-select *ngIf="data.description!=='Sleep Study 1'&& data.description!=='Sleep Study 2'" [(ngModel)]="data.selectedOption" (ngModelChange)="onDropdownSelect($event, data)"
              class="dd-text" placeholder="Select Type" [disabled]="isCheckUpdate">
              <mat-option>
                <ngx-mat-select-search [formControl]="TypeControl" placeholderLabel="Search"
                    noEntriesFoundLabel="No Matches found"
                   ></ngx-mat-select-search>
            </mat-option>
              <mat-option *ngFor="let products of productDtails" [value]="products">
                {{ products.categoryName }}
              </mat-option>
            </mat-select>
            <mat-select *ngIf="data.description==='Sleep Study 1'|| data.description==='Sleep Study 2'" [(ngModel)]="data.selectedOption" (ngModelChange)="onDropdownSelect($event, data)"
            class="dd-text" placeholder="Select Type" [disabled]="isCheckUpdate">
            <mat-option>
              <ngx-mat-select-search [formControl]="TypeControlV1" placeholderLabel="Search"
                  noEntriesFoundLabel="No Matches found"
                 ></ngx-mat-select-search>
          </mat-option>
            <mat-option *ngFor="let products of productDtailsV1" [value]="products">
              {{ products.categoryName }}
            </mat-option>
          </mat-select>
          </td> -->
          <td style="max-width: 18px;" [ngClass]="{ 'highligh-row': data.isTypeRequired == 0}">
            <div class="select-container">
              <mat-select [(ngModel)]="data.selectedOption" (ngModelChange)="onDropdownSelect($event, data)"
                class="dd-text" placeholder="Select Type" [disabled]="isCheckUpdate">
                <mat-option>
                  <ngx-mat-select-search [formControl]="data.description === 'Sleep Study 1' || data.description === 'Sleep Study 2' ? TypeControlV1 : TypeControl" placeholderLabel="Search"
                      noEntriesFoundLabel="No Matches found">
                  </ngx-mat-select-search>
                </mat-option>
                <mat-option *ngFor="let products of (data.description === 'Sleep Study 1' || data.description === 'Sleep Study 2' ? productDtailsV1 : productDtails)" [value]="products">
                  {{ products.categoryName }}
                </mat-option>
              </mat-select>
                <mat-icon *ngIf="data.selectedOption" style="font-size: 15px !important" (click)="clearSelection($event,data)" class="clear-icon mt-1">close</mat-icon>
            </div>
          </td>


          <td [ngClass]="{ 'highligh-row': data.isPressureSettingsRequired == 0 }">
            <input *ngIf="data.isPressureSettingsRequired != 0" type="text" class="textbox-border-class custom-width"
              (change)="onChange(data, DocumentCheckListRows.Pressure_Settings)"
              [disabled]="data.isPressureSettingsRequired == 0 || isCheckUpdate" maxlength="15" (keydown)="onKeyDown($event)"
              [(ngModel)]="data.pressureSettings" matInput [readonly]="isCheckUpdate"/>
          </td>
          <td [ngClass]="{ 'highligh-row': data.isDateRequired == 0 }">
            <!-- <input *ngIf="data.isDateRequired != 0" matInput  [matDatepicker]="picker"
              [(ngModel)]="data.date" name="date" (dateChange)="onChangeDate(data, $event)"
              [matDatepickerFilter]="isDateValidFilter" [disabled]="data.isDateRequired == 0" [max]="maxDate" maxlength="10"
              (keydown)="addSlashToDate($event)" class="textbox-border-class custom-width" placeholder="(MM/DD/YYYY)"
              /><i class="fa fa-calendar" (click)="picker.open()"></i>


            <mat-datepicker #picker></mat-datepicker> -->
            <!-- <ng-container *ngIf="data.isDateRequired != 0">
              <input  autocomplete="off" matInput #dateInput [matDatepicker]="picker" [(ngModel)]="data.date" name="date" (dateChange)="onChangeDate(data, $event,dateInput.value)" [matDatepickerFilter]="isDateValidFilter"
               [disabled]="data.isDateRequired == 0 || isCheckUpdate" [max]="maxDate" [min]="data.description==='Prescription'? fiveYearAgo:agoOneYear" maxlength="10" (keydown)="addSlashToDate($event)" class="textbox-border-class custom-width datepicker" placeholder="(MM/DD/YYYY)" [readonly]="isCheckUpdate"/>
               <i class="fa fa-calendar" style="font-size: medium;" (click)="picker.open()"></i>
              <mat-datepicker #picker></mat-datepicker>
            </ng-container> -->

            <ng-container *ngIf="data.isDateRequired != 0">
              <input  autocomplete="off" matInput #dateInput [matDatepicker]="picker" [(ngModel)]="data.date" name="date" (dateChange)="onChangeDate(data, $event,dateInput.value)" [matDatepickerFilter]="isDateValidFilter"
               [disabled]="data.isDateRequired == 0 || isCheckUpdate" [max]="maxDate" [min]="data.description!=='Prescription'? agoOneYear:null" maxlength="10" (keydown)="addSlashToDate($event)" class="textbox-border-class custom-width datepicker" placeholder="(MM/DD/YYYY)" [readonly]="isCheckUpdate"/>
               <i class="fa fa-calendar" style="font-size: medium;" (click)="picker.open()"></i>
              <mat-datepicker #picker></mat-datepicker>
            </ng-container>
          </td>
          <td [ngClass]="{ 'highligh-row': data.isCodeRequired == 0}">
            <input *ngIf="data.isCodeRequired != 0" matInput class="textbox-border-class custom-width"
              (change)="onChange(data, DocumentCheckListRows.Code)" [disabled]="data.isCodeRequired === 0 || isCheckUpdate"
              [(ngModel)]="data.code" maxlength="10" [readonly]="isCheckUpdate"/>
          </td>

          <td [ngClass]="{ 'highligh-row': data.isDocPrintNameRequired == 0}">
            <mat-checkbox color="primary" [(ngModel)]="data.doctorPrintName" [disabled]="isCheckUpdate"
              (change)="onCheckboxChangeV1(data,DocumentCheckListRows.Doctor_Print_Name)"> </mat-checkbox>
          </td>

          <td [ngClass]="{ 'highligh-row': data.isSignatureRequired == 0}">
            <mat-checkbox color="primary" [(ngModel)]="data.signature" [disabled]="isCheckUpdate"
              (change)="onCheckboxChangeV1(data,DocumentCheckListRows.Signature)">
            </mat-checkbox>
          </td>

        </tr>
      </tbody>
    </table>

    <div class="card card-body pt-2">
      <form [formGroup]="docCheckList">
        <mat-form-field class="col-lg-12">
          <mat-label>Add Notes<span class="asterisk">*</span></mat-label>
          <textarea autocomplete="new-txtNotes"  rows="5" matInput maxlength="5000" formControlName="txtNotes" type="text" [readonly]="isCheckUpdate"></textarea>
        </mat-form-field>
      </form>

      <div class="col-sm-12">
        <div class="text-lg-center">
          <button mat-button class="buttonColor mb-2 mr-2" (click)="addNotes()" [disabled]="docCheckList?.invalid || isShowSpinner || isCheckUpdate">
            Add
            <mat-icon *ngIf="isShowSpinner">
              <mat-spinner class="spinner-border spinner-border-sm" diameter="20"></mat-spinner>
          </mat-icon>
          </button>
        </div>
      </div>
      <h3 class="modal-title customThemeClass">Document Check List Notes History </h3>
      <div *ngIf="isLoading" class="loader">Loading...</div>
      <div *ngIf="!isLoading" class="card card-body" [innerHTML]="savedNoteDetails"></div>
    </div>
  </div>
</mat-dialog-content>
