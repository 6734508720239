import { PagedAndSortedResultRequestDto } from '@abp/ng.core';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PverifyPayersDTO } from 'projects/patient/src/app/patient-proxy/patient/dto/models';
import { PverifyPayersService } from 'projects/patient/src/app/patient-proxy/patient/pverify-payers.service';
import { Observable, SubscribableOrPromise, Subscription } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { ProductAllowablesService } from '../item-proxy/item-management/items';
import { CreateUpdateProductAllowablesDTO } from '../item-proxy/item-management/items/dto/models';
import { ToastrService } from "ngx-toastr";
import { PayerDetailService } from 'projects/patient/src/app/patient-proxy/patient/payer-detail.service';
import { ProductAllowableDetailsService, ProductDetailsService } from '../item-proxy/item-management/optimization';
import { CreateUpdateProductAllowableDetailsDTO, PriceType } from '../item-proxy/item-management/optimization/dto';

@Component({
  selector: 'app-allowables-edit',
  templateUrl: './allowables-edit.component.html',
  styleUrls: ['./allowables-edit.component.scss']
})
export class AllowablesEditComponent implements OnInit , OnDestroy {
  insurance: string = '';
  product: string = ''
  allowablesForm: FormGroup;
  drpInsurance: PverifyPayersDTO[];
  subscription$: Subscription[] = [];
  allowablesId: string;
  drpProductName: any[] = [];
  filterMainProduct: any;
  public filteredInsuranceTypes: Observable<PverifyPayersDTO[]> | undefined;

  constructor(public dialogRef: MatDialogRef<AllowablesEditComponent>,
    private formBuilder: FormBuilder,
    private pverifyPayersService: PverifyPayersService,
    private allowablesService: ProductAllowablesService,
    private toastr: ToastrService,
    private payerDetails: PayerDetailService,
    private allowablesDetails: ProductAllowableDetailsService,
    private productDetailsService: ProductDetailsService,
    @Inject(MAT_DIALOG_DATA) public data: { id: string, ruleId: string, productName: string, productId: string }) { }

  ngOnInit(): void {
    this.allowablesForm = this.formBuilder.group({
      txtProductName: new FormControl("", [Validators.required]),
      txtInsuranceFilter: new FormControl(""),
      drpInsuranceName: new FormControl("", [Validators.required]),
      txtEligibleQty: new FormControl("", [Validators.required]),
      txtEligibleMonths: new FormControl("", [Validators.required]),
      txtAllowableAmount: new FormControl("", [Validators.required]),
      txtMainProductFilter: new FormControl(""),
      txtProductType:new FormControl("")
    });
    this.getInsuranceDropdown();

  }

  ngOnDestroy(): void {
    this.subscription$?.forEach((sub) => {
      sub && sub?.unsubscribe();
    });
  }

  //Get Insurance Dropdown
  getInsuranceDropdown() {

    this.allowablesForm.value.txtProductType = this.allowablesForm.value.txtProductType ? this.allowablesForm.value.txtProductType : 1
    if(this.allowablesForm.value.txtProductType == 1){
      const productName =  this.productDetailsService.getProductName().subscribe( res => {
        // this.drpProductName = res.map((x)=>{
        //   x.ProductTypes == 'MainProduct' ? x : []
        // })
        this.filterMainProduct = this.allowablesForm?.get("txtMainProductFilter").valueChanges
          .pipe(
            startWith(''),
            map(value => this.drpProductName.filter(option => option?.mainProductName?.toLowerCase()?.includes(value?.toLowerCase() ?? "")))
          );

      })
      this.subscription$.push(productName);
    } else if(this.allowablesForm.value.txtProductType == 2){
      const productName =  this.productDetailsService.getProductName().subscribe( res => {
        this.drpProductName = res.map((x)=>{
          //x.ProductTypes == 'ResupplyProduct ' ? x : []
        })
        this.filterMainProduct = this.allowablesForm?.get("txtMainProductFilter").valueChanges
          .pipe(
            startWith(''),
            map(value => this.drpProductName.filter(option => option?.mainProductName?.toLowerCase()?.includes(value?.toLowerCase() ?? "")))
          );

      })
      this.subscription$.push(productName);
    }
    const pVerifyPayer = this.payerDetails.getPayersByClearingHouse().subscribe(response => {
      this.drpInsurance = [];
      response?.forEach(element => {
        this.drpInsurance.push({
          id: element.id,
          payerName: element?.payerName
        });
      });

      // setTimeout(() => {
      this.data?.id != null && this.data?.id != undefined && this.data?.id != "" && this.getAllowablesData();
      // }, 1000);

      this.filteredInsuranceTypes = this.allowablesForm?.get("txtInsuranceFilter")?.valueChanges
        .pipe(
          startWith(''),
          map(value => this.drpInsurance.filter(option => option?.payerName?.toLowerCase()?.includes(value?.toLowerCase() ?? "")))
        );
    }, err => {
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    });
    this.subscription$.push(pVerifyPayer)
  }
  changeProduct(e){
    this.allowablesForm.value.txtProductType = this.allowablesForm.value.txtProductType ? this.allowablesForm.value.txtProductType : e
   this.getInsuranceDropdown()
  }
  //Get Allowables Data By Id
  getAllowablesData() {
   const getInsuranceRules = this.allowablesDetails.get(this.data?.id).subscribe(response => {
      let insuranceDetails = this.drpInsurance?.filter(value => {
        // return value && value?.id === response?.insuranceId
      })[0];
      this.allowablesForm.patchValue({
        txtProductName:  response?.productId ,
        drpInsuranceName: response.payerId,
        txtEligibleQty: response?.quantity,
        txtEligibleMonths: response?.eligibleMonths,
        txtAllowableAmount: response?.allowableAmount,
        txtProductType: response?.ProductTypes == 'MainProduct' ? 1 : 2
      });
    })
    this.subscription$.push(getInsuranceRules);
  }

  //Update Allowables Data
  saveAllowables() {

    this.data = this.data ? this.data : null
    const data: CreateUpdateProductAllowableDetailsDTO = {
      productId: this.allowablesForm.value.txtProductName,
      payerId: this.allowablesForm.value?.drpInsuranceName,
      quantity:  this.allowablesForm.value.txtEligibleQty,
      eligibleMonths: this.allowablesForm.value.txtEligibleMonths,
      priceType: PriceType.Purchase,
      allowableAmount: this.allowablesForm.value.txtAllowableAmount
      // productName: this.allowablesForm.value.txtProductName ,
      // insuranceValidities: [{
      //   // ruleId: this.data?.ruleId,
      //   insuranceId: this.allowablesForm.value.drpInsuranceName?.id,
      //   insuranceName: this.allowablesForm.value.drpInsuranceName?.payerName,
      //   eligibleQuantity: this.allowablesForm.value.txtEligibleQty,
      //   eligibleMonths: this.allowablesForm.value.txtEligibleMonths,

      // }]
    }
    if(this.data != null || this.data != undefined){

      this.allowablesDetails.update(this.data.id ,data).subscribe(response => {
      // Swal.fire({ title: 'Success', html: 'Saved Successfully', icon: 'success', timer: 3000, timerProgressBar: true });
      this.toastr.success('Saved Successfully','Success')
      this.dialogRef.close()
    }, err => {
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    });
  } else {
    this.allowablesDetails.create(data).subscribe(response => {

      // Swal.fire({ title: 'Success', html: 'Saved Successfully', icon: 'success', timer: 3000, timerProgressBar: true });
      this.toastr.success('Saved Successfully','Success')
      this.dialogRef.close()
    }, err => {
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    });
  }
  }

  //Clear Insurance Name
  clearInsurance() {
    this.insurance = '';
    this.allowablesForm.patchValue({ drpInsuranceName: "" });
  }
  clearMainProduct() {
    this.product = '';
    this.allowablesForm.patchValue({ txtProductName: "" });
  }
     // Only Numbers with Decimals
  keyPressNumbersDecimal(event: { which: any; keyCode: any; preventDefault: () => void; }) {
    var charCode = event?.which ? event?.which : event?.keyCode;
    if (charCode != 46 && charCode > 31 && (charCode < 48 || charCode > 57)) {
      event?.preventDefault();
      return false;
    }
    return true;
  }

  //Numbers only
  numberOnly(event): boolean {
    var charCode = (event.which) ? event.which : event.keyCode;
    // Only Numbers 0-9
    if ((charCode < 48 || charCode > 57)) {
      event.preventDefault();
      return false;
    } else {
      return true;
    }
  }

}
