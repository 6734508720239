import type { CreateUpdateCheckOutOrderCreateDTO } from './dto/models';
import { RestService } from '@abp/ng.core';
import { Injectable } from '@angular/core';
import type { ClaimDTO } from '../posting/dto/models';

@Injectable({
  providedIn: 'root',
})
export class CheckoutOrderService {
  apiName = 'billingManagement';

  createCheckOutOrder = (input: CreateUpdateCheckOutOrderCreateDTO) =>
    this.restService.request<any, ClaimDTO>({
      method: 'POST',
      url: '/api/billingApp/checkout-order/create-check-out-order',
      body: input,
    },
     { apiName: this.apiName, skipHandleError: true });

  getCheckOutOrder = (sCheckOutOrderId: string, claimId: string, sStatus: string) =>
    this.restService.request<any, boolean>({
      method: 'GET',
      url: '/api/billingApp/checkout-order/get-check-out-order',
      params: { sCheckOutOrderId, claimId, sStatus },
    },
     { apiName: this.apiName, skipHandleError: true });

  getCheckOutOrderPaymentStatus = () =>
    this.restService.request<any, void>({
      method: 'GET',
      url: '/api/billingApp/checkout-order/get-check-out-order-payment-status',
    },
     { apiName: this.apiName, skipHandleError: true });

  getPaymentCapture = (claimId: string) =>
    this.restService.request<any, ClaimDTO>({
      method: 'GET',
      url: `/api/billingApp/checkout-order/get-payment-capture/${claimId}`,
    },
     { apiName: this.apiName, skipHandleError: true });

  constructor(private restService: RestService) {}
}
