import type { DashboardCount, DashboardCountBasedOnDates, DashboardServicecountDTO, DashboardServicetenantCountDTO, patientCountDTO } from './models';
import { RestService } from '@abp/ng.core';
import { Injectable } from '@angular/core';
import type { UserProfileDTO } from '../user/models';

@Injectable({
  providedIn: 'root',
})
export class DashboardService {
  apiName = 'platformManagement';

  getCount = () =>
    this.restService.request<any, DashboardServicecountDTO>({
      method: 'GET',
      url: '/api/PlatformApp/dashboard/get-count',
    },
    { apiName: this.apiName });

  getCountByTenantByTenantId = (tenantId: string) =>
    this.restService.request<any, DashboardServicetenantCountDTO>({
      method: 'GET',
      url: `/api/PlatformApp/dashboard/get-count-by-tenant/${tenantId}`,
    },
    { apiName: this.apiName });

  getDashboardDetails = () =>
    this.restService.request<any, DashboardCount>({
      method: 'GET',
      url: '/api/PlatformApp/dashboard/get-dashboard-details',
    },
    { apiName: this.apiName });

  getDashboardDetailsBasedOnDatesByFromDateAndToDate = (fromDate: string, toDate: string) =>
    this.restService.request<any, DashboardCountBasedOnDates>({
      method: 'GET',
      url: '/api/PlatformApp/dashboard/get-dashboard-details-based-on-dates',
      params: { fromDate, toDate },
    },
    { apiName: this.apiName });

  getPatientCountByDtStartDateAndDtEndDate = (dtStartDate: string, dtEndDate: string) =>
    this.restService.request<any, patientCountDTO[]>({
      method: 'GET',
      url: '/api/PlatformApp/dashboard/get-patient-count',
      params: { dtStartDate, dtEndDate },
    },
    { apiName: this.apiName });

  getTenantBasedCountByTenantIdAndOrganizationUnitId = (tenantId: string, organizationUnitId: string) =>
    this.restService.request<any, DashboardServicecountDTO>({
      method: 'GET',
      url: '/api/PlatformApp/dashboard/get-tenant-based-count',
      params: { tenantId, organizationUnitId },
    },
    { apiName: this.apiName });

  userProfile = () =>
    this.restService.request<any, UserProfileDTO>({
      method: 'POST',
      url: '/api/PlatformApp/dashboard/user-profile',
    },
    { apiName: this.apiName });

  constructor(private restService: RestService) {}
}
