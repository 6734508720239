import { Component, Input, OnInit } from '@angular/core';
// import { defaultGuid } from 'projects/admin/src/app/document-perviewer/document-perviewer.component';
import { PatientSummaryDTO } from 'projects/patient/src/app/patient-proxy/patient-optimization/dto';
import { PatientPersonalService } from 'projects/patient/src/app/patient-proxy/patient-optimization/patient-personal.service';
import { defaultGuid } from 'projects/shared/src/app/enums/allenums.enum';

@Component({
  selector: 'app-order-summary',
  templateUrl: './order-summary.component.html',
  styleUrls: ['./order-summary.component.scss']
})
export class OrderSummaryComponent implements OnInit {
  @Input() patientId: string = defaultGuid;
  @Input() orderId: string = defaultGuid;
  patientStatus: string = "";
  lblPatientName: string = "";
  lblOrderStatus:string= ""
  @Input() lblPtCurrentStatus: string = "";
  lblPatientGender: string = "";
  lblDefaultPatientId: string = "";
  @Input() lblDefaultSaleOrderId: string = "";
  lblPatientDOB: string = "";
  lblPatientHeight: string = "";
  lblBilledAmount: string = "";
  lblReceivedAmount: string = "";
  lblPendingAmount: string = "";
  lblBillingStatus: string = "";
  lblthirtyDayPaidAmnt: string = "";
  lblsixtyDayPaidAmnt: string = "";
  lblninetyDayPaidAmnt: string = "";
  lblAboveNinetyDayPaidAmnt: string = "";
  constructor(private patientPersonalService: PatientPersonalService) { }


  ngOnInit(): void {
    (this.patientId ?? defaultGuid) !== defaultGuid && this.getPatientSummary();
  }

  //To get Patient Summary
  getPatientSummary() {
    let patientSummaryDto: PatientSummaryDTO;

    patientSummaryDto = {
      patientId: this.patientId,
      orderId :this.orderId,
      defaultOrderId:"",
      patientName: "",
      defaultPatientId: "",
      currentStatus: "",
      dob: "",
      billedAmount: "0",
      receivedAmount: "0",
      pendingAmount: "0",
      billingStatus: "0",
      thirtyDayBilledAmnt: "0",
      sixtyDayBilledAmnt: "0",
      ninetyDayBilledAmnt: "0",
      aboveNinetyDayBilledAmnt: "0",
      gender:""
    }

    const result = this.patientPersonalService.patientSummaryByPatientSummaryDto(patientSummaryDto).subscribe(response => {

      let patientSummary: PatientSummaryDTO = response;
      if (patientSummary != null) {
        this.lblDefaultPatientId = patientSummary?.defaultPatientId;
        this.lblPatientName = patientSummary?.patientName;
        this.lblPatientDOB = (patientSummary?.dob ?? "") === '' ? "" : new Date(patientSummary?.dob)?.toLocaleDateString("en-US");
        this.lblPtCurrentStatus = patientSummary?.currentStatus;
        this.lblBilledAmount = patientSummary?.billedAmount;
        this.lblReceivedAmount = patientSummary?.receivedAmount;
        this.lblPendingAmount = patientSummary?.pendingAmount;
        this.lblBillingStatus = patientSummary?.billingStatus;
        this.lblthirtyDayPaidAmnt = patientSummary?.thirtyDayBilledAmnt;
        this.lblsixtyDayPaidAmnt = patientSummary?.sixtyDayBilledAmnt;
        this.lblninetyDayPaidAmnt = patientSummary?.ninetyDayBilledAmnt;
        this.lblAboveNinetyDayPaidAmnt = patientSummary?.aboveNinetyDayBilledAmnt;
      }
      else { patientSummary = patientSummaryDto; }
    });

  }
}
