<mat-dialog-content class="mat-typography">
  <div>
    <mat-panel-title class="customThemeClass">
      <ng-container *ngIf="itemId === '00000000-0000-0000-0000-000000000000'; else elseTitleTemplate">
        <b>Add Item</b>
      </ng-container>
      <ng-template #elseTitleTemplate>
        <b>Edit Item</b>
      </ng-template>
    </mat-panel-title>

    <form [formGroup]="adminAddItem">
      <mat-form-field class="col-4">
        <mat-label>Item Id / HCPC Code<span class="asterisk">*</span></mat-label>
        <mat-select formControlName="txtItemId" (selectionChange)="onChange($event)" [(value)]="txtItem" #singleSelect>
          <mat-option>
            <ngx-mat-select-search placeholderLabel="Search" noEntriesFoundLabel="No Matching found"
              formControlName="txtSearchFilter"></ngx-mat-select-search>
          </mat-option>
          <mat-option *ngFor="let code of filteredItemTypes | async" [value]="code">
            {{code.productNames}}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="
                adminAddItem.get('txtItemId')?.touched &&
                adminAddItem.get('txtItemId')?.errors?.required
                ">
          Item Id is a required field!
        </mat-error>
        <button mat-button (click)="clearItemType()" (click)="txtItem='';$event.stopPropagation()" *ngIf="txtItem"
          matSuffix mat-icon-button >
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field>

      <mat-form-field class="col-4">
        <mat-label>Name<span class="asterisk">*</span></mat-label>
        <input autocomplete="new-txtItemName" matInput formControlName="txtItemName" type="text" readonly>
        <mat-error *ngIf="
                adminAddItem.get('txtItemName')?.touched &&
                adminAddItem.get('txtItemName')?.errors?.required
                ">
          Name is a required field!
        </mat-error>
      </mat-form-field>


      <mat-form-field class="col-4">
        <mat-label>Insurance Name<span class="asterisk">*</span></mat-label>
        <mat-select formControlName="drpInsurance">
          <mat-option *ngFor="let insurance of drpPlan" [value]="insurance.insuranceName">
            {{insurance.insuranceName}}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field class="col-4">
        <mat-label>Charge <span class="asterisk">*</span></mat-label>
        <span matPrefix>$ &nbsp;</span>
        <input autocomplete="new-txtItemName" matInput maxlength="40" formControlName="txtPrice" minlength="1" maxlength="15" type="text">
        <mat-error *ngIf="
                      adminAddItem.get('txtPrice')?.touched &&
                      adminAddItem.get('txtPrice')?.errors?.required
                      ">
          Charge is a required field!
        </mat-error>
        <mat-error *ngIf="adminAddItem.get('txtPrice')?.touched &&
                  adminAddItem.get('txtPrice')?.errors?.number">
          Enter only numbers!
        </mat-error>
        <mat-error *ngIf="
                adminAddItem.get('txtPrice')?.touched &&
                adminAddItem.get('txtPrice')?.errors">
          Enter Greater than 1!
        </mat-error>
        <mat-error *ngIf=" adminAddItem?.get('txtPrice')?.touched && adminAddItem?.get('txtPrice')?.errors?.msg">
          {{adminAddItem?.get('txtPrice')?.errors?.msg}}
        </mat-error>
      </mat-form-field>

      <mat-form-field class="col-4">
        <mat-label>Units <span class="asterisk">*</span></mat-label>
        <input autocomplete="new-txtItemName" matInput maxlength="40" min="1" formControlName="txtQty" maxlength="2" type="text">
        <mat-error *ngIf="adminAddItem.get('txtQty')?.touched &&
                      adminAddItem.get('txtQty')?.errors?.required">
          Units is a required field!
        </mat-error>
        <mat-error *ngIf="
                      adminAddItem.get('txtQty')?.touched &&
                      adminAddItem.get('txtQty')?.errors?.number
                      ">
          Enter only Numbers!
        </mat-error>
      </mat-form-field>


      <mat-form-field class="col-4">
        <mat-label>Original DOS <span class="asterisk">*</span></mat-label>
        <input autocomplete="new-txtItemName" matInput maxlength="40" placeholder="MM/DD/YYYY" formControlName="txtOriginalDOS"
          [matDatepicker]="myPicker2">
        <mat-datepicker-toggle matSuffix [for]="myPicker2"></mat-datepicker-toggle>
        <mat-datepicker #myPicker2></mat-datepicker>
        <mat-error *ngIf="adminAddItem.get('txtOriginalDOS')?.touched &&
                adminAddItem.get('txtOriginalDOS')?.errors">
          Enter Valid Date!
        </mat-error>
      </mat-form-field>
      <span class="row col-12 mt-3 ml-1" *ngIf="intTotalPrice!==0">
        Total Price : {{intTotalPrice |currency}}
      </span>
    </form>

  </div>


  <div class="col-12" class="text-align-center center">
    <div>
      <button mat-button class="buttonColor  mr-2" [hidden]="saveButtonHide"
        [disabled]="!(!adminAddItem.invalid && adminAddItem.dirty)" (click)="saveItem()"> Save
        <mat-icon *ngIf='isShowSpinner'>
          <mat-spinner class="spinner-border spinner-border-sm" diameter="20"> </mat-spinner>
        </mat-icon>
      </button>
      <button mat-button class="resetclr mb-2 mr-2" (click)="resetAddItemForm()">
        Reset
      </button>
    </div>
  </div>


  <div class="row mr-3">
    <div class="col-12">
      <div [hidden]="!isLoading"
        style="display: flex; justify-content: center; align-items: center; background: transparent">
        <mat-progress-spinner mode="indeterminate" value="66" diameter="45" class="lightseagreen">
        </mat-progress-spinner>
      </div>
      <table mat-table class="table ml-4" matSort [dataSource]="dataSource">
        <form style="display: flex" [formGroup]="adminAddItemSearchForm">
          <ng-container matColumnDef="sno">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>S.No</th>
            <td mat-cell *matCellDef="let data; let i = index">
              {{i+1}}
            </td>
          </ng-container>

          <ng-container matColumnDef="ItemName">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              Item Name
            </th>
            <td mat-cell *matCellDef="let data">
              <ng-container>
                {{data.itemName}}
              </ng-container>
            </td>
          </ng-container>



          <ng-container matColumnDef="Price">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              Price
            </th>
            <td mat-cell *matCellDef="let data">
              <ng-container>
                {{data?.price}}
              </ng-container>
            </td>
          </ng-container>

          <ng-container matColumnDef="Quantity">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              Units
            </th>
            <td mat-cell *matCellDef="let data">
              <ng-container>
                {{data?.Quantity}}
              </ng-container>
            </td>
          </ng-container>
          <ng-container matColumnDef="totalPrice">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              Total Price
            </th>
            <td mat-cell *matCellDef="let data">
              <ng-container>
                {{ data?.totalPrice |currency}}
              </ng-container>
            </td>
          </ng-container>
          <ng-container matColumnDef="OriginalDos">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              Original Dos
            </th>
            <td mat-cell *matCellDef="let data">
              <ng-container>
                {{ data?.dos | date: 'MM/dd/yyyy':'en_US'}}
              </ng-container>
            </td>
          </ng-container>

          <ng-container matColumnDef="Options">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Options</th>
            <td mat-cell *matCellDef="let data">
              <a class="editBtnColor" (click)="viewItem(data)">
                <i class="fa fa-pencil editBtnColor mr-3" matTooltip="Click To Edit"></i>
              </a>
              &nbsp;
            </td>
          </ng-container>
          <ng-container matColumnDef="noRecords">
            <td class="text-align-center" mat-footer-cell *matFooterCellDef colspan="7">
              No Records Found
            </td>
          </ng-container>
          <ng-container matColumnDef="subTotal">
            <td class="text-align-center" mat-footer-cell *matFooterCellDef="let data" colspan="7">
              Total Price : {{intOverAllTotalPrice |currency}}
            </td>

          </ng-container>
          <ng-container matColumnDef="paginator">
            <td mat-footer-cell *matFooterCellDef colspan="7">
              <mat-paginator class="table table-responsive mat-table-class" #MatPaginator
                [length]="dataSource?.data?.length" [pageSize]="10" [pageIndex]="0" showFirstLastButtons
                [pageSizeOptions]="[5, 10, 25, 100]">
              </mat-paginator>
            </td>
          </ng-container>
        </form>
        <tr mat-footer-row *matFooterRowDef="['noRecords']"
          [ngClass]="{ active: dataSource && dataSource?.data && dataSource?.data?.length !== 0 }">
        </tr>
        <tr mat-footer-row *matFooterRowDef="['subTotal']"
          [ngClass]="{ active: dataSource && dataSource?.data && dataSource?.data?.length === 0 }">
        </tr>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        <tr mat-footer-row *matFooterRowDef="['paginator']"></tr>
      </table>

    </div>
  </div>

</mat-dialog-content>
<mat-dialog-actions align="end">
  <button class="buttonCancelColor" mat-button mat-dialog-close>Close</button>
</mat-dialog-actions>
