import { RestService } from '@abp/ng.core';
import { Injectable } from '@angular/core';
import { TenantOfficialInformationDTO } from 'projects/admin/src/app/admin-proxy/platform-app-management/rcm/platform-management/tenants';

@Injectable({
  providedIn: 'root',
})
export class MMPayPalPaymentsService {
  apiName = 'orderManagement';

  createPayPalInvoiceByOrderID = (OrderID: string) =>
    this.restService.request<any, string>(
      {
        method: 'POST',
        responseType: 'text',
        url: '/api/orderApp/m-mPay-pal-payments/create-pay-pal-invoice',
        params: { orderID: OrderID },
      },
      { apiName: this.apiName }
    );

  generateInvoiceNumber = (sAccessToken: string) =>
    this.restService.request<any, string>(
      {
        method: 'GET',
        responseType: 'text',
        url: '/api/orderApp/m-mPay-pal-payments/generate-invoice-number-async-v2',
        params: { sAccessToken },
      },
      { apiName: this.apiName }
    );

  getAccessToken = () =>
    this.restService.request<any, string>(
      {
        method: 'GET',
        responseType: 'text',
        url: '/api/orderApp/m-mPay-pal-payments/get-access-token-async-v2',
      },
      { apiName: this.apiName }
    );

  getInvoice = (sInvoiceId: string, saleOrderId: string) =>
    this.restService.request<any, boolean>(
      {
        method: 'GET',
        url: '/api/orderApp/m-mPay-pal-payments/get-invoice',
        params: { sInvoiceId, saleOrderId },
      },
      { apiName: this.apiName }
    );

  validatePermissionAccessForMerchantPayPalAccountV2 = () =>
    this.restService.request<any, TenantOfficialInformationDTO>(
      {
        method: 'POST',
        url: '/api/orderApp/m-mPay-pal-payments/validate-permission-access-for-merchant-pay-pal-account-v2',
      },
      { apiName: this.apiName }
    );
  cancelSentInvoice = (sInvoiceId: string) =>
    this.restService.request<any, string>(
      {
        method: 'POST',
        responseType: 'text',
        url: `/api/orderApp/m-mPay-pal-payments/cancel-sent-invoice/${sInvoiceId}`,
      },
      { apiName: this.apiName }
    );

    sendPatientRespInvoiceByClaimId = (ClaimId: string) =>
      this.restService.request<any, string>({
        method: 'POST',
        responseType: 'text',
        url: `/api/orderApp/m-mPay-pal-payments/send-patient-resp-invoice/${ClaimId}`,
      },
      { apiName: this.apiName });

  constructor(private restService: RestService) {}
}
