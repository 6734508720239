// Created By Manikandan - 12 April 2024
import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Title } from '@angular/platform-browser';
import { DataTableDirective } from 'angular-datatables';
import { MasterProviderService } from 'projects/admin/src/app/warehouse-proxy/inventory/master-provider.service';
import { PatientMasterDropdownService } from 'projects/patient/src/app/dropdown-proxy/dropdown-management/dropdowns/master-dropdown-service';
import { defaultGuid } from 'projects/shared/src/app/enums/allenums.enum';
import { Subject, Subscription } from 'rxjs';
import { ClaimProcessingService } from '../../claim-proxy/claim-processing-management/rcm/billing-management/billing/claim-processing.service';
import { ClaimFormDetailsService } from '../../claim-proxy/claim-processing-management/rcm/billing-management/billing/claim-form-details.service';
import { DatePipe } from '@angular/common';
import { ClaimViewTabComponent } from '../../claim-view-tab/claim-view-tab.component';
import { MatSelectChange } from '@angular/material/select';
import { MatTableDataSource } from '@angular/material/table';
import { SelectionModel } from '@angular/cdk/collections';
import { ClaimListDTO } from '../../claim-proxy/claim-processing-management/rcm/billing-management/dto';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-claim-process-table',
  templateUrl: './claim-process-table.component.html',
  styleUrls: ['./claim-process-table.component.scss'],
})
export class ClaimProcessTableComponent implements OnInit {
  @Input() lstClaimProcessList: any[];
  selection = new SelectionModel<any>(true, []);
  dataSource: MatTableDataSource<any> = new MatTableDataSource<any>([]);
  @Output() countsUpdate = new EventEmitter();
  @Output() loadtable = new EventEmitter();
  claimProcessFilterForm: FormGroup;
  defaultGuid = defaultGuid;
  totalSelectedOrders: number = 0;
  selectAll: boolean = false;
  allCheckboxdisabled: boolean = false;
  // lstProviders: MasterProviderDTO[] = [];
  // lstPayorLevel: PayorLevelDTO[] = [];
  payorLevels: { value: string; key: string }[] = [];
  subscription$: Subscription[] = [];
  claimIdArray = [];
  selectedUserList: any;
  dtClaimTableOptions: any = {
    responsive: true,
    paging: false,
    dom: 'Bfrtip',
    language: {
      emptyTable: 'No records',
      info: 'Total : _MAX_ records',
      infoEmpty: 'Total : _MAX_ records',
      infoFiltered: '(filtered : _TOTAL_ records)',
      loadingRecords: 'Loading...',
      zeroRecords: 'No matching records',
    },

    scrollCollapse: true,
    scrollY: '400px',
    scrollX: true,
    order: [],
    search: {
      smart: false,
    },
    columnDefs: [
      { targets: [0], orderable: false }, // Hide the first column (index 0)
      { targets: [0, 1, 2, 3, -1], className: 'dt-fixed-column' },
    ],
    buttons: [
      {
        extend: 'excel',
        text: 'Export', // Change the text to 'Export'
        filename: 'Claim Process List',
        exportOptions: {
          columns: ':visible:not(:first-child) ',
          // columns: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
        },
      },
      {
        extend: 'colvis',
        columns: ':not(:eq(0), :eq(1), :eq(2),:eq(3), :eq(-1))', // Exclude first three and last columns from the column visibility toggle
      },
    ],
  };
  fromDate: string = '';
  toDate: string = '';
  isClaimTableLoaded: boolean = true;
  @ViewChild(DataTableDirective, { static: false })
  datatableElement: DataTableDirective;
  dtTrigger: Subject<any> = new Subject<any>();
  ids: any;
  lstClaimProcessListCount: any[];
  checkAll: boolean = false;
  lstcount: number;
  constructor(
    private fb: FormBuilder,
    private dialog: MatDialog,
    private masterProviderService: MasterProviderService,
    private patientDropdownService: PatientMasterDropdownService,
    private title: Title,
    private claimProcessingService: ClaimProcessingService,
    private claimFormDetailsService: ClaimFormDetailsService,
    private datePipe: DatePipe,
    private toastr: ToastrService
  ) {}

  ngOnInit(): void {
    // this.lstClaimProcessList=this.DataList;
    // this.selection=this.DataList;

    this.dtClaimTableOptions.buttons[0].filename =
      'Claim Process List' +
      '-' +
      this.datePipe.transform(new Date(), 'MM-dd-yyyy');

    this.dataSource = new MatTableDataSource(this.lstClaimProcessList);
    if (this.lstClaimProcessList) {
      this.lstcount = this.lstClaimProcessList.filter(
        (x) => x.claimId !== defaultGuid
      ).length;
      if (this.lstcount === 0) {
        this.allCheckboxdisabled = true;
      }
    }
  }

  ngAfterViewInit(): void {
    if (this.datatableElement && this.datatableElement.dtInstance) {
      this.refreshDataTable();
    } else {
      // If dtInstance is not available yet, wait for a short time and try again
      setTimeout(() => this.refreshDataTable(), 500);
    }
    // window.removeEventListener('resize', this.calculateScrollY);
  }

  refreshDataTable(): void {
    this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
      // Clear search input values
      $('input', dtInstance.table(0).footer()).val('');
      // Redraw the DataTable to load all records
      dtInstance.search('').columns().search('').draw();
      // Attach the event listener for filtering
      dtInstance.columns().every(function () {
        const that = this;
        $('input', this.footer()).on('keyup change', function () {
          if (that.search() !== this['value']) {
            that.search(this['value']).draw();
          }
        });
      });
    });
  }

  //To check the total selected count on checkbox change
  onCheckboxChange(event: any, claimId) {
    //To get the selected count and show in the buttons
    if (event.checked) {
      this.totalSelectedOrders++;
      this.claimIdArray.push(claimId);
      this.lstClaimProcessListCount = this.lstClaimProcessList.filter(
        (x) => x.claimId !== defaultGuid
      );
      if (this.claimIdArray.length === this.lstClaimProcessListCount.length) {
        this.checkAll = true;
      }
      this.countsUpdate.emit({
        claimId: claimId,
        status: event.checked,
        total: this.claimIdArray,
      });
    } else if (!event.checked) {
      this.totalSelectedOrders--;
      this.claimIdArray = this.claimIdArray.filter((x) => x !== claimId);
      this.lstClaimProcessListCount = this.lstClaimProcessList.filter(
        (x) => x.claimId !== defaultGuid
      );
      if (this.claimIdArray.length !== this.lstClaimProcessListCount.length) {
        this.checkAll = false;
      }
      this.countsUpdate.emit({
        claimId: claimId,
        status: event.checked,
        total: this.claimIdArray,
      });
    }
    // //For Row checkbox change event to select all and deselect all functionality
    // if (this.selectAll) {
    //   this.selectAll =
    //     this.lstClaimProcessList.filter((a) => a.selected === true).length <
    //     this.lstClaimProcessList.length
    //       ? false
    //       : true;
    // } else if (!this.selectAll) {
    //   this.selectAll =
    //     this.lstClaimProcessList.filter((a) => a.selected === true).length ===
    //     this.lstClaimProcessList.length
    //       ? true
    //       : false;
    // }
  }

  //Open the Claim Form modal popup
  openClaimForm(
    orderId: string,
    patientId: string,
    defaultSaleOrderId: string,
    claimId: string,
    chartNo: string,
    fullName: string
  ) {
    const dialogRef = this.dialog.open(ClaimViewTabComponent, {
      // minwidth: '65%',
      // minheight: '60%',
      disableClose: true,

      data: {
        orderId: orderId,
        patientId: patientId,
        defaultSaleOrderId: defaultSaleOrderId,
        claimId: claimId,
        chartNo: chartNo,
        fullName: fullName,
        isClaimBatch: false,
      },
    });

    const closeDialog = dialogRef.afterClosed().subscribe(
      (claimDetails: any) => {
        // && item.policyId === claimDetails.insurancePlanName
        const existingItemIndex = this.lstClaimProcessList.findIndex(
          (item) => item.mmOrderId === claimDetails.orderId
        );
        this.allCheckboxdisabled = false;
        if (existingItemIndex !== -1) {
          // Item with the flag already exists, update it
          this.lstClaimProcessList[existingItemIndex].claimId = claimDetails.id;
          this.lstClaimProcessList[existingItemIndex].status =
            'No Pending Data';
          // this.lstClaimProcessList[existingItemIndex].selected=true;

          let x = this.lstClaimProcessList.filter((dx) => dx.selected === true);
          this.totalSelectedOrders++;
          this.claimIdArray.push(claimDetails.id);
          this.countsUpdate.emit({
            claimId: claimDetails.id,
            status: true,
            total: null,
          });
          this.loadtable.emit();
        }
      },
      (err) => {
        // const data: HttpErrorResponse = err;
        // console.warn(data?.error?.error?.message)
        // Swal.fire({
        //   icon: 'info',
        //   text: data?.error?.error?.message,
        // });
      }
    );

    // dialogRef.afterClosed().subscribe(() => {
    //   // this.loadClaimProcessList()

    //   //  this.lstClaimProcessList.forEach(x => {
    //   //  if(x.claimId != defaultGuid){
    //   //   this.claimIdArray.push(x.claimId)
    //   //   x.selected = true

    //   //  }
    //   // })

    // });
  }
  onUserSelectionChange(event: MatSelectChange): void {
    this.selectedUserList = event.value;
    this.selectedUserList = this.lstClaimProcessList.find(
      (a) => a.claimId !== defaultGuid
    )?.claimId;
    // if(!this.selectedUserList){
    //   this.checkAll=false;
    //   this.toastr.info('Kindly Save the Claim form','Info')
    // }
  }
  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }
  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    // if there is a selection then clear that selection
    if (this.isSomeSelected()) {
      this.clearAllId();
    } else {
      this.isAllSelected() ? this.clearAllId() : this.getAllId();
    }
  }

  isSomeSelected() {
    return this.selection.selected.length > 0;
  }
  getAllId() {
    this.dataSource.data.forEach((row) =>
      row.claimId !== defaultGuid ? this.selection.select(row) : null
    );
    this.claimIdArray = [];
    this.ids = this.dataSource.data.forEach((row) =>
      row.claimId !== defaultGuid ? this.claimIdArray.push(row.claimId) : null
    );
    this.checkAll = true;
    // if( this.claimIdArray.length===0){
    //   this.checkAll=false;
    // }else{
    // this.checkAll=true;
    // }
    this.countsUpdate.emit({
      claimId: defaultGuid,
      status: true,
      total: this.claimIdArray,
    });
  }
  clearAllId() {
    this.selection.clear();
    this.checkAll = false;
    this.claimIdArray = [];
    this.countsUpdate.emit({
      claimId: defaultGuid,
      status: true,
      total: this.claimIdArray,
    });
  }

  isEmpty(value: any): boolean {
    return (
      value === null ||
      value === undefined ||
      value === '' ||
      value === defaultGuid
    );
  }
}
