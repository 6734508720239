<mat-dialog-content class="mat-typography pt-0 pb-0">
  <div class="modal-header pt-0 pb-0">
    <h1 class="modal-title"><b>Fax Finder</b></h1>
    <mat-icon mat-dialog-close>close</mat-icon>
  </div>


  <div class="card card-body pt-0 pb-0">
    <form [formGroup]="fgFaxFinder">
      <div class="row">

        <!--Fax Id-->
        <mat-form-field class="form-field col-md-2 col-lg-2 col-xl-2">
          <input matInput type="text" formControlName="FaxId" placeholder="FaxId"
            (ngModelChange)="onKeyPressSearch()" />
        </mat-form-field>
        <!--Chart No-->
        <mat-form-field class="form-field col-md-2 col-lg-2 col-xl-2">
          <input matInput type="text" formControlName="ChartNo" placeholder="ChartNo"
            (ngModelChange)="onKeyPressSearch()" />
        </mat-form-field>
        <!--Patient Name-->
        <mat-form-field class="form-field col-md-2 col-lg-2 col-xl-2">
          <input matInput type="text" formControlName="PatientName" placeholder="PatientName"
            (ngModelChange)="onKeyPressSearch()" />
        </mat-form-field>
        <!-- Birth Date -->
        <mat-form-field class="col-md-2 col-lg-2 col-xl-2">
          <mat-label>
            Birth Date </mat-label>
          <input autocomplete="new-txtBirthdate" [max]="BirthdateMaxDate" formControlName="txtBirthdate" matInput
            maxlength="40" type="text" placeholder="(MM/DD/YYYY)" [matDatepicker]="txtBirthdate"
            [min]="BirthdateMinDate" (ngModelChange)="onKeyPressSearch()" />
          <mat-datepicker-toggle matSuffix [for]="txtBirthdate">
          </mat-datepicker-toggle>
          <mat-datepicker #txtBirthdate> </mat-datepicker>
          <mat-error *ngIf="fgFaxFinder.get('txtBirthdate').errors?.invalidDate||
                BirthdateMinDate <=
                fgFaxFinder?.get('txtBirthdate')?.value &&
                !(
                  fgFaxFinder?.get('txtBirthdate')?.touched &&
                  fgFaxFinder?.get('txtBirthdate')?.errors
                    ?.required
                )||fgFaxFinder?.get('txtBirthdate')?.errors?.required
              "> Enter Past Date!
          </mat-error>
        </mat-form-field>

        <div class="col-md-4 col-lg-4 col-xl-4 text-right mt-3">
          <button mat-button class="buttonColor mr-2" [disabled]="!(!fgFaxFinder?.invalid)" (click)="loadDefault()">
            <span>Search</span>
          </button>
          <button mat-raised-button class="resetclr" (click)="resetFaxFinder()">Reset</button>
        </div>
      </div>


    </form>

    <div class="row" *ngIf="isLoading">
      <div class="col-12">
        <div class="d-flex justify-content-center" *ngIf="isLoading && start ===1" style="color: red;">
          <!-- <img class="img-responsive pb-2 mr-1" src="assets/gifs/Infinity-loader.gif" /> -->
          Please wait... Loading...
        </div>
      </div>
    </div>
    <div>
      <div #tableContainer class="p-4">

        <table datatable [dtOptions]="dtOptions" id="faxfindertbl" class="faxfindertbl row-border hover"
          dtLoadingSpinner>
          <thead>
            <tr style="font: 600 14px/20px Roboto, Helvetica Neue, sans-serif;">

              <th [matTooltip]="'Fax Id'">Fax Id</th>
              <th [matTooltip]="'Patient Name'">Patient Name</th>
              <th [matTooltip]="'Chart No'">Chart No</th>
              <th [matTooltip]="'Date of Birth'">DOB</th>
              <th [matTooltip]="'Referral Source Name'">Referral Source Name</th>
              <th [matTooltip]="'Location'">Location</th>
              <th [matTooltip]="'Fax Folder'">Fax Folder</th>
              <th [matTooltip]="'Insurance'">Insurance</th>
            </tr>
          </thead>
          <tbody style="font: 400 13px/20px Roboto, Helvetica Neue, sans-serif !important;">

          </tbody>
          <tfoot>
            <tr>

              <th><input #faxIdInput matInput type="text" class="textbox-border-class" placeholder="Fax Id" /></th>
              <th>
                <input #patientNameInput matInput type="text" class="textbox-border-class" placeholder="Patient Name" />
              </th>
              <th>
                <input #chartNoInput matInput type="text" class="textbox-border-class" placeholder="Chart No" />
              </th>
              <th>
                <input #dateOfBirthInput matInput type="text" class="textbox-border-class"
                  placeholder="DOB" />
              </th>
              <th>
                <input #referralSourceInput matInput class="textbox-border-class" type="text"
                  placeholder="Referral Source Name" />
              </th>
              <th>
                <input #locationInput matInput class="textbox-border-class" type="text" placeholder="Location" />
              </th>
              <th>
                <input #folderInput matInput class="textbox-border-class" type="text" placeholder="Fax Folder" />
              </th>
              <th>
                <input #insuranceInput matInput class="textbox-border-class" type="text" placeholder="Insurance" />
              </th>
            </tr>
          </tfoot>
        </table>


      </div>
    </div>




  </div>
</mat-dialog-content>
