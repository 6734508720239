import { DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import {
  CommonDocumentViewerType,
  defaultGuid,
} from 'projects/shared/src/app/enums/allenums.enum';
import {
  PaymentMethodBillingStatusDTO,
  PaymentsReportDTO,
} from '../../claim-proxy/claim-processing-management/rcm/billing-management/optimization/dto/models';
import { Observable, Subscription } from 'rxjs';
import { BillingReportService } from '../../claim-proxy/claim-processing-management/rcm/billing-management/optimization/billing-reports';
import { map, startWith } from 'rxjs/operators';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { CommonService } from 'projects/shared/src/app/services/common.service';
import { HttpErrorResponse } from '@angular/common/http';
import { MatDialog } from '@angular/material/dialog';
import { UploadEOBComponent } from '../../claim-status-list/claim-status-list-table/upload-eob/upload-eob.component';
import { ItemPaymentService } from '../../claim-proxy/claim-processing-management/rcm/claim-processing-management/x12/item-payment.service';
import { LoaderService } from 'projects/shared/src/app/services/loader-service';

@Component({
  selector: 'app-billing-payments',
  templateUrl: './billing-payments.component.html',
  styleUrls: ['./billing-payments.component.scss'],
})
export class BillingPaymentsComponent implements OnInit {
  DataList: any[];
  paymentSearchForm: FormGroup;
  billingPaymentTable: any = {
    responsive: true,
    paging: false,
    dom: 'Bfrtip',
    scrollCollapse: true,
    scrollY: '500px',
    scrollX: true,
    search: {
      smart: false,
    },
    language: {
      emptyTable: 'No records',
      info: 'Total : _MAX_ records',
      infoEmpty: 'Total : _MAX_ records',
      infoFiltered: '(filtered : _TOTAL_ records)',
      loadingRecords: 'Loading...',
      zeroRecords: 'No matching records',
    },
    order: [],
    columnDefs: [
      // { targets: 0, orderable: false }
    ],
    buttons: [
      {
        extend: 'excel',
        text: 'Export', // Change the text to 'Export'
        filename: 'Payment List',
        exportOptions: {
          columns: ':visible:not(:first-child) ',
        },
      },
    ],
  };
  isLoading: boolean = true;
  isDataLoaded: boolean = false;
  ltPaymentsReport: PaymentsReportDTO[] = [];
  ltPaymentTypes: PaymentMethodBillingStatusDTO[] = [];
  filteredPaymentTypes: Observable<PaymentMethodBillingStatusDTO[]>;
  subscription$: Subscription[] = [];
  constructor(
    private fb: FormBuilder,
    private datepipe: DatePipe,
    private billingReportService: BillingReportService,
    private commonService: CommonService,
    private dialog: MatDialog,
    private itemPaymentService: ItemPaymentService,
    private loaderService: LoaderService
  ) {
    this.loaderService.getLoaderState().subscribe((isDataLoaded) => {
      this.isDataLoaded = isDataLoaded;
    });
  }

  ngOnInit(): void {
    this.billingPaymentTable.buttons[0].filename =
      'Payment List' + '-' + this.datepipe.transform(new Date(), 'MM-dd-yyyy');
    this.initializeForms();
    this.loadDropdowns();
    this.buildFilterParams();
  }
  ngOnDestroy() {
    this.subscription$.forEach((a) => {
      a.unsubscribe();
    });
  }
  initializeForms() {
    this.paymentSearchForm = this.fb.group({
      // drpStatus: new FormControl(''),
      // txtStatusFilter: new FormControl(''),
      drpMethod: new FormControl(''),
      txtMethodFilter: new FormControl(''),
      txtCreatedStartDate: new FormControl(''),
      txtCreatedEndDate: new FormControl(''),
      txtReferenceNo: new FormControl(''),
      txtRemittanceStartDate: new FormControl(''),
      txtRemittanceEndDate: new FormControl(''),
    });
  }
  loadDropdowns() {
    const paymentMethod = this.billingReportService
      .getPaymentMethodsByBFilter(false)
      .subscribe(
        (response) => {
          this.ltPaymentTypes = response ?? [];
          this.filteredPaymentTypes = this.paymentSearchForm
            .get('txtMethodFilter')
            .valueChanges.pipe(
              startWith(''),
              map((value) =>
                this.ltPaymentTypes?.filter((option) =>
                  option?.statusName
                    ?.toLowerCase()
                    ?.includes(value?.toLowerCase() ?? '')
                )
              )
            );
        },
        (error) => {
          console.error('Error:', error);
        }
      );
    this.subscription$.push(paymentMethod);
  }

  clearStatus() {
    this.paymentSearchForm.patchValue({
      drpStatus: null,
    });
  }
  clearMethod() {
    this.paymentSearchForm.patchValue({
      drpMethod: null,
    });
  }
  clearCreatedDate() {
    this.paymentSearchForm.patchValue({
      txtCreatedStartDate: null,
      txtCreatedEndDate: null,
    });
  }
  clearRemittanceDate() {
    this.paymentSearchForm.patchValue({
      txtRemittanceStartDate: null,
      txtRemittanceEndDate: null,
    });
  }

  isEmpty(value: any): boolean {
    return (
      value === null ||
      value === undefined ||
      value === '' ||
      value === defaultGuid
    );
  }
  addSlashToDate(event: Event): void {
    const input = event.target as HTMLInputElement;
    const inputValue = input.value;
    input.value = inputValue
      .replace(/^(\d{2})(\d{1,2})/, '$1/$2')
      .replace(/^(\d{2}\/\d{2})(\d+)/, '$1/$2')
      .replace(/[^\d\/]/g, '');
  }
  onCreatedDateChange(event: MatDatepickerInputEvent<Date>): void {
    if (
      this.paymentSearchForm.value.txtCreatedStartDate &&
      this.paymentSearchForm.value.txtCreatedEndDate
    ) {
      this.buildFilterParams();
    }
  }

  onRemittanceDateChange(event: MatDatepickerInputEvent<Date>): void {
    if (
      this.paymentSearchForm.value.txtRemittanceStartDate &&
      this.paymentSearchForm.value.txtRemittanceEndDate
    ) {
      this.buildFilterParams();
    }
  }

  buildFilterParams() {
    let paymentMethod =
      this.paymentSearchForm.value.drpMethod === null ||
      this.paymentSearchForm.value.drpMethod === ''
        ? defaultGuid
        : this.paymentSearchForm.value.drpMethod;
    let referenceNumber =
      this.paymentSearchForm?.value?.txtReferenceNo?.trim() ?? '';

    let createdFromDate =
      this.paymentSearchForm?.value?.txtCreatedStartDate == null ||
      this.paymentSearchForm?.value?.txtCreatedStartDate == ''
        ? null
        : this.datepipe.transform(
            this.paymentSearchForm?.value?.txtCreatedStartDate,
            'MM/dd/yyyy'
          );
    let createdToDate =
      this.paymentSearchForm?.value?.txtCreatedEndDate == null ||
      this.paymentSearchForm?.value?.txtCreatedEndDate == ''
        ? null
        : this.datepipe.transform(
            this.paymentSearchForm?.value?.txtCreatedEndDate,
            'MM/dd/yyyy'
          );

    let remittanceFromDate =
      this.paymentSearchForm?.value?.txtRemittanceStartDate == null ||
      this.paymentSearchForm?.value?.txtRemittanceStartDate == ''
        ? null
        : this.datepipe.transform(
            this.paymentSearchForm?.value?.txtRemittanceStartDate,
            'MM/dd/yyyy'
          );

    let remittanceToDate =
      this.paymentSearchForm?.value?.txtRemittanceEndDate == null ||
      this.paymentSearchForm?.value?.txtRemittanceEndDate == ''
        ? null
        : this.datepipe.transform(
            this.paymentSearchForm?.value?.txtRemittanceEndDate,
            'MM/dd/yyyy'
          );
    this.isLoading = true;
    this.getPaymentsReport(
      paymentMethod,
      createdFromDate,
      createdToDate,
      referenceNumber,
      remittanceFromDate,
      remittanceToDate
    );
  }
  getPaymentsReport(
    paymentMethod: any,
    createdFromDate: any,
    createdToDate: any,
    referenceNumber: any,
    remittanceFromDate: any,
    remittanceToDate: any
  ) {
    const paymentReport = this.billingReportService
      .getPaymentsBillingReportByPageNoAndPageOffSet(
        paymentMethod,
        createdFromDate,
        createdToDate,
        referenceNumber,
        remittanceFromDate,
        remittanceToDate,
        null,
        null
      )
      .subscribe(
        (response) => {
          this.ltPaymentsReport = response?.items ?? [];

          this.ltPaymentsReport = this.ltPaymentsReport.map((data) => {
            return {
              ...data,
              createdDate: this.commonService.getFormattedDateTimeZone(
                data.createdDate
              ),
              remittanceDate: this.commonService.getFormattedDateZone(
                data.remittanceDate
              ),
              modifiedDate: this.commonService.getFormattedDateTimeZone(
                data.modifiedDate
              ),
            };
          });
          this.isLoading = false;
        },
        (err) => {
          console.log(err);
          this.isLoading = false;
          this.ltPaymentsReport = [];
        }
      );

    this.subscription$.push(paymentReport);
  }

  openEOBModel() {
    let isSaveEnable: boolean = true;
    let cliamData: any;
    const dialogRef = this.dialog.open(UploadEOBComponent, {
      width: '100vw',
      height: '100vh',
      maxWidth: '100vw',
      maxHeight: '100vh',
      panelClass: 'fullscreen-dialog-panel',
      disableClose: true,
      data: {
        cliamData: cliamData,
        isSaveEnable: isSaveEnable,
      },
    });
    const closeDialog = dialogRef.afterClosed().subscribe(
      (isSaved: any) => {
        if (isSaved) {
          this.buildFilterParams();
        }
      },
      (err) => {
        const data: HttpErrorResponse = err;
        console.warn(data?.error?.error?.message);
      }
    );
  }

  downloadEOB(blobName: string, checkNumber: string) {
    this.isDataLoaded = true;
    const eobDownload = this.itemPaymentService
      .getEOBDocumentByBlobIDByBlobName(blobName)
      .subscribe(
        (response) => {
          let pdfBase64String = 'data:application/pdf;base64,' + response;

          const downloadLink = document.createElement('a');
          const fileName = checkNumber + '.pdf';

          downloadLink.href = pdfBase64String;
          downloadLink.download = fileName;
          downloadLink.click();
          this.isDataLoaded = false;
        },
        (err) => {
          console.log();
          this.isDataLoaded = false;
        }
      );
    this.subscription$.push(eobDownload);
  }

  resetForm() {
    this.paymentSearchForm.reset();
    this.buildFilterParams();
  }
}
