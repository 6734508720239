import type { PatientDTO, SearchPatientDTO } from './dto/models';
import { PagedAndSortedResultRequestDto, RestService } from '@abp/ng.core';
import type { PagedResultDto } from '@abp/ng.core';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class PatientSearchService {
  apiName = 'Default';

  getList = (input: PagedAndSortedResultRequestDto) =>
    this.restService.request<any, PagedResultDto<PatientDTO>>(
      {
        method: 'GET',
        url: '/api/app/patient-search',
        params: {
          skipCount: input.skipCount,
          maxResultCount: input.maxResultCount,
          sorting: input.sorting,
        },
      },
      { apiName: this.apiName }
    );

  searchPatientBySPatientNameAndSDefaultPatientIdAndSSSNAndSAccountNumberAndSEmailIdAndSMobileAndSFaxAndSAddressAndDtDOBAndSLockStatusAndSSearchNameAndSCurrentStatus =
    (
      sPatientName: string,
      sDefaultPatientId: string,
      sSSN: string,
      sAccountNumber: string,
      sEmailId: string,
      sMobile: string,
      sFax: string,
      sAddress: string,
      dtDOB: string,
      sLockStatus: string,
      sSearchName: string,
      sCurrentStatus: string
    ) =>
      this.restService.request<any, PagedResultDto<PatientDTO>>(
        {
          method: 'POST',
          url: '/api/app/patient-search/search-patient',
          params: {
            sPatientName,
            sDefaultPatientId,
            sSSN,
            sAccountNumber,
            sEmailId,
            sMobile,
            sFax,
            sAddress,
            dtDOB,
            sLockStatus,
            sSearchName,
            sCurrentStatus,
          },
        },
        { apiName: this.apiName }
      );



  getPatientCurrentStatusByLstPatientDto = (lstPatientDto: PatientDTO[]) =>
    this.restService.request<any, PatientDTO[]>(
      {
        method: 'GET',
        url: '/api/app/patient-search/get-patient-current-status',
        params: { lstPatientDto },
      },
      { apiName: this.apiName }
    );

  getPatientCurrentStatusV2ByPatientId = (patientId: string) =>
    this.restService.request<any, string>(
      {
        method: 'GET',
        responseType: 'text',
        url: `/api/app/patient-search/get-patient-current-status-v2/${patientId}`,
      },
      { apiName: this.apiName }
    );

    // generateChartId = (gBranchId: string) =>
    //   this.restService.request<any, string>({
    //     method: 'GET',
    //     responseType: 'text',
    //     url: `/api/app/patient-search/generate-chart-id/${gBranchId}`,
    //   },
    //   { apiName: this.apiName });


  searchPatientByParametersBySPatientNameAndSDefaultPatientIdAndSSSNAndSEmailIdAndSMobileAndDtDOBAndSLockStatusAndSCurrentStatus =
    (
      sPatientName: string,
      sDefaultPatientId: string,
      sSSN: string,
      sEmailId: string,
      sMobile: string,
      dtDOB: string,
      sLockStatus: string,
      sCurrentStatus: string
    ) =>
      this.restService.request<any, SearchPatientDTO[]>(
        {
          method: 'POST',
          url: '/api/app/patient-search/search-patient-by-parameters',
          params: {
            sPatientName,
            sDefaultPatientId,
            sSSN,
            sEmailId,
            sMobile,
            dtDOB,
            sLockStatus,
            sCurrentStatus,
          },
        },
        { apiName: this.apiName }
      );

  getPatientForCalender = () =>
    this.restService.request<any, SearchPatientDTO[]>(
      {
        method: 'GET',
        url: '/api/app/patient-search/get-patient-for-calender',
      },
      { apiName: this.apiName }
    );
    getNewSetupPatientSearch = (
      sSearchParams: string,
      patient: string
    ) =>
      this.restService.request<any, any>(
        {
          method: 'GET',
          url: '/api/app/patient-personal/get-patient-search',
          params: { sSearchParams, patient },
        },
        { apiName: this.apiName }
      );

  constructor(private restService: RestService) {}
}
