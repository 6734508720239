import type { CreateUpdateMasterProviderDTO, CSRGetDTO, MasterProviderDTO, ProviderGetDTO } from './dto/models';
import { RestService } from '@abp/ng.core';
import type { PagedAndSortedResultRequestDto, PagedResultDto } from '@abp/ng.core';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class MasterProviderService {
  apiName = 'orderManagement';

  create = (input: CreateUpdateMasterProviderDTO) =>
    this.restService.request<any, MasterProviderDTO>({
      method: 'POST',
      url: '/api/orderApp/master-provider',
      body: input,
    },
     { apiName: this.apiName, skipHandleError: true });

  delete = (id: string) =>
    this.restService.request<any, void>({
      method: 'DELETE',
      url: `/api/orderApp/master-provider/${id}`,
    },
     { apiName: this.apiName, skipHandleError: true });

  get = (id: string) =>
    this.restService.request<any, MasterProviderDTO>({
      method: 'GET',
      url: `/api/orderApp/master-provider/${id}`,
    },
     { apiName: this.apiName, skipHandleError: true });

  getList = (input: PagedAndSortedResultRequestDto) =>
    this.restService.request<any, PagedResultDto<MasterProviderDTO>>({
      method: 'GET',
      url: '/api/orderApp/master-provider',
      params: { skipCount: input.skipCount, maxResultCount: input.maxResultCount, sorting: input.sorting },
    },
     { apiName: this.apiName, skipHandleError: true });
     getListV1 = (input: PagedAndSortedResultRequestDto) =>
     this.restService.request<any, PagedResultDto<MasterProviderDTO>>({
       method: 'GET',
       url: '/api/orderApp/master-provider/master-provider-active-list',
       params: { skipCount: input.skipCount, maxResultCount: input.maxResultCount, sorting: input.sorting },
     },
      { apiName: this.apiName, skipHandleError: true });

  update = (id: string, input: CreateUpdateMasterProviderDTO) =>
    this.restService.request<any, MasterProviderDTO>({
      method: 'PUT',
      url: `/api/orderApp/master-provider/${id}`,
      body: input,
    },
     { apiName: this.apiName, skipHandleError: true });

  searchProviderBySNpiAndSCodeAndSPayorCodeAndSFullnameAndSphoneAndSAddress = (sNpi: string, sCode: string, sPayorCode: string, sFullname: string, sphone: string, sAddress: string) =>
    this.restService.request<any, PagedResultDto<MasterProviderDTO>>({
      method: 'POST',
      url: '/api/orderApp/master-provider/search-provider',
      params: { sNpi, sCode, sPayorCode, sFullname, sphone, sAddress },
    },
     { apiName: this.apiName, skipHandleError: true });

  drpMasterProvider = () =>
  this.restService.request<any, PagedResultDto<MasterProviderDTO>>({
    method: 'GET',
    url: '/api/orderApp/master-provider/drp-master-provider',
  },
  { apiName: this.apiName });

  getCsrNameByCNameAndSCSR = (cName: string, sCSR: string[]) =>
    this.restService.request<any, CSRGetDTO[]>({
      method: 'POST',
      url: '/api/orderApp/master-provider/get-csr-name',
      params: { cName },
      body:  sCSR ,
    },
    { apiName: this.apiName });

    getProviderNameByPNameAndSProvider = (pName: string, sProvider: string[]) =>
      this.restService.request<any, ProviderGetDTO[]>({
        method: 'POST',
        url: '/api/orderApp/master-provider/get-provider-name',
        params: { pName },
        body: sProvider,
      },
      { apiName: this.apiName });

  constructor(private restService: RestService) {}
}
