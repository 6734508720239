import type { CreateUpdatePatientPolicyDTO, PatientPolicyDTO, VerificationData } from './dto/models';
import { RestService } from '@abp/ng.core';
import type { PagedAndSortedResultRequestDto, PagedResultDto } from '@abp/ng.core';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class PatientPolicyService {
  apiName = 'Default';

  create = (input: CreateUpdatePatientPolicyDTO) =>
    this.restService.request<any, PatientPolicyDTO>({
      method: 'POST',
      url: '/api/app/patient-policy',
      body: input,
    },
    { apiName: this.apiName });

  delete = (id: string) =>
    this.restService.request<any, void>({
      method: 'DELETE',
      url: `/api/app/patient-policy/${id}`,
    },
    { apiName: this.apiName });

  get = (id: string) =>
    this.restService.request<any, PatientPolicyDTO>({
      method: 'GET',
      url: `/api/app/patient-policy/${id}`,
    },
    { apiName: this.apiName });

  getList = (input: PagedAndSortedResultRequestDto) =>
    this.restService.request<any, PagedResultDto<PatientPolicyDTO>>({
      method: 'GET',
      url: '/api/app/patient-policy',
      params: { sorting: input.sorting, skipCount: input.skipCount, maxResultCount: input.maxResultCount },
    },
    { apiName: this.apiName });

  update = (id: string, input: CreateUpdatePatientPolicyDTO) =>
    this.restService.request<any, PatientPolicyDTO>({
      method: 'PUT',
      url: `/api/app/patient-policy/${id}`,
      body: input,
    },
    { apiName: this.apiName });

    getActivePolicyByPatientIdByPatientId = (patientId: string) =>
    this.restService.request<any, PatientPolicyDTO[]>({
      method: 'GET',
      url: `/api/app/patient-policy/get-active-policy-by-patient-id/${patientId}`,
    },
    { apiName: this.apiName });
    getPatientPolicyByPatientPolicyIdByPatientIdAndPolicyId = (patientId: string, policyId: string) =>
    this.restService.request<any, PatientPolicyDTO[]>({
      method: 'GET',
      url: '/api/app/patient-policy/get-patient-policy-by-patient-policy-id',
      params: { patientId, policyId },
    },
    { apiName: this.apiName });

  getAllPolicyByPatientIdByPatientId = (patientId: string) =>
    this.restService.request<any, PatientPolicyDTO[]>({
      method: 'GET',
      url: `/api/app/patient-policy/get-all-policy-by-patient-id/${patientId}`,
    },
    { apiName: this.apiName });
    getPolicyActiveInactiveStatus = (status: number,patientId:string) =>
    this.restService.request<any, PatientPolicyDTO[]>({
      method: 'GET',
      url: `/api/app/patient-policy/get-all-policy-by-patient-id/${patientId}`,
      params:{status}
    },
    { apiName: this.apiName });
    getPolicyActiveInactiveStatusV1 = (PolicyID:string) =>
    this.restService.request<any, PatientPolicyDTO[]>({
      method: 'POST',
      url: `/api/app/patient-policy/enable-disable-patient-policy`,
      params:{PolicyID}
    },
    { apiName: this.apiName });

    getLastVerificationDataByPolicyId = (policyId: string) =>
this.restService.request<any, VerificationData>({
  method: 'GET',
  url: `/api/app/patient-verification/get-last-verification-data/${policyId}`,
},
{ apiName: this.apiName });

  constructor(private restService: RestService) {}
}
