<table
  [dtOptions]="dtAddressTableOptions"
  datatable
  class="row-border hover display"
>
  <thead>
    <tr>
      <th>Option</th>
      <th [matTooltip]="'Name Line'">Name Line</th>
      <th [matTooltip]="'Description'">Description</th>
      <th [matTooltip]="'Address'">Address</th>
      <th [matTooltip]="'Country'">Country</th>
      <th [matTooltip]="'State'">State</th>
      <th [matTooltip]="'City'">City</th>
      <th [matTooltip]="'Zip'">Zip</th>
      <th [matTooltip]="'Email'">Email</th>
      <th [matTooltip]="'Address Type'">Address Type</th>
      <!-- <th [matTooltip]="'Active'">Active</th> -->
      <th [matTooltip]="'First Order Date'">First Order Date</th>
      <th [matTooltip]="'Last Order Date'">Last Order Date</th>
      <th [matTooltip]="'Sale Order Count'">Sale Order Count</th>
      <th [matTooltip]="'Verified'">Verified</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let address of addressList">
      <td>
        <mat-icon
          type="button"
          (click)="getAddressDetails(address.id)"
          class="icon-success"
          >edit</mat-icon
        >
        <mat-icon type="button" (click)="selectAddress(address)" color="primary"
          >check_circle</mat-icon
        >
        <!-- <button
          (click)="
            verifyAddress(
              address.address,
              address?.city,
              address?.state,
              address?.mobile,
              address?.postalCode
            )
          "
          matTooltip="Click To Verify"
          mat-icon-button
        >
          <img
            class="img-responsive"
            src="assets/Order Page Icons/Verify Address 2.png"
            alt=""
            height="16"
          />
        </button> -->
      </td>
      <td [matTooltip]="address.nameLine || '-'">
        {{ address.nameLine ? address.nameLine : "-" }}
      </td>
      <td [matTooltip]="address.description || '-'">
        {{ address.description ? address.description : "-" }}
      </td>
      <td [matTooltip]="address.address || '-'">
        {{ address.address ? address.address : "-" }}
      </td>
      <td [matTooltip]="address.countryName || '-'">
        {{ address.countryName ? address.countryName : "-" }}
      </td>
      <td [matTooltip]="address.stateName || '-'">
        {{ address.stateName ? address.stateName : "-" }}
      </td>
      <td [matTooltip]="address.city || '-'">
        {{ address.city ? address.city : "-" }}
      </td>
      <td [matTooltip]="address.postalCode || '-'">
        {{ address.postalCode ? address.postalCode : "-" }}
      </td>
      <td [matTooltip]="address.emailId || '-'">
        {{ address.emailId ? address.emailId : "-" }}
      </td>
      <td [matTooltip]="address.addressTypeName || '-'">
        {{ address.addressTypeName ? address.addressTypeName : "-" }}
      </td>
      <!-- <td [matTooltip]="address.active || '-'">
        {{ address.active ? address.active : "-" }}
      </td> -->

      <td [matTooltip]="address.firstOrderDate || '-'">
        {{ address.firstOrderDate ? address.firstOrderDate : "-" }}
      </td>
      <td [matTooltip]="address.lastOrderDate || '-'">
        {{ address.lastOrderDate ? address.lastOrderDate : "-" }}
      </td>
      <td [matTooltip]="address.orderCount || '-'">
        {{ address.orderCount ? address.orderCount : "-" }}
      </td>
      <td
        [matTooltip]="address.isGoogleValidated ? 'Verified' : 'Not Verified'"
      >
        {{ address.isGoogleValidated ? "Verified" : "Not Verified" }}
      </td>
    </tr>
  </tbody>
  <tfoot>
    <tr>
      <th></th>
      <th>
        <input
          matInput
          type="text"
          class="textbox-border-class"
          placeholder="Name Line"
          name="search-Name Line"
        />
      </th>
      <th>
        <input
          matInput
          class="textbox-border-class"
          type="text"
          placeholder="Description"
          name="search-Description"
        />
      </th>
      <th>
        <input
          matInput
          type="text"
          class="textbox-border-class"
          placeholder="Address"
          name="search-Address"
        />
      </th>
      <th>
        <input
          matInput
          type="text"
          class="textbox-border-class"
          placeholder="Country"
          name="search-Country"
        />
      </th>
      <th>
        <input
          matInput
          type="text"
          class="textbox-border-class"
          placeholder="State"
          name="search-State"
        />
      </th>
      <th>
        <input
          matInput
          class="textbox-border-class"
          type="text"
          placeholder="City"
          name="search-City"
        />
      </th>
      <th>
        <input
          matInput
          class="textbox-border-class"
          type="text"
          placeholder="Zip"
          name="search-Zip"
        />
      </th>
      <th>
        <input
          matInput
          class="textbox-border-class"
          type="text"
          placeholder="Email"
          name="search-Email"
        />
      </th>
      <th>
        <input
          matInput
          class="textbox-border-class"
          type="text"
          placeholder="Address Type"
          name="search-AddressType"
        />
      </th>
      <!-- <th>
        <input
          matInput
          class="textbox-border-class"
          type="text"
          placeholder="Active"
          name="search-Active"
        />
      </th> -->
      <th>
        <input
          matInput
          class="textbox-border-class"
          type="text"
          placeholder="First Order Date"
          name="search-First Order Date"
        />
      </th>
      <th>
        <input
          matInput
          class="textbox-border-class"
          type="text"
          placeholder="Last Order Date"
          name="search-Last Order Date"
        />
      </th>
      <th>
        <input
          matInput
          class="textbox-border-class"
          type="text"
          placeholder="Sale Order Count"
          name="search-Sale Order Count"
        />
      </th>
      <th>
        <input
          matInput
          class="textbox-border-class"
          type="text"
          placeholder="Verified"
          name="search-Verified"
        />
      </th>
    </tr>
  </tfoot>
</table>
