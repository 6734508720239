import { Component, Input, OnInit } from '@angular/core';
import { defaultGuid } from '../../enums/allenums.enum';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { Subscription } from 'rxjs';
import { ClaimProcessingService } from 'projects/billing/src/app/claim-proxy/claim-processing-management/rcm/billing-management/billing/claim-processing.service';
import { HttpErrorResponse } from '@angular/common/http';
import Swal from 'sweetalert2';
import { event } from 'jquery';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-patient-common-claim',
  templateUrl: './patient-common-claim.component.html',
  styleUrls: ['./patient-common-claim.component.scss'],
})
export class PatientCommonClaimComponent implements OnInit {
  @Input() patientId: string = defaultGuid;
  commonClaimForm: FormGroup;
  isLoading: boolean = false;
  claimDetailsList: any = [];
  private loadClaimListAPICall: Subscription;
  showInt: boolean = true;
  showPaid: boolean = false;

  constructor(private formbuilder: FormBuilder,
    private claimProcessingService: ClaimProcessingService,
    public title: Title,
  ) { }

  ngOnInit(): void {
    this.title.setTitle('Qsecure | Claim');
    this.initializeForms();
    this.claimDetails(false);
  }

  claimDetails(isPaid: boolean) {
    this.isLoading = true;
    try {
      if (this.loadClaimListAPICall) {
        this.loadClaimListAPICall.unsubscribe();
      }
      this.loadClaimListAPICall = this.claimProcessingService
        .loadClaimListDetailsByGPatientIdAndIsPaid(
          this.patientId, isPaid
        )
        .subscribe(
          (response) => {
            this.claimDetailsList = response;
            this.isLoading = false;
          },
          (error) => {
            this.isLoading = false;
            const data: HttpErrorResponse = error;
            Swal.fire({
              icon: 'info',
              text: data?.error?.error?.message,
            });
          },
          () => {
            // This block will be executed on both success and error
            this.isLoading = false;
          }
        );
      // this.tableData$ = getTableData;
    } catch (error) {
      const data: HttpErrorResponse = error;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    } finally {
    }
  }




  initializeForms() {
    this.commonClaimForm = this.formbuilder.group({
      chkInt: new FormControl(this.showInt),
      chkShow: new FormControl(this.showPaid),
    });
  }

  // showIntOptions(event: MatCheckboxChange): void {
  //   this.isLoading = true;
  //   setTimeout(() => (this.isLoading = false), 500);
  // }

  // showPaidOptions(event: MatCheckboxChange): void {
  //   this.isLoading = true;
  //   setTimeout(() => (this.isLoading = false), 500);
  // }

  showIntOptions($event: any) {
    this.showInt = $event.checked;
    if (this.showInt) {
      this.claimDetails(false);
      this.showPaid = false;
    }
  }

  showPaidOptions($event: any) {
    this.showPaid = $event.checked;
    if (this.showPaid) {
      this.claimDetails(true);;
      this.showInt = false;
    }
  }
}
