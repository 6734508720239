<mat-dialog-content class="mat-typography">
  <div class="row">
    <div class="col-lg-6">
      <h2>{{Title}}</h2>
    </div>
  </div>
  <div class="row">
    <div class="col-lg-12">
      <mat-card-content>
        <div class="row">
          <div class="col-12">
            <span class="customThemeClass"><b>Item Details :</b></span>

            <span class="col-4">Code : <span>{{copyItemPayment.codes}}</span></span>
            <span class="col-4">DOS : <span>{{copyItemPayment.dos}}</span> </span>
            <span class="col-4">Charge : <span>{{copyItemPayment.billed}}</span></span>
          </div>
        </div>
        <div *ngIf="data.selectedInsuranceType==1;" class="row pt-2">
          <mat-form-field class="col-3">
            <mat-label>Allowed</mat-label>
            <input autocomplete="new-allowed" matInput [(ngModel)]="copyItemPayment.allowed" [allowNegativeNumbers]="true" [showMaskTyped]="true"
              placeHolderCharacter="" mask="separator.2"  prefix="$ " type="text" maxlength="40" />
          </mat-form-field>
          <mat-form-field class="col-3">
            <mat-label>Deduct</mat-label>
            <input autocomplete="new-deduct" matInput [(ngModel)]="copyItemPayment.deduct" [allowNegativeNumbers]="true" [showMaskTyped]="true"
              placeHolderCharacter="" mask="separator.2"  prefix="$ " type="text" maxlength="40" />
          </mat-form-field>
          <mat-form-field class="col-3">
            <mat-label>Copay</mat-label>
            <input autocomplete="new-coinsurance" matInput [(ngModel)]="copyItemPayment.coinsurance" [allowNegativeNumbers]="true" [showMaskTyped]="true"
              placeHolderCharacter="" mask="separator.2"  prefix="$ " type="text" maxlength="40" />
          </mat-form-field>
          <mat-form-field class="col-3">
            <mat-label>Payment</mat-label>
            <input autocomplete="new-insurancePaid" matInput [(ngModel)]="copyItemPayment.insurancePaid" (ngModelChange)="paymentDatachanged(copyItemPayment)"
              [allowNegativeNumbers]="true" [showMaskTyped]="true" placeHolderCharacter="" mask="separator.2"
               prefix="$ " type="text" maxlength="40" />
          </mat-form-field>
          <mat-form-field class="col-3">
            <mat-label>Write Off</mat-label>
            <input autocomplete="new-writeOff" matInput [(ngModel)]="copyItemPayment.writeOff" [allowNegativeNumbers]="true" [showMaskTyped]="true"
              placeHolderCharacter="" mask="separator.2"  prefix="$ " type="text" maxlength="40" />
          </mat-form-field>
          <mat-form-field class="col-3">
            <mat-label>Ins Bal</mat-label>
            <input autocomplete="new-insBalance" matInput [(ngModel)]="copyItemPayment.insBalance" [allowNegativeNumbers]="true"
              [showMaskTyped]="true" placeHolderCharacter="" mask="separator.2"  prefix="$ "
              type="text" maxlength="40" />
          </mat-form-field>
          <mat-form-field class="col-3">
            <mat-label>Pat Bal</mat-label>
            <input autocomplete="new-patBalance" matInput [(ngModel)]="copyItemPayment.patBalance" [allowNegativeNumbers]="true"
              [showMaskTyped]="true" placeHolderCharacter="" mask="separator.2"  prefix="$ "
              type="text" maxlength="40" />
          </mat-form-field>
          <!-- <mat-form-field class="col-3">
            <mat-label>Claim <span class="asterisk">*</span></mat-label>
            <mat-select [(ngModel)]="copyItemPayment.claim" type="text"
              (ngModelChange)="claimDatachanged(copyItemPayment)" maxlength="40">
              <mat-option value="">No Action</mat-option>
              <mat-option value="Transfer to patient">Transfer to patient</mat-option>
              <mat-option value="Moved to next ins">Moved to next ins</mat-option>
            </mat-select>
          </mat-form-field> -->
        </div>
        <div *ngIf="data.selectedInsuranceType==2 ||data.selectedInsuranceType==3;" class="row pt-2">
          <mat-form-field class="col-3">
            <mat-label>Allowed</mat-label>
            <input autocomplete="new-allowed" matInput [(ngModel)]="copyItemPayment.allowed" [allowNegativeNumbers]="true" [showMaskTyped]="true"
              placeHolderCharacter="" mask="separator.2"  prefix="$ " type="text" maxlength="40" />
          </mat-form-field>
          <mat-form-field class="col-3">
            <mat-label>Deduct</mat-label>
            <input autocomplete="new-deduct" matInput [(ngModel)]="copyItemPayment.deduct" [allowNegativeNumbers]="true" [showMaskTyped]="true"
              placeHolderCharacter="" mask="separator.2"  prefix="$ " type="text" maxlength="40" />
          </mat-form-field>
          <mat-form-field class="col-3">
            <mat-label>Copay</mat-label>
            <input autocomplete="new-coinsurance" matInput [(ngModel)]="copyItemPayment.coinsurance" [allowNegativeNumbers]="true" [showMaskTyped]="true"
              placeHolderCharacter="" mask="separator.2"  prefix="$ " type="text" maxlength="40" />
          </mat-form-field>
          <mat-form-field class="col-3">
            <mat-label>Payment</mat-label>
            <input autocomplete="new-insurancePaid" matInput [(ngModel)]="copyItemPayment.insurancePaid" (ngModelChange)="paymentDatachanged(copyItemPayment)"
              [allowNegativeNumbers]="true" [showMaskTyped]="true" placeHolderCharacter="" mask="separator.2"
               prefix="$ " type="text" maxlength="40" />
          </mat-form-field>
          <mat-form-field class="col-3">
            <mat-label>Write Off</mat-label>
            <input autocomplete="new-writeOff" matInput [(ngModel)]="copyItemPayment.writeOff" [allowNegativeNumbers]="true" [showMaskTyped]="true"
              placeHolderCharacter="" mask="separator.2"  prefix="$ " type="text" maxlength="40" />
          </mat-form-field>
          <mat-form-field class="col-3">
            <mat-label>Ins Bal</mat-label>
            <input autocomplete="new-insBalance" matInput [(ngModel)]="copyItemPayment.insBalance" [allowNegativeNumbers]="true"
              [showMaskTyped]="true" placeHolderCharacter="" mask="separator.2"  prefix="$ "
              type="text" maxlength="40" />
          </mat-form-field>
          <mat-form-field class="col-3">
            <mat-label>Pat Bal</mat-label>
            <input autocomplete="new-patBalance" matInput [(ngModel)]="copyItemPayment.patBalance" [allowNegativeNumbers]="true"
              [showMaskTyped]="true" placeHolderCharacter="" mask="separator.2"  prefix="$ "
              type="text" maxlength="40" />
          </mat-form-field>
          <!-- <mat-form-field class="col-3">
            <mat-label>Claim <span class="asterisk">*</span></mat-label>
            <mat-select [(ngModel)]="copyItemPayment.claim" type="text"
              (ngModelChange)="claimDatachanged(copyItemPayment)" maxlength="40">
              <mat-option value="">No Action</mat-option>
              <mat-option value="Transfer to patient">Transfer to patient</mat-option>
              <mat-option value="Moved to next ins">Moved to next ins</mat-option>
            </mat-select>
          </mat-form-field> -->
        </div>
      </mat-card-content>
    </div>
  </div>

</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button class="buttonColor" (click)="calculatePayment()"> Calculate </button>
  <button class="buttonCancelColor" mat-button mat-dialog-close>Close</button>
</mat-dialog-actions>
