import { remove } from '@syncfusion/ej2-base';
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { PatientPersonalService } from '../../patient-proxy/patient-optimization';
import { DatePipe } from '@angular/common';
import { Subscription } from 'rxjs';
import Swal from 'sweetalert2';
import { HttpErrorResponse } from '@angular/common/http';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { defaultGuid } from 'projects/shared/src/app/enums/allenums.enum';
import { CommunicationService } from 'projects/shared/src/app/services/communication.service';
import emailMask from 'text-mask-addons/dist/emailMask';
@Component({
  selector: 'app-patient-search-newsetup',
  templateUrl: './patient-search-newsetup.component.html',
  styleUrls: ['./patient-search-newsetup.component.scss'],
})
export class PatientSearchNewsetupComponent implements OnInit {
  patientSearchForm: FormGroup;
  private patientSearchAPICall: Subscription;
  BirthdateMaxDate: Date;
  BirthdateMinDate: Date;
  isShown: boolean = false;
  isLoading: boolean = false;
  isReset:boolean = false;
  patientSearchList: any;
  chartIdInput: string;
  patientNameInput: string;
  dobInput: string;
  ssnInput: string;
  policyNoInput: string;
  EmailIdInput: string;
  txtHomeNoInput: string;
  patientIDV2: string;
  subscription$: Subscription[]=[];
  emailMask: any;
  constructor(
    private fb: FormBuilder,
    private patientPersonalServices: PatientPersonalService,
    private communicationService: CommunicationService,
    private datepipe: DatePipe,

    @Inject(MAT_DIALOG_DATA)
    public data: {
      patientIDV1: any;
    } = {
      patientIDV1: defaultGuid,
    }
  ) {
    const loadPatientData =
      this.communicationService.functionPatientSearchData$.subscribe(
        (patientDetails) => {
          // console.log(patientDetails);
          this.patientSearchList=patientDetails;
        }
      );
    this.subscription$.push(loadPatientData);
  }

  ngOnInit(): void {
    this.initializeForms();
    if (this.data) {
      this.patientIDV2 = this.data?.patientIDV1 ?? defaultGuid;
    }
    this.loadValidDates();
    this.emailMask = emailMask;
    // this.fnSearchPatient();
  }

  initializeForms() {
    this.patientSearchForm = this.fb.group({
      ChartNo: new FormControl(''),
      PatientName: new FormControl(''),
      txtBirthdate: new FormControl('',this.isValidDateValidator.bind(this)),
      ssnNo: new FormControl(''),
      policyNo: new FormControl(''),
      EmailId: new FormControl(''),
      txtHomeNo: new FormControl(''),
    });
  }

  resetPatientSearch() {
    // this.isReset=true;
      //   setTimeout(() => {
      //   this.isLoading=true;
      // }, 100);
      //  this.patientSearchList=[];
      //  setTimeout(() => {
      //   this.isLoading=false;
      // }, 100);
      //  this.patientSearchForm.reset();
    // setTimeout(() => {
    //   this.isReset = false;
    //   // this.fnSearchPatient();
    //   this.isLoading=true;
    //    this.patientSearchList=[];
    //    this.isLoading=false;
    // }, 1000);

    const resetFields = {
      EmailId : ''
    };
    this.isLoading = true;
    this.patientSearchList = [];
    setTimeout(() => {
        this.isLoading=false;
      }, 100);
    this.patientSearchForm.reset(resetFields);
  }

  // onKeyPressSearch() {
  //   if (this.patientSearchForm.valid && !this.patientSearchForm.errors) {
  //     console.log(this.patientSearchForm.value);
  //     this.fnSearchPatient();
  //   }
  // }

  onKeyPressSearch() {
    if (this.patientSearchForm.valid && !this.patientSearchForm.errors && this.areFieldsNotEmpty()) {
      // if(this.isReset === false){
      this.fnSearchPatient();
    // }
  }
  }
  areFieldsNotEmpty(): boolean {
    const { ssnNo, policyNo, EmailId, ChartNo, PatientName, txtBirthdate, txtHomeNo} = this.patientSearchForm.value;
    return ssnNo || policyNo || EmailId || ChartNo || PatientName || txtBirthdate || txtHomeNo;
  }

  fnSearchPatient() {
    this.isLoading = true;
    this.chartIdInput = this.patientSearchForm.value.ChartNo;
    this.patientNameInput = this.patientSearchForm.value.PatientName;
    this.dobInput = this.patientSearchForm.value.txtBirthdate;
    this.ssnInput = this.patientSearchForm.value.ssnNo;
    this.policyNoInput = this.patientSearchForm.value.policyNo;
    this.EmailIdInput = this.patientSearchForm.value.EmailId.toLowerCase() || '';
    this.txtHomeNoInput = this.patientSearchForm.value.txtHomeNo;
    const dobInputConvert = this.datepipe.transform(
      this.dobInput,
      'yyyy-MM-dd'
    );
    try {
      if (this.patientSearchAPICall) {
        this.patientSearchAPICall.unsubscribe();
      }
      this.patientSearchAPICall = this.patientPersonalServices
        .getPatientSearchV1NewSetupV1(
          dobInputConvert,
          this.chartIdInput,
          this.patientNameInput,
          this.txtHomeNoInput,
          null,
          this.ssnInput,
          null,
          this.policyNoInput,
          this.EmailIdInput
        )
        .subscribe(
          (response) => {
            this.patientSearchList = response;
            // this.isLoading = false;
          },
          (error) => {
            this.isLoading = false;
            const data: HttpErrorResponse = error;
            Swal.fire({
              icon: 'info',
              text: data?.error?.error?.message,
            });
          },
          () => {
            // This block will be executed on both success and error
            this.isLoading = false;
          }
        );
      // this.tableData$ = getTableData;
    } catch (error) {
      const data: HttpErrorResponse = error;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    } finally {
    }
  }

  addSlashToDate(event: any): void {
    const input = event.target as HTMLInputElement;
    const inputValue = input.value;
    const keyCode = event.keyCode;

    //  const includedKeys = [8,9, 37, 39, 46,191,111];
    const includedKeys = [];
    if ((keyCode >= 47 && keyCode <= 57) || (keyCode >= 96 && keyCode <= 105)||(includedKeys.includes(keyCode)) ) {
    input.value = inputValue
    .replace(/^(\d{2})(\d{1,2})/, '$1/$2')
    .replace(/^(\d{2}\/\d{2})(\d+)/, '$1/$2')
    .replace(/[^\d\/]/g, '');
    }else if (keyCode >= 65 && keyCode <= 90){
      event.preventDefault();
    }else{

    }

  }

  loadValidDates() {
    this.BirthdateMaxDate = new Date();
    this.BirthdateMaxDate.setDate(this.BirthdateMaxDate.getDate());
    this.BirthdateMinDate = new Date();
    this.BirthdateMinDate.setFullYear(
      this.BirthdateMinDate.getFullYear() - 120
    );
    var oneYearAgo = new Date();
    oneYearAgo.setFullYear(this.BirthdateMaxDate.getFullYear() - 1);
  }
  // checkValidDate(date: any){
  //  console.log(date.getTime());
   isValidDate(dateString: string): boolean {
    if(dateString===''||dateString.length===0){
      return true;
    }
    // Regular expression to match valid dates in the format YYYY-MM-DD
    const dateRegex = /^\d{1,2}\/\d{1,2}\/\d{4}$/;

    if (!dateRegex.test(dateString)) {
        return false; // Date format is not valid
    }

    // Parse the date components
    const [month, day,year] = dateString.split('/').map(Number);

    // Check if the date is valid
    const date = new Date(year, month - 1, day);

    if (date.getFullYear() !== year || date.getMonth() + 1 !== month || date.getDate() !== day) {
        return false; // Date is not valid
    }
    if(year<1900 || year>new Date().getFullYear()){
      return false;
    }

    return true; // Date is valid
}
  // }
  checkValidDate(input: string): string {
  if (!this.isValidDate((input).trim())) {
    this.patientSearchForm
      .get('txtBirthdate')
      .setErrors({ invalid: true })
      return "Not valid";

  }
  return "Valid";
}
checkMinLength(input: string){
  if(input===''||input.length===0){
    return ;
  }
  if(input.length>0 && input.length<8){
    this.patientSearchForm
    .get('txtBirthdate')
    .setErrors({ invalid: true })
  }
}

  getFormattedDate(date: any): string {
    return date
      ? new Date(date).toLocaleString('en-US', {
          month: '2-digit',
          day: '2-digit',
          year: 'numeric',
        })
      : 'Invalid Date';
  }
  isValidDateValidator(control: FormControl) {
    if(control.value !== '' && control.value !== undefined && control.value !== null){
       const selectedDate = new Date(control.value);

       const year = selectedDate.getFullYear();
       const month = selectedDate.getMonth() + 1; // Month is zero-based, so add 1
       const day = selectedDate.getDate();

       const isValidDate = this.isValidDateDob(year, month, day);
       return isValidDate ? false : { invalidDate: true };
    }else{
      return false;
    }
  }

  isValidDateDob(year: number, month: number, day: number): boolean {
    if (
      year >= 1000 &&
      year <= 9999 &&
      month >= 1 &&
      month <= 12 &&
      day >= 1 &&
      day <= 31
    ) {
      return true;
    } else {
      return false;
    }
  }
}
