<form [formGroup]="writeOffForm">
  <mat-dialog-content class="mat-typography">
    <div class="modal-header mt-2">
      <h1 class="modal-title" class="customThemeClass">Write off</h1>
      <mat-radio-group formControlName="rdBalanceType" class="ml-3">
        <mat-radio-button class="mr-1" [value]="1">Insurance Balance</mat-radio-button>
        <mat-radio-button [value]="2">Patient Balance</mat-radio-button>
      </mat-radio-group>
      <button class="text-right close p-0" mat-icon-button (click)="onCloseClick()">
        <mat-icon>close</mat-icon>
      </button>
    </div>


    <div class="modal-body">
      <mat-card-content>
        <div class="row">
          <!-- <div class="mt-3 col-5"> -->
          <mat-form-field class="col-4">
            <mat-label>Product/HCPC Code<span class="asterisk">*</span></mat-label>
            <mat-select formControlName="drpProductCode" (selectionChange)="getAdjustments()">
              <mat-option>
              <ngx-mat-select-search [formControl]="productFilterControl" placeholderLabel="Search"
                      noEntriesFoundLabel="No Matches found" formControlName="txtProduct"></ngx-mat-select-search>
              </mat-option>
              <mat-option *ngFor="let product of filteredProductCodeList" [value]="product.itemId">
                {{
                  product.hcpccode || 'Unknown - NA'
                }}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field class="col-4">
            <mat-label>Adjustments</mat-label>
            <mat-select formControlName="drpAdjustment" multiple (selectionChange)="updateBalance()">

              <mat-option>
                <ngx-mat-select-search [formControl]="adjustmentsFilterControl" placeholderLabel="Search"
                        noEntriesFoundLabel="No Matches found" formControlName="txtAdjustments"></ngx-mat-select-search>
                </mat-option>
                <mat-option *ngFor="let adj of filteredAdjusetmentsCodeList" [value]="adj.glossaryId">
                  {{
                    adj.reasonCode || 'Unknown - NA'
                  }}
                </mat-option>
            </mat-select>
          </mat-form-field>
           <!-- writeOffForm?.get('drpAdjustment')?.value === null ||writeOffForm?.get('drpAdjustment')?.value === ''||writeOffForm?.get('drpAdjustment')?.value === undefined  -->
          <mat-form-field class="col-4" [class.disabled]="true">
            <mat-label>Balance Amount($) <span class="asterisk">($0 not allowed)</span></mat-label>
            <input formControlName="txtBalanceAmount" maxlength="10" matInput>
          </mat-form-field>



          <mat-form-field class="col-4">
            <mat-label>Write Off<span class="asterisk">*</span></mat-label>
            <mat-select formControlName="drpWriteOff">
              <mat-option>
                <ngx-mat-select-search [formControl]="writeOffFilterControl" placeholderLabel="Search"
                        noEntriesFoundLabel="No Matches found" formControlName="txtWriteOff"></ngx-mat-select-search>
                </mat-option>
                <mat-option *ngFor="let adj of filteredWriteOffList" [value]="adj.id">
                  {{
                    adj.description
                  }}
                </mat-option>
            </mat-select>
          </mat-form-field>
          <!-- writeOffForm?.get('drpWriteOff')?.value === null ||writeOffForm?.get('drpWriteOff')?.value === ''||writeOffForm?.get('drpWriteOff')?.value === undefined  -->
          <mat-form-field class="col-4"
            [class.disabled]="selectedGlossaryIds?.length >= 1 ">

            <mat-label>Write Off Amount($)<span class="asterisk">*</span></mat-label>
            <input formControlName="txtWriteOffeAmount"  maxlength="10" matInput autocomplete="off">
            </mat-form-field>

          <mat-form-field class="col-4">
            <mat-label>Note Type <span class="asterisk">*</span></mat-label>
            <mat-select formControlName="drpNotetype" #singleSelect>
              <mat-option value="Finance"> Finance</mat-option>
            </mat-select>
            <mat-error class="spanAlignment" *ngIf="
            writeOffForm?.get('drpNotetype')?.touched &&
            writeOffForm?.get('drpNotetype')?.errors?.required
            ">
              Note Type is a required field!
            </mat-error>
          </mat-form-field>

          <mat-form-field class="col-12">
            <mat-label>Comments<span class="asterisk">*</span></mat-label>
            <textarea matInput rows="6" autocomplete="off" maxlength="5000" formControlName="txtComments"
              [(ngModel)]="notesText" (input)="updateCharacterCount(5000)" type="text"></textarea>
            <div class="char-count">
              {{ notesText?.length }} / 5000
            </div>
            <mat-error class="spanAlignment" *ngIf="writeOffForm?.get('txtComments')?.touched">
              <ng-container *ngIf="writeOffForm?.get('txtComments')?.hasError('required')">
                Description is a required field!
              </ng-container>
              <ng-container *ngIf="writeOffForm?.get('txtComments')?.hasError('whitespace')">
                Description cannot be just whitespace!
              </ng-container>
            </mat-error>
          </mat-form-field>

        </div>
      </mat-card-content>
    </div>
    <div class="modal-footer">
      <div class="col-12 text-center">
        <!-- <button mat-button class="buttonColor mb-2 mr-2" mat-raised-button
      [disabled]="CallbackForm.get('drpBranchType').value === '' ||CallbackForm.get('drpBranchType').value === defaultGuid ||CallbackForm.invalid||savebtn "
         (click)="saveCallback()">Save
      </button> -->
      <!-- || this.currentBalance === 0 -->
        <button mat-button class="buttonColor mb-2 mr-2" mat-raised-button [disabled]="writeOffForm.invalid || isShowSpinner" (click)="saveWriteOff()">
          Save
          <!-- <mat-icon *ngIf="isShowSpinner">
            <mat-spinner class="spinner-border spinner-border-sm" diameter="20"></mat-spinner>
          </mat-icon> -->
        </button>

        <button mat-button class="resetclr mb-2 mr-2">Reset</button>
      </div>
    </div>

  </mat-dialog-content>
</form>
