import { values } from 'pdf-lib';
import { ListService } from '@abp/ng.core';
import { DatePipe } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import {
  Component,
  Injectable,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { OAuthService } from 'angular-oauth2-oidc';
import { UserService } from 'projects/admin/src/app/admin-proxy/platform-app-management/rcm/platform-management/user/user.service';
import { TableService } from 'projects/shared/src/app/table.service';
import { DateValidator } from 'projects/shared/src/app/validations/date-validator';
import { Observable, Subscription } from 'rxjs';
import {
  debounceTime,
  filter,
  map,
  startWith,
  switchMap,
  tap,
} from 'rxjs/operators';
import Swal from 'sweetalert2';
import { PatientPersonalDTO, PatientPolicyDTO } from '../patient-proxy/patient-optimization/dto/models';
import { PatientPersonalService } from '../patient-proxy/patient-optimization/patient-personal.service';
import { LocaleConfig } from 'ngx-daterangepicker-material';
import moment, { Moment } from 'moment';
import { CookieService } from 'ngx-cookie-service';
// import { defaultGuid } from 'projects/admin/src/app/document-perviewer/document-perviewer.component';
import { ToastrService } from 'ngx-toastr';
import { MatOption } from '@angular/material/core';
import { MatSelect, MatSelectChange } from '@angular/material/select';
import { OrganizationUnitService } from 'projects/admin/src/app/admin-proxy/platform-app-management/rcm/platform-management/organization-units';
import { element } from 'protractor';
import { AddNoteTabComponent } from '../add-note-tab/add-note-tab.component';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { PatientCommonNotesComponent } from 'projects/shared/src/app/components/patient-common-notes/patient-common-notes.component';
import { ViewPatientLedgerComponent } from 'projects/admin/src/app/view-patient-ledger/view-patient-ledger.component';
import { DocumentSortingComponent } from 'projects/admin/src/app/document-sorting/document-sorting.component';
import { DocumentCheckListComponent } from 'projects/admin/src/app/document-check-list/document-check-list.component';
import { PatientAddPolicyTabComponent } from '../patient-add-policy-tab/patient-add-policy-tab.component';
import { PatientManualVerificationTabComponent } from '../patient-manual-verification-tab/patient-manual-verification-tab.component';
import { AssignDoNotCallReason } from '../patient-proxy/patient-optimization/assign-do-not-call-reason.enum';
import { MatDatepicker, MatDatepickerInputEvent } from '@angular/material/datepicker';
import { DataTableDirective } from 'angular-datatables';
import { NewVerificationComponent } from '../newVerification/newVerification.component';
import { defaultGuid } from 'projects/shared/src/app/enums/allenums.enum';

function _window(): any {
  return window;
}

@Injectable()
export class WindowRef {
  get nativeWindow(): any {
    return _window();
  }
}

@Component({
  selector: 'app-patient-search-tab',
  templateUrl: './patient-search-tab.component.html',
  styleUrls: ['./patient-search-tab.component.scss'],
  providers: [ListService, WindowRef, DatePipe],
})
export class PatientSearchTabComponent implements OnInit, OnDestroy {
  defaultGuid=defaultGuid;
  patientListDataArray : any;
  showDatePicker: boolean = false;
  isLoadingResults: boolean = false;
  selectedBranchList: string[] = null;
  isLoading: boolean;
  // PatientDetails: any;
  tblItemSelectedRowId: string;
  isShowProgress: boolean;
  private dataSubscription: Subscription;
  subscription$: Subscription[] = [];
  // patientSearchTable: string[] = [
  //   'patientName',
  //   'defaultPatientId',
  //   //'options',
  //   'saleOrder',
  //   'dob',
  //   'branch',
  //   'status',
  //   //'ssn',
  //   'email',
  //   //'mobile',
  //   'postalCode'
  // ];
  selectedRange: { startDate: Moment; endDate: Moment };
  ranges: any;
  minDate: Moment;
  maxDate: Moment;
  fromDate: any;
  selectedPatient: boolean = false;
  dtOptions: any;
  patientId: string;
  documentId: string;
  toDate: any;
  selectedOption = 'option1';
  calendarLocale: LocaleConfig;
  calendarPlaceholder: string = '';
  alwaysShowCalendars: boolean;
  dateRangStatus: boolean = false;
  isNewPatient: boolean = false;

  @ViewChild('picker') picker: MatDatepicker<Date>;
  // @ViewChild('datepickerInput') datepickerInput: ElementRef;

  @ViewChild('datepicker') datepicker: MatDatepicker<any>;
  PATIENTDATA: PatientSearchDTO[] = [];
  // dataSource: MatTableDataSource<any> = new MatTableDataSource<any>([]);
  // @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild(DataTableDirective, { static: false })
  datatableElement: DataTableDirective;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  personalForm: FormGroup;
  organizationUnitId = null;
  @ViewChild('selectBranch') selectBranch: MatSelect;
  @ViewChild('selectAllBranch') private selectAllBranch: MatOption;
  branchValue: string[] = [];
  public brnachList: Observable<Branch[]> | undefined;
  public drpBranch: Branch[] = [];
  tenantId: string = "";
  branchForm: FormGroup;
  organizationUnitName: string;
  chartId: string;
  callReason: any;

  constructor(
    private formBuilder: FormBuilder,
    private dialog: MatDialog,
    private router: Router,
    public list: ListService,
    public title: Title,
    public userProfileService: UserService,
    private tableService: TableService,
    private dateValidator: DateValidator,
    private oauthService: OAuthService,
    private toastr: ToastrService,
    private datepipe: DatePipe,
    private patientPersonalService: PatientPersonalService,
    private cookieService: CookieService,
    private branchService: OrganizationUnitService
  ) {
    this.calendarLocale = {
      customRangeLabel: 'Pick a date...',
      applyLabel: 'Apply',
      clearLabel: 'Clear',
      format: 'DD/MM/YYYY',
      daysOfWeek: ['su', 'mo', 'tu', 'we', 'th', 'fr', 'sa'],
      monthNames: [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Oct',
        'Nov',
        'Dec',
      ],
      firstDay: 1,
    };
    this.ranges = {
      Today: [moment(), moment()],
      Yesterday: [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
      // 'Current week': [moment().startOf('isoWeek'), moment().endOf('isoWeek')],
      // 'Next weekend': [this.getNextSaturday(), this.getNextSunday()],
      // 'Next 3 days': [moment().add(1, 'days'), moment().add(3, 'days')],
      'Last 7 Days': [moment().subtract(6, 'days'), moment()],
      'Last 30 Days': [moment().subtract(29, 'days'), moment()],
      // 'Current day': [moment(), moment()],
      // 'Next 2 days': [moment().add(1, 'days'), moment().add(2, 'days')],
      'This Month': [moment().startOf('month'), moment().endOf('month')],
      // 'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
    };



  }
  ngOnInit(): void {
    // this.dtOptions = {
    //   responsive: true,
    //   paging: false,
    //   scrollCollapse: true,
    //   scrollY: '500px',
    //   scrollX: true,
    //   info: false,
    //   dom: 'Bfrtip',
    //   buttons: [
    //     {
    //       extend: 'colvis',
    //       columns: [8, 9, 10,11]
    //     }
    //   ]
    // };

    // this.dtOptions = {
    //   responsive: true,
    //   dom: 'Bfrtip',
    //   columnDefs: [
    //     { targets: [0], orderable: false }, // Hide the first column (index 0)
    //   ],
    //   pageLength: 15,
    //   lengthMenu: [15, 30, 50, 100, 200],
    //   search: {
    //     smart: false, // Set to "none" for full exact match
    //   },
    //   buttons: [
    //     {
    //       extend: 'excel',
    //       text: 'Export', // Change the text to 'Export'
    //       filename: 'Patient List',
    //       // exportOptions: {
    //       //   columns: [2, 3, 4, 5, 6, 7, 8, 9],
    //       // },

    //     },
    //   ],
    // };

    this.showDatePicker = false;
    this.tenantId = localStorage.getItem("tenantId") ?? "";
    this.title.setTitle('Qsecure | Search Patient');
    //Personal Form
    this.personalForm = this.formBuilder.group({
      patientName: '',
      defaultPatientId: '',
      ssn: '',
      postalCode: '',
      branch: '',
      dob: new FormControl('', this.dateValidator.dateVaidator),
      email: '',
      mobile: '',
      status: '',
      colorCode: '',
    });
    const oauthEvents = this.oauthService?.events?.subscribe(
      (response: any) => {
        const tokenRefreshError = 'token_refresh_error';
        const tokenInvalidGrantError = 'invalid_grant';
        const refreshTokenNotFound = 'refresh_token_not_found';
        if (response?.type === tokenRefreshError) {
          // If auth error is invalid grant or refresh error or refresh token not found
          if (
            (response?.reason && response?.reason?.error) ||
            response?.reason?.error?.error === tokenInvalidGrantError ||
            response?.reason?.error?.error_reason === refreshTokenNotFound
          ) {
          }
        }
      }
    );
    this.getTableData();
    this.donotCallReason();
    this.branchForm = this.formBuilder.group({
      drpBranch: new FormControl(''),
      txtBranch: new FormControl(''),
    });

    this.getBranchList();
  }
  //After View Method
  // ngAfterViewInit(): void {
  //   // this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
  //   //   dtInstance.columns().every(function () {
  //   //     const that = this;
  //   //     $('input', this.footer()).on('keyup change', function () {
  //   //       if (that.search() !== this['value']) {
  //   //         that.search(this['value']).draw();
  //   //       }
  //   //     });
  //   //   });
  //   // });
  // }

  ngAfterViewInit(): void {
    if (this.datatableElement && this.datatableElement.dtInstance) {
      this.refreshDataTable();
    } else {
      // If dtInstance is not available yet, wait for a short time and try again
      setTimeout(() => this.refreshDataTable(), 500);
    }
  }

  ngOnDestroy(): void {
    // Unsubscribe to avoid memory leaks
    if (this.dataSubscription) {
      this.dataSubscription.unsubscribe();
    }
    this.subscription$?.forEach((sub) => {
      sub && sub?.unsubscribe();
    });
  }

  // ngOnDestroy(): void {
  //   this.subscription$?.forEach((sub) => {
  //     sub && sub?.unsubscribe();
  //   });
  // }

  refreshDataTable(): void {
    this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.columns().every(function () {
        const that = this;
        $('input', this.footer()).on('keyup change', function () {
          if (that.search() !== this['value']) {
            that.search(this['value']).draw();
          }
        });
      });
    });
  }

  tblItemSelectedRow(patientId: string, documentId: string, chartId: string) {
    this.selectedPatient = true
    this.tblItemSelectedRowId = this.tblItemSelectedRowId === patientId ? '' : patientId;
    this.patientId = patientId;
    this.documentId = documentId;
    this.chartId = chartId;
  }

  change() { }
  viewOrder(patientId: string) {
    this.cookieService.set('patientStatus', 'false');
    this.cookieService.set('OrderTab', 'true');
    this.router.navigate(['patientCreateEditTab/' + patientId]);
  }
  // viewOrder(patientId: string){
  //   this.cookieService.set('patientStatus','false');
  //   this.cookieService.set('OrderTab','true');
  //   this.router.navigate(['patientCreateEditTab/'+patientId])
  // }

  datesUpdateds(e) {
    if (e.startDate != null || e.endDate != null) {
      this.dateRangStatus = true;
      const startDate = new Date(e.startDate);
      const endDate = new Date(e.endDate);
      this.fromDate = this.datepipe.transform(startDate, 'MM/dd/yyyy');
      this.toDate = this.datepipe.transform(endDate, 'MM/dd/yyyy');
      this.getTableData();
    } else {
      this.dateRangStatus = false;
      (this.fromDate = null), (this.toDate = null);
      this.getTableData();
    }
  }

  choosedDate(e) { }
  getNextSaturday() {
    const dayINeed = 6; // for Saturday
    const today = moment().isoWeekday();
    if (today <= dayINeed) {
      return moment().isoWeekday(dayINeed);
    } else {
      return moment().add(1, 'weeks').isoWeekday(dayINeed);
    }
  }
  getNextSunday() {
    const dayINeed = 7; // for Sunday
    const today = moment().isoWeekday();
    if (today <= dayINeed) {
      return moment().isoWeekday(dayINeed);
    } else {
      return moment().add(1, 'weeks').isoWeekday(dayINeed);
    }
  }


  alphaOnly(event): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (
      charCode > 32 &&
      (charCode < 65 || charCode > 90) &&
      (charCode < 97 || charCode > 122)
    ) {
      return false;
    }
    return true;
  }

  // ! Document State Clear on New Patient
  newPatient() {
    this.isNewPatient = true;
    this.tableService.setDocumentFormData(null);
    this.cookieService.set('patientStatus', 'true');
    this.cookieService.set('OrderTab', 'false');
    this.router.navigate(['patientCreateEditTab']);
  }

  numberOnly(event): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  // To view Patient
  viewPatient(data: any) {
    if (data.patientId != undefined) {
      const storeSubscription =
        data.patientId !== '' &&
        data.patientId !== null &&
        data.patientId !== undefined &&
        this.patientPersonalService.get(data.patientId).subscribe(
          (response) => {
            const selectedPatient: PatientPersonalDTO = response;
            this.tableService.setCountryTable(selectedPatient);
          },
          (err) => {
            const data: HttpErrorResponse = err;
            Swal.fire({
              icon: 'info',
              text: data?.error?.error?.message,
            });
          }
        );
      this.subscription$.push(storeSubscription);
      this.tableService.setDocumentFormData(null);
      this.cookieService.set('patientStatus', 'false');
      this.cookieService.set('OrderTab', 'false');
      this.router.navigate(['/patientCreateEditTab/' + data.patientId + '/' + data.documentId]);
    }
  }
  viewPatientDocImg(data: any) {
    if (data.patientId != undefined) {
      const storeSubscription =
        data.patientId !== '' &&
        data.patientId !== null &&
        data.patientId !== undefined &&
        this.patientPersonalService.get(data.patientId).subscribe(
          (response) => {
            const selectedPatient: PatientPersonalDTO = response;
            this.tableService.setCountryTable(selectedPatient);
          },
          (err) => {
            const data: HttpErrorResponse = err;
            Swal.fire({
              icon: 'info',
              text: data?.error?.error?.message,
            });
          }
        );
      this.subscription$.push(storeSubscription);
      this.tableService.setDocumentFormData(null);
      this.cookieService.set('patientStatus', 'false');
      this.cookieService.set('OrderTab', 'false');
      this.router.navigate(['/patientCreateEditTab/' + data.patientId + '/' + data.documentId + '/' + 'Sorting']);
    }
  }


  // checkListModel() {
  //   const dialogRef = this.dialog.open(DocumentCheckListComponent, {
  //     height: '75%',
  //     width: '80%',
  //     disableClose: true,
  //   });
  //   const closeModal = dialogRef.afterClosed().subscribe(() => { });
  // }

  checkListModel(data) {
    const dialogRef = this.dialog.open(DocumentCheckListComponent, {
      // height: '75%',
      width: '80%',
      disableClose: true,
      data: {
        // Add any data you want to pass to the dialog here
        // For example, you can pass patientId and documentId

        documentId: data.documentId,
        faxId: data.faxId || 'N/A',
      },
    });

    // Subscribe to the closed event
    dialogRef.afterClosed().subscribe(() => {
      // Call the addNotes function directly
      dialogRef.componentInstance.addNotes();
    });
  }

  //To get the Table Data
  // getTableData() {
  //   const valueChanges = this.personalForm.valueChanges
  //     .pipe(
  //       startWith({
  //         patientName: '',
  //         defaultPatientId: '',
  //         ssn: '',
  //         postalCode: '',
  //         dob: '',
  //         email: '',
  //         mobile: '',
  //         status: '',
  //         colorCode: '',
  //       }),
  //       tap((x) => {
  //         this.isLoading = true;
  //         this.setTableData([]);
  //       }),
  //       filter((e) => e && this.personalForm?.valid),
  //       debounceTime(300),
  //       map((response) => {
  //         this.isLoading = true;
  //         const sValue = {
  //           patientName: response?.patientName?.trim()?.toLowerCase(),
  //           defaultPatientId: response?.defaultPatientId?.trim()?.toLowerCase(),
  //           ssn: response?.ssn?.trim()?.toLowerCase(),
  //           postalCode: response?.postalCode.trim().toLowerCase(),
  //           dob: response?.dob?.trim()?.toLowerCase(),
  //           email: response?.email?.trim()?.toLowerCase(),
  //           mobile: response?.mobile?.trim()?.toLowerCase(),
  //           status: response?.status?.trim()?.toLowerCase(),
  //           isInbound: 0,
  //           colorCode: response?.colorCode?.trim().toLowerCase(),
  //         };
  //         return sValue;
  //       }),
  //       switchMap((sValue) =>
  //         this.patientPersonalService.patientSearchBySDefaultPatientIdAndSPatientNameAndDtDOBAndSSSNAndSEmailIdAndSMobileAndSStatusAndFromDateAndToDate(
  //           sValue?.defaultPatientId,
  //           sValue?.patientName,
  //           sValue?.dob,
  //           sValue?.ssn,
  //           sValue?.email,
  //           sValue?.mobile,
  //           sValue?.status,
  //           '',
  //           '',
  //           sValue?.isInbound,
  //           sValue.postalCode,
  //           ''
  //         )
  //       )
  //     )
  //     .subscribe(
  //       (response) => {
  //         this.isLoading = false;
  //         // this.setTableData(response);
  //         this.PatientDetails=response;
  //       },
  //       (err) => {
  //         this.isLoading = false;
  //         const data: HttpErrorResponse = err;
  //         // Swal.fire({
  //         //   icon: 'error',
  //         //   text: data?.error?.error?.message,
  //         // });
  //       }
  //     );

  //   this.subscription$?.push(valueChanges);
  // }

  // getTableData() {
  //   // this.isLoading = true;
  //   // this.patientPersonalService.getPatientsByTenant(this.selectedBranchList).subscribe(response => {
  //   //   this.isLoading = false;
  //   //   this.PatientDetails = response;
  //   // }, error => {
  //   //   console.error('Error fetching pateint details:', error);
  //   //   this.isLoading = false;
  //   // });
  // }

  getTableData() {
    this.isLoading = true;
    try {
      this.dataSubscription =this.patientPersonalService.getPatientsByTenant(this.selectedBranchList).subscribe(
        (response) => {
          this.patientListDataArray = response;
        },
        (err) => {
          const data: HttpErrorResponse = err;
          Swal.fire({
            icon: 'info',
            text: data?.error?.error?.message,
          });
        },
        () => {
          // This block will be executed on both success and error
          this.isLoading = false;
        }
      );
    } catch (error) {
      console.error('Unexpected error:', error);
    } finally {
      setTimeout(() => this.refreshDataTable(), 500);
    }
  }

  claimSelect(e) {
    this.router.navigate(['patient/patientClaim/' + e]);
  }


  orderSelect(e) {
    this.router.navigate(['patient/patientOrders/' + e]);
  }
  //Set Table Data
  // setTableData(data: any[]) {
  //   this.dataSource = new MatTableDataSource(data);
  //   this.dataSource.sort = this.sort;
  //   this.dataSource.paginator = this.paginator;
  // }

  allBranchSelect() {
    if (this.selectAllBranch.selected) {
      this.selectBranch.options.forEach((item: MatOption) => item.select());
    }
    else {
      this.selectBranch.options.forEach((item: MatOption) => item.deselect());
    }
  }
  openAddNotesPopUp() {

    const config: MatDialogConfig = {
      disableClose: true,
      minHeight: '30vh',
      minWidth: '50vw',
      data: {
        // claimId: '00000000-0000-0000-0000-000000000000',
        noteType: 'patient', // 'Order Note',
        notesId: '00000000-0000-0000-0000-000000000000',
        // orderId: '0b50be04-6c75-92d3-97c6-3a0e17ee6a18',
        patientId: this.patientId,
        defaultFaxId: this.chartId,
        isCallNote: false,
        isPending: false,
        docId: this.documentId,
        inboundStatusShortCodeId: defaultGuid,
        selectedNode: defaultGuid,
        addnotes:true
      },
    };
    const dialogRef = this.dialog.open(AddNoteTabComponent, config);

    const closeDialog = dialogRef.afterClosed().subscribe(
      () => {
        // this.getReminderTableData();
      },
      (err) => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      }
    );
    this.subscription$.push(closeDialog);
  }
  openViewNotesPopup() {
    const config: MatDialogConfig = {
      disableClose: true,
      width: '70vw',
      maxWidth: '70vw',
      height: '40vw',
      maxHeight: '40vw',
      data: {
        patientId: this.patientId,
        pageType: 'Patient Note', // this.pageType, //Notes Table Popup Page
        isAddVisible: false,
      },
    };
    const dialogRef = this.dialog.open(PatientCommonNotesComponent, config);

    dialogRef.afterClosed().subscribe(
      () => {
        //this.getReminderTableData();
      },
      (err: HttpErrorResponse) => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      }
    );
  }
  onBranchSelectionChange(event: MatSelectChange): void {
    this.selectedBranchList = event.value;
    this.getTableData();
  }

  getBranchList() {
    const getBranchList = this.branchService.getBranchList(this.tenantId).subscribe(response => {
      response?.forEach(element => {
        this.drpBranch.push({
          id: element.id,
          organizationUnitName: element.organizationUnitName,
        })
      });

      this.brnachList = this.branchForm
        ?.get('txtBranch')
        ?.valueChanges?.pipe(
          startWith(''),
          map((value) =>
            this.drpBranch?.filter((option) =>
              option?.organizationUnitName
                ?.toLowerCase()
                ?.includes(value?.toLowerCase() ?? '')
            )
          )
        );


    }, err => {
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    });
    this.subscription$.push(getBranchList);
  }

  donotCallReason() {
    this.patientPersonalService.getdonotCallReason(1).subscribe(response => {
      this.callReason = response.items;

    }, error => {
      console.error('Error fetching call reason details:', error);
    });
  }
  viewDocument(
    patientId: string,
    documentId: string,
    defaultDocumentId: string,
    isSorted: boolean
  ) {

    const dialogRef = this.dialog.open(DocumentSortingComponent, {
      width: '100vw',
      height: '100vh',
      maxWidth: '100vw',
      maxHeight: '100vh',
      panelClass: 'fullscreen-dialog-panel',
      disableClose: true,
      data: {
        // Add any data you want to pass to the dialog here
        // For example, you can pass patientId and documentId
        patientId: patientId,
        documentId: documentId,
        defaultDocumentId: defaultDocumentId,
        isSorted: isSorted,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      // Handle the dialog close event here if needed

    });
  }
  viewPatientLedger(patientId: string) {
    this.dialog.open(ViewPatientLedgerComponent, {
      width: '100vw',
      height: '100vh',
      maxWidth: '100vw',
      maxHeight: '100vh',
      panelClass: 'fullscreen-dialog-panel',
      disableClose: true,
      data: {
        patientId: patientId,
      },
    });
    this.dialog.afterAllClosed.subscribe((resposne) => { });
  }
  newOrderTemplate() {
    this.router.navigate([
      'orders/saleorderCreateEditTab/871d2144-7dc0-755b-8672-3a0ebcd1e1d1/f7b95d68-5c6c-a099-f2fb-3a0ec19b7d67'
    ]);
  }
  ScheduleAppointmentModal(data) {

    this.tableService.setCalenderPopup(data)
    this.router.navigate(['settings/calender'])
    // this.CalenderScheduleComponent.onPopupOpen(data)
  }
  openVerificationModal(policy) {
    const dialogRef = this.dialog.open(PatientManualVerificationTabComponent, {
      disableClose: true,
      data: { patientId: policy.patientId, policyId: policy.policyId },
    });

    const closeDialog = dialogRef.afterClosed().subscribe((result) => {
      // result !== '' &&
      //   result !== null &&
      //   result !== undefined &&
      //   this.policyDialogRef.close(result);

      // this.policyId !== defaultGuid && this.getVerificationStatus();
    });
    this.subscription$.push(closeDialog);
  }

  ViewPolicy(policy: PatientPolicyDTO) {

    const dialogRef = this.dialog.open(PatientAddPolicyTabComponent, {
      data: {
        patientId: policy.patientId,
        policyId: policy.policyId,
        wipId: policy.wipId ?? defaultGuid,
      },
    });
    const aftClosed = dialogRef.afterClosed().subscribe(
      (result) => {
        // this.patientIdEmitter.next(this.patientId);

        // this.patientId !== defaultGuid && this.getTableData();
      },
      (err) => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      }
    );
    this.subscription$.push(aftClosed);
  }
  assignDoNotCall(patientId: string, reasonId: string) {
    this.patientPersonalService.assignDoNotCallReasonByPatientIDAndAssignDoNotCallReasonV1(patientId, reasonId)
      .subscribe(response => {
        this.toastr.success('Updated Successfully', 'Success');
      })
  }
  openDatepickers(months: number) {

    this.showDatePicker = true;
    this.picker['_openAsPopup']();
  }

  closeDatepicker() {
    this.showDatePicker = false;
  }

  onDatepickerClosed() {
    this.picker.close()
  }
  openDatepicker() {
    this.datepicker.open();
  }
  onDateSelected(event: MatDatepickerInputEvent<Date>) {
    // Additional logic when a date is selected


    // Close the datepicker programmatically after a date is selected
    this.picker.close();
  }
  openNewVerification(patientId:string,isOnline:boolean) {

    const config: MatDialogConfig = {
      disableClose: true,
      data:{
        patientId:patientId,
        isOnline:isOnline
      }
    };
    const dialogRef = this.dialog.open(NewVerificationComponent, config);

    const closeDialog = dialogRef.afterClosed().subscribe(
      () => {
        // this.getReminderTableData();
      },
      (err) => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      }
    );
    this.subscription$.push(closeDialog);
  }
}
export interface PatientSearchDTO {
  patientId?: string;
  defaultPatientId?: string;
  patientName?: string;
  dob?: string;
  ssn?: string;
  emailId?: string;
  mobile?: string;
  colorCode?: string;
  modifiedDate?: string;
}

export interface Branch {
  id?: string;
  organizationUnitName?: string;
}
