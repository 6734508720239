import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SharedTableService {
  arrTableData: BehaviorSubject<any[]> = new BehaviorSubject([]);
  constructor() { }

  getTableData(): Observable<any[]> {
    return this.arrTableData.asObservable();
  }
  setTableData(arrTableData: any[] = []) {
    this.arrTableData.next(arrTableData);
  }
}
