import { DatePipe } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { defaultGuid } from 'projects/shared/src/app/enums/allenums.enum';
import { Subscription } from 'rxjs';
import Swal from 'sweetalert2';
import { BillingDenialService } from '../billing-proxy/billing-management/rcm/billing-management/optimization/billing-reports/billing-denial.service';
import { CommonService } from 'projects/shared/src/app/services/common.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { BillingInvAmountDTO } from '../billing-proxy/billing-management/rcm/billing-management/optimization/dto/models';

@Component({
  selector: 'app-order-invoices',
  templateUrl: './order-invoices.component.html',
  styleUrls: ['./order-invoices.component.scss'],
})
export class OrderInvoicesComponent implements OnInit {
  billingInvoicesService: Subscription;
  orderInvoicesTable: any = {
    responsive: true,
    info: false,
    paging: false,
    dom: 'Bfrtip',
    scrollCollapse: true,
    scrollY: '500px',
    scrollX: true,
    search: {
      smart: false,
    },
    // language: {
    //   emptyTable: 'No records',
    //   info: 'Total : 4 records',
    //   infoEmpty: 'Total : 4 records',
    //   infoFiltered: '(filtered : _TOTAL_ records)',
    //   loadingRecords: 'Loading...',
    //   zeroRecords: 'No matching records',
    // },
    order: [],
    columnDefs: [{ targets: '_all', orderable: false }],
    buttons: [
      {
        extend: 'excel',
        text: 'Export',
        filename: 'Invoices',
        title: 'Order Invoices',
      },
    ],
  };
  selectedInvoice: any;
  orderId: string;
  isTableLoading: boolean = false;
  invoiceTableDataV1: any[] = [];

  chartNo: string;
  dateOfBirth: string;
  patientName: string;
  phoneNo: string;
  ssn: string;
  payerLevel: string;
  insuranceName: string;
  policyCode: string;
  groupNo: string;
  relationShip: string;
  dos: string;
  location: string;
  invoiceStatus: string;
  colorCode: string;
  orderNo: number;
  tblItemSelectedRowId: string;
  patientId: string;
  invoiceNo: string;
  constructor(
    private datepipe: DatePipe,
    private activatedRoute: ActivatedRoute,
    private title: Title,
    private billingDenialservice: BillingDenialService,
    private commonService: CommonService,
    private snackBar: MatSnackBar
  ) {}

  ngOnInit() {
    this.title.setTitle('Invoices | Qsecure');
    this.orderInvoicesTable.buttons[0].filename =
      'Order - Invoices' +
      '-' +
      this.datepipe.transform(new Date(), 'MM-dd-yyyy');
    this.activatedRoute.paramMap.subscribe(
      (response) => {
        this.orderId = response?.get('orderId') ?? defaultGuid;
      },
      (err) => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      }
    );
    this.fnGetBillingInvoicesV1();
  }


  updateTextColor(colorCode: string): string {
    if (this.isValidColorCode(colorCode)) {
      return this.getContrastingTextColor(colorCode);
    } else {
      return '#000000'; // default to black if colorCode is invalid
    }
  }

  isValidColorCode(colorCode: string): boolean {
    // Check if colorCode is a valid hex color code
    const regex = /^#([0-9A-F]{3}){1,2}$/i;
    return regex.test(colorCode);
  }

  getContrastingTextColor(hexColor: string): string {
    if (!hexColor) return '#000000'; // default to black if no color provided

    // Remove the hash if present
    hexColor = hexColor.replace('#', '');

    // Convert to RGB
    const r = parseInt(hexColor.substring(0, 2), 16);
    const g = parseInt(hexColor.substring(2, 4), 16);
    const b = parseInt(hexColor.substring(4, 6), 16);

    // Calculate luminance
    const luminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255;

    // If luminance is high, return dark color (black), otherwise return light color (white)
    return luminance > 0.5 ? '#000000' : '#FFFFFF';
  }



  fnGetBillingInvoicesV1() {
    this.isTableLoading = true;
    if (this.billingInvoicesService) {
      this.billingInvoicesService.unsubscribe();
    }
    this.billingDenialservice
      .getBillingInvoiceV1ByGOrderIdAndRentalId(this.orderId, defaultGuid)
      .subscribe(
        (response) => {
          this.invoiceTableDataV1 = response.invoiceDetails || [];
          this.fnSelectInvoice(this.invoiceTableDataV1[0]);
          this.chartNo = response?.patientDetails?.chartId || '-';
          this.orderNo = response?.patientDetails?.mmReferenceOrderId || 0;
          this.dateOfBirth = this.commonService.getFormattedDateZone(
            response?.patientDetails?.dob
          );
          this.patientName = response?.patientDetails?.fullName || '-';
          this.phoneNo = response?.patientDetails?.phoneNo || '-';
          this.ssn = response?.patientDetails?.ssn || '-';
          this.insuranceName =
            response?.invoiceDetails[0]?.insuranceDetails?.policyName || '-';
          this.payerLevel =
            response?.invoiceDetails[0]?.insuranceDetails?.payerLevel || '-';
          this.policyCode =
            response?.invoiceDetails[0]?.insuranceDetails?.policyNo || '-';
          this.groupNo =
            response?.invoiceDetails[0]?.insuranceDetails?.groupNo || '-';
          this.relationShip =
            response?.invoiceDetails[0]?.insuranceDetails?.relationship || '-';
          this.dos = this.commonService.getFormattedDateZone(
            response?.patientDetails?.dos
          );
          this.location = response?.patientDetails?.locationName || '-';
          this.invoiceStatus = response?.patientDetails?.invoiceStatus || '-';
          this.colorCode = response?.patientDetails?.colorCode || '-';
          this.patientId = response?.patientDetails?.patientId || defaultGuid;
          // this.orderId = response?.patientDetails?.orderId || defaultGuid;
          this.invoiceNo =
            response?.invoiceDetails[0]?.defaultInvoiceCode || '-';
          this.isTableLoading = false;
        },
        (err) => {
          this.isTableLoading = false;
          const data: HttpErrorResponse = err;
          Swal.fire({
            icon: 'info',
            text: data?.error?.error?.message,
          });
        }
      );
  }

  tblItemSelectedRow(billinginvoiceId: string) {
    this.tblItemSelectedRowId = billinginvoiceId;
  }

  onRowDoubleClick(textToCopy) {
    this.copyTextToClipboard(textToCopy);
    this.showNotification('Text copied: ' + textToCopy);
  }

  showNotification(message: string): void {
    this.snackBar.open(message, 'Close', {
      duration: 3000, // Duration in milliseconds
      horizontalPosition: 'end', // Positioning the notification horizontally
      verticalPosition: 'bottom', // Positioning the notification vertically
    });
  }

  copyTextToClipboard(text: string): void {
    const textArea = document.createElement('textarea');
    textArea.value = text;
    document.body.appendChild(textArea);
    textArea.select();
    document.execCommand('Copy');
    textArea.remove();
  }
  fnSelectInvoice(data: any) {
    if (data && data?.payorLevel !== 'Total') {
      this.selectedInvoice = data?.payorLevel;
      this.insuranceName = data?.insuranceDetails?.policyName || '-';
      this.payerLevel = data?.insuranceDetails?.payerLevel || '-';
      this.policyCode = data?.insuranceDetails?.policyNo || '-';
      this.groupNo = data?.insuranceDetails?.groupNo || '-';
      this.relationShip = data?.insuranceDetails?.relationship || '-';
      this.invoiceNo = data?.defaultInvoiceCode || '-';
    }
  }
  isEmpty(value: any): boolean {
    return (
      value === null ||
      value === undefined ||
      value === '' ||
      value === defaultGuid
    );
  }
}
