<mat-card class="ml-3">
  <mat-card-content>
    <div class="row">
      <div class="col-lg-12">
        <div *ngIf="lblDefaultOrderId" class="text-center">
          <span class="colored-heading text-heading  mb-0">SaleOrder Id - {{ lblDefaultOrderId }}</span>
        </div>

        <div class="text-center">
          <span class="colored-heading text-heading mb-0">Chart No - {{ lblDefaultPatientId }}</span>
        </div>
        <div class="text-center">
          <span class="colored-heading mb-0 text-content">Patient Name - {{ lblPatientName }}</span>
        </div>
        <div class="text-center">
          <span class="colored-heading mb-0 text-content">Branch - {{ lblPatientBranchName }}</span>
        </div>
        <div class="text-center">
          <span class="mb-0 text-right text-content">DOB </span>
          <span class="mb-0 text-center text-content">- </span>
          <span class="mb-0 text-left text-content">{{ lblPatientDOB }} - Age {{ lblPatientAge }}</span>
        </div>
        <div class="text-center">
          <span class="mb-0 text-right text-content"> Gender </span>
          <span class="mb-0 text-center text-content">-</span>
          <span class="mb-0 text-left">
            <ng-container [ngSwitch]="lblPatientGender">
              <span *ngSwitchCase="'F'" class="text-content"> Female</span>
              <span *ngSwitchCase="'M'" class="text-content"> Male</span>
              <span *ngSwitchCase="'U'" class="text-content"> Unknown</span>
            </ng-container>
          </span>
        </div>

        <div *ngIf="lblDefaultOrderId===''" class="text-center">
          <span style="font-size: larger mb-0"><h4 class="colored-heading blinking mb-0">Current Status -
            <ng-container [ngSwitch]="lblPtCurrentStatus">
              <ng-container *ngSwitchCase="'clinicalPending'">Clinical Pending </ng-container>
              <ng-container *ngSwitchCase="'policyPending'">Policy Pending </ng-container>
              <ng-container *ngSwitchCase="'verifyPending'">Verification Pending </ng-container>
              <ng-container *ngSwitchCase="'readyForOrder'">Ready For Order </ng-container>
              <ng-container *ngSwitchDefault>Ready For Intake </ng-container>
            </ng-container>
          </h4>
          </span>
        </div>
        <div *ngIf=" lblDefaultOrderId!==''" class="text-center">
          <span style="font-size: larger mb-0"><h4 class="colored-heading blinking mb-0">Current Status -
            <ng-container [ngSwitch]="lblPtCurrentStatus">
              <ng-container *ngSwitchCase="'completed'">Order Completed</ng-container>
              <ng-container *ngSwitchCase="'itemPending'">Item Pending</ng-container>
              <ng-container *ngSwitchCase="'orderVoided'">Order Voided</ng-container>
              <ng-container *ngSwitchCase="'authorizationPending'">Authorization Pending</ng-container>
              <ng-container *ngSwitchCase="'orderPending'">Order Pending</ng-container>
              <ng-container *ngSwitchDefault>Ready For Order</ng-container>
            </ng-container>
          </h4>
          </span>
        </div>
      </div>
    </div>
  </mat-card-content>
</mat-card>

<mat-card class="mt-3 ml-3">
  <mat-card-content>
    <div class="row">
      <div class="col-lg-12">
        <h2 class="colored-heading"><b>Billing Details</b></h2>
        <div class="text-center">
          <p class="mb-0 text-center"> Patient Balance</p>
          <p class="mb-0 text-center colored-heading mt-1"> {{lblPatientBalance | currency : "USD" : "symbol"}}</p>
        </div>
        <div class="table-responsive mt-2">
          <table class="table table-centered">
            <tbody>
              <tr>
                <td>
                  <p class="mb-0 text-center">Inurance Balance</p>
                  <p class="mb-0 text-center colored-heading mt-1"> {{lblInsuranceBalance | currency : "USD" : "symbol"}}</p>
                </td>
              </tr>
              <tr>
                <td>
                  <p class="mb-0 text-center">Recoop Amount</p>
                  <p class="mb-0 text-center colored-heading mt-1"> {{lblInsuranceRecoopAmount | currency : "USD" : "symbol"}}</p>
                </td>
              </tr>
              <tr>
                <td>
                  <p class="mb-0 text-center">Total Amount</p>
                  <p class="mb-0 text-center colored-heading mt-1"> {{lblTotalAmount | currency : "USD" : "symbol"}}</p>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </mat-card-content>
</mat-card>
<mat-card class="mt-3 ml-3">
  <mat-card-content>
    <div class="row">
      <div class="col-lg-12">
        <h2 class="colored-heading"><b>Age Wise Payment Details</b></h2>
        <div class="text-center mt-2">
          <div class="row">
            <div class="col-lg-6">
              <p class="mb-0 ml-3"><b>Days</b></p>
            </div>
            <div class="col-lg-6">
              <p class="mb-0"><b>Amount</b></p>
            </div>
          </div>
        </div>
        <div class="table-responsive mt-2">
          <table class="table table-centered" style="margin-top:0px;">
            <tbody class="text-center">
              <tr>
                <td>
                  <p class="mb-0 text-center">30</p>
                </td>
                <td>
                    <p class="mb-0 text-center">{{lblThirtyDays}}</p>
                </td>
              </tr>
              <tr>
                <td>
                  <p class="mb-0 text-center">60</p>
                </td>
                <td>
                    <p class="mb-0 text-center">{{lblSixtyDays}}</p>

                </td>
              </tr>
              <tr>
                <td>
                  <p class="mb-0 text-center">90</p>
                </td>
                <td>
                    <p class="mb-0 text-center">{{lblNinetyDays}}</p>

                </td>
              </tr>
              <tr>
                <td>
                  <p class="mb-0 text-center">Above 90</p>
                </td>
                <td>
                    <p class="mb-0 text-center">{{lblAboveNinetyDays}}</p>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </mat-card-content>
</mat-card>
