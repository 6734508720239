<div class="table-responsive" *ngIf="activeTabBin === MyQueueClaimStatusText.ClaimSubmission">
  <table #tableContainer datatable [dtOptions]="dtDocumentClaimTableOptions"
    class="row-border hover w-100 display dataTables_scroll">
    <thead>
      <tr>
        <th><mat-icon>apps</mat-icon></th>
        <th [matTooltip]="'Order No'" class="text-right">Order No</th>
        <th [matTooltip]="'Chart Id'">Chart Id</th>
        <th [matTooltip]="'Patient'">Patient</th>
        <th [matTooltip]="'Provider Name'">Provider Name</th>
        <th [matTooltip]="'DOS'">DOS</th>
        <th [matTooltip]="'Total Billed($)'" class="text-right">Total Billed($)</th>
        <th [matTooltip]="'Status'">Status</th>
        <th [matTooltip]="'Insurance Rank'">Insurance Rank</th>
        <th [matTooltip]="'Insurance Name'">Insurance Name</th>
        <th [matTooltip]="'Branch'">Branch</th>
        <th [matTooltip]="'Total Allowed($)'" class="text-right">Total Allowed($)</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let document of inBoundTableData">

        <td class="no-select">
          <mat-icon class="pointer toggle-icon" (click)="getTotalInboundData($event, document)">add</mat-icon>

          <mat-icon style="cursor: pointer !important" [matMenuTriggerFor]="menu">more_vert</mat-icon>
          <mat-menu class="custom-mat-menu" #menu="matMenu">
            <button (click)="openViewNotesPopup(document.patientId,document.defaultFaxId,document.docId)"
              [disabled]="isEmpty(document.patientId)" mat-menu-item>
              View Patient Notes
            </button>
            <button mat-menu-item (click)="viewPatientLedger(document?.patientId)"
              [disabled]="isEmpty(document.patientId)">
              View Patient Ledger
            </button>
          </mat-menu>
        </td>

        <td class="text-right" (dblclick)="onRowDoubleClick(document?.mmRefereceOrderId || '-')"
          [matTooltip]="document?.mmRefereceOrderId || '-'">
          <a [class.disabled]="
            isEmpty(document.mmRefereceOrderId) || isEmpty(document.patientId)
          " [routerLink]="
            '/orders/saleOrder/' +
            document.patientId +
            '/' +
            document.mmOrderId +
            '/' +
            1
          " target="_blank" class="preview-link">
            {{ document?.mmRefereceOrderId || '-'}}
          </a>
        </td>

        <td (dblclick)="onRowDoubleClick(document?.chartNo || '-')" [matTooltip]="document?.chartNo || '-'">
          <a [class.disabled]="isEmpty(document.chartNo) || isEmpty(document.patientId)" [routerLink]="
            '/patientCreateEditTab/' + document.patientId
          " target="_blank" class="preview-link">
            {{ document?.chartNo || '-'}}
          </a>
        </td>
        <td (dblclick)="onRowDoubleClick(document?.fullName || '-')" [matTooltip]="document?.fullName || '-'">
          {{ document?.fullName || '-'}}
        </td>
        <td (dblclick)="onRowDoubleClick(document?.providerName || '-')" [matTooltip]="document?.providerName || '-'">
          {{ document?.providerName || '-'}}
        </td>
        <td (dblclick)="onRowDoubleClick(document?.dos || '-')" [matTooltip]="document?.dos || '-'">
          {{ document?.dos || '-'}}
        </td>
        <td class="text-right" (dblclick)="onRowDoubleClick(document.totalBilled != null ? document.totalBilled : '-')"
          [matTooltip]="document.totalBilled != null ? document.totalBilled : '-'">
          {{ document.totalBilled != null ? document.totalBilled : '-' }}
        </td>
        <td (dblclick)="onRowDoubleClick(document?.status || '-')" [matTooltip]="document?.status || '-'">
          {{ document?.status || '-'}}
        </td>


        <td>
          {{ document?.payorlevel || '-'}}
        </td>
        <td>
          {{ document?.insuranceName || '-'}}
        </td>
        <td>
          {{ document?.location || '-'}}
        </td>
        <td>
          {{ document?.totalAllowed || '-'}}
        </td>
      </tr>
    </tbody>
  </table>
</div>


<div class="table-responsive" *ngIf="activeTabBin === MyQueueClaimStatusText.AutoPosting">
  <table #tableContainer datatable [dtOptions]="dtDocumentClaimAutoPostingTableOptions"
    class="row-border hover w-100 display dataTables_scroll">
    <thead>
      <tr>
        <th><mat-icon>apps</mat-icon></th>
        <th [matTooltip]="'Order No'">Check No</th>
        <th [matTooltip]="'Order Date'">Check Date</th>
        <th [matTooltip]="'Chart Id'">Chart Id</th>
        <th [matTooltip]="'Patient'">Patient</th>
        <th [matTooltip]="'Claim Id'">Claim Id</th>
        <th [matTooltip]="'Rank'">Rank</th>
        <th [matTooltip]="'Status'">Status</th>
        <th [matTooltip]="'Posting Method'">Posting Method</th>
        <th [matTooltip]="'Billed($)'" class="text-right">Billed($)</th>
        <th [matTooltip]="'Paid($)'" class="text-right">Paid($)</th>
        <th [matTooltip]="'Pending($)'" class="text-right">Pending($)</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let document of inBoundTableData">

        <td class="no-select">
          <mat-icon class="pointer toggle-icon" (click)="getTotalInboundData($event, document)">add</mat-icon>

          <mat-icon style="cursor: pointer !important" [matMenuTriggerFor]="menu">more_vert</mat-icon>
          <mat-menu class="custom-mat-menu" #menu="matMenu">
            <button (click)="openViewNotesPopup(document.patientId,document.defaultFaxId,document.docId)"
              [disabled]="isEmpty(document.patientId)" mat-menu-item>
              View Patient Notes
            </button>
            <button mat-menu-item (click)="viewPatientLedger(document?.patientId)"
              [disabled]="isEmpty(document.patientId)">
              View Patient Ledger
            </button>
          </mat-menu>
        </td>

        <td (dblclick)="onRowDoubleClick(document?.checkNumber || '-')" [matTooltip]="document?.checkNumber || '-'">
          <a class="preview-link pointer" (click)="previewDocumet(document)" [class.disabled]="isEmpty(document.checkNumber) || isEmpty(document.blobName)"  >
          {{ document?.checkNumber || '-'}}
          </a>
        </td>

        <td (dblclick)="onRowDoubleClick(document?.checkDate || '-')" [matTooltip]="document?.checkDate || '-'">
          {{ document?.checkDate || '-'}}
        </td>
        <td (dblclick)="onRowDoubleClick(document?.chartId)" [matTooltip]="document?.chartId || '-'">
          <a [class.disabled]="isEmpty(document.chartId) || isEmpty(document.patientId)" [routerLink]="
            '/patientCreateEditTab/' + document.patientId
          " target="_blank" class="preview-link">
            {{ document?.chartId || '-'}}
          </a>
        </td>
        <td (dblclick)="onRowDoubleClick(document?.patientName || '-')" [matTooltip]="document?.patientName || '-'">
          {{ document?.patientName || '-'}}
        </td>
        <td (dblclick)="onRowDoubleClick(document?.defaultClaimId || '-')"
          [matTooltip]="document?.defaultClaimId || '-'">
          <a class="pointer preview-link" [class.disabled]="
                                                isEmpty(document?.defaultClaimId) ||
                                                isEmpty(document.patientId) ||
                                                isEmpty(document.claimId)
                                              " (click)="
                                                openClaimForm(
                                                    document.orderId,
                                                    document.patientId,
                                                    document.chartId,
                                                    document.patientName,
                                                    document?.orderTicketId,
                                                    document.claimId,
                                                  true,
                                                  document?.isResubmitEnabled
                                                )
                                              ">
            {{ document?.defaultClaimId || '-'}}
          </a>
        </td>
        <td (dblclick)="onRowDoubleClick(document?.sPlanType || '-')" [matTooltip]="document?.sPlanType || '-'">
          {{ document?.sPlanType || '-'}}
        </td>
        <td (dblclick)="onRowDoubleClick(document?.claimStatusName || '-')"
          [matTooltip]="document?.claimStatusName || '-'">
          {{ document?.claimStatusName || '-'}}
        </td>
        <td (dblclick)="onRowDoubleClick(document?.postingMethod || '-')" [matTooltip]="document?.postingMethod || '-'">
          {{ document?.postingMethod || '-'}}
        </td>
        <td class="text-right" (dblclick)="onRowDoubleClick(document.billedAmount != null ? document.billedAmount : '-')"
          [matTooltip]="document.billedAmount != null ? document.billedAmount : '-'">
          {{ document.billedAmount != null ? document.billedAmount : '-'}}
        </td>
        <td class="text-right" (dblclick)="onRowDoubleClick(document.paidAmount != null ? document.paidAmount : '-')"
          [matTooltip]="document.paidAmount != null ? document.paidAmount : '-'">
          {{ document.paidAmount != null ? document.paidAmount : '-'}}
        </td>
        <td class="text-right" (dblclick)="onRowDoubleClick(document.pendingAmount != null ? document.pendingAmount : '-')"
          [matTooltip]="document.pendingAmount != null ? document.pendingAmount : '-'">
          {{ document.pendingAmount != null ? document.pendingAmount : '-'}}
        </td>
      </tr>
    </tbody>
  </table>
</div>


<div class="table-responsive" *ngIf="activeTabBin === MyQueueClaimStatusText.ClaimPosting">
  <table #tableContainer datatable [dtOptions]="dtDocumentClaimPostingTableOptions"
    class="row-border hover w-100 display dataTables_scroll">
    <thead>
      <tr>
        <th><mat-icon>apps</mat-icon></th>
        <th [matTooltip]="'Order No'" class="text-right">Order No</th>
        <th [matTooltip]="'Chart Id'">Chart Id</th>
        <th [matTooltip]="'Patient'">Patient</th>
        <th [matTooltip]="'Batch No'">Batch No</th>
        <th [matTooltip]="'Billed Quantity'" class="text-right">Billed Quantity</th>
        <th [matTooltip]="'Net Total($)'" class="text-right">Net Total($)</th>
        <th [matTooltip]="'Total($)'" class="text-right">Total($)</th>
        <th [matTooltip]="'Paid($)'" class="text-right">Paid($)</th>
        <th [matTooltip]="'Balance($)'" class="text-right">Balance($)</th>
        <th [matTooltip]="'Current Balance($)'" class="text-right">Current Balance($)</th>
        <th [matTooltip]="'Waystar Status'">Waystar Status</th>
        <th [matTooltip]="'Status'">Status</th>

        <th>Item Total($)</th>
        <th>Write Off Amount($)</th>
        <th>Recoup($)</th>
        <th>Check No</th>
        <th>Payer Name</th>
        <th>Posting Count</th>
        <th>Clearing House</th>
        <th>Processed Date</th>
        <th>Provider Name</th>
        <th>Settled Status</th>
        <th>Aging Days</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let document of inBoundTableData">

        <td class="no-select">
          <mat-icon class="pointer toggle-icon" (click)="getTotalInboundData($event, document)">add</mat-icon>

          <mat-icon style="cursor: pointer !important" [matMenuTriggerFor]="menu">more_vert</mat-icon>
          <mat-menu class="custom-mat-menu" #menu="matMenu">
            <button (click)="openViewNotesPopup(document.patientId,document.defaultFaxId,document.docId)"
              [disabled]="isEmpty(document.patientId)" mat-menu-item>
              View Patient Notes
            </button>
            <button mat-menu-item (click)="viewPatientLedger(document?.patientId)"
              [disabled]="isEmpty(document.patientId)">
              View Patient Ledger
            </button>
          </mat-menu>
        </td>

        <td class="text-right" (dblclick)="onRowDoubleClick(document?.mmRefOrderId)"
          [matTooltip]="document?.mmRefOrderId || '-'">
          <a [class.disabled]="
              isEmpty(document.mmRefOrderId) || isEmpty(document.patientId)
            " [routerLink]="
              '/orders/saleOrder/' +
              document.patientId +
              '/' +
              document.orderId +
              '/' +
              1
            " target="_blank" class="preview-link">
            {{ document?.mmRefOrderId || '-'}}
          </a>
        </td>

        <td (dblclick)="onRowDoubleClick(document?.chartId)" [matTooltip]="document?.chartId || '-'">
          <a [class.disabled]="isEmpty(document.chartId) || isEmpty(document.patientId)" [routerLink]="
              '/patientCreateEditTab/' + document.patientId
            " target="_blank" class="preview-link">
            {{ document?.chartId || '-'}}
          </a>
        </td>
        <td (dblclick)="onRowDoubleClick(document?.patientName)" [matTooltip]="document?.patientName || '-'">
          {{ document?.patientName || '-'}}
        </td>
        <td (dblclick)="onRowDoubleClick(document?.defaultBatchId)" [matTooltip]="document?.defaultBatchId || '-'">
          {{ document?.defaultBatchId || '-'}}
        </td>
        <td class="text-right" (dblclick)="onRowDoubleClick(document?.billedQuantity ? document.billedQuantity : 0)"
          [matTooltip]="document?.billedQuantity ? document.billedQuantity : 0">
          {{ document?.billedQuantity ? document.billedQuantity : 0 }}
        </td>
        <td class="text-right" (dblclick)="onRowDoubleClick(document?.dos)" [matTooltip]="document?.netTotal || '-'">
          {{ document?.netTotal ? document?.netTotal : 0}}
        </td>
        <td>
          <div class="row">

            <!-- *ngIf="claimData?.allowedAmount > 0" -->
            <div class="text-contentV1 p-0 col-12">
              <mat-label [matTooltip]="'Allowable:' + document?.allowedAmount || 0"
                class="cus-mat-label-shippingFee  rounded ">
                &#160;Allowable:<span class="sf">{{
                  document?.allowedAmount || 0
                  }}</span>&#160;
              </mat-label>
            </div>
            <!-- *ngIf="claimData?.totalAccessory > 0" -->
            <div class="text-contentV1  p-0 col-12 ">
              <mat-label [matTooltip]="
              'Accessory:' + document?.totalAccessory || 0
            " class="cus-mat-label-shippingFee  rounded">
                &#160;Accessory:<span class="sf">{{
                  document?.totalAccessory || 0
                  }}</span>&#160;
              </mat-label>
            </div>
            <!-- *ngIf="claimData?.shippingFee > 0" -->
            <div class=" text-contentV1 col-12 p-0">
              <mat-label [matTooltip]="
              'Shipping Fee:' + document?.shippingFee || 0
            " class="cus-mat-label-shippingFee  rounded">
                &#160;Shipping Fee:<span class="sf">{{
                  document?.shippingFee || 0
                  }}</span>&#160;
              </mat-label>
            </div>
          </div>
        </td>
        <td>
          <div class="row">

            <!-- *ngIf="claimData?.allowedAmount > 0" -->
            <div class="text-contentV1 p-0 col-12">
              <mat-label [matTooltip]="'Total:' + document?.totalPaidAmount || 0"
                class="cus-mat-label-shippingFee  rounded ">
                &#160;Total:<span class="sf">{{
                  document?.totalPaidAmount || 0
                  }}</span>&#160;
              </mat-label>
            </div>
            <!-- *ngIf="claimData?.totalAccessory > 0" -->
            <div class="text-contentV1  p-0 col-12 " style="text-align: left !important;">
              <mat-label class="cus-mat-label-shippingFee  rounded" style="text-align: left;">
                <img [matTooltip]="'Recoup:$' + (document?.recoopAmount || 0)" class="img-responsive"
                  src="assets/Patient Menu Icons/OverPay.png" alt="" height="18" />
                &#160;Insurance:<span class="sf">{{
                  document?.insuranceAmount || 0
                  }}</span>&#160;
                <!-- <button
            class="custom-mainmenu-btn pt-1 color-black"
            mat-menu-item
           [matTooltip]="claimData?.recoopAmount || 0"
          > -->

                <!-- </button> -->
              </mat-label>

            </div>
            <!-- *ngIf="claimData?.shippingFee > 0" -->
            <div class=" text-contentV1 col-12 p-0">
              <mat-label [matTooltip]="
              'Patient:' + (document?.patientRespPaidAmount || 0)
            " class="cus-mat-label-shippingFee  rounded">
                &#160;Patient:<span class="sf">{{
                  document?.patientRespPaidAmount || 0
                  }}</span>&#160;
              </mat-label>
            </div>
          </div>
        </td>
        <td>
          <div class="row">

            <!-- *ngIf="claimData?.allowedAmount > 0" -->
            <div class="text-contentV1 p-0 col-12">
              <mat-label [matTooltip]="'Total:' + (document?.totalBalanceAmount || 0)"
                class="cus-mat-label-shippingFee  rounded ">
                &#160;Total:<span class="sf">{{
                  document?.totalBalanceAmount || 0
                  }}</span>&#160;
              </mat-label>
            </div>
            <!-- *ngIf="claimData?.totalAccessory > 0" -->
            <div class="text-contentV1  p-0 col-12 ">
              <mat-label [matTooltip]="
              'Insurance:' + (document?.insuranceBalance || 0)
            " class="cus-mat-label-shippingFee  rounded">
                &#160;Insurance:<span class="sf">{{
                  document?.insuranceBalance || 0
                  }}</span>&#160;
              </mat-label>
            </div>
            <!-- *ngIf="claimData?.shippingFee > 0" -->
            <div class=" text-contentV1 col-12 p-0">
              <mat-label [matTooltip]="
              'Patient:' + (document?.patientRespPendingAmount || 0)
            " class="cus-mat-label-shippingFee  rounded">
                &#160;Patient:<span class="sf">{{
                  document?.patientRespPendingAmount || 0
                  }}</span>&#160;
              </mat-label>
            </div>
          </div>
        </td>
        <td class="text-right" (dblclick)="onRowDoubleClick(document?.claimCurrentBalance)"
          [matTooltip]="document?.claimCurrentBalance || '-'">
          {{ document?.claimCurrentBalance ? document?.claimCurrentBalance : 0}}
        </td>
        <td (dblclick)="onRowDoubleClick(document?.waystarStatus)" [matTooltip]="document?.waystarStatus || '-'">
          {{ document?.waystarStatus || '-'}}
        </td>
        <!-- <td (dblclick)="onRowDoubleClick(document?.claimStatusName)" [matTooltip]="document?.claimStatusName || '-'">
          {{ document?.claimStatusName || '-'}}
        </td> -->
        <td class="text-center">
          <div class="d-flex align-items-center justify-content-between">
            <mat-icon
              class="text-danger pt-1 f-15 pointer"
              mat-icon-button
              [hidden]="
                !document.isResubmitEnabled ||
                document.isResubmitEnabled == null
              "
              customToolTip
              (mouseover)="showError(document?.claimId)"
              [contentTemplate]="template"
              >info</mat-icon
            >
            <div
              class="w-25"
              [hidden]="
                !(
                  !document.isResubmitEnabled ||
                  document.isResubmitEnabled == null
                )
              "
            ></div>
            <span
              [matTooltip]="document?.claimStatusName || '-'"
              [style.color]="updateTextColor(document?.colorCode)"
              [style.backgroundColor]="document?.colorCode"
              class="rounded-border ml-1 w-75 p-2 claim-status-label"
            >
              {{ document?.claimStatusName || "-" }}
            </span>
          </div>
        </td>


        <td >
          {{ document?.billedAmount ? document.billedAmount : 0 }}
        </td>
        <td>
          {{ document?.writeOffAmount ? document.writeOffAmount : 0 }}
        </td>
        <td >
          {{ document?.recoopAmount ? document.recoopAmount : 0}}
        </td>
        <td>
          {{ document?.chequeNumber || '-'}}
          <div>({{ document?.checkDate || '-'}})</div>
        </td>
        <td >
          {{ document?.payerName || '-'}}
        </td>
        <td>
          {{ document?.postingCount ? document.postingCount : 0}}
        </td>
        <td>
          {{ document?.clearingHouse || '-'}}
        </td>
        <td>
          {{ document?.processedDate || '-'}}
        </td>
        <td>
          {{ document?.providerName || '-'}}
        </td>
        <td>
          {{ document?.isCCSettled || "Unsettled"}}
        </td>
        <td>
          {{ document?.agingDays || '-'}}
        </td>
      </tr>
    </tbody>
  </table>
  <ng-template #template>
    <mat-spinner
      *ngIf="spinnerEnabled"
      class="spinner-border-sm"
      diameter="20"
    ></mat-spinner>
    <div
      style="display: flex; flex-direction: column"
      *ngIf="ltErrors.length > 0 && !spinnerEnabled"
    >
      <p class="text-danger p-1 mb-0 error-popup bolder">Error in Claim Form</p>
      <mat-divider></mat-divider>
      <ng-container *ngFor="let data of ltErrors">
        <p class="mb-0 p-1 error-popup">
          {{ data.serialNumber }}.{{ data.error }}
        </p>
        <mat-divider></mat-divider>
      </ng-container>
    </div>
    <div
      style="display: flex; flex-direction: column"
      *ngIf="ltErrors.length == 0 && !spinnerEnabled"
    >
      <p class="mb-0 p-1 error-popup">No Error Data Found</p>
    </div>
  </ng-template>
</div>

<div class="table-responsive" *ngIf="activeTabBin === MyQueueClaimStatusText.ClaimChecklist">
  <table #tableContainer datatable [dtOptions]="dtDocumentClaimchecklistTableOptions"
    class="row-border hover w-100 display dataTables_scroll">
    <thead>
      <tr>
        <th><mat-icon>apps</mat-icon></th>
        <th [matTooltip]="'Check No'">Check No</th>
        <th [matTooltip]="'Check Amount($)'" class="text-right">Check Amount($)</th>
        <th [matTooltip]="'Claim Number'">Claim Number</th>
        <th [matTooltip]="'Chart No'">Chart No</th>
        <th [matTooltip]="'Patient Name'">Patient Name</th>
        <th [matTooltip]="'Check Date'">Check Date</th>

        <th>Clearing House</th>
        <th>Posting Date</th>
        <th>Billed Amount($)</th>
        <th>Paid Amount($)</th>
        <th>Current Balance($)</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let document of inBoundTableData">

        <td class="no-select">
          <mat-icon class="pointer toggle-icon" (click)="getTotalInboundData($event, document)">add</mat-icon>

          <mat-icon style="cursor: pointer !important" [matMenuTriggerFor]="menu">more_vert</mat-icon>
          <mat-menu class="custom-mat-menu" #menu="matMenu">
            <button (click)="openViewNotesPopup(document.patientId,document.defaultFaxId,document.docId)"
              [disabled]="isEmpty(document.patientId)" mat-menu-item>
              View Patient Notes
            </button>
            <button mat-menu-item (click)="viewPatientLedger(document?.patientId)"
              [disabled]="isEmpty(document.patientId)">
              View Patient Ledger
            </button>
          </mat-menu>
        </td>

        <td (dblclick)="onRowDoubleClick(document?.checkNumber)" [matTooltip]="document?.checkNumber || '-'">
          <a class="preview-link pointer" (click)="previewDocumet(document)" [class.disabled]="isEmpty(document.checkNumber) || isEmpty(document.blobName)">
            {{ document?.checkNumber || '-'}}
          </a>
        </td>


        <td class="text-right" (dblclick)="onRowDoubleClick(document?.chequeAmount)"
          [matTooltip]="document?.chequeAmount || '0'">
          {{ document?.chequeAmount || '0'}}
        </td>
        <!-- isEmpty(document.orderId) || -->
        <td (dblclick)="onRowDoubleClick(document?.defaultClaimId)" >
          <a class="preview-link pointer" matTooltip="{{ document.defaultClaimId || '-' }}" [class.disabled]="
            isEmpty(document.defaultClaimId) ||

            isEmpty(document.patientId) ||
            isEmpty(document.mmRefOrderId) ||
            isEmpty(document.claimId)
          " (click)="
            openClaimForm(
              document.orderId,
              document.patientId,
              document.chartId,
              document.patientName,
              document.mmRefOrderId,
              document.claimId,
              true,
              document.isResubmitEnabled
            )
          ">
            {{ document?.defaultClaimId || '-'}}
          </a>
        </td>
        <td (dblclick)="onRowDoubleClick(document?.chartId)" [matTooltip]="document?.chartId || '-'">
          <a [class.disabled]="isEmpty(document.chartId) || isEmpty(document.patientId)" [routerLink]="
              '/patientCreateEditTab/' + document.patientId
            " target="_blank" class="preview-link">
            {{ document?.chartId || '-'}}
          </a>
        </td>
        <td (dblclick)="onRowDoubleClick(document?.patientName)" [matTooltip]="document?.patientName || '-'">
          {{ document?.patientName || '-'}}
        </td>
        <td (dblclick)="onRowDoubleClick(document?.chequeDate)" [matTooltip]="document?.chequeDate || '-'">
          {{ document?.chequeDate || '-'}}
        </td>




        <td >
          {{ document?.clearingHouse || '-'}}
        </td>
        <td >
          {{ document?.postingDate || '-'}}
        </td>
        <td>
          {{ document.billedAmount != null ? document.billedAmount  : '-'}}
        </td>
        <td>
          {{ document.insurancePaid != null ? document.insurancePaid  : '-'}}
        </td>
        <td >
          {{ document.currentBalance != null ? document.currentBalance  : '-'}}
        </td>
      </tr>
    </tbody>
  </table>
</div>
