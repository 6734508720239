import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ReturnOrderUpdateComponent } from '../return-order-update/return-order-update.component';

@Component({
  selector: 'app-rejected-order-update',
  templateUrl: './rejected-order-update.component.html',
  styleUrls: ['./rejected-order-update.component.scss']
})
export class RejectedOrderUpdateComponent implements OnInit {

  shippingRejectedHide: boolean;
  returnRejectedHide: boolean;

  constructor(
    public dialogRef: MatDialogRef<ReturnOrderUpdateComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { data: any, rejectedReason: any, status: any },

  ) { }

  ngOnInit(): void {
    if (this.data.status == "Shipping Rejected"){
      this.shippingRejectedHide = true
    }
    else if (this.data.status == "Shipping Return Rejected"){
      this.returnRejectedHide = true
    }
  }

}
