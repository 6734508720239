import { HttpErrorResponse } from '@angular/common/http';
import {
  AfterViewInit,
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Button, ButtonComponent } from '@syncfusion/ej2-angular-buttons';
import {
  ActionEventArgs,
  AgendaService,
  CellClickEventArgs,
  DayService,
  DragAndDropService,
  EventClickArgs,
  EventRenderedArgs,
  EventSettingsModel,
  FieldModel,
  MonthService,
  ResizeService,
  Schedule,
  ScheduleComponent,
  WeekService,
  WorkWeekService,
} from '@syncfusion/ej2-angular-schedule';
import { DashboardService } from 'projects/admin/src/app/admin-proxy/platform-app-management/rcm/platform-management/dashboard/dashboard.service';
import { UserService } from 'projects/admin/src/app/admin-proxy/platform-app-management/rcm/platform-management/user/user.service';
import { CreateUpdateCalendarDTO } from 'projects/on-time-schedules/src/app/onTime-proxy/rcm/on-time-management/on-time-schedule/dto/models';
import { OnTimeMasterDropdownDTO } from 'projects/patient/src/app/dropdown-proxy/dropdown-management/dropdowns/dto/on-time-schedule/models';
import { OnTimeMasterDropdownService } from 'projects/patient/src/app/dropdown-proxy/dropdown-management/dropdowns/master-dropdown-service/on-time-master-dropdown.service';
import Swal from 'sweetalert2';
import { DropDownList } from '@syncfusion/ej2-dropdowns';
import { createElement, extend, closest, remove, addClass } from '@syncfusion/ej2-base';
import { ChangeEventArgs } from '@syncfusion/ej2-angular-inputs';
import { OnTimeDropdowns } from 'projects/patient/src/app/dropdown-proxy/dropdown-management/dropdowns/on-time-dropdowns.enum';
import { Observable, of, Subscription } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { Title } from '@angular/platform-browser';
import { TableService } from 'projects/shared/src/app/table.service';
import { PatientPersonalService } from 'projects/patient/src/app/patient-proxy/patient-optimization';
import { OrganizationUnitService } from 'projects/admin/src/app/admin-proxy/platform-app-management/rcm/platform-management/organization-units';
import { FilteringEventArgs } from '@syncfusion/ej2-angular-dropdowns';
import { DatePipe } from '@angular/common';
import {
  calender_status_color_code,
  calender_status_code,
  defaultGuid,
} from 'projects/shared/src/app/enums/allenums.enum';
import { InboundDocumentService } from 'projects/admin/src/app/admin-proxy/platform-app-management/rcm/platform-management/inbound/inbound-document.service';
import { InventoryTransferStatusService } from 'projects/inventory/src/app/item-proxy/item-management/optimization/inventory-transfer-status.service';
import { MmOrderService } from 'projects/order/src/app/order-proxy/order-management/order-optimization/mm-order.service';
import { map, startWith } from 'rxjs/operators';
import { ModeOfCommunicationService } from 'projects/admin/src/app/admin-proxy/platform-app-management/rcm/platform-management/communication-mode';
import { PagedAndSortedResultRequestDto, PagedResultDto, isNullOrUndefined } from '@abp/ng.core';
import { MultiSelect } from '@syncfusion/ej2-dropdowns';
import { RequiredValidator } from '@angular/forms';
import { CalenderScheduleAddModalComponent } from '../calender-schedule-add-modal/calender-schedule-add-modal.component';
@Component({
  selector: 'app-calender-schedule',
  templateUrl: './calender-schedule.component.html',
  styleUrls: ['./calender-schedule.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    DayService,
    WeekService,
    WorkWeekService,
    MonthService,
    AgendaService,
    ResizeService,
    DragAndDropService,
  ],
})
export class CalenderScheduleComponent
  implements OnInit, AfterViewInit, OnDestroy {
  defaultGuid = defaultGuid;
  @ViewChild('scheduleObj', { static: true })
  public scheduleObj: ScheduleComponent;
  userId: string = '';
  roleId: string = '';
  userName: string = '';
  roleName: string = '';
  calenderList: any[] = [];
  userTableData: any[] = [];
  show: boolean = true;
  public selectedDate: Date = new Date();
  public eventSettings: EventSettingsModel = {
    dataSource: this.getTableData(),
    fields: {
      subject: { name: 'Subject', validation: { required: true } },
      startTime: { name: 'StartTime', validation: { required: true } },
      endTime: { name: 'EndTime', validation: { required: true } },
    }
  };
  public roomDataSource: Object[] = this.userTableData;

  public resourcelevel2: any;
  tenantId: string = '';
  isSetupInHome: any;
  //! calender popup open to add event
  patientDet: any[] = [];
  ModeDet: any[] = [];
  QGuid: string = '';
  startEnd: string = '';
  calenderId: string = '';
  calenderDetailId: string = '';
  assignedById: string = '';
  assignedByName: string = '';
  assignedToId: string = '';
  assignedToName: string = '';
  branchDet: any[];
  techDet: any[];
  typeDet: any[];
  userDet: any[];
  resourceDet: any[];
  templateDrp: any[];
  isValidHomeNumber: boolean = true;
  isValidCellNumber: boolean = true;
  isValidWorkNumber: boolean = true;
  isValidEmail: boolean = true;
  // isValidFrom = true;
  patientIdViaURL: string;
  patientId: string;
  docId: string;
  branchId: string;
  techId: string;
  typeId: string;
  userDesID: string;
  // modeID: any[] = ["55943a54-643f-6fa6-b0df-3a12270400dd"];
  modeID: any;
  resourceId: string;
  templateId: string;
  templateName: string;
  home: string;
  cell: string;
  work: string;
  email: string;
  setupInHome: boolean;
  subscription$: Subscription[] = [];
  selectedOption: Object;
  patientChartApiSubscription: Subscription;
  communicationApiSubscription: Subscription;
  templateApiSubscription: Subscription;
  drpModeList: any;
  lstFilterModeList: any;
  modedrp: any;
  templateDropDownList: DropDownList;
  selectedTemplateId:string='';
  selectedTemplateName:string='';

  constructor(
    private adminService: DashboardService,
    public dialog: MatDialog,
    public title: Title,
    private PatientPersonalService: PatientPersonalService,
    private onTimeDropdown: OnTimeMasterDropdownService,
    private branchService: OrganizationUnitService,
    private activatedRoute: ActivatedRoute,
    private inboundDocumentService: InboundDocumentService,
    private tranferStatusService: InventoryTransferStatusService,
    private userService: UserService,
    private mmOrderService: MmOrderService,
    private modeOfCommunicationService: ModeOfCommunicationService,
  ) { }
  ngAfterViewInit(): void { }

  ngOnInit() {
    // this.scheduleObj.allowDragAndDrop = false;
    // this.scheduleObj.allowResizing = false;
    this.tenantId = localStorage.getItem('tenantId') ?? '';
    this.adminService.userProfile().subscribe((response) => {
      this.userId = response?.userId;
      this.userName = response?.userName;
    });
    const activatedRoute = this.activatedRoute.paramMap.subscribe(
      (response) => {
        this.patientId = response.get('patientId');
        this.patientIdViaURL = response.get('patientId');
        this.docId = response.get('documentId') || defaultGuid;
        if (this.patientIdViaURL !== null) {
          this.getSelectedPatientDetailsV1(this.patientIdViaURL);
        }
      }
    );
    this.loadBrachDropdown();
    this.getStausDropDown();
    // this.getPatient();
    this.getPatient('');
    this.getTech();
    this.getType();
    this.getResource();
    this.getUserByTenant();
    this.getCommuncationMode();
    this.title.setTitle('Qsecure | Calender');
    // this.getTemplate([1]);
  }
  ngOnDestroy(): void {
    this.subscription$.forEach((x) => x.unsubscribe());
  }
  formatDate(date: Date): string {
    const datePipe = new DatePipe('en-US');
    return datePipe.transform(date, 'yyyy-MM-dd HH:mm');
  }

  loadBrachDropdown() {
    const getBranchList = this.branchService
      .getBranchList(this.tenantId)
      .subscribe((response) => {
        // this.branchDet = response;
        this.branchDet = [];
        response?.forEach((e) => {
          this.branchDet?.push({
            name: e?.organizationUnitName,
            id: e?.id,
          });
        });
      });
  }

  //GET Mode of Communication  DROP DOWN
  getCommuncationMode() {
    if (this.communicationApiSubscription) {
      this.communicationApiSubscription.unsubscribe();
    }
    this.communicationApiSubscription = this.modeOfCommunicationService
      .getList(new PagedAndSortedResultRequestDto())
      .subscribe(
        (response) => {
          this.ModeDet = response.items.map((e) => ({
            name: e.modeDescription,
            id: e.mode,
          }));
          this.modedrp = [...this.ModeDet]
        },
        (err) => { }
      );
  }

  //GET Template DROP DOWN
  getTemplate(modeId: number[]) {
    // this.templateDropDownList.clear();
    // this.templateDropDownList.value = null;
    this.templateDropDownList.dataSource=[];
    if (this.templateApiSubscription) {
      this.templateApiSubscription.unsubscribe();
    }
    this.templateApiSubscription = this.inboundDocumentService
      .getListSMSTemplateId(modeId)
      .subscribe(
        (response) => {
          this.templateDrp = response.items.map((e) => ({
            name: e.templateCode,
            id: e.template,
          }));
          this.refreshTemplateDropdown();
        },
        (err) => {
          const data: HttpErrorResponse = err;
          // Swal.fire({
          //   icon: 'info',
          //   text: data?.error?.error?.message,
          // });
          this.templateDrp = [];
          this.refreshTemplateDropdown();
        }
      );
  }

  refreshTemplateDropdown() {
    this.templateDropDownList.dataSource = [...this.templateDrp];
    this.templateDropDownList.refresh();
  }

  /// dropdown datas
  getStausDropDown() {
    let onTimeDropdownInput: OnTimeDropdowns[];
    onTimeDropdownInput = [OnTimeDropdowns.CalendarStatus];
    this.onTimeDropdown
      .getOnTimeDropdownsByInput(onTimeDropdownInput)
      .subscribe((stateResponse) => {
        let response: OnTimeMasterDropdownDTO = stateResponse;
        this.userTableData = [];
        response &&
          response?.calendarStatuses.forEach((element) => {
            // this.userTableData = response.calendarStatuses
            this.userTableData?.push({
              OwnerText: element?.calendarStatus,
              shortCodeId: element?.shortCodeId,
              Id: element?.calendarStatusId,
              Color: element?.categoryColor || '#20b2aa'
            });
          });

        // Sort the userTableData array based on shortCodeId
        this.userTableData.sort((a, b) => a.shortCodeId - b.shortCodeId);
        this.roomDataSource = this.userTableData;
      });

    return this.userTableData;
  }
  //! calender table datas list as a view page
  public getTableData(): Record<string, any>[] {
    const eventData: Record<string, any>[] = [];
    this.userId = localStorage.getItem('userId') ?? '';
    this.roleId = localStorage.getItem('roleId') ?? '';
    this.roleName = localStorage.getItem('roleName') ?? '';
    this.inboundDocumentService
      .getCalendarByUserId(this.userId)
      .subscribe((response) => {
        response &&
          response?.items?.forEach((elements) => {
            elements?.calendarDetails?.forEach((element) => {
              eventData?.push({
                EndTime: new Date(element?.toTime),
                StartTime: new Date(element?.fromTime),
                Subject: element?.title,
                Id: element?.calendarId,
                QGuid: elements?.id,
                Guid: element?.guid,
                Description: element?.description,
                StartTimeZone: element?.startTimeZone,
                EndTimeZone: element?.endTimeZone,
                IsAllDay: element?.isAllDay,
                Location: element?.locationId,
                email: element?.email,
                setupInHome: element?.setUpInHome,
                RecurrenceRule: element?.recurrenceRule,
                userName: elements?.userName,
                userId: elements?.userId,
                tech: element?.userID,
                user: element?.userID,
                type: element?.type,
                resource: element?.resource,
                cell: element?.cellNo,
                phone: element?.homePhoneNo,
                work: element?.workNo,
                OwnerId: element?.status,
                roleId: elements?.roleId,
                calendarDetailsId: element?.calendarDetailsId,
                status: element?.status,
                colorCode: element?.colorCode,
                roleName: elements?.roleName,
                isDragAndDrop: true,
                Patient: element?.patientId,
                assignedById: element?.assignedById,
                assignedByName: element?.assignedByName,
                assignedToId: elements?.assignedToId,
                assignedToName: elements?.assignedToName,
                mode: elements?.modeCode,
                templateId: elements?.templateCode,
              });
            });
          });

        this.scheduleObj?.refreshEvents();
      });
    // this.scheduleObj?.addEvent(eventData);

    return eventData;
  }

  //! reschedule color
  // applyCategoryColor(args: EventRenderedArgs): void {
  //   let colorCode: string = args.data.colorCode as string;
  //   args.element.style.backgroundColor = colorCode;
  //   if (!args.element || !colorCode) {
  //     return;
  //   }
  //   if (this.scheduleObj?.currentView === 'Agenda') {
  //     (args.element.firstChild as HTMLElement).style.borderLeftColor =
  //       colorCode;
  //   } else {
  //     args.element.style.backgroundColor = colorCode;
  //   }

  //   if (args.element && args.element.classList.contains('e-month-view')) {
  //     // Customize the event details here
  //     const eventDetails = args.element.querySelector('.e-subject');
  //     if (eventDetails) {
  //       // Example: Show only the event title (assuming the title and identifier are separated by a semicolon)
  //       const titleAndIdentifier = eventDetails.innerHTML;
  //       const title = titleAndIdentifier.split(';')[0].trim();
  //       eventDetails.innerHTML = title;
  //     }
  //   }
  // }

  applyCategoryColorV0(args: EventRenderedArgs): void {
    // // Apply category color
    let colorCode: string = args.data.colorCode as string;
    if (colorCode) {
      args.element.style.backgroundColor = colorCode;

      // if (this.scheduleObj?.currentView === 'Agenda') {
      //   (args.element.firstChild as HTMLElement).style.borderLeftColor =
      //     colorCode;
      // }

      //   // Check if the current view is Agenda or Day
      //   if (this.scheduleObj?.currentView === 'Agenda' || this.scheduleObj?.currentView === 'Day') {
      //     (args.element.firstChild as HTMLElement).style.borderLeftColor = colorCode;
      // } else {
      //     // For other views, apply the background color directly
      //     args.element.style.backgroundColor = colorCode;
      // }
    }

    // // Customize event details in Month view
    // if (args.element && args.element.classList.contains('e-month-view')) {
    //   const eventDetails = args.element.querySelector('.e-subject');
    //   if (eventDetails) {
    //     // Example: Show only the event title (assuming the title and identifier are separated by a semicolon)
    //     const titleAndIdentifier = eventDetails.innerHTML;
    //     const title = titleAndIdentifier.split(';')[0].trim();
    //     eventDetails.innerHTML = title;
    //   }
    // }

    // if (args.viewType === 'Month') {
    //   // Customize the event details here
    //   const eventDetails = args.element.querySelector('.e-subject');
    //   if (eventDetails) {
    //     // Example: Show only the event title (assuming the title and identifier are separated by a semicolon)
    //     const titleAndIdentifier = eventDetails.innerHTML;
    //     const title = titleAndIdentifier.split(';')[0].trim();
    //     eventDetails.innerHTML = title;
    //   }
    // }

    // Check if the current view is Month
    if (this.scheduleObj && this.scheduleObj.currentView === 'Month') {
      // Customize the event details here
      const eventDetails = args.element.querySelector('.e-subject');
      if (eventDetails) {
        // Example: Show only the event title (assuming the title and identifier are separated by a semicolon)
        const titleAndIdentifier = eventDetails.innerHTML;
        const title = titleAndIdentifier.split(';')[0].trim();
        eventDetails.innerHTML = title;
      }
    }

    //  Apply category color based on some condition (customize this part)
    let color = this.colorCode('Scheduled');
    // eventDetails.style.backgroundColor = color;
    args.element.style.backgroundColor = color;
  }

  applyCategoryColorV1(args: EventRenderedArgs): void {
    let categoryColor: string = args.data.colorCode as string;
    if (!args.element || !categoryColor) {
      return;
    }
    if (this.scheduleObj.currentView === 'Agenda') {
      (args.element.firstChild as HTMLElement).style.borderLeftColor = categoryColor;
    } else {
      args.element.style.backgroundColor = categoryColor;
    }

    // Check if the current view is Month
    if (this.scheduleObj && (this.scheduleObj.currentView === 'Month' || this.scheduleObj.currentView === 'Agenda')) {
      // Customize the event details here
      const eventDetails = args.element.querySelector('.e-subject');
      if (eventDetails) {
        // Example: Show only the event title (assuming the title and identifier are separated by a semicolon)
        const titleAndIdentifier = eventDetails.innerHTML;
        const title = titleAndIdentifier.split(';')[0].trim();
        eventDetails.innerHTML = title;
      }
    }
  }

  applyCategoryColor(args: EventRenderedArgs): void {
    let categoryColor: string = args.data.colorCode as string;
    if (!args.element || !categoryColor) {
      return;
    }

    if (this.scheduleObj.currentView === 'Agenda') {
      // Customize the style for Agenda view
      (args.element.firstChild as HTMLElement).style.borderLeftColor = categoryColor;
    } else {
      // Customize the style for other views
      args.element.style.backgroundColor = categoryColor;
    }

    // Check if the current view is 'Agenda'
    if (this.scheduleObj.currentView === 'Agenda') {
      // Find the event subject and location elements
      const eventSubject = args.element.querySelector('.e-subject');
      const eventLocation = args.element.querySelector('.e-location');

      // Check if both subject and location elements exist
      if (eventSubject && eventLocation) {
        // Remove the location element from the event display
        eventLocation.remove();
      }

      // Get the text content of the subject element and remove the last comma if it exists
      const subjectText = eventSubject?.textContent?.trim().replace(/,$/, '');
      // Update the subject element's text content with the modified text (without the last comma)
      if (subjectText) {
        eventSubject.textContent = subjectText;
      }
    }

    if (this.scheduleObj.currentView === 'Month') {
      // Customize the event details here for Agenda and Month views
      const eventDetails = args.element.querySelector('.e-subject');
      if (eventDetails) {
        const titleAndIdentifier = eventDetails.innerHTML;
        const title = titleAndIdentifier.split(';')[0].trim();
        eventDetails.innerHTML = title;
      }
    }


    // Check if the current view is 'Month'
    if (this.scheduleObj.currentView === 'Week' || this.scheduleObj.currentView === 'Day' || this.scheduleObj.currentView === 'WorkWeek') {
      // Find the event container and then find the location element within it
      const eventContainer = args.element.querySelector('.e-appointment-details');
      const eventLocationMonth = eventContainer?.querySelector('.e-location');

      // Check if the location element exists in the month view within the container
      if (eventLocationMonth) {
        // Remove the location element from the event display in the month view
        eventLocationMonth.remove();
      }
    }


  }

  getSelectedPatientDetailsV1(patientId: string) {
    if (patientId !== null && patientId !== undefined && patientId !== '') {
      this.inboundDocumentService
        .getScheduleDetailsByPatientID(patientId)
        .subscribe(
          (response) => {
            this.patientId = response.patientId || '';
            this.branchId = response.locationId || '';
            this.techId = response.tech || '';
            this.typeId = response.type || '';
            this.resourceId = response.resource || '';
            this.home = response?.homePhoneNo ?? '';
            this.cell = response?.cellNo ?? '';
            this.work = response?.workNo ?? '';
            this.email = response?.email ?? '';
            // this.docId = response?.docId || defaultGuid;
            this.setupInHome = response?.setUpInHome ?? false;
          },
          (error) => {
            console.error('Error fetching patient details:', error);
            // this.docId = defaultGuid;
          }
        );
    }
  }

  getSelectedPatientDetails(patientId: string): Observable<any> {
    return new Observable((observer) => {
      if (patientId !== null && patientId !== undefined && patientId !== '') {
        this.inboundDocumentService
          .getScheduleDetailsByPatientID(patientId)
          .subscribe(
            (response) => {
              this.patientId = response.patientId || '';
              this.branchId = response.locationId || '';
              this.techId = response.tech || '';
              this.typeId = response.type || '';
              this.resourceId = response.resource || '';
              this.home = response?.homePhoneNo ?? '';
              this.cell = response?.cellNo ?? '';
              this.work = response?.workNo ?? '';
              this.email = response?.email ?? '';
              // this.docId = response?.docId || defaultGuid;
              this.setupInHome = response?.setUpInHome ?? false;

              // this.docId = response?.docId || defaultGuid;
              // observer.next(); // Notify the promise that the operation is complete
              // observer.complete();
            },
            (error) => {
              console.error('Error fetching patient details:', error);
              // this.docId = defaultGuid;
              observer.next(); // Notify the promise that the operation is complete
              observer.complete();
            }
          );
      }
      observer.next(); // Notify the promise that the operation is complete
      observer.complete();
    });
  }

  // getPatient() {
  //   ///patient link dropdown
  //   const patientSearchSub$ = of(null)
  //     .pipe(
  //       switchMap((x) => this.PatientPersonalService.getPatientForCalenderV1())
  //     )
  //     .subscribe((response) => {
  //       this.patientDet = [];
  //       response?.forEach((e, i) => {
  //         this.patientDet?.push({
  //           name: e?.patientDetails,
  //           id: e?.patientId,
  //           chartId: e.chartId,
  //         });
  //       });
  //     });
  // }

  getPatient(value: any) {

    if (this.patientChartApiSubscription) {
      this.patientChartApiSubscription.unsubscribe();
    }
    this.patientChartApiSubscription = this.mmOrderService
      .getPatientChartNoV3BySSearchParamsAndPatient(value, this.patientId)
      .subscribe(
        (response) => {


          this.patientDet = [];
          response?.forEach((e, i) => {
            this.patientDet?.push({
              name: e?.ddValue,
              id: e?.patientId,
              chartId: e.chartId,
            });
          });

          return this.patientDet;
        },
        (err) => { }
      );
  }

  getPatientV1(
    value: any
  ): Observable<{ name: string; id: string; chartId: string }[]> {
    if (this.patientChartApiSubscription) {
      this.patientChartApiSubscription.unsubscribe();
    }
    return new Observable((observer) => {
      this.patientChartApiSubscription = this.mmOrderService
        .getPatientChartNoV3BySSearchParamsAndPatient(value, this.patientId)
        .subscribe(
          (response) => {


            const patientDet: { name: string; id: string; chartId: string }[] =
              [];
            response?.forEach((e, i) => {
              patientDet.push({
                name: e?.ddValue,
                id: e?.patientId,
                chartId: e.chartId,
              });
            });
            observer.next(patientDet);
            observer.complete();
          },
          (err) => {
            observer.error(err);
          }
        );
    });
  }

  getTech() {
    ///Tech link dropdown
    // const TechSearchSub$ = of(null)
    //   .pipe(
    //     switchMap((x) => this.tranferStatusService.ServiceTypeDetails())
    //   )
    //   .subscribe((response) => {
    //     this.techDet = [];
    //     response?.items.forEach((e) => {
    //       this.techDet?.push({
    //         name: e.productCategoryName,
    //         id: e?.id,
    //       });
    //     });
    //   });

    this.techDet = [];
    this.techDet?.push({
      name: 'Tech',
      id: defaultGuid,
    });
  }

  //GET USER DROP DOWN
  getUserByTenant() {
    const getUserList$ = of(null)
      .pipe(switchMap((x) => this.userService.getUsersByTenant()))
      .subscribe((response) => {
        this.userDet = [];
        response?.forEach((e) => {
          this.userDet?.push({
            name: e.userDesignation,
            id: e?.userId,
          });
        });
        this.userDet.sort((a, b) => a.name.localeCompare(b.name));
      });

    // const getUserList = this.userService
    // .getUsersByTenant()
    // .subscribe(
    //   (response) => {
    //     response?.forEach((element) => {
    //       this.userDet.push({
    //         userId: element.userId,
    //         userDesignation: element.userDesignation,
    //       });
    //     });

    //   },
    //   (err) => {
    //     const data: HttpErrorResponse = err;
    //     Swal.fire({
    //       icon: 'info',
    //       text: data?.error?.error?.message,
    //     });
    //   }
    // );
  }
  getType() {
    ///Type link dropdown

    const TypeSearchSub$ = of(null)
      .pipe(switchMap((x) => this.tranferStatusService.ServiceTypeDetails()))
      .subscribe((response) => {
        this.typeDet = [];
        response?.items.forEach((e) => {
          this.typeDet?.push({
            name: e.productCategoryName,
            id: e?.id,
          });
        });
        // Sort the typeDet array by the 'name' property
        this.typeDet.sort((a, b) => a.name.localeCompare(b.name));
      });
  }

  getResource() {
    ///Tech link dropdown
    // const ResourceSearchSub$ = of(null)
    //   .pipe(
    //     switchMap((x) => this.PatientPersonalService.getPatientForCalenderV1())
    //   )
    //   .subscribe((response) => {
    //     let i = 1;
    //     this.resourceDet = [];
    //     response?.forEach((e) => {
    //       this.resourceDet?.push({
    //         name: 'Moniter Medical',
    //         id: defaultGuid,
    //       });
    //       i++;
    //     });
    //   });
    this.resourceDet = [];
    this.resourceDet?.push({
      name: 'Moniter Medical',
      id: defaultGuid,
    });
  }
  homeInputEle: HTMLInputElement;

  onPopupOpen(args) {
    // console.log(args?.data?.Id);
    // console.log(args?.data?.calendarDetailsId);
    // console.log(args?.data?.QGuid);
    this.assignedById = args?.data?.assignedById;
    this.assignedByName = args?.data?.assignedByName;
    this.assignedToId = args?.data?.assignedToId;
    this.assignedToName = args?.data?.assignedToName;
    this.startEnd =
      args.data.EndTime ??
      'Tue Nov 28 2023 00:00:00 GMT+0530 (India Standard Time)';
    this.calenderDetailId = args.data.calendarDetailsId ?? null;

    // button disable for past date
    if (
      args?.data?.EndTime < new Date() &&
      args?.data?.StartTime !== '' &&
      args?.data?.Subject == undefined &&
      args?.data?.startTime !== '' &&
      args?.data?.EndTime !== ''
    ) {
      if (args.type === 'QuickInfo' || args.type === 'Editor') {
        args.cancel = true;
      }
    }
    if(args?.data?.mode !== "" && args?.data?.mode !== null && args?.data?.mode !== undefined){
      this.getTemplate(args?.data?.mode);
    }

    if (args.type === 'Editor') {
      let statusElement: HTMLInputElement = args.element.querySelector(
        '#EventType'
      ) as HTMLInputElement;
      let dropDownListObject: DropDownList = new DropDownList({
        placeholder: 'Choose status',
        value: statusElement?.value,
        dataSource: [
          { text: 'Day', value: 'Day' },
          { text: 'Week', value: 'Week' },
          { text: 'WorkWeek', value: 'WorkWeek' },
          { text: 'Month', value: 'Month' },
        ],
      });
      dropDownListObject.appendTo(statusElement);

      // Clear existing custom field row if it exists
      const existingCustomFieldRow =
        args.element.querySelector('.custom-field-row');
      if (existingCustomFieldRow) {
        existingCustomFieldRow.remove();
      }

      // if (!args.element.querySelector('.custom-field-row')) {
      let row: HTMLElement = createElement('div', {
        className: 'custom-field-row',
      });

      let formElement: HTMLElement =
        args.element.querySelector('.e-schedule-form');
      let titleLocationRow: HTMLElement = args.element.querySelector(
        '.e-title-location-row'
      );

      // Insert the custom field row after the last default field
      let lastDefaultField: HTMLElement =
        formElement.lastElementChild as HTMLElement;
      formElement.insertBefore(row, lastDefaultField.nextSibling);

      // Add Branch Dropdown
      //#region
      let branchContainer: HTMLElement = createElement('div', {
        className: 'custom-field-container',
      });
      let branchInputEle: HTMLInputElement = createElement('input', {
        className: 'e-field',
        attrs: { name: 'Branch' },
      }) as HTMLInputElement;
      branchContainer.appendChild(branchInputEle);
      row.appendChild(branchContainer);
      let branchDropDownList: DropDownList = new DropDownList({
        dataSource: this.branchDet,
        fields: { text: 'name', value: 'id' },
        // value: args.data.branchId || this.branchId,
        // value: '395ca6b0-9d02-94b2-4d0c-3a0f9e40a596',
        value: args.data.Location || this.branchId || '',
        floatLabelType: 'Always',
        placeholder: 'Branch Name',
        allowFiltering: true,
        filterBarPlaceholder: 'Search',
        filtering: (e: FilteringEventArgs) => {
          const filterText = e.text.toLowerCase();
          e.updateData(
            this.branchDet.filter((branch) => {
              const branchDetails = (branch.name || '').toLowerCase();
              return branchDetails.includes(filterText);
            })
          );
        },
      });
      branchDropDownList.appendTo(branchInputEle);
      branchInputEle.setAttribute('name', 'branchId');
      //#endregion

      // Add Patient Dropdown
      //#region

      // // if (!args.data.patientId) args.data.patientId = this.patientId;
      let patientContainer: HTMLElement = createElement('div', {
        className: 'custom-field-container',
      });
      let inputEle: HTMLInputElement = createElement('input', {
        className: 'e-field',
        attrs: { name: 'Patient', required: 'true' },
        // , placeholder: "Please type the patient's name; it will only show if it exists."
      }) as HTMLInputElement;
      patientContainer.appendChild(inputEle);
      // document.body.appendChild(inputEle);

      row.appendChild(patientContainer);
      // Assuming 'row' is the parent element that exists in the DOM when the page loads
      row.addEventListener('focusin', (event) => {
        const target = event.target as HTMLElement;
        if (target.tagName === 'INPUT' && target.getAttribute('name') === 'Patient') {

          // Clear existing loaded details and trigger a new search
          patientDropDownList.dataSource = []; // Clear existing data
          patientDropDownList.dataBind();
          // Perform a new search or fetch initial data
          this.getPatientV1('').subscribe(
            (patientDet) => {

              patientDropDownList.dataSource = patientDet;
              patientDropDownList.dataBind();
            },
            (err) => {
              console.error('Error fetching patient data:', err);
            }
          );
        }
      });
      let patientDropDownList: DropDownList = new DropDownList({
        dataSource: this.patientDet,
        fields: { text: 'name', value: 'id' },
        // value: (<{ [key: string]: Object }>args.data).Patient as string,
        // value: args.data.patientId || this.patientIdViaURL,
        value: args.data.Patient || this.patientIdViaURL || '',
        floatLabelType: 'Always',
        placeholder: 'Patient Name ',
        // placeholder: "Please type the patient's name; it will only show if it exists.",
        allowFiltering: true,
        filterBarPlaceholder: 'Search',
        filtering: (e: FilteringEventArgs) => {
          const filterText = e.text.toLowerCase();

          if (filterText === '') {
            // Filter text is empty, load all data
            patientDropDownList.dataSource = this.patientDet;
            patientDropDownList.dataBind();
          } else {
            // Filter text is present, call the API
            this.getPatientV1(filterText).subscribe(
              (patientDet) => {

                patientDropDownList.dataSource = patientDet;
                patientDropDownList.dataBind();
              },
              (err) => {
                console.error('Error fetching patient data:', err);
              }
            );
          }

          // e.updateData(
          //   this.patientDet.filter((patient) => {
          //     const patientDetails = (patient.name || '').toLowerCase();

          //     return patientDetails.includes(filterText);
          //   })
          // );
        },
        change: (e: ChangeEventArgs) => {
          if (e.value === null) {
            // this.getSelectedPatientDetailsV1()
          } else {
            this.getSelectedPatientDetailsV1(String(e.value));
          }
          // this.patientId = String(e.value);
          // this.getSelectedPatientDetails(String(e.value));
          // Update args.data.patientId if needed
          // args.data.patientId = e.value as string;
        },
      });
      patientDropDownList.appendTo(inputEle);
      // inputEle.addEventListener('focus', () => {

      //   patientDropDownList.dataSource = this.patientDet; // Set initial data
      //   patientDropDownList.dataBind();
      // });

      inputEle.setAttribute('name', 'patientId');


      //           // Get the select element by its name
      // const patientSelect = document.querySelector('select[name="Patient"]') as HTMLSelectElement;

      // // Add event listener for the 'focus' event
      // patientSelect.addEventListener('focus', (event) => {
      //   // Trigger your desired action here when the dropdown gains focus

      //   // You can call a function, update UI, or perform any other action when the dropdown is focused
      // });

      // const patientSelectId = document.querySelector('select[name="patientId"]') as HTMLSelectElement;

      // // Add event listener for the 'focus' event
      // patientSelectId.addEventListener('focus', (event) => {
      //   // Trigger your desired action here when the dropdown gains focus

      //   // You can call a function, update UI, or perform any other action when the dropdown is focused
      // });



      // // Add focus event listener to input element
      // inputEle.addEventListener('focus', () => {

      //   // Load full data when the input is focused
      //   patientDropDownList.dataSource = this.patientDet;
      //   patientDropDownList.dataBind();
      // });

      //       // Add focus event listener to input element
      // inputEle.addEventListener('focus', () => {

      // });

      // // Trigger focus manually for testing
      // inputEle.focus();



      //       // Create patient container
      // let patientContainer: HTMLElement = createElement('div', {
      //   className: 'custom-field-container',
      // });

      // // Create input element
      // let inputEle: HTMLInputElement = createElement('input', {
      //   className: 'e-field',
      //   attrs: { name: 'Patient' },
      // }) as HTMLInputElement;

      // // Append input element to patient container
      // patientContainer.appendChild(inputEle);

      // // Append patient container to the row or another container
      // row.appendChild(patientContainer);

      // // Add focus event listener to input element
      // inputEle.addEventListener('focus', () => {

      // });

      // // Initialize patient dropdown list
      // let patientDropDownList: DropDownList = new DropDownList({
      //   dataSource: this.patientDet,
      //   fields: { text: 'name', value: 'id' },
      //   value: args.data.Patient || this.patientIdViaURL || '',
      //   floatLabelType: 'Always',
      //   placeholder: 'Patient Name ',
      //   allowFiltering: true,
      //   filterBarPlaceholder: 'Search',
      //   // Filtering and change event handlers remain unchanged from your original code
      // });

      // // Append dropdown list to input element
      // patientDropDownList.appendTo(inputEle);
      // inputEle.setAttribute('name', 'patientId');



      //#endregion

      // Add Tech Dropdown
      //#region
      // let techContainer: HTMLElement = createElement('div', {
      //   className: 'custom-field-container',
      // });
      // let techInputEle: HTMLInputElement = createElement('input', {
      //   className: 'e-field',
      //   attrs: { name: 'Tech' },
      // }) as HTMLInputElement;
      // techContainer.appendChild(techInputEle);
      // row.appendChild(techContainer);
      // let techDropDownList: DropDownList = new DropDownList({
      //   dataSource: this.techDet,
      //   fields: { text: 'name', value: 'id' },
      //   // value: (<{ [key: string]: Object }>args.data).Patient as string,
      //   value: args.data.tech || this.techId || '',
      //   floatLabelType: 'Always',
      //   placeholder: 'Tech Name',
      //   allowFiltering: true,
      //   filterBarPlaceholder: 'Search',
      //   filtering: (e: FilteringEventArgs) => {
      //     const filterText = e.text.toLowerCase();
      //     e.updateData(
      //       this.techDet.filter((tech) => {
      //         const TechDetails = (tech.name || '').toLowerCase();
      //         return TechDetails.includes(filterText);
      //       })
      //     );
      //   },
      // });
      // techDropDownList.appendTo(techInputEle);
      // techInputEle.setAttribute('name', 'techId');
      //#endregion

      // Add User Dropdown
      // #region
      let UserContainer: HTMLElement = createElement('div', {
        className: 'custom-field-container',
      });
      let userInputEle: HTMLInputElement = createElement('input', {
        className: 'e-field',
        attrs: { name: 'User' },
      }) as HTMLInputElement;
      UserContainer.appendChild(userInputEle);
      row.appendChild(UserContainer);
      let userDropDownList: DropDownList = new DropDownList({
        dataSource: this.userDet,
        fields: { text: 'name', value: 'id' },
        // value: (<{ [key: string]: Object }>args.data).Type as string,
        value: args.data.user || this.userDesID || null,
        floatLabelType: 'Always',
        placeholder: 'Select User',
        allowFiltering: true,
        filterBarPlaceholder: 'Search',
        filtering: (e: FilteringEventArgs) => {
          const filterText = e.text.toLowerCase();
          e.updateData(
            this.userDet.filter((user) => {
              const UserDetails = (user.name || '').toLowerCase();
              return UserDetails.includes(filterText);
            })
          );
        },
      });
      userDropDownList.appendTo(userInputEle);
      userInputEle.setAttribute('name', 'userDesID');
      //#endregion

      // Add Type Dropdown
      //#region
      let typeContainer: HTMLElement = createElement('div', {
        className: 'custom-field-container',
      });
      let typeInputEle: HTMLInputElement = createElement('input', {
        className: 'e-field',
        attrs: { name: 'Type' },
      }) as HTMLInputElement;
      typeContainer.appendChild(typeInputEle);
      row.appendChild(typeContainer);
      let typeDropDownList: DropDownList = new DropDownList({
        dataSource: this.typeDet,
        fields: { text: 'name', value: 'id' },
        // value: (<{ [key: string]: Object }>args.data).Type as string,
        value: args.data.type || this.typeId || null,
        floatLabelType: 'Always',
        placeholder: 'Type Name',
        allowFiltering: true,
        filterBarPlaceholder: 'Search',
        filtering: (e: FilteringEventArgs) => {
          const filterText = e.text.toLowerCase();
          e.updateData(
            this.typeDet.filter((type) => {
              const TypeDetails = (type.name || '').toLowerCase();
              return TypeDetails.includes(filterText);
            })
          );
        },
      });
      typeDropDownList.appendTo(typeInputEle);
      typeInputEle.setAttribute('name', 'typeId');
      //#endregion

      // Add Resource Dropdown
      //#region
      let resContainer: HTMLElement = createElement('div', {
        className: 'custom-field-container',
      });
      let resInputEle: HTMLInputElement = createElement('input', {
        className: 'e-field',
        attrs: { name: 'Resourcess' },
      }) as HTMLInputElement;
      resContainer.appendChild(resInputEle);
      row.appendChild(resContainer);
      let resDropDownList: DropDownList = new DropDownList({
        dataSource: this.resourceDet,
        fields: { text: 'name', value: 'id' },
        // value: (<{ [key: string]: Object }>args.data).Resource as string,
        value: args.data.resource || this.resourceId || '',
        floatLabelType: 'Always',
        placeholder: 'Resource',
        allowFiltering: true,
        filterBarPlaceholder: 'Search',
        filtering: (e: FilteringEventArgs) => {
          const filterText = e.text.toLowerCase();
          e.updateData(
            this.resourceDet.filter((resource) => {
              const resourceDetails = (resource.name || '').toLowerCase();
              return resourceDetails.includes(filterText);
            })
          );
        },
      });
      resDropDownList.appendTo(resInputEle);
      resInputEle.setAttribute('name', 'resourceId');
      //#endregion


      // Mode of Communication Dropdown
      // #region
      let CommunicateContainer: HTMLElement = createElement('div', {
        className: 'custom-field-container',
      });

      // Create a MultiSelect element
      let multiSelectElement: HTMLInputElement = createElement('input', {
        className: 'e-field',
        attrs: { name: 'mode' },
      }) as HTMLInputElement;
      CommunicateContainer.appendChild(multiSelectElement);

      // Initialize the MultiSelect component
      let communicationMultiSelect: MultiSelect = new MultiSelect({
        dataSource: this.modedrp,
        fields: { text: 'name', value: 'id' },
        value: args.data.mode || this.modeID || '',
        floatLabelType: 'Always',
        placeholder: 'Mode of Communication',
        allowFiltering: true,
        filterBarPlaceholder: 'Search',
        mode: 'Box', // Set the mode to 'Box' for multiple selection
        filtering: (e: FilteringEventArgs) => {
          const filterText = e.text.toLowerCase();
          e.updateData(
            this.modedrp.filter((mode) => {
              const modeDetails = (mode.name || '').toLowerCase();
              return modeDetails.includes(filterText);
            })
          );
        },
        change: (e: ChangeEventArgs) => {
          const selectedItems = communicationMultiSelect.value as number[];
          this.getTemplate(selectedItems);
          const lastIndex = selectedItems[selectedItems.length - 1];
          if (lastIndex === 4) {
            communicationMultiSelect.value = [4];
          } else {
            const filteredItems = selectedItems.filter(item => item !== 4);
            communicationMultiSelect.value = filteredItems;
          }
          communicationMultiSelect.refresh();
        }
      });

      communicationMultiSelect.appendTo(multiSelectElement);
      multiSelectElement.setAttribute('name', 'modeID');
      row.appendChild(CommunicateContainer);
      //#endregion


      // Add Template Dropdown
      //#region
      let templateContainer: HTMLElement = createElement('div', {
        className: 'custom-field-container',
      });
      let templateInputEle: HTMLInputElement = createElement('input', {
        className: 'e-field',
        attrs: { name: 'TemplateCode'},
      }) as HTMLInputElement;
      templateContainer.appendChild(templateInputEle);
      row.appendChild(templateContainer);
      this.templateDropDownList = new DropDownList({
        dataSource: this.templateDrp,
        fields: { text: 'name', value: 'id' },
        // value: (<{ [key: string]: Object }>args.data).Resource as string,
        value: args.data.templateCode || this.templateId || '',
        floatLabelType: 'Always',
        placeholder: 'Template',
        allowFiltering: true,
        filterBarPlaceholder: 'Search',
        filtering: (e: FilteringEventArgs) => {
          const filterText = e.text.toLowerCase();
          e.updateData(
            this.templateDrp.filter((template) => {
              const templateDetails = (template.name || '').toLowerCase();
              return templateDetails.includes(filterText);
            })
          );
        },
        change: (e: ChangeEventArgs) => {
          const selectedTemplate = templateInputEle.value as string; 
          const selectedTemplateName = e.value.toString(); 
          this.selectedTemplateId = selectedTemplate;
          this.selectedTemplateName = selectedTemplateName;
        }
      });
      this.templateDropDownList.appendTo(templateInputEle);
      templateInputEle.setAttribute('name', 'templateId');
      //#endregion

      // Add Home
      //#region
      let homeContainer: HTMLElement = createElement('div', {
        className: 'custom-field-container',
      });

      // Add Label for Home Textbox
      let homeLabel: HTMLLabelElement = createElement('label', {
        className: 'home-label',
        innerHTML: 'Home:', // Provide the desired label text
      }) as HTMLLabelElement;

      homeContainer.appendChild(homeLabel);

      // let homeInputEle: HTMLInputElement = createElement('input', {
      //   className:
      //     'e-float-input e-control-wrapper form-control mt-0 e-subject e-field',
      //   attrs: {
      //     name: 'HomeText',
      //     type: 'tel',
      //     pattern: '[0-9]{1,10}',
      //     title: 'Enter a valid Home phone number with a maximum of 10 digits',
      //     placeholder: '+1 country code is not needed.',
      //     // value:
      //     //   args.data.HomeText !== undefined && args.data.HomeText !== null
      //     //     ? args.data.HomeText
      //     //     : this.home || '',
      //     value: args.data.phone || this.home || '',
      //   }, // Set pattern and title attributes
      // }) as HTMLInputElement;

      let homeInputEle: HTMLInputElement = createElement('input', {
        className:
          'e-float-input e-control-wrapper form-control mt-0 e-subject e-field',
        attrs: {
          name: 'HomeText',
          type: 'tel',
          pattern: '\\+[0-9]{1,4}[0-9]{1,10}',
          title: 'Enter a valid Home number with a country code and maximum of 10 digits after the country code',
          placeholder: '+ Country code and home number',
          value: args.data.phone || this.home || '',
        },
      }) as HTMLInputElement;

      // Add input event listener for validation
      homeInputEle.maxLength = 13;
      homeInputEle.addEventListener('input', () => {
        this.isValidHomeNumber = homeInputEle.checkValidity();
        const validationHomeMessage = homeInputEle.validationMessage;

        // Display validation message
        const validationMessageContainerForHome = homeContainer.querySelector(
          '.validation-message'
        );
        if (validationMessageContainerForHome) {
          validationMessageContainerForHome.remove();
        }

        if (!this.isValidHomeNumber) {
          const validationMessageElementForHome = createElement('div', {
            className: 'validation-message',
            innerHTML: validationHomeMessage,
            styles: 'color: red; font-size: 12px; margin-top: 5px;',
          });

          homeContainer.appendChild(validationMessageElementForHome);
        }
      });
      homeContainer.appendChild(homeInputEle);
      row.appendChild(homeContainer);
      //#endregion

      // Add Cell
      //#region
      let cellContainer: HTMLElement = createElement('div', {
        className: 'custom-field-container',
      });
      // Add Label for Cell Textbox
      let cellLabel: HTMLLabelElement = createElement('label', {
        className: 'cell-label',
        innerHTML: 'Cell (required for schedule if SMS is selected):', // Provide the desired label text
      }) as HTMLLabelElement;

      cellContainer.appendChild(cellLabel);

      // let cellInputEle: HTMLInputElement = createElement('input', {
      //   className:
      //     'e-float-input e-control-wrapper form-control mt-0 e-subject e-field',
      //   attrs: {
      //     name: 'CellText',
      //     type: 'tel',
      //     pattern: '[0-9]{1,10}',
      //     title: 'Enter a valid Cell number with a maximum of 10 digits',
      //     placeholder: '+1 country code is not needed.',
      //     // value:
      //     //   args.data.CellText !== undefined && args.data.CellText !== null
      //     //     ? args.data.CellText
      //     //     : this.cell || '',
      //     value: args.data.cell || this.cell || '',
      //   },
      // }) as HTMLInputElement;
      let cellInputEle: HTMLInputElement = createElement('input', {
        className:
          'e-float-input e-control-wrapper form-control mt-0 e-subject e-field',
        attrs: {
          name: 'CellText',
          type: 'tel',
          pattern: '\\+[0-9]{1,4}[0-9]{1,10}',
          title: 'Enter a valid Cell number with a country code and maximum of 10 digits after the country code',
          placeholder: '+ Country code and cell number',
          value: args.data.cell || this.cell || '',
        },
      }) as HTMLInputElement;
      // Add input event listener for validation
      cellInputEle.maxLength = 13;
      cellInputEle.addEventListener('input', () => {
        this.isValidCellNumber = cellInputEle.checkValidity();
        const validationCellMessage = cellInputEle.validationMessage;

        // Display validation message
        const validationMessageContainerForCell = cellContainer.querySelector(
          '.validation-message'
        );
        if (validationMessageContainerForCell) {
          validationMessageContainerForCell.remove();
        }

        if (!this.isValidCellNumber) {
          const validationMessageElementForCell = createElement('div', {
            className: 'validation-message',
            innerHTML: validationCellMessage,
            styles: 'color: red; font-size: 12px; margin-top: 5px;',
          });

          cellContainer.appendChild(validationMessageElementForCell);
        }
      });
      cellContainer.appendChild(cellInputEle);
      row.appendChild(cellContainer);
      //#endregion

      // Add Work
      //#region
      let workContainer: HTMLElement = createElement('div', {
        className: 'custom-field-container',
      });
      // Add Label for Work Textbox
      let workLabel: HTMLLabelElement = createElement('label', {
        className: 'work-label',
        innerHTML: 'Work:', // Provide the desired label text
      }) as HTMLLabelElement;

      workContainer.appendChild(workLabel);

      // let workInputEle: HTMLInputElement = createElement('input', {
      //   className:
      //     'e-float-input e-control-wrapper form-control mt-0 e-subject e-field',
      //   // attrs: { name: 'WorkText', type: 'number' },
      //   attrs: {
      //     name: 'WorkText',
      //     type: 'tel',
      //     pattern: '[0-9]{1,10}',
      //     title: 'Enter a valid Work number with a maximum of 10 digits',
      //     placeholder: '+1 country code is not needed.',
      //     // value:
      //     //   args.data.WorkText !== undefined && args.data.WorkText !== null
      //     //     ? args.data.WorkText
      //     //     : this.work || '',
      //     value: args.data.work || this.work || '',
      //   },
      // }) as HTMLInputElement;

      let workInputEle: HTMLInputElement = createElement('input', {
        className:
          'e-float-input e-control-wrapper form-control mt-0 e-subject e-field',
        attrs: {
          name: 'WorkText',
          type: 'tel',
          pattern: '\\+[0-9]{1,4}[0-9]{1,10}',
          title: 'Enter a valid work number with a country code and maximum of 10 digits after the country code',
          placeholder: '+ Country code and work number',
          value: args.data.work || this.work || '',
        },
      }) as HTMLInputElement;

      // Add input event listener for validation
      workInputEle.maxLength = 13;
      workInputEle.addEventListener('input', () => {
        this.isValidWorkNumber = workInputEle.checkValidity();
        const validationMessageForWork = workInputEle.validationMessage;

        // Display validation message
        const validationMessageContainerForWork = workContainer.querySelector(
          '.validation-message'
        );
        if (validationMessageContainerForWork) {
          validationMessageContainerForWork.remove();
        }

        if (!this.isValidWorkNumber) {
          const validationMessageElementForWork = createElement('div', {
            className: 'validation-message',
            innerHTML: validationMessageForWork,
            styles: 'color: red; font-size: 12px; margin-top: 5px;',
          });

          workContainer.appendChild(validationMessageElementForWork);
        }
      });
      workContainer.appendChild(workInputEle);
      row.appendChild(workContainer);
      //#endregion

      // Add Email
      //#region
      let emailContainer: HTMLElement = createElement('div', {
        className: 'custom-field-container',
      });
      // Add Label for Email Textbox
      let emailLabel: HTMLLabelElement = createElement('label', {
        className: 'email-label',
        innerHTML: 'Email (required for schedule if Email is selected):', // Provide the desired label text
      }) as HTMLLabelElement;
      emailContainer.appendChild(emailLabel);
      let emailInputEle: HTMLInputElement = createElement('input', {
        className:
          'e-float-input e-control-wrapper form-control mt-0 e-subject e-field',
        attrs: {
          name: 'EmailText',
          type: 'email',
          pattern: '^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\\.[A-Z|a-z]{2,}$',
          maxLength: '50',
          value: args.data.email || this.email || '',
        },
      }) as HTMLInputElement;
      emailInputEle.addEventListener('input', () => {
        this.isValidEmail = emailInputEle.checkValidity();
        const validationEmailMessage = emailInputEle.validationMessage;

        // Display validation message
        const validationMessageContainer = emailContainer.querySelector(
          '.validation-message'
        );
        if (validationMessageContainer) {
          validationMessageContainer.remove();
        }

        if (!this.isValidEmail) {
          const validationMessageElement = createElement('div', {
            className: 'validation-message',
            innerHTML: validationEmailMessage,
            styles: 'color: red; font-size: 12px; margin-top: 5px;',
          });

          emailContainer.appendChild(validationMessageElement);
        }
      });
      emailContainer.appendChild(emailInputEle);
      row.appendChild(emailContainer);
      //#endregion

      // Add Setup in Home
      //#region
      let setupContainer: HTMLElement = createElement('div', {
        className: 'custom-field-container',
      });
      // Add Label for Email Textbox
      let setupLabel: HTMLLabelElement = createElement('label', {
        className: 'setup-label',
        innerHTML: 'Setup in Home : ', // Provide the desired label text
      }) as HTMLLabelElement;

      setupContainer.appendChild(setupLabel);
      let setupInputEle: HTMLInputElement = createElement('input', {
        className: 'setup-in-home-label mr-2 mt-2 e-field',
        attrs: {
          name: 'Setup',
          type: 'checkbox',
        },
      }) as HTMLInputElement;
      if (args.data && args.data.setupInHome === true) {
        // If Setup is explicitly set to false, uncheck the checkbox
        setupInputEle.checked = true;
      } else {
        setupInputEle.checked = false;
      }
      setupContainer.appendChild(setupInputEle);
      row.appendChild(setupContainer);
      //#endregion

      //! switch case events log
      switch (args?.requestType) {
        case 'viewNavigate':
        case 'dateNavigate':
          this.scheduleObj?.refresh();
          break;
        case 'toolBarItemRendered':
          // this.getTableData()
          // args?.requestType = "viewNavigate"
          break;
        default:
      }
    }
  }


  //   onItemDrag(event: any): void {
  //     if (this.scheduleObj.isAdaptive) {
  //         let classElement: HTMLElement = this.scheduleObj.element.querySelector('.e-device-hover');
  //         if (classElement) {
  //             classElement.classList.remove('e-device-hover');
  //         }
  //         if (event.target.classList.contains('e-work-cells')) {
  //             addClass([event.target], 'e-device-hover');
  //         }
  //     }
  //     document.body.style.position = 'fixed';
  //     if (document.body.style.cursor === 'not-allowed') {
  //         document.body.style.cursor = '';
  //     }
  //     if (event.name === 'nodeDragging') {
  //         let dragElementIcon: NodeListOf<HTMLElement> =
  //             document.querySelectorAll('.e-drag-item.treeview-external-drag .e-icon-expandable');
  //         for (let i: number = 0; i < dragElementIcon.length; i++) {
  //             dragElementIcon[i].style.display = 'none';
  //         }
  //     }
  // }


  // onActionBegin(args: ActionEventArgs): void {
  //   // Prevent default action for other Scheduler actions if needed
  //   args.cancel = true;
  // }
  // editor popup window
  public showQuickInfo: Boolean = false;
  onCellClick(args: CellClickEventArgs): void {
    // args.cancel = true;
    this.scheduleObj?.openEditor(args, 'Add');
  }
  onEventClick(args: EventClickArgs): void {
    if (!(args.event as any).RecurrenceRule) {
      this.scheduleObj?.openEditor(args.event, 'Save');
    } else {
      this.scheduleObj?.quickPopup?.openRecurrenceAlert();
    }
  }

  colorCode(Status: string) {
    let scalender_status_color_code = calender_status_color_code;
    let scalender_status_code = calender_status_code;
    let returntext = calender_status_color_code.Scheduled;
    if (Status === scalender_status_code[1]) {
      returntext = calender_status_color_code.Scheduled;
    } else if (Status === scalender_status_code[2]) {
      returntext = calender_status_color_code.Rescheduled;
    } else if (Status === scalender_status_code[3]) {
      returntext = calender_status_color_code.Completed;
    } else if (Status === scalender_status_code[4]) {
      returntext = calender_status_color_code.Cancelled;
    } else {
      returntext = calender_status_color_code.Scheduled; // Unknown
    }
    return returntext;
  }

  toggle: boolean = false;

  //! calender actions
  async onActionComplete(args: ActionEventArgs) {

    // console.log(args?.addedRecords?.[0]?.Id);
    // console.log(args?.changedRecords?.[0]?.QGuid);

    if (args.requestType === 'eventCreated') {

      let eventCreatedPatientId =
        args?.addedRecords?.[0]?.patientId !== undefined &&
          args?.addedRecords?.[0]?.patientId !== ''
          ? args?.addedRecords?.[0]?.patientId
          : null;

      //   if (eventCreatedPatientId === null)
      // {
      // Wrap the call to getSelectedPatientDetails in a promise
      await new Promise<void>((resolve) => {
        this.getSelectedPatientDetails(eventCreatedPatientId).subscribe(() => {
          resolve();
        });
      });

      // const selectedTemplate = this.templateDropDownList.index;
      // const selectedTemplateV1 = this.templateDropDownList.value
      // const templateCode = selectedTemplate.name;
      // const templateContent = selectedTemplate.id;
      // this.templateDrp = this.templateDropDownList?.find(
      //   (a) => a.templateId == this.templateId
      // );

      // let selectedTemplate = this.templateDrp.find(template => template.id === args?.addedRecords?.[0]?.templateId);
      // let templateCode = selectedTemplate ? selectedTemplate.name : null;
      // let templateContent = args?.addedRecords?.[0]?.templateId || null;
      
      let calender: CreateUpdateCalendarDTO = {
        roleId: this.roleId,
        roleName: this.roleName,
        assignedToId: this.userId,
        assignedToName: this.userName,
        calendarDetails: [
          {
            title: args?.addedRecords?.[0]?.Subject,
            fromTime: args?.addedRecords?.[0]?.StartTime,
            toTime: args?.addedRecords?.[0]?.EndTime,
            status: args?.addedRecords?.[0]?.OwnerId ?? '',
            description: args?.addedRecords?.[0]?.Description ?? '',
            locationId: args.addedRecords[0].branchId || null,
            patientId: args.addedRecords[0].patientId || defaultGuid, // Needs to change once API ready
            // patientId:eventCreatedPatientId,
            // userID: args.addedRecords[0].techId || null,
            userID: args.addedRecords[0].userDesID || null,
            type: args?.addedRecords?.[0]?.typeId ?? this.typeId,
            resource: args?.addedRecords?.[0]?.resourceId || null,
            homePhoneNo:
              this.isValidHomeNumber === true
                ? args?.addedRecords?.[0]?.HomeText
                : null,
            cellNo:
              this.isValidCellNumber === true
                ? args?.addedRecords?.[0]?.CellText
                : null,
            workNo:
              this.isValidWorkNumber === true
                ? args?.addedRecords?.[0]?.WorkText
                : null,
            email:
              (this.isValidEmail === true &&
                args?.addedRecords?.[0]?.EmailText) ||
              null,
            setUpInHome: args?.addedRecords?.[0]?.Setup,
            calendarCode: '',
            calendarId: args?.addedRecords?.[0]?.Id,
            startTimeZone: '',
            endTimeZone: '',
            // colorCode: '#20b2aa',
            colorCode: this.colorCode(args?.addedRecords?.[0]?.OwnerId ?? ''),
            docId: this.docId,
          },
        ],
        modeCode: args?.addedRecords?.[0]?.mode || defaultGuid,
        // templateCode: args?.addedRecords?.[0]?.templateId || null,
        // templateContent: args?.addedRecords?.[0]?.templateName || null,

        // templateCode: templateCode || null,
        // templateContent: templateContent || null,
        
        templateCode: this.selectedTemplateId,
        templateContent: this.selectedTemplateName,
      
      };
      // console.log(calender);
      this.inboundDocumentService.createCalendarByInput(calender).subscribe(
        (response) => {
          this.toggle = !this.toggle;
          this.eventSettings = {
            dataSource: this.getTableData(),
          };
          // this.getTableData();
        },
        (err) => {
          args.cancel = false;
          const data: HttpErrorResponse = err;
          Swal.fire({
            icon: 'info',
            text: data?.error?.error?.message,
          });
          this.eventSettings = {
            dataSource: this.getTableData(),
          };
        }
      );
    } else if (args.requestType === 'eventChanged') {
      let eventChangedPatientId =
        args?.changedRecords?.[0]?.patientId !== undefined &&
          args?.changedRecords?.[0]?.patientId !== ''
          ? args?.changedRecords?.[0]?.patientId
          : null;

      if (!eventChangedPatientId === null)
        // Wrap the call to getSelectedPatientDetails in a promise
        await new Promise<void>((resolve) => {
          this.getSelectedPatientDetails(eventChangedPatientId).subscribe(
            () => {
              resolve();
            }
          );
        });
        
        let selectedTemplate = this.templateDrp.find(template => template.id === args?.changedRecords?.[0]?.templateId);
        let templateCode = selectedTemplate ? selectedTemplate.name : null;
        let templateContent = args?.changedRecords?.[0]?.templateId || null;

      let calender: CreateUpdateCalendarDTO = {
        roleId: this.roleId,
        roleName: this.roleName,
        assignedToId: this.userId,
        assignedToName: this.userName,
        calendarDetails: [
          {
            title: args?.changedRecords?.[0]?.Subject,
            fromTime: args?.changedRecords?.[0]?.StartTime,
            calendarDetailsId: this.calenderDetailId,
            toTime: args?.changedRecords?.[0]?.EndTime,
            status: args?.changedRecords?.[0]?.OwnerId ?? '',
            description: args?.changedRecords?.[0]?.Description ?? '',
            locationId:
              args?.changedRecords?.[0]?.branchId !== undefined &&
                args?.changedRecords?.[0]?.branchId !== ''
                ? args?.changedRecords?.[0]?.branchId
                : null,
            patientId: eventChangedPatientId || defaultGuid,
            userID:
              args?.changedRecords?.[0]?.userDesID !== undefined &&
                args?.changedRecords?.[0]?.userDesID !== ''
                ? args?.changedRecords?.[0]?.userDesID
                : null,
            type:
              args?.changedRecords?.[0]?.typeId !== undefined &&
                args?.changedRecords?.[0]?.typeId !== ''
                ? args?.changedRecords?.[0]?.typeId
                : null,

            resource:
              args?.changedRecords?.[0]?.resourceId !== undefined &&
                args?.changedRecords?.[0]?.resourceId !== ''
                ? args?.changedRecords?.[0]?.resourceId
                : null,
            homePhoneNo:
              this.isValidHomeNumber === true
                ? args?.changedRecords?.[0]?.HomeText
                : null,
            cellNo:
              this.isValidCellNumber === true
                ? args?.changedRecords?.[0]?.CellText
                : null,
            workNo:
              this.isValidWorkNumber === true
                ? args?.changedRecords?.[0]?.WorkText
                : null,
            email:
              (this.isValidEmail === true &&
                args?.changedRecords?.[0]?.EmailText) ||
              null,
            setUpInHome: args?.changedRecords?.[0]?.Setup,
            calendarCode: '',
            calendarId: args?.changedRecords?.[0]?.Id,
            startTimeZone: '',
            endTimeZone: '',
            // colorCode: '#20b2aa',
            colorCode: this.colorCode(args?.changedRecords?.[0]?.OwnerId ?? ''),
            docId: this.docId,
          },
        ],
        modeCode: args?.changedRecords?.[0]?.mode || defaultGuid,
        // templateCode: args?.changedRecords?.[0]?.templateId,
        // templateContent: args?.changedRecords?.[0]?.templateId,

        // templateCode: templateCode || this.selectedTemplateId,
        // templateContent: templateContent || this.selectedTemplateName,

        // templateCode: templateCode || null,
        // templateContent: templateContent || null,
        
        templateCode: this.selectedTemplateId || null,
        templateContent: this.selectedTemplateName || null,
      };



      this.inboundDocumentService
        .updateCalendarByIdAndInput(args?.changedRecords?.[0]?.QGuid, calender)
        .subscribe(
          (response) => {
            this.toggle = !this.toggle;
            this.eventSettings = {
              dataSource: this.getTableData(),
            };
            // this.getTableData();
          },
          (err) => {
            args.cancel = false;

            const data: HttpErrorResponse = err;
            Swal.fire({
              icon: 'info',
              text: data?.error?.error?.message,
            });
            this.eventSettings = {
              dataSource: this.getTableData(),
            };
          }
        );
    } else if (args?.requestType === 'eventRemoved') {


      this.inboundDocumentService
        .deleteCalendarByIdAndCalendarDetailsId(
          args?.deletedRecords?.[0]?.QGuid,
          args?.deletedRecords?.[0]?.calendarDetailsId
        )
        .subscribe(
          (response) => { },
          (err) => {
            const data: HttpErrorResponse = err;
            // Swal.fire({
            //   icon: 'info',
            //   text: data?.error?.error?.message,
            // });
            this.eventSettings = {
              dataSource: this.getTableData(),
            };
          }
        );
    }
  }

  @ViewChild('addButton')
  public addButton: ButtonComponent;
  public onButtonClick(): void {
    let data: Object[] = [
      {
        Id: 0,
        Subject: 'Conference',
        StartTime: new Date(2018, 1, 12, 9, 0),
        EndTime: new Date(2018, 1, 12, 10, 0),
        IsAllDay: true,
      },
    ];
    // this.scheduleObj?.addEvent(data);
    // this.addButton.element?.setAttribute('disabled','true');
  }

  setRequiredFields(element: HTMLElement) {
    const formFields = element.querySelectorAll('.e-schedule-form .custom-field-container');
    formFields.forEach((field) => {
      (field as HTMLInputElement).required = true;
    });
  }

  openAddScheduleModal(){
    const config: MatDialogConfig = {
      disableClose: true,
      width: '30vw',
      height: '80vh',
      maxWidth: '30vw',
      maxHeight: '80vh',
      data: {},
    };
    const dialogRef = this.dialog.open(CalenderScheduleAddModalComponent, config);
    const closeDialog = dialogRef.afterClosed().subscribe(
      () => {},
      (err) => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      }
    );
  }
  
}
// function extend(
//   arg0: undefined[],
//   eventData: Record<string, any>[],
//   arg2: null,
//   arg3: boolean
// ): { [key: string]: Date }[] {
//   throw new Error('Function not implemented.');
// }
// function addClass(arg0: any[], arg1: string) {
//   throw new Error('Function not implemented.');
// }
