<div class="row">
  <div class="col-xl-12">

    <div class="card">
      <div class="card-body">
        <form [formGroup]="mailForm">
          <mat-form-field class="col-12">
            <mat-label>To <span class="asterisk">*</span></mat-label>
            <input autocomplete="new-txtTo" matInput formControlName="txtTo"
              pattern="[a-z0-9._!#$%&'()*+,-./:;<=>?@[\]^_`{|}~]+@[a-z0-9.-]+\.[a-z]{2,4}$" maxlength="60" type="text"
              [value]='patientEmailId'>
            <mat-error *ngIf="
              mailForm?.get('txtTo')?.touched &&
              mailForm?.get('txtTo')?.errors?.required
              ">
              To email is a required field!
            </mat-error>
            <mat-error *ngIf="
              mailForm?.get('txtTo')?.touched &&
              mailForm?.get('txtTo')?.errors
              ">
              Enter Valid Email!
            </mat-error>
          </mat-form-field>
          <p class="pt-0 pl-3 mt-0" *ngIf="patientEmailId===''">
            <span class="p-1 alert-danger">Please fill Email in Personal</span>
          </p>
          <mat-form-field class="col-12">
            <mat-label>Cc </mat-label>
            <input autocomplete="new-txtCc" matInput formControlName="txtCc" maxlength="150" type="text" multiple
              pattern="[a-z0-9._!#$%&'()*+,-./:;<=>?@[\]^_`{|}~]+@[a-z0-9.-]+\.[a-z]{2,4}$">
            <mat-error *ngIf="!ccEmailValidationErrror"> Enter Valid Email! </mat-error>
            <mat-hint>Use Comma (,) to seprate Mail Id`s</mat-hint>
          </mat-form-field>
          <br>
          <mat-form-field class="col-12">
            <mat-label>Bcc </mat-label>
            <input autocomplete="new-txtBcc" matInput formControlName="txtBcc" maxlength="150" type="text" multiple
              pattern="[a-z0-9._!#$%&'()*+,-./:;<=>?@[\]^_`{|}~]+@[a-z0-9.-]+\.[a-z]{2,4}$">
            <mat-error *ngIf="!bccEmailValidationErrror"> Enter Valid Email! </mat-error>
            <mat-hint>Use Comma (,) to seprate Mail Id`s</mat-hint>

          </mat-form-field>
          <br>
          <mat-form-field class="col-12">
            <mat-label>Subject <span class="asterisk">*</span></mat-label>
            <input autocomplete="new-txtSubject" matInput formControlName="txtSubject" maxlength="60" type="text">
            <mat-error *ngIf="
              mailForm?.get('txtSubject')?.touched &&
              mailForm?.get('txtSubject')?.errors?.required
              ">
              Subject is a required field!
            </mat-error>
          </mat-form-field>
          <br>
        </form>
        <angular-editor [(ngModel)]="htmlContent" [config]="config"></angular-editor>

        <div class="row pt-3">
          <div class="col-12 text-right">
            <button mat-button class="resetclr buttonColor mb-2 mr-2" (click)="resetMailForm()">Reset</button>
            <button *ngIf="orderStatus" mat-button mat-button class="buttonColor mb-2 mr-2"
              [disabled]="sendButtonHide||!(!mailForm.invalid &&  mailForm.dirty )" (click)="sendMail()">Send</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
