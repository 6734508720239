<div class="container-fluid">
    <div class="row bg">
        <div class="col-12">
            <div class="d-flex align-items-center justify-content-between ">
                <h3 class="mb-0 font-size-18"><b>Denial Year Report</b></h3>
                <div class="page-title-box page-title-right  pt-2 pb-0">
                    <ol class="breadcrumb m-0">

                        <li class="ml-2 mt-2 breadcrumb-item">Claim Report</li>
                        <li class="mt-2 breadcrumb-item muted">Denial Year Report</li>
                    </ol>
                </div>
            </div>
        </div>
        <div class="col-12">
            <mat-card-content>
                <!-- <div [hidden]="!isLoading"
                    style="display: flex; justify-content: center; align-items: center; background: transparent; color: lightseagreen;">
                    <mat-progress-bar mode="indeterminate" value='66' diameter="45" class="lightseagreen">
                    </mat-progress-bar>
                </div> -->
                <form [formGroup]="denialYearReport">
                    <div class="table table-responsive">
                        <table class="mat-elevation-z8" multiTemplateDataRows mat-table class="table"
                            [dataSource]="dataSource" matSort>

                            <ng-container matColumnDef="fileName">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                    <!-- <mat-form-field class="filter">
                                        <input matInput formControlName="fileName" type="text" />
                                        <mat-placeholder class="center"> Year</mat-placeholder>
                                    </mat-form-field> -->Year
                                </th>
                                <td mat-cell *matCellDef="let element">
                                    <ng-container>
                                        {{ element?.year }}
                                    </ng-container>
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="fileSize">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                    <!-- <mat-form-field class="filter">
                                        <input matInput formControlName="fileSize" type="text" />
                                        <mat-placeholder class="center">Yearly Total Billed</mat-placeholder>
                                    </mat-form-field> -->Yearly Total Billed
                                </th>
                                <td mat-cell *matCellDef="let element">
                                    <ng-container>
                                        {{ element?.yearlyTotalBilled }}
                                    </ng-container>
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="denied">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                    Yearly Denied
                                </th>
                                <td mat-cell *matCellDef="let element">
                                    <ng-container>
                                        {{ element?.yearlyTotalDeniedCount }}
                                    </ng-container>
                                </td>
                            </ng-container>


                            <ng-container matColumnDef="Options">
                                <th class="verticalAlignColumn  text-center" mat-header-cell *matHeaderCellDef
                                    mat-sort-header>
                                    Options
                                </th>
                                <td class="verticalAlignColumn text-center" mat-cell *matCellDef="let element">

                                    <button (click)="expandedElement = expandedElement === element ? null : element"
                                        mat-icon-button class="editBtnColor m-0 p-0">
                                        <mat-icon class="pointer"> {{ expandedElement === element ? 'expand_less' :
                                            'expand_more' }}
                                        </mat-icon>
                                    </button>
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="noRecords">
                                <td class="text-align-center" mat-footer-cell *matFooterCellDef colspan="12">
                                    <div *ngIf="!isLoading">
                                        {{"No Records Found"}}
                                    </div>
                                    <div [hidden]="!isLoading">
                                        <mat-spinner [diameter]="220"></mat-spinner>
                                    </div>
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="paginator">
                                <td mat-footer-cell *matFooterCellDef colspan="12">
                                    <mat-paginator class="table table-responsive mat-table-class" #MatPaginator
                                        [length]="dataSource?.data?.length" [pageSize]="10" [pageIndex]="0"
                                        showFirstLastButtons [pageSizeOptions]="[5, 10, 25, 100]">
                                    </mat-paginator>
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="expandedErrorDetail">
                                <td mat-cell *matCellDef="let element" [attr.colspan]="arrDisplayedColumns?.length">
                                    <div class="example-element-detail"
                                        [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
                                        <div class="example-element-description">
                                            <ng-container>
                                                <p>Monthly Denied Reports</p>
                                                <table class="table table-bordered table-sm">
                                                    <thead>
                                                        <tr>
                                                            <th scope="col">Month</th>
                                                            <th scope="col">Monthly Total Billed</th>
                                                            <th scope="col">Monthly Denied</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr *ngFor="let month of element?.monthlyDeniedReports">
                                                            <td>{{ month?.month }}</td>
                                                            <td>{{ month?.monthlyTotalBilled}}</td>
                                                            <td>{{ month?.monthlyTotalDeniedCount}}</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </ng-container>

                                        </div>
                                    </div>
                                </td>
                            </ng-container>
                            <tr mat-footer-row *matFooterRowDef="['noRecords']"
                                [ngClass]="{ 'active': dataSource && dataSource?.data && dataSource?.data?.length!==0}">
                            </tr>
                            <tr mat-header-row *matHeaderRowDef="arrDisplayedColumns"></tr>
                            <tr mat-footer-row *matFooterRowDef="['paginator']"></tr>
                            <tr mat-row *matRowDef="let row; columns: arrDisplayedColumns"></tr>
                            <tr mat-row *matRowDef="let element; columns: ['expandedErrorDetail']"
                                [class.example-expanded-row]="expandedElement === element"></tr>
                        </table>
                    </div>
                </form>
            </mat-card-content>
        </div>
    </div>
</div>