<!-- Title -->
<!-- <div class="row align-items-center"> -->
<div class="modal-header">
  <h1 class="modal-title">Schedule History </h1>
  <i aria-label="Close" mat-dialog-close class="fa fa-times"></i>
</div>
<!-- </div> -->
<div class="text-center mt-2"><button mat-button class="buttonColor mr-2">{{patientName}} - {{chartId}}</button></div>
<div class="m-3" *ngIf="noData">
  <!-- <div  class="container mx-0 text-center" > -->
  <!-- <div class="content m-3"> -->
  <h2 class="m-3 text-center">No Data Available....</h2>
  <!-- </div> -->
  <!-- </div> -->
</div>
<div class="timeline mx-0" *ngIf="!noData">
  <div *ngFor='let entry of timeLineDetails;let i = index'>
    <div class="container mx-0" [ngClass]="{ left: i%2!==0, right:i%2==0}">
      <div class="content ">
        <p class="heading">{{entry.headingName ? entry.headingName : entry.title}}</p>
        <h2 class="creadtedDate">{{entry.userName ? entry.userName : entry.scheduleCreatedByName}} - {{ entry.date?
          getFormattedDateTimeZone(entry.date) : getFormattedDateTimeZone(entry.scheduleCreatedAt)}} </h2>
      </div>
    </div>
  </div>
</div>