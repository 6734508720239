import { ListService } from '@abp/ng.core';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { Store } from '@ngxs/store';
import { PatientService } from 'projects/patient/src/app/patient-proxy/patient';
import { Subscription } from 'rxjs';
import Swal from 'sweetalert2';
import emailMask from 'text-mask-addons/dist/emailMask';
import { MailSendDTO } from '../order-proxy/order-management/order/models';
import { NotificationService } from '../order-proxy/order-management/order/notification.service';
import { ToastrService } from "ngx-toastr";
@Component({
  selector: 'app-notification-mail',
  templateUrl: './notification-mail.component.html',
  styleUrls: ['./notification-mail.component.scss']
})
export class NotificationMailComponent implements OnInit, OnDestroy {
  mailForm: FormGroup;
  htmlContent = '';
  emailMask: any;
  @Input() patientId: string = '';
  @Input() patientEmailId: string = '';
  sendButtonHide: boolean;
  bccEmailValidationErrror: boolean = false;
  ccEmailValidationErrror: boolean = false;
  @Input() orderStatus: boolean = true;
  subscription$: Subscription[] = [];

  ngOnDestroy(): void {
    this.subscription$?.forEach((sub) => {
      sub && sub?.unsubscribe();
    });
  }

  constructor(
    private formBuilder: FormBuilder,
    private notificationService: NotificationService,
    public list: ListService,
    public activatedRoute: ActivatedRoute,
    public store: Store,
    private toastr: ToastrService,
    private patientService: PatientService
  ) { }

  ngOnInit(): void {
    this.createMailForm();
  }

  // ! Form Creation and Few Validations
  createMailForm() {
    this.mailForm = this.formBuilder.group({
      txtTo: new FormControl({ value: '', disabled: true }, [Validators.required, Validators.email]),
      txtCc: new FormControl(""),
      txtBcc: new FormControl(""),
      txtSubject: new FormControl("", [Validators.required]),
      txtContent: new FormControl(""),
    });
    this.emailMask = emailMask;
    setTimeout(() => {
      this.getPatienTEmailIdvalue();
    }, 500);
  }
  getPatienTEmailIdvalue() {
    if (this.patientId && this.patientId !== "" && this.patientId !== null && this.patientId !== undefined) {
      const getPatient = this.patientService.get(this.patientId).subscribe(response => {
        // this.store.dispatch(new getPatient(this.patientId)).subscribe(response => {
        const selectedPatient = response;
        this.mailForm.patchValue({
          txtTo: selectedPatient?.personals?.emailId ?? ""
        })
        this.patientEmailId = selectedPatient?.personals?.emailId ?? "";
      }, err => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      });
      this.subscription$.push(getPatient);
    }
  }

  // commaSepEmail = (control: AbstractControl): { [key: string]: any } | null => {
  //   const emails = control.value?.split(',');
  //   const forbidden = emails.some(email => Validators.email(new FormControl(email)));
  //   return forbidden ? { 'toAddress': { value: control.value } } : null;
  // };

  // editor configurations
  config: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: '15rem',
    minHeight: '5rem',
    placeholder: 'Enter text here...',
    translate: 'no',
    defaultParagraphSeparator: 'p',
    defaultFontName: 'Arial',
    toolbarHiddenButtons: [
      ['bold']
    ],
    customClasses: [
      {
        name: "quote",
        class: "quote",
      },
      {
        name: 'redText',
        class: 'redText'
      },
      {
        name: "titleText",
        class: "titleText",
        tag: "h1",
      },
    ]
  };


  // send mail to patient

  sendMail() {
    this.sendButtonHide = true;
    // html to string coversion
    const oParser = new DOMParser();
    const oDOM = oParser.parseFromString(this.htmlContent, 'text/html');

    const text = oDOM.body.innerText;
    let ccMails: string[] = [];
    let bccMails: string[] = [];
    if (this.mailForm.value?.txtCc) {
      ccMails = this.mailForm.value?.txtCc?.split(",");
    }
    if (this.mailForm.value?.txtBcc) {
      bccMails = this.mailForm.value?.txtBcc?.split(",");
    }
    const mailDetails: MailSendDTO = {
      toMailAddress: this.patientEmailId,
      ccMailAddresses: ccMails,
      bccMailAddresses: bccMails,
      subject: this.mailForm.value?.txtSubject,
      body: text,
      patientId: this.patientId,
    }
    this.notificationService.sendMailToPatient(mailDetails).subscribe(response => {
      //Swal.fire({ title: 'Success', html: 'Mail Sent Successfully', icon: 'success', timer: 3000, timerProgressBar: true });
      this.toastr.success('Mail Sent Successfully','Success')
      this.resetMailForm();
      this.sendButtonHide = false;
    }, err => {
      this.sendButtonHide = false;
      this.resetMailForm();
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    });
  }


  // reset form

  resetMailForm() {
    this.htmlContent = '';
    this.mailForm.patchValue({
      txtCc: "",
      txtBcc: "",
      txtSubject: "",
      txtContent: "",
    })
    this.mailForm.markAsUntouched();
    this.mailForm.updateValueAndValidity();
    this.getPatienTEmailIdvalue();
  }
}
