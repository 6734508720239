import { RestService } from '@abp/ng.core';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class WarehouseSearchService {
  apiName = 'orderManagement';

  searchWarehouseBySPatientIdAndSPatientNameAndSSaleOrderIdAndIOrderStatusAndSPlanAndSInsuranceNameAndSEmailAndSVerificationStatusAndDtCreatedDateAndSShippingLocationAndDtSuppliedDate = (sPatientId: string, sPatientName: string, sSaleOrderId: string, iOrderStatus: number, sPlan: string, sInsuranceName: string, sEmail: string, sVerificationStatus: string, dtCreatedDate: string, sShippingLocation: string, dtSuppliedDate: string) =>
    this.restService.request<any, any>({
      method: 'POST',
      url: '/api/orderApp/warehouse-search/search-warehouse',
      params: { sPatientId, sPatientName, sSaleOrderId, iOrderStatus, sPlan, sInsuranceName, sEmail, sVerificationStatus, dtCreatedDate, sShippingLocation, dtSuppliedDate },
    },
     { apiName: this.apiName, skipHandleError: true });

  constructor(private restService: RestService) {}
}
