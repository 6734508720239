<table id="tblMyMedicareCheckList" datatable [dtOptions]="dtMyMedicareCheckListOptions" class="tbl hover row-border hover w-100 display">
  <thead>
    <tr>
      <th><mat-icon>apps</mat-icon></th>
      <th [matTooltip]="'Checklist Id'" >Checklist Id</th>
      <th [matTooltip]="'Chart No'" >Chart No</th>
      <th [matTooltip]="'Patient Name'" >Patient Name</th>
      <th [matTooltip]="'Type'" >Type</th>
      <th [matTooltip]="'Prepared By'" >Prepared By</th>
      <th class="text-right" [matTooltip]="'Prepared On'" >Prepared On</th>
      <th [matTooltip]="'Approved By'" >Approved By</th>
      <th class="text-right" [matTooltip]="'Approved On'" >Approved On</th>
      <th [matTooltip]="'Modify By'">Modify By</th>
      <th class="text-right" [matTooltip]="'Modified Date'" >Modified Date</th>
      <th [matTooltip]="'Status'" >Status</th>
    </tr>
  </thead>
  <tbody>
    <tr [ngClass]="
    selectedRow === data.uniqueChecklistId
      ? 'row-highlighted'
      : ''"
      *ngFor="let data of myMedicareCheeckList"
      (click)="tblItemSelectedRow(data)" >
      <td [matTooltip]="'Open Options'" >
        <mat-icon style="cursor: pointer !important" [matMenuTriggerFor]="menu">more_vert</mat-icon>
        <mat-menu class="custom-mat-menu" #menu="matMenu">
          <a [class.disabled]="isEmpty(data.patientId) || isEmpty(data.docId) || isEmpty(data.blobName)||(data.status!=='New')"
          [routerLink]="'/do_checklist/' + data.patientId + '/' + '/' + null"
            target="_blank" class="preview-link">
            <button [disabled]="isEmpty(data.patientId) || isEmpty(data.docId) || isEmpty(data.blobName)||(data.status!=='New')"
              mat-menu-item>Do Checklist</button>
          </a>
          <button mat-menu-item [disabled]="data.patientId === defaultGuid" (click)="openAddNotesPopUp()">Add
            Patient Note</button>
          <!-- <button mat-menu-item [disabled]="true">Move To</button> -->
          <a [class.disabled]="isEmpty(data.patientId) || isEmpty(data.docId) || isEmpty(data.blobName)"
          [routerLink]="'/do_checklist/' + data.patientId + '/' + '/' + null"
              target="_blank" class="preview-link">
              <button [disabled]="isEmpty(data.patientId) || isEmpty(data.docId) || isEmpty(data.blobName)"
                mat-menu-item>Change Checklist Type</button>
            </a>

          <a [class.disabled]="isEmpty(data.docId) || data?.isSorted === false" [routerLink]="'/view_document_tiff/' + data.docId +'/'+ true"
            target="_blank" class="preview-link">
            <button [disabled]="isEmpty(data.docId) || data?.isSorted === false" mat-menu-item>View Document Images</button>
          </a>
          <button mat-menu-item [disabled]="data.patientId === defaultGuid" (click)="openViewNotesPopup()">View Patient
            Notes</button>
            <button mat-menu-item (click)="viewPatientLedger(data.patientId)">View Patient Ledger</button>
          <!-- <button mat-menu-item>Add Fax Process Notes</button> -->
          <button mat-menu-item [disabled]="true">Delete CheckList</button>
        </mat-menu>
      </td>
      <td  (dblclick)="onRowDoubleClick(data?.uniqueChecklistId || '-')" [matTooltip]="data?.uniqueChecklistId || '-'" >{{ data?.uniqueChecklistId || '-'}}</td>
      <td (dblclick)="onRowDoubleClick(data?.chartNo || '-')" [matTooltip]="data?.chartNo || '-' ">
        <a [class.disabled]="isEmpty(data.chartNo)" [routerLink]="'/patientCreateEditTab/' + data?.patientId + '/' + data?.docId" target="_blank"
           class="preview-link">
        {{ data?.chartNo || '-' }}
        </a>
      </td>
      <td  (dblclick)="onRowDoubleClick(data?.patientName || '-')" [matTooltip]="data?.patientName || '-'">
        {{ data?.patientName || '-'}}
      </td>

      <td (dblclick)="onRowDoubleClick(data?.checkListType || '-')" [matTooltip]="data.checkListType || '-'">
        {{ data.checkListType || '-' }}
      </td>
      <td  (dblclick)="onRowDoubleClick(data?.preparedByName || '-')" [matTooltip]="data?.preparedByName || '-'">
        {{  data?.preparedByName || '-' }}
      </td>
      <td (dblclick)="onRowDoubleClick(data?.preparedOn)" [matTooltip]="data?.preparedOn">
        {{ data?.preparedOn }}
      </td>
      <td  (dblclick)="onRowDoubleClick(data?.approvedByUser || '-')" [matTooltip]="data?.approvedByUser || '-'">
        {{ data?.approvedByUser || '-' }}
      </td>
      <td  (dblclick)="onRowDoubleClick(data?.approvedOn)" [matTooltip]="data?.approvedOn">
        {{ data?.approvedOn }}
      </td>
      <td [matTooltip]="data?.modifyBy|| '-'"
      (dblclick)="onRowDoubleClick(data?.modifyBy || '-')">{{
      data?.modifyBy || '-' }}</td>

  <td class="text-right" [matTooltip]="data?.lastModificationTime"
      (dblclick)="onRowDoubleClick(data?.lastModificationTime)" >
      {{ data?.lastModificationTime }}
  </td>
      <td  (dblclick)="onRowDoubleClick(data?.status || '-')" [matTooltip]="data?.status || '-'">
        {{ data?.status || '-' }}
      </td>
    </tr>
  </tbody>
  <tfoot>
    <tr>
      <th><mat-icon>apps</mat-icon></th>
      <th >
        <input matInput class="textbox-border-class" type="text" placeholder="Checklist Id"
          name="search-ChecklistId" />
      </th>
      <th >
        <input matInput type="text" class="textbox-border-class" placeholder="Chart No"
          name="search-ChartNo" />
      </th>
      <th >
        <input matInput type="text" class="textbox-border-class" placeholder="Patient Name"
          name="search-PatientName" />
      </th>
      <th >
        <input matInput type="text" class="textbox-border-class" placeholder="Type" name="search-Type" />
      </th>
      <th >
        <input matInput class="textbox-border-class" type="text" placeholder="Prepared By"
          name="search-PreparedBy" />
      </th>
      <th >
        <input matInput class="textbox-border-class" type="text" placeholder="Prepared On"
          name="search-PreparedOn" />
      </th>
      <th >
        <input matInput class="textbox-border-class" type="text" placeholder="Approved By"
          name="search-ApprovedBy" />
      </th>
      <th >
        <input matInput class="textbox-border-class" type="text" placeholder="Approved On"
          name="search-Approved On" />
      </th>
      <th>
        <input matInput class="textbox-border-class" type="text" placeholder="Modify By"
            name="search-Modify By" />
    </th>
      <th class="text-right">
          <input matInput class="textbox-border-class " type="text"
              placeholder="Modified Date" name="search-Modified Date" />
      </th>
      <th >
        <input matInput class="textbox-border-class" type="text" placeholder="Status" name="search-Status" />
      </th>
    </tr>
  </tfoot>
</table>
