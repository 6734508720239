import { AppNotification } from './app-notification.model';

// Actions
export class FetchNotifications {
  static readonly type = '[Notifications] Fetch';
  constructor(public readonly payload:string) {}
}
export class AddNotification {
  static readonly type = '[Notifications] Add';
  constructor(public readonly payload: AppNotification|undefined) {}
}

export class DeleteNotification {
  static readonly type = '[Notifications] Delete';
  constructor(public readonly payload: AppNotification|undefined) {}
}

export class MarkAsRead {
  static readonly type = '[Notifications] MarkAsRead';
  constructor(public readonly payload: AppNotification |undefined) {}
}

export class MarkAllAsRead {
  static readonly type = '[Notifications] MarkAllAsRead';
}