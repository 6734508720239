import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngxs/store';
import Swal from 'sweetalert2';
import { CheckoutOrderService } from '../billing-proxy/billing-management/pay-pal/checkout-order.service';
import { getBilling } from '../billing-store/billing.action';
@Component({
  selector: 'app-billing-cash-payment-view',
  templateUrl: './billing-cash-payment-view.component.html',
  styleUrls: ['./billing-cash-payment-view.component.scss']
})
export class BillingCashPaymentViewComponent implements OnInit {
  claimId: string = "";
  saleOrderId: string = "";
  Name: string = "";
  dob: string;
  transactionAmount: number;
  // itemName: string = "";
  // amount: number;
  items: any[] = [];
  constructor(private formBuilder: FormBuilder,
    private store: Store,
    private activatedRoute: ActivatedRoute,
    private paymentService: CheckoutOrderService
  ) { }
  CashForm: FormGroup;
  ngOnInit(): void {
    this.CashForm = this.formBuilder.group({
      txtNewPassword: new FormControl(""),
      //txtRepeatPassword: new FormControl(""),
    });
    this.activatedRoute.paramMap.subscribe(response => {
      this.claimId = response.get('id');
    })

    this.getPaymentCapture();

    // this.getPaymentView();
  }

//! capture api
getPaymentCapture(){
  this.paymentService.getPaymentCapture(this.claimId).subscribe(response => {
    let orderData = response;
      this.saleOrderId = orderData.saleorderId;
      this.dob = orderData?.saleOrder?.patients?.personals?.dateOfBirth == null ? undefined : new Date(orderData?.saleOrder?.patients?.personals?.dateOfBirth)?.toLocaleDateString("en-US");
      this.Name = orderData?.saleOrder?.patients?.personals?.fullName;
      this.transactionAmount = Number(orderData?.saleOrder?.paymentTransactionDetails?.payPalCheckOutOrderPayments[0]?.totalPaidAmount)||0;
      orderData?.saleOrder?.items.forEach(element => {
        this.items.push({
          itemName: element.name,
          amount: element.charge,
          code: element.itemProcedureCode
        })

      });
    }, err => {
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    }, () => {
    })

  }

//! payment view
  getPaymentView() {
    this.store.dispatch(new getBilling(this.claimId)).subscribe(response => {

      let orderData = response?.billingState?.selectedBilling?.saleOrder;
      this.saleOrderId = orderData.saleorderId;
      this.dob = orderData?.patients?.personals?.dateOfBirth == null ? undefined : new Date(orderData?.patients?.personals?.dateOfBirth)?.toLocaleDateString("en-US");
      this.Name = orderData?.patients?.personals?.fullName
      orderData.items.forEach(element => {
        this.items.push({
          itemName: element.name,
          amount: element.charge,
          code: element.itemProcedureCode
        })
      });

    })
  }

}
