<mat-dialog-content class="mat-typography">
  <div class="row">
    <div class="col-lg-6">
      <h2>Pickup Exchange Details</h2>
    </div>
    <div class="col-lg-6 text-right">
      <button class="text-right close" aria-label="Close" mat-dialog-close>
        <b> X </b>
      </button>
    </div>
  </div>
  <div class="modal-body">
    <form [formGroup]="pickupExchangeUploadForm">
      <mat-card-content>
        <div class="row">
          <div class="col-6">
            <div class="d-flex align-items-center">
              <button class="file-upload-button cursor">
                <div class="row" style="padding-left: 72px">
                  <div class="brow-align">Browse</div>
                  <mat-icon class="cursor">cloud_upload</mat-icon>
                </div>
                <input
                  id="file-upload"
                  type="file"
                  formControlName="documentFile"
                  class="cursor"
                  (change)="getSelectedFilesDetails(uploadFileReference.files)"
                  [multiple]="false"
                  #uploadFileReference
                  accept="application/pdf"
                />
              </button>
            </div>
            <!--Error Messages-->
            <span *ngIf="showErrorForFileLength">
              <div class="row">
                <div class="col-12">
                  <span
                    class="asterisk"
                    style="padding-left: 85px !important"
                    >{{ "Select Only 10 files" }}</span
                  >
                </div>
              </div>
            </span>
            <span
              *ngIf="showErrorForFileType"
              style="padding-left: 85px !important"
            >
              <div class="row">
                <div class="col-12">
                  <span
                    class="asterisk"
                    style="padding-left: 85px !important"
                    >{{ "Select Only PDF`s" }}</span
                  >
                </div>
              </div>
            </span>
            <span
              *ngIf="showErrorBasedOnSize"
              style="padding-left: 85px !important"
            >
              <div class="row">
                <div class="col-12">
                  <span class="asterisk" style="padding-left: 85px !important">
                    {{ "Select each file`s size of 10mb or Less" }}</span
                  >
                </div>
              </div>
            </span>
            <!--Error Messages End-->
          </div>

          <!--Return Date-->
          <mat-form-field
            *ngIf="selectedFileUploadsData === null"
            class="col-6"
          >
            <mat-label>
              Return Completed Date
              <span class="asterisk">*</span></mat-label
            >
            <input
              [max]="maxDate"
              matInput
              maxlength="40"
              formControlName="txtReturnDate"
              type="text"
              placeholder="(MM/DD/YYYY)"
              autocomplete="new-txtReturnDate"
              matInput
              [matDatepicker]="txtReturnDate"
              [min]="minDate"
            />
            <mat-datepicker-toggle matSuffix [for]="txtReturnDate">
            </mat-datepicker-toggle>
            <mat-datepicker #txtReturnDate> </mat-datepicker>
            <mat-error
              *ngIf="
                pickupExchangeUploadForm?.get('txtReturnDate')?.touched &&
                pickupExchangeUploadForm?.get('txtReturnDate')?.errors &&
                !(
                  pickupExchangeUploadForm <=
                  pickupExchangeUploadForm?.get('txtReturnDate')?.value
                )
              "
            >
              Enter Valid date
            </mat-error>
            <mat-error
              *ngIf="
                pickupExchangeUploadForm?.get('txtReturnDate')?.touched &&
                pickupExchangeUploadForm?.get('txtReturnDate')?.errors?.required
              "
            >
              Pickup/Exchange Date is a required field!
            </mat-error>
            <mat-error
              *ngIf="
                pickupExchangeUploadForm <=
                  pickupExchangeUploadForm?.get('txtReturnDate')?.value &&
                !(
                  pickupExchangeUploadForm?.get('txtReturnDate')?.touched &&
                  pickupExchangeUploadForm?.get('txtReturnDate')?.errors
                    ?.required
                )
              "
            >
              Enter Past Dates Only!
            </mat-error>
          </mat-form-field>
        </div>
      </mat-card-content>

      <div
        class="table-responsive pt-3"
        *ngIf="arrSelectedDocuments && arrSelectedDocuments?.length !== 0"
      >
        <table class="users table table-sm table-bordered">
          <thead style="display: table-header-group">
            <tr>
              <!-- <th class="table-light" scope="col">S.No</th> -->
              <th class="table-light" scope="col">Document Name</th>
              <th class="table-light" scope="col">Document Type</th>
              <th class="table-light" scope="col">Document Size</th>
            </tr>
          </thead>
          <tbody style="display: table-row-group">
            <tr
              scope="row"
              *ngFor="let document of arrSelectedDocuments; let i = index"
            >
              <td class="table-light">
                <p class="text-muted mb-0">
                  <span> {{ i + 1 }} </span>
                </p>
              </td>
              <td class="table-light">
                <p class="text-muted mb-0">
                  <span> {{ document.fileName }} </span>
                </p>
              </td>
              <td class="table-light">
                <p class="text-muted mb-0">
                  <span> {{ document.fileType }} </span>
                </p>
              </td>
              <td class="table-light">
                <p class="text-muted mb-0">
                  <span> {{ document.fileSize }} </span>
                </p>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </form>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button
    class="buttonColor"
    mat-button [disabled]="!(!pickupExchangeUploadForm?.invalid && pickupExchangeUploadForm?.dirty)"
    (click)="saveReturnDetails(pickupExchangeUploadForm?.get('documentFile')?.value)"
  >
    Save
  </button>

  <button class="resetclr" (click)="resetReturnUploadForm()" mat-button>
    Reset
  </button>
</mat-dialog-actions>
