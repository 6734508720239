import { PagedAndSortedResultRequestDto } from '@abp/ng.core';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { DocumentBinService } from 'projects/admin/src/app/admin-proxy/platform-app-management/rcm/platform-management/document-bin/document-bin.service';
import { ActiveStatus } from 'projects/admin/src/app/admin-proxy/platform-app-management/rcm/platform-management/localization/enumeration/active-status.enum';
import { DiscountPercentService } from 'projects/order/src/app/order-proxy/order-management/order-optimization/discount-percent.service';
import { CommunicationService } from 'projects/shared/src/app/services/communication.service';
import { Subscription } from 'rxjs';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-mater-discount',
  templateUrl: './mater-discount.component.html',
  styleUrls: ['./mater-discount.component.scss']
})
export class MaterDiscountComponent implements OnInit {

  selectedOption: number = ActiveStatus.Active;
  isShowSpinner: boolean;
  discountDataList: any;
  subscription$: Subscription[] = [];
  tableData$: Subscription;
  constructor(  private discountService: DiscountPercentService, private communicationService: CommunicationService) { 
    this.communicationService.functionReloadDisocuntTable$.subscribe(() => {
      this.getDiscounttblList();
    });
  }

  ngOnInit(): void {

    this.getDiscounttblList();
  }

  getDiscounttblList() {
    this.isShowSpinner = true;
    this.tableData$?.unsubscribe();
    const documentBinList = this.discountService.getDiscountList(this.selectedOption)
    .subscribe((response) => {
        this.discountDataList = response.items;
        this.isShowSpinner = false;
      },
      (err) => {
        this.isShowSpinner = false;
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      }
    );
    // this.subscription$.push(documentBinList);
    this.tableData$ = documentBinList;
  }

  // getDiscounttblList() {
  //   this.isShowSpinner = true;
  //   this.tableData$?.unsubscribe();
  //   const documentBinList = this.discountService.getList(new PagedAndSortedResultRequestDto())
  //   .subscribe((response) => {
  //       this.discountDataList = response.items;
  //       this.isShowSpinner = false;
  //     },
  //     (err) => {
  //       this.isShowSpinner = false;
  //       const data: HttpErrorResponse = err;
  //       Swal.fire({
  //         icon: 'info',
  //         text: data?.error?.error?.message,
  //       });
  //     }
  //   );
  //   // this.subscription$.push(documentBinList);
  //   this.tableData$ = documentBinList;
  // }
}
