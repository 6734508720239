//#region
import { HttpErrorResponse } from '@angular/common/http';
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { InboundDocumentService } from 'projects/admin/src/app/admin-proxy/platform-app-management/rcm/platform-management/inbound/inbound-document.service';
import { Observable, Subject, Subscription } from 'rxjs';
import Swal from 'sweetalert2';
import {
  SortingShortCode,
  TextAnnotation,
  defaultGuid,
  sotingSelectedCSS,
} from '../../enums/allenums.enum';
import { ToastrService } from 'ngx-toastr';
import {
  AnnotatedDTO,
  SortedDocumentGroupDTO,
} from 'projects/admin/src/app/admin-proxy/platform-app-management/rcm/platform-management/inbound/dto';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SaveDocumentDto } from 'projects/admin/src/app/admin-proxy/platform-app-management/rcm/platform-management/sorting/dto';
import { SortType } from 'projects/admin/src/app/admin-proxy/platform-app-management/rcm/platform-management/localization/enumeration';
import { ListService } from '@abp/ng.core';
import {
  DiagnosisCode10DTO,
  MachineTypeDTO,
  SymptomsDTO,
} from 'projects/patient/src/app/dropdown-proxy/dropdown-management/dropdowns/dto/patient';
import { doctorInterface } from 'projects/billing/src/app/billing-claim-info/billing-claim-info.component';
import { MatDialog } from '@angular/material/dialog';
import { PatientIcd10Component } from 'projects/patient/src/app/patient-icd10/patient-icd10.component';
import { PatientDoctorComponent } from 'projects/patient/src/app/patient-doctor/patient-doctor.component';
import {
  DiagnosisCode10Service,
  PatientDropdowns,
} from 'projects/patient/src/app/dropdown-proxy/dropdown-management/dropdowns';
import { PatientMasterDropdownService } from 'projects/patient/src/app/dropdown-proxy/dropdown-management/dropdowns/master-dropdown-service';
import { debounceTime, map, startWith } from 'rxjs/operators';
import { DoctorService } from 'projects/patient/src/app/patient-proxy/patient/doctor.service';
import { PatientDocumentService } from 'projects/patient/src/app/patient-proxy/patient-optimization/patient-document.service';
import {
  CreateUpdateComplainceDTO,
  CreateUpdateDiagnosticSleepStudyDTO,
  CreateUpdatePrescriptionDTO,
  CreateUpdateSleepStudyDTO,
  CreateUpdateTitrationSleepStudyDTO,
} from 'projects/patient/src/app/patient-proxy/patient/dto';
import {
  CreateUpdateChecklistSortingDTO,
  CreateUpdateInitialFaceToFaceDTO,
} from 'projects/patient/src/app/patient-proxy/patient-optimization/dto';
import { ActivatedRoute } from '@angular/router';
import { DatePipe } from '@angular/common';
import { PatientMedicareCheckListDetailsService } from 'projects/patient/src/app/patient-proxy/patient-optimization/patient-medicare-check-list-details.service';
import { ImageType } from 'projects/patient/src/app/patient-proxy/patient/enumeration-data/image-type.enum';

//#endregion

@Component({
  selector: 'app-common-sorting-view',
  templateUrl: './common-sorting-view.component.html',
  styleUrls: ['./common-sorting-view.component.scss'],
  providers: [ListService, DatePipe],
})
export class CommonSortingViewComponent implements OnInit {
  @Input() medicareId: string;
  @Input() flag: ImageType;
  @Input() docId: string;
  @Input() previewList: number[];
  @Input() isMedicare: boolean = false;
  @Output() prevent_unsaved_sorting = new EventEmitter();
  @Output() prevent_unsaved_annotations = new EventEmitter();
  @Output() headerText = new EventEmitter();
  @Output() emitPageNo = new EventEmitter();
  patientId: string;
  blobName: string;
  pdfheight: string = '100%';
  overAllPages: number = 0;
  overAllPagesBG: number = 0;
  sortedPages: number = 0;
  sortedPagesBG: number = 0;
  pendingPages: number = 0;
  pendingPagesBG: number = 0;
  isLoadSortingView: boolean;
  isSortingStart = false;
  isDisabled = false;
  checkValidInputCase = 0;
  arrowButtonClicked = false;
  @ViewChildren('myInputRefs') myInputRefs: QueryList<ElementRef>;
  saveDocumentDetails: SaveDocumentDto[] = [];
  isBTNdisable: boolean = true;
  document_documentTypeId: any;
  // newSetupForm: FormGroup;
  physicianDrpdwn: any;
  SortingShortCode = SortingShortCode;
  documentTypes = [];
  selectedImgDoc: any[];
  TotalDocumentImageList: any = [];
  SortedDocumentImageList: any = [];
  PendingDocumentImageList: any = [];
  BinDocumentImageList: any = [];
  isMoved: boolean = false;
  filteredDoctors: Observable<doctorInterface[]> | undefined; //Filtered Referring Provider Doctor Types
  lstDoctor: any[];

  drpSymptoms: any[] = [];
  drpMachines: MachineTypeDTO[] = [];
  filteredSymptoms: Observable<SymptomsDTO[]> | undefined;
  filteredDiagnosiss: Observable<DiagnosisCode10DTO[]> | undefined;
  filteredRxDiagnosiss: Observable<DiagnosisCode10DTO[]> | undefined;
  filteredMachines: Observable<MachineTypeDTO[]> | undefined;
  drpDiagnosisICD10: DiagnosisCode10DTO[] = [];
  filteredRxDoctors: Observable<doctorInterface[]> | undefined;
  isSaveDisabled = false;
  private saveSubject: Subject<void> = new Subject<void>();

  RxdateMin: Date;
  RxdateMax: Date;
  isLoading = false;
  @ViewChild('canvas', { static: true }) canvasRef!: ElementRef;
  ctx!: CanvasRenderingContext2D;
  pageNumber: number = 1;
  totalPages: number = 0.3;
  // readonly DEFAULT_ZOOM_LEVEL = 1;
  // readonly MAX_ZOOM_LEVEL = 2.5;
  // readonly MIN_ZOOM_LEVEL = 0.5;
  readonly DEFAULT_ZOOM_LEVEL = 0.7;
  readonly MAX_ZOOM_LEVEL = this.DEFAULT_ZOOM_LEVEL + 4;
  readonly MIN_ZOOM_LEVEL = 0.3;
  zoomLevel: number = this.DEFAULT_ZOOM_LEVEL; // Default Size
  zoomLevelReSize:number = this.DEFAULT_ZOOM_LEVEL;
  existingDocumentImageList: any = [];
  selectedExistingDocument: any = null;
  loadingMessage: string = 'Loading ...';
  isDrawing: boolean = false;
  startX: number = 0;
  startY: number = 0;
  lineColor: string = '#F6BE00';
  textColor: string = '#0000FF';
  savedState: ImageData | null = null;
  textAnnotations: TextAnnotation[] = [];
  isMouseDown: boolean = false;
  isMouseMove: boolean = false;
  isMouseUp: boolean = false;
  isMouseLeave: boolean = false;

  rectWidth: number;
  rectHeight: number;
  strPdfString: string = '';
  isHideTiffViewer: boolean = false;
  TiffError: boolean = true;
  SortSaveError: boolean = true;
  readonly DEFAULT_LAZY_LOADING = 10;
  from: number = 1;
  to: number = this.DEFAULT_LAZY_LOADING;
  readonly DEFAULT_LAZY_LOADING_API = 10; // If change the Value API side also need to change 10 to X
  // readonly DEFAULT_LAZY_LOADING_Index = 9;
  pendingFrom: number = 0;
  pendingTo: number = this.DEFAULT_LAZY_LOADING_API; // If change the Value API side also need to change 10 to X
  sortedFrom: number = 0; // Index based
  sortedTo: number = this.DEFAULT_LAZY_LOADING_API; // If change the Value API side also need to change 10 to X
  showPdfViwer: boolean = false;
  strSelectedPdfPath: string = '';
  strSelectedPdfPathCopy: string = '';
  sotingSelectedCSS = sotingSelectedCSS;
  // selectedOption: sotingSelectedCSS = sotingSelectedCSS.Total;
  selectedOption: sotingSelectedCSS = null;
  tiffPageNo: number = 0;
  disableAnnotation: boolean = false;
  currentArrayIndex: number = 0;
  isSortingStatus: boolean = false;
  TotalAPICallDone: boolean = false;
  PendingAPICallDone: boolean = false;
  pendingTimeout: any;
  isTotalAPIcallGoingOn: boolean = false;
  isDocumentLoadBefore: boolean = false;
  firstTotalAPICall: boolean = false;

  private saveAnnotationAPICall: Subscription;
  loadInboundDocumentAPICall$: Subscription;
  SortedloadInboundDocumentAPICall$: Subscription;
  PendingloadInboundDocumentAPICall$: Subscription;
  loadloadSelectedImageListAPICall$: Subscription;
  subscription$: Subscription[] = [];
  emitPageNoList = [];
  loadloadOverAllSortingAPICall$: Subscription;
  documentPageNo:any;
  selectedPageNoIndex:string;
  constructor(
    private inboundDocumentService: InboundDocumentService,
    private sanitizer: DomSanitizer,
    private toastr: ToastrService,
    private snackBar: MatSnackBar,
    private patientMedicareCheckListDetailsService: PatientMedicareCheckListDetailsService,
    // private matDialog: MatDialog,
    // private matDoctorDialog: MatDialog,
    // private matIcd10Dialog: MatDialog,
    public list: ListService // private doctorService: DoctorService,
  ) // private patientDropdownService: PatientMasterDropdownService,
  // private diagnosis10: DiagnosisCode10Service,
  // private fb: FormBuilder,
  // private datepipe: DatePipe
  { }

  ngOnInit(): void {
    this.isMedicare && this.getPreviousSavedImageList();
    !this.isMedicare && this.getNonMedicareImageList();
    if (!this.isEmpty(this.docId)) {
      this.sortingDocView();
      this.loadPatientDemoForm(this.docId);
    }
  }

  ngOnDestroy(): void {
    if (this.saveAnnotationAPICall) {
      this.saveAnnotationAPICall?.unsubscribe();
    }
    if (this.loadInboundDocumentAPICall$) {
      this.loadInboundDocumentAPICall$.unsubscribe();
    }
    if (this.SortedloadInboundDocumentAPICall$) {
      this.SortedloadInboundDocumentAPICall$.unsubscribe();
    }
    if (this.PendingloadInboundDocumentAPICall$) {
      this.PendingloadInboundDocumentAPICall$.unsubscribe();
    }
    if (this.loadloadOverAllSortingAPICall$) {
      this.loadloadOverAllSortingAPICall$.unsubscribe();
    }
    this.subscription$?.forEach((sub) => {
      sub && sub?.unsubscribe();
    });
  }

  loadPatientDemoForm(docId: string) {
    const getPatientDetails = this.inboundDocumentService
      .getPatientDemographicsDetails(docId)
      .subscribe(
        (response) => {
          this.blobName = response?.blobName || 'no.pdf';
          // After Load Once BlobName set
          this.headerText.emit({
            defaultPatientId: response?.defaultPatientId || '-',
            defaultFaxId: response?.defaultFaxId || '-',
            chartId: response?.chartId || '-',
            fullName: (
              (response?.firstName || '') +
              ' ' +
              (response?.middleName || '') +
              ' ' +
              (response?.lastName || '')
            ).trim(),
          });
        },
        (err) => {
          const data: HttpErrorResponse = err;
          console.warn(data?.error?.error?.message);
        }
      );
  }

  getPreviousSavedImageList() {
    const sgetPreviousSavedImageList =
      this.patientMedicareCheckListDetailsService
        .getImageResultByDocIdAndImgAndMedicareId(
          this.docId,
          this.flag,
          this.medicareId,
          this.previewList
        )
        .subscribe((response) => {

          // this.SortedDocumentImageList=response?.sortedDocuments || [];
          const sortedDocuments = response || [];

          if (sortedDocuments) {

            const filteredFiles = sortedDocuments.filter((file) =>
              this.previewList.includes(file.pageNo)
            );

            this.splitDocumentImageList = filteredFiles.map((a) => {
              return {
                ...a,
                src: this.sanitizer.bypassSecurityTrustResourceUrl(
                  `data:image/tiff;base64, ${a.pageContent}`
                ),
                pageNo: a.pageNo,
              };
            });
          }
        },
          (err) => {

          }
        );

    this.subscription$.push(sgetPreviousSavedImageList);
  }

  // sortingDocView() {
  //   this.isLoadSortingView = true;
  //   this.isDisabled = true;
  //   const sortingDocViewList = this.inboundDocumentService
  //     .getSortedDocumentPagesv1ByGDocumentId(this.docId)
  //     .subscribe(
  //       (response) => {
  //         this.documentTypes = response?.ltDocuments || [];
  //         if (response) {
  //           this.loadBinDocument(
  //             response?.ltDocuments[1]?.shortCode,
  //             response?.ltDocuments[1]?.pageCount
  //           );
  //         } else {
  //           this.clearCanvas();
  //           this.TiffError = true;
  //           this.loadingMessage = ''; // Images has not found
  //           this.isLoading = false;
  //         }
  //         this.isLoadSortingView = false;
  //         this.isDisabled = false;
  //         this.documentTypes.sort((a, b) => a.documentType.localeCompare(b.documentType));
  //       },
  //       (err) => {
  //         // this.strSelectedPdfPath = '';
  //         this.isLoadSortingView = false;
  //         this.isDisabled = false;
  //         this.isLoading = false;
  //         this.loadingMessage='';
  //         this.TiffError = true
  //         // Check if the error has the expected structure
  //         if (err.error && err.error.message) {
  //           // Parse the JSON error message and extract the specific message
  //           try {
  //             const errorMessageObject = JSON.parse(err.error.message);
  //             const specificErrorMessage = errorMessageObject.error.message;

  //             // Print the specific error message
  //             console.warn('Specific Error Message:', specificErrorMessage);

  //             // Show a warning toaster with the specific error message
  //             this.toastr.warning('Reason: ' + specificErrorMessage, 'Warning');
  //           } catch (parseError) {
  //             // Log a warning if there is an issue parsing the error message
  //             console.warn('Error parsing error message:', parseError);
  //             this.toastr.warning('Reason: ' + parseError, 'Warning');
  //           }
  //         } else {
  //           // If the error structure is unexpected, log the entire error message
  //           console.warn('Unexpected error structure:', err);
  //           this.toastr.error('Unexpected error structure Error', 'Error');
  //         }
  //       }
  //     );
  //   this.subscription$.push(sortingDocViewList);
  // }

  sortingDocView() {
    this.isLoadSortingView = true;
    this.isDisabled = true;
    const sortingDocViewList = this.inboundDocumentService
      // .getSortedDocumentPagesv2ByGDocumentId(this.docId)
      .getSortedPatientDocumentsByGPatientDocId(this.docId)
      .subscribe(
        (response) => {
          this.documentTypes = response?.ltDocuments || [];
          const indexToRemove = this.documentTypes.findIndex(
            (doc) => doc.shortCode === SortingShortCode.All
          );

          if (indexToRemove !== -1) {
            this.documentTypes.splice(indexToRemove, 1);
          }
          this.documentTypes.sort((a, b) => a.documentType.localeCompare(b.documentType));

          const pageNos = response?.ltDocuments[0]?.docPage;
          this.document_documentTypeId = response?.ltDocuments[0].documentTypeId;

          if (this.documentTypes.length > 0 && pageNos !== null) {
            this.getSelectedImageList(this.docId, pageNos, response?.ltDocuments[0]?.pageCount, response?.ltDocuments[0].documentTypeId);
          }else{
            this.clearCanvas();
            this.TiffError = true;
            this.loadingMessage = ''; // Images has not found
            this.isLoading = false;
            this.isHideTiffViewer = false;
          }
          this.isLoadSortingView = false;
          this.isDisabled = false;
        },
        (err) => {
          this.isHideTiffViewer = true;
          this.isLoadSortingView = false;
          this.isDisabled = false;
          this.isLoading = false;
          this.loadingMessage = '';
          this.TiffError = true;
          // Check if the error has the expected structure
          if (err.error && err.error.message) {
            // Parse the JSON error message and extract the specific message
            try {
              const errorMessageObject = JSON.parse(err.error.message);
              const specificErrorMessage = errorMessageObject.error.message;

              // Print the specific error message
              console.warn('Specific Error Message:', specificErrorMessage);

              // Show a warning toaster with the specific error message
              this.toastr.warning('Reason: ' + specificErrorMessage, 'Warning');
            } catch (parseError) {
              // Log a warning if there is an issue parsing the error message
              console.warn('Error parsing error message:', parseError);
              this.toastr.warning('Reason: ' + parseError, 'Warning');
            }
          } else {
            // If the error structure is unexpected, log the entire error message
            console.warn('Unexpected error structure:', err);
            this.toastr.error('Unexpected error structure Error', 'Error');
          }
        }
      );
    this.subscription$.push(sortingDocViewList);
  }

  // getMedicareImageBase64ByGDocIdAndPageNos

  loadOverAllSortingAPI() {
    // this.sortingDocView()
    // this.isLoadSortingView = true;
    if (this.loadloadOverAllSortingAPICall$) {
      this.loadloadOverAllSortingAPICall$?.unsubscribe();
    }
    const loadInboundDocument = this.inboundDocumentService
      .getSortedDocumentPagesv1ByGDocumentId(this.docId)
      .subscribe(
        (response) => {
          // this.documentTypesV1 = response?.ltDocuments || [];
          this.documentTypes = response?.ltDocuments || [];
          this.isLoadSortingView = false;

          // Function to add docPageContent to documentTypesV1
          this.documentTypes.forEach((docType) => {
            if (docType.docPage) {
              docType.docPageContent = this.TotalDocumentImageList.filter(
                (doc) => docType.docPage.includes(doc.pageNo)
              );
            } else {
              docType.docPageContent = [];
            }
          });
        },
        (err) => {
          // this.handleErrorResponse(err);
        }
      );
    this.loadloadOverAllSortingAPICall$ = loadInboundDocument;
  }

  private handleErrorResponse(err: any) {
    const data: HttpErrorResponse = err;
    console.warn(data?.error?.error?.message);
    this.loadingMessage = 'TIFF Image conversion has been In Progress.';
    this.TiffError = true;
    this.SortSaveError = true;
    this.isLoading = false;
  }

  loadBinDocument(documentTypeId: string, pagecount: number) {
    if (this.mustSaveAnnotations()) {
      this.tiffPageNo = 1;
      this.pageNumber = 1;
      this.currentArrayIndex = 0;
      if (pagecount !== 0) {
        const filteredDocuments = this.documentTypes.filter(
          (doc) => doc.documentTypeId === documentTypeId
        );
        // Extract docPageContent
        const docPageContent = filteredDocuments.map(
          (doc) => doc.docPageContent
        );
        this.isLoading = true;
        this.totalPages = docPageContent[0]?.length || 0;
        if (this.totalPages > 0) {
          this.existingDocumentImageList = docPageContent[0].map((a) => {
            return {
              ...a,
              src: this.sanitizer.bypassSecurityTrustResourceUrl(
                `data:image/tiff;base64, ${a.file}`
              ),
              pageNo: a.pageNo,
            };
          });
          let findPageNoIndex = this.existingDocumentImageList?.findIndex(doc => String(doc?.pageNo).includes(this.selectedPageNoIndex));
          if (findPageNoIndex === -1) {
            findPageNoIndex = 0;
            this.pageNumber = 1;
          }else{
            this.pageNumber = findPageNoIndex + 1;
          }
          this.loadTiffImageV1(this.existingDocumentImageList?.[findPageNoIndex]);
        } else {
          this.clearCanvas();
          this.TiffError = true;
          this.loadingMessage = ''; // Images has not found
        }
        this.isLoading = false;
      } else {
        this.TiffError = true;
        this.loadingMessage = ''; // Images has not found
        setTimeout(() => {
          this.clearCanvas();
        }, 500);
      }
      // this.document_documentTypeId = documentTypeId;
      // this.selectedOption = sotingSelectedCSS.Bin;
    } else {
      this.pageNumber =
        this.existingDocumentImageList[this.currentArrayIndex].pageNo;
    }
  }

  showNotification(message: string): void {
    this.snackBar.open(message, 'Close', {
      duration: 3000, // Duration in milliseconds
      horizontalPosition: 'end', // Positioning the notification horizontally
      verticalPosition: 'top', // Positioning the notification vertically
    });
  }

  isEmpty(value: any): boolean {
    return (
      value === null ||
      value === undefined ||
      value === '' ||
      value === defaultGuid
    );
  }

  loadExistingImage(docDetails: any) {
    this.loadTiffImageV1(docDetails);
  }

  zoomIn() {
    if (this.zoomLevel < this.MAX_ZOOM_LEVEL) {
      this.zoomLevel += 0.1;
      this.zoomLevelReSize = this.zoomLevel;
      this.redrawAnnotationsZoom(); // Redraw image with new zoom level
    }
  }

  // Function to zoom out
  zoomOut() {
    if (this.zoomLevel > this.MIN_ZOOM_LEVEL) {
      this.zoomLevel -= 0.1;
      this.zoomLevelReSize = this.zoomLevel;
      this.redrawAnnotationsZoom(); // Redraw image with new zoom level
    }
  }

  previousImage() {
    if (this.mustSaveAnnotations()) {
      this.currentArrayIndex--;
      if (0 < this.currentArrayIndex) {
        this.pageNumber = this.currentArrayIndex + 1;
        this.loadExistingImage(
          this.existingDocumentImageList?.[this.currentArrayIndex]
        );
      } else {
        this.firstImage();
      }
    }
  }

  nextImage() {
    if (this.mustSaveAnnotations()) {
      this.currentArrayIndex++;
      if (this.currentArrayIndex < this.existingDocumentImageList.length) {
        this.pageNumber = this.currentArrayIndex + 1;
        this.loadExistingImage(
          this.existingDocumentImageList?.[this.currentArrayIndex]
        );
      } else {
        this.lastImage();
      }
    }
  }

  firstImage() {
    if (this.mustSaveAnnotations()) {
      this.currentArrayIndex = 0;
      this.pageNumber = this.currentArrayIndex + 1;
      this.loadExistingImage(
        this.existingDocumentImageList?.[this.currentArrayIndex]
      );
    }
  }

  lastImage() {
    if (this.mustSaveAnnotations()) {
      this.currentArrayIndex = this.existingDocumentImageList.length - 1;
      this.pageNumber = this.currentArrayIndex + 1;
      this.loadExistingImage(
        this.existingDocumentImageList?.[this.currentArrayIndex]
      );
    }
  }

  // Function to navigate to a specific page
  goToPage() {
    if (this.mustSaveAnnotations()) {
      if (this.existingDocumentImageList.length === 0) {
        this.TiffError = true;
        this.loadingMessage = ''; // Images has not found
        setTimeout(() => {
          this.clearCanvas();
        }, 500);
      } else if (this.pageNumber <= 0) {
        this.pageNumber = 1;
        this.firstImage();
      } else if (this.pageNumber >= 1 && this.pageNumber <= this.totalPages) {
        this.currentArrayIndex = this.pageNumber - 1;
        this.loadExistingImage(
          this.existingDocumentImageList[this.currentArrayIndex]
        );
      } else {
        this.pageNumber = this.totalPages;
        this.lastImage();
      }
    } else {
      this.pageNumber =
        this.existingDocumentImageList[this.currentArrayIndex].pageNo;
    }
  }

  mustSaveAnnotations(): boolean {
    return true;
  }

  // Function to draw the image on the canvas with the current zoom level
  drawZoomedImage() {
    const docDetails = this.selectedExistingDocument;
    const tiffBase64 = docDetails.file;
    const canvas = this.canvasRef.nativeElement;
    this.ctx = canvas.getContext('2d');
    const img = new Image();
    img.onload = () => {
      // Calculate the position to draw the image so that it remains centered
      const imageWidth = img.width * this.zoomLevel;
      const imageHeight = img.height * this.zoomLevel;
      // Clear canvas before drawing
      this.ctx.clearRect(0, 0, canvas.width, canvas.height);

      // Adjust canvas size based on zoom level
      canvas.width = imageWidth;
      canvas.height = imageHeight;
      // Draw image with adjusted dimensions and centered position
      this.ctx.drawImage(img, 0, 0, imageWidth, imageHeight);
    };
    img.src = 'data:image/tiff;base64,' + tiffBase64;
  }

  convertCanvasToBase64() {
    const canvas = this.canvasRef.nativeElement;
    return canvas.toDataURL('image/tiff');
  }

  clearAllAnnotation() {
    const confirmation = confirm(
      'Are you sure you want to delete all annotation text?'
    );
    if (confirmation) {
      this.clrAllAnnotation();
      this.zoomLevel = this.zoomLevelReSize;
    }
  }

  private handleError(error: any) {
    const data: HttpErrorResponse = error;
    this.toastr.error(data?.error?.error?.message, 'Error');
    this.disableAnnotation = true;
  }

  loadTiffImageV1(docDetails: any) {
    if (docDetails && docDetails.file) {
      const selectedPageNoIndex = docDetails.pageNo.toString();
      let findPageNoIndex = this.existingDocumentImageList?.findIndex(doc => String(doc?.pageNo).includes(selectedPageNoIndex));
      if (findPageNoIndex === -1) {
        findPageNoIndex = 0;
        this.pageNumber = 1;
      }else{
        this.pageNumber = findPageNoIndex + 1;
        this.currentArrayIndex=this.pageNumber - 1;
      }
      // this.zoomLevel = this.DEFAULT_ZOOM_LEVEL;
      const canvas = this.canvasRef.nativeElement;
      this.ctx = canvas.getContext('2d');
      const img = new Image();
      img.onload = () => {
        // Calculate the position to draw the image so that it remains centered
        // const imageWidth = img.width * this.DEFAULT_ZOOM_LEVEL;
        // const imageHeight = img.height * this.DEFAULT_ZOOM_LEVEL;
        const imageWidth = img.width * this.zoomLevel;
        const imageHeight = img.height * this.zoomLevel;
        // Clear canvas before drawing
        this.ctx.clearRect(0, 0, canvas.width, canvas.height);

        // Adjust canvas size based on zoom level
        canvas.width = imageWidth;
        canvas.height = imageHeight;
        this.ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
        // Scroll to the selected image
        const selectedImageElement = document.querySelector(
          '.custom-thumbnail-view-selected'
        );
        if (selectedImageElement) {
          selectedImageElement.scrollIntoView({
            behavior: 'auto',
            block: 'center',
          });
        }
      };
      img.src = 'data:image/tiff;base64,' + docDetails.file;

      this.tiffPageNo = docDetails.pageNo || 0;
      this.selectedExistingDocument = docDetails;
      this.TiffError = false;
      this.loadingMessage = '';
    } else {
      this.TiffError = true;
      this.loadingMessage = ''; // Images has not found/Empty
    }
  }
  splitDocumentImageList: any = [];

  //   checkNextIndex() {
  //     const pageNumToFind = this.selectedExistingDocument.pageNo;
  //     let nextIndex = 0;
  //     const index = this.existingDocumentImageList.findIndex(
  //       (item) => item.pageNo === pageNumToFind
  //     );

  //     if (index !== -1) {
  //       nextIndex = (index + 1) % this.existingDocumentImageList.length;
  //     }
  //     return nextIndex;
  //   }

  handleDoubleClick(event: MouseEvent) {
    if ( this.selectedExistingDocument && !this.splitDocumentImageList.some(
        (item) => item.pageNo === this.selectedExistingDocument.pageNo
      )
    ) {
      this.splitDocumentImageList.push(this.selectedExistingDocument);
      this.emitPageNoList = this.splitDocumentImageList.map(
        (document) => document.pageNo
      );
      this.emitPageNo.emit(this.emitPageNoList);
    }
    this.nextImage();
  }

  // redrawAnnotationsZoom() {
  //   if (!this.isLoading) {
  //     const canvas = this.canvasRef.nativeElement;
  //     this.ctx = canvas.getContext('2d');
  //     this.ctx.clearRect(0, 0, canvas.width, canvas.height);
  //     const img = new Image();
  //     img.onload = () => {
  //       const imageWidth = img.width * this.zoomLevel;
  //       const imageHeight = img.height * this.zoomLevel;
  //       // Clear canvas before drawing
  //       this.ctx.clearRect(0, 0, canvas.width, canvas.height);
  //       // Adjust canvas size based on zoom level
  //       canvas.width = imageWidth;
  //       canvas.height = imageHeight;
  //       // Draw image with adjusted dimensions and centered position
  //       this.ctx.drawImage(img, 0, 0, imageWidth, imageHeight);

  //       // this.ctx.drawImage(img, 0, 0);
  //       this.textAnnotations.forEach((annotation) => {
  //         // // Adjust font size for zoom
  //         const fontSize = 45 * this.zoomLevel; // Change 14 to your base font size
  //         this.ctx.font = `${fontSize}px Arial`;

  //         // this.ctx.font = '14px Arial';

  //         // Adjust position for zoom
  //         const adjustedX = annotation.x * this.zoomLevel;
  //         const adjustedY = annotation.y * this.zoomLevel;

  //         this.ctx.fillStyle = this.textColor;
  //         this.ctx.fillText(
  //           annotation.text,
  //           adjustedX - annotation.rectWidth * this.zoomLevel,
  //           adjustedY - (annotation.rectHeight + 10) * this.zoomLevel
  //         );
  //         this.ctx.strokeStyle = this.lineColor;
  //         this.ctx.lineWidth = 3;
  //         this.ctx.strokeRect(
  //           adjustedX - annotation.rectWidth * this.zoomLevel,
  //           adjustedY - annotation.rectHeight * this.zoomLevel,
  //           annotation.rectWidth * this.zoomLevel,
  //           annotation.rectHeight * this.zoomLevel
  //         );

  //         this.savedState = this.ctx.getImageData(
  //           0,
  //           0,
  //           canvas.width,
  //           canvas.height
  //         );
  //       });
  //     };
  //     img.src = 'data:image/tiff;base64,' + this.selectedExistingDocument.file;
  //   }
  // }
  redrawAnnotationsZoom() { //withRotate
    if (!this.isLoading) {
      const canvas = this.canvasRef.nativeElement;
      this.ctx = canvas.getContext('2d');
      this.ctx.clearRect(0, 0, canvas.width, canvas.height);
      const img = new Image();
      img.onload = () => {
        // Calculate the new image dimensions based on the zoom level
        const imageWidth = Math.round(img.width * this.zoomLevel);
        const imageHeight = Math.round(img.height * this.zoomLevel);
  
        // Calculate the canvas size to fit the rotated image
        const angle = this.rotationAngle * Math.PI / 180;
        const sin = Math.sin(angle);
        const cos = Math.cos(angle);
        const rotatedWidth = Math.abs(imageWidth * cos) + Math.abs(imageHeight * sin);
        const rotatedHeight = Math.abs(imageHeight * cos) + Math.abs(imageWidth * sin);
  
        canvas.width = rotatedWidth;
        canvas.height = rotatedHeight;
        this.ctx.save();
  
        // Rotate the canvas context
        this.ctx.translate(canvas.width / 2, canvas.height / 2);
        this.ctx.rotate(angle);
        this.ctx.translate(-imageWidth / 2, -imageHeight / 2);
        this.ctx.drawImage(img, 0, 0, imageWidth, imageHeight);
        this.ctx.restore();
  
  
        // Save the current zoom level and rotation angle for the next redraw
        // this.prevZoomLevel = this.zoomLevel;
        this.prevRotationAngle = this.rotationAngle;
      };
      img.src = 'data:image/tiff;base64,' + this.selectedExistingDocument.file;
    }
  }
  clrAllAnnotation() {
    this.zoomLevel = this.DEFAULT_ZOOM_LEVEL;
    this.textAnnotations = [];
    // this.redrawAnnotations();
    this.redrawAnnotationsZoom();
  }

  clearCanvas() {
    const canvas = this.canvasRef.nativeElement;
    this.ctx = canvas.getContext('2d');
    this.ctx.clearRect(0, 0, canvas.width, canvas.height);
    this.selectedExistingDocument = null;
    this.existingDocumentImageList = [];
    this.pageNumber = 0;
    this.totalPages = 0;
  }

  deleteImage(_t122: any) {
    this.splitDocumentImageList.splice(_t122, 1);
    this.emitPageNoList = this.splitDocumentImageList.map(
      (document) => document.pageNo
    );
    this.emitPageNo.emit(this.emitPageNoList);
  }

  getNonMedicareImageList() {
    const imageList = this.patientMedicareCheckListDetailsService
      .getRXDocsSelectionListByDocIdAndPageNos(this.docId, this.previewList)
      .subscribe((response) => {
        // this.SortedDocumentImageList=response?.sortedDocuments || [];
        const sortedDocuments = response || [];

        if (sortedDocuments) {
          const filteredFiles = sortedDocuments.filter((file) =>
            this.previewList.includes(file.pageNo)
          );

          this.splitDocumentImageList = filteredFiles.map((a) => {
            return {
              ...a,
              src: this.sanitizer.bypassSecurityTrustResourceUrl(
                `data:image/tiff;base64, ${a.pageContent}`
              ),
              pageNo: a.pageNo,
            };
          });
        }
      });

    this.subscription$.push(imageList);
  }

  loadBinSelectedDocument(document: any) {

    this.document_documentTypeId = document.documentTypeId;
    let findDocIndex = this.documentTypes.findIndex(doc => doc.documentTypeId === document.documentTypeId);

    if (this.documentTypes[findDocIndex].docPageContent === undefined) {
      if (document.docPage !== null) {
        this.clearCanvas();
        this.isLoading = true;
        this.isHideTiffViewer = true;
        this.getSelectedImageList(this.docId, document.docPage, document.pageCount, document.documentTypeId);
      }
      else {
        this.clearCanvas();
        this.TiffError = true;
        this.loadingMessage = ''; // Images has not found
        this.isLoading = false;
      }
    } else {
      if (document.documentTypeId) {
        this.loadBinDocument(document.documentTypeId, document.pageCount);
      } else {
        this.clearCanvas();
        this.TiffError = true;
        this.loadingMessage = ''; // Images has not found
        this.isLoading = false;
      }
    }
  }

  getSelectedImageList(docId: string, pageNos: number[], pagecount: number, documentTypeId: string) {
    if (this.loadloadSelectedImageListAPICall$) {
      this.loadloadSelectedImageListAPICall$?.unsubscribe();
    }
    const imageList = this.patientMedicareCheckListDetailsService
      .getRXDocsSelectionListByDocIdAndPageNos(docId, pageNos)
      .subscribe((response) => {
        if (response) {
          let findDocIndex = this.documentTypes.findIndex(doc => doc.documentTypeId === documentTypeId);
          this.selectedImgDoc = [];
          response?.forEach((element) => {
            this.selectedImgDoc?.push({
              file: element?.pageContent,
              pageNo: element?.pageNo,
            });
          });
          this.documentTypes[findDocIndex]['docPageContent'] = [...this.selectedImgDoc] || [];
          this.isLoading = false;
          this.isHideTiffViewer = false;
          this.loadBinDocument(documentTypeId, pagecount);
        } else {
          this.clearCanvas();
          this.TiffError = true;
          this.loadingMessage = ''; // Images has not found
          this.isLoading = false;
          this.isHideTiffViewer = false;
        }
      },
        (err) => {
          this.isLoading = false;
        });
        this.loadloadSelectedImageListAPICall$ = imageList;
  }
  selectedImgPreview(docDetails:any):void {
  const docPageDocuments = this.documentTypes.filter((doc) => 
  doc?.docPage?.includes(docDetails.pageNo)
  );
  this.doubleClickSelectedDocument(docPageDocuments,docDetails.pageNo);
  }

  doubleClickSelectedDocument(document: any,selectedPageNo:any) {
    this.selectedPageNoIndex = selectedPageNo.toString();
    this.document_documentTypeId = document[0].documentTypeId;
    let findDocIndex = this.documentTypes.findIndex(doc => doc.documentTypeId === document[0].documentTypeId);
    if (this.documentTypes[findDocIndex].docPageContent === undefined) {
      if (document[0].docPage !== null) {
        this.clearCanvas();
        this.isLoading = true;
        this.isHideTiffViewer = true;
        this.getSelectedImageList(this.docId, document[0].docPage, document[0].pageCount, document[0].documentTypeId);
      }
      else {
        this.clearCanvas();
        this.TiffError = true;
        this.loadingMessage = ''; // Images has not found
        this.isLoading = false;
      }
    } else {
      if (document[0].documentTypeId) {
        this.loadBinDocument(document[0].documentTypeId, document[0].pageCount);
      } else {
        this.clearCanvas();
        this.TiffError = true;
        this.loadingMessage = ''; // Images has not found
        this.isLoading = false;
      }
    }
  }

  rotationAngle = 0;
  prevRotationAngle = 0;

  rotateClockwise() {
    this.rotationAngle = (this.rotationAngle + 90) % 360;
    this.redrawAnnotationsZoom();
  }
  
  rotateCounterclockwise() {
    this.rotationAngle = (this.rotationAngle - 90 + 360) % 360;
    this.redrawAnnotationsZoom();
  }
}
