import { PagedAndSortedResultRequestDto } from '@abp/ng.core';
import { DatePipe } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, Input, OnInit, ViewChild } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatAccordion } from '@angular/material/expansion';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Store } from '@ngxs/store';
import { ProductType } from 'projects/inventory/src/app/item-proxy/item-management/items/product-type.enum';
import { ProductService } from 'projects/inventory/src/app/item-proxy/item-management/items/product.service';
import { ItemEligibilityRulesService } from 'projects/order/src/app/order-proxy/order-management/inventory/item-eligibility-rules.service';
import { CommonService } from 'projects/order/src/app/order-proxy/order-management/order/common.service';
import { CreateUpdateItemsDTO } from 'projects/order/src/app/order-proxy/patient/dto/models';
import { planInterface } from 'projects/patient/src/app/patient-add-policy/patient-add-policy.component';
import { PatientsDTO } from 'projects/patient/src/app/patient-proxy/patient/dto';
import { PatientsService } from 'projects/patient/src/app/patient-proxy/patient/patients.service';
import { PverifyPayersService } from 'projects/patient/src/app/patient-proxy/patient/pverify-payers.service';
import { NumberValidators } from 'projects/shared/src/app/validations/number-validator';
import { Observable, of } from 'rxjs';
import { groupBy, map, reduce, startWith, toArray } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-admin-add-item-modal',
  templateUrl: './admin-add-item-modal.component.html',
  styleUrls: ['./admin-add-item-modal.component.scss'],
  providers: [DatePipe],
})
export class AdminAddItemModalComponent implements OnInit {
  adminAddItem: FormGroup;
  adminAddItemSearchForm: FormGroup;
  isShowSpinner: boolean;
  intTotalPrice: number = 0;
  intOverAllTotalPrice: number = 0;
  drpPolicies: any[] = [];
  dataSource: MatTableDataSource<any> = new MatTableDataSource<any>([]);
  @ViewChild(MatAccordion) accordion: MatAccordion;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  displayedColumns: string[] = [
    'ItemName',

    //'PricePerQuantity',
    'Options',
    'Price',
    'Quantity',
    'totalPrice',
    'OriginalDos',
  ];
  isLoading: boolean;
  @Input() orderId: string = '';
  drpPlan: any[] = [];
  patientId: string;
  saleorderId: string;
  patientID: string = '';

  constructor(
    private formBuilder: FormBuilder,
    private itemsService: ProductService,
    private numberValidator: NumberValidators,
    private commonService: CommonService,
    private patientsService: PatientsService,
    private toastr: ToastrService,
    @Inject(MAT_DIALOG_DATA) public data: { patientsId: string }
  ) {}

  ngOnInit(): void {
    this.adminAddItem = this.formBuilder.group({
      txtItemId: new FormControl('', Validators.required),
      txtItemName: new FormControl('', Validators.required),
      txtPrice: new FormControl('', [
        Validators.required,
        this.numberValidator.isNumberCheck,
      ]),
      txtQty: new FormControl('', Validators.required),
      txtOriginalDOS: new FormControl('', Validators.required),
      txtSearchFilter: new FormControl(''),
      drpInsurance: new FormControl('', Validators?.required),
      txtInsuranceFilter: new FormControl(''),
    });

    this.adminAddItemSearchForm = this.formBuilder.group({
      itemName: new FormControl(''),
      priceQuantity: new FormControl(''),
      price: new FormControl(''),
      Quantity: new FormControl(''),
      originalDos: new FormControl(''),
    });
    this.itemDropdown();
    this.data.patientsId =
      this.data?.patientsId ?? '00000000-0000-0000-0000-000000000000';
    this.data?.patientsId !== '00000000-0000-0000-0000-000000000000' &&
      this.insuranceNameDropdown();
    this.get();
    this.adminAddItem.valueChanges.subscribe((value) => {
      this.intTotalPrice =
        +Number(
          (value?.txtPrice * value?.txtQty ?? 0) !== NaN
            ? value?.txtPrice * value?.txtQty ?? 0
            : 0
        )?.toFixed(2) ?? 0;
    });
  }

  drpitem: productInterface[] = [];

  public filteredItemTypes: Observable<productInterface[]>;
  itemDropdown(): void {
    this.drpitem = [];
    this.itemsService.getProductsByInput(ProductType.All).subscribe(
      (response) => {
        response?.forEach((element) => {
          this.drpitem.push({
            hcpcCode: element?.hcpcCode,
            productDescription: element?.productDescription,
            productCode: element?.productCode,
            status: element?.status,
            price: element?.price,
            productId: element?.productId,
            productName: element?.productName,
            productType: element?.productType,
            productNames: element?.productName,
            brandName: element?.brandName,
            categoryName: element?.categoryName,
            name: element?.name,
          });
          this.filteredItemTypes = this.adminAddItem
            .get('txtSearchFilter')
            .valueChanges.pipe(
              startWith(''),
              map((value) =>
                this.drpitem.filter((option) =>
                  option?.productName
                    ?.toLowerCase()
                    ?.includes(value?.toLowerCase() ?? '')
                )
              )
            );
          // this.drpInsurance = element?.rulesValidities
        });
        // if (this.data.itemId != null && this.data.itemId != undefined && this.data.itemId !== "") {
        //   this.getOrderByid(this.data.itemId);
        // }
      },
      (err) => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      },
      () => {}
    );
  }

  itemTable: {
    itemId: string;
    insuranceName: string;
    itemCodeId: string;
    itemName: string;
    priceQunatity: string;
    price: number;
    totalPrice: number;
    Quantity: number;
    dos: string;
    id: string;
    productId: string;
  }[] = [];
  saveButtonHide: boolean = false;
  get() {
    this.patientsService.get(this.data.patientsId).subscribe(
      (response) => {
        let res: PatientsDTO = response;
        this.saveButtonHide = response?.orders?.isCompleted === 'Done';
        this.patientId = res?.approvedPatientId;
        this.saleorderId = res?.saleOrderId;
        this.itemTable = [];
        response &&
          response?.orders?.items?.forEach((element) => {
            this.itemTable?.push({
              itemId: element?.itemId,
              insuranceName: element?.insuranceName,
              itemCodeId: element?.itemCode + ' / ' + element?.itemName,
              itemName: element?.itemName,
              priceQunatity: element?.price + ' / ' + element?.quantity,
              price: element?.price,
              Quantity: element?.quantity,
              dos: element?.dos,
              id: element?.itemId,
              productId: element?.productId,
              totalPrice: (element?.price ?? 0) * (element?.quantity ?? 0) ?? 0,
            });
          });
        const overAllTotalPrice: number =
          +this.itemTable
            ?.reduce(
              (previousValue, currentValue) =>
                (previousValue ?? 0) + currentValue.totalPrice,
              0
            )
            ?.toFixed(2) ?? 0;
        this.intOverAllTotalPrice =
          overAllTotalPrice === NaN ? 0 : Number(overAllTotalPrice) ?? 0;
        this.isLoading = false;
        const arrTableData = this.itemTable;
        this.dataSource = new MatTableDataSource(arrTableData);
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
      },
      (err) => {
        this.isLoading = false;
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      }
    );
  }

  itemId: string = '00000000-0000-0000-0000-000000000000';
  // save add Item
  saveItem() {
    if (
      this.itemId == '00000000-0000-0000-0000-000000000000' ||
      this.itemId == null ||
      this.itemId == undefined
    ) {
      let createUpdateItem: CreateUpdateItemsDTO = {
        productId: this.adminAddItem.get('txtItemId')?.value?.productId,
        itemCode: this.adminAddItem.get('txtItemId')?.value?.hcpcCode,
        itemName: this.adminAddItem.get('txtItemId')?.value?.name,
        insuranceName: this.adminAddItem.get('drpInsurance')?.value,
        price: this.adminAddItem.value.txtPrice,
        quantity: this.adminAddItem.value.txtQty,
        dos: this.adminAddItem.value.txtOriginalDOS,
        totalPrice: 0,
        itemId: '00000000-0000-0000-0000-000000000000',
      };
      this.commonService
        .commonUpdate(
          this.data.patientsId,
          this.patientId,
          this.saleorderId,
          createUpdateItem
        )
        .subscribe(
          (response) => {
            this.toastr.success('Saved Successfully', 'Success');
            // Swal.fire({ title: 'Success', html: 'saved Successfully', icon: 'success', timer: 3000, timerProgressBar: true });
            this.get();
            this.resetAddItemForm();
          },
          (err) => {
            const data: HttpErrorResponse = err;
            Swal.fire({
              icon: 'info',
              text: data?.error?.error?.message,
            });
          }
        );
    } else {
      let createUpdateItem: CreateUpdateItemsDTO = {
        productId: this.adminAddItem.get('txtItemId')?.value?.productId,
        itemCode: this.adminAddItem.get('txtItemId')?.value?.hcpcCode,
        itemName: this.adminAddItem.get('txtItemId')?.value?.name,
        insuranceName: this.adminAddItem.get('drpInsurance')?.value,
        price: this.adminAddItem.value.txtPrice,
        quantity: this.adminAddItem.value.txtQty,
        dos: this.adminAddItem.value.txtOriginalDOS,
        totalPrice: 0,
        itemId: this.itemId,
      };
      this.commonService
        .commonUpdate(
          this.data.patientsId,
          this.patientId,
          this.saleorderId,
          createUpdateItem
        )
        .subscribe(
          (response) => {
            this.toastr.success('Updated Successfully', 'Success');
            // Swal.fire({ title: 'Success', html: 'Updated Successfully', icon: 'success', timer: 3000, timerProgressBar: true });
            this.get();
            this.resetAddItemForm();
          },
          (err) => {
            const data: HttpErrorResponse = err;
            Swal.fire({
              icon: 'info',
              text: data?.error?.error?.message,
            });
          }
        );
    }
  }

  //  Item Id dropdown onchange evnet function
  inventoryItemId: string = '';
  onChange(event) {
    this.adminAddItem.patchValue({
      txtItemName: event?.value?.name,
      txtPrice: event?.value?.price,
      txtQty: '',
      txtOriginalDOS: '',
    });
  }

  // insurance dropdwon automatically patch functionality
  insuranceNameDropdown() {
    this.patientsService.get(this.data.patientsId).subscribe((response) => {
      response?.insurances?.primaryInsurance;
      this.drpPlan = [];
      this.drpPlan.push({
        insuranceName: response?.insurances?.primaryInsurance?.pVerifyInsName,
      });
      this.adminAddItem.patchValue({
        drpInsurance: response?.insurances?.primaryInsurance?.pVerifyInsName,
      });
    });
  }

  Insurance: string;
  txtItem: string = '';
  txtInsurance: string = '';
  // dropdown clear functionalities
  clearInsurance() {
    this.Insurance = '';
    this.adminAddItem.patchValue({ drpInsurance: '' });
  }

  //DropDown clear for Item Type

  clearItemType() {
    this.txtItem = '';
    this.adminAddItem.patchValue({
      txtItemId: '',
    });
  }

  //Add item table view inside the Modal
  viewItem(data) {
    let itemDetail = this.drpitem?.filter((value) => {
      return value && value?.productId === data?.productId;
    })[0];
    this.patientsService.get(this.data.patientsId).subscribe((response) => {
      response &&
        response?.orders?.items?.forEach(
          (element) => {
            this.adminAddItem.patchValue({
              txtItemId: itemDetail,
              txtItemName: data?.itemName,
              txtPrice: data?.price,
              txtQty: data?.Quantity,
              txtOriginalDOS: data?.dos,
              drpInsurance: data?.insuranceName,
              itemId: data?.itemId,
            });
            this.itemId = element?.itemId;
          },
          (err) => {
            const data: HttpErrorResponse = err;
            Swal.fire({
              icon: 'info',
              text: data?.error?.error?.message,
            });
          }
        );
    });
  }

  // reset item
  resetAddItemForm() {
    this.adminAddItem.patchValue({
      txtItemId: '',
      txtItemName: '',
      txtPrice: '',
      txtQty: '',
      txtOriginalDOS: '',
      txtSearchFilter: '',
      drpInsurance: '',
      txtInsuranceFilter: '',
    });
    this.itemId = '00000000-0000-0000-0000-000000000000';
    this.adminAddItem.markAsPristine();
    this.adminAddItem?.markAsUntouched();
    this.adminAddItem?.updateValueAndValidity();
  }
}

export interface productInterface {
  productId?: string;
  productType: ProductType;
  productName?: string;
  productNames?: string;
  hcpcCode?: string;
  productDescription?: string;
  productCode: number;
  status?: string;
  price: number;
  productStockId?: string;
  brandName: string;
  categoryName: string;
  name: string;
}
