<div class="container-fluid">
  <div class="row align-items-center">
    <div class="col-lg-9">
      <h4 class="customThemeClass"><b>Total Amount : ${{balanceAmount}} - Monthly Split Amount List</b><br /></h4>
    </div>
    <div class="col-lg-3" style="text-align-last: right">
      <mat-icon mat-dialog-close>close</mat-icon>
    </div>
  </div>
  <div class="row">
    <div class="col-lg-12">
      <div class="card">
        <!-- <div class="card elevated-div"> -->
        <!-- <div class="card-header">Shipping List</div> -->
        <div class="card-body p-2 mb-1 mt-1">
          <div class="col-lg-12 content table-responsive p-0">
            <div class="d-flex justify-content-center" *ngIf="!isLoaded">
              <!-- <mat-spinner></mat-spinner> -->
              <img
                class="img-responsive pb-2 mr-1"
                src="assets/gifs/Infinity-loader.gif"
              />
            </div>
            <table
              *ngIf="isLoaded"
              datatable
              [dtOptions]="dtRentalTblOptions"
              class="row-border hover display"
              id="joinData"
            >
              <thead>
                <tr>
                  <th>#</th>
                  <th [matTooltip]="'Months'">Months</th>
                  <!-- <th class="text-right" [matTooltip]="'Total Amount($)'">
                    Total Amount($)
                  </th> -->
                  <th class="text-right" [matTooltip]="'Monthly Amount($)'">
                    Monthly Amount($)
                  </th>
                  <th [matTooltip]="'Status'">Status</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let data of rentalTableData">
                  <td></td>
                  <!-- <td>{{data.dosDate ?? data.date  | date : "MM/dd/yyyy" : "en_US"}}</td> -->
                  <td
                    [matTooltip]="
                      data?.dosDate
                        ? (data.dosDate | date : 'MM/dd/yyyy' : 'en_US')
                        : '-'
                    "
                  >
                    {{
                      data.dosDate !== null && data.dosDate !== undefined
                        ? (data.dosDate | date : "MM/dd/yyyy" : "en_US")
                        : "-"
                    }}
                  </td>
                  <!-- <td class="text-right" [matTooltip]="balanceAmount">
                    {{ balanceAmount }}
                  </td> -->
                  <td
                    class="text-right"
                    [matTooltip]="data?.orderAmount || '-'"
                  >
                    {{ data.orderAmount ? data.orderAmount : "-" }}
                  </td>

                  <td [matTooltip]="data.rentalStatusName || '-'">{{ data.rentalStatusName || '-' }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <!-- </div> -->
    </div>
  </div>
</div>
