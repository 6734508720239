import { RestService } from '@abp/ng.core';
import type {
  PagedAndSortedResultRequestDto,
  PagedResultDto,
} from '@abp/ng.core';
import { Injectable } from '@angular/core';
import {
  CreateUpdateRequisitionDTO,
  InventoryLimtDTO,
  POSummary,
  PackingListDTO,
  ReceiveRequitioDTO,
  ReqDefaultListDTO,
  RequisitionDTO,
  RequisitionDataDTO,
  RequisitionProductDTO,
  RequisitionProductListDTO,
  RequisitionStatusDTO,
  Runtransfer,
  UpdateRequisitionReceiveQtyDTO,
  requsitionsforPurchaseDto,
} from './dto';

@Injectable({
  providedIn: 'root',
})
export class RequisitionService {
  apiName = 'ItemManagement';

  create = (input: CreateUpdateRequisitionDTO) =>
    this.restService.request<any, RequisitionDTO>(
      {
        method: 'POST',
        url: '/api/ItemApp/requisition',
        body: input,
      },
      { apiName: this.apiName }
    );

  delete = (id: string) =>
    this.restService.request<any, void>(
      {
        method: 'DELETE',
        url: `/api/ItemApp/requisition/${id}`,
      },
      { apiName: this.apiName }
    );

  get = (id: string) =>
    this.restService.request<any, RequisitionDTO>(
      {
        method: 'GET',
        url: `/api/ItemApp/requisition/${id}`,
      },
      { apiName: this.apiName }
    );

  getList = (input: PagedAndSortedResultRequestDto) =>
    this.restService.request<any, PagedResultDto<RequisitionDTO>>(
      {
        method: 'GET',
        url: '/api/ItemApp/requisition',
        params: {
          skipCount: input.skipCount,
          maxResultCount: input.maxResultCount,
          sorting: input.sorting,
        },
      },
      { apiName: this.apiName }
    );

  update = (id: string, input: CreateUpdateRequisitionDTO) =>
    this.restService.request<any, RequisitionDTO>(
      {
        method: 'PUT',
        url: `/api/ItemApp/requisition/${id}`,
        body: input,
      },
      { apiName: this.apiName }
    );
  getInventoryLimit = () =>
    this.restService.request<any, InventoryLimtDTO>(
      {
        method: 'GET',
        url: '/api/ItemApp/requisition/get-inventory-limit',
      },
      { apiName: this.apiName }
    );

  getInventoryLimitV1 = (branchId: string) =>
    this.restService.request<any, InventoryLimtDTO>(
      {
        method: 'GET',
        url: `/api/ItemApp/requisition/get-inventory-limit-v1/${branchId}`,
      },
      { apiName: this.apiName }
    );

  getProductDetailsBasedonProductId = (productId: string) =>
    this.restService.request<any, RequisitionProductDTO[]>(
      {
        method: 'GET',
        url: `/api/ItemApp/requisition/product-details/${productId}`,
      },
      { apiName: this.apiName }
    );
  getProductDetailsV1ByIProductIdAndOrgIdAndNoOfDays = (
    iProductId: string,
    orgId: string,
    noOfDays: number
  ) =>
    this.restService.request<any, RequisitionProductDTO[]>(
      {
        method: 'GET',
        url: '/api/ItemApp/requisition/product-details-v1',
        params: { iProductId, orgId, noOfDays },
      },
      { apiName: this.apiName }
    );
  updateRequisitionDataByGReqIdAndGShippingMethodAndDReqDateAndGReqStatus = (
    gReqId: string,
    gShippingMethod: string,
    dReqDate: string,
    gReqStatus: string
  ) =>
    this.restService.request<any, string>(
      {
        method: 'PUT',
        responseType: 'text',
        url: `/api/ItemApp/requisition/update-requisition-data/${gReqId}`,
        params: { gShippingMethod, dReqDate, gReqStatus },
      },
      { apiName: this.apiName }
    );

  runTransferByInput = (input: Runtransfer) =>
    this.restService.request<any, string>(
      {
        method: 'POST',
        responseType: 'text',
        url: '/api/ItemApp/requisition/run-transfer',
        body: input,
      },
      { apiName: this.apiName }
    );

  getRequisitionList = (isCompleted: boolean) =>
    this.restService.request<any, RequisitionDataDTO[]>(
      {
        method: 'GET',
        url: `/api/ItemApp/requisition/requisitions-data?isCompleted=${isCompleted}`,
      },
      { apiName: this.apiName }
    );

  getAllRequisitionsDataByIsCompleted = (
    isCompleted: boolean,
    pageNo: number,
    pageOffset: number
  
  ) =>
    this.restService.request<any, RequisitionDataDTO[]>(
      {
        method: 'GET',
        url: '/api/ItemApp/requisition/requisitions-data',
        params: { isCompleted,
          pageNo,
          pageOffset
         },
      },
      { apiName: this.apiName }
    );

  getRequisitionItemDetailsByReqId = (reqId: string) =>
    this.restService.request<any, RequisitionProductListDTO[]>(
      {
        method: 'GET',
        url: `/api/ItemApp/requisition/requisition-item-details/${reqId}`,
      },
      { apiName: this.apiName }
    );
  splitReuisitionItemByGReqIdAndGItemIds = (
    gReqId: string,
    gItemIds: string[]
  ) =>
    this.restService.request<any, void>(
      {
        method: 'POST',
        url: `/api/ItemApp/requisition/split-reuisition-item/${gReqId}`,
        body: gItemIds,
      },
      { apiName: this.apiName }
    );

  getRequisitionListByID = (sReqID: string) =>
    this.restService.request<any, RequisitionDTO>(
      {
        method: 'GET',
        url: '/api/ItemApp/requisition/requisition-list-by-iD',
        params: { sReqID },
      },
      { apiName: this.apiName }
    );

  getRequisitionItemsList = (reqId: string) =>
    this.restService.request<any, any[]>(
      {
        method: 'GET',
        url: `/api/ItemApp/requisition/requisition-item-details/${reqId}`,
      },
      { apiName: this.apiName }
    );
  cancelRequisitionByGReqId = (gReqId: string) =>
    this.restService.request<any, void>(
      {
        method: 'POST',
        url: `/api/ItemApp/requisition/cancel-requisition/${gReqId}`,
      },
      { apiName: this.apiName }
    );
  saveInventoryReceivedQtyByInput = (input: ReceiveRequitioDTO[]) =>
    this.restService.request<any, boolean>(
      {
        method: 'POST',
        url: '/api/ItemApp/requisition/save-inventory-received-qty',
        body: input,
      },
      { apiName: this.apiName }
    );
  getRequisitionStatusList = () =>
    this.restService.request<any, RequisitionStatusDTO[]>(
      {
        method: 'GET',
        url: `/api/ItemApp/requisition/requisition-act-status-list`,
      },
      { apiName: this.apiName }
    );

  getRequisitionListByStatusShortCodeId = (
    shortCodeId: string,
    pageNo: Number,
    pageOffset: Number
    ) =>
    this.restService.request<any, RequisitionDataDTO[]>(
      {
        method: 'GET',
        url: `/api/ItemApp/requisition/requisitions-data-with-short-code-id?shortCode=${shortCodeId}`,
        params: {
          pageNo,
          pageOffset
        },
      },
      { apiName: this.apiName }
    );
  calculateRequisitionQtyByNoOfDaysByLtItemsListAndNoOfDays = (
    ltItemsList: RequisitionProductDTO[],
    noOfDays: number
  ) =>
    this.restService.request<any, RequisitionProductDTO[]>(
      {
        method: 'POST',
        url: '/api/ItemApp/requisition/calculate-requisition-qty-by-no-of-days',
        params: { noOfDays },
        body: ltItemsList,
      },
      { apiName: this.apiName }
    );

  getDefaultRequisitionDetails = () =>
    this.restService.request<any, ReqDefaultListDTO>(
      {
        method: 'GET',
        url: '/api/ItemApp/requisition/get-default-requisition-details',
      },
      { apiName: this.apiName }
    );

  getSmartRequitionListByGManIdAndLocationIdAndNoofDays = (
    gManId: string,
    LocationId: string,
    NoofDays: number
  ) =>
    this.restService.request<any, RequisitionProductDTO[]>(
      {
        method: 'GET',
        url: '/api/ItemApp/requisition/smart-requition-list',
        params: { gManId, locationId: LocationId, noofDays: NoofDays },
      },
      { apiName: this.apiName }
    );
  getRequestedOrdersforPurchaseByGManufacturerIds = (
    gManufacturerIds: string[]
  ) =>
    this.restService.request<any, requsitionsforPurchaseDto[]>(
      {
        method: 'GET',
        url: '/api/ItemApp/requisition/get-requested-ordersfor-purchase',
        params: { gManufacturerIds },
      },
      { apiName: this.apiName }
    );
  btnProdListClickByManufacturerIdAndLocationIdAndNoofDays = (
    ManufacturerId: string,
    LocationId: string,
    NoofDays: number
  ) =>
    this.restService.request<any, RequisitionProductDTO[]>(
      {
        method: 'POST',
        url: '/api/ItemApp/requisition/btn-prod-list-click',
        params: {
          manufacturerId: ManufacturerId,
          locationId: LocationId,
          noofDays: NoofDays,
        },
      },
      { apiName: this.apiName }
    );
  packingListPDF = (reqId: string) =>
    this.restService.request<any, PackingListDTO[]>(
      {
        method: 'GET',
        url: `/api/ItemApp/requisition/packing-list-details-for-print/${reqId}`,
      },
      { apiName: this.apiName }
    );

  getRequisitionsSummaryforPO = () =>
    this.restService.request<any, POSummary[]>(
      {
        method: 'GET',
        url: '/api/ItemApp/requisition/get-requisitions-summaryfor-pO',
      },
      { apiName: this.apiName }
    );
  saveInventoryReceivedQtyV1ByInput = (input: ReceiveRequitioDTO) =>
    this.restService.request<any, UpdateRequisitionReceiveQtyDTO>(
      {
        method: 'POST',
        url: '/api/ItemApp/requisition/save-inventory-received-qty-v1',
        body: input,
      },
      { apiName: this.apiName }
    );
  constructor(private restService: RestService) {}
}
