import { FlatTreeControl } from '@angular/cdk/tree';
import { HttpErrorResponse } from '@angular/common/http';
import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatAccordion } from '@angular/material/expansion';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import {
  MatTreeFlatDataSource,
  MatTreeFlattener,
} from '@angular/material/tree';
// import { defaultGuid } from 'projects/admin/src/app/document-perviewer/document-perviewer.component';
import {
  defaultGuid,
  DocumentMaxandTypeLimits,
  DocumentUploadTypes,
} from 'projects/shared/src/app/enums/allenums.enum';
import { TableService } from 'projects/shared/src/app/table.service';
import { DateValidator } from 'projects/shared/src/app/validations/date-validator';
import { combineLatest, defer, from, Subscription } from 'rxjs';
import {
  concatMap,
  groupBy,
  map,
  mergeMap,
  reduce,
  takeLast,
  tap,
  toArray,
} from 'rxjs/operators';
import Swal from 'sweetalert2';
import { PatientDropdowns } from '../dropdown-proxy/dropdown-management/dropdowns';
import {
  BranchOfficeDTO,
  DocumentTypeDTO,
  ReviewReasonDTO,
  ReviewStatusDTO,
  SortingTypeDTO,
} from '../dropdown-proxy/dropdown-management/dropdowns/dto/patient/models';
import { PatientMasterDropdownService } from '../dropdown-proxy/dropdown-management/dropdowns/master-dropdown-service';
import { PatientService } from '../patient-proxy/patient';
import { PatientDocumentService } from '../patient-proxy/patient-optimization';
import {
  DocumentGroupDTO,
  PatientDocumentBlobDTO,
  UpdateDocumentBlobDTO,
} from '../patient-proxy/patient-optimization/dto/models';
import {
  PatientDocumentDTO,
  SavePatientDocumentBlobDTO,
} from '../patient-proxy/patient/dto';
import { ToastrService } from 'ngx-toastr';
let listOfDocuments: PatientDocumentBlobDTO[] = [];

@Component({
  selector: 'app-document-tab',
  templateUrl: './document-tab.component.html',
  styleUrls: ['./document-tab.component.scss'],
})
export class DocumentTabComponent implements OnInit, OnDestroy {
  //#region Declarations
  @Output() saveDocumentFormState: EventEmitter<any> = new EventEmitter<any>();
  @Output() nextTabMoveOnSaveEmitter: EventEmitter<string> =
    new EventEmitter<string>();
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild(MatAccordion, { static: false }) accordion: MatAccordion;

  @ViewChild(MatSort, { static: false }) mergeSort: MatSort;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatPaginator, { static: false }) mergePaginator: MatPaginator;
  @ViewChild(MatTable, { static: false }) tableRef: MatTable<any>;
  panelOpenState: boolean = false;
  dataSource: MatTableDataSource<any> = new MatTableDataSource<any>([]);
  mergeDataSource: MatTableDataSource<any> = new MatTableDataSource<any>([]);
  arrUnsortedDocumentList: PatientDocumentBlobDTO[] = [];
  arrMergeDocumentList: PatientDocumentBlobDTO[] = [];
  arrSortedDocumentList: documentListType[] = [];
  drpDocumentTypeLoop: DocumentTypeDTO[] = [];
  drpReviewStatusLoop: ReviewStatusDTO[] = [];
  documentGroupedByType: Partial<documentGroupIngListType>[] = [];
  documentGroup: DocumentGroupDTO[] = [];
  drpReviewReasonLoop: ReviewReasonDTO[] = [];
  drpBranchLoop: BranchOfficeDTO[] = [];
  drpDocumentUploadType: SortingTypeDTO[] = [];
  drpIsActiveStatus: any[] = [];
  subscription$: Subscription[] = [];
  @Input() patientId: string = defaultGuid;
  isShowProcessBar: boolean = false;
  myDate = new Date();
  tenantId: string = defaultGuid;
  documentTypes: string[] = [
    'ALL',
    'FACE TO FACE',
    'RX',
    'SLEEP STUDY REPORT',
    'INSURANCE VERIFICATION',
    'COMPLIANCE REPORT',
    'CORRESPONDENCE',
    'DEMOGRAPHICS',
    'OTHERS',
  ];
  pdfHeight: string = '100vh';
  displayedColumns: string[] = [
    'options',
    'documentType',
    'fileName',
    'documentStartDate',
    'documentEndDate',
    'documentStatus',
  ];
  document: string;
  documentForm: FormGroup;
  documentSearchForm: FormGroup;
  show: boolean = false;
  showErrorForFileLength: boolean = false;
  showErrorForFileType: boolean;
  showExtensionInfo: string = '';
  showErrorForExtension: boolean = false;
  isDocumentForButtonDisable: boolean = false;
  isPatientLockedByCurrentUser: boolean = true;
  strLoginUserId: string = '';
  filesname: string[] = [];
  intCheckedDocuments: number = 0;
  isDocumentEditSaveButtonHidden: boolean = true;
  @Input() strPdfSource: string = '';
  isShowSpinner: boolean = false;
  strSelectedPdfPath: string = '';
  selectedDocumentforUpdate: Partial<PatientDocumentDTO> = null;
  patientDocumentId: string = '';
  parentPatientDocumentID: string = '';
  activeNode: any;
  //#endregion Declarations

  //Constructor
  constructor(
    private patientDropdownService: PatientMasterDropdownService,
    private fb: FormBuilder, //To Build a Form
    private table: TableService,
    public patientService: PatientService,
    private dateValidator: DateValidator,
    private patientDocumentService: PatientDocumentService,
    private toastr: ToastrService
  ) {}

  //page Load Function
  ngOnInit(): void {
    this.patientId = this.patientId ?? defaultGuid;
    this.documentGrouping();
    this.createDocumentForm();
    this.getDocumentGroup();
    this.pdfHeight = '93%';
  }

  ngAfterViewInit() {
    this.getDropdown();
    this.dataSource.paginator = this.paginator;
  }

  public onPagesLoaded(value: string = ''): void {
    this.pdfHeight = '93%';
  }

  //#region Load Dropdowns
  getDropdown(): void {
    let patientDropdownInput: PatientDropdowns[];
    patientDropdownInput = [
      PatientDropdowns.DocumentTypes,
      PatientDropdowns.ReviewStatus,
      PatientDropdowns.ReviewReasons,
      PatientDropdowns.BranchOffices,
      PatientDropdowns.SortingTypes,
      PatientDropdowns.IsActiveStatus,
    ];
    const patientDropdown = this.patientDropdownService
      .getPatientDropdownsByInput(patientDropdownInput)
      .subscribe(
        (response) => {
          if (
            response &&
            response.reviewStatuses &&
            response.reviewReasons &&
            response.branchOffices &&
            response.documentTypes
          ) {
            this.drpDocumentTypeLoop = response.documentTypes;
            this.drpReviewStatusLoop = response.reviewStatuses;
            this.drpReviewReasonLoop = response.reviewReasons;
            this.drpBranchLoop = response.branchOffices;
            //  this.drpDocumentUploadType = response.sortingTypes;
            this.drpIsActiveStatus = response.lstIsActiveStatus;
            //To Load the Patients Documents Data in the Table on Page Load
            if (
              this.patientId !== '' &&
              this.patientId !== null &&
              this.patientId !== undefined
            ) {
              this.getDocumentsByPatientId();
            }
          }
        },
        (err) => {
          const data: HttpErrorResponse = err;
          Swal.fire({
            icon: 'info',
            text: data?.error?.error?.message,
          });
        },
        () => {}
      );
    this.subscription$.push(patientDropdown);
  }

  //#endregion Dropdowns

  //Intialize Form
  createDocumentForm() {
    this.documentForm = this.fb.group({
      drpReviewStatus: new FormControl(null),
      drpReviewReason: new FormControl(null),
      documentFile: new FormControl(null, [Validators.required]),
      drpDocumentType: new FormControl(null, [Validators.required]),

      //  drpDocumentUploadType: new FormControl(null),
      txtDocumentStartDate: new FormControl('', [Validators.required]),
      txtDocumentEndDate: new FormControl('', [Validators.required]),
      txtNote: new FormControl(''),
    });

    this.documentSearchForm = this.fb.group({
      fileName: new FormControl(null),
      documentStatus: new FormControl(null),
      documentStartDate: new FormControl(null, [
        this.dateValidator.dateVaidator,
      ]),
      documentEndDate: new FormControl(null, [this.dateValidator.dateVaidator]),
      documentType: new FormControl(null),
    });

    const valueChanges = this.documentSearchForm.valueChanges.subscribe(
      (value) => {
        if (this.patientId && this.documentSearchForm.valid) {
          const documentType: string = value?.documentType ?? '';
          const fileName: string = value?.fileName ?? '';
          const docFromDate: string = value?.documentStartDate ?? '';
          const docToDate: string = value?.documentEndDate ?? '';
          this.isShowProcessBar = true;
          this.arrUnsortedDocumentList = [];
          this.dataSource = new MatTableDataSource(
            this.arrUnsortedDocumentList
          );
          this.dataSource.sort = this.sort;
          this.dataSource.paginator = this.paginator;
          const searchDocument$ = this.patientDocumentService
            .searchPatientDocumentDetailsByPatientIdAndSFileNameAndSDocumentTypeAndDtDocumentStartDateAndDtDocumentEndDate(
              this.patientId,
              fileName?.trim(),
              documentType?.trim(),
              docFromDate,
              docToDate
            )
            .subscribe(
              (response) => {
                this.arrUnsortedDocumentList = [];
                if (response) {
                  response?.forEach((element: PatientDocumentBlobDTO) => {
                    if (
                      element?.documentUploadType ===
                        DocumentUploadTypes.SplitUpload &&
                      element?.isVerified !== 1
                    ) {
                      element = this.extractShortNameAndDate(element);
                    } else if (
                      (element?.isVerified === 1 &&
                        element?.documentUploadType ===
                          DocumentUploadTypes.ConsolidatedUpload) ||
                      element?.documentUploadType ===
                        DocumentUploadTypes.ManualUploadType
                    ) {
                      element = this.extractShortNameAndDate(element);
                    }
                  });
                }
                this.isShowProcessBar = false;
                this.dataSource = new MatTableDataSource(
                  this.arrUnsortedDocumentList
                );
                this.dataSource.sort = this.sort;
                this.dataSource.paginator = this.paginator;
              },
              (err) => {
                this.dataSource = new MatTableDataSource(
                  this.arrUnsortedDocumentList
                );
                this.dataSource.sort = this.sort;
                this.dataSource.paginator = this.paginator;
                this.isShowProcessBar = false;
                const data: HttpErrorResponse = err;
                Swal.fire({
                  icon: 'info',
                  text: data?.error?.error?.message,
                });
              }
            );
          this.subscription$.push(searchDocument$);
        }
      }
    );
    this.subscription$.push(valueChanges);

    this.documentForm.disable();
    this.documentForm.controls['documentFile'].enable();
  }

  //To Toggle Extended Info based on Document Type
  togglePanel() {
    if (
      this.documentForm.value.drpDocumentType === 'RX' ||
      this.documentForm.value.drpDocumentType === 'FACE TO FACE'
    ) {
      this.panelOpenState = true; // Flag for the Accordion Expansion

      //Validation Conditions based on Document Type
      this.documentForm?.controls?.txtDocumentStartDate?.setValidators([
        Validators.required,
      ]);
      this.documentForm?.controls?.txtDocumentEndDate?.setValidators([
        Validators.required,
      ]);
      this.documentForm?.controls?.txtDocumentStartDate?.updateValueAndValidity();
      this.documentForm?.controls?.txtDocumentEndDate?.updateValueAndValidity();
    } else {
      this.panelOpenState = false; // Flag for the Accordion Expansion
      //Validation Conditions based on Document Type
      this.documentForm?.controls?.txtDocumentStartDate?.setValidators([
        Validators.nullValidator,
      ]);
      this.documentForm?.controls?.txtDocumentEndDate?.setValidators([
        Validators.nullValidator,
      ]);

      this.documentForm?.controls?.txtDocumentStartDate?.updateValueAndValidity();
      this.documentForm?.controls?.txtDocumentEndDate?.updateValueAndValidity();
    }
  }

  //Get Uploaded File Name
  getfilename(files: FileList): void {
    this.filesname = [];
    for (let index = 0; index < files.length; index++) {
      if (files[index].type !== DocumentMaxandTypeLimits.documentTypeLimit) {
        this.showErrorForFileType = true;
        this.showErrorForFileLength = false;
        this.filesname = [];
        return;
      }
      if (files.length >= DocumentMaxandTypeLimits.documentMaxNoFileLimit) {
        this.showErrorForFileLength = true;
        this.showErrorForFileType = false;
        this.filesname = [];

        return;
      }
      if (files[index].name.length > 15) {
        this.filesname.push(files[index].name.slice(0, 20) + '...');
      } else {
        this.filesname.push(files[index].name);
      }
    }
    this.intCheckedDocuments = files.length;
    this.isDocumentEditSaveButtonHidden = false;
    this.documentForm.enable();
  }

  //#region ButtonClick Functions

  //Reset Button
  resetDocumentForm(): void {
    this.show = true;
    this.documentForm.reset();
    this.documentForm.patchValue({
      drpReviewStatus: null,
      drpReviewReason: null,
      drpDocumentType: null,
      documentFile: null,
      txtDocumentStartDate: '',
      txtDocumentEndDate: '',
      txtNote: '',
    });

    this.showErrorForFileType = false;
    this.showErrorForFileLength = false;
    this.filesname = [];
    this.isDocumentForButtonDisable = false;
    this.intCheckedDocuments = 0;
    this.documentForm.disable();
    this.documentForm.controls['documentFile'].enable();
    this.documentForm.markAsUntouched();
    this.documentForm.updateValueAndValidity();
    this.panelOpenState = false;
    this.isDocumentEditSaveButtonHidden = true;
    this.strPdfSource = '';
    this.isShowSpinner = false;
    this.strSelectedPdfPath = '';
    this.getDocumentsByPatientId();
  }

  //Save Button Click
  upload(files: any) {
    const description = this.documentForm?.get('txtNote')?.value;
    const startDateValue = this.documentForm?.get(
      'txtDocumentStartDate'
    )?.value;
    const endDateValue = this.documentForm?.get('txtDocumentEndDate')?.value;
    const startDate: string | null =
      startDateValue === ''
        ? null
        : String(new Date(startDateValue).toISOString());
    const endDate: string | null =
      endDateValue === '' ? null : String(new Date(endDateValue).toISOString());

    if (
      this.patientDocumentId === '' &&
      this.selectedDocumentforUpdate === null
    ) {
      if (files?.length === 0) {
        return;
      }
      let fileResult: { name: File; value: string; savedOrNot: boolean }[] = [];
      for (let file of files) {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          fileResult.push({
            name: file,
            value: reader?.result?.slice(28)?.toString() ?? '',
            savedOrNot: false,
          });
          if (fileResult.length === files.length) {
            this.table.setDocumentFormData({
              value: this.documentForm.value,
              filenames: this.filesname,
            });
            this.saveManualDocuments(fileResult, startDate, endDate);
          }
        };
      }
    } else {
      this.isShowSpinner = true;
      const UpdateDetails: UpdateDocumentBlobDTO = {
        note: description,
        documentStartDate: startDateValue,
        documentEndDate: endDateValue,
        documentType: this.documentForm?.get('drpDocumentType')?.value,
        tenantId: this.tenantId,
        reviewReason: this.documentForm?.get('drpReviewReason')?.value,
        reviewStatus: this.documentForm?.get('drpReviewStatus')?.value,
      };
      this.patientDocumentService
        .putByInputAndDocumentId(UpdateDetails, this.patientDocumentId)
        .subscribe(
          () => {
            this.isShowSpinner = false;

            // Swal.fire({
            //   title: 'Success',
            //   html: 'Updated Successfully',
            //   icon: 'success',
            //   timer: 3000,
            //   timerProgressBar: true,
            // });
            this.toastr.success('Updated Successfully', 'Success');
            this.getDocumentGroup();
            this.resetDocumentForm();
          },
          (err) => {
            this.isShowSpinner = false;

            const data: HttpErrorResponse = err;
            Swal.fire({
              icon: 'info',
              text: data?.error?.error?.message,
            });
          }
        );
    }
  }

  //Save Documents Manual Upload
  saveManualDocuments(
    fileResult: { name: File; value: string; savedOrNot: boolean }[],
    startDate?: string,
    endDate?: string
  ) {
    let documentArrayToBeSaved: {
      patientDocumentBlobDTO: SavePatientDocumentBlobDTO;
      patientObjectID: string;
    }[] = [];

    fileResult.forEach((file, index) => {
      if (!file.savedOrNot) {
        let savePatientDocumentBlobDTO: SavePatientDocumentBlobDTO = {
          isVerified: 1,
          patientID: this.patientId, //this.patientId,
          fileName: file.name.name,
          fileContentAsBase64String: file.value,
          documentTypeId: this.patientId, //this.patientId
          documentUploadType: DocumentUploadTypes.ManualUploadType,
          parentPatientDocumentID: null ?? null,
          documentOrderNumber: index,
          documentStartDate: startDate,
          documentEndDate: endDate,
          TenantId: this.tenantId,
          docStatus: '',
          //documentType: this.drpDocumentTypeLoop.filter(v => {return v?.documentType ===this.documentForm?.get("drpDocumentType")?.value; })[0]?.documentType,
          documentType:
            this.documentForm?.get('drpDocumentType')?.value == null
              ? ''
              : this.documentForm?.get('drpDocumentType')?.value,
          reviewStatus:
            this.documentForm?.get('drpReviewStatus')?.value == null
              ? ''
              : this.documentForm?.get('drpReviewStatus')?.value,
          reviewReason:
            this.documentForm?.get('drpReviewReason')?.value == null
              ? ''
              : this.documentForm?.get('drpReviewReason')?.value,
          note: this.documentForm?.get('txtNote')?.value,
          isSorted: 0,
        };
        if (this.parentPatientDocumentID !== '') {
          savePatientDocumentBlobDTO = {
            ...savePatientDocumentBlobDTO,
            parentPatientDocumentID: [this.parentPatientDocumentID],
          };
        }
        let documentToBeSaved = {
          patientDocumentBlobDTO: savePatientDocumentBlobDTO,
          patientObjectID: this.patientId,
        };
        documentArrayToBeSaved.push(documentToBeSaved);
      }
    });
    from(documentArrayToBeSaved)
      .pipe(
        tap(() => {
          this.isShowSpinner = true;
        }),
        groupBy((v) => v.patientObjectID),
        mergeMap((group) => group.pipe(toArray())),
        mergeMap((group) =>
          from(group).pipe(
            concatMap((v) =>
              defer(() =>
                this.patientDocumentService.postByInputAndPatientID(
                  v.patientDocumentBlobDTO,
                  v.patientObjectID
                )
              )
            )
          )
        ),
        takeLast(1),
        map(() => {
          this.parentPatientDocumentID = '';
          this.getDocumentsByPatientId();
          this.getDocumentGroup();
        })
      )
      .subscribe(
        () => {
          this.table.setDocumentFormData(null);
          this.isShowSpinner = false;
          // Swal.fire({
          //   title: 'Success',
          //   html: 'Saved Successfully',
          //   icon: 'success',
          //   timer: 3000,
          //   timerProgressBar: true,
          // });
          this.toastr.success('Saved Successfully', 'Success');
          this.resetDocumentForm();
          this.saveDocumentFormState.emit(this.documentForm.value);
        },
        (err) => {
          const data: HttpErrorResponse = err;
          this.isShowSpinner = false;
          Swal.fire({
            icon: 'info',
            text: data?.error?.error?.message,
          });
        }
      );
  }

  patientDetails: PatientDocumentBlobDTO[] = [];
  //To Get the Uploaded Documents by Patient Id
  getDocumentsByPatientId(): void {
    this.isShowProcessBar = true;
    if (
      this.patientId !== '' &&
      this.patientId !== null &&
      this.patientId !== undefined
    ) {
      const patientGet = this.patientDocumentService
        .getByPatientId(this.patientId)
        .subscribe(
          (response) => {
            this.patientDetails = response;
            this.getDocumentsListByPatientId(this.patientDetails);
          },
          (err) => {
            this.isShowProcessBar = false;
            const data: HttpErrorResponse = err;
            Swal.fire({
              icon: 'info',
              text: data?.error?.error?.message,
            });
          }
        );
      this.subscription$.push(patientGet);
    }
  }

  //To Get the Document List
  getDocumentsListByPatientId(patientDetails: PatientDocumentBlobDTO[]): void {
    this.arrUnsortedDocumentList = [];
    this.arrMergeDocumentList = [];
    // this.extractionUploadedDocuments = [];
    // if (patientDetails && patientDetails?.autoIntakeDocumentDetails && patientDetails?.autoIntakeDocumentDetails !== []) {
    //   this.getExtractionDocumentList(patientDetails);
    // }
    patientDetails?.forEach((element: PatientDocumentBlobDTO) => {
      // if (element?.documentUploadType === DocumentUploadTypes.SplitUpload && element?.isVerified !== 1) {
      // element = this.extractShortNameAndDate(element);
      //} else if (element?.isVerified === 1 && element?.documentUploadType === DocumentUploadTypes.ConsolidatedUpload || element?.documentUploadType === DocumentUploadTypes.ManualUploadType) {
      element = this.extractShortNameAndDate(element);
      // }
    });
    this.documentGrouping();
    this.documentGroupingFunction();
    listOfDocuments = patientDetails;
    this.isShowProcessBar = false;
  }

  //To get Extract the Short Name and Date(Format Table Data)
  private extractShortNameAndDate(
    element: PatientDocumentBlobDTO
  ): PatientDocumentBlobDTO {
    let uploadType: DocumentUploadTypes;
    let resultDocStartDate: string = '';
    let resultDocEndDate: string = '';
    this.tenantId = element?.tenantId ?? defaultGuid;
    const filename: string =
      element?.fileName?.toLowerCase()?.replace('/(XP[a-z]th)|(.pdf)/gi', '') ??
      '';
    if (typeof element?.documentStartDate === 'string') {
      const date = `${new Date(element?.documentStartDate)?.toLocaleDateString(
        'en-US'
      )}`;
      const hours = `${new Date(element?.documentStartDate)?.getHours()}`;
      const time = `${new Date(element?.documentStartDate)?.getMinutes()}`;
      resultDocStartDate = date + '  ' + hours + ':' + time;
    }
    if (typeof element?.documentEndDate === 'string') {
      const date = `${new Date(element?.documentEndDate)?.toLocaleDateString(
        'en-US'
      )}`;
      const hours = `${new Date(element?.documentEndDate)?.getHours()}`;
      const time = `${new Date(element?.documentEndDate)?.getMinutes()}`;
      resultDocEndDate = date + '  ' + hours + ':' + time;
    }
    uploadType = DocumentUploadTypes.ManualUploadType;
    element = {
      fileName: filename,
      documentUploadType: uploadType,
      documentStartDate: resultDocStartDate,
      documentEndDate: resultDocEndDate,
      docStatus:
        new Date(resultDocEndDate) >= this.myDate ? 'Active' : 'Expired',
      ...element,
    };
    this.arrUnsortedDocumentList.push(element);
    this.dataSource = new MatTableDataSource(this.arrUnsortedDocumentList);
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
    return element;
  }

  //Grouping the Documents by Document Type Wise
  documentGroupingFunction(): void {
    this.documentGroupedByType = [];
    const groupedDocument$ = from(this.arrUnsortedDocumentList).pipe(
      groupBy((v) => v.documentTypeId),
      mergeMap((group) =>
        group.pipe(
          reduce(
            (
              acc: Partial<documentGroupIngListType>,
              cur: PatientDocumentBlobDTO
            ) => {
              acc?.groupItem?.push(cur);
              return acc;
            },
            {
              documentType: group.key,
              documentGroupedByType: this.drpDocumentTypeLoop.filter(
                (v) => v?.documentType === group.key
              )[0]?.documentType,
              groupItem: [],
            }
          )
        )
      ),
      toArray()
    );
    const combinedData$ = combineLatest([
      groupedDocument$,
      from(this.drpDocumentTypeLoop),
    ]).subscribe(
      (v) => {
        this.documentGroupedByType = v[0];
      },
      (err) => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      }
    );
    this.subscription$.push(combinedData$);
  }

  //

  // documentGrouping
  documentGrouping(): void {
    // let documentDetails: DocumentGroupDTO;
    const getDocuGrouping = this.patientDocumentService
      .getDocumentGroupByPatientId(this.patientId)
      .subscribe((e) => {
        this.documentGroup = e;
      });
    this.subscription$.push(getDocuGrouping);
  }
  //#endregion

  //Edit Document
  viewPdfFile(selectedBlobName: string, patientDocumentId: string): void {
    this.pdfHeight = '100%';
    this.show = false;

    this.patientDocumentId = patientDocumentId;

    this.getpatientDocumentByBlobID(selectedBlobName, patientDocumentId);
  }

  //To get the pdf File on click File Name under Document Group List
  getPdf(value: string) {
    this.pdfHeight = '100%';
    const getDocument = this.patientDocumentService
      .getPatientDocumentByBlobIDByBlobName(value)
      .subscribe(
        (response) => {
          if (response && response !== null && response?.length !== 0) {
            this.strSelectedPdfPath = String(
              'data:application/pdf;base64,' + response
            );
          }
        },
        (err) => {
          const data: HttpErrorResponse = err;
          Swal.fire({
            icon: 'info',
            text: data?.error?.error?.message,
          });
        },
        () => {}
      );
    this.subscription$.push(getDocument);
  }
  //Get Patient Document by Blob Name and Patient Document Id
  getpatientDocumentByBlobID(
    selectedBlobName: string,
    patientDocumentId: string
  ): void {
    this.isShowSpinner = true;
    const getDocument = this.patientDocumentService
      .getPatientDocumentByBlobIDByBlobName(selectedBlobName)
      .subscribe(
        (response) => {
          this.isShowSpinner = false;
          if (response && response !== null && response?.length !== 0) {
            this.strSelectedPdfPath = String(
              'data:application/pdf;base64,' + response
            );
          }

          let selectedDocument: PatientDocumentBlobDTO = listOfDocuments.filter(
            (document) => {
              return document && document?.id === patientDocumentId;
            }
          )[0];

          this.selectedDocumentforUpdate = selectedDocument;
          (selectedDocument !== null || selectedDocument !== undefined) &&
            this.documentForm.patchValue({
              drpDocumentUploadType: 'Manual Sorting',
              txtDocumentStartDate:
                selectedDocument.documentStartDate == null
                  ? ''
                  : selectedDocument.documentStartDate,
              txtDocumentEndDate:
                selectedDocument.documentEndDate == null
                  ? ''
                  : selectedDocument.documentEndDate,
              drpDocumentType:
                selectedDocument.documentTypeId == ''
                  ? null
                  : selectedDocument.documentType,
              drpReviewStatus:
                selectedDocument.reviewStatus == ''
                  ? null
                  : selectedDocument.reviewStatus,
              drpReviewReason:
                selectedDocument.reviewReason == ''
                  ? null
                  : selectedDocument.reviewReason,
              txtNote: selectedDocument.note,
            });
          if (selectedDocument !== null || selectedDocument !== undefined) {
            this.documentForm.enable();
            this.documentForm.controls['documentFile'].disable();
            this.isDocumentForButtonDisable = true;
            this.isDocumentEditSaveButtonHidden = false;
          }
          this.getDocumentsByPatientId();
        },
        (err) => {
          this.isShowSpinner = false;
          const data: HttpErrorResponse = err;
          Swal.fire({
            icon: 'info',
            text: data?.error?.error?.message,
          });
        }
      );

    this.subscription$.push(getDocument);
  }

  //Download Document
  downloadFile(blobName: string, fileName: string): void {
    this.pdfHeight = '100%';
    const downloadDocument = this.patientDocumentService
      .getPatientDocumentByBlobIDByBlobName(blobName)
      .subscribe(
        (response) => {
          if (response && response !== null && response?.length !== 0) {
            let filelocation: String = String(
              'data:application/pdf;base64,' + response
            );
            let link = document.createElement('a');
            link.download = fileName + '.pdf';
            link.href = filelocation.toString();
            link.click();
          }
        },
        (err) => {
          const data: HttpErrorResponse = err;
          Swal.fire({
            icon: 'info',
            text: data?.error?.error?.message,
          });
        },
        () => {}
      );
    this.subscription$.push(downloadDocument);
  }

  //Delete Document
  deleteFileById(patientDocumentId: string): void {
    this.pdfHeight = '100%';
    this.isShowProcessBar = true;
    this.dataSource = new MatTableDataSource([]);
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
    Swal.fire({
      title: 'Are you sure you want to Delete?',
      text: "You won't be able to retrieve this data!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#34c38f',
      cancelButtonColor: '#f46a6a',
      confirmButtonText: 'Yes, delete it!',
    }).then((result) => {
      if (result.value) {
        const deleteDocumentFile = this.patientDocumentService
          .deleteByPatientIdAndPatientDocumentId(
            this.patientId,
            patientDocumentId
          )
          .subscribe(
            () => {
              this.isShowProcessBar = false;
              this.getDocumentsByPatientId();
              this.getDocumentGroup();
              this.resetDocumentForm();
            },
            (err) => {
              this.isShowProcessBar = false;
              this.dataSource = new MatTableDataSource([]);
              this.dataSource.sort = this.sort;
              this.dataSource.paginator = this.paginator;
              const data: HttpErrorResponse = err;
              Swal.fire({
                icon: 'info',
                text: data?.error?.error?.message,
              });
            },
            () => {}
          );
        this.subscription$.push(deleteDocumentFile);
      }
    });
  }

  //#region tree
  private _transformer = (node: DocumentGroupDTO1, level: number) => {
    return {
      expandable: !!node?.documentDetail && node?.documentDetail?.length > 0,
      name: node?.documentType,
      level: level,
      documentCount: node?.documentCount,
      blobName: node?.blobName,
    };
  };

  treeControl = new FlatTreeControl<DocumentTreeNode>(
    (node) => node?.level,
    (node) => node?.expandable
    // node=>node.
  );

  treeFlattener = new MatTreeFlattener(
    this._transformer,
    (node) => node?.level,
    (node) => node?.expandable,
    (node) => node?.documentDetail
  );

  dataSource1 = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);
  hasChild = (_: number, node: DocumentTreeNode) => node?.expandable;

  getDocumentGroup() {
    const newValue = this.patientDocumentService
      .getDocumentGroupByPatientId(this.patientId)
      .subscribe((response) => {
        const ex: DocumentGroupDTO1[] = response?.map((x, i) => {
          let documentDetail: any[] = response[i].documentDetail?.map((e) => {
            let echDeatils = {
              documentType: e.fileName,
              documentCount: x.documentCount,
              blobName: e.blobName,
              fileName: e.blobName,
              documentId: e.documentId,
            };
            return echDeatils;
          });
          let e = {
            documentType: x.documentType,
            documentCount: x.documentCount,
            documentDetail: documentDetail,
          };
          return e;
        });
        this.dataSource1.data = ex;
      });
    this.subscription$.push(newValue);
  }
  // On Page Destroy
  ngOnDestroy(): void {
    this.subscription$?.forEach((sub) => {
      sub && sub?.unsubscribe();
    });
  }
  //#endregion
}

export interface documentGroupIngListType {
  documentType: string;
  documentGroupedByType: string;
  groupItem: PatientDocumentBlobDTO[];
}

export interface documentListType {
  strDocumentName: string;
  dtDocumentDate: string;
  strDocumentPath: string;
  isSelected: boolean;
}
/** Flat node with expandable and level information */
interface DocumentTreeNode {
  expandable: boolean;
  name: string;
  level: number;
  documentCount: number;
  blobName: string;
}

export interface DocumentGroupDTO1 {
  documentType: string;
  documentCount?: number;
  blobName?: string;
  documentDetail?: DocumentDetail[];
}

export interface DocumentDetail {
  documentType: string;
  documentId?: string;
  blobName?: string;
  fileName?: any;
  documentCount?: number;
}
