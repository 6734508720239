<div class="container-fluid">
  <div class="row">
    <div class="col-12 mb-3">
      <div class="row">
        <div class="col-12 d-flex flex-row">
          <ng-container *ngFor="let data of menus">
            <label
              [ngClass]="getArrowClass(data)"
              [ngStyle]="{ 'background-color': data.color }"
              (click)="binActiveTab(data)"
              class="pointer customPointingLabel claim-status-label rounded-border down mb-2 py-2 px-2 text-center mr-1"
              matlabel
            >
              {{ data.status }} ({{ data.count }})
            </label>
          </ng-container>
        </div>
      </div>

      <div class="card card-body">
        <div class="row">
          <div class="col-12">
            <div class="row">
              <div class="col-12 px-0">
                <p class="header-text mb-0 pl-3">Filters</p>
                <form class="pl-1" [formGroup]="orderBinQueueFilterForm">
                  <!-- Order No -->
                  <mat-form-field class="col-1_5 custom-form-field">
                    <mat-label class="drpLabel"
                      >Order No
                      <ng-container *ngIf="lstSelectedOrderNos?.length > 0"
                        >({{ lstSelectedOrderNos?.length }})</ng-container
                      >
                    </mat-label>
                    <mat-select
                      class="custom-mat-select"
                      formControlName="drpOrderNo"
                      [(ngModel)]="lstSelectedOrderNos"
                      (selectionChange)="onSelectionOrderNoChange($event)"
                      (openedChange)="onOrderNoDropdownOpenChange($event)"
                      (closed)="
                        handleOrderNoCloseDrpdown(
                          orderBinQueueFilterForm.get('drpOrderNo').value
                        )
                      "
                      multiple
                    >
                      <mat-option>
                        <ngx-mat-select-search
                          (keyup)="loadOrderNoDropdown()"
                          placeholderLabel="Order No"
                          formControlName="txtOrderNo"
                          noEntriesFoundLabel="No Matches found"
                        ></ngx-mat-select-search>
                      </mat-option>

                      <mat-option
                        *ngIf="!orderNoSelectAllOption"
                        [value]="'selectAll'"
                        class="drpOptionFontSize"
                        >Select All</mat-option
                      >
                      <mat-option
                        *ngIf="orderNoSelectAllOption"
                        [value]="'DeselectAll'"
                        class="drpOptionFontSize"
                        >Deselect All</mat-option
                      >

                      <mat-option
                        *ngFor="let data of lstOrderNos"
                        [value]="data.orderId"
                        >{{ data.mmRefereceOrderId }}</mat-option
                      >
                    </mat-select>
                    <button
                      mat-icon-button
                      matSuffix
                      *ngIf="lstSelectedOrderNos?.length > 0"
                      (click)="
                        lstSelectedOrderNos = [];
                        orderNoSelectAllOption = false;
                        orderBinQueueFilterForm.get('drpOrderNo').setValue([]);
                        getOrderFileterList();
                        $event.stopPropagation()
                      "
                    >
                      <mat-icon>close</mat-icon>
                    </button>
                  </mat-form-field>
                  <!-- Chart Id -->
                  <mat-form-field class="col-1_5 custom-form-field">
                    <mat-label class="drpLabel"
                      >Chart Id
                      <ng-container *ngIf="lstSelectedChartNos?.length > 0"
                        >({{ lstSelectedChartNos?.length }})</ng-container
                      >
                    </mat-label>
                    <mat-select
                      class="custom-mat-select"
                      formControlName="drpChartId"
                      [(ngModel)]="lstSelectedChartNos"
                      (selectionChange)="onSelectionChartNoChange($event)"
                      (openedChange)="onChartNoDropdownOpenChange($event)"
                      (closed)="
                        handleChartNoCloseDrpdown(
                          orderBinQueueFilterForm.get('drpChartId').value
                        )
                      "
                      multiple
                    >
                      <mat-option>
                        <ngx-mat-select-search
                          (keyup)="loadChartNoDropdown()"
                          placeholderLabel="Chart Id"
                          formControlName="txtChartId"
                          noEntriesFoundLabel="No Matches found"
                        ></ngx-mat-select-search>
                      </mat-option>
                      <mat-option
                        *ngIf="!chartNoSelectAllOption"
                        [value]="'selectAll'"
                        class="drpOptionFontSize"
                        >Select All</mat-option
                      >
                      <mat-option
                        *ngIf="chartNoSelectAllOption"
                        [value]="'DeselectAll'"
                        class="drpOptionFontSize"
                        >Deselect All</mat-option
                      >
                      <mat-option
                        *ngFor="let data of lstChartNos"
                        [value]="data?.spatientID"
                        [ngClass]="{
                          inActiveRecord: data?.isActive == '003'
                        }"
                        [matTooltip]="
                          data?.isActive == '003'
                            ? 'Inactive Record'
                            : data?.chartId
                        "
                        >{{ data?.chartId }}</mat-option
                      >
                    </mat-select>
                    <button
                      matSuffix
                      *ngIf="lstSelectedChartNos?.length > 0"
                      (click)="
                        lstSelectedChartNos = [];
                        chartNoSelectAllOption = false;
                        orderBinQueueFilterForm.get('drpChartId').setValue([]);
                        getOrderFileterList();
                        $event.stopPropagation()
                      "
                      mat-icon-button
                    >
                      <mat-icon>close</mat-icon>
                    </button>
                  </mat-form-field>

                  <!-- Patient Name -->
                  <mat-form-field class="col-1_5 custom-form-field">
                    <mat-label class="drpLabel"
                      >Patient
                      <ng-container *ngIf="lstSelectedPatients?.length > 0"
                        >({{ lstSelectedPatients?.length }})</ng-container
                      >
                    </mat-label>
                    <mat-select
                      class="custom-mat-select"
                      formControlName="drpPatientName"
                      (selectionChange)="onSelectionPatientNameChange($event)"
                      (openedChange)="onPatientNameDropdownOpenChange($event)"
                      (closed)="
                        handlePatientNameCloseDrpdown(
                          orderBinQueueFilterForm.get('drpPatientName').value
                        )
                      "
                      [(ngModel)]="lstSelectedPatients"
                      multiple
                    >
                      <mat-option>
                        <ngx-mat-select-search
                          placeholderLabel="Patient Name"
                          (keyup)="loadPatientDropdown()"
                          formControlName="txtPatientName"
                          noEntriesFoundLabel="No Matches found"
                        ></ngx-mat-select-search>
                      </mat-option>
                      <mat-option
                        *ngIf="!patientNameSelectAllOption"
                        [value]="'selectAll'"
                        class="drpOptionFontSize"
                        >Select All</mat-option
                      >
                      <mat-option
                        *ngIf="patientNameSelectAllOption"
                        [value]="'DeselectAll'"
                        class="drpOptionFontSize"
                        >Deselect All</mat-option
                      >
                      <mat-option
                        *ngFor="let data of lstPatients"
                        [value]="data.patId"
                        [matTooltip]="
                          data?.activeStatus == '003'
                            ? 'Inactive Record'
                            : data?.fullName
                        "
                        [ngClass]="{
                          inActiveRecord: data?.activeStatus == '003'
                        }"
                        >{{ data?.fullName }}</mat-option
                      >
                    </mat-select>
                    <button
                      mat-icon-button
                      matSuffix
                      *ngIf="lstSelectedPatients?.length > 0"
                      (click)="
                        lstSelectedPatients = [];
                        patientNameSelectAllOption = false;
                        orderBinQueueFilterForm
                          .get('drpPatientName')
                          .setValue([]);
                        getOrderFileterList();
                        $event.stopPropagation()
                      "
                    >
                      <mat-icon>close</mat-icon>
                    </button>
                  </mat-form-field>

                  <!-- Status -->
                  <mat-form-field class="col-1_5 custom-form-field">
                    <mat-label class="drpLabel"
                      >Status
                      <ng-container *ngIf="lstSelectedOrderStatus?.length > 0"
                        >({{ lstSelectedOrderStatus?.length }})</ng-container
                      >
                    </mat-label>
                    <mat-select
                      (selectionChange)="onSelectionStatusChange($event)"
                      (openedChange)="onStatusDropdownOpenChange($event)"
                      (closed)="
                        handleStatusCloseDrpdown(
                          orderBinQueueFilterForm.get('drpStatus').value
                        )
                      "
                      class="custom-mat-select"
                      formControlName="drpStatus"
                      [(ngModel)]="lstSelectedOrderStatus"
                      multiple
                    >
                      <mat-option>
                        <ngx-mat-select-search
                          (keyup)="loadOrderStatusDropdown()"
                          placeholderLabel="Status"
                          formControlName="txtStatus"
                          noEntriesFoundLabel="No Matches found"
                        ></ngx-mat-select-search>
                      </mat-option>
                      <mat-option
                        *ngIf="!orderStatusSelectAllOption"
                        [value]="'selectAll'"
                        class="drpOptionFontSize"
                        >Select All</mat-option
                      >
                      <mat-option
                        *ngIf="orderStatusSelectAllOption"
                        [value]="'DeselectAll'"
                        class="drpOptionFontSize"
                        >Deselect All</mat-option
                      >
                      <mat-option
                        *ngFor="let data of lstOrderStatus"
                        [value]="data.orderStatusId"
                        >{{ data.description }}</mat-option
                      >
                    </mat-select>
                    <button
                      mat-icon-button
                      matSuffix
                      *ngIf="lstSelectedOrderStatus?.length > 0"
                      (click)="
                        lstSelectedOrderStatus = [];
                        orderStatusSelectAllOption = false;
                        orderBinQueueFilterForm.get('drpStatus').setValue([]);
                        getOrderFileterList();
                        $event.stopPropagation()
                      "
                    >
                      <mat-icon>close</mat-icon>
                    </button>
                  </mat-form-field>

                  <!-- Insurance  -->
                  <mat-form-field
                    *ngIf="
                      activeTabBin == MyQueueBinStatus.Billing ||
                      activeTabBin == MyQueueBinStatus.Shipping
                    "
                    class="col-1_5 custom-form-field"
                  >
                    <mat-label class="drpLabel"
                      >Insurance
                      <ng-container *ngIf="lstSelectedInsurance?.length > 0"
                        >({{ lstSelectedInsurance?.length }})</ng-container
                      ></mat-label
                    >
                    <mat-select
                      (selectionChange)="onSelectionInsuranceChange($event)"
                      (openedChange)="onInsuranceDropdownOpenChange($event)"
                      (closed)="
                        handleInsuranceCloseDrpdown(
                          orderBinQueueFilterForm.get('drpInsurance').value
                        )
                      "
                      [(ngModel)]="lstSelectedInsurance"
                      class="custom-mat-select dd-text"
                      formControlName="drpInsurance"
                      multiple
                    >
                      <mat-option>
                        <ngx-mat-select-search
                          (keyup)="loadInsuranceDropdown()"
                          placeholderLabel="Insurance"
                          formControlName="txtInsurance"
                          noEntriesFoundLabel="No Matches found"
                        ></ngx-mat-select-search>
                      </mat-option>
                      <mat-option
                        *ngIf="!insuranceSelectAllOption"
                        [value]="'selectAll'"
                        class="drpOptionFontSize"
                        >Select All</mat-option
                      >
                      <mat-option
                        *ngIf="insuranceSelectAllOption"
                        [value]="'DeselectAll'"
                        class="drpOptionFontSize"
                        >Deselect All</mat-option
                      >
                      <mat-option
                        *ngFor="let data of lstInsurance"
                        [value]="data.payerId"
                        [ngClass]="{ inActiveRecord: !data?.isActive }"
                        [matTooltip]="
                          !data?.isActive ? 'Inactive Record' : data.payerName
                        "
                        >{{ data.payerName }}</mat-option
                      >
                    </mat-select>
                    <button
                      matSuffix
                      mat-icon-button
                      *ngIf="lstSelectedInsurance?.length > 0"
                      (click)="
                        lstSelectedInsurance = [];
                        insuranceSelectAllOption = false;
                        orderBinQueueFilterForm
                          .get('drpInsurance')
                          .setValue([]);
                        getOrderFileterList();
                        $event.stopPropagation()
                      "
                    >
                      <mat-icon>close</mat-icon>
                    </button>
                  </mat-form-field>

                  <!--Order Date -->
                  <mat-form-field
                    *ngIf="activeTabBin == MyQueueBinStatus.SaleOrder"
                    class="dd-text col-2"
                  >
                    <mat-label class="drpLabel">Order Date</mat-label>
                    <mat-date-range-input [rangePicker]="orderDatePicker">
                      <input
                        matStartDate
                        matInput
                        placeholder="Start date"
                        formControlName="txtOrderFromDate"
                      />

                      <input
                        matEndDate
                        (dateChange)="onOrderDateChange()"
                        matInput
                        placeholder="End date"
                        formControlName="txtOrderToDate"
                      />
                    </mat-date-range-input>
                    <mat-datepicker-toggle
                      matSuffix
                      [for]="orderDatePicker"
                    ></mat-datepicker-toggle>
                    <mat-date-range-picker
                      #orderDatePicker
                    ></mat-date-range-picker>
                    <button
                      mat-icon-button
                      *ngIf="
                        orderBinQueueFilterForm.get('txtOrderFromDate').value ||
                        orderBinQueueFilterForm.get('txtOrderToDate').value
                      "
                      matSuffix
                      (click)="
                        clearOrderDate();
                        orderBinQueueFilterForm
                          .get('txtOrderFromDate')
                          .setValue('');
                        orderBinQueueFilterForm
                          .get('txtOrderToDate')
                          .setValue('');
                        getOrderFileterList();
                        $event.stopPropagation()
                      "
                    >
                      <mat-icon>close</mat-icon>
                    </button>
                  </mat-form-field>
                  <!-- Supplies Date -->
                  <mat-form-field
                    class="dd-text col-2"
                    *ngIf="activeTabBin == MyQueueBinStatus.Resupply"
                  >
                    <mat-label class="drpLabel">Supplies Date</mat-label>
                    <mat-date-range-input [rangePicker]="suppliesDatePicker">
                      <input
                        matStartDate
                        matInput
                        placeholder="Start date"
                        formControlName="txtSuppliesFromDate"
                      />

                      <input
                        matEndDate
                        matInput
                        (dateChange)="onSuppliesDateChange()"
                        placeholder="End date"
                        formControlName="txtSuppliesToDate"
                      />
                    </mat-date-range-input>
                    <mat-datepicker-toggle
                      matSuffix
                      [for]="suppliesDatePicker"
                    ></mat-datepicker-toggle>
                    <mat-date-range-picker
                      #suppliesDatePicker
                    ></mat-date-range-picker>
                    <button
                      *ngIf="
                        orderBinQueueFilterForm.get('txtSuppliesFromDate')
                          .value ||
                        orderBinQueueFilterForm.get('txtSuppliesToDate').value
                      "
                      mat-icon-button
                      matSuffix
                      (click)="
                        clearSuppliesDate();
                        orderBinQueueFilterForm
                          .get('txtSuppliesFromDate')
                          .setValue('');
                        orderBinQueueFilterForm
                          .get('txtSuppliesToDate')
                          .setValue('');
                        getOrderFileterList();
                        $event.stopPropagation()
                      "
                    >
                      <mat-icon>close</mat-icon>
                    </button>
                  </mat-form-field>

                  <button
                    type="button"
                    (click)="resetForm()"
                    matTooltip="Reset"
                    mat-raised-button
                    class="reset-icon-button ml-4 mat-raised-small-button"
                  >
                    <mat-icon class="fg-white">refresh</mat-icon>
                  </button>
                </form>
              </div>
            </div>
            <mat-divider class="col-12 px-0 my-2"></mat-divider>
            <div
              class="d-flex justify-content-center"
              *ngIf="loadInboundOrderData"
            >
              <img
                class="img-responsive pb-2 mr-1"
                src="assets/gifs/Infinity-loader.gif"
              />
            </div>
            <app-order-bin-que-table
              *ngIf="!loadInboundOrderData"
              (nevigatePage)="changePage($event)"
              [orderTableData]="inboundOrderTableData"
              [activeTabBin]="activeTabBin"
              [currentPage]="currentPage"
              [totalPages]="totalPages"
              [paginationInfo]="paginationInfo"
            ></app-order-bin-que-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
