<mat-dialog-content class="mat-typography">
    <div class="row">
        <div class="col-lg-6">
            <h2>Edit Payments</h2>
        </div>
        <!-- <div class="col-lg-6 text-right">
        <button class="text-right close" aria-label="Close" mat-dialog-close> <b> X </b>
        </button>
      </div> -->
    </div>
    <div class="row">
        <!-- <div class="col-1"></div> -->
        <div class="col-1 text-right">
            <span class="mb-2 mr-8 text-right">Visit</span>
        </div>
        <div class="col-3 text-left">
            <mat-radio-group>
                <mat-radio-button class="mb-2 mr-2" value="Pending">Pending</mat-radio-button> &nbsp;&nbsp;
                <mat-radio-button class="mb-2 mr-2" value="UnableToVerify">All Patient</mat-radio-button>
            </mat-radio-group>
        </div>
        <div class="col-8 text-left">
            <mat-checkbox class="ml-2">Show Insurance Payment Detail</mat-checkbox>
        </div>
    </div>
    <div class="col-lg-12">
        <app-table [strPageType]="strPageType" (viewButton)="ViewClaim($event)">
        </app-table>
    </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button class="buttonCancelColor" mat-button mat-dialog-close>Close</button>
    <!-- <button mat-button [mat-dialog-close]="true" cdkFocusInitial>Install</button> -->
</mat-dialog-actions>