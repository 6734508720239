<br>
<div class="container-fluid">
  <div class="row bg">
    <div class="col-12">
      <div class="d-flex align-items-center justify-content-between mb-3">
        <h3 class="font-weight-bolder mb-0">Purchase Order List</h3>
        <div class=" page-title-box page-title-right">
          <ol class="breadcrumbs m-0 align-items-center">
            <button mat-button class="mr-2 buttonColor"
    [disabled]="(dataSource && dataSource?.data && dataSource?.data?.length === 0)"

            (click)="exportTableData()">
              Export
            </button>
            <li class="
            mt-14">
              <button matTooltip="Click To Add New Row" mat-button class="buttonColor font-size-10"
                (click)="addPurchaseOrder()">
                <!-- <i class="mdi mdi-plus mr-1"></i> -->
                <i class="fa fa-plus mr-1" ></i>
                Add Purchase Order
              </button>
            </li>
            <li class="ml-2 mt-2 breadcrumb-item">Inventory</li>
            <li class="mt-2 breadcrumb-item muted">Purchase Order</li>
          </ol>
        </div>
      </div>
    </div>
  </div>

  <div class="col-12" style="padding-left: 1px;
  padding-right: 1px;padding-bottom: 4px;" >
    <div class="card card-body">
      <!-- <ng-container>
        <b class="customThemeClass">Purcahse Order List Search</b>
      </ng-container> -->
      <mat-accordion style="border: 1px solid #dbd5d5de;border-radius: inherit !important;">
        <mat-expansion-panel [expanded]="step">
          <mat-expansion-panel-header>
            <mat-panel-title class="customThemeClass">
              <ng-container>
                <b class="customThemeClass">Purcahse Order List Search</b>
              </ng-container>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div class="col-12" style="margin-top: -19px;">
            <mat-card-content>

      <form [formGroup]="purchaseOrderListForm">
        <div class="row">
          <mat-form-field class="col-3">
            <mat-label>Purchase Order No</mat-label>
            <mat-select #purchaseOrderSelect multiple formControlName="txtPurchaseOrderNo"
              (selectionChange)="onFilterPurchaseOrderNo()" [(value)]="PurchaseOrderNo">
              <mat-option>
                <ngx-mat-select-search placeholderLabel="Search Purchase Order No"
                  noEntriesFoundLabel="No Records found" formControlName="txtPurchaseOrderNoSearch">
                </ngx-mat-select-search>
              </mat-option>

              <mat-option #allPurchaseOrderNoSelected [value]="0" (click)="togglePurchaseOrderNoSelection()">Select All
                Purchase Order No</mat-option>

              <mat-option *ngFor="let purchaseOrderNo of filterPurchaseOrderNo | async" [value]="purchaseOrderNo">
                {{ purchaseOrderNo }}
              </mat-option>
            </mat-select>

            <button mat-button (click)="PurchaseOrderNo='';$event.stopPropagation()" *ngIf="PurchaseOrderNo" matSuffix
              (click)="clearPurcaseOrderNo()" mat-icon-button >
              <mat-icon>close</mat-icon>
            </button>

          </mat-form-field>
          <!--
          <mat-form-field class="col-3">
            <mat-label>Manufacturer  Id</mat-label>
            <mat-select #VendorIdSelect multiple formControlName="txtVendorId"
              (selectionChange)="onFilterVendorIdChange()" [(value)]="vendorID">
              <mat-option>
                <ngx-mat-select-search placeholderLabel="Search Manufacturer  Id" noEntriesFoundLabel="No Records found"
                  formControlName="txtVendorIdSearch">
                </ngx-mat-select-search>
              </mat-option>

              <mat-option #allVendorIdSelected [value]="0" (click)="toggleAllVendorIdSelection()">Select All
                Manufacturer  Id</mat-option>
              <mat-option *ngFor="let patientIds of filterPatientId  | async" [value]="patientIds">
                {{ patientIds }}
              </mat-option>
            </mat-select>
            <button mat-button (click)="vendorID='';$event.stopPropagation()" *ngIf="vendorID" matSuffix
              (click)="clearVendorId()" mat-icon-button >
              <mat-icon>close</mat-icon>
            </button>
          </mat-form-field> -->

          <mat-form-field class="col-3">
            <mat-label>Manufacturer  Name</mat-label>
            <mat-select #VendorNameSelect multiple formControlName="txtVendorName"
              (selectionChange)="onFilterVendorNameChange()" [(value)]="vendorName">
              <mat-option>
                <ngx-mat-select-search placeholderLabel="Search Manufacturer  Name" noEntriesFoundLabel="No Records found"
                  formControlName="txtVendorNameSearch">
                </ngx-mat-select-search>
              </mat-option>
              <mat-option #allVendorNameSelected [value]="0" (click)="toggleAllVendorNameSelection()">Select All
                Manufacturer  Name</mat-option>

              <mat-option *ngFor="let vendorNames of filterVendorName | async" [value]="vendorNames">
                {{ vendorNames }}
              </mat-option>
            </mat-select>
            <button mat-button (click)="vendorName='';$event.stopPropagation()" *ngIf="vendorName" matSuffix
              (click)="clearVendorName()" mat-icon-button >
              <mat-icon>close</mat-icon>
            </button>
          </mat-form-field>



          <!-- <mat-form-field class="col-3">
            <mat-label>Product Names</mat-label>
            <mat-select #productNameSelect multiple formControlName="txtProductName"
              (selectionChange)="onFilterProductNameChange()" [(value)]="productName">
              <mat-option>
                <ngx-mat-select-search placeholderLabel="Search Product Name" noEntriesFoundLabel="No Records found"
                  formControlName="txtProductNameSearch">
                </ngx-mat-select-search>
              </mat-option>

              <mat-option #allProductNameSelect [value]="0" (click)="toggleProductNameSelection()">Select All
                Product No</mat-option>

              <mat-option *ngFor="let productNames of filterProductNames | async" [value]="productNames">
                {{productNames }}
              </mat-option>
            </mat-select>
            <button mat-button (click)="productName='';$event.stopPropagation()" *ngIf="productName" matSuffix
              (click)="clearProductName()" mat-icon-button >
              <mat-icon>close</mat-icon>
            </button>
          </mat-form-field> -->


          <!-- <mat-form-field class="col-6">
            <mat-label>Purchase Order Status</mat-label>
            <mat-select #PurchaseOrderStatusSelect  formControlName="txtPurchaseOrderStatus"
              (selectionChange)="onFilterPurchaseOrdrerStatusChange()" [(value)]="purchaseOrderStatus">
              <mat-option *ngFor="let PurchaseOrderStatus of drpPurchaseOrderStatus"
                [value]="PurchaseOrderStatus">
                {{ PurchaseOrderStatus }}
              </mat-option>
            </mat-select>
            <button mat-button (click)="purchaseOrderStatus='';$event.stopPropagation()" *ngIf="purchaseOrderStatus"
              matSuffix (click)="clearPurchaseOrderStatus()" mat-icon-button >
              <mat-icon>close</mat-icon>
            </button>
          </mat-form-field> -->


          <mat-form-field class="col-6">
            <mat-label>Purchase Order Raised Date Range</mat-label>
            <input autocomplete="new-txtFromDate" matInput type="text" name="daterange" ngxDaterangepickerMd [(ngModel)]="selectedRange"
              (click)="change()" [showCustomRangeLabel]="true" [alwaysShowCalendars]="true" [linkedCalendars]="false"
              [ranges]="ranges" [showClearButton]="true" [locale]="calendarLocale" [placeholder]="calendarPlaceholder"
              [keepCalendarOpeningWithRange]="true" [minDate]="minDate" [maxDate]="maxDate"
              formControlName="txtFromDate" />
            <mat-icon matSuffix>date_range</mat-icon>
          </mat-form-field>

          <div class="col-12 text-align-center" style='top:3px'>
            <button mat-button class="buttonColor mb-2 mr-2" (click)="search()">Search
            </button>
            <button mat-button class="resetclr" (click)="reset()">Reset
            </button>
          </div>

        </div>
      </form>
    </mat-card-content>
    </div>
  </mat-expansion-panel>
  </mat-accordion>
    </div>

  </div>

  <div class="card card-body">
    <h4 class="customThemeClass ml-3 mt-2"><b>Purchase Order List</b></h4>

    <div class="row ml-2">

      <div class="col-12 table-responsive">
        <!-- <div [hidden]="!isShowSpinner"
          style="display: flex; justify-content: center; align-items: center; background: transparent; color: lightseagreen;">
          <mat-progress-bar mode="indeterminate" value='66' diameter="45" class="lightseagreen">
          </mat-progress-bar>
        </div> -->
        <table mat-table class="table border" matSort [dataSource]="dataSource">
          <ng-container matColumnDef="sno">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>S.No</th>
            <td mat-cell *matCellDef="let data; let i = index">
              {{ i+1 }}
            </td>
          </ng-container>

          <ng-container matColumnDef="POno">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              Purchase OrderNo
            </th>
            <td mat-cell *matCellDef="let data">
              <ng-container>
                {{ data?.POno }}
              </ng-container>
            </td>
          </ng-container>
          <ng-container matColumnDef="vEmail">
            <th class="header"  mat-sort-header mat-header-cell *matHeaderCellDef>
              Manufacturer  EmailId
            </th>
            <td mat-cell *matCellDef="let data">
              <ng-container>
                {{ data?.vEmail }}
              </ng-container>
            </td>
          </ng-container>
          <ng-container matColumnDef="date">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              Purchase Raised Date
            </th>
            <td mat-cell *matCellDef="let data">
              <ng-container>
                {{ data?.PORaisedDate | date: 'MM/dd/yyyy h:mm a':'en_US' }}
              </ng-container>
            </td>
          </ng-container>
          <ng-container matColumnDef="vName">
            <th class="header" mat-sort-header mat-header-cell *matHeaderCellDef >
              Manufacturer  Name
            </th>
            <td mat-cell *matCellDef="let data">
              <ng-container>
                {{ data?.vName }}
              </ng-container>
            </td>
          </ng-container>

          <ng-container matColumnDef="status">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              Status
            </th>
            <td mat-cell *matCellDef="let data">
              <ng-container>
                {{ data?.status }}
              </ng-container>
            </td>
          </ng-container>
          <ng-container matColumnDef="createdDate">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              Created Date
            </th>
            <td mat-cell *matCellDef="let data">
              <ng-container>
                {{ data?.createdDate | date: 'MM/dd/yyyy h:mm a':'en_US' }}
              </ng-container>
            </td>
          </ng-container>

          <ng-container matColumnDef="Options">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Options</th>
            <td mat-cell *matCellDef="let data">

              <a class="editBtnColor">
                <i class="fa fa-pencil editBtnColor" (click)="viewPurchaseOrder(data.id)" matTooltip="Click To Edit"
                  ></i>
              </a>
            </td>
          </ng-container>

          <ng-container matColumnDef="noRecords">
            <td class="text-align-center" mat-footer-cell *matFooterCellDef colspan="11">
              <div *ngIf="!isShowSpinner">
                {{"No Records Found"}}
              </div>
              <div [hidden]="!isShowSpinner">
                <mat-spinner [diameter]="220"></mat-spinner>
              </div>
            </td>
          </ng-container>
          <ng-container matColumnDef="paginator">
            <td mat-footer-cell *matFooterCellDef colspan="11">
              <mat-paginator class="table table-responsive mat-table-class" #MatPaginator
                [length]="dataSource?.data?.length" [pageSize]="10" [pageIndex]="0" showFirstLastButtons
                [pageSizeOptions]="[5, 10, 25, 100]">
              </mat-paginator>
            </td>
          </ng-container>
          <tr mat-footer-row *matFooterRowDef="['noRecords']"
            [ngClass]="{ active: dataSource && dataSource?.data && dataSource?.data?.length !== 0 }"></tr>
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
          <tr mat-footer-row *matFooterRowDef="['paginator']"></tr>
        </table>
      </div>
    </div>
  </div>
</div>
