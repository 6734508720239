import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DataTableDirective } from 'angular-datatables';

@Component({
  selector: 'app-master-doctor-table',
  templateUrl: './master-doctor-table.component.html',
  styleUrls: ['./master-doctor-table.component.scss']
})
export class MasterDoctorTableComponent implements OnInit {

  @Input() doctorTableData: any[];
  @Input() currentPage: any = 1;
  @Input() totalPages: any = 1;
  @Input() visibleColumns: number[];
  @Input() paginationInfo: { startRecord: number, endRecord: number, overallTableCount:number };
  dtOptionsPhysician:any;
  @Output() nevigatePage? = new EventEmitter<number>();
  physicianDataList: any[];
  @ViewChild(DataTableDirective, { static: false })
  datatableElement: DataTableDirective;


  constructor(
    private snackBar: MatSnackBar,
    private datepipe: DatePipe
  ) { }

  ngOnInit(): void {
    this.dataTableInitilization();
    this.physicianDataList = this.doctorTableData;
    this.dtOptionsPhysician.buttons[0].filename =
      'Master Physician' + '-' + this.datepipe.transform(new Date(), 'MM-dd-yyyy');
  }

  ngAfterViewInit(): void {
    if (this.datatableElement && this.datatableElement.dtInstance) {
      this.refreshDataTable();
    } else {
      setTimeout(() => this.refreshDataTable(), 500);
    }
  }
  dataTableInitilization(){
    this.dtOptionsPhysician = {
      responsive: true,
      paging: false,
      scrollCollapse: true,
      scrollY: '410px',
      scrollX: true,
      dom: 'Bfrtip',
      order: [],
      language: {
        emptyTable: 'No records',
        info: `Showing ${this.paginationInfo?.startRecord ?? 0} to ${this.paginationInfo?.endRecord ?? 0} of ${this.paginationInfo?.overallTableCount ?? 0} records`,
        infoEmpty: 'Total : _MAX_ records',
        infoFiltered: '(filtered : _TOTAL_ records)',
        loadingRecords: 'Loading...',
        zeroRecords: 'No matching records',
      },
      buttons: [
        {
          extend: 'excel',
          text: 'Export',
          filename: 'Master Physician',
        }
      ]
    };
  }
  refreshDataTable(): void {
    this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
      // Clear search input values
      $('input', dtInstance.table(0).footer()).val('');
      // Redraw the DataTable to load all records
      dtInstance.search('').columns().search('').draw();
      this.addPageNavigationControls();
      // Attach the event listener for filtering
      var visColumns = this.visibleColumns;
      var index = 0;
      dtInstance.columns().every(function () {
        const that = this;
        if (visColumns && visColumns.length > 0) {
          if (visColumns.indexOf(index) < 0)
            this.visible(false);
        }
        index++;

        $('input', this.footer()).on('keyup change', function () {
          if (that.search() !== this['value']) {
            that.search(this['value']).draw();
          }
        });
      });
    });
  }


  addPageNavigationControls() {
    let buttonsElement = $(".dt-buttons")[0];

    $("<span> : <span>").appendTo(buttonsElement);
    if (this.currentPage == 1) {
      $("<button disabled title='First Page' class='dt-button' id='btnFirstPage' type='button'><span>&lt;&lt;</span></button>").appendTo(buttonsElement);
      $("<button disabled class='dt-button' id='btnPrevPage' type='button'><span>&lt;</span></button>").appendTo(buttonsElement);
    }
    else {
      $("<button title='First Page' class='dt-button' id='btnFirstPage' type='button'><span>&lt;&lt;</span></button>").appendTo(buttonsElement);
      $("<button title='Previous Page' class='dt-button' id='btnPrevPage' type='button'><span>&lt;</span></button>").appendTo(buttonsElement);
    }

    $("<span> Page <span>").appendTo(buttonsElement);
    $("<input style='width:50px;' id='txtGotoPage' value='" + this.currentPage + "' type='text' maxlength='10'/>").appendTo(buttonsElement);
    $('#txtGotoPage').on('input', function (e) {
      let value = (this as HTMLInputElement).value;
      value = value.replace(/[^0-9]/g, '');
      if (value.startsWith('0')) {
        value = value.substring(1);
      }
      (this as HTMLInputElement).value = value;
    });
    $("<span> of " + this.totalPages + " <span>").appendTo(buttonsElement);
    $("<button  class='dt-button' id='btnGoToPage'   type='button'><span>Go to</span></button>").appendTo(buttonsElement);

    if (this.currentPage >= this.totalPages) {
      $("<button disabled class='dt-button' id='btnNextPage'   type='button'><span>&gt;</span></button>").appendTo(buttonsElement);
      $("<button disabled title='Last Page'  class='dt-button' id='btnLastPage'  type='button'><span>&gt;&gt</span></button>").appendTo(buttonsElement);
    }
    else {
      $("<button title='Next Page'  class='dt-button' id='btnNextPage'  type='button'><span>&gt;</span></button>").appendTo(buttonsElement);
      $("<button title='Last Page'  class='dt-button' id='btnLastPage'  type='button'><span>&gt;&gt</span></button>").appendTo(buttonsElement);
    }

    $('#btnNextPage').on('click', (event) => this.changePage(true));
    $('#btnPrevPage').on('click', (event) => this.changePage(false));
    $('#btnGoToPage').on('click', (event) => this.fnGotoPage());
    $('#btnFirstPage').on('click', (event) => this.nevigatePage.emit(1));
    $('#btnLastPage').on('click', (event) => this.nevigatePage.emit(this.totalPages));

  // $(".buttons-colvis").on('click', (event) => this.fnColumnSelectionClick());
  // $('#btnDefaultClms').on('click', (event) => this.fnGetColumnVisiblity("DEFAULT"));
  // $('#btnAllClms').on('click', (event) => this.fnGetColumnVisiblity("ALL"));
  }

  changePage(isNext: boolean) {
    if (isNext)
      this.currentPage++;
    else
      this.currentPage--;
    this.nevigatePage.emit(this.currentPage);
  }


  fnGotoPage() {
    let pageValue = $("#txtGotoPage").val();
    let pageno = parseInt(pageValue.toString());
    if (pageno > 0 && this.currentPage != pageno && pageno <= this.totalPages) {
      this.nevigatePage.emit(pageno);
    }
  }

  onRowDoubleClick(textToCopy) {
    this.copyTextToClipboard(textToCopy);
    this.showNotification('Text copied: ' + textToCopy);
  }

  showNotification(message: string): void {
    this.snackBar.open(message, 'Close', {
      duration: 3000, // Duration in milliseconds
      horizontalPosition: 'end', // Positioning the notification horizontally
      verticalPosition: 'bottom', // Positioning the notification vertically
    });
  }

  copyTextToClipboard(text: string): void {
    const textArea = document.createElement('textarea');
    textArea.value = text;
    document.body.appendChild(textArea);
    textArea.select();
    document.execCommand('Copy');
    textArea.remove();
  }

}
