<mat-dialog-content class="mat-typography">
    <div class="row">
        <div class="col-lg-6">
            <h2>{{ reminderId === "00000000-0000-0000-0000-000000000000" ? "Add Reminder" : "Edit Reminder" }}</h2>
        </div>
    </div>
    <hr />
    <div class="row">
        <div class="col-lg-12">
            <mat-card-content>
                <form [formGroup]="reminderForm">
                    <div class="row">
                        <mat-form-field class="col-6">
                            <mat-label>Scheduled Date <span class="asterisk">*</span>
                            </mat-label>
                            <input maxlength="40" autocomplete="new-txtDate" formControlName="txtDate" matInput [matDatepicker]="txtDate"
                                [min]="minDate" placeholder="MM/DD/YYYY"
                                [formControl]="reminderForm?.controls.txtDate" />
                            <mat-datepicker-toggle matSuffix [for]="txtDate"></mat-datepicker-toggle>
                            <mat-datepicker #txtDate></mat-datepicker>
                            <mat-error *ngIf="
                  reminderForm?.get('txtDate')?.touched ||
                  (reminderForm?.get('txtDate')?.errors?.required &&
                    reminderForm?.get('txtDate')?.dirty)
                ">
                                Scheduled Date is a required field!
                            </mat-error>
                            <mat-error *ngIf="
                  reminderForm?.get('txtDate')?.touched &&
                  reminderForm?.get('txtDate')?.errors?.matDatepickerParse
                ">
                                Enter Valid Date!
                            </mat-error>
                            <mat-error *ngIf="
                  minDate >= reminderForm?.get('txtDate')?.value &&
                  !(
                    reminderForm?.get('txtDate')?.touched &&
                    reminderForm?.get('txtDate')?.errors
                  )
                ">
                                Enter Future Dates Only!
                            </mat-error>
                        </mat-form-field>
                        <ng-select class="col-6" [multiple]="true" formControlName="drpAssignedTo" placeholder="Assigned To"
                            maxlength="40">
                            <ng-option *ngFor="let user of arrUsersList" [value]="user.id">
                                {{ user.userName }}</ng-option>
                        </ng-select>

                        <div class="col-6" >
                            <br />
                            <mat-checkbox formControlName="chkCompleted">Completed</mat-checkbox>
                        </div>
                        <div class="col-6" >
                            <br />
                            <mat-checkbox formControlName="chkDontShowAgain">Dont Remind Again</mat-checkbox>
                        </div>
                        <mat-form-field class="col-12">
                            <mat-label>Subject <span class="asterisk">*</span></mat-label>
                            <textarea matInput rows="9" maxlength="400" formControlName="txtSubject"></textarea>
                            <mat-error
                                *ngIf=" reminderForm?.get('txtSubject')?.touched && reminderForm?.get('txtSubject')?.errors?.required ">
                                Subject is a required field!
                            </mat-error>
                        </mat-form-field>
                    </div>
                </form>
            </mat-card-content>
        </div>
    </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button  *ngIf="!isCompleted"  mat-button (click)="saveReminders()" [disabled]="
      saveButtonHide || !(!reminderForm?.invalid && reminderForm?.dirty)
    " class="buttonColor">
        Save
        <mat-icon *ngIf="saveButtonHide">
            <mat-spinner class="spinner-border spinner-border-sm" diameter="20">
            </mat-spinner>
        </mat-icon>
    </button>
    <button class="buttonCancelColor" mat-button mat-dialog-close>Close</button>
</mat-dialog-actions>
