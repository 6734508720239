import { HttpErrorResponse } from '@angular/common/http';
import {
  Component,
  ElementRef,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { DataTableDirective } from 'angular-datatables';
import { ToastrService } from 'ngx-toastr';
import { MmOrderService } from 'projects/order/src/app/order-proxy/order-management/order-optimization/mm-order.service';
import { InsuranceCategoryService } from 'projects/patient/src/app/patient-proxy/patient-optimization/insurance-category.service';
import { defaultGuid } from 'projects/shared/src/app/enums/allenums.enum';
import { Subject, Subscription } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  map,
  startWith,
} from 'rxjs/operators';
import { DatePipe } from '@angular/common';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-allowables-calculation',
  templateUrl: './allowables-calculation.component.html',
  styleUrls: ['./allowables-calculation.component.scss'],
})
export class AllowablesCalculationComponent implements OnInit {
  allowablesForm: FormGroup;
  filteredChartIds: any;
  ltInsuranceCategories: any;
  filteredInsuranceCategory: any;
  orderInfoForm: FormGroup;
  lstChartNos: any[] = [];
  insuranceControl = new FormControl();
  filteredInsurance: any;
  searchControl = new FormControl();
  loadTable: boolean = true;
  subscription$: Subscription[] = [];
  totalAccessorySum: number = 0;
  totalAllowableSum: number = 0;
  totalPointSum: number = 0;
  allowablesData: any;
  lstSerialNo: any;
  tempArray: any = [];
  tempArrayQty: any = [];
  dtTrigger: Subject<any> = new Subject<any>();
  @ViewChildren(DataTableDirective)
  dtElements: QueryList<DataTableDirective>;
  @ViewChild('itemTable') itemTable: ElementRef;
  itemCount: number = 0;
  itemsList: any[] = [];
  dtItemTableOptions: any = {
    responsive: true,
    paging: false,
    scrollX: true,
    language: {
      emptyTable: 'No records',
      info: 'Total : _MAX_ records',
      infoEmpty: 'Total : _MAX_ records',
      infoFiltered: '(filtered : _TOTAL_ records)',
      loadingRecords: 'Loading...',
      zeroRecords: 'No matching records',
    },
    searching: false,
    order: [[0, 'asc']],
    columnDefs: [{ targets: [0], visible: false }],
  };
  filteredOptions: any;
  ltproducts: any;
  lstMasks: any;
  drpInsCategory: any[];
  filterInsCategory: any;
  product: string;
  productId: any;
  productDetails: any;
  tenantId: string;
  actualCostSum: string = '0';
  allowableCostSum: string = '0';
  accessoryCostSum: string = '0';
  pointsSum: string = '0';
  patient: string = '';
  insurance: string = '';
  patientChartApiSubscription: any;
  constructor(
    private formBuilder: FormBuilder,
    private mmOrderService: MmOrderService,
    private datepipe: DatePipe,
    private insuranceCategoryService: InsuranceCategoryService,
    private title: Title,
    private toastr: ToastrService
  ) {
    this.searchControl.valueChanges
      .pipe(debounceTime(300), distinctUntilChanged())
      .subscribe(() => {
        this.filterProducts();
      });
  }

  ngOnInit(): void {
    this.tenantId = localStorage.getItem('tenantId') ?? '';
    this.title.setTitle('QSecure | Allowables Lookup');
    this.allowablesForm = this.formBuilder.group({
      drpPatient: new FormControl(''),
      txtPatientFilter: new FormControl(''),
      drpInsuranceCategory: new FormControl(''),
      txtInsuranceCategory: new FormControl(''),
    });
    this.searchPatient('', '');
    this.loadProducts();
    this.loadDropDown();
  }
  //Method To Search the Products in Dropdown
  filterProducts(): void {
    const searchValue = this.searchControl.value.toLowerCase();
    this.filteredOptions = this.ltproducts.filter((option) =>
      option.productName.toLowerCase().includes(searchValue.toLowerCase())
    );
  }

  loadDropDown() {
    // const getCategory = this.insuranceCategoryService
    //   .getAllInsuranceCategoryListV1()
    //   .subscribe(
    //     (response) => {
    //       this.drpInsCategory = [];
    //       this.drpInsCategory = response;
    //       // response?.forEach((element) => {
    //       //   this.drpProducts.push({
    //       //     productId: element?.id,
    //       //     productName: element?.mainProductName,
    //       //     // name: element?.name,
    //       //     hcpcCode: element?.hcpcCode,
    //       //   });
    //       // });

    //       this.filterInsCategory = this.allowablesForm
    //         ?.get('filterCategory')
    //         ?.valueChanges.pipe(
    //           startWith(''),
    //           map((value) =>
    //             this.drpInsCategory.filter((option) =>
    //               option?.insuranceCategory
    //                 ?.toLowerCase()
    //                 ?.includes(value?.toLowerCase() ?? '')
    //             )
    //           )
    //         );
    //     },
    //     (err) => {
    //       const data: HttpErrorResponse = err;
    //       // Swal.fire({
    //       //   icon: 'error',
    //       //   text: data?.error?.error?.message,
    //       // });
    //     }
    //   );
    // this.subscription$.push(getCategory);
    // this.loadChartNos();
    this.loadInsuranceCategoryDropdown();
  }
  searchPatient(value: any, patientId: string) {
    this.loadChartNo(value, patientId);
  }

    //To Load the Chart No of the Patients
    loadChartNo(searchParameter: string, patientId: string) {
      if (this.patientChartApiSubscription) {
        this.patientChartApiSubscription.unsubscribe();
      }
      this.patientChartApiSubscription = this.mmOrderService
        .getPatientChartNoV3BySSearchParamsAndPatient(searchParameter, patientId)
        .subscribe(
          (response) => {
            this.lstChartNos = response;
            return this.lstChartNos;
          },
          (err) => {}
        );

      // this.filteredChartIds = this.orderForm
      //   .get('txtChartFilter')
      //   .valueChanges.pipe(
      //     startWith(''),
      //     map((value) => [{ patientId: 'test', idAndNameAndDOB: 'sdfsdf' }])
      //   );
      // Old Code
      // const chartNos = this.mmOrderService.getPatientChartNoV1().subscribe(
      //   (response) => {
      //     this.lstChartNos = response;
      //     this.lstChartNos = response.map((x) => {
      //       let y: { patientId: string; idAndNameAndDOB: string } = {
      //         patientId: x?.patientId,
      //         idAndNameAndDOB:
      //           x?.chartId +
      //           ' - ' +
      //           x?.patientName +
      //           ' - ' +
      //           this.datepipe.transform(new Date(x?.dob), 'MM/dd/yyyy'),
      //         ...x,
      //       };
      //       return y;
      //     });
      //     this.filteredChartIds = this.orderForm
      //       .get('txtChartFilter')
      //       .valueChanges.pipe(
      //         startWith(''),
      //         map((value) =>
      //           this.lstChartNos?.filter((option) =>
      //             option?.idAndNameAndDOB
      //               ?.toLowerCase()
      //               ?.includes(value?.toLowerCase() ?? '')
      //           )
      //         )
      //       );
      //   },
      //   (err) => {}
      // );
      // this.subscription$.push(chartNos);
    }

  loadChartNos() {
    const chartNos = this.mmOrderService.getPatientChartNoV1().subscribe(
      (response) => {
        this.lstChartNos = response;
        this.lstChartNos = response.map((x) => {
          let y: { patientId: string; idAndNameAndDOB: string } = {
            patientId: x?.patientId,
            idAndNameAndDOB:
              x?.chartId +
              ' - ' +
              x?.patientName +
              ' - ' +
              this.datepipe.transform(new Date(x?.dob), 'MM/dd/yyyy'),
            ...x,
          };
          return y;
        });

        this.filteredChartIds = this.allowablesForm
          .get('txtPatientFilter')
          .valueChanges.pipe(
            startWith(''),
            map((value) =>
              this.lstChartNos?.filter((option) =>
                option?.idAndNameAndDOB
                  ?.toLowerCase()
                  ?.includes(value?.toLowerCase() ?? '')
              )
            )
          );
      },
      (err) => {}
    );
    this.subscription$.push(chartNos);
  }

  //After View In it function
  ngAfterViewInit(): void {
    this.itemsList.push(this.addEmptyObject());
    this.dtTrigger.next();
  }
  //To add the Row in the Table
  addProductRow() {
    if (
      this.itemsList.filter((a) => a.mmProductId === defaultGuid).length === 0
    ) {
      this.itemsList.push(this.addEmptyObject());
      this.reloadDatatable();
    }
  }
  reloadDatatable() {
    this.dtElements.forEach((dtElement: DataTableDirective, index: number) => {
      if (index === 0) {
        dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
          dtInstance.columns.adjust().draw();
          // Destroy the table first
          dtInstance.destroy();
          // Call the dtTrigger to rerender again
          this.dtTrigger.next();
        });
      }
    });
  }
  ngOnDestroy(): void {
    // Unsubscribe from the DT events when the component is destroyed
    this.dtTrigger.unsubscribe();
    this.subscription$.forEach((subscription) => {
      subscription.unsubscribe();
    });
  }

  //Method to set the height of the table based on the rows
  setItemTableHeight(): void {
    // Set scrollY dynamically based on the content height
    const tableHeight = this.itemTable.nativeElement.offsetHeight;
    const maxHeight = 300; // Set your maximum height here

    if (tableHeight > maxHeight) {
      this.dtItemTableOptions.scrollY = `${maxHeight}px`;
    } else {
      this.dtItemTableOptions.scrollY = null; // Reset to default if not exceeding the limit
    }
  }
  //To add the empty row in the table
  addEmptyObject(): any {
    this.itemCount++;
    let value: any = {
      sortNumber: this.itemCount,
      mmProductId: defaultGuid,
      productCode: '',
      productDescription: '',
      sizeName: '',
      unitCost: '',
      unitAccessory: '',
      qty: 0,
      discountPercent: 0,
      unitAllowable: 0,
      allowableQuantity: 0,
      patientOrderCount: 0,
      allowableDays: 0,
      actualCost: 0,
      allowable: 0,
      accessory: 0,
      points_Cal: 0,
      primaryInsuranceId: defaultGuid,
      insuranceId: defaultGuid,
      patientId: defaultGuid,
      mmCategoryReferenceId: 0,
      itemCost: 0,
    };

    return value;
  }

  //Load Products
  loadProducts() {
    const productsList = this.mmOrderService.getProductDetails().subscribe(
      (response) => {
        this.ltproducts = response;
        this.filteredOptions = [...this.ltproducts];
      },
      (err) => {}
    );
    this.subscription$.push(productsList);
  }
  //To Load the Insurance Category Dropdown
  loadInsuranceCategoryDropdown() {
    const insuranceCategories = this.insuranceCategoryService
      .getAllInsuranceCategoryListV1()
      .subscribe(
        (response) => {
          this.ltInsuranceCategories = response;
          this.filteredInsuranceCategory = this.allowablesForm
            .get('txtInsuranceCategory')
            .valueChanges.pipe(
              startWith(''),
              map((value) =>
                this.ltInsuranceCategories?.filter((option) =>
                  option?.insuranceCategory
                    ?.toLowerCase()
                    ?.includes(value?.toLowerCase() ?? '')
                )
              )
            );
        },
        (err) => {}
      );
    this.subscription$.push(insuranceCategories);
  }
  //Numbers only validation
  onKeyPress(event: KeyboardEvent) {
    const isNumber = /[0-9]/.test(event.key);
    if (!isNumber) {
      event.preventDefault(); // Prevent the input if the key is not a number
    }
  }
  //To remove the product from the table
  removeProduct(productId: string) {
    if (productId != defaultGuid) {
      this.itemsList = this.itemsList.filter((a) => a.mmProductId != productId);

      this.calculateSum();
      this.setItemTableHeight();
      this.reloadDatatable();
      this.toastr.success('Product Removed');
    } else {
      this.toastr.error('Cannot Remove Empty Row');
    }
  }
  //To Load the Product Details on Product Selected
  loadProductDetails(data: any, productId: string) {
    data.mmProductId = productId;
    const patientId = this.allowablesForm.value.drpPatient;
    const insuranceId = this.allowablesForm.value.drpInsuranceCategory;

    if (
      !(this.itemsList.filter((a) => a.mmProductId === productId).length > 1)
    ) {
      const productDetails = this.mmOrderService
        .loadProductDetailsForLookupByGProductIdAndGPatientIdAndGInsuranceGroupId(
          productId,
          patientId,
          insuranceId
        )
        .subscribe(
          (response) => {
            data.productDescription = response?.productDescription ?? '';
            data.sizeName = response?.sizeName ?? '';
            data.unitCost = response?.unitCost ?? '';
            data.unitAccessory = response?.unitAccessory ?? '';
            data.qty = response?.qty ?? '';
            data.discountPercent = response?.discountPercent ?? '';
            data.unitAllowable = response?.unitAllowable ?? '';
            data.allowableQuantity = response?.allowableQuantity ?? '';
            data.patientOrderCount = response?.patientOrderCount ?? '';
            data.allowableDays = response?.allowableDays ?? '';
            data.actualCost = response?.actualCost ?? '';
            data.allowable = response?.allowable ?? '';
            data.accessory = response?.accessory ?? '';
            data.points_Cal = response?.points_Cal ?? '';
            data.actualCost = (response?.qty * response?.unitCost).toFixed(2);

            this.itemsList.filter((a) => a.mmProductId === defaultGuid)
              .length === 0 && this.itemsList.push(this.addEmptyObject());
            this.calculateSum();
            this.setItemTableHeight();
            this.reloadDatatable();
          },
          (err) => {
            this.reloadDatatable();
          }
        );
      this.subscription$.push(productDetails);
    } else {
      data.mmProductId = '';
      this.toastr.warning('Product Already Added!');
    }
  }
  //On Quantity and Discount Field Change Method
  onQtyandDiscountChange(data: any) {
    data.patientId =
      this.allowablesForm.value.drpPatient === null ||
      this.allowablesForm.value.drpPatient === ''
        ? defaultGuid
        : this.allowablesForm.value.drpPatient;
    data.primaryInsuranceId =
      this.allowablesForm.value.drpInsuranceCategory === null ||
      this.allowablesForm.value.drpInsuranceCategory === ''
        ? defaultGuid
        : this.allowablesForm.value.drpInsuranceCategory;
    data.insuranceId =
      this.allowablesForm.value.drpInsuranceCategory === null ||
      this.allowablesForm.value.drpInsuranceCategory === ''
        ? defaultGuid
        : this.allowablesForm.value.drpInsuranceCategory;
    (data.mmCategoryReferenceId = 0), (data.itemCost = 0);
    data.actualCost = (data?.qty * data?.unitCost).toFixed(2);
    this.mmOrderService
      .updateAccessoryExchangeDetailsLookupByItemData(data)
      .subscribe(
        (response) => {
          data.productDescription = response?.productDescription ?? '';
          data.sizeName = response?.sizeName ?? '';
          data.unitCost = response?.unitCost ?? '';
          data.unitAccessory = response?.unitAccessory ?? '';
          data.qty = response?.qty ?? '';
          data.discountPercent = response?.discountPercent ?? '';
          data.unitAllowable = response?.unitAllowable ?? '';
          data.allowableQuantity = response?.allowableQuantity ?? '';
          data.patientOrderCount = response?.patientOrderCount ?? '';
          data.allowableDays = response?.allowableDays ?? '';
          data.actualCost = response?.actualCost ?? '';
          data.allowable = response?.allowable ?? '';
          data.accessory = response?.accessory ?? '';
          data.points_Cal = response?.points_Cal ?? '';
          data.actualCost = (response?.qty * response?.unitCost).toFixed(2);
          this.reloadDatatable();
          this.calculateSum();
        },
        (err) => {
        }
      );
  }
  //To calculate the Sum of the amounts
  calculateSum() {
    this.actualCostSum = this.itemsList
      .filter((a) => a.mmProductId != '')
      .reduce((total, item) => total + Number(item.actualCost), 0)
      ?.toFixed(2)
      ?.toString();
    this.allowableCostSum = this.itemsList
      .filter((a) => a.mmProductId != '')
      .reduce((total, item) => total + Number(item.allowable), 0)
      ?.toFixed(2)
      ?.toString();
    this.accessoryCostSum = this.itemsList
      .filter((a) => a.mmProductId != '')
      .reduce((total, item) => total + Number(item.accessory), 0)
      ?.toFixed(2)
      ?.toString();
    this.pointsSum = this.itemsList
      .filter((a) => a.mmProductId != '')
      .reduce((total, item) => total + Number(item.points_Cal), 0)
      ?.toFixed(2)
      ?.toString();
  }
  //To clear the Patient DD
  clearPatient() {
    this.patient = '';
    this.allowablesForm?.patchValue({ drpPatient: '' });
  }
  //To clear the Insurance and refresh the table
  clearInsurance() {
    this.insurance = '';
    this.allowablesForm?.patchValue({ drpInsuranceCategory: '' });
    this.itemsList = [];
    this.itemsList.push(this.addEmptyObject());
    this.calculateSum();
    this.reloadDatatable();
  }
}
