import { DatePipe } from '@angular/common';
import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Title } from '@angular/platform-browser';
import { ResupplyCallNotesComponent } from '../../resupply-call-notes/resupply-call-notes.component';
import { HttpErrorResponse } from '@angular/common/http';
import { Subscription } from 'rxjs';
import {
  MatSlideToggle,
  MatSlideToggleChange,
} from '@angular/material/slide-toggle';
import { NoteType } from '../../order-proxy/order-management/inventory/dto';
import { DataTableDirective } from 'angular-datatables';
import { VerificationStatusV2 } from 'projects/patient/src/app/patient-proxy/patient/enumeration-data/verification-status.enum';
import { MedicareStatus } from 'projects/patient/src/app/patient-proxy/patient/enumeration-data/medicare-status.enum';
import { ViewPatientLedgerComponent } from 'projects/admin/src/app/view-patient-ledger/view-patient-ledger.component';
import Swal from 'sweetalert2';
import { PatientCommonNotesComponent } from 'projects/shared/src/app/components/patient-common-notes/patient-common-notes.component';
import { AddNoteTabComponent } from 'projects/patient/src/app/add-note-tab/add-note-tab.component';
import { defaultGuid } from 'projects/shared/src/app/enums/allenums.enum';
import { MatSnackBar } from '@angular/material/snack-bar';
import { PatientManualVerificationTabComponent } from 'projects/patient/src/app/patient-manual-verification-tab/patient-manual-verification-tab.component';
import { ResupplyInactiveNotesComponent } from '../../resupply-inactive-notes/resupply-inactive-notes.component';
@Component({
  selector: 'app-archive-supplies',
  templateUrl: './archive-supplies.component.html',
  styleUrls: ['./archive-supplies.component.scss'],
})
export class ArchiveSuppliesComponent implements OnInit {
  isLoading: boolean;
  @Input() tableData: any[];
  @Output() getPatientDetails: EventEmitter<string> =
    new EventEmitter<string>();
  noteType: NoteType;
  @ViewChild(DataTableDirective, { static: false })
  datatableElement: DataTableDirective;
  subscription$: Subscription[] = [];
  $subscription: Subscription[] = [];
  patientId: string;
  chartId: string;
  headingTxt: string;
  noteTypes: NoteType;
  selectedData: any;
  isRoleName: string;
  verifyId: any;
  verifyStatus: any;
  policyId: any;
  lstVerificationSttausV2 = VerificationStatusV2;
  MedicareStatusV1 = MedicareStatus;
  archiveSuppliesData: any[] = [];

  dtArchiveOptions: any = {
    responsive: true,
    stateSave: true,
    paging: false,
    dom: 'Bfrtip',
    language: {
      emptyTable: 'No records',
      info: 'Total : _MAX_ records',
      infoEmpty: 'Total : _MAX_ records',
      infoFiltered: '(filtered : _TOTAL_ records)',
      loadingRecords: 'Loading...',
      zeroRecords: 'No matching records',
    },
    scrollCollapse: true,
    scrollY: '300px',
    scrollX: true,
    fixedHeader: true,
    fixedColumns: {
      leftColumns: 3, // Number of columns to fix on the left
      rightColumns: 1, // Number of columns to fix on the right
    },
    columnDefs: [
      { targets: [0], orderable: false },
      { targets: [0, 1, 2, -1], className: 'dt-fixed-column' }, // Add class to make the first three and last column fixed
      {
        targets: [5, 7],
        type: 'date',
        render: function (data, type, row) {
          if (type === 'sort' || type === 'type') {
            var dateParts = data.split('/');
            return new Date(dateParts[2], dateParts[0] - 1, dateParts[1]);
          } else {
            return data;
          }
        },
      },
    ],
    search: {
      smart: false, // Set to "none" for full exact match
    },
    buttons: [
      {
        extend: 'excel',
        text: 'Export',
        filename: 'Qsecure | Archive Files List',
        exportOptions: {
          columns: ':visible:not(:first-child) ',
        },
      },
      {
        extend: 'colvis',
        columns: ':not(:eq(0), :eq(1), :eq(2), :eq(-1))', // Exclude first three and last columns from the column visibility toggle
      },
    ],
  };

  constructor(
    public title: Title,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private datepipe: DatePipe
  ) {}

  ngOnInit(): void {
    this.dtArchiveOptions.buttons[0].filename =
      'Archive Files List' +
      '-' +
      this.datepipe.transform(new Date(), 'MM-dd-yyyy');
    // this.archiveSuppliesData = this.tableData;
    this.archiveSuppliesData = this.tableData;
    // class ArchiveSuppliesData {
    //   id:string;
    //   chartId: string;
    //   patientname: string;
    //   cellNo: string;
    //   dos: string;
    //   nextCallDate: string;
    //   nextOrderDate: string;
    //   allowableAmount: number;
    //   agingDays: number;
    //   archiveDate: string;
    //   isActive:boolean;

    //   constructor(
    //     id:string,
    //     chartId: string,
    //     patientname: string,
    //     cellNo: string,
    //     dos: string,
    //     nextCallDate: string,
    //     nextOrderDate: string,
    //     allowableAmount: number,
    //     agingDays: number,
    //     archiveDate: string,
    //     isActive:boolean
    //   ) {
    //     this.id = id;
    //     this.chartId = chartId;
    //     this.patientname = patientname;
    //     this.cellNo = cellNo;
    //     this.dos = dos;
    //     this.nextCallDate = nextCallDate;
    //     this.nextOrderDate = nextOrderDate;
    //     this.allowableAmount = allowableAmount;
    //     this.agingDays = agingDays;
    //     this.archiveDate = archiveDate;
    //     this.isActive = isActive;
    //   }
    // }

    // this.archiveSuppliesData = [
    //   new ArchiveSuppliesData('1','12345', 'John Doe', '123-456-7890', '01/01/2022', '02/01/2022', '03/01/2022', 100.00, 30, '04/01/2022',false),
    //   new ArchiveSuppliesData('2','67890', 'Jane Smith', '098-765-4321', '02/01/2022', '03/01/2022', '04/01/2022', 200.00, 60, '05/01/2022',false),
    // ];
  }

  isEmpty(value: any): boolean {
    return (
      value === null ||
      value === undefined ||
      value === '' ||
      value === defaultGuid
    );
  }

  isEmptyStatus(value: any): boolean {
    return value === null || value === undefined || value === '';
  }

  ngAfterViewInit(): void {
    if (this.datatableElement && this.datatableElement.dtInstance) {
      this.refreshDataTable();
    } else {
      setTimeout(() => this.refreshDataTable(), 500);
    }
  }
  refreshDataTable(): void {
    this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
      // Clear search input values
      $('input', dtInstance.table(0).footer()).val('');
      // Redraw the DataTable to load all records
      dtInstance.search('').columns().search('').draw();
      // Attach the event listener for filtering
      dtInstance.columns().every(function () {
        const that = this;
        $('input', this.footer()).on('keyup change', function () {
          if (that.search() !== this['value']) {
            that.search(this['value']).draw();
          }
        });
      });
    });
  }
  ngOnDestroy(): void {
    // Unsubscribe to avoid memory leaks
    this.subscription$?.forEach((sub) => {
      sub && sub?.unsubscribe();
    });

    window.removeEventListener('resize', this.calculateScrollY);
  }

  calculateScrollY = () => {
    const screenHeight =
      window.innerHeight ||
      document.documentElement.clientHeight ||
      document.body.clientHeight;
    const availableSpacePercentage = 0.4; // Adjust the percentage as needed
    this.dtArchiveOptions.scrollY = screenHeight * availableSpacePercentage;
  };

  tblItemSelectedRow(data: any): void {
    this.selectedData = data;
    if (this.patientId !== data?.patientId) {
      this.patientId = data?.patientId;
      this.chartId = data?.chartId;
      this.getPatientDetails.emit(data?.patientId);
    }
  }

  onRowDoubleClick(textToCopy) {
    this.copyTextToClipboard(textToCopy);
    this.showNotification('Text copied: ' + textToCopy);
  }

  showNotification(message: string): void {
    this.snackBar.open(message, 'Close', {
      duration: 3000, // Duration in milliseconds
      horizontalPosition: 'end', // Positioning the notification horizontally
      verticalPosition: 'bottom', // Positioning the notification vertically
    });
  }

  copyTextToClipboard(text: string): void {
    const textArea = document.createElement('textarea');
    textArea.value = text;
    document.body.appendChild(textArea);
    textArea.select();
    document.execCommand('Copy');
    textArea.remove();
  }

  openAddNotesPopUp() {
    const config: MatDialogConfig = {
      disableClose: true,
      minHeight: '30vh',
      minWidth: '50vw',
      data: {
        noteType: 'patient', //'Order Note',
        notesId: '00000000-0000-0000-0000-000000000000',
        patientId: this.patientId,
        defaultFaxId: this.chartId,
        isCallNote: false,
        isPending: false,
        // docId: this.documentId,
        inboundStatusShortCodeId: defaultGuid,
        selectedNode: defaultGuid,
        addnotes: true,
      },
    };
    const dialogRef = this.dialog.open(AddNoteTabComponent, config);

    const closeDialog = dialogRef.afterClosed().subscribe(
      () => {},
      (err) => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      }
    );
    this.subscription$.push(closeDialog);
  }

  openViewNotesPopup() {
    const config: MatDialogConfig = {
      disableClose: true,
      width: '70vw',
      maxWidth: '70vw',
      // height: '40vw',
      // maxHeight: '40vw',
      data: {
        patientId: this.patientId,
        pageType: 'Patient Note', // this.pageType, //Notes Table Popup Page
        isAddVisible: false,
      },
    };
    const dialogRef = this.dialog.open(PatientCommonNotesComponent, config);

    dialogRef.afterClosed().subscribe(
      () => {},
      (err: HttpErrorResponse) => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      }
    );
  }

  viewPatientLedger(patientId: string) {
    this.dialog.open(ViewPatientLedgerComponent, {
      width: '100vw',
      height: '100vh',
      maxWidth: '100vw',
      maxHeight: '100vh',
      panelClass: 'fullscreen-dialog-panel',
      disableClose: true,
      data: {
        patientId: patientId,
      },
    });
    this.dialog.afterAllClosed.subscribe((resposne) => {});
  }

  // Active Toggle
  statusChange(event: MatSlideToggleChange) {
    if (event.checked == true) {
      this.openresupplyCallNotes();
    }
  }

  openresupplyCallNotes() {
    const config: MatDialogConfig = {
      disableClose: true,
      minHeight: '30vh',
      minWidth: '50vw',
      data: {},
    };
    const dialogRef = this.dialog.open(ResupplyCallNotesComponent, config);
    const closeDialog = dialogRef.afterClosed().subscribe(
      () => {},
      (err) => {
        const data: HttpErrorResponse = err;
        console.warn(data?.error?.error?.message);
      }
    );
    this.$subscription.push(closeDialog);
  }

  openLastVerification(element) {
    if (element == 'Primary') {
      const dialogRef = this.dialog.open(
        PatientManualVerificationTabComponent,
        {
          disableClose: true,
          data: {
            patientId: this.selectedData.patientId,
            verificationId: this.selectedData.primaryVerification,
            // policyId: this.priPolicyId,
            savehidden: true,
            isViewVerification: true,
            documentId: this.selectedData.documentId,
            // defaultFaxId:data?.defaultFaxId
          },
        }
      );
      const closeDialog = dialogRef.afterClosed().subscribe((result) => {
        if (result == 'Saved') {
          this.dialog.closeAll();
        }

        result !== '' &&
          result !== null &&
          result !== undefined &&
          this.dialog.closeAll();
      });
      this.subscription$.push(closeDialog);
    } else if (element == 'Secondary') {
      const dialogRef = this.dialog.open(
        PatientManualVerificationTabComponent,
        {
          disableClose: true,
          data: {
            patientId: this.selectedData.patientId,
            // policyId: this.secPolicyId,
            verificationId: this.selectedData.secVerification,
            savehidden: true,
            isViewVerification: true,
            documentId: this.selectedData.documentId,
            // defaultFaxId:data?.defaultFaxId
          },
        }
      );
      const closeDialog = dialogRef.afterClosed().subscribe((result) => {
        if (result == 'Saved') {
          this.dialog.closeAll();
        }

        result !== '' &&
          result !== null &&
          result !== undefined &&
          this.dialog.closeAll();
      });
      this.subscription$.push(closeDialog);
    }
  }
  supplyInactive(optionNo: number, chartId: string) {
    if (optionNo == 1) {
      this.headingTxt = 'Inactive Current Month';
      this.noteTypes = NoteType.currentMonthInactive;
    } else if (optionNo == 2) {
      this.headingTxt = 'Inactive Entire Month';
      this.noteTypes = NoteType.entireMonthInactive;
    } else if (optionNo == 3) {
      this.headingTxt = 'Move To Archive';
      this.noteTypes = NoteType.archive;
    } else if (optionNo == 4) {
      this.headingTxt = 'Active Patient';
      this.noteTypes = NoteType.activePatient;
    }

    const config: MatDialogConfig = {
      disableClose: true,
      minHeight: '33vh',
      minWidth: '52vw',
      data: {
        isNextCallDateshow:true,
        headingTxt: this.headingTxt,
        noteType: this.noteTypes,
        chartId: chartId,
        patientId: this.selectedData.patientId,
        orderId: this.selectedData.orderId,
        currentMonth: optionNo === 1 ? true : false,
        isCurrentSuplies: true,
        isMovetoArchive:optionNo === 3? true : false,
        isActivePatient:optionNo ===4? true : false,
      },
    };

    const dialogRef = this.dialog.open(ResupplyInactiveNotesComponent, config);

    const closeDialog = dialogRef.afterClosed().subscribe(
      () => {},
      (err) => {}
    );
    this.$subscription.push(closeDialog);
  }
}
