<table datatable [dtOptions]="dtPatientListOptions" class="tbl hover row-border hover w-100 display">
    <thead>
        <tr>
            <th [matTooltip]="'Order No'">Order No</th>
            <th [matTooltip]="'Chart No'">Chart No</th>
            <th [matTooltip]="'Patient Name'">Patient Name</th>
            <th [matTooltip]="'Supplies Date'">Supplies Date</th>
            <th [matTooltip]="'Insurance Group'">Insurance Group</th>
            <th [matTooltip]="'Primary Insurance Name'">Primary Insurance Name</th>
            <th [matTooltip]="'Order Status Desc'">Order Status Desc</th>
            <th [matTooltip]="'Last Mask Date'">Last Mask Date</th>
            <th [matTooltip]="'Points'">Points</th>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let data of workSheetData">
            <td [matTooltip]="data.orderNo">{{ data.orderNo }}</td>
            <td [matTooltip]="data.chartNo">{{ data.chartNo }}</td>
            <td [matTooltip]="data.patientName">{{ data.patientName }}</td>
            <td [matTooltip]="data.suppliesDate">{{ data.suppliesDate }}</td>
            <td [matTooltip]="data.insuranceGroup">{{ data.insuranceGroup }}</td>
            <td [matTooltip]="data.primaryInsuranceName">{{ data.primaryInsuranceName }}</td>
            <td [matTooltip]="data.orderStatusDesc">{{ data.orderStatusDesc }}</td>
            <td [matTooltip]="data.lastMaskDate">{{ data.lastMaskDate }}</td>
            <td [matTooltip]="data.points">{{ data.points }}</td>
        </tr>
    </tbody>
    <tfoot>
        <tr>
            <th>
                <input matInput class="textbox-border-class" type="text" placeholder="Order No" name="search-OrderNo" />
            </th>
            <th>
                <input matInput class="textbox-border-class" type="text" placeholder="Chart No" name="search-ChartNo" />
            </th>
            <th>
                <input matInput type="text" class="textbox-border-class" placeholder="Patient Name"
                    name="search-PatientName" />
            </th>
            <th>
                <input matInput class="textbox-border-class" type="text" placeholder="Supplies Date" name="search-SuppliesDate" />
            </th>
            <th>
                <input matInput class="textbox-border-class" type="text" placeholder="Insurance Group"
                    name="search-InsuranceGroup" />
            </th>
            <th class="customWidth">
                <input matInput class="textbox-border-class" type="text" placeholder="Primary Insurance Name"
                    name="search-PrimaryInsuranceName" />
            </th>
            <th>
                <input matInput type="text" class="textbox-border-class" placeholder="Order Status Desc"
                    name="search-OrderStatusDesc" />
            </th>
            <th>
                <input matInput type="text" class="textbox-border-class" placeholder="Last Mask Date"
                    name="search-LastMaskDate" />
            </th>
            <th>
                <input matInput type="text" class="textbox-border-class" placeholder="Points"
                    name="search-Points" />
            </th>
        </tr>
    </tfoot>
</table>