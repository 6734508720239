<!-- <div [hidden]="!isLoading"
  style="display: flex; justify-content: center; align-items: center; background: transparent; color: lightseagreen;">
  <mat-progress-bar mode="indeterminate" value='66' diameter="45" class="lightseagreen">
  </mat-progress-bar>
</div> -->

<table
  id="{{ strPageType }}"
  mat-table
  matSort
  class="table table-bordered"
  [dataSource]="dataSource"
>
  <div [ngSwitch]="strPageType">
    <div *ngSwitchCase="'orders'">
      <ng-container matColumnDef="orderId">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Order ID</th>
        <td mat-cell *matCellDef="let data">
          {{ data?.orderId }}
        </td>
      </ng-container>
      <ng-container matColumnDef="ticketId">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Ticket Id</th>
        <td mat-cell *matCellDef="let datas">
          {{ datas.ticketId }}
        </td>
      </ng-container>
      <ng-container matColumnDef="totalPrice">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Total Price</th>
        <td mat-cell *matCellDef="let datas">
          {{ "$ " + datas.totalPrice }}
        </td>
      </ng-container>

      <ng-container matColumnDef="itemNames">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Item</th>
        <td mat-cell *matCellDef="let data">
          <ng-container>
            {{ data?.itemNames }}
          </ng-container>
        </td>
      </ng-container>
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
        <td mat-cell *matCellDef="let data">
          {{ data?.name }}
        </td>
      </ng-container>
      <ng-container matColumnDef="qty">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Total Units</th>
        <td mat-cell *matCellDef="let data">
          {{ data?.qty }}
        </td>
      </ng-container>
      <ng-container matColumnDef="RP">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Total Price</th>
        <td mat-cell *matCellDef="let data">
          {{ data?.totalItemPrice }}
        </td>
      </ng-container>
      <ng-container matColumnDef="scheduleDate">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          Scheduled Date
        </th>
        <td mat-cell *matCellDef="let data">{{ data?.scheduleDate }}</td>
      </ng-container>
      <ng-container matColumnDef="originalDos">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>DOS</th>
        <td mat-cell *matCellDef="let data">
          {{ data?.originalDos }}
        </td>
      </ng-container>

      <ng-container matColumnDef="createdDate">
        <th class="header" mat-header-cell mat-sort-header *matHeaderCellDef>
          Created Date
        </th>
        <td mat-cell *matCellDef="let element">{{ element?.createdDate }}</td>
      </ng-container>

      <ng-container matColumnDef="Options">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Options</th>
        <td mat-cell *matCellDef="let data">
          <a
            *ngIf="data.orderProcessStatus !== 'Voided order'"
            (click)="ViewButtonHitted(data)"
            class="eyeCursorclass"
            class="pr-3 pb-2"
            target="_blank"
            ><i
              matTooltip="Click To View Order"
              class="fa fa-pencil pencilColor"
            ></i>
          </a>
          <a
            *ngIf="data.orderProcessStatus !== 'Voided order'"
            (click)="deleteButtonClikced(data?.id)"
            class="eyeCursorclass"
            matTooltip="Click to Set as Void Order"
            class="pb-2"
            target="_blank"
            ><i class="fa fa-trash deleteBtnColor"></i>
          </a>
          <button
            (click)="ViewButtonHitted(data)"
            *ngIf="data.orderProcessStatus === 'Voided order'"
            mat-button
            class="buttonColor font-size-14"
          >
            {{ data.orderProcessStatus }}
          </button>
          <!-- <a [hidden]="data?.billingStatus === 'Processed'||
              data?.status === 'Ready To Shipping Approval' ||
              data?.status === 'Ready To Approve' ||
              data?.status === 'Shipping Approved' ||
              data?.status === 'Ready To Pack' ||
              data?.status === 'Ready To Delivery' ||
              data?.status === 'Collected From Office' ||
              data?.status === 'Returned' " (click)="ViewButtonHitted(data)" class="eyeCursorclass" target="_blank"><i
                  class="fa fa-pencil mr-5 pencilColor"></i> </a> -->
        </td>
      </ng-container>

      <ng-container matColumnDef="noRecords">
        <td
          class="text-align-center"
          mat-footer-cell
          *matFooterCellDef
          colspan="9"
        >
          <div *ngIf="!isLoading">
            {{ "No Records Found" }}
          </div>
          <div [hidden]="!isLoading">
            <mat-spinner [diameter]="220"></mat-spinner>
          </div>
        </td>
      </ng-container>
      <ng-container matColumnDef="paginator">
        <td mat-footer-cell *matFooterCellDef colspan="9">
          <mat-paginator
            class="table table-responsive mat-table-class"
            #MatPaginator
            [length]="dataSource?.data?.length"
            [pageSize]="10"
            [pageIndex]="0"
            showFirstLastButtons
            [pageSizeOptions]="[5, 10, 25, 100]"
          >
          </mat-paginator>
        </td>
      </ng-container>
    </div>
    <div *ngSwitchCase="'item'">
      <ng-container matColumnDef="totalPrice">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Total Price</th>
        <td mat-cell *matCellDef="let item">{{ "$ " + item?.totalPrice }}</td>
      </ng-container>
      <ng-container matColumnDef="totalItemQty">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Total Units</th>
        <td mat-cell *matCellDef="let item">{{ item?.totalItemQty }}</td>
      </ng-container>
      <ng-container matColumnDef="DOS">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>DOS</th>
        <td mat-cell mat-cell *matCellDef="let item">
          {{ item?.originalDos }}
        </td>
      </ng-container>
      <ng-container matColumnDef="itemId">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Item</th>
        <td mat-cell mat-cell *matCellDef="let item">{{ item?.name }}</td>
      </ng-container>
      <ng-container matColumnDef="Quantity">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Units</th>
        <td mat-cell mat-cell *matCellDef="let item">{{ item?.qty }}</td>
      </ng-container>
      <!-- <ng-container matColumnDef="UOM">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>UOM</th>
        <td mat-cell mat-cell *matCellDef="let item">{{ item?.UOM }}</td>
      </ng-container> -->
      <ng-container matColumnDef="billQty">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Bill Units</th>
        <td mat-cell *matCellDef="let item">{{ item?.bQty }}</td>
      </ng-container>
      <ng-container matColumnDef="ProcCode">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>ProcCode</th>
        <td mat-cell *matCellDef="let item">{{ item?.priceCode }}</td>
      </ng-container>
      <ng-container matColumnDef="Modifiers">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Modifiers</th>
        <td mat-cell mat-cell *matCellDef="let item">{{ item?.modifiers1 }}</td>
      </ng-container>
      <ng-container matColumnDef="additional">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          Additional Modifiers
        </th>
        <td mat-cell mat-cell *matCellDef="let item">
          {{ item?.additionalModifiers1 }}
        </td>
      </ng-container>
      <ng-container matColumnDef="Coverage">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Coverage</th>
        <td mat-cell mat-cell *matCellDef="let item">
          {{ item?.coverageType }}
        </td>
      </ng-container>
      <ng-container matColumnDef="Allow">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Allow</th>
        <td mat-cell mat-cell *matCellDef="let item">{{ item?.allow }}</td>
      </ng-container>
      <!-- <ng-container matColumnDef="nextDos">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Next DOS</th>
        <td mat-cell mat-cell *matCellDef="let item">{{ item?.nextDOS }}</td>
      </ng-container> -->
      <ng-container matColumnDef="Options">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Options</th>
        <td mat-cell *matCellDef="let item">
          <a
            (click)="ViewButtonHitted(item)"
            class="eyeCursorclass"
            target="_blank"
            ><i class="fa fa-pencil mr-3 pencilColor"></i>
          </a>
          <a class="deleteBtnColor">
            <i (click)="deleteButtonClikced(item)" class="fa fa-trash"></i>
          </a>
        </td>
      </ng-container>
      <ng-container matColumnDef="nextDos">
        <td
          class="text-align-center"
          mat-footer-cell
          *matFooterCellDef
          colspan="10"
        >
          <b>totadfgdfgdfglBilled</b>
        </td>
      </ng-container>

      <ng-container matColumnDef="noRecords">
        <td
          class="text-align-center"
          mat-footer-cell
          *matFooterCellDef
          colspan="9"
        >
          <div *ngIf="!isLoading">
            {{ "No Records Found" }}
          </div>
          <div [hidden]="!isLoading">
            <mat-spinner [diameter]="220"></mat-spinner>
          </div>
        </td>
      </ng-container>
      <ng-container matColumnDef="paginator">
        <td mat-footer-cell *matFooterCellDef colspan="11">
          <mat-paginator
            class="table table-responsive mat-table-class"
            #MatPaginator
            [length]="dataSource?.data?.length"
            [pageSize]="10"
            [pageIndex]="0"
            showFirstLastButtons
            [pageSizeOptions]="[5, 10, 25, 100]"
          >
          </mat-paginator>
        </td>
      </ng-container>
    </div>
    <div *ngSwitchCase="'order'">
      <ng-container matColumnDef="Carrier">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Carrier</th>
        <td mat-cell mat-cell *matCellDef="let order">{{ order.carrierId }}</td>
      </ng-container>
      <ng-container matColumnDef="Tracking">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Tracking No</th>
        <td mat-cell mat-cell *matCellDef="let order">
          {{ order.trackingNo }}
        </td>
      </ng-container>

      <ng-container matColumnDef="Date">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Date</th>
        <td mat-cell *matCellDef="let order">{{ order.scheduledDate }}</td>
      </ng-container>
      <ng-container matColumnDef="noRecords">
        <td
          class="text-align-center"
          mat-footer-cell
          *matFooterCellDef
          colspan="4"
        >
          No Records Found
        </td>
      </ng-container>
      <ng-container matColumnDef="paginator">
        <td mat-footer-cell *matFooterCellDef colspan="4">
          <mat-paginator
            class="table table-responsive mat-table-class"
            #MatPaginator
            [length]="dataSource?.data?.length"
            [pageSize]="10"
            [pageIndex]="0"
            showFirstLastButtons
            [pageSizeOptions]="[5, 10, 25, 100]"
          >
          </mat-paginator>
        </td>
      </ng-container>
    </div>
    <div *ngSwitchCase="'ordersHistory'">
      <ng-container matColumnDef="orderId">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>OrderID</th>
        <td mat-cell *matCellDef="let datas">
          {{ datas.orderId }}
        </td>
      </ng-container>
      <ng-container matColumnDef="ticketId">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Ticket Id</th>
        <td mat-cell *matCellDef="let datas">
          {{ datas.ticketId }}
        </td>
      </ng-container>

      <ng-container matColumnDef="totalItemPrice">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Total Price</th>
        <td mat-cell *matCellDef="let datas">
          {{ "$ " + datas.totalItemPrice }}
        </td>
      </ng-container>
      <ng-container matColumnDef="itemNames">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Item</th>
        <td mat-cell *matCellDef="let datas">
          <ng-container>
            {{ datas.itemNames }}
          </ng-container>
        </td>
      </ng-container>
      <ng-container matColumnDef="Name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
        <td mat-cell *matCellDef="let datas">
          {{ datas.name }}
        </td>
      </ng-container>
      <ng-container matColumnDef="qty">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Total Units</th>
        <td mat-cell *matCellDef="let datas">
          {{ datas.qty }}
        </td>
      </ng-container>

      <ng-container matColumnDef="RP">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>R/P</th>
        <td mat-cell *matCellDef="let datas">
          {{ datas.rp }}
        </td>
      </ng-container>
      <ng-container matColumnDef="scheduleDate">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          Scheduled Date
        </th>
        <td mat-cell *matCellDef="let datas">{{ datas.scheduleDate }}</td>
      </ng-container>
      <ng-container matColumnDef="originalDos">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>DOS</th>
        <td mat-cell *matCellDef="let datas">
          {{ datas.originalDos }}
        </td>
      </ng-container>
      <ng-container matColumnDef="createdDate">
        <th class="header" mat-header-cell *matHeaderCellDef mat-sort-header>
          Created Date
        </th>
        <td mat-cell *matCellDef="let datas">{{ datas?.createdDate }}</td>
      </ng-container>

      <ng-container matColumnDef="Options">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Options</th>
        <td mat-cell *matCellDef="let datas">
          <!-- <a [hidden]="datas.isActive ||
          datas?.status === 'Ready To Shipping Approval' ||
          datas?.status === 'Ready To Approve' ||
          datas?.status === 'Shipping Approved' ||
          datas?.status === 'Ready To Pack' ||
          datas?.status === 'Ready To Delivery' ||
          datas?.status === 'Collected From Office' ||
          datas?.status === 'Returned' " class="eyeCursorclass pencilColor" target="_blank"><i
              class="fa fa-pencil mr-5"></i>
          </a> -->
        </td>
      </ng-container>
      <ng-container matColumnDef="noRecords">
        <td
          class="text-align-center"
          mat-footer-cell
          *matFooterCellDef
          colspan="8"
        >
          <div *ngIf="!isLoading">
            {{ "No Records Found" }}
          </div>
          <div [hidden]="!isLoading">
            <mat-spinner [diameter]="220"></mat-spinner>
          </div>
        </td>
      </ng-container>
      <ng-container matColumnDef="paginator">
        <td mat-footer-cell *matFooterCellDef colspan="8">
          <mat-paginator
            class="table table-responsive mat-table-class"
            #MatPaginator
            [length]="dataSource?.data?.length"
            [pageSize]="10"
            [pageIndex]="0"
            showFirstLastButtons
            [pageSizeOptions]="[5, 10, 25, 100]"
          >
          </mat-paginator>
        </td>
      </ng-container>
    </div>
    <div *ngSwitchCase="'verification'">
      <ng-container matColumnDef="Plan">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Plan</th>
        <td mat-cell mat-cell *matCellDef="let order">{{ order.plan }}</td>
      </ng-container>

      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Plan Name</th>
        <td mat-cell *matCellDef="let order">{{ order.planName }}</td>
      </ng-container>
      <ng-container matColumnDef="Type">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Type</th>
        <td mat-cell *matCellDef="let order">{{ order.typeOfPlan }}</td>
      </ng-container>
      <ng-container matColumnDef="Status">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Status</th>
        <td mat-cell *matCellDef="let order">{{ order.verificationStatus }}</td>
      </ng-container>
      <ng-container matColumnDef="Options">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Options</th>
        <td mat-cell *matCellDef="let order">
          <a
            (click)="ViewButtonHitted(order)"
            class="eyeCursorclass"
            target="_blank"
            ><i class="fa fa-pencil mr-5 pencilColor"></i>
          </a>
        </td>
      </ng-container>

      <ng-container matColumnDef="noRecords">
        <td
          class="text-align-center"
          mat-footer-cell
          *matFooterCellDef
          colspan="5"
        >
          <div *ngIf="!isLoading">
            {{ "No Records Found" }}
          </div>
          <div [hidden]="!isLoading">
            <mat-spinner [diameter]="220"></mat-spinner>
          </div>
        </td>
      </ng-container>
      <ng-container matColumnDef="paginator">
        <td mat-footer-cell *matFooterCellDef colspan="5">
          <mat-paginator
            class="table table-responsive mat-table-class"
            #MatPaginator
            [length]="dataSource?.data?.length"
            [pageSize]="10"
            [pageIndex]="0"
            showFirstLastButtons
            [pageSizeOptions]="[5, 10, 25, 100]"
          >
          </mat-paginator>
        </td>
      </ng-container>
    </div>
    <div *ngSwitchCase="'InventoryItem'">
      <ng-container matColumnDef="sno">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>S.No</th>
        <td mat-cell *matCellDef="let element; let i = index">{{ i + 1 }}</td>
      </ng-container>
      <ng-container matColumnDef="itemId">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Item Id</th>
        <td mat-cell *matCellDef="let datas">
          <ng-container>
            {{ datas.equipmentBillingCode }}
          </ng-container>
        </td>
      </ng-container>
      <ng-container matColumnDef="itemName">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Item Name</th>
        <td mat-cell *matCellDef="let datas">
          {{ datas.itemName }}
        </td>
      </ng-container>
      <ng-container matColumnDef="itemPrice">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Price</th>
        <td mat-cell *matCellDef="let datas">
          {{ datas.price }}
        </td>
      </ng-container>

      <ng-container matColumnDef="Options">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Options</th>
        <td mat-cell *matCellDef="let datas">
          <a
            *ngIf="isShownUpdateIconForItem"
            (click)="ViewButtonHitted(datas)"
            class="eyeCursorclass"
            target="_blank"
            ><i class="fa fa-pencil mr-3"></i>
          </a>
          &nbsp;
          <a class="deleteBtnColor">
            <i
              *ngIf="isShownDeleteIconForItem"
              (click)="deleteButtonClikced(datas)"
              class="fa fa-trash"
            ></i>
          </a>
        </td>
      </ng-container>
      <ng-container matColumnDef="noRecords">
        <td
          class="text-align-center"
          mat-footer-cell
          *matFooterCellDef
          colspan="5"
        >
          No Records Found
        </td>
      </ng-container>
      <ng-container matColumnDef="paginator">
        <td mat-footer-cell *matFooterCellDef colspan="5">
          <mat-paginator
            class="table table-responsive mat-table-class"
            #MatPaginator
            [length]="dataSource?.data?.length"
            [pageSize]="10"
            [pageIndex]="0"
            showFirstLastButtons
            [pageSizeOptions]="[5, 10, 25, 100]"
          >
          </mat-paginator>
        </td>
      </ng-container>
    </div>

    <div *ngSwitchCase="'clientList'">
      <ng-container matColumnDef="sno">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>S.No</th>
        <td mat-cell *matCellDef="let datas; let i = index">
          {{ i + 1 }}
        </td>
      </ng-container>
      <ng-container matColumnDef="ClientCode">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Code</th>
        <td mat-cell *matCellDef="let datas">
          <ng-container>
            {{ datas.code }}
          </ng-container>
        </td>
      </ng-container>
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
        <td mat-cell *matCellDef="let datas">
          {{ datas.name }}
        </td>
      </ng-container>
      <ng-container matColumnDef="mail">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Email</th>
        <td mat-cell *matCellDef="let datas">
          {{ datas.mail }}
        </td>
      </ng-container>
      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Status</th>
        <td mat-cell *matCellDef="let datas">
          {{ datas.status }}
        </td>
      </ng-container>
      <ng-container matColumnDef="date">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Create Date</th>
        <td mat-cell *matCellDef="let datas">
          {{ datas.date }}
        </td>
      </ng-container>

      <ng-container matColumnDef="Options">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Options</th>
        <td mat-cell *matCellDef="let datas">
          <a
            (click)="ViewButtonHitted(datas)"
            class="eyeCursorclass"
            target="_blank"
            ><i class="fa fa-pencil mr-5"></i>
          </a>
          &nbsp;
        </td>
      </ng-container>
      <ng-container matColumnDef="noRecords">
        <td
          class="text-align-center"
          mat-footer-cell
          *matFooterCellDef
          colspan="7"
        >
          No Records Found
        </td>
      </ng-container>
      <ng-container matColumnDef="paginator">
        <td mat-footer-cell *matFooterCellDef colspan="7">
          <mat-paginator
            class="table table-responsive mat-table-class"
            #MatPaginator
            [length]="dataSource?.data?.length"
            [pageSize]="10"
            [pageIndex]="0"
            showFirstLastButtons
            [pageSizeOptions]="[5, 10, 25, 100]"
          >
          </mat-paginator>
        </td>
      </ng-container>
    </div>
    <div *ngSwitchCase="'branchDetals'">
      <ng-container matColumnDef="sno">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>S.No</th>
        <td mat-cell *matCellDef="let datas; let i = index">
          {{ i + 1 }}
        </td>
      </ng-container>
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Branch Name</th>
        <td mat-cell *matCellDef="let datas">
          <ng-container>
            {{ datas.branchName }}
          </ng-container>
        </td>
      </ng-container>
      <ng-container matColumnDef="address">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          Branch Address
        </th>
        <td mat-cell *matCellDef="let datas">
          {{ datas.address }}
        </td>
      </ng-container>
      <ng-container matColumnDef="mail">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Email</th>
        <td mat-cell *matCellDef="let datas">
          {{ datas.email }}
        </td>
      </ng-container>
      <ng-container matColumnDef="Contact">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Contact</th>
        <td mat-cell *matCellDef="let datas">
          {{ datas.contact }}
        </td>
      </ng-container>

      <ng-container matColumnDef="Options">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Options</th>
        <td mat-cell *matCellDef="let datas">
          <a
            (click)="ViewButtonHitted(datas)"
            class="eyeCursorclass"
            target="_blank"
            ><i class="fa fa-pencil editBtnColor mr-3"></i>
          </a>
          &nbsp;
          <a class="deleteBtnColor">
            <i (click)="deleteButtonClikced(datas)" class="fa fa-trash"></i>
          </a>
        </td>
      </ng-container>
      <ng-container matColumnDef="noRecords">
        <td
          class="text-align-center"
          mat-footer-cell
          *matFooterCellDef
          colspan="6"
        >
          <div *ngIf="!isLoading">
            {{ "No Records Found" }}
          </div>
          <div [hidden]="!isLoading">
            <mat-spinner [diameter]="220"></mat-spinner>
          </div>
        </td>
      </ng-container>
      <ng-container matColumnDef="paginator">
        <td mat-footer-cell *matFooterCellDef colspan="6">
          <mat-paginator
            class="table table-responsive mat-table-class"
            #MatPaginator
            [length]="dataSource?.data?.length"
            [pageSize]="10"
            [pageIndex]="0"
            showFirstLastButtons
            [pageSizeOptions]="[5, 10, 25, 100]"
          >
          </mat-paginator>
        </td>
      </ng-container>
    </div>

    <div *ngSwitchCase="'Roles'">
      <ng-container matColumnDef="sno">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>S.No</th>
        <td mat-cell *matCellDef="let datas; let i = index">
          {{ i + 1 }}
        </td>
      </ng-container>
      <ng-container matColumnDef="roleName">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Role Name</th>
        <td mat-cell *matCellDef="let datas">
          <ng-container>
            {{ datas.name == 'admin' ? 'Admin' : datas.name }}
            <mat-chip
              [disabled]="true"
              *ngIf="datas?.isStatic == true && datas?.name == 'admin'"
              selected
              >Static</mat-chip
            >
            <mat-chip
              [disabled]="true"
              *ngIf="datas?.isDefault == true && datas?.name == 'admin'"
              selected
              >Default</mat-chip
            >
          </ng-container>
        </td>
      </ng-container>
      <ng-container matColumnDef="wip">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>WIP</th>
        <td mat-cell *matCellDef="let datas">
          <ng-container>
            Yes
          </ng-container>
        </td>
      </ng-container>
      <ng-container matColumnDef="Options">
        <th
          class="verticalAlignColumn text-center"
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
        >
          Options
        </th>
        <td class="verticalAlignColumn" mat-cell *matCellDef="let datas">
          <ng-container
            *ngIf="
              datas?.name !== 'Admin' &&
              datas?.name !== 'Warehouse Manager' &&
              datas?.name !== 'Warehouse Executive' &&
              datas?.name !== 'Scheduler' &&
              datas?.name !== 'Respiratory Therapist'
            "
          >
            <button
              *ngIf="isShownclientPermission"
              mat-button
              class="buttonColor mb-1 mr-3 text-right"
              matTooltip="Click To Assign Permission for Role"
              (click)="ViewPermissionsClikced(datas)"
            >
              Permission Access
            </button>
          </ng-container>
          <ng-container
            *ngIf="
              datas?.name !== 'admin' &&
              datas?.name !== 'Warehouse Manager' &&
              datas?.name !== 'Warehouse Executive' &&
              datas?.name !== 'Scheduler' &&
              datas?.name !== 'Respiratory Therapist' &&
              datas?.name !== 'CSR' &&
              datas?.name !== 'New Setup' &&
              datas?.name !== 'Verification' &&
              datas?.name !== 'Authorization'
            "
          >
            <a (click)="ViewButtonHitted(datas)"
              ><i
                class="fa fa-pencil pointer editBtnColor mr-3"
                matTooltip="Click To View Role"
              ></i>
            </a>
          </ng-container>
          <ng-container
            *ngIf="
              datas?.name !== 'admin' &&
              datas?.name !== 'Warehouse Manager' &&
              datas?.name !== 'Warehouse Executive' &&
              datas?.name !== 'Scheduler' &&
              datas?.name !== 'Respiratory Therapist' &&
              datas?.name !== 'CSR' &&
              datas?.name !== 'New Setup' &&
              datas?.name !== 'Verification' &&
              datas?.name !== 'Authorization'
            "
          >
            <a *ngIf="isShownDeleteIcon">
              <i
                (click)="deleteRolesClikced(datas)"
                class="deleteBtnColor fa fa-trash pointer mr-3"
                matTooltip="Click To Delete Role"
              ></i>
            </a>
          </ng-container>
          <!-- <ng-container *ngIf='datas?.name!=="admin" && (datas?.name!=="Warehouse Manager" || datas?.name!=="Scheduler" || datas?.name!=="Respiratory Therapist")'>
            <a *ngIf='isShownUpdateIcon ' (click)="ViewButtonHitted(datas)"><i
                class="fa fa-pencil pointer editBtnColor mr-3" matTooltip="Click To View Role"></i> </a>
          </ng-container>

          <ng-container *ngIf='datas?.name!=="admin" && (datas?.name!=="Warehouse Manager" || datas?.name!=="Scheduler" || datas?.name!=="Respiratory Therapist")'>
            <a *ngIf='isShownDeleteIcon '>
              <i (click)="deleteRolesClikced(datas)" class="deleteBtnColor fa fa-trash pointer mr-3"
                matTooltip="Click To Delete Role" ></i>
            </a>
          </ng-container> -->



          <!-- <a  class="deleteBtnColor">
             <button mat-button class="buttonColor mb-2 mr-2" (click)="ViewPermissionsClikced(datas)"
            >Permission Access</button> -->
          <!--<i (click)="ViewPermissionsClikced(datas)" class="fa fa-lock" ></i>
          </a> -->
        </td>
      </ng-container>
      <ng-container matColumnDef="noRecords">
        <td
          class="text-align-center"
          mat-footer-cell
          *matFooterCellDef
          colspan="3"
        >
          <div *ngIf="!isLoading">
            {{ "No Records Found" }}
          </div>
          <div [hidden]="!isLoading">
            <mat-spinner [diameter]="220"></mat-spinner>
          </div>
        </td>
      </ng-container>
      <ng-container matColumnDef="paginator">
        <td mat-footer-cell *matFooterCellDef colspan="3">
          <mat-paginator
            class="table table-responsive mat-table-class"
            #MatPaginator
            [length]="dataSource?.data?.length"
            [pageSize]="10"
            [pageIndex]="0"
            showFirstLastButtons
            [pageSizeOptions]="[5, 10, 25, 100]"
          >
          </mat-paginator>
        </td>
      </ng-container>
    </div>

    <div *ngSwitchCase="'officialList'">
      <ng-container matColumnDef="sno">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>S.No</th>
        <td mat-cell *matCellDef="let datas; let i = index">
          {{ i + 1 }}
        </td>
      </ng-container>
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Company Name</th>
        <td mat-cell *matCellDef="let datas">
          <ng-container>
            {{ datas.equipmentBillingCode }}
          </ng-container>
        </td>
      </ng-container>
      <ng-container matColumnDef="email">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Email</th>
        <td mat-cell *matCellDef="let datas">
          {{ datas.itemName }}
        </td>
      </ng-container>
      <ng-container matColumnDef="ceo">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>CEO</th>
        <td mat-cell *matCellDef="let datas">
          {{ datas.price }}
        </td>
      </ng-container>
      <ng-container matColumnDef="telephone">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Telephone No</th>
        <td mat-cell *matCellDef="let datas">
          {{ datas.price }}
        </td>
      </ng-container>

      <ng-container matColumnDef="Options">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Options</th>
        <td mat-cell *matCellDef="let datas">
          <a
            (click)="ViewButtonHitted(datas)"
            class="eyeCursorclass"
            target="_blank"
            ><i class="fa fa-pencil mr-5"></i>
          </a>
          &nbsp;
          <a class="deleteBtnColor">
            <i (click)="deleteButtonClikced(datas)" class="fa fa-trash"></i>
          </a>
        </td>
      </ng-container>
      <ng-container matColumnDef="noRecords">
        <td
          class="text-align-center"
          mat-footer-cell
          *matFooterCellDef
          colspan="6"
        >
          No Records Found
        </td>
      </ng-container>
      <ng-container matColumnDef="paginator">
        <td mat-footer-cell *matFooterCellDef colspan="6">
          <mat-paginator
            class="table table-responsive mat-table-class"
            #MatPaginator
            [length]="dataSource?.data?.length"
            [pageSize]="10"
            [pageIndex]="0"
            showFirstLastButtons
            [pageSizeOptions]="[5, 10, 25, 100]"
          >
          </mat-paginator>
        </td>
      </ng-container>
    </div>
    <div *ngSwitchCase="'userList'">
      <ng-container matColumnDef="sno">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>S.No</th>
        <td mat-cell *matCellDef="let datas; let i = index">
          {{ i + 1 }}
        </td>
      </ng-container>
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>User Name</th>
        <td mat-cell *matCellDef="let datas">
          <ng-container>
            {{ datas.name }}
          </ng-container>
        </td>
      </ng-container>
      <ng-container matColumnDef="email">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Email</th>
        <td mat-cell *matCellDef="let datas">
          {{ datas.email }}
        </td>
      </ng-container>
      <ng-container matColumnDef="branch">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Branch</th>
        <td mat-cell *matCellDef="let datas">
          {{ datas.branch }}
        </td>
      </ng-container>
      <ng-container matColumnDef="role">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Role</th>
        <td mat-cell *matCellDef="let datas">
          {{ datas.role }}
        </td>
      </ng-container>

      <ng-container matColumnDef="Options">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Options</th>
        <td mat-cell *matCellDef="let datas">
          <a
            (click)="ViewButtonHitted(datas)"
            *ngIf="isShownUpdateIconForUser"
            class="eyeCursorclass"
            target="_blank"
            ><i class="fa fa-pencil editBtnColor mr-3"></i>
          </a>
          &nbsp;
          <a class="deleteBtnColor">
            <i
              (click)="deleteButtonClikced(datas)"
              *ngIf="isShownDeleteIconForUser"
              class="fa fa-trash"
            ></i>
          </a>
        </td>
      </ng-container>
      <ng-container matColumnDef="noRecords">
        <td
          class="text-align-center"
          mat-footer-cell
          *matFooterCellDef
          colspan="6"
        >
          <div *ngIf="!isLoading">
            {{ "No Records Found" }}
          </div>
          <div [hidden]="!isLoading">
            <mat-spinner [diameter]="220"></mat-spinner>
          </div>
        </td>
      </ng-container>
      <ng-container matColumnDef="paginator">
        <td mat-footer-cell *matFooterCellDef colspan="6">
          <mat-paginator
            class="table table-responsive mat-table-class"
            #MatPaginator
            [length]="dataSource?.data?.length"
            [pageSize]="10"
            [pageIndex]="0"
            showFirstLastButtons
            [pageSizeOptions]="[5, 10, 25, 100]"
          >
          </mat-paginator>
        </td>
      </ng-container>
    </div>
    <div *ngSwitchCase="'executiveUserList'">
      <ng-container matColumnDef="sno">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>S.No</th>
        <td mat-cell *matCellDef="let datas; let i = index">
          {{ i + 1 }}
        </td>
      </ng-container>
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>User Name</th>
        <td mat-cell *matCellDef="let datas">
          <ng-container>
            {{ datas.name }}
          </ng-container>
        </td>
      </ng-container>
      <ng-container matColumnDef="email">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Email</th>
        <td mat-cell *matCellDef="let datas">
          {{ datas.email }}
        </td>
      </ng-container>
      <ng-container matColumnDef="role">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Role</th>
        <td mat-cell *matCellDef="let datas">
          {{ datas.role }}
        </td>
      </ng-container>

      <ng-container matColumnDef="Options">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Options</th>
        <td mat-cell *matCellDef="let datas">
          <a
            (click)="ViewButtonHitted(datas)"
            class="eyeCursorclass"
            target="_blank"
            ><i class="fa fa-pencil mr-3"></i>
          </a>
          &nbsp;
          <a class="deleteBtnColor">
            <i (click)="deleteButtonClikced(datas)" class="fa fa-trash"></i>
          </a>
        </td>
      </ng-container>
      <ng-container matColumnDef="noRecords">
        <td
          class="text-align-center"
          mat-footer-cell
          *matFooterCellDef
          colspan="6"
        >
          No Records Found
        </td>
      </ng-container>
      <ng-container matColumnDef="paginator">
        <td mat-footer-cell *matFooterCellDef colspan="6">
          <mat-paginator
            class="table table-responsive mat-table-class"
            #MatPaginator
            [length]="dataSource?.data?.length"
            [pageSize]="10"
            [pageIndex]="0"
            showFirstLastButtons
            [pageSizeOptions]="[5, 10, 25, 100]"
          >
          </mat-paginator>
        </td>
      </ng-container>
    </div>
    <div *ngSwitchCase="'ExecutiveRoles'">
      <ng-container matColumnDef="sno">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>S.No</th>
        <td mat-cell *matCellDef="let datas; let i = index">
          {{ i + 1 }}
        </td>
      </ng-container>
      <ng-container matColumnDef="roleName">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Role Name</th>
        <td mat-cell *matCellDef="let datas">
          <ng-container>
            {{ datas.name }}
          </ng-container>
        </td>
      </ng-container>
      <ng-container matColumnDef="Options">
        <th
          class="verticalAlignColumn text-center"
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
        >
          Options
        </th>
        <td
          class="verticalAlignColumn text-center"
          mat-cell
          *matCellDef="let datas"
        >
        <button
            mat-button
            class="buttonColor mb-1 mr-1 text-right"
            matTooltip="Click To Assign Permission for Role"
            (click)="ViewExecutiveRolesPermissionsClikced(datas)"
          >
            Permission Access
          </button>
          <a *ngIf="isShownUpdateIcon" (click)="ViewButtonHitted(datas)"
            ><i
              class="fa fa-pencil pointer editBtnColor mr-3"
              matTooltip="Click To View Role"
            ></i>
          </a>
          <a *ngIf="isShownDeleteIcon">
            <i
              (click)="deleteExecutiveRolesClikced(datas)"
              class="deleteBtnColor fa fa-trash pointer mr-3"
              matTooltip="Click To Delete Role"
            ></i>
          </a>

        </td>
      </ng-container>
      <ng-container matColumnDef="noRecords">
        <td
          class="text-align-center"
          mat-footer-cell
          *matFooterCellDef
          colspan="3"
        >
          {{ "No Records Found" }}
        </td>
      </ng-container>
      <ng-container matColumnDef="paginator">
        <td mat-footer-cell *matFooterCellDef colspan="3">
          <mat-paginator
            class="table table-responsive mat-table-class"
            #MatPaginator
            [length]="dataSource?.data?.length"
            [pageSize]="10"
            [pageIndex]="0"
            showFirstLastButtons
            [pageSizeOptions]="[5, 10, 25, 100]"
          >
          </mat-paginator>
        </td>
      </ng-container>
    </div>

    <div *ngSwitchCase="'patientOrderStatus'">
      <form>
        <ng-container matColumnDef="SNo">
          <th class="header" mat-header-cell *matHeaderCellDef mat-sort-header>
            S.No
          </th>
          <td mat-cell *matCellDef="let record; let i = index">{{ i + 1 }}</td>
        </ng-container>
        <ng-container matColumnDef="OrderId">
          <th class="header" mat-header-cell *matHeaderCellDef mat-sort-header>
            Order Id
          </th>
          <td mat-cell *matCellDef="let record">{{ record.orderId }}</td>
        </ng-container>
        <ng-container matColumnDef="Verification">
          <th class="header" mat-header-cell *matHeaderCellDef mat-sort-header>
            Verification
          </th>
          <td mat-cell *matCellDef="let record">
            <ng-container *ngIf="record.insuranceVerifiesStatus === 'Done'">
              <button mat-button class="btn btn-sm green-dot">
                <span> {{ record.insuranceVerifiesStatus }}</span>
              </button>
            </ng-container>

            <ng-container
              *ngIf="record.insuranceVerifiesStatus === 'UnableToVerify'"
            >
              <button mat-button class="btn btn-sm yellow-dot">
                <span> {{ record.insuranceVerifiesStatus }}</span>
              </button>
            </ng-container>

            <ng-container *ngIf="record.insuranceVerifiesStatus === 'Pending'">
              <button mat-button class="btn btn-sm grey-dot">
                <span> {{ record.insuranceVerifiesStatus }}</span>
              </button>
            </ng-container>
            <ng-container
              *ngIf="
                record?.insuranceVerifiesStatus === '' ||
                record?.insuranceVerifiesStatus === null ||
                record.insuranceVerifiesStatus === undefined
              "
            >
              <button mat-button class="btn btn-sm grey-dot">
                <span> {{ "Yet To Start" }}</span>
              </button>
            </ng-container>
          </td>
        </ng-container>
        <ng-container matColumnDef="Authorization">
          <th class="header" mat-header-cell *matHeaderCellDef mat-sort-header>
            Authorization
          </th>
          <td mat-cell *matCellDef="let record">
            <ng-container *ngIf="record.authorizationStatus === 'Done'">
              <button mat-button class="btn btn-sm green-dot">
                <span> {{ record.authorizationStatus }}</span>
              </button>
            </ng-container>

            <ng-container *ngIf="record.authorizationStatus === 'Pending'">
              <button mat-button class="btn btn-sm yellow-dot">
                <span> {{ record.authorizationStatus }}</span>
              </button>
            </ng-container>

            <ng-container *ngIf="record.authorizationStatus === 'Not Needed'">
              <span Class="dot green-dot"> {{ "Not Required" }}</span>
            </ng-container>
          </td>
        </ng-container>
        <ng-container matColumnDef="OrderStatus">
          <th class="header" mat-header-cell *matHeaderCellDef mat-sort-header>
            Order Status
          </th>
          <td mat-cell *matCellDef="let record">
            <ng-container *ngIf="record.orderStatus">
              <button mat-button class="btn btn-sm green-dot">
                <span> {{ record.orderStatus ? "Completed" : "Pending" }}</span>
              </button>
            </ng-container>

            <ng-container *ngIf="!record.orderStatus">
              <button mat-button class="btn btn-sm yellow-dot">
                <span> {{ record.orderStatus ? "Completed" : "Pending" }}</span>
              </button>
            </ng-container>

            <!--
              <ng-container *ngIf="record.orderStatus; else orderStatusElseTemplate">
              {{ record.orderStatus?"Completed":"Pending" }}
              <span [ngClass]='{"dot green-dot":record.orderStatus}'></span>
              </ng-container>
              <ng-template #orderStatusElseTemplate>
              {{ record.orderStatus?"Completed":"Pending" }}
              <span [ngClass]='{"dot yellow-dot":!record.orderStatus}'></span>
              </ng-template> -->
          </td>
        </ng-container>

        <ng-container matColumnDef="CreatedDate">
          <th class="header" mat-header-cell *matHeaderCellDef>Created Date</th>
          <td mat-cell *matCellDef="let element">{{ element?.createdDate }}</td>
        </ng-container>

        <ng-container matColumnDef="Action">
          <th class="header" mat-header-cell *matHeaderCellDef mat-sort-header>
            Action
          </th>
          <td mat-cell *matCellDef="let record">
            <a
              [hidden]="record?.billingStatus === 'Processed'"
              class="editBtnColor eyeCursorclass"
              target="_blank"
              (click)="ViewButtonHitted(record.id)"
            >
              <i class="fa fa-eye"></i>
            </a>
            <!-- <a [hidden]="record?.billingStatus === 'Processed'" class="editBtnColor eyeCursorclass" target="_blank"
            (click)="ViewButtonHitted(record.id)">
            <i class="fa fa-eye" ></i>
          </a> -->
          </td>
        </ng-container>

        <ng-container matColumnDef="noRecords">
          <td
            class="text-align-center"
            mat-footer-cell
            *matFooterCellDef
            colspan="7"
          >
            <div *ngIf="!isLoading">
              {{ "No Records Found" }}
            </div>
            <div [hidden]="!isLoading">
              <mat-spinner [diameter]="220"></mat-spinner>
            </div>
          </td>
        </ng-container>
        <ng-container matColumnDef="paginator">
          <td mat-footer-cell *matFooterCellDef colspan="7">
            <mat-paginator
              class="table table-responsive mat-table-class"
              #MatPaginator
              [length]="dataSource?.data?.length"
              [pageSize]="10"
              [pageIndex]="0"
              showFirstLastButtons
              [pageSizeOptions]="[5, 10, 25, 100]"
            >
            </mat-paginator>
          </td>
        </ng-container>
      </form>
    </div>

    <div *ngSwitchCase="'note'">
      <ng-container matColumnDef="action">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Options</th>
        <td mat-cell *matCellDef="let record">
          <div class="form-group ml-2">
            <a
              (click)="ViewButtonHitted(record)"
              class="eyeCursorclass"
              target="_blank"
            >
              <i class="fa fa-pencil mr-3"></i
            ></a>
            <a class="deleteBtnColor">
              <i (click)="deleteButtonClikced(record)" class="fa fa-trash"></i>
            </a>
          </div>
        </td>
      </ng-container>
      <ng-container matColumnDef="defaultNotesId">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Note Id</th>
        <td mat-cell *matCellDef="let record">
          {{ record.defaultNotesId }}
        </td>
      </ng-container>
      <ng-container matColumnDef="createdBy">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Created By</th>
        <td mat-cell *matCellDef="let record">
          {{ record.createdBy }}
        </td>
      </ng-container>
      <ng-container matColumnDef="dateCreated">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Date Created</th>
        <td mat-cell *matCellDef="let record">
          {{ record.dateCreated }}
        </td>
      </ng-container>
      <ng-container matColumnDef="actualDate">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Actual Date</th>
        <td mat-cell *matCellDef="let record">
          {{ record.actualDate }}
        </td>
      </ng-container>
      <ng-container matColumnDef="noteType">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Note Type</th>
        <td mat-cell *matCellDef="let record">
          {{ record.noteType }}
        </td>
      </ng-container>
      <ng-container matColumnDef="reason">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Reason</th>
        <td mat-cell *matCellDef="let record">
          {{ record.noteReason }}
        </td>
      </ng-container>
      <ng-container matColumnDef="dateNeeded">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Date Needed</th>
        <td mat-cell *matCellDef="let record">
          {{ record.dateNeeded }}
        </td>
      </ng-container>
      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Status</th>
        <td mat-cell *matCellDef="let record">
          {{ record.status }}
        </td>
      </ng-container>
      <ng-container matColumnDef="noRecords">
        <td
          class="text-align-center"
          mat-footer-cell
          *matFooterCellDef
          colspan="8"
        >
          No Records Found
        </td>
      </ng-container>
      <ng-container matColumnDef="paginator">
        <td mat-footer-cell *matFooterCellDef colspan="8">
          <mat-paginator
            class="table table-responsive mat-table-class"
            #MatPaginator
            [length]="dataSource?.data?.length"
            [pageSize]="10"
            [pageIndex]="0"
            showFirstLastButtons
            [pageSizeOptions]="[5, 10, 25, 100]"
          >
          </mat-paginator>
        </td>
      </ng-container>
    </div>

    <div *ngSwitchCase="'Provider'">
      <ng-container matColumnDef="npi">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Npi</th>
        <td mat-cell *matCellDef="let record">
          {{ record.npi }}
        </td>
      </ng-container>
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
        <td mat-cell *matCellDef="let record">
          {{ record.name }}
        </td>
      </ng-container>
      <ng-container matColumnDef="phone">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Phone</th>
        <td mat-cell *matCellDef="let record">
          {{ record.phone }}
        </td>
      </ng-container>
      <ng-container matColumnDef="code">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>code</th>
        <td mat-cell *matCellDef="let record">
          {{ record.code }}
        </td>
      </ng-container>

      <ng-container matColumnDef="address">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Address</th>
        <td mat-cell *matCellDef="let record">
          {{ record.address }}
        </td>
      </ng-container>
      <ng-container matColumnDef="payorId">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Payor Id</th>
        <td mat-cell *matCellDef="let record">
          {{ record.payorId }}
        </td>
      </ng-container>
      <ng-container matColumnDef="action">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Options</th>
        <td mat-cell *matCellDef="let record">
          <div class="form-group ml-2">
            <a
              (click)="ViewButtonHitted(record.id)"
              class="eyeCursorclass"
              target="_blank"
            >
              <i class="fa fa-pencil mr-3"></i
            ></a>
            <a class="deleteBtnColor">
              <i
                (click)="deleteButtonClikced(record.id)"
                class="fa fa-trash"
              ></i>
            </a>
          </div>
        </td>
      </ng-container>
      <ng-container matColumnDef="noRecords">
        <td
          class="text-align-center"
          mat-footer-cell
          *matFooterCellDef
          colspan="7"
        >
          No Records Found
        </td>
      </ng-container>
      <ng-container matColumnDef="paginator">
        <td mat-footer-cell *matFooterCellDef colspan="7">
          <mat-paginator
            class="table table-responsive mat-table-class"
            #MatPaginator
            [length]="dataSource?.data?.length"
            [pageSize]="10"
            [pageIndex]="0"
            showFirstLastButtons
            [pageSizeOptions]="[5, 10, 25, 100]"
          >
          </mat-paginator>
        </td>
      </ng-container>
    </div>
    <div *ngSwitchCase="'transactionCodesList'">
      <form [formGroup]="transactionForm">
        <ng-container matColumnDef="sno">
          <th class="header" mat-header-cell *matHeaderCellDef mat-sort-header>
            S.No
          </th>
          <td mat-cell *matCellDef="let record; let i = index">{{ i + 1 }}</td>
        </ng-container>

        <ng-container matColumnDef="Code">
          <th mat-header-cell *matHeaderCellDef>
            <mat-form-field class="filter">
              <input autocomplete="new-Code" matInput formControlName="Code" />
              <mat-placeholder>Code</mat-placeholder>
            </mat-form-field>
          </th>
          <td mat-cell *matCellDef="let record">
            {{ record.code }}
          </td>
        </ng-container>
        <ng-container matColumnDef="CPT Code">
          <th class="header" mat-header-cell *matHeaderCellDef>
            <mat-form-field class="filter">
              <input autocomplete="new-CPTCode" matInput formControlName="CPTCode" />
              <mat-placeholder>CPT Code</mat-placeholder>
            </mat-form-field>
          </th>
          <td mat-cell *matCellDef="let record">
            {{ record.cptCode }}
          </td>
        </ng-container>
        <ng-container matColumnDef="Description">
          <th class="header" mat-header-cell *matHeaderCellDef>
            <mat-form-field class="filter">
              <input autocomplete="new-Description" matInput formControlName="Description" />
              <mat-placeholder>Description</mat-placeholder>
            </mat-form-field>
          </th>
          <td mat-cell *matCellDef="let element">{{ element?.description }}</td>
        </ng-container>
        <ng-container matColumnDef="POS">
          <th class="header" mat-header-cell *matHeaderCellDef>
            <mat-form-field class="filter">
              <input autocomplete="new-POS" matInput formControlName="POS" />
              <mat-placeholder>POS</mat-placeholder>
            </mat-form-field>
          </th>
          <td mat-cell *matCellDef="let element">{{ element?.pos }}</td>
        </ng-container>
        <ng-container matColumnDef="Qty">
          <th class="header" mat-header-cell *matHeaderCellDef>
            <mat-form-field class="filter">
              <input autocomplete="new-Qty" matInput formControlName="Qty" />
              <mat-placeholder>Units</mat-placeholder>
            </mat-form-field>
          </th>
          <td mat-cell *matCellDef="let element">{{ element?.qty }}</td>
        </ng-container>
        <ng-container matColumnDef="Min OH">
          <th class="header" mat-header-cell *matHeaderCellDef>
            <mat-form-field class="filter">
              <input autocomplete="new-MinOH" matInput formControlName="MinOH" />
              <mat-placeholder>Min OH</mat-placeholder>
            </mat-form-field>
          </th>
          <td mat-cell *matCellDef="let element">{{ element?.minOH }}</td>
        </ng-container>

        <ng-container matColumnDef="Order Qty">
          <th class="header" mat-header-cell *matHeaderCellDef>
            <mat-form-field class="filter">
              <input autocomplete="new-OrderQty" matInput formControlName="OrderQty" />
              <mat-placeholder>Order Units</mat-placeholder>
            </mat-form-field>
          </th>
          <td mat-cell *matCellDef="let element">{{ element?.orderQty }}</td>
        </ng-container>
        <ng-container matColumnDef="Action">
          <th class="header" mat-header-cell *matHeaderCellDef mat-sort-header>
            Action
          </th>
          <td mat-cell *matCellDef="let record">
            <a
              class="editBtnColor eyeCursorclass"
              target="_blank"
              (click)="ViewButtonHitted(record.id)"
            >
              <i class="fa fa-eye"></i>
            </a>
          </td>
        </ng-container>
        <ng-container matColumnDef="noRecords">
          <td
            class="text-align-center"
            mat-footer-cell
            *matFooterCellDef
            colspan="9"
          >
            No Records Found
          </td>
        </ng-container>
        <ng-container matColumnDef="paginator">
          <td mat-footer-cell *matFooterCellDef colspan="9">
            <mat-paginator
              class="table table-responsive mat-table-class"
              #MatPaginator
              [length]="dataSource?.data?.length"
              [pageSize]="10"
              [pageIndex]="0"
              showFirstLastButtons
              [pageSizeOptions]="[5, 10, 25, 100]"
            >
            </mat-paginator>
          </td>
        </ng-container>
      </form>
    </div>
    <div *ngSwitchCase="'reminders'">
      <ng-container matColumnDef="SNo">
        <th class="header" mat-header-cell *matHeaderCellDef mat-sort-header>
          S.No
        </th>
        <td mat-cell *matCellDef="let record; let i = index">{{ i + 1 }}</td>
      </ng-container>

      <ng-container matColumnDef="reminderSubject">
        <th class="header" mat-header-cell *matHeaderCellDef mat-sort-header>
          Reminder Subject
        </th>
        <td mat-cell *matCellDef="let record">
          {{ record.subject }}
        </td>
      </ng-container>

      <ng-container matColumnDef="reminderDate">
        <th class="header" mat-header-cell *matHeaderCellDef mat-sort-header>
          Reminder Date
        </th>
        <td mat-cell *matCellDef="let record">
          {{ record.reminderDate }}
        </td>
      </ng-container>
      <ng-container matColumnDef="CreatedDate">
        <th class="header" mat-header-cell *matHeaderCellDef>Created Date</th>
        <td mat-cell *matCellDef="let element">{{ element?.creationTime }}</td>
      </ng-container>

      <ng-container matColumnDef="Action">
        <th class="header" mat-header-cell *matHeaderCellDef mat-sort-header>
          Action
        </th>
        <td mat-cell *matCellDef="let record">
          <a
            class="editBtnColor eyeCursorclass"
            target="_blank"
            (click)="ViewButtonHitted(record.id)"
          >
            <i class="fa fa-eye mr-3"></i>
          </a>
          <a *ngIf="record.orderStatus" class="deleteBtnColor">
            <i (click)="deleteButtonClikced(record)" class="fa fa-trash"></i>
          </a>
        </td>
      </ng-container>

      <ng-container matColumnDef="noRecords">
        <td
          class="text-align-center"
          mat-footer-cell
          *matFooterCellDef
          colspan="5"
        >
          <div *ngIf="!isLoading">
            {{ "No Records Found" }}
          </div>
          <div [hidden]="!isLoading">
            <mat-spinner [diameter]="220"></mat-spinner>
          </div>
        </td>
      </ng-container>
      <ng-container matColumnDef="paginator">
        <td mat-footer-cell *matFooterCellDef colspan="5">
          <mat-paginator
            class="table table-responsive mat-table-class"
            #MatPaginator
            [length]="dataSource?.data?.length"
            [pageSize]="10"
            [pageIndex]="0"
            showFirstLastButtons
            [pageSizeOptions]="[5, 10, 25, 100]"
          >
          </mat-paginator>
        </td>
      </ng-container>
    </div>
    <!-- <div *ngSwitchCase="'patientRenewalOrders'">
      <form style="display: flex" [formGroup]="orderForm">
        <ng-container matColumnDef="OrderId">
          <th class="header" mat-header-cell *matHeaderCellDef>
            <mat-form-field class="filter">
              <input matInput formControlName="OrderId" type="number" min="0">
              <mat-placeholder class="center">Sale Order Id</mat-placeholder>
            </mat-form-field>
          </th>
          <td mat-cell *matCellDef="let element"> {{element.saleorderId}} </td>
        </ng-container>
        <ng-container matColumnDef="ItemId">
          <th class="header" mat-header-cell *matHeaderCellDef>
            <mat-form-field class="filter">
              <input matInput formControlName="ItemId">
              <mat-placeholder>Item Id</mat-placeholder>
            </mat-form-field>
          </th>
          <td mat-cell *matCellDef="let element"> {{element.itemId}} </td>
        </ng-container>
        <ng-container matColumnDef="ItemName">
          <th class="header" mat-header-cell *matHeaderCellDef>
            <mat-form-field class="filter">
              <input matInput formControlName="ItemName">
              <mat-placeholder>Item Name</mat-placeholder>
            </mat-form-field>
          </th>
          <td mat-cell *matCellDef="let element"> {{element.itemName}} </td>
        </ng-container>

        <ng-container matColumnDef="Quantity">
          <th class="header" mat-header-cell *matHeaderCellDef>
            <mat-form-field class="filter">
              <input matInput formControlName="Quantity">
              <mat-placeholder>Quantity</mat-placeholder>
            </mat-form-field>
          </th>
          <td mat-cell *matCellDef="let element"> {{element.quantity}} </td>
        </ng-container>

        <ng-container matColumnDef="CreatedDate">
          <th class="header" mat-header-cell *matHeaderCellDef>
            <mat-form-field class="filter">
              <input matInput formControlName="CreatedDate">
              <mat-placeholder>Created Date</mat-placeholder>
            </mat-form-field>
          </th>
          <td mat-cell *matCellDef="let element"> {{element?.CreatedDate}} </td>
        </ng-container>


        <ng-container matColumnDef="options">
          <th class="header" mat-header-cell *matHeaderCellDef>
            Options
          </th>
          <td mat-cell *matCellDef="let element">
            <a class="editBtnColor" (click)="ViewButtonHitted(element.id)">
              <i class="fa fa-eye" ></i>
            </a>
          </td>
        </ng-container>


        <ng-container matColumnDef="noRecords">
          <td class="text-align-center" mat-footer-cell *matFooterCellDef colspan="6">
            No Records Found
          </td>
        </ng-container>
        <ng-container matColumnDef="paginator">
          <td mat-footer-cell *matFooterCellDef colspan="6">
            <mat-paginator class="table table-responsive mat-table-class" #MatPaginator
              [length]="dataSource?.data?.length" [pageSize]="10" [pageIndex]="0" showFirstLastButtons
              [pageSizeOptions]="[5, 10, 25, 100]">
            </mat-paginator>
          </td>
        </ng-container>
      </form>
    </div> -->
    <div *ngSwitchCase="'disapproveOrders'">
      <form style="display: flex" [formGroup]="orderForm">
        <ng-container matColumnDef="OrderId">
          <th class="header" mat-header-cell *matHeaderCellDef>
            <mat-form-field class="filter">
              <input autocomplete="new-OrderId" matInput formControlName="OrderId" type="number" min="0" />
              <mat-placeholder class="center">Sale Order Id</mat-placeholder>
            </mat-form-field>
          </th>
          <td mat-cell *matCellDef="let element">{{ element.saleorderId }}</td>
        </ng-container>
        <ng-container matColumnDef="ItemId">
          <th class="header" mat-header-cell *matHeaderCellDef>
            <mat-form-field class="filter">
              <input autocomplete="new-ItemId" matInput formControlName="ItemId" />
              <mat-placeholder>Item Id</mat-placeholder>
            </mat-form-field>
          </th>
          <td mat-cell *matCellDef="let element">{{ element.itemId }}</td>
        </ng-container>
        <ng-container matColumnDef="ItemName">
          <th class="header" mat-header-cell *matHeaderCellDef>
            <mat-form-field class="filter">
              <input autocomplete="new-ItemName" matInput formControlName="ItemName" />
              <mat-placeholder>Item Name</mat-placeholder>
            </mat-form-field>
          </th>
          <td mat-cell *matCellDef="let element">{{ element.itemName }}</td>
        </ng-container>

        <ng-container matColumnDef="Quantity">
          <th class="header" mat-header-cell *matHeaderCellDef>
            <mat-form-field class="filter">
              <input autocomplete="new-Quantity" matInput formControlName="Quantity" />
              <mat-placeholder>Units</mat-placeholder>
            </mat-form-field>
          </th>
          <td mat-cell *matCellDef="let element">{{ element.quantity }}</td>
        </ng-container>

        <ng-container matColumnDef="CreatedDate">
          <th class="header" mat-header-cell *matHeaderCellDef>
            <mat-form-field class="filter">
              <input autocomplete="new-CreatedDate" matInput formControlName="CreatedDate" />
              <mat-placeholder>Created Date</mat-placeholder>
            </mat-form-field>
          </th>
          <td mat-cell *matCellDef="let element">{{ element?.CreatedDate }}</td>
        </ng-container>

        <ng-container matColumnDef="options">
          <th class="header" mat-header-cell *matHeaderCellDef>Options</th>
          <td mat-cell *matCellDef="let element">
            <a class="editBtnColor" (click)="ViewButtonHitted(element.id)">
              <i class="fa fa-eye"></i>
            </a>
          </td>
        </ng-container>

        <ng-container matColumnDef="noRecords">
          <td
            class="text-align-center"
            mat-footer-cell
            *matFooterCellDef
            colspan="6"
          >
            No Records Found
          </td>
        </ng-container>
        <ng-container matColumnDef="paginator">
          <td mat-footer-cell *matFooterCellDef colspan="6">
            <mat-paginator
              class="table table-responsive mat-table-class"
              #MatPaginator
              [length]="dataSource?.data?.length"
              [pageSize]="10"
              [pageIndex]="0"
              showFirstLastButtons
              [pageSizeOptions]="[5, 10, 25, 100]"
            >
            </mat-paginator>
          </td>
        </ng-container>
      </form>
    </div>

    <div *ngSwitchCase="'AdminfaxBin'">
      <ng-container matColumnDef="sno">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>S.No</th>
        <td mat-cell *matCellDef="let datas; let i = index">
          {{ i + 1 }}
        </td>
      </ng-container>
      <ng-container matColumnDef="documentName">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Document Name</th>
        <td mat-cell *matCellDef="let datas">
          <ng-container>
            {{ datas.fileName }}
          </ng-container>
        </td>
      </ng-container>
      <ng-container matColumnDef="documentType">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Document Type</th>
        <td mat-cell *matCellDef="let datas">
          <ng-container>
            {{ "PDF" }}
          </ng-container>
        </td>
      </ng-container>
      <ng-container matColumnDef="documentSize">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Document Size</th>
        <td mat-cell *matCellDef="let datas">
          <ng-container>
            {{ datas.fileSize }}
          </ng-container>
        </td>
      </ng-container>
      <ng-container matColumnDef="uploadedDate">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Uploaded Date</th>
        <td mat-cell *matCellDef="let datas">
          <ng-container>
            {{ datas.creationTime }}
          </ng-container>
        </td>
      </ng-container>
      <ng-container matColumnDef="stat">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Stat</th>
        <td mat-cell *matCellDef="let datas">
          <ng-container *ngIf="datas.isStat === 1">
            <!-- {{ datas.isStat===1?"True":"False" }} -->
            <mat-checkbox [disabled]="true" [checked]="true"> </mat-checkbox>
          </ng-container>
          <ng-container *ngIf="datas.isStat === 0">
            <!-- {{ datas.isStat===1?"True":"False" }} -->
            <mat-checkbox [disabled]="true" [checked]="false"> </mat-checkbox>
          </ng-container>
        </td>
      </ng-container>
      <ng-container matColumnDef="assignedPerson">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          Assigned Person
        </th>
        <td mat-cell *matCellDef="let datas">
          <ng-container>
            {{ datas.assignedTo }}
          </ng-container>
        </td>
      </ng-container>
      <ng-container matColumnDef="assignedDate">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Assigned Date</th>
        <td mat-cell *matCellDef="let datas">
          <ng-container>
            {{ datas.assignedTime }}
          </ng-container>
        </td>
      </ng-container>

      <ng-container matColumnDef="Options">
        <th
          class="verticalAlignColumn text-center"
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
        >
          Options
        </th>
        <td
          class="verticalAlignColumn text-center"
          mat-cell
          *matCellDef="let datas"
        >
          <a (click)="ViewButtonHitted(datas)">
            <i
              class="fa fa-pencil pointer editBtnColor mr-3"
              matTooltip="Click To View Role"
            ></i>
          </a>

          <a *ngIf="datas.isAssigned === 0" (click)="deleteRolesClikced(datas)">
            <i
              class="deleteBtnColor fa fa-trash pointer mr-3"
              matTooltip="Click To Delete Role"
            ></i>
          </a>
        </td>
      </ng-container>
      <ng-container matColumnDef="noRecords">
        <td
          class="text-align-center"
          mat-footer-cell
          *matFooterCellDef
          colspan="9"
        >
          {{ "No Records Found" }}
        </td>
      </ng-container>
      <ng-container matColumnDef="paginator">
        <td mat-footer-cell *matFooterCellDef colspan="9">
          <mat-paginator
            class="table table-responsive mat-table-class"
            #MatPaginator
            [length]="dataSource?.data?.length"
            [pageSize]="10"
            [pageIndex]="0"
            showFirstLastButtons
            [pageSizeOptions]="[5, 10, 25, 100]"
          >
          </mat-paginator>
        </td>
      </ng-container>
    </div>

    <div *ngSwitchCase="'userfaxBin'">
      <ng-container matColumnDef="sno">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>S.No</th>
        <td mat-cell *matCellDef="let datas; let i = index">
          {{ i + 1 }}
        </td>
      </ng-container>
      <ng-container matColumnDef="usersList">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          Users To be assigned
        </th>
        <td mat-cell *matCellDef="let datas; let i = index">
          <mat-form-field>
            <mat-select placeholder="Users" ngModelOptions>
              <mat-option
                *ngFor="let user of arrUsersList"
                [value]="user?.id + '/' + user?.userName"
              >
                {{ user.userName }}
              </mat-option>
            </mat-select>
            <button
              mat-button
              (click)="datas.assignedTo = ''; $event.stopPropagation()"
              *ngIf="datas.assignedTo"
              matSuffix
              mat-icon-button
            >
              <mat-icon>close</mat-icon>
            </button>
          </mat-form-field>
        </td>
      </ng-container>

      <ng-container matColumnDef="documentName">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Document Name</th>
        <td mat-cell *matCellDef="let datas">
          <ng-container>
            {{ datas.uploadedFileName }}
          </ng-container>
        </td>
      </ng-container>
      <ng-container matColumnDef="documentType">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Batch Id</th>
        <td mat-cell *matCellDef="let datas">
          <ng-container>
            {{ datas.defaultBatchId }}
          </ng-container>
        </td>
      </ng-container>

      <ng-container matColumnDef="documentSize">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Document Size</th>
        <td mat-cell *matCellDef="let datas">
          <ng-container>
            {{ datas?.extractedData?.extractedfields?.defaultPatientId }}
            <br />

            {{ datas?.extractedData?.extractedfields?.personals?.fullName }}
            <br />

            {{
              datas?.extractedData?.extractedfields?.personals?.dateOfBirth
                | date : "MM/dd/yyyy" : "en_US"
            }}
          </ng-container>
        </td>
      </ng-container>

      <ng-container matColumnDef="stat">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Stat</th>
        <td mat-cell *matCellDef="let datas">
          <ng-container>
            <ng-container *ngIf="datas.isStat === 1">
              <mat-checkbox [disabled]="true" [checked]="true"> </mat-checkbox>
            </ng-container>
            <ng-container *ngIf="datas.isStat === 0">
              <mat-checkbox [disabled]="true" [checked]="false"> </mat-checkbox>
            </ng-container>
          </ng-container>
        </td>
      </ng-container>
      <ng-container matColumnDef="assignedDate">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Assigned Date</th>
        <td mat-cell *matCellDef="let datas">
          <ng-container>
            {{ datas?.assignedTime | date : "MM/dd/yyyy h:mm a" : "en_US" }}
          </ng-container>
        </td>
      </ng-container>

      <ng-container matColumnDef="Options">
        <th
          class="verticalAlignColumn text-center"
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
        >
          Options
        </th>
        <td
          class="verticalAlignColumn text-center"
          mat-cell
          *matCellDef="let datas"
        >
          <a
            (click)="ViewButtonHitted(datas)"
            class="mr-2 ml-2"
            matTooltip="Click To View Document"
          >
            <i class="fa fa-eye pointer editBtnColor"></i>
          </a>
          <a
            (click)="deleteButtonHitted(datas)"
            class="mr-2 ml-2"
            matTooltip="Click To Download Document"
          >
            <i class="fa fa-cloud-download mr-3 pointer customThemeClass"></i>
          </a>
        </td>
      </ng-container>

      <ng-container matColumnDef="noRecords">
        <td
          class="text-align-center"
          mat-footer-cell
          *matFooterCellDef
          colspan="9"
        >
          <div *ngIf="!isLoading">
            {{ "No Records Found" }}
          </div>
          <div [hidden]="!isLoading">
            <mat-spinner [diameter]="220"></mat-spinner>
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="select">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let element">
          <mat-checkbox
            (click)="$event.stopPropagation()"
            (change)="$event ? selection.toggle(element) : null"
            [checked]="selection.isSelected(element)"
          >
          </mat-checkbox>
        </td>
      </ng-container>
      <ng-container matColumnDef="paginator">
        <td mat-footer-cell *matFooterCellDef colspan="9">
          <div class="row">
            <div class="col-3">
              <button
                class="buttonColor"
                *ngIf="!selection.isEmpty()"
                (click)="emitUserDocumentsFaxBinToProcess()"
                mat-button
              >
                Process Selected {{ selection.selected.length }} Documents
                <mat-icon *ngIf="!isLoading" class="mr-2 ml-2">
                  <mat-spinner
                    class="spinner-border spinner-border-sm"
                    diameter="20"
                  >
                  </mat-spinner>
                </mat-icon>
              </button>
            </div>
            <div class="col-9">
              <mat-paginator
                class="table table-responsive mat-table-class"
                #MatPaginator
                [length]="dataSource?.data?.length"
                [pageSize]="10"
                [pageIndex]="0"
                showFirstLastButtons
                [pageSizeOptions]="[5, 10, 25, 100]"
              >
              </mat-paginator>
            </div>
          </div>
        </td>
      </ng-container>
    </div>

    <div *ngSwitchCase="'claimProcessList'">
      <form>
        <ng-container matColumnDef="pateintId">
          <th class="header" mat-header-cell *matHeaderCellDef>
            <mat-form-field class="filter">
              <input autocomplete="new-patientId" matInput formControlName="patientId" />
              <mat-placeholder class="center">Patient Id</mat-placeholder>
            </mat-form-field>
          </th>
          <td mat-cell *matCellDef="let element">{{ element?.patientId }}</td>
        </ng-container>

        <ng-container matColumnDef="id">
          <th class="header" mat-header-cell *matHeaderCellDef>Id</th>
          <td mat-cell *matCellDef="let element">{{ element?.id }}</td>
        </ng-container>

        <ng-container matColumnDef="claimid">
          <th class="header" mat-header-cell *matHeaderCellDef>Claim Id</th>
          <td mat-cell *matCellDef="let element">{{ element?.claimNumber }}</td>
        </ng-container>

        <ng-container matColumnDef="saleorderId">
          <th class="header" mat-header-cell *matHeaderCellDef>
            <mat-form-field class="filter">
              <input autocomplete="new-saleOrderId" matInput formControlName="saleOrderId" />
              <mat-placeholder class="center"> SaleOrder Id </mat-placeholder>
            </mat-form-field>
          </th>
          <td mat-cell *matCellDef="let element">{{ element?.orderId }}</td>
        </ng-container>

        <ng-container matColumnDef="patientName">
          <th class="header" mat-header-cell *matHeaderCellDef>
            <mat-form-field class="filter">
              <input autocomplete="new-patientName" matInput formControlName="patientName" />
              <mat-placeholder class="center">Patient Name </mat-placeholder>
            </mat-form-field>
          </th>
          <td mat-cell *matCellDef="let element">
            {{ element?.patientFirstName + " " + element?.patientLastName }}
          </td>
        </ng-container>
        <ng-container matColumnDef="patientDob">
          <th class="header" mat-header-cell *matHeaderCellDef>Patient DOB</th>
          <td mat-cell *matCellDef="let element">{{ element?.mpatientDob }}</td>
        </ng-container>

        <ng-container matColumnDef="amount">
          <th class="header" mat-header-cell *matHeaderCellDef>
            <mat-form-field class="filter">
              <input autocomplete="new-billedAmount" matInput formControlName="billedAmount" />
              <mat-placeholder class="center"> Total Billed </mat-placeholder>
            </mat-form-field>
          </th>
          <td mat-cell *matCellDef="let element">
            {{ element?.totalItemBilledAmount }}
          </td>
        </ng-container>

        <ng-container matColumnDef="primaryInsuranceName">
          <th class="header" mat-header-cell *matHeaderCellDef>
            <mat-form-field class="filter">
              <input autocomplete="new-payerName" matInput formControlName="payerName" />
              <mat-placeholder class="center"> Insurance Name </mat-placeholder>
            </mat-form-field>
          </th>
          <td mat-cell *matCellDef="let element">
            {{ element?.primaryInsuranceName }}
          </td>
        </ng-container>
        <ng-container matColumnDef="clrhouse">
          <th class="header" mat-header-cell *matHeaderCellDef>
            Clearing House
          </th>
          <td mat-cell *matCellDef="let element">
            {{ element?.clearingHouseType }}
            <!-- <mat-form-field>
            <mat-select placeholder="Clearing House" [ngModelOptions]='{ name: "Clearing House"}'
              [(ngModel)]="element.clearingHouseType">
              <mat-option *ngFor="let clHouseType of clearingHouseTypes" [value]="clHouseType.clearingHouseType">
                {{clHouseType.clearingHouseType}}
              </mat-option>
            </mat-select>
          </mat-form-field> -->
          </td>
        </ng-container>

        <ng-container matColumnDef="formDescripition">
          <th class="header" mat-header-cell *matHeaderCellDef>Status</th>
          <td mat-cell *matCellDef="let element">{{ element?.claimStatus }}</td>
        </ng-container>

        <ng-container matColumnDef="billingType">
          <th class="header" mat-header-cell *matHeaderCellDef>
            <mat-form-field class="filter">
              <input autocomplete="new-billingType" matInput formControlName="billingType" />
              <mat-placeholder class="center"> Billing Type </mat-placeholder>
            </mat-form-field>
          </th>
          <td mat-cell *matCellDef="let element">
            <ng-container *ngIf="element.billingType === 1">
              <span> {{ "Purchase" }}</span>
            </ng-container>
            <ng-container *ngIf="element.billingType === 2">
              <span> {{ "Rental" }}</span>
            </ng-container>
            <ng-container *ngIf="element.billingType === 3">
              <span> {{ "Purchase / Rental" }}</span>
            </ng-container>
          </td>
        </ng-container>

        <ng-container matColumnDef="DOS">
          <th class="header" mat-header-cell *matHeaderCellDef>DOS</th>
          <td mat-cell *matCellDef="let element">
            {{ element?.mitemDateOfService }}
          </td>
        </ng-container>
        <!-- <ng-container matColumnDef="planCode">
        <th class="header" mat-header-cell *matHeaderCellDef>
          Plan Code
        </th>
        <td mat-cell *matCellDef="let element"> {{element?.planCode}} </td>
      </ng-container> -->

        <ng-container matColumnDef="item">
          <th class="header" mat-header-cell *matHeaderCellDef>Item</th>

          <td mat-cell *matCellDef="let element">
            {{ element?.itemDetails[0]?.itemProcedureCodeId }}
          </td>
        </ng-container>
        <ng-container matColumnDef="insurance">
          <th class="header" mat-header-cell *matHeaderCellDef>Insurance</th>
          <td mat-cell *matCellDef="let element">{{ element?.provider }}</td>
        </ng-container>

        <ng-container matColumnDef="options">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Options</th>
          <td mat-cell *matCellDef="let element">
            <a
              (click)="ViewButtonHitted(element?.claimId, element?.id, element)"
              class="eyeCursorclass"
              target="_blank"
              ><i class="fa fa-eye"></i>
            </a>
          </td>
        </ng-container>
        <ng-container matColumnDef="select">
          <!-- <th class="header" mat-header-cell *matHeaderCellDef>
          Process
        </th> -->
          <!-- <td mat-cell *matCellDef="let element">
          <mat-checkbox value="{{element.process}}"></mat-checkbox>
        </td> -->
          <th mat-header-cell *matHeaderCellDef>
            <!-- <mat-checkbox (change)="$event ? masterToggle() : null" [checked]="selection.hasValue() && isAllSelected()"
            [indeterminate]="selection.hasValue() && !isAllSelected()">
          </mat-checkbox> -->
          </th>
          <td mat-cell *matCellDef="let element">
            <mat-checkbox
              (click)="$event.stopPropagation()"
              (change)="$event ? selection.toggle(element) : null"
              [disabled]="!element.clearingHouseType"
              [checked]="selection.isSelected(element)"
            >
            </mat-checkbox>
          </td>
        </ng-container>
        <ng-container matColumnDef="noRecords">
          <td
            class="text-align-center"
            mat-footer-cell
            *matFooterCellDef
            colspan="14"
          >
            <div *ngIf="!isLoading">
              {{ "No Records Found" }}
            </div>
            <div [hidden]="!isLoading">
              <mat-spinner [diameter]="220"></mat-spinner>
            </div>
          </td>
        </ng-container>
        <ng-container matColumnDef="paginator">
          <td mat-footer-cell *matFooterCellDef colspan="14">
            <div class="row">
              <div class="col-6 mt-3">
                <button
                  class="buttonColor"
                  [disabled]="saveButtonHide"
                  *ngIf="!selection.isEmpty()"
                  (click)="emitClaimProcess()"
                  mat-button
                >
                  Manual Process Selected {{ selection.selected.length }} Claims
                  <mat-icon *ngIf="isLoading" class="mr-2 ml-2">
                    <mat-spinner
                      class="spinner-border spinner-border-sm zoom"
                      diameter="20"
                    >
                    </mat-spinner>
                  </mat-icon>
                </button>
                <button
                  class="buttonColor ml-2"
                  [disabled]="saveButtonHide"
                  *ngIf="!selection.isEmpty()"
                  (click)="emitElectronicClaimProcess()"
                  mat-button
                >
                  zermid Process Selected {{ selection.selected.length }} Claims
                  <mat-icon *ngIf="isLoadings" class="mr-2 ml-2">
                    <mat-spinner
                      class="spinner-border zoom spinner-border-sm"
                      diameter="20"
                    >
                    </mat-spinner>
                  </mat-icon>
                </button>
              </div>
              <div class="col-6">
                <mat-paginator
                  class="table table-responsive mat-table-class"
                  #MatPaginator
                  [length]="dataSource?.data?.length"
                  [pageSize]="10"
                  [pageIndex]="0"
                  showFirstLastButtons
                  [pageSizeOptions]="[5, 10, 25, 100]"
                >
                </mat-paginator>
              </div>
            </div>
          </td>
        </ng-container>
      </form>
    </div>

    <div *ngSwitchCase="'selectedDocumentList'">
      <ng-container matColumnDef="sno">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>S.No</th>
        <td mat-cell *matCellDef="let datas; let i = index">
          {{ i + 1 }}
        </td>
      </ng-container>
      <ng-container matColumnDef="fileName">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Document Name</th>
        <td mat-cell *matCellDef="let datas">
          <ng-container>
            {{ datas.fileName }}
          </ng-container>
        </td>
      </ng-container>
      <ng-container matColumnDef="fileType">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Document Type</th>
        <td mat-cell *matCellDef="let datas">
          <ng-container>
            {{ datas.fileType }}
          </ng-container>
        </td>
      </ng-container>
      <ng-container matColumnDef="fileSize">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Document Size</th>
        <td mat-cell *matCellDef="let datas">
          <ng-container>
            {{ datas.fileSize }}
          </ng-container>
        </td>
      </ng-container>
      <!-- <ng-container matColumnDef="modifiedTime">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          Modified Date
        </th>
        <td mat-cell *matCellDef="let datas">
          <ng-container>
            {{ datas.modifiedTime }}
          </ng-container>
        </td>
      </ng-container> -->
      <ng-container matColumnDef="noRecords">
        <td
          class="text-align-center"
          mat-footer-cell
          *matFooterCellDef
          colspan="8"
        >
          {{ "No Records Found" }}
        </td>
      </ng-container>
      <ng-container matColumnDef="paginator">
        <td mat-footer-cell *matFooterCellDef colspan="8">
          <mat-paginator
            class="table table-responsive mat-table-class"
            #MatPaginator
            [length]="dataSource?.data?.length"
            [pageSize]="10"
            [pageIndex]="0"
            showFirstLastButtons
            [pageSizeOptions]="[5, 10, 25, 100]"
          >
          </mat-paginator>
        </td>
      </ng-container>
    </div>

    <div *ngSwitchCase="'patientInsuranceVerification'">
      <form style="display: flex" [formGroup]="insuranceVerificationForm">
        <ng-container matColumnDef="SNo">
          <th
            class="header"
            class="w-125 th-center"
            mat-header-cell
            *matHeaderCellDef
          >
            <mat-form-field class="filter">
              <input autocomplete="new-SNo" matInput formControlName="SNo" type="number" min="0" />
              <mat-placeholder class="center">S.No</mat-placeholder>
            </mat-form-field>
          </th>
          <td mat-cell *matCellDef="let element; let i = index">{{ i + 1 }}</td>
        </ng-container>

        <ng-container matColumnDef="PatientId">
          <th
            class="header"
            class="w-125 th-center"
            mat-header-cell
            *matHeaderCellDef
          >
            <mat-form-field class="filter">
              <input autocomplete="new-PatientId" matInput formControlName="PatientId" />
              <mat-placeholder>PatientId</mat-placeholder>
            </mat-form-field>
          </th>
          <td mat-cell *matCellDef="let element">{{ element?.patientId }}</td>
        </ng-container>
        <ng-container matColumnDef="PatientName">
          <th
            class="header"
            class="w-125 th-center"
            mat-header-cell
            *matHeaderCellDef
          >
            <mat-form-field class="filter">
              <input autocomplete="new-PatientName" matInput formControlName="PatientName" />
              <mat-placeholder>Patient Name</mat-placeholder>
            </mat-form-field>
          </th>
          <td mat-cell *matCellDef="let element">{{ element?.PatientName }}</td>
        </ng-container>

        <ng-container matColumnDef="orderId">
          <th
            class="header"
            class="w-125 th-center"
            mat-header-cell
            *matHeaderCellDef
          >
            <mat-form-field class="filter">
              <input autocomplete="new-orderId" matInput formControlName="orderId" />
              <mat-placeholder>Sale Order Id</mat-placeholder>
            </mat-form-field>
          </th>
          <td mat-cell *matCellDef="let element">{{ element?.OrderId }}</td>
        </ng-container>
        <ng-container matColumnDef="createDate">
          <th
            class="header"
            class="w-125 th-center"
            mat-header-cell
            *matHeaderCellDef
          >
            <mat-form-field class="filter">
              <input autocomplete="new-createDate" matInput formControlName="createDate" />
              <mat-placeholder>Create Date</mat-placeholder>
            </mat-form-field>
          </th>
          <td mat-cell *matCellDef="let element">{{ element?.createDate }}</td>
        </ng-container>
        <ng-container matColumnDef="VerifyName">
          <th
            class="header"
            class="w-125 th-center"
            mat-header-cell
            *matHeaderCellDef
          >
            <mat-form-field class="filter">
              <input autocomplete="new-VerifyName" matInput formControlName="VerifyName" />
              <mat-placeholder>VerifyName</mat-placeholder>
            </mat-form-field>
          </th>
          <td mat-cell *matCellDef="let element">{{ element?.VerifyName }}</td>
        </ng-container>
        <ng-container matColumnDef="Status">
          <th
            class="header"
            class="w-125 th-center"
            mat-header-cell
            *matHeaderCellDef
          >
            <mat-form-field class="filter">
              <input autocomplete="new-Status" matInput formControlName="Status" />
              <mat-placeholder>Status</mat-placeholder>
            </mat-form-field>
          </th>
          <td mat-cell *matCellDef="let element">{{ element?.Status }}</td>
        </ng-container>

        <ng-container matColumnDef="Options">
          <th class="header" mat-header-cell *matHeaderCellDef>Options</th>
          <td mat-cell *matCellDef="let element">
            <a class="editBtnColor" (click)="ViewButtonHitted(element.id)">
              <i class="fa fa-eye mr-3"></i>
            </a>
            <a class="editBtnColor" (click)="editButtonHitted(element.id)">
              <i class="fa fa-pencil"></i>
            </a>
          </td>
        </ng-container>

        <ng-container matColumnDef="noRecords">
          <td
            class="text-align-center"
            mat-footer-cell
            *matFooterCellDef
            colspan="8"
          >
            No Records Found
          </td>
        </ng-container>
        <ng-container matColumnDef="paginator">
          <td mat-footer-cell *matFooterCellDef colspan="8">
            <mat-paginator
              class="table table-responsive mat-table-class"
              #MatPaginator
              [length]="dataSource?.data?.length"
              [pageSize]="10"
              [pageIndex]="0"
              showFirstLastButtons
              [pageSizeOptions]="[5, 10, 25, 100]"
            >
            </mat-paginator>
          </td>
        </ng-container>
      </form>
    </div>

    <div *ngSwitchCase="'auth'">
      <ng-container matColumnDef="authId">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Auth ID</th>
        <td mat-cell mat-cell *matCellDef="let item">{{ item?.authId }}</td>
      </ng-container>
      <ng-container matColumnDef="startDate">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          Auth BeginDate
        </th>
        <td mat-cell mat-cell *matCellDef="let item">{{ item?.startDate }}</td>
      </ng-container>
      <ng-container matColumnDef="endDate">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Auth EndDate</th>
        <td mat-cell mat-cell *matCellDef="let item">{{ item?.endDate }}</td>
      </ng-container>
      <ng-container matColumnDef="authAmount">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Amount</th>
        <td mat-cell mat-cell *matCellDef="let item">{{ item?.authAmount }}</td>
      </ng-container>
      <!-- <ng-container matColumnDef="days">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Days</th>
        <td mat-cell mat-cell *matCellDef="let item">{{ item?.daysDifference }}</td>
      </ng-container> -->
      <!-- <ng-container matColumnDef="AuthNo">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Auth No</th>
        <td mat-cell mat-cell *matCellDef="let item">{{ item?.authNo }}</td>
      </ng-container> -->

      <ng-container matColumnDef="Note">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Note</th>
        <td mat-cell *matCellDef="let item">{{ item?.note }}</td>
      </ng-container>
      <ng-container matColumnDef="Completed">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Status</th>
        <td mat-cell *matCellDef="let item">{{ item?.completed }}</td>
      </ng-container>
      <ng-container matColumnDef="Plan">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Plan</th>
        <td mat-cell *matCellDef="let item">{{ item?.plan }}</td>
      </ng-container>
      <ng-container matColumnDef="ExpiryStatus">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Auth Status</th>

        <td mat-cell *matCellDef="let item">
          <ng-container>
            <span
              [ngClass]="{
                'make-red': item?.expiryStatus === 'Expired',
                'make-black': item?.expiryStatus === 'Not Mentioned',
                'make-green': item?.expiryStatus === 'Active'
              }"
            >
              {{ item?.expiryStatus }}
            </span>
          </ng-container>
        </td>
      </ng-container>
      <ng-container matColumnDef="isFollowUp">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Follow Up</th>
        <td mat-cell *matCellDef="let item">
          <ng-container *ngIf="item?.isFollowUp === 1">
            <mat-checkbox [disabled]="true" [checked]="true"> </mat-checkbox>
          </ng-container>
          <ng-container *ngIf="item?.isFollowUp === 0">
            <mat-checkbox [disabled]="true" [checked]="false"> </mat-checkbox>
          </ng-container>
        </td>
      </ng-container>
      <ng-container matColumnDef="Options">
        <th class="header" mat-header-cell *matHeaderCellDef>Options</th>
        <td mat-cell *matCellDef="let element">
          <a class="editBtnColor" (click)="ViewButtonHitted(element)">
            <i class="fa fa-eye"></i>
          </a>
        </td>
      </ng-container>

      <ng-container matColumnDef="noRecords">
        <td
          class="text-align-center"
          mat-footer-cell
          *matFooterCellDef
          colspan="9"
        >
          <div *ngIf="!isLoading">
            {{ "No Records Found" }}
          </div>
          <div [hidden]="!isLoading">
            <mat-spinner [diameter]="220"></mat-spinner>
          </div>
        </td>
      </ng-container>
      <ng-container matColumnDef="paginator">
        <td mat-footer-cell *matFooterCellDef colspan="9">
          <mat-paginator
            class="table table-responsive mat-table-class"
            #MatPaginator
            [length]="dataSource?.data?.length"
            [pageSize]="10"
            [pageIndex]="0"
            showFirstLastButtons
            [pageSizeOptions]="[5, 10, 25, 100]"
          >
          </mat-paginator>
        </td>
      </ng-container>
    </div>
    <div *ngSwitchCase="'ItemBasedAuth'">
      <ng-container matColumnDef="item">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Item Code</th>
        <td mat-cell mat-cell *matCellDef="let item">{{ item?.item }}</td>
      </ng-container>
      <ng-container matColumnDef="authId">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Auth ID</th>
        <td mat-cell mat-cell *matCellDef="let item">{{ item?.authId }}</td>
      </ng-container>
      <ng-container matColumnDef="startDate">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          Auth BeginDate
        </th>
        <td mat-cell mat-cell *matCellDef="let item">{{ item?.startDate }}</td>
      </ng-container>
      <ng-container matColumnDef="endDate">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Auth EndDate</th>
        <td mat-cell mat-cell *matCellDef="let item">{{ item?.endDate }}</td>
      </ng-container>

      <ng-container matColumnDef="Note">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Note</th>
        <td mat-cell *matCellDef="let item">{{ item?.note }}</td>
      </ng-container>
      <ng-container matColumnDef="Days">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Days</th>
        <td mat-cell *matCellDef="let item">{{ item?.daysDifference }}</td>
      </ng-container>

      <ng-container matColumnDef="Units">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Units</th>
        <td mat-cell *matCellDef="let item">{{ item?.units }}</td>
      </ng-container>

      <ng-container matColumnDef="Used">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Used</th>
        <td mat-cell *matCellDef="let item">{{ item?.used }}</td>
      </ng-container>

      <ng-container matColumnDef="Plan">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Plan</th>
        <td mat-cell *matCellDef="let item">{{ item?.insurance }}</td>
      </ng-container>
      <ng-container matColumnDef="type">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Type</th>
        <td mat-cell *matCellDef="let item">{{ item?.saleOrRent }}</td>
      </ng-container>
      <ng-container matColumnDef="Options">
        <th class="header" mat-header-cell *matHeaderCellDef>Options</th>
        <td mat-cell *matCellDef="let element">
          <a class="editBtnColor" (click)="ViewButtonHitted(element)">
            <i class="fa fa-eye"></i>
          </a>
        </td>
      </ng-container>
      <ng-container matColumnDef="ExpiryStatus">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Auth Status</th>
        <td mat-cell *matCellDef="let item">
          <ng-container *ngIf="item?.expireStatus">
            <!-- <button mat-button class="pt-0 pb-0">
              <i [ngClass]="{'fa fa-times-circle make-red': item?.expireStatus === ' Expired',
              'fa fa-check-circle make-black': item?.expireStatus === 'Not Mentioned',
              'fa fa-check-circle make-green': item?.expireStatus === 'Active'}" >
              </i>
            </button> -->

            <button
              *ngIf="item?.expireStatus === 'Expired'"
              mat-button
              class="pt-0 pb-0 make-red"
            >
              <mat-icon>block</mat-icon><b>{{ item?.expireStatus }}</b>
            </button>
            <button
              *ngIf="item?.expireStatus === 'Active'"
              mat-button
              class="pt-0 pb-0 make-green"
            >
              <mat-icon>check_circle</mat-icon><b>{{ item?.expireStatus }}</b>
            </button>
            <button
              *ngIf="item?.expireStatus === 'Not Mentioned'"
              mat-button
              class="pt-0 pb-0 make-black"
            >
              <mat-icon>check_circle</mat-icon><b>{{ item?.expireStatus }}</b>
            </button>
          </ng-container>
        </td>
      </ng-container>
      <ng-container matColumnDef="isFollowUp">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Follow Up</th>
        <td mat-cell *matCellDef="let item">
          <ng-container *ngIf="item?.isFollowUp === 1">
            <mat-checkbox (change)="ChangeButtonHitted(item)" [checked]="true">
            </mat-checkbox>
          </ng-container>
          <ng-container *ngIf="item?.isFollowUp === 0">
            <mat-checkbox (change)="ChangeButtonHitted(item)" [checked]="false">
            </mat-checkbox>
          </ng-container>
        </td>
      </ng-container>
      <ng-container matColumnDef="noRecords">
        <td
          class="text-align-center"
          mat-footer-cell
          *matFooterCellDef
          colspan="12"
        >
          <div *ngIf="!isLoading">
            {{ "No Records Found" }}
          </div>
          <div [hidden]="!isLoading">
            <mat-spinner [diameter]="220"></mat-spinner>
          </div>
        </td>
      </ng-container>
      <ng-container matColumnDef="paginator">
        <td mat-footer-cell *matFooterCellDef colspan="12">
          <mat-paginator
            class="table table-responsive mat-table-class"
            #MatPaginator
            [length]="dataSource?.data?.length"
            [pageSize]="10"
            [pageIndex]="0"
            showFirstLastButtons
            [pageSizeOptions]="[5, 10, 25, 100]"
          >
          </mat-paginator>
        </td>
      </ng-container>
    </div>
    <div *ngSwitchCase="'items'">
      <ng-container matColumnDef="DOS">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>DOS</th>
        <td mat-cell mat-cell *matCellDef="let item">
          {{ item?.originalDos }}
        </td>
      </ng-container>
      <ng-container matColumnDef="itemId">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Item Id</th>
        <td mat-cell mat-cell *matCellDef="let item">
          {{ item?.itemIdOrBarCode }}
        </td>
      </ng-container>
      <ng-container matColumnDef="Quantity">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Units</th>
        <td mat-cell mat-cell *matCellDef="let item">{{ item?.qty }}</td>
      </ng-container>
      <!-- <ng-container matColumnDef="UOM">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>UOM</th>
        <td mat-cell mat-cell *matCellDef="let item">{{ item?.UOM }}</td>
      </ng-container> -->
      <ng-container matColumnDef="billQty">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Bill Units</th>
        <td mat-cell *matCellDef="let item">{{ item?.bQty }}</td>
      </ng-container>
      <ng-container matColumnDef="ProcCode">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>ProcCode</th>
        <td mat-cell *matCellDef="let item">{{ item?.priceCode }}</td>
      </ng-container>
      <ng-container matColumnDef="Modifiers">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Modifiers</th>
        <td mat-cell mat-cell *matCellDef="let item">{{ item?.modifiers1 }}</td>
      </ng-container>
      <ng-container matColumnDef="additional">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          Additional Modifiers
        </th>
        <td mat-cell mat-cell *matCellDef="let item">
          {{ item?.additionalModifiers1 }}
        </td>
      </ng-container>
      <ng-container matColumnDef="Coverage">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Coverage</th>
        <td mat-cell mat-cell *matCellDef="let item">
          {{ item?.coverageType }}
        </td>
      </ng-container>
      <ng-container matColumnDef="Allow">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Allow</th>
        <td mat-cell mat-cell *matCellDef="let item">{{ item?.allow }}</td>
      </ng-container>
      <ng-container matColumnDef="nextDos">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Next DOS</th>
        <td mat-cell mat-cell *matCellDef="let item">{{ item?.nextDOS }}</td>
      </ng-container>
      <!-- <ng-container matColumnDef="Options">
        <th  mat-header-cell *matHeaderCellDef mat-sort-header>
           Options
        </th>
        <td  mat-cell *matCellDef="let item">
          <a (click)="ViewButtonHitted(item)" class="eyeCursorclass" target="_blank"><i
              class="fa fa-pencil mr-5"></i> </a>
        </td>
      </ng-container> -->

      <!-- <ng-container matColumnDef="options">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Options</th>

        <td mat-cell *matCellDef="let item">
          <a class="eyeCursorclass" target="_blank"
            ><i class="fa fa-pencil"></i
          ></a></td
      ></ng-container> -->

      <ng-container matColumnDef="noRecords">
        <td
          class="text-align-center"
          mat-footer-cell
          *matFooterCellDef
          colspan="10"
        >
          <div *ngIf="!isLoading">
            {{ "No Records Found" }}
          </div>
          <div [hidden]="!isLoading">
            <mat-spinner [diameter]="220"></mat-spinner>
          </div>
        </td>
      </ng-container>
      <ng-container matColumnDef="paginator">
        <td mat-footer-cell *matFooterCellDef colspan="10">
          <mat-paginator
            class="table table-responsive mat-table-class"
            #MatPaginator
            [length]="dataSource?.data?.length"
            [pageSize]="10"
            [pageIndex]="0"
            showFirstLastButtons
            [pageSizeOptions]="[5, 10, 25, 100]"
          >
          </mat-paginator>
        </td>
      </ng-container>
    </div>

    <div *ngSwitchCase="'eligiblityHistory'">
      <ng-container matColumnDef="Sno">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>S.No</th>
        <td mat-cell *matCellDef="let element; let i = index">{{ i + 1 }}</td>
      </ng-container>
      <ng-container matColumnDef="Plan">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Plan</th>
        <td mat-cell mat-cell *matCellDef="let order">{{ order.plan }}</td>
      </ng-container>

      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Plan Name</th>
        <td mat-cell *matCellDef="let order">{{ order.planName }}</td>
      </ng-container>
      <ng-container matColumnDef="Policy">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Policy</th>
        <td mat-cell *matCellDef="let order">{{ order.policy }}</td>
      </ng-container>
      <ng-container matColumnDef="Status">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Status</th>
        <td mat-cell *matCellDef="let order">{{ order.verificationStatus }}</td>
      </ng-container>
      <ng-container matColumnDef="Options">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Edit</th>
        <td mat-cell *matCellDef="let order">
          <a
            (click)="ViewButtonHitted(order)"
            class="eyeCursorclass"
            target="_blank"
            ><i class="fa fa-pencil mr-2"></i>
          </a>
        </td>
      </ng-container>

      <!-- <ng-container matColumnDef="bin">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          Next Bin
        </th>
        <td mat-cell *matCellDef="let order">
          <a (click)="ViewButtonHitted(order)" class="eyeCursorclass" target="_blank"><i
              class="fa fa-pencil mr-5"></i> </a>
        </td>
      </ng-container> -->
      <ng-container matColumnDef="bin">
        <th class="header" mat-header-cell *matHeaderCellDef mat-sort-header>
          Next Bin
        </th>
        <td mat-cell *matCellDef="let record">
          <ng-container>
            <button
              mat-button
              class="btn btn-sm lightSeaGreen-dot"
              (click)="deleteButtonHitted(record)"
            >
              Move To Auth Bin
            </button>
          </ng-container>
        </td>
      </ng-container>

      <ng-container matColumnDef="noRecords">
        <td
          class="text-align-center"
          mat-footer-cell
          *matFooterCellDef
          colspan="6"
        >
          <div *ngIf="!isLoading">
            {{ "No Records Found" }}
          </div>
          <div [hidden]="!isLoading">
            <mat-spinner [diameter]="220"></mat-spinner>
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="paginator">
        <td mat-footer-cell *matFooterCellDef colspan="6">
          <mat-paginator
            class="table table-responsive mat-table-class"
            #MatPaginator
            [length]="dataSource?.data?.length"
            [pageSize]="10"
            [pageIndex]="0"
            showFirstLastButtons
            [pageSizeOptions]="[5, 10, 25, 100]"
          >
          </mat-paginator>
        </td>
      </ng-container>
    </div>
    <div *ngSwitchCase="'billingClaimList'">
      <form [formGroup]="billingClaimListForm">
        <!-- <ng-container matColumnDef="billingId">
          <th mat-header-cell *matHeaderCellDef>
            <mat-form-field class="filter">
              <input matInput formControlName="billingId" type="number" min="0">
              <mat-placeholder class="center">Billing Id</mat-placeholder>
            </mat-form-field>
          </th>
          <td mat-cell *matCellDef="let element"> {{element?.billingId}} </td>
        </ng-container> -->

        <ng-container matColumnDef="patientId">
          <th mat-header-cell class="w-125 th-center" *matHeaderCellDef>
            <mat-form-field class="filter">
              <input autocomplete="new-patientId" matInput formControlName="patientId" />
              <mat-placeholder>Patient Id</mat-placeholder>
            </mat-form-field>
          </th>
          <td mat-cell *matCellDef="let element">{{ element?.patientId }}</td>
        </ng-container>
        <ng-container matColumnDef="saleOrderId">
          <th mat-header-cell *matHeaderCellDef>
            <mat-form-field class="filter">
              <input autocomplete="new-saleOrderId"
                matInput
                formControlName="saleOrderId"
                class="w-125 th-center"
              />
              <mat-placeholder>SaleOrder Id</mat-placeholder>
            </mat-form-field>
          </th>
          <td mat-cell *matCellDef="let element">{{ element?.saleOrderId }}</td>
        </ng-container>
        <ng-container matColumnDef="patientName">
          <th
            class="header"
            class="w-125 th-center"
            mat-header-cell
            *matHeaderCellDef
          >
            <mat-form-field class="filter">
              <input autocomplete="new-patientName" matInput formControlName="patientName" />
              <mat-placeholder>Patient Name</mat-placeholder>
            </mat-form-field>
          </th>
          <td mat-cell *matCellDef="let element">{{ element?.patientName }}</td>
        </ng-container>
        <!-- <ng-container matColumnDef="chartNo">
          <th class="header" mat-header-cell *matHeaderCellDef>
            <mat-form-field class="filter">
              <input matInput formControlName="chartNo">
              <mat-placeholder>Chart No</mat-placeholder>
            </mat-form-field>
          </th>
          <td mat-cell *matCellDef="let element"> {{element?.chartNo}} </td>
        </ng-container> -->
        <ng-container matColumnDef="insuranceName">
          <th
            class="header"
            class="w-125 th-center"
            mat-header-cell
            *matHeaderCellDef
          >
            <mat-form-field class="filter">
              <input autocomplete="new-insuranceName" matInput formControlName="insuranceName" />
              <mat-placeholder>Insurance Name</mat-placeholder>
            </mat-form-field>
          </th>
          <td mat-cell *matCellDef="let element">
            {{ element?.insuranceName }}
          </td>
        </ng-container>
        <ng-container matColumnDef="suppliedDate">
          <th
            class="header"
            class="w-125 th-center"
            mat-header-cell
            *matHeaderCellDef
          >
            <mat-form-field class="filter">
              <input autocomplete="new-suppliedDate" matInput formControlName="suppliedDate" />
              <mat-placeholder>Supplied Date</mat-placeholder>
            </mat-form-field>
          </th>
          <td mat-cell *matCellDef="let element">
            {{ element?.suppliedDate }}
          </td>
        </ng-container>
        <ng-container matColumnDef="patientBalance">
          <th
            class="header"
            class="w-125 th-center"
            mat-header-cell
            *matHeaderCellDef
          >
            <mat-form-field class="filter">
              <input autocomplete="new-patientBalance" matInput formControlName="patientBalance" />
              <mat-placeholder>Patient Balance</mat-placeholder>
            </mat-form-field>
          </th>
          <td mat-cell *matCellDef="let element">
            {{ element?.patientBalance }}
          </td>
        </ng-container>

        <ng-container matColumnDef="insuranceBalance">
          <th
            class="header"
            class="w-125 th-center"
            mat-header-cell
            *matHeaderCellDef
          >
            <mat-form-field class="filter">
              <input autocomplete="new-insuranceBalance" matInput formControlName="insuranceBalance" />
              <mat-placeholder>Insurance Balance</mat-placeholder>
            </mat-form-field>
          </th>
          <td mat-cell *matCellDef="let element">
            {{ element?.insuranceBalance }}
          </td>
        </ng-container>
        <ng-container matColumnDef="totalBalance">
          <th
            class="header"
            class="w-125 th-center"
            mat-header-cell
            *matHeaderCellDef
          >
            <mat-form-field class="filter">
              <input autocomplete="new-totalBalance" matInput formControlName="totalBalance" />
              <mat-placeholder>Total Balance</mat-placeholder>
            </mat-form-field>
          </th>
          <td mat-cell *matCellDef="let element">
            {{ element?.totalBalance }}
          </td>
        </ng-container>
        <ng-container matColumnDef="email">
          <th
            class="header"
            class="w-125 th-center"
            mat-header-cell
            *matHeaderCellDef
          >
            <mat-form-field class="filter">
              <input autocomplete="new-email" matInput formControlName="email" />
              <mat-placeholder>Email</mat-placeholder>
            </mat-form-field>
          </th>
          <td mat-cell *matCellDef="let element">{{ element?.email }}</td>
        </ng-container>
        <ng-container matColumnDef="shippingLocation">
          <th
            class="header"
            class="w-125 th-center"
            mat-header-cell
            *matHeaderCellDef
          >
            <mat-form-field class="filter">
              <input autocomplete="new-shippingLocation" matInput formControlName="shippingLocation" />
              <mat-placeholder>Shipping Location</mat-placeholder>
            </mat-form-field>
          </th>
          <td mat-cell *matCellDef="let element">
            {{ element?.shippingLocation }}
          </td>
        </ng-container>

        <ng-container matColumnDef="status">
          <th
            class="header"
            class="w-125 th-center"
            mat-header-cell
            *matHeaderCellDef
          >
            <mat-form-field class="filter">
              <input autocomplete="new-status" matInput formControlName="status" />
              <mat-placeholder>Status</mat-placeholder>
            </mat-form-field>
          </th>
          <td mat-cell *matCellDef="let element">{{ element?.status }}</td>
        </ng-container>
        <ng-container matColumnDef="createdDate">
          <th
            class="header"
            class="w-125 th-center"
            mat-header-cell
            *matHeaderCellDef
          >
            <mat-form-field class="filter">
              <input autocomplete="new-createdDate" matInput formControlName="createdDate" />
              <mat-placeholder>Created Date</mat-placeholder>
            </mat-form-field>
          </th>
          <td mat-cell *matCellDef="let element">{{ element?.createdDate }}</td>
        </ng-container>
        <ng-container matColumnDef="options">
          <th class="header" mat-header-cell *matHeaderCellDef>Options</th>
          <td mat-cell *matCellDef="let element">
            <a class="editBtnColor" (click)="ViewButtonHitted(element)">
              <i class="fa fa-eye"></i>
            </a>
          </td>
        </ng-container>
        <ng-container matColumnDef="noRecords">
          <td
            class="text-align-center"
            mat-footer-cell
            *matFooterCellDef
            colspan="13"
          >
            No Records Found
          </td>
        </ng-container>
        <ng-container matColumnDef="paginator">
          <td mat-footer-cell *matFooterCellDef colspan="13">
            <mat-paginator
              class="table table-responsive mat-table-class"
              #MatPaginator
              [length]="dataSource?.data?.length"
              [pageSize]="10"
              [pageIndex]="0"
              showFirstLastButtons
              [pageSizeOptions]="[5, 10, 25, 100]"
            >
            </mat-paginator>
          </td>
        </ng-container>
      </form>
    </div>
    <div *ngSwitchCase="'billingWarehouseList'">
      <form [formGroup]="billingwarehouseForm">
        <ng-container matColumnDef="billingId">
          <th class="w-125 th-center" mat-header-cell *matHeaderCellDef>
            <mat-form-field class="filter">
              <input autocomplete="new-billingId"
                matInput
                formControlName="billingId"
                type="number"
                min="0"
              />
              <mat-placeholder class="center">Billing Id</mat-placeholder>
            </mat-form-field>
          </th>
          <td mat-cell *matCellDef="let element">{{ element?.billingId }}</td>
        </ng-container>

        <ng-container matColumnDef="patientId">
          <th class="w-125 th-center" mat-header-cell *matHeaderCellDef>
            <mat-form-field class="filter">
              <input autocomplete="new-patientId" matInput formControlName="patientId" />
              <mat-placeholder>Patient Id</mat-placeholder>
            </mat-form-field>
          </th>
          <td mat-cell *matCellDef="let element">{{ element?.patientId }}</td>
        </ng-container>
        <ng-container matColumnDef="patientName">
          <th
            class="header"
            class="w-125 th-center"
            mat-header-cell
            *matHeaderCellDef
          >
            <mat-form-field class="filter">
              <input autocomplete="new-patientName" matInput formControlName="patientName" />
              <mat-placeholder>Patient Name</mat-placeholder>
            </mat-form-field>
          </th>
          <td mat-cell *matCellDef="let element">{{ element?.patientName }}</td>
        </ng-container>
        <ng-container matColumnDef="chartNo">
          <th
            class="header"
            class="w-125 th-center"
            mat-header-cell
            *matHeaderCellDef
          >
            <mat-form-field class="filter">
              <input autocomplete="new-chartNo" matInput formControlName="chartNo" />
              <mat-placeholder>Chart No</mat-placeholder>
            </mat-form-field>
          </th>
          <td mat-cell *matCellDef="let element">{{ element?.chartNo }}</td>
        </ng-container>
        <ng-container matColumnDef="insuranceName">
          <th
            class="header"
            class="w-125 th-center"
            mat-header-cell
            *matHeaderCellDef
          >
            <mat-form-field class="filter">
              <input autocomplete="new-insuranceName" matInput formControlName="insuranceName" />
              <mat-placeholder>Insurance Name</mat-placeholder>
            </mat-form-field>
          </th>
          <td mat-cell *matCellDef="let element">
            {{ element?.insuranceName }}
          </td>
        </ng-container>
        <ng-container matColumnDef="suppliedDate">
          <th
            class="header"
            class="w-125 th-center"
            mat-header-cell
            *matHeaderCellDef
          >
            <mat-form-field class="filter">
              <input autocomplete="new-suppliedDate" matInput formControlName="suppliedDate" />
              <mat-placeholder>Supplied Date</mat-placeholder>
            </mat-form-field>
          </th>
          <td mat-cell *matCellDef="let element">
            {{ element?.suppliedDate }}
          </td>
        </ng-container>
        <ng-container matColumnDef="patientBalance">
          <th
            class="header"
            class="w-125 th-center"
            mat-header-cell
            *matHeaderCellDef
          >
            <mat-form-field class="filter">
              <input autocomplete="new-patientBalance" matInput formControlName="patientBalance" />
              <mat-placeholder>Patient Balance</mat-placeholder>
            </mat-form-field>
          </th>
          <td mat-cell *matCellDef="let element">
            {{ element?.patientBalance }}
          </td>
        </ng-container>
        <ng-container matColumnDef="insuranceBalance">
          <th
            class="header"
            class="w-125 th-center"
            mat-header-cell
            *matHeaderCellDef
          >
            <mat-form-field class="filter">
              <input autocomplete="new-insuranceBalance" matInput formControlName="insuranceBalance" />
              <mat-placeholder>Insurance Balance</mat-placeholder>
            </mat-form-field>
          </th>
          <td mat-cell *matCellDef="let element">
            {{ element?.insuranceBalance }}
          </td>
        </ng-container>
        <ng-container matColumnDef="totalBalance">
          <th
            class="header"
            class="w-125 th-center"
            mat-header-cell
            *matHeaderCellDef
          >
            <mat-form-field class="filter">
              <input autocomplete="new-totalBalance" matInput formControlName="totalBalance" />
              <mat-placeholder>Total Balance</mat-placeholder>
            </mat-form-field>
          </th>
          <td mat-cell *matCellDef="let element">
            {{ element?.totalBalance }}
          </td>
        </ng-container>
        <ng-container matColumnDef="email">
          <th
            class="header"
            class="w-125 th-center"
            mat-header-cell
            *matHeaderCellDef
          >
            <mat-form-field class="filter">
              <input autocomplete="new-email" matInput formControlName="email" />
              <mat-placeholder>Email</mat-placeholder>
            </mat-form-field>
          </th>
          <td mat-cell *matCellDef="let element">{{ element?.email }}</td>
        </ng-container>
        <ng-container matColumnDef="shippingLocation">
          <th
            class="header"
            class="w-125 th-center"
            mat-header-cell
            *matHeaderCellDef
          >
            <mat-form-field class="filter">
              <input autocomplete="new-shippingLocation" matInput formControlName="shippingLocation" />
              <mat-placeholder>Shipping Location</mat-placeholder>
            </mat-form-field>
          </th>
          <td mat-cell *matCellDef="let element">
            {{ element?.shippingLocation }}
          </td>
        </ng-container>
        <ng-container matColumnDef="status">
          <th
            class="header"
            class="w-125 th-center"
            mat-header-cell
            *matHeaderCellDef
          >
            <mat-form-field class="filter">
              <input autocomplete="new-status" matInput formControlName="status" />
              <mat-placeholder>Status</mat-placeholder>
            </mat-form-field>
          </th>
          <td mat-cell *matCellDef="let element">{{ element?.status }}</td>
        </ng-container>
        <ng-container matColumnDef="createdDate">
          <th
            class="header"
            class="w-125 th-center"
            mat-header-cell
            *matHeaderCellDef
          >
            <mat-form-field class="filter">
              <input autocomplete="new-createdDate" matInput formControlName="createdDate" />
              <mat-placeholder>Created Date</mat-placeholder>
            </mat-form-field>
          </th>
          <td mat-cell *matCellDef="let element">{{ element?.createdDate }}</td>
        </ng-container>
        <ng-container matColumnDef="warehouse">
          <th
            class="header"
            class="w-125 th-center"
            mat-header-cell
            *matHeaderCellDef
          >
            <mat-form-field class="filter">
              <input autocomplete="new-WipStatus" matInput />
              <mat-placeholder>Wip Status</mat-placeholder>
            </mat-form-field>
          </th>
          <td mat-cell *matCellDef="let element">
            <span *ngIf="showValue">{{ element?.wipStateId }}</span>
            <mat-select
              *ngIf="showValue1"
              placeholder="Select"
              formControlName="warehouse"
              maxlength="40"
            >
              <mat-option
                *ngFor="let status of drpwarehouse"
                [value]="status.wipStatusType"
              >
                {{ status.wipStatusType }}
              </mat-option>
            </mat-select>
          </td>
        </ng-container>
        <ng-container matColumnDef="options">
          <th class="header" mat-header-cell *matHeaderCellDef>Options</th>
          <td mat-cell *matCellDef="let element">
            <span
              *ngIf="enableEdit"
              (click)="cancelWip()"
              (click)="enableEdit = false"
              class="editBtnColor"
              ng-click="cancel()"
            >
              <i class="fa fa-close mr-2"></i
            ></span>
            <span
              *ngIf="enableEdit"
              id="saveBtn"
              class="editBtnColor"
              type="submit"
              ><i class="fa fa-check"></i
            ></span>
            <a
              class="editBtnColor"
              (click)="showWip()"
              *ngIf="!enableEdit"
              (click)="enableEdit = true"
            >
              <i
                class="fa fa-pencil"
                uib-tooltip="Edit"
                tooltip-trigger="mouseenter"
                tooltip-append-to-body="true"
                tooltip-placement="left"
              ></i>
            </a>
          </td>
        </ng-container>
        <ng-container matColumnDef="noRecords">
          <td
            class="text-align-center"
            class="text-align-center"
            mat-footer-cell
            *matFooterCellDef
            colspan="14"
          >
            No Records Found
          </td>
        </ng-container>
        <ng-container matColumnDef="paginator">
          <td mat-footer-cell *matFooterCellDef colspan="14">
            <mat-paginator
              class="table table-responsive mat-table-class"
              #MatPaginator
              [length]="dataSource?.data?.length"
              [pageSize]="10"
              [pageIndex]="0"
              showFirstLastButtons
              [pageSizeOptions]="[5, 10, 25, 100]"
            >
            </mat-paginator>
          </td>
        </ng-container>
      </form>
    </div>

    <div *ngSwitchCase="'claimTransaction'">
      <form [formGroup]="claimTransactionForm">
        <ng-container matColumnDef="Code">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            <b>Code</b>
          </th>
          <td mat-cell *matCellDef="let datas">
            {{ datas.code }}
          </td>
        </ng-container>
        <ng-container matColumnDef="M1">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            <b>M1</b>
          </th>
          <td mat-cell *matCellDef="let datas">
            <ng-container>
              {{ datas.m1 }}
            </ng-container>
          </td>
        </ng-container>
        <ng-container matColumnDef="M2">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            <b>M2</b>
          </th>
          <td mat-cell *matCellDef="let datas">
            {{ datas.m2 }}
          </td>
        </ng-container>
        <ng-container matColumnDef="M3">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            <b>M3</b>
          </th>
          <td mat-cell *matCellDef="let datas">
            {{ datas.m3 }}
          </td>
        </ng-container>
        <ng-container matColumnDef="pos">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            <b>POS</b>
          </th>
          <td mat-cell *matCellDef="let datas">
            {{ datas.pos }}
          </td>
        </ng-container>
        <!-- <ng-container matColumnDef="decription">
        <th  mat-header-cell *matHeaderCellDef mat-sort-header>
          <b>Description</b>
        </th>
        <td  mat-cell *matCellDef="let datas">{{ datas.description }}</td>
      </ng-container> -->
        <ng-container matColumnDef="dos">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            <b>DOS</b>
          </th>
          <td mat-cell *matCellDef="let datas">
            {{ datas.dos }}
          </td>
        </ng-container>
        <ng-container matColumnDef="provider">
          <th class="header" mat-header-cell *matHeaderCellDef>
            <b> Provider</b>
          </th>
          <td mat-cell *matCellDef="let element">{{ element?.provider }}</td>
        </ng-container>
        <ng-container matColumnDef="free">
          <th class="header" mat-header-cell *matHeaderCellDef>
            <b> Fee</b>
          </th>
          <td mat-cell *matCellDef="let element">{{ element?.fee }}</td>
        </ng-container>
        <ng-container matColumnDef="units">
          <th class="header" mat-header-cell *matHeaderCellDef>
            <b> Units</b>
          </th>
          <td mat-cell *matCellDef="let element">{{ element?.units }}</td>
        </ng-container>
        <ng-container matColumnDef="plan">
          <th class="header" mat-header-cell *matHeaderCellDef>
            <b> Plan</b>
          </th>
          <td mat-cell *matCellDef="let element">
            <span *ngIf="showClaim">{{ element.plan }}</span>
            <mat-select *ngIf="showClaim1" multiple formControlName="txtPlan">
              <mat-option
                *ngFor="let code of drpinsurance"
                placeholder="Select"
                [value]="code.policy + '/' + code.payorLevel"
              >
                {{ code.policy + "/" + code.payorLevel }}
              </mat-option>
            </mat-select>
          </td>
        </ng-container>

        <ng-container matColumnDef="status">
          <th class="header" mat-header-cell *matHeaderCellDef>
            <b> Status</b>
          </th>
          <td mat-cell *matCellDef="let element">
            <span *ngIf="showClaim">{{ element.statusType }}</span>
            <mat-select
              *ngIf="showClaim1"
              placeholder="Select"
              formControlName="Status"
            >
              <mat-option
                *ngFor="let element of drpStatus"
                [value]="element.statusType"
              >
                {{ element.statusType }}
              </mat-option>
            </mat-select>
            <!-- {{element.statusType}} -->
          </td>
        </ng-container>
        <ng-container matColumnDef="hr">
          <th class="header" mat-header-cell *matHeaderCellDef>
            <b> H-R</b>
          </th>
          <td mat-cell *matCellDef="let element">
            {{ element?.claistatusmid }}
          </td>
        </ng-container>

        <!-- <ng-container matColumnDef="options">
        <th class="header" mat-header-cell *matHeaderCellDef>
          Options
        </th>
        <td mat-cell *matCellDef="let element">
          <a  class="editBtnColor" (click)="savePosting(element.id,element.postingChargesId)"
            type="submit"><i class="fa fa-check"></i></a>
        </td>
      </ng-container> -->
        <ng-container matColumnDef="options">
          <th class="header" mat-header-cell *matHeaderCellDef>Options</th>
          <td mat-cell *matCellDef="let element">
            <span
              *ngIf="enableEdits"
              (click)="cancelClaim()"
              (click)="enableEdits = false"
              class="editBtnColor"
              ng-click="cancelClaim()"
            >
              <i class="fa fa-close mr-2"></i
            ></span>
            <span
              *ngIf="enableEdits"
              id="saveBtn"
              class="editBtnColor"
              (click)="savePosting(element.id, element.postingChargesId)"
              type="submit"
              ><i class="fa fa-check"></i
            ></span>
            <a
              class="editBtnColor"
              (click)="showClaims()"
              *ngIf="!enableEdits"
              (click)="enableEdits = true"
            >
              <i
                class="fa fa-pencil"
                uib-tooltip="Edit"
                tooltip-trigger="mouseenter"
                tooltip-append-to-body="true"
                tooltip-placement="left"
              ></i>
            </a>
          </td>
        </ng-container>

        <ng-container matColumnDef="noRecords">
          <td mat-footer-cell *matFooterCellDef colspan="13">
            <b>No Records Found</b>
          </td>
        </ng-container>
        <ng-container matColumnDef="paginator">
          <td mat-footer-cell *matFooterCellDef colspan="13">
            <mat-paginator
              class="table table-responsive mat-table-class"
              #MatPaginator
              [length]="dataSource?.data?.length"
              [pageSize]="10"
              [pageIndex]="0"
              showFirstLastButtons
              [pageSizeOptions]="[5, 10, 25, 100]"
            >
            </mat-paginator>
          </td>
        </ng-container>
      </form>
    </div>
    <div *ngSwitchCase="'patientLedger'">
      <form style="display: flex" [formGroup]="patientLedgerForm">
        <ng-container matColumnDef="ticketId">
          <th class="header" mat-header-cell *matHeaderCellDef>
            <mat-form-field class="filter">
              <input autocomplete="new-ticketId"
                matInput
                formControlName="ticketId"
                type="number"
                min="0"
              />
              <mat-placeholder class="center">Ticket Id</mat-placeholder>
            </mat-form-field>
          </th>
          <td mat-cell *matCellDef="let element">{{ element?.ticketId }}</td>
        </ng-container>

        <ng-container matColumnDef="patientId">
          <th class="header" mat-header-cell *matHeaderCellDef>
            <mat-form-field class="filter">
              <input autocomplete="new-patientId" matInput formControlName="patientId" />
              <mat-placeholder>Patient Id</mat-placeholder>
            </mat-form-field>
          </th>
          <td mat-cell *matCellDef="let element">{{ element?.patientId }}</td>
        </ng-container>
        <ng-container matColumnDef="patientName">
          <th class="header" mat-header-cell *matHeaderCellDef>
            <mat-form-field class="filter">
              <input autocomplete="new-patientName" matInput formControlName="patientName" />
              <mat-placeholder>Patient Name</mat-placeholder>
            </mat-form-field>
          </th>
          <td mat-cell *matCellDef="let element">{{ element?.patientName }}</td>
        </ng-container>
        <ng-container matColumnDef="chartNo">
          <th class="header" mat-header-cell *matHeaderCellDef>
            <mat-form-field class="filter">
              <input autocomplete="new-chartNo" matInput formControlName="chartNo" />
              <mat-placeholder>Chart No</mat-placeholder>
            </mat-form-field>
          </th>
          <td mat-cell *matCellDef="let element">{{ element?.chartNo }}</td>
        </ng-container>
        <ng-container matColumnDef="insuranceName">
          <th class="header" mat-header-cell *matHeaderCellDef>
            <mat-form-field class="filter">
              <input autocomplete="new-insuranceName" matInput formControlName="insuranceName" />
              <mat-placeholder>Insurance Name</mat-placeholder>
            </mat-form-field>
          </th>
          <td mat-cell *matCellDef="let element">
            {{ element?.insuranceName }}
          </td>
        </ng-container>
        <ng-container matColumnDef="suppliedDate">
          <th class="header" mat-header-cell *matHeaderCellDef>
            <mat-form-field class="filter">
              <input autocomplete="new-suppliedDate" matInput formControlName="suppliedDate" />
              <mat-placeholder>Supplied Date</mat-placeholder>
            </mat-form-field>
          </th>
          <td mat-cell *matCellDef="let element">
            {{ element?.suppliedDate }}
          </td>
        </ng-container>
        <ng-container matColumnDef="patientBalance">
          <th class="header" mat-header-cell *matHeaderCellDef>
            <mat-form-field class="filter">
              <input autocomplete="new-patientBalance" matInput formControlName="patientBalance" />
              <mat-placeholder>Patient Balance</mat-placeholder>
            </mat-form-field>
          </th>
          <td mat-cell *matCellDef="let element">
            {{ element?.patientBalance }}
          </td>
        </ng-container>

        <ng-container matColumnDef="insuranceBalance">
          <th class="header" mat-header-cell *matHeaderCellDef>
            <mat-form-field class="filter">
              <input autocomplete="new-insuranceBalance" matInput formControlName="insuranceBalance" />
              <mat-placeholder>Insurance Balance</mat-placeholder>
            </mat-form-field>
          </th>
          <td mat-cell *matCellDef="let element">
            {{ element?.insuranceBalance }}
          </td>
        </ng-container>
        <ng-container matColumnDef="totalBalance">
          <th class="header" mat-header-cell *matHeaderCellDef>
            <mat-form-field class="filter">
              <input autocomplete="new-totalBalance" matInput formControlName="totalBalance" />
              <mat-placeholder>Total Balance</mat-placeholder>
            </mat-form-field>
          </th>
          <td mat-cell *matCellDef="let element">
            {{ element?.totalBalance }}
          </td>
        </ng-container>
        <ng-container matColumnDef="email">
          <th class="header" mat-header-cell *matHeaderCellDef>
            <mat-form-field class="filter">
              <input autocomplete="new-email" matInput formControlName="email" />
              <mat-placeholder>Email</mat-placeholder>
            </mat-form-field>
          </th>
          <td mat-cell *matCellDef="let element">{{ element?.email }}</td>
        </ng-container>
        <ng-container matColumnDef="shippingLocation">
          <th class="header" mat-header-cell *matHeaderCellDef>
            <mat-form-field class="filter">
              <input autocomplete="new-shippingLocation" matInput formControlName="shippingLocation" />
              <mat-placeholder>Shipping Location</mat-placeholder>
            </mat-form-field>
          </th>
          <td mat-cell *matCellDef="let element">
            {{ element?.shippingLocation }}
          </td>
        </ng-container>
        <ng-container matColumnDef="status">
          <th class="header" mat-header-cell *matHeaderCellDef>
            <mat-form-field class="filter">
              <input autocomplete="new-status" matInput formControlName="status" />
              <mat-placeholder>Status</mat-placeholder>
            </mat-form-field>
          </th>
          <td mat-cell *matCellDef="let element">{{ element?.status }}</td>
        </ng-container>
        <ng-container matColumnDef="csr">
          <th class="header" mat-header-cell *matHeaderCellDef>
            <mat-form-field class="filter">
              <input autocomplete="new-csr" matInput formControlName="csr" />
              <mat-placeholder>CSR</mat-placeholder>
            </mat-form-field>
          </th>
          <td mat-cell *matCellDef="let element">{{ element?.csr }}</td>
        </ng-container>

        <ng-container matColumnDef="createdDate">
          <th class="header" mat-header-cell *matHeaderCellDef>
            <mat-form-field class="filter">
              <input autocomplete="new-createdDate" matInput formControlName="createdDate" />
              <mat-placeholder>Created Date</mat-placeholder>
            </mat-form-field>
          </th>
          <td mat-cell *matCellDef="let element">{{ element?.createdDate }}</td>
        </ng-container>

        <ng-container matColumnDef="options">
          <th class="header" mat-header-cell *matHeaderCellDef>Options</th>
          <td mat-cell *matCellDef="let element">
            <a
              [hidden]="element.orderStatus"
              class="editBtnColor eyeCursorclass"
              (click)="ViewButtonHitted(element.id)"
            >
              <i class="fa fa-eye"></i>
            </a>
          </td>
        </ng-container>

        <ng-container matColumnDef="noRecords">
          <td
            class="text-align-center"
            mat-footer-cell
            *matFooterCellDef
            colspan="14"
          >
            No Records Found
          </td>
        </ng-container>
        <ng-container matColumnDef="paginator">
          <td mat-footer-cell *matFooterCellDef colspan="14">
            <mat-paginator
              class="table table-responsive mat-table-class"
              #MatPaginator
              [length]="dataSource?.data?.length"
              [pageSize]="10"
              [pageIndex]="0"
              showFirstLastButtons
              [pageSizeOptions]="[5, 10, 25, 100]"
            >
            </mat-paginator>
          </td>
        </ng-container>
      </form>
    </div>
    <div *ngSwitchCase="'paymentHistory'">
      <ng-container matColumnDef="saleorderId">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Saleorder Id</th>
        <td mat-cell *matCellDef="let datas">
          {{ datas.saleorderId }}
        </td>
      </ng-container>
      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Status</th>
        <td mat-cell *matCellDef="let datas">
          <ng-container>
            <span class="bckgreen mr-2">{{ datas.readyCount }}</span>
            <span class="bckred mr-2">{{ datas.processedCount }}</span>
            <span class="bckreds mr-2">{{ datas.holdCount }}</span>
            <span class="bckyellow mr-2">{{ datas.crossOverCount }}</span>

            <!-- <span >{{ datas.patientResponsibilityCount }}</span>

            <span >{{ datas.patientResponsibilityAfterInsCount }}</span> -->

            {{ datas.status }}
          </ng-container>
        </td>
      </ng-container>
      <ng-container matColumnDef="day">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>DaySheet</th>
        <td mat-cell *matCellDef="let datas">
          {{ datas.daySheet }}
        </td>
      </ng-container>
      <!-- <ng-container matColumnDef="dos">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          Payer
        </th>
        <td mat-cell *matCellDef="let datas">
          {{ datas.payer }}
        </td>
      </ng-container>
      <ng-container matColumnDef="provider">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          Payee
        </th>
        <td mat-cell *matCellDef="let datas">
          {{ datas.payee }}
        </td>
      </ng-container> -->
      <ng-container matColumnDef="qty">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>#</th>
        <td mat-cell *matCellDef="let datas">{{ datas.data }}</td>
      </ng-container>
      <ng-container matColumnDef="totalFee">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>TotalFee</th>
        <td class="verticalAlignColumn color" mat-cell *matCellDef="let datas">
          {{ datas.totalFee }}
        </td>
      </ng-container>
      <ng-container matColumnDef="balance">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Balance</th>
        <td class="verticalAlignColumn color" mat-cell *matCellDef="let datas">
          ${{ datas?.patBal || 0 }}
        </td>
      </ng-container>

      <ng-container matColumnDef="ins">
        <th class="header" mat-header-cell *matHeaderCellDef>Ins Bal</th>
        <td class="verticalAlignColumn color" mat-cell *matCellDef="let datas">
          ${{ datas?.patBal || 0 }}
        </td>
      </ng-container>
      <ng-container matColumnDef="totalBilledAmount">
        <th class="header" mat-header-cell *matHeaderCellDef>Total Billed</th>
        <td class="verticalAlignColumn color" mat-cell *matCellDef="let datas">
          ${{ datas?.totalBilledAmount || 0 }}
        </td>
      </ng-container>
      <ng-container matColumnDef="totalInsurancePaidAmount">
        <th class="header" mat-header-cell *matHeaderCellDef>
          Total Insurance Paid
        </th>
        <td class="verticalAlignColumn color" mat-cell *matCellDef="let datas">
          ${{ datas?.totalPatientBal || 0 }}
        </td>
      </ng-container>
      <ng-container matColumnDef="totalPatientAmount">
        <th class="header" mat-header-cell *matHeaderCellDef>
          Total Patient Balance
        </th>
        <td class="verticalAlignColumn color" mat-cell *matCellDef="let datas">
          ${{ datas?.totalPatientAmount || 0 }}
        </td>
      </ng-container>
      <ng-container matColumnDef="checkNo">
        <th class="header" mat-header-cell *matHeaderCellDef>Check No</th>
        <td class="verticalAlignColumn color" mat-cell *matCellDef="let datas">
          {{ datas?.checkNo }}
        </td>
      </ng-container>
      <ng-container matColumnDef="patientId">
        <th class="header" mat-header-cell *matHeaderCellDef>Patient Id</th>
        <td mat-cell *matCellDef="let datas">{{ datas?.patientId }}</td>
      </ng-container>
      <ng-container matColumnDef="patientName">
        <th class="header" mat-header-cell *matHeaderCellDef>Patient Name</th>
        <td mat-cell *matCellDef="let datas">{{ datas?.patientName }}</td>
      </ng-container>
      <ng-container matColumnDef="claim">
        <th class="header" mat-header-cell *matHeaderCellDef>Claim Id</th>
        <td mat-cell *matCellDef="let datas">{{ datas?.claimid }}</td>
      </ng-container>

      <ng-container matColumnDef="Options">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Options</th>
        <td mat-cell *matCellDef="let datas">
          <ng-container
            *ngIf="
              pageType === 'paymentHistory' && strPageType === 'paymentHistory'
            "
          >
            <ng-container
              *ngIf="
                !datas?.cashPayment &&
                datas?.isClaimHistoryTable &&
                datas?.billingStatus === 'Processed' &&
                datas?.payPalPaymentStatus !== null
              "
            >
              <button
                mat-button
                class="btn btn-sm lightSeaGreen-dot"
                (click)="addPayPalCheckoutButtonHitted(datas)"
              >
                PayPal {{ datas?.payPalPaymentStatus }}
              </button>
            </ng-container>
          </ng-container>
          <br />
          <!-- <ng-container *ngIf="pageType==='paymentHistory' && strPageType==='paymentHistory'">
            <ng-container *ngIf="(!datas?.cashPayment) && datas?.isClaimHistoryTable">
              <button mat-button class="btn btn-sm lightSeaGreen-dot" (click)="addPayPalInvoiceButtonHitted(datas)">
                Invoice {{datas?.payPalInvoicePaymentStatus }}
              </button>
            </ng-container>
          </ng-container> -->
          <a
            *ngIf="
              pageType === 'paymentHistory' && strPageType === 'paymentHistory'
            "
            class="eyeCursorclass customThemeClass mr-3"
            (click)="addButtonHitted(datas)"
            target="_blank"
          >
            <i class="fa fa-eye"></i
          ></a>
          <a
            *ngIf="
              pageType === 'paymentHistory' && strPageType === 'paymentHistory'
            "
            class="ml-1 eyeCursorclass customThemeClass mr-3"
            (click)="ViewButtonHitted(datas.id)"
            target="_blank"
          >
            <i class="fa fa-commenting-o"></i
          ></a>
          <a
            *ngIf="
              pageType === 'paymentHistory' && strPageType === 'paymentHistory'
            "
            class="eyeCursorclass customThemeClass mr-3"
            (click)="addReminderButtonHitted(datas)"
            target="_blank"
          >
            <i class="fa fa-bell"></i
          ></a>
        </td>
      </ng-container>
      <ng-container matColumnDef="noRecords">
        <td
          class="text-align-center"
          mat-footer-cell
          *matFooterCellDef
          colspan="15"
        >
          <div *ngIf="!isLoading">
            {{ "No Records Found" }}
          </div>
          <div [hidden]="!isLoading">
            <mat-spinner [diameter]="220"></mat-spinner>
          </div>
        </td>
      </ng-container>
      <ng-container matColumnDef="paginator">
        <td mat-footer-cell *matFooterCellDef colspan="15">
          <mat-paginator
            class="table table-responsive mat-table-class"
            #MatPaginator
            [length]="dataSource?.data?.length"
            [pageSize]="10"
            [pageIndex]="0"
            showFirstLastButtons
            [pageSizeOptions]="[5, 10, 25, 100]"
          >
          </mat-paginator>
        </td>
      </ng-container>
    </div>

    <div *ngSwitchCase="'claimStatusList'">
      <ng-container matColumnDef="patientId">
        <th class="header" mat-header-cell *matHeaderCellDef>Patient Id</th>
        <td mat-cell *matCellDef="let element">{{ element?.patientId }}</td>
      </ng-container>
      <ng-container matColumnDef="patientName">
        <th class="header" mat-header-cell *matHeaderCellDef>Patient Name</th>
        <td mat-cell *matCellDef="let element">{{ element?.patientName }}</td>
      </ng-container>
      <ng-container matColumnDef="payerID">
        <th class="header" mat-header-cell *matHeaderCellDef>Payer Id</th>
        <td mat-cell *matCellDef="let element">{{ element?.payerID }}</td>
      </ng-container>
      <ng-container matColumnDef="payerName">
        <th class="header" mat-header-cell *matHeaderCellDef>Payer Name</th>
        <td mat-cell *matCellDef="let element">{{ element?.payerName }}</td>
      </ng-container>
      <ng-container matColumnDef="claimServiceDate">
        <th class="header" mat-header-cell *matHeaderCellDef>
          Claim Service Date
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element?.claimServiceDate }}
        </td>
      </ng-container>
      <ng-container matColumnDef="billingName">
        <th class="header" mat-header-cell *matHeaderCellDef>Provider Name</th>
        <td mat-cell *matCellDef="let element">{{ element?.billingName }}</td>
      </ng-container>
      <!-- <ng-container matColumnDef="checkIssueDate">
        <th class="header" mat-header-cell *matHeaderCellDef>
          Check Issue Date
        </th>
        <td mat-cell *matCellDef="let element"> {{element?.checkIssueDate}} </td>
      </ng-container>
      <ng-container matColumnDef="checkNumber">
        <th class="header" mat-header-cell *matHeaderCellDef>
          Check Number
        </th>
        <td mat-cell *matCellDef="let element"> {{element?.checkNumber}} </td>
      </ng-container> -->
      <!-- <ng-container matColumnDef="paidDate">
        <th class="header" mat-header-cell *matHeaderCellDef>
          Paid Date
        </th>
        <td mat-cell *matCellDef="let element"> {{element?.paidDate}} </td>
      </ng-container> -->
      <!-- <ng-container matColumnDef="entity">
        <th class="header" mat-header-cell *matHeaderCellDef>
          Entity
        </th>
        <td mat-cell *matCellDef="let element"> {{element?.entity}} </td>
      </ng-container> -->
      <!-- <ng-container matColumnDef="trackingNumber">
        <th class="header" mat-header-cell *matHeaderCellDef>
          Tracking Number
        </th>
        <td mat-cell *matCellDef="let element"> {{element?.trackingNumber}} </td>
      </ng-container> -->
      <!-- <ng-container matColumnDef="tradingPartnerClaimNumber">
        <th class="header" mat-header-cell *matHeaderCellDef>
          Trading Partner Claim Number
        </th>
        <td mat-cell *matCellDef="let element"> {{element?.tradingPartnerClaimNumber}} </td>
      </ng-container> -->

      <!-- <ng-container matColumnDef="statusCodeValue">
        <th class="header" mat-header-cell *matHeaderCellDef>
          Status Code Value
        </th>
        <td mat-cell *matCellDef="let element"> {{element?.statusCodeValue}} </td>
      </ng-container> -->
      <ng-container matColumnDef="tradingPartnerClaimNumber">
        <th class="header" mat-header-cell *matHeaderCellDef>Claim Number</th>
        <td mat-cell *matCellDef="let element">{{ element?.claimID }}</td>
      </ng-container>
      <ng-container matColumnDef="status">
        <th class="header" mat-header-cell *matHeaderCellDef>Status</th>
        <td
          mat-cell
          *matCellDef="let element"
          [attr.colspan]="arrDisplayedColumns.length"
        >
          <div
            class="example-element-detail"
            [@detailExpand]="
              element == expandedElement ? 'expanded' : 'collapsed'
            "
          >
            <div class="example-element-description">
              {{ element.description }}
              <span class="example-element-description-attribution">
                {{ element?.status }}</span
              >
            </div>
          </div>
        </td></ng-container
      >

      <ng-container matColumnDef="noRecords">
        <td
          class="text-align-center"
          mat-footer-cell
          *matFooterCellDef
          colspan="11"
        >
          No Records Found
        </td>
      </ng-container>
      <ng-container matColumnDef="paginator">
        <td mat-footer-cell *matFooterCellDef colspan="11">
          <mat-paginator
            class="table table-responsive mat-table-class"
            #MatPaginator
            [length]="dataSource?.data?.length"
            [pageSize]="10"
            [pageIndex]="0"
            showFirstLastButtons
            [pageSizeOptions]="[5, 10, 25, 100]"
          >
          </mat-paginator>
        </td>
      </ng-container>
    </div>
    <div *ngSwitchCase="'insPayment2'">
      <ng-container matColumnDef="Code">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Code</th>
        <td mat-cell *matCellDef="let record">
          {{ record.item }}
        </td>
      </ng-container>
      <ng-container matColumnDef="Dos">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>DOS</th>
        <td mat-cell *matCellDef="let record">
          {{ record.dos }}
        </td>
      </ng-container>
      <ng-container matColumnDef="charge">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Charge</th>
        <td mat-cell *matCellDef="let record">
          {{ record.charge }}
        </td>
      </ng-container>
      <ng-container matColumnDef="allowed">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Allowed</th>
        <td mat-cell *matCellDef="let record">
          <mat-form-field>
            <input autocomplete="new-Allowed"
              matInput
              placeholder="Allowed"
              type="text"
              [(ngModel)]="record.allowed"
              (keyup)="record.writeOff = record.charge - record.allowed"
              (ngModelChange)="updatePaymentData(arrTableData)"
              maxlength="40"
            />
          </mat-form-field>
        </td>
      </ng-container>
      <ng-container matColumnDef="deduct">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Deduct</th>
        <td mat-cell *matCellDef="let record">
          <mat-form-field>
            <input autocomplete="new-Deduct"
              matInput
              placeholder="Deduct"
              [(ngModel)]="record.deduct"
              type="text"
              maxlength="40"
            />
          </mat-form-field>
        </td>
      </ng-container>

      <ng-container matColumnDef="copay">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Copay</th>
        <td mat-cell *matCellDef="let record">
          <mat-form-field>
            <input autocomplete="new-Copay"
              matInput
              placeholder="Copay"
              [(ngModel)]="record.coinsurance"
              type="text"
              maxlength="40"
            />
          </mat-form-field>
        </td>
      </ng-container>
      <ng-container matColumnDef="payment">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Payment</th>
        <td mat-cell *matCellDef="let record">
          <mat-form-field>
            <input autocomplete="new-Payment"
              matInput
              placeholder="Payment"
              [(ngModel)]="record.insurancePaid"
              (keyup)="record.insBal = record.allowed - record.insurancePaid"
              (ngModelChange)="updatePaymentData(arrTableData)"
              type="text"
              maxlength="40"
            />
          </mat-form-field>
        </td>
      </ng-container>

      <ng-container matColumnDef="writeOff">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Write Off</th>
        <td mat-cell *matCellDef="let record">
          <mat-form-field>
            <input autocomplete="new-writeOff"
              matInput
              placeholder="Write Off"
              [(ngModel)]="record.writeOff"
              type="text"
              maxlength="40"
            />
          </mat-form-field>
        </td>
      </ng-container>

      <ng-container matColumnDef="insBal">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Ins Bal</th>
        <td mat-cell *matCellDef="let record">
          <mat-form-field>
            <input autocomplete="new-insBal"
              matInput
              placeholder="Ins Bal"
              [(ngModel)]="record.insBal"
              (ngModelChange)="updatePaymentData(arrTableData)"
              type="text"
              maxlength="40"
            />
          </mat-form-field>
        </td>
      </ng-container>

      <ng-container matColumnDef="patBal">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Pat Bal</th>
        <td mat-cell *matCellDef="let record">
          <mat-form-field>
            <input autocomplete="new-patBal"
              matInput
              placeholder="Pat Bal"
              [(ngModel)]="record.patBal"
              type="text"
              maxlength="40"
            />
          </mat-form-field>
        </td>
      </ng-container>
      <ng-container matColumnDef="claim">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Claim</th>
        <td mat-cell *matCellDef="let record; let i = index">
          <mat-form-field>
            <!-- <input matInput placeholder="Claim" [(ngModel)]="record.claim" type="text" maxlength="40"> -->
            <mat-label> Claim <span class="asterisk">*</span> </mat-label>
            <mat-select
              maxlength="40"
              [(ngModel)]="record.claim"
              (ngModelChange)="updatePaymentData(arrTableData)"
            >
              <mat-option value="">No Action</mat-option>
              <mat-option value="Transfer to patient"
                >Transfer to patient</mat-option
              >
              <mat-option *ngIf="record.nextInsuracne" value="Moved to next ins"
                >Moved to next ins</mat-option
              >
            </mat-select>
          </mat-form-field>
        </td>
      </ng-container>
      <ng-container matColumnDef="noRecords">
        <td
          class="text-align-center"
          mat-footer-cell
          *matFooterCellDef
          colspan="3"
        >
          No Records Found
        </td>
      </ng-container>
      <ng-container matColumnDef="paginator">
        <td mat-footer-cell *matFooterCellDef colspan="3">
          <mat-paginator
            class="table table-responsive mat-table-class"
            #MatPaginator
            [length]="dataSource?.data?.length"
            [pageSize]="10"
            [pageIndex]="0"
            showFirstLastButtons
            [pageSizeOptions]="[5, 10, 25, 100]"
          >
          </mat-paginator>
        </td>
      </ng-container>
    </div>
    <div *ngSwitchCase="'editPayment'">
      <ng-container matColumnDef="Payment">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Payment</th>
        <td mat-cell *matCellDef="let record">
          {{ record.payment }}
        </td>
      </ng-container>
      <ng-container matColumnDef="Posted">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Posted</th>
        <td mat-cell *matCellDef="let record">
          {{ record.posted }}
        </td>
      </ng-container>
      <ng-container matColumnDef="user">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>User</th>
        <td mat-cell *matCellDef="let record">
          {{ record.user }}
        </td>
      </ng-container>
      <ng-container matColumnDef="code">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Code</th>
        <td mat-cell *matCellDef="let record">
          {{ record.code }}
        </td>
      </ng-container>
      <ng-container matColumnDef="description">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Description</th>
        <td mat-cell *matCellDef="let record">
          {{ record.description }}
        </td>
      </ng-container>
      <ng-container matColumnDef="check">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Check No</th>
        <td mat-cell *matCellDef="let record">
          {{ record.checkNo }}
        </td>
      </ng-container>
      <ng-container matColumnDef="fee">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Fee</th>
        <td mat-cell *matCellDef="let record">
          {{ record.fee }}
        </td>
      </ng-container>
      <ng-container matColumnDef="unapplied">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Un Applied</th>
        <td mat-cell *matCellDef="let record">
          {{ record.unapplied }}
        </td>
      </ng-container>
      <ng-container matColumnDef="options">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Options</th>

        <td mat-cell *matCellDef="let record">
          <a
            class="eyeCursorclass"
            (click)="ViewButtonHitted(record.id)"
            target="_blank"
            ><i class="fa fa-pencil"></i
          ></a>
        </td>
      </ng-container>

      <ng-container matColumnDef="noRecords">
        <td
          class="text-align-center"
          mat-footer-cell
          *matFooterCellDef
          colspan="8"
        >
          No Records Found
        </td>
      </ng-container>
      <ng-container matColumnDef="paginator">
        <td mat-footer-cell *matFooterCellDef colspan="8">
          <mat-paginator
            class="table table-responsive mat-table-class"
            #MatPaginator
            [length]="dataSource?.data?.length"
            [pageSize]="10"
            [pageIndex]="0"
            showFirstLastButtons
            [pageSizeOptions]="[5, 10, 25, 100]"
          >
          </mat-paginator>
        </td>
      </ng-container>
    </div>
    <div *ngSwitchCase="'eraPayment'">
      <ng-container matColumnDef="Payment">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Payment</th>
        <td mat-cell *matCellDef="let record">
          {{ record.createdBy }}
        </td>
      </ng-container>
      <ng-container matColumnDef="Patient">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Patient</th>
        <td mat-cell *matCellDef="let record">
          {{ record.dateCreated }}
        </td>
      </ng-container>
      <ng-container matColumnDef="acnt">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>ACNT</th>
        <td mat-cell *matCellDef="let record">
          {{ record.actualDate }}
        </td>
      </ng-container>
      <ng-container matColumnDef="icn">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>ICN</th>
        <td mat-cell *matCellDef="let record">
          {{ record.actualDate }}
        </td>
      </ng-container>
      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Status</th>
        <td mat-cell *matCellDef="let record">
          {{ record.actualDate }}
        </td>
      </ng-container>
      <ng-container matColumnDef="billed">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Billed</th>
        <td mat-cell *matCellDef="let record">
          {{ record.actualDate }}
        </td>
      </ng-container>
      <ng-container matColumnDef="paid">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Paid</th>
        <td mat-cell *matCellDef="let record">
          {{ record.actualDate }}
        </td>
      </ng-container>
      <ng-container matColumnDef="skip">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Skip</th>
        <td mat-cell *matCellDef="let record">
          <mat-checkbox value="{{ record.process }}"></mat-checkbox>
        </td>
      </ng-container>
      <ng-container matColumnDef="ok">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Ok</th>
        <td mat-cell *matCellDef="let record">
          <mat-checkbox value="{{ record.process }}"></mat-checkbox>
        </td>
      </ng-container>

      <ng-container matColumnDef="noRecords">
        <td
          class="text-align-center"
          mat-footer-cell
          *matFooterCellDef
          colspan="3"
        >
          No Records Found
        </td>
      </ng-container>
      <ng-container matColumnDef="paginator">
        <td mat-footer-cell *matFooterCellDef colspan="3">
          <mat-paginator
            class="table table-responsive mat-table-class"
            #MatPaginator
            [length]="dataSource?.data?.length"
            [pageSize]="10"
            [pageIndex]="0"
            showFirstLastButtons
            [pageSizeOptions]="[5, 10, 25, 100]"
          >
          </mat-paginator>
        </td>
      </ng-container>
    </div>
    <div *ngSwitchCase="'eraPayment2'">
      <ng-container matColumnDef="code">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Code</th>
        <td mat-cell *matCellDef="let record">
          {{ record.createdBy }}
        </td>
      </ng-container>
      <ng-container matColumnDef="cpt">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Cpt</th>
        <td mat-cell *matCellDef="let record">
          {{ record.dateCreated }}
        </td>
      </ng-container>
      <ng-container matColumnDef="dos">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Dos</th>
        <td mat-cell *matCellDef="let record">
          {{ record.actualDate }}
        </td>
      </ng-container>
      <ng-container matColumnDef="m1">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>M1</th>
        <td mat-cell *matCellDef="let record">
          {{ record.actualDate }}
        </td>
      </ng-container>
      <ng-container matColumnDef="m2">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>M2</th>
        <td mat-cell *matCellDef="let record">
          {{ record.actualDate }}
        </td>
      </ng-container>
      <ng-container matColumnDef="m3">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>M3</th>
        <td mat-cell *matCellDef="let record">
          {{ record.actualDate }}
        </td>
      </ng-container>
      <ng-container matColumnDef="m4">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>M4</th>
        <td mat-cell *matCellDef="let record">
          {{ record.actualDate }}
        </td>
      </ng-container>
      <ng-container matColumnDef="unit">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Unit</th>
        <td mat-cell *matCellDef="let record">
          <mat-checkbox value="{{ record.process }}"></mat-checkbox>
        </td>
      </ng-container>
      <ng-container matColumnDef="billed">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Billed</th>
        <td mat-cell *matCellDef="let record">
          <mat-checkbox value="{{ record.process }}"></mat-checkbox>
        </td>
      </ng-container>
      <ng-container matColumnDef="allowed">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Allowed</th>
        <td mat-cell *matCellDef="let record">
          <mat-checkbox value="{{ record.process }}"></mat-checkbox>
        </td>
      </ng-container>
      <ng-container matColumnDef="deduct">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Deduct</th>
        <td mat-cell *matCellDef="let record">
          <mat-checkbox value="{{ record.process }}"></mat-checkbox>
        </td>
      </ng-container>
      <ng-container matColumnDef="copay">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Copay</th>
        <td mat-cell *matCellDef="let record">
          <mat-checkbox value="{{ record.process }}"></mat-checkbox>
        </td>
      </ng-container>
      <ng-container matColumnDef="co-ins">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Co-ins</th>
        <td mat-cell *matCellDef="let record">
          <mat-checkbox value="{{ record.process }}"></mat-checkbox>
        </td>
      </ng-container>
      <ng-container matColumnDef="paid">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Paid</th>
        <td mat-cell *matCellDef="let record">
          <mat-checkbox value="{{ record.process }}"></mat-checkbox>
        </td>
      </ng-container>
      <ng-container matColumnDef="write">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>WriteOff</th>
        <td mat-cell *matCellDef="let record">
          <mat-checkbox value="{{ record.process }}"></mat-checkbox>
        </td>
      </ng-container>
      <ng-container matColumnDef="claim">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Claim</th>
        <td mat-cell *matCellDef="let record">
          <mat-checkbox value="{{ record.process }}"></mat-checkbox>
        </td>
      </ng-container>

      <ng-container matColumnDef="noRecords">
        <td
          class="text-align-center"
          mat-footer-cell
          *matFooterCellDef
          colspan="3"
        >
          No Records Found
        </td>
      </ng-container>
      <ng-container matColumnDef="paginator">
        <td mat-footer-cell *matFooterCellDef colspan="3">
          <mat-paginator
            class="table table-responsive mat-table-class"
            #MatPaginator
            [length]="dataSource?.data?.length"
            [pageSize]="10"
            [pageIndex]="0"
            showFirstLastButtons
            [pageSizeOptions]="[5, 10, 25, 100]"
          >
          </mat-paginator>
        </td>
      </ng-container>
    </div>
    <div *ngSwitchCase="'InsPayments'">
      <ng-container matColumnDef="Code">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Code</th>
        <td mat-cell *matCellDef="let record">
          {{ record.createdBy }}
        </td>
      </ng-container>
      <ng-container matColumnDef="description">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Description</th>
        <td mat-cell *matCellDef="let record">
          {{ record.dateCreated }}
        </td>
      </ng-container>
      <ng-container matColumnDef="date">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Date</th>
        <td mat-cell *matCellDef="let record">
          {{ record.actualDate }}
        </td>
      </ng-container>
      <ng-container matColumnDef="fee">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Fee</th>
        <td mat-cell *matCellDef="let record">
          {{ record.actualDate }}
        </td>
      </ng-container>

      <ng-container matColumnDef="noRecords">
        <td
          class="text-align-center"
          mat-footer-cell
          *matFooterCellDef
          colspan="3"
        >
          No Records Found
        </td>
      </ng-container>
      <ng-container matColumnDef="paginator">
        <td mat-footer-cell *matFooterCellDef colspan="3">
          <mat-paginator
            class="table table-responsive mat-table-class"
            #MatPaginator
            [length]="dataSource?.data?.length"
            [pageSize]="10"
            [pageIndex]="0"
            showFirstLastButtons
            [pageSizeOptions]="[5, 10, 25, 100]"
          >
          </mat-paginator>
        </td>
      </ng-container>
    </div>

    <div *ngSwitchCase="'addPosting2'">
      <ng-container matColumnDef="Code">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Item Name</th>
        <td mat-cell *matCellDef="let record">
          {{ record.code }}
        </td>
      </ng-container>
      <ng-container matColumnDef="dos">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>DOS</th>
        <td mat-cell *matCellDef="let record">
          {{ record.dateFrom | date : "MM/dd/yyyy h:mm a" : "en_US" }}
        </td>
      </ng-container>
      <ng-container matColumnDef="Units">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Units</th>
        <td mat-cell *matCellDef="let record">
          {{ record.units }}
        </td>
      </ng-container>

      <ng-container matColumnDef="price">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          Price Per Unit
        </th>
        <td mat-cell *matCellDef="let record">
          {{ record.fee }}
        </td>
      </ng-container>

      <ng-container matColumnDef="total">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Total Price</th>
        <td mat-cell *matCellDef="let record">
          {{ record.total }}
        </td>
      </ng-container>
      <ng-container matColumnDef="DOS">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>DOS</th>
        <td mat-cell *matCellDef="let record">
          {{ record.dos }}
        </td>
      </ng-container>
      <ng-container matColumnDef="M1">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>M1</th>
        <td mat-cell *matCellDef="let record">
          {{ record.m1 }}
        </td>
      </ng-container>
      <ng-container matColumnDef="M2">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>M2</th>
        <td mat-cell *matCellDef="let record">
          {{ record.m2 }}
        </td>
      </ng-container>
      <ng-container matColumnDef="M3">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>M3</th>
        <td mat-cell *matCellDef="let record">
          {{ record.m3 }}
        </td>
      </ng-container>
      <ng-container matColumnDef="M4">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>M4</th>
        <td mat-cell *matCellDef="let record">
          {{ record.m4 }}
        </td>
      </ng-container>
      <ng-container matColumnDef="POS">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>POS</th>
        <td mat-cell *matCellDef="let record">
          {{ record.fee }}
        </td>
      </ng-container>
      <ng-container matColumnDef="Fee">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Fee</th>
        <td mat-cell *matCellDef="let record">
          {{ record.fee }}
        </td>
      </ng-container>
      <ng-container matColumnDef="Diag9">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Diag9</th>
        <td mat-cell *matCellDef="let record">
          <div *ngFor="let item of record.diagnosis9 | keyvalue">
            <div *ngIf="item?.value['diagnosisCode']">
              {{ item?.value["diagnosisCode"] }}
            </div>
          </div>
        </td>
      </ng-container>
      <ng-container matColumnDef="Diag10">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Diag10</th>
        <td mat-cell *matCellDef="let record">
          <div *ngFor="let item of record.diagnosis10 | keyvalue">
            <div *ngIf="item?.value['diagnosisCode']">
              {{ item?.value["diagnosisCode"] }}
            </div>
          </div>
        </td>
      </ng-container>
      <ng-container matColumnDef="Provider">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Provider</th>
        <td mat-cell *matCellDef="let record">
          {{ record.provider }}
        </td>
      </ng-container>

      <ng-container matColumnDef="Options">
        <th
          class="verticalAlignColumn"
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
        >
          Options
        </th>
        <td mat-cell *matCellDef="let data">
          <a
            (click)="ViewButtonHitted(data)"
            class="eyeCursorclass"
            target="_blank"
            ><i class="fa fa-pencil mr-5"></i>
          </a>

          <a class="deleteBtnColor" (click)="deleteButtonClikced(data.id)">
            <i class="fa fa-trash" matTooltip="Click To Delete"></i>
          </a>
        </td>
        <td class="verticalAlignColumn" mat-cell *matCellDef="let record"></td>
      </ng-container>

      <ng-container matColumnDef="noRecords">
        <td
          class="text-align-center"
          mat-footer-cell
          *matFooterCellDef
          colspan="12"
        >
          <div *ngIf="!isLoading">
            {{ "No Records Found" }}
          </div>
          <div [hidden]="!isLoading">
            <mat-spinner [diameter]="220"></mat-spinner>
          </div>
        </td>
      </ng-container>
      <ng-container matColumnDef="paginator">
        <td mat-footer-cell *matFooterCellDef colspan="12">
          <mat-paginator
            class="table table-responsive mat-table-class"
            #MatPaginator
            [length]="dataSource?.data?.length"
            [pageSize]="10"
            [pageIndex]="0"
            showFirstLastButtons
            [pageSizeOptions]="[5, 10, 25, 100]"
          >
          </mat-paginator>
        </td>
      </ng-container>
    </div>

    <div *ngSwitchCase="'Positing'">
      <form [formGroup]="postingForm">
        <ng-container matColumnDef="patientId">
          <th class="w-150 th-center" mat-header-cell *matHeaderCellDef>
            <mat-form-field class="filter">
              <input autocomplete="new-patientId"
                matInput
                formControlName="patientId"
                type="number"
                min="0"
              />
              <mat-placeholder class="center">PatientId</mat-placeholder>
            </mat-form-field>
          </th>
          <td mat-cell *matCellDef="let element">{{ element?.patientId }}</td>
        </ng-container>
        <ng-container matColumnDef="patientName">
          <th
            class="w-150 th-center"
            class="header"
            mat-header-cell
            *matHeaderCellDef
          >
            <mat-form-field class="filter">
              <input autocomplete="new-patientName" matInput formControlName="patientName" />
              <mat-placeholder>Patient Name</mat-placeholder>
            </mat-form-field>
          </th>
          <td mat-cell *matCellDef="let element">{{ element?.patientName }}</td>
        </ng-container>
        <ng-container matColumnDef="dob">
          <th
            class="header"
            class="w-150 th-center"
            mat-header-cell
            *matHeaderCellDef
          >
            <mat-form-field class="filter">
              <input autocomplete="new-dob" matInput formControlName="dob" />
              <mat-placeholder>DOB</mat-placeholder>
            </mat-form-field>
          </th>
          <td mat-cell *matCellDef="let element">{{ element?.patientDob }}</td>
        </ng-container>
        <ng-container matColumnDef="dos">
          <th
            class="header"
            class="w-150 th-center"
            mat-header-cell
            *matHeaderCellDef
          >
            <mat-form-field class="filter">
              <input autocomplete="new-dos" matInput formControlName="dos" />
              <mat-placeholder>DOS</mat-placeholder>
            </mat-form-field>
          </th>
          <td mat-cell *matCellDef="let element">{{ element?.dos }}</td>
        </ng-container>
        <ng-container matColumnDef="planCode">
          <th
            class="header"
            class="w-150 th-center"
            mat-header-cell
            *matHeaderCellDef
          >
            <mat-form-field class="filter">
              <input autocomplete="new-planCode" matInput formControlName="planCode" />
              <mat-placeholder>PlanCode</mat-placeholder>
            </mat-form-field>
          </th>
          <td mat-cell *matCellDef="let element">{{ element?.planCode }}</td>
        </ng-container>
        <ng-container matColumnDef="insurance">
          <th
            class="header"
            class="w-150 th-center"
            mat-header-cell
            *matHeaderCellDef
          >
            <mat-form-field class="filter">
              <input autocomplete="new-insurance" matInput formControlName="insurance" />
              <mat-placeholder>Insurance</mat-placeholder>
            </mat-form-field>
          </th>
          <td mat-cell *matCellDef="let element">{{ element?.insurance }}</td>
        </ng-container>
        <ng-container matColumnDef="createdDateTime">
          <th
            class="header"
            class="w-150 th-center"
            mat-header-cell
            *matHeaderCellDef
          >
            <mat-form-field class="filter">
              <input autocomplete="new-createdDateTime" matInput formControlName="createdDateTime" />
              <mat-placeholder>CreatedDate</mat-placeholder>
            </mat-form-field>
          </th>
          <td mat-cell *matCellDef="let element">
            {{ element?.createdDateTime }}
          </td>
        </ng-container>

        <ng-container matColumnDef="options">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Options</th>

          <td mat-cell *matCellDef="let element">
            <a
              class="eyeCursorclass customThemeClass"
              (click)="ViewButtonHitted(element.id)"
              target="_blank"
              ><i class="fa fa-eye"></i
            ></a>
          </td>
        </ng-container>

        <ng-container matColumnDef="noRecords">
          <td
            class="text-align-center"
            mat-footer-cell
            *matFooterCellDef
            colspan="8"
          >
            No Records Found
          </td>
        </ng-container>
        <ng-container matColumnDef="paginator">
          <td mat-footer-cell *matFooterCellDef colspan="8">
            <mat-paginator
              class="table table-responsive mat-table-class"
              #MatPaginator
              [length]="dataSource?.data?.length"
              [pageSize]="10"
              [pageIndex]="0"
              showFirstLastButtons
              [pageSizeOptions]="[5, 10, 25, 100]"
            >
            </mat-paginator>
          </td>
        </ng-container>
      </form>
    </div>

    <div *ngSwitchCase="'practiceReferral'">
      <ng-container matColumnDef="FaxId">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Fax ID</th>
        <td mat-cell mat-cell *matCellDef="let data">{{ data.payorLevel }}</td>
      </ng-container>
      <ng-container matColumnDef="Path">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Folder Path</th>
        <td mat-cell mat-cell *matCellDef="let data">
          {{ data.insuranceType }}
        </td>
      </ng-container>

      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
        <td mat-cell mat-cell *matCellDef="let data">{{ data.policyNo }}</td>
      </ng-container>
      <ng-container matColumnDef="page">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Pages</th>
        <td mat-cell *matCellDef="let data">{{ data.note }}</td>
      </ng-container>
      <ng-container matColumnDef="description">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Description</th>
        <td mat-cell *matCellDef="let data">{{ data.note }}</td>
      </ng-container>
      <ng-container matColumnDef="location">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Location</th>
        <td mat-cell *matCellDef="let data">{{ data.note }}</td>
      </ng-container>
      <ng-container matColumnDef="pName">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Patient Name</th>
        <td mat-cell *matCellDef="let data">{{ data.note }}</td>
      </ng-container>
      <ng-container matColumnDef="phyName">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          Physician Name
        </th>
        <td mat-cell *matCellDef="let data">{{ data.note }}</td>
      </ng-container>
      <ng-container matColumnDef="pId">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Patient ID</th>
        <td mat-cell *matCellDef="let data">{{ data.note }}</td>
      </ng-container>

      <ng-container matColumnDef="noRecords">
        <td
          class="text-align-center"
          mat-footer-cell
          *matFooterCellDef
          colspan="10"
        >
          <div *ngIf="!isLoading">
            {{ "No Records Found" }}
          </div>
          <div [hidden]="!isLoading">
            <mat-spinner [diameter]="220"></mat-spinner>
          </div>
        </td>
      </ng-container>
      <ng-container matColumnDef="paginator">
        <td mat-footer-cell *matFooterCellDef colspan="10">
          <mat-paginator
            class="table table-responsive mat-table-class"
            #MatPaginator
            [length]="dataSource?.data?.length"
            [pageSize]="10"
            [pageIndex]="0"
            showFirstLastButtons
            [pageSizeOptions]="[5, 10, 25, 100]"
          >
          </mat-paginator>
        </td>
      </ng-container>
    </div>

    <div *ngSwitchCase="'practiceReferralFaxNo'">
      <ng-container matColumnDef="FaxNo">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Fax Number</th>
        <td mat-cell mat-cell *matCellDef="let data">{{ data.payorLevel }}</td>
      </ng-container>

      <ng-container matColumnDef="noRecords">
        <td
          class="text-align-center"
          mat-footer-cell
          *matFooterCellDef
          colspan="10"
        >
          <div *ngIf="!isLoading">
            {{ "No Records Found" }}
          </div>
          <div [hidden]="!isLoading">
            <mat-spinner [diameter]="220"></mat-spinner>
          </div>
        </td>
      </ng-container>
      <ng-container matColumnDef="paginator">
        <td mat-footer-cell *matFooterCellDef colspan="10">
          <mat-paginator
            class="table table-responsive mat-table-class"
            #MatPaginator
            [length]="dataSource?.data?.length"
            [pageSize]="10"
            [pageIndex]="0"
            showFirstLastButtons
            [pageSizeOptions]="[5, 10, 25, 100]"
          >
          </mat-paginator>
        </td>
      </ng-container>
    </div>

    <div *ngSwitchCase="'policy'">
      <ng-container matColumnDef="payorLevel">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Payor Level</th>
        <td mat-cell mat-cell *matCellDef="let policies">
          {{ policies.payorLevel }}
        </td>
      </ng-container>
      <ng-container matColumnDef="insuranceType">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          Insurance Type
        </th>
        <td mat-cell mat-cell *matCellDef="let policies">
          {{ policies.insuranceType }}
        </td>
      </ng-container>
      <ng-container matColumnDef="payorId">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>PayorId</th>
        <td mat-cell mat-cell *matCellDef="let policies">
          {{ policies.payorId }}
        </td>
      </ng-container>
      <ng-container matColumnDef="policyNo">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Policy No</th>
        <td mat-cell mat-cell *matCellDef="let policies">
          {{ policies.policyNo }}
        </td>
      </ng-container>
      <ng-container matColumnDef="note">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Note</th>
        <td mat-cell *matCellDef="let policies">{{ policies.note }}</td>
      </ng-container>
      <!-- <ng-container matColumnDef="coverageType">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Coverage Type</th>
        <td mat-cell *matCellDef="let policies">{{ policies.coverageType }}</td>
      </ng-container>
      <ng-container matColumnDef="groupNumber">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Group No</th>
        <td mat-cell mat-cell *matCellDef="let policies">{{ policies.groupNo }}</td>
      </ng-container>
      <ng-container matColumnDef="groupName">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Group Name</th>
        <td mat-cell mat-cell *matCellDef="let policies">{{ policies.groupName }}</td>
      </ng-container>
      <ng-container matColumnDef="policyType">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Policy Type</th>
        <td mat-cell mat-cell *matCellDef="let policies">{{ policies.payorLevel }}</td>
      </ng-container> -->

      <ng-container matColumnDef="options">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Options</th>

        <td mat-cell *matCellDef="let policies">
          <a
            (click)="ViewButtonHitted(policies)"
            class="eyeCursorclass"
            target="_blank"
            ><i class="fa fa-pencil mr-3"></i
          ></a>
          <a class="deleteBtnColor">
            <i (click)="deleteButtonClikced(policies)" class="fa fa-trash"></i>
          </a>
        </td>
      </ng-container>

      <ng-container matColumnDef="noRecords">
        <td
          class="text-align-center"
          mat-footer-cell
          *matFooterCellDef
          colspan="10"
        >
          <div *ngIf="!isLoading">
            {{ "No Records Found" }}
          </div>
          <div [hidden]="!isLoading">
            <mat-spinner [diameter]="220"></mat-spinner>
          </div>
        </td>
      </ng-container>
      <ng-container matColumnDef="paginator">
        <td mat-footer-cell *matFooterCellDef colspan="10">
          <mat-paginator
            class="table table-responsive mat-table-class"
            #MatPaginator
            [length]="dataSource?.data?.length"
            [pageSize]="10"
            [pageIndex]="0"
            showFirstLastButtons
            [pageSizeOptions]="[5, 10, 25, 100]"
          >
          </mat-paginator>
        </td>
      </ng-container>
    </div>
    <div *ngSwitchCase="'plan'">
      <ng-container matColumnDef="payerId">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Payer Id</th>
        <td mat-cell mat-cell *matCellDef="let plans">{{ plans.payerId }}</td>
      </ng-container>
      <ng-container matColumnDef="payerName">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Payer Name</th>
        <td mat-cell mat-cell *matCellDef="let plans">{{ plans.payerName }}</td>
      </ng-container>
      <ng-container matColumnDef="npiCode">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>NPI Code</th>
        <td mat-cell mat-cell *matCellDef="let plans">{{ plans.npiCode }}</td>
      </ng-container>
      <ng-container matColumnDef="planName">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Plan Name</th>
        <td mat-cell mat-cell *matCellDef="let plans">{{ plans.planName }}</td>
      </ng-container>
      <ng-container matColumnDef="options">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Options</th>

        <td mat-cell *matCellDef="let plans">
          <a
            (click)="ViewButtonHitted(plans)"
            class="eyeCursorclass"
            target="_blank"
            ><i class="fa fa-pencil mr-2"></i
          ></a>
          <a class="deleteBtnColor">
            <i (click)="deleteButtonClikced(plans)" class="fa fa-trash"></i>
          </a>
        </td>
      </ng-container>

      <ng-container matColumnDef="noRecords">
        <td
          class="text-align-center"
          mat-footer-cell
          *matFooterCellDef
          colspan="9"
        >
          No Records Found
        </td>
      </ng-container>
      <ng-container matColumnDef="paginator">
        <td mat-footer-cell *matFooterCellDef colspan="9">
          <mat-paginator
            class="table table-responsive mat-table-class"
            #MatPaginator
            [length]="dataSource?.data?.length"
            [pageSize]="10"
            [pageIndex]="0"
            showFirstLastButtons
            [pageSizeOptions]="[5, 10, 25, 100]"
          >
          </mat-paginator>
        </td>
      </ng-container>
    </div>
    <div *ngSwitchCase="'AdminTenants'">
      <ng-container matColumnDef="sno">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>S.No</th>
        <td mat-cell *matCellDef="let datas; let i = index">
          {{ i + 1 }}
        </td>
      </ng-container>
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
        <td mat-cell *matCellDef="let datas">
          <ng-container>
            {{ datas.name }}
          </ng-container>
        </td>
      </ng-container>
      <ng-container matColumnDef="email">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Email</th>
        <td mat-cell *matCellDef="let datas">
          {{ datas.email }}
        </td>
      </ng-container>
      <ng-container matColumnDef="Options">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Options</th>
        <td mat-cell *matCellDef="let datas">
          <a
            *ngIf="isShownUpdateIconForTenant"
            (click)="ViewButtonHitted(datas)"
            target="_blank"
            ><i
              matTooltip="Click To View Tenant"
              class="fa fa-pencil pointer editBtnColor mr-3"
            ></i>
          </a>
          &nbsp;
          <a *ngIf="isShownDeleteIconForTenant" class="deleteBtnColor">
            <i
              (click)="deleteTenantClikced(datas)"
              class="fa fa-trash pointer"
              matTooltip="Click To Delete Tenant"
            ></i>
          </a>
        </td>
      </ng-container>
      <ng-container matColumnDef="noRecords">
        <td
          class="text-align-center"
          mat-footer-cell
          *matFooterCellDef
          colspan="4"
        >
          {{ "No Records Found" }}
        </td>
      </ng-container>
      <ng-container matColumnDef="paginator">
        <td mat-footer-cell *matFooterCellDef colspan="4">
          <mat-paginator
            class="table table-responsive mat-table-class"
            #MatPaginator
            [length]="dataSource?.data?.length"
            [pageSize]="10"
            [pageIndex]="0"
            showFirstLastButtons
            [pageSizeOptions]="[5, 10, 25, 100]"
          >
          </mat-paginator>
        </td>
      </ng-container>
    </div>
    <div *ngSwitchCase="'sAdminActivateTenants'">
      <ng-container matColumnDef="sno">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>S.No</th>
        <td mat-cell *matCellDef="let datas; let i = index">
          {{ i + 1 }}
        </td>
      </ng-container>
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
        <td mat-cell *matCellDef="let datas">
          <ng-container>
            {{ datas.name }}
          </ng-container>
        </td>
      </ng-container>
      <ng-container matColumnDef="email">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Email</th>
        <td mat-cell *matCellDef="let datas">
          {{ datas.email }}
        </td>
      </ng-container>
      <ng-container matColumnDef="Options">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Options</th>
        <td mat-cell *matCellDef="let datas">
          <section class="example-section">
            <mat-slide-toggle
              (change)="onSlideToggleChange($event, datas)"
              class="example-margin"
            >
              Activate
            </mat-slide-toggle>
          </section>
        </td>
      </ng-container>
      <ng-container matColumnDef="noRecords">
        <td
          class="text-align-center"
          mat-footer-cell
          *matFooterCellDef
          colspan="6"
        >
          {{ "No Records Found" }}
        </td>
      </ng-container>
      <ng-container matColumnDef="paginator">
        <td mat-footer-cell *matFooterCellDef colspan="6">
          <mat-paginator
            class="table table-responsive mat-table-class"
            #MatPaginator
            [length]="dataSource?.data?.length"
            [pageSize]="10"
            [pageIndex]="0"
            showFirstLastButtons
            [pageSizeOptions]="[5, 10, 25, 100]"
          >
          </mat-paginator>
        </td>
      </ng-container>
    </div>
    <div *ngSwitchCase="'AdminUsers'">
      <ng-container matColumnDef="sno">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>S.No</th>
        <td mat-cell *matCellDef="let datas; let i = index">
          {{ i + 1 }}
        </td>
      </ng-container>
      <ng-container matColumnDef="userName">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>User Name</th>
        <td mat-cell *matCellDef="let datas">
          <ng-container>
            {{ datas.userName }}
          </ng-container>
        </td>
      </ng-container>
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>First Name</th>
        <td mat-cell *matCellDef="let datas">
          {{ datas.name }}
        </td>
      </ng-container>
      <ng-container matColumnDef="surName">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Last Name</th>
        <td mat-cell *matCellDef="let datas">
          {{ datas.surName }}
        </td>
      </ng-container>
      <ng-container matColumnDef="role">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Role</th>
        <td mat-cell *matCellDef="let datas">
          {{ datas.role }}
        </td>
      </ng-container>
      <ng-container matColumnDef="tenantName">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Tenent Name</th>
        <td mat-cell *matCellDef="let datas">
          {{ datas.tenantName === null ? "" : datas.tenantName }}
        </td>
      </ng-container>

      <ng-container matColumnDef="organizationUnitName">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Branch Name</th>
        <td mat-cell *matCellDef="let datas">
          {{ datas.organizationUnitName }}
        </td>
      </ng-container>
      <ng-container matColumnDef="emailAddress">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Email Address</th>
        <td mat-cell *matCellDef="let datas">
          {{ datas.email }}
        </td>
      </ng-container>
      <ng-container matColumnDef="phoneNumber">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Phone Number</th>
        <td mat-cell *matCellDef="let datas">
          {{ datas.phoneNumber }}
        </td>
      </ng-container>
      <ng-container matColumnDef="Options">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Options</th>
        <td mat-cell *matCellDef="let datas">
          <div
            *ngIf="datas.userName == 'admin'; then content; else other_content"
          >
            here is ignored
          </div>
          <ng-template #content>
            <span *ngIf="datas.userName !== 'admin'">
              <a
                *ngIf="isShownUpdateIconForUser"
                (click)="ViewButtonHitted(datas)"
                target="_blank"
                ><i
                  class="fa fa-pencil pointer editBtnColor mr-3"
                  matTooltip="Click To View User"
                ></i
              ></a>
            </span>
          </ng-template>
          <ng-template #other_content>
            <a
              *ngIf="isShownUpdateIconForUser"
              (click)="ViewButtonHitted(datas)"
              target="_blank"
              ><i
                class="fa fa-pencil pointer editBtnColor mr-3"
                matTooltip="Click To View User"
              ></i
            ></a>
            <a *ngIf="isShownDeleteIconForUser" class="deleteBtnColor">
              <i
                (click)="deleteUsersClikced(datas)"
                class="fa fa-trash pointer"
                matTooltip="Click To Delete User"
              ></i>
            </a>
          </ng-template>
          <!-- &nbsp; -->
        </td>
      </ng-container>
      <ng-container matColumnDef="noRecords">
        <td
          class="text-align-center"
          mat-footer-cell
          *matFooterCellDef
          colspan="10"
        >
          {{ "No Records Found" }}
        </td>
      </ng-container>
      <ng-container matColumnDef="paginator">
        <td mat-footer-cell *matFooterCellDef colspan="10">
          <mat-paginator
            class="table table-responsive mat-table-class"
            #MatPaginator
            [length]="dataSource?.data?.length"
            [pageSize]="10"
            [pageIndex]="0"
            showFirstLastButtons
            [pageSizeOptions]="[5, 10, 25, 100]"
          >
          </mat-paginator>
        </td>
      </ng-container>
    </div>
    <div *ngSwitchCase="'daySheetlist'">
      <ng-container matColumnDef="sno">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>S.No</th>
        <td
          class="verticalAlignColumn"
          mat-cell
          *matCellDef="let data; let i = index"
        >
          {{ i + 1 }}
        </td>
      </ng-container>

      <ng-container matColumnDef="daysheetDate">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Daysheet Date</th>
        <td class="verticalAlignColumn" mat-cell *matCellDef="let data">
          {{ data?.daysheetDate }}
        </td>
      </ng-container>
      <ng-container matColumnDef="description">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Description</th>
        <td class="verticalAlignColumn" mat-cell *matCellDef="let data">
          {{ data?.description }}
        </td>
      </ng-container>
      <ng-container matColumnDef="Options">
        <th
          class="verticalAlignColumn"
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
        >
          Options
        </th>
        <td class="verticalAlignColumn" mat-cell *matCellDef="let data">
          <a class="eyeCursorclass" target="_blank"
            ><i class="fa fa-pencil mr-5"></i>
          </a>
        </td>
      </ng-container>
      <ng-container matColumnDef="noRecords">
        <td
          class="text-align-center"
          mat-footer-cell
          *matFooterCellDef
          colspan="9"
        >
          No Records Found
        </td>
      </ng-container>
      <ng-container matColumnDef="paginator">
        <td mat-footer-cell *matFooterCellDef colspan="9">
          <mat-paginator
            class="table table-responsive mat-table-class"
            #MatPaginator
            [length]="dataSource?.data?.length"
            [pageSize]="10"
            [pageIndex]="0"
            showFirstLastButtons
            [pageSizeOptions]="[5, 10, 25, 100]"
          >
          </mat-paginator>
        </td>
      </ng-container>
    </div>

    <div *ngSwitchCase="'paymentSetup'">
      <ng-container matColumnDef="sno">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>S.No</th>
        <td
          class="verticalAlignColumn"
          mat-cell
          *matCellDef="let data; let i = index"
        >
          {{ i + 1 }}
        </td>
      </ng-container>
      <ng-container matColumnDef="merchantId">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Merchant Id</th>
        <td class="verticalAlignColumn" mat-cell *matCellDef="let data">
          {{ data?.merchantId }}
        </td>
      </ng-container>
      <ng-container matColumnDef="paymentType">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Payment Type</th>
        <td class="verticalAlignColumn" mat-cell *matCellDef="let data">
          {{ data?.paymentType }}
        </td>
      </ng-container>
      <ng-container matColumnDef="paymentOptionName">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          Payment Option
        </th>
        <td class="verticalAlignColumn" mat-cell *matCellDef="let data">
          {{ data?.paymentOptionName }}
        </td>
      </ng-container>
      <ng-container matColumnDef="chkEnabled">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Status</th>
        <td class="verticalAlignColumn" mat-cell *matCellDef="let data">
          {{ data?.isEnabled === 1 ? "Active" : "Inactive" }}
        </td>
      </ng-container>
      <ng-container matColumnDef="Options">
        <th
          class="verticalAlignColumn"
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
        >
          Options
        </th>
        <td class="verticalAlignColumn" mat-cell *matCellDef="let data">
          <a
            (click)="ViewButtonHitted(data)"
            class="eyeCursorclass"
            matTooltip="Click To Edit"
            target="_blank"
            ><i class="fa fa-pencil mr-5"></i>
          </a>
          <a class="deleteBtnColor mr-3">
            <i
              (click)="deleteButtonHitted(data)"
              class="fa fa-trash pointer"
              matTooltip="Click To Delete"
            ></i>
          </a>
        </td>
      </ng-container>
      <ng-container matColumnDef="noRecords">
        <td
          class="text-align-center"
          mat-footer-cell
          *matFooterCellDef
          colspan="9"
        >
          <div *ngIf="!isLoading">
            {{ "No Records Found" }}
          </div>
          <div [hidden]="!isLoading">
            <mat-spinner [diameter]="220"></mat-spinner>
          </div>
        </td>
      </ng-container>
      <ng-container matColumnDef="paginator">
        <td mat-footer-cell *matFooterCellDef colspan="9">
          <mat-paginator
            class="table table-responsive mat-table-class"
            #MatPaginator
            [length]="dataSource?.data?.length"
            [pageSize]="10"
            [pageIndex]="0"
            showFirstLastButtons
            [pageSizeOptions]="[5, 10, 25, 100]"
          >
          </mat-paginator>
        </td>
      </ng-container>
    </div>

    <div *ngSwitchCase="'payorPage'">
      <ng-container matColumnDef="sno">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>S.No</th>
        <td
          class="verticalAlignColumn"
          mat-cell
          *matCellDef="let data; let i = index"
        >
          {{ i + 1 }}
        </td>
      </ng-container>

      <ng-container matColumnDef="payorId">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Payor Id</th>
        <td class="verticalAlignColumn" mat-cell *matCellDef="let data">
          {{ data?.payerId }}
        </td>
      </ng-container>
      <ng-container matColumnDef="payorName">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Payor Name</th>
        <td class="verticalAlignColumn" mat-cell *matCellDef="let data">
          {{ data?.payerName }}
        </td>
      </ng-container>
      <ng-container matColumnDef="Options">
        <th
          class="verticalAlignColumn"
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
        >
          Options
        </th>
        <td class="verticalAlignColumn" mat-cell *matCellDef="let data">
          <a
            (click)="ViewButtonHitted(data)"
            class="eyeCursorclass"
            target="_blank"
            ><i class="fa fa-pencil mr-5"></i>
          </a>
          <a class="deleteBtnColor mr-3">
            <i
              (click)="deleteButtonHitted(data)"
              class="fa fa-trash pointer"
              matTooltip="Click To Delete"
            ></i>
          </a>
        </td>
      </ng-container>
      <ng-container matColumnDef="noRecords">
        <td
          class="text-align-center"
          mat-footer-cell
          *matFooterCellDef
          colspan="9"
        >
          No Records Found
        </td>
      </ng-container>
      <ng-container matColumnDef="paginator">
        <td mat-footer-cell *matFooterCellDef colspan="9">
          <mat-paginator
            class="table table-responsive mat-table-class"
            #MatPaginator
            [length]="dataSource?.data?.length"
            [pageSize]="10"
            [pageIndex]="0"
            showFirstLastButtons
            [pageSizeOptions]="[5, 10, 25, 100]"
          >
          </mat-paginator>
        </td>
      </ng-container>
    </div>
    <div *ngSwitchCase="'AdminInviteUsers'">
      <ng-container matColumnDef="sno">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>S.No</th>
        <td mat-cell *matCellDef="let datas; let i = index">
          {{ i + 1 }}
        </td>
      </ng-container>
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
        <td mat-cell *matCellDef="let datas">
          <ng-container>
            {{ datas.name }}
            <mat-chip [disabled]="true" *ngIf="datas?.isStatic" selected
              >Static</mat-chip
            >
            <mat-chip [disabled]="true" *ngIf="datas?.isDefault" selected
              >Default</mat-chip
            >
          </ng-container>
        </td>
      </ng-container>

      <ng-container matColumnDef="emailAddress">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Email Address</th>
        <td mat-cell *matCellDef="let datas">
          <span
            matTooltip="{{ datas?.emailAddress }}"
            matTooltipClass="custom-tooltip-font"
          >
            {{ datas?.emailAddress }}</span
          >
        </td>
      </ng-container>
      <ng-container matColumnDef="roleName">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Role Name</th>
        <td mat-cell *matCellDef="let datas">
          {{ datas.roleName }}
        </td>
      </ng-container>
      <ng-container matColumnDef="branchName">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Branch Name</th>
        <td mat-cell *matCellDef="let datas">
          {{ datas.branchName }}
        </td>
      </ng-container>
      <ng-container matColumnDef="createdOn">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Created Date</th>
        <td mat-cell *matCellDef="let datas">
          {{ datas.createdDateTime | date : "MM/dd/yyyy" : "en_US" }}
        </td>
      </ng-container>
      <ng-container matColumnDef="expireOn">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          Expire DateTime
        </th>
        <td mat-cell *matCellDef="let datas">
          {{ datas.expireByLocalDateTime }}
        </td>
      </ng-container>
      <ng-container matColumnDef="inviteStatus">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Status</th>
        <td mat-cell *matCellDef="let datas">
          {{ datas.inviteStatus }}
        </td>
      </ng-container>
      <ng-container matColumnDef="Options">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Options</th>
        <td mat-cell *matCellDef="let datas">
          <div
            *ngIf="
              datas.inviteStatus == 'Invite Sent' ||
                datas.inviteStatus == 'Invite Resent';
              then content;
              else other_content
            "
          >
            here is ignored
          </div>
          <ng-template #content>
            <a
              *ngIf="isShownUpdateIconForUser"
              (click)="ViewButtonHitted(datas)"
              ><i
                class="fa fa-pencil pointer editBtnColor mr-3"
                matTooltip="Click To Edit"
              ></i
            ></a>
          </ng-template>
          <ng-template #other_content>
            <a
              *ngIf="
                datas.inviteStatus == 'Invite Expired' &&
                isShownDeleteIconForUser
              "
              class="deleteBtnColor mr-3"
            >
              <i
                (click)="deleteInviteUsersClicked(datas.id)"
                class="fa fa-trash pointer"
                matTooltip="Click To Delete"
              ></i>
            </a>
            <a
              *ngIf="
                datas.inviteStatus == 'Invite Expired' &&
                isShownUpdateIconForUser
              "
              class="BtnColor mr-3"
            >
              <i
                (click)="ResendInviteUsersClicked(datas)"
                class="fa fa-paper-plane"
                matTooltip="Click To Resend Mail"
              ></i>
            </a>
            <a
              *ngIf="datas.inviteStatus == 'Registration Completed'"
              class="roleBtnColor font-size-19 mr-5"
            >
              <!-- <i (click)="redirectToUserPageToAssignRoles()" class="fa fa-tasks fa-tasks-alt pointer"
                matTooltip="Click To Assign Roles" ></i> -->
            </a>
          </ng-template>
        </td>
      </ng-container>
      <ng-container matColumnDef="noRecords">
        <td
          class="text-align-center"
          mat-footer-cell
          *matFooterCellDef
          colspan="9"
        >
          <div *ngIf="!isLoading">
            {{ "No Records Found" }}
          </div>
          <div [hidden]="!isLoading">
            <mat-spinner [diameter]="220"></mat-spinner>
          </div>
        </td>
      </ng-container>
      <ng-container matColumnDef="paginator">
        <td mat-footer-cell *matFooterCellDef colspan="9">
          <mat-paginator
            class="table table-responsive mat-table-class"
            #MatPaginator
            [length]="dataSource?.data?.length"
            [pageSize]="10"
            [pageIndex]="0"
            showFirstLastButtons
            [pageSizeOptions]="[5, 10, 25, 100]"
          >
          </mat-paginator>
        </td>
      </ng-container>
    </div>
    <div *ngSwitchCase="'AdminRoles'">
      <ng-container matColumnDef="sno">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>S.No</th>
        <td mat-cell *matCellDef="let datas; let i = index">
          {{ i + 1 }}
        </td>
      </ng-container>
      <ng-container matColumnDef="roleName">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Role Name</th>
        <td mat-cell *matCellDef="let datas">
          <ng-container>
            {{ datas.name }}
          </ng-container>
        </td>
      </ng-container>
      <ng-container matColumnDef="Options">
        <th
          class="verticalAlignColumn text-center"
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
        >
          Options
        </th>
        <td class="verticalAlignColumn" mat-cell *matCellDef="let datas">
          <button
            mat-button
            class="buttonColor mb-1 mr-1 text-right"
            matTooltip="Click To Assign Permission for Role"
            (click)="ViewPermissionsClikced(datas)"
          >
            Permission Access
          </button>

          <a *ngIf="isShownUpdateIcon" (click)="ViewButtonHitted(datas)"
            ><i
              class="fa fa-pencil pointer editBtnColor mr-3"
              matTooltip="Click To View Role"
            ></i>
          </a>

          <a *ngIf="isShownDeleteIcon">
            <i
              (click)="deleteRolesClikced(datas)"
              class="deleteBtnColor fa fa-trash pointer mr-3"
              matTooltip="Click To Delete Role"
            ></i>
          </a>
        </td>
      </ng-container>
      <ng-container matColumnDef="noRecords">
        <td
          class="text-align-center"
          mat-footer-cell
          *matFooterCellDef
          colspan="3"
        >
          {{ "No Records Found" }}
        </td>
      </ng-container>
      <ng-container matColumnDef="paginator">
        <td mat-footer-cell *matFooterCellDef colspan="3">
          <mat-paginator
            class="table table-responsive mat-table-class"
            #MatPaginator
            [length]="dataSource?.data?.length"
            [pageSize]="10"
            [pageIndex]="0"
            showFirstLastButtons
            [pageSizeOptions]="[5, 10, 25, 100]"
          >
          </mat-paginator>
        </td>
      </ng-container>
    </div>
    <div *ngSwitchCase="'AdminOrganizations'">
      <ng-container matColumnDef="sno">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>S.No</th>
        <td mat-cell *matCellDef="let datas; let i = index">
          {{ i + 1 }}
        </td>
      </ng-container>
      <ng-container matColumnDef="branchName">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Branch Name</th>
        <td mat-cell *matCellDef="let datas">
          <ng-container>
            {{ datas.name }}
          </ng-container>
        </td>
      </ng-container>
      <ng-container matColumnDef="Options">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Options</th>
        <td mat-cell *matCellDef="let datas">
          <a (click)="ViewButtonHitted(datas)" target="_blank"
            ><i
              matTooltip="Click To View Branch"
              class="fa fa-pencil pointer editBtnColor mr-3"
            ></i>
          </a>
          &nbsp;
          <a class="deleteBtnColor">
            <i
              (click)="deleteOrganizationClikced(datas)"
              class="fa fa-trash pointer"
              matTooltip="Click To Delete Branch"
            ></i>
          </a>
          &nbsp;
        </td>
      </ng-container>
      <ng-container matColumnDef="noRecords">
        <td
          class="text-align-center"
          mat-footer-cell
          *matFooterCellDef
          colspan="3"
        >
          {{ "No Records Found" }}
        </td>
      </ng-container>
      <ng-container matColumnDef="paginator">
        <td mat-footer-cell *matFooterCellDef colspan="3">
          <mat-paginator
            class="table table-responsive mat-table-class"
            #MatPaginator
            [length]="dataSource?.data?.length"
            [pageSize]="10"
            [pageIndex]="0"
            showFirstLastButtons
            [pageSizeOptions]="[5, 10, 25, 100]"
          >
          </mat-paginator>
        </td>
      </ng-container>
    </div>
    <div *ngSwitchCase="'pverifyEligibilityMedicare'">
      <ng-container matColumnDef="sno">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>S.No</th>
        <td mat-cell *matCellDef="let datas; let i = index">
          {{ i + 1 }}
        </td>
      </ng-container>
      <ng-container matColumnDef="insurance">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Plan</th>
        <td mat-cell *matCellDef="let datas">
          <ng-container>
            {{ datas.insurance }}
          </ng-container>
        </td>
      </ng-container>
      <ng-container matColumnDef="coverage">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Status</th>
        <td mat-cell *matCellDef="let datas">
          {{ datas.coverage }}
        </td>
      </ng-container>
      <ng-container matColumnDef="date">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          Effective Date
        </th>
        <td mat-cell *matCellDef="let datas">
          {{ datas.date }}
        </td>
      </ng-container>
      <ng-container matColumnDef="expDate">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Expiry Date</th>
        <td mat-cell *matCellDef="let datas">
          {{ datas.expDate }}
        </td>
      </ng-container>

      <ng-container matColumnDef="grpName">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Group Name</th>
        <td mat-cell *matCellDef="let datas">
          {{ datas.groupName }}
        </td>
      </ng-container>
      <ng-container matColumnDef="grpNo">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Group No</th>
        <td mat-cell *matCellDef="let datas">
          {{ datas.groupNo }}
        </td>
      </ng-container>

      <ng-container matColumnDef="noRecords">
        <td
          class="text-align-center"
          mat-footer-cell
          *matFooterCellDef
          colspan="7"
        >
          {{ "No Records Found" }}
        </td>
      </ng-container>
      <ng-container matColumnDef="paginator">
        <td mat-footer-cell *matFooterCellDef colspan="7">
          <mat-paginator
            class="table table-responsive mat-table-class"
            #MatPaginator
            [length]="dataSource?.data?.length"
            [pageSize]="10"
            [pageIndex]="0"
            showFirstLastButtons
            [pageSizeOptions]="[5, 10, 25, 100]"
          >
          </mat-paginator>
        </td>
      </ng-container>
    </div>
    <div *ngSwitchCase="'pverifyDeductibleBenefits'">
      <ng-container matColumnDef="sno">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>S.No</th>
        <td mat-cell *matCellDef="let datas; let i = index">
          {{ i + 1 }}
        </td>
      </ng-container>
      <ng-container matColumnDef="type">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Type</th>
        <td mat-cell *matCellDef="let datas">
          <ng-container>
            {{ datas.type }}
          </ng-container>
        </td>
      </ng-container>
      <ng-container matColumnDef="baseAmount">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Base Amount</th>
        <td mat-cell *matCellDef="let datas">
          {{ datas.baseAmount }}
        </td>
      </ng-container>
      <ng-container matColumnDef="remaining">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Remaining</th>
        <td mat-cell *matCellDef="let datas">
          {{ datas.remaining }}
        </td>
      </ng-container>
      <ng-container matColumnDef="planDates">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Plan Dates</th>
        <td mat-cell *matCellDef="let datas">
          {{ datas.planDates }}
        </td>
      </ng-container>

      <ng-container matColumnDef="noRecords">
        <td
          class="text-align-center"
          mat-footer-cell
          *matFooterCellDef
          colspan="5"
        >
          {{ "No Records Found" }}
        </td>
      </ng-container>
      <ng-container matColumnDef="paginator">
        <td mat-footer-cell *matFooterCellDef colspan="5">
          <mat-paginator
            class="table table-responsive mat-table-class"
            #MatPaginator
            [length]="dataSource?.data?.length"
            [pageSize]="10"
            [pageIndex]="0"
            showFirstLastButtons
            [pageSizeOptions]="[5, 10, 25, 100]"
          >
          </mat-paginator>
        </td>
      </ng-container>
    </div>
    <div *ngSwitchCase="'pverifyCoInsuranceBenefits'">
      <ng-container matColumnDef="sno">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>S.No</th>
        <td mat-cell *matCellDef="let datas; let i = index">
          {{ i + 1 }}
        </td>
      </ng-container>
      <ng-container matColumnDef="type">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Type</th>
        <td mat-cell *matCellDef="let datas">
          <ng-container>
            {{ datas.type }}
          </ng-container>
        </td>
      </ng-container>
      <ng-container matColumnDef="amount">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Amount</th>
        <td mat-cell *matCellDef="let datas">
          {{ datas.amount }}
        </td>
      </ng-container>
      <ng-container matColumnDef="planDates">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Plan Dates</th>
        <td mat-cell *matCellDef="let datas">
          {{ datas.planDates }}
        </td>
      </ng-container>

      <ng-container matColumnDef="noRecords">
        <td
          class="text-align-center"
          mat-footer-cell
          *matFooterCellDef
          colspan="4"
        >
          {{ "No Records Found" }}
        </td>
      </ng-container>
      <ng-container matColumnDef="paginator">
        <td mat-footer-cell *matFooterCellDef colspan="4">
          <mat-paginator
            class="table table-responsive mat-table-class"
            #MatPaginator
            [length]="dataSource?.data?.length"
            [pageSize]="10"
            [pageIndex]="0"
            showFirstLastButtons
            [pageSizeOptions]="[5, 10, 25, 100]"
          >
          </mat-paginator>
        </td>
      </ng-container>
    </div>

    <div *ngSwitchCase="'npiDoctor'" class="responsive">
      <ng-container matColumnDef="S.NO">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>S.NO</th>
        <td mat-cell *matCellDef="let element; let i = index">{{ i + 1 }}</td>
      </ng-container>
      <ng-container matColumnDef="NPI Number">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>NPI Number</th>
        <td mat-cell *matCellDef="let element">{{ element.NpiNumber }}</td>
      </ng-container>
      <ng-container matColumnDef="Doctor Name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Doctor Name</th>
        <td mat-cell *matCellDef="let element">{{ element.FullName }}</td>
      </ng-container>
      <ng-container matColumnDef="Address">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Address</th>
        <td mat-cell *matCellDef="let element">{{ element.Address1 }}</td>
      </ng-container>
      <ng-container matColumnDef="Country">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Country</th>
        <td mat-cell *matCellDef="let element">{{ element.Country }}</td>
      </ng-container>
      <ng-container matColumnDef="State">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>State</th>
        <td mat-cell *matCellDef="let element">{{ element.State }}</td>
      </ng-container>
      <ng-container matColumnDef="City">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>City</th>
        <td mat-cell *matCellDef="let element">{{ element.City }}</td>
      </ng-container>
      <ng-container matColumnDef="Postal Code">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Postal Code</th>
        <td mat-cell *matCellDef="let element">{{ element.PostalCode }}</td>
      </ng-container>
      <ng-container matColumnDef="Action">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Action</th>

        <td mat-cell *matCellDef="let element">
          <!-- <a (click)="ViewButtonHitted(element)"
            >  <i class="mdi mdi-account-plus mdi-18px"></i></a> -->

          <div *ngIf="element.isExists; then content; else other_content">
            here is ignored
          </div>
          <ng-template #content>Already Exists</ng-template>
          <ng-template #other_content
            ><a target="_blank" (click)="ViewButtonHitted(element)">
              <i class="fa fa-plus pointer mr-5"></i>
            </a>
          </ng-template>
        </td>
      </ng-container>
      <ng-container matColumnDef="noRecords">
        <td
          class="text-align-center"
          mat-footer-cell
          *matFooterCellDef
          colspan="9"
        >
          No Records Found
        </td>
      </ng-container>
      <ng-container matColumnDef="paginator">
        <td mat-footer-cell *matFooterCellDef colspan="9">
          <mat-paginator
            class="table table-responsive mat-table-class"
            #MatPaginator
            [length]="dataSource?.data?.length"
            [pageSize]="10"
            [pageIndex]="0"
            showFirstLastButtons
            [pageSizeOptions]="[5, 10, 25, 100]"
          >
          </mat-paginator>
        </td>
      </ng-container>
    </div>
    <div *ngSwitchCase="'icd9Page'">
      <ng-container matColumnDef="S.NO">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>S.NO</th>
        <td mat-cell mat-cell *matCellDef="let element; let i = index">
          {{ i + 1 }}
        </td>
      </ng-container>
      <ng-container matColumnDef="Code">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Code</th>
        <td mat-cell mat-cell *matCellDef="let element">
          {{ element.DiagnosisCode }}
        </td>
      </ng-container>
      <ng-container matColumnDef="Description">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Description</th>
        <td mat-cell mat-cell *matCellDef="let element">
          {{ element.DiagnsoisCodeData }}
        </td>
      </ng-container>
      <ng-container matColumnDef="Action">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Action</th>

        <td mat-cell *matCellDef="let element">
          <div *ngIf="element.isExists; then content; else other_content">
            here is ignored
          </div>
          <ng-template #content>Already Exists</ng-template>
          <ng-template #other_content
            ><a target="_blank" (click)="ViewButtonHitted(element)">
              <i class="fa fa-plus pointer mr-5"></i>
            </a>
          </ng-template>
        </td>
      </ng-container>
      <ng-container matColumnDef="noRecords">
        <td
          class="text-align-center"
          mat-footer-cell
          *matFooterCellDef
          colspan="4"
        >
          <div *ngIf="!isLoading">
            {{ "No Records Found" }}
          </div>
          <div [hidden]="!isLoading">
            <mat-spinner [diameter]="220"></mat-spinner>
          </div>
        </td>
      </ng-container>
      <ng-container matColumnDef="paginator">
        <td mat-footer-cell *matFooterCellDef colspan="4">
          <mat-paginator
            class="table table-responsive mat-table-class"
            #MatPaginator
            [length]="dataSource?.data?.length"
            [pageSize]="10"
            [pageIndex]="0"
            showFirstLastButtons
            [pageSizeOptions]="[5, 10, 25, 100]"
          >
          </mat-paginator>
        </td>
      </ng-container>
    </div>
    <div *ngSwitchCase="'icd10Page'">
      <ng-container matColumnDef="S.NO">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>S.NO</th>
        <!-- <td mat-cell mat-cell *matCellDef="let element; let i = index">{{ i + 1 }}</td> -->
        <td mat-cell mat-cell *matCellDef="let element">{{ element?.sno }}</td>
      </ng-container>
      <ng-container matColumnDef="Code">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Code</th>
        <td mat-cell mat-cell *matCellDef="let element">
          {{ element.DiagnosisCode }}
        </td>
      </ng-container>
      <ng-container matColumnDef="Description">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Description</th>
        <td mat-cell mat-cell *matCellDef="let element">
          {{ element.DiagnsoisCodeData }}
        </td>
      </ng-container>
      <ng-container matColumnDef="Action">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Action</th>

        <td mat-cell *matCellDef="let element">
          <div *ngIf="element.isExists == 1; then content; else other_content">
            here is ignored
          </div>
          <ng-template #content>Already Exists</ng-template>
          <ng-template #other_content
            ><a target="_blank" (click)="ViewButtonHitted(element)">
              <i class="fa fa-plus pointer mr-5"></i>
            </a>
          </ng-template>
        </td>
      </ng-container>

      <ng-container matColumnDef="Billable">
        <th class="header" mat-header-cell *matHeaderCellDef mat-sort-header>
          Is Billable
        </th>
        <td mat-cell *matCellDef="let element">
          <input autocomplete="new-isBillable"
            readonly
            style="width: 60px; height: 20px"
            type="checkbox"
            [checked]="element?.isBillable == 1"
          />
        </td>
      </ng-container>

      <ng-container matColumnDef="expandedErrorDetail">
        <td
          mat-cell
          *matCellDef="let element"
          [attr.colspan]="arrDisplayedColumns?.length"
        >
          <div
            class="example-element-detail"
            [@detailExpand]="
              element == expandedElement ? 'expanded' : 'collapsed'
            "
          >
            <div class="example-element-description">
              {{ element.description }}
              <!-- <span class="example-element-description-attribution"> -- Wikipedia </span> -->
            </div>
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="noRecords">
        <td
          class="text-align-center"
          mat-footer-cell
          *matFooterCellDef
          colspan="5"
        >
          <div *ngIf="!isLoading">
            {{ "No Records Found" }}
          </div>
          <div [hidden]="!isLoading">
            <mat-spinner [diameter]="220"></mat-spinner>
          </div>
        </td>
      </ng-container>
      <ng-container matColumnDef="paginator">
        <td mat-footer-cell *matFooterCellDef colspan="5">
          <mat-paginator
            class="table table-responsive mat-table-class"
            #MatPaginator
            [length]="dataSource?.data?.length"
            [pageSize]="10"
            [pageIndex]="0"
            showFirstLastButtons
            [pageSizeOptions]="[5, 10, 25, 100]"
          >
          </mat-paginator>
        </td>
      </ng-container>
    </div>
    <div *ngSwitchCase="'History'">
      <ng-container matColumnDef="SNO">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>S.NO</th>
        <td mat-cell mat-cell *matCellDef="let element; let SNO = index">
          {{ SNO + 1 }}
        </td>
      </ng-container>
      <ng-container matColumnDef="dateTime">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Date</th>
        <td mat-cell mat-cell *matCellDef="let element">
          {{ element.dateTime | date : "MM/dd/yyyy h:mm a" : "en_US" }}
        </td>
      </ng-container>
      <ng-container matColumnDef="UserName">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>User</th>
        <td mat-cell mat-cell *matCellDef="let element">
          {{ element.UserName }}
        </td>
      </ng-container>
      <ng-container matColumnDef="Action">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Action</th>
        <td mat-cell *matCellDef="let element">
          <span
            class="text-with-dots"
            matTooltip="{{ element?.Action }}"
            matTooltipClass="custom-tooltip-font"
            >{{ element?.type }}</span
          >
        </td>
      </ng-container>

      <ng-container matColumnDef="ActionComment">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Comment</th>
        <td mat-cell *matCellDef="let element">
          <ng-container
            matTooltipClass="custom-tooltip-font"
            matTooltip="{{ element.ActionComment }}"
          >
            {{ element.ActionComment }}
          </ng-container>
        </td>
      </ng-container>
      <ng-container matColumnDef="noRecords">
        <td
          class="text-align-center"
          mat-footer-cell
          *matFooterCellDef
          colspan="5"
        >
          <div *ngIf="!isLoading">
            {{ "No Records Found" }}
          </div>
          <div [hidden]="!isLoading">
            <mat-spinner [diameter]="220"></mat-spinner>
          </div>
        </td>
      </ng-container>
      <ng-container matColumnDef="paginator">
        <td mat-footer-cell *matFooterCellDef colspan="5">
          <mat-paginator
            class="table table-responsive mat-table-class"
            #MatPaginator
            [length]="dataSource?.data?.length"
            [pageSize]="10"
            [pageIndex]="0"
            showFirstLastButtons
            [pageSizeOptions]="[5, 10, 25, 100]"
          >
          </mat-paginator>
        </td>
      </ng-container>
    </div>

    <!-- ontime tables -->

    <div *ngSwitchCase="'appointmentType'">
      <ng-container matColumnDef="sno">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>S.No</th>
        <td mat-cell *matCellDef="let datas; let i = index">
          {{ i + 1 }}
        </td>
      </ng-container>
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          Appintment Date
        </th>
        <td mat-cell *matCellDef="let datas">
          <ng-container>
            {{ datas.branchName }}
          </ng-container>
        </td>
      </ng-container>
      <ng-container matColumnDef="address">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Durations</th>
        <td mat-cell *matCellDef="let datas">
          {{ datas.address }}
        </td>
      </ng-container>
      <ng-container matColumnDef="mail">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Status</th>
        <td mat-cell *matCellDef="let datas">
          {{ datas.email }}
        </td>
      </ng-container>

      <ng-container matColumnDef="Options">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Options</th>
        <td mat-cell *matCellDef="let datas">
          <a
            (click)="ViewButtonHitted(datas)"
            class="eyeCursorclass"
            target="_blank"
            ><i class="fas fa-pencil-alt editBtnColor mr-3"></i>
          </a>
          &nbsp;
          <a class="deleteBtnColor">
            <i
              (click)="deleteButtonClikced(datas)"
              class="fas fa-trash-alt"
            ></i>
          </a>
        </td>
      </ng-container>
      <ng-container matColumnDef="noRecords">
        <td
          class="text-align-center"
          mat-footer-cell
          *matFooterCellDef
          colspan="6"
        >
          <div *ngIf="!isLoading">
            {{ "No Records Found" }}
          </div>
          <div [hidden]="!isLoading">
            <mat-spinner [diameter]="220"></mat-spinner>
          </div>
        </td>
      </ng-container>
      <ng-container matColumnDef="paginator">
        <td mat-footer-cell *matFooterCellDef colspan="6">
          <mat-paginator
            class="table table-responsive mat-table-class"
            #MatPaginator
            [length]="dataSource?.data?.length"
            [pageSize]="10"
            [pageIndex]="0"
            showFirstLastButtons
            [pageSizeOptions]="[5, 10, 25, 100]"
          >
          </mat-paginator>
        </td>
      </ng-container>
    </div>

    <div *ngSwitchCase="'onTimeUser'">
      <ng-container matColumnDef="sno">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>S.No</th>
        <td mat-cell *matCellDef="let datas; let i = index">
          {{ i + 1 }}
        </td>
      </ng-container>
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>User Name</th>
        <td mat-cell *matCellDef="let datas">
          <ng-container>
            {{ datas.name }}
          </ng-container>
        </td>
      </ng-container>
      <ng-container matColumnDef="email">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Email</th>
        <td mat-cell *matCellDef="let datas">
          {{ datas.email }}
        </td>
      </ng-container>
      <ng-container matColumnDef="branch">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Branch</th>
        <td mat-cell *matCellDef="let datas">
          {{ datas.branch }}
        </td>
      </ng-container>
      <ng-container matColumnDef="role">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Role</th>
        <td mat-cell *matCellDef="let datas">
          {{ datas.role }}
        </td>
      </ng-container>

      <ng-container matColumnDef="Options">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Options</th>
        <td mat-cell *matCellDef="let datas">
          <a
            (click)="ViewButtonHitted(datas)"
            class="eyeCursorclass"
            target="_blank"
            ><i class="fas fa-pencil-alt editBtnColor mr-3"></i>
          </a>
          &nbsp;
          <a class="deleteBtnColor">
            <i
              (click)="deleteButtonClikced(datas)"
              class="fas fa-trash-alt"
            ></i>
          </a>
        </td>
      </ng-container>
      <ng-container matColumnDef="noRecords">
        <td
          class="text-align-center"
          mat-footer-cell
          *matFooterCellDef
          colspan="6"
        >
          <div *ngIf="!isLoading">
            {{ "No Records Found" }}
          </div>
          <div [hidden]="!isLoading">
            <mat-spinner [diameter]="220"></mat-spinner>
          </div>
        </td>
      </ng-container>
      <ng-container matColumnDef="paginator">
        <td mat-footer-cell *matFooterCellDef colspan="6">
          <mat-paginator
            class="table table-responsive mat-table-class"
            #MatPaginator
            [length]="dataSource?.data?.length"
            [pageSize]="10"
            [pageIndex]="0"
            showFirstLastButtons
            [pageSizeOptions]="[5, 10, 25, 100]"
          >
          </mat-paginator>
        </td>
      </ng-container>
    </div>

    <div *ngSwitchDefault>
      <ng-container matColumnDef="noRecords">
        <td
          class="text-align-center"
          mat-footer-cell
          *matFooterCellDef
          colspan="9"
        >
          <div *ngIf="!isLoading">
            {{ "No Records Found" }}
          </div>
          <div [hidden]="!isLoading">
            <mat-spinner [diameter]="220"></mat-spinner>
          </div>
        </td>
      </ng-container>
      <ng-container matColumnDef="paginator">
        <td mat-footer-cell *matFooterCellDef colspan="5">
          <mat-paginator
            class="table table-responsive mat-table-class"
            #MatPaginator
            [length]="dataSource?.data?.length"
            [pageSize]="10"
            [pageIndex]="0"
            showFirstLastButtons
            [pageSizeOptions]="[5, 10, 25, 100]"
          >
          </mat-paginator>
        </td>
      </ng-container>
    </div>
  </div>
  <tr
    mat-footer-row
    *matFooterRowDef="['noRecords']"
    [ngClass]="{
      active: dataSource && dataSource?.data && dataSource?.data?.length !== 0
    }"
  ></tr>
  <tr mat-header-row *matHeaderRowDef="arrDisplayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: arrDisplayedColumns"></tr>
  <tr mat-footer-row *matFooterRowDef="['paginator']"></tr>
</table>
