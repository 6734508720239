<!-- Nav Bar color change  -->
<!-- header -->
<header id="page-topbar">
  <nav class="navbar navbar-expand-lg">
    <div class="row pl-3">
      <a class="logo logo-light pr-3">
        <img
          #applogo1
          id="applogo"
          src="assets/images/rnd.jpg"
          alt=""
          height="40"
          class="navbar-brand"
        />
      </a>

      <!--#region Dashboard  -->
      <!-- Dashboard -->
      <a
        [routerLink]="['dashboard']"
        class="buttonColor color-white pt-1 navBar-button"
        mat-button
      >
        <img
          class="img-responsive pr-2 mb-2"
          src="assets/Tab menu/Dashboard.png"
          height="14"
          alt="Dashboard"
        />
        Inbox
      </a>
      <!-- My Queue -->

      <a
        [routerLink]="['myqueue']"
        class="buttonColor color-white pt-1 navBar-button"
        mat-button
        *ngIf="!tenantUserHide"
      >
        <img
          class="img-responsive pr-2 mb-1"
          src="assets/Tab menu/myqueue.png"
          height="12"
          alt="My Queue"
        />
        My Queue
      </a>

      <!-- <button
        class="btn font-size-18"
        [routerLink]="['dashboard']"
        routerLinkActive
        mat-button
        class="buttonColor"
      >
        <img
          class="img-responsive pr-2 mb-2"
          src="assets/Tab menu/Dashboard.png"
          height="14"
        />
        Dashboard
      </button> -->
      <!--#endregion -->

      <!--#region Tools  -->
      <!-- Tools -->
      <button
        class="buttonColor navBar-button"
        mat-button
        [matMenuTriggerFor]="tools"
      >
        <img
          class="img-responsive pr-2 mb-2"
          src="assets/Tab menu/Tools Menu.png"
          height="14"
          alt="Tools"
        />
        Tools
        <!-- <mat-icon class="color-white">arrow_drop_down</mat-icon> -->
        <mat-icon>arrow_drop_down</mat-icon>
      </button>
      <mat-menu #tools="matMenu">
        <!-- Inbox -->
        <!-- <button class="custom-mainmenu-btn pt-1 color-black" mat-menu-item>
          <a class="color-black" [routerLink]="['patientlistV2']">
            <img
              class="img-responsive pr-2 mb-2"
              src="assets/Opiton Icon/Patient/Inbox.png"
              alt=""
              height="14"
            />
            Inbox
          </a>
        </button> -->

        <!-- Patient List -->
        <a
          type="button"
          mat-menu-item
          [routerLink]="['patient-list']"
          class="color-black pt-1 custom-mainmenu-btn"
        >
          <img
            class="img-responsive pr-2 mb-2"
            src="assets/Opiton Icon/Patient/patientList.png"
            alt=""
            height="14"
          />
          Patient List
        </a>

        <!-- Patient Management -->
        <a
          type="button"
          [routerLink]="['tools/patient-management']"
          mat-menu-item
          class="color-black pt-1 custom-mainmenu-btn"
        >
          <img
            class="img-responsive pr-2 mb-2"
            src="assets/Opiton Icon/Patient/patientList.png"
            alt=""
            height="14"
          />
          Patient Management
        </a>

        <!-- Recal Management -->
        <a
          type="button"
          [ngClass]="{
            'disabled-link': true
          }"
          class="color-black pt-1 custom-mainmenu-btn"
          mat-menu-item
        >
          <img
            class="img-responsive pr-2 mb-2"
            src="assets/Opiton Icon/Patient/RecallManagemnent.png"
            alt=""
            height="14"
          />
          Recal Management
        </a>

        <!--My Verification-->
        <a
          class="color-black custom-mainmenu-btn pt-1"
          [routerLink]="['tools/my-verification']"
          mat-menu-item
        >
          <img
            class="img-responsive pr-2 mb-2"
            src="assets/Opiton Icon/Patient/myVerification.png"
            alt=""
            height="14"
          />
          My Verification
        </a>

        <!--My Medicare Checklist -->
        <a
          type="button"
          mat-menu-item
          class="custom-mainmenu-btn pt-1 color-black"
          [routerLink]="['tools/my-medicarechecklist']"
        >
          <img
            class="img-responsive pr-2 mb-2"
            src="assets/Opiton Icon/Patient/MyMediareChecklist.png"
            alt=""
            height="14"
          />
          My Medicare Checklist
        </a>

        <!-- My Compliance -->
        <a
          type="button"
          mat-menu-item
          class="custom-mainmenu-btn pt-1 color-black"
          [routerLink]="['compliance-report']"
        >
          <img
            class="img-responsive pr-1 mb-2"
            src="assets/Opiton Icon/Patient/My Compilance.png"
            alt=""
            height="12"
          />
          My Compliance
        </a>

        <!-- My Orders -->
        <a
          type="button"
          mat-menu-item
          class="custom-mainmenu-btn pt-1 color-black"
          [routerLink]="['patient/my-orders']"
        >
          <img
            class="img-responsive pr-2 mb-2"
            src="assets/Opiton Icon/Patient/MyOders.png"
            alt=""
            height="14"
          />
          My Orders
        </a>

        <!-- Schedule -->
        <a
          type="button"
          mat-menu-item
          [routerLink]="[]"
          (click)="calender()"
          class="custom-mainmenu-btn pt-1 color-black"
        >
          <img
            class="img-responsive pr-2 mb-2"
            src="assets/Opiton Icon/Appointment/My Calender.png"
            alt=""
            height="14"
          />
          Schedule
        </a>

        <mat-divider></mat-divider>
        <!-- Inventory List -->
        <button
          class="custom-mainmenu-btn pt-1 color-black"
          mat-menu-item
          [matMenuTriggerFor]="inventory"
        >
          <img
            class="img-responsive pr-2 mb-2"
            src="assets\Opiton Icon\Inventory\Stock List.png"
            alt=""
            height="14"
          />
          Inventory
        </button>
        <!-- Sub Menus -->
        <!-- Inventory -->
        <mat-menu #inventory="matMenu">
          <!-- Start a Requisition -->
          <a
            type="button"
            class="custom-mainmenu-btn pt-1 color-black"
            routerLink="/inventory/requisition"
            mat-menu-item
          >
            <img
              class="img-responsive pr-2 mb-2"
              src="assets/Opiton Icon/Patient/StartaRequasion.png"
              alt=""
              height="14"
            />
            Start a Requisition
          </a>
          <!-- Requisition List -->
          <a
            type="button"
            mat-menu-item
            class="custom-mainmenu-btn pt-1 color-black"
            routerLink="/inventory/requisition-list"
          >
            <img
              class="img-responsive pr-2 mb-2"
              src="assets/Opiton Icon/Patient/patientList.png"
              alt=""
              height="14"
            />
            Requisition List
          </a>
          <!-- On Hand Inventory -->
          <a
            type="button"
            mat-menu-item
            routerLink="/inventory/stock-list-tab"
            class="custom-mainmenu-btn pt-1 color-black"
          >
            <img
              class="img-responsive pr-2 mb-2"
              src="assets/Opiton Icon/Patient/Stock.png"
              alt=""
              height="14"
            />
            On Hand Inventory
          </a>
        </mat-menu>
      </mat-menu>
      <!-- Tools End-->
      <!--#endregion -->

      <!--#region List  -->
      <!-- List -->
      <button
        *ngIf="!tenantUserHide"
        class="buttonColor navBar-button"
        mat-button
        [matMenuTriggerFor]="list"
      >
        <img
          class="img-responsive pr-2 mb-2"
          src="assets/Tab menu/patient.png"
          height="14"
          alt="List"
        />
        List
        <mat-icon class="color-white">arrow_drop_down</mat-icon>
      </button>
      <mat-menu #list="matMenu">
        <!-- Patient List -->
        <a
          mat-menu-item
          class="custom-mainmenu-btn pt-1 color-black"
          [routerLink]="['patient-list']"
        >
          <img
            class="img-responsive pr-2 mb-2"
            src="assets/Opiton Icon/Patient/patientList.png"
            alt=""
            height="14"
          />
          Patient List
        </a>
        <!-- Appts Scheduled - Schedule List -->
        <a
          class="custom-mainmenu-btn pt-1 color-black"
          [routerLink]="['settings/schedule-list']"
          mat-menu-item
        >
          <img
            class="img-responsive pr-2 mb-2"
            src="assets/Opiton Icon/Appointment/Calender list.png"
            alt=""
            height="14"
          />
          Appts Scheduled
        </a>

        <!-- Master Medicare Checklist-->
        <a
          [routerLink]="['navigation/mastermedicarechecklist']"
          mat-menu-item
          class="custom-mainmenu-btn pt-1 color-black"
        >
          <img
            class="img-responsive pr-2 mb-2"
            src="assets/Opiton Icon/Patient/MasterMedicare.png"
            alt=""
            height="14"
          />
          Master Medicare Checklist
        </a>
        <!-- Master Verification -->
        <a
          [routerLink]="['navigation/masterverification']"
          mat-menu-item
          class="custom-mainmenu-btn pt-1 color-black"
        >
          <img
            class="img-responsive pr-2 mb-2"
            src="assets/Opiton Icon/Patient/myVerification.png"
            alt=""
            height="14"
          />
          Master Ins Verification
        </a>
        <mat-divider></mat-divider>

        <!-- Practice Management -->
        <button
          mat-menu-item
          class="custom-mainmenu-btn pt-1 color-black"
          [matMenuTriggerFor]="practice"
        >
          <img
            class="img-responsive mb-2 pr-2"
            src="assets/Opiton Icon/Patient/patientList.png"
            height="14"
            alt="Practice Management"
          />
          Practice Management
        </button>

        <!-- Orders List -->
        <button
          mat-menu-item
          class="custom-mainmenu-btn pt-1 color-black"
          [matMenuTriggerFor]="orders"
        >
          <img
            class="img-responsive pr-2 mb-2"
            src="assets/Opiton Icon/Patient/Orders.png"
            alt=""
            height="14"
          />
          Orders
        </button>
        <!-- Billing List -->
        <button
          class="custom-mainmenu-btn pt-1 color-black"
          mat-menu-item
          [matMenuTriggerFor]="billing"
        >
          <img
            class="img-responsive pr-2 mb-2"
            src="assets/Opiton Icon/Patient/Billing.png"
            alt=""
            height="14"
          />
          Billing Dept.
        </button>
        <!-- Shipping List -->
        <button
          class="custom-mainmenu-btn pt-1 color-black"
          mat-menu-item
          [matMenuTriggerFor]="shipping"
        >
          <img
            class="img-responsive pr-2 mb-2"
            src="assets/Patient Menu Icons/Shipping List.png"
            alt=""
            height="14"
          />
          Shipping Dept.
        </button>

        <!-- Claim -->
        <button
          class="custom-mainmenu-btn pt-1 color-black"
          mat-menu-item
          [matMenuTriggerFor]="Claim"
        >
          <img
            class="img-responsive pr-2 mb-2"
            src="assets/Opiton Icon/Patient/Claim Process.png"
            alt=""
            height="14"
          />
          Claim Dept.
        </button>
        <mat-divider></mat-divider>
        <!-- Allowables Lookup-->
        <a
          class="custom-mainmenu-btn pt-1 color-black"
          routerLink="inventory/insurance-allowables"
          mat-menu-item
        >
          <img
            class="img-responsive pr-2 mb-2"
            src="assets\Opiton Icon\Inventory\Allowables List.png"
            alt=""
            height="14"
          />
          Allowables Lookup
        </a>
      </mat-menu>

      <!-- Sub Menus -->
      <!-- Practice List -->

      <mat-menu #practice="matMenu">
        <!-- Practice List -->
        <a
          type="button"
          class="custom-mainmenu-btn pt-1 color-black"
          routerLink="/practicemanagementlist"
          mat-menu-item
        >
          <img
            class="img-responsive pr-2 mb-2"
            src="assets/Opiton Icon/Patient/patientList.png"
            height="14"
            alt="Practice List"
          />
          Practice List
        </a>
        <!-- Report -->
        <a
          type="button"
          class="custom-mainmenu-btn pt-1 color-black"
          routerLink="/report-practicemanagement"
          mat-menu-item
        >
          <img
            class="img-responsive pr-2 mb-2"
            src="assets/Opiton Icon/Patient/MyMediareChecklist.png"
            height="14"
            alt="Report"
          />
          Report
        </a>
      </mat-menu>

      <mat-menu #orders="matMenu">
        <!-- Orders -->
        <a
          [routerLink]="['patient/order-list']"
          class="custom-mainmenu-btn pt-1 color-black"
          mat-menu-item
          type="button"
        >
          <img
            class="img-responsive pr-2 mb-2"
            src="assets/Opiton Icon/Patient/Orders.png"
            alt=""
            height="14"
          />
          Orders
        </a>

        <!-- Exchange Orders -->
        <a
          class="custom-mainmenu-btn pt-1 color-black"
          [routerLink]="['orders/exchange-list']"
          mat-menu-item
          type="button"
        >
          <img
            class="img-responsive pr-2 mb-2"
            src="assets/Patient Menu Icons/Exchange List.png"
            alt=""
            height="14"
          />
          Pickup/Exchange Orders
        </a>

        <!-- Rental List -->
        <a
          [routerLink]="['patient/rental-list']"
          type="button"
          class="custom-mainmenu-btn pt-1 color-black"
          mat-menu-item
        >
          <img
            class="img-responsive pr-2 mb-2"
            src="assets/Patient Menu Icons/Subtract.png"
            alt=""
            height="14"
          />

          Rental List
        </a>

        <!-- Override List -->
        <a
          class="custom-mainmenu-btn pt-1 color-black"
          routerLink="orders/override-list"
          type="button"
          mat-menu-item
        >
          <img
            class="img-responsive pr-2 mb-2"
            src="assets/Patient Menu Icons/Override List.png"
            alt=""
            height="14"
          />
          Override Orders
        </a>

        <!-- Return Orders List -->
        <a
          type="button"
          class="custom-mainmenu-btn pt-1 color-black"
          mat-menu-item
          routerLink="orders/return-orders-list"
        >
          <img
            class="img-responsive pr-2 mb-2"
            src="assets/Patient Menu Icons/Return Orders.png"
            alt=""
            height="14"
          />
          Return Orders
        </a>
        <!-- Hello Signed Report List -->
        <a
          [routerLink]="['patient/order-list/hellosignreport']"
          type="button"
          class="custom-mainmenu-btn pt-1 color-black"
          mat-menu-item
          routerLink="orders/return-orders-list"
        >
          <img
            class="img-responsive pr-2 mb-2"
            src="assets/Patient Menu Icons/helloSignReport.png"
            alt=""
            height="14"
          />
          Hello Sign Report
        </a>
        <!-- Resupply -->
        <a
          [routerLink]="['resupply']"
          type="button"
          class="custom-mainmenu-btn pt-1 color-black"
          mat-menu-item
        >
          <img
            class="img-responsive pr-2 mb-2"
            src="assets/Opiton Icon/Patient/Shipping.png"
            alt=""
            height="14"
          />
          Resupply
        </a>
      </mat-menu>

      <mat-menu #billing="matMenu">
        <!-- Shipping Approval -->
        <a
          type="button"
          routerLink="orders/shipping-approvallist-tab"
          class="custom-mainmenu-btn pt-1 color-black"
          mat-menu-item
        >
          <img
            class="img-responsive pr-2 mb-2"
            src="assets/Opiton Icon/Patient/Shipping.png"
            alt=""
            height="14"
          />
          Shipping Approval
        </a>

        <!-- Billing List -->
        <a
          type="button"
          routerLink="billing-list"
          class="custom-mainmenu-btn pt-1 color-black"
          mat-menu-item
        >
          <img
            class="img-responsive pr-2 mb-2"
            src="assets/Opiton Icon/Patient/Billing.png"
            alt=""
            height="14"
          />
          Billing List
        </a>

        <!-- New Insurance Review -->
        <a
          type="button"
          [routerLink]="['patient/new-insurance-review']"
          class="custom-mainmenu-btn pt-1 color-black"
          mat-menu-item
        >
          <img
            class="img-responsive pr-2 mb-2"
            src="assets\Opiton Icon\Inventory\Allowables List.png"
            alt=""
            height="14"
          />
          New Insurance Review
        </a>
        <!-- Billing-->
        <a
          type="button"
          [routerLink]="['billing/billing']"
          class="custom-mainmenu-btn pt-1 color-black"
          mat-menu-item
        >
          <img
            class="img-responsive pr-2 mb-2"
            src="assets\Opiton Icon\Inventory\Allowables List.png"
            alt=""
            height="14"
          />
          Billing
        </a>
      </mat-menu>
      <!-- Shipping Dept -->
      <mat-menu #shipping="matMenu">
        <!-- Shipping List -->
        <a
          routerLink="shipping-list"
          type="button"
          class="custom-mainmenu-btn pt-1 color-black"
          mat-menu-item
        >
          <img
            class="img-responsive pr-2 mb-2"
            src="assets/Patient Menu Icons/Shipping List.png"
            alt=""
            height="14"
          />
          Shipping List
        </a>

        <!-- Shipping Disapproved List -->
        <a
          class="custom-mainmenu-btn pt-1 color-black"
          routerLink="orders/shipping-disapproved-list"
          mat-menu-item
        >
          <img
            class="img-responsive pr-1 mb-2"
            src="assets/Opiton Icon/Patient/Shipping.png"
            alt=""
            height="14"
          />
          Shipping Disapproved List
        </a>
        <!-- Requisition Viewer -->
        <a
          routerLink="inventory/requisition-viewer"
          class="custom-mainmenu-btn pt-1 color-black"
          mat-menu-item
        >
          <img
            class="img-responsive pr-2 mb-2"
            src="assets/Opiton Icon/Patient/RecivingStatus.png"
            alt=""
            height="14"
          />
          Requisition Viewer
        </a>
      </mat-menu>

      <!-- Claim -->
      <mat-menu #Claim="matMenu">
        <!-- Claim Process -->
        <a
          type="button"
          class="custom-mainmenu-btn pt-1 color-black"
          mat-menu-item
          routerLink="billing/claimprocessing-list"
        >
          <img
            class="img-responsive pr-2 mb-2"
            src="assets/Opiton Icon/Patient/Claim Process.png"
            alt=""
            height="14"
          />
          Claim Process
        </a>

        <!-- Claim Batch -->
        <a
          type="button"
          class="custom-mainmenu-btn pt-1 color-black"
          mat-menu-item
          routerLink="billing/claim-batch"
        >
          <img
            class="img-responsive pr-2 mb-2"
            src="assets/Opiton Icon/Patient/Claim-Batch.png"
            alt=""
            height="14"
          />
          Claim Batch
        </a>

        <!-- Auto Posting ERA -->
        <a
          type="button"
          class="custom-mainmenu-btn pt-1 color-black"
          mat-menu-item
          routerLink="billing/auto-posting-era"
        >
          <img
            class="img-responsive pr-2 mb-2"
            src="assets/Opiton Icon/Patient/Auto_Posting(ERA).png"
            alt=""
            height="14"
          />
          Auto Posting (ERA)
        </a>
        <!-- Claim Status -->
        <a
          type="button"
          [routerLink]="'billing/claims-list'"
          class="custom-mainmenu-btn pt-1 color-black"
          mat-menu-item
        >
          <img
            class="img-responsive pr-2 mb-2"
            src="assets/Opiton Icon/Patient/Claim_Status_List.png"
            alt=""
            height="14"
          />
          Claim Status List
        </a>

        <!-- Claim Cheque List -->
        <a
          type="button"
          [routerLink]="'billing/claim-check-list'"
          class="custom-mainmenu-btn pt-1 color-black"
          mat-menu-item
        >
          <img
            class="img-responsive pr-2 mb-2"
            src="assets/Opiton Icon/Patient/Claim Status.png"
            alt=""
            height="14"
          />
          Claim Check List
        </a>
      </mat-menu>
      <!-- List End -->

      <!--#endregion -->

      <!--#region Inventory  -->
      <!-- Inventory -->
      <button
        *ngIf="!tenantUserHide"
        class="buttonColor navBar-button"
        mat-button
        [matMenuTriggerFor]="inventorymanagement"
      >
        <img
          class="img-responsive pr-2 mb-2"
          src="assets/Tab menu/inventory.png"
          height="14"
          alt="Inventory Management"
        />
        Inventory Management
        <mat-icon class="color-white">arrow_drop_down</mat-icon>
      </button>
      <mat-menu #inventorymanagement="matMenu">
        <!-- Category -->
        <a
          type="button"
          routerLink="/inventory/product-category-tab"
          mat-menu-item
          class="custom-mainmenu-btn pt-1 color-black"
        >
          <img
            class="img-responsive pr-2 mb-2"
            src="assets\Opiton Icon\Inventory\Product Category.png"
            alt=""
            height="14"
          />
          Category
        </a>

        <!-- Manufacturer -->
        <a
          routerLink="/inventory/vendor-tab"
          type="button"
          class="custom-mainmenu-btn pt-1 color-black"
          mat-menu-item
        >
          <img
            class="img-responsive pr-2 mb-2"
            src="assets\Opiton Icon\Inventory\Vendor.png"
            alt=""
            height="14"
          />
          Manufacturer
        </a>

        <!-- Product -->
        <a
          routerLink="/inventory/product"
          type="button"
          mat-menu-item
          class="custom-mainmenu-btn pt-1 color-black"
        >
          <img
            class="img-responsive pr-2 mb-2"
            src="assets\Opiton Icon\Inventory\Product List.png"
            alt=""
            height="14"
          />
          Product
        </a>

        <a
          routerLink="/inventory/productupdate"
          type="button"
          mat-menu-item
          class="custom-mainmenu-btn pt-1 color-black"
        >
          <img
            class="img-responsive pr-2 mb-2"
            src="assets\Opiton Icon\Inventory\Product List.png"
            alt=""
            height="14"
          />
          Product Unit Cost Update
        </a>

        <mat-divider></mat-divider>
        <!-- Purchase Order -->
        <a
          routerLink="/inventory/purchase-order"
          mat-menu-item
          class="custom-mainmenu-btn pt-1 color-black"
        >
          <img
            class="img-responsive pr-2 mb-2"
            src="assets/Opiton Icon/Inventory/Purchase Order.png"
            alt=""
            height="14"
          />
          Purchase Order
        </a>
        <mat-divider></mat-divider>
        <!-- Allowables List -->
        <a
          routerLink="inventory/insurance-allowables-list"
          mat-menu-item
          class="custom-mainmenu-btn pt-1 color-black"
        >
          <img
            class="img-responsive pr-2 mb-2"
            src="assets\Opiton Icon\Inventory\Allowables List.png"
            alt=""
            height="14"
          />
          Insurance Allowables List
        </a>
        <a
          routerLink="inventory/insurance-allowables-update"
          mat-menu-item
          class="custom-mainmenu-btn pt-1 color-black"
        >
          <img
            class="img-responsive pr-1 mb-1"
            src="assets\Opiton Icon\Inventory\Allowable.png"
            alt=""
            height="20"
          />
          Insurance Allowables
        </a>
      </mat-menu>
      <!--#endregion -->

      <!--#region Master  -->
      <button
        *ngIf="!tenantUserHide"
        class="buttonColor navBar-button"
        mat-button
        [matMenuTriggerFor]="master"
      >
        <img
          class="img-responsive pr-2 mb-2"
          src="assets/Tab menu/Master.png"
          height="14"
          alt="Master"
        />
        Master
        <mat-icon class="color-white">arrow_drop_down</mat-icon>
      </button>
      <mat-menu #master="matMenu">
        <!-- Notes -->
        <a
          type="button"
          routerLink="/inventory/notes"
          class="custom-mainmenu-btn pt-1 color-black"
          mat-menu-item
        >
          <img
            class="img-responsive pr-2 mb-2"
            src="assets\Opiton Icon\Master\Notes.png"
            alt=""
            height="14"
          />
          Notes
        </a>

        <!-- Payor -->
        <a
          routerLink="/inventory/payor"
          type="button"
          class="custom-mainmenu-btn pt-1 color-black"
          mat-menu-item
        >
          <img
            class="img-responsive pr-2 mb-2"
            src="assets/Opiton Icon/Patient/Payor.png"
            alt=""
            height="14"
          />
          Payor
        </a>

        <!-- Document Bin -->
        <a
          routerLink="/master/document-bin"
          type="button"
          class="custom-mainmenu-btn pt-1 color-black"
          mat-menu-item
        >
          <img
            class="img-responsive pr-2 mb-2"
            src="assets/Opiton Icon/Patient/Document Bin.png"
            alt=""
            height="14"
          />
          Document Bin
        </a>

        <!-- Provider -->
        <a
          class="custom-mainmenu-btn pt-1 color-black"
          routerLink="/inventory/provider"
          mat-menu-item
        >
          <img
            class="img-responsive pr-2 mb-2"
            src="assets\Opiton Icon\Master\Provider.png"
            alt=""
            height="14"
          />
          Provider
        </a>

        <!-- Modifiers -->
        <a
          type="button"
          class="custom-mainmenu-btn pt-1 color-black"
          routerLink="/inventory/master-modifiers"
          mat-menu-item
        >
          <img
            class="img-responsive pr-2 mb-2"
            src="assets\images\menu icons\Icon\Provider.png"
            alt=""
            height="14"
          />
          Modifiers
        </a>

        <!-- Size -->
        <a
          mat-menu-item
          routerLink="/inventory/master-size"
          type="button"
          class="custom-mainmenu-btn pt-1 color-black"
        >
          <img
            class="img-responsive pr-2 mb-2"
            src="assets\images\menu icons\Icon\Provider.png"
            alt=""
            height="14"
          />
          Size
        </a>

        <!-- Mask -->
        <a
          class="custom-mainmenu-btn pt-1 color-black"
          routerLink="inventory/master-mask"
          mat-menu-item
          [hidden]="true"
        >
          <img
            class="img-responsive pr-2 mb-2"
            src="assets/Opiton Icon/Patient/Document Bin.png"
            alt=""
            height="14"
          />
          Mask
        </a>

        <!-- Order Return / Exchange -->
        <a
          routerLink="inventory/order-return"
          type="button"
          class="custom-mainmenu-btn pt-1 color-black"
          mat-menu-item
          [hidden]="true"
        >
          <img
            class="img-responsive pr-2 mb-2"
            src="assets/Opiton Icon/Patient/Document Bin.png"
            alt=""
            height="14"
          />
          Order Return/Exchange
        </a>
        <!-- MM Insurance Group -->
        <a
          type="button"
          class="custom-mainmenu-btn pt-1 color-black"
          mat-menu-item
          routerLink="/inventory/master-mminsurance"
        >
          <img
            class="img-responsive pr-2 mb-2"
            src="assets/Opiton Icon/Patient/Document Bin.png"
            alt=""
            height="14"
          />
          MM Insurance Group
        </a>
        <!--order shippping-->
        <a
          type="button"
          class="custom-mainmenu-btn pt-1 color-black"
          mat-menu-item
          routerLink="ordershippping"
        >
          <img
            class="img-responsive pr-2 mb-2"
            src="assets/Opiton Icon/Patient/Billing Reports.png"
            height="14"
            alt="Order shippping"
          />
          Order shippping
        </a>
        <!--Discount-->
        <a
          type="button"
          class="custom-mainmenu-btn pt-1 color-black"
          mat-menu-item
          routerLink="/inventory/discount"
          [hidden]="true"
        >
          <img
            class="img-responsive pr-2 mb-2"
            src="assets/Patient Menu Icons/Override List.png"
            alt=""
            height="14"
          />
          Discount
        </a>

        <!--Template-->
        <a
          type="button"
          class="custom-mainmenu-btn pt-1 color-black"
          mat-menu-item
          routerLink="/master/template"
        >
          <img
            class="img-responsive pr-2 mb-2"
            src="assets\Patient Menu Icons\Vector.png"
            alt=""
            height="14"
          />
          Template
        </a>

        <!-- ReSupply -->
        <a
          type="button"
          routerLink="/master/resupply"
          class="custom-mainmenu-btn pt-1 color-black"
          mat-menu-item
        >
          <img
            class="img-responsive pr-2 mb-2"
            src="assets/Opiton Icon/Patient/Shipping.png"
            alt=""
            height="14"
          />
          Resupply
        </a>

        <!-- Physician List -->
        <a
          type="button"
          routerLink="/master/physician"
          class="custom-mainmenu-btn pt-1 color-black"
          mat-menu-item
        >
          <img
            class="img-responsive pr-1 mb-1"
            src="assets/images/doctor2.png"
            alt=""
            height="16"
          />
          Physician List
        </a>

        <!-- <a
      type="button"
      routerLink="/master/twoFactorAuthentication"
      class="custom-mainmenu-btn pt-1 color-black"
      mat-menu-item
    >
    <img class="img-responsive pr-2 mb-2" src="assets/Opiton Icon/Patient/Shipping.png" alt=""
        height="14" />
      Two Factor Authentication
    </a> -->
      </mat-menu>
      <!-- #endregion -->

      <!--#region MIS Reports  -->

      <button
        *ngIf="!tenantUserHide"
        class="buttonColor navBar-button"
        mat-button
        [matMenuTriggerFor]="misreports"
      >
        <img
          class="img-responsive pr-2 mb-2"
          src="assets/Tab menu/MIS.png"
          height="14"
          alt="MIS Reports"
        />
        MIS Reports
        <mat-icon class="color-white">arrow_drop_down</mat-icon>
      </button>

      <mat-menu #misreports="matMenu">
        <!-- Patient Statement -->
        <a
          class="custom-mainmenu-btn pt-1 color-black"
          [routerLink]="['patient-statement-report']"
          mat-menu-item
          type="button"
        >
          <img
            class="img-responsive pr-2 mb-2"
            src="assets/Opiton Icon/Patient/Billing Reports.png"
            alt=""
            height="14"
          />
          Patient Statement
        </a>

        <!-- Billing Reports -->
        <a
          class="custom-mainmenu-btn pt-1 color-black"
          [routerLink]="['billing-report']"
          mat-menu-item
          type="button"
        >
          <img
            class="img-responsive pr-2 mb-2"
            src="assets/Opiton Icon/Patient/Billing Reports.png"
            alt=""
            height="14"
          />
          Billing Reports
        </a>

        <!-- AR Aging Reports -->
        <a
          class="custom-mainmenu-btn pt-1 color-black"
          [routerLink]="['ar-aging-report']"
          mat-menu-item
        >
          <img
            class="img-responsive pr-2 mb-2"
            src="assets\Opiton Icon\MIS Report\Billing report.png"
            alt=""
            height="14"
          />
          AR Aging Reports
        </a>

        <!-- Auth Followup Reports -->
        <a
          class="custom-mainmenu-btn pt-1 color-black"
          [routerLink]="['auth-followup-report']"
          mat-menu-item
        >
          <img
            class="img-responsive pr-2 mb-2"
            src="assets/Opiton Icon/Patient/auth Followup Status.png"
            alt=""
            height="14"
          />
          Auth Follow Up Reports
        </a>

        <!-- Order Followup Reports -->
        <a
          type="button"
          class="custom-mainmenu-btn pt-1 color-black"
          [routerLink]="['order-follow-up-reports']"
          mat-menu-item
        >
          <img
            class="img-responsive pr-2 mb-2"
            src="assets/Opiton Icon/Patient/order follow up.png"
            alt=""
            height="14"
          />
          Order Follow Up Report
        </a>

        <!-- Document Follow Up Report -->
        <a
          type="button"
          class="custom-mainmenu-btn pt-1 color-black"
          [routerLink]="['document-follow-up-reports']"
          mat-menu-item
        >
          <img
            class="img-responsive pr-2 mb-2"
            src="assets\Opiton Icon\MIS Report\AR Aging Report.png"
            alt=""
            height="14"
          />
          Document Follow Up Report
        </a>

        <a
          type="button"
          class="custom-mainmenu-btn pt-1 color-black"
          [routerLink]="['rx-report']"
          mat-menu-item
        >
          <img
            class="img-responsive pr-2 mb-2"
            src="assets\Opiton Icon\MIS Report\rx document.png"
            alt=""
            height="14"
          />
          Rx Report
        </a>

        <a
          type="button"
          class="custom-mainmenu-btn pt-1 color-black"
          [routerLink]="['text-conversation']"
          mat-menu-item
        >
          <img
            class="img-responsive pr-2 mb-2"
            src="assets/Opiton Icon/Patient/RecivingStatus.png"
            alt=""
            height="14"
          />
          Text Conversation
        </a>
      </mat-menu>

      <!-- #endregion -->

      <!--#region Settings  -->
      <!-- Settings -->
      <!-- Tenant Settings -->
      <!-- (click)="clientView()" -->
      <a
        *ngIf="!tenantUserHide"
        [routerLink]="['clientView/' + id]"
        class="buttonColor color-white pt-1 navBar-button"
        mat-button
      >
        <img
          class="img-responsive pb-2 mr-1"
          height="22"
          src="assets/Tab menu/settingsv1.png"
          alt="Settings"
        />
        Settings
      </a>
      <!-- Super Admin Settings -->
      <!-- <button *ngIf="tenantUserHide" class="buttonColor" mat-button>
        <a class="color-black">
          <img
            class="img-responsive pr-2 mb-2"
            src="assets/Tab menu/settingsv1.png"
            height="22"
          />
          Settings
        </a>
      </button> -->
      <!-- Client -->
      <a
        class="color-white buttonColor pt-1"
        routerLink="/clientList"
        *ngIf="tenantUserHide"
        mat-button
      >
        <img
          class="img-responsive pr-2 mb-2"
          src="assets/Tab menu/Client.png"
          height="14"
          alt="Client"
        />
        Client

        <!-- <mat-icon style="color:white">arrow_drop_down</mat-icon> -->
      </a>

      <!-- Settings End -->
      <!--#endregion -->
    </div>

    <!--#region Notification  -->
    <!-- Dont Delete -->
    <!-- <div class="nav-link">
      <app-notiifcation></app-notiifcation>
    </div> -->
    <!--#endregion -->

    <!--#region Profile  -->
    <!-- Profile -->
    <div class="dropdown d-inline-block navbar-nav" ngbDropdown>
      <button type="button" class="btn header-item">
        <!-- <span class="d-xl-inline-block ml-1 mr-2">
          <span> Welcome to Qsecure </span>
        </span> -->
        <!-- <span class=" d-xl-inline-block ml-2 mr-3">
          <span class="mr-2">{{ LoginUserTenenatName===null ?LoginUserName: LoginUserName+ "/" +LoginUserTenenatName
            }}</span>
          <br> -->
        <!-- <span class="mr-2">{{LoginUserTenenatName}}</span> -->
        <!-- <span class="mr-2">{{LoginUserorganizationUnitName}}</span> -->
        <!-- </span> -->
        <!-- <span class="d-xl-inline-block ml-1" (click)="logout()">
          <i class="fa fa-sign-out" > </i> Logout </span> -->
        <span>
          <div class="dropdown d-inline-block" ngbDropdown>
            <a
              class="btn font-size-18"
              id="page-header-user-dropdown"
              ngbDropdownToggle
            >
              <img [src]="fileAs64Value" alt="" height="35" width="35" />
            </a>
            <div class="dropdown-menu dropdown-menu-right" ngbDropdownMenu>
              <a
                class="context-menu dropdown-item"
                routerLinkActive
                #item="routerLinkActive"
              >
                <img
                  class="pr-2 mb-2"
                  src="assets\Opiton Icon\Logout\Admin.png"
                  alt=""
                  height="20"
                />
                {{
                  LoginUserTenenatName === null ? LoginUserName : LoginUserName
                }}
              </a>
              <a
                *ngIf="clientLoginlogoHide"
                class="context-menu dropdown-item"
                routerLinkActive
                #item="routerLinkActive"
              >
                <img
                  class="pr-2 mb-2"
                  src="assets\Opiton Icon\Logout\Warehouse.png"
                  alt=""
                  height="20"
                />
                <span>
                  {{ LoginUserTenenatName }}
                </span>
              </a>
              <a
                class="dropdown-item"
                (click)="logout()"
                [routerLink]="[]"
                routerLinkActive
                #item="routerLinkActive"
              >
                <img
                  class="pr-2 mb-2"
                  src="assets\Opiton Icon\Logout\Logout.png"
                  alt=""
                  height="20"
                />
                <span> Logout </span>
              </a>
            </div>
          </div>
        </span>
      </button>
      <!-- </div> -->
      <!-- <div class="dropdown d-inline-block" ngbDropdown>
      <button type="button" class="btn header-item" id="page-header-user-dropdown" ngbDropdownToggle>
        <img class="rounded-circle header-profile-user" src="assets/images/users/avatar-1.jpg" alt="Header Avatar" />
        <span class="d-none d-xl-inline-block ml-1"> {{ LoginUserName }}</span>
        <i class="mdi mdi-chevron-down d-none d-xl-inline-block"></i>
      </button>
      <div class="dropdown-menu dropdown-menu-right" ngbDropdownMenu>
        <a *ngif="LoginUserTenenatName !== null" class="dropdown-item" >
          <p class="text-muted"> {{ LoginUserTenenatName }}</p>
        </a>
        <div class="dropdown-divider"></div>
        <a class="dropdown-item text-danger" (click)="logout()">
          <i class="fa fa-sign-out" > </i>
          <i class="bx bx-power-off font-size-16 align-middle mr-1 text-danger"></i> Logout</a>
      </div>
    </div> -->
    </div>

    <!--#endregion -->
  </nav>
</header>

<!-- end header -->
