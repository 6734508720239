import { ListService } from '@abp/ng.core';
import { DatePipe } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import {
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatOption } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSelect } from '@angular/material/select';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { Store } from '@ngxs/store';
import moment, { Moment } from 'moment';
import {
  DaterangepickerDirective,
  LocaleConfig,
} from 'ngx-daterangepicker-material';
import { DashboardService } from 'projects/admin/src/app/admin-proxy/platform-app-management/rcm/platform-management/dashboard';
import { SearchSaleorderDTO } from 'projects/admin/src/app/warehouse-proxy/order/dto/models';
import {
  OrdersDTO,
  SaleorderDTO,
} from 'projects/order/src/app/order-proxy/order-management/order/dto/models';
import { SaleOrderSearchService } from 'projects/order/src/app/order-proxy/order-management/order/sale-order-search.service';
import { SaleorderService } from 'projects/order/src/app/order-proxy/order-management/order/saleorder.service';
import { ShippingSearchService } from 'projects/order/src/app/order-proxy/order-management/order/shipping-search.service';
import { ChartDTO } from 'projects/patient/src/app/dropdown-proxy/dropdown-management/dropdowns/dto/order/models';
import {
  PatientExportDatas,
  PatientExportDTO,
} from 'projects/patient/src/app/patient-proxy/patient/dto/models';
import { ExportType } from 'projects/patient/src/app/patient-proxy/patient/enumeration-data/export-type.enum';
import { ReportService } from 'projects/patient/src/app/patient-proxy/patient/report.service';
import { CalendarViewComponent } from 'projects/schedule/src/app/calendar-view/calendar-view.component';
import { TableService } from 'projects/shared/src/app/table.service';
import { DateValidator } from 'projects/shared/src/app/validations/date-validator';
import { Observable, Subscription } from 'rxjs';
import { finalize, map, startWith } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { ToastrService } from "ngx-toastr";

@Component({
  selector: 'app-shipping-approval-list',
  templateUrl: './shipping-approval-list.component.html',
  styleUrls: ['./shipping-approval-list.component.scss'],
  providers: [ListService, DatePipe],
})
export class ShippingApprovalListComponent implements OnInit, OnDestroy {
  //! Variable Initalization
  strSelectedBillingId: string = '';
  drpChart: ChartDTO[] = [];
  orderId: string = '';
  verifyTableData: any[] = [];
  orderForm: FormGroup;
  isShownUpdateIconForOrder: boolean = true;
  public arrDisplayedColumns: string[] = [];
  @Output() orderIdEmitter: EventEmitter<OrdersDTO> =
    new EventEmitter<OrdersDTO>();
  dataSource: MatTableDataSource<any> = new MatTableDataSource<any>([]);
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatTable, { static: false }) tableRef: MatTable<any>;
  @ViewChild(DaterangepickerDirective, { static: false })
  pickerDirective: DaterangepickerDirective;

  // Field Search Declartions

  shippingFilterForm: FormGroup;

  // dropdown clear valus filter
  orderSearchForm: FormGroup;
  saleOrderID: string;
  patientID: string;
  patientName: string;
  CsrName: string;
  ticketDate: string;
  ticketId: string;
  status: string;
  drpSaleOrder;

  // calender declartions
  selectedRange: { startDate: Moment; endDate: Moment };
  ranges: any;
  minDate: Moment;
  maxDate: Moment;
  calendarLocale: LocaleConfig;
  calendarPlaceholder: string = '';

  // get dropdwon declarations

  filterSaleorder: Observable<any>;
  filterPatientId: Observable<any>;
  filterPatientName: Observable<any>;
  filterStatus: Observable<any>;
  filterTicketId: Observable<any>;
  filterCsrName: Observable<any>;
  drpPatientId: any[] = [];
  drpPatientName: any[] = [];
  drpStatus: any[] = [];
  drpTikcetId: any[] = [];
  drpCsrNames;
  isOpen = false;
  step: boolean;

  @ViewChild('allSaleOrderSelected') private allSaleOrderSelected: MatOption;
  @ViewChild('saleOrderSelect') saleOrderSelect: MatSelect;

  @ViewChild('allTicketDateSelected') private allTicketDateSelected: MatOption;
  @ViewChild('TicketDate') TicketDate: MatSelect;

  @ViewChild('allTicketIdSelected') private allTicketIdSelected: MatOption;
  @ViewChild('TicketIdSelect') TicketIdSelect: MatSelect;

  @ViewChild('allStatusSelected') private allStatusSelected: MatOption;
  @ViewChild('StatusSelect') StatusSelect: MatSelect;

  @ViewChild('allCsrNameSelected') private allCsrNameSelected: MatOption;
  @ViewChild('CsrNameSelect') CsrNameSelect: MatSelect;

  @ViewChild('allPatientNameSelected')
  private allPatientNameSelected: MatOption;
  @ViewChild('PatientNameSelect') PatientNameSelect: MatSelect;

  @ViewChild('allPatientIdSelected') private allPatientIdSelected: MatOption;
  @ViewChild('PatientIdSelect') PatientIdSelect: MatSelect;
  private subscription$: Subscription[] = [];
  constructor(
    public tableService: TableService,
    public title: Title,
    public router: Router,
    public list: ListService,
    private snackBar: MatSnackBar,
    private orderService: SaleorderService,
    private formBuilder: FormBuilder,
    private ShippingSearch: ShippingSearchService,
    private dateValidator: DateValidator,
    private orderSearchService: SaleOrderSearchService,
    public dialog: MatDialog,
    private toastr: ToastrService,
    private reportService: ReportService
  ) {
    this.calendarLocale = {
      customRangeLabel: 'Pick a date...',
      applyLabel: 'Apply',
      clearLabel: 'Clear',
      format: 'DD/MM/YYYY',
      daysOfWeek: ['su', 'mo', 'tu', 'we', 'th', 'fr', 'sa'],
      monthNames: [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Oct',
        'Nov',
        'Dec',
      ],
      firstDay: 1,
    };
    this.ranges = {
      Today: [moment(), moment()],
      Yesterday: [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
      'Current week': [moment().startOf('isoWeek'), moment().endOf('isoWeek')],
      'Next weekend': [this.getNextSaturday(), this.getNextSunday()],
      'Next 3 days': [moment().add(1, 'days'), moment().add(3, 'days')],
      'Last 7 Days': [moment().subtract(6, 'days'), moment()],
      'Last 30 Days': [moment().subtract(29, 'days'), moment()],
      // 'Current day': [moment(), moment()],
      // 'Next 2 days': [moment().add(1, 'days'), moment().add(2, 'days')],
      'This Month': [moment().startOf('month'), moment().endOf('month')],
      'Last Month': [
        moment().subtract(1, 'month').startOf('month'),
        moment().subtract(1, 'month').endOf('month'),
      ],
    };
  }
  organizationUnitId: string = '';
  isLoading: boolean = false;
  adminCsrNameShowHide: boolean = false;
  ngOnInit(): void {
    this.tableService.getOrderUpdate().subscribe(
      (value) => {
        if (value == true) {
          this.isShownUpdateIconForOrder = true;
        } else {
          this.isShownUpdateIconForOrder = false;
        }
      },
      (err) => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      }
    );
    this.orderForm = this.formBuilder.group({
      PatientId: new FormControl(''),
      PatientName: new FormControl(''),
      Date: new FormControl('', this.dateValidator.dateVaidator),
      Plan: new FormControl(''),
      VerifyName: new FormControl(''),
      Status: new FormControl(''),
      location: new FormControl(''),
      supplyDate: new FormControl('', this.dateValidator.dateVaidator),
      saleOrderId: new FormControl(''),
      email: new FormControl(''),
      payment: new FormControl(''),
    });
    this.orderForm.valueChanges.subscribe(
      (value: billingList) => {
        let filter: billingList = {
          PatientId: value.PatientId?.trim()?.toLowerCase(),
          PatientName: value.PatientName?.trim()?.toLowerCase(),
          Date: value.Date?.trim()?.toLowerCase(),
          Plan: value.Plan?.trim()?.toLowerCase(),
          VerifyName: value.VerifyName?.trim()?.toLowerCase(),
          Status: value.Status?.trim()?.toLowerCase(),
          location: value.location?.trim()?.toLowerCase(),
          supplyDate: value.supplyDate?.trim()?.toLowerCase(),
          saleOrderId: value.saleOrderId?.trim()?.toLowerCase(),
          email: value.email?.trim()?.toLowerCase(),
        };

        if (this.orderForm.valid) {
          this.dataSource.filter = JSON.stringify(filter);
          this.filtertable(filter);
        }
      },
      (err) => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      }
    );

    // field search implementataion
    this.shippingFilterForm = this.formBuilder.group({
      txtSaleorderId: new FormControl(''),
      txtSaleOrderSearch: new FormControl(''),
      txtPatientId: new FormControl(''),
      txtPatientIdSearch: new FormControl(''),
      txtPatientName: new FormControl(''),
      txtPatientNameSearch: new FormControl(''),
      txtCsrName: new FormControl(''),
      txtCsrNameSearch: new FormControl(''),
      txtStatus: new FormControl(''),
      txtStatusSearch: new FormControl(''),
      txtTicketId: new FormControl(''),
      txtTicketIdSearch: new FormControl(''),
      txtTicketDate: new FormControl(''),
      txtTicketDateSearch: new FormControl(''),
      txtFromDate: new FormControl(''),
      txtToDate: new FormControl(''),
    });
    this.snackBar.dismiss();
    this.title.setTitle('Qsecure | Shipping Approval');
    this.arrDisplayedColumns = [
      'saleOrderId',
      'patientId',
      'options',
      'PatientName',
      'csrName',
      'payment',
      'Status',
      'ticketId',
      'VerifyName',
    ];
    this.tableService.getOrganizationUnitId().subscribe(
      (Value) => {
        this.organizationUnitId = Value;
      },
      (err) => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      }
    );
    this.tableService.setSelectedPatientId('');
    this.getDropdown();
    this.step = false;
  }

  ngOnDestroy(): void {
    this.subscription$?.forEach((sub) => {
      sub && sub?.unsubscribe();
    });
  }
  checkAndFilterOutStatusBasedData(value: SaleorderDTO) {
    return (
      value?.orders?.isCompleted === 'Done' &&
      value?.orderProcessStatus === 'Ready For Order Complete Process' &&
      value?.isShippingApprove === 0 && (value?.payPalPaymentStatus === null || value?.payPalPaymentStatus === 'Paid')
    );
  }
  // ! Get Inital Table Data
  getTableData(): void {
    this.isLoading = true;
    const itemList = (query: any) => this.orderSearchService.getList(query);
    this.verifyTableData = [];
    const itemLists = this.list.hookToQuery(itemList).pipe(finalize(() => {
      this.isLoading = false;
    })).subscribe(
      (response) => {
        response?.items?.forEach((value) => {
          if (this.checkAndFilterOutStatusBasedData(value)) {
            this.verifyTableData.push({
              patientId: value?.patients?.defaultPatientId,
              PatientName: value?.patients?.personals?.fullName,
              createDate:
                value?.creationTime == null
                  ? undefined
                  : new Date(value?.creationTime)?.toLocaleDateString('en-US'),
              plan: value?.patients?.insuranceVerifications
                ?.primaryInsuranceVerifies?.plan,
              VerifyName:
                value?.patients?.insuranceVerifications
                  ?.primaryInsuranceVerifies?.planName,
              csrName:
                value?.shippingCheckList?.userName
                  ?.toLocaleLowerCase()
                  ?.trim() ?? '',
              //Status: value?.patients?.insuranceVerifications?.primaryInsuranceVerifies?.verificationStatus,
              Status: value?.orderStatus,
              saleOrderId: value?.saleorderId,
              location: value?.orders?.invLocationId,
              supplyDate:
                value?.orders?.scheduledDate == null
                  ? undefined
                  : new Date(value?.orders?.scheduledDate)?.toLocaleDateString(
                    'en-US'
                  ),
              email: value?.patients?.personals?.emailId,
              data: value?.id,
              patientID: value?.patientId,
              cashPayment: value?.isCashPayment,
              cash: value?.isCashPayment,
              ticketId: value?.shippingCheckList?.defaultTicketId,
              ticketCreatedDate:
                value?.shippingCheckList?.ticketCreatedDate == null
                  ? undefined
                  : new Date(
                    value?.shippingCheckList?.ticketCreatedDate
                  )?.toLocaleDateString('en-US'),
            });
          }
        });
        this.isLoading = false;

        const arrTableData = this.verifyTableData;
        this.dataSource = new MatTableDataSource(arrTableData);
        this.sort.disableClear = true;
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
      },
      (err) => {
        this.isLoading = false;

        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      }
    );
    this.subscription$.push(itemLists);
  }
  //!! search table

  //! search table
  filtertable(filter: billingList) {
    let saleOrderId = filter.saleOrderId;
    let PatientName = filter.PatientName;
    let PatientId = filter.PatientId;
    let plan = filter.Plan;
    let supplyDate = filter.supplyDate;
    let location = filter.location;
    let VerifyName = filter.VerifyName;
    let date = filter.Date;
    let Status = filter.Status;
    let email = filter.email;
    this.isLoading = true;
    this.verifyTableData = [];
    this.ShippingSearch.searchShippingBySSaleOrderIdAndSInsuranceNameAndSPatientIdAndSPatientNameAndDtSuppliedDateAndSEmailAndSShippingLocationAndDtCreatedDateAndSStatusAndSPlan(
      saleOrderId,
      VerifyName,
      PatientId,
      PatientName,
      supplyDate,
      email,
      location,
      dateYYYYMMDDtoMMDDYYYY(date),
      Status,
      plan
    ).pipe(finalize(() => {
      this.isLoading = false;
    })).subscribe(
      (data) => {
        this.verifyTableData = [];
        data?.items?.forEach((value) => {
          if (this.checkAndFilterOutStatusBasedData(value)) {
            this.verifyTableData.push({
              patientId: value?.patients?.defaultPatientId,
              PatientName: value?.patients?.personals?.fullName,
              createDate:
                value?.creationTime == null
                  ? undefined
                  : new Date(value?.creationTime)?.toLocaleDateString(
                    'en-US'
                  ),
              plan: value?.patients?.insuranceVerifications
                ?.primaryInsuranceVerifies?.plan,
              VerifyName:
                value?.patients?.insuranceVerifications
                  ?.primaryInsuranceVerifies?.planName,
              Status:
                value?.patients?.insuranceVerifications
                  ?.primaryInsuranceVerifies?.verificationStatus,
              saleOrderId: value?.saleorderId,
              location: value?.orders?.invLocationId,
              supplyDate:
                value?.orders?.scheduledDate == null
                  ? undefined
                  : new Date(
                    value?.orders?.scheduledDate
                  )?.toLocaleDateString('en-US'),
              email: value?.patients?.personals?.emailId,
            });
          }

        });
        this.isLoading = false;
        const arrTableData = this.verifyTableData;
        this.dataSource = new MatTableDataSource(arrTableData);
        this.sort.disableClear = true;
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
      },
      (err) => {
        this.isLoading = false;
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      }
    );
  }
  //! Table CLick Event Redirection
  viewShippingDetails(value?: { data?: string; patientID?: string }): void {
    this.router.navigate(['orders/shippingApproval/' + value?.data]);
    this.strSelectedBillingId = value?.data;
    this.tableService.setSelectedPatientId(value?.patientID);
  }

  // Field Search Functionality

  // Field fiter Search
  // dropdown clear functionality
  clearSaleOrderId() {
    this.saleOrderID = '';
    this.shippingFilterForm.patchValue({ txtSaleorderId: '' });
  }
  clearPatientId() {
    this.patientID = '';
    this.shippingFilterForm.patchValue({ txtPatientId: '' });
  }
  clearPatientName() {
    this.patientName = '';
    this.shippingFilterForm.patchValue({ txtPatientName: '' });
  }
  clearCsrName() {
    this.CsrName = '';
    this.shippingFilterForm.patchValue({ txtCsrName: '' });
  }
  clearTicketDate() {
    this.ticketDate = '';
    this.shippingFilterForm.patchValue({ txtFromDate: '' });
  }
  clearTicketId() {
    this.ticketId = '';
    this.shippingFilterForm.patchValue({ txtTicketId: '' });
  }
  clearStatus() {
    this.status = '';
    this.shippingFilterForm.patchValue({ txtStatus: '' });
  }
  // reset table
  reset() {
    this.shippingFilterForm.reset();
    this.getTableData();
  }

  // Filter Clear for saleorder id
  onFilterSaleorderIdChange() {
    this.clearPatientId();
    this.clearPatientName();
    this.clearCsrName();
    this.clearTicketDate();
    this.clearTicketId();
    this.clearStatus();
  }
  // Filter Clear for Patient id
  onFilterPatientIdChange() {
    this.clearSaleOrderId();
    this.clearPatientName();
    this.clearCsrName();
    this.clearTicketDate();
    this.clearTicketId();
    this.clearStatus();
  }
  // Filter Clear for Patient Name
  onFilterPatientNameChange() {
    this.clearSaleOrderId();
    this.clearPatientId();
    this.clearCsrName();
    this.clearTicketDate();
    this.clearTicketId();
    this.clearStatus();
  }
  // Filter Clear for CSR Name
  onFilterCSRNameChange() {
    this.clearSaleOrderId();
    this.clearPatientId();
    this.clearPatientName();
    this.clearTicketDate();
    this.clearTicketId();
    this.clearStatus();
  }
  // Filter Clear for Ticket id
  onFilterTicketIdChange() {
    this.clearSaleOrderId();
    this.clearPatientId();
    this.clearPatientName();
    this.clearTicketDate();
    this.clearCsrName();
    this.clearStatus();
  }
  // Filter Clear for Ticket id
  onFilterStatusChange() {
    this.clearSaleOrderId();
    this.clearPatientId();
    this.clearPatientName();
    this.clearTicketDate();
    this.clearCsrName();
    this.clearTicketId();
  }

  //filter clear for Ticket Date
  change(event: MouseEvent) {
    this.clearSaleOrderId();
    this.clearPatientId();
    this.clearPatientName();
    this.clearTicketId();
    this.clearCsrName();
    this.clearStatus();
  }

  // search table
  tableData: any[] = [];
  search() {
    let saleordersIds: any[] = [];
    let patientIds: any[] = [];
    let patientNames: any[] = [];
    let csrNames: any[] = [];
    let TicketIdSearch: any[] = [];
    let status: any[] = [];

    this.shippingFilterForm.value.txtSaleorderId !== '' &&
      this.shippingFilterForm.value.txtSaleorderId !== undefined &&
      this.shippingFilterForm.value.txtSaleorderId !== null &&
      [this.shippingFilterForm.value.txtSaleorderId].length !== 0 &&
      [this.shippingFilterForm.value.txtSaleorderId].forEach((val) => {
        val?.forEach((element) => {
          element !== undefined && element !== 0 && saleordersIds.push(element);
        });
      });

    this.shippingFilterForm.value.txtPatientId !== '' &&
      this.shippingFilterForm.value.txtPatientId !== undefined &&
      this.shippingFilterForm.value.txtPatientId !== null &&
      [this.shippingFilterForm.value.txtPatientId].length !== 0 &&
      [this.shippingFilterForm.value.txtPatientId].forEach((val) => {
        val?.forEach((element) => {
          element !== undefined && element !== 0 && patientIds.push(element);
        });
      });

    this.shippingFilterForm.value.txtPatientName !== '' &&
      this.shippingFilterForm.value.txtPatientName !== undefined &&
      this.shippingFilterForm.value.txtPatientName !== null &&
      [this.shippingFilterForm.value.txtPatientName].length !== 0 &&
      [this.shippingFilterForm.value.txtPatientName].forEach((val) => {
        val?.forEach((element) => {
          element !== undefined && element !== 0 && patientNames.push(element);
        });
      });

    this.shippingFilterForm.value.txtTicketId !== '' &&
      this.shippingFilterForm.value.txtTicketId !== undefined &&
      this.shippingFilterForm.value.txtTicketId !== null &&
      [this.shippingFilterForm.value.txtTicketId].length !== 0 &&
      [this.shippingFilterForm.value.txtTicketId].forEach((val) => {
        val?.forEach((element) => {
          element !== undefined &&
            element !== 0 &&
            TicketIdSearch.push(element);
        });
      });

    this.shippingFilterForm.value.txtStatus !== '' &&
      this.shippingFilterForm.value.txtStatus !== undefined &&
      this.shippingFilterForm.value.txtStatus !== null &&
      [this.shippingFilterForm.value.txtStatus].length !== 0 &&
      [this.shippingFilterForm.value.txtStatus].forEach((val) => {
        val?.forEach((element) => {
          element !== undefined && element !== 0 && status.push(element);
        });
      });

    this.shippingFilterForm.value.txtCsrName !== '' &&
      this.shippingFilterForm.value.txtCsrName !== undefined &&
      this.shippingFilterForm.value.txtCsrName !== null &&
      [this.shippingFilterForm.value.txtCsrName].length !== 0 &&
      [this.shippingFilterForm.value.txtCsrName].forEach((val) => {
        val?.forEach((element) => {
          element !== undefined && element !== 0 && csrNames.push(element);
        });
      });

    let shippingTicketSearch: SearchSaleorderDTO = {
      patientNames: patientNames,
      userIds: csrNames,
      patientIds: patientIds,
      orderIds: saleordersIds,
      ticketIds: TicketIdSearch,
      status: ['Ready To Shipping Approval'],
      ticketCreatedFromDate:
        this.selectedRange == null || '' || undefined
          ? null
          : this.selectedRange.startDate?.format('YYYY-MM-DD'),
      ticketCreatedToDate:
        this.selectedRange == null || '' || undefined
          ? null
          : this.selectedRange.endDate?.format('YYYY-MM-DD'),
    };

    const orderSearch = this.orderService
      .searchSaleorderByInput(shippingTicketSearch)
      .subscribe((response) => {
        this.tableData = [];
        response &&
          response?.items?.forEach((element) => {
            if (this.checkAndFilterOutStatusBasedData(element)) {
              this.tableData.push({
                id: element?.id,
                saleOrderId: element?.saleorderId,
                patientId: element?.patients?.defaultPatientId,
                PatientName: element?.patients?.personals?.fullName,
                csrName:
                  element?.shippingCheckList?.userName
                    ?.toLocaleLowerCase()
                    ?.trim() ?? '',
                ticketId: element?.shippingCheckList?.defaultTicketId,
                Status: element?.orderStatus,
                ticketCreatedDate:
                  element?.shippingCheckList?.ticketCreatedDate == null
                    ? undefined
                    : new Date(
                      element?.shippingCheckList?.ticketCreatedDate
                    )?.toLocaleDateString('en-US'),
              });
            }
          });
        this.dataSource = new MatTableDataSource(this.tableData);
        this.sort.disableClear = true;
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
      });
    this.subscription$.push(orderSearch);
  }
  // calender

  getNextSaturday() {
    const dayINeed = 6; // for Saturday
    const today = moment().isoWeekday();
    if (today <= dayINeed) {
      return moment().isoWeekday(dayINeed);
    } else {
      return moment().add(1, 'weeks').isoWeekday(dayINeed);
    }
  }
  openDateRangeCalander(e: MouseEvent): void {
    this.pickerDirective.open(e);
  }
  getNextSunday() {
    const dayINeed = 7; // for Sunday
    const today = moment().isoWeekday();
    if (today <= dayINeed) {
      return moment().isoWeekday(dayINeed);
    } else {
      return moment().add(1, 'weeks').isoWeekday(dayINeed);
    }
  }

  viewCalendar(id: string) {
    const dialogRef = this.dialog.open(CalendarViewComponent, {
      disableClose: true,
      // height: "80%",
      width: '80%',
      // maxHeight: '200px',
      // maxWidth: '600px',
      data: { calendarId: id },
    });

    const closeDialog = dialogRef.afterClosed().subscribe(() => { });
    this.subscription$.push(closeDialog);
  }

  // get dropdown for filter search
  getDropdown() {
    const orderDropdown = this.orderService
      .getWarehouseDropdowns()
      .subscribe((response) => {
        this.drpSaleOrder = response.orderIds;
        this.drpPatientId = response.patientIds;
        this.drpPatientName = response.patientNames;
        this.drpStatus = response.status;
        this.drpTikcetId = response.ticketIds;
        this.drpCsrNames = response.userDetails;

        this.filterSaleorder = this.shippingFilterForm
          .get('txtSaleOrderSearch')
          .valueChanges.pipe(
            startWith(''),
            map((value) => {
              return this.drpSaleOrder.filter((option) =>
                option?.toLowerCase()?.includes(value?.toLowerCase() ?? '')
              );
            })
          );

        this.filterPatientId = this.shippingFilterForm
          .get('txtPatientIdSearch')
          .valueChanges.pipe(
            startWith(''),
            map((value) => {
              return this.drpPatientId.filter((option) =>
                option?.toLowerCase()?.includes(value?.toLowerCase() ?? '')
              );
            })
          );

        this.filterPatientName = this.shippingFilterForm
          .get('txtPatientNameSearch')
          .valueChanges.pipe(
            startWith(''),
            map((value) => {
              return this.drpPatientName.filter((option) =>
                option?.toLowerCase()?.includes(value?.toLowerCase() ?? '')
              );
            })
          );

        this.filterTicketId = this.shippingFilterForm
          .get('txtTicketIdSearch')
          .valueChanges.pipe(
            startWith(''),
            map((value) => {
              return this.drpTikcetId.filter((option) =>
                option?.toLowerCase()?.includes(value?.toLowerCase() ?? '')
              );
            })
          );

        this.filterCsrName = this.shippingFilterForm
          .get('txtCsrNameSearch')
          .valueChanges.pipe(
            startWith(''),
            map((value) => {
              return this.drpCsrNames.filter((option) =>
                option?.userName
                  .toLowerCase()
                  ?.includes(value?.toLowerCase() ?? '')
              );
            })
          );

        this.filterStatus = this.shippingFilterForm
          .get('txtStatusSearch')
          .valueChanges.pipe(
            startWith(''),
            map((value) => {
              return this.drpStatus.filter((option) =>
                option?.toLowerCase()?.includes(value?.toLowerCase() ?? '')
              );
            })
          );
      });
    this.subscription$.push(orderDropdown);
    this.getTableData();
  }

  // select all functionality
  toggleAllSaleorderIdSelection() {
    if (this.allSaleOrderSelected.selected) {
      this.saleOrderSelect.options.forEach((item: MatOption) => item.select());
    } else {
      this.saleOrderSelect.options.forEach((item: MatOption) =>
        item.deselect()
      );
    }
  }

  toggleAllPatientIdSelection() {
    if (this.allPatientIdSelected.selected) {
      this.PatientIdSelect.options.forEach((item: MatOption) => item.select());
    } else {
      this.PatientIdSelect.options.forEach((item: MatOption) =>
        item.deselect()
      );
    }
  }

  toggleAllPatientNameSelection() {
    if (this.allPatientNameSelected.selected) {
      this.PatientNameSelect.options.forEach((item: MatOption) =>
        item.select()
      );
    } else {
      this.PatientNameSelect.options.forEach((item: MatOption) =>
        item.deselect()
      );
    }
  }

  toggleAllCsrNamesSelection() {
    if (this.allCsrNameSelected.selected) {
      this.CsrNameSelect.options.forEach((item: MatOption) => item.select());
    } else {
      this.CsrNameSelect.options.forEach((item: MatOption) => item.deselect());
    }
  }

  toggleAllStatusSelection() {
    if (this.allStatusSelected.selected) {
      this.StatusSelect.options.forEach((item: MatOption) => item.select());
    } else {
      this.StatusSelect.options.forEach((item: MatOption) => item.deselect());
    }
  }

  toggleAllTicketIdSelection() {
    if (this.allTicketIdSelected.selected) {
      this.TicketIdSelect.options.forEach((item: MatOption) => item.select());
    } else {
      this.TicketIdSelect.options.forEach((item: MatOption) => item.deselect());
    }
  }

  toggleAllTicketDateSelection() {
    if (this.allTicketDateSelected.selected) {
      this.TicketDate.options.forEach((item: MatOption) => item.select());
    } else {
      this.TicketDate.options.forEach((item: MatOption) => item.deselect());
    }
  }
  // export table excel
  exportTableData() {
    let datas: PatientExportDatas[] = [];
    this.dataSource?.data?.forEach((element) => {
      datas.push({
        saleOrderId: element?.saleOrderId,
        patientDefaultId: element?.patientId,
        patientName: element?.PatientName,
        csrName: element?.csrName?.toLocaleLowerCase()?.trim() ?? '',
        cashPayment: String(
          element?.cashPayment === 0 ? 'No' : element?.cashPayment === 'Yes'
        ),
        orderStatus: element?.Status,
        ticketId: element?.ticketId,
        ticketCreatedDate: element?.ticketCreatedDate,
      });
    });
    let patientsExport: PatientExportDTO = {
      columns: [
        'Sale Order Id',
        'Patient Id',
        'Patient Name',
        'CSR Name',
        'Cash Payment',
        'Status  ',
        'Ticket Id',
        'Ticket Date',
      ],
      datas: datas,
    };
    const exportReport = this.reportService
      .exportPatientReportsByExportTypeAndExportDatas(
        ExportType.ShippingApprovalReports,
        patientsExport
      )
      .subscribe(
        (response) => {
          if (response && response?.length !== 0) {
            let filelocation: String = String(
              'data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,' +
              response
            );
            let link = document.createElement('a');
            link.download = 'Shipping-Approval List' + '.xlsx';
            link.href = filelocation.toString();
            link.click();
            // Swal.fire({
            //   title: 'Success',
            //   html: 'Exported Successfully',
            //   icon: 'success',
            //   timer: 3000,
            //   timerProgressBar: true,
            // });
            this.toastr.success('Exported Successfully','Success')
          }
        },
        (err) => {
          const data: HttpErrorResponse = err;
          let errorMesg: string = data?.error;
          if (
            errorMesg?.includes('Sorry!! There is no data in table to export')
          ) {
            errorMesg = 'Sorry!! There is no data in table to export';
          } else {
            errorMesg = data?.error?.error?.message;
          }
          // Swal.fire({
          //   icon: 'info',
          //   text: errorMesg,
          // });
          this.toastr.info(errorMesg)
        }
      );
    this.subscription$.push(exportReport);
  }
}

export interface billingList {
  PatientId: string;
  PatientName: string;
  Date: string;
  Plan: string;
  VerifyName: string;
  Status: string;
  location: string;
  supplyDate: string;
  saleOrderId: string;
  email: string;
}

function dateYYYYMMDDtoMMDDYYYY(strDate: string): string {
  if (
    strDate &&
    strDate !== '' &&
    typeof strDate === 'string' &&
    strDate !== null &&
    strDate !== undefined &&
    strDate.length >= 1 &&
    !isNaN(new Date(strDate).getTime())
  ) {
    let latest_date = Intl.DateTimeFormat('en-US')?.format(new Date(strDate));
    return latest_date;
  }
  return '';
}
