<br />
<div class="card card-body">
  <form [formGroup]="postingForm">
    <div class="row">
      <div class="col-6">
        <div class="row">

          <div class="col-lg-6">
            <div class="row">
              <div class="col-md-12">
                <h4 class="customThemeClass"><b>Diagnosis Codes-ICD-10 <span class="asterisk">*</span></b></h4>
              </div>
            </div>
            <mat-card-content>
              <div class="row">
                <mat-form-field class="col-12">
                  <mat-label>Diagnosis Code-ICD-10</mat-label>
                  <mat-select multiple formControlName="drpDiag10ID" [(value)]="txtIcd10" #singleSelect>
                    <mat-option>
                      <ngx-mat-select-search placeholderLabel="Search" noEntriesFoundLabel="No Matches found"
                        formControlName="txtIcd10Filter"></ngx-mat-select-search>
                    </mat-option>
                    <mat-option *ngFor="let diagnosis of filteredIcd10Types | async"
                      [value]="diagnosis.diagnosisCode10ShortCodeId">
                      {{diagnosis.diagnosisCode + ' / ' + diagnosis.diagnosisCodeTypeData }}
                    </mat-option>
                  </mat-select>
                  <button mat-button (click)="txtIcd10=[];$event.stopPropagation()" *ngIf="txtIcd10" matSuffix
                    (click)="clearIcd10()" mat-icon-button>
                    <mat-icon>close</mat-icon>
                  </button>
                  <mat-error *ngIf="
                  postingForm?.get('drpDiag10ID')?.touched &&
                  postingForm?.get('drpDiag10ID')?.errors?.required
                ">
                    Diagnosis Code-ICD-10 is a required field!
                  </mat-error>
                </mat-form-field>
              </div>
            </mat-card-content>
          </div>

          <!-- <mat-form-field class="mgn col-6">
            <mat-label>Provider</mat-label>
            <input matInput formControlName="txtProvider" type="text" maxlength="40" />
          </mat-form-field> -->
          <mat-form-field class="mgn col-6">
            <mat-label>Provider </mat-label>
            <mat-select maxlength="100" [(value)]="provider" formControlName="txtProvider">
              <mat-option *ngFor="let providers of drpProviders" [value]="providers.shortCodeId">
                {{ providers.name }}

              </mat-option>
            </mat-select>
            <button mat-button (click)="provider='';$event.stopPropagation()" *ngIf="provider" matSuffix
              (click)="clearProvider()" mat-icon-button >
              <mat-icon>close</mat-icon>
            </button>
          </mat-form-field>

          <mat-form-field class="col-6">
            <mat-label>POS <span class="asterisk">*</span></mat-label>
            <mat-select formControlName="drpPos" maxlength="40" [(value)]="pos">
              <mat-option *ngFor="let pos of drpPos" [value]="pos.placeOfServiceCode">
                {{ pos.placeOfServiceType }}
              </mat-option>
            </mat-select>
            <button mat-button (click)="pos=undefined;$event.stopPropagation()" *ngIf="pos" matSuffix mat-icon-button
              (click)="clearPos()" >
              <mat-icon>close</mat-icon>
            </button>
            <mat-error *ngIf="postingForm?.get('drpPos')?.touched &&postingForm?.get('drpPos')?.errors?.required">
              POS is a required field!
            </mat-error>
          </mat-form-field>
          <!-- newly added params -->
          <!-- <mat-form-field class="col-6">
            <mat-label>Doctor</mat-label>
            <mat-select formControlName="txtReferringDoctor" [(value)]="ReferringDoctor" #singleSelect>
              <mat-option>
                <ngx-mat-select-search placeholderLabel="Search" noEntriesFoundLabel="No Matches found"
                  formControlName="txtRefDoctorFilter"></ngx-mat-select-search>
              </mat-option>
              <mat-option *ngFor="let doctor of filteredRefDoctorTypes | async" [value]="doctor.name">
                {{ doctor.name }}
              </mat-option>
            </mat-select>
            <button mat-button (click)="ReferringDoctor='';$event.stopPropagation()" *ngIf="ReferringDoctor"
              (click)="clearReferringDoctor()" matSuffix mat-icon-button >
              <mat-icon>close</mat-icon>
            </button>
          </mat-form-field> -->

          <mat-form-field *ngIf="doctorShow" class="col-6">
            <mat-label>Doctor <span class="asterisk">*</span></mat-label>
            <mat-select formControlName="txtReferringDoctor" maxlength="100" [(value)]="ReferringDoctor">
              <mat-option *ngFor="let doc of dropDoc" [value]="doc.name">
                {{ doc.name }}
              </mat-option>
            </mat-select>
            <button mat-button (click)="ReferringDoctor=undefined;$event.stopPropagation()" *ngIf="ReferringDoctor"
              matSuffix mat-icon-button (click)="clearReferringDoctor()" >
              <mat-icon>close</mat-icon>
            </button>
            <mat-error
              *ngIf=" postingForm?.get('txtReferringDoctor')?.touched && postingForm?.get('txtReferringDoctor')?.errors?.required">
              Doctor is a required field!
            </mat-error>
          </mat-form-field>

          <mat-form-field *ngIf="!doctorShow" class="col-6">
            <mat-label>Doctor <span class="asterisk">*</span></mat-label>
            <mat-select formControlName="txtReferringDoctor" maxlength="100" [(value)]="ReferringDoctor"
              (click)="clearReferringDoctor()">
              <mat-option *ngFor="let doc of dropDoc" [value]="doc.name">
                {{ doc.name }}
              </mat-option>
            </mat-select>
            <button mat-button (click)="ReferringDoctor=undefined;$event.stopPropagation()" *ngIf="ReferringDoctor"
              matSuffix mat-icon-button (click)="clearReferringDoctor()" >
              <mat-icon>close</mat-icon>
            </button>
          </mat-form-field>
          <!-- newly added params -->

        </div>

        <mat-card-content>
          <div class="row">

          </div>
        </mat-card-content>

      </div>

      <div class="col-6">
        <table class="table table-responsive custom-table" mat-table matSort [dataSource]="dataSource">
          <ng-container matColumnDef="Name">
            <th class="pl-2 pr-2" mat-header-cell *matHeaderCellDef mat-sort-header>Type</th>
            <td class="pl-2 pr-2" mat-cell *matCellDef="let record">
              {{ record.type }}
            </td>
          </ng-container>
          <ng-container matColumnDef="Starts">
            <th class="pl-2 pr-2" mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
            <td class="pl-2 pr-2" mat-cell *matCellDef="let record">
              {{ record.name }}
            </td>
          </ng-container>
          <ng-container matColumnDef="Ends">
            <th class="pl-2 pr-2" mat-header-cell *matHeaderCellDef mat-sort-header>Policy No</th>
            <td class="pl-2 pr-2" mat-cell *matCellDef="let record">
              {{ record.policyNo }}
            </td>
          </ng-container>
          <ng-container matColumnDef="Copay">
            <th class="pl-2 pr-2" mat-header-cell *matHeaderCellDef mat-sort-header>Copay</th>
            <td class="pl-2 pr-2" mat-cell *matCellDef="let record">
              {{ record.actualDate }}
            </td>
          </ng-container>
          <ng-container matColumnDef="Deduct">
            <th class="pl-2 pr-2" mat-header-cell *matHeaderCellDef mat-sort-header>Deduct</th>
            <td class="pl-2 pr-2" mat-cell *matCellDef="let record">
              {{ record.deductible }}
            </td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
          <tr mat-row *matRowDef="let tblPolicesReponse; columns: columnsToDisplay"></tr>
        </table>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="text-left">
          <h4 class="customThemeClass"><b>Authorization</b></h4>
        </div>
        <app-billing-authorization [orderId]='orderId' [patientId]='patientId'></app-billing-authorization>
      </div>
    </div>
  </form>
  <div class="row">
    <div class="col-12">
      &nbsp; <h4 class="customThemeClass"><b>Posting List</b></h4>
      <div class="row pt-1">
        <div class="col-12">
          <table class="table table-responsive">
            <thead>
              <tr>
                <td> <b>Item Name</b></td>
                <td><b>DOS</b></td>
                <td><b>Modifiers 1</b></td>
                <td><b>Modifiers 2</b></td>
                <td><b>Modifiers 3</b></td>
                <td><b>Modifiers 4</b></td>
                <td><b>Price Per Unit</b></td>
                <td><b>Units</b></td>
                <td><b>Total Price</b></td>
                <td><b>Option</b></td>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of tblPostReponse">
                <td>{{item?.item }}</td>
                <td> {{ (item?.dateFrom | date: 'MM/dd/yyyy':'en_US') }} {{(item?.dateTo!=='')? " - " + (item?.dateTo | date: 'MM/dd/yyyy':'en_US') : ""}}
                </td>
                <td> {{item?.m1}}</td>
                <td> {{item?.m2}}</td>
                <td> {{item?.m3}}</td>
                <td> {{item?.m4}}</td>
                <td> $ {{item?.fee}}</td>
                <td> {{item?.units}}</td>
                <td> $ {{item?.total}}</td>
                <td>
                  <a matTooltip="Click To View Provider">
                    <i class="fa fa-eye pointer editBtnColor pl-3" (click)="ViewPosting(item)"></i>
                  </a>
                </td>

              </tr>
              <tr>
                <td colspan="6"></td>
                <td colspan="1"><b>Total</b></td>
                <td colspan="1"><b>{{totalItemQty}}</b> </td>
                <td colspan="1"><b>$ {{totalItemPrice}}</b> </td>
                <td colspan="1"></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-sm-12 mt-2">
      <div class="text-lg-right">
        <button type="button" mat-button [disabled]="saveButtonHide||postingForm?.invalid " (click)="getClaimInfo()"
          class="buttonColor mb-2 mr-2">Proceed
          <mat-icon *ngIf='isShowSpinner'>
            <mat-spinner class="spinner-border spinner-border-sm" diameter="20"> </mat-spinner>
          </mat-icon>
        </button>
      </div>
    </div>
  </div>

</div>
