import { mapEnumToOptions } from '@abp/ng.core';

export enum ImageType {
  CPAPFaceToFace = 0,
  CPAPMedicareSleepTest = 1,
  CPAPDetailWrittenOrder = 2,
  CPAPPickupTicket = 3,
  REPLACEMENTMedicareSeepTest = 4,
  REPLACEMENTDetailWrittenOrder = 5,
  REPLACEMENTftfNote = 6,
  PAPreFaceToFace = 7,
  PAPreMedicareSleepTest = 8,
  PAPreDetailWrittenOrder = 9,
  PAPreReturnTicket = 10,
  COMPLIANCEftf = 11,
  COMPLIANCEMedicareSleepTest = 12,
  COMPLIANCEDetailWrittenOrder = 13,
  OfflineClinicalNote = 14,
  OXYGENofflineClinic = 15,
  OXYGENMedicareSleepTest = 16,
  OXYGENDetailWrittenOrder = 17,
  NONINVASIVEfaceToface = 18,
  NONINVASIVEMedicareSleepTest = 19,
  NONINVASIVEDetailWrittenOrder = 20,
}

export const imageTypeOptions = mapEnumToOptions(ImageType);
