<mat-dialog-content class="mat-typography">
    <div class="modal-header">
        <h1 class="modal-title"> Add HCPC Code</h1>
        <i aria-label="Close" class="fa fa-times pointer" (click)="closeHcpcMdl()"></i>
    </div>
    <div class="row">
        <div class="col-12">
            <div class="card">
                <div class="card-header p-2 text-center">
                    <form [formGroup]="hcpcForm">
                        <div class="row">
                            <div class="col-6">
                                <mat-form-field class="mr-3 ">
                                    <input matInput type="text" maxlength="15" placeholder="HCPC Code"
                                        formControlName="txtHCPCCode" autocomplete="off"/>
                                </mat-form-field>
                            </div>
                            <div class="col-6 text-right">
                                <button type="button" mat-raised-button (click)="addHCPC()" class="buttonColor dd-text p-2 mt-3">
                                    <i class="fa fa-plus iconClr mr-1" aria-hidden="true"></i>
                                    Add
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
                <div *ngIf="ltHCPC.length > 0" class="p-0 card-body">
                    <div class="table table-responsive mb-0 table-container">
                        <table class="table table-bordered mb-0 small-text">
                            <thead>
                                <tr>
                                    <th class="text-center pt-2 m-0 small-text custom-th">
                                        Codes
                                    </th>
                                    <th class="text-center pt-2 m-0 small-text custom-th">
                                        Action
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let data of ltHCPC" class="p-0 m-0 small-text">
                                    <td class="text-center p-0 pt-2 m-0 dd-text">
                                        {{ data.hcpcCode }}
                                    </td>
                                    <td class="text-center p-0 m-0 dd-text">
                                        <button type="button" mat-icon-button>
                                            <mat-icon (click)="removeHCPC(data.hcpcCode)" matTooltip="Click To Remove"
                                                [style.font-size]="'medium'">close</mat-icon>
                                        </button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</mat-dialog-content>