import { ListService, PagedAndSortedResultRequestDto } from '@abp/ng.core';
import { HttpErrorResponse } from '@angular/common/http';
import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostListener,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Title } from '@angular/platform-browser';
import { ToastrService } from 'ngx-toastr';
import { RegionDropdownService } from 'projects/patient/src/app/dropdown-proxy/dropdown-management/dropdowns/region-dropdown-service';
import {
  RegionCitiesDTO,
  RegionCountriesDTO,
  RegionStatesDTO,
} from 'projects/patient/src/app/dropdown-proxy/dropdown-management/dropdowns/dto';
import { defaultCountry } from 'projects/shared/src/app/enums/allenums.enum';
import { TableService } from 'projects/shared/src/app/table.service';
import { DateValidator } from 'projects/shared/src/app/validations/date-validator';
import { NumberValidators } from 'projects/shared/src/app/validations/number-validator';
import { Observable, Subscription } from 'rxjs';
import {
  debounceTime,
  filter,
  map,
  startWith,
  switchMap,
  tap,
} from 'rxjs/operators';
import Swal from 'sweetalert2';
import {
  Status,
  VendorDetailsService,
} from '../item-proxy/item-management/optimization';
import { CreateUpdateVendorDetailsDTO } from '../item-proxy/item-management/optimization/dto';
import { VendorSearchDTO } from '../item-proxy/item-management/items/dto';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { CommunicationService } from 'projects/shared/src/app/services/communication.service';
import { noWhitespaceValidator } from 'projects/patient/src/app/add-note-tab/add-note-tab.component';
import { DatePipe } from '@angular/common';
import { CommonService } from 'projects/shared/src/app/services/common.service';
import emailMask from 'text-mask-addons/dist/emailMask';

@Component({
  selector: 'app-product-vendor-tab',
  templateUrl: './product-vendor-tab.component.html',
  styleUrls: ['./product-vendor-tab.component.scss'],
  providers: [ListService],
})
export class ProductVendorTabComponent implements OnInit {
  productVendorForm: FormGroup;
  vendorId: string = '';
  step: boolean = false;
  isShowSpinner: boolean;

  isShown: boolean = false;
  saveButtonHide: boolean = false;
  Country: string = '';
  State: string = '';
  City: string = '';
  vendorOptions: any = {
    dom: 'Bfrtip',
    responsive: true,
    paging: false,
    scrollCollapse: true,
    scrollX: true,
    scrollY: '300px',
    buttons: [
      {
        extend: 'excel',
        text: 'Product Vendor', // Change the text to 'Export'
        exportOptions: {
          columns: [1, 2, 3, 4, 5, 6, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14], // Specify the columns you want to export (0-indexed)
        },
      },
    ],
  };
  dataSource: MatTableDataSource<any>;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @HostListener('input', ['$event']) onInput(event: Event) {
    const input = event.target as HTMLInputElement;

    // Check if the input field has the class 'zip-code-input'
    if (input.classList.contains('zip-code-input')) {
      let formattedValue = input.value.replace(/[^0-9]/g, '');

      if (formattedValue.length > 5) {
        formattedValue =
          formattedValue.slice(0, 5) + '-' + formattedValue.slice(5);
      }

      input.value = formattedValue;
    }
  }
  displayedColumns: string[] = [
    // 'sno',
    'options',
    'vendorCode',
    'vendorName',
    'vendorContactPersonName',
    'vendorEmail',
    'vendorPhoneNumber',
    'createdDate',
    'status',
  ];
  tableData: any[];
  drpCountry: RegionCountriesDTO[] = [];
  drpStates: RegionStatesDTO[] = [];
  drpCities: RegionCitiesDTO[] = [];
  public filteredCountries: Observable<RegionCountriesDTO[]>;
  public filteredStates: Observable<RegionStatesDTO[]>;
  public filteredCities: Observable<RegionCitiesDTO[]>;
  subscription$: Subscription[] = [];
  CountryName = '';
  stateName = '';
  phoneCode: string;
  vendorList: any;
  emailMask: any;
  isLoadData = true;
  constructor(
    private formBuilder: FormBuilder,
    public title: Title,
    private Table: TableService,
    public list: ListService,
    private dateValidator: DateValidator,
    private numberValidators: NumberValidators,
    private ref: ChangeDetectorRef,
    private region: RegionDropdownService,
    private toastr: ToastrService,
    private productVendorService: VendorDetailsService,
    private communicationService: CommunicationService,
    private datepipe: DatePipe,
    private commonService: CommonService,
  ) {
    const comService1 =
      this.communicationService.insuranceAllowableToggleMethodCall$.subscribe(
        () => {
          this.toggleV2();
        }
      );
    this.subscription$.push(comService1);
    const comService2 =
      this.communicationService.insuranceAllowableGetMethodCall$.subscribe(
        (vendorId) => {
          this.getVendorById(vendorId);
        }
      );

    this.subscription$.push(comService1);

    // const comService3 = this.communicationService.statusMethodCall$
    //   .pipe(map((data: any) => [data.event, data.productId]))
    //   .subscribe(([event, productId]) => {
    //     this.statusChange(event, productId);
    //     // Use statusId and param2 here
    //   });
    // this.subscription$.push(comService3);

    const comService3 =
      this.communicationService.functionReloadProductTable$.subscribe(() => {
        this.reloadTable();
      });
    this.subscription$.push(comService3);
  }

  ngOnInit(): void {
    this.title.setTitle('Qsecure | Manufacturer');
    this.initializeForms();
    this.getVendorListV2();
    this.countryDropdown();
    this.vendorOptions.buttons[0].filename =
    'Product Vendor' + '-' + this.datepipe.transform(new Date(), 'MM-dd-yyyy');
    this.emailMask = emailMask;
  }
  ngOnDestroy(): void {
    this.subscription$.forEach((sub) => {
      sub && sub?.unsubscribe();
    });
  }
  //Initialize Forms
  initializeForms() {
    this.productVendorForm = this.formBuilder.group({
      txtVendorName: new FormControl('', [
        Validators.required,
        noWhitespaceValidator,
      ]),
      txtVendorCode: new FormControl('', [
        Validators.required,
        noWhitespaceValidator,
      ]),
      txtVendorAddress: new FormControl(''),
      txtVendorPhoneNumber: new FormControl(''),
      txtVendorEmailId: new FormControl(''),
      txtPersonPhoneNumber: new FormControl(''),
      txtPersonName: new FormControl(''),
      //txtStatus: new FormControl(''),
      txtCountry: new FormControl(''),
      txtState: new FormControl(''),
      txtCity: new FormControl(''),
      txtCountryFilter: new FormControl(''),
      txtStateFilter: new FormControl(''),
      txtCityFilter: new FormControl(''),
      txtZip: new FormControl(''),
      txtFaxNo: new FormControl(''),
      txtWebSite: new FormControl('', Validators.pattern('(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?')),
      txtRemarks: new FormControl(''),
      txtOrderEmail: new FormControl(''),
      txtLotNumberLength: new FormControl(''),
      txtSerialNumberLength: new FormControl(''),
      txtAccountNumber: new FormControl(''),
    });
  }

  //Load Country Dropdown
  countryDropdown() {
    this.productVendorForm.patchValue({ txtCountry: defaultCountry.US });
    this.onChangeState(defaultCountry.US);
    this.Country = defaultCountry.US;
    this.drpCountry = [];
    const countryDropdown = this.region
      .getAllCountriesByInput(new PagedAndSortedResultRequestDto())
      .subscribe(
        (response) => {
          this.drpCountry = response.items;
          this.filteredCountries = this.productVendorForm
            ?.get('txtCountryFilter')
            .valueChanges.pipe(
              startWith(''),
              map((value) =>
                this.drpCountry.filter((option) =>
                  option?.countryName
                    ?.toLowerCase()
                    ?.includes(value?.toLowerCase() ?? '')
                )
              )
            );
        },
        (err) => {
          const data: HttpErrorResponse = err;
          Swal.fire({
            icon: 'info',
            text: data?.error?.error?.message,
          });
        }
      );
    this.subscription$.push(countryDropdown);
  }

  toggle() {
    this.step = true;
  }
  toggleV2() {
    this.step = false;
  }

  vendorPhoneNo() {
    // this.productVendorForm.controls['txtVendorPhoneNumber'].setValidators(
    //   Validators.required
    // );
  }
  //Load State Dropdown
  onChangeState(value: string | null | undefined): boolean {
    this.productVendorForm.patchValue({
      txtState: null,
      txtCity: null,
    });
    this.drpStates = [];
    this.drpCities = [];
    if (value !== null && value !== undefined) {
      const getStates = this.region
        .getAllStatesByCountryNameByCountryShortName(value)
        .subscribe(
          (response) => {
            this.drpStates = response.items;
            this.phoneCode = response?.items[0]?.countryPhoneCode;
            // this.ref.detach();
            // if (!response.items) {
            //   this.productVendorForm.controls['txtPersonPhoneNumber'].disable();
            //   this.productVendorForm.controls['txtVendorPhoneNumber'].disable();

            //   this.phoneCode = '';
            // } else {
            //   this.productVendorForm.controls['txtPersonPhoneNumber'].enable();
            //   this.productVendorForm.controls['txtVendorPhoneNumber'].enable();
            //   this.phoneCode = response?.items[0]?.countryPhoneCode;
            // }
            // this.ref.reattach();
            this.filteredStates = this.productVendorForm
              ?.get('txtStateFilter')
              .valueChanges.pipe(
                startWith(''),
                map((value) =>
                  this.drpStates.filter((option) =>
                    option?.stateName
                      ?.toLowerCase()
                      ?.includes(value?.toLowerCase() ?? '')
                  )
                )
              );
          },
          (err) => {
            const data: HttpErrorResponse = err;
            Swal.fire({
              icon: 'info',
              text: data?.error?.error?.message,
            });
          }
        );
      this.subscription$.push(getStates);
    }

    return this.drpStates && this.drpStates.length !== 0;
  }

  //Load City Dropdown
  onChangeCity(state: string | null | undefined, country: string): boolean {
    this.productVendorForm.patchValue({
      txtBillingCity: null,
    });
    country =
      country == ''
        ? this.productVendorForm.value.txtCountry == (null || undefined)
          ? ''
          : this.productVendorForm.value.txtCountry
        : country;
    this.drpCities = [];
    if (state !== null && state !== undefined) {
      const getAllCities = this.region
        .getAllCitiesByCountryAndStateNameByCountryShortNameAndStateShortName(
          country,
          state
        )
        .subscribe(
          (response) => {
            this.drpCities = response.items;
            this.filteredCities = this.productVendorForm
              ?.get('txtCityFilter')
              .valueChanges.pipe(
                startWith(''),
                map((value) =>
                  this.drpCities.filter((option) =>
                    option?.cityName
                      ?.toLowerCase()
                      ?.includes(value?.toLowerCase() ?? '')
                  )
                )
              );
          },
          (err) => {
            const data: HttpErrorResponse = err;
            Swal.fire({
              icon: 'info',
              text: data?.error?.error?.message,
            });
          }
        );
      this.subscription$.push(getAllCities);
    }

    return this.drpCities && this.drpCities.length !== 0;
  }

  getVendorListV2() {
    this.isLoadData = false;
    this.productVendorService.getListV2().subscribe(
      (response) => {
        this.isLoadData = true;
        this.vendorList = response;
        this.vendorList = this.vendorList.map((product) => {
          return {
            ...product,
            creationTime: this.commonService.getFormattedDateTimeZone(
              product.creationTime
            ),
            lastModificationTime: this.commonService.getFormattedDateTimeZone(
              product.lastModificationTime
            ),
          };
        });
      },
      (err) => {
        this.isLoadData = true;
      }
    );
  }
  //To Save the Vendor Details
  saveVendorDetails() {
    this.vendorPhoneNo();
    this.saveButtonHide = true;
    let input: CreateUpdateVendorDetailsDTO = {
      //Create Update DTO
      contactPersonName: this.productVendorForm?.value?.txtPersonName ?? '',
      contactNo: this.productVendorForm?.value?.txtPersonPhoneNumber ?? '',
      country: this.productVendorForm.value?.txtCountry ?? '',
      state: this.productVendorForm.value?.txtState ?? '',
      city: this.productVendorForm.value?.txtCity ?? '',
      vendorName: this.productVendorForm?.value?.txtVendorName ?? '',
      vendorCode: this.productVendorForm?.value?.txtVendorCode ?? '',
      addressLine1: this.productVendorForm?.value?.txtVendorAddress ?? '',
      primaryPhoneNumber:
        this.productVendorForm?.value?.txtVendorPhoneNumber ?? '',
      emailId: this.productVendorForm?.value?.txtVendorEmailId.toLowerCase() || '',
      fax: this.productVendorForm?.value?.txtFaxNo ?? '',
      productQuantity: 0,
      webSite: this.productVendorForm?.value?.txtWebSite ?? '',
      status: Status.Active,
      accountNumber: this.productVendorForm?.value?.txtAccountNumber ?? '',
      remarks: this.productVendorForm?.value?.txtRemarks ?? '',
      zip: this.productVendorForm?.value?.txtZip ?? '',
      orderEmail: this.productVendorForm?.value?.txtOrderEmail.toLowerCase() || '',
      lotNumberLength: this.productVendorForm?.value?.txtLotNumberLength
        ? this.productVendorForm?.value?.txtLotNumberLength
        : 0,
      serialNumberLength:
        this.productVendorForm?.value?.txtSerialNumberLength ?? '',
    };
    this.vendorId = this.vendorId ?? '';
    if (this.vendorId === '') {
      this.isLoadData = false;
      this.productVendorService.create(input).subscribe(
        (response) => {
          this.vendorId = '';
          this.toastr.success('Saved Successfully', 'Success');
          this.saveButtonHide = false;
          const userTimezoneCreationTime = this.commonService.getFormattedDateTimeZone(response.creationTime);
          const userTimezonelastModificationTime = this.commonService.getFormattedDateTimeZone(response.lastModificationTime);
          // Update the response with the local time zone creation time
          response.creationTime = userTimezoneCreationTime;
          response.lastModificationTime = userTimezonelastModificationTime;
          this.vendorList.unshift(response);
          this.isLoadData = true;
          this.resetForm();
        },
        (err) => {
          this.saveButtonHide = false;
          this.isLoadData = true;
          const data: HttpErrorResponse = err;

          // this.toastr.success(data?.error?.error?.message,'Error');
          Swal.fire({
            icon: 'info',
            text: data?.error?.error?.message,
          });
        }
      );
    } else {
      this.isLoadData = false;
      this.productVendorService.update(this.vendorId, input).subscribe(
        (response) => {
          this.vendorId = '';
          this.toastr.success('Updated Successfully', 'Success');
          // this.productVendorForm.controls['txtVendorPhoneNumber'].clearValidators()
          this.resetForm();
          const index = this.vendorList.findIndex(
            (obj) => obj.id === response?.id
          );
          if (index !== -1) {
             const userTimezoneCreationTime = this.commonService.getFormattedDateTimeZone(response.creationTime);
            const userTimezonelastModificationTime = this.commonService.getFormattedDateTimeZone(response.lastModificationTime);
            // Update the response with the local time zone creation time
            response.creationTime = userTimezoneCreationTime;
            response.lastModificationTime = userTimezonelastModificationTime;
            this.vendorList[index] = response; //Replace the Object
            const objectToMove = this.vendorList.splice(index, 1)[0]; // Remove and get the object
            this.vendorList.unshift(objectToMove);
          }
          this.isLoadData = true;
          this.saveButtonHide = false;
        },
        (err) => {
          this.saveButtonHide = false;
          this.isLoadData = true;
          const data: HttpErrorResponse = err;
          Swal.fire({
            icon: 'info',
            text: data?.error?.error?.message,
          });

          // this.toastr.success(data?.error?.error?.message,'Error');
        }
      );
    }
  }
  // //Get Vendor Details By ID
  getVendorById(vendorId: string) {
    if (vendorId) {
      const vendorGet = this.productVendorService
        .get(vendorId)
        .subscribe((response) => {
          this.stateName = response?.state;
          this.CountryName = response?.country;
          this.CountryName != null &&
            this.CountryName !== '' &&
            this.CountryName != undefined &&
            this.onChangeState(this.CountryName) &&
            this.productVendorForm.patchValue({
              drpCountry: this.CountryName,
            });
          this.stateName != null &&
            this.stateName !== '' &&
            this.stateName != undefined &&
            this.onChangeCity(this.stateName, this.CountryName) &&
            this.productVendorForm.patchValue({
              drpState: this.stateName,
              drpCity: this.City,
            });

          this.productVendorForm.patchValue({
            txtVendorName: response?.vendorName ?? '',
            txtVendorCode: response?.vendorCode ?? '',
            txtVendorAddress: response?.addressLine1 ?? '',
            txtVendorPhoneNumber: response?.primaryPhoneNumber ?? '',
            txtVendorEmailId: response?.emailId || '',
            txtPersonName: response?.contactPersonName ?? '',
            txtPersonPhoneNumber: response?.contactNo ?? '',
            txtState: response?.state ?? '',
            txtCity: response?.city ?? '',
            txtCountry: response?.country ?? '',
            txtZip: response?.zip ?? '',
            txtFaxNo: response?.fax ?? '',
            txtWebSite: response?.webSite ?? '',
            txtRemarks: response?.remarks ?? '',
            txtOrderEmail: response?.orderEmail || '',
            txtLotNumberLength: response?.lotNumberLength ?? 0,
            txtSerialNumberLength: response?.serialNumberLength ?? '',
            txtAccountNumber: response?.accountNumber ?? '',
          });
          this.vendorId = response?.id ?? '';
          this.step = true;
        });
      this.subscription$.push(vendorGet);
    }
  }

  onPaste(event: ClipboardEvent) {
    const clipboardData = event.clipboardData;
    const pastedData = clipboardData.getData('text');
    const isNumber = /^\d+$/.test(pastedData); //Should contain only whole numbers

    if (!isNumber) {
      event.preventDefault(); // Prevent the input if the key is not a number
    }
  }
  //Delete Vendor By ID
  deleteVendor(value: string) {
    Swal.fire({
      title: 'Are you sure you want to Delete?',
      text: "You won't be able to retrieve this data!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#34c38f',
      cancelButtonColor: '#f46a6a',
      confirmButtonText: 'Yes, delete it!',
    }).then((result) => {
      if (result.value) {
        if (value) {
          const vendorDelete = this.productVendorService
            .delete(value)
            .subscribe(
              (response) => {
                this.resetForm();
                Swal.fire({
                  icon: 'success',
                  text: 'Deleted Successfully!',
                });
                // this.getVendorList();
                this.getVendorListV2();
              },
              (err) => {
                const data: HttpErrorResponse = err;
                Swal.fire({
                  icon: 'info',
                  text: data?.error?.error?.message,
                });
              }
            );
          this.subscription$.push(vendorDelete);
        }
      }
    });
  }
  //Clear Country
  clearCountry() {
    this.Country = 'US';
    this.phoneCode = '1';

    this.productVendorForm.patchValue({ txtCountry: 'US' });
    // this.productVendorForm.controls['txtPersonPhoneNumber'].disable();
  }
  //Clear State
  clearState() {
    this.State = '';
    this.productVendorForm.patchValue({ txtState: '' });
  }
  //Clear City
  clearCity() {
    this.City = '';
    this.productVendorForm.patchValue({ txtCity: '' });
  }
  //Reset Form
  resetForm() {
    // this.productVendorForm?.reset();
    this.vendorId = '';
    const restFields = {
      txtOrderEmail : '',
      txtVendorEmailId : '',
    };
    this.productVendorForm.reset(restFields);
    this.clearCountry();
    this.clearState();
    this.clearCity();
    this.clearValidationErrors();
  }
  // Clear validation errors without triggering validation.
  clearValidationErrors() {
    Object.keys(this.productVendorForm.controls).forEach((key) => {
      this.productVendorForm.get(key).setErrors(null);
    });
  }
  onKeyPress(event: KeyboardEvent) {
    const isNumber = /[0-9]/.test(event.key);
    if (!isNumber) {
      event.preventDefault(); // Prevent the input if the key is not a number
    }
  }
  reloadTable() {
    this.getVendorListV2();
    // this.isLoadData = false;
    // setTimeout(() => {
    //   this.isLoadData = true;
    // }, 100);
  }
}
