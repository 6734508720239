<mat-dialog-content class="mat-typography">
  <div class="container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="d-flex align-items-center justify-content-between">
          <h3 class="font-weight-bolder mb-0 small-text">
            <b>Inventory/Requisition</b>
          </h3>

          <div class="col-lg-3" *ngIf="reqId" style="text-align-last: right">
            <mat-icon mat-dialog-close>close</mat-icon>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <div class="card mb-3">
          <div class="card-header p-2 d-flex justify-content-between">
            <b class="header-text">Requistion Form</b>

            <div>
              <!-- <button mat-raised-button (click)="test()">test</button> -->
              <button
                (click)="submitRequisition()"
                [disabled]="!isSubmitButtonEnabled || isSaveBtnDisabled"
                mat-raised-button
                class="buttonColor mr-3"
              >
                Save Requisition
              </button>
              <button mat-raised-button (click)="resetForm()" class="resetclr">
                Reset
              </button>
            </div>
          </div>
          <div class="card-body p-0">
            <!-- <button mat-raised-button (click)="add()">test</button> -->
            <!-- Requisition Form -->
            <form class="mt-2" [formGroup]="requisitionForm">
              <!-- Id -->
              <!-- <mat-form-field class="col-1">
                <mat-label>Id </mat-label>
                <input
                  matInput
                  readonly
                  formControlName="txtId"
                  placeholder="Enter ID"
                  type="text"
                />
              </mat-form-field> -->

              <!-- Requisition Date -->
              <!-- <mat-form-field class="col-2">
                <mat-label>Requisition Date </mat-label>
                <input
                  autocomplete="new-txtRequisitionDate"
                  matInput
                  readonly
                  [(value)]="requisitionDate"
                  formControlName="txtRequisitionDate"
                  placeholder="Enter Requisition Date"
                  type="text"
                />
              </mat-form-field> -->

              <!-- Requested By -->
              <!-- <mat-form-field class="col-2">
                <mat-label>Requested By</mat-label>
                <input
                  readonly
                  autocomplete="new-txtRequestBy"
                  matInput
                  formControlName="txtRequestBy"
                  placeholder="Requested By"
                  type="text"
                />
              </mat-form-field> -->

              <!--  Shipping Method -->
              <mat-form-field class="col-4 dd-text">
                <mat-label
                  >Shipping Method<span class="asterisk">*</span></mat-label
                >
                <mat-select formControlName="drpShippingMethod" #singleSelect>
                  <mat-option>
                    <ngx-mat-select-search
                      placeholderLabel="Search"
                      noEntriesFoundLabel="No Matches found"
                      formControlName="txtShippingMethodFilter"
                    ></ngx-mat-select-search
                  ></mat-option>
                  <mat-option
                    [value]="option.id"
                    *ngFor="let option of filteredShippingMethodDetails | async"
                  >
                    {{ option.description }}
                  </mat-option>
                </mat-select>

                <mat-error
                  class="spanAlignment"
                  *ngIf="
                    requisitionForm?.get('drpShippingMethod')?.touched &&
                    requisitionForm?.get('drpShippingMethod')?.errors?.required
                  "
                >
                  Shipping Method field is required!
                </mat-error>
              </mat-form-field>

              <!-- Location -->
              <mat-form-field class="col-4 dd-text">
                <mat-label>Location<span class="asterisk">*</span></mat-label>
                <mat-select
                  formControlName="drpLocation"
                  (selectionChange)="onselectionChange($event)"
                  #singleSelect
                >
                  <mat-option>
                    <ngx-mat-select-search
                      placeholderLabel="Search"
                      noEntriesFoundLabel="No Matches found"
                      formControlName="txtLocationFilter"
                    ></ngx-mat-select-search
                  ></mat-option>
                  <mat-option
                    [value]="data.mmRefId"
                    *ngFor="let data of filteredLocationDetails | async"
                  >
                    {{ data.organizationUnitName }}
                  </mat-option>
                </mat-select>
                <mat-error
                  class="spanAlignment"
                  *ngIf="
                    requisitionForm?.get('drpLocation')?.touched &&
                    requisitionForm?.get('drpLocation')?.errors?.required
                  "
                >
                  Location field is required!
                </mat-error>
              </mat-form-field>
              <!-- Inventory Limit -->
              <mat-form-field class="col-4 dd-text">
                <mat-label>Inventory Limit</mat-label>
                <input
                  readonly
                  autocomplete="new-txtInventoryLimit"
                  matInput
                  formControlName="txtInventoryLimit"
                  placeholder="Enter txtInventoryLimit"
                  type="text"
                />
              </mat-form-field>
              <!-- On Hand Total -->
              <mat-form-field class="col-4 dd-text">
                <mat-label>On Hand Total </mat-label>
                <input
                  autocomplete="new-txtOnHandTotal"
                  matInput
                  readonly
                  formControlName="txtOnHandTotal"
                  placeholder="Enter On Hand Total"
                  type="text"
                />
              </mat-form-field>
              <!-- On Order -->
              <mat-form-field class="col-4 dd-text">
                <mat-label>On Order</mat-label>
                <input
                  autocomplete="new-txtOnOrder"
                  matInput
                  readonly
                  formControlName="txtOnOrder"
                  placeholder="Enter On Order"
                  type="text"
                />
              </mat-form-field>
              <!-- Requisition Total -->
              <mat-form-field class="col-4 dd-text">
                <mat-label>Current Requisition($)</mat-label>
                <input
                  readonly
                  autocomplete="new-txtRequisitionTotal"
                  matInput
                  formControlName="txtRequisitionTotal"
                  placeholder="Enter Requisition Total"
                  type="text"
                />
              </mat-form-field>
              <!-- Special Instructions-->
              <mat-form-field class="col-8 dd-text">
                <mat-label>Special Instructions</mat-label>
                <textarea
                  formControlName="txtSpecialInstructions"
                  rows="4"
                  maxlength="5000"
                  [(ngModel)]="notesText"
                  (input)="updateCharacterCount()"
                  matInput
                ></textarea>
                <div class="char-count">{{ notesText?.length }} / 5000</div>
                <mat-error></mat-error>
              </mat-form-field>
              <!-- Manufacturer -->
              <!-- <mat-form-field matTooltip="Field Disabled" class="col-2 mr-3">
                <mat-label>Manufacturer</mat-label>
                <mat-select
                  [disabled]="true"
                  formControlName="txtManufacturer"
                  #singleSelect
                >
                  <mat-option>
                    <ngx-mat-select-search
                      placeholderLabel="Search"
                      noEntriesFoundLabel="No Matches found"
                      formControlName="txtManufacturerFilter"
                    ></ngx-mat-select-search>
                  </mat-option>

                  <mat-option
                    *ngFor="let vendors of filteredVendorDetails | async"
                    [value]="vendors.id"
                  >
                    {{ vendors.vendorName }}
                  </mat-option>
                </mat-select>
                <mat-error></mat-error>
              </mat-form-field> -->
              <!-- Product List Button -->
              <!-- <button
                matTooltip="Field Disabled"
                type="button"
                [disabled]="true"
                class="btn button-hover font-weight-bolder btn-sm mr-1 buttonColor"
              >
                Product List
              </button> -->
              <!-- No of Days -->
              <!-- <mat-form-field matTooltip="Field Disabled" class="col-2 mr-1">
                <mat-label>No.of Days</mat-label>
                <input
                  readonly
                  matInput
                  formControlName="txtNoofDays"
                  placeholder="Days"
                  type="text"
                />
              </mat-form-field> -->

              <!-- Smart Requisition List Button -->
              <!-- <button
                matTooltip="Field Disabled"
                type="button"
                [disabled]="true"
                class="btn button-hover btn-sm mr-3 font-weight-bolder buttonColor"
              >
                Smart Requisition
              </button><br> -->
              <!-- Urgent Items Button -->
              <!-- <button
                matTooltip="Field Disabled"
                [disabled]="true"
                type="button"
                class="btn button-hover ml-3 font-weight-bolder btn-sm buttonColor"
              >
                Urgent Items
              </button> -->
            </form>
            <mat-divider class="my-2"></mat-divider>
            <!-- Requisition Table List -->
            <div class="p-3">
              <div class="table-responsive">
                <table
                  datatable
                  [dtOptions]="orderTableOptions"
                  [dtTrigger]="dtTrigger"
                  class="table row-border hover w-100"
                >
                  <thead>
                    <tr>
                      <th>sortnumber</th>
                      <th [matTooltip]="'Options'">Options</th>
                      <th [matTooltip]="'Product Code'">Product Code</th>
                      <th [matTooltip]="'Description'">Description</th>
                      <th [matTooltip]="'Manufacturer'">Manufacturer</th>
                      <th [matTooltip]="'Category'">Category</th>
                      <th [matTooltip]="'This Order'" class="text-right">
                        This Order
                      </th>
                      <th [matTooltip]="'RecOrderQty'" class="text-right">
                        RecOrderQty
                      </th>
                      <th [matTooltip]="'Status'">Status</th>
                      <th
                        [matTooltip]="'Daily Average Dispense'"
                        class="text-right"
                      >
                        Daily Average Dispense
                      </th>
                      <th [matTooltip]="'Qty On Hand'" class="text-right">
                        Qty On Hand
                      </th>
                      <th [matTooltip]="'Await Delivery'" class="text-right">
                        Await Delivery
                      </th>
                      <th
                        [matTooltip]="'Pending Patient Orde'"
                        class="text-right"
                      >
                        Pending Patient Order
                      </th>
                      <th [matTooltip]="'Unit Cost($)'" class="text-right">
                        Unit Cost($)
                      </th>
                      <th [matTooltip]="'Total Cost($)'" class="text-right">
                        Total Cost($)
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let data of requisitionItems">
                      <td>{{ data.sortNumber }}</td>
                      <td [matTooltip]="'Click to Remove'">
                        <mat-icon
                          (click)="removeProduct(data.productId)"
                          class="text-danger cursor"
                          >close</mat-icon
                        >
                      </td>
                      <td>
                        <mat-select
                          (selectionChange)="
                            loadProductDetails(
                              data,
                              myDDRef.value,
                              myInputRef.value
                            )
                          "
                          class="dd-text"
                          #myDDRef
                          [(value)]="data.productId"
                          placeholder="Product Code"
                        >
                          <!-- <mat-option>
                            <ngx-mat-select-search
                              placeholderLabel="Search"
                              noEntriesFoundLabel="No Matches found"
                              formControlName="txtProductSearch"
                            ></ngx-mat-select-search>
                          </mat-option> -->
                          <mat-option>
                            <ngx-mat-select-search
                              [formControl]="searchControl"
                              placeholderLabel="Search"
                              noEntriesFoundLabel="No Matches found"
                              (keyup)="filterProducts()"
                            ></ngx-mat-select-search
                          ></mat-option>

                          <mat-option
                            *ngFor="let products of filteredProductDetails"
                            [value]="products.productId"
                          >
                            {{ products.productName }}
                          </mat-option>
                        </mat-select>
                      </td>
                      <td [matTooltip]="data?.description || '-'">
                        {{ data.description }}
                      </td>
                      <td [matTooltip]="data?.manufacturer || '-'">
                        {{ data.manufacturer }}
                      </td>
                      <td [matTooltip]="data?.category || '-'">
                        {{ data.category }}
                      </td>
                      <td
                        [matTooltip]="data?.thisOrder || '1'"
                        class="text-right"
                      >
                        <input
                          [disabled]="data.productId == ''"
                          maxlength="8"
                          (focusout)="updateQuantity(data, myInputRef.value)"
                          class="textbox-border-class dd-text text-right"
                          [(ngModel)]="data.thisOrder"
                          #myInputRef="ngModel"
                          required
                          (keypress)="onKeyPress($event)"
                          matInput
                          type="text"
                        />
                        <div
                          class="text-danger dd-text text-center"
                          *ngIf="!myInputRef.valid && myInputRef.touched"
                        >
                          Required!
                        </div>
                        <div
                          class="text-danger dd-text text-center"
                          *ngIf="
                            myInputRef.value === '0' ||
                            myInputRef.value === null ||
                            myInputRef.value === ''
                          "
                        >
                          Enter Minimum Qty!
                        </div>
                      </td>
                      <td
                        [matTooltip]="data?.recOrderQty || '0'"
                        class="text-right"
                      >
                        {{ data.recOrderQty }}
                      </td>
                      <td [matTooltip]="data?.status || ''">
                        {{ data.status }}
                      </td>
                      <td
                        [matTooltip]="data?.dailyAvgDesp || '0'"
                        class="text-right"
                      >
                        {{ data.dailyAvgDesp }}
                      </td>
                      <td
                        [matTooltip]="data?.qtyOnHand || '0'"
                        class="text-right"
                      >
                        {{ data.qtyOnHand }}
                      </td>
                      <td
                        [matTooltip]="data?.availQty || '0'"
                        class="text-right"
                      >
                        {{ data.availQty }}
                      </td>
                      <td
                        [matTooltip]="data?.pendPatOrder || '0'"
                        class="text-right"
                      >
                        {{ data.pendPatOrder }}
                      </td>
                      <td
                        [matTooltip]="data?.unitCost || '0'"
                        class="text-right"
                      >
                        {{ data.unitCost }}
                      </td>
                      <td
                        [matTooltip]="data?.totalCost || '0'"
                        class="text-right"
                      >
                        {{ data.totalCost }}
                      </td>
                      <!-- <td >{{data.sortNumber}}</td> -->
                    </tr>
                  </tbody>

                  <tfoot>
                    <tr>
                      <td [matTooltip]="'Total Amount'" colspan="8" style="text-align: right">
                        <b class="dd-text"> Total Amount:</b>
                      </td>
                      <td [matTooltip]="requisitionTotal || '-'">
                        <b class="dd-text">{{ requisitionTotal }}</b>
                      </td>
                    </tr>
                  </tfoot>


                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</mat-dialog-content>
<app-loader-component *ngIf="isRequisitionLoading"></app-loader-component>
