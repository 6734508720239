<div class="row">
  <div class="col-12">
    <div class="card card-body p-1">
      <form [formGroup]="depositsSearchForm" class="d-flex justify-content-between">
        <div>
          <!-- <button mat-icon-button>
            <mat-icon class="btn-filter-color">filter_list</mat-icon>
          </button>
          <button mat-icon-button>
            <mat-icon>refresh</mat-icon>
          </button> -->
          <mat-form-field class="col-2">
            <mat-label>Status</mat-label>
            <mat-select formControlName="drpStatus">
              <mat-option>
                <ngx-mat-select-search placeholderLabel="Search" noEntriesFoundLabel="No Matches found"
                  hideClearSearchButton="true" formControlName="txtStatusFilter"></ngx-mat-select-search>
              </mat-option>
              <mat-option value="New">New</mat-option>
              <mat-option value="Confirmed">Confirmed</mat-option>
            </mat-select>
            <button
              mat-button
              *ngIf="
                depositsSearchForm.get('drpStatus').value != '' &&
                depositsSearchForm.get('drpStatus').value != null &&
                depositsSearchForm.get('drpStatus').value != undefined
              "
              (click)="clearStatus(); $event.stopPropagation()"
              matSuffix
              mat-icon-button
            >
              <mat-icon>close</mat-icon>
            </button>
          </mat-form-field>
          <mat-form-field class="col-2">
            <input matInput formControlName="txtReferenceNo" placeholder="Reference #"  autocomplete="off" />
          </mat-form-field>
          <mat-form-field class="col-3">
            <mat-label>Date</mat-label>
            <mat-date-range-input [rangePicker]="pickerRemittance">
              <input matStartDate matInput placeholder="Start date" formControlName="txtRemittanceStartDate" />

              <input matEndDate matInput placeholder="End date" formControlName="txtRemittanceEndDate" />
            </mat-date-range-input>
            <mat-datepicker-toggle matSuffix [for]="pickerRemittance"></mat-datepicker-toggle>
            <mat-date-range-picker #pickerRemittance></mat-date-range-picker>
            <button
              mat-button
              *ngIf="
                (depositsSearchForm.get('txtRemittanceStartDate').value != '' &&
                depositsSearchForm.get('txtRemittanceStartDate').value != null &&
                depositsSearchForm.get('txtRemittanceStartDate').value != undefined) ||
                (depositsSearchForm.get('txtRemittanceEndDate').value != '' &&
                depositsSearchForm.get('txtRemittanceEndDate').value != null &&
                depositsSearchForm.get('txtRemittanceEndDate').value != undefined)
              "
              (click)="clearRemittanceDate(); $event.stopPropagation()"
              matSuffix
              mat-icon-button
            >
              <mat-icon>close</mat-icon>
            </button>
          </mat-form-field>
          <mat-form-field class="col-3">
            <mat-label>Created Date</mat-label>
            <mat-date-range-input [rangePicker]="picker">
              <input matStartDate matInput placeholder="Start date" formControlName="txtCreatedStartDate" />

              <input matEndDate matInput placeholder="End date" formControlName="txtCreatedEndDate" />
            </mat-date-range-input>
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-date-range-picker #picker></mat-date-range-picker>
            <button
              mat-button
              *ngIf="
                (depositsSearchForm.get('txtCreatedStartDate').value != '' &&
                depositsSearchForm.get('txtCreatedStartDate').value != null &&
                depositsSearchForm.get('txtCreatedStartDate').value != undefined) ||
                (depositsSearchForm.get('txtCreatedEndDate').value != '' &&
                depositsSearchForm.get('txtCreatedEndDate').value != null &&
                depositsSearchForm.get('txtCreatedEndDate').value != undefined)
              "
              (click)="clearCreatedDate(); $event.stopPropagation()"
              matSuffix
              mat-icon-button
            >
              <mat-icon>close</mat-icon>
            </button>
          </mat-form-field>
          <button
            type="button"
            matTooltip="Reset"
            mat-raised-button
            class="reset-icon-button mr-2 px-2 mat-raised-small-button"
          >
            <mat-icon class="fg-white">refresh</mat-icon>
          </button>
        </div>
      </form>
    </div>
  </div>
</div>

<div class="row mt-2" *ngIf="!isLoading">
  <div class="col-12">
    <div class="card card-body p-1">
      <div class="table table-responsive">
        <table datatable [dtOptions]="billingDepositsTable" class="row-border hover w-100 display">
          <thead>
            <tr>
              <th><mat-checkbox></mat-checkbox></th>
              <th>Deposit Id</th>
              <th>Reference #</th>
              <th>Date</th>
              <th>Note</th>
              <th>Created By</th>
              <th>Created Date</th>
              <th>Modified</th>
              <th class="text-right">Total ($)</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let invoice of DataList">
              <td><mat-checkbox></mat-checkbox></td>
              <td>
                  {{ invoice.depositId || '-'}}
              </td>
              <td>
                {{ invoice.reference || '-'}}
              </td>
              <td>
                {{ invoice.date || "-" }}
              </td>
              <td>
                <span *ngIf="!isEmpty(invoice.note)">
                  <mat-icon mat-icon-button class="pl-2 ico-small" [matTooltip]="invoice.note">notes</mat-icon>
                </span>
                <span *ngIf="isEmpty(invoice.note)">-</span>
              </td>
              <td>{{ invoice.createdBy }}</td>
              <td>{{ invoice.createdDate }}</td>
              <td>{{ invoice.modified }}</td>
              <td class="text-right">
                {{ invoice.total || "-" }}
              </td>
              <td class="text-center">
                <div class="d-flex align-items-center">
                  <span [matTooltip]="invoice?.status || '-'" [ngStyle]="{
                      'background-color': invoice.statusBgColor,
                      color: invoice.statusFgColor
                    }" class="rounded-border ml-1 w-75 p-1 claim-status-label">
                    {{ invoice?.status || "-" }}
                  </span>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>
<div class="row" *ngIf="isLoading">
  <div class="col-12">
    <div class="d-flex justify-content-center">
      <img class="img-responsive pb-2 mr-1" src="assets/gifs/Infinity-loader.gif" />
    </div>
  </div>
</div>
