import type { CreateUpdateAllVerificationDTO, CreateUpdatePatientVerificationDTO, CreateUpdatePolicyPverifyDTO, InboundListDTO, PatientVerificationDTO, VerificationResult, eligibilityview } from './dto/models';
import { RestService } from '@abp/ng.core';
import type { PagedAndSortedResultRequestDto, PagedResultDto } from '@abp/ng.core';
import { Injectable } from '@angular/core';
import type { PverifyDTO } from '../patient/dto/models';
import { InsuranceType } from '../patient/enumeration-data/insurance-type.enum';
import { VerificationStatusV1 } from '../patient/enumeration-data/verification-status.enum';


@Injectable({
  providedIn: 'root',
})
export class PatientVerificationService {
  apiName = 'Default';

  create = (input: CreateUpdatePatientVerificationDTO) =>
    this.restService.request<any, PatientVerificationDTO>({
      method: 'POST',
      url: '/api/app/patient-verification',
      body: input,
    },
    { apiName: this.apiName });

  delete = (id: string) =>
    this.restService.request<any, void>({
      method: 'DELETE',
      url: `/api/app/patient-verification/${id}`,
    },
    { apiName: this.apiName });

  get = (id: string) =>
    this.restService.request<any, PatientVerificationDTO>({
      method: 'GET',
      url: `/api/app/patient-verification/${id}`,
    },
    { apiName: this.apiName });

  getList = (input: PagedAndSortedResultRequestDto) =>
    this.restService.request<any, PagedResultDto<PatientVerificationDTO>>({
      method: 'GET',
      url: '/api/app/patient-verification',
      params: { skipCount: input.skipCount, maxResultCount: input.maxResultCount, sorting: input.sorting },
    },
    { apiName: this.apiName });

  update = (id: string, input: PatientVerificationDTO) =>
    this.restService.request<any, PatientVerificationDTO>({
      method: 'PUT',
      url: `/api/app/patient-verification/${id}`,
      body: input,
    },
    { apiName: this.apiName });

  getPverifyDetailsByInput = (input: CreateUpdatePolicyPverifyDTO) =>
    this.restService.request<any, PverifyDTO>({
      method: 'POST',
      url: '/api/app/patient-verification/get-pverify-details',
      body: input,
    },
    { apiName: this.apiName });

    commonelgibilityCheckByInput = (input: CreateUpdateAllVerificationDTO) =>
    this.restService.request<any, PatientVerificationDTO>({
      method: 'POST',
      url: '/api/app/patient-verification/commonelgibility-check',
      body: input,
    },
    { apiName: this.apiName });

  getVerificationDetailsByPatientIdByPatientId = (patientId: string) =>
    this.restService.request<any, PatientVerificationDTO[]>({
      method: 'GET',
      url: `/api/app/patient-verification/get-verification-details-by-patient-id/${patientId}`,
    },
    { apiName: this.apiName });

    getVerificationByPatientIdpolicyIdByPatientIdAndPolicyId = (patientId: string, policyId: string) =>
    this.restService.request<any, PatientVerificationDTO[]>({
      method: 'GET',
      url: '/api/app/patient-verification/verification-by-patient-id-policy-id',
      params: { patientId, policyId },
    },
    { apiName: this.apiName });

    getLastVerificationDataByPatientIdByPatientIDAndType = (PatientID: string, type: InsuranceType) =>
    this.restService.request<any, PatientVerificationDTO[]>({
      method: 'GET',
      url: '/api/app/patient-verification/get-last-verification-data-by-patient-id',
      params: { patientID: PatientID, type },
    },
    { apiName: this.apiName });

    getVerificationStatusByStatusID = (statusID: string) =>
    this.restService.request<any, VerificationResult>({
      method: 'GET',
      url: '/api/app/patient-verification/verification-status',
      params: { statusID },
    },
    { apiName: this.apiName });

    // getMYVerificationStatusByStatusID = (statusID: string) =>
    // this.restService.request<any, InboundListDTO[]>({
    //   method: 'GET',
    //   url: '/api/app/patient-verification/m-yVerification-status',
    //   params: { statusID },
    // },
    // { apiName: this.apiName });

    getMYVerificationStatusByStatusIDAndLtBranch = (ltBranch: string[]) =>
    this.restService.request<any, InboundListDTO[]>({
      method: 'GET',
      url: '/api/app/patient-verification/m-yVerification-status',
      params: { ltBranch },
    },
    { apiName: this.apiName });

    cancelVerificationStatus = (verificationId: string, verificationStatus: VerificationStatusV1) =>
      this.restService.request<any, any>({
        method: 'PUT',
        url: '/api/app/patient-verification/cancel-verification',
        body: { verificationId: verificationId, verificationStatus:verificationStatus },
      },
      { apiName: this.apiName });

  constructor(private restService: RestService) {}
}
