import { ListService, PagedAndSortedResultRequestDto } from '@abp/ng.core';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
// import {
//   FormBuilder,
//   FormControl,
//   FormGroup,
//   Validators,
// } from '@angular/forms';
// import { MatAccordion } from '@angular/material/expansion';
// import { MatPaginator } from '@angular/material/paginator';
// import { MatSort } from '@angular/material/sort';
// import { MatTableDataSource } from '@angular/material/table';
import { Title } from '@angular/platform-browser';
import { ActiveStatus } from 'projects/admin/src/app/admin-proxy/platform-app-management/rcm/platform-management/localization/enumeration/active-status.enum';
import {
  MasterNotesDTO,
  noteTypeOptions,
} from 'projects/admin/src/app/warehouse-proxy/inventory/dto';
import { MasterNotesService } from 'projects/order/src/app/order-proxy/order-management/inventory';
import { CommonService } from 'projects/shared/src/app/services/common.service';
import { CommunicationService } from 'projects/shared/src/app/services/communication.service';
// import { CreateUpdateMasterNotesDTO } from 'projects/order/src/app/order-proxy/order-management/inventory/dto';
// import {
//   PatientExportDatas,
//   PatientExportDTO,
// } from 'projects/patient/src/app/patient-proxy/patient/dto/models';
// import { ExportType } from 'projects/patient/src/app/patient-proxy/patient/enumeration-data/export-type.enum';
// import { ReportService } from 'projects/patient/src/app/patient-proxy/patient/report.service';
import { TableService } from 'projects/shared/src/app/table.service';
// import { TextValidators } from 'projects/shared/src/app/validations/text-validator';
import { Subscription } from 'rxjs';
// import { delay } from 'rxjs/operators';
// import Swal from 'sweetalert2';
// import { ToastrService } from 'ngx-toastr';
// import { MatSnackBar } from '@angular/material/snack-bar';

// import { DataTableDirective } from 'angular-datatables';
@Component({
  selector: 'app-inventory-notes',
  templateUrl: './inventory-notes.component.html',
  styleUrls: ['./inventory-notes.component.scss'],
  providers: [ListService],
})
export class InventoryNotesComponent implements OnInit {
  // notesForm: FormGroup;
  // notetype: string;
  // notesText = '';
  // panelOpenState: boolean = false;
  tableData: CustomNotesMasterDTO[] = [];
  // dataSource: MatTableDataSource<any> = new MatTableDataSource<any>([]);
  // @ViewChild(MatSort, { static: true }) sort: MatSort;
  // @ViewChild(MatAccordion, { static: true }) matAccordion: MatAccordion;
  // @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  // displayedColumns: string[] = ['options', 'customNoteType', 'noteReason'];
  // notesTableData: any[] = [];
  // notesId: string = '';
  // notesSearchForm: FormGroup;
  // saveButtonHide: boolean;
  // drpNoteType: { value: string; key: string }[] = [];
  // isShowSpinner: boolean = false;
  subscription$: Subscription[] = [];
  isLoading: boolean = true;
  notesListsubscription: Subscription;
  // dtOptions: any;
  NotesTableDate: any;
  selectedOption: number = ActiveStatus.Active;
  // @ViewChild(DataTableDirective, { static: false })
  // datatableElement: DataTableDirective;
  constructor(
    // private formBuilder: FormBuilder,
    private notesService: MasterNotesService,
    public list: ListService,
    // private snackBar: MatSnackBar,
    public title: Title,
    public Table: TableService,
    private communicationService: CommunicationService,
    private commonService : CommonService
    // private toastr: ToastrService
  ) {
    this.communicationService.inventoryMasterNotesCall$.subscribe(() => {
      this.loadNotesTable();
    });
  }

  // isNotesCreatePermission: boolean = false;
  // isNotesUpdatePermission: boolean = false;
  // isNotesDeletePermission: boolean = false;
  // isShownSaveButton: boolean = false;
  // isShownEditButton: boolean = false;
  // isShownDeleteButton: boolean = false;

  // isShownNotesTable: boolean = true;

  ngOnInit(): void {
    // this.dtOptions = {
    //   responsive: true,
    //   paging: false,
    //   scrollCollapse: true,
    //   scrollY: '410px',
    //   scrollX: true,
    //   dom: 'Bfrtip',
    //   language: {
    //     emptyTable: 'No records',
    //     info: 'Total : _MAX_ records',
    //     infoEmpty: 'Total : _MAX_ records',
    //     infoFiltered: '(filtered : _TOTAL_ records)',
    //     loadingRecords: 'Loading...',
    //     zeroRecords: 'No matching records',
    //   },
    //   buttons: [
    //     {
    //       extend: 'excel',
    //       text: 'Export',
    //       filename: 'Physician Info',
    //       exportOptions: {
    //         columns: ':visible:not(:first-child,:eq(0)) ',
    //       },
    //     },
    //   ]
    // };
    // this.createNotesForm();
    this.loadNotesTable();
    //To Load the Filter Dropdown
    //   this.drpNoteType = noteTypeOptions?.map((e) => {
    //     let x = {
    //       value: e.value,
    //       key: e.key,
    //     };
    //     return x;
    //   });
    //   this.title.setTitle('Qsecure | Notes');
  }


  //! Notes TableData Load Function
  loadNotesTable() {
    if (this.notesListsubscription) {
      this.notesListsubscription.unsubscribe();
    }
    this.isLoading = true;
    this.tableData = [];
   this.notesListsubscription = this.notesService
      .getNoteTypesByActiveStatus(this.selectedOption)
      .subscribe(
        (response) => {
          this.isLoading = false;
          this.NotesTableDate = response;
          this.NotesTableDate = this.NotesTableDate.map((data) => {
            return {
              ...data,
              creationTime: this.commonService.getFormattedDateTimeZone(
                data.creationTime
              ),
              lastModificationTime: this.commonService.getFormattedDateTimeZone(
                data.lastModificationTime
              ),
            };
          });
        },
        (err) => {
          this.isLoading = false;
          const data: HttpErrorResponse = err;
          // Swal.fire({
          //   icon: 'error',
          //   text: data?.error?.error?.message,
          // });
        }
      );

    this.subscription$.push(this.notesListsubscription);
  }


  // //After View Method
  // ngAfterViewInit(): void {
  //   this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
  //     dtInstance.columns().every(function () {
  //       const that = this;
  //       $('input', this.footer()).on('keyup change', function () {
  //         if (that.search() !== this['value']) {
  //           that.search(this['value']).draw();
  //         }
  //       });
  //     });
  //   });
  // }
  // ngOnDestroy(): void {
  //   this.subscription$?.forEach((sub) => {
  //     sub && sub?.unsubscribe();
  //   });
  // }
  // updateCharacterCount() {
  //   // Ensure the text length does not exceed the maximum length
  //   if (this.notesText?.length > 5000) {
  //     this.notesText = this.notesText?.substr(0, 5000);
  //   }
  // }
  //! Form Creation and Few Validations
  // createNotesForm() {
  //   this.notesForm = this.formBuilder.group({
  //     txtNoteType: new FormControl('', [Validators.required, this.noWhitespaceValidator,]),
  //     // txtReason: new FormControl('', [
  //     //   Validators.required,
  //     //   this.noWhitespaceValidator,
  //     // ]), //this.textValidators.isTextCheck,
  //   });
  // }

  // public noWhitespaceValidator(control: FormControl) {
  //   const isWhitespace = (control.value || '').trim().length === 0;
  //   const isValid = !isWhitespace;
  //   return isValid ? null : { whitespace: true };
  // }
  //! Notes Save Funtion
  // saveNotes() {
  //   this.isShowSpinner = true;
  //   this.saveButtonHide = true;
  //   let notesDetails: CreateUpdateMasterNotesDTO = {
  //     noteType: this.notesForm.value.txtNoteType,
  //   };

  //   if (
  //     this.notesId === '' ||
  //     this.notesId === undefined ||
  //     this.notesId === null
  //   ) {
  //     this.notesService.create(notesDetails).subscribe(
  //       (response) => {
  //         this.panelOpenState = false;
  //         this.toastr.success('Saved Successfully', 'Success');
  //         this.saveButtonHide = false;
  //         this.isShowSpinner = false;
  //         this.loadNotesTable();
  //         this.resetNotes();
  //       },
  //       (err) => {
  //         this.saveButtonHide = false;
  //         this.isShowSpinner = false;
  //         const data: HttpErrorResponse = err;
  //         Swal.fire({
  //           icon: 'info',
  //           text: data?.error?.error?.message,
  //         });
  //       }
  //     );
  //   } else {
  //     this.notesService.update(this.notesId, notesDetails).subscribe(
  //       (response) => {
  //         this.toastr.success('Updated Successfully', 'Success');
  //         this.loadNotesTable();
  //         this.resetNotes();

  //         this.saveButtonHide = false;
  //         this.isShowSpinner = false;
  //       },
  //       (err) => {
  //         this.saveButtonHide = false;
  //         this.isShowSpinner = false;

  //         const data: HttpErrorResponse = err;
  //         Swal.fire({
  //           icon: 'info',
  //           text: data?.error?.error?.message,
  //         });
  //       }
  //     );
  //   }
  //   this.notesId = '';
  // }
  // toggle() {
  //   this.panelOpenState = true;
  // }
  // toggleV2() {
  //   this.panelOpenState = false;
  // }


  //! View Notes Function By Id
  // viewNotes(notesTableId: string) {
  //   if (this.panelOpenState == false) {
  //     this.panelOpenState = true;
  //   }
  //   const notesGet = this.notesService.get(notesTableId).subscribe(
  //     (response) => {
  //       this.notesForm.patchValue({
  //         txtNoteType: response.noteType,
  //       });
  //       this.notesId = response.id;
  //     },
  //     (err) => {
  //       const data: HttpErrorResponse = err;
  //       Swal.fire({
  //         icon: 'info',
  //         text: data?.error?.error?.message,
  //       });
  //     }
  //   );
  //   this.subscription$.push(notesGet);
  // }

  //! Delete Notes Function By Id
  // deleteNotes(notesTableId: string) {
  //   Swal.fire({
  //     title: 'Are you sure you want to Delete?',
  //     text: "You won't be able to retrieve this data!",
  //     icon: 'warning',
  //     showCancelButton: true,
  //     confirmButtonColor: '#34c38f',
  //     cancelButtonColor: '#f46a6a',
  //     confirmButtonText: 'Yes, delete it!',
  //   }).then((result) => {
  //     if (result.value) {
  //       const notesDelete = this.notesService.delete(notesTableId).subscribe(
  //         (response) => {

  //           this.toastr.success('Deleted Successfully', 'Success');
  //           this.loadNotesTable();
  //           this.resetNotes();
  //         },
  //         (err) => {
  //           const data: HttpErrorResponse = err;
  //           Swal.fire({
  //             icon: 'info',
  //             text: data?.error?.error?.message,
  //           });
  //         }
  //       );
  //     }
  //   });
  // }
  // clearNoteType() {
  //   this.notetype = '';
  //   this.notesForm.patchValue({ txtNoteType: '' });
  // }
  // //! Notes Form Reset Function
  // resetNotes() {
  //   this.notesId = '';
  //   this.notesForm.patchValue({
  //     txtNoteType: '',
  //     // txtReason: '',
  //   });
  //   this.notetype = '';
  //   this.notesForm.reset();
  //   this.notesForm.markAsUntouched();
  //   this.notesForm.updateValueAndValidity();
  //   this.loadNotesTable();
  // }

}

export interface CustomNotesMasterDTO extends MasterNotesDTO {
  sNo: number;
  customNoteType: string;
}
