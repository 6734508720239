import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, ValidatorFn } from '@angular/forms';
import { MatOption } from '@angular/material/core';
import { MatSelect } from '@angular/material/select';
import { OrganizationUnitService } from 'projects/admin/src/app/admin-proxy/platform-app-management/rcm/platform-management/organization-units';
import { HttpErrorResponse } from '@angular/common/http';
import { PatientService } from 'projects/patient/src/app/patient-proxy/patient';
import {
  CreateUpdatePatientNextCallDTO,
  NoteType, PatientPersonalDTO, PatientPolicyDTO,
} from 'projects/patient/src/app/patient-proxy/patient-optimization/dto';
import { Observable, Subject, Subscription } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { DocumentCheckListComponent } from 'projects/admin/src/app/document-check-list/document-check-list.component';
import { PatientPverifyComponent } from '../patient-pverify/patient-pverify.component';
import { PatientVerificationComponent } from '../patient-verification/patient-verification.component';
// import { defaultGuid } from 'projects/admin/src/app/document-perviewer/document-perviewer.component';
import { ViewPatientLedgerComponent } from 'projects/admin/src/app/view-patient-ledger/view-patient-ledger.component';
import { AdminAddItemModalComponent } from 'projects/admin/src/app/admin-add-item-modal/admin-add-item-modal.component';
import { DocumentSortingComponent } from 'projects/admin/src/app/document-sorting/document-sorting.component';
import { PatientCommonNotesComponent } from 'projects/shared/src/app/components/patient-common-notes/patient-common-notes.component';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { AddNoteTabComponent } from '../add-note-tab/add-note-tab.component';
import { PatientAddPolicyComponent } from '../patient-add-policy/patient-add-policy.component';
import { Router } from '@angular/router';
import { Moment } from 'moment';
import { LocaleConfig } from 'ngx-daterangepicker-material';
import moment from 'moment';
import format from "date-fns/format";
import { PatientAddPolicyTabComponent } from '../patient-add-policy-tab/patient-add-policy-tab.component';
import { AddressService, PatientPersonalService } from '../patient-proxy/patient-optimization';
import { AssignDoNotCallReason } from '../patient-proxy/patient-optimization/assign-do-not-call-reason.enum';
import { CalenderScheduleComponent } from 'projects/schedule/src/app/calender-schedule/calender-schedule.component';
import { MatDatepicker, MatDatepickerInputEvent } from '@angular/material/datepicker';
import { PatientManualVerificationTabComponent } from '../patient-manual-verification-tab/patient-manual-verification-tab.component';
import { TableService } from 'projects/shared/src/app/table.service';
import { PatientNextCallService } from '../patient-proxy/patient-optimization/patient-next-call.service';
import { CookieService } from 'ngx-cookie-service';
import { ToastrService } from 'ngx-toastr';
import { DataTableDirective } from 'angular-datatables';
import { Title } from '@angular/platform-browser';
import { defaultGuid } from 'projects/shared/src/app/enums/allenums.enum';
import { NewVerificationComponent } from '../newVerification/newVerification.component';
import { dtCalllogTableOptions } from 'projects/admin/src/app/admin-dashboard/dashboard-data';
import { CommunicationService } from 'projects/shared/src/app/services/communication.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Payor } from '../patient-list-v2/patient-list-v2.component';
import { PayerDetailService } from '../patient-proxy/patient/payer-detail.service';
import { UserByRoleDTO } from 'projects/admin/src/app/admin-proxy/platform-app-management/identity';
import { UserService } from 'projects/admin/src/app/admin-proxy/platform-app-management/rcm/platform-management/user';
import { PatientCommonDocumentMedicareUploadTabComponent } from 'projects/shared/src/app/components/patient-common-document-medicare-upload-tab/patient-common-document-medicare-upload-tab.component';
import { DatePipe } from '@angular/common';
import { CommonService } from 'projects/shared/src/app/services/common.service';
@Component({
  selector: 'app-patient-list-v1',
  templateUrl: './patient-list-v1.component.html',
  styleUrls: ['./patient-list-v1.component.scss']
})
export class PatientListV1Component implements OnInit {
  isClose:boolean =false;
  defaultGuid=defaultGuid;
  PatientDetails: any;
  tblShpSelectedRowId: string;
  // tblItemSelectedRowId: string;
  CallLogDetails: any;
  notesData = []
  @ViewChild('picker') picker: MatDatepicker<Date>;
  // @ViewChild('datepickerInput') datepickerInput: ElementRef;

@ViewChild('datepicker') datepicker: MatDatepicker<any>;
@ViewChild('nextCallDatePicker') nextCallDatePicker: MatDatepicker<any>;
@ViewChild(DataTableDirective, { static: false })
datatableElement: DataTableDirective;
  customDatepickerClass = 'custom-datepicker';
  itemTableData: any = [];
  hcpcTableData: any = [];
  isOpenfields: boolean = false;
  showFilter: boolean = true;
  showDatepicker  = false;
  selectedRange: any = { startDate: null, endDate: null };
  ranges: any;
  minDate: Moment;
  maxDate: Moment;
  fromDate: any='';
  toDate: any='';
  selectedOption = 'option1';
  calendarLocale: LocaleConfig;
  calendarPlaceholder: string = '';
  alwaysShowCalendars: boolean;
  dateRangStatus: boolean = false;
  organizationUnitName: string='';
  showDatePicker: boolean = false;
  @ViewChild(CalenderScheduleComponent)
  CalenderScheduleComponent: CalenderScheduleComponent;
  dtTrigger: Subject<any> = new Subject<any>();
  public drpBranch: Branch[] = [];
  public branchList: Observable<Branch[]> | undefined;
  @ViewChild('selectBranch') selectBranch: MatSelect;
  @ViewChild('selectAllBranch') private selectAllBranch: MatOption;
  branchForm: FormGroup;
  filterForm: FormGroup;
  selectPayorList: any;
  payerId: string = defaultGuid;
  PayorSelectAllOption: boolean = false;
  userSelectAllOption:boolean=false;
  selectedPayorList: string[] = null;
  selectedUserList:string[]=null;
  primaryPayorId: any;
  userList:any;
  isdrpLoading: boolean = false;
  isUniq: boolean = true;
  priPayerDetails: Subscription;
   filteredPrimaryPayors: any;
  public drpPrimaryPayor: Payor[] = [];
  filteredCSRList: UserByRoleDTO[];
  patientSearchForm: FormGroup;
  tenantId: string;
  notesText:string = '';
  isLoaded = false;
  pageType: NoteType = NoteType.general;
  public policyDialogRef: MatDialogRef<PatientAddPolicyComponent>;
  subscription$: Subscription[] = [];
  chartId: string;
  documentId: string;
  patientId: string;
  callReason: any;
  selectedBranchList:string[]=null;
  selectedCSRList:string[]=null;
  nextCallDate:string=null;
  lastMaskProcessDate:string=null;
  dtPMCalllogTableOptions:any = {
    responsive: true,
    language: {
      emptyTable: 'No records',
    },
    paging: false,
    scrollCollapse: true,
    scrollY: '100px',
    scrollX: true,
    info: false,
    bFilter: false
  };
  calllogTableData:any;
  patientForm: FormGroup;
  patientNotesForm: FormGroup;
  sPID:string;
  isLoading: boolean;
  patientSearch:boolean=false;
  patientInupt:string=null;
  lstUsers: any;
  CSRFilterControl= new FormControl();
  userListAPI$: any;
  searchPatient: Subscription;
  userLoading: boolean=false;
  userSearchTxt: string=null;
  payorSearchTxt:string=null;
  currentPage : any = 1;
  pageOffset: any = 50;
  totalPages : any = 1;
  visibleColumns : number[];
  constructor(
    private formBuilder: FormBuilder,
    private branchService: OrganizationUnitService,
    private patientService:PatientService,
    private title: Title,
    private patientPersonalService: PatientPersonalService,
    private cookieService: CookieService,
    private toastr: ToastrService,
    // private PatientPersonalService: PatientPersonalService,
    private dialog: MatDialog,
    private router: Router,
    private PatientNextCallService: PatientNextCallService,
    private tableService: TableService,
    private communicationService: CommunicationService,
    private PatientPersonalService: PatientPersonalService,
    private snackBar: MatSnackBar,
    private payerDetailService: PayerDetailService,
    public PatientAddressService: AddressService,
    private userService: UserService,
    private datePipe: DatePipe,
    private commonService: CommonService,
  ) {}
  tableData$: Subscription;
  ngOnInit(): void {
    this.title.setTitle('Qsecure | Patient Management');
    this.patientSearchForm = this.formBuilder.group({
      patientDetails: new FormControl(''),
    });
     this.isLoaded = false;
    this.tenantId = localStorage.getItem('tenantId') ?? '';
    this.fromDate = this.convertISODateStringToCustomFormat(
      this. getCTStartOfMonth()
     )
       this.toDate = this.convertISODateStringToCustomFormat(
         this.ctDateNow()
       );
    this.initializeForms();
    this.filterForm.patchValue({
      txtNextCallDate:
        this. getCTStartOfMonth()
        +'-'+
        this.ctDateNow()
     });
    this.selectedRange.startDate=this. getCTStartOfMonth(),
    this.selectedRange.endDate= this.ctDateNow()


    this.convertISODateStringToCustomFormat(
      this. getCTStartOfMonth()
     ) +'-'+ this.convertISODateStringToCustomFormat(
      this.ctDateNow()
    );
    this.calendarLocale = {
      customRangeLabel: 'Pick a date...',
      applyLabel: 'Apply',
      clearLabel: 'Clear',
      format: 'MM/DD/YYYY',
      daysOfWeek: ['su', 'mo', 'tu', 'we', 'th', 'fr', 'sa'],
      monthNames: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
      firstDay: 1
    };

    this.ranges = {
      'Today': [moment(), moment()],
      'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
      'Last 7 Days': [moment().subtract(6, 'days'), moment()],
      'Last 30 Days': [moment().subtract(29, 'days'), moment()],
      'This Month': [moment().startOf('month'), moment().endOf('month')],
    };
    // this.getBranchList();
    // this.donotCallReason();
    // this.GetPatientdetails();
    this.patientManagementSearch();
    const comServiceForPm =
    this.communicationService.patientManagementFunctionCall$.subscribe((patientId) => {
      this.loadPatientDetails(patientId);
    });
    this.subscription$.push(comServiceForPm);
    const comServiceForPmNextCall =
    this.communicationService.patientManagementFunctionCallNextCallDate$.subscribe(() => {
      // this.GetPatientdetails();
      this.patientManagementSearch();
    });
    this.subscription$.push(comServiceForPmNextCall);
  this.loadDropdownUser('');
  this.getPrimaryPayerList();

  // this.CSRFilterControl.valueChanges.subscribe((dxTypeFilterText) => {
  //   dxTypeFilterText = dxTypeFilterText.toLowerCase();
  //   this.filteredCSRList = this.lstUsers.filter((dx) =>
  //     dx.name.toLowerCase().includes(dxTypeFilterText)
  //   );
  // });
  }

 ngOnDestroy(): void {
  this.subscription$?.forEach((sub) => {
    sub && sub?.unsubscribe();
  });
  this.dtTrigger.unsubscribe();
}
ctDateNow(): Date {
  let usaTime = new Date().toLocaleString('en-US', {
    timeZone: 'America/Chicago',
  });
  const dateObj = new Date(usaTime);
  // dateObj.setHours(0, 0, 0, 0); // Set hours, minutes, seconds, and milliseconds to zero
  // const year = dateObj.getFullYear();
  // const month = String(dateObj.getMonth() + 1).padStart(2, '0');
  // const day = String(dateObj.getDate()).padStart(2, '0');
  // const formattedDate = `${year}-${month}-${day}`;
  // console.log(formattedDate);
  return  new Date(dateObj.getFullYear(), dateObj.getMonth() + 1, 0);
  // return dateObj;
}
getCTStartOfMonth(): Date {
  let usaTime = new Date().toLocaleString('en-US', {
    timeZone: 'America/Chicago',
  });
  const dateObj = new Date(usaTime);
  dateObj.setDate(1); // Set to the first day of the month
  dateObj.setHours(0, 0, 0, 0);
  //    const year = dateObj.getFullYear();
  // const month = String(dateObj.getMonth() + 1).padStart(2, '0');
  // const day = String(dateObj.getDate()).padStart(2, '0');
  // const formattedDate = `${year}-${month}-${day}`;
  // console.log(formattedDate);

  return dateObj;
}
// @Output() childEvent: EventEmitter<string> = new EventEmitter<string>();
// sendEventToParent(): void {
//   this.childEvent.emit('Event from child!');
// }


// getDateWithOutTime(date: Date) {
//   const currentDate = date;
//   const formattedDate = `${(currentDate.getMonth() + 1)
//     .toString()
//     .padStart(2, '0')}/${currentDate
//       .getDate()
//       .toString()
//       .padStart(2, '0')}/${currentDate.getFullYear()}`;
//   return formattedDate;
// }
convertISODateStringToCustomFormat(isoDateString: any): string {
  const inputDate = new Date(isoDateString);

  // Extracting date components
  const month = (inputDate.getMonth() + 1).toString().padStart(2, '0');
  const day = inputDate.getDate().toString().padStart(2, '0');
  const year = inputDate.getFullYear().toString();

  // Formatted date string in MM/DD/YYYY format
  const formattedDateString = `${month}/${day}/${year}`;

  return formattedDateString;
}
isEmpty(value: any): boolean {
  return (
    value === null ||
    value === undefined ||
    value === '' ||
    value === defaultGuid
  );
}

passInputValue(event: any) {
  const value = event.target.value;
   if(!this.isEmpty(value) && value !='-'){
      this.onRowDoubleClick(value)
    }
}

onRowDoubleClick(textToCopy) {
  this.copyTextToClipboard(textToCopy);
  this.showNotification('Text copied: ' + textToCopy);
}

showNotification(message: string): void {
  this.snackBar.open(message, 'Close', {
    duration: 3000, // Duration in milliseconds
    horizontalPosition: 'end', // Positioning the notification horizontally
    verticalPosition: 'bottom', // Positioning the notification vertically
  });
}

copyTextToClipboard(text: string): void {
  const textArea = document.createElement('textarea');
  textArea.value = text;
  document.body.appendChild(textArea);
  textArea.select();
  document.execCommand('Copy');
  textArea.remove();
}
getFormattedDate(date: any): string {
  return date
    ? new Date(date).toLocaleString('en-US', {
        month: '2-digit',
        day: '2-digit',
        year: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        hour12: true,
      })
    : '-';
}
getFormattedDateV1(date: any): string {
  return date
    ? new Date(date).toLocaleString('en-US', {
        month: '2-digit',
        day: '2-digit',
        year: 'numeric'
      })
    : null;
}
checkNotSelectPayer(){
  if((this.isEmpty(this.filterForm.value.drpPrimaryPayor)|| this.filterForm.value.drpPrimaryPayor.length<=0) && (this.payorSearchTxt!==null&&this.payorSearchTxt!=="")){
    this.payerSearch('');
    this.payorSearchTxt = null;
  }
}
checkNotSelectUser(){
  if((this.isEmpty(this.filterForm.value.drpCSR) || this.filterForm.value.drpCSR.length<=0) && (this.userSearchTxt!==null&&this.userSearchTxt!=="")){
    this.UserSearch('');
    this.userSearchTxt = null;
  }
}
getDateWithOutTime(date: Date | null): string | null {
  if (date === null) {
    return '-'; // or return a default value, e.g., 'N/A'
  }

  const currentDate = date;
  const formattedDate = `${(currentDate.getMonth() + 1)
    .toString()
    .padStart(2, '0')}/${currentDate
      .getDate()
      .toString()
      .padStart(2, '0')}/${currentDate.getFullYear()}`;

  return formattedDate;
}


loadPatientDetails(patientId: string) {
  this.isLoading = true;
  this.tableData$?.unsubscribe();
  const loadPatientDetails =
    this.PatientPersonalService.getBinPatientDetailsByGPatient(
      patientId
    ).subscribe((res) => {
      this.isLoading = false;
      this.patientForm = this.formBuilder.group({
        homeNo: [res?.homePhoneNo === null || res?.homePhoneNo ==='' ? '-' : res?.homePhoneNo ],
        cell: [res?.cellNo === null || res?.cellNo ==='' ? '-' : res?.cellNo],
        workNo: [res?.workPhoneNo === null || res?.workPhoneNo ==='' ? '-' : res?.workPhoneNo],
        // nextCallDate: [this.getDateWithOutTime(new Date(res?.nextCallDate))],
        nextCallDate: [this.getDateWithOutTime(res?.nextCallDate ? new Date(res.nextCallDate) : null)],
        rxDate: [this.getDateWithOutTime(res?.rxDate ? new Date(res.rxDate) : null)],
        // rxDate: [this.getDateWithOutTime(new Date(res?.rxDate))],
      });

      const patientNotesString = Array.isArray(res?.patientNotes) ? res?.patientNotes.join('\n') : '';
      this.patientNotesForm =this.formBuilder.group({
        notes: [patientNotesString]
      });

      this.calllogTableData = res['callDetails'];
    },
      (err) => {
        this.isLoading = false;
        this.patientForm = this.formBuilder.group({
          homeNo: ['-'],
          cell: ['-'],
          workNo: ['-'],
          nextCallDate:['-'],
          rxDate:['-']
        });
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });

      });
  this.subscription$.push(loadPatientDetails);
  this.tableData$ = loadPatientDetails;
}

  openNextCallDatePicker() {
    this.nextCallDatePicker.open();
  }

 //Intialize Forms
  initializeForms() {
    this.branchForm = this.formBuilder.group({
      drpBranch: new FormControl(''),
      txtBranch: new FormControl(''),
      txtNextCallDate:new FormControl(''),
      txtMaskDate:new FormControl(''),
    });

    this.patientNotesForm = this.formBuilder.group({
      notes: ['-']
    });

    this.patientForm = this.formBuilder.group({
      homeNo: ['-'],
      cell: ['-'],
      workNo: ['-'],
      nextCallDate:['-'],
      rxDate : ['-']
    });

    this.filterForm=this.formBuilder.group({
      txtChartNo:new FormControl(''),
      txtPatientName:new FormControl(''),
      txtBirthdate: new FormControl(''),
      txtNextCallDate: new FormControl(''),
      drpCSR:new FormControl(''),
      drpPrimaryPayor:new FormControl(''),
      primaryPayor: new FormControl('')
    })
  }
  datesUpdateds(e) {
    if (e.startDate != null || e.endDate != null) {
      this.dateRangStatus = true;
      const startDate = new Date(e.startDate);
      const endDate = new Date(e.endDate);
      this.fromDate = this.datePipe.transform(startDate, 'MM/dd/yyyy');
      this.toDate = this.datePipe.transform(endDate, 'MM/dd/yyyy');
    } else {
      this.fromDate = null;
      this.toDate = null;
    }
  }
  // export enum AssignDoNotCallReason {
  //   ChangedDMECompany = 0,
  //   Deceased = 1,
  //   NoPatientResponse = 2,
  //   PatientReturnedList = 3,
  // }

  // assignDoNotCall(data){

  //   this.PatientPersonalService.assignDoNotCallReasonByPatientIDAndAssignDoNotCallReason(data.patientId,AssignDoNotCallReason.ChangedDMECompany).subscribe(response => {

  //   })
  // }
  assignDoNotCall(patientId:string,reasonId:string){
    this.patientPersonalService.assignDoNotCallReasonByPatientIDAndAssignDoNotCallReasonV1(patientId,reasonId)
    .subscribe(response => {
      this.toastr.success('Save Successfully', 'Success');
    })
  }
  //Branch List Api Call and Dropdown Binding Method
  getBranchList() {
    const getBranchList = this.branchService
      .getBranchList(this.tenantId)
      .subscribe(
        (response) => {
          response?.forEach((element) => {
            this.drpBranch.push({
              id: element.id,
              organizationUnitName: element.organizationUnitName,
            });
          });

          this.branchList = this.branchForm
            ?.get('txtBranch')
            ?.valueChanges?.pipe(
              startWith(''),
              map((value) =>
                this.drpBranch?.filter((option) =>
                  option?.organizationUnitName
                    ?.toLowerCase()
                    ?.includes(value?.toLowerCase() ?? '')
                )
              )
            );
        },
        (err) => {
          const data: HttpErrorResponse = err;
          Swal.fire({
            icon: 'info',
            text: data?.error?.error?.message,
          });
        }
      );
    this.subscription$.push(getBranchList);
  }
  allBranchSelect() {
    if (this.selectAllBranch.selected) {
      this.selectBranch.options.forEach((item: MatOption) => item.select());
    } else {
      this.selectBranch.options.forEach((item: MatOption) => item.deselect());
    }
  }
  searchDetails(){
    this.selectedBranchList=this.branchForm.value.drpBranch;
    this.nextCallDate=this.branchForm.value.txtNextCallDate;
    this.lastMaskProcessDate=this.branchForm.value.txtMaskDate;
    // this.GetPatientdetails();
    this.patientManagementSearch();
  }

  onRowSelected(patientId: string) {
    this.sPID=patientId;
    // this.tblItemSelectedRowId = patientId;
  }


  GetPatientdetails() {
    this.patientInupt = (this.patientSearchForm.value.patientDetails !==''&&this.patientSearchForm.value.patientDetails !==null)?
    this.patientSearchForm.value.patientDetails.trim():this.patientSearchForm.value.patientDetails;
    let ltBranch: string[]=this.selectedBranchList;
    let ltCsr: string[];
    this.isLoaded = true;
    let NextCallDate: string, LastMaskProcessedDate: string, sChartNo: string, sPatientName: string, DOB: string, sPolicyName: string, CsrName: string
    // this.selectedBranchList,this.selectedCSRList, this.nextCallDate, this.lastMaskProcessDate
    // this.tableData$?.unsubscribe();
    const getPatientsManagementList =
    this.patientPersonalService.getPatientsManagementV2(
      ).subscribe(response => {
    this.PatientDetails = response;

    this.PatientDetails = this.PatientDetails.map((patientdata) => {
      return {
        ...patientdata,
        // dob: this.commonService.getFormattedDate(
        //   patientdata.dob
        // ),
        dob: this.commonService.formatDateDob(
          patientdata.dob
        ),
        nextCallDate: this.commonService.getFormattedDate(
          patientdata.nextCallDate
        ),
        rxDate: this.commonService.getFormattedDate(
          patientdata.rxDate
        ),
        setupDate: this.commonService.getFormattedDate(
          patientdata.setupDate
        ),
        checkListDate: this.commonService.getFormattedDateTimeZone(
          patientdata.checkListDate
        ),
        lastMask: this.commonService.getFormattedDate(
          patientdata.lastMask
        ),
        createdDate: this.commonService.getFormattedDateTimeZone(
          patientdata.createdDate
        ),
        modifiedDate: this.commonService.getFormattedDateTimeZone(
          patientdata.modifiedDate
        ),
      };
    });

    this.isLoaded = false;
  }, error => {
    console.error('Error fetching patient details:', error);
    this.isLoaded = false;
  });
}



  openAddNotesPopUp() {
    const config: MatDialogConfig = {
      disableClose: true,
      minHeight: '30vh',
      minWidth: '50vw',
      data: {
        noteType: 'patient', // 'Order Note',
        notesId: '00000000-0000-0000-0000-000000000000',
        // orderId: '0b50be04-6c75-92d3-97c6-3a0e17ee6a18',
         patientId: this.patientId,
         defaultFaxId:this.chartId,
        isCallNote: false,
        isPending:false,
       docId:this.documentId,
        inboundStatusShortCodeId:defaultGuid,
        selectedNode: defaultGuid,
        addnotes:true
      },
    };
    const dialogRef = this.dialog.open(AddNoteTabComponent, config);

    const closeDialog = dialogRef.afterClosed().subscribe(
      () => {
        // this.getReminderTableData();
      },
      (err) => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      }
    );
    this.subscription$.push(closeDialog);
  }


  // ScheduleAppointmentModal(data){

  //   this.tableService.setCalenderPopup(data)
  //   this.router.navigate(['settings/calender'])
  // // this.CalenderScheduleComponent.onPopupOpen(data)
  // }
  openViewNotesPopup() {
    const config: MatDialogConfig = {
      disableClose: true,
      width: '70vw',
      maxWidth: '70vw',
      height: '40vw',
      maxHeight: '40vw',
      data: {
        patientId: defaultGuid,
        pageType: this.pageType,
        isAddVisible: false,
      },
    };
    const dialogRef = this.dialog.open(PatientCommonNotesComponent, config);

    dialogRef.afterClosed().subscribe(
      () => {
        //this.getReminderTableData();
      },
      (err: HttpErrorResponse) => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      }
    );
  }
  viewDocument(
    patientId: string,
    documentId: string,
    defaultDocumentId: string,
    isSorted: boolean
  ) {

    const dialogRef = this.dialog.open(DocumentSortingComponent, {
      width: '100vw',
      height: '100vh',
      maxWidth: '100vw',
      maxHeight: '100vh',
      panelClass: 'fullscreen-dialog-panel',
      disableClose: true,
      data: {
        // Add any data you want to pass to the dialog here
        // For example, you can pass patientId and documentId
        patientId: patientId,
        documentId: documentId,
        defaultDocumentId: defaultDocumentId,
        isSorted: isSorted,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      // Handle the dialog close event here if needed

    });
  }
  viewPatientLedger(patientId: string) {
    this.dialog.open(ViewPatientLedgerComponent, {
      width: '100vw',
      height: '100vh',
      maxWidth: '100vw',
      maxHeight: '100vh',
      panelClass: 'fullscreen-dialog-panel',
      disableClose: true,
      data: {
        patientId: patientId,
      },
    });
    this.dialog.afterAllClosed.subscribe((resposne) => {});
  }
  openAddItemModal() {
    const dialogRef = this.dialog.open(AdminAddItemModalComponent, {
      disableClose: true,

      data: { patientsId: defaultGuid }
    })
    dialogRef.afterClosed().subscribe(() => {
      // this.getPatientSIdFormPatientSDetails(defaultGuid);
    })
  }
    //Toggle Filter Div
    toggleFilter() {
      this.showFilter = !this.showFilter;
    }
    openVerificationModal(policy) {
      const dialogRef = this.dialog.open(PatientManualVerificationTabComponent, {
        disableClose: true,
        data: { patientId: policy.patientId, policyId: policy.policyId },
      });

      const closeDialog = dialogRef.afterClosed().subscribe((result) => {
        // result !== '' &&
        //   result !== null &&
        //   result !== undefined &&
        //   this.policyDialogRef.close(result);

        // this.policyId !== defaultGuid && this.getVerificationStatus();
      });
      this.subscription$.push(closeDialog);
    }

    ViewPolicy(policy: PatientPolicyDTO) {

      const dialogRef = this.dialog.open(PatientAddPolicyTabComponent, {
        data: {
          patientId: policy.patientId,
          policyId: policy.policyId,
          wipId: policy.wipId ?? defaultGuid,
        },
      });
      const aftClosed = dialogRef.afterClosed().subscribe(
        (result) => {
          // this.patientIdEmitter.next(this.patientId);

          // this.patientId !== defaultGuid && this.getTableData();
        },
        (err) => {
          const data: HttpErrorResponse = err;
          Swal.fire({
            icon: 'info',
            text: data?.error?.error?.message,
          });
        }
      );
      this.subscription$.push(aftClosed);
    }

  // openPverifyModal(data) {

  //   const dialogRef = this.dialog.open(PatientPverifyComponent, {
  //     disableClose: true,
  //     data: { patientId: "7716cab4-407f-795a-5c25-3a0ec74e6765"},
  //   });

  //   const closeDialog = dialogRef.afterClosed().subscribe(() => { });
  //   this.subscription$.push(closeDialog);
  // }
    //! open policy modal using config
    openPverifyModal(data) {

      const dialogRef = this.dialog.open(PatientAddPolicyTabComponent, {
        // maxWidth: '80%',
        // minHeight: '60%',
        disableClose: true,
        data: {
          patientId: data.patientId ?? defaultGuid,
          wipId: data.wipId ?? defaultGuid,
          // policyId: data,
        },
      });

      this.subscription$.push();
    }


    tblShpSelectedRow(id: string) {
      this.tblShpSelectedRowId = this.tblShpSelectedRowId === id ? '' : id;
      this.tblShpSelectedRowId != '' && this.getOrderItems(id);
    }
    getOrderItems(orderId: string) {
      this.itemTableData = [];
      this.hcpcTableData = [];
      // this.loadItmTbl = false;
      // this.saleOrderItemService.getItemsListByOrderIdByOrderId(orderId).subscribe(
      //   (response) => {

      //     this.itemTableData = response;
      //     this.loadItmTbl=true;
      //   },
      //   (err) => {}
      // );
    }

    // getCallLogDetails(id){
    //   this.PatientNextCallService.getNextCallRecordsByGPatient(id).subscribe(res =>{

    //     this.CallLogDetails = res
    //     this.notesData = res['lastFivePatientNotes']

    //     this.notesText = String(this.notesData)

    //     return this.notesText.replace(/\n/g, '<br>');


    //   })
    // }

    getHCPCCodes(id: string) {
      this.hcpcTableData =
        this.hcpcTableData.length > 0 ? [] : this.hcpcTableData;

      this.hcpcTableData = [
        { code: 'A4520' },
        { code: 'TD51245' },
        { code: 'AC54621' },
        { code: 'AC54621' },
        { code: 'AC54621' },
        { code: 'AC54621' },
      ];
    }


  newOrderTemplate() {
    this.router.navigate([
      'orders/saleorderCreateEditTab/871d2144-7dc0-755b-8672-3a0ebcd1e1d1/f7b95d68-5c6c-a099-f2fb-3a0ec19b7d67'
    ]);
  }
  // checkListModel() {
  //   const dialogRef = this.dialog.open(DocumentCheckListComponent, {
  //     height: '75%',
  //     width: '80%',
  //     disableClose: true,
  //   });
  //   const closeModal = dialogRef.afterClosed().subscribe(() => {});
  // }
  // tblItemSelectedRow(patientId:string,documentId:string,chartId:string){
  //   this.tblItemSelectedRowId = this.tblItemSelectedRowId === patientId ? '' : patientId;
  //   this.patientId=patientId;
  //   this.documentId=documentId;
  //   this.chartId=chartId;

  //   this.tblItemSelectedRowId != '' && this.getHCPCCodes(patientId);
  //   // this.getCallLogDetails(patientId)
  // }
  checkListModel(data) {
    const dialogRef = this.dialog.open(DocumentCheckListComponent, {
      // height: '75%',
      width: '80%',
      disableClose: true,
      data: {
        // Add any data you want to pass to the dialog here
        // For example, you can pass patientId and documentId

        documentId: data.documentId,
        faxId: data.faxId || 'N/A',
      },
    });

    // Subscribe to the closed event
    dialogRef.afterClosed().subscribe(() => {
      // Call the addNotes function directly
      dialogRef.componentInstance.addNotes();
    });
  }
  viewPatient(data: any) {
    if (data.patientId != undefined) {
      const storeSubscription =
      data.patientId !== '' &&
      data.patientId !== null &&
      data.patientId !== undefined &&
        this.patientPersonalService.get( data.patientId).subscribe(
          (response) => {
            const selectedPatient: PatientPersonalDTO = response;
            this.tableService.setCountryTable(selectedPatient);
          },
          (err) => {
            const data: HttpErrorResponse = err;
            Swal.fire({
              icon: 'info',
              text: data?.error?.error?.message,
            });
          }
        );
      this.subscription$.push(storeSubscription);
      this.tableService.setDocumentFormData(null);
      this.cookieService.set('patientStatus', 'false');
      this.cookieService.set('OrderTab', 'false');
      this.router.navigate(['/patientCreateEditTab/' +  data.patientId+'/'+data.documentId]);
    }
  }
  viewPatientDocImg(data: any) {
    if (data.patientId != undefined) {
      const storeSubscription =
      data.patientId !== '' &&
      data.patientId !== null &&
      data.patientId !== undefined &&
        this.patientPersonalService.get( data.patientId).subscribe(
          (response) => {
            const selectedPatient: PatientPersonalDTO = response;
            this.tableService.setCountryTable(selectedPatient);
          },
          (err) => {
            const data: HttpErrorResponse = err;
            Swal.fire({
              icon: 'info',
              text: data?.error?.error?.message,
            });
          }
        );
      this.subscription$.push(storeSubscription);
      this.tableService.setDocumentFormData(null);
      this.cookieService.set('patientStatus', 'false');
      this.cookieService.set('OrderTab', 'false');
      this.router.navigate(['/patientCreateEditTab/' +  data.patientId+'/'+data.documentId+'/'+'Sorting']);
    }
  }
  donotCallReason(){
    this.patientPersonalService.getdonotCallReason(1).subscribe(response => {
      this.callReason = response.items;

    }, error => {
      console.error('Error fetching call reason details:', error);
    });
  }

  openNewVerification(patientId:string,isOnline:boolean) {

    const config: MatDialogConfig = {
      disableClose: true,
      data:{
        patientId:patientId,
        isOnline:isOnline
      }
    };
    const dialogRef = this.dialog.open(NewVerificationComponent, config);

    const closeDialog = dialogRef.afterClosed().subscribe(
      () => {
        // this.getReminderTableData();
      },
      (err) => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      }
    );
    this.subscription$.push(closeDialog);
  }

  onKeyPressSearch(){
    this.isClose=true;
  this.patientSearch=true;
  // this.GetPatientdetails();
  this.patientManagementSearch();
  }

  clearPatinet(){
    this.patientSearchForm.reset();
    this.patientSearch=false;
    // this.GetPatientdetails();
    this.patientManagementSearch();
    this.isClose=false;
  }

  fnGetVisibleColumns (columns:number[]){
    this.visibleColumns = columns;
  }

  changePage(pageNo : number){
   
    if( pageNo != this.currentPage && pageNo > 0 && pageNo <= this.totalPages)
    {
      this.currentPage = pageNo; 
      this.patientManagementSearch();
    }
  }

  patientManagementSearch(){
    this.isLoaded = true;
    if (this.searchPatient) {
      this.searchPatient.unsubscribe();
    }
    this.patientForm.reset();
    this.patientNotesForm.reset();
    this.searchPatient=
    this.PatientPersonalService.patientManagementSearchV1(
      this.filterForm.value.txtChartNo,
      this.filterForm.value.txtPatientName,
      this.getFormattedDateV1(this.filterForm.value.txtBirthdate),
      '',
      null,
      this.fromDate ,
      this.toDate,
      // this.getFormattedDateV1(this.filterForm.value.txtNextCallDate),
      (this.filterForm.value.drpCSR===''||this.filterForm.value.drpCSR===null)?[]:this.filterForm.value.drpCSR,
      (this.filterForm.value.drpPrimaryPayor===''||this.filterForm.value.drpPrimaryPayor===null)?[]:this.filterForm.value.drpPrimaryPayor,
      this.currentPage,
      this.pageOffset
    ).subscribe(response => {
        let pagedResponse = response as any;
        this.totalPages = Math.ceil(pagedResponse.totalCount / this.pageOffset);
        this.PatientDetails = pagedResponse.items;
 
        this.PatientDetails = this.PatientDetails.map((patientdata) => {
          return {
            ...patientdata,
            // dob: this.commonService.getFormattedDate(
            //   patientdata.dob
            // ),
            dob: this.commonService.formatDateDob(
              patientdata.dob
            ),
            nextCallDate: this.commonService.getFormattedDate(
              patientdata.nextCallDate
            ),
            rxDate: this.commonService.getFormattedDate(
              patientdata.rxDate
            ),
            setupDate: this.commonService.getFormattedDate(
              patientdata.setupDate
            ),
            checkListDate: this.commonService.getFormattedDateTimeZone(
              patientdata.checkListDate
            ),
            lastMask: this.commonService.getFormattedDate(
              patientdata.lastMask
            ),
            createdDate: this.commonService.getFormattedDateTimeZone(
              patientdata.createdDate
            ),
            modifiedDate: this.commonService.getFormattedDateTimeZone(
              patientdata.modifiedDate
            ),
          };
        });

        this.isLoaded = false;
      }, error => {
        console.error('Error fetching patient details:', error);
        this.isLoaded = false;
      });
      }
  areFieldsNotEmpty(): boolean {
    const { txtNextCallDate, txtPatientName, txtChartNo,drpCSR,drpPrimaryPayor} = this.filterForm.value;
    return txtNextCallDate  || txtPatientName || txtChartNo||drpCSR||drpPrimaryPayor ;
  }
  resetFilter(){
  this.filterForm.reset();
  // this.fromDate = this.convertISODateStringToCustomFormat(
  //   this. getCTStartOfMonth()
  //  )
  //    this.toDate = this.convertISODateStringToCustomFormat(
  //      this.ctDateNow()
  //    );
  // // this.filterForm.patchValue({
  // //   txtNextCallDate:
  // //     this. getCTStartOfMonth()
  // //     +'-'+
  // //     this.ctDateNow()
  // //  });
  //  this.selectedRange=this.fromDate+'-'+this.toDate;
  //  this.selectedRange.startDate=this.fromDate;
  //  this.selectedRange.endDate=this.toDate;
  //  console.log(this.selectedRange);
  //  console.log("this.selectedRange");
  this.payerSearch('');
  this.UserSearch('');
  // this.GetPatientdetails();
  this.patientManagementSearch();
  }

  loadDropdownUser(search:string) {
    this.userListAPI$?.unsubscribe();
  this.userLoading=true;
  this.lstUsers=[];
  this.filteredCSRList=[];
    const csrUsersList = this.userService.getCSRUsers(search).subscribe(
      (response) => {
        if (response) {
          this.lstUsers = response;
          this.filteredCSRList = this.lstUsers;
          this.userLoading=false;
        }
      },
      (error) => {
        this.toastr.error(error,'Fail');
        this.userLoading=false;
        }
    );

    this.userListAPI$ = csrUsersList;
  }
  onSelectPrimaryPayor(event: any) {
    this.selectedPayorList = event.value;
    if (this.selectedPayorList.includes('selectAll')) {
      this.PayorSelectAllOption = true;
      if (this.PayorSelectAllOption) {

        this.selectedPayorList = this.drpPrimaryPayor.map(payer => payer.payerId);
        this.filterForm
          .get('drpPrimaryPayor')
          .patchValue(this.selectedPayorList);

      }
    } else if (this.selectedPayorList.includes('DeselectAll')) {
      this.selectedPayorList = [];
      this.PayorSelectAllOption = false;
      this.filterForm
        .get('drpPrimaryPayor')
        .patchValue([]);
    }
    else {
      this.PayorSelectAllOption =
        this.selectedPayorList.length === this.drpPrimaryPayor.length;
    }

    // const payorList = event.value;
    // let splitValue = payorList.split('/');
    // this.primaryPayorId = splitValue[0];
    // this.checkPayorUniqness(this.primaryPayorId);
  }
  onSelectUser(event: any) {
    this.selectedUserList = event.value;
    if (this.selectedUserList.includes('selectAll')) {
      this.userSelectAllOption = true;
      if (this.userSelectAllOption) {

        this.selectedUserList = this.filteredCSRList.map(user => user.userId);
        this.filterForm
          .get('drpCSR')
          .patchValue(this.selectedUserList);

      }
    } else if (this.selectedUserList.includes('DeselectAll')) {
      this.selectedUserList = [];
      this.userSelectAllOption = false;
      this.filterForm
        .get('drpCSR')
        .patchValue([]);
    }
    else {
      this.userSelectAllOption =
        this.selectedUserList.length === this.filteredCSRList.length;
    }

    // const payorList = event.value;
    // let splitValue = payorList.split('/');
    // this.primaryPayorId = splitValue[0];
    // this.checkPayorUniqness(this.primaryPayorId);
  }
  checkPayorUniqness(payerId: string) {
    if (payerId !== '' && payerId !== defaultGuid) {
      this.PatientAddressService.getPayorCheckAvailablity(payerId).subscribe(
        (response) => {
          this.isUniq = response;
        },
        (err) => {
          const data: HttpErrorResponse = err;
          Swal.fire({
            icon: 'info',
            text: data?.error?.error?.message,
          });
        }
      );
    }
  }

  clearPrimaryPolicy(event: Event) {
    this.PayorSelectAllOption = false;
    event.stopPropagation();
    this.primaryPayorId = this.filterForm.value?.drpPrimaryPayor;
    this.filterForm.patchValue({
      drpPrimaryPayor: '',
    });
    this.getPrimaryPayerList();
    // this.getTableData();
  }
  clearNextCallDate(){
    this.filterForm.patchValue({
      txtNextCallDate: null,
     });
     this.fromDate = null;
     this.toDate = null;
     this.selectedRange=null;
    //  this.patientManagementSearch();

  }
  clearUser(event: Event) {
    this.PayorSelectAllOption = false;
    event.stopPropagation();
    this.userList = this.filterForm.value?.drpCSR;
    this.filterForm.patchValue({
      drpCSR: '',
    });
    this.UserSearch('');
  }
  isDropdownOpen = false;
  onDropdownOpenChange(isOpen: boolean){
    this.isDropdownOpen = isOpen;
    if(this.isDropdownOpen){
      this.getPrimaryPayerList()
    }
  }

  payerSearch(value) {
    //Get Payer list
    this.isdrpLoading = true;
    this.payorSearchTxt=value;
    if (this.priPayerDetails) {
      this.priPayerDetails.unsubscribe();
    }
    let eligibilityMode = 4;
    this.priPayerDetails = this.payerDetailService
      .getdrpPayerByIModeV1(eligibilityMode, value, this.payerId)
      .subscribe((response) => {
        this.selectPayorList = response;
        this.isdrpLoading = false;
        this.selectPayorList = response.map((x) => {
          x['payerShortCodeList'] =
          ((x?.payerCode===null||x?.payerCode==="")?'-' :x?.payerCode)+ ' ' + '/' + ' ' + x?.payerName+' ' + '/' + ' '
          + ((x?.code===null||x?.code==="")?'-':x?.code);
          return x;
        });

        this.filteredPrimaryPayors =  this.selectPayorList;
        this.drpPrimaryPayor = this.selectPayorList;
      },
    (err)=>{
      this.isdrpLoading = false;
    });
  }
  UserSearch(value){
  this.userSearchTxt=value;
  this.loadDropdownUser(value);
  }
  //Get Payer list
  getPrimaryPayerList() {
    this.isdrpLoading = true;
    let eligibilityMode = 4;
    this.filteredPrimaryPayors=[];
    this.payerDetailService
      .getdrpPayerByIModeV1(eligibilityMode, '', '')
      .subscribe(
        (response) => {
          this.selectPayorList = response;
          this.isdrpLoading = false;
          this.selectPayorList = response.map((x) => {
            x['payerShortCodeList'] =
            ((x?.payerCode===null||x?.payerCode==="")?'-' :x?.payerCode)+ ' ' + '/' + ' ' + x?.payerName+ ' ' +'/' + ' '
          + ((x?.code===null||x?.code==="")?'-':x?.code);
            return x;
          });

          this.filteredPrimaryPayors =  this.selectPayorList;
          this.drpPrimaryPayor = this.selectPayorList;
        },
        (err) => {
          this.isdrpLoading = true;
        }
      );

    }
    drpDownMessage() {
      if (this.isdrpLoading === false) {
        return 'No Matches found';
      } else {
        return 'Loading...';
      }
    }
    drpDownMessageV1() {
      if (this.userLoading === false) {
        return 'No Matches found';
      } else {
        return 'Loading...';
      }
    }
  addSlashToDate(event: Event): void {
    const input = event.target as HTMLInputElement;
    const inputValue = input.value;
    input.value = inputValue
      .replace(/^(\d{2})(\d{1,2})/, '$1/$2')
      .replace(/^(\d{2}\/\d{2})(\d+)/, '$1/$2')
      .replace(/[^\d\/]/g, '');
  }
   //To add inbound documents manually
   addDocumentType(patientId: string, wipId: string) {
    const dialogData = {
      constructorpatientId: patientId ?? defaultGuid, // Replace with your actual patientId value
      constructortenantId: this.tenantId ?? defaultGuid,       // Replace with your actual tendid value
      selectedWipId: wipId ?? defaultGuid,
      isPatientList: true,
      isPatientDisable:false,
    };
    const dialogRef = this.dialog.open(PatientCommonDocumentMedicareUploadTabComponent, {
      minHeight: '33vh',
      minWidth: '75vw',
      data: dialogData,
      disableClose: true
    });
  }
}
export interface Branch {
  id?: string;
  organizationUnitName?: string;
}
