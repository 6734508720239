import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DataTableDirective } from 'angular-datatables';
import { ViewTemplateComponent } from '../view-template/view-template.component';
import Swal from 'sweetalert2';
import { PatientTemplateService } from 'projects/admin/src/app/admin-proxy/platform-app-management/rcm/platform-management/patient-template';
import { HttpErrorResponse } from '@angular/common/http';
import { CommunicationService } from 'projects/shared/src/app/services/communication.service';
import { ToastrService } from 'ngx-toastr';
import { DatePipe } from '@angular/common';
@Component({
  selector: 'app-template-creation-table',
  templateUrl: './template-creation-table.component.html',
  styleUrls: ['./template-creation-table.component.scss']
})
export class TemplateCreationTableComponent implements OnInit {

  @Input() tableData: any;
  @ViewChild(DataTableDirective, { static: false })
  datatableElement: DataTableDirective;
  templateList: any;

  dtTemplateOptions: any = {
    responsive: true,
    paging: false,
    dom: 'Bfrtip',
    language: {
      emptyTable: 'No records',
      info: 'Total : _MAX_ records',
      infoEmpty: 'Total : _MAX_ records',
      infoFiltered: '(filtered : _TOTAL_ records)',
      loadingRecords: 'Loading...',
      zeroRecords: 'No matching records',
    },
    scrollCollapse: true,
    scrollY: '350px',
    scrollX: true,
    fixedHeader: true,

    search: {
      smart: false,
    },
    buttons: [
      {
        extend: 'excel',
        filename: 'Template',
        text: 'Export',
        exportOptions: {
          columns: ':visible:not(:first-child,:eq(0)) ',
        },
      },
      {
        extend: 'colvis',
        columns: ':not(:eq(0), :eq(1))',
      }
    ],
  };

  constructor(private snackBar: MatSnackBar, private dialog: MatDialog, private patientTemplateServices: PatientTemplateService, private communicationService: CommunicationService, private toastr: ToastrService,private datepipe: DatePipe ) { }

  ngOnInit(): void {
    this.templateList = this.tableData;

    this.dtTemplateOptions.buttons[0].filename =
    'Template' + '-' + this.datepipe.transform(new Date(), 'MM-dd-yyyy');

  }
  //After View Method
  ngAfterViewInit(): void {
    if (this.datatableElement && this.datatableElement.dtInstance) {
      this.refreshDataTable();
    } else {
      setTimeout(() => this.refreshDataTable(), 500);
    }
  }


  refreshDataTable(): void {
    this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.columns().every(function () {
        const that = this;
        $('input', this.footer()).on('keyup change', function () {
          if (that.search() !== this['value']) {
            that.search(this['value']).draw();
          }
        });
      });
    });
  }

  onRowDoubleClickV1(textToCopy) {
    this.copyTextToClipboard(textToCopy);
    this.showNotification('Text copied: ' + textToCopy);
  }

  showNotification(message: string): void {
    this.snackBar.open(message, 'Close', {
      duration: 3000, // Duration in milliseconds
      horizontalPosition: 'end', // Positioning the notification horizontally
      verticalPosition: 'bottom', // Positioning the notification vertically
    });
  }

  copyTextToClipboard(text: string): void {
    const textArea = document.createElement('textarea');
    textArea.value = text;
    document.body.appendChild(textArea);
    textArea.select();
    document.execCommand('Copy');
    textArea.remove();
  }

  viewTemplate(blobName: string) {
    this.dialog.open(ViewTemplateComponent, {
      width: '100vw',
      height: '100vh',
      maxWidth: '100vw',
      maxHeight: '100vh',
      panelClass: 'fullscreen-dialog-panel',
      disableClose: true,
      data: {
        blobName: blobName,
      },
    });
    this.dialog.afterAllClosed.subscribe((resposne) => { });
  }
  deleteTemplate(tempId: string) {
    Swal.fire({
      title: 'Are you sure you want to Delete?',
      text: 'You won\'t be able to retrieve this data!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#34c38f',
      cancelButtonColor: '#f46a6a',
      confirmButtonText: 'Yes, delete it!'
    }).then(result => {
      if (result.value) {
        this.patientTemplateServices.delete(tempId).subscribe(response => {
          this.communicationService.triggerTemplateFunction();
          this.toastr.success('Deleted Successfully', 'Success')
        }, err => {
          const data: HttpErrorResponse = err;
          Swal.fire({
            icon: 'info',
            text: data?.error?.error?.message,
          });
        });
      }
    });
  }
}
