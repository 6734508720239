import { AgmCoreModule } from '@agm/core';
import { MatGoogleMapsAutocompleteModule } from '@angular-material-extensions/google-maps-autocomplete';
import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatOptionModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { BrowserModule } from '@angular/platform-browser';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { TextMaskModule } from 'angular2-text-mask';
import { NgxMaskModule } from 'ngx-mask';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { SharedModule } from 'projects/shared/src/app/shared.module';
import { AddBranchWorkHoursComponent } from './add-branch-work-hours/add-branch-work-hours.component';
import { AddCoverageAreaComponent } from './add-coverage-area/add-coverage-area.component';
import { AddPickupDetailsComponent } from './add-pickup-details/add-pickup-details.component';
import { AddWorkingHoursComponent } from './add-working-hours/add-working-hours.component';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AppointmentTypeComponent } from './appointment-type/appointment-type.component';
import { BranchWorkHoursComponent } from './branch-work-hours/branch-work-hours.component';
import { HolidayComponent } from './holiday/holiday.component';
import { LeaveApprovalComponent } from './leave-approval/leave-approval.component';
import { OnTimeCreateEditComponent } from './on-time-create-edit/on-time-create-edit.component';
import { OntimeBranchComponent } from './ontime-branch/ontime-branch.component';
import { OntimeCoverageAreaComponent } from './ontime-coverage-area/ontime-coverage-area.component';
import { OntimePickupAreaComponent } from './ontime-pickup-area/ontime-pickup-area.component';
import { OntimeUserComponent } from './ontime-user/ontime-user.component';
import { PickupCreationComponent } from './pickup-creation/pickup-creation.component';
import { OntimeUserWorkHoursComponent } from './ontime-user-work-hours/ontime-user-work-hours.component';
import { ProfileComponent } from './profile/profile.component';
import { SupplyItemsComponent } from './supply-items/supply-items.component';
import { OntimeLeaveApplyComponent } from './ontime-leave-apply/ontime-leave-apply.component';
import { GoogleAPIKey } from 'projects/patient/src/environments/environment';
import { DataTablesModule } from 'angular-datatables';
const providers : any = []

@NgModule({
  declarations: [
    AppComponent,
    OnTimeCreateEditComponent,
    AppointmentTypeComponent,
    SupplyItemsComponent,
    HolidayComponent,
    ProfileComponent,
    OntimeUserComponent,
    AddWorkingHoursComponent,
    OntimeBranchComponent,
    BranchWorkHoursComponent,
    LeaveApprovalComponent,
    // CoverageAreaComponent,
    // OntimePickupComponent,
    AddPickupDetailsComponent,
    OntimeCoverageAreaComponent,
    OntimePickupAreaComponent,
    AddCoverageAreaComponent,
    // CoverageAreaComponent,
    // OntimePickupComponent
    PickupCreationComponent,
    AddBranchWorkHoursComponent,
    OntimeUserWorkHoursComponent,
    OntimeLeaveApplyComponent],
  imports: [
    BrowserModule,
    MatGoogleMapsAutocompleteModule,
    AppRoutingModule,
    CommonModule,
    AppRoutingModule,
    NgSelectModule,
    NgxMaskModule.forRoot(),
    NgxMatSelectSearchModule,
    TextMaskModule,
    MatDatepickerModule,
    MatListModule,
    MatGridListModule,
    MatCardModule,
    MatButtonModule,
    MatDialogModule,
    MatFormFieldModule,
    DataTablesModule,
    MatStepperModule,
    MatIconModule,
    MatSnackBarModule,
    MatRadioModule,
    MatSelectModule,
    MatCheckboxModule,
    NgbModule,
    MatInputModule,
    MatOptionModule,
    MatDatepickerModule,
    ReactiveFormsModule,
    FormsModule,
    MatDividerModule,
    MatTableModule,
    MatProgressSpinnerModule,
    MatPaginatorModule,
    MatTooltipModule,
    SharedModule,
    MatProgressBarModule,
    MatExpansionModule,
    MatSlideToggleModule,
    NgxMaterialTimepickerModule,
    //AgmCoreModule,
    AgmCoreModule.forRoot({
      apiKey: GoogleAPIKey,
      language: "en",
      libraries: ['places', 'drawing', 'geometry'],
    })
  ],
  bootstrap: [AppComponent],
  exports:[AddBranchWorkHoursComponent]
})
export class AppModule { }
@NgModule({})
export class OnTimeModule {
  static forRoot(): ModuleWithProviders<OnTimeModule> {
    return {
      ngModule: AppModule,
      providers:[]
    };
  }
}
