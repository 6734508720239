<mat-dialog-content class="mat-typography">
    <div class="modal-header" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
      <h1 class="modal-title" >Map User</h1>
      <!-- <button class="text-right close" aria-label="Close" mat-dialog-close> -->
        <i aria-label="Close" mat-dialog-close class="fa fa-times"></i>
      <!-- </button> -->
    </div>

    <form [formGroup]="sendAnotherUserForm">
    <div class="modal-body">

        <mat-card-content>
          <div class="row">
            <!-- <div class="col-12"> -->
                <!-- CSR Dropdown -->
                <mat-form-field class="col-6">
                    <!-- <mat-label>CSR <span class="asterisk">*</span>   [Name/Role/Branch]</mat-label> -->

                    <mat-label>
                      <div class="select-placeholder-container" *ngIf="iscsrLoading">
                        <span>Loading...</span>
                        <mat-spinner class="spinner" diameter="20"></mat-spinner>
                      </div>
                      <span *ngIf="!iscsrLoading">CSR <span class="asterisk">*</span></span>
                      [Name/Role/Branch]
                    </mat-label>

                    <mat-select
                      maxlength="40"
                      formControlName="drpCSR"
                      #singleSelect
                      [(ngModel)]="seletedCSRValue"
                    >
                      <mat-option>
                        <ngx-mat-select-search
                          placeholderLabel="Search- Name/Role/Branch"
                          [formControl]="CSRFilterControl"
                          formControlName="txtCSR"
                          noEntriesFoundLabel="No Matches found"
                          (keyup)="UserSearch($event.target.value)"
                        ></ngx-mat-select-search>
                      </mat-option>
                      <mat-option
                        *ngFor="let csr of filteredCSRList"
                        [disabled]="csr.userId === userId"
                        [value]="csr.userId" id="csrDetails"
                      >
                        {{ csr.userDetails }}
                      </mat-option>
                    </mat-select>
                    <mat-error class="spanAlignment" *ngIf="
                    sendAnotherUserForm?.get('drpCSR')?.touched &&
                    sendAnotherUserForm?.get('drpCSR')?.errors?.required
              ">
                      CSR  is a required field!
                    </mat-error>
                    <button type="button" mat-button (click)="clearUser($event)"
                    matSuffix mat-icon-button>
                    <mat-icon>close</mat-icon>
                  </button>
                  </mat-form-field>


                  <!-- <mat-form-field class="col-6 pl-0">
                    <mat-label>Note Type <span class="asterisk">*</span></mat-label>
                    <mat-select formControlName="drpNotetype"  placeholder="Note Type"
                      #singleSelect>
                      <mat-option>
                        <ngx-mat-select-search [formControl]="NoteTypeControl" placeholderLabel="Search"
                          noEntriesFoundLabel="No Matches found" formControlName="txtNoteType"></ngx-mat-select-search>
                      </mat-option>
                      <mat-option *ngFor="let note of drpNotetypeList" [value]="note.id+ '/' +note.noteType">
                        {{ note.noteType }}
                      </mat-option>
                    </mat-select>
                    <mat-error class="spanAlignment" *ngIf="
                    sendAnotherUserForm?.get('drpNotetype')?.touched &&
                    sendAnotherUserForm?.get('drpNotetype')?.errors?.required
              ">
                      Note Type is a required field!
                    </mat-error>
                  </mat-form-field> -->


                  <mat-form-field class="col-6 pl-0">
                    <mat-select formControlName="drpNotetype">
                      <mat-option>
                        <ngx-mat-select-search [formControl]="NoteTypeControl" placeholderLabel="Search"
                          noEntriesFoundLabel="No Matches found"
                          formControlName="txtNoteType"></ngx-mat-select-search>
                      </mat-option>
                      <mat-option *ngFor="let note of drpNotetypeList" [value]="note.id+ '/' +note.noteType">
                        {{ note.noteType }}
                      </mat-option>
                    </mat-select>

                    <mat-label>
                      <div class="select-placeholder-container" *ngIf="isdrpLoading">
                        <span>Loading...</span>
                        <mat-spinner class="spinner" diameter="20"></mat-spinner>
                      </div>
                      <span *ngIf="!isdrpLoading">Note Type <span class="asterisk">*</span></span>
                    </mat-label>

                    <mat-error class="spanAlignment" *ngIf="
                sendAnotherUserForm?.get('drpNotetype')?.touched &&
                sendAnotherUserForm?.get('drpNotetype')?.errors?.required
              ">
                      Note Type is a required field!
                    </mat-error>
                  </mat-form-field>

            <!-- </div> -->
            <div class="col-12">
                <!--Comments-->
            <!-- <mat-form-field >
              <mat-label>Comments <span class="asterisk">*</span></mat-label>
              <textarea formControlName="txtComments" rows="5" maxlength="500" matInput ></textarea>
            </mat-form-field> -->

            <mat-form-field>
              <mat-label>Comments<span class="asterisk">*</span></mat-label>
              <textarea matInput rows="6" autocomplete="off" maxlength="5000" formControlName="txtComments"  [(ngModel)]="notesText" (input)="updateCharacterCount(5000)"
                type="text"></textarea>
                <div class="char-count">
                  {{ notesText?.length }} / 5000
                </div>
              <mat-error class="spanAlignment" *ngIf="sendAnotherUserForm?.get('txtComments')?.touched">
                <ng-container *ngIf="sendAnotherUserForm?.get('txtComments')?.hasError('required')">
                  Comments is a required field!
                </ng-container>
                <ng-container *ngIf="sendAnotherUserForm?.get('txtComments')?.hasError('whitespace')">
                  Comments cannot be just whitespace!
                </ng-container>
              </mat-error>
            </mat-form-field>

            </div>
          </div>
        </mat-card-content>
    </div>
    <div class="modal-footer">
      <div class="col-12 text-center">
        <button mat-button class="buttonColor mb-2 mr-2" mat-raised-button [disabled]="isSaveDisable||sendAnotherUserForm.invalid|| sendAnotherUserForm?.get('drpCSR').value===defaultGuid"
         (click)="csrDetailsSave(sendAnotherUserForm.get('drpCSR').value, sendAnotherUserForm.get('txtComments').value)">
          <span >Save</span>
          <mat-icon *ngIf='isShowSpinner'>
            <mat-spinner class="spinner-border-sm" diameter="20"> </mat-spinner>
          </mat-icon>
        </button>
        <button mat-button class="resetclr mb-2 mr-2"(click)="Reset()">Reset</button>
      </div>
    </div>
  </form>
  </mat-dialog-content>
