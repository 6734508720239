<table
  datatable
  [dtOptions]="dtBulkTableOptions"
  class="row-border hover w-100 display"

>
<!-- (dblclick)="onRowDblClick($event)" -->
  <thead>
    <tr>
      <th>#</th>
      <th>Product</th>
      <th>HCPC code</th>
      <th>Unit Cost</th>
      <th>Insurance Category</th>
      <th>Allowable amount</th>
      <th>Modified Date</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let item of tableData; let i = index" [attr.data-index]="i">
      <td>{{ i + 1 }}</td>
      <td>{{ item.product }}</td>
      <td>{{ item.hcpc }}</td>
      <td>{{ item.cost }}</td>
      <td>{{ item.insurance_category }}</td>
      <td>{{ item.allowable }}</td>
      <td>{{ item.modified_date | date: 'MM-dd-yyyy' }}</td>
    </tr>
  </tbody>
</table>
