<div class="col-12" style="margin-top: -18px;">
  <div class=" d-flex align-items-center justify-content-between m-0">
    <h4 class="font-size-18 m-0"><b>Referral Practice</b></h4>
    <div class=" page-title-box page-title-right">
      <ol class="breadcrumbs m-0">
        <li class="breadcrumb-item cls">Referral</li>
        <li class="breadcrumb-item active">{{1?"View ":"New "}} Referral Practice</li>
      </ol>
    </div>
  </div>
</div>
<div class="row" style="margin-top: -27px;">
  <div class="col-xl-4 pr-0">
    <div class="card card-body">
      <app-practice-management></app-practice-management>
    </div>
  </div>
  <div class="col-xl-8">
    <ul class="card card-body" ngbNav #nav="ngbNav" justify="justified"
      class="alert alert-success nav nav-pills nav-fill">
      <li ngbNavItem="physician">
        <a ngbNavLink>Physicians</a>         
        <ng-template ngbNavContent>
          <app-practice-add-doctor></app-practice-add-doctor>
        </ng-template>
      </li>
      <li ngbNavItem="requirements">
        <a ngbNavLink>Requirements</a>
        <ng-template ngbNavContent>
        </ng-template>
      </li>
      <li ngbNavItem="MoreInfo">
        <a ngbNavLink>More Info</a>
        <ng-template ngbNavContent>
        </ng-template>
      </li>
      <li ngbNavItem="Notes">
        <a ngbNavLink>Notes</a>
        <ng-template ngbNavContent>
        </ng-template>
      </li>
      <li ngbNavItem="FaxLog">
        <a ngbNavLink>Fax Log</a>
        <ng-template ngbNavContent>
          <app-referral-fax-log></app-referral-fax-log>
        </ng-template>
      </li>
      <li ngbNavItem="FaxNumber">
        <a ngbNavLink>Fax Number</a>
        <ng-template ngbNavContent>
          <app-referral-fax-number></app-referral-fax-number>
        </ng-template>
      </li>
    </ul>
    <div [ngbNavOutlet]="nav"></div>
  </div>
</div>
