import { Component, OnInit, ViewChild } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { AddProductModalComponent } from '../add-product-modal/add-product-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { AddAllowanceComponent } from '../add-allowance/add-allowance.component';
import Swal from 'sweetalert2';
import {
  ProductService,
  ProductType,
  Status,
} from '../item-proxy/item-management/items';
import { HttpErrorResponse } from '@angular/common/http';
import { PagedAndSortedResultRequestDto } from '@abp/ng.core';
import {
  ProductDetailsService,
  ResupplyProductDetailsService,
} from '../item-proxy/item-management/optimization';
import {
  CreateUpdateResupplyProductDetailsDTO,
  ResuplyProductDetailSearchDTO,
} from '../item-proxy/item-management/optimization/dto';
import { ToastrService } from 'ngx-toastr';
import { map, startWith } from 'rxjs/operators';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { SearchResupplyProductDTO } from '../item-proxy/item-management/items/dto';
import {
  ItemExportDTO,
  ItemExportDatas,
} from 'projects/patient/src/app/patient-proxy/patient/dto';
import { ReportService } from 'projects/patient/src/app/patient-proxy/patient/report.service';
import { ExportType } from 'projects/patient/src/app/patient-proxy/patient/enumeration-data/export-type.enum';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-resupply-product-tab',
  templateUrl: './resupply-product-tab.component.html',
  styleUrls: ['./resupply-product-tab.component.scss'],
})
export class ResupplyProductTabComponent implements OnInit {
  step: boolean = false;
  supplyProductForm: FormGroup;
  resupplyProductForm: FormGroup;
  subscription$: Subscription[] = [];
  isProgress: boolean;
  filterMainProduct: any;
  reSupplyId: string = '';
  supplyTableData: any[] = [];
  drpMainProduct: any[] = [];
  MainProduct: string = '';
  isLoading: boolean = false;
  optionStatus: string = 'Add Product Allowables';
  dataSource: MatTableDataSource<any> = new MatTableDataSource<any>([]);
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild(MatPaginator, { static: false }) Paginator: MatPaginator;
  @ViewChild(MatTable, { static: false }) tableRef: MatTable<any>;
  arrDisplayedColumns: string[] = [
    'Options',
    'name',
    'mainProductName',
    'minimumQuantity',
    'availableQuantity',
    'hcpcCode',
    'status',
    
  ];
  constructor(
    private formBuilder: FormBuilder,
    public dialog: MatDialog,
    private productService: ProductService,
    private productDetailService: ProductDetailsService,
    private toastr: ToastrService,
    private reportService: ReportService,
    private resupplyDetailsService: ResupplyProductDetailsService,
    public title: Title
  ) {}

  ngOnInit(): void {
    this.title.setTitle('Qsecure | Resupply Product');
    this.resupplyProductForm = this.formBuilder.group({
      txtBrandName: new FormControl(null, Validators.required),
      txthcpcCode: new FormControl('', Validators.required),
      txtProductCode: new FormControl(''),
      txtproductDescription: new FormControl('', Validators.required),
      txtminimumQuantity: new FormControl('', Validators.required),
      txtMainProductFilter: new FormControl(''),
      txtMainProduct: new FormControl('', Validators.required),
      mainProductName:new FormControl(''),
    });
    this.supplyProductForm = this.formBuilder.group({
      Name: new FormControl(''),
      productCode: new FormControl(''),
      description: new FormControl(''),
      hcpcCode: new FormControl(''),
      createdDate: new FormControl(''),
      status: new FormControl(''),
      productStatus: new FormControl(''),
      minQty: new FormControl(''),
      avaiQty: new FormControl(''),
      mainProductName:new FormControl(''),
    });
  const SupplyProductValueChanges =
  this.supplyProductForm.valueChanges.subscribe((value) => {
    let filter = {
      Name: value.Name.trim().toLowerCase() ?? '',
      productCode: value.productCode.trim().toLowerCase() ?? '',
      description: value.description.trim().toLowerCase()?? '',
      hcpcCode: value.hcpcCode.trim().toLowerCase()?? '',
      status: value.status.trim().toLowerCase()?? '',
      productStatus: value.productStatus.trim().toLowerCase()?? '',
      minQty: value.minQty.trim().toLowerCase()?? '',
      avaiQty: value.avaiQty.trim().toLowerCase()?? '',
      mainProductName: value.avaiQty.trim().toLowerCase()?? ''
    };
    if (this.supplyProductForm.valid) {
      this.dataSource.filter = JSON.stringify(filter);
      this.filterSupplytable(filter);
    }
  });

this.subscription$.push(SupplyProductValueChanges);

  let filter = {
    Name:  '',
    productCode:  '',
    description:  '',
    hcpcCode:  '',
    status: '',
    productStatus:  '',
    minQty:  '',
    avaiQty: '',
    mainProductName:''
  };
  this.filterSupplytable(filter);

   
    this.getDropDown();
    // this.getResuplyList();
  }

  getDropDown() {
    const getMainProduct = this.productDetailService
      .getList(new PagedAndSortedResultRequestDto())
      .subscribe((response) => {
        this.drpMainProduct = response.items.map((x) =>
          x.status == 'Active' ? x : []
        );

        this.filterMainProduct = this.resupplyProductForm
          ?.get('txtMainProductFilter')
          .valueChanges.pipe(
            startWith(''),
            map((value) =>
              this.drpMainProduct.filter((option) =>
                option?.mainProductName
                  ?.toLowerCase()
                  ?.includes(value?.toLowerCase() ?? '')
              )
            )
          );
      });
    this.subscription$.push(getMainProduct);
  }

  filterSupplytable(filter) {
    let filterInput: ResuplyProductDetailSearchDTO = {
      resupplyProductName: filter.Name,
      productCode: filter.productCode,
      minimumQuantity: filter.minQty,
      availableQuantity: filter.avaiQty,
      stockStatus: filter.productStatus,
      status: filter.status,
      hcpcCode: filter.hcpcCode,
      mainProductName: filter.mainProductName
      // createdDate: filter.createdDate
    };

    this.supplyTableData = [];
    const resupplyProductSearch = this.resupplyDetailsService
      .getSearchResupplyByInput(filterInput)
      .subscribe((datas) => {
        this.supplyTableData = [];
        datas?.items?.forEach((data) => {
          this.supplyTableData.push({
         
            name: data?.resupplyProductName,
            productCode: data?.productCode,
            hcpcCode: data?.hcpcCode,
            productDescription: data?.productDescription,
            id: data?.id,
            // supplyId: data?.resupplyProductId,
            // createdDate: data?.createdDate,
            resupplyProductStatus: data?.status,
            status: data?.status,
            minimumQuantity: data?.minimumQuantity,
            availableQuantity: data?.availableQuantity,
            stockStatus: data?.stockStatus,
            mainProductName: data?.mainProductName
          });
        });
        this.dataSource = new MatTableDataSource(this.supplyTableData);
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.Paginator;
        this.isLoading = false;
      });
    this.subscription$.push(resupplyProductSearch);
  }

  statusChanged(event: MatSlideToggleChange, data: any) {
    if (event.checked == true) {
      Swal.fire({
        title: 'Do you want to activate?',
        text: 'By Changing Status, this Product  will be activated',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#34c38f',
        cancelButtonColor: '#f46a6a',
        confirmButtonText: 'Yes, Activate it!',
      }).then((result) => {
        if (result.value) {
          let productCategoryDetails = {
            mainproductId: data?.mainProductId,
            resupplyProductName: data?.resupplyProductName,
            hcpcCode: data?.hcpcCode,
            productCode: data?.productCode,
            productDescription: data?.productDescription,
            status: Status.Active,
            minimumQuantity: data?.minimumQuantity,
          };
          if (
            data?.id &&
            data?.id !== '' &&
            data?.id !== null &&
            data?.id !== undefined
          ) {
            this.resupplyDetailsService
              .update(data.id, productCategoryDetails)
              .subscribe(
                (response) => {
                  // Swal.fire({ title: 'Success', html: 'Activated Successfully', icon: 'success', timer: 3000, timerProgressBar: true })
                  this.toastr.success('Activated Successfully', 'Success');
                  this.getResuplyList();
                },
                (err) => {
                  const data: HttpErrorResponse = err;
                  Swal.fire({
                    icon: 'info',
                    text: data?.error?.error?.message,
                  });
                }
              );
          }
        } else {
          this.getResuplyList();
        }
      });
    } else if (event.checked == false) {
      Swal.fire({
        title: 'Do you want to deactivate?',
        text: 'By Changing Status, this Product will be deactivated',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#34c38f',
        cancelButtonColor: '#f46a6a',
        confirmButtonText: 'Yes, deactivate it!',
      }).then((result) => {
        if (result.value) {
          let productCategoryDetails = {
            mainproductId: data?.mainProductId,
            resupplyProductName: data?.resupplyProductName,
            hcpcCode: data?.hcpcCode,
            productCode: data?.productCode,
            productDescription: data?.productDescription,
            status: Status.Inactive,
            minimumQuantity: data?.minimumQuantity,
          };
          if (
            data?.id &&
            data?.id !== '' &&
            data?.id !== null &&
            data?.id !== undefined
          ) {
            this.resupplyDetailsService
              .update(data.id, productCategoryDetails)
              .subscribe(
                (response) => {
                  // Swal.fire({ title: 'Success', html: 'Deactivated Successfully', icon: 'success', timer: 3000, timerProgressBar: true })
                  this.toastr.success('Deactivated Successfully', 'Success');
                  this.getResuplyList();
                },
                (err) => {
                  const data: HttpErrorResponse = err;
                  Swal.fire({
                    icon: 'info',
                    text: data?.error?.error?.message,
                  });
                }
              );
          }
        } else {
          this.getResuplyList();
        }
      });
    }
  }

  getResuplyList() {
    this.isProgress = true;
    const productGetList = this.resupplyDetailsService
      .getList(new PagedAndSortedResultRequestDto())
      .subscribe((response) => {
        this.supplyTableData = [];
        response?.items?.forEach((data) => {
          this.supplyTableData.push({
            name: data?.resupplyProductName,
            productCode: data?.productCode,
            productDescription: data?.productDescription,
            hcpcCode: data?.hcpcCode,
            id: data?.id,
            // supplyId: data?.resupplyProductId,
            createdDate: data?.creationTime,
            resupplyProductStatus: data?.status,
            mainProductId: data?.mainproductId,
            resupplyProductName: data?.resupplyProductName,
            status: data?.status,
            minimumQuantity: data?.minimumQuantity,
            availableQuantity: data?.availableQuantity,
            stockStatus: data?.stockStatus,
            // allowablesId: data?.allowablesId
          });
        });
        this.isProgress = false;
        this.dataSource = new MatTableDataSource(this.supplyTableData);
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.Paginator;
        this.isProgress = false;
      });
    this.subscription$.push(productGetList);
  }

  addResupplyProduct() {
    let CreateUpdateProductDTO: CreateUpdateResupplyProductDetailsDTO = {
      mainproductId: this.resupplyProductForm.value.txtMainProduct,
      hcpcCode: this.resupplyProductForm.value.txthcpcCode,
      resupplyProductName: this.resupplyProductForm.value.txtBrandName,
      productCode: 0,
      productDescription: this.resupplyProductForm.value.txtproductDescription,
      minimumQuantity: this.resupplyProductForm.value.txtminimumQuantity,
    };
    if (
      this.reSupplyId == null ||
      this.reSupplyId == '' ||
      this.reSupplyId == undefined
    ) {
      this.resupplyDetailsService.create(CreateUpdateProductDTO).subscribe(
        (response) => {
         this.resetForm()
          this.getResuplyList();
          // Swal.fire({ title: 'Success', html: 'Saved Successfully', icon: 'success', timer: 3000, timerProgressBar: true });
          this.toastr.success('Saved Successfully', 'Success');
        },
        (err) => {
          const data: HttpErrorResponse = err;
          Swal.fire({
            icon: 'info',
            text: data?.error?.error?.message,
          });
        }
      );
    } else {
      this.resupplyDetailsService
        .update(this.reSupplyId, CreateUpdateProductDTO)
        .subscribe(
          (response) => {
            this.resetForm()
            this.getResuplyList();
            // Swal.fire({ title: 'Success', html: 'Saved Successfully', icon: 'success', timer: 3000, timerProgressBar: true });
            this.toastr.success('Saved Successfully', 'Success');
          },
          (err) => {
            const data: HttpErrorResponse = err;
            Swal.fire({
              icon: 'info',
              text: data?.error?.error?.message,
            });
          }
        );
    }
  }
  //!ViewSupplyProduct
  ViewSupplyProduct(data) {
    const dialogRef = this.dialog.open(AddProductModalComponent, {
      disableClose: true,
      data: { supplyId: data.id },
    });

    const closeModal = dialogRef.afterClosed().subscribe(() => {
      this.getResuplyList();
    });
    this.subscription$.push(closeModal);
  }

  //! supply product getby id

  getBySupplyProductById(data) {
    this.reSupplyId = data.id;
    const ResupplyProduct = this.resupplyDetailsService
      .get(data.id)
      .subscribe((response) => {
        this.step = true;

        this.resupplyProductForm.patchValue({
          txtBrandName: response?.resupplyProductName,
          txtMainProduct: response?.mainproductId,
          txtproductDescription: response?.productDescription,
          txthcpcCode: response?.hcpcCode,
          txtProductCode: response?.productCode,
          txtminimumQuantity: response?.minimumQuantity,
        });
        // this.resupplyProductStockStatus = response?.stockStatus;
      });
    this.subscription$.push(ResupplyProduct);
  }
  toggle() {
    this.step = true;
  }
  toggleV2() {
    this.step = false;
  }
  //! view popup modal
  ViewAddSupplyModalProduct(data) {
    const dialogRef = this.dialog.open(AddAllowanceComponent, {
      height: '65%',
      width: '70%',
      disableClose: true,
      data: {
        productId: data.id,
        productName: data.name,
        prodHpscCode: data.hcpcCode,
        allowablesId: data.allowablesId,
        supplyId: data.id,
        resupplyProductName: data.resupplyProductName,
      },
    });
    const closeModal = dialogRef.afterClosed().subscribe(() => {
      this.getResuplyList();
    });
    this.subscription$.push(closeModal);
  }
  resetForm() {
    this.resupplyProductForm?.reset();
    this.clearValidationErrors()
  }
  
    // Clear validation errors without triggering validation.
    clearValidationErrors() {
   
      Object.keys(this.resupplyProductForm.controls).forEach(key => {
        this.resupplyProductForm.get(key).setErrors(null);
      });
    }
  DeleteSupplyProduct(data) {
    Swal.fire({
      title: 'Are you sure you want to Delete?',
      text: "You won't be able to retrieve this data!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#34c38f',
      cancelButtonColor: '#f46a6a',
      confirmButtonText: 'Yes, delete it!',
    }).then((result) => {
      if (result.value) {
        const deleteResupplyProduct =
          data &&
          this.productService
            .deleteResupplyProductByMainProductIdAndResupplyProductId(
              data.id,
              data.supplyId
            )
            .subscribe(
              (resposne) => {
                this.getResuplyList();
              },
              (err) => {
                const data: HttpErrorResponse = err;
                Swal.fire({
                  icon: 'info',
                  text: data?.error?.error?.message,
                });
              }
            );
        this.subscription$.push(deleteResupplyProduct);
      }
    });
  }
  // resupply product export Resupply-Product
  resupplyExportTableData() {
    let datas: ItemExportDatas[] = [];
    this.dataSource?.data?.forEach((element) => {
      datas.push({
        productName: element?.name,
        minimumQty: String(element?.minimumQuantity),
        availableQty: String(element?.availableQuantity),
        hcpcCode: element?.hcpcCode,
        stockStatus: element?.stockStatus,
        productStatus: element?.status,
      });
    });
    let itemExport: ItemExportDTO = {
      columns: [
        'Resupply Product Name',
        'Minimum Qunatity',
        'Available Qunatity',
        'HCPC Code',
        'Stock Stauts',
        'Product Status',
      ],
      datas: datas,
    };
    const ResupplyExport = this.reportService
      .exportItemReportsByExportTypeAndExportDatas(
        ExportType.ResupplyProductReports,
        itemExport
      )
      .subscribe(
        (response) => {
          if (response && response?.length !== 0) {
            let filelocation: String = String(
              'data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,' +
                response
            );
            let link = document.createElement('a');
            link.download = 'Resupply-Product' + '.xlsx';
            link.href = filelocation.toString();
            link.click();
            // Swal.fire({ title: 'Success', html: 'Exported Successfully', icon: 'success', timer: 3000, timerProgressBar: true })
            this.toastr.success('Exported Successfully', 'Success');
          }
        },
        (err) => {
          const data: HttpErrorResponse = err;
          let errorMesg: string = data?.error;
          if (
            errorMesg?.includes('Sorry!! There is no data in table to export')
          ) {
            errorMesg = 'Sorry!! There is no data in table to export';
          } else {
            errorMesg = data?.error?.error?.message;
          }
          // Swal.fire({
          //   icon: 'info',
          //   text: errorMesg
          // });
          this.toastr.info(errorMesg);
        }
      );
    this.subscription$.push(ResupplyExport);
  }

  clearMainProduct() {
    this.MainProduct = '';
    this.resupplyProductForm.patchValue({ txtMainProduct: '' });
  }
}
