import { FullAuditedEntityDto, RestService } from '@abp/ng.core';
import type { PagedAndSortedResultRequestDto, PagedResultDto } from '@abp/ng.core';
import { Injectable } from '@angular/core';
import { CreateUpdateReminderDetailDTO, ReminderDetailDTO } from './dto';

@Injectable({
  providedIn: 'root',
})
export class ReminderDetailService {
  apiName = 'orderManagement';

  create = (input: CreateUpdateReminderDetailDTO) =>
    this.restService.request<any, ReminderDetailDTO>({
      method: 'POST',
      url: '/api/orderApp/reminder-detail',
      body: input,
    },
    { apiName: this.apiName });

  delete = (id: string) =>
    this.restService.request<any, void>({
      method: 'DELETE',
      url: `/api/orderApp/reminder-detail/${id}`,
    },
    { apiName: this.apiName });

  get = (id: string) =>
    this.restService.request<any, ReminderDetailDTO>({
      method: 'GET',
      url: `/api/orderApp/reminder-detail/${id}`,
    },
    { apiName: this.apiName });

  getList = (input: PagedAndSortedResultRequestDto) =>
    this.restService.request<any, PagedResultDto<ReminderDetailDTO>>({
      method: 'GET',
      url: '/api/orderApp/reminder-detail',
      params: { sorting: input.sorting, skipCount: input.skipCount, maxResultCount: input.maxResultCount },
    },
    { apiName: this.apiName });

  getRemindersByOrderIdByID = (ID: string) =>
    this.restService.request<any, ReminderDetailDTO[]>({
      method: 'GET',
      url: '/api/orderApp/reminder-detail/reminders-by-order-id',
      params: { id: ID },
    },
    { apiName: this.apiName });

  update = (id: string, input: CreateUpdateReminderDetailDTO) =>
    this.restService.request<any, ReminderDetailDTO>({
      method: 'PUT',
      url: `/api/orderApp/reminder-detail/${id}`,
      body: input,
    },
    { apiName: this.apiName });

  constructor(private restService: RestService) {}
}
