import { ListService } from '@abp/ng.core';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import {
  AbstractControl,
  AsyncValidatorFn,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatSelectChange } from '@angular/material/select';
import { Title } from '@angular/platform-browser';
import * as moment from 'moment';
import { TableService } from 'projects/shared/src/app/table.service';
import { TextValidators } from 'projects/shared/src/app/validations/text-validator';
import { Observable } from 'rxjs';
import { debounceTime, delay, map, tap } from 'rxjs/operators';
import Swal from 'sweetalert2';
import emailMask from 'text-mask-addons/dist/emailMask';

import { InviteStatusService } from '../admin-proxy/platform-app-management/rcm/platform-management/dropdowns/invite-status.service';
import { OrganizationUnitDTO } from '../admin-proxy/platform-app-management/rcm/platform-management/organization-units/dto/models';
import { OrganizationUnitService } from '../admin-proxy/platform-app-management/rcm/platform-management/organization-units/organization-unit.service';
import { GetRoleListUsingTenantIdDto } from '../admin-proxy/platform-app-management/rcm/platform-management/role/models';
import { RoleService } from '../admin-proxy/platform-app-management/rcm/platform-management/role/role.service';
import { TenantsService } from '../admin-proxy/platform-app-management/rcm/platform-management/tenant/tenants.service';
import { UserService } from '../admin-proxy/platform-app-management/rcm/platform-management/user';
import {
  CreateUpdateInviteUserDTO,
  InviteStatusDTO,
  InviteUserIdDTO,
} from '../admin-proxy/platform-app-management/rcm/platform-management/user-invite/dto/models';
import { UserInviteService } from '../admin-proxy/platform-app-management/rcm/platform-management/user-invite/user-invite.service';
import { UsernameEmailValidationDto } from '../admin-proxy/platform-app-management/rcm/platform-management/users/dto';
import { IdentityRoleDto } from '../admin-proxy/platform-app-management/volo/abp/identity/models';
import { ToastrService } from 'ngx-toastr';
import { RoleDto } from '../admin-proxy/platform-app-management/identity';

@Component({
  selector: 'app-invite-user',
  templateUrl: './invite-user.component.html',
  styleUrls: ['./invite-user.component.scss'],
  providers: [ListService],
})
export class InviteUserComponent implements OnInit {
  //! Declarations
  isLoading: boolean = true;
  @Input() clientId: string = '';
  isPanelOpenState: boolean = false;
  // drpRoles: IdentityRoleDto[] = [];
  drpRoles: RoleDto[] = [];
  drpOrganization: OrganizationUnitDTO[] = [];
  drpInvite: InviteStatusDTO[] = [];
  public strPageType: string = 'AdminInviteUsers';
  inviteUsersForm: FormGroup;
  inviteStatusForm: FormGroup;
  inviteUsersTableData: any[] = [];
  inviteUsersData: any;
  isinviteUsers: boolean = false;
  emailMask: any;
  inviteUserId: string = '';
  createdBy: string = '';
  expiryMinimumDate: Date;
  branch: string;
  role: string;
  expireByDateTime: Date;
  expireByDateTimeV1: string;
  isShownSaveButton: boolean = true;
  isShownInviteUsersTable: boolean = true;
  isShownResetButton: boolean = true;
  inviteUserUpdateRequest: CreateUpdateInviteUserDTO;
  inviteStatusRequest: InviteStatusDTO;
  saveButtonHide: boolean;
  isShowSpinner: boolean = false;
  constructor(
    private formBuilder: FormBuilder,
    private textValidators: TextValidators,
    public list: ListService,
    private Table: TableService,
    public title: Title,
    private userService: UserService,

    private inviteUserService: UserInviteService,
    private roleService: RoleService,
    private branchService: OrganizationUnitService,
    private inviteStatusService: InviteStatusService,
    private tenantService: TenantsService,
    private toastr: ToastrService
  ) {}

  ngOnInit(): void {
    this.clientId != null &&
      this.clientId != '' &&
      this.clientId != undefined &&
      this.getClientStatus();

    this.title.setTitle('Qsecure | Invite Users');

    //! Invite Users FormControls and Validations
    this.inviteUsersForm = this.formBuilder.group({
      txtName: new FormControl(
        '',
        Validators.compose([
          Validators.required,
          this.textValidators.isTextCheck,
        ]),
        [this.checkUserUniqness()]
      ),
      txtSurName: new FormControl('', [this.textValidators.isTextCheck]),
      txtEmailAddress: new FormControl(
        '',
        Validators.compose([Validators.required, Validators.email]),
        [this.checkEmailUniqness()]
      ),
      txtExpireDatetime: new FormControl('', [Validators.required]),
      drpRoles: new FormControl(null, [Validators.required]),
      drpOrganization: new FormControl(null, [Validators.required]),
    });

    //! Invite Status FormControls and Validations
    this.inviteStatusForm = this.formBuilder.group({
      drpInviteStatus: new FormControl(null),
    });
    this.emailMask = emailMask;

    //! To check UserCreate Permission
    this.Table.getUsersCreate().subscribe((value) => {
      value == true
        ? (this.isShownSaveButton = true)
        : (this.isShownSaveButton = false);
    });

    //! To check UserView Permission
    let data = this.Table.getUsersView().subscribe((value) => {
      if (value == true) {
        this.isShownInviteUsersTable = true;
        this.loadInviteUserTable();
      } else {
        this.isShownInviteUsersTable = false;
      }
    });

    //! Validation For Expiry DateTime
    this.expiryMinimumDate = new Date();
    this.expiryMinimumDate.setDate(this.expiryMinimumDate.getDate());

    this.loadRolesDropdown();
    this.loadOrganizationDropdown();
    this.loadInviteStatusDropdown();

    //! Get Login UserName
    this.Table.getLoginUserName().subscribe((val) => {
      this.createdBy = val;
    });
  }

  //! Get Client Status to Disable Button & Table
  getClientStatus() {
    const isSupAdmin: number =
      Number(+localStorage.getItem('isAdmin') ?? 0) ?? 0;

    isSupAdmin &&
      this.tenantService
        .getTenantInformationById(this.clientId)
        .subscribe((response) => {
          if (response?.status == 'InActive') {
            this.isShownSaveButton = false;
            this.isShownInviteUsersTable = false;
            this.isShownResetButton = false;
          }
        });
  }

  checkUserUniqness(): AsyncValidatorFn {
    return (
      control: AbstractControl
    ): Observable<{ [key: string]: any } | null> => {
      const input: UsernameEmailValidationDto = {
        username:
          String(control?.value ?? '')
            ?.toLowerCase()
            ?.trim() ?? '',
        emailAddress: '',
        isUserNameExists: false,
        isEmailAddressExists: false,
      };
      return this.userService.checkUserNameIfExistsByInput(input).pipe(
        tap((x) => control?.setErrors({ isUserNameExists: true })),
        debounceTime(300),
        map((response) =>
          response?.isUserNameExists === true
            ? { isUserNameExists: true }
            : control?.setErrors(null) == null
            ? null
            : null
        )
      );
    };
  }
  checkEmailUniqness(): AsyncValidatorFn {
    return (
      control: AbstractControl
    ): Observable<{ [key: string]: any } | null> => {
      const input: UsernameEmailValidationDto = {
        username: '',
        emailAddress:
          String(control?.value ?? '')
            ?.toLowerCase()
            ?.trim() ?? '',
        isUserNameExists: false,
        isEmailAddressExists: false,
      };
      return this.userService.checkEmailIfExistsByInput(input).pipe(
        tap((x) => control?.setErrors({ isEmailAddressExists: true })),
        debounceTime(300),
        map((response) =>
          response?.isEmailAddressExists === true
            ? { isEmailAddressExists: true }
            : control?.setErrors(null) == null
            ? null
            : null
        )
      );
    };
  }
  //! Save & Update Invite Users
  saveInviteUsers() {
    this.isShowSpinner = true;
    this.saveButtonHide = true;
    let inviteUserSaveRequest: CreateUpdateInviteUserDTO;
    if (
      this.inviteUserId === '' ||
      this.inviteUserId === undefined ||
      this.inviteUserId === null
    ) {
      inviteUserSaveRequest = {
        name: this.inviteUsersForm.value.txtName,
        surname: this.inviteUsersForm.value.txtSurName,
        emailAddress: this.inviteUsersForm.value.txtEmailAddress.toLowerCase(),
        roleId: this.inviteUsersForm.value.drpRoles,
        //   createdBy: this.createdBy,
        createdDateTime: null,
        inviteStatus: null,
        expireByDateTime: this.inviteUsersForm.value.txtExpireDatetime,
        inviteAcceptedDatetime: null,
        url: null ?? '',
        isInviteResend: false,
        inviteResendCount: 0,
        organizationUnitId: this.inviteUsersForm.value.drpOrganization,
        tenantId: this.clientId,
      };
    } else {
      this.inviteUserUpdateRequest.surname =
        this.inviteUsersForm.value.txtSurName;
      (this.inviteUserUpdateRequest.roleId =
        this.inviteUsersForm.value.drpRoles),
        (this.inviteUserUpdateRequest.organizationUnitId =
          this.inviteUsersForm.value.drpOrganization);
      this.inviteUserUpdateRequest.expireByDateTime =
        this.inviteUsersForm.value.txtExpireDatetime;
    }
    if (
      this.inviteUserId === '' ||
      this.inviteUserId === undefined ||
      this.inviteUserId === null
    ) {
      try {
        this.inviteUserService
          .createUserInviteByInput(inviteUserSaveRequest)
          .subscribe(
            (response) => {
              if (response) {
                // Swal.fire('Success', 'Saved and Mail Sent Successfully', 'success');
                this.toastr.success(
                  'Saved and Mail Sent Successfully',
                  'Success'
                );
                this.inviteUserId = response.id;
                this.loadInviteUserTable();
                this.resetInviteUsers();
              }
              this.saveButtonHide = false;
              this.isShowSpinner = false;
            },
            (err) => {
              this.isShowSpinner = false;
              this.saveButtonHide = false;
              const data: HttpErrorResponse = err;

              Swal.fire({
                icon: 'info',
                // text: data?.error?.error?.message
                text: 'Please verify the provided email address for accuracy or contact the administrator.',
                // html: data?.error?.error?.message
              });
            }
          );
      } catch (error) {}
    } else {
      this.inviteUserService
        .update(this.inviteUserId, this.inviteUserUpdateRequest)
        .subscribe(
          (response) => {
            // Swal.fire({ title: 'Success', html: 'Updated Successfully', icon: 'success', timer: 3000, timerProgressBar: true });
            this.toastr.success('Updated Successfully', 'Success');
            this.saveButtonHide = false;
            this.isShowSpinner = false;
            this.inviteUserId = response?.id ?? '';
            this.loadInviteUserTable();
            this.resetInviteUsers();
          },
          (err) => {
            this.isShowSpinner = false;
            this.saveButtonHide = false;
            const data: HttpErrorResponse = err;
            Swal.fire({
              icon: 'info',
              text: data?.error?.error?.message,
            });
          }
        );
    }
    this.inviteUserId = '';
  }

  //! Load Invite Users Table
  loadInviteUserTable() {
    this.loadRolesDropdown();
    this.loadOrganizationDropdown();
    this.inviteStatusRequest = {
      tenantId: this.clientId,
      inviteStatus: null,
    };
    this.getInviteUserList(this.inviteStatusRequest);
  }

  //! get InviteUser list Datas
  getInviteUserList(inviteStatusRequest: InviteStatusDTO) {
    this.isLoading = true;
    this.inviteUserService
      .getInvitedUserDetailsBasedonStatusByInviteStatus(inviteStatusRequest)
      .subscribe(
        (response) => {
          this.inviteUsersData = response;

          // if (this.inviteUsersData.length != 0) {
          //   this.isinviteUsers = true;
          //   this.isPanelOpenState = false;
          // } else {
          //   this.isinviteUsers = true;
          //   this.isPanelOpenState = true;
          // }
          // this.inviteUsersTableData = [];
          // response?.forEach((element) => {
          //   let utcToLocalDateTime = moment
          //     .utc(element?.expireByDateTime)
          //     .local()
          //     .format('MMM DD YYYY,LT');
          //   this.inviteUsersTableData.push({
          //     name: element?.name,
          //     inviteUserId: element?.inviteUserId,
          //     surname: element?.surname,
          //     emailAddress: element?.emailAddress,
          //     url: element?.url,
          //     //   createdBy: element?.createdBy,
          //     createdDateTime: element?.createdDateTime,
          //     expireByLocalDateTime: utcToLocalDateTime,
          //     expireByUtcDateTime: element?.expireByDateTime,
          //     inviteAcceptedDateTime: element?.inviteAcceptedDatetime,
          //     inviteResendCount: element?.inviteResendCount,
          //     isInviteResend: element?.isInviteResend,
          //     roleId: element?.roleId,
          //     organizationUnitId: element?.organizationUnitId,
          //     inviteStatus: element?.inviteStatus,
          //     id: element?.id,
          //     roleName: element?.roleName ?? '', //this.drpRoles?.filter(value => { return value && value?.id === element?.roleId })[0]?.name,
          //     branchName: this.drpOrganization?.filter((value) => {
          //       return value && value?.id === element?.organizationUnitId;
          //     })[0]?.organizationUnitName,
          //     creationTime:
          //       element?.creationTime == null
          //         ? undefined
          //         : new Date(element?.creationTime).toLocaleDateString('en-US'),
          //   });
          // });
          this.isLoading = false;
          // this.Table.setInviteUsersTable(this.inviteUsersTableData);
        },
        (err) => {
          this.isLoading = false;
          const data: HttpErrorResponse = err;
          // Swal.fire({
          //   icon: 'error',
          //   text: data?.error?.error?.message,
          // });
        }
      );
  }

  //! View Invite Users
  ViewInviteUsers(datas: any) {
    this.Table.getUsersUpdate().subscribe((value) => {
      value == true
        ? (this.isShownSaveButton = true)
        : (this.isShownSaveButton = false);
    });

    let inviteUser: InviteUserIdDTO = {
      tenantId: this.clientId,
      inviteUserId: datas.inviteUserId,
    };

    this.inviteUserService.getInviteUserByIdByInviteUser(inviteUser).subscribe(
      (response) => {
        this.isPanelOpenState = true;
        this.inviteUsersForm.controls['txtName'].disable();
        this.inviteUsersForm.controls['txtEmailAddress'].disable();
        if( response?.expireByDateTime){
          this.expireByDateTime=new Date(response?.expireByDateTime)
          this.expireByDateTime.setDate(this.expireByDateTime.getDate() + 1)
          this.expireByDateTimeV1 =this.expireByDateTime.toISOString().split('T')[0]
          this.inviteUsersForm
          .get('txtExpireDatetime')
          .setValue(this.expireByDateTimeV1)
        }
        this.inviteUsersForm.patchValue({
          txtName: response?.name,
          txtSurName: response?.surname,
          txtEmailAddress: response?.emailAddress,
          // txtExpireDatetime: response?.expireByDateTime,
          drpRoles: response?.roleId,
          drpOrganization: response?.organizationUnitId,
        });
        this.inviteUserId = response?.id ?? '';
        this.inviteUserUpdateRequest = null;
        this.inviteUserUpdateRequest = {
          name: response?.name,
          surname: response?.surname,
          emailAddress: response?.emailAddress,
          expireByDateTime: response?.expireByDateTime,
          roleId: response?.roleId,
          organizationUnitId: response?.organizationUnitId,
          //   createdBy: response?.createdBy,
          createdDateTime: response?.createdDateTime,
          url: response?.createdDateTime,
          tenantId: response?.tenantId,
          inviteStatus: response?.inviteStatus,
          inviteAcceptedDatetime: response?.inviteAcceptedDatetime,
          isInviteResend: datas?.isInviteResend,
          inviteResendCount: response?.inviteResendCount,
        };
      },
      (err) => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      }
    );
  }

  //! Delete Invite User
  deleteInviteUsers(inviteUserId: string) {
    Swal.fire({
      title: 'Are you sure you want to delete?',
      text: "You won't be able to retrieve this data!",
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#34c38f',
      cancelButtonColor: '#f46a6a',
      confirmButtonText: 'Yes, delete it!',
    }).then((result) => {
      if (result.value) {
        let inviteUser: InviteUserIdDTO = {
          tenantId: this.clientId,
          inviteUserId: inviteUserId,
        };
        this.inviteUserService.deleteInviteUser(inviteUser).subscribe(
          (response) => {
            this.inviteUsersTableData.forEach((element) => {
              if (inviteUserId == element.id) {
                let index = this.inviteUsersTableData.indexOf(element, 0);
                this.inviteUsersTableData.splice(index, 1);
              }
            });
            this.Table.setInviteUsersTable(this.inviteUsersTableData);
          },
          (err) => {
            const data: HttpErrorResponse = err;
            Swal.fire({
              icon: 'info',
              text: data?.error?.error?.data?.Message,
            });
          }
        );
      }
    });
  }

  //! Resend Invite User
  resendInviteUsers(datas?: any) {
    Swal.fire({
      title: 'Would you like to update any details before resend mail?',
      icon: 'question',
      showDenyButton: true,
      confirmButtonColor: '#50a5f1',
      denyButtonColor: '#f46a6a',
      confirmButtonText: 'Yes',
      showCancelButton: true,
      cancelButtonColor: '#6c757d',
    }).then((result) => {
      if (result.value == true) {
        //If yes
        datas.isInviteResend = true;
        this.ViewInviteUsers(datas);
      } else if (result.value == false) {
        //If no
        //To check rolename & branchname is null or not
        if (
          (datas?.roleName == '' ||
            datas?.roleName == null ||
            datas?.roleName == undefined) &&
          (datas?.branchName == '' ||
            datas?.branchName == null ||
            datas?.branchName == undefined)
        ) {
          Swal.fire({
            icon: 'info',
            text: 'Please select role and branch for this user',
          });
        }
        //To check rolename is null or not
        else if (
          datas?.roleName == '' ||
          datas?.roleName == null ||
          datas?.roleName == undefined
        ) {
          Swal.fire({
            icon: 'info',
            text: 'Please select role for this user',
          });
        }
        //To check branchname is null or not
        else if (
          datas?.branchName == '' ||
          datas?.branchName == null ||
          datas?.branchName == undefined
        ) {
          Swal.fire({
            icon: 'info',
            text: 'Please select branch for this user',
          });
        } else {
          this.inviteUserUpdateRequest = {
            name: datas?.name,
            surname: datas?.surname,
            emailAddress: datas?.emailAddress,
            expireByDateTime: datas?.expireByUtcDateTime,
            roleId: datas?.roleId,
            organizationUnitId: datas?.organizationUnitId,
            //      createdBy: datas?.createdBy,
            createdDateTime: datas?.createdDateTime,
            url: datas?.url,
            inviteStatus: datas?.inviteStatus,
            inviteAcceptedDatetime: datas?.inviteAcceptedDatetime,
            isInviteResend: true,
            inviteResendCount: datas?.inviteResendCount,
            tenantId: this.clientId,
          };

          this.inviteUserService
            .update(datas?.id, this.inviteUserUpdateRequest)
            .subscribe(
              (response) => {
                if (response) {
                  // Swal.fire('Success', 'Mail Resent Successfully', 'success');
                  this.toastr.success('Mail Resent Successfully', 'Success');
                  this.loadInviteUserTable();
                  this.resetInviteUsers();
                }
              },
              (err) => {
                const data: HttpErrorResponse = err;
                Swal.fire({
                  icon: 'info',
                  text: data?.error?.error?.data?.Message,
                });
              }
            );
        }
      }
    });
  }

  //! Load Roles Dropdown Function
  loadRolesDropdown() {
    let roleList: GetRoleListUsingTenantIdDto = {
      tenantId: this.clientId,
    };
    this.roleService.getRoleList(roleList).subscribe(
      (response) => {
        this.drpRoles = [];
        this.drpRoles = response;
      },
      (err) => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      }
    );
  }

  //! Load Organization Dropdown Function
  loadOrganizationDropdown() {
    this.branchService.getOrganizationList(this.clientId).subscribe(
      (response) => {
        this.drpOrganization = [];
        this.drpOrganization = response;
      },
      (err) => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      }
    );
  }

  //! Load Invite Status Dropdown Function
  loadInviteStatusDropdown() {
    const inviteStatusList = (query: any) =>
      this.inviteStatusService.getList(query);

    this.list.hookToQuery(inviteStatusList).subscribe(
      (response) => {
        this.drpInvite = response?.items ?? [];
        const sortedList = this.drpInvite.sort((a, b) =>
          a.inviteStatus.localeCompare(b.inviteStatus)
        );
        this.inviteStatusForm.patchValue({
          drpInviteStatus: this.drpInvite[0]?.inviteStatus ?? '',
        });
      },
      (err) => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      }
    );
  }

  //! Clear Branch Dropdown Values
  clearBranch() {
    this.branch = '';
    this.inviteUsersForm.patchValue({ drpOrganization: '' });
  }

  //! Clear Roles Dropdown Values
  clearRole() {
    this.role = '';
    this.inviteUsersForm.patchValue({ drpRoles: '' });
  }

  //! Load Invite Users Table Based on invite status
  loadInviteUserTableBasedOnStatus(event: MatSelectChange) {
    this.loadRolesDropdown();
    this.loadOrganizationDropdown();

    this.inviteStatusRequest = {
      tenantId: this.clientId,
      inviteStatus: this.inviteStatusForm.value.drpInviteStatus,
    };
    this.getInviteUserList(this.inviteStatusRequest);
  }

  //! Reset Invite User Form
  resetInviteUsers() {
    this.inviteUsersForm.controls['txtName'].enable();
    this.inviteUsersForm.controls['txtEmailAddress'].enable();
    this.Table.getUsersCreate().subscribe((value) => {
      value == true
        ? (this.isShownSaveButton = true)
        : (this.isShownSaveButton = false);
    });
    this.inviteUserId = '';
    this.inviteUsersForm.reset();
    this.inviteUsersForm.markAsUntouched();
    this.inviteUsersForm.updateValueAndValidity();
  }
}
