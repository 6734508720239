import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ProductExpiryDaysDTO } from 'projects/patient/src/app/dropdown-proxy/dropdown-management/dropdowns/dto/order';
import { OrderMasterDropdownService } from 'projects/patient/src/app/dropdown-proxy/dropdown-management/dropdowns/master-dropdown-service';
import { NumberValidators } from 'projects/shared/src/app/validations/number-validator';
import { Subscription } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import Swal from 'sweetalert2';

import { CreateUpdateMainProductDetailsDTO, CreateUpdateProductDTO,  } from '../item-proxy/item-management/items/dto/models';
import { ProductBrandService } from '../item-proxy/item-management/items/product-brand.service';
import { ProductCategoryService } from '../item-proxy/item-management/items/product-category.service';
import { ProductType } from '../item-proxy/item-management/items/product-type.enum';
import { ProductService } from '../item-proxy/item-management/items/product.service';
import { Status } from '../item-proxy/item-management/items/status.enum';
import { VendorService } from '../item-proxy/item-management/items/vendor.service';
import { ToastrService } from "ngx-toastr";
import { ProductDetailsService, ResupplyProductDetailsService } from '../item-proxy/item-management/optimization';
import { CreateUpdateResupplyProductDetailsDTO } from '../item-proxy/item-management/optimization/dto';

@Component({
  selector: 'app-add-product-modal',
  templateUrl: './add-product-modal.component.html',
  styleUrls: ['./add-product-modal.component.scss']
})
export class AddProductModalComponent implements OnInit , OnDestroy {
  productForm: FormGroup;
  productSupplyForm: FormGroup;
  radioButton: boolean = true;
  main: boolean = true;
  reSupply: boolean = false;
  mainProductStockStatus: string = '';
  resupplyProductStockStatus: string = '';
  subscription$: Subscription[] = [];
  constructor(private formBuilder: FormBuilder,
    private productService: ProductService,
    private brandService: ProductBrandService,
    private VendorsService: VendorService,
    private numberValidators: NumberValidators,
    private catService: ProductCategoryService,
    private orderDropdownService: OrderMasterDropdownService,
    private toastr: ToastrService,
    private productDetailsService: ProductDetailsService,
    private resupplyProductDetails: ResupplyProductDetailsService,
    public dialogRef: MatDialogRef<AddProductModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { productId: string, supplyId: string }) { }

  ngOnInit(): void {
    this.productForm = this.formBuilder.group({
      txtProductName: new FormControl("", [Validators.required]),
      txtDescription: new FormControl("", [Validators.required]),
      txtHcpc: new FormControl("", [Validators.required]),
      txtProductCode: new FormControl("", [this.numberValidators.isNumberCheck]),
      txtMinQty: new FormControl("", [Validators.required, this.numberValidators.isNumberCheck]),

    });

    this.productSupplyForm = this.formBuilder.group({
      txtProductName: new FormControl("", [Validators.required]),
      txtDescription: new FormControl("", [Validators.required]),
      txtHcpc: new FormControl("", [Validators.required]),
      txtProductCode: new FormControl("", [this.numberValidators.isNumberCheck]),
      txtMainProduct: new FormControl("", [Validators.required]),
      txtMainProductFilter: new FormControl(""),
      txtMinQty: new FormControl("", [Validators.required, this.numberValidators.isNumberCheck]),
    })
    this.getDropdown();
  }

  ngOnDestroy(): void {
    this.subscription$?.forEach((sub) => {
      sub && sub?.unsubscribe();
    });
  }

  error() {

  }
  // get dropdown values
  drpExpiryDays: ProductExpiryDaysDTO[] = [];
  drpMainProduct: any[] = [];
  filterbrand;
  filterVendor;
  filterCategory;
  filterMainProduct;
  filterSupplyCategory;
  drpBrand: any[] = [];
  drpVendor: any[] = [];
  drpCategory: any[] = [];
  ///dropdown get
  getDropdown() {
    // let orderDropdownInput: OrderDropdowns[];
    // orderDropdownInput = [
    //   OrderDropdowns.ProductExpiryDays
    // ]
    // this.orderDropdownService.getOrderDropdownsByInput(orderDropdownInput).subscribe((stateResponse) => {
    //   let response: OrderMasterDropdownDTO = stateResponse;
    //   this.drpExpiryDays = response?.productExpiryDays;

    // });

    if (this.data.supplyId !== undefined) {
      const getMainProduct = this.productService.getProductsByInput(ProductType.ResupplyProduct).subscribe(response => {
        this.drpMainProduct = response
        this.filterMainProduct = this.productSupplyForm?.get("txtMainProductFilter").valueChanges
          .pipe(
            startWith(''),
            map(value => this.drpMainProduct.filter(option => option?.productName?.toLowerCase()?.includes(value?.toLowerCase() ?? "")))
          );

      })
      this.subscription$.push(getMainProduct);
    }else if(this.data.productId !== undefined && this.data.supplyId === undefined) {
    const getMainProduct = this.productService.getProductsByInput(ProductType.MainProduct).subscribe(response => {
      this.drpMainProduct = response
      this.filterMainProduct = this.productSupplyForm?.get("txtMainProductFilter").valueChanges
        .pipe(
          startWith(''),
          map(value => this.drpMainProduct.filter(option => option?.productName?.toLowerCase()?.includes(value?.toLowerCase() ?? "")))
        );

    })
    this.subscription$.push(getMainProduct);
  }

    if (this.data.productId !== undefined && this.data.supplyId === undefined) {
      this.getByMainProductById()
    }
    if (this.data.supplyId !== undefined) {
      this.getBySupplyProductById()
    }
    else {
      this.radioButton = true
    }
  }

  //! main product getby id
  getByMainProductById() {
    const getProduct = this.productDetailsService.get(this.data.productId).subscribe(response => {
      this.radioButton = false
      this.productForm.patchValue({
        txtProductName: response?.mainProductName,
        // txtCategoryName: response?.mainProductDetails?.categoryName,
        // txtBrandName: response?.mainProductDetails?.brandName,
        // txtPrice: response?.mainProductDetails?.price,
        txtDescription: response?.productDescription,
        // txtQty: response?.mainProductDetails?.quantity,
        // txtMinQty: response?.mainProductDetails?.minimumQuantity,
        txtHcpc: response?.hcpcCode,
        txtProductCode: response?.productCode,
        txtMinQty: response?.minimumQuantity,
        // txtVendorName: response?.mainProductDetails?.vendorId,
        // txtMainProduct:response?.mainProductDetails?.mainProductName,
      });
      // this.mainProductStockStatus = response?.mainProductDetails?.stockStatus;
    })
    this.subscription$.push(getProduct);
  }

  //! supply product getby id

  getBySupplyProductById() {

  const ResupplyProduct =  this.resupplyProductDetails.get(this.data.supplyId).subscribe(response => {
      this.radioButton = false
      this.main = false;
      this.reSupply = true;
      this.productSupplyForm.patchValue({
        txtProductName: response?.resupplyProductName,
        // txtCategoryName: response?.categoryName,
        // txtBrandName: response?.brandName,
        // txtPrice: response?.price,
        txtDescription: response?.productDescription,
        // txtQty: response?.quantity,
        // txtMinQty: response?.minimumQuantity,
        txtHcpc: response?.hcpcCode,
        txtProductCode: response?.productCode,
        // txtVendorName: response?.vendorId,
        // txtMainProduct: response?.mainProductId,
        txtMinQty: response?.minimumQuantity,
        // txtExpDays:  response?.expiryDays,
      });
      this.resupplyProductStockStatus = response?.stockStatus;
    })
    this.subscription$.push(ResupplyProduct)
  }

  //! get radio button onchenge values
  onChangeGetInsuranceVerify(event) {
    if (event.value === 'MainProduct') {
      this.main = true;
      this.reSupply = false;
    }
    if (event.value === 'ResupplyProduct') {
      this.main = false;
      this.reSupply = true;
    }
  }



  //! save main products
  saveMainProduct() {
    // if (this.data.productId === "00000000-0000-0000-0000-000000000000" || this.data.productId === "" || this.data.productId === undefined) {
    //   let CreateUpdateProductDTO: CreateUpdateProductDTO = {
    //     productType: ProductType.MainProduct,
    //     mainProductDetails: {
    //       status: Status.Active,
    //       hcpcCode: this.productForm.value.txtHcpc,
    //       mainProductName: this.productForm.value.txtProductName,
    //       productCode: "0",
    //       productDescription: this.productForm.value.txtDescription,
    //       availableQuantity: 0,
    //       minimumQuantity: this.productForm.value.txtMinQty,
    //       stockStatus: this.mainProductStockStatus
    //     },
    //     resupplyProductDetails: []
    //   }

    //   this.productService.create(CreateUpdateProductDTO).subscribe(response => {

    //     // Swal.fire({ title: 'Success', html: 'Saved Successfully', icon: 'success', timer: 3000, timerProgressBar: true });
    //     this.toastr.success('Saved Successfully','Success')
    //     this.dialogRef.close();
    //   }, err => {
    //     const data: HttpErrorResponse = err;
    //     Swal.fire({
    //       icon: 'info',
    //       text: data?.error?.error?.message,
    //     });
    //   });
    // } else {

    //   let UpdateProductDTO: CreateUpdateMainProductDetailsDTO = {
    //     status: Status.Active,
    //     hcpcCode: this.productForm.value.txtHcpc,
    //     mainProductName: this.productForm.value.txtProductName,
    //     productCode: "0",
    //     productDescription: this.productForm.value.txtDescription,
    //     availableQuantity: 0,
    //     minimumQuantity: this.productForm.value.txtMinQty
    //   }
    //   this.productDetailsService.update(this.data.productId, UpdateProductDTO).subscribe(response => {

    //     // Swal.fire({ title: 'Success', html: 'Saved Successfully', icon: 'success', timer: 3000, timerProgressBar: true });
    //     this.toastr.success('Saved Successfully','Success')
    //     this.dialogRef.close('main');
    //   }, err => {
    //     const data: HttpErrorResponse = err;
    //     Swal.fire({
    //       icon: 'info',
    //       text: data?.error?.error?.message,
    //     });
    //   });
    // }

  }


  //! save resupply products
  saveSupplyProduct() {
    if (this.data.supplyId === "00000000-0000-0000-0000-000000000000" || this.data.supplyId === "" || this.data.supplyId === undefined) {

      let CreateUpdateProductDTO: CreateUpdateResupplyProductDetailsDTO = {


        // status: Status.Active,
        mainproductId: this.productSupplyForm.value.txtMainProduct,
        // resupplyProductId: "00000000-0000-0000-0000-000000000000",
        hcpcCode: this.productSupplyForm.value.txtHcpc,
        resupplyProductName: this.productSupplyForm.value.txtProductName,
        productCode: 0,
        productDescription: this.productSupplyForm.value.txtDescription,
        // availableQuantity: 0,
        minimumQuantity: this.productSupplyForm.value.txtMinQty,
        // stockStatus: this.resupplyProductStockStatus
        // categoryName: this.productSupplyForm.value.txtCategoryName,
        // brandName: this.productSupplyForm.value.txtBrandName,
        // expiryDays: this.productSupplyForm.value.txtExpDays,
        // price: this.productSupplyForm.value.txtPrice,
        // minimumQuantity: this.productSupplyForm.value.txtMinQty,
        // quantity: this.productSupplyForm.value.txtQty,
        // vendorId: this.productSupplyForm.value.txtVendorName,
        // vendorName: this.productSupplyForm.value.txtVendorName,
        // createdDate: "",
      }
      this.resupplyProductDetails.update(this.data.supplyId,CreateUpdateProductDTO).subscribe(response => {

        // Swal.fire({ title: 'Success', html: 'Saved Successfully', icon: 'success', timer: 3000, timerProgressBar: true });
        this.toastr.success('Saved Successfully','Success')
        this.dialogRef.close('supp');
      }, err => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      });
    } else {
      let CreateUpdateProductDTO: CreateUpdateResupplyProductDetailsDTO = {
        // status: Status.Active,
        mainproductId: this.productSupplyForm.value.txtMainProduct,
        // resupplyProductId: this.data.supplyId,
        hcpcCode: this.productSupplyForm.value.txtHcpc,
        resupplyProductName: this.productSupplyForm.value.txtProductName,
        productCode: 0,
        productDescription: this.productSupplyForm.value.txtDescription,
        // availableQuantity: 0,
        minimumQuantity: this.productSupplyForm.value.txtMinQty,
        // stockStatus: this.resupplyProductStockStatus
        // categoryName: this.productSupplyForm.value.txtCategoryName,
        // brandName: this.productSupplyForm.value.txtBrandName,
        // expiryDays: this.productSupplyForm.value.txtExpDays,
        // price: this.productSupplyForm.value.txtPrice,
        // minimumQuantity: this.productSupplyForm.value.txtMinQty,
        // quantity: this.productSupplyForm.value.txtQty,
        // vendorId: this.productSupplyForm.value.txtVendorName,
        // vendorName: this.productSupplyForm.value.txtVendorName,
        // createdDate: "",
      }
      this.resupplyProductDetails.update(this.data.supplyId,CreateUpdateProductDTO).subscribe(response => {

        // Swal.fire({ title: 'Success', html: 'Saved Successfully', icon: 'success', timer: 3000, timerProgressBar: true });
        this.toastr.success('Saved Successfully','Success')
        this.dialogRef.close();
      }, err => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      });
    }
  }

  SupplyCategory: string = '';
  clearSupplyCategory() {
    this.Category = '';
    this.productSupplyForm.patchValue({ txtCategoryName: "" });
  }

  Category: string = '';
  clearCategory() {
    this.Category = '';
    this.productForm.patchValue({ txtCategoryName: "" });
  }

  brand: string = '';
  clearbrand() {
    this.brand = '';
    this.productForm.patchValue({ txtBrandName: "" });
  }

  Vendor: string = '';
  clearVendor() {
    this.Vendor = '';
    this.productForm.patchValue({ txtVendorName: "" });
  }



  SupplyBrand: string = '';
  clearSupplyBrand() {
    this.SupplyBrand = '';
    this.productSupplyForm.patchValue({ txtBrandName: "" });
  }

  supplyVendor: string = '';
  clearsupplyVendor() {
    this.supplyVendor = '';
    this.productSupplyForm.patchValue({ txtVendorName: "" });
  }

  MainProduct: string = '';
  clearMainProduct() {
    this.MainProduct = '';
    this.productForm.patchValue({ txtMainProduct: "" });
  }
}
