import { DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { EOBERAReportService } from '../../billing-proxy/billing-management/rcm/billing-management/optimization/billing-reports/eoberareport.service';
import {
  clsEOBERAReportDTO,
  clsEOBERAReportSearch,
  clsEOBStatusDTO,
  clsPayerDetailDTO,
  clsPayerSearch,
} from '../../billing-proxy/billing-management/rcm/billing-management/optimization/dto';
import { Observable, Subscription } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { defaultGuid } from 'projects/shared/src/app/enums/allenums.enum';
import { CommonService } from 'projects/shared/src/app/services/common.service';
import { ItemPaymentService } from '../../claim-proxy/claim-processing-management/rcm/claim-processing-management/x12/item-payment.service';
import { LoaderService } from 'projects/shared/src/app/services/loader-service';
import { ToastrService } from 'ngx-toastr';
import { UploadEOBComponent } from '../../claim-status-list/claim-status-list-table/upload-eob/upload-eob.component';
import { HttpErrorResponse } from '@angular/common/http';
import { MatDialog } from '@angular/material/dialog';
import { MatSelectChange } from '@angular/material/select';

@Component({
  selector: 'app-billing-eob-era',
  templateUrl: './billing-eob-era.component.html',
  styleUrls: ['./billing-eob-era.component.scss'],
})
export class BillingEOBERAComponent implements OnInit {
  eobSearchForm: FormGroup;
  isLoading: boolean;
  isDataLoaded: boolean = false;
  eobTable: any = {
    responsive: true,
    paging: false,
    dom: 'Bfrtip',
    scrollCollapse: true,
    scrollY: '500px',
    scrollX: true,
    search: {
      smart: false,
    },
    language: {
      emptyTable: 'No records',
      info: 'Total : _MAX_ records',
      infoEmpty: 'Total : _MAX_ records',
      infoFiltered: '(filtered : _TOTAL_ records)',
      loadingRecords: 'Loading...',
      zeroRecords: 'No matching records',
    },
    order: [],
    columnDefs: [
      { targets: 0, orderable: false },
      { targets: 8, orderable: false },
    ],
    buttons: [
      {
        extend: 'excel',
        text: 'Export', // Change the text to 'Export'
        filename: 'EOB_ERA List',
        exportOptions: {
          columns: ':visible:not(:first-child) ',
        },
      },
    ],
  };
  ltPayors: clsPayerDetailDTO[] = [];
  filteredPayors: Observable<clsPayerDetailDTO[]>;
  subscription$: Subscription[] = [];
  eobData: clsEOBERAReportDTO[] = [];
  ltStatus: clsEOBStatusDTO[] = [];
  filteredStatuses: Observable<clsEOBStatusDTO[]>;
  selectedPayerIdList:string[]=[];
  payerIdSelectAllOption:boolean=false;
  isPayerSelectionChange:boolean=false;
  constructor(
    private fb: FormBuilder,
    private datepipe: DatePipe,
    private eobService: EOBERAReportService,
    private commonService: CommonService,
    private itemPaymentService: ItemPaymentService,
    private loaderService: LoaderService,
    private toasterService: ToastrService,
    private dialog: MatDialog
  ) {
    this.loaderService.getLoaderState().subscribe((isDataLoaded) => {
      this.isDataLoaded = isDataLoaded;
    });
  }

  ngOnInit(): void {
    this.eobTable.buttons[0].filename =
      'EOB_ERA List' + '-' + this.datepipe.transform(new Date(), 'MM-dd-yyyy');
    this.initializeForms();
    this.loadDropdowns();
    this.buildFilterParams();
  }
  initializeForms() {
    this.eobSearchForm = this.fb.group({
      txtRemittanceStartDate: new FormControl(''),
      txtRemittanceEndDate: new FormControl(''),
      txtCreatedStartDate: new FormControl(''),
      txtCreatedEndDate: new FormControl(''),
      drpPayer: new FormControl(''),
      txtPayerFilter: new FormControl(''),
      txtAmount: new FormControl(''),
      txtPaymentMethod: new FormControl(''),
      txtCheckNumber: new FormControl(''),
      drpStatus: new FormControl(''),
      txtStatusFilter: new FormControl(''),
    });
  }

  loadDropdowns() {
    this.loadPayors('');
    this.loadStatus();
  }

  searchPayor(value: any) {
    this.loadPayors(value);
  }

  loadPayors(value: string) {
    let payorsList =
      this.eobSearchForm.value.drpPayer === '' ||
      this.eobSearchForm.value.drpPayer === null ||
      this.eobSearchForm.value.drpPayer === undefined
        ? []
        : this.eobSearchForm.value.drpPayer;

    let params: clsPayerSearch = {
      iMode: 4,
      sSearch: value,
      ltExisting: payorsList,
    };

    const payorSearch = this.eobService
      .drpSearchPayerBySearch(params)
      .subscribe(
        (response) => {
          this.ltPayors = response.items ?? [];
          this.filteredPayors = this.eobSearchForm
            .get('txtPayerFilter')
            .valueChanges.pipe(
              startWith(''),
              map((value) =>
                this.ltPayors?.filter((option) =>
                  option?.payerName
                    ?.toLowerCase()
                    ?.includes(value?.toLowerCase() ?? '')
                )
              )
            );
        },
        (err) => {}
      );

    this.subscription$.push(payorSearch);
  }
  loadStatus() {
    const status = this.eobService.drpEOBStatus().subscribe(
      (response) => {
        this.ltStatus = response.items ?? [];
        this.filteredStatuses = this.eobSearchForm
          .get('txtStatusFilter')
          .valueChanges.pipe(
            startWith(''),
            map((value) =>
              this.ltStatus?.filter((option) =>
                option?.statusName
                  ?.toLowerCase()
                  ?.includes(value?.toLowerCase() ?? '')
              )
            )
          );
      },
      (error) => {
        console.error('Error:', error);
      }
    );
    this.subscription$.push(status);
  }
  addSlashToDate(event: Event): void {
    const input = event.target as HTMLInputElement;
    const inputValue = input.value;
    input.value = inputValue
      .replace(/^(\d{2})(\d{1,2})/, '$1/$2')
      .replace(/^(\d{2}\/\d{2})(\d+)/, '$1/$2')
      .replace(/[^\d\/]/g, '');
  }

  onCreatedDateChange(event: MatDatepickerInputEvent<Date>): void {
    if (
      this.eobSearchForm.value.txtCreatedStartDate &&
      this.eobSearchForm.value.txtCreatedEndDate
    ) {
      this.buildFilterParams();
    }
  }
  onRemittanceDateChange(event: MatDatepickerInputEvent<Date>): void {
    if (
      this.eobSearchForm.value.txtRemittanceStartDate &&
      this.eobSearchForm.value.txtRemittanceEndDate
    ) {
      this.buildFilterParams();
    }
  }
  buildFilterParams() {
    let payer =
      this.eobSearchForm.value.drpPayer === null ||
      this.eobSearchForm.value.drpPayer.length === 0
        ? []
        : this.eobSearchForm.value.drpPayer;
    let status =
      this.eobSearchForm.value.drpStatus === null ||
      this.eobSearchForm.value.drpStatus?.length === 0
        ? []
        : this.eobSearchForm.value.drpStatus;

    let checkNumber = this.eobSearchForm?.value?.txtCheckNumber?.trim() ?? '';
    let amount =
      this.eobSearchForm?.value?.txtAmount === '' ||
      this.eobSearchForm?.value?.txtAmount === null
        ? null
        : this.eobSearchForm?.value?.txtAmount;
    let createdFromDate =
      this.eobSearchForm?.value?.txtCreatedStartDate == null ||
      this.eobSearchForm?.value?.txtCreatedStartDate == ''
        ? null
        : this.datepipe.transform(
            this.eobSearchForm?.value?.txtCreatedStartDate,
            'MM/dd/yyyy'
          );
    let createdToDate =
      this.eobSearchForm?.value?.txtCreatedEndDate == null ||
      this.eobSearchForm?.value?.txtCreatedEndDate == ''
        ? null
        : this.datepipe.transform(
            this.eobSearchForm?.value?.txtCreatedEndDate,
            'MM/dd/yyyy'
          );

    let remittanceFromDate =
      this.eobSearchForm?.value?.txtRemittanceStartDate == null ||
      this.eobSearchForm?.value?.txtRemittanceStartDate == ''
        ? null
        : this.datepipe.transform(
            this.eobSearchForm?.value?.txtRemittanceStartDate,
            'MM/dd/yyyy'
          );

    let remittanceToDate =
      this.eobSearchForm?.value?.txtRemittanceEndDate == null ||
      this.eobSearchForm?.value?.txtRemittanceEndDate == ''
        ? null
        : this.datepipe.transform(
            this.eobSearchForm?.value?.txtRemittanceEndDate,
            'MM/dd/yyyy'
          );
    let params: clsEOBERAReportSearch = {
      ltPayers: payer,
      ltStatus: status,
      sCheckNumber: checkNumber,
      dAmount: amount,
    };
    // dtRemitanceFrom: remittanceFromDate,
    // dtRemitanceTo: remittanceToDate,
    // dtCreatedFrom: createdFromDate,
    // dtCreatedTo: createdToDate,
    this.getEOBERAReport(
      params,
      remittanceFromDate,
      remittanceToDate,
      createdFromDate,
      createdToDate
    );
  }
  downloadEOB(blobName: string, checkNumber: string) {
    this.isDataLoaded = true;
    const eobDownload = this.itemPaymentService
      .getEOBDocumentByBlobIDByBlobName(blobName)
      .subscribe(
        (response) => {
          let pdfBase64String = 'data:application/pdf;base64,' + response;

          const downloadLink = document.createElement('a');
          const fileName = checkNumber + '.pdf';

          downloadLink.href = pdfBase64String;
          downloadLink.download = fileName;
          downloadLink.click();
          this.isDataLoaded = false;
        },
        (err) => {
          console.log();
          this.isDataLoaded = false;
        }
      );
    this.subscription$.push(eobDownload);
  }
  getEOBERAReport(
    params: clsEOBERAReportSearch,
    remittanceFrom,
    remittanceTo,
    createdFrom,
    createdTo
  ) {
    this.isLoading = true;
    const report = this.eobService
      .getEOBERAReportsBySearchAndDtRemitanceFromAndDtRemitanceToAndDtCreatedFromAndDtCreatedTo(
        params,
        remittanceFrom,
        remittanceTo,
        createdFrom,
        createdTo
      )
      .subscribe(
        (response) => {
          this.eobData = response?.items ?? [];
          this.eobData = this.eobData.map((data) => {
            return {
              ...data,
              dtCreated: this.commonService.getFormattedDateTimeZone(
                data.dtCreated
              ),
              dtRemitance: this.commonService.getFormattedDateZone(
                data.dtRemitance
              ),
              dtModified: this.commonService.getFormattedDateTimeZone(
                data.dtModified
              ),
            };
          });
          this.isLoading = false;
        },
        (err) => {
          this.isLoading = false;
        }
      );
  }
  openEOBModel() {
    let isSaveEnable: boolean = true;
    let cliamData: any;
    const dialogRef = this.dialog.open(UploadEOBComponent, {
      width: '100vw',
      height: '100vh',
      maxWidth: '100vw',
      maxHeight: '100vh',
      panelClass: 'fullscreen-dialog-panel',
      disableClose: true,
      data: {
        cliamData: cliamData,
        isSaveEnable: isSaveEnable,
      },
    });
    const closeDialog = dialogRef.afterClosed().subscribe(
      (isSaved: any) => {
        if (isSaved) {
          this.buildFilterParams();
        }
      },
      (err) => {
        const data: HttpErrorResponse = err;
        console.warn(data?.error?.error?.message);
      }
    );
  }
  completeEOB(eobId: string) {
    this.eobService.markEOBCompleteByGEOBIdAndBComplete(eobId, true).subscribe(
      (response) => {
        this.buildFilterParams();
        this.toasterService.success('EOB Completed Successfully');
      },
      (err) => {
        this.toasterService.success('Something Went Wrong');
      }
    );
  }
  clearRemittanceDate() {
    this.eobSearchForm.patchValue({
      txtRemittanceStartDate: null,
      txtRemittanceEndDate: null,
    });
  }
  clearCreatedDate() {
    this.eobSearchForm.patchValue({
      txtCreatedStartDate: null,
      txtCreatedEndDate: null,
    });
  }
  clearPayer() {
    this.eobSearchForm.patchValue({
      drpPayer: null,
    });
    this.payerIdSelectAllOption = false;
  }
  clearPaymentMethod() {
    this.eobSearchForm.patchValue({
      drpPaymentMethod: null,
    });
  }
  clearStatus() {
    this.eobSearchForm.patchValue({
      drpStatus: null,
    });
    this.statusIdSelectAllOption=false;
  }
  resetForm() {
    this.eobSearchForm.reset();
    this.buildFilterParams();
  }
  isPayerDropdownOpen = false;
  onPayerDropdownOpenChange(isOpen: boolean) {
    this.isPayerDropdownOpen = isOpen;
    if (this.isPayerDropdownOpen) {
      this.loadPayors('');
    }
  }
  handlePayerCloseDrpdown(value: any): void {
    if (value && this.isPayerSelectionChange) {
      this.isPayerSelectionChange = false;
      this.buildFilterParams();
    }
  }
  onSelectionPayerIdChange(event: MatSelectChange): void {
    this.selectedPayerIdList = event.value;
    this.isPayerSelectionChange=true;
    if (this.selectedPayerIdList.includes('selectAll')) {
      this.payerIdSelectAllOption = true;
      this.selectedPayerIdList = this.ltPayors.map((data) => data.payerId);
      this.eobSearchForm.get('drpPayer').patchValue(this.selectedPayerIdList);
    } else if (this.selectedPayerIdList.includes('DeselectAll')) {
      this.selectedPayerIdList = [];
      this.payerIdSelectAllOption = false;
      this.eobSearchForm.get('drpPayer').patchValue([]);
    } else {
      this.payerIdSelectAllOption = this.selectedPayerIdList.length === this.ltPayors.length;
    }
  }
  selectedStatusList:string[]=[];
  isStatusSelectionChange:boolean = false;
  statusIdSelectAllOption:boolean = false;
  handleStatusCloseDrpdown(value: any): void {
    if (value && this.isStatusSelectionChange) {
      this.isStatusSelectionChange = false;
      this.buildFilterParams();
    }
  }
  onSelectionStatusIdChange(event: MatSelectChange): void {
    this.selectedStatusList = event.value;
    this.isStatusSelectionChange=true;
    if (this.selectedStatusList.includes('selectAll')) {
      this.statusIdSelectAllOption = true;
      this.selectedStatusList = this.ltStatus.map((data) => data.statusId);
      this.eobSearchForm.get('drpStatus').patchValue(this.selectedStatusList);
    } else if (this.selectedStatusList.includes('DeselectAll')) {
      this.selectedStatusList = [];
      this.statusIdSelectAllOption = false;
      this.eobSearchForm.get('drpStatus').patchValue([]);
    } else {
      this.statusIdSelectAllOption = this.selectedStatusList.length === this.ltStatus.length;
    }
  }
}
