import { RestService } from '@abp/ng.core';
import type {
  PagedAndSortedResultRequestDto,
  PagedResultDto,
} from '@abp/ng.core';
import { Injectable } from '@angular/core';
import {
  CreateUpdateItemPaymentsDTO,
  CreateUpdateItemPaymentsAddEOBDTO,
  ItemPaymentsDTO,
  ItemPaymentsAddEOBDTO,
  searchItemPaymentsInput,
  AutoPostingDTO,
  CreateUpdateItemPaymentsForCCDTO,
} from '../../billing-management/posting/dto/models';
import { APIResponseDTO } from 'projects/admin/src/app/admin-proxy/platform-app-management/rcm/platform-management/inbound/dto/models';
import { ClaimTabResultDTO, InputClaimFormDTO, MyQueueBatchNumberMethodDTO, MyQueueClaimNumberDTO, MyQueuePostingMethodDTO, ProductDetailsHCPC, requestOfClaimwoance1, writeOffCreateDTO, writeOffDTOnotes } from './dto/models';
import { WonaceClaimTab } from '../../billing-management/billing/wonace-claim-tab.enum';
import { requestOfClaimwoance } from '../../billing-management/dto/models';

@Injectable({
  providedIn: 'root',
})
export class ItemPaymentService {
  apiName = 'ClaimProcessingManagement';

  create = (input: CreateUpdateItemPaymentsDTO) =>
    this.restService.request<any, ItemPaymentsDTO>(
      {
        method: 'POST',
        url: '/api/claimProcessingApp/item-payment',
        body: input,
      },
      { apiName: this.apiName }
    );

  createForCCV0 = (input: CreateUpdateItemPaymentsForCCDTO) =>
    this.restService.request<any, ItemPaymentsDTO>(
      {
        method: 'POST',
        url: '/api/claimProcessingApp/item-payment',
        body: input,
      },
      { apiName: this.apiName }
    );

  // uploadClaimEOBByEobFileAndGClaimId = (eobFile: File, gClaimId: string) =>{
  //   const formData = new FormData();
  //   // Append all fields to formData
  //   for (const key in eobFile) {
  //     if (eobFile.hasOwnProperty(key)) {
  //       formData.append(key, eobFile[key]);
  //     }
  //   }
  //   return
  //   this.restService.request<any, APIResponseDTO>({
  //     method: 'POST',
  //     url: `/api/claimProcessingApp/item-payment/upload-claim-eOB/${gClaimId}`,
  //   },
  //   { apiName: this.apiName });
  // }

  uploadClaimEOBByEobFileAndGClaimId = (eobFile: File, gClaimId: string) => {
    const formData = new FormData();
    formData.append('eobFile', eobFile);
    return this.restService.request<any, ItemPaymentsDTO>(
      {
        method: 'POST',
        url: `/api/claimProcessingApp/item-payment/upload-claim-eOB/${gClaimId}`,
        body: formData,
      },
      { apiName: this.apiName }
    );
  };
  uploadClaimEOBByEobFileAndGClaimIdV1 = (eobFile: File, eobTextFile: File) => {
    const formData = new FormData();
    formData.append('eobFile', eobFile||null);
    // const txtFormData = new FormData();
    formData.append('eobTextFile', eobTextFile||null);
    return this.restService.request<any, ItemPaymentsDTO>(
      {
        method: 'POST',
        url: `/api/claimProcessingApp/item-payment/upload-claim-eOBV1`,
        // body: {eobFile:formData,eobTextFile:txtFormData},
        body: formData,
      },
      { apiName: this.apiName }
    );
  };


  createForCC = (input: CreateUpdateItemPaymentsForCCDTO) => {
    const formData = new FormData();
    // Append all fields to formData
    for (const key in input) {
      if (input.hasOwnProperty(key)) {
        formData.append(key, input[key]);
      }
    }
    return this.restService.request<any, ItemPaymentsDTO>(
      {
        method: 'POST',
        url: '/api/claimProcessingApp/item-payment',
        body: formData,
      },
      { apiName: this.apiName }
    );
  };

  createForCCV1 = (input: CreateUpdateItemPaymentsForCCDTO) =>
    this.restService.request<any, ItemPaymentsDTO>(
      {
        method: 'POST',
        url: '/api/claimProcessingApp/item-payment',
        body: input,
      },
      { apiName: this.apiName }
    );

  updateForCCV1 = (id: string, input: CreateUpdateItemPaymentsForCCDTO) =>
    this.restService.request<any, ItemPaymentsDTO>(
      {
        method: 'PUT',
        url: `/api/claimProcessingApp/item-payment/${id}`,
        body: input,
      },
      { apiName: this.apiName }
    );

  createV1 = (input: CreateUpdateItemPaymentsForCCDTO) =>
    this.restService.request<any, ItemPaymentsDTO>(
      {
        method: 'POST',
        url: '/api/claimProcessingApp/item-payment/async-v1',
        body: input,
      },
      { apiName: this.apiName }
    );
  createV2 = (input: CreateUpdateItemPaymentsForCCDTO) =>
    this.restService.request<any, ItemPaymentsDTO>(
      {
        method: 'POST',
        url: '/api/claimProcessingApp/item-payment/async-v2',
        body: input,
      },
      { apiName: this.apiName }
    );
  updateV1 = (id: string, input: CreateUpdateItemPaymentsForCCDTO) =>
    this.restService.request<any, ItemPaymentsDTO>(
      {
        method: 'PUT',
        url: `/api/claimProcessingApp/item-payment/${id}/async-v2`,
        body: input,
      },
      { apiName: this.apiName }
    );
  updateV2 = (id: string, input: CreateUpdateItemPaymentsForCCDTO) =>
    this.restService.request<any, ItemPaymentsDTO>(
      {
        method: 'PUT',
        url: `/api/claimProcessingApp/item-payment/${id}/async-v2`,
        body: input,
      },
      { apiName: this.apiName }
    );
  // updateForCC = (id: string, input: CreateUpdateItemPaymentsForCCDTO) =>{
  //   const formData = new FormData();
  //   // Append all fields to formData
  //   for (const key in input) {
  //     if (input.hasOwnProperty(key)) {
  //       formData.append(key, input[key]);
  //     }
  //   }
  //   return this.restService.request<any, ItemPaymentsDTO>({
  //     method: 'PUT',
  //     url: `/api/claimProcessingApp/item-payment/${id}`,
  //     body: formData,
  //   },
  //   { apiName: this.apiName });
  // };

  // createAddEOBDetails = (input: CreateUpdateItemPaymentsAddEOBDTO) =>
  //   this.restService.request<any, ItemPaymentsAddEOBDTO>({
  //     method: 'POST',
  //     url: '/api/claimProcessingApp/item-payment',
  //     body: input,
  //   },
  //   { apiName: this.apiName });

  createAddEOBDetails = (input: CreateUpdateItemPaymentsDTO) =>
    this.restService.request<any, ItemPaymentsAddEOBDTO>(
      {
        method: 'POST',
        url: '/api/claimProcessingApp/item-payment',
        body: input,
      },
      { apiName: this.apiName }
    );

  delete = (id: string) =>
    this.restService.request<any, void>(
      {
        method: 'DELETE',
        url: `/api/claimProcessingApp/item-payment/${id}`,
      },
      { apiName: this.apiName }
    );

  get = (id: string) =>
    this.restService.request<any, ItemPaymentsDTO>(
      {
        method: 'GET',
        url: `/api/claimProcessingApp/item-payment/${id}`,
      },
      { apiName: this.apiName }
    );

  getList = (input: PagedAndSortedResultRequestDto) =>
    this.restService.request<any, PagedResultDto<ItemPaymentsDTO>>(
      {
        method: 'GET',
        url: '/api/claimProcessingApp/item-payment',
      },
      { apiName: this.apiName }
    );

  update = (id: string, input: CreateUpdateItemPaymentsDTO) =>
    this.restService.request<any, ItemPaymentsDTO>(
      {
        method: 'PUT',
        url: `/api/claimProcessingApp/item-payment/${id}`,
        body: input,
      },
      { apiName: this.apiName }
    );
  getItemPaymentsBasedOnClaimIdByClaimId = (claimId: string) =>
    this.restService.request<any, ItemPaymentsDTO[]>(
      {
        method: 'GET',
        url: `/api/claimProcessingApp/item-payment/get-item-payments-based-on-claim-id/${claimId}`,
      },
      { apiName: this.apiName }
    );

  getAutoPostedEobDetailsByDtFromDateAndDtToDateAndGClaimStatusAndSCheckNumber =
    (
      dtFromDate: string,
      dtToDate: string,
      gClaimStatus: string,
      sCheckNumber: string
    ) =>
      this.restService.request<any, PagedResultDto<AutoPostingDTO>>(
        {
          method: 'GET',
          url: '/api/claimProcessingApp/item-payment/get-auto-posted-eob-details',
          params: { dtFromDate, dtToDate, gClaimStatus, sCheckNumber },
        },
        { apiName: this.apiName }
      );

  getEOBDocumentByBlobIDByBlobName = (blobName: string) =>
    this.restService.request<any, string>(
      {
        method: 'GET',
        url: '/api/claimProcessingApp/item-payment/e-oBDocument-by-blob-iD',
        params: { blobName },
      },
      { apiName: this.apiName }
    );

  getItemPaymentsBasedOnInput = (input: searchItemPaymentsInput) =>
    this.restService.request<any, PagedResultDto<ItemPaymentsDTO[]>>(
      {
        method: 'POST',
        body: input,
        url: `/api/claimProcessingApp/item-payment/search-check-lists-based-on-input`,
      },
      { apiName: this.apiName }
    );

    getWriteOffItemDetailsByIClaimId = (iClaimId: string) =>
      this.restService.request<any, ProductDetailsHCPC[]>({
        method: 'GET',
        url: `/api/claimProcessingApp/item-payment/get-write-off-item-details/${iClaimId}`,
      },
      { apiName: this.apiName });

      saveWriteOffByInput = (input: writeOffCreateDTO) =>
        this.restService.request<any, string>({
          method: 'POST',
          responseType: 'text',
          url: '/api/claimProcessingApp/item-payment/save-write-off',
          body: input,
        },
        { apiName: this.apiName });

        getClaimWriteOffDetailsByClaimIdAndPatientId = (claimId: string, patientId: string) =>
          this.restService.request<any, writeOffDTOnotes[]>({
            method: 'GET',
            url: '/api/claimProcessingApp/item-payment/claim-write-off-details',
            params: { claimId, patientId },
          },
          { apiName: this.apiName });

          // getClaimTabReportByShortCode = (shortCode: WonaceClaimTab) =>
          //   this.restService.request<any, ClaimTabResultDTO>({
          //     method: 'GET',
          //     url: '/api/claimProcessingApp/item-payment/get-claim-tab-report',
          //     params: { shortCode },
          //   },
          //   { apiName: this.apiName });

          getClaimTabReportByDto = (dto: requestOfClaimwoance1) =>
            this.restService.request<any, ClaimTabResultDTO>({
              method: 'POST',
              url: '/api/claimProcessingApp/item-payment/get-claim-tab-report',
              body: dto,
            },
            { apiName: this.apiName });
            getClaimNumberForMyQueueByInput = (input: InputClaimFormDTO) =>
              this.restService.request<any, MyQueueClaimNumberDTO[]>({
                method: 'POST',
                url: '/api/claimProcessingApp/item-payment/get-claim-number-for-my-queue',
                body: input,
              },
              { apiName: this.apiName });

          getPostingMethodForMyQueueByInput = (input: InputClaimFormDTO) =>
            this.restService.request<any, MyQueuePostingMethodDTO[]>({
              method: 'POST',
              url: '/api/claimProcessingApp/item-payment/get-posting-method-for-my-queue',
              body: input,
            },
            { apiName: this.apiName });

          getBatchNumberForMyQueueByInput = (input: InputClaimFormDTO) =>
            this.restService.request<any, MyQueueBatchNumberMethodDTO[]>({
              method: 'POST',
              url: '/api/claimProcessingApp/item-payment/get-batch-number-for-my-queue',
              body: input,
            },
            { apiName: this.apiName });

  constructor(private restService: RestService) {}
}
