import { HttpErrorResponse } from '@angular/common/http';
import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatAccordion } from '@angular/material/expansion';
import { TableService } from 'projects/shared/src/app/table.service';
import { delay } from 'rxjs/operators';
import Swal from 'sweetalert2';
import {
  IdentityRoleDto,
  IdentityRoleUpdateDto,
} from '../admin-proxy/platform-app-management/identity/models';
import { Role } from '../admin-proxy/platform-app-management/rcm/platform-management';

import {
  GetRoleListUsingTenantIdDto,
  RoleService_GetRoleListUsingTenantIdDto,
} from '../admin-proxy/platform-app-management/rcm/platform-management/role/models';
import { RoleService } from '../admin-proxy/platform-app-management/rcm/platform-management/role/role.service';
import { Title } from '@angular/platform-browser';
import { Subscription } from 'rxjs';
import { CommunicationService } from 'projects/shared/src/app/services/communication.service';
import { DatePipe } from '@angular/common';
import { CommonService } from 'projects/shared/src/app/services/common.service';

@Component({
  selector: 'app-role',
  templateUrl: './role.component.html',
  styleUrls: ['./role.component.scss'],
})
export class RoleComponent implements OnInit {
  dtRoleTableOptions = {
    responsive: true,
    paging: false,
    dom: 'Bfrtip',
    language: {
      emptyTable: 'No records',
      info: 'Total : _MAX_ records',
      infoEmpty: 'Total : _MAX_ records',
      infoFiltered: '(filtered : _TOTAL_ records)',
      loadingRecords: 'Loading...',
      zeroRecords: 'No matching records',
    },
    scrollCollapse: true,
    scrollY: '400px',
    scrollX: true,
    fixedHeader: true,
    columnDefs: [
      { targets: [0, 1], orderable: false },
      {
        targets: [2],
        type: 'date',
        render: function (data, type, row) {
          // Assuming data is in the format MM/DD/YYYY
          if (type === 'sort' || type === 'type') {
            var dateParts = data.split('/');
            return new Date(dateParts[2], dateParts[0] - 1, dateParts[1]);
          } else {
            return data;
          }
        },
      },
    ],
    search: {
      smart: false, // Set to "none" for full exact match
    },
    buttons: [
      {
        extend: 'excel',
        text: 'Export',
        filename: 'Role',
        exportOptions: {
          columns: ':visible:not(:first-child,:eq(0)) ',
        },
      },
      {
        extend: 'colvis',
        columns: ':not(:eq(0), :eq(1) ,:eq(2),:eq(-1))',
      }
    ],
  };

  // emptyguid: string = defaultGuid;
  tableData$: Subscription;
  // dtOptions: any;
  isLoading: boolean;
  selectedOption: number;
  // panelOpenState = false;
  // roleForm: FormGroup;
  public strPageType: string = 'Roles';
  @Input() clientId: string = '';
  roleTableData: any[] = [];
  // roleId: string = defaultGuid;
  // roleResponse: IdentityRoleDto = null;
  isShown: boolean = false;
  isShownSaveButton: boolean = true;
  isShownRolesTable: boolean = true;
  step: boolean = false;
  @ViewChild(MatAccordion) accordion: MatAccordion;
  @Output() nextTabMoveOnSaveEmitter: EventEmitter<string> =
    new EventEmitter<string>();
  // saveButtonHide: boolean;
  isRolesCreatePermission: boolean = false;
  isRolesViewPermission: boolean = false;
  isRolesUpdatePermission: boolean = false;
  isShowSpinner: boolean = false;
  isShownclientPermission: boolean = true;
  isRolesDeletePermission: boolean = true;
  isShownDeleteIcon: boolean = true;
  //Accordion Toggle Methods
  toggle() {
    this.step = true;
  }
  toggleV2() {
    this.step = false;
  }
  constructor(
    // private formBuilder: FormBuilder,
    private Table: TableService,
    public dialog: MatDialog,
    // private officialService: TenantsService,
    private roleService: RoleService,
    public title: Title,
    // private toastr: ToastrService,
    // private snackBar: MatSnackBar,
    private communicationService: CommunicationService,
    private datepipe: DatePipe,
    private commonService: CommonService
  ) {
    this.communicationService.functionLoadRoleListv1$.subscribe(() => {
      this.getRoleTable();
    });
  }

  ngOnInit(): void {
    this.title.setTitle('Qsecure | Roles');

    this.dtRoleTableOptions.buttons[0].filename =
    'Role' + '-' + this.datepipe.transform(new Date(), 'MM-dd-yyyy');

    // this.Table.getRolesManagePermission().subscribe(
    //   (value) => {
    //     if (value == true) {
    //       this.isShownclientPermission = true;
    //     } else {
    //       this.isShownclientPermission = false;
    //     }
    //   },
    //   (err) => {
    //     const data: HttpErrorResponse = err;
    //     Swal.fire({
    //       icon: 'info',
    //       text: data?.error?.error?.message,
    //     });
    //   }
    // );

    // this.Table.getRolesDelete().subscribe(
    //   (value) => {
    //     this.isRolesDeletePermission = value;
    //     if (this.isRolesDeletePermission == true) {
    //       this.isShownDeleteIcon = true;
    //     } else {
    //       this.isShownDeleteIcon = false;
    //     }
    //   },
    //   (err) => {
    //     const data: HttpErrorResponse = err;
    //     Swal.fire({
    //       icon: 'info',
    //       text: data?.error?.error?.message,
    //     });
    //   }
    // );

    this.selectedOption = 1;
    // this.Table.getRolesCreate().subscribe(
    //   (value) => {
    //     this.isRolesCreatePermission = value;

    //     if (this.isRolesCreatePermission == true) {
    //       this.isShownSaveButton = true;
    //     } else {
    //       this.isShownSaveButton = false;
    //     }
    //   },
    //   (err) => {
    //     const data: HttpErrorResponse = err;
    //     // Swal.fire({
    //     //   icon: 'error',
    //     //   text: data?.error?.error?.message,
    //     // });
    //   }
    // );
    this.Table.getRolesView().subscribe(
      (value) => {
        this.isRolesViewPermission = value;

        if (this.isRolesViewPermission == true) {
          this.isShownRolesTable = true;
          this.getRoleTable();
        } else {
          this.isShownRolesTable = false;
        }
      },
      (err) => {
        const data: HttpErrorResponse = err;
        // Swal.fire({
        //   icon: 'error',
        //   text: data?.error?.error?.message,
        // });
      }
    );
    // this.roleForm = this.formBuilder.group({
    //   txtRoleName: new FormControl('', Validators.required),
    // });
    // this.clientId != null &&
    //   this.clientId != '' &&
    //   this.clientId != undefined &&
    //   this.getInActive();

    // this.getRoleTable();
  }

  ngOnDestroy(): void {
    this.tableData$?.unsubscribe();
    // this.dtOptions = {};
  }

  onRadioChange() {

    this.getRoleTable();
  }

  //!buttonhide
  // getInActive() {
  //   const isSupAdmin: number =
  //     Number(+localStorage.getItem('isAdmin') ?? 0) ?? 0;
  //   isSupAdmin &&
  //     this.officialService
  //       .getTenantInformationById(this.clientId)
  //       .subscribe((response) => {
  //         if (response.status == 'InActive') {
  //           this.isShownSaveButton = false;
  //           this.isShownRolesTable = false;
  //         }
  //       });
  // }

  // //! view role id for edit
  // ViewRole(event: { id: any }) {
  //   window.scrollTo({ top: 0, behavior: 'smooth' }); // Scroll to the top of the page
  //   this.step = true;
  //   this.accordion.openAll();
  //   this.getRoleById(event?.id ?? defaultGuid);
  // }
  // //! edit role
  // getRoleById(id: string = defaultGuid) {
  //   this.roleService.getRoleById(this.clientId, id).subscribe((response) => {
  //     this.roleId = response?.id ?? defaultGuid;
  //     this.roleResponse = response ?? null;
  //     this.roleForm.patchValue({
  //       txtRoleName: response?.name ?? '',
  //     });
  //   });
  // }

  //! role grt list
  getRoleTable() {
    this.isLoading = true;
    let roleList: GetRoleListUsingTenantIdDto = {
      tenantId: this.clientId,
    };
    this.roleTableData = [];
    let list: RoleService_GetRoleListUsingTenantIdDto = {
      tenantId: this.clientId,
      status: this.selectedOption
    };


    this.tableData$?.unsubscribe();
    const getRolesTableData = this.roleService
      .getRoleListV1(list)
      .subscribe(
        (response) => {
          this.roleTableData = response;
          this.roleTableData = this.roleTableData.map((data) => {
            return {
              ...data,
              modifyTime: this.commonService.getFormattedDateTimeZone(
                data.modifyTime
              ),
              creationTime: this.commonService.getFormattedDateTimeZone(
                data.creationTime
              ),
            };
          });
          this.isLoading = false;
        },
        (err) => {
          this.isLoading = false;
          this.roleTableData = [];
          const data: HttpErrorResponse = err;
          Swal.fire({
            icon: 'info',
            text: data?.error?.error?.message,
          });
        }
      );
    this.tableData$ = getRolesTableData;
  }

  //! save role
  // saveRole() {
  //   this.isShowSpinner = true;
  //   this.saveButtonHide = true;
  //   // this.step = false;
  //   this.roleId = this.roleId ?? defaultGuid;
  //   if (this.roleId === defaultGuid) {
  //     let roles: IdentityRoleCreateUsingTenantIdDto = {
  //       name: this.roleForm.value.txtRoleName,
  //       isDefault: this.roleResponse?.isDefault ?? false,
  //       isPublic: this.roleResponse?.isPublic ?? true,
  //       extraProperties: this.roleResponse?.extraProperties ?? {},
  //       tenantId: this.clientId ?? defaultGuid,
  //     };
  //     this.roleService.createRole(roles).subscribe(
  //       (response) => {
  //         this.toastr.success('Saved Successfully', 'Success');
  //         this.saveButtonHide = false;
  //         this.isShowSpinner = false;
  //         this.roleId = defaultGuid;
  //         this.reset();
  //         this.getRoleTable();
  //       },
  //       (err) => {
  //         this.saveButtonHide = false;
  //         this.roleId = defaultGuid;
  //         this.isShowSpinner = false;
  //         const data: HttpErrorResponse = err;
  //         Swal.fire({
  //           icon: 'info',
  //           text: data?.error?.error?.message,
  //         });
  //       }
  //     );
  //   } else {
  //     let roles: IdentityRoleUpdateDto = {
  //       concurrencyStamp: this.roleResponse?.concurrencyStamp ?? '',
  //       name: this.roleForm.value.txtRoleName,
  //       isDefault: this.roleResponse?.isDefault,
  //       isPublic: this.roleResponse?.isPublic,
  //       extraProperties: this.roleResponse?.extraProperties ?? {},
  //     };
  //     this.roleService.updateRole(this.clientId, this.roleId, roles).subscribe(
  //       (response) => {
  //         this.toastr.success('Updated Successfully', 'Success');
  //         this.roleId = defaultGuid;
  //         this.saveButtonHide = false;
  //         this.isShowSpinner = false;
  //         this.step = false;
  //         this.reset();
  //         this.getRoleTable();
  //       },
  //       (err) => {
  //         this.step = false;
  //         // this.roleId = defaultGuid;
  //         this.saveButtonHide = false;
  //         this.isShowSpinner = false;
  //         const data: HttpErrorResponse = err;
  //         Swal.fire({
  //           icon: 'info',
  //           text: data?.error?.error?.message,
  //         });
  //       }
  //     );
  //   }
  // }

  // check() {

  // }
  // deleteRole(event) {
  //   Swal.fire({
  //     title: 'Are you sure you want to Delete?',
  //     text: "You won't be able to retrieve this data!",
  //     icon: 'warning',
  //     showCancelButton: true,
  //     confirmButtonColor: '#34c38f',
  //     cancelButtonColor: '#f46a6a',
  //     confirmButtonText: 'Yes, delete it!',
  //   }).then((result) => {
  //     if (result.value) {
  //       //
  //       this.roleService.deleteRole(this.clientId, event.id).subscribe(
  //         (response) => {
  //           this.roleTableData.forEach((element) => {
  //             if (event.id == element?.id) {
  //               let index = this.roleTableData.indexOf(element, 0);
  //               this.roleTableData.splice(index, 1);
  //             }
  //           });
  //           this.Table.setRoleListTable(this.roleTableData);
  //           // this.resetUsers();
  //         },
  //         (err) => {
  //           const data: HttpErrorResponse = err;
  //           Swal.fire({
  //             icon: 'info',
  //             text: data?.error?.error?.message,
  //           });
  //         }
  //       );
  //     }
  //   });
  //   this.reset();
  // }
  // reset() {
  //   this.roleForm.patchValue({
  //     txtRoleName: '',
  //   });
  //   this.saveButtonHide = false;
  //   this.isShowSpinner = false;
  //   this.roleResponse = null;
  //   this.roleId = defaultGuid;
  //   this.roleForm.reset();
  //   this.roleForm.markAsUntouched();
  //   this.roleForm.updateValueAndValidity();
  // }

  // statusChange(event: MatSlideToggleChange, data: any) {

  //   // this.status = event.checked == true ? "Active" : "Inactive";
  //   if (event.checked == true) {
  //     Swal.fire({
  //       title: 'Do you want to activate?',
  //       text: 'By Changing Status, this role  will be activated',
  //       icon: 'warning',
  //       showCancelButton: true,
  //       confirmButtonColor: '#34c38f',
  //       cancelButtonColor: '#f46a6a',
  //       confirmButtonText: 'Yes, Activate it!',
  //     }).then((result) => {
  //       if (result.value) {
  //         if (
  //           data?.id &&
  //           data?.id !== '' &&
  //           data?.id !== null &&
  //           data?.id !== undefined
  //         ) {
  //           this.roleService
  //             .enableDisableStatusById(data?.id)
  //             .subscribe(
  //               () => {
  //                 this.toastr.success('Activated Successfully', 'Success');
  //                 this.isShownSaveButton = true;
  //                 this.isShowSpinner = false;
  //                 this.reset();
  //                 this.getRoleTable();
  //               },
  //               (err) => {
  //                 // this.saveButtonHide = false;
  //                 const data: HttpErrorResponse = err;
  //                 this.isShowSpinner = false;
  //                 // this.saveButtonHide = false;
  //                 Swal.fire({
  //                   icon: 'info',
  //                   text: data?.error?.error?.message,
  //                 });
  //                 event.source.checked = false;
  //               }
  //             );
  //         }
  //       } else {
  //         event.source.checked = false;
  //       }
  //     });
  //   } else if (event.checked == false) {
  //     Swal.fire({
  //       title: 'Do you want to deactivate?',
  //       text: 'By Changing Status, this role will be deactivated',
  //       icon: 'warning',
  //       showCancelButton: true,
  //       confirmButtonColor: '#34c38f',
  //       cancelButtonColor: '#f46a6a',
  //       confirmButtonText: 'Yes, deactivate it!',
  //       // cancelButtonText: 'No, deactivate it!',
  //     }).then((result) => {
  //       if (result.value) {
  //         if (
  //           data?.id &&
  //           data?.id !== '' &&
  //           data?.id !== null &&
  //           data?.id !== undefined
  //         ) {
  //           this.roleService
  //             .enableDisableStatusById(data?.id)
  //             .subscribe(
  //               () => {
  //                 this.toastr.success('Deactivated Successfully', 'Success');
  //                 this.isShownSaveButton = true;
  //                 this.isShowSpinner = false;
  //                 this.reset();
  //                 this.getRoleTable();
  //               },
  //               (err) => {
  //                 const data: HttpErrorResponse = err;
  //                 this.isShowSpinner = false;
  //                 event.source.checked = true;
  //                 Swal.fire({
  //                   icon: 'info',
  //                   text: data?.error?.error?.message,
  //                 });
  //               }
  //             );
  //         }
  //       } else {
  //         event.source.checked = true;
  //       }
  //     });
  //   }
  // }

  // onRowDoubleClickV1(textToCopy) {
  //   this.copyTextToClipboard(textToCopy);
  //   this.showNotification('Text copied: ' + textToCopy);
  // }

  // showNotification(message: string): void {
  //   this.snackBar.open(message, 'Close', {
  //     duration: 3000, // Duration in milliseconds
  //     horizontalPosition: 'end', // Positioning the notification horizontally
  //     verticalPosition: 'bottom', // Positioning the notification vertically
  //   });
  // }

  // copyTextToClipboard(text: string): void {
  //   const textArea = document.createElement('textarea');
  //   textArea.value = text;
  //   document.body.appendChild(textArea);
  //   textArea.select();
  //   document.execCommand('Copy');
  //   textArea.remove();
  // }
  // getFormattedDate(date: string): string {
  //   return date ? new Date(date).toLocaleDateString('en-US') : '-';
  // }
}
