import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { InventoryProviderDetailsComponent } from '../inventory-provider-details/inventory-provider-details.component';
import { HttpErrorResponse } from '@angular/common/http';
import Swal from 'sweetalert2';
import { Subscription } from 'rxjs';
import { MasterProviderService } from 'projects/order/src/app/order-proxy/order-management/inventory/master-provider.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DataTableDirective } from 'angular-datatables';
import { CommunicationService } from 'projects/shared/src/app/services/communication.service';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { ToastrService } from 'ngx-toastr';
import { DatePipe } from '@angular/common';
@Component({
  selector: 'app-inventory-provider-table',
  templateUrl: './inventory-provider-table.component.html',
  styleUrls: ['./inventory-provider-table.component.scss']
})
export class InventoryProviderTableComponent implements OnInit {

  @Input() providerTableDataList: any;
  @Input() selectedOption:number;
  // providerTableDataList: any;
  subscription$: Subscription[] = [];
  constructor(
    public dialog: MatDialog,
    private masterproviderService: MasterProviderService,
    private snackBar: MatSnackBar,
    private communicationService: CommunicationService,
    private toastr: ToastrService,
    private datepipe:DatePipe
  ) { }

  @ViewChild(DataTableDirective, { static: false })
  datatableElement: DataTableDirective;
  isLoading:boolean=false;
  dtProviderListTableOptions = {
    responsive: true,
    paging: false,
    dom: 'Bfrtip',
    language: {
      emptyTable: 'No records',
      info: 'Total : _MAX_ records',
      infoEmpty: 'Total : _MAX_ records',
      infoFiltered: '(filtered : _TOTAL_ records)',
      loadingRecords: 'Loading...',
      zeroRecords: 'No matching records',
    },
    scrollCollapse: true,
    scrollY: '500px',
    scrollX: true,
    fixedHeader: true,
    columnDefs: [
      { targets: [0], orderable: false },
      { targets: [0, 1, 2, 3, 4], className: 'dt-fixed-column' },
      { targets: [6,8], visible: false },
    ],
    search: {
      smart: false,
    },
    buttons: [
      {
        extend: 'excel',
        text: 'Export',
        filename: 'Provider List',
        exportOptions: {
          columns: ':visible:not(:first-child,:eq(0)) ',
        },
      },
      {
        extend: 'colvis',
        columns: ':not(:eq(0), :eq(1),:eq(2),:eq(3),:eq(4))',
      }
    ],
  };

  ngOnInit(): void {
    this.dtProviderListTableOptions.buttons[0].filename =
    'Provider List' + '-' + this.datepipe.transform(new Date(), 'MM-dd-yyyy');
    // this.providerTableDataList = this.tableData;
  }

    // //After View Method
    ngAfterViewInit(): void {
      this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
        dtInstance.columns().every(function () {
          const that = this;
          $('input', this.footer()).on('keyup change', function () {
            if (that.search() !== this['value']) {
              that.search(this['value']).draw();
            }
          });
        });
      });
    }

  //Edit View Provider
  ViewProvider(record?: any) {
    const dialogRef = this.dialog.open(InventoryProviderDetailsComponent, {
      disableClose: true,
      data: { providerId: record }
    });

    const closeModal = dialogRef.afterClosed().subscribe(() => {
      // this.getTableDate();
      this.communicationService.triggerMasterProviderGetMethodCall();
    }, err => {
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    });
    this.subscription$.push(closeModal);
  }

  /// Delete provide api implement
  // deleteProvider(providerId: string) {
  //   Swal.fire({
  //     title: 'Are you sure you want to Delete?',
  //     text: 'You won\'t be able to retrieve this data!',
  //     icon: 'warning',
  //     showCancelButton: true,
  //     confirmButtonColor: '#34c38f',
  //     cancelButtonColor: '#f46a6a',
  //     confirmButtonText: 'Yes, delete it!',
  //     allowOutsideClick: false,

  //   }).then(result => {
  //     if (result.value) {
  //       const providerDelete = this.masterproviderService.delete(providerId).subscribe(response => {
  //         this.communicationService.triggerMasterProviderGetMethodCall();
  //         // this.getTableDate();--Com
  //       }, err => {
  //         const data: HttpErrorResponse = err;
  //         Swal.fire({
  //           icon: 'info',
  //           text: data?.error?.error?.message,
  //         });
  //       });
  //       this.subscription$.push(providerDelete);
  //     }
  //   });
  // }

  //Copy Text While Duble on table Row
  onRowDoubleClickV1(textToCopy) {
    this.copyTextToClipboard(textToCopy);
    this.showNotification('Text copied: ' + textToCopy);
  }

  showNotification(message: string): void {
    this.snackBar.open(message, 'Close', {
      duration: 3000, // Duration in milliseconds
      horizontalPosition: 'end', // Positioning the notification horizontally
      verticalPosition: 'bottom', // Positioning the notification vertically
    });
  }

  copyTextToClipboard(text: string): void {
    const textArea = document.createElement('textarea');
    textArea.value = text;
    document.body.appendChild(textArea);
    textArea.select();
    document.execCommand('Copy');
    textArea.remove();
  }
  statusChange(event: MatSlideToggleChange, data: any) {

    if (event.checked == true) {

      Swal.fire({
        title: 'Do you want to activate?',
        text: 'By Changing Status, this Provider will be activated',
        icon: 'info',
        showCancelButton: true,
        confirmButtonColor: '#34c38f',
        cancelButtonColor: '#f46a6a',
        confirmButtonText: 'Yes, Activate it!'
      }).then(result => {
        if (result.value) {
          this.isLoading=true;
          if (data?.id && data?.id !== "" && data?.id !== null && data?.id !== undefined) {
            this.masterproviderService.ProviderActiveInactive(data?.id).subscribe(response => {
               if(this.selectedOption !==0){
                let index = this.providerTableDataList.findIndex(d => d.id === data?.id);
                this.providerTableDataList.splice(index,1);
              }else{
                this.providerTableDataList.find(item => item.id == data?.id).isActive = 1;
              }
              this.toastr.success('Activated Successfully', 'Success')
              this.isLoading=false;
            }, err => {
              event.source.checked = false;
              this.toastr.success('Activate Failed', 'Fail')
              this.isLoading=false;
          }
        );
      }
        }
        else {
          event.source.checked = false;
        }
      });
    }
    else {
        Swal.fire({
          title: 'Do you want to deactivate?',
          text: 'By Changing Status, this Provider will be deactivated',
          icon: 'info',
          showCancelButton: true,
          confirmButtonColor: '#34c38f',
          cancelButtonColor: '#f46a6a',
          confirmButtonText: 'Yes, deactivate it!'
        }).then(result => {
          if (result.value) {
            this.isLoading=true;
            if (data?.id && data?.id !== "" && data?.id !== null && data?.id !== undefined) {
              this.masterproviderService.ProviderActiveInactive(data?.id).subscribe(response => {
                if(this.selectedOption !==0){
                 let index = this.providerTableDataList.findIndex(d => d.id === data?.id);
                 this.providerTableDataList.splice(index,1);
               }else{
                 this.providerTableDataList.find(item => item.id == data?.id).isActive = 0;
               }
                this.toastr.success('Deactivated Successfully', 'Success')
                this.isLoading=false;
              }, err => {
                event.source.checked = true;
                this.toastr.success('Deactivate Failed', 'Fail')
                this.isLoading=false;
             }
           );
         }

          }
          else {
            event.source.checked = true;
          }
        });
      }
  }
}
