import { FlatTreeControl } from '@angular/cdk/tree';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTreeFlatDataSource, MatTreeFlattener } from '@angular/material/tree';
import { DataTableDirective } from 'angular-datatables';
import { dtInboundOptions, inboundTableData } from '../admin-dashboard/dashboard-data';

const TREE_DATA: FoodNode[] = [
  {
    name: 'Patient Demographic (6)',
    children: [
      { name: 'UnProcessed (10)' },
      { name: 'Fax Issues (5)' },
      { name: 'Confirmation (6)' },
      { name: 'Verification (9)' },
      { name: 'Precert (15)' },
      {
        name: 'Medicare',
        children: [{ name: 'Verbal Approvals' }],
      },
    ],
  },
  {
    name: 'Insurance Verification (1)',
    children: [
      { name: 'UnProcessed (10)' },
      { name: 'Fax Issues (5)' },
      { name: 'Confirmation (6)' },
      { name: 'Verification (9)' },
      { name: 'Precert (15)' },
      {
        name: 'Medicare',
        children: [{ name: 'Verbal Approvals' }],
      },
    ],
  },
  {
    name: 'medical Records (42)',
    children: [
      { name: 'UnProcessed (10)' },
      { name: 'Fax Issues (5)' },
      { name: 'Confirmation (6)' },
      { name: 'Verification (9)' },
      { name: 'Precert (15)' },
      {
        name: 'Medicare',
        children: [{ name: 'Verbal Approvals' }],
      },
    ],
  },
  {
    name: 'sleep study Report (16)',
    children: [
      { name: 'UnProcessed (10)' },
      { name: 'Fax Issues (5)' },
      { name: 'Confirmation (6)' },
      { name: 'Verification (9)' },
      { name: 'Precert (15)' },
      {
        name: 'Medicare',
        children: [{ name: 'Verbal Approvals' }],
      },
    ],
  },
  {
    name: 'trash documents (32)',
    children: [
      { name: 'UnProcessed (10)' },
      { name: 'Fax Issues (5)' },
      { name: 'Confirmation (6)' },
      { name: 'Verification (9)' },
      { name: 'Precert (15)' },
      {
        name: 'Medicare',
        children: [{ name: 'Verbal Approvals' }],
      },
    ],
  },


];
interface ExampleFlatNode {
  expandable: boolean;
  name: string;
  level: number;
}
interface FoodNode {
  name: string;
  children?: FoodNode[];
}

@Component({
  selector: 'app-view-document',
  templateUrl: './view-document.component.html',
  styleUrls: ['./view-document.component.scss']
})
export class ViewDocumentComponent implements OnInit {
  inboundTableData = inboundTableData;
  dtInboundOptions = dtInboundOptions;
  @ViewChild(DataTableDirective, { static: false })
  datatableElement: DataTableDirective;
  myTable: DataTables.Api;
  private transformer = (node: FoodNode, level: number) => {
    return {
      expandable: !!node.children && node.children.length > 0,
      name: node.name,
      level: level,
    };
  };
  treeControl = new FlatTreeControl<ExampleFlatNode>(
    (node) => node.level,
    (node) => node.expandable
  );

  treeFlattener = new MatTreeFlattener(
    this.transformer,
    (node) => node.level,
    (node) => node.expandable,
    (node) => node.children
  );

  treeDatasource = new MatTreeFlatDataSource(
    this.treeControl,
    this.treeFlattener
  );
  constructor() {
    this.treeDatasource.data = TREE_DATA;
   }

  ngOnInit(): void {
  }
  hasChild = (_: number, node: ExampleFlatNode) => node.expandable;

  ngAfterViewInit(): void {
    this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.columns().every(function () {

        const that = this;
        $('input', this.footer()).on('keyup change', function () {

          if (that.search() !== this['value']) {
            that.search(this['value']).draw();
          }
        });
      });
    });
  }


  //Row Click Event UnProcessed Table
  handleTableCellClick(e: Event, notesData: any): void {

    const target = e.target as HTMLElement;
    const tr = target.closest('tr');
    this.getDataTableData(tr, notesData);
  }
    //DataTable Data Get Method and Toggle Notes Row in UnProcessed Table
    async getDataTableData(tr: any, notesData: any): Promise<void> {
      this.myTable = await this.datatableElement.dtInstance;


      if (tr) {
        const row = this.myTable.row(tr);

        if (row.child.isShown()) {
          // This row is already open - close it
          row.child.hide();
        } else {
          // Open this row
          row.child(this.format(row.data(), notesData)).show();
        }
      }
      // Now you can use the dataTable object to get data, for example:
      // const data = this.myTable.rows().data().toArray();

    }
    format(d: any, notesData: any) {

      let returnString: string = '';
      notesData.forEach(function (value) {
        returnString += '<tr class="border-bottom"><td>' + value + '</td></tr>';
      });
      return returnString;
      // (
      //   '<tr class="border-bottom"><td>' +
      //   'There is a language course organised at the training centre. Your unit can send one soldier to attend this course. You have been tasked with giving a briefing presenting two possible candidates' +
      //   '</td></tr>' +
      //   '<tr class="border-bottom"><td>' +
      //   'Let me start with Candidate A and his strengths. Captain Nowak is a very experienced officer who has participated in a few missions abroad and cooperated with our foreign allies. Therefore, he has plenty of practical experience in real life communication in English and working in this language' +
      //   '</td></tr>'
      // );
    }
}
