<!-- <h2 class="customThemeClass" mat-dialog-title>{{ notesModalTitle }}</h2>
<hr /> -->
<div class="modal-header">
    <h1 class="modal-title"><b>{{ notesModalTitle }}</b></h1>
    <mat-icon mat-dialog-close>close</mat-icon>
</div>
<mat-dialog-content class="mat-typography">

    <div class="card card-body">
    <!-- <div class="row">
        <div class="col-lg-6">
            <h2>{{ notesModalTitle }}</h2>
        </div> -->
        <!-- <div class="col-lg-6 text-right">
            <button class="text-right close" aria-label="Close" mat-dialog-close> <b> X </b>
            </button>
        </div> -->
    <!-- </div> -->
    <form [formGroup]="providerForm">
        <div class="modal-body">
            <div class="row">
                <div class="col-lg-12">
                    <mat-card-content>
                        <div class="row">
                            <!-- Prefix Code -->
                            <mat-form-field class="col-3">
                                <mat-label>Prefix Code <span class="asterisk">*</span></mat-label>
                                <input autocomplete="off" matInput maxlength="3" formControlName="txtPrefixCode"
                                    type="text">
                                    <mat-error *ngIf="
                                    (
                                    providerForm?.get('txtPrefixCode')?.errors?.sAlphabets)||
                                    (providerForm?.get('txtPrefixCode')?.errors?.whitespace &&
                                    !providerForm?.get('txtPrefixCode')?.errors?.required)
                                  ">
                                        Enter only Alphabets!
                                    </mat-error>
                                    <mat-error *ngIf="
                                    providerForm?.get('txtPrefixCode')?.errors?.required
                                  ">
                                        Prefix Code is required!
                                    </mat-error>
                            </mat-form-field>


                            <!-- First Name -->
                            <mat-form-field class="col-3">
                                <mat-label>First Name <span class="asterisk">*</span></mat-label>
                                <input autocomplete="new-txtFirstName" matInput maxlength="40" formControlName="txtFirstName" type="text">
                                <!-- <mat-error *ngIf="
                                providerForm?.get('txtFirstName')?.touched &&
                                providerForm?.get('txtFirstName')?.errors?.sAlphabets
                              ">
                                    Enter only Alphabets!
                                </mat-error> -->
                                <mat-error
                                    *ngIf="providerForm?.get('txtFirstName')?.errors?.required">
                                    First Name is a required field!
                                </mat-error>
                            </mat-form-field>
                            <!-- MI -->
                            <mat-form-field class="col-3">
                                <mat-label>Middle Name  </mat-label>
                                <input autocomplete="new-txtMI" matInput maxlength="40" formControlName="txtMI" type="text"
                                    >
                                <!-- <mat-error *ngIf="
                                providerForm?.get('txtMI')?.touched &&
                                providerForm?.get('txtMI')?.errors?.pattern
                ">
                                    Enter only Alphanumeric!
                                </mat-error> -->
                            </mat-form-field>
                            <!-- Last Name -->

                            <mat-form-field class="col-3">
                                <mat-label>Last Name </mat-label>
                                <input autocomplete="new-txtLastName" matInput maxlength="40" formControlName="txtLastName" type="text">
                                <!-- <mat-error *ngIf="
                                providerForm?.get('txtLastName')?.touched &&
                                providerForm?.get('txtLastName')?.errors?.sAlphabets
                              ">
                                    Enter only Alphabets!
                                </mat-error> -->
                                <mat-error
                                    *ngIf="providerForm?.get('txtLastName')?.errors?.required">
                                    Last Name is a required field!
                                </mat-error>
                            </mat-form-field>
                            <!-- NPI -->
                            <mat-form-field class="col-3">
                                <mat-label>NPI <span class="asterisk">*</span></mat-label>
                                <input autocomplete="new-txtNpi" maxlength="13" mask="000-000-0000" placeholder="Enter NPI"
                                    formControlName="txtNpi" matInput type="text">
                                <mat-error *ngIf="
                                    providerForm?.get('txtNpi')?.errors &&(!providerForm?.get('txtNpi')?.errors?.required)
                                  ">
                                    Enter Minimum 10 Digits!
                                </mat-error>
                                <mat-error
                                    *ngIf="providerForm?.get('txtNpi')?.errors?.required">
                                    NPI is a required field!
                                </mat-error>
                                <!-- <mat-error *ngIf="
                                    providerForm?.get('txtNpi')?.touched &&
                                    providerForm?.get('txtNpi')?.errors?.pattern
                    ">
                                        Enter only Alphanumeric!
                                    </mat-error> -->
                            </mat-form-field>
                            <!-- Tax ID -->
                            <mat-form-field class="col-3">
                                <mat-label>Tax ID<span class="asterisk">*</span></mat-label>
                                <input autocomplete="new-txtMmTaxId" minlength="9" maxlength="9"  placeholder="Enter Tax Id"
                                pattern="[0-9-]*"  formControlName="txtMmTaxId" matInput type="text">
                                <mat-error *ngIf="
                                    providerForm?.get('txtMmTaxId')?.errors?.required
                    ">
                                    Tax ID is a required field!
                                </mat-error>
                                <mat-error *ngIf="

                                    (providerForm?.get('txtMmTaxId')?.errors &&
                                    !providerForm?.get('txtMmTaxId')?.errors?.required)
                    ">
                                   Enter 9 digit Numbers only!
                                </mat-error>
                            </mat-form-field>
                            <!-- code -->
                            <mat-form-field class="col-3">
                                <mat-label>code</mat-label>
                                <input autocomplete="new-txtCode" matInput maxlength="40" formControlName="txtCode"
                                    type="text">
                                <!-- <mat-error *ngIf="
                                providerForm?.get('txtCode')?.touched &&
                                providerForm?.get('txtCode')?.errors?.pattern
                ">
                                    Enter only Alphanumeric!
                                </mat-error> -->
                            </mat-form-field>
                            <!-- Default Practice -->
                            <mat-form-field class="col-3">
                                <mat-label> Default Practice </mat-label>
                                <mat-select maxlength="40" formControlName="drpDefaultPractice"
                                    [(value)]="defaultPractice">
                                    <mat-option *ngFor="let practice of drpDefaultPractice"
                                        [value]="practice.shortCodeId">
                                        {{practice.defaultPracticeName}}
                                    </mat-option>
                                </mat-select>
                                <button mat-button (click)="defaultPractice=undefined;$event.stopPropagation()"
                                    (click)="clearDefaultPractice()" *ngIf="defaultPractice" matSuffix mat-icon-button
                                    >
                                    <mat-icon>close</mat-icon>
                                </button>
                            </mat-form-field>
                            <!-- Home Address -->
                            <mat-form-field class="col-3">
                                <mat-label>Home Address</mat-label>
                                <textarea autocomplete="new-txtHomeAddress" formControlName="txtHomeAddress" placeholder="Enter Charlotte Address"
                                    rows="1" maxlength="100" matInput></textarea>
                            </mat-form-field>
                            <!-- <ng-select class="col-3" (change)="onChangeCountry($event)" formControlName="drpCountry" [(value)]="country"
                                placeholder="Country" maxlength="40">
                                <ng-option *ngFor="let country of drpCountry" [value]="country.countryName">
                                    {{ country.countryName }}</ng-option>
                            </ng-select>
                            <ng-select class="col-3" (change)="onChangeState($event,country)" formControlName="drpState"
                                placeholder="State" maxlength="40">
                                <ng-option *ngFor="let state of drpStates" [value]="state.stateName">
                                    {{ state.stateName }}</ng-option>
                            </ng-select> -->
<!--
                            <ng-select class="col-3" formControlName="drpCity" placeholder="City" maxlength="40">
                                <ng-option *ngFor="let city of drpCities" [value]="city.cityName">
                                    {{ city.cityName }}</ng-option>
                            </ng-select>  -->

                            <!-- Country-->
                        <mat-form-field class="col-3" >
                          <mat-label>Country</mat-label>
                          <mat-select
                            formControlName="drpCountry"
                            [(value)]="country"
                            (selectionChange)="onChangeCountry($event.value)"
                          >
                            <mat-option>
                              <ngx-mat-select-search
                                placeholderLabel="Search"
                                noEntriesFoundLabel="No Matches found"
                                formControlName="txtCountryFilter"
                              ></ngx-mat-select-search>
                            </mat-option>
                            <mat-option
                              *ngFor="let country of filteredCountries | async"
                              [value]="country.countryShortName"
                            >
                              {{ country.countryName }}
                            </mat-option>
                          </mat-select>
                          <button
                            mat-button
                            (click)="country = ''; $event.stopPropagation()"
                            *ngIf="country"
                            (click)="clearCountry()"
                            matSuffix
                            mat-icon-button
                          >
                            <mat-icon>close</mat-icon>
                          </button>
                        </mat-form-field>

                        <!-- State-->
                        <mat-form-field class="col-3" >
                          <mat-label>State</mat-label>
                          <mat-select
                            formControlName="drpState"
                            (selectionChange)="
                            onChangeState_GetCity($event.value, country)
                          "
                            [(value)]="State"
                          >
                          <!-- -->
                            <mat-option>
                              <ngx-mat-select-search
                                placeholderLabel="Search"
                                noEntriesFoundLabel="No Matches found"
                                formControlName="txtStateFilter"
                              ></ngx-mat-select-search>
                            </mat-option>
                            <mat-option
                              *ngFor="let state of filteredStates | async"
                              [value]="state.stateShortName"
                            >
                              {{ state.stateName }}
                            </mat-option>
                          </mat-select>
                          <button
                            mat-button
                            (click)="State = ''; $event.stopPropagation()"
                            *ngIf="State"
                            (click)="clearState()"
                            matSuffix
                            mat-icon-button
                          >
                            <mat-icon>close</mat-icon>
                          </button>
                        </mat-form-field>

                        <!-- City-->
                        <mat-form-field class="col-3"  >
                          <mat-label>City</mat-label>
                          <mat-select
                            formControlName="drpCity"
                            [(value)]="City"
                            #citySelect
                          >

                            <mat-option>
                              <ngx-mat-select-search
                                placeholderLabel="Search"
                                (keypress)="onCityKeyPress($event)"

                                formControlName="txtCityFilter"
                                hideClearSearchButton="false"
                                (focusout)="focusOut()"
                              >
                              <!-- [noEntriesFoundLabel]="cityDrpDownMessage()" -->
                              </ngx-mat-select-search>
                            </mat-option>
                            <mat-option
                              *ngFor="let city of filteredCities | async"
                              [value]="city.cityName"
                            >
                              {{ city.cityName }}
                            </mat-option>
                          </mat-select>
                          <!-- *ngIf="providerForm.get('drpCity').value" -->
                          <button
                            mat-button
                            (click)="City = ''; $event.stopPropagation()"
                            *ngIf="City"
                            matSuffix
                            (click)="clearCity()"
                            mat-icon-button
                          >
                            <mat-icon>close</mat-icon>
                          </button>
                        </mat-form-field>


                            <!-- Zip -->
                            <mat-form-field class="col-3">
                                <mat-label>Zip </mat-label>
                                <input autocomplete="new-txtZip" matInput maxlength="10" formControlName="txtZip"  pattern="[0-9-]*" type="text" class="zip-code-input">

                                <mat-error *ngIf="
                                providerForm?.get('txtZip')?.errors?.pattern
                ">
                                    Enter only numbers!
                                </mat-error>
                            </mat-form-field>
                            <mat-form-field class="col-3">
                                <mat-label>Phone Number</mat-label>
                                <input autocomplete="new-txtPhoneNumber" matInput prefix="{{ phoneCode }}-" mask="(000)-000-0000" maxlength="40"
                                    placeholder="Enter Phone Number" formControlName="txtPhoneNumber" type="text">
                                <mat-error *ngIf="
                                    providerForm?.get('txtPhoneNumber')?.errors
                                  ">
                                    Enter Minimum 10 Digits!
                                </mat-error>
                            </mat-form-field>
                            <!-- <mat-form-field class="col-3">
                                <mat-label>Fax</mat-label>
                                <input matInput maxlength="13" placeholder="Enter MM Fax" formControlName="txtFax"
                                    type="text">
                            </mat-form-field> -->
                            <mat-form-field class="col-3">
                                <mat-label>Mobile </mat-label>
                                <input autocomplete="new-txtMobile" matInput prefix="{{ phoneCode }}-" mask="(000)-000-0000" maxlength="40"
                                    placeholder="Enter Phone Number" formControlName="txtMobile" type="text">
                                <mat-error *ngIf="
                                    providerForm?.get('txtMobile')?.errors
                                  ">
                                    Enter Minimum 10 Digits!
                                </mat-error>
                            </mat-form-field>

                            <mat-form-field class="col-3">
                                <mat-label>Email</mat-label>
                                <input autocomplete="new-txtEmail" matInput maxlength="40"
                                pattern="[a-zA-Z0-9._!#$%&'()*+,-./:;<=>?@[\]^_`{|}~]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$"
                                    [textMask]="{ mask: emailMask }" formControlName="txtEmail" type="text">
                                <mat-error *ngIf="
                                providerForm?.get('txtEmail')?.errors?.pattern
              ">
                                    This is not a valid Email!!!
                                </mat-error>
                            </mat-form-field>

                            <mat-form-field class="col-3">
                                <mat-label>Creditials </mat-label>
                                <input autocomplete="new-txtCreditials" matInput maxlength="40" formControlName="txtCreditials"
                                    type="text">
                                <!-- <mat-error *ngIf="
                                providerForm?.get('txtCreditials')?.touched &&
                                providerForm?.get('txtCreditials')?.errors?.pattern
                ">
                                    Enter only Alphanumeric!
                                </mat-error> -->
                            </mat-form-field>
                            <!--
                            <mat-form-field class="col-3">
                                <mat-label> Appt/ Charge Prov </mat-label>
                                <mat-select maxlength="40" formControlName="txtChargeProv">

                                </mat-select>
                            </mat-form-field> -->

                            <mat-form-field class="col-3">
                                <mat-label>Insurance Name </mat-label>
                                <input autocomplete="new-txtInsuranceName" matInput maxlength="40" formControlName="txtInsuranceName" type="text">
                                <mat-error *ngIf="
                                providerForm?.get('txtInsuranceName')?.errors?.sAlphabets
                              ">
                                    Enter only Alphabets!
                                </mat-error>
                            </mat-form-field>


                            <mat-form-field class="col-3">
                                <mat-label>Lola Fax</mat-label>
                                <input autocomplete="new-txtLolaFax" matInput maxlength="13" placeholder="Enter Lola Fax"
                                    formControlName="txtLolaFax" type="text">
                                <!-- <mat-error *ngIf="
                                    providerForm?.get('txtLolaFax')?.touched &&
                                    providerForm?.get('txtLolaFax')?.errors?.pattern
                    ">
                                    Enter only Alphanumeric!
                                </mat-error> -->
                            </mat-form-field>
                            <mat-form-field class="col-3">
                                <mat-label>PTAN</mat-label>
                                <input autocomplete="new-txtPtan" matInput maxlength="13" placeholder="Enter PTAN" formControlName="txtPtan"
                                    type="text">
                                <!-- <mat-error *ngIf="
                                    providerForm?.get('txtPtan')?.touched &&
                                    providerForm?.get('txtPtan')?.errors?.pattern
                    ">
                                    Enter only Alphanumeric!
                                </mat-error> -->
                            </mat-form-field>
                            <mat-form-field class="col-3">
                              <mat-label>TPI</mat-label>
                              <input autocomplete="off" matInput maxlength="13" placeholder="Enter TPI" formControlName="txtTpi"
                                  type="text">
                              <!-- <mat-error *ngIf="
                                  providerForm?.get('txtPtan')?.touched &&
                                  providerForm?.get('txtPtan')?.errors?.pattern
                  ">
                                  Enter only Alphanumeric!
                              </mat-error> -->
                          </mat-form-field>
                            <mat-form-field class="col-3">
                                <mat-label>Address</mat-label>
                                <textarea autocomplete="new-txtMmAddress" formControlName="txtMmAddress" placeholder="Enter Address"
                                    rows="1" maxlength="100" matInput></textarea>
                            </mat-form-field>

                            <mat-form-field class="col-3">
                                <mat-label>Call Back</mat-label>
                                <input autocomplete="new-txtCallBack" maxlength="13" placeholder="Enter Call Back" formControlName="txtCallBack"
                                   matInput type="text">
                                <!-- <mat-error *ngIf="
                                    providerForm?.get('txtCallBack')?.touched &&
                                    providerForm?.get('txtCallBack')?.errors?.pattern
                    ">
                                    Enter only Alphanumeric!
                                </mat-error> -->
                            </mat-form-field>
                            <mat-form-field class="col-3">
                                <mat-label>Charlotte Address</mat-label>
                                <textarea autocomplete="new-txtMmCharlotteAddress" formControlName="txtMmCharlotteAddress"
                                    placeholder="Enter Charlotte Address" rows="1" maxlength="100"
                                    matInput></textarea>
                            </mat-form-field>
                            <mat-form-field class="col-3">
                                <mat-label>Payor ID</mat-label>
                                <input autocomplete="new-txtPayorId" maxlength="13" placeholder="Enter Payor Id"
                                    formControlName="txtPayorId" matInput type="text">
                                <!-- <mat-error *ngIf="
                                    providerForm?.get('txtPayorId')?.touched &&
                                    providerForm?.get('txtPayorId')?.errors?.pattern
                    ">
                                    Enter only Alphanumeric!
                                </mat-error> -->
                            </mat-form-field>


                            <mat-form-field class="col-3">
                                <mat-label>BC/BS Group Id</mat-label>
                                <input autocomplete="new-txtBcBsGroupId" matInput formControlName="txtBcBsGroupId"  type="text"
                                    maxlength="40" />
                                <!-- <mat-error *ngIf="
                                providerForm?.get('txtBcBsGroupId')?.touched &&
                                providerForm?.get('txtBcBsGroupId')?.errors?.pattern
                ">
                                    Enter only Alphanumeric!
                                </mat-error> -->
                            </mat-form-field>

                            <mat-form-field class="col-3">
                                <mat-label>BC/BS provider Id</mat-label>
                                <input autocomplete="new-txtBcBsProviderId" matInput formControlName="txtBcBsProviderId"  type="text"
                                    maxlength="40" />
                                <!-- <mat-error *ngIf="
                                providerForm?.get('txtBcBsProviderId')?.touched &&
                                providerForm?.get('txtBcBsProviderId')?.errors?.pattern
                ">
                                    Enter only Alphanumeric!
                                </mat-error> -->
                            </mat-form-field>

                            <mat-form-field class="col-3">
                                <mat-label>Default Provider Id</mat-label>
                                <input autocomplete="new-txtDefaultProviderId" matInput formControlName="txtDefaultProviderId"
                                    type="text" maxlength="40" />
                                <!-- <mat-error *ngIf="
                                providerForm?.get('txtDefaultProviderId')?.touched &&
                                providerForm?.get('txtDefaultProviderId')?.errors?.pattern
                ">
                                    Enter only Alphanumeric!
                                </mat-error> -->
                            </mat-form-field>

                            <mat-form-field class="col-3">
                                <mat-label>Facility Provider Id</mat-label>
                                <input autocomplete="new-txtFacilityProviderId" matInput formControlName="txtFacilityProviderId"
                                    type="text" maxlength="40" />
                                <!-- <mat-error *ngIf="
                                providerForm?.get('txtFacilityProviderId')?.touched &&
                                providerForm?.get('txtFacilityProviderId')?.errors?.pattern
                ">
                                    Enter only Alphanumeric!
                                </mat-error> -->
                            </mat-form-field>

                            <mat-form-field class="col-3">
                                <mat-label>Medicaid Group Id</mat-label>
                                <input autocomplete="new-txtMedicaidGroupId" matInput formControlName="txtMedicaidGroupId"  type="text"
                                    maxlength="40" />
                                <!-- <mat-error *ngIf="
                                providerForm?.get('txtMedicaidGroupId')?.touched &&
                                providerForm?.get('txtMedicaidGroupId')?.errors?.pattern
                ">
                                    Enter only Alphanumeric!
                                </mat-error> -->
                            </mat-form-field>

                            <mat-form-field class="col-3">
                                <mat-label>Medicaid Provider Id</mat-label>
                                <input autocomplete="new-txtMedicaidProviderId" matInput formControlName="txtMedicaidProviderId"
                                    type="text" maxlength="40" />
                                <!-- <mat-error *ngIf="
                                providerForm?.get('txtMedicaidProviderId')?.touched &&
                                providerForm?.get('txtMedicaidProviderId')?.errors?.pattern
                ">
                                    Enter only Alphanumeric!
                                </mat-error> -->
                            </mat-form-field>

                            <mat-form-field class="col-3">
                                <mat-label>Medicare Group Id</mat-label>
                                <input autocomplete="new-txtMedicareGroupId" matInput formControlName="txtMedicareGroupId" type="text"
                                    maxlength="40" />
                                <!-- <mat-error *ngIf="
                                providerForm?.get('txtMedicareGroupId')?.touched &&
                                providerForm?.get('txtMedicareGroupId')?.errors?.pattern
                ">
                                    Enter only Alphanumeric!
                                </mat-error> -->
                            </mat-form-field>

                            <mat-form-field class="col-3">
                                <mat-label>Medicare Provider Id</mat-label>
                                <input autocomplete="new-txtMedicareProviderId" matInput formControlName="txtMedicareProviderId"
                                    type="text" maxlength="40" />
                                <!-- <mat-error *ngIf="
                                providerForm?.get('txtMedicareProviderId')?.touched &&
                                providerForm?.get('txtMedicareProviderId')?.errors?.pattern
                ">
                                    Enter only Alphanumeric!
                                </mat-error> -->
                            </mat-form-field>

                            <mat-form-field class="col-3">
                                <mat-label>NPI Group Id</mat-label>
                                <input autocomplete="new-txtNPIGroupId" matInput formControlName="txtNPIGroupId" type="text"
                                    maxlength="40" />
                                <!-- <mat-error *ngIf="
                                providerForm?.get('txtNPIGroupId')?.touched &&
                                providerForm?.get('txtNPIGroupId')?.errors?.pattern
                ">
                                    Enter only Alphanumeric!
                                </mat-error> -->
                            </mat-form-field>

                            <mat-form-field class="col-3">
                                <mat-label>NPI Provider Id</mat-label>
                                <input autocomplete="new-txtNpiProviderId" matInput formControlName="txtNpiProviderId"type="text"
                                    maxlength="40" />
                                <!-- <mat-error *ngIf="
                                providerForm?.get('txtNpiProviderId')?.touched &&
                                providerForm?.get('txtNpiProviderId')?.errors?.pattern
                ">
                                    Enter only Alphanumeric!
                                </mat-error> -->
                            </mat-form-field>

                            <mat-form-field class="col-3">
                                <mat-label>Signature Line</mat-label>
                                <input autocomplete="new-txtSignatureLine" matInput formControlName="txtSignatureLine"  type="text"
                                    maxlength="40" />
                                <!-- <mat-error *ngIf="
                                providerForm?.get('txtSignatureLine')?.touched &&
                                providerForm?.get('txtSignatureLine')?.errors?.pattern
                ">
                                    Enter only Alphanumeric!
                                </mat-error> -->
                            </mat-form-field>

                            <mat-form-field class="col-3">
                                <mat-label>Taxonomy Code</mat-label>
                                <input autocomplete="new-txtTaxonomyCode" matInput formControlName="txtTaxonomyCode" type="text"
                                    maxlength="40" />
                                <!-- <mat-error *ngIf="
                                providerForm?.get('txtTaxonomyCode')?.touched &&
                                providerForm?.get('txtTaxonomyCode')?.errors?.pattern
                ">
                                    Enter only Alphanumeric!
                                </mat-error> -->
                            </mat-form-field>


                            <mat-form-field class="col-3">
                                <mat-label>Workers Comp Group Id</mat-label>
                                <input autocomplete="new-txtWorkersCompGroupId" matInput formControlName="txtWorkersCompGroupId"
                                    type="text" maxlength="40" />
                                <!-- <mat-error *ngIf="
                                providerForm?.get('txtWorkersCompGroupId')?.touched &&
                                providerForm?.get('txtWorkersCompGroupId')?.errors?.pattern
                ">
                                    Enter only Alphanumeric!
                                </mat-error> -->
                            </mat-form-field>


                            <mat-form-field class="col-3">
                                <mat-label>Workers Comp Provider Id</mat-label>
                                <input autocomplete="new-txtWorkersCompProviderId" matInput formControlName="txtWorkersCompProviderId"
                                    type="text" maxlength="40" />
                                <!-- <mat-error *ngIf="
                                providerForm?.get('txtWorkersCompProviderId')?.touched &&
                                providerForm?.get('txtWorkersCompProviderId')?.errors?.pattern
                ">
                                    Enter only Alphanumeric!
                                </mat-error> -->
                            </mat-form-field>


                            <!-- <div class="row"> -->
                            <div class="col-3">
                                <br>
                                <mat-checkbox formControlName="chkActive"> Active
                                </mat-checkbox>
                            </div>

                            <div class="col-3">
                                <br>
                                <mat-checkbox formControlName="chkAppointments"> Appointments
                                </mat-checkbox>
                            </div>

                            <div class="col-3">
                                <br>
                                <mat-checkbox formControlName="chkBilllingProvider"> Billing Provider
                                </mat-checkbox>
                            </div>
                            <div class="col-3">
                                <br>
                                <mat-checkbox formControlName="chkSignatureOnFile"> Signature on File
                                </mat-checkbox>
                            </div>

                            <div class="col-3">
                                <br>
                                <mat-checkbox formControlName="chkRenderingProvider"> Rendering Provider
                                </mat-checkbox>
                            </div>

                            <!-- <div class="col-4">
                                    <br>
                                    <mat-checkbox formControlName="chkMaster"> Master
                                    </mat-checkbox>
                                </div>                           -->
                            <!-- </div> -->
                        </div>

                    </mat-card-content>
                </div>
            </div>
        </div>
    </form>
</div>
</mat-dialog-content>
<!-- <br />
  <hr> -->

<mat-dialog-actions align="center">
    <button mat-button class="buttonColor" [disabled]="saveButtonHide||!(!providerForm.invalid &&providerForm.dirty)"
        (click)="saveProvider()">{{isSave}}
        <mat-icon *ngIf='isShowSpinner'>
            <mat-spinner class="spinner-border spinner-border-sm" diameter="20"> </mat-spinner>
        </mat-icon>
    </button>
    <button mat-button class="resetclr mr-2" (click)="resetInsurance()">Reset</button>
    <!-- <button class="buttonCancelColor" mat-button mat-dialog-close>Close</button> -->
    <!-- <button mat-button [mat-dialog-close]="true" cdkFocusInitial>Install</button> -->
</mat-dialog-actions>
