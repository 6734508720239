<div class="container-fluid">
    <!-- Headers -->
    <div class="row">
        <div class="col-lg-12">
            <div class="d-flex align-items-center justify-content-between">
                <h3 class="mb-0 header-text">
                    Update Claim Status - {{data.cliamData.defaultClaimId}}
                </h3>
                <!-- <h2 class="head" ></h2> -->
                <div class="page-title-box page-title-right">
                    <button mat-dialog-close mat-icon-button>
                        <mat-icon>close</mat-icon>
                    </button>
                </div>
            </div>
        </div>
    </div>

    <hr class="m-0">

    <div class="row mt-2">
        <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
            <!-- <div class="card card-body p-2"> -->
            <form [formGroup]="UpdateClaimForm">

                <div class="row">
                    <mat-form-field class="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                        <mat-label>Claim Status<span class="asterisk">*</span></mat-label>
                        <mat-select formControlName="drpOrderStatus">
                            <mat-option>
                                <ngx-mat-select-search placeholderLabel="Search" noEntriesFoundLabel="No Matches found"
                                    formControlName="txtStatusFilter"></ngx-mat-select-search>
                            </mat-option>
                            <mat-option *ngFor="let status of filteredOrderStatusList | async" [value]="status.id" [hidden]="status.shortCodeId === '015' || status.shortCodeId === '016'">
                                {{ status.claimStatus }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>

                    <mat-form-field class="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                        <mat-label>Notes Type<span class="asterisk">*</span></mat-label>
                        <mat-select formControlName="drpNotesType">
                            <mat-option>
                                <ngx-mat-select-search placeholderLabel="Search" noEntriesFoundLabel="No Matches found"
                                    formControlName="txtNotesTypeFilter"></ngx-mat-select-search>
                            </mat-option>
                            <mat-option *ngFor="let notes of filteredNoteTypesList | async" [value]="notes">
                                {{ notes.notesType }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>

                    <mat-form-field class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                        <mat-label>Notes<span class="asterisk">*</span></mat-label>
                        <textarea
                        class="textarea-resize-none" matInput rows="4"  [(ngModel)]="notesText" autocomplete="new-txtAreaNotes"
                      maxlength="5000" formControlName="txtAreaNotes"
                        ></textarea>
                        <div class="char-count">
                            {{ notesText?.length }} / 5000
                          </div>
                    </mat-form-field>

                </div>
                <div class="row">
                    <div class="col-lg-12">
                        <div class="d-flex align-items-center justify-content-center">
                            <button matTooltip="Click To Add New Patient" mat-button
                                class="buttonColor font-size-10 mr-2" (click)="updateClaim()" [disabled]="UpdateClaimForm.invalid || isUpdateClaimData">
                                Update
                            </button>
                            <button matTooltip="Click To Add New Patient" mat-button class="resetclr font-size-10 mr-2"
                                (click)="resetClaim()">
                                Reset
                            </button>
                        </div>
                    </div>
                </div>
            </form>
            <!-- </div> -->
        </div>
    </div>

</div>
