<table
  datatable
  [dtOptions]="vendorOptions"
  class="row-bordered hover w-100 display"
>
  <thead>
    <tr>
      <th class="text-center" [matTooltip]="'Options'" matColumnDef="options">
        Options
      </th>
      <th matColumnDef="status" [matTooltip]="'Status'">Status</th>
      <th matColumnDef="vendorCode" [matTooltip]="'Manufacturer Code'">
        Manufacturer Code
      </th>
      <th matColumnDef="vendorName" [matTooltip]="'Manufacturer Name'">
        Manufacturer Name
      </th>
      <th
        [matTooltip]="'Contact Person'"
        matColumnDef="vendorContactPersonName"
      >
        Contact Person
      </th>
      <th [matTooltip]="'Manufacturer Email'" matColumnDef="vendorEmail">
        Manufacturer Email
      </th>
      <th
        [matTooltip]="'Phone Number'"
        matColumnDef="vendorPhoneNumber"
        class="text-right"
      >
        Phone Number
      </th>
      <th
        [matTooltip]="'Manufacturer Address'"
        matColumnDef="vendorPhoneNumber"
      >
        Manufacturer Address
      </th>
      <th [matTooltip]="'Zip'" matColumnDef="createdDate" class="text-right">Zip</th>
      <th [matTooltip]="'FaxNo'" matColumnDef="createdDate" class="text-right">FaxNo</th>
      <th [matTooltip]="'WebSite'" matColumnDef="createdDate">WebSite</th>
      <th
        [matTooltip]="' AccountNumber'"
        matColumnDef="createdDate"
        class="text-right"
      >
        AccountNumber
      </th>
      <th [matTooltip]="'Remarks'" matColumnDef="createdDate">Remarks</th>
      <th [matTooltip]="'Order Email'" matColumnDef="createdDate">
        Order Email
      </th>
      <th
        [matTooltip]="'Lot Number Length'"
        matColumnDef="createdDate"
        class="text-right"
      >
        Lot Number Length
      </th>
      <th
        [matTooltip]="'Serial Number Length'"
        matColumnDef="createdDate"
        class="text-right"
      >
        Serial Number Length
      </th>
      <th [matTooltip]="'Created By'" matColumnDef="CreatedDate"> Created By</th>
      <th [matTooltip]="'created Date'"  matColumnDef="Created date">Created Date</th>
      <th [matTooltip]="'Modified By'" matColumnDef="createdDate">Modified By</th>
      <th [matTooltip]="'Modified Date'" matColumnDef="createdDate">Modified Date</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let data of vendorList">
      <td class="text-center">
        <a
          *ngIf="data?.status === 'Active'"
          class="editBtnColor mr-2"
          (click)="getInsAllowablesById(data?.id)"
        >
          <i
            class="fa fa-pencil pointer"
            (click)="triggerToggle()"
            matTooltip="Click To Edit"
          ></i>
        </a>
        <!-- <a class="deleteBtnColor">
        <i class="fa fa-trash-o" matTooltip="Click To Delete"></i>
      </a> -->
      </td>
      <td [matTooltip]="data?.status">
        <ng-container *ngIf="data?.status === 'Active'">
          <mat-slide-toggle
            (change)="statusToggleTrigger($event, data)"
            [checked]="true"
            class="ml-1"
          >
            {{ data?.status }}</mat-slide-toggle
          >
        </ng-container>
        <ng-container *ngIf="data?.status === 'Inactive'">
          <mat-slide-toggle
            (change)="statusToggleTrigger($event, data)"
            [checked]="false"
            class="ml-1"
          >
            {{ data?.status }}</mat-slide-toggle
          >
        </ng-container>
      </td>
      <td [matTooltip]="data?.vendorCode || '-'">{{ data.vendorCode || '-'}}</td>
      <td [matTooltip]="data?.vendorName || '-'">{{ data.vendorName || '-'}}</td>
      <td [matTooltip]="data?.contactPersonName || ''">
        {{ data.contactPersonName || '-'}}
      </td>
      <td [matTooltip]="data?.emailId || ''">{{ data.emailId || '-'}}</td>
      <td [matTooltip]="data?.contactNo || ''" class="text-right">
        {{ data.contactNo || '-'}}
      </td>
      <td [matTooltip]="data?.addressLine1 || ''">{{ data.addressLine1 || '-'}}</td>
      <td [matTooltip]="data?.zip || ''" class="text-right">{{ data.zip || '-'}}</td>
      <td [matTooltip]="data?.fax || '-'" class="text-right">{{ data.fax || '-'}}</td>
      <td [matTooltip]="data?.webSite || '-'">{{ data.webSite || '-'}}</td>
      <td [matTooltip]="data?.accountNumber || '-'" class="text-right">
        {{ data.accountNumber || '-'}}
      </td>
      <td [matTooltip]="data?.remarks || '-'">{{ data.remarks || '-'}}</td>
      <td [matTooltip]="data?.orderEmail || '-'">{{ data.orderEmail || '-'}}</td>
      <td [matTooltip]="data?.lotNumberLength || '-'" class="text-right">
        {{ data.lotNumberLength || '-'}}
      </td>
      <td [matTooltip]="data?.serialNumberLength || '-'" class="text-right">
        {{ data?.serialNumberLength || '-'}}
      </td>
      <td [matTooltip]="data?.createdBy || '-'" >{{data.createdBy ? data.createdBy : '-'}}</td>
      
      <td class="text-center">
        <span [matTooltip]="data?.creationTime">{{
          data?.creationTime }}</span>
      </td>

      <!-- <td
        [matTooltip]="
          data?.creationTime
            ? (data?.creationTime | date : 'MM/dd/yyyy ' : 'en_US')
            : '-'
        "
      >
        {{
          data.creationTime
            ? (data.creationTime | date : "MM/dd/yyyy " : "en_US")
            : "-"
        }}
      </td> -->

      <td [matTooltip]="data?.lastModifyBy || '-'">
        {{ data.lastModifyBy ? data.lastModifyBy : '-' }}
      </td>

      <td class="text-center">
        <span [matTooltip]="data?.lastModificationTime">{{
          data?.lastModificationTime }}</span>
      </td>

      <!-- <td
        [matTooltip]="
          data?.lastModificationTime
            ? (data?.lastModificationTime
              | date : 'MM/dd/yyyy h:mm a' : 'en_US')
            : '-'
        "
      >
        {{
          data.lastModificationTime == "" || data.lastModificationTime == null
            ? "-"
            : (data.lastModificationTime | date : "MM/dd/yyyy h:mm a" : "en_US")
        }}
      </td> -->
      
    </tr>
  </tbody>
  <tfoot>
    <tr>
      <th></th>
      <th></th>
      <th>
        <input
          matInput
          class="textbox-border-class"
          type="text"
          placeholder="Manufacturer Code"
        />
      </th>
      <th>
        <input
          matInput
          class="textbox-border-class"
          type="text"
          placeholder="Manufacturer Name"
        />
      </th>
      <th>
        <input
          matInput
          class="textbox-border-class"
          type="text"
          placeholder="Contact Person"
        />
      </th>
      <th>
        <input
          matInput
          class="textbox-border-class"
          type="text"
          placeholder=" Manufacturer Email"
        />
      </th>
      <th>
        <input
          matInput
          class="textbox-border-class text-right"
          type="text"
          placeholder="Phone Number"
        />
      </th>
      <th>
        <input
          matInput
          class="textbox-border-class"
          type="text"
          placeholder="Manufacturer Address"
        />
      </th>
      <th>
        <input
          matInput
          class="textbox-border-class text-right"
          type="text"
          placeholder="Zip "
        />
      </th>
      <th>
        <input
          matInput
          class="textbox-border-class text-right"
          type="text"
          placeholder="FaxNo"
        />
      </th>
      <th>
        <input
          matInput
          class="textbox-border-class"
          type="text"
          placeholder="WebSite"
        />
      </th>
      <th>
        <input
          matInput
          class="textbox-border-class text-right"
          type="text"
          placeholder="AccountNumber"
        />
      </th>
      <th>
        <input
          matInput
          class="textbox-border-class"
          type="text"
          placeholder="Remarks"
        />
      </th>
      <th>
        <input
          matInput
          class="textbox-border-class"
          type="text"
          placeholder="Order Email"
        />
      </th>
      <th>
        <input
          matInput
          class="textbox-border-class"
          type="text"
          placeholder=" Lot Number Length text-right"
        />
      </th>
      <th>
        <input
          matInput
          class="textbox-border-class"
          type="text"
          placeholder="Serial Number Length text-right"
        />
      </th>
      <th>
        <input
          matInput
          class="textbox-border-class"
          type="text"
          placeholder="Created By"
        />
      </th>
      <th>
        <input
          matInput
          class="textbox-border-class"
          type="text"
          placeholder="Created Date"
        />
      </th>
      
      <th>
        <input
          matInput
          class="textbox-border-class"
          type="text"
          placeholder="Modified By"
        />
      </th>
      <th>
        <input
          matInput
          class="textbox-border-class"
          type="text"
          placeholder="Modified Date"
        />
      </th>
    </tr>
  </tfoot>
</table>
