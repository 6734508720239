import type { ARAgingReportDTO, BilliingFilterResultDTO, BillingReportDTO, ChartReportsDTO, ChatReportsDTO, ClaimDeniedReportsDTO, CreateUpdatePayerDTO, CustomReportsDTO, ItemExportDTO, NotesExportDTO, PatientExportDTO, SummaryMailDTO, SummaryReportsDTO,  TrendChartDataDTO,  TrendChartReportsDTO,  WareHouseExportDTO } from './dto/models';
import type { ChatReports } from './enumeration-data/chat-reports.enum';
import type { ExportType } from './enumeration-data/export-type.enum';
import type { QuarterYear } from './enumeration-data/quarter-year.enum';
import type { ReportType } from './enumeration-data/report-type.enum';
import { RestService } from '@abp/ng.core';
import { Injectable } from '@angular/core';
import { CreateUpdateMasterProviderDTO } from '../order-management/inventory/dto';

@Injectable({
  providedIn: 'root',
})
export class ReportService {
  apiName = 'Default';

  exportArAgingReportsByLstArAgingReports = (lstArAgingReports: ARAgingReportDTO[]) =>
    this.restService.request<any, string>({
      method: 'POST',
      responseType: 'text',
      url: '/api/app/report/export-ar-aging-reports',
      body: lstArAgingReports,
    },
    { apiName: this.apiName });

  exportBillingReportsByLstBillingReports = (lstBillingReports: BillingReportDTO[]) =>
    this.restService.request<any, string>({
      method: 'POST',
      responseType: 'text',
      url: '/api/app/report/export-billing-reports',
      body: lstBillingReports,
    },
    { apiName: this.apiName });

  exportItemReportsByExportTypeAndExportDatas = (exportType: ExportType, exportDatas: ItemExportDTO) =>
    this.restService.request<any, string>({
      method: 'POST',
      responseType: 'text',
      url: '/api/app/report/export-item-reports',
      params: { exportType },
      body: exportDatas,
    },
    { apiName: this.apiName });

  exportPatientReportsByExportTypeAndExportDatas = (exportType: ExportType, exportDatas: PatientExportDTO) =>
    this.restService.request<any, string>({
      method: 'POST',
      responseType: 'text',
      url: '/api/app/report/export-patient-reports',
      params: { exportType },
      body: exportDatas,
    },
    { apiName: this.apiName });


  exportWareHouseReportsByExportTypeAndExportDatas = (exportType: ExportType, exportDatas: WareHouseExportDTO) =>
  this.restService.request<any, string>({
    method: 'POST',
    responseType: 'text',
    url: '/api/app/report/export-ware-house-reports',
    params: { exportType },
    body: exportDatas,
  },
  { apiName: this.apiName });

  getARAginReport = () =>
    this.restService.request<any, ARAgingReportDTO[]>({
      method: 'GET',
      url: '/api/app/report/get-aRAgin-report',
    },
      { apiName: this.apiName });

  getClaimDeniedReports = () =>
    this.restService.request<any, ClaimDeniedReportsDTO>({
      method: 'GET',
      url: '/api/app/report/get-claim-denied-reports',
    },
      { apiName: this.apiName });
  getChatDataByInput = (input: ChatReports) =>
    this.restService.request<any, ChatReportsDTO>({
      method: 'GET',
      url: '/api/app/report/get-chart-datas',
      params: { input },
    },
      { apiName: this.apiName });

  gettrendsChatDataByInput = (input: 'Week' | 'Month') =>
    this.restService.request<any, TrendChartReportsDTO>({
      method: 'GET',
      url: '/api/app/report/get-trend-chart-report',
      params: { input },
    },
      { apiName: this.apiName });

  getSummaryReports = () =>
    this.restService.request<any, SummaryReportsDTO>({
      method: 'GET',
      url: '/api/app/report/get-summary-reports',
    },
      { apiName: this.apiName });

  getBillingReports = () =>
    this.restService.request<any, BillingReportDTO[]>({
      method: 'GET',
      url: '/api/app/report/get-billing-reports',
    },
    { apiName: this.apiName });

  getBillingReportsByDateRangeByDtFromDateAndDtToDate = (dtFromDate: string, dtToDate: string) =>
    this.restService.request<any, BillingReportDTO[]>({
      method: 'GET',
      url: '/api/app/report/get-billing-reports-by-date-range',
      params: { dtFromDate, dtToDate },
    },
    { apiName: this.apiName });

  getChartDatasByInput = (input: ChatReports) =>
    this.restService.request<any, ChartReportsDTO>({
      method: 'GET',
      url: '/api/app/report/get-chart-datas',
      params: { input },
    },
    { apiName: this.apiName });

  getChatReportsByInput = (input: ChatReports) =>
    this.restService.request<any, ChatReportsDTO>({
      method: 'GET',
      url: '/api/app/report/get-chat-reports',
      params: { input },
    },
    { apiName: this.apiName });



  getCustomReportByYearAndQuarterYear = (year: number, quarterYear: QuarterYear) =>
    this.restService.request<any, CustomReportsDTO>({
      method: 'GET',
      url: '/api/app/report/get-custom-report',
      params: { year, quarterYear },
    },
    { apiName: this.apiName });

  getFilteredArAgingReportsBySArAgingReportRowNameAndSArAgingReportColumnName = (sArAgingReportRowName: string, sArAgingReportColumnName: string) =>
    this.restService.request<any, BilliingFilterResultDTO>({
      method: 'GET',
      url: '/api/app/report/get-filtered-ar-aging-reports',
      params: { sArAgingReportRowName, sArAgingReportColumnName },
    },
    { apiName: this.apiName });



  getFilteredBillingReportsByOBillingReportRowNameAndOBillingReportColumnName = (oBillingReportRowName: string, oBillingReportColumnName: string) =>
    this.restService.request<any, BilliingFilterResultDTO>({
      method: 'GET',
      url: '/api/app/report/get-filtered-billing-reports',
      params: { oBillingReportRowName, oBillingReportColumnName },
    },
    { apiName: this.apiName });

  sendSummaryReportToMailByLstSummaryMail = (lstSummaryMail: SummaryMailDTO[]) =>
    this.restService.request<any, void>({
      method: 'POST',
      url: '/api/app/report/send-summary-report-to-mail',
      body: lstSummaryMail,
    },
      { apiName: this.apiName });


  getTrendChartReportByInput = (input: ReportType) =>
    this.restService.request<any, TrendChartDataDTO>({
      method: 'GET',
      url: '/api/app/report/get-trend-chart-report',
      params: { input },
    },
    { apiName: this.apiName });
;

  searchArAgingReportBySInputAndLstArAgingReports = (sInput: string, lstArAgingReports: ARAgingReportDTO[]) =>
    this.restService.request<any, ARAgingReportDTO[]>({
      method: 'POST',
      url: '/api/app/report/search-ar-aging-report',
      params: { sInput },
      body: lstArAgingReports,
    },
    { apiName: this.apiName });

  searchBillingReportBySInputAndLstBillingReports = (sInput: string, lstBillingReports: BillingReportDTO[]) =>
    this.restService.request<any, BillingReportDTO[]>({
      method: 'POST',
      url: '/api/app/report/search-billing-report',
      params: { sInput },
      body: lstBillingReports,
    },
    { apiName: this.apiName });



    notesExportsOptionsByLstNotesReports = (lstNotesReports: NotesExportDTO[]) =>
    this.restService.request<any, string>({
      method: 'POST',
      responseType: 'text',
      url: '/api/app/report/notes-exports-options',
      body: lstNotesReports,
    },
    { apiName: this.apiName });



  exportPayorReportsByLstPayerReports = (lstPayerReports: CreateUpdatePayerDTO[]) =>
    this.restService.request<any, string>({
      method: 'POST',
      responseType: 'text',
      url: '/api/app/report/export-payor-reports',
      body: lstPayerReports,
    },
    { apiName: this.apiName });


	  exportProviderReportsByLstProviderReports = (lstProviderReports: CreateUpdateMasterProviderDTO[]) =>
    this.restService.request<any, string>({
      method: 'POST',
      responseType: 'text',
      url: '/api/app/report/export-provider-reports',
      body: lstProviderReports,
    },
    { apiName: this.apiName });

  constructor(private restService: RestService) {}
}
