<div class="container-fluid">
  <div class="row">
    <div class="col-12">
      <div class="d-flex align-items-center justify-content-between">
        <h3 class="font-weight-bolder mb-0">
          <b>Inventory/Requisition</b>
        </h3>

        <div class="col-lg-3" *ngIf="reqId" style="text-align-last: right">
          <mat-icon mat-dialog-close>close</mat-icon>
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-12">
      <div class="card mb-3">
        <div class="card-header p-2 d-flex justify-content-between">
          <b class="header-text">Requistion Form</b>

          <div>
            <!-- <button mat-raised-button (click)="test()">test</button> -->
            <button
              (click)="submitRequisition()"
              [disabled]="!isSubmitButtonEnabled || isSaveBtnDisabled"
              mat-raised-button
              class="buttonColor mr-3"
            >
              Submit Requisition
            </button>
            <button mat-raised-button (click)="resetForm()" class="resetclr">
              Reset
            </button>
          </div>
        </div>
        <div class="card-body p-0">
          <!-- <button mat-raised-button (click)="add()">test</button> -->
          <!-- Requisition Form -->
          <form class="mt-2" [formGroup]="requisitionForm">
            <!-- Id -->
            <mat-form-field class="col-1">
              <mat-label>Id </mat-label>
              <input
                matInput
                readonly
                formControlName="txtId"
                placeholder="Enter ID"
                type="text"
              />
            </mat-form-field>

            <!-- Requisition Date -->
            <mat-form-field class="col-2">
              <mat-label>Requisition Date </mat-label>
              <input
                autocomplete="new-txtRequisitionDate"
                matInput
                readonly
                [(value)]="requisitionDate"
                formControlName="txtRequisitionDate"
                placeholder="Enter Requisition Date"
                type="text"
              />
            </mat-form-field>

            <!-- Requested By -->
            <mat-form-field class="col-2">
              <mat-label>Requested By</mat-label>
              <input
                readonly
                autocomplete="new-txtRequestBy"
                matInput
                formControlName="txtRequestBy"
                placeholder="Requested By"
                type="text"
              />
            </mat-form-field>

            <!--  Shipping Method -->
            <mat-form-field class="col-4">
              <mat-label
                >Shipping Method<span class="asterisk">*</span></mat-label
              >
              <mat-select formControlName="drpShippingMethod" #singleSelect>
                <mat-option
                  [value]="option.id"
                  *ngFor="let option of ltShippingMethods"
                >
                  {{ option.description }}
                </mat-option>
              </mat-select>

              <mat-error
                class="spanAlignment"
                *ngIf="
                  requisitionForm?.get('drpShippingMethod')?.touched &&
                  requisitionForm?.get('drpShippingMethod')?.errors?.required
                "
              >
                Shipping Method field is required!
              </mat-error>
            </mat-form-field>

            <!-- Location -->
            <mat-form-field class="col-3">
              <mat-label>Location<span class="asterisk">*</span></mat-label>
              <mat-select formControlName="drpLocation" #singleSelect>
                <mat-option [value]="data.id" *ngFor="let data of lstBranch">
                  {{ data.organizationUnitName }}
                </mat-option>
              </mat-select>
              <mat-error
                class="spanAlignment"
                *ngIf="
                  requisitionForm?.get('drpLocation')?.touched &&
                  requisitionForm?.get('drpLocation')?.errors?.required
                "
              >
                Location field is required!
              </mat-error>
            </mat-form-field>
            <!-- Inventory Limit -->
            <mat-form-field class="col-3">
              <mat-label>Inventory Limit</mat-label>
              <input
                readonly
                autocomplete="new-txtInventoryLimit"
                matInput
                formControlName="txtInventoryLimit"
                placeholder="Enter txtInventoryLimit"
                type="text"
              />
            </mat-form-field>
            <!-- On Hand Total -->
            <mat-form-field class="col-3">
              <mat-label>On Hand Total </mat-label>
              <input
                autocomplete="new-txtOnHandTotal"
                matInput
                readonly
                formControlName="txtOnHandTotal"
                placeholder="Enter On Hand Total"
                type="text"
              />
            </mat-form-field>
            <!-- On Order -->
            <mat-form-field class="col-3">
              <mat-label>On Order</mat-label>
              <input
                autocomplete="new-txtOnOrder"
                matInput
                readonly
                formControlName="txtOnOrder"
                placeholder="Enter On Order"
                type="text"
              />
            </mat-form-field>
            <!-- Requisition Total -->
            <mat-form-field class="col-3">
              <mat-label>Requisition Total</mat-label>
              <input
                readonly
                autocomplete="new-txtRequisitionTotal"
                matInput
                formControlName="txtRequisitionTotal"
                placeholder="Enter Requisition Total"
                type="text"
              />
            </mat-form-field>
            <!-- Special Instructions-->
            <mat-form-field class="col-5">
              <mat-label>Special Instructions</mat-label>
              <textarea
                formControlName="txtSpecialInstructions"
                rows="4"
                maxlength="5000"
                [(ngModel)]="notesText"
                (input)="updateCharacterCount()"
                matInput
              ></textarea>
              <div class="char-count">{{ notesText?.length }} / 5000</div>
              <mat-error></mat-error>
            </mat-form-field>
            <!-- Manufacturer -->
            <mat-form-field matTooltip="Field Disabled" class="col-2 mr-3">
              <mat-label>Manufacturer</mat-label>
              <mat-select
                [disabled]="true"
                formControlName="txtManufacturer"
                #singleSelect
              >
                <mat-option>
                  <ngx-mat-select-search
                    placeholderLabel="Search"
                    noEntriesFoundLabel="No Matches found"
                    formControlName="txtManufacturerFilter"
                  ></ngx-mat-select-search>
                </mat-option>

                <mat-option
                  *ngFor="let vendors of filteredVendorDetails | async"
                  [value]="vendors.id"
                >
                  {{ vendors.vendorName }}
                </mat-option>
              </mat-select>
              <mat-error></mat-error>
            </mat-form-field>
            <!-- Product List Button -->
            <button
              matTooltip="Field Disabled"
              type="button"
              [disabled]="true"
              class="btn button-hover font-weight-bolder btn-sm mr-1 buttonColor"
            >
              Product List
            </button>
            <!-- No of Days -->
            <mat-form-field matTooltip="Field Disabled" class="col-2 mr-1">
              <mat-label>No.of Days</mat-label>
              <input
                readonly
                matInput
                formControlName="txtNoofDays"
                placeholder="Days"
                type="text"
              />
            </mat-form-field>

            <!-- Smart Requisition List Button -->
            <button
              matTooltip="Field Disabled"
              type="button"
              [disabled]="true"
              class="btn button-hover btn-sm mr-3 font-weight-bolder buttonColor"
            >
              Smart Requisition
            </button>
            <!-- Urgent Items Button -->
            <button
              matTooltip="Field Disabled"
              [disabled]="true"
              type="button"
              class="btn button-hover font-weight-bolder btn-sm buttonColor"
            >
              Urgent Items
            </button>
          </form>
          <mat-divider class="my-2"></mat-divider>
          <!-- Requisition Table List -->
          <div class="p-3">
            <div class="table-responsive">
              <table
                datatable
                [dtOptions]="orderTableOptions"
                [dtTrigger]="dtTrigger"
                class="table row-border hover w-100"
              >
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Product Code</th>
                    <th>Description</th>
                    <th>Manufacturer</th>
                    <th>Category</th>
                    <th>This Order</th>
                    <th>RecOrderQty</th>
                    <th>Status</th>
                    <th>Daily Average Dispense</th>
                    <th>Qty On Hand</th>
                    <th>Await Delivery</th>
                    <th>Pending Patient Order</th>
                    <th>Unit Cost</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let data of requisitionItems">
                    <td>{{ data.sortNumber }}</td>
                    <td>
                      <mat-select
                        (selectionChange)="
                          loadProductDetails(
                            data,
                            myDDRef.value,
                            myInputRef.value
                          )
                        "
                        #myDDRef
                        [(value)]="data.productId"
                        placeholder="Product Code"
                      >
                        <!-- <mat-option>
                          <ngx-mat-select-search
                            placeholderLabel="Search"
                            noEntriesFoundLabel="No Matches found"
                            [(ngModel)]="searchCtrl"
                          ></ngx-mat-select-search>
                        </mat-option> -->

                        <mat-option
                          *ngFor="
                            let products of filteredProductDetails | async
                          "
                          [value]="products.productId"
                        >
                          {{ products.productName }}
                        </mat-option>
                      </mat-select>
                    </td>
                    <td>
                      {{ data.description }}
                    </td>
                    <td>{{ data.manufacturer }}</td>
                    <td>
                      {{ data.category }}
                    </td>
                    <td>
                      <input
                        maxlength="8"
                        (focusout)="updateQuantity(data, myInputRef.value)"
                        class="textbox-border-class"
                        [(ngModel)]="data.thisOrder"
                        #myInputRef="ngModel"
                        required
                        (keypress)="onKeyPress($event)"
                        matInput
                        type="text"
                      />
                      <div
                        class="text-danger"
                        *ngIf="!myInputRef.valid && myInputRef.touched"
                      >
                        Required!
                      </div>
                      <div
                        class="text-danger"
                        *ngIf="
                          myInputRef.value === '0' ||
                          myInputRef.value === null ||
                          myInputRef.value === ''
                        "
                      >
                        Enter Minimum Quantity!
                      </div>
                    </td>
                    <td>{{ data.recOrderQty }}</td>
                    <td>{{ data.status }}</td>
                    <td>{{ data.dailyAvgDesp }}</td>
                    <td>{{ data.qtyOnHand }}</td>
                    <td>{{ data.availQty }}</td>
                    <td>{{ data.pendPatOrder }}</td>
                    <td>{{ data.unitCost }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
