<div class="container-fluid">
  <div class="row bg">
    <!-- Heading -->
    <div class="col-12">
      <div class="d-flex align-items-center justify-content-between mb-3">
        <!-- <h3 class="font-weight-bolder mb-0">Auth Reports</h3> -->
        <h3 class="mb-2 font-size-18 customThemeClass"><b>MIS Report / Auth Report</b></h3>
        <div class="page-title-box page-title-right">
          <ol class="breadcrumbs m-0 align-items-center">
            <button
              class="mr-2 buttonColor"
              mat-raised-button
              [disabled]="
                dataSource && dataSource?.data && dataSource?.data?.length === 0
              "
              (click)="
                exporter.exportTable('xlsx', {
                  fileName: 'Auth Followup List',
                  sheet: 'AuthList',
                  Props: { Author: 'QSecure' }
                })
              "
            >
              Export
            </button>
            <!-- <li class="breadcrumb-item">Reports</li>
            <li class="breadcrumb-item">Auth Reports</li> -->
          </ol>
        </div>
      </div>
    </div>

  
      <form [formGroup]="AuthFilterForm">
        <div class="col-12">
          <h4 class="customThemeClass"><b>Auth Follow Up Filter</b></h4>
        </div>
        <mat-form-field class="col-12">
          <mat-label>Auth Status</mat-label>
          <mat-select
            formControlName="drpForms"
            (selectionChange)="getAuthFollowTableData($event.value)"
            maxlength="40"
          >
            <mat-option
              *ngFor="let providers of drpAuthReportType"
              [value]="providers.key"
            >
              {{ providers.value }}
            </mat-option>
          </mat-select>
          <button
            mat-button
            (click)="$event?.stopPropagation()"
            *ngIf="AuthFilterForm?.get('drpForms')?.value"
            matSuffix
            (click)="clearDrpForms()"
            mat-icon-button
          >
            <mat-icon>close</mat-icon>
          </button>
          <mat-error
            *ngIf="
              AuthFilterForm?.get('drpForms')?.touched &&
              AuthFilterForm?.get('drpForms')?.errors?.required
            "
          >
            Auth Status is a required field!
          </mat-error>
        </mat-form-field>
      </form>
  
    <div class="col-lg-12">
        <div class="example-container mat-elevation-z4">
      <table
        mat-table
        [dataSource]="dataSource"
        matTableExporter
        #exporter="matTableExporter"
        matSort
      >
        <ng-container matColumnDef="item">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Item Code</th>
          <td mat-cell mat-cell *matCellDef="let item">{{ item?.itemCode }}</td>
        </ng-container>
        <ng-container matColumnDef="authId">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Auth ID</th>
          <td mat-cell mat-cell *matCellDef="let item">{{ item?.authId }}</td>
        </ng-container>
        <ng-container matColumnDef="patId">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Patient Id</th>
          <td mat-cell mat-cell *matCellDef="let item">
            {{ item?.defaultPatientId }}
          </td>
        </ng-container>
        <ng-container matColumnDef="patName">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            Patient Name
          </th>
          <td mat-cell mat-cell *matCellDef="let item">
            {{ item?.patientName }}
          </td>
        </ng-container>
        <ng-container matColumnDef="startDate">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            Auth BeginDate
          </th>
          <td mat-cell mat-cell *matCellDef="let item">
            {{
              item?.authBeginDate
                | date
                  : "MM/dd/yyyy h:mm
                        a"
                  : "en_US"
            }}
          </td>
        </ng-container>
        <ng-container matColumnDef="endDate">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            Auth EndDate
          </th>
          <td mat-cell mat-cell *matCellDef="let item">
            {{
              item?.authEndDate
                | date
                  : "MM/dd/yyyy h:mm
                        a"
                  : "en_US"
            }}
          </td>
        </ng-container>
        <ng-container matColumnDef="Note">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Note</th>
          <td mat-cell *matCellDef="let item">{{ item?.note }}</td>
        </ng-container>
        <ng-container matColumnDef="Days">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Days</th>
          <td mat-cell *matCellDef="let item">{{ item?.days }}</td>
        </ng-container>
        <ng-container matColumnDef="Units">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Units</th>
          <td mat-cell *matCellDef="let item">{{ item?.units }}</td>
        </ng-container>
        <ng-container matColumnDef="Used">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Used</th>
          <td mat-cell *matCellDef="let item">{{ item?.used }}</td>
        </ng-container>
        <ng-container matColumnDef="Plan">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Plan</th>
          <td mat-cell *matCellDef="let item">{{ item?.planName }}</td>
        </ng-container>
        <ng-container matColumnDef="type">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Type</th>
          <td mat-cell *matCellDef="let item">{{ item?.saleOrRent }}</td>
        </ng-container>
        <ng-container matColumnDef="Options">
          <th class="header" mat-header-cell *matHeaderCellDef>Options</th>
          <td mat-cell *matCellDef="let element">
            <a class="editBtnColor" (click)="viewItemBasedAuth(element)">
              <i class="fa fa-eye"></i>
            </a>
          </td>
        </ng-container>
        <ng-container matColumnDef="ExpiryStatus">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Auth Status</th>
          <td mat-cell *matCellDef="let item">
            <ng-container *ngIf="item?.authStatus">          
                {{ item?.authStatus }}
            </ng-container>
          </td>
        </ng-container>

        <ng-container matColumnDef="noRecords">
          <td
            class="text-align-center"
            mat-footer-cell
            *matFooterCellDef
            colspan="14"
          >
            <div *ngIf="!isLoading">
              {{ "No Records Found" }}
            </div>
            <mat-spinner
              *ngIf="isLoading"
              class="center"
              mode="indeterminate"
              diameter="50"
            >
            </mat-spinner>
          </td>
        </ng-container>
        <ng-container matColumnDef="paginator">
          <td mat-footer-cell *matFooterCellDef colspan="14">
            <mat-paginator
              class="table table-responsive mat-table-class"
              #MatPaginator
              [length]="dataSource?.data?.length"
              [pageSize]="10"
              [pageIndex]="0"
              showFirstLastButtons
              [pageSizeOptions]="[5, 10, 25, 100]"
            >
            </mat-paginator>
          </td>
        </ng-container>
        <tr
          mat-footer-row
          *matFooterRowDef="['noRecords']"
          [ngClass]="{
            active:
              dataSource && dataSource?.data && dataSource?.data?.length !== 0
          }"
        ></tr>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        <tr mat-footer-row *matFooterRowDef="['paginator']"></tr>
      </table>
      </div>
    </div>
  </div>
</div>
