import { FormBuilder, FormControl, FormGroup, NgControl } from '@angular/forms';
import { Component, Directive, HostListener, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CreateUpdateSourceMDPatientsDTO, InsuranceDetailsDTO, SourceMDPatientsDTO } from '../patient-proxy/source-mdpatient/dto/models';
import { SourceMDPatientService } from '../patient-proxy/source-mdpatient/source-mdpatient.service';
import { HttpErrorResponse } from '@angular/common/http';
import Swal from 'sweetalert2';
import { PatientDropdowns } from '../dropdown-proxy/rcm/dropdown-management/dropdowns/patient-dropdowns.enum';
import { PatientMasterDropdownService } from '../dropdown-proxy/rcm/dropdown-management/dropdowns/master-dropdown-service/patient-master-dropdown.service';
import { Subscription } from 'rxjs';
import { ToastrService } from "ngx-toastr";
//Currency with dollar sign, two decimals, negative allowed, starting with period ok
export const currencyregex: string = `[0-9]?[0-9]?[0-9]?[0-9]?([0-9]?[0-9]?[0-9]?[0-9]?[0-9]?[.]?[0-9]?[0-9]?[0-9]?[0-9]?[0-9]?[0-9]?[0-9]?)`;
// export const currencyregex: string = `([$]?[0-9]?[.]?[0-9]+([.]?[0-9]?[0-9]?[0-9][%]?))`;
@Component({
  selector: 'app-patient-v-import',
  templateUrl: './patient-v-import.component.html',
  styleUrls: ['./patient-v-import.component.scss']
})
export class PatientVImportComponent implements OnInit , OnDestroy {

  pateintId: string = "";
  insuranceId: string = "";
  isShowSpinner = false;
  drpVerificationStatus: any[] = [];
  subscription$: Subscription[] = [];
  selData: SourceMDPatientsDTO = null;
  VInsuranceForm: FormGroup;
  currencyRegex: string = "";
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { pateintId?: string; InsId: string },
    private smdPatientService: SourceMDPatientService,
    private formBuilder: FormBuilder,
    public dialog: MatDialog,
    private toastr: ToastrService,
    private dropdownService: PatientMasterDropdownService


  ) { }

  ngOnInit(): void {
    this.currencyRegex = currencyregex;
    this.pateintId = this.data?.pateintId ?? "";
    this.insuranceId = this.data?.InsId ?? "";
    this.VInsuranceForm = this.formBuilder.group({
      isInsuranceActive: new FormControl(""),
      insuranceComments: new FormControl(""),
      isBlueCardEligible: new FormControl(""),
      blueCardComments: new FormControl(""),
      isPreAuthAvailable: new FormControl(""),
      preAuthComments: new FormControl(""),
      isClaimSubmit: new FormControl(""),
      claimSubmitComments: new FormControl(""),
      productCategory: new FormControl(""),
      product: new FormControl(""),
      referenceNumber: new FormControl(""),
      isLocalBillAvailable: new FormControl(""),
      localBillComments: new FormControl(""),
      insurance: new FormControl(""),
      payerAllowables: new FormControl(""),
      verificationStatus: new FormControl(""),
      paymentComments: new FormControl(""),
      familyCoinsurance: new FormControl(""),
      familyDeductiblePayment: new FormControl(""),
      familyOutOfPocket: new FormControl(""),
      individualCoinsurance: new FormControl(""),
      individualDeductiblePayment: new FormControl(""),
      individualOutOfPocket: new FormControl(""),
      txtSourceMDComments: new FormControl(""),
      txtRndComments: new FormControl("")
      // rndComments: new FormControl(""),
      // sourcemdComments: new FormControl("")
    })
    const input: PatientDropdowns[] = [PatientDropdowns.All];
    const patientDropdown = this.dropdownService.getPatientDropdownsByInput(input).subscribe(resposne => {
      this.drpVerificationStatus = resposne?.lstVerificationStatus ?? [];
    })
    this.subscription$.push(patientDropdown);
    //
    const patientGet = this.pateintId && this.smdPatientService.get(this.pateintId).subscribe(response => {
      this.selData = response;
      const insDetails: InsuranceDetailsDTO = response?.insuranceDetailsList?.filter(e => e?.uniqueInsuranceId === this.insuranceId)[0] || null;

      if (insDetails) {
        this.VInsuranceForm.patchValue({
          isInsuranceActive: insDetails?.isInsuranceActive,
          insuranceComments: insDetails?.insuranceComments,
          isBlueCardEligible: insDetails?.isBlueCardEligible,
          blueCardComments: insDetails?.blueCardComments,
          isPreAuthAvailable: insDetails?.isPreAuthAvailable,
          preAuthComments: insDetails?.preAuthComments,
          isClaimSubmit: insDetails?.isClaimSubmit,
          claimSubmitComments: insDetails?.claimSubmitComments,
          productCategory: insDetails?.productCategory,
          product: insDetails?.product,
          referenceNumber: insDetails?.referenceNumber,
          isLocalBillAvailable: insDetails?.isLocalBillAvailable,
          localBillComments: insDetails?.localBillComments,
          insurance: insDetails?.insurance,
          payerAllowables: insDetails?.payerAllowables,
          verificationStatus: insDetails?.verificationStatus ?? "",
          paymentComments: insDetails?.paymentComments ?? "",
          familyCoinsurance: insDetails?.familyCoinsurance ?? "",
          familyDeductiblePayment: insDetails?.familyDeductiblePayment ?? "",
          familyOutOfPocket: insDetails?.familyOutOfPocket ?? "",
          individualCoinsurance: insDetails?.individualCoinsurance ?? "",
          individualDeductiblePayment: insDetails?.individualDeductiblePayment ?? "",
          individualOutOfPocket: insDetails?.individualOutOfPocket ?? "",
          txtSourceMDComments: insDetails?.sourcemdComments ?? "",
          txtRndComments: insDetails?.rndComments ?? "",
        })
      }
    }, err => {
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    });
    this.subscription$.push(patientGet);
  }

  ngOnDestroy(): void {
    this.subscription$?.forEach((sub) => {
      sub && sub?.unsubscribe();
    });
  }
  saveInsrance() {
    this.isShowSpinner = true;
    const insValues = this.VInsuranceForm.value;
    const selInsValue: InsuranceDetailsDTO = this.selData?.insuranceDetailsList?.filter(e => e?.uniqueInsuranceId === this.insuranceId)[0] || null;
    const selInsValueIndex: number = this.selData?.insuranceDetailsList?.indexOf(selInsValue, 0) || 0;

    let MinsValues: InsuranceDetailsDTO = {
      uniqueInsuranceId: selInsValue?.uniqueInsuranceId ?? "",
      insurance: insValues?.insurance ?? "",
      insuranceId: insValues?.insuranceId ?? "",
      hcps: selInsValue?.hcps ?? "",
      payerAllowables: insValues?.payerAllowables ?? "",
      productCategory: insValues?.productCategory ?? "",
      product: selInsValue?.product ?? "",
      isInsuranceActive: ((+insValues?.isInsuranceActive) ? true : false),
      insuranceComments: insValues?.insuranceComments ?? "",
      isBlueCardEligible: ((+ insValues?.isBlueCardEligible) ? true : false),
      blueCardComments: insValues?.blueCardComments ?? "",
      isLocalBillAvailable: ((+insValues?.isLocalBillAvailable) ? true : false),
      localBillComments: insValues?.localBillComments ?? "",
      isPreAuthAvailable: ((+ insValues?.isPreAuthAvailable) ? true : false),
      preAuthComments: insValues?.preAuthComments ?? "",
      isClaimSubmit: ((+insValues?.isClaimSubmit) ? true : false),
      claimSubmitComments: insValues?.claimSubmitComments ?? "",
      patientResponsiblePayment: insValues?.patientResponsiblePayment ?? "",
      referenceNumber: insValues?.referenceNumber ?? "",
      verificationStatus: insValues?.verificationStatus ?? "",
      paymentComments: insValues?.paymentComments ?? "",
      familyCoinsurance: insValues?.familyCoinsurance ?? "",
      familyDeductiblePayment: insValues?.familyDeductiblePayment ?? "",
      familyOutOfPocket: insValues?.familyOutOfPocket ?? "",
      individualCoinsurance: insValues?.individualCoinsurance ?? "",
      individualDeductiblePayment: insValues?.individualDeductiblePayment ?? "",
      individualOutOfPocket: insValues?.individualOutOfPocket ?? "",
      sourcemdComments: insValues?.txtSourceMDComments ?? "",
      rndComments: insValues?.txtRndComments ?? "",

    }
    if (selInsValueIndex !== -1) {
      this.selData.insuranceDetailsList[selInsValueIndex] = MinsValues;
    }
    const input: CreateUpdateSourceMDPatientsDTO = {
      firstName: this.selData?.firstName,
      lastName: this.selData?.lastName,
      dob: this.selData?.dob,
      streetAddress: this.selData?.streetAddress,
      city: this.selData?.city,
      state: this.selData?.state,
      zipCode: this.selData?.zipCode,
      insuranceDetailsList: this.selData?.insuranceDetailsList,
      defaultPatientId: this.selData?.defaultPatientId,

    }
    this.smdPatientService.update(this.pateintId, input).subscribe(response => {
      // Swal.fire({ title: 'Success', html: 'Saved Successfully', icon: 'success', timer: 3000, timerProgressBar: true })
      this.toastr.success('Saved Successfully','Success')
      this.dialog.closeAll();
      this.isShowSpinner = false;
    }, err => {
      this.isShowSpinner = false;

      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    });
  }
}

