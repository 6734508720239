// import packages if needed
import { Injectable } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';

// custom validator to check email validation
@Injectable({
    providedIn: 'root'
})

// export class for email validation
export class EmailValidation {

    // method to validate email by using paaterns
    isValidMailFormat(control: FormControl) {
        const EMAIL_REGEXP = /^[a-z0-9!#$%&'*+\/=?^_`{|}~.-]+@[a-z0-9]([a-z0-9-]*[a-z0-9])?(\.[a-z0-9]([a-z0-9-]*[a-z0-9])?)*$/i;
        if (control?.value != "" && (control?.value?.length <= 5 || !EMAIL_REGEXP?.test(control?.value))) {
            return { email: true, msg: "Please Enter valid email address" };
        }
        return null;
    }
}
