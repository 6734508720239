<table
  id="tblCurrentSuppliesList"
  datatable
  [dtOptions]="dtOverdueSuppliesOptions"
  class="tbl hover row-border hover w-100 display"
>
  <thead>
    <tr>
      <th><mat-icon>apps</mat-icon></th>
      <th [matTooltip]="'Chart No'">Chart No</th>
      <th [matTooltip]="'Patient Name'">Patient Name</th>
      <th [matTooltip]="'Cell No'">Cell No</th>
      <th [matTooltip]="'DOS'">DOS</th>
      <th [matTooltip]="'Next Call Date'">Next Call Date</th>
      <!-- <th [matTooltip]="'Next Order Date'">Next Order Date</th> -->
      <th [matTooltip]="'Allowable Amount'">Allowable Amount</th>
      <th [matTooltip]="'Aging Days'" class="text-right">Aging Days</th>
      <th [matTooltip]="'CSR Name'">CSR Name</th>
      <th [matTooltip]="'Verification'">Verification</th>
      <th [matTooltip]="'Authorization'">Authorization</th>
      <th [matTooltip]="'Medicare'">Medicare</th>
    </tr>
  </thead>
  <tbody>
    <tr
      *ngFor="let data of overDueDetails"
      (click)="tblItemSelectedRow(data)"
      [ngClass]="{
        'row-highlighted': patientId === data?.patientId
      }"
    >
      <td>
        <mat-icon
          style="cursor: pointer !important"
          [matTooltip]="'Open Options'"
          [matMenuTriggerFor]="menu"
          >more_vert</mat-icon
        >
        <mat-menu class="custom-mat-menu" #menu="matMenu">
          <a
            [routerLink]="
              '/orders/saleOrder/' +
              data?.patientId +
              '/' +
              data?.orderId +
              '/' +
              true +
              '/' +
              2+
              '/' + true
            "
            target="_blank"
            class="preview-link"
            [ngClass]="{
              'disabled-link':
                (data?.verificationStatus !== lstVerificationSttausV2.Done &&
                  data?.authStatus !== 'Approved') ||
                (data?.verificationStatus !== lstVerificationSttausV2.Done &&
                  data?.medicareStatus !== MedicareStatusV1.Approved) ||
                isEmpty(data.patientId)
            }"
          >
            <button mat-menu-item>Create Order</button>
          </a>
          <mat-divider></mat-divider>
          <button
            mat-menu-item
            [disabled]="isEmpty(data.patientId)"
            (click)="openAddNotesPopUp()"
          >
            Add Notes
          </button>
          <button
            mat-menu-item
            (click)="openAddNotesPopUpV1($event, data, true)"
            [disabled]="isEmpty(data.patientId)"
          >
            Add Call Note
          </button>
          <mat-divider></mat-divider>
          <button
            mat-menu-item
            [disabled]="isEmpty(data.patientId)"
            (click)="openViewNotesPopup()"
          >
            View Notes
          </button>
          <button mat-menu-item (click)="viewPatientLedger(data?.patientId)">
            View Patient Ledger
          </button>

          <a
            [routerLink]="'/view_document_tiff/' + data.documentId + '/' + true"
            target="_blank"
            class="preview-link"
            [class.disabled]="isEmpty(data.documentId)"
          >
            <button [disabled]="isEmpty(data.documentId)" mat-menu-item>
              View Document Image
            </button>
          </a>
          <mat-divider></mat-divider>
          <button
            mat-menu-item
            (click)="openLastVerification('Primary')"
            [disabled]="isEmpty(data?.primaryVerification)"
          >
            View Primary Insurance
          </button>

          <button
            mat-menu-item
            (click)="openLastVerification('Secondary')"
            [disabled]="isEmpty(data?.secVerification)"
          >
            View Secondary Insurance
          </button>

          <a
            [routerLink]="
              '/view_checklist/' +
              data.patientId +
              '/' +
              true +
              '/' +
              data.medicareId
            "
            target="_blank"
            class="preview-link"
            [ngClass]="{
              'disabled-link':
                data.medicareId === null ||
                isRoleName !== 'admin' ||
                data.medicareStatus === MedicareStatusV1.New ||
                isEmptyStatus(data?.medicareStatus)
            }"
          >
            <button mat-menu-item>View Checklist</button>
          </a>

          <mat-divider></mat-divider>
          <button
            mat-menu-item
            [disabled]="
              data?.verificationStatus === lstVerificationSttausV2.Done ||
              isEmptyStatus(data?.verificationStatus)
            "
            (click)="doVerfication(data)"
          >
            Do Verification
          </button>

          <button
            mat-menu-item
            [disabled]="
              data?.authStatus === 'Approved' || isEmptyStatus(data?.authStatus)
            "
            (click)="doprecert(data)"
          >
            Pre-cert Request
          </button>

          <button
            mat-menu-item
            [disabled]="
              isEmpty(data?.patientId) ||
              data?.medicareStatus !== MedicareStatusV1.New
            "
            (click)="isDoChekList(data)"
          >
            Medicare Request
          </button>

          <mat-divider></mat-divider>
          <button
            mat-menu-item
            (click)="rxMailSend(data?.patientId, data?.orderId, data?.chartId)"
          >
            RX Mail
          </button>
          <button (click)="openTextConversation()" mat-menu-item>
            Text Conversation
          </button>
          <button mat-menu-item>Patient Call</button>
          <mat-divider></mat-divider>
          <button mat-menu-item [matMenuTriggerFor]="supply">
            Supply Inactivation
          </button>
          <button mat-menu-item (click)="supplyInactive(3, data?.chartId)">
            Move to Archive Files
          </button>
        </mat-menu>
        <mat-menu #supply="matMenu">
          <ng-container>
            <button
              mat-menu-item
              value="Inactive Current Month"
              (click)="supplyInactive(1, data?.chartId)"
            >
              Inactive Current Month
            </button>
            <button
              mat-menu-item
              value="Inactive Entire Supply"
              (click)="supplyInactive(2, data?.chartId)"
            >
              Inactive Entire Supply
            </button>
          </ng-container>
        </mat-menu>
      </td>
      <td
        (dblclick)="onRowDoubleClick(data?.chartId || '-')"
        [matTooltip]="data?.chartId || '-'"
      >
        <a
          [class.disabled]="isEmpty(data?.chartId) || isEmpty(data?.patientId)"
          [routerLink]="'/patientCreateEditTab/' + data?.patientId"
          target="_blank"
          class="preview-link"
          >{{ data?.chartId || "-" }}
        </a>
      </td>
      <td
        [matTooltip]="data?.patientname || '-'"
        (dblclick)="onRowDoubleClick(data?.patientname || '-')"
      >
        {{ data?.patientname || "-" }}
      </td>
      <td
        [matTooltip]="data?.cellNo || '-'"
        (dblclick)="onRowDoubleClick(data?.cellNo || '-')"
      >
        {{ data.cellNo || "-" }}
      </td>
      <td
        [matTooltip]="data?.dos || '-'"
        (dblclick)="onRowDoubleClick(data?.dos || '-')"
        [matTooltip]="data.dos"
      >
        {{ data.dos || "-" }}
      </td>
      <td
        [matTooltip]="data?.nextCallDate || '-'"
        (dblclick)="onRowDoubleClick(data?.nextCallDate || '-')"
        [matTooltip]="data.nextCallDate"
      >
        {{ data.nextCallDate || "-" }}
      </td>
      <!-- <td
        [matTooltip]="data?.nextOrderDate || '-'"
        (dblclick)="onRowDoubleClick(data?.nextOrderDate || '-')"
        [matTooltip]="data.nextOrderDate"
      >
        {{ data.nextOrderDate || "-" }}
      </td> -->
      <td
        [matTooltip]="data?.allowableAmt || '-'"
        (dblclick)="onRowDoubleClick(data?.allowableAmt || '-')"
        class="text-right"
      >
        {{ data.allowableAmt || "-" }}
      </td>
      <td
        [matTooltip]="data?.agingDays || '-'"
        (dblclick)="onRowDoubleClick(data?.agingDays || '-')"
        class="text-right"
      >
        {{ data.agingDays || "0" }}
      </td>
      <td [matTooltip]="data?.csrName || '-'" (dblclick)="onRowDoubleClick(data?.csrName || '-')">
        {{ data.csrName || "-" }}
      </td>
      <td
        [matTooltip]="data?.verificationStatus || '-'"
        (dblclick)="onRowDoubleClick(data?.verificationStatus || '-')"
      >
        <div
          class="d-flex align-items-center justify-content-between"
          *ngIf="data?.verificationStatus === lstVerificationSttausV2.New"
        >
          <span
            class="w-100 text-center p-1 insurance-type-label statusUnaleToVerify"
          >
            {{ data.verificationStatus || "-" }}
          </span>
        </div>

        <div
          class="d-flex align-items-center justify-content-between"
          *ngIf="data?.verificationStatus === lstVerificationSttausV2.Done"
        >
          <span class="w-100 text-center p-1 insurance-type-label statusDone">
            {{ data.verificationStatus || "-" }}
          </span>
        </div>

        <div
          class="d-flex align-items-center justify-content-between"
          *ngIf="
            data?.verificationStatus === lstVerificationSttausV2.Unabletoverify
          "
        >
          <span
            class="w-100 text-center p-1 insurance-type-label statusUnaleToVerify"
          >
            Unable To Verify
          </span>
        </div>

        <div
          class="d-flex align-items-center justify-content-between"
          *ngIf="data?.verificationStatus === lstVerificationSttausV2.Cancelled"
        >
          <span
            class="w-100 text-center p-1 insurance-type-label statusYetToVerify"
          >
            {{ data.verificationStatus || "-" }}
          </span>
        </div>

        <div
          class="d-flex align-items-center justify-content-between"
          *ngIf="
            data?.verificationStatus === lstVerificationSttausV2.Yettoverify
          "
        >
          <span
            class="w-100 text-center p-1 insurance-type-label statusYetToVerify"
          >
            {{ data.verificationStatus || "-" }}
          </span>
        </div>

        <div
          class="d-flex align-items-center justify-content-between"
          *ngIf="data?.verificationStatus === lstVerificationSttausV2.Pending"
        >
          <span
            class="w-100 text-center p-1 insurance-type-label statusYetToVerify"
          >
            {{ data.verificationStatus || "-" }}
          </span>
        </div>

        <div
          class="d-flex align-items-center justify-content-between"
          *ngIf="data?.verificationStatus === lstVerificationSttausV2.Expired"
        >
          <span
            class="w-100 text-center p-1 insurance-type-label statusYetToVerify"
          >
            {{ data.verificationStatus || "-" }}
          </span>
        </div>

        <ng-container *ngIf="!data?.verificationStatus">
          <span>-</span>
        </ng-container>
      </td>
      <td (dblclick)="onRowDoubleClick(data?.authStatus || '-')">
        <ng-container [ngSwitch]="data?.authStatus">
          <div
            class="d-flex align-items-center justify-content-between"
            *ngSwitchCase="'Approved'"
            [matTooltip]="data?.authStatus || '-'"
          >
            <span class="w-100 text-center p-1 insurance-type-label statusDone">
              {{ data.authStatus || "-" }}
            </span>
          </div>
          <div
            class="d-flex align-items-center justify-content-between"
            *ngSwitchCase="'To be Expired'"
          >
            <span
              class="w-100 text-center p-1 insurance-type-label statusYetToVerify"
            >
              <i
                class="fa fa-info-circle mr-2"
                [matTooltip]="data?.authStatus || '-'"
              ></i>
              {{ data.authStatus || "-" }}
            </span>
          </div>
          <div
            class="d-flex align-items-center justify-content-between"
            *ngSwitchDefault
            [matTooltip]="data?.authStatus || '-'"
          >
            <span
              class="w-100 text-center p-1 insurance-type-label statusUnaleToVerify"
              *ngIf="
                data?.authStatus !== 'Approved' &&
                data?.authStatus !== 'To be Expired' &&
                data?.authStatus !== '' &&
                data?.authStatus !== null
              "
            >
              {{ data.authStatus || "-" }}
            </span>
            <span *ngIf="!data?.authStatus">-</span>
          </div>
        </ng-container>
      </td>
      <td
        [matTooltip]="data?.medicareStatus || '-'"
        (dblclick)="onRowDoubleClick(data?.medicareStatus || '-')"
      >
        <div
          class="d-flex align-items-center justify-content-between"
          *ngIf="data?.medicareStatus === 'New'"
        >
          <span
            class="w-100 text-center p-1 insurance-type-label statusUnaleToVerify"
          >
            {{ data.medicareStatus || "-" }}
          </span>
        </div>

        <div
          class="d-flex align-items-center justify-content-between"
          *ngIf="data?.medicareStatus === 'Approved'"
        >
          <span class="w-100 text-center p-1 insurance-type-label statusDone">
            {{ data.medicareStatus || "-" }}
          </span>
        </div>

        <div
          class="d-flex align-items-center justify-content-between"
          *ngIf="data?.medicareStatus === 'Disapproved'"
        >
          <span
            class="w-100 text-center p-1 insurance-type-label statusYetToVerify"
          >
            {{ data.medicareStatus || "-" }}
          </span>
        </div>

        <div
          class="d-flex align-items-center justify-content-between"
          *ngIf="data?.medicareStatus === 'Submitted'"
        >
          <span
            class="w-100 text-center p-1 insurance-type-label statusUnaleToVerify"
          >
            {{ data.medicareStatus || "-" }}
          </span>
        </div>

        <div
          class="d-flex align-items-center justify-content-between"
          *ngIf="data?.medicareStatus === 'Pending '"
        >
          <span
            class="w-100 text-center p-1 insurance-type-label statusUnaleToVerify"
          >
            {{ data.medicareStatus || "-" }}
          </span>
        </div>

        <ng-container *ngIf="!data?.medicareStatus">
          <span>-</span>
        </ng-container>
      </td>
    </tr>
  </tbody>
  <tfoot>
    <tr>
      <th>
        <mat-icon>apps</mat-icon>
      </th>
      <th>
        <input
          matInput
          class="textbox-border-class"
          type="text"
          placeholder="Chart No"
          name="search-chartId"
        />
      </th>
      <th>
        <input
          matInput
          class="textbox-border-class"
          type="text"
          placeholder="Patient Name"
          name="search-patientName"
        />
      </th>
      <th>
        <input
          matInput
          class="textbox-border-class"
          type="text"
          placeholder="Cell No"
          name="search-cellNo"
        />
      </th>
      <th>
        <input
          matInput
          class="textbox-border-class"
          type="text"
          placeholder="DOS"
          name="search-dos"
        />
      </th>
      <th>
        <input
          matInput
          class="textbox-border-class"
          type="text"
          placeholder="Next Call Date"
          name="search-nextCallDate"
        />
      </th>
      <!-- <th>
        <input
          matInput
          class="textbox-border-class"
          type="text"
          placeholder="Next Order Date"
          name="search-nextOrderDate"
        />
      </th> -->
      <th>
        <input
          matInput
          class="textbox-border-class"
          type="text"
          placeholder="Allowable Amount"
          name="search-allowableAmount"
        />
      </th>
      <th>
        <input
          matInput
          class="textbox-border-class"
          type="text"
          placeholder="Aging Days"
          name="search-agingDays"
        />
      </th>
      <th>
        <input
          matInput
          class="textbox-border-class"
          type="text"
          placeholder="CSR Name"
          name="search-CSRName"
        />
      </th>
      <th>
        <input
          matInput
          class="textbox-border-class"
          type="text"
          placeholder="Verification"
          name="search-verification"
        />
      </th>
      <th>
        <input
          matInput
          class="textbox-border-class"
          type="text"
          placeholder="Authorization"
          name="search-authorization"
        />
      </th>
      <th>
        <input
          matInput
          class="textbox-border-class"
          type="text"
          placeholder="Medicare"
          name="search-medicare"
        />
      </th>
    </tr>
  </tfoot>
</table>
<!-- </div> -->
<div class="row" *ngIf="isLoading">
  <div class="col-12">
    <div class="d-flex justify-content-center">
      <img
        class="img-responsive pb-2 mr-1"
        src="assets/gifs/Infinity-loader.gif"
      />
    </div>
  </div>
</div>
