import type { CreateUpdateRentalSaleOrderTemplateDTO, OrderDetailsDTO, RentalSaleOrderTemplateDTO } from './dto/models';
import { RestService } from '@abp/ng.core';
import type { PagedAndSortedResultRequestDto, PagedResultDto } from '@abp/ng.core';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class RentalSaleOrderTemplateService {
  apiName = 'orderManagement';

  create = (input: CreateUpdateRentalSaleOrderTemplateDTO) =>
    this.restService.request<any, RentalSaleOrderTemplateDTO>({
      method: 'POST',
      url: '/api/orderApp/rental-sale-order-template',
      body: input,
    },
      { apiName: this.apiName });

  delete = (id: string) =>
    this.restService.request<any, void>({
      method: 'DELETE',
      url: `/api/orderApp/rental-sale-order-template/${id}`,
    },
      { apiName: this.apiName });

  get = (id: string) =>
    this.restService.request<any, RentalSaleOrderTemplateDTO>({
      method: 'GET',
      url: `/api/orderApp/rental-sale-order-template/${id}`,
    },
      { apiName: this.apiName });
  getRendalOrderListByPatientIDByPatientID = (patientID: string) =>
    this.restService.request<any, RentalSaleOrderTemplateDTO[]>({
      method: 'GET',
      url: '/api/orderApp/rental-sale-order-template/rendal-order-list-by-patient-iD',
      params: { patientID },
    },
      { apiName: this.apiName });

  getList = (input: PagedAndSortedResultRequestDto) =>
    this.restService.request<any, PagedResultDto<RentalSaleOrderTemplateDTO>>({
      method: 'GET',
      url: '/api/orderApp/rental-sale-order-template',
      params: { sorting: input.sorting, skipCount: input.skipCount, maxResultCount: input.maxResultCount },
    },
      { apiName: this.apiName });

  update = (id: string, input: CreateUpdateRentalSaleOrderTemplateDTO) =>
    this.restService.request<any, RentalSaleOrderTemplateDTO>({
      method: 'PUT',
      url: `/api/orderApp/rental-sale-order-template/${id}`,
      body: input,
    },
      { apiName: this.apiName });

  generateInvoicePDFByGInvoiceId = (gInvoiceId: string) =>
    this.restService.request<any, string>({
      method: 'POST',
      responseType: 'text',
      url: `/api/orderApp/rental-sale-order-template/generate-invoice-pDF/${gInvoiceId}`,
    },
      { apiName: this.apiName });

  generateOrder = () =>
    this.restService.request<any, void>({
      method: 'POST',
      url: '/api/orderApp/rental-sale-order-template/generate-order',
    },
      { apiName: this.apiName });

  getSaleOrderDetailsByTemplateByGSaleOrderId = (gSaleOrderId: string) =>
    this.restService.request<any, OrderDetailsDTO>({
      method: 'GET',
      url: `/api/orderApp/rental-sale-order-template/get-sale-order-details-by-template/${gSaleOrderId}`,
    },
      { apiName: this.apiName });

  constructor(private restService: RestService) { }
}
