<div class="container-fluid p-0">
  <div class="row">
    <!-- <div class="col-12">
     <h1 class="head">patient Responsibility - {{data.claimData.defaultClaimId}}</h1>
    </div> -->
    <div class="col-12 px-0">
      <div class="d-flex justify-content-between align-items-center">
        <!-- <h1 class="head">patient Responsibility - {{data.claimData.defaultClaimId}}</h1> -->
        <p class="mb-0 ml-2 fweight">Choose your preferred way to pay online</p>
        <button mat-icon-button mat-dialog-close class="close-button ml-2" style="margin-bottom: 15px;">
          <mat-icon>close</mat-icon>
        </button>
      </div>
      <mat-divider></mat-divider>
      <!-- <p class="mb-0 ml-2 fweight">Choose your preferred way to pay online</p> -->
      <div
        class="card card-body d-flex justify-content-center align-items-center p-3 border-0"
      >
        <div class="col-12 p-0">
          <div
            (click)="selectPaymentMethod(paymentMethod.creditOrDebit)"
            [ngClass]="
              selectedMethod == paymentMethod.creditOrDebit
                ? 'float-shadow'
                : ''
            "
            class="card p-3 mb-2 pointer"
          >
            <p class="fa-lightseagreen mb-0">
              <img
                class="img-responsive pr-1 mb-1"

                src="assets/Order Page Icons/Card.png"
                alt=""
                height="17"
              />
              Credit Card or Debit Card
            </p>
          </div>
          <div
            (click)="selectPaymentMethod(paymentMethod.invoiceLink)"
            [ngClass]="
              selectedMethod == paymentMethod.invoiceLink ? 'float-shadow' : ''
            "
            class="card p-3 pointer"
          >
            <p class="fa-lightseagreen mb-0">
              <img
                class="img-responsive pr-1 mb-1"
                src="assets/Order Page Icons/Paypal Payment 1.png"
                alt=""
                height="17"
              />
              Payment Link
            </p>
          </div>
        </div>
      </div>
      <div class="col-12 d-flex flex-row-reverse mt-2">
        <button
          type="button"
          (click)="processPayment()"
          mat-raised-button
          [disabled]="!(selectedMethod != null) || !isProcessButtonEnabled"
          class="buttonColor process-btn pl-1"
        >

          <img
            class="img-responsive pr-1 mb-1"
            src="assets/Order Page Icons/Process Payment.png"
            alt=""
            height="14"
          />
          Process
          <!--  -->
          <mat-icon *ngIf="!isProcessButtonEnabled">
            <!-- spinner-border    -->
            <mat-spinner class="spinner-border-sm" diameter="20"></mat-spinner>
          </mat-icon>
        </button>
      </div>
    </div>
  </div>
</div>
