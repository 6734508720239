import { DatePipe } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DataTableDirective } from 'angular-datatables';
import { TableService } from 'projects/shared/src/app/table.service';
import Swal from 'sweetalert2';
@Component({
  selector: 'app-invite-user-table',
  templateUrl: './invite-user-table.component.html',
  styleUrls: ['./invite-user-table.component.scss']
})
export class InviteUserTableComponent implements OnInit {

  dtOptionsInviteUser: any = {
    responsive: true,
    paging: false,
    dom: 'Bfrtip',
    language: {
      emptyTable: 'No records',
      info: 'Total : _MAX_ records',
      infoEmpty: 'Total : _MAX_ records',
      infoFiltered: '(filtered : _TOTAL_ records)',
      loadingRecords: 'Loading...',
      zeroRecords: 'No matching records',
    },
    scrollCollapse: true,
    scrollY: '400px',
    scrollX: true,
    fixedHeader: true,
    columnDefs: [
      { targets: [0, 1], orderable: false },
      {
        targets: [6],
        type: 'date',
        render: function (data, type, row) {
          // Assuming data is in the format MM/DD/YYYY
          if (type === 'sort' || type === 'type') {
            var dateParts = data.split('/');
            return new Date(dateParts[2], dateParts[0] - 1, dateParts[1]);
          } else {
            return data;
          }
        },
      },
    ],
    search: {
      smart: false, // Set to "none" for full exact match
    },
    buttons: [
      {
        extend: 'excel',
        text: 'Export',
        filename: 'Invite User',
        exportOptions: {
          columns: ':visible:not(:first-child,:eq(0)) ',
        },
      },
      {
        extend: 'colvis',
        columns: ':not(:eq(0), :eq(-1))',
      }
    ],
  };
  isUsersUpdatePermission: boolean = true;
  isShownUpdateIconForUser: boolean = true;
  isShownDeleteIconForUser: boolean = true;
  isUsersDeletePermission: boolean = true;
  @Input() tableInviteUser: any;

  inviteUserList: any;
  @ViewChild(DataTableDirective, { static: false })
  datatableElement: DataTableDirective;

  @Output() viewButton: EventEmitter<any> = new EventEmitter<any>();
  @Output() deleteButton: EventEmitter<any> = new EventEmitter<any>();
  @Output() resendInviteButton: EventEmitter<any> = new EventEmitter<any>();
  constructor(
    private snackBar: MatSnackBar,
    private Table: TableService,
    private datepipe: DatePipe,
  ) { }

  ngOnInit(): void {

    this.dtOptionsInviteUser.buttons[0].filename =
    'Invite User' + '-' + this.datepipe.transform(new Date(), 'MM-dd-yyyy');

    this.inviteUserList = this.tableInviteUser;

    this.Table.getUsersUpdate().subscribe(
      (value) => {
        this.isUsersUpdatePermission = value;
        if (this.isUsersUpdatePermission == true) {
          this.isShownUpdateIconForUser = true;
        } else {
          this.isShownUpdateIconForUser = false;
        }
      },
      (err) => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      }
    );
    this.Table.getUsersDelete().subscribe(
      (value) => {
        this.isUsersDeletePermission = value;
        if (this.isUsersDeletePermission == true) {
          this.isShownDeleteIconForUser = true;
        } else {
          this.isShownDeleteIconForUser = false;
        }
      },
      (err) => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      }
    );

  }


  ngAfterViewInit(): void {
    if (this.datatableElement && this.datatableElement.dtInstance) {
      this.refreshDataTable();
    } else {
      setTimeout(() => this.refreshDataTable(), 500);
    }
    setTimeout(() => {
      $('#tblinviteUser').DataTable().columns.adjust().draw();
    }, 200);
  }

  refreshDataTable(): void {
    this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
      $('input', dtInstance.table(0).footer()).val('');
      dtInstance.search('').columns().search('').draw();
      dtInstance.columns().every(function () {
        const that = this;
        $('input', this.footer()).on('keyup change', function () {
          if (that.search() !== this['value']) {
            that.search(this['value']).draw();
          }
        });
      });
    });
  }
  onRowDoubleClickV1(textToCopy) {
    this.copyTextToClipboard(textToCopy);
    this.showNotification('Text copied: ' + textToCopy);
  }

  showNotification(message: string): void {
    this.snackBar.open(message, 'Close', {
      duration: 3000, // Duration in milliseconds
      horizontalPosition: 'end', // Positioning the notification horizontally
      verticalPosition: 'bottom', // Positioning the notification vertically
    });
  }

  copyTextToClipboard(text: string): void {
    const textArea = document.createElement('textarea');
    textArea.value = text;
    document.body.appendChild(textArea);
    textArea.select();
    document.execCommand('Copy');
    textArea.remove();
  }
  getFormattedDate(date: string): string {
    return date ? new Date(date).toLocaleDateString('en-US') : '-';
  }

  ViewButtonHitted(value: any, value3?: any, ...value2: any) {
    this.viewButtonClikced(value);
  }
  viewButtonClikced(value: any) {
    this.viewButton.emit(value);
  }

  deleteInviteUsersClicked(value: any) {
    this.deleteButton.emit(value);
  }
  ResendInviteUsersClicked(value: any) {
    this.resendInviteButton.emit(value);
  }
}

