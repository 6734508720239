<div class="container-fluid">
  <div class="row bg">
    <div class="col-12">
      <div class="d-flex align-items-center justify-content-between">
        <h3 class="font-weight-bolder" style="margin: 5px 0 0 0px !important">
          <b class="header-class" >Tools / Patient Management</b>
        </h3>
         <div class="page-title-box page-title-right" style="margin: 5px 0 0 0px !important">
         <!-- <ol class="breadcrumb m-0"> -->
            <!-- <form [formGroup]="patientSearchForm">
              <mat-form-field class="mr-3 customWidth">
            <div style="margin-bottom:-30px;">
              <mat-icon style="margin-top:-2px" matSuffix></mat-icon>
            </div>
            <span matSuffix>
              <mat-icon matSuffix>search</mat-icon>
            </span>
            <input matInput type="search" maxlength="15" formControlName="patientDetails" (ngModelChange)="onKeyPressSearch()" placeholder="Chart No/Patinet Name/DOB &nbsp;&nbsp;"/>
            </mat-form-field>
            </form> -->

            <!-- last comment -->
            <!-- <form [formGroup]="patientSearchForm">
            <div class="search-box mr-3">
              <button style="background: none; border: none; padding: 0"><mat-icon style="font-size: 30px; vertical-align: -1px; color: #9B9B9B" >&nbsp;search</mat-icon></button>
              <input type="search" name="focus" placeholder="Chart No/Patient Name/DOB" id="search-input" maxlength="40" formControlName="patientDetails" (ngModelChange)="onKeyPressSearch()" />
              <button class="closeIcon" *ngIf="isClose" (click)="clearPatinet()"><mat-icon>&nbsp;close</mat-icon></button>
            </div>
            </form> -->
            <!-- <li >  <button
              mat-icon-button
              class="filter-button"
              (click)="toggleFilter()"
            >
              <i class="fa fa-filter"></i>
            </button>
            </li> -->
            <!-- <li>
              <button [disabled]="true" mat-button class=" buttonColor font-size-10" (click)="toggleFilter()">
                <i class="fa fa-filter "></i>
              </button>
            </li> -->
          <!-- </ol>
        </div> -->
        <button  mat-button class=" buttonColor font-size-10"  (click)="addDocumentType(defaultGuid,defaultGuid)">
          <i class="fa fa-upload "></i> Upload Document
        </button>
      </div>
      </div>
    </div>
    <!-- Filter Card -->
    <!-- <mat-card class="filter-card" [hidden]="showFilter">
      <form [formGroup]="branchForm">
        <h4 class="customThemeClass ml-3 mt-2"><b>Filter</b></h4>
        <mat-icon class="float-right btn-close" (click)="toggleFilter()">close</mat-icon>
        <mat-card-content>
          <div class="row">
            <div class="col-12">
              <div class="col-12">
              </div> -->
              <!-- branch -->
              <!-- <mat-form-field class="col-12 ">
                <mat-label>Branch</mat-label>
                <mat-select #selectBranch formControlName="drpBranch" multiple [(value)]="organizationUnitName">
                  <mat-option>
                    <ngx-mat-select-search placeholderLabel="Search" noEntriesFoundLabel="No Matches found"
                      formControlName="txtBranch"></ngx-mat-select-search>
                  </mat-option>

                  <mat-option #selectAllBranch (click)="allBranchSelect()" value="0">All</mat-option>

                  <mat-option *ngFor="let branch of branchList | async" [value]="branch.id">
                    {{ branch.organizationUnitName }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <mat-form-field class="col-12">
                <mat-label>Next Call Date</mat-label>
                <input matInput [matDatepicker]="nextCallDatePicker" (click)="openNextCallDatePicker()"
                  formControlName="txtNextCallDate" placeholder="Next Call Date">
                <mat-datepicker-toggle matSuffix [for]="nextCallDatePicker"></mat-datepicker-toggle>
                <mat-datepicker #nextCallDatePicker></mat-datepicker>
              </mat-form-field>
              <mat-form-field class="col-12 ">
                <mat-label> Last Mask Process date</mat-label>
                <input matInput formControlName="txtMaskDate" type="text" placeholder=" Last Mask Process date" />
              </mat-form-field>
            </div>
          </div>
        </mat-card-content>
      </form> -->
      <!-- <br />
      <div class="col-sm-12">
        <div class="text-lg-center">
          <button mat-button class="buttonColor mb-2 mr-2" (click)="searchDetails()">
            Search
          </button>
          <button mat-button class="resetclr buttonColor mb-2 mr-2">
            Reset
          </button>
        </div>
      </div> -->
      <!-- <br />
    </mat-card> -->
    <br>

    <div class="card card-body " style="margin: 8px 8px 0px 8px !important">
     <form [formGroup]="filterForm">
      <div class="row">
        <mat-form-field class="col-1_5" >
          <mat-label
            >Next Call Date
            <i class="fa fa-calendar ml-2" style="color: rgb(0, 0, 0)"
              matSuffix align="end" ></i>
          </mat-label>
          <!-- <input
            autocomplete="new-txtNextCallDate"
            matInput
            type="text"
            name="daterange"
            ngxDaterangepickerMd
            [showCustomRangeLabel]="true"
            [alwaysShowCalendars]="true"
            [autoApply]="true"
            [(ngModel)]="selectedRange"
            [linkedCalendars]="false"
            [ranges]="ranges"
            [showClearButton]="false"
            [locale]="calendarLocale"
            [keepCalendarOpeningWithRange]="true"
            formControlName="txtNextCallDate"
            (datesUpdated)="datesUpdateds($event)"
            [readonly]="true"
            (keyup)="datesUpdateds($event)"
          /> -->
          <input
                    autocomplete="new-txtNextCallDate"
                    matInput
                    type="text"
                    name="daterange"
                    ngxDaterangepickerMd
                    [showCustomRangeLabel]="true"
                    [alwaysShowCalendars]="true"
                    [autoApply]="true"
                    [(ngModel)]="selectedRange"
                    [linkedCalendars]="false"
                    [ranges]="ranges"
                    [showClearButton]="false"
                    [locale]="calendarLocale"
                    [keepCalendarOpeningWithRange]="true"
                    formControlName="txtNextCallDate"
                    (datesUpdated)="datesUpdateds($event)"
                    [readonly]="true"
                    (keyup)="datesUpdateds($event)"
                  />
          <mat-error
            *ngIf="
              filterForm?.get('txtNextCallDate')?.touched &&
              (filterForm?.get('txtNextCallDate')?.errors
                ?.required ||
                filterForm.get('txtNextCallDate')?.errors?.invalid)
            "
          >
            Next call Date is a required field!
          </mat-error>
          <button type="button" mat-button (click)="clearNextCallDate()"
          matSuffix mat-icon-button>
          <mat-icon>close</mat-icon>
        </button>
        </mat-form-field>
        <mat-form-field class="col-1_5">
          <mat-label>Chart No</mat-label>
          <input matInput  type="text" maxlength="15"
            formControlName="txtChartNo" placeholder="Chart No">
        </mat-form-field>

        <mat-form-field class="col-2">
          <mat-label>Patient Name</mat-label>
          <input matInput  type="text" maxlength="40"
            formControlName="txtPatientName" placeholder="Patient Name">
        </mat-form-field>

        <!-- <mat-form-field class="col-0_5">
          <mat-label>DOB</mat-label>
          <input matInput [matDatepicker]="dob" placeholder="(MM/DD/YYYY)"
            formControlName="txtBirthdate"  (keydown)="addSlashToDate($event)">
          <mat-datepicker-toggle matSuffix [for]="dob"></mat-datepicker-toggle>
          <mat-datepicker #dob></mat-datepicker>
          <mat-error *ngIf="!(
            filterForm?.get('txtBirthdate')?.touched &&
            filterForm?.get('txtBirthdate')?.errors)
            "> Enter a Valid Date!
            </mat-error>
        </mat-form-field> -->

        <!-- <mat-form-field class="col-1_5">
          <mat-label>Next Call Date</mat-label>
          <input matInput [matDatepicker]="nextCallDatePicker" placeholder="(MM/DD/YYYY)"
            formControlName="txtNextCallDate"  (keydown)="addSlashToDate($event)">
          <mat-datepicker-toggle matSuffix [for]="nextCallDatePicker"></mat-datepicker-toggle>
          <mat-datepicker #nextCallDatePicker></mat-datepicker>
        </mat-form-field> -->


        <mat-form-field class="col-2_5">
          <mat-label>
          <div class="select-placeholder-container" *ngIf="isdrpLoading">
            <span>Loading...</span>
            <mat-spinner class="spinner" diameter="20"></mat-spinner>
          </div></mat-label>
          <mat-label> Payor </mat-label>
          <mat-select formControlName="drpPrimaryPayor" multiple [(value)]="primaryPayorId"
            (selectionChange)="onSelectPrimaryPayor($event)" (focusout)="checkNotSelectPayer()">
            <mat-option>
              <ngx-mat-select-search placeholderLabel="payerCode / payerName / Code" hideClearSearchButton="true"
                [noEntriesFoundLabel]="drpDownMessage()" formControlName="primaryPayor"
                (keyup)="payerSearch($event.target.value)">
              </ngx-mat-select-search>
            </mat-option>

            <mat-option *ngIf="!PayorSelectAllOption" [value]="'selectAll'">Select All</mat-option>
            <mat-option *ngIf="PayorSelectAllOption" [value]="'DeselectAll'">Deselect All</mat-option>

            <mat-option *ngFor="let payor of filteredPrimaryPayors" [value]="payor?.payerId" [title]="payor?.payerShortCodeList">
              {{ payor?.payerShortCodeList }}
            </mat-option>
          </mat-select>

          <!-- <mat-hint class="text-danger fw-bolder" *ngIf="!isUniq"> Insurance Category Not Available!</mat-hint> -->
          <button type="button" mat-button (click)="clearPrimaryPolicy($event)"
            matSuffix mat-icon-button>
            <mat-icon>close</mat-icon>
          </button>
        </mat-form-field>
         <!-- CSR Dropdown -->
         <mat-form-field class="col-2">

        <mat-label>
         <div class="select-placeholder-container" *ngIf="userLoading">
           <span>Loading...</span>
           <mat-spinner class="spinner" diameter="20"></mat-spinner>
         </div></mat-label>
          <mat-label >CSR</mat-label>
          <mat-select  formControlName="drpCSR" multiple  [(value)]="userList"
          (selectionChange)="onSelectUser($event)" (focusout)="checkNotSelectUser()">
            <mat-option>
              <ngx-mat-select-search placeholderLabel="Search CSR" [formControl]="CSRFilterControl" (keyup)="UserSearch($event.target.value)"
              [noEntriesFoundLabel]="drpDownMessageV1()" hideClearSearchButton="true"
              ></ngx-mat-select-search>
            </mat-option>
            <mat-option *ngIf="userLoading" >Loading...</mat-option>
            <mat-option *ngIf="!userSelectAllOption && !userLoading" [value]="'selectAll'">Select All</mat-option>
            <mat-option *ngIf="userSelectAllOption && !userLoading" [value]="'DeselectAll'">Deselect All</mat-option>
            <mat-option *ngFor="let csr of filteredCSRList" [value]="csr.userId">
              {{ csr.name }}
            </mat-option>
          </mat-select>
          <button type="button" mat-button (click)="clearUser($event)"
          matSuffix mat-icon-button>
          <mat-icon>close</mat-icon>
        </button>
        </mat-form-field>


        <div class=" all-icon" matTooltip="Search"  style="padding-left: 15px;">
          <i class="fa fa-search search align-items-center" (click)="patientManagementSearch()"
          [class.disabled]="(!this.filterForm.valid || !this.areFieldsNotEmpty())"
           ></i>

        </div>
        &nbsp; &nbsp;
        <div class=" all-icon" matTooltip="Reset">
          <i class="fa fa-refresh reset align-items-center" (click)="resetFilter()"></i>
        </div>
      </div>
     </form>
    </div>

    <div class="card card-body " style="margin: 10px 8px 0px 8px !important">
      <div class="row" *ngIf="!isLoaded">
        <div class="col-12">
          <div *ngIf="!isLoaded" class="table table-responsive">
            <app-patient-management-table [tableData]="PatientDetails" [defaultSelectedRow]="sPID" (rowSelected)="onRowSelected($event)"
            (isLoadTbl)="patientManagementSearch()" (getVisibleColumns)="fnGetVisibleColumns($event)"
            (nevigatePage)="changePage($event)" [visibleColumns]="visibleColumns" [currentPage]="currentPage" [totalPages]="totalPages">
            </app-patient-management-table>
          </div>
        </div>
      </div>
      <div class="row" *ngIf="isLoaded">
        <div class="col-12">
          <div class="d-flex justify-content-center" *ngIf="isLoaded">
            <img class="img-responsive pb-2 mr-1" src="assets/gifs/Infinity-loader.gif" />
          </div>
        </div>
      </div>

      <mat-accordion #firstAccordion="matAccordion" >
        <mat-expansion-panel >
          <mat-expansion-panel-header>
            <mat-panel-title class="customThemeClass">
              <h4 class="customThemeClass pt-2"><b>Patient Info</b></h4>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <mat-divider></mat-divider>
            <!-- <div class="row" *ngIf="isLoaded">
              <div class="col-12">
                <div class="d-flex justify-content-center" *ngIf="isLoaded">
                  <img class="img-responsive pb-2 mr-1" src="assets/gifs/Infinity-loader.gif" />
                </div>
              </div>
            </div> -->
            <div class="row" style="margin-top: 8px !important;scroll-margin-bottom: 8px !important;" >
            <div class="col-4">
              <mat-label class="headingV1" >
                Call Details
              </mat-label>
              <div class="card mb-2 elevated-div content">
                <div class="table-responsive" *ngIf="!isLoading">
                  <table datatable [dtOptions]="dtPMCalllogTableOptions" class="tbl hover row-border hover w-100 display">
                    <thead>
                      <tr>
                        <th>Date Called</th>
                        <th>Called By</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let log of calllogTableData">
                        <td (dblclick)="onRowDoubleClick(getFormattedDate(log?.calledOn))" [matTooltip]="getFormattedDate(log?.calledOn)">
                          {{ getFormattedDate(log?.calledOn) }}
                        </td>
                        <td (dblclick)="onRowDoubleClick(log.calledBy || '-')" [matTooltip]="log.calledBy || '-'">{{ log.calledBy || '-' }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div class="col-8">
              <mat-label class="headingV1" >
                Profile Notes
              </mat-label>
              <div class="card mb-2 elevated-div content">
                <form [formGroup]="patientForm">
                  <div class="row" style="padding: 1rem 0rem 0rem 0rem !important;">
                    <!-- <div class="col-lg-12 cusPadd"> -->
                      <div class="col-2_4 ">
                        <mat-label class="heading">Home No</mat-label>
                        <h2>{{patientForm.value.homeNo}}</h2>
                        <!-- <input autocomplete="new-txtEmail" matInput formControlName="homeNo" type="text" readonly (click)="passInputValue($event);"/> -->
                      </div>
                      <div class="col-2_4 ">
                        <mat-label class="heading">Work No</mat-label>
                        <h2>{{patientForm.value.workNo}}</h2>
                        <!-- <input autocomplete="new-txtEmail" matInput formControlName="workNo" type="text" readonly (click)="passInputValue($event);"/> -->
                      </div>
                      <div class="col-2_4 ">
                        <mat-label class="heading">Mobile No</mat-label>
                        <h2>{{patientForm.value.cell}}</h2>
                        <!-- <input autocomplete="new-txtEmail" matInput formControlName="cell" type="text" readonly (click)="passInputValue($event);"/> -->
                      </div>
                      <div class="col-2_4 ">
                        <mat-label class="heading">Next Call Date</mat-label>
                        <h2>{{patientForm.value.nextCallDate}}</h2>
                        <!-- <input autocomplete="new-txtEmail" matInput formControlName="nextCallDate" type="text" readonly (click)="passInputValue($event);"/> -->
                      </div>
                      <div class="col-2_4 ">
                        <mat-label class="heading">RX Date</mat-label>
                        <h2>{{patientForm.value.rxDate}}</h2>
                        <!-- <input autocomplete="new-txtEmail" matInput formControlName="rxDate" type="text" readonly (click)="passInputValue($event);"/> -->
                      </div>
                    </div>
                  <!-- </div> -->
                  <div class="row " style="padding: 0.5rem 1rem 1rem 1rem !important;">
                    <div class="col-12" style="padding-left: 17px !important;">
                      <!-- <mat-form-field class="col-lg-12 "> -->
                        <mat-label class="heading">Profile Notes</mat-label>
                        <!-- <h3>{{patientNotesForm.value.notes}}</h3> -->
                        <textarea  [disabled]="true" cols="132" rows="3" style="resize: none;"
                        class="txtnotescls" id="scrolltxt">{{patientNotesForm.value.notes}}</textarea>
                        <!-- <input autocomplete="new-txtEmail" matInput formControlName="rxDate" type="text" readonly (click)="passInputValue($event);"/> -->
                      <!-- </mat-form-field> -->
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </mat-expansion-panel>
      </mat-accordion>

    </div>
  </div>
</div>
