import { HttpErrorResponse } from '@angular/common/http';
import {
  AfterViewInit,
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Button, ButtonComponent } from '@syncfusion/ej2-angular-buttons';
import {
  ActionEventArgs,
  addDays,
  AgendaService,
  CellClickEventArgs,
  DayService,
  DragAndDropService,
  DragEventArgs,
  EventClickArgs,
  EventRenderedArgs,
  EventSettingsModel,
  getWeekFirstDate,
  getWeekLastDate,
  MonthService,
  ResizeEventArgs,
  ResizeService,
  ResourceDetails,
  ScheduleComponent,
  WeekService,
  WorkWeekService,
} from '@syncfusion/ej2-angular-schedule';
import { DashboardService } from 'projects/admin/src/app/admin-proxy/platform-app-management/rcm/platform-management/dashboard/dashboard.service';
import { UserService } from 'projects/admin/src/app/admin-proxy/platform-app-management/rcm/platform-management/user/user.service';
import { CreateUpdateCalendarDTO } from 'projects/on-time-schedules/src/app/onTime-proxy/rcm/on-time-management/on-time-schedule/dto/models';
import { OnTimeMasterDropdownService } from 'projects/patient/src/app/dropdown-proxy/dropdown-management/dropdowns/master-dropdown-service/on-time-master-dropdown.service';
import Swal from 'sweetalert2';
import { DropDownList } from '@syncfusion/ej2-dropdowns';
import {
  closest,
  remove,
  addClass,
} from '@syncfusion/ej2-base';
import { Observable, of, Subscription } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { Title } from '@angular/platform-browser';
import { PatientPersonalService } from 'projects/patient/src/app/patient-proxy/patient-optimization';
import { OrganizationUnitService } from 'projects/admin/src/app/admin-proxy/platform-app-management/rcm/platform-management/organization-units';
import { DatePipe } from '@angular/common';
import {
  calender_status_color_code,
  calender_status_code,
  defaultGuid,
} from 'projects/shared/src/app/enums/allenums.enum';
import { InboundDocumentService } from 'projects/admin/src/app/admin-proxy/platform-app-management/rcm/platform-management/inbound/inbound-document.service';
import { InventoryTransferStatusService } from 'projects/inventory/src/app/item-proxy/item-management/optimization/inventory-transfer-status.service';
import { MmOrderService } from 'projects/order/src/app/order-proxy/order-management/order-optimization/mm-order.service';
import { map, startWith } from 'rxjs/operators';
import { ModeOfCommunicationService } from 'projects/admin/src/app/admin-proxy/platform-app-management/rcm/platform-management/communication-mode';
import {
  isNullOrUndefined,
} from '@abp/ng.core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  RequiredValidator,
} from '@angular/forms';
import { CalenderScheduleAddModalComponent } from '../calender-schedule-add-modal/calender-schedule-add-modal.component';
import { CommunicationService } from 'projects/shared/src/app/services/communication.service';
import {
  ContextMenuComponent,
  MenuItemModel,
  BeforeOpenCloseMenuEventArgs,
  MenuEventArgs,
  TreeViewComponent,
  DragAndDropEventArgs,
} from '@syncfusion/ej2-angular-navigations';
import { removeClass } from '@syncfusion/ej2-base';
import { AddNoteTabComponent } from 'projects/patient/src/app/add-note-tab/add-note-tab.component';
import { PatientCommonNotesComponent } from 'projects/shared/src/app/components/patient-common-notes/patient-common-notes.component';
import { ViewPatientLedgerComponent } from 'projects/admin/src/app/view-patient-ledger/view-patient-ledger.component';
import { LoaderService } from 'projects/shared/src/app/services/loader-service';
import { MatSelectChange } from '@angular/material/select';
import { ScheduleDetailsService } from 'projects/admin/src/app/admin-proxy/platform-app-management/rcm/platform-management/appointments/schedule-details.service';
import { CreateUpdateScheduleDTO, scheduleFilterDTO } from 'projects/admin/src/app/admin-proxy/platform-app-management/rcm/platform-management/appointments/dto/models';
import { CalendarStatus } from 'projects/on-time-schedules/src/app/onTime-proxy/rcm/on-time-management/on-time-schedule';
@Component({
  selector: 'app-calender-schedule-v2',
  templateUrl: './calender-schedule-v2.component.html',
  styleUrls: ['./calender-schedule-v2.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    DayService,
    WeekService,
    WorkWeekService,
    MonthService,
    AgendaService,
    ResizeService,
    DragAndDropService,
  ],
})
export class CalenderScheduleV2Component
  implements OnInit, AfterViewInit, OnDestroy {
  defaultGuid = defaultGuid;
  @ViewChild('scheduleObj', { static: true })
  public scheduleObj: ScheduleComponent;
  userId: string = '';
  sesUserId: string;
  organizationUnitName: string;
  roleId: string = '';
  userName: string = '';
  roleName: string = '';
  calenderList: any[] = [];
  userTableData: any[] = [];
  show: boolean = true;
  public temp: string =
    '<div class="tooltip-wrap">' +
    '<div class="type">Status :&nbsp;${status}</div></div></div>' +
    '<div class="patient">Patient&nbsp;:${patientName}</div></div></div>' +
    '<div class="csr">Technician &nbsp;:&nbsp;${assignedUserName}</div></div></div>' +
    '<div class="content-area"><div class="name">Location : &nbsp; ${assignedBranchName}</></div>' +
    '<div class="time">From&nbsp;:&nbsp;${StartTime.toLocaleString()} </div>' +
    '<div class="time">To&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:&nbsp;${EndTime.toLocaleString()} </div>';
  public selectedDate: Date = new Date();
  public selectedTarget: Element;
  public menuItems: MenuItemModel[] = [
    { text: 'Open', iconCss: 'e-icons e-circle-add', id: 'Save' },
    { text: 'Delete', iconCss: 'e-icons e-trash', id: 'Delete' },
    {
      text: 'Start New Order',
      iconCss: 'e-icons e-timeline-today',
      id: 'StartNewOrder',
    },
    {
      text: 'Email Confirmation',
      iconCss: 'e-icons e-edit',
      id: 'emailconfirmation',
    },
    { text: 'Add Notes', iconCss: 'e-icons e-plus', id: 'AddNotes' },
    { text: 'View Notes', iconCss: 'e-icons e-eye', id: 'ViewNotes' },
    {
      text: 'View Patient Leadger',
      iconCss: 'e-icons e-eye',
      id: 'ViewPatientLeadger',
    },
    {
      text: 'Document Imaging',
      iconCss: 'e-icons e-paste-match-destination',
      id: 'DocumentImaging',
    },
  ];

  CalenderStatus = CalendarStatus;
  public eventSettings: EventSettingsModel;

  public roomDataSource: Object[] = this.userTableData;
  public resourcelevel2: any;
  tenantId: string = '';
  isSetupInHome: any;
  //! calender popup open to add event
  patientDet: any[] = [];
  ModeDet: any[] = [];
  QGuid: string = '';
  startEnd: string = '';
  calenderId: string = '';
  calenderDetailId: string = '';
  assignedById: string = '';
  assignedByName: string = '';
  assignedToId: string = '';
  assignedToName: string = '';
  branchDet: any[];
  techDet: any[];
  typeDet: any[];
  userDet: any[];
  resourceDet: any[];
  templateDrp: any[];
  isValidHomeNumber: boolean = true;
  isValidCellNumber: boolean = true;
  isValidWorkNumber: boolean = true;
  isValidEmail: boolean = true;
  patientIdViaURL: string;
  patientId: string;
  docId: string;
  branchId: string;
  techId: string;
  typeId: string;
  userDesID: string;
  modeID: any;
  resourceId: string;
  templateId: string;
  templateName: string;
  home: string;
  cell: string;
  work: string;
  email: string;
  setupInHome: boolean;
  subscription$: Subscription[] = [];
  selectedOption: Object;
  patientChartApiSubscription: Subscription;
  communicationApiSubscription: Subscription;
  templateApiSubscription: Subscription;
  scheduleGetDataApiSubscription: Subscription;
  drpModeList: any;
  lstFilterModeList: any;
  modedrp: any;
  templateDropDownList: DropDownList;
  selectedTemplateId: string = '';
  selectedTemplateName: string = '';
  isUpdate: boolean = false;
  @ViewChild('menuObj') public menuObj: ContextMenuComponent;
  UserTypeControl = new FormControl();
  BranchTypeControl = new FormControl();
  TypeNameControl = new FormControl();
  isUserLoading: boolean = false;
  isShowSpinner: boolean = false;
  scheduleSearchForm: FormGroup;
  typeDrpData: any;
  saveButtonDisabled = true;
  isScheduleLoading: any;
  scheduleTotalCount:number = 0;
  public drpUsertype: User[] = [];
  public lstFilterUsers: any | undefined;
  selectedUserList: string[] = null;
  UserSelectAllOption: boolean = false;
  userDesignation: string;

  public drpBranch: Branch[] = [];
  public brnachList: Observable<Branch[]> | undefined;
  BranchSelectAllOption: boolean = false;
  selectedBranchList: string[] = null;

  public drpTypeName: TypeDrp[] = [];
  description: string;
  selectedTypeList: string[] = null;
  TypeSelectAllOption: boolean = false;

  public allowDragAndDrop = true;
  public isTreeItemDropped = false;
  public draggedItemId = '';
  isRoleName: string;
  selectedUserType: string;
  currentMonth: string = 'Week';
  isMonth:number=0;
  scheduleStartDate: any;  
  scheduleEndDate:   any;
  scheduleMonthDate: any;
  isLunchTime:boolean = false;
  @ViewChild('treeObj') public treeObj: TreeViewComponent;

  constructor(
    private formBuilder: FormBuilder,
    private adminService: DashboardService,
    public dialog: MatDialog,
    public title: Title,
    private PatientPersonalService: PatientPersonalService,
    private onTimeDropdown: OnTimeMasterDropdownService,
    private branchService: OrganizationUnitService,
    private activatedRoute: ActivatedRoute,
    private inboundDocumentService: InboundDocumentService,
    private tranferStatusService: InventoryTransferStatusService,
    private userService: UserService,
    private mmOrderService: MmOrderService,
    private communicationservice: CommunicationService,
    private loaderService: LoaderService,
    private datepipe: DatePipe,
    private scheduledetailsService: ScheduleDetailsService,
  ) {
    this.communicationservice.functionReloadSchedule$.subscribe(() => {
      this.setEventSettings();
    });

    this.loaderService.getLoaderState().subscribe((isScheduleLoading) => {
      this.isScheduleLoading = isScheduleLoading;
    });
  }
  ngAfterViewInit(): void { }

  ngOnInit() {
    this.tenantId = localStorage.getItem('tenantId') ?? '';
    this.adminService.userProfile().subscribe((response) => {
      this.userId = response?.userId;
      this.userName = response?.userName;
    });
    this.sesUserId = localStorage.getItem("userId") ?? "";
    const activatedRoute = this.activatedRoute.paramMap.subscribe(
      (response) => {
        this.patientId = response.get('patientId');
        this.patientIdViaURL = response.get('patientId');
        this.docId = response.get('documentId') || defaultGuid;
        if (this.patientIdViaURL !== null) {
          this.getSelectedPatientDetailsV1(this.patientIdViaURL);
        }
      }
    );
    this.getPatient('', this.patientId);
    this.getTypeDropDown();
    this.title.setTitle('Qsecure | Calender');

    this.scheduleSearchForm = this.formBuilder.group({
      // drpUserName: new FormControl(this.userId),
      // txtUserType: new FormControl(''),
      drpUsertype: new FormControl(''),
      txtUserTypeV1: new FormControl(''),
      drpBranch: new FormControl(''),
      txtBranch: new FormControl(''),
      drpTypeName: new FormControl(''),
      txtTypeName: new FormControl(''),
    });
    // this.scheduleSearchForm.patchValue({ drpUsertype: [this.sesUserId] });
    this.hasvaluePatientSearchForm();
    this.isRoleName = localStorage.getItem('roleName');

  }
  ngOnDestroy(): void {
    this.subscription$.forEach((x) => x.unsubscribe());
  }
  formatDate(date: Date): string {
    const datePipe = new DatePipe('en-US');
    return datePipe.transform(date, 'yyyy-MM-dd HH:mm');
  }

  setEventSettings() {
    this.eventSettings = {
      dataSource: this.getTableData(),
      enableTooltip: true,
      tooltipTemplate: this.temp,
    };
  }

  resetCalender() {
    this.scheduleSearchForm.reset();
    // this.UserSelectAllOption = false;
    // if (this.typeDrpData.length > 0) {
    //   this.onTypeSelectionChange({ value: 'selectAll' } as MatSelectChange);
    // }
    // if (this.isRoleName === 'admin') {
    //   this.onSelectionUserChange({ value: 'selectAll' } as MatSelectChange);
    // } else {
    //   this.scheduleSearchForm.patchValue({ drpUsertype: [this.sesUserId] });
    // }
    //this.onBranchSelectionChange({ value: 'selectAll' } as MatSelectChange);
    setTimeout(() => {
      this.setEventSettings();
    }, 1000);
  }

  //#region Branch Drop Down
  getBranchList() {
    const getBranchList = this.branchService
      // .getBranchListV1(this.tenantId,false)
      .getBranchListBasedOnSchedule(this.tenantId)
      .subscribe(
        (response) => {
          response?.forEach((element) => {
            this.drpBranch.push({
              id: element.id,
              organizationUnitName: element.organizationUnitName,
            });
          });
          this.drpBranch = this.drpBranch.sort((a, b) => a.organizationUnitName.localeCompare(b.organizationUnitName))
          this.brnachList = this.scheduleSearchForm
            ?.get('txtBranch')
            ?.valueChanges?.pipe(
              startWith(''),
              map((value) =>
                this.drpBranch?.filter((option) =>
                  option?.organizationUnitName
                    ?.toLowerCase()
                    ?.includes(value?.toLowerCase() ?? '')
                )
              )
            );

          // this.onBranchSelectionChange({ value: 'selectAll' } as MatSelectChange);
          // if(this.isRoleName === 'admin') {
          //   // this.onBranchSelectionChange({ value : 'selectAll' }as MatSelectChange);
          //   this.loadMapedBrachDropdown();
          //   }else{
          //     this.loadMapedBrachDropdown();
          //   }
        },
        (err) => {}
      );
    this.subscription$.push(getBranchList);
  }

  //Multi Select Branch
  onBranchSelectionChange(event: MatSelectChange): void {
    this.selectedBranchList = event.value;
    if (this.selectedBranchList.includes('selectAll')) {
      // this.BranchSelectAllOption = !this.BranchSelectAllOption;
      this.BranchSelectAllOption = true;
      if (this.BranchSelectAllOption) {
        // If 'Select All' is selected, set the selectedBranchList to include all branch IDs
        this.selectedBranchList = this.drpBranch.map(branch => branch.id);
        this.scheduleSearchForm
          .get('drpBranch')
          .patchValue(this.selectedBranchList);

      }
    } else if (this.selectedBranchList.includes('DeselectAll')) {
      this.selectedBranchList = [];
      this.BranchSelectAllOption = false;
      this.scheduleSearchForm
        .get('drpBranch')
        .patchValue([]);
    }
    else {
      this.BranchSelectAllOption =
        this.selectedBranchList.length === this.drpBranch.length;
    }
  }

  //#endregion Branch Dropdown 

  //#region Technician Drop Down
  getUserByTenantV1() {
    this.loaderService.showLoader();
    this.isUserLoading = true;
    const getBranchList = this.userService
      // .getUsersByTenantV1()
      .getUsersByTenant()
      .subscribe(
        (response) => {
          this.isUserLoading = false;
          response?.forEach((element) => {
            this.drpUsertype.push({
              userId: element.userId,
              userDesignation: element.userDesignation,
            });
          });
          this.lstFilterUsers = this.scheduleSearchForm
            ?.get('txtUserTypeV1')
            ?.valueChanges?.pipe(
              startWith(''),
              map((value) =>
                this.drpUsertype?.filter((option) =>
                  option?.userDesignation
                    ?.toLowerCase()
                    ?.includes(value?.toLowerCase() ?? '')
                )
              )
            );

          // if (this.isRoleName === 'admin') {
          //   this.onSelectionUserChange({ value: 'selectAll' } as MatSelectChange);
          // } else {
          //   this.scheduleSearchForm.patchValue({ drpUsertype: [this.sesUserId] });
          // }
        },
        (err) => {
          this.loaderService.hideLoader();
          this.isUserLoading = false;
          setTimeout(() => {
            this.setEventSettings();
          }, 1000);
        }
      );
    this.subscription$.push(getBranchList);
  }


  //User Multi Select
  onSelectionUserChange(event: MatSelectChange): void {
    this.selectedUserList = event.value;
    if (this.selectedUserList.includes('selectAll')) {
      this.UserSelectAllOption = true;
      if (this.UserSelectAllOption) {

        this.selectedUserList = this.drpUsertype.map(user => user.userId);
        this.scheduleSearchForm
          .get('drpUsertype')
          .patchValue(this.selectedUserList);

      }
    } else if (this.selectedUserList.includes('DeselectAll')) {
      this.selectedUserList = [];
      this.UserSelectAllOption = false;
      this.scheduleSearchForm
        .get('drpUsertype')
        .patchValue([]);
    }
    else {
      this.UserSelectAllOption =
        this.selectedUserList.length === this.drpUsertype.length;
    }
  }
  //#endregion

  //#region Type Dropdown
  getTypeDropDown() {
    const TypeSearchSub$ = of(null)
      .pipe(switchMap((x) => this.inboundDocumentService.getAptclassList()))
      .subscribe((response) => {
        this.typeDrpData = response;
        this.drpTypeName = this.typeDrpData;

        //Type Search Type
        this.TypeNameControl.valueChanges.subscribe((searchTerm) => {
          searchTerm = searchTerm.toLowerCase();
          this.typeDrpData = this.drpTypeName.filter((dx) =>
            dx.description.toLowerCase().includes(searchTerm)
          );
        });
        // if (this.typeDrpData.length > 0) {
        //   this.onTypeSelectionChange({ value: 'selectAll' } as MatSelectChange);
        // }
      });
  }

  //Type Multi Select
  onTypeSelectionChange(event: MatSelectChange): void {
    this.selectedTypeList = event.value;
    if (this.selectedTypeList.includes('selectAll')) {
      this.TypeSelectAllOption = true;
      if (this.TypeSelectAllOption) {

        this.selectedTypeList = this.drpTypeName.map(type => type.aptClassId);
        this.scheduleSearchForm
          .get('drpTypeName')
          .patchValue(this.selectedTypeList);

      }
    } else if (this.selectedTypeList.includes('DeselectAll')) {
      this.selectedTypeList = [];
      this.TypeSelectAllOption = false;
      this.scheduleSearchForm
        .get('drpTypeName')
        .patchValue([]);
    }
    else {
      this.TypeSelectAllOption =
        this.selectedTypeList.length === this.drpTypeName.length;
    }
  }
  //#endregion

  //#region Schedule Data View V1 -- Optimization
  public getTableData(): Record<string, any>[] {

    if (this.scheduleGetDataApiSubscription) {
      this.scheduleGetDataApiSubscription.unsubscribe();
    }

    this.loaderService.showLoader();
    this.userId = localStorage.getItem('userId') ?? '';
    let selectedTypeId;
    let selectedUserId;
    let selectedBranchId;
    //Type Drop Down Get Value 
    if (this.TypeSelectAllOption === true) {
      selectedTypeId = null;
    } else if (this.scheduleSearchForm.value.drpTypeName?.length === 0) {
      selectedTypeId = null;
    } else {
      selectedTypeId = this.scheduleSearchForm.value.drpTypeName;
    }

    //User Drop Down Get Value
    if (this.UserSelectAllOption === true) {
      selectedUserId = null;
    } else if (this.scheduleSearchForm.value.drpUsertype?.length === 0) {
      selectedUserId = null;
    } else {
      selectedUserId = this.scheduleSearchForm.value.drpUsertype;
    }

    //Branch Drop Down Get Value
    if (this.BranchSelectAllOption === true) {
      selectedBranchId = null;
    } else if (this.scheduleSearchForm.value.drpBranch?.length === 0) {
      selectedBranchId = null;
    } else {
      selectedBranchId = this.scheduleSearchForm.value.drpBranch
    }

    const eventData: Record<string, any>[] = [];
    this.roleId = localStorage.getItem('roleId') ?? '';
    this.roleName = localStorage.getItem('roleName') ?? '';

    let getcalendarDTO: scheduleFilterDTO = {
      startDate: this.isMonth === 1 ? this.scheduleMonthDate : this.scheduleStartDate,
      endDate: this.isMonth === 1 ? null : this.scheduleEndDate,
      type: selectedTypeId || null,
      users: selectedUserId || null,
      location: selectedBranchId || null,
      status: null,
      isMonth: this.isMonth
    };

    this.scheduleGetDataApiSubscription = this.scheduledetailsService
      .getSchedulesByFilterByDto(
        getcalendarDTO
      )
      .subscribe(
        (response) => {
          this.scheduleTotalCount = response?.totalCount;
          response &&
            response?.items?.forEach((element) => {
                eventData?.push({
                  EndTime: new Date(element?.toTime),
                  StartTime: new Date(element?.fromTime),
                  Subject: element?.title,
                  Id: element?.scheduleId,
                  QGuid: element?.scheduleId,
                  // Guid: element?.guid,
                  Description: element?.description,
                  StartTimeZone: element?.fromTime,
                  EndTimeZone: element?.toTime,
                  // IsAllDay: element?.isAllDay,
                  Location: element?.locationId,
                  email: element?.email,
                  setupInHome: element?.setUpInHome,
                  // RecurrenceRule: element?.recurrenceRule,
                  // userName: element?.userName,
                  userId: element?.assignedTo,
                  tech: element?.assignedTo,
                  user: element?.assignedTo,
                  type: element?.type,
                  typeId: element?.typeId,
                  resource: element?.providerId,
                  cell: element?.cellNo,
                  phone: element?.homePhoneNo,
                  work: element?.workNo,
                  OwnerId: element?.status,
                  // roleId: element?.roleId,
                  calendarDetailsId: element?.scheduleId,
                  // status: element?.status,
                  status: element?.statusName ||'--',
                  colorCode: element?.colorCode,
                  // roleName: element?.roleName,
                  isDragAndDrop: true,
                  Patient: element?.patientId,
                  // patientName: selectedPatientName || '--',
                  patientName: element?.patientFullName || '--',
                  assignedById: element?.assignedById,
                  assignedByName: element?.assignedByName,
                  assignedToId: element?.assignedTo,
                  assignedToName: element?.assignedToName,
                  // assignedUserName: selectedUserName || '--',
                  assignedUserName: element?.assignedToName || '--',
                  // assignedBranchName: selectedBranchName || '--',
                  assignedBranchName: element?.locationName || '--',
                  mode: element?.modeCode,
                  templateId: element?.templateCode,
                  documentIdV1: element?.docId,
                  statusId:element?.statusId,
                  statusName:element?.statusName
                });
            });
          this.loaderService.hideLoader();
          this.scheduleObj?.refreshEvents();
        },
        (error) => {
          this.loaderService.hideLoader();
          console.error('Error fetching Schedule details:', error);
        }
      );
    // this.scheduleObj?.addEvent(eventData);

    return eventData;
  }
  //#endregion



  applyCategoryColorV0(args: EventRenderedArgs): void {
    // // Apply category color
    let colorCode: string = args.data.colorCode as string;
    if (colorCode) {
      args.element.style.backgroundColor = colorCode;
    }

    // Check if the current view is Month
    if (this.scheduleObj && this.scheduleObj.currentView === 'Month') {
      // Customize the event details here
      const eventDetails = args.element.querySelector('.e-subject');
      if (eventDetails) {
        // Example: Show only the event title (assuming the title and identifier are separated by a semicolon)
        const titleAndIdentifier = eventDetails.innerHTML;
        const title = titleAndIdentifier.split(';')[0].trim();
        eventDetails.innerHTML = title;
      }
    }

    //  Apply category color based on some condition (customize this part)
    let color = this.colorCode('Scheduled');
    // eventDetails.style.backgroundColor = color;
    args.element.style.backgroundColor = color;
  }

  applyCategoryColorV1(args: EventRenderedArgs): void {
    let categoryColor: string = args.data.colorCode as string;
    if (!args.element || !categoryColor) {
      return;
    }
    if (this.scheduleObj.currentView === 'Agenda') {
      (args.element.firstChild as HTMLElement).style.borderLeftColor =
        categoryColor;
    } else {
      args.element.style.backgroundColor = categoryColor;
    }

    // Check if the current view is Month
    if (
      this.scheduleObj &&
      (this.scheduleObj.currentView === 'Month' ||
        this.scheduleObj.currentView === 'Agenda')
    ) {
      // Customize the event details here
      const eventDetails = args.element.querySelector('.e-subject');
      if (eventDetails) {
        // Example: Show only the event title (assuming the title and identifier are separated by a semicolon)
        const titleAndIdentifier = eventDetails.innerHTML;
        const title = titleAndIdentifier.split(';')[0].trim();
        eventDetails.innerHTML = title;
      }
    }
  }

  updateBackgroundColor(colorCode: string): string {
    if (this.isValidColorCode(colorCode)) {
      return colorCode;
    } else {
      return '#20b2aa'; // default to light Sea Green if colorCode is invalid
    }
  }

  updateTextColorV2(colorCode: string): string {
    if (this.isValidColorCode(colorCode)) {
      return this.getContrastingTextColor(colorCode);
    } else {
      return '#000000'; // default to black if colorCode is invalid
    }
  }

  isValidColorCode(colorCode: string): boolean {
    // Check if colorCode is a valid hex color code
    const regex = /^#([0-9A-F]{3}){1,2}$/i;
    return regex.test(colorCode);
  }

  updateTextColor(colorCode: string) {
    if (colorCode) {
      return this.getContrastingTextColor(colorCode);
    } else {
      return '#000000'; // default to black
    }
  }

  getContrastingTextColor(hexColor: string): string {
    if (!hexColor) return '#000000'; // default to black if no color provided

    // Remove the hash if present
    hexColor = hexColor.replace('#', '');

    // Convert to RGB
    const r = parseInt(hexColor.substring(0, 2), 16);
    const g = parseInt(hexColor.substring(2, 4), 16);
    const b = parseInt(hexColor.substring(4, 6), 16);

    // Calculate luminance
    const luminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255;

    // If luminance is high, return dark color (black), otherwise return light color (white)
    return luminance > 0.5 ? '#000000' : '#FFFFFF';
  }
  applyCategoryColor(args: EventRenderedArgs): void {
    const eventData = args.data;
    const eventElement = args.element;

    eventElement.querySelector('.e-subject').innerHTML = `
        Status &nbsp;: ${eventData?.status} <br>
        Patient : ${eventData?.patientName} <br>
        Technician &nbsp;: ${eventData?.assignedUserName} <br>
        Location   : ${eventData?.assignedBranchName}
      `;

    let categoryColor: string = args.data.colorCode as string;
    if (!args.element || !categoryColor) {
      return;
    }

    if (this.scheduleObj.currentView === 'Agenda') {
      // Customize the style for Agenda view
      (args.element.firstChild as HTMLElement).style.borderLeftColor =
        categoryColor;
    } else {
      // Customize the style for other views
      args.element.style.backgroundColor =
        this.updateBackgroundColor(categoryColor);
      args.element.style.color = this.updateTextColor(categoryColor);
    }

    // Check if the current view is 'Agenda'
    if (this.scheduleObj.currentView === 'Agenda') {
      // Find the event subject and location elements
      const eventSubject = args.element.querySelector('.e-subject');
      const eventLocation = args.element.querySelector('.e-location');

      // Check if both subject and location elements exist
      if (eventSubject && eventLocation) {
        // Remove the location element from the event display
        eventLocation.remove();
      }

      // Get the text content of the subject element and remove the last comma if it exists
      const subjectText = eventSubject?.textContent?.trim().replace(/,$/, '');
      // Update the subject element's text content with the modified text (without the last comma)
      if (subjectText) {
        eventSubject.textContent = subjectText;
      }
    }

    if (this.scheduleObj.currentView === 'Month') {
      // Customize the event details here for Agenda and Month views
      const eventDetails = args.element.querySelector('.e-subject');
      if (eventDetails) {
        const titleAndIdentifier = eventDetails.innerHTML;
        const title = titleAndIdentifier.split(';')[0].trim();
        eventDetails.innerHTML = title;
      }
    }

    // Check if the current view is 'Month'
    if (
      this.scheduleObj.currentView === 'Week' ||
      this.scheduleObj.currentView === 'Day' ||
      this.scheduleObj.currentView === 'WorkWeek'
    ) {
      // Find the event container and then find the location element within it
      const eventContainer = args.element.querySelector(
        '.e-appointment-details'
      );
      const eventLocationMonth = eventContainer?.querySelector('.e-location');

      // Check if the location element exists in the month view within the container
      if (eventLocationMonth) {
        // Remove the location element from the event display in the month view
        eventLocationMonth.remove();
      }
    }
  }

  getSelectedPatientDetailsV1(patientId: string) {
    if (patientId !== null && patientId !== undefined && patientId !== '') {
      this.inboundDocumentService
        .getScheduleDetailsByPatientID(patientId)
        .subscribe(
          (response) => {
            this.patientId = response.patientId || '';
            this.branchId = response.locationId || '';
            this.techId = response.tech || '';
            this.typeId = response.type || '';
            this.resourceId = response.resource || '';
            this.home = response?.homePhoneNo ?? '';
            this.cell = response?.cellNo ?? '';
            this.work = response?.workNo ?? '';
            this.email = response?.email ?? '';
            this.docId = response?.docId || defaultGuid;
            this.setupInHome = response?.setUpInHome ?? false;
          },
          (error) => {
            console.error('Error fetching patient details:', error);
          }
        );
    }
  }

  getSelectedPatientDetails(patientId: string): Observable<any> {
    return new Observable((observer) => {
      if (patientId !== null && patientId !== undefined && patientId !== '') {
        this.inboundDocumentService
          .getScheduleDetailsByPatientID(patientId)
          .subscribe(
            (response) => {
              this.patientId = response.patientId || '';
              this.branchId = response.locationId || '';
              this.techId = response.tech || '';
              this.typeId = response.type || '';
              this.resourceId = response.resource || '';
              this.home = response?.homePhoneNo ?? '';
              this.cell = response?.cellNo ?? '';
              this.work = response?.workNo ?? '';
              this.email = response?.email ?? '';
              this.docId = response?.docId || defaultGuid;
              this.setupInHome = response?.setUpInHome ?? false;
            },
            (error) => {
              console.error('Error fetching patient details:', error);
              observer.next(); // Notify the promise that the operation is complete
              observer.complete();
            }
          );
      }
      observer.next(); // Notify the promise that the operation is complete
      observer.complete();
    });
  }

  getPatient(value: any, patientId: string) {
    this.getViewStartEnd(new Date());
    this.getUserByTenantV1();
    this.getBranchList();
    if (this.patientChartApiSubscription) {
      this.patientChartApiSubscription.unsubscribe();
    }
    this.patientChartApiSubscription = this.mmOrderService
      .getPatientChartNoV3BySSearchParamsAndPatient(value, patientId)
      .subscribe(
        (response) => {
          this.patientDet = [];
          response?.forEach((e, i) => {
            this.patientDet?.push({
              name: e?.ddValue,
              id: e?.patientId,
              chartId: e?.chartId,
              patientName: e?.patientName,
            });
          });
          // setTimeout(() => {
          //   this.setEventSettings();
          // }, 1000);
          return this.patientDet;
        },
        (err) => {
          setTimeout(() => {
            this.setEventSettings();
          }, 1000);
        }
      );
  }
  hasvaluePatientSearchForm() {
    this.scheduleSearchForm.valueChanges.subscribe(() => {
      const hasValue = Object.values(this.scheduleSearchForm.controls).some(
        (control) => control.value
      );
      this.saveButtonDisabled = !hasValue;
    });
  }

  fnSearchCalendarList() {
    this.setEventSettings();
  }

  homeInputEle: HTMLInputElement;

  onPopupOpen(args) {
    const eventObj: any = args.data instanceof Array ? args.data[0] : args.data;
    if (
      (args.target &&
        !args.target.classList.contains('e-appointment') &&
        args.type === 'QuickInfo') ||
      args.type === 'Editor'
    ) {
      args.cancel = this.onEventCheck(args);
      if (args.cancel === true && eventObj?.QGuid === undefined) {
        args.cancel = true;
      } else {
        args.cancel = true;
        this.openAddScheduleModal(args);
      }
    }
  }

  public onEventCheck(args: any): boolean {

    const currentDate = new Date();

    const currentDateV1 = currentDate.toLocaleString('en-US', { timeZone: 'America/Chicago' });

    const selectedDate = args.data?.StartTime;

    const selectedDateChicago = new Date(selectedDate);

    const seleteDateV1 = selectedDateChicago.toLocaleString('en-US');

    const seleteDateV2 = new Date(seleteDateV1);
    const currentDateV2 = new Date(currentDateV1);

    return seleteDateV2 < currentDateV2;
  }


  //GET Template DROP DOWN
  getTemplate(modeId: number[]) {
    if (this.templateApiSubscription) {
      this.templateApiSubscription.unsubscribe();
    }
    this.templateApiSubscription = this.inboundDocumentService
      .getListSMSTemplateId(modeId)
      .subscribe(
        (response) => {
          this.templateDrp = response?.items;
        },
        (err) => { }
      );
  }

  //To Check the View in Header View which is clicked
  onNavigating(args) {
    if (args.action === 'date') {
      if (this.currentMonth === 'Week') {
        this.currentMonth = 'Week';
        this.getViewStartEnd(args.currentDate);
      }else if(this.currentMonth === 'Day'){
        this.currentMonth === 'Day';
        this.getViewStartEnd(args.currentDate);
      } else {
        this.currentMonth = 'Month';
        this.getViewStartEnd(args.currentDate);
      }
    } else if (args.action === 'view') {
      if (args.currentView === 'Month') {
        this.currentMonth = args.currentView;
        this.getViewStartEnd(args.currentDate);
      } else if (args.currentView === 'Week') {
        this.currentMonth = args.currentView;
        this.getViewStartEnd(args.currentDate);
      } else if (args.currentView === 'WorkWeek') {
        this.currentMonth = args.currentView;
        this.getViewStartEnd(args.currentDate);
      } else if (args.currentView === 'Day') {
        this.currentMonth = args.currentView;
        this.getViewStartEnd(args.currentDate);
      }
    }
  }

  //To find Start and End date of the view
  getViewStartEnd(date) {
    let start, end;

    let month = date.getMonth();
  
    switch (this.currentMonth) {
      case "Day":
        this.isMonth = 0;
        start = new Date(date);
        end = new Date(date);
        // end = addDays(new Date(date), 1);
        break;
      case "Week":
        this.isMonth = 0;
        start = getWeekFirstDate(new Date(date), 0);
        end = addDays(getWeekLastDate(new Date(date), 0), 1);
        break;
      case "WorkWeek":
        this.isMonth = 0;
        start = addDays(getWeekFirstDate(new Date(date), 0), 1);
        end = getWeekLastDate(new Date(date), 0);
        break;
      case "Month":
        this.isMonth = 1;
        start = getWeekFirstDate(new Date(date.getFullYear(), month, 1), 0);
        end = addDays(getWeekLastDate(new Date(date.getFullYear(), month + 1, 0), 1), 1);
        break;
    }
  
    if (this.currentMonth === "Day" || this.currentMonth === "Week" || this.currentMonth === "WorkWeek") {
      this.scheduleStartDate  = this.datepipe.transform(start, 'yyyy-MM-dd');
      this.scheduleEndDate  = this.datepipe.transform(end, 'yyyy-MM-dd');
      setTimeout(() => {
        this.setEventSettings();
      }, 500);
    } else if (this.currentMonth === "Month") {
      this.scheduleMonthDate = this.datepipe.transform(new Date(date.getFullYear(), month, 1), 'yyyy-MM-dd');
      setTimeout(() => {
        this.setEventSettings();
      }, 500);
    }
  
    return { start, end };
  }

  // editor popup window
  public showQuickInfo: Boolean = false;
  onCellClick(args: CellClickEventArgs): void {
    // args.cancel = true;
    this.scheduleObj?.openEditor(args, 'Add');
  }
  onEventClick(args: EventClickArgs): void {
    if (!(args.event as any).RecurrenceRule) {
      this.scheduleObj?.openEditor(args.event, 'Save');
    } else {
      this.scheduleObj?.quickPopup?.openRecurrenceAlert();
    }
  }

  colorCode(Status: string) {
    let scalender_status_color_code = calender_status_color_code;
    let scalender_status_code = calender_status_code;
    let returntext = calender_status_color_code.Scheduled;
    if (Status === scalender_status_code[1]) {
      returntext = calender_status_color_code.Scheduled;
    } else if (Status === scalender_status_code[2]) {
      returntext = calender_status_color_code.Rescheduled;
    } else if (Status === scalender_status_code[3]) {
      returntext = calender_status_color_code.Completed;
    } else if (Status === scalender_status_code[4]) {
      returntext = calender_status_color_code.Cancelled;
    } else {
      returntext = calender_status_color_code.Scheduled;
    }
    return returntext;
  }

  toggle: boolean = false;


  //#region Save Schedule
  async onActionComplete(args: ActionEventArgs) {
 
    if (this.isLunchTime === true) {
      Swal.fire({
        icon: 'warning',
        title: 'Between 12 PM to 1 PM is Lunch time',
        text: 'Are You Sure Want to Schedule?',
        showCancelButton: true,
        cancelButtonColor: '#dc3741',
        confirmButtonText: 'Yes',
      }).then((result) => {
        if (result.value) {
          this.saveSchedule(args);
        } else {
          this.setEventSettings();
        }
        this.isLunchTime = false;
      });
    } else {
      this.saveSchedule(args);
      this.isLunchTime = false;
    }

  }

  //! calender actions
  async saveSchedule(args: ActionEventArgs) {
    if (args.requestType === 'eventCreated') {
      let eventCreatedPatientId =
        args?.addedRecords?.[0]?.patientId !== undefined &&
          args?.addedRecords?.[0]?.patientId !== ''
          ? args?.addedRecords?.[0]?.patientId
          : null;

      //   if (eventCreatedPatientId === null)
      // {
      // Wrap the call to getSelectedPatientDetails in a promise
      await new Promise<void>((resolve) => {
        this.getSelectedPatientDetails(eventCreatedPatientId).subscribe(() => {
          resolve();
        });
      });

      let calender: CreateUpdateCalendarDTO = {
        roleId: this.roleId,
        roleName: this.roleName,
        assignedToId: this.userId,
        assignedToName: this.userName,
        calendarDetails: [
          {
            title: args?.addedRecords?.[0]?.Subject,
            fromTime: args?.addedRecords?.[0]?.StartTime,
            toTime: args?.addedRecords?.[0]?.EndTime,
            status: args?.addedRecords?.[0]?.OwnerId ?? '',
            description: args?.addedRecords?.[0]?.Description ?? '',
            locationId: args.addedRecords[0].branchId || null,
            patientId: args.addedRecords[0].patientId || defaultGuid, // Needs to change once API ready
            // patientId:eventCreatedPatientId,
            // userID: args.addedRecords[0].techId || null,
            userID: args.addedRecords[0].userDesID || null,
            type: args?.addedRecords?.[0]?.typeId ?? this.typeId,
            resource: args?.addedRecords?.[0]?.resourceId || null,
            homePhoneNo:
              this.isValidHomeNumber === true
                ? args?.addedRecords?.[0]?.HomeText
                : null,
            cellNo:
              this.isValidCellNumber === true
                ? args?.addedRecords?.[0]?.CellText
                : null,
            workNo:
              this.isValidWorkNumber === true
                ? args?.addedRecords?.[0]?.WorkText
                : null,
            email:
              (this.isValidEmail === true &&
                args?.addedRecords?.[0]?.EmailText) ||
              null,
            setUpInHome: args?.addedRecords?.[0]?.Setup,
            calendarCode: '',
            calendarId: args?.addedRecords?.[0]?.Id,
            startTimeZone: '',
            endTimeZone: '',
            // colorCode: '#20b2aa',
            colorCode: this.colorCode(args?.addedRecords?.[0]?.OwnerId ?? ''),
            docId: this.docId,
          },
        ],
        modeCode: args?.addedRecords?.[0]?.mode || defaultGuid,
        // templateCode: args?.addedRecords?.[0]?.templateId || null,
        // templateContent: args?.addedRecords?.[0]?.templateName || null,

        // templateCode: templateCode || null,
        // templateContent: templateContent || null,

        templateCode: this.selectedTemplateId,
        templateContent: this.selectedTemplateName,
      };
      // this.inboundDocumentService.createCalendarByInput(calender).subscribe(
      this.inboundDocumentService.createCalendarByInput(calender).subscribe(
        (response) => {
          this.toggle = !this.toggle;
          this.eventSettings = {
            dataSource: this.getTableData(),
          };
          // this.getTableData();
        },
        (err) => {
          args.cancel = false;
          const data: HttpErrorResponse = err;
          Swal.fire({
            icon: 'info',
            text: data?.error?.error?.message,
          });
          this.eventSettings = {
            dataSource: this.getTableData(),
          };
        }
      );
    } else if (args.requestType === 'eventChanged') {
      const isModeOfcommunicaion = args?.changedRecords?.[0]?.mode;
      if (isModeOfcommunicaion.includes(4)) {
        this.updateScheduledEvents(false, args);
      } else {
        Swal.fire({
          icon: 'info',
          title: 'Do you want to Send SMS/Email Again?',
          showCancelButton: true,
          confirmButtonText: 'Yes',
          denyButtonText: 'No',
          showDenyButton: true,
          customClass: {
            actions: 'my-actions',
            cancelButton: 'order-1 right-gap',
            confirmButton: 'order-2',
            denyButton: 'order-3',
          },
        }).then((result) => {
          if (result.isConfirmed) {
            this.updateScheduledEvents(true, args);

          } else if (result.isDenied) {
            this.updateScheduledEvents(false, args);
          }
          else {
            this.triggerGetTabel();
          }
        })
      }

    } else if (args?.requestType === 'eventRemoved') {
      this.inboundDocumentService
        .deleteCalendarByIdAndCalendarDetailsId(
          args?.deletedRecords?.[0]?.QGuid,
          args?.deletedRecords?.[0]?.calendarDetailsId
        )
        .subscribe(
          (response) => { },
          (err) => {
            const data: HttpErrorResponse = err;
            this.eventSettings = {
              dataSource: this.getTableData(),
            };
          }
        );
    }
  }

  getTypeObjectById(id: number): any {
    if (this.templateDrp) {
      return this.templateDrp.find(temp => temp.templateCode === id);
    } else {
      return null;
    }
  }

  //#region Drag and Drop Updtaed Events
  async updateScheduledEvents(isSmsEmailSend: boolean, args: ActionEventArgs) {

    this.loaderService.showLoader();
    const selectedTemplate = this.getTypeObjectById(args?.changedRecords?.[0]?.templateId);

    let eventChangedPatientId =
      args?.changedRecords?.[0]?.patientId !== undefined &&
        args?.changedRecords?.[0]?.patientId !== ''
        ? args?.changedRecords?.[0]?.patientId
        : null;

    if (!eventChangedPatientId === null)
      // Wrap the call to getSelectedPatientDetails in a promise
      await new Promise<void>((resolve) => {
        this.getSelectedPatientDetails(eventChangedPatientId).subscribe(
          () => {
            resolve();
          }
        );
      });

    let calender: CreateUpdateScheduleDTO = {
      assignedToId: args?.changedRecords?.[0]?.user || null,
      modeCode: args?.changedRecords?.[0]?.mode || [0],
      templateCode: args?.changedRecords?.[0]?.templateId || null,
      templateContent: selectedTemplate?.template || null,
      calendarDetails: [
        {
          docId: this.docId || defaultGuid,
          locationId:
          args?.changedRecords?.[0]?.Location !== undefined &&
            args?.changedRecords?.[0]?.Location !== ''
            ? args?.changedRecords?.[0]?.Location
            : null,
          patientId: args?.changedRecords?.[0]?.Patient || defaultGuid,
          type:
          args?.changedRecords?.[0]?.type !== undefined &&
            args?.changedRecords?.[0]?.type !== ''
            ? args?.changedRecords?.[0]?.type
            : null,
        typeId: args?.changedRecords?.[0]?.typeId !== undefined &&
          args?.changedRecords?.[0]?.typeId !== ''
          ? args?.changedRecords?.[0]?.typeId
          : null,
          homePhoneNo: args?.changedRecords?.[0]?.phone,
          cellNo:
            this.isValidCellNumber === true
              ? args?.changedRecords?.[0]?.cell
              : null,
          workNo: args?.changedRecords?.[0]?.work,
          email: args?.changedRecords?.[0]?.email,
          providerId:  args?.changedRecords?.[0]?.resource !== undefined &&
          args?.changedRecords?.[0]?.resource !== ''
          ? args?.changedRecords?.[0]?.resource
          : null,
          fromTime: args?.changedRecords?.[0]?.StartTime,
          toTime: args?.changedRecords?.[0]?.EndTime,
          description: args?.changedRecords?.[0]?.Description ?? '',
          setUpInHome: args?.changedRecords?.[0]?.setupInHome,
          title: args?.changedRecords?.[0]?.Subject,
          statusId: args?.changedRecords?.[0]?.statusId,
          statusName: args?.changedRecords?.[0]?.statusName,
        },
      ],
      isMailorMessageSend: isSmsEmailSend,
    };
    
    // this.inboundDocumentService
    //   .updateCalendarByIdAndInput(args?.changedRecords?.[0]?.QGuid, calender)
    this.scheduledetailsService.updateScheduleByIdAndInput(args?.changedRecords?.[0]?.QGuid, calender)
      .subscribe(
        (response) => {
          this.toggle = !this.toggle;
          this.eventSettings = {
            dataSource: this.getTableData(),
          };
        },
        (err) => {
          args.cancel = false;

          const data: HttpErrorResponse = err;
          Swal.fire({
            icon: 'info',
            text: data?.error?.error?.message,
          });
          this.eventSettings = {
            dataSource: this.getTableData(),
          };
        }
      );
  }

  //#endregion
  @ViewChild('addButton')
  public addButton: ButtonComponent;
  public onButtonClick(): void {
    let data: Object[] = [
      {
        Id: 0,
        Subject: 'Conference',
        StartTime: new Date(2018, 1, 12, 9, 0),
        EndTime: new Date(2018, 1, 12, 10, 0),
        IsAllDay: true,
      },
    ];
    // this.scheduleObj?.addEvent(data);
    // this.addButton.element?.setAttribute('disabled','true');
  }

  setRequiredFields(element: HTMLElement) {
    const formFields = element.querySelectorAll(
      '.e-schedule-form .custom-field-container'
    );
    formFields.forEach((field) => {
      (field as HTMLInputElement).required = true;
    });
  }

  openAddScheduleModal(args) {
    const config: MatDialogConfig = {
      disableClose: true,
      width: '47vw',
      maxWidth: '47vw',
      panelClass: 'responsive-modal',
      data: {
        scheduleData: args,
        patientId: this.patientId,
        // docId: this.docId,
        docId: args?.data?.documentIdV1,
        patientIdViaURL: this.patientIdViaURL,
        calenderDetailId: this.calenderDetailId,
      },
    };
    const dialogRef = this.dialog.open(
      CalenderScheduleAddModalComponent,
      config
    );
    const closeDialog = dialogRef.afterClosed().subscribe(() => { });
  }

  // openAddScheduleModal(args) {
  //   const config: MatDialogConfig = {
  //     disableClose: true,
  //     width: '60vw',
  //     maxWidth: '60vw',
  //     // height: '80vh',
  //     // maxHeight: 'auto',
  //     // minHeight:'auto',
  //     panelClass: 'responsive-modal',
  //     data: {
  //       scheduleData: args,
  //       patientId: this.patientId,
  //       // docId: this.docId,
  //       docId: args?.data?.documentIdV1,
  //       patientIdViaURL: this.patientIdViaURL,
  //       calenderDetailId: this.calenderDetailId,
  //     },
  //   };
  //   const dialogRef = this.dialog.open(
  //     CalendarScheduleAddModalV1NewComponent,
  //     config
  //   );
  //   const closeDialog = dialogRef.afterClosed().subscribe(() => {});
  // }

  triggerGetTabel() {
    this.eventSettings = {
      dataSource: this.getTableData(),
    };
  }

  public onContextMenuBeforeOpen(args: BeforeOpenCloseMenuEventArgs): void {
    const newEventElement: HTMLElement = document.querySelector(
      '.e-new-event'
    ) as HTMLElement;
    if (newEventElement) {
      remove(newEventElement);
      removeClass(
        [document.querySelector('.e-selected-cell')],
        'e-selected-cell'
      );
    }
    this.scheduleObj.closeQuickInfoPopup();
    const targetElement: HTMLElement = args.event.target as HTMLElement;
    if (closest(targetElement, '.e-contextmenu')) {
      return;
    }
    this.selectedTarget = closest(
      targetElement,
      '.e-appointment,.e-work-cells,' +
      '.e-vertical-view .e-date-header-wrap .e-all-day-cells,.e-vertical-view .e-date-header-wrap .e-header-cells'
    );
    if (isNullOrUndefined(this.selectedTarget)) {
      args.cancel = true;
      return;
    }
    if (this.selectedTarget.classList.contains('e-appointment')) {
      const eventObj: Record<string, any> = this.scheduleObj.getEventDetails(
        this.selectedTarget
      ) as Record<string, any>;

      if (
        eventObj.documentIdV1 === defaultGuid ||
        eventObj.documentIdV1 === null
      ) {
        this.menuObj.enableItems(['Document Imaging'], false);
      } else {
        this.menuObj.enableItems(['Document Imaging'], true);
      }

      if (eventObj.RecurrenceRule) {
        this.menuObj.showItems(
          [
            'Save',
            'Delete',
            'StartNewOrder',
            'emailconfirmation',
            'AddNotes',
            'ViewNotes',
            'ViewPatientLeadger',
            'DocumentImaging',
          ],
          true
        );
      } else {
        this.menuObj.showItems(
          [
            'Save',
            'Delete',
            'StartNewOrder',
            'emailconfirmation',
            'AddNotes',
            'ViewNotes',
            'ViewPatientLeadger',
            'DocumentImaging',
          ],
          true
        );
      }
      return;
    } else if (
      (this.selectedTarget.classList.contains('e-work-cells') ||
        this.selectedTarget.classList.contains('e-all-day-cells')) &&
      !this.selectedTarget.classList.contains('e-selected-cell')
    ) {
      removeClass(
        [].slice.call(
          this.scheduleObj.element.querySelectorAll('.e-selected-cell')
        ),
        'e-selected-cell'
      );
      this.selectedTarget.classList.add('e-selected-cell');
      this.selectedTarget.setAttribute('aria-selected', 'true');
    }
    // this.menuObj.showItems(['Save', 'Delete','StartNewOrder','emailconfirmation','AddNotes','ViewNotes','ViewPatientLeadger','DocumentImaging'], true);
    this.menuObj.hideItems(
      [
        'Save',
        'Delete',
        'StartNewOrder',
        'emailconfirmation',
        'AddNotes',
        'ViewNotes',
        'ViewPatientLeadger',
        'DocumentImaging',
      ],
      true
    );
  }

  public onMenuItemSelect(args: MenuEventArgs): void {
    const selectedMenuItem: string = args.item.id;
    let eventObj: Record<string, any>;
    if (this.selectedTarget.classList.contains('e-appointment')) {
      eventObj = this.scheduleObj.getEventDetails(
        this.selectedTarget
      ) as Record<string, any>;
    }
    switch (selectedMenuItem) {
      case 'StartNewOrder':
        this.openStratNewOrder(eventObj);
        break;
      case 'AddNotes':
        this.openAddNotesPopUp(eventObj);
        break;
      case 'Save':
        this.scheduleObj.openEditor(eventObj, selectedMenuItem);
        break;
      case 'Delete':
        // this.scheduleObj.deleteEvent(eventObj);
        // this.setEventSettings();
        this.deleteSchedule(eventObj?.calendarDetailsId);
        break;
      case 'ViewNotes':
        this.openViewNotesPopup(eventObj);
        break;
      case 'emailconfirmation':
        this.openEmailPrintLetter(eventObj);
        break;
      case 'ViewPatientLeadger':
        this.viewPatientLedger(eventObj);
        break;
      case 'DocumentImaging':
        this.viewDocumentImage(eventObj);
        break;
    }
  }

  //Delete Schedule
  deleteSchedule(eventObj:any) {
    Swal.fire({
      title: 'Are you sure you want to Delete?',
      text: "You won't be able to retrieve this data!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#34c38f',
      cancelButtonColor: '#f46a6a',
      confirmButtonText: 'Yes, delete it!',
    }).then((result) => {
      if (result.value) {
        this.scheduledetailsService
          .deleteScheduleById(eventObj)
          .subscribe(
            (response) => {
              this.setEventSettings();
            },
            (err) => {
              const data: HttpErrorResponse = err;
              Swal.fire({
                icon: 'info',
                text: data?.error?.error?.message,
              });
              this.setEventSettings();
            }
          );
      }
    });
  }

  openStratNewOrder(eventObj: any) {
    const url = '/#orders/saleOrder/' + eventObj.Patient;
    window.open(url, '_blank');
  }

  openEmailPrintLetter(eventObj: any) {
    const url = '/#emailprintletter/' + eventObj.Patient;
    window.open(url, '_blank');
  }

  openAddNotesPopUp(eventObj: any) {
    const config: MatDialogConfig = {
      disableClose: true,
      minHeight: '30vh',
      minWidth: '50vw',
      data: {
        noteType: 'patient', // 'Order Note',
        notesId: '00000000-0000-0000-0000-000000000000',
        patientId: eventObj.Patient,
        // defaultFaxId: eventObj.Patient,
        isCallNote: false,
        isPending: false,
        docId: eventObj?.documentIdV1,
        inboundStatusShortCodeId: defaultGuid,
        selectedNode: defaultGuid,
        addnotes: true,
      },
    };
    const dialogRef = this.dialog.open(AddNoteTabComponent, config);

    const closeDialog = dialogRef.afterClosed().subscribe(
      () => { },
      (err) => {
        // const data: HttpErrorResponse = err;
        // Swal.fire({
        //   icon: 'info',
        //   text: data?.error?.error?.message,
        // });
      }
    );
    this.subscription$.push(closeDialog);
  }

  openViewNotesPopup(eventObj: any) {
    const config: MatDialogConfig = {
      disableClose: true,
      width: '70vw',
      maxWidth: '70vw',
      data: {
        patientId: eventObj.Patient,
        pageType: 'Patient Note', // this.pageType, //Notes Table Popup Page
        isAddVisible: false,
      },
    };
    const dialogRef = this.dialog.open(PatientCommonNotesComponent, config);

    dialogRef.afterClosed().subscribe(
      () => {
        //this.getReminderTableData();
      },
      (err: HttpErrorResponse) => {
        // const data: HttpErrorResponse = err;
        // Swal.fire({
        //   icon: 'info',
        //   text: data?.error?.error?.message,
        // });
      }
    );
  }

  viewPatientLedger(eventObj: any) {
    this.dialog.open(ViewPatientLedgerComponent, {
      width: '100vw',
      height: '100vh',
      maxWidth: '100vw',
      maxHeight: '100vh',
      panelClass: 'fullscreen-dialog-panel',
      disableClose: true,
      data: {
        patientId: eventObj.Patient,
      },
    });
    this.dialog.afterAllClosed.subscribe((resposne) => { });
  }

  viewDocumentImage(eventObj: any) {
    const url = '/#view_document_tiff/' + eventObj?.documentIdV1 + '/' + true;
    window.open(url, '_blank');
  }

  public onTreeDrag(event: any): void {
    alert('Drag');
    // if (this.scheduleObj.isAdaptive) {
    //   const classElement: HTMLElement = this.scheduleObj.element.querySelector('.e-device-hover');
    //   if (classElement) {
    //     classElement.classList.remove('e-device-hover');
    //   }
    //   if (event.target.classList.contains('e-work-cells')) {
    //     addClass([event.target], 'e-device-hover');
    //   }
    // }
  }

  public onTreeDragStop(event: DragAndDropEventArgs): void {
    alert("Started dragging");
    const treeElement: Element = closest(event.target, '.e-treeview') as Element;
    const classElement: HTMLElement = this.scheduleObj.element.querySelector('.e-device-hover');
    if (classElement) {
      classElement.classList.remove('e-device-hover');
    }
    if (!treeElement) {
      event.cancel = true;
      const scheduleElement: Element = closest(event.target, '.e-content-wrap') as Element;
      if (scheduleElement) {
        const treeviewData: Record<string, any>[] = this.treeObj.fields.dataSource as Record<string, any>[];
        if (event.target.classList.contains('e-work-cells')) {
          const filteredData: Record<string, any>[] = treeviewData.filter((item: any) =>
            item.Id === parseInt(event.draggedNodeData.id as string, 10));
          const cellData: CellClickEventArgs = this.scheduleObj.getCellDetails(event.target);
          const resourceDetails: ResourceDetails = this.scheduleObj.getResourcesByIndex(cellData.groupIndex);
          const eventData: Record<string, any> = {
            Name: filteredData[0].Name,
            StartTime: cellData.startTime,
            EndTime: cellData.endTime,
            IsAllDay: cellData.isAllDay,
            Description: filteredData[0].Description,
            DepartmentID: resourceDetails.resourceData.GroupId,
            ConsultantID: resourceDetails.resourceData.Id
          };
          this.scheduleObj.openEditor(eventData, 'Add', true);
          this.isTreeItemDropped = true;
          this.draggedItemId = event.draggedNodeData.id as string;
        }
      }
    }
    document.body.classList.remove('e-disble-not-allowed');
  }

  onDragStart(args: any): void {
    this.getTemplate(args?.data?.mode);
  }

  onResizeStart(args: ResizeEventArgs): void {
    this.getTemplate(args?.data?.mode);
  }

  onDragStop(args: DragEventArgs): void {
    this.checkLunchTime(args);
  }

  onResizeStop(args: ResizeEventArgs): void {
    this.checkLunchTime(args);
  }

  checkLunchTime(args: DragEventArgs | ResizeEventArgs): void {
    const startTime = new Date(args.data['StartTime']);
    const endTime = new Date(args.data['EndTime']);

    const lunchStart = new Date(startTime);
    lunchStart.setHours(12, 0, 0, 0);

    const lunchEnd = new Date(startTime);
    lunchEnd.setHours(13, 0, 0, 0);

    if ((startTime >= lunchStart && startTime < lunchEnd) || (endTime > lunchStart && endTime <= lunchEnd) || (startTime < lunchStart && endTime > lunchEnd)) {
      this.isLunchTime = true;
    }else{
      this.isLunchTime = false;
    }
  }
}
export interface User {
  userId?: string;
  userDesignation?: string;
}

export interface Branch {
  id?: string;
  organizationUnitName?: string;
}

export interface TypeDrp {
  aptClassId?: string;
  description?: string;
}