<form [formGroup]="ClinicalForm" class="mt-4">
  <!--Form-->
  <!--Important Fields-->
  <mat-card class="mr-3">
    <div class="row">
      <!-- Referring Providers -->
      <div class="col-lg-6">
        <!--New Referring Provider Doctor button-->
        <div class="row">
          <div class="col-md-6">
            <h4 class="customThemeClass"><b>Referring Provider</b></h4>
          </div>
          <div class="col-md-6 text-right">
            <button type="button"[disabled]="!editFormValues" class="buttonColor btn-sm" (click)="openDoctorModal()">
              <i class="fa fa-plus" matTooltip="Click to Add Referring Provider"></i>
            </button>
          </div>
        </div>
        <!-- End -->
        <mat-card-content>
          <div class="row">
            <!--Type-->
            <mat-form-field class="col-6 mt-1">
              <mat-label>Type</mat-label>
              <mat-select formControlName="txtReferringProviderType" [(value)]="referringProviderType" maxlength="40">
                <mat-option *ngFor="let type of drpReferringtypeLoop" [value]="type.renderingProviderShortCodeId">
                  {{ type.renderingProviderName }}
                </mat-option>
              </mat-select>
              <button mat-button [disabled]="!editFormValues" (click)="referringProviderType = ''; $event.stopPropagation()"
                *ngIf="referringProviderType" (click)="clearReferringProviderType()" matSuffix mat-icon-button>
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field>

            <!--Facility-->
            <!-- <mat-form-field class="col-6">
              <mat-label>Facility</mat-label>
              <mat-select [(value)]="referringProviderFacility" formControlName="txtReferringProviderFacility">
                <mat-option *ngFor="let facility of drpReferringProviderFacility"
                  [value]="facility.facilityId">
                  {{ facility.facilityName }}
                </mat-option>
              </mat-select>
              <button mat-button [disabled]="!editFormValues" (click)="
                  referringProviderFacility = ''; $event.stopPropagation()
                " (click)="clearReferringProviderFacility()" *ngIf="referringProviderFacility" matSuffix
                mat-icon-button>
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field> -->

            <!--Referral Practice-->
            <mat-form-field class="col-6">
              <mat-label>Referral Practice</mat-label>
              <mat-select [(value)]="referringProviderFacility"  formControlName="txtReferringProviderFacility">
                <mat-option>
                  <ngx-mat-select-search [formControl]="RefTypeControl" placeholderLabel="Search"
                      noEntriesFoundLabel="No Matches found"
                      formControlName="txtRefType"></ngx-mat-select-search>
                </mat-option>

                <mat-option *ngFor="let facility of drpReferralPracticeExtened"
                  [value]="facility.id" (click)="reloadDoctor('',false)">
                  {{ facility.practiceName }}
                </mat-option>
              </mat-select>
              <button mat-button [disabled]="!editFormValues" (click)="
                  referringProviderFacility = ''; $event.stopPropagation()
                " (click)="clearReferringProviderFacility()" *ngIf="referringProviderFacility" matSuffix
                mat-icon-button>
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field>
             <!--Doctor-->
            <!-- (selectionChange)="handleReferraPracticeChange($event)" -->
            <mat-form-field class="col-6">
              <mat-label>Doctor<span class="asterisk">*</span></mat-label>
              <mat-select formControlName="txtReferringProviderDoctor" [(value)]="referringDoctor" #singleSelect >
                <mat-option>
                  <ngx-mat-select-search placeholderLabel="Search" noEntriesFoundLabel="No Matches found"
                    [formControl]="txtReferringProviderDoctorFilter"
                    formControlName="txtReferringProviderDoctorFilter"  ></ngx-mat-select-search>
                </mat-option>
                <mat-option *ngFor="
                    let doctor of filteredReferringProviderDoctorTypes "
                  [value]="doctor.id+'/'+ doctor.name">
                  {{ doctor.name }}
                </mat-option>
              </mat-select>
              <button mat-button [disabled]="!editFormValues" (click)="referringDoctor = ''; $event.stopPropagation()" *ngIf="referringDoctor"
                (click)="clearReferringDoctor()" matSuffix mat-icon-button>
                <mat-icon>close</mat-icon>
              </button>
              <mat-error class="spanAlignment" *ngIf="
                ClinicalForm?.get(
                  'txtReferringProviderDoctor'
                )?.touched &&
                ClinicalForm?.get(
                  'txtReferringProviderDoctor'
                )?.errors
              ">
                Referring Provider Doctor is a required field!
              </mat-error>
            </mat-form-field>

          </div>
        </mat-card-content>
        <br />
      </div>
      <!-- End Referring Providers -->

      <!--Primary Doctor-->
      <div class="col-lg-6">
        <!--Same as Referring Provider Button -->
        <div class="row">
          <div class="col-md-6">
            <h4 class="customThemeClass"><b>Primary Doctor</b></h4>
          </div>
          <div class="col-md-6 text-right">
            <button mat-button [disabled]="!editFormValues" class="buttonColor mr-1 font-size-12 btn-sm" (click)="sameAsReferringProviderDoctor()">
              <i class="fa fa-clone mr-1"></i>
              Same as Referring Provider
            </button>
          </div>
        </div>
        <!--Primary Doctor Dropdown-->
        <mat-card-content>
          <div class="row">
            <mat-form-field class="col-12">
              <mat-label>Primary Doctor</mat-label>
              <mat-select formControlName="txtPrimaryDoctor" [(value)]="primaryDoctor" #singleSelect>
                <mat-option>
                  <ngx-mat-select-search placeholderLabel="Search" noEntriesFoundLabel="No Matching found"
                    formControlName="txtPrimaryDoctorSearchFilter"></ngx-mat-select-search>
                </mat-option>
                <mat-option *ngFor="let doctor of filteredPrimaryDoctorTypes | async"  [value]="doctor.id+'/'+ doctor.name">
                  {{ doctor.name }}
                </mat-option>
              </mat-select>
              <button mat-button [disabled]="!editFormValues" (click)="primaryDoctor = ''; $event.stopPropagation()" *ngIf="primaryDoctor"
                (click)="clearPrimaryDoctor()" matSuffix mat-icon-button>
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field>
          </div>
        </mat-card-content>
      </div>
    </div>

    <div class="row">
      <!--Diagnosis Code ICD-9-->
      <div class="col-lg-6">
        <div class="row">
          <div class="col-md-9">
            <h4 class="customThemeClass"><b>Diagnosis Codes-ICD-9</b></h4>
          </div>
          <div class="col-md-3 text-right">
            <button type="button"[disabled]="!editFormValues"  class="buttonColor btn-sm" (click)="openIcd9Modal()">
              <i class="fa fa-plus" matTooltip="Click to Add DiagnosisCodes-9"></i>
            </button>
          </div>
        </div>
        <mat-card-content>
          <div class="row">
            <!-- <mat-form-field class="col-12">
              <mat-label>Diagnosis Code ICD-9</mat-label>
              <mat-select multiple formControlName="txtDiagnosisCodes9" [(value)]="txtIcd9" #singleSelect>
                <mat-option>
                  <ngx-mat-select-search placeholderLabel="Search" noEntriesFoundLabel="No Matches found"
                    formControlName="txtIcd9Filter"></ngx-mat-select-search>
                </mat-option>
                <mat-option *ngFor="let diagnosis9 of filteredIcd9Types | async"
                  [value]="diagnosis9?.diagnosisCode">
                  {{ diagnosis9?.customDiagnosisCode  }}
                </mat-option>
              </mat-select>
              <button mat-button [disabled]="!editFormValues" (click)="txtIcd9 = []; $event.stopPropagation()" *ngIf="txtIcd9?.length !== 0"
                matSuffix (click)="clearIcd9()" mat-icon-button>
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field> -->

            <mat-form-field class="col-12">
              <mat-label>Diagnosis Code ICD-9</mat-label>
              <mat-select formControlName="txtDiagnosisCodes9" multiple (selectionChange)="onSelectiondiagnosisCodes9Change($event)"
                (openedChange)="ondiagCode9DropdownOpenChange($event,selecteddiadnosisCode9Value)" [(value)]="txtIcd9">
                <mat-option>
                  <ngx-mat-select-search placeholderLabel="Search" noEntriesFoundLabel="No Matches found" formControlName="txtIcd9Filter"
                    (keyup)="icd9Dropdown($event.target.value,selecteddiadnosisCode9Value)" hideClearSearchButton="true"></ngx-mat-select-search>
                </mat-option>
                <mat-option *ngIf="!diagCode9SelectAllOption" [value]="'selectAll'">Select All</mat-option>
                <mat-option *ngIf="diagCode9SelectAllOption" [value]="'DeselectAll'">Deselect All</mat-option>
                <mat-option *ngFor="let diagnosis9 of filteredIcd9Types | async" [value]="diagnosis9?.diagnosisCode">
                  {{ diagnosis9?.customDiagnosisCode  }}
                </mat-option>
              </mat-select>
            <button mat-button [disabled]="!editFormValues" (click)="txtIcd9 = []; $event.stopPropagation()" *ngIf="txtIcd9?.length !== 0"
              matSuffix (click)="clearIcd9()" mat-icon-button>
              <mat-icon>close</mat-icon>
            </button>
            </mat-form-field>

          </div>
          <div class="row">
            <div class="col-12">

              <table class="table table-bordered " *ngIf="ClinicalForm?.value?.txtDiagnosisCodes9?.length!==0">
                <thead>
                  <th class="p-0 pl-3 m-0"> Code </th>
                  <th class="p-0 pl-3 m-0"> Description </th>
                  <th class="p-0  m-0"> Options </th>
                </thead>
                <tbody>
                  <tr class="p-0 m-0" *ngFor="let x of ClinicalForm?.value?.txtDiagnosisCodes9;">
                    <td class="pl-3 p-0 m-0">
                      {{x}}
                    </td >
                    <td class="pl-3 p-0 m-0">
                      {{x |diagonsis :drpDiagnosisICD9}}
                    </td>
                    <td class=" p-0 m-0">
                      <button mat-icon-button  [disabled]="!editFormValues" (click)="removeICD9Code(x)">
                        <mat-icon
                  matTooltip="Click To Remove"

                        [style.font-size]="'medium'">close</mat-icon>
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

          </div>
        </mat-card-content>
      </div>
      <!--Diagnosis Code ICD-10-->
      <div class="col-lg-6">
        <div class="row">
          <div class="col-md-6">
            <h4 class="customThemeClass">
              <b>Diagnosis Codes-ICD-10</b> <span class="asterisk">*</span>
            </h4>
          </div>
          <div class="col-md-6 text-right">
            <button type="button" [disabled]="!editFormValues" class="buttonColor btn-sm btn-circle" (click)="openIcd10Modal()">
              <i class="fa fa-plus" matTooltip="Click to Add DiagnosisCodes-10"></i>
            </button>
          </div>
        </div>
        <mat-card-content>
          <div class="row">
            <!-- <mat-form-field class="col-12">
              <mat-label>Diagnosis Code-ICD-10</mat-label>
              <mat-select multiple formControlName="txtdiagnosisCode10Id" [(value)]="txtIcd10" #singleSelect>
                <mat-option>
                  <ngx-mat-select-search placeholderLabel="Search" noEntriesFoundLabel="No Matches found"
                    formControlName="txtIcd10Filter"></ngx-mat-select-search>
                </mat-option>

                <mat-option *ngFor="let diagnosis of filteredIcd10Types | async"
                  [value]="diagnosis?.diagnosisCode">
                  {{ diagnosis?.customDiagnosisCode }}
                </mat-option>

              </mat-select>
              <button mat-button (click)="txtIcd10 = []; $event.stopPropagation()" *ngIf="txtIcd10" matSuffix
                (click)="cleartxtIcd10()" mat-icon-button>
                <mat-icon>close</mat-icon>
              </button>

              <mat-error class="spanAlignment" *ngIf="
                  ClinicalForm?.get(
                    'txtdiagnosisCode10Id'
                  )?.touched &&
                  ClinicalForm?.get(
                    'txtdiagnosisCode10Id'
                  )?.errors
                ">
                Diagnosis Code-ICD-10 is a required field!
              </mat-error>
            </mat-form-field> -->

            <mat-form-field class="col-12">
              <mat-label>Diagnosis Code ICD-10</mat-label>
              <mat-select formControlName="txtdiagnosisCode10Id" multiple (selectionChange)="onSelectiondiagnosisCodes10Change($event)"
                (openedChange)="ondiagCode10DropdownOpenChange($event,selecteddiadnosisCode10Value)" [(value)]="txtIcd10">
                <mat-option>
                  <ngx-mat-select-search placeholderLabel="Search" noEntriesFoundLabel="No Matches found" formControlName="txtIcd10Filter"
                    (keyup)="icd10Dropdown($event.target.value,selecteddiadnosisCode10Value)" hideClearSearchButton="true"></ngx-mat-select-search>
                </mat-option>
                <mat-option *ngIf="!diagCode10SelectAllOption" [value]="'selectAll'">Select All</mat-option>
                <mat-option *ngIf="diagCode10SelectAllOption" [value]="'DeselectAll'">Deselect All</mat-option>
                <mat-option *ngFor="let diagnosis of filteredIcd10Types | async" [value]="diagnosis?.diagnosisCode">
                  {{ diagnosis?.customDiagnosisCode }}
                </mat-option>
              </mat-select>

            <button mat-button (click)="txtIcd10 = []; $event.stopPropagation()" *ngIf="txtIcd10" matSuffix
              (click)="cleartxtIcd10()" mat-icon-button>
              <mat-icon>close</mat-icon>
            </button>

            <mat-error class="spanAlignment" *ngIf="ClinicalForm?.get('txtdiagnosisCode10Id')?.errors">
                 Diagnosis Code-ICD-10 is a required field!
            </mat-error>
          </mat-form-field>

          </div>
          <div class="row">
            <div class="col-12">

              <table class="table table-bordered " *ngIf="ClinicalForm?.value?.txtdiagnosisCode10Id?.length!==0">
                <thead>
                  <th class="p-0 pl-3 m-0"> Code </th>
                  <th class="p-0 pl-3 m-0"> Description </th>
                  <th class="p-0 m-0"> Options </th>
                </thead>
                <tbody >
                  <tr class="p-0 m-0" *ngFor="let x of ClinicalForm?.value?.txtdiagnosisCode10Id;">
                    <td class="pl-3 p-0 m-0">
                      {{x}}
                    </td >
                    <td class="pl-3 p-0 m-0">
                      {{x |diagonsis :drpDiagnosisICD10}}
                    </td>
                    <td class="p-0 m-0">
                      <button mat-icon-button [disabled]="!editFormValues">
                        <mat-icon (click)="removeICD10Code(x)"
                  matTooltip="Click To Remove"
                        [style.font-size]="'medium'">close</mat-icon>
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

          </div>
        </mat-card-content>
      </div>

    </div>
    <div class="row">
      <div class="col-6">
        <mat-accordion>
          <mat-expansion-panel class="custom-expansion-panel">
              <mat-expansion-panel-header>
                  <mat-panel-title class="customThemeClass">
                      <ng-container>
                          <b>Other Doctor</b>
                      </ng-container>
                  </mat-panel-title>
              </mat-expansion-panel-header>
              <div class="custom-card elevated-div content">
              <div class="table-responsive" *ngIf="!isOtherDoctorLoading">
                <table id="tblOtherDoctor" datatable [dtOptions]="dtOptionsOtherDoctor" class="tbl hover row-border hover w-100 display">
                  <thead>
                    <tr>
                      <th class="table-header custom-width"><mat-icon>wrap_text</mat-icon></th>
                      <th class="table-header custom-width"><mat-icon>apps</mat-icon></th>
                      <th class="table-header" [matTooltip]="'NPI'">NPI</th>
                      <th class="table-header" [matTooltip]="'Doctor Name'">Doctor Name</th>
                      <th class="table-header" [matTooltip]="'Added On'">Added On</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let data of otherDoctorTableList">
                    <td class="table-data custom-width">
                      <mat-icon class="pointer toggle-icon" (click)="getAddressDetatils($event, data)">arrow_right</mat-icon>
                    </td>
                    <td class="table-data custom-width">
                      <i class="fa fa-trash deleteButton" matTooltip="Click to Delete Address" (click)="deleteDoctorAddress(data)"></i>
                    </td>
                      <td class="table-data" [matTooltip]="data?.npi || '-'">
                        {{data?.npi || '-'}}
                      </td>
                      <td class="table-data" [matTooltip]="data?.doctorName || '-'">
                        {{ data?.doctorName || '-' }}
                      </td>
                      <td class="table-data" [matTooltip]="(data?.creationName + ',' + data?.creationTime) || '-'">
                        {{ (data?.creationName + ',' + data?.creationTime) || '-' }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </mat-expansion-panel>
      </mat-accordion>
      </div>
      <div class="col-6">
        <mat-accordion>
          <mat-expansion-panel class="custom-expansion-panel">
              <mat-expansion-panel-header>
                  <mat-panel-title class="customThemeClass">
                      <ng-container>
                          <b>Other Referral</b>
                      </ng-container>
                  </mat-panel-title>
              </mat-expansion-panel-header>
              <div class="custom-card elevated-div content">
              <div class="table-responsive" *ngIf="!isOtherDoctorLoading">
                <app-patient-clinical-other-referral-table [tableData]="otherReferralTableList" (isDeleteReferral)="deleteReferralAddress($event)"></app-patient-clinical-other-referral-table>
              </div>
            </div>
          </mat-expansion-panel>
      </mat-accordion>
      </div>
    </div>
    <br/>
  </mat-card>
  <!--Important Fields End-->

  <!--Extended Info Start-->
  <div class="mt-3 mr-3">
    <mat-accordion #firstAccordion="matAccordion" class="mb-3">
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title class="customThemeClass">
            <ng-container>
              <b>Extended Info</b>
            </ng-container>
          </mat-panel-title>
        </mat-expansion-panel-header>

        <div class="row">
          <div class="col-lg-12">
            <div class="row">
              <!--General-->
              <div class="col-lg-6">
                <div class="row">
                  <div class="col-md-6">
                    <h4 class="customThemeClass"><b>General</b></h4>
                  </div>
                </div>
                <mat-card-content>
                  <!--Infectious Condition Checkbox-->
                  <div class="row">
                    <div class="col-6">
                      <br />
                      <mat-checkbox formControlName="txtGeneralCondition">
                        Infectious Condition</mat-checkbox>
                    </div>
                  </div>

                  <div class="row">
                    <!--Air borne Condition Checkbox-->
                    <div class="col-6">
                      <br />
                      <mat-checkbox formControlName="txtAirborne">Airborne</mat-checkbox>
                    </div>
                    <!--Height in Feet-->
                    <mat-form-field class="col-3">
                      <mat-label>Height(In Feet)</mat-label>
                      <input matInput type="text" formControlName="txtGeneralHeightFeet" pattern="[0-9]"
                        (keypress)="numberOnly($event)" maxlength="1" minlength="1" autocomplete="new-txtGeneralHeightFeet" />
                      <mat-error *ngIf="
                          ClinicalForm?.get(
                            'ClinicalGeneral.txtGeneralHeightFeet'
                          )?.touched &&
                          ClinicalForm?.get(
                            'ClinicalGeneral.txtGeneralHeightFeet'
                          )?.errors
                        ">
                        Enter Minimum 1 Digit!
                      </mat-error>
                      <mat-error *ngIf="
                          ClinicalForm?.get(
                            'ClinicalGeneral.txtGeneralHeightFeet'
                          )?.touched &&
                          ClinicalForm?.get(
                            'ClinicalGeneral.txtGeneralHeightFeet'
                          )?.errors?.number
                        ">
                        Enter only Numbers!
                      </mat-error>
                    </mat-form-field>
                    <!--Height in Inches-->
                    <mat-form-field class="col-3">
                      <mat-label>Height(In Inches)</mat-label>
                      <input matInput type="text" (keypress)="numberOnly($event)"
                        formControlName="txtGeneralHeightInches" maxlength="2" autocomplete="new-txtGeneralHeightInches" />

                      <mat-error *ngIf="
                          ClinicalForm?.get(
                            'ClinicalGeneral.txtGeneralHeightInches'
                          )?.touched &&
                          ClinicalForm?.get(
                            'ClinicalGeneral.txtGeneralHeightInches'
                          )?.errors
                        ">
                        Inches should be less than 11 Only allowed!
                      </mat-error>
                      <mat-error *ngIf="
                          ClinicalForm?.get(
                            'ClinicalGeneral.txtGeneralHeightInches'
                          )?.touched &&
                          ClinicalForm?.get(
                            'ClinicalGeneral.txtGeneralHeightInches'
                          )?.errors?.number
                        ">
                        Enter only Numbers!
                      </mat-error>
                    </mat-form-field>
                    <!--Contact Checkbox-->
                    <div class="col-6">
                      <br />
                      <mat-checkbox formControlName="txtContact">Contact</mat-checkbox>
                    </div>

                    <!--Weight-->
                    <mat-form-field class="col-6">
                      <mat-label>Weight(lbs)</mat-label>
                      <input matInput (keypress)="numberOnly($event)" formControlName="txtGeneralWeight" maxlength="3"
                        (keypress)="numberOnly($event)" autocomplete="new-txtGeneralWeight" type="text" />
                      <mat-error *ngIf="
                          ClinicalForm?.get('ClinicalGeneral.txtGeneralWeight')
                            ?.touched &&
                          ClinicalForm?.get('ClinicalGeneral.txtGeneralWeight')
                            ?.errors?.number
                        ">
                        Enter only Numbers!
                      </mat-error>
                    </mat-form-field>

                    <!--Droplet Checkbox-->
                    <div class="col-6">
                      <br />
                      <mat-checkbox formControlName="txtDroplet">Droplet</mat-checkbox>
                    </div>

                    <!--DOD-->
                    <mat-form-field class="col-6">
                      <mat-label>DOD</mat-label>
                      <input formControlName="txtGeneralDod" placeholder="MM/DD/YYYY" matInput
                        [matDatepicker]="myPicker2" maxlength="10" autocomplete="new-txtGeneralDod"
                        onkeydown="this.value=this.value.replace(/^(\d\d)(\d)$/g,'$1/$2').replace(/^(\d\d\/\d\d)(\d+)$/g,'$1/$2').replace(/[^\d\/]/g,'')"
                        />
                      <mat-datepicker-toggle matSuffix [for]="myPicker2">
                      </mat-datepicker-toggle>
                      <mat-datepicker #myPicker2></mat-datepicker>
                      <mat-error *ngIf="
                          ClinicalForm?.get('ClinicalGeneral.txtGeneralDod')
                            ?.touched &&
                          ClinicalForm?.get('ClinicalGeneral.txtGeneralDod')
                            ?.errors
                        ">
                        Enter Valid Date!
                      </mat-error>
                    </mat-form-field>

                    <!--Functional Ability-->
                    <mat-form-field class="col-6 pt-1">
                      <mat-label>Functional Ability</mat-label>
                      <input matInput autocomplete="new-txtGeneralFunctionalAbility" formControlName="txtGeneralFunctionalAbility" maxlength="40"
                        type="text" />
                    </mat-form-field>

                    <!--Practitioner Dropdown-->
                    <mat-form-field class="col-6">
                      <mat-label>Practitioner</mat-label>
                      <mat-select formControlName="txtGeneralPractioner" [(value)]="practitioner" #singleSelect>
                        <mat-option>
                          <ngx-mat-select-search placeholderLabel="Search" noEntriesFoundLabel="No Matching found"
                            formControlName="txtPractitionerSearchFilter"></ngx-mat-select-search>
                        </mat-option>
                        <mat-option *ngFor="let doctor of filteredPractionerTypes | async" [value]="doctor.id">
                          {{ doctor.name }}
                        </mat-option>
                      </mat-select>
                      <button mat-button (click)="practitioner = ''; $event.stopPropagation()" *ngIf="practitioner"
                        (click)="clearPractitionerDoctor()" matSuffix mat-icon-button>
                        <mat-icon>close</mat-icon>
                      </button>
                    </mat-form-field>
                  </div>
                </mat-card-content>
              </div>
              <!--General End-->

              <div class="col-lg-6">
                <!--Marketting Referral-->
                <div class="row">
                  <div class="col-lg-12">
                    <div class="row">
                      <div class="col-md-6">
                        <h4 class="customThemeClass">
                          <b>Marketing Referral</b>
                        </h4>
                      </div>
                      <!--Add Doctor Button-->
                      <div class="col-md-6 text-right">
                        <button type="button"[disabled]="!editFormValues" class="buttonColor btn-sm" (click)="openDoctorModal()">
                          <i class="fa fa-plus" matTooltip="Click to Add Doctor"></i>
                        </button>
                      </div>
                    </div>
                    <mat-card-content>
                      <div class="row">
                        <!--Marketting Referral Type-->
                        <mat-form-field class="col-6 mt-1">
                          <mat-label>Type</mat-label>
                          <mat-select formControlName="txtMarketingReferralType" [(value)]="marketingReferralType"
                            maxlength="40">
                            <mat-option *ngFor="let drp of drpMarketingtypeLoop"
                              [value]="drp.marketingReferralShortCodeId">
                              {{ drp.marketingReferralTypeName }}
                            </mat-option>
                          </mat-select>
                          <button mat-button [disabled]="!editFormValues" (click)="
                              marketingReferralType = '';
                              $event.stopPropagation()
                            " *ngIf="marketingReferralType" (click)="clearmarketingReferralType()" matSuffix
                            mat-icon-button>
                            <mat-icon>close</mat-icon>
                          </button>
                        </mat-form-field>

                        <!--Marketing Referral Facility-->
                        <!-- <mat-form-field class="col-6">
                          <mat-label>Facility</mat-label>
                          <mat-select formControlName="txtMarketingReferralFacility"
                            [(value)]="marketingReferralfacility" maxlength="40">
                            <mat-option *ngFor="let facility of drpMarketingFacility"
                              [value]="facility.facilityShortCodeId">
                              {{ facility.facilityName }}
                            </mat-option>
                          </mat-select>
                          <button mat-button [disabled]="!editFormValues" (click)="
                              marketingReferralfacility = '';
                              $event.stopPropagation()
                            " *ngIf="marketingReferralfacility" matSuffix (click)="clearMarketingReferralFacility()"
                            mat-icon-button>
                            <mat-icon>close</mat-icon>
                          </button>
                        </mat-form-field> -->

                           <!--Marketting Referral Doctor-->
                           <mat-form-field class="col-6">
                            <mat-label>Doctor</mat-label>
                            <mat-select formControlName="txtMarketingReferralDoctor" [(value)]="marketingReferralDoctor"
                              #singleSelect (selectionChange)="extendedReferraPractice($event)">
                              <mat-option>
                                <ngx-mat-select-search placeholderLabel="Search" noEntriesFoundLabel="No Matches found"
                                  formControlName="txtMarketingReferralDoctorFilter">
                                </ngx-mat-select-search>
                              </mat-option>
                              <mat-option *ngFor="
                                  let doctor of filteredMarketingReferralDoctorTypes
                                    | async
                                " [value]="doctor.id">
                                {{ doctor.name }}
                              </mat-option>
                            </mat-select>
                            <button mat-button [disabled]="!editFormValues" (click)="
                                marketingReferralDoctor = '';
                                $event.stopPropagation()
                              " *ngIf="marketingReferralDoctor" (click)="clearMarketingReferralDoctor()" matSuffix
                              mat-icon-button>
                              <mat-icon>close</mat-icon>
                            </button>
                          </mat-form-field>

                          <!--Referral Practice-->
                        <mat-form-field class="col-6">
                          <mat-label>Referral Practice</mat-label>
                          <mat-select formControlName="txtMarketingReferralFacility"
                            [(value)]="marketingReferralfacility" maxlength="40">
                            <mat-option>
                              <ngx-mat-select-search [formControl]="RefinfoControl" placeholderLabel="Search"
                                  noEntriesFoundLabel="No Matches found"
                                  formControlName="txtRefinfoType"></ngx-mat-select-search>
                            </mat-option>
                            <mat-option *ngFor="let facility of drpReferralPractice"
                              [value]="facility.id">
                              {{ facility.practiceName }}
                            </mat-option>
                          </mat-select>
                          <button mat-button [disabled]="!editFormValues" (click)="
                              marketingReferralfacility = '';
                              $event.stopPropagation()
                            " *ngIf="marketingReferralfacility" matSuffix (click)="clearMarketingReferralFacility()"
                            mat-icon-button>
                            <mat-icon>close</mat-icon>
                          </button>
                        </mat-form-field>

                        <!--Marketing Referral Contact-->
                        <mat-form-field class="col-6 pb-1">
                          <mat-label>Contact</mat-label>
                          <input formControlName="txtMarketingReferralContact" maxlength="10" matInput
                            (keypress)="numberOnly($event)" type="text" autocomplete="new-txtMarketingReferralContact" />
                          <mat-error *ngIf="
                              ClinicalForm?.get('txtMarketingReferralContact')
                                ?.touched &&
                              ClinicalForm?.get('txtMarketingReferralContact')
                                ?.errors?.number
                            ">
                            Enter only Numbers!
                          </mat-error>
                        </mat-form-field>

                        <!--Referral Practice-->
                         <!-- <mat-form-field class="col-6">
                          <mat-label>Referral Practice</mat-label>
                          <mat-select formControlName="txtMarketingReferralFacility"
                            [(value)]="marketingReferralfacility" maxlength="40">
                            <mat-option *ngFor="let facility of drpMarketingFacility"
                              [value]="facility.facilityShortCodeId">
                              {{ facility.facilityName }}
                            </mat-option>
                          </mat-select>
                          <button mat-button [disabled]="!editFormValues" (click)="
                              marketingReferralfacility = '';
                              $event.stopPropagation()
                            " *ngIf="marketingReferralfacility" matSuffix (click)="clearMarketingReferralFacility()"
                            mat-icon-button>
                            <mat-icon>close</mat-icon>
                          </button>
                        </mat-form-field> -->
                      </div>
                    </mat-card-content>
                  </div>
                </div>
                <!--Marketing Referral End-->

                <!--Marketing Rep-->
                <!-- <div class="row">
                  <div class="col-lg-12"> -->
                <!--Same as Refferal Button-->
                <!-- <div class="row">
                      <div class="col-md-4">
                        <h4 class="customThemeClass"><b>Marketing Rep</b></h4>
                      </div>
                      <div class="col-md-8 text-right">
                        <button
                          mat-button
                          (click)="sameAsReferalDoctor()"
                          class="buttonColor mr-1 font-size-12 btn-sm"
                        >

                          <i class="fa fa-clone mr-1"></i>
                          Same as Referral
                        </button>
                      </div>
                    </div> -->

                <!--Marketing Rep Dropdown Field-->
                <!-- <mat-card-content>
                      <div class="row">
                        <mat-form-field class="col-12">
                          <mat-label>Marketing Rep</mat-label>
                          <mat-select
                            formControlName="txtMarketingRep"
                            [(value)]="marketingRepDoctor"
                            #singleSelect
                          >
                            <mat-option>
                              <ngx-mat-select-search
                                placeholderLabel="Search"
                                noEntriesFoundLabel="No Matches found"
                                formControlName="txtMarketingRepSearchFilter"
                              >
                              </ngx-mat-select-search>
                            </mat-option>
                            <mat-option
                              *ngFor="
                                let doctor of filteredMarketingRepDoctorTypes
                                  | async
                              "
                              [value]="doctor.id"
                            >
                              {{ doctor.name }}
                            </mat-option>
                          </mat-select>
                          <button
                            mat-button
                            (click)="
                              marketingRepDoctor = ''; $event.stopPropagation()
                            "
                            *ngIf="marketingRepDoctor"
                            (click)="clearMarRepDoctor()"
                            matSuffix
                            mat-icon-button

                          >
                            <mat-icon>close</mat-icon>
                          </button>
                        </mat-form-field>
                      </div>
                    </mat-card-content>
                  </div>
                </div> -->
                <!--End Marketing Rep-->
              </div>
            </div>
          </div>
        </div>
      </mat-expansion-panel>

      <br />
    </mat-accordion>
  </div>

  <div class="row">
    <div class="col-sm-12">
      <div class="text-lg-center">
        <button mat-button [disabled]="!editFormValues || saveButtonHide || !!ClinicalForm.invalid" class="buttonColor mb-2 mr-2"
          (click)="saveClinical()">
          Save
          <mat-icon *ngIf="isShowSpinner">
            <mat-spinner class="spinner-border spinner-border-sm" diameter="20">
            </mat-spinner>
          </mat-icon>
        </button>
      </div>
    </div>
  </div>
  <br /><br /><br />
</form>

<ng-template #doctorModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title my-3" id="modal-basic-title">
      <b>Add Doctor</b>
    </h4>
    <button type="button" [disabled]="!editFormValues" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span>&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <ng-container>
      <!-- <div class="d-flex"> -->
      <ul ngbNav #nav="ngbNav" class="nav-pills" orientation="horizontal">
        <li ngbNavItem="Verify with NPI">
          <a ngbNavLink>Verify with NPI</a>
          <ng-template ngbNavContent>
            <!-- <app-patient-npi-doctor></app-patient-npi-doctor> -->
          </ng-template>
        </li>
        <li ngbNavItem="Verify without NPI">
          <a ngbNavLink>Verify without NPI</a>
          <ng-template ngbNavContent>
            <!-- <app-patient-doctor></app-patient-doctor> -->
          </ng-template>
        </li>
      </ul>

      <div [ngbNavOutlet]="nav"></div>
    </ng-container>
    <ng-template #VerifyWithNPI> </ng-template>
    <ng-template #VerifyWithoutNPI> </ng-template>
  </div>
</ng-template>

<ng-template #icd9Modal let-modal>
  <div class="modal-header">
    <h4 class="modal-title my-3" id="modal-basic-title">
      <b>Add Diagnosis Codes ICD-9 </b>
    </h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span>&times;</span>
    </button>
  </div>
  <div class="modal-body"></div>
</ng-template>

<ng-template #icd10Modal let-modal>
  <div class="modal-header">
    <h4 class="modal-title my-3" id="modal-basic-title">
      <b>Add Diagnosis Codes ICD-10</b>
    </h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span>&times;</span>
    </button>
  </div>
  <div class="modal-body"></div>
</ng-template>
