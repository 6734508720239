import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import emailMask from 'text-mask-addons/dist/emailMask';
import { Title } from '@angular/platform-browser';
import { InboundDocumentService } from '../admin-proxy/platform-app-management/rcm/platform-management/inbound/inbound-document.service';
import { ToastrService } from "ngx-toastr";
import { HttpErrorResponse } from '@angular/common/http';
import Swal from 'sweetalert2';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { MailTemplateDetailsService, MailTemplateMasterService } from '../admin-proxy/platform-app-management/rcm/platform-management/email-template';
import { map, startWith } from 'rxjs/operators';
import { CreateUpdateMailTemplateDetailsDTO } from '../admin-proxy/platform-app-management/rcm/platform-management/email-template/dto';
import { defaultGuid } from 'projects/shared/src/app/enums/allenums.enum';
@Component({
  selector: 'app-email-print-letter',
  templateUrl: './email-print-letter.component.html',
  styleUrls: ['./email-print-letter.component.scss']
})
export class EmailPrintLetterComponent implements OnInit {

  EmailForm: FormGroup;
  emailMask: any;
  editorContent: string;
  saveButtonHide: boolean;
  patientId: string;
  subscription$: Subscription[] = [];
  filteredTemplate: any;
  lstTemplate: any;
  templateId: any;
  templateName:any;
  constructor(private title: Title, private formBuilder: FormBuilder,
    public InboundDocumentEmailService: InboundDocumentService,
     private toastr: ToastrService,
     private activatedRoute: ActivatedRoute,
     private mailTemplateMasterService: MailTemplateMasterService,
     private mailTemplateDetailsService:  MailTemplateDetailsService) { }

  name = 'Email/PrintLetter';
  htmlContent = '';
  config: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: '35rem',
    minHeight: '5rem',
    placeholder: 'Enter text here...',
    translate: 'no',
    defaultParagraphSeparator: 'p',
    defaultFontName: 'Arial',
    toolbarHiddenButtons: [
      ['insertImage', 'insertVideo' ,'fontName'], // hide image and video icons
    ],
    // toolbarHiddenButtons: [
    //   ['bold']
    // ],
    customClasses: [
      {
        name: "quote",
        class: "quote",
      },
      {
        name: 'redText',
        class: 'redText'
      },
      {
        name: "titleText",
        class: "titleText",
        tag: "h1",
      },
    ]
  };

  ngOnInit(): void {
    this.title.setTitle('Qsecure | Email-PrintLetter');
    this.emailMask = emailMask;
    this.EmailForm = this.formBuilder.group({
      txtEmail: new FormControl("", [Validators.required]),
      editorContent: new FormControl("", [Validators.required]),
      drpTemplate: new FormControl("",[Validators.required]),
      txtSubject: new FormControl('',[Validators.required]),
      txtTemplate: new FormControl('')
    });

    this.loadTemplate()

    const activatedRoute = this.activatedRoute.paramMap.subscribe(
      (response) => {
        this.patientId =  response.get('patientId')?? defaultGuid;
        this.templateName=(response.get('templateName')!==undefined&&response.get('templateName')!==null )?response.get('templateName')?.toLowerCase(): null;
        // this.defaultFaxId = response.get('defaultFaxId') ?? defaultGuid;

      },
      (err) => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      }
    );
    this.subscription$.push(activatedRoute);

    this.EmailForm.patchValue({
      drpTemplate: this.templateName,
    });

  }


  // fnSendEmail() {
  //   this.saveButtonHide = true;
  //   // const patientID = this.patientId;
  //   const patientID= defaultGuid;
  //   const sContent = this.EmailForm.get('editorContent').value;
  //   const sToAddress = this.EmailForm.get('txtEmail').value;
  //   if (!sContent) {
  //     console.error('Content is empty');
  //     return;
  //   }

  //   this.InboundDocumentEmailService.sendMailtoPatientByPatientIDAndSContentAndSToAddress(patientID, sContent, sToAddress)
  //     .subscribe(
  //       (response) => {
  //         this.saveButtonHide = false;
  //         if (response) {
  //           this.toastr.success('Successfully Send Mail', 'Success');
  //           this.EmailForm.reset();
  //         } else {
  //           this.toastr.error('Failed to send email', 'Error');
  //           this.EmailForm.reset();
  //         }
  //       },
  //       (error) => {
  //         this.saveButtonHide = false;
  //         const data: HttpErrorResponse = error;
  //         Swal.fire({
  //           icon: 'info',
  //           text: data?.error?.error?.message,
  //         });
  //       }
  //     );
  // }


  fnSendEmail() {
    this.saveButtonHide = true;
    // const patientID = this.patientId;
    const patientID= defaultGuid;
    const sContent = this.EmailForm.get('editorContent').value;
    const sToAddress = this.EmailForm.get('txtEmail').value;
    if (!sContent) {
      console.error('Content is empty');
      return;
    }
    const templateDetails: CreateUpdateMailTemplateDetailsDTO ={
      templateId: this.templateId,
      toEmail: this.EmailForm.value.txtEmail.toLowerCase(),
      subject: this.EmailForm.value.txtSubject?? '',
      isActive: 0,
      isMailSent: true,
      tenantId: defaultGuid,
      documentId: defaultGuid,
      patientId: this.patientId,
      mailContent: this.EmailForm.value.editorContent,
      mailstatusReason: ''
    }

    this.mailTemplateDetailsService.create(templateDetails)
      .subscribe(
        (response) => {
          this.saveButtonHide = false;
          if (response) {
            this.toastr.success('Successfully Send Mail', 'Success');
            this.EmailForm.reset();
          } else {
            this.toastr.error('Failed to send email', 'Error');
            this.EmailForm.reset();
          }
        },
        (error) => {
          this.saveButtonHide = false;
          const data: HttpErrorResponse = error;
          Swal.fire({
            icon: 'info',
            text: data?.error?.error?.message,
          });
        }
      );
  }


  loadTemplate(){
    const template = this.mailTemplateMasterService.getStatusBasedMailTemplateList(1).subscribe(response => {
      this.lstTemplate = response

      this.filteredTemplate = this.EmailForm
      .get('txtTemplate')
      .valueChanges.pipe(
        startWith(''),
        map((value) =>
          this.lstTemplate?.filter((option) =>
            option?.templateName
              ?.toLowerCase()
              ?.includes(value?.toLowerCase() ?? '')
          )
        )
      );
      this.loadTemplateInDefault();
  },
  (err) => {}
);

  }
  loadTemplateInDefault(){


    if(this.EmailForm.value.drpTemplate !==null && this.EmailForm.value.drpTemplate!==''&& this.EmailForm.value.drpTemplate !== undefined){

      let template = this.lstTemplate?.filter((x)=>(x.templateName)?.toLowerCase()===(this.EmailForm.value.drpTemplate)?.toLowerCase())?.[0];

      const getTemplate = {
        templateId: template.id,
        patientId: (this.patientId === null ? defaultGuid : this.patientId )
      };
      this.templateId = template.id;
      this.mailTemplateMasterService.getMailContentByDTO(getTemplate).subscribe(response => {
        this.EmailForm.patchValue({
          editorContent: response.mailContent,
          txtEmail: response.email ?? '',
        })

      },(err) => {
        const data: HttpErrorResponse = err;
              Swal.fire({
                icon: 'info',
                text: data?.error?.error?.message ? data?.error?.error?.message : 'Internal Server Error',
              });
      })

    }
  }

  // selectTemaplate(template){
  //    this.templateId = template;
  //   const getTemplate = {
  //     templateId: this.templateId.id,
  //     patientId: (this.patientId === null ? defaultGuid : this.patientId )
  //   }

  //   this.mailTemplateMasterService.getMailContentByDTO(getTemplate).subscribe(response => {
  //     this.EmailForm.patchValue({
  //       editorContent: response.mailContent,
  //       txtEmail: response.email ?? '',
  //     })

  //   },(err) => {
  //     const data: HttpErrorResponse = err;
  //           Swal.fire({
  //             icon: 'info',
  //             text: data?.error?.error?.message ? data?.error?.error?.message : 'Internal Server Error',
  //           });
  //   })
  // }

  // fnSendEmail() {
  //   this.saveButtonHide = true;
  //   const patientID = '88959669-1f10-3a44-c20f-3a0fdb5df3f8';
  //   const sToAddress = this.EmailForm.get('txtEmail').value;

  //   // Get the content and convert to base64
  //   const editorContent = this.EmailForm.get('editorContent').value;
  //   const base64Image = this.convertImageToBase64(editorContent);

  //   if (!base64Image) {
  //     console.error('Failed to convert image to base64');
  //     return;
  //   }

  //   this.InboundDocumentEmailService.sendMailtoPatientByPatientIDAndSContentAndSToAddress(patientID, base64Image, sToAddress)
  //     .subscribe(
  //       (response) => {
  //         this.saveButtonHide = false;
  //         if (response) {


  //         } else {
  //           console.error('Failed to send email');

  //         }
  //       },
  //       (error) => {
  //         this.saveButtonHide = false;
  //         console.error(error);

  //       }
  //     );
  // }

  // convertImageToBase64(imageSrc: string): string | null {
  //   try {

  //     const base64Image = imageSrc.split(',')[1];
  //     return base64Image;
  //   } catch (error) {
  //     console.error('Error converting image to base64', error);
  //     return null;
  //   }
  // }



}
