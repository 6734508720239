<div class="row">
    <div class="col-lg-12">
        <h2>Shipping Ticket Reject Reason</h2>
    </div>

</div>
<mat-dialog-content class="mat-typography">
    <div class="row">
        <div class="col-lg-12">
            <mat-card-content>
                <form [formGroup]="declineForm">
                    <div class="row">
                        <div class="col-lg-12">

                            <mat-form-field class="col-12">
                                <mat-label>CSR Name <span class="asterisk">*</span></mat-label>
                                <input autocomplete="new-txtCsrName" readonly formControlName="txtCsrName" maxlength="40" matInput type="text" />
                            </mat-form-field>

                            <mat-form-field class="col-12">
                                <mat-label>Comments <span class="asterisk">*</span></mat-label>
                                <textarea formControlName="txtComments" rows="3" matInput maxlength="400"></textarea>
                                <mat-error *ngIf="declineForm?.get('txtComments')?.touched &&
                                declineForm?.get('txtComments')?.errors?.required">
                                    Comments Name is a required field!
                                </mat-error>
                            </mat-form-field>

                        </div>
                    </div>
                </form>
            </mat-card-content>
        </div>
    </div>
</mat-dialog-content>

<mat-dialog-actions align="end">
    <button *ngIf="!showButton" [disabled]="!( saveButtonHide ||!declineForm.invalid && declineForm.dirty )"
        (click)="declineSave()" mat-button class="resetclr">Reject
    </button>

    <button *ngIf="showButton" (click)="declineReturnSave()" mat-button class="resetclr">Return Reject
    </button>

    <button class="buttonCancelColor" mat-button mat-dialog-close>Close</button>

</mat-dialog-actions>