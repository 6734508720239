import { ListService, PagedAndSortedResultRequestDto } from '@abp/ng.core';
import { DatePipe } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { SaleorderDTO } from 'projects/order/src/app/order-proxy/order-management/order/dto/models';
import { SaleorderService } from 'projects/order/src/app/order-proxy/order-management/order/saleorder.service';
import { addOrder } from 'projects/order/src/app/order-store/order.action';
import { TableService } from 'projects/shared/src/app/table.service';
import { DateValidator } from 'projects/shared/src/app/validations/date-validator';
import { Subscription } from 'rxjs';
import Swal from 'sweetalert2';

import { AuthorizationSearchService } from '../patient-proxy/patient/authorization-search.service';
import { AuthorizationDTO, PatientDTO, PatientExportDatas, PatientExportDTO } from '../patient-proxy/patient/dto/models';
import { ExportType } from '../patient-proxy/patient/enumeration-data/export-type.enum';

import { PatientSearchService } from '../patient-proxy/patient/patient-search.service';
import { PatientService } from '../patient-proxy/patient/patient.service';
import { ReportService } from '../patient-proxy/patient/report.service';
import { getPatient } from '../patient-store/patient.action';
import { ToastrService } from "ngx-toastr";
@Component({
  selector: 'app-patient-auth-menu',
  templateUrl: './patient-auth-menu.component.html',
  styleUrls: ['./patient-auth-menu.component.scss'],
  providers: [ListService, DatePipe]
})

export class PatientAuthMenuComponent implements OnInit, OnDestroy {
  strPageType: string = "patientInsuranceAuthorization";
  isLoading: boolean = true;
  authTableData: any[] = [];
  authForm: FormGroup;
  public arrDisplayedColumns: string[] = [];
  dataSource: MatTableDataSource<any> = new MatTableDataSource<any>([]);
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatTable, { static: false }) tableRef: MatTable<any>
  constructor(
    public title: Title,
    public dialog: MatDialog,
    public router: Router,
    public list: ListService,
    private snackBar: MatSnackBar,
    public patientService: PatientService,
    public patientSearchService: PatientSearchService,
    public table: TableService,
    private formBuilder: FormBuilder,
    private AuthSearch: AuthorizationSearchService,
    public datepipe: DatePipe,
    private store: Store,
    private dateValidator: DateValidator,
    private orderService: SaleorderService,
    private toastr: ToastrService,
    private reportService: ReportService
  ) { }

  organizationUnitId = null;
  verifyDetails: any[] = [];

  isShownUpdateIconForPatient: boolean = true;
  subscription$: Subscription[] = [];
  ngOnDestroy(): void {
    this.subscription$?.forEach((sub) => {
      sub && sub?.unsubscribe();
    });
  }

  ngOnInit(): void {
    this.isLoading = true;
    const patientUpdateSubscription = this.table.getPatientUpdate().subscribe(value => {
      if (value == true) {
        this.isShownUpdateIconForPatient = true;
      }
      else {
        this.isShownUpdateIconForPatient = false;
      }
    }, err => {
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    });
    this.subscription$.push(patientUpdateSubscription);
    //
    this.authForm = this.formBuilder.group({
      PatientId: new FormControl(""),
      PatientName: new FormControl(""),
      Date: new FormControl("", this.dateValidator.dateVaidator),
      Plan: new FormControl(""),
      AuthId: new FormControl(""),
      Status: new FormControl(""),
    })
    const valueChanges = this.authForm.valueChanges.subscribe((value: Patient) => {
      let filter: Patient = {
        PatientId: value?.PatientId?.trim()?.toLowerCase(),
        PatientName: value?.PatientName?.trim()?.toLowerCase(),
        Date: value?.Date?.trim()?.toLowerCase(),
        Plan: value?.Plan?.trim()?.toLowerCase(),
        AuthId: value?.AuthId,
        Status: value?.Status
      };
      if (this.authForm.valid) {
        this.dataSource.filter = JSON.stringify(filter);
        this.filtertable(filter);
      }
    }, err => {
      this.isLoading = false;
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    });
    this.subscription$.push(valueChanges)
    //
    this.arrDisplayedColumns = ['PatientId', 'options', 'bin', 'PatientName', 'Date', 'Plan', 'AuthId', 'Status'];
    this.snackBar.dismiss();
    // this.organizationUnitId = localStorage.getItem('initialOrganizationUnitId');
    this.title.setTitle("Qsecure | Authorization To Do");
    this.getAuthorizationTableData();

  }
  isSaleOrderData: boolean = false;
  //! auth table
  getAuthorizationTableData() {
    this.isLoading = true;
    // let verifyDetails: PatientDTO[] = [];
    const getTableSubscription = this.patientSearchService.getList(new PagedAndSortedResultRequestDto).subscribe(response => {
      this.authTableData = [];

      let verifyDetails = response;

      verifyDetails?.items?.map(value => {
        const creationTime = value?.creationTime === null ? "" : this.datepipe.transform(value?.creationTime, 'MM/dd/yyyy') ?? "";

        let data = {
          sPatientId: value?.defaultPatientId,
          sPatientName: value?.personals.fullName,
          dCreatedDate: creationTime
        }
        const primaryAuth = value?.authorizations.primaryAuthorizations
        const secondaryAuth = value?.authorizations.secondaryAuthorizations
        const tertiaryAuth = value?.authorizations.tertiaryAuthorizations
        if (value?.authorizations.primaryAuthorizations !== null) {
          if (value?.authorizations?.primaryAuthorizations?.moveToOrderStatus !== "Ready To Order") {
            const dCreatedDate = data?.dCreatedDate === null ? "" : this.datepipe.transform(data?.dCreatedDate, 'MM/dd/yyyy') ?? "";
            this.authTableData.push({
              patientId: data.sPatientId,
              PatientName: data.sPatientName,
              createDate: dCreatedDate,
              plan: primaryAuth?.plan,
              AuthId: primaryAuth?.authId,
              Status: primaryAuth?.isCompleted,
              id: value?.id,
              binStatus: primaryAuth?.isCompleted == "Done" &&
                (value?.saleOrderId === "00000000-0000-0000-0000-000000000000" ||
                  value?.saleOrderId === null)
            })
          }
        }
        if (value?.authorizations.secondaryAuthorizations !== null) {
          if (secondaryAuth?.moveToOrderStatus !== "Ready To Order") {
            const dCreatedDate = data?.dCreatedDate === null ? "" : this.datepipe.transform(data?.dCreatedDate, 'MM/dd/yyyy') ?? "";

            this.authTableData.push({
              patientId: data.sPatientId,
              PatientName: data.sPatientName,
              createDate: dCreatedDate,
              plan: secondaryAuth?.plan,
              AuthId: secondaryAuth?.authId,
              Status: (secondaryAuth?.isCompleted),
              id: value?.id,
              // planStatus: true,
              // binStatus: secondaryAuth?.isCompleted !== "Done"
            })
          }
        }
        if (value?.authorizations?.tertiaryAuthorizations !== null) {
          if (value?.authorizations?.tertiaryAuthorizations?.moveToOrderStatus !== "Ready To Order") {
            const dCreatedDate = data?.dCreatedDate === null ? "" : this.datepipe.transform(data?.dCreatedDate, 'MM/dd/yyyy') ?? "";

            this.authTableData.push({
              patientId: data.sPatientId,
              PatientName: data.sPatientName,
              createDate: dCreatedDate,
              plan: tertiaryAuth?.plan,
              AuthId: tertiaryAuth?.authId,
              Status: (tertiaryAuth?.isCompleted),
              id: value?.id,
              // planStatus: true,
              // binStatus: tertiaryAuth?.isCompleted !== "Done"
            })
          }
        }
        this.isLoading = false;
        const authTableData = this.authTableData;
        this.dataSource = new MatTableDataSource(authTableData);
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
      })
      this.isLoading = false;
    }, err => {
      this.isLoading = false;
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    })
    this.subscription$.push(getTableSubscription);
  }
  authView(id: string) {
    this.router.navigate(['/patientViewEdit/' + id]);
  }
  //! search
  filtertable(filter: Patient) {
    let PatientId = filter.PatientId;
    let PatientName = filter.PatientName;
    let Plan = filter.Plan;
    let Date = filter.Date;
    let AuthId = filter.AuthId;
    let Status: string = filter.Status;
    // if(filter.Status=="Pending"){
    //   Status=0;
    // }else{
    //   Status=1;
    // }
    let verifyDetails;
    const filterSubscription = this.AuthSearch.searchAuthorizationBySPatientIdAndSPatietNameAndSAuthIdAndSPlanAndDtCreatedDateAndSStatus(
      PatientId, PatientName, AuthId, Plan, dateYYYYMMDDtoMMDDYYYY(Date), Status).subscribe(data => {
        this.authTableData = [];
        const responseData: any[] = [];
        responseData[0] = data;
        responseData[0]?.forEach((value) => {
          //
          if (value?.authorizations) {
            const mcreationTime = value?.CreationTime === null ? "" : this.datepipe.transform(value?.CreationTime, 'MM/dd/yyyy') ?? "";

            let data = {
              sPatientId: value?.defaultPatientId,
              PatientName: value?.Personals?.fullName,
              dCreatedDate: mcreationTime,
            }
            const primaryAuth = value?.authorizations.primaryAuthorizations;
            const secondaryAuth = value?.authorizations.secondaryAuthorizations;
            const tertiaryAuth = value?.authorizations.tertiaryAuthorizations;
            if (value?.authorizations.primaryAuthorizations !== null) {
              if (value?.authorizations.primaryAuthorizations.moveToOrderStatus !== "Ready To Order") {
                this.authTableData.push({
                  patientId: data.sPatientId,
                  PatientName: data.PatientName,
                  createDate: data?.dCreatedDate,
                  plan: primaryAuth?.plan,
                  AuthId: primaryAuth?.authId,
                  Status: primaryAuth?.isCompleted,
                  binStatus: primaryAuth?.isCompleted == "Done" &&
                    (value?.saleOrderId === "00000000-0000-0000-0000-000000000000" ||
                      value?.saleOrderId === null),
                  //binStatus: primaryAuth?.isCompleted == "Done",
                  id: value?.Id

                })
              }
            }
            if (value?.authorizations.secondaryAuthorizations !== null) {
              if (secondaryAuth.moveToOrderStatus !== "Ready To Order") {
                this.authTableData.push({
                  patientId: data.sPatientId,
                  PatientName: data.PatientName,
                  createDate: data?.dCreatedDate,
                  plan: secondaryAuth?.plan,
                  AuthId: secondaryAuth?.authId,
                  Status: secondaryAuth?.isCompleted,
                  // binStatus: secondaryAuth?.isCompleted !== "Done",
                  id: value?.Id
                })
              }
            }
            if (tertiaryAuth !== null) {
              if (value?.authorizations.tertiaryAuthorizations.moveToOrderStatus !== "Ready To Order") {
                this.authTableData.push({
                  patientId: data.sPatientId,
                  PatientName: data.PatientName,
                  createDate: data?.dCreatedDate,
                  plan: tertiaryAuth?.plan,
                  AuthId: tertiaryAuth?.authId,
                  Status: tertiaryAuth?.isCompleted,
                  // binStatus: tertiaryAuth?.isCompleted !== "Done",
                  id: value?.Id
                })
              }
            }
          } else {
            if (value !== null) {
              const mcreationTime = value?.creationTime === null ? "" : this.datepipe.transform(value?.creationTime, 'MM/dd/yyyy') ?? "";
              this.authTableData.push({
                Id: value?.Id,
                patientId: value?.defaultPatientId,
                PatientName: value?.fullName,
                createDate: mcreationTime,
                plan: value?.plan,
                AuthId: value?.authId,
                Status: value?.isCompleted,
                id: value?.Id
              })
            }
          }
        })
        const authTableData = this.authTableData;
        this.dataSource = new MatTableDataSource(authTableData);
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
      }, err => {
        const data: HttpErrorResponse = err;
        this.isLoading = false
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      })
    this.subscription$.push(filterSubscription)
  }
  viewInsuranceAuthorization(id: string) {
    this.router.navigate(['patient/saleorderCreateEdit/' + id])
  }

  //! move to order
  moveToOrder(event) {
    if (event.id !== "" && event.id !== null && event.id !== undefined) {

    }
    // const storeSubscription =  this.store.dispatch(new getPatient(event.id)).subscribe(response => {
    const storeSubscription = this.patientService.get(event.id).subscribe(response => {
      // const selectedPatient: PatientDTO = response?.patientState?.selectedPatient;
      let savedAuth: Partial<{ primaryAuthorizations: AuthorizationDTO; secondaryAuthorizations: AuthorizationDTO; tertiaryAuthorizations: AuthorizationDTO; }> = {
        primaryAuthorizations: response?.authorizations?.primaryAuthorizations,
        secondaryAuthorizations: response?.authorizations?.secondaryAuthorizations,
        tertiaryAuthorizations: response?.authorizations?.tertiaryAuthorizations
      }
      if (savedAuth?.primaryAuthorizations?.plan === "Primary") {

        savedAuth.primaryAuthorizations.moveToOrderStatus = "Ready To Order"
        // selectedPatient.authorizations.primaryAuthorizations.moveToOrderStatus =
        // ...selectedPatient?.authorizations?.primaryAuthorizations ?? null,
        // moveToOrderStatus: "Ready To Order"


        // savedAuth.primaryAuthorizations = {
        //   moveToOrderStatus: "Ready To Order",
        //   ...selectedPatient?.authorizations?.primaryAuthorizations
        // }
      }
      if (savedAuth?.secondaryAuthorizations?.plan === "Secondary") {
        savedAuth.secondaryAuthorizations.moveToOrderStatus = "Ready To Order"
        // savedAuth.secondaryAuthorizations = {
        //   ...selectedPatient.authorizations?.secondaryAuthorizations,
        //   moveToOrderStatus: "Ready To Order"
        // }
      }
      if (savedAuth?.tertiaryAuthorizations?.plan === "Tertiary") {
        savedAuth.tertiaryAuthorizations.moveToOrderStatus = "Ready To Order"
        // savedAuth.tertiaryAuthorizations = {
        //   ...selectedPatient?.authorizations?.tertiaryAuthorizations,
        //   moveToOrderStatus: "Ready To Order"
        // }
      }
      let patientsAuth = {
        personals: response?.personals,
        addresses: response?.addresses,
        contacts: response?.contacts,
        clinicals: response?.clinicals,
        insurances: response?.insurances,
        notes: response?.notes,
        policies: response?.policies,
        defaultPatientId: response?.defaultPatientId,
        insuranceVerifications: response?.insuranceVerifications,
        authorizations: {
          primaryAuthorizations: savedAuth.primaryAuthorizations,
          secondaryAuthorizations: savedAuth.secondaryAuthorizations,
          tertiaryAuthorizations: savedAuth.tertiaryAuthorizations
        },
        patientDocuments: response?.patientDocuments,
        organizationUnitId: response?.organizationUnitId,
        isAutoIntake: response?.isAutoIntake,
        autoIntakeDocumentDetails: response.autoIntakeDocumentDetails,
        chartId: response?.chartId,
        billingDetails: response.billingDetails,
        patientLock: response.patientLock,
        fileId: response?.fileId,

      }

      if (this.orderId === "" || this.orderId === null || this.orderId === undefined) {
        let orderData: SaleorderDTO = {
          orders: {
            // saleorderId: this.saleorderId,
            scheduledDate: "",
            scheduledTime: "",
            actualDate: "",
            actualTime: "",
            address: "",
            city: "",
            state: "",
            county: "",
            country: "",
            postalCode: "",
            phone: "",
            mobile: "",
            facilityId: "",
            taxZoneId: "",
            taxRate: "",
            orderNote: "",
            deliveryNote: "",
            setupMethodId: "",
            deliveryTechnicianId: "",
            dropShipStatus: "",
            isSignatureRequired: 0,
            isManualHold: 0,
            holdReasonId: "",
            stopDate: "",
            stopReasonId: "",
            branchId: "",
            invLocationId: "",
            statusId: "",
            classificationId: "",
            placeOfServiceId: "",
            dateOfAdmission: "",
            dateOfDischarge: "",
            discountPct: "0",
            poNumber: "",
            reference: "",
            user1: "",
            user2: "",
            user3: "",
            user4: "",
            priorSystemKey: "",
            orderStatus: "",
            statusDateTime: "",
            lastMessage: "",
            lastMessageDateTime: "",
            orderNumber: "",
            type: "",
            dateCreated: "",
            createdBy: "",
            lastPrinted: "",
            printedBy: "",
            confirmedBy: "",
            confirmedDate: "",
            prevBilling: "",
            nextBilling: "",
            shippingStatus: "",
            shippingCarrierId: "",
            shippingMethodId: "",
            daysInState: "",
            wipStateId: "",
            assignedToId: "",
            dateNeeded: "",
            isCompleted: "",
            complianceUsageMetId: "",
            authorizationOfOrderId: "",
            maskOrderId: "",
            headGearId: "",
            tubingId: "",
            cushionsPillowId: "",
            dispFiltersId: "",
            reusableFiltersId: "",
            waterChamberId: "",
            carrierId: "",
            trackingNo: "",
            isValidateInsuranceStatus: false,

          },
          insurances: [],
          clinicals: [],
          items: [],
          // saleorderId:0,
          orderStatus: "Ready To Order",
          saleorderId: "",
          organizationUnitId: null,
          currentStage: "orderProcessing",
          currentStageStatus: "",
          patients: patientsAuth,
          defaultClaimId: "",
          claimId: "00000000-0000-0000-0000-000000000000",
          patientId: response.id,
          isShippingApprove: 0,
          isCashPayment: +response.personals.isCashPayment,
          orderProcessStatus: "",
          paymentTransactionDetails: null,
          payPalMailResendCount: 0,
          payPalPaymentMethodType: null,
          payPalPaymentStatus: null,
          shippingCheckList: null,
          orderBilling: null,
          deliveryTicket: null,
          authType: ""

          //saleorderId:0
        }
        this.saveOrUpdate(orderData);
      }

    }, err => {
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    })
    this.subscription$.push(storeSubscription);
  }
  orderId: string = "";
  saveOrUpdate(orderData: SaleorderDTO) {

    this.store.dispatch(new addOrder(orderData)).subscribe((stateResponse) => {
      Swal.fire({ title: 'Success', html: 'Record Moved To Order Bin Successfully', icon: 'success', timer: 3000, timerProgressBar: true }).then(result => {
        this.getAuthorizationTableData()
      }, err => { });
      this.orderId = stateResponse?.orderState?.selectedOrder.id;
      this.table.setSelectedOrderId(this.orderId);
      localStorage.setItem('orderId', this.orderId);
    }, error => {
      const errData: HttpErrorResponse = error;
      errData?.error && errData?.error?.error && Swal.fire({ icon: 'info', title: 'Please Fill Below Required Feilds', text: `${errData?.error?.error.message}` })
    })
    // this.store.dispatch(new addOrder(orderData)).subscribe((stateResponse) => {
    //   Swal.fire({ title: 'Success', html: 'Saved Successfully', icon: 'success', timer: 3000, timerProgressBar: true }).then(result => {
    //     this.getAuthorizationTableData()
    //   }, err => { });
    //   this.orderId = stateResponse?.orderState?.selectedOrder.id;
    //   this.table.setSelectedOrderId(this.orderId);
    //   localStorage.setItem('orderId', this.orderId);
    // }, error => {
    //   const errData: HttpErrorResponse = error;
    //   errData?.error && errData?.error?.error && Swal.fire({ icon: 'error', title: 'Please Fill Below Required Feilds', text: `${errData?.error?.error.message}` })

    //

  }

  exportTableData() {
    let datas: PatientExportDatas[] = []
    this.dataSource?.data?.forEach(element => {

      datas.push({
        patientDefaultId: element?.patientId,
        patientName: element?.PatientName,
        patientCreatedDate: element?.createDate,
        planType: element?.plan,
        authId: element?.AuthId,
        authStatus: element?.Status,
      });
    })
    let patientsExport: PatientExportDTO = {
      columns: ["Patient Id", "Patient Name", "Created Date", "Plan", "Auth Id", "Status"],
      datas: datas
    };
    const exportSubscription = this.reportService.exportPatientReportsByExportTypeAndExportDatas(ExportType.AuthorizationReports, patientsExport).subscribe(response => {
      if (response && response?.length !== 0) {
        let filelocation: String = String("data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64," + response);
        let link = document.createElement("a");
        link.download = ("Patient-Authorization") + ".xlsx";
        link.href = filelocation.toString();
        link.click();
        // Swal.fire({ title: 'Success', html: 'Exported Successfully', icon: 'success', timer: 3000, timerProgressBar: true })
        this.toastr.success('Exported Successfully','Success')
      }
    }, err => {
      const data: HttpErrorResponse = err;
      let errorMesg: string = data?.error;
      if (errorMesg?.includes('Sorry!! There is no data in table to export')) {
        errorMesg = 'Sorry!! There is no data in table to export';
      }
      else {
        errorMesg = data?.error?.error?.message;
      }
      // Swal.fire({
      //   icon: 'info',
      //   text: errorMesg
      // });
      this.toastr.info(errorMesg)
    });
    this.subscription$.push(exportSubscription)
  }
}

export interface infInsuranceAuthorizationTable {
  id?: string,
  saleorderId?: number,
  SNo?: string,
  PatientId: string,
  PatientName: string,
  AuthId: string,
  AuthorizationId?: string,
  Status: string,
  CreatedDate: string,
  isActive?: boolean
}
export interface Patient {
  PatientId?: string,
  PatientName?: string,
  Date?: string,
  Plan?: string,
  AuthId?: string,
  Status?: string,

}

function dateYYYYMMDDtoMMDDYYYY(strDate: string): string {
  if (strDate && strDate !== "" && typeof strDate === 'string' && strDate !== null && strDate !== undefined && strDate.length >= 1 && !isNaN(new Date(strDate).getTime())) {
    let latest_date = Intl.DateTimeFormat('en-US')?.format(new Date(strDate))
    return latest_date;
  }
  return "";
}


