import { AbstractControl } from '@angular/forms';

export class PasswordValidation {

  static MatchPassword(AC: AbstractControl): string | null {
    let password = AC?.get('txtPassword')?.value;
    if (AC?.get('txtConfirmPassword')?.touched || AC?.get('txtConfirmPassword')?.dirty) {
      let verifyPassword = AC?.get('txtConfirmPassword')?.value;

      if (password != verifyPassword) {
        AC?.get('txtConfirmPassword')?.setErrors({ MatchPassword: true })
      } else {
        return null
      }
      return null
    }
    return null
  }
// For Form 2 User Create
  static MatchPasswordV1(AC: AbstractControl): string | null {
    let password = AC?.get('txtPassword2')?.value;
    if (AC?.get('txtConfirmPassword2')?.touched || AC?.get('txtConfirmPassword2')?.dirty) {
      let verifyPassword = AC?.get('txtConfirmPassword2')?.value;

      if (password != verifyPassword) {
        AC?.get('txtConfirmPassword2')?.setErrors({ MatchPassword: true })
      } else {
        return null
      }
      return null
    }
    return null
  }
}
