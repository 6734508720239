


export class getOrder {
  static readonly type = '[SaleorderDTO] Get]';
  constructor(public id: string) { }
}

export class addOrder {
  static readonly type = '[SaleorderDTO] Add]';
  constructor(public readonly payload: any) { }
}

export class deleteOrder {
  static readonly type = '[SaleorderDTO] Delete]';
  constructor(public id: string) { }
}
export class updateOrder {
  static readonly type = '[SaleorderDTO] Update]';
  constructor(public id: string, public readonly payload?: any) { }
}







