<mat-card >
  <mat-card-title class="customThemeClass">
    <div class="row">
      <div class="col-md-6">
        <h4 class="customThemeClass pt-2"><b>Do Verification List</b></h4>
      </div>
    </div>
  </mat-card-title>
  <mat-divider class="bg mb-5"> </mat-divider>
  <br>
  <div class="row" *ngIf="isLoading">
    <div class="col-12">
      <div class="d-flex justify-content-center" *ngIf="isLoading">
        <img class="img-responsive pb-2 mr-1" src="assets/gifs/Infinity-loader.gif" style="color: lightseagreen" />
      </div>
    </div>
  </div>
  <mat-accordion *ngIf="!isLoading" #firstAccordion="matAccordion" >
    <mat-expansion-panel *ngFor="let id of sLstPolicyId" style="margin-bottom: 1%;">
      <mat-expansion-panel-header>
        <mat-panel-title class="customThemeClass">
          <h4 class="customThemeClass pt-2"><b>{{id.chartNo}} - {{id.patientName}}</b></h4>
        </mat-panel-title>
        <div class="auth-status-header font-weight-bolder blinking" style="margin-right: 5%;">
          {{ id.verificationStatus }}
        </div>
      </mat-expansion-panel-header>
      <div class="row">
        <div class="col-12">
         <app-patient-add-policy-tab [MasterDocId]="id.documentId" [isMstVerification]=true [MasterVerifyPolicyId]="id.policyId" [MasterVerificationId]="id.verificationId" (reload)="loadPage($event)"></app-patient-add-policy-tab>
        </div>
      </div>
    </mat-expansion-panel>
  </mat-accordion>
  <!-- (isLoadPage)="loadPage()" -->
  <br>
</mat-card>
