import { Component, EventEmitter, HostListener, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import emailMask from 'text-mask-addons/dist/emailMask';
import { NoteType } from 'projects/admin/src/app/warehouse-proxy/inventory/dto';
// import { defaultGuid } from 'projects/admin/src/app/document-perviewer/document-perviewer.component';
import { RegionCitiesDTO, RegionCountriesDTO, RegionStatesDTO } from 'projects/patient/src/app/dropdown-proxy/dropdown-management/dropdowns/dto/models';
import { defaultCountry, defaultGuid } from 'projects/shared/src/app/enums/allenums.enum';
import { RegionDropdownService } from 'projects/patient/src/app/dropdown-proxy/dropdown-management/dropdowns/region-dropdown-service';
import { Observable, Subscription } from 'rxjs';
import { debounceTime, filter, map, startWith, switchMap, tap } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import Swal from 'sweetalert2';
import { PagedAndSortedResultRequestDto } from '@abp/ng.core';
import { UserService } from 'projects/admin/src/app/admin-proxy/platform-app-management/rcm/platform-management/user';
import { UserByRoleDTO } from 'projects/admin/src/app/admin-proxy/platform-app-management/identity';
import { PracticeManagementService } from '../patient-proxy/patient/practice-management';
import { CreateUpdatePracticeManagementDTO, CreateUpdateRequirementsDTO, CreateUpdateMoreInfoDTO } from '../patient-proxy/patient/practice-management/dto/models';
import { Status } from 'projects/inventory/src/app/item-proxy/item-management/items/status.enum';
import { ToastrService } from "ngx-toastr";
import { ActivatedRoute, Router } from '@angular/router';
import { CommunicationService } from 'projects/shared/src/app/services/communication.service';
import { noWhitespaceValidator } from '../add-note-tab/add-note-tab.component';
import { InboundDocumentService } from 'projects/admin/src/app/admin-proxy/platform-app-management/rcm/platform-management/inbound/inbound-document.service';
import { MatDatepicker } from '@angular/material/datepicker';
import { PracticeManagementNotesComponent } from '../practice-management-notes/practice-management-notes.component';
import { MatSelect } from '@angular/material/select';
@Component({
  selector: 'app-add-practice-management',
  templateUrl: './add-practice-management.component.html',
  styleUrls: ['./add-practice-management.component.scss']
})
export class AddPracticeManagementComponent implements OnInit {
  @ViewChild('childComponentRef') childComponent!: PracticeManagementNotesComponent;
  sessionPracticeId: string = '';
  drpAccountManger: any;
  practiceForm: FormGroup;
  faxlogForm: FormGroup;
  isShowSpinner: boolean = false;
  isLoading: boolean;
  // showPhysicianComponent:boolean = false;
  phoneCode: string = '';
  hidecountry: boolean = true;
  status: string = '';
  patientId: string;
  emailMask: any;
  drpStates: RegionStatesDTO[] = [];
  drpCities: any[] = [];
  drpCountry: RegionCountriesDTO[] = [];
  lstFilterUsers: UserByRoleDTO[] = [];
  subscription$: Subscription[] = [];
  activeTab: string = 'Physicians';
  loadChildComponent = false;
  isAccountMangerTouched = false;
  isTATTouched = false;
  isStateTouched = false;
  noteType: NoteType = NoteType.patient;
  docId: string = defaultGuid;
  CityTypeControl = new FormControl();
  StateTypeControl = new FormControl();
  CountryTypeControl = new FormControl();
  ActManagerTypeControl = new FormControl();
  Country: string = "";
  State: string = "";
  City: string = "";
  stateName: string = "";
  CountryName: string = "";
  commentsText = '';
  tableData$: Subscription;
  practiceFaxlog: any;
  @Input() selectedNode: any;
  dateValue: any;
  selectedMonth: number;
  selectedYear: number;
  maxDate: Date;
  @ViewChild('txtDateValue') txtDateValue: MatDatepicker<Date>;
  @Output() reloadTableEvent = new EventEmitter();
  // txttat = new FormControl();

  // txtActManger = new FormControl();
  public filteredStates: Observable<RegionStatesDTO[]> | undefined;
  public filteredCities: Observable<RegionCitiesDTO[]> | undefined;
  public filteredCountries: Observable<RegionCountriesDTO[]> | undefined;

  @HostListener('input', ['$event']) onInput(event: Event) {
    const input = event.target as HTMLInputElement;

    // Check if the input field has the class 'zip-code-input'
    if (input.classList.contains('zip-code-input')) {
      let formattedValue = input.value.replace(/[^0-9]/g, '');

      if (formattedValue.length > 5) {
        formattedValue = formattedValue.slice(0, 5) + '-' + formattedValue.slice(5);
      }

      input.value = formattedValue;
    }
  }

  constructor(
    private title: Title,
    private fb: FormBuilder,
    private formBuilder: FormBuilder,
    private region: RegionDropdownService,
    private regionDropdownService: RegionDropdownService,
    private userService: UserService,
    private practiceManagementService: PracticeManagementService,
    private toastr: ToastrService,
    private router: Router, private route: ActivatedRoute,
    private communicationService: CommunicationService,
    private inboundDocumentservice: InboundDocumentService,

  ) { }

  ngOnInit(): void {
    this.title.setTitle('Qsecure | Add Practice Management');

    this.sessionPracticeId = this.route.snapshot.params.id;

    this.practiceForm = this.fb.group({
      txtName: ['', [Validators.required, Validators.pattern('^[a-zA-Z ]*$'), noWhitespaceValidator]],
      txtAddress: new FormControl('', [Validators.required, noWhitespaceValidator]),

      drpState: new FormControl("", [Validators.required]),
      drpStateFilter: new FormControl(""),

      drpCity: new FormControl("", [Validators.required]),
      drpCityFilter: new FormControl(""),

      drpCountry: new FormControl(defaultCountry.US, [Validators.required]),

      txtZipCode: new FormControl('', [Validators.required]),

      txtCellNo: new FormControl(''),
      txtFaxNo: new FormControl(''),
      txtManPhnNo: new FormControl(''),
      txtEmail: new FormControl(''),

      // txttat: new FormControl('', [Validators.required]),
      txttat: new FormControl(''),

      txtWebSite: new FormControl(''),
      txtManager: new FormControl(''),
      txtmanEmail: new FormControl(''),

      drpAccountManger: new FormControl(''),
      txtActManger: new FormControl(''),

      txtComments: new FormControl(''),
    });
    //Account Manager Search Drop Down
    this.ActManagerTypeControl.valueChanges.subscribe((searchTerm) => {
      searchTerm = searchTerm.toLowerCase();
      this.lstFilterUsers = this.drpAccountManger.filter((dx) =>
        dx.userDesignation.toLowerCase().includes(searchTerm)
      );
    });
    this.emailMask = emailMask;
    this.countryDropdown();
    this.getUserByTenant();

    this.dateValue =
      new Date().toLocaleString('default', { month: 'short' }) +
      '/' +
      new Date().getFullYear();

    this.selectedNode =
      new Date().getMonth() + 1 + '/' + new Date().getFullYear();
    this.selectedMonth = new Date().getMonth() + 1;
    this.selectedYear = new Date().getFullYear();

    this.faxlogForm = this.formBuilder.group({
      txtDateValue: new FormControl(''),
      txtDate: new FormControl(),
    });
    this.faxlogForm.patchValue({
      txtDateValue: this.dateValue,
    });

    if (this.sessionPracticeId) {
      this.getPracticeById();
      this.getPracticeFaxLog(this.selectedMonth, this.selectedYear);
      this.faxlogForm.enable();
    }else{
      this.faxlogForm.disable();
    }
  }
  changeTab(tab: string) {
    this.activeTab = tab;
    if(tab==='Fax Log'){
      if (this.sessionPracticeId) {
      this.getPracticeFaxLog(this.selectedMonth, this.selectedYear);
    }
    }
  }

  // Get Country Dropdown
  countryDropdown() {
    this.practiceForm.patchValue({ "drpCountry": defaultCountry.US })
    this.onChangeState(defaultCountry.US);
    this.Country = defaultCountry.US;

    this.drpCountry = [];
    this.regionDropdownService.getAllCountriesByInput(new PagedAndSortedResultRequestDto).subscribe(response => {
      //
      this.drpCountry = response?.items.filter(x => x.countryShortName == "US") ?? [];
      this.filteredCountries = this.practiceForm?.get("txtCountryFilter")?.valueChanges
        .pipe(
          startWith(''),
          map(value => this.drpCountry.filter(option => option?.countryName?.toLowerCase()?.includes(value?.toLowerCase() ?? "")))
        );
    }, err => {
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    });
  }

  // On Change State Dropdown
  onChangeState(value: string | null | undefined): boolean {
      this.practiceForm.patchValue({
        drpState: null,
        drpCity: null
      })
    this.drpStates = [];
    this.drpCities = [];
    value !== null && value !== undefined && this.regionDropdownService.getAllStatesByCountryNameByCountryShortName(value).subscribe(response => {
      this.drpStates = response?.items ?? [];
      // this.phoneCode = response?.items[0]?.countryPhoneCode;
      this.filteredStates = this.practiceForm?.get("drpStateFilter")?.valueChanges
        .pipe(
          startWith(''),
          map(value => this.drpStates.filter(option =>
            option?.stateName?.toLowerCase()?.includes(value?.toLowerCase() ?? "")
          ))
        );
    }, err => {
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    });
    return this.drpStates.length !== 0;
  }

  // On Change City Dropdown
  onChangeCity(state: string | null | undefined, country: string): boolean {
    this.practiceForm.patchValue({
      drpCity: null
    });
    country = (country == "") ? (this.practiceForm.value.drpCountry == (null || undefined) ? "" : (this.practiceForm.value.drpCountry)) : country;
    this.drpCities = [];
    state !== null && state !== undefined && this.regionDropdownService.getAllCitiesByCountryAndStateNameByCountryShortNameAndStateShortName(country, state).subscribe(response => {

      this.drpCities = response?.items ?? [];

      this.filteredCities = this.practiceForm?.get("drpCityFilter")?.valueChanges
        .pipe(
          startWith(''),
          map(value => this.drpCities.filter(option => option?.cityName?.toLowerCase()?.includes(value?.toLowerCase() ?? "")))
        );
    }, err => {
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    });
    return this.drpCities.length !== 0;
  }


  //GET Account Manager Drop Down
  getUserByTenant() {
    const userList = this.userService.getUsersByTenant().subscribe(
      (response) => {
        this.lstFilterUsers = response;
        this.drpAccountManger = this.lstFilterUsers;
      },
      (err) => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
        this.subscription$.push(userList);
      });
  }

  //Clear Country
  clearCountry() {
    this.Country = 'US';
    this.practiceForm.patchValue({ drpCountry: defaultCountry.US });
  }

  //Clear State
  clearState() {
    this.State = '';
    this.practiceForm.patchValue({ drpState: null });
    this.clearCity();
  }

  //Clear City
  clearCity() {
    this.City = '';
    this.practiceForm.patchValue({ drpCity: null });
  }

  // Save functionality
  savePractice() {
    // console.log('Save Practice called....')
    this.isShowSpinner = true;
    if ((this.sessionPracticeId == null || this.sessionPracticeId == "" || this.sessionPracticeId == undefined)) {
      let phoneNo =null;
      let managerPhoneNo =null;
      if(this.practiceForm.value.txtCellNo){
       phoneNo=this.practiceForm.value.txtCellNo.length===10?
      this.practiceForm.value.txtCellNo.slice(0,3)+"-"+this.practiceForm.value.txtCellNo.slice(3,6)+"-"+this.practiceForm.value.txtCellNo.slice(6,15):
         this.practiceForm.value.txtCellNo;
      }
      if(this.practiceForm.value.txtManPhnNo){
     managerPhoneNo =this.practiceForm.value.txtManPhnNo.length===10?
    this.practiceForm.value.txtManPhnNo.slice(0,3)+"-"+this.practiceForm.value.txtManPhnNo.slice(3,6)+"-"+this.practiceForm.value.txtManPhnNo.slice(6,15):
    this.practiceForm.value.txtManPhnNo;
      }
      const chkIsNewCity  =
      this.drpCities?.filter((x) => x?.cityName === this.practiceForm.value.drpCity)?.[0]
        ?.cityId ?? null;
      let savePractzParameter: CreateUpdatePracticeManagementDTO = {
        practiceName: this.practiceForm.value.txtName,
        practiceAddress: this.practiceForm.value.txtAddress,
        practiceCountry: this.practiceForm.value.drpCountry,
        practiceCity: this.practiceForm.value.drpCity,
        practiceState: this.practiceForm.value.drpState,
        practiceZipCode: this.practiceForm.value.txtZipCode,
        practicePhone: phoneNo || null,
        practiceFax: this.practiceForm.value.txtFaxNo || null,
        practiceWebsite: this.practiceForm.value.txtWebSite || null,
        practiceManager: this.practiceForm.value.txtManager || null,
        practiceEmail: this.practiceForm.value.txtEmail.toLowerCase() || null,
        accountManager: this.practiceForm.value.drpAccountManger || null,
        accountManagerEmail: this.practiceForm.value.txtmanEmail.toLowerCase() || null,
        accountManagerPhone:managerPhoneNo || null,
        comments: this.practiceForm.value.txtComments || null,
        tatHours: this.practiceForm.value.txttat || 0,
        isNewCity: chkIsNewCity==1?true: false,
        // medicareIDTF: false,
        // outSourceReferrals: false,
      };
      // console.log('Save clicked')
      this.practiceManagementService.create(savePractzParameter).subscribe(response => {
        var lstSavedCity =this.drpCities?.filter((x) => x?.cityName === this.practiceForm.value.drpCity);
        if(lstSavedCity.length>0){
          lstSavedCity.forEach(element => {
            element.cityId=null;
           });
          }
        this.toastr.success('Saved Successfully', 'Success');
        if (this.childComponent) {
          this.childComponent.getPracticeNotes()
        }
        // this.resetPractice();
        this.isShowSpinner = false;
        this.sessionPracticeId = response.id;
        this.faxlogForm.enable();
        this.communicationService.triggerPhysicianTab(this.sessionPracticeId);

        // console.log('Child api called')
      }, err => {

        const data: HttpErrorResponse = err;
        this.isShowSpinner = false;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      });
    } else {
      let phoneNo =null;
      let managerPhoneNo =null;
      if(this.practiceForm.value.txtCellNo){
       phoneNo=this.practiceForm.value.txtCellNo.length===10?
      this.practiceForm.value.txtCellNo.slice(0,3)+"-"+this.practiceForm.value.txtCellNo.slice(3,6)+"-"+this.practiceForm.value.txtCellNo.slice(6,15):
      this.practiceForm.value.txtCellNo;
      }
   if(this.practiceForm.value.txtManPhnNo){
     managerPhoneNo =this.practiceForm.value.txtManPhnNo.length===10?
    this.practiceForm.value.txtManPhnNo.slice(0,3)+"-"+this.practiceForm.value.txtManPhnNo.slice(3,6)+"-"+this.practiceForm.value.txtManPhnNo.slice(6,15):
    this.practiceForm.value.txtManPhnNo;
   }
   const chkIsNewCity  =
   this.drpCities?.filter((x) => x?.cityName === this.practiceForm.value.drpCity)?.[0]
     ?.cityId ?? null;
      let savePractzParameter: CreateUpdatePracticeManagementDTO = {
        practiceName: this.practiceForm.value.txtName,
        practiceAddress: this.practiceForm.value.txtAddress,
        practiceCountry: this.practiceForm.value.drpCountry,
        practiceCity: this.practiceForm.value.drpCity,
        practiceState: this.practiceForm.value.drpState,
        practiceZipCode: this.practiceForm.value.txtZipCode,
        practicePhone: phoneNo || null,
        practiceFax: this.practiceForm.value.txtFaxNo || null,
        practiceWebsite: this.practiceForm.value.txtWebSite || null,
        practiceManager: this.practiceForm.value.txtManager || null,
        practiceEmail: this.practiceForm.value.txtEmail.toLowerCase() || null,
        accountManager: this.practiceForm.value.drpAccountManger || null,
        accountManagerEmail: this.practiceForm.value.txtmanEmail.toLowerCase() || null,
        accountManagerPhone: managerPhoneNo || null,
        comments: this.practiceForm.value.txtComments || null,
        tatHours: this.practiceForm.value.txttat || 0,
        isNewCity: chkIsNewCity==1?true: false,
      };
      this.practiceManagementService.update(this.sessionPracticeId, savePractzParameter).subscribe(response => {
        var lstSavedCity =this.drpCities?.filter((x) => x?.cityName === this.practiceForm.value.drpCity);
        if(lstSavedCity.length>0){
          lstSavedCity.forEach(element => {
            element.cityId=null;
           });
          }
        this.toastr.success('Updated Successfully', 'Success');
        if (this.childComponent) {
          this.childComponent.getPracticeNotes()
        }
        // this.resetPractice();
        this.isShowSpinner = false;
        this.sessionPracticeId = response.id;
      }, err => {
        this.isShowSpinner = false;
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      });
    }
  }

  //Update Character Count for TextArea
  updateCharacterCount(maxlength: number) {
    // Ensure the text length does not exceed the maximum length
    if (this.commentsText?.length > maxlength) {
      this.commentsText = this.commentsText?.substr(0, maxlength);
    }
  }

  //Update GET By Practice ID
  getPracticeById() {

    this.practiceManagementService.get(this.sessionPracticeId).subscribe(response => {

      this.stateName = response?.practiceState;
      this.CountryName = defaultCountry.US;

      this.CountryName != null && this.CountryName !== "" && this.CountryName != undefined &&
        this.onChangeState(defaultCountry.US) && this.practiceForm.patchValue({
          drpCountry: this.CountryName,
        });
      this.stateName != null && this.stateName !== "" && this.stateName != undefined &&
        this.onChangeCity(this.stateName, defaultCountry.US) && this.practiceForm.patchValue({
          drpState: this.stateName,
          drpCity: response?.practiceCity
        });

      this.practiceForm.patchValue({
        txtName: response?.practiceName,
        txtAddress: response?.practiceAddress,
        drpCountry: defaultCountry.US,
        drpState: response?.practiceState,
        drpCity: response?.practiceCity,
        txtZipCode: response?.practiceZipCode,
        txtCellNo: response?.practicePhone,
        txtFaxNo: response?.practiceFax,
        txtWebSite: response?.practiceWebsite,
        txtManager: response?.practiceManager,
        txtEmail: response?.practiceEmail || '',
        drpAccountManger: response?.accountManager,
        txtmanEmail: response?.accountManagerEmail || '',
        txtManPhnNo: response?.accountManagerPhone,
        txtComments: response?.comments,
        // txttat: response?.tatHours,
      });
      if (response.tatHours !== 0) {
        this.practiceForm.patchValue({
          txttat: response?.tatHours,
        });
      }
      this.practiceForm.markAsDirty();
    }, err => {
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    })
  }

  resetPractice(): void {
    const resetFields = {
      txtEmail:'',
      txtmanEmail:''
    };
    this.practiceForm.reset(resetFields);
    this.clearCountry();
    this.clearState();
    this.clearCity();
    const currentCountry = this.practiceForm.value.drpCountry;
    if (currentCountry) {
      // Reset the state dropdown value to null
      this.practiceForm.get("drpState")?.setValue(null);
      this.onChangeState(currentCountry);
    }
  }

  backToPracticeList() {
    this.router.navigate(['/practicemanagementlist/'])
  }

  openDatePicker() {
    this.txtDateValue.open();
  }

  chosenMonthHandler(normalizedMonth: any, datepicker: any) {
    datepicker.close();
    this.dateValue =
      normalizedMonth.toLocaleString('default', { month: 'short' }) +
      '/' +
      normalizedMonth.getFullYear();
    this.selectedNode =
      normalizedMonth.getMonth() + 1 + '/' + normalizedMonth.getFullYear();
    this.selectedMonth = normalizedMonth.getMonth() + 1;
    this.selectedYear = normalizedMonth.getFullYear();
    this.getPracticeFaxLog(this.selectedMonth, this.selectedYear);
  }

  //GET getPractice FAX Log List In Table
  getPracticeFaxLog(month:number,year:number) {
    this.isLoading = true;
    this.tableData$?.unsubscribe();
    const getFaxNumberbleData = this.inboundDocumentservice
      .getFaxLogByIMonthAndIYearAndPracticeId(month, year, this.sessionPracticeId)
      .subscribe(
        (response) => {
          this.practiceFaxlog = response;
          this.isLoading = false;
        },
        (err) => {
          this.isLoading = false;
          this.practiceFaxlog = [];
          const data: HttpErrorResponse = err;
          Swal.fire({
            icon: 'info',
            text: data?.error?.error?.message,
          });
        }
      );
    this.tableData$ = getFaxNumberbleData;
  }
  cityDrpDownMessage(){
    if (this.practiceForm.value.drpState === ''||this.practiceForm.value.drpState === null || this.practiceForm.value.drpState === undefined) {
      return 'No Matches found';
    }
    else if( (this.practiceForm.value.drpCityFilter === ''||this.practiceForm.value.drpCityFilter === null || this.practiceForm.value.drpCityFilter === undefined)?
    (this.practiceForm.value.drpCityFilter !== ''&&this.practiceForm.value.drpCityFilter !== null && this.practiceForm.value.drpCityFilter !== undefined)
    : (this.practiceForm.value.drpCityFilter).trim().length>40){
      return 'maximum 40 characters only allowed';
    } else {
      return 'Enter a City Name';
    }
  }


  onCityKeyPress(event: KeyboardEvent) {
    const isAlphabetOrSpace = /^[a-zA-Z\s]$/.test(event.key);
    if (!isAlphabetOrSpace) {
      event.preventDefault(); // Prevent the input if the key is not an alphabet or space
    }
  }

  @ViewChild('citySelect') citySelect: MatSelect;
  focusOut() {
    const searchValue = this.practiceForm.get('drpCityFilter').value;
    const stateValue= this.practiceForm.get('drpState').value;
    let noEntriesFound =
      this.drpCities?.filter((a) =>
        a.cityName
          ?.trim()
          ?.toLowerCase()
          ?.includes(searchValue?.trim()?.toLowerCase())
      ).length == 0
        ? true
        : false;
    if (noEntriesFound && searchValue && searchValue.length <= 40 &&
      stateValue !== undefined &&
      stateValue !== null &&
      stateValue !== ''
    ) {
      Swal.fire({
        title: 'City not registered in Google Api',
        text: 'Do you want to include this city?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#D3D3D3',
        confirmButtonText: 'Add',
      }).then((result) => {
        if (result.isConfirmed) {
          this.addNewCity(searchValue);
        }
      });
    } else {
      if (searchValue && noEntriesFound) {
        this.citySelect.close();
      }
    }
  }

  addNewCity(cityValue: string) {
    if (
      cityValue !== undefined &&
      cityValue !== null &&
      cityValue !== '' &&
      cityValue.length <= 40
    ) {
      const newCityName = cityValue.trim().toLowerCase();
      var alreadyAdd = this.drpCities?.filter(
        (obj) => obj.cityName.trim().toLowerCase() === newCityName
      );
      console.log(alreadyAdd);
      if (alreadyAdd.length === 0) {
        let newCity = {
          cityName: cityValue.trim(),
          cityId: 1,
        };
        this.drpCities?.push(newCity);
        this.drpCities.sort((a, b) => a.cityName.localeCompare(b.cityName));
        console.log(this.drpCities);
        this.practiceForm.patchValue({
          drpCity: cityValue,
        });
        this.citySelect.open();
        setTimeout(() => {
          this.citySelect.close();
        }, 100);
      }
    }
  }















  // addNewCity(){

  //   if((this.practiceForm.value.drpCityFilter)!== undefined && (this.practiceForm.value.drpCityFilter) !==null
  //   && (this.practiceForm.value.drpCityFilter).trim() !=='' && (this.practiceForm.value.drpCityFilter).trim().length<=40)
  //   {
  //     // const objWithIdIndex = this.drpCities.findIndex((obj) => obj.cityId === 1);
  //     // if(objWithIdIndex !== -1){
  //     // this.drpCities.splice(objWithIdIndex, 1);
  //     // }
  //     const newCityName=this.practiceForm.value.drpCityFilter.trim().toLowerCase();
  //     var alreadyAdd=this.drpCities?.filter((obj) => (obj.cityName).trim().toLowerCase() === newCityName);
  //     if(alreadyAdd.length===0){

  //  let newCity = {
  //     cityName:this.practiceForm.value.drpCityFilter,
  //     cityId:1
  //   };

  //   this.drpCities?.push(newCity);
  //   this.practiceForm.patchValue({ drpCity: this.practiceForm.value.drpCityFilter });
  // }
  // }
  // }

  // whitespaceValidator(control: FormControl): { [key: string]: boolean } {
  //   const isWhitespace = (control.value || '').trim().length === 0;
  //   const isValid = !isWhitespace;
  //   return isValid ? null : { whitespace: true };
  // }
}
