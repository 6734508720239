import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatOptionModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FullCalendarModule } from '@fullcalendar/angular';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { ButtonAllModule, CheckBoxAllModule, SwitchAllModule } from '@syncfusion/ej2-angular-buttons';
import { DatePickerAllModule, DateTimePickerAllModule, TimePickerAllModule } from '@syncfusion/ej2-angular-calendars';
import { DropDownListAllModule, MultiSelectAllModule } from '@syncfusion/ej2-angular-dropdowns';
import { MaskedTextBoxModule, NumericTextBoxAllModule, TextBoxAllModule, UploaderAllModule } from '@syncfusion/ej2-angular-inputs';
import { ContextMenuAllModule, ToolbarAllModule, TreeViewModule } from '@syncfusion/ej2-angular-navigations';
import { RecurrenceEditorAllModule, ScheduleAllModule } from '@syncfusion/ej2-angular-schedule';
import { DropDownButtonAllModule } from '@syncfusion/ej2-angular-splitbuttons';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { TextMaskModule } from 'angular2-text-mask';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { NgxMaskModule } from 'ngx-mask';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { SharedModule } from 'projects/shared/src/app/shared.module';
import { CalendarViewComponent } from './calendar-view/calendar-view.component';
import { NgxMatDatetimePickerModule, NgxMatNativeDateModule, NgxMatTimepickerModule } from '@angular-material-components/datetime-picker';
import { AdminCalenderComponent } from './admin-calender/admin-calender.component';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CalendarListComponent } from './calendar-list/calendar-list.component';
import { CalenderScheduleComponent } from './calender-schedule/calender-schedule.component';
import { EditCalenderComponent } from './edit-calender/edit-calender.component';
import { OnTimeScheduleCreateEditComponent } from './on-time-schedule-create-edit/on-time-schedule-create-edit.component';
import { OntimeScheduleNewComponent } from './ontime-schedule-new/ontime-schedule-new.component';
import { DataTablesModule } from 'angular-datatables';
import { AppointmentScheduleComponent } from './appointment-schedule/appointment-schedule.component';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { CalendarListV1Component } from './calendar-list-v1/calendar-list-v1.component';
import { CalendarListTableComponent } from './calendar-list-v1/calendar-list-table/calendar-list-table.component';
import { CalenderScheduleV2Component } from './calender-schedule-v2/calender-schedule-v2.component';
import { CalenderScheduleAddModalComponent } from './calender-schedule-add-modal/calender-schedule-add-modal.component';
// import { ImageAnnotationComponent } from './image-annotation/image-annotation.component';
import { CalendarScheduleAddModalV1NewComponent } from './calender-schedule-add-modal/calendar-schedule-add-modal-v1-new/calendar-schedule-add-modal-v1-new.component';
import { CalendarScheduleV3Component } from './calendar-schedule-v3/calendar-schedule-v3.component';

const providers: any = [];
@NgModule({
  declarations: [
    AppComponent,
    OnTimeScheduleCreateEditComponent,
    OntimeScheduleNewComponent,
    CalenderScheduleComponent,
    AdminCalenderComponent,
    CalendarListComponent,
    CalendarViewComponent,
    EditCalenderComponent,
      AppointmentScheduleComponent,
      CalendarListV1Component,
      CalendarListTableComponent,
      CalenderScheduleV2Component,
      CalenderScheduleAddModalComponent,
      // ImageAnnotationComponent,
      CalendarScheduleAddModalV1NewComponent,
      CalendarScheduleV3Component
   ],
  imports: [
    DropDownButtonAllModule,
    TreeViewModule,
    DropDownListAllModule,
    MultiSelectAllModule,
    MaskedTextBoxModule,
    UploaderAllModule,
    ToolbarAllModule,
    ContextMenuAllModule,
    NgxMaterialTimepickerModule,
    ToolbarAllModule,
    ButtonAllModule,
    CheckBoxAllModule,
    SwitchAllModule,
    DataTablesModule,
    DatePickerAllModule,
    TimePickerAllModule,
    DateTimePickerAllModule,
    NumericTextBoxAllModule,
    TextBoxAllModule,
    ScheduleAllModule,
    RecurrenceEditorAllModule,
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    BrowserModule,
    AppRoutingModule,
    CommonModule,
    AppRoutingModule,
    NgSelectModule,
    NgxMaskModule.forRoot(),
    NgxMatSelectSearchModule,
    TextMaskModule,
    MatDatepickerModule,
    MatListModule,
    MatGridListModule,
    MatCardModule,
    MatButtonModule,
    MatDialogModule,
    MatFormFieldModule,
    MatStepperModule,
    MatIconModule,
    MatSnackBarModule,
    MatRadioModule,
    MatSelectModule,
    MatCheckboxModule,
    NgbModule,
    MatInputModule,
    MatOptionModule,
    MatDatepickerModule,
    ReactiveFormsModule,
    FormsModule,
    MatDividerModule,
    MatTableModule,
    MatProgressSpinnerModule,
    MatPaginatorModule,
    MatTooltipModule,
    SharedModule,
    MatProgressBarModule,
    MatExpansionModule,
    MatSlideToggleModule,
    FullCalendarModule,
    NgxMatDatetimePickerModule,
    NgxMatTimepickerModule,
    NgxMatNativeDateModule,
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory
    }),
    NgxDaterangepickerMd.forRoot()
    //NgxMatDrpModule,
    // NgxMatDatetimePickerModule,
    // NgxMatDatetimePickerModule
  ],
  providers: [
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
@NgModule({})
export class onTimeScheduleModule {
  static forRoot(): ModuleWithProviders<onTimeScheduleModule> {
    return {
      ngModule: AppModule,
      providers
    };
  }
}
