<mat-dialog-content class="mat-typography">
    <div class="row">
        <div class="col-lg-6">
            <h2> Reports</h2>
        </div>
        <div class="col-lg-6">
        </div>
    </div>
    <hr />
    <mat-card-content>
        <!-- Weekly Reports -->
        <div class="row">
            <div class="col-lg-12">
                <mat-accordion class="example-headers-align" multi>
                    <mat-expansion-panel (afterExpand)="getWeeklyReports()">
                        <mat-expansion-panel-header>
                            <mat-panel-title class="customThemeClass font-size-18">
                                <b>Weekly Reports</b>
                            </mat-panel-title>
                        </mat-expansion-panel-header>

                        <mat-card class="container">
                            <section>
                                <h4><strong class="customThemeClass">Period :</strong> <b
                                        class="ml-2">{{sumrySrcMDPtReport?.weekPatientCountReport?.currentWeekPeriod||0}}</b>
                                </h4>
                                <h4><strong class="customThemeClass">Current Week Verification :</strong>
                                    <b class="ml-2">{{
                                        (sumrySrcMDPtReport?.weekPatientCountReport?.currentWeekVerificationCount||0)}}</b>
                                </h4>
                                <h4><strong class="customThemeClass">Previous Week Verification
                                        :</strong> <b class="ml-2">{{
                                        (sumrySrcMDPtReport?.weekPatientCountReport?.previousWeekVerificationCount)||0}}</b>
                                </h4>
                                <h4> <strong class="customThemeClass">Increase or Decrease % :
                                    </strong> <b
                                        class="ml-2">{{(sumrySrcMDPtReport?.weekPatientCountReport?.verificationCountDeviation||0)
                                        + " %"}}</b>
                                </h4>

                                <h4><strong class="customThemeClass">Current Week Patient :</strong>
                                    <b
                                        class="ml-2">{{sumrySrcMDPtReport?.weekPatientCountReport?.currentWeekPatientCount||0}}</b>
                                </h4>
                                <h4><strong class="customThemeClass">Previous Week Patient
                                        :</strong> <b class="ml-2">{{
                                        (sumrySrcMDPtReport?.weekPatientCountReport?.previousWeekPatientCount)||0}}</b>
                                </h4>
                                <h4> <strong class="customThemeClass">Increase or Decrease % :
                                    </strong> <b
                                        class="ml-2">{{(sumrySrcMDPtReport?.weekPatientCountReport?.patientCountDeviation||0)
                                        + " %"}}</b>
                                </h4>

                            </section>
                        </mat-card>

                        <div class="row">
                            <div class="col-6 text-align-center">
                                <button mat-button (click)="openReportsSummary('WeeklyReports',null,null,'SourceMd')"
                                    class="buttonColor mb-2 mr-2 mt-3">View Week
                                    Summary</button>
                            </div>
                            <div class="col-6 text-align-center">
                                <button mat-button (click)="openReportsSummary('WeeklyTrends',null,null,'SourceMd')"
                                    class="buttonColor mb-2 mr-2 mt-3">Last Four Week
                                    Trends</button>
                            </div>
                        </div>
                    </mat-expansion-panel>
                </mat-accordion>
            </div>
        </div>
        <!-- Monthly Report -->
        <div class="row mt-2">
            <div class="col-lg-12">
                <mat-accordion class="example-headers-align" multi>
                    <mat-expansion-panel (afterExpand)="getWeeklyReports()">
                        <mat-expansion-panel-header>
                            <mat-panel-title class="customThemeClass font-size-18">
                                <b>Monthly Reports</b>
                            </mat-panel-title>
                        </mat-expansion-panel-header>

                        <mat-card class="container">
                            <section>
                                <h4><strong class="customThemeClass">Period :</strong> <b
                                        class="ml-2">{{sumrySrcMDPtReport?.monthPatientCountReport?.currentMonthPeriod||0}}</b>
                                </h4>
                                <h4><strong class="customThemeClass">Current Month Verification :</strong>
                                    <b class="ml-2">{{
                                        (sumrySrcMDPtReport?.monthPatientCountReport?.currentMonthVerificationCount)||0}}</b>
                                </h4>
                                <h4><strong class="customThemeClass">Previous Month Verification
                                        :</strong> <b class="ml-2">{{
                                        (sumrySrcMDPtReport?.monthPatientCountReport?.previousMonthVerificationCount)||0}}</b>
                                </h4>
                                <h4> <strong class="customThemeClass">Increase or Decrease % :
                                    </strong> <b
                                        class="ml-2">{{(sumrySrcMDPtReport?.monthPatientCountReport?.verificationCountDeviation||0)
                                        + " %"}}</b>
                                </h4>

                                <h4><strong class="customThemeClass">Current Month Patient :</strong>
                                    <b
                                        class="ml-2">{{sumrySrcMDPtReport?.monthPatientCountReport?.currentMonthPatientCount||0}}</b>
                                </h4>
                                <h4><strong class="customThemeClass">Previous Month Patient
                                        :</strong> <b class="ml-2">{{
                                        (sumrySrcMDPtReport?.monthPatientCountReport?.previousMonthPatientCount)||0}}</b>
                                </h4>
                                <h4> <strong class="customThemeClass">Increase or Decrease % :
                                    </strong> <b
                                        class="ml-2">{{(sumrySrcMDPtReport?.monthPatientCountReport?.patientCountDeviation||0)
                                        + " %"}}</b>
                                </h4>

                            </section>
                        </mat-card>

                        <div class="row">
                            <div class="col-6 text-align-center">
                                <button mat-button (click)="openReportsSummary('MonthlyReports',null,null,'SourceMd')"
                                    class="buttonColor mb-2 mr-2 mt-3">View Month
                                    Summary</button>
                            </div>
                            <div class="col-6 text-align-center">
                                <button mat-button (click)="openReportsSummary('MonthlyTrends',null,null,'SourceMd')"
                                    class="buttonColor mb-2 mr-2 mt-3">Last Four Month
                                    Trends</button>
                            </div>
                        </div>
                    </mat-expansion-panel>
                </mat-accordion>
            </div>
        </div>
        <!--Custome Report-->
        <div class="row mt-2">
          <div class="col-lg-12">
            <mat-accordion class="example-headers-align" multi>
              <mat-expansion-panel>
                <mat-expansion-panel-header>
                  <mat-panel-title class="customThemeClass font-size-18">
                    <b>Custom Period</b>
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <div class="row">
                  <h3 class="customThemeClass ml-2 col-12">Select Year
                  </h3>
                </div>
                <!-- Year 1 -->
                <div class="row">
                  <div class="ml-3">
                    <button *ngIf="hideInitialButton" mat-button class="buttonColor mb-2 mr-2 mt-1 ml-3"
                      [value]="previousYear" (click)="onChange21(previousYear)"> 2021
                    </button>
                  </div>
                  <div>
                    <button *ngIf="hideInitialButton" mat-button class="buttonColor mb-2 mr-2 mt-1 ml-3"
                      [value]="currentYear" (click)="onChange21(currentYear)"> 2022
                    </button>
                  </div>
                </div>
                <!-- Year 2 -->
                <div class="row">
                  <div class="col-md-12  text-right">
                    <div>
                      <button *ngIf="hide2021YearButton2" mat-button class="buttonColor mb-2 mr-2 mt-1 ml-3">
                        2021
                      </button>
                    </div>
                    <div>
                      <button *ngIf="hide2022YearButton2" mat-button class="buttonColor mb-2 mr-2 mt-1 ml-3">
                        2022
                      </button>
                    </div>
                  </div>
                </div>
                <!-- Quarter 1 -->
                <div class="row" *ngIf="hideQuarter1button">
                  <h3 class="customThemeClass ml-3 col-12">Select Quarter
                  </h3>
                  <div class="col-3">
                    <button mat-button class="buttonColor mb-2 mr-2 mt-1 ml-3" (click)="onChangeQuarter('Jan')">
                      Q1 -
                      Jan,Feb,Mar
                    </button>
                  </div>
                  <div class="col-3">
                    <button mat-button class="buttonColor mb-2 mr-2 mt-1 ml-3" (click)="onChangeQuarter('Apr')">
                      Q2 -
                      Apr,May,Jun
                    </button>
                  </div>
                </div>

                <div class="row" *ngIf="hideQuarter1button">
                  <div class="col-3">
                    <button mat-button class="buttonColor mb-2 mr-2 mt-1 ml-3"
                      (click)="onChangeQuarter('July')"> Q3 -
                      Jul,Aug,Sept
                    </button>
                  </div>
                  <div class="col-3">
                    <button mat-button class="buttonColor mb-2 mr-2 mt-1 ml-3" (click)="onChangeQuarter('Oct')">
                      Q4 -
                      Oct,Nov,Dec
                    </button>
                  </div>
                </div>
                <!-- Quarter 2 -->
                <div *ngIf="hideQuarter2button">
                  <div class="row ">
                    <h3 *ngIf="selectQuarter" class="customThemeClass ml-2 col-12">Select Quarter
                    </h3>
                  </div>

                  <div class="row mr-2">
                    <div class="col-9">
                    </div>
                    <div class="col-3">
                      <button mat-button *ngIf="hideJanButton" class="buttonColor mb-2 mr-2 mt-1 ml-3"> Q1
                        -
                        Jan,Feb,Mar
                      </button>
                    </div>
                  </div>
                  <div class="row mr-2">
                    <div class="col-9">
                    </div>
                    <div class="col-3">
                      <button mat-button *ngIf="hideAprButton" class="buttonColor mb-2 mr-2 mt-1 ml-3"> Q2
                        -
                        Apr,May,Jun
                      </button>
                    </div>
                  </div>

                  <div class="row mr-2">
                    <div class="col-9">
                    </div>
                    <div class="col-3">
                      <button mat-button *ngIf="hideJulyButton" class="buttonColor mb-2 mr-2 mt-1 ml-3"> Q3
                        -
                        Jul,Aug,Sept
                      </button>
                    </div>
                  </div>
                  <div class="row mr-2">
                    <div class="col-9">
                    </div>
                    <div class="col-3">
                      <button mat-button *ngIf="hideOctButton" class="buttonColor mb-2 mr-2 mt-1 ml-3"> Q4
                        -
                        Oct,Nov,Dec
                      </button>
                    </div>
                  </div>
                </div>
                <!-- Details Loaded Button -->
                <div class="row" *ngIf="detailsHide">
                  <div class="col-4">
                    <button mat-button class="mb-2 mr-2 mt-1">
                      Detials Will be loaded.......
                    </button>
                  </div>
                </div>
              </mat-expansion-panel>
            </mat-accordion>
          </div>
        </div>
    </mat-card-content>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button class="buttonCancelColor" mat-button mat-dialog-close>Close</button>
</mat-dialog-actions>