import { ListService } from '@abp/ng.core';
import { SelectionModel } from '@angular/cdk/collections';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { Store } from '@ngxs/store';
import { DocumentMaxandTypeLimits, DocumentUploadTypes } from 'projects/shared/src/app/enums/allenums.enum';
import { TableService } from 'projects/shared/src/app/table.service';
import { DateValidator } from 'projects/shared/src/app/validations/date-validator';
import { combineLatest, defer, from, Subscription } from 'rxjs';
import { concatMap, groupBy, map, mergeMap, reduce, takeLast, toArray } from 'rxjs/operators';
import Swal from 'sweetalert2';

import { BranchOfficeDTO, DocumentTypeDTO, ReviewReasonDTO, ReviewStatusDTO, SortingTypeDTO } from '../dropdown-proxy/dropdown-management/dropdowns/dto/patient/models';
import { PatientMasterDropdownService } from '../dropdown-proxy/dropdown-management/dropdowns/master-dropdown-service/patient-master-dropdown.service';
import { PatientDropdowns } from '../dropdown-proxy/dropdown-management/dropdowns/patient-dropdowns.enum';
import { DocumentsService } from '../patient-proxy/controllers';
import { DocumentService, PatientService } from '../patient-proxy/patient';
import { ToastrService } from "ngx-toastr";
import { AutoIntakeDocumentDetailsDTO, PatientDocumentDTO, PatientDTO, PatientLockDTO, SavePatientDocumentBlobDTO, UpdateDocumentBlobDTO } from '../patient-proxy/patient/dto';

let listOfDocuments: PatientDocumentDTO[] = [];

@Component({
  selector: 'app-patient-document',
  templateUrl: './patient-document.component.html',
  styleUrls: ['./patient-document.component.scss'],
  providers: [ListService]

})
export class PatientDocumentComponent implements OnInit, OnDestroy {
  @Output() patientContact: EventEmitter<string> = new EventEmitter();
  @Output() saveDocumentFormState: EventEmitter<any> = new EventEmitter<any>();
  @Output() nextTabMoveOnSaveEmitter: EventEmitter<string> = new EventEmitter<string>();

  @Input() patientID: string = '';
  intCheckedDocuments: number = 0;
  drpDocumentTypeList: string[] = ["Select", "RX", "Others", "SleepApena"];
  strSelectedPdfPath: string = "";
  strPageType: string = "document";
  documentForm: FormGroup;
  documentSearchForm: FormGroup;
  documentGroupedByType: Partial<documentGroupIngListType>[] = [];
  isCheckAllDocuments: boolean = false;
  isDocumentEditSaveButtonHidden: boolean = true;
  arrUnsortedDocumentList: PatientDocumentDTO[] = [];
  arrMergeDocumentList: PatientDocumentDTO[] = [];
  arrSortedDocumentList: documentListType[] = [];
  drpDocumentTypeLoop: DocumentTypeDTO[] = [];
  drpIsActiveStatus: any[] = [];
  drpReviewStatusLoop: ReviewStatusDTO[] = [];
  drpReviewReasonLoop: ReviewReasonDTO[] = [];
  drpBranchLoop: BranchOfficeDTO[] = [];
  drpDocumentUploadType: SortingTypeDTO[] = [];
  displayedColumns: string[] = ['position', 'name', 'weight', 'symbol'];
  showFileList: any[] = [];
  showErrorForFileType: boolean;
  showExtensionInfo: string = "";
  showErrorForExtension: boolean = false;
  isDocumentForButtonDisable: boolean = false;
  isPatientLockedByCurrentUser: boolean = true;
  strLoginUserId: string = '';
  show: boolean = false;
  OriginalDocumentList: any[] = [];
  nameAndSizeArray: any[] = [];
  FileLength: number = 0;
  filesname: string[] = [];
  showErrorForFileLength: boolean = false;
  patientDocumentId: string = "";
  parentPatientDocumentID: string = "";
  drpPatientData: { name: string, data: string, id: string }[] = [];
  sortDocumentCatorgory: number = 0;
  selectedDocumentforUpdate: Partial<PatientDocumentDTO> = null;
  isShowSpinner: boolean = false;
  isShownDocumentDeleteIcon = false;
  @Input() strPdfSource: string = '';
  @Input() patient: any;
  public arrDisplayedColumns: string[] = [];
  dataSource: MatTableDataSource<any> = new MatTableDataSource<any>([]);
  mergeDataSource: MatTableDataSource<any> = new MatTableDataSource<any>([]);

  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild(MatSort, { static: false }) mergeSort: MatSort;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatPaginator, { static: false }) mergePaginator: MatPaginator;
  @ViewChild(MatTable, { static: false }) tableRef: MatTable<any>;
  selection = new SelectionModel<PatientDocumentDTO>(true, [], true);
  arrDisplayedMergeColumns: string[] = [];
  subscription$: Subscription[] = []
  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource?.data?.filter(row => !row.clearingHouseType).length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected()
      ? this.selection.clear()
      : this.dataSource?.data?.forEach(row => {
        if (!row.clearingHouseType && row.clearingHouseType !== '') {
          this.selection.select(row);
        }
      });
  }

  extractionUploadedDocuments: {
    documentUploadType: DocumentUploadTypes, documentDate: string, blobName: string, fileName: string, processedStatus: number, autoIntakePatientDocumentId: string
  }[] = [];
  arrDisplayedColumnsExtraction: string[] = [];
  isShowProcessBar: boolean = false;
  constructor(
    private documentService: DocumentService,
    private patientDropdownService: PatientMasterDropdownService,
    private fb: FormBuilder,
    public list: ListService,
    private table: TableService,
    private documentServices: DocumentsService,
    private dateValidator: DateValidator,
    public patientService: PatientService,
    private toastr: ToastrService,
    private store: Store

  ) {
    this.getDropdown();

  }


  // dropTable(event: CdkDragDrop<any[]>) {
  //   const prevIndex = this.arrUnsortedDocumentList.findIndex((d) => d === event.item.data);
  //   moveItemInArray(this.arrUnsortedDocumentList, prevIndex, event.currentIndex);
  //   this.tableRef.renderRows();
  //   this.dataSource = new MatTableDataSource(this.arrUnsortedDocumentList);
  //   this.dataSource.sort = this.sort;
  //   this.dataSource.paginator = this.paginator;
  // }
  ngOnDestroy(): void {
    this.subscription$?.forEach((sub) => {
      sub && sub?.unsubscribe();
    });
  }
  //! merge selected documents
  mergeAndUpdateSelectedDocuments() {
    // mergeFilesByPatientIdAndAutoUploadDocumentId = (patientId: string, autoUploadDocumentId: string) =>
    // updateFileByLstPatientDocumentBlobDTOAndPatientObjectID = (lstPatientDocumentBlobDTO: UpdateDocumentBlobDTO[], patientObjectID: string) =>
    this.isShowSpinner = true;
    if (this.selection?.selected) {
      let lstPatientDocumentBlobDTO: UpdateDocumentBlobDTO[] = []
      let patientObjectID: string = this.patientID;
      let parentPatientDocumentId: string = "";

      this.selection.selected.forEach(element => {
        element = {
          isVerified: 1,
          patientDocumentID: element?.patientDocumentID,
          patientID: element?.patientID,
          fileName: element?.fileName,
          blobName: element?.blobName,
          tags: element?.tags,
          documentTypeId: element?.documentTypeId,
          reviewStatus: element?.reviewStatus,
          reviewReason: element?.reviewReason,
          // documentDate: element?.documentDate === "" ? undefined : element?.documentDate ?? "",
          note: element?.note,
          documentUploadType: element?.documentUploadType,
          parentPatientDocumentID: element?.parentPatientDocumentID,
          documentType: element?.documentType,
          approvalStatus: element?.approvalStatus,
          approvedByWhom: element?.approvedByWhom,
          approvedOn: element?.approvedOn,
          fileSize: element?.fileSize,
          documentOrderNumber: element?.documentOrderNumber,
          isSorted: element?.isSorted,
          documentStartDate: element?.documentStartDate,
          documentEndDate: element?.documentEndDate,
          docStatus: element?.docStatus,
        };
        parentPatientDocumentId = element?.parentPatientDocumentID[0] ?? null;
        lstPatientDocumentBlobDTO.push(element);
      });
      const uploadFile = this.documentServices.updateFileByLstPatientDocumentBlobDTOAndPatientObjectID(lstPatientDocumentBlobDTO, patientObjectID).subscribe(value => {
        const mergeFile = this.documentServices.mergeFilesByPatientIdAndParentPatientDocumentId(patientObjectID, parentPatientDocumentId).subscribe(value => {
          this.isShowSpinner = false;
          this.selection.clear();
          // Swal.fire({ title: 'Success', html: 'Saved Successfully', icon: 'success', timer: 3000, timerProgressBar: true });
          this.toastr.success('Saved Successfully','Success')
          setTimeout(() => {
            this.resetDocumentForm();
          }, 100);
        }, err => {
          this.selection.clear();
          const data: HttpErrorResponse = err;
          this.isShowSpinner = false;
          Swal.fire({
            icon: 'info',
            text: data?.error?.error?.message,
          });
          setTimeout(() => {
            this.resetDocumentForm();
          }, 100);
        })
        this.subscription$.push(mergeFile);
      }, err => {
        this.selection.clear();
        const data: HttpErrorResponse = err;
        this.isShowSpinner = false;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
        setTimeout(() => {
          this.resetDocumentForm();
        }, 100);
      })
      this.subscription$.push(uploadFile);
    }
  }
  ngOnInit(): void {
    const LoginUserId = this.table.getLoginUserId().subscribe(val => {
      this.strLoginUserId = val;
    })
    this.subscription$.push(LoginUserId);
    //
    const documentDelete = this.table.getDocumentDelete().subscribe(value => {
      if (value == true) {
        this.isShownDocumentDeleteIcon = true;
      }
      else {
        this.isShownDocumentDeleteIcon = false;
      }
    }, err => {
    });
    this.subscription$.push(documentDelete)
    //
    this.arrDisplayedMergeColumns = ['options','select', 'OrderId', 'ItemId', 'ItemName', ];
    this.arrDisplayedColumns = ['options','OrderId', 'ItemId', 'documentStartDate', 'documentEndDate', 'documentStatus', ];
    this.arrDisplayedColumnsExtraction = [ 'options','fileName', 'documentDate', 'documentUploadType',];
    this.createDocumentForm();
    //
    if (this.documentForm.valid || this.documentForm.dirty) {
      this.saveDocumentFormState.emit(this.documentForm);
    }
  }
  // alert for reload
  // @HostListener('window:beforeunload', ['$event'])
  // beforeUnloadHander(event) {
  //   return false;
  // }
  createDocumentForm() {
    this.documentForm = this.fb.group({
      drpReviewStatus: new FormControl(null),
      documentFile: new FormControl(null),
      drpReviewReason: new FormControl(null),
      drpDocumentType: new FormControl(null),
      drpDocumentUploadType: new FormControl(null, [Validators.required]),
      txtDocumentStartDate: new FormControl(""),
      txtDocumentEndDate: new FormControl(""),
      txtNote: new FormControl("")
    })
    this.documentSearchForm = this.fb.group({
      fileName: new FormControl(null),
      documentStatus: new FormControl(null),
      documentStartDate: new FormControl(null, [this.dateValidator.dateVaidator]),
      documentEndDate: new FormControl(null, [this.dateValidator.dateVaidator]),
      documentType: new FormControl(null),
    });
    this.patientID = this.patientID ?? "";
    //
    if (this.patientID) {
      const storeSubscription = this.patientService.get(this.patientID).subscribe(response => {
        const patientLock: PatientLockDTO = response?.patientLock;
        //Patient is locked & userId is not equal to loginUserId or patient is unlocked
        this.isPatientLockedByCurrentUser = (patientLock?.isLocked &&
          patientLock?.userId == this.strLoginUserId) ||
          (patientLock?.isLocked == false) ? true : false;
      });
      this.subscription$.push(storeSubscription)
    }
    //

    const valueChanes = this.documentSearchForm.valueChanges.subscribe(value => {
      if (this.patientID && this.documentSearchForm.valid) {
        const documentType: string = value?.documentType ?? "";
        const fileName: string = value?.fileName ?? "";
        const docFromDate: string = value?.documentStartDate ?? "";
        const docToDate: string = value?.documentEndDate ?? "";
        this.documentService.searchPatientDocumentsByPatientIdAndSFileNameAndSDocumentTypeAndDtDocumentStartDateAndDtDocumentEndDate(this.patientID, fileName?.trim(), documentType?.trim(), docFromDate, docToDate).subscribe(response => {
          this.arrUnsortedDocumentList = [];
          if (response && response?.length !== 0) {
            response?.forEach((element: PatientDocumentDTO) => {
              if (element?.documentUploadType === DocumentUploadTypes.SplitUpload && element?.isVerified !== 1) {
                element = this.extractShortNameAndDate(element);
              } else if (element?.isVerified === 1 && element?.documentUploadType === DocumentUploadTypes.ConsolidatedUpload || element?.documentUploadType === DocumentUploadTypes.ManualUploadType) {
                element = this.extractShortNameAndDate(element);
              }
            });
          }
          this.dataSource = new MatTableDataSource(this.arrUnsortedDocumentList);
          this.dataSource.sort = this.sort;
          this.dataSource.paginator = this.paginator;
        })


      }

    })
    this.subscription$.push(valueChanes)
    //
    this.documentForm.disable();
    //! set Rx and Face to face
    const docValueChanges = this.docTypeform()?.valueChanges?.subscribe(value => {
      const documentType: string = value ?? "";
      if (documentType === 'RX' || documentType === "FACE TO FACE") {
        this.documentForm?.controls?.txtDocumentStartDate?.setValidators([Validators.required]);
        this.documentForm?.controls?.txtDocumentEndDate?.setValidators([Validators.required]);
        this.documentForm?.controls?.txtDocumentStartDate?.updateValueAndValidity();
        this.documentForm?.controls?.txtDocumentEndDate?.updateValueAndValidity();

      } else {
        this.documentForm?.controls?.txtDocumentStartDate?.setValidators([Validators.nullValidator]);
        this.documentForm?.controls?.txtDocumentEndDate?.setValidators([Validators.nullValidator]);
        this.documentForm?.controls?.txtDocumentStartDate?.updateValueAndValidity();
        this.documentForm?.controls?.txtDocumentEndDate?.updateValueAndValidity();

      }
    })
    this.subscription$.push(docValueChanges)

    const DocumentFormData = this.table.getDocumentFormData().pipe().subscribe(resposne => {
      if (resposne && resposne?.filenames && resposne?.value) {
        this.documentForm.enable();
        this.filesname = resposne?.filenames;
        this.isShowSpinner = true;

        this.isDocumentEditSaveButtonHidden = false;
        this.documentForm.patchValue({
          drpReviewStatus: resposne?.value.drpReviewStatus,
          drpReviewReason: resposne?.value.drpReviewReason,
          drpDocumentType: resposne?.value.drpDocumentType,
          txtDocumentStartDate: resposne?.value.txtDocumentStartDate,
          txtDocumentEndDate: resposne?.value.txtDocumentEndDate,
          txtNote: resposne?.value.txtNote,
          drpDocumentUploadType: resposne?.value.drpDocumentUploadType,
        })
      } else {
        this.resetDocumentForm();
      }
    }, err => {
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    });
    this.subscription$.push(DocumentFormData)
  }

  docTypeform(): AbstractControl {
    return this.documentForm?.get("drpDocumentType")
  }
  documentTypeDropdownChange() {
    if (this.documentForm?.get('drpDocumentUploadType')?.value === DocumentUploadTypes.AutoUploadType) {
      this.documentForm?.patchValue({ drpDocumentType: null });
      this.documentForm?.get('drpDocumentType')?.setValidators([Validators.nullValidator]);
      this.documentForm?.get('drpDocumentType')?.disable({ onlySelf: true });
      this.documentForm?.updateValueAndValidity();
    }
    else if (this.documentForm?.get('drpDocumentUploadType')?.value === DocumentUploadTypes.ManualUploadType) {
      this.documentForm?.get('drpDocumentType')?.setValidators([Validators.required]);
      this.documentForm?.get('drpDocumentType')?.enable({ onlySelf: true });
      this.documentForm?.updateValueAndValidity();
    }
  }
  upload(files: any) {

    const startDateValue = this.documentForm?.get("txtDocumentStartDate")?.value;
    const endDateValue = this.documentForm?.get("txtDocumentEndDate")?.value;
    const startDate: string | null = (startDateValue === '') ? null : String(new Date(startDateValue).toISOString());
    const endDate: string | null = (endDateValue === '') ? null : String(new Date(endDateValue).toISOString());
    if (this.patientDocumentId === "" && this.selectedDocumentforUpdate === null) {
      if (files?.length === 0) {
        return;
      }
      //! Manual Sort Save Upload Part//
      let fileResult: { name: File, value: string, savedOrNot: boolean }[] = [];
      for (let file of files) {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          fileResult.push({ name: file, value: reader?.result?.slice(28)?.toString() ?? "", savedOrNot: false });
          if (fileResult.length === files.length) {
            this.table.setDocumentFormData({ value: this.documentForm.value, filenames: this.filesname });
            this.saveManualDocuments(fileResult, startDate, endDate);
          }
        }
      }
    } else {
      this.isShowSpinner = true;

      //! Document edit Part//
      let patientDocumentBlobDTO: UpdateDocumentBlobDTO = {
        isVerified: this.selectedDocumentforUpdate.isVerified,
        documentOrderNumber: this.selectedDocumentforUpdate.documentOrderNumber,
        fileName: this.selectedDocumentforUpdate.fileName,
        patientDocumentId: this.patientDocumentId,
        documentStartDate: startDate,
        documentEndDate: endDate,
        //   documentTypeId: this.documentForm?.get("drpDocumentType")?.value == null ? "" : this.documentForm?.get("drpDocumentType")?.value,
        documentTypeId: this.patientID,
        documentUploadType: this.documentForm?.get("drpDocumentUploadType")?.value,
        parentPatientDocumentID: this.selectedDocumentforUpdate.parentPatientDocumentID,
        tags: this.selectedDocumentforUpdate.tags,
        documentType: this.documentForm?.get("drpDocumentType")?.value == null ? "" : this.documentForm?.get("drpDocumentType")?.value,
        reviewStatus: this.documentForm?.get("drpReviewStatus")?.value == null ? "" : this.documentForm?.get("drpReviewStatus")?.value,
        reviewReason: this.documentForm?.get("drpReviewReason")?.value == null ? "" : this.documentForm?.get("drpReviewReason")?.value,
        note: this.documentForm?.get("txtNote")?.value,
        isSorted: this.selectedDocumentforUpdate.isSorted
      };
      if (this.parentPatientDocumentID !== "") {
        patientDocumentBlobDTO = { ...patientDocumentBlobDTO, parentPatientDocumentID: [this.parentPatientDocumentID] };
      }
      const documentPatientEditFile = this.documentServices.editFileByPatientDocumentBlobDTOAndPatientObjectID(patientDocumentBlobDTO, this.patientID).subscribe(() => {
        this.patientDocumentId = "";
        this.selectedDocumentforUpdate = {
          parentPatientDocumentID: [],
          documentOrderNumber: 0,
          isVerified: 0,
          isSorted: 0
        };
        this.parentPatientDocumentID = "";
        // Swal.fire({ title: 'Success', html: 'Saved Successfully', icon: 'success', timer: 3000, timerProgressBar: true });
        this.toastr.success('Saved Successfully','Success')
        this.resetDocumentForm();
        this.getDocumentsByPatientId();
        this.isShowSpinner = false;

        this.saveDocumentFormState.emit(this.documentForm.value);

      }, err => {
        this.isShowSpinner = false;

        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      })
      this.subscription$.push(documentPatientEditFile);
    }
  }



  //! Manual Upolad Document Save Function
  saveManualDocuments(fileResult: { name: File, value: string, savedOrNot: boolean }[], startDate: string | null, endDate: string | null) {
    this.isShowSpinner = true;
    let documentArrayToBeSaved: { patientDocumentBlobDTO: SavePatientDocumentBlobDTO; patientObjectID: string; }[] = [];
    fileResult.forEach((file, index) => {
      if (!file.savedOrNot) {
        let savePatientDocumentBlobDTO: SavePatientDocumentBlobDTO = {
          isVerified: 0,
          patientID: this.patientID,
          fileName: file.name.name,
          fileContentAsBase64String: file.value,
          documentTypeId: this.patientID,
          documentUploadType: this.documentForm?.get("drpDocumentUploadType")?.value,
          parentPatientDocumentID: null ?? null,
          documentOrderNumber: index,
          documentStartDate: startDate,
          documentEndDate: endDate,
          docStatus: "",
          //documentType: this.drpDocumentTypeLoop.filter(v => {return v?.documentType ===this.documentForm?.get("drpDocumentType")?.value; })[0]?.documentType,
          documentType: this.documentForm?.get("drpDocumentType")?.value == null ? "" : this.documentForm?.get("drpDocumentType")?.value,
          reviewStatus: this.documentForm?.get("drpReviewStatus")?.value == null ? "" : this.documentForm?.get("drpReviewStatus")?.value,
          reviewReason: this.documentForm?.get("drpReviewReason")?.value == null ? "" : this.documentForm?.get("drpReviewReason")?.value,
          note: this.documentForm?.get("txtNote")?.value,
          isSorted: 0
        };
        if (this.parentPatientDocumentID !== "") {
          savePatientDocumentBlobDTO = { ...savePatientDocumentBlobDTO, parentPatientDocumentID: [this.parentPatientDocumentID] };
        }
        let documentToBeSaved = { patientDocumentBlobDTO: savePatientDocumentBlobDTO, patientObjectID: this.patientID };
        documentArrayToBeSaved.push(documentToBeSaved);
      }
    })
    from(documentArrayToBeSaved).pipe(
      groupBy(v => v.patientObjectID),
      mergeMap(group => group.pipe(toArray())),
      mergeMap(group => from(group)
        .pipe(
          concatMap((v) => defer(() => this.documentServices.fileUploadByPatientDocumentBlobDTOAndPatientObjectID(v.patientDocumentBlobDTO, v.patientObjectID))))),
      takeLast(1),
      map(v => {
        this.parentPatientDocumentID = "";

        this.getDocumentsByPatientId();
      })
    ).subscribe(value => {
      this.table.setDocumentFormData(null);

      this.isShowSpinner = false;
      Swal.fire({ title: 'Success', html: 'Saved Successfully', icon: 'success', timer: 3000, timerProgressBar: true }).finally(() => {
        // if (result.value) {
        //!change next tab
        this.resetDocumentForm();
        this.nextTabMoveOnSaveEmitter.emit("Document");
        //  }
      });
      this.saveDocumentFormState.emit(this.documentForm.value);
    }, err => {
      const data: HttpErrorResponse = err;
      this.isShowSpinner = false;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    })
  }
  deleteFileById(patientDocumentId: string): void {

    Swal.fire({
      title: 'Are you sure you want to Delete?',
      text: 'You won\'t be able to retrieve this data!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#34c38f',
      cancelButtonColor: '#f46a6a',
      confirmButtonText: 'Yes, delete it!'
    }).then(result => {
      if (result.value) {
        const deleteDocumentFile = this.documentServices.deleteFileByPatientIdAndPatientDocumentId(this.patientID, patientDocumentId).subscribe(response => {
          this.getDocumentsByPatientId();
          this.resetDocumentForm()
        }, err => {
          const data: HttpErrorResponse = err;
          Swal.fire({
            icon: 'info',
            text: data?.error?.error?.message,
          });
        }, () => {
        })
        this.subscription$.push(deleteDocumentFile)
      }
    });
  }
  getDocumentsByPatientId(): void {
    let patientDetails: PatientDTO;
    if (this.patientID !== "" && this.patientID !== null && this.patientID !== undefined) {
      // this.table.loadDummyPatientData1().subscribe(response => {
      const patientGet = this.patientService.get(this.patientID).subscribe(response => {
        patientDetails = response;
        //   patientDetails = response;

        this.getDocumentsListByPatientId(patientDetails);
      }, err => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      })
      this.subscription$.push(patientGet)
    }
  }
  getDocumentsListByPatientId(patientDetails: PatientDTO): void {
    this.isShowProcessBar = true;
    this.arrUnsortedDocumentList = [];
    this.arrMergeDocumentList = [];
    // this.extractionUploadedDocuments = [];
    // if (patientDetails && patientDetails?.autoIntakeDocumentDetails && patientDetails?.autoIntakeDocumentDetails !== []) {
    //   this.getExtractionDocumentList(patientDetails);
    // }
    if (patientDetails?.patientDocuments && patientDetails && patientDetails?.patientDocuments?.length !== 0) {
      patientDetails?.patientDocuments.forEach((element: PatientDocumentDTO) => {
        if (element?.documentUploadType === DocumentUploadTypes.SplitUpload && element?.isVerified !== 1) {
          element = this.extractShortNameAndDate(element);
        } else if (element?.isVerified === 1 && element?.documentUploadType === DocumentUploadTypes.ConsolidatedUpload || element?.documentUploadType === DocumentUploadTypes.ManualUploadType) {
          element = this.extractShortNameAndDate(element);
        }
      });
      this.documentGroupingFunction();
      listOfDocuments = patientDetails?.patientDocuments;
    }
    this.isShowProcessBar = false;
  }
  private getExtractionDocumentList(patientDetails: PatientDTO) {
    patientDetails?.autoIntakeDocumentDetails?.forEach(element => {
      if (element?.processedStatus === 0) {
        let resulteddocumentDate: string = "";
        if (typeof element?.documentDate === "string") {
          const date = `${(new Date(element?.documentDate))?.toLocaleDateString("en-US")}`;
          const hours = `${(new Date(element?.documentDate))?.getHours()}`;
          const time = `${(new Date(element?.documentDate))?.getMinutes()}`;
          resulteddocumentDate = date + "  " + hours + ":" + time;
        }
        this.extractionUploadedDocuments.push({
          autoIntakePatientDocumentId: element?.autoIntakePatientDocumentId ?? "",
          documentUploadType: DocumentUploadTypes.ExtractionUploadType,
          documentDate: resulteddocumentDate ?? "",
          blobName: element?.blobName ?? "",
          fileName: element?.fileName ?? "",
          processedStatus: element?.processedStatus
        });
      }
    });

    // this.extractionDataSource = new MatTableDataSource(this.extractionUploadedDocuments);
    // this.extractionDataSource.sort = this.extractionSort;
    // this.extractionDataSource.paginator = this.ExtractionMatPaginator;
  }

  private extractShortNameAndDate(element: PatientDocumentDTO): PatientDocumentDTO {
    let uploadType: DocumentUploadTypes;
    let resultDocStartDate: string = "";
    let resultDocEndDate: string = "";
    const filename: string = element?.fileName?.toLowerCase()?.replace('/(XP[a-z]th)|(.pdf)/gi', '') ?? "";
    const filename2: string = filename?.replace('/(XP[a-z]th)|(.pdf)/gi', '') ?? "";

    if (typeof element?.documentStartDate === "string") {
      const date = `${(new Date(element?.documentStartDate))?.toLocaleDateString("en-US")}`;
      const hours = `${(new Date(element?.documentStartDate))?.getHours()}`;
      const time = `${(new Date(element?.documentStartDate))?.getMinutes()}`;
      resultDocStartDate = date + "  " + hours + ":" + time;
    }
    if (typeof element?.documentEndDate === "string") {
      const date = `${(new Date(element?.documentEndDate))?.toLocaleDateString("en-US")}`;
      const hours = `${(new Date(element?.documentEndDate))?.getHours()}`;
      const time = `${(new Date(element?.documentEndDate))?.getMinutes()}`;
      resultDocEndDate = date + "  " + hours + ":" + time;
    }
    if (element?.documentUploadType === DocumentUploadTypes.ConsolidatedUpload) {
      uploadType = DocumentUploadTypes.AutoUploadType;
      element = { fileName: filename, documentUploadType: uploadType, documentStartDate: resultDocStartDate, documentEndDate: resultDocEndDate, ...element };
      this.arrUnsortedDocumentList.push(element);
      this.dataSource = new MatTableDataSource(this.arrUnsortedDocumentList);
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
    }
    if (element?.documentUploadType === DocumentUploadTypes.ManualUploadType) {
      uploadType = DocumentUploadTypes.ManualUploadType;
      element = { fileName: filename, documentUploadType: uploadType, documentStartDate: resultDocStartDate, documentEndDate: resultDocEndDate, ...element };
      this.arrUnsortedDocumentList.push(element);
      this.dataSource = new MatTableDataSource(this.arrUnsortedDocumentList);
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
    }
    if (element?.documentUploadType === DocumentUploadTypes.SplitUpload) {
      uploadType = DocumentUploadTypes.SplitUpload;
      element = { fileName: filename, documentUploadType: uploadType, documentStartDate: resultDocStartDate, documentEndDate: resultDocEndDate, ...element };
      this.arrMergeDocumentList.push(element);
      this.mergeDataSource = new MatTableDataSource(this.arrMergeDocumentList);
      this.mergeDataSource.sort = this.mergeSort;
      this.mergeDataSource.paginator = this.mergePaginator;
    }
    return element;
  }
  processFile(blobName: AutoIntakeDocumentDetailsDTO) {
    this.isShowProcessBar = true;
    if (blobName && blobName?.blobName) {
      const getDocumentList = this.documentService.getPatientDocumentByBlobIDByBlobName(blobName?.blobName).subscribe(response => {
        const filelocation: string = "" + response;
        if (filelocation && response) {
          let resulteddocumentDate: string = '';
          if (blobName?.documentDate && blobName?.documentDate === "") {
            resulteddocumentDate = new Date(blobName?.documentDate)?.toISOString() ?? "";
          }
          let savePatientDocumentBlobDTO: SavePatientDocumentBlobDTO = {
            patientID: this.patientID,
            fileName: blobName.fileName,
            fileContentAsBase64String: filelocation,
            documentTypeId: this.patientID,
            documentUploadType: DocumentUploadTypes.AutoUploadType,
            parentPatientDocumentID: null,
            documentOrderNumber: 0,
            documentDate: resulteddocumentDate ?? "",
            //documentType: this.drpDocumentTypeLoop.filter(v => {return v?.documentType ===this.documentForm?.get("drpDocumentType")?.value; })[0]?.documentType,
            documentType: "",
            reviewStatus: "",
            reviewReason: "",
            note: "",
            isVerified: 0,
            isSorted: 0,
            documentStartDate: "",
            documentEndDate: "",
            docStatus: "",
          };
          this.isShowProcessBar = false;
          this.documentServices.fileUploadByPatientDocumentBlobDTOAndPatientObjectID(savePatientDocumentBlobDTO, this.patientID).subscribe(value => {
            this.parentPatientDocumentID = "";
            this.isShowProcessBar = false;
            this.resetDocumentForm();
            Swal.fire({ title: 'Success', html: 'Saved Successfully', icon: 'success', timer: 3000, timerProgressBar: true }).finally(() => { });
            this.saveDocumentFormState.emit(this.documentForm.value);
            this.updateExtractionDetails(blobName);
            this.isShowProcessBar
          }, err => {
            const data: HttpErrorResponse = err;
            this.isShowProcessBar = false;
            Swal.fire({
              icon: 'info',
              text: data?.error?.error?.message,
            });
          })
        }
      })
      this.subscription$.push(getDocumentList);
    }


  }
  private updateExtractionDetails(blobName: AutoIntakeDocumentDetailsDTO) {
    let patientDetails: PatientDTO;
    const patientGet = this.patientService.get(this.patientID).pipe().subscribe(response => {
      patientDetails = response;
      let autoIntakeDocumentDetails: AutoIntakeDocumentDetailsDTO[] = patientDetails?.autoIntakeDocumentDetails.slice(0, patientDetails?.autoIntakeDocumentDetails.length);
      let selectedAutoIntakeDocumentDetails: AutoIntakeDocumentDetailsDTO = autoIntakeDocumentDetails.filter(d => d.autoIntakePatientDocumentId === blobName.autoIntakePatientDocumentId)[0];
      let mSelectedAutoIntakeDocumentDetails: AutoIntakeDocumentDetailsDTO = {
        autoIntakePatientDocumentId: selectedAutoIntakeDocumentDetails.autoIntakePatientDocumentId,
        blobName: selectedAutoIntakeDocumentDetails.blobName,
        documentDate: selectedAutoIntakeDocumentDetails.documentDate,
        fileName: selectedAutoIntakeDocumentDetails.fileName,
        processedStatus: 1,
      };
      autoIntakeDocumentDetails = autoIntakeDocumentDetails.map(element => {
        if (element?.autoIntakePatientDocumentId === mSelectedAutoIntakeDocumentDetails.autoIntakePatientDocumentId)
          element = mSelectedAutoIntakeDocumentDetails;
        return element;
      });
      patientDetails = {
        autoIntakeDocumentDetails: autoIntakeDocumentDetails,
        personals: patientDetails?.personals,
        addresses: patientDetails?.addresses,
        contacts: patientDetails?.contacts,
        clinicals: patientDetails?.clinicals,
        insurances: patientDetails?.insurances,
        notes: patientDetails?.notes,
        policies: patientDetails?.policies,
        patientDocuments: patientDetails?.patientDocuments,
        insuranceVerifications: patientDetails?.insuranceVerifications,
        authorizations: patientDetails?.authorizations,
        defaultPatientId: patientDetails?.defaultPatientId,
        isAutoIntake: patientDetails?.isAutoIntake,
        tenantId: patientDetails?.tenantId,
        organizationUnitId: patientDetails?.organizationUnitId,
        chartId: response.chartId,
        billingDetails: patientDetails?.billingDetails,
        patientLock: patientDetails?.patientLock,
        fileId: patientDetails?.fileId,
      };
      // if (patientDetails && autoIntakeDocumentDetails && this.patientID) {

      //   this.patientService.update(this.patientID, patientDetails).subscribe(response => {
      //     const selPatientDetails: PatientDTO = response;
      //     this.getDocumentsListByPatientId(selPatientDetails);

      //   }, err => {
      //     const data: HttpErrorResponse = err;
      //     Swal.fire({
      //       icon: 'error',
      //       text: data?.error?.error?.message,
      //     });
      //   });
      // }

    }, err => {
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    });
    this.subscription$.push(patientGet);
  }

  documentGroupingFunction(): void {

    this.documentGroupedByType = [];
    const groupedDocument$ = from(this.arrUnsortedDocumentList).pipe(
      groupBy(v => v.documentType),
      mergeMap(group => group.pipe(
        reduce((acc: Partial<documentGroupIngListType>, cur: PatientDocumentDTO) => {
          acc?.groupItem?.push(cur);
          return acc;
        },
          {
            documentType: group.key,
            documentGroupedByType: this.drpDocumentTypeLoop.filter(v => v?.documentType === group.key)[0]?.documentType,
            groupItem: []
          }))), toArray());
    const combinedData$ = combineLatest([groupedDocument$, from(this.drpDocumentTypeLoop)]).pipe(takeLast(1)).subscribe(v => {
      this.documentGroupedByType = v[0];
    }, err => {
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    });
    this.subscription$.push(combinedData$)

  }
  getfilename(files: FileList): void {
    this.filesname = [];
    for (let index = 0; index < files.length; index++) {

      if (files[index].type !== DocumentMaxandTypeLimits.documentTypeLimit) {
        this.showErrorForFileType = true;
        this.showErrorForFileLength = false;
        this.filesname = [];

        return;
      };

      if (files.length >= DocumentMaxandTypeLimits.documentMaxNoFileLimit) {
        this.showErrorForFileLength = true;
        this.showErrorForFileType = false;
        this.filesname = [];

        return;
      };


      if (files[index].name.length > 15) {
        this.filesname.push(files[index].name.slice(0, 20) + "...");
      } else {
        this.filesname.push(files[index].name);
      }

    }

    this.intCheckedDocuments = files.length;
    this.isDocumentEditSaveButtonHidden = false;
    this.documentForm.enable();
  }


  resetDocumentForm(): void {
    this.show = true;
    this.documentForm.patchValue({
      drpReviewStatus: null,
      drpReviewReason: null,
      drpDocumentType: null,
      documentFile: null,
      txtDocumentStartDate: "",
      txtDocumentEndDate: "",
      txtNote: "",
      drpDocumentUploadType: "",
    })

    this.showErrorForFileType = false;
    this.showErrorForFileLength = false;
    this.filesname = [];
    this.isDocumentForButtonDisable = false;
    this.intCheckedDocuments = 0;
    this.documentForm.disable();
    this.documentForm.controls['documentFile'].enable();
    this.documentForm.markAsUntouched();
    this.documentForm.updateValueAndValidity();

    this.isDocumentEditSaveButtonHidden = true;
    this.strPdfSource = "";
    this.isShowSpinner = false;
    this.strSelectedPdfPath = "";
    if (this.patientID !== "" && this.patientID !== null && this.patientID !== undefined) { this.getDocumentsByPatientId(); }

  }


  getDropdown(): void {
    let patientDropdownInput: PatientDropdowns[];
    patientDropdownInput = [
      PatientDropdowns.DocumentTypes,
      PatientDropdowns.ReviewStatus,
      PatientDropdowns.ReviewReasons,
      PatientDropdowns.BranchOffices,
      PatientDropdowns.SortingTypes,
      PatientDropdowns.IsActiveStatus
    ]
    const patientDropdown = this.patientDropdownService.getPatientDropdownsByInput(patientDropdownInput).subscribe(response => {
      if (response && response.reviewStatuses && response.reviewReasons && response.branchOffices && response.documentTypes) {
        this.drpDocumentTypeLoop = response.documentTypes;
        this.drpReviewStatusLoop = response.reviewStatuses;
        this.drpReviewReasonLoop = response.reviewReasons;
        this.drpBranchLoop = response.branchOffices;
        this.drpDocumentUploadType = response.sortingTypes;
        this.drpIsActiveStatus = response.lstIsActiveStatus;
        if (this.patientID !== "" && this.patientID !== null && this.patientID !== undefined) { this.getDocumentsByPatientId(); }

      }
    }, err => {
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    }, () => {
    })
    this.subscription$.push(patientDropdown)
  }


  downloadFile(blobName: string, fileName: string): void {
    const downloadDocument = this.documentService.getPatientDocumentByBlobIDByBlobName(blobName).subscribe(response => {
      if (response && response !== null && response?.length !== 0) {
        let filelocation: String = String("data:application/pdf;base64," + response);
        let link = document.createElement("a");
        link.download = fileName + ".pdf";
        link.href = filelocation.toString();
        link.click();
      }
    }, err => {
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    }, () => {
    })
    this.subscription$.push(downloadDocument);
  }
  viewPdfFile(selectedBlobName: string, patientDocumentId: string): void {
    this.show = false;
    this.patientDocumentId = patientDocumentId;
    this.getpatientDocumentByBlobID(selectedBlobName, patientDocumentId);
  }

  getpatientDocumentByBlobID(selectedBlobName: string, patientDocumentId: string): void {
    const getDocument = this.documentService.getPatientDocumentByBlobIDByBlobName(selectedBlobName).subscribe(response => {
      if (response && response !== null && response?.length !== 0) {
        this.strSelectedPdfPath = String("data:application/pdf;base64," + response);
      }
      this.subscription$.push(getDocument)
    }, err => {
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    }, () => {
    })
    let selectedDocument: PatientDocumentDTO = listOfDocuments.filter((document) => { return document && (document?.patientDocumentID === patientDocumentId) })[0];
    this.selectedDocumentforUpdate = selectedDocument;
    (selectedDocument !== null || selectedDocument !== undefined) && this.documentForm.patchValue({
      drpDocumentUploadType: "Manual Sorting",
      txtDocumentStartDate: selectedDocument.documentStartDate == null ? "" : (selectedDocument.documentStartDate),
      txtDocumentEndDate: selectedDocument.documentEndDate == null ? "" : (selectedDocument.documentEndDate),
      drpDocumentType: selectedDocument.documentType == "" ? null : selectedDocument.documentType,
      drpReviewStatus: selectedDocument.reviewStatus == "" ? null : selectedDocument.reviewStatus,
      drpReviewReason: selectedDocument.reviewReason == "" ? null : selectedDocument.reviewReason,
      txtNote: selectedDocument.note,
    })
    if (selectedDocument !== null || selectedDocument !== undefined) {
      this.documentForm.enable();
      this.documentForm.controls['documentFile'].disable();
      this.documentForm.controls['drpDocumentUploadType'].disable();

      this.isDocumentForButtonDisable = true;
      this.isDocumentEditSaveButtonHidden = false;

    }
    this.subscription$.push(getDocument)
  }

}
export interface downloadValues { documentFilename: string, documentFilePath: string };
export interface documentListType { strDocumentName: string, dtDocumentDate: string, strDocumentPath: string, isSelected: boolean };
export interface documentGroupIngListType {
  documentType: string,
  documentGroupedByType: string,
  groupItem: PatientDocumentDTO[]
};
export interface listDocumentTypeData {
  patientDocumentID?: string,
  patientID?: string,
  fileName?: string,
  blobName?: string,
  tags?: string,
  documentTypeId?: string,
  reviewStatus?: string,
  reviewReason?: string,
  documentDate?: string,
  note?: string,
  documentUploadType: string,
  parentPatientDocumentID: string[],
  documentType?: string,
  approvalStatus?: string,
  approvedByWhom?: string,
  approvedOn?: string,
  fileSize?: string,
  documentOrderNumber: number;
  isVerified: number;
}
