import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import Swal from 'sweetalert2';
import { PracticeManagementService } from '../patient-proxy/patient/practice-management';
import { ActiveStatus } from 'projects/admin/src/app/admin-proxy/platform-app-management/rcm/platform-management/localization/enumeration/active-status.enum';
import { CreateUpdateFaxNumbersDTO } from '../patient-proxy/patient/practice-management/dto';
import { ToastrService } from 'ngx-toastr';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { CommunicationService } from 'projects/shared/src/app/services/communication.service';
import { DatePipe } from '@angular/common';
@Component({
  selector: 'app-practice-management-faxnumber',
  templateUrl: './practice-management-faxnumber.component.html',
  styleUrls: ['./practice-management-faxnumber.component.scss']
})
export class PracticeManagementFaxnumberComponent implements OnInit {
  @Input() sessionPracticeId: any;
  practiceNotesId: string;
  tableData$: Subscription;
  isLoading: boolean;
  dtOptions: any;
  $subscription: any;
  faxNumberForm: FormGroup;
  FaxNumberdetails: any;
  isShowSpinner: boolean = false;
  ischeckPracticeId:boolean;
  faxnumberid: string;
  constructor(
    private fb: FormBuilder,
    private toastr: ToastrService,
    private practiceManagementService: PracticeManagementService,
    private communicationService: CommunicationService,
    private datepipe:DatePipe
  ) { 
    this.communicationService.functionCallFaxNumber$.subscribe((selectedNode) => {
      this.getPracticeFaxNumber();
    });
  }

  ngOnInit(): void {

    this.dtOptions = {
      responsive: true,
      paging: false,
      scrollCollapse: true,
      scrollY: '410px',
      scrollX: true,
      dom: 'Bfrtip',
      language: {
        emptyTable: 'No records',
        info: 'Total : _MAX_ records',
        infoEmpty: 'Total : _MAX_ records',
        infoFiltered: '(filtered : _TOTAL_ records)',
        loadingRecords: 'Loading...',
        zeroRecords: 'No matching records',
      },
      buttons: [
        {
          extend: 'excel',
          text:'Export',
          filename: 'Fax Number Info',
          exportOptions: {
            columns: ':visible:not(:first-child,:eq(0)) ',
          },
        },
      ]
    };

    this.faxNumberForm = this.fb.group({
      txtFax: new FormControl(''),
    });
    this.practiceNotesId = this.sessionPracticeId;
   
    if (this.practiceNotesId) {
      this.getPracticeFaxNumber();
      this.faxNumberForm.enable();
    } else {
      this.ischeckPracticeId = true;
      this.faxNumberForm.disable();
    }

    this.dtOptions.buttons[0].filename =
    'Fax Number Info' + '-' + this.datepipe.transform(new Date(), 'MM-dd-yyyy');
  }

  //GET Reminder List In Table
  getPracticeFaxNumber() {

    this.isLoading = true;
    this.tableData$?.unsubscribe();
    const getFaxNumberbleData = this.practiceManagementService
      .getPracticeFaxNumbersByPracticeIdAndFilter(this.practiceNotesId, ActiveStatus.All)
      .subscribe(
        (response) => {
          this.FaxNumberdetails = response;
          this.isLoading = false;
        },
        (err) => {
          this.isLoading = false;
          this.FaxNumberdetails = [];
          const data: HttpErrorResponse = err;
          // Swal.fire({
          //   icon: 'info',
          //   text: data?.error?.error?.message,
          // });
        }
      );
    this.tableData$ = getFaxNumberbleData;
  }

  //Save FAX NUMBER
  saveFaxNumber() {
    this.isShowSpinner = true;
    if (this.faxnumberid !== null && this.faxnumberid != "" && this.faxnumberid !== undefined) {
      const faxNumberInput: CreateUpdateFaxNumbersDTO = {
        practiceId: this.practiceNotesId ?? '',
        faxNumber: this.faxNumberForm.value.txtFax ?? '',
      };
      //Update
      this.practiceManagementService.updateFaxNumbersByIdAndInput(this.faxnumberid, faxNumberInput).subscribe(
        (response) => {
          this.toastr.success('Update Successfully!', 'Success');
          this.faxnumberid = null;
          this.getPracticeFaxNumber();
          this.isShowSpinner = false;
          this.faxNumberForm.reset();
        },
        (err) => {
          this.isShowSpinner = false;
          const data: HttpErrorResponse = err;
          Swal.fire({
            icon: 'info',
            text: data?.error?.error?.message,
          });
        }
      );
    } else {
      const faxNumberInput: CreateUpdateFaxNumbersDTO = {
        practiceId: this.practiceNotesId ?? '',
        faxNumber: this.faxNumberForm.value.txtFax ?? '',
      };
      //Save
      this.practiceManagementService.createFaxNumbersByInput(faxNumberInput).subscribe(
        (response) => {
          this.toastr.success('Added Successfully!', 'Success');
          this.getPracticeFaxNumber();
          this.isShowSpinner = false;
          this.faxNumberForm.reset();
        },
        (err) => {
          this.isShowSpinner = false;
          const data: HttpErrorResponse = err;
          Swal.fire({
            icon: 'info',
            text: data?.error?.error?.message,
          });
        }
      );
    }

  }

  //Update FAX NUMBER
  updatefaxNumber(id: string, faxNumber: string) {
    this.faxNumberForm.patchValue({
      txtFax: faxNumber ?? ''
    });
    this.faxnumberid = id;
    this.faxNumberForm.markAsDirty();
  }

  //Delete FAX NUMBER
  //  deleteFaxNumber(faxId: string) {
  //   Swal.fire({
  //     title: 'Are you sure you want to Delete?',
  //     text: 'You won\'t be able to retrieve this data!',
  //     icon: 'warning',
  //     showCancelButton: true,
  //     confirmButtonColor: '#34c38f',
  //     cancelButtonColor: '#f46a6a',
  //     confirmButtonText: 'Yes, delete it!'
  //   }).then(result => {
  //     if (result.value) {
  //       this.practiceManagementService.deleteFaxNumbersByFaxid(faxId).subscribe(response => {
  //         this.toastr.success('Deleted Successfully', 'Success');
  //         this.getPracticeFaxNumber();
  //         this.resetFaxNumber();
  //       }, err => {
  //         const data: HttpErrorResponse = err;
  //         Swal.fire({
  //           icon: 'info',
  //           text: data?.error?.error?.message,
  //         });
  //       });
  //     }
  //   });
  // }

  statusChange(event: MatSlideToggleChange, data: any) {

    if (event.checked == true) {

      Swal.fire({
        title: 'Do you want to activate?',
        text: 'By Changing Status, this Fax Number will be activated',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#34c38f',
        cancelButtonColor: '#f46a6a',
        confirmButtonText: 'Yes, Activate it!',
      }).then((result) => {
        if (result.value) {

          if (
            data?.id &&
            data?.id !== '' &&
            data?.id !== null &&
            data?.id !== undefined
          ) {
            this.practiceManagementService.activeInactiveFaxNumbersByFaxid(data?.id)
              .subscribe(
                () => {
                  this.toastr.success('Activated Successfully', 'Success');
                  this.isShowSpinner = false;
                  this.resetFaxNumber();
                  this.getPracticeFaxNumber();
                },
                (err) => {
                  const data: HttpErrorResponse = err;
                  this.isShowSpinner = false;
                  Swal.fire({
                    icon: 'info',
                    text: data?.error?.error?.message,
                  });
                  event.source.checked = false;
                }
              );
          }
        } else {
          event.source.checked = false;
        }
      });
    } else if (event.checked == false) {

      Swal.fire({
        title: 'Do you want to deactivate?',
        text: 'By Changing Status, this Fax Number will be deactivated',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#34c38f',
        cancelButtonColor: '#f46a6a',
        confirmButtonText: 'Yes, deactivate it!',
        //cancelButtonText: 'No, deactivate it!',
      }).then((result) => {

        if (result.value) {
          if (
            data?.id &&
            data?.id !== '' &&
            data?.id !== null &&
            data?.id !== undefined
          ) {
            this.practiceManagementService.activeInactiveFaxNumbersByFaxid(data?.id)
              .subscribe(
                () => {
                  this.toastr.success('Deactivated Successfully', 'Success');
                  this.isShowSpinner = false;
                  this.resetFaxNumber();
                  this.getPracticeFaxNumber();
                },
                (err) => {
                  const data: HttpErrorResponse = err;
                  this.isShowSpinner = false;
                  event.source.checked = true;
                  Swal.fire({
                    icon: 'info',
                    text: data?.error?.error?.message,
                  });
                }
              );
          }
        } else {
          event.source.checked = true;
        }
      });
    }
  }





  // statusChange(event: MatSlideToggleChange, faxId:string) {
  //   if (event.checked == true) {
  //     Swal.fire({
  //       title: 'Do you want to activate?',
  //       text: 'By Changing Status, this Fax Number will be activated',
  //       icon: 'warning',
  //       showCancelButton: true,
  //       confirmButtonColor: '#34c38f',
  //       cancelButtonColor: '#f46a6a',
  //       confirmButtonText: 'Yes, Activate it!'
  //     }).then(result => {
  //       if (result.value) {
  //           this.practiceManagementService.activeInactiveFaxNumbersByFaxid(faxId).subscribe(response => {
  //             this.toastr.success('Activated Successfully', 'Success')
  //             this.isShowSpinner = false;
  //             this.getPracticeFaxNumber();
  //           }, err => {
  //             const data: HttpErrorResponse = err;
  //             Swal.fire({
  //               icon: 'info',
  //               text: data?.error?.error?.message,
  //             });
  //           });
  //         }
  //       else {
  //         event.source.checked = false;
  //       }
  //     });
  //   }
  //   else
  //     if (event.checked == false) {
  //       Swal.fire({
  //         title: 'Do you want to deactivate?',
  //         text: 'By Changing Status, this Fax Number will be deactivated',
  //         icon: 'warning',
  //         showCancelButton: true,
  //         confirmButtonColor: '#34c38f',
  //         cancelButtonColor: '#f46a6a',
  //         confirmButtonText: 'Yes, deactivate it!'
  //       }).then(result => {
  //         if (result.value) {
  //             this.practiceManagementService.activeInactiveFaxNumbersByFaxid(faxId).subscribe(response => {
  //               this.toastr.success('Deactivated Successfully', 'Success')
  //               this.isShowSpinner = false;
  //               this.getPracticeFaxNumber();
  //             }, err => {
  //               const data: HttpErrorResponse = err;
  //               Swal.fire({
  //                 icon: 'info',
  //                 text: data?.error?.error?.message,
  //               });
  //             });

  //         }
  //         else {
  //           event.source.checked = true;
  //         }
  //       });
  //     }
  // }

  resetFaxNumber() {
    this.faxNumberForm.reset();
    this.faxnumberid = null;
  }
}
