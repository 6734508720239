<div class="container-fluid">
  <div class="row">
    <div class="col-12">
      <div class="d-flex align-items-center justify-content-between">
        <h3 class="font-weight-bolder mb-3 mt-1">
          <b class="customThemeClass">List / Orders / Override Orders</b>
        </h3>
        <div class="mt-2">
          <form [formGroup]="frmOverrideList">
            <mat-form-field>
              <mat-label>Override Date Range</mat-label>
              <mat-date-range-input [rangePicker]="picker">
                <input
                  matStartDate
                  matInput
                  placeholder="Start date"
                  formControlName="txtDateValue"
                />
                <input
                  matEndDate
                  matInput
                  placeholder="End date"
                  formControlName="txtDate"
                  (dateChange)="openDatePicker($event, 'date')"
                />
                <!-- (click)="getOrderDetails('data')" -->
              </mat-date-range-input>
              <mat-datepicker-toggle
                matSuffix
                [for]="picker"
              ></mat-datepicker-toggle>
              <mat-date-range-picker #picker></mat-date-range-picker>
            </mat-form-field>
          </form>
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-12">
      <div class="card card-body">
        <div class="d-flex justify-content-center" *ngIf="!isOrdersLoaded">
          <img
            class="img-responsive pb-2 mr-1"
            src="assets/gifs/Infinity-loader.gif"
          />
        </div>
        <div *ngIf="isOrdersLoaded" class="table table-responsive">
          <app-override-list-table
            [ltOverrideOrders]="ltOverrideOrders"
          ></app-override-list-table>
        </div>
      </div>
    </div>
  </div>
</div>
