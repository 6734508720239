import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { HellosignSignatureService } from '../order-proxy/order-management/order-optimization/hellosign-signature.service';
import { PagedAndSortedResultRequestDto } from '@abp/ng.core';
import { CommonService } from 'projects/shared/src/app/services/common.service';

@Component({
  selector: 'app-hello-sign-report',
  templateUrl: './hello-sign-report.component.html',
  styleUrls: ['./hello-sign-report.component.scss']
})
export class HelloSignReportComponent implements OnInit {

  isLoading:boolean = false;
  signCountData: any;
  helloSignReportData:any;
  constructor(
    private title: Title,
    private HellosignSignatureService: HellosignSignatureService,
    private commonService: CommonService,
  ) { }

  ngOnInit() {
    this.title.setTitle('Qsecure | Hello Sign Report');
    this.loadSignCount()
    this.loadReportList()

  }


  //load report table list
  loadReportList(){
    this.isLoading = true;
    const getReportList = this.HellosignSignatureService.getList(new PagedAndSortedResultRequestDto).subscribe(response => {
      this.helloSignReportData = response?.items;
      this.helloSignReportData = this.helloSignReportData.map((data) => {
        return {
          ...data,
          orderDate: this.commonService.getFormattedDate(
            data.orderDate
          ),
          creationTime: this.commonService.getFormattedDateTimeZone(
            data.creationTime
          ),
          lastModificationTime: this.commonService.getFormattedDateTimeZone(
            data.lastModificationTime
          ),
          documentSentOn: this.commonService.getFormattedDate(
            data.documentSentOn
          ),
          documentReceivedOn: this.commonService.getFormattedDate(
            data.documentReceivedOn
          ),
          expireDate: this.commonService.getFormattedDate(
            data.expireDate
          ),
        };
      });
      this.isLoading = false
    },err=>{
      this.isLoading = false
    })


  }
  //load  report status count 
  loadSignCount(){
    this.HellosignSignatureService.getHesignStatusCountDetails().subscribe(response => {
      this.signCountData = response
    },(err)=>{

    })
  }

}
