import { HttpErrorResponse } from '@angular/common/http';
import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  Renderer2,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DataTableDirective } from 'angular-datatables';
import { BillingDenialService } from 'projects/billing/src/app/billing-proxy/billing-management/rcm/billing-management/optimization/billing-reports/billing-denial.service';
import { ClaimViewTabComponent } from 'projects/billing/src/app/claim-view-tab/claim-view-tab.component';
import { PatientCommonNotesComponent } from 'projects/shared/src/app/components/patient-common-notes/patient-common-notes.component';
import { defaultGuid } from 'projects/shared/src/app/enums/allenums.enum';
import { CommonService } from 'projects/shared/src/app/services/common.service';
import Swal from 'sweetalert2';
import { ViewPatientLedgerComponent } from '../../view-patient-ledger/view-patient-ledger.component';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import { GlossaryDTO } from 'projects/billing/src/app/claim-proxy/claim-processing-management/rcm/billing-management/optimization/dto';
import { BillingDenialSearchDTO } from 'projects/billing/src/app/billing-proxy/billing-management/rcm/billing-management/optimization/dto';
import { GlossaryDetailsService } from 'projects/billing/src/app/claim-proxy/claim-processing-management/rcm/billing-management/optimization/claim/glossary-details.service';
import { DatePipe } from '@angular/common';
import { map, startWith } from 'rxjs/operators';
import { MatSelectChange } from '@angular/material/select';

@Component({
  selector: 'app-denial-bin-queue-table',
  templateUrl: './denial-bin-queue-table.component.html',
  styleUrls: ['./denial-bin-queue-table.component.scss'],
})
export class DenialBinQueueTableComponent implements OnInit {
  denialSearchForm: FormGroup;
  @Input() inBoundTableData: any[];
  @Input() paginationInfo: { startRecord: number, endRecord: number, overallTableCount:number };
  @Input() currentPage: any = 1;
  @Input() totalPages: any = 1;
  pageOffset: any = 50;
  recordsPerPage: number = 50;
  overallTableCount: number = 0;
  @Output() nevigatePage? = new EventEmitter<number>();
  @ViewChild('tableContainer', { read: ViewContainerRef })
  tableContainer: ViewContainerRef;
  myTable2: DataTables.Api;
  @ViewChild(DataTableDirective, { static: false })
  datatableElement2: DataTableDirective;
  isdrpLoading: boolean;
  selectPayorList: any;
  drpDenialId: any;
  isloadingGlossaryDropDownData: boolean = false;
  public filteredPrimaryPayors: Observable<string[]> | undefined;
  loadPayerDropDownAPICall: Subscription;
  loadGlossaryDropDownAPICall: Subscription;
  loadDenialsTableAPICall: Subscription;
  denialCodeSelectAllOption: boolean = false;
  payorSelectAllOption: boolean = false;
  filteredreasonCode: Observable<GlossaryDTO[]>;
  lstReasonCode: any;
  drpPayorId: any;
  payerId: string = defaultGuid;
  dtDenialBinTableOptions: any;
  isLoading: boolean = false;
  constructor(
    private snackBar: MatSnackBar,
    private renderer: Renderer2,
    private commonService: CommonService,
    private dialog: MatDialog,
    private fb: FormBuilder,
    private datepipe: DatePipe,
    private billingDenialService: BillingDenialService,
    private glossaryDetailsService: GlossaryDetailsService,
    private _snackBar: MatSnackBar
  ) {}

  ngOnInit() {
    this.dataTableInitilization()
    this.initializeForms();
    this.loadGlossaryDropDown();
    this.loadPayerDropDown('');
    this.dtDenialBinTableOptions.buttons[0].filename =
      'Denials List' + '-' + this.datepipe.transform(new Date(), 'MM-dd-yyyy');
    // this.getTableData();
  }
  ngAfterViewInit(): void {
    if (this.datatableElement2 && this.datatableElement2.dtInstance) {
      this.refreshDataTable();
    } else {
      setTimeout(() => this.refreshDataTable(), 500);
    }
  }
  refreshDataTable(): void {
    this.datatableElement2.dtInstance.then((dtInstance: DataTables.Api) => {
      this.addPageNavigationControls();
    });
  }
  dataTableInitilization(){
    this.dtDenialBinTableOptions = {
      responsive: true,
      paging: false,
      dom: 'Bfrtip',
      scrollCollapse: true,
      scrollY: '500px',
      scrollX: true,
      search: {
        smart: false,
      },
      columnDefs: [{ targets: [11], visible: false }],
      language: {
        search: '',
        searchPlaceholder: 'Search Here',
        emptyTable: 'No records',
        info: `Showing ${this.paginationInfo?.startRecord ?? 0} to ${this.paginationInfo?.endRecord ?? 0} of ${this.paginationInfo?.overallTableCount ?? 0} records`,
        infoEmpty: 'Total : _MAX_ records',
        infoFiltered: '(filtered : _TOTAL_ records)',
        loadingRecords: 'Loading...',
        zeroRecords: 'No matching records',
      },
      order: [0],
      // ordering: false,
      buttons: [
        {
          extend: 'excel',
          text: 'Export',
          className: 'custom-dt-export-button',
          filename: 'Denials List',
          exportOptions: {
            columns: ':not(:first-child)', // Skip the first column
          },
        },
      ],
      initComplete: function (this: any) {
        //Search Input Alignment
        const searchInput = document.querySelector(
          '.dataTables_wrapper .dataTables_filter'
        ) as HTMLElement;
        if (searchInput) {
          searchInput.style.float = 'left';
        }
        //Search Input Design and Icon
        const searchFilterInput = document.querySelector(
          '.dataTables_filter input'
        ) as HTMLInputElement;
        if (searchFilterInput) {
          const searchContainer = document.createElement('div');
          searchContainer.className = 'search-container';
          searchFilterInput.style.paddingLeft = '25px';
          searchFilterInput.style.fontStyle = 'italic';
          searchFilterInput.style.fontSize = '12px';
          // Add event listeners for focusin and focusout
          searchFilterInput.addEventListener('focus', () => {
            searchFilterInput.style.border = '1px solid lightseagreen';
            searchFilterInput.style.outline = 'lightseagreen';
          });
  
          searchFilterInput.addEventListener('blur', () => {
            searchFilterInput.style.border = '';
            searchFilterInput.style.outline = '';
          });
  
          searchFilterInput.parentNode?.insertBefore(
            searchContainer,
            searchFilterInput
          );
          searchContainer.appendChild(searchFilterInput);
  
          const searchIcon = document.createElement('i');
          searchIcon.className = 'fa fa-search';
          searchIcon.style.position = 'absolute';
          searchIcon.style.left = '10px';
          searchIcon.style.top = '16px';
          searchIcon.style.transform = 'translateY(-50%)';
          searchIcon.style.pointerEvents = 'none';
          searchIcon.style.color = '#aaa';
  
          searchContainer.appendChild(searchIcon);
        }
      },
    };
  }
  initializeForms() {
    this.denialSearchForm = this.fb.group({
      drpDenial: new FormControl(''),
      txtDenialFilter: new FormControl(''),
      drpchartId: new FormControl(''),
      txtChartIdFilter: new FormControl(''),
      drpPatient: new FormControl(''),
      txtPatientFilter: new FormControl(''),
      drpHcpc: new FormControl(''),
      txtHcpcFilter: new FormControl(''),
      // txtPrimaryPayorFilter: new FormControl(''),
      // txtPrimaryPayorId: new FormControl(''),
      // txtCreatedFromDate: new FormControl(''),
      // txtCreatedEndDate: new FormControl(''),
      drpPayer: new FormControl(''),
      txtPayerFilter: new FormControl(''),
      // drpStatus: new FormControl(''),
      // txtStatusFilter: new FormControl(''),
      // txtDOSFromDate: new FormControl(''),
      // txtDOSEndDate: new FormControl(''),
    });
  }
  onRowDoubleClick(textToCopy) {
    this.copyTextToClipboard(textToCopy);
    this.showNotification('Text copied: ' + textToCopy);
  }

  showNotification(message: string): void {
    this.snackBar.open(message, 'Close', {
      duration: 3000, // Duration in milliseconds
      horizontalPosition: 'end', // Positioning the notification horizontally
      verticalPosition: 'bottom', // Positioning the notification vertically
    });
  }
  handlePayorSelectionChange(value: any): void {
    if (value && this.isPayorSelectionChanged) {
      this.isPayorSelectionChanged = false;
      this.getTableData();
    }
  }
  handleDenialSelectionChange(value: any): void {
    if (value && this.isDenialSelectionChanged) {
      this.isDenialSelectionChanged = false;
      this.getTableData();
    }
  }
  copyTextToClipboard(text: string): void {
    const textArea = document.createElement('textarea');
    textArea.value = text;
    document.body.appendChild(textArea);
    textArea.select();
    document.execCommand('Copy');
    textArea.remove();
  }
  isEmpty(value: any): boolean {
    return (
      value === null ||
      value === undefined ||
      value === '' ||
      value === defaultGuid
    );
  }

  openClaimForm(
    orderId: string,
    patientId: string,
    defaultSaleOrderId: string,
    claimId: string,
    chartNo: string,
    fullName: string
  ) {
    const dialogRef = this.dialog.open(ClaimViewTabComponent, {
      // minwidth: '65%',
      // minheight: '60%',
      disableClose: true,

      data: {
        orderId: orderId,
        patientId: patientId,
        defaultSaleOrderId: defaultSaleOrderId,
        claimId: claimId,
        chartNo: chartNo,
        fullName: fullName,
        isClaimBatch: true,
      },
    });

    const closeDialog = dialogRef.afterClosed().subscribe();
  }
  getTotalInboundData(event: MouseEvent, data: any): void {
    if (data.glossaryDescription != null) {
      const target = event.target as HTMLElement;
      const tr = target.closest('tr');
      const icon = target as HTMLElement;
      this.getDataTableData2(tr, data.glossaryDescription, icon);
    }
  }
  //DataTable Data Get Method and Toggle Notes Row in Table
  async getDataTableData2(
    tr: any,
    inboundData: any,
    icon: HTMLElement
  ): Promise<void> {
    this.myTable2 = await this.datatableElement2.dtInstance;
    if (tr) {
      const row = this.myTable2.row(tr);

      if (row.child.isShown()) {
        // This row is already open - close it
        row.child.hide();
        icon.innerText = 'add';
      } else {
        // Open this row
        row.child(this.format(row.data(), inboundData)).show();

        row.child().addClass('no-padding');
        icon.innerText = 'remove';
      }
    }
  }
  //Add Dynamic Notes Row in UnProcessed Table
  format(d: any, notesData: any) {
    let returnString: string = '';
    // notesData?.forEach(function (value, index) {
    returnString +=
      '' + notesData + '<h1 class="line" style="Margin: 4px 0 9px 0px"></h1>';
    // });
    return returnString;
  }

  openViewNotesPopup(patientID) {
    const config: MatDialogConfig = {
      disableClose: true,
      width: '70vw',
      maxWidth: '70vw',
      height: '40vw',
      maxHeight: '40vw',
      data: {
        patientId: patientID,
        pageType: 'Patient Note',
        isAddVisible: false,
      },
    };
    const dialogRef = this.dialog.open(PatientCommonNotesComponent, config);

    dialogRef.afterClosed().subscribe(
      () => {
        //this.getReminderTableData();
      },
      (err: HttpErrorResponse) => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      }
    );
  }
  viewPatientLedger(patientId: string) {
    this.dialog.open(ViewPatientLedgerComponent, {
      width: '100vw',
      height: '100vh',
      maxWidth: '100vw',
      maxHeight: '100vh',
      panelClass: 'fullscreen-dialog-panel',
      disableClose: true,
      data: {
        patientId: patientId,
      },
    });
    this.dialog.afterAllClosed.subscribe((resposne) => {});
  }
  clearPayer() {
    this.denialSearchForm.patchValue({
      drpPayer: null,
    });
    this.getTableData();
  }
  clearDenial() {
    this.denialSearchForm.patchValue({
      drpDenial: null,
    });
    this.getTableData();
  }
  loadGlossaryDropDown() {
    this.isloadingGlossaryDropDownData = true;
    try {
      if (this.loadGlossaryDropDownAPICall) {
        this.loadGlossaryDropDownAPICall.unsubscribe();
      }
      this.loadGlossaryDropDownAPICall = this.glossaryDetailsService
        .drpGlossary()
        .subscribe(
          (response) => {
            if (response.items.length > 0) {
              this.lstReasonCode = response?.items;
              this.filteredreasonCode = this.denialSearchForm
                .get('txtDenialFilter')
                .valueChanges.pipe(
                  startWith(''),
                  map((value) =>
                    this.lstReasonCode?.filter((option) =>
                      option?.glossaryCode
                        ?.toLowerCase()
                        ?.includes(value?.toLowerCase() ?? '')
                    )
                  )
                );
            }

            setTimeout(() => {
              this.isloadingGlossaryDropDownData = false;
            }, 500);
          },
          (error) => {
            console.error('Error:', error);
            setTimeout(() => {
              this.isloadingGlossaryDropDownData = false;
            }, 500);
          }
        );
    } catch (error) {
      console.error('Unexpected error:', error);
      setTimeout(() => {
        this.isloadingGlossaryDropDownData = false;
      }, 500);
    } finally {
    }
  }
  getTableData() {
    this.isLoading = true;
    try {
      if (this.loadDenialsTableAPICall) {
        this.loadDenialsTableAPICall.unsubscribe();
      }
      let input: BillingDenialSearchDTO = {
        glossaryId: this.denialSearchForm.value.drpDenial
          ? this.denialSearchForm.value.drpDenial
          : [],
        // dtFromDate: this.txtCreatedFromDate,
        // dtToDate: txtCreatedEndDate,
        // dtDosFromDate: this.txtDOSFromDate,
        // dtDosToDate: txtDOSEndDate,
        // status: this.denialSearchForm.value.drpStatus || null,
        payerIds: this.denialSearchForm.value.drpPayer
          ? this.denialSearchForm.value.drpPayer
          : [],
        pageNo: this.currentPage,
        pageOffSet: this.pageOffset,
      };
      this.loadDenialsTableAPICall = this.billingDenialService
        .getDenialReportsByInput(input)
        .subscribe(
          (response) => {
            this.inBoundTableData = response?.items;
            this.overallTableCount = response?.totalCount;
            this.totalPages = Math.ceil(response?.totalCount / this.pageOffset);
            this.inBoundTableData = this.inBoundTableData.map((billing) => {
              return {
                ...billing,
                dosDate: this.commonService.getFormattedDateZone(billing.dtDos),
                dob: this.commonService.getFormattedDateZone(billing.dob),
                creationTime: this.commonService.getFormattedDateTimeZone(
                  billing.creationTime
                ),
                hcpcCode: this.processString(billing.hcpcCode),
                status: 'Paid',
                statusFgColor: '#fff',
                statusBgColor: '#16aeff',
              };
            });
            // this.tblShpSelectedRow(
            //   this.DataList?.[0]?.rentalId,
            //   this.DataList?.[0]
            // );
            const startRecord = (this.currentPage - 1) * this.recordsPerPage + 1;
            const endRecord = Math.min(this.currentPage * this.recordsPerPage, this.overallTableCount);
            this.dtDenialBinTableOptions.language.info = `Showing ${startRecord} to ${endRecord} of ${this.overallTableCount} records`;
            this.isLoading = false;
            setTimeout(() => {
              this.refreshDataTable();
            }, 100);
          },
          (err) => {
            this.inBoundTableData = [];
            const data: HttpErrorResponse = err;
            console.error('Unexpected error:', data?.error?.error?.message);
            setTimeout(() => {
              this.isLoading = false;
            }, 500);
          }
        );
      // this.tableData$ = getTableData;
    } catch (error) {
      console.error('Unexpected error:', error);
      // setTimeout(() => {
      this.isLoading = false;
      // }, 500);
    } finally {
    }
  }

  isPayerDropdownOpen = false;
  onPayerDropdownOpenChange(isOpen: boolean) {
    this.isPayerDropdownOpen = isOpen;
    if (this.isPayerDropdownOpen) {
      // this.invoiceSearchForm.value.txtInvoiceFilter || null
      this.loadPayerDropDown('');
    }
  }

  onSelectPrimaryPayor(event: any) {
    this.getTableData();
    // const payorList = event.value;
    // let splitValue = payorList.split('/');
    // this.primaryPayorId = splitValue[0];
    // this.checkPayorUniqness(this.primaryPayorId);
  }

  onSelectDenial(event: any) {
    // const denialList = event.value;
    this.getTableData();
    // let splitValue = payorList.split('/');
    // this.primaryPayorId = splitValue[0];
    // this.checkPayorUniqness(this.primaryPayorId);
  }
  oldPayer: string;
  loadPayerDropDown(value: string, eventKey?: string) {
    if (
      eventKey !== 'ArrowDown' &&
      eventKey !== 'ArrowUp' &&
      eventKey !== 'ArrowRight' &&
      eventKey !== 'ArrowLeft'
      // &&
      // value == ''
      // && value != this.oldPayer
    ) {
      this.oldPayer = value;
      //Get Payer list
      this.isdrpLoading = true;
      if (this.loadPayerDropDownAPICall) {
        this.loadPayerDropDownAPICall.unsubscribe();
      }
      let eligibilityMode = null;

      // iMode: number, sSearch: string, gPayor: string, ExistingPayors: string[]

      this.loadPayerDropDownAPICall = this.billingDenialService
        .getdrpPayerByIModeAndSSearchAndGPayorAndExistingPayors(
          eligibilityMode,
          value,
          this.payerId,
          this.denialSearchForm.value.drpPayer
            ? this.denialSearchForm.value.drpPayer
            : []
        )
        .subscribe((response) => {
          this.selectPayorList = response;
          this.isdrpLoading = false;
          this.selectPayorList = response.map((x) => {
            x['payerShortCodeList'] =
              (x?.payerCode === null ||
              x?.payerCode === '' ||
              (x?.payerCode).toLowerCase() == 'null'
                ? '-'
                : x?.payerCode) +
              ' ' +
              '/' +
              ' ' +
              x?.payerName +
              ' ' +
              '/' +
              ' ' +
              (x?.code === null ||
              x?.code === '' ||
              (x?.code).toLowerCase() == 'null'
                ? '-'
                : x?.code);
            return x;
          });

          this.filteredPrimaryPayors = this.denialSearchForm
            .get('txtPayerFilter')
            ?.valueChanges.pipe(
              startWith(''),
              map((value) =>
                this.selectPayorList?.filter((option) =>
                  option?.payerShortCodeList
                    ?.toLowerCase()
                    ?.includes(value?.toLowerCase() ?? '')
                )
              )
            );
        });
    } else {
    }
  }
  drpDownMessage() {
    if (this.isdrpLoading === false) {
      return 'No Matches found';
    } else {
      return 'Loading...';
    }
  }
  processString(input: string): string {
    const parts = input.split('/');

    // If the string was split, remove the first part and return the remaining
    if (parts.length > 1) {
      return parts.slice(1).join(', ').trim();
    }

    // If no split happened, return the input as is
    return input.trim();
  }
  addPageNavigationControls() {
    let buttonsElement = $('.dt-buttons')[0];

    if (this.currentPage == 1) {
      $(
        "<button disabled title='First Page' class='dt-button dt-custom-pagination-buttons disabled' id='btnFirstPage' type='button'><span>&lt;&lt;</span></button>"
      ).appendTo(buttonsElement);
      $(
        "<button disabled class='dt-button dt-custom-pagination-buttons disabled' id='btnPrevPage' type='button'><span>&lt;</span></button>"
      ).appendTo(buttonsElement);
    } else {
      $(
        "<button title='First Page' class='dt-button dt-custom-pagination-buttons' id='btnFirstPage' type='button'><span>&lt;&lt;</span></button>"
      ).appendTo(buttonsElement);
      $(
        "<button title='Previous Page' class='dt-button dt-custom-pagination-buttons' id='btnPrevPage' type='button'><span>&lt;</span></button>"
      ).appendTo(buttonsElement);
    }
    // style='float:left !important;'
    $("<span class='dt-pagination-input-span' > Page <span>").appendTo(
      buttonsElement
    );
    $(
      "<input class='dt-pagination-input' id='txtGotoPage' value='" +
        this.currentPage +
        "' type='text' maxlength='6'/>"
    ).appendTo(buttonsElement);
    $('#txtGotoPage').on('input', function (e) {
      let value = (this as HTMLInputElement).value;
      value = value.replace(/[^0-9]/g, '');
      if (value.startsWith('0')) {
        value = value.substring(1);
      }
      (this as HTMLInputElement).value = value;
    });
    $(
      "<span class='dt-pagination-input-span pl-1'> of " +
        this.totalPages +
        ' <span>'
    ).appendTo(buttonsElement);
    $(
      "<button  class='dt-button dt-custom-pagination-buttons-goto' id='btnGoToPage'   type='button'><span>Go to</span></button>"
    ).appendTo(buttonsElement);

    if (this.currentPage >= this.totalPages) {
      $(
        "<button disabled class='dt-button dt-custom-pagination-buttons disabled' id='btnNextPage'   type='button'><span>&gt;</span></button>"
      ).appendTo(buttonsElement);
      $(
        "<button disabled title='Last Page'  class='dt-button dt-custom-pagination-buttons disabled' id='btnLastPage'  type='button'><span>&gt;&gt</span></button>"
      ).appendTo(buttonsElement);
    } else {
      $(
        "<button title='Next Page'  class='dt-button dt-custom-pagination-buttons' id='btnNextPage'  type='button'><span>&gt;</span></button>"
      ).appendTo(buttonsElement);
      $(
        "<button title='Last Page'  class='dt-button dt-custom-pagination-buttons' id='btnLastPage'  type='button'><span>&gt;&gt</span></button>"
      ).appendTo(buttonsElement);
    }

    $('#btnNextPage').on('click', (event) => this.changePage(true));
    $('#btnPrevPage').on('click', (event) => this.changePage(false));
    $('#btnGoToPage').on('click', (event) => this.fnGotoPage());
    $('#btnFirstPage').on('click', (event) => this.nevigatePage.emit(1));
    $('#btnLastPage').on('click', (event) =>
      this.nevigatePage.emit(this.totalPages)
    );
  }
  changePage(isNext: boolean) {
    if (isNext) this.currentPage++;
    else this.currentPage--;
    this.nevigatePage.emit(this.currentPage);
  }

  fnGotoPage() {
    let pageValue = $('#txtGotoPage').val();
    let pageno = parseInt(pageValue.toString());
    if (pageno > 0 && this.currentPage != pageno && pageno <= this.totalPages) {
      this.nevigatePage.emit(pageno);
    }
  }

  isDenialSelectionChanged = false;
  onSelectionDenialChange(event: MatSelectChange): void {
    this.isDenialSelectionChanged = true;

    this.drpDenialId = event.value;
    if (this.drpDenialId.includes('selectAll')) {
      this.denialCodeSelectAllOption = true;
      if (this.denialCodeSelectAllOption) {
        this.drpDenialId = this.lstReasonCode.map((data) => data.id);
        this.denialSearchForm.get('drpDenial').patchValue(this.drpDenialId);
      }
    } else if (this.drpDenialId.includes('DeselectAll')) {
      this.drpDenialId = [];
      this.denialCodeSelectAllOption = false;
      this.denialSearchForm.get('drpDenial').patchValue([]);
    } else {
      this.denialCodeSelectAllOption =
        this.drpDenialId.length === this.lstReasonCode.length;
    }
    this.drpDenialId = this.denialSearchForm.value.drpDenial;
  }

  isPayorSelectionChanged = false;
  onSelectionPayorChange(event: MatSelectChange): void {
    this.isPayorSelectionChanged = true;

    this.drpPayorId = event.value;
    if (this.drpPayorId.includes('selectAll')) {
      this.payorSelectAllOption = true;
      if (this.payorSelectAllOption) {
        this.drpPayorId = this.selectPayorList.map((data) => data.payerId);
        this.denialSearchForm.get('drpPayer').patchValue(this.drpPayorId);
      }
    } else if (this.drpPayorId.includes('DeselectAll')) {
      this.drpPayorId = [];
      this.payorSelectAllOption = false;
      this.denialSearchForm.get('drpPayer').patchValue([]);
    } else {
      this.payorSelectAllOption =
        this.drpPayorId.length === this.lstReasonCode.length;
    }
    this.drpPayorId = this.denialSearchForm.value.drpPayer;
  }
  resetForm() {
    this.denialCodeSelectAllOption = false;
    this.payorSelectAllOption = false;
    this.denialSearchForm.reset();
    this.getTableData();
  }
}
