import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Subscription } from 'rxjs';
import {
  ProductDetailsService,
  ProductStockDetailsService,
} from '../item-proxy/item-management/optimization';
import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import {
  debounceTime,
  filter,
  map,
  startWith,
  switchMap,
  tap,
} from 'rxjs/operators';
import { MatOption } from '@angular/material/core';
import { MatSelect } from '@angular/material/select';
import {
  ProductStockDetailStatus,
  stockDetailsStatusOptions,
} from '../item-proxy/item-management/optimization/dto';
import { DateValidator } from 'projects/shared/src/app/validations/date-validator';
import { MatDialog } from '@angular/material/dialog';
import { AddStockComponent } from '../add-stock/add-stock.component';
import { Router } from '@angular/router';

@Component({
  selector: 'app-stock-list-tab',
  templateUrl: './stock-list-tab.component.html',
  styleUrls: ['./stock-list-tab.component.scss'],
  animations: [
    trigger('openClose', [
      state(
        'open',
        style({
          transform: 'translateX(0)',
        })
      ),
      state(
        'closed',
        style({
          transform: 'translateX(-100%)',
        })
      ),
      transition('open <=> closed', [animate('0.3s')]),
    ]),
  ],
})
export class StockListTabComponent implements OnInit {
  step: boolean = false;
  stockForm: FormGroup;
  tableData: any[] = [];
  stockSearchForm: FormGroup;
  dataSource: any;
  product: string = '';
  drpProduct: any;
  filterMainProducts: any;
  status: string = '';
  drpStatus: { value: string; key: string }[] = [];
  filterStatus: any;
  displayedColumns: string[] = [

    'productName',
    // 'brandName',
    'categoryName',
    'pricePerUnit',
    'lotNo',
    // 'options',
    'stockCount',
    'balanceStock',
    'status',
    'createdDate',
  ];
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild('allProducts') private allProducts: MatOption;
  @ViewChild('selectProduct') selectProduct: MatSelect;
  @ViewChild('allStatus') private allStatus: MatOption;
  @ViewChild('selectStatus') selectStatus: MatSelect;
  subscription$: Subscription[] = [];
  isShowSpinner: boolean = false;
  showFilter = true;


  constructor(
    private formBuilder: FormBuilder,
    private stockDetailsService: ProductStockDetailsService,
    private productDetailsService: ProductDetailsService,
    private dateValidator: DateValidator,
    public dialog: MatDialog,
    public router: Router,
  ) {}

  //Page Load
  ngOnInit(): void {
    this.initializeForms();
    this.loadDropdowns();
    this.searchStockByFilter(); //Get All data on Page Load
    this.searchStockByTableInput();
  }

  //Toggle Filter Div
  toggleFilter() {
    this.showFilter = !this.showFilter;
  }
  //Method for Product Multi Select Dropdown all options
  toggleAllSelection() {
    if (this.allProducts.selected) {
      this.selectProduct.options.forEach((item: MatOption) => item.select());
    } else {
      this.selectProduct.options.forEach((item: MatOption) => item.deselect());
    }
  }
  updateSerialNo(data){
    this.router.navigate(['inventory/stock-view/' + [data.id.toString()] ])

  }
  openProductModal() {
    const dialogRef = this.dialog.open(AddStockComponent, {
      disableClose: true,

    });

    const closeModal =  dialogRef.afterClosed().subscribe(() => {
      this.searchStockByTableInput()
    });
    this.subscription$.push(closeModal);
  }

  editStockList(data){
    const dialogRef = this.dialog.open(AddStockComponent, {
      disableClose: true,
      data: { productId: data.id, }
    });

    const closeModal =  dialogRef.afterClosed().subscribe(() => {
      this.searchStockByTableInput()
    });
    this.subscription$.push(closeModal);
  }
  //Method to Initialize the Forms
  initializeForms() {
    this.stockForm = this.formBuilder.group({
      drpProduct: new FormControl(''),
      txtMainProductFilter: new FormControl(''),
      drpStatus: new FormControl(''),
      txtStatusFilter: new FormControl(''),
      txtCreatedate: new FormControl('',),
      txtProductType: new FormControl('')
    });

    this.stockSearchForm = this.formBuilder.group({
      productName: new FormControl(''),
      brandName: new FormControl(''),
      categoryName: new FormControl(''),
      pricePerUnit: new FormControl(''),
      lotNo: new FormControl(''),
      //txtStockCount: new FormControl(''),
      balanceStock: new FormControl(''),
      createdDate: new FormControl('', this.dateValidator.dateVaidator),
    });
  }

  //Dropdowns load function
  loadDropdowns() {
    this.getProducts();
    this.getStockStatus();
  }
  //Get Stock Status and patch in Status Dropdown
  getStockStatus() {
    this.filterStatus = stockDetailsStatusOptions?.map((e) => {
      let x = { value: e.value, key: e.key };
      return x;
    });
  }

  //To get the All Products List and to patch in Dropdown
  getProducts() {
    this.stockForm.value.txtProductType = this.stockForm.value.txtProductType ? this.stockForm.value.txtProductType : 1
    if(this.stockForm.value.txtProductType == 1){
      const productList = this.productDetailsService.getProductsList().subscribe(
        (response) => {
          // this.drpProduct = response;
          // this.drpProduct = response.map((x)=>{
          //   x.ProductTypes == 'MainProduct' ? x : []
          // })
          this.filterMainProducts = this.stockForm
            ?.get('txtMainProductFilter')
            .valueChanges.pipe(
              startWith(''),
              map((value) =>
                this.drpProduct.filter((option) =>
                  option?.mainProductName
                    ?.toLowerCase()
                    ?.includes(value?.toLowerCase() ?? '')
                )
              )
            );
        },
        (err) => {
          const data: HttpErrorResponse = err;
        }
      );

      this.subscription$.push(productList);
    } else if(this.stockForm.value.txtProductType == 2){
      const productList = this.productDetailsService.getProductsList().subscribe(
        (response) => {
          // this.drpProduct = response;
          // this.drpProduct = response.map((x)=>{
          //   x.ProductTypes == 'ResupplyProduct' ? x : []
          // })
          this.filterMainProducts = this.stockForm
            ?.get('txtMainProductFilter')
            .valueChanges.pipe(
              startWith(''),
              map((value) =>
                this.drpProduct.filter((option) =>
                  option?.mainProductName
                    ?.toLowerCase()
                    ?.includes(value?.toLowerCase() ?? '')
                )
              )
            );
        },
        (err) => {
          const data: HttpErrorResponse = err;
        }
      );

      this.subscription$.push(productList);
    }

  }

  changeProduct(e){
    this.stockForm.value.txtProductType = this.stockForm.value.txtProductType ? this.stockForm.value.txtProductType : e
   this.loadDropdowns()
  }

  //Set Table Data
  setTableData(data: any[]) {
    this.dataSource = new MatTableDataSource(data);
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }

  //Method to get the Stock search by Table Input Fields
  searchStockByTableInput() {
    const stockSearch = this.stockSearchForm.valueChanges
      .pipe(
        startWith({
          productName: '',
          brandName: '',
          categoryName: '',
          pricePerUnit: '',
          lotNo: '',
          balanceStock: '',
          createdDate: '',
        }),
        tap((x) => {
          this.isShowSpinner = true;
          this.setTableData([]);
        }),
        filter((e) => e && this.stockSearchForm?.valid),
        debounceTime(300),
        map((response) => {
          this.isShowSpinner = true;
          const sValue = {
            productName: response?.productName?.trim()?.toLowerCase(),
            brandName: response?.brandName?.trim()?.toLowerCase(),
            categoryName: response?.categoryName?.trim()?.toLowerCase(),
            pricePerUnit: response?.pricePerUnit.trim()?.toLowerCase(),
            lotNo: response?.lotNo?.trim()?.toLowerCase(),
            balanceStock: response?.balanceStock?.trim()?.toLowerCase(),
            status: ProductStockDetailStatus.All,
            createdDate: response?.createdDate?.trim()?.toLowerCase(),
          };
          return sValue;
        }),
        switchMap((sValue) =>
          this.stockDetailsService.getSearchStocksByLotNoAndPricePerUnitAndQuantityAndSoldQuantityAndProductType(
            sValue?.productName,
            sValue?.categoryName,
            sValue?.brandName,
            sValue?.lotNo,
            sValue?.pricePerUnit,
            '', //quantity
            '', //soldquantity,
            sValue?.balanceStock,
            '', //Product Type
            ProductStockDetailStatus.All,
            //sValue?.status,
            sValue?.createdDate
          )
        )
      )
      .subscribe(
        (response) => {
          this.isShowSpinner = false;

          this.setTableData(response);
        },
        (err) => {
          this.isShowSpinner = false;
          const data: HttpErrorResponse = err;
        }
      );
    this.subscription$.push(stockSearch);
  }

  //Method to get the Stock search by Filter Options
  searchStockByFilter() {
    this.isShowSpinner = true;
    let productIds: any[] = [];
    let stockStatus: any[] = [];

    this.stockForm.value.drpProduct !== '' &&
      this.stockForm.value.drpProduct !== undefined &&
      this.stockForm.value.drpProduct !== null &&
      [this.stockForm.value.drpProduct].length !== 0 &&
      [this.stockForm.value.drpProduct].forEach((val) => {
        val?.forEach((element) => {
          element !== undefined && element !== 0 && productIds.push(element);
        });
      });
    this.stockForm.value.drpStatus != '' &&
      this.stockForm.value.drpStatus !== undefined &&
      this.stockForm.value.drpStatus !== null &&
      stockStatus.push(this.stockForm.value.drpStatus);
    let createdDate: string = this.stockForm.value.txtCreatedate;

    const stockListByFilter = this.stockDetailsService
      .getSearchStocksBasedonFilter(
        productIds,
        stockStatus,
        createdDate,
        createdDate
      )
      .subscribe(
        (response) => {
          this.isShowSpinner = false;

          this.setTableData(response);
        },
        (err) => {}
      );
  }

  //Reset the Filter Form
  resetForm() {
    this.stockForm?.reset();
    this.stockForm.patchValue({
      drpProduct: '',
      drpStatus: '',
    });
    this.toggleFilter();
    this.searchStockByFilter();
  }

  //method to clear the product field
  clearProduct() {
    this.product = '';
    this.stockForm.patchValue({ drpProduct: '' });
  }

}
