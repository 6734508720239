<br>
<div class="card card-body">
  <mat-accordion #firstAccordion="matAccordion">
    <mat-expansion-panel [expanded]="step" hideToggle>
      <mat-expansion-panel-header>
        <mat-panel-title class="customThemeClass">
          <b>Paypal Details</b>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div class="row">
        <div class="col-12">
          <form [formGroup]="paymentSetupForm">
            <div class="row">
              <mat-form-field class="col-2">
                <mat-label>Merchant Id <span class="asterisk">*</span></mat-label>
                <input autocomplete="new-merchantId" formControlName="merchantId" minlength="13" maxlength="13" matInput type="text" />
                <mat-error *ngIf="
                paymentSetupForm?.get('merchantId')?.touched &&
                paymentSetupForm?.get('merchantId')?.errors?.required
              ">
                  Merchant Id is a required field!
                </mat-error>
                <mat-error *ngIf="
                paymentSetupForm?.get('merchantId')?.touched &&
                paymentSetupForm?.get('merchantId')?.errors?.minlength
              ">
                  Enter Minimum 13 Characters!
                </mat-error>
                <mat-icon matSuffix (click)="toggleHelpContext(showHelp,'isMerchantHelp')">help_outline</mat-icon>
              </mat-form-field>

              <mat-form-field class="col-2">
                <mat-label>Payment Type<span class="asterisk">*</span></mat-label>
                <mat-select formControlName="paymentType" [(value)]="paymentType" maxlength="40">
                  <mat-option *ngFor="let type of drpPaymentType" [value]="type.paymentType">
                    {{ type.paymentType }}
                  </mat-option>
                </mat-select>
                <button mat-button (click)="paymentType=''" (click)="clearPaymentName();$event.stopPropagation()"
                  *ngIf="paymentType" matSuffix mat-icon-button >
                  <mat-icon>close</mat-icon>
                </button>
                <mat-error
                  *ngIf=" paymentSetupForm?.get('paymentType')?.touched && paymentSetupForm?.get('paymentType')?.errors?.required">
                  Payment Type is a required field!
                </mat-error>
              </mat-form-field>
              <mat-form-field class="col-2">
                <mat-label>Payment Options<span class="asterisk">*</span></mat-label>
                <mat-select formControlName="paymentOptionName" [(value)]="paymentOptionName" multiple maxlength="40">
                  <mat-option *ngFor="let typeOptions of drpPaymentTypeOptions"
                    [value]="typeOptions.paymentTypeOptions">
                    {{ typeOptions.paymentTypeOptions }}
                  </mat-option>
                </mat-select>
                <button mat-button (click)="paymentOptionName=''"
                  (click)="clearPaymentOptionName();$event.stopPropagation()" *ngIf="paymentOptionName" matSuffix
                  mat-icon-button >
                  <mat-icon>close</mat-icon>
                </button>
                <mat-error
                  *ngIf=" paymentSetupForm?.get('paymentOptionName')?.touched && paymentSetupForm?.get('paymentOptionName')?.errors?.required">
                  Payment Options is a required field!
                </mat-error>
              </mat-form-field>
              <mat-form-field class="col-2">
                <mat-label>Paypal Email Address <span class="asterisk">*</span></mat-label>
                <input autocomplete="new-txtPaypalLoginEmailAddress" formControlName="txtPaypalLoginEmailAddress" matInput [textMask]="{ mask: emailMask }"
                pattern="[a-zA-Z0-9._!#$%&'()*+,-./:;<=>?@[\]^_`{|}~]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$" type="text" />
                <mat-error *ngIf="                paymentSetupForm?.get('txtPaypalLoginEmailAddress')?.touched &&
                paymentSetupForm?.get('txtPaypalLoginEmailAddress')?.errors?.required              ">
                  Paypal Email Address is a required field!
                </mat-error>
                <mat-error *ngIf="                paymentSetupForm?.get('txtPaypalLoginEmailAddress')?.touched &&
                paymentSetupForm?.get('txtPaypalLoginEmailAddress')?.errors?.pattern              ">
                  This is not a valid Email!!!
                </mat-error>
              </mat-form-field>

              <mat-form-field class="col-2">
                <mat-label>Payment Due Days<span class="asterisk">*</span></mat-label>
                <mat-select formControlName="drpPaymentDueDays" [(value)]="drpPaymentDueDays" maxlength="40">
                  <mat-option *ngFor="let typeOptions of lstPaymentDueDays" [value]="typeOptions.shortCodeId">
                    {{ typeOptions.paymentDueDays }}
                  </mat-option>
                </mat-select>
                <button mat-button (click)="drpPaymentDueDays=''"
                  (click)="clearPaymentDueDays();$event.stopPropagation()" *ngIf="drpPaymentDueDays" matSuffix
                  mat-icon-button >
                  <mat-icon>close</mat-icon>
                </button>
                <mat-error
                  *ngIf=" paymentSetupForm?.get('drpPaymentDueDays')?.touched && paymentSetupForm?.get('drpPaymentDueDays')?.errors?.required">
                  Payment Due Days is a required field!
                </mat-error>

              </mat-form-field>
              <div class="col-2 pt-3">
                <mat-checkbox formControlName="chkEnabled">Active</mat-checkbox>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <div *ngIf="showHelp.isMerchantHelp" class="alert alert-success" role="alert">
                  <!-- {{showHelp.helpText }} -->
                  <ul class="list-unstyled">
                    <li>Where to Find Merchant Id:
                      <ol>
                        <li>Login to your PayPal account.</li>
                        <li>Click the Settings icon on the top right,Select “Account Settings”.</li>
                        <li>On the left column, under “Business Profile” click “Business Information”.</li>
                        <li>The PayPal Merchant ID should display.</li>
                      </ol>
                    </li>
                  </ul>
                  <a class="text-primary" href="https://www.sandbox.paypal.com/bizsignup/#/checkAccount"
                    target="_blank"><strong>
                      <u> Click Here</u>
                    </strong> </a>
                </div>
                <div *ngIf="showHelp.isMerchantHelp" class="alert alert-success" role="alert">
                  <!-- {{showHelp.helpText }} -->
                  <ul class="list-unstyled">
                    <li>Give Authorize to following Permissions:
                      <ol>
                        <li>Login to your PayPal account.</li>
                        <li>Click the Settings icon on the top right,Select “Account Settings”.</li>
                        <li>On the left column, under Account & Security” click “Account access”.</li>
                        <li>In the “API access” section, click “Update”.</li>
                        <li>Click “Grant API permission” under “Pre-built payment solution”.</li>
                        <li>Enter API username to grant permissions.</li>
                        <li>On the Add New Third Party Permissions page, select the below permissions you want to grant
                          to the third party and click Add.</li>
                        <ul>
                          <li> Use Express Checkout to process payments</li>
                          <li>Process your customers credit or debit card payments.</li>
                          <li>Authorize and capture your PayPal transactions.</li>
                          <li>Obtain information about a single transaction.</li>
                          <li>Search your transactions for items that match specific criteria and display the results.
                          </li>
                          <!-- <li>Obtain authorization for pre-approved payments and initiate pre-approved transactions.
                          </li>
                          <li>Use Express Checkout to process mobile payments.</li> -->
                          <li>Manage your invoicing through a third party.</li>
                          <li>Capture Payment in Batches.</li>
                        </ul>
                      </ol>
                    </li>
                  </ul>
                  <a class="text-primary" href="https://www.sandbox.paypal.com/bizsignup/#/checkAccount"
                    target="_blank"><strong>
                      <u> Click Here</u>
                    </strong> </a>
                </div>

              </div>
            </div>
          </form>
        </div>
        <div class="col-12 text-lg-center">
          <button [disabled]="paymentSetupForm.invalid" mat-button class="buttonColor mr-2"
            (click)="savePaymentDetails()">
            Save
            <mat-icon *ngIf='isShowSpinner'>
              <mat-spinner class="spinner-border spinner-border-sm" diameter="20"> </mat-spinner>
            </mat-icon>
          </button>
          <button mat-button class="buttonColor mr-2 resetclr" (click)="resetPaymentDetailsForm()">
            Reset
          </button>
        </div>
      </div>
    </mat-expansion-panel>
  </mat-accordion>
  <div class="row">
    <div class="col-12 table-responsive">
      <app-table [strPageType]="strPageType" [isLoading]="isLoading" (viewButton)="getTenantDetailsByID($event)"
        (deleteButton)="deletePaymentOption($event)"> </app-table>
    </div>

    <div class="col-12 table-responsive">
      <h4 class="customThemeClass"><b>Payment Transaction Detatils</b></h4>
      <!-- <div [hidden]="!isLoading"
        style="display: flex; justify-content: center; align-items: center; background: transparent; color: lightseagreen;">
        <mat-progress-bar mode="indeterminate" value='66' diameter="45" class="lightseagreen">
        </mat-progress-bar>
      </div> -->
      <table mat-table class="table " [dataSource]="dataSource" style="border: 1px solid #cdc7c7"  matSort>
        <ng-container matColumnDef="sno">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            S.No
          </th>
          <td mat-cell *matCellDef="let datas; let i = index">
            {{ i+1 }}
          </td>
        </ng-container>

        <ng-container matColumnDef="patientId">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            Patient Id
          </th>
          <td mat-cell *matCellDef="let datas">
            {{ datas?.patientId }}
          </td>
        </ng-container>
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            Name
          </th>
          <td mat-cell *matCellDef="let datas">
            <ng-container>
              {{ datas?.PatientName }}
            </ng-container>
          </td>
        </ng-container>
        <ng-container matColumnDef="orderId">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            Order Id
          </th>
          <td mat-cell *matCellDef="let datas">
            {{ datas?.saleOrderId }}
          </td>
        </ng-container>
        <ng-container matColumnDef="status">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            Status
          </th>
          <td mat-cell *matCellDef="let datas">
            {{ datas?.status }}
          </td>
        </ng-container>
        <ng-container matColumnDef="date">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            Create Date
          </th>
          <td mat-cell *matCellDef="let datas">
            {{ datas?.createDate }}
          </td>
        </ng-container>
        <ng-container matColumnDef="paymentMode">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            Payment Mode
          </th>
          <td mat-cell *matCellDef="let datas">
            {{ datas?.checkoutOrInvoice }}
          </td>
        </ng-container>
        <ng-container matColumnDef="Options">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            Options
          </th>
          <td mat-cell *matCellDef="let datas">
            <a *ngIf=" datas?.checkoutOrInvoice==='CheckOutOrder'" (click)="getItemPayment(datas?.id)"
              class="eyeCursorclass" target="_blank"><i class="fa fa-eye pointer customThemeClass mr-5"></i> </a>
            <a *ngIf="datas?.checkoutOrInvoice==='Invoice'" (click)="getItemInvoicePayment(datas?.id)"
              class="eyeCursorclass" target="_blank"><i class="fa fa-eye pointer customThemeClass mr-5"></i> </a>
          </td>
        </ng-container>

        <ng-container matColumnDef="noRecords">
          <td class="text-align-center" mat-footer-cell *matFooterCellDef colspan="8">
            <div *ngIf="!isLoading">
              {{"No Records Found"}}
            </div>
            <div [hidden]="!isLoading" >
              <mat-spinner [diameter]="220"></mat-spinner>
            </div>
          </td>
        </ng-container>
        <ng-container matColumnDef="paginator">
          <td mat-footer-cell *matFooterCellDef colspan="8">
            <mat-paginator class="table table-responsive mat-table-class" #MatPaginator
              [length]="dataSource?.data?.length" [pageSize]="10" [pageIndex]="0" showFirstLastButtons
              [pageSizeOptions]="[5, 10, 25, 100]">
            </mat-paginator>
          </td>
        </ng-container>

        <tr mat-footer-row *matFooterRowDef="['noRecords']"
          [ngClass]="{ active: dataSource && dataSource.data && dataSource?.data?.length !== 0 }"></tr>
        <tr mat-header-row *matHeaderRowDef="arrDisplayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: arrDisplayedColumns"></tr>
        <tr mat-footer-row *matFooterRowDef="['paginator']"></tr>
      </table>
    </div>

  </div>
</div>
