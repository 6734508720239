<div class="container-fluid">
  <!-- Page Headers -->
  <div class="row bg">
    <div class="col-12">
      <div class="d-flex align-items-center justify-content-between">
        <h3 class="mb-0 font-size-18"><b>Inbound / Sorting Documents</b></h3>
        <div class="page-title-box page-title-right pt-2 pb-0">
          <ol class="breadcrumb m-0" style="background-color: transparent">
            <div class="input-group m-2" id="daterange">
              <input
                type="text"
                class="form-control"
                [hidden]="dateRangStatus != true"
                aria-describedby="basic-addon1"
                name="daterange"
                ngxDaterangepickerMd
                [ranges]="ranges"
                [(ngModel)]="selectedRange"
                id="calendar-input"
                [showCustomRangeLabel]="true"
                [alwaysShowCalendars]="true"
                [linkedCalendars]="true"
                [showClearButton]="true"
                [locale]="calendarLocale"
                [placeholder]="calendarPlaceholder"
                [keepCalendarOpeningWithRange]="true"
                [minDate]="minDate"
                [maxDate]="maxDate"
                (datesUpdated)="datesUpdateds($event)"
                autocomplete="new-daterange"
              />
            </div>
           
            <li>
              <!-- <form [formGroup]="branchForm"> -->
                <mat-form-field class="pr-3">
                  <mat-label>Branch</mat-label>
                  <mat-select #selectBranch formControlName="drpBranch" multiple [(value)]="organizationUnitName">
                    <mat-option>
                      <ngx-mat-select-search
                        placeholderLabel="Search"
                        noEntriesFoundLabel="No Matches found"
                        formControlName="txtBranch"
                      ></ngx-mat-select-search>
                    </mat-option>
    
                    <mat-option #selectAllBranch (click)="allBranchSelect()" value="0">All</mat-option>
    
                    <mat-option *ngFor="let branch of brnachList | async" [value]="branch.id">
                      {{ branch.organizationUnitName }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
                <mat-radio-button class="All-radio-button m-2" id="disableRadio" [checked]="true" (click)="disableFilter()">
                  All
              </mat-radio-button>
              <mat-radio-button class="Date-radio-button m-2" id="enableRadio" (click)="enableFilter()">
                Date
            </mat-radio-button>
              <li>
                  <span class="input-group-text bg-color mr-3 mt-1 disabled" id="basic-addon1">
                      <label style="margin: auto" for="calendar-input">
                          <i class="fa fa-filter cursor" aria-hidden="true"></i>
                      </label>
                  </span>
              </li>
              <li>
                <button
                  matTooltip="Click To Go back to Processed Documents"
                  (click)="redirectToProcessedDocuments()"
                  mat-button
                  class="buttonColor font-size-10 mr-2"
                >
                  <i class="fa fa-backward mr-1"></i>Back to Processed Documents
                </button>
                <button
                  matTooltip="Click To Go back to Inbound"
                  (click)="redirectToInbound()"
                  mat-button
                  class="buttonColor font-size-10 mr-2"
                >
                  <i class="fa fa-backward mr-1"></i>Back to Inbound
                </button>
              <!-- </form> -->
              
            </li>

            <!-- <li class="ml-2 mt-2 breadcrumb-item">Inbound</li>
            <li class="mt-2 breadcrumb-item muted">Processed Documents</li> -->
          </ol>
        </div>
      </div>
    </div>
  </div>
  <!-- <button mat-button (click)="redirectToManualSorting()">Manual Sorting</button> -->
  <!-- <button mat-button (click)="redirectToAutoSorting()">Auto Sorting</button> -->
  <!-- Table Div-->
  <div class="row mt-2">
    <div class="col-lg-2">
      <mat-card class="overflow-hidden pr-0" style="height:75vh;">
        <mat-card-header class="sticky-top">
          <div class="total-count-label">Locations</div>
        </mat-card-header>
        <mat-divider class="pt-1"></mat-divider>
        <mat-card-content>
          
          <mat-tree [dataSource]="treeDatasource" [treeControl]="treeControl" style="height:70vh;" class="overflow-auto">
            <!-- This is the tree node template for leaf nodes -->
            <mat-tree-node *matTreeNodeDef="let node" matTreeNodePadding>
              <!-- use a disabled button to provide padding for tree leaf -->
              <button mat-icon-button disabled></button>
              {{ node.name }}
            </mat-tree-node>
            <!-- This is the tree node template for expandable nodes -->
            <mat-tree-node
              *matTreeNodeDef="let node; when: hasChild"
              matTreeNodePadding
            >
              <button
                mat-icon-button
                matTreeNodeToggle
                [attr.aria-label]="'toggle ' + node.name"
              >
                <mat-icon class="mat-icon-rtl-mirror">
                  {{ treeControl.isExpanded(node) ? "expand_more" : "chevron_right" }}
                </mat-icon>
              </button>
              {{ node.name }}
            </mat-tree-node>
          </mat-tree>
        </mat-card-content>
      </mat-card>
    </div>
  <div class="col-lg-10 ">
    <form [formGroup]="processedDocumentsSearchForm">
      <div class="table table-responsive example-container mat-elevation-z4">
        <table mat-table [dataSource]="dataSource" matSort class="pt-0 mt-0">
          <!-- Document Id -->
          <ng-container matColumnDef="documentId">
            <th
              class="header"
              mat-sort-header
              mat-header-cell
              *matHeaderCellDef
            >
              <mat-form-field class="filter">
                <input
                  autocomplete="new-documentId"
                  matInput
                  formControlName="documentId"
                  (keydown.space)="$event.stopPropagation()"
                  (click)="$event.stopPropagation()"
                />
                <mat-placeholder>Document Id</mat-placeholder>
              </mat-form-field>
            </th>
            <td mat-cell *matCellDef="let data">
              <ng-container>
              <a  matTooltip="Click to View Sorting" class="eyeCursorclass"
              (click)="viewSorting(data.patientId,data.wipId)"
              >  {{ data.defaultdocumentId }}</a>

              </ng-container>
            </td>
          </ng-container>
          <!-- WIP ID -->
          <ng-container matColumnDef="wipId">
            <th
              class="header"
              mat-sort-header
              mat-header-cell
              *matHeaderCellDef
            >
              <mat-form-field class="filter">
                <input
                  autocomplete="new-wipId"
                  matInput
                  formControlName="wipId"
                  (keydown.space)="$event.stopPropagation()"
                  (click)="$event.stopPropagation()"
                />
                <mat-placeholder>WIP Id</mat-placeholder>
              </mat-form-field>
            </th>
            <td mat-cell *matCellDef="let data">
              <ng-container>
                {{ data.defaultWipId }}
              </ng-container>
            </td>
          </ng-container>
          <!-- Patient Id -->
          <ng-container matColumnDef="patientId">
            <th
              class="header"
              mat-sort-header
              mat-header-cell
              *matHeaderCellDef
            >
              <mat-form-field class="filter">
                <input
                  autocomplete="new-patientId"
                  matInput
                  formControlName="patientId"
                  (keydown.space)="$event.stopPropagation()"
                  (click)="$event.stopPropagation()"
                />
                <mat-placeholder>Patient Id</mat-placeholder>
              </mat-form-field>
            </th>
            <td mat-cell *matCellDef="let data">
              <ng-container>
                {{ data.defaultpatientId }}
              </ng-container>
            </td>
          </ng-container>

          <!-- Patient Name -->
          <ng-container matColumnDef="patientName">
            <th
              class="header"
              mat-sort-header
              mat-header-cell
              *matHeaderCellDef
            >
              <mat-form-field class="filter">
                <input
                  autocomplete="new-patientName"
                  matInput
                  formControlName="patientName"
                  (keydown.space)="$event.stopPropagation()"
                  (click)="$event.stopPropagation()"
                />
                <mat-placeholder>Patient Name</mat-placeholder>
              </mat-form-field>
            </th>
            <td mat-cell *matCellDef="let data">
              <ng-container>
                {{ data.name }}
              </ng-container>
            </td>
          </ng-container>

          <!-- Assigned To -->
          <ng-container matColumnDef="assignedTo">
            <th
              class="header"
              mat-sort-header
              mat-header-cell
              *matHeaderCellDef
            >
              <mat-form-field class="filter">
                <input
                  autocomplete="new-assignedTo"
                  matInput
                  formControlName="assignedTo"
                  (keydown.space)="$event.stopPropagation()"
                  (click)="$event.stopPropagation()"
                />
                <mat-placeholder>Assigned To</mat-placeholder>
              </mat-form-field>
            </th>
            <td mat-cell *matCellDef="let data">
              <ng-container> {{ data.assignedToName }} </ng-container>
            </td>
          </ng-container>

          <!-- Assigned Date -->
          <ng-container matColumnDef="assignedDate">
            <th
              class="header"
              mat-sort-header
              mat-header-cell
              *matHeaderCellDef
            >
              <mat-form-field class="filter">
                <input
                  autocomplete="new-assignedDate"
                  matInput
                  formControlName="assignedDate"
                  (keydown.space)="$event.stopPropagation()"
                  (click)="$event.stopPropagation()"
                />
                <mat-placeholder>Assigned Date</mat-placeholder>
              </mat-form-field>
            </th>
            <td mat-cell *matCellDef="let data">
              <ng-container>
                {{ data.assignedDate | date : "MM/dd/yyyy h:mm a" : "en_US" }}
              </ng-container>
            </td>
          </ng-container>

          <!-- Sorted Date -->
          <ng-container matColumnDef="sortedDate">
            <th
              class="header"
              mat-sort-header
              mat-header-cell
              *matHeaderCellDef
            >
              <mat-form-field class="filter">
                <input
                  autocomplete="new-sortedDate"
                  matInput
                  formControlName="sortedDate"
                  (keydown.space)="$event.stopPropagation()"
                  (click)="$event.stopPropagation()"
                />
                <mat-placeholder>Sorted Date</mat-placeholder>
              </mat-form-field>
            </th>
            <td mat-cell *matCellDef="let data">
              <ng-container>
                {{ data.sortedDate | date : "MM/dd/yyyy h:mm a" : "en_US" }}
              </ng-container>
            </td>
          </ng-container>

          <!--Status-->
          <ng-container matColumnDef="documentStatus">
            <th
              class="header"
              mat-sort-header
              mat-header-cell
              *matHeaderCellDef
            >
              <mat-form-field class="filter">
                <input
                  autocomplete="new-documentStatus"
                  matInput
                  formControlName="documentStatus"
                  (keydown.space)="$event.stopPropagation()"
                  (click)="$event.stopPropagation()"
                />
                <mat-placeholder>Status</mat-placeholder>
              </mat-form-field>
            </th>
            <td mat-cell *matCellDef="let data">
              <ng-container> {{ data.documentStatus }} </ng-container>
            </td>
          </ng-container>

          <!--No Records-->
          <ng-container matColumnDef="noRecords">
            <td
              class="text-align-center"
              mat-footer-cell
              *matFooterCellDef
              colspan="6"
            >
              <div *ngIf="!isShowSpinner">
                {{ "No Records Found" }}
              </div>
              <div [hidden]="!isShowSpinner">
                <mat-spinner [diameter]="220"></mat-spinner>
              </div>
            </td>
          </ng-container>
          <!--Options-->
          <!-- <ng-container matColumnDef="options">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Options</th>
            <td mat-cell *matCellDef="let data">
              <a class="editBtnColor mr-2" (click)="redirectToManualSorting(data.patientId,data.documentId)">
                <i
                  class="fa fa-pencil pointer"
                  matTooltip="Click To Sort Manually"
                ></i>
              </a>
            </td>
          </ng-container> -->

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr
            mat-row
            [ngStyle]="{ 'background-color': row.colorCode }"
            *matRowDef="let row; columns: displayedColumns"
          ></tr>
        </table>

        <div
          *ngIf="
            !(dataSource && dataSource?.data && dataSource?.data?.length !== 0)
          "
          class="text-align-center"
        >
          <div class="message py-3" *ngIf="!isShowSpinner">
            {{ "No Records Found" }}
          </div>
          <div class="py-3" [hidden]="!isShowSpinner">
            <mat-spinner [diameter]="220"></mat-spinner>
          </div>
        </div>
        <mat-paginator
          [pageSizeOptions]="[10, 20, 50, 100]"
          showFirstLastButtons
        ></mat-paginator>
      </div>
    </form>
  </div>
</div>
</div>
