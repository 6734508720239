<div class="modal-header">
    <h2 class="modal-title customThemeClass">{{isHeaderTitle}}</h2>
    <mat-icon mat-dialog-close>close</mat-icon>
</div>
<!-- <mat-dialog-content class="mat-typography"> -->
<div class="card card-body">
    <form [formGroup]="scheduleForm">
        <div class="row">
            <!-- Title -->
            <mat-form-field class="col-4">
                <mat-label [ngClass]="{ 'is-invalid': scheduleForm?.get('txtTitle')?.invalid }">Title <span
                        class="asterisk">*</span></mat-label>
                <input autocomplete="new-txtTitle" matInput formControlName="txtTitle" type="text" maxlength="50"
                    oninput="this.value = this.value = this.value.charAt(0).toUpperCase() + this.value.slice(1);" />
                <mat-error
                    *ngIf="scheduleForm?.get('txtTitle')?.touched && scheduleForm?.get('txtTitle')?.errors?.required">
                    Title is required field!
                </mat-error>
            </mat-form-field>

            <!--Start Date-->
            <!-- <mat-form-field class="col-4">
                <mat-label [ngClass]="{ 'is-invalid': scheduleForm?.get('txtStartDate')?.invalid }">Start Date <span
                        class="asterisk">*</span></mat-label>
                <input autocomplete="new-txtStartDate" formControlName="txtStartDate" placeholder="(MM/DD/YYYY)"
                    matInput maxlength="40" [formControl]="
                                scheduleForm.controls.txtStartDate
                              " [ngxMatDatetimePicker]="txtStartDate" [min]="isUpdate ? null : minDate" />
                <mat-datepicker-toggle matSuffix [for]="txtStartDate"></mat-datepicker-toggle>
                <ngx-mat-datetime-picker #txtStartDate [showSpinners]="true" [showSeconds]="true" [stepHour]="1"
                    [stepMinute]="1" [stepSecond]="1" [touchUi]="false" [color]="'primary'" [enableMeridian]="true">
                    <ng-template>
                        <span>OK</span>
                    </ng-template>
                </ngx-mat-datetime-picker>

                <mat-error *ngIf="
                      scheduleForm?.get('txtStartDate')?.touched ||
                      scheduleForm?.get('txtStartDate')?.errors?.invalidDate || scheduleForm.get('txtStartDate')?.invalid
                    ">
                    Enter Valid Date!
                </mat-error>
            </mat-form-field> -->

            <!--End Date-->
            <!-- <mat-form-field class="col-2">
                <mat-label [ngClass]="{ 'is-invalid': scheduleForm?.get('txtEndDate')?.invalid }">End Date <span
                        class="asterisk">*</span></mat-label>
                <input autocomplete="new-txtEndDate" formControlName="txtEndDate" placeholder="(MM/DD/YYYY)" matInput
                    maxlength="40" [formControl]="
                                scheduleForm.controls.txtEndDate
                              " [min]="
                                scheduleForm.controls.txtStartDate.value
                              " [ngxMatDatetimePicker]="txtEndDate" />
                <mat-datepicker-toggle matSuffix [for]="txtEndDate"></mat-datepicker-toggle>
                <ngx-mat-datetime-picker #txtEndDate [showSpinners]="true" [showSeconds]="true" [stepHour]="1"
                    [stepMinute]="1" [stepSecond]="1" [touchUi]="false" [color]="'primary'" [enableMeridian]="true">
                    <ng-template>
                        <span>OK</span>
                    </ng-template>
                </ngx-mat-datetime-picker>
                <mat-error class="spanAlignment" *ngIf="
                scheduleForm?.get('txtEndDate')?.touched ||
                (scheduleForm?.get('txtEndDate')?.errors?.invalidDate ||
                (scheduleForm.controls.txtStartDate.value ||
                scheduleForm.controls.txtEndDate.value ||
                scheduleForm.controls.txtStartDate.value >= scheduleForm.controls.txtEndDate.value))
            ">
                    Enter Valid and greater than the start date!
                </mat-error>
            </mat-form-field> -->

            <!--Start Date-->
            <mat-form-field class="col-4">
                <mat-label>Start Date <span class="asterisk">*</span></mat-label>
                <input autocomplete="new-txtStartDate" formControlName="txtStartDate" matInput maxlength="10"
                    type="text" placeholder="(MM/DD/YYYY)" [matDatepicker]="txtStartDate" [min]="isUpdate ? null : minDate" 
                    onkeydown="this.value=this.value.replace(/^(\d\d)(\d)$/g,'$1/$2').replace(/^(\d\d\/\d\d)(\d+)$/g,'$1/$2').replace(/[^\d\/]/g,'')"/>
                <mat-datepicker-toggle matSuffix [for]="txtStartDate"></mat-datepicker-toggle>
                <mat-datepicker #txtStartDate></mat-datepicker>
            </mat-form-field>

             <!--Start Time-->
            <mat-form-field class="col-2">
                <mat-label>Start Time <span class="asterisk">*</span></mat-label>
                <input type="time" matInput formControlName="txtStartTime" class="timeCls"/>
                <mat-error *ngIf="scheduleForm.get('txtStartTime').hasError('required')">Start time is required.</mat-error>
            </mat-form-field>
            
            <!--End Time-->
            <mat-form-field class="col-2">
                <mat-label>End Time <span class="asterisk">*</span></mat-label>
                <input type="time" matInput formControlName="txtEndTime" class="timeCls"/>
                <mat-error *ngIf="scheduleForm.get('txtEndTime').hasError('required')">End time is required.</mat-error>
                <mat-error *ngIf="scheduleForm.get('txtEndTime').hasError('endTimeInvalid')">End time must be greater than start time.</mat-error>
            </mat-form-field>
          
           
            <!--Patient Name Drop Down-->
            <mat-form-field class="col-4">
                <mat-label [ngClass]="{ 'is-invalid': scheduleForm?.get('drpPatientName')?.invalid }">
                    <div class="select-placeholder-container" *ngIf="isPatientLoading">
                        <span>Loading...</span>
                        <mat-spinner class="spinner" diameter="20"></mat-spinner>
                    </div>
                    <span *ngIf="!isPatientLoading">Patient Name <span class="asterisk">*</span></span>
                </mat-label>
                <mat-select formControlName="drpPatientName" #singleSelect
                    (selectionChange)="onSelectionPatientChange($event)" (openedChange)="onDropdownOpenChange($event)">
                    <mat-option>
                        <ngx-mat-select-search [formControl]="PatinetTypeControl" placeholderLabel="Search"
                            noEntriesFoundLabel="No Matches found"
                            (keyup)="searchPatient($event.target.value,singleSelect.value)"
                            formControlName="txtPatinetType"></ngx-mat-select-search>
                    </mat-option>
                    <mat-option *ngFor="let patint of patientDrpData" [value]="patint?.patientId">
                        {{ patint?.ddValue }}
                    </mat-option>
                </mat-select>
                <mat-error class="spanAlignment"
                    *ngIf="isUsertypeTouched && scheduleForm?.get('drpPatientName')?.errors?.required">
                    Patinet is a required field!
                </mat-error>
            </mat-form-field>

            <!--Status Drop Down-->
            <mat-form-field class="col-4">
                <mat-label [ngClass]="{ 'is-invalid': scheduleForm?.get('drpStatus')?.invalid }">Status <span
                        class="asterisk">*</span></mat-label>
                <mat-select formControlName="drpStatus">
                    <mat-option *ngFor="let status of statusDrpData" [value]="status?.calendarStatus">
                        {{ status?.calendarStatus }}
                    </mat-option>
                </mat-select>
                <mat-error class="spanAlignment"
                    *ngIf="isStatusTouched && scheduleForm?.get('drpStatus')?.errors?.required">
                    Status is a required field!
                </mat-error>
            </mat-form-field>

            <!--User Drop Down-->
            <mat-form-field class="col-4">
                <mat-label [ngClass]="{ 'is-invalid': scheduleForm?.get('drpUserName')?.invalid }">
                    <div class="select-placeholder-container" *ngIf="isUserLoading">
                        <span>Loading...</span>
                        <mat-spinner class="spinner" diameter="20"></mat-spinner>
                    </div>
                    <span *ngIf="!isUserLoading">Technician <span class="asterisk">*</span></span>
                </mat-label>
                <mat-select formControlName="drpUserName" (openedChange)="searchTechnician('')"
                    [(ngModel)]="TechnicianNew">
                    <mat-option>
                        <ngx-mat-select-search [formControl]="UserTypeControl" placeholderLabel="Search"
                            noEntriesFoundLabel="No Matches found" (keyup)="searchTechnician($event.target.value)"
                            formControlName="txtUserType"></ngx-mat-select-search>
                    </mat-option>
                    <mat-option *ngFor="let users of userDrpData" [value]="users?.userId">
                        {{ users?.userDesignation }}
                    </mat-option>
                </mat-select>

                <mat-error class="spanAlignment"
                    *ngIf="isUsertypeTouched && scheduleForm?.get('drpUserName')?.errors?.required">
                    Technician is a required field!
                </mat-error>
            </mat-form-field>

            <!--Branch Name Drop Down-->
            <mat-form-field class="col-4">
                <mat-label>Location</mat-label>
                <mat-select formControlName="drpBranchName">
                    <mat-option>
                        <ngx-mat-select-search [formControl]="BranchTypeControl" placeholderLabel="Search"
                            noEntriesFoundLabel="No Matches found"
                            formControlName="txtBranchType"></ngx-mat-select-search>
                    </mat-option>

                    <mat-option *ngFor="let branch of branchDrpData" [value]="branch?.id">
                        {{ branch?.organizationUnitName }}
                    </mat-option>
                </mat-select>

                <mat-error class="spanAlignment"
                    *ngIf="isStatusTouched && scheduleForm?.get('drpBranchName')?.errors?.required">
                    Branch Name is a required field!
                </mat-error>
            </mat-form-field>


            <!--Type Name Drop Down-->
            <mat-form-field class="col-4">
                <mat-label>Type Name </mat-label>
                <mat-select formControlName="drpTypeName">
                    <mat-option>
                        <ngx-mat-select-search [formControl]="TypeNameControl" placeholderLabel="Search"
                            noEntriesFoundLabel="No Matches found"
                            formControlName="txtTypeName"></ngx-mat-select-search>
                    </mat-option>

                    <mat-option *ngFor="let type of typeDrpData" [value]="type"
                        [ngStyle]="{'background-color': type?.colorCode}"
                        [style.color]="updateTextColor(type?.colorCode)">
                        {{ type?.description }}
                    </mat-option>
                </mat-select>

                <mat-error class="spanAlignment"
                    *ngIf="isStatusTouched && scheduleForm?.get('drpTypeName')?.errors?.required">
                    Type Name is a required field!
                </mat-error>
            </mat-form-field>


            <!--Moode of Communication Drop Down-->
            <mat-form-field class="col-4">
                <mat-label [ngClass]="{ 'is-invalid': scheduleForm?.get('drpModeCommunication')?.invalid }">Mode of
                    Communication <span class="asterisk">*</span></mat-label>
                <mat-select formControlName="drpModeCommunication" multiple
                    (selectionChange)="onSelectionUserChange($event)" [(value)]="modeDescription"
                    [(ngModel)]="selectedModeList">
                    <mat-option>
                        <ngx-mat-select-search placeholderLabel="Search" noEntriesFoundLabel="No Matches found"
                            formControlName="txtModeType"></ngx-mat-select-search>
                    </mat-option>

                    <mat-option *ngFor="let comuni of modeDrpData | async" [value]="comuni?.mode"
                        [disabled]="isOptionDisabled(comuni.mode) || comuni.mode === 3">
                        {{ comuni?.modeDescription }}
                    </mat-option>

                </mat-select>
                <mat-error
                    *ngIf="scheduleForm?.get('drpModeCommunication')?.touched && scheduleForm?.get('drpModeCommunication')?.errors?.required">
                    Mode of Communication is required field!
                </mat-error>
            </mat-form-field>

            <!-- Home No-->
            <mat-form-field class="col-4">
                <mat-label>Home No </mat-label>
                <input matInput maxlength="40" formControlName="txtHomeNo" type="text" mask="(000)-000-0000"
                    prefix="+1-" />
                <mat-error *ngIf="
                                scheduleForm?.get('txtHomeNo')?.touched &&
                                scheduleForm?.get('txtHomeNo')?.errors
                                    ?.required
                                ">
                    Home Number is a required field!
                </mat-error>
                <mat-error *ngIf="
                                scheduleForm?.get('txtHomeNo')?.touched &&
                                scheduleForm?.get('txtHomeNo')?.errors
                                    ?.sAlphabets
                                ">
                    Enter only Numbers!
                </mat-error>
            </mat-form-field>

            <!-- Work No-->
            <mat-form-field class="col-4">
                <mat-label>Work No </mat-label>
                <input matInput maxlength="40" formControlName="txtWorkNo" type="text" mask="(000)-000-0000"
                    prefix="+1-" />
                <mat-error
                    *ngIf="scheduleForm?.get('txtWorkNo')?.touched && scheduleForm?.get('txtWorkNo')?.hasError('required')">
                    Work Number is a required field!
                </mat-error>
            </mat-form-field>

            <!--Resource Drop Down-->
            <mat-form-field class="col-4">
                <mat-label>Resource </mat-label>
                <mat-select formControlName="drpResource">

                    <mat-option>
                        <ngx-mat-select-search [formControl]="ResourceTypeControl" placeholderLabel="Search"
                            noEntriesFoundLabel="No Matches found"
                            formControlName="txtResourceName"></ngx-mat-select-search>
                    </mat-option>

                    <mat-option *ngFor="let resu of resourceDet" [value]="resu?.resid">
                        {{ resu?.resname }}
                    </mat-option>
                </mat-select>

                <mat-error class="spanAlignment"
                    *ngIf="isStatusTouched && scheduleForm?.get('drpResource')?.errors?.required">
                    Resource is a required field!
                </mat-error>
            </mat-form-field>


            <!--Appointment Note Field-->
            <mat-form-field class="col-8">
                <mat-label>Appointment Note</mat-label>
                <textarea matInput rows="4" autocomplete="off" maxlength="5000" formControlName="txtDescription"
                    type="text" style="resize: none;"></textarea>
            </mat-form-field>


            <!--Setup Home-->
            <mat-checkbox class="mt-5 col-4" formControlName="setupHome"><span class="mr-5">Setup
                    Home</span></mat-checkbox>

            <!--Template Drop Down-->
            <!-- <mat-form-field class="col-6">
                    <mat-label [ngClass]="{ 'is-invalid': scheduleForm?.get('drpTemplate')?.invalid }">Template <span class="asterisk" *ngIf="isTemplateRequired">*</span></mat-label>
                    <mat-select formControlName="drpTemplate">

                        <mat-option>
                            <ngx-mat-select-search [formControl]="TemplateTypeControl" placeholderLabel="Search"
                                noEntriesFoundLabel="No Matches found"
                                formControlName="txtTemplateName"></ngx-mat-select-search>
                        </mat-option>

                        <mat-option *ngFor="let temp of templateDrpData" [value]="temp">
                            {{ temp?.templateCode }}
                        </mat-option>
                    </mat-select>

                    <mat-error class="spanAlignment"
                        *ngIf="isStatusTouched && scheduleForm?.get('drpTemplate')?.errors?.required">
                        Template is a required field!
                    </mat-error>
                </mat-form-field> -->

            <!-- Cell No-->
            <!-- <mat-form-field class="col-4">
                    <mat-label [ngClass]="{ 'is-invalid': scheduleForm?.get('txtCellNo')?.invalid }">Cell No <span class="asterisk cell-asterisk"
                            *ngIf="isCellNoRequired">*</span></mat-label>
                    <input matInput maxlength="40" formControlName="txtCellNo" type="text" mask="(000)-000-0000"
                        prefix="+1-" />
                    <mat-error *ngIf="
                                    scheduleForm?.get('txtCellNo')?.touched &&
                                    scheduleForm?.get('txtCellNo')?.errors
                                        ?.required
                                    ">
                        Cell Number is a required field!
                    </mat-error>
                    <mat-error *ngIf="
                                    scheduleForm?.get('txtCellNo')?.touched &&
                                    scheduleForm?.get('txtCellNo')?.errors
                                        ?.sAlphabets
                                    ">
                        Enter only Numbers!
                    </mat-error>
                </mat-form-field> -->

            <!-- Email -->
            <!-- <mat-form-field class="col-4">
                        <mat-label [ngClass]="{ 'is-invalid': scheduleForm?.get('txtEmail')?.invalid }">Email <span class="asterisk email-asterisk" *ngIf="isEmailRequired">*</span></mat-label>
                        <input autocomplete="new-txtEmail" matInput maxlength="40" formControlName="txtEmail" type="text"
                            pattern="[a-z0-9._!#$%&'()*+,-./:;<=>?@[\]^_`{|}~]+@[a-z0-9.-]+\.[a-z]{2,4}$"
                            [textMask]="{ mask: emailMask }" />
                        <mat-error
                            *ngIf="scheduleForm?.get('txtEmail')?.touched && scheduleForm?.get('txtEmail')?.hasError('required')">
                            Email is a required field!
                        </mat-error>
                        <mat-error
                            *ngIf="scheduleForm?.get('txtEmail')?.touched && scheduleForm?.get('txtEmail')?.errors?.pattern">
                            This is not a valid Email!
                        </mat-error>
                    </mat-form-field> -->
        </div>
    </form>
</div>
<!-- </mat-dialog-content> -->

<mat-dialog-actions>
    <div class="col-5 text-left mt-1" *ngIf="isDelete">
        <button mat-button class="deleteClr mr-2" (click)="deleteSchedule()">
            Delete
        </button>
    </div>
    <div class="mt-1" [ngClass]="{ 'col-7 text-right': isDelete, 'col-12 text-center': !isDelete }">
        <button mat-button class="buttonColor mr-2"
            [disabled]="isShowSpinner || !(!scheduleForm?.invalid && scheduleForm?.dirty)" (click)="saveSchedule()">
            <span>{{isSave}}</span>
            <mat-icon *ngIf="isShowSpinner">
                <mat-spinner class="spinner-border spinner-border-sm" diameter="20"></mat-spinner>
            </mat-icon>
        </button>
        <button mat-button class="resetclr mr-2" (click)="resetSchedule()">
            Reset
        </button>
    </div>
</mat-dialog-actions>