import { PagedAndSortedResultRequestDto } from '@abp/ng.core';
import { DatePipe } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatAccordion } from '@angular/material/expansion';
import { MatPaginator } from '@angular/material/paginator';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { MatSort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { Status } from 'projects/inventory/src/app/item-proxy/item-management/items/status.enum';
import { DateValidator } from 'projects/shared/src/app/validations/date-validator';
import { delay } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { ToastrService } from "ngx-toastr";
import { AppointmentTypeService } from '../onTime-proxy/rcm/on-time-management/on-time-schedule/appointment-type.service';
import { CreateUpdateAppointmentTypeDTO, SearchAppointmentTypeDTO } from '../onTime-proxy/rcm/on-time-management/on-time-schedule/dto/models';
import { Title } from '@angular/platform-browser';


@Component({
  selector: 'app-appointment-type',
  templateUrl: './appointment-type.component.html',
  styleUrls: ['./appointment-type.component.scss'],
  providers: [DatePipe]

})
export class AppointmentTypeComponent implements OnInit {
  AppontmentForm: FormGroup;
  appointmentSearchForm: FormGroup;
  @ViewChild('firstAccordion') firstAccordion: MatAccordion;
  appointTypeId: string = "";
  panelOpenState = false;
  step: boolean = false;
  isShowActive: boolean = false;
  public strPageType: string = "appointmentType";
  displayedColumns: string[] = [  'Appointment Type', 'Duration', 'Date', 'Status','Options',];
  dataSource: MatTableDataSource<any> = new MatTableDataSource<any>([]);
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatTable, { static: false }) tableRef: MatTable<any>;
  isLoading: boolean;
  appointmentTableData: any[] = [];
  status: string = '';
  durationShow: boolean = false;
  isShownSaveButton: boolean = false;

  constructor(
    private formBuilder: FormBuilder,
    private appointmentService: AppointmentTypeService,
    private datepipe: DatePipe,
    private toastr: ToastrService,
    public title: Title,
    private dateValidator: DateValidator,) { }

  ngOnInit(): void {
    this.title.setTitle("Qsecure | Appointment ");
    this.AppontmentForm = this.formBuilder.group({
      txtAppointType: new FormControl("", Validators.required),
      txtDuration: new FormControl("", Validators.required),
      chkActive: new FormControl(""),
    }),
      this.appointmentSearchForm = this.formBuilder.group({
        appointmentTypeSearch: new FormControl(""),
        durationSearch: new FormControl(""),
        statusSearch: new FormControl(""),
        Date: new FormControl("", this.dateValidator.dateVaidator),

      })
    // valueChanges for filter
    this.appointmentSearchForm.valueChanges.subscribe((value) => {
      const filter = {
        appointmentTypeSearch: value?.appointmentTypeSearch?.trim()?.toLowerCase(),
        durationSearch: value?.durationSearch?.trim()?.toLowerCase(),
        statusSearch: value?.statusSearch?.trim()?.toLowerCase(),
        Date: value?.Date?.trim()?.toLowerCase(),
      };
      if (this.appointmentSearchForm.valid) {
        this.dataSource.filter = JSON.stringify(filter);
        this.filtertable(filter);
      }
    }, err => {
    });
    this.getTableData();
  }

  // ! save Functionality
  saveAppointment() {
    this.isShownSaveButton= true;
    const appointment: CreateUpdateAppointmentTypeDTO = {
      appointmentType: this.AppontmentForm.getRawValue().txtAppointType,
      duration: this.AppontmentForm.getRawValue().txtDuration,
      status: this.AppontmentForm.value.chkActive === true || this.AppontmentForm.value.chkActive === 1 ? "Active" : "InActive",
    };
    if ((this.appointTypeId == null || this.appointTypeId == "" || this.appointTypeId == undefined)) {
      this.appointmentService.create(appointment).subscribe(response => {
        // Swal.fire({ title: 'Success', html: 'Saved Successfully', icon: 'success', timer: 3000, timerProgressBar: true }).then(result => {
        // });
        this.toastr.success('Saved Successfully','Success')
        this.isShownSaveButton= false;
        this.reset();
        this.getTableData()
      }, err => {
        this.isShownSaveButton= false;
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      });
    }
    else {
      this.appointmentService.update(this.appointTypeId, appointment).subscribe(response => {
        // Swal.fire({ title: 'Success', html: 'Saved Successfully', icon: 'success', timer: 3000, timerProgressBar: true }).then(result => {
        // });
        this.toastr.success('Saved Successfully','Success')
        this.isShownSaveButton= false;
        this.reset();
        this.getTableData()

      }, err => {
        this.isShownSaveButton= false;
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      });
    }
  }

  /// load table list
  getTableData() {
    this.isLoading = true

    this.appointmentService.getList(new PagedAndSortedResultRequestDto).subscribe(response => {
      this.appointmentTableData = [];
      response && response?.items?.forEach(element => {
        this.appointmentTableData.push({
          Type: element?.appointmentType,
          Duration: element?.duration,
          status: element?.status,
          data: element?.id,
          createDate: element?.creationTime === null ? "" : this.datepipe.transform(element?.creationTime, 'MM/dd/yyyy') ?? ""
        });
      })
      const arrTableData = this.appointmentTableData;
      this.dataSource = new MatTableDataSource(arrTableData);
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
      this.isLoading = false;
    }, err => {
      this.isLoading = false;
      const data: HttpErrorResponse = err;
      // Swal.fire({
      //   icon: 'error',
      //   text: data?.error?.error?.message,
      // });
    })
  }

  // ! Search table data
  filtertable(filter) {
    const Date = filter.Date;
    // let DateFormat = Date === null ? "" : Intl.DateTimeFormat('en-US').format(new Date(Date));
    const searchInput: SearchAppointmentTypeDTO = {
      appointmentType: filter.appointmentTypeSearch,
      duration: filter.durationSearch,
      status: filter.statusSearch,
      createdDate: dateYYYYMMDDtoMMDDYYYY(Date)
    }
    this.appointmentTableData = [];
    this.appointmentService.searchAppointmentTypesByInput(searchInput).subscribe(data => {
      this.appointmentTableData = [];
      data?.items.forEach(element => {
        this.appointmentTableData.push({
          Type: element.appointmentType,
          Duration: element?.duration,
          stats: element?.status,
          data: element?.id,
          createDate: element?.creationTime === null ? "" : this.datepipe.transform(element?.creationTime, 'MM/dd/yyyy') ?? ""
        });
      })
      this.dataSource = new MatTableDataSource(this.appointmentTableData);
      this.dataSource.sort = this.sort;
      setTimeout(() => this.dataSource.paginator = this.paginator);
    }, err => {
      this.dataSource = new MatTableDataSource(this.appointmentTableData);
      this.dataSource.sort = this.sort;
      setTimeout(() => this.dataSource.paginator = this.paginator);
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    });
  }

  // ! edit appointment
  ViewAppointment(data) {
    this.isShowActive = true;
    this.step = !this.step;
    this.appointTypeId = data.data;
    this.appointmentService.get(this.appointTypeId).subscribe(response => {
      this.AppontmentForm.patchValue({
        txtAppointType: response.appointmentType,
        txtDuration: response.duration,
        chkActive: response.status == "Active" ? 1 : 0
      })
      this.appointTypeId = response.id;
      this.status = response.status;
      this.status === Status.Active && this.AppontmentForm.controls.txtAppointType.enable();
      this.status === Status.Inactive && this.AppontmentForm.controls.txtAppointType.disable();
      if (this.status === Status.Inactive) {
        this.durationShow = true;
      } else {
        this.durationShow = false;
      }
    })
  }

  /// delete appoinmrnt
  deleteAppointment(data) {
    Swal.fire({
      title: 'Are you sure you want to Delete?',
      text: 'You won\'t be able to retrieve this data!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#34c38f',
      cancelButtonColor: '#f46a6a',
      confirmButtonText: 'Yes, delete it!'
    }).then(result => {
      if (result.value) {
        this.appointmentService.delete(data.data).subscribe(response => {
          this.appointmentTableData.forEach(element => {
            if (data.id == element?.id) {
              const index = this.appointmentTableData.indexOf(element, 0)
              this.appointmentTableData.splice(index, 1)
            }
          });
          this.getTableData();
        }, err => {
          const data: HttpErrorResponse = err;

          Swal.fire({
            icon: 'info',
            text: data?.error?.error?.message,
          });
        });
      }
    });
    this.reset();
  }

  // Product Category Status change
  statusChange(event: any) {
    this.status = event.checked == true ? Status.Active : Status.Inactive;

    this.status === Status.Active && this.AppontmentForm.controls.txtAppointType.enable();
    this.status === Status.Inactive && this.AppontmentForm.controls.txtAppointType.disable();
    if (this.status === Status.Inactive) {
      this.durationShow = true;
    } else {
      this.durationShow = false;
    }
  }

  // ! reset appointmentForm
  reset() {
    this.isShowActive = false;
    this.AppontmentForm.patchValue({
      txtAppointType: "",
      txtDuration: "",
      chkActive: "",
    })
    this.AppontmentForm.reset();
    this.AppontmentForm.markAsUntouched();
    this.AppontmentForm.updateValueAndValidity();
    this.AppontmentForm.enable();
    this.appointTypeId = "";
  }

  appointmentStatusChange(event: MatSlideToggleChange, data: any) {
    this.status = event.checked == true ? "Active" : "Inactive";

    if (event.checked == true) {
      Swal.fire({
        title: 'Do you want to activate?',
        text: 'By Changing Status, this Appointment will be activated',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#34c38f',
        cancelButtonColor: '#f46a6a',
        confirmButtonText: 'Yes, Activate it!'
      }).then(result => {
        if (result.value) {
          const input: CreateUpdateAppointmentTypeDTO = {
            appointmentType: data?.Type,
            duration: data?.Duration,
            status: "Active",
          }
          if (data?.data && data?.data !== "" && data?.data !== null && data?.data !== undefined) {
            this.appointmentService.update(data?.data, input).subscribe(response => {
              // Swal.fire({ title: 'Success', html: 'Activated Successfully', icon: 'success', timer: 3000, timerProgressBar: true })
              this.toastr.success('Activated Successfully','Success')
              this.getTableData();
            }, (err) => {
              const data: HttpErrorResponse = err;
              Swal.fire({
                icon: 'info',
                text: data?.error?.error?.message,
              });
            })
          }
        }
        else {
          this.getTableData();
        }
      });
    }
    else
      if (event.checked == false) {
        Swal.fire({
          title: 'Do you want to deactivate?',
          text: 'By Changing Status, this Appointment Type will be deactivated',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#34c38f',
          cancelButtonColor: '#f46a6a',
          confirmButtonText: 'Yes, deactivate it!'
        }).then(result => {
          if (result.value) {
            const input: CreateUpdateAppointmentTypeDTO = {
              appointmentType: data?.Type,
              duration: data?.Duration,
              status: "Inactive",
            }
            if (data?.data && data?.data !== "" && data?.data !== null && data?.data !== undefined) {
              this.appointmentService.update(data?.data, input).subscribe(response => {
                // Swal.fire({ title: 'Success', html: 'Deactivateed Successfully', icon: 'success', timer: 3000, timerProgressBar: true })
                this.toastr.success('Deactivateed Successfully','Success')
                this.getTableData();
              }, (err) => {
                const data: HttpErrorResponse = err;
                Swal.fire({
                  icon: 'info',
                  text: data?.error?.error?.message,
                });
              })
            }
          }
          else {
            this.getTableData();
          }
        });
      }
  }
}


function dateYYYYMMDDtoMMDDYYYY(strDate: string): string {
  if (strDate && strDate !== "" && typeof strDate === 'string' && strDate !== null && strDate !== undefined && strDate.length >= 1 && !isNaN(new Date(strDate).getTime())) {
    const latest_date = Intl.DateTimeFormat('en-US')?.format(new Date(strDate))
    return latest_date;
  }
  return "";
}
