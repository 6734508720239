import { Component, OnInit, ViewChild } from '@angular/core';
import { ItemTabComponent } from '../item-tab/item-tab.component';
import { Subscription } from 'rxjs';
import Swal from 'sweetalert2';
import { HttpErrorResponse } from '@angular/common/http';
import { SaleOrderItemService } from '../order-proxy/order-management/order-optimization';
import { ActivatedRoute, Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { MatDialog } from '@angular/material/dialog';
import { AddItemTabComponent } from '../add-item-tab/add-item-tab.component';
// import { defaultGuid } from 'projects/admin/src/app/document-perviewer/document-perviewer.component';
import { PickupExchangeOptionsTabComponent } from '../pickup-exchange-options-tab/pickup-exchange-options-tab.component';
import {
  ReturnAllItemsInputDTO,
  SaleOrderItemDTO,
} from '../order-proxy/order-management/order-optimization/dto';
import { PickUpExchangeService } from '../order-proxy/order-management/order-optimization/pick-up-exchange.service';
import { defaultGuid } from 'projects/shared/src/app/enums/allenums.enum';

@Component({
  selector: 'app-add-pickup-exchange-item-tab',
  templateUrl: './add-pickup-exchange-item-tab.component.html',
  styleUrls: ['./add-pickup-exchange-item-tab.component.scss'],
})
export class AddPickupExchangeItemTabComponent implements OnInit {
  dataSource: any;
  tblItemList: SaleOrderItemDTO[] = [];
  isLoading: boolean = false;
  arrDisplayedColumns: any;
  totalQty: any;
  orderId: string;
  patientId: string;
  subscription$: Subscription[] = [];
  @ViewChild(ItemTabComponent) OrderItemComponent: ItemTabComponent;
  totalPrice: number;
  constructor(
    private itemService: SaleOrderItemService,
    private activatedRoute: ActivatedRoute,
    private PickupExchangeService: PickUpExchangeService,
    private router: Router,
    private dialog: MatDialog,
    private cookieService: CookieService
  ) {}

  ngOnInit(): void {
    this.activatedRoute.paramMap.subscribe((response) => {
      this.orderId = response.get('orderId') ?? '';
      this.patientId = response.get('patientId') ?? '';
    });
    this.getItemList();
  }
  ngOnDestroy(): void {
    this.subscription$?.forEach((sub) => {
      sub && sub?.unsubscribe();
    });
  }
  getItemList() {
    this.tblItemList = [];
    const saleOrderGet = this.itemService
      .getItemsListByOrderIdByOrderId(this.orderId)
      .subscribe(
        (response) => {

          this.totalQty = +String(
            response?.reduce((prvsValue, curValue) => {
              return prvsValue + +curValue?.orderingQuantity;
            }, 0) ?? 0
          );
          this.isLoading = false;
          this.tblItemList = response?.map((x) => {
            let y = {
              totalPrice:
                (+x?.charge ?? 0 ?? 0) * (+x?.orderingQuantity ?? 0 ?? 0),
              ...x,
            };
            return y;
          });
          this.totalPrice =
            +String(
              this.tblItemList?.reduce((prvsValue, curValue) => {
                return prvsValue + +(curValue?.['totalPrice'] ?? 0);
              }, 0) ?? 0
            ) ?? 0;
        },
        (err) => {
          this.isLoading = false;
          const data: HttpErrorResponse = err;
          Swal.fire({
            icon: 'info',
            text: data?.error?.error?.message,
          });
        }
      );
    this.subscription$.push(saleOrderGet);
  }

  //Open Add Item Popup Modal
  openNewItemDialog(itemId: string = defaultGuid) {

    const dialogRef = this.dialog.open(AddItemTabComponent, {
      disableClose: true,
      data: {
        patientId: this.patientId,
        orderId: this.orderId,
        itemId: itemId,
        isTemplate: false,
        isExchange: true,
      },
    });
  }

  //Open the Exchange Options Popup Modal
  openPickupExchangeOptionsTab(itemId: string = defaultGuid) {

    const dialogRef = this.dialog.open(PickupExchangeOptionsTabComponent, {
      disableClose: true,
      data: {
        itemId: itemId,
        patientId: this.patientId,
        orderId: this.orderId,
        itemDetails: null,
        isExchange: false,
      },
    });
  }

  //Redirect to Order
  redirectToOrder() {
    this.cookieService.set('orderStatus', 'true');
    this.router.navigate([
      'orders/saleorderCreateEditTab',
      this.patientId,
      this.orderId,
    ]);
  }

  //Return Total Items
  returnTotalItems() {
    const dialogRef = this.dialog.open(PickupExchangeOptionsTabComponent, {
      disableClose: true,
      data: {
        itemId: defaultGuid,
        patientId: this.patientId,
        orderId: this.orderId,
        itemDetails: null,
        isExchange: false,
        isReturnAllItems:true
      },
    });
  }
}
