import { DatePipe } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Title } from '@angular/platform-browser';
import { DateValidator } from 'projects/shared/src/app/validations/date-validator';
import { Observable, Subscription } from 'rxjs';
import {
  debounceTime,
  filter,
  finalize,
  map,
  startWith,
  switchMap,
  tap,
} from 'rxjs/operators';
import Swal from 'sweetalert2';
import { searchItemPaymentsInput } from '../claim-proxy/claim-processing-management/rcm/billing-management/posting/dto/models';
import { ClaimInfoDetailsService } from '../claim-proxy/claim-processing-management/rcm/claim-processing-management/claim-optimization';
import { CheckListDTO } from '../claim-proxy/claim-processing-management/rcm/claim-processing-management/claim-optimization/dto';
import { ItemPaymentService } from '../claim-proxy/claim-processing-management/rcm/claim-processing-management/x12/item-payment.service';
import { regexPattern } from '../claim-status/claim-status.component';
import { MatOption } from '@angular/material/core';
import { MatSelect } from '@angular/material/select';
import { OrganizationUnitService } from 'projects/admin/src/app/admin-proxy/platform-app-management/rcm/platform-management/organization-units/organization-unit.service';

@Component({
  selector: 'app-claim-check-list',
  templateUrl: './claim-check-list.component.html',
  styleUrls: ['./claim-check-list.component.scss']
})
export class ClaimCheckListComponent implements OnInit ,OnDestroy{

  claimTableData: chequeList[] = [];
  dataSource: MatTableDataSource<any> = new MatTableDataSource<any>([]);
  @ViewChild(MatSort, { static: false }) sort: MatSort = new MatSort();
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  public arrDisplayedColumns: string[] = [
    'checkNumber',
    'checkAmount',
    'defaultClaimId',
    'defaultPatientId',
    'patientName',
    'branch',
    'clearingHouse',
    'postingDate',
    'billed',
    'currentBalance',
    'checkDate',
  ];
  isLoading: boolean = false;
  subscription$: Subscription[] = [];
  chequeForm: FormGroup;
  @ViewChild('selectBranch') selectBranch: MatSelect;
  @ViewChild('selectAllBranch') private selectAllBranch: MatOption;
  branchValue : string[] =[];
  public brnachList: Observable<Branch[]> | undefined;
  public drpBranch: Branch[] = [];
  tenantId: string = "";
  branchForm: FormGroup;
  organizationUnitName: string;
  constructor(
    private formbuilder: FormBuilder,
    private titleService: Title,
    private itemPaymentService: ItemPaymentService,
    private dateValidator: DateValidator,
    private claimInfoService: ClaimInfoDetailsService,
    private branchService : OrganizationUnitService
  ) {}
  ngOnInit(): void {
    this.tenantId = localStorage.getItem("tenantId") ?? "";
    this.titleService.setTitle('Qsecure | Cheque List');
    this.chequeForm = this.formbuilder.group({
      chequeNumber: new FormControl(''),
      defaultClaimId: new FormControl(''),
      checkAmount: new FormControl(''),
      defaultPatientId: new FormControl(''),
      postingDate: new FormControl('', this.dateValidator?.dateVaidator),
      patientName: new FormControl(''),
      billedAmount: new FormControl(''),
      currentBalance: new FormControl(''),
      checkDate: new FormControl('', this.dateValidator?.dateVaidator),
    });

    //! Search cheque list by form values
    this.chequeForm.valueChanges
      .pipe(
        startWith({
          chequeNumber: '',
          defaultClaimId: '',
          checkAmount: '',
          defaultPatientId: '',
          postingDate: '',
          patientName: '',
          billedAmount: '',
          currentBalance: '',
          checkDate: '',
        }),
        tap((x) => {
          this.isLoading = true;
          this.setTableData([]);
        }),
        filter((e) => e && this.chequeForm?.valid && this.chequeForm?.valid),
        debounceTime(300),
        map((response) => {
          const billedAmount =
            String(response?.billedAmount)?.replace(regexPattern, '') ?? '';
          const currentBalance =
            String(response?.currentBalance)?.replace(regexPattern, '') ?? '';
          const oSearchClaim: CheckListDTO = {
            checkNumber:
              String(response?.chequeNumber)?.trim()?.toLowerCase() ?? '',
            checkAmount:
              String(response?.checkAmount)?.trim()?.toLowerCase() ?? '',
            checkDate: String(response?.checkDate)?.trim()?.toLowerCase() ?? '',
            defaultClaimId:
              String(response?.defaultClaimId)?.trim()?.toLowerCase() ?? '',
            defaultPatientId: String(response?.defaultPatientId)?.trim()?.toLowerCase() ?? '',
            patientName:
              String(response?.patientName)?.trim()?.toLowerCase() ?? '',
            billedAmount: String(billedAmount?.trim()?.toLowerCase()) ?? '',
            currentBalance: String(currentBalance)?.trim()?.toLowerCase() ?? '',
            postingDate:
              String(response?.postingDate)?.trim()?.toLowerCase() ?? '',
          };
          return oSearchClaim;
        }),
        switchMap((value: CheckListDTO) =>
          this.claimInfoService.searchCheckListsBasedOnInputByCheckNumberAndCheckAmountAndClaimNumberAndDefaultPatientNumberAndPatientNameAndPostingDateAndBilledAmountAndCurrentBalanceAndCheckDate(
            value.checkNumber,
            value.checkAmount,
            value.defaultClaimId,
            value.defaultPatientId,
            value.patientName,
            value.postingDate,
            value.billedAmount,
            value.currentBalance,
            value.checkDate
          )
        ),
        finalize(() => {
          this.isLoading = false;
        }),
        tap((x) => {
          this.isLoading = false;
        })
      )
      .subscribe(
        (response) => {
          this.setTableData(response ?? []);
          this.isLoading = false;
        },
        (err: HttpErrorResponse) => {
          this.isLoading = false;
          this.setTableData([]);
          const data: HttpErrorResponse = err;
          Swal.fire({ icon: 'info', text: data?.error?.error?.message });
        }
      );

      this.branchForm = this.formbuilder.group({
        drpBranch : new FormControl(''),
        txtBranch : new FormControl(''),
      });
  
      this.getBranchList();
  }


  setTableData(claims: any[]) {
    this.claimTableData = claims?.map((x) => {
      x.postingDate = dateFormatter(String(x?.postingDate ?? '')?.trim()) ?? ''; //(x?.postingDate);
      x.checkDate = dateFormatter(String(x?.checkDate ?? '')?.trim()) ?? ''; //(x?.postingDate);
      return x;
    });
    this.dataSource = new MatTableDataSource(this.claimTableData ?? []);
    this.sort.disableClear = true;
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }
  ngOnDestroy(): void {
    this.subscription$?.forEach((sub) => {
      sub && sub?.unsubscribe();
    });
  }

  allBranchSelect(){
    if(this.selectAllBranch.selected){
      this.selectBranch.options.forEach((item: MatOption) => item.select());
    }
    else{
      this.selectBranch.options.forEach((item: MatOption) => item.deselect());
    }
  }

  getBranchList(){
    const getBranchList = this.branchService.getBranchList(this.tenantId).subscribe(response => {
      response?.forEach(element => {
        this.drpBranch.push({
          id: element.id,
          organizationUnitName: element.organizationUnitName,
        })
      });

      this.brnachList = this.branchForm
        ?.get('txtBranch')
        ?.valueChanges?.pipe(
          startWith(''),
          map((value) => 
            this.drpBranch?.filter((option) =>
              option?.organizationUnitName
                ?.toLowerCase()
                ?.includes(value?.toLowerCase() ?? '')
            )
          )
        );


    }, err =>{
      const data : HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    });
    this.subscription$.push(getBranchList);
  }
}

export interface chequeList {
  chequeNumber: string;
  claimNumber: string;
  checkAmount: string;
  patientId: string;
  claimPostedDate: string;
  patientName: string;
  billedAmount: string;
  currentBalance: string;
  claimServiceDate: string;
  claimId: string;
  defaultPatientId: string;
}
function dateFormatter(documentDate: string | Date): string {
  let resulteddocumentDate: string = '';
  if (typeof documentDate === 'string' && documentDate !== '') {
    const date = `${new Date(documentDate)?.toJSON()?.slice(0, 10)}`??"";
    resulteddocumentDate = date;
  }
  return resulteddocumentDate;
}

export interface Branch{
  id?: string;
  organizationUnitName?: string;
}