<div class="container-fluid">
  <div class="row bg">
    <div class="col-12">
      <div class="d-flex align-item-center justify-content-between m-0">
        <h4 class="mt-2 mb-0 font-size-18"><b>Shipping Ticket View -</b> <b style="color: lightseagreen;">
            {{ticketId}} </b></h4>
        <div class=" page-title-box  page-title-right mt-1 mb-0">
          <ol class="breadcrumbs m-0">
            <li class="blinking ml-2 mr-2 breadcrumb-item">{{currentOrderStatus}}</li>
            <li class="breadcrumb-item">Shipping Ticket List</li>
            <li class="breadcrumb-item"></li>
          </ol>
        </div>
      </div>
    </div>
    <div class="card card-body  ml-3 mr-3">
      <div class="col-lg-12">
        <form [formGroup]="shippingViewForm">

          <div class="row">
            <mat-form-field class="col-3">
              <mat-label>Order Id </mat-label>
              <input autocomplete="new-txtOrderId" readonly matInput maxlength="40" formControlName="txtOrderId" type="text" />
            </mat-form-field>


            <mat-form-field class="col-3">
              <mat-label>Patient Name </mat-label>
              <input autocomplete="new-txtPatientName" readonly matInput maxlength="40" formControlName="txtPatientName" type="text" />
            </mat-form-field>

            <mat-form-field class="col-3">
              <mat-label>Patient Id </mat-label>
              <input autocomplete="new-txtId" readonly formControlName="txtId" matInput type="text" />
            </mat-form-field>
            <mat-form-field class="col-3">
              <mat-label>Patient Phone Number </mat-label>
              <input autocomplete="new-txtPatientNo" readonly matInput maxlength="40" formControlName="txtPatientNo" type="text" />
            </mat-form-field>
            <mat-form-field class="col-3">
              <mat-label>CSR Name</mat-label>
              <input autocomplete="new-txtCsrName" readonly matInput maxlength="40" formControlName="txtCsrName" type="text" />
            </mat-form-field>

            <mat-form-field class="col-3">
              <mat-label>CSR Phone No</mat-label>
              <input autocomplete="new-txtCsrNo" readonly matInput maxlength="40" formControlName="txtCsrNo" type="text" />
            </mat-form-field>
            <mat-form-field class="col-3">
              <mat-label>Units</mat-label>
              <input autocomplete="new-txtQty" readonly matInput formControlName="txtQty" type="text" />
            </mat-form-field>
            <mat-form-field class="col-3">
              <mat-label>Ticket Id</mat-label>
              <input autocomplete="new-txtTicketId" readonly matInput maxlength="40" formControlName="txtTicketId" type="text" />
            </mat-form-field>

            <mat-form-field class="col-3">
              <mat-label>Ticket Created Date</mat-label>
              <input autocomplete="new-txtTDate" readonly formControlName="txtTDate" matInput type="text" />
            </mat-form-field>

            <mat-form-field class="col-3">
              <mat-label>Patient Address</mat-label>
              <textarea autocomplete="new-txtLocation" readonly formControlName="txtLocation" maxlength="400" rows="2" matInput></textarea>
              <!-- <textarea readonly formControlName="txtLocation" matInput type="text" /> </mat-form-field> -->
            </mat-form-field>


            <!-- ready to pack view -->
            <mat-form-field *ngIf="packView" class="col-3">
              <mat-label>Product Weight <span class="asterisk">*</span></mat-label>
              <input autocomplete="new-txtWeight" formControlName="txtWeight" placeholder="Weight In KG" matInput type="text" />
              <!-- <span matSuffix>KG</span> -->
              <mat-error *ngIf="
                            shippingViewForm?.get('txtWeight')?.touched &&
                            shippingViewForm?.get('txtWeight')?.errors?.required
                          ">
                Weight is a required field!
              </mat-error>

              <mat-error *ngIf="
                      shippingViewForm?.get('txtWeight')?.touched &&
                      shippingViewForm?.get('txtWeight')?.errors
                    ">
                Enter Minimun 1 KG and Maximum 68 KG
              </mat-error>
            </mat-form-field>

            <mat-form-field *ngIf="packView" class="col-3">
              <mat-label>Delivery Type <span class="asterisk">*</span> </mat-label>
              <mat-select maxlength="40" (selectionChange)="onEndsChanges($event.value)" formControlName="drpDelType"
                [(value)]="type">
                <mat-option *ngFor="let type of delType" [value]="type.modeOfDelivery">
                  {{type.modeOfDelivery}}
                </mat-option>
              </mat-select>
              <button mat-button (click)="type=undefined;$event.stopPropagation()" (click)="cleartype()" *ngIf="type"
                matSuffix mat-icon-button>
                <mat-icon>close</mat-icon>
              </button>
              <mat-error *ngIf="
                            shippingViewForm?.get('drpDelType')?.touched &&
                            shippingViewForm?.get('drpDelType')?.errors?.required
                          ">
                Delivery Type is a required field!
              </mat-error>
            </mat-form-field>

            <mat-form-field *ngIf="packView" class="col-3">
              <!-- <mat-label>Courier</mat-label>
                    <input readonly   formControlName="drpCourier" matInput type="text" /> -->
              <mat-label>Services <span class="asterisk">*</span></mat-label>
              <mat-select maxlength="40" formControlName="drpCourier" [(value)]="courier">
                <mat-option *ngFor="let courier of drpCourier" [value]="courier.invLocationType">
                  {{courier.invLocationType}}
                </mat-option>
              </mat-select>
              <button mat-button (click)="courier=undefined;$event.stopPropagation()" (click)="clearcourier()"
                *ngIf="courier" matSuffix mat-icon-button>
                <mat-icon>close</mat-icon>
              </button>
              <mat-error *ngIf="
                            shippingViewForm?.get('drpCourier')?.touched &&
                            shippingViewForm?.get('drpCourier')?.errors?.required
                          ">
                Services is a required field!
              </mat-error>
            </mat-form-field>
            <mat-form-field [hidden]="true" *ngIf="packView" class="col-3">
              <mat-label>Track Id</mat-label>
              <input autocomplete="new-txtTrackId" formControlName="txtTrackId" matInput type="text" />
            </mat-form-field>

            <mat-form-field *ngIf="ReturnReason" class="col-3">
              <mat-label>Return Reason</mat-label>
              <textarea readonly formControlName="txtReturnReason" maxlength="400" rows="2" matInput></textarea>
            </mat-form-field>


          </div>
        </form>
        <br>
        <mat-accordion>
          <mat-expansion-panel [expanded]="step">
            <mat-expansion-panel-header>
              <mat-panel-title class="customThemeClass">
                <ng-container>
                  <b>Product Details</b>
                  <!-- <span class="ml-3">Total Qty : {{totalQty}} </span> -->
                </ng-container>
              </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="row">
              <div class="col-12">
                <div class="row pt-1">
                  <table style="width:100%">

                    <tr>
                      <td> <b>Product Name</b></td>
                      <td><b>Brand Name</b></td>
                      <td><b>Category Name</b></td>
                      <td><b>Units</b></td>
                      <td><b>Price</b></td>
                      <td><b>Option</b></td>
                    </tr>

                    <tbody>
                      <tr *ngFor="let item of itemTable">
                        <td> {{ item.productName}}</td>
                        <td> {{item.brandName}}</td>
                        <td> {{item.categoryName}}</td>
                        <td> {{item.qty}}</td>
                        <td> $ {{item.price}}</td>
                        <td>
                          <a *ngIf="item?.productLotDetails.length !== 0" matTooltip="Click To View Provider">
                            <i class="fa fa-eye pointer editBtnColor pl-3"
                              (click)="openViewModal(item.productLotDetails,item?.orderId, item?.productName, item?.productId,item?.itemId, item?.status)"></i>
                          </a>
                        </td>

                      </tr>
                      <tr>
                        <td colspan="2"></td>
                        <td colspan="1"><b>Total</b></td>
                        <td colspan="1"><b>{{totalItemQty}}</b> </td>
                        <td colspan="1"><b>$ {{totalItemPrice}}</b> </td>
                        <td colspan="1"></td>
                      </tr>
                    </tbody>
                  </table>

                </div>
              </div>
            </div>
          </mat-expansion-panel>
        </mat-accordion>
        <br>
        <div class="row">
          <div class="col-12">
            <div class="row">
              <div class="col-1">
              </div>
              <div class="col-7">
              </div>
              <div class="col-sm-4">
                <div class="text-lg-right">
                  <!-- wm approve button -->
                  <button *ngIf="!showButton && shippingApprove" type="submit" (click)="approve()" mat-button
                    class="buttonColor mb-2 mr-2">Approve
                  </button>


                  <!--wm return approve -->
                  <button *ngIf="returnView" type="submit" (click)="approveReturn()" mat-button
                    class="buttonColor mb-2 mr-2">Return Approve
                  </button>
                  <!--wm return reject button -->
                  <button *ngIf="returnView" type="submit" mat-button class="resetclr mb-2 mr-2"
                    (click)="openModel()">Return Reject
                  </button>
                  <!--wm reject button -->
                  <button *ngIf="!showButton && !packView && shippingApprove" type="submit" mat-button
                    class="resetclr mb-2 mr-2" (click)="openModel()">Reject
                  </button>
                  <!-- executive pack button -->
                  <button *ngIf="readyToPack" type="submit" (click)="ReadyToPack()" mat-button
                    class="buttonColor mb-2 mr-2">Ready To Pack
                  </button>
                  <!-- executive pack button -->
                  <button *ngIf="received" type="submit" (click)="ProductReceived()" mat-button
                    class="buttonColor mb-2 mr-2">Product Received
                  </button>
                  <!-- executive ship button -->

                  <button *ngIf="packView" type="submit" mat-button class="buttonColor mb-2 mr-2"
                    (click)="viewTicket()">View Delivery Ticket
                  </button>
                  <button *ngIf="packView && readyToDel" type="submit" mat-button class="buttonColor mb-2 mr-2"
                    [disabled]="!(!shippingViewForm.invalid  && shippingViewForm.dirty)"
                    (click)="confirmReadyToDelivery()">Ready To Delivery
                    <mat-icon *ngIf='isShowSpinner'>
                      <mat-spinner class="spinner-border spinner-border-sm" diameter="20">
                      </mat-spinner>
                    </mat-icon>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>


      </div>
    </div>

  </div>
</div>
