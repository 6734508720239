import { Injectable } from '@angular/core';


import { CommonService } from './common.service';
import { clsPrintPickupOrder } from 'projects/order/src/app/order-proxy/order-management/order-optimization/dto/models';

@Injectable({
  providedIn: 'root',
})
export class GeneratePdfService {
  constructor(private commonService: CommonService) {}

  async getPickupTicketPdf(
    pickupDetails: clsPrintPickupOrder
  ): Promise<string> {
    let docDefinition = this.generateContent(pickupDetails);

    const pdfMake = window['pdfMake'];
    const pdfDocGenerator = pdfMake.createPdf(docDefinition);

    // Convert PDF to base64 string
    const base64String = await new Promise<string>((resolve, reject) => {
      pdfDocGenerator.getBase64((base64String: string) => {
        resolve(base64String);
      });
    });
    console.log(base64String);
    return base64String;
  }


  async downloadPickupTicketPdf(pickupDetails: clsPrintPickupOrder) {
    let docDefinition = this.generateContent(pickupDetails);

    const pdfMake = window['pdfMake'];
    const pdfDocGenerator = pdfMake.createPdf(docDefinition);

    // Download the PDF
    pdfDocGenerator.download();
  }

  generateContent(pickupDetails: any): any {
    const currentDate = this.commonService.getFormattedDate(
      new Date().toString()
    );
    let totalAllowed = pickupDetails?.returnItems.reduce(
      (acc, item) => acc + Number(item?.allowed),
      0
    );
    let totalAccessory = pickupDetails?.returnItems.reduce(
      (acc, item) => acc + Number(item?.accessory),
      0
    );
    let pickupExchangeDate = this.commonService.getFormattedDate(
      pickupDetails?.sDate
    );
    console.log(currentDate);
    let docDefinition: any = {
      pageSize: 'letter',
      pageMargins: [0, 310, 0, 30], // Left, Top, Right, Bottom margins
      watermark: {
        text: 'Qsecure',
        color: 'blue',
        opacity: 0.01,
        bold: true,
        italics: false,
      },
      //#region HEADER
      header: function (currentPage, pageCount, pageSize) {
        function concatenateWithComma(
          ...args: (string | null | undefined)[]
        ): string {
          return args.filter((arg) => arg).join(', ');
        }

        const billingAddress = pickupDetails?.isBillingAddressAvailable
          ? concatenateWithComma(
              pickupDetails?.billingCity,
              pickupDetails?.billingState,
              pickupDetails?.billingZip
            )
          : concatenateWithComma(
              pickupDetails?.deliveryCity,
              pickupDetails?.deliveryState,
              pickupDetails?.deliveryZip
            );

        return [
          {
            columns: [
              {
                width: '100%',
                text: [
                  { text: '\n' },
                  {
                    text: 'Pickup/Exchange Ticket',
                    fontSize: 18,
                    bold: true,
                    alignment: 'center',
                    color: 'lightseagreen',
                  },
                ],
              },
            ],
          },
          {
            stack: [
              {
                canvas: [
                  {
                    type: 'line',
                    x1: 20,
                    y1: 0,
                    x2: 592, // Width of letter page minus left and right margins
                    y2: 0,
                    lineWidth: 1,
                    //dash: { length: 1, space: 1 },
                    // color: 'lightseagreen', // Line color
                  },
                ],
                margin: [0, 5, 0, 5], // Add margin around the line
              },
            ],
          },
          {
            columns: [
              {
                width: '50%',
                table: {
                  body: [
                    [
                      {
                        text:
                          pickupDetails?.providerFirstName +
                          ' ' +
                          (pickupDetails?.providerLastName ?? ''),
                        fontSize: 10,
                        bold: true,
                        alignment: 'left',
                        border: [false, false, false, false],
                      },
                    ],
                    [
                      {
                        text: pickupDetails?.providerAddress,
                        fontSize: 10,
                        alignment: 'left',
                        border: [false, false, false, false],
                      },
                    ],
                    [
                      {
                        text: concatenateWithComma(
                          pickupDetails?.providerCity,
                          pickupDetails?.providerState,
                          pickupDetails?.providerZip
                        ),

                        fontSize: 10,
                        alignment: 'left',
                        border: [false, false, false, false],
                      },
                    ],
                    [
                      {
                        text: pickupDetails?.providerPhone ?? '',
                        fontSize: 10,
                        alignment: 'left',
                        border: [false, false, false, false],
                      },
                    ],
                  ],
                },
                alignment: 'left',
              },
              {
                width: '50%',
                table: {
                  widths: ['50%', '50%'],
                  body: [
                    [
                      {
                        text: 'Date: ',
                        fontSize: 10,
                        bold: true,
                        alignment: 'right',
                        border: [false, false, false, false],
                      },
                      {
                        text: currentDate,
                        fontSize: 10,
                        alignment: 'left',
                        border: [false, false, false, false],
                      },
                    ],
                    [
                      {
                        text: 'Order: ',
                        fontSize: 10,
                        bold: true,
                        alignment: 'right',
                        border: [false, false, false, false],
                      },
                      {
                        text: pickupDetails?.ticketId ?? '',
                        fontSize: 10,
                        alignment: 'left',
                        border: [false, false, false, false],
                      },
                    ],
                    [
                      {
                        text: 'Customer ID: ',
                        fontSize: 10,
                        bold: true,
                        alignment: 'right',
                        border: [false, false, false, false],
                      },
                      {
                        text: pickupDetails?.customerId ?? '',
                        fontSize: 10,
                        alignment: 'left',
                        border: [false, false, false, false],
                      },
                    ],
                    [
                      {
                        text: 'Document ID: ',
                        fontSize: 10,
                        bold: true,
                        alignment: 'right',
                        border: [false, false, false, false],
                      },
                      {
                        text: pickupDetails?.documentId ?? '',
                        fontSize: 10,
                        alignment: 'left',
                        border: [false, false, false, false],
                      },
                    ],
                  ],
                },
                alignment: 'right',
              },
            ],
            margin: [20, 0, 20, 0],
          },
          {
            stack: [
              {
                canvas: [
                  {
                    type: 'line',
                    x1: 20,
                    y1: 0,
                    x2: 592, // Width of letter page minus left and right margins
                    y2: 0,
                    lineWidth: 1,
                    //dash: { length: 1, space: 2 }, // Dotted line
                    //color: 'lightseagreen', // Line color
                  },
                ],
                margin: [0, 5, 0, 5], // Add margin around the line
              },
            ],
          },
          {
            columns: [
              {
                width: '50%',
                table: {
                  body: [
                    [
                      {
                        text: 'Patient: ',
                        fontSize: 10,
                        bold: true,
                        alignment: 'right',
                        border: [false, false, false, false],
                      },
                      {
                        text: pickupDetails?.patientFullName ?? '',
                        fontSize: 10,
                        alignment: 'left',
                        border: [false, false, false, false],
                      },
                    ],
                    [
                      {
                        text: 'Bill To: ',
                        fontSize: 10,
                        bold: true,
                        alignment: 'right',
                        border: [false, false, false, false],
                      },
                      {
                        text: pickupDetails?.isBillingAddressAvailable
                          ? pickupDetails?.billingAddress
                          : pickupDetails?.deliveryAddress,
                        fontSize: 10,
                        alignment: 'left',
                        border: [false, false, false, false],
                      },
                    ],
                    [
                      {
                        text: ' ',
                        fontSize: 10,
                        bold: true,
                        alignment: 'left',
                        border: [false, false, false, false],
                      },
                      {
                        text: billingAddress,
                        fontSize: 10,
                        alignment: 'left',
                        border: [false, false, false, false],
                      },
                    ],
                    // [
                    //   {
                    //     text: ' ',
                    //     fontSize: 10,
                    //     bold: true,
                    //     alignment: 'left',
                    //     border: [false, false, false, false],
                    //   },
                    //   {
                    //     text: 'TN 38119',
                    //     fontSize: 10,
                    //     alignment: 'left',
                    //     border: [false, false, false, false],
                    //   },
                    // ],
                    [
                      {
                        text: ' ',
                        fontSize: 10,
                        bold: true,
                        alignment: 'left',
                        border: [false, false, false, false],
                      },
                      {
                        text: pickupDetails?.isBillingAddressAvailable
                          ? pickupDetails?.billingPhone
                          : pickupDetails?.deliveryPhone,
                        fontSize: 10,
                        alignment: 'left',
                        border: [false, false, false, false],
                      },
                    ],
                  ],
                },
                // margin: [20, 0, 20, 0],
                alignment: 'left',
              },
              {
                width: '50%',
                table: {
                  widths: ['50%', '50%'],
                  body: [
                    [
                      {
                        text: 'Pickup/Exchange Address: ',
                        fontSize: 10,
                        bold: true,
                        alignment: 'right',
                        border: [false, false, false, false],
                      },
                      {
                        text: pickupDetails?.deliveryAddress,
                        fontSize: 10,
                        alignment: 'left',
                        border: [false, false, false, false],
                      },
                    ],
                    [
                      {
                        text: ' ',
                        fontSize: 10,
                        bold: true,
                        alignment: 'left',
                        border: [false, false, false, false],
                      },
                      {
                        text:
                          pickupDetails?.deliveryCity +
                          ',' +
                          pickupDetails?.deliveryState +
                          ',' +
                          pickupDetails?.deliveryZip,
                        fontSize: 10,
                        alignment: 'left',
                        border: [false, false, false, false],
                      },
                    ],
                    // [
                    //   {
                    //     text: ' ',
                    //     fontSize: 10,
                    //     bold: true,
                    //     alignment: 'left',
                    //     border: [false, false, false, false],
                    //   },
                    //   {
                    //     text: 'TN 38119',
                    //     fontSize: 10,
                    //     alignment: 'left',
                    //     border: [false, false, false, false],
                    //   },
                    // ],
                    [
                      {
                        text: ' ',
                        fontSize: 10,
                        bold: true,
                        alignment: 'left',
                        border: [false, false, false, false],
                      },
                      {
                        text: pickupDetails?.deliveryPhone ?? '',
                        fontSize: 10,
                        alignment: 'left',
                        border: [false, false, false, false],
                      },
                    ],
                  ],
                },
                // margin: [20, 0, 20, 0],
                alignment: 'right',
              },
            ],
            margin: [20, 0, 20, 0],
          },
          {
            stack: [
              {
                canvas: [
                  {
                    type: 'line',
                    x1: 20,
                    y1: 0,
                    x2: 592, // Width of letter page minus left and right margins
                    y2: 0,
                    lineWidth: 1,
                    //dash: { length: 1, space: 2 }, // Dotted line
                    //color: 'lightseagreen', // Line color
                  },
                ],
                margin: [0, 5, 0, 5], // Add margin around the line
              },
            ],
          },
          {
            columns: [
              {
                width: '100%',
                table: {
                  body: [
                    [
                      {
                        text: 'Comments or Special Instructions',
                        fontSize: 10,
                        bold: true,
                        alignment: 'left',
                        border: [false, false, false, false],
                      },
                    ],
                    [
                      {
                        text:
                          pickupDetails?.commentsOrSpecialInstructions ??
                          'NA\n\n',
                        fontSize: 10,
                        alignment: 'left',
                        border: [false, false, false, false],
                      },
                    ],
                  ],
                },
                margin: [20, 0, 20, 5],
                alignment: 'left',
              },
            ],
          },

          {
            columns: [
              {
                table: {
                  widths: ['20%', '30%', '50%'], // Set the widths of the 4 columns
                  body: [
                    [
                      {
                        text: 'Pickup/Exchange Date',
                        bold: true,
                        fontSize: 10,
                        alignment: 'center',
                        fillColor: '#7dded9', // Header background color
                        color: 'black', // Header text color
                      },
                      {
                        text: 'CSR',
                        bold: true,
                        fontSize: 10,
                        alignment: 'center',
                        fillColor: '#7dded9', // Header background color
                        color: 'black', // Header text color
                      },
                      {
                        text: 'Branch',
                        bold: true,
                        fontSize: 10,
                        alignment: 'center',
                        fillColor: '#7dded9', // Header background color
                        color: 'black', // Header text color
                      },
                    ],
                    [
                      {
                        text: pickupExchangeDate,
                        fontSize: 10,
                        alignment: 'center',
                        fillColor: '#FFFFFF',
                      },
                      {
                        text: pickupDetails?.csr ?? '',
                        fontSize: 10,
                        alignment: 'center',
                        fillColor: '#FFFFFF',
                      },
                      {
                        text: pickupDetails?.location ?? '',
                        fontSize: 10,
                        alignment: 'center',
                        fillColor: '#FFFFFF',
                      },
                    ],
                  ],
                },
                width: '100%', // Set the table width to 100%
                layout: {
                  hLineWidth: function (i, node) {
                    return i === 0 || i === node.table.body.length ? 1 : 0.5; // Adjust horizontal line width
                  },
                  vLineWidth: function (i) {
                    return 0.5; // Adjust vertical line width
                  },
                  hLineColor: function (i) {
                    return 'black'; // Adjust horizontal line color
                  },
                  vLineColor: function (i) {
                    return 'black'; // Adjust vertical line color
                  },
                },
                margin: [20, 0, 20, 0],
                alignment: 'center',
                TextAlignment: 'center',
              },
            ],
          },
          {
            stack: [
              {
                canvas: [
                  {
                    type: 'line',
                    x1: 20,
                    y1: 0,
                    x2: 592, // Width of letter page minus left and right margins
                    y2: 0,
                    lineWidth: 1,
                    //dash: { length: 1, space: 2 }, // Dotted line
                    //color: 'lightseagreen', // Line color
                  },
                ],
                margin: [0, 10, 0, 5], // Add margin around the line
              },
            ],
          },
        ];
      },
      //#endregion HEADER
      //#region FOOTER
      footer: (currentPage, pageCount, pageSize) => {
        return {
          columns: [
            {
              width: '100%',
              text: [
                {
                  text: 'Page ' + currentPage.toString() + ' of ' + pageCount,
                  fontSize: 10,
                  alignment: 'center',
                },
              ],
            },
          ],
        };
      },

      //#endregion FOOTER
      //#region CONTENT
      content: [
        //#region Row 5
        {
          columns: [
            {
              table: {
                headerRows: 1, // Number of header rows to repeat on each page
                dontBreakRows: true, // Ensure rows are not split across pages
                widths: ['10%', '10%', '10%', '50%', '10%', '10%'], // Set the widths of the columns
                body: [
                  // Table header
                  [
                    {
                      text: 'Quantity',
                      bold: true,
                      alignment: 'center',
                      margin: [0, 10, 0, 0],
                      fillColor: '#7dded9', // Header background color
                      color: 'black', // Header text color
                    },
                    {
                      text: 'Type',
                      bold: true,
                      alignment: 'center',
                      margin: [0, 10, 0, 0],
                      fillColor: '#7dded9', // Header background color
                      color: 'black', // Header text color
                    },
                    {
                      text: 'Exchange Sale Order',
                      bold: true,
                      alignment: 'center',
                      margin: [0, 10, 0, 0],
                      fillColor: '#7dded9', // Header background color
                      color: 'black', // Header text color
                    },
                    {
                      text: 'Item',
                      bold: true,
                      alignment: 'center',
                      margin: [0, 10, 0, 0],
                      fillColor: '#7dded9', // Header background color
                      color: 'black', // Header text color
                    },
                    {
                      text: 'Allowed ($)',
                      alignment: 'center',
                      bold: true,
                      margin: [0, 10, 0, 0],
                      fillColor: '#7dded9', // Header background color
                      color: 'black', // Header text color
                    },
                    {
                      text: 'Accessory ($)',
                      bold: true,
                      alignment: 'center',
                      margin: [0, 10, 0, 0],
                      fillColor: '#7dded9', // Header background color
                      color: 'black', // Header text color
                    },
                  ],
                  // Table data
                  ...pickupDetails?.returnItems.map((item, index) => [
                    {
                      text: item.quantity.toString(),
                      alignment: 'center',
                      margin: [0, 10, 0, 10],
                      fillColor: index % 2 === 0 ? '#F9F9F9' : '#FFFFFF', // Alternating row colors
                    },
                    {
                      text: item?.type,
                      alignment: 'center',
                      margin: [0, 10, 0, 10],
                      fillColor: index % 2 === 0 ? '#F9F9F9' : '#FFFFFF', // Alternating row colors
                    },
                    {
                      text: item?.exchangeSaleOrder,
                      alignment: 'center',
                      margin: [0, 10, 0, 10],
                      fillColor: index % 2 === 0 ? '#F9F9F9' : '#FFFFFF', // Alternating row colors
                    },
                    {
                      text: item?.item,
                      alignment: 'center',
                      margin: [0, 10, 0, 10],
                      fillColor: index % 2 === 0 ? '#F9F9F9' : '#FFFFFF', // Alternating row colors
                    },
                    {
                      text: item?.allowed,
                      alignment: 'center',
                      margin: [0, 10, 0, 10],
                      fillColor: index % 2 === 0 ? '#F9F9F9' : '#FFFFFF', // Alternating row colors
                    },
                    {
                      text: item?.accessory,
                      alignment: 'center',
                      margin: [0, 10, 0, 10],
                      fillColor: index % 2 === 0 ? '#F9F9F9' : '#FFFFFF', // Alternating row colors
                    },
                  ]),
                  [
                    {
                      text: '',
                      bold: true,
                      alignment: 'center',
                      border: [false, false, false, false],
                    },
                    {
                      text: '',
                      bold: true,
                      alignment: 'right',
                      border: [false, false, false, false],
                    },
                    {
                      text: '',
                      bold: true,
                      alignment: 'right',
                      border: [false, false, false, false],
                    },
                    {
                      text: 'Total',
                      bold: true,
                      alignment: 'right',
                      margin: [0, 10, 0, 10],
                      border: [false, false, false, false],
                    },
                    {
                      text: totalAllowed,
                      bold: true,
                      alignment: 'center',
                      margin: [0, 10, 0, 10],
                      // border: [false, false, false, false],
                    },
                    {
                      text: totalAccessory,
                      bold: true,
                      alignment: 'center',
                      margin: [0, 10, 0, 10],
                      // border: [false, false, false, false],
                    },
                  ],
                ],
              },
              layout: {
                hLineWidth: function (i, node) {
                  return i === 0 || i === node.table.body.length ? 1 : 0.5; // Adjust horizontal line width
                },
                vLineWidth: function (i) {
                  return 0.5; // Adjust vertical line width
                },
                hLineColor: function (i) {
                  return 'black'; // Adjust horizontal line color
                },
                vLineColor: function (i) {
                  return 'black'; // Adjust vertical line color
                },
              },
              margin: [20, 10, 20, 0], // Add margin around the table
              fontSize: 10,
            },
          ],
        },
        //#endregion Row 5

        //#region Row 6
        {
          columns: [
            {
              width: '33%',
              stack: [
                {
                  canvas: [
                    {
                      type: 'line',
                      x1: 0,
                      y1: 0,
                      x2: 190,
                      y2: 0,
                      lineWidth: 0.5,
                      // color: 'lightseagreen', // Line color
                    },
                  ],
                  margin: [0, 0, 0, 5], // Add margin to create space below the line
                },
                {
                  text: 'Beneficiary (or Parent/Guardian/Representative)',
                  bold: true,
                  alignment: 'center',
                  // color: 'lightseagreen', // Text color
                },
              ],
              margin: [0, 0, 0, 0], // Add margin around the table
              fontSize: 8,
              align: 'left',
            },
            {
              width: '33%',
              stack: [
                {
                  canvas: [
                    {
                      type: 'line',
                      x1: 10,
                      y1: 0,
                      x2: 200,
                      y2: 0,
                      lineWidth: 0.5,
                      // color: 'lightseagreen', // Line color
                    },
                  ],
                  margin: [0, 0, 0, 5], // Add margin to create space below the line
                },
                {
                  text: 'Relationship of Beneficiary (if Applicable)',
                  bold: true,
                  alignment: 'center',
                  margin: [10, 0, 0, 0],
                  // color: 'lightseagreen', // Text color
                },
              ],
              fontSize: 8,
              align: 'center',
            },
            {
              width: '33%',
              stack: [
                {
                  canvas: [
                    {
                      type: 'line',
                      x1: 20,
                      y1: 0,
                      x2: 195,
                      y2: 0,
                      lineWidth: 0.5,
                      // color: 'lightseagreen', // Line color
                    },
                  ],
                  margin: [0, 0, 0, 5], // Add margin to create space below the line
                },
                {
                  text: 'Technical Initials',
                  bold: true,
                  alignment: 'center',
                  margin: [15, 0, 0, 0],
                  // color: 'lightseagreen', // Text color
                },
              ],
              align: 'right',
              margin: [0, 0, 0, 0], // Add margin around the table
              fontSize: 8,
            },
          ],
          margin: [20, 50, 20, 0],
        },
        // Date row
        {
          columns: [
            {
              width: '33%',
              stack: [
                {
                  canvas: [
                    {
                      type: 'line',
                      x1: 0,
                      y1: 0,
                      x2: 190,
                      y2: 0,
                      lineWidth: 0.5,
                      // color: 'lightseagreen', // Line color
                    },
                  ],
                  margin: [0, 0, 0, 5], // Add margin to create space below the line
                },
                {
                  text: 'Date',
                  bold: true,
                  alignment: 'center',
                  // color: 'lightseagreen', // Text color
                },
              ],
              align: 'left',
              margin: [0, 0, 0, 0], // Add margin around the table
              fontSize: 8,
            },
          ],
          margin: [20, 15, 20, 0],
        },
        //#endregion Row 6
      ],
      //#endregion CONTENT
    };
    return docDefinition;
  }
}
