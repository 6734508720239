<h2 mat-dialog-title>Claim Status</h2>
<mat-dialog-content class="mat-typography">
    <div class="row">
        <div class="col-12">Claim Id : {{ clearingHouseData?.claimId }}</div>
    </div>
    <div class="row">
        <div class="Col-12  pr-2 pl-3">
            Patient Name : {{ clearingHouseData?.claims.claimData[0]?.patName }}
        </div>
    </div>
    <div class="row">
        <div class="Col-12  pr-2 pl-3">
            Payer Name / Payer ID : {{
                clearingHouseData?.claims.claimData[0]?.payerName }} /
            {{ clearingHouseData?.claims.claimData[0]?.payerName }}
        </div>
    </div>
    <div class="row">
        <div class="Col-12  pr-2 pl-3">
            Claim Number : {{ clearingHouseData?.claims.claimData[0]?.claimID }}
        </div>
    </div>
    <div class="row mt-2">
        <div class="col-12">
            <mat-form-field>
                <mat-label>
                    Select Claim Status
                    <span class="asterisk">*</span>
                </mat-label>
                <mat-select [(ngModel)]="strStatus" maxlength="40">
                    <mat-option *ngFor="let claimStatuses of drpClaimStatus" [value]="claimStatuses?.claimStatus">
                        {{ claimStatuses?.claimStatus }}
                    </mat-option>

                </mat-select>
                <button mat-button (click)="strStatus='';$event.stopPropagation()" *ngIf="strStatus" matSuffix
                    mat-icon-button >
                    <mat-icon>close</mat-icon>
                </button>
            </mat-form-field>
        </div>
    </div>
    <div class="container-fluid main-content">
        <div class="row">
            <div class="col-12 table-responsive">
                <table mat-table class="table table-bordered" [dataSource]="dataSource" matSort>

                    <ng-container matColumnDef="eventID">
                        <th class="header" mat-header-cell *matHeaderCellDef>
                            Event ID
                        </th>
                        <td mat-cell *matCellDef="let element"> {{ element?.eventID}}
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="eventDT">
                        <th class="header" mat-header-cell *matHeaderCellDef>
                            Event Date
                        </th>

                        <td mat-cell *matCellDef="let element">
                            {{ element?.eventDT | date :'MM/dd/yyyy h:mm a' :'en_US'}}
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="usrName">
                        <th class="header" mat-header-cell *matHeaderCellDef>
                            User Name
                        </th>
                        <td mat-cell *matCellDef="let element"> {{ element?.usrName}} </td>
                    </ng-container>
                    <ng-container matColumnDef="rawMessage">
                        <th class="header" mat-header-cell *matHeaderCellDef>
                            Raw Message
                        </th>
                        <td mat-cell *matCellDef="let element">
                            <span [matTooltip]="element?.rawMessage">
                                {{element?.description}}
                            </span>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="status">
                        <th class="header" mat-header-cell *matHeaderCellDef>
                            Status
                        </th>
                        <td mat-cell *matCellDef="let element">
                            {{element?.status}}
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="noRecords">
                        <td class="text-align-center" mat-footer-cell *matFooterCellDef colspan="9">
                            <div *ngIf="!isLoading">
                                {{"No Records Found"}}
                            </div>
                            <div [hidden]="!isLoading">
                                <mat-spinner [diameter]="220"></mat-spinner>
                            </div>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="paginator">
                        <td mat-footer-cell *matFooterCellDef colspan="11">
                            <mat-paginator class="table table-responsive mat-table-class" #MatPaginator
                                [length]="dataSource?.data?.length" [pageSize]="10" [pageIndex]="0" showFirstLastButtons
                                [pageSizeOptions]="[5, 10, 25, 100]">
                            </mat-paginator>
                        </td>
                    </ng-container>
                    <tr mat-footer-row *matFooterRowDef="['noRecords']"
                        [ngClass]="{ active: dataSource && dataSource?.data && dataSource?.data?.length !== 0 }"></tr>
                    <tr mat-header-row *matHeaderRowDef="arrDisplayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: arrDisplayedColumns" class="example-element-row"></tr>
                    <tr mat-footer-row *matFooterRowDef="['paginator']"></tr>
                </table>
            </div>
        </div>

    </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button [disabled]="isShowSpinner || !strStatus" mat-button class="buttonColor"
        (click)="updateClaimStatus(strStatus)">
        Update
        <mat-icon *ngIf="isShowSpinner" class='mr-2 ml-2'>
            <mat-spinner class="spinner-border  spinner-border-sm" diameter="20"> </mat-spinner>
        </mat-icon>
    </button>
    <button class="buttonCancelColor" mat-button mat-dialog-close>
        Close
    </button>
</mat-dialog-actions>
