<div class="row">
  <div class="col-lg-6">
    <!-- Modal Title Start  -->
    <ng-container
      *ngIf="
        itemId === '00000000-0000-0000-0000-000000000000';
        else elseTitleTemplate
      "
    >
      <ng-container
        *ngIf="
          data.isExchange && itemId === '00000000-0000-0000-0000-000000000000';
          else addTemplate
        "
      >
        <h2>Exchange Item</h2>
      </ng-container>
      <ng-template #addTemplate>
        <h2>Add Item</h2>
      </ng-template>
    </ng-container>
    <ng-template #elseTitleTemplate>
      <h2>Edit Item</h2>
    </ng-template>

    <!-- Modal Title End  -->
  </div>
</div>
<mat-dialog-content class="mat-typography">
  <!-- Form Start -->
  <form [formGroup]="itemForm">
    <div class="row">
      <div class="col-lg-6">
        <div class="row">
          <div class="col-md-6">
            <h4 class="customThemeClass"><b>Item</b></h4>
          </div>
        </div>
        <mat-card-content>
          <div class="row">
            <mat-form-field class="col-6">
              <mat-label
                >Item Id / HCPC Code<span class="asterisk">*</span></mat-label
              >
              <mat-select
                formControlName="txtItem"
                (selectionChange)="onItemChangeDrpDown($event?.value)"
                [(value)]="txtItem"
                #singleSelect
              >
                <mat-option>
                  <ngx-mat-select-search
                    placeholderLabel="Search"
                    noEntriesFoundLabel="No Matching found"
                    formControlName="txtSearchFilter"
                  ></ngx-mat-select-search>
                </mat-option>
                <mat-option
                  *ngFor="let code of filteredItemTypes | async"
                  [value]="code"
                  [disabled]="code.balanceQuantity !== null && code.balanceQuantity <= 0"
                  [matTooltip]="code.balanceQuantity !== null && code.balanceQuantity <= 0 ? 'No stock' : ''"
                  [matTooltipClass]="'custom-tooltip'"
                >
                  {{ code.productName }}
                </mat-option>
              </mat-select>
              <mat-error
                *ngIf="
                  itemForm?.get('txtItem')?.touched &&
                  itemForm?.get('txtItem')?.errors?.required
                "
              >
                Item Id is a required field!
              </mat-error>
              <button
                mat-button
                (click)="clearItemType()"
                (click)="txtItem = ''; $event.stopPropagation()"
                *ngIf="txtItem"
                matSuffix
                mat-icon-button
              >
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field>

            <mat-form-field class="col-6">
              <mat-label>Name<span class="asterisk">*</span></mat-label>
              <input
                autocomplete="new-txtItemName"
                matInput
                formControlName="txtItemName"
                type="text"
                readonly
              />

              <mat-error
                *ngIf="
                  itemForm?.get('txtItemName')?.touched &&
                  itemForm?.get('txtItemName')?.errors?.required
                "
              >
                Name is a required field!
              </mat-error>
            </mat-form-field>

            <mat-form-field class="col-6">
              <mat-label>Brand Name </mat-label>
              <input
                autocomplete="new-txtBrandName"
                formControlName="txtBrandName"
                matInput
                type="text"
                readonly
              />
            </mat-form-field>

            <mat-form-field class="col-6">
              <mat-label>Category </mat-label>
              <input
                autocomplete="new-txtCategoryName"
                formControlName="txtCategoryName"
                matInput
                type="text"
                readonly
              />
            </mat-form-field>
          </div>
          <div class="row">
            <mat-form-field class="col-6">
              <mat-label>Description</mat-label>
              <input
                autocomplete="new-txtItemDescription"
                matInput
                formControlName="txtItemDescription"
                type="text"
                readonly
              />
            </mat-form-field>
            <mat-form-field class="col-6">
              <mat-label>Branch</mat-label>
              <mat-select [(value)]="location" formControlName="drpLocation">
                <mat-option
                  *ngFor="let location of drpLocationTypes"
                  [value]="location.organizationUnitName"
                >
                  {{ location.organizationUnitName }}
                </mat-option>
              </mat-select>
              <button
                mat-button
                (click)="clearLocation()"
                (click)="location = ''; $event.stopPropagation()"
                *ngIf="location"
                matSuffix
                mat-icon-button
              >
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field>
          </div>
        </mat-card-content>
      </div>
      <div class="col-lg-6">
        <div class="row">
          <div class="col-md-6">
            <h4 class="customThemeClass"><b>Note</b></h4>
          </div>
        </div>
        <mat-card-content>
          <div class="row">
            <mat-form-field class="col-6">
              <mat-label>Note</mat-label>
              <textarea
                rows="1"
                matInput
                maxlength="5000"
                [(ngModel)]="notesText"
                (input)="updateCharacterCount()"
                formControlName="txtNote"
              ></textarea>
              <div class="char-count">{{ notesText?.length }} / 5000</div>
            </mat-form-field>

            <mat-form-field class="col-6">
              <mat-label>Size</mat-label>
              <mat-select formControlName="drpSize" [(value)]="size">
                <mat-option
                  *ngFor="let size of drpSize"
                  [value]="size.itemSize"
                >
                  {{ size.itemSize }}
                </mat-option>
              </mat-select>
              <button
                mat-button
                (click)="clearSize()"
                (click)="size = ''; $event.stopPropagation()"
                *ngIf="size"
                matSuffix
                mat-icon-button
              >
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field>

            <mat-form-field class="col-12">
              <mat-label *ngIf="isInsurance == true">Insurance Name</mat-label>
              <mat-label *ngIf="isInsurance != true"
                >Insurance Name<span class="asterisk">*</span></mat-label
              >
              <mat-select formControlName="drpInsurance">
                <mat-option
                  *ngFor="let insurance of drpPolicies"
                  [value]="insurance.payorName"
                >
                  {{ insurance.payorId + " / " + insurance.payorName }}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <div class="row col-12">
              <!-- <label id="example-radio-group-label">Select Clearing House</label><br> -->

              <mat-radio-group
                aria-label="Select an option"
                (click)="ChangeItems()"
                formControlName="txtProductType"
              >
                <mat-radio-button
                  class="col-3 space"
                  (click)="purchasevalue()"
                  [checked]="true"
                  [value]="1"
                  >Purchase</mat-radio-button
                >
                <mat-radio-button
                  class="col-3 space"
                  (click)="ChangeRetal()"
                  [value]="2"
                  >Rental</mat-radio-button
                >
                <mat-radio-button
                  class="col-3 space"
                  (click)="ChangeResupply()"
                  [value]="3"
                  >Resupply</mat-radio-button
                >
              </mat-radio-group>
            </div>
            <!-- <mat-form-field class="col-6">
              <mat-label>Follow Up Duration<span class="asterisk">*</span>
              </mat-label>
              <input matInput formControlName="txtFollowUpDuration" type="text" />
              <mat-error
                *ngIf="itemForm?.get('txtFollowUpDuration')?.touched && itemForm?.get('txtFollowUpDuration')?.errors?.required">
                Follow Up Duration is required!
              </mat-error>
              <mat-error *ngIf="
                itemForm?.get('txtFollowUpDuration')?.touched && itemForm?.get('txtFollowUpDuration')?.errors?.msg">
                {{itemForm?.get('txtFollowUpDuration').errors?.msg }}
              </mat-error>
              <mat-error
                *ngIf="
                itemForm?.get('txtFollowUpDuration')?.touched && itemForm?.get('txtFollowUpDuration')?.errors?.max?.max">
                Maximum Value is 99
              </mat-error>
            </mat-form-field> -->
            <div class="col-6 mt-3">
              <!-- <mat-slide-toggle formControlName="chkIsFollowUpNeeded" class="customThemeClass toggle">
                <b>Is Follow Needed</b>
              </mat-slide-toggle> -->
            </div>
          </div>
        </mat-card-content>
      </div>
    </div>
    <br /><br />
    <mat-accordion>
      <mat-expansion-panel
        [disabled]="
          productType == 'MainProduct' &&
          productType != 'Rental' &&
          productType != 'Resupply'
        "
        [expanded]="step1"
        (afterExpand)="toggle()"
        (closed)="purchasevalue()"
      >
        <mat-expansion-panel-header>
          <mat-panel-title class="customThemeClass">
            <ng-container>
              <b>More Details</b> </ng-container
            ><br /><br />
          </mat-panel-title>
        </mat-expansion-panel-header>
        <mat-card-content>
          <mat-form-field class="col-3">
            <mat-label
              >Schedule Type<span *ngIf="isValidators == true" class="asterisk"
                >*</span
              ></mat-label
            >
            <mat-select
              maxlength="40"
              formControlName="drpScheduleType"
              [(value)]="strScheduleType"
            >
              <mat-option
                *ngFor="let scheduleType of drpScheduleType"
                [value]="scheduleType"
              >
                {{ scheduleType }}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <ng-container>
            <mat-form-field class="col-3">
              <mat-label
                >Start Date
                <span *ngIf="isValidators == true" class="asterisk">*</span>
              </mat-label>
              <!-- [min]="scheduledMinDate" -->
              <input
                maxlength="40"
                formControlName="txtScheduledDate"
                [matDatepicker]="txtScheduledDate"
                matInput
                autocomplete="new-txtScheduledDate"
                placeholder="(MM/DD/YYYY)"
              />
              <mat-datepicker-toggle
                matSuffix
                [for]="txtScheduledDate"
              ></mat-datepicker-toggle>
              <mat-datepicker #txtScheduledDate></mat-datepicker>
              <mat-error
                *ngIf="
                  itemForm?.get('txtScheduledDate')?.touched &&
                  itemForm?.get('txtScheduledDate')?.errors
                "
              >
                Enter Valid Date!
              </mat-error>
              <mat-error
                *ngIf="
                  scheduledMinDate >=
                    itemForm?.get('txtScheduledDate')?.value &&
                  !(
                    itemForm?.get('txtScheduledDate')?.touched &&
                    itemForm?.get('txtScheduledDate')?.errors?.required
                  )
                "
              >
                Enter Future Dates Only!
              </mat-error>
              <mat-error
                *ngIf="
                  itemForm?.get('Delivery.txtScheduledDate')?.touched &&
                  itemForm?.get('Delivery.txtScheduledDate')?.errors?.required
                "
              >
                Scheduled Date is a required field!
              </mat-error>
            </mat-form-field>
            <mat-form-field class="col-3">
              <mat-label
                >End Period<span *ngIf="isValidators == true" class="asterisk"
                  >*</span
                ></mat-label
              >
              <input
                maxlength="15"
                autocomplete="new-txtEndPeriod"
                formControlName="txtEndPeriod"
                pattern="[0-9-]*"
                matInput
                type="text"
              />
            </mat-form-field>
          </ng-container>

          <div
            *ngIf="itemForm?.value?.drpScheduleType === 'Monthly'"
            class="col-8"
          >
            <h4
              *ngIf="itemForm?.value?.drpScheduleType === 'Monthly'"
              class="customThemeClass mar-top"
            >
              <b>Days Calcaution</b>
            </h4>
            <div
              *ngIf="itemForm?.value?.drpScheduleType === 'Monthly'"
              class="row"
            >
              <mat-form-field class="col-3">
                <mat-label
                  >Start Day
                  <span *ngIf="isValidators == true" class="asterisk"
                    >*</span
                  ></mat-label
                >
                <input
                  maxlength="15"
                  autocomplete="new-txtScheduledDays"
                  formControlName="txtScheduledDays"
                  pattern="^(0?[1-9]|[1-2][0-9]|30|31)$"
                  matInput
                  type="text"
                />
                <!-- <mat-error *ngIf="
                  itemForm?.get('txtScheduledDays')?.touched &&
                  itemForm?.get('txtScheduledDays')?.errors &&
                  itemForm?.get('txtScheduledDays')?.value!==''">
                      Enter Valid Number Without Leading Zeros In range Of 1-31 Only

                  </mat-error>
                  <mat-error *ngIf="
                  itemForm?.get('txtScheduledDays')?.touched &&
                  itemForm?.get('txtScheduledDays')?.value===''
                      ">
                      Start Day is a required field!
                  </mat-error> -->
              </mat-form-field>

              <!-- <mat-form-field class="col-9">
                <mat-label
                  >Months
                  <span *ngIf="isValidators == true" class="asterisk"
                    >*</span
                  ></mat-label
                >
                <mat-select
                  maxlength="40"
                  formControlName="drpMonths"
                  (selectionChange)="toggleAllSelection()"
                  [(value)]="arrMonths"
                  multiple
                >
                  <mat-option
                    *ngFor="let month of drpMonthsList"
                    [value]="month"
                  >
                    {{ month.key }}
                  </mat-option>
                </mat-select>
                <button
                  mat-button
                  (click)="clearScheduleType()"
                  (click)="arrMonths = []; $event.stopPropagation()"
                  *ngIf="arrMonths !== []"
                  matSuffix
                  mat-icon-button
                >
                  <mat-icon>close</mat-icon>
                </button>
              </mat-form-field> -->


              <mat-form-field class="col-9">
                <mat-label>Months</mat-label>
                <mat-select  #select multiple formControlName="drpMonths"  [(value)]="arrMonths">
                  <div class="select-all" style="margin-left: 15px !important;margin-top: 10px !important;">
                      <mat-checkbox  [(ngModel)]="allSelected"
                                      [ngModelOptions]="{standalone: true}"
                                      (change)="toggleAllSelections()"> All</mat-checkbox>
                  </div>
                  <mat-option    (click)="optionClick()" (onSelectionChange)="onChange($event)"*ngFor="let month of drpMonthsList" [value]="month">
                    {{month.key}}
                  </mat-option>
                </mat-select>
              </mat-form-field>

            </div>

            <h4 class="customThemeClass mt-3">
              <b>Summary Details</b>
            </h4>
            <div class="row">
              <mat-form-field class="col-12">
                <mat-label>Schedule Summary</mat-label>
                <textarea
                  maxlength="500"
                  rows="4"
                  autocomplete="off"
                  formControlName="txtScheduleSummary"
                  readonly
                  matInput
                  type="text"
                ></textarea>
              </mat-form-field>
              <mat-form-field class="col-6">
                <mat-label>Last Run</mat-label>
                <input
                  maxlength="40"
                  formControlName="txtLastDate"
                  matInput
                  autocomplete="new-txtLastDate"
                  [disabled]="true"
                  [matDatepicker]="txtLastDate"
                  placeholder="(MM/DD/YYYY)"
                />
                <mat-datepicker-toggle
                  matSuffix
                  [for]="txtLastDate"
                ></mat-datepicker-toggle>
                <mat-datepicker #txtLastDate></mat-datepicker>
              </mat-form-field>
              <mat-form-field class="col-6">
                <mat-label>Next Run</mat-label>
                <input
                  maxlength="40"
                  formControlName="txtNextDate"
                  matInput
                  autocomplete="new-txtNextDate"
                  [disabled]="true"
                  [matDatepicker]="txtNextDate"
                  placeholder="(MM/DD/YYYY)"
                />
                <mat-datepicker-toggle
                  matSuffix
                  [for]="txtNextDate"
                ></mat-datepicker-toggle>
                <mat-datepicker #txtNextDate></mat-datepicker>
              </mat-form-field>
            </div>
          </div>

          <div
            *ngIf="itemForm?.value?.drpScheduleType === 'Weekly'"
            class="col-8"
          >
            <h4
              *ngIf="itemForm?.value?.drpScheduleType === 'Weekly'"
              class="customThemeClass mar-top"
            >
              <b>Days Calcaution</b>
            </h4>
            <div
              *ngIf="itemForm?.value?.drpScheduleType === 'Weekly'"
              class="row"
            >
              <mat-form-field class="col-6">
                <mat-label
                  >Weeks<span *ngIf="isValidators == true" class="asterisk"
                    >*</span
                  >
                </mat-label>
                <input
                  maxlength="40"
                  formControlName="txtWeeks"
                  matInput
                  autocomplete="new-txtWeeks"
                  placeholder="Weeks"
                />
              </mat-form-field>
              <!-- <mat-form-field class="col-9">
                <mat-label>Weeks <span class="asterisk">*</span></mat-label>
                <mat-select maxlength="40" formControlName="drpWeeks" [(value)]="arrWeeks" multiple>
                    <mat-option *ngFor="let week of drpWeeksList" [value]="week">
                        {{ week.key }}
                    </mat-option>
                </mat-select>
                <button mat-button (click)="clearScheduleType()"
                    (click)="arrWeeks = []; $event.stopPropagation()" *ngIf="arrWeeks!==[]" matSuffix
                    mat-icon-button>
                    <mat-icon>close</mat-icon>
                </button>
            </mat-form-field> -->
            </div>

            <h4 class="customThemeClass mt-3">
              <b>Summary Details</b>
            </h4>
            <div class="row">
              <mat-form-field class="col-12">
                <mat-label>Schedule Summary</mat-label>
                <textarea
                  maxlength="500"
                  rows="4"
                  autocomplete="off"
                  formControlName="txtScheduleSummary"
                  readonly
                  matInput
                  type="text"
                ></textarea>
              </mat-form-field>
              <mat-form-field class="col-6">
                <mat-label>Last Run</mat-label>
                <input
                  maxlength="40"
                  formControlName="txtLastDate"
                  matInput
                  autocomplete="new-txtLastDate"
                  [disabled]="true"
                  [matDatepicker]="txtLastDate"
                  placeholder="(MM/DD/YYYY)"
                />
                <mat-datepicker-toggle
                  matSuffix
                  [for]="txtLastDate"
                ></mat-datepicker-toggle>
                <mat-datepicker #txtLastDate></mat-datepicker>
              </mat-form-field>
              <mat-form-field class="col-6">
                <mat-label>Next Run</mat-label>
                <input
                  maxlength="40"
                  formControlName="txtNextDate"
                  matInput
                  autocomplete="new-txtNextDate"
                  [disabled]="true"
                  [matDatepicker]="txtNextDate"
                  placeholder="(MM/DD/YYYY)"
                />
                <mat-datepicker-toggle
                  matSuffix
                  [for]="txtNextDate"
                ></mat-datepicker-toggle>
                <mat-datepicker #txtNextDate></mat-datepicker>
              </mat-form-field>
            </div>
          </div>

          <div
            *ngIf="itemForm?.value?.drpScheduleType === 'Daily'"
            class="col-8"
          >
            <h4
              *ngIf="itemForm?.value?.drpScheduleType === 'Monthly'"
              class="customThemeClass mar-top"
            >
              Daily <b>Days Calcaution</b>
            </h4>
            <div
              *ngIf="itemForm?.value?.drpScheduleType === 'Monthly'"
              class="row"
            >
              <mat-form-field class="col-3">
                <mat-label>Days To Be Run</mat-label>
                <input
                  maxlength="15"
                  autocomplete="new-txtEndPeriod"
                  formControlName="txtEndPeriod"
                  pattern="[0-9]*"
                  matInput
                  type="text"
                />
              </mat-form-field>
            </div>

            <h4 class="customThemeClass mar-top">
              <b>Summary Details</b>
            </h4>
            <div class="row">
              <mat-form-field class="col-12">
                <mat-label>Schedule Summary</mat-label>
                <textarea
                  maxlength="500"
                  rows="4"
                  autocomplete="off"
                  formControlName="txtScheduleSummary"
                  readonly
                  matInput
                  type="text"
                ></textarea>
              </mat-form-field>
              <mat-form-field class="col-6">
                <mat-label>Last Run</mat-label>
                <input
                  maxlength="40"
                  formControlName="txtLastDate"
                  matInput
                  autocomplete="new-txtLastDate"
                  [disabled]="true"
                  [matDatepicker]="txtLastDate"
                  placeholder="(MM/DD/YYYY)"
                />
                <mat-datepicker-toggle
                  matSuffix
                  [for]="txtLastDate"
                ></mat-datepicker-toggle>
                <mat-datepicker #txtLastDate></mat-datepicker>
              </mat-form-field>
              <mat-form-field class="col-6">
                <mat-label>Next Run</mat-label>
                <input
                  maxlength="40"
                  formControlName="txtNextDate"
                  matInput
                  autocomplete="new-txtNextDate"
                  [disabled]="true"
                  [matDatepicker]="txtNextDate"
                  placeholder="(MM/DD/YYYY)"
                />
                <mat-datepicker-toggle
                  matSuffix
                  [for]="txtNextDate"
                ></mat-datepicker-toggle>
                <mat-datepicker #txtNextDate></mat-datepicker>
              </mat-form-field>
            </div>
          </div>

          <div
            *ngIf="itemForm?.value?.drpScheduleType === 'Yearly'"
            class="col-8"
          >
            <h4
              *ngIf="itemForm?.value?.drpScheduleType === 'Yearly'"
              class="customThemeClass mar-top"
            >
              <b>Days Calcaution</b>
            </h4>
            <mat-form-field class="row col-3">
              <mat-label
                >Years
                <span *ngIf="isValidators == true" class="asterisk"
                  >*</span
                ></mat-label
              >
              <input
                maxlength="40"
                formControlName="txtYears"
                matInput
                autocomplete="new-txtYears"
                placeholder="txtYears"
              />
            </mat-form-field>
            <div
              *ngIf="itemForm?.value?.drpScheduleType === 'Monthly'"
              class="row"
            >
              <mat-form-field class="col-3">
                <mat-label>Days To Be Run</mat-label>
                <input
                  maxlength="15"
                  autocomplete="new-txtEndPeriod"
                  formControlName="txtEndPeriod"
                  pattern="[0-9]*"
                  matInput
                  type="text"
                />
              </mat-form-field>
            </div>

            <h4 class="customThemeClass mar-top">
              <b>Summary Details</b>
            </h4>
            <div class="row">
              <mat-form-field class="col-12">
                <mat-label>Schedule Summary</mat-label>
                <textarea
                  maxlength="500"
                  rows="4"
                  autocomplete="off"
                  formControlName="txtScheduleSummary"
                  readonly
                  matInput
                  type="text"
                ></textarea>
              </mat-form-field>
              <mat-form-field class="col-6">
                <mat-label>Last Run</mat-label>
                <input
                  maxlength="40"
                  formControlName="txtLastDate"
                  matInput
                  autocomplete="new-txtLastDate"
                  [disabled]="true"
                  [matDatepicker]="txtLastDate"
                  placeholder="(MM/DD/YYYY)"
                />
                <mat-datepicker-toggle
                  matSuffix
                  [for]="txtLastDate"
                ></mat-datepicker-toggle>
                <mat-datepicker #txtLastDate></mat-datepicker>
              </mat-form-field>
              <mat-form-field class="col-6">
                <mat-label>Next Run</mat-label>
                <input
                  maxlength="40"
                  formControlName="txtNextDate"
                  matInput
                  autocomplete="new-txtNextDate"
                  [disabled]="true"
                  [matDatepicker]="txtNextDate"
                  placeholder="(MM/DD/YYYY)"
                />
                <mat-datepicker-toggle
                  matSuffix
                  [for]="txtNextDate"
                ></mat-datepicker-toggle>
                <mat-datepicker #txtNextDate></mat-datepicker>
              </mat-form-field>
            </div>
          </div>
          <div
            *ngIf="itemForm?.value?.drpScheduleType === 'Once'"
            class="col-8"
          >
            <h4
              *ngIf="itemForm?.value?.drpScheduleType === 'Monthly'"
              class="customThemeClass mar-top"
            >
              Once <b>Days Calcaution</b>
            </h4>
            <div
              *ngIf="itemForm?.value?.drpScheduleType === 'Monthly'"
              class="row"
            >
              <mat-form-field class="col-3">
                <mat-label>Days To Be Run</mat-label>
                <input
                  maxlength="15"
                  autocomplete="new-txtEndPeriod"
                  formControlName="txtEndPeriod"
                  pattern="[0-9]*"
                  [value]="'1'"
                  [disabled]="true"
                  matInput
                  type="text"
                />
              </mat-form-field>
            </div>

            <h4 class="customThemeClass mar-top">
              <b>Summary Details</b>
            </h4>
            <div class="row">
              <mat-form-field class="col-12">
                <mat-label>Schedule Summary</mat-label>
                <textarea
                  maxlength="500"
                  rows="4"
                  autocomplete="off"
                  formControlName="txtScheduleSummary"
                  readonly
                  matInput
                  type="text"
                ></textarea>
              </mat-form-field>
              <mat-form-field class="col-6">
                <mat-label>Last Run</mat-label>
                <input
                  maxlength="40"
                  formControlName="txtLastDate"
                  matInput
                  autocomplete="new-txtLastDate"
                  [disabled]="true"
                  [matDatepicker]="txtLastDate"
                  placeholder="(MM/DD/YYYY)"
                />
                <mat-datepicker-toggle
                  matSuffix
                  [for]="txtLastDate"
                ></mat-datepicker-toggle>
                <mat-datepicker #txtLastDate></mat-datepicker>
              </mat-form-field>
              <mat-form-field class="col-6">
                <mat-label>Next Run</mat-label>
                <input
                  maxlength="40"
                  formControlName="txtNextDate"
                  matInput
                  autocomplete="new-txtNextDate"
                  [disabled]="true"
                  [matDatepicker]="txtNextDate"
                  placeholder="(MM/DD/YYYY)"
                />
                <mat-datepicker-toggle
                  matSuffix
                  [for]="txtNextDate"
                ></mat-datepicker-toggle>
                <mat-datepicker #txtNextDate></mat-datepicker>
              </mat-form-field>
            </div>
          </div>
        </mat-card-content>
      </mat-expansion-panel> </mat-accordion
    ><br /><br />
    <mat-accordion>
      <mat-expansion-panel [expanded]="step">
        <mat-expansion-panel-header>
          <mat-panel-title class="customThemeClass">
            <ng-container>
              <b>Tricare 2017 Purchase Pricing</b> </ng-container
            ><br /><br />
          </mat-panel-title>
        </mat-expansion-panel-header>

        <div class="row">
          <div class="col-lg-12">
            <div class="row">
              <div class="col-md-6">
                <!-- <h4 class="customThemeClass">
              <b>Tricare 2017 Purchase Pricing</b>
            </h4> -->
              </div>
            </div>
            <mat-card-content>
              <div class="row">
                <div class="col-md-2">
                  <br />
                  <mat-checkbox formControlName="chkPricing"
                    >Special Pricing</mat-checkbox
                  >
                </div>
                <div class="col-md-2">
                  <br />
                  <mat-checkbox formControlName="chkTaxable"
                    >Taxable</mat-checkbox
                  >
                </div>
                <div class="col-md-2">
                  <br />
                  <mat-checkbox formControlName="chkChargeOveride"
                    >Price Override</mat-checkbox
                  >
                </div>
                <div class="col-md-2">
                  <br />
                  <mat-checkbox formControlName="chkTaxRate"
                    >Special Tax Rate</mat-checkbox
                  >
                </div>

                <div class="col-md-4">
                  <br />
                  <mat-checkbox formControlName="chkConvertPurchase"
                    >Manual Convert to Purchase</mat-checkbox
                  >
                </div>
                <div class="col-md-2">
                  <br />
                  <mat-checkbox formControlName="chkAbn">ABN</mat-checkbox>
                </div>
                <div class="col-md-2">
                  <br />
                  <mat-checkbox formControlName="chkAbnUpgrade"
                    >ABN Upgrade</mat-checkbox
                  >
                </div>
                <div class="col-md-2">
                  <br />
                  <mat-checkbox formControlName="chkCbOverride"
                    >CB Override</mat-checkbox
                  >
                </div>
                <div class="col-md-2">
                  <br />
                  <mat-checkbox formControlName="chkAssignment"
                    >Accept Assignment</mat-checkbox
                  >
                </div>

                <mat-form-field class="col-2">
                  <mat-label>Allow</mat-label>
                  <input
                    autocomplete="new-txtAllow"
                    matInput
                    formControlName="txtAllow"
                    type="text"
                  />
                  <span matPrefix>$</span>
                  <mat-error
                    *ngIf="
                      itemForm?.get('txtAllow')?.touched &&
                      itemForm?.get('txtAllow')?.errors?.number
                    "
                  >
                    Enter only numbers!
                  </mat-error>
                </mat-form-field>
                <mat-form-field class="col-2">
                  <mat-label>Billing Units</mat-label>
                  <input
                    autocomplete="new-txtBillingQty"
                    matInput
                    maxlength="40"
                    min="1"
                    formControlName="txtBillingQty"
                    maxlength="4"
                    type="text"
                  />
                  <mat-error
                    *ngIf="
                      itemForm?.get('txtBillingQty')?.touched &&
                      itemForm?.get('txtBillingQty')?.errors?.number
                    "
                  >
                    Enter only Numbers!
                  </mat-error>
                  <mat-error
                    *ngIf="
                      itemForm?.get('txtBillingQty')?.touched &&
                      itemForm?.get('txtBillingQty')?.errors?.pattern
                    "
                  >
                    Enter Valid Number!
                  </mat-error>
                  <mat-error
                    *ngIf="
                      itemForm?.get('txtBillingQty')?.touched &&
                      itemForm?.get('txtBillingQty')?.errors?.min?.min
                    "
                  >
                    Enter Valid Number Greater Than 0!
                  </mat-error>
                </mat-form-field>

                <!-- Modifiers 1 -->

                <mat-form-field class="col-2">
                  <mat-label> Modifiers 1 </mat-label>
                  <input
                    autocomplete="new-txtModifiers1"
                    matInput
                    maxlength="4"
                    formControlName="txtModifiers1"
                    pattern="[a-zA-Z0-9]*"
                    type="text"
                  />
                  <mat-error
                    *ngIf="
                      itemForm?.get('txtModifiers1')?.touched &&
                      itemForm?.get('txtModifiers1')?.errors?.pattern
                    "
                  >
                    Enter only AlphaNumeric!
                  </mat-error>
                </mat-form-field>

                <!-- Modifiers 2 -->
                <mat-form-field class="col-2">
                  <mat-label> Modifiers 2 </mat-label>
                  <input
                    autocomplete="new-txtModifiers2"
                    maxlength="4"
                    formControlName="txtModifiers2"
                    matInput
                    type="text"
                    pattern="[a-zA-Z0-9]*"
                  />
                  <mat-error
                    *ngIf="
                      itemForm?.get('txtModifiers2')?.touched &&
                      itemForm?.get('txtModifiers2')?.errors?.pattern
                    "
                  >
                    Enter Only Alphanumeric Only!
                  </mat-error>
                </mat-form-field>

                <!-- Modifiers 3 -->
                <mat-form-field class="col-2">
                  <mat-label> Modifiers 3 </mat-label>
                  <input
                    autocomplete="new-txtModifiers3"
                    matInput
                    maxlength="4"
                    formControlName="txtModifiers3"
                    pattern="[a-zA-Z0-9]*"
                    type="text"
                  />
                  <mat-error
                    *ngIf="
                      itemForm?.get('txtModifiers3')?.touched &&
                      itemForm?.get('txtModifiers3')?.errors?.pattern
                    "
                  >
                    Enter only AlphaNumeric!
                  </mat-error>
                </mat-form-field>
                <!-- Modifiers 4 -->
                <mat-form-field class="col-2">
                  <mat-label> Modifiers 4 </mat-label>
                  <input
                    autocomplete="new-txtModifiers4"
                    matInput
                    maxlength="4"
                    formControlName="txtModifiers4"
                    pattern="[a-zA-Z0-9]*"
                    type="text"
                  />
                  <mat-error
                    *ngIf="
                      itemForm?.get('txtModifiers4')?.touched &&
                      itemForm?.get('txtModifiers4')?.errors?.pattern
                    "
                  >
                    Enter only AlphaNumeric!
                  </mat-error>
                </mat-form-field>
                <mat-form-field class="col-2">
                  <mat-label>Price <span class="asterisk">*</span></mat-label>
                  <span matPrefix>$ &nbsp;</span>
                  <input
                    autocomplete="new-txtPrice"
                    matInput
                    maxlength="4"
                    formControlName="txtPrice"
                    type="text"
                  />
                  <mat-error
                    *ngIf="
                      itemForm?.get('txtPrice')?.touched &&
                      itemForm?.get('txtPrice')?.errors?.required &&
                      !itemForm?.get('txtPrice')?.errors?.msg
                    "
                  >
                    Price is a required field!
                  </mat-error>
                  <mat-error
                    *ngIf="
                      itemForm?.get('txtPrice')?.touched &&
                      itemForm?.get('txtPrice')?.errors?.number
                    "
                  >
                    Enter only Valid Numbers!
                  </mat-error>
                  <mat-error
                    *ngIf="
                      itemForm?.get('txtPrice')?.touched &&
                      itemForm?.get('txtPrice').errors
                    "
                  >
                    Enter Valid Number Greater Than 0!
                  </mat-error>
                </mat-form-field>
                <mat-form-field class="col-2">
                  <mat-label
                    >Ordering Units <span class="asterisk">*</span></mat-label
                  >
                  <input
                    autocomplete="new-txtOrderingQty"
                    matInput
                    min="1"
                    formControlName="txtOrderingQty"
                    maxlength="4"
                    type="number"
                  />
                  <mat-error
                    *ngIf="
                      itemForm?.get('txtOrderingQty')?.touched &&
                      itemForm?.get('txtOrderingQty')?.errors?.required
                    "
                  >
                    Units is a required field!
                  </mat-error>
                  <!-- <mat-error
                *ngIf="
                  itemForm?.get('txtOrderingQty')?.touched &&
                  itemForm?.get('txtOrderingQty')?.errors?.number
                "
              >
                Enter only Valid Numbers!
              </mat-error> -->
                  <!-- <mat-error
                *ngIf="
                  itemForm?.get('txtOrderingQty')?.touched &&
                  itemForm?.get('txtOrderingQty')?.errors?.pattern
                "
              >
                Enter Valid Number!
              </mat-error>
              <mat-error
                *ngIf="
                  itemForm?.get('txtOrderingQty')?.touched &&
                  itemForm?.get('txtOrderingQty')?.errors?.msg?.min
                "
              >
                Enter Valid Number Greater Than 0!
              </mat-error> -->
                </mat-form-field>
                <p class="mt-3">
                  <b class="col-2 customThemeClass">Total Price:</b> {{ "$ "
                  }}{{ totalPrice | number : "1.2-2" }}
                </p>
              </div>
            </mat-card-content>
          </div>
        </div>
      </mat-expansion-panel> </mat-accordion
    ><br /><br />

    <div class="row">
      <div class="col-lg-6">
        <div class="row">
          <div class="col-md-6">
            <h4 class="customThemeClass"><b>Dates</b></h4>
          </div>
        </div>
        <mat-card-content>
          <div class="row">
            <div class="col-md-5">
              <br />
              <mat-checkbox formControlName="chkSupply"
                >Days Of Supply Override</mat-checkbox
              >
            </div>
            <div class="col-md-7">
              <br />
              <mat-checkbox formControlName="chkSplit"
                >Span Date Split Billing Enabled</mat-checkbox
              >
            </div>

            <mat-form-field class="col-4">
              <mat-label
                >Original DOS <span class="asterisk">*</span></mat-label
              >
              <input
                autocomplete="new-txtFromDOS"
                matInput
                maxlength="40"
                placeholder="MM/DD/YYYY"
                formControlName="txtFromDOS"
                [matDatepicker]="myPicker2"
                [min]="minDate"
              />
              <mat-datepicker-toggle
                matSuffix
                [for]="myPicker2"
              ></mat-datepicker-toggle>
              <mat-datepicker #myPicker2></mat-datepicker>
              <mat-error
                *ngIf="
                  itemForm?.get('txtFromDOS')?.touched &&
                  itemForm?.get('txtFromDOS')?.errors
                "
              >
                Enter Valid Date!
              </mat-error>
            </mat-form-field>

            <mat-form-field class="col-4">
              <mat-label>DOS to</mat-label>
              <input
                autocomplete="new-txtToDOS"
                matInput
                maxlength="40"
                placeholder="MM/DD/YYYY"
                formControlName="txtToDOS"
                [matDatepicker]="myPicker3"
              />
              <mat-datepicker-toggle
                matSuffix
                [for]="myPicker3"
              ></mat-datepicker-toggle>
              <mat-datepicker #myPicker3></mat-datepicker>
              <mat-error
                *ngIf="
                  itemForm?.get('txtToDOS')?.touched &&
                  itemForm?.get('txtToDOS')?.errors
                "
              >
                Enter Valid Date!
              </mat-error>
            </mat-form-field>
          </div>
        </mat-card-content>
      </div>
      <div class="col-lg-6">
        <div class="row">
          <div class="col-md-6">
            <h4 class="customThemeClass"><b>Insurance</b></h4>
          </div>
        </div>
        <mat-card-content>
          <div class="row">
            <div class="col-md-4">
              <br />
              <mat-checkbox formControlName="chkPrimary">Primary</mat-checkbox>
            </div>
            <div class="col-md-4">
              <br />
              <mat-checkbox formControlName="chkSecondary"
                >Secondary</mat-checkbox
              >
            </div>
            <div class="col-md-4">
              <br />
              <mat-checkbox formControlName="chkTertiary"
                >Tertiary</mat-checkbox
              >
            </div>
            <div class="col-md-4">
              <br />
              <mat-checkbox formControlName="chkPatient">Patient</mat-checkbox>
            </div>
            <mat-form-field class="col-4">
              <mat-label>Coverage Type</mat-label>
              <input
                autocomplete="new-txtCoverageType"
                matInput
                maxlength="40"
                formControlName="txtCoverageType"
                type="text"
              />
            </mat-form-field>
            <mat-form-field class="col-4">
              <mat-label>Claim Submission</mat-label>
              <input
                autocomplete="new-txtSubmission"
                matInput
                maxlength="40"
                formControlName="txtSubmission"
                type="text"
              />
            </mat-form-field>
          </div>
        </mat-card-content>
      </div>
    </div>
  </form>
  <!-- Form End -->
</mat-dialog-content>
<mat-dialog-actions align="end">
  <!-- Modal Action Buttons Start -->
  <button
    *ngIf="orderStatus"
    [disabled]="itemForm?.invalid || isShowSpinner"
    mat-button
    class="buttonColor"
    id="btnSaveItem"
    (click)="saveItem()"
  >
    {{ btnVal }}
    <mat-icon *ngIf="isShowSpinner">
      <mat-spinner class="spinner-border spinner-border-sm" diameter="20">
      </mat-spinner>
    </mat-icon>
  </button>

  <button class="buttonCancelColor" mat-button mat-dialog-close>Close</button>
  <!-- Modal Action Buttons  End -->
</mat-dialog-actions>
