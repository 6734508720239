import { Component, EventEmitter, HostListener, Inject, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { RegionCitiesDTO, RegionCountriesDTO, RegionStatesDTO } from 'projects/patient/src/app/dropdown-proxy/dropdown-management/dropdowns/dto/models';
import { Observable, Subscription } from 'rxjs';
import { RegionDropdownService } from 'projects/patient/src/app/dropdown-proxy/dropdown-management/dropdowns/region-dropdown-service';
import { defaultCountry } from 'projects/shared/src/app/enums/allenums.enum';
import { map, startWith } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import Swal from 'sweetalert2';
import { PagedAndSortedResultRequestDto } from '@abp/ng.core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PracticeManagementService } from '../patient-proxy/patient/practice-management';
import { DoctorService } from '../patient-proxy/patient/doctor.service';
import { CreateUpdateDoctorDTO, CreateUpdatePracticeDoctorDTO, DoctorDTO } from '../patient-proxy/patient/dto/models';
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-update-practice-physician',
  templateUrl: './update-practice-physician.component.html',
  styleUrls: ['./update-practice-physician.component.scss']
})
export class UpdatePracticePhysicianComponent implements OnInit {

  @Input() physicianid: string;
  @Output() reloadTableEvent = new EventEmitter();
  @Output() selectNode = new EventEmitter();
  isShowSpinner: boolean = false;
  updatedrp: any;
  doctorId: string;
  hidecountry: boolean = true;
  drpStates: RegionStatesDTO[] = [];
  drpCities: RegionCitiesDTO[] = [];
  drpCountry: RegionCountriesDTO[] = [];
  physicianForm: FormGroup;
  isStateTouched = false;
  CityTypeControl = new FormControl();
  StateTypeControl = new FormControl();
  CountryTypeControl = new FormControl();
  Country: string = "";
  State: string = "";
  City: string = "";
  stateName: string = "";
  CountryName: string = "";
  subscription$: Subscription[] = [];
  public filteredStates: Observable<RegionStatesDTO[]> | undefined;
  public filteredCities: Observable<RegionCitiesDTO[]> | undefined;
  public filteredCountries: Observable<RegionCountriesDTO[]> | undefined;

  @HostListener('input', ['$event']) onInput(event: Event) {
    const input = event.target as HTMLInputElement;

    // Check if the input field has the class 'zip-code-input'
    if (input.classList.contains('zip-code-input')) {
      let formattedValue = input.value.replace(/[^0-9]/g, '');

      if (formattedValue.length > 5) {
        formattedValue = formattedValue.slice(0, 5) + '-' + formattedValue.slice(5);
      }

      input.value = formattedValue;
    }
  }

  constructor(
    private fb: FormBuilder,
    private region: RegionDropdownService,
    private regionDropdownService: RegionDropdownService,
    private doctorService: DoctorService,
    private toastr: ToastrService,
    private practiceManagementService: PracticeManagementService,
    public physicianDialogRef: MatDialogRef<UpdatePracticePhysicianComponent>,

    @Inject(MAT_DIALOG_DATA)
    public data: {
      physicianid: string;
    } = {
        physicianid: '',
      },

  ) { }

  ngOnInit(): void {

    this.physicianForm = this.fb.group({
      txtNpiNumber: new FormControl({ value: '', disabled: true }),
      txtDoctorName: new FormControl({ value: '', disabled: true }),
      txtphyAddress: new FormControl(''),

      drpCountry: new FormControl(defaultCountry.US),

      drpState: new FormControl(""),
      drpStateFilter: new FormControl(""),

      drpCity: new FormControl(""),
      drpCityFilter: new FormControl(""),



      txtZipCode: new FormControl(''),
    });
    this.countryDropdown();

    if (this.data) {
      this.doctorId = this.data?.physicianid;
    }

    if (this.doctorId) {
      this.loadDoctorDetails();
    }
  }


  // On Change State Dropdown
  onChangeState(value: string | null | undefined): boolean {
    this.physicianForm.patchValue({
      drpState: null,
      drpCity: null
    })
    this.drpStates = [];
    this.drpCities = [];
    value !== null && value !== undefined && this.regionDropdownService.getAllStatesByCountryNameByCountryShortName(value).subscribe(response => {
      this.drpStates = response?.items ?? [];
      // this.phoneCode = response?.items[0]?.countryPhoneCode;
      this.filteredStates = this.physicianForm?.get("drpStateFilter")?.valueChanges
        .pipe(
          startWith(''),
          map(value => this.drpStates.filter(option =>
            option?.stateName?.toLowerCase()?.includes(value?.toLowerCase() ?? "")
          ))
        );
    }, err => {
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    });
    return this.drpStates.length !== 0;
  }

  // Get Country Dropdown
  countryDropdown() {
    this.physicianForm.patchValue({ "drpCountry": defaultCountry.US })
    this.onChangeState(defaultCountry.US);
    this.Country = defaultCountry.US;

    this.drpCountry = [];
    this.regionDropdownService.getAllCountriesByInput(new PagedAndSortedResultRequestDto).subscribe(response => {
      //
      this.drpCountry = response?.items.filter(x => x.countryShortName == "US") ?? [];
      this.filteredCountries = this.physicianForm?.get("txtCountryFilter")?.valueChanges
        .pipe(
          startWith(''),
          map(value => this.drpCountry.filter(option => option?.countryName?.toLowerCase()?.includes(value?.toLowerCase() ?? "")))
        );
    }, err => {
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    });
  }

  // On Change City Dropdown
  onChangeCity(state: string | null | undefined, country: string): boolean {
    this.physicianForm.patchValue({
      drpCity: null
    });
    country = (country == "") ? (this.physicianForm.value.drpCountry == (null || undefined) ? "" : (this.physicianForm.value.drpCountry)) : country;
    this.drpCities = [];
    state !== null && state !== undefined && this.regionDropdownService.getAllCitiesByCountryAndStateNameByCountryShortNameAndStateShortName(country, state).subscribe(response => {

      this.drpCities = response?.items ?? [];

      this.filteredCities = this.physicianForm?.get("drpCityFilter")?.valueChanges
        .pipe(
          startWith(''),
          map(value => this.drpCities.filter(option => option?.cityName?.toLowerCase()?.includes(value?.toLowerCase() ?? "")))
        );
    }, err => {
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    });
    return this.drpCities.length !== 0;
  }

  loadDoctorDetails() {
    const physicianList = this.practiceManagementService.docGetList(this.doctorId).subscribe(
      (response) => {

        this.stateName = response?.state;
        this.CountryName = defaultCountry.US;

        this.CountryName != null && this.CountryName !== "" && this.CountryName != undefined &&
          this.onChangeState(defaultCountry.US) && this.physicianForm.patchValue({
            drpCountry: this.CountryName,
          });
        this.stateName != null && this.stateName !== "" && this.stateName != undefined &&
          this.onChangeCity(this.stateName, defaultCountry.US) && this.physicianForm.patchValue({
            drpState: this.stateName,
            drpCity: response?.city
          });

        this.physicianForm.patchValue({
          txtNpiNumber: response?.npiNumber ?? '',
          txtDoctorName: response?.firstName ?? '',
          txtphyAddress: response?.address ?? '',
          drpCountry: defaultCountry.US,
          drpState: response?.state ?? '',
          drpCity: response?.city ?? '',
          txtZipCode: response?.postalCode ?? '',
        });
        this.physicianForm.markAsDirty();

      },
      (err) => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.message || 'An error occurred while fetching the reminder.',
        });
        this.subscription$.push(physicianList);
      }
    );
  }


  //updatePhysician Address
  updatePhysician() {
    this.isShowSpinner = true;
    const physicianInput: CreateUpdatePracticeDoctorDTO = {
      address: this.physicianForm.value.txtphyAddress ?? '',
      state: this.physicianForm.value.drpState ?? '',
      city: this.physicianForm.value.drpCity ?? '',
      postalCode: this.physicianForm.value.txtZipCode ?? '',
    };
    //Update Physician Address
    this.doctorService.updatePracticeDoctor(this.doctorId, physicianInput).subscribe(
      (response) => {
        const physicianSaved: DoctorDTO = response;
        this.toastr.success('Updated Successfully!', 'Success')
        this.loadDoctorDetails();
        this.reloadTableEvent.emit();
        this.physicianDialogRef.close(physicianSaved);
        this.isShowSpinner = false;
      },
      (err) => {
        this.isShowSpinner = false;
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      }
    );
  }

  resetPhysicain() {
    this.physicianForm.reset();
  }
}
