<!-- <div class="home-btn d-none d-sm-block">
  <a href="/" class="text-dark"><i class="fa fa-home h2"></i></a>
</div> -->
<div class="account-pages mt-5 mb-5">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-md-8 col-lg-6 col-xl-5">
        <div class="card overflow-hidden">
          <div class="bg-soft-primary">
            <div class="col-12">
              <div class="text-clr p-4">
                <h6 class="text-clr">Welcome to Qsecure!</h6>
                <!-- <p class="text-clr">For Your Protection,please verify your identity</p> -->
              </div>
            </div>

          </div>
          <div class="card-body pt-0">
            <div class="col-md-12">
              <div class="row">
                <div class="col-12 text-center">
                  <img src="assets/images/rnd.jpg" alt="" height="100">
                </div>
              </div>
            </div>
            <h4 class="text-center btm">Update Your Password</h4>
            <form [formGroup]="resetForm">
              <div class="p-2">
                <mat-card-content>
                  <mat-form-field class="col-12 pt-1">
                    <mat-label>New Password</mat-label>
                    <input autocomplete="new-password" matInput maxlength="40" minlength="6" formControlName="txtPassword" [type]="showPassword ? 'text' : 'password'"
                    pattern="(?=.*\d)(?=.*[a-z])(?=.*[$@$#!%*?^()&])(?=.*[A-Z]).{8,}" >
                    <mat-icon matSuffix (click)="togglePasswordVisibility()">
                      {{showPassword?'visibility_off':'visibility'}}
                  </mat-icon>
                  <mat-error *ngIf="
                  resetForm?.get('txtPassword')?.touched &&
                  resetForm?.get('txtPassword')?.errors?.required
                    ">
                    Password is a required field!
                  </mat-error>
                  <mat-error *ngIf="
                  resetForm?.get('txtPassword')?.touched &&
                  resetForm?.get('txtPassword')?.errors?.pattern
                        ">
                    Passwords must contain at least one non alphanumeric character,one uppercase,one digit
                    and at
                    least 8 or more characters
                  </mat-error>
                  </mat-form-field>
                  <mat-form-field class="col-12 mt-3">
                    <mat-label>Confirmed Password</mat-label>
                    <input autocomplete="new-password" matInput maxlength="40" minlength="6" formControlName="txtConfirmPassword" [type]="showConfirmPassword ? 'text' : 'password'">
                    <mat-icon matSuffix (click)="confirmPasswordPasswordVisibility()">
                      {{showConfirmPassword?'visibility_off':'visibility'}}
                  </mat-icon>
                    <mat-error *ngIf="
                    resetForm?.get('txtConfirmPassword')?.touched &&
                    resetForm?.get('txtConfirmPassword')?.errors?.required ">
                    Confirm Password is a required field!
                  </mat-error>
                  <mat-error *ngIf="resetForm.get('txtConfirmPassword').hasError('passwordMismatch')">Password and Confirm Password does not match</mat-error>

                  <!-- <mat-error *ngIf="resetForm?.get('txtConfirmPassword').pristine ||
                  resetForm?.get('txtConfirmPassword')?.errors?.MatchPassword">Password and Confirm Password does not match</mat-error> -->

                  </mat-form-field>
                </mat-card-content>
                <div class="row">
                  <div class="col-6 mt-4 link text-left">
                    <i class="fa fa-arrow-circle-left fa-2x ml-3" (click)="backToLogin()" ></i>
                  </div>
                  <div class="col-6 mt-4 text-right">
                    <button mat-button style="width: 71px !important" class="buttonColor btn-block mb-2 mr-2" [disabled]="resetForm.invalid"
                      (click)="submit()" type="submit">Ok
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>


      </div>
    </div>
  </div>
  <!-- end container -->
</div>
<!-- end page -->
