<div class="container-fluid">
  <div class="row bg">
    <div class="col-12">
      <div class="d-flex align-items-center justify-content-between">
        <h3 class="font-weight-bolder mb-0">
          <b class="customThemeClass page-header-breadcrumb">Order / Order View</b>
        </h3>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-md-4 col-lg-3 col-xl-2 mt-2">
      <!-- <div class="d-flex justify-content-center mb-3 align-items-center"> -->
        <!-- <div class="card card-bordy w-100 h-100 mt-2 p-1 curved-card"> -->
          <div class="card d-flex justify-content-center pl-1 overflow-hidden curved-card" style="height: 85vh;width:16vw">
            <div class="scrollable-content">
          <!-- Order Details -->
          <div class="mt-2">
            <div class="d-flex flex-row pb-2">
              <p class="header-text mb-0 pr-1">
                <img class="img-responsive" src="assets/Billing Icons/Order Details.png" height="20" />
                Order Details
              </p>
              <!-- [matTooltip]="orderDetails?.orderStatus || '-'"  d-flex align-items-center justify-content-center-->
            </div>

            <div class="d-flex justify-content-between">
              <div class="pl-1 pb-1">
                <b class="text-min mb-0">Order No:</b> <br />
                <span class="heading-boady mb-0 ellipsis" [matTooltip]="orderDetails?.orderNo   || '-'">
                  <a [class.disabled]="isEmpty(orderDetails?.patientId) || isEmpty(orderDetails?.orderId)"
                    class="preview-link "
                    [routerLink]="'/orders/saleOrder/' + orderDetails?.patientId + '/' + orderDetails?.orderId+'/0'"
                    target="_blank">{{ orderDetails?.orderNo || '-' }}</a>
                </span>
              </div>
              <div class="pr-1 pb-1">
                <b class="text-min mb-0">Created:</b> <br />
                <mat-label [matTooltip]="orderDetails?.createdDate || '-'" class="dd-header-text">{{
                  orderDetails?.createdDate || "-" }}</mat-label>
              </div>
            </div>
            <!-- (click)="getOrderHistory()" -->
            <span
              class="rounded-border d-flex justify-content-center align-items-center p-1 claim-status-label">
              {{ orderDetails?.orderStatus || "-" }}
            </span>
            <!-- <div class="pl-1 pb-1">
              <b class="text-min mb-0">Order No:</b> <br />
              <mat-label
                [matTooltip]="orderDetails?.orderNo || '-'"
                class="dd-header-text"
                >{{ orderDetails?.orderNo || "-" }}</mat-label
              >
            </div>

            <div class="pl-1 pb-1">
              <b class="text-min mb-0">Created:</b> <br />
              <mat-label
                [matTooltip]="orderDetails?.createdDate || '-'"
                class="dd-header-text"
                >{{ orderDetails?.createdDate || "-" }}</mat-label
              >
            </div> -->

            <div class="d-flex justify-content-between mt-1 pb-1">
              <span class="text-center w-50 order-type-label mr-1" [matTooltip]="
                  orderDetails?.newSetup === 1 ? 'Setup' : 'Supplies'
                ">
                <ng-container *ngIf="orderDetails?.newSetup === 1">
                  <mat-icon mat-icon-button class="ico-small align-middle text-center">settings</mat-icon>
                  Setup
                </ng-container>

                <ng-container *ngIf="orderDetails?.newSetup !== 1">
                  <img style="cursor: pointer" class="img-responsive pb-1 pr-1" src="assets/Billing Icons/Supplies.png"
                    height="20" />Supplies
                </ng-container>
              </span>
              <span (click)="
                  orderDetails?.isRental
                    ? openRentalDetails()
                    : $event.stopPropagation()
                " class="d-flex align-items-center justify-content-center w-50 order-type-label cursor-pointer"
                [matTooltip]="
                  orderDetails?.isRental
                    ? 'Click to View the Rental Details'
                    : 'Purchase'
                ">
                <!-- [matTooltip]="
              orderDetails?.isRental
                ? 'Rental (' + orderDetails?.rentalMonth + ')'
                : 'Purchase'
            " -->
                <ng-container *ngIf="!orderDetails?.isRental">
                  <mat-icon mat-icon-button class="ico-small align-middle text-center">shopping_cart</mat-icon>Purchase
                </ng-container>
                <ng-container *ngIf="orderDetails?.isRental">
                  <img style="cursor: pointer" class="img-responsive pb-1 pr-1" src="assets/Billing Icons/Rental.png"
                    height="15" />Rental ({{ orderDetails?.rentalMonth }})
                </ng-container>
              </span>
              <!-- <span
                class="ml-1 w-75 p-1 tag-status-label"
                matTooltip="Purchase"
              >
                <mat-icon mat-icon-button class="ico-small"
                  >shopping_cart</mat-icon
                >Purchase
              </span>
              <span class="ml-1 w-75 p-1 tag-status-label" matTooltip="Rental">
                <img
                  style="cursor: pointer"
                  class="img-responsive pr-1"
                  src="assets/Billing Icons/Rental.png"
                  height="11"
                />Rental
              </span> -->
            </div>
          </div>
          <mat-divider class="my-1"></mat-divider>

          <!-- Patient Details -->
          <div class="mt-2">
            <p class="header-text mb-0 pb-2">
              <img class="img-responsive" src="assets/Billing Icons/Patient details.png" height="20" />
              Patient Details
            </p>

            <div class="pb-1 pl-1">
              <b class="text-min mb-0">Chart No:</b> <br />
              <mat-label [matTooltip]="orderDetails?.chartId || '-'" class="dd-header-text">
                <span class="cus-text-label mb-0 ellipsis" [matTooltip]="orderDetails?.chartId || '-'">
                  <a [class.disabled]="isEmpty(orderDetails?.patientId)" class="preview-link "
                    [routerLink]="'/patientCreateEditTab/' + orderDetails?.patientId" target="_blank">{{
                    orderDetails?.chartId || '-' }}</a>
                </span>
              </mat-label>
            </div>

            <div class="pl-1">
              <b class="text-min mb-0">Patient Name:</b> <br />
              <mat-label [matTooltip]="orderDetails?.patientFullName || '-'" class="cus-text-label">
                {{ orderDetails?.patientFullName || "-" }}</mat-label>
            </div>
            <div class="pb-1 pl-1 d-flex align-items-center flex-row justify-content-between">
              <mat-label [matTooltip]="orderDetails?.dateOfBirth +' - Age '+ (orderDetails?.age || '0') + ' Years'" class="cus-text-label">{{
                orderDetails?.dateOfBirth }} - Age  {{ orderDetails?.age}}
              </mat-label>

              <i *ngIf="orderDetails?.gender === 'F'" class="fa fa-venus font-weight-bolder mb-2 pr-1" aria-hidden="true"
                [matTooltip]="'Female'"></i>

              <i *ngIf="orderDetails?.gender === 'M'" class="fa fa-mars font-weight-bolder mb-2 pr-1" aria-hidden="true"
                [matTooltip]="'Male'"></i>
              <!--
              <mat-icon *ngIf="orderDetails?.gender === 'F'" [matTooltip]="'Female'"
                class="mt-0 gender-icon-button">female</mat-icon>
              <mat-icon *ngIf="orderDetails?.gender === 'M'" class="mt-0 gender-icon-button"
                [matTooltip]="'Male'">male</mat-icon> -->
            </div>

            <div class="pl-1 pb-1 d-flex justify-content-between">
              <b class="text-min mb-0">Delivery Address:</b> <i class="fa fa-map-marker pr-2 mt-1"
                aria-hidden="true"></i>
            </div>

            <div class="d-flex justify-content-between pl-1 pb-1">
              <mat-label [matTooltip]="orderDetails?.addressDescription || '-'" class="cus-text-label">{{
                orderDetails?.addressDescription || "-" }}
              </mat-label>
            </div>


            <div class="pl-1 pb-1 d-flex justify-content-between">
              <b class="text-min mb-0">Cell No:</b> <i class="fa fa-phone pr-1" aria-hidden="true"></i>
            </div>

            <div class="d-flex justify-content-between pl-1 pb-1">
              <mat-label [matTooltip]="orderDetails?.phoneNo || '-'" class="cus-text-label">{{ orderDetails?.phoneNo ||
                "-" }}</mat-label>
            </div>


            <div class="pl-1 pb-1 d-flex justify-content-between">
              <b class="text-min mb-0">Email:</b><i class="fa fa-envelope mr-1 iconfont" aria-hidden="true"></i>
            </div>

            <div class="d-flex justify-content-between pl-1 pb-1">
              <mat-label [matTooltip]="orderDetails?.patientemail || '-'" class="cus-text-label">
                {{ orderDetails?.patientemail || "-" }}
              </mat-label>
            </div>


            <div class="pl-1 pb-1 d-flex justify-content-between">
              <b class="text-min mb-0">Fax Location:</b> <i class="fa fa-map-marker pr-2" aria-hidden="true"></i>
            </div>

            <div class="d-flex justify-content-between pl-1 pb-1">
            <mat-label [matTooltip]="(orderDetails?.location | titlecase) || '-'" class="cus-text-label">
              {{ (orderDetails?.location | titlecase) || "-" }}
            </mat-label>
            </div>

          </div>

          <mat-divider class="my-1"></mat-divider>
          <!-- Referring Provider -->
          <div class="mt-2">
            <p class="header-text mb-0 pb-2">
              <img class="img-responsive" src="assets/Billing Icons/Referring Provider.png" height="20" />
              Referring Provider
            </p>

            <div class="pl-1 pb-1">
              <b class="text-min mb-0">Name and Address:</b> <br />
              <mat-label
                [matTooltip]="((orderDetails?.referringDoctorName | titlecase) || '-') + '' + (orderDetails?.referringDoctorAddress | titlecase) || '-'"
                class="cus-text-label">
                {{ (orderDetails?.referringDoctorName | titlecase)|| "-" }}<br />
                {{ (orderDetails?.referringDoctorAddress | titlecase)|| "-" }}
              </mat-label>
            </div>
            <div class="pl-1 pb-1">
              <b class="text-min mb-0">NPI:</b> <br />
              <mat-label [matTooltip]="orderDetails?.referringNPINo || '-'" class="cus-text-label">{{
                orderDetails?.referringNPINo || "-" }}
              </mat-label>
            </div>

            <div class="pl-1 pb-1">
              <b class="text-min mb-0">Phone:</b> <br />
              <mat-label [matTooltip]="orderDetails?.referringDoctorPhoneNo || '-'" class="cus-text-label">{{
                orderDetails?.referringDoctorPhoneNo || "-" }}
              </mat-label>
            </div>
          </div>

          <div class="pl-1 pb-1">
            <b class="text-min mb-0">Billing Provider:</b> <br />
            <mat-label
              [matTooltip]="((orderDetails?.providerName | titlecase) || '-') + '' + (orderDetails?.provideraddress | titlecase) || '-'"
              class="cus-text-label">
              {{ (orderDetails?.providerName | titlecase)|| "-" }}<br />
              {{ (orderDetails?.provideraddress | titlecase)|| "-" }}
            </mat-label>
          </div>
          <div class="pl-1 pb-1">
            <b class="text-min mb-0">NPI:</b> <br />
            <mat-label [matTooltip]="orderDetails?.providerNpi || '-'" class="cus-text-label">{{
              orderDetails?.providerNpi || "-" }}
            </mat-label>
          </div>

          <div class="pl-1 pb-1">
            <b class="text-min mb-0">Phone:</b> <br />
            <mat-label [matTooltip]="orderDetails?.phoneNumber || '-'" class="cus-text-label">{{
              orderDetails?.phoneNumber || "-" }}
            </mat-label>
          </div>
          <mat-divider class="my-1"></mat-divider>
          <!-- Insurance -->
          <div class="w-100 mt-1 p-1 mb-2">
            <div class="d-flex justify-content-between align-items-center header-text">
              <p class="header-text mb-0 pb-2">
                <img class="img-responsive" src="assets/Billing Icons/Insurance.png" height="20" />
                Insurance
              </p>
            </div>

            <!--Primary Insurance-->
            <mat-expansion-panel [expanded]="step" class="exapnsion-panel">
              <mat-expansion-panel-header>
                <mat-panel-title>
                  <span class="cus-mat-header-label">Primary Insurance</span>
                </mat-panel-title>
              </mat-expansion-panel-header>
              <div class="d-flex justify-content-between align-items-center cus-text-label">
                <mat-label [matTooltip]="(orderDetails?.insuranceName | titlecase ) || '-'" class="cus-text-label">
                  <ng-container *ngIf="orderDetails?.insuranceId !== defaultGuid && !orderDetails?.cashOrder">
                    <a (click)="
                  openVerification(
                    orderDetails?.insuranceId,
                    orderDetails?.verificationId
                  )
                " class="preview-link cursor-pointer cus-text-label">
                      {{ (orderDetails?.insuranceName | titlecase ) || "-" }}
                    </a>
                  </ng-container>
                  <ng-container
                    *ngIf="orderDetails?.insuranceId === defaultGuid || orderDetails?.cashOrder">-</ng-container>
                </mat-label>
                <!-- <i class="fa fa-info-circle iconclr"
                  [matTooltip]="'Verified : ' + (orderDetails?.verificationDate) + ' ' + (orderDetails?.verifiedBy ||'-' | titlecase) || '-'"></i> -->

                  <i class="fa fa-info-circle iconclr" *ngIf="orderDetails?.insuranceId !== defaultGuid && !orderDetails?.cashOrder" [matTooltip]="(orderDetails?.isPrimaryVerificationDone) ?
                  'Verified : ' + (orderDetails?.verificationDate) + ' ' + (orderDetails?.verifiedBy ||'-' | titlecase) || '-' :
                  'Not Verified'">
                  </i>

                  <!-- <img class="img-responsive" src="assets/Dashboard New/Real-Transparent-pVerify-logo.png" height="18"/> -->
              </div>
              <div *ngIf="
            orderDetails?.insuranceId !== defaultGuid &&
            !orderDetails?.cashOrder
          " class="pb-2">
                <b class="text-min mb-0">Policy No:</b> <br />
                <mat-label [matTooltip]="orderDetails?.policNo || '-'" class="cus-text-label">
                  {{ orderDetails?.policNo || "-" }}
                </mat-label>
              </div>

            </mat-expansion-panel>

            <!--Secondary Insurance-->
            <mat-expansion-panel [expanded]="step" class="exapnsion-panel" *ngIf="orderDetails?.secondaryPolicyId !== defaultGuid">
              <mat-expansion-panel-header>
                <mat-panel-title>
                  <span class="cus-mat-header-label">Secondary Insurance</span>
                </mat-panel-title>
              </mat-expansion-panel-header>
              <div class="d-flex justify-content-between align-items-center cus-text-label">
                <mat-label [matTooltip]="(orderDetails?.secondarypolicyname | titlecase) || '-'" class="cus-text-label">
                  <ng-container *ngIf="orderDetails?.secondaryPolicyId !== defaultGuid &&!orderDetails?.cashOrder">
                    <a (click)="
                  openVerification(
                    orderDetails?.secondaryPolicyId,
                    orderDetails?.secondaryVerificationId
                  )
                " class="preview-link cus-text-label cursor-pointer">
                      {{ (orderDetails?.secondarypolicyname | titlecase) || "-" }}
                    </a>
                  </ng-container>

                  <ng-container *ngIf="
                orderDetails?.secondaryPolicyId === defaultGuid ||
                orderDetails?.cashOrder
              ">-</ng-container>
                </mat-label>
                <i class="fa fa-info-circle iconclr" *ngIf="orderDetails?.insuranceId !== defaultGuid && !orderDetails?.cashOrder" [matTooltip]="(orderDetails?.isSecondaryVerificationDone) ?
                'Verified : ' + (orderDetails?.secondaryVerificationVerifiedOn) + ' ' + (orderDetails?.secondaryVerificationVerifiedBy ||'-' | titlecase) || '-' :
                'Not Verified'">
                </i>
              </div>
              <div class="pb-1" *ngIf="
            orderDetails?.secondaryPolicyId !== defaultGuid &&
            !orderDetails?.cashOrder
          ">
                <b class="text-min mb-0">Policy No:</b> <br />
                <mat-label [matTooltip]="orderDetails?.secondaryPolicyNo || '-'" class="cus-text-label">
                  {{ orderDetails?.secondaryPolicyNo || "-" }}
                </mat-label>
              </div>

            </mat-expansion-panel>
          </div>


          <!-- <div class="mt-2">
            <p class="header-text mb-0 pb-2">
              <img class="img-responsive" src="assets/Billing Icons/Insurance.png" height="20" />
              Insurance
            </p>

            <div class="pl-1 pb-1">
              <div class="d-flex justify-content-between align-items-center">
                <b class="text-min mb-0">Primary Insurance</b>
              </div>
              <mat-label [matTooltip]="
                  orderDetails?.verifiedBy != null &&
                  orderDetails?.insuranceId !== defaultGuid &&
                  !orderDetails?.cashOrder
                    ? 'Verified on ' +
                      orderDetails?.verificationDate +
                      ' by ' +
                      ' ' +
                      orderDetails?.verifiedBy
                    : '-'
                " class="dd-header-text">
                <ng-container *ngIf="
                    orderDetails?.insuranceId !== defaultGuid &&
                    !orderDetails?.cashOrder
                  ">
                  <a (click)="
                      openVerification(
                        orderDetails?.insuranceId,
                        orderDetails?.verificationId
                      )
                    " [matTooltip]="
                      'Verified on ' +
                      orderDetails?.verificationDate +
                      ' by ' +
                      ' ' +
                      orderDetails?.verifiedBy
                    " class="preview-link bolder cursor-pointer">
                    {{ orderDetails?.insuranceName || "-" }}
                  </a>
                </ng-container>
                <ng-container *ngIf="
                    orderDetails?.insuranceId === defaultGuid ||
                    orderDetails?.cashOrder
                  ">-</ng-container>
              </mat-label>
            </div>

            <div *ngIf="
                orderDetails?.insuranceId !== defaultGuid &&
                !orderDetails?.cashOrder
              " class="pl-1 pb-2">
              <b class="text-min mb-0">Policy No:</b> <br />
              <mat-label [matTooltip]="orderDetails?.policNo || '-'" class="dd-header-text">
                {{ orderDetails?.policNo || "-" }}
              </mat-label>
            </div>

            <div class="pl-1 pb-1">
              <div class="d-flex justify-content-between align-items-center">
                <b class="text-min mb-0">Secondary Insurance</b>
              </div>

              <mat-label [matTooltip]="
                  orderDetails?.secondaryVerificationVerifiedBy != null &&
                  orderDetails?.secondaryPolicyId !== defaultGuid &&
                  !orderDetails?.cashOrder
                    ? 'Verified on ' +
                      orderDetails?.secondaryVerificationVerifiedOn +
                      ' by ' +
                      ' ' +
                      orderDetails?.secondaryVerificationVerifiedBy
                    : '-'
                " class="dd-header-text">
                <ng-container *ngIf="
                    orderDetails?.secondaryPolicyId !== defaultGuid &&
                    !orderDetails?.cashOrder
                  ">
                  <a (click)="
                      openVerification(
                        orderDetails?.secondaryPolicyId,
                        orderDetails?.secondaryVerificationId
                      )
                    " class="preview-link bolder cursor-pointer">
                    {{ orderDetails?.secondarypolicyname || "-" }}
                  </a>
                </ng-container>

                <ng-container *ngIf="
                    orderDetails?.secondaryPolicyId === defaultGuid ||
                    orderDetails?.cashOrder
                  ">-</ng-container>
              </mat-label>
            </div>

            <div class="pl-1 pb-1" *ngIf="
                orderDetails?.secondaryPolicyId !== defaultGuid &&
                !orderDetails?.cashOrder
              ">
              <b class="text-min mb-0">Policy No:</b> <br />
              <mat-label [matTooltip]="orderDetails?.secondaryPolicyNo || '-'" class="dd-header-text">
                {{ orderDetails?.secondaryPolicyNo || "-" }}
              </mat-label>
            </div>
          </div> -->
        </div>
      </div>
    </div>
    <div class="col-md-8 col-lg-9 col-xl-10 mt-1">
      <div class="col-12 px-0">
        <div class="card card-body mt-1 p-2 curved-card card-header cardCustomCls">
          <div class="d-flex justify-content-between">
            <!-- <div class="col-2">
                <mat-icon>monetization_on</mat-icon>
              </div> -->
            <!-- <mat-divider [vertical]="true"></mat-divider> -->
            <div class="col-1.5">
              <p class="mb-1 amnt-header">Order Amount</p>
              <!-- <b [matTooltip]="'$' + orderDetails?.orderAmount">${{ orderDetails?.orderAmount }}</b> -->
              <span [matTooltip]="'$' + orderDetails?.orderAmount" class="text-currency"> <i class="fa fa-usd dolloricon"
                  aria-hidden="true"></i> {{ orderDetails?.orderAmount }}</span>
            </div>
            <mat-divider [vertical]="true"></mat-divider>
            <div class="col-1.5">
              <p class="mb-1 amnt-header">Allowed</p>
              <!-- <b [matTooltip]="'$' + orderDetails?.allowedAmount">${{ orderDetails?.allowedAmount }}</b> -->
              <span [matTooltip]="'$' + orderDetails?.allowedAmount" class="text-currency"> <i class="fa fa-usd dolloricon"
                  aria-hidden="true"></i> {{ orderDetails?.allowedAmount }}</span>
            </div>
            <mat-divider [vertical]="true"></mat-divider>
            <div class="col-1.5">
              <p class="mb-1 amnt-header">Accessory</p>
              <!-- <b [matTooltip]="'$' + orderDetails?.accessory">${{ orderDetails?.accessory }}</b> -->
              <span [matTooltip]="'$' + orderDetails?.accessory" class="text-currency"> <i class="fa fa-usd dolloricon"
                  aria-hidden="true"></i> {{ orderDetails?.accessory }}</span>
            </div>
            <mat-divider [vertical]="true"></mat-divider>
            <div class="col-1.5">
              <p class="mb-1 amnt-header">Discount</p>
              <!-- <b [matTooltip]="'$' + orderDetails?.totDiscount">${{ orderDetails?.totDiscount }}</b> -->
              <span [matTooltip]="'$' + orderDetails?.totDiscount" class="text-currency"> <i class="fa fa-usd dolloricon"
                  aria-hidden="true"></i> {{ orderDetails?.totDiscount }}</span>
            </div>
            <mat-divider [vertical]="true"></mat-divider>
            <div class="col-1.5">
              <p class="mb-1 amnt-header">Est. Copay</p>
              <!-- <b [matTooltip]="'$' + orderDetails?.estcopay">${{ orderDetails?.estcopay }}</b> -->
              <span [matTooltip]="'$' + orderDetails?.estcopay" class="text-currency"> <i class="fa fa-usd dolloricon"
                  aria-hidden="true"></i> {{ orderDetails?.estcopay }}</span>
            </div>
            <mat-divider [vertical]="true"></mat-divider>
            <div class="col-1.5">
              <p class="mb-1 amnt-header">Est. Amount Due</p>
              <!-- <b [matTooltip]="'$' + orderDetails?.estAmountDue">${{ orderDetails?.estAmountDue }}</b> -->
              <span [matTooltip]="'$' + orderDetails?.estAmountDue" class="text-currency"> <i class="fa fa-usd dolloricon"
                  aria-hidden="true"></i> {{ orderDetails?.estAmountDue }}</span>
            </div>
            <mat-divider [vertical]="true"></mat-divider>
            <div class="col-1.5 mr-2">
              <p class="mb-1 amnt-header">Shipping Fee</p>
              <span [matTooltip]="'$' + shippingFee" class="text-currency"> <i class="fa fa-usd dolloricon"
                  aria-hidden="true"></i> {{ shippingFee }}</span>
            </div>
          </div>
        </div>
      </div>

      <div class="d-flex justify-content-center mb-3 mt-1 align-items-center">
        <div class="card card-bordy w-100 h-100 mt-2 p-3 curved-card">
          <div class="row">
            <div class="col-12 d-flex justify-content-between">
              <div class="row">
                <div class="col-12 pb-2 d-flex justify-content-between">
                  <b class="header-text align-middle text-center">
                    <img class="img-responsive pr-1 pb-1" src="assets/Billing Icons/Order Info.png" height="25" />Order
                    Info
                    <ng-container *ngIf="orderDetails?.cashOrder">
                      -
                      <span class="rounded-border p-1 claim-status-label">
                        Cash Order
                      </span>
                    </ng-container>
                  </b>

                  <!-- <mat-icon mat-icon-button matTooltip="Click to View Options" [matMenuTriggerFor]="menu"
                    class="cursor-pointer">more_vert</mat-icon> -->
                  <mat-menu class="custom-mat-menu" #menu="matMenu">
                    <button (click)="openAddNotesPopUp()" mat-menu-item>
                      Add Patient Notes
                    </button>
                    <button (click)="openViewNotesPopup()" mat-menu-item>
                      View Patient Notes
                    </button>
                    <button (click)="viewPatientLedgerPopup()" mat-menu-item>
                      View Patient Ledger
                    </button>
                    <button (click)="getPrintTicketDetails()" mat-menu-item>
                      Print Ticket
                    </button>
                    <a [class.disabled]="
                        this.orderDetails?.inboundId == '' ||
                        this.orderDetails?.inboundId == null
                      " [routerLink]="
                        '/view_document_tiff/' +
                        orderDetails?.inboundId +
                        '/' +
                        true
                      " target="_blank" class="preview-link">
                      <button [disabled]="
                          this.orderDetails?.inboundId == '' ||
                          this.orderDetails?.inboundId == null
                        " mat-menu-item>
                        View Document
                      </button>
                    </a>
                  </mat-menu>
                </div>

                <div class="col-3 pb-1">
                  <b class="text-min mb-0">Order Date:</b> <br />
                  <mat-label [matTooltip]="orderDetails?.orderDate || '-'" class="dd-header-text">{{
                    orderDetails?.orderDate }}</mat-label>
                </div>

                <div class="col-3 pb-1">
                  <b class="text-min mb-0">RX Date:</b> <br />
                  <div class="d-flex flex-row align-items-center">
                    <mat-label [matTooltip]="orderDetails?.rxDate || '-'" class="dd-header-text">{{ orderDetails?.rxDate
                      || "-" }}
                    </mat-label>
                    <mat-icon *ngIf="orderDetails?.rxDocuments?.length > 0" [matTooltip]="'Click to View RX Document'"
                      class="pl-1 eye-icon-button" (click)="viewRXDocument()" mat-icon-button>remove_red_eye</mat-icon>
                  </div>
                </div>

                <div class="col-3 pb-1">
                  <b class="text-min mb-0">DOS:</b> <br />
                  <mat-label [matTooltip]="orderDetails?.dos || '-'" class="dd-header-text">{{ orderDetails?.dos || "-"
                    }}</mat-label>
                </div>

                <div class="col-3 pb-1">
                  <b class="text-min mb-0">Auth Id:</b> <br />

                  <div class="d-flex flex-row align-items-center">
                    <mat-label [matTooltip]="orderDetails?.authNo || '-'" class="dd-header-text">{{ orderDetails?.authNo
                      || "-" }}
                    </mat-label>
                    <mat-icon *ngIf="
                        !(
                          orderDetails?.authNo === '' ||
                          orderDetails?.authNo === null ||
                          orderDetails?.authNo === undefined
                        )
                      " class="pl-1 eye-icon-button" (click)="
                        viewAuthorization(
                          orderDetails?.authId,
                          orderDetails?.verificationId
                        )
                      " mat-icon-button>remove_red_eye</mat-icon>
                  </div>
                </div>

                <div class="col-3 pb-1">
                  <b class="text-min mb-0">Checklist:</b> <br />

                  <div class="d-flex flex-row align-items-center">
                    <mat-label [matTooltip]="orderDetails?.medicareName || '-'" class="dd-header-text">{{
                      orderDetails?.medicareName || "-" }}</mat-label>

                    <a *ngIf="
                        !(
                          orderDetails?.medicareChecklistId === defaultGuid ||
                          orderDetails?.medicareChecklistId === null
                        )
                      " [routerLink]="
                        '/view_checklist/' +
                        patientId +
                        '/' +
                        true +
                        '/' +
                        true +
                        '/' +
                        orderDetails?.medicareChecklistId
                      " target="_blank" class="checklist-view-button" mat-icon-button>
                      <mat-icon class="pl-1 eye-icon-button">remove_red_eye</mat-icon>
                    </a>
                  </div>
                </div>

                <div class="col-3 pb-1">
                  <b class="text-min mb-0">CSR:</b> <br />
                  <mat-label [matTooltip]="orderDetails?.csrName || '-'" class="dd-header-text">{{ orderDetails?.csrName
                    || "-" }}</mat-label>
                </div>

                <div class="col-3 pb-1">
                  <b class="text-min mb-0">Shipping Type:</b> <br />
                  <mat-label [matTooltip]="orderDetails?.deliveryType || '-'" class="dd-header-text">{{
                    orderDetails?.deliveryType || "-" }}</mat-label>
                </div>

                <div class="col-3 pb-1">
                  <b class="text-min mb-0">Location:</b> <br />
                  <mat-label [matTooltip]="orderDetails?.location || '-'" class="dd-header-text">{{
                    orderDetails?.location || "-" }}</mat-label>
                </div>
                <div class="col-3 pb-1">
                  <b class="text-min mb-0">Service Location:</b> <br />
                  <!-- <mat-label [matTooltip]="
                      (orderDetails?.serviceLocation || '-') +
                      ' (' +
                      orderDetails?.providerNPINo +
                      ')'
                    " class="dd-header-text">{{ orderDetails?.serviceLocation }} ({{
                    orderDetails?.providerNPINo
                    }})</mat-label> -->

                  <!-- <mat-label [matTooltip]="(orderDetails?.serviceLocation || '-') +' (' +(orderDetails?.providerNPINo || '') +')'
                  " class="dd-header-text">
                  {{ orderDetails?.serviceLocation }} ({{ orderDetails?.providerNPINo || '' }})
                  </mat-label> -->

                  <mat-label [matTooltip]="orderDetails?.providerNPINo ? (orderDetails?.serviceLocation || '-') + ' (' +(orderDetails?.providerNPINo || '') +')':(orderDetails?.serviceLocation || '-')
                  " class="dd-header-text">
                    {{ orderDetails?.serviceLocation }}
                    <span *ngIf="orderDetails?.providerNPINo">
                      ({{ orderDetails?.providerNPINo }})
                    </span>
                  </mat-label>

                </div>

                <div class="col-3 pb-1">
                  <b class="text-min mb-0">Scheduled:</b> <br />
                  <mat-label [matTooltip]="
                      orderDetails?.scheduleDate != '-'
                        ? (orderDetails?.scheduleDate || '-') +
                          ' by ' +
                          (orderDetails?.scheduleName || 'System')
                        : '-'
                    " class="dd-header-text">
                    <ng-container *ngIf="orderDetails?.scheduleDate != '-'">
                      {{ orderDetails?.scheduleDate }} by
                      {{ orderDetails?.scheduleName || "System" }}
                    </ng-container>

                    <ng-container *ngIf="orderDetails?.scheduleDate == '-'">
                      -
                    </ng-container>
                  </mat-label>
                </div>

                <div class="col-3 pb-1">
                  <b class="text-min mb-0">Created:</b> <br />
                  <mat-label [matTooltip]="
                      orderDetails?.ordercreateddate != '-'
                        ? (orderDetails?.ordercreateddate || '-') +
                          ' by ' +
                          (orderDetails?.createdBy || 'System')
                        : '-'
                    " class="dd-header-text">
                    <ng-container *ngIf="orderDetails?.ordercreateddate != '-'">
                      {{ orderDetails?.ordercreateddate }} by
                      {{ orderDetails?.createdBy || "System" }}
                    </ng-container>

                    <ng-container *ngIf="orderDetails?.ordercreateddate == '-'">
                      -
                    </ng-container>
                  </mat-label>
                </div>

                <div class="col-3 pb-1">
                  <b class="text-min mb-0">Last Modified:</b> <br />
                  <mat-label [matTooltip]="
                      orderDetails?.modifiedDate != '-'
                        ? (orderDetails?.modifiedDate || '-') +
                          ' by ' +
                          (orderDetails?.modifiedBy || 'System')
                        : '-'
                    " class="dd-header-text">
                    <ng-container *ngIf="orderDetails?.modifiedDate != '-'">
                      {{ orderDetails?.modifiedDate }} by
                      {{ orderDetails?.modifiedBy || "System" }}
                    </ng-container>

                    <ng-container *ngIf="orderDetails?.modifiedDate == '-'">
                      -
                    </ng-container>
                  </mat-label>
                </div>

                <div class="col-12 my-2">
                  <mat-divider></mat-divider>
                </div>
                <!-- <div class="col-3"></div> -->
                <div class="col-12 pb-2">
                  <b class="header-text align-middle text-center">
                    <img class="img-responsive pr-1 pb-1" src="assets/Billing Icons/Referring Provider.png"
                      height="25" />Practice Management</b>
                </div>

                <div class="col-3 pb-1">
                  <b class="text-min mb-0">Practice:</b> <br />
                  <mat-label [matTooltip]="orderDetails?.practiceName || '-'" class="dd-header-text">{{
                    orderDetails?.practiceName || "-" }}</mat-label>
                </div>
                <div class="col-3 pb-1">
                  <b class="text-min mb-0">Fax:</b> <br />
                  <mat-label [matTooltip]="orderDetails?.practiceFax || '-'" class="dd-header-text">{{
                    orderDetails?.practiceFax || "-" }}</mat-label>
                </div>
                <div class="col-3 pb-1">
                  <b class="text-min mb-0">Email:</b> <br />
                  <mat-label [matTooltip]="orderDetails?.practiceEmail || '-'" class="dd-header-text">{{
                    orderDetails?.practiceEmail || "-" }}</mat-label>
                </div>

                <div class="col-12 my-2">
                  <mat-divider></mat-divider>
                </div>
                <div class="col-12 pb-2">
                  <b class="header-text align-middle text-center">
                    <img class="img-responsive pr-1 pb-1" src="assets/Billing Icons/Comments.png"
                      height="25" />Comments</b>
                </div>

                <div class="col-3 pb-1">
                  <b class="text-min mb-0">Patient Comments:</b> <br />
                  <mat-label [matTooltip]="orderDetails?.commentsToPatient || '-'" class="dd-header-text clamped-label">
                    {{ orderDetails?.commentsToPatient || "-" }}</mat-label>
                </div>

                <div class="col-3 pb-1">
                  <b class="text-min mb-0">Shipping Comments:</b> <br />
                  <mat-label [matTooltip]="orderDetails?.commentsToShipping || '-'"
                    class="dd-header-text clamped-label">
                    {{ orderDetails?.commentsToShipping || "-" }}</mat-label>
                </div>

                <div class="col-3 pb-1">
                  <b class="text-min mb-0">Billing Comments:</b> <br />
                  <mat-label [matTooltip]="orderDetails?.commentsToBilling || '-'" class="dd-header-text clamped-label">
                    {{ orderDetails?.commentsToBilling || "-" }}</mat-label>
                </div>

                <div class="col-3 pb-1">
                  <b class="text-min mb-0">Tech Comments:</b> <br />
                  <mat-label [matTooltip]="orderDetails?.commentsToTech || '-'" class="dd-header-text clamped-label">
                    {{ orderDetails?.commentsToTech || "-" }}</mat-label>
                </div>

                <div class="col-12 my-2">
                  <mat-divider></mat-divider>
                </div>
              </div>
            </div>
          </div>

          <!-- Ordered Items -->
          <div class="row">
            <div class="col-12 pb-2">
              <b class="header-text align-middle text-center">
                <img class="img-responsive pr-1 pb-1" src="assets/Billing Icons/Ordered Items.png" height="25" />Ordered
                Items
                <ng-container *ngIf="orderDetails?.exchanged || orderDetails?.returned">
                  -
                </ng-container>
                <span *ngIf="orderDetails?.exchanged" class="rounded-border p-1 claim-status-label">
                  Exchange Order - {{ orderDetails?.newOrderMmReferenceId }}
                </span>
                <span *ngIf="orderDetails?.returned" class="rounded-border p-1 claim-status-label">
                  Return - {{ orderDetails?.newOrderMmReferenceId }}
                </span>
              </b>
            </div>
            <div class="col-12 mt-2">
              <div *ngIf="isLoaded" class="table table-responsive">
                <table datatable class="table hover row-border w-100 display" [dtOptions]="dtItemTableOptions">
                  <thead>
                    <tr>
                      <th matTooltip="Product Code">Product Code</th>
                      <th matTooltip="Product Name">Product Name</th>
                      <th matTooltip="Manufacturer">Manufacturer</th>
                      <th matTooltip="Serial No">Serial No</th>
                      <th matTooltip="HCPCS">HCPCS</th>
                      <th matTooltip="Qty">Qty</th>
                      <th matTooltip="Exchange">Exchange</th>
                      <th matTooltip="Return">Return</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let data of orderItemDetails">
                      <td>
                        <div class="d-flex flex-row align-items-center">
                          <button [matTooltip]="'Machine Settings'" type="button" *ngIf="data?.newSetup" mat-icon-button
                            (click)="
                              openMachineSettings(
                                data.itemId,
                                data.categoryName,
                                data.machineSettings
                              )
                            " class="options">
                            <mat-icon class="lightseagreen mb-2">settings</mat-icon>
                          </button>

                          <ng-container [matTooltip]="data.prodCode || '-'">{{
                            data.prodCode || "-"
                            }}</ng-container>
                        </div>
                      </td>
                      <td [matTooltip]="data.itemName || '-'">
                        {{ data.itemName || "-" }}
                      </td>
                      <td [matTooltip]="data.manufactuureName || '-'">
                        {{ data.manufactuureName || "-" }}
                      </td>
                      <td [matTooltip]="data.serialNumber || '-'">
                        {{ data.serialNumber || "-" }}
                      </td>

                      <td class="align-middle">
                        <ng-container [matTooltip]="data.hcpcCode || '-'">
                          {{ data.hcpcCode || "-" }}
                        </ng-container>
                        <img *ngIf="
                            data?.hcpcCode !== '' && data.hcpcCode !== null
                          " mat-icon-button customToolTip (mouseover)="showHCPCS(data?.itemId)"
                          [contentTemplate]="template" style="cursor: pointer" class="img-responsive pl-1"
                          src="assets/Billing Icons/hcpc-info.png" height="18" />
                      </td>
                      <td [matTooltip]="data.quantity || '-'">
                        {{ data.quantity || "-" }}
                      </td>
                      <td [matTooltip]="data.exchanged ? 'Yes' : 'No'">
                        {{ data.exchanged ? "Yes" : "No" }}
                      </td>
                      <td [matTooltip]="data.returned ? 'Yes' : 'No'">
                        {{ data.returned ? "Yes" : "No" }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #template>
  <mat-spinner *ngIf="spinnerEnabled" class="spinner-border-sm" diameter="20"></mat-spinner>
  <div style="display: flex; flex-direction: column" *ngIf="ltHCPCS.length > 0 && !spinnerEnabled">
    <ng-container>
      <p class="mb-0 p-1 error-popup">
        {{ ltHCPCS }}
      </p>
    </ng-container>
  </div>
  <div style="display: flex; flex-direction: column" *ngIf="ltHCPCS.length == 0 && !spinnerEnabled">
    <p class="mb-0 p-1 error-popup">No Data Found</p>
  </div>
</ng-template>

<app-loader-component *ngIf="isDataLoaded"></app-loader-component>
