import { PagedAndSortedResultRequestDto } from '@abp/ng.core';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatRadioChange } from '@angular/material/radio';
import { MatSelectChange } from '@angular/material/select';
import { Store } from '@ngxs/store';
// import { defaultGuid } from 'projects/admin/src/app/document-perviewer/document-perviewer.component';
import { AuthCategoryDTO, AuthVisitsDTO, OrderMasterDropdownDTO, TypeOfPlanDTO } from 'projects/patient/src/app/dropdown-proxy/dropdown-management/dropdowns/dto/order/models';
import { PatientMasterDropdownDTO, SaleOrRentDTO } from 'projects/patient/src/app/dropdown-proxy/dropdown-management/dropdowns/dto/patient/models';
import { OrderMasterDropdownService } from 'projects/patient/src/app/dropdown-proxy/dropdown-management/dropdowns/master-dropdown-service/order-master-dropdown.service';
import { PatientMasterDropdownService } from 'projects/patient/src/app/dropdown-proxy/dropdown-management/dropdowns/master-dropdown-service/patient-master-dropdown.service';
import { OrderDropdowns } from 'projects/patient/src/app/dropdown-proxy/dropdown-management/dropdowns/order-dropdowns.enum';
import { PatientDropdowns } from 'projects/patient/src/app/dropdown-proxy/dropdown-management/dropdowns/patient-dropdowns.enum';
import { TableService } from 'projects/shared/src/app/table.service';
import { NumberValidators } from 'projects/shared/src/app/validations/number-validator';
import { TextValidators } from 'projects/shared/src/app/validations/text-validator';
import { Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import Swal from 'sweetalert2';

import { CreateUpdateItemAuthorizationDTO, ItemAuthorizationDTO, ItemDTO, SaleorderDTO } from '../order-proxy/order-management/order/dto/models';
import { ItemAuthorizationService } from '../order-proxy/order-management/order/item-authorization.service';
import { SaleorderService } from '../order-proxy/order-management/order/saleorder.service';
import { AuthorizationDTO, PolicyDTO } from '../order-proxy/order-management/rcm/patient-management/patient/dto/models';
import { getOrder } from '../order-store/order.action';
import { ToastrService } from "ngx-toastr";
import { defaultGuid } from 'projects/shared/src/app/enums/allenums.enum';

@Component({
  selector: 'app-add-item-authorization',
  templateUrl: './add-item-authorization.component.html',
  styleUrls: ['./add-item-authorization.component.scss']
})
export class AddItemAuthorizationComponent implements OnInit, OnDestroy {

  public authorizationForm: FormGroup;
  @Input() isStatusShow: boolean = false;
  @Input() orderId: string = '';
  @Input() patientId: string = '';
  authId: string = "00000000-0000-0000-0000-000000000000";
  authModalTitle: string = '';
  authorizationId: string = "00000000-0000-0000-0000-000000000000";
  drpAuthVisit: AuthVisitsDTO[] = [];
  drpCategory: AuthCategoryDTO[] = [];
  //drpMarketingRef: MarketingReferralDTO[] = [];
  drpPlanType: any[];
  drpPlan: { planName?: string }[] = [];
  drpAuthItem: ItemDTO[] = [];
  drpSaleOrRents: SaleOrRentDTO[] = [];
  orderStatus: boolean = true;
  drpPolicies: PolicyDTO[] = [];
  drpItemCdoes: string[] = [];
  authVisit: string;
  category: string;
  sale: string;
  item: string;
  notesText ='';
  plan: string;
  itemCodes: string;
  Show: boolean = false;
  saveButtonHide: boolean
  isShowSpinner: boolean = false;
  subscription$: Subscription[] = [];
  constructor(
    private fb: FormBuilder,
    private text: TextValidators,
    private store: Store,
    private numberValidators: NumberValidators,
    private Table: TableService,
    private saleOrderService: SaleorderService,
    private orderDropdownService: OrderMasterDropdownService,
    private patientDropdownService: PatientMasterDropdownService,
    private itemAuthorizationService: ItemAuthorizationService,
    private toastr: ToastrService,
    public authDialogRef: MatDialogRef<AddItemAuthorizationComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { orderId: string, authId?: string, authDatas?: ItemAuthorizationDTO, allowUptSve: boolean, patientId: string }
  ) { }

  ngOnInit(): void {
    this.authId = this.data?.authId ?? defaultGuid;
    this.data.orderId = this.data?.orderId ?? "";
    this.createAuthorizationForm();
    this.getDropdowns();
    this.drpPlan = [];
    if (this.data?.orderId != "" && this.data?.orderId != null && this.data?.orderId != undefined) {
      const saleOrderGet = this.saleOrderService.get(this.data.orderId).subscribe(response => {
        this.drpAuthItem = response?.items;
        const saleOrderData: SaleorderDTO = response;
        this.orderStatus = this.data?.allowUptSve;
        this.patientId = saleOrderData?.patientId ?? "";
        if (saleOrderData?.patients?.policies?.primaryPolicy && saleOrderData?.patients?.policies?.primaryPolicy.policiesId) {
          this.drpPlan?.push({ planName: saleOrderData?.patients?.policies?.primaryPolicy?.payorId });
        }
        if (saleOrderData?.patients?.policies?.secondaryPolicy && saleOrderData?.patients?.policies?.secondaryPolicy.policiesId) {
          this.drpPlan?.push({ planName: saleOrderData?.patients?.policies?.secondaryPolicy?.payorId });
        }
        if (saleOrderData?.patients?.policies?.tertiaryPolicy && saleOrderData?.patients?.policies?.tertiaryPolicy.policiesId) {
          this.drpPlan?.push({ planName: saleOrderData?.patients?.policies?.tertiaryPolicy?.payorId });
        }
      });
      this.subscription$.push(saleOrderGet)
    }

    if (this.data.authDatas != null && this.data.authDatas != undefined && this.data.authDatas.id !== "" && this.data.authDatas.id !== '00000000-0000-0000-0000-000000000000') {
      this.getAuthDatas(this.data.authDatas);
      this.orderId = this.data.orderId;
      this.authId = this.data?.authDatas?.id ?? "";
      this.authModalTitle = 'Edit Authorization';
    }
    else {
      this.orderId = this.data.orderId;
      this.authId = '00000000-0000-0000-0000-000000000000';
      this.authModalTitle = 'Add Authorization';
    }
    !this.isStatusShow && this.authorizationForm.removeControl("rbtnAuth");
    this.authId !== defaultGuid && this.getAuthDatas(this.data?.authDatas)
  }

  ngOnDestroy(): void {
    this.subscription$?.forEach((sub) => {
      sub && sub?.unsubscribe();
    });
  }
  updateCharacterCount() {
    // Ensure the text length does not exceed the maximum length
    if (this.notesText?.length > 5000) {
      this.notesText = this.notesText?.substr(0, 5000);
    }
  }
  //! get date difference based on start & end dates
  dateChangeEvent(event: MatDatepickerInputEvent<Date>) {
    if (this.authorizationForm?.value?.txtStartDate && this.authorizationForm?.value?.txtEndDate) {
      const startDate = new Date(this.authorizationForm?.value?.txtStartDate)?.toLocaleDateString("en-US");
      const startDatet = new Date(startDate)?.getTime() ?? 0;
      const endDate = new Date(this.authorizationForm?.value?.txtEndDate)?.toLocaleDateString("en-US");
      const endDatet = new Date(endDate)?.getTime() ?? 0;
      const diff = new Date(endDatet).setHours(12) - new Date(startDatet).setHours(12);
      const daysDifference: number = Math.round(diff / 8.64e7); //+((timeDiff / 31536000000 ?? 0).toFixed(0))??0;
      this.authorizationForm.patchValue({
        txtDaysDiff: daysDifference
      })
    } else {
      this.authorizationForm.patchValue({
        txtDaysDiff: ""
      })
    }
  }



  //! Auth Form With Validations
  createAuthorizationForm() {
    this.authorizationForm = this.fb.group({
      txtAuthId: new FormControl("", [Validators.required]),
      txtDaysDiff: new FormControl(null),
      txtAuthAmount: new FormControl(""),
      txtStartDate: new FormControl("", [Validators.required]),
      txtEndDate: new FormControl("", [Validators.required]),
      txtItem: new FormControl(null, [Validators.required]),
      txtNote: new FormControl(""),
      txtPlan: new FormControl(null, [Validators.required]),
      rbtnAuth: new FormControl("", Validators.required),
      rbtnPolicyType: new FormControl(null),
      txtReason: new FormControl(null),
      txtUnits: new FormControl(""),
      txtUsed: new FormControl(""),
      txtSaleOrRent: new FormControl(""),
    });
  }

  //! Load Order Dropdowns
  getDropdowns() {

    let orderDropdownInput: OrderDropdowns[];
    orderDropdownInput = [
      OrderDropdowns.AuthVisits,
      OrderDropdowns.AuthCategories,
      //OrderDropdowns.MarketingReferrals,
      OrderDropdowns.TypeOfPlans,
      OrderDropdowns.PlanTypes
    ]
    const getOrderDropdown = this.orderDropdownService.getOrderDropdownsByInput(orderDropdownInput).subscribe((stateResponse) => {
      let response: OrderMasterDropdownDTO = stateResponse;
      this.drpAuthVisit = response.authVisits;
      this.drpCategory = response.authCategories;
      //this.drpMarketingRef = response.marketingReferrals;
      this.drpPlanType = response?.planTypes;
    });
    this.subscription$.push(getOrderDropdown);
    //
    let patientDropdownInput: PatientDropdowns[];
    patientDropdownInput = [PatientDropdowns.SaleOrRent]
   const orderDropdown = this.patientDropdownService.getPatientDropdownsByInput(patientDropdownInput).subscribe((response: PatientMasterDropdownDTO) => {
      this.drpSaleOrRents = response.saleOrRents;
    });
    this.subscription$.push(orderDropdown);

   if (this.data?.patientId != null && this.data?.patientId != undefined && this.data?.patientId != "") {
     const getAllItemCodes = this.saleOrderService.getAllItemCodesByPatientId(this.data?.patientId).subscribe(response => {
        this.drpItemCdoes = response;
      });
      this.subscription$.push(getAllItemCodes);
    }

  }

  //! Save Auth
  saveAuthorization() {
    this.isShowSpinner = true;
    const authFValue = this.authorizationForm.value;

    const input: CreateUpdateItemAuthorizationDTO = {
      authorizationId: this.authId === '' ? defaultGuid : this.authId,
      authId: authFValue?.txtAuthId,
      authNo: authFValue?.txtAuthNo,
      authAmount: authFValue?.txtAuthAmount,
      startDate: authFValue?.txtStartDate,// dateYYYYMMDDtoMMDDYYYY(authFValue?.txtStartDate)===''?null:dateYYYYMMDDtoMMDDYYYY(authFValue?.txtStartDate) ,
      endDate: authFValue?.txtEndDate,//dateYYYYMMDDtoMMDDYYYY(authFValue?.txtEndDate)===''?null:dateYYYYMMDDtoMMDDYYYY(authFValue?.txtEndDate) ,
      days: authFValue?.days,
      item: authFValue?.txtItem,
      note: authFValue?.txtNote,
      approvalStatus: authFValue?.rbtnAuth,
      approvedByWhom: "",
      approvedOn: "",
      insurance: authFValue?.txtPlan,
      reason: authFValue?.txtReason,
      units: authFValue?.txtUnits,
      used: authFValue?.txtUsed,
      saleOrRent: authFValue?.txtSaleOrRent,
      isFollowUp: this.data?.authDatas?.isFollowUp,
      patientId: this.patientId,
      orderId: this.orderId
    }
    if (this.authId !== defaultGuid) {
      this.itemAuthorizationService.update(this.authId, input).subscribe(response => {
        // Swal.fire({ title: 'Success', html: 'Saved Successfully', icon: 'success', timer: 3000, timerProgressBar: true })
        this.toastr.success('Saved Successfully','Success')
        this.authDialogRef.close();
        this.isShowSpinner = false;
      }, err => {
        this.isShowSpinner = false;
        const data: HttpErrorResponse = err;
        Swal.fire({ icon: 'info', text: data?.error?.error?.message });
      });

    } else {
      this.itemAuthorizationService.create(input).subscribe(response => {
        // Swal.fire({ title: 'Success', html: 'Saved Successfully', icon: 'success', timer: 3000, timerProgressBar: true })
        this.toastr.success('Saved Successfully','Success')
        this.authDialogRef.close();
        this.isShowSpinner = false;
      }, err => {
        this.isShowSpinner = false;
        const data: HttpErrorResponse = err;
        Swal.fire({ icon: 'info', text: data?.error?.error?.message });
      });
    }
  }

  //! Get Auth Datas by Id
  getAuthDatas(authData: ItemAuthorizationDTO) {
    this.authorizationForm.patchValue({
      txtAuthId: authData?.authId,
      txtDaysDiff: authData?.days,
      txtAuthAmount: authData?.authAmount,
      txtStartDate: new Date(authData?.startDate),
      txtEndDate: new Date(authData?.endDate),
      txtItem: authData?.item,
      txtNote: authData?.note,
      txtPlan: authData?.insurance,
      rbtnAuth: authData?.approvalStatus,
      rbtnPolicyType: authData?.approvalStatus,
      txtReason: authData?.reason,
      txtUnits: authData?.units,
      txtUsed: authData?.used,
      txtSaleOrRent: authData?.saleOrRent,
    })

  }

  //! Get Auth Datas based on radio button change
  onChangeGetAuth($event: MatRadioChange) {
    this.clearData();
    const saleOrderGet = this.saleOrderService.get(this.orderId).pipe(map(responses => {
    // this.store.dispatch(new getOrder(this.orderId)).pipe(map(responses => {
      let selectedPatient = responses?.patients.authorizations;
      let authPrimaryData: any[] = [];
      let authSecondaryData: any[] = [];
      let authTertiaryData: any[] = [];
      let authPrimaryResponse = selectedPatient?.primaryAuthorizations;
      let authSecondaryResponse = selectedPatient?.secondaryAuthorizations;
      let authTertiaryResponse = selectedPatient?.tertiaryAuthorizations;
      if ($event.value === 'Primary') {
        $event.value === 'Primary' && authPrimaryResponse.plan === "Primary" && authPrimaryData.push(authPrimaryResponse)
        return authPrimaryData
      }
      if ($event.value === 'Secondary') {
        $event.value === 'Secondary' && authSecondaryResponse?.plan === "Secondary" && authSecondaryData.push(authSecondaryResponse)
        return authSecondaryData
      }
      if ($event.value === 'Tertiary') {
        $event.value === 'Tertiary' && authTertiaryResponse?.plan === "Tertiary" && authTertiaryData.push(authTertiaryResponse)
        return authTertiaryData
      }
    })).subscribe((response) => {
      if (response && response[0]) {
        this.authorizationForm.patchValue({
          txtAuthId: response[0]?.authId,
          drpAuthVisit: response[0]?.authVisits,
          txtAuthNo: response[0]?.authNo,
          txtAuthAmount: response[0]?.authAmount,
          txtStartDate: response[0]?.startDate,
          txtEndDate: response[0]?.endDate,
          drpCategory: response[0]?.category,
          drpItem: response[0]?.item,
          txtNote: response[0]?.note,
          chkCompleted: response[0]?.isCompleted,
          txtCompletedBy: response[0]?.completedBy,
          txtPlan: response[0]?.plan
        })
      }

    })
    this.subscription$.push(saleOrderGet);
  }

  //! Get Policy Datas based on Payor Level Dropdown
  valueChangeLoadDropdown(type: MatSelectChange) {
    // this.authorizationForm.patchValue({
    //   txtPlanName: this.drpPolicies.filter(value => {
    //     return value && value.payorLevel === type.value
    //   })[0]?.insuranceType,
    //   txtPolicy: this.drpPolicies.filter(value => { return value && value.payorLevel === type.value })[0]?.policyNo,
    //   txtGroup: this.drpPolicies.filter(value => { return value && value.payorLevel === type.value })[0]?.groupName,
    // })
  }

  //! Get Disapprove reason based on radio button changes
  onChangeGetReason($event: MatRadioChange) {
    if ($event.value === "Disapproved") {
      this.Show = true
    } else {
      this.Show = false
    }
  }

  //! Reset auth Form
  clearData() {
    this.authorizationForm.reset();
    this.authorizationForm.markAsUntouched();
    this.authorizationForm.updateValueAndValidity();
  }

  //! Clear dropdowns
  clearAuthVisit() {
    this.authVisit = '';
    this.authorizationForm.patchValue({
      drpAuthVisit: ''
    });
  }

  clearCategory() {
    this.category = '';
    this.authorizationForm.patchValue({
      drpCategory: ''
    });
  }

  clearItem() {
    this.item = '';
    this.authorizationForm.patchValue({
      drpItem: ''
    });
  }
  clearPlan() {
    this.plan = '';
    this.authorizationForm.patchValue({
      txtPlan: ''
    });
  }
  clearSale() {
    this.sale = '';
    this.authorizationForm.patchValue({ txtSaleOrRent: "" });
  }
  clearItemCodes() {
    this.itemCodes = '';
    this.authorizationForm.patchValue({ txtItemCodes: "" });
  }
}
export function dateYYYYMMDDtoMMDDYYYY(strDate: Date): string {
  strDate = strDate ?? null
  if (strDate && typeof strDate === 'object' && strDate !== null && !isNaN(new Date(strDate).getTime())) {
    let latest_date = Intl.DateTimeFormat('en-US')?.format(new Date(strDate))
    return latest_date;
  }
  return "";
}
