<!-- Download-->
<!-- <div class="card card-body" style="border-top: 3px solid rgb(201, 201, 201)"> -->
<div *ngIf="activeTabID === ComplaincesTypes.New" class="container-fluid">
  <div class="d-flex justify-content-center" *ngIf="isLoading">
    <!-- <mat-spinner></mat-spinner> -->
    <img class="img-responsive pb-2 mr-1" src="assets/gifs/Infinity-loader.gif" />
  </div>
  <div class="table table-responsive">
    <table datatable class="row-border hover w-100 display" [dtOptions]="dtComplianceDownloadOptions">
      <thead>
        <tr>
          <th><mat-icon>apps</mat-icon></th>
          <th [matTooltip]="'Order Date'">Order Date</th>
          <th [matTooltip]="'Location'">Location</th>
          <th [matTooltip]="'Chart No'">Chart No</th>
          <th [matTooltip]="'Name'">Name</th>
          <th [matTooltip]="'Day'" class="text-right">Day</th>
          <th [matTooltip]="'Technician'">Technician</th>
          <th [matTooltip]="'% Days Used vs Data'" class="text-right">% Days Used vs Data</th>
          <th [matTooltip]="'% Days Used vs Setup'" class="text-right">% Days Used vs Setup</th>
          <th [matTooltip]="'% Days Used > 4HR vs Setup'" class="text-right">% Days Used > 4HR vs Setup</th>
          <th [matTooltip]="'Compliance %'">Compliance %</th>
          <th [matTooltip]="'Last Call'">Last Call</th>
          <th [matTooltip]="'Created By'">Created By</th>
          <th class="text-right" [matTooltip]="'Created Date'" >Created Date</th>
          <th [matTooltip]="'Modify By'">Modify By</th>
          <th class="text-right" [matTooltip]="'Modified Date'" >Modified Date</th>
          <th [matTooltip]="'Returned'">Returned</th>
          <th [matTooltip]="'NC'">NC</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let data of DataList; let i = index"
          (click)="tblItemSelectedRow(data.patientId,data.documentId,data.chartId)">
          <td>
            <mat-icon style="cursor: pointer !important" [matMenuTriggerFor]="menu">more_vert</mat-icon>
            <mat-icon *ngIf="data.isLock===true" style="color: #20b2aa;">lock</mat-icon>
            <mat-menu class="custom-mat-menu" #menu="matMenu">
              <button mat-menu-item [disabled]="data.patientId === defaultGuid" (click)="openAddNotesPopUp()">Add
                Note</button>
              <button mat-menu-item [disabled]="data.patientId === defaultGuid" (click)="openViewNotesPopup()">View
                Patient
                Notes</button>

              <a [class.disabled]="isEmpty(data.documentId) || data?.isSorted === false"
                [routerLink]="'/view_document_tiff/'  + data.documentId +'/'+ true  "
                target="_blank" class="preview-link">
                <button [disabled]="isEmpty(data.documentId) || data?.isSorted === false" mat-menu-item>View Document Images</button>
              </a>
              <button mat-menu-item (click)="viewPatientLedger(data.patientId)">View Patient Ledger</button>
              <button mat-menu-item [matMenuTriggerFor]="compliance">Compliance Letters</button>
              <button mat-menu-item [matMenuTriggerFor]="location">Move To</button>
            </mat-menu>

            <mat-menu #compliance="matMenu">
              <a [routerLink]="['/emailprintletter/'+data.patientId]" target="_blank">
                <button mat-menu-item >Download Letter</button>
              </a>
              <a [routerLink]="['/emailprintletter/'+data.patientId]" target="_blank">
              <button mat-menu-item>Incomplete file for medicare billing</button>
              </a>
              <a [routerLink]="['/emailprintletter/'+data.patientId]" target="_blank">
              <button mat-menu-item>Lat Resort Letter</button>
              </a>
              <a [routerLink]="['/emailprintletter/'+data.patientId]" target="_blank">
              <button mat-menu-item>Letter To Return Equipment</button>
              </a>
            </mat-menu>
            <mat-menu #location="matMenu">
              <ng-container *ngFor="let list of reportList">
                <button mat-menu-item [value]="list.id" (click)="reportMoveTo(data.id,list.id,list.complianceName)" [disabled]="list.id === data.statusId || list.complianceName === 'New'">
                  {{ list.complianceName }}
                </button>
              </ng-container>
            </mat-menu>
          </td>
          <td (dblclick)="onRowDoubleClick(data?.orderDate)"
            [matTooltip]="data?.orderDate">
            {{ data?.orderDate }}
          </td>
          <td (dblclick)="onRowDoubleClick(data.location || '-')" [matTooltip]="data.location || '-'">
            {{ data.location || '-' }}
          </td>
          <td (dblclick)="onRowDoubleClick(data.chartId || '-')">
            <a [class.disabled]="isEmpty(data.chartId) || isEmpty(data.patientId)" class="preview-link"
              [routerLink]="'/patientCreateEditTab/' + data.patientId" target="_blank"
              [matTooltip]="data.chartId || '-'">{{ data.chartId || '-'}}</a>
          </td>
          <td (dblclick)="onRowDoubleClick(data.name || '-')" [matTooltip]="data.name || '-'">
            {{ data.name || '-' }}
          </td>
          <td class="text-right" (dblclick)="onRowDoubleClick(data.day || '-')" [matTooltip]="data.day || '-'">
            {{ data.day || '-' }}
          </td>
          <td (dblclick)="onRowDoubleClick(data.technician || '-')" [matTooltip]="data.technician || '-'">
            {{ data.technician || '-' }}
          </td>
          <td (dblclick)="onRowDoubleClick(data.datausedvsdata)" [matTooltip]="data.datausedvsdata" class="text-right">
            {{ data.datausedvsdata }}
          </td>
          <td (dblclick)="onRowDoubleClick(data.datausedvssetup)" [matTooltip]="data.datausedvssetup" class="text-right">
            {{ data.datausedvssetup }}
          </td>
          <td (dblclick)="onRowDoubleClick(data.datausedvs4hr )" [matTooltip]="data.datausedvs4hr" class="text-right">
            {{ data.datausedvs4hr }}
          </td>
          <td (dblclick)="onRowDoubleClick(data.complaince || '-')" [matTooltip]="data.complaince || '-'">
            {{ data.complaince || '-' }}
          </td>
          <td (dblclick)="onRowDoubleClick(data.lastCallDate || '-')" [matTooltip]="data.lastCallDate || '-'">
            {{ data.lastCallDate || '-' }}
          </td>
          <td [matTooltip]="data?.createdBy || '-'"
          (dblclick)="onRowDoubleClick(data?.createdBy || '-')">{{
          data?.createdBy || '-' }}</td>

        <td class="text-right" [matTooltip]="data?.createdDate" >
            {{ data?.createdDate }}
        </td>
        <td [matTooltip]="data?.modifyBy|| '-'"
            (dblclick)="onRowDoubleClick(data?.modifyBy || '-')">{{
            data?.modifyBy || '-' }}</td>

        <td class="text-right" [matTooltip]="data?.modifiedDate">
            {{ data?.modifiedDate }}
        </td>
          <td> <mat-checkbox color="primary" [(ngModel)]="data.returned"></mat-checkbox></td>
          <td><mat-checkbox color="primary" [(ngModel)]="data.nc"></mat-checkbox></td>
        </tr>
      </tbody>
      <!-- Footer -->
      <tfoot>
        <tr>
          <th><mat-icon>apps</mat-icon></th>
          <th>
            <input matInput class="textbox-border-class" type="text" placeholder="Order Date" name="search-orderDate" />
          </th>
          <th>
            <input matInput class="textbox-border-class" type="text" placeholder="Location" name="search-location" />
          </th>
          <th>
            <input matInput class="textbox-border-class" type="text" placeholder="ChartNo" name="search-chartNo" />
          </th>
          <th>
            <input matInput class="textbox-border-class" type="text" placeholder="Name" name="search-Name" />
          </th>
          <th>
            <input matInput class="textbox-border-class text-right" type="text" placeholder="Day" name="search-day" />
          </th>
          <th>
            <input matInput class="textbox-border-class" type="text" placeholder="Technician"
              name="search-technicians" />
          </th>
          <th>
            <input matInput class="textbox-border-class text-right" type="text" placeholder="% Days Used vs Data"
              name="search-dayData" />
          </th>
          <th>
            <input matInput class="textbox-border-class text-right" type="text" placeholder="% Days Used vs Setup"
              name="search-daySetup" />
          </th>
          <th>
            <input matInput class="textbox-border-class text-right" type="text" placeholder="% Days Used > 4HR vs Setup"
              name="search-day4hrSetup" />
          </th>
          <th>
            <input matInput class="textbox-border-class" type="text" placeholder="Compliance"
              name="search-compliance" />
          </th>
          <th>
            <input matInput class="textbox-border-class" type="text" placeholder="LastCall" name="search-lastCall" />
          </th>
          <th>
            <input matInput class="textbox-border-class" type="text" placeholder="Created By"
                name="search-Created By" />
        </th>
        <th class="text-right">
            <input matInput class="textbox-border-class " type="text"
                  placeholder="Created Date" name="search-Created Date" />
         </th>
         <th>
            <input matInput class="textbox-border-class" type="text" placeholder="Modify By"
                name="search-Modify By" />
        </th>
          <th class="text-right">
              <input matInput class="textbox-border-class " type="text"
                  placeholder="Modified Date" name="search-Modified Date" />
          </th>
          <th><mat-icon>no_encryption</mat-icon></th>
          <th><mat-icon>no_encryption</mat-icon></th>
        </tr>
      </tfoot>
    </table>
  </div>
</div>

<!-- FTF-Note-->
<div *ngIf="activeTabID === ComplaincesTypes.FTFNote" class="container-fluid">
  <div class="d-flex justify-content-center" *ngIf="isLoading">
    <!-- <mat-spinner></mat-spinner> -->
    <img class="img-responsive pb-2 mr-1" src="assets/gifs/Infinity-loader.gif" />
  </div>
  <div class="table table-responsive">
    <table datatable class="row-border hover w-100 display" [dtOptions]="dtComplianceDownloadOptions">
      <thead>
        <tr>
          <th><mat-icon>apps</mat-icon></th>
          <th [matTooltip]="'Order Date'">Order Date</th>
          <th [matTooltip]="'Location'">Location</th>
          <th [matTooltip]="'Chart No'">Chart No</th>
          <th [matTooltip]="'Name'">Name</th>
          <th [matTooltip]="'Day'" class="text-right">Day</th>
          <th [matTooltip]="'Technician'">Technician</th>
          <!-- <th [matTooltip]="'FTF Date'">FTF Date</th> -->
          <th [matTooltip]="'% Days Used vs Data'" class="text-right">% Days Used vs Data</th>
          <th [matTooltip]="'% Days Used vs Setup'" class="text-right">% Days Used vs Setup</th>
          <th [matTooltip]="'% Days Used > 4HR vs Setup'" class="text-right">% Days Used > 4HR vs Setup</th>
          <th [matTooltip]="'Compliance %'">Compliance %</th>
          <th [matTooltip]="'Last Call'">Last Call</th>
          <th [matTooltip]="'Created By'">Created By</th>
          <th class="text-right" [matTooltip]="'Created Date'" >Created Date</th>
          <th [matTooltip]="'Modify By'">Modify By</th>
          <th class="text-right" [matTooltip]="'Modified Date'" >Modified Date</th>
          <th [matTooltip]="'Returned'">Returned</th>
          <th [matTooltip]="'NC'">NC</th>

        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let data of DataList; let i = index"
          (click)="tblItemSelectedRow(data.patientId,data.documentId,data.chartId)">
          <td>
            <mat-icon style="cursor: pointer !important" [matMenuTriggerFor]="menu">more_vert</mat-icon>
            <mat-icon *ngIf="data.isLock===true" style="color: #20b2aa;">lock</mat-icon>
            <mat-menu class="custom-mat-menu" #menu="matMenu">
              <button mat-menu-item [disabled]="data.patientId === defaultGuid" (click)="openAddNotesPopUp()">Add
                Note</button>
              <button mat-menu-item [disabled]="data.patientId === defaultGuid" (click)="openViewNotesPopup()">View
                Patient
                Notes</button>

              <a [class.disabled]="isEmpty(data.documentId) || data?.isSorted === false"
              [routerLink]="'/view_document_tiff/'  + data.documentId +'/'+ true"
                target="_blank" class="preview-link">
                <button [disabled]="isEmpty(data.documentId) || data?.isSorted === false" mat-menu-item>View Document Images</button>
              </a>

              <button mat-menu-item (click)="viewPatientLedger(data.patientId)">View Patient Ledger</button>
              <button mat-menu-item [matMenuTriggerFor]="compliance">Compliance Letters</button>
              <button mat-menu-item [matMenuTriggerFor]="location">Move To</button>
            </mat-menu>
            <mat-menu #compliance="matMenu">
              <a [routerLink]="['/emailprintletter/'+data.patientId]" target="_blank">
              <button mat-menu-item>Download Letter</button>
              </a>
              <a [routerLink]="['/emailprintletter/'+data.patientId]" target="_blank">
              <button mat-menu-item>Incomplete file for medicare billing</button>
              </a>
              <a [routerLink]="['/emailprintletter/'+data.patientId]" target="_blank">
              <button mat-menu-item>Lat Resort Letter</button>
              </a>
              <a [routerLink]="['/emailprintletter/'+data.patientId]" target="_blank">
              <button mat-menu-item>Letter To Return Equipment</button>
              </a>
            </mat-menu>

            <mat-menu #location="matMenu">
              <ng-container *ngFor="let list of reportList">
                <button mat-menu-item [value]="list.id" (click)="reportMoveTo(data.id,list.id,list.complianceName)" [disabled]="list.id === data.statusId || list.complianceName === 'New'">
                  {{ list.complianceName }}
                </button>
              </ng-container>
            </mat-menu>
          </td>

          <td (dblclick)="onRowDoubleClick(data?.orderDate)"
            [matTooltip]="data?.orderDate">
            {{ data?.orderDate }}
          </td>
          <td (dblclick)="onRowDoubleClick(data.location || '-')" [matTooltip]="data.location || '-'">
            {{ data.location || '-' }}
          </td>
          <td (dblclick)="onRowDoubleClick(data.chartId || '-')">
            <a [class.disabled]="isEmpty(data.chartId) || isEmpty(data.patientId)" class="preview-link"
              [routerLink]="'/patientCreateEditTab/' + data.patientId" target="_blank"
              [matTooltip]="data.chartId || '-'">{{ data.chartId || '-'}}</a>
          </td>
          <td (dblclick)="onRowDoubleClick(data.name || '-')" [matTooltip]="data.name || '-'">
            {{ data.name || '-' }}
          </td>
          <td class="text-right" (dblclick)="onRowDoubleClick(data.day || '-')" [matTooltip]="data.day || '-'">
            {{ data.day || '-' }}
          </td>
          <td (dblclick)="onRowDoubleClick(data.technician || '-')" [matTooltip]="data.technician || '-'">
            {{ data.technician || '-' }}
          </td>
          <td (dblclick)="onRowDoubleClick(data.datausedvsdata)" [matTooltip]="data.datausedvsdata" class="text-right">
            {{ data.datausedvsdata }}
          </td>
          <td (dblclick)="onRowDoubleClick(data.datausedvssetup)" [matTooltip]="data.datausedvssetup" class="text-right">
            {{ data.datausedvssetup }}
          </td>
          <td (dblclick)="onRowDoubleClick(data.datausedvs4hr )" [matTooltip]="data.datausedvs4hr" class="text-right">
            {{ data.datausedvs4hr }}
          </td>
          <td (dblclick)="onRowDoubleClick(data.complaince || '-')" [matTooltip]="data.complaince || '-'">
            {{ data.complaince || '-' }}
          </td>
          <td (dblclick)="onRowDoubleClick(data.lastCallDate || '-')" [matTooltip]="data.lastCallDate || '-'">
            {{ data.lastCallDate || '-' }}
          </td>
          <td [matTooltip]="data?.createdBy || '-'"
          (dblclick)="onRowDoubleClick(data?.createdBy || '-')">{{
          data?.createdBy || '-' }}</td>

        <td class="text-right" [matTooltip]="data?.createdDate" >
            {{ data?.createdDate }}
        </td>
        <td [matTooltip]="data?.modifyBy|| '-'"
            (dblclick)="onRowDoubleClick(data?.modifyBy || '-')">{{
            data?.modifyBy || '-' }}</td>

        <td class="text-right" [matTooltip]="data?.modifiedDate">
            {{ data?.modifiedDate }}
        </td>
          <td> <mat-checkbox color="primary" [(ngModel)]="data.returned"></mat-checkbox></td>
          <td><mat-checkbox color="primary" [(ngModel)]="data.nc"></mat-checkbox></td>

        </tr>
      </tbody>
      <!-- Footer -->
      <tfoot>
        <tr>
          <th><mat-icon>apps</mat-icon></th>
          <th>
            <input matInput class="textbox-border-class" type="text" placeholder="Order Date" name="search-orderDate" />
          </th>
          <th>
            <input matInput class="textbox-border-class" type="text" placeholder="location" name="search-location" />
          </th>
          <th>
            <input matInput class="textbox-border-class" type="text" placeholder="ChartNo" name="search-chartNo" />
          </th>
          <th>
            <input matInput class="textbox-border-class" type="text" placeholder="Name" name="search-Name" />
          </th>
          <th>
            <input matInput class="textbox-border-class text-right" type="text" placeholder="Day" name="search-day" />
          </th>
          <th>
            <input matInput class="textbox-border-class" type="text" placeholder="Technician"
              name="search-technicians" />
          </th>
          <!-- <th>
            <input matInput class="textbox-border-class" type="text" placeholder="FTFDate" name="search-FTFDate" />
          </th> -->
          <th>
            <input matInput class="textbox-border-class text-right" type="text" placeholder="% Days Used vs Data"
              name="search-dayData" />
          </th>
          <th>
            <input matInput class="textbox-border-class text-right" type="text" placeholder="% Days Used vs Setup"
              name="search-daySetup" />
          </th>
          <th>
            <input matInput class="textbox-border-class text-right" type="text" placeholder="% Days Used > 4HR vs Setup"
              name="search-day4hrSetup" />
          </th>
          <th>
            <input matInput class="textbox-border-class" type="text" placeholder="Compliance"
              name="search-compliance" />
          </th>
          <th>
            <input matInput class="textbox-border-class" type="text" placeholder="LastCall" name="search-lastCall" />
          </th>
          <th>
            <input matInput class="textbox-border-class" type="text" placeholder="Created By"
                name="search-Created By" />
        </th>
        <th class="text-right">
            <input matInput class="textbox-border-class " type="text"
                  placeholder="Created Date" name="search-Created Date" />
         </th>
         <th>
            <input matInput class="textbox-border-class" type="text" placeholder="Modify By"
                name="search-Modify By" />
        </th>
          <th class="text-right">
              <input matInput class="textbox-border-class " type="text"
                  placeholder="Modified Date" name="search-Modified Date" />
          </th>
          <th><mat-icon>no_encryption</mat-icon></th>
          <th><mat-icon>no_encryption</mat-icon></th>
        </tr>
      </tfoot>
    </table>
  </div>
</div>

<!--Checklist-->
<div *ngIf="activeTabID === ComplaincesTypes.CheckList" class="container-fluid">
  <div class="d-flex justify-content-center" *ngIf="isLoading">
    <!-- <mat-spinner></mat-spinner> -->
    <img class="img-responsive pb-2 mr-1" src="assets/gifs/Infinity-loader.gif" />
  </div>
  <div class="table table-responsive">
    <table datatable class="row-border hover w-100 display" [dtOptions]="dtComplianceDownloadOptions">
      <thead>
        <tr>
          <th><mat-icon>apps</mat-icon></th>
          <th [matTooltip]="'Order Date'">Order Date</th>
          <th [matTooltip]="'Location'">Location</th>
          <th [matTooltip]="'Chart No'">Chart No</th>
          <th [matTooltip]="'Name'">Name</th>
          <th [matTooltip]="'Day'" class="text-right">Day</th>
          <th [matTooltip]="'Technician'">Technician</th>
          <th [matTooltip]="'% Days Used vs Data'" class="text-right">% Days Used vs Data</th>
          <th [matTooltip]="'% Days Used vs Setup'" class="text-right">% Days Used vs Setup</th>
          <th [matTooltip]="'% Days Used > 4HR vs Setup'" class="text-right">% Days Used > 4HR vs Setup</th>
          <th [matTooltip]="'Compliance %'">Compliance %</th>
          <th [matTooltip]="'Last Call'">Last Call</th>
          <th [matTooltip]="'FTF Note'">FTF Note</th>
          <th [matTooltip]="'Final Billing'">Final Billing</th>
          <th [matTooltip]="'Created By'">Created By</th>
         <th class="text-right" [matTooltip]="'Created Date'" >Created Date</th>
         <th [matTooltip]="'Modify By'">Modify By</th>
         <th class="text-right" [matTooltip]="'Modified Date'" >Modified Date</th>
          <th [matTooltip]="'Returned'">Returned</th>
          <th [matTooltip]="'NC'">NC</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let data of DataList; let i = index"
          (click)="tblItemSelectedRow(data.patientId,data.documentId,data.chartId)">
          <td>
            <mat-icon style="cursor: pointer !important" [matMenuTriggerFor]="menu">more_vert</mat-icon>
            <mat-menu class="custom-mat-menu" #menu="matMenu">
              <button mat-menu-item>Edit Compliance Release Checklist</button>
              <button mat-menu-item [disabled]="data.patientId === defaultGuid" (click)="openAddNotesPopUp()">Add
                Note</button>
              <button mat-menu-item [disabled]="data.patientId === defaultGuid" (click)="openViewNotesPopup()">View
                Patient
                Notes</button>

              <a [class.disabled]="isEmpty(data.documentId) || data?.isSorted === false"
              [routerLink]="'/view_document_tiff/'  + data.documentId +'/'+ true"
                target="_blank" class="preview-link">
                <button [disabled]="isEmpty(data.documentId) || data?.isSorted === false" mat-menu-item>View Document Images</button>
              </a>

              <button mat-menu-item (click)="viewPatientLedger(data.patientId)">View Patient Ledger</button>
              <button mat-menu-item [matMenuTriggerFor]="compliance">Compliance Letters</button>
              <button mat-menu-item [matMenuTriggerFor]="location">Move To</button>
            </mat-menu>
            <mat-menu #compliance="matMenu">
              <a [routerLink]="['/emailprintletter/'+data.patientId]" target="_blank">
              <button mat-menu-item>Download Letter</button>
              </a>
              <a [routerLink]="['/emailprintletter/'+data.patientId]" target="_blank">
              <button mat-menu-item>Incomplete file for medicare billing</button>
              </a>
              <a [routerLink]="['/emailprintletter/'+data.patientId]" target="_blank">
              <button mat-menu-item>Lat Resort Letter</button>
              </a>
              <a [routerLink]="['/emailprintletter/'+data.patientId]" target="_blank">
              <button mat-menu-item>Letter To Return Equipment</button>
              </a>
            </mat-menu>

            <mat-menu #location="matMenu">
              <ng-container *ngFor="let list of reportList">
                <button mat-menu-item [value]="list.id" (click)="reportMoveTo(data.id,list.id,list.complianceName)" [disabled]="list.id === data.statusId || list.complianceName === 'New'">
                  {{ list.complianceName }}
                </button>
              </ng-container>
            </mat-menu>

          </td>

          <td (dblclick)="onRowDoubleClick(data?.orderDate)"
            [matTooltip]="data?.orderDate">
            {{ data?.orderDate }}
          </td>
          <td (dblclick)="onRowDoubleClick(data.location || '-')" [matTooltip]="data.location || '-'">
            {{ data.location || '-' }}
          </td>
          <td (dblclick)="onRowDoubleClick(data.chartId || '-')">
            <a [class.disabled]="isEmpty(data.chartId) || isEmpty(data.patientId)" class="preview-link"
              [routerLink]="'/patientCreateEditTab/' + data.patientId" target="_blank"
              [matTooltip]="data.chartId || '-'">{{ data.chartId || '-'}}</a>
          </td>
          <td (dblclick)="onRowDoubleClick(data.name || '-')" [matTooltip]="data.name || '-'">
            {{ data.name || '-' }}
          </td>
          <td class="text-right" (dblclick)="onRowDoubleClick(data.day || '-')" [matTooltip]="data.day || '-'">
            {{ data.day || '-' }}
          </td>
          <td (dblclick)="onRowDoubleClick(data.technician || '-')" [matTooltip]="data.technician || '-'">
            {{ data.technician || '-' }}
          </td>
          <td (dblclick)="onRowDoubleClick(data.datausedvsdata)" [matTooltip]="data.datausedvsdata" class="text-right">
            {{ data.datausedvsdata }}
          </td>
          <td (dblclick)="onRowDoubleClick(data.datausedvssetup)" [matTooltip]="data.datausedvssetup" class="text-right">
            {{ data.datausedvssetup }}
          </td>
          <td (dblclick)="onRowDoubleClick(data.datausedvs4hr)" [matTooltip]="data.datausedvs4hr" class="text-right">
            {{ data.datausedvs4hr }}
          </td>
          <td (dblclick)="onRowDoubleClick(data.complaince || '-')" [matTooltip]="data.complaince || '-'">
            {{ data.complaince || '-' }}
          </td>
          <td (dblclick)="onRowDoubleClick(data.lastCallDate || '-')" [matTooltip]="data.lastCallDate || '-'">
            {{ data.lastCallDate || '-' }}
          </td>
          <td (dblclick)="onRowDoubleClick(data.ftpnote || '-')" [matTooltip]="data.ftpnote || '-'">
            {{ data.ftpnote || '-' }}
          </td>
          <td (dblclick)="onRowDoubleClick(data.ftpBilling || '-')" [matTooltip]="data.ftpBilling || '-'">
            {{ data.ftpBilling || '-' }}
          </td>
          <td [matTooltip]="data?.createdBy || '-'"
          (dblclick)="onRowDoubleClick(data?.createdBy || '-')">{{
          data?.createdBy || '-' }}</td>

        <td class="text-right" [matTooltip]="data?.createdDate" >
            {{ data?.createdDate }}
        </td>
        <td [matTooltip]="data?.modifyBy|| '-'"
            (dblclick)="onRowDoubleClick(data?.modifyBy || '-')">{{
            data?.modifyBy || '-' }}</td>

        <td class="text-right" [matTooltip]="data?.modifiedDate">
            {{ data?.modifiedDate }}
        </td>
          <td> <mat-checkbox color="primary" [(ngModel)]="data.returned"></mat-checkbox></td>
          <td><mat-checkbox color="primary" [(ngModel)]="data.nc"></mat-checkbox></td>
        </tr>
      </tbody>
      <!-- Footer -->
      <tfoot>
        <tr>
          <th><mat-icon>apps</mat-icon></th>
          <th>
            <input matInput class="textbox-border-class" type="text" placeholder="Order Date" name="search-orderDate" />
          </th>
          <th>
            <input matInput class="textbox-border-class" type="text" placeholder="Location" name="search-location" />
          </th>
          <th>
            <input matInput class="textbox-border-class" type="text" placeholder="ChartNo" name="search-chartNo" />
          </th>
          <th>
            <input matInput class="textbox-border-class" type="text" placeholder="Name" name="search-Name" />
          </th>
          <th>
            <input matInput class="textbox-border-class text-right" type="text" placeholder="Day" name="search-day" />
          </th>
          <th>
            <input matInput class="textbox-border-class" type="text" placeholder="Technician"
              name="search-technicians" />
          </th>
          <th>
            <input matInput class="textbox-border-class text-right" type="text" placeholder="% Days Used vs Data"
              name="search-dayData" />
          </th>
          <th>
            <input matInput class="textbox-border-class text-right" type="text" placeholder="% Days Used vs Setup"
              name="search-daySetup" />
          </th>
          <th>
            <input matInput class="textbox-border-class text-right" type="text" placeholder="% Days Used > 4HR vs Setup"
              name="search-day4hrSetup" />
          </th>
          <th>
            <input matInput class="textbox-border-class" type="text" placeholder="Dompliance"
              name="search-compliance" />
          </th>
          <th>
            <input matInput class="textbox-border-class" type="text" placeholder="LastCall" name="search-lastCall" />
          </th>
          <th>
            <input matInput class="textbox-border-class" type="text" placeholder="FTF Note" name="search-FtfNote" />
          </th>
          <th>
            <input matInput class="textbox-border-class" type="text" placeholder="Final Billing"
              name="search-FinalBilling" />
          </th>
          <th>
            <input matInput class="textbox-border-class" type="text" placeholder="Created By"
                name="search-Created By" />
        </th>
        <th class="text-right">
            <input matInput class="textbox-border-class " type="text"
                  placeholder="Created Date" name="search-Created Date" />
         </th>
         <th>
            <input matInput class="textbox-border-class" type="text" placeholder="Modify By"
                name="search-Modify By" />
        </th>
          <th class="text-right">
              <input matInput class="textbox-border-class " type="text"
                  placeholder="Modified Date" name="search-Modified Date" />
          </th>
          <th><mat-icon>no_encryption</mat-icon></th>
          <th><mat-icon>no_encryption</mat-icon></th>
        </tr>
      </tfoot>
    </table>
  </div>
</div>

<!--Billing-->
<div *ngIf="activeTabID === ComplaincesTypes.Billing" class="container-fluid">
  <div class="d-flex justify-content-center" *ngIf="isLoading">
    <!-- <mat-spinner></mat-spinner> -->
    <img class="img-responsive pb-2 mr-1" src="assets/gifs/Infinity-loader.gif" />
  </div>
  <div class="table table-responsive">
    <table datatable class="row-border hover w-100 display" [dtOptions]="dtComplianceDownloadOptions">
      <thead>
        <tr>
          <th><mat-icon>apps</mat-icon></th>
          <th [matTooltip]="'Order Date'">Order Date</th>
          <th [matTooltip]="'Location'">Location</th>
          <th [matTooltip]="'Chart No'">Chart No</th>
          <th [matTooltip]="'Name'">Name</th>
          <th [matTooltip]="'Day'" class="text-right">Day</th>
          <th [matTooltip]="'Technician'">Technician</th>
          <th [matTooltip]="'% Days Used vs Data'" class="text-right">% Days Used vs Data</th>
          <th [matTooltip]="'% Days Used vs Setup'" class="text-right">% Days Used vs Setup</th>
          <th [matTooltip]="'% Days Used > 4HR vs Setup'" class="text-right">% Days Used > 4HR vs Setup</th>
          <th [matTooltip]="'Compliance %'">Compliance %</th>
          <th [matTooltip]="'Last Call'">Last Call</th>
          <th [matTooltip]="'FTF Note'">FTF Note</th>
          <th [matTooltip]="'Final Billing'">Final Billing</th>
          <th [matTooltip]="'Created By'">Created By</th>
          <th class="text-right" [matTooltip]="'Created Date'" >Created Date</th>
          <th [matTooltip]="'Modify By'">Modify By</th>
          <th class="text-right" [matTooltip]="'Modified Date'" >Modified Date</th>
          <th [matTooltip]="'Returned'">Returned</th>
          <th [matTooltip]="'NC'">NC</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let data of DataList; let i = index"
          (click)="tblItemSelectedRow(data.patientId,data.documentId,data.chartId)">
          <td>
            <mat-icon style="cursor: pointer !important" [matMenuTriggerFor]="menu">more_vert</mat-icon>
            <mat-icon *ngIf="data.isLock===true" style="color: #20b2aa;">lock</mat-icon>
            <mat-menu class="custom-mat-menu" #menu="matMenu">
              <button mat-menu-item>View Compliance Release checklist</button>
              <button mat-menu-item [disabled]="data.patientId === defaultGuid" (click)="openAddNotesPopUp()">Add
                Note</button>
              <button mat-menu-item [disabled]="data.patientId === defaultGuid" (click)="openViewNotesPopup()">View
                Patient
                Notes</button>

              <a [class.disabled]="isEmpty(data.documentId) || data?.isSorted === false"
              [routerLink]="'/view_document_tiff/'  + data.documentId +'/'+ true "
                target="_blank" class="preview-link">
                <button [disabled]="isEmpty(data.documentId) || data?.isSorted === false" mat-menu-item>View Document Images</button>
              </a>

              <button mat-menu-item (click)="viewPatientLedger(data.patientId)">View Patient Ledger</button>
              <button mat-menu-item [matMenuTriggerFor]="compliance">Compliance Letters</button>
              <button mat-menu-item [matMenuTriggerFor]="location">Move To</button>
            </mat-menu>
            <mat-menu #compliance="matMenu">
              <a [routerLink]="['/emailprintletter/'+data.patientId]" target="_blank">
              <button mat-menu-item>Download Letter</button>
              </a>
              <a [routerLink]="['/emailprintletter/'+data.patientId]" target="_blank">
              <button mat-menu-item>Incomplete file for medicare billing</button>
              </a>
              <a [routerLink]="['/emailprintletter/'+data.patientId]" target="_blank">
              <button mat-menu-item>Lat Resort Letter</button>
              </a>
              <a [routerLink]="['/emailprintletter/'+data.patientId]" target="_blank">
              <button mat-menu-item>Letter To Return Equipment</button>
              </a>
            </mat-menu>

            <mat-menu #location="matMenu">
              <ng-container *ngFor="let list of reportList">
                <button mat-menu-item [value]="list.id" (click)="reportMoveTo(data.id,list.id,list.complianceName)" [disabled]="list.id === data.statusId || list.complianceName === 'New'">
                  {{ list.complianceName }}
                </button>
              </ng-container>
            </mat-menu>
          </td>

          <td (dblclick)="onRowDoubleClick(data?.orderDate)"
            [matTooltip]="data?.orderDate">
            {{ data?.orderDate }}
          </td>
          <td (dblclick)="onRowDoubleClick(data.location || '-')" [matTooltip]="data.location || '-'">
            {{ data.location || '-' }}
          </td>
          <td (dblclick)="onRowDoubleClick(data.chartId || '-')">
            <a [class.disabled]="isEmpty(data.chartId) || isEmpty(data.patientId)" class="preview-link"
              [routerLink]="'/patientCreateEditTab/' + data.patientId" target="_blank"
              [matTooltip]="data.chartId || '-'">{{ data.chartId || '-'}}</a>
          </td>
          <td (dblclick)="onRowDoubleClick(data.name || '-')" [matTooltip]="data.name || '-'">
            {{ data.name || '-' }}
          </td>
          <td class="text-right" (dblclick)="onRowDoubleClick(data.day || '-')" [matTooltip]="data.day || '-'">
            {{ data.day || '-' }}
          </td>
          <td (dblclick)="onRowDoubleClick(data.technician || '-')" [matTooltip]="data.technician || '-'">
            {{ data.technician || '-' }}
          </td>
          <td (dblclick)="onRowDoubleClick(data.datausedvsdata)" [matTooltip]="data.datausedvsdata" class="text-right">
            {{ data.datausedvsdata }}
          </td>
          <td (dblclick)="onRowDoubleClick(data.datausedvssetup)" [matTooltip]="data.datausedvssetup" class="text-right">
            {{ data.datausedvssetup }}
          </td>
          <td (dblclick)="onRowDoubleClick(data.datausedvs4hr)" [matTooltip]="data.datausedvs4hr" class="text-right">
            {{ data.datausedvs4hr }}
          </td>
          <td (dblclick)="onRowDoubleClick(data.complaince || '-')" [matTooltip]="data.complaince || '-'">
            {{ data.complaince || '-' }}
          </td>
          <td (dblclick)="onRowDoubleClick(data.lastCallDate || '-')" [matTooltip]="data.lastCallDate || '-'">
            {{ data.lastCallDate || '-' }}
          </td>
          <td (dblclick)="onRowDoubleClick(data.ftpnote || '-')" [matTooltip]="data.ftpnote || '-'">
            {{ data.ftpnote || '-' }}
          </td>
          <td (dblclick)="onRowDoubleClick(data.ftpBilling || '-')" [matTooltip]="data.ftpBilling || '-'">
            {{ data.ftpBilling || '-' }}
          </td>
          <td [matTooltip]="data?.createdBy || '-'"
          (dblclick)="onRowDoubleClick(data?.createdBy || '-')">{{
          data?.createdBy || '-' }}</td>

        <td class="text-right" [matTooltip]="data?.createdDate" >
            {{ data?.createdDate }}
        </td>
        <td [matTooltip]="data?.modifyBy|| '-'"
            (dblclick)="onRowDoubleClick(data?.modifyBy || '-')">{{
            data?.modifyBy || '-' }}</td>

        <td class="text-right" [matTooltip]="data?.modifiedDate">
            {{ data?.modifiedDate }}
        </td>
          <td> <mat-checkbox color="primary" [(ngModel)]="data.returned"></mat-checkbox></td>
          <td><mat-checkbox color="primary" [(ngModel)]="data.nc"></mat-checkbox></td>

        </tr>
      </tbody>
      <!-- Footer -->
      <tfoot>
        <tr>
          <th><mat-icon>apps</mat-icon></th>
          <th>
            <input matInput class="textbox-border-class" type="text" placeholder="Order Date" name="search-orderDate" />
          </th>
          <th>
            <input matInput class="textbox-border-class" type="text" placeholder="Location" name="search-location" />
          </th>
          <th>
            <input matInput class="textbox-border-class" type="text" placeholder="ChartNo" name="search-chartNo" />
          </th>
          <th>
            <input matInput class="textbox-border-class" type="text" placeholder="Name" name="search-Name" />
          </th>
          <th>
            <input matInput class="textbox-border-class text-right" type="text" placeholder="Day" name="search-day" />
          </th>
          <th>
            <input matInput class="textbox-border-class" type="text" placeholder="Technician"
              name="search-technicians" />
          </th>
          <th>
            <input matInput class="textbox-border-class text-right" type="text" placeholder="% Days Used vs Data"
              name="search-dayData" />
          </th>
          <th>
            <input matInput class="textbox-border-class text-right" type="text" placeholder="% Days Used vs Setup"
              name="search-daySetup" />
          </th>
          <th>
            <input matInput class="textbox-border-class text-right" type="text" placeholder="% Days Used > 4HR vs Setup"
              name="search-day4hrSetup" />
          </th>
          <th>
            <input matInput class="textbox-border-class" type="text" placeholder="Compliance"
              name="search-compliance" />
          </th>
          <th>
            <input matInput class="textbox-border-class" type="text" placeholder="LastCall" name="search-lastCall" />
          </th>
          <th>
            <input matInput class="textbox-border-class" type="text" placeholder="FTF Note" name="search-FtfNote" />
          </th>
          <th>
            <input matInput class="textbox-border-class" type="text" placeholder="Final Billing"
              name="search-FinalBilling" />
          </th>
          <th>
            <input matInput class="textbox-border-class" type="text" placeholder="Created By"
                name="search-Created By" />
        </th>
        <th class="text-right">
            <input matInput class="textbox-border-class " type="text"
                  placeholder="Created Date" name="search-Created Date" />
         </th>
         <th>
            <input matInput class="textbox-border-class" type="text" placeholder="Modify By"
                name="search-Modify By" />
        </th>
          <th class="text-right">
              <input matInput class="textbox-border-class " type="text"
                  placeholder="Modified Date" name="search-Modified Date" />
          </th>
          <th><mat-icon>no_encryption</mat-icon></th>
          <th><mat-icon>no_encryption</mat-icon></th>
        </tr>
      </tfoot>
    </table>
  </div>
</div>

<!--Compliance-Met-->
<div *ngIf="activeTabID === ComplaincesTypes.ComplianceMet" class="container-fluid">
  <div class="d-flex justify-content-center" *ngIf="isLoading">
    <!-- <mat-spinner></mat-spinner> -->
    <img class="img-responsive pb-2 mr-1" src="assets/gifs/Infinity-loader.gif" />
  </div>
  <div class="table table-responsive">
    <table datatable class="row-border hover w-100 display" [dtOptions]="dtComplianceDownloadOptions">
      <thead>
        <tr>
          <th><mat-icon>apps</mat-icon></th>
          <th [matTooltip]="'Order Date'">Order Date</th>
          <th [matTooltip]="'Location'">Location</th>
          <th [matTooltip]="'Chart No'">Chart No</th>
          <th [matTooltip]="'Name'">Name</th>
          <th [matTooltip]="'Day'" class="text-right">Day</th>
          <th [matTooltip]="'Technician'">Technician</th>
          <th [matTooltip]="'% Days Used vs Data'" class="text-right">% Days Used vs Data</th>
          <th [matTooltip]="'% Days Used vs Setup'" class="text-right">% Days Used vs Setup</th>
          <th [matTooltip]="'% Days Used > 4HR vs Setup'" class="text-right">% Days Used > 4HR vs Setup</th>
          <th [matTooltip]="'Compliance %'">Compliance %</th>
          <th [matTooltip]="'Last Call'">Last Call</th>
          <th [matTooltip]="'FTF Note'">FTF Note</th>
          <th [matTooltip]="'Final Billing'">Final Billing</th>
          <th [matTooltip]="'Created By'">Created By</th>
          <th class="text-right" [matTooltip]="'Created Date'" >Created Date</th>
          <th [matTooltip]="'Modify By'">Modify By</th>
          <th class="text-right" [matTooltip]="'Modified Date'" >Modified Date</th>
          <th [matTooltip]="'Returned'">Returned</th>
          <th [matTooltip]="'NC'">NC</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let data of DataList; let i = index"
          (click)="tblItemSelectedRow(data.patientId,data.documentId,data.chartId)">
          <td>
            <mat-icon style="cursor: pointer !important" [matMenuTriggerFor]="menu">more_vert</mat-icon>
            <mat-icon *ngIf="data.isLock===true" style="color: #20b2aa;">lock</mat-icon>
            <mat-menu class="custom-mat-menu" #menu="matMenu">
              <button mat-menu-item>View Compliance Release checklist</button>
              <button mat-menu-item [disabled]="data.patientId === defaultGuid" (click)="openAddNotesPopUp()">Add
                Note</button>
              <button mat-menu-item [disabled]="data.patientId === defaultGuid" (click)="openViewNotesPopup()">View
                Patient
                Notes</button>

              <a [class.disabled]="isEmpty(data.documentId) || data?.isSorted === false"
              [routerLink]="'/view_document_tiff/'  + data.documentId +'/'+ true "
                target="_blank" class="preview-link">
                <button [disabled]="isEmpty(data.documentId) || data?.isSorted === false" mat-menu-item>View Document Images</button>
              </a>

              <button mat-menu-item (click)="viewPatientLedger(data.patientId)">View Patient Ledger</button>
              <button mat-menu-item [matMenuTriggerFor]="compliance">Compliance Letters</button>
              <button mat-menu-item [matMenuTriggerFor]="location">Move To</button>
            </mat-menu>
            <mat-menu #compliance="matMenu">
              <a [routerLink]="['/emailprintletter/'+data.patientId]" target="_blank">
              <button mat-menu-item>Download Letter</button>
              </a>
              <a [routerLink]="['/emailprintletter/'+data.patientId]" target="_blank">
              <button mat-menu-item>Incomplete file for medicare billing</button>
              </a>
              <a [routerLink]="['/emailprintletter/'+data.patientId]" target="_blank">
              <button mat-menu-item>Lat Resort Letter</button>
              </a>
              <a [routerLink]="['/emailprintletter/'+data.patientId]" target="_blank">
              <button mat-menu-item>Letter To Return Equipment</button>
              </a>
            </mat-menu>

            <mat-menu #location="matMenu">
              <ng-container *ngFor="let list of reportList">
                <button mat-menu-item [value]="list.id" (click)="reportMoveTo(data.id,list.id,list.complianceName)" [disabled]="list.id === data.statusId || list.complianceName === 'New'">
                  {{ list.complianceName }}
                </button>
              </ng-container>
            </mat-menu>
          </td>

          <td (dblclick)="onRowDoubleClick(data?.orderDate)"
            [matTooltip]="data?.orderDate">
            {{ data?.orderDate }}
          </td>
          <td (dblclick)="onRowDoubleClick(data.location || '-')" [matTooltip]="data.location || '-'">
            {{ data.location || '-' }}
          </td>
          <td (dblclick)="onRowDoubleClick(data.chartId || '-')">
            <a [class.disabled]="isEmpty(data.chartId) || isEmpty(data.patientId)" class="preview-link"
              [routerLink]="'/patientCreateEditTab/' + data.patientId" target="_blank"
              [matTooltip]="data.chartId || '-'">{{ data.chartId || '-'}}</a>
          </td>
          <td (dblclick)="onRowDoubleClick(data.name || '-')" [matTooltip]="data.name || '-'">
            {{ data.name || '-' }}
          </td>
          <td class="text-right" (dblclick)="onRowDoubleClick(data.day || '-')" [matTooltip]="data.day || '-'">
            {{ data.day || '-' }}
          </td>
          <td (dblclick)="onRowDoubleClick(data.technician || '-')" [matTooltip]="data.technician || '-'">
            {{ data.technician || '-' }}
          </td>
          <td (dblclick)="onRowDoubleClick(data.datausedvsdata)" [matTooltip]="data.datausedvsdata" class="text-right">
            {{ data.datausedvsdata }}
          </td>
          <td (dblclick)="onRowDoubleClick(data.datausedvssetup)" [matTooltip]="data.datausedvssetup" class="text-right">
            {{ data.datausedvssetup }}
          </td>
          <td (dblclick)="onRowDoubleClick(data.datausedvs4hr)" [matTooltip]="data.datausedvs4hr" class="text-right">
            {{ data.datausedvs4hr }}
          </td>
          <td (dblclick)="onRowDoubleClick(data.complaince || '-')" [matTooltip]="data.complaince || '-'">
            {{ data.complaince || '-' }}
          </td>
          <td (dblclick)="onRowDoubleClick(data.lastCallDate || '-')" [matTooltip]="data.lastCallDate || '-'">
            {{ data.lastCallDate || '-' }}
          </td>
          <td (dblclick)="onRowDoubleClick(data.ftpnote || '-')" [matTooltip]="data.ftpnote || '-'">
            {{ data.ftpnote || '-' }}
          </td>
          <td (dblclick)="onRowDoubleClick(data.ftpBilling || '-')" [matTooltip]="data.ftpBilling || '-'">
            {{ data.ftpBilling || '-' }}
          </td>
          <td [matTooltip]="data?.createdBy || '-'"
          (dblclick)="onRowDoubleClick(data?.createdBy || '-')">{{
          data?.createdBy || '-' }}</td>

        <td class="text-right" [matTooltip]="data?.createdDate" >
            {{ data?.createdDate }}
        </td>
        <td [matTooltip]="data?.modifyBy|| '-'"
            (dblclick)="onRowDoubleClick(data?.modifyBy || '-')">{{
            data?.modifyBy || '-' }}</td>

        <td class="text-right" [matTooltip]="data?.modifiedDate">
            {{ data?.modifiedDate }}
        </td>
          <td> <mat-checkbox color="primary" [(ngModel)]="data.returned"></mat-checkbox></td>
          <td><mat-checkbox color="primary" [(ngModel)]="data.nc"></mat-checkbox></td>

        </tr>
      </tbody>
      <!-- Footer -->
      <tfoot>
        <tr>
          <th><mat-icon>apps</mat-icon></th>
          <th>
            <input matInput class="textbox-border-class" type="text" placeholder="Order Date" name="search-orderDate" />
          </th>
          <th>
            <input matInput class="textbox-border-class" type="text" placeholder="Location" name="search-location" />
          </th>
          <th>
            <input matInput class="textbox-border-class" type="text" placeholder="ChartNo" name="search-chartNo" />
          </th>
          <th>
            <input matInput class="textbox-border-class" type="text" placeholder="Name" name="search-Name" />
          </th>
          <th>
            <input matInput class="textbox-border-class text-right" type="text" placeholder="Day" name="search-day" />
          </th>
          <th>
            <input matInput class="textbox-border-class" type="text" placeholder="Technician"
              name="search-technicians" />
          </th>
          <!-- <th  >
            <input matInput class="textbox-border-class" type="text" placeholder="FTFDate" name="search-FTFDate" />
          </th> -->
          <th>
            <input matInput class="textbox-border-class text-right" type="text" placeholder="% Days Used vs Data"
              name="search-dayData" />
          </th>
          <th>
            <input matInput class="textbox-border-class text-right" type="text" placeholder="% Days Used vs Setup"
              name="search-daySetup" />
          </th>
          <th>
            <input matInput class="textbox-border-class text-right" type="text" placeholder="% Days Used > 4HR vs Setup"
              name="search-day4hrSetup" />
          </th>
          <th>
            <input matInput class="textbox-border-class" type="text" placeholder="Compliance"
              name="search-compliance" />
          </th>
          <th>
            <input matInput class="textbox-border-class" type="text" placeholder="LastCall" name="search-lastCall" />
          </th>
          <th>
            <input matInput class="textbox-border-class" type="text" placeholder="FTF Note" name="search-FtfNote" />
          </th>
          <th>
            <input matInput class="textbox-border-class" type="text" placeholder="Final Billing"
              name="search-FinalBilling" />
          </th>
          <th>
            <input matInput class="textbox-border-class" type="text" placeholder="Created By"
                name="search-Created By" />
        </th>
        <th class="text-right">
            <input matInput class="textbox-border-class " type="text"
                  placeholder="Created Date" name="search-Created Date" />
         </th>
         <th>
            <input matInput class="textbox-border-class" type="text" placeholder="Modify By"
                name="search-Modify By" />
        </th>
          <th class="text-right">
              <input matInput class="textbox-border-class " type="text"
                  placeholder="Modified Date" name="search-Modified Date" />
          </th>
          <th><mat-icon>no_encryption</mat-icon></th>
          <th><mat-icon>no_encryption</mat-icon></th>
        </tr>
      </tfoot>
    </table>
  </div>
</div>

<!--Non Compliance-Met-->
<div *ngIf="activeTabID === ComplaincesTypes.NonComplianceMet" class="container-fluid">
  <div class="d-flex justify-content-center" *ngIf="isLoading">
    <!-- <mat-spinner></mat-spinner> -->
    <img class="img-responsive pb-2 mr-1" src="assets/gifs/Infinity-loader.gif" />
  </div>
  <div class="table table-responsive">
    <table datatable class="row-border hover w-100 display" [dtOptions]="dtComplianceDownloadOptions">
      <thead>
        <tr>
          <th><mat-icon>apps</mat-icon></th>
          <th [matTooltip]="'Order Date'">Order Date</th>
          <th [matTooltip]="'Location'">Location</th>
          <th [matTooltip]="'Chart No'">Chart No</th>
          <th [matTooltip]="'Name'">Name</th>
          <th [matTooltip]="'Day'" class="text-right">Day</th>
          <th [matTooltip]="'Technician'">Technician</th>
          <th [matTooltip]="'% Days Used vs Data'" class="text-right">% Days Used vs Data</th>
          <th [matTooltip]="'% Days Used vs Setup'" class="text-right">% Days Used vs Setup</th>
          <th [matTooltip]="'% Days Used > 4HR vs Setup'" class="text-right">% Days Used > 4HR vs Setup</th>
          <th [matTooltip]="'Compliance %'">Compliance %</th>
          <th [matTooltip]="'Last Call'">Last Call</th>
          <th [matTooltip]="'FTF Note'">FTF Note</th>
          <th [matTooltip]="'Final Billing'">Final Billing</th>
          <th [matTooltip]="'Created By'">Created By</th>
         <th class="text-right" [matTooltip]="'Created Date'" >Created Date</th>
         <th [matTooltip]="'Modify By'">Modify By</th>
         <th class="text-right" [matTooltip]="'Modified Date'" >Modified Date</th>
          <th [matTooltip]="'Returned'">Returned</th>
          <th [matTooltip]="'NC'">NC</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let data of DataList; let i = index"
          (click)="tblItemSelectedRow(data.patientId,data.documentId,data.chartId)">
          <td>
            <mat-icon style="cursor: pointer !important" [matMenuTriggerFor]="menu">more_vert</mat-icon>
            <mat-icon *ngIf="data.isLock===true" style="color: #20b2aa;">lock</mat-icon>
            <mat-menu class="custom-mat-menu" #menu="matMenu">
              <button mat-menu-item>View Compliance Release checklist</button>
              <button mat-menu-item [disabled]="data.patientId === defaultGuid" (click)="openAddNotesPopUp()">Add
                Note</button>
              <button mat-menu-item [disabled]="data.patientId === defaultGuid" (click)="openViewNotesPopup()">View
                Patient
                Notes</button>

              <a [class.disabled]="isEmpty(data.documentId) || data?.isSorted === false"
              [routerLink]="'/view_document_tiff/'  + data.documentId +'/'+ true"
                target="_blank" class="preview-link">
                <button [disabled]="isEmpty(data.documentId) || data?.isSorted === false" mat-menu-item>View Document Images</button>
              </a>

              <button mat-menu-item (click)="viewPatientLedger(data.patientId)">View Patient Ledger</button>
              <button mat-menu-item [matMenuTriggerFor]="compliance">Compliance Letters</button>
              <button mat-menu-item [matMenuTriggerFor]="location">Move To</button>
            </mat-menu>
            <mat-menu #compliance="matMenu">
              <a [routerLink]="['/emailprintletter/'+data.patientId]" target="_blank">
              <button mat-menu-item>Download Letter</button>
              </a>
              <a [routerLink]="['/emailprintletter/'+data.patientId]" target="_blank">
              <button mat-menu-item>Incomplete file for medicare billing</button>
              </a>
              <a [routerLink]="['/emailprintletter/'+data.patientId]" target="_blank">
              <button mat-menu-item>Lat Resort Letter</button>
              </a>
              <a [routerLink]="['/emailprintletter/'+data.patientId]" target="_blank">
              <button mat-menu-item>Letter To Return Equipment</button>
              </a>
            </mat-menu>

            <mat-menu #location="matMenu">
              <ng-container *ngFor="let list of reportList">
                <button mat-menu-item [value]="list.id" (click)="reportMoveTo(data.id,list.id,list.complianceName)" [disabled]="list.id === data.statusId || list.complianceName === 'New'">
                  {{ list.complianceName }}
                </button>
              </ng-container>
            </mat-menu>
          </td>
          <td (dblclick)="onRowDoubleClick(data?.orderDate)"
            [matTooltip]="data?.orderDate">
            {{ data?.orderDate }}
          </td>
          <td (dblclick)="onRowDoubleClick(data.location || '-')" [matTooltip]="data.location || '-'">
            {{ data.location || '-' }}
          </td>
          <td (dblclick)="onRowDoubleClick(data.chartId || '-')">
            <a [class.disabled]="isEmpty(data.chartId) || isEmpty(data.patientId)" class="preview-link"
              [routerLink]="'/patientCreateEditTab/' + data.patientId" target="_blank"
              [matTooltip]="data.chartId || '-'">{{ data.chartId || '-'}}</a>
          </td>
          <td (dblclick)="onRowDoubleClick(data.name || '-')" [matTooltip]="data.name || '-'">
            {{ data.name || '-' }}
          </td>
          <td class="text-right" (dblclick)="onRowDoubleClick(data.day || '-')" [matTooltip]="data.day || '-'">
            {{ data.day || '-' }}
          </td>
          <td (dblclick)="onRowDoubleClick(data.technician || '-')" [matTooltip]="data.technician || '-'">
            {{ data.technician || '-' }}
          </td>
          <td (dblclick)="onRowDoubleClick(data.datausedvsdata)" [matTooltip]="data.datausedvsdata" class="text-right">
            {{ data.datausedvsdata }}
          </td>
          <td (dblclick)="onRowDoubleClick(data.datausedvssetup)" [matTooltip]="data.datausedvssetup" class="text-right">
            {{ data.datausedvssetup }}
          </td>
          <td (dblclick)="onRowDoubleClick(data.datausedvs4hr )" [matTooltip]="data.datausedvs4hr " class="text-right">
            {{ data.datausedvs4hr }}
          </td>
          <td (dblclick)="onRowDoubleClick(data.complaince || '-')" [matTooltip]="data.complaince || '-'">
            {{ data.complaince || '-' }}
          </td>
          <td (dblclick)="onRowDoubleClick(data.lastCallDate || '-')" [matTooltip]="data.lastCallDate || '-'">
            {{ data.lastCallDate || '-' }}
          </td>
          <td (dblclick)="onRowDoubleClick(data.ftpnote || '-')" [matTooltip]="data.ftpnote || '-'">
            {{ data.ftpnote || '-' }}
          </td>
          <td (dblclick)="onRowDoubleClick(data.ftpBilling || '-')" [matTooltip]="data.ftpBilling || '-'">
            {{ data.ftpBilling || '-' }}
          </td>
          <td [matTooltip]="data?.createdBy || '-'"
          (dblclick)="onRowDoubleClick(data?.createdBy || '-')">{{
          data?.createdBy || '-' }}</td>

        <td class="text-right" [matTooltip]="data?.createdDate" >
            {{ data?.createdDate }}
        </td>
        <td [matTooltip]="data?.modifyBy|| '-'"
            (dblclick)="onRowDoubleClick(data?.modifyBy || '-')">{{
            data?.modifyBy || '-' }}</td>

        <td class="text-right" [matTooltip]="data?.modifiedDate">
            {{ data?.modifiedDate }}
        </td>
          <td> <mat-checkbox color="primary" [(ngModel)]="data.returned"></mat-checkbox></td>
          <td><mat-checkbox color="primary" [(ngModel)]="data.nc"></mat-checkbox></td>
        </tr>
      </tbody>
      <!-- Footer -->
      <tfoot>
        <tr>
          <th><mat-icon>apps</mat-icon></th>
          <th>
            <input matInput class="textbox-border-class" type="text" placeholder="Order Date" name="search-orderDate" />
          </th>
          <th>
            <input matInput class="textbox-border-class" type="text" placeholder="Location" name="search-location" />
          </th>
          <th>
            <input matInput class="textbox-border-class" type="text" placeholder="Chart No" name="search-ChartNo" />
          </th>
          <th>
            <input matInput class="textbox-border-class" type="text" placeholder="Name" name="search-Name" />
          </th>
          <th>
            <input matInput class="textbox-border-class text-right" type="text" placeholder="Day" name="search-day" />
          </th>
          <th>
            <input matInput class="textbox-border-class" type="text" placeholder="Technician"
              name="search-technicians" />
          </th>
          <!-- <th  >
            <input matInput class="textbox-border-class" type="text" placeholder="FTFDate" name="search-FTFDate" />
          </th> -->
          <th>
            <input matInput class="textbox-border-class text-right" type="text" placeholder="% Days Used vs Data"
              name="search-dayData" />
          </th>
          <th>
            <input matInput class="textbox-border-class text-right" type="text" placeholder="% Days Used vs Setup"
              name="search-daySetup" />
          </th>
          <th>
            <input matInput class="textbox-border-class text-right" type="text" placeholder="% Days Used > 4HR vs Setup"
              name="search-day4hrSetup" />
          </th>
          <th>
            <input matInput class="textbox-border-class" type="text" placeholder="compliance"
              name="search-compliance" />
          </th>
          <th>
            <input matInput class="textbox-border-class" type="text" placeholder="LastCall" name="search-lastCall" />
          </th>
          <th>
            <input matInput class="textbox-border-class" type="text" placeholder="FTF Note" name="search-FtfNote" />
          </th>
          <th>
            <input matInput class="textbox-border-class" type="text" placeholder="Final Billing"
              name="search-FinalBilling" />
          </th>
          <th>
            <input matInput class="textbox-border-class" type="text" placeholder="Created By"
                name="search-Created By" />
        </th>
        <th class="text-right">
            <input matInput class="textbox-border-class " type="text"
                  placeholder="Created Date" name="search-Created Date" />
         </th>
         <th>
            <input matInput class="textbox-border-class" type="text" placeholder="Modify By"
                name="search-Modify By" />
        </th>
          <th class="text-right">
              <input matInput class="textbox-border-class " type="text"
                  placeholder="Modified Date" name="search-Modified Date" />
          </th>
          <th><mat-icon>no_encryption</mat-icon></th>
          <th><mat-icon>no_encryption</mat-icon></th>
        </tr>
      </tfoot>
    </table>
  </div>
</div>
<!-- </div> -->
<!-- Call Details and Other Info -->
<!-- <div class="card card-body mt-2"> -->
  <div class="row mt-3">
    <div class="col-12">
      <mat-divider></mat-divider>
    </div>
    <div class="col-lg-6 mt-3">
      <!-- <div class="card elevated-div mt-2 px-0"> -->
      <h4 class="customfont">Call Details</h4>
      <div class="card card-body mt-1 p-3">
        <!-- <div class="d-flex justify-content-center" *ngIf="isLoading">
          <img class="img-responsive pb-2 mr-1" src="assets/gifs/Infinity-loader.gif" />
        </div> -->
        <app-complaince-call-details [tableData]="callLogTableData" *ngIf="!isCallLoading"></app-complaince-call-details>
      </div>
      <!-- </div> -->
    </div>

    <div class="col-lg-6 mt-3">
      <!-- <div class="card elevated-div mt-2 px-0"> -->
      <h4 class="customfont">Other Info</h4>
      <div class="card card-body mt-1 ">
        <form [formGroup]="otherInfoForm" class="otherInfo">
          <!-- Phone -->
          <mat-form-field class="col-6 mt-1 custom-font-size">
            <mat-label>Phone</mat-label>
            <input readonly matInput maxlength="40" formControlName="txtPhone" type="text" placeholder="Phone"
              autocomplete="off" />
          </mat-form-field>

          <!-- Cell Phone -->
          <mat-form-field class="col-6 mt-1 custom-font-size">
            <mat-label>Cell</mat-label>
            <input readonly matInput maxlength="40" formControlName="txtCellPhone" type="text" placeholder="Cell Phone"
              autocomplete="off" />
          </mat-form-field>

          <!-- Referral Practice -->
          <mat-form-field class="col-6 mt-1 custom-font-size">
            <mat-label>Referral Practice</mat-label>
            <input readonly matInput maxlength="40" formControlName="txtReferralPractice" type="text"
              placeholder="Referral Practice" autocomplete="off" />
          </mat-form-field>

          <!-- Ordering Physician -->
          <mat-form-field class="col-6 mt-1 custom-font-size">
            <mat-label>Ordering Physician</mat-label>
            <input readonly matInput maxlength="40" formControlName="txtOrderingPhysician" type="text"
              placeholder="Ordering Physician" autocomplete="off" />
          </mat-form-field>
        </form>
      </div>
      <!-- </div> -->
    </div>
  </div>
<!-- </div> -->
