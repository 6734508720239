<table class="table display hover w-100" datatable [dtOptions]="dtTableOptions">
  <thead>
    <tr>
      <th>Order No</th>
      <th>Supplies Date</th>
      <th>Primary Insurance</th>
      <th>Order Date</th>
      <th>Order Status</th>
      <th>Last Mask</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let data of tableData">
      <td>{{ data.orderId }}</td>
      <td>{{ data.suppliesDate }}</td>
      <td>{{ data.primaryIns }}</td>
      <td>{{ data.orderDate }}</td>
      <td>{{ data.orderStatusName }}</td>
      <td>{{ data.lastMaskDate }}</td>
    </tr>
  </tbody>
  <tfoot>
    <tr>
      <th>
        <input
          matInput
          class="textbox-border-class"
          type="text"
          placeholder="Order No"
        />
      </th>
      <th>
        <input
          matInput
          class="textbox-border-class"
          type="text"
          placeholder="Supplies Date"
        />
      </th>
      <th>
        <input
          matInput
          class="textbox-border-class"
          type="text"
          placeholder="Primary Insurance"
        />
      </th>
      <th>
        <input
          matInput
          class="textbox-border-class"
          type="text"
          placeholder="Order Date"
        />
      </th>
      <th>
        <input
          matInput
          class="textbox-border-class"
          type="text"
          placeholder="Order Status"
        />
      </th>
      <th>
        <input
          matInput
          class="textbox-border-class"
          type="text"
          placeholder="Last Mask"
        />
      </th>
    </tr>
  </tfoot>
</table>
