import type {
  ClaimInfoDetailsDTO,
  ClearingHouseUpdateBatchesDTO,
  CreateUpdateClearingHouseUpdateBatchesDTO,
  ErrorDetails277DTO,
} from './dto/models';
import { RestService } from '@abp/ng.core';
import type {
  PagedAndSortedResultRequestDto,
  PagedResultDto,
} from '@abp/ng.core';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ClearingHouseUpdateBatchesService {
  apiName = 'ClaimProcessingManagement';

  create = (input: CreateUpdateClearingHouseUpdateBatchesDTO) =>
    this.restService.request<any, ClearingHouseUpdateBatchesDTO>(
      {
        method: 'POST',
        url: '/api/claimProcessingApp/clearing-house-update-batches',
        body: input,
      },
      { apiName: this.apiName }
    );

  delete = (id: string) =>
    this.restService.request<any, void>(
      {
        method: 'DELETE',
        url: `/api/claimProcessingApp/clearing-house-update-batches/${id}`,
      },
      { apiName: this.apiName }
    );

  get = (id: string) =>
    this.restService.request<any, ClearingHouseUpdateBatchesDTO>(
      {
        method: 'GET',
        url: `/api/claimProcessingApp/clearing-house-update-batches/${id}`,
      },
      { apiName: this.apiName }
    );

  getList = (input: PagedAndSortedResultRequestDto) =>
    this.restService.request<
      any,
      PagedResultDto<ClearingHouseUpdateBatchesDTO>
    >(
      {
        method: 'GET',
        url: '/api/claimProcessingApp/clearing-house-update-batches',
        params: {
          skipCount: input.skipCount,
          maxResultCount: input.maxResultCount,
          sorting: input.sorting,
        },
      },
      { apiName: this.apiName }
    );

  update = (id: string, input: CreateUpdateClearingHouseUpdateBatchesDTO) =>
    this.restService.request<any, ClearingHouseUpdateBatchesDTO>(
      {
        method: 'PUT',
        url: `/api/claimProcessingApp/clearing-house-update-batches/${id}`,
        body: input,
      },
      { apiName: this.apiName }
    );

  checkClaimHistoryBySClaimNumberAndDtDateOfServiceAndClaimId = (
    sClaimNumber: string,
    dtDateOfService: string,
    claimId: string
  ) =>
    this.restService.request<any, ClaimInfoDetailsDTO>(
      {
        method: 'POST',
        url: `/api/claimProcessingApp/clearing-house-update-batches/check-claim-history/${claimId}`,
        params: { sClaimNumber, dtDateOfService },
      },
      { apiName: this.apiName }
    );

  getClaimStatusDataByClaimIdByClaimId = (claimId: string) =>
    this.restService.request<any, ClearingHouseUpdateBatchesDTO>(
      {
        method: 'GET',
        url: `/api/claimProcessingApp/clearing-house-update-batches/get-claim-status-data-by-claim-id/${claimId}`,
      },
      { apiName: this.apiName }
    );
  get277ErrorDetailsByClaimId = (claimId: string) =>
    this.restService.request<any, ErrorDetails277DTO[]>(
      {
        method: 'GET',
        url: `/api/claimProcessingApp/clearing-house-update-batches/277Error-details/${claimId}`,
      },
      { apiName: this.apiName }
    );
  constructor(private restService: RestService) {}
}
