import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { DataTableDirective } from 'angular-datatables';
import { CommunicationService } from 'projects/shared/src/app/services/communication.service';
import { ProductHcpcCodeService } from '../../item-proxy/item-management/optimization/product-hcpc-code.service';
import { Subject, Subscription } from 'rxjs';
import Swal from 'sweetalert2';
import {
  ProductDetailsService,
  Status,
} from '../../item-proxy/item-management/optimization';
import { HttpErrorResponse } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-product-table-list',
  templateUrl: './product-table-list.component.html',
  styleUrls: ['./product-table-list.component.scss'],
})
export class ProductTableListComponent implements OnInit {
  @Input() productListValue: any;
  @ViewChild(DataTableDirective, { static: false })
  datatableElement: DataTableDirective;
  @Input() currentPage: any = 1;
  @Input() totalPages: any = 1;
  @Output() navigatePage? = new EventEmitter<number>();
  @Input() paginationInfo: {
    startRecord: number;
    endRecord: number;
    overallTableCount: number;
  };
  myTable: DataTables.Api;
  subscription$: Subscription[] = [];
  dtOptions: any;
  constructor(
    private communicationService: CommunicationService,
    private hcpcCodeService: ProductHcpcCodeService,
    private productDetailsService: ProductDetailsService,
    private toaster: ToastrService,
    private datepipe: DatePipe
  ) {}

  ngOnInit() {
    console.log(this.paginationInfo);
    this.dtOptions = {
      dom: 'Bfrtip',
      responsive: true,
      paging: false,
      scrollCollapse: true,
      scrollY: '300px',
      scrollX: true,
      language: {
        search: '',
        searchPlaceholder: 'Search Here',
        emptyTable: 'No records',
        info: `Showing ${this.paginationInfo?.startRecord ?? 0} to ${
          this.paginationInfo?.endRecord ?? 0
        } of ${this.paginationInfo?.overallTableCount ?? 0} records`,
        infoEmpty: 'Total : _MAX_ records',
        infoFiltered: '(filtered : _TOTAL_ records)',
        loadingRecords: 'Loading...',
        zeroRecords: 'No matching records',
      },
      columnDefs: [{ targets: [16], searchable: true, visible: false }],

      search: {
        smart: false, // Set to "none" for full exact match
      },
      buttons: [
        {
          extend: 'excel',
          text: 'Export', // Change the text to 'Export'
          filename: 'Product List',
          className: 'custom-dt-export-button',
          exportOptions: {
            // columns: ':visible:not(:nth-child(1),:nth-child(2))',
            columns: [2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16], // Specify the columns you want to export (0-indexed)
          },
        },
      ],
      initComplete: function (this: any) {
        //Search Input Alignment
        const searchInput = document.querySelector(
          '.dataTables_wrapper .dataTables_filter'
        ) as HTMLElement;
        if (searchInput) {
          searchInput.style.float = 'left';
        }

        //Search Input Design and Icon
        const searchFilterInput = document.querySelector(
          '.dataTables_filter input'
        ) as HTMLInputElement;
        if (searchFilterInput) {
          const searchContainer = document.createElement('div');
          searchContainer.className = 'search-container';
          searchFilterInput.style.paddingLeft = '25px';
          searchFilterInput.style.fontStyle = 'italic';
          searchFilterInput.style.fontSize = '12px';

          // Add event listeners for focusin and focusout
          searchFilterInput.addEventListener('focus', () => {
            searchFilterInput.style.border = '1px solid lightseagreen';
            searchFilterInput.style.outline = 'lightseagreen';
          });

          searchFilterInput.addEventListener('blur', () => {
            searchFilterInput.style.border = '';
            searchFilterInput.style.outline = '';
          });

          searchFilterInput.parentNode?.insertBefore(
            searchContainer,
            searchFilterInput
          );
          searchContainer.appendChild(searchFilterInput);

          const searchIcon = document.createElement('i');
          searchIcon.className = 'fa fa-search';
          searchIcon.style.position = 'absolute';
          searchIcon.style.left = '10px';
          searchIcon.style.top = '16px';
          searchIcon.style.transform = 'translateY(-50%)';
          searchIcon.style.pointerEvents = 'none';
          searchIcon.style.color = '#aaa';

          searchContainer.appendChild(searchIcon);
        }
      },
    };

    this.dtOptions.buttons[0].filename =
      'Product  List' + '-' + this.datepipe.transform(new Date(), 'MM-dd-yyyy');
    this.calculateScrollY();
    window.addEventListener('resize', this.calculateScrollY);
  }

  ngOnDestroy(): void {
    window.removeEventListener('resize', this.calculateScrollY);
    this.subscription$?.forEach((sub) => {
      sub && sub?.unsubscribe();
    });
  }
  ngAfterViewInit(): void {
    if (this.datatableElement && this.datatableElement.dtInstance) {
      this.refreshDataTable();
    } else {
      // If dtInstance is not available yet, wait for a short time and try again
      setTimeout(() => this.refreshDataTable(), 500);
    }
  }

  calculateScrollY = () => {
    const screenHeight =
      window.innerHeight ||
      document.documentElement.clientHeight ||
      document.body.clientHeight;
    const availableSpacePercentage = 0.5; // Adjust the percentage as needed
    this.dtOptions.scrollY = screenHeight * availableSpacePercentage;
  };
  refreshDataTable(): void {
    this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
      // Clear search input values
      $('input', dtInstance.table(0).footer()).val('');
      // Redraw the DataTable to load all records
      dtInstance.search('').columns().search('').draw();
      this.addPageNavigationControls();

      var index = 0;
      dtInstance.columns().every(function () {
        const that = this;

        index++;

        $('input', this.footer()).on('keyup change', function () {
          if (that.search() !== this['value']) {
            that.search(this['value']).draw();
          }
        });
      });
    });
  }

  triggerToggle() {
    this.communicationService.triggerInsuranceAllowableToggle();
  }
  getInsAllowablesById(id: string) {
    this.communicationService.triggerInsuranceAllowableGetMethodCall(id);
  }
  statusToggleTrigger(toggle: MatSlideToggleChange, data: any) {
    this.communicationService.triggerStatusGetMethodCall(toggle, data);
  }
  handleTableCellClickTrigger(event, productId) {
    this.communicationService.triggerHandleTableCellMethodCall(
      event,
      productId
    );
  }
  //On Product Status Change
  statusChange(event: MatSlideToggleChange, data: any) {
    data.status = event.checked ? Status.Active : Status.Inactive;
    Swal.fire({
      title: 'Do you want to Change Product Status?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#34c38f',
      cancelButtonColor: '#f46a6a',
      confirmButtonText: 'Confirm',
    }).then((result) => {
      if (result.value) {
        // this.loadItemTable = false;
        this.productDetailsService
          .updateStatusV1ByGProductIdAndSStatus(
            data.id,
            event.checked ? Status.Active : Status.Inactive
          )
          .subscribe(
            (response) => {
              data.status = event.checked ? Status.Active : Status.Inactive;
              const index = this.productListValue.findIndex(
                (obj) => obj.id === response?.id
              );
              if (index !== -1) {
                this.productListValue[index] = response; //Replace the Object
                const objectToMove = this.productListValue.splice(index, 1)[0]; // Remove and get the object
                this.productListValue.unshift(objectToMove);
              }
              this.communicationService.triggerReloadProduct();
              this.toaster.success('Product Details Updated!');
            },
            (err) => {
              const errMessage: HttpErrorResponse = err;
              this.toaster.info(errMessage.error.error.message);
              data.status = event.checked ? Status.Inactive : Status.Active;
              event.source.checked = event.checked ? false : true;
            }
          );
      } else {
        data.status = event.checked ? Status.Inactive : Status.Active;
        event.source.checked = event.checked ? false : true;
      }
    });
  }

  handleTableCellClick(e: Event, productId: string) {
    let hcpcData = [];
    const hcpcDetails = this.hcpcCodeService
      .getProductHCPCCodeListByID(productId)
      .subscribe(
        (response) => {
          if (response.length > 0) {
            const target = e.target as HTMLElement;
            const tr = target.closest('tr');

            const hcpcCodes = response
              ?.map((item) => item.hcpcCode)
              .filter(Boolean); // Filter out null or undefined values
            const commaSeparatedHcpcs = hcpcCodes.join(', ');

            this.getDataTableData(tr, commaSeparatedHcpcs);
          }
        },
        (err) => {}
      );
    this.subscription$.push(hcpcDetails);
  }

  //DataTable Data Get Method and Toggle Notes Row in UnProcessed Table
  async getDataTableData(tr: any, hcpcData: any): Promise<void> {
    this.myTable = await this.datatableElement.dtInstance;

    if (tr) {
      const row = this.myTable.row(tr);

      if (row.child.isShown()) {
        // This row is already open - close it
        row.child.hide();
      } else {
        // Open this row
        row.child(this.format(row.data(), hcpcData)).show();
      }
    }
  }
  //Add Dynamic Notes Row in UnProcessed Table
  format(d: any, hcpcData: any) {
    let returnString: string = '';
    // hcpcData?.forEach(function (value) {
    returnString += '<tr class="border-bottom"><td>' + hcpcData + '</td></tr>';
    // });
    return returnString;
  }

  addPageNavigationControls() {
    let buttonsElement = $('.dt-buttons')[0];
    let totalPages = this.totalPages;
    let currentPage = this.currentPage;

    // $("<span> : <span>").appendTo(buttonsElement);
    if (this.currentPage == 1) {
      $(
        "<button disabled title='First Page' class='dt-button dt-custom-pagination-buttons disabled' id='btnFirstPage' type='button'><span>&lt;&lt;</span></button>"
      ).appendTo(buttonsElement);
      $(
        "<button disabled class='dt-button dt-custom-pagination-buttons disabled' id='btnPrevPage' type='button'><span>&lt;</span></button>"
      ).appendTo(buttonsElement);
    } else {
      $(
        "<button title='First Page' class='dt-button dt-custom-pagination-buttons' id='btnFirstPage' type='button'><span>&lt;&lt;</span></button>"
      ).appendTo(buttonsElement);
      $(
        "<button title='Previous Page' class='dt-button dt-custom-pagination-buttons' id='btnPrevPage' type='button'><span>&lt;</span></button>"
      ).appendTo(buttonsElement);
    }
    // style='float:left !important;'
    $("<span class='dt-pagination-input-span' > Page <span>").appendTo(
      buttonsElement
    );
    $(
      "<input class='dt-pagination-input' id='txtGotoPage' value='" +
        this.currentPage +
        "' type='text'/>"
    ).appendTo(buttonsElement);

    ////Input Restrictions on pagination text box
    $('#txtGotoPage').on('input', function (e) {
      let value = (this as HTMLInputElement).value;
      value = value.replace(/[^0-9]/g, '');
      if (value.startsWith('0')) {
        value = value.substring(1);
      }
      //Invalid page number
      if (
        (value == '' ? currentPage : value) > totalPages ||
        (value == '' ? currentPage : value) == currentPage
      ) {
        $('#btnGoToPage').addClass('disabled');
      } else if (value != currentPage && value <= totalPages) {
        $('#btnGoToPage').removeClass('disabled');
      }
      (this as HTMLInputElement).value = value;
    });
    //If the user leaves empty text box patch current page
    $('#txtGotoPage').on('focusout', function (e) {
      let value = (this as HTMLInputElement).value;
      if (value == '') {
        (this as HTMLInputElement).value = currentPage;
      }
    });

    $(
      "<span class='dt-pagination-input-span pl-1'> of " +
        this.totalPages +
        ' <span>'
    ).appendTo(buttonsElement);
    $(
      "<button  class='dt-button dt-custom-pagination-buttons-goto disabled' id='btnGoToPage'   type='button'><span>Go to</span></button>"
    ).appendTo(buttonsElement);

    if (this.currentPage >= this.totalPages) {
      $(
        "<button disabled class='dt-button dt-custom-pagination-buttons disabled' id='btnNextPage'   type='button'><span>&gt;</span></button>"
      ).appendTo(buttonsElement);
      $(
        "<button disabled title='Last Page'  class='dt-button dt-custom-pagination-buttons disabled' id='btnLastPage'  type='button'><span>&gt;&gt</span></button>"
      ).appendTo(buttonsElement);
    } else {
      $(
        "<button title='Next Page'  class='dt-button dt-custom-pagination-buttons' id='btnNextPage'  type='button'><span>&gt;</span></button>"
      ).appendTo(buttonsElement);
      $(
        "<button title='Last Page'  class='dt-button dt-custom-pagination-buttons' id='btnLastPage'  type='button'><span>&gt;&gt</span></button>"
      ).appendTo(buttonsElement);
    }

    $('#btnNextPage').on('click', (event) => this.changePage(true));
    $('#btnPrevPage').on('click', (event) => this.changePage(false));
    $('#btnGoToPage').on('click', (event) => this.fnGotoPage());
    $('#btnFirstPage').on('click', (event) => this.navigatePage.emit(1));
    $('#btnLastPage').on('click', (event) =>
      this.navigatePage.emit(this.totalPages)
    );

    // $(".buttons-colvis").on('click', (event) => this.fnColumnSelectionClick());
    // $('#btnDefaultClms').on('click', (event) => this.fnGetColumnVisiblity("DEFAULT"));
    // $('#btnAllClms').on('click', (event) => this.fnGetColumnVisiblity("ALL"));
  }
  changePage(isNext: boolean) {
    if (isNext) this.currentPage++;
    else this.currentPage--;
    this.navigatePage.emit(this.currentPage);
  }

  fnGotoPage() {
    let pageValue = $('#txtGotoPage').val();
    let pageno = parseInt(pageValue.toString());
    if (pageno > 0 && this.currentPage != pageno && pageno <= this.totalPages) {
      this.navigatePage.emit(pageno);
    }
  }
}
