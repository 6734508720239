<!-- Check List Page -->
<div class="container-fluid">
  <div class="row bg">
    <div class="col-12">
      <div class="d-flex align-items-center justify-content-between mb-1">
        <h3 class="font-weight-bolder mb-0">Cheque List Page</h3>
        <div class="page-title-box page-title-right">
          <ol class="breadcrumbs m-0">
            <li>

              <button class="mr-2 buttonColor" mat-raised-button
                [disabled]="(dataSource && dataSource?.data && dataSource?.data?.length === 0)" (click)="
                  exporter.exportTable('xlsx', {
                    fileName: 'Cheque List',
                    sheet: 'ChequeList',
                    Props: { Author: 'QSecure' }
                  })
                ">
                Export
              </button>
            </li>
            <li class="breadcrumb-item mt-2 pl-2">Claim</li>
            <li class="breadcrumb-item mt-2">Cheque List Page</li>
          </ol>
        </div>
      </div>
    </div>
    <div class="col-lg-12">
      <form [formGroup]="chequeForm">
        <div class="example-container mat-elevation-z4">
          <table mat-table [dataSource]="dataSource" matTableExporter #exporter="matTableExporter" matSort
            class="mat-elevation-z8">
            <ng-container matColumnDef="checkNo">
              <th class="header" mat-header-cell *matHeaderCellDef>
                <mat-form-field class="filter">
                  <input autocomplete="new-chequeNumber" matInput formControlName="chequeNumber" />
                  <mat-placeholder class="center">Cheque Number</mat-placeholder>
                </mat-form-field>
              </th>
              <td mat-cell *matCellDef="let element">
                {{ element?.checkNo }}
              </td>
            </ng-container>
            <ng-container matColumnDef="claimNumber">
              <th class="header" mat-header-cell *matHeaderCellDef>
                <mat-form-field class="filter">
                  <input autocomplete="new-claimNumber" matInput formControlName="claimNumber" />
                  <mat-placeholder class="center">Claim Number</mat-placeholder>
                </mat-form-field>
              </th>
              <td mat-cell *matCellDef="let element">
                <a matTooltip="Click to View Patient" class="eyeCursorclass" [queryParams]="{ id: 'All' }"
                  [routerLink]="'/AddNewPosting/' + element?.claimId" target="_blank">
                  {{ element?.claimNumber }}
                </a>
              </td>
            </ng-container>
            <ng-container matColumnDef="checkAmount">
              <th class="header" mat-header-cell *matHeaderCellDef>
                <mat-form-field class="filter">
                  <input autocomplete="new-checkAmount" matInput formControlName="checkAmount" />
                  <mat-placeholder class="center">Cheque Amount</mat-placeholder>
                </mat-form-field>
              </th>
              <td mat-cell *matCellDef="let element">
                {{ element?.checkAmount }}
              </td>
            </ng-container>

            <ng-container matColumnDef="patientId">
              <th class="header" mat-header-cell *matHeaderCellDef>
                <mat-form-field class="filter">
                  <input autocomplete="new-patientId" matInput formControlName="patientId" />
                  <mat-placeholder class="center">Patient Id</mat-placeholder>
                </mat-form-field>
              </th>
              <td mat-cell *matCellDef="let element">
                <a matTooltip="Click to View Patient" class="eyeCursorclass"
                  [routerLink]="'/patientCreateEditTab/' + element?.patientId" target="_blank">
                  {{ element?.defaultPatientId }}
                </a>
              </td>
            </ng-container>

            <ng-container matColumnDef="postingDate">
              <th class="header" mat-header-cell *matHeaderCellDef>
                <mat-form-field class="filter">
                  <input autocomplete="new-postingDate" matInput formControlName="postingDate" />
                  <mat-placeholder class="center">Posting Date</mat-placeholder>
                  <mat-error *ngIf="
                      chequeForm?.get('postingDate')?.touched &&
                      chequeForm?.get('postingDate')?.errors?.dateVaidator
                    ">
                    Enter Valid date
                  </mat-error>
                </mat-form-field>
              </th>
              <td mat-cell *matCellDef="let element">
                {{
                element?.postingDate === ""
                ? ""
                : (element?.postingDate | date: "MM/dd/yyyy":"en_US")
                }}
              </td>
            </ng-container>

            <ng-container matColumnDef="patientName">
              <th class="header" mat-header-cell *matHeaderCellDef>
                <mat-form-field class="filter">
                  <input autocomplete="new-patientName" matInput formControlName="patientName" />
                  <mat-placeholder class="center">Patient Name</mat-placeholder>
                </mat-form-field>
              </th>
              <td mat-cell *matCellDef="let element">
                {{ element?.patientName }}
              </td>
            </ng-container>

            <ng-container matColumnDef="billed">
              <th class="header" mat-header-cell *matHeaderCellDef>
                <mat-form-field class="filter">
                  <input autocomplete="new-billedAmount" matInput formControlName="billedAmount" />
                  <mat-placeholder class="center">Billed Amount</mat-placeholder>
                </mat-form-field>
              </th>
              <td mat-cell *matCellDef="let element">${{ element?.billed }}</td>
            </ng-container>

            <ng-container matColumnDef="currentBalance">
              <th class="header" mat-header-cell *matHeaderCellDef>
                <mat-form-field class="filter">
                  <input autocomplete="new-currentBalance" matInput formControlName="currentBalance" />
                  <mat-placeholder class="center">Current Balance</mat-placeholder>
                </mat-form-field>
              </th>
              <td mat-cell *matCellDef="let element">
                ${{
                element?.currentBalance === "" ||
                element?.currentBalance === null
                ? 0
                : element?.currentBalance
                }}
              </td>
            </ng-container>

            <ng-container matColumnDef="checkDate">
              <th class="header" mat-header-cell *matHeaderCellDef>
                <mat-form-field class="filter">
                  <input autocomplete="new-checkDate" matInput formControlName="checkDate" />
                  <mat-placeholder class="center">Cheque Date</mat-placeholder>
                </mat-form-field>
              </th>
              <td mat-cell *matCellDef="let element">
                {{ element?.checkDate | date: "MM/dd/yyyy":"en_US" }}
              </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="arrDisplayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: arrDisplayedColumns"></tr>
          </table>
          <div *ngIf="
             !( dataSource && dataSource?.data && dataSource?.data?.length !== 0)
            " class="text-align-center">
            <div class="message py-3" *ngIf="!isLoading">
              {{ "No Records Found" }}
            </div>
            <div class="py-3" [hidden]="!isLoading">
              <mat-spinner [diameter]="220"></mat-spinner>
            </div>
          </div>
          <mat-paginator [pageSizeOptions]="[10, 20, 50, 100]" showFirstLastButtons></mat-paginator>
        </div>
      </form>
    </div>
  </div>
</div>