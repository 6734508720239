import { ListService } from '@abp/ng.core';
import { DatePipe } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, Injectable, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatButton } from '@angular/material/button';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { OAuthService } from 'angular-oauth2-oidc';
import { DashboardService } from 'projects/admin/src/app/admin-proxy/platform-app-management/rcm/platform-management/dashboard/dashboard.service';
import { UserService } from 'projects/admin/src/app/admin-proxy/platform-app-management/rcm/platform-management/user/user.service';
import { TableService } from 'projects/shared/src/app/table.service';
import { DateValidator } from 'projects/shared/src/app/validations/date-validator';
import { BehaviorSubject, Subscription } from 'rxjs';
import { distinctUntilChanged, filter, map, startWith, switchMap, takeWhile } from 'rxjs/operators';
import Swal from 'sweetalert2';

import { DocumentsService } from '../patient-proxy/controllers/document.service';
import { PatientService } from '../patient-proxy/patient';
import { AutoIntakeDocumentDetailsDTO, PatientDTO, PatientExportDatas, PatientExportDTO, SearchPatientDTO } from '../patient-proxy/patient/dto/models';
import { ExportType } from '../patient-proxy/patient/enumeration-data/export-type.enum';
import { PatientSearchService } from '../patient-proxy/patient/patient-search.service';
import { ReportService } from '../patient-proxy/patient/report.service';
import { ToastrService } from "ngx-toastr";

function _window(): any {
  return window;
}

@Injectable()
export class WindowRef {
  get nativeWindow(): any {
    return _window();
  }
}

@Component({
  selector: 'app-patient-search',
  templateUrl: './patient-search.component.html',
  styleUrls: ['./patient-search.component.scss'],
  providers: [ListService, WindowRef, DatePipe],
})
export class PatientSearchComponent implements OnInit, OnDestroy {
  isLoadingResults: boolean = false;
  isLoading: boolean;
  isShowProgress: boolean;
  subscription$: Subscription[] = [];
  displayedColumns: string[] = ['Name', 'Patient Id', 'options', 'SSN', 'Email Address', 'Date Of Birth', 'Moblie', 'currStatus', 'LockStatus'];
  PATIENTDATA: Patient[] = [];
  dataSource = new MatTableDataSource(this.PATIENTDATA);
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild('MatPaginator', { static: false }) paginator: MatPaginator;
  personalForm: FormGroup;
  organizationUnitId = null;
  isAlive: boolean = true;
  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    public list: ListService,
    public title: Title,
    public userProfileService: UserService,
    private tableService: TableService,
    private patientSearchService: PatientSearchService,
    private snackBar: MatSnackBar,
    private documentService: DocumentsService,
    private dateValidator: DateValidator,
    private oauthService: OAuthService,
    private dashboardService: DashboardService,
    private reportService: ReportService,
    private patientService: PatientService,
    private toastr: ToastrService,
  ) {
    this.personalForm = this.formBuilder.group({
      Name: '',
      PatientId: '',
      Address: '',
      SSN: '',
      AccountNumber: '',
      EmailAddress: '',
      DateOfBirth: new FormControl('', this.dateValidator.dateVaidator),
      Moblie: '',
      LockStatus: '',
      currStatus: '',
    });
    /// patient list search based on value changes
    // const valueChanges = this.personalForm.valueChanges
    //   .pipe(distinctUntilChanged(),
    //     startWith({ PatientId: "", Name: "", Address: "", SSN: "", AccountNumber: "", EmailAddress: "", DateOfBirth: "", Moblie: "", LockStatusWithName: "", currStatus: "" }),
    //     filter(e => e && this.personalForm.valid),
    //     map((value: any) => {
    //       this.isLoading = true;
    //       this.isShowProgress = true;
    //       const sValue: Patient = {
    //         PatientId: value?.PatientId?.trim()?.toLowerCase(),
    //         Name: value?.Name?.trim()?.toLowerCase(),
    //         Address: value?.Address?.trim()?.toLowerCase(),
    //         SSN: value?.SSN?.trim()?.toLowerCase(),
    //         AccountNumber: value?.AccountNumber?.trim()?.toLowerCase(),
    //         EmailAddress: value?.EmailAddress?.trim()?.toLowerCase(),
    //         DateOfBirth: value?.DateOfBirth?.trim()?.toLowerCase(),
    //         Moblie: value?.Moblie?.trim()?.toLowerCase(),
    //         LockStatusWithName: value?.LockStatusWithName?.trim()?.toLowerCase(),
    //         currStatus: value?.currStatus?.trim()?.toLowerCase(),
    //       };
    //       return sValue;
    //     }),
    //     switchMap((sValue) =>
    //       this.patientSearchService.searchPatientBySPatientNameAndSDefaultPatientIdAndSSSNAndSAccountNumberAndSEmailIdAndSMobileAndSFaxAndSAddressAndDtDOBAndSLockStatusAndSSearchNameAndSCurrentStatus(
    //         sValue?.Name, sValue?.PatientId, sValue?.SSN, sValue?.AccountNumber, sValue?.EmailAddress,
    //         sValue?.Moblie, sValue?.Address, sValue?.Address, sValue?.DateOfBirth, sValue?.LockStatus === true ? '' : '',
    //         null, sValue?.LockStatusWithName
    //       )
    //     )).subscribe(response => {
    //       this.PATIENTDATA = [];
    //       response && response?.items && this.setPatientTable(response);
    //       this.isShowProgress = false;
    //       this.isLoading = false;
    //       this.dataSource = new MatTableDataSource(this.PATIENTDATA);
    //       this.dataSource.sort = this.sort;
    //       this.dataSource.paginator = this.paginator;
    //     },
    //       err => {
    //         this.isShowProgress = false;
    //         this.isLoading = false;
    //         this.dataSource = new MatTableDataSource(this.PATIENTDATA);
    //         this.dataSource.sort = this.sort;
    //         this.dataSource.paginator = this.paginator;
    //         const data: HttpErrorResponse = err;
    //         Swal.fire({
    //           icon: 'error',
    //           text: data?.error?.error?.message,
    //         });
    //       });
    const valueChanges = this.personalForm.valueChanges
      .pipe(distinctUntilChanged(),
        startWith({ PatientId: "", Name: "", Address: "", SSN: "", AccountNumber: "", EmailAddress: "", DateOfBirth: "", Moblie: "", LockStatusWithName: "", currStatus: "" }),
        filter(e => e && this.personalForm?.valid),
        map((value: any) => {
          this.setPatientTable([]);
          this.PATIENTDATA = [];
          this.isLoading = true;
          this.isShowProgress = true;
          const sValue: Patient = {
            PatientId: value?.PatientId?.trim()?.toLowerCase(),
            Name: value?.Name?.trim()?.toLowerCase(),
            Address: value?.Address?.trim()?.toLowerCase(),
            SSN: value?.SSN?.trim()?.toLowerCase(),
            AccountNumber: value?.AccountNumber?.trim()?.toLowerCase(),
            EmailAddress: value?.EmailAddress?.trim()?.toLowerCase(),
            DateOfBirth: value?.DateOfBirth?.trim()?.toLowerCase(),
            Moblie: value?.Moblie?.trim()?.toLowerCase(),
            LockStatusWithName: value?.LockStatusWithName?.trim()?.toLowerCase(),
            currStatus: value?.currStatus?.trim()?.toLowerCase(),
          };
          return sValue;
        }),
        switchMap((sValue) =>
          //   sPatientName, sDefaultPatientId, sSSN, sEmailId, sMobile, dtDOB, sLockStatus, sCurrentStatus
          this.patientSearchService.searchPatientByParametersBySPatientNameAndSDefaultPatientIdAndSSSNAndSEmailIdAndSMobileAndDtDOBAndSLockStatusAndSCurrentStatus(
            sValue?.Name, sValue?.PatientId, sValue?.SSN, sValue?.EmailAddress, sValue?.Moblie, (sValue?.DateOfBirth), sValue?.AccountNumber, sValue.currStatus
          )
        )).subscribe(response => {
          response && response && this.setPatientTable(response);
          this.isShowProgress = false;
          this.isLoading = false;
        },
          err => {
            this.setPatientTable([]);
            this.isLoading = false;
            this.isShowProgress = false;
            const data: HttpErrorResponse = err;
            Swal.fire({
              icon: 'info',
              text: data?.error?.error?.message,
            });
          });
    this.subscription$?.push(valueChanges);
  }

  dateOnChange(){

  }
  setPatientTable(response: SearchPatientDTO[] = []) {
    response && response?.forEach((element) => {
      // const dateOfBirth = dateTimeZoneChangertoMMDDYYYY(element?.dob);
      this.PATIENTDATA?.push({
        id: element?.patientId,
        PatientId: element?.defaultPatientId?.toString(),
        // Address:element?.addresses[0]?.address === null || undefined
        // ? '' : element?.addresses[0]?.address,
        Name: element?.patientName ?? '',
        DateOfBirth: element?.dob,
        SSN: element?.ssn ?? "",
        // AccountNumber:
        // element?.accountNumber === null || undefined
        // ? '' : element?.personals?.accountNumber,
        EmailAddress: element?.email ?? "",
        Moblie: element?.mobile ?? "",
        LockStatusWithName: element?.lockStatus ?? "",
        currStatus: element?.currentStatus,
      });
    });
    this.dataSource = new MatTableDataSource(this.PATIENTDATA);
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }
  ngOnDestroy(): void {
    this.isAlive = false;
    this.subscription$?.forEach((sub) => {
      sub && sub?.unsubscribe();
    });
  }
  isPatientCreatePermission: boolean = false;
  isPatientViewPermission: boolean = false;
  isPatientUpdatePermission: boolean = false;
  isShownPatientTable: boolean = true;
  isShownSaveButton: boolean = true;
  isShownUpdateIconForPatient: boolean = true;
  strLoginUserId: string = '';
  strLoginUserName: string = '';
  tenantId: string = '';
  @ViewChild('btnRef') buttonRef: MatButton;
  message: BehaviorSubject<any>;
  ngOnInit(): void {
    this.tableService.requestPermission();
    this.tableService.receiveMessage();
    const corrtListMessage = this.tableService.crrtListMessage.subscribe(
      () => { },
      (err) => {
        console.warn('Unable to get permission to notify.', err);
      }
    );
    this.subscription$.push(corrtListMessage);
    //
    this.message = this.tableService.currentMessage;
    // this.loadUserProfile();
    const loginUserId = this.tableService.getLoginUserId().subscribe((val) => {
      this.strLoginUserId = val;
    });
    this.subscription$.push(loginUserId);
    //
    const tokenRefreshError = 'token_refresh_error';
    const tokenInvalidGrantError = 'invalid_grant';
    const refreshTokenNotFound = 'refresh_token_not_found';

    if (!this.oauthService.events) {
      return;
    }

    const oauthEvents = this.oauthService?.events?.subscribe((response: any) => {
      if (response?.type === tokenRefreshError) {
        // If auth error is invalid grant or refresh error or refresh token not found
        if (
          (response?.reason && response?.reason?.error) ||
          response?.reason?.error.error === tokenInvalidGrantError ||
          response?.reason?.error.error_reason === refreshTokenNotFound
        ) {
          Swal.fire({
            html: '<b>Session Expired, Please Login Again..</b>',
            showCancelButton: true,
            confirmButtonColor: '#50a5f1',
            cancelButtonColor: '#6c757d',
            confirmButtonText: 'Login',
          }).then((result) => {
            if (result.value) {
              // this.oauthService.logOut(); //To logout user
              // localStorage.clear(); // To clear local storage & Session Storage
              // sessionStorage.clear();
              // (window as any).removeAllListeners();
          //    this.oauthService.loadDiscoveryDocument(); // To load Identity server Login Page
            } else if (result.value == undefined || false) {
              // this.oauthService.logOut();
              // localStorage.clear();
              // sessionStorage.clear();
              // (window as any).removeAllListeners();
              // this.oauthService.loadDiscoveryDocument();
            }
          });
        }
      }
    });
    this.subscription$.push(oauthEvents);
    //
    this.tableService.clearPatientLockStatus();
    const loginUserName = this.tableService.getLoginUserName().subscribe((val) => {
      this.strLoginUserName = val;
    });
    this.subscription$.push(loginUserName)
    //
    this.snackBar.dismiss();

    // setTimeout(() => {
    const getPatientView = this.tableService
      .getPatientView()
      .pipe(takeWhile(() => this.isAlive))
      .subscribe((value) => {
        this.isPatientViewPermission = value;
        this.tableService
          .getOrganizationUnitId()
          .pipe(takeWhile(() => this.isAlive))
          .subscribe((value) => {

            this.organizationUnitId = value;
            if (
              this.organizationUnitId == null ||
              this.organizationUnitId == '' ||
              this.organizationUnitId == undefined
            ) {
              this.isLoading = true;
              this.isAlive = false;
            } else {
              this.isLoading = false;
              this.isAlive = false;
            }
          });
      });
    this.subscription$.push(getPatientView);
    //
    const patientCreate = this.tableService.getPatientCreate().subscribe(
      (value) => {
        this.isPatientCreatePermission = value;
        if (this.isPatientCreatePermission == true) {
          this.isShownSaveButton = true;
        } else {
          this.isShownSaveButton = false;
        }
      },
      (err) => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      }
    );
    this.subscription$.push(patientCreate)
    // }, 1000);

    const patientUpdate = this.tableService.getPatientUpdate().subscribe(
      (value) => {
        this.isPatientUpdatePermission = value;
        if (this.isPatientUpdatePermission == true) {
          this.isShownUpdateIconForPatient = true;
        } else {
          this.isShownUpdateIconForPatient = false;
        }
      },
      (err) => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      }
    );
    this.subscription$.push(patientUpdate);
    //
    this.title.setTitle('Qsecure | Search Patient');
    this.dataSource = new MatTableDataSource();
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
    this.getdropdowns();
  }
  alphaOnly(event): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (
      charCode > 32 &&
      (charCode < 65 || charCode > 90) &&
      (charCode < 97 || charCode > 122)
    ) {
      return false;
    }
    return true;
  }

  loadUserProfile() {
    const loadProfile = this.dashboardService.userProfile().subscribe(
      (response) => {
        this.organizationUnitId = response.organizationUnitId;
      },
      (err) => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      }
    );
    this.subscription$.push(loadProfile);
  }

  // ! Document State Clear on New Patient
  newPatient() {
    this.tableService.setDocumentFormData(null);
    this.router.navigate(['patientCreateEdit']);
  }


  numberOnly(event): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  // ! Document State Clear on Exisiting  Patient
  viewPatient(patientId: string) {
    const storeSubscription = patientId !== '' &&
      patientId !== null &&
      patientId !== undefined &&
      this.patientService.get(patientId).subscribe(response => {

        const selectedPatient: PatientDTO =
          response;
        this.tableService.setCountryTable(selectedPatient);
      },
        (err) => {
          const data: HttpErrorResponse = err;
          Swal.fire({
            icon: 'info',
            text: data?.error?.error?.message,
          });
        }
      );
    this.subscription$.push(storeSubscription);
    this.tableService.setDocumentFormData(null);
    this.router.navigate(['/patientViewEdit/' + patientId]);
  }



  // ! Master Dropdown Funtion
  getdropdowns() { }
  downloadAutoUploadLastDocument(
    lastAutoDocumentBlob: AutoIntakeDocumentDetailsDTO
  ) {
    if (
      typeof lastAutoDocumentBlob?.blobName === 'string' &&
      lastAutoDocumentBlob?.blobName
    ) {
      const patientDocument = this.documentService
        .getPatientDocumentByBlobIDByBlobName(lastAutoDocumentBlob.blobName)
        .subscribe(
          (response) => {
            if (
              typeof response === 'string' &&
              response &&
              response?.length !== 0
            ) {
              const filelocation: String = String(
                'data:application/pdf;base64,' + response
              );
              const link = document.createElement('a');
              link.download = lastAutoDocumentBlob?.fileName + '.pdf';
              link.href = filelocation.toString();
              link.click();
            }
          },
          (err) => {
            const data: HttpErrorResponse = err;
            Swal.fire({
              icon: 'info',
              text: data?.error?.error?.message,
            });
          },
          () => { }
        );
      this.subscription$.push(patientDocument);
    }
  }
  patientPush: any[] = [];
  /// export data from table list
  exportTableData() {
    const datas: PatientExportDatas[] = [];
    this.dataSource?.data?.forEach((element) => {
      datas.push({
        patientName: element?.Name,
        patientDefaultId: element?.PatientId,
        ssn: element?.SSN,
        emailAddress: element?.EmailAddress,
        dateOfBirth: element?.DateOfBirth,
        mobile: element?.Moblie,
        currentStatus: element?.currStatus,
      });
    });
    const patientsExport: PatientExportDTO = {
      columns: [
        'Patient Name',
        'Patient Id',
        'SSN',
        'Email Address',
        'Date of Birth',
        'Mobile',
        'Current Status',
        'Lock Status',
      ],
      datas,
    };
    const patientExport = this.reportService
      .exportPatientReportsByExportTypeAndExportDatas(
        ExportType.PatientSearchReports,
        patientsExport
      )
      .subscribe(
        (response) => {
          if (response && response?.length !== 0) {
            const filelocation: String = String(
              'data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,' +
              response
            );
            const link = document.createElement('a');
            link.download = 'Patient-Search' + '.xlsx';
            link.href = filelocation.toString();
            link.click();
            // Swal.fire({
            //   title: 'Success',
            //   html: 'Exported Successfully',
            //   icon: 'success',
            //   timer: 3000,
            //   timerProgressBar: true,
            // });
            this.toastr.success('Exported Successfully','Success')
          }
        },
        (err) => {
          const data: HttpErrorResponse = err;
          let errorMesg: string = data?.error;
          if (
            errorMesg?.includes('Sorry!! There is no data in table to export')
          ) {
            errorMesg = 'Sorry!! There is no data in table to export';
          } else {
            errorMesg = data?.error?.error?.message;
          }
          // Swal.fire({
          //   icon: 'info',
          //   text: errorMesg,
          // });
          this.toastr.info(errorMesg)
        }
      );
    this.subscription$.push(patientExport);
  }
}
export interface Patient {
  id?: string;
  Name?: string;
  PatientId?: string;
  Address?: string;
  SSN?: string;
  AccountNumber?: string;
  EmailAddress?: string;
  DateOfBirth?: string;
  Moblie?: string;
  isAutoIntake?: number;
  autoIntakeDocumentDetails?: AutoIntakeDocumentDetailsDTO | null;
  LockStatus?: boolean;
  LockStatusWithName?: string;
  UserId?: string;
  TenantId?: string;
  currStatus: string;
}
// Date TimeZone changer
export function dateTimeZoneChangertoMMDDYYYY(strDate: string): string {
  if (strDate && strDate !== "" && typeof strDate === 'string' && strDate !== null && strDate !== undefined && strDate.length >= 1 && !isNaN(new Date(strDate).getTime())) {
    const latest_date = Intl.DateTimeFormat('en-US')?.format(new Date(strDate))
    return latest_date;
  }
  return "";
}
