<br />
<div class="card card-body">
    <mat-accordion #firstAccordion="matAccordion">
        <mat-expansion-panel [expanded]="step">
            <mat-expansion-panel-header>
                <mat-panel-title class="customThemeClass">
                    <ng-container *ngIf="appointTypeId===''; else elseTitleTemplate">
                        <b>Add Appointment Type</b>
                    </ng-container>
                    <ng-template #elseTitleTemplate>
                        <b>Edit Appointment Type</b>
                    </ng-template>
                </mat-panel-title>

            </mat-expansion-panel-header>
            <form [formGroup]="AppontmentForm">
                <div class="row">
                    <mat-form-field class="col-4">
                        <mat-label> Appointment Type <span class="asterisk">*</span></mat-label>
                        <input autocomplete="new-txtAppointType" matInput maxlength="40" formControlName="txtAppointType" type="text" />
                        <mat-error
                            *ngIf="AppontmentForm?.get('txtAppointType')?.touched && AppontmentForm?.get('txtAppointType')?.errors?.required">
                            Birth Date is a required field!
                        </mat-error>
                    </mat-form-field>


                    <div class="col-3 time">
                        <mat-label> Duration <span class="asterisk">*</span></mat-label>
                        <div class="ngx-timepicker-field-example">
                            <ngx-timepicker-field [disabled]="durationShow" formControlName="txtDuration" [format]="24">
                            </ngx-timepicker-field>
                            <mat-error
                                *ngIf="AppontmentForm?.get('txtDuration')?.touched && AppontmentForm?.get('txtDuration')?.errors?.required">
                                Birth Date is a required field!
                            </mat-error>
                        </div>
                    </div>

                    <!-- <div *ngIf="isShowActive" class="col-md-3">
                        <br>
                        <mat-checkbox class="padd" (change)="statusChange($event)" formControlName="chkActive">Active
                        </mat-checkbox>
                    </div> -->
                </div>
            </form>
            <br />
            <div class="row">
                <div class="col-sm-12">
                    <div class="text-lg-center">
                        <button mat-button class="buttonColor mr-2"
                            [disabled]="!( !AppontmentForm.invalid && AppontmentForm.dirty )"
                            (click)="saveAppointment()">
                            Save
                            <mat-icon *ngIf='isShownSaveButton'>
                                <mat-spinner class="spinner-border spinner-border" diameter="60"> </mat-spinner>
                            </mat-icon>
                        </button>
                        <button mat-button class="resetclr buttonColor mb-2 mr-2" (click)="reset()">Reset</button>
                    </div>
                </div>
            </div>
        </mat-expansion-panel>
    </mat-accordion>
    <!-- <h4 class="customThemeClass ml-3 mt-2 mb-0"><b>Appontment Type Details</b></h4> -->

    <div class="row">
        <div class="col-12 table-responsive">
            <!-- <div [hidden]="!isLoading"
                style="display: flex; justify-content: center; align-items: center; background: transparent; color: lightseagreen;">
                <mat-progress-bar mode="indeterminate" value='66' diameter="45" class="lightseagreen">
                </mat-progress-bar>
            </div> -->
            <table mat-table class="table ml-4 border" matSort [dataSource]="dataSource">
                <form style="display: flex" [formGroup]="appointmentSearchForm">

                    <ng-container matColumnDef="sno">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>S.No</th>
                        <td mat-cell *matCellDef="let data; let i = index">
                            {{ i+1 }}
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="Appointment Type">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                            <mat-form-field class="filter">
                                <input autocomplete="new-appointmentTypeSearch" matInput formControlName="appointmentTypeSearch" />
                                <mat-placeholder>Appointment Type</mat-placeholder>
                            </mat-form-field>
                        </th>
                        <td mat-cell *matCellDef="let data">
                            <ng-container>
                                {{data.Type}}
                            </ng-container>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="Duration">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                            <mat-form-field class="filter">
                                <input autocomplete="new-durationSearch" matInput formControlName="durationSearch" />
                                <mat-placeholder>Duration</mat-placeholder>
                            </mat-form-field>
                        </th>
                        <td mat-cell *matCellDef="let data">
                            <ng-container>
                                {{data.Duration}}
                            </ng-container>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="Date">
                        <th class="header" mat-header-cell *matHeaderCellDef>
                            <mat-form-field class="filter">
                                <mat-label> Create Date </mat-label>
                                <input autocomplete="new-Date" matInput formControlName="Date" placeholder="(MM/DD/YYYY)" />
                                <mat-error
                                    *ngIf=" appointmentSearchForm?.get('Date')?.touched && appointmentSearchForm?.get('Date')?.errors?.dateVaidator">
                                    Enter Valid date
                                </mat-error>
                            </mat-form-field>
                        </th>
                        <td mat-cell *matCellDef="let element"> {{element.createDate}} </td>
                    </ng-container>


                    <ng-container matColumnDef="Status">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                            <!-- <mat-form-field class="filter">
                            <input matInput formControlName="status" />
                            <mat-placeholder> Status</mat-placeholder>
                          </mat-form-field> -->
                            <mat-label class="text-center">Status </mat-label>
                        </th>
                        <td mat-cell *matCellDef="let data">
                            <ng-container *ngIf="data?.status==='Active'">
                                <mat-slide-toggle (change)="appointmentStatusChange($event, data)" [checked]='true'
                                    class="ml-2">
                                    {{data?.status}}</mat-slide-toggle>
                            </ng-container>
                            <ng-container *ngIf="data?.status==='Inactive'">
                                <mat-slide-toggle (change)="appointmentStatusChange($event, data)" [checked]='false'
                                    class="ml-2">
                                    {{data?.status}}</mat-slide-toggle>
                            </ng-container>
                        </td>
                    </ng-container>

                    <!-- <ng-container matColumnDef="Status">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                            <mat-form-field class="filter">
                                <input matInput formControlName="statusSearch" />
                                <mat-placeholder>Status</mat-placeholder>
                            </mat-form-field>
                        </th>
                        <td mat-cell *matCellDef="let data">
                            <ng-container>
                                {{data.stats}}
                            </ng-container>
                        </td>
                    </ng-container> -->

                    <ng-container matColumnDef="Options">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Options</th>
                        <td mat-cell *matCellDef="let data">
                            <a class="editBtnColor" (click)="ViewAppointment(data)">
                                <i *ngIf="data?.status==='Active'" class="fa fa-pencil pointer editBtnColor mr-3"
                                    matTooltip="Click To Edit" ></i>
                            </a>
                            &nbsp;
                            <a class="deleteBtnColor" (click)="deleteAppointment(data)">
                                <i *ngIf="data?.status==='Active'" class="fa fa-trash-o" matTooltip="Click To Delete"
                                    ></i>
                            </a>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="noRecords">
                        <td class="text-align-center" mat-footer-cell *matFooterCellDef colspan="6">
                            <div *ngIf="!isLoading">
                                {{"No Records Found"}}
                            </div>
                            <div [hidden]="!isLoading">
                                <mat-spinner [diameter]="220"></mat-spinner>
                            </div>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="paginator">
                        <td mat-footer-cell *matFooterCellDef colspan="6">
                            <mat-paginator class="table table-responsive mat-table-class" #MatPaginator
                                [length]="dataSource?.data?.length" [pageSize]="10" [pageIndex]="0" showFirstLastButtons
                                [pageSizeOptions]="[5, 10, 25, 100]">
                            </mat-paginator>
                        </td>
                    </ng-container>
                </form>
                <tr mat-footer-row *matFooterRowDef="['noRecords']"
                    [ngClass]="{ active: dataSource && dataSource?.data && dataSource?.data?.length !== 0 }"></tr>
                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
                <tr mat-footer-row *matFooterRowDef="['paginator']"></tr>
            </table>
        </div>
    </div>


</div>
<div class="row"></div>
