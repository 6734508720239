import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { PlanTypeDTO } from 'projects/patient/src/app/dropdown-proxy/dropdown-management/dropdowns/dto/order';

@Component({
  selector: 'app-primary-ins',
  templateUrl: './primary-ins.component.html',
  styleUrls: ['./primary-ins.component.scss']
})
export class PrimaryInsComponent implements OnInit {
  primaryInsForm1: FormGroup
  primaryInsForm2: FormGroup
  drpPlanType: PlanTypeDTO[] = [];
  constructor(
    private formBuilder: FormBuilder
  ) { }

  ngOnInit(): void {
    this.primaryInsForm1 = this.formBuilder.group({
      txtChartNo: new FormControl(''),
      txtName: new FormControl(''),
      txtAddress: new FormControl(''),
      txtDOB: new FormControl(''),
      txtNotes: new FormControl('')
    })
    this.primaryInsForm2 = this.formBuilder.group({
      drpPlan: new FormControl(''),
      txtPlanPhone: new FormControl(''),
      drpPlanName: new FormControl(''),
      txtPolicy: new FormControl(''),
      txtGroup: new FormControl(''),
      txtEffectiveDate: new FormControl(''),
      drpType: new FormControl(''),
      txtFascalYearStart: new FormControl(''),
      txtEnd: new FormControl(''),
      txtCmnCode: new FormControl(''),
      chkMedicare: new FormControl(''),
      chkFamilyDeductible: new FormControl(''),
      drpTypeOfPlan: new FormControl(''),
      chkIndividual: new FormControl(''),
      chkFamily: new FormControl(''),
      chkOutOfNetwork: new FormControl(''),
    })


  }

}
