import { mapEnumToOptions } from '@abp/ng.core';

export enum MedicareStatus {
  New = 'New',
  Approved = 'Approved',
  Disapproved  = 'Disapproved',
  Pending = 'Pending',
  Submitted = 'Submitted',
}

export const medicareStatusOptions = mapEnumToOptions(MedicareStatus);
