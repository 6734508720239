import { DatePipe } from '@angular/common';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { DataTableDirective } from 'angular-datatables';
import { defaultGuid } from 'projects/shared/src/app/enums/allenums.enum';
import { Observable, Subscription } from 'rxjs';
import { InvoiceReportService } from '../../billing-proxy/billing-management/rcm/billing-management/optimization/billing-reports/invoice-report.service';
import { map, startWith } from 'rxjs/operators';
import {
  clsInvoiceReportSearch,
  clsInvoiceStatusDTO,
  clsSearch,
} from '../../billing-proxy/billing-management/rcm/billing-management/optimization/dto/models';
import { MatSnackBar } from '@angular/material/snack-bar';
import { HttpErrorResponse } from '@angular/common/http';
import { CommonService } from 'projects/shared/src/app/services/common.service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { AddNoteTabComponent } from 'projects/patient/src/app/add-note-tab/add-note-tab.component';
import { ToastrService } from 'ngx-toastr';
import { PatientCommonNotesComponent } from 'projects/shared/src/app/components/patient-common-notes/patient-common-notes.component';
import { ViewPatientLedgerComponent } from 'projects/admin/src/app/view-patient-ledger/view-patient-ledger.component';
import { UploadEOBComponent } from '../../claim-status-list/claim-status-list-table/upload-eob/upload-eob.component';
import { MatSelectChange } from '@angular/material/select';

@Component({
  selector: 'app-billing-invoice',
  templateUrl: './billing-invoice.component.html',
  styleUrls: ['./billing-invoice.component.scss'],
})
export class BillingInvoiceComponent implements OnInit {
  DataList: any[];
  invoiceSearchForm: FormGroup;
  @ViewChild(DataTableDirective, { static: false })
  datatableElement: DataTableDirective;
  invoiceTable: DataTables.Api;
  defaultGuid = defaultGuid;
  billingInvoiceTable: any = {
    responsive: true,
    paging: false,
    dom: 'Bfrtip',
    scrollCollapse: true,
    scrollY: '500px',
    scrollX: true,
    search: {
      smart: false,
    },
    initComplete: () => {
      this.expandAllRows();
    },
    language: {
      emptyTable: 'No records',
      info: 'Total : _MAX_ records',
      infoEmpty: 'Total : _MAX_ records',
      infoFiltered: '(filtered : _TOTAL_ records)',
      loadingRecords: 'Loading...',
      zeroRecords: 'No matching records',
    },
    order: [],
    columnDefs: [{ targets: 1, orderable: false }],
    buttons: [
      {
        extend: 'excel',
        text: 'Export', // Change the text to 'Export'
        filename: 'Invoice List',
        exportOptions: {
          // columns: ':not(:first-child),:nth-child(2)', // Skip the first column and 2nd column
          columns: function (idx, data, node) {
            return idx !== 0 && idx !== 1;
          }
        },
      },
    ],
  };
  isLoading: boolean = true;
  drpPatientId : any[] = [];
  drpInvoiceId : any[] = [];
  drpStatusId : any[] = [];
  public filteredPatient: Observable<string[]> | undefined;
  public filteredInvoice: Observable<string[]> | undefined;
  public filteredStatus: Observable<string[]> | undefined;
  isdrpLoading: boolean;
  isdrpInvoiceLoading: boolean;
  loadPatientDropDownAPICall: Subscription;
  loadInvoiceDropDownAPICall: Subscription;
  loadStatusDropDownAPICall: Subscription;
  loadInvoiceTableAPICall: Subscription;
  $subscription: Subscription[] = [];
  selectPatientList: any;
  selectInvoiceList: any;
  selectStatusList: any;
  isInvoiceDropdownOpen = false;
  txtDOSFromDate: any;
  isPatinetDropdownOpen = false;
  chartNoSelectAllOption:boolean = false;
  selectedPatientChatNoList:string[]=[];
  selectedInvoiceIdList:string[]=[];
  selectedStatusIdList:string[]=[];
  invoiceIdSelectAllOption:boolean=false;
  statusIdSelectAllOption:boolean=false;
  constructor(
    private fb: FormBuilder,
    private datepipe: DatePipe,
    private invoiceReportService: InvoiceReportService,
    private _snackBar: MatSnackBar,
    private commonService: CommonService,
    private dialog: MatDialog,
    private toaster: ToastrService,
  ) {}

  ngOnInit(): void {

    const { firstDate, lastDate } = this.getFirstAndLastDatesOfCurrentMonth();
    this.billingInvoiceTable.buttons[0].filename =
      'Invoice List' + '-' + this.datepipe.transform(new Date(), 'MM-dd-yyyy');
    this.initializeForms();
    this.invoiceSearchForm.patchValue({
      txtDOSFromDate: firstDate,
      txtDOSEndDate: lastDate
    });
    this.loadPatientDropDown('');
    this.loadInvoiceDropDown('');
    this.loadStatusDropDown();
    this.getTableData();
  }
  ngOnDestroy(): void {
    if (this.loadPatientDropDownAPICall) {
      this.loadPatientDropDownAPICall.unsubscribe();
    }
    if (this.loadInvoiceDropDownAPICall) {
      this.loadInvoiceDropDownAPICall.unsubscribe();
    }
    if (this.loadStatusDropDownAPICall) {
      this.loadStatusDropDownAPICall.unsubscribe();
    }
    if (this.loadInvoiceTableAPICall) {
      this.loadInvoiceTableAPICall.unsubscribe();
    }
    this.$subscription.forEach((sub) => {
      sub && sub?.unsubscribe();
    });
  }
  initializeForms() {
    this.invoiceSearchForm = this.fb.group({
      drpPatient: new FormControl(''),
      txtPatientFilter: new FormControl(''),
      drpInvoice: new FormControl(''),
      txtInvoiceFilter: new FormControl(''),
      drpStatus: new FormControl(''),
      txtStatusFilter: new FormControl(''),
      txtDOSFromDate: new FormControl(''),
      txtDOSEndDate: new FormControl(''),
    });
  }

  isEmpty(value: any): boolean {
    return (
      value === null ||
      value === undefined ||
      value === '' ||
      value === defaultGuid
    );
  }
  onPatinetDropdownOpenChange(isOpen: boolean) {
    this.isPatinetDropdownOpen = isOpen;
    if (this.isPatinetDropdownOpen) {
      // this.invoiceSearchForm.value.txtPatientFilter || null
      this.loadPatientDropDown(
        ''
      );
    }
  }

  onInvoiceDropdownOpenChange(isOpen: boolean) {
    this.isInvoiceDropdownOpen = isOpen;
    if (this.isInvoiceDropdownOpen) {
      // this.invoiceSearchForm.value.txtInvoiceFilter || null
      this.loadInvoiceDropDown(
        ''
      );
    }
  }

  onSelectPatient(event: any) {
    this.getTableData();
  }
  onSelectInvoice(event: any) {
    this.getTableData();
  }
  onSelectStatus(event: any) {
    this.getTableData();
  }
  drpDownMessage() {
    if (this.isdrpLoading === false) {
      return 'No Matches found';
    } else {
      return 'Loading...';
    }
  }
  drpInvoiceDownMessage() {
    if (this.isdrpInvoiceLoading === false) {
      return 'No Matches found';
    } else {
      return 'Loading...';
    }
  }
  oldPatient:string;
  loadPatientDropDown(value: string, eventKey?: string) {
    if (
      eventKey !== 'ArrowDown' &&
      eventKey !== 'ArrowUp' &&
      eventKey !== 'ArrowRight' &&
      eventKey !== 'ArrowLeft' &&
      (value == '' || value?.length > 2 )
      && value != this.oldPatient
    ) {
      this.oldPatient = value;
      //Get Payer list
      let sSearch:clsSearch={
        sSearch : value,
        ltExisting: this.invoiceSearchForm.value.drpPatient || []
        // ltExisting: this.drpPatientId
      };
      this.isdrpLoading = true;
      if (this.loadPatientDropDownAPICall) {
        this.loadPatientDropDownAPICall.unsubscribe();
      }
      this.loadPatientDropDownAPICall = this.invoiceReportService
        .drpSearchPatientChartBySearch(sSearch)
        .subscribe((response) => {
          this.selectPatientList = response?.items || [];
          this.isdrpLoading = false;

          this.filteredPatient = this.invoiceSearchForm
            .get('txtPatientFilter')
            ?.valueChanges.pipe(
              startWith(''),
              map((value) =>
                this.selectPatientList?.filter((option) =>
                  option?.patientChart
                    ?.toLowerCase()
                    ?.includes(value?.toLowerCase() ?? '')
                )
              )
            );
        });
    }
  }
  oldInvoice : string;
  loadInvoiceDropDown(value: string, eventKey?: string) {
    if (
      eventKey !== 'ArrowDown' &&
      eventKey !== 'ArrowUp' &&
      eventKey !== 'ArrowRight' &&
      eventKey !== 'ArrowLeft'&&
      (value == '' || value?.length > 3 )
      && value != this.oldInvoice
    ) {
      this.oldInvoice=value;
      //Get Payer list
      this.isdrpInvoiceLoading = true;
      if (this.loadInvoiceDropDownAPICall) {
        this.loadInvoiceDropDownAPICall.unsubscribe();
      }
      let sSearch:clsSearch={
        sSearch : value,
        ltExisting: this.invoiceSearchForm.value.drpInvoice || []
        // ltExisting: this.drpInvoiceId
      };
      this.loadInvoiceDropDownAPICall = this.invoiceReportService
        .drpSearchInvoiceNumberBySearch(sSearch)
        .subscribe((response) => {
          this.selectInvoiceList = response?.items || [];
          this.isdrpInvoiceLoading = false;

          this.filteredInvoice = this.invoiceSearchForm
            .get('txtPatientFilter')
            ?.valueChanges.pipe(
              startWith(''),
              map((value) =>
                this.selectInvoiceList?.filter((option) =>
                  option?.defaultClaimId
                    ?.toLowerCase()
                    ?.includes(value?.toLowerCase() ?? '')
                )
              )
            );
        });
    }
  }
  loadStatusDropDown(eventKey?: string) {
    if (
      eventKey !== 'ArrowDown' &&
      eventKey !== 'ArrowUp' &&
      eventKey !== 'ArrowRight' &&
      eventKey !== 'ArrowLeft'
    ) {
      //Get Payer list
      // this.isdrpInvoiceLoading = true;
      if (this.loadStatusDropDownAPICall) {
        this.loadStatusDropDownAPICall.unsubscribe();
      }
      this.loadStatusDropDownAPICall = this.invoiceReportService
        .drpInvoiceStatus()
        .subscribe((response) => {
          this.selectStatusList = response?.items || [];
          // this.isdrpInvoiceLoading = false;

          this.filteredStatus = this.invoiceSearchForm
            .get('txtStatusFilter')
            ?.valueChanges.pipe(
              startWith(''),
              map((value) =>
                this.selectStatusList?.filter((option) =>
                  option?.statusName
                    ?.toLowerCase()
                    ?.includes(value?.toLowerCase() ?? '')
                )
              )
            );
        });
    }
  }
  focusOutDOS() {
    let txtDOSFromDate = this.datepipe.transform(
      this.invoiceSearchForm.value.txtDOSFromDate || null,
      'yyyy-MM-dd'
    );
    if (txtDOSFromDate !== this.txtDOSFromDate) {
      this.getTableData();
    }
  }
  clearPatient() {
    this.invoiceSearchForm.patchValue({
      drpPatient: null,
    });
    this.drpPatientId=[];
    this.chartNoSelectAllOption = false;
    this.getTableData();
  }
  clearInvoice() {
    this.invoiceSearchForm.patchValue({
      drpInvoice: null,
    });
    this.drpInvoiceId=[];
    this.invoiceIdSelectAllOption=false;
    this.getTableData();
  }
  clearStatus() {
    this.invoiceSearchForm.patchValue({
      drpStatus: null,
    });
    this.drpStatusId=[];
    this.statusIdSelectAllOption = false;
    this.getTableData();
  }
  clearDOS() {
    this.invoiceSearchForm.patchValue({
      txtDOSFromDate: null,
      txtDOSEndDate: null,
    });
  }
  clearAll() {
    const { firstDate, lastDate } = this.getFirstAndLastDatesOfCurrentMonth();
    this.invoiceSearchForm.reset();
    this.invoiceSearchForm.patchValue({
      txtDOSFromDate: firstDate,
      txtDOSEndDate: lastDate
    });
    this.drpPatientId=[];
    this.drpInvoiceId=[];
    this.drpStatusId=[];
    this.getTableData();
  }
  addSlashToDate(event: Event): void {
    const input = event.target as HTMLInputElement;
    const inputValue = input.value;
    input.value = inputValue
      .replace(/^(\d{2})(\d{1,2})/, '$1/$2')
      .replace(/^(\d{2}\/\d{2})(\d+)/, '$1/$2')
      .replace(/[^\d\/]/g, '');
  }

  updateTextColor(colorCode: string): string {
    if (this.isValidColorCode(colorCode)) {
      return this.getContrastingTextColor(colorCode);
    } else {
      return '#000000'; // default to black if colorCode is invalid
    }
  }

  isValidColorCode(colorCode: string): boolean {
    // Check if colorCode is a valid hex color code
    const regex = /^#([0-9A-F]{3}){1,2}$/i;
    return regex.test(colorCode);
  }

  getContrastingTextColor(hexColor: string): string {
    if (!hexColor) return '#000000'; // default to black if no color provided

    // Remove the hash if present
    hexColor = hexColor.replace('#', '');

    // Convert to RGB
    const r = parseInt(hexColor.substring(0, 2), 16);
    const g = parseInt(hexColor.substring(2, 4), 16);
    const b = parseInt(hexColor.substring(4, 6), 16);

    // Calculate luminance
    const luminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255;

    // If luminance is high, return dark color (black), otherwise return light color (white)
    return luminance > 0.5 ? '#000000' : '#FFFFFF';
  }

  getTableData() {
    this.txtDOSFromDate = this.datepipe.transform(
      this.invoiceSearchForm.value.txtDOSFromDate || null,
      'yyyy-MM-dd'
    );
    let txtDOSEndDate = this.datepipe.transform(
      this.invoiceSearchForm.value.txtDOSEndDate || null,
      'yyyy-MM-dd'
    );

    if (
      (this.txtDOSFromDate &&
        txtDOSEndDate &&
        this.txtDOSFromDate <= txtDOSEndDate) ||
      (!this.txtDOSFromDate && !txtDOSEndDate)
    ) {
      this.isLoading = true;

      // setTimeout(() => {
      //   this.isLoading = false;
      // }, 500);

      try {
        if (this.loadInvoiceTableAPICall) {
          this.loadInvoiceTableAPICall.unsubscribe();
        }

        let input: clsInvoiceReportSearch = {
          // ltPatients: this.drpPatientId.length == 0 ? null : this.drpPatientId,
          // ltClaims: this.drpInvoiceId.length == 0 ? null : this.drpInvoiceId,
          // ltStatus: this.drpStatusId.length == 0 ? null : this.drpStatusId,
          ltPatients: this.invoiceSearchForm.value.drpPatient || [],
          ltClaims: this.invoiceSearchForm.value.drpInvoice || [],
          ltStatus: this.invoiceSearchForm.value.drpStatus || [],
          dtDOSFrom: this.txtDOSFromDate,
          dtDOSTo: txtDOSEndDate
        };
        this.loadInvoiceTableAPICall = this.invoiceReportService
          .getInvoiceReportDetailsBySearch(input)
          .subscribe(
            (response) => {
              this.DataList = response?.items || [];
              this.DataList = this.DataList.map((invoice) => {
                return {
                  ...invoice,
                  dtDOB: this.commonService.getFormattedDateZone(invoice.dtDOB),
                  dtDOS: this.commonService.getFormattedDateZone(invoice.dtDOS),
                  creationTime: this.commonService.getFormattedDateTimeZone(
                    invoice.creationTime
                  ),
                  modifiedTime: this.commonService.getFormattedDateTimeZone(
                    invoice.modifiedTime
                  ),
                };
              });
              setTimeout(() => {
                this.isLoading = false;
              }, 500);
            },
            (err) => {
              this.DataList = [];
              const data: HttpErrorResponse = err;
              console.error('Unexpected error:', data?.error?.error?.message);
              setTimeout(() => {
                this.isLoading = false;
              }, 500);
            }
          );
        // this.tableData$ = getTableData;
      } catch (error) {
        console.error('Unexpected error:', error);
        setTimeout(() => {
          this.isLoading = false;
        }, 500);
      } finally {
      }
    } else {
      this._snackBar.open('Select Start / End Date!', 'Close', {
        duration: 2000,
      });
      this.isLoading = false;
    }
  }
  expandAllRows(): void {
    this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.rows().every((rowIdx, tableLoop, rowLoop) => {
        const row = dtInstance.row(rowIdx);

        const rowData = row.data();
        let status = this.DataList.find((a) => a.id == rowData[0])?.tagStatus;
        if (status?.length > 0) {
          row.child(this.format(row.data())).show();
        }
      });
    });
  }

  format(data: any) {
    let returnString: string = '';
    let status = this.DataList.find((a) => a.id == data[0]).tagStatus;
    status?.forEach(function (value, index) {
      let design = '';
      if (value.type === 1) {
        design +=
          ' <span class="ml-1 w-75 p-1 tag-status-label"> <img class="img-responsive pr-1" src="assets/Billing Icons/Tag.png" height="11"/>' +
          value.status +
          '</span>';
      } else if (value.type === 2) {
        design +=
          '<span class="rounded-border ml-1 w-75 p-1 niko-status-label">' +
          value.status +
          '</span>';
      }
      returnString += design;
    });
    return returnString;
  }

  openAddNotesPopUp(
    event: MouseEvent,
    data: any,
    isCallNote: boolean
  ) {
    const config: MatDialogConfig = {
      disableClose: true,
      minHeight: '30vh',
      minWidth: '50vw',
      data: {
        noteType: 'patient', // 'Order Note',
        notesId: '00000000-0000-0000-0000-000000000000',
        // orderId: '0b50be04-6c75-92d3-97c6-3a0e17ee6a18',
        claimId: data.claimId,
        // defaultFaxId: data.defaultFaxId,
        // isCallNote: isCallNote,
        // isPending: false,
        // docId: data.docId,
        // inboundStatusShortCodeId: defaultGuid,
        // selectedNode: null,
        addnotes: false,
        // ----- patientNotes: data.patientNotes,
        // orderId: data.orderId,
        patientId: data.patientId,
        chartNo: data.chartId,
      },
    };
    const dialogRef = this.dialog.open(AddNoteTabComponent, config);

    const closeDialog = dialogRef.afterClosed().subscribe(
      () => {
        // this.getReminderTableData();
      },
      (err) => {
        const data: HttpErrorResponse = err;
        this.toaster.error(data?.error?.error?.message);
      }
    );
    this.$subscription.push(closeDialog);
  }
  openViewNotesPopup(patientID) {
    const config: MatDialogConfig = {
      disableClose: true,
      width: '70vw',
      maxWidth: '70vw',
      height: '40vw',
      maxHeight: '40vw',
      data: {
        patientId: patientID,
        pageType: 'Patient Note',
        isAddVisible: false,
      },
    };
    const dialogRef = this.dialog.open(PatientCommonNotesComponent, config);

    dialogRef.afterClosed().subscribe(
      () => {
        //this.getReminderTableData();
      },
      (err: HttpErrorResponse) => {
        const data: HttpErrorResponse = err;
        this.toaster.error(data?.error?.error?.message);
      }
    );
  }
  viewPatientLedger(patientId: string) {
    const dialogRef = this.dialog.open(ViewPatientLedgerComponent, {
      width: '100vw',
      height: '100vh',
      maxWidth: '100vw',
      maxHeight: '100vh',
      panelClass: 'fullscreen-dialog-panel',
      disableClose: true,
      data: {
        patientId: patientId,
      },
    });
    // this.dialog.afterAllClosed.subscribe((resposne) => {});

    const closeDialog = dialogRef.afterClosed().subscribe(
      (isSaved: any) => {
        if (isSaved) {
          // this.reloadClaimTable.emit();
        }
      },
      (err) => {
        const data: HttpErrorResponse = err;
        console.warn(data?.error?.error?.message);
      }
    );
  }

  uploadEOB(cliamData: any, isSaveEnable: boolean) {
    const dialogRef = this.dialog.open(UploadEOBComponent, {
      width: '100vw',
      height: '100vh',
      maxWidth: '100vw',
      maxHeight: '100vh',
      panelClass: 'fullscreen-dialog-panel',
      disableClose: true,
      data: {
        cliamData: cliamData,
        isSaveEnable: isSaveEnable,
      },
    });
    // this.dialog.afterAllClosed.subscribe((resposne) => {

    // });

    const closeDialog = dialogRef.afterClosed().subscribe(
      (isSaved: any) => {
        if (isSaved) {
          this.getTableData();
        }
      },
      (err) => {
        const data: HttpErrorResponse = err;
        console.warn(data?.error?.error?.message);
      }
    );
  }

   // Function to get the first and last dates of the current month
   getFirstAndLastDatesOfCurrentMonth(): { firstDate: Date; lastDate: Date } {
    const today = new Date();
    const firstDate = new Date(today.getFullYear(), today.getMonth(), 1);
    const lastDate = new Date(today.getFullYear(), today.getMonth() + 1, 0);
    return { firstDate, lastDate };
  }

  onSelectionChartNoChange(event: MatSelectChange): void {
    this.selectedPatientChatNoList = event.value;
    if (this.selectedPatientChatNoList.includes('selectAll')) {
      this.chartNoSelectAllOption = true;
      this.selectedPatientChatNoList = this.selectPatientList.map((data) => data.patientId);
      this.invoiceSearchForm.get('drpPatient').patchValue(this.selectedPatientChatNoList);
    } else if (this.selectedPatientChatNoList.includes('DeselectAll')) {
      this.selectedPatientChatNoList = [];
      this.chartNoSelectAllOption = false;
      this.invoiceSearchForm.get('drpPatient').patchValue([]);
    } else {
      this.chartNoSelectAllOption = this.selectedPatientChatNoList.length === this.selectPatientList.length;
    }
  }
  onSelectionInvoiceChange(event: MatSelectChange): void {
    this.selectedInvoiceIdList = event.value;
    if (this.selectedInvoiceIdList.includes('selectAll')) {
      this.invoiceIdSelectAllOption = true;
      this.selectedInvoiceIdList = this.selectInvoiceList.map((data) => data.claimId);
      this.invoiceSearchForm.get('drpInvoice').patchValue(this.selectedInvoiceIdList);
    } else if (this.selectedInvoiceIdList.includes('DeselectAll')) {
      this.selectedInvoiceIdList = [];
      this.invoiceIdSelectAllOption = false;
      this.invoiceSearchForm.get('drpInvoice').patchValue([]);
    } else {
      this.invoiceIdSelectAllOption = this.selectedInvoiceIdList.length === this.selectInvoiceList.length;
    }
  }

  onSelectionStatusIdChange(event: MatSelectChange): void {
    this.selectedStatusIdList = event.value;
    if (this.selectedStatusIdList.includes('selectAll')) {
      this.statusIdSelectAllOption = true;
      this.selectedStatusIdList = this.selectStatusList.map((data) => data.statusId);
      this.invoiceSearchForm.get('drpStatus').patchValue(this.selectedStatusIdList);
    } else if (this.selectedStatusIdList.includes('DeselectAll')) {
      this.selectedStatusIdList = [];
      this.statusIdSelectAllOption = false;
      this.invoiceSearchForm.get('drpStatus').patchValue([]);
    } else {
      this.statusIdSelectAllOption = this.selectedStatusIdList.length === this.selectStatusList.length;
    }
  }
}
