import { RestService } from '@abp/ng.core';
import type { PagedAndSortedResultRequestDto, PagedResultDto } from '@abp/ng.core';
import { Injectable } from '@angular/core';
import type { CreateUpdateModifierDTO, ModifierDTO } from '../dto/billing/models';

@Injectable({
  providedIn: 'root',
})
export class ModifierService {
  apiName = 'DropdownManagement';

  create = (input: CreateUpdateModifierDTO) =>
    this.restService.request<any, ModifierDTO>({
      method: 'POST',
      url: '/api/DropdownApp/modifier',
      body: input,
    },
    { apiName: this.apiName });

  delete = (id: string) =>
    this.restService.request<any, void>({
      method: 'DELETE',
      url: `/api/DropdownApp/modifier/${id}`,
    },
    { apiName: this.apiName });

  get = (id: string) =>
    this.restService.request<any, ModifierDTO>({
      method: 'GET',
      url: `/api/DropdownApp/modifier/${id}`,
    },
    { apiName: this.apiName });

  getList = (input: PagedAndSortedResultRequestDto) =>
    this.restService.request<any, PagedResultDto<ModifierDTO>>({
      method: 'GET',
      url: '/api/DropdownApp/modifier',
      params: { sorting: input.sorting, skipCount: input.skipCount, maxResultCount: input.maxResultCount },
    },
    { apiName: this.apiName });

    searchModifierBySModifierNameAndSdtCreationDateAndDtStartDateAndDtEndDate = (sModifierName: string, sdtCreationDate: string, dtStartDate: string, dtEndDate: string) =>
    this.restService.request<any, PagedResultDto<ModifierDTO>>({
      method: 'POST',
      url: '/api/DropdownApp/modifier/search-modifier',
      params: { sModifierName, sdtCreationDate, dtStartDate, dtEndDate },
    },
    { apiName: this.apiName });

  update = (id: string, input: CreateUpdateModifierDTO) =>
    this.restService.request<any, ModifierDTO>({
      method: 'PUT',
      url: `/api/DropdownApp/modifier/${id}`,
      body: input,
    },
    { apiName: this.apiName });

  constructor(private restService: RestService) {}
}
