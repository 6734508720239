import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { defaultGuid } from 'projects/shared/src/app/enums/allenums.enum';
// import { defaultGuid } from 'projects/admin/src/app/document-perviewer/document-perviewer.component';

@Component({
  selector: 'app-patient-notes-modal',
  templateUrl: './patient-notes-modal.component.html',
  styleUrls: ['./patient-notes-modal.component.scss']
})
export class PatientNotesModalComponent implements OnInit {
  patientId: string = "";
  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: { patientId: string } = { patientId: defaultGuid },
  ) { }

  ngOnInit(): void {
    this.patientId = this.data?.patientId ?? defaultGuid;
  }

}
