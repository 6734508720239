import { RestService } from '@abp/ng.core';
import type { PagedAndSortedResultRequestDto, PagedResultDto } from '@abp/ng.core';
import { Injectable } from '@angular/core';
import { AdjustmentReasonDTO, CreateUpdateInventoryAdjustmentDTO, InventoryAdjustmentDTO } from './dto';

@Injectable({
  providedIn: 'root',
})
export class InventoryAdjustmentService {
  apiName = 'ItemManagement';

  create = (input: CreateUpdateInventoryAdjustmentDTO) =>
    this.restService.request<any, InventoryAdjustmentDTO>({
      method: 'POST',
      url: '/api/ItemApp/inventory-adjustment',
      body: input,
    },
    { apiName: this.apiName });

  delete = (id: string) =>
    this.restService.request<any, void>({
      method: 'DELETE',
      url: `/api/ItemApp/inventory-adjustment/${id}`,
    },
    { apiName: this.apiName });

  get = (id: string) =>
    this.restService.request<any, InventoryAdjustmentDTO>({
      method: 'GET',
      url: `/api/ItemApp/inventory-adjustment/${id}`,
    },
    { apiName: this.apiName });

  getAllAdjustmentReasonList = () =>
    this.restService.request<any, AdjustmentReasonDTO[]>({
      method: 'GET',
      url: '/api/ItemApp/inventory-adjustment/adjustment-reason-list',
    },
    { apiName: this.apiName });

  getAllInventoryAdjustmentList = () =>
    this.restService.request<any, InventoryAdjustmentDTO[]>({
      method: 'GET',
      url: '/api/ItemApp/inventory-adjustment/inventory-adjustment-list',
    },
    { apiName: this.apiName });

  getList = (input: PagedAndSortedResultRequestDto) =>
    this.restService.request<any, PagedResultDto<InventoryAdjustmentDTO>>({
      method: 'GET',
      url: '/api/ItemApp/inventory-adjustment',
      params: { skipCount: input.skipCount, maxResultCount: input.maxResultCount, sorting: input.sorting },
    },
    { apiName: this.apiName });

    getInventoryAdjustmentItemDetailsByGInventory = (gInventory: string[]) =>
    this.restService.request<any, CreateUpdateInventoryAdjustmentDTO>({
      method: 'GET',
      url: '/api/ItemApp/inventory-adjustment/get-inventory-adjustment-item-details',
      params: { gInventory },
    },
    { apiName: this.apiName });

  update = (id: string, input: CreateUpdateInventoryAdjustmentDTO) =>
    this.restService.request<any, InventoryAdjustmentDTO>({
      method: 'PUT',
      url: `/api/ItemApp/inventory-adjustment/${id}`,
      body: input,
    },
    { apiName: this.apiName });

  constructor(private restService: RestService) {}
}
