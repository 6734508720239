<!-- Title -->
<div class="row align-items-center">

  <div class="col-md-10">
    <!-- <h2 *ngIf="data.addnotes == true" class="customThemeClass">
      {{ notesModalTitle }} - {{ defaultFaxIdV1 ? defaultFaxIdV1 : 'N/A' }}
    </h2> -->
    <h2 class="customThemeClass">
      {{ data.headingTxt }} - <span> <a [class.disabled]="isEmpty(data?.chartId) || isEmpty(data?.patientId)" [routerLink]="'/patientCreateEditTab/' + data?.patientId"target="_blank"class="preview-link">{{ data?.chartId || "-" }}</a></span>
    </h2>

  </div>
  <div class="col-md-2" style="text-align: right">
    <mat-icon mat-dialog-close>close</mat-icon>
  </div>
</div>

<mat-dialog-content>
  <div class="row">
    <div class="col-lg-12">
      <div class="card">
        <div class="card-body pb-0 mb-1 mt-1">
          <!-- Notes Form -->
          <form [formGroup]="notesForm">
            <div class="modal-body">
              <div class="row">
                <div class="col-lg-12">
                  <div class="row">

                    <mat-form-field class="col-4" [hidden]="data.isNextCallDateshow !== true">
                      <mat-label>Next Call Date <span class="asterisk">*</span></mat-label>
                      <input
                        type="text"
                        matInput
                        autocomplete="new-txtNextCalldate"
                        maxlength="10"
                        [matDatepicker]="datepicker"
                        placeholder="MM/DD/YYYY"
                        [min]="minDate"
                        formControlName="txtNextCalldate"
                        (keydown)="addSlashToDate($event)"
                      />
                      <!-- [readonly]="!patientLedgerForm.get('txtNextCalldate').value" -->
                      <mat-datepicker-toggle
                        matSuffix
                        [for]="datepicker"
                      ></mat-datepicker-toggle>
                      <!-- [disabled]="!patientLedgerForm.get('txtNextCalldate').value" -->
                      <mat-datepicker #datepicker></mat-datepicker>
                      <mat-error
                        *ngIf="
                          notesForm.get('txtNextCalldate').touched &&
                          notesForm.get('txtNextCalldate').errors
                        "
                      >
                        Enter Future valid date!
                      </mat-error>
                      <!-- <mat-error
                        *ngIf="
                          notesForm.get('txtNextCalldate').touched &&
                          notesForm
                            .get('txtNextCalldate')
                            .hasError('minDateError')
                        "
                      >
                        Next Call Date cannot be before today!
                      </mat-error>
                      <mat-error
                        *ngIf="
                          notesForm.get('txtNextCalldate').touched &&
                          notesForm.get('txtNextCalldate').hasError('invalidYear')
                        "
                      >
                        Enter Valid date MM/DD/YYYY ..!
                      </mat-error> -->
                    </mat-form-field>

                    <!--Notes Type-->
                      <mat-form-field class="col-5">
                        <mat-select formControlName="drpNotetype" #singleSelect >
                          <mat-option>
                            <ngx-mat-select-search [formControl]="NoteTypeControl" placeholderLabel="Search"
                              noEntriesFoundLabel="No Matches found"
                              formControlName="txtNoteType"></ngx-mat-select-search>
                          </mat-option>
                          <mat-option *ngFor="let note of drpNotetype" [value]="note.id+ '/' +note.noteType">
                            {{ note.noteType }}
                          </mat-option>
                        </mat-select>

                        <mat-label>
                          <div class="select-placeholder-container" *ngIf="isNoteLoading">
                            <span>Loading...</span>
                            <mat-spinner class="spinner" diameter="20"></mat-spinner>
                          </div>
                          <span *ngIf="!isNoteLoading">Note Type <span class="asterisk">*</span></span>
                        </mat-label>

                        <mat-error class="spanAlignment" *ngIf="
                    notesForm?.get('drpNotetype')?.touched &&
                    notesForm?.get('drpNotetype')?.errors?.required
                  ">
                          Note Type is a required field!
                        </mat-error>
                      </mat-form-field>

                      <mat-checkbox  class="col-2 ml-3 mt-3" formControlName="chkIsCallNote">is Call
                        Note<span class="asterisk">*</span>
                      </mat-checkbox>
                  </div>

                  <div class="row">
                    <mat-form-field class="col-12">
                      <mat-label>Description<span class="asterisk">*</span></mat-label>
                      <textarea matInput rows="6" autocomplete="off" maxlength="5000" formControlName="txtDescription"  [(ngModel)]="notesText" (input)="updateCharacterCount(5000)"
                        type="text" ></textarea>
                        <div class="char-count">
                          {{ notesText?.length }} / 5000
                        </div>
                      <mat-error class="spanAlignment" *ngIf="notesForm?.get('txtDescription')?.touched">
                        <ng-container *ngIf="notesForm?.get('txtDescription')?.hasError('required')">
                          Description is a required field!
                        </ng-container>
                        <ng-container *ngIf="notesForm?.get('txtDescription')?.hasError('whitespace')">
                          Description cannot be just whitespace!
                        </ng-container>
                      </mat-error>
                    </mat-form-field>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions>
  <div class="col-12 text-center mt-1">
    <button  mat-button class="buttonColor mb-2 mr-2"
      [disabled]="notesForm?.invalid ||  notesForm.value.chkIsCallNote!==true || isShowSpinner" (click)="SaveActiveNote()">
      Save
      <mat-icon *ngIf="isShowSpinner">
        <mat-spinner class="spinner-border spinner-border-sm" diameter="20"></mat-spinner>
      </mat-icon>
    </button>
    <button mat-button class="resetclr mb-2 mr-2" (click)="resetNotes()">Reset</button>
  </div>
</mat-dialog-actions>
