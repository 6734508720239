import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { defaultGuid } from 'projects/shared/src/app/enums/allenums.enum';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-new-setup-doc-tiff-viewer',
  templateUrl: './new-setup-doc-tiff-viewer.component.html',
  styleUrls: ['./new-setup-doc-tiff-viewer.component.scss']
})
export class NewSetupDocTiffViewerComponent implements OnInit {

  @Input() TiffData : any[];
  existingDocumentImageList  : any[];
  subscription$: Subscription[] = [];
  isLoading: boolean;
  loadInboundDocumentAPICall$: Subscription;
  @ViewChild('canvas', { static: true }) canvasRef!: ElementRef;
  ctx!: CanvasRenderingContext2D;
  pageNumber: number = 1;
  totalPages: number = 0;
  // readonly DEFAULT_ZOOM_LEVEL = 1;
  // readonly MAX_ZOOM_LEVEL = 2.5;
  // readonly MIN_ZOOM_LEVEL = 0.5;
  readonly DEFAULT_ZOOM_LEVEL = 1.3;
  readonly MAX_ZOOM_LEVEL = this.DEFAULT_ZOOM_LEVEL+4;
  readonly MIN_ZOOM_LEVEL = 0.5;
  zoomLevel: number = this.DEFAULT_ZOOM_LEVEL; // Default Size
  selectedExistingDocument: any = null;
  loadingMessage: string = 'Loading ...';

  constructor() { }

  ngOnInit(): void {

    this.existingDocumentImageList=this.TiffData;
  }

  isEmpty(value: any): boolean {
    return (
      value === null ||
      value === undefined ||
      value === '' ||
      value === defaultGuid
    );
  }

  loadExistingImage(docDetails: any) {
    this.selectedExistingDocument = docDetails;
    this.loadTiffImage(docDetails.file);
    this.pageNumber = docDetails.pageNo;
  }

  // Function to zoom in
  zoomIn() {
    if (this.zoomLevel < this.MAX_ZOOM_LEVEL) {
      this.zoomLevel += 0.1;
      this.drawZoomedImage(); // Redraw image with new zoom level
    }
  }

  // Function to zoom out
  zoomOut() {
    if (this.zoomLevel > this.MIN_ZOOM_LEVEL) {
      this.zoomLevel -= 0.1;
      this.drawZoomedImage(); // Redraw image with new zoom level
    }
  }

  previousImage() {
    if (
      this.selectedExistingDocument &&
      this.selectedExistingDocument.pageNo > 1
    ) {
      let previousImageNo = this.selectedExistingDocument.pageNo - 2;
      this.loadExistingImage(this.existingDocumentImageList?.[previousImageNo]);
    }
  }

  nextImage() {
    if (
      this.selectedExistingDocument &&
      this.selectedExistingDocument.pageNo <
        this.existingDocumentImageList.length
    ) {
      let nextImageNo = this.selectedExistingDocument.pageNo;
      this.loadExistingImage(this.existingDocumentImageList?.[nextImageNo]);
    }
  }

  firstImage() {
    this.loadExistingImage(this.existingDocumentImageList?.[0]);
  }

  lastImage() {
    const pageIndex = this.totalPages - 1;
    this.loadExistingImage(this.existingDocumentImageList?.[pageIndex]);
  }

  // Function to navigate to a specific page
  goToPage() {
    if(this.pageNumber <= 0){
      this.pageNumber = 1;
      this.firstImage();
    }
    else if (this.pageNumber >= 1 && this.pageNumber <= this.totalPages) {
      const pageIndex = this.pageNumber - 1;
      this.loadExistingImage(this.existingDocumentImageList[pageIndex]);
    }else{
      this.pageNumber = this.totalPages;
      this.lastImage();
    }
  }

  // Function to draw the image on the canvas with the current zoom level
  drawZoomedImage() {
    const docDetails = this.selectedExistingDocument;
    const tiffBase64 = docDetails.file;
    const canvas = this.canvasRef.nativeElement;
    this.ctx = canvas.getContext('2d');
    const img = new Image();
    img.onload = () => {
      // Calculate the position to draw the image so that it remains centered
      const imageWidth = img.width * this.zoomLevel;
      const imageHeight = img.height * this.zoomLevel;
      // Clear canvas before drawing
      this.ctx.clearRect(0, 0, canvas.width, canvas.height);

      // Adjust canvas size based on zoom level
      canvas.width = imageWidth;
      canvas.height = imageHeight;
      // Draw image with adjusted dimensions and centered position

      this.ctx.drawImage(img, 0, 0, imageWidth, imageHeight);
    };
    img.src = 'data:image/tiff;base64,' + tiffBase64;
  }

  loadTiffImage(tiffBase64: string) {
    const canvas = this.canvasRef.nativeElement;
    this.ctx = canvas.getContext('2d');
    const img = new Image();
    img.onload = () => {
      // canvas.width = img.width;
      // canvas.height = img.height;
      // Calculate the position to draw the image so that it remains centered
      const imageWidth = img.width * this.DEFAULT_ZOOM_LEVEL;
      const imageHeight = img.height * this.DEFAULT_ZOOM_LEVEL;
      // Clear canvas before drawing
      this.ctx.clearRect(0, 0, canvas.width, canvas.height);

      // Adjust canvas size based on zoom level
      canvas.width = imageWidth;
      canvas.height = imageHeight;
      this.ctx.drawImage(img, 0, 0,canvas.width, canvas.height);
      // Scroll to the selected image
      const selectedImageElement = document.querySelector(
        '.custom-thumbnail-view-selected'
      );
      if (selectedImageElement) {
        selectedImageElement.scrollIntoView({
          behavior: 'auto',
          block: 'center',
        });
      }
    };
    img.src = 'data:image/tiff;base64,' + tiffBase64;
    this.zoomLevel = this.DEFAULT_ZOOM_LEVEL;

  }

}
