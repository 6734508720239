
<div class="container-fluid">
  <div class="row">
    <div class="col-6">
      <h4 class="customThemeClass pt-3"><b> Posting History</b></h4>
    </div>
    <div class="col-6">
      <div class="d-flex align-items-center justify-content-end m-0">
        <div class="page-title-box page-title-right pb-0">
          <ol class="breadcrumbs m-0">
            <li class="mb-1">
              <button matTooltip="Click To Go back to Posting List" [routerLink]="'/billing/claimStatusList'"
                [queryParams]="{ id: strClaimStatus }" mat-button class="buttonColor font-size-10 mr-2">
                <i class="fa fa-backward mr-1"></i> Back To Claim List
              </button>
              <button matTooltip="Click To Go back to Posting List" [routerLink]="'/AddNewPosting/' + claimId"
                [queryParams]="{ id: strClaimStatus }" mat-button class="buttonColor font-size-10 mr-2">
                <i class="fa fa-backward mr-1"></i> Back To Posting List
              </button>
            </li>
            <li class="ml-2 mt-2 breadcrumb-item">History</li>
            <li class="mt-2 breadcrumb-item muted">Posting History</li>
          </ol>
        </div>
      </div>
    </div>
  </div>
  <div class="table table-responsive">
    <app-table [strPageType]='strPageType' [isLoading]="isLoading"></app-table>
  </div>
</div>
