<div class="container-fluid">
  <div class="row bg">
    <div class="col-12">
      <div class="d-flex align-items-center justify-content-between mb-3">
        <h3 class="font-weight-bolder mb-0">Shipping Return Approved List</h3>
        <div class=" page-title-box page-title-right">
          <ol class="breadcrumbs m-0 align-items-center">
            <button mat-button class="mr-2 buttonColor"    [disabled]="(dataSource && dataSource?.data && dataSource?.data?.length === 0)"
             (click)="exportTableData()">
              Export
            </button>
            <li class="breadcrumb-item">Orders</li>
            <li class="breadcrumb-item ">Shipping Return Approved List</li>
          </ol>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="col-12">
  <div class="card">
    <div class="card-body">
      <div class="customThemeClass">
        <ng-container>
          <b>Shipping Ticket List Search</b>
        </ng-container>
      </div>
      <form [formGroup]="shippingTicketListForm">
        <div class="row">
          <mat-form-field class="col-3">
            <mat-label>SaleOrder Id</mat-label>
            <mat-select #saleOrderSelect multiple formControlName="txtSaleorderId"
              (selectionChange)="onFilterSaleorderIdChange()" [(value)]="saleOrderID">
              <mat-option>
                <ngx-mat-select-search placeholderLabel="Search SaleOrder Id" noEntriesFoundLabel="No Records found"
                  formControlName="txtSaleOrderSearch">
                </ngx-mat-select-search>
              </mat-option>
              <mat-option #allSaleOrderSelected [value]="0" (click)="toggleAllSaleorderIdSelection()">Select All
                SaleOrder</mat-option>

              <mat-option *ngFor="let saleOrderId of filterSaleorder | async" [value]="saleOrderId">
                {{ saleOrderId }}
              </mat-option>

            </mat-select>
            <button mat-button (click)="saleOrderID='';$event.stopPropagation()" *ngIf="saleOrderID" matSuffix
              (click)="clearSaleOrderId()" mat-icon-button >
              <mat-icon>close</mat-icon>
            </button>
          </mat-form-field>

          <mat-form-field class="col-3">
            <mat-label>Patient Id</mat-label>
            <mat-select #PatientIdSelect multiple formControlName="txtPatientId"
              (selectionChange)="onFilterPatientIdChange()" [(value)]="patientID">
              <mat-option>
                <ngx-mat-select-search placeholderLabel="Search Patient Id" noEntriesFoundLabel="No Records found"
                  formControlName="txtPatientIdSearch">
                </ngx-mat-select-search>
              </mat-option>

              <mat-option #allPatientIdSelected [value]="0" (click)="toggleAllPatientIdSelection()">Select All
                Patient Id</mat-option>
              <mat-option *ngFor="let patientIds of filterPatientId  | async" [value]="patientIds">
                {{ patientIds }}
              </mat-option>
            </mat-select>
            <button mat-button (click)="patientID='';$event.stopPropagation()" *ngIf="patientID" matSuffix
              (click)="clearPatientId()" mat-icon-button >
              <mat-icon>close</mat-icon>
            </button>
          </mat-form-field>

          <mat-form-field class="col-3">
            <mat-label>Patient Name</mat-label>
            <mat-select #PatientNameSelect multiple formControlName="txtPatientName"
              (selectionChange)="onFilterPatientNameChange()" [(value)]="patientName">
              <mat-option>
                <ngx-mat-select-search placeholderLabel="Search Patient Name" noEntriesFoundLabel="No Records found"
                  formControlName="txtPatientNameSearch">
                </ngx-mat-select-search>
              </mat-option>
              <mat-option #allPatientNameSelected [value]="0" (click)="toggleAllPatientNameSelection()">Select All
                Patient Name</mat-option>

              <mat-option *ngFor="let patientNames of filterPatientName | async" [value]="patientNames">
                {{ patientNames }}
              </mat-option>
            </mat-select>
            <button mat-button (click)="patientName='';$event.stopPropagation()" *ngIf="patientName" matSuffix
              (click)="clearPatientName()" mat-icon-button >
              <mat-icon>close</mat-icon>
            </button>
          </mat-form-field>

          <mat-form-field class="col-3">
            <mat-label>CSR Name</mat-label>
            <mat-select #CsrNameSelect multiple formControlName="txtCsrName" (selectionChange)="onFilterCSRNameChange()"
              [(value)]="CsrName">
              <mat-option>
                <ngx-mat-select-search placeholderLabel="Search CSR Name" noEntriesFoundLabel="No Records found"
                  formControlName="txtCsrNameSearch">
                </ngx-mat-select-search>
              </mat-option>
              <mat-option #allCsrNameSelected [value]="0" (click)="toggleAllCsrNamesSelection()">Select All
                CSR Names</mat-option>

              <mat-option *ngFor="let csrNames of filterCsrName | async" [value]="csrNames.userName">
                {{ csrNames.userName }}
              </mat-option>
            </mat-select>
            <button mat-button (click)="CsrName='';$event.stopPropagation()" *ngIf="CsrName" matSuffix
              (click)="clearCsrName()" mat-icon-button >
              <mat-icon>close</mat-icon>
            </button>
          </mat-form-field>

          <mat-form-field class="col-3">
            <mat-label>Ticket Id</mat-label>
            <mat-select #TicketIdSelect multiple formControlName="txtTicketId"
              (selectionChange)="onFilterTicketIdChange()" [(value)]="ticketId">
              <mat-option>
                <ngx-mat-select-search placeholderLabel="Search Ticket Id" noEntriesFoundLabel="No Records found"
                  formControlName="txtTicketIdSearch">
                </ngx-mat-select-search>
              </mat-option>
              <mat-option #allTicketIdSelected [value]="0" (click)="toggleAllTicketIdSelection()">Select All
                Ticket Id</mat-option>
              <mat-option *ngFor="let ticketId of filterTicketId | async  " [value]="ticketId">
                {{ ticketId }}
              </mat-option>
            </mat-select>
            <button mat-button (click)="ticketId='';$event.stopPropagation()" *ngIf="ticketId" matSuffix
              (click)="clearTicketId()" mat-icon-button >
              <mat-icon>close</mat-icon>
            </button>
          </mat-form-field>

          <mat-form-field class="col-6">
            <mat-label>Select Tikcet Date Range</mat-label>
            <input autocomplete="new-txtFromDate" matInput type="text" name="daterange" ngxDaterangepickerMd [(ngModel)]="selectedRange"
              (click)="change()" [showCustomRangeLabel]="true" [alwaysShowCalendars]="true" [linkedCalendars]="false"
              [ranges]="ranges" [showClearButton]="true" [locale]="calendarLocale" [placeholder]="calendarPlaceholder"
              [keepCalendarOpeningWithRange]="true" [minDate]="minDate" [maxDate]="maxDate"
              formControlName="txtFromDate" />
            <mat-icon matSuffix>date_range</mat-icon>
          </mat-form-field>

          <div class="col-3" style='top:3px'>
            <button mat-button class="buttonColor mb-2 mr-2" (click)="search()">Search
            </button>
            <button mat-button class="resetclr" (click)="reset()">Reset
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>

<div class="col-12 table-responsive" style="margin-top: -7px;">
  <!-- <div [hidden]="!isLoading"
    style="display: flex; justify-content: center; align-items: center; background: transparent; color: lightseagreen;">
    <mat-progress-bar mode="indeterminate" value='66' diameter="45" class="lightseagreen">
    </mat-progress-bar>
  </div> -->
  <!-- <app-table [hidden]="isLoading" [strPageType]="strPageType" (viewButton)="existingOrders($event)"></app-table> -->
  <table mat-table class="table" [dataSource]="dataSource" matSort>
    <form style="display: flex" [formGroup]="orderForm">

      <ng-container matColumnDef="saleOrderId">
        <th class="header" mat-header-cell *matHeaderCellDef>
          SaleOrder Id
        </th>
        <td mat-cell *matCellDef="let element"> {{element.saleOrderId}} </td>
      </ng-container>
      <ng-container matColumnDef="patientId">
        <th class="header" mat-header-cell *matHeaderCellDef>
          Patient Id
        </th>
        <td mat-cell *matCellDef="let element"> {{element.patientId}} </td>
      </ng-container>
      <ng-container matColumnDef="PatientName">
        <th class="header" mat-header-cell *matHeaderCellDef>
          Patient Name
        </th>
        <td mat-cell *matCellDef="let element"> {{element.PatientName}} </td>
      </ng-container>
      <ng-container matColumnDef="csrName">
        <th class="header" mat-header-cell *matHeaderCellDef>
          CSR Name
        </th>
        <td mat-cell *matCellDef="let element"> {{element.userName}} </td>
      </ng-container>

      <ng-container matColumnDef="payment">
        <th class="header" mat-header-cell *matHeaderCellDef mat-sort-header>
          Cash Payment
        </th>
        <td mat-cell *matCellDef="let element">
          <input autocomplete="new-cashPayment" disabled style="width: 60px;height: 20px;" type="checkbox" [checked]="element?.cashPayment" />
        </td>
      </ng-container>

      <ng-container matColumnDef="status">
        <th class="header" mat-header-cell *matHeaderCellDef>
          Status
        </th>
        <td mat-cell *matCellDef="let element"> {{element.Status}} </td>
      </ng-container>

      <ng-container matColumnDef="Quantity">
        <th class="header" mat-header-cell *matHeaderCellDef>
          Units
        </th>
        <td mat-cell *matCellDef="let element"> {{element?.totalItemQty}} </td>
      </ng-container>



      <ng-container matColumnDef="TicketId">
        <th class="header" mat-header-cell *matHeaderCellDef>
          Ticket Id
        </th>
        <td mat-cell *matCellDef="let element"> {{element?.ticketId}} </td>
      </ng-container>


      <ng-container matColumnDef="Date">
        <th class="header" mat-header-cell *matHeaderCellDef>
          Ticket Date
        </th>
        <td mat-cell *matCellDef="let element"> {{element.ticketCreatedDate}} </td>
      </ng-container>

      <ng-container matColumnDef="options">
        <th class="header" mat-header-cell *matHeaderCellDef>
          Options
        </th>
        <td class="align" mat-cell *matCellDef="let element">
          <a class="editBtnColor">
            <i class="fa fa-eye" (click)="viewTicket(element?.data)" ></i>
          </a>
        </td>
      </ng-container>

      <ng-container matColumnDef="noRecords">
        <td class="text-align-center" mat-footer-cell *matFooterCellDef colspan="9">
          <div *ngIf="!isLoading">
            {{"No Records Found"}}
          </div>
          <div [hidden]="!isLoading">
            <mat-spinner [diameter]="220"></mat-spinner>
          </div>
        </td>
      </ng-container>
      <ng-container matColumnDef="paginator">
        <td mat-footer-cell *matFooterCellDef colspan="10">
          <mat-paginator class="table table-responsive mat-table-class" #MatPaginator
            [length]="dataSource?.data?.length" [pageSize]="10" [pageIndex]="0" showFirstLastButtons
            [pageSizeOptions]="[5, 10, 25, 100]">
          </mat-paginator>
        </td>
      </ng-container>
    </form>
    <tr mat-footer-row *matFooterRowDef="['noRecords']"
      [ngClass]="{ active: dataSource && dataSource.data && dataSource?.data?.length !== 0 }"></tr>
    <tr mat-header-row *matHeaderRowDef="arrDisplayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: arrDisplayedColumns"></tr>
    <tr mat-footer-row *matFooterRowDef="['paginator']"></tr>
  </table>
</div>
