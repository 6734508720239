<div class="card">
  <div class="row">
    <div class="col-12">
      <div class="card-body pb-0">
        <div class="row">
          <div class="col-12">
            <div class="table table-responsive">
              <table id="tblClaimBatchList" datatable [dtOptions]="dtClaimBatchOptions"
                class="tbl hover row-border hover w-100 display">
                <thead>
                  <tr>
                    <th [matTooltip]="'Batch No'">Batch No</th>
                    <th class="text-right" [matTooltip]="'File Count'">File Count</th>
                    <th [matTooltip]="'Provider'">Provider</th>
                    <th [matTooltip]="'Insurance Rank'">Insurance Rank</th>
                    <th class="text-right" [matTooltip]="'Claim Sent Date'">Claim Sent Date</th>
                    <th [matTooltip]="'Claim Sent By'">Claim Sent By</th>
                    <th [matTooltip]="'Status'">Status</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let data of DataList" (click)="tblItemSelectedRow(data)"
                    [class.selected-row]="data.claimBatchId === selectedRow">
                    <td (dblclick)="onRowDoubleClick(data?.batchNo || '-')" [matTooltip]="data?.batchNo || '-'" >
                      {{ data?.batchNo || '-'}}
                    </td>
                    <td (dblclick)="onRowDoubleClick(data?.fileCount || '-')" [matTooltip]="data?.fileCount || '-'"
                      class="text-right">{{
                      data?.fileCount || '-'}}</td>
                    <td (dblclick)="onRowDoubleClick(data?.providerName || '-')" [matTooltip]="data?.providerName || '-'">

                      {{ data?.providerName || '-' }}
                    </td>
                    <td (dblclick)="onRowDoubleClick(data?.insuranceRank || '-')"
                      [matTooltip]="data?.insuranceRank || '-'">
                      {{ data?.insuranceRank || '-'}}
                    </td>

                    <td class="text-right"(dblclick)="onRowDoubleClick(data?.claimSentDate || '-')"
                      [matTooltip]="data?.claimSentDate || '-'">
                      {{ data?.claimSentDate || '-' }}
                    </td>
                    <td (dblclick)="onRowDoubleClick(data?.claimSentBy || '-')" [matTooltip]="data?.claimSentBy || '-'">
                      {{ data?.claimSentBy || '-' }}
                    </td>
                    <!-- <td (dblclick)="onRowDoubleClick(data?.claimBatchStatusName || '-')" [matTooltip]="data?.claimBatchStatusName || '-'">
                      {{ data?.claimBatchStatusName || '-' }}
                    </td> -->
                    <td [matTooltip]="data?.claimBatchStatusName || '-'">
                    <mat-label class="cus-mat-label-status p-1 border rounded" [style.backgroundColor]="data?.claimBatchColorCode"
                    [style.color]="(data?.claimBatchColorCode!==''&&data?.claimBatchColorCode!==null)?'white':'black'" >
                      {{ data?.claimBatchStatusName  || '-'  }}</mat-label>
                        </td>
                  </tr>
                </tbody>
                <tfoot>
                  <tr>
                    <th>
                      <input matInput class="textbox-border-class" type="text" placeholder="Batch No"
                        name="search-BatchNo" />
                    </th>
                    <th class="text-right">
                      <input matInput class="textbox-border-class" type="text" placeholder="File Count"
                        name="search-FileCount" />
                    </th>
                    <th>
                      <input matInput type="text" class="textbox-border-class" placeholder="Provider"
                        name="search-Provider" />
                    </th>
                    <th>
                      <input matInput type="text" class="textbox-border-class" placeholder="Insurance Rank"
                        name="search-InsuranceRank" />
                    </th>
                    <th class="text-right">
                      <input matInput type="text" class="textbox-border-class" placeholder="Claim Sent Date"
                        name="search-ClaimSentDate" />
                    </th>
                    <th>
                      <input matInput class="textbox-border-class" type="text" placeholder="Claim Sent By"
                        name="search-ClaimSentBy" />
                    </th>
                    <th>
                      <input matInput class="textbox-border-class" type="text" placeholder="Status"
                        name="search-Status" />
                    </th>
                  </tr>
                </tfoot>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="card mt-2 mb-4">
  <div class="row">
    <div class="col-12">
      <div class="card-body pb-0">
        <div class="row">
          <div class="col-12">
            <div class="table table-responsive">

              <div class="row" *ngIf="isLoading">
                <div class="col-12">
                  <div class="d-flex justify-content-center">
                    <img class="img-responsive pb-2 mr-1" src="assets/gifs/Infinity-loader.gif" />
                  </div>
                </div>
              </div>

              <div *ngIf="!isLoading">
                <table id="tblClaimBatchChildList" datatable [dtOptions]="dtClaimBatchChildOptions"
                  class="tbl hover row-border hover w-100 display">
                  <thead>
                    <tr>
                      <th><mat-icon>reorder</mat-icon></th>
                      <th [matTooltip]="'Claim ID'">Claim ID</th>
                      <th [matTooltip]="'Ticket ID'">Ticket ID</th>
                      <th [matTooltip]="'Chart No'">Chart No</th>
                      <th [matTooltip]="'Patient Name'">Patient Name</th>
                      <th class="text-right" [matTooltip]="'Order Date'">Order Date</th>
                      <th [matTooltip]="'CSR'">CSR</th>
                      <th [matTooltip]="'Insurance'">Insurance</th>
                      <th class="text-right" [matTooltip]="'Claim Process Date'">Claim Process Date</th>
                      <th class="text-right" [matTooltip]="'Billed Amount'">Billed Amount ($)</th>
                      <th [matTooltip]="'Status'">Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let data of DataListChild">
                      <td>
                        <!-- <mat-icon style="cursor: pointer !important;color:lightseagreen"
                        [matTooltip]="'View Manual Posting'"
                       (click)="openEOBModel()">remove_red_eye</mat-icon> -->

                       <a class="ml-2" [matTooltip]="'View Posting Details'"
                       (click)="uploadEOB(data,false)">
                    <img class="img-responsive pr-1 mb-1 cursor" src="assets/Claim Page Icons/Vector.png" alt=""
                        height="17" />
                      </a>
                      </td>
                      <td  class="preview-link" style="color:#007bff;cursor: pointer;" (dblclick)="onRowDoubleClick(data?.defaultClaimId || '-')" [matTooltip]="data?.defaultClaimId || '-'"
                      (click)="openClaimForm(data.orderId,data.patientId,data.mmRefOrderId, data.claimId)"
                        >
                        {{ data?.defaultClaimId || '-'}}
                      </td>
                      <td class="text-right" [matTooltip]="data?.mmRefOrderId || '-'" ><a class="preview-link" [class.disabled]="isEmpty(data.patientId) || isEmpty(data.mmRefOrderId)" [routerLink]="'/orders/saleOrder/' +data.patientId +'/' +data.orderId + '/'+'0'" target="_blank">
                        {{data?.mmRefOrderId || '-'}}
                            </a></td>
                      <td (dblclick)="onRowDoubleClick(data?.chartId || '-')" [matTooltip]="data?.chartId || '-'">
                        <a class="preview-link" [class.disabled]="isEmpty(data.patientId)" class="eyeCursorclass"
                        [routerLink]="'/patientCreateEditTab/' + data?.patientId" target="_blank">
                        {{ data.chartId }}
                        </a>
                      </td>
                      <td (dblclick)="onRowDoubleClick(data?.patientName || '-')"
                        [matTooltip]="data?.patientName || '-'">

                        {{ data?.patientName || '-' }}
                      </td>
                      <td class="text-right" (dblclick)="onRowDoubleClick(data?.orderDate)"
                      [matTooltip]="data?.orderDate">

                      {{ data?.orderDate }}
                    </td>
                    <td (dblclick)="onRowDoubleClick(data?.csrName || '-')" [matTooltip]="data?.csrName || '-'">
                      {{ data?.csrName || '-'}}
                    </td>
                      <td (dblclick)="onRowDoubleClick(data?.insuranceName || '-')" [matTooltip]="data?.insuranceName || '-'">
                        {{ data?.insuranceName || '-'}}
                      </td>
                      <td class="text-right" (dblclick)="onRowDoubleClick(data?.claimProcessDate)"
                      [matTooltip]="data?.claimProcessDate">

                      {{ data?.claimProcessDate }}
                    </td>
                      <td (dblclick)="onRowDoubleClick(data?.billedAmount || '-')"
                        [matTooltip]="data?.billedAmount || '-'" class="text-right">
                        {{ data?.billedAmount || '-' }}
                      </td>

                      <td [matTooltip]="data?.claimStatusName || '-'">
                        <mat-label class="cus-mat-label-status p-1 border rounded"
                        [style.backgroundColor]="data?.claimStatusColorCode"
                        [style.color]="(data?.claimStatusColorCode!==''&&data?.claimStatusColorCode!==null)?'white':'black'" >
                          {{ data?.claimStatusName  || '-'  }}</mat-label>
                            </td>

                    </tr>
                  </tbody>
                </table>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>



<br>
