import type { SaleorderDTO } from './dto/models';
import { PagedAndSortedResultRequestDto, RestService } from '@abp/ng.core';
import type { PagedResultDto } from '@abp/ng.core';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class SaleOrderSearchService {
  apiName = 'orderManagement';

  searchSaleOrderBySSaleOrderIdAndSItemIdAndSItemNameAndDtCreatedDateAndIQuantityAndSStatusAndSPatientIdAndSItemSize = (sSaleOrderId: string, sItemId: string, sItemName: string, dtCreatedDate: string, iQuantity: number, sStatus: string, sPatientId: string, sItemSize: string) =>
    this.restService.request<any, PagedResultDto<SaleorderDTO>>({
      method: 'POST',
      url: '/api/orderApp/sale-order-search/search-sale-order',
      params: { sSaleOrderId, sItemId, sItemName, dtCreatedDate, iQuantity, sStatus, sPatientId, sItemSize },
    },
      { apiName: this.apiName, skipHandleError: true });

  getList = (input: PagedAndSortedResultRequestDto) =>
    this.restService.request<any, PagedResultDto<SaleorderDTO>>({
      method: 'GET',
      url: '/api/orderApp/sale-order-search',
      params: { skipCount: input.skipCount, maxResultCount: input.maxResultCount, sorting: input.sorting },
    },
      { apiName: this.apiName, skipHandleError: true });

  updateOrderBillingStatus = (saleOrderId: string) =>
    this.restService.request<any, PagedResultDto<SaleorderDTO>>({
      method: 'PUT',
      url: `/api/orderApp/sale-order-search/update-order-billing-status/${saleOrderId}`,
    },
      { apiName: this.apiName, skipHandleError: true });



  constructor(private restService: RestService) { }
}
