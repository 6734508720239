<div class="row">
  <div class="col-lg-6">
    <h2>Add Product</h2>
  </div>

</div>

<mat-dialog-content class="mat-typography">
  <div *ngIf="radioButton" class="row">

    <mat-radio-group class="rg pt-3 ml-3" (change)="onChangeGetInsuranceVerify($event)">
      <mat-radio-button [checked]='true' value="MainProduct">Main Product</mat-radio-button>
      &nbsp;&nbsp;&nbsp;
      <mat-radio-button value="ResupplyProduct">Resupply Product</mat-radio-button>&nbsp;&nbsp;&nbsp;
    </mat-radio-group>
  </div>
  <div *ngIf="main">
    <form [formGroup]="productForm">
      <div class="row">
        <div class="col-lg-12">
          <mat-card-content>
            <div class="row">
              <div class="col-lg-12">
                <mat-form-field class="col-4">
                  <mat-label>Product Name <span class="asterisk">*</span></mat-label>
                  <input autocomplete="new-txtProductName" maxlength="100" formControlName="txtProductName" matInput type="text" />
                  <mat-error
                    *ngIf="productForm?.get('txtProductName')?.touched &&productForm?.get('txtProductName')?.errors?.required">
                    Product Name is a required field!
                  </mat-error>
                </mat-form-field>
                <mat-form-field class="col-4">
                  <mat-label>HCPC Code <span class="asterisk">*</span></mat-label>
                  <input autocomplete="new-txtHcpc" maxlength="40" formControlName="txtHcpc" matInput type="text" />
                  <mat-error *ngIf="productForm?.get('txtHcpc')?.touched &&productForm?.get('txtHcpc')?.errors?.required">
                    HCPC Code is a required field!
                  </mat-error>
                </mat-form-field>
                <mat-form-field [hidden]="true" class="col-4">
                  <mat-label>Product Code <span class="asterisk">*</span></mat-label>
                  <input autocomplete="new-txtProductCode" maxlength="40" formControlName="txtProductCode" matInput type="text" />
                  <mat-error
                    *ngIf="productForm?.get('txtProductCode')?.touched &&productForm?.get('txtProductCode')?.errors?.required">
                    Product Code is a required field!
                  </mat-error>
                  <mat-error *ngIf="
                  productForm?.get('txtProductCode')?.touched &&
                  productForm?.get('txtProductCode')?.errors?.number
          ">
                    Enter only Numbers!
                  </mat-error>
                </mat-form-field>


                <mat-form-field class="col-4">
                  <mat-label>Minimum Keeping Stock<span class="asterisk">*</span></mat-label>
                  <input autocomplete="new-txtMinQty" maxlength="40" min="1" formControlName="txtMinQty" matInput type="text" />
                  <mat-error *ngIf="
                  productForm?.get('txtMinQty')?.touched &&
                  productForm?.get('txtMinQty')?.errors?.required
                                ">
                    Unit is a required field!
                  </mat-error>
                  <mat-error *ngIf="
                  productForm?.get('txtMinQty')?.touched &&
                  productForm?.get('txtMinQty')?.errors?.number
                    ">
                    Enter only Numbers!
                  </mat-error>
                </mat-form-field>

                <mat-form-field class="col-6">
                  <mat-label>Description <span class="asterisk">*</span></mat-label>
                  <textarea formControlName="txtDescription" rows="3" matInput maxlength="400"></textarea>
                  <mat-error *ngIf="
                                productForm?.get('txtDescription')?.touched &&
                                productForm?.get('txtDescription')?.errors?.required
                              ">
                    Description is a required field!
                  </mat-error>
                </mat-form-field>
              </div>
            </div>
          </mat-card-content>
        </div>
      </div>
    </form>
  </div>



  <div *ngIf="reSupply">
    <form [formGroup]="productSupplyForm">
      <div class="row">
        <div class="col-lg-12">

          <mat-form-field class="col-4">
            <mat-label>Product Name <span class="asterisk">*</span></mat-label>
            <input autocomplete="new-txtProductName" maxlength="100" formControlName="txtProductName" matInput type="text" />
            <mat-error
              *ngIf="productSupplyForm?.get('txtProductName')?.touched &&productSupplyForm?.get('txtProductName')?.errors?.required">
              Product Name is a required field!
            </mat-error>
          </mat-form-field>
          <!-- resupply -->
          <mat-form-field class="col-4">
            <mat-label>Main Product<span class="asterisk">*</span> </mat-label>
            <mat-select formControlName="txtMainProduct" [(value)]="MainProduct" #singleSelect>
              <mat-option>
                <ngx-mat-select-search placeholderLabel="Search" noEntriesFoundLabel="No Matches found"
                  formControlName="txtMainProductFilter"></ngx-mat-select-search>
              </mat-option>
              <mat-option *ngFor="let provider of filterMainProduct | async" [value]="provider.productId">
                {{provider.productName}}
              </mat-option>
            </mat-select>

            <button mat-button (click)="MainProduct=undefined;$event.stopPropagation()" *ngIf="MainProduct" matSuffix
              (click)="clearMainProduct()" mat-icon-button >
              <mat-icon>close</mat-icon>
            </button>
          </mat-form-field>

          <mat-form-field class="col-4">
            <mat-label>HCPC Code <span class="asterisk">*</span></mat-label>
            <input autocomplete="new-txtHcpc" maxlength="40" formControlName="txtHcpc" matInput type="text" />
            <mat-error
              *ngIf="productSupplyForm?.get('txtHcpc')?.touched &&productSupplyForm?.get('txtHcpc')?.errors?.required">
              HCPC Code is a required field!
            </mat-error>
          </mat-form-field>
          <mat-form-field class="col-4" [hidden]="true">
            <mat-label>Product Code <span class="asterisk">*</span></mat-label>
            <input autocomplete="new-txtProductCode" maxlength="40" formControlName="txtProductCode" matInput type="text" />
            <mat-error
              *ngIf="productSupplyForm?.get('txtProductCode')?.touched &&productSupplyForm?.get('txtProductCode')?.errors?.required">
              Product Code is a required field!
            </mat-error>
            <mat-error *ngIf="
            productSupplyForm?.get('txtProductCode')?.touched &&
            productSupplyForm?.get('txtProductCode')?.errors?.number
          ">
              Enter only Numbers!
            </mat-error>
          </mat-form-field>

          <mat-form-field class="col-4">
            <mat-label>Minimum Keeping Stock<span class="asterisk">*</span></mat-label>
            <input autocomplete="new-txtMinQty" maxlength="40" min="1" formControlName="txtMinQty" matInput type="text" />
            <mat-error *ngIf="
            productSupplyForm?.get('txtMinQty')?.touched &&
            productSupplyForm?.get('txtMinQty')?.errors?.required
                          ">
              Units is a required field!
            </mat-error>


            <mat-error *ngIf="
            productSupplyForm?.get('txtMinQty')?.touched &&
            productSupplyForm?.get('txtMinQty')?.errors?.number
              ">
              Enter only Numbers!
            </mat-error>
          </mat-form-field>
          <mat-form-field class="col-5">
            <mat-label>Description <span class="asterisk">*</span></mat-label>
            <textarea formControlName="txtDescription" rows="3" matInput maxlength="400"></textarea>
            <mat-error *ngIf="
                    productSupplyForm?.get('txtDescription')?.touched &&
                    productSupplyForm?.get('txtDescription')?.errors?.required
                  ">
              Description is a required field!
            </mat-error>
          </mat-form-field>
        </div>
      </div>
    </form>
  </div>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button *ngIf="main" [disabled]="!(!productForm.invalid  && productForm.dirty)" mat-button class="buttonColor"
    (click)='saveMainProduct()'>Save
  </button>
  <button *ngIf="reSupply" [disabled]="!(!productSupplyForm.invalid && productSupplyForm.dirty )" mat-button
    class="buttonColor" (click)='saveSupplyProduct()'>Save
  </button>
  <button class="buttonCancelColor" mat-button mat-dialog-close>Close</button>

</mat-dialog-actions>
