import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-shipping-ticket-page2',
  templateUrl: './shipping-ticket-page2.component.html',
  styleUrls: ['./shipping-ticket-page2.component.scss']
})
export class ShippingTicketPage2Component implements OnInit {
  displayedColumns: string[] = [  'name', 'phn', 'date','hcpc','Status','Options',];
  dataSource: MatTableDataSource<any> = new MatTableDataSource<any>([]);

  userSearchForm : FormGroup

  constructor(
    private formBuilder: FormBuilder,
  ) { }

  ngOnInit(): void {
       //fromgrup for table
       this.userSearchForm = this.formBuilder.group({
        // appointmentTypeSearch: new FormControl(""),
        nameSearch: new FormControl(""),
        phnSearch: new FormControl(""),
        dateSearch: new FormControl(""),
        statusSearch: new FormControl(""),
        txthcpc: new FormControl("")
      });
  }

}
