import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { nikoBillingMenu } from 'projects/shared/src/app/enums/allenums.enum';

@Component({
  selector: 'app-niko-billing',
  templateUrl: './niko-billing.component.html',
  styleUrls: ['./niko-billing.component.scss'],
})
export class NikoBillingComponent implements OnInit {
  nikoBillingMenu = nikoBillingMenu;
  activeTab = nikoBillingMenu.INVOICES;

  constructor(private title: Title,) {}

  ngOnInit() {
    this.title.setTitle('Billing | Qsecure');
  }

  selectTab(tab: number) {
    // Check if the clicked tab is already active
    if (this.activeTab === tab) {
      return; // Exit the function if the clicked tab is already active
    }
    this.activeTab = tab;
  }
}
