<div class="container-fluid">
  <!-- Headers -->
  <div class="row">
    <div class="col-12 d-flex justify-content-between">
      <h3 class="font-weight-bolder mb-2 mt-2">
        <b class="customThemeClass">List / Allowables Lookup</b>
      </h3>
      <!-- <button mat-raised-button class="buttonColor" (click)="calculateSum()">
        Test
      </button> -->
      <div class="col-6">
        <form [formGroup]="allowablesForm">
          <div class="row">
            <mat-form-field class="pr-3 col-6 dd-text">
              <mat-label>Patient (Optional)</mat-label>
              <mat-select
                [(ngModel)]="patient"
                formControlName="drpPatient"
                #singleSelect
              >
                <mat-option>
                  <ngx-mat-select-search
                    placeholderLabel="Search"
                    noEntriesFoundLabel="No Matches found"
                    formControlName="txtPatientFilter"
                    (keyup)="
                    searchPatient($event.target.value, singleSelect.value)
                  "
                  ></ngx-mat-select-search>
                </mat-option>

                <!-- <mat-option
                  *ngFor="let chart of filteredChartIds | async"
                  [value]="chart.patientId"
                >
                  {{ chart.idAndNameAndDOB }}
                </mat-option> -->
                <mat-option
                    *ngFor="let chart of lstChartNos"
                    [value]="chart.patientId"
                  >
                    {{ chart.ddValue }}
                  </mat-option>
              </mat-select>
              <button
                matSuffix
                (click)="clearPatient()"
                (click)="patient = ''; $event.stopPropagation()"
                *ngIf="patient"
                mat-icon-button
              >
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field>

            <mat-form-field class="pr-3 col-6 dd-text">
              <mat-label>Insurance Category (Optional)</mat-label>
              <!-- <span class="asterisk">*</span> -->
              <!-- (selectionChange)="addProductRow()" -->
              <mat-select
                [(ngModel)]="insurance"
                formControlName="drpInsuranceCategory"
                #singleSelect
              >
                <mat-option>
                  <ngx-mat-select-search
                    placeholderLabel="Search"
                    noEntriesFoundLabel="No Matches found"
                    formControlName="txtInsuranceCategory"
                  ></ngx-mat-select-search>
                </mat-option>

                <mat-option
                  *ngFor="let insurance of filteredInsuranceCategory | async"
                  [value]="insurance.id"
                >
                  {{ insurance.insuranceCategory }}
                </mat-option>
              </mat-select>
              <button
                matSuffix
                (click)="clearInsurance()"
                (click)="insurance = ''; $event.stopPropagation()"
                *ngIf="insurance"
                mat-icon-button
              >
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field>
          </div>
        </form>
      </div>
    </div>
  </div>

  <!-- Content -->
  <div class="row">
    <div class="col-12">
      <div class="card card-body">
        <div class="table table-responsive">
          <table
            datatable
            #itemTable
            class="row-border hover display w-100"
            [dtOptions]="dtItemTableOptions"
            [dtTrigger]="dtTrigger"
          >
            <thead>
              <tr>
                <th>sortNumber</th>
                <th [matTooltip]="'Options'">Options</th>
                <th [matTooltip]="'Product Code'">Product Code</th>
                <th [matTooltip]="'Description'">Description</th>
                <th [matTooltip]="'Size'">Size</th>
                <th [matTooltip]="'Unit Cost($)'">Unit Cost($)</th>
                <th [matTooltip]="'Cash($)'">Cash($)</th>
                <th [matTooltip]="'Quantity'">Quantity</th>
                <th [matTooltip]="'Discount(%)'">Discount(%)</th>
                <th [matTooltip]="'Ins Allowable($)'">Ins Allowable($)</th>
                <th [matTooltip]="'Ins Allowable Qty'">Ins Allowable Qty</th>
                <th [matTooltip]="'Patient Ordered Qty'">
                  Patient Ordered Qty
                </th>
                <th [matTooltip]="'Ins Allowable Days'">Ins Allowable Days</th>
                <th [matTooltip]="'Actual Cost($)'">Actual Cost($)</th>
                <th [matTooltip]="'Allowable Cost($)'">Allowable Cost($)</th>
                <th [matTooltip]="'Accessory Cost($)'">Accessory Cost($)</th>
                <th [matTooltip]="'Points'">Points</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let data of itemsList">
                <td>{{ data.sortNumber }}</td>
                <td [matTooltip]="'Close'">
                  <mat-icon
                    type="button"
                    (click)="removeProduct(data.mmProductId)"
                    class="h-25"
                    mat-icon-button
                    class="text-danger"
                    >close</mat-icon
                  >
                </td>
                <td [matTooltip]="'Product Code'">
                  <mat-select
                    (selectionChange)="
                      loadProductDetails(data, drpProductCode.value)
                    "
                    #drpProductCode
                    [(value)]="data.mmProductId"
                    placeholder="Product Code"
                    class="dd-text"
                  >
                    <mat-option>
                      <ngx-mat-select-search
                        [formControl]="searchControl"
                        placeholderLabel="Search"
                        noEntriesFoundLabel="No Matches found"
                        (keyup)="filterProducts()"
                      ></ngx-mat-select-search
                    ></mat-option>

                    <mat-option
                      *ngFor="let products of filteredOptions"
                      [value]="products.productId"
                    >
                      {{ products.productName }}
                    </mat-option>
                  </mat-select>
                </td>
                <td [matTooltip]="data?.productDescription || '-'">
                  {{ data.productDescription || "-" }}
                </td>
                <td [matTooltip]="data?.sizeName || '-'">
                  {{ data.sizeName || "-" }}
                </td>
                <td
                  class="text-right"
                  [matTooltip]="data.unitCost ? '$' + data.unitCost : '-'"
                >
                  {{ data.unitCost ? "$" + data.unitCost : "-" }}
                </td>
                <td
                  class="text-right"
                  [matTooltip]="
                    data.unitAccessory ? '$' + data.unitAccessory : '-'
                  "
                >
                  {{ data.unitAccessory ? "$" + data.unitAccessory : "-" }}
                </td>
                <td [matTooltip]="'Quantity'">
                  <!-- {{ data.qty || "-" }} -->

                  <!-- (focusout)="updateQty(data)" -->
                  <input
                    maxlength="8"
                    class="textbox-border-class text-right"
                    [(ngModel)]="data.qty"
                    #txtQuantity="ngModel"
                    required
                    (change)="onQtyandDiscountChange(data)"
                    (keypress)="onKeyPress($event)"
                    matInput
                    type="text"
                  />
                  <div
                    class="text-danger dd-text text-center"
                    *ngIf="!txtQuantity.valid && txtQuantity.touched"
                  >
                    Required!
                  </div>
                  <div
                    class="text-danger dd-text text-error text-center"
                    *ngIf="
                      txtQuantity.value === '0' ||
                      txtQuantity.value === null ||
                      txtQuantity.value === ''
                    "
                  >
                    Enter Minimum Quantity!
                  </div>
                </td>
                <td [matTooltip]="'Discount(%)'">
                  <!-- {{ data.discountPercent || "-" }} -->
                  <!-- (focusout)="updateAccessory(data)" -->
                  <input
                    maxlength="8"
                    class="textbox-border-class text-right"
                    [(ngModel)]="data.discountPercent"
                    #txtDiscount="ngModel"
                    required
                    (change)="onQtyandDiscountChange(data)"
                    placeholder="Discount(%)"
                    (keypress)="onKeyPress($event)"
                    matInput
                    type="text"
                  />
                </td>
                <td
                  class="text-right"
                  [matTooltip]="
                    data.unitAllowable ? '$' + data.unitAllowable : '-'
                  "
                >
                  {{ data.unitAllowable ? "$" + data.unitAllowable : "-" }}
                </td>
                <td
                  class="text-right"
                  [matTooltip]="data?.allowableQuantity || '-'"
                >
                  {{ data.allowableQuantity || "-" }}
                </td>
                <td
                  class="text-right"
                  [matTooltip]="data?.patientOrderCount || '-'"
                >
                  {{ data.patientOrderCount || "-" }}
                </td>
                <td
                  class="text-right"
                  [matTooltip]="data?.allowableDays || '-'"
                >
                  {{ data.allowableDays || "-" }}
                </td>
                <td
                  class="text-right"
                  [matTooltip]="data.actualCost ? '$' + data.actualCost : '-'"
                >
                  {{ data.actualCost ? "$" + data.actualCost : "-" }}
                </td>
                <td
                  class="text-right"
                  [matTooltip]="data.allowable ? '$' + data.allowable : '-'"
                >
                  {{ data.allowable ? "$" + data.allowable : "-" }}
                </td>
                <td
                  class="text-right"
                  [matTooltip]="data.accessory ? '$' + data.accessory : '-'"
                >
                  {{ data.accessory ? "$" + data.accessory : "-" }}
                </td>
                <td class="text-right" [matTooltip]="data.points_Cal || '-'">
                  {{ data.points_Cal || "-" }}
                </td>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
                <th class="text-right" [matTooltip]="'Total'">Total</th>
                <th class="text-right">
                  {{ actualCostSum ? "$" + actualCostSum : "-" }}
                </th>
                <th class="text-right">
                  {{ allowableCostSum ? "$" + allowableCostSum : "-" }}
                </th>
                <th class="text-right">
                  {{ accessoryCostSum ? "$" + accessoryCostSum : "-" }}
                </th>
                <th class="text-right">{{ pointsSum }}</th>
              </tr>
            </tfoot>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>
