<br />
<div class="card card-body">
  <form [formGroup]="postingForm">
    <div class="row">
      <div class="col-5">
        <div class="row">
          <div class="col-lg-6">
            <div class="row">
              <div class="col-md-12">
                <!-- <h4 *ngIf='!isIcd9Shown' class="customThemeClass"><b>Diagnosis Codes-ICD-9</b></h4> -->
                <h4 class="customThemeClass"><b>Diagnosis Codes-ICD-9</b></h4>
              </div>
            </div>
            <mat-card-content>
              <div class="row">
                <!-- <ng-select formControlName="drpDiag9" (change)='onIcd9Change($event)' class="col-12" multiple
                  placeholder="Diagnosis Code-ICD-9" maxlength="40">
                  <ng-option *ngFor="let diagnosis of drpDiagnosis9" [value]="diagnosis.diagnosisCode9ShortCodeId">
                    {{ diagnosis.diagnosisCode +" "+ diagnosis.diagnosisCodeTypeData }}</ng-option>
                </ng-select> -->
                <mat-form-field class="col-12">
                  <mat-label>Diagnosis Code ICD-9</mat-label>
                  <mat-select multiple formControlName="drpDiag9" [(value)]="txtIcd9" #singleSelect>
                    <mat-option>
                      <ngx-mat-select-search placeholderLabel="Search" noEntriesFoundLabel="No Matches found"
                        formControlName="txtIcd9Filter"></ngx-mat-select-search>
                    </mat-option>
                    <mat-option *ngFor="let diagnosis9 of filteredIcd9Types | async"
                      [value]="diagnosis9.diagnosisCodeTypeData">
                      {{diagnosis9.diagnosisCode + ' / ' + diagnosis9.diagnosisCodeTypeData }}
                    </mat-option>
                  </mat-select>
                  <button mat-button (click)="txtIcd9='';$event.stopPropagation()" *ngIf="txtIcd9" matSuffix
                    (click)="clearIcd9()" mat-icon-button >
                    <mat-icon>close</mat-icon>
                  </button>
                </mat-form-field>
              </div>
            </mat-card-content>
          </div>
          <div class="col-lg-6">
            <div class="row">
              <div class="col-md-12">
                <h4 class="customThemeClass"><b>Diagnosis Codes-ICD-10 <span class="asterisk">*</span></b></h4>
                <!-- <h4 class="customThemeClass"><b>Diagnosis Codes-ICD-10</b></h4> -->
              </div>
            </div>
            <mat-card-content>
              <div class="row">
                <!-- <ng-select formControlName="drpDiag10ID1" (change)='onIcd10Change1($event)' class="col-12" multiple
                  placeholder="Diagnosis Code-ICD-10" maxlength="40">
                  <ng-option *ngFor="let diagnosis of drpDiagnosis10" [value]="diagnosis.diagnosisCode10ShortCodeId">
                    {{ diagnosis.diagnosisCode +" "+ diagnosis.diagnosisCodeTypeData }}</ng-option>
                </ng-select> -->

                <mat-form-field class="col-12">
                  <mat-label>Diagnosis Code-ICD-10</mat-label>
                  <mat-select multiple formControlName="drpDiag10ID" [(value)]="txtIcd10" #singleSelect>
                    <mat-option>
                      <ngx-mat-select-search placeholderLabel="Search" noEntriesFoundLabel="No Matches found"
                        formControlName="txtIcd10Filter"></ngx-mat-select-search>
                    </mat-option>
                    <mat-option *ngFor="let diagnosis of filteredIcd10Types | async"
                      [value]="diagnosis.diagnosisCodeTypeData">
                      {{diagnosis.diagnosisCode + ' / ' + diagnosis.diagnosisCodeTypeData }}
                    </mat-option>

                  </mat-select>
                  <button mat-button (click)="txtIcd10='';$event.stopPropagation()" *ngIf="txtIcd10" matSuffix
                    (click)="clearIcd10()" mat-icon-button>
                    <mat-icon>close</mat-icon>
                  </button>
                  <mat-error *ngIf="
                  postingForm?.get('drpDiag10ID')?.touched &&
                  postingForm?.get('drpDiag10ID')?.errors?.required
                ">
                    Diagnosis Code-ICD-10 is a required field!
                  </mat-error>
                </mat-form-field>

                <!-- <mat-error
                            *ngIf="
                            postingForm?.get('drpDiag10ID1')?.touched &&
                            postingForm?.get('drpDiag10ID1')?.errors?.required
                            "
                          >
                            &nbsp; &nbsp; Diagnosis Code ICD-10 is a required field!
                          </mat-error> -->
              </div>
            </mat-card-content>
          </div>
        </div>
        <mat-card-content>
          <div class="row">
            <!-- <ng-select formControlName="drpDiag10ID2" (change)='onIcd10Change2($event)' class="col-6"
              placeholder="Diagnosis Code-ICD-10" maxlength="40">
              <ng-option *ngFor="let diagnosis of drpDiagnosis10" [value]="diagnosis.diagnosisCode10ShortCodeId">
                {{diagnosis.diagnosisCode +" "+ diagnosis.diagnosisCodeTypeData }}</ng-option>
            </ng-select>
            <ng-select formControlName="drpDiag10ID3" (change)='onIcd10Change3($event)' class="col-6"
              placeholder="Diagnosis Code-ICD-10" maxlength="40">
              <ng-option *ngFor="let diagnosis of drpDiagnosis10" [value]="diagnosis.diagnosisCode10ShortCodeId">
                {{ diagnosis.diagnosisCode +" "+ diagnosis.diagnosisCodeTypeData }}</ng-option>
            </ng-select> -->
          </div>
        </mat-card-content>
      </div>
      <div class="col-7">
        <!-- <div class="row">
        <div class="col-12">
            &nbsp; <h4 class="customThemeClass"><b>Plan Information</b></h4>
        </div>
    </div> -->
        <table mat-table matSort [dataSource]="dataSource">
          <ng-container matColumnDef="Name">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Type</th>
            <td mat-cell *matCellDef="let record">
              {{ record.type }}
            </td>
          </ng-container>
          <ng-container matColumnDef="Starts">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
            <td mat-cell *matCellDef="let record">
              {{ record.name }}
            </td>
          </ng-container>
          <ng-container matColumnDef="Ends">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Policy No</th>
            <td mat-cell *matCellDef="let record">
              {{ record.policyNo }}
            </td>
          </ng-container>
          <ng-container matColumnDef="Copay">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Copay</th>
            <td mat-cell *matCellDef="let record">
              {{ record.actualDate }}
            </td>
          </ng-container>
          <ng-container matColumnDef="Deduct">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Deduct</th>
            <td mat-cell *matCellDef="let record">
              {{ record.deductible }}
            </td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
          <tr mat-row *matRowDef="let tblPolicesReponse; columns: columnsToDisplay"></tr>

        </table>
      </div>
    </div>
    <br>
    <div class="row">

      <div class="col-lg-12">
        <div class="row">
          <div class="col-md-6">
            <h4 class="customThemeClass"><b>Transaction Details</b></h4>
          </div>
        </div>
        <mat-card-content>
          <div class="row">
            <mat-form-field class="col-3">
              <mat-label>Item<span class="asterisk">*</span></mat-label>
              <mat-select formControlName="drpCode" [(value)]="code" (click)="clearCode()">
                <mat-option *ngFor="let code of drpitem" [value]="code.itemId">
                  {{ code.itemId + '/'+ code.name}}

                </mat-option>
              </mat-select>
              <button mat-button (click)="code='';$event.stopPropagation()" *ngIf="code" matSuffix (click)="clearCode()"
                mat-icon-button >
                <mat-icon>close</mat-icon>
              </button>
              <mat-error *ngIf="
                        postingForm?.get('drpCode')?.touched &&
                        postingForm?.get('drpCode')?.errors?.required">
                Item is a required field!
              </mat-error>
            </mat-form-field>
            <mat-form-field class="col-3">
              <mat-label>Insurance<span class="asterisk">*</span></mat-label>
              <!-- <input matInput formControlName="txtDescription" type="text" maxlength="40" /> -->
              <mat-select multiple formControlName="txtDescription" [(value)]="description"
                (click)="clearDescription()">
                <mat-option *ngFor="let code of drpinsurance" [value]="code.policy+'/'+code.payorLevel">
                  {{ code.policy+'/'+code.payorLevel }}
                </mat-option>
              </mat-select>
              <button mat-button (click)="description='';$event.stopPropagation()" *ngIf="description" matSuffix
                (click)="clearDescription()" mat-icon-button >
                <mat-icon>close</mat-icon>
              </button>
              <mat-error *ngIf="
                        postingForm?.get('txtDescription')?.touched &&
                        postingForm?.get('txtDescription')?.errors?.required">
                Insurance is a required field!
              </mat-error>
            </mat-form-field>

            <mat-form-field class="col-3">
              <mat-label>Provider</mat-label>
              <input autocomplete="new-txtProvider" matInput formControlName="txtProvider" type="text" maxlength="40" />
            </mat-form-field>
            <mat-form-field class="col-3">
              <mat-label>Check No</mat-label>
              <input autocomplete="new-txtCheckNo" matInput formControlName="txtCheckNo" type="text" maxlength="15" />
              <mat-error *ngIf="postingForm?.get('txtCheckNo')?.touched &&
                        postingForm?.get('txtCheckNo')?.errors?.number">
                Enter only Numbers!
              </mat-error>
            </mat-form-field>
          </div>

          <div class="row">
            <div class="col-3">
              <div class="row">
                <div class="col-6">
                  <div class="row">
                    <!-- Modifiers 1 -->

                    <mat-form-field class="col-6">
                      <mat-label> Modifiers 1 </mat-label>
                      <input autocomplete="new-txtModifiers1" matInput maxlength="4" formControlName="txtModifiers1" pattern="[0-9A-Za-z]*"
                        type="text" />
                      <mat-error *ngIf="
                      postingForm?.get('txtModifiers1')?.touched &&
                      postingForm?.get('txtModifiers1')?.errors?.pattern
          ">
                        Enter only AlphaNumeric!
                      </mat-error>
                    </mat-form-field>

                    <!-- Modifiers 2 -->
                    <mat-form-field class="col-6">
                      <mat-label> Modifiers 2 </mat-label>
                      <input autocomplete="new-txtModifiers2" matInput maxlength="4" formControlName="txtModifiers2" pattern="[0-9A-Za-z]*"
                        type="text" />
                      <mat-error *ngIf="
                      postingForm?.get('txtModifiers2')?.touched &&
                      postingForm?.get('txtModifiers2')?.errors?.pattern
          ">
                        Enter only AlphaNumeric!
                      </mat-error>
                    </mat-form-field>
                  </div>
                </div>
                <div class="col-6">
                  <div class="row">

                    <!-- Modifiers 3 -->
                    <mat-form-field class="col-3">
                      <mat-label> Modifiers 3 </mat-label>
                      <input autocomplete="new-txtModifiers3" matInput maxlength="4" formControlName="txtModifiers3" pattern="[0-9A-Za-z]*"
                        type="text" />
                      <mat-error *ngIf="
                      postingForm?.get('txtModifiers3')?.touched &&
                      postingForm?.get('txtModifiers3')?.errors?.pattern
  ">
                        Enter only AlphaNumeric!
                      </mat-error>
                    </mat-form-field>
                    <!-- Modifiers 4 -->
                    <mat-form-field class="col-3">
                      <mat-label> Modifiers 4 </mat-label>
                      <input autocomplete="new-txtModifiers4" matInput maxlength="4" formControlName="txtModifiers4" pattern="[0-9A-Za-z]*"
                        type="text" />
                      <mat-error *ngIf="
                      postingForm?.get('txtModifiers4')?.touched &&
                      postingForm?.get('txtModifiers4')?.errors?.pattern
  ">
                        Enter only AlphaNumeric!
                      </mat-error>
                    </mat-form-field>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-3">
              <div class="row">
                <div class="col-6">
                  <div class="row">
                    <mat-form-field class="col-6">
                      <mat-label>DX Pointer 1</mat-label>
                      <mat-select formControlName="drpDx1" [(value)]="dx1" (click)="clearDx1()" maxlength="40">
                        <mat-option *ngFor="let test of drpModifiers" [value]="test.shortCodeId">
                          {{ test.modifierName }}
                        </mat-option>
                      </mat-select>
                      <button mat-button (click)="dx1=undefined;$event.stopPropagation()" *ngIf="dx1" matSuffix
                        mat-icon-button >
                        <mat-icon>close</mat-icon>
                      </button>
                    </mat-form-field>
                    <mat-form-field class="col-6">
                      <mat-label>DX Pointer 2</mat-label>
                      <mat-select formControlName="drpDx2" [(value)]="dx2" (click)="clearDx2()" maxlength="40">
                        <mat-option *ngFor="let test of drpModifiers" [value]="test.shortCodeId">
                          {{ test.modifierName }}
                        </mat-option>
                      </mat-select>
                      <button mat-button (click)="dx2=undefined;$event.stopPropagation()" *ngIf="dx2" matSuffix
                        mat-icon-button >
                        <mat-icon>close</mat-icon>
                      </button>
                    </mat-form-field>
                  </div>
                </div>
                <div class="col-6">
                  <div class="row">
                    <mat-form-field class="col-6">
                      <mat-label>DX Pointer 3</mat-label>
                      <mat-select formControlName="drpDx3" [(value)]="dx3" (click)="clearDx3()" maxlength="40">
                        <mat-option *ngFor="let test of drpModifiers" [value]="test.shortCodeId">
                          {{ test.modifierName }}
                        </mat-option>
                      </mat-select>
                      <button mat-button (click)="dx3=undefined;$event.stopPropagation()" *ngIf="dx3" matSuffix
                        mat-icon-button >
                        <mat-icon>close</mat-icon>
                      </button>
                    </mat-form-field>
                    <mat-form-field class="col-6">
                      <mat-label>DX Pointer 4</mat-label>
                      <mat-select formControlName="drpDx4" [(value)]="dx4" (click)="clearDx4()" maxlength="40">
                        <mat-option *ngFor="let test of drpModifiers" [value]="test.shortCodeId">
                          {{ test.modifierName }}
                        </mat-option>
                      </mat-select>
                      <button mat-button (click)="dx4=undefined;$event.stopPropagation()" *ngIf="dx4" matSuffix
                        mat-icon-button >
                        <mat-icon>close</mat-icon>
                      </button>
                    </mat-form-field>
                  </div>
                </div>
              </div>
            </div>
            <mat-form-field class="col-3">
              <mat-label>POS <span class="asterisk">*</span></mat-label>
              <mat-select formControlName="drpPos" maxlength="40" [(value)]="pos" (click)="clearPos()">
                <mat-option *ngFor="let pos of drpPos" [value]="pos.shortCodeId">
                  {{ pos.posName }}
                </mat-option>
              </mat-select>
              <button mat-button (click)="pos=undefined;$event.stopPropagation()" *ngIf="pos" matSuffix mat-icon-button
                (click)="clearPos()" >
                <mat-icon>close</mat-icon>
              </button>

              <mat-error *ngIf="postingForm?.get('drpPos')?.touched &&postingForm?.get('drpPos')?.errors?.required">
                POS is a required field!
              </mat-error>
            </mat-form-field>
            <mat-form-field class="col-3">
              <mat-label>TOS</mat-label>
              <mat-select formControlName="drpTos" maxlength="40" [(value)]="tos" (click)="clearTos()">
                <mat-option *ngFor="let tos of drpTos" [value]="tos.shortCodeId">
                  {{ tos.tosName }}
                </mat-option>
              </mat-select>
              <button mat-button (click)="tos=undefined;$event.stopPropagation()" *ngIf="tos" matSuffix mat-icon-button
                >
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field>
          </div>
          <div class="row">

            <!-- <mat-form-field class="col-3">
                        <mat-label>Date From</mat-label>
                        <input matInput type="text" formControlName="txtDateFrom" maxlength="40" />
                    </mat-form-field> -->
            <mat-form-field class="col-3">
              <mat-label>Date From</mat-label>
              <input autocomplete="new-txtDateFrom" maxlength="40" placeholder="MM/DD/YYYY" formControlName="txtDateFrom" matInput
                [matDatepicker]="txtDateFrom" [formControl]="postingForm.controls.txtDateFrom" />
              <mat-datepicker-toggle matSuffix [for]="txtDateFrom">
              </mat-datepicker-toggle>
              <mat-datepicker #txtDateFrom></mat-datepicker>
              <mat-error *ngIf="
                        postingForm?.get('txtDateFrom')?.touched &&
                        postingForm?.get('txtDateFrom')?.errors
                              ">
                Enter Valid Date!
              </mat-error>
            </mat-form-field>
            <!-- <mat-form-field class="col-3">
                        <mat-label>Date To</mat-label>
                        <input matInput type="text" formControlName="txtDateTo" maxlength="40" />
                    </mat-form-field> -->
            <mat-form-field class="col-3">
              <mat-label>Date To</mat-label>
              <input autocomplete="new-txtDateTo" maxlength="40" placeholder="MM/DD/YYYY" formControlName="txtDateTo" matInput
                [matDatepicker]="txtDateTo" [formControl]="postingForm.controls.txtDateTo"
                [min]="postingForm.controls.txtDateFrom.value" />
              <mat-datepicker-toggle matSuffix [for]="txtDateTo">
              </mat-datepicker-toggle>
              <mat-datepicker #txtDateTo></mat-datepicker>
              <mat-error *ngIf="
                        postingForm?.get('txtDateTo')?.touched &&
                        postingForm?.get('txtDateTo')?.errors
                              ">
                Enter Valid Date!
              </mat-error>
            </mat-form-field>
            <mat-form-field class="col-3">
              <mat-label>Fee<span class="asterisk">*</span></mat-label>
              <input autocomplete="new-txtFee" matInput formControlName="txtFee" type="text" maxlength="10" />
              <mat-error *ngIf="
                        postingForm?.get('txtFee')?.touched &&
                        postingForm?.get('txtFee')?.errors?.required
                              ">
                Fee is a required field!
              </mat-error>
              <mat-error *ngIf="
                        postingForm?.get('txtFee')?.touched &&
                        postingForm?.get('txtFee')?.errors?.number
                              ">
                Enter Only numbers!
              </mat-error>

            </mat-form-field>
            <mat-form-field class="col-3">
              <mat-label>Units <span class="asterisk">*</span></mat-label>
              <input autocomplete="new-txtUnits" matInput type="text" formControlName="txtUnits" maxlength="10" />
              <mat-error *ngIf="
                        postingForm?.get('txtUnits')?.touched &&
                        postingForm?.get('txtUnits')?.errors?.number
                              ">
                Enter Only numbers!
              </mat-error>
              <mat-error *ngIf="
            postingForm?.get('txtUnits')?.touched &&
            postingForm?.get('txtUnits')?.errors?.required
                  ">
                Units is a required field!
              </mat-error>
            </mat-form-field>
          </div>
          <div class="row">

            <mat-form-field class="col-3">
              <mat-label>Copay/Coins</mat-label>
              <input autocomplete="new-txtCopay" matInput formControlName="txtCopay" type="text" maxlength="40" />
            </mat-form-field>
            <mat-form-field class="col-3">
              <mat-label>Pat.Other</mat-label>
              <input autocomplete="new-txtOther" matInput formControlName="txtOther" type="text" maxlength="40" />
            </mat-form-field>
            <mat-form-field class="col-3">
              <mat-label>Total <span class="asterisk">*</span></mat-label>
              <input autocomplete="new-txtTotal" matInput formControlName="txtTotal" type="text" maxlength="10" appTwoDigitDecimaNumber />
              <mat-error *ngIf="
                        postingForm?.get('txtTotal')?.touched &&
                        postingForm?.get('txtTotal')?.errors?.number
                              ">
                Enter Only numbers!
              </mat-error>
              <mat-error *ngIf="
            postingForm?.get('txtTotal')?.touched &&
            postingForm?.get('txtTotal')?.errors?.required
                  ">
                Units is a required field!
              </mat-error>
            </mat-form-field>
            <mat-form-field class="col-3">
              <mat-label>Document</mat-label>
              <input autocomplete="new-txtDocument" matInput formControlName="txtDocument" type="text" maxlength="40" />
            </mat-form-field>
          </div>
          <div class="row">
            <mat-form-field class="col-3">
              <mat-label>Statement Note</mat-label>
              <input autocomplete="new-txtStatement" matInput formControlName="txtStatement" type="text" maxlength="40" />
            </mat-form-field>
            <!-- <mat-form-field class="col-3">
                        <mat-label>Patient Resp</mat-label>
                        <input matInput formControlName="txtPatientResp" type="text" maxlength="40" />
                    </mat-form-field> -->
            <div class="col-3">
              <br>
              <mat-checkbox formControlName="txtPatientResp">Patient Resp</mat-checkbox>
            </div>
          </div>
        </mat-card-content>
      </div>
    </div>
  </form>
  <div class="row">
    <div class="col-sm-12">
      <div class="text-lg-center">

        <button type="button" mat-button [disabled]="saveButtonHide|| !(!postingForm.invalid && postingForm.dirty)"
          (click)="getClaimInfo()" class="buttonColor mb-2 mr-2">
          Save
          <mat-icon *ngIf='isShowSpinner'>
            <mat-spinner class="spinner-border spinner-border-sm" diameter="20"> </mat-spinner>
          </mat-icon>
        </button>

      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-12">
      &nbsp; <h4 class="customThemeClass"><b>Posting List - Total: {{total}}</b></h4>
    </div>
  </div>
  <app-table [strPageType]="strPageType2" (viewButton)="ViewPosting($event)" [isLoading]="isLoading"
    (deleteButton)="deleteData($event)">
  </app-table>
</div>


<div class="row"></div>
