import { PagedAndSortedResultRequestDto } from '@abp/ng.core';
import { DatePipe } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { doctorInterface } from 'projects/billing/src/app/billing-claim-info/billing-claim-info.component';
import { MasterProviderService } from 'projects/order/src/app/order-proxy/order-management/inventory/master-provider.service';
import { DoctorService } from 'projects/patient/src/app/patient-proxy/patient';
import { PatientPersonalService } from 'projects/patient/src/app/patient-proxy/patient-optimization';
import { defaultGuid } from 'projects/shared/src/app/enums/allenums.enum';
import { Observable, Subscription } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

@Component({
  selector: 'app-patient-statement-history',
  templateUrl: './patient-statement-history.component.html',
  styleUrls: ['./patient-statement-history.component.scss'],
})
export class PatientStatementHistoryComponent implements OnInit {
  patientStatementHistoryForm: FormGroup;
  isLoaded: boolean = true;
  ltProviders: any = [];
  ltPatientStatementHistory: any = [];
  referralPractice: any;

  filteredProviders: Observable<any[]> | undefined;
  filteredPractices: Observable<doctorInterface[]> | undefined;
  referralDropdown: Subscription;
  subscription$: Subscription[] = [];
  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      patientId: string;
      chartNo: string;
    } = {
      patientId: defaultGuid,
      chartNo: '',
    },
    private fb: FormBuilder,
    private providerService: MasterProviderService,
    private doctorService: DoctorService,
    private datePipe: DatePipe,
    private patientPersonalService: PatientPersonalService
  ) {}

  ngOnInit() {
    this.initializeForms();
    this.loadDropdowns();
    this.getOrderHistoryReport(this.data.patientId);
  }

  initializeForms() {
    this.patientStatementHistoryForm = this.fb.group({
      txtStartDate: new FormControl(''),
      txtEndDate: new FormControl(''),
      drpPractices: new FormControl(''),
      txtPracticeFilter: new FormControl(''),
      drpProviders: new FormControl(''),
      txtProviderFilter: new FormControl(''),
    });
  }
  loadDropdowns() {
    this.practiceSearch('');
    this.loadProviders();
  }

  //Load Practices
  //Practice Management Dropdown
  practiceSearch(value) {
    if (this.referralDropdown) {
      this.referralDropdown.unsubscribe();
    }
    this.referralDropdown = this.doctorService
      .getPracticeDropdown(value, defaultGuid)
      .subscribe(
        (response) => {
          this.referralPractice = response;
          //Referring Provider Doctor Dropdown
          this.filteredPractices = this.patientStatementHistoryForm
            ?.get('txtPracticeFilter')
            ?.valueChanges.pipe(
              startWith(''),
              map((value) =>
                this.referralPractice?.filter((option) =>
                  option?.practiceName
                    ?.toLowerCase()
                    ?.includes(value?.toLowerCase() ?? '')
                )
              )
            );
        },
        (err) => {}
      );
  }

  //Get Provider list
  loadProviders() {
    const providerList = this.providerService
      .getListV1(new PagedAndSortedResultRequestDto())
      .subscribe((response) => {
        response?.items?.forEach((element) => {
          this.ltProviders.push({
            id: element?.id,
            name: element?.fullName,
            shortCodeId: element?.shortCodeId,
            fullName: element?.fullName,
            npi: element?.npi,
          });
        });
        this.filteredProviders = this.patientStatementHistoryForm
          ?.get('txtProviderFilter')
          ?.valueChanges.pipe(
            startWith(''),
            map((value) =>
              this.ltProviders?.filter((option) =>
                option?.fullName
                  ?.toLowerCase()
                  ?.includes(value?.toLowerCase() ?? '')
              )
            )
          );
      });
    this.subscription$.push(providerList);
  }
  //To load the Order History Report
  getOrderHistoryReport(patientId: string) {
    const startDate =
      this.patientStatementHistoryForm.get('txtStartDate').value;
    const endDate = this.patientStatementHistoryForm.get('txtEndDate').value;
    let fromDate = this.datePipe.transform(startDate, 'MM/dd/yyyy');
    let toDate = this.datePipe.transform(endDate, 'MM/dd/yyyy');
    let practice = this.patientStatementHistoryForm.value.drpPractices;
    let provider = this.patientStatementHistoryForm.value.drpProviders;
    this.isLoaded = false;

    this.patientPersonalService
      .getPatientExportOrderDataByPatientId(patientId)
      .subscribe(
        (response) => {
          this.ltPatientStatementHistory = response ?? [];
          this.isLoaded = true;
        },
        (err) => {
          this.ltPatientStatementHistory = [];
          this.isLoaded = true;
        }
      );
  }
  resetForm() {
    this.patientStatementHistoryForm.reset();
  }
}
