<div class="row">
  <div class="col-12">
    <p class="dd-heading-text customThemeClass mb-0 pt-4 tb-2 pl-2">
      Do Authorization List
    </p>
  </div>
</div>

<div class="row">
  <div class="col-12 mt-3">
    <div class="card card-body py-0 px-1 mb-3">
      <div class="row" *ngIf="isLoading">
        <div class="col-12">
          <div class="d-flex justify-content-center" *ngIf="isLoading">
            <img
              class="img-responsive pb-2 mr-1"
              src="assets/gifs/Infinity-loader.gif"
              style="color: lightseagreen"
            />
          </div>
        </div>
      </div>
      <mat-accordion *ngIf="!isLoading" #firstAccordion="matAccordion">
        <mat-expansion-panel class="accordion-border" *ngFor="let auth of sLstAuthorizationId">
          <mat-expansion-panel-header >
            <!-- Accordion Header-->
            <mat-panel-title
              class="d-flex justify-content-between align-items-center"
            >
              <p class="dd-heading-text customThemeClass mb-0">
                {{ auth.chartNo }} / {{ auth.patientName }}
              </p>
              <p class="font-weight-bolder blinking mb-0 mt-0">Yet to Start</p>
            </mat-panel-title>
          </mat-expansion-panel-header>

          <!-- Auth Content -->
          <div class="row">
            <div class="col-12">
              <app-patient-auth-save-update-form
                [isSupplies]="true"
                [isView]="true"
                [authorizationId]="auth.athorizationId"
                [isMultiAuth]="true"
                (reload)="loadPage($event)"
              >
              </app-patient-auth-save-update-form>
            </div>
          </div>
        </mat-expansion-panel>
      </mat-accordion>
    </div>
  </div>
</div>
