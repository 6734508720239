import type { ComplianceStatusDTO, CreateUpdateComplianceStatusDTO } from './dto/models';
import { RestService } from '@abp/ng.core';
import type { PagedAndSortedResultRequestDto, PagedResultDto } from '@abp/ng.core';
import { Injectable } from '@angular/core';
import { ActiveStatus } from 'projects/admin/src/app/admin-proxy/platform-app-management/rcm/platform-management/localization/enumeration/active-status.enum';
import { PatientCallDetailsDTO } from 'projects/patient/src/app/patient-proxy/patient-optimization/dto/models';

@Injectable({
  providedIn: 'root',
})
export class ComplianceStatusService {
  apiName = 'orderManagement';

  create = (input: CreateUpdateComplianceStatusDTO) =>
    this.restService.request<any, ComplianceStatusDTO>({
      method: 'POST',
      url: '/api/orderApp/compliance-status',
      body: input,
    },
    { apiName: this.apiName });

  delete = (id: string) =>
    this.restService.request<any, void>({
      method: 'DELETE',
      url: `/api/orderApp/compliance-status/${id}`,
    },
    { apiName: this.apiName });

  enableDisableComplianceStatusById = (Id: string) =>
    this.restService.request<any, string>({
      method: 'POST',
      responseType: 'text',
      url: `/api/orderApp/compliance-status/enable-disable-compliance-status/${Id}`,
    },
    { apiName: this.apiName });

  get = (id: string) =>
    this.restService.request<any, ComplianceStatusDTO>({
      method: 'GET',
      url: `/api/orderApp/compliance-status/${id}`,
    },
    { apiName: this.apiName });

  getAllList = (input: PagedAndSortedResultRequestDto) =>
    this.restService.request<any, PagedResultDto<ComplianceStatusDTO>>({
      method: 'GET',
      url: '/api/orderApp/compliance-status/list',
      params: { skipCount: input.skipCount, maxResultCount: input.maxResultCount, sorting: input.sorting },
    },
    { apiName: this.apiName });

  getComplianceStatusList = (statusFilter: ActiveStatus) =>
    this.restService.request<any, PagedResultDto<ComplianceStatusDTO>>({
      method: 'GET',
      url: '/api/orderApp/compliance-status/compliance-status-list',
      params: { statusFilter },
    },
    { apiName: this.apiName });

  getList = (input: PagedAndSortedResultRequestDto) =>
    this.restService.request<any, PagedResultDto<ComplianceStatusDTO>>({
      method: 'GET',
      url: '/api/orderApp/compliance-status',
      params: { skipCount: input.skipCount, maxResultCount: input.maxResultCount, sorting: input.sorting },
    },
    { apiName: this.apiName });

  getPatientDetailsByGPatient = (gPatient: string) =>
    this.restService.request<any, PatientCallDetailsDTO>({
      method: 'GET',
      url: '/api/orderApp/compliance-status/patient-details',
      params: { gPatient },
    },
    { apiName: this.apiName });

  update = (id: string, input: CreateUpdateComplianceStatusDTO) =>
    this.restService.request<any, ComplianceStatusDTO>({
      method: 'PUT',
      url: `/api/orderApp/compliance-status/${id}`,
      body: input,
    },
    { apiName: this.apiName });

    getComplianceStatusForComplaince = () =>
    this.restService.request<any, PagedResultDto<ComplianceStatusDTO>>({
      method: 'GET',
      url: '/api/orderApp/compliance-status/compliance-status-for-complaince',
    },
    { apiName: this.apiName });

  constructor(private restService: RestService) {}
}
