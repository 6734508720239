import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { defaultGuid } from 'projects/shared/src/app/enums/allenums.enum';
import { HellosignSignatureService } from '../order-proxy/order-management/order-optimization/hellosign-signature.service';
import { HttpErrorResponse } from '@angular/common/http';
import Swal from 'sweetalert2';
import { DatePipe } from '@angular/common';
import { MessageAndMailResponseService } from 'projects/admin/src/app/admin-proxy/platform-app-management/rcm/platform-management/smsresponse-history';

@Component({
  selector: 'app-time-line',
  templateUrl: './time-line.component.html',
  styleUrls: ['./time-line.component.scss']
})
export class TimeLineComponent implements OnInit {
  timeLineDetails:any;
  noData:boolean=false;
  patientName:string;
  chartId:string;
  // timeLine = [
  //   {date: '01/05/2024 8:51 AM', event: 'Sent On', description: 'Lee Glender - 6321456987'},
  //   {date: '01/09/2024 11:05 AM', event: 'Viewed On', description: ''},
  //   {date: '01/09/2024 13:09 PM', event: 'Signed On', description: ''},
  //   {date: '01/12/2024 14:00 PM', event: 'Received On', description: ''}
  // ];
  constructor(
    private HelloSignatureService: HellosignSignatureService,
    private messageAndMailResponseService: MessageAndMailResponseService,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      orderId: string;
      signatureId:string;
      scheduleId: string;
      isScheduleStatus: boolean
    } = {
      orderId: defaultGuid,
      signatureId:defaultGuid,
      scheduleId: '',
      isScheduleStatus: false
    },
  ) { }

  ngOnInit(): void {
    if(this.data.isScheduleStatus == true){
      this.loadScheduleHistory()
    } else{
      this.loadHistoryDetails();

    }
  }
  loadHistoryDetails(){
    this.HelloSignatureService.signatureHistoryByGOrderIdAndGSignature(this.data.orderId,this.data.signatureId).subscribe(response => {
     this.timeLineDetails=response.signatureHistoryData;
     this.patientName=response.patientName;
     this.chartId=response.patientChartId;
     if(this.timeLineDetails.length==0){
      this.noData=true;
     }else{
      this.noData=false;
     }
    },(err)=>{
    this.noData=true;
      // const data: HttpErrorResponse = err;
      // Swal.fire({
      //   icon: 'info',
      //   text: data?.error?.error?.message
      // });
    })
  }
  loadScheduleHistory(){
    this.messageAndMailResponseService.getScheduleResponseByScheduleId(this.data.scheduleId).subscribe(response => {
      this.patientName = response?.patientName
      this.chartId = response?.chartId
      this.timeLineDetails = response?.reponse 
      if(this.timeLineDetails.length == 0){
        this.noData = true;
       }else{
        this.noData = false;
       }
      // this.timeLineDetails = response.title
    })
  }
  formatDate(date: Date): string {
    const datePipe = new DatePipe('en-US');
    return datePipe.transform(date, 'MM-dd-yyyy HH:mm');
  }
  getFormattedDateTimeZone(date: string): string {
    if (date) {
      const formattedDate = new Date(date);
      const options: Intl.DateTimeFormatOptions = {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: 'numeric',
        minute: '2-digit',
        hour12: true,
        timeZone: 'America/Chicago',
      };
      return formattedDate.toLocaleString('en-US', options);
    } else {
      return '-';
    }
  }
}
