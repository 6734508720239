import { mapEnumToOptions } from '@abp/ng.core';

export enum UploadedFileStatus {
  New = 'New',
  Processing = 'Processing',
  SortingCompleted = 'SortingCompleted',
  ExtractionCompleted = 'ExtractionCompleted',
  Completed = 'Completed',
  SortingCompletedAndExtractionInprogress = 'SortingCompletedAndExtractionInprogress',
  ExtractionCompletedAndSortingInprogress = 'ExtractionCompletedAndSortingInprogress',
  Approved = 'Approved',
  Rejected = 'Rejected',
  Failed = 'Failed',
  Processed = 'Processed'
}

export const uploadedFileStatusOptions = mapEnumToOptions(UploadedFileStatus);
