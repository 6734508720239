import type { CreateUpdateMaskSizeDTO, MaskSizeDTO } from './dto/models';
import { RestService } from '@abp/ng.core';
import type { PagedAndSortedResultRequestDto, PagedResultDto } from '@abp/ng.core';
import { Injectable } from '@angular/core';
import { ActiveStatus } from 'projects/admin/src/app/admin-proxy/platform-app-management/rcm/platform-management/localization/enumeration/active-status.enum';

@Injectable({
  providedIn: 'root',
})
export class MaskSizeService {
  apiName = 'orderManagement';

  create = (input: CreateUpdateMaskSizeDTO) =>
    this.restService.request<any, MaskSizeDTO>({
      method: 'POST',
      url: '/api/orderApp/mask-size',
      body: input,
    },
    { apiName: this.apiName });

  delete = (id: string) =>
    this.restService.request<any, void>({
      method: 'DELETE',
      url: `/api/orderApp/mask-size/${id}`,
    },
    { apiName: this.apiName });

  get = (id: string) =>
    this.restService.request<any, MaskSizeDTO>({
      method: 'GET',
      url: `/api/orderApp/mask-size/${id}`,
    },
    { apiName: this.apiName });

  getActiveList = (input: PagedAndSortedResultRequestDto) =>
    this.restService.request<any, PagedResultDto<MaskSizeDTO>>({
      method: 'GET',
      url: '/api/orderApp/mask-size/active-list',
      params: { sorting: input.sorting, skipCount: input.skipCount, maxResultCount: input.maxResultCount },
    },
    { apiName: this.apiName });

  getList = (input: PagedAndSortedResultRequestDto) =>
    this.restService.request<any, PagedResultDto<MaskSizeDTO>>({
      method: 'GET',
      url: '/api/orderApp/mask-size',
      params: { sorting: input.sorting, skipCount: input.skipCount, maxResultCount: input.maxResultCount },
    },
    { apiName: this.apiName });
    getMaskDetailsBystatusByStatusFilter = (statusFilter: ActiveStatus) =>
      this.restService.request<any, PagedResultDto<MaskSizeDTO>>({
        method: 'GET',
        url: '/api/orderApp/mask-size/get-mask-details-bystatus',
        params: { statusFilter },
      },
      { apiName: this.apiName });

      updateMaskStatusByIdAndStatus = (Id: string, status: boolean) =>
        this.restService.request<any, void>({
          method: 'PUT',
          url: `/api/orderApp/mask-size/update-mask-status/${Id}`,
          params: { status },
        },
        { apiName: this.apiName });
        
  update = (id: string, input: CreateUpdateMaskSizeDTO) =>
    this.restService.request<any, MaskSizeDTO>({
      method: 'PUT',
      url: `/api/orderApp/mask-size/${id}`,
      body: input,
    },
    { apiName: this.apiName });

  constructor(private restService: RestService) {}
}
