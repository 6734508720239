<!-- Billing reports Design  -->



<div class="container-fluid">
  <div class="row">
    <div class="col-12">
      <div class="d-flex align-items-center justify-content-between">
        <h3 class="mb-0 font-size-18 customThemeClass mt-2"><b>MIS Report / Billing Report</b></h3>
        <!-- <div class="page-title-box page-title-right pt-2 pb-0">
          <ol class="breadcrumb m-0">
            <li class="ml-2 mt-2 breadcrumb-item">MIS Report</li>
            <li class="mt-2 breadcrumb-item muted">Billing Report</li>
          </ol>
        </div> -->
      </div>
    </div>
    <div class="col-12 mt-3">
      <div class="card card-body">
        <dx-load-panel #loadPanel shadingColor="rgba(0,0,0,0.4)" [position]="{ of: '#employee' }"
          [(visible)]="loadingVisible" [showIndicator]="true" [showPane]="true" [shading]="true">
        </dx-load-panel>
        <ng-container *ngIf="!loadingVisible">
          <dx-data-grid id="gridContainer" #dataGrid [dataSource]="dataSource" [remoteOperations]="false"
            [allowColumnReordering]="true" [rowAlternationEnabled]="true" [showBorders]="true" [columnAutoWidth]="true"
            [columnResizingMode]="columnResizingMode" [allowColumnResizing]="true"
            (onContentReady)="contentReady($event)">
            <dxo-paging [pageSize]="15"></dxo-paging>
            <dxo-state-storing [enabled]="true" type="localStorage" storageKey="storage"></dxo-state-storing>
            <dxo-pager [showPageSizeSelector]="true" [allowedPageSizes]="allowedPageSizes" [showInfo]="showInfo"
              [visible]="true" [displayMode]="'full'" [showNavigationButtons]="true"></dxo-pager>
            <dxo-search-panel [visible]="true" [highlightCaseSensitive]="true"></dxo-search-panel>
            <dxo-group-panel [visible]="true"></dxo-group-panel>
            <dxo-grouping [autoExpandAll]="false"></dxo-grouping>
            <dxo-filter-row [visible]="showFilterRow" [applyFilter]="currentFilter"></dxo-filter-row>
            <dxo-filter-builder [customOperations]="customOperations" [allowHierarchicalFields]="true">
            </dxo-filter-builder>
            <dxo-filter-builder-popup [position]="popupPosition"> </dxo-filter-builder-popup>

            <dxo-filter-panel [visible]="true"></dxo-filter-panel>
            <ng-container *ngFor="let column of tableColumns">
              <dxi-column caption="{{column?.caption}}" format="{{column?.format}}"
                [allowGrouping]="column?.allowGrouping" cssClass="{{column?.cssClass}}"
                alignment="{{column?.alignment}}" cellTemplate="{{column?.cellTemplate}}"
                dataField="{{column?.dataField}}" dataType="{{column?.dataType}}">
              </dxi-column>
            </ng-container>
            <dxo-column-chooser #columnChooser [enabled]="true" [allowSearch]="allowSearch" [mode]="'select'">
            </dxo-column-chooser>
            <dxo-export [enabled]="true" [formats]="['']" [allowExportSelectedData]="true"></dxo-export>
            <div *dxTemplate="let d of 'defaultID&GUIDCellTemplate'">
              <div *ngIf="d?.column?.caption==='Patient Id'" class="current-value">
                <a matTooltip="Click to View" class="eyeCursorclass"
                  [routerLink]="'/patientCreateEditTab/' + d?.data['patientId']" target="_blank">
                  {{d?.data["defaultPatientId"] }}
                </a>
              </div>
              <div *ngIf="d?.column?.caption==='Claim Id'" class="current-value">
                <a matTooltip="Click to View" class="eyeCursorclass"
                  [routerLink]="'/AddNewPosting/' + d?.data['claimId']" target="_blank">
                  {{d?.data["defaultClaimId"] }}
                </a>
              </div>
              <div *ngIf="d?.column?.caption==='SaleOrder Id'" class="current-value">
                <a matTooltip="Click to View" class="eyeCursorclass"
                  [routerLink]="'/orders/saleorderCreateEditTab/' + d?.data['patientId'] + '/' + d?.data['orderId']"
                  target="_blank">
                  {{d?.data["defaultSaleorderId"] }}
                </a>
              </div>
            </div>
            <div *dxTemplate="let d of 'priceCellTemplate'">
              <div *ngIf="d?.column?.caption==='Claim Total Price'" class="current-value">
                <div>
                  {{d?.data["billedAmount"] | currency: "USD":"symbol"}}
                </div>
              </div>
              <div *ngIf="d?.column?.caption==='Claim Allow Price'" class="current-value">
                <div>
                  {{d?.data["allowedAmount"] | currency: "USD":"symbol"}}
                </div>
              </div>
              <div *ngIf="d?.column?.caption==='Claim Balance'" class="current-value">
                <div>
                  {{d?.data["currentBalance"] | currency: "USD":"symbol"}}
                </div>
              </div>
              <div *ngIf="d?.column?.caption==='Check Amount'" class="current-value">
                <div>
                  {{d?.data["checkAmount"] | currency: "USD":"symbol"}}
                </div>
              </div>
              <div *ngIf="d?.column?.caption==='Claim Paid'" class="current-value">
                <div>
                  {{d?.data["insurancePaid"] | currency: "USD":"symbol"}}
                </div>
              </div>

              <div *ngIf="d?.column?.caption==='Adjustment'" class="current-value">
                <div>
                  {{d?.data["adjustment"] | currency: "USD":"symbol"}}
                </div>
              </div>

            </div>
            <div *dxTemplate="let d of 'claimStatusCellTemplate'">
              <div [ngSwitch]="d?.data['claimStatus']">
                <div *ngSwitchCase="'all'">ALL</div>
                <div *ngSwitchCase="'claimProcessed'">Claim Processed</div>
                <div *ngSwitchCase="'claimDenied'">Claim Denied</div>
                <div *ngSwitchCase="'readyToProcess'">Ready To Process</div>
                <div *ngSwitchCase="'claimCompleted'">Claim Completed</div>
                <div *ngSwitchCase="'postingCompleted'">Posting Completed</div>
                <div *ngSwitchCase="'primaryPostingPending'">Primary Posting Pending</div>
                <div *ngSwitchCase="'secondaryPostingPending'">Secondary Posting Pending</div>
                <div *ngSwitchCase="'tertiaryPostingPending'">Tertiary Posting Pending</div>
                <div *ngSwitchCase="'eobPending'">EOB Pending</div>
                <div *ngSwitchDefault></div>
              </div>
            </div>
          </dx-data-grid>
        </ng-container>
      </div>
    </div>
  </div>
</div>