import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { DataTableDirective } from 'angular-datatables';
import { ToastrService } from 'ngx-toastr';
import { InboundDocumentService } from 'projects/admin/src/app/admin-proxy/platform-app-management/rcm/platform-management/inbound/inbound-document.service';
import { Subscription } from 'rxjs';
import { defaultGuid } from '../medicare-check-list/medicare-check-list.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-tat-practice-management',
  templateUrl: './tat-practice-management.component.html',
  styleUrls: ['./tat-practice-management.component.scss']
})
export class TatPracticeManagementComponent implements OnInit {

  dtPracticeTATOption:any = {
    responsive: true,
    paging: false,
    dom: 'Bfrtip',
    language: {
      emptyTable: 'No records',
      info: 'Total : _MAX_ records',
      infoEmpty: 'Total : _MAX_ records',
      infoFiltered: '(filtered : _TOTAL_ records)',
      loadingRecords: 'Loading...',
      zeroRecords: 'No matching records',
    },
    scrollCollapse: true,
    scrollY: '450px',
    scrollX: true,
    fixedHeader: true,
    columnDefs: [
      { targets: [0, 1], orderable: false },
      {
        targets: [5,6],
        type: 'date',
        render: function (data, type, row) {
          // Assuming data is in the format MM/DD/YYYY
          if (type === 'sort' || type === 'type') {
            var dateParts = data.split('/');
            return new Date(dateParts[2], dateParts[0] - 1, dateParts[1]);
          } else {
            return data;
          }
        },
      },
    ],
    search: {
      smart: false,
    },
    buttons: [
      {
        extend: 'excel',
        text:'Export',
        filename: 'Practice Report - TAT',
        title: 'Qsecure | Practice Report TAT',
        exportOptions: {
          columns: ':visible:not(:nth-child(1))',
        },
      },
      {
        extend: 'colvis',
        columns: ':not(:eq(0), :eq(1), :eq(2), :eq(-1))',
      }
    ],
  };

  inboundTableData: any; //Inbound Table Data Variable
  @Input() tableData: any;
  @Output() reloadTableEvent = new EventEmitter(); 
  myTable: DataTables.Api;
  @ViewChild(DataTableDirective, { static: false })
  datatableElement: DataTableDirective;
  $subscription: Subscription[] = [];
  tblSelectedFaxId:string;
  isLoading:boolean=false;
  constructor(  
    private InboundDocService: InboundDocumentService,
    private toaster:ToastrService,
    private snackBar: MatSnackBar,
    private datepipe: DatePipe,
    ) { }

  ngOnInit(): void {
    this.inboundTableData = this.tableData;
    this.dtPracticeTATOption.buttons[0].filename =
    'Practice Report - TAT' + '-' + this.datepipe.transform(new Date(), 'MM-dd-yyyy');
  }

  ngAfterViewInit(): void {
    this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.columns().every(function () {
        const that = this;
        $('input', this.footer()).on('keyup change', function () {
          if (that.search() !== this['value']) {
            that.search(this['value']).draw();
          }
        });
      });
    });
  }

  // isStatUpdate(docId: string) {
  //   const isStatUpdate = this.InboundDocService.STATupdate(docId).subscribe(
  //     (value) => {
  //       this.toaster.success('STAT has been updated successfully', 'Success');
  //       // this.reloadParentPage();
  //     },
  //     (err) => {
  //       this.toaster.success('STAT has been updated successfully', 'Success');
  //       // this.reloadParentPage();
  //     }
  //   );
  //   this.$subscription.push(isStatUpdate);
  // }

  isStatUpdate(event: any, data: any): void {
    this.isLoading = true;
    const isStatUpdate = this.InboundDocService.STATupdate(
      data.docId
    ).subscribe(
      (response) => {
        this.toaster.success('STAT has been updated successfully', 'Success');
        data.successfulStatUpdate = event.checked ? true : false;
        this.isLoading = false;
      },
      (err) => {
        this.isLoading = false;
        console.error('STAT update failed:', err);
        this.toaster.error('STAT update has failed', 'Error');
        this.reloadTableEvent.emit();
      }
    );
    // Unsubscribe from the observable to prevent memory leaks
    this.$subscription.push(isStatUpdate);
  }

  getValueColor(value: number): string {
    if (value == 2) {
      return 'red';
    } else if (value == 1) {
      return 'green';
    }
  }

    //Row Click Event UnProcessed Table
    handleTableCellClick(e: Event, notesData: any): void {
      const target = e.target as HTMLElement;
      const tr = target.closest('tr');
      this.getDataTableData(tr, notesData);
    }
    //DataTable Data Get Method and Toggle Notes Row in UnProcessed Table
    async getDataTableData(tr: any, notesData: any): Promise<void> {
      this.myTable = await this.datatableElement.dtInstance;
  
      if (tr) {
        const row = this.myTable.row(tr);
  
        if (row.child.isShown()) {
          // This row is already open - close it
          row.child.hide();
        } else {
          // Open this row
          row.child(this.format(row.data(), notesData)).show();
        }
      }
    }
    format(d: any, notesData: any): string {
      let returnString: string = '';
  
      if (Array.isArray(notesData)) {
        notesData.forEach(function (value) {
          // returnString += '<tr class="border-bottom" style="white-space: pre-wrap !important;"><td>' + value + '</td></tr>';
          returnString +=
          '<tr class="border-bottom " style="white-space: pre-wrap !important;"><td style="border: 1px solid rgba(0, 0, 0, 0.2); width: 100%">' +
          value +
          '</td></tr>';
        });
      } else if (typeof notesData === 'string') {
        // returnString += '<tr class="border-bottom" style="white-space: pre-wrap !important;"><td>' + notesData + '</td></tr>';
        returnString +=
        '<tr class="border-bottom " style="white-space: pre-wrap !important;"><td style="border: 1px solid rgba(0, 0, 0, 0.2); width: 100%">' +
        notesData +
        '</td></tr>';
      } else {
        console.error('notesData is neither an array nor a string:', notesData);
      }
  
      return returnString;
    }

    fnSelectedRow(id: string, patientId: string) {
      this.tblSelectedFaxId = this.tblSelectedFaxId === id ? defaultGuid : id;
    }

    onRowDoubleClickV1(textToCopy) {
      this.copyTextToClipboard(textToCopy);
      this.showNotification('Text copied: ' + textToCopy);
    }
  
    showNotification(message: string): void {
      this.snackBar.open(message, 'Close', {
        duration: 3000, // Duration in milliseconds
        horizontalPosition: 'end', // Positioning the notification horizontally
        verticalPosition: 'bottom', // Positioning the notification vertically
      });
    }
  
    copyTextToClipboard(text: string): void {
      const textArea = document.createElement('textarea');
      textArea.value = text;
      document.body.appendChild(textArea);
      textArea.select();
      document.execCommand('Copy');
      textArea.remove();
    }

    isEmpty(value: any): boolean {
      return (
        value === null ||
        value === undefined ||
        value === '' ||
        value === defaultGuid
      );
    }
}
