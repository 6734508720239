<mat-dialog-content class="mat-typography">
  <div class="row">
    <div class="col-lg-6">
      <h2>{{authModalTitle }}</h2>
    </div>
  </div>
  <mat-card-content>
    <form [formGroup]="authorizationForm">
      <mat-radio-group *ngIf="isStatusShow" class="rg pt-3" (change)="onChangeGetAuth($event)"
        formControlName="rbtnPolicyType">
        <mat-radio-button [checked]='true' value="Primary">Primary</mat-radio-button>&nbsp;&nbsp;&nbsp;
        <mat-radio-button value="Secondary">Secondary</mat-radio-button>&nbsp;&nbsp;&nbsp;
        <mat-radio-button value="Tertiary">Tertiary</mat-radio-button>
      </mat-radio-group>
      <div class="row">
        <mat-form-field class="col-2">
          <mat-label>Item<span class="asterisk">*</span></mat-label>
          <mat-select  formControlName="txtItem" [(value)]="item" >
            <mat-option *ngFor="let item of drpAuthItem" [value]="item.itemIdOrBarCode+ '/'+ item.name">
              {{item.itemIdOrBarCode+ "/"+ item.name}}
            </mat-option>
          </mat-select>
          <button mat-button (click)="clearItem()" (click)="item=undefined;$event.stopPropagation()" *ngIf="item"
            matSuffix mat-icon-button >
            <mat-icon>close</mat-icon>
          </button>
        </mat-form-field>
        <mat-form-field class="col-2">
          <mat-label> Plan <span class="asterisk">*</span></mat-label>
          <mat-select (selectionChange)="valueChangeLoadDropdown($event)" [(value)]="plan" formControlName="txtPlan"
            >
            <mat-option *ngFor="let policy of drpPlan" [value]="policy?.planName">
              {{policy?.planName}}
            </mat-option>
          </mat-select>
          <button mat-button (click)="clearPlan()" (click)="plan=undefined;$event.stopPropagation()" *ngIf="plan"
            matSuffix mat-icon-button >
            <mat-icon>close</mat-icon>
          </button>
          <mat-error *ngIf="
        authorizationForm?.get('txtPlan')?.touched &&
        authorizationForm?.get('txtPlan')?.errors?.required
        ">
            Plan is a required field!
          </mat-error>
        </mat-form-field>
        <mat-form-field class="col-2">
          <mat-label> Auth ID <span class="asterisk">*</span></mat-label>
          <input autocomplete="new-txtAuthId" matInput formControlName="txtAuthId" maxlength="15" type="text">
          <mat-error *ngIf="
        authorizationForm?.get('txtAuthId')?.touched &&
        authorizationForm?.get('txtAuthId')?.errors?.required
        ">
            Auth ID is a required field!
          </mat-error>
        </mat-form-field>


        <mat-form-field class="col-2">
          <mat-label>Auth Amount </mat-label>
          <input autocomplete="new-txtAuthAmount" matInput minlength="1" maxlength="15" prefix="$" mask="999999999999999" placeHolderCharacter=""
            formControlName="txtAuthAmount" type="text">
          <mat-error *ngIf="
        authorizationForm?.get('txtAuthAmount')?.touched &&
        authorizationForm?.get('txtAuthAmount')?.errors?.required
        ">
            Auth Amount is a required field!
          </mat-error>
          <mat-error *ngIf="
        authorizationForm?.get('txtAuthAmount')?.touched &&
        authorizationForm?.get('txtAuthAmount')?.errors?.number
        ">
            Enter only numbers!
          </mat-error>
        </mat-form-field>
        <mat-form-field class="col-2">
          <mat-label>Start Date<span class="asterisk">*</span></mat-label>
          <input autocomplete="new-txtStartDate" matInput formControlName="txtStartDate" maxlength="40"   (dateInput)="dateChangeEvent($event)"  placeholder="(MM/DD/YYYY)"
            [formControl]="authorizationForm.controls.txtStartDate" [matDatepicker]="myPicker">
          <mat-datepicker-toggle matSuffix [for]="myPicker">
          </mat-datepicker-toggle>
          <mat-datepicker #myPicker></mat-datepicker>
          <mat-error *ngIf="
        authorizationForm?.get('txtStartDate')?.touched && authorizationForm?.get('txtStartDate')?.errors
        ">
            Enter Valid Date!
          </mat-error>
        </mat-form-field>
        <mat-form-field class="col-2">
          <mat-label>End Date<span class="asterisk">*</span></mat-label>
          <input autocomplete="new-txtEndDate" matInput formControlName="txtEndDate" maxlength="40" placeholder="(MM/DD/YYYY)"  (dateInput)="dateChangeEvent($event)"
            [formControl]="authorizationForm.controls.txtEndDate" [min]="authorizationForm.controls.txtStartDate.value"
            [matDatepicker]="myPicker1">
          <mat-datepicker-toggle matSuffix [for]="myPicker1">
          </mat-datepicker-toggle>
          <mat-datepicker #myPicker1></mat-datepicker>
          <mat-error *ngIf="
        authorizationForm?.get('txtEndDate')?.touched && authorizationForm?.get('txtEndDate')?.errors
        ">
            Enter Valid Date!
          </mat-error>
        </mat-form-field>
        <mat-form-field class="col-2">
          <mat-label>Days</mat-label>
          <input autocomplete="new-txtDaysDiff" matInput formControlName="txtDaysDiff" maxlength="15" type="text" />

        </mat-form-field>

        <mat-form-field class="col-2">
          <mat-label>Note </mat-label>
          <textarea rows="1" maxlength="2000" [(ngModel)]="notesText"
          (input)="updateCharacterCount()" matInput formControlName="txtNote"></textarea>  <div class="char-count">
            {{ notesText?.length }} / 5000  
          </div>
        </mat-form-field>
        <mat-form-field class="col-2">
          <mat-label> Units</mat-label>
          <input autocomplete="new-txtUnits" matInput formControlName="txtUnits" maxlength="15" type="text" />
        </mat-form-field>
        <mat-form-field class="col-2">
          <mat-label> Used </mat-label>
          <input autocomplete="new-txtUsed" matInput formControlName="txtUsed" maxlength="15" type="text" />
        </mat-form-field>
        <mat-form-field class="col-2">
          <mat-label>Sale/Rent</mat-label>
          <mat-select maxlength="40" [(value)]="sale" formControlName="txtSaleOrRent">
            <mat-option *ngFor="let data of drpSaleOrRents" [value]="data.saleOrRent">
              {{ data.saleOrRent }}
            </mat-option>
          </mat-select>
          <button mat-button (click)="sale = undefined; $event.stopPropagation()" *ngIf="sale" matSuffix
            (click)="clearSale()" mat-icon-button >
            <mat-icon>close</mat-icon>
          </button>
        </mat-form-field>
        <mat-form-field *ngIf="Show" class="col-2">
          <mat-label>Reason</mat-label>
          <input autocomplete="new-txtReason" matInput maxlength="100" formControlName="txtReason" type="text">
        </mat-form-field>
      </div>

    </form>
  </mat-card-content>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <div *ngIf="!isStatusShow">
    <button  mat-button class="buttonColor" (click)="saveAuthorization()"
      [disabled]=" saveButtonHide || !(!authorizationForm.invalid && (authorizationForm.dirty ))">Save
      <mat-icon *ngIf='isShowSpinner'>
        <mat-spinner class="spinner-border spinner-border-sm" diameter="20"> </mat-spinner>
      </mat-icon>
    </button>
    <button mat-button class="resetclr" (click)="clearData()">Reset</button>
    <button class="buttonCancelColor" mat-button mat-dialog-close>Close</button>
  </div>
</mat-dialog-actions>
