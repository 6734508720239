<div>
<!-- <mat-dialog-content class="mat-typography"> -->
  <!-- <div class="row">
    <div class="col-lg-6">
      <h2>{{doctorModalTitle}}</h2>
    </div>
    <div class="col-lg-6 text-right">
      <button class="text-right close" aria-label="Close" mat-dialog-close><b> X </b></button>
    </div>
  </div> -->

  <div class="modal-header" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle
  style="cursor:move">
    <h1 class="modal-title"><b>{{doctorModalTitle}}</b></h1>
    <i aria-label="Close" mat-dialog-close class="fa fa-times"></i>
  </div>
  <!--
  <hr /> -->
  <app-patient-npi-doctor [sesPracticeID]="sesPracticeID"></app-patient-npi-doctor>
  <!-- <ng-container>
     <div class="d-flex">
    <ul ngbNav #nav="ngbNav" justify="justified" class="nav nav-pills nav-fill" (navChange)="beforeChange($event)">
      <li id="tab-1" ngbNavItem="Verify with NPI">
        <a ngbNavLink>Verify with NPI</a>
        <ng-template ngbNavContent>
          <app-patient-npi-doctor></app-patient-npi-doctor>
        </ng-template>
      </li> -->
  <!-- <li id="tab-2" ngbNavItem="Verify without NPI">
        <a ngbNavLink>Verify without NPI</a>
        <br>
        <ng-template ngbNavContent>
          <br />
          <div class="card card-body">
          <div class="list-box">


          <form [formGroup]="doctorForm">
            <div class="row">
              <div class="col-lg-6">
                <div class="row">
                  <div class="col-md-6">
                    <h4 class="customThemeClass"><b>General</b></h4>
                  </div>
                </div>
                <mat-card-content>
                  <div class="row">
                    <mat-form-field class="col-3">
                      <mat-label>Title</mat-label>
                      <input matInput formControlName="txtGeneraltitle" type="text" maxlength="40" />
                      <mat-error
                        *ngIf="doctorForm?.get('txtGeneraltitle')?.touched && doctorForm?.get('txtGeneraltitle')?.errors?.sAlphabets">
                        Enter only Alphabets!
                      </mat-error>
                    </mat-form-field>

                    <mat-form-field class="col-3">
                      <mat-label>First Name <span class="asterisk">*</span> </mat-label>
                      <input matInput formControlName="txtGeneralFirstName" maxlength="40" type="text" />
                      <mat-error
                        *ngIf="doctorForm?.get('txtGeneralFirstName')?.touched && doctorForm?.get('txtGeneralFirstName')?.errors?.required">
                        First Name is a required field!
                      </mat-error>
                      <mat-error
                        *ngIf="doctorForm?.get('txtGeneralFirstName')?.touched && doctorForm?.get('txtGeneralFirstName')?.errors?.sAlphabets">
                        Enter only Alphabets!
                      </mat-error>
                    </mat-form-field>
                    <mat-form-field class="col-3">
                      <mat-label>Middle Name</mat-label>
                      <input matInput formControlName="txtGeneralMiddleName" maxlength="40" type="text" />
                      <mat-error
                        *ngIf="doctorForm?.get('txtGeneralMiddleName')?.touched && doctorForm?.get('txtGeneralMiddleName')?.errors?.sAlphabets">
                        Enter only Alphabets!
                      </mat-error>
                    </mat-form-field>
                    <mat-form-field class="col-3">
                      <mat-label>Last Name</mat-label>
                      <input matInput formControlName="txtGeneralLastName" maxlength="40" type="text" />
                      <mat-error
                        *ngIf="doctorForm?.get('txtGeneralLastName')?.touched && doctorForm?.get('txtGeneralLastName')?.errors?.sAlphabets">
                        Enter only Alphabets!
                      </mat-error>
                    </mat-form-field>
                    <mat-form-field class="col-3">
                      <mat-label>Suffix</mat-label>
                      <input matInput formControlName="txtGeneralSuffixName" maxlength="40" type="text" />
                      <mat-error
                        *ngIf="doctorForm?.get('txtGeneralSuffixName')?.touched && doctorForm?.get('txtGeneralSuffixName')?.errors?.sAlphabets">
                        Enter only Alphabets!
                      </mat-error>
                    </mat-form-field>
                    <mat-form-field class="col-3">
                      <mat-label>Doctor Group</mat-label>
                      <mat-select formControlName="drpGeneralDoctorGroup" [(value)]="doctorGroup"
                        (click)="clearDoctorGroup()" maxlength="40">
                        <mat-option *ngFor="let group of drpDoctorgroup" [value]="group.doctorGroupShortCodeId">
                          {{ group.doctorGroupName }}
                        </mat-option>
                      </mat-select>
                      <button mat-button (click)="doctorGroup='';$event.stopPropagation()" *ngIf="doctorGroup"
                        matSuffix mat-icon-button >
                        <mat-icon>close</mat-icon>
                      </button>
                    </mat-form-field>
                    <mat-form-field class="col-3">
                      <mat-label>Facility</mat-label>
                      <mat-select formControlName="drpGeneralFacility" [(value)]="facility" (click)="clearFacility()"
                        maxlength="40">
                        <mat-option *ngFor="let facility of drpFacility" [value]="facility.facilityShortCodeId">
                          {{ facility.facilityName }}
                        </mat-option>
                      </mat-select>
                      <button mat-button (click)="facility='';$event.stopPropagation()" *ngIf="facility"
                        matSuffix mat-icon-button >
                        <mat-icon>close</mat-icon>
                      </button>
                    </mat-form-field>

                    <mat-form-field class="col-3">
                      <mat-label>Marketing Rep</mat-label>
                      <mat-select formControlName="txtMarketingRep" [(value)]="marketingRep" (click)="clearMarketingRep()" maxlength="40">
                        <mat-option *ngFor="let marketing of drpMarketingtypeLoop"
                          [value]="marketing.marketingReferralTypeId">
                          {{ marketing.marketingReferralTypeName }}
                        </mat-option>
                      </mat-select>
                      <button mat-button (click)="marketingRep='';$event.stopPropagation()" *ngIf="marketingRep" matSuffix
                      mat-icon-button >
                      <mat-icon>close</mat-icon>
                    </button>
                    </mat-form-field>
                  </div>
                </mat-card-content>
              </div>
              <div class="col-lg-6">
                <div class="row">
                  <div class="col-md-6">
                    <h4 class="customThemeClass"><b>Contact Information</b></h4>
                  </div>
                </div>
                <mat-card-content>
                  <div class="row">
                    <mat-form-field class="col-3">
                      <mat-label>Phone</mat-label>
                      <input matInput formControlName="txtContactInfoPhone" prefix="+1-" mask="(000)-000-0000"
                        maxlength="40" type="text" />
                      <mat-error
                        *ngIf="doctorForm?.get('txtContactInfoPhone')?.touched && doctorForm?.get('txtContactInfoPhone')?.errors">
                        Enter Minimum 10 Digits!
                      </mat-error>
                    </mat-form-field>
                    <mat-form-field class="col-3">
                      <mat-label>Fax</mat-label>
                      <input matInput formControlName="txtContactInfoFax" type="text" prefix="+1-" mask="(000)-000-0000"
                        maxlength="40" />
                      <mat-error
                        *ngIf="doctorForm?.get('txtContactInfoFax')?.touched && doctorForm?.get('txtContactInfoFax')?.errors">
                        Enter Minimum 10 Digits!
                      </mat-error>
                    </mat-form-field>
                    <mat-form-field class="col-3">
                      <mat-label>Mobile</mat-label>
                      <input matInput formControlName="txtContactInfoMobile" prefix="+1-" mask="(000)-000-0000"
                        maxlength="40" type="text" />
                      <mat-error
                        *ngIf="doctorForm?.get('txtContactInfoMobile')?.touched && doctorForm?.get('txtContactInfoMobile')?.errors">
                        Enter Minimum 10 Digits!
                      </mat-error>
                    </mat-form-field>
                    <mat-form-field class="col-3">
                      <mat-label>Email</mat-label>
                      <input matInput formControlName="txtContactInfoEmail" [textMask]="{ mask: emailMask }"
                        pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$" maxlength="40" type="text" />
                      <mat-error
                        *ngIf="doctorForm?.get('txtContactInfoEmail')?.touched && doctorForm?.get('txtContactInfoEmail')?.errors?.email">
                        This is not a valid Email!!!
                      </mat-error>
                    </mat-form-field>
                    <mat-form-field class="col-3">
                      <mat-label>Fax Attention</mat-label>
                      <input matInput formControlName="txtContactInfoFaxAttention" maxlength="40" type="text" />
                    </mat-form-field>
                    <div class="col-3">
                      <br />
                      <mat-checkbox formControlName="chkElectronic">Exclude From Electronic Fax</mat-checkbox>
                    </div>
                  </div>
                </mat-card-content>
              </div>
            </div>
            <br />
            <div class="row">
              <div class="col-lg-6">
                <div class="row">
                  <div class="col-md-6">
                    <h4 class="customThemeClass"><b>Address</b></h4>
                  </div>
                </div>
                <mat-card-content>
                  <div class="row">
                    <mat-form-field class="col-6">
                      <mat-label>Address</mat-label>
                      <textarea formControlName="txtAddress" rows="5" maxlength="100" matInput></textarea>
                    </mat-form-field>
                    <ng-select class="col-4" (change)="onChangeState($event)" formControlName="txtCountry"
                      placeholder="Country" maxlength="40">
                      <ng-option *ngFor="let country of drpCountry" [value]="country.countryName">
                        {{ country.countryName }}</ng-option>
                    </ng-select>
                  </div>
                  <div class="row">
                    <mat-form-field class="col-4">
                      <mat-label>State</mat-label>
                      <mat-select formControlName="txtState" maxlength="40">
                        <mat-option *ngFor="let state of drpState" [value]="state.stateId">
                          {{ state.stateType }}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                    <mat-form-field class="col-4">
                      <mat-label>Country</mat-label>
                      <mat-select formControlName="txtCountry" maxlength="40">
                        <mat-option *ngFor="let country of countries" [value]="country.countryTypeId">
                          {{ country.countryType }}
                        </mat-option>
                      </mat-select>
                    </mat-form-field> -->
  <!-- <ng-select class="col-4" (change)="onChangecity($event)" formControlName="txtState"
                    placeholder="State" maxlength="40">
                    <ng-option *ngFor="let state of drpStates" [value]="state.stateName">
                      {{ state.stateName }}</ng-option>
                  </ng-select>
                  <ng-select class="col-4" formControlName="txtCity" placeholder="City" maxlength="40">
                    <ng-option *ngFor="let city of drpCities" [value]="city.cityName">
                      {{ city.cityName }}</ng-option>
                  </ng-select>
                    <mat-form-field class="col-4">
                      <mat-label>Postal Code</mat-label>
                      <input matInput formControlName="txtPostalCode" (keypress)="numberAndAlphaOnly($event)" type="text" maxlength="40" />
                    </mat-form-field>
                  </div>
                </mat-card-content>
              </div>
              <div class="col-lg-6">
                <div class="row">
                  <div class="col-md-6">
                    <h4 class="customThemeClass"><b>Medical Information</b></h4>
                  </div>
                </div>
                <mat-card-content>
                  <div class="row">
                    <mat-form-field class="col-3">
                      <mat-label>License No.</mat-label>
                      <input matInput formControlName="txtMedicalLicenseNo" maxlength="12" type="text" /> -->
  <!-- <mat-error
                          *ngIf="doctorForm?.get('txtMedicalLicenseNo')?.touched && doctorForm?.get('txtMedicalLicenseNo')?.errors?.number
                          "
                        >
                          Enter only Numbers!
                        </mat-error> -->
  <!-- </mat-form-field>
                    <mat-form-field class="col-3">
                      <mat-label>Expiration</mat-label>
                      <input matInput formControlName="txtExpiration" type="text" maxlength="12" />
                    </mat-form-field>
                    <mat-form-field class="col-3">
                      <mat-label>DEA Number</mat-label>
                      <input matInput formControlName="txtMedicalInfoDEANumber" maxlength="12" type="text" />
                      <mat-error *ngIf="doctorForm?.get('txtMedicalInfoDEANumber')?.touched && doctorForm?.get('txtMedicalInfoDEANumber')?.errors?.number
                          ">
                        Enter only Numbers!
                      </mat-error>
                    </mat-form-field>
                    <mat-form-field class="col-3">
                      <mat-label>UPIN</mat-label>
                      <input matInput formControlName="txtMedicalInfoUPIN" maxlength="12" type="text" />
                      <mat-error *ngIf="doctorForm?.get('txtMedicalInfoUPIN')?.touched && doctorForm?.get('txtMedicalInfoUPIN')?.errors?.number
                          ">
                        Enter only Numbers!
                      </mat-error>
                    </mat-form-field>
                    <mat-form-field class="col-3">
                      <mat-label>State Medicaid ID</mat-label>
                      <input matInput formControlName="txtMedicalInfoStateMedicaidID" maxlength="12" type="text" />
                      <mat-error *ngIf="doctorForm?.get('txtMedicalInfoStateMedicaidID')?.touched && doctorForm?.get('txtMedicalInfoStateMedicaidID')?.errors?.number
                          ">
                        Enter only Numbers!
                      </mat-error>
                    </mat-form-field>
                    <mat-form-field class="col-3">
                      <mat-label>NPI <span class="asterisk">*</span></mat-label>
                      <input matInput formControlName="txtNPITextBox" minlength="10" maxlength="10" type="text" />
                      <mat-error
                        *ngIf="doctorForm?.get('txtNPITextBox')?.touched && doctorForm?.get('txtNPITextBox')?.errors?.required">
                        NPI is a required field!
                      </mat-error>
                      <mat-error *ngIf="doctorForm?.get('txtNPITextBox')?.touched && doctorForm?.get('txtNPITextBox')?.errors?.minlength
                          ">
                        Enter Minimum 10 Digits!
                      </mat-error>
                      <mat-error *ngIf="doctorForm?.get('txtNPITextBox')?.touched && doctorForm?.get('txtNPITextBox')?.errors?.number
                          ">
                        Enter only Numbers!
                      </mat-error>
                      <mat-error> </mat-error>
                    </mat-form-field>
                    <mat-form-field class="col-3">
                      <mat-label>Taxonomy Code</mat-label>
                      <input matInput formControlName="txtTaxonomy" type="text" maxlength="12" />
                    </mat-form-field>
                    <div class="col-3">
                      <br />
                      <mat-checkbox formControlName="chkPCOS">PECOS Registered </mat-checkbox>
                    </div>
                  </div>
                </mat-card-content>
              </div>
            </div>
            <br />
            <div class="row">
              <div class="col-lg-6">
                <div class="row">
                  <div class="col-md-6">
                    <h4 class="customThemeClass"><b>Note</b></h4>
                  </div>

                </div>
                <mat-card-content>
                  <div class="row">
                    <mat-form-field class="col-12">
                      <mat-label>Note</mat-label>
                      <input matInput formControlName="txtDoctorNotes" type="text" maxlength="400" />
                    </mat-form-field>
                  </div>
                </mat-card-content>
              </div>
              <div class="col-lg-6">
                <div class="row">
                  <div class="col-md-6">
                    <h4 class="customThemeClass"><b>User Data</b></h4>
                  </div> -->

  <!-- </div>
                <mat-card-content>
                  <div class="row">
                    <mat-form-field class="col-6">
                      <mat-label>User 1</mat-label>
                      <input matInput formControlName="txtUser1" type="text" maxlength="40" />
                    </mat-form-field>
                    <mat-form-field class="col-6">
                      <mat-label>User 2</mat-label>
                      <input matInput formControlName="txtUser2" type="text" maxlength="40" />
                    </mat-form-field>
                  </div>
                </mat-card-content>
              </div>
            </div>

            <div class="row">
              <div class="col-lg-12 text-center"> -->
  <!-- <button mat-button (click)="saveDoctor()" [disabled]="!(!doctorForm.invalid && (doctorForm.dirty ))"
                  class="buttonColor">
                  Save
                </button> -->
  <!-- </div>
            </div> -->
  <!-- *ngIf="!isHideSaveButton" -->

  <!-- </form>
        </ng-template>
      </li>  -->

  <!-- </ul>

    <div [ngbNavOutlet]="nav"></div> -->
  <!-- </div> -->
  <!-- <ngb-tabset
      justify="justified"
      type="pills"
      (tabChange)="beforeChange($event)"
      #ngbTabset="ngbTabset"
    >
      <ngb-tab id="tab-1" title="Verify with NPI" class="">
        <ng-template ngbTabContent class="nav-item">
          <ng-template [ngTemplateOutlet]="VerifyWithNPI"> </ng-template>
        </ng-template>
      </ngb-tab>
      <ngb-tab id="tab-2" title="Verify without NPI" class="">
        <ng-template ngbTabContent class="nav-item">
          <ng-template [ngTemplateOutlet]="VerifyWithoutNPI"> </ng-template>
        </ng-template>
      </ngb-tab>
    </ngb-tabset> -->
  <!-- </ng-container> -->
  <!-- <ng-template #VerifyWithNPI>
    <app-patient-npi-doctor></app-patient-npi-doctor>
  </ng-template>
  <ng-template #VerifyWithoutNPI>
    <form [formGroup]="doctorForm">
      <div class="row">
        <div class="col-lg-6">
          <div class="row">
            <div class="col-md-6">
              <h4 class="customThemeClass"><b>General</b></h4>
            </div>
          </div>
          <mat-card-content>
            <div class="row">
              <mat-form-field class="col-3">
                <mat-label>Title</mat-label>
                <input matInput formControlName="txtGeneraltitle" type="text" maxlength="40" />
                <mat-error
                  *ngIf="                    doctorForm?.get('txtGeneraltitle')?.touched &&
                    doctorForm?.get('txtGeneraltitle')?.errors?.sAlphabets
                  "
                >
                Enter only Alphabets!
                </mat-error>
              </mat-form-field>

              <mat-form-field class="col-3">
                <mat-label>First Name <span class="asterisk">*</span> </mat-label>
                <input matInput formControlName="txtGeneralFirstName" maxlength="40" type="text" />
                <mat-error
                  *ngIf="                    doctorForm?.get('txtGeneralFirstName')?.touched &&
                    doctorForm?.get('txtGeneralFirstName')?.errors?.required
                  "
                >
                  First Name is a required field!
                </mat-error>
                <mat-error
                  *ngIf="                    doctorForm?.get('txtGeneralFirstName')?.touched &&
                    doctorForm?.get('txtGeneralFirstName')?.errors?.sAlphabets
                  "
                >
                  Enter only Alphabets!
                </mat-error>
              </mat-form-field>
              <mat-form-field class="col-3">
                <mat-label>Middle Name</mat-label>
                <input matInput formControlName="txtGeneralMiddleName" maxlength="40" type="text" />
                <mat-error
                  *ngIf="                    doctorForm?.get('txtGeneralMiddleName')?.touched &&
                    doctorForm?.get('txtGeneralMiddleName')?.errors?.sAlphabets
                  "
                >
                  Enter only Alphabets!
                </mat-error>
              </mat-form-field>
              <mat-form-field class="col-3">
                <mat-label>Last Name</mat-label>
                <input matInput formControlName="txtGeneralLastName" maxlength="40" type="text" />
                <mat-error
                  *ngIf="                    doctorForm?.get('txtGeneralLastName')?.touched &&
                    doctorForm?.get('txtGeneralLastName')?.errors?.sAlphabets
                  "
                >
                  Enter only Alphabets!
                </mat-error>
              </mat-form-field>
              <mat-form-field class="col-3">
                <mat-label>Suffix</mat-label>
                <input matInput formControlName="txtGeneralSuffixName" maxlength="40" type="text" />
                <mat-error
                  *ngIf="                    doctorForm?.get('txtGeneralSuffixName')?.touched &&
                    doctorForm?.get('txtGeneralSuffixName')?.errors?.sAlphabets
                  "
                >
                  Enter only Alphabets!
                </mat-error>
              </mat-form-field>
              <mat-form-field class="col-3">
                <mat-label>Doctor Group</mat-label>
                <mat-select formControlName="drpGeneralDoctorGroup" maxlength="40">
                  <mat-option *ngFor="let group of drpDoctorgroup" [value]="group.doctorGroupId">
                    {{ group.doctorGroupName }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <mat-form-field class="col-3">
                <mat-label>Facility</mat-label>
                <mat-select formControlName="drpGeneralFacility" maxlength="40">
                  <mat-option *ngFor="let facility of drpFacility" [value]="facility.facilityId">
                    {{ facility.facilityName }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <mat-form-field class="col-3">
                <mat-label>Marketing Rep</mat-label>
                <mat-select formControlName="txtMarketingRep" maxlength="40">
                  <mat-option
                    *ngFor="let marketing of drpMarketingtypeLoop"
                    [value]="marketing.marketingReferralTypeId"
                  >
                    {{ marketing.marketingReferralTypeName }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </mat-card-content>
        </div>
        <div class="col-lg-6">
          <div class="row">
            <div class="col-md-6">
              <h4 class="customThemeClass"><b>Contact Information</b></h4>
            </div>
          </div>
          <mat-card-content>
            <div class="row">
              <mat-form-field class="col-3">
                <mat-label>Phone</mat-label>
                <input
                  matInput
                  formControlName="txtContactInfoPhone"
                  prefix="+1-"
                  mask="(000)-000-0000"
                  maxlength="40"
                  type="text"
                />
                <mat-error
                  *ngIf="                    doctorForm?.get('txtContactInfoPhone')?.touched &&
                    doctorForm?.get('txtContactInfoPhone')?.errors
                  "
                >
                  Enter Minimum 10 Digits!
                </mat-error>
              </mat-form-field>
              <mat-form-field class="col-3">
                <mat-label>Fax</mat-label>
                <input
                  matInput
                  formControlName="txtContactInfoFax"
                  type="text"
                  prefix="+1-"
                  mask="(000)-000-0000"
                  maxlength="40"
                />
                <mat-error
                  *ngIf="                    doctorForm?.get('txtContactInfoFax')?.touched &&
                    doctorForm?.get('txtContactInfoFax')?.errors
                  "
                >
                  Enter Minimum 10 Digits!
                </mat-error>
              </mat-form-field>
              <mat-form-field class="col-3">
                <mat-label>Mobile</mat-label>
                <input
                  matInput
                  formControlName="txtContactInfoMobile"
                  prefix="+1-"
                  mask="(000)-000-0000"
                  maxlength="40"
                  type="text"
                />
                <mat-error
                  *ngIf="                    doctorForm?.get('txtContactInfoMobile')?.touched &&
                    doctorForm?.get('txtContactInfoMobile')?.errors
                  "
                >
                  Enter Minimum 10 Digits!
                </mat-error>
              </mat-form-field>
              <mat-form-field class="col-3">
                <mat-label>Email</mat-label>
                <input
                  matInput
                  formControlName="txtContactInfoEmail"
                  [textMask]="{ mask: emailMask }"
                  pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
                  maxlength="40"
                  type="text"
                />
                <mat-error
                  *ngIf="                    doctorForm?.get('txtContactInfoEmail')?.touched &&
                    doctorForm?.get('txtContactInfoEmail')?.errors?.email
                  "
                >
                  This is not a valid Email!!!
                </mat-error>
              </mat-form-field>
              <mat-form-field class="col-3">
                <mat-label>Fax Attention</mat-label>
                <input
                  matInput
                  formControlName="txtContactInfoFaxAttention"
                  maxlength="40"
                  type="text"
                />
              </mat-form-field>
              <div class="col-3">
                <br />
                <mat-checkbox formControlName="chkElectronic"
                  >Exclude From Electronic Fax</mat-checkbox
                >
              </div>
            </div>
          </mat-card-content>
        </div>
      </div>
      <br />
      <div class="row">
        <div class="col-lg-6">
          <div class="row">
            <div class="col-md-6">
              <h4 class="customThemeClass"><b>Address</b></h4>
            </div>
          </div>
          <mat-card-content>
            <div class="row">
              <mat-form-field class="col-3">
                <mat-label>Address</mat-label>
                <textarea formControlName="txtAddress" rows="1" maxlength="100" matInput></textarea>
              </mat-form-field>
              <mat-form-field class="col-3">
                <mat-label>City</mat-label>
                <mat-select formControlName="txtCity" maxlength="40">
                  <mat-option *ngFor="let city of drpCity" [value]="city.cityId">
                    {{ city.cityType }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <mat-form-field class="col-3">
                <mat-label>State</mat-label>
                <mat-select formControlName="txtState" maxlength="40">
                  <mat-option *ngFor="let state of drpState" [value]="state.stateId">
                    {{ state.stateType }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <mat-form-field class="col-3">
                <mat-label>Country</mat-label>
                <mat-select formControlName="txtCountry" maxlength="40">
                  <mat-option *ngFor="let country of countries" [value]="country.countryTypeId">
                    {{ country.countryType }}
                  </mat-option>
                </mat-select>
              </mat-form-field>

              <mat-form-field class="col-3">
                <mat-label>Postal Code</mat-label>
                <input matInput formControlName="txtPostalCode" type="text" maxlength="40" />
              </mat-form-field>
            </div>
          </mat-card-content>
        </div>
        <div class="col-lg-6">
          <div class="row">
            <div class="col-md-6">
              <h4 class="customThemeClass"><b>Medical Information</b></h4>
            </div>
          </div>
          <mat-card-content>
            <div class="row">
              <mat-form-field class="col-3">
                <mat-label>License No.</mat-label>
                <input matInput formControlName="txtMedicalLicenseNo"  maxlength="12" type="text" />
                <mat-error
                  *ngIf="                    doctorForm?.get('txtMedicalLicenseNo')?.touched &&
                    doctorForm?.get('txtMedicalLicenseNo')?.errors?.number
                  "
                >
                  Enter only Numbers!
                </mat-error> -->
  <!-- </mat-form-field>
              <mat-form-field class="col-3">
                <mat-label>Expiration</mat-label>
                <input matInput formControlName="txtExpiration" type="text"  maxlength="12" />
              </mat-form-field>
              <mat-form-field class="col-3">
                <mat-label>DEA Number</mat-label>
                <input
                  matInput
                  formControlName="txtMedicalInfoDEANumber"
                  maxlength="12"
                  type="text"
                />
                <mat-error
                  *ngIf="                    doctorForm?.get('txtMedicalInfoDEANumber')?.touched &&
                    doctorForm?.get('txtMedicalInfoDEANumber')?.errors?.number
                  "
                >
                  Enter only Numbers!
                </mat-error>
              </mat-form-field>
              <mat-form-field class="col-3">
                <mat-label>UPIN</mat-label>
                <input matInput formControlName="txtMedicalInfoUPIN" maxlength="12" type="text" />
                <mat-error
                  *ngIf="                    doctorForm?.get('txtMedicalInfoUPIN')?.touched &&
                    doctorForm?.get('txtMedicalInfoUPIN')?.errors?.number
                  "
                >
                  Enter only Numbers!
                </mat-error>
              </mat-form-field>
              <mat-form-field class="col-3">
                <mat-label>State Medicaid ID</mat-label>
                <input
                  matInput
                  formControlName="txtMedicalInfoStateMedicaidID"
                  maxlength="12"

                  type="text"
                />
                <mat-error
                  *ngIf="                    doctorForm?.get('txtMedicalInfoStateMedicaidID')?.touched &&
                    doctorForm?.get('txtMedicalInfoStateMedicaidID')?.errors?.number
                  "
                >
                  Enter only Numbers!
                </mat-error>
              </mat-form-field>
              <mat-form-field class="col-3">
                <mat-label>NPI <span class="asterisk">*</span></mat-label>
                <input
                  matInput
                  formControlName="txtNPITextBox"
                  minlength="10"
                  maxlength="10"
                  type="text"
                />
                <mat-error
                  *ngIf="                    doctorForm?.get('txtNPITextBox')?.touched &&
                    doctorForm?.get('txtNPITextBox')?.errors?.required
                  "
                >
                  NPI is a required field!
                </mat-error>
                <mat-error
                  *ngIf="                    doctorForm?.get('txtNPITextBox')?.touched &&
                    doctorForm?.get('txtNPITextBox')?.errors?.minlength
                  "
                >
                  Enter Minimum 10 Digits!
                </mat-error>
                <mat-error
                  *ngIf="                    doctorForm?.get('txtNPITextBox')?.touched &&
                    doctorForm?.get('txtNPITextBox')?.errors?.number
                  "
                >
                  Enter only Numbers!
                </mat-error>
                <mat-error> </mat-error>
              </mat-form-field>
              <mat-form-field class="col-3">
                <mat-label>Taxonomy Code</mat-label>
                <input matInput formControlName="txtTaxonomy" type="text"                  maxlength="12"
                />
              </mat-form-field>
              <div class="col-3">
                <br />
                <mat-checkbox formControlName="chkPCOS">PECOS Registered </mat-checkbox>
              </div>
            </div>
          </mat-card-content>
        </div>
      </div>
      <br />
      <div class="row">
        <div class="col-lg-6">
          <div class="row">
            <div class="col-md-6">
              <h4 class="customThemeClass"><b>Note</b></h4>
            </div>
          </div>
          <mat-card-content>
            <div class="row">
              <mat-form-field class="col-3">
                <mat-label>Note</mat-label>
                <input matInput formControlName="txtDoctorNotes" type="text" maxlength="40" />
              </mat-form-field>
            </div>
          </mat-card-content>
        </div>
        <div class="col-lg-6">
          <div class="row">
            <div class="col-md-6">
              <h4 class="customThemeClass"><b>User Data</b></h4>
            </div>
          </div>
          <mat-card-content>
            <div class="row">
              <mat-form-field class="col-3">
                <mat-label>User 1</mat-label>
                <input matInput formControlName="txtUser1" type="text" maxlength="40" />
              </mat-form-field>
              <mat-form-field class="col-3">
                <mat-label>User 2</mat-label>
                <input matInput formControlName="txtUser2" type="text" maxlength="40" />
              </mat-form-field>
            </div>
          </mat-card-content>
        </div>
      </div>
    </form>
  </ng-template> -->
<!-- </mat-dialog-content> -->
</div>
<!-- <hr /> -->
<!-- <mat-dialog-actions align="end"> -->
<!-- <button mat-button (click)="saveDoctor()" [disabled]="!(!doctorForm.invalid && (doctorForm.dirty ))"
    class="buttonColor">
    Save
  </button> -->
<!-- <button class="buttonCancelColor" mat-button mat-dialog-close>Close</button> -->
<!-- <button mat-button [mat-dialog-close]="true" cdkFocusInitial>Install</button> -->
<!-- </mat-dialog-actions> -->

<!-- <mat-dialog-actions align="end">
  <button
    mat-button
    (click)="saveDoctor()"
    [disabled]="doctorForm.invalid"

    class="buttonColor"
  >
    Save
  </button>
</mat-dialog-actions>  -->
<!-- *ngIf="!isHideSaveButton" -->
