import type { CreateUpdateProductBrandDetailsDTO, ProductBrandDetailSearchDTO, ProductBrandDetailsDTO } from './dto/models';
import { RestService } from '@abp/ng.core';
import type { PagedAndSortedResultRequestDto, PagedResultDto } from '@abp/ng.core';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ProductBrandDetailsService {
  apiName = 'ItemManagement';

  create = (input: CreateUpdateProductBrandDetailsDTO) =>
    this.restService.request<any, ProductBrandDetailsDTO>({
      method: 'POST',
      url: '/api/ItemApp/product-brand-details',
      body: input,
    },
    { apiName: this.apiName });

  delete = (id: string) =>
    this.restService.request<any, void>({
      method: 'DELETE',
      url: `/api/ItemApp/product-brand-details/${id}`,
    },
    { apiName: this.apiName });

  get = (id: string) =>
    this.restService.request<any, ProductBrandDetailsDTO>({
      method: 'GET',
      url: `/api/ItemApp/product-brand-details/${id}`,
    },
    { apiName: this.apiName });

  getList = (input: PagedAndSortedResultRequestDto) =>
    this.restService.request<any, PagedResultDto<ProductBrandDetailsDTO>>({
      method: 'GET',
      url: '/api/ItemApp/product-brand-details',
      params: { skipCount: input.skipCount, maxResultCount: input.maxResultCount, sorting: input.sorting },
    },
    { apiName: this.apiName });

  update = (id: string, input: CreateUpdateProductBrandDetailsDTO) =>
    this.restService.request<any, ProductBrandDetailsDTO>({
      method: 'PUT',
      url: `/api/ItemApp/product-brand-details/${id}`,
      body: input,
    },
    { apiName: this.apiName });

  searchProductBrandByInput = (input: ProductBrandDetailSearchDTO) =>
    this.restService.request<any, PagedResultDto<ProductBrandDetailsDTO>>({
      method: 'GET',
      url: '/api/ItemApp/product-brand-details/search-product-brand',
      params: { productBrandDetailName: input.productBrandDetailName, status: input.status, createdDate: input.createdDate },
    },
    { apiName: this.apiName });

  constructor(private restService: RestService) {}
}
