import { RestService } from '@abp/ng.core';
import type { PagedAndSortedResultRequestDto, PagedResultDto } from '@abp/ng.core';
import { Injectable } from '@angular/core';
import type { CreateUpdateInsurancePaymentDTO, EOBHistoryDTO, Import835FileDTO, InsuranceItemDetailsDTO, InsurancePaymentDTO, UploadEobBlobDTO } from '../../billing-management/posting/dto/models';

@Injectable({
  providedIn: 'root',
})
export class InsurancePaymentService {
  apiName = 'ClaimProcessingManagement';

  create = (input: CreateUpdateInsurancePaymentDTO) =>
    this.restService.request<any, InsurancePaymentDTO>(
      {
        method: 'POST',
        url: '/api/claimProcessingApp/insurance-payment',
        body: input,
      },
      { apiName: this.apiName }
    );

  delete = (id: string) =>
    this.restService.request<any, void>(
      {
        method: 'DELETE',
        url: `/api/claimProcessingApp/insurance-payment/${id}`,
      },
      { apiName: this.apiName }
    );

  get = (id: string) =>
    this.restService.request<any, InsurancePaymentDTO>(
      {
        method: 'GET',
        url: `/api/claimProcessingApp/insurance-payment/${id}`,
      },
      { apiName: this.apiName }
    );

  getList = (input: PagedAndSortedResultRequestDto) =>
    this.restService.request<any, PagedResultDto<InsurancePaymentDTO>>(
      {
        method: 'GET',
        url: '/api/claimProcessingApp/insurance-payment',
        params: {
          skipCount: input.skipCount,
          maxResultCount: input.maxResultCount,
          sorting: input.sorting,
        },
      },
      { apiName: this.apiName }
    );

  update = (id: string, input: CreateUpdateInsurancePaymentDTO) =>
    this.restService.request<any, InsurancePaymentDTO>(
      {
        method: 'PUT',
        url: `/api/claimProcessingApp/insurance-payment/${id}`,
        body: input,
      },
      { apiName: this.apiName }
    );
  getInsurancePaymentsByClaimId = (claimId: string) =>
    this.restService.request<any, InsuranceItemDetailsDTO[]>(
      {
        method: 'GET',
        url: `/api/claimProcessingApp/insurance-payment/get-insurance-payments/${claimId}`,
      },
      { apiName: this.apiName }
    );

  updateEOBByInsurancePaymentIdAndEob = (input: UploadEobBlobDTO) =>
    this.restService.request<any, any>(
      {
        method: 'PUT',
        url: `/api/claimProcessingApp/insurance-payment/update-eOB`,
        body: input,
      },
      { apiName: this.apiName }
    );

  getInsurancePaymentDetails = (insurancePaymentId: string) =>
    this.restService.request<any, InsurancePaymentDTO>(
      {
        method: 'GET',
        url: `/api/claimProcessingApp/insurance-payment/get-insurance-payment-details/${insurancePaymentId}`,
      },
      { apiName: this.apiName }
    );

  import835FileByClaimIdAndOImport835File = (
    claimId: string,
    oImport835File: Import835FileDTO
  ) =>
    this.restService.request<any, EOBHistoryDTO>(
      {
        method: 'POST',
        url: `/api/claimProcessingApp/insurance-payment/import835File/${claimId}`,
        body: oImport835File,
      },
      { apiName: this.apiName }
    );

  constructor(private restService: RestService) {}
}
