<div class="card card-body">
    <div class="modal-header">
        <h1 class="modal-title"><b>{{physiciantitle}}</b></h1>
        <i aria-label="Close" mat-dialog-close class="fa fa-times"></i>
    </div>

    <form [formGroup]="physicianForm">
        <div class="modal-body">
            <div class="row">
                <div class="col-6 text-left">
                    <h2 class="header"><b>NPI Search</b></h2>
                </div>
                <div class="col-6">
                    <div class="ml-auto">
                        <div class="d-flex justify-content-end align-items-center">
                            <span class="font-size-10 text-right mr-2 mb-4"><b>Medicare</b></span>
                            <app-toggle-switch [onText]="'Yes'" [offText]="'NO'" class="mt-1">
                            </app-toggle-switch>
                        </div>
                    </div>
                </div>

                <!--NPI Number Field-->
                <mat-form-field class="col-2">
                    <mat-label>NPI Number</mat-label>
                    <input autocomplete="new-txtNpiNumber" matInput formControlName="txtNpiNumber" type="text"
                        maxlength="40" />
                </mat-form-field>

                <!--First Name Field-->
                <mat-form-field class="col-3">
                    <mat-label>First Name</mat-label>
                    <input autocomplete="new-txtfirstName" matInput formControlName="txtfirstName" type="text"
                        maxlength="40" />
                </mat-form-field>

                <!--Last Name Field-->
                <mat-form-field class="col-3">
                    <mat-label>Last Name</mat-label>
                    <input autocomplete="new-txtlastName" matInput formControlName="txtlastName" type="text"
                        maxlength="40" />
                </mat-form-field>

                <!--City Name Field-->
                <mat-form-field class="col-2">
                    <mat-label>City</mat-label>
                    <input autocomplete="new-txtCity" matInput formControlName="txtCity" type="text" maxlength="40" />
                </mat-form-field>

                <!--Postal Code Field-->
                <mat-form-field class="col-2">
                    <mat-label>Postal Code</mat-label>
                    <input autocomplete="new-txtPostalCode" matInput formControlName="txtPostalCode" type="text"
                        maxlength="40" />
                </mat-form-field>
            </div>
        </div>
        <div class="col-12 text-right">
            <button mat-button class="buttonColor mb-2 mr-2">
                <span>Search</span>
                <!-- <mat-icon *ngIf="isShowSpinner">
                    <mat-spinner class="spinner-border spinner-border-sm" diameter="20"></mat-spinner>
                </mat-icon> -->
            </button>
            <button mat-button class="resetclr mb-2 mr-2" (click)="resetPhysician()">Reset</button>
        </div>
    </form>
    <mat-divider></mat-divider>
    <div class="col-lg-12 mt-3">
        <!-- Loader Section -->
        <div class="row" *ngIf="isLoading">
            <div class="col-12">
                <div class="d-flex justify-content-center" *ngIf="isLoading">
                    <img class="img-responsive pb-2 mr-1" src="assets/gifs/Infinity-loader.gif" />
                </div>
            </div>
        </div>
        <div *ngIf="!isLoading" class="table table-responsive">
            <table id="tbl" datatable [dtOptions]="dtOptions" style="width: 100%" class="hover nowrap_th_td">
                <thead>
                    <tr>

                        <th class="text-center">NPI</th>
                        <th class="text-center">Physicians</th>
                        <th class="text-center">FAX</th>
                        <th class="text-center">Address</th>
                        <th class="text-center">City</th>
                        <th class="text-center">State</th>
                        <th class="text-center">Country</th>
                        <th class="text-center">Postal Code</th>
                        <th class="text-center">Action</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let data of Physiciandetails">
                        <td class="text-center">{{ data.NPI || '-' }}</td>
                        <td class="text-center">{{ data.Physicians || '-' }}</td>
                        <td class="text-center">{{ data.FAx || '-' }}</td>
                        <td class="text-center">{{ data.Address || '-' }}</td>
                        <td class="text-center">{{ data.City || '-' }}</td>
                        <td class="text-center">{{ data.State || '-' }}</td>
                        <td class="text-center">{{ data.Country || '-' }}</td>
                        <td class="text-center">{{ data.Postal || '-' }}</td>
                        <td class="text-center"><i class="fa fa-plus action"></i></td>

                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>