import { PagedAndSortedResultRequestDto } from '@abp/ng.core';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
// import { defaultGuid } from 'projects/admin/src/app/document-perviewer/document-perviewer.component';
import {
  orderSearchDTO,
  RentalSaleOrderTemplateDTO,
} from 'projects/order/src/app/order-proxy/order-management/order-optimization/dto/models';
import { PatientSaleOrderService } from 'projects/order/src/app/order-proxy/order-management/order-optimization/patient-sale-order.service';
import { RentalSaleOrderTemplateService } from 'projects/order/src/app/order-proxy/order-management/order-optimization/rental-sale-order-template.service';
import { SharedTableService } from 'projects/shared/src/app/shared-table/shared-table.service';
import { Observable, Subscription } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { PatientPersonalService } from '../patient-proxy/patient-optimization';
import { Title } from '@angular/platform-browser';
import { defaultGuid } from 'projects/shared/src/app/enums/allenums.enum';

@Component({
  selector: 'app-patient-order-tab',
  templateUrl: './patient-order-tab.component.html',
  styleUrls: ['./patient-order-tab.component.scss'],
  providers: [SharedTableService],
})
export class PatientOrderTabComponent implements OnInit {
  // //#region Declarations
  @Output() patientIdEmitter: EventEmitter<string> = new EventEmitter<string>();
  public strPageType: string = 'orders';
  public strPageType2: string = 'template';
  drpPolicies: any;
  public preOrderId: string;
  public secondPreOrderId: string;
  @Input() orderId: string = defaultGuid;
  @Input() patientId: string = defaultGuid;
  tblOrderReponse: Partial<customOrderSearchDTO>[] = [];
  insuranceId: any;
  isLoading: boolean = false;
  isLoadingTemplate: boolean = false;
  subscription$: Subscription[] = [];
  editFormValues: boolean = false;
  isNewPatient: any;
  templateDetails: RentalSaleOrderTemplateDTO[] = [];
  arrDisplayedColumns: string[] = [
    'Options',
    'saleOrderId',
    'itemNames',
    'quantity',
    'totalPrice',
    'dateOfService',
    'createdDate',
    'status',

  ];
  arrDisplayedColumns2: string[] = [
    'templateId',
    'startDate',
    'rentalTypes',
    'endPeriod',
    'nextRun',
    'periodsRun',
    'status',
    'lstSaleOrderIds',
  ];

  dataSource: MatTableDataSource<any> = new MatTableDataSource<any>([]);
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  constructor(
    private router: Router,
    private snackBar: MatSnackBar,
    private Table: SharedTableService,
    private orderService: PatientSaleOrderService,
    private PatientPersonalService: PatientPersonalService,
    private rentalSaleOrderTemplateService: RentalSaleOrderTemplateService,
    private cookieService: CookieService,
    public title: Title,
  ) {}

  ngOnDestroy(): void {
    this.subscription$?.forEach((sub) => {
      sub && sub?.unsubscribe();
    });
  }

  ngOnInit(): void {
    this.title.setTitle("Qsecure | Order");

    let data = this.cookieService.get('OrderTab');
    if (data == 'true') {
      this.editFormValues = false;
    }
    this.Table.setTableData([]);
    this.patientId = this.patientId ?? defaultGuid;
    this.patientId !== defaultGuid && this.getOrderTableData();
    this.patientId !== defaultGuid && this.getTemplateTableData();
    this.snackBar.dismiss();
    this.isNewPatient = this.cookieService.get('patientStatus');
    if (this.isNewPatient == 'true') {
      this.editFormValues = true;
    } else {
      this.editFormValues = false;
      // this.patientIdEmitter.emit();
    }
  }
  editForm() {
    this.editFormValues = true;
  }
  getOrderTableData() {
    this.Table.setTableData([]);
    this.isLoading = true;
    const orderDetails = this.orderService
      .orderSearchBySDefaultPatientIdAndSSaleOrderIdAndSPatientNameAndSCSRNameAndDtDOSAndSStatus(
        this.patientId,
        '',
        '',
        '',
        '',
        '',
        ''
      )
      .subscribe(
        (response) => {

          this.tblOrderReponse =
            response?.map((x, i = 1) => {
              const y = {
                orderId: x?.saleOrderId,
                orderStatus: x?.status,
                ...x,
              };
              return y;
            }) ?? [];

          this.isLoading = false;

          this.Table.setTableData(this.tblOrderReponse);
        },
        (err) => {
          this.Table.setTableData([]);

          this.isLoading = false;
          const data: HttpErrorResponse = err;
          Swal.fire({
            icon: 'info',
            text: data?.error?.error?.message,
          });
        }
      );
    this.subscription$.push(orderDetails);
  }
  //update
  templateStatusUpdate(value: string) {
    this.isLoadingTemplate = true;
    this.setTableData([]);
    const rentalsub = this.rentalSaleOrderTemplateService
      .get(value)
      .pipe(
        map((x) => {
          x.isEnabled = x.isEnabled === 1 ? 0 : 1;
          return x;
        }),
        switchMap((x) => this.rentalSaleOrderTemplateService.update(value, x))
      )
      .subscribe(
        (response) => {
          this.patientId !== defaultGuid && this.getTemplateTableData();
          this.isLoadingTemplate = false;
        },
        (err) => {
          this.setTableData([]);
          this.isLoadingTemplate = false;
          const data: HttpErrorResponse = err;
          Swal.fire({
            icon: 'info',
            text: data?.error?.error?.message,
          });
        }
      );
    this.subscription$.push(rentalsub);
  }
  //get Template Table Data by patient ID
  getTemplateTableData() {
    this.setTableData([]);
    this.isLoadingTemplate = true;
    const rentalsub = this.rentalSaleOrderTemplateService
      .getRendalOrderListByPatientIDByPatientID(this.patientId)
      .subscribe(
        (response) => {
          this.setTableData(response ?? []);
          this.isLoadingTemplate = false;
        },
        (err) => {
          this.setTableData([]);
          this.isLoadingTemplate = false;
          const data: HttpErrorResponse = err;
          Swal.fire({
            icon: 'info',
            text: data?.error?.error?.message,
          });
        }
      );
    this.subscription$.push(rentalsub);
  }

  //Set Table Data
  setTableData(data: any[]) {
    this.dataSource = new MatTableDataSource(data);
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }

  //New Sale Order Button Click Function
  newSaleorder() {
    var v = this.PatientPersonalService.validatePatientAsync(
      this.patientId
    ).subscribe(
      (response) => {
        if (response) {
          this.cookieService.set('orderStatus', 'true');
          this.router.navigate([
            'orders/saleorderCreateEditTab',
            this.patientId,
            defaultGuid,
          ]);
        }
      },
      (err) => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      }
    );
  }

  ViewOrders(value: any) {
    this.router.navigate([
      'orders/saleorderCreateEditTab',
      value?.patientId,
      value?.orderId,
    ]);
  }

  ViewTemplate(value: any) {
    this.router.navigate([
      'orders/OrderTemplateCreateEditTab',
      this.patientId,
      value?.saleOrderId,
      value?.id,
    ]);
  }

  //New Template Order Button Click Function
  newOrderTemplate() {
    this.router.navigate([
      'orders/OrderTemplateCreateEditTab/',
      this.patientId,
      defaultGuid,
      defaultGuid,
    ]);
  }
}
export interface customOrderSearchDTO extends orderSearchDTO {
  orderId: string;
  orderStatus: string;
  sno: number;
}
