import type { CreateUpdateMasterCheckListQuestionsDTO, MasterCheckListQuestionsDTO, QuestionMapping, QuestionResult } from './dto/models';
import { RestService } from '@abp/ng.core';
import type { PagedAndSortedResultRequestDto, PagedResultDto } from '@abp/ng.core';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class MasterCheckListQuestionsService {
  apiName = 'Default';

  create = (input: CreateUpdateMasterCheckListQuestionsDTO) =>
    this.restService.request<any, MasterCheckListQuestionsDTO>({
      method: 'POST',
      url: '/api/app/master-check-list-questions',
      body: input,
    },
    { apiName: this.apiName });

  delete = (id: string) =>
    this.restService.request<any, void>({
      method: 'DELETE',
      url: `/api/app/master-check-list-questions/${id}`,
    },
    { apiName: this.apiName });

  get = (id: string) =>
    this.restService.request<any, MasterCheckListQuestionsDTO>({
      method: 'GET',
      url: `/api/app/master-check-list-questions/${id}`,
    },
    { apiName: this.apiName });

  getList = (input: PagedAndSortedResultRequestDto) =>
    this.restService.request<any, PagedResultDto<MasterCheckListQuestionsDTO>>({
      method: 'GET',
      url: '/api/app/master-check-list-questions',
      params: { skipCount: input.skipCount, maxResultCount: input.maxResultCount, sorting: input.sorting },
    },
    { apiName: this.apiName });

  update = (id: string, input: CreateUpdateMasterCheckListQuestionsDTO) =>
    this.restService.request<any, MasterCheckListQuestionsDTO>({
      method: 'PUT',
      url: `/api/app/master-check-list-questions/${id}`,
      body: input,
    },
    { apiName: this.apiName });

  getQuestionsByCheckListIdByGCheckList = (gCheckList: string) =>
    this.restService.request<any, MasterCheckListQuestionsDTO[]>({
      method: 'GET',
      url: '/api/app/master-check-list-questions/get-questions-by-check-list-id',
      params: { gCheckList },
    },
    { apiName: this.apiName });

  getQuestionsByGCheckList = (gCheckList: QuestionMapping) =>
    this.restService.request<any, QuestionResult>({
      method: 'GET',
      url: '/api/app/master-check-list-questions/get-questions',
      params: { checkListId: gCheckList.checkListId, questionId: gCheckList.questionId, documentId: gCheckList.documentId, patientId: gCheckList.patientId, option: gCheckList.option, secondOption: gCheckList.secondOption },
    },
    { apiName: this.apiName });

  constructor(private restService: RestService) {}
}
