<div class="container-fluid" *ngIf="!isLoading">

  <div class="row bg">
    <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
      <div class="">
        <!-- d-flex align-items-center justify-content-between -->
        <h3 class="font-weight-bolder mb-0">
          <b class="customThemeClass page-header-breadcrumb">Billing / Invoice View</b>
        </h3>
      </div>
    </div>

    <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" *ngIf="Error">
      <div class="">
        <!-- d-flex align-items-center justify-content-between -->
        <h3 class="font-weight-bolder mb-0">
          <b class="asterisk">Error........</b>
        </h3>
      </div>
    </div>

  </div>

  <div class="row">
    <!-- <div class="col-6 col-sm-4 col-md-4 col-lg-3 col-xl-2 p-1"> -->
    <div class="col-md-4 col-lg-3 col-xl-2 mt-2">
    <div class="card d-flex justify-content-center pl-1 overflow-hidden curved-card" style="height: 85vh;width:16vw">
      <!-- <div class="mb-3 align-items-center" style="height: 88vh"> -->
        <div class="scrollable-content">
          <div class="w-100 mt-2 p-1" *ngIf="invoiceDetailsList?.orderDetails">
            <div class="d-flex justify-content-between align-items-center header-color">
              <span><img class="img-responsive" src="assets/Billing Icons/invoice.png" height="20" /> <span
                  class="ml-1 heading-title">Invoice Details </span></span>
              <span [matTooltip]="invoiceDetailsList?.invoiceStatusName || '-'"
                class="rounded-border p-1 text-center cus-label-nocolor" [ngStyle]="{ 'background-color': invoiceDetailsList?.invoiceStatusColor || 'transparent',
                'color': getContrastingTextColor(invoiceDetailsList?.invoiceStatusColor)
              }">
                {{ invoiceDetailsList?.invoiceStatusName || '-' }}
              </span>
            </div>
<!-- 
            <div class="d-flex justify-content-between align-items-center mt-2">
              <b class="heading-boady ml-4 mb-0 ellipsis" [matTooltip]="invoiceDetailsList?.invoiceNumber || '-'">
                <a class="preview-link pointer">
                  {{ invoiceDetailsList?.invoiceNumber || '-' }}</a>

              </b>
              <i class="fa fa-clone mr-1 customThemeClass pointer" [matTooltip]="'Click to Copy Invoice ID'"
                (click)="copyText(invoiceDetailsList?.invoiceNumber || '-')" aria-hidden="true"></i>
            </div> -->

            <!-- <div class="row mt-2"> -->
              <!-- <div class="col-6">
                <mat-label class="text-plan">Default Order Id:</mat-label>
                <div class="d-flex justify-content-between align-items-center">
                  <span class="heading-boady mb-0 ellipsis"
                    [matTooltip]="invoiceDetailsList?.orderDetails?.defaultOrderId || '-'">
                    <a [class.disabled]="isEmpty(invoiceDetailsList?.patientDetails?.patientId) || isEmpty(invoiceDetailsList?.orderDetails?.orderId) "
                      [routerLink]="
              '/orders/saleOrderview/' + invoiceDetailsList?.patientDetails?.patientId + '/' + invoiceDetailsList?.orderDetails?.orderId
            " target="_blank" class="preview-link">
                      {{ invoiceDetailsList?.orderDetails?.defaultOrderId || '-' }}</a>
                  </span>
                </div>
              </div> -->
              <!-- <div class="col-6">
                <mat-label class="text-plan ml-1">Order No:</mat-label>
                <div class="d-flex justify-content-between align-items-center ml-1">
                  <span class="heading-boady mb-0 ellipsis"
                    [matTooltip]="invoiceDetailsList?.orderDetails?.orderTicketId || '-'">
                    <a [class.disabled]="isEmpty(invoiceDetailsList?.patientDetails?.patientId) || isEmpty(invoiceDetailsList?.orderDetails?.orderId)"
                      class="preview-link "
                      [routerLink]="'/orders/saleOrder/' + invoiceDetailsList?.patientDetails?.patientId + '/' + invoiceDetailsList?.orderDetails?.orderId+'/0'"
                      target="_blank">{{ invoiceDetailsList?.orderDetails?.orderTicketId || '-' }}</a>
                  </span>
                </div>
              </div> -->
            <!-- </div> -->

            <div class="row mt-2">
              <div class="col-6">
                <mat-label class="text-plan">Invoice Id:</mat-label>
                <div class="d-flex justify-content-between align-items-center">
                  <span class="heading-boady mb-0 ellipsis" [matTooltip]="invoiceDetailsList?.invoiceNumber || '-'">
                    <a class="preview-link">{{ invoiceDetailsList?.invoiceNumber || '-' }} </a>
                  </span>
                </div>
              </div>

              <div class="col-6">
                <mat-label class="text-plan ml-1">Order No:</mat-label>
                <div class="d-flex justify-content-between align-items-center ml-1">
                  <span class="heading-boady mb-0 ellipsis"
                    [matTooltip]="invoiceDetailsList?.orderDetails?.orderTicketId || '-'">
                    <a [class.disabled]="isEmpty(invoiceDetailsList?.patientDetails?.patientId) || isEmpty(invoiceDetailsList?.orderDetails?.orderId)"
                      class="preview-link "
                      [routerLink]="'/orders/saleOrder/' + invoiceDetailsList?.patientDetails?.patientId + '/' + invoiceDetailsList?.orderDetails?.orderId+'/0'"
                      target="_blank">{{ invoiceDetailsList?.orderDetails?.orderTicketId || '-' }}</a>
                  </span>
                </div>
              </div>
            </div>

            <div class="row mt-2">
              <div class="col-6">
                <mat-label class="text-plan">Created:</mat-label>
                <div class="d-flex justify-content-between align-items-center">
                  <span class="heading-boady mb-0 ellipsis" [matTooltip]="orderDateTime || '-'">
                    {{ orderDateTime || '-' }}
                  </span>
                </div>
              </div>

              <div class="col-6">
                <mat-label class="text-plan ml-1">Created By:</mat-label>
                <div class="d-flex justify-content-between align-items-center ml-1">
                  <span class="heading-boady mb-0 ellipsis"
                    [matTooltip]="(invoiceDetailsList?.orderDetails?.orderCreatedByName | titlecase) || '-'">
                    {{ (invoiceDetailsList?.orderDetails?.orderCreatedByName | titlecase) || '-' }}
                  </span>
                </div>
              </div>
            </div>
<!-- 
            <span (click)="getOrderHistory()" matTooltip="Click to View the Order History"
            class="rounded-border d-flex justify-content-center align-items-center p-1 claim-status-label pointer mt-1">
            {{ invoiceDetailsList?.orderDetails?.orderStatus || "-" }}
          </span> -->

          <span
            class="rounded-border d-flex justify-content-center align-items-center p-1 claim-status-label mt-1">
            {{ invoiceDetailsList?.orderDetails?.orderStatus || "-" }}
          </span>

            <div class="d-flex flex-wrap  mt-2"
              *ngIf="invoiceDetailsList?.orderDetails?.isPurchase || invoiceDetailsList?.orderDetails?.isRental">
              <span *ngIf="invoiceDetailsList?.orderDetails?.isPurchase"
                class="rounded-border text-center p-1 cus-label-plain mr-1 mb-1" matTooltip="Purchase">
                <img class="img-responsive pr-1" src="assets/Billing Icons/Tag.png" height="11" />
                Purchase
              </span>
              <span *ngIf="invoiceDetailsList?.orderDetails?.isRental"
                class="rounded-border text-center p-1 cus-label-plain mr-1 mb-1"
                [matTooltip]="'Rental ('+(invoiceDetailsList?.orderDetails?.rentalMonths || '0') + ') Months'">
                <img class="img-responsive pr-1" src="assets/Billing Icons/Tag.png" height="11" />
                Rental ({{ invoiceDetailsList?.orderDetails?.rentalMonths || '0' }})
              </span>
            </div>
          </div>
          <mat-divider class="my-1" *ngIf="invoiceDetailsList?.orderDetails"></mat-divider>



          <div class="w-100 mt-2 p-1" *ngIf="invoiceDetailsList?.invoiceType">

            <div class="d-flex justify-content-between align-items-center header-color">
              <span><img class="img-responsive" src="assets/Billing Icons/billing.png" height="20" /> <span
                  class="ml-1 heading-title">Billing To </span></span>


              <span *ngIf="invoiceDetailsList?.invoiceType == 1" [matTooltip]="'Primary'"
                class="rounded-border p-1 text-center label-active">
                Primary
              </span>
              <span *ngIf="invoiceDetailsList?.invoiceType == 2" [matTooltip]="'Secondary'"
                class="rounded-border p-1 text-center label-active">
                Secondary
              </span>
            </div>

            <div *ngIf="invoiceDetailsList?.invoiceType == 1"
              class="d-flex justify-content-between align-items-center mt-1"
              [matTooltip]="invoiceDetailsList?.primaryPolicy?.payerName ||'-'">
              <span class="heading-boady mt-1 ml-1">
                {{ invoiceDetailsList?.primaryPolicy?.payerName ||'-' }}
              </span>
            </div>
            <div *ngIf="invoiceDetailsList?.invoiceType == 2"
              class="d-flex justify-content-between align-items-center mt-1"
              [matTooltip]="invoiceDetailsList?.claimDetails?.claimStatusName ||'-'">
              <span class="heading-boady mt-1 ml-1">
                {{ invoiceDetailsList?.secondaryPolicy?.payerName ||'-' }}
              </span>
            </div>
            <div *ngIf="invoiceDetailsList?.invoiceType == 3"
              class="d-flex justify-content-between align-items-center mt-1" [matTooltip]="'Patient Responsibility'">
              <span class="heading-boady mt-1 ml-1">
                {{ 'Patient Responsibility' }}
              </span>
            </div>

          </div>

          <mat-divider class="my-1" *ngIf="invoiceDetailsList?.invoiceType"></mat-divider>


          <div class="w-100 mt-2 p-1" *ngIf="invoiceDetailsList?.patientDetails">
            <div class="d-flex justify-content-between align-items-center header-text">
              <p class="header-text mb-0 pb-2">
                <img class="img-responsive" src="assets/Billing Icons/Patient details.png" height="20" />
                Patient Details
              </p>
            </div>
            <div class="d-flex justify-content-between align-items-center">
              <mat-label class="cus-mat-header-label">Chart No:</mat-label>
            </div>

            <div class="d-flex justify-content-between align-items-center mt-1">
              <span class="cus-text-label mb-0 ellipsis"
                [matTooltip]="invoiceDetailsList?.patientDetails?.chartId || '-'">
                <a [class.disabled]="isEmpty(invoiceDetailsList?.patientDetails?.patientId)" class="preview-link "
                  [routerLink]="'/patientCreateEditTab/' + invoiceDetailsList?.patientDetails?.patientId"
                  target="_blank">{{ invoiceDetailsList?.patientDetails?.chartId || '-' }}</a>
              </span>
              <!-- <i class="fa fa-clone mr-1 customThemeClass pointer" [matTooltip]="'Click to Copy Chart No'"
                (click)="copyText(invoiceDetailsList?.patientDetails?.chartId || '-')" aria-hidden="true"></i> -->
            </div>

            <div class="d-flex justify-content-between align-items-center mt-1">
              <mat-label class="cus-mat-header-label">Patient Name:</mat-label>

            </div>

            <div class="d-flex justify-content-between align-items-center cus-text-label"
              [matTooltip]="(invoiceDetailsList?.patientDetails?.patientName | titlecase) || '-'">
              {{ (invoiceDetailsList?.patientDetails?.patientName | titlecase) || '-' }}
            </div>



            <div class="d-flex align-items-center flex-row cus-text-label justify-content-between"
              [matTooltip]="patientDOB +' - Age '+ (invoiceDetailsList?.patientDetails?.age || '0') + ' Years - ' + (invoiceDetailsList?.patientDetails?.gender || '-')">

              {{patientDOB}} - Age {{ invoiceDetailsList?.patientDetails?.age || '0'}}
              <!-- <mat-icon class="">male</mat-icon> -->
              <i *ngIf="invoiceDetailsList?.patientDetails?.gender == 'Male'" class="fa fa-mars font-weight-bolder mb-2"
                aria-hidden="true"></i>
              <!-- <mat-icon *ngIf="invoiceDetailsList?.patientDetails?.gender == 'Male'"
                class="mt-0 gender-icon-button">female</mat-icon> -->
              <i *ngIf="invoiceDetailsList?.patientDetails?.gender == 'Female'"
                class="fa fa-venus font-weight-bolder mb-2" aria-hidden="true"></i>
              <!-- <mat-icon *ngIf="invoiceDetailsList?.patientDetails?.gender == 'Female'"
                class="mt-0 gender-icon-button">female</mat-icon> -->
              <!-- <i *ngIf="invoiceDetailsList?.patientDetails?.gender != 'Male' && invoiceDetailsList?.patientDetails?.gender != 'Female'" class="fa fa-genderless" aria-hidden="true"></i> -->
              <!-- gender-icon-button -->
            </div>

            <div class="d-flex justify-content-between align-items-center">
              <mat-label class="cus-mat-header-label">Delivery Address :</mat-label><i
                class="fa fa-map-marker mr-1 mt-1" aria-hidden="true"></i>
            </div>

            <div class="d-flex justify-content-between align-items-center cus-text-label"
              [matTooltip]="invoiceDetailsList?.patientDetails?.address || '-'">
              {{ invoiceDetailsList?.patientDetails?.address || '-'}}
            </div>

            <div class="d-flex justify-content-between align-items-center mt-1">
              <mat-label class="cus-mat-header-label">Cell No:</mat-label><i class="fa fa-phone mr-1 mt-1"
                aria-hidden="true"></i>
            </div>

            <div class="d-flex justify-content-between align-items-center cus-text-label"
              [matTooltip]="invoiceDetailsList?.patientDetails?.cellNumber || '-'">
              {{ invoiceDetailsList?.patientDetails?.cellNumber || '-'}}
            </div>

            <div class="d-flex justify-content-between align-items-center mt-1">
              <mat-label class="cus-mat-header-label">Email:</mat-label> <i class="fa fa-envelope mr-1 iconfont"
                aria-hidden="true"></i>
            </div>

            <div class="d-flex justify-content-between align-items-center cus-text-label"
              [matTooltip]="invoiceDetailsList?.patientDetails?.emailAddress || '-'">
              {{ invoiceDetailsList?.patientDetails?.emailAddress || '-' }}
            </div>

            <div class="d-flex justify-content-between align-items-center mt-1">
              <mat-label class="cus-mat-header-label">RX Date:</mat-label><i class="fa fa-calendar-o mr-1 iconfont"
                aria-hidden="true"></i>
            </div>

            <div class="d-flex justify-content-between align-items-center cus-text-label"
              [matTooltip]="patientRxDate || '-'">
              {{ patientRxDate || '-' }}
            </div>

            <div class="d-flex justify-content-between align-items-center mt-1">
              <mat-label class="cus-mat-header-label">Fax Location:</mat-label><i class="fa fa-map-marker mr-1"
                aria-hidden="true"></i>
            </div>

            <div class="d-flex justify-content-between align-items-center cus-text-label"
              [matTooltip]="(invoiceDetailsList?.locationDetails?.locationName | titlecase ) || '-'">
              {{ (invoiceDetailsList?.locationDetails?.locationName | titlecase ) || '-' }}
            </div>

          </div>
          <mat-divider class="my-1" *ngIf="invoiceDetailsList?.patientDetails"></mat-divider>
          <div class="w-100 mt-2 p-1" *ngIf="invoiceDetailsList?.clinicalDetails">
            <div class="d-flex justify-content-between align-items-center header-text">
              <p class="header-text mb-0 pb-2">
                <img class="img-responsive" src="assets/Billing Icons/Referring Provider.png" height="20" />
                Referring Provider
              </p>
            </div>

            <div class="d-flex justify-content-between align-items-center mt-1">
              <mat-label class="cus-mat-header-label">Name and Address:</mat-label>
            </div>

            <div class="d-flex justify-content-between align-items-center cus-text-label" [matTooltip]="(invoiceDetailsList?.clinicalDetails?.referringProviderName | titlecase) || '-'">
              {{ (invoiceDetailsList?.clinicalDetails?.referringProviderName | titlecase) || '-'}}
            </div>

            <div class="d-flex justify-content-between align-items-center cus-text-label"
              [matTooltip]="invoiceDetailsList?.clinicalDetails?.address">
              {{(invoiceDetailsList?.clinicalDetails?.address | titlecase) || '-'}}
            </div>

            <div class="d-flex justify-content-between align-items-center mt-1">
              <mat-label class="cus-mat-header-label">NPI:</mat-label>
            </div>

            <div class="d-flex justify-content-between align-items-center cus-text-label" [matTooltip]="invoiceDetailsList?.clinicalDetails?.referringProviderNPI || '-'">
              {{invoiceDetailsList?.clinicalDetails?.referringProviderNPI || '-'}}
            </div>

            <div class="d-flex justify-content-between align-items-center mt-1">
              <mat-label class="cus-mat-header-label">Phone:</mat-label>
            </div>

            <div class="d-flex justify-content-between align-items-center cus-text-label" [matTooltip]="invoiceDetailsList?.clinicalDetails?.phoneNumber || '-'">
              {{invoiceDetailsList?.clinicalDetails?.phoneNumber  || '-'}}
            </div>

            <!--Billing Provider-->

            <div class="d-flex justify-content-between align-items-center mt-1">
              <mat-label class="cus-mat-header-label">Billing Provider:</mat-label>
            </div>

            <div class="d-flex justify-content-between align-items-center cus-text-label" [matTooltip]="(invoiceDetailsList?.providerDetails?.providerName | titlecase) || '-'">
              {{ (invoiceDetailsList?.providerDetails?.providerName | titlecase) || '-'}}
            </div>

            <div class="d-flex justify-content-between align-items-center cus-text-label"
              [matTooltip]="invoiceDetailsList?.providerDetails?.address">
              {{(invoiceDetailsList?.providerDetails?.address | titlecase) || '-'}}
            </div>

            <div class="d-flex justify-content-between align-items-center mt-1">
              <mat-label class="cus-mat-header-label">NPI:</mat-label>
            </div>

            <div class="d-flex justify-content-between align-items-center cus-text-label" [matTooltip]="invoiceDetailsList?.providerDetails?.providerNpi || '-'">
              {{invoiceDetailsList?.providerDetails?.providerNpi || '-'}}
            </div>

            <div class="d-flex justify-content-between align-items-center mt-1">
              <mat-label class="cus-mat-header-label">Phone:</mat-label>
            </div>

            <div class="d-flex justify-content-between align-items-center cus-text-label" [matTooltip]="invoiceDetailsList?.providerDetails?.providerPhoneNumber || '-'">
              {{invoiceDetailsList?.providerDetails?.providerPhoneNumber  || '-'}}
            </div>
          </div>
          <mat-divider class="my-1" *ngIf="invoiceDetailsList?.clinicalDetails"></mat-divider>
          <div class="w-100 mt-2 p-1" *ngIf="invoiceDetailsList?.practiceDetails">
            <div class="d-flex justify-content-between align-items-center header-text mt-1">
              PRACTICE DETAILS :
            </div>

            <div class="d-flex justify-content-between align-items-center mt-2">
              <b class="main-id mb-0 ellipsis" [matTooltip]="invoiceDetailsList?.practiceDetails?.practiceName || '-'">
                <a [class.disabled]="isEmpty(invoiceDetailsList?.practiceDetails?.practiceId)" class="preview-link "
                  [routerLink]="'/addpracticemanagement/' + invoiceDetailsList?.practiceDetails?.practiceId"
                  target="_blank">{{ invoiceDetailsList?.practiceDetails?.practiceName || '-' }}</a>
              </b>
            </div>

          </div>
          <mat-divider class="my-1" *ngIf="invoiceDetailsList?.practiceDetails"></mat-divider>
          <div class="w-100 mt-2 p-1 mb-2"
            *ngIf="invoiceDetailsList?.primaryPolicy || invoiceDetailsList?.secondaryPolicy">
            <div class="d-flex justify-content-between align-items-center header-text">
              <p class="header-text mb-0 pb-2">
                <img class="img-responsive" src="assets/Billing Icons/Insurance.png" height="20" />
                Insurance
              </p>
            </div>

            <!--Primary Insurance-->
            <mat-expansion-panel [expanded]="step" class="exapnsion-panel" *ngIf="invoiceDetailsList?.primaryPolicy">
              <mat-expansion-panel-header>
                <mat-panel-title>
                  <span class="cus-mat-header-label">Primary Insurance</span>
                </mat-panel-title>
              </mat-expansion-panel-header>

              <div class="d-flex justify-content-between align-items-center cus-text-label">

                <ng-container *ngIf="invoiceDetailsList?.primaryPolicy?.policyId !== defaultGuid && !invoiceDetailsList?.orderDetails?.isCashOrder">

                <a [matTooltip]="(invoiceDetailsList?.primaryPolicy?.payerName | titlecase) || '-'"
                  [class.disabled]="isEmpty(invoiceDetailsList?.primaryPolicy?.verificaitonDetails?.policyId) || isEmpty(invoiceDetailsList?.primaryPolicy?.verificaitonDetails?.verificationId)"
                  (click)=" openVerification(invoiceDetailsList?.primaryPolicy?.verificaitonDetails?.policyId,invoiceDetailsList?.primaryPolicy?.verificaitonDetails?.verificationId) "
                  class="preview-link bolder pointer">{{ invoiceDetailsList?.primaryPolicy?.payerName || '-' | titlecase}} </a>

                </ng-container>

                <ng-container *ngIf="invoiceDetailsList?.primaryPolicy?.policyId === defaultGuid || invoiceDetailsList?.orderDetails?.isCashOrder">-</ng-container>

                <i class="fa fa-info-circle iconclr" *ngIf="invoiceDetailsList?.primaryPolicy?.policyId !== defaultGuid && !invoiceDetailsList?.orderDetails?.isCashOrder"
                  [matTooltip]="'Verified : ' + (primaryVerifiedDate) + ' ' + (invoiceDetailsList?.primaryPolicy?.verificaitonDetails?.verifiedByName ||'-' | titlecase) || '-'"></i>

                <img class="img-responsive" src="assets/Dashboard New/Real-Transparent-pVerify-logo.png" height="18" *ngIf="invoiceDetailsList?.primaryPolicy?.verificaitonDetails?.eligibilityMethod == 'pVerify'"/>
                <!-- <img class="img-responsive" src="assets/Billing Icons/manual.png"  height="18"/> -->
              
              </div>

              <div class="d-flex justify-content-between align-items-center mt-1" *ngIf="invoiceDetailsList?.primaryPolicy?.policyId !== defaultGuid && !invoiceDetailsList?.orderDetails?.isCashOrder">
                <mat-label class="cus-mat-header-label">Policy No</mat-label>
              </div>

              <div class="d-flex justify-content-between align-items-center cus-text-label"
                [matTooltip]="invoiceDetailsList?.primaryPolicy?.policNumber || '-'" *ngIf="invoiceDetailsList?.primaryPolicy?.policyId !== defaultGuid && !invoiceDetailsList?.orderDetails?.isCashOrder">
                {{ invoiceDetailsList?.primaryPolicy?.policNumber || '-' }}
              </div>

              <div class="d-flex justify-content-between align-items-center mt-1" *ngIf="invoiceDetailsList?.primaryPolicy?.policyId !== defaultGuid && !invoiceDetailsList?.orderDetails?.isCashOrder">
                <mat-label class="cus-mat-header-label">Group No</mat-label>
              </div>

              <div class="d-flex justify-content-between align-items-center cus-text-label"
                [matTooltip]="invoiceDetailsList?.primaryPolicy?.groupNumber || '-'" *ngIf="invoiceDetailsList?.primaryPolicy?.policyId !== defaultGuid && !invoiceDetailsList?.orderDetails?.isCashOrder">
                {{ invoiceDetailsList?.primaryPolicy?.groupNumber || '-' }}
              </div>
            </mat-expansion-panel>

            <!--Secondary Insurance-->
            <mat-expansion-panel [expanded]="step" class="exapnsion-panel" *ngIf="invoiceDetailsList?.secondaryPolicy">
              <mat-expansion-panel-header>
                <mat-panel-title>
                  <span class="cus-mat-header-label">Secondary Insurance</span>
                </mat-panel-title>
              </mat-expansion-panel-header>

              <div class="d-flex justify-content-between align-items-center cus-text-label">

                <ng-container *ngIf="invoiceDetailsList?.secondaryPolicy?.policyId !== defaultGuid && !invoiceDetailsList?.orderDetails?.isCashOrder">

                <a [matTooltip]="(invoiceDetailsList?.secondaryPolicy?.payerName | titlecase) || '-'"
                  [class.disabled]="isEmpty(invoiceDetailsList?.secondaryPolicy?.verificaitonDetails?.policyId) || isEmpty(invoiceDetailsList?.secondaryPolicy?.verificaitonDetails?.verificationId)"
                  (click)=" openVerification(invoiceDetailsList?.secondaryPolicy?.verificaitonDetails?.policyId,invoiceDetailsList?.secondaryPolicy?.verificaitonDetails?.verificationId) "
                  class="preview-link bolder pointer">{{ invoiceDetailsList?.secondaryPolicy?.payerName || '-' |
                  titlecase}} </a>

                </ng-container>
                <ng-container *ngIf="invoiceDetailsList?.secondaryPolicy?.policyId === defaultGuid || invoiceDetailsList?.orderDetails?.isCashOrder">-</ng-container>
                <!-- <i class="fa fa-info-circle mr-2 iconclr"
                  [matTooltip]="'Verified : ' + (secondaryVerifiedDate) + ' ' + (invoiceDetailsList?.secondaryPolicy?.verificaitonDetails?.verifiedByName ||'-' | titlecase)"></i> -->

                  <i class="fa fa-info-circle iconclr" *ngIf="invoiceDetailsList?.secondaryPolicy?.policyId !== defaultGuid && !invoiceDetailsList?.orderDetails?.isCashOrder"[matTooltip]="(secondaryVerifiedDate && invoiceDetailsList?.secondaryPolicy?.verificaitonDetails?.verifiedByName) ?
                  'Verified : ' + (secondaryVerifiedDate) + ' ' + (invoiceDetailsList?.secondaryPolicy?.verificaitonDetails?.verifiedByName ||'-' | titlecase) :
                  'Not Verified'">
                  </i>
                <img class="img-responsive" src="assets/Dashboard New/Real-Transparent-pVerify-logo.png" height="18" *ngIf="invoiceDetailsList?.secondaryPolicy?.verificaitonDetails?.eligibilityMethod == 'pVerify'"/>
              </div>

              <div class="d-flex justify-content-between align-items-center" *ngIf="invoiceDetailsList?.secondaryPolicy?.policyId !== defaultGuid && !invoiceDetailsList?.orderDetails?.isCashOrder">
                <mat-label class="cus-mat-header-label">Policy Number:</mat-label>
              </div>

              <div class="d-flex justify-content-between align-items-center cus-text-label"
                [matTooltip]="invoiceDetailsList?.secondaryPolicy?.policNumber || '-'" *ngIf="invoiceDetailsList?.secondaryPolicy?.policyId !== defaultGuid && !invoiceDetailsList?.orderDetails?.isCashOrder">
                {{ invoiceDetailsList?.secondaryPolicy?.policNumber || '-' }}
              </div>

              <div class="d-flex justify-content-between align-items-center" *ngIf="invoiceDetailsList?.secondaryPolicy?.policyId !== defaultGuid && !invoiceDetailsList?.orderDetails?.isCashOrder">
                <mat-label class="cus-mat-header-label">Group Number:</mat-label>
              </div>

              <div class="d-flex justify-content-between align-items-center cus-text-label"
                [matTooltip]="invoiceDetailsList?.secondaryPolicy?.groupNumber || '-'" *ngIf="invoiceDetailsList?.secondaryPolicy?.policyId !== defaultGuid && !invoiceDetailsList?.orderDetails?.isCashOrder">
                {{ invoiceDetailsList?.secondaryPolicy?.groupNumber || '-' }}
              </div>

            </mat-expansion-panel>
          </div>

          <!-- <div class="card card-bordy w-100 mt-2 p-1"
            *ngIf="invoiceDetailsList?.primaryPolicy || invoiceDetailsList?.secondaryPolicy">

            <div *ngIf="invoiceDetailsList?.primaryPolicy">
              <div class="d-flex justify-content-between align-items-center header-text  mt-1">
                PRIMARY INSURANCE :
              </div>
              <div class="d-flex justify-content-between align-items-center cus-text-label"
                [matTooltip]="invoiceDetailsList?.primaryPolicy?.payerName || '-'">
                <a [class.disabled]="isEmpty(invoiceDetailsList?.primaryPolicy?.verificaitonDetails?.policyId) || isEmpty(invoiceDetailsList?.primaryPolicy?.verificaitonDetails?.verificationId)"
                  (click)=" openVerification(invoiceDetailsList?.primaryPolicy?.verificaitonDetails?.policyId,invoiceDetailsList?.primaryPolicy?.verificaitonDetails?.verificationId) "
                  class="preview-link bolder pointer">{{ invoiceDetailsList?.primaryPolicy?.payerName || '-' }} </a>
              </div>

              <div class="d-flex justify-content-between align-items-center">
                <mat-label class="cus-mat-header-label">Policy Number:</mat-label>
              </div>

              <div class="d-flex justify-content-between align-items-center cus-text-label"
                [matTooltip]="invoiceDetailsList?.primaryPolicy?.policNumber || '-'">
                {{ invoiceDetailsList?.primaryPolicy?.policNumber || '-' }}
              </div>

              <div class="d-flex justify-content-between align-items-center">
                <mat-label class="cus-mat-header-label">Group Number:</mat-label>
              </div>

              <div class="d-flex justify-content-between align-items-center cus-text-label"
                [matTooltip]="invoiceDetailsList?.primaryPolicy?.groupNumber || '-'">
                {{ invoiceDetailsList?.primaryPolicy?.groupNumber || '-' }}
              </div>

              <div class="d-flex justify-content-between align-items-center">
                <mat-label class="cus-mat-header-label">Verified On:</mat-label>
              </div>

              <div class="d-flex justify-content-between align-items-center cus-text-label"
                [matTooltip]="primaryVerifiedDate || '-'">
                {{ primaryVerifiedDate || '-' }}
              </div>

              <div class="d-flex justify-content-between align-items-center">
                <mat-label class="cus-mat-header-label">Verified By:</mat-label>
              </div>

              <div class="d-flex justify-content-between align-items-center cus-text-label"
                [matTooltip]="(invoiceDetailsList?.primaryPolicy?.verificaitonDetails?.verifiedByName | titlecase) || '-'">
                {{ (invoiceDetailsList?.primaryPolicy?.verificaitonDetails?.verifiedByName | titlecase) || '-' }}
              </div>
            </div>

            <div *ngIf="invoiceDetailsList?.secondaryPolicy">

              <div class="d-flex justify-content-between align-items-center header-text  mt-1">
                SECONDARY INSURANCE :
              </div>

              <div class="d-flex justify-content-between align-items-center cus-text-label"
                [matTooltip]="invoiceDetailsList?.secondaryPolicy?.payerName || '-'">
                <a [class.disabled]="isEmpty(invoiceDetailsList?.secondaryPolicy?.verificaitonDetails?.policyId) || isEmpty(invoiceDetailsList?.secondaryPolicy?.verificaitonDetails?.verificationId)"
                  (click)=" openVerification(invoiceDetailsList?.secondaryPolicy?.verificaitonDetails?.policyId,invoiceDetailsList?.secondaryPolicy?.verificaitonDetails?.verificationId) "
                  class="preview-link bolder pointer">{{ invoiceDetailsList?.secondaryPolicy?.payerName || '-' }} </a>
              </div>

              <div class="d-flex justify-content-between align-items-center">
                <mat-label class="cus-mat-header-label">Policy Number:</mat-label>
              </div>

              <div class="d-flex justify-content-between align-items-center cus-text-label"
                [matTooltip]="invoiceDetailsList?.secondaryPolicy?.policNumber || '-'">
                {{ invoiceDetailsList?.secondaryPolicy?.policNumber || '-' }}
              </div>

              <div class="d-flex justify-content-between align-items-center">
                <mat-label class="cus-mat-header-label">Group Number:</mat-label>
              </div>

              <div class="d-flex justify-content-between align-items-center cus-text-label"
                [matTooltip]="invoiceDetailsList?.secondaryPolicy?.groupNumber || '-'">
                {{ invoiceDetailsList?.secondaryPolicy?.groupNumber || '-' }}
              </div>

              <div class="d-flex justify-content-between align-items-center">
                <mat-label class="cus-mat-header-label">Verified On:</mat-label>
              </div>

              <div class="d-flex justify-content-between align-items-center cus-text-label"
                [matTooltip]="secondaryVerifiedDate || '-'">
                {{ secondaryVerifiedDate || '-' }}
              </div>

              <div class="d-flex justify-content-between align-items-center">
                <mat-label class="cus-mat-header-label">Verified By:</mat-label>
              </div>

              <div class="d-flex justify-content-between align-items-center cus-text-label"
                [matTooltip]="(invoiceDetailsList?.secondaryPolicy?.verificaitonDetails?.verifiedByName | titlecase) || '-'">
                {{(invoiceDetailsList?.secondaryPolicy?.verificaitonDetails?.verifiedByName | titlecase) || '-' }}
              </div>
            </div>
          </div> -->




        </div>
      <!-- </div> -->
    </div>
    </div>

    <div class="col-md-8 col-lg-9 col-xl-10 mt-1">
      <div class="d-flex justify-content-center mb-3 align-items-center">
        <div class="col-12 px-0">
          <div class="d-flex align-items-center heading-title"
            *ngIf="invoiceDetailsList?.invoiceType == 1 || invoiceDetailsList?.invoiceType == 2">
            Claim Details :
            <span class="heading-title mb-0 ellipsis ml-1 header-color"
              [matTooltip]="invoiceDetailsList?.claimDetails?.defaultClaimId || '-'">
              {{ invoiceDetailsList?.claimDetails?.defaultClaimId || '-' }}
            </span>

          </div>

          <div class="d-flex justify-content-between" *ngIf="invoiceDetailsList?.invoiceType == 3">
            <div class="col-6">
              <mat-label class="text-left heading-title">Paypal Status :</mat-label>
              <span class="heading-title mb-0 ellipsis ml-1 header-color"
                [matTooltip]="invoiceDetailsList?.paypalDetails?.paymentStatus || '-'">
                {{ invoiceDetailsList?.paypalDetails?.paymentStatus || '-' }}
              </span>
            </div>

            <div class="col-6 text-right">
              <mat-label class="heading-title"> Invoice Number :</mat-label>
              <span class="heading-title mb-0 ellipsis ml-1 header-color"
                [matTooltip]="invoiceDetailsList?.paypalDetails?.invoiceNumber || '-'">
                {{ invoiceDetailsList?.paypalDetails?.invoiceNumber || '-' }}
              </span>
            </div>
          </div>
<!--
          <div class="d-flex align-items-center heading-title header-color" *ngIf="invoiceDetailsList?.invoiceType == 3">
            Paypal Status :
            <span class="heading-boady mb-0 ellipsis ml-1 pointer"
              [matTooltip]="invoiceDetailsList?.paypalDetails?.paymentStatus || '-'">
              <a class="preview-link ">{{ invoiceDetailsList?.paypalDetails?.paymentStatus || '-' }}</a>
            </span>
          </div> -->


          <div class="card card-body mt-2 p-2 card-header cardCustomCls curved-card"
            *ngIf="invoiceDetailsList?.invoiceType == 1 || invoiceDetailsList?.invoiceType == 2 || invoiceDetailsList?.invoiceType == 3">
            <div class="d-flex justify-content-between mt-2" *ngIf="invoiceDetailsList?.invoiceType == 1 || invoiceDetailsList?.invoiceType == 2">
              <div class="col-1.5">
                <p class="mb-1 header-text">Order Amount</p>
                  <i class="fa fa-usd dolloricon" aria-hidden="true"></i>
                <span class="text-currency">
                  {{ invoiceDetailsList?.invoiceStatusName === 'Hold' ? invoiceDetailsList?.orderDetails?.charges || '0' : invoiceDetailsList?.claimDetails?.charges || '0' }}
                </span>
                <!-- <b><i class="fa fa-usd dolloricon" aria-hidden="true"></i> {{ invoiceDetailsList?.claimDetails?.charges || '0'}}</b> -->
              </div>
              <mat-divider [vertical]="true"></mat-divider>
              <div class="col-1.5">
                <p class="mb-1 header-text">Allowed</p>

                <span class="text-currency">
                  <i class="fa fa-usd dolloricon" aria-hidden="true"></i>
                  {{ invoiceDetailsList?.invoiceStatusName === 'Hold' ? invoiceDetailsList?.orderDetails?.allowed || '0' : invoiceDetailsList?.claimDetails?.allowed || '0' }}
                </span>

                <!-- <b><i class="fa fa-usd dolloricon" aria-hidden="true"></i> {{ invoiceDetailsList?.claimDetails?.allowed
                  || '0'}}</b> -->
              </div>
              <mat-divider [vertical]="true"></mat-divider>
              <div class="col-1.5">
                <p class="mb-1 header-text">Accessory</p>
                <span class="text-currency"> <i class="fa fa-usd dolloricon"
                    aria-hidden="true"></i> {{ invoiceDetailsList?.orderDetails?.accessory || '0' }}</span>
              </div>
              <mat-divider [vertical]="true"></mat-divider>
              <div class="col-1.5">
                <p class="mb-1 header-text">Adjustments</p>

                <span class="text-currency">
                  <i class="fa fa-usd dolloricon" aria-hidden="true"></i>
                  {{ invoiceDetailsList?.invoiceStatusName === 'Hold' ? invoiceDetailsList?.orderDetails?.adjustments || '0' : invoiceDetailsList?.claimDetails?.adjustments || '0' }}
                </span>

                <!-- <b><i class="fa fa-usd dolloricon" aria-hidden="true"></i> {{ invoiceDetailsList?.claimDetails?.adjustments || '0'}}</b> -->
              </div>
              <mat-divider [vertical]="true"></mat-divider>
              <div class="col-1.5">
                <p class="mb-1 header-text">Payment</p>
                <span class="text-currency">
                  <i class="fa fa-usd dolloricon" aria-hidden="true"></i>
                  {{ invoiceDetailsList?.invoiceStatusName === 'Hold' ? invoiceDetailsList?.orderDetails?.payments || '0' : invoiceDetailsList?.claimDetails?.payments || '0' }}
                </span>
                <!-- <b><i class="fa fa-usd dolloricon" aria-hidden="true"></i> {{ invoiceDetailsList?.claimDetails?.payments || '0'}}</b> -->
              </div>
              <mat-divider [vertical]="true"></mat-divider>
              <div class="col-1.5">
                <p class="mb-1 header-text">Balance</p>
                <span class="text-currency">
                  <i class="fa fa-usd dolloricon" aria-hidden="true"></i>
                  {{ invoiceDetailsList?.invoiceStatusName === 'Hold' ? invoiceDetailsList?.orderDetails?.balance || '0' : invoiceDetailsList?.claimDetails?.balance || '0' }}
                </span>

                <!-- <b><i class="fa fa-usd dolloricon" aria-hidden="true"></i> {{ invoiceDetailsList?.claimDetails?.balance || '0'}}</b> -->
              </div>
              <mat-divider [vertical]="true"></mat-divider>
              <div class="col-1.5 mr-2">
                <p class="mb-1 header-text">Shipping Fee</p>
                <span class="text-currency">
                  <i class="fa fa-usd dolloricon" aria-hidden="true"></i>
                  {{ invoiceDetailsList?.claimDetails?.shippingFee || '0' }} 
                </span>
              </div>
            </div>

            <div class="d-flex justify-content-between mt-2" *ngIf="invoiceDetailsList?.invoiceType == 3">
              <div class="col-1.5">
                <p class="mb-1 header-text">Order Amount</p>
                <span class="text-currency">
                  <i class="fa fa-usd dolloricon" aria-hidden="true"></i>
                  {{ invoiceDetailsList?.invoiceStatusName === 'Hold' ? invoiceDetailsList?.orderDetails?.charges || '0' : invoiceDetailsList?.paypalDetails?.charges || '0' }}
                </span>
                <!-- <b><i class="fa fa-usd dolloricon" aria-hidden="true"></i> {{ invoiceDetailsList?.paypalDetails?.charges
                  || '0'}}</b> -->
              </div>
              <mat-divider [vertical]="true"></mat-divider>
              <div class="col-1.5">
                <p class="mb-1 header-text">Allowed</p>
                <span class="text-currency">
                  <i class="fa fa-usd dolloricon" aria-hidden="true"></i>
                  {{ invoiceDetailsList?.invoiceStatusName === 'Hold' ? invoiceDetailsList?.orderDetails?.allowed || '0' : invoiceDetailsList?.paypalDetails?.allowed || '0' }}
                </span>
                <!-- <b><i class="fa fa-usd dolloricon" aria-hidden="true"></i> {{ invoiceDetailsList?.paypalDetails?.allowed
                  || '0'}}</b> -->
              </div>
              <mat-divider [vertical]="true"></mat-divider>
              <div class="col-1.5">
                <p class="mb-1 header-text">Accessory</p>
                <span class="text-currency"> <i class="fa fa-usd dolloricon"
                    aria-hidden="true"></i> {{ invoiceDetailsList?.orderDetails?.accessory || '0' }}</span>
              </div>
              <mat-divider [vertical]="true"></mat-divider>
              <div class="col-1.5">
                <p class="mb-1 header-text">Adjustment</p>
                <span class="text-currency">
                  <i class="fa fa-usd dolloricon" aria-hidden="true"></i>
                  {{ invoiceDetailsList?.invoiceStatusName === 'Hold' ? invoiceDetailsList?.orderDetails?.adjustments || '0' : invoiceDetailsList?.paypalDetails?.adjustments || '0' }}
                </span>
                <!-- <b><i class="fa fa-usd dolloricon" aria-hidden="true"></i> {{
                  invoiceDetailsList?.paypalDetails?.adjustments || '0'}}</b> -->
              </div>
              <mat-divider [vertical]="true"></mat-divider>
              <div class="col-1.5">
                <p class="mb-1 header-text">Payment</p>
                <span class="text-currency">
                  <i class="fa fa-usd dolloricon" aria-hidden="true"></i>
                  {{ invoiceDetailsList?.invoiceStatusName === 'Hold' ? invoiceDetailsList?.orderDetails?.payments || '0' : invoiceDetailsList?.paypalDetails?.payments || '0' }}
                </span>
                <!-- <b><i class="fa fa-usd dolloricon" aria-hidden="true"></i> {{
                  invoiceDetailsList?.paypalDetails?.payments || '0'}}</b> -->
              </div>
              <mat-divider [vertical]="true"></mat-divider>
              <div class="col-1.5">
                <p class="mb-1 header-text">Balance</p>
                <span class="text-currency">
                  <i class="fa fa-usd dolloricon" aria-hidden="true"></i>
                  {{ invoiceDetailsList?.invoiceStatusName === 'Hold' ? invoiceDetailsList?.orderDetails?.balance || '0' : invoiceDetailsList?.paypalDetails?.balance || '0' }}
                </span>
                <!-- <b><i class="fa fa-usd dolloricon" aria-hidden="true"></i> {{ invoiceDetailsList?.paypalDetails?.balance
                  || '0'}}</b> -->
              </div>
              <mat-divider [vertical]="true"></mat-divider>
              <div class="col-1.5 mr-2">
                <p class="mb-1 header-text">Shipping Fee</p>
                <span class="text-currency">
                  <i class="fa fa-usd dolloricon" aria-hidden="true"></i>
                  {{ invoiceDetailsList?.claimDetails?.shippingFee || '0' }} 
                </span>
              </div>
            </div>
            <!-- <hr class="mt-0 mb-0"> -->
          </div>

          <div class="d-flex justify-content-between p-1 footerCls curved-card-bottom"
            *ngIf="invoiceDetailsList?.invoiceType == 1 || invoiceDetailsList?.invoiceType == 2">

            <div class="col-3">
              <mat-label class="cus-mat-header-label">Deductible: {{ invoiceDetailsList?.claimDetails?.deductible || '0'
                }}</mat-label>
            </div>
            <!-- <mat-divider [vertical]="true"></mat-divider> -->
            <div class="col-2">
              <mat-label class="cus-mat-header-label">Copay: {{ invoiceDetailsList?.claimDetails?.copay || '0'
                }}</mat-label>
            </div>
            <!-- <mat-divider [vertical]="true"></mat-divider> -->
            <div class="col-3">
              <mat-label class="cus-mat-header-label">Coinsurance: {{ invoiceDetailsList?.claimDetails?.coinsurance ||
                '0' }}</mat-label>
            </div>
          </div>


          <div class="d-flex justify-content-between p-1 footerCls curved-card-bottom" *ngIf="invoiceDetailsList?.invoiceType == 3">

            <div class="col-3">
              <mat-label class="cus-mat-header-label">Deductible: {{ invoiceDetailsList?.paypalDetails?.deductible ||
                '0' }}</mat-label>
            </div>
            <div class="col-2">
              <mat-label class="cus-mat-header-label">Copay: {{ invoiceDetailsList?.paypalDetails?.copay || '0'
                }}</mat-label>
            </div>
            <div class="col-3">
              <mat-label class="cus-mat-header-label">Coinsurance: {{ invoiceDetailsList?.paypalDetails?.coinsurance ||
                '0' }}</mat-label>
            </div>
          </div>
          <div style="height: 72vh">
            <div class="scrollable-content">
          <div class="card card-body mt-2 p-2 curved-card">
            <div class="d-flex justify-content-between align-items-center header-text">
              <p class="mb-0 pb-2">
                <img class="img-responsive" src="assets/Billing Icons/Referring Provider.png" height="20" />
                <span class="ml-2 header-text"> Invoice Info 
                  <ng-container *ngIf="invoiceDetailsList?.orderDetails?.isCashOrder">
                  -
                  <span class="rounded-border p-1 claim-status-label">
                    Cash Order
                  </span>
                </ng-container></span>
              </p>
            </div>
              <!-- <div class="d-flex justify-content-between"
                *ngIf="invoiceDetailsList?.clinicalDetails || invoiceDetailsList?.clinicalDetails">
                <div class="col-5 mt-1" *ngIf="invoiceDetailsList?.clinicalDetails">
                  <mat-label class="text-facility">Referring Provider :</mat-label>
                  <span class="cus-text-label ml-2"
                    [matTooltip]="(invoiceDetailsList?.clinicalDetails?.referringProviderName || '-' ) + ' ( NPI: ' + (invoiceDetailsList?.clinicalDetails?.referringProviderNPI || '-' ) + ')'">
                    {{ (invoiceDetailsList?.clinicalDetails?.referringProviderName || '-' ) + ' ( NPI: ' +
                    (invoiceDetailsList?.clinicalDetails?.referringProviderNPI || '-' ) + ')' }}
                  </span>
                </div>
                <mat-divider *ngIf="invoiceDetailsList?.clinicalDetails" [vertical]="true"></mat-divider>
                <div class="col-6 mt-1" *ngIf="invoiceDetailsList?.providerDetails">
                  <mat-label class="text-facility">Billing provider :</mat-label>
                  <span class="cus-text-label ml-2"
                    [matTooltip]="(invoiceDetailsList?.providerDetails?.providerName || '-' ) + ' ( NPI: ' + (invoiceDetailsList?.providerDetails?.providerNpi || '-' ) + ')'">
                    {{ (invoiceDetailsList?.providerDetails?.providerName || '-' ) + ' ( NPI: ' +
                    (invoiceDetailsList?.providerDetails?.providerNpi || '-' ) + ')' }}</span>
                </div>
              </div> -->

              <div class="justify-content-between" *ngIf="holdReasons?.length != 0">
                <mat-label class="cus-mat-header-label mr-1">Hold Reasons:</mat-label>
                <div class="d-flex flex-wrap ml-3 mt-1">
                  <span *ngFor="let data of holdeReasonItems; let i = index" [matTooltip]="data" class="rounded-border text-center p-1 insurance-type-label mr-1">
                    {{ data }}
                  </span>
                  <span class="rounded-border p-1 text-center label-active pointer" *ngIf="holdeReasonshowMore" (click)="toggleShowMoreReason()">
                    More...
                  </span>
                  <span *ngIf="holdeReasonshowLess" (click)="toggleShowMoreReason()" class="rounded-border p-1 text-center label-active pointer">
                    Less...
                  </span>

                </div>
              </div>

              <div class="justify-content-between mt-1" *ngIf="diagnosis?.length != 0">
                <mat-label class="cus-mat-header-label mr-1">Diagnosis:</mat-label>
                <div class="d-flex flex-wrap ml-3 mt-1">
                  <span *ngFor="let diag of visibleItems; let i = index" [matTooltip]="diag" class="rounded-border text-center p-1 insurance-type-label mr-1">
                    {{ diag }}
                  </span>
                  <span class="rounded-border p-1 text-center label-active pointer" *ngIf="showMore" (click)="toggleShowMore()">
                    More...
                  </span>
                  <span *ngIf="showLess" (click)="toggleShowMore()" class="rounded-border p-1 text-center label-active pointer">
                    Less...
                  </span>

                </div>
              </div>
              <mat-divider class="mt-2" *ngIf="invoiceTableDataV1?.length >= 1"></mat-divider>
              <div *ngIf="invoiceTableDataV1?.length >= 1">

                <div class="card card-body mt-2 p-1 curved-card" *ngIf="!isTableLoading">
                  <div class="d-flex justify-content-between align-items-center header-text">
                    <p class="mb-0">
                      <img class="img-responsive" src="assets/Billing Icons/service.png" height="18" />
                      <span class="ml-2 header-text"> Invoice Split</span>
                    </p>
                  </div>

                  <div class="table table-responsive">
                    <table datatable [dtOptions]="orderInvoicesTable" class="display row-border hover w-100 table">
                      <thead>
                        <tr>
                          <th [matTooltip]="'Insurance'">Insurance</th>
                          <th [matTooltip]="'Invoice No'">Invoice No</th>
                          <th [matTooltip]="'Billed ($)'" class="text-right">Billed ($)</th>
                          <th [matTooltip]="'Allowed ($)'" class="text-right">Allowed ($)</th>
                          <th [matTooltip]="'Paid ($)'" class="text-right">Paid ($)</th>
                          <th [matTooltip]="'Adjustments/Accessories  ($)'" class="text-right">Adjustments/Accessories ($)</th>
                          <th [matTooltip]="'Total Balance ($)'" class="text-right">Total Balance ($)</th>
                        </tr>
                      </thead>
                      <tbody>
                        <!-- [ngClass]="this.selectedInvoice === invoice?.payorLevel ? 'row-highlighted' : '' " (click)="fnSelectInvoice(invoice)" -->
                        <tr *ngFor="let invoice of invoiceTableDataV1"
                          [ngStyle]="{'background-color': invoice.payorLevel === 'Total' ? 'silver' : ''}">

                          <td [matTooltip]="invoice?.payorLevel || '-'">
                            <div
                              [innerHTML]="invoice.payorLevel === 'Total' ? '<b>' + (invoice?.payorLevel || '-') + '</b>' : (invoice?.payorLevel || '-')">
                            </div>
                          </td>
                          <td [matTooltip]="invoice?.defaultInvoiceCode || '-'">
                            <div
                              [innerHTML]="invoice.payorLevel === 'Total' ? '<b>' + (invoice?.defaultInvoiceCode || '-') + '</b>' : (invoice?.defaultInvoiceCode || '-')">
                            </div>
                          </td>
                          <td [matTooltip]="invoice?.billed || '-'" class="text-right">
                            <div
                              [innerHTML]="invoice.payorLevel === 'Total' ? '<b>' + (invoice?.billed || '-') + '</b>' : (invoice?.billed || '-')">
                            </div>
                          </td>
                          <td [matTooltip]="invoice?.allowed || '-'" class="text-right">
                            <div
                              [innerHTML]="invoice.payorLevel === 'Total' ? '<b>' + (invoice?.allowed || '-') + '</b>' : (invoice?.allowed || '-')">
                            </div>
                          </td>
                          <td [matTooltip]="invoice?.insurancePaid || '-'" class="text-right">
                            <div
                              [innerHTML]="invoice.payorLevel === 'Total' ? '<b>' + (invoice?.insurancePaid || '-') + '</b>' : (invoice?.insurancePaid || '-')">
                            </div>
                          </td>
                          <td [matTooltip]="invoice?.adjustAmount || '-'" class="text-right">
                            <div
                              [innerHTML]="invoice.payorLevel === 'Total' ? '<b>' + (invoice?.adjustAmount || '-') + '</b>' : (invoice?.adjustAmount || '-')">
                            </div>
                          </td>
                          <td [matTooltip]="invoice?.totBalance || '-'" class="text-right">
                            <div
                              [innerHTML]="invoice.payorLevel === 'Total' ? '<b>' + (invoice?.totBalance || '-') + '</b>' : (invoice?.totBalance || '-')">
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div *ngFor="let item of ltItems; index as i">
                <mat-divider class="mt-2"></mat-divider>
                <div class="text-patient-name mt-3">
                  <img class="img-responsive" src="assets/Billing Icons/service.png" height="18" /> <a [class.disabled]="false" class="preview-link ml-2 header-color header-text">Service Line {{ i+1 }} : </a>
                  <span [matTooltip]="'Rental'" class="rounded-border text-center p-1 mr-1 rental-label"
                    *ngIf="item.isRental">
                    Rental
                  </span>
                  <a *ngIf="item.isRental" [class.disabled]="false" class="preview-link "
                    [matTooltip]="'PERIOD ' + (item.rentalMonths || '0') + ' | MONTHLY'"> PERIOD {{ item.rentalMonths ||
                    '0' }} | MONTHLY
                  </a>
                </div>

                <div class="d-flex justify-content-between">
                  <div class="col-2 mt-1 p-1">
                    <mat-label class="text-facility">DOS:</mat-label>
                    <!-- d-flex justify-content-between align-items-center  -->
                    <div class="cus-text-label" [matTooltip]="item.dtDOS || '-'">
                      {{ item.dtDOS || '-' }}
                    </div>
                  </div>
                  <div class="col-2 mt-1 p-1">
                    <mat-label class="text-facility">POS:</mat-label>
                    <!-- d-flex justify-content-between align-items-center  -->
                    <div class="cus-text-label" [matTooltip]="item.posNumber || '0' ">
                      {{ item.posNumber || '0' }}
                    </div>
                  </div>
                  <div class="col-2 mt-1 p-1">
                    <mat-label class="text-facility">HCPCS:</mat-label>
                    <!-- d-flex justify-content-between align-items-center  -->
                    <div class="cus-text-label" [matTooltip]="item.hcpcCode || '-'">
                      {{ item.hcpcCode || '-' }}
                    </div>
                  </div>
                  <div class="col-2 mt-1 p-1">
                    <mat-label class="text-facility">Modifiers:</mat-label>
                    <!-- d-flex justify-content-between align-items-center  -->
                    <div class="cus-text-label" [matTooltip]="item.modifiers || '-'">
                      {{ item.modifiers || '-' }}
                    </div>
                  </div>
                  <div class="col-2 mt-1 p-1">
                    <mat-label class="text-facility">Diagnosis:</mat-label>
                    <!-- d-flex justify-content-between align-items-center  -->
                    <div>
                      <app-read-more><span class="cus-text-label" [matTooltip]="item.diagnosisCode || '-'">{{ item.diagnosisCode || '-' }}</span></app-read-more>
                    </div>
                  </div>

                  <!-- <div class="col-1 mt-1 p-1">
                <mat-label class="text-facility" >Qty:</mat-label>
                <div class="d-flex justify-content-between align-items-center cus-text-label" [matTooltip]="item.quantity || '0'">
                  {{ item.quantity || '0' }}
                </div>
              </div> -->
                </div>


                <div class="d-flex justify-content-between">
                  <div class="col-2 p-1">
                    <mat-label class="text-facility">POS Name:</mat-label>
                    <!-- d-flex justify-content-between align-items-center  -->
                    <div class="cus-text-label" [matTooltip]="item.posName || '-'">
                      {{ item.posName || '-' }}
                    </div>
                  </div>
                  <div class="col-2 p-1">
                    <mat-label class="text-facility">Item:</mat-label>
                    <!-- d-flex justify-content-between align-items-center  -->
                    <div class="cus-text-label" [matTooltip]="item.productName || '-'">
                      {{ item.productName || '-' }}
                    </div>
                  </div>

                  <div class="col-2 p-1">
                    <mat-label class="text-facility">Deductible:</mat-label>
                    <!-- d-flex justify-content-between align-items-center  -->
                    <div class="cus-text-label" [matTooltip]="item.deductible || '-'">
                      {{ item.deductible || '-' }}
                    </div>
                  </div>

                  <div class="col-2 p-1">
                    <mat-label class="text-facility">Copay:</mat-label>
                    <!-- d-flex justify-content-between align-items-center  -->
                    <div class="cus-text-label" [matTooltip]="item.copay || '-'">
                      {{ item.copay || '-' }}
                    </div>
                  </div>

                  <div class="col-2 p-1">
                    <mat-label class="text-facility" [matTooltip]="'Item:'">Coinsurance:</mat-label>
                    <!-- d-flex justify-content-between align-items-center  -->
                    <div class="cus-text-label" [matTooltip]="item.coinsurance || '-'">
                      {{ item.coinsurance || '-' }}
                    </div>
                  </div>
                </div>


                <div class="card card-body mt-2 p-2 head footerCls cardCustom">
                  <div class="d-flex justify-content-between">
                    <div class="col-2">
                      <p class="mb-0 cus-text-label">Charge : <i class="fa fa-usd" aria-hidden="true"></i> {{
                        item.charges || '0' }}</p>
                    </div>
                    <mat-divider [vertical]="true"></mat-divider>
                    <div class="col-2">
                      <p class="mb-0 cus-text-label">Allowed : <i class="fa fa-usd" aria-hidden="true"></i> {{
                        item.allowed || '0' }}</p>
                    </div>
                    <mat-divider [vertical]="true"></mat-divider>
                    <div class="col-2">
                      <p class="mb-0 cus-text-label">Adjustment : <i class="fa fa-usd" aria-hidden="true"></i> {{
                        item.adjustments || '0' }}
                      </p>
                    </div>
                    <mat-divider [vertical]="true"></mat-divider>
                    <div class="col-2">
                      <p class="mb-0 cus-text-label">Payment <i class="fa fa-usd" aria-hidden="true"></i> {{
                        item.payments || '0' }}</p>
                    </div>
                    <mat-divider [vertical]="true"></mat-divider>
                    <div class="col-2">
                      <p class="mb-0 cus-text-label">Balance : <i class="fa fa-usd" aria-hidden="true"></i> {{
                        item.balance || '0' }}</p>
                    </div>
                  </div>

                </div>

              </div>
            </div>
          </div>

        </div>
        </div>
      </div>
    </div>
  </div>

</div>

<div class="row" *ngIf="isLoading">
  <div class="col-12">
    <div class="d-flex justify-content-center">
      <img class="img-responsive pb-2 mr-1" src="assets/gifs/Infinity-loader.gif" />
    </div>
  </div>
</div>
