 <!-- <div class="text-center">
    <span>
        Hi {{loginUserName}} 👋
        <button class="text-right close" aria-label="Close" (click)="close()"> X</button>
    </span>
</div>
<span>
    Patient Name : {{patientName}}
    <br>
    Reminder  🔔 : {{reminderSubject}}
</span>  -->

<div class="notifications">
  <div *ngFor="let reminder of reminders" class="notification" [ngClass]="className(reminder)">
    <ng-container *ngTemplateOutlet="remindersTpl;context:{reminder:reminder}"></ng-container>
  </div>
</div>

<ng-template #remindersTpl let-reminder="reminder">
  <div class="row title">
    <div class="col-10">{{reminder.title}}</div>
    <button mat-icon-button class="col-2" (click)="closeReminder(reminder)" matTooltip="Close the Reminder"
      matTooltipPosition="before">
      <mat-icon>cancel</mat-icon>
    </button>
  </div>
  <div class="message">
    <span>
      Patient Name : {{reminder.message.patientName}}
      <br>
      Reminder 🔔 : {{reminder.message.subject}}
    </span>
  </div>
</ng-template>
