<div class="row">
    <div class="col-12">
      <div class="table table-responsive mt-3">
        <table id="tblinviteUser" datatable [dtOptions]="dtOptionsInviteUser" class="tbl hover row-border hover w-100 display">
          <thead>
            <tr>
                <th><mat-icon>apps</mat-icon></th>
              <th [matTooltip]="'Name'">Name</th>
              <th [matTooltip]="'Email Address'">Email Address</th>
              <th [matTooltip]="'Role Name'">Role Name</th>
              <th [matTooltip]="'Branch Name'">Branch Name</th>
              <th [matTooltip]="'Created Date'">Created Date</th>
              <th [matTooltip]="'Expire Date Time'">Expire Date Time</th>
              <th [matTooltip]="'Status'">Status</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let datas of inviteUserList">
              <td>
              
                <div
                  *ngIf="
                    datas.inviteStatus == 'Invite Sent' ||
                      datas.inviteStatus == 'Invite Resent';
                    then content;
                    else other_content
                  "
                >
                  here is ignored
                </div>
                <ng-template #content>
                  <a
                    *ngIf="isShownUpdateIconForUser"
                    (click)="ViewButtonHitted(datas)"
                    ><i
                      class="fa fa-pencil pointer editBtnColor mr-3"
                      matTooltip="Click To Edit"
                    ></i
                  ></a>
                </ng-template>
                <ng-template #other_content>
                  <a
                    *ngIf="
                      datas.inviteStatus == 'Invite Expired' &&
                      isShownDeleteIconForUser
                    "
                    class="deleteBtnColor mr-3"
                  >
                    <i
                      (click)="deleteInviteUsersClicked(datas.id)"
                      class="fa fa-trash pointer"
                      matTooltip="Click To Delete"
                    ></i>
                  </a>
                  <a
                    *ngIf="
                      datas.inviteStatus == 'Invite Expired' &&
                      isShownUpdateIconForUser
                    "
                    class="BtnColor mr-3"
                  >
                    <i
                      (click)="ResendInviteUsersClicked(datas)"
                      class="fa fa-paper-plane"
                      matTooltip="Click To Resend Mail"
                    ></i>
                  </a>
                  <a
                    *ngIf="datas.inviteStatus == 'Registration Completed'"
                    class="roleBtnColor font-size-19 mr-5"
                  >
                  </a>
                </ng-template>
              </td>

              <td [matTooltip]="datas?.name || '-'"
              (dblclick)="onRowDoubleClickV1(datas?.name || '-')">
                {{ datas?.name !== null && datas?.name !== undefined ? datas?.name : '-' }}
              </td>

              <td [matTooltip]="datas?.emailAddress || '-'"
              (dblclick)="onRowDoubleClickV1(datas?.emailAddress || '-')">
                {{ datas?.emailAddress !== null && datas?.emailAddress !== undefined ? datas?.emailAddress : '-' }}
              </td>

              <td [matTooltip]="datas?.roleName || '-'"
              (dblclick)="onRowDoubleClickV1(datas?.roleName || '-')">
                {{ datas?.roleName !== null && datas?.roleName !== undefined ? datas?.roleName : '-' }}
              </td>

              <td [matTooltip]="datas?.organizationUnitName || '-'"
              (dblclick)="onRowDoubleClickV1(datas?.organizationUnitName || '-')">
                {{ datas?.organizationUnitName !== null && datas?.organizationUnitName !== undefined ? datas?.organizationUnitName : '-' }}
              </td>
              
              <td class="text-center" [matTooltip]="getFormattedDate(datas?.createdDateTime)"
              (dblclick)="onRowDoubleClickV1(getFormattedDate(datas?.createdDateTime))">{{ datas?.createdDateTime?(datas?.createdDateTime | date : "MM/dd/yyyy"
                :
                "en_US"):'_' }}
              </td>

              <td class="text-center" [matTooltip]="getFormattedDate(datas?.expireByDateTime)"
              (dblclick)="onRowDoubleClickV1(getFormattedDate(datas?.expireByDateTime))">{{ datas?.expireByDateTime?(datas?.expireByDateTime | date : "MM/dd/yyyy"
                :
                "en_US"):'_' }}
              </td>
              <td [matTooltip]="datas?.inviteStatus || '-'"
              (dblclick)="onRowDoubleClickV1(datas?.inviteStatus || '-')">
                {{ datas?.inviteStatus !== null && datas?.inviteStatus !== undefined ? datas?.inviteStatus : '-' }}
              </td>
            </tr>
          </tbody>
          <tfoot>
            <tr>
                <th><mat-icon>apps</mat-icon></th>
                <th>
                    <input matInput class="textbox-border-class" type="text" placeholder="Name"
                        name="search-Name" />
                </th>
                <th>
                    <input matInput type="text" class="textbox-border-class" placeholder="Email Address"
                        name="search-EmailAddress" />
                </th>
                <th>
                    <input matInput type="text" class="textbox-border-class" placeholder="Role Name"
                        name="search-RoleName" />
                </th>
                <th>
                    <input matInput type="text" class="textbox-border-class" placeholder="Branch Name"
                        name="search-BranchName" />
                </th>
                <th>
                    <input matInput type="text" class="textbox-border-class" placeholder="Created Date"
                        name="search-CreatedDate" />
                </th>
                <th>
                    <input matInput type="text" class="textbox-border-class" placeholder="Expire Date Time"
                        name="search-ExpireDateTime" />
                </th>
                <th>
                    <input matInput type="text" class="textbox-border-class" placeholder="Status"
                        name="search-Status" />
                </th>
            </tr>
        </tfoot>
        </table>
      </div>
    </div>
  </div>