<meta http-equiv="Cache-control" content="no-cache" />
<div class="container-fluid">
    <div class="row align-items-center">
        <div class="col-lg-12">
            <h3 class="customThemeClass mt-2">
                <b>View Document Image <span *ngIf="defaultFaxId !== '-' "><b>- {{ defaultFaxId }}</b> </span></b>
            </h3>
        </div>
    </div>
    
    
    <div class="row bg">
        <div class="col-lg-12">
            <div class="d-flex align-items-center justify-content-between">
                <h3 class="font-size-18">
                    <b>Sorting / Sorted Documents</b>
                </h3>
                <h3 class="font-size-18">
                    <b>Chart Id:</b>&nbsp;<b class="text-danger">{{
                        chartId ? chartId : '-'
                        }}</b>
                </h3>
                <!-- <h3 class="mb-0 font-size-18">
                    <b>Patient Id:</b>&nbsp;<b class="text-danger">{{
                        defaultPatientId ? defaultPatientId : '-'
                        }}</b>
                </h3> -->
                <h3 class="font-size-18">
                    <b>Patient Name:</b>&nbsp;<b class="text-danger">{{ patientName ? patientName : '-' }}</b>
                </h3>

                <div class="row">
                    <div class="col-12">
                <button class="buttonColor p-2 dd-text mr-1" [disabled]="isEmpty(blobName) || isEmpty(docId) || isShowSpinner" mat-raised-button matTooltip="Download Patient Document" [matTooltipDisabled]="isEmpty(blobName) || isEmpty(docId)" (click)="patientDocPdfDownload()">
                    <i class="fa fa-download mr-1" aria-hidden="true"></i> <span *ngIf="!isShowSpinner" class="dd-text">Document</span> <span *ngIf="isShowSpinner" class="dd-text">Downloading</span>
                    <mat-icon *ngIf="isShowSpinner">
                        <mat-spinner class="spinner-border-sm ml-1" diameter="20"></mat-spinner>
                    </mat-icon>
                </button>

                <button class="buttonColor p-2 dd-text mr-1" [disabled]="isEmpty(blobName) || isEmpty(docId) || isShowSpinner" mat-raised-button matTooltip="View Document in PDF" [matTooltipDisabled]="isEmpty(blobName) || isEmpty(docId)" (click)="viewPatientBlobPDF()">
                    <i class="fa fa-eye" aria-hidden="true"></i> <span class="dd-text ml-1">View Document in PDF</span>
                </button>
            </div>
        </div>
            </div>
        </div>
    </div>

    <app-common-sorting (headerText)="BindHearderText($event)" [docId]="docId" *ngIf="!isLoading"></app-common-sorting>
</div>