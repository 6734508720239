import { Injectable } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import moment from 'moment';

// import statements
// inject module here
@Injectable({
  providedIn: 'root'
})

// export class for date validation
export class DateValidator {

  //  custom validator to check date validation
  dateVaidator(AC: AbstractControl): { dateVaidator: boolean; msg: string; } | null {
    if (AC && AC?.value) {
      const strDate: string = String(AC?.value)?.trim();
      const formats: string[] = ['MM-DD-YYYY', 'MM/DD/YYYY', 'M-D-YY', 'M/D/YY', 'M-D-YYYY', 'M/D/YYYY'];
      if (strDate && !moment(strDate, formats, true).isValid()) {
        return { 'dateVaidator': true, msg: "Please enter valid date" };
      }
      if (strDate && !moment(strDate, formats, true).isValid()) {
        return { 'dateVaidator': true, msg: "Please enter valid date" };
      }
      return null;
    }
    return null;
  }
}
