import { PagedAndSortedResultRequestDto } from '@abp/ng.core';
import { DatePipe } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, Inject, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Store } from '@ngxs/store';
import { WindowRef } from 'angular-disable-browser-back-button';
import { AddNotesComponent } from 'projects/patient/src/app/add-notes/add-notes.component';
import { PatientDropdowns } from 'projects/patient/src/app/dropdown-proxy/dropdown-management/dropdowns';
import { NotesSeverityDTO, NotesStatusDTO, NotesTypeDTO, PatientMasterDropdownDTO } from 'projects/patient/src/app/dropdown-proxy/dropdown-management/dropdowns/dto/patient';
import { PatientMasterDropdownService } from 'projects/patient/src/app/dropdown-proxy/dropdown-management/dropdowns/master-dropdown-service';
import { NotesSearchService, PatientService } from 'projects/patient/src/app/patient-proxy/patient';
import { getPatient } from 'projects/patient/src/app/patient-store/patient.action';
import { TableService } from 'projects/shared/src/app/table.service';
import { DateValidator } from 'projects/shared/src/app/validations/date-validator';
import { Subscription } from 'rxjs';
import Swal from 'sweetalert2';
import { MasterNotesDTO } from '../order-proxy/order-management/inventory/dto/models';
import { MasterNotesService } from '../order-proxy/order-management/inventory/master-notes.service';
import { PatientDTO, PatientLockDTO } from '../order-proxy/patient/dto';

@Component({
  selector: 'app-order-notes',
  templateUrl: './order-notes.component.html',
  styleUrls: ['./order-notes.component.scss'],
  providers: [WindowRef, DatePipe]
})
export class OrderNotesComponent implements OnInit, OnDestroy {
  @Output() patientIdEmitter: EventEmitter<PatientDTO> = new EventEmitter<PatientDTO>(null);
  public strPageType: string = "note";
  subscription$: Subscription[] = [];
  @Input() patientID: string = '';
  statuses: NotesStatusDTO[] = [];
  drpseverity: NotesSeverityDTO[] = []
  tblNotesReponse: any[] = [];
  private window: Window;
  NotesData: searchNotesDTO[] = [];
  notesForm: FormGroup;
  isLoading: boolean = true;
  @ViewChild(MatSort, { static: false }) sort: MatSort = new MatSort();
  @ViewChild('noteTbSort', { static: false }) noteTbSort: MatSort;
  @ViewChild('MatPaginator', { static: false }) paginator: MatPaginator;
  lstNotesType: any = [];
  dataSource = new MatTableDataSource(this.NotesData);
  isShownUpdateIconForNotes: boolean = true;
  isDeleteForNotes: boolean = true;
  displayedColumns: string[] = ['Options',"defaultNotesId", "createdBy", "dateCreated", "actualDate", "noteType", "noteReason", "dateNeeded", "subject", ];
  drpState: MasterNotesDTO[] = [];
  constructor(
    private store: Store, private datepipe: DatePipe,
    public dialog: MatDialog,
    private notesDialogRef: MatDialogRef<any>, @Inject(MAT_DIALOG_DATA) private data: { patientID: string, notesId?: string },
    private Table: TableService, private deleteSerivice: PatientService, private formBuilder: FormBuilder, private windowRef: WindowRef,
    private dateValidator: DateValidator, private patientDropdownService: PatientMasterDropdownService, private notesSearchService: NotesSearchService,
    private notesService: MasterNotesService

  ) {
    this.window = this.windowRef.nativeWindow;
    this.notesForm = this.formBuilder.group({
      notesId: '',
      defaultNotesId: '',
      createdBy: '',
      dateCreated: new FormControl("", this.dateValidator.dateVaidator),
      actualDate: new FormControl("", this.dateValidator.dateVaidator),
      noteType: '',
      noteReason: '',
      dateNeeded: new FormControl("", this.dateValidator.dateVaidator),
      status: '',
      subject: ""
    })

    const valueChanges: Subscription = this.notesForm.valueChanges.subscribe((value: searchNotesDTO) => {
      const filter: searchNotesDTO = {
        notesId: value.defaultNotesId?.trim()?.toLowerCase(),
        defaultNotesId: value.defaultNotesId?.trim()?.toLowerCase(),
        createdBy: value.createdBy.trim()?.toLowerCase(),
        dateCreated: value.dateCreated?.trim()?.toLowerCase(),
        actualDate: value.actualDate?.trim()?.toLowerCase(),
        dateNeeded: value.dateNeeded?.trim()?.toLowerCase(),
        noteType: value.noteType?.trim()?.toLowerCase(),
        noteReason: value.noteReason?.trim()?.toLowerCase(),
        status: value.status?.trim()?.toLowerCase(),
      };
      if (this.notesForm.valid) {
        this.dataSource.filter = JSON.stringify(filter);
        this.filtertable(filter);
      }
    }, err => {
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    });
    this.subscription$.push(valueChanges);




  }
  isPatientLockedByCurrentUser: boolean = true;
  strLoginUserId: string = '';
  ngOnInit(): void {
    this.patientID = this.data?.patientID ?? "";
    this.isLoading = true;
    const loginUserId = this.Table.getLoginUserId().subscribe(val => {
      this.strLoginUserId = val;
    })
    this.subscription$.push(loginUserId);
    //
    this.getDropdown();
    this.dataSource = new MatTableDataSource();
    this.sort.disableClear = true;
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }
  //
  ngOnDestroy(): void {
    this.subscription$?.forEach((sub) => {
      sub && sub?.unsubscribe();
    });
  }

  filtertable(filter) {
    const notes = {
      patientId: this.patientID,
      notesId: filter.defaultNotesId,
      defaultNotesId: filter.defaultNotesId,
      createdBy: filter.createdBy,
      dateCreated: filter.dateCreated,
      actualDate: filter.actualDate,
      dateNeeded: null,
      noteType: filter.noteType,
      noteReason: filter.noteReason,
      status: filter.status,
    }
    this.NotesData = [];
    const notesSearch = this.notesSearchService.searchNotesByInput
      (notes).subscribe(data => {
        this.NotesData = [];
        data && data?.items?.forEach(element => {
          this.NotesData.push({
            notesId: element?.notesId,
            createdBy: element?.createdBy,
            defaultNotesId: element?.defaultNotesId,
            dateCreated: element?.dateCreated,
            actualDate: element?.actualDate,
            noteType: element?.noteType,
            status: element?.status,
            noteReason: element?.noteReason,
            dateNeeded: element?.dateNeeded,
          //  subject: this.drpState?.filter(n => n?.id === element?.subject)[0]?.subject ?? ""

          });
          this.isLoading = false;
          this.NotesData = this.NotesData.sort((a, b) => new Date(b?.dateCreated)?.getTime() - new Date(a?.dateCreated)?.getTime());

          this.dataSource = new MatTableDataSource(this.NotesData);
          this.sort.disableClear = true;
          this.dataSource.sort = this.sort;
          this.dataSource.paginator = this.paginator;
        })
      }, error => {
        this.isLoading = false;
        this.dataSource = new MatTableDataSource(this.NotesData);
        this.sort.disableClear = true;
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
      })
    this.subscription$.push(notesSearch);
  }
  getTableData(patientID: string) {

    this.isLoading = true;
    let patientDropdownInput: PatientDropdowns[];
    patientDropdownInput = [PatientDropdowns.NoteTypes]
    this.NotesData = [];
    const getPatientDropdown = this.patientDropdownService.getPatientDropdownsByInput(patientDropdownInput).subscribe((stateResponse) => {
      const response: PatientMasterDropdownDTO = stateResponse;
      this.lstNotesType = response.lstNotesType;
      let notesDetails: PatientDTO;
      this.store.dispatch(new getPatient(patientID)).subscribe(response => {
        notesDetails = response?.patientState?.selectedPatient;
        const patientLock: PatientLockDTO = response?.patientState?.selectedPatient?.patientLock
        // Patient is locked & userId is not equal to loginUserId or patient is unlocked
        this.isPatientLockedByCurrentUser = (patientLock?.isLocked == true &&
          patientLock?.userId == this.strLoginUserId) ||
          (patientLock?.isLocked == false) ? true : false;
        this.NotesData = [];
        notesDetails?.notes?.forEach(element => {
          this.NotesData.push({
            notesId: element?.notesId,
            createdBy: element?.createdBy,
            defaultNotesId: element?.defaultNotesId,
            dateCreated: element?.dateCreated,
            actualDate: element?.actualDate,
            noteType: element?.noteType,
            status: element?.status,
            noteReason: element?.noteReason,
            dateNeeded: element?.dateNeeded,
           // subject: this.drpState?.filter(n => n?.id === element?.subject)[0]?.subject ?? ""


          });
        })
        this.isLoading = false;
        this.NotesData = this.NotesData.sort((a, b) => new Date(b?.dateCreated)?.getTime() - new Date(a?.dateCreated)?.getTime());

        this.dataSource = new MatTableDataSource(this.NotesData);
        this.sort.disableClear = true;
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
      }, error => {
        const data: HttpErrorResponse = error;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
        this.isLoading = false;
        this.dataSource = new MatTableDataSource(this.NotesData);
        this.sort.disableClear = true;
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
      });
    });
    this.subscription$.push(getPatientDropdown);
    //
    this.isLoading = true;
  }


  deleteNotes(noteId: string) {
    Swal.fire({
      title: 'Are you sure you want to Delete?',
      text: 'You won\'t be able to retrieve this data!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#34c38f',
      cancelButtonColor: '#f46a6a',
      confirmButtonText: 'Yes, delete it!'
    }).then(result => {
      if (result.value) {
        //
        const deleteNotes = this.deleteSerivice.deleteNotes(this.patientID, noteId).subscribe(response => {
          this.NotesData.forEach(element => {
            if (noteId == element?.notesId) {
              const index = this.NotesData.indexOf(element, 0)
              this.NotesData.splice(index, 1)
            }
          });
          this.getTableData(this.patientID)
          // this.resetUsers();
        }, err => {
          const data: HttpErrorResponse = err;
          Swal.fire({
            icon: 'info',
            text: data?.error?.error?.message,
          });
        });
        this.subscription$.push(deleteNotes);
      }
    });
  }



  drpNoteType: NotesTypeDTO[];
  getDropdown() {
    let patientDropdownInput: PatientDropdowns[];
    patientDropdownInput = [
      PatientDropdowns.NoteTypes,
      PatientDropdowns.NoteStatus,
      PatientDropdowns.NoteSeverities
    ]
    const patientDropdown = this.patientDropdownService.getPatientDropdownsByInput(patientDropdownInput).subscribe((stateResponse) => {
      const response: PatientMasterDropdownDTO = stateResponse;
      this.drpNoteType = response.lstNotesType;
      this.statuses = response?.notesStatuses;
      // this.drpState = response?.states;
      this.drpseverity = response?.notesSeverities;
      const notesListSubscription = this.notesService.getList(new PagedAndSortedResultRequestDto).subscribe(response => {
        this.drpState = response?.items ?? [];
      }, err => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      })
      if (this.patientID !== "") { this.getTableData(this.patientID); }
    }, err => {
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    })
    this.subscription$.push(patientDropdown);
  }

  openNoteModal() {
    const dialogRef = this.dialog.open(AddNotesComponent, {
      disableClose: true,
      data: { patientID: this.patientID }
    });

    const closeDialog = dialogRef.afterClosed().subscribe((result) => {
      result !== "" && result !== null && result !== undefined && this.patientIdEmitter.emit(result);
      this.getTableData(this.patientID);
    }, err => {
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    });
    this.subscription$.push(closeDialog);
  }
  ViewNotes(notesid?: any) {
    const dialogRef = this.dialog.open(AddNotesComponent, {
      data: { patientID: this.patientID, notesId: notesid }
    });

    const closeDialog = dialogRef.afterClosed().subscribe(() => {
      this.getTableData(this.patientID);
    }, err => {
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    });
    this.subscription$.push(closeDialog);
  }
}

export interface searchNotesDTO {
  notesId?: string;
  defaultNotesId?: string;
  createdBy?: string;
  dateCreated?: string;
  actualDate?: string;
  dateNeeded?: string;
  noteType?: string;
  noteReason?: string;
  status?: string;
  subject?: string
}
