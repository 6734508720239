import type { CreateUpdatePracticeManagementDTO, DrpPracticeManagementDTO, PatientFaxReportDTO, PracticeManagementDTO, SearchPracticeDTO } from './dto/models';
import { RestService } from '@abp/ng.core';
import type { PagedAndSortedResultRequestDto, PagedResultDto } from '@abp/ng.core';
import { Injectable } from '@angular/core';
import { ActiveStatus } from 'projects/admin/src/app/admin-proxy/platform-app-management/rcm/platform-management/localization/enumeration/active-status.enum';

@Injectable({
  providedIn: 'root',
})
export class PracticeManagementService {
  apiName = 'Default';

  create = (input: CreateUpdatePracticeManagementDTO) =>
    this.restService.request<any, PracticeManagementDTO>({
      method: 'POST',
      url: '/api/app/practice-management',
      body: input,
    },
    { apiName: this.apiName });

  delete = (id: string) =>
    this.restService.request<any, void>({
      method: 'DELETE',
      url: `/api/app/practice-management/${id}`,
    },
    { apiName: this.apiName });

  get = (id: string) =>
    this.restService.request<any, PracticeManagementDTO>({
      method: 'GET',
      url: `/api/app/practice-management/${id}`,
    },
    { apiName: this.apiName });

  getList = (input: PagedAndSortedResultRequestDto) =>
    this.restService.request<any, PagedResultDto<PracticeManagementDTO>>({
      method: 'GET',
      url: '/api/app/practice-management',
      params: { skipCount: input.skipCount, maxResultCount: input.maxResultCount, sorting: input.sorting },
    },
    { apiName: this.apiName });

    getListV1 = (input: PagedAndSortedResultRequestDto) =>
    this.restService.request<any, PagedResultDto<PracticeManagementDTO>>({
      method: 'GET',
      url: '/api/app/practice-management/active-list',
      params: { skipCount: input.skipCount, maxResultCount: input.maxResultCount, sorting: input.sorting },
    },
    { apiName: this.apiName });


  update = (id: string, input: CreateUpdatePracticeManagementDTO) =>
    this.restService.request<any, PracticeManagementDTO>({
      method: 'PUT',
      url: `/api/app/practice-management/${id}`,
      body: input,
    },
    { apiName: this.apiName });

  createPhysicianByIdAndSFirstNameAndSLastNameAndSNpi = (id: string, sFirstName: string, sLastName: string, sNpi: string) =>
    this.restService.request<any, PracticeManagementDTO>({
      method: 'POST',
      url: `/api/app/practice-management/${id}/create-physician`,
      params: { sFirstName, sLastName, sNpi },
    },
    { apiName: this.apiName });

  getReferralPracticeReportByIdAndDtStartDateAndDtEndDate = (id: string, dtStartDate: string, dtEndDate: string) =>
    this.restService.request<any, PracticeManagementDTO>({
      method: 'GET',
      url: `/api/app/practice-management/${id}/get-referral-practice-report`,
      params: { dtStartDate, dtEndDate },
    },
    { apiName: this.apiName });

  searchPracticeByInput = (input: SearchPracticeDTO) =>
    this.restService.request<any, PagedResultDto<PracticeManagementDTO>>({
      method: 'GET',
      url: '/api/app/practice-management/search-practice',
      params: { practiceName: input.practiceName, city: input.city, state: input.state, zipCode: input.zipCode, phoneNo: input.phoneNo, faxNo: input.faxNo, manager: input.manager, createdDate: input.createdDate, status: input.status },
    },
    { apiName: this.apiName });

    practiceReportByStatusAndMonthAndYear = (status: ActiveStatus, month: number, year: number) =>
    this.restService.request<any, PracticeManagementDTO[]>({
      method: 'POST',
      url: '/api/app/practice-management/practice-report',
      params: { status, month, year },
    },
    { apiName: this.apiName });

    practiceReportByStatus = (status: ActiveStatus,sSearch:string) =>
    this.restService.request<any, PracticeManagementDTO[]>({
      method: 'POST',
      url: '/api/app/practice-management/practice-report',
      params: { status ,sSearch},
    },
    { apiName: this.apiName });

    drpPractice = () =>
    this.restService.request<any, DrpPracticeManagementDTO[]>({
      method: 'POST',
      url: '/api/app/practice-management/drp-practice',
    },
    { apiName: this.apiName });

    // drpPracticeByDoctorIdByPhysiciansId = (physiciansId: string) =>
    // this.restService.request<any, DrpPracticeManagementDTO[]>({
    //   method: 'POST',
    //   url: `/api/app/practice-management/drp-practice-by-doctor-id/${physiciansId}`,
    // },
    // { apiName: this.apiName });
    drpPracticeByDoctorIdByPhysiciansId = (physiciansId: string) =>
    this.restService.request<any, DrpPracticeManagementDTO[]>({
      method: 'POST',
      url: `/api/app/doctor/drp-practice-by-doctor-id/${physiciansId}`,
    },
    { apiName: this.apiName });

    patientWiseFaxReportByIMonthAndIYearAndPracticeId = (iMonth: number, iYear: number, practiceId: string) =>
    this.restService.request<any, PatientFaxReportDTO[]>({
      method: 'POST',
      url: `/api/app/practice-management/patient-wise-fax-report/${practiceId}`,
      params: { iMonth, iYear },
    },
    { apiName: this.apiName });

  constructor(private restService: RestService) {}
}
