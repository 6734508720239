<div class=container-fluid>
  <div class="row">
    <div class="col-12 mt-2 d-flex justify-content-between">
      <h3 class="font-weight-bolder mb-0 d-flex align-items-center">
        CC Settlement
      </h3>
      <div class="page-title-box page-title-right mt-3">
        <ol class="breadcrumbs m-0 align-items-center">
          <form [formGroup]="settlementForm">
            <!-- <mat-form-field>
              <mat-label> Date Picker</mat-label>
              <input autocomplete="new-dtDOSFrom" formControlName="dtDate" matInput readonly type="text"
                [(value)]="fromDate" placeholder="(MM/DD/YYYY)" [matDatepicker]="dtDate" />
              <mat-datepicker-toggle matSuffix [for]="dtDate">
              </mat-datepicker-toggle>
              <mat-datepicker #dtDate> </mat-datepicker>
            </mat-form-field> -->

            <mat-form-field class="pr-3 dd-text"
                  [ngClass]="{'mat-form-field-invalid': settlementForm.get('txtStartDateValue').invalid || settlementForm.get('txtEndDateValue').invalid}">
                  <mat-label>Order Date Range<span class="asterisk">*</span></mat-label>
                  <mat-date-range-input [rangePicker]="picker">
                    <input matStartDate matInput [(ngModel)]="dOrderFromDate" placeholder="Start date"
                      formControlName="txtStartDateValue" (keydown)="addSlashToDate($event)" maxlength="10" />
                    <input matEndDate matInput (dateChange)="onDateChange($event)" [(ngModel)]="dOrderToDate"
                      placeholder="End date" formControlName="txtEndDateValue" (keydown)="addSlashToDate($event)"
                      maxlength="10" />
                  </mat-date-range-input>
                  <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                  <mat-date-range-picker #picker></mat-date-range-picker>
                  <mat-error *ngIf="settlementForm.get('txtStartDateValue').hasError('required')">Start date is
                    required</mat-error>
                  <mat-error
                    *ngIf="settlementForm.get('txtStartDateValue').invalid && !settlementForm.get('txtStartDateValue').hasError('required')">Invalid
                    start date</mat-error>

                  <mat-error *ngIf="settlementForm.get('txtEndDateValue').hasError('required')">End date is
                    required</mat-error>
                  <mat-error
                    *ngIf="settlementForm.get('txtEndDateValue').invalid && !settlementForm.get('txtEndDateValue').hasError('required')">Invalid
                    end date</mat-error>
                  <mat-error *ngIf="settlementForm.hasError('dateRangeError')">From date must be less than or equal to
                    To date</mat-error>
                </mat-form-field>


            <button mat-button class="buttonColor mr-3 ml-3"><mat-icon style="margin-right: 5px">check</mat-icon>
              Check Settlement
            </button>

            <!-- <button matTooltip="Click To Upload Manul XML" mat-button class="buttonColor font-size-10 mr-2"
              [disabled]="isXMLLoading" (click)="uploadFile.click()">Manual XML Upload <span class="asterisk">*</span>
              <mat-icon>cloud_upload</mat-icon>
              <mat-icon *ngIf="isXMLLoading">
                <mat-spinner class="spinner-border spinner-border-sm" diameter="20"></mat-spinner>
              </mat-icon>
            </button>

            <input autocomplete="new-documentFile" id="file-upload" type="file" class="hidden"
              (change)="onFileChange($event)" #uploadFile accept=".xml" style="display: none;"
              formControlName="fileUpload" /> -->

          </form>

        </ol>
      </div>

    </div>
  </div>

  <!-- First Table -->

  <div class="row">
    <div class="col-12 mt-2">
      <div class="row">
        <!-- Shipping List Table -->
        <div class="col-12">
          <div class="card card-body">
            <div class="d-flex justify-content-center" *ngIf="isLoadCCTableData">
              <!-- <mat-spinner></mat-spinner> -->
              <img class="img-responsive pb-2 mr-1" src="assets/gifs/Infinity-loader.gif" />
            </div>

            <div *ngIf="!isLoadCCTableData" class="table table-responsive">
              <table datatable [dtOptions]="dtRentalListOptions" class="row-border hover w-100 display">
                <thead>
                  <tr>
                    <th><mat-icon>apps</mat-icon></th>
                    <th [matTooltip]="'Chart No'">Chart No</th>
                    <th [matTooltip]="'First Name'">Patient Name</th>
                    <th [matTooltip]="'Order ID'" class="text-right">Order ID</th>
                    <th [matTooltip]="'Amount($)'" class="text-right">Amount($)</th>
                    <th [matTooltip]="'Approve ID'" class="text-right">Approve ID</th>
                    <th [matTooltip]="'Date'">Date</th>
                    <th [matTooltip]="'CSR'">CSR</th>
                    <th [matTooltip]="'Settled'" class="text-center">Settled</th>

                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let data of CCSettlementArray">
                    <td>
                      <button> </button>
                    </td>
                    <td>
                      <a [class.disabled]="isEmpty(data.patientId) || isEmpty(data.chartId)"
                        matTooltip="{{ data?.chartId || '-'}}" class="preview-link"
                        [routerLink]="'/patientCreateEditTab/' + data?.patientId" target="_blank">
                        {{ data?.chartId || '-'}}
                      </a>
                    </td>
                    <td [matTooltip]="data?.patientName || '-'">{{data.patientName}}</td>

                    <td  class="text-right" [matTooltip]="data?.mmRefOrderId || '-'"><a class="preview-link"
                      [class.disabled]="isEmpty(data.patientId) || isEmpty(data.orderId)"
                      [routerLink]="'/orders/saleOrder/' +data.patientId +'/' +data.orderId+'/'+'0'" target="_blank">
                      {{data?.mmRefOrderId || '-'}}
                    </a></td>
                    <td class="text-right" [matTooltip]="data?.patientRespAmount || '-'">{{data.patientRespAmount}}</td>
                    <td class="text-right" [matTooltip]="data?.paypalTransId || '-'">{{data.paypalTransId}}</td>
                    <td [matTooltip]="data?.paypalPaidDate || '-'">{{data.paypalPaidDate}}</td>
                    <td [matTooltip]="data?.csrName || '-'">{{data.csrName}}</td>
                    <td class="text-center"><mat-checkbox  ngModel></mat-checkbox></td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <!-- Notes Details -->

      </div>
    </div>
  </div>

  <!-- Second Table -->

  <div class="row" [hidden]="true">
    <div class="col-12 mt-4">
      <div class="row">
        <!-- Shipping List Table -->
        <div class="col-12">
          <div class="card card-body">
            <div class="d-flex justify-content-center" *ngIf="!isTableLoaded">
              <!-- <mat-spinner></mat-spinner> -->
              <img class="img-responsive pb-2 mr-1" src="assets/gifs/Infinity-loader.gif" />
            </div>

            <div *ngIf="isTableLoaded" class="table table-responsive">
              <!-- <table datatable [dtOptions]="dtRentalItemListOptions" class="row-border hover w-100 display">
                <thead>
                  <tr>
                    <th [matTooltip]="'Transaction ID'">Transaction ID</th>
                    <th [matTooltip]="'Date'">Date</th>
                    <th [matTooltip]="'Type'">Type</th>
                    <th [matTooltip]="'ACCT'">ACCT</th>
                    <th [matTooltip]="'Amount($)'" class="text-right">Amount($)</th>
                    <th [matTooltip]="'Result'">Result</th>
                    <th [matTooltip]="'Message'">Message</th>
                    <th [matTooltip]="'CSR'">CSR</th>
                    <th [matTooltip]="'Paypal TR ID'" class="text-right">Paypal TR ID</th>
                    <th [matTooltip]="'Order'">Order</th>
                    <th [matTooltip]="'Settled'" class="text-right">Settled</th>


                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let data of CCSettlementArrayItem">
                    <td [matTooltip]="data?.id || '-'">{{data.id}} </td>
                    <td [matTooltip]="data?.date || '-'">{{data.date}}</td>
                    <td [matTooltip]="data?.type || '-'">{{data.type}}</td>
                    <td [matTooltip]="data?.acct || '-'">{{data.acct}}</td>
                    <td class="text-right" [matTooltip]="data?.amount || '-'">{{data.amount}}</td>
                    <td [matTooltip]="data?.result || '-'">{{data.result}}</td>
                    <td [matTooltip]="data?.mesg || '-'">{{data.mesg}}</td>
                    <td [matTooltip]="data?.CSR || '-'">{{data.CSR}}</td>
                    <td class="text-right" [matTooltip]="data?.paypalId || '-'">{{data.paypalId}}</td>
                    <td [matTooltip]="data?.orderId || '-'">{{data.orderId}}</td>
                    <td class="text-right"><mat-checkbox></mat-checkbox></td>

                  </tr>
                </tbody>
              </table> -->
            </div>
          </div>
        </div>
        <!-- Notes Details -->

      </div>
    </div>
  </div>
</div>
