<div class="col-12" style="margin-top: -17px; margin-bottom: -30px">
    <div class="d-flex align-items-center justify-content-between m-0">
        <div class=" page-title-box  page-title-right mt-4 mb-0">
            <ol class="breadcrumbs m-0">
            </ol>
        </div>
    </div>
</div>
<br />

<div class="card card-body">
    <mat-accordion>
        <mat-expansion-panel [expanded]="step">
            <mat-expansion-panel-header>
                <mat-panel-title class="customThemeClass">
                    <ng-container>
                        <b>New Schedule</b>
                    </ng-container>

                </mat-panel-title>
            </mat-expansion-panel-header>
            <form [formGroup]="newScheduleForm">
                <div class="row">
                    <div class="col-lg-12">
                        <mat-card-content>
                            <h4 class="customThemeClass"><b>Patient Encounter Info :</b></h4>
                            <div class="row">
                                <mat-form-field class="col-3">
                                    <mat-label>Patient<span class="asterisk">*</span></mat-label>
                                    <input autocomplete="new-txtPatientName" matInput maxlength="40" formControlName="txtPatientName"
                                        placeholder="Enter Patient Name" type="text" />
                                    <mat-error *ngIf="
                                    newScheduleForm?.get('txtPatientName')?.touched &&
                                    newScheduleForm?.get('txtPatientName')?.errors?.required
                                            ">
                                        Patient Name is a required field!
                                    </mat-error>
                                </mat-form-field>

                                <mat-form-field class="col-3">
                                    <mat-label>Phone Number</mat-label>
                                    <input autocomplete="new-txtPhoneNumber" matInput prefix="+1-" mask="(000)-000-0000" maxlength="40"
                                        placeholder="Enter Phone Number" formControlName="txtPhoneNumber" type="text">
                                    <mat-error *ngIf="
                                        newScheduleForm?.get('txtPhoneNumber')?.touched &&
                                        newScheduleForm?.get('txtPhoneNumber')?.errors
                                      ">
                                        Enter Minimum 10 Digits!
                                    </mat-error>
                                </mat-form-field>



                                <mat-form-field class="col-3">
                                    <mat-label> Email Address</mat-label>
                                    <input autocomplete="new-txtEmail" matInput maxlength="40" formControlName="txtEmail" type="text"
                                       pattern="[a-zA-Z0-9._!#$%&'()*+,-./:;<=>?@[\]^_`{|}~]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$"
                                        [textMask]="{ mask: emailMask }" />

                                    <mat-error *ngIf="newScheduleForm?.get('txtEmail')?.touched &&
                                      newScheduleForm?.get('txtEmail')?.errors?.required ">
                                        Email Address is a required field!
                                    </mat-error>
                                    <mat-error *ngIf="
                                      newScheduleForm?.get('txtEmail')?.touched &&
                                      newScheduleForm?.get('txtEmail')?.errors?.pattern
                                      ">
                                        Please Enter a Valid Email Address
                                    </mat-error>
                                </mat-form-field>

                                <mat-form-field class="col-3">
                                    <mat-label>Approinemnt Type</mat-label>
                                    <mat-select formControlName="drpAppointmentType" #singleSelect>
                                        <!-- <mat-option>
                                        <ngx-mat-select-search placeholderLabel="Search" noEntriesFoundLabel="No Matches found"
                                          formControlName="txtBillingStateFilter"></ngx-mat-select-search>
                                      </mat-option>
                                      <mat-option *ngFor="let state of filteredBillingStates | async" [value]="state.stateShortName">
                                        {{ state.stateName }}
                                      </mat-option> -->
                                    </mat-select>
                                    <!-- <button mat-button (click)="BillingState=undefined;$event.stopPropagation()" *ngIf="BillingState"
                                      (click)="clearBillingState()" matSuffix mat-icon-button >
                                      <mat-icon>close</mat-icon>
                                    </button> -->
                                </mat-form-field>

                                <mat-form-field class="col-3">
                                    <mat-label> Patient Address </mat-label>
                                    <textarea autocomplete="new-txtAddress" maxlength="400" formControlName="txtAddress" rows="3" matInput></textarea>
                                </mat-form-field>

                                <mat-form-field class="col-3">
                                    <mat-label>Patient ID</mat-label>
                                    <input autocomplete="new-txtPatientId" matInput maxlength="40" formControlName="txtPatientId"
                                        placeholder="Enter Patient ID" type="text" />
                                    <mat-error *ngIf="
                                    newScheduleForm?.get('txtPatientName')?.touched &&
                                    newScheduleForm?.get('txtPatientName')?.errors?.required
                                            ">
                                        Patient Name is a required field!
                                    </mat-error>
                                </mat-form-field>

                                <mat-form-field class="col-3">
                                    <mat-label> Get appointment slots from/after </mat-label>
                                    <input autocomplete="new-txtDate" [max]="BirthdateMaxDate" formControlName="txtDate" matInput maxlength="40"
                                        type="text" placeholder="(MM/DD/YYYY)" [matDatepicker]="txtDate"
                                        [min]="BirthdateMinDate" />
                                    <mat-datepicker-toggle matSuffix [for]="txtDate"> </mat-datepicker-toggle>
                                    <mat-datepicker #txtDate> </mat-datepicker>
                                    <mat-error *ngIf="
                                    newScheduleForm?.get('txtDate')?.touched &&
                                    newScheduleForm?.get('txtDate')?.errors">
                                        Enter Valid date
                                    </mat-error>
                                    <mat-error
                                        *ngIf=" newScheduleForm?.get('txtDate')?.touched && newScheduleForm?.get('txtDate')?.errors?.required">
                                        Date is a required field!
                                    </mat-error>
                                </mat-form-field>

                                <mat-form-field class="col-3">
                                    <mat-label>None Selected</mat-label>
                                    <mat-select multiple>
                                        <mat-option *ngFor="let leaves of leave" [value]="leaves.value">
                                            {{leaves.viewValue}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                        </mat-card-content>
                    </div>
                </div>
            </form>
            <div class="row">
                <div class="col-sm-12">
                    <div class="text-lg-center">
                        <button mat-button class="buttonColor mb-2 mr-2">
                            Check
                        </button>

                    </div>
                </div>
            </div>
            <br />
        </mat-expansion-panel>
    </mat-accordion>

    <div class="row">
        <div class="col-12 table-responsive">
            <h4 class="customThemeClass ml-3 mt-3"><b>Schedule Details</b></h4>
            <!-- <div [hidden]="!isLoading"
                style="display: flex; justify-content: center; align-items: center; background: transparent; color: lightseagreen;">
                <mat-progress-bar mode="indeterminate" value='66' diameter="45" class="lightseagreen">
                </mat-progress-bar>
            </div> -->

            <table mat-table class="table ml-4 border" matSort [dataSource]="dataSource">
                <form style="display: flex" [formGroup]="holidaySearchForm">

                    <ng-container matColumnDef="Code">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                            <mat-form-field class="filter">
                                <input autocomplete="new-code" matInput formControlName="code" />
                                <mat-placeholder>Code</mat-placeholder>
                            </mat-form-field>
                        </th>
                        <td mat-cell *matCellDef="let data">
                            <ng-container>

                            </ng-container>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="Visit">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                            <mat-form-field class="filter">
                                <input autocomplete="new-visit" matInput formControlName="visit" />
                                <mat-placeholder>Visit</mat-placeholder>
                            </mat-form-field>
                        </th>
                        <td mat-cell *matCellDef="let data">
                            <ng-container>

                            </ng-container>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="Patient">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                            <mat-form-field class="filter">
                                <input autocomplete="new-patient" matInput formControlName="patient" />
                                <mat-placeholder>Patient</mat-placeholder>
                            </mat-form-field>
                        </th>
                        <td mat-cell *matCellDef="let data">
                            <ng-container>

                            </ng-container>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="Respiratory Therapist">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                            <mat-form-field class="filter">
                                <input autocomplete="new-respiratoryTherapist" matInput formControlName="respiratoryTherapist" />
                                <mat-placeholder>Respiratory therapist</mat-placeholder>
                            </mat-form-field>
                        </th>
                        <td mat-cell *matCellDef="let data">
                            <ng-container>

                            </ng-container>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="Type">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                            <mat-form-field class="filter">
                                <input autocomplete="new-type" matInput formControlName="type" />
                                <mat-placeholder>Type</mat-placeholder>
                            </mat-form-field>
                        </th>
                        <td mat-cell *matCellDef="let data">
                            <ng-container>

                            </ng-container>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="Appointment Date">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                            <mat-form-field class="filter">
                                <input autocomplete="new-appointmentDate" matInput formControlName="appointmentDate" />
                                <mat-placeholder>Appointment Date</mat-placeholder>
                            </mat-form-field>
                        </th>
                        <td mat-cell *matCellDef="let data">
                            <ng-container>

                            </ng-container>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="From Time">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                            <mat-form-field class="filter">
                                <input autocomplete="new-fromTime" matInput formControlName="fromTime" />
                                <mat-placeholder>From Time</mat-placeholder>
                            </mat-form-field>
                        </th>
                        <td mat-cell *matCellDef="let data">
                            <ng-container>

                            </ng-container>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="To Time">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                            <mat-form-field class="filter">
                                <input autocomplete="new-toTime" matInput formControlName="toTime" />
                                <mat-placeholder>To Time</mat-placeholder>
                            </mat-form-field>
                        </th>
                        <td mat-cell *matCellDef="let data">
                            <ng-container>

                            </ng-container>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="Status">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                            <mat-form-field class="filter">
                                <input autocomplete="new-status" matInput formControlName="status" />
                                <mat-placeholder>Status</mat-placeholder>
                            </mat-form-field>
                        </th>
                        <td mat-cell *matCellDef="let data">
                            <ng-container>

                            </ng-container>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="TAT">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                            <mat-form-field class="filter">
                                <input autocomplete="new-tat" matInput formControlName="tat" />
                                <mat-placeholder>TAT</mat-placeholder>
                            </mat-form-field>
                        </th>
                        <td mat-cell *matCellDef="let data">
                            <ng-container>

                            </ng-container>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="Options">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Options</th>
                        <td mat-cell *matCellDef="let data">

                        </td>
                    </ng-container>
                    <ng-container matColumnDef="noRecords">
                        <td class="text-align-center" mat-footer-cell *matFooterCellDef colspan="11">
                            <div *ngIf="!isLoading">
                                {{"No Records Found"}}
                            </div>
                            <div [hidden]="!isLoading">
                                <mat-spinner [diameter]="220"></mat-spinner>
                            </div>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="paginator">
                        <td mat-footer-cell *matFooterCellDef colspan="11">
                            <mat-paginator class="table table-responsive mat-table-class" #MatPaginator
                                [length]="dataSource?.data?.length" [pageSize]="10" [pageIndex]="0" showFirstLastButtons
                                [pageSizeOptions]="[5, 10, 25, 100]">
                            </mat-paginator>
                        </td>
                    </ng-container>
                </form>
                <tr mat-footer-row *matFooterRowDef="['noRecords']"
                    [ngClass]="{ active: dataSource && dataSource?.data && dataSource?.data?.length !== 0 }"></tr>
                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
                <tr mat-footer-row *matFooterRowDef="['paginator']"></tr>
            </table>
        </div>
    </div>

</div>
