<br />
<!-- notification -->
<div class="row">
  <div class="col-lg-12">
    <mat-accordion class="example-headers-align" multi>
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title class="customThemeClass font-size-18">
            <b>Notification</b>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <mat-radio-group (change)="onChangeGetnotification($event)" class="rg pt-3 ml-3">
          <mat-radio-button [checked]='true' value="Email">Email</mat-radio-button>&nbsp;&nbsp;&nbsp;
          <mat-radio-button value="Message">Message</mat-radio-button>&nbsp;&nbsp;&nbsp;
        </mat-radio-group>
        <app-notification-mail [patientEmailId]="patientemailId" [patientId]="patientId" *ngIf="Email"
          [orderStatus]="orderStatus">
        </app-notification-mail>
        <app-message *ngIf="Message && patientId" [patientId]="patientId" [orderStatus]="orderStatus"></app-message>
      </mat-expansion-panel>
    </mat-accordion>
  </div>
</div>
<!-- notification end-->
<br />
<div class="card card-body">
  <form [formGroup]="orderForm">
    <br />
    <div class="row">
      <div class="col-lg-12">
        <div class="row">
          <div class="col-md-6">
            <h4 class="customThemeClass mar-top"><b>Delivery</b></h4>
          </div>
        </div>
        <mat-card-content formGroupName="Delivery">
          <div class="row">
            <mat-form-field class="col-3">
              <mat-label>Scheduled Date <span class="asterisk">*</span> </mat-label>
              <input autocomplete="new-txtScheduledDate" maxlength="40" formControlName="txtScheduledDate" matInput [matDatepicker]="txtScheduledDate"
                placeholder="(MM/DD/YYYY)" />
              <mat-datepicker-toggle matSuffix [for]="txtScheduledDate"></mat-datepicker-toggle>
              <mat-datepicker #txtScheduledDate></mat-datepicker>
              <mat-error *ngIf="
                orderForm?.get('Delivery.txtScheduledDate')?.touched &&
                orderForm?.get('Delivery.txtScheduledDate')?.errors
              ">
                Enter Valid Date!
              </mat-error>
              <mat-error
                *ngIf=" scheduledDate>=orderForm?.get('Delivery.txtScheduledDate')?.value && !(orderForm?.get('Delivery.txtScheduledDate')?.touched && orderForm?.get('Delivery.txtScheduledDate')?.errors?.required)">
                Enter Future Dates Only!
              </mat-error>
              <mat-error *ngIf=" orderForm?.get('Delivery.txtScheduledDate')?.touched &&
             orderForm?.get('Delivery.txtScheduledDate')?.errors?.required">
                Scheduled Date is a required field!
              </mat-error>
            </mat-form-field>
            <!-- <mat-form-field class="col-3">
             <mat-label> Scheduled Time</mat-label>
           <input maxlength="40" formControlName="txtScheduledTime" matInput type="time" />

             </mat-form-field> -->
            <div class="col-3 time">
              <mat-label> Scheduled Time</mat-label>
              <div class="ngx-timepicker-field-example">
                <ngx-timepicker-field formControlName="txtScheduledTime"></ngx-timepicker-field>
              </div>
            </div>


            <mat-form-field class="col-3">
              <mat-label>Actual Date <span class="asterisk">* </span></mat-label>
              <input autocomplete="new-txtActualDate" maxlength="40" formControlName="txtActualDate" matInput [matDatepicker]="txtActualDate"
                placeholder="(MM/DD/YYYY)" />
              <mat-datepicker-toggle matSuffix [for]="txtActualDate"></mat-datepicker-toggle>
              <mat-datepicker #txtActualDate></mat-datepicker>
              <mat-error *ngIf="
                  orderForm?.get('Delivery.txtActualDate')?.touched &&
                  orderForm?.get('Delivery.txtActualDate')?.errors
                ">
                Enter Valid Date!
              </mat-error>
              <mat-error
                *ngIf=" scheduledDate>=orderForm?.get('Delivery.txtActualDate')?.value && !(orderForm?.get('Delivery.txtActualDate')?.touched && orderForm?.get('Delivery.txtActualDate')?.errors?.required)">
                Enter Future Dates Only!
              </mat-error>
              <mat-error *ngIf=" orderForm?.get('Delivery.txtActualDate')?.touched &&
             orderForm?.get('Delivery.txtActualDate')?.errors?.required">
                Actual Date is a required field!
              </mat-error>
            </mat-form-field>
            <!-- <mat-form-field class="col-3">
            <mat-label> Actual Time</mat-label>
            <input formControlName="txtActualTime" matInput type="time" maxlength="40" />
            <mat-error *ngIf="
                  orderForm?.get('Delivery.txtActualTime')?.touched &&
                  orderForm?.get('Delivery.txtActualTime')?.errors
                ">
              Enter Valid Date!
            </mat-error>

            <mat-error *ngIf="
                orderForm?.get('Delivery.txtActualTime')?.touched &&
                orderForm?.get('Delivery.txtActualTime')?.errors
              ">
              Enter Valid Date!
            </mat-error>
          </mat-form-field> -->
            <div class="col-3 time">
              <mat-label> Actual Time</mat-label>
              <div class="ngx-timepicker-field-example">
                <ngx-timepicker-field formControlName="txtActualTime"></ngx-timepicker-field>
              </div>
            </div>
            <mat-form-field class="col-3">
              <mat-label>Address</mat-label>
              <textarea autocomplete="new-txtAddress" maxlength="400" formControlName="txtAddress" rows="3" matInput></textarea>
            </mat-form-field>

            <!-- <ng-select class="col-3" (change)="onChangeState($event)" formControlName="drpCountry" placeholder="Country"
            maxlength="40">
            <ng-option *ngFor="let country of drpCountry" [value]="country.countryName">
              {{ country.countryName }}</ng-option>
          </ng-select> -->
            <mat-form-field class="col-3">
              <mat-label>Country</mat-label>
              <mat-select formControlName="drpCountry" (selectionChange)='onChangeState($event.value)'
                [(value)]="Country" #singleSelect>
                <mat-option>
                  <ngx-mat-select-search placeholderLabel="Search" noEntriesFoundLabel="No Matches found"
                    formControlName="txtCountryFilter"></ngx-mat-select-search>
                </mat-option>
                <mat-option *ngFor="let country of filteredCountries | async" [value]="country.countryShortName">
                  {{ country.countryName }}
                </mat-option>
              </mat-select>
              <button mat-button (click)="clearCountry()" (click)="Country='';$event.stopPropagation()" *ngIf="Country"
                matSuffix mat-icon-button >
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field>
            <!-- <ng-select class="col-3" (change)="onChangeCity($event)" formControlName="drpState" placeholder="State"
            maxlength="40">
            <ng-option *ngFor="let state of drpStates" [value]="state.stateName">
              {{ state.stateName }}</ng-option>
          </ng-select> -->
            <mat-form-field class="col-3">
              <mat-label>State</mat-label>
              <mat-select formControlName="drpState" (selectionChange)='onChangeCity($event.value,"")' [(value)]="State"
                #singleSelect>
                <mat-option>
                  <ngx-mat-select-search placeholderLabel="Search" noEntriesFoundLabel="No Matches found"
                    formControlName="txtStateFilter"></ngx-mat-select-search>
                </mat-option>
                <mat-option *ngFor="let state of filteredStates | async" [value]="state.stateShortName">
                  {{ state.stateName }}
                </mat-option>
              </mat-select>
              <button mat-button (click)="clearState()" (click)="State='';$event.stopPropagation()" *ngIf="State"
                matSuffix mat-icon-button >
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field>
            <mat-form-field class="col-3">
              <mat-label> County</mat-label>
              <input autocomplete="new-drpCounty" formControlName="drpCounty" matInput maxlength="40" type="text" />
              <mat-error *ngIf="
              orderForm?.get('Delivery.drpCounty')?.touched &&
              orderForm?.get('Delivery.drpCounty')?.errors?.sAlphabets
            ">
                Enter only Alphabets!
              </mat-error>
            </mat-form-field>
            <!-- <mat-form-field class="col-3">
              <mat-label> County</mat-label>
              <mat-select maxlength="40" formControlName="drpCounty" [(value)]="county" (click)="clearCounty()">
                <mat-option *ngFor="let county of drpCounty" [value]="county.countyTypeShortCodeId">
                  {{ county.countyType }}
                </mat-option>
              </mat-select>
              <button mat-button (click)="county='';$event.stopPropagation()" *ngIf="county" matSuffix
                mat-icon-button >
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field> -->
            <!-- <ng-select class="col-3" formControlName="drpCity" placeholder="City" maxlength="40">
            <ng-option *ngFor="let city of drpCities" [value]="city.cityName">
              {{ city.cityName }}</ng-option>
          </ng-select> -->
            <mat-form-field class="col-3">
              <mat-label>City</mat-label>
              <mat-select formControlName="drpCity" [(value)]="City" #singleSelect>
                <mat-option>
                  <ngx-mat-select-search placeholderLabel="Search" noEntriesFoundLabel="No Matches found"
                    formControlName="txtCityFilter"></ngx-mat-select-search>
                </mat-option>
                <mat-option *ngFor="let city of filteredCities | async" [value]="city.cityName">
                  {{ city.cityName }}
                </mat-option>
              </mat-select>
              <button mat-button (click)="clearCity()" (click)="City='';$event.stopPropagation()" *ngIf="City" matSuffix
                mat-icon-button >
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field>
            <mat-form-field class="col-3">
              <mat-label>Zip Code</mat-label>
              <input autocomplete="new-txtPostalCode" maxlength="15" formControlName="txtPostalCode" pattern="[0-9-]*" matInput type="text" />
              <mat-error *ngIf="
            orderForm?.get('Delivery.txtPostalCode')?.touched &&
            orderForm?.get('Delivery.txtPostalCode')?.errors?.pattern
          ">
                Enter only Numbers!
              </mat-error>
            </mat-form-field>
            <mat-form-field class="col-3">
              <mat-label>Phone</mat-label>
              <input autocomplete="new-txtPhone" maxlength="40" formControlName="txtPhone" prefix="{{phoneCode}}-" mask="(000)-000-0000" matInput
                type="text" />
              <mat-error *ngIf="
                  orderForm?.get('Delivery.txtPhone')?.touched &&
                  orderForm?.get('Delivery.txtPhone')?.errors
                ">
                Enter Minimum 10 Digits!
              </mat-error>
            </mat-form-field>
            <mat-form-field class="col-3">
              <mat-label>Mobile </mat-label>
              <input autocomplete="new-txtMobile" matInput prefix="{{phoneCode}}-" mask="(000)-000-0000" maxlength="40"
                placeholder="Enter Phone Number" formControlName="txtMobile" type="text">
              <mat-error *ngIf="
            orderForm?.get('Delivery.txtMobile')?.touched &&
            orderForm?.get('Delivery.txtMobile')?.errors
              ">
                Enter Minimum 10 Digits!
              </mat-error>
            </mat-form-field>
            <mat-form-field class="col-3">
              <mat-label>Facility</mat-label>
              <input autocomplete="new-txtFacility" maxlength="40" formControlName="txtFacility" matInput type="text" />

              <mat-error *ngIf="
                  orderForm?.get('Delivery.txtFacility')?.touched &&
                  orderForm?.get('Delivery.txtFacility')?.errors?.sAlphabets
                ">
                Enter only Alphabets!
              </mat-error>
            </mat-form-field>
            <!--
            <mat-form-field class="col-3">
              <mat-label>Facility</mat-label>
              <mat-select maxlength="40" [(value)]="facility" (click)="clearFacility()" formControlName="txtFacility">
                <mat-option *ngFor="let facility of drpFacility" [value]="facility.facilityShortCodeId">
                  {{ facility.facilityName }}
                </mat-option>
              </mat-select>
              <button mat-button (click)="facility='';$event.stopPropagation()" *ngIf="facility" matSuffix
                mat-icon-button >
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field> -->

            <mat-form-field class="col-3">
              <mat-label>Tax Zone</mat-label>
              <input autocomplete="new-txtTaxzone" maxlength="40" formControlName="txtTaxzone" matInput type="text" />
              <mat-error *ngIf="
                  orderForm?.get('Delivery.txtTaxzone')?.touched &&
                  orderForm?.get('Delivery.txtTaxzone')?.errors?.sAlphabets
                ">
                Enter only Alphabets!
              </mat-error>
            </mat-form-field>

            <!-- <mat-form-field class="col-3">
              <mat-label>Tax Zone</mat-label>
              <mat-select maxlength="40" [(value)]="zone" (click)="clearTaxZone()" formControlName="txtTaxZone">
                <mat-option *ngFor="let tax of drpTaxZone" [value]="tax.taxZoneTypeShortCodeId">
                  {{ tax.taxZoneType }}
                </mat-option>
              </mat-select>
              <button mat-button (click)="zone='';$event.stopPropagation()" *ngIf="zone" matSuffix
                mat-icon-button >
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field> -->
            <mat-form-field class="col-3">
              <mat-label>Tax Rate</mat-label>
              <input autocomplete="new-txtTaxRate" maxlength="15" formControlName="txtTaxRate" matInput type="text" />

              <mat-error *ngIf="
                  orderForm?.get('Delivery.txtTaxRate')?.touched &&
                  orderForm?.get('Delivery.txtTaxRate')?.errors?.number
                ">
                Enter only Numbers!
              </mat-error>
            </mat-form-field>
            <mat-form-field class="col-3">
              <mat-label>Order Note</mat-label>
              <textarea maxlength="5000" rows="1"[(ngModel)]="notesText"
              (input)="updateCharacterCount()" formControlName="txtOrderNote" matInput></textarea> <div class="char-count">
                {{ notesText?.length }} / 5000  
              </div>
              <!-- <mat-error *ngIf="
            orderForm?.get('Delivery.txtOrderNote')?.touched &&
            orderForm?.get('Delivery.txtOrderNote')?.errors?.sAlphabets
          ">
                Enter only Alphabets!
              </mat-error> -->
            </mat-form-field>
            <mat-form-field class="col-3">
              <mat-label>Delivery Note</mat-label>
              <textarea maxlength="1000" rows="1" formControlName="txtDeliveryNote" matInput></textarea>
              <!-- <mat-error *ngIf="
            orderForm?.get('Delivery.txtDeliveryNote')?.touched &&
            orderForm?.get('Delivery.txtDeliveryNote')?.errors?.pattern
            ">
                Enter Only Alphanumeric !
              </mat-error> -->
            </mat-form-field>
            <mat-form-field class="col-3">
              <mat-label>Setup Method</mat-label>
              <input autocomplete="new-drpSetupMethod" maxlength="40" formControlName="drpSetupMethod" matInput type="text" />
              <mat-error *ngIf="
                  orderForm?.get('Delivery.drpSetupMethod')?.touched &&
                  orderForm?.get('Delivery.drpSetupMethod')?.errors?.pattern
                ">
                Enter only Alphabets!
              </mat-error>
            </mat-form-field>
            <mat-form-field class="col-3">
              <mat-label>Delivery Technician</mat-label>
              <input autocomplete="new-txtDeliveryTechnician" maxlength="40" formControlName="txtDeliveryTechnician" matInput type="text" />
              <mat-error *ngIf="
                  orderForm?.get('Delivery.txtDeliveryTechnician')?.touched &&
                  orderForm?.get('Delivery.txtDeliveryTechnician')?.errors?.sAlphabets
                ">
                Enter only Alphabets!
              </mat-error>
            </mat-form-field>
            <mat-form-field class="col-3">
              <mat-label>Drop Ship Status</mat-label>
              <input autocomplete="new-txtdrpShipStatus" maxlength="40" formControlName="txtdrpShipStatus" matInput type="text" />
              <mat-error *ngIf="
                  orderForm?.get('Delivery.txtdrpShipStatus')?.touched &&
                  orderForm?.get('Delivery.txtdrpShipStatus')?.errors?.sAlphabets
                ">
                Enter only Alphabets!
              </mat-error>
            </mat-form-field>
            <div class="col-md-3">
              <br />
              <mat-checkbox formControlName="chkSignatureRequired">Signature Required</mat-checkbox>
            </div>

            <mat-form-field class="col-md-3">
              <mat-label>Fullfillment Manufacturer </mat-label>
              <mat-select maxlength="40" formControlName="drpFulfillmentVendor" [(value)]="fulfillmentVendor">
                <mat-option *ngFor="let complaince of drpComplaince" [value]="complaince.shortCodeId">
                  {{ complaince.complainceUsageMetType }}
                </mat-option>
              </mat-select>
              <button mat-button (click)="clearFulfillmentVendor()"
                (click)="fulfillmentVendor='';$event.stopPropagation()" *ngIf="fulfillmentVendor" matSuffix
                mat-icon-button >
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field>
            <mat-form-field class="col-md-3">
              <mat-label>Account Number</mat-label>
              <mat-select maxlength="40" formControlName="drpAccountNumber" [(value)]="accountNumber">
                <mat-option *ngFor="let complaince of drpComplaince" [value]="complaince.shortCodeId">
                  {{ complaince.complainceUsageMetType }}
                </mat-option>
              </mat-select>
              <button mat-button (click)="clearAccountNumber()" (click)="accountNumber='';$event.stopPropagation()"
                *ngIf="accountNumber" matSuffix mat-icon-button >
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field>
            <mat-form-field class="col-md-3">
              <mat-label>Ship By</mat-label>
              <mat-select maxlength="40" formControlName="drpShipBy" [(value)]="shipBy">
                <mat-option *ngFor="let complaince of drpComplaince" [value]="complaince.shortCodeId">
                  {{ complaince.complainceUsageMetType }}
                </mat-option>
              </mat-select>
              <button mat-button (click)="clearShipBy()" (click)="shipBy='';$event.stopPropagation()" *ngIf="shipBy"
                matSuffix mat-icon-button >
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field>
            <mat-form-field class="col-md-3">
              <mat-label>Status</mat-label>
              <mat-select maxlength="40" formControlName="txtStatus" [(value)]="status">
                <mat-option *ngFor="let complaince of drpComplaince" [value]="complaince.shortCodeId">
                  {{ complaince.complainceUsageMetType }}
                </mat-option>
              </mat-select>
              <button mat-button (click)="clearStatus()" (click)="status='';$event.stopPropagation()" *ngIf="status"
                matSuffix mat-icon-button >
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field>
            <!-- <mat-form-field class="col-3">
              <mat-label>Stop Date</mat-label>
              <input maxlength="40" formControlName="txtStatusDate" matInput [matDatepicker]="txtStatusDate"
                placeholder="(MM/DD/YYYY)" />
              <mat-datepicker-toggle matSuffix [for]="txtStatusDate"></mat-datepicker-toggle>
              <mat-datepicker #txtStatusDate></mat-datepicker>
              <mat-error *ngIf="
                  orderForm?.get('Delivery.txtStatusDate')?.touched &&
                  orderForm?.get('Delivery.txtStatusDate')?.errors
                ">
                Enter Valid Date!
              </mat-error>
            </mat-form-field> -->
            <div class="col-md-3">
              <br />
              <mat-checkbox (click)="cashPaymentClick()" formControlName="chkCahPayment">Cash Payment</mat-checkbox>
            </div>
          </div>
        </mat-card-content>
      </div>
    </div>

    <br />
    <div class="row">
      <div class="col-lg-12">
        <div class="row">
          <div class="col-md-6">
            <h4 class="customThemeClass"><b>General</b></h4>
          </div>
        </div>
        <mat-card-content formGroupName="General">
          <div class="row">
            <div class="col-md-3">
              <br />
              <mat-checkbox formControlName="chkGeneralManualHold">Manual Hold</mat-checkbox>
            </div>
            <mat-form-field class="col-3">
              <mat-label>Hold Reason</mat-label>
              <mat-select maxlength="40" formControlName="drpGeneralHoldReason" [(value)]="generalHoldReason">
                <mat-option *ngFor="let hold of drpHoldReason" [value]="hold.holdReasonShortCodeId">
                  {{ hold.holdReasonName }}
                </mat-option>
              </mat-select>
              <button mat-button (click)="clearGeneralHoldReason()"
                (click)="generalHoldReason='';$event.stopPropagation()" *ngIf="generalHoldReason" matSuffix
                mat-icon-button >
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field>
            <mat-form-field class="col-3">
              <mat-label>Stop Date</mat-label>
              <input autocomplete="new-txtGeneralStopDate" maxlength="40" formControlName="txtGeneralStopDate" matInput [matDatepicker]="txtGeneralStopDate"
                placeholder="(MM/DD/YYYY)" />
              <mat-datepicker-toggle matSuffix [for]="txtGeneralStopDate"></mat-datepicker-toggle>
              <mat-datepicker #txtGeneralStopDate></mat-datepicker>
              <mat-error *ngIf="
                  orderForm?.get('General.txtGeneralStopDate')?.touched &&
                  orderForm?.get('General.txtGeneralStopDate')?.errors
                ">
                Enter Valid Date!
              </mat-error>
            </mat-form-field>
            <mat-form-field class="col-3">
              <mat-label>Stop Reason</mat-label>
              <mat-select maxlength="40" formControlName="drpGeneralStopReason" [(value)]="generalStopReason">
                <mat-option *ngFor="let stop of drpStopReason" [value]="stop.shortCodeId">
                  {{ stop.stopReasonType }}
                </mat-option>
              </mat-select>
              <button mat-button (click)="clearGeneralStopReason()"
                (click)="generalStopReason='';$event.stopPropagation()" *ngIf="generalStopReason" matSuffix
                mat-icon-button >
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field>
            <mat-form-field class="col-3">
              <mat-label>Branch</mat-label>
              <mat-select maxlength="40" formControlName="drpGeneralBranch" [(value)]="generalBranch">
                <!-- <mat-option *ngFor="let branch of drpBranch" [value]="branch.branchId">
                {{ branch.branchType }}
              </mat-option> -->
                <mat-option *ngFor="let branch of drpBranch" [value]="branch.branchOfficeShortCodeId">
                  {{ branch.branchOffice }}
                </mat-option>
              </mat-select>
              <button mat-button (click)="clearGeneralBranch()" (click)="generalBranch='';$event.stopPropagation()"
                *ngIf="generalBranch" matSuffix mat-icon-button >
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field>
            <mat-form-field class="col-3">
              <mat-label>Inv.Location</mat-label>
              <mat-select maxlength="40" formControlName="drpGeneralInvLocation" [(value)]="generalInvLocation">
                <mat-option *ngFor="let inv of drpInvLocation" [value]="inv.invLocationType">
                  {{ inv.invLocationType }}
                </mat-option>

              </mat-select>
              <button mat-button (click)="clearGeneralInvLocation()"
                (click)="generalInvLocation='';$event.stopPropagation()" *ngIf="generalInvLocation" matSuffix
                mat-icon-button >
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field>
            <mat-form-field class="col-3">
              <mat-label>Status</mat-label>
              <mat-select maxlength="40" formControlName="drpGeneralStatus" [(value)]="generalStatus">
                <mat-option *ngFor="let status of drpStatus" [value]="status.shortCodeId">
                  {{ status.statusType }}
                </mat-option>
              </mat-select>
              <button mat-button (click)="clearGeneralStatus()" (click)="generalStatus='';$event.stopPropagation()"
                *ngIf="generalStatus" matSuffix mat-icon-button >
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field>
            <mat-form-field class="col-3">
              <mat-label>Classification</mat-label>
              <mat-select maxlength="40" formControlName="drpGeneralClassification" [(value)]="generalClassification">
                <mat-option *ngFor="let classification of drpClassification" [value]="classification.shortCodeId">
                  {{ classification.classificationType }}
                </mat-option>
              </mat-select>
              <button mat-button (click)="clearGeneralClassification()"
                (click)="generalClassification='';$event.stopPropagation()" *ngIf="generalClassification" matSuffix
                mat-icon-button >
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field>
            <mat-form-field class="col-3">
              <mat-label>Place Of Service <span class="asterisk">*</span></mat-label>
              <mat-select maxlength="40" formControlName="drpGeneralPlaceOfService" [(value)]="generalPlaceOfService">
                <mat-option *ngFor="let pos of drpPlace" [value]="pos.placeOfServiceCode">
                  {{ pos.placeOfServiceType }}
                </mat-option>
              </mat-select>
              <button mat-button (click)="clearGeneralPlaceOfService()"
                (click)="generalPlaceOfService='';$event.stopPropagation()" *ngIf="generalPlaceOfService" matSuffix
                mat-icon-button >
                <mat-icon>close</mat-icon>
              </button>
              <mat-error *ngIf=" orderForm?.get('General.drpGeneralPlaceOfService')?.touched &&
              orderForm?.get('General.drpGeneralPlaceOfService')?.errors?.required">
                Place Of Service is a required field!
              </mat-error>
            </mat-form-field>
            <mat-form-field class="col-3">
              <mat-label>Date Of Admission</mat-label>
              <input autocomplete="new-txtGeneralDateOfAdmission" maxlength="40" formControlName="txtGeneralDateOfAdmission" matInput
                [matDatepicker]="txtGeneralDateOfAdmission" placeholder="(MM/DD/YYYY)" />
              <mat-datepicker-toggle matSuffix [for]="txtGeneralDateOfAdmission"></mat-datepicker-toggle>
              <mat-datepicker #txtGeneralDateOfAdmission></mat-datepicker>
              <mat-error *ngIf="
                  orderForm?.get('General.txtGeneralDateOfAdmission')?.touched &&
                  orderForm?.get('General.txtGeneralDateOfAdmission')?.errors
                ">
                Enter Valid Date!
              </mat-error>
            </mat-form-field>
            <mat-form-field class="col-3">
              <mat-label>Date Of Discharge</mat-label>
              <input autocomplete="new-txtGeneralDateOfDischarge" maxlength="40" formControlName="txtGeneralDateOfDischarge" matInput
                [matDatepicker]="txtGeneralDateOfDischarge" placeholder="(MM/DD/YYYY)" />
              <mat-datepicker-toggle matSuffix [for]="txtGeneralDateOfDischarge"></mat-datepicker-toggle>
              <mat-datepicker #txtGeneralDateOfDischarge></mat-datepicker>
              <mat-error *ngIf="
                  orderForm?.get('General.txtGeneralDateOfDischarge')?.touched &&
                  orderForm?.get('General.txtGeneralDateOfDischarge')?.errors
                ">
                Enter Valid Date!
              </mat-error>
            </mat-form-field>
            <mat-form-field class="col-3">
              <mat-label>Discount Pct</mat-label>
              <input autocomplete="new-txtGeneralDiscountPct" mask="percent" suffix="%" formControlName="txtGeneralDiscountPct" matInput type="text" />
              <mat-error *ngIf="
                  orderForm?.get('General.txtGeneralDiscountPct')?.touched &&
                  orderForm?.get('General.txtGeneralDiscountPct')?.errors?.number
                ">
                Enter only Numbers!
              </mat-error>
              <mat-error *ngIf="
                orderForm?.get('General.txtGeneralDiscountPct')?.touched &&
                orderForm?.get('General.txtGeneralDiscountPct')?.errors
              ">
                Enter Minimum 2 Digits!
              </mat-error>
            </mat-form-field>
            <mat-form-field class="col-3">
              <mat-label>P.O.Number</mat-label>
              <input autocomplete="new-txtGeneralPoNumber" maxlength="15" formControlName="txtGeneralPoNumber" matInput type="text" />
              <mat-error *ngIf="
                  orderForm?.get('General.txtGeneralPoNumber')?.touched &&
                  orderForm?.get('General.txtGeneralPoNumber')?.errors?.number
                ">
                Enter only Numbers!
              </mat-error>
            </mat-form-field>
            <mat-form-field class="col-3">
              <mat-label>Reference</mat-label>
              <input autocomplete="new-txtGeneralReference" maxlength="40" formControlName="txtGeneralReference" matInput type="text" />
              <mat-error *ngIf="
                  orderForm?.get('General.txtGeneralReference')?.touched &&
                  orderForm?.get('General.txtGeneralReference')?.errors?.sAlphabets
                ">
                Enter only Alphabets!
              </mat-error>
            </mat-form-field>
            <mat-form-field class="col-3">
              <mat-label>User 1</mat-label>
              <input autocomplete="new-txtGeneralUser1" maxlength="40" formControlName="txtGeneralUser1" matInput type="text" />
              <mat-error *ngIf="
                  orderForm?.get('General.txtGeneralUser1')?.touched &&
                  orderForm?.get('General.txtGeneralUser1')?.errors?.sAlphabets
                ">
                Enter only Alphabets!
              </mat-error>
            </mat-form-field>
            <mat-form-field class="col-3">
              <mat-label>User 2</mat-label>
              <input autocomplete="new-txtGeneralUser2" maxlength="40" formControlName="txtGeneralUser2" matInput type="text" />
              <mat-error *ngIf="
                  orderForm?.get('General.txtGeneralUser2')?.touched &&
                  orderForm?.get('General.txtGeneralUser2')?.errors?.sAlphabets
                ">
                Enter only Alphabets!
              </mat-error>
            </mat-form-field>
            <mat-form-field class="col-3">
              <mat-label>User 3</mat-label>
              <input autocomplete="new-txtGeneralUser3" maxlength="40" formControlName="txtGeneralUser3" matInput type="text" />
              <mat-error *ngIf="
                  orderForm?.get('General.txtGeneralUser3')?.touched &&
                  orderForm?.get('General.txtGeneralUser3')?.errors?.sAlphabets
                ">
                Enter only Alphabets!
              </mat-error>
            </mat-form-field>
            <mat-form-field class="col-3">
              <mat-label>User 4</mat-label>
              <input autocomplete="new-txtGeneralUser4" maxlength="40" formControlName="txtGeneralUser4" matInput type="text" />
              <mat-error *ngIf="
                  orderForm?.get('General.txtGeneralUser4')?.touched &&
                  orderForm?.get('General.txtGeneralUser4')?.errors?.sAlphabets
                ">
                Enter only Alphabets!
              </mat-error>
            </mat-form-field>
            <mat-form-field class="col-3">
              <mat-label>Prior System Key</mat-label>
              <input autocomplete="new-txtGeneralPriorSystemKey" maxlength="40" formControlName="txtGeneralPriorSystemKey" matInput type="text" />
              <mat-error *ngIf="
                  orderForm?.get('General.txtGeneralPriorSystemKey')?.touched &&
                  orderForm?.get('General.txtGeneralPriorSystemKey')?.errors?.sAlphabets
                ">
                Enter only Alphabets!
              </mat-error>
            </mat-form-field>
          </div>
        </mat-card-content>
      </div>
    </div>

    <br />
    <div class="row">
      <div class="col-lg-6">
        <div class="row">
          <div class="col-md-6">
            <h4 class="customThemeClass"><b>Audit Trail</b></h4>
          </div>
        </div>
        <mat-card-content formGroupName="AuditTrial">
          <div class="row">
            <mat-form-field class="col-6">
              <mat-label>Order Number</mat-label>
              <input autocomplete="new-txtAuditTrialOrderNumber" maxlength="15" formControlName="txtAuditTrialOrderNumber" matInput type="text" />
              <mat-error *ngIf="
                  orderForm?.get('AuditTrial.txtAuditTrialOrderNumber')?.touched &&
                  orderForm?.get('AuditTrial.txtAuditTrialOrderNumber')?.errors?.number
                ">
                Enter only Numbers!
              </mat-error>
            </mat-form-field>

            <mat-form-field class="col-6">
              <mat-label>Type</mat-label>
              <input autocomplete="new-txtAuditTrialType" maxlength="40" formControlName="txtAuditTrialType" matInput type="text" />
              <mat-error *ngIf="
                  orderForm?.get('AuditTrial.txtAuditTrialType')?.touched &&
                  orderForm?.get('AuditTrial.txtAuditTrialType')?.errors?.sAlphabets
                ">
                Enter only Alphabets!
              </mat-error>
            </mat-form-field>

            <mat-form-field class="col-6">
              <mat-label>Date Created</mat-label>
              <input autocomplete="new-txtAuditTrialDateCreated" maxlength="40" formControlName="txtAuditTrialDateCreated" matInput
                [matDatepicker]="txtAuditTrialDateCreated" placeholder="(MM/DD/YYYY)" />
              <mat-datepicker-toggle matSuffix [for]="txtAuditTrialDateCreated"></mat-datepicker-toggle>
              <mat-datepicker #txtAuditTrialDateCreated></mat-datepicker>
              <mat-error *ngIf="
                  orderForm?.get('AuditTrial.txtAuditTrialDateCreated')?.touched &&
                  orderForm?.get('AuditTrial.txtAuditTrialDateCreated')?.errors
                ">
                Enter Valid Date!
              </mat-error>
            </mat-form-field>

            <mat-form-field class="col-6">
              <mat-label>Created By</mat-label>
              <input autocomplete="new-txtAuditTrialCreatedBy" maxlength="40" formControlName="txtAuditTrialCreatedBy" matInput type="text" />
              <mat-error *ngIf="
                  orderForm?.get('AuditTrial.txtAuditTrialCreatedBy')?.touched &&
                  orderForm?.get('AuditTrial.txtAuditTrialCreatedBy')?.errors?.sAlphabets
                ">
                Enter only Alphabets!
              </mat-error>
            </mat-form-field>

            <mat-form-field class="col-6">
              <mat-label>Last Printed</mat-label>
              <input autocomplete="new-txtAuditTrialLastPrinted" maxlength="40" formControlName="txtAuditTrialLastPrinted" matInput type="text" />
              <mat-error *ngIf="
                  orderForm?.get('AuditTrial.txtAuditTrialLastPrinted')?.touched &&
                  orderForm?.get('AuditTrial.txtAuditTrialLastPrinted')?.errors?.sAlphabets
                ">
                Enter only Alphabets!
              </mat-error>
            </mat-form-field>
            <mat-form-field class="col-6">
              <mat-label>Printed By</mat-label>
              <input autocomplete="new-txtAuditTrialPrintedBy" maxlength="40" formControlName="txtAuditTrialPrintedBy" matInput type="text" />
              <mat-error *ngIf="
                  orderForm?.get('AuditTrial.txtAuditTrialPrintedBy')?.touched &&
                  orderForm?.get('AuditTrial.txtAuditTrialPrintedBy')?.errors?.sAlphabets
                ">
                Enter only Alphabets!
              </mat-error>
            </mat-form-field>

            <mat-form-field class="col-6">
              <mat-label>Confirmed By</mat-label>
              <input autocomplete="new-txtAuditTrialConfirmedBy" maxlength="40" formControlName="txtAuditTrialConfirmedBy" matInput type="text" />
              <mat-error *ngIf="
                  orderForm?.get('AuditTrial.txtAuditTrialConfirmedBy')?.touched &&
                  orderForm?.get('AuditTrial.txtAuditTrialConfirmedBy')?.errors?.sAlphabets
                ">
                Enter only Alphabets!
              </mat-error>
            </mat-form-field>

            <mat-form-field class="col-6">
              <mat-label>Confirmed Date</mat-label>
              <input autocomplete="new-txtAuditTrialConfirmedDate" maxlength="40" formControlName="txtAuditTrialConfirmedDate" matInput
                [matDatepicker]="txtAuditTrialConfirmedDate" placeholder="(MM/DD/YYYY)" />
              <mat-datepicker-toggle matSuffix [for]="txtAuditTrialConfirmedDate"></mat-datepicker-toggle>
              <mat-datepicker #txtAuditTrialConfirmedDate></mat-datepicker>
              <mat-error *ngIf="
                  orderForm?.get('AuditTrial.txtAuditTrialConfirmedDate')?.touched &&
                  orderForm?.get('AuditTrial.txtAuditTrialConfirmedDate')?.errors
                ">
                Enter Valid Date!
              </mat-error>
            </mat-form-field>

            <mat-form-field class="col-6">
              <mat-label>Prev Billing</mat-label>
              <input autocomplete="new-txtAuditTrialPrevBilling" maxlength="40" formControlName="txtAuditTrialPrevBilling" matInput
                [matDatepicker]="txtAuditTrialPrevBilling" placeholder="(MM/DD/YYYY)" />
              <mat-datepicker-toggle matSuffix [for]="txtAuditTrialPrevBilling"></mat-datepicker-toggle>
              <mat-datepicker #txtAuditTrialPrevBilling></mat-datepicker>
              <mat-error *ngIf="
                  orderForm?.get('AuditTrial.txtAuditTrialPrevBilling')?.touched &&
                  orderForm?.get('AuditTrial.txtAuditTrialPrevBilling')?.errors
                ">
                Enter Valid Date!
              </mat-error>
            </mat-form-field>

            <mat-form-field class="col-6">
              <mat-label>Next Billing</mat-label>
              <input autocomplete="new-txtAuditTrialNextBilling" maxlength="40" formControlName="txtAuditTrialNextBilling" matInput
                [matDatepicker]="txtAuditTrialNextBilling" placeholder="(MM/DD/YYYY)" />
              <mat-datepicker-toggle matSuffix [for]="txtAuditTrialNextBilling"></mat-datepicker-toggle>
              <mat-datepicker #txtAuditTrialNextBilling></mat-datepicker>
              <mat-error *ngIf="
                  orderForm?.get('AuditTrial.txtAuditTrialNextBilling')?.touched &&
                  orderForm?.get('AuditTrial.txtAuditTrialNextBilling')?.errors
                ">
                Enter Valid Date!
              </mat-error>
            </mat-form-field>
          </div>
        </mat-card-content>
      </div>
      <div class="col-lg-6">
        <div class="row">
          <div class="col-md-6">
            <h4 class="customThemeClass"><b>Point Of Delivery</b></h4>
          </div>
        </div>
        <mat-card-content formGroupName="AutoConfirm">
          <div class="row">
            <div formGroupName="PointDelivery">
              <mat-form-field class="col-6">
                <mat-label>Order Status</mat-label>
                <input autocomplete="new-txtPointDeliveryOrderStatus" maxlength="40" formControlName="txtPointDeliveryOrderStatus" matInput type="text" />
                <mat-error *ngIf="
                    orderForm?.get('AutoConfirm.PointDelivery.txtPointDeliveryOrderStatus')?.touched &&
                    orderForm?.get('AutoConfirm.PointDelivery.txtPointDeliveryOrderStatus')?.errors
                      ?.sAlphabets
                  ">
                  Enter only Alphabets!
                </mat-error>
              </mat-form-field>

              <mat-form-field class="col-6">
                <mat-label>Status Date/Time</mat-label>
                <input autocomplete="new-txtPointDeliveryStatusDateTime" maxlength="40" formControlName="txtPointDeliveryStatusDateTime" matInput
                  [matDatepicker]="txtPointDeliveryStatusDateTime" placeholder="(MM/DD/YYYY)" />
                <mat-datepicker-toggle matSuffix [for]="txtPointDeliveryStatusDateTime"></mat-datepicker-toggle>
                <mat-datepicker #txtPointDeliveryStatusDateTime></mat-datepicker>
                <mat-error *ngIf="
                    orderForm?.get('AutoConfirm.PointDelivery.txtPointDeliveryStatusDateTime')?.touched &&
                    orderForm?.get('AutoConfirm.PointDelivery.txtPointDeliveryStatusDateTime')?.errors
                  ">
                  Enter Valid Date!
                </mat-error>
              </mat-form-field>

              <mat-form-field class="col-6">
                <mat-label>Last Message</mat-label>
                <input autocomplete="new-txtPointDeliveryLastMessage" maxlength="40" formControlName="txtPointDeliveryLastMessage" matInput type="text" />
                <mat-error *ngIf="
                    orderForm?.get('AutoConfirm.PointDelivery.txtPointDeliveryLastMessage')?.touched &&
                    orderForm?.get('AutoConfirm.PointDelivery.txtPointDeliveryLastMessage')?.errors
                      ?.sAlphabets
                  ">
                  Enter only Alphabets!
                </mat-error>
              </mat-form-field>

              <mat-form-field class="col-6">
                <mat-label>Last Message Date/Time</mat-label>
                <input autocomplete="new-txtPointDeliveryLastMessageDateTime" maxlength="40" formControlName="txtPointDeliveryLastMessageDateTime" matInput
                  [matDatepicker]="txtPointDeliveryLastMessageDateTime" placeholder="(MM/DD/YYYY)" />
                <mat-datepicker-toggle matSuffix [for]="txtPointDeliveryLastMessageDateTime"></mat-datepicker-toggle>
                <mat-datepicker #txtPointDeliveryLastMessageDateTime></mat-datepicker>
                <mat-error *ngIf="
                    orderForm?.get('AutoConfirm.PointDelivery.txtPointDeliveryLastMessageDateTime')
                      ?.touched &&
                    orderForm?.get('AutoConfirm.PointDelivery.txtPointDeliveryLastMessageDateTime')
                      ?.errors
                  ">
                  Enter Valid Date!
                </mat-error>
              </mat-form-field>
            </div>
            <div formGroupName="BrightShip">
              <div class="col-12">
                <br />
                <h4 class="customThemeClass"><b>BrightShip</b></h4>
              </div>
              <mat-form-field class="col-6">
                <mat-label>Shipping Status</mat-label>
                <input autocomplete="new-txtBrightShipShippingStatus" maxlength="40" formControlName="txtBrightShipShippingStatus" matInput type="text" />
                <mat-error *ngIf="
                    orderForm?.get('AutoConfirm.BrightShip.txtBrightShipShippingStatus')?.touched &&
                    orderForm?.get('AutoConfirm.BrightShip.txtBrightShipShippingStatus')?.errors
                      ?.sAlphabets
                  ">
                  Enter only Alphabets!
                </mat-error>
              </mat-form-field>

              <mat-form-field class="col-6">
                <mat-label>Shipping Carrier</mat-label>
                <mat-select maxlength="40" formControlName="drpBrightShipShippingCarrier"
                  [(value)]="brightShipShippingCarrier">
                  <mat-option *ngFor="let carrier of drpShippingCarrier" [value]="carrier.shortCodeId">
                    {{ carrier.shippingCarrierType }}
                  </mat-option>
                </mat-select>
                <button mat-button (click)="clearBrightShipShippingCarrier()"
                  (click)="brightShipShippingCarrier='';$event.stopPropagation()" *ngIf="brightShipShippingCarrier"
                  matSuffix mat-icon-button >
                  <mat-icon>close</mat-icon>
                </button>
              </mat-form-field>

              <mat-form-field class="col-6">
                <mat-label>Shipping Method</mat-label>
                <mat-select maxlength="40" formControlName="drpBrightShipShippingMethod"
                  [(value)]="brightShipShippingMethod">
                  <mat-option *ngFor="let method of drpShippingMethod" [value]="method.shortCodeId">
                    {{ method.shippingMethodType }}
                  </mat-option>
                </mat-select>
                <button mat-button (click)="clearBrightShipShippingMethod()"
                  (click)="brightShipShippingMethod='';$event.stopPropagation()" *ngIf="brightShipShippingMethod"
                  matSuffix mat-icon-button >
                  <mat-icon>close</mat-icon>
                </button>
              </mat-form-field>
            </div>
          </div>
        </mat-card-content>
      </div>
    </div>

    <br />
    <div class="row">
      <div class="col-lg-6">
        <div class="row">
          <div class="col-md-6">
            <h4 class="customThemeClass"><b>Custom Fields</b></h4>
          </div>
        </div>
        <mat-card-content formGroupName="AuditTrial">
          <div class="row">
            <mat-form-field class="col-6">
              <mat-label>Compliance Usage Met</mat-label>
              <mat-select maxlength="40" formControlName="drpCustomFieldsComplianceUsage"
                [(value)]="customFieldsComplianceUsage">
                <mat-option *ngFor="let complaince of drpComplaince" [value]="complaince.shortCodeId">
                  {{ complaince.complainceUsageMetType }}
                </mat-option>
              </mat-select>
              <button mat-button (click)="clearCustomFieldsComplianceUsage()"
                (click)="customFieldsComplianceUsage='';$event.stopPropagation()" *ngIf="customFieldsComplianceUsage"
                matSuffix mat-icon-button >
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field>
            <mat-form-field class="col-6">
              <mat-label>Authorization Of Order</mat-label>
              <mat-select maxlength="40" formControlName="drpCustomFieldsAuthorizationOrder"
                [(value)]="customFieldsAuthorizationOrder">
                <mat-option *ngFor="let authOrder of drpAuthorization" [value]="authOrder.shortCodeId">
                  {{ authOrder.authorizationOfOrderType }}
                </mat-option>
              </mat-select>
              <button mat-button (click)="clearCustomFieldsAuthorizationOrder()"
                (click)="customFieldsAuthorizationOrder='';$event.stopPropagation()"
                *ngIf="customFieldsAuthorizationOrder" matSuffix mat-icon-button >
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field>
            <mat-form-field class="col-6">
              <mat-label>Mask Order</mat-label>
              <mat-select maxlength="40" formControlName="drpCustomFieldsMaskOrder" [(value)]="customFieldsMaskOrder">
                <mat-option *ngFor="let mask of drpMask" [value]="mask.shortCodeId">
                  {{ mask.maskOrderType }}
                </mat-option>
              </mat-select>
              <button mat-button (click)="clearCustomFieldsMaskOrder()"
                (click)="customFieldsMaskOrder='';$event.stopPropagation()" *ngIf="customFieldsMaskOrder" matSuffix
                mat-icon-button >
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field>
            <mat-form-field class="col-6">
              <mat-label>Head Gear</mat-label>
              <mat-select maxlength="40" formControlName="drpCustomFieldsHeadGear" [(value)]="customFieldsHeadGear">
                <mat-option *ngFor="let head of drpHead" [value]="head.shortCodeId">
                  {{ head.headGearType }}
                </mat-option>
              </mat-select>
              <button mat-button (click)="clearCustomFieldsHeadGear()"
                (click)="customFieldsHeadGear='';$event.stopPropagation()" *ngIf="customFieldsHeadGear" matSuffix
                mat-icon-button >
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field>
            <mat-form-field class="col-6">
              <mat-label>Tubing</mat-label>
              <mat-select maxlength="40" formControlName="drpCustomFieldsTubing" [(value)]="customFieldsTubing">
                <mat-option *ngFor="let tube of drpTubing" [value]="tube.shortCodeId">
                  {{ tube.tubingType }}
                </mat-option>
              </mat-select>
              <button mat-button (click)="clearCustomFieldsTubing()"
                (click)="customFieldsTubing='';$event.stopPropagation()" *ngIf="customFieldsTubing" matSuffix
                mat-icon-button >
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field>
            <mat-form-field class="col-6">
              <mat-label>Cushions/Pillow</mat-label>
              <mat-select maxlength="40" formControlName="drpCustomFieldsCushionsPillow"
                [(value)]="customFieldsCushionsPillow">
                <mat-option *ngFor="let pillow of drpPillow" [value]="pillow.shortCodeId">
                  {{ pillow.cushionsPillowType }}
                </mat-option>
              </mat-select>
              <button mat-button (click)="clearCustomFieldsCushionsPillow()"
                (click)="customFieldsCushionsPillow='';$event.stopPropagation()" *ngIf="customFieldsCushionsPillow"
                matSuffix mat-icon-button >
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field>
            <mat-form-field class="col-6">
              <mat-label>Disp.Filters</mat-label>
              <mat-select maxlength="40" formControlName="drpCustomFieldsDispFilters"
                [(value)]="customFieldsDispFilters">
                <mat-option *ngFor="let disp of drpDisp" [value]="disp.shortCodeId">
                  {{ disp.dispFiltersType }}
                </mat-option>
              </mat-select>
              <button mat-button (click)="clearCustomFieldsDispFilters()"
                (click)="customFieldsDispFilters='';$event.stopPropagation()" *ngIf="customFieldsDispFilters" matSuffix
                mat-icon-button >
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field>
            <mat-form-field class="col-6">
              <mat-label>Reusable Filters</mat-label>
              <mat-select maxlength="40" formControlName="drpCustomFieldsReusableFilters"
                [(value)]="customFieldsReusableFilters">
                <mat-option *ngFor="let reusable of drpReusable" [value]="reusable.shortCodeId">
                  {{ reusable.reusableFiltersType }}
                </mat-option>
              </mat-select>
              <button mat-button (click)="clearCustomFieldsReusableFilters()"
                (click)="customFieldsReusableFilters='';$event.stopPropagation()" *ngIf="customFieldsReusableFilters"
                matSuffix mat-icon-button >
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field>
            <mat-form-field class="col-6">
              <mat-label>Water Chamber</mat-label>
              <mat-select maxlength="40" formControlName="drpCustomFieldsWaterChamber"
                [(value)]="customFieldsWaterChamber">
                <mat-option *ngFor="let water of drpWater" [value]="water.shortCodeId">
                  {{ water.waterChamberType }}
                </mat-option>
              </mat-select>
              <button mat-button (click)="clearCustomFieldsWaterChamber()"
                (click)="customFieldsWaterChamber='';$event.stopPropagation()" *ngIf="customFieldsWaterChamber"
                matSuffix mat-icon-button >
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field>
          </div>
        </mat-card-content>
      </div>
      <div class="col-lg-6">
        <div class="row">
          <div class="col-md-6">
            <h4 class="customThemeClass"><b>Work In Progress</b></h4>
          </div>
        </div>
        <mat-card-content>
          <div class="row">
            <div formGroupName="AutoConfirm">
              <mat-form-field class="col-6" formGroupName="WorkProgress">
                <mat-label>Days In State</mat-label>
                <input autocomplete="new-txtWorkProgressDaysInState" maxlength="40" formControlName="txtWorkProgressDaysInState" matInput type="text" />
                <mat-error *ngIf="
                    orderForm?.get('AutoConfirm.WorkProgress.txtWorkProgressDaysInState')?.touched &&
                    orderForm?.get('AutoConfirm.WorkProgress.txtWorkProgressDaysInState')?.errors
                      ?.sAlphabets
                  ">
                  Enter only Alphabets!
                </mat-error>
              </mat-form-field>
              <mat-form-field class="col-6" formGroupName="WorkProgress">
                <mat-label>WIP State</mat-label>
                <mat-select maxlength="40" formControlName="drpWorkProgressWIPState" [(value)]="workProgressWIPState">
                  <mat-option *ngFor="let wip of drpWipState" [value]="wip.shortCodeId">
                    {{ wip.wipStateType }}
                  </mat-option>
                </mat-select>
                <button mat-button (click)="clearWorkProgressWIPState()"
                  (click)="workProgressWIPState='';$event.stopPropagation()" *ngIf="workProgressWIPState" matSuffix
                  mat-icon-button >
                  <mat-icon>close</mat-icon>
                </button>
              </mat-form-field>
              <mat-form-field class="col-6" formGroupName="WorkProgress">
                <mat-label>Assigned To</mat-label>
                <input autocomplete="new-txtWorkProgressAssignedTo" maxlength="40" formControlName="txtWorkProgressAssignedTo" matInput type="text" />
                <mat-error *ngIf="
                    orderForm?.get('AutoConfirm.WorkProgress.txtWorkProgressAssignedTo')?.touched &&
                    orderForm?.get('AutoConfirm.WorkProgress.txtWorkProgressAssignedTo')?.errors
                      ?.sAlphabets
                  ">
                  Enter only Alphabets!
                </mat-error>
              </mat-form-field>
              <mat-form-field class="col-6" formGroupName="WorkProgress">
                <mat-label>Date Needed</mat-label>
                <input autocomplete="new-txtWorkProgressDateNeeded" maxlength="40" formControlName="txtWorkProgressDateNeeded" matInput
                  [matDatepicker]="txtWorkProgressDateNeeded" placeholder="(MM/DD/YYYY)" />
                <mat-datepicker-toggle matSuffix [for]="txtWorkProgressDateNeeded"></mat-datepicker-toggle>
                <mat-datepicker #txtWorkProgressDateNeeded></mat-datepicker>
                <mat-error *ngIf="
                    orderForm?.get('AutoConfirm.WorkProgress.txtWorkProgressDateNeeded')?.touched &&
                    orderForm?.get('AutoConfirm.WorkProgress.txtWorkProgressDateNeeded')?.errors
                  ">
                  Enter Valid Date!
                </mat-error>

              </mat-form-field>
              <div class="col-12" formGroupName="WorkProgress">
                <!-- <br /> -->
                <mat-checkbox class="col-6" style="padding-left: initial" *ngIf="show" [disabled]="true"
                  [disabled]="!orderStatus" (change)="isCompeltedConfirmation()"
                  formControlName="chkWorkProgressCompleted">
                  <b>Completed</b>
                </mat-checkbox>
                <mat-checkbox class="col-6 blinking" style="padding-left: initial" *ngIf="show1"
                  [disabled]="!orderStatus" (change)="isCompeltedConfirmation()"
                  formControlName="chkWorkProgressCompleted">
                  <b>Completed</b>
                </mat-checkbox>
                <mat-form-field class="col-6">
                  <mat-label>Reschedule Date</mat-label>
                  <input autocomplete="new-txtRescheduleDate" [min]="reScheduledDate" maxlength="40" formControlName="txtRescheduleDate" matInput
                    [matDatepicker]="txtRescheduleDate" placeholder="(MM/DD/YYYY)" />
                  <mat-datepicker-toggle matSuffix [for]="txtRescheduleDate"></mat-datepicker-toggle>
                  <mat-datepicker #txtRescheduleDate></mat-datepicker>
                  <mat-error *ngIf="
                      orderForm?.get('AutoConfirm.WorkProgress.txtRescheduleDate')?.touched &&
                      orderForm?.get('AutoConfirm.WorkProgress.txtRescheduleDate')?.errors
                    ">
                    Enter Valid Date!
                  </mat-error>
                  <mat-error
                    *ngIf=" scheduledDate>=orderForm?.get('AutoConfirm.WorkProgress.txtRescheduleDate')?.value && !(orderForm?.get('AutoConfirm.WorkProgress.txtRescheduleDate')?.touched && orderForm?.get('AutoConfirm.WorkProgress.txtRescheduleDate')?.errors)">
                    Enter Future Dates Only!
                  </mat-error>
                </mat-form-field>
              </div>
              <!-- <div class="col-md-6 blinking" *ngIf="show1" formGroupName="WorkProgress">
                <br />
                <mat-checkbox (change)="isCompeltedConfirmation()" formControlName="chkWorkProgressCompleted">
                  <b>Completed</b>
                </mat-checkbox>
              </div> -->
              <!-- <mat-form-field class="col-6">
                <mat-label>Reschedule Date</mat-label>
                <input maxlength="40" formControlName="txtRescheduleDate" matInput
                  [matDatepicker]="txtRescheduleDate" placeholder="(MM/DD/YYYY)" />
                <mat-datepicker-toggle matSuffix [for]="txtRescheduleDate"></mat-datepicker-toggle>
                <mat-datepicker #txtRescheduleDate></mat-datepicker>
                <mat-error *ngIf="
                    orderForm?.get('AutoConfirm.WorkProgress.txtRescheduleDate')?.touched &&
                    orderForm?.get('AutoConfirm.WorkProgress.txtRescheduleDate')?.errors
                  ">
                  Enter Valid Date!
                </mat-error>
              </mat-form-field> -->
            </div>
            <div formGroupName="Tracking">
              <div class="col-12">
                <br />
                <h4 class="customThemeClass"><b>Tracking</b></h4>
              </div>
              <mat-form-field class="col-6">
                <mat-label>Carrier</mat-label>
                <input autocomplete="new-txtTrackingCarrier" maxlength="40" formControlName="txtTrackingCarrier" matInput type="text" />
                <mat-error *ngIf="
                    orderForm?.get('Tracking.txtTrackingCarrier')?.touched &&
                    orderForm?.get('Tracking.txtTrackingCarrier')?.errors?.sAlphabets
                  ">
                  Enter only Alphabets!
                </mat-error>
              </mat-form-field>
              <mat-form-field class="col-6">
                <mat-label>Tracking No</mat-label>
                <input autocomplete="new-txtTrackingTrackingNo" maxlength="15" formControlName="txtTrackingTrackingNo" matInput type="text" />
                <mat-error *ngIf="
                  orderForm?.get('Tracking.txtTrackingTrackingNo')?.touched &&
                  orderForm?.get('Tracking.txtTrackingTrackingNo')?.errors?.number
                ">
                  Enter only Numbers!
                </mat-error>
              </mat-form-field>
            </div>
          </div>
        </mat-card-content>
      </div>
    </div>
    <br />
    <!-- <app-table [strPageType]="strPageType" >
    </app-table>   -->
    <br />
    <div class="row">
      <div class="col-sm-12">
        <div class="text-lg-center">
          <button mat-button *ngIf="orderStatus" (click)="saveOrder()" [disabled]=" saveButtonHide||orderFormStatus"
            class="buttonColor">Save
            <mat-icon *ngIf='isShowSpinner'>
              <mat-spinner class="spinner-border spinner-border-sm" diameter="20"> </mat-spinner>
            </mat-icon>
          </button>
        </div>
      </div>
    </div>

    <div class="row mt-2" *ngIf="saveButtonHide">
      <div class="col-sm-4">
      </div>
      <div class="col-sm-4">
        <div class="text-lg-center " *ngIf='isShowSpinner'>
          <div class="alert alert-success" role="alert">
            Please Wait,While Data is Saved!
          </div>
        </div>
      </div>
      <div class="col-sm-4">
      </div>
    </div>
  </form>
</div>
<br />
<br />
