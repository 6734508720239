import { mapEnumToOptions } from '@abp/ng.core';

export enum DXTypes {
  OSA = 'OSA',
  CSA = 'CSA',
  CPAPtoBIPAP = 'CPAP to BIPAP',
  CompSA = 'CompSA',
}

export const dxTypesOptions = mapEnumToOptions(DXTypes);
