import { PagedAndSortedResultRequestDto } from '@abp/ng.core';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { Title } from '@angular/platform-browser';
import { ComplainceDTO, DemographicsDTO, PatientsDTO, PatientsSearchDTO, PrescriptionDTO } from 'projects/patient/src/app/patient-proxy/patient/dto/models';
import { PatientsService } from 'projects/patient/src/app/patient-proxy/patient/patients.service';
import { DateValidator } from 'projects/shared/src/app/validations/date-validator';
import { debounceTime, delay, distinctUntilChanged, filter, map, switchMap, tap } from 'rxjs/operators';
import Swal from 'sweetalert2';


type PatientTable = {
  createdDate?: string,
  dateOfBirth?: string,
  isDoctorSigned?: string,
  nameOfPatient?: string,
  docotrNpi?: string,
  complaincePercent?: string;
  batchId?: string,
  fileId?: string
}

@Component({
  selector: 'app-patient-list',
  templateUrl: './patient-list.component.html',
  styleUrls: ['./patient-list.component.scss']
})
export class PatientListComponent implements OnInit {
  //! Declaration and variable Initialization
  isLoading: boolean = true;
  dataSource: MatTableDataSource<any> = new MatTableDataSource<any>([]);
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatTable, { static: false }) tableRef: MatTable<any>;
  arrDisplayedColumns: string[] = [];
  patientListDataForm: FormGroup;
  constructor(
    private patientsService: PatientsService,
    public fb: FormBuilder,
    private title: Title,
    public dateValidators: DateValidator,
  ) { }
  ngOnInit(): void {
    this.isLoading = true;
    this.patientListDataForm = this.fb.group({
      createdDate: new FormControl("", [this.dateValidators.dateVaidator]),
      dateOfBirth: new FormControl("", [this.dateValidators.dateVaidator]),
      isDoctorSigned: new FormControl(""),
      nameOfPatient: new FormControl(""),
      complaincePercent: new FormControl(""),
      docotrNpi: new FormControl(""),
    })
    this.arrDisplayedColumns = [ 'Options','nameOfPatient', 'dateOfBirth', 'isDoctorSigned', 'complaincePercent', 'docotrNpi', 'createdDate',];
    //! Page Initalization Patients list
    this.patientsService.getList(new PagedAndSortedResultRequestDto).subscribe(response => {
      let data: PatientsDTO[] = response?.items ?? [];
      let tableData: PatientTable[] = [];
      data?.forEach(e => {
        const lastDemoDetails: DemographicsDTO = e?.demographics?.slice(e?.demographics?.length - 1, e?.demographics?.length)[0] || null;
        const lastPrescDetails: PrescriptionDTO = e?.prescriptions?.slice(e?.prescriptions?.length - 1, e?.prescriptions?.length)[0] || null;
        const lastComplDetails: ComplainceDTO = e?.complainces?.slice(e?.complainces?.length - 1, e?.complainces?.length)[0] || null;

        let dateOfBirth: string = "";
        dateOfBirth = lastDemoDetails?.dateOfBirth === null || lastDemoDetails?.dateOfBirth === undefined ? "" : new Date(lastDemoDetails?.dateOfBirth)?.toLocaleDateString("en-US") ?? "";
        const creationTime: string = e?.creationTime === null || e?.creationTime === undefined ? "" : new Date(e?.creationTime)?.toLocaleDateString("en-US") ?? "";
        tableData?.push({
          createdDate: creationTime,
          dateOfBirth: dateOfBirth,
          isDoctorSigned: lastPrescDetails?.isDoctorSigned === 1 ? "Yes" : "No",
          nameOfPatient: lastDemoDetails?.patientName ?? "",
          docotrNpi: lastPrescDetails?.npi ?? "",
          complaincePercent: lastComplDetails?.complaincePercentage ?? "",
          batchId: e?.batchId ?? "",
          fileId: e?.fileId ?? ""
        })
      })
      this.isLoading = false;
      this.dataSource = new MatTableDataSource(tableData ?? []);
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
    }, err => {
      this.isLoading = false;
      this.dataSource = new MatTableDataSource([]);
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    });
    const patientListFormValue$ = this.patientListDataForm?.valueChanges?.pipe(
      filter(e => e && this.patientListDataForm?.valid),
      distinctUntilChanged(),
      debounceTime(300),
      map(response => {
        this.isLoading = true;
        const SearchValues: PatientsSearchDTO = {
          patientName: String(response?.nameOfPatient)?.trim() ?? "",
          dateOfBirth: String(response?.dateOfBirth)?.trim() ?? "",
          createdDate: String(response?.createdDate)?.trim() ?? "",
          complaincePercent: String(response?.complaincePercent)?.trim() ?? "",
          npi: String(response?.docotrNpi)?.trim() ?? "",
          doctorSign: String(response?.isDoctorSigned)?.trim() ?? "",
        };
        return SearchValues
      }),
    )


    patientListFormValue$.pipe(
      switchMap(SearchValues => this.patientsService?.searchPatientsByInput(SearchValues)),
      map(x => x?.items)
    ).subscribe(response => {
      let tableData: PatientTable[] = [];
      const patientListTableData: PatientsDTO[] = response ?? [];
      patientListTableData?.forEach(e => {
        const lastComplDetails: ComplainceDTO = e?.complainces?.slice(e?.complainces?.length - 1, e?.complainces?.length)[0] || null;
        const lastDemoDetails: DemographicsDTO = e?.demographics?.slice(e?.demographics?.length - 1, e?.demographics?.length)[0] || null;
        const lastPrescDetails: PrescriptionDTO = e?.prescriptions?.slice(e?.prescriptions?.length - 1, e?.prescriptions?.length)[0] || null;
        let firstName: string = lastDemoDetails?.firstName ?? "";
        let lastName: string = lastDemoDetails?.lastName ?? "";
        tableData?.push({
          createdDate: (e?.creationTime === null || e?.creationTime === undefined) ? "" : (new Date(e?.creationTime)?.toLocaleDateString("en-US") ?? ""),
          dateOfBirth: (lastDemoDetails?.dateOfBirth === null || lastDemoDetails?.dateOfBirth === undefined) ? "" : (new Date(lastDemoDetails?.dateOfBirth)?.toLocaleDateString("en-US") ?? ""),
          isDoctorSigned: lastPrescDetails?.isDoctorSigned === 1 ? "Yes" : "No",
          nameOfPatient: firstName + " " + lastName,
          docotrNpi: lastPrescDetails?.npi ?? "",
          complaincePercent: lastComplDetails?.complaincePercentage ?? "",
          batchId: e?.batchId ?? "",
          fileId: e?.fileId ?? ""
        })
      })
      this.dataSource = new MatTableDataSource(tableData ?? []);
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
      this.isLoading = false;
    }, err => {
      this.dataSource = new MatTableDataSource([]);
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
      this.isLoading = false;
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    })

  }


  //! Document Viewer with Feilds side by side
  documnetViewerSideBySide(fileId: string) {
    if (fileId) {
      const url = '/#/ViewDocumentDetailsAndApprove/' + [fileId.toString()];
      window.open(url, '_blank');
    }
  }


  //! Search By Form Inputs
  searchByFormInputs(SearchValues: PatientsSearchDTO) {

    return
  }
}

