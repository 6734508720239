import { PagedAndSortedResultRequestDto } from '@abp/ng.core';
import { DatePipe } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { OrganizationUnitService } from 'projects/admin/src/app/admin-proxy/platform-app-management/rcm/platform-management/organization-units/organization-unit.service';
import { IdentityUserUsingTenantIdDto } from 'projects/admin/src/app/admin-proxy/platform-app-management/rcm/platform-management/user/models';
import { UserService } from 'projects/admin/src/app/admin-proxy/platform-app-management/rcm/platform-management/user/user.service';
import { CoverageAreaTypeDTO, LunchBreakHoursStartDTO, OnTimeMasterDropdownDTO, WorkingHoursDTO, WorkingTimeDTO, WorkingWeeksDTO } from 'projects/patient/src/app/dropdown-proxy/dropdown-management/dropdowns/dto/on-time-schedule/models';
import { OnTimeMasterDropdownService } from 'projects/patient/src/app/dropdown-proxy/dropdown-management/dropdowns/master-dropdown-service/on-time-master-dropdown.service';
import { OnTimeDropdowns } from 'projects/patient/src/app/dropdown-proxy/dropdown-management/dropdowns/on-time-dropdowns.enum';
import Swal from 'sweetalert2';
import { ToastrService } from "ngx-toastr";
import { UserWorkingHoursService, WeekDays } from '../onTime-proxy/rcm/on-time-management/on-time-schedule';
import { CoverageAreaService } from '../onTime-proxy/rcm/on-time-management/on-time-schedule/coverage-area.service';
import { CreateUpdateOfficeDetailsDTO, CreateUpdateUserWorkingHoursDTO, GetOfficesDTO } from '../onTime-proxy/rcm/on-time-management/on-time-schedule/dto';


@Component({
  selector: 'app-ontime-user-work-hours',
  templateUrl: './ontime-user-work-hours.component.html',
  styleUrls: ['./ontime-user-work-hours.component.scss'],
  providers: [DatePipe],
})
export class OntimeUserWorkHoursComponent implements OnInit {
  tenanatId: string = "";
  AddWorkForm: FormGroup;
  drpCoverage: CoverageAreaTypeDTO[];
  drpStartEnd: WorkingHoursDTO[];
  drpStartAt: WorkingTimeDTO[] = [];
  drpWorkOptn: WorkingWeeksDTO[];
  arrUsersList: any[] = [];
  arr2UsersList: any[] = [];
  sunday: string = "Leave";
  monday: string = "Leave";
  tuesday: string = "Leave";
  wedday: string = "Leave";
  thuday: string = "Leave";
  Friday: string = "Leave";
  Satday: string = "Leave";
  sundayLeaveHide: boolean = false;
  mondayLeaveHide: boolean = false;
  tuedayLeaveHide: boolean = false;
  weddayLeaveHide: boolean = false;
  thudayLeaveHide: boolean = false;
  fridayLeaveHide: boolean = false;
  satdayLeaveHide: boolean = false;
  sundayHide: boolean = false;
  sunMore: boolean = true;
  monMore: boolean = true;
  tueMore: boolean = true;
  wedMore: boolean = true;
  thuMore: boolean = true;
  friMore: boolean = true;
  satMore: boolean = true;
  mondayHide: boolean = false;
  TuedayHide: boolean = false;
  WeddayHide: boolean = false;
  ThudayHide: boolean = false;
  FridayHide: boolean = false;
  SatdayHide: boolean = false;
  userData: any[] = [];
  branchTableData: any[] = [];
  userTableData: any[] = [];
  bindUserName: string;
  mondayResponse: any;
  sundayResponse: any;
  tuesdayResponse: any;
  wednesdayResponse: any;
  thursdayResponse : any;
  fridayResponse: any;
  saturdayResposnse: any;

  constructor(private formBuilder: FormBuilder,
    public notesDialogRef: MatDialogRef<OntimeUserWorkHoursComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { userId: string, addWorkingId: string, userName: string },
    private onTimeDropdown: OnTimeMasterDropdownService,
    private userWorkHours: UserWorkingHoursService,
    private userService: UserService,
    private coverageAreaService: CoverageAreaService,
    private branchService: OrganizationUnitService,
    private toastr: ToastrService,
    private datepipe: DatePipe,
  ) { }

  ngOnInit(): void {
    this.AddWorkForm = this.formBuilder.group({
      drpWorkOptn: new FormControl(""),
      drpStartAt: new FormControl(""),
      drpStartEnd: new FormControl(""),
      drpSelCov: new FormControl(""),
      AddSundayStatus: new FormControl(""),
      drpUser: new FormControl(""),
      drpSunCoverage: new FormControl(""),
      drpSun2Coverage: new FormControl(""),
      drp2WorkOptn: new FormControl(""),
      drp2StartAt: new FormControl(""),
      drp2StartEnd: new FormControl(""),
      drp2SelCov: new FormControl(""),
      drp2User: new FormControl(""),


      ///monday
      AddMondayStatus: new FormControl(""),
      drpMonWorkOptn: new FormControl(""),
      drpMonStartAt: new FormControl(""),
      drpMonStartEnd: new FormControl(""),
      drpMonSelCov: new FormControl(""),
      drpMonUser: new FormControl(""),
      drpMonCoverage: new FormControl(""),
      drpMon2Coverage: new FormControl(""),

      drpMon2WorkOptn: new FormControl(""),
      drpMon2StartAt: new FormControl(""),
      drpMon2StartEnd: new FormControl(""),
      drpMon2SelCov: new FormControl(""),
      drpMon2User: new FormControl(""),


      ///tuesday
      AddTuesdayStatus: new FormControl(""),
      drpTueWorkOptn: new FormControl(""),
      drpTueStartAt: new FormControl(""),
      drpTueStartEnd: new FormControl(""),
      drpTueSelCov: new FormControl(""),
      drpTueUser: new FormControl(""),
      drpTueCoverage: new FormControl(""),
      drpTue2Coverage: new FormControl(""),

      drpTue2WorkOptn: new FormControl(""),
      drpTue2StartAt: new FormControl(""),
      drpTue2StartEnd: new FormControl(""),
      drpTue2SelCov: new FormControl(""),
      drpTue2User: new FormControl(""),

      ///Wednesday
      AddWednesdayStatus: new FormControl(""),
      drpWedWorkOptn: new FormControl(""),
      drpWedStartAt: new FormControl(""),
      drpWedStartEnd: new FormControl(""),
      drpWedSelCov: new FormControl(""),
      drpWedUser: new FormControl(""),
      drpWedCoverage: new FormControl(""),
      drpWed2Coverage: new FormControl(""),

      drpWed2WorkOptn: new FormControl(""),
      drpWed2StartAt: new FormControl(""),
      drpWed2StartEnd: new FormControl(""),
      drpWed2SelCov: new FormControl(""),
      drpWed2User: new FormControl(""),

      ///Thunesday
      AddThudayStatus: new FormControl(""),
      drpThuWorkOptn: new FormControl(""),
      drpThuStartAt: new FormControl(""),
      drpThuStartEnd: new FormControl(""),
      drpThuSelCov: new FormControl(""),
      drpThuUser: new FormControl(""),
      drpThuCoverage: new FormControl(""),
      drpThu2Coverage: new FormControl(""),

      drpThu2WorkOptn: new FormControl(""),
      drpThu2StartAt: new FormControl(""),
      drpThu2StartEnd: new FormControl(""),
      drpThu2SelCov: new FormControl(""),
      drpThu2User: new FormControl(""),

      ///Frinesday
      AddFridayStatus: new FormControl(""),
      drpFriWorkOptn: new FormControl(""),
      drpFriStartAt: new FormControl(""),
      drpFriStartEnd: new FormControl(""),
      drpFriSelCov: new FormControl(""),
      drpFriUser: new FormControl(""),
      drpFriCoverage: new FormControl(""),
      drpFri2Coverage: new FormControl(""),

      drpFri2WorkOptn: new FormControl(""),
      drpFri2StartAt: new FormControl(""),
      drpFri2StartEnd: new FormControl(""),
      drpFri2SelCov: new FormControl(""),
      drpFri2User: new FormControl(""),

      ///Satday
      AddSatdayStatus: new FormControl(""),
      drpSatWorkOptn: new FormControl(""),
      drpSatStartAt: new FormControl(""),
      drpSatStartEnd: new FormControl(""),
      drpSatSelCov: new FormControl(""),
      drpSatUser: new FormControl(""),
      drpSatCoverage: new FormControl(""),
      drpSat2Coverage: new FormControl(""),

      drpSat2WorkOptn: new FormControl(""),
      drpSat2StartAt: new FormControl(""),
      drpSat2StartEnd: new FormControl(""),
      drpSat2SelCov: new FormControl(""),
      drpSat2User: new FormControl(""),

      drpFromLunch: new FormControl(""),
      txtToLunch: new FormControl("")
    })
    this.getDropDown();
    this.tenanatId = localStorage.getItem("tenantId") ?? "";
    let filters: IdentityUserUsingTenantIdDto = {
      tenantId: this.tenanatId
    }

    this.data.userName
    //get user list

    this.userService.getUserList(filters).subscribe(response => {
      response && response?.forEach(element => {
        // ;
      })
    })


    // get branch list
    this.branchService.getOrganizationList(this.tenanatId).subscribe(response => {
      this.branchTableData = [];
      response && response?.forEach(element => {
        this.branchTableData.push({
          id: element.id,
          branchName: element?.organizationUnitName,
          address: element?.address1,
          contact: element?.phoneNumber,
          createdDate: element?.creationTime === null ? "" : this.datepipe.transform(element?.creationTime, 'MM/dd/yyyy') ?? "",
          status: element?.status,
          tenentId: element?.tenantId,
          workingHoursId: element?.workingHoursId,
          officeType: element?.officeType,
          email: element?.emailAddress
        });
      })
      if (this.data.addWorkingId !== "00000000-0000-0000-0000-000000000000" || this.data.addWorkingId !== null) {
        this.getAddHoursById()
      }
    }, err => {
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    })

  }


  /// dropdown datas
  drpFromLunch: LunchBreakHoursStartDTO[];

  getDropDown() {
    let onTimeDropdownInput: OnTimeDropdowns[];
    onTimeDropdownInput = [
      OnTimeDropdowns.CoverageAreaType,
      OnTimeDropdowns.WorkingWeeks,
      OnTimeDropdowns.WorkingHoursStart,
      OnTimeDropdowns.LunchBreakHoursStart,
    ]
    this.onTimeDropdown.getOnTimeDropdownsByInput(onTimeDropdownInput).subscribe((stateResponse) => {
      let response: OnTimeMasterDropdownDTO = stateResponse;
      this.drpCoverage = response?.coverageAreaTypes;
      this.drpWorkOptn = response?.workingWeeks;
      this.drpFromLunch = response?.lunchBreakHoursStarts

      // this.drpStartAt = response?.workingHoursStarts;
    }, err => {
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    });
    this.onTimeDropdown.getWorkTimings().subscribe((stateResponse) => {
      this.drpStartAt = stateResponse.workingTimes

      // stateResponse.workingTimes.forEach(ele => {
      // this.drpStartAt.push({
      //   startAt: ele?.startTime
      // })
      // })
    })

  }

  LunchChanges(event) {

    this.onTimeDropdown.getLunchBreakEndTimingsBySLunchBreakStartShortCodeId(event).subscribe(stateResponse => {
      this.AddWorkForm.patchValue({
        txtToLunch: stateResponse
      })
    })
  }
  // get Add Hours byid
  getAddHoursById() {

    // for second array edit open
    this.userWorkHours.get(this.data.addWorkingId).subscribe(response => {
      this.sundayResponse = response?.userSundayWorkingHours.userWorkDetails[1]?.officeDetails
      this.mondayResponse = response?.userMondayWorkingHours.userWorkDetails[1]?.officeDetails
      this.tuesdayResponse = response?.userTuesdayWorkingHours.userWorkDetails[1]?.officeDetails
      this.wednesdayResponse = response?.userWednesdayWorkingHours.userWorkDetails[1]?.officeDetails
      this.thursdayResponse = response?.userThursdayWorkingHours.userWorkDetails[1]?.officeDetails
      this.fridayResponse = response?.userFridayWorkingHours.userWorkDetails[1]?.officeDetails
      this.saturdayResposnse = response?.userSaturdayWorkingHours.userWorkDetails[1]?.officeDetails

      if(this.sundayResponse?.length !== 0 && this.sundayResponse?.length !== undefined){
        this.addAccordion();
      }
      if (this.mondayResponse?.length !== 0 && this.mondayResponse?.length !=undefined) {
        this.addMonday();
      }
      if (this.tuesdayResponse?.length !== 0 && this.tuesdayResponse?.length !=undefined) {
        this.addTueday();
      }
      if (this.wednesdayResponse?.length !== 0 && this.wednesdayResponse?.length !=undefined) {
        this.addWedday();
      }
      if (this.thursdayResponse?.length !== 0 && this.thursdayResponse?.length !=undefined) {
        this.addThuday();
      }
      if (this.fridayResponse?.length !== 0 && this.fridayResponse?.length !=undefined) {
        this.addFriday();
      }
      if (this.saturdayResposnse?.length !== 0 && this.saturdayResposnse?.length !=undefined) {
        this.addSatday();
      }

      // let res = response?.
      let sundayStart;
      let mondayStart;
      let tuedayStart;
      let weddayStart;
      let thudayStart;
      let fridayStart;
      let satdayStart;
      let sunday2Start;
      let monday2Start;
      let tueday2Start;
      let wedday2Start;
      let thuday2Start;
      let friday2Start;
      let satday2Start;
      sundayStart = this.drpStartAt?.filter(value => {
        return value && value?.startTimeShortCodeId === response?.userSundayWorkingHours?.userWorkDetails[0]?.startAt
      })[0];

      sunday2Start = this.drpStartAt?.filter(value => {
        return value && value?.startTimeShortCodeId === response?.userSundayWorkingHours?.userWorkDetails[1]?.startAt
      })[0];

      mondayStart = this.drpStartAt?.filter(value => {
        return value && value?.startTimeShortCodeId === response?.userMondayWorkingHours?.userWorkDetails[0]?.startAt
      })[0];

      monday2Start = this.drpStartAt?.filter(value => {
        return value && value?.startTimeShortCodeId === response?.userMondayWorkingHours?.userWorkDetails[1]?.startAt
      })[0];

      tuedayStart = this.drpStartAt?.filter(value => {
        return value && value?.startTimeShortCodeId === response?.userTuesdayWorkingHours?.userWorkDetails[0]?.startAt
      })[0];
      tueday2Start = this.drpStartAt?.filter(value => {
        return value && value?.startTimeShortCodeId === response?.userTuesdayWorkingHours?.userWorkDetails[1]?.startAt
      })[0];

      weddayStart = this.drpStartAt?.filter(value => {
        return value && value?.startTimeShortCodeId === response?.userWednesdayWorkingHours?.userWorkDetails[0]?.startAt
      })[0];
      wedday2Start = this.drpStartAt?.filter(value => {
        return value && value?.startTimeShortCodeId === response?.userWednesdayWorkingHours?.userWorkDetails[1]?.startAt
      })[0];

      thudayStart = this.drpStartAt?.filter(value => {
        return value && value?.startTimeShortCodeId === response?.userThursdayWorkingHours?.userWorkDetails[0]?.startAt
      })[0];
      thuday2Start = this.drpStartAt?.filter(value => {
        return value && value?.startTimeShortCodeId === response?.userThursdayWorkingHours?.userWorkDetails[1]?.startAt
      })[0];

      fridayStart = this.drpStartAt?.filter(value => {
        return value && value?.startTimeShortCodeId === response?.userFridayWorkingHours?.userWorkDetails[0]?.startAt
      })[0];
      friday2Start = this.drpStartAt?.filter(value => {
        return value && value?.startTimeShortCodeId === response?.userFridayWorkingHours?.userWorkDetails[1]?.startAt
      })[0];

      satdayStart = this.drpStartAt?.filter(value => {
        return value && value?.startTimeShortCodeId === response?.userSaturdayWorkingHours?.userWorkDetails[0]?.startAt
      })[0];
      satday2Start = this.drpStartAt?.filter(value => {
        return value && value?.startTimeShortCodeId === response?.userSaturdayWorkingHours?.userWorkDetails[1]?.startAt
      })[0];

      if (response?.userSundayWorkingHours?.isWorking == 1) {
        this.sunday = "Working"
        this.sundayLeaveHide = true;
      }
      if (response?.userSundayWorkingHours?.isWorking == 0) {
        this.sunday = "Leave"
        this.sundayLeaveHide = false;
        this.AddWorkForm.patchValue({
          drpSunCoverage: "",
          drpWorkOptn: "",
          drpStartAt: "",
          drpStartEnd: "",
          drpUser: "",
          drpSun2Coverage: "",
          drp2WorkOptn: "",
          drp2StartAt: "",
          drp2StartEnd: "",
          drp2User: "",
        })
      }

      if (response?.userMondayWorkingHours?.isWorking == 1) {
        this.monday = "Working"
        this.mondayLeaveHide = true;
      }
      if (response?.userMondayWorkingHours?.isWorking == 0) {
        this.monday = "Leave"
        this.mondayLeaveHide = false;
        this.AddWorkForm.patchValue({
          drpMonCoverage: "",
          drpMonWorkOptn: "",
          drpMonStartAt: "",
          drpMonStartEnd: "",
          drpMonUser: "",
          drpMon2Coverage: "",
          drpMon2WorkOptn: "",
          drpMon2StartAt: "",
          drpMon2StartEnd: "",
          drpMon2User: "",
          drpMon2SelCov: "",
          drpMonSelCov: "",
        })
      }


      if (response?.userTuesdayWorkingHours?.isWorking == 1) {
        this.tuesday = "Working"
        this.tuedayLeaveHide = true;
      }
      if (response?.userTuesdayWorkingHours?.isWorking == 0) {
        this.tuesday = "Leave"
        this.tuedayLeaveHide = false;
        this.AddWorkForm.patchValue({
          drpTueWorkOptn: "",
          drpTueStartAt: "",
          drpTueStartEnd: "",
          drpTueSelCov: "",
          drpTueUser: "",
          drpTueCoverage: "",
          drpTue2Coverage: "",
          drpTue2WorkOptn: "",
          drpTue2StartAt: "",
          drpTue2StartEnd: "",
          drpTue2SelCov: "",
          drpTue2User: "",
        })
      }


      if (response?.userWednesdayWorkingHours?.isWorking == 1) {
        this.wedday = "Working"
        this.weddayLeaveHide = true;

      }
      if (response?.userWednesdayWorkingHours?.isWorking == 0) {
        this.wedday = "Leave"
        this.weddayLeaveHide = false;
        this.AddWorkForm.patchValue({
          drpWedWorkOptn: "",
          drpWedStartAt: "",
          drpWedStartEnd: "",
          drpWedSelCov: "",
          drpWedUser: "",
          drpWedCoverage: "",
          drpWed2Coverage: "",
          drpWed2WorkOptn: "",
          drpWed2StartAt: "",
          drpWed2StartEnd: "",
          drpWed2SelCov: "",
          drpWed2User: "",
        })
      }


      if (response?.userThursdayWorkingHours?.isWorking == 1) {
        this.thuday = "Working"
        this.thudayLeaveHide = true;
      }
      if (response?.userThursdayWorkingHours?.isWorking == 0) {
        this.thuday = "Leave"
        this.thudayLeaveHide = false;
        this.AddWorkForm.patchValue({
          drpThuWorkOptn: "",
          drpThuStartAt: "",
          drpThuStartEnd: "",
          drpThuSelCov: "",
          drpThuUser: "",
          drpThuCoverage: "",
          drpThu2Coverage: "",
          drpThu2WorkOptn: "",
          drpThu2StartAt: "",
          drpThu2StartEnd: "",
          drpThu2SelCov: "",
          drpThu2User: "",
        })
      }


      if (response?.userFridayWorkingHours?.isWorking == 1) {
        this.Friday = "Working"
        this.fridayLeaveHide = true;
      }
      if (response?.userFridayWorkingHours?.isWorking == 0) {
        this.Friday = "Leave"
        this.fridayLeaveHide = false;
        this.AddWorkForm.patchValue({
          drpFriWorkOptn: "",
          drpFriStartAt: "",
          drpFriStartEnd: "",
          drpFriSelCov: "",
          drpFriUser: "",
          drpFriCoverage: "",
          drpFri2Coverage: "",
          drpFri2WorkOptn: "",
          drpFri2StartAt: "",
          drpFri2StartEnd: "",
          drpFri2SelCov: "",
          drpFri2User: "",
        })
      }


      if (response?.userSaturdayWorkingHours?.isWorking == 1) {
        this.Satday = "Working"
        this.satdayLeaveHide = true;
      }
      if (response?.userSaturdayWorkingHours?.isWorking == 0) {
        this.Satday = "Leave"
        this.AddWorkForm.patchValue({
          drpSatWorkOptn: "",
          drpSatStartAt: "",
          drpSatStartEnd: "",
          drpSatSelCov: "",
          drpSatUser: "",
          drpSatCoverage: "",
          drpSat2Coverage: "",

          drpSat2WorkOptn: "",
          drpSat2StartAt: "",
          drpSat2StartEnd: "",
          drpSat2SelCov: "",
          drpSat2User: "",
        })
      }

      this.arrUsersList = response?.userSundayWorkingHours?.userWorkDetails[0]?.officeDetails
      this.arr2UsersList = response?.userSundayWorkingHours?.userWorkDetails[1]?.officeDetails,

        this.arrMonUsersList = response?.userMondayWorkingHours?.userWorkDetails[0]?.officeDetails,
        this.arrMon2UsersList = response?.userMondayWorkingHours?.userWorkDetails[1]?.officeDetails,

        this.arrTueUsersList = response?.userTuesdayWorkingHours?.userWorkDetails[0]?.officeDetails
      this.arrTue2UsersList = response?.userTuesdayWorkingHours?.userWorkDetails[1]?.officeDetails

      this.arrWedUsersList = response?.userWednesdayWorkingHours?.userWorkDetails[0]?.officeDetails
      this.arrWed2UsersList = response?.userWednesdayWorkingHours?.userWorkDetails[1]?.officeDetails

      this.arrThuUsersList = response?.userThursdayWorkingHours?.userWorkDetails[0]?.officeDetails
      this.arrThu2UsersList = response?.userThursdayWorkingHours?.userWorkDetails[1]?.officeDetails

      this.arrFriUsersList = response?.userFridayWorkingHours?.userWorkDetails[0]?.officeDetails
      this.arrFri2UsersList = response?.userFridayWorkingHours?.userWorkDetails[1]?.officeDetails

      this.arrSatUsersList = response?.userSaturdayWorkingHours?.userWorkDetails[0]?.officeDetails
      this.arrSat2UsersList = response?.userSaturdayWorkingHours?.userWorkDetails[1]?.officeDetails


      this.AddWorkForm.patchValue({

        drpWorkOptn: response?.userSundayWorkingHours?.userWorkDetails[0]?.weekOption,
        drpStartAt: sundayStart,
        drpStartEnd: response?.userSundayWorkingHours?.userWorkDetails[0]?.endAt,
        AddSundayStatus: response?.userSundayWorkingHours?.isWorking == 1 ? true : false,
        drpUser: response?.userSundayWorkingHours?.userWorkDetails[0]?.officeDetails[0]?.officeId,
        drpSunCoverage: response?.userSundayWorkingHours?.userWorkDetails[0]?.coverageArea,

        drp2WorkOptn: response?.userSundayWorkingHours?.userWorkDetails[1]?.weekOption,
        drp2StartAt: sunday2Start,
        drp2StartEnd: response?.userSundayWorkingHours?.userWorkDetails[1]?.endAt,
        drp2User: response?.userSundayWorkingHours?.userWorkDetails[1]?.officeDetails[0]?.officeId,
        drpSun2Coverage: response?.userSundayWorkingHours?.userWorkDetails[1]?.coverageArea,

        ///monday
        AddMondayStatus: response?.userMondayWorkingHours?.isWorking == 1 ? true : false,
        drpMonWorkOptn: response?.userMondayWorkingHours?.userWorkDetails[0]?.weekOption,
        drpMonStartAt: mondayStart,
        drpMonStartEnd: response?.userMondayWorkingHours?.userWorkDetails[0]?.endAt,
        drpMonUser: response?.userMondayWorkingHours?.userWorkDetails[0]?.officeDetails[0]?.officeId,
        drpMonCoverage: response?.userMondayWorkingHours?.userWorkDetails[0]?.coverageArea,

        drpMon2WorkOptn: response?.userMondayWorkingHours?.userWorkDetails[1]?.weekOption,
        drpMon2StartAt: monday2Start,
        drpMon2StartEnd: response?.userMondayWorkingHours?.userWorkDetails[1]?.endAt,
        drpMon2User: response?.userMondayWorkingHours?.userWorkDetails[1]?.officeDetails[0]?.officeId,
        drpMon2Coverage: response?.userMondayWorkingHours?.userWorkDetails[1]?.coverageArea,
        ///tuesday
        AddTuesdayStatus: response?.userTuesdayWorkingHours?.isWorking == 1 ? true : false,
        drpTueWorkOptn: response?.userTuesdayWorkingHours?.userWorkDetails[0]?.weekOption,
        drpTueStartAt: tuedayStart,
        drpTueStartEnd: response?.userTuesdayWorkingHours?.userWorkDetails[0]?.endAt,
        drpTueUser: response?.userTuesdayWorkingHours?.userWorkDetails[0]?.officeDetails[0]?.officeId,
        drpTueCoverage: response?.userTuesdayWorkingHours?.userWorkDetails[0]?.coverageArea,

        drpTue2WorkOptn: response?.userTuesdayWorkingHours?.userWorkDetails[1]?.weekOption,
        drpTue2StartAt: tueday2Start,
        drpTue2StartEnd: response?.userTuesdayWorkingHours?.userWorkDetails[1]?.endAt,
        drpTue2User: response?.userTuesdayWorkingHours?.userWorkDetails[1]?.officeDetails[0]?.officeId,
        drpTue2Coverage: response?.userTuesdayWorkingHours?.userWorkDetails[1]?.coverageArea,

        ///Wednesday
        AddWednesdayStatus: response?.userWednesdayWorkingHours?.isWorking == 1 ? true : false,
        drpWedWorkOptn: response?.userWednesdayWorkingHours?.userWorkDetails[0]?.weekOption,
        drpWedStartAt: weddayStart,
        drpWedStartEnd: response?.userWednesdayWorkingHours?.userWorkDetails[0]?.endAt,
        drpWedUser: response?.userWednesdayWorkingHours?.userWorkDetails[0]?.officeDetails[0]?.officeId,
        drpWedCoverage: response?.userWednesdayWorkingHours?.userWorkDetails[0]?.coverageArea,

        drpWed2WorkOptn: response?.userWednesdayWorkingHours?.userWorkDetails[1]?.weekOption,
        drpWed2StartAt: wedday2Start,
        drpWed2StartEnd: response?.userWednesdayWorkingHours?.userWorkDetails[1]?.endAt,
        drpWed2User: response?.userWednesdayWorkingHours?.userWorkDetails[1]?.officeDetails[0]?.officeId,
        drpWed2Coverage: response?.userWednesdayWorkingHours?.userWorkDetails[1]?.coverageArea,

        ///Thunesday
        AddThudayStatus: response?.userThursdayWorkingHours?.isWorking == 1 ? true : false,
        drpThuWorkOptn: response?.userThursdayWorkingHours?.userWorkDetails[0]?.weekOption,
        drpThuStartAt: thudayStart,
        drpThuStartEnd: response?.userThursdayWorkingHours?.userWorkDetails[0]?.endAt,
        drpThuUser: response?.userThursdayWorkingHours?.userWorkDetails[0]?.officeDetails[0]?.officeId,
        drpThuCoverage: response?.userThursdayWorkingHours?.userWorkDetails[0]?.coverageArea,

        drpThu2WorkOptn: response?.userThursdayWorkingHours?.userWorkDetails[1]?.weekOption,
        drpThu2StartAt: thuday2Start,
        drpThu2StartEnd: response?.userThursdayWorkingHours?.userWorkDetails[1]?.endAt,
        drpThu2User: response?.userThursdayWorkingHours?.userWorkDetails[1]?.officeDetails[0]?.officeId,
        drpThu2Coverage: response?.userThursdayWorkingHours?.userWorkDetails[1]?.coverageArea,

        ///Frinesday
        AddFridayStatus: response?.userFridayWorkingHours?.isWorking == 1 ? true : false,
        drpFriWorkOptn: response?.userFridayWorkingHours?.userWorkDetails[0]?.weekOption,
        drpFriStartAt: fridayStart,
        drpFriStartEnd: response?.userFridayWorkingHours?.userWorkDetails[0]?.endAt,
        drpFriUser: response?.userFridayWorkingHours?.userWorkDetails[0]?.officeDetails[0]?.officeId,
        drpFriCoverage: response?.userFridayWorkingHours?.userWorkDetails[0]?.coverageArea,

        drpFri2WorkOptn: response?.userFridayWorkingHours?.userWorkDetails[1]?.weekOption,
        drpFri2StartAt: friday2Start,
        drpFri2StartEnd: response?.userFridayWorkingHours?.userWorkDetails[1]?.endAt,
        drpFri2User: response?.userFridayWorkingHours?.userWorkDetails[1]?.officeDetails[0]?.officeId,
        drpFri2Coverage: response?.userFridayWorkingHours?.userWorkDetails[1]?.coverageArea,


        ///Satday
        AddSatdayStatus: response?.userSaturdayWorkingHours?.isWorking == 1 ? true : false,
        drpSatWorkOptn: response?.userSaturdayWorkingHours?.userWorkDetails[0]?.weekOption,
        drpSatStartAt: satdayStart,
        drpSatStartEnd: response?.userSaturdayWorkingHours?.userWorkDetails[0]?.endAt,
        drpSatUser: response?.userSaturdayWorkingHours?.userWorkDetails[0]?.officeDetails[0]?.officeId,
        drpSatCoverage: response?.userSaturdayWorkingHours?.userWorkDetails[0]?.coverageArea,

        drpSat2WorkOptn: response?.userSaturdayWorkingHours?.userWorkDetails[1]?.weekOption,
        drpSat2StartAt: satday2Start,
        drpSat2StartEnd: response?.userSaturdayWorkingHours?.userWorkDetails[1]?.endAt,
        drpSat2User: response?.userSaturdayWorkingHours?.userWorkDetails[1]?.officeDetails[0]?.officeId,
        drpSat2Coverage: response?.userSaturdayWorkingHours?.userWorkDetails[1]?.coverageArea,
        drpFromLunch: response?.userLunchBreakTimings?.fromTime,
        txtToLunch: response?.userLunchBreakTimings?.toTime
      })
    })
  }
  //! add sunday more
  addAccordion() {
    this.sundayHide = true;
    this.sunMore = false
  }
  addMonday() {
    this.mondayHide = true;
    this.monMore = false
  }
  addTueday() {
    this.TuedayHide = true;
    this.tueMore = false
  }

  addWedday() {
    this.WeddayHide = true;
    this.wedMore = false
  }
  addThuday() {
    this.ThudayHide = true;
    this.thuMore = false
  }
  addFriday() {
    this.FridayHide = true;
    this.friMore = false
  }

  addSatday() {
    this.SatdayHide = true;
    this.satMore = false
  }


  //! save function   startTimeShortCodeId
  getWorkingSave() {
    let AddWorkingHours: CreateUpdateUserWorkingHoursDTO;
    let sundayofficeDetailsDTO: CreateUpdateOfficeDetailsDTO[] = [];
    let sunday2officeDetailsDTO: CreateUpdateOfficeDetailsDTO[] = [];
    let mondayofficeDetailsDTO: CreateUpdateOfficeDetailsDTO[] = [];
    let monday2officeDetailsDTO: CreateUpdateOfficeDetailsDTO[] = [];
    let tuesdayofficeDetailsDTO: CreateUpdateOfficeDetailsDTO[] = [];
    let tuesday2officeDetailsDTO: CreateUpdateOfficeDetailsDTO[] = []
    let wednesdayofficeDetailsDTO: CreateUpdateOfficeDetailsDTO[] = [];
    let wednesday2officeDetailsDTO: CreateUpdateOfficeDetailsDTO[] = [];
    let thursdayofficeDetailsDTO: CreateUpdateOfficeDetailsDTO[] = [];
    let thursday2officeDetailsDTO: CreateUpdateOfficeDetailsDTO[] = [];
    let fridayofficeDetailsDTO: CreateUpdateOfficeDetailsDTO[] = [];
    let friday2officeDetailsDTO: CreateUpdateOfficeDetailsDTO[] = [];
    let saturdayofficeDetailsDTO: CreateUpdateOfficeDetailsDTO[] = [];
    let saturday2officeDetailsDTO: CreateUpdateOfficeDetailsDTO[] = [];

    // sunday
    if (this.AddWorkForm.value.drpUser == "" || this.AddWorkForm.value.drpUser == null || this.AddWorkForm.value.drpUser == undefined) {
      sundayofficeDetailsDTO = [];
    } else {
      sundayofficeDetailsDTO?.push({
        officeId: this.AddWorkForm.value.drpUser == "" ? null : this.AddWorkForm.value.drpUser,
        officeName: null
      })
    }

    // Add more sunday
    if (this.AddWorkForm.value.drp2User == "" || this.AddWorkForm.value.drp2User == null || this.AddWorkForm.value.drp2User == undefined) {
      sunday2officeDetailsDTO = [];
    } else {
      sunday2officeDetailsDTO?.push({
        officeId: this.AddWorkForm.value.drp2User == "" ? null : this.AddWorkForm.value.drp2User,
        officeName: null
      })
    }

    // monday
    if (this.AddWorkForm.value.drpMonUser == "" || this.AddWorkForm.value.drpMonUser == null || this.AddWorkForm.value.drpMonUser == undefined) {
      mondayofficeDetailsDTO = [];
    } else {
      mondayofficeDetailsDTO?.push({
        officeId: this.AddWorkForm.value.drpMonUser == "" ? null : this.AddWorkForm.value.drpMonUser,
        officeName: null
      })
    }
    // Add more monday
    if (this.AddWorkForm.value.drpMon2User == "" || this.AddWorkForm.value.drpMon2User == null || this.AddWorkForm.value.drpMon2User == undefined) {
      monday2officeDetailsDTO = [];
    } else {
      monday2officeDetailsDTO?.push({
        officeId: this.AddWorkForm.value.drpMon2User == "" ? null : this.AddWorkForm.value.drpMon2User,
        officeName: null
      })
    }
    // Tuesday
    if (this.AddWorkForm.value.drpTueUser == "" || this.AddWorkForm.value.drpTueUser == null || this.AddWorkForm.value.drpTueUser == undefined) {
      tuesdayofficeDetailsDTO = [];
    } else {
      tuesdayofficeDetailsDTO?.push({
        officeId: this.AddWorkForm.value.drpTueUser == "" ? null : this.AddWorkForm.value.drpTueUser,
        officeName: null
      })
    }
    // Add more Tuesday
    if (this.AddWorkForm.value.drpTue2User == "" || this.AddWorkForm.value.drpTue2User == null || this.AddWorkForm.value.drpTue2User == undefined) {
      tuesday2officeDetailsDTO = [];
    }
    else {
      tuesday2officeDetailsDTO?.push({
        officeId: this.AddWorkForm.value.drpTue2User == "" ? null : this.AddWorkForm.value.drpTue2User,
        officeName: null
      })
    }
    // wednesday
    if (this.AddWorkForm.value.drpWedUser == "" || this.AddWorkForm.value.drpWedUser == null || this.AddWorkForm.value.drpWedUser == undefined) {
      wednesdayofficeDetailsDTO = [];
    } else {
      wednesdayofficeDetailsDTO?.push({
        officeId: this.AddWorkForm.value.drpWedUser == "" ? null : this.AddWorkForm.value.drpWedUser,
        officeName: null
      })
    }
    // add more wednesday

    if (this.AddWorkForm.value.drpWed2User == "" || this.AddWorkForm.value.drpWed2User == null || this.AddWorkForm.value.drpWed2User == undefined) {
      wednesday2officeDetailsDTO = [];
    } else {
      wednesday2officeDetailsDTO?.push({
        officeId: this.AddWorkForm.value.drpWed2User == "" ? null : this.AddWorkForm.value.drpWed2User,
        officeName: null
      })
    }

    //Thursday
    if (this.AddWorkForm.value.drpThuUser == "" || this.AddWorkForm.value.drpThuUser == null || this.AddWorkForm.value.drpThuUser == undefined) {
      thursdayofficeDetailsDTO = [];
    } else {
      thursdayofficeDetailsDTO?.push({
        officeId: this.AddWorkForm.value.drpThuUser == "" ? null : this.AddWorkForm.value.drpThuUser,
        officeName: null
      })
    }
    // add more thusday
    if (this.AddWorkForm.value.drpThu2User == "" || this.AddWorkForm.value.drpThu2User == null || this.AddWorkForm.value.drpThu2User == undefined) {
      thursday2officeDetailsDTO = [];
    } else {
      thursday2officeDetailsDTO?.push({
        officeId: this.AddWorkForm.value.drpThu2User == "" ? null : this.AddWorkForm.value.drpThu2User,
        officeName: null
      })
    }
    //friday
    if (this.AddWorkForm.value.drpFriUser == "" || this.AddWorkForm.value.drpFriUser == null || this.AddWorkForm.value.drpFriUser == undefined) {
      fridayofficeDetailsDTO = [];
    } else {
      fridayofficeDetailsDTO?.push({
        officeId: this.AddWorkForm.value.drpFriUser == "" ? null : this.AddWorkForm.value.drpFriUser,
        officeName: null
      })
    }

    // addmore Friday
    if (this.AddWorkForm.value.drpFri2User == "" || this.AddWorkForm.value.drpFri2User == null || this.AddWorkForm.value.drpFri2User == undefined) {
      friday2officeDetailsDTO = [];
    } else {
      friday2officeDetailsDTO?.push({
        officeId: this.AddWorkForm.value.drpFri2User == "" ? null : this.AddWorkForm.value.drpFri2User,
        officeName: null
      })
    }

    // saturday
    if (this.AddWorkForm.value.drpSatUser == "" || this.AddWorkForm.value.drpSatUser == null || this.AddWorkForm.value.drpSatUser == undefined) {
      saturdayofficeDetailsDTO = []
    } else {
      saturdayofficeDetailsDTO?.push({
        officeId: this.AddWorkForm.value.drpSatUser == "" ? null : this.AddWorkForm.value.drpSatUser,
        officeName: null
      })
    }
    //add more saturday
    if (this.AddWorkForm.value.drpSat2User == "" || this.AddWorkForm.value.drpSat2User == null || this.AddWorkForm.value.drpSat2User == undefined) {
      saturday2officeDetailsDTO = [];
    } else {
      saturday2officeDetailsDTO?.push({
        officeId: this.AddWorkForm.value.drpSat2User == "" ? null : this.AddWorkForm.value.drpSat2User,
        officeName: null
      })
    }

    AddWorkingHours = {
      userId: this.data.userId,
      userName: "",
      userSundayWorkingHours: {
        isWorking: this.AddWorkForm.value.AddSundayStatus === true ? 1 : 0,
        userWorkDetails: [{
          coverageArea: this.AddWorkForm.value.drpSunCoverage,
          weekOption: this.AddWorkForm.value?.drpWorkOptn === "" ? [] : this.AddWorkForm.value.drpWorkOptn,
          startAt: this.AddWorkForm.value.drpStartAt.startTimeShortCodeId === undefined ? "" : this.AddWorkForm.value.drpStartAt.startTimeShortCodeId,
          endAt: this.AddWorkForm.value.drpStartEnd,
          officeDetails: sundayofficeDetailsDTO
        },
        {
          coverageArea: this.AddWorkForm.value.drpSun2Coverage,
          weekOption: this.AddWorkForm.value.drp2WorkOptn === "" ? [] : this.AddWorkForm.value.drp2WorkOptn,
          startAt: this.AddWorkForm.value.drp2StartAt?.startTimeShortCodeId === undefined ? "" : this.AddWorkForm.value.drp2StartAt?.startTimeShortCodeId,
          endAt: this.AddWorkForm.value.drp2StartEnd,
          officeDetails: sunday2officeDetailsDTO
        }
        ]
      },
      userMondayWorkingHours: {
        isWorking: this.AddWorkForm.value.AddMondayStatus === true ? 1 : 0,
        userWorkDetails: [{
          coverageArea: this.AddWorkForm.value.drpMonCoverage,
          weekOption: this.AddWorkForm.value.drpMonWorkOptn === "" ? [] : this.AddWorkForm.value.drpMonWorkOptn,
          startAt: this.AddWorkForm.value.drpMonStartAt?.startTimeShortCodeId === undefined ? "" : this.AddWorkForm.value.drpMonStartAt?.startTimeShortCodeId,
          endAt: this.AddWorkForm.value.drpMonStartEnd,
          officeDetails: mondayofficeDetailsDTO
        },
        {
          coverageArea: this.AddWorkForm.value.drpMon2Coverage,
          weekOption: this.AddWorkForm.value.drpMon2WorkOptn === "" ? [] : this.AddWorkForm.value.drpMon2WorkOptn,
          startAt: this.AddWorkForm.value.drpMon2StartAt?.startTimeShortCodeId === undefined ? "" : this.AddWorkForm.value.drpMon2StartAt?.startTimeShortCodeId,
          endAt: this.AddWorkForm.value.drpMon2StartEnd,
          officeDetails: monday2officeDetailsDTO
        }
        ]
      },
      userTuesdayWorkingHours: {
        isWorking: this.AddWorkForm.value.AddTuesdayStatus === true ? 1 : 0,
        userWorkDetails: [{
          coverageArea: this.AddWorkForm.value.drpTueCoverage,
          weekOption: this.AddWorkForm.value.drpTueWorkOptn === "" ? [] : this.AddWorkForm.value.drpTueWorkOptn,
          startAt: this.AddWorkForm.value.drpTueStartAt?.startTimeShortCodeId === undefined ? "" : this.AddWorkForm.value.drpTueStartAt?.startTimeShortCodeId,
          endAt: this.AddWorkForm.value.drpTueStartEnd,
          officeDetails: tuesdayofficeDetailsDTO
        },
        {
          coverageArea: this.AddWorkForm.value.drpTue2Coverage,
          weekOption: this.AddWorkForm.value.drpTue2WorkOptn === "" ? [] : this.AddWorkForm.value.drpTue2WorkOptn,
          startAt: this.AddWorkForm.value.drpTue2StartAt?.startTimeShortCodeId === undefined ? "" : this.AddWorkForm.value.drpTue2StartAt?.startTimeShortCodeId,
          endAt: this.AddWorkForm.value.drpTue2StartEnd,
          officeDetails: tuesday2officeDetailsDTO
        }
        ]
      },
      userWednesdayWorkingHours: {
        isWorking: this.AddWorkForm.value.AddWednesdayStatus === true ? 1 : 0,
        userWorkDetails: [{
          coverageArea: this.AddWorkForm.value.drpWedCoverage,
          weekOption: this.AddWorkForm.value.drpWedWorkOptn === "" ? [] : this.AddWorkForm.value.drpWedWorkOptn,
          startAt: this.AddWorkForm.value.drpWedStartAt?.startTimeShortCodeId === undefined ? "" : this.AddWorkForm.value.drpWedStartAt?.startTimeShortCodeId,
          endAt: this.AddWorkForm.value.drpWedStartEnd,
          officeDetails: wednesdayofficeDetailsDTO
        },
        {
          coverageArea: this.AddWorkForm.value.drpWed2Coverage,
          weekOption: this.AddWorkForm.value.drpWed2WorkOptn === "" ? [] : this.AddWorkForm.value.drpWed2WorkOptn,
          startAt: this.AddWorkForm.value.drpWed2StartAt?.startTimeShortCodeId === undefined ? "" : this.AddWorkForm.value.drpWed2StartAt?.startTimeShortCodeId,
          endAt: this.AddWorkForm.value.drpWed2StartEnd,
          officeDetails: wednesday2officeDetailsDTO
        }
        ]
      },
      userThursdayWorkingHours: {
        isWorking: this.AddWorkForm.value.AddThudayStatus === true ? 1 : 0,
        userWorkDetails: [{
          coverageArea: this.AddWorkForm.value.drpThuCoverage,
          weekOption: this.AddWorkForm.value.drpThuWorkOptn === "" ? [] : this.AddWorkForm.value.drpThuWorkOptn,
          startAt: this.AddWorkForm.value.drpThuStartAt?.startTimeShortCodeId === undefined ? "" : this.AddWorkForm.value.drpThuStartAt?.startTimeShortCodeId,
          endAt: this.AddWorkForm.value.drpThuStartEnd,
          officeDetails: thursdayofficeDetailsDTO
        },
        {
          coverageArea: this.AddWorkForm.value.drpThu2Coverage,
          weekOption: this.AddWorkForm.value.drpThu2WorkOptn === "" ? [] : this.AddWorkForm.value.drpThu2WorkOptn,
          startAt: this.AddWorkForm.value.drpThu2StartAt?.startTimeShortCodeId === undefined ? "" : this.AddWorkForm.value.drpThu2StartAt?.startTimeShortCodeId,
          endAt: this.AddWorkForm.value.drpThu2StartEnd,
          officeDetails: thursday2officeDetailsDTO
        }
        ]
      },
      userFridayWorkingHours: {
        isWorking: this.AddWorkForm.value.AddFridayStatus === true ? 1 : 0,
        userWorkDetails: [{
          coverageArea: this.AddWorkForm.value.drpFriCoverage,
          weekOption: this.AddWorkForm.value.drpFriWorkOptn === "" ? [] : this.AddWorkForm.value.drpFriWorkOptn,
          startAt: this.AddWorkForm.value.drpFriStartAt?.startTimeShortCodeId === undefined ? "" : this.AddWorkForm.value.drpFriStartAt?.startTimeShortCodeId,
          endAt: this.AddWorkForm.value.drpFriStartEnd,
          officeDetails: fridayofficeDetailsDTO
        },
        {
          coverageArea: this.AddWorkForm.value.drpFri2Coverage,
          weekOption: this.AddWorkForm.value.drpFri2WorkOptn === "" ? [] : this.AddWorkForm.value.drpFri2WorkOptn,
          startAt: this.AddWorkForm.value.drpFri2StartAt?.startTimeShortCodeIde === undefined ? "" : this.AddWorkForm.value.drpFri2StartAt?.startTimeShortCodeId,
          endAt: this.AddWorkForm.value.drpFri2StartEnd,
          officeDetails: friday2officeDetailsDTO
        }]
      },
      userSaturdayWorkingHours: {
        isWorking: this.AddWorkForm.value.AddSatdayStatus === true ? 1 : 0,
        userWorkDetails: [{
          coverageArea: this.AddWorkForm.value.drpSatCoverage,
          weekOption: this.AddWorkForm.value.drpSatWorkOptn === "" ? [] : this.AddWorkForm.value.drpSatWorkOptn,
          startAt: this.AddWorkForm.value.drpSatStartAt?.startTimeShortCodeIde === undefined ? "" : this.AddWorkForm.value.drpSatStartAt?.startTimeShortCodeId,
          endAt: this.AddWorkForm.value.drpSatStartEnd,
          officeDetails: saturdayofficeDetailsDTO
        },
        {
          coverageArea: this.AddWorkForm.value.drpSat2Coverage,
          weekOption: this.AddWorkForm.value.drpSat2WorkOptn === "" ? [] : this.AddWorkForm.value.drpSat2WorkOptn,
          startAt: this.AddWorkForm.value.drpSat2StartAt?.startTimeShortCodeId === undefined ? "" : this.AddWorkForm.value.drpSat2StartAt?.startTimeShortCodeId,
          endAt: this.AddWorkForm.value.drpSat2StartEnd,
          officeDetails: saturday2officeDetailsDTO
        }
        ]
      },
      userLunchBreakTimings: {
        fromTime: this.AddWorkForm.value.drpFromLunch,
        toTime: this.AddWorkForm.value.txtToLunch,
      }
    }
    if (this.data.addWorkingId === "" || this.data.addWorkingId === null || this.data.addWorkingId === "00000000-0000-0000-0000-000000000000") {
      this.userWorkHours.create(AddWorkingHours).subscribe(response => {
        // Swal.fire({ title: 'Success', html: 'Saved Successfully', icon: 'success', timer: 3000, timerProgressBar: true });
        this.toastr.success('Saved Successfully','Success')
        this.data.addWorkingId = response.id
        this.notesDialogRef.close();
      }, err => {
        const data: HttpErrorResponse = err;
        // Swal.fire({
        //   icon: 'info',
        //   html: data?.error?.error?.message,
        // })
        this.toastr.info(data?.error?.error?.message)
      });
    }
    else {
      this.userWorkHours.update(this.data.addWorkingId, AddWorkingHours).subscribe(response => {
        // this.patientsId = response.id
        // Swal.fire({ title: 'Success', html: 'Saved Successfully', icon: 'success', timer: 3000, timerProgressBar: true });
        this.toastr.success('Saved Successfully','Success')
        this.notesDialogRef.close();
      }, err => {
        const data: HttpErrorResponse = err;
        // Swal.fire({
        //   icon: 'info',
        //   html: data?.error?.error?.message,
        // })
        this.toastr.info(data?.error?.error?.message)
      });
    }
  }




  //! branch users
  onEndsChanges(startAt, startEnd, work) {
    if (work === "Coverage Area") {
      this.coverageAreaService.getList(new PagedAndSortedResultRequestDto).subscribe(response => {
        this.arrUsersList = [];
        response?.items?.forEach(e => {
          this.arrUsersList.push({
            officeId: e.id,
            officeName: e.coverageAreaName,
            // squareMile: e.squareMile,
            // userDetails: e.userDetails,
            // status: e.status,
            // coverageAreaLocation: e.coverageAreaLocation
          })
        })
      }, err => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      });
    } else {
      let userDatas: GetOfficesDTO = {
        weekDays: WeekDays.Sunday,
        // weekOption: work,
        startsAt: startAt.startTimeShortCodeId,
        endsAt: startEnd,
        officeDetails: null

      }
      this.userWorkHours.getOfficeBasedOnTimingsByInput(userDatas).subscribe(response => {
        this.arrUsersList = response?.officeDetails;
      }, err => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      });
    }
  }

  onSun2Changes(startAt, startEnd, work) {
    if (work === "Coverage Area") {
      this.coverageAreaService.getList(new PagedAndSortedResultRequestDto).subscribe(response => {
        this.arr2UsersList = [];
        response?.items?.forEach(e => {
          this.arr2UsersList.push({
            officeId: e.id,
            officeName: e.coverageAreaName,
            squareMile: e.squareMile,
            userDetails: e.userDetails,
            status: e.status,
            coverageAreaLocation: e.coverageAreaLocation
          })
        })
      }, err => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      });
    } else {
      let userDatas: GetOfficesDTO = {
        weekDays: WeekDays.Sunday,
        // weekOption: work,
        startsAt: startAt?.startTimeShortCodeId,
        endsAt: startEnd,
        officeDetails: null

      }
      this.userWorkHours.getOfficeBasedOnTimingsByInput(userDatas).subscribe(response => {


        this.arr2UsersList = response.officeDetails;

      }, err => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      });
    }
  }


  ///Monday users
  arrMonUsersList: any[] = [];
  arrMon2UsersList: any[] = [];
  onMonEndsChanges(startAt, startEnd, work) {
    if (work === "Coverage Area") {
      this.coverageAreaService.getList(new PagedAndSortedResultRequestDto).subscribe(response => {
        this.arrMonUsersList = [];
        response?.items?.forEach(e => {
          this.arrMonUsersList.push({
            officeId: e.id,
            officeName: e.coverageAreaName,
            squareMile: e.squareMile,
            userDetails: e.userDetails,
            status: e.status,
            coverageAreaLocation: e.coverageAreaLocation
          })
        })
      }, err => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      });
    } else {
      let userDatas: GetOfficesDTO = {
        weekDays: WeekDays.Monday,
        // weekOption: work,
        startsAt: startAt?.startTimeShortCodeId,
        endsAt: startEnd,
        officeDetails: null

      }
      this.userWorkHours.getOfficeBasedOnTimingsByInput(userDatas).subscribe(response => {


        this.arrMonUsersList = response.officeDetails;

      }, err => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      });
    }
  }

  onMon2Changes(startAt, startEnd, work) {
    if (work === "Coverage Area") {
      this.coverageAreaService.getList(new PagedAndSortedResultRequestDto).subscribe(response => {
        this.arrMon2UsersList = [];
        response?.items?.forEach(e => {
          this.arrMon2UsersList.push({
            officeId: e.id,
            officeName: e.coverageAreaName,
            squareMile: e.squareMile,
            userDetails: e.userDetails,
            status: e.status,
            coverageAreaLocation: e.coverageAreaLocation
          })
        })

      }, err => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      });
    } else {
      let userDatas: GetOfficesDTO = {
        weekDays: WeekDays.Monday,
        // weekOption: work,
        startsAt: startAt?.startTimeShortCodeId,
        endsAt: startEnd,
        officeDetails: null

      }
      this.userWorkHours.getOfficeBasedOnTimingsByInput(userDatas).subscribe(response => {


        this.arrMon2UsersList = response.officeDetails;

      }, err => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      });
    }
  }


  ///Tues users
  arrTueUsersList: any[] = [];
  arrTue2UsersList: any[] = [];
  onTueEndsChanges(startAt, startEnd, work) {
    if (work === "Coverage Area") {
      this.coverageAreaService.getList(new PagedAndSortedResultRequestDto).subscribe(response => {
        this.arrTueUsersList = [];
        response?.items?.forEach(e => {
          this.arrTueUsersList.push({
            officeId: e.id,
            officeName: e.coverageAreaName,
            squareMile: e.squareMile,
            userDetails: e.userDetails,
            status: e.status,
            coverageAreaLocation: e.coverageAreaLocation
          })
        })



      }, err => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      });
    } else {
      let userDatas: GetOfficesDTO = {
        weekDays: WeekDays.Tuesday,
        // weekOption: work,
        startsAt: startAt?.startTimeShortCodeId,
        endsAt: startEnd,
        officeDetails: null

      }
      this.userWorkHours.getOfficeBasedOnTimingsByInput(userDatas).subscribe(response => {


        this.arrTueUsersList = response.officeDetails;

      }, err => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      });
    }
  }

  onTue2Changes(startAt, startEnd, work) {
    if (work === "Coverage Area") {
      this.coverageAreaService.getList(new PagedAndSortedResultRequestDto).subscribe(response => {
        this.arrTue2UsersList = [];
        response?.items?.forEach(e => {
          this.arrTue2UsersList.push({
            officeId: e.id,
            officeName: e.coverageAreaName,
            squareMile: e.squareMile,
            userDetails: e.userDetails,
            status: e.status,
            coverageAreaLocation: e.coverageAreaLocation
          })
        })



      }, err => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      });
    } else {
      let userDatas: GetOfficesDTO = {
        weekDays: WeekDays.Tuesday,
        // weekOption: work,
        startsAt: startAt?.startTimeShortCodeId,
        endsAt: startEnd,
        officeDetails: null

      }
      this.userWorkHours.getOfficeBasedOnTimingsByInput(userDatas).subscribe(response => {


        this.arrTue2UsersList = response.officeDetails;

      }, err => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      });
    }
  }

  ///wed users
  arrWedUsersList: any[] = [];
  arrWed2UsersList: any[] = [];
  onWedEndsChanges(startAt, startEnd, work) {
    if (work === "Coverage Area") {
      this.coverageAreaService.getList(new PagedAndSortedResultRequestDto).subscribe(response => {
        this.arrWedUsersList = [];
        response?.items.forEach(e => {
          this.arrWedUsersList.push({
            officeId: e.id,
            officeName: e.coverageAreaName,
            squareMile: e.squareMile,
            userDetails: e.userDetails,
            status: e.status,
            coverageAreaLocation: e.coverageAreaLocation
          })
        })

      }, err => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      });
    } else {
      let userDatas: GetOfficesDTO = {
        weekDays: WeekDays.Wednesday,
        // weekOption: work,
        startsAt: startAt?.startTimeShortCodeId,
        endsAt: startEnd,
        officeDetails: null

      }
      this.userWorkHours.getOfficeBasedOnTimingsByInput(userDatas).subscribe(response => {


        this.arrWedUsersList = response.officeDetails;

      }, err => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      });
    }
  }

  onWed2Changes(startAt, startEnd, work) {
    if (work === "Coverage Area") {
      this.coverageAreaService.getList(new PagedAndSortedResultRequestDto).subscribe(response => {
        this.arrWed2UsersList = [];
        response?.items.forEach(e => {
          this.arrWed2UsersList.push({
            officeId: e.id,
            officeName: e.coverageAreaName,
            squareMile: e.squareMile,
            userDetails: e.userDetails,
            status: e.status,
            coverageAreaLocation: e.coverageAreaLocation
          })
        })



      }, err => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      });
    } else {
      let userDatas: GetOfficesDTO = {
        weekDays: WeekDays.Wednesday,
        // weekOption: work,
        startsAt: startAt?.startTimeShortCodeId,
        endsAt: startEnd,
        officeDetails: null

      }
      this.userWorkHours.getOfficeBasedOnTimingsByInput(userDatas).subscribe(response => {


        this.arrWed2UsersList = response.officeDetails;

      }, err => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      });
    }
  }

  ///Thu users
  arrThuUsersList: any[] = [];
  arrThu2UsersList: any[] = [];
  onThuEndsChanges(startAt, startEnd, work) {
    if (work === "Coverage Area") {
      this.coverageAreaService.getList(new PagedAndSortedResultRequestDto).subscribe(response => {
        this.arrThuUsersList = [];
        response?.items.forEach(e => {
          this.arrThuUsersList.push({
            officeId: e.id,
            officeName: e.coverageAreaName,
            squareMile: e.squareMile,
            userDetails: e.userDetails,
            status: e.status,
            coverageAreaLocation: e.coverageAreaLocation
          })
        })



      }, err => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      });
    } else {
      let userDatas: GetOfficesDTO = {
        weekDays: WeekDays.Thursday,
        // weekOption: work,
        startsAt: startAt?.startTimeShortCodeId,
        endsAt: startEnd,
        officeDetails: null

      }
      this.userWorkHours.getOfficeBasedOnTimingsByInput(userDatas).subscribe(response => {


        this.arrThuUsersList = response.officeDetails;

      }, err => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      });
    }
  }

  onThu2Changes(startAt, startEnd, work) {
    if (work === "Coverage Area") {
      this.coverageAreaService.getList(new PagedAndSortedResultRequestDto).subscribe(response => {
        this.arrThu2UsersList = [];
        response?.items.forEach(e => {
          this.arrThu2UsersList.push({
            officeId: e.id,
            officeName: e.coverageAreaName,
            squareMile: e.squareMile,
            userDetails: e.userDetails,
            status: e.status,
            coverageAreaLocation: e.coverageAreaLocation
          })
        })



      }, err => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      });
    } else {
      let userDatas: GetOfficesDTO = {
        weekDays: WeekDays.Thursday,
        // weekOption: work,
        startsAt: startAt?.startTimeShortCodeId,
        endsAt: startEnd,
        officeDetails: null

      }
      this.userWorkHours.getOfficeBasedOnTimingsByInput(userDatas).subscribe(response => {


        this.arrThu2UsersList = response.officeDetails;

      }, err => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      });
    }
  }


  ///Fri users
  arrFriUsersList: any[] = [];
  arrFri2UsersList: any[] = [];
  onFriEndsChanges(startAt, startEnd, work) {
    if (work === "Coverage Area") {
      this.coverageAreaService.getList(new PagedAndSortedResultRequestDto).subscribe(response => {
        this.arrFriUsersList = [];
        response?.items.forEach(e => {
          this.arrFriUsersList.push({
            officeId: e.id,
            officeName: e.coverageAreaName,
            squareMile: e.squareMile,
            userDetails: e.userDetails,
            status: e.status,
            coverageAreaLocation: e.coverageAreaLocation
          })
        })



      }, err => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      });
    } else {
      let userDatas: GetOfficesDTO = {
        weekDays: WeekDays.Friday,
        // weekOption: work,
        startsAt: startAt?.startTimeShortCodeId,
        endsAt: startEnd,
        officeDetails: null

      }
      this.userWorkHours.getOfficeBasedOnTimingsByInput(userDatas).subscribe(response => {


        this.arrFriUsersList = response.officeDetails;

      }, err => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      });
    }
  }

  onFri2Changes(startAt, startEnd, work) {
    if (work === "Coverage Area") {
      this.coverageAreaService.getList(new PagedAndSortedResultRequestDto).subscribe(response => {
        this.arrFri2UsersList = [];
        response?.items.forEach(e => {
          this.arrFri2UsersList.push({
            officeId: e.id,
            officeName: e.coverageAreaName,
            squareMile: e.squareMile,
            userDetails: e.userDetails,
            status: e.status,
            coverageAreaLocation: e.coverageAreaLocation
          })
        })



      }, err => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      });
    } else {
      let userDatas: GetOfficesDTO = {
        weekDays: WeekDays.Friday,
        // weekOption: work,
        startsAt: startAt?.startTimeShortCodeId,
        endsAt: startEnd,
        officeDetails: null

      }
      this.userWorkHours.getOfficeBasedOnTimingsByInput(userDatas).subscribe(response => {

        this.arrFri2UsersList = response.officeDetails;

      }, err => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      });
    }
  }

  ///Sat users
  arrSatUsersList: any[] = [];
  arrSat2UsersList: any[] = [];
  onSatEndsChanges(startAt, startEnd, work) {
    if (work === "Coverage Area") {
      this.coverageAreaService.getList(new PagedAndSortedResultRequestDto).subscribe(response => {
        this.arrSatUsersList = [];
        response?.items.forEach(e => {
          this.arrSatUsersList.push({
            officeId: e.id,
            officeName: e.coverageAreaName,
            squareMile: e.squareMile,
            userDetails: e.userDetails,
            status: e.status,
            coverageAreaLocation: e.coverageAreaLocation
          })
        })



      }, err => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      });
    } else {
      let userDatas: GetOfficesDTO = {
        weekDays: WeekDays.Saturday,
        // weekOption: work,
        startsAt: startAt?.startTimeShortCodeId,
        endsAt: startEnd,
        officeDetails: null

      }
      this.userWorkHours.getOfficeBasedOnTimingsByInput(userDatas).subscribe(response => {


        this.arrSatUsersList = response.officeDetails;

      }, err => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      });
    }
  }

  onSat2Changes(startAt, startEnd, work) {
    if (work === "Coverage Area") {
      this.coverageAreaService.getList(new PagedAndSortedResultRequestDto).subscribe(response => {
        this.arrSat2UsersList = [];
        response?.items.forEach(e => {
          this.arrSat2UsersList.push({
            officeId: e.id,
            officeName: e.coverageAreaName,
            squareMile: e.squareMile,
            userDetails: e.userDetails,
            status: e.status,
            coverageAreaLocation: e.coverageAreaLocation
          })
        })



      }, err => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      });
    } else {
      let userDatas: GetOfficesDTO = {
        weekDays: WeekDays.Saturday,
        // weekOption: work,
        startsAt: startAt?.startTimeShortCodeId,
        endsAt: startEnd,
        officeDetails: null

      }
      this.userWorkHours.getOfficeBasedOnTimingsByInput(userDatas).subscribe(response => {


        this.arrSat2UsersList = response.officeDetails;

      }, err => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      });
    }
  }
  //! sunday status
  sundayStatus(status) {
    if (this.AddWorkForm.value.AddSundayStatus == true) {
      this.sunday = "Leave"
      this.sundayLeaveHide = false;
      this.AddWorkForm.patchValue({
        drpSunCoverage: "",
        drpWorkOptn: "",
        drpStartAt: "",
        drpStartEnd: "",
        drpUser: "",
        drpSun2Coverage: "",
        drp2WorkOptn: "",
        drp2StartAt: "",
        drp2StartEnd: "",
        drp2User: "",
      })
    }
    if (this.AddWorkForm.value.AddSundayStatus == false) {
      this.sunday = "Working"
      this.sundayLeaveHide = true;
    }
  }

  //! monday status
  mondayStatus(status) {
    if (this.AddWorkForm.value.AddMondayStatus == true) {
      this.monday = "Leave"
      this.mondayLeaveHide = false;
      this.AddWorkForm.patchValue({
        drpMonCoverage: "",
        drpMonWorkOptn: "",
        drpMonStartAt: "",
        drpMonStartEnd: "",
        drpMonUser: "",
        drpMon2Coverage: "",
        drpMon2WorkOptn: "",
        drpMon2StartAt: "",
        drpMon2StartEnd: "",
        drpMon2User: "",
        drpMon2SelCov: "",
        drpMonSelCov: "",
      })
    }
    if (this.AddWorkForm.value.AddMondayStatus == false) {
      this.monday = "Working"
      this.mondayLeaveHide = true;


    }
  }

  //! tuesday status
  tuesStatus(status) {
    if (this.AddWorkForm.value.AddTuesdayStatus == true) {
      this.tuesday = "Leave"
      this.tuedayLeaveHide = false;
      this.AddWorkForm.patchValue({
        drpTueWorkOptn: "",
        drpTueStartAt: "",
        drpTueStartEnd: "",
        drpTueSelCov: "",
        drpTueUser: "",
        drpTueCoverage: "",
        drpTue2Coverage: "",
        drpTue2WorkOptn: "",
        drpTue2StartAt: "",
        drpTue2StartEnd: "",
        drpTue2SelCov: "",
        drpTue2User: "",
      })
    }
    if (this.AddWorkForm.value.AddTuesdayStatus == false) {
      this.tuesday = "Working"
      this.tuedayLeaveHide = true;
    }
  }

  //! wedday status wedday
  wedStatus(status) {
    if (this.AddWorkForm.value.AddWednesdayStatus == true) {
      this.wedday = "Leave"
      this.weddayLeaveHide = false;
      this.AddWorkForm.patchValue({
        drpWedWorkOptn: "",
        drpWedStartAt: "",
        drpWedStartEnd: "",
        drpWedSelCov: "",
        drpWedUser: "",
        drpWedCoverage: "",
        drpWed2Coverage: "",
        drpWed2WorkOptn: "",
        drpWed2StartAt: "",
        drpWed2StartEnd: "",
        drpWed2SelCov: "",
        drpWed2User: "",
      })
    }
    if (this.AddWorkForm.value.AddWednesdayStatus == false) {
      this.wedday = "Working"
      this.weddayLeaveHide = true;
    }

  }
  //! thuday status wedday
  thuStatus(status) {
    if (this.AddWorkForm.value.AddThudayStatus == true) {
      this.thuday = "Leave"
      this.thudayLeaveHide = false;
      this.AddWorkForm.patchValue({
        drpThuWorkOptn: "",
        drpThuStartAt: "",
        drpThuStartEnd: "",
        drpThuSelCov: "",
        drpThuUser: "",
        drpThuCoverage: "",
        drpThu2Coverage: "",
        drpThu2WorkOptn: "",
        drpThu2StartAt: "",
        drpThu2StartEnd: "",
        drpThu2SelCov: "",
        drpThu2User: "",
      })
    }
    if (this.AddWorkForm.value.AddThudayStatus == false) {
      this.thuday = "Working"
      this.thudayLeaveHide = true;
    }
  }

  //! Friday status wedday
  FriStatus(status) {
    if (this.AddWorkForm.value.AddFridayStatus == true) {
      this.Friday = "Leave"
      this.fridayLeaveHide = false;
      this.AddWorkForm.patchValue({
        drpFriWorkOptn: "",
        drpFriStartAt: "",
        drpFriStartEnd: "",
        drpFriSelCov: "",
        drpFriUser: "",
        drpFriCoverage: "",
        drpFri2Coverage: "",
        drpFri2WorkOptn: "",
        drpFri2StartAt: "",
        drpFri2StartEnd: "",
        drpFri2SelCov: "",
        drpFri2User: "",
      })

    }
    if (this.AddWorkForm.value.AddFridayStatus == false) {
      this.Friday = "Working"
      this.fridayLeaveHide = true;

    }

  }

  //! Satday status wedday
  SatStatus(status) {
    if (this.AddWorkForm.value.AddSatdayStatus == true) {
      this.Satday = "Leave"
      this.satdayLeaveHide = false;
      this.AddWorkForm.patchValue({
        drpSatWorkOptn: "",
        drpSatStartAt: "",
        drpSatStartEnd: "",
        drpSatSelCov: "",
        drpSatUser: "",
        drpSatCoverage: "",
        drpSat2Coverage: "",

        drpSat2WorkOptn: "",
        drpSat2StartAt: "",
        drpSat2StartEnd: "",
        drpSat2SelCov: "",
        drpSat2User: "",
      })
    }
    if (this.AddWorkForm.value.AddSatdayStatus == false) {
      this.Satday = "Working"
      this.satdayLeaveHide = true;
    }
  }
}
