<mat-dialog-content class="mat-typography">
  <div *ngIf="selData" class="row">
    <div class="col-lg-12">
      <h2 class="pl-3">Patient Details</h2>
    </div>
  </div>
  <div *ngIf="selData" class="container-fluid">
    <div class="card card-body mt-1" *ngIf="selData">
      <form>
        <div class="row">
          <div class="col-lg-6">
            <div *ngIf="selData?.defaultPatientId" class="row">
              <div class="col-2">Patient ID</div>
              <div class="col-10">: {{ selData?.defaultPatientId }}</div>
            </div>
            <div *ngIf="selData?.firstName" class="row">
              <div class="col-2">FirstName</div>
              <div class="col-10">: {{ selData?.firstName }}</div>
            </div>
            <div *ngIf="selData?.lastName" class="row">
              <div class="col-2">LastName</div>
              <div class="col-10">: {{ selData?.lastName }}</div>
            </div>
            <div *ngIf="selData?.dob" class="row">
              <div class="col-2">Date Of Birth</div>
              <div class="col-10">
                : {{ selData?.dob | date: "MM/dd/yyyy":"en_US" }}
              </div>
            </div>
            <div *ngIf="selData?.edcDate" class="row">
              <div class="col-2">EDC Date</div>
              <div class="col-10">
                : {{ selData?.edcDate | date: "MM/dd/yyyy":"en_US" }}
              </div>
            </div>
          </div>
          <div class="col-lg-6">
            <div *ngIf="selData?.city" class="row">
              <div class="col-2">City</div>
              <div class="col-10">: {{ selData?.city }}</div>
            </div>
            <div *ngIf="selData?.streetAddress" class="row">
              <div class="col-2">Address</div>
              <div class="col-10">: {{ selData?.streetAddress }}</div>
            </div>
            <div *ngIf="selData?.state" class="row">
              <div class="col-2">State</div>
              <div class="col-10">: {{ selData?.state }}</div>
            </div>
            <div *ngIf="selData?.zipCode" class="row">
              <div class="col-2">Zip Code</div>
              <div class="col-10">: {{ selData?.zipCode }}</div>
            </div>
            <div *ngIf="selData?.homePhone" class="row">
              <div class="col-2">Home Phone</div>
              <div class="col-10">: {{ selData?.homePhone }}</div>
            </div>
            <div *ngIf="selData?.dayPhone" class="row">
              <div class="col-2">Day Phone</div>
              <div class="col-10">: {{ selData?.dayPhone }}</div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
  <div *ngIf="
      selData?.insuranceDetailsList !== [] &&
      selData?.insuranceDetailsList?.length !== 0
    " class="row">
    <div class="col-lg-12">
      <h2 class="pl-3">Insurance Details</h2>
    </div>
  </div>
  <div class="container-fluid" *ngIf="selData">
    <form>
      <ng-container *ngIf="
          selData?.insuranceDetailsList !== [] &&
          selData?.insuranceDetailsList?.length !== 0
        ">
        <table class="table table-bordered table-sm">
          <thead>
            <tr>
              <th scope="col">Insurance Name</th>
              <th scope="col">Insurance Id</th>
              <th scope="col">HCPS</th>
              <th scope="col">Blue Card Eligible</th>
              <th scope="col">Claim Submit</th>
              <th scope="col">Insurance Active</th>
              <th scope="col">Pre-Auth Available</th>
              <th scope="col">Local-Bill Available</th>
              <th scope="col">Payer Allowables</th>
              <th scope="col">Family Coinsurance</th>
              <th scope="col">Family Deductible Payment</th>
              <th scope="col">Family Out Of Pocket</th>
              <th scope="col">Individual Coinsurance</th>
              <th scope="col">Individual Deductible Payment</th>
              <th scope="col">Individual Out Of Pocket</th>
              <th scope="col">Payment Comments</th>
            </tr>
          </thead>
          <tbody>
            <ng-container *ngFor="let ins of selData?.insuranceDetailsList">
              <tr *ngIf="ins?.uniqueInsuranceId === insuranceId">
                <th scope="row">{{ ins?.insurance }}</th>
                <td>{{ ins?.insuranceId }}</td>
                <td>{{ ins?.hcps }}</td>
                <td>
                  {{ ins?.isBlueCardEligible === true ? "Yes" : "" }}
                  {{ ins?.isBlueCardEligible === false ? "No" : "" }}
                </td>
                <td>
                  {{ ins?.isClaimSubmit === true ? "Yes" : "" }}
                  {{ ins?.isClaimSubmit === false ? "No" : "" }}
                </td>
                <td>
                  {{ ins?.isInsuranceActive === true ? "Yes" : "" }}
                  {{ ins?.isInsuranceActive === false ? "No" : "" }}
                </td>
                <td>
                  {{ ins?.isPreAuthAvailable === true ? "Yes" : "" }}
                  {{ ins?.isPreAuthAvailable === false ? "No" : "" }}
                </td>
                <td>
                  {{ ins?.isLocalBillAvailable === true ? "Yes" : "" }}
                  {{ ins?.isLocalBillAvailable === false ? "No" : "" }}
                </td>
                <td>{{ ins?.payerAllowables }}</td>
                <td>
                  <ng-container *ngIf="ins?.familyCoinsurance">
                    {{ "$" + ins?.familyCoinsurance }}
                  </ng-container>
                </td>
                <td>
                  <ng-container *ngIf="ins?.familyDeductiblePayment">
                    {{ "$" + ins?.familyDeductiblePayment }}
                  </ng-container>
                </td>
                <td>
                  <ng-container *ngIf="ins?.familyOutOfPocket">
                    {{ "$" + ins?.familyOutOfPocket }}
                  </ng-container>
                </td>
                <td>
                  <ng-container *ngIf="ins?.individualCoinsurance">
                    {{ "$" + ins?.individualCoinsurance }}
                  </ng-container>
                </td>
                <td>
                  <ng-container *ngIf="ins?.individualDeductiblePayment">
                    {{ "$" + ins?.individualDeductiblePayment }}
                  </ng-container>
                </td>
                <td>
                  <ng-container *ngIf="ins?.individualOutOfPocket">
                    {{ "$" + ins?.individualOutOfPocket }}
                  </ng-container>
                </td>
                <td>
                  <ng-container *ngIf="ins?.paymentComments">
                    {{ ins?.paymentComments }}
                  </ng-container>
                </td>
              </tr>
            </ng-container>
          </tbody>
        </table>
      </ng-container>
    </form>
  </div>
  <div class="container-fluid" *ngIf="selData">
    <form [formGroup]="VInsuranceForm">
      <div class="row">
        <div class="col-12">
          <p>Does this member have active insurance?</p>
          <mat-radio-group formControlName="isInsuranceActive" class="pt-3">
            <mat-radio-button class="pr-3" [value]="true">Yes</mat-radio-button>
            <mat-radio-button [value]="false">No</mat-radio-button>
          </mat-radio-group>
        </div>

        <mat-form-field class="col-12">
          <mat-label>Insurance Comments</mat-label>
          <input autocomplete="new-insuranceComments" matInput maxlength="40" formControlName="insuranceComments" type="text" />
        </mat-form-field>
        <div class="col-12">
          <p>Is this patieint blue card eligible?</p>
          <mat-radio-group formControlName="isBlueCardEligible" class="pt-3">
            <mat-radio-button class="pr-3" [value]="true">Yes</mat-radio-button>
            <mat-radio-button [value]="false">No</mat-radio-button>
          </mat-radio-group>
        </div>

        <mat-form-field class="col-12">
          <mat-label>Blue Card Eligible</mat-label>
          <input autocomplete="new-blueCardComments" matInput maxlength="40" formControlName="blueCardComments" type="text" />
        </mat-form-field>

        <div class="col-12">
          <p>
            If yes, so as long as I am in network with my local BCBS I can bill
            to my local directly for this member with Place of Service 99?
          </p>
          <mat-radio-group formControlName="isLocalBillAvailable" class="pt-3">
            <mat-radio-button class="pr-3" [value]="true">Yes</mat-radio-button>
            <mat-radio-button [value]="false">No</mat-radio-button>
          </mat-radio-group>
        </div>

        <mat-form-field class="col-12">
          <mat-label>LocalBill Comments</mat-label>
          <input autocomplete="new-isPreAuthAvailable" matInput maxlength="40" formControlName="isPreAuthAvailable" type="text" />
        </mat-form-field>
        <div class="col-12">
          <p>Do we need a preauthorization to bill for this product?</p>
          <mat-radio-group formControlName="isPreAuthAvailable" class="pt-3">
            <mat-radio-button class="pr-3" [value]="true">Yes</mat-radio-button>
            <mat-radio-button [value]="false">No</mat-radio-button>
          </mat-radio-group>
        </div>

        <mat-form-field class="col-12">
          <mat-label>Pre-Auth Comments</mat-label>
          <input autocomplete="new-preAuthComments" matInput maxlength="40" formControlName="preAuthComments" type="text" />
        </mat-form-field>
        <div class="col-12">
          <p>
            Can I submit a claim for this CPT / HCPCS code and be reimbursed for
            services (patient not exhausted benefit as well)
          </p>
          <mat-radio-group formControlName="isClaimSubmit" class="pt-3">
            <mat-radio-button class="pr-3" [value]="true">Yes</mat-radio-button>
            <mat-radio-button [value]="false">No</mat-radio-button>
          </mat-radio-group>
        </div>

        <mat-form-field class="col-12">
          <mat-label>Claim Submit Comments</mat-label>
          <input autocomplete="new-claimSubmitComments" matInput maxlength="40" formControlName="claimSubmitComments" type="text" />
        </mat-form-field>
        <mat-form-field class="col-12">
          <mat-label>Can I please have a reference number for this call?</mat-label>
          <input autocomplete="new-referenceNumber" matInput maxlength="40" formControlName="referenceNumber" type="text" />
        </mat-form-field>
        <mat-form-field class="col-12">
          <mat-label>Reference Number</mat-label>
          <input autocomplete="new-referenceNumber" matInput maxlength="40" formControlName="referenceNumber" type="text" />
        </mat-form-field>
        <mat-form-field class="col-6">
          <mat-label>Family Coinsurance</mat-label>
          <input autocomplete="new-familyCoinsurance" matInput maxlength="40" mask="separator" [showMaskTyped]="false" formControlName="familyCoinsurance"
            type="text" />
          <mat-error *ngIf="
              VInsuranceForm?.get('familyCoinsurance')?.touched &&
              VInsuranceForm?.get('familyCoinsurance')?.errors?.pattern
            ">
            Enter only Numbers!
          </mat-error>
        </mat-form-field>
        <mat-form-field class="col-6">
          <mat-label>Individual Coinsurance</mat-label>
          <input autocomplete="new-individualCoinsurance" matInput maxlength="40" mask="separator" [showMaskTyped]="false"
            formControlName="individualCoinsurance" type="text" />
          <mat-error *ngIf="
              VInsuranceForm?.get('individualCoinsurance')?.touched &&
              VInsuranceForm?.get('individualCoinsurance')?.errors?.pattern
            ">
            Enter only Numbers!
          </mat-error>
        </mat-form-field>
        <mat-form-field class="col-6">
          <mat-label>Family Deductible Payment</mat-label>
          <input autocomplete="new-familyDeductiblePayment" matInput maxlength="40" mask="separator" [showMaskTyped]="false"
            formControlName="familyDeductiblePayment" type="text" />
          <mat-error *ngIf="
              VInsuranceForm?.get('familyDeductiblePayment')?.touched &&
              VInsuranceForm?.get('familyDeductiblePayment')?.errors?.pattern
            ">
            Enter only Numbers!
          </mat-error>
        </mat-form-field>
        <mat-form-field class="col-6">
          <mat-label>Individual Deductible Payment</mat-label>
          <input autocomplete="new-individualDeductiblePayment" matInput maxlength="40" mask="separator" [showMaskTyped]="false"
            formControlName="individualDeductiblePayment" type="text" />
          <mat-error *ngIf="
              VInsuranceForm?.get('individualDeductiblePayment')?.touched &&
              VInsuranceForm?.get('individualDeductiblePayment')?.errors
                ?.pattern
            ">
            Enter only Numbers!
          </mat-error>
        </mat-form-field>
        <mat-form-field class="col-6">
          <mat-label>Family Out Of Pocket</mat-label>
          <input autocomplete="new-familyOutOfPocket" matInput maxlength="40" mask="separator" [showMaskTyped]="false" formControlName="familyOutOfPocket"
            type="text" />
          <mat-error *ngIf="
              VInsuranceForm?.get('familyOutOfPocket')?.touched &&
              VInsuranceForm?.get('familyOutOfPocket')?.errors?.pattern
            ">
            Enter only Numbers!
          </mat-error>
        </mat-form-field>
        <mat-form-field class="col-6">
          <mat-label>Individual Out Of Pocket</mat-label>
          <input autocomplete="new-individualOutOfPocket" matInput maxlength="40" mask="separator" [showMaskTyped]="false"
            formControlName="individualOutOfPocket" type="text" />
          <mat-error *ngIf="
              VInsuranceForm?.get('individualOutOfPocket')?.touched &&
              VInsuranceForm?.get('individualOutOfPocket')?.errors?.pattern
            ">
            Enter only Numbers!
          </mat-error>
        </mat-form-field>
        <mat-form-field class="col-6">
          <mat-label>Payer Allowables</mat-label>
          <input autocomplete="new-payerAllowables" matInput maxlength="40" formControlName="payerAllowables" mask="separator" [showMaskTyped]="false"
            type="text" />
          <mat-error *ngIf="
              VInsuranceForm?.get('payerAllowables')?.touched &&
              VInsuranceForm?.get('payerAllowables')?.errors?.pattern
            ">
            Enter only Numbers!
          </mat-error>
        </mat-form-field>
        <mat-form-field class="col-6">
          <mat-label>Payment Comments</mat-label>
          <textarea formControlName="paymentComments" rows="3" maxlength="200" matInput></textarea>
        </mat-form-field>
        <mat-form-field class="col-6">
          <mat-label>Insurance</mat-label>
          <input autocomplete="new-insurance" matInput maxlength="40" formControlName="insurance" type="text" />
        </mat-form-field>
        <mat-form-field class="col-6">
          <mat-label>Product</mat-label>
          <input autocomplete="new-product" matInput maxlength="40" formControlName="product" type="text" />
        </mat-form-field>
        <mat-form-field class="col-6">
          <mat-label>Product Category</mat-label>
          <input autocomplete="new-productCategory" matInput maxlength="40" formControlName="productCategory" type="text" />
        </mat-form-field>

        <mat-form-field class="col-6">
          <mat-label>Verification Status </mat-label>
          <mat-select formControlName="verificationStatus">
            <mat-option *ngFor="let provider of drpVerificationStatus" [value]="provider?.verificationStatus">
              {{ provider?.verificationStatus }}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <!-- Newly added params -->
        <mat-form-field class="col-6">
          <mat-label>RND Comments </mat-label>
          <textarea formControlName="txtRndComments" rows="3" maxlength="1000" matInput></textarea>
        </mat-form-field>
        <mat-form-field class="col-6">
          <mat-label>SourceMD Comments </mat-label>
          <textarea matInput rows="3" maxlength="1000" formControlName="txtSourceMDComments"></textarea>
        </mat-form-field>
        <!-- Newly added params -->

      </div>
    </form>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button class="buttonColor" [disabled]="isShowSpinner" (click)="saveInsrance()">
    Save
    <mat-icon *ngIf="isShowSpinner">
      <mat-spinner class="spinner-border spinner-border-sm" diameter="20">
      </mat-spinner>
    </mat-icon>
  </button>
  <button class="buttonCancelColor" mat-button mat-dialog-close>Close</button>
</mat-dialog-actions>