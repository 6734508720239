<div class="container-fluid mt-3 section">
    <div class="row bg">
        <div class="col-12">
            <div class="d-flex align-items-center justify-content-between">
            <h3 class="font-weight-bolder mb-0">
                <b class="customThemeClass">List / Orders / Resupply</b>
            </h3>
            </div>
        </div>
    </div>

    <div class="card mt-2">
      <div class="row">
          <div class="col-12">
              <div class="card">
                <div class="row">
                  <div class="col-xl-8">
                    <ul ngbNav #nav="ngbNav" justify="justified" class="alert-success nav nav-pills nav-fill curved-card-header">
                      <li ngbNavItem="Current" class="header-text first-tab" [class.selected-tab]="nav.activeId === ResupplyTypes.CurrentSupplies">
                        <a ngbNavLink (click)="changeTab(ResupplyTypes.CurrentSupplies)">Current Supplies</a>
                      </li>
                      <li ngbNavItem="Overdue" class="header-text"[class.selected-tab]="nav.activeId === ResupplyTypes.OverDueSupplies">
                        <a ngbNavLink (click)="changeTab(ResupplyTypes.OverDueSupplies)">Overdue Supplies</a>
                      </li>
                      <li ngbNavItem="Above90Days" class="header-text"[class.selected-tab]="nav.activeId === ResupplyTypes.Above90Days">
                        <a ngbNavLink (click)="changeTab(ResupplyTypes.Above90Days)">Overdue Orders</a>
                      </li>
                      <li ngbNavItem="Inactive" class="header-text"[class.selected-tab]="nav.activeId === ResupplyTypes.InactiveSupplies">
                        <a ngbNavLink (click)="changeTab(ResupplyTypes.InactiveSupplies)">Inactive Supplies</a>
                      </li>
                      <li ngbNavItem="Archive" class="header-text"[class.selected-tab]="nav.activeId === ResupplyTypes.ArchiveSupplies">
                        <a ngbNavLink (click)="changeTab(ResupplyTypes.ArchiveSupplies)">Archive Files</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
        </div>

        <div class="row mt-3">
          <div class="col-12">
                <!-- <div *ngIf="(activeTab===ResupplyTypes.CurrentSupplies||activeTab===ResupplyTypes.OverDueSupplies)"> -->
                <div>
                    <form [formGroup]="suppliesForm" *ngIf="!isChangeTab">
                        <div class="col-lg-12">
                          <mat-card-content>
                              <div class="row">

                                <mat-form-field class="col-2" *ngIf="activeTab === ResupplyTypes.CurrentSupplies">
                                  <mat-label> Next Call Date Range
                                    <i class="fa fa-calendar ml-2" style="color: rgb(0, 0, 0)"
                                      matSuffix align="end" ></i>
                                  </mat-label>
                                  <input
                                            autocomplete="new-txtNextCallDate"
                                            matInput
                                            type="text"
                                            name="daterange"
                                            ngxDaterangepickerMd
                                            [showCustomRangeLabel]="true"
                                            [alwaysShowCalendars]="true"
                                            [autoApply]="true"
                                            [(ngModel)]="selectedRange"
                                            [linkedCalendars]="false"
                                            [ranges]="ranges"
                                            [showClearButton]="false"
                                            [locale]="calendarLocale"
                                            [keepCalendarOpeningWithRange]="true"
                                            formControlName="txtNextCallDate"
                                            (datesUpdated)="datesUpdateds($event)"
                                            [readonly]="true"
                                            (keyup)="datesUpdateds($event)"
                                          />
                                  <button type="button" mat-button (click)="clearNextCallDate()"
                                  matSuffix mat-icon-button>
                                  <mat-icon>close</mat-icon>
                                </button>
                                </mat-form-field>

                                <!-- <mat-form-field class="col-2" *ngIf="activeTab === ResupplyTypes.Above90Days">
                                  <mat-label> Supply Date Range
                                    <i class="fa fa-calendar ml-2" style="color: rgb(0, 0, 0)"
                                      matSuffix align="end" ></i>
                                  </mat-label>
                                  <input
                                            autocomplete="new-txtSupplyCallDate"
                                            matInput
                                            type="text"
                                            name="daterange"
                                            ngxDaterangepickerMd
                                            [showCustomRangeLabel]="true"
                                            [alwaysShowCalendars]="true"
                                            [autoApply]="true"
                                            [(ngModel)]="selectedRange"
                                            [linkedCalendars]="false"
                                            [ranges]="ranges"
                                            [showClearButton]="false"
                                            [locale]="calendarLocale"
                                            [keepCalendarOpeningWithRange]="true"
                                            formControlName="txtNextCallDate"
                                            (datesUpdated)="datesUpdateds($event)"
                                            [readonly]="true"
                                            (keyup)="datesUpdateds($event)"
                                          />
                                  <button type="button" mat-button (click)="clearNextCallDate()"
                                  matSuffix mat-icon-button>
                                  <mat-icon>close</mat-icon>
                                </button>
                                </mat-form-field> -->
                                   <!-- Supply Date -->
                                <mat-form-field class="col-1_5"  *ngIf="activeTab === ResupplyTypes.Above90Days">
                                  <mat-label> Due Date
                                    <!-- <i class="fa fa-calendar ml-2" style="color: rgb(0, 0, 0)"
                                      matSuffix align="end" ></i> -->
                                  </mat-label>
                                   <input
                                     formControlName="txtSupplyDate"
                                     matInput
                                     maxlength="40"
                                     (keydown)="addSlashToDate($event)"
                                     type="text"
                                     placeholder="(MM/DD/YYYY)"
                                     [matDatepicker]="txtsupplyDate"
                                   />
                                   <mat-datepicker-toggle matSuffix [for]="txtsupplyDate">
                                   </mat-datepicker-toggle>
                                   <mat-datepicker #txtsupplyDate> </mat-datepicker>
                                </mat-form-field>

                                <mat-form-field class="col-2" *ngIf="activeTab !== ResupplyTypes.CurrentSupplies && activeTab !== ResupplyTypes.Above90Days">
                                  <mat-label> {{
                                    activeTab === ResupplyTypes.OverDueSupplies ? 'Next Call Date Range' :
                                    activeTab === ResupplyTypes.InactiveSupplies ? 'Inactive Date Range' :
                                    activeTab === ResupplyTypes.ArchiveSupplies ? 'Archive Date Range' :
                                    ''
                                  }}
                                    <i class="fa fa-calendar ml-2" style="color: rgb(0, 0, 0)"
                                      matSuffix align="end" ></i>
                                  </mat-label>
                                  <input
                                            autocomplete="new-txtNextCallDateV1"
                                            matInput
                                            type="text"
                                            name="daterange"
                                            ngxDaterangepickerMd
                                            [showCustomRangeLabel]="true"
                                            [alwaysShowCalendars]="true"
                                            [autoApply]="true"
                                            [(ngModel)]="selectedRangeV1"
                                            [linkedCalendars]="false"
                                            [ranges]="ranges"
                                            [showClearButton]="false"
                                            [locale]="calendarLocale"
                                            [keepCalendarOpeningWithRange]="true"
                                            formControlName="txtNextCallDateV1"
                                            (datesUpdated)="datesUpdatedsV1($event)"
                                            [readonly]="true"
                                            (keyup)="datesUpdatedsV1($event)"
                                          />
                                  <button type="button" mat-button (click)="clearNextCallDateV1()"
                                  matSuffix mat-icon-button>
                                  <mat-icon>close</mat-icon>
                                </button>
                                </mat-form-field>


                                    <mat-form-field [ngClass]="activeTab === ResupplyTypes.CurrentSupplies ? 'col-1' : 'col-2'">
                                      <mat-label>Chart Id</mat-label>
                                      <input autocomplete="new-txtChartId" appearance="inline" matInput maxlength="20"formControlName="txtChartId" type="text"/>
                                    </mat-form-field>
                                    <mat-form-field class="col-2" >
                                      <mat-label>Patient Name</mat-label>
                                      <input autocomplete="new-patientName" appearance="inline" matInput maxlength="20"formControlName="patientName" type="text"/>
                                    </mat-form-field>

                                    <mat-form-field class="col-2" [hidden]="(activeTab!==ResupplyTypes.CurrentSupplies)" *ngIf="activeTab != ResupplyTypes.Above90Days">
                                      <mat-label>Aging Days</mat-label>
                                      <mat-select formControlName="drpAgingDays">

                                        <mat-option *ngFor="let days of lstAgingDays" [value]="days.id">
                                          {{ days.agingDays }}
                                        </mat-option>

                                      </mat-select>
                                    </mat-form-field>

                                      <!-- CSR Drop Down-->
                                      <mat-form-field class="col-2">
                                        <mat-label> CSR </mat-label>
                                        <mat-select #selectBranch formControlName="drpCSR" multiple [(value)]="name"
                                            (selectionChange)="onCSRSelectionChange($event)" [disabled]="isAdmin">
                                            <mat-select-trigger *ngIf="CSRSelectAllOption">
                                                <span>All</span>
                                              </mat-select-trigger>
                                            <mat-option>
                                                <ngx-mat-select-search placeholderLabel="Search" noEntriesFoundLabel="No Matches found"
                                                    formControlName="txtCSRFilter"></ngx-mat-select-search>
                                            </mat-option>
                                            <mat-option *ngIf="!CSRSelectAllOption" [value]="'selectAll'">Select All</mat-option>
                                            <mat-option *ngIf="CSRSelectAllOption" [value]="'DeselectAll'">Deselect All</mat-option>
                                            <mat-option *ngFor="let csr of filteredUsers | async" [value]="csr.userId">
                                            {{ csr.name }}
                                          </mat-option>
                                        </mat-select>
                                    </mat-form-field>

                                    <mat-radio-group formControlName="isSelectAll" class="radioButtonTxt" [hidden]="(activeTab!==ResupplyTypes.CurrentSupplies && activeTab !== ResupplyTypes.OverDueSupplies)">
                                      <mat-radio-button [value]="0" [checked]="0">All</mat-radio-button>
                                      <mat-radio-button class="p-2"  [value]="1">Ready for Order</mat-radio-button>
                                    </mat-radio-group>
                                    <button class="buttonColor ml-3 mat-raised-small-button" mat-raised-button [matTooltip]="'Search Resupply'" (click)="fnSearchResuppySupply()"><i class="fa fa-search"></i>
                                    </button>
                                    <button class="resetclr ml-1 mat-raised-small-button"mat-raised-button [matTooltip]="'Reset'" (click)="fnFormReset()"><i class="fa fa-refresh"></i>
                                    </button>
                            </div>
                          </mat-card-content>
                        </div>
                    </form>
                </div>
          </div>
        </div>
        <hr class="horizontalLine">
      <div class="row">
          <div class="col-12">
              <div class="card-body">
                  <div class="table table-responsive" *ngIf="activeTab === ResupplyTypes.CurrentSupplies">
                    <app-current-supplies [tableData]="tableDetails" *ngIf="!isLoading"
                    (getPatientDetails)="fnResupplyPatientDetails($event)" (isLoadTbl)="fnSearchSupply()"></app-current-supplies>
                  </div>
                  <div class="table table-responsive" *ngIf="activeTab === ResupplyTypes.OverDueSupplies">
                  <app-overdue-supplies [tableData]="tableDetails"  *ngIf="!isLoading"
                  (getPatientDetails)="fnResupplyPatientDetails($event)"></app-overdue-supplies>
                  </div>
                  <div class="table table-responsive" *ngIf="activeTab === ResupplyTypes.InactiveSupplies">
                  <app-inactive-supplies [tableData]="InactivetableDetails" *ngIf="!isLoading" (getPatientDetails)="fnResupplyPatientDetails($event)" (isInactiveLoadTbl)="fnSearchInActiveSupply()"></app-inactive-supplies>
                  </div>
                  <div class="table table-responsive" *ngIf="activeTab === ResupplyTypes.ArchiveSupplies">
                    <app-archive-supplies  (getPatientDetails)="fnResupplyPatientDetails($event)" [tableData]="tableDetails" *ngIf="!isLoading"></app-archive-supplies>
                  </div>
                  <div class="table table-responsive" *ngIf="activeTab === ResupplyTypes.Above90Days">
                    <app-above-ninetyDays [tableData]="aboveNinetyDaysLst" *ngIf="!isLoading"
                    (getPatientDetails)="fnResupplyPatientDetails($event)" (isLoadTbl)="fnSearchSupply()"></app-above-ninetyDays>
                  </div>
                  <div class="row" *ngIf="isLoading">
                    <div class="col-12">
                      <div class="d-flex justify-content-center" *ngIf="isLoading">
                        <img class="img-responsive pb-2 mr-1" src="assets/gifs/Infinity-loader.gif" />
                      </div>
                    </div>
                  </div>
            </div>
          </div>
      </div>
    </div>

    <div class="mt-2 mb-3">
      <mat-accordion>
        <mat-expansion-panel [expanded]="panelOpenState" (afterExpand)="toggle()" (closed)="toggleV2()">
            <mat-expansion-panel-header class="clsPatientInfo">
                <mat-panel-title>
                    <ng-container>
                        <b class="patientInfo">Patient Info</b>
                    </ng-container>
                </mat-panel-title>
            </mat-expansion-panel-header>
            <hr class="horizontalLine">
            <mat-card-content>
                <div class="row">
                  <div class="col-md-12">
                    <div class="row">
                      <div class="col-md-4">
                        <h1 class="bold" style="padding-top: 10px;">Balance Details</h1>
                          <div class="card card-body clsBalance">
                          <form  [formGroup]="resupplyPatientForm">
                            <div class="row pl-2">
                              <div class="col-md-3">
                                <h3 class="clsPatientDetails">Patient ($)</h3>
                                <h3 class="patientInfo">{{resupplyPatientForm.value.patientAmount}}</h3>
                              </div>
                              <div class="col-md-3">
                                <h3 class="clsPatientDetails">Insurance ($)</h3>
                                <h3 class="patientInfo">{{resupplyPatientForm.value.insAmount}}</h3>
                              </div>
                              <div class="col-md-3">
                                <h3 class="clsPatientDetails">Total ($)</h3>
                                <h3 class="patientInfo">{{resupplyPatientForm.value.totalAmount}}</h3>
                              </div>
                              <div class="col-md-3">
                                <h3 class="clsPatientDetails">Over paid ($)</h3>
                                <h3 class="patientInfo">{{resupplyPatientForm.value.overPaid}}</h3>
                              </div>
                            </div>
                          </form>
                          </div>
                          <h1 class="mt-2 bold">Patient Details</h1>
                          <div class="card card-body clsBalance mb-3">
                            <div class="row pl-2">
                              <div class="col-md-6">
                                <h3 class="clsPatientDetails">Next Call Date</h3>
                                <h3 class="patientInfo">{{resupplyPatientForm.value.nextCallDate||'-'}}</h3>
                                <hr>
                                <h3 class="clsPatientDetails">Newsetup Date</h3>
                                <h3 class="patientInfo">{{resupplyPatientForm.value.newsetupDate||'-'}}</h3>
                                <hr>
                                <h3 class="clsPatientDetails">Practitioner Name</h3>
                                <h3 class="patientInfo">{{resupplyPatientForm.value.practitionerName||'-'}}</h3>
                              </div>
                              <div class="col-md-6">
                                <h3 class="clsPatientDetails">RX Date</h3>
                                <h3 class="patientInfo">{{resupplyPatientForm.value.rxDate||'-'}}</h3>
                                <hr>
                                <h3 class="clsPatientDetails">Email</h3>
                                <h3 class="patientInfo">{{resupplyPatientForm.value.email||'-'}}</h3>
                                <hr>
                                <h3 class="clsPatientDetails">Home No</h3>
                                <h3 class="patientInfo">{{resupplyPatientForm.value.homeNo||'-'}}</h3>
                              </div>
                            </div>
                          </div>

                      </div>
                      <div class="col-md-8">
                        <h1 class="bold" style="padding-top: 10px;">Call Details</h1>
                        <div style="height: 250px; overflow: auto;">
                          <div class="card mb-2 elevated-div content">
                            <div class="d-flex justify-content-center" *ngIf="isPatientLoading">
                              <img class="img-responsive pb-2 mr-1" src="assets/gifs/Infinity-loader.gif" />
                            </div>
                            <div class="table-responsive" *ngIf="!isPatientLoading">
                              <table datatable [dtOptions]="dtCalllogTableOptions" class="tbl hover row-border hover w-100 display">
                            <thead>
                              <tr>
                                <th [matTooltip]="'Date Called'">Date Called</th>
                                <th [matTooltip]="'Called By'">Called By</th>
                                <th [matTooltip]="'Called Notes'">Called Notes</th>
                              </tr>
                            </thead>
                            <tbody class="table table-striped">
                              <tr *ngFor="let log of calllogTableData">
                                <td [matTooltip]="log.creationTime||'-'"  (dblclick)="onRowDoubleClick(log.creationTime || '-')">{{log.creationTime||'-'}} </td>
                                <td [matTooltip]="log.createdByName||'-'" (dblclick)="onRowDoubleClick(log.createdByName || '-')">{{log.createdByName||'-'}}</td>
                                <td [matTooltip]="log.description||'-'" (dblclick)="onRowDoubleClick(log.description || '-')">{{log.description||'-'}}</td>
                              </tr>
                            </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
            </mat-card-content>
        </mat-expansion-panel>
       </mat-accordion>
    </div>
</div>
