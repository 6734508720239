<div class="py-1">
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-12">
        <div class="toast-header">

          <h4 class="mr-auto mt-1 mb-0" style="color:#20B2AA;"><b>Override</b></h4>
          <button type="button" class="close" (click)="closeDialog()">
            <span><b>X</b></span>
          </button>

        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-md-12">
                <div class="form-group">

                  <mat-form-field style="width: 100%">
                  <mat-label style="width: 100%">Override Type <span style="color: red;">*</span></mat-label>
                  <mat-select formControlName="overrideType">
                    <mat-option value="one">Patient Balance Override</mat-option>
                    <mat-option value="two">Second option</mat-option>
                    <mat-option value="three">Third option</mat-option>
                    <mat-option value="four">Fourth option</mat-option>
                  </mat-select>

                </mat-form-field>

              </div>
              </div>
            </div>

            <div class="row">
              <div class="col-md-12">
                <!-- <div class="form-group border"> -->
                  <div class="card flex justify-content-center mb-2  border-0 ">
                    <mat-label>Override Note  <span style="color: red;">*</span> </mat-label>
                    <textarea rows="5" cols="30" pInputTextarea [(ngModel)]="value" class="border-dark mt-1 mb-1"></textarea>
                </div>

                <!-- </div> -->
              </div>
            </div>


            <div class="row">
              <div class="col-md-4">
                <mat-form-field style="width: 100%">
                  <mat-label>User Name <span style="color: red;">*</span></mat-label>
                  <input matInput>
                </mat-form-field>

              </div>
              <div class="col-md-4">
                <mat-form-field style="width: 100%">
                  <mat-label>Password <span style="color: red;">*</span></mat-label>
                  <input matInput [type]="hide ? 'password' : 'text'">
                  <button mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
                    <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                  </button>
                </mat-form-field>
              </div>

              <!-- buttons -->
              <div class="col-md-4 mt"  [formGroup]="overrideForm" (ngSubmit)="onSubmit()">
                <button type="submit"  class="btn mx-1" style="background-color: #20B2AA; color: white;">&nbsp;Save&nbsp;</button>
                <button type="reset" class="btn mx-1" style="background-color: #FF0000; color: white;" (click)="resetForm()">Reset</button></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="py-2 text-center">
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-12"><button type="submit" class="btn " style="background-color: #20B2AA; color: white;">Submit for Override</button></div>
    </div>
  </div>
</div>
