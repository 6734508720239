<div *ngIf="!isLoading">
<table
  id="tblMMCFaxProcessList"
  datatable
  [dtOptions]="dtMMCPendingOptions"
  class="tbl hover row-border hover w-100 display"
>
  <thead>
    <tr>
      <!-- class="text-center" -->
      <th><mat-icon> wrap_text</mat-icon></th>
      <th><mat-icon>apps</mat-icon></th>
      <th [matTooltip]="'Checklist Id'" class="text-right">Checklist Id</th>
      <th [matTooltip]="'Fax Id'">Fax Id</th>
      <th [matTooltip]="'Chart No'">Chart No</th>
      <th [matTooltip]="'Patient Name'">Patient Name</th>
      <th [matTooltip]="'Type'">Type</th>
      <th [matTooltip]="'Prepared By'">Prepared By</th>
      <th [matTooltip]="'Prepared On'" class="text-center">Prepared On</th>
      <th [matTooltip]="'Status'">Status</th>
      <th [matTooltip]="'Location'">Location</th>
      <th [matTooltip]="'Locked By'">Locked By</th>
      <th [matTooltip]="'Created By'">Created By</th>
      <th class="text-right" [matTooltip]="'Created Date'">Created Date</th>
      <th [matTooltip]="'Tech'">Tech</th>
      <th [matTooltip]="'STAT'">STAT</th>
    </tr>
  </thead>
  <tbody>
    <tr
      *ngFor="let data of DataList"
      (click)="tblItemSelectedRow(data.medicareChecklistId)"
      [class.selected-row]="data.medicareChecklistId === selectedRow"
      [ngClass]="{
        stat_highlight: data.stat === 1 || data.successfulStatUpdate
      }"
    >
      <td
        [matTooltip]="'Expand Notes'"
        class="dt-control"
        (click)="handleTableCellClick($event, data.notes)"
      ></td>
      <td>
        <mat-icon
          [matTooltip]="'Open Options'"
          style="cursor: pointer !important; margin-right: 8px"
          [matMenuTriggerFor]="menu"
          >more_vert</mat-icon
        >
        <ng-container *ngIf="data.lockedStatus === 0; else case1">
          <mat-checkbox
            color="primary"
            [disabled]="disableLockCheckbox"
            [matTooltip]="'Lock Checkbox'"
            class="mt-1"
            [(ngModel)]="data.isLocked"
            (change)="islockUpdate($event, data, 0)"
          >
          </mat-checkbox>
        </ng-container>
        <ng-template #case1>
          <ng-container
            *ngIf="
              data.lockedStatus === 1 || isRoleName === 'admin';
              else case2
            "
          >
            <mat-checkbox
              color="primary"
              [disabled]="disableLockCheckbox"
              [matTooltip]="'Lock Checkbox'"
              class="mt-1"
              [(ngModel)]="data.isLocked"
              (change)="islockUpdate($event, data, 0)"
            >
            </mat-checkbox>
          </ng-container>
          <ng-template #case2>
            <ng-container
              *ngIf="data.lockedStatus === 2 || isRoleName !== 'admin'"
            >
              <mat-icon style="color: #fd0808">lock</mat-icon>
            </ng-container>
          </ng-template>
        </ng-template>
        <mat-menu class="custom-mat-menu" #menu="matMenu">
          <!-- <a [class.disabled]="isEmpty(data.patientId) || isEmpty(data.docId) || isEmpty(data.blobName)||(data.status!=='New')"
              [routerLink]="'/do_checklist/' + data.blobName + '/' + data.defautlFaxNo + '/' + data.docId + '/' + data.patientId + '/' + data.medicareCheckListId"
              target="_blank" class="preview-link">
              <button [disabled]="isEmpty(data.patientId) || isEmpty(data.docId) || isEmpty(data.blobName)||(data.status!=='New')"
                mat-menu-item>Do Checklist</button>
            </a> -->

          <!-- <button mat-menu-item [disabled]="isEmpty(data.patientId) || isEmpty(data.medicareChecklistId)"
              (click)="openAddNotesPopUp(data.patientId, data.defautlFaxNo , data.docId, data.medicareChecklistId)">Add
              Patient Note</button> -->

          <!-- <a [routerLink]="'/do_checklist/' + data.patientId + '/' + '/' + data.medicareChecklistId" target="_blank"
            class="preview-link" [ngClass]="{'disabled-link':  data.status!=='New'}">
            <button mat-menu-item>Do Checklist</button>
            </a> -->

          <button
            mat-menu-item
            [disabled]="
              isEmpty(data.patientId) ||
              isEmpty(data.docId) ||
              data.status !== 'New' ||
              (data.lockedStatus === 2 && isRoleName !== 'admin')
            "
            (click)="isDoChekList(data)"
          >
            Do Checklist
          </button>

          <a
            [routerLink]="
              '/view_checklist/' +
              data.patientId +
              '/' +
              true +
              '/' +
              data.medicareChecklistId
            "
            target="_blank"
            class="preview-link"
            [ngClass]="{
              'disabled-link':
                data.status == 'New' ||
                data.medicareChecklistId === null ||
                (data.lockedStatus === 2 && isRoleName !== 'admin')
            }"
          >
            <button mat-menu-item>View Checklist</button>
          </a>

          <button
            mat-menu-item
            [disabled]="
              isEmpty(data.patientId) || isEmpty(data.medicareChecklistId)
            "
            (click)="openAddNotesPopUp($event, data)"
          >
            Add Patient Note
          </button>

          <button
            mat-menu-item
            [disabled]="isEmpty(data.patientId) || isEmpty(data.docId)"
            (click)="openViewNotesPopup($event, data)"
          >
            View Patient Notes
          </button>

          <!-- <a [class.disabled]="isEmpty(data.patientId) || isEmpty(data.docId)"
            [routerLink]="'/do_checklist/' + data.patientId + '/' + '/' + data.medicareChecklistId"
              target="_blank" class="preview-link" [ngClass]="{'disabled-link':  data.status=='New'}">
              <button [disabled]="isEmpty(data.patientId) || isEmpty(data.docId)"
                mat-menu-item>Change Checklist Type</button>
            </a> -->

          <button
            mat-menu-item
            [disabled]="
              isEmpty(data.patientId) ||
              isEmpty(data.docId) ||
              data.status == 'New' ||
              (data.lockedStatus === 2 && isRoleName !== 'admin')
            "
            (click)="isDoChekList(data)"
          >
            Change Checklist Type
          </button>

          <a
            [class.disabled]="isEmpty(data.docId) || data?.isSorted === false"
            [routerLink]="'/view_document_tiff/' + data.docId + '/' + true"
            target="_blank"
            class="preview-link"
          >
            <button
              [disabled]="isEmpty(data.docId) || data?.isSorted === false"
              mat-menu-item
            >
              View Document Images
            </button>
          </a>
          <button
            mat-menu-item
            [disabled]="isEmpty(data.verificationId)"
            (click)="viewVerificationById(data)"
          >
            View Verification
          </button>
          <button
            mat-menu-item
            [disabled]="isEmpty(data.patientId)"
            (click)="viewPatientLedger(data.patientId)"
          >
            View Patient Ledger
          </button>
          <button
            mat-menu-item
            [disabled]="true"
            (click)="deleteChecklist(data.medicareChecklistId)"
          >
            Delete Checklist
          </button>
        </mat-menu>
      </td>
      <td
        (dblclick)="onRowDoubleClick(data?.uniqueChecklistId || '-')"
        [matTooltip]="data?.uniqueChecklistId || '-'"
        class="text-right"
      >
        {{ data?.uniqueChecklistId || "-" }}
      </td>
      <!-- <td (dblclick)="onRowDoubleClick(data?.defautlFaxNo || '-')" [matTooltip]="data?.defautlFaxNo || '-'">{{
          data?.defautlFaxNo || '-'}}</td> -->
      <td
        class="custom-width"
        (dblclick)="onRowDoubleClick(data?.defautlFaxNo || '-')"
      >
        <a
          [matTooltip]="data?.defautlFaxNo || '-'"
          [class.disabled]="isEmpty(data.docId) || isEmpty(data.defautlFaxNo)"
          [routerLink]="
            '/patient_preview_tiff_document/' +
            data.defautlFaxNo +
            '/' +
            data.docId
          "
          target="_blank"
          class="preview-link"
          >{{ data?.defautlFaxNo || "-" }}</a
        >
      </td>
      <td
        (dblclick)="onRowDoubleClick(data?.chartNo || '-')"
        [matTooltip]="data?.chartNo || '-'"
      >
        <a
          [class.disabled]="isEmpty(data.chartNo) || isEmpty(data.patientId)"
          [routerLink]="
            '/patientCreateEditTab/' + data?.patientId + '/' + data?.docId
          "
          target="_blank"
          class="preview-link"
        >
          {{ data?.chartNo || "-" }}
        </a>
      </td>
      <td
        (dblclick)="onRowDoubleClick(data?.patientName || '-')"
        [matTooltip]="data?.patientName || '-'"
      >
        {{ data?.patientName || "-" }}
      </td>

      <td
        (dblclick)="onRowDoubleClick(data?.checkListType || '-')"
        [matTooltip]="data?.checkListType || '-'"
      >
        {{ data?.checkListType || "-" }}
      </td>
      <td
        (dblclick)="onRowDoubleClick(data?.preparedByName || '-')"
        [matTooltip]="data?.preparedByName || '-'"
      >
        {{ data?.preparedByName || "-" }}
      </td>
      <td
        (dblclick)="onRowDoubleClick(data?.preparedOn || '-')"
        [matTooltip]="data?.preparedOn || '-'"
        class="text-center"
      >
        {{ data?.preparedOn || "-" }}
      </td>
      <td
        (dblclick)="onRowDoubleClick(data?.status || '-')"
        [matTooltip]="data?.status || '-'"
      >
        {{ data?.status || "-" }}
      </td>
      <td
        (dblclick)="onRowDoubleClick(data?.location || '-')"
        [matTooltip]="data?.location || '-'"
      >
        {{ data?.location || "-" }}
      </td>
      <td
        (dblclick)="onRowDoubleClick(data?.lockedbyName || '-')"
        [matTooltip]="data?.lockedbyName || '-'"
      >
        {{ data?.lockedbyName || "-" }}
      </td>

      <td
        [matTooltip]="data?.createdBy || '-'"
        (dblclick)="onRowDoubleClick(data?.createdBy || '-')"
      >
        {{ data?.createdBy || "-" }}
      </td>

      <td
        class="text-right"
        [matTooltip]="data?.creationTime || '-'"
        (dblclick)="onRowDoubleClick(data?.creationTime || '-')"
      >
        {{ data?.creationTime || "-" }}
      </td>
      <td
        (dblclick)="onRowDoubleClick(data?.technician || '-')"
        [matTooltip]="data?.technician || '-'"
      >
        {{ data?.technician || "-" }}
      </td>
      <td>
        <!-- isEmpty(data.medicareChecklistId) && -->
        <!-- <mat-checkbox color="primary" [matTooltip]="'STAT Checkbox'" [disabled]="disableSTATCheckbox"
            [(ngModel)]="data.stat" (change)="isStatUpdate(data.medicareChecklistId, data.docId)">
          </mat-checkbox> -->
        <mat-checkbox
          color="primary"
          [matTooltip]="'STAT Checkbox'"
          [disabled]="disableSTATCheckbox"
          [(ngModel)]="data.stat"
          (change)="isStatUpdate($event, data)"
        >
          <span [hidden]="true">{{ data.stat ? "True" : "False" }}</span>
        </mat-checkbox>
      </td>
    </tr>
  </tbody>
  <tfoot>
    <tr>
      <th><mat-icon> wrap_text</mat-icon></th>
      <th><mat-icon>apps</mat-icon></th>
      <th>
        <input
          matInput
          class="textbox-border-class text-center"
          type="text"
          placeholder="Checklist Id"
          name="search-ChecklistId"
        />
      </th>
      <th>
        <input
          matInput
          class="textbox-border-class"
          type="text"
          placeholder="Fax Id"
          name="search-FaxId"
        />
      </th>
      <th>
        <input
          matInput
          type="text"
          class="textbox-border-class"
          placeholder="Chart No"
          name="search-ChartNo"
        />
      </th>
      <th>
        <input
          matInput
          type="text"
          class="textbox-border-class"
          placeholder="Patient Name"
          name="search-PatientName"
        />
      </th>
      <th>
        <input
          matInput
          type="text"
          class="textbox-border-class"
          placeholder="Type"
          name="search-Type"
        />
      </th>
      <th>
        <input
          matInput
          class="textbox-border-class"
          type="text"
          placeholder="Prepared By"
          name="search-PreparedBy"
        />
      </th>
      <th>
        <input
          matInput
          class="textbox-border-class text-center"
          type="text"
          placeholder="Prepared On"
          name="search-PreparedOn"
        />
      </th>
      <th>
        <input
          matInput
          class="textbox-border-class"
          type="text"
          placeholder="Status"
          name="search-Status"
        />
      </th>
      <th>
        <input
          matInput
          class="textbox-border-class"
          type="text"
          placeholder="Location"
          name="search-Location"
        />
      </th>
      <th>
        <input
          matInput
          class="textbox-border-class"
          type="text"
          placeholder="Locked By"
          name="search-Locked-By"
        />
      </th>
      <th>
        <input
          matInput
          class="textbox-border-class"
          type="text"
          placeholder="Created By"
          name="search-Created By"
        />
      </th>
      <th class="text-right">
        <input
          matInput
          class="textbox-border-class"
          type="text"
          placeholder="Created Date"
          name="search-Created Date"
        />
      </th>
      <th>
        <input
          matInput
          class="textbox-border-class"
          type="text"
          placeholder="Tech"
          name="search-Tech"
        />
      </th>
      <th><mat-icon>no_encryption</mat-icon></th>
    </tr>
  </tfoot>
</table>
</div>
<div class="row" *ngIf="isLoading">
  <div class="col-12">
    <div class="d-flex justify-content-center">
      <img
        class="img-responsive pb-2 mr-1"
        src="assets/gifs/Infinity-loader.gif"
      />
    </div>
  </div>
</div>
