<mat-dialog-content class="mat-typography" style="height:90% !important">
    <div>
        <h3 class="customThemeClass mt-2"><b>Product Allowables View</b></h3>
        <form [formGroup]="productForm">
            <mat-form-field class="col-3">
                <mat-label> HCPC Code / Product Name <span class="asterisk">*</span> </mat-label>
                <input autocomplete="new-drpProductNameHpscCode"  matInput readonly formControlName="drpProductNameHpscCode" type="text" />
                <mat-error *ngIf="
                productForm?.get('drpProductNameHpscCode')?.touched &&
                productForm?.get('drpProductNameHpscCode')?.errors?.required
              ">
                    HCPC Code / Product Name is a required field!
                </mat-error>
            </mat-form-field>

            <mat-form-field class="col-3">
                <mat-label>Insurance Name <span class="asterisk">*</span></mat-label>
                <mat-select formControlName="drpInsurance" [(value)]="Insurance" #singleSelect>
                    <mat-option>
                        <ngx-mat-select-search placeholderLabel="Search" noEntriesFoundLabel="No Matches found"
                            formControlName="txtInsuranceFilter"></ngx-mat-select-search>
                    </mat-option>
                    <mat-option *ngFor="let insurance of filteredInsuranceTypes | async" [value]="insurance.id">
                        {{insurance.payerCode + '/' + insurance.payerName}}
                    </mat-option>
                </mat-select>
                <mat-error *ngIf="
                productForm?.get('drpInsurance')?.touched &&
                productForm?.get('drpInsurance')?.errors?.required
              ">
                    Insurance Name is a required field!
                </mat-error>
                <button mat-button (click)="Insurance=undefined;$event.stopPropagation()" *ngIf="Insurance" matSuffix
                    (click)="clearInsurance()" mat-icon-button >
                    <mat-icon>close</mat-icon>
                </button>
            </mat-form-field>

            <mat-form-field class="col-3">
                <mat-label> Eligible Quantity <span class="asterisk">*</span> </mat-label>
                <input autocomplete="new-txtEligibleQuantity" matInput min="1" maxlength="4" formControlName="txtEligibleQuantity" type="text"  />
                <mat-error *ngIf="
                    productForm?.get('txtEligibleQuantity')?.touched &&
                    productForm?.get('txtEligibleQuantity')?.errors?.number
                ">
                    Enter only Numbers!
                </mat-error>
                <mat-error *ngIf="
                productForm?.get('txtEligibleQuantity')?.touched &&
                productForm?.get('txtEligibleQuantity')?.errors?.required
              ">
                    Eligible Qty  is a required field!
                </mat-error>
                <mat-error *ngIf="
                productForm?.get('txtEligibleQuantity')?.touched &&
                productForm?.get('txtEligibleQuantity')?.errors?.min
              ">
              Enter Valid Number Greater Than 0!
                </mat-error>
            </mat-form-field>

            <mat-form-field class="col-3">
                <mat-label> Eligible Months <span class="asterisk">*</span> </mat-label>
                <input autocomplete="new-txtEligibleMonths" matInput min="1" maxlength="2" formControlName="txtEligibleMonths" type="text" />
                <mat-error *ngIf="
                        productForm?.get('txtEligibleMonths')?.touched &&
                        productForm?.get('txtEligibleMonths')?.errors?.number
                    ">
                    Enter only Numbers!
                </mat-error>
                <mat-error *ngIf="
                        productForm?.get('txtEligibleMonths')?.touched &&
                        productForm?.get('txtEligibleMonths')?.errors?.required
                    ">
                    Eligible Months is a required field!
                </mat-error>
                <mat-error *ngIf="
                productForm?.get('txtEligibleMonths')?.touched &&
                productForm?.get('txtEligibleMonths')?.errors?.min
                  ">
                  Enter Valid Number Greater Than 0!
                    </mat-error>
            </mat-form-field>
            <mat-form-field class="col-3">
                <mat-label> Allowables Amount <span class="asterisk">*</span> </mat-label>
                <input autocomplete="new-txtAllowableAmount" matInput (keypress)="keyPressNumbersDecimal($event)"
                formControlName="txtAllowableAmount" min="1" type="text" />
                <!-- <mat-error *ngIf="
                        productForm?.get('txtAllowableAmount')?.touched &&
                        productForm?.get('txtAllowableAmount')?.errors?.pattern
                    ">
                    Enter only Numbers!
                </mat-error> -->
                <mat-error *ngIf="
                        productForm?.get('txtAllowableAmount')?.touched &&
                        productForm?.get('txtAllowableAmount')?.errors?.required
                    ">
                    Allowables Amount is a required field!
                </mat-error>
                <mat-error *ngIf="
                productForm?.get('txtAllowableAmount')?.touched &&
                productForm?.get('txtAllowableAmount')?.errors?.min
                  ">
                  Enter Valid Number Greater Than 0!
                    </mat-error>
            </mat-form-field>
        </form>

    </div>


    <div class="col-12" class="text-align-center center">
        <div>
            <button mat-button class="buttonColor  mr-2" [disabled]="!(!productForm.invalid && productForm.dirty )"
                (click)="saveProduct()"> Save
                <mat-icon *ngIf='isShowSpinner'>
                    <mat-spinner class="spinner-border spinner-border-sm" diameter="20"> </mat-spinner>
                </mat-icon>
            </button>
            <button mat-button class="resetclr mb-2 mr-2" (click)="resetProductAllowablesForm()">
                Reset
            </button>
        </div>
    </div>


    <div class="row mr-3">
        <div class="col-12">
            <!-- <div [hidden]="!isLoading"
                style="display: flex; justify-content: center; align-items: center; background: transparent">
                <mat-progress-spinner mode="indeterminate" value="66" diameter="45" class="lightseagreen">
                </mat-progress-spinner>
            </div> -->

            <table mat-table class="table ml-4" matSort [dataSource]="dataSource">
                <form style="display: flex" [formGroup]="productSearchForm">
                    <ng-container matColumnDef="sno">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>S.No</th>
                        <td mat-cell *matCellDef="let data; let i = index">
                            {{i+1}}
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="productNumber">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                            Insurance Name
                        </th>
                        <td mat-cell *matCellDef="let data">
                            <ng-container>
                                {{data.insuranceName}}
                            </ng-container>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="eligibleQuantity">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                            Eligible Units
                        </th>
                        <td mat-cell *matCellDef="let data">
                            <ng-container>
                                {{data.eligibleQuantity}}
                            </ng-container>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="eligibleMonths">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                            Eligible Months
                        </th>
                        <td mat-cell *matCellDef="let data">
                            <ng-container>
                                {{data?.eligibleMonths}}
                            </ng-container>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="allowablesAmount">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                           Allowables Amount
                        </th>
                        <td mat-cell *matCellDef="let data">
                            <ng-container>
                                {{data?.allowableAmount}}
                            </ng-container>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="createdDate">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                            Created Date
                        </th>
                        <td mat-cell *matCellDef="let data">
                            <ng-container>
                                {{data?.createdDate}}
                            </ng-container>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="status">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                            Status
                        </th>
                        <td mat-cell *matCellDef="let data">
                            <ng-container>
                                {{data?.status}}
                            </ng-container>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="Options">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Options</th>
                        <td mat-cell *matCellDef="let data">
                            <a class="editBtnColor" (click)="viewAllowables(data)">
                                <i class="fa fa-pencil editBtnColor mr-3" matTooltip="Click To Edit"
                                    ></i>
                            </a>
                            &nbsp;
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="noRecords">
                        <td class="text-align-center" mat-footer-cell *matFooterCellDef colspan="9">
                            <div *ngIf="!isLoading">
                                {{"'No Records Found'"}}
                            </div>
                            <div [hidden]="!isLoading">
                                <mat-spinner [diameter]="220"></mat-spinner>
                            </div>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="paginator">
                        <td mat-footer-cell *matFooterCellDef colspan="8">
                            <mat-paginator class="table table-responsive mat-table-class" #MatPaginator
                                [length]="dataSource?.data?.length" [pageSize]="10" [pageIndex]="0" showFirstLastButtons
                                [pageSizeOptions]="[5, 10, 25, 100]">
                            </mat-paginator>
                        </td>
                    </ng-container>
                </form>
                <tr mat-footer-row *matFooterRowDef="['noRecords']"
                    [ngClass]="{ active: dataSource && dataSource?.data && dataSource?.data?.length !== 0 }">
                </tr>
                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
                <tr mat-footer-row *matFooterRowDef="['paginator']"></tr>
            </table>

        </div>
    </div>

</mat-dialog-content>

<mat-dialog-actions align="end">
    <button class="buttonCancelColor" mat-button mat-dialog-close>Close</button>
</mat-dialog-actions>
