import { PagedAndSortedResultRequestDto } from '@abp/ng.core';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatOption } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSelect } from '@angular/material/select';
import { Button } from '@syncfusion/ej2-angular-buttons';
import { DropDownList } from '@syncfusion/ej2-angular-dropdowns';
import { MonthService, ScheduleComponent, TimelineMonthService, TimelineViewsService, WeekService } from '@syncfusion/ej2-angular-schedule';
import { ActionEventArgs, CellClickEventArgs, EJ2Instance, EventClickArgs, EventRenderedArgs, EventSettingsModel, GroupModel } from '@syncfusion/ej2-schedule';
import { DashboardService } from 'projects/admin/src/app/admin-proxy/platform-app-management/rcm/platform-management/dashboard/dashboard.service';
import { RoleService } from 'projects/admin/src/app/admin-proxy/platform-app-management/rcm/platform-management/role/role.service';
import { IdentityUserUsingTenantIdDto } from 'projects/admin/src/app/admin-proxy/platform-app-management/rcm/platform-management/user/models';
import { UserService } from 'projects/admin/src/app/admin-proxy/platform-app-management/rcm/platform-management/user/user.service';
import { CalendarService } from 'projects/on-time-schedules/src/app/onTime-proxy/rcm/on-time-management/on-time-schedule/calendar.service';
import { CreateUpdateCalendarDetailsDTO, CreateUpdateCalendarDTO } from 'projects/on-time-schedules/src/app/onTime-proxy/rcm/on-time-management/on-time-schedule/dto/models';
import { map, startWith, switchMap } from 'rxjs/operators';
import { createElement } from '@syncfusion/ej2-base';

import { resourceData } from '../data';
import { PatientSearchService } from 'projects/patient/src/app/patient-proxy/patient/patient-search.service';
import { HttpErrorResponse } from '@angular/common/http';
import Swal from 'sweetalert2';
import { CalendarStatus } from 'projects/on-time-schedules/src/app/onTime-proxy/rcm/on-time-management/on-time-schedule/calendar-status.enum';
import { OnTimeDropdowns } from 'projects/patient/src/app/dropdown-proxy/dropdown-management/dropdowns/on-time-dropdowns.enum';
import { OnTimeMasterDropdownDTO } from 'projects/patient/src/app/dropdown-proxy/dropdown-management/dropdowns/dto/on-time-schedule/models';
import { OnTimeMasterDropdownService } from 'projects/patient/src/app/dropdown-proxy/dropdown-management/dropdowns/master-dropdown-service/on-time-master-dropdown.service';
import { of, Subscription } from 'rxjs';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-admin-calender',
  templateUrl: './admin-calender.component.html',
  styleUrls: ['./admin-calender.component.scss'],
  providers: [WeekService, MonthService, TimelineViewsService, TimelineMonthService],
})
export class AdminCalenderComponent implements OnInit, OnDestroy {
  calenderForm: FormGroup;
  @ViewChild("scheduleObj", { static: true })
  public scheduleObj: ScheduleComponent;
  tenantId: string = "";
  userTableData: any[] = [];
  statusTableData: any[] = [];
  userData: any[] = [];
  SearchUserTableData: any[] = [];
  QGuid: string = "";
  userId: string = "";
  userName: string = "";
  roleId: string = "";
  roleName: string = "";
  subscription$: Subscription[] = [];
  @ViewChild('allUsersSelected') private allUsersSelected: MatOption;
  @ViewChild('userSelect') userSelect: MatSelect;
  @ViewChild('allRolesSelected') private allRolesSelected: MatOption;
  @ViewChild('roleSelect') roleSelect: MatSelect;
  public selectedDate: Date = new Date();
  public views: Array<string> = ['Week', 'Month', 'TimelineWeek', 'TimelineMonth'];
  public eventSettings: EventSettingsModel = {
    dataSource: this.getTableData()
  };
  public group: GroupModel = {
    byGroupID: false,
    resources: ['Owners']

  }
  public allowMultipleRoom: Boolean = true;
  public roomDataSource: Object[] = this.userData;
  constructor(private userService: UserService,
    private calenderService: CalendarService,
    private adminService: DashboardService,
    private formBuilder: FormBuilder,
    private roleService: RoleService,
    public dialog: MatDialog,
    private PatientService: PatientSearchService,
    public title: Title,
    private onTimeDropdown: OnTimeMasterDropdownService) { }
  ngOnDestroy(): void {
    this.subscription$.forEach(x => x.unsubscribe())
  }

  public conferenceDataSource: Object[] = this.getDropDown();


  ngOnInit(): void {
    this.adminService.userProfile().subscribe((response) => {
      this.userId = response?.userId;
      this.userName = response?.userName;

    })

    this.calenderForm = this.formBuilder.group({
      txtRole: new FormControl(""),
      txtRoleFilter: new FormControl(""),
      txtUser: new FormControl(""),
      txtUserFilter: new FormControl(""),
    })

    this.tenantId = localStorage.getItem("tenantId") ?? "";
    this.getPatient();
    this.getUserList()
    this.getRoleList();
    this.getDropDown()
    this.title.setTitle("Qsecure | Admin Calender");
  }



  /// dropdown datas
  getDropDown() {
    let onTimeDropdownInput: OnTimeDropdowns[];
    onTimeDropdownInput = [
      OnTimeDropdowns.CalendarStatus,

    ]
    this.onTimeDropdown.getOnTimeDropdownsByInput(onTimeDropdownInput).subscribe((stateResponse) => {
      let response: OnTimeMasterDropdownDTO = stateResponse;
      this.statusTableData = [];
      response && response?.calendarStatuses.forEach(element => {
        this.statusTableData?.push({
          Text: element?.calendarStatus,
          shortCodeId: element?.shortCodeId,
          Id: element?.calendarStatusId,
        });
      })

      this.conferenceDataSource = this.statusTableData;
    })
    return this.statusTableData

  }

  Role: string = '';
  User: string = '';
  filteredRoleTypes;
  filteredUserTypes;
  cleartxtRole() {
    this.Role = '';
    this.calenderForm.patchValue({ txtRole: "" });
    this.onChanges(this.calenderForm.value.txtRole, this.calenderForm.value.txtUser);

  }
  cleartxtUser() {
    this.User = '';
    this.calenderForm.patchValue({ txtUser: "" });
    this.onChanges(this.calenderForm.value.txtRole, this.calenderForm.value.txtUser);
  }
  //! Users get list
  getUserList() {
    let filters: IdentityUserUsingTenantIdDto = {
      tenantId: this.tenantId
    }
    this.userService.getUserList(filters).subscribe(response => {
      let resourceData: Object[]
      this.userTableData = [];
      response && response?.forEach(element => {
        this.userTableData.push({
          OwnerText: element?.userName,
          email: element?.email,
          role: element?.roleName,
          Id: element?.id,
        });

        this.filteredUserTypes = this.calenderForm?.get("txtUserFilter").valueChanges
          .pipe(
            startWith(''),
            map(value => this.userTableData.filter(option => option?.OwnerText?.toLowerCase()?.includes(value?.toLowerCase() ?? "")))
          );
      })
      // this.roomDataSource = this.userTableData;

    })
    // return this.userTableData
  }


  ///role list
  drpRoles: any[] = [];
  getRoleList() {
    let roleList = {
      tenantId: this.tenantId
    }
    this.roleService.getRoleList(roleList).subscribe(response => {
      response.forEach(element => {
        this.drpRoles.push({
          id: element?.Id,
          name: element.Name
        })
        this.filteredRoleTypes = this.calenderForm?.get("txtRoleFilter").valueChanges
          .pipe(
            startWith(''),
            map(value => this.drpRoles.filter(option => option?.name?.toLowerCase()?.includes(value?.toLowerCase() ?? "")))
          );
      });
    })
  }

  /// onchange user list
  eventDatas: any[] = [];
  onChanges(role, user) {

    this.userData = [];
    this.userService.getUsersListByLstRolesAndLstUsersAndTenantId(role, user, this.tenantId).subscribe(response => {
      response?.forEach(element => {
        this.userData.push({
          OwnerText: element?.userName,
          email: element?.email,
          role: element?.roleName,
          Id: element?.id,
        })

      });
      this.roomDataSource = this.userData;
    })


    this.eventDatas = user;
    this.getTableData();

    return this.userData


  }

  //// search function
  search() {
    let userNames: any[] = [];
    let roleNames: any[] = [];
    this.calenderForm.value.txtUser !== "" &&
      this.calenderForm.value.txtUser !== undefined &&
      this.calenderForm.value.txtUser !== null &&
      [this.calenderForm.value.txtUser].length !== 0 &&
      [this.calenderForm.value.txtUser].forEach(val => {
        val?.forEach(element => {
          element !== undefined && element !== 0 && userNames.push(element);
        });
      });

    this.calenderForm.value.txtRole !== "" &&
      this.calenderForm.value.txtRole !== undefined &&
      this.calenderForm.value.txtRole !== null &&
      [this.calenderForm.value.txtRole].length !== 0 &&
      [this.calenderForm.value.txtRole].forEach(val => {
        val?.forEach(element => {
          element !== undefined && element !== 0 && roleNames.push(element);
        });
      });

    this.userData = [];
    this.userService.getUsersListByLstRolesAndLstUsersAndTenantId(roleNames, userNames, this.tenantId).subscribe(response => {
      response?.forEach(element => {

        this.userData.push({
          OwnerText: element?.userName,
          email: element?.email,
          role: element?.roleName,
          Id: element?.id,
        })
      });
      this.roomDataSource = this.userData;
    })


    // this.eventDatas = user;
    this.getTableData();

    return this.userData
  }


  toggleAllUsersSelection() {

    if (this.allUsersSelected.selected) {
      this.userSelect.options.forEach((item: MatOption) => item.select());
    }
    else {
      this.userSelect.options.forEach((item: MatOption) => item.deselect());
    }
  }

  toggleAllRolesSelection() {
    if (this.allRolesSelected.selected) {
      this.roleSelect.options.forEach((item: MatOption) => item.select());


    }
    else {
      this.roleSelect.options.forEach((item: MatOption) => item.deselect());
    }
  }

  // toggleAllRoleSelection(){
  //   this.userData = [];
  //   this.userService.getUsersListByLstRolesAndLstUsersAndTenantId(role,[],this.tenantId).subscribe(response => {
  //     response?.forEach(element => {
  //        this.userData.push({
  //       OwnerText: element?.userName,
  //       email: element?.email,
  //       role: element?.roleName,
  //       Id: element?.id,
  //     })

  //     });
  //     this.roomDataSource = this.userData;
  //   })


  //   this.eventDatas = user;
  //   this.getTableData();

  //   return this.userData
  // }

  //! calender table datas list as a view page
  public getTableData(): Record<string, any>[] {
    let user: any[] = [];
    const eventData: Record<string, any>[] = [];
    this.userId = localStorage.getItem("userId") ?? "";
    this.roleId = localStorage.getItem("roleId") ?? "";
    this.roleName = localStorage.getItem("roleName") ?? "";
    this.calenderService.getList(new PagedAndSortedResultRequestDto).subscribe(response => {
      // eventData=[];
      response && response?.items?.forEach(elements => {
        elements?.calendarDetails?.forEach(element => {
          eventData.push({
            EndTime: new Date(element?.toTime),
            StartTime: new Date(element?.fromTime),
            Subject: element?.title,
            Id: element?.calendarId,
            QGuid: elements?.id,
            Guid: element?.guid,
            Description: element?.description,
            StartTimeZone: element?.startTimeZone,
            EndTimeZone: element?.endTimeZone,
            // IsAllDay: true,
            Location: element?.location,
            RecurrenceRule: element?.recurrenceRule,
            userName: elements?.userName,
            OwnerId: elements?.assignedToId,
            colorCode: element?.colorCode,
            Patient: element?.patientFullName,
            userId: elements?.userId,
            roleId: elements?.roleId,
            calendarDetailsId: element?.calendarDetailsId,
            status: element?.status,
            roleName: elements?.roleName,
            isDragAndDrop: true,
            // RecurrenceRule: "FREQ=WEEKLY;BYDAY=FR;INTERVAL=1;",
            // RecurrenceException: "20201106T130000Z"
          });
        })

      })

      this.scheduleObj?.refreshEvents();
      // this.scheduleObj.dataModule.dataManager.dataSource.json =  this.calenderList;
    })
    // this.scheduleObj.addEvent(eventData);

    return eventData;
  }
  //! reschedule color
  applyCategoryColor(args: EventRenderedArgs): void {
    let colorCode: string = args.data.colorCode as string;
    args.element.style.backgroundColor = colorCode;
    if (!args.element || !colorCode) {
      return;
    }
    if (this.scheduleObj.currentView === 'Agenda') {
      (args.element.firstChild as HTMLElement).style.borderLeftColor = colorCode;
    } else {
      args.element.style.backgroundColor = colorCode;
    }
  }


  //// get patient list
  patientDet: any[] = [];
  getPatient() {
    ///patient link dropdown
    const patientSearchSub$ =
      of(null).pipe(
        switchMap(x => this.PatientService.getPatientForCalender()))
        .subscribe(response => {
          this.patientDet = [];
          response?.forEach(e => {
            this.patientDet.push({
              name: e?.patientName,
              id: e?.patientId,
              defaultPatientId: e?.defaultPatientId
            })
          })
        });

    this.subscription$.push(patientSearchSub$);
  }


  //! popup for calender
  startEnd: string = "";
  calenderId: string = "";
  calenderDetailId: string = "";
  selectedUserId: string = '';
  onPopupOpen(args) {

    this.selectedUserId = args?.data?.OwnerId
    this.eventSettings = {
      dataSource: this.getTableData()

    };


    if (args?.type === 'Editor') {

      this.getPatient();
      // Create required custom elements in initial time
      if (!args.element.querySelector('.custom-field-row')) {
        let row: HTMLElement = createElement('div', { className: 'custom-field-row' });
        let formElement: HTMLElement = args.element.querySelector('.e-schedule-form');
        formElement.firstChild.insertBefore(row, args.element.querySelector('.e-title-location-row'));
        let container: HTMLElement = createElement('div', { className: 'custom-field-container' });
        let inputEle: HTMLInputElement = createElement('input', {
          className: 'e-field', attrs: { name: 'Patient' }
        }) as HTMLInputElement;
        container.appendChild(inputEle);
        row.appendChild(container);
        let dropDownList: DropDownList = new DropDownList({
          dataSource: this.patientDet,


          fields: { text: 'name', value: 'name' },
          value: (<{ [key: string]: Object }>(args.data)).Patient as string,
          floatLabelType: 'Always', placeholder: 'Patient'
        });
        dropDownList.appendTo(inputEle);
        inputEle.setAttribute('name', 'Patient');
      }


    }

    this.startEnd = args.data.EndTime;
    this.calenderDetailId = args.data.calendarDetailsId;
    if (args?.data?.EndTime < new Date() && (args?.data?.StartTime !== "" && args?.data?.Subject == undefined &&
      args?.data?.startTime !== "" && args?.data?.EndTime !== "")) {
      if (args.type === 'QuickInfo' || args.type === 'Editor') {
        args.cancel = true;

      }
    }

    ////buttondisable based on past date and status
    if (args?.data?.EndTime < new Date() && (args?.data?.OwnerId != "Scheduled")) {

      var buttonElement = args?.type === "QuickInfo" ? ".e-event-popup .e-save" : ".e-schedule-dialog .e-event-save";
      var saveButton = document.querySelector(buttonElement);
      if (saveButton && (saveButton as EJ2Instance)?.ej2_instances) {
        ((saveButton as EJ2Instance)?.ej2_instances[0] as Button).disabled = true;
      }

      var buttonElement = args.type === "QuickInfo" ? ".e-event-popup .e-delete" : ".e-schedule-dialog .e-event-delete";
      var deleteButton = document.querySelector(buttonElement);
      if (deleteButton && (deleteButton as EJ2Instance)?.ej2_instances) {
        ((deleteButton as EJ2Instance)?.ej2_instances[0] as Button).disabled = true;
      }

      // if (args.type === 'Editor' || args.type === 'QuickInfo') {
      //   args.cancel = true;
      // }
    }
    if (args?.data?.EndTime > new Date() && args?.data?.OwnerId === "Rescheduled") {

      var buttonElement = args.type === "QuickInfo" ? ".e-event-popup .e-save" : ".e-schedule-dialog .e-event-save";
      var saveButton = document.querySelector(buttonElement);
      if (saveButton && (saveButton as EJ2Instance).ej2_instances) {
        ((saveButton as EJ2Instance).ej2_instances[0] as Button).disabled = true;
      }

      var buttonElement = args.type === "QuickInfo" ? ".e-event-popup .e-delete" : ".e-schedule-dialog .e-event-delete";
      var deleteButton = document.querySelector(buttonElement);
      if (deleteButton && (deleteButton as EJ2Instance).ej2_instances) {
        ((deleteButton as EJ2Instance).ej2_instances[0] as Button).disabled = true;
      }

      // if (args.type === 'Editor' || args.type === 'QuickInfo') {
      //   args.cancel = true;
      // }
    }

    if (args?.data?.QGuid !== "") {
      this.QGuid = args?.data?.QGuid
      this.calenderId = args?.data?.calendarDetailsId
    }
  }
  toggle: boolean = false;


  /// initial call edit popup
  public showQuickInfo: Boolean = false;
  onCellClick(args: CellClickEventArgs): void {
    this.scheduleObj.openEditor(args, 'Add');
  }
  onEventClick(args: EventClickArgs): void {
    if (!(args.event as any).RecurrenceRule) {
      this.scheduleObj.openEditor(args.event, 'Save');
    }
    else {
      this.scheduleObj.quickPopup.openRecurrenceAlert();
    }
  }

  //! calenders action-save,view, edit, navigation, etc.,
  onActionComplete(args: ActionEventArgs): void {


    //! for save the schedule event
    if (args?.requestType === 'eventCreated') {
      let patient = this.patientDet?.filter(value => {
        return value && value?.name === args?.addedRecords[0]?.Patient
      })[0];

      let users = this.userTableData?.filter(value => {
        return value && value?.Id === args?.addedRecords[0]?.OwnerId
      })[0];
      let calender: CreateUpdateCalendarDTO = {
        // userId: this.userId,
        // userName: this.userName,

        roleId: this.roleId,
        roleName: this.roleName,
        assignedToId: args?.addedRecords[0]?.OwnerId,
        assignedToName: users.OwnerText,
        calendarDetails: [{
          setUpInHome:false,
        userID: '',
        // tech : 'Mani',
        // roleId: args?.changedRecords?.[0]?.roleId,
        assignedByName : '',
        status: args?.changedRecords?.[0]?.OwnerId,
        description: args?.changedRecords?.[0]?.Description,
        startTimeZone: args?.changedRecords?.[0]?.StartTimezone,
        endTimeZone: args?.changedRecords?.[0]?.EndTimezone,
        // isAllDay: args?.changedRecords?.[0]?.IsAllDay,
        // location: args?.changedRecords?.[0]?.Location,
        // recurrenceRule: args?.changedRecords?.[0]?.RecurrenceRule,
        calendarId: args?.changedRecords?.[0]?.Id,
        title: args?.changedRecords?.[0]?.Subject,
        fromTime: args?.changedRecords?.[0]?.StartTime,
        toTime: args?.changedRecords?.[0]?.EndTime,
        // guid: args?.changedRecords?.[0]?.Guid,
        colorCode: '',
        // createdDate: "",
        calendarDetailsId: this.calenderDetailId,
        // isDragAndDrop: false
        patientId : ''

        }]
      }
      this.calenderService.create(calender).subscribe(response => {
        this.toggle = !this.toggle;
        // let currentUrl = this.router.url;
        // this.router.routeReuseStrategy.shouldReuseRoute = () => false;
        // this.router.onSameUrlNavigation = 'reload';
        // this.router.navigate([currentUrl]);
        this.eventSettings = {
          dataSource: this.getTableData()
        };
        this.getTableData();
      }, err => {
        args.cancel = false;
        this.eventSettings = {
          dataSource: this.getTableData()
        };
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      })
    }


    //!/ for update the schedule event
    if (args?.requestType === 'eventChanged' && args?.changedRecords?.[0]?.OwnerId !== "Rescheduled") {
      let patient = this.patientDet?.filter(value => {
        return value && value?.name === args?.changedRecords?.[0]?.Patient
      })[0];
      let users = this.userTableData?.filter(value => {
        return value && value?.Id === args?.changedRecords?.[0]?.OwnerId
      })[0];
      let calender: CreateUpdateCalendarDTO = {

        // userId: args?.changedRecords?.[0]?.userId,
        // userName: args?.changedRecords?.[0]?.userName,
        roleId: args?.changedRecords?.[0]?.roleId,
        roleName: args?.changedRecords?.[0]?.roleName,
        assignedToId: args?.changedRecords?.[0]?.OwnerId,
        assignedToName: users.OwnerText,
        calendarDetails: [{
          setUpInHome:false,
        userID: '',
        // tech : 'Mani',
        // roleId: args?.changedRecords?.[0]?.roleId,
        assignedByName : '',
        status: args?.changedRecords?.[0]?.OwnerId,
        description: args?.changedRecords?.[0]?.Description,
        startTimeZone: args?.changedRecords?.[0]?.StartTimezone,
        endTimeZone: args?.changedRecords?.[0]?.EndTimezone,
        // isAllDay: args?.changedRecords?.[0]?.IsAllDay,
        // location: args?.changedRecords?.[0]?.Location,
        // recurrenceRule: args?.changedRecords?.[0]?.RecurrenceRule,
        calendarId: args?.changedRecords?.[0]?.Id,
        title: args?.changedRecords?.[0]?.Subject,
        fromTime: args?.changedRecords?.[0]?.StartTime,
        toTime: args?.changedRecords?.[0]?.EndTime,
        // guid: args?.changedRecords?.[0]?.Guid,
        colorCode: '',
        // createdDate: "",
        calendarDetailsId: this.calenderDetailId,
        // isDragAndDrop: false
        patientId : ''

        }]
      }
      this.calenderService.update(this.QGuid, calender).subscribe(response => {
        this.eventSettings = {
          dataSource: this.getTableData()

        };
        this.getTableData();
      }, err => {
        args.cancel = false;
        this.eventSettings = {
          dataSource: this.getTableData()

        };
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      })

    }


    ///// button disable for statrt time is greater than end time
    if (this.startEnd > args?.changedRecords?.[0]?.StartTime) {
      var buttonElement = args.requestType === "eventChanged" ? ".e-event-popup .e-save" : ".e-schedule-dialog .e-event-save";
      var saveButton = document.querySelector(buttonElement);
      if (saveButton && (saveButton as EJ2Instance).ej2_instances) {
        ((saveButton as EJ2Instance).ej2_instances[0] as Button).disabled = true;
      }
    }



    //// update function for reschedule option
    if (args?.changedRecords?.[0]?.OwnerId === "Rescheduled") {

      // if (this.startEnd > args?.changedRecords?.[0]?.StartTime) {
      //   this.scheduleObj.uiStateValues.isBlock = true;

      // }

      let calender: CreateUpdateCalendarDetailsDTO
      calender = {
        setUpInHome:false,
        userID: '',
        // tech : 'Mani',
        // roleId: args?.changedRecords?.[0]?.roleId,
        assignedByName : '',
        status: args?.changedRecords?.[0]?.OwnerId,
        description: args?.changedRecords?.[0]?.Description,
        startTimeZone: args?.changedRecords?.[0]?.StartTimezone,
        endTimeZone: args?.changedRecords?.[0]?.EndTimezone,
        // isAllDay: args?.changedRecords?.[0]?.IsAllDay,
        // location: args?.changedRecords?.[0]?.Location,
        // recurrenceRule: args?.changedRecords?.[0]?.RecurrenceRule,
        calendarId: args?.changedRecords?.[0]?.Id,
        title: args?.changedRecords?.[0]?.Subject,
        fromTime: args?.changedRecords?.[0]?.StartTime,
        toTime: args?.changedRecords?.[0]?.EndTime,
        // guid: args?.changedRecords?.[0]?.Guid,
        colorCode: '',
        // createdDate: "",
        calendarDetailsId: this.calenderDetailId,
        // isDragAndDrop: false
        patientId : ''


      };

      this.calenderService.updateRescheduleByIdAndInput(this.QGuid, calender).subscribe(response => {
        this.eventSettings = {
          dataSource: this.getTableData()

        };
        this.getTableData();
      }, err => {
        this.eventSettings = {
          dataSource: this.getTableData()

        };
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      })
    }

    /// delete function for removed
    if (args?.requestType === 'eventRemoved') {
      this.calenderService.deleteCalendarByIdAndCalendarDetailsId(this.QGuid, this.calenderId).subscribe(response => {

      })
    }

    //! switch case events log
    switch (args?.requestType) {
      case "viewNavigate":
      case "dateNavigate":
        this.scheduleObj?.refresh();
        break;
      case "toolBarItemRendered":

        // this.getTableData()
        // args?.requestType = "viewNavigate"
        break;
      default:
    }


  }
}
