import { filter } from 'rxjs/operators';
import {
  Component,
  ElementRef,
  HostListener,
  Input,
  OnInit,
  QueryList,
  Renderer2,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DataTableDirective } from 'angular-datatables';
import { requisitionItemsData } from 'projects/admin/src/app/admin-dashboard/dashboard-data';
import { Subject, Subscription } from 'rxjs';
import { RequisitionService } from '../item-proxy/item-management/optimization/requisition.service';
import { ReceiveRequitioDTO } from '../item-proxy/item-management/optimization/dto';
import { RequisitionListComponent } from '../requisition-list/requisition-list.component';
import { CommunicationService } from 'projects/shared/src/app/services/communication.service';
import { Title } from '@angular/platform-browser';
import { RequisitionStatusShortCode } from 'projects/shared/src/app/data/common-data';
import { defaultGuid } from 'projects/shared/src/app/enums/allenums.enum';
import { HttpErrorResponse } from '@angular/common/http';
import Swal from 'sweetalert2';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from 'projects/shared/src/app/services/common.service';

@Component({
  selector: 'app-requisition-items-list',
  templateUrl: './requisition-items-list.component.html',
  styleUrls: ['./requisition-items-list.component.scss'],
})
export class RequisitionItemsListComponent implements OnInit {
  RequisitionItemsForm: FormGroup;
  @Input() requisitionItemsList: any[];
  @Input() reqStatusShortCodeId: string;
  reqStatusShortCodeIds = RequisitionStatusShortCode;
  requisitionItemsListOptions: any = {
    responsive: true,
    paging: false,
    scrollX: true,
    scrollCollapse: true,
    scrollY: '300px',
    language: {
      emptyTable: 'No records',
      info: 'Total : _MAX_ records',
      infoEmpty: 'Total : _MAX_ records',
      infoFiltered: '(filtered : _TOTAL_ records)',
      loadingRecords: 'Loading...',
      zeroRecords: 'No matching records',
    },
    columnDefs: [
      { targets: [0], orderable: false },
      { targets: [0, 1, 2, 3, -1], className: 'dt-fixed-column' },
    ],
    order: [],
  };
  editableRowIndex: number | null = null;
  subscription$: Subscription[] = [];
  receivedQtyNumber: number=null;
  isUpdate:boolean = false;
  constructor(
    private fb: FormBuilder,
    private title: Title,
    private toastr: ToastrService,
    private requisitionService: RequisitionService,
    private communicationService: CommunicationService,
    private commonService: CommonService
  ) {}

  ngOnInit() {
    this.title.setTitle('Qsecure | Requisition List');
    this.requisitionItemsList = this.requisitionItemsList?.map((item) => ({
      ...item,
      txtSerialNo: item.serialLotNumber,
      txtReceivedQty: item.receivedQuantity.toString()==='0'?'1':item.receivedQuantity.toString(),
      txtTrackingNumber: item.tracking,
      processedDate: this.commonService.getFormattedDateTimeZone(
        item.processedDate
      ),
    }));
  }

  ngAfterViewInit() {}

  // On Page Destroy
  ngOnDestroy(): void {
    this.subscription$?.forEach((sub) => {
      sub && sub?.unsubscribe();
    });
  }
  cancel(): void {
    this.editableRowIndex = null;
  }

  toggleEdit(rowIndex: number, data: any): void {
    if (this.editableRowIndex === rowIndex) {
      // Save changes

      this.updateReceivedItemsData(data);

      this.editableRowIndex = null;
    } else {
      // Start editing
      this.editableRowIndex = rowIndex;
    }
  }

  //Update Received Items Data
  updateReceivedItemsData(input: any) {
    this.isUpdate = true;
    let inputData: ReceiveRequitioDTO = {
      productId: input?.productId,
      reqId: input?.reqId,
      serialLotNumber: input?.txtSerialNo,
      receivedQuantity: input?.txtReceivedQty,
      thisOrder: 0,
      trackingNumber: input?.txtTrackingNumber,
      requisitionItemId: input?.requestItemId,
    };
    let itemList: ReceiveRequitioDTO[] = [];
    itemList.push(inputData);

    this.requisitionService
      .saveInventoryReceivedQtyV1ByInput(inputData)
      .subscribe(
        (response) => {
          let updatedIndex = 0;
          response?.lstReqItemDetails.forEach((element) => {
            if (element.requestItemId == input.requestItemId) {
              updatedIndex = this.requisitionItemsList.findIndex(
                (obj) => obj.requestItemId === element.requestItemId
              );
              if (updatedIndex !== -1) {
                this.requisitionItemsList[updatedIndex] = element; //Replace the Object
              }
            } else {
              const newIndex = updatedIndex + 1;
              this.requisitionItemsList.splice(newIndex, 0, element);
              this.toastr.success('Received Qty updated in the stock successfully ','success');
            }
          });
          let params = {
            data: this.requisitionItemsList,
            flag: !response?.isCompleted,
          };

          this.communicationService.triggerRequisitionList(params);

          response?.isCompleted &&
            this.communicationService.triggerRequisitionList(params) ;
          setTimeout(() => {
            this.isUpdate = false;
          }, 100);
        },
        (err) => {
          this.isUpdate = false;
          const data: HttpErrorResponse = err;
          this.toastr.error(data?.error?.error?.message);
        }
      );
  }

  //Allow only number inputs
  onKeyPress(event: KeyboardEvent) {
    const isNumber = /[0-9]/.test(event.key);
    if (!isNumber) {
      event.preventDefault(); // Prevent the input if the key is not a number
    }
  }

  //Validate Requisition Item
  isSaveButtonEnabled(requisitionItemId: string): boolean {
    // Check both form validity and items list condition
    return this.requisitionItemsList
      .filter((a) => a.requestItemId === requisitionItemId)
      .every(
        (item) =>
          item.txtSerialNo.trim() === '' ||
          item.txtSerialNo === null ||
          item.txtReceivedQty === '0' ||
          item.txtReceivedQty === null ||
          item.txtReceivedQty === '' ||
          item.txtTrackingNumber === '' ||
          item.txtTrackingNumber === null ||
          item.txtReceivedQty > item.balanceQuantity
      );
  }
  //New Validation
  isSaveButtonEnabledV2(requisitionItemId: string): boolean {
    // Check both form validity and items list condition
    // return this.requisitionItemsList
    //  .filter((a) => a.requestItemId === requisitionItemId)
    // .every(
    //   (item) =>
    //     item.txtSerialNo === '' ||
    //     item.txtSerialNo === null ||
    //     item.txtReceivedQty === '0' ||
    //     item.txtReceivedQty === null ||
    //     item.txtReceivedQty === '' ||
    //     item.txtTrackingNumber === '' ||
    //     item.txtTrackingNumber === null ||
    //     item.txtReceivedQty > item.balanceQuantity
    // );

    const requisitionItem = this.requisitionItemsList.find(
      (a) => a.requestItemId === requisitionItemId
    );
    const receivedQty =
      this.requisitionItemsList.find(
        (a) => a.requestItemId === requisitionItemId
      ).txtReceivedQty ??'0';
      this.receivedQtyNumber=+receivedQty;

    if (receivedQty > 0) {
      return this.requisitionItemsList
        .filter((a) => a.requestItemId === requisitionItemId)
        .every(
          (item) =>
            (item.txtSerialNo).trim() === '' ||
            item.txtSerialNo === null ||
            item.txtTrackingNumber === '' ||
            item.txtTrackingNumber === null ||
            item.txtReceivedQty==='0'||
            item.txtReceivedQty > item.balanceQuantity
        );
    } else if (
      ((requisitionItem.txtSerialNo).trim() === '' ||
        requisitionItem.txtSerialNo === null) &&
      (requisitionItem.txtTrackingNumber === '' ||
        requisitionItem.txtTrackingNumber === null) &&
      (receivedQty === 0 || receivedQty === '')
    ) {
      return true;
    } else {
      return false;
    }
  }
  scrollPosition: any;
  onScroll(event: any) {
    this.scrollPosition = event.target.scrollTop;
  }
}
