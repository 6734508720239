import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, FormGroupName, Validators } from '@angular/forms';
import { Observable, Subscription, forkJoin, of } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { PatientPersonalService } from '../patient-proxy/patient-optimization';
import { defaultGuid } from '../medicare-check-list/medicare-check-list.component';
import { InboundDocumentService } from 'projects/admin/src/app/admin-proxy/platform-app-management/rcm/platform-management/inbound';
import { ToastrService } from 'ngx-toastr';
import { PatientMasterDropdownDTO, PayorLevelDTO } from '../dropdown-proxy/dropdown-management/dropdowns/dto/patient';
import Swal from 'sweetalert2';
import { HttpErrorResponse } from '@angular/common/http';
import { OrderMasterDropdownService, PatientMasterDropdownService } from '../dropdown-proxy/dropdown-management/dropdowns/master-dropdown-service';
import { OrderDropdowns, PatientDropdowns } from '../dropdown-proxy/dropdown-management/dropdowns';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { PatientNewVerificationDTO } from '../patient-proxy/patient-optimization/dto';
import { OrderMasterDropdownDTO, YearTypeDTO } from '../dropdown-proxy/dropdown-management/dropdowns/dto/order';
import { PatientAddPolicyTabComponent } from '../patient-add-policy-tab/patient-add-policy-tab.component';
import { noWhitespaceValidator } from '../add-note-tab/add-note-tab.component';

@Component({
  selector: 'app-newVerification',
  templateUrl: './newVerification.component.html',
  styleUrls: ['./newVerification.component.scss']
})
export class NewVerificationComponent implements OnInit {
  PatientForm :FormGroup;
  PlanForm:FormGroup;
  formBuilder: any;
  BirthdateMinDate: Date;
  drpYearType: YearTypeDTO[] = [];
  BirthdateMaxDate:Date;
  payorLevel: PayorLevelDTO[] = [];
  defaultGuid = defaultGuid;
  isGroupNoNull:boolean;
  notesText = "";
  filteredPatients: Observable<{ guid: string; idAndNameAndDOB: string }[]> =
  of([]);
  @Output() patientIdEmitters: EventEmitter<string> =
    new EventEmitter<string>();
  lstAllPatientIds: { guid: string; idAndNameAndDOB: string }[] = [];
  subscription$: Subscription[] = [];
  patientId: string=defaultGuid;
  policyId: any;
  constructor(private fb: FormBuilder,
    private patientPersonalService: PatientPersonalService,
    private inboundDocumentService: InboundDocumentService,
    private patientDropdownService: PatientMasterDropdownService,
    private orderDropdownService: OrderMasterDropdownService,
    private toastr: ToastrService,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      patientId:string
      isOnline:boolean
    }={patientId:defaultGuid,isOnline:false},) { }

  ngOnInit() {
    this.initializeForms();
    this.loadPatient();
    this.loadValidDates();
    this.loadPatientForm();
  }
  initializeForms(){
    this.PatientForm = this.fb.group({
      txtChartNo: new FormControl(''),
      drpSearchPatient: new FormControl(''),
      txtPatientFilter:new FormControl(''),
      txtPatientName:new FormControl(''),
      txtAddress: new FormControl(''),
      txtDob:new FormControl(''),
      txtComment: new FormControl(''),
    });
    this.PlanForm = this.fb.group({
      txtPlan: new FormControl('',[Validators.required,noWhitespaceValidator,]),
      txtPlanName: new FormControl('',[Validators.required,noWhitespaceValidator,]),
      txtPlanPhone: new FormControl(''),
      txtPolicy: new FormControl('',[Validators.required,noWhitespaceValidator,]),
      txtGroup: new FormControl('',[Validators.required,noWhitespaceValidator,]),
      txtEffective: new FormControl(''),
      txtType: new FormControl(''),
      txtstart:new FormControl(''),
      txtEnd: new FormControl(''),
      chkSkipVerification:new FormControl(false),
    });
  }
    resetPlan(){
      this.PlanForm.patchValue({
        txtPlanName: '',
        txtPlanPhone: '',
        txtPolicy: '',
        txtGroup: '',
        txtEffective: '',
        txtType: '',
        txtstart:'',
        txtEnd: '',
        chkSkipVerification:false,
      });
      // this.PlanForm.reset();
    }

  loadPatient(){
    const searchPatientSub = this.patientPersonalService
    .patientSearchBySDefaultPatientIdAndSPatientNameAndDtDOBAndSSSNAndSEmailIdAndSMobileAndSStatus(
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      1
    )
    .subscribe((response) => {
      this.lstAllPatientIds = response.map((x) => {
        const dateOptions: Intl.DateTimeFormatOptions = {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
        };
        //date in MM/DD/YYYY FORMAT
        const dob =
          (x?.dob ?? '') === ''
            ? ''
            : new Date(x?.dob)?.toLocaleDateString('en-US', dateOptions) ??
              '';
        let y: { guid: string; idAndNameAndDOB: string } = {
          guid: x?.patientId,
          idAndNameAndDOB:
            x?.chartId + ' - ' + x?.patientName + ' - ' + dob,
          ...x,
        };
        return y;
      });

      this.filteredPatients = this.PatientForm
        ?.get('txtPatientFilter')
        ?.valueChanges?.pipe(
          startWith(''),
          map((value) =>
            this.lstAllPatientIds.filter((option) =>
              option?.idAndNameAndDOB
                ?.toLowerCase()
                ?.includes(value?.toLowerCase() ?? '')
            )
          )
        );
    });
   this.subscription$.push(searchPatientSub);
      const patientDropdownInput: PatientDropdowns[] = [
        PatientDropdowns.PayorLevels,
        PatientDropdowns.RelationShipWithSelf,
        PatientDropdowns.ClaimFillingCodes,
        PatientDropdowns.Genders,
        PatientDropdowns.InsuranceTypes,
        PatientDropdowns.Assignments,
      ];
      const patientDropdownSubscription = this.patientDropdownService
      .getPatientDropdownsByInput(patientDropdownInput)
      .subscribe(
        (stateResponse) => {
          const response: PatientMasterDropdownDTO = stateResponse;
          this.payorLevel = response?.payorLevels ?? [];
        },
        (err) => {
          const data: HttpErrorResponse = err;
          Swal.fire({
            icon: 'info',
            text: data?.error?.error?.message,
          });
        }
      );
    this.subscription$.push(patientDropdownSubscription);
    const orderDropdownInput: OrderDropdowns[] = [
      OrderDropdowns.Charts,
      OrderDropdowns.TypeOfPlans,
      OrderDropdowns.PlanTypes,
      OrderDropdowns.PayablePercents,
      OrderDropdowns.YearTypes,
    ];
    const orderDropdown =
      this.orderDropdownService.getOrderDropdownsByInput(orderDropdownInput);
    const patietDropdown =
      this.patientDropdownService.getPatientDropdownsByInput(
        patientDropdownInput
      );

      const combinedObs = forkJoin([
        orderDropdown,
        patietDropdown,
      ]);
      combinedObs.subscribe(
      (response) => {
        const responseData: OrderMasterDropdownDTO = response?.[0];
        this.drpYearType = responseData?.yearTypes;
      });
  }
  //Load Patient Forms
  loadPatientForm() {
    if(this.patientId===defaultGuid){
      this.patientId = this.data.patientId;
    }else{
     this.patientId = this.PatientForm.value.drpSearchPatient;
    }
      const getPatientDetails = this.inboundDocumentService
        .getPatientDetail(this.patientId)
        .subscribe(
          (response) => {

            this.PatientForm.patchValue({
              drpSearchPatient: this.patientId,
              txtChartNo: response?.chartId,
              txtPatientName: response?.firstName+' '+response?.middleName+' '+response?.lastName,
              txtDob: response?.dob,
              txtAddress:response?.address,
              txtComment:''
            });
            this.resetPlan();
            this.PlanForm.patchValue({
              txtPlan:''
            });
            this.PatientForm.patchValue({
              txtComment:''
            });
          },
          (err) => {
            this.PatientForm.reset();
            this.toastr.error('Something Went Wrong!');
          }
        );
      this.subscription$.push(getPatientDetails);

    }
    loadValidDates() {
      this.BirthdateMaxDate = new Date();
      this.BirthdateMaxDate.setDate(this.BirthdateMaxDate.getDate());
      this.BirthdateMinDate = new Date();
      this.BirthdateMinDate.setFullYear(
        this.BirthdateMinDate.getFullYear() - 120
      );
      var oneYearAgo = new Date();
      oneYearAgo.setFullYear(this.BirthdateMaxDate.getFullYear() - 1);
    }
    loadPlanForm(){
     this.resetPlan();
      const getPlanDetails = this.patientPersonalService
        .getPatientPlanDetail(this.patientId,this.PlanForm.value.txtPlan)
        .subscribe(
          (response) => {
            this.PlanForm.patchValue({
              txtPlan:this.PlanForm.value.txtPlan,
              txtPlanName: response?.payorId+'/'+response?.payorName??'' ,
              txtPolicy: response?.policyNo??'',
              txtGroup:  response?.groupNo??null,
              chkSkipVerification:response?.isSkip??false,
            });
            if(this.PlanForm.value.txtGroup===null){
             this.isGroupNoNull=true;
            }else{
              this.isGroupNoNull=false;
            }
            this.policyId=response?.policyId;
          },
          (err) => {
            const data: HttpErrorResponse = err;
            Swal.fire({
              icon: 'info',
              text: data?.error?.error?.message,
            });
          },
        );
      this.subscription$.push(getPlanDetails);
    }
    saveVerification(method:number){
      const plan = this.PlanForm.value;
      const patient=this.PatientForm.value;
      let inputVerification: PatientNewVerificationDTO;
      inputVerification={
        patientId: this.patientId,
        policyId: this.policyId,
        method: method,
        // status:
        policyNo:plan.txtPolicy ,
        groupNo:plan.groupNo  ,
        plan:plan.txtPlan ,
        notes:patient.txtComment,
        skipPrevious:plan.chkSkipVerification,
      }
      this.patientPersonalService
      .patientNewVerificationSave(inputVerification)
      .subscribe(
        (response) => {
          this.toastr.success('Save Successfully', 'Success');
          this.dialog.closeAll();
        },
        (err) => {
          if(err.status===200){
            this.toastr.success('Save Successfully', 'Success');
            this.dialog.closeAll();
          }else{
          const data: HttpErrorResponse = err;
          Swal.fire({
            icon: 'info',
            text: data?.error?.error?.message,
          });
         }
        }
      );
    }
     //To View The Policy
  EditPolicy(policy: any) {

    const dialogRef = this.dialog.open(PatientAddPolicyTabComponent, {
      data: {
        patientId: policy.patientId,
        policyId: defaultGuid,
        documentId: policy.docId,
        wipId: policy.wipId ?? defaultGuid,
        isNewVerify:true
        // selectedNode: this.selectedNode,
      },
    });
    const aftClosed = dialogRef.afterClosed().subscribe(
      (result) => {
        this.patientIdEmitters.next(policy.patientId);
          this.loadPlanForm();
        // policy.patientId !== defaultGuid && this.getTableData();
      },
      (err) => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      }
    );
    this.subscription$.push(aftClosed);
  }
   //Update Character Count for TextArea
   updateCharacterCount(maxlength: number) {
    // Ensure the text length does not exceed the maximum length
    if (this.notesText?.length > maxlength) {
      this.notesText = this.notesText?.substr(0, maxlength);
    }
  }
}

