import { PagedAndSortedResultRequestDto } from '@abp/ng.core';
import { MapsAPILoader } from '@agm/core';
import { DatePipe } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, OnInit, ViewChild, ChangeDetectorRef  } from '@angular/core';
import {
  AbstractControl,
  AsyncValidatorFn,
  FormBuilder,
  FormControl,
  FormGroup,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { MatSort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { OntimeUserWorkHoursComponent } from 'projects/on-time-schedules/src/app/ontime-user-work-hours/ontime-user-work-hours.component';
import {
  RegionCitiesDTO,
  RegionCountriesDTO,
  RegionStatesDTO,
} from 'projects/patient/src/app/dropdown-proxy/dropdown-management/dropdowns/dto/models';
import { RegionDropdownService } from 'projects/patient/src/app/dropdown-proxy/dropdown-management/dropdowns/region-dropdown-service';
import {
  defaultCountry,
  LogoMaxandTypeLimits,
} from 'projects/shared/src/app/enums/allenums.enum';
import { TableService } from 'projects/shared/src/app/table.service';
import { DateValidator } from 'projects/shared/src/app/validations/date-validator';
import { Observable, Subscription } from 'rxjs';
import { debounceTime, map, startWith, tap } from 'rxjs/operators';
import Swal from 'sweetalert2';
import emailMask from 'text-mask-addons/dist/emailMask';

import { MouseEvent } from '@agm/core';
import { OrganizationUnitDTO } from '../admin-proxy/platform-app-management/rcm/platform-management/organization-units/dto/models';
import { OrganizationUnitService } from '../admin-proxy/platform-app-management/rcm/platform-management/organization-units/organization-unit.service';
import { GetRoleListUsingTenantIdDto } from '../admin-proxy/platform-app-management/rcm/platform-management/role/models';
import { RoleService } from '../admin-proxy/platform-app-management/rcm/platform-management/role/role.service';
import { TenantsService } from '../admin-proxy/platform-app-management/rcm/platform-management/tenant/tenants.service';
import {
  IdentityUserCreateUsingTenantIdDto,
  IdentityUserUsingTenantIdDto,
} from '../admin-proxy/platform-app-management/rcm/platform-management/user/models';
import { UserService } from '../admin-proxy/platform-app-management/rcm/platform-management/user/user.service';
import { IdentityUserService } from '../admin-proxy/platform-app-management/volo/abp/identity/identity-user.service';
import {
  SearchUserDTO,
  UsernameEmailValidationDto,
} from '../admin-proxy/platform-app-management/rcm/platform-management/users/dto';
import {
  IdentityRoleDto,
  IdentityUserUpdateDto,
} from '../admin-proxy/platform-app-management/volo/abp/identity/models';
import { PasswordValidation } from '../confirmedvalidator';
import { ToastrService } from 'ngx-toastr';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { Role } from '../admin-proxy/platform-app-management/rcm/platform-management';
import { RoleDto } from '../admin-proxy/platform-app-management/identity';
import { MatSelect } from '@angular/material/select';
import { MatOption } from '@angular/material/core';

const defaultUrl = '../../../assets/images/user.png';

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss'],
  providers: [DatePipe],
})
export class UserComponent implements OnInit {
  agmmap: google.maps.Map;
  hide = true;
  isLoading: boolean = true;
  isShowSpinner: boolean = false;
  userForm: FormGroup;
  userForm2: FormGroup;
  roleForm: FormGroup;
  UserFilterForm: FormGroup;
  panelOpenState = false;
  userTab:boolean=true;
  @Input() clientId: string = '';
  drpOrganization: OrganizationUnitDTO[] = [];
  // drpRoles: IdentityRoleDto[] = [];
  drpRoles: RoleDto[] = [];
  userId: string = '';
  concurrencyStamp: string = '';
  userTableData: any[] = [];
  emailMask: any;
  step: boolean = false;
  branch: string;
  role: string;
  isShownSaveButton: boolean = true;
  isShownUsersTable: boolean = true;
  showErrorForFileType: boolean = false;
  showErrorBasedOnSize: boolean = false;
  fileAs64Value: string = defaultUrl;
  saveButtonHide: boolean;
  displayedColumns: string[] = [
    'Options',
    'userName',
    'email',
    'branch',
    'phoneNumber',
    'roleName',
    'createdDate',
    'Status',

  ];
  mapHide: boolean = false;
  maps: boolean = false;
  zoom: number;
  address: string;
  public latitude: number;
  public longitude: number;
  addWorkingButton = false;
  formHide: boolean = false;
  form2Hide: boolean = false;
  phoneCode: string = '';
  drpCountry: RegionCountriesDTO[] = [];
  drpStates: RegionStatesDTO[] = [];
  drpCities: RegionCitiesDTO[] = [];
  CountryName = '';
  stateName = '';
  Country: string = '';
  State: string = '';
  City: string = '';
  saveHide: boolean = true;
  saveHide2: boolean = false;
  roleDropdownValue: string;
  isConfirmBranchLocation: boolean = false;
  branchLocation: string = '';
  branchName: string = '';
  sessionUserId: string = '';
  isEditDetails: boolean = false;
  public filteredStates: Observable<RegionStatesDTO[]> | undefined;
  public filteredCities: Observable<RegionCitiesDTO[]> | undefined;
  public filteredCountries: Observable<RegionCountriesDTO[]> | undefined;
  public lat: number;
  public long: number;
  emptyguid:string ="00000000-0000-0000-0000-000000000000";

  public brnachList: Observable<Branch[]> | undefined;
  public drpBranch: Branch[] = [];
  organizationUnitName: string;
  tenantId: string = '';
  subscription$: Subscription[] = [];

  dataSource: MatTableDataSource<any> = new MatTableDataSource<any>([]);
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatTable, { static: false }) tableRef: MatTable<any>;
  @ViewChild('selectBranch') selectBranch: MatSelect;
  @ViewChild('selectAllBranch') private selectAllBranch: MatOption;

  constructor(
    private formBuilder: FormBuilder,
    private fb: FormBuilder,
    private roleService: RoleService,
    private userService: UserService,
    private IdentityUserService:IdentityUserService,
    private cdr: ChangeDetectorRef,
    private branchService: OrganizationUnitService,
    private Table: TableService,
    private officialService: TenantsService,
    public dialog: MatDialog,
    private datepipe: DatePipe,
    private regionDropdownService: RegionDropdownService,
    private mapsAPILoader: MapsAPILoader,
    private dateValidator: DateValidator,
    private toastr: ToastrService,
    public title: Title,
    private route: ActivatedRoute,
  ) {}

  ngOnInit(): void {
    this.tenantId = localStorage.getItem('tenantId') ?? '';
    this.title.setTitle("Qsecure | User");
    this.sessionUserId = this.route.snapshot.params.id;
    this.sessionUserId ? this.isEditDetails = true : this.isEditDetails = false
    this.Table.getUsersCreate().subscribe(
      (value) => {
        if (value == true) {
          this.isShownSaveButton = true;
        } else {
          this.isShownSaveButton = false;
        }
      },
      (err) => {
        const data: HttpErrorResponse = err;
        // Swal.fire({
        //   icon: 'error',
        //   text: data?.error?.error?.message,
        // });
      }
    );

    this.userForm = this.fb.group({
      drpBranch: new FormControl(''),
      txtBranch: new FormControl(''),
    });

    this.getBranchList();

    // setTimeout(() => {
    this.Table.getUsersView().subscribe(
      (value) => {
        if (value == true) {
          this.isShownUsersTable = true;
          this.getTableData();
        } else {
          this.isShownUsersTable = false;
        }
      },
      (err) => {
        const data: HttpErrorResponse = err;
        // Swal.fire({
        //   icon: 'error',
        //   text: data?.error?.error?.message,
        // });
      }
    );

    this.roleForm = this.formBuilder.group({
      txtRole: new FormControl('', Validators.required),
    });

    this.userForm = this.formBuilder.group(
      {
        txtUserName: new FormControl(
          '',
          Validators.required
          ,this.checkUserUniqness()
        ),
        txtEmail: new FormControl(
          '',
          Validators.compose([Validators.required, Validators.email]),
          [this.checkEmailUniqness()]
        ),
        txtBranchName: new FormControl('', Validators.required),
        // txtRole: new FormControl("", Validators.required),
        txtPassword: new FormControl('', Validators.required),
        txtConfirmPassword: new FormControl('', Validators.required),
      },
      {
        validator: PasswordValidation.MatchPassword,
      }
    );

    this.userForm2 = this.formBuilder.group(
      {
        txtUserName2: new FormControl(
          '',
          Validators.required
          ,this.checkUserUniqness()
        ),
        txtEmail2: new FormControl(
          '',
          Validators.compose([Validators.required, Validators.email]),
          [this.checkEmailUniqness()]
        ),
        txtBranchName2: new FormControl('', Validators.required),

        // newly added
        drpCountry: new FormControl('', Validators.required),
        drpState: new FormControl(''), // Validators.required
        drpCity: new FormControl(''),
        txtAddress1: new FormControl('', Validators.required),
        txtAddress2: new FormControl(''),
        txtPhnNo: new FormControl('', Validators.required),
        txtZipCode: new FormControl('', [Validators.required, Validators.minLength(5), Validators.maxLength(9), Validators.pattern('-?[0-9]+(\.[0-9][0-9]?)?')]),
        drpCityFilter: new FormControl(''),
        drpCountryFilter: new FormControl(''),
        drpStateFilter: new FormControl(''),
        statusToggle: new FormControl(''),
        txtMap: new FormControl(''),
        txtPassword2: new FormControl('', Validators.required),
        txtConfirmPassword2: new FormControl('', Validators.required),
      },
      {
        validator: PasswordValidation.MatchPasswordV1,
      }
    );
    // filter variables
    this.UserFilterForm = this.formBuilder.group({
      userName: new FormControl(''),
      createdDate: new FormControl('', this.dateValidator.dateVaidator),
      phoneNumber: new FormControl(''),
      roleName: new FormControl(''),
      status: new FormControl(''),
      email: new FormControl(''),
      txtBranch: new FormControl(''),
    });

    // valueChanges for filter
    this.UserFilterForm.valueChanges.subscribe((value) => {
      let filter = {
        userName: value?.name?.trim()?.toLowerCase() ?? value?.userName?.trim()?.toLowerCase(),
        roleName: value?.roleName?.trim()?.toLowerCase(),
        phoneNumber: value?.phoneNumber?.trim()?.toLowerCase(),
        createdDate: value?.createdDate?.trim()?.toLowerCase(),
        status: value?.status?.trim()?.toLowerCase(),
        email: value?.email?.trim()?.toLowerCase(),
        branch: value?.txtBranch?.trim()?.toLowerCase(),
      };
      if (this.UserFilterForm.valid) {
        this.dataSource.filter = JSON.stringify(filter);
        this.filtertable(filter);
      }
    });
    this.emailMask = emailMask;
    this.clientId != null &&
      this.clientId != '' &&
      this.clientId != undefined &&
      this.getBranch();

    this.loadOrganizationDropdown();
    this.loadRoleDropdown();
    this.countryDropdown();
    // this.getTableData();
    // this.StatusFilter(1);
  }

  getBranchList() {
    const getBranchList = this.branchService
      .getBranchList(this.tenantId)
      .subscribe(
        (response) => {

          response?.forEach((element) => {
            this.drpBranch.push({
              id: element.id,
              organizationUnitName: element.organizationUnitName,
            });
          });

          this.brnachList = this.userForm
            ?.get('txtBranch')
            ?.valueChanges?.pipe(
              startWith(''),
              map((value) =>
                this.drpBranch?.filter((option) =>
                  option?.organizationUnitName
                    ?.toLowerCase()
                    ?.includes(value?.toLowerCase() ?? '')
                )
              )
            );
        },
        (err) => {
          const data: HttpErrorResponse = err;
          Swal.fire({
            icon: 'info',
            text: data?.error?.error?.message,
          });
        }
      );
    this.subscription$.push(getBranchList);
  }

  checkUserUniqness(): AsyncValidatorFn {
    return (
      control: AbstractControl
    ): Observable<{ [key: string]: any } | null> => {
    const gUser=this.userId == "" ? this.emptyguid : this.userId;
      const input: UsernameEmailValidationDto = {
        username:
          String(control?.value ?? '')
            ?.toLowerCase()
            ?.trim() ?? '',
        emailAddress: '',
        isUserNameExists: false,
        isEmailAddressExists: false,
        userId:gUser
      };
      return this.userService.checkUserNameIfExistsByInputV1(input).pipe(
        tap((x) => control?.setErrors({ isUserNameExists: true })),
        debounceTime(300),
        map((response) =>
          response?.isUserNameExists === true
            ? { isUserNameExists: true }
            : control?.setErrors(null) == null
            ? null
            : null
        )
      );
    };
  }
  checkEmailUniqness(): AsyncValidatorFn {
    return (
      control: AbstractControl
    ): Observable<{ [key: string]: any } | null> => {
      const gUser=this.userId == "" ? this.emptyguid : this.userId;
      // const userId = await getUserIdAsync();
      const input: UsernameEmailValidationDto = {
        username: '',
        emailAddress:
          String(control?.value ?? '')
            ?.toLowerCase()
            ?.trim() ?? '',
        isUserNameExists: false,
        isEmailAddressExists: false,
        // userId:"4472e4c3-968b-b08b-c11a-3a0d4e4e48f2",
        userId:gUser
      };
      return this.userService.checkEmailIfExistsByInputV1(input).pipe(
        tap((x) => control?.setErrors({ isEmailAddressExists: true })),
        debounceTime(300),
        map((response) =>
          response?.isEmailAddressExists === true
            ? { isEmailAddressExists: true }
            : control?.setErrors(null) == null
            ? null
            : null
        )
      );
    };

  }
  //! Validate Image
  onFileSelected(value: File) {
    if (value.size >= LogoMaxandTypeLimits.documentFileSizeLimit) {
      this.showErrorForFileType = false;
      this.showErrorBasedOnSize = true;
      return;
    }
    if ('image/jpeg' !== value.type) {
      this.showErrorForFileType = true;
      this.showErrorBasedOnSize = false;
      return;
    }
    let reader = new FileReader();
    this.fileAs64Value = '';
    reader.readAsDataURL(value);
    reader.onload = () => {
      this.fileAs64Value = reader?.result?.toString() ?? '';
      this.showErrorBasedOnSize = false;
      this.showErrorForFileType = false;
    };
  }

  // Get Country Dropdown
  countryDropdown() {
    this.userForm2.patchValue({ drpCountry: defaultCountry.US });
    this.onChangeState(defaultCountry.US);
    this.Country = defaultCountry.US;
    this.drpCountry = [];
    this.regionDropdownService
      .getAllCountriesByInput(new PagedAndSortedResultRequestDto())
      .subscribe(
        (response) => {
          //
          this.drpCountry =
            response?.items.filter((x) => x.countryShortName == 'US') ?? [];
          this.filteredCountries = this.userForm2
            ?.get('txtCountryFilter')
            ?.valueChanges.pipe(
              startWith(''),
              map((value) =>
                this.drpCountry.filter((option) =>
                  option?.countryName
                    ?.toLowerCase()
                    ?.includes(value?.toLowerCase() ?? '')
                )
              )
            );
        },
        (err) => {
          const data: HttpErrorResponse = err;
          Swal.fire({
            icon: 'info',
            text: data?.error?.error?.message,
          });
        }
      );
  }
  // On Change State Dropdown
  onChangeState(value: string | null | undefined): boolean {
    this.userForm2.patchValue({
      drpState: null,
      drpCity: null,
    });
    this.drpStates = [];
    this.drpCities = [];
    value !== null &&
      value !== undefined &&
      this.regionDropdownService
        .getAllStatesByCountryNameByCountryShortName(value)
        .subscribe(
          (response) => {
            this.drpStates = response?.items ?? [];
            if (response?.items?.length === 0) {
              this.phoneCode = '';
              this.userForm2.controls['txtPhnNo'].disable();
            } else {
              this.userForm2.controls['txtPhnNo'].enable();
              this.phoneCode = this.drpStates[0]?.countryPhoneCode ?? '';
            }
            this.filteredStates = this.userForm2
              ?.get('drpStateFilter')
              ?.valueChanges.pipe(
                startWith(''),
                map((value) =>
                  this.drpStates.filter((option) =>
                    option?.stateName
                      ?.toLowerCase()
                      ?.includes(value?.toLowerCase() ?? '')
                  )
                )
              );
          },
          (err) => {
            const data: HttpErrorResponse = err;
            Swal.fire({
              icon: 'info',
              text: data?.error?.error?.message,
            });
          }
        );
    return this.drpStates.length !== 0;
  }
  // On Change City Dropdown
  onChangeCity(state: string | null | undefined, country: string): boolean {
    this.userForm2.patchValue({
      drpCity: null,
    });
    country =
      country == ''
        ? this.userForm2.value.drpCountry == (null || undefined)
          ? ''
          : this.userForm2.value.drpCountry
        : country;
    this.drpCities = [];
    state !== null &&
      state !== undefined &&
      this.regionDropdownService
        .getAllCitiesByCountryAndStateNameByCountryShortNameAndStateShortName(
          country,
          state
        )
        .subscribe(
          (response) => {
            this.drpCities = response?.items ?? [];

            this.filteredCities = this.userForm2
              ?.get('drpCityFilter')
              ?.valueChanges.pipe(
                startWith(''),
                map((value) =>
                  this.drpCities.filter((option) =>
                    option?.cityName
                      ?.toLowerCase()
                      ?.includes(value?.toLowerCase() ?? '')
                  )
                )
              );
          },
          (err) => {
            const data: HttpErrorResponse = err;
            Swal.fire({
              icon: 'info',
              text: data?.error?.error?.message,
            });
          }
        );
    return this.drpCities.length !== 0;
  }

  //! get official name in super admin
  getBranch() {
    const isSupAdmin: number =
      Number(+localStorage.getItem('isAdmin') ?? 0) ?? 0;
    isSupAdmin &&
      this.officialService
        .getTenantInformationById(this.clientId)
        .subscribe((response) => {
          if (response?.status == 'InActive') {
            this.isShownSaveButton = false;
            this.isShownUsersTable = false;
          }
        });
  }
  //! Load Organization Dropdown Function

  loadOrganizationDropdown() {
    this.branchService
      .getOrganizationList(this.clientId)
      .subscribe((response) => {
        this.drpOrganization = [];

        response &&
          response?.forEach((element) => {
            if (element?.status == 'Active') {
              this.drpOrganization.push(element);

              //this.drpOrganization = response ?? [];
            }
          });
      });
  }

  //! load role dropdown
  loadRoleDropdown() {
    let roleList: GetRoleListUsingTenantIdDto = {
      tenantId: this.clientId,
    };
    this.roleService.getRoleList(roleList).subscribe(
      (response) => {
        this.drpRoles = [];
        this.drpRoles = response;
      },
      (err) => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      }
    );
  }

  addWorkingId: string;
  userName: string;

  // ------------------------------------------ ontime functionality ---------------------------------------------

  // dropdown changes per select
  ngOnChanges(userId) {
    userId = this.userId;
  }

  onUserChange(userId) {
    this.roleDropdownValue = userId.value;
    if (
      userId.value == 'Scheduler' ||
      userId.value == 'Respiratory Therapist'
    ) {
      this.formHide = false;
      this.form2Hide = true;
      this.saveHide = false;
      this.saveHide2 = true;
    } else {
      this.formHide = true;
      this.form2Hide = false;
      this.saveHide = true;
      this.saveHide2 = false;
    }
  }
  getSelectedRoleValue() {
    return this.roleForm.get('txtRole').value;
  }

  /// save user functionality
  saveOnTimeUser(data: string) {
    if (data === 'firstForm') {
      this.isShowSpinner = true;
      this.saveButtonHide = true;
      if (
        this.userId == null ||
        this.userId == '' ||
        this.userId == undefined
      ) {
        let user: IdentityUserCreateUsingTenantIdDto = {
          tenantId: this.clientId,
          userName: this.userForm.value.txtUserName.replace(/ /g, ""),
          name: this.userForm.value.txtUserName,
          surname: '',
          email: this.userForm.value.txtEmail.toLowerCase(),
          phoneNumber: '',
          lockoutEnabled: true,
          roleNames: [this.roleDropdownValue],
          password: this.userForm.value.txtPassword,
          extraProperties: {
            organizationUnitId: this.userForm.value.txtBranchName,
            profileImageAsBase64String:
              this.fileAs64Value === defaultUrl
                ? ''
                : this.fileAs64Value?.slice(23)?.toString() ?? '',
            status: 'Active',
            RemoteLocationsId:null,
          },
        };
        this.userService.createUser(user).subscribe(
          () => {
            this.addWorkingButton = false;
            this.reset('');
            this.getTableData();
            // Swal.fire({ title: 'Success', html: 'Saved Successfully', icon: 'success', timer: 3000, timerProgressBar: true });
            this.toastr.success('Saved Successfully', 'Success');
            this.saveButtonHide = false;
            this.isShowSpinner = false;
            this.isConfirmBranchLocation = false;
          },
          (err) => {
            this.saveButtonHide = false;
            this.isShowSpinner = false;
            this.isConfirmBranchLocation = false;
            const data: HttpErrorResponse = err;
            Swal.fire({
              icon: 'info',
              text: data?.error?.error?.message,
            });
          }
        );
      } else {
        let user: IdentityUserUpdateDto = {
          userName: this.userForm.value.txtUserName.replace(/ /g, ""),
          name:  this.userForm.value.txtUserName,
          surname: '',
          email: this.userForm.value.txtEmail.toLowerCase(),
          phoneNumber: '',
          lockoutEnabled: true,
          // roleNames: [this.roleDropdownValue],
          roleNames: [this.getSelectedRoleValue()],
          password: this.userForm.value.txtPassword,
          extraProperties: {
            organizationUnitId: this.userForm.value.txtBranchName,
            status: 'Active',
            profileImageAsBase64String:
              this.fileAs64Value === defaultUrl
                ? ''
                : this.fileAs64Value?.slice(23)?.toString() ?? '',
            RemoteLocationsId:null,

          },
          concurrencyStamp: this.concurrencyStamp,
        };

        // return false;

        // return false;
        this.userService.updateUser(this.clientId, this.userId, user).subscribe(
          () => {
            this.reset('');
            this.getTableData();
            this.addWorkingButton = false;
            this.saveButtonHide = false;
            this.isConfirmBranchLocation = false;
            // Swal.fire({ title: 'Success', html: 'Saved Successfully', icon: 'success', timer: 3000, timerProgressBar: true });
            this.toastr.success('Update Successfully', 'Success');
            this.isShowSpinner = false;
            this.saveButtonHide = false;
          },
          (err) => {
            this.saveButtonHide = false;
            this.isConfirmBranchLocation = false;
            const data: HttpErrorResponse = err;
            this.isShowSpinner = false;
            this.saveButtonHide = false;
            Swal.fire({
              icon: 'info',
              text: data?.error?.error?.message,
            });
          }
        );
      }
    } else if (data === 'secondForm') {
      this.getenablefields();

      if (
        this.userId == null ||
        this.userId == '' ||
        this.userId == undefined
      ) {

        let user: IdentityUserCreateUsingTenantIdDto = {
          tenantId: this.clientId,
          userName: this.userForm2.value.txtUserName2.replace(/ /g, ""),
          name: this.userForm2.value.txtUserName2,
          surname: '',
          email: this.userForm2.value.txtEmail2.toLowerCase(),
          phoneNumber: this.userForm2.value.txtPhnNo,
          lockoutEnabled: true,
          roleNames: [this.roleDropdownValue],
          password: this.userForm2.value.txtPassword2,
          extraProperties: {
            organizationUnitId: this.userForm2.value.txtBranchName2,
            // profileImageAsBase64String: '',
            profileImageAsBase64String:this.fileAs64Value === defaultUrl
                ? ''
                : this.fileAs64Value?.slice(23)?.toString() ?? '',
            address1: this.userForm2.value.txtAddress1,
            address2: this.userForm2.value.txtAddress2,
            country: this.userForm2.value.drpCountry,
            state: this.userForm2.value.drpState,
            city: this.userForm2.value.drpCity,
            zipCode: this.userForm2.value.txtZipCode,
            // status:
            //   this.userForm2.value.statusToggle === true
            //     ? 'Active'
            //     : 'Inactive',
            status: 'Active',
            userWorkingHoursId: this.emptyguid,
            latitude: this.latitude,
            longitude: this.longitude,
          },
        };

        this.userService.createUser(user).subscribe(
          (response) => {

            this.addWorkingButton = false;
            this.getTableData();
            this.reset('');
            // Swal.fire({ title: 'Success', html: 'Saved Successfully', icon: 'success', timer: 3000, timerProgressBar: true });
            this.toastr.success('Saved Successfully', 'Success');
            this.isConfirmBranchLocation = false;
          },
          (err) => {
            this.isConfirmBranchLocation = false;
            const data: HttpErrorResponse = err;
            Swal.fire({
              icon: 'info',
              text: data?.error?.error?.message,
            });
          }
        );
      } else {

        this.onUserChange(this.userId);
        let user: IdentityUserUpdateDto = {
          userName: this.userForm2.value.txtUserName2.replace(/ /g, ""),
          name: this.userForm2.value.txtUserName2,
          surname: '',
          email: this.userForm2.value.txtEmail2.toLowerCase(),
          phoneNumber: this.userForm2.value.txtPhnNo,
          lockoutEnabled: true,
          roleNames: [this.roleForm.value.txtRole],
          password: this.userForm2.value.txtPassword2,
          extraProperties: {
            organizationUnitId: this.userForm2.value.txtBranchName2,
            profileImageAsBase64String: '',
            address1: this.userForm2.value.txtAddress1,
            address2: this.userForm2.value.txtAddress2,
            country: this.userForm2.value.drpCountry,
            state: this.userForm2.value.drpState,
            city: this.userForm2.value.drpCity,
            zipCode: this.userForm2.value.txtZipCode,
            // status:
            //   this.userForm2.value.statusToggle === true
            //     ? 'Active'
            //     : 'Inactive',
            status: 'Active',
            latitude: this.latitude,
            longitude: this.longitude,
            // userWorkingHoursId:
          },
          concurrencyStamp: this.concurrencyStamp,
        };

        this.userService.updateUser(this.clientId, this.userId, user).subscribe(
          (response) => {

            this.getTableData();
            this.reset('');
            this.addWorkingButton = false;
            this.isConfirmBranchLocation = false;
            // Swal.fire({ title: 'Success', html: 'Updated Successfully', icon: 'success', timer: 3000, timerProgressBar: true });
            this.toastr.success('Updated Successfully', 'Success');
          },
          (err) => {
            this.isConfirmBranchLocation = false;
            const data: HttpErrorResponse = err;
            Swal.fire({
              icon: 'info',
              text: data?.error?.error?.message,
            });
          }
        );
      }
    }
  }

  ViewUser(event) {
    window.scrollTo({ top: 0, behavior: 'smooth' });     // Scroll to the top of the page
    this.step = !this.step;
    this.panelOpenState = true;
    this.getUserById(event.id);
  }
  confirmUserLocation() {
    if (
      this.latitude == null ||
      (undefined && this.longitude == null) ||
      undefined
    ) {
      this.isConfirmBranchLocation = true;
      this.branchLocation = '';
      this.branchName = this.userForm2.value.txtBranchName;
      this.userForm2.value.txtAddress2 =
        this.userForm2.value.txtAddress2 == null || undefined
          ? ' '
          : this.userForm2.value.txtAddress2;
      this.userForm2.value.drpCity =
        this.userForm2.value.drpCity == null
          ? ' '
          : this.userForm2.value.drpCity;
      this.mapsAPILoader?.load()?.then(() => {
        let geocoder = new google.maps.Geocoder();
        this.branchLocation = this.branchLocation
          .concat(
            this.userForm2.value.txtAddress +
              ' ' +
              this.userForm2.value.txtAddress2 +
              ' ' +
              this.userForm2.value.drpState +
              ' ' +
              this.userForm2.value.drpCountry +
              ' ' +
              this.userForm2.value.drpCity +
              ' ' +
              this.userForm2.value.txtPostalCode
          )
          .trim();
        geocoder?.geocode(
          { address: this.branchLocation },
          (results, status) => {
            if (status == google.maps.GeocoderStatus.OK) {
              (this.latitude = results[0].geometry.location.lat()),
                (this.longitude = results[0].geometry.location.lng());
            }
          }
        );
      });
    } else {
      this.isConfirmBranchLocation = true;

      this.mapsAPILoader?.load()?.then(() => {
        let geocoder = new google.maps.Geocoder();

        geocoder?.geocode(
          { location: { lat: this.latitude, lng: this.longitude } },
          (results, status) => {
            if (status == google.maps.GeocoderStatus.OK) {
              (this.latitude = results[0].geometry.location.lat()),
                (this.longitude = results[0].geometry.location.lng());
            }
          }
        );
      });
      this.markers.push({
        lat: this.latitude,
        lng: this.longitude,
        draggable: true,
      });
    }
  }

  confirmMap() {}

  getUserById(id) {
    this.userService.getUserById(this.clientId, id).subscribe(
      (response) => {
        this.addWorkingId = response?.extraProperties.userWorkingHoursId;
        if (
          response?.roleName === 'Scheduler' ||
          response?.roleName == 'Respiratory Therapist'
        ) {
          this.formHide = false;
          this.form2Hide = true;
          this.saveHide = false;
          this.saveHide2 = true;
          this.addWorkingButton = true;
          this.userId = response?.id;
          this.stateName = response?.extraProperties?.state;
          this.CountryName = response?.extraProperties?.country;

          this.CountryName != null &&
            this.CountryName !== '' &&
            this.CountryName != undefined &&
            this.onChangeState(this.CountryName) &&
            this.userForm2.patchValue({
              drpCountry: this.CountryName,
            });
            this.userForm.patchValue({
              txtUserName: response?.name != '' ? response?.name : response?.userName ,
              txtEmail: response?.email,
              txtBranchName: response?.extraProperties.organizationUnitId,
            });
          this.stateName != null &&
            this.stateName !== '' &&
            this.stateName != undefined &&
            this.onChangeCity(this.stateName, this.CountryName) &&
            this.userForm2.patchValue({
              drpState: this.stateName,
              drpCity: this.City,
            });

          const profileUrl =
            (response?.extraProperties?.profileImageAsBase64String ?? '') === ''
              ? defaultUrl
              : String(
                  'data:image/jpeg;base64,' +
                    response?.extraProperties?.profileImageAsBase64String
                );
          this.fileAs64Value = profileUrl;
          this.concurrencyStamp = response?.concurrencyStamp ?? '';

          this.userForm2.patchValue({
            txtUserName2: response?.name != '' ? response?.name : response?.userName,
            txtEmail2: response?.email,
            txtBranchName2: response?.extraProperties.organizationUnitId,
            drpCountry: response?.extraProperties.country,
            drpState: response?.extraProperties.state,
            drpCity: response?.extraProperties.city,
            txtAddress1: response?.extraProperties.address1,
            txtAddress2: response?.extraProperties.address2,
            txtPhnNo: response?.phoneNumber,
            txtZipCode: response?.extraProperties.zipCode,
            statusToggle:
              response?.extraProperties.status == 'Inactive' ? false : true,
          }),
            this.roleForm.patchValue({
              txtRole: response?.roleName,
            });
          this.latitude = response?.extraProperties.latitude;
          this.longitude = response?.extraProperties.longitude;
          // this.userForm2.controls['txtPassword2'].disable();
          // this.userForm2.controls['txtConfirmPassword2'].disable();
        } else {
          this.formHide = true;
          this.form2Hide = false;
          this.saveHide = true;
          this.saveHide2 = false;
          this.addWorkingButton = true;
          this.userId = response?.id;
          const profileUrl =
            (response?.extraProperties?.profileImageAsBase64String ?? '') === ''
              ? defaultUrl
              : String(
                  'data:image/jpeg;base64,' +
                    response?.extraProperties?.profileImageAsBase64String
                );
          this.fileAs64Value = profileUrl;
          this.concurrencyStamp = response?.concurrencyStamp ?? '';
          this.userForm.patchValue({
            txtUserName: response?.name != '' ? response?.name : response?.userName,
            txtEmail: response?.email,
            txtBranchName: response?.extraProperties.organizationUnitId,
          });
          this.userForm2.patchValue({
            txtUserName2: response?.name != '' ? response?.name : response?.userName,
            txtEmail2 : response?.email,
            txtBranchName2: response?.extraProperties.organizationUnitId,
            txtPhnNo: response?.phoneNumber,
          });
          this.roleForm.patchValue({
            txtRole: response?.roleName,
          });

          this.saveButtonHide = false;

          // this.saveButtonHide = false;
          // this.userForm.controls['txtPassword'].disable();
          // this.userForm.controls['txtConfirmPassword'].disable();
        }
      },
      (err) => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      }
    );
  }
  // StatusFilter(status:number){

  //   this.userTableData = [];
  //   this.dataSource = new MatTableDataSource(this.userTableData);
  //   this.dataSource.sort = this.sort;
  //   this.dataSource.paginator = this.paginator;
  //   this.isLoading = true;
  //   const tbleData=this.userService.StatusFilter(status).subscribe(
  //     (response) => {

  //     this.userTableData=response;
  //       // response &&
  //       // response?.forEach((element) => {
  //       //   this.userTableData.push({
  //       //     name: element?.name != '' ? element?.name : element?.userName,
  //       //     email: element?.email,
  //       //     branch:
  //       //       this.drpOrganization?.filter(
  //       //         (v) =>
  //       //           v?.id ===
  //       //           (element?.extraProperties?.organizationUnitId ?? '')
  //       //       )?.[0]?.organizationUnitName ?? '',
  //       //     role: element?.roleName,
  //       //     id: element?.id,
  //       //     phoneNumber: element?.phoneNumber,
  //       //     createdDate:
  //       //       (element?.creationTime ?? '') === ''
  //       //         ? ''
  //       //         : this.datepipe.transform(
  //       //             element?.creationTime,
  //       //             'MM/dd/yyyy'
  //       //           ) ?? '',
  //       //     status: element?.extraProperties?.status,
  //       //     isStatic: element?.isStatic,
  //       //     isDefault: element?.isDefault,
  //       //   });
  //       // });
  //         this.userTableData.filter((test, index, array) =>
  //         index === array.findIndex((findTest) =>
  //            findTest.email === test.email
  //         )
  //      );
  //     const arrTableData = this.userTableData;
  //     this.dataSource = new MatTableDataSource(arrTableData);
  //     this.dataSource.sort = this.sort;
  //     this.dataSource.paginator = this.paginator;
  //     this.isLoading = false;
  //     this.userTableData=[];
  //     });
  // }

  //// get table data
  getTableData() {

    let filters: IdentityUserUsingTenantIdDto = {
      tenantId: this.clientId,
    };
    this.userTableData = [];
    this.dataSource = new MatTableDataSource(this.userTableData);
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
    this.isLoading = true;
    this.userService.getUserList(filters).subscribe(
      (response) => {
        response &&
          response?.forEach((element) => {
            this.userTableData.push({
              name: element?.name != '' ? element?.name : element?.userName,
              email: element?.email,
              branch:
                this.drpOrganization?.filter(
                  (v) =>
                    v?.id ===
                    (element?.extraProperties?.organizationUnitId ?? '')
                )?.[0]?.organizationUnitName ?? '',
              role: element?.roleName,
              id: element?.id,
              phoneNumber: element?.phoneNumber,
              createdDate:
                (element?.creationTime ?? '') === ''
                  ? ''
                  : this.datepipe.transform(
                      element?.creationTime,
                      'MM/dd/yyyy'
                    ) ?? '',
              status: element?.extraProperties?.status,
              isStatic: element?.isStatic,
              isDefault: element?.isDefault,
            });
          });
            this.userTableData.filter((test, index, array) =>
            index === array.findIndex((findTest) =>
               findTest.email === test.email
            )
         );

        const arrTableData = this.userTableData;
        this.dataSource = new MatTableDataSource(arrTableData);
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
        this.isLoading = false;
        this.userTableData=[];
        // this.StatusFilter(1);
      },
      (err) => {
        const data: HttpErrorResponse = err;
        // Swal.fire({
        //   icon: 'error',
        //   text: data?.error?.error?.message,
        // });
      }
    );
  }

  // filter table search
  filtertable(filter) {
    let Date = filter.Date;
    // let DateFormat = Date === null ? "" : Intl.DateTimeFormat('en-US').format(new Date(Date));
    let searchInput: SearchUserDTO = {
      tenantId: this.clientId,
      userName: filter?.userName,
      phoneNumber: filter?.phoneNumber,
      roleName: filter?.roleName,
      status: filter?.status,
      createdDate: filter?.createdDate,
      email: filter?.email,
      branch: filter?.branch,
    };
    this.isLoading = true;

    this.userTableData = [];
    this.dataSource = new MatTableDataSource(this.userTableData);
    this.dataSource.sort = this.sort;
    setTimeout(() => (this.dataSource.paginator = this.paginator));
    this.userService.searchUsersByInput(searchInput).subscribe(
      (data) => {
        this.userTableData = [];
        data?.forEach((element) => {
          this.userTableData.push({
            id: element?.id,
            name: element?.userName,
            phoneNumber: element?.phoneNumber,
            role: element?.roleName,
            branch:
              this.drpOrganization?.filter(
                (v) =>
                  v?.id === (element?.extraProperties?.organizationUnitId ?? '')
              )?.[0]?.organizationUnitName ?? '',
            createdDate:
              (element?.creationTime ?? '') === ''
                ? ''
                : this.datepipe.transform(
                    element?.creationTime,
                    'MM/dd/yyyy'
                  ) ?? '',
            tenentId: element?.tenantId,
            status: element?.extraProperties?.status,
            email: element?.email,
            isStatic: element?.isStatic,
            isDefault: element?.isDefault,
          });
        });
        this.isLoading = false;
        this.dataSource = new MatTableDataSource(this.userTableData);
        this.dataSource.sort = this.sort;
        setTimeout(() => (this.dataSource.paginator = this.paginator));
      },
      (err) => {
        this.dataSource = new MatTableDataSource(this.userTableData);
        this.dataSource.sort = this.sort;
        setTimeout(() => (this.dataSource.paginator = this.paginator));
        const data: HttpErrorResponse = err;
        this.isLoading = false;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      }
    );
  }

  // Open add working hours
  openAddWorkingModel() {
    const dialogRef = this.dialog.open(OntimeUserWorkHoursComponent, {
      height: '80%',
      width: '80%',
      disableClose: true,
      data: {
        userId: this.userId,
        addWorkingId: this.addWorkingId,
        userName: this.userName,
      },
    });

    dialogRef.afterClosed().subscribe(() => {
      this.reset('');
    });
  }

  // delete user function
  deleteUser(event: { id: string }) {
    Swal.fire({
      title: 'Are you sure you want to Delete?',
      text: "You won't be able to retrieve this data!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#34c38f',
      cancelButtonColor: '#f46a6a',
      confirmButtonText: 'Yes, delete it!',
    }).then((result) => {

      if (result.value) {
        //
        // this.userService.deleteUser(this.clientId, event.id).subscribe(
        //   (response) => {

        //     this.userTableData.forEach((element) => {
        //       if (event.id == element?.id) {
        //         let index = this.userTableData.indexOf(element, 0);
        //         this.userTableData.splice(index, 1);
        //         // Swal.fire({ title: 'Success', html: 'Deleted Successfully', icon: 'success', timer: 3000, timerProgressBar: true });
        //         this.toastr.success('Deleted Successfully', 'Success');
        //         this.getTableData();
        //       }
        //     });
        //     this.Table.setUserListTable(this.userTableData);
        //     // this.resetUsers();
        //   },
        //   (err) => {
        //     const data: HttpErrorResponse = err;
        //     Swal.fire({
        //       icon: 'info',
        //       text: data?.error?.error?.message,
        //     });
        //   }
        // );
        this.IdentityUserService.delete(event.id).subscribe(
          () => {
            this.getTableData();
            this.addWorkingButton = false;
            this.saveButtonHide = false;
            this.toastr.success('Deleted Successfully', 'Success');
            this.isShowSpinner = false;
            this.saveButtonHide = false;
          },
          (err) => {
            const data: HttpErrorResponse = err;

          }
        );

      }
    });
    this.reset('');
  }

  // reset fields
  reset(data: string) {
    if (data == 'firstForm') {
      this.userForm.patchValue({
        txtUserName: '',
        txtEmail: '',
        txtBranchName: '',
        txtRole: '',
        txtPassword: '',
        txtConfirmPassword: '',
      });
      this.roleForm.patchValue({
        txtRole: '',
      });
    } else if (data == 'secondForm') {
      this.userForm2.patchValue({
        txtUserName2: '',
        txtEmail2: '',
        txtBranchName2: '',
        txtBranch: '',
        drpCountry: '',
        drpState: '',
        drpCity: '',
        txtAddress1: '',
        txtAddress2: '',
        txtPhnNo: '',
        txtZipCode: '',
        txtCityFilter: '',
        txtCountryFilter: '',
        txtStateFilter: '',
        statusToggle: '',
        txtMap: '',
        txtPassword2: '',
        txtConfirmPassword2: '',
      }),
        this.roleForm.patchValue({
          txtRole: '',
        });
    }

    this.roleDropdownValue = '';
    this.saveButtonHide = false;
    this.userId = '';
    this.userForm.controls['txtPassword'].enable();
    this.userForm.controls['txtConfirmPassword'].enable();
    this.userForm2.controls['txtPassword2'].enable();
    this.userForm2.controls['txtConfirmPassword2'].enable();
    this.userForm.reset();
    this.userForm2.reset();
    this.roleForm.reset();
    this.userForm.markAsUntouched();
    this.userForm.updateValueAndValidity();
    this.showErrorForFileType = false;
    this.showErrorBasedOnSize = false;
    this.fileAs64Value = defaultUrl;
    this.addWorkingButton = false;
    this.formHide = false;
    this.form2Hide = false;
  }

  //Active and Inactive Functionality
  statusChange(event: MatSlideToggleChange, data: any) {
    // this.status = event.checked == true ? "Active" : "Inactive";
    if (event.checked == true) {
      Swal.fire({
        title: 'Do you want to activate?',
        text: 'By Changing Status, this User will be activated',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#34c38f',
        cancelButtonColor: '#f46a6a',
        confirmButtonText: 'Yes, Activate it!',
      }).then((result) => {
        if (result.value) {
          let user: IdentityUserUpdateDto = {
            userName: data?.name,
            name: '',
            surname: '',
            email: data?.email,
            phoneNumber: data?.phoneNumber,
            lockoutEnabled: true,
            roleNames: [data?.role],
            password: '',
            extraProperties: {
              organizationUnitId: data.barnch,
              status: 'Active',
              profileImageAsBase64String: '',
            },
            concurrencyStamp: '',
          };
          if (
            data?.id &&
            data?.id !== '' &&
            data?.id !== null &&
            data?.id !== undefined
          ) {
            this.userService
              .updateUser(this.clientId, data?.id, user)
              .subscribe(
                () => {
                  this.getTableData();
                  this.addWorkingButton = false;
                  this.saveButtonHide = false;

                  // Swal.fire({ title: 'Success', html: 'Saved Successfully', icon: 'success', timer: 3000, timerProgressBar: true });
                  this.toastr.success('Saved Successfully', 'Success');
                  this.isShowSpinner = false;
                  this.saveButtonHide = false;
                },
                (err) => {
                  this.saveButtonHide = false;
                  const data: HttpErrorResponse = err;
                  this.isShowSpinner = false;
                  this.saveButtonHide = false;
                  Swal.fire({
                    icon: 'info',
                    text: data?.error?.error?.message,
                  });
                }
              );
          }
        } else {
          this.getTableData();
        }
      });
    } else if (event.checked == false) {
      Swal.fire({
        title: 'Do you want to deactivate?',
        text: 'By Changing Status, this User will be deactivated',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#34c38f',
        cancelButtonColor: '#f46a6a',
        confirmButtonText: 'Yes, deactivate it!',
      }).then((result) => {
        if (result.value) {
          let user: IdentityUserUpdateDto = {
            userName: data?.name,
            name: '',
            surname: '',
            email: data?.email,
            phoneNumber: data?.phoneNumber,
            lockoutEnabled: true,
            roleNames: [data?.role],
            password: '',
            extraProperties: {
              organizationUnitId: data.branch,
              status: 'Inactive',
              profileImageAsBase64String: '',
            },
            concurrencyStamp: '',
          };
          if (
            data?.id &&
            data?.id !== '' &&
            data?.id !== null &&
            data?.id !== undefined
          ) {
            this.userService
              .updateUser(this.clientId, data?.id, user)
              .subscribe(
                () => {
                  this.reset('');
                  this.getTableData();
                  this.addWorkingButton = false;
                  this.saveButtonHide = false;

                  // Swal.fire({ title: 'Success', html: 'Saved Successfully', icon: 'success', timer: 3000, timerProgressBar: true });
                  this.toastr.success('Saved Successfully', 'Success');
                  this.isShowSpinner = false;
                  this.saveButtonHide = false;
                },
                (err) => {
                  this.saveButtonHide = false;

                  const data: HttpErrorResponse = err;
                  this.isShowSpinner = false;
                  this.saveButtonHide = false;
                  Swal.fire({
                    icon: 'info',
                    text: data?.error?.error?.message,
                  });
                }
              );
          }
        } else {
          this.getTableData();
        }
      });
    }
  }

  // Disable and enable funtions from ontime
  getdisablefields() {
    this.userForm.controls['txtUserName'].disable();
    this.userForm2.controls['drpCountry'].disable();
    this.userForm2.controls['drpState'].disable();
    this.userForm2.controls['drpCity'].disable();
    this.userForm2.controls['txtAddress1'].disable();
    this.userForm2.controls['txtAddress2'].disable();
    this.userForm.controls['txtPhnNo'].disable();
    this.userForm2.controls['txtZipCode'].disable();
    this.roleForm.controls['txtRole'].disable();
    this.userForm.controls['txtBranchName'].disable();
    this.userForm.controls['txtEmail'].disable();
  }

  getenablefields() {
    this.userForm.controls['txtUserName'].enable();
    this.userForm2.controls['drpCountry'].enable();
    this.userForm2.controls['drpState'].enable();
    this.userForm2.controls['txtAddress1'].enable();
    this.userForm2.controls['txtAddress2'].enable();
    this.userForm2.controls['txtPhnNo'].enable();
    this.userForm2.controls['txtZipCode'].enable();
    this.roleForm.controls['txtRole'].enable();
    // this.userForm.controls['txtBranchName'].enable();
    this.userForm.controls['txtBranch'].enable();
    this.userForm.controls['txtEmail'].enable();
    this.userForm2.controls['drpCity'].enable();
  }
  hideUser()
  {
    this.userTab=false;
  }
  showUser()
  {
    this.userTab=true;
  }
  // Confirm Location
  // togglePasswordVisibility() {
  //   this.hide = !this.hide;


  // }

  // clear dropdown from ontime
  clearCountry() {
    // this.userForm2.controls['txtPhnNo'].disable();
    this.Country = '';
    this.userForm.patchValue({ drpCountry: '' });
  }

  clearState() {
    this.State = '';
    this.userForm.patchValue({ drpState: '' });
  }

  clearCity() {
    this.City = '';
    this.userForm.patchValue({ drpCity: '' });
  }

  clearRole() {
    this.role = '';
    this.roleForm.patchValue({ txtRole: '' })
    // this.userForm.patchValue({ txtRole: '' });
  }

  clearBranch() {
    this.branch = '';
    this.userForm.patchValue({ txtBranchName: '' });
  }

  // map functionality
  markers: marker[] = [];

  markerDragEnd($event: MouseEvent) {
    (this.latitude = $event.coords.lat), (this.longitude = $event.coords.lng);

    this.markers.push({
      lat: $event.coords.lat,
      lng: $event.coords.lng,
      draggable: true,
      visible: false,
      opacity: 0.4,
    });
  }

  allBranchSelect() {
    if (this.selectAllBranch.selected) {
      this.selectBranch.options.forEach((item: MatOption) => item.select());
    } else {
      this.selectBranch.options.forEach((item: MatOption) => item.deselect());
    }
  }

}

export interface Branch {
  id?: string;
  organizationUnitName?: string;
}

interface marker {
  lat?: number;
  lng?: number;
  label?: string;
  draggable?: boolean;
  visible?: boolean;
  opacity?: number;
}
