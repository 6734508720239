<!-- Title -->
<div class="row align-items-center">
  <!-- <div class="col-lg-12"> -->

  <div class="col-md-10">
    <!-- <h2 class="customThemeClass">{{ notesModalTitle }} - {{data.defaultFaxId}}</h2>
    <h2 class="customThemeClass">{{ notesModalTitle }} - {{data.chartNo}}</h2> -->
    <h2 *ngIf="data.addnotes == true" class="customThemeClass">
      {{ notesModalTitle }} - {{ defaultFaxIdV1 ? defaultFaxIdV1 : 'N/A' }}
    </h2>
    <h2 *ngIf="data.addnotes == false" class="customThemeClass">
      {{ notesModalTitle }} - {{ chartNoV1 ? chartNoV1 : 'N/A'}}
    </h2>

  </div>
  <!-- <div class="col-md-5"> -->
    <!-- <h2 *ngIf="Idview" class="headStyle text-danger">
      {{ defaultNotesId }}
    </h2> -->
  <!-- </div> -->
  <div class="col-md-2" style="text-align: right">
    <mat-icon mat-dialog-close>close</mat-icon>
  </div>
</div>

<!-- <mat-divider></mat-divider> -->
<!-- Title -->
<mat-dialog-content>
  <div class="row">
    <div class="col-lg-12">
      <!-- <div class="card elevated-div"> -->
      <!-- <div class="card-header">Shipping List</div> -->
      <div class="card">
        <div class="card-body pb-0 mb-1 mt-1">
          <!-- Notes Form -->
          <form [formGroup]="noteForm">
            <div class="modal-body">
              <div class="row">
                <div class="col-lg-12">
                  <!-- <mat-card-content> -->
                  <!--Row 1-->
                  <div class="row">
                    <!--Notes Type-->
                    <!-- <div class="col-12"> -->
                      <mat-form-field class="col-6">
                        <!-- <mat-label>Note Type <span class="asterisk">*</span></mat-label> -->
                        <mat-select formControlName="drpNotetype" #singleSelect [disabled]="drpDisable">
                          <mat-option>
                            <ngx-mat-select-search [formControl]="NoteTypeControl" placeholderLabel="Search"
                              noEntriesFoundLabel="No Matches found"
                              formControlName="txtNoteType"></ngx-mat-select-search>
                          </mat-option>
                          <mat-option *ngFor="let note of drpNotetype" [value]="note.id+ '/' +note.noteType">
                            {{ note.noteType }}
                          </mat-option>
                        </mat-select>

                        <mat-label>
                          <div class="select-placeholder-container" *ngIf="isdrpLoading">
                            <span>Loading...</span>
                            <mat-spinner class="spinner" diameter="20"></mat-spinner>
                          </div>
                          <span *ngIf="!isdrpLoading">Note Type <span class="asterisk">*</span></span>
                        </mat-label>
                        <mat-error class="spanAlignment" *ngIf="
                    noteForm?.get('drpNotetype')?.touched &&
                    noteForm?.get('drpNotetype')?.errors?.required
                  ">
                          Note Type is a required field!
                        </mat-error>
                      </mat-form-field>

                      <mat-checkbox *ngIf="isCallNote" class="col-3 ml-3 mt-3" formControlName="chkIsCallNote">is Call
                        Note<span class="asterisk">*</span>
                      </mat-checkbox>
                    <!-- </div> -->
                    <!-- <div class="col-6">
                      <button *ngIf="!saveButtonHide" mat-button class="buttonColor mb-2 mr-2"
                        [disabled]="isShowSpinner || !(!noteForm?.invalid && noteForm?.dirty)" (click)="saveNotes()">
                        Save
                        <mat-icon *ngIf="isShowSpinner">
                          <mat-spinner class="spinner-border spinner-border-sm" diameter="20"></mat-spinner>
                        </mat-icon>
                      </button>
                      <button mat-button class="resetclr mb-2 mr-2" (click)="resetNotes()">Reset</button>
                    </div> -->
                  </div>

                  <!-- Row 3-->
                  <!-- <div class="row"> -->
                    <!-- <mat-checkbox *ngIf="isCallNote" class="col-lg-3 mt-3" formControlName="chkIsCallNote">is Call
                      Note<span class="asterisk">*</span>
                    </mat-checkbox> -->
                  <!-- </div> -->
                  <!--Row 4-->
                  <div class="row">
                    <mat-form-field class="col-12">
                      <mat-label>Description<span class="asterisk">*</span></mat-label>
                      <textarea matInput rows="6" autocomplete="off" maxlength="5000" formControlName="txtDescription"  [(ngModel)]="notesText" (input)="updateCharacterCount(5000)"
                        type="text" cdkFocusInitial></textarea>
                        <div class="char-count">
                          {{ notesText?.length }} / 5000
                        </div>
                      <mat-error class="spanAlignment" *ngIf="noteForm?.get('txtDescription')?.touched">
                        <ng-container *ngIf="noteForm?.get('txtDescription')?.hasError('required')">
                          Description is a required field!
                        </ng-container>
                        <ng-container *ngIf="noteForm?.get('txtDescription')?.hasError('whitespace')">
                          Description cannot be just whitespace!
                        </ng-container>
                      </mat-error>
                    </mat-form-field>
                  </div>
                  <!--Row 5-->
                  <div class="row">
                    <!--Date Completed-->
                    <!-- <mat-form-field class="col-4">
                <mat-label>Date Completed</mat-label>
                <input formControlName="txtDateCompleted" placeholder="(MM/DD/YYYY)" matInput autocomplete="new-txtDateCompleted"
                  maxlength="40" [ngxMatDatetimePicker]="txtDateCompleted" />
                <mat-datepicker-toggle matSuffix [for]="txtDateCompleted"></mat-datepicker-toggle>
                <ngx-mat-datetime-picker #txtDateCompleted [showSpinners]="true" [showSeconds]="true" [stepHour]="1"
                  [stepMinute]="1" [stepSecond]="1" [touchUi]="false" [color]="'primary'" [enableMeridian]="true">
                  <ng-template>
                    <span>OK</span>
                  </ng-template>
                </ngx-mat-datetime-picker>
                <mat-error *ngIf="
                    noteForm?.get('txtDateCompleted')?.touched &&
                    noteForm?.get('txtDateCompleted')?.errors
                  ">
                  Enter Valid Date!
                </mat-error>
              </mat-form-field> -->
                    <!--Assigned-->
                    <!-- <mat-form-field class="col-4">
                 <mat-label>Assigned To</mat-label>
                 <mat-select maxlength="40">
                   <mat-option>
                   Test
                   </mat-option>
                   <mat-option>
                    Test1
                    </mat-option>
                 </mat-select>
                </mat-form-field> -->
                    <!-- Reminder -->
                    <!-- <mat-checkbox class="col-4 mt-3" formControlName="chkReminder">Reminder
                </mat-checkbox> -->
                  </div>
                  <!--Row 6-->
                  <div class="row">
                    <!--Date Needed-->
                    <!-- <mat-form-field class="col-4">
                <mat-label>Date Needed</mat-label>
                <input formControlName="txtDateNeeded" placeholder="(MM/DD/YYYY)" matInput autocomplete="new-txtDateNeeded"
                  maxlength="40" [ngxMatDatetimePicker]="txtDateNeeded" />
                <mat-datepicker-toggle matSuffix [for]="txtDateNeeded"></mat-datepicker-toggle>
                <ngx-mat-datetime-picker #txtDateNeeded [showSpinners]="true" [showSeconds]="true" [stepHour]="1"
                  [stepMinute]="1" [stepSecond]="1" [touchUi]="false" [color]="'primary'" [enableMeridian]="true">
                  <ng-template>
                    <span>OK</span>
                  </ng-template>
                </ngx-mat-datetime-picker>
                <mat-error *ngIf="
                    noteForm?.get('txtDateNeeded')?.touched &&
                    noteForm?.get('txtDateNeeded')?.errors
                  ">
                  Enter Valid Date!
                </mat-error>
              </mat-form-field> -->

                    <!--Severity-->
                    <!-- <mat-form-field class="col-4">
                <mat-label>Severity</mat-label>
                <mat-select formControlName="drpSeverity" [(value)]="severity" maxlength="40">
                  <mat-option *ngFor="let severity of drpSeverity" [value]="severity.severityShortCodeId">
                    {{ severity.severityType }}
                  </mat-option>
                </mat-select>
                <button mat-button (click)="severity = undefined; $event.stopPropagation()" *ngIf="severity" matSuffix
                  (click)="clearSeverity()" mat-icon-button>
                  <mat-icon>close</mat-icon>
                </button>
              </mat-form-field> -->
                    <!--Actual Date-->
                    <!-- <mat-form-field class="col-4">
                <mat-label>Actual Date</mat-label>
                <input formControlName="txtActualDate" placeholder="(MM/DD/YYYY)" matInput autocomplete="new-txtActualDate"
                  maxlength="40" [ngxMatDatetimePicker]="txtActualDate" />
                <mat-datepicker-toggle matSuffix [for]="txtActualDate"></mat-datepicker-toggle>
                <ngx-mat-datetime-picker #txtActualDate [showSpinners]="true" [showSeconds]="true" [stepHour]="1"
                  [stepMinute]="1" [stepSecond]="1" [touchUi]="false" [color]="'primary'" [enableMeridian]="true">
                  <ng-template>
                    <span>OK</span>
                  </ng-template>
                </ngx-mat-datetime-picker>
              </mat-form-field> -->
                    <!--State-->
                    <!-- <mat-form-field class="col-4">
                <mat-label>State<span class="asterisk">*</span></mat-label>
                <mat-select
                  formControlName="drpState"
                  [(value)]="state"
                  maxlength="40"
                >
                  <mat-option
                    *ngFor="let state of drpState"
                    [value]="state.severityShortCodeId"
                  >
                    {{ state.severityType }}
                  </mat-option>
                </mat-select>
                <button
                  mat-button
                  (click)="state = undefined; $event.stopPropagation()"
                  *ngIf="state"
                  matSuffix
                  (click)="clearState()"
                  mat-icon-button
                >
                  <mat-icon>close</mat-icon>
                </button>
              </mat-form-field> -->
                  </div>

                  <!-- </mat-card-content> -->
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</mat-dialog-content>
<!-- <mat-dialog-actions align="end"> -->
<!-- Button Actions -->
<!-- <button *ngIf="!saveButtonHide" mat-button class="buttonColor"
    [disabled]="isShowSpinner || !(!noteForm?.invalid && noteForm?.dirty)" (click)="saveNotes()">
    Save
    <mat-icon *ngIf="isShowSpinner">
      <mat-spinner class="spinner-border spinner-border-sm" diameter="20">
      </mat-spinner>
    </mat-icon>
  </button> -->
<!-- {{isShowSpinner || !(!noteForm?.invalid && noteForm?.dirty)}}
  {{ !(!noteForm?.invalid && noteForm?.dirty)}}
  {{ noteForm?.invalid }}

 Button Actions -->
<!-- <button class="buttonCancelColor" mat-button mat-dialog-close>Close</button>
</mat-dialog-actions> -->
<mat-dialog-actions>
  <div class="col-12 text-center mt-1">
    <button *ngIf="!saveButtonHide" mat-button class="buttonColor mb-2 mr-2"
      [disabled]="isShowSpinner || !(!noteForm?.invalid && noteForm?.dirty) || issaving" (click)="saveNotes()">
      {{issave}}
      <mat-icon *ngIf="isShowSpinner">
        <mat-spinner class="spinner-border spinner-border-sm" diameter="20"></mat-spinner>
      </mat-icon>
    </button>
    <button mat-button class="resetclr mb-2 mr-2" (click)="resetNotes()">Reset</button>
  </div>
</mat-dialog-actions>
