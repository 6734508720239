import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-saleorder-shipping1',
  templateUrl: './saleorder-shipping1.component.html',
  styleUrls: ['./saleorder-shipping1.component.scss']
})
export class SaleorderShipping1Component implements OnInit {
  hide = true;
  value: any;
  overrideForm: FormGroup;
  formInteracted = false;

  constructor(public dialogRef: MatDialogRef<SaleorderShipping1Component>, private fb: FormBuilder) {}

  ngOnInit(): void {
    this.overrideForm = this.fb.group({
      overrideType: ['', Validators.required],
      overrideNote: ['', Validators.required],
      userName: ['', Validators.required],
      password: ['', [Validators.required, Validators.minLength(10)]],
    });
  }

  resetForm(): void {
    this.overrideForm.reset();
    this.formInteracted = false;
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  onSubmit(): void {
    this.formInteracted = true;

    if (this.overrideForm.valid) {
      Swal.fire({
        icon: 'success',
        title: 'Saved successfully!',
        showConfirmButton: false,
        timer: 1500
      });


      this.closeDialog();
    } else {
      Swal.fire({
        icon: 'error',
        title: 'Form is incomplete or invalid!',
        text: 'Please fill in all required fields.',
      });
    }
  }

}
