<div class="container-fluid main-content">
    <div class="row bg">
        <div class="col-12">
            <div class="d-flex align-items-center justify-content-between mb-3">
                <h3 class="font-weight-bolder mb-0 pt-2 pb-2">Settings / OnTime Setting</h3>

                <!-- <div class=" page-title-box page-title-right">
                    <ol class="breadcrumbs m-0 align-items-center">
                        <li class="breadcrumb-item">Settings</li>
                        <li class="breadcrumb-item ">OnTime Settings</li>
                    </ol>
                </div> -->

            </div>
        </div>
    </div>
</div>
<div class="col-12" style="margin-top: -21px;">
    <!-- <div class="card">
            <div class="card-body"> -->
    <ul ngbNav #nav="ngbNav" justify="justified"
        class="alert alert-success nav nav-pills nav-fill">
        <li ngbNavItem="appointmentType" class="nav-item">
            <a ngbNavLink>Appointment Type</a>
            <ng-template ngbNavContent>
                <app-appointment-type>
                </app-appointment-type>
            </ng-template>
        </li>
        <li ngbNavItem="holiday" class="nav-item">
            <a ngbNavLink>Holiday</a>
            <ng-template ngbNavContent>
                <app-holiday>
                </app-holiday>
            </ng-template>
        </li>
        <!-- <li  ngbNavItem="items" class="nav-item">
            <a ngbNavLink>Supply Items</a>
            <ng-template ngbNavContent>
                <app-supply-items></app-supply-items>
            </ng-template>
        </li> -->
        <li  ngbNavItem="user" class="nav-item">
            <a ngbNavLink>Ontime User</a>
            <ng-template ngbNavContent>
                <app-ontime-user></app-ontime-user>
            </ng-template>
        </li>
        <li  ngbNavItem="coverage" class="nav-item">
            <a ngbNavLink>Coverage Area</a>
            <ng-template ngbNavContent>
                <app-ontime-coverage-area></app-ontime-coverage-area>
            </ng-template>
        </li>
        <li  ngbNavItem="Office" class="nav-item">
            <a ngbNavLink>Ontime Branch</a>
            <ng-template ngbNavContent>
                <app-ontime-branch></app-ontime-branch>
            </ng-template>
        </li>

        <li  ngbNavItem="pickup" class="nav-item">
            <a ngbNavLink>Pickup</a>
            <ng-template ngbNavContent>
              <app-ontime-pickup-area></app-ontime-pickup-area>
            </ng-template>
        </li>
        <!-- <li  ngbNavItem="profile" class="nav-item">
            <a ngbNavLink>Profile</a>
            <ng-template ngbNavContent>
                <app-profile></app-profile>
            </ng-template>
        </li>-->
        <!-- <li  ngbNavItem="Approval" class="nav-item">
            <a ngbNavLink>Leave Approval</a>
            <ng-template ngbNavContent>
                <app-leave-approval></app-leave-approval>
            </ng-template>
        </li> -->
    </ul>
    <div [ngbNavOutlet]="nav"></div>
    <!-- </div>
        </div>
    </div> -->
</div>
