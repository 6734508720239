<table
datatable
[dtOptions]="dtPatientClaimOptions"
class="row-border hover w-100 display"
>
<thead>
  <tr>
    <th [matTooltip]="'ID'">ID</th>
    <th [matTooltip]="'Status'" class="text-right">Status</th>
    <th [matTooltip]="'Auth'" class="text-right">Auth</th>
    <th [matTooltip]="'Day Sheet'">Day Sheet</th>
    <th [matTooltip]="'DOS'">DOS</th>
    <th [matTooltip]="'Provider'">Provider</th>
    <th [matTooltip]="'#'" class="text-right">#</th>
    <th [matTooltip]="'Patient Balance ($)'" class="text-right">Patient Balance ($)</th>
    <th [matTooltip]="'Insurance Balance ($)'" class="text-right">Insurance Balance ($)</th>
    <th [matTooltip]="'Total ($)'" class="text-right">Total ($)</th>
    <th [matTooltip]="'First Filled'">First Filled</th>
    <th [matTooltip]="'Last Filled'">Last Filled</th>
    <th [matTooltip]="'Claim ID'">Claim ID</th>
  </tr>
</thead>
<tbody>
  <tr *ngFor="let claim of claimDataArray">
    <!-- Accessing properties of each claim object for table cells -->
    <td [matTooltip]="claim?.id || '-'">{{ claim.id }}</td>
    <td [matTooltip]="claim?.status || '-'" class="hold-text-color text-right" >
      <a (click)="editClaimInfo()" > {{ claim.status }}</a>
    </td>
    <td [matTooltip]="claim?.auth || '-'" class="authNo-text-color text-right">{{ claim.auth }}</td>
    <td [matTooltip]="claim?.daySheet || '-'"> {{ claim.daySheet }}</td>
    <td [matTooltip]="claim?.dos || '-'">{{ claim.dos }}</td>
    <td [matTooltip]="claim?.provider || '-'">{{ claim.provider }}</td>
    <td [matTooltip]="2" class="text-right">2</td>
    <td [matTooltip]="claim?.patientBalance || '-'" class="text-right">{{ claim.patientBalance }}</td>
    <td [matTooltip]="claim?.insuranceBalance || '-'" class="text-right">{{ claim.insuranceBalance }}</td>
    <td [matTooltip]="claim?.total || '-'" class="text-right">{{ claim.total }}</td>
    <td [matTooltip]="claim?.firstFilled ? (claim?.firstFilled | date : 'MM/dd/yyyy' : 'en_US'):'-' "> {{ claim.firstFilled }}</td>
    <td [matTooltip]="claim?.lastFilled ? (claim?.lastFilled | date : 'MM/dd/yyyy' : 'en_US'):'-' ">{{ claim.lastFilled }}</td>
    <td [matTooltip]="claim?.claimId || '-'">{{ claim.claimId }}</td>
  </tr>
</tbody>
<tfoot>
    <tr>
      <th>
        <input
          matInput
          class="textbox-border-class"
          type="text"
          placeholder="ID"
        />
      </th>
      <th>
        <input
          matInput
          class="textbox-border-class text-right"
          type="text"
          placeholder="Status"
        />
      </th>
      <th>
        <input
          matInput
          class="textbox-border-class text-right"
          type="text"
          placeholder="Auth"
        />
      </th>
      <th>
        <input
          matInput
          class="textbox-border-class"
          type="text"
          placeholder="Day Sheet"
        />
      </th>
      <th>
        <input
          matInput
          class="textbox-border-class"
          type="text"
          placeholder="DOS"
        />
      </th>
      <th>
        <input
          matInput
          class="textbox-border-class"
          type="text"
          placeholder="Provider"
        />
      </th>
      <th>
        <input
          matInput
          class="textbox-border-class text-right"
          type="text"
          placeholder="#"
        />
      </th>
      <th>
        <input
          matInput
          class="textbox-border-class text-right"
          type="text"
          placeholder="Patient Balance ($)"
        />
      </th>
      <th>
        <input
          matInput
          class="textbox-border-class text-right"
          type="text"
          placeholder="Insurance Balance ($)"
        />
      </th>
      <th>
        <input
          matInput
          class="textbox-border-class text-right"
          type="text"
          placeholder="Total ($)"
        />
      </th>
      <th>
        <input
          matInput
          class="textbox-border-class"
          type="text"
          placeholder="First Filled"
        />
      </th>
      <th>
        <input
          matInput
          class="textbox-border-class"
          type="text"
          placeholder="Last Filled"
        />
      </th>
      <th>
        <input
          matInput
          class="textbox-border-class"
          type="text"
          placeholder="Claim ID"
        />
      </th>
    </tr>
  </tfoot>
</table>