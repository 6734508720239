import type { CreateUpdateVendorDetailsDTO, VendorDataDTO, VendorDetailsDTO } from './dto/models';
import { RestService } from '@abp/ng.core';
import type { PagedAndSortedResultRequestDto, PagedResultDto } from '@abp/ng.core';
import { Injectable } from '@angular/core';
import type { VendorSearchDTO } from '../items/dto/models';
import { IFormFile } from 'projects/patient/src/app/patient-proxy/microsoft/asp-net-core/http';


@Injectable({
  providedIn: 'root',
})
export class VendorDetailsService {
  apiName = 'ItemManagement';

  create = (input: CreateUpdateVendorDetailsDTO) =>
    this.restService.request<any, VendorDetailsDTO>({
      method: 'POST',
      url: '/api/ItemApp/vendor-details',
      body: input,
    },
    { apiName: this.apiName });

  delete = (id: string) =>
    this.restService.request<any, void>({
      method: 'DELETE',
      url: `/api/ItemApp/vendor-details/${id}`,
    },
    { apiName: this.apiName });

  get = (id: string) =>
    this.restService.request<any, VendorDetailsDTO>({
      method: 'GET',
      url: `/api/ItemApp/vendor-details/${id}`,
    },
    { apiName: this.apiName });

  getList = (input: PagedAndSortedResultRequestDto) =>
    this.restService.request<any, PagedResultDto<VendorDetailsDTO>>({
      method: 'GET',
      url: '/api/ItemApp/vendor-details',
      params: { skipCount: input.skipCount, maxResultCount: input.maxResultCount, sorting: input.sorting },
    },
    { apiName: this.apiName });

  update = (id: string, input: CreateUpdateVendorDetailsDTO) =>
    this.restService.request<any, VendorDetailsDTO>({
      method: 'PUT',
      url: `/api/ItemApp/vendor-details/${id}`,
      body: input,
    },
    { apiName: this.apiName });
    getListV1 = (input: PagedAndSortedResultRequestDto) =>
    this.restService.request<any, PagedResultDto<VendorDataDTO>>({
      method: 'GET',
      url: '/api/ItemApp/vendor-details/async-v1',
      params: { skipCount: input.skipCount, maxResultCount: input.maxResultCount, sorting: input.sorting },
    },
    { apiName: this.apiName });
  importVendor = (fileContentAsFile: IFormFile) =>
    this.restService.request<any, PagedResultDto<VendorDetailsDTO>>({
      method: 'POST',
      url: '/api/ItemApp/vendor-details/import-vendor',
    },
    { apiName: this.apiName });

    searchVendorByInputAndDtStartDateAndDtEndDate = (
      vendorCode: string,
      vendorName: string,
      contactPersonName: string,
      email: string,
      contactNo: string,
      createdDate: string
    ) =>
      this.restService.request<any, PagedResultDto<VendorDetailsDTO>>(
        {
          method: 'GET',
          url: '/api/ItemApp/vendor-details/search-vendor',
          params: {
            vendorCode,
            vendorName,
            contactPersonName,
            email,
            contactNo,
            createdDate,
          },
        },
        { apiName: this.apiName }
      );
      getActiveVendorList = (input: PagedAndSortedResultRequestDto) =>
      this.restService.request<any, PagedResultDto<VendorDetailsDTO>>({
        method: 'GET',
        url: '/api/ItemApp/vendor-details/active-vendor',
      },
      { apiName: this.apiName });
      getListV2 = () =>
      this.restService.request<any, VendorDetailsDTO[]>({
        method: 'GET',
        url: '/api/ItemApp/vendor-details/async-v2',
      },
      { apiName: this.apiName });
  constructor(private restService: RestService) {}
}
