import type { CreateUpdatePayPalInvoiceDTO } from './dto/models';
import { RestService } from '@abp/ng.core';
import { Injectable } from '@angular/core';
import type { ClaimDTO } from '../posting/dto/models';

@Injectable({
  providedIn: 'root',
})
export class InvoiceService {
  apiName = 'billingManagement';

  createDraftInvoice = (input: CreateUpdatePayPalInvoiceDTO) =>
    this.restService.request<any, ClaimDTO>({
      method: 'POST',
      url: '/api/billingApp/invoice/create-draft-invoice',
      body: input,
    },
     { apiName: this.apiName, skipHandleError: true });

  getInvoicePaymentStatus = () =>
    this.restService.request<any, void>({
      method: 'GET',
      url: '/api/billingApp/invoice/get-invoice-payment-status',
    },
     { apiName: this.apiName, skipHandleError: true });

  remindInvoice = () =>
    this.restService.request<any, void>({
      method: 'POST',
      url: '/api/billingApp/invoice/remind-invoice',
    },
     { apiName: this.apiName, skipHandleError: true });

  constructor(private restService: RestService) {}
}
