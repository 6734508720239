<div class="row pb-2">
  <div class="col-lg-9 d-flex align-items-center">
    <h2 class="mb-2 header-text" mat-dialog-title>
      <B>Add Size</B>
    </h2>
  </div>
  <div class="col-lg-2 text-right">
    <button type="button" mat-icon-button class="close-button" (click)="onCloseClick()">
      <mat-icon>close</mat-icon>
    </button>
  </div>
</div>
<mat-dialog-content class="mat-typography">
  <div class="card card-body">
    <div class="row">


      <form [formGroup]="sizeForm">
        <mat-form-field class="col-12 dd-text">
          <mat-label
            >Size Name
            <span class="asterisk">*</span></mat-label
          >
          <input
            autocomplete="new-txtSize"
            matInput
            formControlName="txtSize"
            placeholder="Enter Size Name"
            type="text"
            maxlength="40"
          />
          <mat-error
            *ngIf="
            sizeForm?.get('txtSize')?.touched &&
            sizeForm?.get('txtSize')?.errors?.required
            "
          >
          Size Name is a required field!
          </mat-error>
          <mat-error
            *ngIf="
            sizeForm?.get('txtSize')?.touched &&
            sizeForm?.get('txtSize')?.errors
                ?.sAlphabets
            "
          >
            Enter only Alphabets!
          </mat-error>
        </mat-form-field>
      </form>
    </div>
  </div>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button
                              mat-button
                              (click)="saveSizedetails()"
                              class="buttonColor mb-1 mr-2"
                              [disabled]="
                      !(
                        !sizeForm?.invalid
                      ) || saveButtonHide
                    "
                            >
                              Save
                            </button>
  <button class="buttonCancelColor" mat-button (click)="onCloseClick()">Close</button>

  <!-- <button mat-button (click)="check()" >Check</button> -->
  <!-- <button class="buttonCancelColor" mat-button mat-dialog-close>Close</button> -->
</mat-dialog-actions>
