import { ListService } from '@abp/ng.core';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, FormGroupDirective, NgForm, Validators } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Title } from '@angular/platform-browser';
import { Store } from '@ngxs/store';
import { ProductType } from 'projects/inventory/src/app/item-proxy/item-management/items/product-type.enum';
import { ProductService } from 'projects/inventory/src/app/item-proxy/item-management/items/product.service';
import { productInterface } from 'projects/order/src/app/add-item/add-item.component';
import { MasterProviderService } from 'projects/order/src/app/order-proxy/order-management/inventory/master-provider.service';
import { SaleorderService } from 'projects/order/src/app/order-proxy/order-management/order/saleorder.service';
import { BillingDropdowns } from 'projects/patient/src/app/dropdown-proxy/dropdown-management/dropdowns/billing-dropdowns.enum';
import { BillingMasterDropdownDTO, CodeDTO, TOSDTO } from 'projects/patient/src/app/dropdown-proxy/dropdown-management/dropdowns/dto/billing/models';
import { DiagnosisCode10DTO, DiagnosisCode9DTO, PatientMasterDropdownDTO, PayorLevelDTO, PlaceOfServiceDTO } from 'projects/patient/src/app/dropdown-proxy/dropdown-management/dropdowns/dto/patient/models';
import { BillingMasterDropdownService } from 'projects/patient/src/app/dropdown-proxy/dropdown-management/dropdowns/master-dropdown-service/billing-master-dropdown.service';
import { PatientMasterDropdownService } from 'projects/patient/src/app/dropdown-proxy/dropdown-management/dropdowns/master-dropdown-service/patient-master-dropdown.service';
import { PatientDropdowns } from 'projects/patient/src/app/dropdown-proxy/dropdown-management/dropdowns/patient-dropdowns.enum';
import { DoctorService } from 'projects/patient/src/app/patient-proxy/patient/doctor.service';
import { PoliciesDTO } from 'projects/patient/src/app/patient-proxy/patient/dto';
import { getPatient } from 'projects/patient/src/app/patient-store/patient.action';
import { TableService } from 'projects/shared/src/app/table.service';
import { NumberValidators } from 'projects/shared/src/app/validations/number-validator';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import Swal from 'sweetalert2';

import { ClaimInformationsService } from '../billing-proxy/billing-management/posting/claim-informations.service';
import { ClaimDTO, PostingChargesDTO } from '../billing-proxy/billing-management/posting/dto/models';
import { PatientDTO } from '../billing-proxy/billing-management/rcm/patient-management/patient/dto/models';
import { getBilling, updateBilling } from '../billing-store/billing.action';
import { PostingItemPopupComponent } from '../posting-item-popup/posting-item-popup.component';
import { ToastrService } from "ngx-toastr";

export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}

@Component({
  selector: 'app-posting-charges',
  templateUrl: './posting-charges.component.html',
  styleUrls: ['./posting-charges.component.scss'],
  providers: [ListService]
})
export class PostingChargesComponent implements OnInit {

  @Output() nextTabMoveOnSaveEmitter: EventEmitter<string> = new EventEmitter<string>(null);

  @Output() savePostingChargesFormState: EventEmitter<any> = new EventEmitter<any>();
  txtIcd10: string []= [];
  public filteredIcd9Types: Observable<any[]>;
  public filteredIcd10Types: Observable<any[]>;
  txtIcd9: string = '';
  matcher = new MyErrorStateMatcher();
  isShowSpinner: boolean = false;
  public strPageType: string = "addPosting";
  public strPageType2: string = "addPosting2";
  public postingForm: FormGroup;
  drpCode: CodeDTO[] = [];
  drpDiagnosis10: DiagnosisCode10DTO[] = [];
  drpDiagnosis9: DiagnosisCode9DTO[] = [];
  drpModifiers: any[] = [];
  drpPos: PlaceOfServiceDTO[] = [];
  drpTos: TOSDTO[] = [];
  tblPostReponse: any[] = [];
  organizationUnitId: string = "";
  @Input() claimId: string = '';
  @Input() orderId: string = '';
  @Input() patientId: string = '';
  drpinsurance: insuranceface[] = [];
  drpitem: productInterface[] = [];
  tblPolicesReponse: any[] = [];
  doctorShow: boolean = false;
  isLoading: boolean = true;
  isIcd9Shown: boolean = false;
  isIcd10Shown: boolean = false;
  icd9Event: string = "";
  icd10Event1: string = "";
  icd10Event2: string = "";
  icd10Event3: string = "";
  @Input() listClaimId: string = '';
  @Input() listOrderId: string = '';
  @ViewChild(MatSort) sort: MatSort;
  code: string = '';
  description: string = '';
  modifiers1: string = '';
  modifiers2: string = '';
  modifiers3: string = '';
  modifiers4: string = '';
  dx1: string = '';
  dx2: string = '';
  dx3: string = '';
  dx4: string = '';
  pos: string = '';
  tos: string = '';
  saveButtonHide: boolean;
  drpProviders: any[] = [];
  drpDoctor: doctorInterface[] = [];
  constructor(private fb: FormBuilder, private billingDropdownService: BillingMasterDropdownService,
    private patientDropdownService: PatientMasterDropdownService,
    private title: Title, private orderService: SaleorderService,
    private number: NumberValidators, private store: Store,
    private Table: TableService,
    private deleteSerivice: ClaimInformationsService,
    private providerList: MasterProviderService,
    public list: ListService, public dialog: MatDialog,
    private doctorService: DoctorService,
    private itemsService: ProductService,
    private toastr: ToastrService,
  ) { }
  dataSource = new MatTableDataSource(this.tblPolicesReponse);

  columnsToDisplay = ['Name', 'Starts', 'Ends',];//'Copay', 'Deduct'
  ngOnInit(): void {
    this.title.setTitle("Qsecure | Add Posting");
    this.postingForm = this.fb.group({
      drpDiag9: new FormControl(""),
      drpDiag10ID: new FormControl("", [Validators.required]),
      drpCode: new FormControl(""),
      txtDescription: new FormControl(""),
      txtProvider: new FormControl("", [Validators.required]),
      txtCheckNo: new FormControl("", [this.number.isNumberCheck]),
      txtModifiers1: new FormControl(null),
      txtModifiers2: new FormControl(""),
      txtModifiers3: new FormControl(""),
      txtModifiers4: new FormControl(""),
      drpDx1: new FormControl(""),
      drpDx2: new FormControl(""),
      drpDx3: new FormControl(""),
      drpDx4: new FormControl(""),
      drpPos: new FormControl("", [Validators.required]),
      txtRefDoctorFilter: new FormControl(""),
      txtReferringDoctor: new FormControl("", [Validators.required]),
      drpTos: new FormControl(""),
      txtDateFrom: new FormControl(""),
      txtDateTo: new FormControl(""),
      txtFee: new FormControl(""),
      txtUnits: new FormControl(""),
      txtCopay: new FormControl(""),
      txtOther: new FormControl(""),
      txtTotal: new FormControl(""),
      txtDocument: new FormControl(""),
      txtStatement: new FormControl(""),
      txtPatientResp: new FormControl(""),
      txtIcd10Filter: new FormControl(null),
      txtIcd9Filter: new FormControl(null),
    })
    this.dataSource.sort = this.sort;
    this.getDropdown();
    this.getDiagnosisDropdown();
    this.itemDropdown();
    this.Table.setPostTable([])
    this.getItemTable();


    this.Table.getValueFilterTable().subscribe(value => {
    }, err => {
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    })

    if (this.postingForm.invalid || this.postingForm.dirty) {
      this.savePostingChargesFormState.emit(this.postingForm);
    }
    this.claimId = this.claimId ?? "";
  }

  patientName: string = "";
  patientDOB: string = "";
  defaultId: string = "";
  defaultSaleOrderId: string = "";

  //! get Patient Data By Id
  getPatientData() {
    this.store.dispatch(new getPatient(this.patientId)).pipe(
      map((value: any) => {
        return value?.patientState?.selectedPatient;
      })).subscribe((response: PatientDTO) => {
        if (response?.clinicals?.diagnosisCode9) {
          const diagonsis9: DiagnosisCode9DTO[] = this.drpDiagnosis9.filter((e, index) => e?.diagnosisCodeTypeData === response?.clinicals?.diagnosisCode9[index]) ?? [];
        }
        if (response?.clinicals?.diagnosisCode10) {
          const diagonsis10: DiagnosisCode10DTO[] = this.drpDiagnosis10.filter((e, index) => e?.diagnosisCode10ShortCodeId === response?.clinicals?.diagnosisCode10[index]) ?? [];
        }
        this.patientName = response?.personals?.firstName + ' ' + response?.personals?.lastName;
        this.patientDOB = response?.personals?.dateOfBirth;
        this.defaultId = response?.defaultPatientId;
      }, err => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      })
  }




  //! dropdown list
  dropDoc: any[] = []
  public filteredRefDoctorTypes: Observable<doctorInterface[]> | undefined;
  getDropdown() {
    let billingDropdownInput: BillingDropdowns[];
    billingDropdownInput = [
      BillingDropdowns.Codes,
      BillingDropdowns.Modifiers,
      BillingDropdowns.Pos,
      BillingDropdowns.Tos
    ]
    this.billingDropdownService.getBillingDropdownsByInput(billingDropdownInput).subscribe((stateResponse) => {
      let response: BillingMasterDropdownDTO = stateResponse;
      this.drpCode = response?.codes;
      this.drpModifiers = response?.modifiers;

      this.drpTos = response?.tos;
    }, err => {
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    })



    //! provider getlist
    const providerList = (query: any) => this.providerList.getList(query);
    this.list.hookToQuery(providerList).subscribe(response => {
      response?.items?.forEach(element => {
        this.drpProviders.push({
          id: element?.id,
          name: element?.fullName,
          shortCodeId: element?.shortCodeId,
          fullName: element?.fullName,
          npi: element?.npi
        });
      });
    });

    // Doctor dropdown
    this.dropDoc = [];
    const itemList = (query: any) => this.doctorService.getList(query);
    this.list.hookToQuery(itemList).subscribe(response => {
      response?.items?.forEach(element => {

        this.dropDoc.push({
          name: element?.npiNumber + ' / ' + element?.firstName + " " + element?.middleName + " " + element?.lastName,
          id: element?.id ?? ""
        });
      });
      this.filteredRefDoctorTypes = this.postingForm?.get("txtRefDoctorFilter")?.valueChanges
        .pipe(
          startWith(''),
          map(value => this.dropDoc?.filter(option => option?.name?.toLowerCase()?.includes(value?.toLowerCase() ?? "")))
        );
    })



  }

  //// diagnosis dropdown
  getDiagnosisDropdown() {
    let patientDropdownInput: PatientDropdowns[];
    patientDropdownInput = [
      PatientDropdowns.DiagnosisCode9,
      PatientDropdowns.DiagnosisCode10,
      PatientDropdowns.PlaceOfServices
    ]
    this.patientDropdownService.getPatientDropdownsByInput(patientDropdownInput).subscribe((stateResponse) => {
      const response: PatientMasterDropdownDTO = stateResponse;
      this.drpDiagnosis9 = response?.diagnosisCodes9;
      this.drpDiagnosis10 = response?.diagnosisCodes10;
      this.drpPos = response?.placeOfServices;
      this.filteredIcd9Types = this.postingForm?.get("txtIcd9Filter").valueChanges
        .pipe(
          startWith(''),
          map(value => this.drpDiagnosis9.filter(option => option?.diagnosisCodeTypeData?.toLowerCase()?.includes(value?.toLowerCase() ?? "")))
        );
      this.filteredIcd10Types = this.postingForm?.get("txtIcd10Filter").valueChanges
        .pipe(
          startWith(''),
          map(value => this.drpDiagnosis10.filter(option => option?.diagnosisCodeTypeData?.toLowerCase()?.includes(value?.toLowerCase() ?? "")))
        );
      this.claimId = this.claimId ?? "";
      this.claimId !== "00000000-0000-0000-0000-000000000000" && this.getPostingTable();
      if (this.patientId && this.patientId !== "" && this.patientId !== null && this.patientId !== undefined) {
        this.getPatientData();
      }
    }, err => {
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    })

  }

  /// item dropdown
  dos: string = "";
  itemDropdown(): void {

    // if (this.orderId != "" && this.orderId != null && this.orderId != undefined) {
    //   this.store.dispatch(new getOrder(this.orderId)).subscribe(response => {
    //     response?.orderState.selectedOrder.items.forEach(element => {
    //       this.dos = element?.originalDos
    //       this.drpitem.push({
    //         itemId: element?.itemIdOrBarCode,
    //         id: element?.id,
    //         name: element?.name,
    //         dos: element?.originalDos
    //       });
    //     });
    //     let selectedOrder: SaleorderDTO = response?.orderState.selectedOrder
    //     this.patientId = selectedOrder?.patientId ?? "";
    //     this.patientId && this.insuranceDropdown();
    //   }, err => {
    //     const data: HttpErrorResponse = err;
    //     Swal.fire({
    //       icon: 'error',
    //       text: data?.error?.error?.message,
    //     });
    //   });
    // }
    // else {
    //   this.listOrderId = this.listOrderId ?? "";
    //   this.listOrderId && this.store.dispatch(new getOrder(this.listOrderId)).subscribe(response => {
    //     response?.orderState.selectedOrder.items.forEach(element => {
    //       this.dos = element?.originalDos
    //       this.drpitem.push({
    //         itemId: element?.itemCodeId,
    //         id: element?.id,
    //         name: element?.name,
    //         dos: element?.originalDos
    //       });
    //     });
    //     let selectedOrder: SaleorderDTO = response?.orderState?.selectedOrder;
    //     this.patientId = selectedOrder?.patientId ?? "";
    //     this.patientId && this.insuranceDropdown();
    //   }, err => {
    //     const data: HttpErrorResponse = err;
    //     Swal.fire({
    //       icon: 'error',
    //       text: data?.error?.error?.message,
    //     });
    //   });
    // }

    this.drpitem = [];

    this.itemsService.getProductsByInput(ProductType.All).subscribe(response => {
      response?.forEach(element => {
        this.drpitem?.push({
          hcpcCode: element?.hcpcCode,
          productDescription: element?.productDescription,
          productCode: element?.productCode,
          status: element?.status,
          price: element?.price,
          productId: element?.productId,
          productName: element?.productName,
          productType: element?.productType,
          productNames: element?.productName,
          brandName: element?.brandName,
          categoryName: element?.categoryName,
          name: element?.productName,
        });
      });
      this.claimId !== "00000000-0000-0000-0000-000000000000" && this.getPostingTable();

    }, err => {
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    });
  }
  payorLevel: PayorLevelDTO[] = [];
  //! insurance active policy dropdown
  getPolicyDropdown() {
    let patientDropdownInput: PatientDropdowns[];
    patientDropdownInput = [
      PatientDropdowns.PayorLevels
    ]
    this.patientDropdownService.getPatientDropdownsByInput(patientDropdownInput).subscribe((stateResponse) => {
      let response: PatientMasterDropdownDTO = stateResponse;
      this.payorLevel = response?.payorLevels;
    }, err => {
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    });
  }

  /// ins dropdown
  insuranceDropdown(): void {
    this.drpinsurance = [];
    this.store.dispatch(new getPatient(this.patientId)).subscribe(response => {
      let policyResp = response?.patientState.selectedPatient.policies
      if (policyResp?.primaryPolicy != null) {
        this.drpinsurance.push({
          id: policyResp?.primaryPolicy?.id,
          policy: policyResp?.primaryPolicy?.insuranceType,
          policiesId: policyResp?.primaryPolicy?.policiesId,
          payorLevel: policyResp?.primaryPolicy?.payorLevel,
          name: policyResp?.primaryPolicy?.fisrtName
        });
      }

      if (policyResp?.secondaryPolicy != null) {
        this.drpinsurance.push({
          id: policyResp?.secondaryPolicy?.id,
          policy: policyResp?.secondaryPolicy?.insuranceType,
          policiesId: policyResp?.secondaryPolicy?.policiesId,
          payorLevel: policyResp?.secondaryPolicy?.payorLevel,
          name: policyResp?.secondaryPolicy?.fisrtName
        });
      }
      if (policyResp?.tertiaryPolicy != null) {
        this.drpinsurance.push({
          id: policyResp?.tertiaryPolicy?.id,
          policy: policyResp?.tertiaryPolicy?.insuranceType,
          policiesId: policyResp?.tertiaryPolicy?.policiesId,
          payorLevel: policyResp?.tertiaryPolicy?.payorLevel,
          name: policyResp?.tertiaryPolicy?.fisrtName
        });
      }

      // });
    }, err => {
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    });

  }

  //// get by id
  getClaimInfo() {
    let claimDetails: ClaimDTO;
    this.claimId = this.claimId ?? "";
    if (this.claimId != "") {
      this.store.dispatch(new getBilling(this.claimId)).subscribe(response => {
        claimDetails = response?.billingState.selectedBilling;
        this.savePosting(claimDetails);
      }, err => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      }, () => {
      });
    } else {
      this.store.dispatch(new getBilling(this.listClaimId)).subscribe(response => {
        claimDetails = response?.billingState.selectedBilling;
        this.savePosting(claimDetails);
      }, err => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      }, () => {
      });
    }

  }
  //! save posting and charges
  postingChargesId: "00000000-0000-0000-0000-000000000000";
  savePosting(response: ClaimDTO) {

    this.isShowSpinner = true;
    this.saveButtonHide = true;
    let postingDetails: ClaimDTO;
    let currentPosting: PostingChargesDTO[] = [];

    this.claimId = this.claimId ?? "";
    response?.lstPostingCharges?.forEach(lstItems => {
      lstItems.pos = this.postingForm.value.drpPos;
    })
    response.lstClaimInfo[0].referringProvider = this.postingForm.value.txtReferringDoctor;
    response.lstClaimInfo[0].diagnosisCode10 = this.postingForm.value.drpDiag10ID;
    response.lstClaimInfo[0].orderingProvider = this.postingForm.value.txtProvider;


    currentPosting = response?.lstPostingCharges;
    postingDetails = {
      lstClaimInfo: response?.lstClaimInfo,
      lstPostingCharges: response?.lstPostingCharges,
      //insurancePayments:null,
      insurancePayments: {
        primaryInsurancePaymentDetails: {
          source: "",
          code: "",
          description: "",
          checkNo: "",
          date: "",
          amount: "",
          plansOfFiles: "",
          statementNote: "",
          lstItemPayment: [],
          paymentType: "",
          reason: "",
          eobDocuments: null,
          totalBilled: 0,
          totalAllowed: 0,
          totalDeduct: 0,
          totalCoInsurance: 0,
          totalWriteOff: 0,
          totalInsurancePaid: 0,
          totalInsBal: 0,
          totalPatientBal: 0
        },
        secondaryInsurancePaymentDetails: {
          source: "",
          code: "",
          description: "",
          checkNo: "",
          date: "",
          amount: "",
          plansOfFiles: "",
          statementNote: "",
          lstItemPayment: [],
          paymentType: "",
          reason: "",
          eobDocuments: null,
          totalBilled: 0,
          totalAllowed: 0,
          totalDeduct: 0,
          totalCoInsurance: 0,
          totalWriteOff: 0,
          totalInsurancePaid: 0,
          totalInsBal: 0,
          totalPatientBal: 0

        },
        tertiaryInsurancePaymentDetails: {
          source: "",
          code: "",
          description: "",
          checkNo: "",
          date: "",
          amount: "",
          plansOfFiles: "",
          statementNote: "",
          lstItemPayment: [],
          paymentType: "",
          reason: "",
          eobDocuments: null,
          totalBilled: 0,
          totalAllowed: 0,
          totalDeduct: 0,
          totalCoInsurance: 0,
          totalWriteOff: 0,
          totalInsurancePaid: 0,
          totalInsBal: 0,
          totalPatientBal: 0
        }
      },
      saleOrder: response?.saleOrder,
      patientId: response?.patientId,
      orderId: response?.orderId,
      saleorderId: response?.saleorderId,
      defaultPatientId: response?.defaultPatientId,
      patientName: response?.patientName,
      patientDob: response?.patientDob,
      readyCount: response?.readyCount,
      processedCount: response?.processedCount,
      crossOverCount: response?.crossOverCount,
      holdCount: response?.holdCount,
      patientResponsibilityCount: response?.patientResponsibilityCount,
      patientResponsibilityAfterInsCount: response?.patientResponsibilityAfterInsCount,
      claimId: response?.claimId,
      lstClearingHouseUpdates: response?.lstClearingHouseUpdates,
      totalPatientBal: response?.totalPatientBal,
      lstPatientVisitNotes: response?.lstPatientVisitNotes,
      totalBilled: response?.totalBilled,
      totalInsBal: response?.totalInsBal,
      claimStatus: response?.claimStatus,
      lstReminders: response?.lstReminders,
      isVoided: response?.isVoided,

      claimProcessMethod: response?.claimProcessMethod
    }

    if (this.claimId != "") {
      this.store.dispatch(new updateBilling(this.claimId, postingDetails)).subscribe(value => {
        // Swal.fire({ title: 'Success', html: 'Saved Successfully', icon: 'success', timer: 3000, timerProgressBar: true });
        this.toastr.success('Saved Successfully','Success')
        this.savePostingChargesFormState.emit(this.postingForm.value);
        this.claimId = value?.billingState?.selectedBilling?.id;
        this.Table.setBillingData(this.claimId);
        this.getPostingTable();
        this.postingChargesId = "00000000-0000-0000-0000-000000000000";
        this.nextTabMoveOnSaveEmitter.emit("Posting");
        this.saveButtonHide = false;
        this.isShowSpinner = false;
      }, err => {
        this.saveButtonHide = false;
        this.isShowSpinner = false;
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      });
    }
  }
  //! for save button enable disable
  onIcd9Change(event) {
    this.icd9Event = event
    if ((event == undefined || event == null || event == "") &&
      (this.icd10Event1 == undefined || this.icd10Event1 == null || this.icd10Event1 == "") &&
      (this.icd10Event2 == undefined || this.icd10Event2 == null || this.icd10Event2 == "") &&
      (this.icd10Event3 == undefined || this.icd10Event3 == null || this.icd10Event3 == "")) {
      this.isIcd9Shown = false;
      this.isIcd10Shown = false;
    }
    else {
      this.isIcd9Shown = true;
      this.isIcd10Shown = true;
    }
  }

  /// onchange data for icd10
  onIcd10Change1(event) {
    this.icd10Event1 = event;
    if ((event == undefined || event == null || event == "") &&
      (this.icd9Event == undefined || this.icd9Event == null || this.icd9Event == "") &&
      (this.icd10Event2 == undefined || this.icd10Event2 == null || this.icd10Event2 == "") &&
      (this.icd10Event3 == undefined || this.icd10Event3 == null || this.icd10Event3 == "")) {
      this.isIcd10Shown = false;
      this.isIcd9Shown = false;
    }
    else {
      this.isIcd10Shown = true;
      this.isIcd9Shown = true;
    }
  }

  /// onchange data for icd10
  onIcd10Change2(event) {
    this.icd10Event2 = event;
    if ((event == undefined || event == null || event == "") &&
      (this.icd9Event == undefined || this.icd9Event == null || this.icd9Event == "") &&
      (this.icd10Event1 == undefined || this.icd10Event1 == null || this.icd10Event1 == "") &&
      (this.icd10Event3 == undefined || this.icd10Event3 == null || this.icd10Event3 == "")) {
      this.isIcd10Shown = false;
      this.isIcd9Shown = false;
    }
    else {
      this.isIcd10Shown = true;
      this.isIcd9Shown = true;
    }
  }
  /// onchange data for icd10
  onIcd10Change3(event) {
    this.icd10Event3 = event;
    if ((event == undefined || event == null || event == "") && (this.icd9Event == undefined || this.icd9Event == "")
      && (this.icd10Event1 == undefined || this.icd10Event1 == "") && (this.icd10Event2 == undefined || this.icd10Event2 == "")) {
      this.isIcd10Shown = false;
      this.isIcd9Shown = false;
    }
    else {
      this.isIcd10Shown = true;
      this.isIcd9Shown = true;
    }
  }
  //! item dropdown list
  getItemTable() {



    this.store.dispatch(new getPatient(this.patientId)).subscribe(response => {
      this.tblPolicesReponse = [];
      const policyResp: PoliciesDTO = response?.patientState?.selectedPatient?.policies;

      if (policyResp?.primaryPolicy != null) {
        this.tblPolicesReponse.push({
          type: policyResp?.primaryPolicy?.payorLevel,
          name: policyResp?.primaryPolicy?.insuranceType,
          policyNo: policyResp?.primaryPolicy?.policyNo
        });
      }

      if (policyResp?.secondaryPolicy != null) {
        this.tblPolicesReponse.push({
          type: policyResp?.secondaryPolicy?.payorLevel,
          name: policyResp?.secondaryPolicy?.insuranceType,
          policyNo: policyResp?.secondaryPolicy?.policyNo
        });
      }
      if (policyResp?.tertiaryPolicy != null) {
        this.tblPolicesReponse.push({
          type: policyResp?.tertiaryPolicy?.payorLevel,
          name: policyResp?.tertiaryPolicy?.insuranceType,
          policyNo: policyResp?.tertiaryPolicy?.policyNo
        });
      }

      this.dataSource = new MatTableDataSource(this.tblPolicesReponse);
      this.Table.setPolicyTable(this.tblPolicesReponse);

      // patientDetails = response?.patientState.selectedPatient;
      // this.tblPolicesReponse = [];
      // patientDetails?.policies && patientDetails?.policies !== null && patientDetails?.policies.forEach(element => {

      //   if (element?.id !== "00000000-0000-0000-0000-000000000000") {
      //     this.tblPolicesReponse.push({
      //       policiesId: element?.policiesId,
      //       startDate: element?.startDate == null ? "" : new Date(element?.startDate)?.toLocaleDateString("en-US"),
      //       endDate: element?.endDate == null ? "" : new Date(element?.endDate)?.toLocaleDateString("en-US"),
      //       deductible: element?.deductible,
      //       lastName: element?.lastName,
      //       fisrtName: element?.payorLevel,
      //       middleName: element?.middleName,
      //       birthdate: element?.birthdate,
      //     });
      //   }
      // })
      // this.dataSource = new MatTableDataSource(this.tblPolicesReponse);
      // this.Table.setPolicyTable(this.tblPolicesReponse);
    }, err => {
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    }, () => {

    });
  }
  //! posting table list
  total: number;
  totalItemQty;
  totalItemPrice;
  getPostingTable() {
    this.isLoading = true;
    let postingDetails: ClaimDTO;

    if (this.claimId != null && this.claimId != "") {
      this.store.dispatch(new getBilling(this.claimId)).subscribe(response => {

        postingDetails = response?.billingState?.selectedBilling;
        this.orderId = postingDetails?.orderId ?? "";
        this.postingForm.patchValue({
          drpDiag10ID: ((postingDetails?.lstClaimInfo[0]?.diagnosisCode10)),
          txtProvider: postingDetails?.lstClaimInfo[0]?.orderingProvider ?? "",
          drpPos: postingDetails?.lstPostingCharges[0]?.pos ?? "",
          txtReferringDoctor: postingDetails?.lstClaimInfo[0]?.referringProvider ?? ""
        });
        this.txtIcd10=((postingDetails?.lstClaimInfo[0]?.diagnosisCode10)??[])
        this.provider=postingDetails?.lstClaimInfo[0]?.orderingProvider ?? ""
        this.pos= postingDetails?.lstPostingCharges[0]?.pos ?? ""
        this.ReferringDoctor = postingDetails?.lstClaimInfo[0]?.referringProvider??"";
        this.tblPostReponse = [];
        this.total = response?.billingState?.selectedBilling?.totalFee;
        this.totalItemQty = postingDetails?.saleOrder?.shippingCheckList?.totalItemQty;
        this.totalItemPrice = postingDetails?.saleOrder?.shippingCheckList?.totalItemPrice;
        postingDetails?.lstPostingCharges?.forEach(element => {
          if (element?.postingChargesId !== "00000000-0000-0000-0000-000000000000") {
            const diagonsis9: DiagnosisCode9DTO[] = this.drpDiagnosis9?.filter((e, index) => e?.diagnosisCodeTypeData === element?.diagnosisCode9[index]) ?? [];
            const diagonsis10: DiagnosisCode10DTO[] = this.drpDiagnosis10?.filter((e, index) => e?.diagnosisCodeTypeData === element?.diagnosisCode10[index]) ?? [];
            this.tblPostReponse.push({
              itemId: element?.itemId ?? "00000000-0000-0000-0000-000000000000",
              productId: element?.productId ?? "00000000-0000-0000-0000-000000000000",
              itemSize: element?.itemSize,
              cpt: "",
              dos: element?.dos === null ? "" : new Date(element?.dos)?.toLocaleDateString("en-US") ?? "",
              diagnosis9: diagonsis9,
              diagnosis10: diagonsis10,
              item: element?.item,
              code: element?.code,
              insurance: element?.insurance,
              provider: element?.provider,
              checkNo: element?.checkNo,
              m1: element?.modifier1,
              m2: element?.modifier2,
              m3: element?.modifier3,
              m4: element?.modifier4,
              dx1: element?.dxPointer1,
              dx2: element?.dxPointer2,
              dx3: element?.dxPointer3,
              dx4: element?.dxPointer4,
              pos: element?.pos,
              tos: element?.tos,
              dateFrom: element?.dateFrom,
              dateTo: (element?.dateTo??""),
              fee: element?.fee,
              units: element?.units,
              copay: element?.copay,
              patOther: element?.other,
              total: element?.total,
              document: element?.document,
              statement: element?.statementNote,
              patientResp: element?.patientResp,
              id: element?.postingChargesId,
              orderId: postingDetails?.orderId,
              claimId: postingDetails?.id,
              patientId: postingDetails?.patientId,
              postingId: element?.postingChargesId,
              codes: element?.code,
              ddescription: element?.description,
            });
          }
          this.tblPostReponse[0];
        })
        postingDetails?.lstPostingCharges?.length === 0 && postingDetails?.lstPostingCharges  && this.getPatientData();
        this.isLoading = false;
        this.Table.setPostTable(this.tblPostReponse.reverse());
      }, err => {
        this.isLoading = false;
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      });
    } else {
      this.store.dispatch(new getBilling(this.listClaimId)).subscribe(response => {
        postingDetails = response?.billingState?.selectedBilling;
        this.tblPostReponse = [];
        let original = this.dos;
        this.orderId = postingDetails?.orderId ?? "";

        this.total = response?.billingState?.selectedBilling?.totalFee;
        postingDetails?.lstPostingCharges?.forEach(element => {
          if (element?.postingChargesId !== "00000000-0000-0000-0000-000000000000") {

            this.tblPostReponse.push({
              itemId: element?.itemId ?? "00000000-0000-0000-0000-000000000000",
              productId: element?.productId ?? "00000000-0000-0000-0000-000000000000",
              cpt: "",
              dos: original == null ? "" : new Date(original)?.toLocaleDateString("en-US"),
              diagnosis9: element?.diagnosisCode9,
              diagnosis10: element?.diagnosisCode10,
              code: element?.item,
              insurance: element?.insurance,
              provider: element?.provider,
              checkNo: element?.checkNo,
              m1: element?.modifier1,
              m2: element?.modifier2,
              m3: element?.modifier3,
              m4: element?.modifier4,
              dx1: element?.dxPointer1,
              dx2: element?.dxPointer2,
              dx3: element?.dxPointer3,
              dx4: element?.dxPointer4,
              pos: element?.pos,
              tos: element?.tos,
              dateFrom: element?.dateFrom,
              dateTo: element?.dateTo,
              fee: element?.fee,
              units: element?.units,
              copay: element?.copay,
              patOther: element?.other,
              total: element?.total,
              document: element?.document,
              statement: element?.statementNote,
              patientResp: element?.patientResp,
              id: element?.postingChargesId,
            });
          }
        })
        this.Table.setPostTable(this.tblPostReponse.reverse());
      }, err => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      });
    }


  }
  //! delete table record
  deleteData(id: string) {

    if (this.claimId != null && this.claimId != "") {

      Swal.fire({
        title: 'Are you sure you want to Delete?',
        text: 'You won\'t be able to retrieve this data!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#34c38f',
        cancelButtonColor: '#f46a6a',
        confirmButtonText: 'Yes, delete it!'
      }).then(result => {
        if (result.value) {
          this.deleteSerivice.deletePostingCharges(this.claimId, id).subscribe(response => {
            this.tblPostReponse.forEach(element => {
              if (id == element?.id) {
                let index = this.tblPostReponse.indexOf(element, 0)
                this.tblPostReponse.splice(index, 1)
                // Swal.fire({ title: 'Success', html: 'Deleted Successfully', icon: 'success', timer: 3000, timerProgressBar: true });
                this.toastr.success('Deleted Successfully','Success')
              }
            });
            this.Table.setPostTable(this.tblPostReponse);

          });
        }
      });
    }
    else {

      Swal.fire({
        title: 'Are you sure you want to Delete?',
        text: 'You won\'t be able to retrieve this data!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#34c38f',
        cancelButtonColor: '#f46a6a',
        confirmButtonText: 'Yes, delete it!'
      }).then(result => {
        if (result.value) {
          this.deleteSerivice.deletePostingCharges(this.listClaimId, id).subscribe(response => {
            this.tblPostReponse.forEach(element => {

              if (id == element?.id) {
                let index = this.tblPostReponse.indexOf(element, 0)
                this.tblPostReponse.splice(index, 1)
                // Swal.fire({ title: 'Success', html: 'Deleted Successfully', icon: 'success', timer: 3000, timerProgressBar: true });
                this.toastr.success('Deleted Successfully','Success')
              }
            });
            // this.Table.setPostTable(this.tblPostReponse);
            //this.getPostingTable()
            this.Table.setPostTable(this.tblPostReponse);

          });

        }
      });
    }

  }

  /// view item page
  ViewPosting(data: any) {

    const dialogRef = this.dialog.open(PostingItemPopupComponent, {
      disableClose: true,
      data: { data: data }
    });
    dialogRef.afterClosed().subscribe(() => {
      this.getPostingTable()
    }, err => {
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    });

  }
  provider: string = ''
  ReferringDoctor: string = '';
  clearProvider() {
    this.provider = '';
    this.postingForm.patchValue({ txtProvider: '' });
  }
  clearIcd9() {
    this.txtIcd9 = "";
    this.postingForm.patchValue({ drpDiag9: "" });

  }
  clearIcd10() {
    this.txtIcd10 = [];
    this.postingForm.patchValue({ drpDiag10ID: "" });
  }
  // clear dropdown
  clearCode() {
    this.code = "";
    this.postingForm.patchValue({ drpCode: "" });
  }
  clearDescription() {
    this.description = "";
    this.postingForm.patchValue({ txtDescription: "" });
  }

  clearModifiers1() {
    this.modifiers1 = "";
    this.postingForm.patchValue({ drpModifiers1: "" });
  }
  clearModifiers2() {
    this.modifiers2 = "";
    this.postingForm.patchValue({ drpModifiers2: "" });
  }
  clearModifiers3() {
    this.modifiers3 = "";
    this.postingForm.patchValue({ drpModifiers3: "" });
  }
  clearModifiers4() {
    this.modifiers4 = "";
    this.postingForm.patchValue({ drpModifiers4: "" });
  }

  clearDx1() {
    this.dx1 = "";
    this.postingForm.patchValue({ drpDx1: "" });
  }

  clearDx2() {
    this.dx2 = "";
    this.postingForm.patchValue({ drpDx2: "" });
  }
  clearDx3() {
    this.dx3 = "";
    this.postingForm.patchValue({ drpDx3: "" });
  }
  clearDx4() {
    this.dx4 = "";
    this.postingForm.patchValue({ drpDx4: "" });
  }

  clearPos() {
    this.pos = "";
    this.postingForm.patchValue({ drpPos: "" });
  }
  clearTos() {
    this.tos = "";
    this.postingForm.patchValue({ drpTos: "" });
  }
  clearReferringDoctor() {
    this.ReferringDoctor = '';
    this.postingForm.patchValue({ txtReferringDoctor: '' });

  }
}
export interface itemInterface {
  equipmentBillingCode?: string;
  id?: string;
  itemId?: string;
  name?: string,
  dos?: string;
  location?: string;
  insurance?: string;
  charge?: number;
}
export interface insuranceface {
  id: string;
  policy: string;
  name: string;
  policiesId: string;
  payorLevel: string
}
export interface doctorInterface {
  id: string;
  name: string;
}
