import { ArAgingReportRowsDTO, PatientMasterDropdownDTO } from './../../../../patient/src/app/dropdown-proxy/dropdown-management/dropdowns/dto/patient/models';

import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { BilliingFilterResultDTO } from 'projects/patient/src/app/patient-proxy/patient/dto/models';
import { ReportService } from 'projects/patient/src/app/patient-proxy/patient/report.service';
import Swal from 'sweetalert2';

import { Title } from '@angular/platform-browser';
import { BillingReportRowsDTO } from 'projects/patient/src/app/dropdown-proxy/dropdown-management/dropdowns/dto/patient/models';
import { PatientMasterDropdownService } from 'projects/patient/src/app/dropdown-proxy/dropdown-management/dropdowns/master-dropdown-service/patient-master-dropdown.service';
import { PatientDropdowns } from 'projects/patient/src/app/dropdown-proxy/dropdown-management/dropdowns/patient-dropdowns.enum';
import { SourceMDPatientService } from '../patient-proxy/source-mdpatient/source-mdpatient.service';
import { PagedAndSortedResultRequestDto } from '@abp/ng.core';
import { FilterPatientsDTO, SourceMDPatientsDTO } from '../patient-proxy/source-mdpatient/dto/models';
import { DatePipe } from '@angular/common';
import { PatientSourcemdPopupComponent } from '../patient-sourcemd-popup/patient-sourcemd-popup.component';
import { MatDialog } from '@angular/material/dialog';
import { BillingReportRowName } from '../patient-proxy/patient/enumeration-data/billing-report-row-name.enum';
import { BillingReportColumnName } from '../patient-proxy/patient/enumeration-data/billing-report-column-name.enum';
import { ToastrService } from "ngx-toastr";
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-import-mis-report',
  templateUrl: './import-mis-report.component.html',
  styleUrls: ['./import-mis-report.component.scss'],
  providers: [DatePipe],
})
export class ImportMisReportComponent implements OnInit, OnDestroy {
  el: ElementRef;
  source?: any[] = [];
  configs?: { rows?: string; columns?: string };
  txtRow: BillingReportRowName;
  txtColumn: BillingReportColumnName;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  dataSource: MatTableDataSource<any> = new MatTableDataSource<any>([]);
  public arrDisplayedColumns: string[] = [];
  isLoading: boolean = true;
  scheduledDate: Date;
  drpBillingReport: BillingReportRowsDTO[] = []
  drpARAgingReport: ArAgingReportRowsDTO[] = []
  BillingReportRowName: BillingReportRowName;
  BillingReportColumnName: BillingReportColumnName;
  msiForm: FormGroup
  filteredData: BilliingFilterResultDTO;
  subscription$: Subscription[] = []
  constructor(
    @Inject(ElementRef) el: ElementRef,
    private fb: FormBuilder,
    private title: Title,
    private patientDropdownService: PatientMasterDropdownService,
    private reportService: ReportService,
    private smdPatientService: SourceMDPatientService,
    private datePipe: DatePipe,
    private dialog: MatDialog,
    private toastr: ToastrService,
  ) {
    this.el = el;
  }

  ngOnInit(): void {
    this.title.setTitle("Qsecure | Import Reports");
    this.arrDisplayedColumns = ['name', 'dob', 'insurance', 'CreatedOn'];
    this.msiForm = this.fb.group({
      txtFromDate: new FormControl("", Validators.required),
      txtToDate: new FormControl('', Validators.required)
    })
    this.getTableData();
    const patientDropdownInput: PatientDropdowns[] = [
      PatientDropdowns.BillingReports,
      PatientDropdowns.ArAgingReports
    ]
    const patientDropdownSubscription = this.patientDropdownService.getPatientDropdownsByInput(patientDropdownInput).subscribe((stateResponse) => {
      const reponse: PatientMasterDropdownDTO = stateResponse;

      reponse?.lstBillingReportRows.forEach(ele => {
        if (ele?.rowName === "Insurance" || ele?.rowName === "ItemName" || ele?.rowName === "PatientName") {
          this.drpBillingReport.push({
            creationTime: ele?.creationTime,
            creatorId: ele?.creatorId,
            deleterId: ele?.deleterId,
            deletionTime: ele?.deletionTime,
            id: ele?.id,
            isDeleted: ele?.isDeleted,
            lastModificationTime: ele?.lastModificationTime,
            lastModifierId: ele?.lastModifierId,
            rowName: ele?.rowName,
            shortCodeId: ele?.shortCodeId
          })
        }
      })
      // this.drpBillingReport = reponse?.lstBillingReportRows ?? [];
      // this.drpARAgingReport = reponse?.lstArAgingReportRows ?? [];

      reponse?.lstArAgingReportRows.forEach(ele => {
        if (ele?.rowName === "Insurance" || ele?.rowName === "ItemName" || ele?.rowName === "PatientName") {
          this.drpARAgingReport.push({
            creationTime: ele?.creationTime,
            creatorId: ele?.creatorId,
            deleterId: ele?.deleterId,
            deletionTime: ele?.deletionTime,
            id: ele?.id,
            isDeleted: ele?.isDeleted,
            lastModificationTime: ele?.lastModificationTime,
            lastModifierId: ele?.lastModifierId,
            rowName: ele?.rowName,
            shortCodeId: ele?.shortCodeId
          })
        }
      })
    }, err => {
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
      this.subscription$.push(patientDropdownSubscription)
    });
    const firstDay = new Date(new Date().getFullYear(), new Date().getMonth(), 1);
    const lastDay = new Date(new Date().getFullYear(), new Date().getMonth(), daysInMonth(new Date().getMonth() + 1, new Date().getFullYear()));
    this.msiForm.patchValue({
      txtFromDate: firstDay,
      txtToDate: lastDay
    });
    this.scheduledDate = new Date(this.msiForm.value.txtFromDate);
  }

  ngOnDestroy(): void {
    this.subscription$?.forEach((sub) => {
      sub && sub?.unsubscribe();
    });
  }

  // Add Reports Popup
  openReportsmodal() {
    const dialogRef = this.dialog.open(PatientSourcemdPopupComponent, {
      width: '50%',
      minHeight: '60%',
      height: 'auto'
    });
    const closedialoag = dialogRef.afterClosed().subscribe(() => {
    }, err => {
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    });
    this.subscription$.push(closedialoag);
  }

  //! get default table data
  getTableData() {
    this.isLoading = true;
    let data: any[] = [];
    this.dataSource = new MatTableDataSource([]);
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
    const patientGetList = this.smdPatientService.getList(new PagedAndSortedResultRequestDto).subscribe(resposne => {
      this.isLoading = false;
      data = resposne?.items ?? [];
      setTimeout(() => {
        this.dataSource = new MatTableDataSource(data);
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
      }, 100);
    }, err => {
      this.isLoading = false;
      let error: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: error?.error?.error?.message,
      });
    });
    this.subscription$.push(patientGetList);
  }


/// Table list export
disableExportButton: boolean = true;
exportDataToExcel() {
  const searchFormValue = this.msiForm?.value;
  let txtUplaodedFromDate: string = ((searchFormValue?.txtFromDate ?? null) === "") ? null : (new Date(searchFormValue?.txtFromDate)?.toLocaleDateString("en-US")) ?? null;
  let txtUplaodedToDate: string = ((searchFormValue?.txtToDate ?? null) === "") ? null : (new Date(searchFormValue?.txtToDate)?.toLocaleDateString("en-US")) ?? null;

    let filterData: FilterPatientsDTO = {
      patientName: "",
      insuranceId: '',
      insuranceName: "",
      isActiveInsurance: null,
      verificationStatus: "",
      createdDateFrom: this.datePipe.transform(txtUplaodedFromDate, "yyyy-MM-dd"),
      createdDateTo: this.datePipe.transform(txtUplaodedToDate, "yyyy-MM-dd")
    }

    let tableData: SourceMDPatientsDTO[] = [];
    this.disableExportButton = true;
    const exportSubscription = this.smdPatientService.exportAllSourceMDPatients().subscribe(response => {
      if (response && response?.length !== 0) {
        let filelocation: String = String("data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64," + response);
        let link = document.createElement("a");
        link.download = ("Mis-Report") + ".xlsx";
        link.href = filelocation.toString();
        link.click();
        // Swal.fire({ title: 'Success', html: 'Exported Successfully', icon: 'success', timer: 3000, timerProgressBar: true })
        this.toastr.success('Exported Successfully','Success')
      }
      this.disableExportButton = false;

    }, err => {
      this.disableExportButton = false;

      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    });
    this.subscription$.push(exportSubscription)
  }

  //! get  table data based on from and to dates
  getTableDataBasedOnDates() {
    let fromDate: string = "";
    let toDate: string = '';
    if (this.msiForm.value.txtFromDate !== '' && this.msiForm.value.txtFromDate !== null) {
      fromDate = Intl.DateTimeFormat('en-US')?.format(new Date(this.msiForm.value.txtFromDate)) ?? "";
    }
    if (this.msiForm.value.txtToDate !== '' && this.msiForm.value.txtToDate !== null) {
      toDate = Intl.DateTimeFormat('en-US')?.format(new Date(this.msiForm.value.txtToDate)) ?? "";
    }
    this.isLoading = true;
    let data: any[] = [];
    this.dataSource = new MatTableDataSource([]);
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
    let searchIbput: FilterPatientsDTO = {
      patientName: "",
      insuranceId: "",
      insuranceName: "",
      isActiveInsurance: true,
      verificationStatus: "",
      createdDateFrom: this.datePipe.transform(this.msiForm.value.txtFromDate, "yyyy-MM-dd"),
      createdDateTo: this.datePipe.transform(this.msiForm.value.txtToDate, "yyyy-MM-dd"),
    }
    const filterSubscription = this.smdPatientService.filterPatientsByOFilterPatients(searchIbput).subscribe(resposne => {
      this.isLoading = false;
      data = resposne ?? [];

      this.dataSource = new MatTableDataSource(data);
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
    }, err => {
      this.isLoading = false;
      let error: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: error?.error?.error?.message,
      });
    });
    this.subscription$.push(filterSubscription);
  }

  //! get  table data based on row column names
  getTableBasedOnFilter() {
    this.source = [];
    this.configs = { rows: "", columns: "" };
    this.filteredData = null;
    const getFilterSubscription = this.smdPatientService.getFilteredPatientsReportsByPatientReportRowNameAndPatientReportColumnName(this.txtRow, this.txtColumn).subscribe(response => {
      this.isLoading = false;
      const data: BilliingFilterResultDTO = response;
      this.filteredData = data;
      let datas: { columnName?: string, filters?: any[], rowName?: string } | null = { filters: [] };
      datas.columnName = data?.columnName;
      datas.rowName = data?.rowName;
      this.source = data?.filters?.data;
      this.configs = { rows: this.txtRow, columns: this.txtColumn }
    }, err => {
      this.isLoading = false;
      let error: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: error?.error?.error?.message,
      });
    });
    this.subscription$.push(getFilterSubscription);
  }

  // reset form
  resetForm() {
    this.msiForm.reset();
    this.msiForm.updateValueAndValidity();
    this.msiForm.markAsUntouched();
    const firstDay = new Date(new Date().getFullYear(), new Date().getMonth(), 1);
    const lastDay = new Date(new Date().getFullYear(), new Date().getMonth(), daysInMonth(new Date().getMonth() + 1, new Date().getFullYear()));
    this.msiForm.patchValue({
      txtFromDate: firstDay,
      txtToDate: lastDay,
    });
    this.scheduledDate = new Date(this.msiForm.value.txtFromDate);
    this.getTableData()
  }
}


function daysInMonth(month: number, year: number) {
  return new Date(year, month, 0).getDate();
}
