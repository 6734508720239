<br />
<div class="card card-body">
  <!-- <div class="list-box"> -->
    <div class="row">
      <div class="col-12 mar-top">
        &nbsp; <h4 class="customThemeClass"><b>Frequently Bought Items</b></h4>
      </div>
      <div class="col-12 table-responsive">
        <app-table *ngIf="strPageType1 === 'items'" [isLoading]="isLoading" [strPageType]="strPageType1">
        </app-table>
      </div>
    </div>
    <div class="row">
      <div class="col-6 text-left">
        &nbsp; <h4 class="customThemeClass"><b>Verification</b></h4>
      </div>
      <div class="col-6 text-right">
        <button mat-button class="buttonColor mr-1 font-size-13 btn-sm" (click)="openPolicyModal()">
          <i class="fa fa-plus mr-1" ></i>Add Policy</button>
          <button mat-button class="buttonColor mr-1 font-size-13 btn-sm" (click)="openVerifyModal()">
            <i class="fa fa-plus mr-1" ></i>Add Verification</button>
    </div>
      <div class="col-12 table-responsive">
        <app-table *ngIf="strPageType === 'verification'" [isLoading]="isLoading" (viewButton)="viewVerification($event)" [strPageType]="strPageType">
        </app-table>
      </div>
    </div>

    <!-- <div class="row">
      <div class="col-12 text-right">
          <button mat-button class="buttonColor mr-1 font-size-13 btn-sm" (click)="openPolicyModal()">
            <i class="fa fa-plus mr-1" ></i>Add Policy</button>
      </div>
    </div> -->
</div>
