import type { CreateUpdateVendorDTO, VendorDTO, VendorSearchDTO } from './dto/models';
import { RestService } from '@abp/ng.core';
import type { PagedAndSortedResultRequestDto, PagedResultDto } from '@abp/ng.core';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class VendorService {
  apiName = 'ItemManagement';

  create = (input: CreateUpdateVendorDTO) =>
    this.restService.request<any, VendorDTO>({
      method: 'POST',
      url: '/api/ItemApp/vendor',
      body: input,
    },
     { apiName: this.apiName, skipHandleError: true });

  delete = (id: string) =>
    this.restService.request<any, void>({
      method: 'DELETE',
      url: `/api/ItemApp/vendor/${id}`,
    },
     { apiName: this.apiName, skipHandleError: true });

  get = (id: string) =>
    this.restService.request<any, VendorDTO>({
      method: 'GET',
      url: `/api/ItemApp/vendor/${id}`,
    },
     { apiName: this.apiName, skipHandleError: true });

  getList = (input: PagedAndSortedResultRequestDto) =>
    this.restService.request<any, PagedResultDto<VendorDTO>>({
      method: 'GET',
      url: '/api/ItemApp/vendor',
      params: { skipCount: input.skipCount, maxResultCount: input.maxResultCount, sorting: input.sorting },
    },
     { apiName: this.apiName, skipHandleError: true });

  update = (id: string, input: CreateUpdateVendorDTO) =>
    this.restService.request<any, VendorDTO>({
      method: 'PUT',
      url: `/api/ItemApp/vendor/${id}`,
      body: input,
    },
     { apiName: this.apiName, skipHandleError: true });

  searchVendorByInput = (input: VendorSearchDTO) =>
    this.restService.request<any, PagedResultDto<VendorDTO>>({
      method: 'GET',
      url: '/api/ItemApp/vendor/search-vendor',
      params: { vendorName: input.vendorName, vendorCode: input.vendorCode, contactPersonName: input.contactPersonName, contactNo: input.contactNo, productQuantity: input.productQuantity, createdDate: input.createdDate, status: input.status },
    },
     { apiName: this.apiName, skipHandleError: true });

  constructor(private restService: RestService) {}
}
