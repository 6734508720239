import { Component, OnInit } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { PushNotificationService } from 'projects/admin/src/app/admin-proxy/platform-app-management/rcm/platform-management/push-notification/push-notification.service';
import {  Observable } from 'rxjs';
import { AppNotification } from './app-notification.model';
import { FetchNotifications,  DeleteNotification } from './notifications.actions';
import { NotificationsState } from './notifications.state';
import { NotificationsService } from './notifications.service';
import { CommunicationService } from 'projects/shared/src/app/services/communication.service';
import { Router } from '@angular/router';
@Component({
  selector: 'app-notiifcation',
  templateUrl: './notiifcation.component.html',
  styleUrls: ['./notiifcation.component.scss'],
})
export class NotiifcationComponent implements OnInit {
  @Select(NotificationsState) notifications$: Observable<any>;
  @Select(NotificationsState.unReadCount) unReadCount$: Observable<number>;
  isOpen: boolean;
  userId: string = "";
  swEnabled = false;
  constructor(
    private store: Store,
    private pushNotificationService: PushNotificationService,
    private notificationsService: NotificationsService,
    private communicationService: CommunicationService,
    private router: Router,
  ) {
  }

  ngOnInit() {
    //! get notifications list and count initally
    if (window.Notification.permission === 'granted' || window.Notification.permission === "default") {
      this.swEnabled = true;
    }
    const userId = localStorage?.getItem("userId") ?? "";
    this.userId = userId;
    this.swEnabled && userId && this.store.dispatch(new FetchNotifications(userId));
  }
  // mark notifcations as read
  markAsRead(notification: AppNotification) {
    this.pushNotificationService.updateNotification(notification?.id, !notification?.isViewed).subscribe(respnose => {
      this.store.dispatch(new FetchNotifications(this.userId));
    })
  }
  dismiss(notification: AppNotification, event: { stopPropagation: () => void; }) {
    event.stopPropagation();
    this.store.dispatch(new DeleteNotification(notification));
  }



  toggleDropdown() {

    // this.router.navigate(['/dashboard/'+'Reminder']);
    // this.notificationsService.toggleReminderTab('Reminder');
    this.communicationService.triggerFunctionCallReminder();
    this.isOpen = !this.isOpen;
    const userId = localStorage?.getItem("userId") ?? "";
    userId && this.store.dispatch(new FetchNotifications(userId));
  setTimeout(() => {
    this.onClickOutside()
  }, 5000)
  }

  onClickOutside() {
    this.isOpen = false;
  }


  // track by id - ngfor optimoiztion
  trackById(index: number, item: AppNotification) {
    return item.id;
  }
}
