import { PagedAndSortedResultRequestDto } from '@abp/ng.core';
import { DatePipe } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { DashboardService } from 'projects/admin/src/app/admin-proxy/platform-app-management/rcm/platform-management/dashboard';
import { LeaveTypeDTO } from 'projects/patient/src/app/dropdown-proxy/dropdown-management/dropdowns/dto/on-time-schedule/models';
import { OnTimeMasterDropdownDTO } from 'projects/patient/src/app/dropdown-proxy/rcm/dropdown-management/dropdowns/dto/on-time-schedule/models';
import { OnTimeMasterDropdownService } from 'projects/patient/src/app/dropdown-proxy/rcm/dropdown-management/dropdowns/master-dropdown-service/on-time-master-dropdown.service';
import { OnTimeDropdowns } from 'projects/patient/src/app/dropdown-proxy/rcm/dropdown-management/dropdowns/on-time-dropdowns.enum';
import { DateValidator } from 'projects/shared/src/app/validations/date-validator';
import { delay } from 'rxjs/operators';
import Swal from 'sweetalert2';

import { CreateUpdateLeaveApprovalDTO, SearchLeaveApprovalDTO } from '../onTime-proxy/rcm/on-time-management/on-time-schedule/dto/models';
import { LeaveApprovalStatus } from '../onTime-proxy/rcm/on-time-management/on-time-schedule/leave-approval-status.enum';
import { LeaveApprovalService } from '../onTime-proxy/rcm/on-time-management/on-time-schedule/leave-approval.service';
import { Title } from '@angular/platform-browser';
import { ToastrService } from "ngx-toastr";

@Component({
  selector: 'app-ontime-leave-apply',
  templateUrl: './ontime-leave-apply.component.html',
  styleUrls: ['./ontime-leave-apply.component.scss'],
  providers: [DatePipe]
})
export class OntimeLeaveApplyComponent implements OnInit {
  leaveTypeId: string
  leaveApplyForm: FormGroup;
  leaveApplySearchForm: FormGroup;
  textValidators: any;
  numberValidators: any;
  step: boolean = false;
  dataSource: MatTableDataSource<any>;
  desigation: string;
  displayedColumns: string[] = ['Options','LeaveType', 'FromDate', 'ToDate', 'Comments', 'Status', ];
  isLoading: boolean;
  drpLeaveType: LeaveTypeDTO[] = [];
  vationFieldsHide: boolean = false;
  dayOffFiledsHide: boolean = false;
  leaveApprovalId: string = "";
  saveButtonHide: boolean;
  username: string;
  roleName: string;
  commentsHide: boolean = false;
  leaveApplyTableData: any[] = [];
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatTable, { static: false }) tableRef: MatTable<any>;
  toDateHide: boolean
  totalHide: boolean;
  BirthdateMaxDate: Date;
  BirthdateMinDate: Date;
  isShownSaveButton: boolean = false;
  constructor(
    private formBuilder: FormBuilder,
    private onTimeMasterDropdown: OnTimeMasterDropdownService,
    private leaveApprovalService: LeaveApprovalService,
    private dashboardService: DashboardService,
    protected datepipe: DatePipe,
    private dateValidator: DateValidator,
    public title: Title,
    private toastr: ToastrService,
  ) { }

  ngOnInit(): void {
  this.title.setTitle("Qsecure | Coverage Area");

    this.leaveApplyForm = this.formBuilder.group({
      drpLeaveType: new FormControl("", Validators.required),
      // txtDate: new FormControl(""),
      txtComments: new FormControl("", Validators.required),
      txtFromDate: new FormControl("", Validators.required),
      txtToDate: new FormControl(""),
    })

    this.leaveApplySearchForm = this.formBuilder.group({
      name: new FormControl(""),
      designation: new FormControl(""),
      leaveType: new FormControl(""),
      fromDate: new FormControl("", this.dateValidator.dateVaidator),
      toDate: new FormControl("", this.dateValidator.dateVaidator),
      comments: new FormControl(""),
      status: new FormControl(""),
    });
    //Get Table Search formcontrol values
    this.leaveApplySearchForm.valueChanges.subscribe((value: LeaveApply) => {

      let filter: SearchLeaveApprovalDTO = {
        name: value?.name != null || undefined ? value?.name.trim().toLowerCase() : value?.name,
        designation: value?.designation != null || undefined ? value?.designation.trim().toLowerCase() : value?.designation,
        leaveType: value?.leaveType != null || undefined ? value?.leaveType.trim().toLowerCase() : value?.leaveType,
        fromDate: value?.fromDate,
        toDate: value?.toDate,
        comments: value?.comments != null || undefined ? value?.comments.trim().toLowerCase() : value?.comments,
        status: value?.status != null || undefined ? value?.status.trim().toLowerCase() : value?.status,
      };
      if (this.leaveApplySearchForm.valid) {
        this.dataSource.filter = JSON.stringify(filter);
        this.searchLeaveAppy(filter);
      }
    });
    this.getDropowns();
    this.getTableData();
    this.dashboardService.userProfile().subscribe((response) => {

      this.username = response.userName
      this.roleName = response.roleName

    });
    this.BirthdateMinDate = new Date();
    this.BirthdateMinDate.setFullYear(this.BirthdateMinDate.getFullYear() - 120);
    this.commentsHide = false;

  }

  getControlName(name): any {
    return this.leaveApplySearchForm.get(name);
  }

  ngOnChanges(leaveId) {
    leaveId = this.leaveApprovalId

  }

  getDropowns() {
    let onTimeMasterDropdown: OnTimeDropdowns[];
    onTimeMasterDropdown = [
      OnTimeDropdowns.LeaveType,
    ]
    this.onTimeMasterDropdown.getOnTimeDropdownsByInput(onTimeMasterDropdown).subscribe((stateResponse) => {
      let response: OnTimeMasterDropdownDTO = stateResponse;
      this.drpLeaveType = response.leaveTypes;
    })
  }

  onLeaveChange(leaveId) {
    if (leaveId.value == "Day Off") {
      this.commentsHide = true
      this.vationFieldsHide = false;
      this.dayOffFiledsHide = true;
    }
    else {
      this.commentsHide = true
      this.vationFieldsHide = true
      this.dayOffFiledsHide = false;
    }

  }

  saveLeaveApprovalForm() {
    this.isShownSaveButton = true;
    let leaveApproval: CreateUpdateLeaveApprovalDTO = {
      leaveType: this.leaveApplyForm.value.drpLeaveType,
      fromDate: this.leaveApplyForm.value.txtFromDate,
      toDate: this.leaveApplyForm.value.txtToDate === null || "" ? this.leaveApplyForm.value.txtFromDate : this.leaveApplyForm.value.txtToDate,
      comments: this.leaveApplyForm.value.txtComments,
      name: this.username,
      designation: this.roleName,
      status: LeaveApprovalStatus.Pending,
    };
    if ((this.leaveApprovalId == null || this.leaveApprovalId == "" || this.leaveApprovalId == undefined)) {
      this.leaveApprovalService.create(leaveApproval).subscribe(response => {
        this.toastr.success('Saved Successfully','Success');
        // Swal.fire({ title: 'Success', html: 'Saved Successfully', icon: 'success', timer: 3000, timerProgressBar: true }).then(result => {
        //   if (result.value) {
        //   }
        this.isShownSaveButton = false;
        // }, err => { });
        this.reset();
        this.getTableData();
      }, err => {
        this.saveButtonHide = false;
        this.isShownSaveButton = false;
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      });
    } else {
      this.leaveApprovalService.update(this.leaveApprovalId, leaveApproval).subscribe(response => {
        this.toastr.success('Updated Successfully','Success');
        // Swal.fire({ title: 'Success', html: 'Saved Successfully', icon: 'success', timer: 3000, timerProgressBar: true }).then(result => {
        //   if (result.value) {

        //   }
        this.saveButtonHide = false;
        // }, err => { });
        this.reset();
        this.getTableData();
      }, err => {
        this.saveButtonHide = false;
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      });
    }
  }

  reset() {
    this.leaveApplyForm.patchValue({
      drpLeaveType: "",
      txtDate: "",
      txtComments: "",
      txtFromDate: "",
      txtToDate: "",
      txtVactionComments: ""
    })
    this.commentsHide = false;
    this.vationFieldsHide = false;
    this.commentsHide = false;
    this.leaveApprovalId = '';
    this.leaveApplyForm.reset();
    this.leaveApplyForm.markAsUntouched();
    this.leaveApplyForm.updateValueAndValidity();
  }

  getTableData() {
    this.isLoading = true
    this.leaveApprovalService.getList(new PagedAndSortedResultRequestDto).subscribe(response => {
      this.leaveApplyTableData = [];
      response && response?.items?.forEach((element, tableIndex) => {
        this.leaveApplyTableData.push({
          data: element?.id,
          leaveType: element?.leaveType,
          fromDate: element?.fromDate === null ? "" : this.datepipe.transform(element?.fromDate, 'MM/dd/yyyy') ?? "",
          toDate: element?.toDate === null ? "" : this.datepipe.transform(element?.toDate, 'MM/dd/yyyy') ?? element?.fromDate,
          comments: element?.comments,
          status: element?.status,
          desigation: element?.designation,
          name: element?.name,
          sno: tableIndex + 1,
        });
      })
      const arrTableData = this.leaveApplyTableData;
      this.dataSource = new MatTableDataSource(arrTableData);
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
      this.isLoading = false;
    }, err => {
      this.isLoading = false;
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    })
  }

  //! edit leaveApproval
  ViewLeaveApproval(data) {

    this.step = !this.step;
    this.leaveApprovalId = data.data;

    this.leaveApprovalService.get(this.leaveApprovalId).subscribe(response => {
      if (response.fromDate == response.toDate) {
        this.vationFieldsHide = false;
        // this.dayOffFiledsHide = true;
        this.toDateHide = true;
        this.commentsHide = true;
      } else {
        this.vationFieldsHide = true;
        this.dayOffFiledsHide = false;
        this.toDateHide = true
        this.commentsHide = true;
      }
      this.leaveApplyForm.patchValue({
        drpLeaveType: response?.leaveType,
        txtComments: response?.comments,
        txtFromDate: response?.fromDate,
        txtToDate: response?.toDate
      })

    })
  }

  ///delete leaveApproval
  deleteLeaveApproval(data) {
    Swal.fire({
      title: 'Are you sure you want to Delete?',
      text: 'You won\'t be able to retrieve this data!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#34c38f',
      cancelButtonColor: '#f46a6a',
      confirmButtonText: 'Yes, delete it!'
    }).then(result => {
      if (result.value) {
        this.leaveApprovalService.delete(data.data).subscribe(response => {
          this.leaveApplyTableData.forEach(element => {
            if (data.id == element?.id) {
              let index = this.leaveApplyTableData.indexOf(element, 0)
              this.leaveApplyTableData.splice(index, 1)
            }
          });
          this.getTableData();
        }, err => {
          const data: HttpErrorResponse = err;

          Swal.fire({
            icon: 'info',
            text: data?.error?.error?.message,
          });
        });
      }
    });
    this.reset();
  }

  searchLeaveAppy(filter) {
    this.leaveApplyTableData = [];
    this.leaveApprovalService.searchLeaveApprovalByInput(filter).subscribe(response => {
      response?.items.forEach((element, tableIndex) => {
        this.leaveApplyTableData.push({
          data: element?.id,
          leaveType: element?.leaveType,
          fromDate: element?.fromDate === null ? "" : this.datepipe.transform(element?.fromDate, 'MM/dd/yyyy') ?? "",
          toDate: element?.toDate === null ? "" : this.datepipe.transform(element?.toDate, 'MM/dd/yyyy') ?? element?.fromDate,
          comments: element?.comments,
          status: element?.status,
          desigation: element?.designation,
          name: element?.name,
          sno: tableIndex + 1,
        });
      })
      this.dataSource = new MatTableDataSource(this.leaveApplyTableData);
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
    })
  }

}

export interface LeaveApply {
  name: string,
  designation: string,
  leaveType: string,
  fromDate: string,
  toDate: string,
  comments: string,
  status: string
}
