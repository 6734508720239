import { RestService } from '@abp/ng.core';
import { Injectable } from '@angular/core';

import type { CreateUpdateReminderDTO, ReminderDTO } from './dto/models';
import type { PagedAndSortedResultRequestDto, PagedResultDto } from '@abp/ng.core';
@Injectable({
  providedIn: 'root',
})
export class ReminderService {
  apiName = 'orderManagement';

  create = (input: CreateUpdateReminderDTO) =>
    this.restService.request<any, ReminderDTO>({
      method: 'POST',
      url: '/api/orderApp/reminder',
      body: input,
    },
     { apiName: this.apiName, skipHandleError: true });

  delete = (id: string) =>
    this.restService.request<any, void>({
      method: 'DELETE',
      url: `/api/orderApp/reminder/${id}`,
    },
     { apiName: this.apiName, skipHandleError: true });

  get = (id: string) =>
    this.restService.request<any, ReminderDTO>({
      method: 'GET',
      url: `/api/orderApp/reminder/${id}`,
    },
     { apiName: this.apiName, skipHandleError: true });

  getList = (input: PagedAndSortedResultRequestDto) =>
    this.restService.request<any, PagedResultDto<ReminderDTO>>({
      method: 'GET',
      url: '/api/orderApp/reminder',
      params: { skipCount: input.skipCount, maxResultCount: input.maxResultCount, sorting: input.sorting },
    },
     { apiName: this.apiName, skipHandleError: true });

  getRemaindersBasedOnOrganizationByOrganizationUnitId = (organizationUnitId: string) =>
    this.restService.request<any, ReminderDTO[]>({
      method: 'GET',
      url: `/api/orderApp/reminder/remainders-based-on-organization/${organizationUnitId}`,
    },
     { apiName: this.apiName, skipHandleError: true });

  update = (id: string, input: CreateUpdateReminderDTO) =>
    this.restService.request<any, ReminderDTO>({
      method: 'PUT',
      url: `/api/orderApp/reminder/${id}`,
      body: input,
    },
     { apiName: this.apiName, skipHandleError: true });

  constructor(private restService: RestService) {}
}
