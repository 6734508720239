<div class="row">
    <div class="col-lg-12">
        <h2>Return Update</h2>
    </div>
</div>
<mat-dialog-content class="mat-typography">
    <div class="row">
        <div class="col-lg-12">
            <mat-card-content>
                <form [formGroup]="returnForm">
                    <div class="row">
                        <div class="col-lg-12">


                            <mat-form-field class="col-12">
                                <mat-label>Comments <span class="asterisk">*</span></mat-label>
                                <textarea formControlName="txtComments" rows="3" matInput maxlength="400"></textarea>

                            </mat-form-field>
                        </div>
                    </div>
                </form>
            </mat-card-content>
        </div>
    </div>
</mat-dialog-content>

<mat-dialog-actions align="end">
    <button mat-button class="buttonColor" (click)="saveReturn()">Save
    </button>

    <button class="buttonCancelColor" mat-button mat-dialog-close>Close</button>

</mat-dialog-actions>