<div class="card card-body">
  <div class="row">
    <div class="col-lg-12">
      <mat-card-content>
        <div class="row mar">
          <div class="col-12 text-right">
            <button mat-button class="buttonColor font-size-14" (click)="redirect()">
              <i class="fa fa-plus mr-1"></i>
              Add Pickup and Exchange item
            </button>
          </div>
        </div>
      </mat-card-content>
    </div>
    <div class="col-12 table-responsive" style="padding-top: 6px">
      <table class="table table-bordered">
        <thead>
          <th>Item Name</th>
          <th>Item code</th>
          <th>Type</th>
          <th>Units</th>
          <th>Created Date</th>
          <th>Reason</th>
          <th>Options</th>
        </thead>
        <tbody>

          <tr *ngFor="let record of tblItemReponse; let i = index">
            <td>
              {{ record?.itemName }}
            </td>
            <td>
              {{ record?.itemCode }}
            </td>
            <td>
              <div [ngSwitch]="record?.pickupType">
                <div *ngSwitchCase="'ItemReturn'">Return</div>
                <div *ngSwitchCase="'ItemExchange'">Exchange</div>
                <div *ngSwitchDefault></div>
              </div>
            </td>
            <td>
              {{ +record?.quantity }}
            </td>
            <td>{{ record.initiatedDate | date : "MM/dd/yyyy" : "en_US" }}</td>
            <td>{{ record.pickupReason }}</td>
            <td>
              <a class="deleteBtnColor">
                <i matTooltip="Click to Delete" (click)="deletePickupExchangeItem(record?.exchangeReturnItemid)"
                  class="fa fa-trash text-danger"></i>
              </a>
            </td>
          </tr>
        </tbody>
        <tfoot>
          <ng-container *ngIf="tblItemReponse && tblItemReponse?.length === 0 && !isLoading">
            <td class="text-align-center" colspan="12">No Records Found</td>
          </ng-container>
          <ng-container *ngIf="isLoading">
            <td class="text-align-center" colspan="12">
              <mat-spinner [diameter]="220"></mat-spinner>
            </td>
          </ng-container>
        </tfoot>
      </table>
    </div>
  </div>
</div>
