<br />
<div class="card card-body">
    <div class="row mt-2">
        <div class="col-6">
            <h4 class="customThemeClass mar-top"><b>Reminders</b></h4>
        </div>
        <div class="col-6 text-right">
            <button mat-button [disabled]="!editFormValues" class="buttonColor font-size-14" (click)="viewClick(null)">
              <i class="fa fa-plus mr-1"></i>
              Add Reminder
            </button>
          </div>
    </div>
    <app-shared-table [arrDisplayedColumns]="arrDisplayedColumns" [crudPermisson]="crudPermisson"
    [strPageType]="strPageType"  [isLoading]="isLoading"
    (viewClick)="viewClick($event)" (deleteClick)="deleteClick($event)"></app-shared-table>
</div>
