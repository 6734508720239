import { Component, HostListener, OnInit, ViewChildren } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { OAuthService } from 'angular-oauth2-oidc';
import { ReplaySubject, Subscription, timer } from 'rxjs';
import { map, switchMap, takeWhile, tap } from 'rxjs/operators';
import { EmailOTPControllerService } from '../admin-proxy/platform-app-management/rcm/platform-management/sorting/emailOTPController.service';
import Swal from 'sweetalert2';
import { HttpErrorResponse } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from '../admin-proxy/platform-app-management/rcm/platform-management/user';

@Component({
  selector: 'app-two-factor-authentication',
  templateUrl: './two-factor-authentication.component.html',
  styleUrls: ['./two-factor-authentication.component.scss']
})
export class TwoFactorAuthenticationComponent implements OnInit {

  @ViewChildren('formRow') rows: any;
  twoFactorAuthenticationForm:FormGroup ;
  formInput = ['input1', 'input2', 'input3', 'input4', 'input5', 'input6'];
  enableResend=false;
  resendOTPCount:number=0;
  verifyAttamptCount:number=0;
  private duration$ = new ReplaySubject<number>(2);
  display:any;
  showErrorMsg:any;
  encryptyValue:string='';
  subscription$: Subscription[] = [];
  userId: string;
  tenantId:string;
  email:string;
  constructor(
    private oauthService: OAuthService,
    private emailOTPControllerService:EmailOTPControllerService,
    private toastr: ToastrService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private userService: UserService,
  ) {
    this.twoFactorAuthenticationForm = this.toFormGroup(this.formInput);
    this.timer(2);
   }
   @HostListener('window:beforeunload', ['$event'])
   unloadNotification($event: any): void {
    // $event.preventDefault();
    // $event.returnValue = true;
   }

  ngOnInit() {
    const activateRoute = this.activatedRoute.paramMap.subscribe(
      (response) => {
         this.userId = response?.get('userId') ?? '';
         this.tenantId = response?.get('tenantId') ?? '';
      },
      (err) => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      }
    );
    this.subscription$.push(activateRoute);
    this.getEmailAddress();

  }
  toFormGroup(elements) {
    const group: any = {};

    elements.forEach(key => {
      group[key] = new FormControl('', Validators.required);
    });
    return new FormGroup(group);
  }
  keyUpEvent(event, index) {
    let pos = index;
    if (event.keyCode === 8 && event.which === 8) {
      pos = index - 1 ;
    } else {
      const isNumber = /[0-9]/.test(event.key);
      if (!isNumber) {
        event.preventDefault(); // Prevent the input if the key is not a number
      }else{
        pos = index + 1 ;
      }
    }
    if (pos > -1 && pos < this.formInput.length ) {
      this.rows._results[pos].nativeElement.focus();
    }

  }
  timer(minute) {
    // let minute = 1;
    let seconds: number = minute * 60;
    let textSec: any = "0";
    let statSec: number = 60;

    const prefix = minute < 10 ? "0" : "";

    const timer = setInterval(() => {
      seconds--;
      if (statSec != 0) statSec--;
      else statSec = 59;

      if (statSec < 10) {
        textSec = "0" + statSec;
      } else textSec = statSec;

      this.display = `${prefix}${Math.floor(seconds / 60)}:${textSec}`;

      if (seconds == 0) {
        this.resendOTPCount =this.resendOTPCount+1
        this.enableResend=true;
        clearInterval(timer);
      }
    }, 1000);
  }
  resendOTP(){
    this.showErrorMsg='';
    if(this.resendOTPCount>=4){
      this.oauthService.loadDiscoveryDocumentAndLogin();
    }else{
   this.emailOTPControllerService.twoAuthOTPMailSend(this.email,false)
   .subscribe(
     (response) => {
    this.enableResend=false;
        this.timer(2);
        this.showErrorMsg='OTP has been send to your registered Email Address'
     },
     (error) => {
       const data: HttpErrorResponse = error;
       Swal.fire({
         icon: 'info',
         text: data.error,
       });
     }
   );
    }

  }
  //back to login
  backToLogin() {
    this.oauthService.loadDiscoveryDocumentAndLogin();
  }
  verifyOTP(){
    this.verifyAttamptCount=this.verifyAttamptCount+1 ;
    const otp = this.twoFactorAuthenticationForm.value.input1 + this.twoFactorAuthenticationForm.value.input2
     + this.twoFactorAuthenticationForm.value.input3 + this.twoFactorAuthenticationForm.value.input4
     + this.twoFactorAuthenticationForm.value.input5 + this.twoFactorAuthenticationForm.value.input6;
    this.emailOTPControllerService.twoAuthOTPVerify(otp)
    .subscribe(
      (response) => {
          // this.toastr.success('Successfully Send Mail', 'Success');
     this.router.navigate(['/dashboard']);
      },
      (error) => {
        const data: HttpErrorResponse = error;
       if(this.verifyAttamptCount>=4){
           this.showErrorMsg=data.error
       }else{

        Swal.fire({
          icon: 'info',
          text: data.error,
        });
      }
      }
    );
  }
  getEmailAddress(){
    this.userService.getUserById(this.tenantId,this.userId).subscribe(
      (response) => {
       this.email = response.email;
      },
      (err) => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      }
    );
  }
}
