import { mapEnumToOptions } from '@abp/ng.core';

export enum claimStatus {
  all = 'all',
  claimProcessed = 'claimProcessed',
  claimDenied = 'claimDenied',
  readyToProcess = 'readyToProcess',
  claimCompleted = 'claimCompleted',
  postingCompleted = 'postingCompleted',
  primaryPostingPending = 'primaryPostingPending',
  secondaryPostingPending = 'secondaryPostingPending',
  tertiaryPostingPending = 'tertiaryPostingPending',
  eobPending = 'eobPending',
}

export const claimStatusOptions = mapEnumToOptions(claimStatus);
