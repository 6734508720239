<div class="row">
    <div class="col-lg-6">
      <h2 class="customThemeClass">Edit Claim</h2>
    </div>
  </div>
  <mat-dialog-content class="mat-typography"><br>
   <div class="row">
      <div class="col-12">
        <h4 class="customThemeClass"><b>Transactions</b></h4>
      </div>
    </div>
    <app-table [strPageType]="strPageType">  </app-table>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button class="buttonCancelColor" mat-button mat-dialog-close>Close</button>
    <!-- <button mat-button [mat-dialog-close]="true" cdkFocusInitial>Install</button> -->
  </mat-dialog-actions>
  