import type { AddPlanDTO, CreateUpdateAddPlanDTO } from './dto/models';
import { RestService } from '@abp/ng.core';
import type { PagedAndSortedResultRequestDto, PagedResultDto } from '@abp/ng.core';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class AddPlanService {
  apiName = 'Default';

  create = (input: CreateUpdateAddPlanDTO) =>
    this.restService.request<any, AddPlanDTO>({
      method: 'POST',
      url: '/api/app/add-plan',
      body: input,
    },
     { apiName: this.apiName, skipHandleError: true });

  delete = (id: string) =>
    this.restService.request<any, void>({
      method: 'DELETE',
      url: `/api/app/add-plan/${id}`,
    },
     { apiName: this.apiName, skipHandleError: true });

  get = (id: string) =>
    this.restService.request<any, AddPlanDTO>({
      method: 'GET',
      url: `/api/app/add-plan/${id}`,
    },
     { apiName: this.apiName, skipHandleError: true });

  getList = (input: PagedAndSortedResultRequestDto) =>
    this.restService.request<any, PagedResultDto<AddPlanDTO>>({
      method: 'GET',
      url: '/api/app/add-plan',
      params: { skipCount: input.skipCount, maxResultCount: input.maxResultCount, sorting: input.sorting },
    },
     { apiName: this.apiName, skipHandleError: true });

  getPlanBasedOnOrganizationByOrganizationUnitId = (organizationUnitId: string) =>
    this.restService.request<any, AddPlanDTO[]>({
      method: 'GET',
      url: `/api/app/add-plan/plan-based-on-organization/${organizationUnitId}`,
    },
     { apiName: this.apiName, skipHandleError: true });

  update = (id: string, input: CreateUpdateAddPlanDTO) =>
    this.restService.request<any, AddPlanDTO>({
      method: 'PUT',
      url: `/api/app/add-plan/${id}`,
      body: input,
    },
     { apiName: this.apiName, skipHandleError: true });

  constructor(private restService: RestService) {}
}
