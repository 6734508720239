import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import emailMask from 'text-mask-addons/dist/emailMask';
import { PracticeManagementService } from '../patient-proxy/patient/practice-management';
import { ToastrService } from 'ngx-toastr';
import { HttpErrorResponse } from '@angular/common/http';
import Swal from 'sweetalert2';
import { CreateUpdateRequirementsDTO } from '../patient-proxy/patient/practice-management/dto';
import { noWhitespaceValidator } from '../add-note-tab/add-note-tab.component';

@Component({
  selector: 'app-practice-management-requirements',
  templateUrl: './practice-management-requirements.component.html',
  styleUrls: ['./practice-management-requirements.component.scss']
})
export class PracticeManagementRequirementsComponent implements OnInit {
  @Input() sessionPracticeId: any;
  practiceId: string;
  emailMask: any;
  isShowSpinner: boolean = false;
  ischeckPracticeId: boolean;
  requirementForm: FormGroup;
  saveButtonDisabled = true;
  // ProductTypeControl=new FormControl();
  constructor(
    private fb: FormBuilder,
    private practiceManagementService: PracticeManagementService,
    private toastr: ToastrService,
  ) { }

  ngOnInit(): void {

    this.requirementForm = this.fb.group({

      medicareidtf: new FormControl(''),
      outsource: new FormControl(''),
      modemRequires: new FormControl(''),
      unitcompliance: new FormControl(''),

      txtduration: new FormControl(''),
      txtRequirementName: new FormControl(''),
      txtRequirementEmail: new FormControl(''),
      txtRequirementFaxNo: new FormControl(''),
      // drpProductBrand: new FormControl(''),
      txtProductBrand: new FormControl(''),
      txtPocName: new FormControl(''),
      txtPocEmail: new FormControl(''),
      txtPocFaxNo: new FormControl(''),
      txtPcoPhoneNo: new FormControl(''),
      txtPreference: new FormControl(''),
    });
    this.requirementForm.valueChanges.subscribe(() => {
      const hasValue = Object.values(this.requirementForm.controls).some(control => control.value);
      this.saveButtonDisabled = !hasValue;
    });

    this.emailMask = emailMask;

    this.practiceId = this.sessionPracticeId;
    if (this.practiceId) {
      this.getRequirementsById();
      this.requirementForm.enable();
    } else {
      this.ischeckPracticeId = true;
      this.requirementForm.disable();
    }
  }

  getRequirementsById() {
    this.practiceManagementService.getRequirementInfoByPracticeIdByPracticeId(this.practiceId).subscribe(response => {
      this.requirementForm.patchValue({
        //Requirement Info
        modemRequires: response[0].modemRequired,
        medicareidtf: response[0].medicareIDTF,
        outsource: response[0].outSourceReferrals,
        unitcompliance: response[0].complianceUntilMet,

        txtRequirementName: response[0].requirementUserName,
        txtRequirementEmail: response[0].requirementUserEmail || '',
        txtRequirementFaxNo: response[0].requirementFax,
        txtProductBrand: response[0].preferredBrandName,

        //POC Details
        // txtduration: response[0].durationHours,
        txtPocName: response[0].pocName,
        txtPocEmail: response[0].pocEmail,
        txtPocFaxNo: response[0].pocFax,
        txtPcoPhoneNo: response[0].pocPhone,
        txtPreference: response[0].otherPreferences,
      });
      if (response[0].durationHours !== 0) {
        this.requirementForm.patchValue({
          txtduration: response[0].durationHours,
        });
      }
      this.requirementForm.markAsDirty();
    }, err => {
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    })
  }

  resetRequirement() {
    this.requirementForm.reset();
  }

  saveRequirements() {
    this.isShowSpinner = true;
    const requirementInput: CreateUpdateRequirementsDTO = {
      modemRequired: this.requirementForm.value.modemRequires ?? '',
      medicareIDTF: this.requirementForm.value.medicareidtf ?? '',
      outSourceReferrals: this.requirementForm.value.outsource ?? '',
      complianceUntilMet: this.requirementForm.value.unitcompliance ?? '',
      otherPreferences: this.requirementForm.value.txtPreference ?? '',
      durationHours: this.requirementForm.value.txtduration || 0,
      requirementUserName: this.requirementForm.value.txtRequirementName ?? '',
      requirementUserEmail: this.requirementForm.value.txtRequirementEmail.toLowerCase() ?? '',
      requirementFax: this.requirementForm.value.txtRequirementFaxNo ?? '',
      pocName: this.requirementForm.value.txtPocName ?? '',
      pocEmail: this.requirementForm.value.txtPocEmail.toLowerCase() ?? '',
      pocFax: this.requirementForm.value.txtPocFaxNo ?? '',
      pocPhone: this.requirementForm.value.txtPcoPhoneNo ?? '',
      preferredBrandName: this.requirementForm.value.txtProductBrand ?? '',
    };
    //Update
    this.practiceManagementService.updateRequirementInfoByPracticeIdAndInput(this.practiceId, requirementInput).subscribe(
      (response) => {
        this.toastr.success('Update Successfully!', 'Success');
        this.isShowSpinner = false;
        // this.requirementForm.reset();
      },
      (err) => {
        this.isShowSpinner = false;
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      }
    );
  }
}
