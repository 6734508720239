import type { CoverageAreaDTO, CreateUpdateCoverageAreaDTO, SearchCoverageAreaDTO } from './dto/models';
import { RestService } from '@abp/ng.core';
import type { PagedAndSortedResultRequestDto, PagedResultDto } from '@abp/ng.core';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class CoverageAreaService {
  apiName = 'OnTimeManagement';

  create = (input: CreateUpdateCoverageAreaDTO) =>
    this.restService.request<any, CoverageAreaDTO>({
      method: 'POST',
      url: '/api/OnTimeApp/coverage-area',
      body: input,
    },
     { apiName: this.apiName, skipHandleError: true });

  delete = (id: string) =>
    this.restService.request<any, void>({
      method: 'DELETE',
      url: `/api/OnTimeApp/coverage-area/${id}`,
    },
     { apiName: this.apiName, skipHandleError: true });

  get = (id: string) =>
    this.restService.request<any, CoverageAreaDTO>({
      method: 'GET',
      url: `/api/OnTimeApp/coverage-area/${id}`,
    },
     { apiName: this.apiName, skipHandleError: true });

  getList = (input: PagedAndSortedResultRequestDto) =>
    this.restService.request<any, PagedResultDto<CoverageAreaDTO>>({
      method: 'GET',
      url: '/api/OnTimeApp/coverage-area',
      params: { skipCount: input.skipCount, maxResultCount: input.maxResultCount, sorting: input.sorting },
    },
     { apiName: this.apiName, skipHandleError: true });

  update = (id: string, input: CreateUpdateCoverageAreaDTO) =>
    this.restService.request<any, CoverageAreaDTO>({
      method: 'PUT',
      url: `/api/OnTimeApp/coverage-area/${id}`,
      body: input,
    },
     { apiName: this.apiName, skipHandleError: true });

  searchCoverageAreaByInput = (input: SearchCoverageAreaDTO) =>
    this.restService.request<any, PagedResultDto<CoverageAreaDTO>>({
      method: 'GET',
      url: '/api/OnTimeApp/coverage-area/search-coverage-area',
      params: { coverageAreaName: input.coverageAreaName, squareMile: input.squareMile, userDetails: input.userDetails, status: input.status, createdDate: input.createdDate },
    },
     { apiName: this.apiName, skipHandleError: true });

  constructor(private restService: RestService) {}
}
