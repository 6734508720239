import { Component, Inject, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { ClaimFormDetailsService } from '../../../claim-proxy/claim-processing-management/rcm/billing-management/billing/claim-form-details.service';
import { ToastrService } from 'ngx-toastr';
import { ClaimItemHcpcCode, claimItemDetailsDTO, claimRecoopAmountDTO } from '../../../claim-proxy/claim-processing-management/rcm/billing-management/dto/models';
import { Observable, Subscription } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { ClaimProcessingService } from '../../../claim-proxy/claim-processing-management/rcm/billing-management/billing/claim-processing.service';
import Swal from 'sweetalert2';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-insurance-recoop',
  templateUrl: './insurance-recoop.component.html',
  styleUrls: ['./insurance-recoop.component.scss'],
})
export class InsuranceRecoopComponent implements OnInit {
  InsuranceRecoopForm: FormGroup;
  isSaveInsuranceRecoop: boolean = false;
  SaveInsuranceRecoopAPICall: any;
  claimRecoopAmount_DTO:claimRecoopAmountDTO;
  isLoadingHCPC: boolean = false;
  private loadHCPCCodeListAPICall: Subscription;
  filteredtxtItemId_HCPCCode: Observable<ClaimItemHcpcCode[]>;
  lstHCPCList: ClaimItemHcpcCode[];
  isGetInsuranceRecoop: boolean = false;
  private GetInsuranceRecoopAPICall: Subscription;
  ltItems: claimItemDetailsDTO[]=[];
  dtRecoopAmount: number;
  gOrderItemId: string;
  isOnChange: boolean=true;
 warningMsg:string='';
  constructor(
    private claimFormDetailsService: ClaimFormDetailsService,
    private claimProcessingService: ClaimProcessingService,
    private formBuilder: FormBuilder,
    private toaster: ToastrService,
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      cliamData: any;
    } = {
      cliamData: [],
    }
  ) {}

  ngOnInit(): void {
    this.intializeForms();
    this.GetInsuranceRecoop();
  }

  intializeForms() {
    this.InsuranceRecoopForm = this.formBuilder.group({
      txtbilledAmount: new FormControl('', [Validators.required]),
      txtInsuranceAmount: new FormControl(''),
      txtRecoopAmount: new FormControl('', [Validators.required]),
      sltItemId_HCPCCode: new FormControl('', [Validators.required]),
      sltItemId_HCPCCodeFilter: new FormControl(''),
    });
  }
 showWarningMsg(msg:string){
  this.warningMsg=msg;
 }
  sanitizeInput(event: any, controlName: string) {
    const inputValue = event.target.value;
    const sanitizedValue = inputValue.replace(/[^\d.]/g, '');
    this.InsuranceRecoopForm.get(controlName)?.setValue(sanitizedValue);
  }

  loadHCPCCode() {
    this.isLoadingHCPC = true;
    try {
      if (this.loadHCPCCodeListAPICall) {
        this.loadHCPCCodeListAPICall.unsubscribe();
      }
      this.loadHCPCCodeListAPICall = this.claimProcessingService
        .getClaimItemHCPCListByGClaimId(
          this.data.cliamData.claimId
        )
        .subscribe(
          (response) => {
            this.lstHCPCList = response;
            this.filteredtxtItemId_HCPCCode = this.InsuranceRecoopForm.get(
              'sltItemId_HCPCCodeFilter'
            ).valueChanges.pipe(
              startWith(''),
              map((value) =>
                this.lstHCPCList?.filter((option) =>
                  option?.hcpcCode
                    ?.toLowerCase()
                    ?.includes(value?.toLowerCase() ?? '')
                )
              )
            );

            setTimeout(() => {
              this.isLoadingHCPC = false; // Enable the checkbox
            }, 100);
          },
          (error) => {
            console.error('Error:', error);
            setTimeout(() => {
              this.isLoadingHCPC = false; // Enable the checkbox
            }, 100);
          }
        );
    } catch (error) {
      // this.reloadTable.emit('Mani');
      console.error('Unexpected error:', error);
      setTimeout(() => {
        this.isLoadingHCPC = false; // Enable the checkbox
      }, 100);
    } finally {
    }
  }

  PatchReCopeAmount() {
    this.isOnChange=false
    const index = this.ltItems.findIndex(
      (obj) => obj.gOrderItemId === this.InsuranceRecoopForm.value.sltItemId_HCPCCode.gOrderItemId
    );
    if (index !== -1) {
      this.InsuranceRecoopForm?.patchValue({
        txtRecoopAmount: this.ltItems[index]?.recoopAmount || 0
      });
    }else{
      this.InsuranceRecoopForm?.patchValue({
        txtRecoopAmount: 0
      });
      this.showWarningMsg('The Previous Recoup Details have not found ')
    }
  }

  resetInsuranceRecoop() {
    // this.InsuranceRecoopForm.reset();
    this.InsuranceRecoopForm.patchValue({
      sltItemId_HCPCCode: '',
      txtRecoopAmount: null
    });
    this.warningMsg='';
  }

  GetInsuranceRecoop() {
    this.isGetInsuranceRecoop = true;
    try {
      if (this.GetInsuranceRecoopAPICall) {
        this.GetInsuranceRecoopAPICall.unsubscribe();
      }
      let gClaimId: string=this.data.cliamData.claimId;
      this.GetInsuranceRecoopAPICall = this.claimFormDetailsService
        .recoopAmountDetailsByGClaimId(gClaimId)
        .subscribe(
          (response) => {
            // this.claimRecoopAmount_DTO=response;
            if(response){
              this.InsuranceRecoopForm?.patchValue({
                txtbilledAmount: response?.billedAmount || 0,
                txtInsuranceAmount: response?.insurancePaid || 0,
              });

              this.ltItems=response?.ltItems || [];

              this.filteredtxtItemId_HCPCCode = this.InsuranceRecoopForm.get(
                'sltItemId_HCPCCodeFilter'
              ).valueChanges.pipe(
                startWith(''),
                map((value) =>
                  this.ltItems?.filter((option) =>
                    option?.hcpcCode
                      ?.toLowerCase()
                      ?.includes(value?.toLowerCase() ?? '')
                  )
                )
              );



              // this.loadHCPCCode();
            }
            // this.toaster.success(
            //   'The manual Process has been completed.',
            //   'Success'
            // );
          },
          (error) => {
            console.error(error);
            setTimeout(() => {
              this.isGetInsuranceRecoop = false; // Enable the checkbox
            }, 1000);
          },
        );
    } catch (error) {
      console.error(error);
      setTimeout(() => {
        this.isGetInsuranceRecoop = false; // Enable the checkbox
      }, 1000);
    } finally {
    }
  }

  saveInsuranceRecoop() {
    let lgOrderItemId=this.InsuranceRecoopForm.value.sltItemId_HCPCCodeFilter;
    let ldtRecoopAmount=this.InsuranceRecoopForm.value.txtRecoopAmount;
    if(this.gOrderItemId === lgOrderItemId && this.dtRecoopAmount === ldtRecoopAmount){
      this.showWarningMsg(
        'The Recoup Amount has already been updated.'
      );
    }else{
      this.isSaveInsuranceRecoop = true;
      try {
        if (this.SaveInsuranceRecoopAPICall) {
          this.SaveInsuranceRecoopAPICall.unsubscribe();
        }
        let gClaimId: string;
        gClaimId=this.data.cliamData.claimId;
        this.dtRecoopAmount=ldtRecoopAmount;
        this.gOrderItemId=lgOrderItemId;
        this.SaveInsuranceRecoopAPICall = this.claimFormDetailsService
          .updateClaimRecoopAmountByGClaimIdAndDtRecoopAmountAndGOrderItemId(gClaimId,this.dtRecoopAmount,this.gOrderItemId)
          .subscribe(
            (response) => {
              this.toaster.success(
                'The Recoup Amount has been updated.',
                'Success'
              );
              this.warningMsg=null;
              setTimeout(() => {
                this.isSaveInsuranceRecoop = false;
              }, 1000);
              this.dialog.closeAll();
            },
            (error) => {
              const data: HttpErrorResponse = error;
                   if(data?.error?.text !=="Updated Successfully") {
                   Swal.fire({
                     icon: 'error',
                     text: data?.error?.error?.message,
                   });
                 }else{
                  this.toaster.success(
                    'The Recoup Amount has been updated.',
                    'Success'
                  );
                  this.warningMsg=null;
                  setTimeout(() => {
                    this.isSaveInsuranceRecoop = false;
                  }, 1000);
                  this.dialog.closeAll();
                 }
              setTimeout(() => {
                this.isSaveInsuranceRecoop = false;
              }, 1000);
            },
          );
      } catch (error) {
              const data: HttpErrorResponse = error;
              if(data?.error?.text !=="Updated Successfully") {
                   Swal.fire({
                     icon: 'error',
                     text: data?.error?.error?.message,
                   });
                 }else{
                  this.toaster.success(
                    'The Recoup Amount has been updated.',
                    'Success'
                  );
                  this.warningMsg=null;
                  setTimeout(() => {
                    this.isSaveInsuranceRecoop = false;
                  }, 1000);
                  this.dialog.closeAll();
                 }
        setTimeout(() => {
          this.isSaveInsuranceRecoop = false;
        }, 1000);
      } finally {
      }
    }

  }
}
