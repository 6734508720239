import { ListService, PagedAndSortedResultRequestDto } from '@abp/ng.core';
import { SelectionModel } from '@angular/cdk/collections';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource, MatTable } from '@angular/material/table';
import { Title } from '@angular/platform-browser';
import { itemInterface } from 'projects/order/src/app/add-item/add-item.component';
import { MasterProviderDTO } from 'projects/order/src/app/order-proxy/order-management/inventory/dto/models';
import { ItemEligibilityRulesService } from 'projects/order/src/app/order-proxy/order-management/inventory/item-eligibility-rules.service';
import { MasterProviderService } from 'projects/order/src/app/order-proxy/order-management/inventory/master-provider.service';
import { PersonalDTO } from 'projects/order/src/app/order-proxy/patient/dto';
import { BillingDropdowns } from 'projects/patient/src/app/dropdown-proxy/dropdown-management/dropdowns/billing-dropdowns.enum';
import {
  BillingMasterDropdownDTO,
  ClearingHouseTypeDTO,
} from 'projects/patient/src/app/dropdown-proxy/dropdown-management/dropdowns/dto/billing/models';
import {
  BillingTypeDTO,
  ChartDTO,
  OrderMasterDropdownDTO,
  PayablePercentDTO,
  PreparedByDTO,
  TypeOfPlanDTO,
  VerifiedByDTO,
} from 'projects/patient/src/app/dropdown-proxy/dropdown-management/dropdowns/dto/order/models';
import {
  DiagnosisCode10DTO,
  DiagnosisCode9DTO,
  FacilityDTO,
  PatientMasterDropdownDTO,
  RelationShipWithoutSelfDTO,
  RelationShipWithSelfDTO,
} from 'projects/patient/src/app/dropdown-proxy/dropdown-management/dropdowns/dto/patient/models';
import { BillingMasterDropdownService } from 'projects/patient/src/app/dropdown-proxy/dropdown-management/dropdowns/master-dropdown-service/billing-master-dropdown.service';
import { OrderMasterDropdownService } from 'projects/patient/src/app/dropdown-proxy/dropdown-management/dropdowns/master-dropdown-service/order-master-dropdown.service';
import { PatientMasterDropdownService } from 'projects/patient/src/app/dropdown-proxy/dropdown-management/dropdowns/master-dropdown-service/patient-master-dropdown.service';
import { OrderDropdowns } from 'projects/patient/src/app/dropdown-proxy/dropdown-management/dropdowns/order-dropdowns.enum';
import { PatientDropdowns } from 'projects/patient/src/app/dropdown-proxy/dropdown-management/dropdowns/patient-dropdowns.enum';
import { DoctorService } from 'projects/patient/src/app/patient-proxy/patient';
import { AddPlanService } from 'projects/patient/src/app/patient-proxy/patient/add-plan.service';
import {
  InsuranceVerifyDTO,
  PatientDTO,
  PatientExportDatas,
  PatientExportDTO,
  PayerDTO,
  PolicyDTO,
} from 'projects/patient/src/app/patient-proxy/patient/dto/models';
import { ExportType } from 'projects/patient/src/app/patient-proxy/patient/enumeration-data/export-type.enum';
import { PayerService } from 'projects/patient/src/app/patient-proxy/patient/payer.service';
import { ReportService } from 'projects/patient/src/app/patient-proxy/patient/report.service';
import { claimAcceptedStatus } from 'projects/shared/src/app/enums/allenums.enum';
import { SpinnerService } from 'projects/shared/src/app/services/spinner.service';
import { TableService } from 'projects/shared/src/app/table.service';
import { Observable, of, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, finalize, map, switchMap, tap } from 'rxjs/operators';
import Swal from 'sweetalert2';

import {
  ClaimDTO,
  PostingChargesDTO,
} from '../billing-proxy/billing-management/posting/dto/models';
import { claimProcessMethod } from '../claim-proxy/claim-processing-management/rcm/billing-management/billing/claim-process-method.enum';
import { ZermidClearingHouseService } from '../claim-proxy/claim-processing-management/rcm/claim-processing-management/x12';
import { ClaimProcessService } from '../claim-proxy/claim-processing-management/rcm/claim-processing-management/x12/claim-process.service';
import {
  ClaimBatchProcessRequestDTO,
  ClaimDetailsDTO,
  InsuredDetailDTO,
  ItemDetailsDTO,
  PatientDetailsDTO,
  PayerDetailsDTO,
  ProviderDetailsDTO,
  ReferringProviderDetailsDTO,
  RenderingingProviderDetailsDTO,
  SearchClaimDetailsDTO,
} from '../claim-proxy/claim-processing-management/rcm/claim-processing-management/x12/dto/models';
import { P837ClaimService } from '../claim-proxy/claim-processing-management/rcm/claim-processing-management/x12/p837-claim.service';
import { SaleType } from '../claim-proxy/claim-processing-management/rcm/order-management/order/sale-type.enum';
import { ClaimViewReportComponent, doctorDTO } from '../claim-view-report/claim-view-report.component';
import { ToastrService } from "ngx-toastr";


@Component({
  selector: 'app-claim-process',
  templateUrl: './claim-process.component.html',
  styleUrls: ['./claim-process.component.scss'],
  providers: [ListService],
  encapsulation: ViewEncapsulation.Emulated,
})
export class ClaimProcessComponent implements OnInit, OnDestroy {
  strPageType: string = 'claimProcessList';
  claimAcceptedStatus: ClaimDTO[];
  claimProcessForm: FormGroup;
  claimSearchForm: FormGroup = new FormGroup({});
  drpChart: ChartDTO[] = [];
  drpPlan: TypeOfPlanDTO[] = [];
  drpAllPlans: any[] = [];
  drpDiagnosis10: DiagnosisCode10DTO[] = [];
  drpDiagnosis9: DiagnosisCode9DTO[] = [];
  // drpPos: POSDTO[] = [];
  drpVerified: VerifiedByDTO[] = [];
  drpPrepared: PreparedByDTO[] = [];
  drpPayable: PayablePercentDTO[] = [];
  drpFacility: FacilityDTO[] = [];
  orderId: string = '';
  organizationunitId: string = '';
  claimTableData: any[] = [];
  drpDoctor: doctorDTO[] = [];

  // selectedData: CreateClaimP837_DTO[] = [];
  clearingHouseTypes: ClearingHouseTypeDTO[] = [];
  drpPayerList: PayerDTO[] = [];
  drpitems: itemInterface[] = [];
  drpProviders: MasterProviderDTO[] = [];
  selectedData: any[] = [];
  isLoading: boolean;
  isLoadings: boolean;
  saveButtonHide: boolean;
  strProviders: string = '';
  strbillingValue: string = '';
  drpBillingType: BillingTypeDTO[] = [];
  subscription$: Subscription[] = [];
  dataSource: MatTableDataSource<any> = new MatTableDataSource<any>([]);
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatTable, { static: false }) tableRef: MatTable<any>;
  selection = new SelectionModel<any>(true, [], true);
  arrDisplayedColumns: string[] = [];
  spinner$: Observable<boolean>;
  relationShipWithSelf: RelationShipWithSelfDTO[] = [];

  constructor(
    private title: Title,
    public list: ListService,
    private Table: TableService,
    private snackBar: MatSnackBar,
    private planService: AddPlanService,
    private formBuilder: FormBuilder,
    private doctorService: DoctorService,
    private payerService: PayerService,
    // private itemService: InsuranceProviderProductEligibilityRulesService,
    private providerList: MasterProviderService,
    private patientDropdownService: PatientMasterDropdownService,
    private orderDropdownService: OrderMasterDropdownService,
    private billingDropdownService: BillingMasterDropdownService,
    private claimProcessService: ClaimProcessService,
    private itemService: ItemEligibilityRulesService,
    public P837ClaimService: P837ClaimService,
    private reportService: ReportService,
    private dialog: MatDialog,
    public spinnerService: SpinnerService,
    private toastr: ToastrService,
  ) {
    this.getDropdowns();

    this.Table?.getOrganizationUnitId().subscribe(
      (val) => {
        this.organizationunitId = val;
      },
      (err) => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      }
    );
  }

  ngOnInit(): void {
    this.arrDisplayedColumns = [
      'options',
      'select',
      'saleorderId',
      'pateintId',
      'patientName',
      'amount',
      'primaryInsuranceName',
      'billingType',
     

    ];
    this.snackBar.dismiss();
    this.title?.setTitle('Qsecure | Claim Process');
    this.createFormGroups();
    this.Table?.getBillingClaimListDataTable().subscribe(
      () => {
        // this.orderId = value
      },
      (err) => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      }
    );
    this.claimTableData = [];
    this.Table?.setClaimProcessListData(this.claimTableData);
    this.claimSearchForm = this.formBuilder.group({
      patientId: new FormControl(""),
      saleOrderId: new FormControl(""),
      patientName: new FormControl(""),
      billedAmount: new FormControl(""),
      payerName: new FormControl(""),
      billingType: new FormControl(""),
    })
    this.claimSearchForm.valueChanges.pipe(
      tap(x => { this.isLoading = true; this.setTableData([]); this.selection.clear(); }),
      distinctUntilChanged(),
      filter(e => e && this.claimSearchForm?.valid),
      debounceTime(300),
      map(response => {
        // this.isLoading = true;
        const oSearchClaim: SearchClaimDetailsDTO = {
          saleOrderId: String(response?.saleOrderId)?.trim() ?? "",
          patientId: String(response?.patientId)?.trim() ?? "",
          patientName: String(response?.patientName)?.trim() ?? "",
          totalBilled: String(response?.billedAmount)?.trim() ?? "",
          insuranceName: String(response?.payerName)?.trim() ?? "",
          billType: String(response?.billingType)?.trim() ?? "",
        }
        return oSearchClaim
      }),
      switchMap((value: SearchClaimDetailsDTO) => this.claimProcessService.searchClaimsByOSearchClaim(this.claimProcessForm?.value?.drpForms, value)),
      finalize(() => {
        this.isLoading = false;
      }),
      tap(x => { this.isLoading = false; }),
    ).subscribe(response => {
      // this.isLoading = true;
      // this.setTableData(response ?? []);
    },
      (err) => {
        this.setTableData([]);
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      }
    );
  }

  //! load Dropdowns functions

  getDropdowns() {
    this.isLoading = true;

    this.drpAllPlans = [];
    const planist = (query: any) => this.planService?.getList(query);
    const planListSubs: Subscription = this.list.hookToQuery(planist).subscribe(
      (response) => {
        //  this.planService?.getPlanBasedOnOrganizationByOrganizationUnitId(this.organizationunitId).subscribe(response => {
        this.drpAllPlans = response?.items;
      },
      (err) => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      }
    );
    this.subscription$?.push(planListSubs);

    const billingDropdownInput: BillingDropdowns[] = [
      BillingDropdowns.ClearingHouseTypes,
    ];
    const billingDropdownSubs: Subscription = this.billingDropdownService
      .getBillingDropdownsByInput(billingDropdownInput)
      .subscribe(
        (stateResponse) => {
          // let response: BillingMasterDropdownDTO = stateResponse?.billDropdownState?.selectedDropdown;
          // this.drpPos = response?.pos;

          const response: BillingMasterDropdownDTO = stateResponse;
          this.clearingHouseTypes = response?.clearingHouseTypes ?? [];
        },
        (err) => {
          const data: HttpErrorResponse = err;
          Swal.fire({
            icon: 'info',
            text: data?.error?.error?.message,
          });
        }
      );
    this.subscription$?.push(billingDropdownSubs);

    const patientDropdownInput: PatientDropdowns[] = [
      PatientDropdowns.DiagnosisCode9,
      PatientDropdowns.DiagnosisCode10,
      PatientDropdowns.RelationShipWithSelf,
    ];
    const patientDropdownSubs: Subscription = this.patientDropdownService
      .getPatientDropdownsByInput(patientDropdownInput)
      .subscribe(
        (stateResponse) => {
          const response: PatientMasterDropdownDTO = stateResponse;
          this.drpDiagnosis9 = response?.diagnosisCodes9 ?? [];
          this.drpDiagnosis10 = response?.diagnosisCodes10 ?? [];
          this.drpFacility = response?.facilities;
          this.relationShipWithSelf = response?.relationShipWithSelves ?? [];
        },
        (err) => {
          const data: HttpErrorResponse = err;
          Swal.fire({
            icon: 'info',
            text: data?.error?.error?.message,
          });
        }
      );
    this.subscription$?.push(patientDropdownSubs);

    // get billing dropdown
    const orderDropdownInput: OrderDropdowns[] = [
      OrderDropdowns.lstBillingTypes,
    ];
    const orderDropdownSubs: Subscription = this.orderDropdownService
      .getOrderDropdownsByInput(orderDropdownInput)
      .subscribe(
        (stateResponse) => {
          let response: OrderMasterDropdownDTO = stateResponse;
          this.drpBillingType = response?.lstBillingTypes;
          this.drpChart = response?.charts;
          this.drpPlan = response?.typeOfPlans;
          this.drpVerified = response?.verifiedBies;
          this.drpPrepared = response?.preparedBies;
          this.drpPayable = response?.payablePercents;
          this.claimProcessForm.patchValue({
            drpBillingType: this.drpBillingType[0]?.billingTypeName ?? '',
          });
        },
        (err) => {
          const data: HttpErrorResponse = err;
          Swal.fire({
            icon: 'info',
            text: data?.error?.error?.message,
          });
        }
      );
    this.subscription$?.push(orderDropdownSubs);

    //! provider getlist
    const providerList = (query: any) => this.providerList.getList(query);
    const providerSubs: Subscription = this.list
      .hookToQuery(providerList)
      .subscribe(
        (response) => {
          this.drpProviders = response?.items ?? [];
          this.claimProcessForm.patchValue({
            drpForms: this.drpProviders[0]?.shortCodeId ?? '',
          });
        },
        (err) => {
          const data: HttpErrorResponse = err;
          Swal.fire({
            icon: 'info',
            text: data?.error?.error?.message,
          });
        }
      );
    this.subscription$?.push(providerSubs);
    this.drpPayerList = [];


    const itemDropdownSubs: Subscription = this.itemService
      .getList(new PagedAndSortedResultRequestDto())
      .subscribe(
        (response) => {
          response?.items?.forEach((element) => {
            //
            this.drpitems.push({
              equipmentBillingCode: element?.itemName,
              itemId: element?.itemCode,
              id: element?.id,
              name: element?.itemName,
              location: element?.locationId,
              // insurance: element?.insuranceId,
              charge: element?.price,
            });
          });
          const payerList = (query: any) => this.payerService?.getList(query);
          const payerListSubs: Subscription = this.list
            .hookToQuery(payerList)
            .subscribe(
              (response) => {

                this.drpPayerList = response?.items ?? [];
                setTimeout(() => {
                  this.loadClaimProcess(this.drpProviders[0]?.shortCodeId);
                }, 500);
              },
              (err) => {
                const data: HttpErrorResponse = err;
                Swal.fire({
                  icon: 'info',
                  text: data?.error?.error?.message,
                });
              }
            );
          this.subscription$?.push(payerListSubs);

        },
        (err) => {
          const data: HttpErrorResponse = err;
          Swal.fire({
            icon: 'info',
            text: data?.error?.error?.message,
          });
        }
      );
    this.subscription$?.push(itemDropdownSubs);

    this.drpDoctor = [];
    this.doctorService
      .getList(new PagedAndSortedResultRequestDto())
      .pipe(map((e) => e.items))
      .subscribe(
        (response) => {
          response?.forEach((element) => {
            const docName = `${element?.npiNumber + ' / ' + element?.firstName + ' ' + element?.middleName + ' ' + element?.lastName}`
            this.drpDoctor.push({
              fLname: element?.firstName ?? '' + ' ' + element?.middleName ?? '' + ' ' + element?.lastName ?? '', LTname: element?.lastName ?? '',
              Npi: element?.npiNumber,
              mdName: element?.middleName,
              fTname: docName,
              id: element?.id ?? '',
            });
          });
        },
        (err) => {
          const data: HttpErrorResponse = err;
          Swal.fire({
            icon: 'info',
            text: data?.error?.error?.message,
          });
        }
      );
  }

  //! Load Claim Process based on dropdown function
  loadClaimProcess(sProviderName?: any) {
    this.isLoading = true;
    this.selectedData = [];
    sProviderName = this.claimProcessForm.value?.drpForms ?? '';
    this.strProviders = this.claimProcessForm.value?.drpForms;
    if (sProviderName === '') {
      this.isLoading = false;
      this.dataSource = new MatTableDataSource(this.claimTableData);
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
      return;
    }

    this.claimProcessService
      ?.getClaimsBasedOnProvidersBySProviderName(sProviderName).pipe(
        tap(x => { this.isLoading = true; this.setTableData([]); }),
        finalize(() => {
          this.isLoading = false;
        }),
        tap(x => { this.isLoading = false; }),
      ).subscribe(
        (response) => {
          // this.setTableData(response?.items ?? []);
        },
        (err) => {
          this.setTableData([]);
          const data: HttpErrorResponse = err;
          Swal.fire({
            icon: 'info',
            text: data?.error?.error?.message,
          });
        }
      );
  }

  private setTableData(response: ClaimDTO[] = []) {
    this.claimTableData = [];
    response?.forEach((element) => {
  //    if ((!element?.isVoided) && (element?.claimStatus === claimAcceptedStatus.YetToProcess || element?.claimStatus === claimAcceptedStatus.readyToClaimProcess || element?.claimStatus === '')) {
        const selectedOrderDetail = element?.saleOrder;
        const selectItemDetails = element?.saleOrder?.items;
        const selectedPatient: PatientDTO = element?.saleOrder?.patients;
        const selPatPersonal: PersonalDTO = selectedPatient?.personals;
        const selPatInsVerify: InsuranceVerifyDTO = selectedPatient?.insuranceVerifications?.primaryInsuranceVerifies;
        const selectedPatPolicy: PolicyDTO = selectedPatient?.policies?.primaryPolicy;
        const patientId = element?.patientId;
        const selectedPatientDob: string = selPatPersonal?.dateOfBirth ?? '';
        const selectedPatientDos: string = '2015-10-03T18:30:00.000Z';
        const selectedPatientGenderType: string = selectedPatient?.clinicals?.genderType ?? '';
        const selectedPatientInsuranceType: string = this.drpProviders?.filter(
          (v) => v?.shortCodeId === selPatPersonal?.providerId
        )[0]?.fullName ?? '';
        const selectedPatientInsuranceNpi: string = this.drpAllPlans?.filter(
          (value) => value?.name === selectedPatPolicy?.insuranceType
        )[0]?.code ?? '';

        const lstPostingCharges: PostingChargesDTO[] = element?.lstPostingCharges;
        let selectedItemDetails: ItemDetailsDTO[] = [];
        lstPostingCharges?.forEach((element) => {
          selectedItemDetails?.push({
            itemId: element?.itemId,
            itemProcedureCodeId: element?.code ?? '',
            itemChargeAmount: element?.total?.toString() ?? '',
            itemQtyUnit: element?.units ?? '',
            itemDateOfServiceFrom: dateYYYYMMDDtoMMDDYYYY(
              element?.dateFrom
            ),
            itemDateOfServiceTo: dateYYYYMMDDtoMMDDYYYY(element?.dateTo),
            modifiers1: element?.modifier1,
            modifiers2: element?.modifier2,
            modifiers3: element?.modifier3,
            modifiers4: element?.modifier4,
          });
        });

        // Purchase or Rental Items or Purchase or Rental patch based on billing type
        const saletypeR = selectItemDetails.every(
          (m) => m.billingType === SaleType.Rental
        );
        const saletypeP = selectItemDetails.every(
          (m) => m.billingType === SaleType.Purchase
        );
        const saletypePR = !saletypeR && !saletypeP;
        let typeofpurchse: number = 0;
        if (saletypePR) {
          typeofpurchse = 3;
        }
        if (saletypeP) {
          typeofpurchse = 1;
        }
        if (saletypeR) {
          typeofpurchse = 2;
        }

        // const randomClaimIds = Math.floor(Math.random() * sampleEOBclaimIds?.length);
        const selectedPatientPosCode = selectedOrderDetail?.orders?.placeOfServiceId ?? '12'; //12
        const relationshipId = this.relationShipWithSelf?.filter((v) => v?.relationTypeShortCodeId === selectedPatient?.contacts[0]?.relationshipId)[0]?.relationShipCode;
        const mdiagnosisCode9Id: string = '0340';
        const mdiagnosisCode10Id: string = 'A0100';
        const selClaimAddress: string[] = selPatInsVerify?.claimAddress?.split(',') ?? [];
        const totalItemBilledAmount = String(lstPostingCharges?.reduce((prvsValue, curValue) => { return ((+prvsValue) + (+curValue?.total)); }, 0));
        const selPatPayor = this.drpPayerList?.filter((p) => p?.payerId === selectedPatPolicy?.payorId?.split(' / ')[0])[0] ?? null;


        const input = {
          provider: selectedPatientInsuranceType ?? "",
          npi: selectedPatientInsuranceNpi ?? "",
          patientGuid: element?.patientId ?? '',
          patientLastName: selPatPersonal?.lastName ?? '',
          patientFirstName: selPatPersonal?.firstName ?? '',
          patientDob: dateYYYYMMDDtoMMDDYYYY(selectedPatientDob),
          patientGenderType: selectedPatientGenderType ?? '',
          patientIndividualRelationShipCode: relationshipId ?? '18',
          patientAddress: selectedPatient?.addresses[0]?.address ?? '',
          patientCity: selectedPatient?.addresses[0]?.city ?? '',
          patientState: selectedPatient?.addresses[0]?.state ?? '',
          patientZipCode: selectedPatient?.addresses[0]?.postalCode ?? '',
          clearingHouseType: 'Zirmed',
          claimId: element?.id ?? '',
          patientRelationShipPersonGenderType: selectedPatientGenderType === '001' ? 'M' : 'F',
          claimNumber: element?.claimId,
          totalItemChargeAmount: element?.lstPostingCharges[0]?.total ?? '',
          placeOfServiceCode: selectedPatientPosCode ?? "",
          authNumber: selectedPatient?.authorizations?.primaryAuthorizations?.authId ?? "",
          diagnosisCode9Id: selectedPatient?.clinicals?.diagnosisCode9[0] ||
            mdiagnosisCode9Id,
          diagnosisCode10Id: selectedPatient?.clinicals?.diagnosisCode10 ||
            mdiagnosisCode10Id,
          itemDetails: selectedItemDetails,
          payerAddressLine1: selClaimAddress[0] ?? '',
          payerAddressLine2: selClaimAddress[1] ?? '',
          totalClaimChargeAmount: element?.lstPostingCharges[0]?.total ?? '',
          totalItemBilledAmount: totalItemBilledAmount ?? "",
          billingType: typeofpurchse ?? "",
          id: patientId ?? "",
        };

        const refProviderDocName: string = selectedPatient?.clinicals?.referringProviderDoctor?.split(
          ' / '
        )[1] ?? '';

        const mRefProviderDocName: string = (refProviderDocName?.trim() ?? '') === '/'
          ? ''
          : refProviderDocName;
        const sProviderDetails: MasterProviderDTO[] = this.drpProviders?.filter(
          (e) => e?.shortCodeId === this.claimProcessForm.value?.drpForms
        ) ?? null;
        const selectedDoctor = this.drpDoctor?.filter(d => d.Npi === selectedPatient?.clinicals?.referringProviderDoctor?.split(' / ')[0])[0] ?? null;
        const modifiedInput = {
          totalItemBilledAmount: '$' + element?.saleOrder?.shippingCheckList?.totalItemPrice ??
            '',
          primaryInsuranceName: selPatPayor?.payerName ?? '',
          patientMiddleName: selPatPersonal?.middleName ?? '',
          claimStatus: element?.claimStatus === ''
            ? 'Unprocessed'
            : element?.claimStatus,
          mpatientDob: selectedPatientDob === null ||
            selectedPatientDob === undefined ||
            selectedPatientDob === ''
            ? ''
            : new Date(selectedPatientDob)?.toLocaleDateString('en-US'),
          mitemDateOfService: selectedPatientDos === null ||
            selectedPatientDos === undefined ||
            selectedPatientDos === ''
            ? ''
            : new Date(selectedPatientDos)?.toLocaleDateString('en-US'),
          patientId: selectedPatient?.defaultPatientId,
          orderId: element?.saleorderId,
          referringProviderDoctorNpi: selectedDoctor?.Npi ?? "",
          referringProviderDoctorFirstName: mRefProviderDocName?.split(' ')[0] ?? '',
          referringProviderDoctorMiddleName: mRefProviderDocName?.split(' ')[1] ?? '',
          referringProviderDoctorLastName: mRefProviderDocName?.split(' ')[2] ?? '',
          renderingProviderDoctorNpi: sProviderDetails[0]?.npi,
          renderingProviderDoctorFirstName: sProviderDetails[0]?.fullName,
          renderingProviderDoctorMiddleName: '',
          renderingProviderDoctorLastName: '',
          payerName: this.drpPayerList?.filter(
            (p) => p?.payerId === selectedPatPolicy?.payorId?.split(' / ')[0]
          )[0]?.payerName ?? '',
          policyNo: selectedPatPolicy?.policyNo ?? "",
          payerId: selectedPatPolicy?.payorId?.split(' / ')[0] ?? '',
          insuredId: selectedPatPolicy?.policyNo,
          insuredLastName: selectedPatPolicy?.lastName,
          insuredFirstName: selectedPatPolicy?.fisrtName,
          insuredMiddleName: selectedPatPolicy?.middleName,
          insuredDob: ((dateYYYYMMDDtoMMDDYYYY(selectedPatPolicy?.birthdate) === "") ? null : dateYYYYMMDDtoMMDDYYYY(selectedPatPolicy?.birthdate)),
          insuredGenderType: selectedPatPolicy?.gender,
          insuredAddress: selectedPatPolicy?.address1,
          insuredCity: selectedPatPolicy?.city,
          insuredState: selectedPatPolicy?.state,
          insuredZipCode: selectedPatPolicy?.postalCode,
          insuredTelephone: selectedPatPolicy?.phone,
          insuredPhoneNumber: selectedPatPolicy?.phone,
          insurancePlanName: selPatInsVerify?.planName,
          groupName: selPatInsVerify?.groupName ?? '',
          insuredPolicyNumber: selectedPatPolicy?.policyNo ?? "",
          authNumber: selectedPatient?.authorizations?.primaryAuthorizations?.authId,
          isSSN: selectedPatPolicy?.alwaysCrossover,
          ...input,
        };

        this.claimTableData.push(modifiedInput);
 //     }
    });
    // this.isLoading = false;
    this.dataSource = new MatTableDataSource(this.claimTableData);
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }

  createFormGroups() {
    this.claimProcessForm = this.formBuilder.group({
      drpForms: new FormControl(''),
      drpBillingType: new FormControl(''),
      //    txtDosFrom: new FormControl(""),
      //    txtDosTo: new FormControl("")
    });
  }


  captureErrorMsg(data: HttpErrorResponse) {
    try {

      if (typeof data?.error === 'object') {
        const valditionerror: { message: string }[] =
          data?.error?.error?.validationErrors ?? [];
        if (
          valditionerror &&
          valditionerror[0] &&
          valditionerror[0]?.message?.includes('is required')
        ) {
          this.totalValue = [];
          valditionerror?.forEach((val: { message: any }) => {
            this.messageValue = val.message;
            this.totalValue?.push(this.messageValue);
          });
          Swal.fire({
            icon: 'info',
            html: this.totalValue.join('\n'),
          });
        }
        return;
      }
      if (typeof data?.error === 'string') {
        const strTrailEnded = 'Your trial has ended! If you would like to continue using EdiFabric, please head on to https://www.edifabric.com/pricing.html to purchase it.';
        const strSubscriptionEnded = 'Your subscription key has expired! Please use the renewal key or contact us at office@edifabric.com for assistance.';
        if (data?.error?.includes(strTrailEnded) || data?.error?.includes(strSubscriptionEnded)) {
          Swal.fire({
            icon: 'info',
            text: 'Your Subscription has ended!. Please contact admin to purchase EdiFabric.',
          });
          return;
        }
        const modifiedError = JSON.parse(data?.error);
        const valditionerror: { message: string }[] =
          modifiedError?.error?.validationErrors ?? [];
        if (valditionerror[0]?.message?.includes('is required')) {
          this.totalValue = [];
          valditionerror?.forEach((val: { message: any }) => {
            this.messageValue = val?.message;
            this.totalValue?.push(this.messageValue);
          });
          Swal.fire({
            icon: 'info',
            html: this.totalValue.join('\n'),
          });
        }
        if (valditionerror[0]?.message?.includes('minimum') || valditionerror[0]?.message?.includes('maximum')) {
          this.totalValue = [];
          valditionerror?.forEach((val: { message: any }) => {
            this.messageValue = val?.message;
            this.totalValue?.push(this.messageValue);
          });
          Swal.fire({
            icon: 'info',
            html: this.totalValue.join('\n'),
          });
        }
        return;
      }
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    } catch (error) {
      console.warn(error);
    }
  }
  totalValue: any;
  messageValue: any;

  //! proccess Selected Claim Process for manual
  proccessSelectedClaimProcess() {
    this.isLoading = true;
    this.saveButtonHide = true;
    let inputClaimDetails: ClaimDetailsDTO[] = [];
    const claimFormValue = this.claimProcessForm?.value;
    const sProviderDetails: MasterProviderDTO[] = this.drpProviders?.filter((e) => e?.shortCodeId === claimFormValue?.drpForms) ?? null;
    this.selectedData?.forEach((v) => {
      const referringProviderDetails: ReferringProviderDetailsDTO = {
        referrringProviderDoctorFirstName: v?.referringProviderDoctorFirstName,
        referrringProviderDoctorLastName: v?.referringProviderDoctorLastName,
        referrringProviderDoctorMiddleName: v?.referringProviderDoctorMiddleName,
        referrringProviderDoctorNpi: v?.referringProviderDoctorNpi,
      };

      const renderingProviderDetails: RenderingingProviderDetailsDTO = {
        renderingProviderDoctorFirstName: v?.renderingProviderDoctorFirstName,
        renderingProviderDoctorLastName: v?.renderingProviderDoctorLastName,
        renderingProviderDoctorMiddleName: v?.renderingProviderDoctorMiddleName,
        renderingProviderDoctorNpi: v?.renderingProviderDoctorNpi,
      };
      const payerDetails: PayerDetailsDTO = {
        payerName: v?.payerName,
        payerId: v?.payerId,
        policyNo: v?.policyNo,
        payerAddressLine1: v?.payerAddressLine1,
        payerAddressLine2: v?.payerAddressLine2,
      };
      const patientDetails: PatientDetailsDTO = {
        patientLastName: v?.patientLastName,
        patientFirstName: v?.patientFirstName,
        patientMiddleName: v?.patientMiddleName,
        patientDob: v?.patientDob,
        patientGenderType: v?.patientGenderType,
        patientIndividualRelationShipCode: v?.patientIndividualRelationShipCode,
        patientAddress: v?.patientAddress,
        patientCity: v?.patientCity,
        patientState: v?.patientState,
        patientZipCode: v?.patientZipCode,
        patientRelationShipPersonGenderType: v?.patientGenderType,
        authNumber: v?.authNumber ?? "",
        isSSN: v?.isSSN,
      };
      const insuredDetails: InsuredDetailDTO = {
        insuredId: v?.insuredId,
        insuredLastName: v?.insuredLastName,
        insuredFirstName: v?.insuredFirstName,
        insuredMiddleName: v?.insuredMiddleName,
        insuredDob: v?.insuredDob,
        insuredGenderType: v?.insuredGenderType,
        insuredAddress: v?.insuredAddress,
        insuredCity: v?.insuredCity,
        insuredState: v?.insuredState,
        insuredZipCode: v?.insuredZipCode,
        insuredTelephone: v?.insuredTelephone,
        insuredPlanName: v?.payerId + ' / ' + v?.payerName,
        insuredPhoneNumber: v?.insuredPhoneNumber,
        insuredGroupName: v?.insuredGroupName,
        payorLevel: v?.payorLevel
      };

      inputClaimDetails?.push({
        claimId: v?.claimId,
        claimNumber: v?.claimNumber,
        clearingHouseType: v?.clearingHouseType,
        totalItemChargeAmount: v?.totalClaimChargeAmount,
        placeOfServiceCode: v?.placeOfServiceCode,
        diagnosisCode10Id: v?.diagnosisCode10Id,
        payerDetails: payerDetails,
        patientDetails: patientDetails,
        itemDetails: v?.itemDetails,
        referringProviderDetails: referringProviderDetails,
        renderingProviderDetails: renderingProviderDetails,
        insuredDetails: insuredDetails,
      });
    });
    const selectedProvider: ProviderDetailsDTO = sProviderDetails?.map((e) => {
      return {
        providerFullName: e?.fullName ?? "",
        providerLastName: e?.lastName ?? "",
        providerFirstName: e?.firstName ?? "",
        providerNpi: e?.npi ?? "",
        providerPhoneNumber: e?.phone ?? "",
        providerAddress: e?.homeAddress ?? "",
        providerCity: e?.city ?? "",
        providerState: e?.state ?? "",
        providerZipCode: e?.zip ?? "",
        providerTaxId: e?.mmTaxId ?? "",
        providerId: e?.id ?? "",
      };
    })[0];
    const input: ClaimBatchProcessRequestDTO = {
      providerDetails: selectedProvider,
      claimDetails: inputClaimDetails,
      claimProcessMethod: claimProcessMethod?.Manual,
    };
    this.claimProcessService?.processClaimBatchByInput(input).subscribe(
      (response) => {
        this.isLoading = true;
        this.saveButtonHide = true;
        if (response && response?.length !== 0) {
          let filelocation: String = String(
            'data:application/octet-stream;base64,' + response
          );
          let link = document.createElement('a');
          link.download = (inputClaimDetails[0]?.claimNumber ?? '') + '.a837';
          link.href = filelocation.toString();
          link.click();
        }
        this.loadClaimProcess(this.drpProviders[0]?.shortCodeId);
        // Swal.fire({
        //   title: 'Success',
        //   html: 'Processed Successfully',
        //   icon: 'success',
        //   timer: 3000,
        //   timerProgressBar: true,
        // });
        
 this.toastr.success('Processed Successfully','Success')
        this.saveButtonHide = false;
        this.isLoading = false;
        this.selection.clear();

      },
      (err) => {
        this.selection.clear();
        this.saveButtonHide = false;
        this.isLoading = false;
        const data: HttpErrorResponse = err;
        data && this.captureErrorMsg(data);
      }
    );
  }
  //! proccess Selected Claim Process for zermid
  getElectronicProcess(value?: any[]) {
    this.isLoadings = true;
    this.saveButtonHide = true;
    let inputClaimDetails: ClaimDetailsDTO[] = [];
    value?.forEach((v) => {
      const referringProviderDetails: ReferringProviderDetailsDTO = {
        referrringProviderDoctorFirstName: v?.referringProviderDoctorFirstName,
        referrringProviderDoctorLastName: v?.referringProviderDoctorLastName,
        referrringProviderDoctorMiddleName: v?.referringProviderDoctorMiddleName,
        referrringProviderDoctorNpi: v?.referringProviderDoctorNpi,
      };
      const renderingProviderDetails: RenderingingProviderDetailsDTO = {
        renderingProviderDoctorFirstName: v?.renderingProviderDoctorFirstName,
        renderingProviderDoctorLastName: v?.renderingProviderDoctorLastName,
        renderingProviderDoctorMiddleName: v?.renderingProviderDoctorMiddleName,
        renderingProviderDoctorNpi: v?.renderingProviderDoctorNpi,
      };
      const payerDetails: PayerDetailsDTO = {
        payerName: v?.payerName,
        payerId: v?.payerId,
        policyNo: v?.policyNo,
        payerAddressLine1: v?.payerAddressLine1,
        payerAddressLine2: v?.payerAddressLine2,
      };
      const insuredDetails: InsuredDetailDTO = {
        insuredId: v?.insuredId,
        insuredLastName: v?.insuredLastName,
        insuredFirstName: v?.insuredFirstName,
        insuredMiddleName: v?.insuredMiddleName,
        insuredDob: v?.insuredDob,
        insuredGenderType: v?.insuredGenderType,
        insuredAddress: v?.insuredAddress,
        insuredCity: v?.insuredCity,
        insuredState: v?.insuredState,
        insuredZipCode: v?.insuredZipCode,
        insuredTelephone: v?.insuredTelephone,
        insuredPlanName: v?.payerId + ' / ' + v?.payerName,
        insuredGroupName: v?.insuredGroupName,
        insuredPhoneNumber: v?.insuredPhoneNumber,
        payorLevel: v?.payorLevel
      };
      const patientDetails: PatientDetailsDTO = {
        patientLastName: v?.patientLastName,
        patientFirstName: v?.patientFirstName,
        patientMiddleName: v?.patientMiddleName,
        patientDob: v?.patientDob,
        patientGenderType: v?.patientGenderType,
        patientIndividualRelationShipCode: v?.patientIndividualRelationShipCode,
        patientAddress: v?.patientAddress,
        patientCity: v?.patientCity,
        patientState: v?.patientState,
        patientZipCode: v?.patientZipCode,
        patientRelationShipPersonGenderType: v?.patientRelationShipPersonGenderType,
        authNumber: v?.authNumber ?? "",
        isSSN: v?.isSSN,
      };

      inputClaimDetails.push({
        claimId: v?.claimId,
        claimNumber: v?.claimNumber,
        clearingHouseType: v?.clearingHouseType,
        totalItemChargeAmount: v?.totalClaimChargeAmount,
        placeOfServiceCode: v?.placeOfServiceCode,
        diagnosisCode10Id: v?.diagnosisCode10Id,
        payerDetails: payerDetails,
        patientDetails: patientDetails,
        itemDetails: v?.itemDetails,
        referringProviderDetails: referringProviderDetails,
        renderingProviderDetails: renderingProviderDetails,
        insuredDetails: insuredDetails,
      });
    });
    const sProviderDetails: MasterProviderDTO[] =
      this.drpProviders?.filter(
        (e) => e?.shortCodeId === this.claimProcessForm.value?.drpForms
      ) ?? null;
    const selectedProvider: ProviderDetailsDTO = sProviderDetails?.map((e) => {
      return {
        providerFullName: e?.fullName ?? "",
        providerLastName: e?.lastName ?? "",
        providerFirstName: e?.firstName ?? "",
        providerNpi: e?.npi ?? "",
        providerPhoneNumber: e?.phone ?? "",
        providerAddress: e?.homeAddress ?? "",
        providerCity: e?.city ?? "",
        providerState: e?.state ?? "",
        providerZipCode: e?.zip ?? "",
        providerTaxId: e?.mmTaxId ?? "",
        providerId: e?.id ?? "",

      };
    })[0];

    const input: ClaimBatchProcessRequestDTO = {
      providerDetails: selectedProvider,
      claimDetails: inputClaimDetails,
      claimProcessMethod: claimProcessMethod?.Zermid,
    };

    this.P837ClaimService?.generateEdiByInput(input).subscribe(
      (response) => {
        this.isLoadings = true;
        this.saveButtonHide = true;
        this.selection.clear();

        if (response && response?.length !== 0) {
          if (
            response?.includes('Please enter') ||
            response?.includes('zermid claim process')
          ) {
            Swal.fire({
              icon: 'info',
              text: response,
            });
          } else {
            let filelocation: String = String(
              'data:application/octet-stream;base64,' + response
            );
            let link = document.createElement('a');
            link.download = (inputClaimDetails[0]?.claimNumber ?? '') + '.a837';
            link.href = filelocation.toString();
            link.click();
            // Swal.fire({
            //   title: 'Success',
            //   html: 'Processed Successfully',
            //   icon: 'success',
            //   timer: 3000,
            //   timerProgressBar: true,
            // });
            this.toastr.success('Processed Successfully','Success')
            this.saveButtonHide = false;
            this.isLoadings = false;
          }
        }
        this.loadClaimProcess(this.drpProviders[0]?.shortCodeId);
        this.isLoadings = false;
        this.saveButtonHide = false;
      },
      (err) => {
        this.selection.clear();
        this.saveButtonHide = false;
        this.isLoadings = false;
        const data: HttpErrorResponse = err;
        data && this.captureErrorMsg(data);
      }
    );
  }
  getProccessSelectedClaimProcess(value?: any[]) {
    this.selectedData = value;
    this.proccessSelectedClaimProcess();
  }
  onProviderChange(sProviderId: { value: any }) {
    let selectedProvider = sProviderId.value;
    this.selection.clear();
    this.loadClaimProcess(selectedProvider);
  }

  clearDrpForms() {
    this.strProviders = '';
    this.claimProcessForm.patchValue({ drpForms: '' });
    this.claimTableData = [];
    this.Table?.setClaimProcessListData(this.claimTableData);
  }

  exportTableData() {
    let datas: PatientExportDatas[] = [];
    this.claimTableData?.forEach((element) => {
      let billitype = element?.billingType;
      datas?.push({
        saleOrderId: element?.orderId,
        patientDefaultId: element?.patientId,
        patientName:
          element?.patientFirstName +
          '' +
          element?.patientMiddleName +
          '' +
          element?.patientLastName,
        totalBilled: String(element?.totalItemBilledAmount),
        insuranceName: element?.primaryInsuranceName,
        // billingStatus: element?.claimStatus,
        itemBillType: (((billitype === 1) ? SaleType.Purchase : ((billitype === 2)) ? SaleType.Rental : ((billitype === 3)) ? SaleType.PurchaseRental : "")),
      });
    });
    let patientsExport: PatientExportDTO = {
      columns: [
        'Sale Order Id',
        'Patient Id',
        'Patient Name',
        'Total Billed',
        'Insurance Name',
        'Item Bill Type',
      ],
      datas: datas,
    };
    this.reportService
      .exportPatientReportsByExportTypeAndExportDatas(
        ExportType.ClaimProcessReports,
        patientsExport
      )
      .subscribe(
        (response) => {
          if (response && response?.length !== 0) {
            let filelocation: String = String(
              'data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,' +
              response
            );
            let link = document.createElement('a');
            link.download = 'Exportedlist' + '.xlsx';
            link.href = filelocation.toString();
            link.click();
            // Swal.fire({
            //   title: 'Success',
            //   html: 'Exported Successfully',
            //   icon: 'success',
            //   timer: 3000,
            //   timerProgressBar: true,
            // });
            this.toastr.success('Exported Successfully','Success')
          }
        },
        (err) => {
          const data: HttpErrorResponse = err;
          let errorMesg: string = data?.error;
          if (
            errorMesg?.includes('Sorry!! There is no data in table to export')
          ) {
            errorMesg = 'Sorry!! There is no data in table to export';
          } else {
            errorMesg = data?.error?.error?.message;
          }
          // Swal.fire({
          //   icon: 'info',
          //   text: errorMesg,
          // });
          this.toastr.info(errorMesg)
        }
      );
  }

  viewClaimDetailsById(value: { claimId: string; patientGuid: string }) {

    const dialogRef = this.dialog.open(ClaimViewReportComponent, {
      disableClose: true,
      data: {
        claimId: value?.claimId,
        ClaimDetails: [value],
        patientId: value?.patientGuid,
      },
    });
    dialogRef.afterClosed().subscribe(() => {
      this.getDropdowns();
      this.selection.clear();
    });
  }

  billingType: string = '';
  clearBillingType() {
    this.billingType = '';
    this.claimProcessForm.patchValue({ drpBillingType: '' });
  }
  ngOnDestroy(): void {
    this.subscription$?.forEach((sub) => {
      sub && sub?.unsubscribe();
    });
  }


}

export interface claimProcessList {
  id: string;
  pateintId: string;
  patientName: string;
  DOS: string;
  planCode: string;
  insurance: string;
  process: boolean;
  category: string;
  facility: string;
  formDescripition: string;
}
export interface claimSearch { billedAmount: string, billingType: string, patientId: string, patientName: string, payerName: string, saleOrderId: string }
function dateYYYYMMDDtoMMDDYYYY(strDate: string): string {
  let latest_date: string = '';
  if (
    strDate &&
    strDate !== '' &&
    typeof strDate === 'string' &&
    strDate !== null &&
    strDate !== undefined &&
    strDate.length >= 1 &&
    !isNaN(new Date(strDate).getTime())
  ) {
    latest_date = Intl.DateTimeFormat('en-US')?.format(new Date(strDate)) ?? '';
    return latest_date;
  }
  return latest_date;
}
