<mat-dialog-content class="mat-typography">
    <div class="col-lg-12">
      <div class="row align-items-center" > <!-- style="flex-wrap: nowrap;" -->
        <div class="col-lg-10 align-center">
          <h4 class="customThemeClass">
            <b>Save Model</b>
          </h4>
        </div>
        <div class="col-lg-2 align-right">
          <mat-icon mat-dialog-close>close</mat-icon>
        </div>
      </div>
    </div>
    <div class="card card-body">
      <div class="row pt-2">
        This is Final Model
      </div>
    </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <!-- Button Actions -->
  <button mat-button class="buttonColor" >
    Save
  </button>
  <button class="buttonCancelColor" mat-button mat-dialog-close>Close</button>
</mat-dialog-actions>
