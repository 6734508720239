import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DataTableDirective } from 'angular-datatables';
import { defaultGuid } from 'projects/shared/src/app/enums/allenums.enum';

@Component({
  selector: 'app-practice-management-faxlog',
  templateUrl: './practice-management-faxlog.component.html',
  styleUrls: ['./practice-management-faxlog.component.scss']
})
export class PracticeManagementFaxlogComponent implements OnInit {
  isLoading: boolean;
  dtOptions: any;
  $subscription: any;
  @Input() tableData: any;
  @Input() sessionPracticeId: any;
  practiceId: string;
  practiceFaxlog: any;
  @ViewChild(DataTableDirective, { static: false })
  datatableElement: DataTableDirective;
  constructor( private snackBar: MatSnackBar) { }

  ngOnInit(): void {

    this.dtOptions = {
      responsive: true,
      paging: false,
      scrollCollapse: true,
      scrollY: '410px',
      scrollX: true,
      fixedHeader: true,
      dom: 'Bfrtip',
      order:[],
      language: {
        emptyTable: 'No records',
        info: 'Total : _MAX_ records',
        infoEmpty: 'Total : _MAX_ records',
        infoFiltered: '(filtered : _TOTAL_ records)',
        loadingRecords: 'Loading...',
        zeroRecords: 'No matching records',
      },
      buttons: [
        {
          extend: 'excel',
          text: 'Export',
          filename: 'Fax log Info',
        },
        {
          extend: 'colvis',
          columns: ':not(:eq(0), :eq(1), :eq(2), :eq(-1))', // Exclude first three and last columns from the column visibility toggle
        },
        // {
        //   text: 'Show Default Column',
        //   action: function (e, dt, node, config) {
        //     dt.columns(':not(:lt(3),:last-child)').visible(false);
        //   },
        // },
        {
          text: 'Show All Column',
          action: function (e, dt, node, config) {
            dt.columns().visible(true);
          },
        },
      ]
    };

    this.practiceId = this.sessionPracticeId;
    this.practiceFaxlog = this.tableData;
  }

    //After View Method
    ngAfterViewInit(): void {
      if (this.datatableElement && this.datatableElement.dtInstance) {
        this.refreshDataTable();
      } else {
        setTimeout(() => this.refreshDataTable(), 500);
      }
    }
  
  
    refreshDataTable(): void {
      this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
        dtInstance.columns().every(function () {
          const that = this;
          $('input', this.footer()).on('keyup change', function () {
            if (that.search() !== this['value']) {
              that.search(this['value']).draw();
            }
          });
        });
      });
    }
    onRowDoubleClickV1(textToCopy) {
      this.copyTextToClipboard(textToCopy);
      this.showNotification('Text copied: ' + textToCopy);
    }
    
    showNotification(message: string): void {
      this.snackBar.open(message, 'Close', {
        duration: 3000, // Duration in milliseconds
        horizontalPosition: 'end', // Positioning the notification horizontally
        verticalPosition: 'bottom', // Positioning the notification vertically
      });
    }
    
    copyTextToClipboard(text: string): void {
      const textArea = document.createElement('textarea');
      textArea.value = text;
      document.body.appendChild(textArea);
      textArea.select();
      document.execCommand('Copy');
      textArea.remove();
    }
    isEmpty(value: any): boolean {
      return (
        value === null ||
        value === undefined ||
        value === '' ||
        value === defaultGuid
      );
    }
    getFormattedDate(date: any): string {
      return date
        ? new Date(date).toLocaleString('en-US', {
            month: '2-digit',
            day: '2-digit',
            year: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            hour12: true,
          })
        : '-';
    }
}
