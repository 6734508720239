import { ToastrService } from 'ngx-toastr';
import { Component, OnInit, ViewChild } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import {
  ProductCategoryDetailsService,
  ProductDetailsService,
  Status,
  VendorDetailsService,
} from '../item-proxy/item-management/optimization';
import { PagedAndSortedResultRequestDto } from '@abp/ng.core';
import { Observable, Subject, Subscription } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import {
  CreateUpdateProductDetailsDTO,
  CreateUpdateProductHcpcCodeDTO,
  VendorDetailsDTO,
} from '../item-proxy/item-management/optimization/dto';
// import { defaultGuid } from 'projects/admin/src/app/document-perviewer/document-perviewer.component';
import { HttpErrorResponse } from '@angular/common/http';
import Swal from 'sweetalert2';

import { DataTableDirective } from 'angular-datatables';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { ProductHcpcCodeService } from '../item-proxy/item-management/optimization/product-hcpc-code.service';
import { Title } from '@angular/platform-browser';
import { MaskSizeService } from 'projects/order/src/app/order-proxy/order-management/order-optimization/mask-size.service';
import { CommunicationService } from 'projects/shared/src/app/services/communication.service';
import { CommonService } from 'projects/shared/src/app/services/common.service';
import { MatDialog } from '@angular/material/dialog';
import { AddManufacturerComponent } from 'projects/shared/src/app/components/add-manufacturer/add-manufacturer.component';
import { AddCategoryComponent } from 'projects/shared/src/app/components/add-category/add-category.component';
import { AddSizeComponent } from 'projects/shared/src/app/components/add-size/add-size.component';
import { defaultGuid } from 'projects/shared/src/app/enums/allenums.enum';
import { defaultTablePageOffset } from 'projects/shared/src/app/data/common-data';

@Component({
  selector: 'app-product-tab',
  templateUrl: './product-tab.component.html',
  styleUrls: ['./product-tab.component.scss'],
})
export class ProductTabComponent implements OnInit {
  productId: string = defaultGuid;
  unitCost: number;
  lstMasks: any;
  paginationInfo: any;
  currentPage: any = 1;
  totalPages: any = 1;
  pageOffset: any = defaultTablePageOffset;
  productList: any;
  productListData: any;
  ltVendors: any;
  filteredVendorDetails: Observable<VendorDetailsDTO[]> | undefined;
  // filteredVendorDetails: Observable<string[]> | undefined;
  filteredSizeId: any;
  ltCategories: any;
  filteredCategoryDetails: Observable<string[]> | undefined;
  productForm: FormGroup;
  ltHCPC: CreateUpdateProductHcpcCodeDTO[] = [];
  hcpcDuplicateValidation: boolean = false;
  notesText: any;
  isSaveBtnDisabled: boolean = false;
  loadItemTable: boolean = true;
  @ViewChild(DataTableDirective, { static: false })
  datatableElement: DataTableDirective;
  myTable: DataTables.Api;
  subscription$: Subscription[] = [];
  defaultGuid = defaultGuid;
  constructor(
    private fb: FormBuilder,
    private title: Title,
    private vendorService: VendorDetailsService,
    private categoryService: ProductCategoryDetailsService,
    private productDetailsService: ProductDetailsService,
    private hcpcCodeService: ProductHcpcCodeService,
    private toaster: ToastrService,
    private MaskSizeService: MaskSizeService,
    private communicationService: CommunicationService,
    private commonService: CommonService,
    private dialog: MatDialog
  ) {
    const comService2 =
      this.communicationService.insuranceAllowableGetMethodCall$.subscribe(
        (productId) => {
          this.editProduct(productId);
        }
      );
    this.subscription$.push(comService2);

    const comService3 = this.communicationService.statusMethodCall$
      .pipe(map((data: any) => [data.event, data.productId]))
      .subscribe(([event, productId]) => {
        //this.statusChange(event, productId);
        // Use statusId and param2 here
      });
    this.subscription$.push(comService3);
    const comService4 = this.communicationService.HandleMethodMethodCall$.pipe(
      map((data: any) => [data.event, data.productId])
    ).subscribe(([event, productId]) => {
      this.handleTableCellClick(event, productId);
      // Use statusId and param2 here
    });
    this.subscription$.push(comService4);

    const comService5 =
      this.communicationService.functionReloadProductTable$.subscribe(() => {
        this.reloadTable();
      });
    this.subscription$.push(comService5);
  }

  ngOnInit(): void {
    this.title.setTitle('Qsecure | Product');
    this.initializeForms();
    this.loadDropdowns();
    this.loadMaskSize();
    // this.loadProductDetails();
    this.loadProductDetailsV2();
  }
  ngOnDestroy(): void {
    this.subscription$?.forEach((sub) => {
      sub && sub?.unsubscribe();
    });
  }
  //To Initialize the Forms
  initializeForms() {
    this.productForm = this.fb.group({
      drpManufacturer: new FormControl('', [Validators.required]),
      txtManufacturerFilter: new FormControl(''),
      drpCategory: new FormControl('', [Validators.required]),
      txtCategoryFilter: new FormControl(''),
      txtProductCode: new FormControl('', [Validators.required]),
      txtDescription: new FormControl('', [Validators.required]),
      txtUnitCost: new FormControl('', [this.decimalValidator()]),
      txtMiniQty: new FormControl(null, [Validators.required]),
      txtCash: new FormControl('', [this.decimalValidator()]),
      txtSizeId: new FormControl('', [Validators.required]),
      txtSizeIdFileter: new FormControl(''),
      txtLocationBin: new FormControl(''),
      txtTicketExtraNote: new FormControl(''),
      chkFulfillment: new FormControl(''),
      chkCDSPriceVerify: new FormControl(''),
      chkCDSPartVerify: new FormControl(''),
      chkHasPPMPart: new FormControl(''),
      chkHasCDSPart: new FormControl(''),
      txtHCPCCode: new FormControl(''),
      chkIsHide: new FormControl(''),
    });
  }

  openAddManufacturerModal() {
    const dialogRef = this.dialog.open(AddManufacturerComponent, {
      // minwidth: '65%',
      // minheight: '60%',
      disableClose: true,

      data: {
        // orderId: orderId,
        // patientId: patientId,
        // defaultSaleOrderId: defaultSaleOrderId,
        // claimId: claimId,
        // chartNo: chartNo,
        // fullName: fullName,
        // isClaimBatch: false,
      },
    });

    const closeDialog = dialogRef.afterClosed().subscribe(
      (claimDetails: any) => {
        console.log('claimDetails');
        console.log(claimDetails);
        if (claimDetails) {
          const existingItemIndex = this.ltVendors.findIndex(
            (item) => item.id === claimDetails.id
          );
          console.log('existingItemIndex');
          console.log(existingItemIndex);
          if (existingItemIndex === -1) {
            this.ltVendors.push(claimDetails);
            this.ltVendors.sort((a, b) =>
              a.vendorName.localeCompare(b.vendorName)
            );
          }
          console.log('this.ltVendors');
          console.log(this.ltVendors);

          // this.ltVendors = response?.items;
          this.filteredVendorDetails = this.productForm
            .get('txtManufacturerFilter')
            ?.valueChanges.pipe(
              startWith(''),
              map((value) =>
                this.ltVendors?.filter((option) =>
                  option?.vendorName
                    ?.toLowerCase()
                    ?.includes(value?.toLowerCase() ?? '')
                )
              )
            );
          console.log('this.filteredVendorDetails');
          console.log(this.filteredVendorDetails);
        }
      },
      (err) => {
        // const data: HttpErrorResponse = err;
        // console.warn(data?.error?.error?.message)
        // Swal.fire({
        //   icon: 'info',
        //   text: data?.error?.error?.message,
        // });
      }
    );
  }

  openAddCategoryModal() {
    const dialogRef = this.dialog.open(AddCategoryComponent, {
      disableClose: true,

      data: {},
    });

    const closeDialog = dialogRef.afterClosed().subscribe(
      (claimDetails: any) => {
        if (claimDetails) {
          const existingItemIndex = this.ltCategories.findIndex(
            (item) => item.id === claimDetails.id
          );
          if (existingItemIndex === -1) {
            this.ltCategories.push(claimDetails);
            this.ltCategories.sort((a, b) =>
              a.productCategoryName.localeCompare(b.productCategoryName)
            );
          }
          // this.ltCategories = response?.items;
          this.filteredCategoryDetails = this.productForm
            .get('txtCategoryFilter')
            ?.valueChanges.pipe(
              startWith(''),
              map((value) =>
                this.ltCategories?.filter((option) =>
                  option?.productCategoryName
                    ?.toLowerCase()
                    ?.includes(value?.toLowerCase() ?? '')
                )
              )
            );
        }
      },
      (err) => {
        // const data: HttpErrorResponse = err;
        // console.warn(data?.error?.error?.message)
        // Swal.fire({
        //   icon: 'info',
        //   text: data?.error?.error?.message,
        // });
      }
    );
  }

  openAddSizeModal() {
    const dialogRef = this.dialog.open(AddSizeComponent, {
      disableClose: true,

      data: {},
    });

    const closeDialog = dialogRef.afterClosed().subscribe(
      (claimDetails: any) => {
        console.log('claimDetails');
        console.log(claimDetails);
        if (claimDetails) {
          const existingItemIndex = this.lstMasks.findIndex(
            (item) => item.id === claimDetails.id
          );
          console.log('existingItemIndex');
          console.log(existingItemIndex);
          if (existingItemIndex === -1) {
            this.lstMasks.push(claimDetails);
            this.lstMasks.sort((a, b) =>
              a.description.localeCompare(b.description)
            );
          }
          console.log('this.lstMasks');
          console.log(this.lstMasks);

          // this.ltCategories = response?.items;
          this.filteredSizeId = this.productForm
            .get('txtSizeIdFileter')
            ?.valueChanges.pipe(
              startWith(''),
              map((value) =>
                this.lstMasks?.filter((option) =>
                  option?.description
                    ?.toLowerCase()
                    ?.includes(value?.toLowerCase() ?? '')
                )
              )
            );
          console.log('this.filteredSizeId');
          console.log(this.filteredSizeId);
        }
      },
      (err) => {
        // const data: HttpErrorResponse = err;
        // console.warn(data?.error?.error?.message)
        // Swal.fire({
        //   icon: 'info',
        //   text: data?.error?.error?.message,
        // });
      }
    );
  }

  // Add the HCPC Codes to the Table
  addHCPC() {
    if (
      this.productForm?.value?.txtHCPCCode?.trim() === '' ||
      this.productForm?.value?.txtHCPCCode?.trim() === null ||
      this.productForm?.value?.txtHCPCCode?.trim() === undefined
    ) {
      this.toaster.error('Enter Valid HCPC Code!');
    } else if (!this.validateHCPC()) {
      const value = this.productForm.value.txtHCPCCode.trim();
      this.ltHCPC.push({
        hcpcCode: value,
        productId: this.productId,
        status: Status.Active,
        line: 0,
      });
      this.productForm.patchValue({
        txtHCPCCode: '',
      });
    } else {
      this.toaster.error('Code Already Exists!');
    }
    // if (!this.validateHCPC()) {
    //   const value = this.productForm.value.txtHCPCCode;
    //   this.ltHCPC.push({
    //     hcpcCode: value,
    //     productId: this.productId,
    //     status: Status.Active,
    //     line: 0,
    //   });
    //   this.productForm.patchValue({
    //     txtHCPCCode: '',
    //   });
    // } else {
    //   this.toaster.error('Code Already Exists!');
    // }
  }
  //Remove the HCPC Codes
  removeHCPC(code: string) {
    this.ltHCPC = this.ltHCPC.filter((a) => a.hcpcCode !== code);
  }
  //validate the HCPC Codes
  validateHCPC() {
    const value = this.productForm?.value?.txtHCPCCode?.trim();
    const count = this.ltHCPC.filter(
      (a) => a.hcpcCode?.toLowerCase() == value.toLowerCase()
    ).length;
    this.hcpcDuplicateValidation = count > 0 ? true : false;
    return this.hcpcDuplicateValidation;
  }
  //Update Character Count for TextArea
  updateCharacterCount(maxlength: number) {
    // Ensure the text length does not exceed the maximum length
    if (this.notesText?.length > maxlength) {
      this.notesText = this.notesText?.substr(0, maxlength);
    }
  }
  //To load the Dropdowns
  loadDropdowns() {
    const vendorDetails = this.vendorService
      .getActiveVendorList(new PagedAndSortedResultRequestDto())
      .subscribe(
        (response) => {
          this.ltVendors = response?.items;
          this.filteredVendorDetails = this.productForm
            .get('txtManufacturerFilter')
            ?.valueChanges.pipe(
              startWith(''),
              map((value) =>
                this.ltVendors?.filter((option) =>
                  option?.vendorName
                    ?.toLowerCase()
                    ?.includes(value?.toLowerCase() ?? '')
                )
              )
            );
        },
        (err) => {}
      );
    this.subscription$.push(vendorDetails);

    const categoryDetails = this.categoryService
      .getActiveCategoryList(new PagedAndSortedResultRequestDto())
      .subscribe(
        (response) => {
          this.ltCategories = response?.items;
          this.filteredCategoryDetails = this.productForm
            .get('txtCategoryFilter')
            ?.valueChanges.pipe(
              startWith(''),
              map((value) =>
                this.ltCategories?.filter((option) =>
                  option?.productCategoryName
                    ?.toLowerCase()
                    ?.includes(value?.toLowerCase() ?? '')
                )
              )
            );
        },
        (err) => {}
      );
    this.subscription$.push(categoryDetails);
  }

  numbersAndfloatOnly(event: any) {
    const allowedChars = '0123456789.';
    const inputChar = event.key;

    if (event.key === '.' && event.currentTarget.value.includes('.')) {
      // Allow only one decimal point
      event.preventDefault();
    } else if (allowedChars.indexOf(inputChar) === -1) {
      // Prevent input of characters other than numbers and dot
      event.preventDefault();
    }
  }

  onPaste(event: ClipboardEvent) {
    const clipboardData = event.clipboardData;
    const pastedData = clipboardData.getData('text');
    // const isNumber = /^\d+$/.test(pastedData); //Should contain only whole numbers
    const isNumber = /^\d+(\.\d+)?$/.test(pastedData); //Should contain only whole numbers and one decimel point(.)
    if (!isNumber) {
      event.preventDefault(); // Prevent the input if the key is not a number
    }
  }
  //Load Mask Size Dropdowns
  loadMaskSize() {
    const maskDetails = this.MaskSizeService.getActiveList(
      new PagedAndSortedResultRequestDto()
    ).subscribe(
      (response) => {
        this.lstMasks = response.items;
        // this.ltVendors = response?.items;

        this.filteredSizeId = this.productForm
          .get('txtSizeIdFileter')
          ?.valueChanges.pipe(
            startWith(''),
            map((value) =>
              this.lstMasks?.filter((option) =>
                option?.description
                  ?.toLowerCase()
                  ?.includes(value?.toLowerCase() ?? '')
              )
            )
          );
      },
      (err) => {}
    );
  }
  //To load the product details in the table
  loadProductDetailsV2() {
    this.loadItemTable = false;
    const productList = this.productDetailsService
      .getProductsListAsync(this.currentPage, this.pageOffset)
      .subscribe(
        (response) => {
          this.productList = response.items ?? [];

          this.productList = this.productList.map((product: any) => {
            return {
              ...product,
              creationTime: this.commonService.getFormattedDateTimeZone(
                product.creationTime
              ),
              lastModificationTime: this.commonService.getFormattedDateTimeZone(
                product.lastModificationTime
              ),
            };
          });

          this.totalPages =
            Math.ceil(response?.totalCount / this.pageOffset) == 0
              ? 1
              : Math.ceil(response?.totalCount / this.pageOffset);

          this.paginationInfo = {
            startRecord: (this.currentPage - 1) * this.pageOffset + 1,
            endRecord: Math.min(
              this.currentPage * this.pageOffset,
              response?.totalCount ?? 0
            ),
            overallTableCount: response?.totalCount ?? 0,
          };
console.log(this.paginationInfo);
          this.loadItemTable = true;
        },
        () => {
          this.loadItemTable = true;
        }
      );
    this.subscription$.push(productList);
  }

  //Get HCPC Codes as comma seperated string
  getFormattedHcpcCodes(data): string {
    return (
      data?.hcpcCodes?.map((subItem) => subItem?.hcpcCode)?.join(', ') ?? ''
    );
  }

  //Save & Update Product Details
  saveUpdateProductDetails() {
    const formValue = this.productForm?.value;

    let productDetails: CreateUpdateProductDetailsDTO = {
      productCode: formValue?.txtProductCode,
      mainProductName: formValue?.txtDescription,
      productDescription: formValue?.txtDescription,
      manufacturerId: formValue?.drpManufacturer,
      categoryId: formValue?.drpCategory,
      unitCost: formValue?.txtUnitCost === '' ? 0 : formValue?.txtUnitCost,
      binLocation: formValue?.txtLocationBin,
      minimumQuantity: formValue?.txtMiniQty,
      fulfillment: formValue?.chkFulfillment ? 1 : 0,
      cdsPriceVerif: formValue?.chkCDSPriceVerify ? 1 : 0,
      cdsPartVerif: formValue?.chkCDSPartVerify ? 1 : 0,
      hasPPMPart: formValue?.chkHasPPMPart ? 1 : 0,
      hasCDSPart: formValue?.chkHasCDSPart ? 1 : 0,
      status: Status.Active,
      cash: formValue?.txtCash,
      ticketExtraNote: formValue?.txtTicketExtraNote,
      hcpcCodes: this.ltHCPC,
      sizeId: formValue?.txtSizeId ? formValue?.txtSizeId : defaultGuid,
      hide: formValue?.chkIsHide ? 1 : 0,
    };

    if (formValue?.chkIsHide === true) {
      Swal.fire({
        title: 'Are You Sure?',
        text: "The product is hide it won't show anywhere!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#34c38f',
        cancelButtonColor: '#f46a6a',
        confirmButtonText: 'Yes',
      }).then((result) => {
        if (result.value) {
          if (
            this.productId === defaultGuid ||
            this.productId === '' ||
            this.productId === undefined
          ) {
            // this.loadItemTable = false;
            this.productDetailsService.create(productDetails).subscribe(
              (response) => {
                // const userTimezonelastModificationTime =
                //   this.commonService.getFormattedDateTimeZone(
                //     response.lastModificationTime
                //   );
                // response.lastModificationTime =
                //   userTimezonelastModificationTime;
                // const userTimezonecreationTime =
                //   this.commonService.getFormattedDateTimeZone(
                //     response.creationTime
                //   );
                // response.creationTime = userTimezonecreationTime;

                // this.productList.unshift(response);
                this.currentPage = 1;

                console.log(this.paginationInfo);
                this.loadProductDetailsV2();
                // this.loadItemTable = true;
                this.resetForm();
                this.toaster.success('Product Details Saved!');
              },
              (err) => {
                this.loadItemTable = true;
                const data: HttpErrorResponse = err;
                Swal.fire({
                  icon: 'info',
                  text: data?.error?.error?.message,
                });
              }
            );
          } else {
            // this.loadItemTable = false;
            this.productDetailsService
              .update(this.productId, productDetails)
              .subscribe(
                (response) => {
                  // const index = this.productList.findIndex(
                  //   (obj) => obj.id === response?.id
                  // );
                  // if (index !== -1) {
                  //   this.productList[index] = response; //Replace the Object
                  //   const objectToMove = this.productList.splice(index, 1)[0]; // Remove and get the object
                  //   const userTimezonelastModificationTime =
                  //     this.commonService.getFormattedDateTimeZone(
                  //       response.lastModificationTime
                  //     );
                  //   response.lastModificationTime =
                  //     userTimezonelastModificationTime;
                  //   const userTimezonecreationTime =
                  //     this.commonService.getFormattedDateTimeZone(
                  //       response.creationTime
                  //     );
                  //   response.creationTime = userTimezonecreationTime;
                  //   this.productList.unshift(objectToMove);
                  // }
                  this.currentPage = 1;

                  console.log(this.paginationInfo);
                  this.loadProductDetailsV2();
                  this.resetForm();
                  // this.loadItemTable = true;
                  this.toaster.success('Product Details Updated!');
                },
                (err) => {
                  this.loadItemTable = true;
                  const data: HttpErrorResponse = err;
                  Swal.fire({
                    icon: 'info',
                    text: data?.error?.error?.message,
                  });
                }
              );
          }
        } else {
          this.loadItemTable = true;
        }
      });
    } else {
      if (
        this.productId === defaultGuid ||
        this.productId === '' ||
        this.productId === undefined
      ) {
        // this.loadItemTable = false;
        this.productDetailsService.create(productDetails).subscribe(
          (response) => {
            // const userTimezonelastModificationTime =
            //   this.commonService.getFormattedDateTimeZone(
            //     response.lastModificationTime
            //   );
            // response.lastModificationTime = userTimezonelastModificationTime;
            // const userTimezonecreationTime =
            //   this.commonService.getFormattedDateTimeZone(
            //     response.creationTime
            //   );
            // response.creationTime = userTimezonecreationTime;
            // this.productList.unshift(response);
            this.currentPage = 1;

            console.log(this.paginationInfo);
            this.loadProductDetailsV2();
            // this.loadItemTable = true;
            this.resetForm();
            this.toaster.success('Product Details Saved!');
          },
          (err) => {
            this.loadItemTable = true;
            const data: HttpErrorResponse = err;
            Swal.fire({
              icon: 'info',
              text: data?.error?.error?.message,
            });
          }
        );
      } else {
        // this.loadItemTable = false;
        this.productDetailsService
          .update(this.productId, productDetails)
          .subscribe(
            (response) => {
              // const index = this.productList.findIndex(
              //   (obj) => obj.id === response?.id
              // );
              // if (index !== -1) {
              //   this.productList[index] = response; //Replace the Object
              //   const objectToMove = this.productList.splice(index, 1)[0]; // Remove and get the object
              //   const userTimezonecreationTime =
              //     this.commonService.getFormattedDateTimeZone(
              //       response.creationTime
              //     );
              //   response.creationTime = userTimezonecreationTime;
              //   const userTimezonelastModificationTime =
              //     this.commonService.getFormattedDateTimeZone(
              //       response.lastModificationTime
              //     );
              //   response.lastModificationTime =
              //     userTimezonelastModificationTime;
              //   this.productList.unshift(objectToMove);
              // }
              this.currentPage = 1;

              console.log(this.paginationInfo);
              this.loadProductDetailsV2();
              this.resetForm();
              // this.loadItemTable = true;
              this.toaster.success('Product Details Updated!');
            },
            (err) => {
              this.loadItemTable = true;
              const data: HttpErrorResponse = err;
              Swal.fire({
                icon: 'info',
                text: data?.error?.error?.message,
              });
            }
          );
      }
    }
  }
  //Get Product Details
  editProduct(itemId: string) {
    const itemDetails = this.productDetailsService.get(itemId).subscribe(
      (response) => {
        this.productId = response.id;
        this.productForm.patchValue({
          txtProductCode: response.productCode,
          txtDescription: response.productDescription,
          drpManufacturer: response.manufacturerId,
          drpCategory: response?.categoryId,
          txtUnitCost: response?.unitCost,
          chkFulfillment: response?.fulfillment == 1 ? true : false,
          chkCDSPriceVerify: response?.cdsPriceVerif,
          chkCDSPartVerify: response?.cdsPartVerif,
          chkHasPPMPart: response?.hasPPMPart,
          chkHasCDSPart: response?.hasCDSPart,
          txtCash: response?.cash,
          txtTicketExtraNote: response?.ticketExtraNote,
          txtMiniQty: response?.minimumQuantity,
          txtLocationBin: response?.binLocation,
          txtSizeId: response?.sizeId,
          chkIsHide: response?.hide == 1 ? true : false,
        });

        const hcpcDetails = this.hcpcCodeService
          .getProductHCPCCodeListByID(itemId)
          .subscribe(
            (response) => {
              this.ltHCPC = response;
            },
            (err) => {}
          );
        this.subscription$.push(hcpcDetails);
      },
      (err) => {}
    );
    this.subscription$.push(itemDetails);
  }
  //To reset the Product Form
  resetForm() {
    this.productForm.reset();

    this.ltHCPC = [];
    this.productId = defaultGuid;
  }
  //To view the hcpc codes
  handleTableCellClick(e: Event, productId: string) {
    let hcpcData = [];
    const hcpcDetails = this.hcpcCodeService
      .getProductHCPCCodeListByID(productId)
      .subscribe(
        (response) => {
          const target = e.target as HTMLElement;
          const tr = target.closest('tr');

          const hcpcCodes = response
            ?.map((item) => item.hcpcCode)
            .filter(Boolean); // Filter out null or undefined values
          const commaSeparatedHcpcs = hcpcCodes.join(', ');

          this.getDataTableData(tr, commaSeparatedHcpcs);
        },
        (err) => {}
      );
    this.subscription$.push(hcpcDetails);
  }

  //DataTable Data Get Method and Toggle Notes Row in UnProcessed Table
  async getDataTableData(tr: any, hcpcData: any): Promise<void> {
    this.myTable = await this.datatableElement.dtInstance;

    if (tr) {
      const row = this.myTable.row(tr);

      if (row.child.isShown()) {
        // This row is already open - close it
        row.child.hide();
      } else {
        // Open this row
        row.child(this.format(row.data(), hcpcData)).show();
      }
    }
  }
  //Add Dynamic Notes Row in UnProcessed Table
  format(d: any, hcpcData: any) {
    let returnString: string = '';
    // hcpcData?.forEach(function (value) {
    returnString += '<tr class="border-bottom"><td>' + hcpcData + '</td></tr>';
    // });
    return returnString;
  }

  decimalValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const value = control.value;
      if (
        value === null ||
        value === undefined ||
        Validators.required(control) !== null
      ) {
        // If the field is required or empty, don't perform decimal validation
        return null;
      }

      // Regular expression for decimal numbers
      const decimalRegExp = /^-?\d*(\.\d+)?$/;

      if (!decimalRegExp.test(value.toString())) {
        return { invalidDecimal: true };
      }

      return null;
    };
  }
  reloadTable() {
    this.loadProductDetailsV2();
    // this.loadItemTable = false;
    // setTimeout(() => {
    //   this.loadItemTable = true;
    // }, 100);
  }
  onKeyPress(event: KeyboardEvent) {
    const isNumber = /[0-9]/.test(event.key);
    if (!isNumber) {
      event.preventDefault(); // Prevent the input if the key is not a number
    }
  }
  changePage(pageNo: number) {
    if (pageNo != this.currentPage && pageNo > 0 && pageNo <= this.totalPages) {
      this.currentPage = pageNo;
      this.loadProductDetailsV2();
    }
  }
}
