import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';
// import { defaultGuid } from 'projects/admin/src/app/document-perviewer/document-perviewer.component';
import { PatientAuthorizationService } from '../patient-proxy/patient-optimization';
import { HttpErrorResponse } from '@angular/common/http';
import Swal from 'sweetalert2';
import { Subscription } from 'rxjs';
import { SharedTableService } from 'projects/shared/src/app/shared-table/shared-table.service';
import { MatDialog } from '@angular/material/dialog';
import { AuthService } from '@abp/ng.core';
import { PatientAuthorizationDTO } from '../patient-proxy/patient-optimization/dto/models';
import { Title } from '@angular/platform-browser';
import { defaultGuid } from 'projects/shared/src/app/enums/allenums.enum';


@Component({
  selector: 'app-patient-authorization-tab',
  templateUrl: './patient-authorization-tab.component.html',
  styleUrls: ['./patient-authorization-tab.component.scss'],
})
export class PatientAuthorizationTabComponent implements OnInit {
  @Output() patientIdEmitter: EventEmitter<string> = new EventEmitter<string>();
  @Input() patientId: string = defaultGuid;
  @ViewChild('authViewerDialog') authViewerDialog = {} as TemplateRef<string>;
  patientInfo: any;
  strPageType: string = 'patientAuthorization';
  arrDisplayedColumns = [
    'options',
    'authId',
    'authBeginDate',
    'authEndDate',
    'authStatus',
    'isFollowUp',
    'units',
    'planName',
    'planType',
    'reference',
  ];
  tblPatientAuthorization: any[] = [];
  tableAuthorizationDetails: PatientAuthorizationDTO[];
  isLoading: boolean = true;
  dialogRef: any;
  subscription$: Subscription[] = [];
  ngOnInit(): void {
    this.title.setTitle('Qsecure | Authorization');
    this.getAuthDetailsList();
  }
  constructor(
    private authService: PatientAuthorizationService,
    private sharedTableService: SharedTableService,
    private dialog: MatDialog,
    public title: Title,
  ) { }
  getAuthDetailsList() {
    this.isLoading = true;
    this.tblPatientAuthorization = [];
    const saleOrderGet = this.authService
      .getPatientAuthByPatientId(this.patientId)
      .subscribe(
        (response) => {
          this.tableAuthorizationDetails = response;
          this.tblPatientAuthorization = response ?? [];
          this.sharedTableService.setTableData(this.tblPatientAuthorization);
          this.isLoading = false;
        },
        (err) => {
          this.sharedTableService.setTableData(this.tblPatientAuthorization);
          this.isLoading = false;
          const data: HttpErrorResponse = err;
          Swal.fire({
            icon: 'info',
            text: data?.error?.error?.message,
          });
        }
      );
    this.subscription$.push(saleOrderGet);
  }
  ViewAuthDetail(authId: string) {
    const authDetails = this.authService
      .getPatientBasicInfoByAuthId(authId)
      .subscribe((response) => {
        this.patientInfo = response;
        this.dialogRef = this.dialog.open(this.authViewerDialog, {
          minWidth: '67vw',
          width: '50vw',
          disableClose: true,
        });
      });

    this.subscription$.push(authDetails);
  }
  close() {
    this.dialog.closeAll();
  }
  loadAuthTable(){
    this.getAuthDetailsList();
  }
}
