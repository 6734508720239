import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { CreateUpdateShippingCheckListDTO } from 'projects/order/src/app/order-proxy/order-management/order/dto/models';
import { SaleorderService } from 'projects/order/src/app/order-proxy/order-management/order/saleorder.service';
import { Subscription } from 'rxjs';
import Swal from 'sweetalert2';
import { ToastrService } from "ngx-toastr";

@Component({
  selector: 'app-decline-update',
  templateUrl: './decline-update.component.html',
  styleUrls: ['./decline-update.component.scss']
})
export class DeclineUpdateComponent implements OnInit , OnDestroy {
  declineForm: FormGroup;
  showButton: boolean = false;
  saveButtonHide: boolean;
  subscription$: Subscription[] = [];
  constructor(public dialogRef: MatDialogRef<DeclineUpdateComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { orderId: string, userName: string },
    private orderService: SaleorderService,
    private fb: FormBuilder,
    private toastr: ToastrService,
    private router: Router) { }

  ngOnInit(): void {
    this.declineForm = this.fb.group({
      txtCsrName: new FormControl(""),
      txtComments: new FormControl("", Validators.required),
    })
    this.getInitialPatch();
  }

  /// get initial data bind
  getInitialPatch() {
    const orderGet = this.orderService.get(this.data.orderId).subscribe(orderResponse => {
      let shippingTicketView = orderResponse?.shippingCheckList;
      if (shippingTicketView?.status === "Returned") {
        this.showButton = true
      }
      this.declineForm.patchValue({
        txtCsrName: shippingTicketView.userName,
        txtComments: shippingTicketView.rejectedReason
      })
    })
    this.subscription$.push(orderGet);
    // this.declineForm.patchValue({
    //   txtCsrName:this.data.userName
    // })
  }

  ngOnDestroy(): void {
    this.subscription$?.forEach((sub) => {
      sub && sub?.unsubscribe();
    });
  }

  /// save decline datas
  declineSave() {
    let CreateUpdateShipping: CreateUpdateShippingCheckListDTO = {

      userName: this.declineForm.value.txtCsrName,
      status: "Shipping Rejected",
      rejectedReason: this.declineForm.value?.txtComments

    }
    this.orderService.updateShippingCheckListByOrderIdAndInput(this.data.orderId, CreateUpdateShipping).subscribe(response => {

      // Swal.fire({ title: 'Success', html: 'Shipping Rejected Successfully', icon: 'success', timer: 3000, timerProgressBar: true });
      this.toastr.success('Shipping Rejected Successfully','Success')
      this.router.navigate(['orders/shippingTicketList']);
      this.dialogRef.close();
    }, err => {
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    });
  }


  /// return reject
  declineReturnSave() {
    let CreateUpdateShipping: CreateUpdateShippingCheckListDTO = {

      userName: this.declineForm.value.txtCsrName,
      status: "Shipping Return Rejected",
      rejectedReason: this.declineForm.value?.txtComments

    }
    this.orderService.updateShippingCheckListByOrderIdAndInput(this.data.orderId, CreateUpdateShipping).subscribe(response => {

      // Swal.fire({ title: 'Success', html: 'Return Rejected Successfully', icon: 'success', timer: 3000, timerProgressBar: true });
      this.toastr.success('Return Rejected Successfully','Success')
      this.router.navigate(['orders/shippingReturnTicketList']);
      this.dialogRef.close();
    }, err => {
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    });
  }

}
