import { RestService } from '@abp/ng.core';
import { Injectable } from '@angular/core';
import type { ContentResult } from '../microsoft/asp-net-core/mvc/models';
import type { CreateUpdateZermidVerificationEligibilityDTO } from '../patient/dto/models';

@Injectable({
  providedIn: 'root',
})
export class ZermidVerificationEligibilityService {
  apiName = 'Default';

  getZermidVerificationEligibilityDetailsByInput = (input: CreateUpdateZermidVerificationEligibilityDTO) =>
    this.restService.request<any, any>({
      method: 'POST',
      url: `/api/app/patientInsurance/ZermidVerificationEligibility`,
      body: input,responseType:'text'

    },
    { apiName: this.apiName, skipHandleError:true });

  constructor(private restService: RestService) {}
}
