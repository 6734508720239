import { ListService, PagedAndSortedResultRequestDto } from '@abp/ng.core';
import { DatePipe } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatOption } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSelect } from '@angular/material/select';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import moment from 'moment';
import { LocaleConfig } from 'ngx-daterangepicker-material';
import { TenantsService } from 'projects/admin/src/app/admin-proxy/platform-app-management/rcm/platform-management/tenant';
import { PaymentViewComponent } from 'projects/admin/src/app/payment-view/payment-view.component';
import { PatientExportDatas, PatientExportDTO } from 'projects/patient/src/app/patient-proxy/patient/dto/models';
import { ExportType } from 'projects/patient/src/app/patient-proxy/patient/enumeration-data/export-type.enum';
import { ReportService } from 'projects/patient/src/app/patient-proxy/patient/report.service';
import { CalendarViewComponent } from 'projects/schedule/src/app/calendar-view/calendar-view.component';
import { orderStatus } from 'projects/shared/src/app/enums/allenums.enum';
import { TableService } from 'projects/shared/src/app/table.service';
import { DateValidator } from 'projects/shared/src/app/validations/date-validator';
import { Observable, Subscription } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { SearchSaleorderDTO } from '../order-proxy/order-management/order/dto/models';

import { SaleOrderSearchService } from '../order-proxy/order-management/order/sale-order-search.service';
import { SaleorderTemplateService } from '../order-proxy/order-management/order/saleorder-template.service';
import { SaleorderService } from '../order-proxy/order-management/order/saleorder.service';
import { RejectedOrderUpdateComponent } from '../rejected-order-update/rejected-order-update.component';
import { ReturnOrderUpdateComponent } from '../return-order-update/return-order-update.component';
import { ToastrService } from "ngx-toastr";
////'../../../../patient/src/assets/images/rnd.jpg'


@Component({
  selector: 'app-renewal-orders-menu',
  templateUrl: './renewal-orders-menu.component.html',
  styleUrls: ['./renewal-orders-menu.component.scss'],
  providers: [ListService, DatePipe]
})
export class RenewalOrdersMenuComponent implements OnInit, OnDestroy {
  @Input() orderId: string = '';
  strPageType: string = "patientRenewalOrders";
  displayedColumns: string[] = [ 'position', 'name', 'sex', 'dob', 'status','options',];
  isLoading: boolean = false;
  organizationUnitId: string = "";
  orderForm: FormGroup;
  orderFilterForm: FormGroup;
  public arrDisplayedColumns: string[] = [];
  dataSource: MatTableDataSource<any> = new MatTableDataSource<any>([]);
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatTable, { static: false }) tableRef: MatTable<any>
  isShownUpdateIconForOrder: boolean = true;
  // dropdown clear valus filter
  orderSearchForm: FormGroup
  saleOrderID: string;
  patientID: string;
  patientName: string;
  CsrName: string;
  ticketDate: string;
  ticketId: string;
  status: string;
  drpSaleOrder;
  billingStatus: orderStatus.billingStatus;
  ReadyToShippingApproval: orderStatus.ReadyToShippingApproval;
  ReadyToApprove: orderStatus.ReadyToApprove;
  ShippingApproved: orderStatus.ShippingApproved;
  ReadyToPack: orderStatus.ReadyToPack;
  ReadyToDelivery: orderStatus.ReadyToDelivery;
  CollectedFromOffice: orderStatus.CollectedFromOffice;
  Returned: orderStatus.Returned;
  ReturnApproved: orderStatus.ReturnApproved;
  ReturnProductReceived: orderStatus.ReturnProductReceived;
  // calender declartions
  selectedRange: { startDate: moment.Moment, endDate: moment.Moment };
  ranges: any;
  minDate: moment.Moment;
  maxDate: moment.Moment;
  calendarLocale: LocaleConfig;
  calendarPlaceholder: string = '';
  // get dropdwon declarations
  filterSaleorder: Observable<any>
  filterPatientId: Observable<any>
  filterPatientName: Observable<any>
  filterStatus: Observable<any>
  filterTicketId: Observable<any>
  filterCsrName: Observable<any>
  drpPatientId: any[] = []
  drpPatientName: any[] = []
  drpStatus: any[] = []
  drpTikcetId: any[] = []
  drpCsrNames
  step: boolean = true;
  subscription$: Subscription[] = [];
  @ViewChild('allSaleOrderSelected') private allSaleOrderSelected: MatOption;
  @ViewChild('saleOrderSelect') saleOrderSelect: MatSelect;

  @ViewChild('allTicketDateSelected') private allTicketDateSelected: MatOption;
  @ViewChild('TicketDate') TicketDate: MatSelect;

  @ViewChild('allTicketIdSelected') private allTicketIdSelected: MatOption;
  @ViewChild('TicketIdSelect') TicketIdSelect: MatSelect;

  @ViewChild('allStatusSelected') private allStatusSelected: MatOption;
  @ViewChild('StatusSelect') StatusSelect: MatSelect;

  @ViewChild('allCsrNameSelected') private allCsrNameSelected: MatOption;
  @ViewChild('CsrNameSelect') CsrNameSelect: MatSelect;

  @ViewChild('allPatientNameSelected') private allPatientNameSelected: MatOption;
  @ViewChild('PatientNameSelect') PatientNameSelect: MatSelect;

  @ViewChild('allPatientIdSelected') private allPatientIdSelected: MatOption;
  @ViewChild('PatientIdSelect') PatientIdSelect: MatSelect;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    public title: Title,
    public list: ListService,
    private snackBar: MatSnackBar,
    public saleOrderService: SaleorderService,
    public orderTemplateService: SaleorderTemplateService,
    public table: TableService,
    public tenantService: TenantsService,

    public searchService: SaleOrderSearchService,
    private dateValidator: DateValidator,
    private datepipe: DatePipe,
    public dialog: MatDialog,
    private orderService: SaleorderService,
    private reportService: ReportService,
    private toastr: ToastrService,
  ) {
    this.calendarLocale = {
      customRangeLabel: 'Pick a date...',
      applyLabel: 'Apply',
      clearLabel: 'Clear',
      format: 'DD/MM/YYYY',
      daysOfWeek: ['su', 'mo', 'tu', 'we', 'th', 'fr', 'sa'],
      monthNames: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
      firstDay: 1
    };
    this.ranges = {
      'Today': [moment(), moment()],
      'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
      'Current week': [moment().startOf('isoWeek'), moment().endOf('isoWeek')],
      'Next weekend': [this.getNextSaturday(), this.getNextSunday()],
      'Next 3 days': [moment().add(1, 'days'), moment().add(3, 'days')],
      'Last 7 Days': [moment().subtract(6, 'days'), moment()],
      'Last 30 Days': [moment().subtract(29, 'days'), moment()],
      // 'Current day': [moment(), moment()],
      // 'Next 2 days': [moment().add(1, 'days'), moment().add(2, 'days')],
      'This Month': [moment().startOf('month'), moment().endOf('month')],
      'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
    };

  }

  ngOnInit(): void {
    this.table.getOrderUpdate().subscribe(value => {
      if (value == true) {
        this.isShownUpdateIconForOrder = true;
      } else {
        this.isShownUpdateIconForOrder = false;
      }
    }, err => {
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    });
    this.snackBar.dismiss();
    // this.organizationUnitId = localStorage.getItem('initialOrganizationUnitId');
    this.orderForm = this.formBuilder.group({
      OrderId: new FormControl(""),
      ItemId: new FormControl(""),
      ItemName: new FormControl(""),
      Quantity: new FormControl(""),
      CreatedDate: new FormControl("", this.dateValidator.dateVaidator),
      Status: new FormControl(""),
      PatientId: new FormControl(""),
      size: new FormControl(""),
      payment: new FormControl(""),
      warehouse: new FormControl(""),
    });
    let filter: { sSaleOrderId: string, sItemId: string, sItemName: string, dtCreatedDate: string, iQuantity: number, sStatus: string, sPatientId: string, size: string } = {
      sSaleOrderId: this.orderForm.value?.OrderId,
      sItemId: this.orderForm.value?.ItemId,
      sItemName: this.orderForm.value?.ItemName,
      dtCreatedDate: this.orderForm.value?.CreatedDate,
      iQuantity: this.orderForm.value?.Quantity,
      sStatus: this.orderForm.value.Status,
      sPatientId: this.orderForm.value.PatientId,
      size: this.orderForm.value.size
    }
    this.orderForm.valueChanges.subscribe(() => {
      filter = {
        sSaleOrderId: this.orderForm.value?.OrderId,
        sItemId: this.orderForm.value?.ItemId,
        sItemName: this.orderForm.value?.ItemName,
        dtCreatedDate: this.orderForm.value?.CreatedDate,
        iQuantity: this.orderForm.value?.Quantity,
        sStatus: this.orderForm.value.Status,
        sPatientId: this.orderForm.value.PatientId,
        size: this.orderForm.value.size
      }
      if (this.orderForm.valid) {
        this.dataSource.filter = JSON.stringify(filter);
        this.getSaleOrderListData(filter);
      }

    }, err => {
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    })
    // field search implementataion
    this.orderFilterForm = this.formBuilder.group({
      txtSaleorderId: new FormControl(""),
      txtSaleOrderSearch: new FormControl(""),
      txtPatientId: new FormControl(""),
      txtPatientIdSearch: new FormControl(""),
      txtPatientName: new FormControl(""),
      txtPatientNameSearch: new FormControl(""),
      txtCsrName: new FormControl(""),
      txtCsrNameSearch: new FormControl(""),
      txtStatus: new FormControl(""),
      txtStatusSearch: new FormControl(""),
      txtTicketId: new FormControl(""),
      txtTicketIdSearch: new FormControl(""),
      txtTicketDate: new FormControl(""),
      txtTicketDateSearch: new FormControl(""),
      txtFromDate: new FormControl(""),
      txtToDate: new FormControl("")
    });
    this.arrDisplayedColumns = ['options','PatientId',  'saleorderId', 'PatientName', 'csrName', 'payment', 'payPalCheckOutOrderPaymentStatus', 'ticketId', 'CreatedDate', 'orderStatus'];
    this.title.setTitle("Qsecure | Existing Orders");
    this.getSaleOrderListData(filter);
    this.dataSource = new MatTableDataSource();
    // this.colorBasedOnCondition();
    this.getDropdown();
    this.step = false;
  }

  ngOnDestroy(): void {
    this.subscription$.forEach((sub) => {
      sub && sub?.unsubscribe();
    });
  }
  //// reject stats
  rejectOrder(data) {
    const dialogRef = this.dialog.open(ReturnOrderUpdateComponent, {
      disableClose: true,
      data: { data: data }
    });

    const closeDialog = dialogRef.afterClosed().subscribe(() => {
      this.getTableData()
    }, err => {
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    });
    this.subscription$.push(closeDialog)
  }
  // Rejected Order Update

  rejectOrderUpdate(data) {
    const dialogRef = this.dialog.open(RejectedOrderUpdateComponent, {
      disableClose: true,

      data: { data: data, rejectedReason: data.rejectedReason }
    });
    const closeDialog = dialogRef.afterClosed().subscribe(() => {
      this.getTableData()
    }, err => {
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    });
    this.subscription$.push(closeDialog);
  }

  //! order list
  getSaleOrderListData(filter: { sSaleOrderId: string, sItemId: string, sItemName: string, dtCreatedDate: string, iQuantity: number, sStatus: string, sPatientId: string, size: string }) {
    this.isLoading = true;
    const saleOrderSearch = this.searchService.searchSaleOrderBySSaleOrderIdAndSItemIdAndSItemNameAndDtCreatedDateAndIQuantityAndSStatusAndSPatientIdAndSItemSize(filter.sSaleOrderId, filter.sItemId, filter.sItemName, dateYYYYMMDDtoMMDDYYYY(filter.dtCreatedDate), filter.iQuantity, filter.sStatus, filter.sPatientId, filter.size).subscribe(response => {
      let data: infOrdersTable[] = [];
      response?.items?.forEach((element, index) => {
        if (element?.orderProcessStatus !== 'Voided order') {
          element?.orders && data?.push({
            Sno: String(index + 1),
            id: element?.id === null || element?.id === undefined ? "" : element?.id,
            saleorderId: element?.saleorderId === null || element?.saleorderId === undefined ? "" : element?.saleorderId,
            itemId: element?.items[0]?.itemCodeId === null || element?.items[0]?.itemCodeId === undefined ? "" : element?.items[0]?.itemCodeId,
            itemName: element?.items[0]?.name === null || element?.items[0]?.name === undefined ? "" : element?.items[0]?.name,
            PatientId: String(element?.patients?.defaultPatientId),
            PatientName: element?.patients?.personals?.fullName,
            csrName: element?.shippingCheckList?.userName?.toLocaleLowerCase()?.trim() ?? "",
            quantity: element?.items[0]?.qty === null || element?.items[0]?.qty === undefined ? 0 : element?.items[0]?.qty,
            CreatedDate: element?.creationTime === null || element?.creationTime === undefined ? "" : this.datepipe.transform(element?.creationTime, 'MM/dd/yyyy') ?? "",
            //Status: element?.orders?.isCompleted === null || element?.orders?.isCompleted === undefined ? "" : element?.orders?.isCompleted,
            orderStatus: element?.orderStatus,
            size: element?.items[0]?.itemSize === null || element?.items[0]?.itemSize === undefined ? "" : element?.items[0]?.itemSize,
            billingStatus: element?.orderBilling?.status,
            ticketId: element?.shippingCheckList?.defaultTicketId,
            ticketCreatedDate: element?.shippingCheckList?.ticketCreatedDate === null || element?.shippingCheckList?.ticketCreatedDate === undefined ? "" : this.datepipe.transform(element?.shippingCheckList?.ticketCreatedDate, 'MM/dd/yyyy') ?? "",
            cash: element?.isCashPayment,
            wipStatusType: element?.orders?.wipStateId,
            insPayment: element?.isCashPayment !== 0,
            cashPayment: element?.isCashPayment !== 1,
            payPalCheckOutOrderPaymentStatus: element?.payPalPaymentStatus,
            status: element?.shippingCheckList?.status,
            returnReason: element?.shippingCheckList?.returnReason,
            productWeight: element?.shippingCheckList?.productWeight,
            methodOfdelivery: element?.shippingCheckList?.methodOfdelivery,
            modeOfDelivery: element?.shippingCheckList?.modeOfDelivery,
            rejectedReason: element?.shippingCheckList?.rejectedReason,
            currentOrderStatus: element?.currentOrderStatus,
            orderPayStatus: element?.orders?.isCompleted,
            billStatus: orderStatus.ReadyToApprove
          });
        } else {

        }
      });
      this.isLoading = false
      let arrTableData = data;
      this.dataSource = new MatTableDataSource(arrTableData);
      this.sort.disableClear = true;
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
    }, err => {
      this.isLoading = false;
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    });
    this.subscription$.push(saleOrderSearch);
  }
  //! view records
  existingOrders(orderId: string) {
    this.router.navigate(['/patient/saleorderCreateEdit/' + orderId])
  }
  //! Download Order template , Generate Template
  getOrderTemplate(orderValue: { id: string, saleorderId?: string }) {
    if (orderValue?.id) {
      const orderTemplate = this.orderTemplateService.getSaleOrderDocumentByOrderId(orderValue?.id).subscribe(response => {
        if (typeof response === 'string' && response && response?.length !== 0) {
          const filelocation: String = String('data:application/pdf;base64,' + response);
          const link = document?.createElement('a');
          link.download = orderValue?.saleorderId + '.pdf';
          link.href = filelocation?.toString();
          link?.click();
        }
      }, err => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      });
      this.subscription$.push(orderTemplate);
    }

  }
  //! move to next bin
  moveToOrder(value) {

  }

  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;

  }



  getTableData() {
    const ordergetList = this.orderService.getList(new PagedAndSortedResultRequestDto).subscribe(response => {
      let data: infOrdersTable[] = [];
      response?.items?.forEach((element, index) => {
        if (element?.orderProcessStatus !== 'Voided order') {
          element?.orders && data?.push({
            Sno: String(index + 1),
            id: element?.id === null || element?.id === undefined ? "" : element?.id,
            saleorderId: element?.saleorderId === null || element?.saleorderId === undefined ? "" : element?.saleorderId,
            itemId: element?.items[0]?.itemCodeId === null || element?.items[0]?.itemCodeId === undefined ? "" : element?.items[0]?.itemCodeId,
            itemName: element?.items[0]?.name === null || element?.items[0]?.name === undefined ? "" : element?.items[0]?.name,
            PatientId: String(element?.patients?.defaultPatientId),
            PatientName: element?.patients?.personals?.fullName,
            quantity: element?.items[0]?.qty === null || element?.items[0]?.qty === undefined ? 0 : element?.items[0]?.qty,
            CreatedDate: element?.creationTime === null || element?.creationTime === undefined ? "" : this.datepipe.transform(element?.creationTime, 'MM/dd/yyyy') ?? "",
            //Status: element?.orders?.isCompleted === null || element?.orders?.isCompleted === undefined ? "" : element?.orders?.isCompleted,
            orderStatus: element?.orderStatus,
            size: element?.items[0]?.itemSize === null || element?.items[0]?.itemSize === undefined ? "" : element?.items[0]?.itemSize,
            billingStatus: element?.orderBilling?.status,
            ticketId: element?.shippingCheckList?.defaultTicketId,
            ticketCreatedDate: element?.shippingCheckList?.ticketCreatedDate === null || element?.shippingCheckList?.ticketCreatedDate === undefined ? "" : this.datepipe.transform(element?.shippingCheckList?.ticketCreatedDate, 'MM/dd/yyyy') ?? "",
            cash: element?.isCashPayment,
            wipStatusType: element?.orders?.wipStateId,
            insPayment: element?.isCashPayment !== 0,
            cashPayment: element?.isCashPayment !== 1,
            payPalCheckOutOrderPaymentStatus: element?.payPalPaymentStatus,
            status: element?.shippingCheckList?.status,
            csrName: element?.shippingCheckList?.userName?.toLocaleLowerCase()?.trim() ?? "",
            returnReason: element?.shippingCheckList?.returnReason,
            productWeight: element?.shippingCheckList?.productWeight,
            methodOfdelivery: element?.shippingCheckList?.methodOfdelivery,
            modeOfDelivery: element?.shippingCheckList?.modeOfDelivery,
            rejectedReason: element?.shippingCheckList?.rejectedReason,
            currentOrderStatus: element?.currentOrderStatus,
            orderPayStatus: element?.orders?.isCompleted,
            billStatus: orderStatus.ReadyToApprove
          });
        } else {

        }

      });
      this.isLoading = false;
      let arrTableData = data;
      this.dataSource = new MatTableDataSource(arrTableData);
      this.sort.disableClear = true;
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;

    }, err => {
      this.isLoading = false;
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    });
    this.subscription$.push(ordergetList)
  }

  //! initiate payment
  paymentInitiate(record: { payPalCheckOutOrderPaymentStatus: string; id: any; }) {
    if (record.payPalCheckOutOrderPaymentStatus === "Initiate Payment" || "Link Expired") {

      const dialogRef = this.dialog.open(PaymentViewComponent, {
        disableClose: true,
        data: { orderId: record.id }

      });
      const closeDialog = dialogRef.afterClosed().subscribe(() => {
        this.getTableData();
      })
      this.subscription$.push(closeDialog);

    } else {
      const dialogRef = this.dialog.open(PaymentViewComponent, {
        disableClose: true,
        data: { orderId: record.id }
      });
      const closeDialog = dialogRef.afterClosed().subscribe(() => {
        this.getTableData();
      })
      this.subscription$.push(closeDialog)
    }
    // if (record.payPalCheckOutOrderPaymentStatus === "Money Transferred to Client Account" || record.payPalCheckOutOrderPaymentStatus === "Mail Sent") {

    // }

  }

  // Field fiter Search
  // dropdown clear functionality
  clearSaleOrderId() {
    this.saleOrderID = '';
    this.orderFilterForm.patchValue({ txtSaleorderId: '' });
  }
  clearPatientId() {
    this.patientID = "";
    this.orderFilterForm.patchValue({ txtPatientId: '' });
  }
  clearPatientName() {
    this.patientName = "";
    this.orderFilterForm.patchValue({ txtPatientName: '' });
  }
  clearCsrName() {
    this.CsrName = "";
    this.orderFilterForm.patchValue({ txtCsrName: '' });
  }
  clearTicketDate() {
    this.ticketDate = "";
    this.orderFilterForm.patchValue({ txtFromDate: '' });
  }
  clearTicketId() {
    this.ticketId = "";
    this.orderFilterForm.patchValue({ txtTicketId: '' });
  }
  clearStatus() {
    this.status = "";
    this.orderFilterForm.patchValue({ txtStatus: '' });
  }
  // reset table
  reset() {
    this.orderFilterForm.reset();
    this.getTableData();
  }

  // Filter Clear for saleorder id
  onFilterSaleorderIdChange() {
    this.clearPatientId();
    this.clearPatientName();
    this.clearCsrName();
    this.clearTicketDate();
    this.clearTicketId();
    this.clearStatus();
  }
  // Filter Clear for Patient id
  onFilterPatientIdChange() {
    this.clearSaleOrderId();
    this.clearPatientName();
    this.clearCsrName();
    this.clearTicketDate();
    this.clearTicketId();
    this.clearStatus();
  }
  // Filter Clear for Patient Name
  onFilterPatientNameChange() {
    this.clearSaleOrderId();
    this.clearPatientId();
    this.clearCsrName();
    this.clearTicketDate();
    this.clearTicketId();
    this.clearStatus();
  }
  // Filter Clear for CSR Name
  onFilterCSRNameChange() {
    this.clearSaleOrderId();
    this.clearPatientId();
    this.clearPatientName();
    this.clearTicketDate();
    this.clearTicketId();
    this.clearStatus();
  }
  // Filter Clear for Ticket id
  onFilterTicketIdChange() {
    this.clearSaleOrderId();
    this.clearPatientId();
    this.clearPatientName();
    this.clearTicketDate();
    this.clearCsrName();
    this.clearStatus();
  }
  // Filter Clear for Ticket id
  onFilterStatusChange() {
    this.clearSaleOrderId();
    this.clearPatientId();
    this.clearPatientName();
    this.clearTicketDate();
    this.clearCsrName();
    this.clearTicketId();
  }


  //filter clear for Ticket Date
  change() {
    this.clearSaleOrderId();
    this.clearPatientId();
    this.clearPatientName();
    this.clearTicketId()
    this.clearCsrName();
    this.clearStatus();
  }

  // search table
  tableData: any[] = [];
  search() {
    let saleordersIds: any[] = [];
    let patientIds: any[] = [];
    let patientNames: any[] = [];
    let csrNames: any[] = [];
    let TicketIdSearch: any[] = [];
    let status: any[] = [];

    this.orderFilterForm.value.txtSaleorderId !== "" &&
      this.orderFilterForm.value.txtSaleorderId !== undefined &&
      this.orderFilterForm.value.txtSaleorderId !== null &&
      [this.orderFilterForm.value.txtSaleorderId].length !== 0 &&
      [this.orderFilterForm.value.txtSaleorderId].forEach(val => {
        val?.forEach(element => {
          element !== undefined && element !== 0 && saleordersIds.push(element);
        });
      });

    this.orderFilterForm.value.txtPatientId !== "" &&
      this.orderFilterForm.value.txtPatientId !== undefined &&
      this.orderFilterForm.value.txtPatientId !== null &&
      [this.orderFilterForm.value.txtPatientId].length !== 0 &&
      [this.orderFilterForm.value.txtPatientId].forEach(val => {
        val?.forEach(element => {
          element !== undefined && element !== 0 && patientIds.push(element);
        });
      });

    this.orderFilterForm.value.txtPatientName !== "" &&
      this.orderFilterForm.value.txtPatientName !== undefined &&
      this.orderFilterForm.value.txtPatientName !== null &&
      [this.orderFilterForm.value.txtPatientName].length !== 0 &&
      [this.orderFilterForm.value.txtPatientName].forEach(val => {
        val?.forEach(element => {
          element !== undefined && element !== 0 && patientNames.push(element);
        });
      });

    this.orderFilterForm.value.txtTicketId !== "" &&
      this.orderFilterForm.value.txtTicketId !== undefined &&
      this.orderFilterForm.value.txtTicketId !== null &&
      [this.orderFilterForm.value.txtTicketId].length !== 0 &&
      [this.orderFilterForm.value.txtTicketId].forEach(val => {
        val?.forEach(element => {
          element !== undefined && element !== 0 && TicketIdSearch.push(element);
        });
      });

    this.orderFilterForm.value.txtStatus !== "" &&
      this.orderFilterForm.value.txtStatus !== undefined &&
      this.orderFilterForm.value.txtStatus !== null &&
      [this.orderFilterForm.value.txtStatus].length !== 0 &&
      [this.orderFilterForm.value.txtStatus].forEach(val => {
        val?.forEach(element => {
          element !== undefined && element !== 0 && status.push(element);
        });
      });

    this.orderFilterForm.value.txtCsrName !== "" &&
      this.orderFilterForm.value.txtCsrName !== undefined &&
      this.orderFilterForm.value.txtCsrName !== null &&
      [this.orderFilterForm.value.txtCsrName].length !== 0 &&
      [this.orderFilterForm.value.txtCsrName].forEach(val => {
        val?.forEach(element => {
          element !== undefined && element !== 0 && csrNames.push(element);
        });
      });

    let shippingTicketSearch: SearchSaleorderDTO = {
      patientNames: patientNames,
      userIds: csrNames,
      patientIds: patientIds,
      orderIds: saleordersIds,
      ticketIds: TicketIdSearch,
      status: status == null || "" || undefined ? [] : status,
      ticketCreatedFromDate: this.selectedRange == null || "" || undefined ? null : this.selectedRange.startDate?.format('YYYY-MM-DD'),
      ticketCreatedToDate: this.selectedRange == null || "" || undefined ? null : this.selectedRange.endDate?.format('YYYY-MM-DD')
    }

    const orderSearch = this.orderService.searchSaleorderByInput(shippingTicketSearch).subscribe(response => {

      this.tableData = [];

      response && response?.items?.forEach((element) => {
        if (element?.orderProcessStatus !== 'Voided order') {
          this.tableData.push({
            id: element?.id,
            saleorderId: element?.saleorderId,
            PatientId: element?.patients?.defaultPatientId,
            PatientName: element?.patients?.personals?.fullName,
            csrName: element?.shippingCheckList?.userName?.toLocaleLowerCase()?.trim() ?? "",
            ticketId: element?.shippingCheckList?.defaultTicketId,
            orderStatus: element?.orderStatus,
            ticketCreatedDate: element?.shippingCheckList?.ticketCreatedDate == null ? undefined : new Date(element?.shippingCheckList?.ticketCreatedDate)?.toLocaleDateString("en-US"),
            payPalCheckOutOrderPaymentStatus: element?.payPalPaymentStatus,
            cashPayment: element?.isCashPayment !== 1,
            status: element?.shippingCheckList?.status,
            billingStatus: element?.orderBilling?.status,
          });
        }
      })
      this.dataSource = new MatTableDataSource(this.tableData);
      this.sort.disableClear = true;
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
    });
    this.subscription$.push(orderSearch);
  }
  // calender

  getNextSaturday() {
    const dayINeed = 6; // for Saturday
    const today = moment().isoWeekday();
    if (today <= dayINeed) {
      return moment().isoWeekday(dayINeed);
    } else {
      return moment().add(1, 'weeks').isoWeekday(dayINeed);
    }
  }

  getNextSunday() {
    const dayINeed = 7; // for Sunday
    const today = moment().isoWeekday();
    if (today <= dayINeed) {
      return moment().isoWeekday(dayINeed);
    } else {
      return moment().add(1, 'weeks').isoWeekday(dayINeed);
    }
  }

  viewCalendar(id: string) {
    const dialogRef = this.dialog.open(CalendarViewComponent, {
      disableClose: true,
      // height: "80%",
      width: "80%",
      // maxHeight: '200px',
      // maxWidth: '600px',
      data: { calendarId: id }
    });

    const closeDialog = dialogRef.afterClosed().subscribe(() => {
    });
    this.subscription$.push(closeDialog);
  }

  // get dropdown for filter search
  getDropdown() {
    const warehouseDropdown = this.orderService.getWarehouseDropdowns().subscribe(response => {
      this.drpSaleOrder = response.orderIds
      this.drpPatientId = response.patientIds
      this.drpPatientName = response.patientNames
      this.drpStatus = response.status
      this.drpTikcetId = response.ticketIds
      this.drpCsrNames = response.userDetails

      this.filterSaleorder = this.orderFilterForm?.get("txtSaleOrderSearch").valueChanges
        .pipe(
          startWith(''),
          map(value => {
            return this.drpSaleOrder.filter(option => option?.toLowerCase()?.includes(value?.toLowerCase() ?? ""))
          })
        );

      this.filterPatientId = this.orderFilterForm?.get("txtPatientIdSearch").valueChanges
        .pipe(
          startWith(''),
          map(value => {
            return this.drpPatientId.filter(option => option?.toLowerCase()?.includes(value?.toLowerCase() ?? ""))
          })
        );

      this.filterPatientName = this.orderFilterForm?.get("txtPatientNameSearch").valueChanges
        .pipe(
          startWith(''),
          map(value => {
            return this.drpPatientName.filter(option => option?.toLowerCase()?.includes(value?.toLowerCase() ?? ""))
          })
        );

      this.filterTicketId = this.orderFilterForm?.get("txtTicketIdSearch").valueChanges
        .pipe(
          startWith(''),
          map(value => {
            return this.drpTikcetId.filter(option => option?.toLowerCase()?.includes(value?.toLowerCase() ?? ""))
          })
        );


      this.filterCsrName = this.orderFilterForm?.get("txtCsrNameSearch").valueChanges
        .pipe(
          startWith(''),
          map(value => {
            return this.drpCsrNames.filter(option => option?.userName.toLowerCase()?.includes(value?.toLowerCase() ?? ""))
          })
        );

      this.filterStatus = this.orderFilterForm?.get("txtStatusSearch").valueChanges
        .pipe(
          startWith(''),
          map(value => {
            return this.drpStatus.filter(option => option?.toLowerCase()?.includes(value?.toLowerCase() ?? ""))
          })
        );

    })
    this.subscription$.push(warehouseDropdown);
  }

  // select all functionality
  toggleAllSaleorderIdSelection() {
    if (this.allSaleOrderSelected.selected) {
      this.saleOrderSelect.options.forEach((item: MatOption) => item.select());
    }
    else {
      this.saleOrderSelect.options.forEach((item: MatOption) => item.deselect());
    }
  }

  toggleAllPatientIdSelection() {
    if (this.allPatientIdSelected.selected) {
      this.PatientIdSelect.options.forEach((item: MatOption) => item.select());
    }
    else {
      this.PatientIdSelect.options.forEach((item: MatOption) => item.deselect());
    }
  }

  toggleAllPatientNameSelection() {
    if (this.allPatientNameSelected.selected) {
      this.PatientNameSelect.options.forEach((item: MatOption) => item.select());
    }
    else {
      this.PatientNameSelect.options.forEach((item: MatOption) => item.deselect());
    }
  }

  toggleAllCsrNamesSelection() {
    if (this.allCsrNameSelected.selected) {
      this.CsrNameSelect.options.forEach((item: MatOption) => item.select());
    }
    else {
      this.CsrNameSelect.options.forEach((item: MatOption) => item.deselect());
    }
  }

  toggleAllStatusSelection() {
    if (this.allStatusSelected.selected) {
      this.StatusSelect.options.forEach((item: MatOption) => item.select());
    }
    else {
      this.StatusSelect.options.forEach((item: MatOption) => item.deselect());
    }
  }

  toggleAllTicketIdSelection() {
    if (this.allTicketIdSelected.selected) {
      this.TicketIdSelect.options.forEach((item: MatOption) => item.select());
    }
    else {
      this.TicketIdSelect.options.forEach((item: MatOption) => item.deselect());
    }
  }

  toggleAllTicketDateSelection() {
    if (this.allTicketDateSelected.selected) {
      this.TicketDate.options.forEach((item: MatOption) => item.select());
    }
    else {
      this.TicketDate.options.forEach((item: MatOption) => item.deselect());
    }
  }

  exportTableData() {
    let datas: PatientExportDatas[] = []
    this.dataSource?.data?.forEach(element => {

      datas.push({
        patientDefaultId: element?.PatientId,
        saleOrderId: element?.saleorderId,
        patientName: element?.PatientName,
        csrName: element?.csrName,
        cashPayment: String(element?.cashPayment === true ? "No" : element?.cashPayment === false ? "yes" : ""),
        paymentStatus: element?.payPalCheckOutOrderPaymentStatus,
        ticketId: element?.ticketId,
        ticketCreatedDate: element?.ticketCreatedDate,
        orderStatus: element?.status
      });
    })
    let patientsExport: PatientExportDTO = {
      columns: ["Patient Id", "Sale Order Id", "Patient Name", "CSR Name", "Cash Payment", "Payment Status", "Ticket Id", "Ticket Created Date", "Status"],
      datas: datas
    };
    const exportData = this.reportService.exportPatientReportsByExportTypeAndExportDatas(ExportType.OrderReports, patientsExport).subscribe(response => {
      if (response && response?.length !== 0) {
        let filelocation: String = String("data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64," + response);
        let link = document.createElement("a");
        link.download = ("Orders-List") + ".xlsx";
        link.href = filelocation.toString();
        link.click();
        // Swal.fire({ title: 'Success', html: 'Exported Successfully', icon: 'success', timer: 3000, timerProgressBar: true })
        this.toastr.success('Exported Successfully','Success')
      }
    }, err => {
      const data: HttpErrorResponse = err;
      let errorMesg: string = data?.error;
      if (errorMesg?.includes('Sorry!! There is no data in table to export')) {
        errorMesg = 'Sorry!! There is no data in table to export';
      }
      else {
        errorMesg = data?.error?.error?.message;
      }
      // Swal.fire({
      //   icon: 'info',
      //   text: errorMesg
      // });
      this.toastr.info(errorMesg)
    });
    this.subscription$.push(exportData);
  }
}
export interface infOrdersTable {
  id?: string,
  Sno: string,
  saleorderId: string,
  PatientId: string,
  PatientName: string,
  // hCPCS: string
  itemId: string
  itemName: string
  quantity: number,
  isActive?: boolean,
  CreatedDate: string,
  orderStatus: string,
  size: string
  billingStatus: string,
  ticketId: string;
  ticketCreatedDate: string,
  cashPayment: any;
  wipStatusType: string;
  insPayment: any,
  cash: any,
  payPalCheckOutOrderPaymentStatus: string,
  status: string,
  csrName: string,
  returnReason: any;
  productWeight: any;
  methodOfdelivery: any;
  modeOfDelivery: any;
  rejectedReason: any;
  currentOrderStatus: any;
  orderPayStatus: any;
  billStatus: any
}
// function dateYYYYMMDDtoMMDDYYYY(strDate: string): string {
//   if (typeof strDate === 'string' && strDate !== null && strDate !== undefined && strDate.length >= 1) {
//     let latest_date = formatDate(strDate, 'yyyy-MM-dd', 'en_US')
//     return latest_date;
//   }
//   return "";
// }
function dateYYYYMMDDtoMMDDYYYY(strDate: string): string {
  if (strDate && strDate !== "" && typeof strDate === 'string' && strDate !== null && strDate !== undefined && strDate.length >= 1 && !isNaN(new Date(strDate).getTime())) {
    let latest_date = Intl.DateTimeFormat('en-US')?.format(new Date(strDate))
    return latest_date;
  }
  return "";
}


