import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import {
  PatientMasterDropdownDTO,
  SaleOrRentDTO,
} from '../dropdown-proxy/dropdown-management/dropdowns/dto/patient';
import {
  CreateUpdateItemAuthDetailsDTO,
  CreateUpdatePatientAuthorizationDTO,
  PatientAuthorizationDTO,
  ProviderNPIdetailsDTO,
} from '../patient-proxy/patient-optimization/dto';
import { Observable, Subscription } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import Swal from 'sweetalert2';
import { ProductDetailsService } from 'projects/inventory/src/app/item-proxy/item-management/optimization';
import { MatSelect, MatSelectChange } from '@angular/material/select';
import {
  OrderMasterDropdownService,
  PatientMasterDropdownService,
} from '../dropdown-proxy/dropdown-management/dropdowns/master-dropdown-service';
import {
  OrderDropdowns,
  PatientDropdowns,
} from '../dropdown-proxy/dropdown-management/dropdowns';
import { OrderMasterDropdownDTO } from '../dropdown-proxy/dropdown-management/dropdowns/dto/order';
import {
  PatientAuthorizationService,
  PatientPersonalService,
} from '../patient-proxy/patient-optimization';
// import { defaultGuid } from 'projects/admin/src/app/document-perviewer/document-perviewer.component';
import { TableService } from 'projects/shared/src/app/table.service';
import { PatientAuthorizationDetailsComponent } from '../patient-authorization-details/patient-authorization-details.component';
import { ToastrService } from 'ngx-toastr';
import { MatOption } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { PDFDocument } from 'pdf-lib';
import { data } from 'jquery';
import { ItemEligibilityRulesService } from 'projects/admin/src/app/warehouse-proxy/inventory';
import { noWhitespaceValidator } from '../add-note-tab/add-note-tab.component';

@Component({
  selector: 'app-patient-auth-save-form',
  templateUrl: './patient-auth-save-form.component.html',
  styleUrls: ['./patient-auth-save-form.component.scss'],
})
export class PatientAuthSaveFormComponent implements OnInit {
  // @Input() patientInfo: any;
  // @Input() patientId: string;
  // @Input() isView?: boolean;
  // @Input() isSupplies?: boolean;
  // @Input() docId?: string;
  // @ViewChild('documentViewerDialog') documentViewerDialog =
  //   {} as TemplateRef<string>;
  // @ViewChild(PatientAuthorizationDetailsComponent)
  // PatientAuthorizationDetailsComponent: PatientAuthorizationDetailsComponent;
  // @ViewChild('allDocumentSelected') private allDocumentSelected: MatOption;
  // @ViewChild('documentSelect') documentSelect: MatSelect;
  // public filteredDocuments: Observable<any[]> | undefined;
  // finalPdfBase64: string = '';
  // selectedStatus: string = '';
  // documentsValue: string[] = [];
  // lstDocs: any[] = [];
  // authStatus: string;
  // pdfHeight: string = '100%';
  // ltProducts: any;
  // ltAuthStatus: any;
  // drpPlanTypes: any;
  // referenceValue: string;
  // statusValue: string;
  // filteredItemTypes: Observable<any[]>;
  // authorizationForm: FormGroup;
  // //itemsList: FormArray;
  // notesText = '';
  // @ViewChild('itemSelect') itemSelect: MatSelect;
  // drpPlanName: any[]; //Plan name Dropdown
  // planName: string; //Plan name Value
  // planType: string; //PlanType Value
  // viewBtnSpinner: boolean = false;
  // drpItemCodes: any;
  // item: string[] = [];
  // sale: string;
  // tableData: CreateUpdateItemAuthDetailsDTO[] = [];
  // dialogRef: any;
  // drpSaleOrRents: SaleOrRentDTO[] = [];
  // subscription$: Subscription[] = [];
  // step: boolean;
  // constructor(
  //   private fb: FormBuilder,
  //   private productDetailsService: ProductDetailsService,
  //   private dropdownService: OrderMasterDropdownService,
  //   private patientDropdownService: PatientMasterDropdownService,
  //   private patientAuthService: PatientAuthorizationService,
  //   private table: TableService,
  //   private toastr: ToastrService,
  //   private patientPersonal: PatientPersonalService,
  //   public dialog: MatDialog,
  //   private itemService: ItemEligibilityRulesService
  // ) {}

  ngOnInit(): void {
    // this.initializeForms();
    // this.loadDropdowns();
    // this.getPatientAuthDetails();
    // !this.isSupplies && this.loadNewSetup();
    // this.isSupplies && this.loadSupplies();
  }
  // getPatientAuthDetails() {
  //   this.patientPersonal
  //     .getPatientIdBasicDetailsV1(this.patientId, this.docId)
  //     .subscribe(
  //       (response) => {
  //         this.patientInfo = response;
  //         this.loadAuthDetails(this.patientInfo);
  //         this.lstDocs = this.patientInfo?.lstDocs;
  //       },
  //       (err) => {
  //         Swal.fire({
  //           icon: 'error',
  //           text: 'Something Went Wrong!',
  //         });
  //       }
  //     );
  // }

  // //Load Supplies Details
  // loadSupplies() {
  //   this.authStatus = this.patientInfo.authStatus ?? '';
  //   this.planName = this.patientInfo?.policyName ?? '';
  //   this.planType = this.patientInfo?.typeofPlanId ?? defaultGuid;
  //   this.authorizationForm.patchValue({
  //     txtPlanName: this.planName,
  //     txtPlanType: this.patientInfo?.typeofPlanId,
  //   });
  //   this.loadDropdowns();
  //   if (
  //     this.patientInfo.authDetails != null &&
  //     this.patientInfo.authDetails.id !== null &&
  //     this.patientInfo.authDetails.id !== defaultGuid &&
  //     this.patientInfo.authDetails.id !== ''
  //   ) {
  //     this.loadAuthDetails(this.patientInfo.authDetails);
  //   } else {
  //     this.tableData = this.patientInfo.resupplyItems ?? [];
  //     for (const item of this.tableData) {
  //       const fields = this.fb.group({
  //         lblItemId: [item.itemId, []],
  //         lblItemName: [item.itemName, []],
  //         txtUnit: ['', Validators.required],
  //         drpSaleOrRent: ['', Validators.required],
  //       });
  //       this.fieldsArray.push(fields);
  //     }
  //   }

  //   this.onReferenceChange();
  // }
  // //Load New Setup Details
  // loadNewSetup() {
  //   if (this.isView) {
  //     this.step = true;
  //     this.authorizationForm.disable();
  //   }

  //   this.authStatus = this.patientInfo.authStatus ?? '';
  //   this.planName = this.patientInfo?.policyName ?? '';
  //   this.planType = this.patientInfo?.typeofPlanId ?? defaultGuid;
  //   this.authorizationForm.patchValue({
  //     txtPlanName: this.planName,
  //     txtPlanType: this.patientInfo?.typeofPlanId,
  //   });
  //   this.loadDropdowns();
  //   this.patientInfo.authDetails != null &&
  //     this.patientInfo.authDetails.id !== null &&
  //     this.patientInfo.authDetails.id !== defaultGuid &&
  //     this.patientInfo.authDetails.id !== '' &&
  //     this.loadAuthDetails(this.patientInfo);
  //   this.onReferenceChange();
  // }

  // //To Load the Auth Details
  // // loadAuthDetails(authDetails: PatientAuthorizationDTO) {
  // loadAuthDetails(patientInfo: ProviderNPIdetailsDTO) {
  //   console.log(patientInfo);
  //   this.authorizationForm.patchValue({
  //     drpReference: patientInfo.authDetails?.reference || '',
  //     drpStatus: patientInfo.authDetails?.authStatus || '',
  //     txtSpokeWith: patientInfo.authDetails?.spokeWith || '',
  //     txtFaxNumber: patientInfo.authDetails?.faxNumber || '',
  //     txtAuthId: patientInfo.authDetails?.authId || null,
  //     txtPlanName: patientInfo?.policyName || '',
  //     txtPlanType: patientInfo.typeofPlanId || '',
  //     txtStartDate: patientInfo.authDetails?.startDate || '',
  //     txtEndDate: patientInfo.authDetails?.endDate || '',
  //     drpDocument: patientInfo.authDetails?.sortedDocsId || '',
  //     // drpItem:
  //     //   patientInfo.authDetails?.itemAuthDetails &&
  //     //   patientInfo.authDetails.itemAuthDetails.length > 0
  //     //     ? patientInfo.authDetails.itemAuthDetails[0].itemName
  //     //     : '',
  //     txtUnits:
  //       patientInfo.resupplyItems && patientInfo.resupplyItems.length > 0
  //         ? patientInfo.resupplyItems[0]?.units || ''
  //         : '',
  //     drpSaleOrRent:
  //       patientInfo.resupplyItems && patientInfo.resupplyItems.length > 0
  //         ? patientInfo.resupplyItems[0]?.saleOrRent || ''
  //         : '',

  //     txtNotes: patientInfo.authDetails?.note || '',
  //   });
  //   this.onStatusChange(patientInfo.authStatus);
  //   const base64Data =
  //     patientInfo.authDetails === null
  //       ? ''
  //       : patientInfo.authDetails.authFileContent.split(',')[1];
  //   this.finalPdfBase64 = base64Data ?? '';
  //   this.documentsValue = patientInfo?.authDetails?.sortedDocsId ?? [];
  //   this.referenceValue = patientInfo?.authDetails?.reference ?? '';
  //   this.notesText = patientInfo?.authDetails.note ?? '';
  //   this.docId = patientInfo?.authDetails?.documentId ?? defaultGuid;
  //   console.log(this.docId);
  //   this.tableData = []; //patientInfo.authDetails.itemAuthDetails ?? [];

  //   for (const item of this.tableData) {
  //     const fields = this.fb.group({
  //       lblItemId: [item.itemId, []],
  //       lblItemName: [item.itemName, []],
  //       txtUnit: [item.units, Validators.required],
  //       drpSaleOrRent: [item.saleOrRent, Validators.required],
  //     });
  //     this.fieldsArray.push(fields);
  //   }
  // }
  // //To open the Document Viewer with Merged Selected Documents
  // openDocumentViewer() {
  //   this.viewBtnSpinner = true;
  //   let value = this.documentsValue.filter((a) => a != '0' && a != undefined);
  //   const personal = this.patientPersonal.getAuthFiles(value).subscribe(
  //     (response) => {
  //       this.finalPdfBase64 = response;
  //       this.viewBtnSpinner = false;
  //       this.dialogRef = this.dialog.open(this.documentViewerDialog, {
  //         // minHeight: '95vh',
  //         minWidth: '67vw',
  //         width: '50vw',
  //         // height: '95vh',
  //         disableClose: true,
  //       });
  //     },
  //     (err) => {
  //       this.toastr.error('Something Went Wrong!');
  //       this.viewBtnSpinner = false;
  //       this.dialog.closeAll();
  //     }
  //   );

  //   this.subscription$.push(personal);
  // }

  // //Select All Documents
  // toggleAllDocumentsSelection() {
  //   if (this.allDocumentSelected.selected) {
  //     this.documentSelect.options.forEach((item: MatOption) => item.select());
  //   } else {
  //     this.documentSelect.options.forEach((item: MatOption) => item.deselect());
  //   }
  // }

  // //Initialize Form Method
  // initializeForms() {
  //   this.authorizationForm = this.fb.group({
  //     drpReference: new FormControl('', [Validators.required]),
  //     drpStatus: new FormControl('', [Validators.required]),
  //     drpDocument: new FormControl(''),
  //     selectDocument: new FormControl(''),
  //     txtAuthId: new FormControl(null),
  //     txtPlanName: new FormControl(''),
  //     txtPlanType: new FormControl(''),
  //     txtStartDate: new FormControl(''),
  //     txtEndDate: new FormControl(''),
  //     drpItem: new FormControl(''),
  //     // txtSearchFilter: new FormControl(''),
  //     txtUnits: new FormControl(''),
  //     txtSaleOrRent: new FormControl(''),
  //     chkIsFollowUp: new FormControl(''),
  //     txtNotes: new FormControl(''),
  //     referenceValue: new FormControl(''),
  //     statusValue: new FormControl(''),
  //     txtSpokeWith: new FormControl(''),
  //     txtFaxNumber: new FormControl(''),
  //     itemsList: this.fb.array([]),
  //   });
  //   //this.itemsList = this.authorizationForm.get('itemsList') as FormArray;
  // }
  // //On Reference Change Function
  // //On Reference Change Function
  // onReferenceChange() {
  //   const reference = this.authorizationForm.get('drpReference').value;
  //   this.authorizationForm.get('txtSpokeWith').disable();
  //   this.authorizationForm.get('txtFaxNumber').disable();
  //   this.authorizationForm.get('drpDocument').disable();
  //   if (reference === 'Call') {
  //     this.authorizationForm.get('txtSpokeWith').enable();
  //     this.authorizationForm?.controls?.txtSpokeWith?.setValidators([
  //       Validators.required,
  //       noWhitespaceValidator,
  //     ]);
  //   } else if (reference === 'Fax') {
  //     this.authorizationForm.get('txtFaxNumber').enable();
  //     this.authorizationForm?.controls?.txtFaxNumber?.setValidators([
  //       Validators.required,
  //     ]);
  //     this.authorizationForm.get('drpDocument').enable();
  //     this.authorizationForm?.controls?.drpDocument?.setValidators([
  //       Validators.required,
  //     ]);
  //   } else {
  //     this.authorizationForm?.controls?.txtSpokeWith?.setValidators([
  //       Validators.nullValidator,
  //     ]);
  //     this.authorizationForm?.controls?.txtFaxNumber?.setValidators([
  //       Validators.nullValidator,
  //     ]);
  //     this.authorizationForm?.controls?.drpDocument?.setValidators([
  //       Validators.nullValidator,
  //     ]);
  //   }
  // }
  // onStatusChange(event: any) {
  //   if (event.source === undefined) {
  //     this.selectedStatus = event;
  //   } else {
  //     this.selectedStatus = event.source.selected.viewValue;
  //   }
  //   if (
  //     this.selectedStatus === 'Approved' ||
  //     this.selectedStatus === 'Pre-Determination Approved'
  //   ) {
  //     //Validation Conditions based on Auth Status
  //     this.authorizationForm?.controls?.txtAuthId?.setValidators([
  //       Validators.required,
  //       noWhitespaceValidator,
  //     ]);
  //     this.authorizationForm?.controls?.txtStartDate?.setValidators([
  //       Validators.required,
  //     ]);
  //     this.authorizationForm?.controls?.txtEndDate?.setValidators([
  //       Validators.required,
  //     ]);
  //     this.authorizationForm?.controls?.txtPlanName?.setValidators([
  //       Validators.required,
  //     ]);
  //     this.authorizationForm?.controls?.txtAuthId?.updateValueAndValidity();
  //     this.authorizationForm?.controls?.txtPlanName?.updateValueAndValidity();
  //     this.authorizationForm?.controls?.txtStartDate?.updateValueAndValidity();
  //     this.authorizationForm?.controls?.txtEndDate?.updateValueAndValidity();
  //   } else {
  //     this.authorizationForm?.controls?.txtAuthId?.setValidators([
  //       Validators.nullValidator,
  //     ]);
  //     this.authorizationForm?.controls?.txtStartDate?.setValidators([
  //       Validators.nullValidator,
  //     ]);
  //     this.authorizationForm?.controls?.txtEndDate?.setValidators([
  //       Validators.nullValidator,
  //     ]);
  //     this.authorizationForm?.controls?.txtPlanName?.setValidators([
  //       Validators.nullValidator,
  //     ]);
  //     this.authorizationForm?.controls?.txtAuthId?.updateValueAndValidity();
  //     this.authorizationForm?.controls?.txtStartDate?.updateValueAndValidity();
  //     this.authorizationForm?.controls?.txtEndDate?.updateValueAndValidity();
  //     this.authorizationForm?.controls?.txtPlanName?.updateValueAndValidity();
  //   }
  // }
  // resetForm() {
  //   // if (!this.isSupplies) {
  //   //   this.fieldsArray.clear();
  //   //   this.authorizationForm.reset();
  //   // } else if (this.isSupplies) {
  //   this.authorizationForm.get('txtAuthId').setValue('');
  //   this.authorizationForm.get('txtPlanType').setValue('');
  //   this.authorizationForm.get('txtStartDate').setValue(null);
  //   this.authorizationForm.get('txtEndDate').setValue(null);
  //   this.authorizationForm.get('txtNotes').setValue('');
  //   this.authorizationForm.get('drpReference').setValue('');
  //   this.authorizationForm.get('drpStatus').setValue('');
  //   this.authorizationForm.get('txtFaxNumber').setValue('');
  //   this.authorizationForm.get('txtSpokeWith').setValue('');
  //   this.authorizationForm.get('drpDocument').setValue([]);
  //   this.fieldsArray.controls.forEach((control) => {
  //     control.get('txtUnit').setValue(''); // or control.get('field2').setValue(null) to set it to null
  //     control.get('drpSaleOrRent').setValue(''); // or control.get('field3').setValue(null) to set it to null
  //   });
  //   // }
  //   this.referenceValue = '';
  //   this.finalPdfBase64 = '';
  //   this.documentsValue = [];
  //   this.notesText = '';
  //   this.planType = '';
  //   this.tableData = !this.isSupplies ? [] : this.tableData;
  // }
  // // Load Dropdowns
  // loadDropdowns() {
  //   //To Load Sale or Rent Dropdown
  //   let patientDropdownInput: PatientDropdowns[];
  //   patientDropdownInput = [
  //     PatientDropdowns.SaleOrRent,
  //     PatientDropdowns.AuthStatus,
  //   ];
  //   const getPatientDropdown = this.patientDropdownService
  //     .getPatientDropdownsByInput(patientDropdownInput)
  //     .subscribe((response: PatientMasterDropdownDTO) => {
  //       this.drpSaleOrRents = response.saleOrRents;
  //       this.ltAuthStatus = response.lstAuthStatus;
  //     });
  //   this.subscription$.push(getPatientDropdown);
  //   let orderDropdownInput: OrderDropdowns[];
  //   orderDropdownInput = [OrderDropdowns.PlanTypes];
  //   //Load Plan Types
  //   const planTypes = this.dropdownService
  //     .getOrderDropdownsByInput(orderDropdownInput)
  //     .subscribe((stateResponse) => {
  //       let response: OrderMasterDropdownDTO = stateResponse;
  //       this.drpPlanTypes = response?.planTypes;
  //     });
  //   this.subscription$.push(planTypes);

  //   //Products Dropdown data
  //   const getProduct = this.itemService
  //     // .getProductDetailsforSaleOrder()
  //     .getActiveProductCategoryV1()
  //     .subscribe(
  //       (response) => {
  //         this.ltProducts = response ?? [];
  //         this.filteredItemTypes = this.authorizationForm
  //           ?.get('txtSearchFilter')
  //           .valueChanges.pipe(
  //             startWith(''),
  //             map((value) =>
  //               this.ltProducts?.filter((option) =>
  //                 option?.productName
  //                   ?.toLowerCase()
  //                   ?.includes(value?.toLowerCase() ?? '')
  //               )
  //             )
  //           );
  //       },
  //       (err) => {
  //         const data: HttpErrorResponse = err;
  //         Swal.fire({
  //           icon: 'info',
  //           text: data?.error?.error?.message,
  //         });
  //       }
  //     );
  //   this.subscription$.push(getProduct);
  // }
  // toggle() {
  //   this.step = true;
  // }
  // toggleV2() {
  //   this.step = false;
  // }
  // //Save Authorization
  // saveAuthorization(patientId: string, wipId: string) {
  //   //this.getTableData();
  //   let saveData: CreateUpdatePatientAuthorizationDTO = {
  //     patientId: patientId,
  //     documentId: this.patientInfo.authDetails.docu ?? null,
  //     authStatus: this.authorizationForm.value.drpStatus,
  //     authorizationId:
  //       this.patientInfo.authDetails === null
  //         ? ''
  //         : this.patientInfo.authDetails.authorizationId,
  //     authId: this.authorizationForm.value.txtAuthId,
  //     startDate: this.authorizationForm.value.txtStartDate,
  //     endDate: this.authorizationForm.value.txtEndDate,
  //     authFileContent: this.finalPdfBase64 ?? '',
  //     // itemAuthDetails: this.tableData,
  //     note: this.authorizationForm.value.txtNotes,
  //     // used: '0',
  //     planName: this.authorizationForm.value.txtPlanName,
  //     planType: null,
  //     typeofPlanId: this.authorizationForm.value.txtPlanType,
  //     isExpired: 0,
  //     reference: this.authorizationForm.value.drpReference,
  //     spokeWith: this.authorizationForm.value.txtSpokeWith,
  //     faxNumber: this.authorizationForm.value.txtFaxNumber,
  //     policyId: this.patientInfo.policyId,
  //     wipId: this.patientInfo.wipId,
  //     sortedDocsId: this.authorizationForm.value.drpDocument,
  //     // authAmount: this.patientInfo.authAmount,
  //     tenantId:
  //       this.patientInfo.authDetails === null
  //         ? ''
  //         : this.patientInfo.authDetails.tenantId,
  //     orderId: [],
  //     // authDocsId: [],
  //     cptPrecercodes: null,
  //     supplies:[],
  //     oxygenPrecercodes: null,
  //     diagnosis: []
  //   };
  //   if (
  //     this.patientInfo.authDetails != null &&
  //     this.patientInfo.authDetails.id !== null &&
  //     this.patientInfo.authDetails.id !== defaultGuid &&
  //     this.patientInfo.authDetails.id !== ''
  //   ) {
  //     this.patientAuthService
  //       .update(this.patientInfo.authDetails.id, saveData)
  //       .subscribe(
  //         (response) => {
  //           this.authStatus = response.authStatusName;
  //           this.toastr.success('Auth Saved Successfully!');
  //           this.toggleV2();
  //         },
  //         (err) => {
  //           this.toastr.error('Something Went Wrong!');
  //         }
  //       );
  //   } else {
  //     this.patientAuthService.create(saveData).subscribe(
  //       (response) => {
  //         this.authStatus = response.authStatusName;
  //         this.patientInfo.authDetails = response;
  //         this.toastr.success('Auth Updated Successfully!');
  //         this.toggleV2();
  //       },
  //       (err) => {
  //         this.toastr.error('Something Went Wrong!');
  //       }
  //     );
  //   }
  // }
  // //Add Item to table Data
  // addItemToTable() {
  //   var checkExist = this.tableData?.filter(
  //     (a) => a.itemId === this.authorizationForm.value.drpItem?.id
  //   );

  //   //this.getTableData();
  //   if (checkExist.length === 0) {
  //     const itemAuthDetails: CreateUpdateItemAuthDetailsDTO = {
  //       itemId: this.authorizationForm.value.drpItem?.id,
  //       itemName: this.authorizationForm.value.drpItem?.productName,
  //     };

  //     const fields = this.fb.group({
  //       lblItemId: [itemAuthDetails.itemId, []],
  //       lblItemName: [itemAuthDetails.itemName, []],
  //       txtUnit: ['', Validators.required],
  //       drpSaleOrRent: ['', Validators.required],
  //     });
  //     this.tableData.push(itemAuthDetails);
  //     this.fieldsArray.push(fields);
  //   } else {
  //     this.toastr.error('Item Already Added!');
  //   }
  // }
  // //Get Rent or Sale Dropdown values in table
  // getData(val, itemId: string) {
  //   const tableValue = this.tableData?.find((a) => a.itemId === itemId);
  //   tableValue.saleOrRent = val.value ?? null;
  // }
  // addUnitToTable(event: any, itemId: string) {
  //   const tableValue = this.tableData?.find((a) => a.itemId === itemId);
  //   tableValue.units = event.target.value ?? '';
  // }
  // //Delete Item from a Table
  // deleteItem(id: string, index: number) {
  //   this.tableData = this.tableData.filter((item) => item.itemId !== id);
  //   this.fieldsArray.removeAt(index);
  // }
  // //Send Fax
  // sendFax() {
  //   this.toastr.success('Fax Sent Successfully!');
  //   this.dialog.closeAll();
  // }
  // get fieldsArray() {
  //   return this.authorizationForm.get('itemsList') as FormArray;
  // }
  // removeField(index: number) {
  //   this.fieldsArray.removeAt(index);
  // }
}
