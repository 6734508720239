import { PagedAndSortedResultRequestDto } from '@abp/ng.core';
import { DatePipe } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { HolidayYearsDTO, OnTimeMasterDropdownDTO } from 'projects/patient/src/app/dropdown-proxy/rcm/dropdown-management/dropdowns/dto/on-time-schedule';
import { OnTimeMasterDropdownService } from 'projects/patient/src/app/dropdown-proxy/rcm/dropdown-management/dropdowns/master-dropdown-service/on-time-master-dropdown.service';
import { OnTimeDropdowns } from 'projects/patient/src/app/dropdown-proxy/rcm/dropdown-management/dropdowns/on-time-dropdowns.enum';
import { DateValidator } from 'projects/shared/src/app/validations/date-validator';
import { delay } from 'rxjs/operators';

import Swal from 'sweetalert2';

import { HolidayService } from '../onTime-proxy/rcm/on-time-management/on-time-schedule';
import { CreateUpdateHolidayDTO, SearchHolidayDTO } from '../onTime-proxy/rcm/on-time-management/on-time-schedule/dto';
import { Title } from '@angular/platform-browser';
import { ToastrService } from "ngx-toastr";

@Component({
  selector: 'app-holiday',
  templateUrl: './holiday.component.html',
  styleUrls: ['./holiday.component.scss'],
  providers: [DatePipe]
})
export class HolidayComponent implements OnInit {
  saveButtonHide: boolean;
  isLoading: boolean;
  step: boolean = false;
  holidayForm: FormGroup;
  holidaySearchForm: FormGroup;
  holidayTotalSearchForm: FormGroup;
  BirthdateMaxDate: Date;
  BirthdateMinDate: Date;
  chosenYearDate: Date;
  dataSource: MatTableDataSource<any>;
  holidayId: string = "";
  displayedColumns: string[] = [ 'HolidayDate', 'holidayDescription','Options',];
  holidayTableData: any[] = [];
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatTable, { static: false }) tableRef: MatTable<any>;
  year: string = "";
  drpYear: HolidayYearsDTO[] = [];
  isShownSaveButton: boolean = false;
  constructor(
    private formBuilder: FormBuilder,
    private holidayService: HolidayService,
    protected datepipe: DatePipe,
    private onTimeMasterDropdown: OnTimeMasterDropdownService,
    private dateValidator: DateValidator,
    public title: Title,
    private toastr: ToastrService,
  ) { }

  ngOnInit(): void {

    this.title.setTitle("Qsecure | Holiday");
    this.holidayForm = this.formBuilder.group({
      txtHolidayName: new FormControl(),
      txtDate: new FormControl(""),
      drpYear: new FormControl()
    })

    this.holidaySearchForm = this.formBuilder.group({
      holidayName: new FormControl(""),
      holidayDate: new FormControl("", this.dateValidator.dateVaidator),
      drpYearSearch: new FormControl()
    })

    this.holidayTotalSearchForm = this.formBuilder.group({
      drpYear: new FormControl(),
    });

    this.holidayTotalSearchForm.valueChanges.subscribe(
      value => {
        const filter: SearchHolidayDTO = {
          holidayName: null,
          holidayDate: null,
          holidayYear: value?.drpYear
        };
        this.dataSource.filter = JSON.stringify(filter);
        this.filtertable(filter);
      });

    this.holidaySearchForm.valueChanges.subscribe((value: SearchHolidayDTO) => {
      const filter: SearchHolidayDTO = {
        holidayName: value?.holidayName?.trim()?.toLowerCase(),
        holidayDate: value?.holidayDate?.trim()?.toLowerCase(),
      };

      if (this.holidaySearchForm.valid) {
        this.dataSource.filter = JSON.stringify(filter);
        this.filtertable(filter);
      }
    }, err => {
    });
    this.getTableData();
    this.getDropowns();
  }

  saveHoliday() {
    this.isShownSaveButton = true;
    const holiday: CreateUpdateHolidayDTO = {
      holidayName: this.holidayForm.value.txtHolidayName,
      holidayDate: this.holidayForm.value.txtDate
    };
    if ((this.holidayId == null || this.holidayId == "" || this.holidayId == undefined)) {
      this.holidayService.create(holiday).subscribe(response => {
        this.toastr.success('Saved Successfully','Success');
        // Swal.fire({ title: 'Success', html: 'Saved Successfully', icon: 'success', timer: 3000, timerProgressBar: true }).then(result => {
        //   if (result.value) {

        //   }
        // }, err => { });
        this.isShownSaveButton = false;
        this.reset();
        this.getTableData();
      }, err => {
        this.saveButtonHide = false;
        this.isShownSaveButton = false;
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      });
    } else {
      this.holidayService.update(this.holidayId, holiday).subscribe(response => {
        this.toastr.success('Updated Successfully','Success');
        // Swal.fire({ title: 'Success', html: 'Saved Successfully', icon: 'success', timer: 3000, timerProgressBar: true }).then(result => {
        //   if (result.value) {

        //   }
        this.isShownSaveButton = false;
        this.saveButtonHide = false;
        // }, err => { });
        this.reset();
        this.getTableData();
      }, err => {
        this.isShownSaveButton = false;
        this.saveButtonHide = false;
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      });

    }
  }

  reset() {
    this.holidayForm.patchValue({
      txtHolidayName: "",
      txtDate: "",

    });
    this.holidayId = '';
    this.holidayForm.reset();
    this.holidayForm.markAsUntouched();
    this.holidayForm.updateValueAndValidity();

  }

  getTableData() {
    this.isLoading = true
    this.holidayService.getList(new PagedAndSortedResultRequestDto).subscribe(response => {
      this.holidayTableData = [];
      response && response?.items?.forEach(element => {
        this.holidayTableData.push({
          data: element?.id,
          holidayName: element?.holidayName,
          holidayDate: element?.holidayDate === null ? "" : this.datepipe.transform(element?.holidayDate, 'MM/dd/yyyy') ?? ""
        });
      })
      const arrTableData = this.holidayTableData;
      this.dataSource = new MatTableDataSource(arrTableData);
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
      this.isLoading = false;
    }, err => {
      this.isLoading = false;
      const data: HttpErrorResponse = err;
      // Swal.fire({
      //   icon: 'error',
      //   text: data?.error?.error?.message,
      // });
    })
  }


  // ! edit holiday
  ViewHoliday(data) {
    this.step = !this.step;
    this.holidayId = data.data;
    this.holidayService.get(this.holidayId).subscribe(response => {
      this.holidayForm.patchValue({
        txtHolidayName: response?.holidayName,
        txtDate: response?.holidayDate
      })
    });
  }

  /// delete Holiday
  deleteHoliday(data) {
    Swal.fire({
      title: 'Are you sure you want to Delete?',
      text: 'You won\'t be able to retrieve this data!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#34c38f',
      cancelButtonColor: '#f46a6a',
      confirmButtonText: 'Yes, delete it!'
    }).then(result => {
      if (result.value) {
        this.holidayService.delete(data.data).subscribe(response => {
          this.holidayTableData.forEach(element => {
            if (data.id == element?.id) {
              const index = this.holidayTableData.indexOf(element, 0)
              this.holidayTableData.splice(index, 1)
            }
          });
          this.getTableData();
        }, err => {
          const data: HttpErrorResponse = err;

          Swal.fire({
            icon: 'info',
            text: data?.error?.error?.message,
          });
        });
      }
    });
    this.reset();
  }


  getDropowns() {
    let onTimeMasterDropdown: OnTimeDropdowns[];
    onTimeMasterDropdown = [
      OnTimeDropdowns.HolidayYears,
    ]
    this.onTimeMasterDropdown.getOnTimeDropdownsByInput(onTimeMasterDropdown).subscribe((stateResponse) => {
      const response: OnTimeMasterDropdownDTO = stateResponse;
      this.drpYear = response.holidayYears;
    })
  }

  filtertable(filter) {

    const Date = filter.holidayDate;
    const searchInput: SearchHolidayDTO = {
      holidayName: filter?.holidayName,
      holidayDate: dateYYYYMMDDtoMMDDYYYY(Date),
      holidayYear: null
    }
    this.holidayTableData = [];
    this.holidayService.searchHolidaysByInput(filter).subscribe(data => {
      this.holidayTableData = [];
      data?.items.forEach(element => {
        this.holidayTableData.push({
          holidayName: element?.holidayName,
          holidayDate: element?.holidayDate === null ? "" : this.datepipe.transform(element?.holidayDate, 'MM/dd/yyyy') ?? "",
          data: element?.id,
        });


      });
      this.dataSource = new MatTableDataSource(this.holidayTableData);
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
    })
  }


  clearYear() {
    this.year = '';
    this.holidayForm.patchValue({ drpyear: '' });
    this.getTableData();
  }



}
function dateYYYYMMDDtoMMDDYYYY(strDate: string): string {
  if (strDate && strDate !== "" && typeof strDate === 'string' && strDate !== null && strDate !== undefined && strDate.length >= 1 && !isNaN(new Date(strDate).getTime())) {
    const latest_date = Intl.DateTimeFormat('en-US')?.format(new Date(strDate))
    return latest_date;
  }
  return "";
}
