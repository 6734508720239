<mat-dialog-content class="mat-typography">
    <div class="modal-header">
        <h1 class="modal-title">{{remindertitle}}</h1>
        <i aria-label="Close" mat-dialog-close class="fa fa-times"></i>
    </div>

    <form [formGroup]="reminderForm">
        <div class="modal-body">
            <div class="row">
                <!--User Drop Down-->
                <mat-form-field class="col-4">
                    <!-- <mat-label>User <span class="asterisk">*</span></mat-label> -->
                    <mat-label>
                        <div class="select-placeholder-container" *ngIf="isUserLoading">
                          <span>Loading...</span>
                          <mat-spinner class="spinner" diameter="20"></mat-spinner>
                        </div>
                        <span *ngIf="!isUserLoading">Assigned To <span class="asterisk">*</span></span>
                    </mat-label>
                    <mat-select formControlName="drpUsertype">
                        <mat-option>
                            <ngx-mat-select-search [formControl]="UserTypeControl" placeholderLabel="Search"
                                noEntriesFoundLabel="No Matches found"
                                formControlName="txtUserType"></ngx-mat-select-search>
                        </mat-option>
                        <mat-option *ngFor="let users of lstFilterUsers" [value]="users?.userId">
                            {{ users?.userDesignation }}
                        </mat-option>
                    </mat-select>

                    <mat-error class="spanAlignment"
                        *ngIf="isUsertypeTouched && reminderForm?.get('drpUsertype')?.errors?.required">
                        User is a required field!
                    </mat-error>
                </mat-form-field>

                <!--Note type Drop Down-->
                <mat-form-field class="col-4">
                    <!-- <mat-label>Note Type <span class="asterisk">*</span></mat-label> -->
                    <mat-label>
                        <div class="select-placeholder-container" *ngIf="isNoteLoading">
                          <span>Loading...</span>
                          <mat-spinner class="spinner" diameter="20"></mat-spinner>
                        </div>
                        <span *ngIf="!isNoteLoading">Note Type <span class="asterisk">*</span></span>
                    </mat-label>
                    <mat-select formControlName="drpNotetypev1">
                        <mat-option>
                            <ngx-mat-select-search [formControl]="NoteTypeControl" placeholderLabel="Search"
                                noEntriesFoundLabel="No Matches found"
                                formControlName="txtNoteType"></ngx-mat-select-search>
                        </mat-option>
                        <mat-option *ngFor="let note of drpNotetype" [value]="note.id">
                            {{ note.noteType }}
                        </mat-option>
                    </mat-select>
                    <mat-error class="spanAlignment"
                        *ngIf="isNotetypeTouched && reminderForm?.get('drpNotetypev1')?.errors?.required">
                        Note Type is a required field!
                    </mat-error>
                </mat-form-field>

                <!--Status Drop Down-->
                <!-- <mat-form-field class="col-4" *ngIf="!status">
                    <mat-label>Status</mat-label>
                    <mat-select formControlName="drpStatustype" #singleSelect>
                        <mat-option>
                            <ngx-mat-select-search [formControl]="StatusTypeControl" placeholderLabel="Search"
                                noEntriesFoundLabel="No Matches found"
                                formControlName="txtStatus"></ngx-mat-select-search>
                        </mat-option>
                        <mat-option *ngFor="let status of statusdrp" [value]="status?.id">
                            {{ status?.remainderStatus }}
                        </mat-option>
                    </mat-select>
                </mat-form-field> -->

                <!--Patient Drop Down-->
                <!-- <mat-form-field class="col-4">
                    <mat-label>
                        <div class="select-placeholder-container" *ngIf="isPatientLoading">
                          <span>Loading...</span>
                          <mat-spinner class="spinner" diameter="20"></mat-spinner>
                        </div>
                        <span *ngIf="!isPatientLoading">Patient</span>
                    </mat-label>

                    <mat-select formControlName="drpSearchPatient" [disabled]="!isdisable">
                        <mat-option>
                            <ngx-mat-select-search placeholderLabel="Search" noEntriesFoundLabel="No Matches found"
                                formControlName="txtPatientFilter"></ngx-mat-select-search>
                        </mat-option>
                        <mat-option *ngFor="let patients of filteredPatients | async" [value]="patients.guid">
                            {{ patients.idAndNameAndDOB }}
                        </mat-option>
                    </mat-select>
                </mat-form-field> -->

                <mat-form-field class="col-4">
                    <mat-label>
                        <div class="select-placeholder-container" *ngIf="isPatientLoading">
                          <span>Loading...</span>
                          <mat-spinner class="spinner" diameter="20"></mat-spinner>
                        </div>
                        <span *ngIf="!isPatientLoading">Patient</span>
                    </mat-label>

                    <mat-select formControlName="drpSearchPatient" #singleSelect [disabled]="!isdisable" (openedChange)="onDropdownOpenChange($event)">
                      <mat-option>
                        <ngx-mat-select-search (keyup)="
                            searchPatient($event.target.value, singleSelect.value)
                          " placeholderLabel="Search" noEntriesFoundLabel="No Matches found"
                          formControlName="txtPatientFilter"></ngx-mat-select-search>
                      </mat-option>
                      <mat-option *ngFor="let chart of lstChartNos" [value]="chart.patientId">
                        {{ chart.ddValue }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>


                <!--Priority Drop Down-->
                <mat-form-field class="col-4 mt-1">
                    <mat-label>Priority<span class="asterisk">*</span></mat-label>
                    <mat-select formControlName="drpPrioritytype">
                        <mat-option>
                            <ngx-mat-select-search [formControl]="PriorityTypeControl" placeholderLabel="Search"
                                noEntriesFoundLabel="No Matches found"
                                formControlName="txtPriorityType"></ngx-mat-select-search>
                        </mat-option>
                        <mat-option *ngFor="let data of Prioritydrp" [value]="data.id">
                            {{ data.remainderPriority }}
                        </mat-option>
                    </mat-select>
                    <mat-error class="spanAlignment"
                        *ngIf="isPrioritytypeTouched && reminderForm?.get('drpPrioritytype')?.errors?.required">
                        Priority is a required field!
                    </mat-error>
                </mat-form-field>

                <!-- Due Date Field -->
                <mat-form-field class="col-4">
                    <mat-label>Due Date <span class="asterisk">*</span></mat-label>
                    <input autocomplete="new-txtDuedate" formControlName="txtDuedate" matInput maxlength="10"
                        type="text" placeholder="(MM/DD/YYYY)" [matDatepicker]="txtDuedate" [min]="minDate"
                        onkeydown="this.value=this.value.replace(/^(\d\d)(\d)$/g,'$1/$2').replace(/^(\d\d\/\d\d)(\d+)$/g,'$1/$2').replace(/[^\d\/]/g,'')"/>
                    <mat-datepicker-toggle matSuffix [for]="txtDuedate"></mat-datepicker-toggle>
                    <mat-datepicker #txtDuedate></mat-datepicker>

                    <mat-error *ngIf="
        reminderForm?.get('txtDuedate')?.touched &&
        reminderForm?.get('txtDuedate')?.hasError('matDatepickerParse')
    ">
                        Enter a valid date!
                    </mat-error>

                    <mat-error *ngIf="
        reminderForm?.get('txtDuedate')?.touched &&
        reminderForm?.get('txtDuedate')?.errors?.required &&
        !reminderForm?.get('txtDuedate')?.hasError('matDatepickerParse')
    ">
                        Due Date is a required field!
                    </mat-error>

                    <mat-error *ngIf="
        reminderForm?.get('txtDuedate')?.touched &&
        !reminderForm?.get('txtDuedate')?.errors?.required &&
        minDate > reminderForm?.get('txtDuedate')?.value
    ">
                        Enter Future Dates Only!
                    </mat-error>
                </mat-form-field>

                <!--Description Field-->
                <mat-form-field class="col-12">
                    <mat-label>Description<span class="asterisk">*</span></mat-label>
                    <textarea matInput rows="5" autocomplete="off" maxlength="500" formControlName="txtDescription" [(ngModel)]="notesText" (input)="updateCharacterCount(500)"
                        type="text"></textarea>
                        <div class="char-count">
                            {{ notesText?.length }} / 500
                          </div>
                    <mat-error class="spanAlignment"
                        *ngIf="isDescriptionTouched && reminderForm?.get('txtDescription')?.hasError('required')">
                        Description is a required field!
                    </mat-error>
                    <mat-error class="spanAlignment"
                        *ngIf="isDescriptionTouched && reminderForm?.get('txtDescription')?.hasError('whitespace')">
                        Description cannot be just whitespace!
                    </mat-error>
                </mat-form-field>
            </div>
        </div>

        <div class="modal-footer">
            <div class="col-12 text-center mt-3">
                <button mat-button class="buttonColor mr-2"
                    [disabled]="isShowSpinner || !(!reminderForm?.invalid && reminderForm?.dirty)"
                    (click)="saveReminder()">
                    <!-- <span>Save</span> -->
                    <span>{{issave}}</span>
                    <mat-icon *ngIf="isShowSpinner">
                        <mat-spinner class="spinner-border spinner-border-sm" diameter="20"></mat-spinner>
                    </mat-icon>
                </button>
                <button mat-button class="resetclr mr-2" (click)="resetReminder()">Reset</button>
            </div>
        </div>
    </form>
</mat-dialog-content>
