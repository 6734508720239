<div class="py-2" style="border: 2px">
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-5 d-inline-flex align-items-center">
        <h3 class="font-weight-bolder mb-3">
          <b class="customThemeClass">Master / Notes</b>
        </h3>
      </div>
      <div class="col-7">
        <div class="d-flex flex-row-reverse" style="margin-right: 8%;">
          <mat-radio-group [(ngModel)]="selectedOption" (change)="loadNotesTable()">
            <mat-radio-button [value]="0" class="active"><b>All</b></mat-radio-button>
            <mat-radio-button [value]="1" class="active p-2" [checked]="true"><b>Active</b></mat-radio-button>
            <mat-radio-button [value]="2" class="active"><b>Inactive</b></mat-radio-button>
          </mat-radio-group>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="card card-body">
  <app-inventory-notes-table [tableData]="NotesTableDate" *ngIf="!isLoading"></app-inventory-notes-table>
  <!--Loader Section-->
  <div class="row" *ngIf="isLoading">
    <div class="col-12">
      <div class="d-flex justify-content-center" *ngIf="isLoading">
        <img class="img-responsive pb-2 mr-1" src="assets/gifs/Infinity-loader.gif" />
      </div>
    </div>
  </div>
</div>
<br>