<div class="container-fluid">
  <!-- <div class="row bg"> -->
  <div class="col-12">
    <div class="d-flex align-item-center justify-content-between m-0">
      <h3 class="mt-2 mb-0 font-size-18"><b>Purchase Order</b></h3>
      <div class=" page-title-box  page-title-right mt-1 mb-0">
        <ol class="breadcrumbs m-0">
              <!-- (click)="exportTableData()" -->
              <!-- <button mat-button class="mr-2 buttonColor">
                Export
              </button> -->
          <li class="mr-3">
            <button matTooltip="Click To Goback to StockList" mat-button class="mt-0 btn btn-sm lightSeaGreen-dot"
              (click)="backToList()">
              <i class="fa fa-backward mr-1" ></i> Back To Purchase Order List
            </button>
          </li>
          <li class="breadcrumb-item">Inventory</li>
          <li class="breadcrumb-item">Add Purchase Order</li>
        </ol>
      </div>
    </div>
  </div>

</div>
<!-- <div class="">
<div class="row">
  <div class="col-lg-4">
    <h2>Add Purchase Order</h2>
  </div>

</div>

<mat-dialog-content class="mat-typography"> -->

<div class="card card-body">
  <form [formGroup]="purchaseOrderForm">
    <div class="col-lg-12">
      <mat-card-content>
        <div class="row">
          <mat-form-field class="col-3" [ngClass]="{fieldHide:purchaseId !==undefined}">
            <mat-label>Vendor Name <span class="asterisk">*</span> </mat-label>

            <mat-select (selectionChange)="onChanges($event)" formControlName="txtVendorName" [(value)]="supplyVendor"
              #singleSelect>
              <mat-option>
                <ngx-mat-select-search placeholderLabel="Search" noEntriesFoundLabel="No Matches found"
                  formControlName="txtVendorFilter"></ngx-mat-select-search>
              </mat-option>
              <mat-option *ngFor="let vendor of filterVendor | async" [value]="vendor.name">
                {{vendor.name}}
              </mat-option>
            </mat-select>

            <button mat-button (click)="supplyVendor=undefined;$event.stopPropagation()" *ngIf="supplyVendor" matSuffix
              (click)="clearsupplyVendor()" mat-icon-button >
              <mat-icon>close</mat-icon>
            </button>
            <mat-error
              *ngIf="purchaseOrderForm?.get('txtVendorName')?.touched &&purchaseOrderForm?.get('txtVendorName')?.errors?.required">
              Vendor Name is a required field!
            </mat-error>
          </mat-form-field>

          <mat-form-field class="col-3 readonly">
            <mat-label>Vendor Code</mat-label>
            <input autocomplete="new-txtVCode" formControlName="txtVCode" matInput type="text" />
          </mat-form-field>
          <mat-form-field class="col-3 readonly">
            <mat-label>Vendor Address</mat-label>
            <input autocomplete="new-txtVAddress" formControlName="txtVAddress" matInput type="text" />
          </mat-form-field>
          <mat-form-field class="col-3 readonly">
            <mat-label>Vendor Email Id</mat-label>
            <input autocomplete="new-txtVEmail" formControlName="txtVEmail" matInput type="text" />
          </mat-form-field>


          <mat-form-field class="col-3 readonly">
            <mat-label>Country</mat-label>
            <mat-select formControlName="txtVCountry" (selectionChange)='onChangeState($event.value)'
              [(value)]="Country" #singleSelect>
              <mat-option>
                <ngx-mat-select-search placeholderLabel="Search" noEntriesFoundLabel="No Matches found"
                  formControlName="txtCountryFilter"></ngx-mat-select-search>
              </mat-option>
              <mat-option *ngFor="let country of filteredCountries | async" [value]="country.countryShortName">
                {{ country.countryName }}
              </mat-option>
            </mat-select>
            <button mat-button (click)="Country=undefined;$event.stopPropagation()" *ngIf="Country"
              (click)="clearCountry()" matSuffix mat-icon-button >
              <mat-icon>close</mat-icon>
            </button>
          </mat-form-field>

          <mat-form-field class="col-3 readonly">
            <mat-label>State</mat-label>
            <mat-select formControlName="txtVState" (selectionChange)='onChangeCity($event.value,"")' [(value)]="State"
              #singleSelect>
              <mat-option>
                <ngx-mat-select-search placeholderLabel="Search" noEntriesFoundLabel="No Matches found"
                  formControlName="txtStateFilter"></ngx-mat-select-search>
              </mat-option>
              <mat-option *ngFor="let state of filteredStates | async" [value]="state.stateShortName">
                {{ state.stateName }}
              </mat-option>
            </mat-select>
            <button mat-button (click)="State=undefined;$event.stopPropagation()" *ngIf="State" (click)="clearState()"
              matSuffix mat-icon-button >
              <mat-icon>close</mat-icon>
            </button>
          </mat-form-field>


          <!-- <mat-form-field [hidden]="'true" class="col-3 readonly">
            <mat-label>City</mat-label>
            <mat-select formControlName="txtVCity" [(value)]="City" #singleSelect>
              <mat-option>
                <ngx-mat-select-search placeholderLabel="Search" noEntriesFoundLabel="No Matches found"
                  formControlName="txtCityFilter"></ngx-mat-select-search>
              </mat-option>
              <mat-option *ngFor="let city of filteredCities | async" [value]="city.cityName">
                {{ city.cityName }}
              </mat-option>
            </mat-select>
            <button mat-button (click)="City='';$event.stopPropagation()" *ngIf="City" matSuffix (click)="clearCity()"
              mat-icon-button >
              <mat-icon>close</mat-icon>
            </button>
          </mat-form-field> -->
          <mat-form-field class="col-3 readonly">
            <mat-label>City</mat-label>
            <input autocomplete="new-txtVCity" formControlName="txtVCity" matInput type="text" />
          </mat-form-field>

          <mat-form-field class="col-3 readonly">
            <mat-label>Contact number</mat-label>
            <input autocomplete="new-txtVContactNo" matInput formControlName="txtVContactNo" placeholder="Enter Phone Number" type="text"
              mask="(000)-000-0000" prefix="{{phoneCode}}-" />

            <mat-error
              *ngIf=" purchaseOrderForm?.get('txtVContactNo')?.touched && purchaseOrderForm?.get('txtVContactNo')?.errors?.sAlphabets">
              Enter only Numbers!
            </mat-error>
            <mat-error *ngIf="
                      purchaseOrderForm?.get('txtVContactNo')?.touched &&
                      purchaseOrderForm?.get('txtVContactNo')?.errors
                    ">
              Enter Minimum 10 Digits!
            </mat-error>
          </mat-form-field>


        </div>
      </mat-card-content>
    </div>
  </form>

  <!-- <div class="col-9"></div> -->
  <div class="col-12 text-lg-right">
    <button mat-button class="buttonColor" [disabled]="!(!purchaseOrderForm.invalid)"
      (click)="savePurchaseOrder()">Save</button>

    <button *ngIf="showButton" matTooltip="Click To Add New Row" mat-button
      class="btn btn-sm lightSeaGreen-dot text-lg-left ml-2" (click)="addTable()">
      <i class="fa fa-plus mr-1" ></i>
      Add Purchase Order
    </button>

  </div>
  <div class="">
    <table class="style mt-3 table-bordered">
      <tr>
        <th>Product Name</th>
        <th>Units</th>
        <th [hidden]="purchaseId==undefined"> Product Received</th>
        <th [hidden]="purchaseId==undefined"> Stock Status</th>
        <th [hidden]="purchaseId==undefined"> Purchase Order Status</th>
        <th [hidden]="purchaseId !== undefined"> Option</th>
      </tr>
      <tr *ngFor="let item of row; let i = index">
        <td>
          <!-- <input class="tblInput" [(ngModel)]="row[i].productName" type="text" >  -->
          <div class="tblInput">
            <ng-select [ngClass]="{fieldHide:purchaseId !==undefined}" [(ngModel)]="row[i].productName">
              <ng-option *ngFor="let value of drpMainProduct" [value]="value.productName">
                {{ value.productName }}</ng-option>
            </ng-select>
          </div>

        </td>
        <td>
          <div class="tblInput">
            <input autocomplete="new-purchaseId" type="text" [ngClass]="{fieldHide:purchaseId !==undefined}" matInput [(ngModel)]="row[i].quantity"
            (keypress)="numberOnly($event)">
          </div>
        </td>

        <td [hidden]="purchaseId==undefined">
          <div class="tblInput">
            <mat-checkbox [(ngModel)]="row[i].isProductReceived">
            </mat-checkbox>
          </div>
        </td>

        <td class="curser" [hidden]="purchaseId==undefined">
          <div class="tblInput" (click)="addToStock(row[i].stockStatus)">
            {{row[i].stockStatus}}
          </div>
        </td>

        <td [hidden]="purchaseId==undefined">
          <div class="tblInput">
            {{row[i].purchaseOrderStatus}}
          </div>
        </td>
        <td [hidden]="purchaseId !== undefined">
          <button class="ml-5 deleteBtnColor" placeholderLabel="Click to remove" (click)="deleteRow(i)"><i
              class="fa fa-trash"></i></button>
        </td>
      </tr>
    </table>
  </div>

</div>
<!-- </mat-dialog-content> -->

<!-- <mat-dialog-actions align="end">
  <button mat-button class="buttonColor"
  [disabled]="!(!purchaseOrderForm.invalid)" (click)="savePurchaseOrder()">Save</button>
  <button class="buttonCancelColor" mat-button mat-dialog-close>Close</button>

</mat-dialog-actions> -->
<!-- </div> -->
