import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { DataTableDirective } from 'angular-datatables';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import Swal from 'sweetalert2';
import { PracticeManagementService } from '../patient-proxy/patient/practice-management';
import { ActiveStatus } from 'projects/admin/src/app/admin-proxy/platform-app-management/rcm/platform-management/localization/enumeration/active-status.enum';
import { CreateUpdateFaxNumbersDTO } from '../patient-proxy/patient/practice-management/dto';
import { ToastrService } from 'ngx-toastr';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { CommunicationService } from 'projects/shared/src/app/services/communication.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-practice-fax-number-table',
  templateUrl: './practice-fax-number-table.component.html',
  styleUrls: ['./practice-fax-number-table.component.scss']
})
export class PracticeFaxNumberTableComponent implements OnInit {

  dtOptions: any = {
    responsive: true,
    paging: false,
    scrollCollapse: true,
    scrollY: '410px',
    scrollX: true,
    dom: 'Bfrtip',
    language: {
      emptyTable: 'No records',
      info: 'Total : _MAX_ records',
      infoEmpty: 'Total : _MAX_ records',
      infoFiltered: '(filtered : _TOTAL_ records)',
      loadingRecords: 'Loading...',
      zeroRecords: 'No matching records',
    },
    buttons: [
      {
        extend: 'excel',
        text: 'Export',
        filename: 'Fax Number Info',
        exportOptions: {
          columns: ':visible:not(:first-child,:eq(0)) ',
        },
      },
    ]
  };

  FaxNumberdetails:any;
  @Input() tableData:any;
  @Input() practiceNotesSesId:any;

  practiceNotesId: string;
  tableData$: Subscription;
  isLoading: boolean;
  $subscription: any;
  faxNumberForm: FormGroup;
  isShowSpinner: boolean = false;
  ischeckPracticeId:boolean;
  faxnumberid: string;

  @ViewChild(DataTableDirective, { static: false })
  datatableElement: DataTableDirective;
  constructor(
    private fb: FormBuilder,
    private toastr: ToastrService,
    private practiceManagementService: PracticeManagementService,
    private communicationService: CommunicationService,
    private snackBar: MatSnackBar,
    private datepipe: DatePipe
  ) { }

  ngOnInit(): void {

    this.practiceNotesId = this.practiceNotesSesId;

    this.FaxNumberdetails=this.tableData;
    this.faxNumberForm = this.fb.group({
      txtFax: new FormControl(''),
    });

    if (this.practiceNotesId) {
      this.faxNumberForm.enable();
    }else{
      this.faxNumberForm.disable();
    }

    this.dtOptions.buttons[0].filename =
    'Fax Number Info' + '-' + this.datepipe.transform(new Date(), 'MM-dd-yyyy');
  }


    //After View Method
    ngAfterViewInit(): void {
      if (this.datatableElement && this.datatableElement.dtInstance) {
        this.refreshDataTable();
      } else {
        setTimeout(() => this.refreshDataTable(), 500);
      }
    }
  
    getFormattedDateTime(date: string): string {
      if (date) {
        const formattedDate = new Date(date);
        const options: Intl.DateTimeFormatOptions = {
          year: 'numeric', month: '2-digit', day: '2-digit',
          hour: 'numeric', minute: '2-digit', second: '2-digit',
          hour12: true, // 12-hour format with AM/PM
          timeZone: 'America/Chicago'
        };
        return formattedDate.toLocaleString('en-US', options);
      } else {
        return '-';
      }
    }
  
    refreshDataTable(): void {
      this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
        dtInstance.columns().every(function () {
          const that = this;
          $('input', this.footer()).on('keyup change', function () {
            if (that.search() !== this['value']) {
              that.search(this['value']).draw();
            }
          });
        });
      });
    }

  //Save FAX NUMBER
  saveFaxNumber() {
    this.isShowSpinner = true;
    if (this.faxnumberid !== null && this.faxnumberid != "" && this.faxnumberid !== undefined) {
      const faxNumberInput: CreateUpdateFaxNumbersDTO = {
        practiceId: this.practiceNotesId ?? '',
        faxNumber: this.faxNumberForm.value.txtFax ?? '',
      };
      //Update
      this.practiceManagementService.updateFaxNumbersByIdAndInput(this.faxnumberid, faxNumberInput).subscribe(
        (response) => {
          this.toastr.success('Update Successfully!', 'Success');
          this.faxnumberid = null;
          // this.getPracticeFaxNumber();
          this.communicationService.triggerLoadFaxNumber();
          this.isShowSpinner = false;
          this.faxNumberForm.reset();
        },
        (err) => {
          this.isShowSpinner = false;
          const data: HttpErrorResponse = err;
          Swal.fire({
            icon: 'info',
            text: data?.error?.error?.message,
          });
        }
      );
    } else {
      const faxNumberInput: CreateUpdateFaxNumbersDTO = {
        practiceId: this.practiceNotesId ?? '',
        faxNumber: this.faxNumberForm.value.txtFax ?? '',
      };
      //Save
      this.practiceManagementService.createFaxNumbersByInput(faxNumberInput).subscribe(
        (response) => {
          this.toastr.success('Added Successfully!', 'Success');
          // this.getPracticeFaxNumber();
          this.communicationService.triggerLoadFaxNumber();
          this.isShowSpinner = false;
          this.faxNumberForm.reset();
        },
        (err) => {
          this.isShowSpinner = false;
          const data: HttpErrorResponse = err;
          Swal.fire({
            icon: 'info',
            text: data?.error?.error?.message,
          });
        }
      );
    }

  }

  //Update FAX NUMBER
  updatefaxNumber(id: string, faxNumber: string) {
    this.faxNumberForm.patchValue({
      txtFax: faxNumber ?? ''
    });
    this.faxnumberid = id;
    this.faxNumberForm.markAsDirty();
  }


  //Status Change
  statusChange(event: MatSlideToggleChange, data: any) {

    if (event.checked == true) {

      Swal.fire({
        title: 'Do you want to activate?',
        text: 'By Changing Status, this Fax Number will be activated',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#34c38f',
        cancelButtonColor: '#f46a6a',
        confirmButtonText: 'Yes, Activate it!',
      }).then((result) => {
        if (result.value) {

          if (
            data?.id &&
            data?.id !== '' &&
            data?.id !== null &&
            data?.id !== undefined
          ) {
            this.practiceManagementService.activeInactiveFaxNumbersByFaxid(data?.id)
              .subscribe(
                () => {
                  this.toastr.success('Activated Successfully', 'Success');
                  this.isShowSpinner = false;
                  this.resetFaxNumber();
                  // this.getPracticeFaxNumber();
                  this.communicationService.triggerLoadFaxNumber();
                },
                (err) => {
                  const data: HttpErrorResponse = err;
                  this.isShowSpinner = false;
                  Swal.fire({
                    icon: 'info',
                    text: data?.error?.error?.message,
                  });
                  event.source.checked = false;
                }
              );
          }
        } else {
          event.source.checked = false;
        }
      });
    } else if (event.checked == false) {

      Swal.fire({
        title: 'Do you want to deactivate?',
        text: 'By Changing Status, this Fax Number will be deactivated',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#34c38f',
        cancelButtonColor: '#f46a6a',
        confirmButtonText: 'Yes, deactivate it!',
        //cancelButtonText: 'No, deactivate it!',
      }).then((result) => {

        if (result.value) {
          if (
            data?.id &&
            data?.id !== '' &&
            data?.id !== null &&
            data?.id !== undefined
          ) {
            this.practiceManagementService.activeInactiveFaxNumbersByFaxid(data?.id)
              .subscribe(
                () => {
                  this.toastr.success('Deactivated Successfully', 'Success');
                  this.isShowSpinner = false;
                  this.resetFaxNumber();
                  // this.getPracticeFaxNumber();
                  this.communicationService.triggerLoadFaxNumber();
                },
                (err) => {
                  const data: HttpErrorResponse = err;
                  this.isShowSpinner = false;
                  event.source.checked = true;
                  Swal.fire({
                    icon: 'info',
                    text: data?.error?.error?.message,
                  });
                }
              );
          }
        } else {
          event.source.checked = true;
        }
      });
    }
  }

  //Reset FaxNumber
  resetFaxNumber() {
    this.faxNumberForm.reset();
    this.faxnumberid = null;
  }
  onRowDoubleClickV1(textToCopy) {
    this.copyTextToClipboard(textToCopy);
    this.showNotification('Text copied: ' + textToCopy);
  }
  
  showNotification(message: string): void {
    this.snackBar.open(message, 'Close', {
      duration: 3000, // Duration in milliseconds
      horizontalPosition: 'end', // Positioning the notification horizontally
      verticalPosition: 'bottom', // Positioning the notification vertically
    });
  }
  
  copyTextToClipboard(text: string): void {
    const textArea = document.createElement('textarea');
    textArea.value = text;
    document.body.appendChild(textArea);
    textArea.select();
    document.execCommand('Copy');
    textArea.remove();
  }

}
