<div class="row">
  <div class="col-lg-12">
    <h2>Item Details Edit</h2>
  </div>
</div>
<mat-dialog-content class="mat-typography">
  <form [formGroup]="postingForm">
    <div class="row">
      <div class="col-lg-12">
        <div class="row">
          <div class="col-md-6">
            <h4 class="customThemeClass"><b>Transaction Details</b></h4>
          </div>
        </div>
        <mat-card-content>
          <div class="row">
            <mat-form-field class="col-3">
              <mat-label>Item <span class="asterisk">*</span> </mat-label>
              <mat-select formControlName="drpCode" [(value)]="code" (selectionChange)="onChange($event.value)">
                <mat-option *ngFor="let code of drpitem" [value]="code.name">
                  {{ code.name}}
                </mat-option>
              </mat-select>
              <button mat-button (click)="code='';$event.stopPropagation()" *ngIf="code" matSuffix (click)="clearCode()"
                mat-icon-button >
                <mat-icon>close</mat-icon>
              </button>
              <mat-error *ngIf="
              postingForm?.get('drpCode')?.touched &&
              postingForm?.get('drpCode')?.errors?.required">
                Item is a required field!
              </mat-error>
            </mat-form-field>
            <mat-form-field class="col-3">
              <mat-label>Copay/Coins</mat-label>
              <input autocomplete="new-txtCopay" matInput formControlName="txtCopay" type="text" maxlength="40" />
            </mat-form-field>
            <mat-form-field class="col-3">
              <mat-label>Check No</mat-label>
              <input autocomplete="new-txtCheckNo" matInput formControlName="txtCheckNo" type="text" maxlength="15" />
              <!-- <mat-error *ngIf="postingForm?.get('txtCheckNo')?.touched &&
                      postingForm?.get('txtCheckNo')?.errors?.number">
                Enter only Numbers!
              </mat-error> -->
            </mat-form-field>
            <mat-form-field class="col-3">
              <mat-label>Size</mat-label>
              <mat-select formControlName="drpSize" [(value)]="size">
                <mat-option *ngFor="let size of drpSize" [value]="size.itemSize">
                  {{ size.itemSize }}
                </mat-option>
              </mat-select>
              <button mat-button (click)="clearSize()" (click)="size='';$event.stopPropagation()" *ngIf="size" matSuffix
                mat-icon-button >
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field>
          </div>
          <div class="row">
            <div class="col-12">
              <div class="row">
                <div class="col-6">
                  <div class="row">
                    <mat-form-field class="col-6">
                      <mat-label> Modifiers 1 </mat-label>
                      <input autocomplete="new-txtModifiers1" matInput maxlength="4" formControlName="txtModifiers1" pattern="[0-9A-Za-z]*"
                        type="text" />
                      <mat-error *ngIf="
                      postingForm?.get('txtModifiers1')?.touched &&
                      postingForm?.get('txtModifiers1')?.errors?.pattern
                  ">
                        Enter only AlphaNumeric!
                      </mat-error>
                    </mat-form-field>

                    <!-- Modifiers 2 -->
                    <mat-form-field class="col-6">
                      <mat-label> Modifiers 2 </mat-label>
                      <input autocomplete="new-txtModifiers2" matInput maxlength="4" formControlName="txtModifiers2" pattern="[0-9A-Za-z]*"
                        type="text" />
                      <mat-error *ngIf="
                      postingForm?.get('txtModifiers2')?.touched &&
                      postingForm?.get('txtModifiers2')?.errors?.pattern
                  ">
                        Enter only AlphaNumeric!
                      </mat-error>
                    </mat-form-field>

                  </div>
                </div>
                <div class="col-6">
                  <div class="row">

                    <mat-form-field class="col-6">
                      <mat-label> Modifiers 3 </mat-label>
                      <input autocomplete="new-txtModifiers3" matInput maxlength="4" formControlName="txtModifiers3" pattern="[0-9A-Za-z]*"
                        type="text" />
                      <mat-error *ngIf="
              postingForm?.get('txtModifiers3')?.touched &&
              postingForm?.get('txtModifiers3')?.errors?.pattern
          ">
                        Enter only AlphaNumeric!
                      </mat-error>
                    </mat-form-field>
                    <!-- Modifiers 4 -->
                    <mat-form-field class="col-6">
                      <mat-label> Modifiers 4 </mat-label>
                      <input autocomplete="new-txtModifiers4" matInput maxlength="4" formControlName="txtModifiers4" pattern="[0-9A-Za-z]*"
                        type="text" />
                      <mat-error *ngIf="
              postingForm?.get('txtModifiers4')?.touched &&
              postingForm?.get('txtModifiers4')?.errors?.pattern
          ">
                        Enter only AlphaNumeric!
                      </mat-error>
                    </mat-form-field>

                  </div>
                </div>
              </div>
            </div>
            <div class="col-12">
              <div class="row">
                <div class="col-6">
                  <div class="row">
                    <mat-form-field class="col-6">
                      <mat-label>DX Pointer 1</mat-label>
                      <mat-select formControlName="drpDx1" [(value)]="dx1" (click)="clearDx1()">
                        <mat-option *ngFor="let test of drpModifiers" [value]="test.shortCodeId">
                          {{ test.modifierName }}
                        </mat-option>
                      </mat-select>
                      <button mat-button (click)="dx1=undefined;$event.stopPropagation()" *ngIf="dx1" matSuffix
                        mat-icon-button >
                        <mat-icon>close</mat-icon>
                      </button>
                    </mat-form-field>
                    <mat-form-field class="col-6">
                      <mat-label>DX Pointer 2</mat-label>
                      <mat-select formControlName="drpDx2" [(value)]="dx2" (click)="clearDx2()">
                        <mat-option *ngFor="let test of drpModifiers" [value]="test.shortCodeId">
                          {{ test.modifierName }}
                        </mat-option>
                      </mat-select>
                      <button mat-button (click)="dx2=undefined;$event.stopPropagation()" *ngIf="dx2" matSuffix
                        mat-icon-button >
                        <mat-icon>close</mat-icon>
                      </button>
                    </mat-form-field>
                  </div>
                </div>
                <div class="col-6">
                  <div class="row">
                    <mat-form-field class="col-6">
                      <mat-label>DX Pointer 3</mat-label>
                      <mat-select formControlName="drpDx3" [(value)]="dx3" (click)="clearDx3()">
                        <mat-option *ngFor="let test of drpModifiers" [value]="test.shortCodeId">
                          {{ test.modifierName }}
                        </mat-option>
                      </mat-select>
                      <button mat-button (click)="dx3=undefined;$event.stopPropagation()" *ngIf="dx3" matSuffix
                        mat-icon-button >
                        <mat-icon>close</mat-icon>
                      </button>
                    </mat-form-field>
                    <mat-form-field class="col-6">
                      <mat-label>DX Pointer 4</mat-label>
                      <mat-select formControlName="drpDx4" [(value)]="dx4" (click)="clearDx4()">
                        <mat-option *ngFor="let test of drpModifiers" [value]="test.shortCodeId">
                          {{ test.modifierName }}
                        </mat-option>
                      </mat-select>
                      <button mat-button (click)="dx4=undefined;$event.stopPropagation()" *ngIf="dx4" matSuffix
                        mat-icon-button >
                        <mat-icon>close</mat-icon>
                      </button>
                    </mat-form-field>
                  </div>
                </div>
              </div>
            </div>

            <mat-form-field class="col-3">
              <mat-label>TOS</mat-label>
              <mat-select formControlName="drpTos" maxlength="40" [(value)]="tos" (click)="clearTos()">
                <mat-option *ngFor="let tos of drpTos" [value]="tos.shortCodeId">
                  {{ tos.tosName }}
                </mat-option>
              </mat-select>
              <button mat-button (click)="tos=undefined;$event.stopPropagation()" *ngIf="tos" matSuffix mat-icon-button
                >
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field>
            <mat-form-field class="col-3">
              <mat-label>Pat.Other</mat-label>
              <input autocomplete="new-txtOther" matInput formControlName="txtOther" type="text" maxlength="40" />
            </mat-form-field>

          </div>
          <div class="row">


            <mat-form-field class="col-3">
              <mat-label>Date From<span class="asterisk">*</span></mat-label>
              <input autocomplete="new-txtDateFrom" maxlength="40" placeholder="MM/DD/YYYY" formControlName="txtDateFrom" matInput
                [matDatepicker]="txtDateFrom" [formControl]="postingForm?.controls?.txtDateFrom" />
              <mat-datepicker-toggle matSuffix [for]="txtDateFrom">
              </mat-datepicker-toggle>
              <mat-datepicker #txtDateFrom></mat-datepicker>
              <mat-error *ngIf="
                      postingForm?.get('txtDateFrom')?.touched &&
                      postingForm?.get('txtDateFrom')?.errors
                            ">
                Enter Valid Date!
              </mat-error>
              <mat-error *ngIf="
                                    postingForm?.get('txtDateFrom')?.touched &&
                                    postingForm?.get('txtDateFrom')?.errors?.required
                                          ">
                Date From is a required field!
              </mat-error>
            </mat-form-field>

            <mat-form-field class="col-3">
              <mat-label>Date To</mat-label>
              <input autocomplete="new-txtDateTo" maxlength="40" placeholder="MM/DD/YYYY" formControlName="txtDateTo" matInput
                [matDatepicker]="txtDateTo" [formControl]="postingForm?.controls?.txtDateTo"
                [min]="postingForm?.controls?.txtDateFrom?.value" />
              <mat-datepicker-toggle matSuffix [for]="txtDateTo">
              </mat-datepicker-toggle>
              <mat-datepicker #txtDateTo></mat-datepicker>
              <mat-error *ngIf="
                      postingForm?.get('txtDateTo')?.touched &&
                      postingForm?.get('txtDateTo')?.errors
                            ">
                Enter Valid Date!
              </mat-error>
            </mat-form-field>

            <mat-form-field class="col-3">
              <mat-label>Fee <span class="asterisk">*</span> </mat-label>
              <span matPrefix>$ &nbsp;</span>
              <input autocomplete="new-txtPrice" matInput formControlName="txtPrice" type="text" step="0.01sa" maxlength="10" />
              <mat-error *ngIf="
                      postingForm?.get('txtPrice')?.touched &&
                      postingForm?.get('txtPrice')?.errors?.msg
                            ">
                {{postingForm?.get('txtPrice')?.errors?.msg}}
              </mat-error>
              <mat-error *ngIf="
              postingForm?.get('txtPrice')?.touched &&
              postingForm?.get('txtPrice')?.errors?.required">
                Fee is a required field!
              </mat-error>
            </mat-form-field>

            <mat-form-field class="col-3">
              <mat-label>Units <span class="asterisk">*</span></mat-label>
              <input autocomplete="new-txtUnits" matInput type="text" formControlName="txtUnits" maxlength="10" />
              <mat-error *ngIf="
                      postingForm?.get('txtUnits')?.touched &&
                      postingForm?.get('txtUnits')?.errors?.number
                            ">
                Enter Only numbers!
              </mat-error>
              <mat-error *ngIf="
              postingForm?.get('txtUnits')?.touched &&
              postingForm?.get('txtUnits')?.errors?.required">
                Units is a required field!
              </mat-error>
            </mat-form-field>
          </div>
          <div class="row">
            <mat-form-field class="col-3">
              <mat-label>Total <span class="asterisk">*</span></mat-label>
              <span matPrefix>$ &nbsp;</span>
              <input autocomplete="new-txtTotal" readonly matInput formControlName="txtTotal" type="text" maxlength="10" />
              <mat-error *ngIf="
                      postingForm?.get('txtTotal')?.touched &&
                      postingForm?.get('txtTotal')?.errors?.number
                            ">
                Enter Only numbers!
              </mat-error>

            </mat-form-field>
            <mat-form-field class="col-3">
              <mat-label>Document</mat-label>
              <input autocomplete="new-txtDocument" matInput formControlName="txtDocument" type="text" maxlength="40" />
            </mat-form-field>
            <mat-form-field class="col-3">
              <mat-label>Statement Note</mat-label>
              <input autocomplete="new-txtStatement" matInput formControlName="txtStatement" type="text" maxlength="40" />
            </mat-form-field>
            <div class="col-3">
              <br>
              <mat-checkbox formControlName="txtPatientResp">Patient Resp</mat-checkbox>
            </div>
          </div>
        </mat-card-content>
      </div>
    </div>
  </form>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button class="buttonColor" [disabled]="postingForm?.invalid || saveButtonHide"
    (click)="getClaimInfo()">Save
    <mat-icon *ngIf='isShowSpinner'>
      <mat-spinner class="spinner-border spinner-border-sm" diameter="20"> </mat-spinner>
    </mat-icon>
  </button>
  <button class="buttonCancelColor" mat-button mat-dialog-close>Close</button>

</mat-dialog-actions>
