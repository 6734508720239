<div class="container-fluid">
<div class="row align-items-center">
    <div class="col-md-6">
        <!-- <h2 class="customThemeClass">{{ notesModalTitle }} - {{data.defaultFaxId}}</h2>
        <h2 class="customThemeClass">{{ notesModalTitle }} - {{data.chartNo}}</h2> -->
        <h2  class="customThemeClass">Add Notes  {{data.ticketId? '-' + data.ticketId : ''}}</h2>
      </div>
      <div class="col-md-6" style="text-align: right">
        <mat-icon mat-dialog-close>close</mat-icon>
      </div>
    </div>
    <div class="row">
        <div class="col-lg-12">
          <!-- <div class="card elevated-div"> -->
          <!-- <div class="card-header">Shipping List</div> -->
          <div class="card">
            <div class="card-body pb-0 mb-1 mt-1">
              <!-- Notes Form -->
              <form [formGroup]="noteForm">
                <div class="modal-body">
                  <div class="row">
                    <div class="col-lg-12">
                      <mat-card-content>
                        <!--Row 1-->
                        <div class="row">
                          <!--Notes Type-->
                          <!-- <div class="col-md-8"> -->
                            <mat-form-field class="col-6">
                              <mat-label>Note Type <span class="asterisk">*</span></mat-label>
                              <mat-select   formControlName="drpNotetype" #singleSelect >

                                <mat-option [value]="data.notesType">{{ data.notesType }} </mat-option>

                              </mat-select>


                            </mat-form-field>

                          <!-- <div class="row">
                          <div class="col-md-1">
                            <button *ngIf="!saveButtonHide" mat-button class="buttonColor"
                              [disabled]="isShowSpinner || !(!noteForm?.invalid && noteForm?.dirty)" (click)="saveNotes()">
                              Save
                              <mat-icon *ngIf="isShowSpinner">
                                <mat-spinner class="spinner-border spinner-border-sm" diameter="20">
                                </mat-spinner>
                              </mat-icon>
                            </button>

                          </div>
                          <div class="col-md-1">
                            <button mat-button class="resetclr" (click)="resetNotes()">Reset</button>
                          </div>
                          </div> -->



                            <div class="col-lg-6">
                                <div class="row">
                                    <div class="col-lg-12 d-flex justify-content-center">
                              <button
                                mat-button
                                (click)="saveNotes()"
                                class="buttonColor mb-1 mr-2"
                                [disabled]="isShowSpinner || noteForm?.invalid"
                              >
                                Save
                              </button>
                              <button
                                mat-button
                                (click)="resetNotes()"
                                class="resetclr buttonColor mb-1 mr-2"
                              >
                                Reset
                              </button>
                            </div>
                        </div>
                          </div>
                        </div>
                    <!-- </div> -->
                        <!-- Row 3-->
                        <!-- <div class="row">
                          <mat-checkbox *ngIf="isCallNote" class="col-lg-3 mt-3" formControlName="chkIsCallNote">is Call
                            Note<span class="asterisk">*</span>
                          </mat-checkbox>

                        </div> -->
                        <!--Row 4-->
                        <div class="row">
                          <mat-form-field class="col-12">
                            <mat-label>Description<span class="asterisk">*</span></mat-label>
                            <textarea matInput rows="5" autocomplete="off" maxlength="500" formControlName="txtDescription"
                              type="text"></textarea>
                            <mat-error class="spanAlignment" *ngIf="noteForm?.get('txtDescription')?.touched">
                              <ng-container *ngIf="noteForm?.get('txtDescription')?.hasError('required')">
                                Description is a required field!
                              </ng-container>
                              <ng-container *ngIf="noteForm?.get('txtDescription')?.hasError('whitespace')">
                                Description cannot be just whitespace!
                              </ng-container>
                            </mat-error>
                          </mat-form-field>
                        </div>
                        <!--Row 5-->
                        <div class="row">

                        </div>

                      </mat-card-content>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
</div>
