import { ListService } from '@abp/ng.core';
import { DatePipe } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatOption } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSelect } from '@angular/material/select';
import { MatSort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import moment, { Moment } from 'moment';
import { LocaleConfig } from 'ngx-daterangepicker-material';
import { SearchSaleorderDTO } from 'projects/order/src/app/order-proxy/order-management/order/dto';
import { SaleorderService } from 'projects/order/src/app/order-proxy/order-management/order/saleorder.service';
import { WareHouseExportDatas, WareHouseExportDTO } from 'projects/patient/src/app/patient-proxy/patient/dto/models';
import { ExportType } from 'projects/patient/src/app/patient-proxy/patient/enumeration-data/export-type.enum';
import { ReportService } from 'projects/patient/src/app/patient-proxy/patient/report.service';
import { CalendarViewComponent } from 'projects/schedule/src/app/calendar-view/calendar-view.component';
import { Observable, Subscription } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { ToastrService } from "ngx-toastr";
@Component({
  selector: 'app-shipping-return-list',
  templateUrl: './shipping-return-list.component.html',
  styleUrls: ['./shipping-return-list.component.scss'],
  providers: [ListService, DatePipe]
})
export class ShippingReturnListComponent implements OnInit , OnDestroy{
  dataSource: MatTableDataSource<any> = new MatTableDataSource<any>([]);
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatTable, { static: false }) tableRef: MatTable<any>
  orderForm: FormGroup;
  isLoading: boolean = false;
  shippingTicketListForm: FormGroup;
  public arrDisplayedColumns: string[] = [];

  ShippingTableData: any[] = [];
  saleOrderID: string;
  patientID: string;
  patientName: string;
  CsrName: string;
  ticketDate: string;
  ticketId: string;
  status: string;
  drpSaleOrder

  // get dropdwon declarations

  filterSaleorder: Observable<any>
  filterPatientId: Observable<any>
  filterPatientName: Observable<any>
  filterStatus: Observable<any>
  filterTicketId: Observable<any>
  filterCsrName: Observable<any>
  drpPatientId: any[] = []
  drpPatientName: any[] = []
  drpStatus: any[] = []
  drpTikcetId: any[] = []
  drpCsrNames
  subscription$: Subscription[] = [];
  // calender Declarations

  selectedRange: { startDate: Moment, endDate: Moment };
  ranges: any;
  minDate: Moment;
  maxDate: Moment;
  calendarLocale: LocaleConfig;
  calendarPlaceholder: string = '';


  @ViewChild('allSaleOrderSelected') private allSaleOrderSelected: MatOption;
  @ViewChild('saleOrderSelect') saleOrderSelect: MatSelect;

  @ViewChild('allTicketDateSelected') private allTicketDateSelected: MatOption;
  @ViewChild('TicketDate') TicketDate: MatSelect;

  @ViewChild('allTicketIdSelected') private allTicketIdSelected: MatOption;
  @ViewChild('TicketIdSelect') TicketIdSelect: MatSelect;

  @ViewChild('allStatusSelected') private allStatusSelected: MatOption;
  @ViewChild('StatusSelect') StatusSelect: MatSelect;

  @ViewChild('allCsrNameSelected') private allCsrNameSelected: MatOption;
  @ViewChild('CsrNameSelect') CsrNameSelect: MatSelect;

  @ViewChild('allPatientNameSelected') private allPatientNameSelected: MatOption;
  @ViewChild('PatientNameSelect') PatientNameSelect: MatSelect;

  @ViewChild('allPatientIdSelected') private allPatientIdSelected: MatOption;
  @ViewChild('PatientIdSelect') PatientIdSelect: MatSelect;

  constructor(private formBuilder: FormBuilder,
    private orderService: SaleorderService,
    public list: ListService,
    public router: Router,
    public datepipe: DatePipe,
    public dialog: MatDialog,
    private toastr: ToastrService,
    private reportService: ReportService
  ) {
    this.calendarLocale = {
      customRangeLabel: 'Pick a date...',
      applyLabel: 'Apply',
      clearLabel: 'Clear',
      format: 'DD/MM/YYYY',
      daysOfWeek: ['su', 'mo', 'tu', 'we', 'th', 'fr', 'sa'],
      monthNames: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
      firstDay: 1
    };
    this.ranges = {
      'Today': [moment(), moment()],
      'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
      'Current week': [moment().startOf('isoWeek'), moment().endOf('isoWeek')],
      'Next weekend': [this.getNextSaturday(), this.getNextSunday()],
      'Next 3 days': [moment().add(1, 'days'), moment().add(3, 'days')],
      'Last 7 Days': [moment().subtract(6, 'days'), moment()],
      'Last 30 Days': [moment().subtract(29, 'days'), moment()],
      // 'Current day': [moment(), moment()],
      // 'Next 2 days': [moment().add(1, 'days'), moment().add(2, 'days')],
      'This Month': [moment().startOf('month'), moment().endOf('month')],
      'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
    };
  }

  ngOnInit(): void {
    this.orderForm = this.formBuilder.group({
      PatientId: new FormControl(""),
      PatientName: new FormControl(""),
      Date: new FormControl(""),
      csrName: new FormControl(""),
      Quantity: new FormControl(""),
      TicketId: new FormControl(""),
      location: new FormControl(""),
      supplyDate: new FormControl(""),
      saleOrderId: new FormControl(""),
      email: new FormControl(""),
      status: new FormControl(""),
      // payment:new FormControl(),
    });
    this.arrDisplayedColumns = ['saleOrderId', 'patientId', 'options', 'PatientName', 'csrName', 'payment', 'status', 'TicketId', 'Date'];
    this.getTableData()
    this.shippingTicketListForm = this.formBuilder.group({
      txtSaleorderId: new FormControl(""),
      txtSaleOrderSearch: new FormControl(""),
      txtPatientId: new FormControl(""),
      txtPatientIdSearch: new FormControl(""),
      txtPatientName: new FormControl(""),
      txtPatientNameSearch: new FormControl(""),
      txtCsrName: new FormControl(""),
      txtCsrNameSearch: new FormControl(""),
      txtStatus: new FormControl(""),
      txtStatusSearch: new FormControl(""),
      txtTicketId: new FormControl(""),
      txtTicketIdSearch: new FormControl(""),
      txtTicketDate: new FormControl(""),
      txtTicketDateSearch: new FormControl(""),
      txtFromDate: new FormControl(""),
      txtToDate: new FormControl("")
    });
    this.getDropdown();
  }

  /// get table datas
  // getTableData() {
  //   this.isLoading = true;
  //   this.orderService.getOrderList("Ready To Approve").subscribe(response => {
  //     response?.items?.forEach(value => {


  viewTicket(id) {
    this.router.navigate(['orders/shippingTicketView/' + id.toString()])
  }

  ngOnDestroy(): void {
    this.subscription$?.forEach((sub) => {
      sub && sub?.unsubscribe();
    });
  }

  // initaial List Implementation
  getTableData() {
    this.isLoading = true;
    const orderGetList = this.orderService.getOrderList("Returned").subscribe(response => {
      this.ShippingTableData = []
      response?.items?.forEach(value => {
        this.ShippingTableData.push({
          patientId: value?.patients?.defaultPatientId,
          PatientName: value?.patients?.personals?.fullName,
          createDate: value?.creationTime == null ? undefined : new Date(value?.creationTime)?.toLocaleDateString("en-US"),
          plan: value?.patients?.insuranceVerifications?.primaryInsuranceVerifies?.plan,
          VerifyName: value?.patients?.insuranceVerifications?.primaryInsuranceVerifies?.planName,
          Status: value?.orderStatus,
          saleOrderId: value?.saleorderId,
          location: value?.orders?.invLocationId,
          supplyDate: value?.orders?.scheduledDate == null ? undefined : new Date(value?.orders?.scheduledDate)?.toLocaleDateString("en-US"),
          email: value?.patients?.personals?.emailId,
          data: value?.id,
          userName: value?.shippingCheckList?.userName,
          totalItemQty: value?.shippingCheckList?.totalItemQty,
          ticketId: value?.shippingCheckList?.defaultTicketId,
          cash: value?.isCashPayment,
          cashPayment: value?.isCashPayment,
          ticketCreatedDate: value?.shippingCheckList?.ticketCreatedDate == null ? undefined : new Date(value?.shippingCheckList?.ticketCreatedDate)?.toLocaleDateString("en-US")
        })
      })
      this.isLoading = false;
      const arrTableData = this.ShippingTableData;
      this.dataSource = new MatTableDataSource(arrTableData);
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
    }, err => {
      this.isLoading = false;
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    })
    this.subscription$.push(orderGetList);
  }

  // dropdown clear functionality
  clearSaleOrderId() {
    this.saleOrderID = '';
    this.shippingTicketListForm.patchValue({ txtSaleorderId: '' });
  }
  clearPatientId() {
    this.patientID = "";
    this.shippingTicketListForm.patchValue({ txtPatientId: '' });
  }
  clearPatientName() {
    this.patientName = "";
    this.shippingTicketListForm.patchValue({ txtPatientName: '' });
  }
  clearCsrName() {
    this.CsrName = "";
    this.shippingTicketListForm.patchValue({ txtCsrName: '' });
  }
  clearTicketDate() {
    this.ticketDate = "";
    this.shippingTicketListForm.patchValue({ txtFromDate: '' });
  }
  clearTicketId() {
    this.ticketId = "";
    this.shippingTicketListForm.patchValue({ txtTicketId: '' });
  }
  clearStatus() {
    this.status = "";
    this.shippingTicketListForm.patchValue({ txtStatus: '' });
  }
  // reset table
  reset() {
    this.shippingTicketListForm.reset();
    this.getTableData();
  }

  // Filter Clear for saleorder id
  onFilterSaleorderIdChange() {
    this.clearPatientId();
    this.clearPatientName();
    this.clearCsrName();
    this.clearTicketDate();
    this.clearTicketId();
  }
  // Filter Clear for Patient id
  onFilterPatientIdChange() {
    this.clearSaleOrderId();
    this.clearPatientName();
    this.clearCsrName();
    this.clearTicketDate();
    this.clearTicketId();
  }
  // Filter Clear for Patient Name
  onFilterPatientNameChange() {
    this.clearSaleOrderId();
    this.clearPatientId();
    this.clearCsrName();
    this.clearTicketDate();
    this.clearTicketId();
  }
  // Filter Clear for CSR Name
  onFilterCSRNameChange() {
    this.clearSaleOrderId();
    this.clearPatientId();
    this.clearPatientName();
    this.clearTicketDate();
    this.clearTicketId();
  }
  // Filter Clear for Ticket id
  onFilterTicketIdChange() {
    this.clearSaleOrderId();
    this.clearPatientId();
    this.clearPatientName();
    this.clearTicketDate();
    this.clearCsrName();
  }
  //filter clear for Ticket Date
  change() {
    this.clearSaleOrderId();
    this.clearPatientId();
    this.clearPatientName();
    this.clearTicketId()
    this.clearCsrName();
  }

  // search table
  tableData: any[] = [];
  search() {
    let saleordersIds: any[] = [];
    let patientIds: any[] = [];
    let patientNames: any[] = [];
    let csrNames: any[] = [];
    let TicketIdSearch: any[] = [];
    let status: any[] = [];

    this.shippingTicketListForm.value.txtSaleorderId !== "" &&
      this.shippingTicketListForm.value.txtSaleorderId !== undefined &&
      this.shippingTicketListForm.value.txtSaleorderId !== null &&
      [this.shippingTicketListForm.value.txtSaleorderId].length !== 0 &&
      [this.shippingTicketListForm.value.txtSaleorderId].forEach(val => {
        val?.forEach(element => {
          element !== undefined && element !== 0 && saleordersIds.push(element);
        });
      });

    this.shippingTicketListForm.value.txtPatientId !== "" &&
      this.shippingTicketListForm.value.txtPatientId !== undefined &&
      this.shippingTicketListForm.value.txtPatientId !== null &&
      [this.shippingTicketListForm.value.txtPatientId].length !== 0 &&
      [this.shippingTicketListForm.value.txtPatientId].forEach(val => {
        val?.forEach(element => {
          element !== undefined && element !== 0 && patientIds.push(element);
        });
      });

    this.shippingTicketListForm.value.txtPatientName !== "" &&
      this.shippingTicketListForm.value.txtPatientName !== undefined &&
      this.shippingTicketListForm.value.txtPatientName !== null &&
      [this.shippingTicketListForm.value.txtPatientName].length !== 0 &&
      [this.shippingTicketListForm.value.txtPatientName].forEach(val => {
        val?.forEach(element => {
          element !== undefined && element !== 0 && patientNames.push(element);
        });
      });

    this.shippingTicketListForm.value.txtTicketId !== "" &&
      this.shippingTicketListForm.value.txtTicketId !== undefined &&
      this.shippingTicketListForm.value.txtTicketId !== null &&
      [this.shippingTicketListForm.value.txtTicketId].length !== 0 &&
      [this.shippingTicketListForm.value.txtTicketId].forEach(val => {
        val?.forEach(element => {
          element !== undefined && element !== 0 && TicketIdSearch.push(element);
        });
      });

    this.shippingTicketListForm.value.txtStatus !== "" &&
      this.shippingTicketListForm.value.txtStatus !== undefined &&
      this.shippingTicketListForm.value.txtStatus !== null &&
      [this.shippingTicketListForm.value.txtStatus].length !== 0 &&
      [this.shippingTicketListForm.value.txtStatus].forEach(val => {
        val?.forEach(element => {
          element !== undefined && element !== 0 && status.push(element);
        });
      });

    this.shippingTicketListForm.value.txtCsrName !== "" &&
      this.shippingTicketListForm.value.txtCsrName !== undefined &&
      this.shippingTicketListForm.value.txtCsrName !== null &&
      [this.shippingTicketListForm.value.txtCsrName].length !== 0 &&
      [this.shippingTicketListForm.value.txtCsrName].forEach(val => {
        val?.forEach(element => {

          element !== undefined && element !== 0 && csrNames.push(element);
        });
      });

    let shippingTicketSearch: SearchSaleorderDTO = {
      patientNames: patientNames,
      userIds: csrNames,
      patientIds: patientIds,
      orderIds: saleordersIds,
      ticketIds: TicketIdSearch,
      status: ["Ready To Approve"],
      ticketCreatedFromDate: this.selectedRange == null || "" || undefined ? null : this.selectedRange.startDate?.format('YYYY-MM-DD'),
      ticketCreatedToDate: this.selectedRange == null || "" || undefined ? null : this.selectedRange.endDate?.format('YYYY-MM-DD')
    }

    const orderSearch = this.orderService.searchSaleorderByInput(shippingTicketSearch).subscribe(response => {
      this.tableData = [];
      response && response?.items?.forEach((element) => {

        element?.items.forEach(() => {
          this.tableData = [];
          this.tableData.push({
            id: element?.id,
            saleOrderId: element?.saleorderId,
            patientId: element?.patients?.defaultPatientId,
            PatientName: element?.patients?.personals?.fullName,
            userName: element?.shippingCheckList?.userName,
            ticketId: element?.shippingCheckList?.defaultTicketId,
            Status: element?.orderStatus,
            cashPayment: element?.isCashPayment,
            ticketCreatedDate: element?.shippingCheckList?.ticketCreatedDate == null ? undefined : new Date(element?.shippingCheckList?.ticketCreatedDate)?.toLocaleDateString("en-US")
          });
        })
      })
      this.dataSource = new MatTableDataSource(this.tableData);
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
    });
    this.subscription$.push(orderSearch);
  }

  // get dropdown for filter search
  getDropdown() {
    const getWarehouseDropdown = this.orderService.getWarehouseDropdowns().subscribe(response => {
      this.drpSaleOrder = response.orderIds
      this.drpPatientId = response.patientIds
      this.drpPatientName = response.patientNames
      this.drpStatus = response.status
      this.drpTikcetId = response.ticketIds
      this.drpCsrNames = response.userDetails

      this.filterSaleorder = this.shippingTicketListForm?.get("txtSaleOrderSearch").valueChanges
        .pipe(
          startWith(''),
          map(value => {
            return this.drpSaleOrder.filter(option => option?.toLowerCase()?.includes(value?.toLowerCase() ?? ""))
          })
        );



      this.filterPatientId = this.shippingTicketListForm?.get("txtPatientIdSearch").valueChanges
        .pipe(
          startWith(''),
          map(value => {
            return this.drpPatientId.filter(option => option?.toLowerCase()?.includes(value?.toLowerCase() ?? ""))
          })
        );

      this.filterPatientName = this.shippingTicketListForm?.get("txtPatientNameSearch").valueChanges
        .pipe(
          startWith(''),
          map(value => {
            return this.drpPatientName.filter(option => option?.toLowerCase()?.includes(value?.toLowerCase() ?? ""))
          })
        );

      this.filterTicketId = this.shippingTicketListForm?.get("txtTicketIdSearch").valueChanges
        .pipe(
          startWith(''),
          map(value => {
            return this.drpTikcetId.filter(option => option?.toLowerCase()?.includes(value?.toLowerCase() ?? ""))
          })
        );


      this.filterCsrName = this.shippingTicketListForm?.get("txtCsrNameSearch").valueChanges
        .pipe(
          startWith(''),
          map(value => {
            return this.drpCsrNames.filter(option => option?.userName.toLowerCase()?.includes(value?.toLowerCase() ?? ""))
          })
        );

    })
    this.subscription$.push(getWarehouseDropdown);
  }

  // select all functionality
  toggleAllSaleorderIdSelection() {
    if (this.allSaleOrderSelected.selected) {
      this.saleOrderSelect.options.forEach((item: MatOption) => item.select());
    }
    else {
      this.saleOrderSelect.options.forEach((item: MatOption) => item.deselect());
    }
  }

  toggleAllPatientIdSelection() {
    if (this.allPatientIdSelected.selected) {
      this.PatientIdSelect.options.forEach((item: MatOption) => item.select());
    }
    else {
      this.PatientIdSelect.options.forEach((item: MatOption) => item.deselect());
    }
  }

  toggleAllPatientNameSelection() {
    if (this.allPatientNameSelected.selected) {
      this.PatientNameSelect.options.forEach((item: MatOption) => item.select());
    }
    else {
      this.PatientNameSelect.options.forEach((item: MatOption) => item.deselect());
    }
  }

  toggleAllCsrNamesSelection() {
    if (this.allCsrNameSelected.selected) {
      this.CsrNameSelect.options.forEach((item: MatOption) => item.select());
    }
    else {
      this.CsrNameSelect.options.forEach((item: MatOption) => item.deselect());
    }
  }

  toggleAllStatusSelection() {
    if (this.allStatusSelected.selected) {
      this.StatusSelect.options.forEach((item: MatOption) => item.select());
    }
    else {
      this.StatusSelect.options.forEach((item: MatOption) => item.deselect());
    }
  }

  toggleAllTicketIdSelection() {
    if (this.allTicketIdSelected.selected) {
      this.TicketIdSelect.options.forEach((item: MatOption) => item.select());
    }
    else {
      this.TicketIdSelect.options.forEach((item: MatOption) => item.deselect());
    }
  }

  toggleAllTicketDateSelection() {
    if (this.allTicketDateSelected.selected) {
      this.TicketDate.options.forEach((item: MatOption) => item.select());
    }
    else {
      this.TicketDate.options.forEach((item: MatOption) => item.deselect());
    }
  }

  // calender

  getNextSaturday() {
    const dayINeed = 6; // for Saturday
    const today = moment().isoWeekday();
    if (today <= dayINeed) {
      return moment().isoWeekday(dayINeed);
    } else {
      return moment().add(1, 'weeks').isoWeekday(dayINeed);
    }
  }

  getNextSunday() {
    const dayINeed = 7; // for Sunday
    const today = moment().isoWeekday();
    if (today <= dayINeed) {
      return moment().isoWeekday(dayINeed);
    } else {
      return moment().add(1, 'weeks').isoWeekday(dayINeed);
    }
  }

  viewCalendar(id: string) {
    const dialogRef = this.dialog.open(CalendarViewComponent, {
      disableClose: true,
      // height: "80%",
      width: "80%",
      // maxHeight: '200px',
      // maxWidth: '600px',
      data: { calendarId: id }
    });

    const closeModal = dialogRef.afterClosed().subscribe(() => {
    });
    this.subscription$.push(closeModal);
  }

  // onchange dropdown function for clear dropdown
  // ngOnChanges(leaveId) {
  //   leaveId = this.leaveApprovalId

  // }

  exportTableData() {
    let datas: WareHouseExportDatas[] = []
    this.dataSource?.data?.forEach(element => {

      datas.push({
        saleOrderId: element?.saleOrderId,
        patientId: element?.patientId,
        patientName: element?.PatientName,
        csrName: element?.userName,
        payment: String(element?.cash),
        status: element?.Status,
        ticketId: element?.ticketId,
        ticketCreatedDate: element?.ticketCreatedDate
      });
    })
    let warehouseExport: WareHouseExportDTO = {
      columns: ["Sale order Id", "Patient Id", "Patient Name", "CSR Name", "Payment", "Status", "Ticket Id", "Ticket Created Date"],
      datas: datas
    };
   const exportData = this.reportService.exportWareHouseReportsByExportTypeAndExportDatas(ExportType.shippingReturnTicketList, warehouseExport).subscribe(response => {
      if (response && response?.length !== 0) {
        let filelocation: String = String("data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64," + response);
        let link = document.createElement("a");
        link.download = ("Shipping-Return") + ".xlsx";
        link.href = filelocation.toString();
        link.click();
        // Swal.fire({ title: 'Success', html: 'Exported Successfully', icon: 'success', timer: 3000, timerProgressBar: true })
        this.toastr.success('Exported Successfully','Success')
      }
    }, err => {
      const data: HttpErrorResponse = err;
      let errorMesg: string = data?.error;
      if (errorMesg?.includes('Sorry!! There is no data in table to export')) {
        errorMesg = 'Sorry!! There is no data in table to export';
      }
      else {
        errorMesg = data?.error?.error?.message;
      }
      // Swal.fire({
      //   icon: 'info',
      //   text: errorMesg
      // });
      this.toastr.info(errorMesg)
    });
    this.subscription$.push(exportData);
  }
}

