import type { CreateUpdateOfficeWorkingHoursDTO, GetUsersDTO, OfficeWorkingHoursDTO } from './dto/models';
import { RestService } from '@abp/ng.core';
import type { PagedAndSortedResultRequestDto, PagedResultDto } from '@abp/ng.core';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class OfficeWorkingHoursService {
  apiName = 'OnTimeManagement';

  create = (input: CreateUpdateOfficeWorkingHoursDTO) =>
    this.restService.request<any, OfficeWorkingHoursDTO>({
      method: 'POST',
      url: '/api/OnTimeApp/office-working-hours',
      body: input,
    },
     { apiName: this.apiName, skipHandleError: true });

  delete = (id: string) =>
    this.restService.request<any, void>({
      method: 'DELETE',
      url: `/api/OnTimeApp/office-working-hours/${id}`,
    },
     { apiName: this.apiName, skipHandleError: true });

  get = (id: string) =>
    this.restService.request<any, OfficeWorkingHoursDTO>({
      method: 'GET',
      url: `/api/OnTimeApp/office-working-hours/${id}`,
    },
     { apiName: this.apiName, skipHandleError: true });

  getList = (input: PagedAndSortedResultRequestDto) =>
    this.restService.request<any, PagedResultDto<OfficeWorkingHoursDTO>>({
      method: 'GET',
      url: '/api/OnTimeApp/office-working-hours',
      params: { skipCount: input.skipCount, maxResultCount: input.maxResultCount, sorting: input.sorting },
    },
     { apiName: this.apiName, skipHandleError: true });

  update = (id: string, input: CreateUpdateOfficeWorkingHoursDTO) =>
    this.restService.request<any, OfficeWorkingHoursDTO>({
      method: 'PUT',
      url: `/api/OnTimeApp/office-working-hours/${id}`,
      body: input,
    },
     { apiName: this.apiName, skipHandleError: true });

  getUsersBasedOnTimingsByInput = (input: GetUsersDTO) =>
    this.restService.request<any, GetUsersDTO>({
      method: 'GET',
      url: '/api/OnTimeApp/office-working-hours/get-users-based-on-timings',
      params: { weekDays: input.weekDays, startsAt: input.startsAt, endsAt: input.endsAt, userDetails: input.userDetails },
    },
     { apiName: this.apiName, skipHandleError: true });

  constructor(private restService: RestService) {}
}
