import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { Store } from '@ngxs/store';

import { ItemPaymentDTO } from '../billing-proxy/billing-management/posting/dto/models';


@Component({
  selector: 'app-insurance-payment-modal',
  templateUrl: './insurance-payment-modal.component.html',
  styleUrls: ['./insurance-payment-modal.component.scss']
})
export class InsurancePaymentModalComponent implements OnInit {
  Title: string = "Edit Payment Calculations";
  public paymentModelForm: FormGroup;
  itemPayment: ItemPaymentDTO;
  copyItemPayment: ItemPaymentDTO;
  selectedInsuranceType: number = 0;
  insurnaceBalance: number;
  constructor(
    public dialog: MatDialog,
    private fb: FormBuilder,
    private store: Store,
    @Inject(MAT_DIALOG_DATA) public data: { itemPayment: ItemPaymentDTO, selectedInsuranceType: number, claimId: string },
  ) { }
  ngOnInit(): void {
    this.selectedInsuranceType = this.data?.selectedInsuranceType;
    this.itemPayment = this.data?.itemPayment;
    this.insurnaceBalance = this.data?.itemPayment?.insBalance;
    this.copyItemPayment = this.itemPayment;
  }
  allowedDatachanged(copyItemPayment: ItemPaymentDTO) {
    copyItemPayment.writeOff = copyItemPayment?.billed - copyItemPayment?.allowed
    return copyItemPayment?.writeOff
  }
  paymentDatachanged(copyItemPayment: ItemPaymentDTO) {
    copyItemPayment.patBalance = 0;
    copyItemPayment.claim = "";
  }
  // claimDatachanged(copyItemPayment: ItemPaymentDTO) {
  //   if (copyItemPayment?.claim === 'Transfer to patient') {
  //     copyItemPayment.patBalance = copyItemPayment?.insBalance
  //     copyItemPayment.insBalance = 0;
  //   } else if (copyItemPayment?.claim === 'Moved to next ins') {
  //   } else {
  //   }
  //   return copyItemPayment?.claim
  // }
  paymentDatachangedForOtherInsurance(itemPayment: ItemPaymentDTO) {
    return itemPayment.insBalance - itemPayment.insurancePaid;
  }
  calculatePayment() {
    if (this.selectedInsuranceType === 1) {
      this.copyItemPayment.writeOff = this.subractValues(this.copyItemPayment?.billed, this.copyItemPayment?.allowed);
      this.copyItemPayment.insBalance = this.subractValues(this.copyItemPayment?.allowed, this.copyItemPayment?.insurancePaid);
    } else {
      this.copyItemPayment.insBalance = this.subractValues(this.insurnaceBalance, this.copyItemPayment?.insurancePaid);
    }
  }
  subractValues(number_1: number, number_2: number) {
    const num1: number = Number(number_1.toFixed(2)) || 0;
    const num2: number = Number(number_2.toFixed(2)) || 0;
    const num3: number = 100;
    const answer: number = Number(((num1 * num3) - (num2 * num3)));
    const answers = Number(answer / num3) || 0;
    return answers || 0;



    let b1 = number_1?.toString()?.split(".");
    let b1_max = 0;
    if (b1?.length == 2) {
      b1_max = b1[1]?.length;
    }
    let c1 = number_2?.toString()?.split(".");
    let c1_max = 0;
    if (c1?.length == 2) {
      c1_max = c1[1]?.length;
    }
    //let max_len = b1_max > c1_max ? b1_max : c1_max;
    return Number((number_1 - number_2)?.toFixed(2)) | 0;
  }
}

