
import { Component, OnInit, ViewChild } from '@angular/core';
import { DataTableDirective } from 'angular-datatables';
import { PatientPersonalService } from '../../patient-proxy/patient-optimization/patient-personal.service';
import { HttpErrorResponse } from '@angular/common/http';
import Swal from 'sweetalert2';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-patient-list-table',
  templateUrl: './patient-list-table.component.html',
  styleUrls: ['./patient-list-table.component.scss']
})
export class PatientListTableComponent implements OnInit {
  patientListDataArray : any;
  tblItemSelectedRowId: string;
  dtPatientListOptions: any = {
    responsive: true,
    dom: 'Bfrtip',
    columnDefs: [
      { targets: [0], orderable: false }, // Hide the first column (index 0)
    ],
    pageLength: 15,
    lengthMenu: [15, 30, 50, 100, 200],
    search: {
      smart: false, // Set to "none" for full exact match
    },
    buttons: [
      {
        extend: 'excel',
        text: 'Export', // Change the text to 'Export'
        filename: 'Patient List',
        // exportOptions: {
        //   columns: [2, 3, 4, 5, 6, 7, 8, 9],
        // },

      },
    ],
  };
  isLoading:boolean;
  selectedBranchList: string[] = null;
  selectedPatient: boolean = false;
  patientId: string;
  documentId: string;
  chartId: string;
  constructor(private patientPersonalService: PatientPersonalService,private datepipe:DatePipe) {}

  ngOnInit() {
    this.initializeForms();
    this.getTableData();

    this.dtPatientListOptions.buttons[0].filename =
    'Patient List' + '-' + this.datepipe.transform(new Date(), 'MM-dd-yyyy');
  }
  @ViewChild(DataTableDirective, { static: false })
  datatableElement: DataTableDirective;
  //After View Method
  ngAfterViewInit(): void {
    this.refreshDataTable();
  }

  initializeForms() {

  }

  // Add this inside your component class
onSearchChartNo(event: any): void {
  const value = event.target.value;
  if (this.datatableElement) {
    this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.columns(1).search(value).draw(); // Assuming 'Chart No' is in the second column (index 1)
    });
  }
}

  tblItemSelectedRow(patientId: string, documentId: string, chartId: string) {
    this.selectedPatient = true
    this.tblItemSelectedRowId = this.tblItemSelectedRowId === patientId ? '' : patientId;
    this.patientId = patientId;
    this.documentId = documentId;
    this.chartId = chartId;
  }

  refreshDataTable(): void {
    if (this.datatableElement) {
      this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
        dtInstance.draw();
      });
    }
  }

  getTableData() {
    this.isLoading = true;
    this.patientPersonalService.getPatientsByTenant(this.selectedBranchList).subscribe(response => {
      this.isLoading = false;
      this.patientListDataArray = response;
      this.refreshDataTable();
    },
    (err) => {
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    });
  }

}
