<table id="tbl" datatable [dtOptions]="dthelloSignOptions" class="tbl hover row-border hover w-100 display">
  <thead>
    <tr>
      <th><mat-icon>apps</mat-icon></th>
      <th [matTooltip]="'Chart No'" >Chart No</th>
      <th [matTooltip]="'Patient Name'">Patient Name</th>
      <th [matTooltip]="'Email'">Email</th>
      <th class="text-right"[matTooltip]="'Ticket No'">Ticket No</th>
      <th class="text-right"[matTooltip]="'orderDate'">Order Date</th>
      <th [matTooltip]="'CSR'">CSR</th>
      <th [matTooltip]="'Created By'">Created By</th>
      <th class="text-right" [matTooltip]="'Created Date'" >Created Date</th>
      <th [matTooltip]="'Modify By'">Modify By</th>
      <th class="text-right" [matTooltip]="'Modified Date'" >Modified Date</th>
      <th class="text-right"[matTooltip]="'Doc Send On'">Doc Send On</th>
      <th class="text-right"class="text-right"[matTooltip]="'Doc Received On'">Doc Received On</th>
      <th [matTooltip]="'Hello Sign Status'">Hello Sign Status</th>
      <th class="text-right"[matTooltip]="'Document Link Expiry On'">Document Link Expiry On</th>
      <th [matTooltip]="'Expiry Status'" >Expiry Status</th>
    </tr>
  </thead>
  <tbody>
    <!-- <tr *ngFor="let data of myverification" (click)="tblItemSelectedRow(data.patientId,data.chartNo,data.policyId)"> -->
    <tr *ngFor="let data of helloSignReportData">
      <!-- -->
      <td  >
        <a   [matTooltip]="'Click to Status'" (click)="openTimelinePopup(data)">
        <img
        class="img-responsive pr-1 mb-1 cursor"
        src="assets/Claim Page Icons/status.png"
        alt=""
        height="17"
      /></a>
      <a   [matTooltip]="'Click to Signed Document Preview'"  [class.disabled]="data.helloSignStatus != 'signed'" (click)="viewSortedDoc(data.signaturerequestid)" >
      <img
      class="img-responsive pr-1 mb-1 cursor"
      src="assets/Claim Page Icons/docPreview.png"
      alt=""
      height="17"
    ></a>

    <a   [matTooltip]="'Click to View Hello Sign'"
    [routerLink]="
      '/Hello-sign/'+ data.orderId+'/'+data.patientId +'/'+data.id +'/'+true"

    target="_blank"
    class="preview-link"
  >
    <img
    class="img-responsive pr-1 mb-1 cursor"
    src="assets/Claim Page Icons/DocSigned.png"
    alt=""
    height="17"
  /></a>
      </td>
      <td (dblclick)="onRowDoubleClick(data?.chartNo || '-')" [matTooltip]="data?.chartNo || '-'">
          <a
            [routerLink]="'/patientCreateEditTab/' + data.patientId + '/' + data.docId" target="_blank"
            class="preview-link">{{data?.chartNumber || '-'}}
          </a>
      </td>
      <td (dblclick)="onRowDoubleClick(data?.patientName || '-')" [matTooltip]="data?.patientName || '-'">
        {{ data?.patientName || '-' }}
      </td>
      <td (dblclick)="onRowDoubleClick(data?.patientEmail || '-')" [matTooltip]="data?.patientEmail || '-'">
        {{ data?.patientEmail || '-' }}
      </td>
      <td class="text-right"(dblclick)="onRowDoubleClick(data?.orderNumber || '-')" [matTooltip]="data?.orderNumber || '-'">
        {{ data?.orderNumber || '-' }}
      </td>
      <td class="text-right" (dblclick)="onRowDoubleClick(data?.orderDate)" [matTooltip]="data?.orderDate">{{ data?.orderDate }}
      </td>
      <td (dblclick)="onRowDoubleClick(data?.csrName || '-')" [matTooltip]="data?.csrName || '-'">
        {{ data?.csrName || '-' }}
      </td>
      <td [matTooltip]="data?.createdBy || '-'"
      (dblclick)="onRowDoubleClick(data?.createdBy || '-')">{{
      data?.createdBy || '-' }}</td>

  <td class="text-right" [matTooltip]="data?.creationTime"
      (dblclick)="onRowDoubleClick(data?.creationTime)" >
      {{ data?.creationTime }}
  </td>
  <td [matTooltip]="data?.modifyBy|| '-'"
      (dblclick)="onRowDoubleClick(data?.modifyBy || '-')">{{
      data?.modifyBy || '-' }}</td>

  <td class="text-right" [matTooltip]="data?.lastModificationTime"
      (dblclick)="onRowDoubleClick(data?.lastModificationTime)" >
      {{ data?.lastModificationTime }}
  </td>
      <td class="text-right" (dblclick)="onRowDoubleClick(data?.documentSentOn)" [matTooltip]="data?.documentSentOn" >{{ data?.documentSentOn }}
      </td>
      <td class="text-right" (dblclick)="onRowDoubleClick(data?.documentReceivedOn || '-')" [matTooltip]="data?.documentReceivedOn" >{{ data?.documentReceivedOn || '-'}}
      </td>
      <td (dblclick)="onRowDoubleClick(data?.helloSignStatus || '-')" [matTooltip]="data?.helloSignStatus || '-'">
        {{ data?.helloSignStatus || '-' }}
      </td>
      <td class="text-right" (dblclick)="onRowDoubleClick(data?.expireDate)" [matTooltip]="data?.expireDate" >{{ data?.expireDate }}
      </td>
      <td (dblclick)="onRowDoubleClick(data?.expireStatus || '-')" [matTooltip]="data?.expireStatus || '-'">
        {{ data?.expireStatus || '-' }}
      </td>
    </tr>
  </tbody>
  <tfoot>
    <tr>
      <th><mat-icon>apps</mat-icon></th>
      <th >
        <input matInput type="text" class="textbox-border-class" placeholder="Chart No" name="search-ChartNo" />
      </th>
      <th >
        <input matInput class="textbox-border-class" type="text" placeholder="Patient Name " name="search-Name" />
      </th>
      <th >
        <input matInput type="text" class="textbox-border-class" placeholder="Email" name="search-Email" />
      </th>
      <th class="text-right">
        <input matInput type="text" class="textbox-border-class" placeholder="Ticket No" name="search-Ticket No" />
      </th>
      <th class="text-right">
        <input matInput type="text" class="textbox-border-class" placeholder="Order Date" name="search-Order Date" />
      </th>
      <th >
        <input matInput type="text" class="textbox-border-class" placeholder="CSR" name="search-CSR" />
      </th>
      <th>
        <input matInput class="textbox-border-class" type="text" placeholder="Created By"
            name="search-Created By" />
    </th>
    <th class="text-right">
        <input matInput class="textbox-border-class " type="text"
              placeholder="Created Date" name="search-Created Date" />
     </th>
     <th>
        <input matInput class="textbox-border-class" type="text" placeholder="Modify By"
            name="search-Modify By" />
    </th>
      <th class="text-right">
          <input matInput class="textbox-border-class " type="text"
              placeholder="Modified Date" name="search-Modified Date" />
      </th>
      <th class="text-right">
        <input matInput type="text" class="textbox-border-class" placeholder="Doc Send On" name="search-Doc Send On" />
      </th>
      <th class="text-right">
        <input matInput class="textbox-border-class" type="text" placeholder="Doc Received On" name="search-Doc Received On" />
      </th>
      <th >
        <input matInput class="textbox-border-class" type="text" placeholder="Hello Sign Status" name="search-Status" />
      </th>
      <th class="text-right">
        <input matInput class="textbox-border-class" type="text" placeholder="Document Link Expiry On" name="search-Document Link Expiry On" />
      </th>
      <th >
        <input matInput class="textbox-border-class " type="text" placeholder="Expiry Status" name="search-Expiry Status" />
      </th>
    </tr>
  </tfoot>
</table>
