import { PagedAndSortedResultRequestDto } from '@abp/ng.core';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { NgbNavChangeEvent } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngxs/store';
import { TableService } from 'projects/shared/src/app/table.service';
import { EmailValidation } from 'projects/shared/src/app/validations/email-validator';
import { NumberValidators } from 'projects/shared/src/app/validations/number-validator';
import { TextValidators } from 'projects/shared/src/app/validations/text-validator';
import { Subscription } from 'rxjs';
import Swal from 'sweetalert2';
import emailMask from 'text-mask-addons/dist/emailMask';

import { DoctorGroupDTO, FacilityDTO, MarketingReferralTypeDTO, PatientMasterDropdownDTO } from '../dropdown-proxy/dropdown-management/dropdowns/dto/patient/models';
import { PatientMasterDropdownService } from '../dropdown-proxy/dropdown-management/dropdowns/master-dropdown-service/patient-master-dropdown.service';
import { PatientDropdowns } from '../dropdown-proxy/dropdown-management/dropdowns/patient-dropdowns.enum';
import { ProviderDropdownService } from '../dropdown-proxy/dropdown-management/dropdowns/provider-dropdown-service/provider-dropdown.service';
import { DoctorService } from '../patient-proxy/patient/doctor.service';
import { DoctorDTO } from '../patient-proxy/patient/dto/models';
import { ToastrService } from "ngx-toastr";


// import { NgbTabChangeEvent } from '@ng-bootstrap/ng-bootstrap';
@Component({
  selector: 'app-patient-doctor',
  templateUrl: './patient-doctor.component.html',
  styleUrls: ['./patient-doctor.component.scss']
})
export class PatientDoctorComponent implements OnInit, OnDestroy {
  isHideSaveButton: boolean = true;
  drpDoctorgroup: DoctorGroupDTO[];
  drpFacility: FacilityDTO[];
  drpMarketingtypeLoop: MarketingReferralTypeDTO[];
  public emailMask: any;
  doctorModalTitle: string;
  doctorId: string = "";
  practiceId:string="";
  sesPracticeID:string;
  @Input() drpDoctor: { id: string; name: string; }[] = [];
  @Input() doctorName: string;
  doctorForm: FormGroup;
  doctorGroup: string;
  facility: string;
  marketingRep: string;
  constructor(private formBuilder: FormBuilder,
    public dialog: MatDialogRef<PatientDoctorComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { doctorId?: string,
      practiceId?:string;
    },
    private text: TextValidators,
    private email: EmailValidation,
    private number: NumberValidators,
    private doctorService: DoctorService,
    private toastr: ToastrService,
    private store: Store, private ProviderDropdown: ProviderDropdownService,
    public Table: TableService,
    private patientDropdownService: PatientMasterDropdownService
  ) { }
  public beforeChange($event: NgbNavChangeEvent) {
    if ($event.nextId === 'tab-2') {
      this.isHideSaveButton = false;
    } else {
      this.isHideSaveButton = true;
    }
  }
  subscription$: Subscription[] = [];
  CountryName: string = "";
  StateName: string = "";
  // ! Page Initialization funtions
  ngOnInit(): void {
    this.getDropdown();
    this.createDoctorForm();
    this.doctorName = this?.data?.doctorId ?? "";
    this.sesPracticeID=this?.data?.practiceId??"";
    this.doctorName !== "" && this.doctorName !== undefined && this.getDoctorDetails(this.doctorName);
  }

  ngOnDestroy(): void {
    this.subscription$?.forEach((sub) => {
      sub && sub?.unsubscribe();
    });
  }

  // clear dropdown
  clearDoctorGroup() {
    this.doctorGroup = '';
    this.doctorForm.patchValue({ drpGeneralDoctorGroup: '' });
  }
  clearFacility() {
    this.facility = '';
    this.doctorForm.patchValue({ drpGeneralFacility: '' });
  }

  clearMarketingRep() {
    this.marketingRep = '';
    this.doctorForm.patchValue({ txtMarketingRep: '' });
  }




  drpCountry: any[] = [];
  drpStates: any[] = [];
  drpCities: any[] = [];
  // Country city state Dropdown
  countryDropdown() {

    this.drpCountry = [];
    const countryDropdown = this.ProviderDropdown.getAllCountriesByInput(new PagedAndSortedResultRequestDto).subscribe(response => {
      this.drpCountry = response?.items ?? [];
    }, err => {
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    });
    this.subscription$.push(countryDropdown);
  }
  onChangeState(value: string | null | undefined) {
    this.drpStates = [];
    this.doctorForm.patchValue({
      txtCity: null,
      txtState: null,
    })
    if (value !== null && value !== undefined) {
      const stateDropdown = this.ProviderDropdown.getAllStatesBasedonCountriesByCountryName(value).subscribe(response => {
        this.drpStates = response?.items ?? [];
      }, err => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      })
      this.subscription$.push(stateDropdown);
    }
  }

  onChangecity(value: string | null | undefined) {
    this.drpCities = [];
    if (value !== null && value !== undefined) {
      const cityDropdown = this.ProviderDropdown.getAllCitiesBasedonStatesByStateName(value).subscribe(response => {
        this.drpCities = response.items ?? [];
      }, err => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      })
      this.subscription$.push(cityDropdown)
    }
  }


  // ! Doctor Edit details
  getDoctorDetails(doctorId: string) {
    const doctorDetails = this.doctorService.get(doctorId).subscribe(response => {
      let responseData: { deaNumber: number, npiNumber: number, upin: number } = { deaNumber: +response.deaNumber, npiNumber: +response.npiNumber, upin: +response.upin };
      let responeMobile = response?.mobileNo?.replace("-", "");
      responeMobile = responeMobile?.replace("(", "").replace(")", "").replace("#", "").replace("-", "").split(" ").join("");
      let responeFaxno = response?.faxNo?.replace("-", "");
      responeFaxno = responeFaxno?.replace("(", "").replace(")", "").replace("#", "").replace("-", "").split(" ").join("");
      let responePhone = response?.phoneNo?.replace("-", "");
      responePhone = responePhone?.replace("(", "").replace(")", "").replace("#", "").replace("-", "").split(" ").join("");
      this.CountryName = response?.country ?? "";
      this.StateName = response?.state ?? "";
      this.CountryName != null && this.CountryName !== "" && this.CountryName != undefined && this.onChangeState(this.CountryName)
      this.StateName != null && this.StateName !== "" && this.StateName != undefined && this.onChangecity(this.StateName)
      this.doctorForm.patchValue({

        txtGeneraltitle: response?.title,
        txtGeneralLastName: response?.lastName,
        txtGeneralFirstName: response?.firstName,
        txtGeneralMiddleName: response?.middleName,
        txtGeneralSuffixName: response?.suffix,
        drpGeneralDoctorGroup: response?.doctorGroup,
        drpGeneralFacility: response?.facilityId,
        txtMarketingRep: response?.marketingRep,
        txtAddress: response?.address,
        txtCity: response?.city, // === null || undefined ? "" : response.city,
        txtState: response?.state, // === null || undefined ? "" : response.state,
        txtCountry: response?.country,// === null || undefined ? "" : response.country,
        txtPostalCode: response?.postalCode,
        chkPCOS: response?.isPCOS,
        txtContactInfoPhone: responeMobile,
        txtNPITextBox: +responseData?.npiNumber,
        txtTaxonomy: response?.taxonomyCode,
        txtContactInfoFax: responeFaxno,
        txtContactInfoMobile: responePhone,
        txtContactInfoEmail: response?.emailId,
        txtContactInfoFaxAttention: response?.faxAttention,
        txtMedicalLicenseNo: response?.medicalLicenseNo,
        txtMedicalInfoDEANumber: responseData?.deaNumber,
        txtMedicalInfoUPIN: responseData?.upin,
        txtMedicalInfoStateMedicaidID: response?.stateMedicalId,
        txtDoctorNotes: response?.doctorNotes,
        txtExpiration: response?.expiration,
        txtUser1: response?.userDeliveredUser1,
        txtUser2: response?.userDeliveredUser2,
        chkElectronic: +response?.excludeFromElectronicFax
      });

      this.doctorId = response?.id ?? "";
      this.doctorModalTitle = "Edit Physicians";
      this.doctorForm.updateValueAndValidity();
    }, err => {
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    });
    this.subscription$.push(doctorDetails)
  }
  // only number and alpha
  numberAndAlphaOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    let inp = String.fromCharCode(event.keyCode);
    if (charCode > 31 && (charCode < 48 || charCode > 57) && (charCode >= 15 && charCode <= 64)) {
      return false;
    }
    return true;

  }
  // ! Form Creation and Few Validations
  createDoctorForm() {
    this.emailMask = emailMask;
    this.doctorForm = this.formBuilder.group({
      txtGeneraltitle: new FormControl(""),
      txtGeneralLastName: new FormControl("", this.text.isTextCheck),
      txtGeneralFirstName: new FormControl("", [Validators.required, this.text.isTextCheck]),
      txtGeneralMiddleName: new FormControl("", this.text.isTextCheck),
      txtGeneralSuffixName: new FormControl("", this.text.isTextCheck),
      drpGeneralDoctorGroup: new FormControl(),
      drpGeneralFacility: new FormControl(),
      txtMarketingRep: new FormControl(null),
      txtAddress: new FormControl(""),
      txtCity: new FormControl(null),
      txtState: new FormControl(null),
      txtCountry: new FormControl(null),
      txtPostalCode: new FormControl(""),
      chkPCOS: new FormControl(""),
      txtContactInfoPhone: new FormControl("", this.number.isNumberCheck),
      txtNPITextBox: new FormControl("", [this.number.isNumberCheck, Validators.required]),
      txtTaxonomy: new FormControl(""),
      txtContactInfoFax: new FormControl("", this.number.isNumberCheck),
      txtContactInfoMobile: new FormControl("", this.number.isNumberCheck),
      txtContactInfoEmail: new FormControl("", Validators.email),
      txtContactInfoFaxAttention: new FormControl(""),
      txtMedicalLicenseNo: new FormControl(""),
      txtMedicalInfoDEANumber: new FormControl("", this.number.isNumberCheck),
      txtMedicalInfoUPIN: new FormControl("", this.number.isNumberCheck),
      txtMedicalInfoStateMedicaidID: new FormControl("", this.number.isNumberCheck),
      txtDoctorNotes: new FormControl(""),
      txtExpiration: new FormControl(""),
      txtUser1: new FormControl(""),
      txtUser2: new FormControl(""),
      chkElectronic: new FormControl(""),
    })
    const ValueChanges = this.doctorForm.valueChanges.subscribe(() => {
      let firstname = this.doctorForm?.get('txtGeneralFirstName');
      if (firstname?.dirty) {
        firstname.value?.trim() === "" && firstname?.setErrors({ required: true })
        firstname?.markAsDirty();
        firstname?.markAsTouched();
        // firstname.markAsPristine();
      }

    }, err => {
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    })
    this.subscription$.push(ValueChanges)
    this.doctorModalTitle = "Add Physicians";
  }

  // ! Master Dropdown Funtion
  getDropdown() {
    let patientDropdownInput: PatientDropdowns[];
    patientDropdownInput = [
      PatientDropdowns.DoctorGroups,
      PatientDropdowns.Facilities,
      PatientDropdowns.MarketingReferralTypes
    ]
    const patientDropdown = this.patientDropdownService.getPatientDropdownsByInput(patientDropdownInput).subscribe((stateResponse) => {
      let response: PatientMasterDropdownDTO = stateResponse;
      this.drpDoctorgroup = response.doctorGroups;
      this.drpFacility = response.facilities;
      this.drpMarketingtypeLoop = response.marketingReferralTypes;
      // this.drpCity = response.cities;
      // this.drpState = response.states;
      // this.countries = response.countries;
    }, err => {
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    })
    this.subscription$.push(patientDropdown)
    // this.countryDropdown();
  }

  organizationUnitId = null;
  // ! Save Function
  saveDoctor() {
    // this.Table.getOrganizationUnitId().subscribe(value => {
    //   this.organizationUnitId = value;
    // });
    // this.organizationUnitId = localStorage.getItem('initialOrganizationUnitId');
    let doctor: DoctorDTO = {
      id: this.doctorId,
      title: this.doctorForm?.get("txtGeneraltitle")?.value,
      lastName: this.doctorForm?.get("txtGeneralLastName")?.value,
      firstName: this.doctorForm?.get("txtGeneralFirstName")?.value,
      middleName: this.doctorForm?.get("txtGeneralMiddleName")?.value,
      suffix: this.doctorForm?.get("txtGeneralSuffixName")?.value,
      doctorGroup: this.doctorForm?.get("drpGeneralDoctorGroup")?.value,
      facilityId: this.doctorForm?.get("drpGeneralFacility")?.value,
      npiNumber: String(this.doctorForm?.get("txtNPITextBox")?.value),
      taxonomyCode: this.doctorForm?.get("txtTaxonomy")?.value,
      marketingRep: this.doctorForm?.get("txtMarketingRep")?.value,
      excludeFromElectronicFax: +this.doctorForm?.get("chkElectronic")?.value,
      address: this.doctorForm?.get("txtAddress")?.value,
      city: this.doctorForm?.get("txtCity")?.value === null || undefined ? "" : this.doctorForm?.get("txtCity")?.value,
      state: this.doctorForm?.get("txtState")?.value === null || undefined ? "" : this.doctorForm?.get("txtState")?.value,
      country: this.doctorForm?.get("txtCountry")?.value === null || undefined ? "" : this.doctorForm?.get("txtCountry")?.value,
      postalCode: this.doctorForm?.get("txtPostalCode")?.value,
      isPCOS: +this.doctorForm?.get("chkPCOS")?.value,
      phoneNo: this.doctorForm?.get("txtContactInfoPhone")?.value,
      faxNo: this.doctorForm?.get("txtContactInfoFax")?.value,
      mobileNo: this.doctorForm?.get("txtContactInfoMobile")?.value,
      emailId: this.doctorForm?.get("txtContactInfoEmail")?.value,
      faxAttention: this.doctorForm?.get("txtContactInfoFaxAttention")?.value,
      medicalLicenseNo: this.doctorForm?.get("txtMedicalLicenseNo")?.value,
      expiration: this.doctorForm?.get("txtExpiration")?.value,
      deaNumber: String(this.doctorForm?.get("txtMedicalInfoDEANumber")?.value),
      upin: String(this.doctorForm?.get("txtMedicalInfoUPIN")?.value),
      stateMedicalId: this.doctorForm?.get("txtMedicalInfoStateMedicaidID")?.value,
      doctorNotes: this.doctorForm?.get("txtDoctorNotes")?.value,
      userDeliveredUser1: this.doctorForm?.get("txtUser1")?.value,
      userDeliveredUser2: this.doctorForm?.get("txtUser1")?.value,
      organizationUnitId: this.organizationUnitId ?? "",
      isMedicare:true
      // createdByUserId: 1,
    };
    if ((this.doctorName === "" || this.doctorName === undefined || this.doctorName === null)) {
      this.doctorService.create(doctor).subscribe(response => {
        // Swal.fire({ title: 'Success', html: 'Saved Successfully', icon: 'success', timer: 3000, timerProgressBar: true });
        this.toastr.success('Saved Successfully','Success')
        this.dialog.close();
      }, err => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      });
    }
    else {
      this.doctorService.update(this.doctorId, doctor).subscribe(response => {
        // Swal.fire({ title: 'Success', html: 'Saved Successfully', icon: 'success', timer: 3000, timerProgressBar: true });
        this.toastr.success('Saved Successfully','Success')

        this.dialog.close();
      }, err => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      });
    }
  }
}
