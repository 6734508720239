


<div class="py-2" style="border: 2px">
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-6 d-inline-flex align-items-center">
        <h2 class="mb-0"><b>Mask Description</b></h2>
      </div>
      <div class="col-md-6 d-inline-flex justify-content-end">
        <a
          class="btn d-inline-flex justify-content-end align-items-end"
          style="background-color: rgb(32, 178, 170); color: white"
          href="#"
          >Export</a
        >
      </div>
    </div>
  </div>
</div>

<!-- accordion -->

<div class="py-2 px-2 mx-4 border">
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-12">
        <mat-accordion>
          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title style="color: rgb(32, 178, 170)">
                <b>Add Mask details</b>
              </mat-panel-title>
              <mat-panel-description></mat-panel-description>
            </mat-expansion-panel-header>

            <div class="row">
              <div class="col-12">
                <form [formGroup]="notesForm">
                  <mat-form-field class="col-6" >
                    <mat-label>Description</mat-label>
                    <textarea
                      autocomplete="new-txtNotes"
                      matInput
                      maxlength="5000"
                      formControlName="txtNotes"
                      type="text"
                    ></textarea>
                  </mat-form-field>
                  <button
                  mat-button
                  class="buttonColor mb-2 mr-2"
                  [disabled]="isSaveButtonDisabled"
                  (click)="onSave()"
                >
                  {{ isEditMode ? 'Save' : 'Save' }}
                </button>
                <button mat-button class="resetclr buttonColor mb-2 mr-2">
                  Reset
                </button>
                </form>



              </div>
            </div>
          </mat-expansion-panel>
        </mat-accordion>
      </div>
    </div>
  </div>
</div>

<!-- table -->



<div class="py-2 px-2 mx-4 border">
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-12" style="color: rgb(32, 178, 170)">
        <b>Add Mask details</b> </div>
        <div class="table-responsive shadow p-3">
          <table class="table"   #frequentItemsTable
          [dtOptions]="dtFrequentItemTableOptions"
          datatable
          class="row-border hover w-100">
            <thead class="mb-3" >
              <tr>
                <th>S.No</th>
                <th>Description</th>
                <th>Status</th>
                <th>Option</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let maskDetail of maskDetails; let i = index">
                <td>{{ i + 1 }}</td>
                <td>{{ maskDetail.description }}</td>
                <td><mat-slide-toggle [(ngModel)]="maskDetail.status"></mat-slide-toggle></td>
                <td>
                 
                  <mat-icon class="icon" style="color: rgb(32, 178, 170);" (click)="editMaskDetail(i)">edit</mat-icon>

                 
                  <mat-icon class="icon" style="color: rgb(178, 32, 32);" (click)="deleteMaskDetail(i)">delete</mat-icon>
                </td>
              </tr>
            </tbody>
          </table>

         
        </div>
     
    </div>
  </div>
</div>
