<div class="container-fluid">
    <div class="row bg">
      <div class="col-12">
        <div class="d-flex align-items-center justify-content-between mb-1">
          <h3 class="font-weight-bolder mb-0">{{patientIds?.defaultPatientId ? patientOrders + patientIds?.defaultPatientId  : 'Patient Orders' }}</h3>
          <div class="page-title-box page-title-right">
            <ol class="breadcrumbs">

            <button mat-button class="mr-2 buttonColor"  [disabled]="
            dataSource && dataSource?.data && dataSource?.data?.length === 0
          " mat-raised-button (click)="
            exporter.exportTable('xlsx', {
              fileName: 'Patient Orders List',
              sheet: 'PatientOrdersList',
              Props: { Author: 'QSecure' }
            })
          ">
             Export
            </button>

              <li class="breadcrumb-item mt-2 pl-2">Orders List</li>
              <li class="breadcrumb-item mt-2">Patient Orders </li>
            </ol>
          </div>
        </div>
      </div>
      <div class="col-lg-12">

      <!-- <div class="example-container mat-elevation-z4"> -->
        <form [formGroup]="claimProcessingForm">
        <div class="table table-responsive example-container mat-elevation-z4 pt-0 mt-0">
        <table mat-table [dataSource]="dataSource" matTableExporter #exporter="matTableExporter" matSort
        class="mat-elevation-z8 pt-0 mt-0" style="margin-left: 15px !important;">
          <!-- Document Id -->
          <ng-container matColumnDef="saleOrderId" sticky>
            <th class="header" mat-header-cell mat-sort-header *matHeaderCellDef>
              <mat-form-field class="filter">
                <input matInput formControlName="saleOrderId" autocomplete="new-saleOrderId" />
                <mat-placeholder class="center">Order ID
                </mat-placeholder>
              </mat-form-field>
            </th>
            <td mat-cell *matCellDef="let element">
             {{element?.saleOrderId }}
            </td>
          </ng-container>
          <!-- WIP ID-->
          <ng-container matColumnDef="items" sticky>
            <th class="header" mat-header-cell mat-sort-header *matHeaderCellDef>
              <!-- WIP Id -->
              <mat-form-field class="filter">
                <input matInput formControlName="items" autocomplete="new-items" />
                <mat-placeholder class="center">Item</mat-placeholder>
              </mat-form-field>
            </th>
            <td mat-cell *matCellDef="let element">
              {{element?.items}}
            </td>
          </ng-container>

          <ng-container matColumnDef="totalQuantity" sticky>
            <th class="header" mat-header-cell mat-sort-header *matHeaderCellDef>
              <mat-form-field class="filter">
                <input matInput formControlName="totalQuantity" autocomplete="new-totalQuantity" />
                <mat-placeholder class="center">Total Units </mat-placeholder>
              </mat-form-field>
            </th>
            <td mat-cell *matCellDef="let element">
              {{element?.totalQuantity }}
            </td>
          </ng-container>

          <ng-container matColumnDef="totalCharge">
            <th class="header" mat-header-cell mat-sort-header *matHeaderCellDef>
              <mat-form-field class="filter">
                <input matInput formControlName="totalCharge" autocomplete="new-totalCharge" />
                <mat-placeholder class="center"> Total Price</mat-placeholder>
              </mat-form-field>
            </th>
            <td mat-cell *matCellDef="let element">
              {{"$ " + element?.totalCharge}}
            </td>
          </ng-container>

          <ng-container matColumnDef="dateOfService">
            <th class="header" mat-header-cell mat-sort-header *matHeaderCellDef>
              <mat-form-field class="filter">
                <input matInput formControlName="dateOfService" autocomplete="new-dateOfService" />
                <mat-placeholder class="center"> DOS </mat-placeholder>
              </mat-form-field>
            </th>
            <td mat-cell *matCellDef="let element">
                {{ element?.dateOfService | date: "MM/dd/yyyy":"en_US" }}
            </td>
          </ng-container>

          <ng-container matColumnDef="creationTime">
            <th class="header" mat-header-cell mat-sort-header *matHeaderCellDef>
              <mat-form-field class="filter">
                <input matInput formControlName="creationTime" autocomplete="new-creationTime" />
                <mat-placeholder class="center">Created Date</mat-placeholder>
              </mat-form-field>
            </th>
            <td mat-cell *matCellDef="let element">
              {{element?.creationTime | date: "MM/dd/yyyy":"en_US"  }}
            </td>
          </ng-container>

          <ng-container matColumnDef="status">
            <th class="header" mat-header-cell mat-sort-header *matHeaderCellDef>
              <mat-form-field class="filter">
                <input matInput formControlName="status" autocomplete="new-status" />
                <mat-placeholder class="center">Status</mat-placeholder>
              </mat-form-field>
            </th>
            <td mat-cell *matCellDef="let element">
              {{element?.status }}
            </td>
          </ng-container>

          <!-- No Records-->
          <ng-container matColumnDef="noRecords">
            <td class="text-align-center" mat-footer-cell *matFooterCellDef colspan="6">
              <div *ngIf="!isLoading">
                {{ "No Records Found" }}
              </div>
              <div [hidden]="!isLoading">
                <mat-spinner [diameter]="220"></mat-spinner>
              </div>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="arrDisplayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: arrDisplayedColumns"></tr>
        </table>
        <div *ngIf="
              !(
                dataSource &&
                dataSource?.data &&
                dataSource?.data?.length !== 0
              )
            " class="text-align-center">
            <div class="message py-3" *ngIf="!isLoading">
              {{ "No Records Found" }}
            </div>
            <div class="py-3" [hidden]="!isLoading">
              <mat-spinner [diameter]="220"></mat-spinner>
            </div>
          </div>


        </div>
        </form>
        <mat-paginator [pageSizeOptions]="[10, 20, 50, 100]" showFirstLastButtons></mat-paginator>
      </div>
    </div>

    </div>
