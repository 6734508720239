import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { noWhitespaceValidator } from 'projects/patient/src/app/status/status.component';
import { MasterNotesService } from '../order-proxy/order-management/inventory/master-notes.service';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-resupply-call-notes',
  templateUrl: './resupply-call-notes.component.html',
  styleUrls: ['./resupply-call-notes.component.scss']
})
export class ResupplyCallNotesComponent implements OnInit {

  public noteForm: FormGroup;
  NoteTypeControl = new FormControl();
  isdrpLoading:boolean = false;
  drpNotetype:any;
  listNoteType: any;
  notesText = '';
  isShowSpinner:boolean=false;
  constructor(
    private fb: FormBuilder,
    private notesService: MasterNotesService,
  ) { }

  ngOnInit(): void {
    this.intilizationForm();

    this.noteTypeDetails();
    this.NoteTypeControl.valueChanges.subscribe((FilterText) => {
      FilterText = FilterText.toLowerCase();
      this.drpNotetype = this.listNoteType.filter((dx) =>
        dx.noteType.toLowerCase().includes(FilterText)
      );
    });
  }

  intilizationForm(): void {
    this.noteForm = this.fb.group({
      drpNotetype: new FormControl('', [Validators.required]),
      txtNoteType: new FormControl(''),
      chkIsCallNote: new FormControl('', [Validators.required]),
      txtDescription: new FormControl('', [
        Validators.required,
        noWhitespaceValidator,
      ]),
    });
  }

  noteTypeDetails() {
    this.isdrpLoading = true;
    const NoteTypes = this.notesService.getDrpNoteTypes().subscribe(
      (response) => {
        this.isdrpLoading = false;
        this.listNoteType = response;
        this.drpNotetype = this.listNoteType;

        // this.defaultNotetype=this.listNoteType.filter(x=>x.noteType==NoteType.generalNoted)[0]?.noteType;
        // this.defaultNotetypeId=this.listNoteType.filter(x=>x.noteType==NoteType.generalNoted)[0]?.id;
        // this.noteForm.patchValue({
        //   drpNotetype: this.defaultNotetypeId + '/' +  this.defaultNotetype
        // });
      },
      (err) => {
        this.isdrpLoading = true;
        const data: HttpErrorResponse = err;
        console.warn(data?.error?.error?.message);
      }
    );
  }

    //Update Character Count for TextArea
    updateCharacterCount(maxlength: number) {
      // Ensure the text length does not exceed the maximum length
      if (this.notesText?.length > maxlength) {
        this.notesText = this.notesText?.substr(0, maxlength);
      }
    }

    saveCallNotes(){

    }

    resetCallNotes() {
      this.noteForm.reset();
    }
}
