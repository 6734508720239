import { Component, OnInit ,Input,ViewChild, Output, EventEmitter} from '@angular/core';
import { Title } from '@angular/platform-browser';
import { DataTableDirective } from 'angular-datatables';
import { defaultGuid } from 'projects/shared/src/app/enums/allenums.enum';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { AddNoteTabComponent } from 'projects/patient/src/app/add-note-tab/add-note-tab.component';
import { HttpErrorResponse } from '@angular/common/http';
import { Observable, Subject, Subscription } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { PatientCommonNotesComponent } from 'projects/shared/src/app/components/patient-common-notes/patient-common-notes.component';
import { ViewPatientLedgerComponent } from 'projects/admin/src/app/view-patient-ledger/view-patient-ledger.component';
import { TextConversationComponent } from 'projects/patient/src/app/text-conversation/text-conversation.component';
import Swal from 'sweetalert2';
import { PatientManualVerificationTabComponent } from 'projects/patient/src/app/patient-manual-verification-tab/patient-manual-verification-tab.component';
import { RxMailSendComponent } from '../../rxMailSend/rxMailSend.component';
import { DatePipe } from '@angular/common';
import { ResupplyInactiveNotesComponent } from '../../resupply-inactive-notes/resupply-inactive-notes.component';
import { NoteType } from '../../order-proxy/order-management/inventory/dto';
import { PatientAddPolicyTabComponent } from 'projects/patient/src/app/patient-add-policy-tab/patient-add-policy-tab.component';
import { PatientAuthSaveUpdateFormComponent } from 'projects/patient/src/app/patient-auth-save-update-form/patient-auth-save-update-form.component';
import { month } from 'projects/admin/src/app/ngx-chart/ngx-chart.component';
import { VerificationStatus, VerificationStatusV1, VerificationStatusV2 } from 'projects/patient/src/app/patient-proxy/patient/enumeration-data/verification-status.enum';
import { MedicareStatus } from 'projects/patient/src/app/patient-proxy/patient/enumeration-data/medicare-status.enum';
import { CommunicationService } from 'projects/shared/src/app/services/communication.service';
import { OndemandOnlineVerificationComponent } from 'projects/patient/src/app/ondemand-online-verification/ondemand-online-verification.component';
@Component({
  selector: 'app-current-supplies',
  templateUrl: './current-supplies.component.html',
  styleUrls: ['./current-supplies.component.scss']
})
export class CurrentSuppliesComponent implements OnInit {
  currentSuppliesData : any;
  @Input() tableData : any[];
  @Output() getPatientDetails: EventEmitter<string> = new EventEmitter<string>();
  @Output() isLoadTbl = new EventEmitter<boolean>();
  isLoading : boolean;
  dtOptions: any;
  defaultGuid = defaultGuid;
  noteType: NoteType;
  @ViewChild(DataTableDirective, { static: false })
  datatableElement: DataTableDirective;
  subscription$: Subscription[] = [];
  $subscription: Subscription[] = [];
  patientId : string;
  chartId : string;
  headingTxt: string;
  noteTypes: NoteType;
  selectedData:any;
  isRoleName:string;
  verifyId:any;
  verifyStatus: any;
  policyId:any;
  lstVerificationSttausV2=VerificationStatusV2;
  MedicareStatusV1 = MedicareStatus;
  funcListener: any;
  constructor(
    public title: Title,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private datepipe:DatePipe
  ) {
    this.funcListener = this.func.bind(this);
    window.addEventListener('storage', this.funcListener);
  }

  ngOnInit(): void
    {
      this.title.setTitle('Qsecure | Current Supplies List');
      this.dtOptions = {
        responsive: true,
        stateSave: true,
        paging: false,
        dom: 'Bfrtip',
        language: {
          emptyTable: 'No records',
          info: 'Total : _MAX_ records',
          infoEmpty: 'Total : _MAX_ records',
          infoFiltered: '(filtered : _TOTAL_ records)',
          loadingRecords: 'Loading...',
          zeroRecords: 'No matching records',
        },
        scrollCollapse: true,
        scrollY: '300px',
        scrollX: true,
        fixedHeader: true,
        fixedColumns: {
          leftColumns: 3, // Number of columns to fix on the left
          rightColumns: 1, // Number of columns to fix on the right
        },
        columnDefs: [
          { targets: [0], orderable: false },
          { targets: [0, 1, 2, -1], className: 'dt-fixed-column' }, // Add class to make the first three and last column fixed
          // { targets: [10], visible: false },
          // {
          //   targets: [5, 6,7,8,9],
          //   type: 'date',
          //   render: function (data, type, row) {
          //     if (type === 'sort' || type === 'type') {
          //       var dateParts = data.split('/');
          //       return new Date(dateParts[2], dateParts[0] - 1, dateParts[1]);
          //     } else {
          //       return data;
          //     }
          //   },
          // },
        ],
        search: {
          smart: false, // Set to "none" for full exact match
        },
        buttons: [
          {
            extend: 'excel',
            text: 'Export',
            filename: 'Current Supplies',
            exportOptions: {
              columns: ':visible:not(:first-child) ',
            },
          },
          {
            extend: 'colvis',
            columns: ':not(:eq(0), :eq(1), :eq(2), :eq(-1))', // Exclude first three and last columns from the column visibility toggle
          }
        ],
      };
      this.currentSuppliesData = this.tableData;

      this.dtOptions.buttons[0].filename =
      'ReSupply - Current Supplies' + '-' + this.datepipe.transform(new Date(), 'MM-dd-yyyy');
      this.isRoleName = localStorage.getItem('roleName');
    }

    isEmpty(value: any): boolean {
      return (
        value === null ||
        value === undefined ||
        value === '' ||
        value === defaultGuid
      );
    }

    isEmptyStatus(value:any):boolean{
      return(
        value === null ||
        value === undefined ||
        value === ''
      )
    }

    ngAfterViewInit(): void {
      if (this.datatableElement && this.datatableElement.dtInstance) {
        this.refreshDataTable();
      } else {
        setTimeout(() => this.refreshDataTable(), 500);
      }
    }
    refreshDataTable(): void {
      this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
        // Clear search input values
        $('input', dtInstance.table(0).footer()).val('');
        // Redraw the DataTable to load all records
        dtInstance.search('').columns().search('').draw();
        // Attach the event listener for filtering
        dtInstance.columns().every(function () {
          const that = this;
          $('input', this.footer()).on('keyup change', function () {
            if (that.search() !== this['value']) {
              that.search(this['value']).draw();
            }
          });
        });
      });
    }
    ngOnDestroy(): void {
      // Unsubscribe to avoid memory leaks
      this.subscription$?.forEach((sub) => {
        sub && sub?.unsubscribe();
      });

      window.removeEventListener('resize', this.calculateScrollY);
    }


    calculateScrollY = () => {
      const screenHeight =
        window.innerHeight ||
        document.documentElement.clientHeight ||
        document.body.clientHeight;
      const availableSpacePercentage = 0.4; // Adjust the percentage as needed
      this.dtOptions.scrollY = screenHeight * availableSpacePercentage;
    };

    tblItemSelectedRow(data:any): void {
      this.selectedData = data;
      if(this.patientId !== data?.patientId){
        this.patientId = data?.patientId;
        this.chartId = data?.chartId;
        this.getPatientDetails.emit(data?.patientId);
      }
    }

    onRowDoubleClick(textToCopy) {
      this.copyTextToClipboard(textToCopy);
      this.showNotification('Text copied: ' + textToCopy);
    }

    showNotification(message: string): void {
      this.snackBar.open(message, 'Close', {
        duration: 3000, // Duration in milliseconds
        horizontalPosition: 'end', // Positioning the notification horizontally
        verticalPosition: 'bottom', // Positioning the notification vertically
      });
    }

    copyTextToClipboard(text: string): void {
      const textArea = document.createElement('textarea');
      textArea.value = text;
      document.body.appendChild(textArea);
      textArea.select();
      document.execCommand('Copy');
      textArea.remove();
    }

    openAddNotesPopUp() {
      const config: MatDialogConfig = {
        disableClose: true,
        minHeight: '30vh',
        minWidth: '50vw',
        data: {
          noteType: 'patient', //'Order Note',
          notesId: '00000000-0000-0000-0000-000000000000',
          patientId: this.patientId,
          defaultFaxId: this.chartId,
          isCallNote: false,
          isPending: false,
          // docId: this.documentId,
          inboundStatusShortCodeId: defaultGuid,
          selectedNode: defaultGuid,
          addnotes: true,
        },
      };
      const dialogRef = this.dialog.open(AddNoteTabComponent, config);

      const closeDialog = dialogRef.afterClosed().subscribe(
        () => {
        },
        (err) => {
          const data: HttpErrorResponse = err;
          Swal.fire({
            icon: 'info',
            text: data?.error?.error?.message,
          });
        }
      );
      this.subscription$.push(closeDialog);
    }

    openViewNotesPopup() {
      const config: MatDialogConfig = {
        disableClose: true,
        width: '70vw',
        maxWidth: '70vw',
        // height: '40vw',
        // maxHeight: '40vw',
        data: {
          patientId: this.patientId,
          pageType: 'Patient Note', // this.pageType, //Notes Table Popup Page
          isAddVisible: false,
        },
      };
      const dialogRef = this.dialog.open(PatientCommonNotesComponent, config);

      dialogRef.afterClosed().subscribe(
        () => {
        },
        (err: HttpErrorResponse) => {
          const data: HttpErrorResponse = err;
          Swal.fire({
            icon: 'info',
            text: data?.error?.error?.message,
          });
        }
      );
    }

    viewPatientLedger(patientId: string) {
      this.dialog.open(ViewPatientLedgerComponent, {
        width: '100vw',
        height: '100vh',
        maxWidth: '100vw',
        maxHeight: '100vh',
        panelClass: 'fullscreen-dialog-panel',
        disableClose: true,
        data: {
          patientId: patientId,
        },
      });
      this.dialog.afterAllClosed.subscribe((resposne) => {});
    }

    openTextConversation(){
      const config: MatDialogConfig = {
        disableClose: true,
        width: '60%',
        height:'70%',
        data: {
          isPatientMdl: true,
        }
      };
      const dialogRef = this.dialog.open(TextConversationComponent, config);

      dialogRef.afterClosed().subscribe(
        () => {
          //this.getReminderTableData();
        },
        (err: HttpErrorResponse) => {
          const data: HttpErrorResponse = err;
          Swal.fire({
            icon: 'info',
            text: data?.error?.error?.message,
          });
        }
      );
    }

    openAddNotesPopUpV1(
      event: MouseEvent,
      data: any,
      isCallNote: boolean
    ) {
      const config: MatDialogConfig = {
        disableClose: true,
        minHeight: '30vh',
        minWidth: '50vw',
        data: {
          noteType: 'patient', // 'Order Note',
          notesId: '00000000-0000-0000-0000-000000000000',
          patientId: data.patientId,
          defaultFaxId: data.defaultFaxId,
          isCallNote: isCallNote,
          isPending: false,
          docId: data.docId,
          inboundStatusShortCodeId: defaultGuid,
          addnotes: true,
          patientNotes: data.patientNotes,
        },
      };
      const dialogRef = this.dialog.open(AddNoteTabComponent, config);
      const closeDialog = dialogRef.afterClosed().subscribe(() => {
          this.getPatientDetails.emit(data?.patientId);
        },
        (err) => {
          const data: HttpErrorResponse = err;
          console.warn(data?.error?.error?.message);
        }
      );
      this.subscription$.push(closeDialog);
    }

    openLastVerification(element) {

      if (element == 'Primary') {
        const dialogRef = this.dialog.open(
          PatientManualVerificationTabComponent,
          {
            disableClose: true,
            data: {
              patientId: this.selectedData.patientId,
              verificationId: this.selectedData.primaryVerification,
              // policyId: this.priPolicyId,
              savehidden: true,
              isViewVerification: true,
              documentId: this.selectedData.documentId,
              // defaultFaxId:data?.defaultFaxId
            },
          }
        );
        const closeDialog = dialogRef.afterClosed().subscribe((result) => {
          if (result == 'Saved') {
            this.dialog.closeAll();
          }

          result !== '' &&
            result !== null &&
            result !== undefined &&
            this.dialog.closeAll();
        });
        this.subscription$.push(closeDialog);
      } else if (element == 'Secondary') {
        const dialogRef = this.dialog.open(
          PatientManualVerificationTabComponent,
          {
            disableClose: true,
            data: {
              patientId: this.selectedData.patientId,
              // policyId: this.secPolicyId,
              verificationId: this.selectedData.secVerification,
              savehidden: true,
              isViewVerification: true,
              documentId: this.selectedData.documentId,
              // defaultFaxId:data?.defaultFaxId
            },
          }
        );
        const closeDialog = dialogRef.afterClosed().subscribe((result) => {
          if (result == 'Saved') {
            this.dialog.closeAll();
          }

          result !== '' &&
            result !== null &&
            result !== undefined &&
            this.dialog.closeAll();
        });
        this.subscription$.push(closeDialog);
      }
  };

  rxMailSend(patientId:string,orderId:string,chartId:string){
    const dialogRef = this.dialog.open(RxMailSendComponent, {
      minHeight: '33vh',
      minWidth: '52vw',
      disableClose: true,
      data: {
        patientId:patientId,
        orderId:orderId,
        chartId: chartId
      },
    });
    dialogRef.afterClosed().subscribe((a) => {
    });
  }
  // supplyInactive(optionNo:number,chartId:string){
  //   // 1.Inactive current month
  //   // 2.Inactive entire month
  //   // 3.active supply
  //   if(optionNo==1){
  //     this.headingTxt = "Inactive Current Month";
  //     this.noteTypes = NoteType.currentMonthInactive;
  //   }else if(optionNo==2){
  //     this.headingTxt = "Inactive Entire Month";
  //     this.noteTypes = NoteType.entireMonthInactive;
  //   }
  //   const dialogRef = this.dialog.open(ResupplyInactiveNotesComponent, {
  //     minHeight: '33vh',
  //     minWidth: '52vw',
  //     disableClose: true,
  //     data: {
  //       headingTxt:this.headingTxt,
  //       noteType:this.noteTypes,
  //       chartId:chartId,
  //       patientId:this.selectedData.patientId,
  //       orderId:this.selectedData.orderId,
  //       currentMonth: optionNo === 1 ? true : false,
  //       isCurrentSuplies:true,
  //     },
  //   });
  //   dialogRef.afterClosed().subscribe((a) => {

  //   });
  // }

  supplyInactive(optionNo:number,chartId:string,nextCallDate:string){

    if(optionNo==1){
      this.headingTxt = "Inactive Current Month";
      // this.noteTypes = NoteType.currentMonthInactive;
      this.noteTypes = NoteType.monthInactive;
    }else if(optionNo==2){
      this.headingTxt = "Inactive Entire Month";
      this.noteTypes = NoteType.entireMonthInactive;
    }else if(optionNo==3){
      this.headingTxt = "Move To Archive";
      this.noteTypes = NoteType.archive;
    }else if(optionNo==4){
      this.headingTxt = "Active Patient";
      this.noteTypes = NoteType.activePatient;
    }

    const config: MatDialogConfig = {
      disableClose: true,
      minHeight: '33vh',
      minWidth: '52vw',
      data: {
        headingTxt:this.headingTxt,
        noteType:this.noteTypes,
        chartId:chartId,
        nextCallDate:nextCallDate,
        patientId:this.selectedData.patientId,
        orderId:this.selectedData.orderId,
        currentMonth: optionNo === 1 ? true : false,
        isCurrentSuplies:true,
        isMovetoArchive:optionNo === 3? true : false,
        isActivePatient:optionNo ===4? true : false,
      },
    };

    const dialogRef = this.dialog.open(ResupplyInactiveNotesComponent, config);

    const closeDialog = dialogRef.afterClosed().subscribe(
      () => {},
      (err) => {}
    );
    this.$subscription.push(closeDialog);
  }

  //Medicare Request
   // isDoChekList(data :any) : void {
  //   const url = '/#do_checklist/' + data?.patientId + '/' + data.medicareId;
  //   window.open(url, '_blank');
  // }

  isDoChekList(patientId :string) : void {
    const url = '/#currentsupplies/mastermedicarechecklist/' + patientId;
    window.open(url, '_blank');
  }
 
  //Pre-Cer Request
  doprecert(patientId :string) : void {
    const url = '/#currentsupplies/masterverification/' + patientId;
    window.open(url, '_blank');
  }
  doVerfication(policy: any) {
    if (policy.primaryInsId === defaultGuid) {
      if (policy.secondaryInsId === defaultGuid) {
        this.policyId = policy.secondaryInsId;
      } else {
        this.policyId = policy.terPolicyId;
      }
    } else {
      this.policyId = policy.primaryInsId;
    }
    if (policy.primaryVerification === defaultGuid) {
      if (policy.secVerificationId === defaultGuid) {
        this.verifyId = policy.secVerificationId;
      } else {
        this.verifyId = policy.terVerificationId;
      }
    } else {
      this.verifyId = policy.primaryVerification;
    }
    if (
      policy.verificationStatus === null ||
      policy.verificationStatus === ''
    ) {
      if (
        policy.secVerificationStatus === null ||
        policy.secVerificationStatus === ''
      ) {
        this.verifyStatus = policy.secVerificationStatus;
      } else {
        this.verifyStatus = policy.terVerificationStatus;
      }
    } else {
      this.verifyStatus = policy.verificationStatus;
    }
    const dialogRef = this.dialog.open(PatientAddPolicyTabComponent, {
      disableClose: true,
      data: {
        patientId: policy.patientId,
        policyId: this.policyId,
        documentId: policy.docId,
        wipId: policy.wipId ?? defaultGuid,
        verificationId: this.verifyId,
        verificationStatus: this.verifyStatus,
        defaultFaxId:policy?.defaultFaxId
      },
    });
    const aftClosed = dialogRef.afterClosed().subscribe(
      (result) => {
        this.isLoadTbl.next(true);

      },
      (err) => {
        const data: HttpErrorResponse = err;
        console.warn(data?.error?.error?.message);
      }
    );
    this.$subscription.push(aftClosed);
  }

  // doprecert(data: any) {
  //   if (data.primaryVerification === defaultGuid) {
  //     if (data.secVerification === defaultGuid) {
  //       this.verifyId = data.secVerification;
  //     } else {
  //       this.verifyId = data.terVerificationId;
  //     }
  //   } else {
  //     this.verifyId = data.primaryVerification;
  //   }
  //   const dialogRef = this.dialog.open(PatientAuthSaveUpdateFormComponent,{
  //     data: {
  //       documentId: data.docId,
  //       defaultFaxId:data?.defaultFaxId
  //     },
  //   });
  //   const componentInstance = dialogRef.componentInstance;
  //   componentInstance.patientId = data.patientId;
  //   componentInstance.verificationId = data.verifyId;
  //   componentInstance.authorizationId = data.authorizationId;
  //   componentInstance.docId = data.docId;
  //   componentInstance.isView = true;
  //   componentInstance.isSupplies = true;
  //   componentInstance.chartId = data.chartId;
  //   componentInstance.authStatusName = data.authStatus;
  // }

    // Freelancer Senthil Code
    func(event): void {
      if (event.key == 'checkNewTabData') {
        if (event.newValue == 'NewTabDataSaved') {
         this.isLoadTbl.emit(true);
        } else {
        }
      }
    }

    //Online/OnDemand Verfication Open Modal
    ondemandOnlineVerification(policy:any){
      if (policy.primaryInsId === defaultGuid) {
        if (policy.secondaryInsId === defaultGuid) {
          this.policyId = policy.secondaryInsId;
        } else {
          this.policyId = policy.terPolicyId;
        }
      } else {
        this.policyId = policy.primaryInsId;
      }
      if (policy.primaryVerification === defaultGuid) {
        if (policy.secVerificationId === defaultGuid) {
          this.verifyId = policy.secVerificationId;
        } else {
          this.verifyId = policy.terVerificationId;
        }
      } else {
        this.verifyId = policy.primaryVerification;
      }
      if (policy.verificationStatus === null ||policy.verificationStatus === '' ) {
        if (policy.secVerificationStatus === null ||policy.secVerificationStatus === '') {
          this.verifyStatus= policy.secVerificationStatus;
        } else {
          this.verifyStatus = policy.terVerificationStatus;
        }
      } else {
        this.verifyStatus = policy.verificationStatus;
      }
      const dialogRef = this.dialog.open(OndemandOnlineVerificationComponent, {
        data: {
          patientId: policy.patientId,
          policyId: this.policyId??defaultGuid,
          documentId: policy.docId,
          wipId: policy.wipId ?? defaultGuid,
          verificationId: this.verifyId,
          verificationStatus:this.verifyStatus,

        },
      });
      const aftClosed = dialogRef.afterClosed().subscribe(
        (result) => {
          this.isLoadTbl.next(true);
        },
        (err) => {
          const data: HttpErrorResponse = err;
          Swal.fire({
            icon: 'info',
            text: data?.error?.error?.message,
          });
        }
      );
      this.subscription$.push(aftClosed);
    }

    //View Pre Cert
    viewprecert(data) {
      if (data.primaryVerification === defaultGuid) {
        if (data.secVerification === defaultGuid) {
          this.verifyId = data.secVerification;
        } else {
          this.verifyId = data.terVerificationId;
        }
      } else {
        this.verifyId = data.primaryVerification;
      }
      const dialogRef = this.dialog.open(PatientAuthSaveUpdateFormComponent,{
        data: {
          documentId: data.documentId,
          defaultFaxId:data?.defaultFaxId
        },
      });
      const componentInstance = dialogRef.componentInstance;
      componentInstance.patientId = data.patientId;
      componentInstance.verificationId = data.verifyId;
      componentInstance.authorizationId = data.authorizationId;
      componentInstance.docId = data.documentId;
      componentInstance.faxId = data.faxId;
      componentInstance.isView = true;
      componentInstance.resethidden = true;
      componentInstance.isSupplies = true;
      componentInstance.chartId = data.chartId;
      componentInstance.authStatusName = data.authStatus;
      componentInstance.savehide = true;
  
    }
}
