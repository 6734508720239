<div class="col-12" style="margin-top: -17px; margin-bottom: -30px">
    <div class="d-flex align-items-center justify-content-between m-0">
        <div class=" page-title-box  page-title-right mt-4 mb-0">
            <ol class="breadcrumbs m-0">
            </ol>
        </div>
    </div>
</div>
<br />

<div class="card card-body">
    <mat-accordion>
        <mat-expansion-panel [expanded]="step">
            <mat-expansion-panel-header>
                <mat-panel-title class="customThemeClass">
                    <ng-container *ngIf="holidayId===''; else elseTitleTemplate">
                        <b>Add Holiday</b>
                    </ng-container>
                    <ng-template #elseTitleTemplate>
                        <b>Edit Holiday</b>
                    </ng-template>

                </mat-panel-title>
            </mat-expansion-panel-header>
            <form [formGroup]="holidayForm">
                <div class="row">
                    <div class="col-lg-12">
                        <mat-card-content>
                            <div class="row">
                                <mat-form-field class="col-4">
                                    <mat-label>Holiday Name<span class="asterisk">*</span></mat-label>
                                    <input autocomplete="new-txtHolidayName" matInput maxlength="40" formControlName="txtHolidayName"
                                        placeholder="Enter Holiday Name " type="text" />
                                    <mat-error *ngIf="
                                    holidayForm?.get('txtHolidayName')?.touched &&
                                    holidayForm?.get('txtHolidayName')?.errors?.required
                                            ">
                                        Holidayis a required field!
                                    </mat-error>
                                </mat-form-field>

                                <mat-form-field class="col-4">
                                    <mat-label> Date <span class="asterisk">*</span></mat-label>
                                    <input autocomplete="new-txtDate" [max]="BirthdateMaxDate" formControlName="txtDate" matInput maxlength="40"
                                        type="text" placeholder="(MM/DD/YYYY)" [matDatepicker]="txtDate"
                                        [min]="BirthdateMinDate" />
                                    <mat-datepicker-toggle matSuffix [for]="txtDate"> </mat-datepicker-toggle>
                                    <mat-datepicker #txtDate> </mat-datepicker>
                                    <mat-error *ngIf="
                                    holidayForm?.get('txtDate')?.touched &&
                                    holidayForm?.get('txtDate')?.errors">
                                        Enter Valid date
                                    </mat-error>
                                    <mat-error
                                        *ngIf=" holidayForm?.get('txtDate')?.touched && holidayForm?.get('txtDate')?.errors?.required">
                                        Date is a required field!
                                    </mat-error>
                                </mat-form-field>

                                <mat-form-field [hidden]="true" class="col-4">
                                    <mat-label>Year <span class="asterisk">*</span></mat-label>
                                    <mat-select maxlength="40" [(value)]="year" formControlName="drpYear">
                                        <mat-option *ngFor="let year of drpYear" [value]="year.holidayYears">
                                            {{year.holidayYears}}
                                        </mat-option>
                                    </mat-select>
                                    <button mat-button (click)="clearYear()" (click)="year='';$event.stopPropagation()"
                                        *ngIf="year" matSuffix mat-icon-button >
                                        <mat-icon>close</mat-icon>
                                    </button>
                                    <mat-error
                                        *ngIf=" holidayForm?.get('drpRoles')?.touched && holidayForm?.get('drpRoles')?.errors?.required">
                                        Role Name is a required field!
                                    </mat-error>
                                </mat-form-field>
                            </div>
                        </mat-card-content>
                    </div>
                </div>
            </form>
            <div class="row">
                <div class="col-sm-12">
                    <div class="text-lg-center">
                        <button mat-button class="buttonColor mb-2 mr-2"
                            [disabled]="!( isShownSaveButton ||!holidayForm.invalid && holidayForm.dirty )"
                            (click)="saveHoliday()">
                            Add
                            <mat-icon *ngIf='isShownSaveButton'>
                                <mat-spinner class="spinner-border spinner-border-sm" diameter="20"> </mat-spinner>
                            </mat-icon>
                        </button>
                        <button mat-button class="resetclr mb-2 mr-2" (click)="reset()">
                            Reset
                        </button>
                    </div>
                </div>
            </div>
            <br />
        </mat-expansion-panel>
    </mat-accordion>
    <div class="row">
        <div class="col-12 table-responsive">
            <div class="row mt-3">

                <div class="col-10">
                    <h4 class="customThemeClass ml-3"><b>Holiday List</b></h4>
                </div>

                <div class="col-md-2">
                    <form [formGroup]="holidayTotalSearchForm">
                        <div class="md-form mt-0 text-left" style="margin-left: -87px;">
                            <mat-form-field class="ml-5">
                                <mat-label>Year Search</mat-label>
                                <mat-select maxlength="40" [(value)]="year" formControlName="drpYear">
                                    <mat-option *ngFor="let year of drpYear" [value]="year.holidayYears">
                                        {{year.holidayYears}}
                                    </mat-option>
                                </mat-select>
                                <button mat-button (click)="clearYear()" (click)="year='';$event.stopPropagation()"
                                    *ngIf="year" matSuffix mat-icon-button >
                                    <mat-icon>close</mat-icon>
                                </button>
                                <!-- <mat-error
                                    *ngIf=" holidayForm?.get('drpYear')?.touched && holidayForm?.get('drpYear')?.errors?.required">
                                    Role Name is a required field!
                                </mat-error> -->
                            </mat-form-field>
                        </div>
                    </form>
                </div>


            </div>

            <!-- <div [hidden]="!isLoading"
                style="display: flex; justify-content: center; align-items: center; background: transparent; color: lightseagreen;">
                <mat-progress-bar mode="indeterminate" value='66' diameter="45" class="lightseagreen">
                </mat-progress-bar>
            </div> -->
            <table mat-table class="table ml-4 border" matSort [dataSource]="dataSource">
                <form style="display: flex" [formGroup]="holidaySearchForm">
                    <ng-container matColumnDef="sno">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>S.No</th>
                        <td mat-cell *matCellDef="let data; let i = index">
                            {{ i+1 }}
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="HolidayDate">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                            <mat-form-field class="filter">
                                <mat-label>Holiday Date </mat-label>
                                <input autocomplete="new-holidayDate" matInput formControlName="holidayDate" type="text" placeholder="(MM/DD/YYYY)" />
                                <mat-error
                                    *ngIf=" holidayForm?.get('holidayDate')?.touched && holidayForm?.get('holidayDate')?.errors?.dateVaidator">
                                    Enter Valid date
                                </mat-error>
                            </mat-form-field>
                        </th>
                        <td mat-cell *matCellDef="let data">
                            <ng-container>
                                {{data.holidayDate}}
                            </ng-container>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="holidayDescription">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                            <mat-form-field class="filter">
                                <input autocomplete="new-holidayName" matInput formControlName="holidayName" />
                                <mat-placeholder>Holiday Name</mat-placeholder>
                            </mat-form-field>
                        </th>
                        <td mat-cell *matCellDef="let data">
                            <ng-container>
                                {{data.holidayName}}
                            </ng-container>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="Options">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Options</th>
                        <td mat-cell *matCellDef="let data">
                            <a class="editBtnColor" (click)="ViewHoliday(data)">
                                <i class="fa fa-pencil pointer editBtnColor mr-3" matTooltip="Click To Edit"
                                    ></i>
                            </a>
                            &nbsp;
                            <a class="deleteBtnColor" (click)="deleteHoliday(data)">
                                <i class="fa fa-trash-o" matTooltip="Click To Delete" ></i>
                            </a>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="noRecords">
                        <td class="text-align-center" mat-footer-cell *matFooterCellDef colspan="9">
                            <div *ngIf="!isLoading">
                                {{"No Records Found"}}
                            </div>
                            <div [hidden]="!isLoading">
                                <mat-spinner [diameter]="220"></mat-spinner>
                              </div>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="paginator">
                        <td mat-footer-cell *matFooterCellDef colspan="8">
                            <mat-paginator class="table table-responsive mat-table-class" #MatPaginator
                                [length]="dataSource?.data?.length" [pageSize]="10" [pageIndex]="0" showFirstLastButtons
                                [pageSizeOptions]="[5, 10, 25, 100]">
                            </mat-paginator>
                        </td>
                    </ng-container>
                </form>
                <tr mat-footer-row *matFooterRowDef="['noRecords']"
                    [ngClass]="{ active: dataSource && dataSource?.data && dataSource?.data?.length !== 0 }"></tr>
                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
                <tr mat-footer-row *matFooterRowDef="['paginator']"></tr>
            </table>
        </div>
    </div>

</div>
