import { ListService } from '@abp/ng.core';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  FormGroupDirective,
  NgForm,
  Validators,
} from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Store } from '@ngxs/store';
import { productInterface } from 'projects/admin/src/app/admin-add-item-modal/admin-add-item-modal.component';
import { ProductType } from 'projects/inventory/src/app/item-proxy/item-management/items/product-type.enum';
import { ProductService } from 'projects/inventory/src/app/item-proxy/item-management/items/product.service';
import { MasterProviderService } from 'projects/order/src/app/order-proxy/order-management/inventory/master-provider.service';
import { getOrder } from 'projects/order/src/app/order-store/order.action';
import { BillingDropdowns } from 'projects/patient/src/app/dropdown-proxy/dropdown-management/dropdowns/billing-dropdowns.enum';
import { ItemSizeDTO } from 'projects/patient/src/app/dropdown-proxy/dropdown-management/dropdowns/dto/order/models';
import { OrderMasterDropdownService } from 'projects/patient/src/app/dropdown-proxy/dropdown-management/dropdowns/master-dropdown-service';
import { OrderDropdowns } from 'projects/patient/src/app/dropdown-proxy/dropdown-management/dropdowns/order-dropdowns.enum';
import {
  BillingMasterDropdownDTO,
  CodeDTO,
  POSDTO,
  TOSDTO,
} from 'projects/patient/src/app/dropdown-proxy/rcm/dropdown-management/dropdowns/dto/billing/models';
import { BillingMasterDropdownService } from 'projects/patient/src/app/dropdown-proxy/rcm/dropdown-management/dropdowns/master-dropdown-service/billing-master-dropdown.service';
import { NumberValidators } from 'projects/shared/src/app/validations/number-validator';
import Swal from 'sweetalert2';
import {
  ClaimDTO,
  PostingChargesDTO,
} from '../billing-proxy/billing-management/posting/dto/models';
import { getBilling, updateBilling } from '../billing-store/billing.action';
import { SaleorderDTO } from '../claim-proxy/claim-processing-management/rcm/order-management/order/dto/models';
import { ToastrService } from "ngx-toastr";
export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(
    control: FormControl | null,
    form: FormGroupDirective | NgForm | null
  ): boolean {
    const isSubmitted = form && form.submitted;
    return !!(
      control &&
      control.invalid &&
      (control.dirty || control.touched || isSubmitted)
    );
  }
}

@Component({
  selector: 'app-posting-item-popup',
  templateUrl: './posting-item-popup.component.html',
  styleUrls: ['./posting-item-popup.component.scss'],
  providers: [ListService],
})
export class PostingItemPopupComponent implements OnInit {
  public postingForm: FormGroup;
  drpCode: CodeDTO[] = [];
  matcher = new MyErrorStateMatcher();
  drpModifiers: any[] = [];
  drpPos: POSDTO[] = [];
  drpTos: TOSDTO[] = [];
  drpProviders: any[] = [];
  drpinsurance: insuranceface[] = [];
  drpSize: ItemSizeDTO[] = [];
  size;
  drpitem: productInterface[] = [];
  tos: string = '';
  constructor(
    private fb: FormBuilder,
    private number: NumberValidators,
    private billingDropdownService: BillingMasterDropdownService,
    private providerList: MasterProviderService,
    public list: ListService,
    private orderDropdownService: OrderMasterDropdownService,
    public dialogRef: MatDialogRef<PostingItemPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { data: any },
    private itemsService: ProductService,
    private store: Store,
    private toastr: ToastrService,
  ) { }
  ngOnInit(): void {
    this.postingForm = this.fb.group({
      drpDiag9: new FormControl(''),
      drpDiag10ID: new FormControl(''),
      drpCode: new FormControl('', Validators.required),
      txtDescription: new FormControl(''),
      txtProvider: new FormControl(''),
      txtCheckNo: new FormControl(''),
      txtModifiers1: new FormControl(null),
      txtModifiers2: new FormControl(''),
      txtModifiers3: new FormControl(''),
      txtModifiers4: new FormControl(''),
      drpDx1: new FormControl(''),
      drpDx2: new FormControl(''),
      drpDx3: new FormControl(''),
      drpDx4: new FormControl(''),
      drpPos: new FormControl(''),
      drpTos: new FormControl(''),
      txtDateFrom: new FormControl('', Validators.required),
      txtDateTo: new FormControl(''),
      txtPrice: new FormControl('', [Validators.required, this.number.isNumberCheck]),
      txtUnits: new FormControl('', [
        Validators.required,
        this.number.isNumberCheck,
      ]),
      txtCopay: new FormControl(''),
      txtOther: new FormControl(''),
      txtTotal: new FormControl('', [this.number.isNumberCheck]),
      txtDocument: new FormControl(''),
      txtStatement: new FormControl(''),
      txtPatientResp: new FormControl(''),
      txtIcd10Filter: new FormControl(null),
      txtIcd9Filter: new FormControl(null),
      drpSize: new FormControl(''),
    });

    this.itemDropdown();
    this.getDropdown();

    this.postingForm.controls['txtUnits'].valueChanges.subscribe((response) => {
      this.calulateTotalByUnitsPrice(
        response,
        this.postingForm.controls['txtPrice'].value
      );
    });
    this.postingForm.controls['txtPrice'].valueChanges.subscribe((response) => {
      this.calulateTotalByUnitsPrice(
        this.postingForm.controls['txtUnits'].value,
        response
      );
    });
  }
  logErrors(){

  }
  /// initial dropdown
  dos: string = '';
  total: number;
  round: string;
  onChange(value: string) {
    const selItem: productInterface =
      this.drpitem?.filter((e) => (e?.name ?? '') === value)[0] ?? null;
    if (selItem) {
      this.postingForm.patchValue({
        txtPrice: selItem?.price,
        txtTotal: (
          +(this.data?.data?.units ?? 0) * (selItem?.price ?? 0)
        )?.toString(),
      });
    }
  }
  calulateTotalByUnitsPrice(txtUnits: string, txtPrice: string) {
    const units = +txtUnits ?? 0;
    const fee = +txtPrice ?? 0;
    this.postingForm.patchValue({
      txtTotal: (units * fee).toFixed(2),
    });
  }

  itemDropdown(): void {
    this.drpitem = [];
    this.itemsService.getProductsByInput(ProductType.All).subscribe(
      (response) => {
        response?.forEach((element) => {
          this.drpitem.push({
            hcpcCode: element?.hcpcCode,
            productDescription: element?.productDescription,
            productCode: element?.productCode,
            status: element?.status,
            price: element?.price,
            // productStockId:element?.productStockId,
            // lotId: element?.lotId,
            // currentAvailableStockCount:element?.currentAvailableStockCount,
            productId: element?.productId,
            productName: element?.productName,
            productType: element?.productType,
            productNames: element?.productName,
            brandName: element?.brandName,
            categoryName: element?.categoryName,
            name: element?.productName,
          });
        });
        this.getById(this.data?.data);
      },
      (err) => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      }
    );
    this.store.dispatch(new getOrder(this.data?.data?.orderId)).subscribe(
      (response) => {
        this.postingForm.patchValue({
          drpPos: response?.orderState.selectedOrder?.order?.placeOfService,
        });
        // response?.orderState.selectedOrder?.items?.forEach(element => {
        //   this.dos = element?.originalDos
        //   this.drpitem.push({
        //     itemId: element?.itemIdOrBarCode,
        //     id: element?.id,
        //     name: element?.itemIdOrBarCode + " " + "/" + " " + element?.name,
        //     dos: element?.originalDos
        //   });
        // });
      },
      (err) => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      }
    );
  }

  getClaimInfo() {
    let claimDetails: ClaimDTO;
    this.isShowSpinner = true;
    this.saveButtonHide = true;
    this.store.dispatch(new getBilling(this.data?.data?.claimId)).subscribe(
      (response) => {
        claimDetails = response?.billingState?.selectedBilling;

        this.savePosting(claimDetails);
      },
      (err) => {
        this.saveButtonHide = false;
        this.isShowSpinner = false;
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      },
      () => { }
    );
  }

  isShowSpinner: boolean = false;
  saveButtonHide: boolean = false;
  //! save posting and charges
  postingChargesId: '00000000-0000-0000-0000-000000000000';
  savePosting(response: ClaimDTO) {
    let postingDetails: ClaimDTO;
    let currentPosting: PostingChargesDTO[] = response?.lstPostingCharges;
    currentPosting = currentPosting?.filter(
      (lstPostingCharges) =>
        lstPostingCharges &&
        lstPostingCharges?.postingChargesId != this.data?.data?.postingId
    );
    if (this.postingChargesId != '00000000-0000-0000-0000-000000000000') {
      const selItem: productInterface =
        this.drpitem.filter(
          (e) => (e?.name ?? '') === this.postingForm.value.drpCode
        )[0] ?? null;
      currentPosting = [
        ...currentPosting,
        {
          itemSize: this.postingForm.value.drpSize,
          itemId: this.data?.data?.itemId,
          productId: selItem?.productId,
          postingChargesId: this.data?.data?.postingId,
          diagnosisCode9: this.data?.data?.diagnosis9,
          diagnosisCode10: this.data?.data?.diagonsis10,
          item: this.postingForm.value.drpCode,
          insurance: this.postingForm.value.txtDescription,
          modifier1: this.postingForm.value.txtModifiers1,
          modifier2: this.postingForm.value.txtModifiers2,
          modifier3: this.postingForm.value.txtModifiers3,
          modifier4: this.postingForm.value.txtModifiers4,
          pos: this.data?.data?.pos,
          tos: this.postingForm.value.drpTos,
          provider: this.postingForm.value.txtProvider,
          dateFrom: this.postingForm.value.txtDateFrom,
          dateTo: this.postingForm.value.txtDateTo,
          dxPointer1: this.postingForm.value.drpDx1,
          dxPointer2: this.postingForm.value.drpDx2,
          dxPointer3: this.postingForm.value.drpDx3,
          dxPointer4: this.postingForm.value.drpDx4,
          checkNo: this.postingForm.value.txtCheckNo,
          fee: this.postingForm.value.txtPrice ?? "",
          units: this.postingForm.value.txtUnits,
          copay: this.postingForm.value.txtCopay,
          other: this.postingForm.value.txtOther,
          total: this.postingForm.value.txtTotal ?? "",
          document: this.postingForm.value.txtDocument,
          statementNote: this.postingForm.value.txtStatement,
          cpts: '',
          dos: new Date(this.dos)?.toLocaleDateString('en-US'),
          status: 'Ready',
          patientResp: this.postingForm.value.txtPatientResp == true ? 1 : 0,
          code: this.data?.data?.codes,
          description: this.data?.data?.description,
        },
      ];
    }
    postingDetails = {
      lstClaimInfo: response.lstClaimInfo,
      lstPostingCharges: currentPosting ?? [],
      insurancePayments: {
        primaryInsurancePaymentDetails: {
          source: '',
          code: '',
          description: '',
          checkNo: '',
          date: '',
          amount: '',
          plansOfFiles: '',
          statementNote: '',
          lstItemPayment: [],
          paymentType: '',
          reason: '',
          eobDocuments: null,
          totalBilled: 0,
          totalAllowed: 0,
          totalDeduct: 0,
          totalCoInsurance: 0,
          totalWriteOff: 0,
          totalInsurancePaid: 0,
          totalInsBal: 0,
          totalPatientBal: 0,
        },
        secondaryInsurancePaymentDetails: {
          source: '',
          code: '',
          description: '',
          checkNo: '',
          date: '',
          amount: '',
          plansOfFiles: '',
          statementNote: '',
          lstItemPayment: [],
          paymentType: '',
          reason: '',
          eobDocuments: null,
          totalBilled: 0,
          totalAllowed: 0,
          totalDeduct: 0,
          totalCoInsurance: 0,
          totalWriteOff: 0,
          totalInsurancePaid: 0,
          totalInsBal: 0,
          totalPatientBal: 0,
        },
        tertiaryInsurancePaymentDetails: {
          source: '',
          code: '',
          description: '',
          checkNo: '',
          date: '',
          amount: '',
          plansOfFiles: '',
          statementNote: '',
          lstItemPayment: [],
          paymentType: '',
          reason: '',
          eobDocuments: null,
          totalBilled: 0,
          totalAllowed: 0,
          totalDeduct: 0,
          totalCoInsurance: 0,
          totalWriteOff: 0,
          totalInsurancePaid: 0,
          totalInsBal: 0,
          totalPatientBal: 0,
        },
      },
      saleOrder: response.saleOrder,
      patientId: response.patientId,
      orderId: response.orderId,
      saleorderId: response.saleorderId,
      defaultPatientId: response.defaultPatientId,
      patientName: response.patientName,
      patientDob: response.patientDob,
      readyCount: 0,
      processedCount: 0,
      crossOverCount: 0,
      holdCount: 0,
      patientResponsibilityCount: 0,
      patientResponsibilityAfterInsCount: 0,
      claimId: response.claimId,
      lstClearingHouseUpdates: [],
      totalPatientBal: 0,
      lstPatientVisitNotes: [],
      totalBilled: 0,
      totalInsBal: 0,
      lstReminders: response?.lstReminders,
      claimStatus: response?.claimStatus,
      isVoided: response?.isVoided,
      claimProcessMethod: response?.claimProcessMethod,
    };

    if (this.data?.data?.claimId != null && this.data?.data?.claimId != '') {
      this.store
        .dispatch(new updateBilling(this.data?.data?.claimId, postingDetails))
        .subscribe(
          (value) => {
            // Swal.fire({
            //   title: 'Success',
            //   html: 'Saved Successfully',
            //   icon: 'success',
            //   timer: 3000,
            //   timerProgressBar: true,
            // });
            this.toastr.success('Saved Successfully','Success')
            this.dialogRef.close();

            this.postingChargesId = '00000000-0000-0000-0000-000000000000';

            this.saveButtonHide = false;
            this.isShowSpinner = false;
          },
          (err) => {
            this.saveButtonHide = false;
            this.isShowSpinner = false;
            const data: HttpErrorResponse = err;
            Swal.fire({
              icon: 'info',
              text: data?.error?.error?.message,
            });
          }
        );
    }
  }
  ///// get item view
  getById(data) {
    this.postingForm.patchValue({
      drpCode: data?.item,
      txtDescription: data?.insurance,
      txtProvider: data?.provider,
      txtCheckNo: data?.checkNo ?? "",
      txtModifiers1: data?.m1,
      txtModifiers2: data?.m2,
      txtModifiers3: data?.m3,
      txtModifiers4: data?.m4,
      drpDx1: data?.dx1,
      drpDx2: data?.dx2,
      drpDx3: data?.dx3,
      drpDx4: data?.dx4,
      drpPos: data?.pos,
      drpTos: data?.tos,
      txtDateFrom: data?.dateFrom,
      txtDateTo: data?.dateTo,
      txtPrice: data?.fee,
      txtUnits: data?.units,
      txtCopay: data?.copay,
      txtOther: data?.patOther,
      txtTotal: data?.total,
      txtDocument: data?.document,
      txtStatement: data?.statement,
      txtPatientResp: data?.patientResp,
      drpSize: data?.itemSize,
    });
  }

  //! dropdown list
  getDropdown() {
    let billingDropdownInput: BillingDropdowns[];
    billingDropdownInput = [
      BillingDropdowns.Codes,
      BillingDropdowns.Modifiers,
      BillingDropdowns.Pos,
      BillingDropdowns.Tos,
    ];
    this.billingDropdownService
      .getBillingDropdownsByInput(billingDropdownInput)
      .subscribe(
        (stateResponse) => {
          let response: BillingMasterDropdownDTO = stateResponse;
          this.drpCode = response.codes;
          this.drpModifiers = response.modifiers;
          this.drpPos = response.pos;
          this.drpTos = response.tos;
        },
        (err) => {
          const data: HttpErrorResponse = err;
          Swal.fire({
            icon: 'info',
            text: data?.error?.error?.message,
          });
        }
      );

    const orderDropdownInput: OrderDropdowns[] = [OrderDropdowns.Itemsize];
    this.orderDropdownService
      .getOrderDropdownsByInput(orderDropdownInput)
      .subscribe(
        (stateResponse) => {
          this.drpSize = stateResponse?.itemSize ?? [];
        },
        (err) => {
          const data: HttpErrorResponse = err;
          Swal.fire({
            icon: 'info',
            text: data?.error?.error?.message,
          });
        }
      );
    //! provider getlist
    const providerList = (query: any) => this.providerList.getList(query);
    this.list.hookToQuery(providerList).subscribe((response) => {
      response?.items?.forEach((element) => {
        this.drpProviders?.push({
          id: element?.id,
          name: element?.fullName,
          shortCodeId: element?.shortCodeId,
          fullName: element?.fullName,
          npi: element?.npi,
        });
      });
    });
  }

  pos: string = '';
  modifiers1: string = '';
  modifiers2: string = '';
  modifiers3: string = '';
  modifiers4: string = '';
  dx1: string = '';
  dx2: string = '';
  dx3: string = '';
  dx4: string = '';
  code: string = '';
  description: string = '';
  clearPos() {
    this.pos = '';
    this.postingForm.patchValue({ drpPos: '' });
  }
  clearModifiers1() {
    this.modifiers1 = '';
    this.postingForm.patchValue({ drpModifiers1: '' });
  }
  clearSize() {
    this.size = '';
    this.postingForm.patchValue({ drpSize: '' });
  }
  clearModifiers2() {
    this.modifiers2 = '';
    this.postingForm.patchValue({ drpModifiers2: '' });
  }
  clearModifiers3() {
    this.modifiers3 = '';
    this.postingForm.patchValue({ drpModifiers3: '' });
  }
  clearModifiers4() {
    this.modifiers4 = '';
    this.postingForm.patchValue({ drpModifiers4: '' });
  }
  clearTos() {
    this.tos = '';
    this.postingForm.patchValue({ drpTos: '' });
  }
  clearDescription() {
    this.description = '';
    this.postingForm.patchValue({ txtDescription: '' });
  }
  clearCode() {
    this.code = '';
    this.postingForm.patchValue({ drpCode: '' });
  }
  clearDx1() {
    this.dx1 = '';
    this.postingForm.patchValue({ drpDx1: '' });
  }

  clearDx2() {
    this.dx2 = '';
    this.postingForm.patchValue({ drpDx2: '' });
  }
  clearDx3() {
    this.dx3 = '';
    this.postingForm.patchValue({ drpDx3: '' });
  }
  clearDx4() {
    this.dx4 = '';
    this.postingForm.patchValue({ drpDx4: '' });
  }
}

export interface itemInterface {
  equipmentBillingCode?: string;
  id?: string;
  itemId?: string;
  name?: string;
  dos?: string;
  location?: string;
  insurance?: string;
  charge?: number;
}
export interface insuranceface {
  id: string;
  policy: string;
  name: string;
  policiesId: string;
  payorLevel: string;
}
