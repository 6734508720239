import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatAccordion } from '@angular/material/expansion';

@Component({
  selector: 'app-supply-items',
  templateUrl: './supply-items.component.html',
  styleUrls: ['./supply-items.component.scss']
})
export class SupplyItemsComponent implements OnInit {
  AppontmentForm: FormGroup
  constructor(private formBuilder: FormBuilder,) { }
  @ViewChild('firstAccordion') firstAccordion: MatAccordion;
  appointTypeId: string = "";
  panelOpenState = false;
  step: boolean = false;
  public strPageType: string ="appointmentType";

  ngOnInit(): void {
    this.AppontmentForm = this.formBuilder.group({
      txtAppointDate: new FormControl(""),
      txtDuration: new FormControl(""),
      chkActive: new FormControl(""),
    })
  }
  saveAppointment() {

  }
  reset() {

  }
}
