<br>
<div class="container-fluid">
  <div class="row bg">
    <div class="col-12">
      <div class="d-flex align-items-center justify-content-between mb-3">
        <h3 class="font-weight-bolder mb-0">Product List</h3>
        <div class=" page-title-box page-title-right">
          <ol class="breadcrumbs m-0 align-items-center">
            <!-- (click)="exportTableData()" -->
            <button mat-button class="mr-2 buttonColor"
            [disabled]="(MainProduct && MainProduct?.data && MainProduct?.data?.length === 0)"

            (click)="mainExportTableData()">
              Main Product Export
            </button>
            <li>
              <button matTooltip="Click To Add New Product" mat-button class="buttonColor font-size-10"
                (click)="openProductModal()">
                <!-- <i class="mdi mdi-plus mr-1"></i> -->
                <i class="fa fa-plus mr-1" ></i>
                Add Product
              </button>
            </li>
            <li class="ml-2 mt-2 breadcrumb-item">Inventory</li>
            <li class="mt-2 breadcrumb-item muted">Product</li>
          </ol>
        </div>
      </div>
    </div>
  </div>
</div>
<br>

<!-- <h4 class="customThemeClass ml-3"><b>Main Product List</b></h4> -->

  <div class="col-12" style="padding-right: 1px;
  padding-left: 1px">
    <div class="card card-body">
      <h4 class="customThemeClass ml-3 mt-2"><b>Main Product List</b></h4>

      <div class="col-12">
    <form [formGroup]="mainProductForm">
      <!-- <div [hidden]="!isLoading"
        style="display: flex; justify-content: center; align-items: center; background: transparent; color: lightseagreen;">
        <mat-progress-bar mode="indeterminate" value='66' diameter="45" class="lightseagreen">
        </mat-progress-bar>
      </div> -->
      <div class="tabspace table table-responsive">
        <table mat-table [dataSource]="MainProduct" matSort class="mat-elevation-z8 border">
          <ng-container matColumnDef="S.No">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>S.No</th>
            <td mat-cell *matCellDef="let data; let i = index">
              {{ i+1 }}
            </td>
          </ng-container>

          <!-- Name Column -->
          <ng-container matColumnDef="Name">
            <!-- <th mat-header-cell *matHeaderCellDef> Name </th> -->
            <th class="header" mat-sort-header mat-header-cell *matHeaderCellDef>
              <mat-form-field class="filter">
                <input autocomplete="new-Name" matInput (keydown.space)="$event.stopPropagation()" (click)="$event.stopPropagation()"
                formControlName="Name" />
                <mat-placeholder>Product Name</mat-placeholder>
              </mat-form-field>
            </th>
            <td mat-cell *matCellDef="let element">
              <span matTooltip="{{element?.name}}" matTooltipClass="custom-tooltip-font"> {{ element?.name
                }}</span>
            </td>
          </ng-container>
          <!-- brandName Column -->
          <ng-container matColumnDef="Brand">
            <!-- <th mat-header-cell *matHeaderCellDef> brandName </th> -->
            <th class="header" mat-sort-header mat-header-cell *matHeaderCellDef>
              <mat-form-field class="filter">
                <input autocomplete="new-productCode" matInput (keydown.space)="$event.stopPropagation()" (click)="$event.stopPropagation()"
                formControlName="productCode" />
                <mat-placeholder>Product Code</mat-placeholder>
              </mat-form-field>
            </th>
            <td mat-cell *matCellDef="let element"> {{ element?.productCode }}</td>
          </ng-container>

          <!-- Symbol Column -->
          <ng-container matColumnDef="Category">
            <!-- <th mat-header-cell *matHeaderCellDef> category </th> -->
            <th class="header" mat-sort-header fxFlex="200" mat-header-cell *matHeaderCellDef>
              <mat-form-field class="filter">
                <input autocomplete="new-description" matInput (keydown.space)="$event.stopPropagation()" (click)="$event.stopPropagation()"
                formControlName="description" />
                <mat-placeholder>Description</mat-placeholder>
              </mat-form-field>
            </th>
            <td fxFlex="200" mat-cell *matCellDef="let element"> <span class="ellipsis .cut-text">
                {{ element?.productDescription }} </span>
            </td>
          </ng-container>

          <ng-container matColumnDef="Price">
            <!-- <th mat-header-cell *matHeaderCellDef> Account Number </th> -->
            <th class="header  w-175" mat-sort-header mat-header-cell *matHeaderCellDef>
              <mat-form-field class="filter">
                <input autocomplete="new-hcpcCode" matInput (keydown.space)="$event.stopPropagation()" (click)="$event.stopPropagation()"
                formControlName="hcpcCode" />
                <mat-placeholder>Hcpc Code</mat-placeholder>
              </mat-form-field>
            </th>
            <td mat-cell *matCellDef="let element">{{ element?.hcpcCode }}</td>
          </ng-container>

          <ng-container matColumnDef="createdDate">
            <th class="header" mat-header-cell *matHeaderCellDef mat-sort-header>
              <mat-form-field class="filter">
                <mat-label> Created Date</mat-label>
                <input autocomplete="new-createdDate" matInput (keydown.space)="$event.stopPropagation()" (click)="$event.stopPropagation()"
                formControlName="createdDate" type="text" placeholder="(MM/DD/YYYY)" />
                <mat-error
                  *ngIf=" mainProductForm?.get('createdDate')?.touched && mainProductForm?.get('createdDate')?.errors?.dateVaidator">
                  Enter Valid date
                </mat-error>
              </mat-form-field>
            </th>
            <td mat-cell *matCellDef="let element">
              <ng-container>
                {{ element?.createdDate }}
              </ng-container>
            </td>
          </ng-container>

          <ng-container matColumnDef="productStatus">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              <mat-form-field class="filter">
                <input autocomplete="new-productStatus" matInput (keydown.space)="$event.stopPropagation()" (click)="$event.stopPropagation()"
                formControlName="productStatus" />
                <mat-placeholder>Product Status</mat-placeholder>
              </mat-form-field>
            <td mat-cell *matCellDef="let element">{{ element?.stockStatus }}</td>
          </ng-container>

          <ng-container matColumnDef="minQty">
            <th class="header" mat-sort-header mat-header-cell *matHeaderCellDef >
              <mat-form-field class="filter">
                <input autocomplete="new-minQty" matInput formControlName="minQty" />
                <mat-placeholder>Minimum Units</mat-placeholder>
              </mat-form-field>
            <td mat-cell *matCellDef="let element">{{ element?.minimumQuantity }}</td>
          </ng-container>

          <ng-container matColumnDef="avaiQty">
            <th class="header" mat-sort-header mat-header-cell *matHeaderCellDef >
              <mat-form-field class="filter">
                <input autocomplete="new-avaiQty" matInput (keydown.space)="$event.stopPropagation()" (click)="$event.stopPropagation()"
                formControlName="avaiQty" />
                <mat-placeholder>Available Units</mat-placeholder>
              </mat-form-field>
            <td mat-cell *matCellDef="let element">{{ element?.availableQuantity }}</td>
          </ng-container>

          <ng-container matColumnDef="status">
            <th  class="header" mat-sort-header mat-header-cell *matHeaderCellDef mat-sort-header>
              <mat-form-field class="filter">
                <input autocomplete="new-status" matInput (keydown.space)="$event.stopPropagation()" (click)="$event.stopPropagation()"
                formControlName="status" />
                <mat-placeholder>Status</mat-placeholder>
              </mat-form-field>
            </th>
            <td mat-cell *matCellDef="let data">
              <ng-container *ngIf="data?.mainProductStatus==='Active'">
                <mat-slide-toggle (change)="statusMainChange($event, data)" [checked]='true' class="ml-2">
                  {{data?.mainProductStatus}}</mat-slide-toggle>
              </ng-container>
              <ng-container *ngIf="data?.mainProductStatus==='Inactive'">
                <mat-slide-toggle (change)="statusMainChange($event, data)" [checked]='false' class="ml-2">
                  {{data?.mainProductStatus}}</mat-slide-toggle>
              </ng-container>
            </td>
          </ng-container>

          <ng-container matColumnDef="Options">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              Options
            </th>
            <td mat-cell *matCellDef="let data">
              <a class="editBtnColor">
                <i class="fa fa-pencil pointer" matTooltip="Click To Edit" (click)="ViewProduct(data)"
                  ></i>
              </a> &nbsp;
              <!-- Viiw Allowance -->
              <a mat-button class="btn btn-sm lightSeaGreen-dot mr-2" (click)="viewAddProductModal(data)">
                {{optionStatus}}
              </a>
              <a [hidden]="true" class="deleteBtnColor">
                <i class="fa fa-trash pointer" matTooltip="Click To Delete" (click)="DeleteProduct(data)"
                  ></i>
              </a>
            </td>
          </ng-container>

          <ng-container matColumnDef="noRecord">
            <td class="text-align-center" mat-footer-cell *matFooterCellDef colspan="9">
              <div *ngIf="isLoading">
                {{"No Records Found"}}
              </div>

              <div *ngIf="!isLoading">
               {{"Processing"}}
              </div>
              <!-- <div [hidden]="isLoading">
                <mat-spinner [diameter]="220"></mat-spinner>
              </div> -->
            </td>
          </ng-container>
          <ng-container matColumnDef="MainPaginator">
            <td mat-footer-cell *matFooterCellDef colspan="9">
              <mat-paginator class="table table-responsive" #MainPaginator [length]="MainProduct?.data?.length"
                [pageSize]="10" [pageIndex]="0" showFirstLastButtons [pageSizeOptions]="[5, 10, 25, 100]">
              </mat-paginator>
            </td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
          <tr mat-footer-row *matFooterRowDef="['noRecord']"
            [ngClass]="{ actives: MainProduct && MainProduct.data && MainProduct.data.length !== 0 }"></tr>
          <tr mat-footer-row *matFooterRowDef="['MainPaginator']"></tr>
        </table>
      </div>
    </form>
  </div>
  </div>
</div>

<div class="card card-body">
<div class="row">
  <div class="col-8">
    <h4 class=" col-7 customThemeClass ml-3 mt-2"><b>Resupply Product List</b></h4>
  </div>
  <div class="col-4 text-right marginLeft">
    <button mat-button class=" col-5 mr-2 buttonColor"
    [disabled]="(dataSource && dataSource?.data && dataSource?.data?.length === 0)"

    (click)="resupplyExportTableData()">
      Resupply Product Export
    </button>
  </div>
</div>


  <div class="col-12">
    <form [formGroup]="supplyProductForm">
      <div class="tabspace table table-responsive">
        <table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8 border">
          <ng-container matColumnDef="S.No">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>S.No</th>
            <td mat-cell *matCellDef="let data; let i = index">
              {{ i+1 }}
            </td>
          </ng-container>
          <!-- Name Column -->
          <ng-container matColumnDef="Name">
            <!-- <th mat-header-cell *matHeaderCellDef> Name </th> -->
            <th class="header" mat-sort-header mat-header-cell *matHeaderCellDef>
              <mat-form-field class="filter">
                <input autocomplete="new-Name" matInput (keydown.space)="$event.stopPropagation()" (click)="$event.stopPropagation()"
                formControlName="Name" />
                <mat-placeholder>Resupply Name</mat-placeholder>
              </mat-form-field>
            </th>
            <td mat-cell *matCellDef="let element">
              <span matTooltip="{{element?.name}}" matTooltipClass="custom-tooltip-font"> {{ element?.name
                }}</span>
            </td>
          </ng-container>
          <!-- brandName Column -->
          <ng-container matColumnDef="Brand">
            <!-- <th mat-header-cell *matHeaderCellDef> brandName </th> -->
            <th class="header" mat-sort-header mat-header-cell *matHeaderCellDef>
              <mat-form-field class="filter">
                <input autocomplete="new-productCode" matInput (keydown.space)="$event.stopPropagation()" (click)="$event.stopPropagation()"
                formControlName="productCode" />
                <mat-placeholder>Product Code</mat-placeholder>
              </mat-form-field>
            </th>
            <td mat-cell *matCellDef="let element"> {{ element?.productCode }}</td>
          </ng-container>

          <!-- Symbol Column -->
          <ng-container matColumnDef="Category">
            <!-- <th mat-header-cell *matHeaderCellDef> category </th> -->
            <th class="header" mat-sort-header fxFlex="200" mat-header-cell *matHeaderCellDef>
              <mat-form-field class="filter">
                <input autocomplete="new-description" matInput (keydown.space)="$event.stopPropagation()" (click)="$event.stopPropagation()"
                formControlName="description" />
                <mat-placeholder>Description</mat-placeholder>
              </mat-form-field>
            </th>
            <td fxFlex="200" mat-cell *matCellDef="let element"> <span class="ellipsis .cut-text">
                {{ element?.productDescription }} </span>
            </td>
          </ng-container>

          <ng-container matColumnDef="Price">
            <!-- <th mat-header-cell *matHeaderCellDef> Account Number </th> -->
            <th class="header  w-175" mat-sort-header mat-header-cell *matHeaderCellDef>
              <mat-form-field class="filter">
                <input autocomplete="new-hcpcCode" matInput (keydown.space)="$event.stopPropagation()" (click)="$event.stopPropagation()"
                formControlName="hcpcCode" />
                <mat-placeholder>hcpcCode</mat-placeholder>
              </mat-form-field>
            </th>
            <td mat-cell *matCellDef="let element">{{ element?.hcpcCode }}</td>
          </ng-container>

          <ng-container matColumnDef="createdDate">
            <th class="header" mat-sort-header mat-header-cell *matHeaderCellDef >
              <mat-form-field class="filter">
                <mat-label> Created Date</mat-label>
                <input autocomplete="new-createdDate" matInput (keydown.space)="$event.stopPropagation()" (click)="$event.stopPropagation()"
                formControlName="createdDate" type="text" placeholder="(MM/DD/YYYY)" />
                <mat-error
                  *ngIf=" supplyProductForm?.get('createdDate')?.touched && supplyProductForm?.get('createdDate')?.errors?.dateVaidator">
                  Enter Valid date
                </mat-error>
              </mat-form-field>
            </th>
            <td mat-cell *matCellDef="let element">
              <ng-container>
                {{ element?.createdDate }}
              </ng-container>
            </td>
          </ng-container>

          <ng-container matColumnDef="productStatus">
            <th class="header" mat-header-cell *matHeaderCellDef mat-sort-header>
              <mat-form-field class="filter">
                <input autocomplete="new-productStatus" matInput (keydown.space)="$event.stopPropagation()" (click)="$event.stopPropagation()"
                formControlName="productStatus" />
                <mat-placeholder>Product Status</mat-placeholder>
              </mat-form-field>
            <td mat-cell *matCellDef="let element">{{ element?.stockStatus }}</td>
          </ng-container>

          <ng-container matColumnDef="minQty">
            <th class="header" mat-sort-header mat-header-cell *matHeaderCellDef >
              <mat-form-field class="filter">
                <input autocomplete="new-minQty" matInput (keydown.space)="$event.stopPropagation()" (click)="$event.stopPropagation()"
                formControlName="minQty" />
                <mat-placeholder>Minimum Units</mat-placeholder>
              </mat-form-field>
            <td mat-cell *matCellDef="let element">{{ element?.minimumQuantity }}</td>
          </ng-container>

          <ng-container matColumnDef="avaiQty">
            <th class="header" mat-sort-header mat-header-cell *matHeaderCellDef >
              <mat-form-field class="filter">
                <input autocomplete="new-avaiQty" matInput (keydown.space)="$event.stopPropagation()" (click)="$event.stopPropagation()"
                formControlName="avaiQty" />
                <mat-placeholder>Available Units</mat-placeholder>
              </mat-form-field>
            <td mat-cell *matCellDef="let element">{{ element?.availableQuantity }}</td>
          </ng-container>


          <ng-container matColumnDef="status">
            <th class="header" mat-sort-header mat-header-cell *matHeaderCellDef >
              <mat-form-field class="filter">
                <input autocomplete="new-status" matInput (keydown.space)="$event.stopPropagation()" (click)="$event.stopPropagation()"
                formControlName="status" />
                <mat-placeholder>Status</mat-placeholder>
              </mat-form-field>
            </th>
            <td mat-cell *matCellDef="let data">
              <ng-container *ngIf="data?.resupplyProductStatus==='Active'">
                <mat-slide-toggle (change)="statusChanged($event, data)" [checked]='true' class="ml-2">
                  {{data?.resupplyProductStatus}}</mat-slide-toggle>
              </ng-container>
              <ng-container *ngIf="data?.resupplyProductStatus==='Inactive'">
                <mat-slide-toggle (change)="statusChanged($event, data)" [checked]='false' class="ml-2">
                  {{data?.resupplyProductStatus}}</mat-slide-toggle>
              </ng-container>
            </td>
          </ng-container>

          <ng-container matColumnDef="Options">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              Options
            </th>
            <td mat-cell *matCellDef="let data">
              <a class="editBtnColor">
                <i class="fa fa-pencil pointer" matTooltip="Click To Edit" (click)="ViewSupplyProduct(data)"
                  ></i>
              </a> &nbsp;
              <!-- Viiw Allowance -->
              <a mat-button class="btn btn-sm lightSeaGreen-dot mr-2" (click)="ViewAddSupplyModalProduct(data)">
                {{optionStatus}}
              </a>
              <a [hidden]="true" class="deleteBtnColor">
                <i class="fa fa-trash pointer" matTooltip="Click To Delete" (click)="DeleteSupplyProduct(data)"
                  ></i>
              </a>
            </td>
          </ng-container>

          <ng-container matColumnDef="noRecords">
            <td class="text-align-center" mat-footer-cell *matFooterCellDef colspan="9">
              <div *ngIf="!isProgress">
                {{"No Records Found"}}
              </div>
              <div [hidden]="!isProgress">
                <mat-spinner [diameter]="220"></mat-spinner>
              </div>

            </td>
          </ng-container>
          <ng-container matColumnDef="paginator">
            <td mat-footer-cell *matFooterCellDef colspan="9">
              <mat-paginator class="table table-responsive" #paginator [length]="dataSource?.data?.length"
                [pageSize]="10" [pageIndex]="0" showFirstLastButtons [pageSizeOptions]="[5, 10, 25, 100]">
              </mat-paginator>
            </td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="arrDisplayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: arrDisplayedColumns"></tr>
          <tr mat-footer-row *matFooterRowDef="['noRecords']"
            [ngClass]="{ active: dataSource && dataSource.data && dataSource?.data?.length !== 0 }"></tr>
          <tr mat-footer-row *matFooterRowDef="['paginator']"></tr>
        </table>
      </div>
    </form>


</div>

</div>
