<div class="modal-header"  cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
  <h2 class="modal-title">{{vchartNo ? vchartNo : 'N/A'}} - {{vpatientName}}</h2>
  <!-- <i aria-label="Close" mat-dialog-close class="fa fa-times"></i> -->
  <mat-icon mat-dialog-close>close</mat-icon>
</div>
<!-- <mat-dialog-content class="mat-typography"> -->

<!-- <div class="card-body"> -->
  <!-- <div class="text-right" style="text-align-last: right">
    <mat-icon mat-dialog-close>close</mat-icon>
  </div> -->


  <!-- <div class="card card-body mt-1" *ngIf="!isLoading" style="width: 70rem;">

    <div class="card-title">
      <div class="row">
        <div class="col-md-5">
          <h4 class="customThemeClass justify-content-center"><b> {{vchartNo}} - {{vpatientName}}</b></h4>
        </div>
        <div class="col-md-4">
        </div>
        <div class="col-md-3 text-right" *ngIf="!isAddVisible">
          <button mat-button class="buttonColor" (click)="openAddNotesPopUp(false)">
            <i class="fa fa-plus mr-1"></i> Add Note
          </button>
        </div>

      </div>
    </div>
    <div class="row">
      <div class="col-6">
        <div class="text-right">
          <button mat-button [disabled]="!editFormValues||!editOrderStatus" *ngIf="isAddVisible" (click)="NoteModal()"
            class="buttonColor font-size-14">
            <i class="fa fa-plus mr-1"></i>
            Add Note
          </button>
        </div>
      </div>
    </div>
    <div *ngIf="!isLoading" class="row mt-3">

      <div class="col-lg-12">

        <div *ngIf="!isLoading" class="table table-responsive">

          <table id="tbl" datatable [dtOptions]="dtOptions" style="width: 100%"
            class="row-border hover hover nowrap_th_td">
            <thead>
              <tr>
                <th class="text-center">Entered By Name</th>
                <th class="text-center">Entered</th>
                <th class="text-center">Note Type</th>
                <th class="text-center">Note Text</th>
                <th class="text-center">Modified</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let data of PatientNotesDetailsV1" (click)="openDescription(data.description)">
                <td class="text-center">{{ data.createdByName }}</td>
                <td class="text-center">{{ data.creationTime | date : "MM/dd/yyyy" }}</td>
                <td class="text-center">{{ data.noteType }}</td>
                <td class="text-center">{{ data.description }}</td>
                <td class="text-center">{{ data?.modifiedDate?(data?.modifiedDate | date : "MM/dd/yyyy" :
                  "en_US"):'_'}}
                </td>
              </tr>
            </tbody>
          </table>

        </div>
      </div>
    </div>
    <div class="row" *ngIf="isLoading">
      <div class="col-12">
        <div class="d-flex justify-content-center" *ngIf="isLoading">
          <img class="img-responsive pb-2 mr-1" src="assets/gifs/Infinity-loader.gif" />
        </div>
      </div>
    </div>
  </div> -->

  <div class="card card-body">
    <!-- Header Section -->
    <div class="card-title" *ngIf="!isLoading">
      <!-- <div class="row"> -->
        <!-- <div class="col-md-5"> -->
          <!-- <h4 class="customThemeClass justify-content-center"><b>{{vchartNo ? vchartNo : 'N/A'}} - {{vpatientName}}</b>
          </h4> -->
        <!-- </div>
        <div class="col-md-4">
        </div> -->
        <!-- <div class="col-md-4" > -->
          <button mat-button class="buttonColor custombtn" (click)="openAddNotesPopUp(false)" *ngIf="!isAddVisible">
            <i class="fa fa-plus mr-1"></i> Add Note
          </button>
        </div>
      <!-- </div>
    </div> -->

    <!-- Add Note Button Section -->
    <div class="row" *ngIf="!isLoading">
      <div class="col-6">
        <div class="text-right">
          <button mat-button [disabled]="!editFormValues || !editOrderStatus" *ngIf="isAddVisible" (click)="NoteModal()"
            class="buttonColor font-size-14">
            <i class="fa fa-plus mr-1"></i> Add Note
          </button>
        </div>
      </div>
    </div>

    <!-- Table Section -->
    <div class="row mt-3">
      <!-- Loader Section -->
      <div class="col-12" *ngIf="isLoading">
        <div class="d-flex justify-content-center">
          <img class="img-responsive pb-2 mr-1" src="assets/gifs/Infinity-loader.gif" />
        </div>
      </div>
      <div class="col-12" *ngIf="!isLoading">
        <app-patient-notes-modal [vchartNo]="vchartNo" [vDescription]="vDescription" [vPatientVerificationId]="data.patientVerificationId"
          [tableData]="PatientNotesDetailsV1" [vPatientAuthorizationId]="data.patientAuthorizationId" [docId]="data.docId" [vMedicareChecklistIdId]="data.patientMedicareChecklistId"></app-patient-notes-modal>
        <!-- <table id="tbl" datatable [dtOptions]="dtOptions" style="width: 100%" class="row-border hover nowrap_th_td">
            <thead>
              <tr>
                <th class="text-center">Options</th>
                <th class="text-center">Entered By Name</th>
                <th class="text-center">Entered</th>
                <th class="text-center">Note Type</th>
                <th class="text-center">Note Text</th>
                <th class="text-center">Modified By</th>
                <th class="text-center">Modified</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let data of PatientNotesDetailsV1; let i = index"
                (click)="openDescription(data.description); setSelectedRow(i)"
                [ngClass]="{'selected-row': i === selectedRowIndex}">
                <td class="text-center">
                  <a class="eyeCursorclass" target="_blank">
                    <i class="fa fa-pencil editBtnColor mr-1" (click)="UpdateNotes(data.notesId,false)"></i>
                  </a>
                </td>

                <td class="text-center" [title]=" data.createdByName || '-'">{{ data.createdByName || '-' }}</td>
                <td class="text-center" [title]=" data?.creationTime ? (data.creationTime | date : 'MM/dd/yyyy h:mm a' : 'en_US') : '-'" >{{ data?.creationTime ? (data.creationTime | date : "MM/dd/yyyy h:mm a" : "en_US") : '-' }}</td>
                <td class="text-center" [title]=" data.noteType || '-'" >{{ data.noteType || '-' }}</td>
                <td class="text-center" [title]=" data.description || '-'" >{{ data.description || '-' }}</td>
                <td class="text-center" [title]=" data.modifiedBy || '-'"  >{{ data.modifiedBy || '-' }}</td>
                <td class="text-center" [title]=" data?.lastModificationTime ? (data?.lastModificationTime | date : 'MM/dd/yyyy h:mm:ss a' : 'en_US') : '-' "  >
                  {{ data?.lastModificationTime ? (data?.lastModificationTime | date : "MM/dd/yyyy h:mm:ss a" : "en_US") : '-' }}
                </td>

              </tr>
            </tbody>

          </table> -->
      </div>
    </div>
  </div>
<!-- </div> -->
<!-- </mat-dialog-content> -->
