import { AuditedEntityDto, FullAuditedEntityDto, mapEnumToOptions } from '@abp/ng.core';
// import { NoteType } from 'projects/patient/src/app/patient-proxy/patient-optimization/dto';

export interface CreateUpdateDescriptionDTO {
  bcbsGroupId?: string;
  providerId?: string;
  defaultProviderId?: string;
  facilityProviderId?: string;
  medicaidGroupId?: string;
  medicaidProviderId?: string;
  medicareGroupId?: string;
  medicareProviderId?: string;
  npiGroupId?: string;
  npiProviderId?: string;
  signatureLine?: string;
  taxanomyCode?: string;
  workerCompGroupId?: string;
  workersCompProviderId?: string;
}

export interface CreateUpdateInsuranceProviderDTO {
  insuranceName: string;
  insuranceShortCodeId?: string;
  phoneNumber?: string;
  mmFax?: string;
  lolaFax?: string;
  payorId?: string;
  mmTaxId?: string;
  ptan?: string;
  mmAddress?: string;
  npi?: string;
  callBack?: string;
  mmCharlotteAddress?: string;
}

export interface CreateUpdateInsuranceProviderProductEligibilityRulesDTO {
  ruleId?: string;
  locationId: string;
  insuranceId?: string;
  itemName: string;
  price: number;
  equipmentBillingCode: string;
  itemsAllowed: number;
  minPeriodInMonths: number;
  rulesValidatity: CreateUpdateRulesValidatityDTO;
}

export interface CreateUpdateItemEligibilityRulesDTO {
  locationId?: string;
  itemCode: string;
  itemName: string;
  price: number;
  rulesValidities: CreateUpdateRulesValidityDTO[];
}

export interface CreateUpdateMasterNotesDTO {
  noteType:NoteType;
  noteReason?:string;
  }


export interface CreateUpdateMasterProviderDTO {
  code?: string;
  defaultPractice?: string;
  firstName?: string;
  lastName?: string;
  fullName?: string;
  mi?: string;
  homeAddress?: string;
  state?: string;
  city?: string;
  zip?: string;
  phone?: string;
  fax?: string;
  mobile?: string;
  email?: string;
  credentials?: string;
  apptChargeProv?: string;
  insranceName?: string;
  lolaFax?: string;
  ptan?: string;
  mmAddress?: string;
  npi?: string;
  callBack?: string;
  charlotteAddress?: string;
  payorId?: string;
  mmTaxId?: string;
  isActive: number;
  isAppoinments: number;
  isBillingProvider: number;
  isSignatureOnFile: number;
  isRenderingProvider: number;
  description: CreateUpdateDescriptionDTO;
  shortCodeId?: string;
  prefixCode: string;
  tpi?: string;
  country?: string;
}

export interface CreateUpdateRulesValidatityDTO {
  startDate?: string;
  endDate?: string;
  active: boolean;
}

export interface CreateUpdateRulesValidityDTO {
  ruleId?: string;
  insuranceName?: string;
  eligibleQuantity: number;
  eligibleDays: number;
  eligibleMonths?: string;
  status?: string;
  startDate?: string;
  endDate?: string;
}

export interface DescriptionDTO extends AuditedEntityDto<string> {
  bcbsGroupId?: string;
  providerId?: string;
  defaultProviderId?: string;
  facilityProviderId?: string;
  medicaidGroupId?: string;
  medicaidProviderId?: string;
  medicareGroupId?: string;
  medicareProviderId?: string;
  npiGroupId?: string;
  npiProviderId?: string;
  signatureLine?: string;
  taxanomyCode?: string;
  workerCompGroupId?: string;
  workersCompProviderId?: string;
}

export interface InsuranceProviderDTO extends AuditedEntityDto<string> {
  insuranceName?: string;
  insuranceShortCodeId?: string;
  phoneNumber?: string;
  mmFax?: string;
  lolaFax?: string;
  payorId?: string;
  mmTaxId?: string;
  ptan?: string;
  mmAddress?: string;
  npi?: string;
  callBack?: string;
  mmCharlotteAddress?: string;
}

export interface InsuranceProviderProductEligibilityRulesDTO extends AuditedEntityDto<string> {
  ruleId?: string;
  locationId?: string;
  insuranceId?: string;
  itemName?: string;
  price: number;
  equipmentBillingCode?: string;
  itemsAllowed: number;
  minPeriodInMonths: number;
  rulesValidatity: RulesValidatityDTO;
}

export interface ItemEligibilityRulesDTO extends FullAuditedEntityDto<string> {
  locationId?: string;
  itemCode?: string;
  itemName?: string;
  price: number;
  rulesValidities: RulesValidityDTO[];
}


export enum NoteType {
  general='General Note',
  financial = 'Financial Note',
  practitioner = 'Practitioner Note',
  patient='Patient Note',
  order='Order Note',
  invoice='Invoice Note',
  faxNote='Fax Note',
  others='Others',
  currentMonthInactive='Current Month Inactive',
  monthInactive='Month Inactive',
  activeSupplies='Active Supplies',
  entireMonthInactive='Entire Month Inactive',
  archive='Inactive Patient',
  activePatient='Fax Process Note',
}

export enum NotesType {
  general='General Note',
  financial = 'Financial Note',
  practitioner = 'Practitioner Note',
  patient='patient',
  order='Order Note',
  invoice='invoice',
  shipping = 'shipping',
  faxNote='Fax Note',
  orderRelated='Order Related',
  others='Others',
}

export interface MasterNotesDTO extends AuditedEntityDto<string> {
  noteType:NoteType;
  noteReason?:string;

  }


  export interface statusDTO  {
    inboundStatus: string;
    creationTime:any;
    id:string;
    }
export const noteTypeOptions = mapEnumToOptions(NoteType);


export interface MasterProviderDTO extends FullAuditedEntityDto<string> {
  code?: string;
  defaultPractice?: string;
  firstName?: string;
  lastName?: string;
  fullName?: string;
  mi?: string;
  homeAddress?: string;
  country?: string;
  state?: string;
  city?: string;
  zip?: string;
  phone?: string;
  fax?: string;
  mobile?: string;
  email?: string;
  credentials?: string;
  apptChargeProv?: string;
  insranceName?: string;
  lolaFax?: string;
  ptan?: string;
  mmAddress?: string;
  npi?: string;
  callBack?: string;
  charlotteAddress?: string;
  payorId?: string;
  mmTaxId?: string;
  isActive: number;
  isAppoinments: number;
  isBillingProvider: number;
  isSignatureOnFile: number;
  isRenderingProvider: number;
  description: DescriptionDTO;
  shortCodeId?: string;
  prefixCode: string;
  tpi?: string;
}

export interface RulesValidatityDTO {
  startDate?: string;
  endDate?: string;
  active: boolean;
}

export interface RulesValidityDTO {
  ruleId?: string;
  insuranceName?: string;
  eligibleQuantity: number;
  eligibleDays: number;
  eligibleMonths?: string;
  status?: string;
  startDate?: string;
  endDate?: string;
}

export interface UploadItemsDTO {
  fileContentAsBase64String: string;
  fileName: string;
}
