import { ListService, PagedAndSortedResultRequestDto } from '@abp/ng.core';
import { HttpErrorResponse } from '@angular/common/http';
import {
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Title } from '@angular/platform-browser';
import {
  RegionCitiesDTO,
  RegionCountriesDTO,
} from 'projects/patient/src/app/dropdown-proxy/dropdown-management/dropdowns/dto/models';
import { RegionDropdownService } from 'projects/patient/src/app/dropdown-proxy/dropdown-management/dropdowns/region-dropdown-service';
import { RegionStatesDTO } from 'projects/patient/src/app/dropdown-proxy/rcm/dropdown-management/dropdowns/dto';
import { ReportService } from 'projects/patient/src/app/patient-proxy/patient/report.service';
import { defaultCountry } from 'projects/shared/src/app/enums/allenums.enum';
import { TableService } from 'projects/shared/src/app/table.service';
import { DateValidator } from 'projects/shared/src/app/validations/date-validator';
import { NumberValidators } from 'projects/shared/src/app/validations/number-validator';
import { Observable, Subscription } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import Swal from 'sweetalert2';
import emailMask from 'text-mask-addons/dist/emailMask';
import {
  ItemExportDatas,
  ItemExportDTO,
} from 'projects/patient/src/app/patient-proxy/patient/dto/models';
import { Status, VendorService } from '../item-proxy/item-management/items';
import {
  CreateUpdateVendorDTO,
  VendorDTO,
  VendorSearchDTO,
} from '../item-proxy/item-management/items/dto';
import { ExportType } from 'projects/patient/src/app/patient-proxy/patient/enumeration-data/export-type.enum';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-vendor',
  templateUrl: './vendor.component.html',
  styleUrls: ['./vendor.component.scss'],
  providers: [ListService],
})
export class VendorComponent implements OnInit, OnDestroy {
  vendorId: string = '';
  emailMask = emailMask;
  vendorForm: FormGroup;
  vSearchForm: FormGroup;
  isShown: boolean = false;
  dataSource: MatTableDataSource<any>;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  displayedColumns: string[] = [
    'Options',
    'vendorCode',
    'vendorName',
    'creationTime',
    'status',
  ];
  public strPageType: string = 'InventoryItem';
  phoneCode: string;
  isShowSpinner: boolean;
  saveButtonHide: boolean;
  tableData: VendorDTO[] = [];
  step: boolean = false;
  Country: string = '';
  State: string = '';
  City: string = '';

  drpCountry: RegionCountriesDTO[] = [];
  drpStates: RegionStatesDTO[] = [];
  drpCities: RegionCitiesDTO[] = [];

  public filteredCountries: Observable<RegionCountriesDTO[]>;
  public filteredStates: Observable<RegionStatesDTO[]>;
  public filteredCities: Observable<RegionCitiesDTO[]>;
  subscription$: Subscription[] = [];
  CountryName = '';
  stateName = '';

  constructor(
    private reportService: ReportService,
    private formBuilder: FormBuilder,
    public title: Title,
    private Table: TableService,
    public list: ListService,
    private vendorService: VendorService,
    private dateValidator: DateValidator,
    private numberValidators: NumberValidators,
    private region: RegionDropdownService,
    private ref: ChangeDetectorRef,
    private toastr: ToastrService
  ) {}

  ngOnInit(): void {
    this.title.setTitle('Qsecure | Vendor');
    this.vendorForm = this.formBuilder.group({
      txtVendorName: new FormControl('', Validators.required),
      txtVendorCode: new FormControl('', Validators.required),
      txtVendorAddress: new FormControl('', Validators.required),
      txtVendorPhoneNumber: new FormControl('', Validators.required),
      txtVendorEmailId: new FormControl('', Validators.required),
      txtPersonPhoneNumber: new FormControl(''),
      txtPersonName: new FormControl(''),
      txtStatus: new FormControl(''),
      // txtQty: new FormControl("", [Validators.required, this.numberValidators.isNumberCheck]),
      txtCountry: new FormControl(''),
      txtState: new FormControl(''),
      txtCity: new FormControl(''),
      txtCountryFilter: new FormControl(''),
      txtStateFilter: new FormControl(''),
      txtCityFilter: new FormControl(''),
      txtQty: new FormControl(''),
    });
    this.vSearchForm = this.formBuilder.group({
      vName: new FormControl(''),
      vcode: new FormControl(''),
      vPersonNo: new FormControl(''),
      vQty: new FormControl(''),
      vPname: new FormControl(''),
      vCreatedOn: new FormControl('', this.dateValidator.dateVaidator),
      Options: new FormControl(''),
      vStatus: new FormControl(''),
    });

    this.getVendorTable();
    const valueChanges = this.getVendorForm()?.valueChanges?.subscribe(() => {
      const txtVendorName: AbstractControl =
        this.getVendorForm()?.get('txtVendorName');
      if (txtVendorName?.dirty) {
        txtVendorName?.value?.trim() === '' &&
          txtVendorName?.setErrors({ required: true });
        txtVendorName?.markAsDirty();
        txtVendorName?.markAsTouched();
      }
    });
    this.subscription$.push(valueChanges);
    //
    const getVendorValueChange = this.getVendorSForm()?.valueChanges?.subscribe(
      (resposne) => {
        this.getVendorSForm()?.valid && this.getSearchedVendorTable();
      }
    );
    this.subscription$.push(getVendorValueChange);
    this.countryDropdown();
  }

  ngOnDestroy(): void {
    this.subscription$?.forEach((sub) => {
      sub && sub?.unsubscribe();
    });
  }

  // vendor dropdown
  countryDropdown() {
    this.vendorForm.patchValue({ txtCountry: defaultCountry.US });
    this.onChangeState(defaultCountry.US);
    this.Country = defaultCountry.US;
    this.drpCountry = [];
    const countryDropdown = this.region
      .getAllCountriesByInput(new PagedAndSortedResultRequestDto())
      .subscribe(
        (response) => {
          this.drpCountry = response.items;
          this.filteredCountries = this.vendorForm
            ?.get('txtCountryFilter')
            .valueChanges.pipe(
              startWith(''),
              map((value) =>
                this.drpCountry.filter((option) =>
                  option?.countryName
                    ?.toLowerCase()
                    ?.includes(value?.toLowerCase() ?? '')
                )
              )
            );
        },
        (err) => {
          const data: HttpErrorResponse = err;
          Swal.fire({
            icon: 'info',
            text: data?.error?.error?.message,
          });
        }
      );
    this.subscription$.push(countryDropdown);
  }

  //  state city//

  onChangeState(value: string | null | undefined): boolean {
    this.vendorForm.patchValue({
      txtState: null,
      txtCity: null,
    });
    this.drpStates = [];
    this.drpCities = [];
    if (value !== null && value !== undefined) {
      const getStates = this.region
        .getAllStatesByCountryNameByCountryShortName(value)
        .subscribe(
          (response) => {
            this.drpStates = response.items;
            this.ref.detach();
            if (response.items == []) {
              this.vendorForm.controls['txtPersonPhoneNumber'].disable();
              this.phoneCode = '';
            } else {
              this.vendorForm.controls['txtPersonPhoneNumber'].enable();
              this.phoneCode = response?.items[0]?.countryPhoneCode;
            }
            this.ref.reattach();
            this.filteredStates = this.vendorForm
              ?.get('txtStateFilter')
              .valueChanges.pipe(
                startWith(''),
                map((value) =>
                  this.drpStates.filter((option) =>
                    option?.stateName
                      ?.toLowerCase()
                      ?.includes(value?.toLowerCase() ?? '')
                  )
                )
              );
          },
          (err) => {
            const data: HttpErrorResponse = err;
            Swal.fire({
              icon: 'info',
              text: data?.error?.error?.message,
            });
          }
        );
      this.subscription$.push(getStates);
    }

    return this.drpStates !== [] && this.drpStates.length !== 0;
  }

  /// state pass for city
  onChangeCity(state: string | null | undefined, country: string): boolean {
    this.vendorForm.patchValue({
      txtBillingCity: null,
    });
    country =
      country == ''
        ? this.vendorForm.value.txtCountry == (null || undefined)
          ? ''
          : this.vendorForm.value.txtCountry
        : country;
    this.drpCities = [];
    if (state !== null && state !== undefined) {
      const getAllCities = this.region
        .getAllCitiesByCountryAndStateNameByCountryShortNameAndStateShortName(
          country,
          state
        )
        .subscribe(
          (response) => {
            this.drpCities = response.items;
            this.filteredCities = this.vendorForm
              ?.get('txtCityFilter')
              .valueChanges.pipe(
                startWith(''),
                map((value) =>
                  this.drpCities.filter((option) =>
                    option?.cityName
                      ?.toLowerCase()
                      ?.includes(value?.toLowerCase() ?? '')
                  )
                )
              );
          },
          (err) => {
            const data: HttpErrorResponse = err;
            Swal.fire({
              icon: 'info',
              text: data?.error?.error?.message,
            });
          }
        );
      this.subscription$.push(getAllCities);
    }

    return this.drpCities !== [] && this.drpCities.length !== 0;
  }

  clearCountry() {
    this.Country = '';
    this.phoneCode = '';
    this.vendorForm.patchValue({ drpBillingCountry: '' });
    this.vendorForm.controls['txtPersonPhoneNumber'].disable();
  }
  clearState() {
    this.State = '';
    this.vendorForm.patchValue({ txtState: '' });
  }
  clearCity() {
    this.City = '';
    this.vendorForm.patchValue({ txtCity: '' });
  }

  //! get vendor Instance
  getVendorForm(): FormGroup {
    return this.vendorForm;
  }
  //! get vendor Instance
  getVendorSForm(): FormGroup {
    return this.vSearchForm;
  }
  //! get vendor Table
  getVendorTable() {
    this.isShowSpinner = true;
    this.tableData = [];
    const vendorGetList = this.vendorService
      .getList(new PagedAndSortedResultRequestDto())
      .subscribe(
        (response) => {
          this.tableData = response?.items ?? [];
          this.isShowSpinner = false;
          this.dataSource = new MatTableDataSource(this.tableData);
          this.dataSource.sort = this.sort;
          this.dataSource.paginator = this.paginator;
        },
        (error) => {
          const data: HttpErrorResponse = error;
          this.isShowSpinner = false;
          // Swal.fire({
          //   icon: 'error',
          //   text: data?.error?.error?.message,
          // });
          this.dataSource = new MatTableDataSource(this.tableData);
          this.dataSource.sort = this.sort;
          this.dataSource.paginator = this.paginator;
        }
      );
    this.subscription$.push(vendorGetList);
  }
  //! View Vendor By ID
  ViewVendor(value: string) {
    if (value) {
      const vendorGet = this.vendorService.get(value).subscribe((resposne) => {
        this.stateName = resposne?.state;
        this.CountryName = resposne?.country;
        this.CountryName != null &&
          this.CountryName !== '' &&
          this.CountryName != undefined &&
          this.onChangeState(this.CountryName) &&
          this.vendorForm.patchValue({
            drpCountry: this.CountryName,
          });
        this.stateName != null &&
          this.stateName !== '' &&
          this.stateName != undefined &&
          this.onChangeCity(this.stateName, this.CountryName) &&
          this.vendorForm.patchValue({
            drpState: this.stateName,
            drpCity: this.City,
          });

        this.getVendorForm().patchValue({
          txtVendorName: resposne?.vendorName ?? '',
          txtVendorCode: resposne?.vendorCode ?? '',
          txtVendorAddress: resposne?.vendorAddress ?? '',
          txtVendorPhoneNumber: resposne?.vendorPhoneNumber ?? '',
          txtVendorEmailId: resposne?.vendorEmailId ?? '',
          txtPersonName: resposne?.contactPersonName ?? '',
          txtStatus: resposne?.status ?? '',
          txtQty: resposne?.productQuantity ?? '',
          txtPersonPhoneNumber: resposne?.contactNo ?? '',
          txtState: resposne?.state ?? '',
          txtCity: resposne?.city ?? '',
          txtCountry: resposne?.country ?? '',
        });
        this.vendorId = resposne?.id ?? '';
        this.step = true;
      });
      this.subscription$.push(vendorGet);
    }
  }
  //! Delete Vendor By ID
  deleteVendor(value: string) {
    Swal.fire({
      title: 'Are you sure you want to Delete?',
      text: "You won't be able to retrieve this data!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#34c38f',
      cancelButtonColor: '#f46a6a',
      confirmButtonText: 'Yes, delete it!',
    }).then((result) => {
      if (result.value) {
        if (value) {
          const vendorDelete = this.vendorService.delete(value).subscribe(
            (resposne) => {
              this.resetVendor();
              this.getVendorTable();
            },
            (err) => {
              const data: HttpErrorResponse = err;
              Swal.fire({
                icon: 'info',
                text: data?.error?.error?.message,
              });
            }
          );
          this.subscription$.push(vendorDelete);
        }
      }
    });
  }
  //! get By Search vendor  Table
  getSearchedVendorTable() {
    this.isShowSpinner = true;
    this.tableData = [];

    const input: VendorSearchDTO = {
      vendorCode: this.getVendorSForm().value?.vcode,
      vendorName: this.getVendorSForm().value?.vName,
      contactPersonName: this.getVendorSForm().value?.vPname,
      contactNo: this.getVendorSForm().value?.vPersonNo,
      productQuantity: this.getVendorSForm().value?.vcQty,
      createdDate: this.getVendorSForm().value?.vCreatedOn,
      status: this.getVendorSForm().value?.vStatus,
    };

    const vendorSearch = this.vendorService
      .searchVendorByInput(input)
      .subscribe(
        (response) => {
          this.tableData = response?.items ?? [];
          this.isShowSpinner = false;
          this.dataSource = new MatTableDataSource(this.tableData);
          this.dataSource.sort = this.sort;
          this.dataSource.paginator = this.paginator;
        },
        (error) => {
          const data: HttpErrorResponse = error;
          this.isShowSpinner = false;
          Swal.fire({
            icon: 'info',
            text: data?.error?.error?.message,
          });
          this.dataSource = new MatTableDataSource(this.tableData);
          this.dataSource.sort = this.sort;
          this.dataSource.paginator = this.paginator;
        }
      );
    this.subscription$.push(vendorSearch);
  }
  //! Save Or Update vendor
  saveVendor() {
    this.saveButtonHide = true;
    let input: CreateUpdateVendorDTO = {
      contactPersonName: this.getVendorForm()?.value?.txtPersonName ?? '',
      contactNo: this.getVendorForm()?.value?.txtPersonPhoneNumber ?? '',
      // status: this.getVendorForm()?.value?.txtStatus,
      status: Status.Active,
      country: this.getVendorForm()?.value?.txtCountry ?? '',
      state: this.getVendorForm()?.value?.txtState ?? '',
      city: this.getVendorForm()?.value?.txtCity ?? '',
      productQuantity: 0,
      vendorName: this.getVendorForm()?.value?.txtVendorName ?? '',
      vendorCode: this.getVendorForm()?.value?.txtVendorCode ?? '',
      vendorAddress: this.getVendorForm()?.value?.txtVendorAddress ?? '',
      vendorPhoneNumber:
        this.getVendorForm()?.value?.txtVendorPhoneNumber ?? '',
      vendorEmailId: this.getVendorForm()?.value?.txtVendorEmailId.toLowerCase() ?? '',
    };
    this.vendorId = this.vendorId ?? '';
    if (this.vendorId === '') {
      this.vendorService.create(input).subscribe(
        (response) => {
          this.vendorId = '';
          // Swal.fire({ title: 'Success', html: 'Saved Successfully', icon: 'success', timer: 3000, timerProgressBar: true });
          this.toastr.success('Saved Successfully', 'Success');
          this.saveButtonHide = false;
          this.resetVendor();
          this.getVendorTable();
        },
        (err) => {
          this.saveButtonHide = false;
          const data: HttpErrorResponse = err;
          Swal.fire({
            icon: 'info',
            text: data?.error?.error?.message,
          });
        }
      );
    } else {
      this.vendorService.update(this.vendorId, input).subscribe(
        (response) => {
          this.vendorId = '';
          // Swal.fire({ title: 'Success', html: 'Updated Successfully', icon: 'success', timer: 3000, timerProgressBar: true });
          this.toastr.success('Updated Successfully', 'Success');
          this.resetVendor();
          this.getVendorTable();
          this.saveButtonHide = false;
        },
        (err) => {
          this.saveButtonHide = false;
          const data: HttpErrorResponse = err;
          Swal.fire({
            icon: 'info',
            text: data?.error?.error?.message,
          });
        }
      );
    }
  }
  //Product Category Status change
  statusChange(event: MatSlideToggleChange, data: any) {
    if (event.checked == true) {
      Swal.fire({
        title: 'Do you want to activate?',
        text: 'By Changing Status, this Vendor will be activated',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#34c38f',
        cancelButtonColor: '#f46a6a',
        confirmButtonText: 'Yes, Activate it!',
      }).then((result) => {
        if (result.value) {
          let productCategoryDetails = {
            ...(data ?? null),
            status: Status.Active,
          };
          if (
            data?.id &&
            data?.id !== '' &&
            data?.id !== null &&
            data?.id !== undefined
          ) {
            this.vendorService
              .update(data?.id, productCategoryDetails)
              .subscribe(
                (response) => {
                  // Swal.fire({ title: 'Success', html: 'Activated Successfully', icon: 'success', timer: 3000, timerProgressBar: true })
                  this.toastr.success('Activated Successfully', 'Success');
                  this.getVendorTable();
                },
                (err) => {
                  const data: HttpErrorResponse = err;
                  Swal.fire({
                    icon: 'info',
                    text: data?.error?.error?.message,
                  });
                }
              );
          }
        } else {
          this.getVendorTable();
        }
      });
    } else if (event.checked == false) {
      Swal.fire({
        title: 'Do you want to deactivate?',
        text: 'By Changing Status, this Vendor will be deactivated',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#34c38f',
        cancelButtonColor: '#f46a6a',
        confirmButtonText: 'Yes, deactivate it!',
      }).then((result) => {
        if (result.value) {
          let productCategoryDetails = {
            ...(data ?? null),
            status: Status.Inactive,
          };
          if (
            data?.id &&
            data?.id !== '' &&
            data?.id !== null &&
            data?.id !== undefined
          ) {
            this.vendorService
              .update(data?.id, productCategoryDetails)
              .subscribe(
                (response) => {
                  // Swal.fire({ title: 'Success', html: 'Deactivated Successfully', icon: 'success', timer: 3000, timerProgressBar: true })
                  this.toastr.success('Deactivated Successfully', 'Success');
                  this.getVendorTable();
                },
                (err) => {
                  const data: HttpErrorResponse = err;
                  Swal.fire({
                    icon: 'info',
                    text: data?.error?.error?.message,
                  });
                }
              );
          }
        } else {
          this.getVendorTable();
        }
      });
    }
  }
  //! Reset vendor Form
  resetVendor() {
    this.getVendorForm().patchValue({
      txtVendorName: '',
      txtVendorCode: '',
      txtVendorAddress: '',
      txtVendorPhoneNumber: '',
      txtVendorEmailId: '',
    });
    this.vendorId = '';
    this.getVendorForm()?.markAsUntouched();
    this.getVendorForm()?.updateValueAndValidity();
    this.getVendorForm()?.reset();
  }

  //export excel
  exportTableData() {
    let datas: ItemExportDatas[] = [];
    this.dataSource?.data?.forEach((element) => {
      datas.push({
        vendorCode: element?.vendorCode,
        vendorName: element?.vendorName,
        vendorCreatedDate: element?.creationTime,
        vendorStatus: element?.status,
      });
    });
    let itemExport: ItemExportDTO = {
      columns: [
        'Vendor Code',
        'Vendor Name',
        'Vendor Created Date',
        'VendorStatus',
      ],
      datas: datas,
    };
    const exportReport = this.reportService
      .exportItemReportsByExportTypeAndExportDatas(
        ExportType.VendorReports,
        itemExport
      )
      .subscribe(
        (response) => {
          if (response && response?.length !== 0) {
            let filelocation: String = String(
              'data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,' +
                response
            );
            let link = document.createElement('a');
            link.download = 'Vendor' + '.xlsx';
            link.href = filelocation.toString();
            link.click();
            // Swal.fire({ title: 'Success', html: 'Exported Successfully', icon: 'success', timer: 3000, timerProgressBar: true })
            this.toastr.success('Exported Successfully', 'Success');
          }
        },
        (err) => {
          const data: HttpErrorResponse = err;
          let errorMesg: string = data?.error;
          if (
            errorMesg?.includes('Sorry!! There is no data in table to export')
          ) {
            errorMesg = 'Sorry!! There is no data in table to export';
          } else {
            errorMesg = data?.error?.error?.message;
          }
          // Swal.fire({
          //   icon: 'info',
          //   text: errorMesg
          // });
          this.toastr.info(errorMesg);
        }
      );
    this.subscription$.push(exportReport);
  }
}
