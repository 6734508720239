<!--Mat Card-->
<mat-card class="mr-3 mt-4">
  <mat-card-title class="customThemeClass"><b>Document View</b></mat-card-title>
  <mat-divider class="bg mb-5"> </mat-divider>
  <mat-card-content>
    <div class="row mt-3">
      <div class="col-lg-12">
        <mat-card-content>
          <form [formGroup]="medicareUpdateForm">
            <div class="row pt-2">
              <mat-form-field class="col-lg-3">
                <mat-label> WIP List <span class="asterisk">*</span> </mat-label>
                <mat-select maxlength="40" formControlName="drpWIPList" [(ngModel)]="wipSelectedValue">
                  <mat-option>
                    <ngx-mat-select-search placeholderLabel="Search WIP List" [formControl]="wipFilterControl"
                      noEntriesFoundLabel="No Matches found"></ngx-mat-select-search>
                  </mat-option>
                  <mat-option *ngFor="let wip of drpWIPList" [value]="wip?.id">
                    <!-- filteredWIPList -->
                    {{ wip?.wipId }}
                  </mat-option>
                </mat-select>
                <mat-error class="spanAlignment" *ngIf="
                  medicareUpdateForm?.get('drpWIPList')?.touched &&
                  medicareUpdateForm?.get('drpWIPList')?.errors?.required
                  "> WIP is a required field! </mat-error>
              </mat-form-field>

              <mat-checkbox class="col-lg-2 pt-3" formControlName="chkIsRulesNeeded">Is Rules Needed</mat-checkbox>

              <mat-form-field class="col-lg-3">
                <mat-label> DX Type <span class="asterisk">*</span> </mat-label>
                <mat-select maxlength="40" formControlName="drpDXType">
                  <mat-option>
                    <ngx-mat-select-search placeholderLabel="Search DX Type" [formControl]="dxTypeFilterControl"
                      noEntriesFoundLabel="No Matches found"></ngx-mat-select-search>
                  </mat-option>
                  <mat-option *ngFor="let dx of filteredDXTypeList" [value]="dx.dxType"> {{ dx.dxType }} </mat-option>
                </mat-select>
                <!-- <button mat-button matSuffix (click)="clearDXType()" mat-icon-button>
									<mat-icon>close</mat-icon>
								</button> -->
                <mat-error class="spanAlignment" *ngIf="
                  medicareUpdateForm?.get('drpDXType')?.touched &&
                  medicareUpdateForm?.get('drpDXType')?.errors?.required
                  "> DX Type is a required field! </mat-error>
              </mat-form-field>

              <mat-form-field class="col-lg-3"
                *ngIf="medicareUpdateForm?.get('drpDXType')?.value !== 'CPAP to BIPAP' && medicareUpdateForm?.get('drpDXType')?.value !== 'CompSA' ">
                <mat-label> Product Setup Type <span class="asterisk">*</span> </mat-label>
                <mat-select maxlength="40" formControlName="drpPrdSetupType">
                  <mat-option>
                    <!-- <ngx-mat-select-search placeholderLabel="Search Product Setup Type" noEntriesFoundLabel="No Matches found" formControlName="txtDXTypeFilter"></ngx-mat-select-search> -->
                    <ngx-mat-select-search placeholderLabel="Search Product Setup Type"
                      [formControl]="PrdSetupTypeFilterControl"
                      noEntriesFoundLabel="No Matches found"></ngx-mat-select-search>
                  </mat-option>
                  <mat-option *ngFor="let setup of filteredPrdSetupTypeList" [value]="setup?.setupType"> {{
                    setup?.setupType }} </mat-option>
                </mat-select>
                <button mat-button matSuffix (click)="clearPrdSetupType()" mat-icon-button>
                  <mat-icon>close</mat-icon>
                </button>
                <mat-error class="spanAlignment"
                  *ngIf="medicareUpdateForm?.get('drpPrdSetupType')?.touched && medicareUpdateForm?.get('drpPrdSetupType')?.errors?.required">
                  Product Setup Type is a required field! </mat-error>
              </mat-form-field>
            </div>

            <div class="row pt-2">

              <mat-form-field class="col-lg-3">
                <mat-label> Pre Questionaire <span class="asterisk">*</span> </mat-label>
                <mat-select maxlength="40" (selectionChange)="onPreQuestionaireChange()"
                  formControlName="drpPreQuestionaireList">
                  <mat-option>
                    <ngx-mat-select-search placeholderLabel="Search Pre Questionaire List"
                      noEntriesFoundLabel="No Matches found" formControlName="txtWIPFilter"></ngx-mat-select-search>
                  </mat-option>
                  <mat-option *ngFor="let list of preQuestionaireList" [value]="list?.Title">
                    {{ list?.Title }}
                  </mat-option>
                </mat-select>
                <mat-error class="spanAlignment" *ngIf="
                  medicareUpdateForm?.get('drpPreQuestionaireList')?.touched &&
                  medicareUpdateForm?.get('drpPreQuestionaireList')?.errors?.required
                  "> Pre Questionaire is a required field! </mat-error>
              </mat-form-field>

              <div class="col-lg-9" *ngIf="selectedTitle">
                <div *ngFor="let list of preQuestionaireList">
                  <div *ngIf="list.Title === selectedTitle">
                    <mat-label class="title-label"> {{ list?.Question }} <span class="asterisk">*</span> </mat-label>
                    <div class="checkbox-options">
                      <mat-checkbox class="matcheckbox" *ngFor="let option of list.Options" [value]="option">
                        {{ option }}
                      </mat-checkbox>
                    </div>
                    <mat-label class="notes-label"> <span class="asterisk"> {{ list?.Notes }}</span> </mat-label>
                  </div>
                </div>
              </div>

            </div>
          </form>
        </mat-card-content>
        <mat-divider></mat-divider>

        <div class="row pt-2">
          <!--Side Bar-->
          <div class="col-xl-5 tree-container" style="height: 1000px">
            <div class="card card-body ml-2">
              <div class="text-center">
                <span style="font-size: larger"><b class="colored-heading">Document Types</b></span>
                <button mat-icon-button (click)="addDocumentType()">
                  <!-- <mat-icon aria-label="Add document type">add</mat-icon> -->
                  <mat-icon aria-label="Add document type">add_circle_outline</mat-icon>
                </button>
              </div>
              <div layout-align="center center">

                <mat-accordion>
                  <form [formGroup]="PreviewExtractionForm">


                    <mat-expansion-panel>
                      <mat-expansion-panel-header class="custom-acccordion-header">
                        <mat-panel-title>
                          <div class="custom-qsecure-color font-weight-bolder">
                            ALL&nbsp;
                            <span class="circular-badge">
                              {{ childTree['all'] ? childTree['all'].length : 0 }}
                            </span>
                          </div>

                        </mat-panel-title>
                      </mat-expansion-panel-header>
                      <mat-card-content>
                        <div class="form-row">
                          <mat-tree [dataSource]="childTree['all']" [treeControl]="treeControl">
                            <mat-tree-node class="childNodeClass text-justify" *matTreeNodeDef="let ex"
                              matTreeNodePadding val="ex" (click)="activeNode = ex"
                              [ngClass]="{ 'background-highlight': activeNode === ex }" (click)="getPdf(ex.blobName)">
                              <div class="custom-doc-info">
                                <i class="fa fa-file-pdf-o"></i>
                                <div class="custom-doc-name">&nbsp;{{ ex?.name?.length > 10 ?
                                  ex?.name.substring(0,10)+'...'+ex?.name.substring(ex?.name.lastIndexOf('.')) :
                                  ex?.name || 'Untitled-Name' }}&nbsp;</div>
                                <small>&nbsp;{{ ex?.uploadedDate }}&nbsp;</small>
                                <div class="pl-3 mr-3">
                                  <a class="editBtnColor">
                                    <i class="fa fa-pencil mr-3 pointer customThemeClass" matTooltip="Edit" (click)="
                                        viewPdfFile(ex.blobName, ex.id)
                                      "></i>
                                  </a>
                                  <a class="editBtnColor">
                                    <i class="fa fa-cloud-download mr-3 pointer customThemeClass" matTooltip="Download"
                                      (click)="
                                        downloadFile(
                                          ex.blobName,
                                          ex.fileName
                                        )
                                      "></i>
                                  </a>
                                  <a class="editBtnColor">
                                    <i class="fa fa-trash pointer asterisk" matTooltip="Delete"
                                      (click)="deleteFileById(ex.id)"></i>
                                  </a>
                                </div>
                              </div>
                            </mat-tree-node>
                          </mat-tree>

                        </div>


                      </mat-card-content>
                    </mat-expansion-panel>
                    <mat-expansion-panel>
                      <mat-expansion-panel-header class="custom-acccordion-header">
                        <mat-panel-title>
                          <div class="custom-qsecure-color font-weight-bolder">
                            INITIAL FACE TO FACE&nbsp;
                            <span class="circular-badge">
                              {{ childTree['facetoface'] ? childTree['facetoface'].length : 0 }}
                            </span>
                          </div>
                        </mat-panel-title>
                      </mat-expansion-panel-header>
                      <mat-card-content>
                        <div class="form-row">

                          <mat-form-field class="col-12">
                            <mat-label> Patient Name </mat-label>
                            <input autocomplete="new-txtFobPatientName" matInput maxlength="40"
                              formControlName="txtFobPatientName" type="text" />
                            <mat-error *ngIf="
                              PreviewExtractionForm?.get(
                                'txtFobPatientName'
                              )?.touched &&
                              PreviewExtractionForm?.get(
                                'txtFobPatientName'
                              )?.errors?.sAlphabets
                            ">
                              Enter only Alphabets!
                            </mat-error>
                          </mat-form-field>

                          <mat-form-field class="col-12">
                            <mat-label> Date of Birth </mat-label>
                            <input autocomplete="new-txtIobDob" formControlName="txtIobDob" matInput maxlength="40"
                              type="text" placeholder="(MM/DD/YYYY)" [matDatepicker]="txtIobDob" />
                            <mat-datepicker-toggle matSuffix [for]="txtIobDob">
                            </mat-datepicker-toggle>
                            <mat-datepicker #txtIobDob> </mat-datepicker>
                            <mat-error *ngIf="
                              PreviewExtractionForm?.get('txtIobDob')
                                ?.touched &&
                              PreviewExtractionForm?.get('txtIobDob')
                                ?.errors
                            ">
                              Enter Valid date
                            </mat-error>
                          </mat-form-field>

                          <mat-form-field class="col-12">
                            <mat-label [ngClass]="{
                              warning:
                                ruleColor?.erStatus13 ||
                                ruleColor?.erStatus15 ||
                                ruleColor?.erStatus29 ||
                                ruleColor?.erStatus40 ||
                                ruleColor?.erStatus43
                            }">
                              Face To Face Date
                            </mat-label>
                            <input autocomplete="new-txtIobFadeToFace" formControlName="txtIobFadeToFace" matInput
                              matTooltip="{{
                              ruleColor?.errValue13 ||
                                ruleColor?.errValue15 ||
                                ruleColor?.errValue29 ||
                                ruleColor?.errValue40 ||
                                ruleColor?.errValue43
                            }}" maxlength="40" type="text" placeholder="(MM/DD/YYYY)"
                              [matDatepicker]="txtIobFadeToFace" />
                            <mat-datepicker-toggle matSuffix [for]="txtIobFadeToFace">
                            </mat-datepicker-toggle>
                            <mat-datepicker #txtIobFadeToFace>
                            </mat-datepicker>
                            <mat-error *ngIf="
                              PreviewExtractionForm?.get('txtIobFadeToFace')
                                ?.touched &&
                              PreviewExtractionForm?.get('txtIobFadeToFace')
                                ?.errors
                            ">
                              Enter Valid date
                            </mat-error>
                          </mat-form-field>

                          <div class="col-md-12 text-right">
                            <button type="button" class="buttonColor btn-sm btn-circle" (click)="openDoctorModal()">
                              <i class="fa fa-plus" matTooltip="Click to Add Doctor"></i>
                            </button>
                          </div>

                          <mat-form-field class="col-12">
                            <mat-label>Doctor Name</mat-label>
                            <mat-select formControlName="txtDoctor" (valueChange)="
                              doctorValueChange('f2f', singleSelect)
                            " [(value)]="OrderingDoctor" #singleSelect>
                              <mat-option *ngFor="let doctor of drpDoctor" [value]="doctor.name">
                                {{ doctor.name }}
                              </mat-option>
                            </mat-select>
                          </mat-form-field>

                          <mat-form-field class="col-12">
                            <mat-label>NPI Number</mat-label>
                            <input autocomplete="new-txtNpiNumber" maxlength="13" mask="000-000-0000"
                              formControlName="f2ftxtNpiNumber" matInput type="text" />
                            <mat-error *ngIf="
                              PreviewExtractionForm?.get('f2ftxtNpiNumber')
                                ?.touched &&
                              PreviewExtractionForm?.get('f2ftxtNpiNumber')
                                ?.errors?.number
                            ">
                              Enter only Numbers!
                            </mat-error>
                          </mat-form-field>

                          <mat-form-field class="col-12">
                            <mat-label> Signed date </mat-label>
                            <input autocomplete="new-txtSignedDate" formControlName="txtSignedDate" matInput
                              maxlength="40" type="text" placeholder="(MM/DD/YYYY)" [matDatepicker]="txtSignedDate1" />
                            <mat-datepicker-toggle matSuffix [for]="txtSignedDate1">
                            </mat-datepicker-toggle>
                            <mat-datepicker #txtSignedDate1> </mat-datepicker>
                            <mat-error *ngIf="
                              PreviewExtractionForm?.get('txtSignedDate')
                                ?.touched &&
                              PreviewExtractionForm?.get('txtSignedDate')
                                ?.errors
                            ">
                              Enter Valid date
                            </mat-error>
                          </mat-form-field>

                          <div class="col-12">
                            <br />
                            <mat-checkbox formControlName="chkPrevioushistory">Previous history – declaration
                            </mat-checkbox>
                          </div>

                          <div class="col-12">
                            <br />
                            <mat-checkbox aria-labelledby="Sleep Test Recommended" [ngClass]="{
                              warning:
                                ruleColor?.erStatus2 ||
                                ruleColor?.erStatus21
                            }" matTooltip="{{
                              ruleColor?.errValue2 || ruleColor?.errValue21
                            }}" formControlName="chkSleepTestRecommended">Sleep Test Recommended
                            </mat-checkbox>
                          </div>

                          <div class="col-12">
                            <br />
                            <mat-checkbox [ngClass]="{
                              warning:
                                ruleColor?.erStatus3 ||
                                ruleColor?.erStatus17 ||
                                ruleColor?.erStatus22 ||
                                ruleColor?.erStatus32
                            }" matTooltip="{{
                              ruleColor?.errValue3 ||
                                ruleColor?.errValue17 ||
                                ruleColor?.errValue22 ||
                                ruleColor?.errValue32
                            }}" formControlName="chkSleepDoctorSigned">Doctor Signed
                            </mat-checkbox>
                          </div>

                          <div class="col-12">
                            <br />

                            <mat-checkbox matTooltip="{{
                              ruleColor?.errValue16 || ruleColor?.errValue30
                            }}" [ngClass]="{
                              warning:
                                ruleColor?.erStatus16 ||
                                ruleColor?.erStatus30
                            }" formControlName="chkSleepPatientBenifited">
                              Patient Benifits and Usage
                            </mat-checkbox>
                          </div>

                          <mat-form-field class="col-12">
                            <mat-label [ngClass]="{
                              warning:
                                ruleColor?.erStatus1 ||
                                ruleColor?.erStatus20
                            }">Symptoms
                            </mat-label>
                            <mat-select multiple matTooltip="{{
                              ruleColor?.errValue1 || ruleColor?.errValue20
                            }}" formControlName="drpSymptoms">
                              <mat-option *ngFor="let sysm of drpSymptoms" [value]="sysm.symptom">
                                {{ sysm.symptom }}
                              </mat-option>
                            </mat-select>
                            <mat-error *ngIf="
                              PreviewExtractionForm?.get('drpSymptoms')
                                ?.touched &&
                              PreviewExtractionForm?.get('drpSymptoms')
                                ?.errors?.required
                            ">
                              Symptoms is a required field!
                            </mat-error>
                          </mat-form-field>

                          <mat-tree [dataSource]="childTree['facetoface']" [treeControl]="treeControl">
                            <mat-tree-node class="childNodeClass text-justify" *matTreeNodeDef="let ex"
                              matTreeNodePadding val="ex" (click)="activeNode = ex"
                              [ngClass]="{ 'background-highlight': activeNode === ex }" (click)="getPdf(ex.blobName)">
                              <div class="custom-doc-info">
                                <i class="fa fa-file-pdf-o"></i>
                                <div class="custom-doc-name">&nbsp;{{ ex?.name?.length > 10 ?
                                  ex?.name.substring(0,10)+'...'+ex?.name.substring(ex?.name.lastIndexOf('.')) :
                                  ex?.name || 'Untitled-Name' }}&nbsp;</div>
                                <small>&nbsp;{{ ex?.uploadedDate }}&nbsp;</small>
                                <div class="pl-3 mr-3">
                                  <a class="editBtnColor">
                                    <i class="fa fa-pencil mr-3 pointer customThemeClass" matTooltip="Edit" (click)="
                                      viewPdfFile(ex.blobName, ex.id)
                                    "></i>
                                  </a>
                                  <a class="editBtnColor">
                                    <i class="fa fa-cloud-download mr-3 pointer customThemeClass" matTooltip="Download"
                                      (click)="
                                      downloadFile(
                                        ex.blobName,
                                        ex.fileName
                                      )
                                    "></i>
                                  </a>
                                  <a class="editBtnColor">
                                    <i class="fa fa-trash pointer asterisk" matTooltip="Delete"
                                      (click)="deleteFileById(ex.id)"></i>
                                  </a>
                                </div>
                              </div>
                            </mat-tree-node>
                          </mat-tree>


                        </div>

                      </mat-card-content>

                    </mat-expansion-panel>

                    <mat-expansion-panel>
                      <mat-expansion-panel-header class="custom-acccordion-header">
                        <mat-panel-title>
                          <div class="custom-qsecure-color font-weight-bolder">
                            SLEEP STUDY REPORT&nbsp;
                            <span class="circular-badge">{{ childTree['sleepstudyreport'] ?
                              childTree['sleepstudyreport'].length : 0 }}</span>
                          </div>
                        </mat-panel-title>
                      </mat-expansion-panel-header>
                      <mat-card-content>
                        <div class="form-row">
                          <mat-form-field class="col-12">
                            <mat-label> Patient Name </mat-label>
                            <input autocomplete="new-txtPatientName1" matInput maxlength="40"
                              formControlName="txtPatientName1" type="text" />
                            <mat-error *ngIf="
                              PreviewExtractionForm?.get('txtPatientName1')
                                ?.touched &&
                              PreviewExtractionForm?.get('txtPatientName1')
                                ?.errors?.sAlphabets
                            ">
                              Enter only Alphabets!
                            </mat-error>
                          </mat-form-field>

                          <mat-form-field class="col-12">
                            <mat-label> Birth Date </mat-label>
                            <input autocomplete="new-txtDob" formControlName="txtDob" matInput maxlength="40"
                              type="text" placeholder="(MM/DD/YYYY)" [matDatepicker]="txtDob" />
                            <mat-datepicker-toggle matSuffix [for]="txtDob">
                            </mat-datepicker-toggle>
                            <mat-datepicker #txtDob> </mat-datepicker>
                            <mat-error *ngIf="
                              PreviewExtractionForm?.get('txtDob')
                                ?.touched &&
                              PreviewExtractionForm?.get('txtDob')?.errors
                            ">
                              Enter Valid date
                            </mat-error>
                          </mat-form-field>

                          <mat-form-field class="col-6">
                            <mat-label> Study date </mat-label>
                            <input autocomplete="new-txtStudyDate" formControlName="txtStudyDate" matInput
                              maxlength="40" type="text" placeholder="(MM/DD/YYYY)" [matDatepicker]="txtStudyDate" />
                            <mat-datepicker-toggle matSuffix [for]="txtStudyDate">
                            </mat-datepicker-toggle>
                            <mat-datepicker #txtStudyDate> </mat-datepicker>
                            <mat-error *ngIf="
                              PreviewExtractionForm?.get('txtStudyDate')
                                ?.touched &&
                              PreviewExtractionForm?.get('txtStudyDate')
                                ?.errors
                            ">
                              Enter Valid date
                            </mat-error>
                          </mat-form-field>

                          <mat-form-field class="col-6">
                            <mat-label>Pressure Setting </mat-label>
                            <input autocomplete="new-txtPressureSetting" matInput formControlName="txtPressureSetting"
                              pattern="^\d{0,10}" (keypress)="numberOnly($event)" maxlength="40" type="text" />
                          </mat-form-field>

                          <mat-form-field class="col-12">
                            <mat-label>REI</mat-label>
                            <input autocomplete="new-txtREI" maxlength="5" formControlName="txtREI"
                              (keypress)="keyPressNumbersDecimal($event)" matInput type="text" />
                          </mat-form-field>

                          <mat-form-field class="col-12">
                            <mat-label>RDI </mat-label>
                            <input autocomplete="new-txtRDI" maxlength="5" formControlName="txtRDI"
                              (keypress)="keyPressNumbersDecimal($event)" matInput type="text" />
                          </mat-form-field>
                          <div>
                            <p class="col-12">
                              <br />
                              <b> Diagnostic Sleep Study </b>
                            </p>
                            <mat-form-field class="col-6">
                              <mat-label [ngClass]="{ warning: ruleColor?.erStatus5 }">Total Sleep Minutes</mat-label>
                              <input autocomplete="new-txtSleepMinutes" matInput formControlName="txtSleepMinutes"
                                matTooltip="{{ ruleColor?.errValue5 }}" pattern="^\d{0,10}"
                                (keypress)="numberOnly($event)" maxlength="5" type="text" />
                            </mat-form-field>
                            <mat-form-field class="col-6">
                              <mat-label [ngClass]="{
                                warning:
                                  ruleColor?.erStatus6 ||
                                  ruleColor?.erStatus24 ||
                                  ruleColor?.erStatus32
                              }">
                                AHI</mat-label>
                              <input autocomplete="new-txtAHI" type="text" maxlength="5"
                                (keypress)="keyPressNumbersDecimal($event)" formControlName="txtAHI" matInput
                                matTooltip="{{
                                ruleColor?.errValue6 ||
                                  ruleColor?.errValue24 ||
                                  ruleColor?.errValue32
                              }}" />
                            </mat-form-field>
                            <div class="col-6">
                              <br />
                              <mat-checkbox matTooltip="{{
                                ruleColor?.errValue7 ||
                                  ruleColor?.errValue18 ||
                                  ruleColor?.errValue23 ||
                                  ruleColor?.errValue26 ||
                                  ruleColor?.errValue35
                              }}" [ngClass]="{
                                warning:
                                  ruleColor?.erStatus7 ||
                                  ruleColor?.erStatus18 ||
                                  ruleColor?.erStatus23 ||
                                  ruleColor?.erStatus26 ||
                                  ruleColor?.erStatus35
                              }" formControlName="chkSleepStudyDoctorSigned">Doctor Signed
                              </mat-checkbox>
                            </div>
                            <mat-form-field class="col-6">
                              <mat-label [ngClass]="{ warning: ruleColor?.erStatus27 }">Central Apneas</mat-label>
                              <input autocomplete="new-txtSSCentralApneas" type="text" maxlength="5"
                                matTooltip="{{ ruleColor?.errValue27 }}" (keypress)="keyPressNumbersDecimal($event)"
                                formControlName="txtSSCentralApneas" matInput />
                            </mat-form-field>
                            <mat-form-field class="col-6">
                              <mat-label [ngClass]="{ warning: ruleColor?.erStatus27 }">Central Hyponeas</mat-label>
                              <input autocomplete="new-txtSSCentralHyponeas" type="text" maxlength="5"
                                matTooltip="{{ ruleColor?.errValue27 }}" (keypress)="keyPressNumbersDecimal($event)"
                                formControlName="txtSSCentralHyponeas" matInput />
                            </mat-form-field>

                            <mat-form-field class="col-6">
                              <mat-label [ngClass]="{ warning: ruleColor?.erStatus27 }">Total Apnea And Hypopnea
                              </mat-label>
                              <input autocomplete="new-txtSSApneaAndHypopnea" type="text" maxlength="5"
                                matTooltip="{{ ruleColor?.errValue27 }}" (keypress)="keyPressNumbersDecimal($event)"
                                formControlName="txtSSApneaAndHypopnea" matInput />
                            </mat-form-field>
                            <mat-form-field class="col-6">
                              <mat-label [ngClass]="{
                                warning:
                                  ruleColor?.erStatus4 ||
                                  ruleColor?.erStatus23 ||
                                  ruleColor?.erStatus14
                              }">
                                Diagnosis Sleep Study date
                              </mat-label>
                              <input autocomplete="new-txtDiagnosisStudyDate" formControlName="txtDiagnosisStudyDate"
                                matInput maxlength="40" type="text" matTooltip="{{
                                ruleColor?.errValue4 ||
                                  ruleColor?.errValue23 ||
                                  ruleColor?.errValue14
                              }}" placeholder="(MM/DD/YYYY)" [matDatepicker]="txtDiagnosisStudyDate" />
                              <mat-datepicker-toggle matSuffix [for]="txtDiagnosisStudyDate">
                              </mat-datepicker-toggle>
                              <mat-datepicker #txtDiagnosisStudyDate>
                              </mat-datepicker>
                              <mat-error *ngIf="
                                PreviewExtractionForm?.get(
                                  'txtDiagnosisStudyDate'
                                )?.touched &&
                                PreviewExtractionForm?.get(
                                  'txtDiagnosisStudyDate'
                                )?.errors
                              ">
                                Enter Valid date
                              </mat-error>
                            </mat-form-field>
                          </div>
                          <div>
                            <p class="col-12">
                              <br />
                              <b> Titration Sleep Study </b>
                            </p>
                            <mat-form-field class="col-6">
                              <mat-label [ngClass]="{
                                warning:
                                  ruleColor?.erStatus37 ||
                                  ruleColor?.erStatus45
                              }">Sleep Study Date
                              </mat-label>
                              <input autocomplete="new-txtTitrationSleepStudyDate"
                                formControlName="txtTitrationSleepStudyDate" matInput maxlength="40" type="text"
                                placeholder="(MM/DD/YYYY)" matTooltip="{{
                                ruleColor?.errValue37 ||
                                  ruleColor?.errValue45
                              }}" [matDatepicker]="txtTitrationSleepStudyDate" />
                              <mat-datepicker-toggle matSuffix [for]="txtTitrationSleepStudyDate">
                              </mat-datepicker-toggle>
                              <mat-datepicker #txtTitrationSleepStudyDate>
                              </mat-datepicker>
                              <mat-error *ngIf="
                                PreviewExtractionForm?.get(
                                  'txtTitrationSleepStudyDate'
                                )?.touched &&
                                PreviewExtractionForm?.get(
                                  'txtTitrationSleepStudyDate'
                                )?.errors
                              ">
                                Enter Valid date
                              </mat-error>
                            </mat-form-field>
                            <mat-form-field class="col-6">
                              <mat-label [ngClass]="{
                                warning:
                                  ruleColor?.erStatus6 ||
                                  ruleColor?.erStatus24 ||
                                  ruleColor?.erStatus32
                              }">
                                AHI</mat-label>
                              <input autocomplete="new-txtTAHI" type="text" maxlength="5"
                                (keypress)="keyPressNumbersDecimal($event)" formControlName="txtTAHI" matTooltip="{{
                                ruleColor?.errValue6 ||
                                  ruleColor?.errValue24 ||
                                  ruleColor?.errValue32
                              }}" matInput />
                            </mat-form-field>
                            <mat-form-field class="col-6">
                              <mat-label [ngClass]="{ warning: ruleColor?.erStatus27 }">Central Apneas</mat-label>
                              <input autocomplete="new-txtTCentralApneas" type="text"
                                matTooltip="{{ ruleColor?.errValue27 }}" maxlength="5"
                                (keypress)="keyPressNumbersDecimal($event)" formControlName="txtTCentralApneas"
                                matInput />
                            </mat-form-field>
                            <mat-form-field class="col-6">
                              <mat-label [ngClass]="{ warning: ruleColor?.erStatus27 }">Central Hyponeas</mat-label>
                              <input autocomplete="new-txtTCentralHyponeas" type="text" maxlength="5"
                                matTooltip="{{ ruleColor?.errValue27 }}" (keypress)="keyPressNumbersDecimal($event)"
                                formControlName="txtTCentralHyponeas" matInput />
                            </mat-form-field>
                            <mat-form-field class="col-6">
                              <mat-label [ngClass]="{ warning: ruleColor?.erStatus27 }">Total Apnea And Hypopnea
                              </mat-label>
                              <input autocomplete="new-txtTApneaAndHypopnea" type="text" maxlength="5"
                                matTooltip="{{ ruleColor?.errValue27 }}" (keypress)="keyPressNumbersDecimal($event)"
                                formControlName="txtTApneaAndHypopnea" matInput />
                            </mat-form-field>
                            <div class="row m-0 p-0">
                              <div class="col-12">
                                <br />
                                <mat-checkbox [ngClass]="{
                                  warning:
                                    ruleColor?.erStatus8 ||
                                    ruleColor?.erStatus38
                                }" matTooltip="{{
                                  ruleColor?.errValue8 ||
                                    ruleColor?.errValue38
                                }}" formControlName="chkCPAPTriedAndFail">
                                  CPAP Tried And Fail
                                </mat-checkbox>
                              </div>
                              <div class="col-12">
                                <br />
                                <mat-checkbox [ngClass]="{
                                  warning:
                                    ruleColor?.erStatus10 ||
                                    ruleColor?.erStatus39
                                }" matTooltip="{{
                                  ruleColor?.errValue10 ||
                                    ruleColor?.errValue39
                                }}" formControlName="chkRespiratoryBreakdownAvailable">
                                  Respiratory Breakdown Available
                                </mat-checkbox>
                              </div>
                            </div>
                            <div class="row m-0 p-0">
                              <div class="col-12">
                                <br />
                                <mat-checkbox [ngClass]="{
                                  warning: ruleColor?.erStatus3
                                }" matTooltip="{{ ruleColor?.errValue3 }}"
                                  formControlName="chkTitrationSleepStudyDoctorSigned">
                                  Doctor Signed
                                </mat-checkbox>
                              </div>
                              <div class="col-12">
                                <br />
                                <mat-checkbox formControlName="chkisCPAPReturned">
                                  CPAP Returned
                                </mat-checkbox>
                              </div>
                            </div>
                            <div class="row m-0 p-0">
                              <div class="col-12">
                                <br />
                                <mat-checkbox [ngClass]="{
                                  warning: ruleColor?.erStatus41
                                }" matTooltip="{{ ruleColor?.errValue41 }}" formControlName="chkTSSBaselineFile">
                                  Base line File Available
                                </mat-checkbox>
                              </div>
                              <mat-form-field class="col-12">
                                <mat-label [ngClass]="{
                                  warning: ruleColor?.erStatus49
                                }">Obstructive Apnea
                                </mat-label>
                                <input autocomplete="new-txtObstructiveApnea" type="text" maxlength="5"
                                  matTooltip="{{ ruleColor?.errValue49 }}" (keypress)="keyPressNumbersDecimal($event)"
                                  formControlName="txtObstructiveApnea" matInput />
                              </mat-form-field>
                            </div>
                            <div class="col-md-12 text-right">
                              <button type="button" class="buttonColor btn-sm btn-circle" (click)="openIcd10Modal()">
                                <i class="fa fa-plus" matTooltip="Click to Add DiagnosisCodes-10"></i>
                              </button>
                            </div>
                            <mat-form-field class="col-12">
                              <mat-label>Diagnosis Code-ICD-10</mat-label>
                              <mat-select multiple formControlName="txtdiagnosisCode10Id">
                                <mat-option *ngFor="let diagnosis of drpDiagnosisICD10" [value]="
                                  diagnosis.diagnosisCode10ShortCodeId
                                ">
                                  {{ diagnosis.diagnosisCodeTypeData }}
                                </mat-option>
                              </mat-select>
                            </mat-form-field>
                          </div>
                          <mat-tree [dataSource]="childTree['sleepstudyreport']" [treeControl]="treeControl">
                            <mat-tree-node class="childNodeClass text-justify" *matTreeNodeDef="let ex"
                              matTreeNodePadding val="ex" (click)="activeNode = ex"
                              [ngClass]="{ 'background-highlight': activeNode === ex }" (click)="getPdf(ex.blobName)">
                              <div class="custom-doc-info">
                                <i class="fa fa-file-pdf-o"></i>
                                <div class="custom-doc-name">&nbsp;{{ ex?.name?.length > 10 ?
                                  ex?.name.substring(0,10)+'...'+ex?.name.substring(ex?.name.lastIndexOf('.')) :
                                  ex?.name || 'Untitled-Name' }}&nbsp;</div>
                                <small>&nbsp;{{ ex?.uploadedDate }}&nbsp;</small>
                                <div class="pl-3 mr-3">
                                  <a class="editBtnColor">
                                    <i class="fa fa-pencil mr-3 pointer customThemeClass" matTooltip="Edit" (click)="
                                      viewPdfFile(ex.blobName, ex.id)
                                    "></i>
                                  </a>
                                  <a class="editBtnColor">
                                    <i class="fa fa-cloud-download mr-3 pointer customThemeClass" matTooltip="Download"
                                      (click)="
                                      downloadFile(
                                        ex.blobName,
                                        ex.fileName
                                      )
                                    "></i>
                                  </a>
                                  <a class="editBtnColor">
                                    <i class="fa fa-trash pointer asterisk" matTooltip="Delete"
                                      (click)="deleteFileById(ex.id)"></i>
                                  </a>
                                </div>
                              </div>
                            </mat-tree-node>
                          </mat-tree>
                        </div>
                      </mat-card-content>

                    </mat-expansion-panel>

                    <mat-expansion-panel>
                      <mat-expansion-panel-header class="custom-acccordion-header">
                        <mat-panel-title>
                          <div class="custom-qsecure-color font-weight-bolder">
                            RX&nbsp;
                            <span class="circular-badge">
                              {{ childTree['rx'] ? childTree['rx'].length : 0 }}</span>
                          </div>
                        </mat-panel-title>
                      </mat-expansion-panel-header>
                      <mat-card-content>
                        <div class="form-row">
                          <mat-form-field class="col-12">
                            <mat-label> Patient Name </mat-label>
                            <input autocomplete="new-txtPatientName2" matInput maxlength="40"
                              formControlName="txtPatientName2" type="text" />
                            <mat-error *ngIf="
                              PreviewExtractionForm?.get('txtPatientName2')
                                ?.touched &&
                              PreviewExtractionForm?.get('txtPatientName2')
                                ?.errors?.sAlphabets
                            ">
                              Enter only Alphabets!
                            </mat-error>
                          </mat-form-field>

                          <mat-form-field class="col-12">
                            <mat-label> Birth Date </mat-label>
                            <input autocomplete="new-txtDOB2" [max]="txtDOB2" formControlName="txtDOB2" matInput
                              maxlength="40" type="text" placeholder="(MM/DD/YYYY)" [matDatepicker]="txtDOB2"
                              [min]="txtDOB2" />
                            <mat-datepicker-toggle matSuffix [for]="txtDOB2">
                            </mat-datepicker-toggle>
                            <mat-datepicker #txtDOB2> </mat-datepicker>
                            <mat-error *ngIf="
                              PreviewExtractionForm?.get('txtDOB2')
                                ?.touched &&
                              PreviewExtractionForm?.get('txtDOB2')?.errors
                            ">
                              Enter Valid date
                            </mat-error>
                          </mat-form-field>
                          <mat-form-field class="col-md-12">
                            <mat-label [ngClass]="{
                              warning:
                                ruleColor?.erStatus12 ||
                                ruleColor?.erStatus19 ||
                                ruleColor?.erStatus28 ||
                                ruleColor?.erStatus36
                            }">
                              Prescription Date
                            </mat-label>
                            <input autocomplete="new-txtPrescriptionDate" formControlName="txtPrescriptionDate"
                              matTooltip="{{
                              ruleColor?.errValue12 ||
                                ruleColor?.errValue19 ||
                                ruleColor?.errValue28 ||
                                ruleColor?.errValue36
                            }}" matInput maxlength="40" type="text" placeholder="(MM/DD/YYYY)"
                              [matDatepicker]="txtPrescriptionDate" />
                            <mat-datepicker-toggle matSuffix [for]="txtPrescriptionDate">
                            </mat-datepicker-toggle>
                            <mat-datepicker #txtPrescriptionDate>
                            </mat-datepicker>
                            <mat-error *ngIf="
                              PreviewExtractionForm?.get(
                                'txtPrescriptionDate'
                              )?.touched &&
                              PreviewExtractionForm?.get(
                                'txtPrescriptionDate'
                              )?.errors
                            ">
                              Enter Valid date
                            </mat-error>
                          </mat-form-field>

                          <div class="col-md-12 text-right">
                            <button type="button" class="buttonColor btn-sm btn-circle" (click)="openDoctorModal()">
                              <i class="fa fa-plus" matTooltip="Click to Add Doctor"></i>
                            </button>
                          </div>

                          <mat-form-field class="col-12">
                            <mat-label>Doctor Name</mat-label>
                            <mat-select formControlName="txtDoctor" (valueChange)="
                              doctorValueChange('p2p', doctorSelect)
                            " #doctorSelect>
                              <mat-option *ngFor="let doctor of drpDoctor" [value]="doctor.name">
                                {{ doctor.name }}
                              </mat-option>
                            </mat-select>
                          </mat-form-field>

                          <mat-form-field class="col-6">
                            <mat-label>Machine Details</mat-label>
                            <mat-select formControlName="drpMachineDetails" maxlength="40">
                              <mat-option *ngFor="let machine of drpMachines" [value]="machine.machineType">
                                {{ machine.machineType }}
                              </mat-option>
                            </mat-select>
                          </mat-form-field>
                          <mat-form-field class="col-6">
                            <mat-label> Pressure Level </mat-label>
                            <input autocomplete="new-txtPressureLevel" matInput maxlength="40"
                              formControlName="txtPressureLevel" (keypress)="numberOnly($event)" pattern="^\d{0,10}"
                              type="text" />
                          </mat-form-field>
                          <mat-form-field class="col-6">
                            <mat-label> Length of need </mat-label>
                            <input autocomplete="new-txtLengthOfNeed" matInput maxlength="40"
                              formControlName="txtLengthOfNeed" type="text" />
                          </mat-form-field>
                          <mat-form-field class="col-6">
                            <mat-label>NPI Number</mat-label>
                            <input autocomplete="new-txtNpiNumber" maxlength="13" mask="000-000-0000"
                              formControlName="rxtxtNpiNumber" matInput type="text" />
                            <mat-error *ngIf="
                              PreviewExtractionForm?.get('rxtxtNpiNumber')
                                ?.touched &&
                              PreviewExtractionForm?.get('rxtxtNpiNumber')
                                ?.errors?.number
                            ">
                              Enter only Numbers!
                            </mat-error>
                          </mat-form-field>
                          <div class="col-6">
                            <br />
                            <mat-checkbox formControlName="chkDoctorSigned">Is Doctor Signed
                            </mat-checkbox>
                          </div>
                          <mat-form-field class="col-12">
                            <mat-label> Doctor Signed Date </mat-label>
                            <input autocomplete="new-txtSignedDate" [max]="txtSignedDate"
                              formControlName="txtSignedDate" matInput maxlength="40" type="text"
                              placeholder="(MM/DD/YYYY)" [matDatepicker]="txtSignedDate" [min]="txtSignedDate" />
                            <mat-datepicker-toggle matSuffix [for]="txtSignedDate">
                            </mat-datepicker-toggle>
                            <mat-datepicker #txtSignedDate> </mat-datepicker>
                            <mat-error *ngIf="
                              PreviewExtractionForm?.get('txtSignedDate')
                                ?.touched &&
                              PreviewExtractionForm?.get('txtSignedDate')
                                ?.errors
                            ">
                              Enter Valid date
                            </mat-error>
                          </mat-form-field>

                          <mat-form-field class="col-12">
                            <mat-label>Diagnosis Code-ICD-10</mat-label>
                            <mat-select multiple formControlName="txtdiagnosisCode10Id1">
                              <mat-option *ngFor="let diagnosis of drpDiagnosisICD10"
                                [value]="diagnosis.diagnosisCode10ShortCodeId">
                                {{ diagnosis.diagnosisCodeTypeData }}
                              </mat-option>
                            </mat-select>
                          </mat-form-field>

                          <mat-tree [dataSource]="childTree['rx']" [treeControl]="treeControl">
                            <mat-tree-node class="childNodeClass text-justify" *matTreeNodeDef="let ex"
                              matTreeNodePadding val="ex" (click)="activeNode = ex"
                              [ngClass]="{ 'background-highlight': activeNode === ex }" (click)="getPdf(ex.blobName)">
                              <div class="custom-doc-info">
                                <i class="fa fa-file-pdf-o"></i>
                                <div class="custom-doc-name">&nbsp;{{ ex?.name?.length > 10 ?
                                  ex?.name.substring(0,10)+'...'+ex?.name.substring(ex?.name.lastIndexOf('.')) :
                                  ex?.name || 'Untitled-Name' }}&nbsp;</div>
                                <small>&nbsp;{{ ex?.uploadedDate }}&nbsp;</small>
                                <div class="pl-3 mr-3">
                                  <a class="editBtnColor">
                                    <i class="fa fa-pencil mr-3 pointer customThemeClass" matTooltip="Edit" (click)="
                                      viewPdfFile(ex.blobName, ex.id)
                                    "></i>
                                  </a>
                                  <a class="editBtnColor">
                                    <i class="fa fa-cloud-download mr-3 pointer customThemeClass" matTooltip="Download"
                                      (click)="
                                      downloadFile(
                                        ex.blobName,
                                        ex.fileName
                                      )
                                    "></i>
                                  </a>
                                  <a class="editBtnColor">
                                    <i class="fa fa-trash pointer asterisk" matTooltip="Delete"
                                      (click)="deleteFileById(ex.id)"></i>
                                  </a>
                                </div>
                              </div>
                            </mat-tree-node>
                          </mat-tree>


                        </div>
                      </mat-card-content>

                    </mat-expansion-panel>

                    <mat-expansion-panel>
                      <mat-expansion-panel-header class="custom-acccordion-header">
                        <mat-panel-title>
                          <div class="custom-qsecure-color font-weight-bolder">
                            COMPLIANCE REPORT&nbsp;
                            <span class="circular-badge">
                              {{ childTree['compliancereport'] ? childTree['compliancereport'].length : 0 }}</span>
                          </div>
                        </mat-panel-title>
                      </mat-expansion-panel-header>
                      <mat-card-content>
                        <div class="form-row">
                          <mat-form-field class="col-6">
                            <mat-label> Patient Name </mat-label>
                            <input autocomplete="new-txtPatientName3" matInput maxlength="40"
                              formControlName="txtPatientName3" type="text" />
                            <mat-error *ngIf="
                              PreviewExtractionForm?.get('txtPatientName3')
                                ?.touched &&
                              PreviewExtractionForm?.get('txtPatientName3')
                                ?.errors?.sAlphabets
                            ">
                              Enter only Alphabets!
                            </mat-error>
                          </mat-form-field>
                          <mat-form-field class="col-6">
                            <mat-label> Birth Date </mat-label>
                            <input autocomplete="new-txtDOB3" formControlName="txtDOB3" matInput maxlength="40"
                              type="text" placeholder="(MM/DD/YYYY)" [matDatepicker]="txtDOB3" />
                            <mat-datepicker-toggle matSuffix [for]="txtDOB3">
                            </mat-datepicker-toggle>
                            <mat-datepicker #txtDOB3> </mat-datepicker>
                            <mat-error *ngIf="
                              PreviewExtractionForm?.get('txtDOB3')
                                ?.touched &&
                              PreviewExtractionForm?.get('txtDOB3')?.errors
                            ">
                              Enter Valid date
                            </mat-error>
                          </mat-form-field>
                          <mat-form-field class="col-6">
                            <mat-label> Study Start date </mat-label>
                            <input autocomplete="new-txtStudyStartDate" formControlName="txtStudyStartDate" matInput
                              maxlength="40" type="text" placeholder="(MM/DD/YYYY)"
                              [matDatepicker]="txtStudyStartDate" />
                            <mat-datepicker-toggle matSuffix [for]="txtStudyStartDate">
                            </mat-datepicker-toggle>
                            <mat-datepicker #txtStudyStartDate>
                            </mat-datepicker>
                            <mat-error *ngIf="
                              PreviewExtractionForm?.get(
                                'txtStudyStartDate'
                              )?.touched &&
                              PreviewExtractionForm?.get(
                                'txtStudyStartDate'
                              )?.errors
                            ">
                              Enter Valid date
                            </mat-error>
                          </mat-form-field>
                          <mat-form-field class="col-6">
                            <mat-label>Compliance percentage</mat-label>
                            <input autocomplete="new-txtCompliancePercentage" maxlength="40"
                              formControlName="txtCompliancePercentage" matInput type="text" pattern="[a-zA-Z0-9]*" />
                          </mat-form-field>
                          <mat-form-field class="col-12">
                            <mat-label> Study End date </mat-label>
                            <input autocomplete="new-txtStudyEndDate" formControlName="txtStudyEndDate" matInput
                              maxlength="40" type="text" placeholder="(MM/DD/YYYY)" [matDatepicker]="txtStudyEndDate" />
                            <mat-datepicker-toggle matSuffix [for]="txtStudyEndDate">
                            </mat-datepicker-toggle>
                            <mat-datepicker #txtStudyEndDate>
                            </mat-datepicker>
                            <mat-error *ngIf="
                              PreviewExtractionForm?.get('txtStudyEndDate')
                                ?.touched &&
                              PreviewExtractionForm?.get('txtStudyEndDate')
                                ?.errors
                            ">
                              Enter Valid date
                            </mat-error>
                          </mat-form-field>
                          <div class="col-12 mt-2">
                            <label for="rbnComplianceMet">
                              Compliance Met
                            </label>
                            <mat-radio-group id="rbnComplianceMet" formControlName="rbnComplianceMet"
                              class="col-sm-7 radio mt-2 txt-left">
                              <mat-radio-button class="mr-2" value="1">Yes</mat-radio-button>
                              <mat-radio-button class="mr-2" value="0">No</mat-radio-button>
                            </mat-radio-group>
                          </div>
                          <mat-form-field class="col-6">
                            <mat-label> Usage days</mat-label>
                            <input autocomplete="new-txtUsagedays" maxlength="40" formControlName="txtUsagedays"
                              matInput type="text" pattern="[a-zA-Z0-9]*" />
                          </mat-form-field>
                          <mat-form-field class="col-6">
                            <mat-label>out of</mat-label>
                            <input autocomplete="new-txtUsagedaysOutOf" maxlength="40"
                              formControlName="txtUsagedaysOutOf" matInput type="text" pattern="[a-zA-Z0-9]*" />
                          </mat-form-field>
                          <mat-form-field class="col-6">
                            <mat-label>Greater than 4 hours</mat-label>
                            <input autocomplete="new-txtGreater4Hours" maxlength="40" formControlName="txtGreater4Hours"
                              matInput type="text" pattern="[a-zA-Z0-9]*" />
                          </mat-form-field>
                          <mat-form-field class="col-6">
                            <mat-label>Less than 4 hours</mat-label>
                            <input autocomplete="new-txtLess4hours" maxlength="40" formControlName="txtLess4hours"
                              matInput type="text" pattern="[a-zA-Z0-9]*" />
                          </mat-form-field>
                          <mat-tree [dataSource]="childTree['compliancereport']" [treeControl]="treeControl">
                            <mat-tree-node class="childNodeClass text-justify" *matTreeNodeDef="let ex"
                              matTreeNodePadding val="ex" (click)="activeNode = ex"
                              [ngClass]="{ 'background-highlight': activeNode === ex }" (click)="getPdf(ex.blobName)">
                              <div class="custom-doc-info">
                                <i class="fa fa-file-pdf-o"></i>
                                <div class="custom-doc-name">&nbsp;{{ ex?.name?.length > 10 ?
                                  ex?.name.substring(0,10)+'...'+ex?.name.substring(ex?.name.lastIndexOf('.')) :
                                  ex?.name || 'Untitled-Name' }}&nbsp;</div>
                                <small>&nbsp;{{ ex?.uploadedDate }}&nbsp;</small>
                                <div class="pl-3 mr-3">
                                  <a class="editBtnColor">
                                    <i class="fa fa-pencil mr-3 pointer customThemeClass" matTooltip="Edit" (click)="
                                      viewPdfFile(ex.blobName, ex.id)
                                    "></i>
                                  </a>
                                  <a class="editBtnColor">
                                    <i class="fa fa-cloud-download mr-3 pointer customThemeClass" matTooltip="Download"
                                      (click)="
                                      downloadFile(
                                        ex.blobName,
                                        ex.fileName
                                      )
                                    "></i>
                                  </a>
                                  <a class="editBtnColor">
                                    <i class="fa fa-trash pointer asterisk" matTooltip="Delete"
                                      (click)="deleteFileById(ex.id)"></i>
                                  </a>
                                </div>
                              </div>
                            </mat-tree-node>
                          </mat-tree>
                        </div>
                      </mat-card-content>

                    </mat-expansion-panel>

                    <mat-expansion-panel *ngFor="let key of childDocType let index = index">

                      <mat-expansion-panel-header class="custom-acccordion-header">
                        <mat-panel-title>
                          <div class="custom-qsecure-color font-weight-bolder">
                            {{ sfull_name[index] }}&nbsp;
                            <span class="circular-badge">{{ childTree[key] ? childTree[key].length : 0 }}</span>
                          </div>
                        </mat-panel-title>
                      </mat-expansion-panel-header>
                      <mat-card-content>
                        <div class="form-row">
                          <mat-tree [dataSource]="childTree[key]" [treeControl]="treeControl">
                            <mat-tree-node class="childNodeClass text-justify" *matTreeNodeDef="let ex"
                              matTreeNodePadding val="ex" (click)="activeNode = ex"
                              [ngClass]="{ 'background-highlight': activeNode === ex }" (click)="getPdf(ex.blobName)">
                              <div class="custom-doc-info">
                                <i class="fa fa-file-pdf-o"></i>
                                <div class="custom-doc-name">&nbsp;{{ ex?.name?.length > 10 ?
                                  ex?.name.substring(0,10)+'...'+ex?.name.substring(ex?.name.lastIndexOf('.')) :
                                  ex?.name || 'Untitled-Name' }}&nbsp;</div>
                                <small>&nbsp;{{ ex?.uploadedDate }}&nbsp;</small>
                                <div class="pl-3 mr-3">
                                  <a class="editBtnColor">
                                    <i class="fa fa-pencil mr-3 pointer customThemeClass" matTooltip="Edit" (click)="
                                        viewPdfFile(ex.blobName, ex.id)
                                      "></i>
                                  </a>
                                  <a class="editBtnColor">
                                    <i class="fa fa-cloud-download mr-3 pointer customThemeClass" matTooltip="Download"
                                      (click)="
                                        downloadFile(
                                          ex.blobName,
                                          ex.fileName
                                        )
                                      "></i>
                                  </a>
                                  <a class="editBtnColor">
                                    <i class="fa fa-trash pointer asterisk" matTooltip="Delete"
                                      (click)="deleteFileById(ex.id)"></i>
                                  </a>
                                </div>
                              </div>
                            </mat-tree-node>
                          </mat-tree>
                        </div>



                      </mat-card-content>
                    </mat-expansion-panel>

                    <mat-card-content>
                      <div class="form-row ml-2">
                        <!-- Add a text area for comments -->
                        <mat-form-field class="col-12">
                          <textarea matInput placeholder="Comments"></textarea>
                        </mat-form-field>
                      </div>
                    </mat-card-content>

                    <mat-card-content>
                      <div class="form-row ml-2" (click)="clearNotificationData()">

                        <mat-progress-bar mode="determinate" [ngClass]="{
                                progress_success: percentageOfCompletion === 100,
                                progress_danger: percentageOfCompletion !== 100
                              }" matTooltip="{{ percentageOfCompletion + ' % Of Completion' }}"
                          [value]="percentageOfCompletion">
                        </mat-progress-bar>
                      </div>
                      <div class="col-12" [style.text-align]="'-webkit-center'">
                        <label *ngIf="percentageOfCompletion === 100" class="mt-3 badge badge-success">
                          {{ percentageOfCompletion + " % Of Completion" }}
                        </label>
                        <label *ngIf="percentageOfCompletion !== 100" class="mt-3 badge badge-danger">
                          {{ percentageOfCompletion + " % Of Completion" }}
                        </label>
                      </div>
                    </mat-card-content>

                    <button mat-button class="float-right buttonColors mr-2 mt-2 disabled-button" [disabled]="true"
                      [matTooltip]="'This button is disabled because of an upcoming feature under progress'"
                      (click)="onSubmitDisapproveMedicare()">
                      Disapprove Medicare
                    </button>
                    <button mat-button class="float-right buttonColor mr-2 mt-2" (click)="onSubmit()">
                      Check Rules
                    </button>
                  </form>
                </mat-accordion>
              </div>
            </div>
          </div>
          <!--Side Bar End-->

          <!--Document Viewer-->
          <div class="col-xl-7" [hidden]="viewDocViewer">
            <ng-container>
              <ngx-extended-pdf-viewer [src]="strSelectedPdfPath" (pagesLoaded)="onPagesLoaded($event)"
                [height]="pdfHeight" [showSidebarButton]="false" [textLayer]="true"
                [showPagingButtons]="true" [showOpenFileButton]="false" [showHandToolButton]="false"
                [showScrollingButton]="false" [showSpreadButton]="false" [showPropertiesButton]="false"
                [showBookmarkButton]="false" [zoom]="'page-width'" [enableDragAndDrop]="false" [showFindButton]="false">
              </ngx-extended-pdf-viewer>
            </ng-container>
          </div>
          <br />
        </div>

      </div>
    </div>
  </mat-card-content>
</mat-card>
