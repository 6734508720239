import { ListService, PagedAndSortedResultRequestDto } from '@abp/ng.core';
import { DatePipe } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { InvoicePaymentViewComponent } from 'projects/admin/src/app/invoice-payment-view/invoice-payment-view.component';
import { PaymentViewComponent } from 'projects/admin/src/app/payment-view/payment-view.component';
import { SaleorderDTO } from 'projects/order/src/app/order-proxy/order-management/order/dto/models';
import { SaleOrderSearchService } from 'projects/order/src/app/order-proxy/order-management/order/sale-order-search.service';
import { SaleorderService } from 'projects/order/src/app/order-proxy/order-management/order/saleorder.service';
import { WarehouseSearchService } from 'projects/order/src/app/order-proxy/order-management/order/warehouse-search.service';
import {
  updateOrder
} from 'projects/order/src/app/order-store/order.action';
import {
  OrderMasterDropdownDTO,
  WarehouseWipStatusDTO
} from 'projects/patient/src/app/dropdown-proxy/dropdown-management/dropdowns/dto/order/models';
import { OrderMasterDropdownService } from 'projects/patient/src/app/dropdown-proxy/dropdown-management/dropdowns/master-dropdown-service/order-master-dropdown.service';
import { OrderDropdowns } from 'projects/patient/src/app/dropdown-proxy/dropdown-management/dropdowns/order-dropdowns.enum';
import { TableService } from 'projects/shared/src/app/table.service';
import { DateValidator } from 'projects/shared/src/app/validations/date-validator';
import { Subscription } from 'rxjs';
import Swal from 'sweetalert2';
import { ToastrService } from "ngx-toastr";

@Component({
  selector: 'app-warehouse-list',
  templateUrl: './warehouse-list.component.html',
  styleUrls: ['./warehouse-list.component.scss'],
  providers: [ListService, DatePipe],
})
export class WarehouseListComponent implements OnInit, OnDestroy {
  //! Variable Initalization
  strSelectedBillingId: string = '';
  strPageType: string = 'billingWarehouseList';
  organizationUnitId: string = null;
  verifyTableData: any[] = [];
  paymentData: any[] = [];
  public arrDisplayedColumns: string[] = [];
  orderForm: FormGroup;
  isLoading: boolean = false;
  showValue: boolean = false;
  showValue1: boolean = false;
  enableEdit = false;
  initialPaymentHide: boolean = false;
  PaymentStatusHide: boolean = false;
  PaidHide: boolean = false;
  enableEditIndex: any = null;
  drpwarehouse: WarehouseWipStatusDTO[] = [];
  sWipStatus: string = '';
  dataSource: MatTableDataSource<any> = new MatTableDataSource<any>([]);
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatTable, { static: false }) tableRef: MatTable<any>;
  isShownUpdateIconForOrder: boolean = true;
  subscription$: Subscription[] = [];

  constructor(
    public tableService: TableService,
    public title: Title,
    public router: Router,
    public list: ListService,
    private snackBar: MatSnackBar,
    private orderService: SaleorderService,
    private orderSearchService: SaleOrderSearchService,
    private formBuilder: FormBuilder,
    private warehouseSearch: WarehouseSearchService,
    private store: Store,
    private dateValidator: DateValidator,
    public dialog: MatDialog,
    private toastr: ToastrService,
    private orderDropdownService: OrderMasterDropdownService
  ) {}

  ngOnInit(): void {
    const getOrderReport = this.tableService.getOrderUpdate().subscribe(
      (value) => {
        if (value == true) {
          this.isShownUpdateIconForOrder = true;
        } else {
          this.isShownUpdateIconForOrder = false;
        }
      },
      (err) => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      }
    );
    this.subscription$.push(getOrderReport);
    //
    this.orderForm = this.formBuilder.group({
      PatientId: new FormControl(''),
      PatientName: new FormControl(''),
      date: new FormControl('', this.dateValidator.dateVaidator),
      Plan: new FormControl(''),
      VerifyName: new FormControl(''),
      Status: new FormControl(''),
      location: new FormControl(''),
      supplyDate: new FormControl('', this.dateValidator.dateVaidator),
      saleOrderId: new FormControl(''),
      email: new FormControl(''),
      warehouse: new FormControl(''),
    });
    const valueChanges = this.orderForm.valueChanges.subscribe(
      (value: billingList) => {
        let filter: billingList = {
          PatientId: value?.PatientId?.trim()?.toLowerCase(),
          PatientName: value?.PatientName?.trim()?.toLowerCase(),
          date: value?.date?.trim()?.toLowerCase(),
          Plan: value?.Plan?.trim()?.toLowerCase(),
          VerifyName: value?.VerifyName?.trim()?.toLowerCase(),
          Status: value?.Status?.trim()?.toLowerCase(),
          location: value?.location?.trim()?.toLowerCase(),
          supplyDate: value?.supplyDate?.trim()?.toLowerCase(),
          saleOrderId: value?.saleOrderId?.trim()?.toLowerCase(),
          email: value?.email?.trim()?.toLowerCase(),
          warehouse: value?.warehouse,
        };
        if (this.orderForm.valid) {
          this.dataSource.filter = JSON.stringify(filter);
          this.filtertable(filter);
        }
      },
      (err) => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      }
    );
    this.subscription$.push(valueChanges);
    //
    this.getDropdown();
    this.arrDisplayedColumns = [
      'patientId',
      'PatientName',
      'saleOrderId',
      'warehouse',
      'options',
      'Plan',
      'VerifyName',
      'email',
      'Status',
      'Date',
      'location',
      'supplyDate',
    ];
    this.tableService.getOrganizationUnitId().subscribe(
      (Value) => {
        this.organizationUnitId = Value;
      },
      (err) => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      }
    );
    this.snackBar.dismiss();
    this.title.setTitle('Qsecure | Warehouse');
    this.getTableData();
  }

  ngOnDestroy(): void {
    this.subscription$?.forEach((sub) => {
      sub && sub?.unsubscribe();
    });
  }
  //! dropdown
  getDropdown() {
    let orderDropdownInput: OrderDropdowns[];
    orderDropdownInput = [OrderDropdowns.WareHouseWipStatus];
    const getOrderDropdown = this.orderDropdownService
      .getOrderDropdownsByInput(orderDropdownInput)
      .subscribe(
        (stateResponse) => {
          let response: OrderMasterDropdownDTO = stateResponse;
          this.drpwarehouse = response.lstWarehouseWipStatuses;
        },
        (err) => {
          const data: HttpErrorResponse = err;
          Swal.fire({
            icon: 'info',
            text: data?.error?.error?.message,
          });
        }
      );
    this.subscription$.push(getOrderDropdown);
  }
  enableEditMethod(i: any) {
    this.enableEdit = true;
    this.enableEditIndex = i;
  }
  showWip() {
    this.showValue = false;
    this.showValue1 = true;
  }
  cancelWip() {
    this.showValue = true;
    this.showValue1 = false;
  }

  //! initiate payment
  paymentInitiate(record: {
    payPalCheckOutOrderPaymentStatus: string;
    data: any;
  }) {
    if (
      record.payPalCheckOutOrderPaymentStatus === 'Initiate Payment' ||
      'Link Expired'
    ) {
      const dialogRef = this.dialog.open(PaymentViewComponent, {
        disableClose: true,
        data: { orderId: record.data },
      });
      const closeModal = dialogRef.afterClosed().subscribe(() => {
        this.getTableData();
      });
      this.subscription$.push(closeModal);
      //
      // let payment: CreateUpdateChecoutOrderCreateDTO;
      // this.store.dispatch(new getOrder(record.data)).subscribe(response => {
      //
      //   let orderData = response?.orderState?.selectedOrder;
      //   let orderItem = response?.orderState?.selectedOrder.items;
      //   let orderId = orderData.id
      //   this.paymentData = []
      //   orderItem?.forEach(element => {
      //     this.paymentData.push({
      //       itemId: element?.itemId,
      //       itemCurrenyCode: "USD",
      //       itemAmount: String(element?.charge)
      //     })
      //   });
      //   payment = {
      //     saleOrderId: orderData?.id,
      //     returnUrl: 'http://localhost:4200/#/cashPayment/'+ orderId,
      //     itemDetails: this.paymentData,
      //     clientMailAddress: "sb-pddu46631598@business.example.com",
      //     clientMerchantId: "VN995TY99ZFHC"
      //   }
      //   this.paymentService.createCheckOutOrder(payment).subscribe(response => {
      //
      //     Swal.fire({ title: 'Success', html: 'Saved Successfully', icon: 'success', timer: 3000, timerProgressBar: true });
      //     this.getTableData()

      //   })
      // })
    } else {
      const dialogRef = this.dialog.open(PaymentViewComponent, {
        disableClose: true,
        data: { orderId: record.data },
      });
      const closeModal = dialogRef.afterClosed().subscribe(() => {
        this.getTableData();
      });
      this.subscription$.push(closeModal);
    }
    // if (record.payPalCheckOutOrderPaymentStatus === "Money Transferred to Client Account" || record.payPalCheckOutOrderPaymentStatus === "Mail Sent") {

    // }
  }

  //! Invoice initiate payment
  paymentInvoiceInitiate(record: any) {
    if (record.payPalInvoicePaymentStatus === 'Initiate Payment') {
      const dialogRef = this.dialog.open(InvoicePaymentViewComponent, {
        disableClose: true,
        data: { orderId: record.data },
      });
      const closeModal = dialogRef.afterClosed().subscribe(() => {
        this.getTableData();
      });
      this.subscription$.push(closeModal);
    } else {
      const dialogRef = this.dialog.open(InvoicePaymentViewComponent, {
        disableClose: true,
        data: { orderId: record.data },
      });
      const closeModal = dialogRef.afterClosed().subscribe(() => {
        this.getTableData();
      });
      this.subscription$.push(closeModal);
      //
    }
    // if (record.payPalInvoicePaymentStatus === "Money Transferred to Client Account" || record.payPalInvoicePaymentStatus === "Mail Sent") {

    // }
    // if (record.payPalInvoicePaymentStatus === "Paid") {
    //   const dialogRef = this.dialog.open(InvoicePaymentViewComponent, {
    //     disableClose: true,
    //     data: { orderId: record.data }
    //   });
    //   dialogRef.afterClosed().subscribe(() => {
    //     this.getTableData();
    //   })
    // }
  }

  //! get order saved datas
  getDetailsForSave(id) {
    let OrderDetails: SaleorderDTO;
    const orderGet = this.orderService.get(id).subscribe(
      (response) => {
        // this.store.dispatch(new getOrder(id)).subscribe(response => {
        OrderDetails = response;
        this.saveWipStatus(OrderDetails);
      },
      (err) => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      },
      () => {}
    );
    this.subscription$.push(orderGet);
  }
  onOptionsSelected(value) {
    this.sWipStatus = value;
  }

  //! save function after dropdown change
  saveWipStatus(response: SaleorderDTO) {
    // this.organizationUnitId = localStorage.getItem('initialOrganizationUnitId');
    let OrderDetails: SaleorderDTO;
    OrderDetails = {
      defaultClaimId: response.defaultClaimId,
      orders: response.orders,
      insurances: response.insurances,
      clinicals: response.clinicals,
      items: response?.items,
      orderStatus: response.orderStatus,
      saleorderId: response.saleorderId,
      currentStage: response.currentStage,
      currentStageStatus: response.currentStageStatus,
      orderWipStatus: this.sWipStatus,
      organizationUnitId: response.organizationUnitId,
      patients: response.patients,
      patientLedgerStatus: response.patientLedgerStatus,
      shippingApprovalStatus: response.shippingApprovalStatus,
      claimHistoryStatus: response.claimHistoryStatus,
      claimId: response.claimId,
      patientId: response.patientId,
      isShippingApprove: 0,
      isCashPayment: response.isCashPayment,
      orderProcessStatus: response.orderProcessStatus,
      patientLedgerReason: response.patientLedgerReason,
      documentReason: response.documentReason,
      paymentTransactionDetails: response?.paymentTransactionDetails,
      payPalMailResendCount: response?.payPalMailResendCount,
      payPalPaymentMethodType: response?.payPalPaymentMethodType,
      payPalPaymentStatus: response?.payPalPaymentStatus,
      shippingCheckList: response?.shippingCheckList,
      orderBilling: response?.orderBilling,
      authType: response?.authType,

      deliveryTicket: response?.deliveryTicket,
    };
    response.id != '' &&
      this.store
        .dispatch(new updateOrder(response?.id ?? '', OrderDetails))
        .subscribe(
          () => {
            // Swal.fire({
            //   title: 'Success',
            //   html: 'Saved Successfully',
            //   icon: 'success',
            //   timer: 3000,
            //   timerProgressBar: true,
            // });
            this.toastr.success('Saved Successfully','Success')
            this.enableEdit = false;
            this.enableEdit = false;
            this.showValue = true;
            this.showValue1 = false;
            this.getTableData();
          },
          (err) => {
            const data: HttpErrorResponse = err;
            Swal.fire({
              icon: 'info',
              text: data?.error?.error?.message,
            });
          }
        );
  }
  //! Get Inital Table Data
  getTableData() {
    this.isLoading = true;
    this.verifyTableData = [];
    const getList = this.orderSearchService
      .getList(new PagedAndSortedResultRequestDto())
      .subscribe(
        (response) => {
          response?.items?.map((value) => {
            if (
              value?.isCashPayment === 0 &&
              value?.isShippingApprove == 1 &&
              value?.orderWipStatus != 'Order delivered' &&
              value?.orderWipStatus != 'Order dispatched'
            ) {
              // this.initialPaymentHide = false
              // this.PaymentStatusHide = false
              // this.PaidHide = false
              if (
                value !== null &&
                value?.patients?.insuranceVerifications
                  ?.primaryInsuranceVerifies?.verificationStatus == 'Done' &&
                value?.orders?.isCompleted == 'Done'
              ) {
                this.getPaymentData(value);
              }
            }
            if (value?.isCashPayment === 1) {
              if (
                value?.isCashPayment === 1 &&
                value?.orders.isCompleted === 'Done' &&
                value?.orderWipStatus != 'Order delivered' &&
                value?.orderWipStatus != 'Order dispatched'
              ) {
                this.showValue = false;

                // if (value?.isCashPayment == 1 && value?.payPalCheckOutOrderPaymentStatus == null) {
                //   this.initialPaymentHide = true
                //   this.PaymentStatusHide = false
                //   this.PaidHide = false Aii
                // }
                // if (value?.isCashPayment == 1 && value?.payPalCheckOutOrderPaymentStatus === "Mail Sent") {
                //   this.initialPaymentHide = false
                //   this.PaymentStatusHide = true
                //   this.PaidHide = false
                // }  if (value?.isCashPayment == 1 && value?.payPalCheckOutOrderPaymentStatus === "Money Transferred to Client Account") {
                //   this.initialPaymentHide = false
                //   this.PaymentStatusHide = false
                //   this.PaidHide = true
                // }
                this.getPaymentData(value);
              }
            }
          });
          this.isLoading = false;
          const arrTableData = this.verifyTableData;
          this.dataSource = new MatTableDataSource(arrTableData);
          this.dataSource.sort = this.sort;
          this.dataSource.paginator = this.paginator;
        },
        (err) => {
          this.isLoading = false;
          const arrTableData = this.verifyTableData;
          this.dataSource = new MatTableDataSource(arrTableData);
          this.dataSource.sort = this.sort;
          this.dataSource.paginator = this.paginator;
          const data: HttpErrorResponse = err;
          Swal.fire({
            icon: 'info',
            text: data?.error?.error?.message,
          });
        }
      );
    this.subscription$.push(getList);
  }
  //! for seperate table data based on cash and insurance payment
  getPaymentData(value: SaleorderDTO) {
    this.verifyTableData.push({
      patientId: value?.patients?.defaultPatientId,
      PatientName: value?.patients?.personals?.fullName,
      createDate:
        value?.creationTime == null
          ? undefined
          : new Date(value?.creationTime)?.toLocaleDateString('en-US'),
      plan: value?.patients?.insuranceVerifications?.primaryInsuranceVerifies
        ?.plan,
      VerifyName:
        value?.patients?.insuranceVerifications?.primaryInsuranceVerifies
          ?.planName,
      status:
        value?.patients?.insuranceVerifications?.primaryInsuranceVerifies
          ?.verificationStatus,
      saleOrderId: value?.saleorderId,
      location: value?.orders?.invLocationId,
      supplyDate:
        value?.orders?.scheduledDate == null
          ? undefined
          : new Date(value?.orders?.scheduledDate)?.toLocaleDateString('en-US'),
      email: value?.patients?.personals?.emailId,
      data: value?.id,
      wipStatusType: value?.orders?.wipStateId,
      payPalPaymentStatus: value?.payPalPaymentStatus,
      cashPayment: value?.isCashPayment !== 1,
      insPayment: value?.isCashPayment !== 0,
      value: value,
    });
    const arrTableData = this.verifyTableData;
    this.dataSource = new MatTableDataSource(arrTableData);
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }

  // }
  //! search table
  filtertable(filter: billingList) {
    let saleOrderId = filter.saleOrderId;
    let PatientName = filter.PatientName;
    let PatientId = filter.PatientId;
    let supplyDate = filter.supplyDate;
    let location = filter.location;
    let VerifyName = filter.VerifyName;
    let date = filter.date;
    let Status = filter.Status;
    let email = filter.email;
    let Plan = filter.Plan;
    let warehouse = filter.warehouse;
    const warehouseSearch = this.warehouseSearch
      .searchWarehouseBySPatientIdAndSPatientNameAndSSaleOrderIdAndIOrderStatusAndSPlanAndSInsuranceNameAndSEmailAndSVerificationStatusAndDtCreatedDateAndSShippingLocationAndDtSuppliedDate(
        PatientId,
        PatientName,
        saleOrderId,
        warehouse,
        Plan,
        VerifyName,
        email,
        Status,
        dateYYYYMMDDtoMMDDYYYY(date),
        location,
        dateYYYYMMDDtoMMDDYYYY(supplyDate)
      )
      .subscribe(
        (data) => {
          this.verifyTableData = [];
          data?.forEach(
            (value: {
              id: any;
              patients: {
                defaultPatientId: any;
                personals: { fullName: any; emailId: any };
                insuranceVerifications: {
                  primaryInsuranceVerifies: {
                    plan: any;
                    planName: any;
                    verificationStatus: any;
                  };
                };
              };
              isShippingApprove: number;
              orderWipStatus: string;
              creationTime: string | number | Date;
              saleorderId: any;
              orders: {
                invLocationId: any;
                scheduledDate: string | number | Date;
              };
              isCashPayment: number;
              payPalPaymentStatus: any;
            }) => {
              if (value?.patients) {
                if (value !== null) {
                  // if (primaryIns?.verificationStatus == 'Done' && primaryAuth?.isCompleted == 1 && value?.orders?.isCompleted == 1) {
                  if (
                    value?.isShippingApprove == 1 &&
                    value?.orderWipStatus != 'Order delivered' &&
                    value?.orderWipStatus != 'Order dispatched'
                  ) {
                    this.verifyTableData.push({
                      patientId: value?.patients?.defaultPatientId,
                      PatientName: value?.patients.personals?.fullName,
                      createDate:
                        value?.creationTime == null
                          ? undefined
                          : new Date(value?.creationTime)?.toLocaleDateString(
                              'en-US'
                            ),
                      plan: value?.patients?.insuranceVerifications
                        ?.primaryInsuranceVerifies?.plan,
                      VerifyName:
                        value?.patients?.insuranceVerifications
                          ?.primaryInsuranceVerifies?.planName,
                      status:
                        value?.patients?.insuranceVerifications
                          ?.primaryInsuranceVerifies?.verificationStatus,
                      saleOrderId: value?.saleorderId,
                      location: value?.orders?.invLocationId,
                      supplyDate:
                        value?.orders?.scheduledDate == null
                          ? undefined
                          : new Date(
                              value?.orders?.scheduledDate
                            )?.toLocaleDateString('en-US'),
                      email: value?.patients?.personals?.emailId,
                      cashPayment: value?.isCashPayment !== 1,
                      insPayment: value?.isCashPayment !== 0,
                      payPalCheckOutOrderPaymentStatus:
                        value?.payPalPaymentStatus,
                      data: value?.id,
                    });
                  }
                }
              }

              // else {
              //   if (value?.isShippingApprove == 1) {

              //     this.verifyTableData.push({
              //       saleOrderId: value?.saleorderId,
              //       VerifyName: value?.planName,
              //       patientId: value?.defaultPatientId,
              //       PatientName: value?.fullName,
              //       supplyDate: value?.scheduledDate == null ? undefined : new Date(value?.scheduledDate)?.toLocaleDateString("en-US"),
              //       location: value?.invLocationId,
              //       plan: value?.plan,
              //       createDate: value?.creationTime == null ? undefined : new Date(value?.creationTime)?.toLocaleDateString("en-US"),
              //       status: value?.verificationStatus,
              //       email: value?.personals?.emailId,
              //       cashPayment: value?.isCashPayment !== 1,
              //       insPayment: value?.isCashPayment !== 0,
              //       payPalCheckOutOrderPaymentStatus: value?.payPalCheckOutOrderPaymentStatus,

              //     })
              //   }
              // }

              // if (value?.patients) {
              //   if (value?.isShippingApprove == 1 && value?.orderWipStatus != "Order delivered" &&
              //     value?.orderWipStatus != "Order dispatched") {
              //     let data = {
              //       sPatientId: value?.patients?.defaultPatientId;
              //       sPatientName: value?.patients?.personals?.fullName;
              //       dCreatedDate: value?.creationTime;
              //       sSaleOrderId: value?.saleorderId;
              //       sLocation: value?.orders?.invLocationId;
              //       dtScheduleDate: value?.orders?.scheduledDate;
              //       email: value?.patients?.personals?.emailId;
              //     }
              //     const primaryIns = value?.patients?.insuranceVerifications?.primaryInsuranceVerifies;
              //     const secondaryIns = value?.patients?.insuranceVerifications?.secondaryInsuranceVerifies;
              //     const tertiaryIns = value?.patients?.insuranceVerifications?.tertiaryInsuranceVerifies;
              //     const primaryAuth = value?.patients?.authorizations?.primaryAuthorizations;
              //     const secondaryAuth = value?.patients?.authorizations?.secondaryAuthorizations;
              //     const tertiaryAuth = value?.patients?.authorizations?.tertiaryAuthorizations;
              //     if (primaryIns !== null) {
              //       if (primaryIns?.verificationStatus == 'Done' && primaryAuth?.isCompleted == "Done" && value?.orders?.isCompleted == 1) {
              //         this.verifyTableData.push({
              //           patientId: data?.sPatientId;
              //           PatientName: data?.sPatientName;
              //           createDate: data?.dCreatedDate;
              //           plan: primaryIns?.plan;
              //           VerifyName: primaryIns?.planName;
              //           Status: primaryIns?.verificationStatus;
              //           saleOrderId: data?.sSaleOrderId;
              //           location: data?.sLocation;
              //           supplyDate: data?.dtScheduleDate;
              //           email: data?.email;

              //         })
              //       }
              //     }
              //     if (secondaryIns !== null) {
              //       if (secondaryIns?.verificationStatus == 'Done' && secondaryAuth?.isCompleted == "Done" && value?.orders?.isCompleted == 1) {

              //         this.verifyTableData.push({
              //           patientId: data?.sPatientId,
              //           PatientName: data?.sPatientName,
              //           createDate: data?.dCreatedDate,
              //           plan: secondaryIns?.plan,
              //           VerifyName: secondaryIns?.planName,
              //           Status: secondaryIns?.verificationStatus,
              //           saleOrderId: data?.sSaleOrderId,
              //           location: data?.sLocation,
              //           supplyDate: data?.dtScheduleDate,
              //           email: data?.email

              //         })
              //       }
              //     }
              //     if (tertiaryIns !== null) {
              //       if (tertiaryIns?.verificationStatus == 'Done' && tertiaryAuth?.isCompleted == "Done" && value?.orders?.isCompleted == 1) {

              //         this.verifyTableData.push({
              //           patientId: data?.sPatientId,
              //           PatientName: data?.sPatientName,
              //           createDate: data?.dCreatedDate,
              //           plan: tertiaryIns?.plan,
              //           planCob: tertiaryIns?.planCob,
              //           planName: tertiaryIns?.planName,
              //           Status: tertiaryIns?.verificationStatus,
              //           saleOrderId: data?.sSaleOrderId,
              //           location: data?.sLocation,
              //           supplyDate: data?.dtScheduleDate,
              //           email: data?.email

              //         })
              //       }
              //     }
              //   }
              // } else {
              //   if (value?.isShippingApprove == 1 && value?.orderWipStatus != "Order delivered" &&
              //     value?.orderWipStatus != "Order dispatched") {
              //     this.verifyTableData.push({
              //       patientId: value?.defaultPatientId,
              //       PatientName: value?.fullName,
              //       createDate: value?.creationTime,
              //       plan: value?.plan,
              //       VerifyName: value?.planName,
              //       Status: value?.verificationStatus,
              //       saleOrderId: value?.saleorderId,
              //       location: value?.invLocationId,
              //       supplyDate: value?.scheduledDate,
              //       email: value?.emailId
              //     })
              //   }
              // }
            }
          );
          const arrTableData = this.verifyTableData;
          this.dataSource = new MatTableDataSource(arrTableData);
          this.dataSource.sort = this.sort;
          this.dataSource.paginator = this.paginator;
        },
        (err) => {
          const arrTableData = this.verifyTableData;
          this.dataSource = new MatTableDataSource(arrTableData);
          this.dataSource.sort = this.sort;
          this.dataSource.paginator = this.paginator;
          const data: HttpErrorResponse = err;
          Swal.fire({
            icon: 'info',
            text: data?.error?.error?.message,
          });
        }
      );
    this.subscription$.push(warehouseSearch);
  }
  //  ! Table CLick Event redirection
  viewShippingDetails(id: string) {
    this.router.navigate(['billing/billingProcessing/' + id]);
    this.strSelectedBillingId = id;
  }
}

export interface billingList {
  PatientId?: string;
  PatientName?: string;
  date?: string;
  Plan?: string;
  VerifyName?: string;
  Status?: string;
  location?: string;
  supplyDate?: string;
  saleOrderId?: string;
  email?: string;
  warehouse?: number;
}

function dateYYYYMMDDtoMMDDYYYY(strDate: string): string {
  if (
    strDate &&
    strDate !== '' &&
    typeof strDate === 'string' &&
    strDate !== null &&
    strDate !== undefined &&
    strDate.length >= 1 &&
    !isNaN(new Date(strDate).getTime())
  ) {
    let latest_date = Intl.DateTimeFormat('en-US')?.format(new Date(strDate));
    return latest_date;
  }
  return '';
}
