import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MasterCheckListService } from '../patient-proxy/patient-optimization/master-check-list.service';
import { PagedAndSortedResultRequestDto, PagedResultDto } from '@abp/ng.core';
import { MasterCheckListDTO } from '../patient-proxy/patient-optimization/dto';
import {
  MatDialog,
  MatDialogConfig,
  MatDialogRef,
} from '@angular/material/dialog';
import { MasterCheckListQuestionsService } from '../patient-proxy/patient-optimization/master-check-list-questions.service';
import { HttpErrorResponse } from '@angular/common/http';
import Swal from 'sweetalert2';
import { MedicareCheckFinalModelComponent } from '../medicare-check-final-model/medicare-check-final-model.component';

@Component({
  selector: 'app-medicare-question-model',
  templateUrl: './medicare-question-model.component.html',
  styleUrls: ['./medicare-question-model.component.scss'],
})
export class MedicareQuestionModelComponent implements OnInit {
  isDefault: boolean = true;
  preQuestionHeaderTitle = 'Pre Questionnaire';
  preQuestion = 'Please choose a Check List Type and click Next. ';
  currrentQuestionId: string;
  questionHeaderTitle: string = 'Empty Header';
  currrentQuestion: string = 'Current Question';
  currrentQuestionNotes: string =
    'Currrent Question Notes. If you need help, call the Medicare Team for clarification.';
  isNextEnable: boolean = false;
  medicarePreQuestionaireForm: FormGroup;
  medicareQuestionaireForm: FormGroup;
  preQuestionaireOptionList: any;
  optionList: any;
  index: number = 0;

  newQuestionObject: any;

  QuestionOverAllArray: {
    questionId: string;
    question: string;
    selectedOptionId: string;
    option: string;
  }[] = [];

  testClickCount: number = 0;

  constructor(
    public dialog: MatDialog,
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<MedicareQuestionModelComponent>,
    private masterCheckListService: MasterCheckListService,
    private masterCheckListQuestionsService: MasterCheckListQuestionsService
  ) {}

  ngOnInit(): void {
    this.getMasterFirstQuestion();

    this.medicarePreQuestionaireForm = this.fb.group({
      rdPreQuestionaireList: ['', Validators.required],
    });

    // Subscribe to value changes
    this.medicarePreQuestionaireForm
      .get('rdPreQuestionaireList')
      .valueChanges.subscribe((value) => {
        // Handle the change event here


        // Find the selected item based on the ID
        let selectedItem = this.preQuestionaireOptionList.find(
          (item) => item.id === value
        );

        // //  Log the selected text value
        // if (selectedItem) {

        // }

        this.newQuestionObject = {
          questionId: '0',
          question: this.preQuestion,
          selectedOptionId: value,
          option: selectedItem ? selectedItem.checklist : '', // Ensure selectedItem is not undefined
        };

        console.warn(this.newQuestionObject);

        let existingIndex = this.QuestionOverAllArray.findIndex(
          (item) => item.questionId === this.newQuestionObject.questionId
        );

        if (existingIndex !== -1) {
          // Update the existing object
          this.QuestionOverAllArray[existingIndex] = this.newQuestionObject;
        } else {
          // Push the new object
          this.QuestionOverAllArray.push(this.newQuestionObject);
        }

        // Log the array for testing

        // Add your custom logic here
        // this.isNextEnable=true;
      });

    this.medicarePreQuestionaireForm.statusChanges.subscribe((status) => {
      // Update isNextEnable based on form validity
      this.isNextEnable = status === 'VALID';
    });

    this.medicareQuestionaireForm = this.fb.group({
      rdQuestionaireList: ['', Validators.required],
    });

    this.medicareQuestionaireForm
      .get('rdQuestionaireList')
      .valueChanges.subscribe((value) => {
        // Handle the change event here


        // Find the selected item based on the ID
        // let selectedItem = this.optionList.find(
        //   (item) => item.id === value
        // );

        // // Log the selected text value
        // if (selectedItem) {

        // }

        this.newQuestionObject = {
          questionId: this.currrentQuestionId,
          question: this.currrentQuestion,
          selectedOptionId: '',
          option: value, // Ensure selectedItem is not undefined
        };

        let existingIndex = this.QuestionOverAllArray.findIndex(
          (item) => item.questionId === this.newQuestionObject.questionId
        );
        if (this.currrentQuestionId !== undefined && value != null) {
          if (existingIndex !== -1) {
            // Update the existing object
            this.QuestionOverAllArray[existingIndex] = this.newQuestionObject;
          } else {
            // Push the new object
            this.QuestionOverAllArray.push(this.newQuestionObject);
          }
        }

        // Log the array for testing

        // Add your custom logic here
        // this.isNextEnable=true;
      });

    this.medicareQuestionaireForm.statusChanges.subscribe((status) => {
      // Update isNextEnable based on form validity
      this.isNextEnable = status === 'VALID';
    });
  }
  goToNext() {
    this.isNextEnable = false;
    this.index++;

    this.getUpcomingQuestion();
    // this.currrentQuestionId=this.testClickCount.toString();
    // this.questionHeaderTitle = 'New Setup Header';
    // this.currrentQuestion = "Has this patient ever rec'd a PAP before?";
    // this.currrentQuestionNotes ='';
    // this.optionList = [
    //   { option: ["Yes", "No"] }
    // ];
    // this.isDefault=false;
  }

  goBack() {
    this.index--;

    this.triggerFormStatusCheck();

    //this.getMasterFirstQuestion();
  }

  triggerFormStatusCheck() {
    // Manually trigger the status check
    if (this.isDefault) {
      this.medicarePreQuestionaireForm.updateValueAndValidity();
    } else {
      this.medicareQuestionaireForm.updateValueAndValidity();
    }
  }

  getMasterFirstQuestion() {
    // Assuming you want to use default values for skipCount, maxResultCount, and sorting
    const defaultRequest: PagedAndSortedResultRequestDto = {
      skipCount: 0, // Replace with your default skipCount value
      maxResultCount: 10, // Replace with your default maxResultCount value
      sorting: 'id', // Replace with your default sorting value
    };

    this.masterCheckListService.getList(defaultRequest).subscribe(
      (response: PagedResultDto<MasterCheckListDTO>) => {
        // Handle the response data here

        this.preQuestionaireOptionList = response.items;
        this.isDefault = true;
      },
      (error) => {
        // Handle errors
        console.error(error);
        this.isDefault = true;
      }
    );
  }

  getUpcomingQuestion() {
    this.clearSelection();
    // Next Question API
    this.testClickCount = this.testClickCount + 1;
    if (this.testClickCount <= 5) {
      if (this.testClickCount % 2 === 0) {

        this.currrentQuestionId = this.testClickCount.toString();
        this.questionHeaderTitle = 'New Setup Header';
        this.currrentQuestion = this.testClickCount + ' Question';
        this.currrentQuestionNotes = '';
        this.optionList = [{ option: ['Yes', 'No'] }];
        this.isDefault = false;
      } else {

        this.currrentQuestionId = this.testClickCount.toString();
        this.questionHeaderTitle = 'Dummy Header';
        this.currrentQuestion = this.testClickCount + ' Question';
        this.currrentQuestionNotes = '';
        this.optionList = [{ option: ['Yes', 'No', 'None'] }];
        this.isDefault = false;
      }
    } else {


      // Call Function to close the modal
      this.closeModal();

      const config: MatDialogConfig = {
        disableClose: true,
        // width: '400px',
        data: {
          patientID: '12321312312312',
        },
      };
      const dialogRef = this.dialog.open(
        MedicareCheckFinalModelComponent,
        config
      );

      const closeDialog = dialogRef.afterClosed().subscribe(
        () => {
          // this.getReminderTableData();
        },
        (err) => {
          const data: HttpErrorResponse = err;
          Swal.fire({
            icon: 'info',
            text: data?.error?.error?.message,
          });
        }
      );
      // this.subscription$.push(closeDialog);
    }
  }

  clearSelection() {
    this.medicareQuestionaireForm.get('rdQuestionaireList').setValue(null);
  }

  // Function to close the modal
  closeModal(): void {
    this.dialogRef.close();
  }
}
