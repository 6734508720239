import { ListService, PagedAndSortedResultRequestDto } from '@abp/ng.core';
import { HttpErrorResponse } from '@angular/common/http';
import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Title } from '@angular/platform-browser';
import { CreateUpdateMasterRegionStatesDTO, MasterRegionStatesDTO, RegionCitiesDTO, RegionCountriesDTO } from 'projects/patient/src/app/dropdown-proxy/dropdown-management/dropdowns/dto/models';
import { MasterStateService, RegionDropdownService } from 'projects/patient/src/app/dropdown-proxy/dropdown-management/dropdowns/region-dropdown-service';
import { RegionStatesDTO } from 'projects/patient/src/app/dropdown-proxy/rcm/dropdown-management/dropdowns/dto';
import { defaultCountry } from 'projects/shared/src/app/enums/allenums.enum';
import { DateValidator } from 'projects/shared/src/app/validations/date-validator';
import { Observable, Subscription } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { ToastrService } from "ngx-toastr";

@Component({
  selector: 'app-master-state',
  templateUrl: './master-state.component.html',
  styleUrls: ['./master-state.component.scss'],
  providers: [ListService]

})
export class MasterStateComponent implements OnInit , OnDestroy {
  stateId: string = "";
  stateForm: FormGroup;
  isShown: boolean = false;
  dataSource: MatTableDataSource<any>;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  displayedColumns: string[] = [ 'Options', "stateName", "stateShortName", "countryName", "countryShortName", "countryPhoneCode",];
  phoneCode: string;
  isShowSpinner: boolean;
  saveButtonHide: boolean;
  tableData: MasterRegionStatesDTO[] = [];
  step: boolean = false;
  Country: string = defaultCountry.US;
  State: string = '';
  City: string = '';
  drpCountry: RegionCountriesDTO[] = [];
  tableStates: MasterRegionStatesDTO[] = [];
  drpStates: RegionStatesDTO[] = [];
  drpCities: RegionCitiesDTO[] = [];
  public filteredCountries: Observable<RegionCountriesDTO[]>;
  public filteredStates: Observable<RegionStatesDTO[]>;
  public filteredCities: Observable<RegionCitiesDTO[]>;
  CountryName = "";
  stateName = "";
  subscription$: Subscription[] = [];
  constructor(
    private formBuilder: FormBuilder,
    public title: Title,
    public list: ListService,
    private region: RegionDropdownService,
    private mstState: MasterStateService,
    private toastr: ToastrService,

  ) { }

  ngOnInit(): void {
    this.title.setTitle("Qsecure | State");
    this.stateForm = this.formBuilder.group({
      txtCountry: new FormControl("", Validators.required),
      txtStatePhoneNumber: new FormControl("", Validators.required),
      txtStateShortCode: new FormControl("", Validators.required),
      txtStateName: new FormControl(""),
      txtNewStateName: new FormControl("", Validators.required),
      txtCountryFilter: new FormControl(""),
      txtStateFilter: new FormControl(""),
    })
    this.countryDropdown();
    this.stateForm.patchValue({
      txtCountry: this.Country
    });
    this.getStatesTable();
  }

  ngOnDestroy(): void {
    this.subscription$?.forEach((sub) => {
      sub && sub?.unsubscribe();
    });
  }

  //! get states tables data
  getStatesTable() {
    this.isShowSpinner = true;
    this.tableStates = [];
    const stateGetList = this.mstState.getList(new PagedAndSortedResultRequestDto).subscribe(response => {
      this.tableStates = response?.items ?? [];
      this.isShowSpinner = false;
      this.dataSource = new MatTableDataSource(this.tableStates);
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
    }, err => {
      this.isShowSpinner = false;
      this.dataSource = new MatTableDataSource(this.tableStates);
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    });
    this.subscription$.push(stateGetList);
  }

  //get country dropdown
  countryDropdown() {
    this.isShowSpinner = true;
    this.onChangeState(this.Country);
    this.drpCountry = [];
  const countryDropdown =  this.region.getAllCountriesByInput(new PagedAndSortedResultRequestDto).subscribe(response => {
      this.drpCountry = response?.items ?? [];
      this.filteredCountries = this.stateForm?.get("txtCountryFilter").valueChanges
        .pipe(
          startWith(''),
          map(value => this.drpCountry.filter(option => option?.countryName?.toLowerCase()?.includes(value?.toLowerCase() ?? "")))
        );
    }, err => {
      this.isShowSpinner = false;
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    });
    this.subscription$.push(countryDropdown);
  }

  // on change get state city//

  onChangeState(value: string | null | undefined): boolean {
    this.stateForm.patchValue({ txtState: null, txtCity: null })
    this.drpStates = [];
    this.drpCities = [];
    if (value !== null && value !== undefined ){
    const stateDropdown =  this.region.getAllStatesByCountryNameByCountryShortName(value).subscribe(response => {
        this.drpStates = response?.items ?? [];
        this.filteredStates = this.stateForm?.get("txtStateFilter").valueChanges
          .pipe(
            startWith(''),
            map(value => this.drpStates.filter(option =>
              option?.stateName?.toLowerCase()?.includes(value?.toLowerCase() ?? "")
            ))
          );

        if (response.items == []) {
          this.stateForm.controls['txtStatePhoneNumber'].disable();
          this.stateForm.patchValue({
            txtStatePhoneNumber: response?.items[0]?.countryPhoneCode ?? ""
          });
          this.phoneCode = "";
        } else {
          this.stateForm.controls['txtStatePhoneNumber'].enable();
          this.stateForm.patchValue({
            txtStatePhoneNumber: response?.items[0]?.countryPhoneCode ?? ""
          });
          this.phoneCode = response?.items[0]?.countryPhoneCode;
        }
      }, err => {
        const data: HttpErrorResponse = err;
        Swal.fire({ icon: 'info', text: data?.error?.error?.message});
      })
      this.subscription$.push(stateDropdown);
    }

    return this.drpStates !== [] && this.drpStates.length !== 0;
  }




  clearCountry() {
    this.Country = '';
    this.phoneCode = '';
    this.stateForm.patchValue({ drpBillingCountry: "" });
    this.stateForm.controls['txtStatePhoneNumber'].disable();
  }
  clearState() {
    this.State = '';
    this.stateForm.patchValue({ txtState: "" });
  }
  clearCity() {
    this.City = '';
    this.stateForm.patchValue({ txtCity: "" });
  }

  //! get state form Instance
  getstateForm(): FormGroup {
    return this.stateForm
  }


  //! View state By ID
  viewState(value: string) {
    this.saveButtonHide = true;

  if(value) {
    const getState = this.mstState.get(value).subscribe(resposne => {
      this.saveButtonHide = false;
      this.stateName = resposne?.stateShortName ?? "";
      this.CountryName = resposne?.countryShortName ?? "";
      this.CountryName !== "" && this.onChangeState(resposne?.countryShortName) && this.stateForm.patchValue({
        txtCountry: this.CountryName,
      });

      this.getstateForm().patchValue({
        txtCountry: resposne?.countryShortName,
        txtStatePhoneNumber: resposne?.statePhoneCode,
        txtStateShortCode: resposne?.stateShortName,
        txtNewStateName: resposne?.stateName
        // txtNewCityName: resposne?.cityName,
      })
      this.stateId = resposne?.id ?? "";
      this.step = true;
    }, (err: HttpErrorResponse) => {
      this.saveButtonHide = false;
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    });
    this.subscription$.push(getState);
  }

  }
  //! Delete state By ID
  deleteState(value: string) {
    Swal.fire({
      title: 'Are you sure you want to Delete?',
      text: 'You won\'t be able to retrieve this data!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#34c38f',
      cancelButtonColor: '#f46a6a',
      confirmButtonText: 'Yes, delete it!'
    }).then(result => {
      if (result.value) {
       if (value){
        const stateDelete =this.mstState.delete(value).subscribe(resposne => {
          this.resetstate();
          this.getStatesTable();
        }, err => {
          const data: HttpErrorResponse = err;
          Swal.fire({
            icon: 'info',
            text: data?.error?.error?.message,
          });
        });
        this.subscription$.push(stateDelete);
       }

      }
    });
  }

  //! Save Or Update state
  savestate() {
    this.saveButtonHide = true;
    let input: CreateUpdateMasterRegionStatesDTO = {
      stateName: this.stateForm.value.txtNewStateName ?? "",
      stateShortName: this.stateForm.value.txtStateShortCode ?? "",
      countryShortName: this.stateForm.value.txtCountry ?? "",
      countryPhoneCode: this.stateForm.value.txtStatePhoneNumber ?? "",
      countryName: this.drpCountry?.filter(e => e?.countryShortName === this.stateForm.value.txtCountry)[0]?.countryName ?? ""
    }
    this.stateId = this.stateId ?? "";
    if (this.stateId === "") {
      this.mstState.create(input).subscribe(response => {
        this.stateId = "";
        // Swal.fire({ title: 'Success', html: 'Saved Successfully', icon: 'success', timer: 3000, timerProgressBar: true });
        this.toastr.success('Saved Successfully','Success')
        this.saveButtonHide = false;
        this.resetstate();
        this.getStatesTable();
      }, err => {
        this.saveButtonHide = false;
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      });
    }
    else {
      this.mstState.update(this.stateId, input).subscribe(response => {
        this.stateId = "";
        // Swal.fire({ title: 'Success', html: 'Updated Successfully', icon: 'success', timer: 3000, timerProgressBar: true });
        this.toastr.success('Updated Successfully','Success')
        this.resetstate();
        this.getStatesTable();
        this.saveButtonHide = false;
      }, err => {
        this.saveButtonHide = false;
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      });
    }
  }

  //! Reset state Form
  resetstate() {
    this.getstateForm().patchValue({
      txtCountry: "",
      txtStatePhoneNumber: "",
      txtStateShortCode: "",
      txtStateName: "",
      txtNewStateName: "",
    });
    this.stateId = "";
    this.getstateForm()?.markAsUntouched();
    this.getstateForm()?.updateValueAndValidity();
    this.getstateForm()?.reset();

  }


}
