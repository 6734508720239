import type { AppointmentTypeDTO, CreateUpdateAppointmentTypeDTO, SearchAppointmentTypeDTO } from './dto/models';
import { RestService } from '@abp/ng.core';
import type { PagedAndSortedResultRequestDto, PagedResultDto } from '@abp/ng.core';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class AppointmentTypeService {
  apiName = 'OnTimeManagement';

  create = (input: CreateUpdateAppointmentTypeDTO) =>
    this.restService.request<any, AppointmentTypeDTO>({
      method: 'POST',
      url: '/api/OnTimeApp/appointment-type',
      body: input,
    },
     { apiName: this.apiName, skipHandleError: true });

  delete = (id: string) =>
    this.restService.request<any, void>({
      method: 'DELETE',
      url: `/api/OnTimeApp/appointment-type/${id}`,
    },
     { apiName: this.apiName, skipHandleError: true });

  get = (id: string) =>
    this.restService.request<any, AppointmentTypeDTO>({
      method: 'GET',
      url: `/api/OnTimeApp/appointment-type/${id}`,
    },
     { apiName: this.apiName, skipHandleError: true });

  getList = (input: PagedAndSortedResultRequestDto) =>
    this.restService.request<any, PagedResultDto<AppointmentTypeDTO>>({
      method: 'GET',
      url: '/api/OnTimeApp/appointment-type',
      params: { skipCount: input.skipCount, maxResultCount: input.maxResultCount, sorting: input.sorting },
    },
     { apiName: this.apiName, skipHandleError: true });

  update = (id: string, input: CreateUpdateAppointmentTypeDTO) =>
    this.restService.request<any, AppointmentTypeDTO>({
      method: 'PUT',
      url: `/api/OnTimeApp/appointment-type/${id}`,
      body: input,
    },
     { apiName: this.apiName, skipHandleError: true });

  searchAppointmentTypesByInput = (input: SearchAppointmentTypeDTO) =>
    this.restService.request<any, PagedResultDto<AppointmentTypeDTO>>({
      method: 'GET',
      url: '/api/OnTimeApp/appointment-type/search-appointment-types',
      params: { appointmentType: input.appointmentType, duration: input.duration, status: input.status, createdDate: input.createdDate },
    },
     { apiName: this.apiName, skipHandleError: true });

  constructor(private restService: RestService) {}
}
