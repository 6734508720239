<br>
<div class="container-fluid">
  <div class="row bg">
    <div class="col-12">
      <div class="d-flex align-items-center justify-content-between mb-2 mt-1">
        <h3 class="font-weight-bolder mb-0">Calender</h3>
        <div class=" page-title-box page-title-right">
          <ol class="breadcrumbs m-0 align-items-center">

            <li class="ml-2 mt-2 breadcrumb-item">Settings</li>
            <li class="mt-2 breadcrumb-item muted">Admin Calender</li>
          </ol>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="card card-body mt-1" style="margin-left: 15px;">
  <form [formGroup]="calenderForm">
    <div class="row">
      <mat-form-field class="col-4">
        <mat-label>Role</mat-label>
        <mat-select #roleSelect multiple formControlName="txtRole" [(value)]="Role" #singleSelect>
          <mat-option>
            <ngx-mat-select-search placeholderLabel="Search" noEntriesFoundLabel="No Matches found"
              formControlName="txtRoleFilter"></ngx-mat-select-search>
          </mat-option>
          <mat-option #allRolesSelected [value]="0" (click)="toggleAllRolesSelection()">Select All Roles</mat-option>

          <mat-option *ngFor="let role of filteredRoleTypes | async" [value]="role.id">
            {{ role.name }}
          </mat-option>
        </mat-select>
        <button mat-button (click)="Role='';$event.stopPropagation()" *ngIf="Role" matSuffix (click)="cleartxtRole()"
          mat-icon-button >
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field>

      <mat-form-field class="col-4">
        <mat-label>User</mat-label>
        <mat-select #userSelect multiple formControlName="txtUser" [(value)]="User">

          <mat-option>
            <ngx-mat-select-search placeholderLabel="Search" noEntriesFoundLabel="No Matches found"
              formControlName="txtUserFilter"></ngx-mat-select-search>
          </mat-option>
          <mat-option #allUsersSelected [value]="0" (click)="toggleAllUsersSelection()">Select All Users</mat-option>
          <mat-option *ngFor="let user of filteredUserTypes | async" [value]="user.Id">
            {{ user.OwnerText }}
          </mat-option>
        </mat-select>
        <button mat-button (click)="User='';$event.stopPropagation()" *ngIf="User" matSuffix (click)="cleartxtUser()"
          mat-icon-button >
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field>
      <div class="col-4">
        <button mat-button class="buttonColor" (click)="search()">Search
        </button>
      </div>
    </div>
  </form>

  <div class="col-lg-12 mar-bottom mt-2">
    <!-- <mat-card> -->
    <div class="row">
      <div class="col-2">
        <span><b>Information Status :</b></span>
      </div>
      <span class="col-2 align">Scheduled <span class="dot sea-dot"></span></span>
      <span class="col-2 align">Rescheduled <span class="dot orange-dot"></span> </span>
      <span class="col-2 align">Completed <span class="dot green-dot"></span></span>
      <span class="col-2 align">Cancelled <span class="dot red-dot"></span></span>
      <span class="col-2 align"></span>

    </div>
    <!-- </mat-card> -->
    <br />
  </div>

  <ejs-schedule width="100%" height="550px" #scheduleObj [selectedDate]="selectedDate" [views]="views"
    (popupOpen)="onPopupOpen($event)" (actionComplete)="onActionComplete($event)" [eventSettings]="eventSettings"
    (eventRendered)="applyCategoryColor($event)" (eventClick)="onEventClick($event)" [showQuickInfo]="showQuickInfo"
    (cellClick)="onCellClick($event)" [group]='group'>
    <e-resources>

      <e-resource field="ConferenceId" title="Conference" name="Conferences" [dataSource]="conferenceDataSource"
        textField='Text' idField='Id' colorField='Color'>
      </e-resource>
      <e-resource field="OwnerId" title="Owner" name="Owners" [dataSource]="roomDataSource" textField='OwnerText'
        idField='Id' groupIdField='OwnerGroupId' colorField='OwnerColor' (eventRendered)="applyCategoryColor($event)">
      </e-resource>

      <!-- <e-resource field="ProjectId" title="Choose Project" name="Projects"
        [dataSource]="projectDataSource"
        textField="text" idField="id" colorField="color">
    </e-resource> -->

    </e-resources>
    <ng-template #groupHeaderTooltipTemplate let-data>
      <div class='template-wrap'>
        <div class="res-text">Name: {{data.resourceData.OwnerText}} </div>
      </div>
    </ng-template>
  </ejs-schedule>
</div>
