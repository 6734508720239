import { RestService } from '@abp/ng.core';
import type { PagedAndSortedResultRequestDto, PagedResultDto } from '@abp/ng.core';
import { Injectable } from '@angular/core';
import type { ProviderCitiesDTO, ProviderCountriesDTO, ProviderStatesDTO } from '../dto/models';

@Injectable({
  providedIn: 'root',
})
export class ProviderDropdownService {
  apiName = 'DropdownManagement';

  getAllCitiesBasedonStatesByStateName = (stateName: string) =>
    this.restService.request<any, PagedResultDto<ProviderCitiesDTO>>({
      method: 'GET',
      url: '/api/DropdownApp/provider-dropdown/cities-basedon-states',
      params: { stateName },
    },
     { apiName: this.apiName, skipHandleError: true });

  getAllCountriesByInput = (input: PagedAndSortedResultRequestDto) =>
    this.restService.request<any, PagedResultDto<ProviderCountriesDTO>>({
      method: 'GET',
      url: '/api/DropdownApp/provider-dropdown/countries',
      params: { skipCount: input.skipCount, maxResultCount: input.maxResultCount, sorting: input.sorting },
    },
     { apiName: this.apiName, skipHandleError: true });

  getAllStatesBasedonCountriesByCountryName = (countryName: string) =>
    this.restService.request<any, PagedResultDto<ProviderStatesDTO>>({
      method: 'GET',
      url: '/api/DropdownApp/provider-dropdown/states-basedon-countries',
      params: { countryName },
    },
     { apiName: this.apiName, skipHandleError: true });

  constructor(private restService: RestService) {}
}
