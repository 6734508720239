import { PagedAndSortedResultRequestDto } from '@abp/ng.core';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, TemplateRef } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { TableService } from 'projects/shared/src/app/table.service';
import { map } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { BillingPaypalInvoiceComponent } from '../billing-paypal-invoice/billing-paypal-invoice.component';
import { BillingPaypalComponent } from '../billing-paypal/billing-paypal.component';

import { ClaimInformationsService } from '../billing-proxy/billing-management/posting/claim-informations.service';
import { BillingReminderComponent } from '../billing-reminder/billing-reminder.component';
import { InsurancePaymentsComponent } from '../insurance-payments/insurance-payments.component';
import { VisitNoteComponent } from '../visit-note/visit-note.component';

@Component({
  selector: 'app-claim-history',
  templateUrl: './claim-history.component.html',
  styleUrls: ['./claim-history.component.scss']
})
export class ClaimHistoryComponent implements OnInit {

  public strPageType: string = "paymentHistory";
  organizationunitId: string = "";
  claimTableData: any[] = [];
  isShowInt: boolean = false;
  isShowPaid: boolean = false;
  lblPatientName: string = "";

  isLoading:boolean= true;
  patientId: string = '';
  claimId: string = '';
  orderId: string = '';
  saleorderId: string;

  constructor(
    public tableService: TableService,
    public title: Title,
    public router: Router,
    public dialog: MatDialog,
    private fb: FormBuilder,
    private store: Store,
    private claimInformationService: ClaimInformationsService,
    private activatedRoute: ActivatedRoute,
  ) { }
  public claimHistoryForm: FormGroup;
  openDialogWithTemplateRef(templateRef: TemplateRef<any>) {
    this.dialog.open(templateRef);
  }
  ngOnInit(): void {



    // this.getTableData()
    this.tableService.getOrganizationUnitId().subscribe(val => {
      this.organizationunitId = val;
    })
    // }, err => {
    //   const data: HttpErrorResponse = err;
    //   Swal.fire({
    //     icon: 'error',
    //     text: data?.error?.error?.message,
    //   });
    // });
    this.activatedRoute.paramMap.subscribe(response => {

      this.patientId = response.get('id');

    })

    // }, err => {
    //   const data: HttpErrorResponse = err;
    //   Swal.fire({
    //     icon: 'error',
    //     text: data?.error?.error?.message,
    //   });
    // })
    this.loadClaimHistoryTable();

  }
  payment(value: any) {
    //

    this.claimId = value.id;
    const dialogRef = this.dialog.open(InsurancePaymentsComponent, {
      data: {
        patientId: this.patientId,
        orderId: this.orderId,
        claimId: this.claimId
      },
      height: "80vh",
      width: "80vw",
      maxHeight: "80vh",
      maxWidth: "80vw"
    });

    dialogRef.afterClosed().subscribe(() => {
      this.loadClaimHistoryTable();
    }, err => {
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    });
  }
  charges() {
    this.router.navigate(['billing/addposting'])

  }
  deleteItems(element: any) {
    this.router.navigate(['billing/insurancePayment/' + element])
  }

  //! Load Patient Visits Table function
  loadClaimHistoryTable() {
    this.isLoading=true;
    this.claimTableData = [];
    this.tableService.setBillHistoryDataTable(this.claimTableData);
    this.claimInformationService.getList(new PagedAndSortedResultRequestDto).subscribe(response => {
      response?.items?.forEach((element, index) => {
        this.tableService.setBillClaimListDataTable(element)

        if (this.patientId === element?.patientId) {
          this.claimTableData.push({
            saleorderId: element?.saleorderId,
            dos: element?.lstPostingCharges[0]?.dos == null ? "" : new Date(element?.lstPostingCharges[0]?.dos)?.toLocaleDateString("en-US"),
            payee:element?.insurancePayments?.primaryInsurancePaymentDetails?.payee,
            payer: element?.insurancePayments?.primaryInsurancePaymentDetails?.payer,
            totalBilledAmount: element?.saleOrder?.shippingCheckList?.totalItemPrice,
            totalPatientBal:element?.totalPatientBal,
        //    totalPatientAmount:element?.totalPatientAmount,
            checkNo:element?.insurancePayments?.primaryInsurancePaymentDetails?.checkNo,
            patBal: element?.totalPatientBal,
            insBal: element?.totalInsBal,
            total: element?.totalBilled,
            Fille: element?.creationTime == null ? "" : new Date(element?.creationTime)?.toLocaleDateString("en-US"),
            lastFilled: element?.lastModificationTime == null ? "" : new Date(element?.lastModificationTime)?.toLocaleDateString("en-US"),
            claimid: element?.claimId,
            id: element?.id,
            patientResponsibilityAfterInsCount: element?.patientResponsibilityAfterInsCount,
            patientResponsibilityCount: element?.patientResponsibilityCount,
            processedCount: element?.processedCount,
            readyCount: element?.readyCount,
            holdCount: element?.holdCount,
            crossOverCount: element?.crossOverCount,
            patientId: element?.defaultPatientId,
            patientName: element?.patientName,
            cashPayment: element?.saleOrder?.isCashPayment !== 0,
            insPayment: element?.saleOrder?.isCashPayment !== 1,
            payPalPaymentStatus: element?.saleOrder?.payPalPaymentStatus,
            orderId: element?.orderId,
            isClaimHistoryTable : true,
            billingStatus:element?.claimStatus
          });
          this.lblPatientName = element?.patientName;
        }
        // let facilityName = this.drpFacility?.filter(v=>v?.facilityId===element?.patients?.clinicals?.marketingReferralFacility)[0]?.facilityName;
        // this.claimTableData.push({
        //   patientId: element?.defaultPatientId,
        //   saleorderId: element?.saleorderId,
        //   //status: element?.status,
        //   daySheet: "12/12/2020",
        //   dos: element?.lstPostingCharges[0]?.dos == null ? "" : new Date(element?.lstPostingCharges[0]?.dos)?.toLocaleDateString("en-US"),
        //   provider: element?.lstPostingCharges[0]?.provider,
        //   data: "1",
        //   totalFee: element?.totalFee,
        //   patBal: element?.patBalanceSum,
        //   insBal: element?.totalInsBal,
        //   total: element?.totalBilled,
        //   Fille: element?.creationTime == null ? "" : new Date(element?.creationTime)?.toLocaleDateString("en-US"),
        //   lastFilled: element?.lastModificationTime == null ? "" : new Date(element?.lastModificationTime)?.toLocaleDateString("en-US"),
        //   claimid: element?.claimId,
        //   id: element?.id,
        //   patientResponsibilityAfterInsCount: element?.patientResponsibilityAfterInsCount,
        //   patientResponsibilityCount: element?.patientResponsibilityCount,
        //   // planCode: null,
        //   processedCount: element?.processedCount,
        //   readyCount: element?.readyCount,
        //   holdCount: element?.holdCount,
        //   crossOverCount: element?.crossOverCount
        // });

      });
      this.isLoading= false;
      this.tableService.setBillHistoryDataTable(this.claimTableData);
    }, err => {
      this.isLoading=false;
      const data: HttpErrorResponse = err;
      this.isLoading= false;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    })
  }

  //Show only Ready status records
  showInt(event: MatCheckboxChange) {
    this.isShowInt = event.checked;
    if (event.checked == true && this.isShowPaid == false) {
      let status = "Ready";
      let isPostingCompleted = 0;
      this.claimInformationService.getListBasedOnStatus(status, isPostingCompleted).subscribe(response => {
        this.claimTableData = [];
        response?.items?.forEach((element, index) => {
          // let facilityName = this.drpFacility?.filter(v=>v?.facilityId===element?.patients?.clinicals?.marketingReferralFacility)[0]?.facilityName;
          this.claimTableData.push({
            patientId: element?.defaultPatientId,
            saleorderId: element?.saleorderId,
            //status: element?.status,
            daySheet: "12/12/2020",
            dos: element?.dos == null ? "" : new Date(element?.dos)?.toLocaleDateString("en-US"),
            //dos: element?.lstPostingCharges[0]?.dos == null ? "" : new Date(element?.lstPostingCharges[0]?.dos)?.toLocaleDateString("en-US"),
            provider: element?.lstPostingCharges[0]?.provider,
            data: "1",
            totalFee: element?.totalFee,
            patBal: element?.patBalanceSum,
            insBal: element?.totalInsBal,
            total: element?.totalBilled,
            Fille: element?.creationTime == null ? "" : new Date(element?.creationTime)?.toLocaleDateString("en-US"),
            lastFilled: element?.lastModificationTime == null ? "" : new Date(element?.lastModificationTime)?.toLocaleDateString("en-US"),
            claimid: element?.claimId,
            patientResponsibilityAfterInsCount: element?.patientResponsibilityAfterInsCount,
            patientResponsibilityCount: element?.patientResponsibilityCount,
            // planCode: null,
            processedCount: element?.processedCount,
            readyCount: element?.readyCount,
            holdCount: element?.holdCount,
            crossOverCount: element?.crossOverCount,
            cashPayment: element?.saleOrder?.isCashPayment !== 0,
            insPayment: element?.saleOrder?.isCashPayment !== 1,
            payPalPaymentStatus: element?.saleOrder?.payPalPaymentStatus,
            isClaimHistoryTable : true,
            billingStatus:element?.claimStatus,
            patientName:element?.patientName
          });


        })


        this.tableService.setBillHistoryDataTable(this.claimTableData);
      }, err => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      });
    }
    else if (event.checked == true && this.isShowPaid == true) {
      let status = "Ready";
      let isPostingCompleted = 1;
      this.claimInformationService.getListBasedOnStatus(status, isPostingCompleted).subscribe(response => {
        this.claimTableData = [];
        response?.items?.forEach((element, index) => {
          // let facilityName = this.drpFacility?.filter(v=>v?.facilityId===element?.patients?.clinicals?.marketingReferralFacility)[0]?.facilityName;
          this.claimTableData.push({
            patientId: element?.defaultPatientId,
            saleorderId: element?.saleorderId,
            //status: element?.status,
            daySheet: "12/12/2020",
            dos: element?.dos == null ? "" : new Date(element?.dos)?.toLocaleDateString("en-US"),
            //dos: element?.lstPostingCharges[0]?.dos == null ? "" : new Date(element?.lstPostingCharges[0]?.dos)?.toLocaleDateString("en-US"),
            provider: element?.lstPostingCharges[0]?.provider,
            data: "1",
            totalFee: element?.totalFee,
            patBal: element?.patBalanceSum,
            insBal: element?.totalInsBal,
            total: element?.totalBilled,
            Fille: element?.creationTime == null ? "" : new Date(element?.creationTime)?.toLocaleDateString("en-US"),
            lastFilled: element?.lastModificationTime == null ? "" : new Date(element?.lastModificationTime)?.toLocaleDateString("en-US"),
            claimid: element?.claimId,
            patientResponsibilityAfterInsCount: element?.patientResponsibilityAfterInsCount,
            patientResponsibilityCount: element?.patientResponsibilityCount,
            // planCode: null,
            processedCount: element?.processedCount,
            readyCount: element?.readyCount,
            holdCount: element?.holdCount,
            crossOverCount: element?.crossOverCount,
            cashPayment: element?.saleOrder?.isCashPayment !== 0,
            insPayment: element?.saleOrder?.isCashPayment !== 1,
            payPalPaymentStatus: element?.saleOrder?.payPalPaymentStatus,
            isClaimHistoryTable : true,
            billingStatus:element?.claimStatus,
            patientName:element?.patientName
          });
        })


        this.tableService.setBillHistoryDataTable(this.claimTableData);
      }, err => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      });

    }
    else {
      if (this.isShowPaid == true) {
        let status = null;
        let isPostingCompleted = 1;
        this.claimInformationService.getListBasedOnStatus(status, isPostingCompleted).subscribe(response => {
          this.claimTableData = [];
          response?.items?.forEach((element, index) => {
            // let facilityName = this.drpFacility?.filter(v=>v?.facilityId===element?.patients?.clinicals?.marketingReferralFacility)[0]?.facilityName;
            this.claimTableData.push({
              patientId: element?.defaultPatientId,
              saleorderId: element?.saleorderId,
              //status: element?.status,
              daySheet: "12/12/2020",
              dos: element?.dos == null ? "" : new Date(element?.dos)?.toLocaleDateString("en-US"),
              //dos: element?.lstPostingCharges[0]?.dos == null ? "" : new Date(element?.lstPostingCharges[0]?.dos)?.toLocaleDateString("en-US"),
              provider: element?.lstPostingCharges[0]?.provider,
              data: "1",
              totalFee: element?.totalFee,
              patBal: element?.patBalanceSum,
              insBal: element?.totalInsBal,
              total: element?.totalBilled,
              Fille: element?.creationTime == null ? "" : new Date(element?.creationTime)?.toLocaleDateString("en-US"),
              lastFilled: element?.lastModificationTime == null ? "" : new Date(element?.lastModificationTime)?.toLocaleDateString("en-US"),
              claimid: element?.claimId,
              patientResponsibilityAfterInsCount: element?.patientResponsibilityAfterInsCount,
              patientResponsibilityCount: element?.patientResponsibilityCount,
              // planCode: null,
              processedCount: element?.processedCount,
              readyCount: element?.readyCount,
              holdCount: element?.holdCount,
              crossOverCount: element?.crossOverCount,
              cashPayment: element?.saleOrder?.isCashPayment !== 0,
              insPayment: element?.saleOrder?.isCashPayment !== 1,
              payPalPaymentStatus: element?.saleOrder?.payPalPaymentStatus,
              isClaimHistoryTable : true,
              billingStatus:element?.claimStatus,
              patientName:element?.patientName
            });
          })


          this.tableService.setBillHistoryDataTable(this.claimTableData);
        }, err => {
          const data: HttpErrorResponse = err;
          Swal.fire({
            icon: 'info',
            text: data?.error?.error?.message,
          });
        });
      }
      else {
        this.loadClaimHistoryTable();
      }
    }
  }

  //Show only PostingCompleted records
  showPaid(event: MatCheckboxChange) {
    this.isShowPaid = event.checked;
    if (this.isShowInt == false && event.checked == true) {
      let status = null;
      let isPostingCompleted = 1;
      this.claimInformationService.getListBasedOnStatus(status, isPostingCompleted).subscribe(response => {
        this.claimTableData = [];
        response?.items?.forEach((element, index) => {
          // let facilityName = this.drpFacility?.filter(v=>v?.facilityId===element?.patients?.clinicals?.marketingReferralFacility)[0]?.facilityName;
          this.claimTableData.push({
            patientId: element?.defaultPatientId,
            saleorderId: element?.saleorderId,
            //status: element?.status,
            daySheet: "12/12/2020",
            dos: element?.dos == null ? "" : new Date(element?.dos)?.toLocaleDateString("en-US"),
            //dos: element?.lstPostingCharges[0]?.dos == null ? "" : new Date(element?.lstPostingCharges[0]?.dos)?.toLocaleDateString("en-US"),
            provider: element?.lstPostingCharges[0]?.provider,
            data: "1",
            totalFee: element?.totalFee,
            patBal: element?.patBalanceSum,
            insBal: element?.totalInsBal,
            total: element?.totalBilled,
            Fille: element?.creationTime == null ? "" : new Date(element?.creationTime)?.toLocaleDateString("en-US"),
            lastFilled: element?.lastModificationTime == null ? "" : new Date(element?.lastModificationTime)?.toLocaleDateString("en-US"),
            claimid: element?.claimId,
            patientResponsibilityAfterInsCount: element?.patientResponsibilityAfterInsCount,
            patientResponsibilityCount: element?.patientResponsibilityCount,
            // planCode: null,
            processedCount: element?.processedCount,
            readyCount: element?.readyCount,
            holdCount: element?.holdCount,
            crossOverCount: element?.crossOverCount,
            cashPayment: element?.saleOrder?.isCashPayment !== 0,
            insPayment: element?.saleOrder?.isCashPayment !== 1,
            payPalPaymentStatus: element?.saleOrder?.payPalPaymentStatus,
            isClaimHistoryTable : true,
            billingStatus:element?.claimStatus
          });
        })


        this.tableService.setBillHistoryDataTable(this.claimTableData);
      }, err => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      });
    }
    else if (this.isShowInt == true && event.checked == true) {
      let status = "Ready";
      let isPostingCompleted = 1;
      this.claimInformationService.getListBasedOnStatus(status, isPostingCompleted).subscribe(response => {
        this.claimTableData = [];
        response?.items?.forEach((element, index) => {
          // let facilityName = this.drpFacility?.filter(v=>v?.facilityId===element?.patients?.clinicals?.marketingReferralFacility)[0]?.facilityName;
          this.claimTableData.push({
            patientId: element?.defaultPatientId,
            saleorderId: element?.saleorderId,
            //status: element?.status,
            daySheet: "12/12/2020",
            dos: element?.dos == null ? "" : new Date(element?.dos)?.toLocaleDateString("en-US"),
            //dos: element?.lstPostingCharges[0]?.dos == null ? "" : new Date(element?.lstPostingCharges[0]?.dos)?.toLocaleDateString("en-US"),
            provider: element?.lstPostingCharges[0]?.provider,
            data: "1",
            totalFee: element?.totalFee,
            patBal: element?.patBalanceSum,
            insBal: element?.totalInsBal,
            total: element?.totalBilled,
            Fille: element?.creationTime == null ? "" : new Date(element?.creationTime)?.toLocaleDateString("en-US"),
            lastFilled: element?.lastModificationTime == null ? "" : new Date(element?.lastModificationTime)?.toLocaleDateString("en-US"),
            claimid: element?.claimId,
            patientResponsibilityAfterInsCount: element?.patientResponsibilityAfterInsCount,
            patientResponsibilityCount: element?.patientResponsibilityCount,
            // planCode: null,
            processedCount: element?.processedCount,
            readyCount: element?.readyCount,
            holdCount: element?.holdCount,
            crossOverCount: element?.crossOverCount,
            cashPayment: element?.saleOrder?.isCashPayment !== 0,
            insPayment: element?.saleOrder?.isCashPayment !== 1,
            payPalPaymentStatus: element?.saleOrder?.payPalPaymentStatus,
            isClaimHistoryTable : true,
            billingStatus:element?.claimStatus
          });
        })


        this.tableService.setBillHistoryDataTable(this.claimTableData);
      }, err => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      });
    }
    else {
      if (this.isShowInt == true) {
        let status = "Ready";
        let isPostingCompleted = 0;
        this.claimInformationService.getListBasedOnStatus(status, isPostingCompleted).subscribe(response => {
          this.claimTableData = [];
          response?.items?.forEach((element, index) => {
            // let facilityName = this.drpFacility?.filter(v=>v?.facilityId===element?.patients?.clinicals?.marketingReferralFacility)[0]?.facilityName;
            this.claimTableData.push({
              patientId: element?.defaultPatientId,
              saleorderId: element?.saleorderId,
              //status: element?.status,
              daySheet: "12/12/2020",
              dos: element?.dos == null ? "" : new Date(element?.dos)?.toLocaleDateString("en-US"),
              //dos: element?.lstPostingCharges[0]?.dos == null ? "" : new Date(element?.lstPostingCharges[0]?.dos)?.toLocaleDateString("en-US"),
              provider: element?.lstPostingCharges[0]?.provider,
              data: "1",
              totalFee: element?.totalFee,
              patBal: element?.patBalanceSum,
              insBal: element?.totalInsBal,
              total: element?.totalBilled,
              Fille: element?.creationTime == null ? "" : new Date(element?.creationTime)?.toLocaleDateString("en-US"),
              lastFilled: element?.lastModificationTime == null ? "" : new Date(element?.lastModificationTime)?.toLocaleDateString("en-US"),
              claimid: element?.claimId,
              patientResponsibilityAfterInsCount: element?.patientResponsibilityAfterInsCount,
              patientResponsibilityCount: element?.patientResponsibilityCount,
              // planCode: null,
              processedCount: element?.processedCount,
              readyCount: element?.readyCount,
              holdCount: element?.holdCount,
              crossOverCount: element?.crossOverCount,
              cashPayment: element?.saleOrder?.isCashPayment !== 0,
              insPayment: element?.saleOrder?.isCashPayment !== 1,
              payPalPaymentStatus: element?.saleOrder?.payPalPaymentStatus,
              isClaimHistoryTable : true,
              billingStatus:element?.claimStatus
            });
          })


          this.tableService.setBillHistoryDataTable(this.claimTableData);
        }, err => {
          const data: HttpErrorResponse = err;
          Swal.fire({
            icon: 'info',
            text: data?.error?.error?.message,
          });
        });
      }
      else {
        this.loadClaimHistoryTable();
      }
    }
  }


  getTableData() {

    this.activatedRoute.paramMap.pipe(map(v => {
      return this.claimId = v.get('id')
    })).subscribe(v => v)


    this.claimInformationService.get(this.claimId).subscribe(response => {
      this.patientId = response.patientId;
      this.orderId = response.orderId;
      this.saleorderId = response.saleorderId;
    }, err => {
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    });
    let tableDummyData: billingList[] = [
      {
        id: "808956",
        saleorderId: this.saleorderId,
        status: "done",
        daysheet: "12/12/2020",
        dos: "12/12/2020",
        provider: "sugar land",
        data: "1",
        patBal: "$0.00",
        insBal: "$0.00",
        total: "$0.00",
        Fille: "12/12/2020",
        lastFilled: "12/12/2020",
        claimid: "45ACT44",
        patietId: this.patientId,
        orderId: this.orderId,
        calimId: this.claimId
      },
      {
        id: "987456",
        saleorderId: this.saleorderId,
        status: "done",
        daysheet: "10/10/2010",
        dos: "10/10/2010",
        provider: "sugar land, Monitor Medical",
        data: "1",
        patBal: "$0.00",
        insBal: "$0.00",
        total: "$0.00",
        Fille: "12/12/2020",
        lastFilled: "12/12/2020",
        claimid: "45ACT44",
        patietId: this.patientId,
        orderId: this.orderId,
        calimId: this.claimId
      },
      {
        id: "321456",
        saleorderId: this.saleorderId,
        status: "done",
        daysheet: "12/12/2020",
        dos: "12/12/2020",
        provider: "sugar land",
        data: "1",
        patBal: "$0.00",
        insBal: "$0.00",
        total: "$0.00",
        Fille: "12/12/2020",
        lastFilled: "12/12/2020",
        claimid: "45ACT44",
        patietId: this.patientId,
        orderId: this.orderId,
        calimId: this.claimId
      },
      {
        id: "147852",
        saleorderId: this.saleorderId,
        status: "done",
        daysheet: "12/12/2020",
        dos: "12/12/2020",
        provider: "sugar land",
        data: "1",
        patBal: "$0.00",
        insBal: "$0.00",
        total: "$0.00",
        Fille: "12/12/2020",
        lastFilled: "12/12/2020",
        claimid: "45ACT44",
        patietId: this.patientId,
        orderId: this.orderId,
        calimId: this.claimId
      },
      {
        id: "369852",
        saleorderId: this.saleorderId,
        status: "done",
        daysheet: "12/12/2020",
        dos: "12/12/2020",
        provider: "sugar land",
        data: "1",
        patBal: "$0.00",
        insBal: "$0.00",
        total: "$0.00",
        Fille: "12/12/2020",
        lastFilled: "12/12/2020",
        claimid: "45ACT44",
        patietId: this.patientId,
        orderId: this.orderId,
        calimId: this.claimId
      }

    ];
    this.tableService.setBillHistoryDataTable(tableDummyData);
  }
  openPatientVisitNotes(id: string) {
    this.claimId = id;
    const dialogRef = this.dialog.open(VisitNoteComponent, {
      data: { claimId: this.claimId }
    });
    dialogRef.afterClosed().subscribe(() => {
    });
  }
  CreateNewPatientWindow: any = 0;

  claimInfo(id: string) {
    // this.router.navigate(['/billing/claimInsurance'])

  }
  addReminder(id: string) {
    this.claimId = id['id'];

    const config: MatDialogConfig = {
      disableClose: true, data: {
        patientId: this.patientId,
        saleOrderId: this.claimId,
        claimId:  this.claimId
      }
    }
    const dialogRef = this.dialog.open(BillingReminderComponent, config);

    dialogRef.afterClosed().subscribe(() => {
    }, err => {
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    });
  }


  //! initiate payment
  paymentInitiate(record: { payPalCheckOutOrderPaymentStatus: string; orderId: string; id: string; }) {

    if (record.payPalCheckOutOrderPaymentStatus === "Initiate Payment" || "Link Expired" || null) {
      const dialogRef = this.dialog.open(BillingPaypalComponent, {
        disableClose: true,
        data: { orderId: record?.orderId, claimId: record?.id }
      });
      dialogRef.afterClosed().subscribe(() => {
        this.loadClaimHistoryTable();
      })
    } else {
      const dialogRef = this.dialog.open(BillingPaypalComponent, {
        disableClose: true,
        data: { orderId: record?.orderId, claimId: record?.id }
      });
      dialogRef.afterClosed().subscribe(() => {
        this.loadClaimHistoryTable();
      })
    }
  }


  //! Invoice initiate payment
  paymentInvoiceInitiate(record: { payPalInvoicePaymentStatus: string; orderId: string; id: string; }) {

    if (record.payPalInvoicePaymentStatus === "Initiate Payment") {
      const dialogRef = this.dialog.open(BillingPaypalInvoiceComponent, {
        disableClose: true,
        data: { orderId: record?.orderId, claimId: record?.id }
      });
      dialogRef.afterClosed().subscribe(() => {
        this.loadClaimHistoryTable();
      })

    } else {
      const dialogRef = this.dialog.open(BillingPaypalInvoiceComponent, {
        disableClose: true,
        data: { orderId: record?.orderId, claimId: record?.id }
      });
      dialogRef.afterClosed().subscribe(() => {
        this.loadClaimHistoryTable();
      })
    }
  }
}

export interface billingList {
  id: string,
  saleorderId: string,
  status: string,
  daysheet: string,
  dos: string,
  provider: string,
  data: string,
  patBal: string,
  insBal: string,
  total: string,
  Fille: string,
  lastFilled: string,
  claimid: string,
  patietId: string,
  orderId: string,
  calimId: string
}
