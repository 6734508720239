<div class="card card-body">
  <ng-container *ngIf="ruleId === '00000000-0000-0000-0000-000000000000'; else elseTitleTemplate">
    <b class="customThemeClass">Add Insurance</b>
  </ng-container>
  <ng-template #elseTitleTemplate>
    <b class="customThemeClass">Edit Insurance</b>
  </ng-template>
  <mat-dialog-content class="mat-typography" style="overflow-x: hidden">
    <form [formGroup]="insuranceForm">
      <div class="row">
        <div class="col-lg-12">
          <mat-card-content>
            <div class="row">
              <mat-form-field class="col-3">
                <mat-label>Insurance Name<span class="asterisk">*</span></mat-label>
                <input autocomplete="new-txtInsuaranceName" matInput maxlength="40" formControlName="txtInsuaranceName" placeholder="Enter Insurance Name"
                  type="text" />
                <mat-error *ngIf="
                                    insuranceForm?.get('txtInsuaranceName')?.touched &&
                                    insuranceForm?.get('txtInsuaranceName')?.errors?.required
                                  ">
                  Insurance Name is a required field!
                </mat-error>
                <mat-error *ngIf="
                                    insuranceForm?.get('txtInsuaranceName')?.touched &&
                                    insuranceForm?.get('txtInsuaranceName')?.errors?.sAlphabets
                                  ">
                  Enter only Alphabets!
                </mat-error>
              </mat-form-field>
              <mat-form-field class="col-3">
                <mat-label>Eligible Quantity <span class="asterisk">*</span></mat-label>
                <input autocomplete="new-txtQty" matInput formControlName="txtQty" placeholder="Enter Eligible Quantity" minlength="1"
                  maxlength="15" type="text" />
                <mat-error *ngIf="insuranceForm?.get('txtQty')?.touched &&
                                    insuranceForm?.get('txtQty')?.errors?.pattern">
                  Enter only Numbers!
                </mat-error>
                <mat-error *ngIf="
                              insuranceForm?.get('txtQty')?.touched &&
                              insuranceForm?.get('txtQty')?.errors?.required
                                    ">
                  Units is a required field!
                </mat-error>
              </mat-form-field>
              <mat-form-field class="col-3">
                <mat-label>Eligible Days <span class="asterisk">*</span></mat-label>
                <input autocomplete="new-txtDays" matInput formControlName="txtDays" max="9" Min="0" placeholder="Enter Eligible Days"
                  type="text" />
                <mat-error *ngIf="insuranceForm?.get('txtDays')?.touched &&
                                insuranceForm?.get('txtDays')?.errors?.pattern">
                  Enter only Numbers!
                </mat-error>
                <mat-error *ngIf="
                          insuranceForm?.get('txtDays')?.touched &&
                          insuranceForm?.get('txtDays')?.errors?.required
                                ">
                  Units is a required field!
                </mat-error>
              </mat-form-field>
              <div class="col-md-3">
                <div class=" chk custom-control custom-checkbox mb-3">
                  <br>
                  <mat-checkbox formControlName="chkActive">Active<span class="asterisk">*</span></mat-checkbox>
                </div>
              </div>
              <div class="col-5"></div>
              <div class="text-lg-center">
                <button mat-button class="col-4 buttonColor" (click)="saveInsurance()"
                  [disabled]="!(!insuranceForm.invalid && insuranceForm.dirty)">
                  Save</button>
              </div>
              <div class="text-lg-center ">
                <button mat-button class="col-4 resetclr" (click)="reset()">
                  Reset</button>
              </div>
              <div class="col-4"></div>
            </div>
          </mat-card-content>
        </div>
      </div>
    </form>

    <h3 class="customThemeClass ml-1"><b>Insurance List</b></h3>
    <div class="row mr-3">
      <div class="col-12">
        <div [hidden]="!isLoading"
          style="display: flex; justify-content: center; align-items: center; background: transparent">
          <mat-progress-spinner mode="indeterminate" value="66" diameter="45" class="lightseagreen">
          </mat-progress-spinner>
        </div>
        <table mat-table class="table ml-4" matSort [dataSource]="dataSource">
          <form style="display: flex" [formGroup]="insuranceSearchForm">
            <!-- <ng-container matColumnDef="sno">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>S.No</th>
              <td mat-cell *matCellDef="let data; let i = index">
                {{ i+1 }}
              </td>
            </ng-container> -->
            <ng-container matColumnDef="insuranceName">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                <mat-form-field class="filter">
                  <input autocomplete="new-insuranceNameSearch" matInput formControlName="insuranceNameSearch" />
                  <mat-placeholder>Insurance Name</mat-placeholder>
                </mat-form-field>
              </th>
              <td mat-cell *matCellDef="let data">
                <ng-container>
                  {{ data.insuranceName }}
                </ng-container>
              </td>
            </ng-container>
            <ng-container matColumnDef="eligibleQuantity">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                <mat-form-field class="filter">
                  <input autocomplete="new-eligibleQuantitySearch" matInput (keypress)="numberOnly($event)" formControlName="eligibleQuantitySearch" />
                  <mat-placeholder>Eligible Quantity</mat-placeholder>
                </mat-form-field>
              </th>
              <td mat-cell *matCellDef="let data">
                <ng-container>
                  {{ data.eligibleQuantity }}
                </ng-container>
              </td>
            </ng-container>
            <ng-container matColumnDef="eligibleDays">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                <mat-form-field class="filter">
                  <input autocomplete="new-eligibleDaySearch" matInput (keypress)="numberOnly($event)" formControlName="eligibleDaySearch" />
                  <mat-placeholder>Eligible Days</mat-placeholder>
                </mat-form-field>
              </th>
              <td mat-cell *matCellDef="let data">
                <ng-container>
                  {{ data.eligibleDays }}
                </ng-container>
              </td>
            </ng-container>

            <ng-container matColumnDef="status">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                <mat-form-field class="filter">
                  <input autocomplete="new-statusSearch" matInput formControlName="statusSearch" />
                  <mat-placeholder>Status</mat-placeholder>
                </mat-form-field>
              </th>
              <td mat-cell *matCellDef="let data">
                <ng-container>
                  {{ data.status }}
                </ng-container>
              </td>
            </ng-container>
            <ng-container matColumnDef="Options">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Options</th>
              <td mat-cell *matCellDef="let data">
                <a class="editBtnColor" (click)="ViewItem(data)">
                  <i class="fa fa-pencil editBtnColor mr-3" matTooltip="Click To Edit" ></i>
                </a>
                &nbsp;
                <a class="deleteBtnColor" (click)="deleteItem(data)">
                  <i class="fa fa-trash-o" matTooltip="Click To Delete" ></i>
                </a>

              </td>
            </ng-container>
            <ng-container matColumnDef="noRecords">
              <td class="text-align-center" mat-footer-cell *matFooterCellDef colspan="9">
                No Records Found
              </td>
            </ng-container>
            <ng-container matColumnDef="paginator">
              <td mat-footer-cell *matFooterCellDef colspan="8">
                <mat-paginator class="table table-responsive mat-table-class" #MatPaginator
                  [length]="dataSource?.data?.length" [pageSize]="10" [pageIndex]="0" showFirstLastButtons
                  [pageSizeOptions]="[5, 10, 25, 100]">
                </mat-paginator>
              </td>
            </ng-container>
          </form>
          <tr mat-footer-row *matFooterRowDef="['noRecords']"
            [ngClass]="{ active: dataSource && dataSource?.data && dataSource?.data?.length !== 0 }"></tr>
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
          <tr mat-footer-row *matFooterRowDef="['paginator']"></tr>
        </table>
      </div>
    </div>
  </mat-dialog-content>

</div>
<mat-dialog-actions align="end">
  <button class="buttonCancelColor" mat-button mat-dialog-close>Close</button>

</mat-dialog-actions>
