import { DatePipe } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ToastrService } from 'ngx-toastr';
import { EOBERAReportService } from 'projects/billing/src/app/billing-proxy/billing-management/rcm/billing-management/optimization/billing-reports/eoberareport.service';
import { InvoiceReportService } from 'projects/billing/src/app/billing-proxy/billing-management/rcm/billing-management/optimization/billing-reports/invoice-report.service';
import {
  clsEOBERAReportSearch,
  clsInvoiceReportSearch,
} from 'projects/billing/src/app/billing-proxy/billing-management/rcm/billing-management/optimization/dto';
import { BillingReportService } from 'projects/billing/src/app/claim-proxy/claim-processing-management/rcm/billing-management/optimization/billing-reports';
import { MmOrderService } from 'projects/order/src/app/order-proxy/order-management/order-optimization/mm-order.service';
import {
  defaultGuid,
  nikoBillingMenu,
  nikoBillingMenuStatus,
} from 'projects/shared/src/app/enums/allenums.enum';
import { CommonService } from 'projects/shared/src/app/services/common.service';
import { CommunicationService } from 'projects/shared/src/app/services/communication.service';
import { Subscription } from 'rxjs';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-invoice-bin-queue',
  templateUrl: './invoice-bin-queue.component.html',
  styleUrls: ['./invoice-bin-queue.component.scss'],
})
export class InvoiceBinQueueComponent implements OnInit {
  nikoBillingMenu = nikoBillingMenuStatus;
  activeTab = nikoBillingMenuStatus.INVOICES;
  activeTabBin: nikoBillingMenuStatus;
  dataCount = 0;
  inboundTableData: any;
  ltPaymentsReport: any;
  eobData: any;
  loadInboundData: boolean = false;
  loadInvoiceTableAPICall: Subscription;
  billingPaymentSubcription: Subscription;
  eobERaReportApiSubscription: Subscription;
  invoiceCount = 0;
  subscription$: Subscription[] = [];
  currentPage: any = 1;
  totalPages: any = 1;
  pageOffset: any = 50;
  overallTableCount:number = 0;
  paginationInfo:any;
  recordsPerPage:number = 50;
  visibleColumns : number[];
  constructor(
    private invoiceReportService: InvoiceReportService,
    private billingReportService: BillingReportService,
    private commonService: CommonService,
    private eobService: EOBERAReportService,
    private mmOrderService: MmOrderService,
    private communicationService: CommunicationService
  ) {
    const loadTableData =
      this.communicationService.invoiceTabReloadMethodCall$.subscribe(() => {
        this.getTableData();
      });
    this.subscription$.push(loadTableData);
  }

  ngOnInit() {
    this.activeTabBin = nikoBillingMenuStatus.INVOICES;
    this.getInvoiceTabsSummary();
    this.getTableData();
  }
  getArrowClass(data: any): any {
    return { [data.arrowclass]: this.activeTabBin === data.status };
  }
  binActiveTab(data: any) {
    this.activeTabBin = data?.status;
    this.currentPage = 1;
    if (this.activeTabBin === nikoBillingMenuStatus.INVOICES) {
      this.getTableData();
    } else if (this.activeTabBin === nikoBillingMenuStatus.PAYMENTS) {
      this.getPaymentsReport(defaultGuid, null, null, null, null, null);
    } else if (this.activeTabBin === nikoBillingMenuStatus.EOB_ERA) {
      this.getEOBERAReport(null, null, null, null);
    }
  }

  changePage(pageNo: number) {
    if (pageNo != this.currentPage && pageNo > 0 && pageNo <= this.totalPages) {
      this.currentPage = pageNo;
      if (this.activeTabBin === nikoBillingMenuStatus.INVOICES) {
        this.getTableData();
      } else if (this.activeTabBin === nikoBillingMenuStatus.PAYMENTS) {
        this.getPaymentsReport(defaultGuid, null, null, null, null, null);
      } else if (this.activeTabBin === nikoBillingMenuStatus.EOB_ERA) {
        this.getEOBERAReport(null, null, null, null);
      }
    }
  }
  getTableData() {
    // this.txtDOSFromDate = this.datepipe.transform(
    //   this.invoiceSearchForm.value.txtDOSFromDate || null,
    //   'yyyy-MM-dd'
    // );
    // let txtDOSEndDate = this.datepipe.transform(
    //   this.invoiceSearchForm.value.txtDOSEndDate || null,
    //   'yyyy-MM-dd'
    // );

    // if (
    //   (this.txtDOSFromDate &&
    //     txtDOSEndDate &&
    //     this.txtDOSFromDate <= txtDOSEndDate) ||
    //   (!this.txtDOSFromDate && !txtDOSEndDate)
    // ) {
    //   console.log('If');
    //   console.log(this.txtDOSFromDate, txtDOSEndDate);
    //   this.isLoading = true;

    //   // setTimeout(() => {
    //   //   this.isLoading = false;
    //   // }, 500);

    //   try {
    //     if (this.loadInvoiceTableAPICall) {
    //       this.loadInvoiceTableAPICall.unsubscribe();
    //     }

        // let input: clsInvoiceReportSearch = {
        //   ltPatients: this.drpPatientId.length == 0 ? null : this.drpPatientId,
        //   ltClaims: this.drpInvoiceId.length == 0 ? null : this.drpInvoiceId,
        //   ltStatus: this.drpStatusId.length == 0 ? null : this.drpStatusId,
        //   dtDOSFrom: this.txtDOSFromDate,
        //   dtDOSTo: txtDOSEndDate
        // };
        this.loadInboundData=true;
        let input: clsInvoiceReportSearch = {
            ltPatients: null,
            ltClaims:  null,
            ltStatus: null,
            dtDOSFrom: '',
            dtDOSTo: '',
            pageNo: this.currentPage,
            pageOffSet: this.pageOffset
          };
          if (this.loadInvoiceTableAPICall) {
                  this.loadInvoiceTableAPICall.unsubscribe();
                }
        this.loadInvoiceTableAPICall = this.invoiceReportService
          .getInvoiceReportDetailsBySearch(input)
          .subscribe(
            (response) => {
              this.overallTableCount = response?.totalCount || 0;
              this.totalPages = Math.ceil(response?.totalCount / this.pageOffset);
              this.inboundTableData = response?.items  || [];
              this.invoiceCount=this.inboundTableData.length;
              // this.menus.find(x=>x.status===this.activeTabBin).count=this.invoiceCount;
              this.inboundTableData = this.inboundTableData.map((invoice) => {
                return {
                  ...invoice,
                  dtDOB: this.commonService.getFormattedDateZone(invoice.dtDOB),
                  dtDOS: this.commonService.getFormattedDateZone(invoice.dtDOS),
                  creationTime: this.commonService.getFormattedDateTimeZone(
                    invoice.creationTime
                  ),
                  modifiedTime: this.commonService.getFormattedDateTimeZone(
                    invoice.modifiedTime
                  ),
                };
              });
              this.paginationInfo = {
                startRecord: (this.currentPage - 1) * this.recordsPerPage + 1,
                endRecord: Math.min(this.currentPage * this.recordsPerPage, this.overallTableCount),
                overallTableCount : this.overallTableCount
              };
             this.loadInboundData=false;
            },
            (err) => {
              this.inboundTableData = [];
              this.invoiceCount=this.inboundTableData.length;
              this.loadInboundData=false;
              const data: HttpErrorResponse = err;
              Swal.fire({
                icon: 'info',
                text: data?.error?.error?.message,
              });
            }
          );
  }
  getPaymentsReport(
    paymentMethod: any,
    createdFromDate: any,
    createdToDate: any,
    referenceNumber: any,
    remittanceFromDate: any,
    remittanceToDate: any
  ) {
    this.loadInboundData = true;
    if (this.billingPaymentSubcription) {
      this.billingPaymentSubcription.unsubscribe();
    }
    this.billingPaymentSubcription = this.billingReportService
      .getPaymentsBillingReportByPageNoAndPageOffSet(
        paymentMethod,
        createdFromDate,
        createdToDate,
        referenceNumber,
        remittanceFromDate,
        remittanceToDate,
        this.currentPage,
        this.pageOffset
      )
      .subscribe(
        (response) => {
          this.overallTableCount = response?.totalCount || 0;
          this.totalPages = Math.ceil(response?.totalCount / this.pageOffset);
          this.ltPaymentsReport = response?.items ?? [];
          // this.menus.find(x=>x.status===this.activeTabBin).count= response?.totalCount;
          this.ltPaymentsReport = this.ltPaymentsReport.map((data) => {
            return {
              ...data,
              createdDate: this.commonService.getFormattedDateTimeZone(
                data.createdDate
              ),
              remittanceDate: this.commonService.getFormattedDateZone(
                data.remittanceDate
              ),
              modifiedDate: this.commonService.getFormattedDateTimeZone(
                data.modifiedDate
              ),
            };
          });
          this.paginationInfo = {
            startRecord: (this.currentPage - 1) * this.recordsPerPage + 1,
            endRecord: Math.min(this.currentPage * this.recordsPerPage, this.overallTableCount),
            overallTableCount : this.overallTableCount
          };
          this.loadInboundData=false;
        },
        (err) => {
          this.loadInboundData = false;
          const data: HttpErrorResponse = err;
          Swal.fire({
            icon: 'info',
            text: data?.error?.error?.message,
          });
        }
      );
  }
  getEOBERAReport(remittanceFrom, remittanceTo, createdFrom, createdTo) {
    let params: clsEOBERAReportSearch = {
      ltPayers: [],
      ltStatus: [],
      sCheckNumber: '',
      dAmount: null,
    };
    const pageNo = this.currentPage;
    const pageOffSet = this.pageOffset;
    this.loadInboundData = true;
    if (this.eobERaReportApiSubscription) {
      this.eobERaReportApiSubscription.unsubscribe();
    }
    this.eobERaReportApiSubscription = this.eobService
      .getEOBERAReportsPageNoAndPageOffSet(
        params,
        remittanceFrom,
        remittanceTo,
        createdFrom,
        createdTo,
        pageNo,
        pageOffSet
      )
      .subscribe(
        (response) => {
          this.overallTableCount = response?.totalCount || 0;
          this.totalPages = Math.ceil(response?.totalCount / this.pageOffset);
          this.eobData = response?.items ?? [];
          // this.menus.find(x=>x.status===this.activeTabBin).count= response?.totalCount;
          this.eobData = this.eobData.map((data) => {
            return {
              ...data,
              dtCreated: this.commonService.getFormattedDateTimeZone(
                data.dtCreated
              ),
              dtRemitance: this.commonService.getFormattedDateZone(
                data.dtRemitance
              ),
              dtModified: this.commonService.getFormattedDateTimeZone(
                data.dtModified
              ),
            };
          });
          this.paginationInfo = {
            startRecord: (this.currentPage - 1) * this.recordsPerPage + 1,
            endRecord: Math.min(this.currentPage * this.recordsPerPage, this.overallTableCount),
            overallTableCount : this.overallTableCount
          };
          this.loadInboundData = false;
        },
        (err) => {
          this.loadInboundData = false;
          const data: HttpErrorResponse = err;
          Swal.fire({
            icon: 'info',
            text: data?.error?.error?.message,
          });
        }
      );
  }

  getInvoiceTabsSummary() {
    const invoiceSummary = this.mmOrderService.getInvoiceCounts().subscribe(
      (response) => {
        this.menus.forEach((menu) => {
          switch (menu.status) {
            case nikoBillingMenuStatus.INVOICES:
              menu.count = response?.invoiceCount || 0;
              break;
            case nikoBillingMenuStatus.PAYMENTS:
              menu.count = response?.paymentCount || 0;
              break;
            case nikoBillingMenuStatus.EOB_ERA:
              menu.count = response?.eoBcount || 0;
              break;
          }
        });
      },
      (err) => {
        console.log(err);
      }
    );
    this.subscription$.push(invoiceSummary);
  }

  menus = [
    {
      status: nikoBillingMenuStatus.INVOICES,
      color: '#faa307',
      count: 0,
      arrowclass: 'unprocessed-down',
      // inboundStatusIds: this..unprocessedFax
    },
    {
      status: nikoBillingMenuStatus.PAYMENTS,
      color: '#b56576',
      count: 0,
      arrowclass: 'verification-down',
      // inboundStatusIds: this.inboundStatusIds.verification
    },
    {
      status: nikoBillingMenuStatus.EOB_ERA,
      color: '#c77dff',
      count: 0,
      arrowclass: 'precert-down',
      // inboundStatusIds: this.inboundStatusIds.preCert
    },
  ];
}
