
export const baseUrl = 'https://qsecure.ai';
export const identityUrl = 'https://identity.qsecure.ai';
export const PlatformUrl = "https://platform.qsecure.ai";
export const PatientUrl = "https://patient.qsecure.ai";
export const BillingUrl = "https://billing.qsecure.ai";
export const ClaimProcessingUrl = "https://claim.qsecure.ai";
export const OrderUrl = "https://order.qsecure.ai";
export const DropdownUrl = "https://dropdown.qsecure.ai";
export const ItemApiUrl = "https://item.qsecure.ai";
export const WarehouseUrl = 'https://warehouse.qsecure.ai';
export const OnTimeApiUrl = "https://ontime.qsecure.ai";


// export const baseUrl = 'https://demo.qsecure.ai';
// export const identityUrl = 'https://idmdemo.qsecure.ai';
// export const PlatformUrl = "https://pldemo.qsecure.ai";
// export const PatientUrl = "https://pmdemo.qsecure.ai";
// export const BillingUrl = "https://bmdemo.qsecure.ai";
// export const ClaimProcessingUrl = "https://clmdemo.qsecure.ai";
// export const OrderUrl = "https://omdemo.qsecure.ai";
// export const DropdownUrl = "https://drdemo.qsecure.ai";
// export const ItemApiUrl = "https://itmdemo.qsecure.ai";
// export const WarehouseUrl = 'https://wardemo.qsecure.ai';
// export const OnTimeApiUrl = "https://ontdemo.qsecure.ai";


// 172.16.31.50 demo.qsecure.ai
// 172.16.31.50 idmdemo.qsecure.ai
// 172.16.31.50 bmdemo.qsecure.ai
// 172.16.31.50 pmdemo.qsecure.ai
// 172.16.31.50 wardemo.qsecure.ai
// 172.16.31.50 pldemo.qsecure.ai
// 172.16.31.50 ontdemo.qsecure.ai
// 172.16.31.50 omdemo.qsecure.ai
// 172.16.31.50 itmdemo.qsecure.ai
// 172.16.31.50 drdemo.qsecure.ai
// 172.16.31.50 clmdemo.qsecure.ai

// export const OnTimeUrl = 'http://202.129.198.205:8080/OnTime/public/';
export const misReportsUrl = 'https://software1.rndsoftech.com:899/rcmreport';
export const GoogleAPIKey = 'AIzaSyBmV2SKYpRUsV522QoJCSKX_p7voacc0JI';

import { Environment } from '@abp/ng.core';
import { fontConfigType } from 'projects/shared/src/app/table.service';


// angui.peoplemailer.com          GTM-5RQ232PX
// anguistag2.peoplemailer.com     GTM-N23756S3
// anguiuat.qsecure.ai             GTM-KDF536JG
// qsecure.ai                      GTM-P828JW5P

export const environment  = {
  production: true,
  gtmId: 'G-CGTWWLEX1T', // Live
  // gtmId: 'G-P1YTY2PQYD', // UAT
  application: {
    baseUrl,
    name: 'PatientManagement',
    logoUrl: '',
  },
  oAuthConfig: {
    issuer: identityUrl,
    redirectUri: baseUrl + '/#/dashboard',
    clientId: 'PatientManagement_App',
    responseType: 'code',
    scope: 'offline_access PatientManagement',
    postLogoutRedirectUri: baseUrl,
    logoutUrl: identityUrl + '/connect/endsession',
  },
  apis: {
    default: {
      url: PatientUrl,
      rootNamespace: 'RCM.PatientManagement',
    },
    orderManagement: {
      url: OrderUrl,
      rootNamespace: 'RCM.OrderManagement',
    },
    platformManagement: {
      url: PlatformUrl,
      rootNamespace: 'RCM.OrderManagement',
    },
    AbpPermissionManagement: {
      url: PlatformUrl,
      rootNamespace: 'RCM.OrderManagement',
    },
    AbpIdentity: {
      url: PlatformUrl,
      rootNamespace: 'Volo.Abp',
    },
    AbpTenantManagement: {
      url: PlatformUrl,
      rootNamespace: 'Volo.Abp',
    },
    AbpAccount: {
      url: PlatformUrl,
      rootNamespace: 'Volo.Abp',
    },
    billingManagement: {
      url: BillingUrl,
      rootNamespace: 'RND.BillingManagement',
    },
    ClaimProcessingManagement: {
      url: ClaimProcessingUrl,
      rootNamespace: 'RND.ClaimProcessingManagement',
    },
    DropdownManagement: {
      url: DropdownUrl,
      rootNamespace: 'RCM.DropdownManagement',
    },
    OnTimeManagement: {
      url: OnTimeApiUrl,
      rootNamespace: 'RCM.OnTimeManagement',
    },
    ItemManagement: {
      url: ItemApiUrl,
      rootNamespace: 'RCM.ItemManagement',
    },
    WarehouseManagement: {
      url: WarehouseUrl,
      rootNamespace: 'RCM.WarehouseManagement',
    },
  },
} as Environment;

export const fontStyling: fontConfigType = {
  boldConfig: { font: "600 16px/28px Roboto, 'Helvetica Neue', sans-serif" },
  hConfig: {
    // h1: { font: "400 18px/20px Roboto, 'Helvetica Neue', sans-serif" },
    // h2: { font: "400 16px/20px Roboto, 'Helvetica Neue', sans-serif" },
    // h3: { font: "400 15px/20px Roboto, 'Helvetica Neue', sans-serif" },
    // h4: { font: "400 14px/20px Roboto, 'Helvetica Neue', sans-serif" },
    // h5: { font: "400 13px/20px Roboto, 'Helvetica Neue', sans-serif" },
    // h6: { font: "400 12px/20px Roboto, 'Helvetica Neue', sans-serif" },
    h1: { font: "400 16px/20px Roboto, 'Helvetica Neue', sans-serif" },
    h2: { font: "400 14px/20px Roboto, 'Helvetica Neue', sans-serif" },
    h3: { font: "400 13px/20px Roboto, 'Helvetica Neue', sans-serif" },
    h4: { font: "400 12px/20px Roboto, 'Helvetica Neue', sans-serif" },
    h5: { font: "400 11px/20px Roboto, 'Helvetica Neue', sans-serif" },
    h6: { font: "400 10px/20px Roboto, 'Helvetica Neue', sans-serif" },
  },
  normalConfig: {
    font: "400 14px / 20px Roboto, 'Helvetica Neue', sans-serif",
  },
};
