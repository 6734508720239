<div class="container-fluid">
  <div class="row bg">
    <div class="col-12">
      <div class="d-flex align-items-center justify-content-between mb-0">
        <h3 class="font-weight-bolder mb-2 mt-2">
          <b class="customThemeClass"
            >Inventory Management / Product Unit Cost Update</b
          >
        </h3>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-12">
      <mat-accordion>
        <mat-expansion-panel [expanded]="step">
          <mat-expansion-panel-header class="pl-4">
            <mat-panel-title class="header-text mt-3">
              <b>Add / Edit Product Unit Cost </b>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <mat-card-content>
            <div class="row">
              <div class="col-8">
                <form [formGroup]="productUnitCostForm">
                  <div class="row">
                    <mat-form-field class="col-4">
                      <mat-label
                        >Product <span class="asterisk">*</span></mat-label
                      >
                      <mat-select
                        class="dd-text"
                        formControlName="drpProduct"
                        #singleSelect
                        (selectionChange)="bindHCPCandUnitCost($event)"
                        (openedChange)="
                          onDropdownOpenChange($event, singleSelect.value)
                        "
                      >
                        <mat-option>
                          <ngx-mat-select-search
                            placeholderLabel="Search"
                            hideClearSearchButton="true"
                            noEntriesFoundLabel="No Matches found"
                            formControlName="txtProduct"
                            (keyup)="
                              searchProduct(
                                $event.target.value,
                                singleSelect.value
                              )
                            "
                          ></ngx-mat-select-search>
                        </mat-option>
                        <mat-option
                          *ngFor="let product of filterProduct | async"
                          [value]="product.id"
                        >
                          {{ product.productCode || "-" }} /
                          {{ product.mainProductName || "-" }}
                        </mat-option>
                      </mat-select>
                      <mat-error
                        *ngIf="
                          productUnitCostForm?.get('drpProduct')?.touched &&
                          productUnitCostForm?.get('drpProduct')?.errors
                            ?.required
                        "
                      >
                        Prodcut is a required field!
                      </mat-error>
                    </mat-form-field>

                    <mat-form-field class="col-4">
                      <mat-label>Hcpc Code</mat-label>
                      <input matInput type="text" formControlName="txtHCPC" />

                      <a
                        [class.disabled]="isEmpty(txtProductdrpId)"
                        class="pointer"
                        [matTooltip]="'Add/Remove HCPC Code'"
                        matSuffix
                        style="margin-left: 10px"
                        (click)="$event.stopPropagation(); openMdlHCPCcode()"
                      >
                        <i class="fa fa-plus iconClr" aria-hidden="true"></i>
                      </a>
                    </mat-form-field>

                    <mat-form-field class="col-4">
                      <mat-label
                        >Unit Cost ($)<span class="asterisk">*</span>
                      </mat-label>
                      <input
                        matInput
                        type="text"
                        formControlName="txtUnitCost"
                        autocomplete="off"
                        maxlength="12"
                        (input)="sanitizeInput($event, 'txtUnitCost')"
                        (keypress)="numbersAndfloatOnly($event)"
                        (paste)="onPaste($event)"
                      />

                      <mat-error
                        *ngIf="
                          productUnitCostForm
                            .get('txtUnitCost')
                            .hasError('invalidDecimal')
                        "
                        >Invalid Unit Cost</mat-error
                      >
                    </mat-form-field>
                  </div>
                </form>
              </div>
              <div class="col-4 mt-3">
                <button
                  mat-button
                  class="buttonColor mr-2"
                  [disabled]="productUnitCostForm.invalid || isSaveLoading"
                  (click)="unitCostUpdate()"
                >
                  Save/Update
                </button>
                <button
                  mat-button
                  (click)="resetForm()"
                  class="resetclr buttonColor"
                >
                  Reset
                </button>
              </div>
            </div>
          </mat-card-content>
        </mat-expansion-panel>
      </mat-accordion>

      <div class="mt-2 card">
        <div class="card-header header-text">Product Unit Cost List</div>
        <div class="card-body">
          <div class="d-flex justify-content-center" *ngIf="isLoadingTable">
            <img
              class="img-responsive pb-2 mr-1"
              src="assets/gifs/Infinity-loader.gif"
            />
          </div>
          <div *ngIf="!isLoadingTable" class="table table-responsive">
            <app-product-update-unit-cost-table
              [tableDataList]="tableDataList"
              (nevigatePage)="changePage($event)"
              [currentPage]="currentPage"
              [totalPages]="totalPages"
              [paginationInfo]="paginationInfo"
            ></app-product-update-unit-cost-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
