import { CUSTOM_ELEMENTS_SCHEMA, ModuleWithProviders, NgModule } from '@angular/core';


import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { FooterComponent } from './footer/footer.component';
import { HorizontalComponent } from './horizontal/horizontal.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { TopbarComponent } from './topbar/topbar.component';
import { VeritcalComponent } from './veritcal/veritcal.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { MatIconModule } from '@angular/material/icon';
import { MatToolbarModule } from '@angular/material/toolbar';
import { AdminModule } from 'projects/admin/src/app/app.module';
import { HorizontaltopbarComponent } from './horizontoltopbar/horizontoltopbar.component';
import { OAuthModule } from 'angular-oauth2-oidc';
import { SharedModule } from 'projects/shared/src/app/shared.module';
import { NotiifcationComponent } from './notiifcation/notiifcation.component';
import { MatBadgeModule } from '@angular/material/badge';
import { DataTablesModule } from 'angular-datatables';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';

const providers: any[] = []
@NgModule({
  declarations: [
    AppComponent,
    HorizontaltopbarComponent,
    HorizontalComponent,
    VeritcalComponent,
    TopbarComponent,
    FooterComponent,
    SidebarComponent,
    NotiifcationComponent,

  ],
  imports: [
    AppRoutingModule,
    SharedModule,
    MatInputModule,
    MatFormFieldModule,
    RouterModule,
    CommonModule,
    DataTablesModule,
    MatBadgeModule,
    NgbDropdownModule,
    MatIconModule,
    MatToolbarModule,
    AdminModule,
    AppRoutingModule,
    MatMenuModule,
    MatButtonModule,
    MatDividerModule,
    OAuthModule.forRoot()
  ],

  exports: [AppComponent, HorizontalComponent, HorizontaltopbarComponent],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule { }
@NgModule({})
export class HorizontolModule {
  static forRoot(): ModuleWithProviders<HorizontolModule> {
    return {
      ngModule: AppModule,
      providers
    };
  }
}
