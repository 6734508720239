<div class="container-fluid">
  <div class="row bg">
    <div class="col-12">
      <div class=" d-flex align-items-center justify-content-between ">
        <h3 class="mb-0 font-size-18"><b>Patient List</b></h3>
        <div class="page-title-box page-title-right  pt-2 pb-0">
          <ol class=" breadcrumb m-0">
            <li class="ml-2 mt-2 breadcrumb-item">Patient</li>
            <li class="mt-2 breadcrumb-item muted">Patient List</li>
          </ol>
        </div>
      </div>
    </div>
  </div>
  <mat-card-content>
    <!-- <div [hidden]="!isLoading"
      style="display: flex; justify-content: center; align-items: center; background: transparent; color: lightseagreen;">
      <mat-progress-bar mode="indeterminate" value='66' diameter="45" class="lightseagreen">
      </mat-progress-bar>
    </div> -->
    <form [formGroup]="patientListDataForm">
      <div class="table table-responsive">
        <table class="mat-elevation-z8" mat-table class="table" [dataSource]="dataSource" matSort>
          <ng-container matColumnDef="nameOfPatient">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              <mat-form-field class="filter">
                <input autocomplete="new-nameOfPatient" matInput formControlName="nameOfPatient" type="text" />
                <mat-placeholder class="center">Full Name</mat-placeholder>
              </mat-form-field>
            </th>
            <td mat-cell *matCellDef="let element">
              {{ element?.nameOfPatient }}
            </td>
          </ng-container>
          <ng-container matColumnDef="dateOfBirth">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              <mat-form-field class="filter">
                <mat-label> Date Of Birth</mat-label>
                <input autocomplete="new-dateOfBirth" matInput formControlName="dateOfBirth" type="text" placeholder="(MM/DD/YYYY)" />
                <mat-error
                  *ngIf=" patientListDataForm?.get('dateOfBirth')?.touched && patientListDataForm?.get('dateOfBirth')?.errors?.dateVaidator">
                  Enter Valid date
                </mat-error>
              </mat-form-field>
            </th>
            <td mat-cell *matCellDef="let element">
              <ng-container>
                {{ element?.dateOfBirth }}
              </ng-container>
            </td>
          </ng-container>



          <ng-container matColumnDef="docotrNpi">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              <mat-form-field class="filter">
                <input autocomplete="new-docotrNpi" matInput formControlName="docotrNpi" type="text" />
                <mat-placeholder class="center"> Doctor NPI</mat-placeholder>
              </mat-form-field>
            </th>
            <td mat-cell *matCellDef="let element">
              <ng-container>
                {{ element?.docotrNpi }}
              </ng-container>
            </td>
          </ng-container>
          <ng-container matColumnDef="isDoctorSigned">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              <mat-form-field class="filter">
                <input autocomplete="new-isDoctorSigned" matInput formControlName="isDoctorSigned" type="text" />
                <mat-placeholder class="center"> Is Doctor Signed</mat-placeholder>
              </mat-form-field>
            </th>
            <td mat-cell *matCellDef="let element">
              <ng-container>
                {{ element?.isDoctorSigned }}
              </ng-container>
            </td>
          </ng-container>
          <ng-container matColumnDef="complaincePercent">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              <mat-form-field class="filter">
                <input autocomplete="new-complaincePercent" matInput formControlName="complaincePercent" type="text" />
                <mat-placeholder class="center"> Complaince Percentage</mat-placeholder>
              </mat-form-field>
            </th>
            <td mat-cell *matCellDef="let element">
              <ng-container>
                {{ element?.complaincePercent }}
              </ng-container>
            </td>
          </ng-container>


          <ng-container matColumnDef="createdDate">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              <mat-form-field class="filter">
                <mat-label> Created Date</mat-label>
                <input autocomplete="new-createdDate" matInput formControlName="createdDate" type="text" placeholder="(MM/DD/YYYY)" />
                <mat-error
                  *ngIf=" patientListDataForm?.get('createdDate')?.touched && patientListDataForm?.get('createdDate')?.errors?.dateVaidator">
                  Enter Valid date
                </mat-error>
              </mat-form-field>
            </th>
            <td mat-cell *matCellDef="let element">
              <ng-container>
                {{ element?.createdDate }}
              </ng-container>
            </td>
          </ng-container>
          <ng-container matColumnDef="Options">
            <th class="verticalAlignColumn  text-center" mat-header-cell *matHeaderCellDef mat-sort-header>
              Options
            </th>
            <td class="verticalAlignColumn text-center" mat-cell *matCellDef="let element">
              <a (click)="documnetViewerSideBySide(element?.fileId)">
                <i class="fa fa-eye pointer editBtnColor mr-3" matTooltip="Click To View"></i> </a>

            </td>
          </ng-container>
          <ng-container matColumnDef="noRecords">
            <td class="text-align-center" mat-footer-cell *matFooterCellDef colspan="9">
              <div *ngIf="!isLoading">
                {{"No Records Found"}}
              </div>
              <div *ngIf="isLoading">
                {{"Processing..."}}
              </div>
            </td>
          </ng-container>
          <ng-container matColumnDef="paginator">
            <td mat-footer-cell *matFooterCellDef colspan="10">
              <mat-paginator class="table table-responsive mat-table-class" #MatPaginator
                [length]="dataSource?.data?.length" [pageSize]="10" [pageIndex]="0" showFirstLastButtons
                [pageSizeOptions]="[5, 10, 25, 100]">
              </mat-paginator>
            </td>
          </ng-container>
          <tr mat-footer-row *matFooterRowDef="['noRecords']"
            [ngClass]="{ active: dataSource && dataSource?.data && dataSource?.data?.length!==0}"></tr>
          <tr mat-header-row *matHeaderRowDef="arrDisplayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: arrDisplayedColumns"></tr>
          <tr mat-footer-row *matFooterRowDef="['paginator']"></tr>
        </table>
      </div>
    </form>
  </mat-card-content>
</div>