import { HttpErrorResponse } from '@angular/common/http';
import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { Title } from '@angular/platform-browser';
import { ClaimStatusDTO } from 'projects/patient/src/app/dropdown-proxy/dropdown-management/dropdowns/dto/billing';
import { PayorLevelDTO } from 'projects/patient/src/app/dropdown-proxy/rcm/dropdown-management/dropdowns/dto/patient';
import { DateValidator } from 'projects/shared/src/app/validations/date-validator';
import { Observable, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, finalize, map, startWith, switchMap, tap } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { ClaimEventViewTabComponent } from '../claim-event-view-tab/claim-event-view-tab.component';
import { ClaimInfoDetailsService } from '../claim-proxy/claim-processing-management/rcm/claim-processing-management/claim-optimization';
import { ClaimStatusSearchDTO } from '../claim-proxy/claim-processing-management/rcm/claim-processing-management/claim-optimization/dto/models';
import { claimStatus } from '../claim-proxy/claim-processing-management/rcm/claim-processing-management/x12/enumeration-data/claim-status.enum';
export const regexPattern: RegExp = /[`~!@#$%^&*()_|+\-=?;:'",<>\{\}\[\]\\\/]/gi;
import { MatOption } from '@angular/material/core';
import { MatSelect } from '@angular/material/select';
import { OrganizationUnitService } from 'projects/admin/src/app/admin-proxy/platform-app-management/rcm/platform-management/organization-units/organization-unit.service';
import { AddItemPaymentComponent } from '../add-item-payment/add-item-payment.component';
import { ActivatedRoute } from '@angular/router';
export const defaultGuid: string = "00000000-0000-0000-0000-000000000000";

@Component({
  selector: 'app-claim-status-tab',
  templateUrl: './claim-status-tab.component.html',
  styleUrls: ['./claim-status-tab.component.scss']
})
export class ClaimStatusTabComponent implements OnInit, OnDestroy {
  drpClaimStatus: ClaimStatusDTO[] = [];
  isLoading: boolean = true;
  strPageType: string = 'claimStatusList';
  expandedElement: any | null;
  orderId: string = '';
  organizationunitId: string = '';
  claimTableData: ClaimStatusSearchDTO[] = [];
  strPatientId: string = '';
  claimStatusForm: FormGroup;
  claimSearchForm: FormGroup;
  selectedStatus: string;
  drpPayorLevel: PayorLevelDTO[] = [];
  strPayorLevel: string = '';
  enumClaimStatus: claimStatus = claimStatus.readyToProcess;
  strClaimId: string = '';
  strInsPaymentId: string = '';
  strTitle: 'Add Insurance Payment' | 'Edit Insurance Payment' =
    'Add Insurance Payment';

  arrDisplayedColumns: string[] = [
    'option',
    'defaultClaimId',
    'defaultOrderId',
    'defaultPatientId',
    'patientName',
    'branch',
    'billedQty',
    'billedAmount',
    'insuranceName',
    'dos',
    'claimStatus',
    'postingCount',
    'wayStarClaimStatus',
    'clearingHouse',
    'claimProcessedDate',
    'providerName',
  ];
  dataSource: MatTableDataSource<any> = new MatTableDataSource<any>([]);
  organizationUnitId = null;
  @ViewChild(MatSort, { static: false }) sort: MatSort = new MatSort();
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatTable, { static: false }) tableRef: MatTable<any>;
  subscription$: Subscription[] = [];
  @ViewChild('uploadFileReference', { static: false })
  uploadFileReference: ElementRef;
  file835Strm: string[] = [];
  isImportLoading: boolean = false;
  @ViewChild('selectBranch') selectBranch: MatSelect;
  @ViewChild('selectAllBranch') private selectAllBranch: MatOption;
  branchValue : string[] =[];
  public brnachList: Observable<Branch[]> | undefined;
  public drpBranch: Branch[] = [];
  tenantId: string = "";
  branchForm: FormGroup;
  organizationUnitName: string;

  constructor(
    private titleService: Title,
    public dialog: MatDialog,
    private claimInfoDetailsService: ClaimInfoDetailsService,
    public formBuilder: FormBuilder,
    private dateValidator: DateValidator,
    private branchService : OrganizationUnitService,
    public activatedRoute: ActivatedRoute,
  ) { }

  ngOnInit(): void {
    this.tenantId = localStorage.getItem("tenantId") ?? "";
    this.claimStatusForm = this.formBuilder.group({
      drpStatus: new FormControl(''),
    });

    const activatedRoute: Subscription =
    this.activatedRoute?.paramMap?.subscribe((response) => {
      this.strClaimId = response?.get('id') ?? '';
      this.strInsPaymentId = response?.get('paymentId') ?? '';
      this.strTitle =
        this.strInsPaymentId === ''
          ? 'Add Insurance Payment'
          : 'Edit Insurance Payment';
    });
  this.subscription$?.push(activatedRoute);

    this.claimSearchForm = this.formBuilder.group({
      patientId: new FormControl(''),
      patientName: new FormControl(''),
      payerID: new FormControl(''),
      payerName: new FormControl(''),
      claimServiceDate: new FormControl('', this.dateValidator?.dateVaidator),
      billingName: new FormControl(''),
      claimNumber: new FormControl(''),
      saleOrderId: new FormControl(''),
      currentBalance: new FormControl(''),
      itemCode: new FormControl(''),
      billedAmount: new FormControl(''),
      billedQty: new FormControl(''),
      appClaimStatus: new FormControl(''),
      zirmedClaimStatus: new FormControl(''),
      remitClaimStatus: new FormControl(''),
      claimProcessedDate: new FormControl('', this.dateValidator?.dateVaidator),
    });
    this.titleService?.setTitle('Qsecure | Claim Status');
    this.setTableData([]);
    this.getSearchObs();

    this.branchForm = this.formBuilder.group({
      drpBranch : new FormControl(''),
      txtBranch : new FormControl(''),
    });

    this.getBranchList();
  }
  getSearchObs() {
    const claimSearchSub = this.claimSearchForm.valueChanges.pipe(
      startWith({
        claimNumber: "",
        saleOrderId: "",
        patientId: "",
        patientName: "",
        billedQuantity: "",
        billedAmount: "",
        payerName: "",
        providerName: "",
        currentBalance: "",
        claimServiceDate: "",
        claimStatus: "",
        wayStarClaimStatus: "",
        postingCount: "",
        claimProcessedDate: "",
      }),
      debounceTime(500),
      distinctUntilChanged(),
      filter(x => this.claimSearchForm.valid),
      map(response => {
        this.isLoading = true;

        this.setTableData([]);
        const search: claimStatusSearch = {
          defaultClaimNumber: String(response?.claimNumber ?? "")?.trim()?.toLocaleLowerCase() ?? "",
          defaultOrderNumber: String(response?.saleOrderId ?? "")?.trim()?.toLocaleLowerCase() ?? "",
          defaultPatientNumber: String(response?.patientId ?? "")?.trim()?.toLocaleLowerCase() ?? "",
          sPatientName: String(response?.patientName ?? "")?.trim()?.toLocaleLowerCase() ?? "",
          billedQuantity: String(response?.billedQty ?? "" ?? "")?.trim()?.toLocaleLowerCase() ?? "",
          billedAmount: String(response?.billedAmount ?? "" ?? "")?.trim()?.toLocaleLowerCase() ?? "",
          payerName: String(response?.payerName ?? "")?.trim()?.toLocaleLowerCase() ?? "",
          providerName: String(response?.billingName ?? "")?.trim()?.toLocaleLowerCase() ?? "",
          currentBalance: String(response?.currentBalance ?? "")?.trim()?.toLocaleLowerCase() ?? "",
          claimServiceDate: String(response?.claimServiceDate ?? "")?.trim()?.toLocaleLowerCase() ?? "",
          claimStatus: String(response?.appClaimStatus ?? "" ?? "")?.trim()?.toLocaleLowerCase() ?? "",
          wayStarClaimStatus: String(response?.zirmedClaimStatus ?? "")?.trim()?.toLocaleLowerCase() ?? "",
          postingCount: String(response?.remitClaimStatus ?? "")?.trim()?.toLocaleLowerCase() ?? "",
          claimProcessedDate: String(response?.claimProcessedDate ?? "")?.trim()?.toLocaleLowerCase() ?? "",
        };
        return search
      }),
      switchMap((x: claimStatusSearch) =>

        this.claimInfoDetailsService.claimStatusSearchByDefaultClaimNumberAndDefaultOrderNumberAndDefaultPatientNumberAndSPatientNameAndBilledQuantityAndBilledAmountAndPayerNameAndProviderNameAndCurrentBalanceAndClaimServiceDateAndClaimStatusAndWayStarClaimStatusAndPostingCountAndClaimProcessedDate(
          x?.defaultClaimNumber,
          x?.defaultOrderNumber,
          x?.defaultPatientNumber,
          x?.sPatientName,
          x?.billedQuantity,
          x?.billedAmount,
          x?.payerName,
          x?.providerName,
          x?.currentBalance,
          x?.claimServiceDate,
          x?.claimStatus,
          x?.wayStarClaimStatus,
          x?.postingCount,
          x?.claimProcessedDate
        )
      ),
      finalize(() => {
        this.isLoading = false;
      }),
    ).subscribe(response => {

      this.setTableData(response ?? []);
      this.isLoading = false;

    },
      (err) => {
        this.setTableData([]);
        this.isLoading = false;

        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      });
    this.subscription$?.push(claimSearchSub);
  }



  setTableData(response: ClaimStatusSearchDTO[] = []) {
    this.claimTableData = response;
    //!  Listed the Claim processed records in claim proces table
    this.dataSource = new MatTableDataSource(this.claimTableData);
    this.dataSource.sort = this.sort;
    this.dataSource.sort.disableClear = true;
    this.dataSource.paginator = this.paginator;
  }
  ngOnDestroy(): void {
    this.subscription$?.forEach((sub) => {
      sub && sub?.unsubscribe();
    });
  }
  //To View The Claim Statuses Modal
  openClaimHistoryModal(claimId: string) {

    const dialogRef = this.dialog.open(ClaimEventViewTabComponent, {
      data: { claimId: claimId },
    });
    const aftClosed = dialogRef.afterClosed().subscribe(
      (result) => {
        this.getSearchObs();

      },
      (err) => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      });
    this.subscription$.push(aftClosed);
  }

  allBranchSelect(){
    if(this.selectAllBranch.selected){
      this.selectBranch.options.forEach((item: MatOption) => item.select());
    }
    else{
      this.selectBranch.options.forEach((item: MatOption) => item.deselect());
    }
  }

  getBranchList(){
    const getBranchList = this.branchService.getBranchList(this.tenantId).subscribe(response => {
      response?.forEach(element => {
        this.drpBranch.push({
          id: element.id,
          organizationUnitName: element.organizationUnitName,
        })
      });

      this.brnachList = this.branchForm
        ?.get('txtBranch')
        ?.valueChanges?.pipe(
          startWith(''),
          map((value) =>
            this.drpBranch?.filter((option) =>
              option?.organizationUnitName
                ?.toLowerCase()
                ?.includes(value?.toLowerCase() ?? '')
            )
          )
        );


    }, err =>{
      const data : HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    });
    this.subscription$.push(getBranchList);
  }

  paymentItemsModalEvent(value?: string, paymentId?: string, claimId?:string) {
    if (value !== '') {
      const dialogRef = this.dialog.open(AddItemPaymentComponent, {
        data: {
          insPaymentId: defaultGuid,
          itemPaymentId: value ?? '',
          claimId: claimId?? '', //this.strClaimId,
          reasonPaymentId: paymentId,
        },
      });
      dialogRef.afterClosed().subscribe(
        () => {
          // this.getTableData();
        },
        (err) => {
          const data: HttpErrorResponse = err;
          Swal.fire({
            icon: 'info',
            text: data?.error?.error?.message,
          });
        }
      );
    } else {
      const dialogRef = this.dialog.open(AddItemPaymentComponent, {
        data: {
          insPaymentId: defaultGuid,
          itemPaymentId: value ?? '',
          claimId: claimId?? '', //this.strClaimId,
          reasonPaymentId: paymentId,
        },
      });
      dialogRef.afterClosed().subscribe(
        () => {
          // this.getTableData();
        },
        (err) => {
          const data: HttpErrorResponse = err;
          Swal.fire({
            icon: 'info',
            text: data?.error?.error?.message,
          });
        }
      );
    }
  }

}

export interface claimStatusSearch {
  defaultClaimNumber: string;
  defaultOrderNumber: string;
  defaultPatientNumber: string;
  sPatientName: string;
  billedQuantity: string;
  billedAmount: string;
  payerName: string;
  providerName: string;
  currentBalance: string;
  claimServiceDate: string;
  claimStatus: string;
  wayStarClaimStatus: string;
  postingCount: string;
  claimProcessedDate: string;
}

export interface Branch{
  id?: string;
  organizationUnitName?: string;
}
