import { PagedAndSortedResultRequestDto } from '@abp/ng.core';
import { AgmMap, CircleManager, DataLayerManager, GoogleMapsAPIWrapper, InfoWindowManager, KmlLayerManager, MapsAPILoader, MarkerManager, MouseEvent, PolygonManager, PolylineManager } from '@agm/core';
import { DatePipe } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { MatSort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { CreateUpdateOrganizationUnitDTO, SearchOrganizationUnitDTO } from 'projects/admin/src/app/admin-proxy/platform-app-management/rcm/platform-management/organization-units/dto';
import { OrganizationUnitService } from 'projects/admin/src/app/admin-proxy/platform-app-management/rcm/platform-management/organization-units/organization-unit.service';
import { TenantsService } from 'projects/admin/src/app/admin-proxy/platform-app-management/rcm/platform-management/tenant/tenants.service';
import { RegionCitiesDTO, RegionCountriesDTO, RegionStatesDTO } from 'projects/patient/src/app/dropdown-proxy/dropdown-management/dropdowns/dto/models';
import { RegionDropdownService } from 'projects/patient/src/app/dropdown-proxy/dropdown-management/dropdowns/region-dropdown-service';
import { OnTimeMasterDropdownDTO } from 'projects/patient/src/app/dropdown-proxy/rcm/dropdown-management/dropdowns/dto/on-time-schedule/models';
import { OnTimeMasterDropdownService } from 'projects/patient/src/app/dropdown-proxy/rcm/dropdown-management/dropdowns/master-dropdown-service/on-time-master-dropdown.service';
import { OnTimeDropdowns } from 'projects/patient/src/app/dropdown-proxy/rcm/dropdown-management/dropdowns/on-time-dropdowns.enum';
import { TableService } from 'projects/shared/src/app/table.service';
import { DateValidator } from 'projects/shared/src/app/validations/date-validator';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import Swal from 'sweetalert2';
import emailMask from 'text-mask-addons/dist/emailMask';

import { defaultCountry } from 'projects/shared/src/app/enums/allenums.enum';
import { AddBranchWorkHoursComponent } from '../add-branch-work-hours/add-branch-work-hours.component';
import { ToastrService } from "ngx-toastr";

// import { OrganizationUnitService } from 'projects/patient/src/app/proxy/rcm/platform-management/organization-units/organization-unit.service';
import PlaceResult = google.maps.places.PlaceResult;
import { Title } from '@angular/platform-browser';
declare const google: any
@Component({
  selector: 'app-ontime-branch',
  templateUrl: './ontime-branch.component.html',
  styleUrls: ['./ontime-branch.component.scss'],
  // providers: [DatePipe],
  providers: [
    CircleManager,
    DataLayerManager,
    DataLayerManager,
    GoogleMapsAPIWrapper,
    InfoWindowManager,
    KmlLayerManager,
    MarkerManager,
    PolygonManager,
    PolylineManager,
    DatePipe,
  ]
})
export class OntimeBranchComponent implements AfterViewInit, OnInit {



  addWorkingButton: boolean = false;
  inActiveHide: boolean = true;
  phoneCode: string = '';
  isLoading: boolean=false;
  step: boolean = false;
  BranchForm: FormGroup;
  ontimeBranchForm: FormGroup;
  BirthdateMaxDate: Date;
  BirthdateMinDate: Date;
  chosenYearDate: Date;
  desigation: string;
  displayedColumns: string[] = ['Options','BranchName', 'Address', 'Phone Number', 'type', 'Created Date', 'Status', ];
  drpCountry: RegionCountriesDTO[] = [];
  public filteredStates: Observable<RegionStatesDTO[]> | undefined;
  public filteredCities: Observable<RegionCitiesDTO[]> | undefined;
  public filteredCountries: Observable<RegionCountriesDTO[]> | undefined;
  Country: string = "";
  State: string = "";
  City: string = "";
  role: string = "";
  branch: string = "";
  tenanatId: string = "";
  branchId: string = "";
  activeStatus: string = "";
  status: boolean = false;
  drpStates: RegionStatesDTO[] = [];
  drpCities: RegionCitiesDTO[] = [];
  emailMask: any;
  @Input() clientId: string = '';
  branchTableData: any[] = [];
  saveButtonHide: boolean;
  public strPageType: string = "ontimeBranch";
  showTable: boolean = true;
  isConfirmBranchLocation: boolean = false;
  branchLocation: string = '';
  branchName: string = '';
  public latitude: number;
  public longitude: number;
  public zoom: number;
  public searchControl: FormControl;
  public lat: number;
  public long: number;
  dataSource: MatTableDataSource<any> = new MatTableDataSource<any>([]);
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatTable, { static: false }) tableRef: MatTable<any>;
  @ViewChild(AgmMap) agmmap: AgmMap;

  constructor(
    private formBuilder: FormBuilder,
    public dialog: MatDialog,
    private regionDropdownService: RegionDropdownService,
    private branchService: OrganizationUnitService,
    private Table: TableService,
    private officialService: TenantsService,
    private onTimeDropdown: OnTimeMasterDropdownService,
    private datepipe: DatePipe,
    private mapsAPILoader: MapsAPILoader,
    private dateValidator: DateValidator,
    private toastr: ToastrService,
    public title: Title,

  ) { }

  ngOnInit(): void {

    this.title.setTitle("Qsecure | Ontime Branch");
    this.Table.getTenantId().subscribe(value => {
      this.tenanatId = value;
    });
    this.BranchForm = this.formBuilder.group({
      txtDesingnation: new FormControl(),
      txtFromDate: new FormControl(),
      txtToDate: new FormControl(),
      txtEmployee: new FormControl(),
      rbtnApprovalStatus: new FormControl(),
      txtAddress: new FormControl("", [Validators.required]),
      txtPhoneNumber: new FormControl("",[Validators.required]),
      drpCountry: new FormControl("", [Validators.required]),
      drpState: new FormControl("", [Validators.required]),
      drpCity: new FormControl("",[Validators.required]),
      txtPostalCode: new FormControl("", [Validators.required]),
      txtCompanyName: new FormControl(""),
      txtAddress2: new FormControl(""),
      txtBranchName: new FormControl("", [Validators.required]),
      txtExt: new FormControl(""),
      drpCityFilter: new FormControl(""),
      txtCountryFilter: new FormControl(""),
      drpStateFilter: new FormControl(""),
      statusToggle: new FormControl(""),
      drpOffice: new FormControl("",[Validators.required])
    })
    this.ontimeBranchForm = this.formBuilder.group({
      branchName: new FormControl(""),
      address: new FormControl(""),
      phoneNumber: new FormControl(""),
      createdDate: new FormControl("",  this.dateValidator.dateVaidator),
      status: new FormControl(""),
      email: new FormControl(""),
      type: new FormControl(""),
    });
    // valueChanges for filter
    this.ontimeBranchForm.valueChanges.subscribe((value) => {
      const filter = {
        branchName: value?.branchName?.trim()?.toLowerCase(),
        address: value?.address?.trim()?.toLowerCase(),
        phoneNumber: value?.phoneNumber?.trim()?.toLowerCase(),
        createdDate: value?.createdDate?.trim()?.toLowerCase(),
        status: value?.status?.trim()?.toLowerCase(),
        email: value?.email?.trim()?.toLowerCase(),
        type: value?.type?.trim()?.toLowerCase(),
      };
      if (this.ontimeBranchForm.valid) {
        this.dataSource.filter = JSON.stringify(filter);
        this.filtertable(filter);
      }
    }, err => {
    });
    this.countryDropdown();
    this.getDropDown();
    this.Table.getclientLoginView().subscribe(response => {
      if (response?.tenantId != null && response?.roleName == 'admin' && response?.organizationUnitId == null) {
        this.clientId = response?.tenantId
        this.getCompanyName(response);
      } else {
        this.clientId != null && this.clientId != "" && this.clientId != undefined && this.getBranch()
      }
    })
    this.tenanatId !== '' &&
      this.tenanatId !== undefined &&
      this.tenanatId !== null &&
      this.getBranchTable();
    this.clientId != null && this.clientId != "" && this.clientId != undefined && this.getBranchTable()
    this.emailMask = emailMask;
  }

  ngAfterViewInit(): void {
    // this.mapsAPILoader?.load()?.then(() => {
    //   this.confirmBranchLocation();

    // });
  }

  /// dropdown datas
  drpOffice: any[] = [];
  getDropDown() {
    let onTimeDropdownInput: OnTimeDropdowns[];
    onTimeDropdownInput = [
      OnTimeDropdowns.OfficeType,
    ]
    this.onTimeDropdown.getOnTimeDropdownsByInput(onTimeDropdownInput).subscribe((stateResponse) => {
      const response: OnTimeMasterDropdownDTO = stateResponse;
      this.drpOffice = response?.officeTypes;
    }, err => {
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    });


  }


  // ! filter table search
  filtertable(filter) {


    const Date = filter.Date;
    // let DateFormat = Date === null ? "" : Intl.DateTimeFormat('en-US').format(new Date(Date));
    const searchInput: SearchOrganizationUnitDTO = {
      organizationUnitName: filter.branchName,
      emailAddress: filter.email,
      address: filter.address,
      phoneNumber: filter.phoneNumber,
      status: filter.status,
      officeType: filter.type,
      createdDate: dateYYYYMMDDtoMMDDYYYY(filter.createdDate,)
    }
    this.branchTableData = [];
    this.branchService.searchOrganizationByInput(searchInput).subscribe(data => {

      this.branchTableData = [];
      data?.items.forEach(element => {
        this.branchTableData.push({
          id: element.id,
          branchName: element?.organizationUnitName,
          address: element?.address1,
          contact: element?.phoneNumber,
          createdDate: element?.creationTime === null ? "" : this.datepipe.transform(element?.creationTime, 'MM/dd/yyyy') ?? "",
          status: element?.status,
          tenentId: element?.tenantId,
          workingHoursId: element?.workingHoursId,
          officeType: element?.officeType,
          email: element?.emailAddress
        });
      })
      this.dataSource = new MatTableDataSource(this.branchTableData);
      this.dataSource.sort = this.sort;
      setTimeout(() => this.dataSource.paginator = this.paginator);
    }, err => {
      this.dataSource = new MatTableDataSource(this.branchTableData);
      this.dataSource.sort = this.sort;
      setTimeout(() => this.dataSource.paginator = this.paginator);
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    });
  }

  saveOnTimeBranch() {
    this.getenablefields();

    const branch: CreateUpdateOrganizationUnitDTO = {
      organizationUnitName: this.BranchForm.value.txtBranchName,
      emailAddress: this.BranchForm.value.txtEmail,
      // address: this.BranchForm.value.txtAddress,
      phoneNumber: this.BranchForm.value.txtPhoneNumber,
      tenantId: this.tenanatId,
      address1: this.BranchForm.value.txtAddress,
      address2: this.BranchForm.value.txtAddress2,
      country: this.BranchForm.value.drpCountry,
      state: this.BranchForm.value.drpState,
      city: this.BranchForm.value.drpCity,
      zipcode: this.BranchForm.value.txtPostalCode,
      workingHoursId: "00000000-0000-0000-0000-000000000000",
      status: this.BranchForm.value.statusToggle === true ? "Active" : "Inactive",
      officeType: this.BranchForm.value.drpOffice,

    };
    if ((this.branchId == null || this.branchId == "" || this.branchId == undefined)) {

      this.branchService.create(branch).subscribe(response => {
        this.toastr.success('Saved Successfully','Success');
        // Swal.fire({ title: 'Success', html: 'Saved Successfully', icon: 'success', timer: 3000, timerProgressBar: true }).then(result => {

        // }, err => { });
        this.reset();
        this.getBranchTable();
      }, err => {
        this.saveButtonHide = false;
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      });
    }
    else {

      this.branchService.update(this.branchId, branch).subscribe(response => {
        this.toastr.success('Updated Successfully','Success')
        // Swal.fire({ title: 'Success', html: 'Saved Successfully', icon: 'success', timer: 3000, timerProgressBar: true }).then(result => {

        this.saveButtonHide = false;
        // }, err => { });
        this.reset();
        this.getBranchTable();
      }, err => {
        this.saveButtonHide = false;
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      });
    }
  }

  reset() {
    this.isConfirmBranchLocation = false;
    this.BranchForm.patchValue({
      txtBranchName: "",
      txtEmail: "",
      txtAddress: "",
      txtAddress2: '',
      txtPhoneNumber: '',
      drpCountry: '',
      drpState: '',
      drpCity: '',
      zipCode: '',
      statusToggle: '',
    });
    this.branchId = '';
    this.status = false;
    this.BranchForm.reset();
    this.BranchForm.markAsUntouched();
    this.BranchForm.updateValueAndValidity();
    // this.BranchForm.controls['txtBranchName'].enable();
    // this.BranchForm.controls['txtAddress'].enable()
    // this.BranchForm.controls['txtAddress2'].enable()
    // this.BranchForm.controls['drpCountry'].enable()
    // this.BranchForm.controls['drpState'].enable()
    // this.BranchForm.controls['drpCity'].enable()
    // this.BranchForm.controls['txtPostalCode'].enable()
    // this.BranchForm.controls['txtPhoneNumber'].enable()
    this.addWorkingButton = false
  }


  clearDesigation() {
    this.desigation = '';
    this.BranchForm.patchValue({ txtDesingnation: '' });
  }



  // ! branch table list
  getBranchTable() {
    this.isLoading = true;
    this.branchService.getOrganizationList(this.tenanatId).subscribe(response => {
      this.branchTableData = [];
      response && response?.forEach(element => {
        this.branchTableData.push({
          id: element.id,
          branchName: element?.organizationUnitName,
          address: element?.address1,
          address2: element?.address2,
          contact: element?.phoneNumber,
          createdDate: element?.creationTime === null ? "" : this.datepipe.transform(element?.creationTime, 'MM/dd/yyyy') ?? "",
          status: element?.status,
          tenentId: element?.tenantId,
          workingHoursId: element?.workingHoursId,
          officeType: element?.officeType,
          email: element?.emailAddress,
          country: element?.country,
          state: element?.state,
          city: element?.city,
          zipCode: element?.zipcode

        });
      })
      const arrTableData = this.branchTableData;
      this.dataSource = new MatTableDataSource(arrTableData);
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
      this.isLoading = false;
    }, err => {
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    })
  }

  statusChange(event: MatSlideToggleChange, data: any) {
   // this.status = event.checked == true ? "Active" : "Inactive";
    if(event.checked == true){
      Swal.fire({
        title: 'Do you want to activate?',
        text: 'By Changing Status, this branch will be activated',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#34c38f',
        cancelButtonColor: '#f46a6a',
        confirmButtonText: 'Yes, Activate it!'
      }).then(result => {
        if (result.value) {
          const branch: CreateUpdateOrganizationUnitDTO = {
            organizationUnitName: data?.branchName,
            emailAddress: data?.email,
            phoneNumber: data?.contact,
            tenantId: data?.tenentId,
            address1: data?.address,
            address2: data?.address2,
            country: data?.country,
            state: data?.state,
            city: data?.city,
            zipcode: data?.zipCode,
            workingHoursId: data?.workingHoursId,
            status:  "Active" ,
            officeType: data?.officeType
          };
          if (data?.id && data?.id !== "" && data?.id !== null && data?.id !== undefined) {
            this.branchService.update(data?.id, branch).subscribe(response => {
              // Swal.fire({ title: 'Success', html: 'Activated Successfully', icon: 'success', timer: 3000, timerProgressBar: true })
              this.toastr.success('Activated Successfully','Success')
              this.getBranchTable();
            }, err => {
              const data: HttpErrorResponse = err;
              Swal.fire({
                icon: 'info',
                text: data?.error?.error?.message,
              });
            });
          }
        }
        else{
          this.getBranchTable();
        }
      });
    }
    else
    if(event.checked == false){
      Swal.fire({
        title: 'Do you want to deactivate?',
        text: 'By Changing Status, this branch will be deactivated',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#34c38f',
        cancelButtonColor: '#f46a6a',
        confirmButtonText: 'Yes, deactivate it!'
      }).then(result => {
        if (result.value) {
          const branch: CreateUpdateOrganizationUnitDTO = {
            organizationUnitName: data?.branchName,
            emailAddress: data?.email,
            phoneNumber: data?.contact,
            tenantId: data?.tenentId,
            address1: data?.address,
            address2: data?.address2,
            country: data?.country,
            state: data?.state,
            city: data?.city,
            zipcode: data?.zipCode,
            workingHoursId: data?.workingHoursId,
            status:  "Inactive" ,
            officeType: data?.officeType
          };
          if (data?.id && data?.id !== "" && data?.id !== null && data?.id !== undefined) {
            this.branchService.update(data?.id, branch).subscribe(response => {
              // Swal.fire({ title: 'Success', html: 'Deactivated Successfully', icon: 'success', timer: 3000, timerProgressBar: true })
              this.toastr.success('Deactivated Successfully','Success')
              this.getBranchTable();
            }, err => {
              const data: HttpErrorResponse = err;
              Swal.fire({
                icon: 'info',
                text: data?.error?.error?.message,
              });
            });
          }

        }
        else{
          this.getBranchTable();
        }
      });
    }
  }

  wrokingbranchId: string;
  BranchName: string;
  ViewClient(event) {
    this.status = true;
    this.branchId = event.id
    this.wrokingbranchId = event.workingHoursId
    this.step = !this.step;
    this.isConfirmBranchLocation = false;
    this.getBranchById(event.id)
  }

  //
  openAddWorkingModel() {
    // const dialogRef = this.dialog.open(BranchWorkHoursComponent, {
    //   disableClose: true,
    //   data: { branchId: this.branchId, wrokingbranchId: this.wrokingbranchId }
    // });
    const dialogRef = this.dialog.open(AddBranchWorkHoursComponent, {
      // height: "80%", width:"80%",
      disableClose: true,
      data: { branchId: this.branchId, wrokingbranchId: this.wrokingbranchId, BranchName: this.branchName }
    });
    dialogRef.afterClosed().subscribe(() => {
    });
    this.reset()
  }
  stateName: string = "";
  CountryName: string = "";
  // ! get branch by id
  getBranchById(id: string) {
    this.addWorkingButton = false
    this.branchService.get(id).subscribe(response => {
      this.wrokingbranchId = response.workingHoursId
      this.activeStatus = response.status;
      this.stateName = response?.state;
      this.CountryName = response?.country;
      this.branchName = response?.organizationUnitName;

      this.CountryName != null && this.CountryName !== "" && this.CountryName != undefined &&
        this.onChangeState(this.CountryName) && this.BranchForm.patchValue({
          drpCountry: this.CountryName,
        });
      this.stateName != null && this.stateName !== "" && this.stateName != undefined &&
        this.onChangeCity(this.stateName, this.CountryName) && this.BranchForm.patchValue({
          drpState: this.stateName,
          drpCity: response?.city
        });
      this.branchId = response?.id ?? "";

      if (response?.status == "Active") {

        this.BranchForm.patchValue({
          txtBranchName: response?.organizationUnitName,
          txtAddress: response?.address1,
          txtAddress2: response?.address2,
          txtEmail: response?.emailAddress,
          txtPhoneNumber: response?.phoneNumber,
          drpCountry: response?.country,
          drpState: response?.state,
          drpCity: response?.city,
          txtPostalCode: response?.zipcode,
          statusToggle: response?.status == "Active" ? true : false,
          drpOffice: response?.officeType
        })
        // this.getenablefields();
      }
      if (response?.status == "Inactive") {

        this.BranchForm.patchValue({
          txtBranchName: response?.organizationUnitName,
          txtAddress: response?.address1,
          txtAddress2: response?.address2,
          txtEmail: response?.emailAddress,
          txtPhoneNumber: response?.phoneNumber,
          drpCountry: response?.country,
          drpState: response?.state,
          drpCity: response?.city,
          txtPostalCode: response?.zipcode,
          statusToggle: response?.status == "Inactive" ? false : true,
          drpOffice: response?.officeType
        })
        // this.getdisablefields();


      }
    })
  }

  getdisablefields() {
    this.BranchForm.controls.txtBranchName.disable()
    this.BranchForm.controls.txtAddress.disable()
    this.BranchForm.controls.txtAddress2.disable()
    this.BranchForm.controls.drpCountry.disable()
    this.BranchForm.controls.drpState.disable()
    this.BranchForm.controls.drpCity.disable()
    this.BranchForm.controls.txtPostalCode.disable()
    this.BranchForm.controls.txtPhoneNumber.disable()
    this.BranchForm.controls.drpOffice.disable()
  }

  getenablefields() {
    this.BranchForm.controls.txtBranchName.enable();
    this.BranchForm.controls.txtAddress.enable()
    this.BranchForm.controls.txtAddress2.enable()
    this.BranchForm.controls.drpCountry.enable()
    this.BranchForm.controls.drpState.enable()
    this.BranchForm.controls.drpCity.enable()
    this.BranchForm.controls.txtPostalCode.enable()
    this.BranchForm.controls.txtPhoneNumber.enable()
    this.BranchForm.controls.drpOffice.enable()
  }
  // ! in active popup
  activeChange(event: MatSlideToggleChange) {

    if (event.checked == true) {
      this.activeStatus = "Active"
      this.getenablefields();
    }
    if (event.checked === false) {
      Swal.fire({
        title: 'Are you sure you want to Inactive?',
        // text: 'You won\'t be able to retrieve this data!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#34c38f',
        cancelButtonColor: '#f46a6a',
        confirmButtonText: 'Yes!'
      }).then(result => {


        if (result.isConfirmed === true) {
          this.BranchForm.patchValue({
            statusToggle: false
          })
          this.activeStatus = "Inactive"
          this.getdisablefields();
        }
        if (result.isConfirmed === false) {
          this.BranchForm.patchValue({
            statusToggle: true
          })
          this.getenablefields();
        }
      });
    }
  }

  deleteBranch($event) {
    Swal.fire({
      title: 'Are you sure you want to Delete?',
      text: 'You won\'t be able to retrieve this data!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#34c38f',
      cancelButtonColor: '#f46a6a',
      confirmButtonText: 'Yes, delete it!'
    }).then(result => {
      if (result.value) {
        this.branchService.deleteOrganization($event.id).subscribe(response => {
          this.branchTableData.forEach(element => {
            if ($event.id == element?.id) {
              const index = this.branchTableData.indexOf(element, 0)
              this.branchTableData.splice(index, 1)
            }
          });
          // this.Table.setBranchListTable(this.branchTableData);
          this.dataSource = new MatTableDataSource(this.branchTableData);
          // this.resetUsers();
        }, err => {
          const data: HttpErrorResponse = err;

          Swal.fire({
            icon: 'info',
            text: data?.error?.error?.message,
          });
        });
      }
    });
    this.reset();
  }

  // Get Country Dropdown
  countryDropdown() {
    this.BranchForm.patchValue({ "drpCountry": defaultCountry.US })
    this.onChangeState(defaultCountry.US);
    this.Country = defaultCountry.US;
    this.drpCountry = [];
    this.regionDropdownService.getAllCountriesByInput(new PagedAndSortedResultRequestDto).subscribe(response => {
      //
      this.drpCountry = response?.items.filter(x => x.countryShortName == "US") ?? [];
      this.filteredCountries = this.BranchForm?.get("txtCountryFilter")?.valueChanges
        .pipe(
          startWith(''),
          map(value => this.drpCountry.filter(option => option?.countryName?.toLowerCase()?.includes(value?.toLowerCase() ?? "")))
        );
    }, err => {
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    });
    this.getBranchTable();
  }
  // On Change State Dropdown
  onChangeState(value: string | null | undefined): boolean {
    this.BranchForm.patchValue({
      drpState: null,
      drpCity: null
    })
    this.drpStates = [];
    this.drpCities = [];
    value !== null && value !== undefined && this.regionDropdownService.getAllStatesByCountryNameByCountryShortName(value).subscribe(response => {

      this.drpStates = response?.items ?? [];

      if (response?.items?.length=== 0) {
        this.phoneCode = "";
        this.BranchForm.controls.txtPhoneNumber.disable();
      } else {
        this.BranchForm.controls.txtPhoneNumber.enable();
        this.phoneCode = this.drpStates[0]?.countryPhoneCode ?? "";
      }
      this.filteredStates = this.BranchForm?.get("drpStateFilter")?.valueChanges
        .pipe(
          startWith(''),
          map(value => this.drpStates.filter(option =>
            option?.stateName?.toLowerCase()?.includes(value?.toLowerCase() ?? "")
          ))
        );
    }, err => {
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    });
    return this.drpStates !== [] && this.drpStates.length !== 0;
  }
  // On Change City Dropdown

  onChangeCity(state: string | null | undefined, country: string): boolean {
    this.BranchForm.patchValue({
      drpCity: null
    });
    country = (country == "") ? (this.BranchForm.value.drpCountry == (null || undefined) ? "" : (this.BranchForm.value.drpCountry)) : country;
    this.drpCities = [];
    state !== null && state !== undefined && this.regionDropdownService.getAllCitiesByCountryAndStateNameByCountryShortNameAndStateShortName(country, state).subscribe(response => {

      this.drpCities = response?.items ?? [];

      this.filteredCities = this.BranchForm?.get("drpCityFilter")?.valueChanges
        .pipe(
          startWith(''),
          map(value => this.drpCities.filter(option => option?.cityName?.toLowerCase()?.includes(value?.toLowerCase() ?? "")))
        );
    }, err => {
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    });
    return this.drpCities !== [] && this.drpCities.length !== 0;
  }


  clearCountry() {
    // this.OnTimeUserForm.controls['txtPhnNo'].disable();
    this.Country = "";
    this.BranchForm.patchValue({ drpCountry: "" })
  }

  clearState() {
    this.State = "";
    this.BranchForm.patchValue({ drpState: "" })
  }

  clearCity() {
    this.City = "";
    this.BranchForm.patchValue({ drpCity: "" })
  }

  clearRole() {
    this.role = '';
    this.BranchForm.patchValue({ txtRole: '' });
  }

  clearBranch() {
    this.branch = '';
    this.BranchForm.patchValue({ txtBranchName: '' });
  }

  // ! get official name by client
  getCompanyName(value: { tenantName: any; }) {
    this.BranchForm.controls.txtCompanyName.disable()
    this.BranchForm.patchValue({
      txtCompanyName: value?.tenantName,
    })
  }

  // ! get official name in super admin
  getBranch() {
    this.BranchForm.controls.txtCompanyName.disable()
    this.officialService.getTenantInformationById(this.clientId).subscribe(response => {
      this.BranchForm.patchValue({
        txtCompanyName: response?.tenantName,
      })
      if (response?.status == "InActive") {
        this.inActiveHide = false
        this.showTable = false
      }
    })
  }


  // Confirm Branch Location in Map
  confirmBranchLocation() {
    this.isConfirmBranchLocation = true;
    this.branchLocation = '';
    this.branchName = this.BranchForm.value.txtBranchName;
    this.BranchForm.value.txtAddress2 = this.BranchForm.value.txtAddress2 == null || undefined ? " " : this.BranchForm.value.txtAddress2;
    this.BranchForm.value.drpCity = this.BranchForm.value.drpCity == null ? " " : this.BranchForm.value.drpCity;
    this.mapsAPILoader?.load()?.then(() => {
      const geocoder = new google.maps.Geocoder();
      this.branchLocation = this.branchLocation.concat(
        this.BranchForm.value.txtAddress + ' ' +
        this.BranchForm.value.txtAddress2 + ' ' +
        this.BranchForm.value.drpState + ' ' +
        this.BranchForm.value.drpCountry + ' ' +
        this.BranchForm.value.drpCity + ' ' +
        this.BranchForm.value.txtPostalCode).trim();
      geocoder?.geocode({ address: this.branchLocation }, (results, status) => {
        if (status == google.maps.GeocoderStatus.OK) {
          this.latitude = results[0].geometry.location.lat(),
            this.longitude = results[0].geometry.location.lng()
        }
        else {
        }
      });
    });
  }

  mapClicked($event: MouseEvent) {
    // this.markers.push({
    //   lat: $event.coords.lat,
    //   lng: $event.coords.lng,
    //   draggable: true,
    //   content: "InfoWindow content",
    //   iconUrl: "http://maps.google.com/mapfiles/ms/micons/blue-pushpin.png"
    // });
  }



  markers: marker[] = [

  ]

  // markerDragEnd( $event: MouseEvent) {
  //       geocoder?.geocode({ location: { lat: this.latitude, lng: this.longitude} }, (results, status) => {
  //         if (status == google.maps.GeocoderStatus.OK) {
  //           this.latitude = results[0].geometry.location.lat(),
  //             this.longitude = results[0].geometry.location.lng();
  //         }
  //       });
  //     });
  //     this.markers.push({ lat: this.latitude, lng: this.longitude, draggable: true })
  //   }
  // }


  markerDragEnd($event: MouseEvent) {
    this.latitude = $event.coords.lat,
      this.longitude = $event.coords.lng

    this.markers.push({
      lat: $event.coords.lat,
      lng: $event.coords.lng,
      draggable: true,
      visible: false,
      opacity: 0.4
    });
  }






  clickedMarker(label: string, index: number) {
  }
}
function dateYYYYMMDDtoMMDDYYYY(strDate: string): string {
  if (strDate && strDate !== "" && typeof strDate === 'string' && strDate !== null && strDate !== undefined && strDate.length >= 1 && !isNaN(new Date(strDate).getTime())) {
    const latest_date = Intl.DateTimeFormat('en-US')?.format(new Date(strDate))
    return latest_date;
  }
  return "";
}

interface marker {

  lat?: number;
  lng?: number;
  label?: string;
  draggable?: boolean;
  visible?: boolean;
  opacity?: number
}
