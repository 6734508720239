<mat-dialog-content class="mat-typography">
    <div class="modal-header">
        <h1 class="modal-title">Patient List</h1>
        <!-- <i aria-label="Close" mat-dialog-close class="fa fa-times"></i> -->
        <mat-icon mat-dialog-close>close</mat-icon>
    </div>

<div class="row">
    <div class="col-lg-12">
        <div class="card card-body">
            <!-- Loader Section -->
            <div class="row" *ngIf="isLoading">
                <div class="col-12">
                    <div class="d-flex justify-content-center" *ngIf="isLoading">
                        <img class="img-responsive pb-2 mr-1" src="assets/gifs/Infinity-loader.gif" />
                    </div>
                </div>
            </div>
            <div class="table-responsive" *ngIf="!isLoading">
            <app-practice-view-patient-list-table [patinetTableData]="patinetListDate"></app-practice-view-patient-list-table>
            </div>
        </div>
    </div>
</div>
</mat-dialog-content>