<div class="account-pages my-5 pt-5">
  <div class="container">
      <div class="row">
          <div class="col-lg-12">
              <div class="text-center mb-5">
                  <h2 class="text-uppercase font-weight-bold">Sorry !! page not found</h2>
                  <div class="mt-5 text-center">
                    <button mat-button class="buttonColor font-size-16" (click)="backToDashboard()">
                      <i class="fa fa-backward mr-1" ></i>
                      Back to Dashboard
                    </button>
                  </div>
              </div>
          </div>
      </div>
  </div>
</div>
