<br />
<div class="card card-body">
<!-- <div class="list-box"> -->
<form [formGroup]="insuranceForm">

  <div class="row" formGroupName="insurancePrimary">
    <div class="col-lg-12">
      <div class="row">
        <div class="col-md-6">
          <h4 class="customThemeClass"><b>Primary</b></h4>
        </div>
      </div>
      <mat-card-content>
        <div class="row">
          <mat-form-field class="col-3">
            <mat-label>Policy</mat-label>
            <input autocomplete="new-txtPrimaryPolicy" matInput formControlName="txtPrimaryPolicy"  type="text">
          </mat-form-field>
          <mat-form-field class="col-3">
            <mat-label> Pay Pct</mat-label>
            <input autocomplete="new-txtPrimaryPaypct" matInput mask="percent" suffix="%" formControlName="txtPrimaryPaypct" type="text">
            <mat-error *ngIf="
                    insuranceForm?.get('insurancePrimary.txtPrimaryPaypct')?.touched &&
                    insuranceForm?.get('insurancePrimary.txtPrimaryPaypct')?.errors
                  ">
              Enter Minimum 2 Digits!
            </mat-error>
          </mat-form-field>
          <mat-form-field class="col-3">
            <mat-label>Box 10d</mat-label>
            <input autocomplete="new-txtPrimaryBox10d" matInput formControlName="txtPrimaryBox10d" maxlength="40" type="text">
          </mat-form-field>
          <mat-form-field class="col-3">
            <mat-label>Box 19</mat-label>
            <input autocomplete="new-txtPrimaryBox19" matInput formControlName="txtPrimaryBox19" maxlength="40" type="text">
          </mat-form-field>
          <mat-form-field class="col-3">
            <mat-label>Box 24Ia</mat-label>
            <input autocomplete="new-txtPrimaryBox24Ia" matInput formControlName="txtPrimaryBox24Ia" maxlength="40" type="text">
          </mat-form-field>
          <mat-form-field class="col-3">
            <mat-label>Box 24Ja</mat-label>
            <input autocomplete="new-txtPrimaryBox24Ja" matInput formControlName="txtPrimaryBox24Ja" maxlength="40" type="text">
          </mat-form-field>
          <mat-form-field class="col-3">
            <mat-label>Box 24Jb</mat-label>
            <input autocomplete="new-txtPrimaryBox24Jb" matInput formControlName="txtPrimaryBox24Jb" maxlength="40" type="text">
          </mat-form-field>
          <div class="col-md-3">
            <br>
            <mat-checkbox formControlName="chkPrimaryIncludeBox24jb">Include Box 24jb</mat-checkbox>
          </div>
          <div class="col-md-4">
            <br>
            <mat-checkbox formControlName="chkPrimarySaleorder">Include this Payor Level on Sales Order</mat-checkbox>
          </div>
          <div class="col-md-4">
            <br>
            <mat-checkbox formControlName="chkPrimaryBeforeBill">Wait for Previous Payor before Billing</mat-checkbox>
          </div>
          <div class="col-md-4">
            <br>
            <mat-checkbox formControlName="chkPrimaryPayPct">Pay Pct equal to 0%</mat-checkbox>
          </div>
        </div>
      </mat-card-content>
    </div>
  </div>

  <br>
  <div class="row" formGroupName="insuranceSecondary">
    <div class="col-lg-12">
      <div class="row">
        <div class="col-md-6">
          <h4 class="customThemeClass"><b>Secondary</b></h4>
        </div>
      </div>
      <mat-card-content>
        <div class="row">
          <mat-form-field class="col-3">
            <mat-label>Policy</mat-label>
            <input autocomplete="new-txtSecondaryPolicy" matInput  formControlName="txtSecondaryPolicy" type="text">
          </mat-form-field>
          <mat-form-field class="col-3">
            <mat-label> Pay Pct</mat-label>
            <input autocomplete="new-txtSecondaryPct" matInput mask="percent" suffix="%" formControlName="txtSecondaryPct" type="text">
            <mat-error *ngIf="
                    insuranceForm?.get('insuranceSecondary.txtSecondaryPct')?.touched &&
                    insuranceForm?.get('insuranceSecondary.txtSecondaryPct')?.errors
                  ">
              Enter Minimum 2 Digits!
            </mat-error>
          </mat-form-field>
          <mat-form-field class="col-3">
            <mat-label>Box 10d</mat-label>
            <input autocomplete="new-txtSecondaryBox10" matInput maxlength="40" formControlName="txtSecondaryBox10" type="text">
          </mat-form-field>
          <mat-form-field class="col-3">
            <mat-label>Box 19</mat-label>
            <input autocomplete="new-txtSecondaryBox19" matInput maxlength="40" formControlName="txtSecondaryBox19" type="text">
          </mat-form-field>
          <mat-form-field class="col-3">
            <mat-label>Box 24Ia</mat-label>
            <input autocomplete="new-txtSecondaryBox24Ia" matInput maxlength="40" formControlName="txtSecondaryBox24Ia" type="text">
          </mat-form-field>
          <mat-form-field class="col-3">
            <mat-label>Box 24Ja</mat-label>
            <input autocomplete="new-txtSecondaryBox24Ja" matInput maxlength="40" formControlName="txtSecondaryBox24Ja" type="text">
          </mat-form-field>
          <mat-form-field class="col-3">
            <mat-label>Box 24Jb</mat-label>
            <input autocomplete="new-txtSecondaryBox24Jb" matInput maxlength="40" formControlName="txtSecondaryBox24Jb" type="text">
          </mat-form-field>
          <div class="col-md-3">
            <br>
            <mat-checkbox formControlName="chkSecondaryBox24Jb">Include Box 24jb</mat-checkbox>
          </div>
          <div class="col-md-4">
            <br>
            <mat-checkbox formControlName="chkSecondarySale">Include this Payor Level on Sales Order</mat-checkbox>
          </div>
          <div class="col-md-4">
            <br>
            <mat-checkbox formControlName="chkSecondaryBeforeBill">Wait for Previous Payor before Billing</mat-checkbox>
          </div>
          <div class="col-md-4">
            <br>
            <mat-checkbox formControlName="chkSecondaryPct">Pay Pct equal to 0%</mat-checkbox>
          </div>
        </div>
      </mat-card-content>
    </div>
  </div>

  <br>
  <div class="row" formGroupName="insuranceTertiary">
    <div class="col-lg-12">
      <div class="row">
        <div class="col-md-6">
          <h4 class="customThemeClass"><b>Tertiary</b></h4>
        </div>
      </div>
      <mat-card-content>
        <div class="row">
          <mat-form-field class="col-3">
            <mat-label>Policy</mat-label>
            <input autocomplete="new-txtTertiaryPolicy" matInput  formControlName="txtTertiaryPolicy" type="text">
          </mat-form-field>
          <mat-form-field class="col-3">
            <mat-label> Pay Pct</mat-label>
            <input autocomplete="new-txtTertiaryPct" matInput mask="percent" suffix="%" formControlName="txtTertiaryPct" type="text">
            <mat-error *ngIf="
                    insuranceForm?.get('insuranceTertiary.txtTertiaryPct')?.touched &&
                    insuranceForm?.get('insuranceTertiary.txtTertiaryPct')?.errors
                  ">
              Enter Minimum 2 Digits!
            </mat-error>
          </mat-form-field>
          <mat-form-field class="col-3">
            <mat-label>Box 10d</mat-label>
            <input autocomplete="new-txtTertiaryBox10" matInput maxlength="40" formControlName="txtTertiaryBox10" type="text">
          </mat-form-field>
          <mat-form-field class="col-3">
            <mat-label>Box 19</mat-label>
            <input autocomplete="new-txtTertiaryBox19" matInput maxlength="40" formControlName="txtTertiaryBox19" type="text">
          </mat-form-field>
          <mat-form-field class="col-3">
            <mat-label>Box 24Ia</mat-label>
            <input autocomplete="new-txtTertiaryBox24Ia" matInput maxlength="40" formControlName="txtTertiaryBox24Ia" type="text">
          </mat-form-field>
          <mat-form-field class="col-3">
            <mat-label>Box 24Ja</mat-label>
            <input autocomplete="new-txtTertiaryBox24Ja" matInput maxlength="40" formControlName="txtTertiaryBox24Ja" type="text">
          </mat-form-field>
          <mat-form-field class="col-3">
            <mat-label>Box 24Jb</mat-label>
            <input autocomplete="new-txtTertiaryBox24Jb" matInput maxlength="40" formControlName="txtTertiaryBox24Jb" type="text">
          </mat-form-field>
          <div class="col-md-3">
            <br>
            <mat-checkbox formControlName="chkTertiaryInclude">Include Box 24jb</mat-checkbox>
          </div>
          <div class="col-md-4">
            <br>
            <mat-checkbox formControlName="chkTertiarySale">Include this Payor Level on Sales Order</mat-checkbox>
          </div>
          <div class="col-md-4">
            <br>
            <mat-checkbox formControlName="chkTertiaryBeforeBill">Wait for Previous Payor before Billing</mat-checkbox>
          </div>
          <div class="col-md-4">
            <br>
            <mat-checkbox formControlName="chkTertiaryPayPct">Pay Pct equal to 0%</mat-checkbox>
          </div>
        </div>
      </mat-card-content>
    </div>
  </div>

  <br>
  <div class="row" formGroupName="insuranceVerification">
    <div class="col-lg-6">
      <div class="row">
        <div class="col-md-6">
          <h4 class="customThemeClass"><b>Verification</b></h4>
        </div>
      </div>
      <mat-card-content>
        <div class="row">
          <div class="col-md-6">
            <br>
            <mat-checkbox [disabled]="isDisabled" formControlName="chkVerificationInsurance">Insurance Verified
            </mat-checkbox>
          </div>
          <div class="col-md-6">
            <br>
            <mat-checkbox formControlName="chkVerificationCoverage">Coverage Verified</mat-checkbox>
          </div>
        </div>
      </mat-card-content>
    </div>
    <div class="col-lg-6">
      <div class="row">
        <div class="col-md-6">
          <h4 class="customThemeClass"><b>Exclude From Eligibility check</b></h4>
        </div>
      </div>
      <mat-card-content>
        <div class="row">
          <div class="col-md-6">
            <br>
            <mat-checkbox formControlName="chkVerificationExclude">Exclude?</mat-checkbox>
          </div>

        </div>
      </mat-card-content>
    </div>
    <!-- <div class="col-lg-4">
        <div class="row">
            <div class="col-md-6">
              <h4 class="customThemeClass"><b>Patient</b></h4>
            </div>
          </div>
        <mat-card-content>
            <div class="row">
                <mat-form-field class="col-12">
                    <mat-label>Pay Pct</mat-label>
                    <input matInput type="text">
                </mat-form-field>
                <div class="col-md-12">
                    <br>
                    <mat-checkbox>Include this Payor Level on Sales Order</mat-checkbox>
                </div>
                <div class="col-md-12">
                    <br>
                    <mat-checkbox>Wait for Previous payor before Billing</mat-checkbox>
                </div>

            </div>
        </mat-card-content>
    </div> -->
  </div>

  <br>
  <div class="row" formGroupName="insurancePatient">

    <div class="col-lg-12">
      <div class="row">
        <div class="col-md-6">
          <h4 class="customThemeClass"><b>Patient</b></h4>
        </div>
      </div>
      <mat-card-content>
        <div class="row">
          <mat-form-field class="col-3">
            <mat-label>Pay Pct</mat-label>
            <input autocomplete="new-txtPatientPct" matInput mask="percent" suffix="%" formControlName="txtPatientPct" type="text">
            <mat-error *ngIf="
                     insuranceForm?.get('insurancePatient.txtPatientPct')?.touched &&
                     insuranceForm?.get('insurancePatient.txtPatientPct')?.errors
                   ">
              Enter Minimum 2 Digits!
            </mat-error>
          </mat-form-field>
          <div class="col-md-4">
            <br>
            <mat-checkbox formControlName="chkPatientSale">Include this Payor Level on Sales Order</mat-checkbox>
          </div>
          <div class="col-md-3">
            <br>
            <mat-checkbox formControlName="chkPatientBill">Wait for Previous payor before Billing</mat-checkbox>
          </div>

        </div>
      </mat-card-content>
    </div>
  </div>

  <br>
  <div class="row" formGroupName="insuranceCompensation1">

    <div class="col-lg-12">
      <div class="row">
        <div class="col-md-6">
          <h4 class="customThemeClass"><b>Workers Compensation</b></h4>
        </div>
      </div>
      <mat-card-content>
        <div class="row">


          <div class="col-md-3">
            <br>
            <mat-checkbox formControlName="chkCompensation1Auto">Injury Related to Auto Accident</mat-checkbox>
          </div>
          <div class="col-md-4">
            <br>
            <mat-checkbox formControlName="chkCompensation1Other">Injury Related to Other Accident</mat-checkbox>
          </div>
          <div class="col-md-5">
            <br>
            <mat-checkbox formControlName="chkCompensation1Emp">Injury Related to Employment(Current or Previous)
            </mat-checkbox>
          </div>
          <mat-form-field class="col-3">
            <mat-label>Date of Onset</mat-label>
            <input autocomplete="new-txtCompensation1Onset" matInput maxlength="40" placeholder="(MM/DD/YYYY)" formControlName="txtCompensation1Onset"
              [matDatepicker]="myPicker1">
            <mat-datepicker-toggle matSuffix [for]="myPicker1"></mat-datepicker-toggle>
            <mat-datepicker #myPicker1></mat-datepicker>
            <mat-error *ngIf="insuranceForm?.get('insuranceCompensation1.txtCompensation1Onset')?.touched &&
                    insuranceForm?.get('insuranceCompensation1.txtCompensation1Onset')?.errors">
              Enter Valid Date!
            </mat-error>
          </mat-form-field>

          <mat-form-field class="col-3">
            <mat-label>State of Auto Accident</mat-label>
            <mat-select formControlName="txtCompensation1State" [(value)]="stateOfAutoAccident"
             >
              <mat-option *ngFor=" let state of drpStateofAccident" [value]="state.stateOfAutoAccidentShortCodeId">
                {{state.stateOfAutoAccidentType}} </mat-option>

            </mat-select>
            <button mat-button  (click)="clearStateOfAutoAccident()" (click)="stateOfAutoAccident=undefined;$event.stopPropagation()" *ngIf="stateOfAutoAccident" matSuffix
            mat-icon-button >
            <mat-icon>close</mat-icon>
          </button>
          </mat-form-field>


          <!-- <mat-form-field class="col-3">
            <mat-label>State of Auto Accident</mat-label>
            <mat-select maxlength="40" formControlName="txtCompensation1State">
              <mat-option *ngFor="let state of drpStateofAccident" [value]="state.stateOfAutoAccidentId">
                {{state.stateOfAutoAccidentType}}
              </mat-option>
            </mat-select>
          </mat-form-field> -->

        </div>
      </mat-card-content>
    </div>
  </div>

  <br>
  <div class="row" formGroupName="insuranceCompensation2">

    <div class="col-lg-6">
      <div class="row">
        <div class="col-md-6">
          <h4 class="customThemeClass"><b>EClaims</b></h4>
        </div>
      </div>
      <mat-card-content>
        <div class="row">
          <div class="col-md-6">
            <br>
            <mat-checkbox formControlName="chkCompensation2eclaim">Include eClaims Attachment</mat-checkbox>
          </div>
          <mat-form-field class="col-6">
            <mat-label>Attachment Number</mat-label>
            <input autocomplete="new-txtCompensation2Attatchment" maxlength="15" matInput formControlName="txtCompensation2Attatchment" type="text">
            <mat-error *ngIf="
                    insuranceForm?.get('insuranceCompensation2.txtCompensation2Attatchment')?.touched &&
                    insuranceForm?.get('insuranceCompensation2.txtCompensation2Attatchment')?.errors?.number
                  ">
              Enter only Numbers!
            </mat-error>
          </mat-form-field>
          <mat-form-field class="col-6">
            <mat-label>Type Code</mat-label>
            <mat-select maxlength="40" formControlName="drpTypeCode" [(value)]="typeCode">
              <mat-option *ngFor="let type of drpTypeCode" [value]="type.shortCodeId">
                {{type.typeCodeType}}
              </mat-option>
            </mat-select>
            <button mat-button  (click)="clearTypeCode()" (click)="typeCode=undefined;$event.stopPropagation()" *ngIf="typeCode" matSuffix
              mat-icon-button >
              <mat-icon>close</mat-icon>
            </button>
          </mat-form-field>
          <mat-form-field class="col-6">
            <mat-label>Trans Code</mat-label>
            <mat-select maxlength="40" formControlName="drpTrans" [(value)]="transCode" >
              <mat-option *ngFor="let trans of drpTrans" [value]="trans.shortCodeId">
                {{trans.transCodeType}}
              </mat-option>
            </mat-select>
            <button mat-button (click)="clearTransCode()" (click)="transCode=undefined;$event.stopPropagation()" *ngIf="transCode" matSuffix
              mat-icon-button >
              <mat-icon>close</mat-icon>
            </button>
          </mat-form-field>

        </div>
      </mat-card-content>
    </div>

    <div class="col-lg-6">
      <div class="row">
        <div class="col-md-6">
          <h4 class="customThemeClass"><b>Claim Note</b></h4>
        </div>
      </div>
      <mat-card-content>
        <div class="row">
          <mat-form-field class="col-6">
            <mat-label>Type</mat-label>
            <mat-select maxlength="40" formControlName="drpClaim" [(value)]="claimType" >
              <mat-option *ngFor="let claim of drpClaim" [value]="claim.shortCodeId">
                {{claim.claimNoteType}}
              </mat-option>
            </mat-select>
            <button mat-button (click)="clearClaimType()" (click)="claimType=undefined;$event.stopPropagation()" *ngIf="claimType" matSuffix
              mat-icon-button >
              <mat-icon>close</mat-icon>
            </button>
          </mat-form-field>
          <mat-form-field class="col-6">
            <mat-label>Note</mat-label>
            <textarea maxlength="400" [(ngModel)]="notesText"
            (input)="updateCharacterCount()"rows="1" matInput formControlName="txtCompensation2Note"></textarea>  <div class="char-count">
              {{ notesText?.length }} / 5000  
            </div>
          </mat-form-field>
        </div>
      </mat-card-content>
    </div>
  </div>
</form>
<br>
<div class="row mb-2">
  <div class="col-sm-12">
    <div class="text-lg-center ">
      <!-- <button type="submit" class="btn btn-primary  mb-1 mr-1">Save</button> -->

      <button *ngIf="orderStatus" mat-button class="buttonColor" [disabled]="saveButtonHide||!(!insuranceForm.invalid && (insuranceForm.dirty ))"
        (click)="getPatientDetailsForSave()">Save
        <mat-icon *ngIf='isShowSpinner'>
          <mat-spinner class="spinner-border spinner-border-sm" diameter="20"> </mat-spinner>
        </mat-icon>
      </button>
    </div>
  </div>
</div>
<!-- </div> -->
</div>
