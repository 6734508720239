import type { ClaimBatchProcessRequestDTO } from './dto/models';
import { RestService } from '@abp/ng.core';
import { Injectable } from '@angular/core';
import type { TS837P } from '../../../edi-fabric/templates/hipaa5010/models';

@Injectable({
  providedIn: 'root',
})
export class P837ClaimService {
  apiName = 'ClaimProcessingManagement';

  generateEdiByInput = (input: ClaimBatchProcessRequestDTO) =>
    this.restService.request<any, string>({
      method: 'POST',
      responseType: 'text',
      url: '/api/claimProcessingApp/p837Claim/generate-edi',
      body: input,
    },
    { apiName: this.apiName });

  parseEdi = () =>
    this.restService.request<any, TS837P>({
      method: 'POST',
      url: '/api/claimProcessingApp/p837Claim/parse-edi',
    },
    { apiName: this.apiName });

  constructor(private restService: RestService) {}
}
