import { Injectable } from '@angular/core';
import {UrlSerializer, UrlTree, DefaultUrlSerializer} from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class CustomUrlSerializer implements UrlSerializer {
    // parse(url: any): UrlTree {
    //     let dus = new DefaultUrlSerializer();
    //     return dus.parse(url);
    // }

    // serialize(tree: UrlTree): any {
    //     let dus = new DefaultUrlSerializer(),
    //         path = dus.serialize(tree);
    // use your regex to replace as per your requirement.
    //     return path.replace('','+');
    // }

    private _defaultUrlSerializer: DefaultUrlSerializer = new DefaultUrlSerializer();

    parse(url: string): UrlTree {
        // Encode "+" to "%2B"
        url = url.replace(/\+/gi, '%2B');
        // Use the default serializer.
        return this._defaultUrlSerializer.parse(url);
    }

    serialize(tree: UrlTree): string {
        return this._defaultUrlSerializer.serialize(tree).replace(/\+/gi, '%2B');
    }
}
