import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { InboundDocumentService } from '../../admin-proxy/platform-app-management/rcm/platform-management/inbound/inbound-document.service';
import {
  FormBuilder,
  FormControl,
  FormGroup,
} from '@angular/forms';
import { DatePipe } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import Swal from 'sweetalert2';
import { SearchFaxFinder } from 'projects/shared/src/app/enums/allenums.enum';

@Component({
  selector: 'app-faxFinder',
  templateUrl: './faxFinder.component.html',
  styleUrls: ['./faxFinder.component.scss'],
})
export class FaxFinderComponent implements OnInit {
  faxFinderDataList: any;
  isLoading: boolean = false;
  // isShowSpinner: boolean = false;
  private faxFinderAPICall: Subscription;
  fgFaxFinder: FormGroup;
  fromDate: any;
  toDate: any;
  today: string;

  isShown: boolean = false;

  FaxIdInput: string;
  ChartNoInput: string;
  PatientNameInput: string;
  txtBirthdateInput: string;

  BirthdateMaxDate: Date;
  BirthdateMinDate: Date;
  RxdateMin: Date;
  searchFaxFinderInput=SearchFaxFinder;
  constructor(
    private inboundDocService: InboundDocumentService,
    private datepipe: DatePipe,
    private fb: FormBuilder
  ) {
    this.today = this.datepipe.transform(new Date(), 'yyyy-MM-dd');
  }
  ngOnInit() {
    this.initializeForms();
    this.loadValidDates();
  }
  ngOnDestroy(): void {
    if (this.faxFinderAPICall) {
      this.faxFinderAPICall.unsubscribe();
    }
  }

  initializeForms() {
    this.fgFaxFinder = this.fb.group({
      FaxId: new FormControl(''),
      ChartNo: new FormControl(''),
      PatientName: new FormControl(''),
      txtBirthdate: new FormControl(''),
    });
  }
  //To set the Valid Dates for Birth Date field
  loadValidDates() {
    this.BirthdateMaxDate = new Date();
    this.BirthdateMaxDate.setDate(this.BirthdateMaxDate.getDate());
    this.BirthdateMinDate = new Date();
    this.BirthdateMinDate.setFullYear(
      this.BirthdateMinDate.getFullYear() - 120
    );
    var oneYearAgo = new Date();
    oneYearAgo.setFullYear(this.BirthdateMaxDate.getFullYear() - 1);
    this.RxdateMin = oneYearAgo;
  }

  getFormattedDate(date: string) {
    const dob = date ? new Date(date).toLocaleDateString('en-US') : '-';
  }

  onKeyPressSearch() {
    this.fnSearchFax();
  }
  fnSearchFax() {
    this.isShown = true;
    this.isLoading = true;
    // this.isShowSpinner = true;
    this.FaxIdInput = this.fgFaxFinder.value.FaxId;
    this.ChartNoInput = this.fgFaxFinder.value.ChartNo;
    this.PatientNameInput = this.fgFaxFinder.value.PatientName;
    this.txtBirthdateInput = this.fgFaxFinder.value.txtBirthdate;
    // const dobInputConvert = this.txtBirthdateInput ? new Date(this.txtBirthdateInput).toLocaleDateString('en-US') : '';
    const dobInputConvert = this.datepipe.transform(this.txtBirthdateInput, 'yyyy-MM-dd');
    try {
      if (this.faxFinderAPICall) {
        this.faxFinderAPICall.unsubscribe();
      }
      this.faxFinderAPICall = this.inboundDocService
        .getFaxDocumentDetailsByFilter(null, null, dobInputConvert, this.FaxIdInput, this.ChartNoInput, this.PatientNameInput)
        .subscribe(
          (response) => {
            this.faxFinderDataList = response['ltFaxes'];
            // this.isShowSpinner = false;
          },
          (error) => {
            this.isLoading = false;
            // this.isShowSpinner = false;
            const data: HttpErrorResponse = error;
            Swal.fire({
              icon: 'info',
              text: data?.error?.error?.message,
            });
          },
          () => {
            // This block will be executed on both success and error
            this.isLoading = false;
            // this.isShowSpinner = false;
          }
        );
      // this.tableData$ = getTableData;
    } catch (error) {
      const data: HttpErrorResponse = error;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    } finally {
    }
  }


  resetFaxFinder() {
    this.fgFaxFinder.reset();
    this.fnSearchFax();
  }


  getFirstAndLastDatesOfCurrentMonth(): { firstDate: Date; lastDate: Date } {
    const today = new Date();
    const firstDate = new Date(today.getFullYear(), today.getMonth(), 1);
    const lastDate = new Date();
    return { firstDate, lastDate };
  }
}
