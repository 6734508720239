import { mapEnumToOptions } from '@abp/ng.core';

export enum MedicareCheckListStatus {
  New = 0,
  Approved = 1,
  Disapproved  = 2,
  Pending = 3,
  Submitted = 4,
}

export enum MedicareCheckListFilter {
  FaxProcess = 1,
  OnDemand = 2,
  ApproveDisapprove = 3,
  Pending = 4,
}

export enum CCSettlementTab {
  Pending = 1,
  Completed = 2,
}

export enum DisapprovedStatus {
  FaceToFace,
  SleepStudy,
  Prescription,
  DownloadReport,
  InsuranceIssue,
  Other
}

export enum DisapprovedStatusId {
  FaceToFace = 0,
  SleepStudy = 1,
  Prescription = 2,
  DownloadReport = 3,
  InsuranceIssue = 4,
  Other = 5
}


export const DisapprovedList = {
  [DisapprovedStatusId.FaceToFace]: 'FaceToFace',
  [DisapprovedStatusId.SleepStudy]: 'SleepStudy',
  [DisapprovedStatusId.Prescription]: 'Prescription',
  [DisapprovedStatusId.DownloadReport]: 'DownloadReport',
  [DisapprovedStatusId.InsuranceIssue]: 'InsuranceIssue',
  [DisapprovedStatusId.Other]: 'Other',
};

export const medicareCheckListStatusOptions = mapEnumToOptions(
  MedicareCheckListStatus
);
