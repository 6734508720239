import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { ErrorStateMatcher, MatNativeDateModule, MatOptionModule, ShowOnDirtyErrorStateMatcher } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { TextMaskModule } from 'angular2-text-mask';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';
import { NgxMaskModule } from 'ngx-mask';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { SharedModule } from 'projects/shared/src/app/shared.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BillingClaimInfoComponent } from './billing-claim-info/billing-claim-info.component';
import { BillingCreateEditComponent } from './billing-create-edit/billing-create-edit.component';
import { BillingListComponent } from './billing-list/billing-list.component';
import { BillingPatientVisitComponent } from './billing-patient-visit/billing-patient-visit.component';
import { BillingPostingChargesComponent } from './billing-posting-charges/billing-posting-charges.component';
import { CheckEftSelectionComponent } from './check-eft-selection/check-eft-selection.component';
import { ClaimHistoryComponent } from './claim-history/claim-history.component';
import { ClaimInfoComponent } from './claim-info/claim-info.component';
import { ClaimProcessComponent } from './claim-process/claim-process.component';
import { ClaimStatusComponent, ClaimStatusDialog } from './claim-status/claim-status.component';
import { EditClaimStatusComponent } from './edit-claim-status/edit-claim-status.component';
import { EditPaymentComponent } from './edit-payment/edit-payment.component';
import { ERAPostingComponent } from './era-posting/era-posting.component';
import { InsurancePaymentModalComponent } from './insurance-payment-modal/insurance-payment-modal.component';
import { InsurancePaymentsComponent } from './insurance-payments/insurance-payments.component';
import { PostingListComponent } from './posting-list/posting-list.component';
import { VisitNoteComponent } from './visit-note/visit-note.component';
import { PostingChargesComponent } from './posting-charges/posting-charges.component';
import { PostingItemPopupComponent } from './posting-item-popup/posting-item-popup.component';
import { PostingHistoryComponent } from './posting-history/posting-history.component';
import { PrimaryPaymentComponent } from './primary-payment/primary-payment.component';
import { SecondaryPaymentComponent } from './secondary-payment/secondary-payment.component';
import { TeritaryPaymentComponent } from './teritary-payment/teritary-payment.component';
import { BillingPaypalComponent } from './billing-paypal/billing-paypal.component';
import { BillingPaypalInvoiceComponent } from './billing-paypal-invoice/billing-paypal-invoice.component';
import { BillingCashPaymentViewComponent } from './billing-cash-payment-view/billing-cash-payment-view.component';
import { BillingReminderComponent } from './billing-reminder/billing-reminder.component';
import { BillingReminderMessageComponent } from './billing-reminder-message/billing-reminder-message.component';
import { ClaimDenialReportsComponent } from './claim-denial-reports/claim-denial-reports.component';
import { DenialYearReportComponent } from './denial-year-report/denial-year-report.component';
import { BillingAuthorizationComponent } from './billing-authorization/billing-authorization.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { BillingDocumnetComponent } from './billing-documnet/billing-documnet.component';
import { NgxMatDatetimePickerModule, NgxMatTimepickerModule } from '@angular-material-components/datetime-picker';
import { ClaimViewReportComponent } from './claim-view-report/claim-view-report.component';
import { ClaimEventViewComponent } from './claim-event-view/claim-event-view.component';
import { AddPaymentsComponent } from './add-payments/add-payments.component';
import { AddItemPaymentComponent } from './add-item-payment/add-item-payment.component';
import { ManualPostingHistoryComponent } from './manual-posting-history/manual-posting-history.component';
import { AddInsurancePaymentComponent } from './add-insurance-payment/add-insurance-payment.component';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSortModule } from '@angular/material/sort';
import { ChequeListComponent } from './cheque-list/cheque-list.component';
import { MatTableExporterModule } from 'mat-table-exporter';
import { ClaimProcessTabComponent } from './claim-process-tab/claim-process-tab.component';
import { ClaimViewTabComponent } from './claim-view-tab/claim-view-tab.component';
import { MatToolbarModule } from '@angular/material/toolbar';
import { ClaimStatusTabComponent } from './claim-status-tab/claim-status-tab.component';
import { ClaimCheckListComponent } from './claim-check-list/claim-check-list.component';
import { ClaimEventViewTabComponent } from './claim-event-view-tab/claim-event-view-tab.component';
import { MatMenuModule } from '@angular/material/menu';
import { DataTablesModule } from 'angular-datatables';
import { BillingListTabComponent } from './billing-list-tab/billing-list-tab.component';
import { MatDaterangepickerModule } from 'mat-daterangepicker';
import { PatientClaimsTabComponent } from './patient-claims-tab/patient-claims-tab.component';
import { EditClaimInfoComponent } from './edit-claim-info/edit-claim-info.component';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { BillingListPageDetailsComponent } from './billing-list-tab/billing-list-page-details/billing-list-page-details.component';
import { ClaimStatusListComponent } from './claim-status-list/claim-status-list.component';
import { ClaimStatusListTableComponent } from './claim-status-list/claim-status-list-table/claim-status-list-table.component';
import { ClaimChequeListComponent } from './claim-cheque-list/claim-cheque-list.component';
import { ClaimChequeListTableComponent } from './claim-cheque-list/claim-cheque-list-table/claim-cheque-list-table.component';
import { PatientClaimTableListComponent } from './patient-claims-tab/patient-claim-table-list/patient-claim-table-list.component';
import { ClaimProcessTableComponent } from './claim-process-tab/claim-process-table/claim-process-table.component';
import { ClaimBatchComponent } from './claim-batch/claim-batch.component';
import { ClaimBatchTableComponent } from './claim-batch/claim-batch-table/claim-batch-table.component';
import { AutoPostingERAComponent } from './auto-posting-era/auto-posting-era.component';
import { AutoPostingERATableComponent } from './auto-posting-era/auto-posting-eratable/auto-posting-eratable.component';
import { UploadEOBComponent } from './claim-status-list/claim-status-list-table/upload-eob/upload-eob.component';
import { InsuranceRecoopComponent } from './claim-status-list/claim-status-list-table/insurance-recoop/insurance-recoop.component';
import { ClaimUploadEOBComponent } from './auto-posting-era/upload-eob/upload-eob.component';
import { MoveToSecondaryModelComponent } from './auto-posting-era/move-to-secondary-model/move-to-secondary-model.component';
import { UpdateClaimStatusComponent } from './claim-status-list/claim-status-list-table/update-claim-status/update-claim-status.component';
import { CheckFilterSwitchComponent } from './claim-cheque-list/check-filter-switch/check-filter-switch.component';
import { AutoPostingFilterSwitchComponent } from './auto-posting-era/auto-posting-filter-switch/auto-posting-filter-switch.component';
import { NikoBillingComponent } from './niko-billing/niko-billing.component';
import { BillingInvoiceComponent } from './niko-billing/billing-invoice/billing-invoice.component';
import { BillingPaymentsComponent } from './niko-billing/billing-payments/billing-payments.component';
import { BillingDenialsComponent } from './niko-billing/billing-denials/billing-denials.component';
import { BillingEOBERAComponent } from './niko-billing/billing-eob-era/billing-eob-era.component';
import { BillingDepositsComponent } from './niko-billing/billing-deposits/billing-deposits.component';
import { BillingPaymentsFilterComponent } from './niko-billing/billing-payments/billing-payments-filter/billing-payments-filter.component';
import { BillingViewInvoiceComponent } from './niko-billing/billing-view-invoice/billing-view-invoice.component';
import { OrderInvoicesComponent } from './order-invoices/order-invoices.component';
import { WriteOffModelComponent } from './writeOff-Model/writeOff-Model.component';
import { ReadMoreComponent } from './niko-billing/read-more/read-more.component';

const providers : any = [] ;
@NgModule({
  declarations: [	
    AppComponent,
    EditPaymentComponent,
    BillingListComponent,
    BillingCreateEditComponent,
    EditClaimStatusComponent,
    ClaimHistoryComponent,
    VisitNoteComponent,
    InsurancePaymentsComponent,
    InsurancePaymentModalComponent,
    ERAPostingComponent,
    ClaimInfoComponent,
    CheckEftSelectionComponent,
    PostingListComponent,
    BillingPatientVisitComponent,
    BillingClaimInfoComponent,
    BillingPostingChargesComponent,
    ClaimHistoryComponent,
    ClaimProcessComponent,
    ClaimStatusComponent,
    ClaimStatusDialog,
    ClaimProcessComponent,
    PostingChargesComponent,
    PostingItemPopupComponent,
    PostingHistoryComponent,
    PrimaryPaymentComponent,
    SecondaryPaymentComponent,
    TeritaryPaymentComponent,
    BillingPaypalComponent,
    BillingPaypalInvoiceComponent,
    BillingCashPaymentViewComponent,
    BillingReminderComponent,
    BillingReminderMessageComponent,
    ClaimDenialReportsComponent,
    DenialYearReportComponent,
    BillingAuthorizationComponent,
    BillingDocumnetComponent,
    ClaimViewReportComponent,
    ClaimEventViewComponent,
    AddPaymentsComponent,
    AddItemPaymentComponent,
    ManualPostingHistoryComponent,
    AddInsurancePaymentComponent,
    ChequeListComponent,
    ClaimProcessTabComponent,
    ClaimViewTabComponent,
    ClaimStatusTabComponent,
    ClaimCheckListComponent,
    ClaimEventViewTabComponent,
      BillingListTabComponent,
      PatientClaimsTabComponent,
      EditClaimInfoComponent,
      BillingListPageDetailsComponent,
      ClaimStatusListComponent,
      ClaimStatusListTableComponent,
      ClaimChequeListComponent,
      ClaimChequeListTableComponent,
      PatientClaimTableListComponent,
      ClaimProcessTableComponent,
      ClaimBatchComponent,
      ClaimBatchTableComponent,
      AutoPostingERAComponent,
      AutoPostingERATableComponent,
      UploadEOBComponent,
      InsuranceRecoopComponent,
      ClaimUploadEOBComponent,
      MoveToSecondaryModelComponent,
      UpdateClaimStatusComponent,
      CheckFilterSwitchComponent,
      AutoPostingFilterSwitchComponent,
      NikoBillingComponent,
      BillingInvoiceComponent,
      BillingPaymentsComponent,
      BillingDenialsComponent,
      BillingEOBERAComponent,
      BillingDepositsComponent,
      BillingPaymentsFilterComponent,
      BillingViewInvoiceComponent,
      OrderInvoicesComponent,
      WriteOffModelComponent,
      ReadMoreComponent
   ],
  imports: [
    CommonModule,
    AppRoutingModule,
    MatToolbarModule,
    NgSelectModule,
    NgxMaskModule.forRoot(),
    NgxMatSelectSearchModule,
    TextMaskModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatDaterangepickerModule,
    MatListModule,
    MatSlideToggleModule,
    ScrollingModule,
    DataTablesModule,
    MatGridListModule,
    MatCardModule,
    MatButtonModule,
    MatTableExporterModule,
    MatMenuModule,
    MatSortModule,
    MatDialogModule,
    MatFormFieldModule,
    NgxMatDatetimePickerModule,
    NgxMatTimepickerModule,
    MatStepperModule,
    MatIconModule,
    MatSnackBarModule,
    MatRadioModule,
    MatSelectModule,
    MatCheckboxModule,
    NgbModule,
    MatInputModule,
    MatOptionModule,
    MatDatepickerModule,
    ReactiveFormsModule,
    FormsModule,
    NgxExtendedPdfViewerModule,
    MatDividerModule,
    MatTableModule,
    MatProgressSpinnerModule,
    MatPaginatorModule,
    MatTooltipModule,
    SharedModule,
    MatProgressBarModule,
    MatExpansionModule,
    NgxDaterangepickerMd
  ],
  providers: [
    { provide: ErrorStateMatcher, useClass: ShowOnDirtyErrorStateMatcher }],
  bootstrap: [AppComponent]
})
export class AppModule { }
@NgModule({})
export class BillingModule{
  static forRoot():ModuleWithProviders<BillingModule> {
    return {
      ngModule: AppModule,
      providers
      // providers: [
      //   { provide: OAuthStorage, useFactory: storageFactory }
      // ]
    };
  }
}
