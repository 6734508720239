import { RestService } from '@abp/ng.core';
import { Injectable } from '@angular/core';
import { SaleOrderDocumentDTO } from './dto/models';

@Injectable({
  providedIn: 'root',
})
export class SaleorderTemplateService {
  apiName = 'orderManagement';
  headerDict = {
    'Content-Type': 'application/json',
    'Accept': 'text/plain',
    'Access-Control-Allow-Headers': 'Content-Type',
  }
  requestOptions = {
    headers: new Headers(this.headerDict),
  };
  getSaleOrderDocumentByOrderId = (orderId: string) =>
    this.restService.request<any, string>({
      method: 'POST',
      responseType: 'text',
      url: `/api/orderApp/order-delivery-template/generate-sale-order-document/${orderId}`,
    },
      { apiName: this.apiName });

  constructor(private restService: RestService) { }
}
