import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-patient-cnm-report',
  templateUrl: './patient-cnm-report.component.html',
  styleUrls: ['./patient-cnm-report.component.scss']
})
export class PatientCnmReportComponent implements OnInit {
  isReportLoading:boolean = false;
  selectedOption:any;
  cnmReporttbldata:any;
  constructor() { }

  ngOnInit() {
  }

  getCNMFilterList(){

  }
}
