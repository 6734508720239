import { RestService } from '@abp/ng.core';
import { Injectable } from '@angular/core';
import { SaleorderDTO } from './dto';

@Injectable({
  providedIn: 'root',
})
export class BillingSearchService {
  apiName = 'orderManagement';

  searchBillingBySPatientIdAndSPatietNameAndSSaleOrderIdAndSInsuranceNameAndDtSuppliedDateAndSEmailIdAndSShippingLocationAndDtCreatedDateAndSPlanAndSStatus = (sPatientId: string, sPatietName: string, sSaleOrderId: string, sInsuranceName: string, dtSuppliedDate: string, sEmailId: string, sShippingLocation: string, dtCreatedDate: string, sPlan: string, sStatus: string) =>
    this.restService.request<any, SaleorderDTO[]>({
      method: 'POST',
      url: '/api/orderApp/billing-search/search-billing',
      params: { sPatientId, sPatietName, sSaleOrderId, sInsuranceName, dtSuppliedDate, sEmailId, sShippingLocation, dtCreatedDate, sPlan, sStatus },
    },
     { apiName: this.apiName, skipHandleError: true });

  constructor(private restService: RestService) {}
}
