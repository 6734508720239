import type { DashboardService_Csr3MonthStatistics, DashboardService_CsrCommission, DashboardService_CsrHurdleStat, DashboardService_DashboardExchanges } from './models';
import { RestService } from '@abp/ng.core';
import { Injectable } from '@angular/core';
import type { OverrideListDTO } from '../order-optimization/dto/models';

@Injectable({
  providedIn: 'root',
})
export class OrderDashboardService {
  apiName = 'orderManagement';

  getCsr3MonthsStatisticsListByGCSRAndGLocationAndIMonthAndIYear = (gCSR: string, gLocation: string, iMonth: number, iYear: number) =>
    this.restService.request<any, DashboardService_Csr3MonthStatistics[]>({
      method: 'GET',
      url: '/api/orderApp/dashboard/csr3Months-statistics-list',
      params: { gCSR, gLocation, iMonth, iYear },
    },
    { apiName: this.apiName });

  getCsrWiseCommissionListByGCSRAndGLocationAndIMonthAndIYear = (gCSR: string, gLocation: string, iMonth: number, iYear: number) =>
    this.restService.request<any, DashboardService_CsrCommission[]>({
      method: 'GET',
      url: '/api/orderApp/dashboard/csr-wise-commission-list',
      params: { gCSR, gLocation, iMonth, iYear },
    },
    { apiName: this.apiName });

  getOrderExchangeListByGCSRAndGLocationAndIMonthAndIYear = (gCSR: string, gLocation: string, iMonth: number, iYear: number) =>
    this.restService.request<any, DashboardService_DashboardExchanges[]>({
      method: 'GET',
      url: '/api/orderApp/dashboard/order-exchange-list',
      params: { gCSR, gLocation, iMonth, iYear },
    },
    { apiName: this.apiName });

  getOrderOverrideListByGCSRAndGLocationAndIMonthAndIYear = (gCSR: string, gLocation: string, iMonth: number, iYear: number) =>
    this.restService.request<any, OverrideListDTO[]>({
      method: 'GET',
      url: '/api/orderApp/dashboard/order-override-list',
      params: { gCSR, gLocation, iMonth, iYear },
    },
    { apiName: this.apiName });

  getCsrOrderStatByGCSRAndGLocationAndIMonthAndIYear = (gCSR: string, gLocation: string, iMonth: number, iYear: number) =>
    this.restService.request<any, DashboardService_CsrHurdleStat>({
      method: 'GET',
      url: '/api/orderApp/dashboard/get-csr-order-stat',
      params: { gCSR, gLocation, iMonth, iYear },
    },
    { apiName: this.apiName });

  constructor(private restService: RestService) {}
}
