<div class="row">
  <div class="col-lg-12 d-flex justify-content-between align-items-center">
    <h5 class="customThemeClass mb-0">
      <b>Permissions - {{ data.roleDisplayName }}</b>
    </h5>
    <!-- <button mat-icon-button class="close-button">
      <mat-icon>close</mat-icon>
    </button> -->
  </div>
</div>
<mat-dialog-content class="mat-typography">
  <div class="row">
    <div class="col-lg-12">
      <mat-card-content>
        <div class="d-flex justify-content-center" *ngIf="!isPermissionLoaded">
          <img
            class="img-responsive pb-2 mr-1"
            src="assets/gifs/Infinity-loader.gif"
          />
        </div>
        <form *ngIf="isPermissionLoaded" [formGroup]="permissionForm">
          <div class="row">
            <div class="col-12 pb-2">
              <!-- Select All & Expand Accordion Buttons -->
              <div class="row">
                <div
                  class="col-12 d-flex justify-content-between align-items-center"
                >
                  <mat-checkbox
                    class="mt-2 dd-text"
                    (change)="selectAllModulePermission($event)"
                    formControlName="chkAllManagement"
                  >
                    Select All</mat-checkbox
                  >
                  <!-- <button mat-button id="collapse-btn">Expand All</button> -->
                  <!-- <p class="header-text">Modules</p> -->

                  <button
                    class="btn-expand dd-text d-flex align-items-center"
                    (click)="toggleExpand()"
                  >
                    <mat-icon
                      class="mr-2 d-flex align-items-center justify-content-center"
                      style="height: 23px !important"
                      [ngClass]="expanded ? 'ico-expand' : ''"
                      [@iconAnimation]="expanded ? 'expanded' : 'collapsed'"
                    >
                      {{ expanded ? "expand_more" : "chevron_right" }}
                    </mat-icon>
                    {{ expanded ? "Collapse All" : "Expand All" }}
                  </button>
                </div>
              </div>

              <!-- Accordions Content -->
              <mat-accordion *ngIf="permissionDetails" multi>
                <ng-container
                  *ngFor="let permissionDetail of permissionDetails"
                >
                  <mat-expansion-panel>
                    <!-- Header (Module Name )-->
                    <mat-expansion-panel-header>
                      <mat-panel-title
                        class="header-text d-flex justify-content-center"
                      >
                        {{ permissionDetail.moduleName }}
                      </mat-panel-title>
                    </mat-expansion-panel-header>
                    <mat-checkbox
                      class="mr-3 dd-text"
                      (change)="
                        selectAllPermissionsfromModule(
                          $event,
                          permissionDetail.moduleName
                        )
                      "
                      [(checked)]="permissionDetail.isSelected"
                    >
                      Select All Permission for
                      {{ permissionDetail.moduleName }}
                    </mat-checkbox>
                    <mat-divider></mat-divider>

                    <div
                      *ngFor="
                        let permissionFeatures of permissionDetail.permissionData
                      "
                    >
                      <!-- <hr /> -->
                      <mat-panel-title class="header-text-normal py-1">
                        {{ permissionFeatures.permissionName }}
                      </mat-panel-title>
                      <div class="row">
                        <div class="col-12 checkbox-row">
                          <ng-container
                            *ngFor="
                              let permissions of permissionFeatures.permissions
                            "
                          >
                            <!-- [(ngModel)]="permissions.isGranted" -->
                            <mat-checkbox
                              class="mr-3 dd-text"
                              [(checked)]="permissions.isGranted"
                              (change)="
                                changeValue(
                                  $event,
                                  permissionDetail.moduleName,
                                  permissions
                                )
                              "
                            >
                              {{ permissions.displayName }}
                            </mat-checkbox>
                          </ng-container>
                        </div>
                      </div>
                      <mat-divider></mat-divider>
                    </div>
                  </mat-expansion-panel>
                </ng-container>
              </mat-accordion>
            </div>
          </div>
        </form>
      </mat-card-content>
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button class="buttonColor mr-2" (click)="savePermissions()">
    Save
  </button>
  <button class="buttonCancelColor" mat-button mat-dialog-close>Close</button>
</mat-dialog-actions>
<app-loader-component *ngIf="isPermissionSaved"></app-loader-component>
