import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { MmOrderService } from 'projects/order/src/app/order-proxy/order-management/order-optimization/mm-order.service';
import { MyQueueWizard } from 'projects/shared/src/app/enums/allenums.enum';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-my-queue',
  templateUrl: './my-queue.component.html',
  styleUrls: ['./my-queue.component.scss'],
})
export class MyQueueComponent implements OnInit {
  myQueueWizard = MyQueueWizard;
  activeTab = MyQueueWizard.DOCUMENTBIN;
  deineialCount=0;
  PatientEntryCount=0;
  getTabDataCount:Subscription;
  denailTabCount:number = 0;
  invoiceTabCount:number = 0;
  claimTabCount:number = 0;
  orderTabCount:number = 0;
  patientEntryTabCount:number = 0;
  constructor(
    private mmorderService : MmOrderService,
    private title:Title
  ) {}

  ngOnInit() {
    this.title.setTitle('Qsecure | My Queue');
    this.getWonaceTabCount();
  }
  getActiveTabClass(value: any): string {
    return this.activeTab == value ? 'active' : 'inactive';
  }
  selectWizard(myQueueWizard: MyQueueWizard) {
    this.activeTab = myQueueWizard;
  }
  getDeineialCount(event: any){
     this.deineialCount=event;
  }
  getPatiententryCount(event: any){
    this.PatientEntryCount=event;
 }

 getWonaceTabCount() {
  if (this.getTabDataCount) {
    this.getTabDataCount.unsubscribe();
  }
  this.getTabDataCount = this.mmorderService.getCountOfTabsInWonace().subscribe(
    (response) => {
      this.patientEntryTabCount = response?.patientEntryCount || 0;
      this.orderTabCount = response?.orderCount || 0;
      this.claimTabCount = response?.claimCount || 0;
      this.invoiceTabCount = response?.invoiceCount || 0;
      this.denailTabCount = response?.denialCount || 0;
    },
    (err) => {

    }
  );
}
}
