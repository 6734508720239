import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { Status } from 'projects/inventory/src/app/item-proxy/item-management/items';
import { CreateUpdateProductCategoryDetailsDTO } from 'projects/inventory/src/app/item-proxy/item-management/optimization/dto/models';
import { ProductCategoryDetailsService } from 'projects/inventory/src/app/item-proxy/item-management/optimization/product-category-details.service';
import { noWhitespaceValidator } from 'projects/patient/src/app/add-note-tab/add-note-tab.component';

@Component({
  selector: 'app-add-category',
  templateUrl: './add-category.component.html',
  styleUrls: ['./add-category.component.scss'],
})
export class AddCategoryComponent implements OnInit {
  productCategoryForm: FormGroup;
  constructor(
    public addCategoryModelRef: MatDialogRef<AddCategoryComponent>,
    private formBuilder: FormBuilder,
    private productCategoryService: ProductCategoryDetailsService,
    private toastr: ToastrService
  ) {}
  ngOnInit(): void {
    this.initializeForms();
  }
  //Initialize Forms
  initializeForms() {
    this.productCategoryForm = this.formBuilder.group({
      txtProductCategoryName: new FormControl('', [
        Validators.required,
        noWhitespaceValidator,
      ])
    });
  }

  onCloseClick(): void {
    this.addCategoryModelRef.close([]);
  }

  saveButtonHide: boolean = false;
  saveButtonDisabled: boolean = false;

  saveProductCategory() {
    this.saveButtonDisabled = true;
    // const categoryForm = this.productCategoryForm.value;
    let productCategoryDetails: CreateUpdateProductCategoryDetailsDTO = {
      productCategoryName: this.productCategoryForm?.value?.txtProductCategoryName?.trim(),
      status: Status.Active,
      updatedDate: '',
      accessories:0
    };

        this.productCategoryService.create(productCategoryDetails).subscribe(
          (response) => {
            console.log(response);
                  this.toastr.success('Saved Successfully', 'Success');
        this.addCategoryModelRef.close(response);
          },
          (err) => {
            // this.loadTable = true;
            this.saveButtonDisabled = false;
            const data: HttpErrorResponse = err;
            this.toastr.warning(data?.error?.error?.message, 'Warning');
          }
        );
  }

  // //To Save the Vendor Details
  // saveVendorDetails() {
  //   this.saveButtonHide = true;
  //   let input: CreateUpdateVendorDetailsDTO = {
  //     vendorName: this.productVendorForm?.value?.txtVendorName ?? '',
  //     vendorCode: this.productVendorForm?.value?.txtVendorCode ?? '',
  //     addressLine1: '',
  //     primaryPhoneNumber: '',
  //     productQuantity: 0,
  //     status: Status.Active,
  //   };
  //   this.productVendorService.create(input).subscribe(
  //     (response) => {
  //       console.log(response);
  //       this.toastr.success('Saved Successfully', 'Success');
  //       this.addManufacturerModelRef.close(response);
  //     },
  //     (err) => {
  //       this.saveButtonHide = false;
  //       const data: HttpErrorResponse = err;
  //       this.toastr.warning(data?.error?.error?.message, 'Error');
  //     }
  //   );
  // }
}
