import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ShippingPatientBalanceNotesDTO } from 'projects/order/src/app/order-proxy/order-management/order-optimization/dto';
import { ShippingApprovalService } from 'projects/order/src/app/order-proxy/order-management/order-optimization/shipping-approval.service';
import { ShippingListService } from 'projects/order/src/app/order-proxy/order-management/order-optimization/shipping-list.service';
import { defaultGuid } from 'projects/shared/src/app/enums/allenums.enum';
import { CommonService } from 'projects/shared/src/app/services/common.service';
import { CommunicationService } from 'projects/shared/src/app/services/communication.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-shipping-disapproved-list',
  templateUrl: './shipping-disapproved-list.component.html',
  styleUrls: ['./shipping-disapproved-list.component.scss'],
})
export class ShippingDisapprovedListComponent implements OnInit {
  shippingForm: FormGroup;
  isShippingTableLoaded: boolean = true;
  isItemTableLoaded: boolean = true;
  tblItemSelectedRowId: string = defaultGuid;
  patientBalanceandNotesDetails: ShippingPatientBalanceNotesDTO;
  itemTableData: any = [];
  hcpcTableData: any = [];
  authTableData: any = [];
  dtItemOptions: any = {
    responsive: true,
    info: true,
    scrollCollapse: true,
    lengthChange: false,
    scrollY: '225px',
    bFilter: false,
    order: [],
    language: {
      emptyTable: 'No records',
      info: 'Total : _MAX_ records',
      infoEmpty: 'Total : _MAX_ records',
      infoFiltered: '(filtered : _TOTAL_ records)',
      loadingRecords: 'Loading...',
      zeroRecords: 'No matching records',
    },
    //pageLength: 5,
    //lengthMenu: [10, 25, 50, 100, 200],
    paging: false,
    columns: [{ width: '10%' }, { width: '80%' }, { width: '10%' }],
  };
  ltShippingDisapproved: any[] = [];
  subscription$: Subscription[] = [];
  disapproveListSubscription: Subscription;
  itemListSubscription: Subscription;
  notesListSubscription: Subscription;
  constructor(
    private formBuilder: FormBuilder,
    private shippingApprovalService: ShippingApprovalService,
    private shippingListService:ShippingListService,
    private communicationService: CommunicationService,
    private commonService: CommonService,
    private title : Title
  ) {
    const orderItems =
      this.communicationService.loadDisapprovedOrderItemMethodCall$.subscribe(
        (orderId) => {
          this.getOrderItems(orderId);
        }
      );
    this.subscription$.push(orderItems);
    const orderNotes =
      this.communicationService.loadShippingandBillingNotesMethodCall$.subscribe(
        (orderId) => {
          this.getNotesDetails(orderId);
        }
      );
    this.subscription$.push(orderNotes);
  }

  ngOnInit(): void {
    this.title.setTitle('Qsecure | Shipping Disapproved List');
    this.initializeForms();
    this.getDisapprovedOrdersList();
  }
  //To initialize the Component Forms
  initializeForms() {
    this.shippingForm = this.formBuilder.group({
      txtPatientBalance: new FormControl(''),
      txtINSBalance: new FormControl(''),
      txtTotalBalance: new FormControl(''),
      txtBillingNotes: new FormControl(''),
      txtShippingNotes: new FormControl(''),
    });
  }

  //Disapproved Orders List
  getDisapprovedOrdersList() {
    this.isShippingTableLoaded = false;
    if (this.disapproveListSubscription) {
      this.disapproveListSubscription.unsubscribe();
    }
    this.disapproveListSubscription = this.shippingApprovalService
      .getDisapprovedOrderDetails()
      .subscribe(
        (response) => {
          this.isShippingTableLoaded = true;

          this.ltShippingDisapproved = response;
          this.ltShippingDisapproved = this.ltShippingDisapproved.map((shipping) => {
            return {
              ...shipping,
              suppliesdate: this.commonService.getFormattedDateZone(
                shipping.suppliesdate
              ),
              disapproveddate: this.commonService.getFormattedDateZone(
                shipping.disapproveddate
              ),
            };
          });
        },
        (err) => {
          this.isShippingTableLoaded = true;
        }
      );
    this.subscription$.push(this.disapproveListSubscription);
  }
  tblItemSelectedRow(id: string, data: any) {
    this.tblItemSelectedRowId = id;
    //this.tblItemSelectedRowId === id ? '' :

    //this.tblItemSelectedRowId != '' &&
    this.getHCPCCodes(data);
  }
  getHCPCCodes(data) {
    this.hcpcTableData =
      this.hcpcTableData.length > 0 ? [] : this.hcpcTableData;

    this.hcpcTableData = data;
  }
  //To get the Order Items
  getOrderItems(orderId: string) {
    this.itemTableData = [];
    this.hcpcTableData = [];
    this.isItemTableLoaded = false;
    if (this.itemListSubscription) {
      this.itemListSubscription.unsubscribe();
    }
    this.itemListSubscription = this.shippingApprovalService
      .getShippingItemDetailsByGOrderId(orderId)
      .subscribe(
        (response) => {
          this.itemTableData = response;

          this.isItemTableLoaded = true;
          this.tblItemSelectedRow(
            this.itemTableData?.[0]?.productId,
            this.itemTableData?.[0]?.hcpcList
          );
        },
        (err) => {}
      );
    this.subscription$.push(this.itemListSubscription);
  }
  //To get the Notes Details
  getNotesDetails(orderId: string) {
    if (this.notesListSubscription) {
      this.notesListSubscription.unsubscribe();
    }
    this.notesListSubscription = this.shippingApprovalService
      .getPatientBalNotesDetailsV1ByOrderId(orderId)
      .subscribe(
        (response) => {

          this.patientBalanceandNotesDetails = response;
        },
        (err) => {}
      );
    this.subscription$.push(this.notesListSubscription);
  }



  //Approval No and Amount
  getApprovalNoandAmnt(orderId: string) {
    this.shippingListService.getApprovalNoByOrderId(orderId).subscribe(
      (response) => {
        this.authTableData = response;
      },
      (err) => {
        console.log(err);
      }
    );
  }


}
