<table datatable [dtOptions]="dtItemOptions" class="tbl hover row-border hover w-100 display" id="itemTable">
<thead>
  <tr>
    <th [matTooltip]="'HCPC Code'">HCPC Code</th>
    <th [matTooltip]="'Product Code'">Product Code</th>
    <th [matTooltip]="'Description'">Description</th>
    <th [matTooltip]="'Size'">Size</th>
    <th [matTooltip]="'Qty'" class="text-right">Quantity</th>
    <th [matTooltip]="'Back Order Since'">Back Order Since</th>
    <th [matTooltip]="'Serial/Lot No'">Serial/Lot No</th>
    <th [matTooltip]="'Tracking'">Tracking</th>
    <th [matTooltip]="'Shipping Date'">Shipping Date</th>
    <th [matTooltip]="'Packed'">Packed</th>
  </tr>
</thead>
<tbody>
  <tr
    (click)="tblItemSelectedRow(data.mmOrderItemId, data)"
    [ngClass]="
      this.tblItemSelectedRowId === data.mmOrderItemId
        ? 'row-highlighted'
        : ''
    "
    *ngFor="let data of itemTableData"
  >

  <!-- <td class="dt-control text-center" (click)="handleTableCellClick($event, data)"></td>-->
  <td>
    <ng-container *ngIf="data?.hcpcList?.length >0; else noData">
      <ng-container *ngFor="let hcpcData of data?.hcpcList; let i = index">
        <span [matTooltip]="getHcpcCodes(data?.hcpcList)">
          {{hcpcData?.hcpcCode || '-'}}
          <span *ngIf="i < data?.hcpcList.length - 1">,</span>
        </span>
      </ng-container>
    </ng-container>
    <ng-template #noData>
      <span>-</span>
    </ng-template>
  </td>

    <td [matTooltip]="data?.productCode">
      {{ data?.productCode }}
    </td>

    <td
      [matTooltip]="
        data?.productDescription ? data?.productDescription : ''
      "
    >
      {{
        data?.productDescription
          ? data?.productDescription
          : data?.description
      }}
    </td>
    <td [matTooltip]="data.sizeName ? data.sizeName : '-'">
      {{ data.sizeName ? data.sizeName : "-" }}
    </td>

    <td class="text-right" [matTooltip]="data.qty ? data.qty : '-'">
      {{ data.qty }}
    </td>
    <td
      [matTooltip]="
        data.backOrderedSince
      "
    >
      {{
        data.backOrderedSince
      }}
    </td>
    <td [matTooltip]="data.serialLotName || '-'">
      {{ data.serialLotName || '-'}}
    </td>
    <td>
    <a [class.disabled]="isEmpty(data.trackingNumber)" href="https://www.ups.com/track?loc=en_US&requester=QUIC&tracknum={{data.trackingNumber}}/"
        target="_blank" class="preview-link" [matTooltip]="'Click here to Track'">
    {{ data.trackingNumber ? data.trackingNumber : "-" }}
    </a>
      
    </td>

    <td
      [matTooltip]="
       data.shippingDate
      "
    >
      {{
        data.shippingDate
      }}
    </td>
    <td [matTooltip]="data.packedby || '-'">
      {{ data.packedby || '-' }}
    </td>
  </tr>
</tbody>
</table>
