<div class="container-fluid">
    <div class="row bg">
      <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
        <div class="d-flex align-items-center justify-content-between">
          <div class="row px-3">
          <h2 class="font-weight-bolder mb-2">
            <b class="header-class">Tools / Schedule</b>
          </h2>
          </div>
          <div>
            <form [formGroup]="scheduleSearchForm">

               <!--User Drop Down-->
               <mat-form-field class="mr-3 matFormField">
                <mat-label>
                  <div class="select-placeholder-container" *ngIf="isUserLoading">
                      <span>Loading...</span>
                      <mat-spinner class="spinner" diameter="20"></mat-spinner>
                  </div>
                  <span *ngIf="!isUserLoading">Select Technician</span>
              </mat-label>
                <mat-select formControlName="drpUsertype" multiple (selectionChange)="onSelectionUserChange($event)"
                    [(value)]="userDesignation">

                    <mat-select-trigger *ngIf="UserSelectAllOption">
                      <span>All</span>                      
                    </mat-select-trigger>

                    <mat-option>
                        <ngx-mat-select-search placeholderLabel="Search" noEntriesFoundLabel="No Matches found"
                            formControlName="txtUserTypeV1"></ngx-mat-select-search>
                    </mat-option>
                    <mat-option *ngIf="!UserSelectAllOption" [value]="'selectAll'">Select All</mat-option>
                    <mat-option *ngIf="UserSelectAllOption" [value]="'DeselectAll'">Deselect All</mat-option>
                    <mat-option *ngFor="let users of lstFilterUsers | async" [value]="users?.userId">
                        {{ users?.userDesignation }}
                    </mat-option>
                </mat-select>
            </mat-form-field>

              <!--Branch Drop Down-->
              <mat-form-field class="col-3 matFormField">
                <mat-label>Select Location</mat-label>
                <mat-select #selectBranch formControlName="drpBranch" multiple [(value)]="organizationUnitName"
                    (selectionChange)="onBranchSelectionChange($event)">
                    <mat-select-trigger *ngIf="BranchSelectAllOption">
                      <span>All</span>                      
                    </mat-select-trigger>
                    <mat-option>
                        <ngx-mat-select-search placeholderLabel="Search" noEntriesFoundLabel="No Matches found"
                            formControlName="txtBranch"></ngx-mat-select-search>
                    </mat-option>
                    <mat-option *ngIf="!BranchSelectAllOption" [value]="'selectAll'">Select All</mat-option>
                    <mat-option *ngIf="BranchSelectAllOption" [value]="'DeselectAll'">Deselect All</mat-option>
                    <mat-option *ngFor="let branch of brnachList | async" [value]="branch.id">
                        {{ branch.organizationUnitName }}
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <!--Type Name Drop Down-->
            <mat-form-field class="mr-3 matFormField">
                <mat-label>Select Type Name </mat-label>
                <mat-select formControlName="drpTypeName" multiple [(value)]="description"
                (selectionChange)="onTypeSelectionChange($event)">
                <mat-select-trigger *ngIf="TypeSelectAllOption">
                  <span>All</span>                      
                </mat-select-trigger>
                    <mat-option>
                        <ngx-mat-select-search [formControl]="TypeNameControl" placeholderLabel="Search"
                            noEntriesFoundLabel="No Matches found"
                            formControlName="txtTypeName"></ngx-mat-select-search>
                    </mat-option>
                    <mat-option *ngIf="!TypeSelectAllOption" [value]="'selectAll'">Select All</mat-option>
                    <mat-option *ngIf="TypeSelectAllOption" [value]="'DeselectAll'">Deselect All</mat-option>
                    <mat-option *ngFor="let type of typeDrpData" [value]="type?.aptClassId" [ngStyle]="{'background-color': type?.colorCode}" [style.color]="updateTextColorV2(type?.colorCode)">
                        {{ type?.description }}
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <button mat-button class="buttonColor mr-3" (click)="fnSearchCalendarList()" [disabled]="scheduleSearchForm.invalid || saveButtonDisabled">
              <mat-icon>search</mat-icon> Search
               <mat-icon *ngIf="isShowSpinner">
                   <mat-spinner class="spinner-border spinner-border-sm" diameter="20"></mat-spinner>
               </mat-icon>
           </button>
           <button mat-button class="resetclr mr-3" (click)="resetCalender()" [disabled]="saveButtonDisabled">Reset</button>

          </form>
          </div>
        </div>
      </div>
    </div>
  
  <!-- <div class="card card-body ml-2"> -->
    <!-- Design issue overlap fixed By Manikandan 2024-01-10 -->
    <!-- <div class="d-flex"> -->
      <!-- <div class="col-12">
        <b>Information Status:</b>
        <span class="ml-2 align-items-center">
          Scheduled <span class="dot sea-dot ml-2"></span>
        </span>
        <span class="ml-2 align-items-center">
          Rescheduled <span class="dot orange-dot ml-2"></span>
        </span>
        <span class="ml-2 align-items-center">
          Completed <span class="dot green-dot ml-2"></span>
        </span>
        <span class="ml-2 align-items-center">
          Cancelled <span class="dot red-dot ml-2"></span>
        </span>
      </div> -->
    <!-- </div> -->
  
    <div class="ml-2 mr-2 mt-2">
      <p class="statusCls">Schedule Count : {{scheduleTotalCount}}</p>
      <ejs-schedule #scheduleObj height="650px" [selectedDate]="selectedDate" [eventSettings]="eventSettings"
        currentView="Week" (popupOpen)="onPopupOpen($event)" (actionComplete)="onActionComplete($event)"
        (eventRendered)="applyCategoryColor($event)" (eventClick)="onEventClick($event)" [showQuickInfo]="showQuickInfo"
        (cellClick)="onCellClick($event)" (dragStart)="onDragStart($event)" (dragStop)="onDragStop($event)" (resizeStart)="onResizeStart($event)" (resizeStop)="onResizeStop($event)" (navigating)="onNavigating($event)" [timezone]="'America/Chicago'">
        
        <e-views>
          <e-view option="Day" [timeScale]="{ enable: true }"></e-view>
          <e-view option="Week" [timeScale]="{ enable: true }"></e-view>
          <e-view option="WorkWeek" [timeScale]="{ enable: true }"></e-view>
          <e-view option="Month" [timeScale]="{ enable: true }"></e-view>
        </e-views>
        <e-resources>
          <e-resource *ngIf="show" field="OwnerId" title="Status" name="Schedule" [dataSource]="roomDataSource"
            textField='OwnerText' idField='OwnerText' >
            <!-- [(ngModel)]="selectedOption" -->
          </e-resource>
        </e-resources>
  
        <!-- <e-resources>
          <e-resource *ngIf="show" field="OwnerId" title="Status" name="Schedule" [dataSource]="roomDataSource"
              textField='OwnerText' idField='OwnerText' [allowMultiple]="true" [filterType]="'Menu'">
          </e-resource>
      </e-resources> -->
  
  
        <!-- <ng-template #eventSettingsTemplate let-data>
          <div>
            <div class="subject">{{data?.Subject}}</div>
            <div class="time">
              Status: {{data?.status}}
            </div>
          </div>
        </ng-template> -->
        <!-- <ng-template #groupHeaderTooltipTemplate let-data>
          <div class='template-wrap'>
            <div class="res-text">Name: {{data?.resourceData?.OwnerText}} </div>
          </div>
        </ng-template> -->
      </ejs-schedule>
  
      <ejs-contextmenu #menuObj cssClass='schedule-context-menu' target='.e-schedule' [items]='menuItems' (beforeOpen)='onContextMenuBeforeOpen($event)' (select)='onMenuItemSelect($event)'></ejs-contextmenu>

      <!--
        <ejs-schedule #scheduleObj height="650px" [selectedDate]="selectedDate" [eventSettings]="eventSettings"
          currentView="Week" (popupOpen)="onPopupOpen($event)" (actionComplete)="onActionComplete($event)"
          (eventRendered)="applyCategoryColor($event)" (eventClick)="onEventClick($event)" [showQuickInfo]="showQuickInfo"
          (cellClick)="onCellClick($event)">
  
          <! -- Other schedule settings and configurations -->
  
          <!-- <ng-template #eventTemplate let-data>
            <div class="custom-event-template">
              <div>{{ data.Subject }}</div>
              <div>{{ formatDate(data.StartTime) }} - {{ formatDate(data.EndTime) }}</div>
            </div>
          </ng-template> -->
  
          <!-- <e-resources>
            <e-resource field="isAllDay" title="All Day"></e-resource>
        </e-resources> - ->
  
        </ejs-schedule> -->
    </div>
  </div>
  <!-- </div> -->

  <!-- Loader Div -->
<div class="spinner-container" *ngIf="isScheduleLoading">
  <div class="spinner">
    <div class="double-circle"></div>
  </div>
</div>

  <!-- <app-loader-component *ngIf="isScheduleLoading"></app-loader-component> -->
