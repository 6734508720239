<div class="row p-2" >
    <div class="col-lg-2">
        <div class="card card-body align-items-center scrollable-card p-0" style="height: 80vh">
            <ng-container *ngFor="let image of existingDocumentImageList">
                <div class="card my-2 elevated-div custom-thumbnail-view">
                    <img class="card-img-top img-fluid custom-thumbnail-image" (click)="loadExistingImage(image)"
                        [ngClass]="{
                'custom-thumbnail-view-selected':
                  selectedExistingDocument?.pageNo === image?.pageNo
              }" [src]="image?.src" [alt]="image?.pageNo" />
                    <p class="align-end">Page No : {{ image.pageNo }}</p>
                </div>
            </ng-container>
        </div>
    </div>

    <div class="col-lg-10">

        <div class="card card-body align-items-center justify-content-center p-0">
            <div class="zoom-buttons">
                <button mat-button color="primary" (click)="firstImage()"  [disabled]="isLoading">
                    <mat-icon>first_page</mat-icon>
                </button>
                <button mat-button color="primary" (click)="previousImage()"  [disabled]="isLoading">
                    <mat-icon>keyboard_arrow_left</mat-icon>
                </button>
                <input matInput type="number" class="form-control custom-input" [(ngModel)]="pageNumber" min="1" max="{{totalPages}}"
                    (change)="goToPage()" [readonly]="isLoading" />
                <span> of {{ totalPages }}</span>
                <button mat-button color="primary" (click)="nextImage()"  [disabled]="isLoading" >
                    <mat-icon>keyboard_arrow_right</mat-icon>
                </button>
                <button mat-button color="primary" (click)="lastImage()"  [disabled]="isLoading">
                    <mat-icon>last_page</mat-icon>
                </button>
                <button mat-button color="primary" (click)="zoomIn()"  [disabled]="isLoading">
                    <mat-icon>add</mat-icon>
                </button>
                <button mat-button color="primary" (click)="zoomOut()"  [disabled]="isLoading">
                    <mat-icon>remove</mat-icon>
                </button>

            </div>
        </div>

        <div class="card card-body align-items-center justify-content-center scrollable-card p-0" style="height: 76vh">
            <div class="scrollable-card-image">
                <!-- <h1 *ngIf="selectedExistingDocument == null" class="fw-bolder">
                    {{ loadingMessage }}
                </h1> -->
                <canvas #canvas></canvas>
            </div>
        </div>
    </div>
</div>