<div class="container-fluid mt-1">

    <div class="row bg">
        <div class="col-12">
            <div class="d-flex align-items-center justify-content-between">
                <h3 class="font-weight-bolder mb-0">
                    <b class="header-class">Tools / My Verification</b>
                </h3>

                <div class="page-title-box page-title-right pt-3 pb-0">
                    <ol class="breadcrumb m-0">
                      <div class="row">
                        <div class="col-12 iconcustom m-2">
                            <div class="page-title-box page-title-right statusIcon">
                                <i class="fa fa-check checkicon"></i><span class="ml-2">Done</span>
                                <i class="fa fa-clock-o pendingicon ml-2"></i><span class="ml-2">New/Pending</span>
                                <i class="fa fa-exclamation expicon ml-2"></i><span class="ml-2">Unable To Verify</span>
                                <i class="fa fa-times cansicon ml-2"></i><span class="ml-2">Expired</span>
                                <i class="fa fa-ban Cancelled ml-2"></i><span class="ml-2">Cancelled</span>
                            </div>
                        </div>
                    </div>
                        <form [formGroup]="branchForm">
                            <mat-form-field class="pr-3 mt-3">
                                <mat-label>Branch</mat-label>
                                <mat-select #selectBranch formControlName="drpBranch" multiple
                                    [(value)]="organizationUnitName"
                                    (selectionChange)="onBranchSelectionChange($event)">
                                    <mat-option>
                                        <ngx-mat-select-search placeholderLabel="Search"
                                            noEntriesFoundLabel="No Matches found"
                                            formControlName="txtBranch"></ngx-mat-select-search>
                                    </mat-option>

                                    <!-- <mat-option #selectAllBranch (click)="allBranchSelect()">All</mat-option> -->
                                    <mat-option *ngIf="!BranchSelectAllOption" [value]="'selectAll'">Select All</mat-option>
                                    <mat-option *ngIf="BranchSelectAllOption" [value]="'DeselectAll'">Deselect All</mat-option>

                                    <mat-option *ngFor="let branch of brnachList | async" [value]="branch.id">
                                        {{ branch.organizationUnitName }}
                                    </mat-option>
                                </mat-select>

                            </mat-form-field>
                        </form>
                        <!-- <li>
                            <button matTooltip="Click To New Verification" mat-button
                                class="mr-2  mt-3 buttonColor font-size-10" (click)="openNewVerification()"
                                [disabled]="!selectedPatient">
                                <i class="fa fa-plus "></i>
                            </button>
                        </li> -->
                        <!-- <li>
                            <button matTooltip="Click To Add Note" mat-button
                                class="mr-2  mt-3 buttonColor font-size-10" (click)="openAddNotesPopUp()"
                                [disabled]="!selectedPatient">
                                <i class="fa fa-pencil-square-o"></i>
                            </button>
                        </li> -->
                        <!-- <li>
                            <button matTooltip="Click To View Notes" mat-button (click)="openViewNotesPopup(patientId,docId,docId)"
                                class="mr-2  mt-3 buttonColor font-size-10"
                                [disabled]="!selectedPatient">
                                <i class="fa fa-eye"></i>
                            </button>
                        </li> -->
                    </ol>
                </div>
            </div>
        </div>
    </div>
    <div class="card card-body mt-3">

        <div class="row">
            <div class="col-12">
                <div *ngIf="!isLoading" class="table table-responsive">
                    <app-myverficationtable [tableData]="myverification" (isLoadTbl)="getTableData(selectedBranchList)">

                    </app-myverficationtable>


                </div>
            </div>
        </div>
        <div class="row" *ngIf="isLoading">
            <div class="col-12">
                <div class="d-flex justify-content-center" *ngIf="isLoading">
                    <img class="img-responsive pb-2 mr-1" src="assets/gifs/Infinity-loader.gif" />
                </div>
            </div>
        </div>
    </div>
</div>
