import { PagedAndSortedResultRequestDto } from '@abp/ng.core';
import { HttpErrorResponse } from '@angular/common/http';
import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { RegionDropdownService } from 'projects/patient/src/app/dropdown-proxy/dropdown-management/dropdowns/region-dropdown-service';
import {
  RegionCitiesDTO,
  RegionCountriesDTO,
  RegionStatesDTO,
} from 'projects/patient/src/app/dropdown-proxy/dropdown-management/dropdowns/dto';
import { ComplainceUsageMetDTO } from 'projects/patient/src/app/dropdown-proxy/dropdown-management/dropdowns/dto/order';
import {
  PatientMasterDropdownDTO,
  PlaceOfServiceDTO,
} from 'projects/patient/src/app/dropdown-proxy/dropdown-management/dropdowns/dto/patient';
import { defaultCountry, defaultGuid } from 'projects/shared/src/app/enums/allenums.enum';
import { NumberValidators } from 'projects/shared/src/app/validations/number-validator';
import { Observable, Subscription } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import Swal from 'sweetalert2';
import {
  OrderMasterDropdownService,
  PatientMasterDropdownService,
} from 'projects/patient/src/app/dropdown-proxy/dropdown-management/dropdowns/master-dropdown-service';
import {
  OrderDropdowns,
  PatientDropdowns,
} from 'projects/patient/src/app/dropdown-proxy/dropdown-management/dropdowns';
// import { defaultGuid } from 'projects/admin/src/app/document-perviewer/document-perviewer.component';
import {
  CreateUpdateOrderDetailsDTO,
  OrderDetailsDTO,
} from '../order-proxy/order-management/order-optimization/dto/models';
import { PatientSaleOrderService } from '../order-proxy/order-management/order-optimization/patient-sale-order.service';
import { Title } from '@angular/platform-browser';
import { AddressService } from 'projects/patient/src/app/patient-proxy/patient-optimization';
import { PatientAddressDTO } from 'projects/patient/src/app/patient-proxy/patient-optimization/dto';
import { AddressType } from 'projects/patient/src/app/patient-proxy/patient/enumeration-data';
import { RentalSaleOrderTemplateService } from '../order-proxy/order-management/order-optimization/rental-sale-order-template.service';
import { ToastrService } from 'ngx-toastr';
import { CookieService } from 'ngx-cookie-service';
import { MatDialog } from '@angular/material/dialog';
import { PatientPverifyTabComponent } from 'projects/patient/src/app/patient-pverify-tab/patient-pverify-tab.component';
import { DocumentCheckListComponent } from 'projects/admin/src/app/document-check-list/document-check-list.component';
import { PatientAuthSaveUpdateFormComponent } from 'projects/patient/src/app/patient-auth-save-update-form/patient-auth-save-update-form.component';
import { PatientManualVerificationTabComponent } from 'projects/patient/src/app/patient-manual-verification-tab/patient-manual-verification-tab.component';
@Component({
  selector: 'app-order-tab',
  templateUrl: './order-tab.component.html',
  styleUrls: ['./order-tab.component.scss'],
})
export class OrderTabComponent implements OnInit, OnDestroy {
  @Input() patientId: string = defaultGuid;
  @Input() saleOrderId: string = defaultGuid;
  @Output() orderIdEmitter: EventEmitter<string> = new EventEmitter<string>(
    null
  );
  tenantId: string = defaultGuid;
  saleOrderForm: FormGroup;
  isShowSpinner: boolean = false;
  @Input() isTemplate: boolean = false;
  saveButtonHide: boolean = false;
  status: string;
  phoneCode: string = '';
  scheduledDate: Date;
  generalPlaceOfService: string;
  city: string = '';
  country: string = '';
  state: string = '';
  show: boolean;
  orderStatus: boolean = true;
  editFormValues: boolean = false;
  isNewPatient: any;
  drpPlace: PlaceOfServiceDTO[] = [];
  drpComplaince: ComplainceUsageMetDTO[] = [];
  drpStates: any[] = [];
  drpCities: any[] = [];
  drpCountry: any[] = [];
  subscription$: Subscription[] = [];

  public filteredCountries: Observable<RegionCountriesDTO[]>;
  public filteredStates: Observable<RegionStatesDTO[]>;
  public filteredCities: Observable<RegionCitiesDTO[]>;
  defualtSaleOrderId: string = '';

  constructor(
    private title: Title,
    private formBuilder: FormBuilder,
    private numberValidators: NumberValidators,
    private region: RegionDropdownService,
    private patientAddressService: AddressService,
    private orderDropdownService: OrderMasterDropdownService,
    private patientDropdownService: PatientMasterDropdownService,
    private orderService: PatientSaleOrderService,
    private toastr: ToastrService,
    private rentalSaleOrderTemplateService: RentalSaleOrderTemplateService,
    private cookieService: CookieService,
    private dialog: MatDialog
  ) {}

  ngOnInit(): void {
    //Form Initialization
    this.saleOrderForm = this.formBuilder.group({
      txtCountryFilter: new FormControl(''),
      txtCityFilter: new FormControl(''),
      txtStateFilter: new FormControl(''),
      txtScheduledDate: new FormControl('', Validators.required),
      txtScheduledTime: new FormControl(''),
      txtActualDate: new FormControl('', Validators.required),
      txtActualTime: new FormControl(''),
      txtAddress: new FormControl('', Validators.required),
      drpCity: new FormControl('', Validators.required),
      drpState: new FormControl('', Validators.required),
      drpCounty: new FormControl(''),
      drpCountry: new FormControl(''),
      txtPostalCode: new FormControl('', Validators.required),
      txtPhone: new FormControl('', [this.numberValidators.isNumberCheck]),
      txtMobile: new FormControl('', [this.numberValidators.isNumberCheck]),
      txtOrderNote: new FormControl(''),
      txtDeliveryNote: new FormControl(''),
      txtStatus: new FormControl(''),
      drpGeneralPlaceOfService: new FormControl('', Validators.required),
      chkCashPayment: new FormControl(''),
      // chkOrderCompleted: new FormControl(''),

      txtPrimaryInsurance: new FormControl(''),

      txtSecondaryInsurance: new FormControl(''),

      txtChecklist: new FormControl(''),

      txtAuthorization: new FormControl(''),
      txtPatientComments: new FormControl(''),
      txtShippingComments: new FormControl(''),
      txtBillingComments: new FormControl(''),
      drpCSR: new FormControl(''),
      txtCSRFilter: new FormControl(''),
      drpLocation: new FormControl(''),
      txtLocationFilter: new FormControl(''),
      chkPAPTherapy: new FormControl(''),
      drpShippingMethod: new FormControl(''),
      drpOrderingProvider: new FormControl(''),
      drpPracticeManagement: new FormControl(''),
      drpPreferredBrand: new FormControl(''),
      txtTechComments: new FormControl(''),
    });

    this.isNewPatient = this.cookieService.get('orderStatus');
    if (this.isNewPatient == 'true') {
      this.saleOrderForm.enable();
      this.editFormValues = true;
    } else {
      this.saleOrderForm.disable(), (this.editFormValues = false);
    }

    this.countryDropdown();
    this.getDropdown();
    // this.getBillingAddress();
  }
  // On Page Destroy
  ngOnDestroy(): void {
    this.subscription$?.forEach((sub) => {
      sub && sub?.unsubscribe();
    });
  }

  // Country Dropdown
  countryDropdown() {
    this.saleOrderForm.patchValue({ drpCountry: defaultCountry.US });
    this.loadState(defaultCountry.US);
    this.country = defaultCountry.US;
    this.drpStates = [];
    this.drpCountry = [];
    this.drpCities = [];
    const countryDropdown = this.region
      .getAllCountriesByInput(new PagedAndSortedResultRequestDto())
      .subscribe(
        (response) => {
          this.drpCountry = response?.items;
          this.filteredCountries = this.saleOrderForm
            ?.get('txtCountryFilter')
            .valueChanges.pipe(
              startWith(''),
              map((value) =>
                this.drpCountry.filter((option) =>
                  option?.countryName
                    ?.toLowerCase()
                    ?.includes(value?.toLowerCase() ?? '')
                )
              )
            );
        },
        (err) => {
          const data: HttpErrorResponse = err;
          Swal.fire({
            icon: 'info',
            text: data?.error?.error?.message,
          });
        }
      );
    this.subscription$.push(countryDropdown);
  }
  editForm() {
    this.editFormValues = true;
    this.saleOrderForm.enable();
  }
  // To Load the State based on the Country Selection
  loadState(value: string | null | undefined): boolean {
    this.saleOrderForm.patchValue({
      drpCity: null,
      drpState: null,
    });
    this.drpStates = [];
    this.drpCities = [];
    if (value !== null && value !== undefined) {
      const getState = this.region
        .getAllStatesByCountryNameByCountryShortName(value)
        .subscribe(
          (response) => {
            this.drpStates = response?.items;

            if (response?.items?.length == 0) {
              this.phoneCode = '';
              // this.saleOrderForm.controls.txtMobile.disable();// commed for form disabled
              // this.saleOrderForm.controls.txtPhone.disable();
            } else {
              // this.saleOrderForm.controls.txtMobile.enable(); // commed for form disabled
              // this.saleOrderForm.controls.txtPhone.enable();
              this.phoneCode = response?.items[0]?.countryPhoneCode;
            }

            this.filteredStates = this.saleOrderForm
              ?.get('txtStateFilter')
              .valueChanges.pipe(
                startWith(''),
                map((value) =>
                  this.drpStates.filter((option) =>
                    option?.stateName
                      ?.toLowerCase()
                      ?.includes(value?.toLowerCase() ?? '')
                  )
                )
              );
          },
          (err) => {
            const data: HttpErrorResponse = err;
            Swal.fire({
              icon: 'info',
              text: data?.error?.error?.message,
            });
          }
        );
      this.subscription$.push(getState);
    }
    return this.drpStates && this.drpStates?.length !== 0;
  }

  //To Load the Cities based on the State Selection
  loadCity(state: string | null | undefined, country: string): boolean {
    this.saleOrderForm.patchValue({
      drpCity: null,
    });

    country =
      country == ''
        ? this.saleOrderForm.value.drpCountry == (null || undefined)
          ? ''
          : this.saleOrderForm.value.drpCountry
        : country;
    this.drpCities = [];
    if (state !== null && state !== undefined) {
      const getCities = this.region
        .getAllCitiesByCountryAndStateNameByCountryShortNameAndStateShortName(
          country,
          state
        )
        .subscribe(
          (response) => {
            this.drpCities = response?.items;
            this.filteredCities = this.saleOrderForm
              ?.get('txtCityFilter')
              .valueChanges.pipe(
                startWith(''),
                map((value) =>
                  this.drpCities.filter((option) =>
                    option?.cityName
                      ?.toLowerCase()
                      ?.includes(value?.toLowerCase() ?? '')
                  )
                )
              );
          },
          (err) => {
            const data: HttpErrorResponse = err;
            Swal.fire({
              icon: 'info',
              text: data?.error?.error?.message,
            });
          }
        );
      this.subscription$.push(getCities);
    }
    return this.drpCities && this.drpCities?.length !== 0;
  }

  // Master Dropdown Funtion
  getDropdown() {
    let orderDropdownInput: OrderDropdowns[];
    orderDropdownInput = [OrderDropdowns.StopReasons, OrderDropdowns.Statuses];
    const orderDropdown = this.orderDropdownService
      .getOrderDropdownsByInput(orderDropdownInput)
      .subscribe(
        (stateResponse) => {
          this.drpComplaince = stateResponse?.statuses;
          this.saleOrderId == defaultGuid && this.getBillingAddress();
          this.saleOrderId != defaultGuid && this.getOrderByid(); //Get Order Details by GUID
          this.patientId !== defaultGuid &&
            this.title.setTitle('Qsecure | New Order');
          this.saleOrderId !== defaultGuid &&
            this.title.setTitle('Qsecure | View Order');
        },
        (err) => {
          const data: HttpErrorResponse = err;
          Swal.fire({
            icon: 'info',
            text: data?.error?.error?.message,
          });
        }
      );
    this.subscription$.push(orderDropdown);
    let patientDropdownInput: PatientDropdowns[];
    patientDropdownInput = [
      PatientDropdowns.BranchOffices,
      PatientDropdowns.Facilities,
      PatientDropdowns.TaxZones,
      PatientDropdowns.HoldReasons,
      PatientDropdowns.PlaceOfServices,
    ];
    const patientDropdown = this.patientDropdownService
      .getPatientDropdownsByInput(patientDropdownInput)
      .subscribe(
        (stateResponse) => {
          const response: PatientMasterDropdownDTO = stateResponse;
          this.drpPlace = response?.placeOfServices;
        },
        (err) => {
          const data: HttpErrorResponse = err;
          Swal.fire({
            icon: 'info',
            text: data?.error?.error?.message,
          });
        }
      );

    this.subscription$.push(patientDropdown);
  }

  //To Complete the Order
  orderCompleteConfirmation() {
    Swal.fire({
      title: 'Are you sure you want to complete?',
      text: 'Order details cannot be modifed in further',
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#34c38f',
      cancelButtonColor: '#f46a6a',
      confirmButtonText: 'Yes, Confirm!',
    }).then((result) => {
      if (result.value) {
        this.saleOrderForm.patchValue({
          chkOrderCompleted: result.isConfirmed,
        });
        this.saveOrUpdateOrder();
      } else {
        // this.saleOrderForm.patchValue({
        //   chkOrderCompleted: result.isConfirmed
        // })
        // this.saveOrUpdateOrder();
      }
    });
  }

  //To Save the Order
  saveOrUpdateOrder() {
    this.isShowSpinner = true;
    this.saveButtonHide = true;

    const orderData: CreateUpdateOrderDetailsDTO = {
      patientId: this.patientId,
      scheduledDate: this.saleOrderForm.value.txtScheduledDate,
      scheduledTime: this.saleOrderForm.value.txtScheduledTime,
      actualDate: this.saleOrderForm.value.txtActualDate,
      actualTime: this.saleOrderForm.value.txtActualTime,
      address: this.saleOrderForm.value.txtAddress,
      city:
        this.saleOrderForm.value.drpCity === null || undefined
          ? ''
          : this.saleOrderForm.value.drpCity,
      state:
        this.saleOrderForm.value.drpState === null || undefined
          ? ''
          : this.saleOrderForm.value.drpState,
      county:
        this.saleOrderForm.value.drpCounty === null || undefined
          ? ''
          : this.saleOrderForm.value.drpCounty,
      country:
        this.saleOrderForm.value.drpCountry === null || undefined
          ? ''
          : this.saleOrderForm.value.drpCountry,
      postalCode: this.saleOrderForm.value.txtPostalCode,
      phone: this.saleOrderForm.value.txtPhone,
      mobile: this.saleOrderForm.value.txtMobile,
      orderNote: this.saleOrderForm.value.txtOrderNote,
      deliveryNote: this.saleOrderForm.value.txtDeliveryNote,
      status: this.saleOrderForm.value.txtStatus,
      placeOfService: this.saleOrderForm.value.drpGeneralPlaceOfService,
      isCashPayment: +this.saleOrderForm.value.chkCashPayment | 0,
      isCompleted: Boolean(this.saleOrderForm.value.chkOrderCompleted),
      payPalMailResendCount: 0,
      payPalPaymentMethodType: '',
      payPalPaymentStatus: '',
      tenantId: this.tenantId,
      defaultSaleorderId: this.defualtSaleOrderId,
      isTemplate: this.isTemplate ? 1 : 0,
    };
    this.saleOrderId = this.saleOrderId ?? defaultGuid;
    //Patient Save
    if (this.saleOrderId == '' || this.saleOrderId == defaultGuid) {
      this.orderService.create(orderData).subscribe(
        (response) => {
          // Swal.fire({
          //   title: 'Success',
          //   html: 'Saved Successfully',
          //   icon: 'success',
          //   timer: 3000,
          //   timerProgressBar: true,
          // }).then(
          //   (result) => {
          //     if (result.value) {
          //       // !change next tab
          //       //this.nextTabMoveOnSaveEmitter.emit("Order");
          //     }
          //     this.isShowSpinner = false;
          //   },
          //   () => { }
          // );
          this.toastr.success('Saved Successfully', 'Success');
          this.isShowSpinner = false;
          this.orderIdEmitter.next(response?.id);

          this.saleOrderId = response?.id;
          this.saveButtonHide = false;
          this.isShowSpinner = false;
        },
        (err) => {
          this.saveButtonHide = false;
          this.isShowSpinner = false;
          const errData: HttpErrorResponse = err;
          Swal.fire({
            icon: 'info',
            text: errData?.error?.error?.message,
          });
        }
      );
    }
    //Order Update
    else if (this.saleOrderId !== defaultGuid || this.saleOrderId !== '') {
      this.orderService.update(this.saleOrderId, orderData).subscribe(
        (response) => {
          // Swal.fire({
          //   title: 'Success',
          //   html: 'Updated Successfully',
          //   icon: 'success',
          //   timer: 3000,
          //   timerProgressBar: true,
          // });
          this.toastr.success('Updated Successfully', 'Success');
          this.orderIdEmitter.next(response?.id);
          this.saleOrderId = response?.id;
          this.saveButtonHide = false;
          this.isShowSpinner = false;
        },
        (err) => {
          this.saveButtonHide = false;
          this.isShowSpinner = false;
          const errData: HttpErrorResponse = err;
          Swal.fire({
            icon: 'info',
            text: errData?.error?.error?.message,
          });
        }
      );
    }
  }

  //To get the Order by Id
  getOrderByid() {
    if (
      this.saleOrderId != '' &&
      this.saleOrderId != null &&
      this.saleOrderId != undefined
    ) {
      if (this.isTemplate) {
        const orderGet = this.rentalSaleOrderTemplateService
          .getSaleOrderDetailsByTemplateByGSaleOrderId(this.saleOrderId)
          .subscribe(
            (response) => {
              this.patchOrderDetails(response);
            },
            (err) => {
              const data: HttpErrorResponse = err;
              Swal.fire({
                icon: 'info',
                text: data?.error?.error?.message,
              });
            }
          );
        this.subscription$.push(orderGet);
      } else {
        const orderGet = this.orderService.get(this.saleOrderId).subscribe(
          (response) => {
            this.patchOrderDetails(response);
          },
          (err) => {
            const data: HttpErrorResponse = err;
            Swal.fire({
              icon: 'info',
              text: data?.error?.error?.message,
            });
          }
        );
        this.subscription$.push(orderGet);
      }
    }
  }
  patchOrderDetails(response: OrderDetailsDTO) {
    const orderDetail: OrderDetailsDTO = response;
    this.orderIdEmitter.next(response?.id);

    this.tenantId = orderDetail?.tenantId ?? '';
    this.country = orderDetail?.country ?? '';

    if (this.country !== '' && this.loadState(this.country)) {
      this.saleOrderForm.patchValue({ drpCountry: this.country });
    }

    if (
      (orderDetail?.state ?? '') !== '' &&
      this.loadCity(orderDetail?.state, this.country)
    ) {
      this.state = orderDetail?.state ?? '';

      this.saleOrderForm.patchValue({
        drpState: this.state,
        drpCity: orderDetail?.city,
      });
    }
    this.defualtSaleOrderId = response?.defaultSaleOrderId ?? '';

    //Patching values to the fields
    orderDetail !== null &&
      orderDetail !== undefined &&
      this.saleOrderForm.patchValue({
        txtScheduledDate:
          orderDetail?.scheduledDate == null ? '' : orderDetail?.scheduledDate,
        txtScheduledTime: orderDetail?.scheduledTime,
        txtActualDate: orderDetail?.actualDate,
        txtActualTime: orderDetail?.actualTime,
        txtAddress: orderDetail?.address,
        drpCity:
          orderDetail?.city === '' || undefined ? null : orderDetail?.city,
        drpState:
          orderDetail?.state === '' || undefined ? null : orderDetail?.state,
        drpCounty:
          orderDetail?.county === '' || undefined ? null : orderDetail?.county,
        drpCountry:
          orderDetail?.country === '' || undefined
            ? null
            : orderDetail?.country,
        txtPostalCode: orderDetail?.postalCode,
        txtPhone: orderDetail?.phone,
        txtMobile: orderDetail?.mobile,
        txtOrderNote: orderDetail?.orderNote,
        txtDeliveryNote: orderDetail?.deliveryNote,
        chkOrderCompleted: orderDetail?.isCompleted,
        txtStatus: orderDetail?.status,
        chkCashPayment: +orderDetail?.isCashPayment,
        drpGeneralPlaceOfService: orderDetail?.placeOfService,
      });
  }

  getBillingAddress() {
    if (
      this.patientId != '' &&
      this.patientId != null &&
      this.patientId != undefined
    ) {
      const patientAddress = this.patientAddressService
        .getAddressByPatientIdByPatientId(this.patientId)
        .subscribe(
          (response) => {
            let billingAddressDetails: PatientAddressDTO;
            billingAddressDetails = response?.filter(
              (e) => e?.addressType === AddressType.Billing
            )?.[0];

            this.country = billingAddressDetails?.country ?? '';

            if (this.country !== '' && this.loadState(this.country)) {
              this.saleOrderForm.patchValue({ drpCountry: this.country });
            }

            if (
              (billingAddressDetails?.state ?? '') !== '' &&
              this.loadCity(billingAddressDetails?.state, this.country)
            ) {
              this.state = billingAddressDetails?.state ?? '';

              this.saleOrderForm.patchValue({
                drpState: this.state,
                drpCity: billingAddressDetails?.city,
              });
            }

            billingAddressDetails !== null &&
              billingAddressDetails !== undefined &&
              this.saleOrderForm.patchValue({
                txtAddress: billingAddressDetails?.address,
                drpCity:
                  billingAddressDetails?.city === '' || undefined
                    ? null
                    : billingAddressDetails?.city,
                drpState:
                  billingAddressDetails?.state === '' || undefined
                    ? null
                    : billingAddressDetails?.state,
                drpCounty:
                  billingAddressDetails?.county === '' || undefined
                    ? null
                    : billingAddressDetails?.county,
                drpCountry:
                  billingAddressDetails?.country === '' || undefined
                    ? null
                    : billingAddressDetails?.country,
                txtPostalCode: billingAddressDetails?.postalCode,
                txtPhone: billingAddressDetails?.phone,
                txtMobile: billingAddressDetails?.mobile,
              });
          },
          (err) => {
            const errData: HttpErrorResponse = err;
            Swal.fire({
              icon: 'info',
              text: errData?.error?.error?.message,
            });
          }
        );

      this.subscription$.push(patientAddress);
    }
  }

  // Cash Payment Confirmation Popup
  cashPaymentClick() {
    if (
      this.saleOrderForm.value.chkCashPayment == 0 ||
      this.saleOrderForm.value.chkCashPayment == false
    ) {
      Swal.fire({
        title: 'Are you sure you want to Proceed With Cash Payment?',
        text: 'You Can Proceed this Order with Cash Payment',
        icon: 'question',
        showCancelButton: true,
        confirmButtonColor: '#34c38f',
        cancelButtonColor: '#f46a6a',
        cancelButtonText: 'No, cancel it!',
        confirmButtonText: 'Yes, Confirm!',
      }).then((result) => {
        if (result?.value === true) {
          this.saleOrderForm.patchValue({
            chkCashPayment: true,
          });
        } else
          this.saleOrderForm.patchValue({
            chkCashPayment: false,
          });
      });
    } else if (this.saleOrderForm.value.chkCashPayment == true) {
      Swal.fire({
        title: 'Are you sure you want to Proceed Without Cash Payment?',
        text: 'You Can Proceed this Order without Cash Payment',
        icon: 'question',
        showCancelButton: true,
        confirmButtonColor: '#34c38f',
        cancelButtonColor: '#f46a6a',
        cancelButtonText: 'No, cancel it!',
        confirmButtonText: 'Yes, Confirm!',
      }).then((result) => {
        if (result?.value === true) {
          this.saleOrderForm.patchValue({
            chkCashPayment: false,
          });
        } else
          this.saleOrderForm.patchValue({
            chkCashPayment: true,
          });
      });
    }
  }

  //To Clear the City
  clearCity() {
    this.city = '';
    this.saleOrderForm.patchValue({
      drpCity: null,
    });
  }

  //To Clear the State
  clearState() {
    this.state = '';
    this.saleOrderForm.patchValue({
      drpState: null,
    });
  }

  //To clear the country
  clearCountry() {
    this.country = '';
    this.phoneCode = '';
    const delivery: any = this.saleOrderForm.controls;
    this.saleOrderForm.controls.txtMobile.disable();
    this.saleOrderForm.controls.txtPhone.disable();
    this.saleOrderForm.patchValue({
      drpCountry: null,
    });
  }

  //To clear the Status
  clearStatus() {
    this.status = '';
    this.saleOrderForm.patchValue({
      txtStatus: null,
    });
  }
  //Clear General Place of Service
  clearGeneralPlaceOfService() {
    this.generalPlaceOfService = '';
    this.saleOrderForm.patchValue({
      drpGeneralPlaceOfService: null,
    });
  }
  //Open Verification Modal
  viewVerificationById(

  ): void {
    // patientId: string,
    // policyId: string,
    // verificationId: string
    const dialogRef = this.dialog.open(PatientManualVerificationTabComponent, {
      data: {
        patientId: defaultGuid,
        policyId: defaultGuid,
        verificationId: defaultGuid,
        savehidden: true,
      },
    });

  }

  //Open Verification Modal
  openPverifyModal() {
    const dialogRef = this.dialog.open(PatientPverifyTabComponent, {
      disableClose: true,
      data: { patientId: this.patientId, policyId: defaultGuid },
    });
  }
  //Open CheckList Modal
  checkListModel() {
    const dialogRef = this.dialog.open(DocumentCheckListComponent, {
      // height: '75%',
      width: '80%',
      disableClose: true,
      data: {
        // Add any data you want to pass to the dialog here
        // For example, you can pass patientId and documentId

        documentId: defaultGuid,
      },
    });
  }
  //Do Authorization
  doprecert() {
    const dialogRef = this.dialog.open(PatientAuthSaveUpdateFormComponent);
    const componentInstance = dialogRef.componentInstance;
    componentInstance.patientId = defaultGuid;
    componentInstance.docId = defaultGuid;
    componentInstance.isView = true;
    componentInstance.isSupplies = true;
  }
  //View Authorization
  viewprecert() {
    const dialogRef = this.dialog.open(PatientAuthSaveUpdateFormComponent);
    const componentInstance = dialogRef.componentInstance;
    componentInstance.patientId = defaultGuid;
    componentInstance.docId = defaultGuid;
    componentInstance.isView = false;
    componentInstance.isSupplies = true;
  }
}
