import { PagedAndSortedResultRequestDto } from '@abp/ng.core';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatAccordion } from '@angular/material/expansion';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { SaleOrderSearchService } from 'projects/order/src/app/order-proxy/order-management/order/sale-order-search.service';
import { SaleorderService } from 'projects/order/src/app/order-proxy/order-management/order/saleorder.service';
import { PaymentTypeDTO, PaymentTypeOptionsDTO } from 'projects/patient/src/app/dropdown-proxy/dropdown-management/dropdowns/dto/patient/models';
import { PatientMasterDropdownService } from 'projects/patient/src/app/dropdown-proxy/dropdown-management/dropdowns/master-dropdown-service/patient-master-dropdown.service';
import { PatientDropdowns } from 'projects/patient/src/app/dropdown-proxy/dropdown-management/dropdowns/patient-dropdowns.enum';
import { TableService } from 'projects/shared/src/app/table.service';
import Swal from 'sweetalert2';
import emailMask from 'text-mask-addons/dist/emailMask';

import { PaymentSettingService } from '../admin-proxy/platform-app-management/rcm/platform-management/tenant/payment-setting.service';
import { CreateUpdatePaymentSettingsDTO, PaymentOptionsDTO } from '../admin-proxy/platform-app-management/rcm/platform-management/tenants/models';
import { PaymentViewComponent } from '../payment-view/payment-view.component';
import { ToastrService } from "ngx-toastr";
import { Title } from '@angular/platform-browser';




@Component({
  selector: 'app-payment-setup',
  templateUrl: './payment-setup.component.html',
  styleUrls: ['./payment-setup.component.scss']
})
export class PaymentSetupComponent implements OnInit {
  isLoading:boolean ;
  step: boolean = false;
  public strPageType: string = "paymentSetup";
  @ViewChild('firstAccordion') firstAccordion: MatAccordion;
  emailMask: any;

  @Input() clientId: string = '';
  paymentSetupForm: FormGroup;
  paymentOptionId: string = '';
  paymentType: string = "";
  paymentOptionName: string = "";
  isMerchantHelp: boolean = false;
  isPaymentOptionName: boolean = false;
  drpPaymentDueDays: string = "";
  lstPaymentDueDays: any[] = [];
  @Output() nextTabMoveOnSaveEmitter: EventEmitter<string> = new EventEmitter<string>();
    isShowSpinner: boolean = false;


  isPaymentType: boolean = false;
  showHelp: { isMerchantHelp: boolean, isPaymentOptionName: boolean, isPaymentType: boolean, helpText: string } = { isMerchantHelp: false, isPaymentOptionName: false, isPaymentType: false, helpText: '' };
  drpPaymentType: PaymentTypeDTO[] = [];
  drpPaymentTypeOptions: PaymentTypeOptionsDTO[] = [];
  dataSource: MatTableDataSource<any> = new MatTableDataSource<any>([]);
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  arrDisplayedColumns: string[] = [ 'Options','patientId', 'name', 'orderId', 'status', 'paymentMode', 'date', ];
  verifyTableData: {
    patientId: string
    PatientName: string
    createDate: string
    status: string
    saleOrderId: string
    id: string
    checkoutOrInvoice: string
  }[] = [];
  constructor(
    private formBuilder: FormBuilder,
    private paymentService: PaymentSettingService,
    private patientDropdownService: PatientMasterDropdownService,
    private tableService: TableService, public dialog: MatDialog,
    private orderService: SaleorderService,
    private orderSearchService: SaleOrderSearchService,
    private toastr: ToastrService,
    public title: Title,
  ) { }

  ngOnInit(): void {
    this.title.setTitle("Qsecure | Paypal");
    this.emailMask = emailMask;
    this.tableService.setPaymentSetupList([]);
    this.paymentSetupForm = this.formBuilder.group({
      paymentOptionName: new FormControl("", [Validators.required]),
      paymentType: new FormControl("", [Validators.required]),
      merchantId: new FormControl("", [Validators.required]),
      chkEnabled: new FormControl(""),
      txtPaypalLoginEmailAddress: new FormControl("", [Validators.required]),
      drpPaymentDueDays: new FormControl("", [Validators.required]),
    });

    const tenantId: string = this.clientId;
    // tenantId != null && tenantId != "" && tenantId != undefined && this.getTenantDetailsByID(tenantId);
    this.getDropdown();
    tenantId != null && tenantId != "" && tenantId != undefined && this.getTableListData(tenantId);
    this.getOrderTable();
  }

  clearPaymentOptionName() {
    this.paymentOptionName = "";
    this.paymentSetupForm.patchValue({ paymentOptionName: '' });
  }
  clearPaymentName() {
    this.paymentType = "";
    this.paymentSetupForm.patchValue({ paymentType: '' });
  }

  clearPaymentDueDays() {
    this.drpPaymentDueDays = "";
    this.paymentSetupForm.patchValue({ drpPaymentDueDays: '' });
  }

  getTableListData(tenantId: string) {
    this.isLoading=true;
    this.paymentService.getPaymentSettings(tenantId).subscribe(response => {
      if (response && response?.settings?.paymentSettings && response?.settings?.paymentSettings?.paymentOptions !== [] && response?.settings?.paymentSettings?.paymentOptions?.length !== 0) {
        let paymentOptions: PaymentOptionsDTO[] = response?.settings?.paymentSettings?.paymentOptions;
        this.tableService.setPaymentSetupList(paymentOptions);
      } else {
        this.tableService.setPaymentSetupList([]);
      }
      this.isLoading=false;
    }, err => {
      this.isLoading=false;
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    });
  }
  getDropdown() {
    this.drpPaymentType = [];
    this.drpPaymentTypeOptions = [];
    let patientDropdownInput: PatientDropdowns[];
    patientDropdownInput = [
      PatientDropdowns.PaymentTypes,
      PatientDropdowns.PaymentTypeOptions,
      PatientDropdowns.PaymentDueDays
    ]
    this.patientDropdownService.getPatientDropdownsByInput(patientDropdownInput).subscribe(response => {
      if (response && response?.lstPaymentType && response?.lstPaymentTypeOptions) {
        this.drpPaymentType = response?.lstPaymentType;
        this.drpPaymentTypeOptions = response?.lstPaymentTypeOptions;
        this.lstPaymentDueDays = response?.lstPaymentDueDays;

      }
    }, err => {
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    });



  }
  getTenantDetailsByID(paymentOptions: PaymentOptionsDTO) {
    const tenantId: string = this.clientId;
    this.paymentService.getPaymentSettings(tenantId).subscribe(response => {
      this.step = !this.step;
      if (response && response?.settings?.paymentSettings && response?.settings?.paymentSettings?.paymentOptions !== [] && response?.settings?.paymentSettings?.paymentOptions?.length !== 0) {
        const responsePaymentOptions: PaymentOptionsDTO[] = response?.settings?.paymentSettings?.paymentOptions;
        const selectedPaymentOptions: PaymentOptionsDTO = responsePaymentOptions.filter(e => e.paymentOptionId === paymentOptions.paymentOptionId)[0] ?? null;
        if (paymentOptions?.paymentOptionId && responsePaymentOptions && selectedPaymentOptions) {
          this.paymentSetupForm.patchValue({
            merchantId: selectedPaymentOptions?.merchantId,
            paymentOptionName: selectedPaymentOptions?.paymentOptionName,
            paymentType: selectedPaymentOptions?.paymentType,
            chkEnabled: selectedPaymentOptions?.isEnabled,
            txtPaypalLoginEmailAddress: selectedPaymentOptions?.paypalLoginEmailAddress,
            drpPaymentDueDays: selectedPaymentOptions?.paymentDueDays
          })
          this.drpPaymentDueDays = "";
          this.paymentOptionId = selectedPaymentOptions?.paymentOptionId ?? "";
        }
      }
    }, err => {
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    });
  }

  savePaymentDetails() {
    this.isShowSpinner = true;
    const tenantId: string = this.clientId;

    if (this.paymentOptionId === "" || this.paymentOptionId === null) {
      const input: CreateUpdatePaymentSettingsDTO = {
        paymentOptionId: "00000000-0000-0000-0000-000000000000",
        merchantId: this.paymentSetupForm.value?.merchantId ?? "",
        paymentOptionName: this.paymentSetupForm.value?.paymentOptionName ?? "",
        paymentType: this.paymentSetupForm.value?.paymentType ?? "",
        isEnabled: this.paymentSetupForm.value?.chkEnabled ? 1 : 0,
        paypalLoginEmailAddress: this.paymentSetupForm.value?.txtPaypalLoginEmailAddress.toLowerCase(),
        paymentDueDays: this.paymentSetupForm.value?.drpPaymentDueDays,
      };
      this.paymentService.savePaymentSettings(tenantId, input).subscribe(response => {
        // Swal.fire({ title: 'Success', html: 'Saved Successfully', icon: 'success', timer: 3000, timerProgressBar: true });
        this.toastr.success('Saved Successfully','Success')
        this.getTableListData(tenantId);
        this.resetPaymentDetailsForm();
        this.isShowSpinner = false;
      }, err => {
        this.isShowSpinner = false;
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });

      });
      return
    }

    if (this.paymentOptionId !== "" && this.paymentOptionId !== null && this.paymentOptionId !== undefined) {
      this.updatePaymentDetails();
      return
    }


  }

  updatePaymentDetails() {
    const tenantId: string = this.clientId;
    const modifiedInput: CreateUpdatePaymentSettingsDTO = {
      paymentOptionId: this.paymentOptionId ?? "",
      paymentOptionName: this.paymentSetupForm.value?.paymentOptionName ?? "",
      paymentType: this.paymentSetupForm.value?.paymentType ?? "",
      isEnabled: this.paymentSetupForm.value?.chkEnabled ? 1 : 0,
      merchantId: this.paymentSetupForm.value?.merchantId ?? "",
      paypalLoginEmailAddress: this.paymentSetupForm.value?.txtPaypalLoginEmailAddress.toLowerCase(),
      paymentDueDays: this.paymentSetupForm.value?.drpPaymentDueDays,
    };
    this.paymentService.updatePaymentSettings(tenantId, modifiedInput).subscribe(response => {
      // Swal.fire({ title: 'Success', html: 'Saved Successfully', icon: 'success', timer: 3000, timerProgressBar: true });
      this.toastr.success('Saved Successfully','Success')
      this.getTableListData(tenantId);
      this.resetPaymentDetailsForm();
    }, err => {
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });

    });
  }
  resetPaymentDetailsForm() {
    this.paymentSetupForm.patchValue({
      paymentOptionName: "",
      paymentType: "",
      merchantId: ""
    });
    this.paymentOptionName = "";
    this.paymentType = "";
    this.paymentOptionId = '';
    this.toggleHelpContext();
    this.paymentSetupForm.updateValueAndValidity();
    this.paymentSetupForm.markAsUntouched();
    this.paymentSetupForm.reset();
  }
  toggleHelpContext(value?: { isMerchantHelp: boolean; isPaymentOptionName: boolean; isPaymentType: boolean; }, type?: string) {
    if (!value?.isMerchantHelp && type === 'isMerchantHelp') {
      this.showHelp = {
        isMerchantHelp: true, isPaymentOptionName: false, isPaymentType: false, helpText: `Login to your PayPal account,
      Click the Settings icon on the top right.
      Select “Account Settings”,
      On the left column, under “Business Profile” click “Business Information”.
      The PayPal Merchant ID should display.`      }; return;
    }
    // if (!value?.isPaymentOptionName && type === 'isPaymentOptionName') {
    //   this.showHelp = { isMerchantHelp: false, isPaymentOptionName: true, isPaymentType: false, helpText: "" }; return;
    // }
    // if (!value?.isPaymentType && type === 'isPaymentType') {
    //   this.showHelp = { isMerchantHelp: false, isPaymentOptionName: false, isPaymentType: true, helpText: "" }; return;
    // };
    this.showHelp = { isMerchantHelp: false, isPaymentOptionName: false, isPaymentType: false, helpText: "" };
  }

  deletePaymentOption(value: PaymentOptionsDTO) {

    const tenantId: string = this.clientId;

    Swal.fire({
      title: 'Are you sure you want to Delete?',
      text: 'You won\'t be able to retrieve this data!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#34c38f',
      cancelButtonColor: '#f46a6a',
      confirmButtonText: 'Yes, delete it!'
    }).then(result => {
      if (result.value && value && value?.paymentOptionId) {
        this.paymentService.deletePaymentSettingsByTenantIdAndPaymentOptionId(this.clientId, value.paymentOptionId).subscribe(response => {
          this.getTableListData(tenantId);
        }, err => {
          const data: HttpErrorResponse = err;
          Swal.fire({
            icon: 'info',
            text: data?.error?.error?.message,
          });
        });
      }
    });
  }

  //! get Payment Order Table list
  getOrderTable() {
    this.isLoading=true;
    this.orderSearchService.getList(new PagedAndSortedResultRequestDto).subscribe(response => {
      this.verifyTableData = [];
      response?.items?.map(value => {
        if (value.isCashPayment === 1 && value?.paymentTransactionDetails?.payPalCheckOutOrderPayments && value?.paymentTransactionDetails?.payPalCheckOutOrderPayments.length !== 0) {
          this.verifyTableData.push({
            patientId: value?.patients?.defaultPatientId ?? "",
            PatientName: value?.patients?.personals?.fullName ?? "",
            createDate: value?.creationTime == null ? "" : new Date(value?.creationTime)?.toLocaleDateString("en-US") ?? "",
            status: value?.payPalPaymentStatus ?? "",
            saleOrderId: value?.saleorderId ?? "",
            id: value?.id ?? "",
            checkoutOrInvoice: value?.payPalPaymentMethodType
          })
        }
        if (value.isCashPayment === 1 && value?.paymentTransactionDetails?.payPalInvoicePayments && value?.paymentTransactionDetails?.payPalInvoicePayments?.length !== 0) {
          this.verifyTableData.push({
            patientId: value?.patients?.defaultPatientId ?? "",
            PatientName: value?.patients?.personals?.fullName ?? "",
            createDate: value?.creationTime == null ? "" : new Date(value?.creationTime)?.toLocaleDateString("en-US") ?? "",
            status: value?.payPalPaymentStatus ?? "",
            saleOrderId: value?.saleorderId ?? "",
            id: value?.id ?? "",
            checkoutOrInvoice: value?.payPalPaymentMethodType
          })

        }

      })
      const arrTableData = this.verifyTableData;
      this.dataSource = new MatTableDataSource(arrTableData ?? []);
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
      this.isLoading=false;
    }, err => {
      this.isLoading=false;
      const data: HttpErrorResponse = err;
      // Swal.fire({
      //   icon: 'error',
      //   text: data?.error?.error?.message,
      // });
    });

  }

  //! payment view popup
  getItemPayment(orderId) {
    const dialogRef = this.dialog.open(PaymentViewComponent, {
      disableClose: true,
      data: { orderId: orderId }
    });
    dialogRef.afterClosed().subscribe(() => {

    })

  }
  //! Invoice payment view popup

  getItemInvoicePayment(orderId: string) {
    const dialogRef = this.dialog.open(PaymentViewComponent, {
      disableClose: true,
      data: { orderId: orderId }
    });
    dialogRef.afterClosed().subscribe(() => {

    })
    // const dialogRef = this.dialog.open(InvoicePaymentViewComponent, {
    //   disableClose: true,
    //   data: { orderId: orderId }
    // });
    // dialogRef.afterClosed().subscribe(() => {
    // })
  }
}

