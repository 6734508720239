import type { CreateUpdateInventorySerialLotNoDTO, InventorySerialLotNoDTO, InventorySerialLotNumberQuantityDto } from './dto/models';
import { RestService } from '@abp/ng.core';
import type { PagedAndSortedResultRequestDto, PagedResultDto } from '@abp/ng.core';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class InventorySerialLotNoService {
  apiName = 'ItemManagement';

  create = (input: CreateUpdateInventorySerialLotNoDTO) =>
    this.restService.request<any, InventorySerialLotNoDTO>({
      method: 'POST',
      url: '/api/ItemApp/inventory-serial-lot-no',
      body: input,
    },
    { apiName: this.apiName });

  delete = (id: string) =>
    this.restService.request<any, void>({
      method: 'DELETE',
      url: `/api/ItemApp/inventory-serial-lot-no/${id}`,
    },
    { apiName: this.apiName });

  get = (id: string) =>
    this.restService.request<any, InventorySerialLotNoDTO>({
      method: 'GET',
      url: `/api/ItemApp/inventory-serial-lot-no/${id}`,
    },
    { apiName: this.apiName });

    getListActiveProductSerialList = (gProductId: string) =>
    this.restService.request<any, PagedResultDto<InventorySerialLotNoDTO>>({
      method: 'GET',
      url: `/api/ItemApp/inventory-serial-lot-no/active-product-serial-list/${gProductId}`,
    },
    { apiName: this.apiName });
    
  getList = (input: PagedAndSortedResultRequestDto) =>
    this.restService.request<any, PagedResultDto<InventorySerialLotNoDTO>>({
      method: 'GET',
      url: '/api/ItemApp/inventory-serial-lot-no',
      params: { skipCount: input.skipCount, maxResultCount: input.maxResultCount, sorting: input.sorting },
    },
    { apiName: this.apiName });

  update = (id: string, input: CreateUpdateInventorySerialLotNoDTO) =>
    this.restService.request<any, InventorySerialLotNoDTO>({
      method: 'PUT',
      url: `/api/ItemApp/inventory-serial-lot-no/${id}`,
      body: input,
    },
    { apiName: this.apiName });

  getSerialLotNumberByOrderIdByGorderId = (gorderId: string) =>
    this.restService.request<any, InventorySerialLotNumberQuantityDto[]>({
      method: 'GET',
      url: `/api/ItemApp/inventory-serial-lot-no/serial-lot-number-by-order-id/${gorderId}`,
    },
    { apiName: this.apiName });

    getSerialLotNumberByProductIdByGproductId = (gproductId: string) =>
      this.restService.request<any, InventorySerialLotNumberQuantityDto[]>({
        method: 'GET',
        url: `/api/ItemApp/inventory-serial-lot-no/serial-lot-number-by-product-id/${gproductId}`,
      },
      { apiName: this.apiName });

  constructor(private restService: RestService) {}
}
