<!-- Order-Follow-Up-Reports Page -->
<div class="container-fluid">
  <div class="row bg">
    <div class="col-12">
      <div class="d-flex align-items-center justify-content-between mb-1">
        <h3 class="mb-3 mt-2 font-size-18 customThemeClass"><b>MIS Report / Order Follow Up Report</b></h3>
        <div class="page-title-box page-title-right">
          <ol class="breadcrumbs m-0">
            <button
              class="mr-2 buttonColor"
              [disabled]="(dataSource && dataSource?.data && dataSource?.data?.length === 0)"
              mat-raised-button
              (click)="
                exporter.exportTable('xlsx', {
                  fileName: 'Order List',
                  sheet: 'OrderList',
                  Props: { Author: 'QSecure' }
                })
              "
            >
              Export
            </button>
            <!-- <li class="breadcrumb-item mt-2 pl-2">Report Page</li>
            <li class="breadcrumb-item mt-2">Follow Up Report</li> -->
          </ol>
        </div>
      </div>
    </div>
    <div class="col-lg-12">
      <form [formGroup]="orderItemsFollowUpForm">
        <div class="example-container mat-elevation-z4">
          <table
            mat-table
            [dataSource]="dataSource"
            matTableExporter
            #exporter="matTableExporter"
            matSort
            class="mat-elevation-z8"
          >
            <ng-container matColumnDef="saleOrderId">
              <th
                class="header"
                mat-header-cell
                mat-sort-header
                *matHeaderCellDef
              >
                <mat-form-field class="filter">
                  <input
                    matInput
                    formControlName="saleOrderId"
                    autocomplete="new-saleOrderId"
                  />
                  <mat-placeholder class="center"
                    >SaleOrder Number</mat-placeholder
                  >
                </mat-form-field>
              </th>
              <td mat-cell *matCellDef="let element">
                <a
                  matTooltip="Click to View Order"
                  class="eyeCursorclass"
                  [routerLink]="'/patient/saleorderCreateEdit/' + element?.saleOrderGuid"
                  target="_blank"
                >
                  {{ element?.saleOrderId }}
                </a>
              </td>
            </ng-container>

            <ng-container matColumnDef="patientId">
              <th
                class="header"
                mat-header-cell
                mat-sort-header
                *matHeaderCellDef
              >
                <mat-form-field class="filter">
                  <input
                    matInput
                    formControlName="patientId"
                    autocomplete="new-patientId"
                  />
                  <mat-placeholder class="center">Patient Id</mat-placeholder>
                </mat-form-field>
              </th>
              <td mat-cell *matCellDef="let element">
                <a
                  matTooltip="Click to View Patient"
                  class="eyeCursorclass"
                  [routerLink]="'/patientViewEdit/' + element?.patientId"
                  target="_blank"
                >
                  {{ element?.defaultPatientId }}
                </a>
              </td>
            </ng-container>

            <ng-container matColumnDef="nextDOSDate">
              <th
                class="header"
                mat-header-cell
                mat-sort-header
                *matHeaderCellDef
              >
                <mat-form-field class="filter">
                  <input
                    matInput
                    formControlName="nextDOSDate"
                    autocomplete="new-nextDOSDate"
                  />
                  <mat-placeholder class="center"
                    >Next DOS Date</mat-placeholder
                  >
                  <mat-error
                    *ngIf="
                      orderItemsFollowUpForm?.get('nextDOSDate')?.touched &&
                      orderItemsFollowUpForm?.get('nextDOSDate')?.errors
                        ?.dateVaidator
                    "
                  >
                    Enter Valid date
                  </mat-error>
                </mat-form-field>
              </th>
              <td mat-cell *matCellDef="let element">
                {{ element?.nextDOSDate | date: "MM/dd/yyyy":"en_US" }}
              </td>
            </ng-container>

            <ng-container matColumnDef="patientName">
              <th
                class="header"
                mat-header-cell
                mat-sort-header
                *matHeaderCellDef
              >
                <mat-form-field class="filter">
                  <input
                    matInput
                    formControlName="patientName"
                    autocomplete="new-patientName"
                  />
                  <mat-placeholder class="center">Patient Name</mat-placeholder>
                </mat-form-field>
              </th>
              <td mat-cell *matCellDef="let element">
                {{ element?.patientName }}
              </td>
            </ng-container>

            <ng-container matColumnDef="insuranceName">
              <th
                class="header"
                mat-header-cell
                mat-sort-header
                *matHeaderCellDef
              >
                <mat-form-field class="filter">
                  <input
                    matInput
                    formControlName="insuranceName"
                    autocomplete="new-insuranceName"
                  />
                  <mat-placeholder class="center"
                    >Insurance Name</mat-placeholder
                  >
                </mat-form-field>
              </th>
              <td mat-cell *matCellDef="let element">
                {{ element?.insuranceName }}
              </td>
            </ng-container>

            <ng-container matColumnDef="hcpcsCode">
              <th
                class="header"
                mat-header-cell
                mat-sort-header
                *matHeaderCellDef
              >
                <mat-form-field class="filter">
                  <input
                    matInput
                    formControlName="hcpcsCode"
                    autocomplete="new-hcpcsCode"
                  />
                  <mat-placeholder class="center">HCPC</mat-placeholder>
                </mat-form-field>
              </th>
              <td mat-cell *matCellDef="let element">
                {{ element?.hcpcsCode }}
              </td>
            </ng-container>
            <ng-container matColumnDef="noRecords">
              <td
                class="text-align-center"
                mat-footer-cell
                *matFooterCellDef
                colspan="6"
              >
                <div *ngIf="!isLoading">
                  {{ "No Records Found" }}
                </div>
                <div [hidden]="!isLoading">
                  <mat-spinner [diameter]="220"></mat-spinner>
                </div>
              </td>
            </ng-container>
            <!-- <ng-container matColumnDef="paginator">
              <td mat-footer-cell *matFooterCellDef colspan="6">
                <mat-paginator class="table table-responsive" #MatPaginator [length]="dataSource?.data?.length"
                  [pageSize]="10" [pageIndex]="0" showFirstLastButtons [pageSizeOptions]="[5, 10, 25, 100]">
                </mat-paginator>
              </td>
            </ng-container> -->
            <tr mat-header-row *matHeaderRowDef="arrDisplayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: arrDisplayedColumns"></tr>
            <!-- <tr mat-footer-row *matFooterRowDef="['noRecords']" [ngClass]="{
                active:
                  dataSource &&
                  dataSource?.data &&
                  dataSource?.data?.length !== 0
              }"></tr>
            <tr mat-footer-row *matFooterRowDef="['paginator']"></tr> -->
          </table>
          <div
          *ngIf="
           !( dataSource && dataSource?.data && dataSource?.data?.length !== 0)
          "
          class="text-align-center"
        >
          <div class="message py-3" *ngIf="!isLoading">
            {{ "No Records Found" }}
          </div>
          <div class="py-3" [hidden]="!isLoading">
            <mat-spinner [diameter]="220"></mat-spinner>
          </div>
        </div>
        <mat-paginator
          [pageSizeOptions]="[10, 20, 50, 100]"
          showFirstLastButtons
        ></mat-paginator>
        </div>
      </form>
    </div>
  </div>
</div>
