import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { TableService } from 'projects/shared/src/app/table.service';
import {
  PickUpExchangeDTO,
  PickupUpdateRequestDTO,
} from '../order-proxy/order-management/order-optimization/dto';
import { defaultGuid, DocumentMaxandTypeLimits } from 'projects/shared/src/app/enums/allenums.enum';
import { PickupExchangeListComponent } from '../pickup-exchange-list/pickup-exchange-list.component';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
// import { defaultGuid } from 'projects/admin/src/app/document-perviewer/document-perviewer.component';
import { PickUpExchangeService } from '../order-proxy/order-management/order-optimization/pick-up-exchange.service';
import Swal from 'sweetalert2';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-pickup-exchange-upload-tab',
  templateUrl: './pickup-exchange-upload-tab.component.html',
  styleUrls: ['./pickup-exchange-upload-tab.component.scss'],
  providers: [DatePipe],
})
export class PickupExchangeUploadTabComponent implements OnInit {
  pickupExchangeUploadForm: FormGroup;
  selectFileValue: any;
  myPicker2: Date;
  noErrors: boolean = false;
  fileId: string = '';
  chooseDate: any;
  blobName: string;
  inboundUploadForm: FormGroup;
  showErrorBasedOnSize: boolean = false;
  showErrorForFileType: boolean = false;
  showErrorForFileLength: boolean = false;
  selectedFileUploadsData: Partial<PickUpExchangeDTO> | null = null;
  arrSelectedDocuments: any[] = [];
  maxDate: Date;
  minDate: Date;
  @Output() saveEvent = new EventEmitter<void>();
  constructor(
    private fb: FormBuilder,
    private datepipe: DatePipe,
    private table: TableService,
    private pickupExchangeService: PickUpExchangeService,
    private dialogRef: MatDialogRef<PickupExchangeUploadTabComponent>,

    @Inject(MAT_DIALOG_DATA)
    public data: {
      pickupExchangeId: string;
    } = {
      pickupExchangeId: defaultGuid,
    }
  ) {}

  ngOnInit(): void {
    this.pickupExchangeUploadForm = this.fb.group({
      documentFile: new FormControl(null),
      txtReturnDate: new FormControl(''),
    });
    this.maxDate = new Date();
    this.maxDate.setDate(this.maxDate.getDate());
    this.minDate = new Date();
    this.minDate.setFullYear(this.minDate.getFullYear() - 120);
    this.table.setSelectedDocumentList(this.arrSelectedDocuments);
  }
  getSelectedFilesDetails(files: any) {
    this.arrSelectedDocuments = [];
    this.table.setSelectedDocumentList([]);
    let fileResult: {
      base64Value: string;
      fileName: string;
      fileType: string;
      fileSize: string;
    }[] = [];
    this.noErrors = true;
    for (let index = 0; index < files.length; index++) {
      if (files[index].size > DocumentMaxandTypeLimits.documentFileSizeLimit) {
        this.showErrorBasedOnSize = true;
        this.showErrorForFileType = false;
        this.showErrorForFileLength = false;
        this.arrSelectedDocuments = [];

        return;
      }
      if (files[index].type !== DocumentMaxandTypeLimits.documentTypeLimit) {
        this.showErrorForFileType = true;
        this.showErrorBasedOnSize = false;
        this.showErrorForFileLength = false;
        this.arrSelectedDocuments = [];

        return;
      }

      if (files.length >= DocumentMaxandTypeLimits.documentMaxNoFileLimit) {
        this.showErrorForFileLength = true;
        this.showErrorForFileType = false;
        this.showErrorBasedOnSize = false;
        this.arrSelectedDocuments = [];

        return;
      }
      if (files[index].size > DocumentMaxandTypeLimits.documentFileSizeLimit) {
        this.showErrorBasedOnSize = true;
        this.showErrorForFileType = false;
        this.showErrorForFileLength = false;
        this.arrSelectedDocuments = [];
        return;
      }
      this.showErrorForFileType = false;
      this.showErrorBasedOnSize = false;
      this.showErrorForFileLength = false;
      let fileResultValue: string = '';
      const fileName: string = files[index].name;
      const m1fileName: string = fileName.replace('.pdf', '');
      const m2fileName: string = m1fileName.replace('.pdf', '');
      let reader = new FileReader();
      reader.readAsDataURL(files[index]);

      let modifiedElement: {
        base64Value: string;
        fileName: string;
        fileType: string;
        fileSize: string;
      };
      reader.onload = () => {
        fileResultValue = reader?.result?.slice(28)?.toString() ?? '';
        modifiedElement = {
          base64Value: fileResultValue,
          fileName: m2fileName,
          fileType: files[index].type === 'application/pdf' ? 'PDF' : '',

          fileSize: this.formatFileSize(files[index].size, 3),
        };
        fileResult.push(modifiedElement);
      };
      this.arrSelectedDocuments = fileResult;
    }
    this.noErrors = false;
  }

  formatFileSize(bytes: number, decimalPoint: number) {
    if (bytes == 0) return '0 Bytes';
    if (isNaN(bytes)) return '0 Bytes';
    const k = 1024;
    const dm = decimalPoint || 2;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }

  resetReturnUploadForm() {
    this.pickupExchangeUploadForm.reset({
      documentFile: null,
      txtComments: '',
    });
    this.arrSelectedDocuments = [];
    this.table.setSelectedDocumentList(this.arrSelectedDocuments);
  }

  saveReturnDetails(files: any) {
    this.blobName = files;
    if (this.fileId === '') {
      if (files.length === 0) {
        return;
      }
      this.saveFunction();
    }
  }
  saveFunction() {
    const uploadedById: string = localStorage.getItem('userId') ?? '';
    this.arrSelectedDocuments?.forEach((element) => {
      let FileUploadsInput: PickupUpdateRequestDTO = {
        pickupExchangeId: this.data.pickupExchangeId,
        fileContentAsBase64String: element.base64Value,
        returnCompletedDate: this.pickupExchangeUploadForm.value.txtReturnDate,
        blobName: element.fileName,
      };
      this.pickupExchangeService
        .updatePickUpDateByIdAndInput(
          this.data.pickupExchangeId,
          FileUploadsInput
        )
        .subscribe(
          (response) => {
            Swal.fire({
              icon: 'success',
              text: 'Saved Successfully',
            });
            this.saveEvent.emit();
            this.dialogRef.close('saved');
          },
          (err) => {
            const data: HttpErrorResponse = err;
            Swal.fire({
              icon: 'info',
              text: data?.error?.error?.message,
            });
          }
        );
    });
  }
}
