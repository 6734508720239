import { ListService } from '@abp/ng.core';
import { DatePipe } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { BillingSearchService } from 'projects/order/src/app/order-proxy/order-management/order/billing-search.service';
import { SaleorderDTO } from 'projects/order/src/app/order-proxy/order-management/order/dto/models';
import { SaleOrderSearchService } from 'projects/order/src/app/order-proxy/order-management/order/sale-order-search.service';
import {
  ChartDTO,
  OrderMasterDropdownDTO,
  PayablePercentDTO,
  PreparedByDTO,
  TypeOfPlanDTO,
  VerifiedByDTO,
} from 'projects/patient/src/app/dropdown-proxy/dropdown-management/dropdowns/dto/order/models';
import {
  FacilityDTO,
  PatientMasterDropdownDTO,
} from 'projects/patient/src/app/dropdown-proxy/dropdown-management/dropdowns/dto/patient/models';
import { OrderMasterDropdownService } from 'projects/patient/src/app/dropdown-proxy/dropdown-management/dropdowns/master-dropdown-service/order-master-dropdown.service';
import { PatientMasterDropdownService } from 'projects/patient/src/app/dropdown-proxy/dropdown-management/dropdowns/master-dropdown-service/patient-master-dropdown.service';
import { OrderDropdowns } from 'projects/patient/src/app/dropdown-proxy/dropdown-management/dropdowns/order-dropdowns.enum';
import { PatientDropdowns } from 'projects/patient/src/app/dropdown-proxy/dropdown-management/dropdowns/patient-dropdowns.enum';
import {
  PatientExportDatas,
  PatientExportDTO,
} from 'projects/patient/src/app/patient-proxy/patient/dto/models';
import { ExportType } from 'projects/patient/src/app/patient-proxy/patient/enumeration-data/export-type.enum';
import { ReportService } from 'projects/patient/src/app/patient-proxy/patient/report.service';
import { TableService } from 'projects/shared/src/app/table.service';
import { DateValidator } from 'projects/shared/src/app/validations/date-validator';
import { Observable, Subscription } from 'rxjs';
import {
  distinctUntilChanged,
  filter,
  finalize,
  map,
  startWith,
  switchMap,
  tap,
} from 'rxjs/operators';
import Swal from 'sweetalert2';
import { ClaimProcessService } from '../claim-proxy/claim-processing-management/rcm/claim-processing-management/x12';
import { ToastrService } from "ngx-toastr";
import { MatOption } from '@angular/material/core';
import { MatSelect } from '@angular/material/select';
import { OrganizationUnitService } from 'projects/admin/src/app/admin-proxy/platform-app-management/rcm/platform-management/organization-units/organization-unit.service';
@Component({
  selector: 'app-billing-list',
  templateUrl: './billing-list.component.html',
  styleUrls: ['./billing-list.component.scss'],
  providers: [ListService, DatePipe],
})
export class BillingListComponent implements OnInit, OnDestroy {
  @ViewChild(MatSort, { static: false }) sort: MatSort = new MatSort();
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  dataSource: MatTableDataSource<any> = new MatTableDataSource<any>([]);
  organizationUnitId: string = '';
  strPageType: string = 'billingClaimList';
  claimTableData: any[] = [];
  drpChart: ChartDTO[] = [];
  drpPlan: TypeOfPlanDTO[] = [];
  drpVerified: VerifiedByDTO[] = [];
  drpPrepared: PreparedByDTO[] = [];
  drpPayable: PayablePercentDTO[] = [];
  drpFacility: FacilityDTO[] = [];
  organizationunitId: string = '';
  orderForm: FormGroup;
  verifyTableData: any[] = [];
  public arrDisplayedColumns: string[] = [];
  enableEdit = false;
  enableEditIndex = null;
  isLoading: boolean = false;
  strSelectedBillingId: string = '';
  subscription$: Subscription[] = [];
  @ViewChild('selectBranch') selectBranch: MatSelect;
  @ViewChild('selectAllBranch') private selectAllBranch: MatOption;
  branchValue : string[] =[];
  public brnachList: Observable<Branch[]> | undefined;
  public drpBranch: Branch[] = [];
  tenantId: string = "";
  branchForm: FormGroup;
  organizationUnitName: string;

  constructor(
    public router: Router,
    private snackBar: MatSnackBar,
    public list: ListService,
    private Table: TableService,
    private titleService: Title,
    private formBuilder: FormBuilder,
    private billingSearch: BillingSearchService,
    public tableService: TableService,
    private dateValidator: DateValidator,
    private datepipe: DatePipe,
    private patientDropdownService: PatientMasterDropdownService,
    private orderDropdownService: OrderMasterDropdownService,
    private orderSearchService: SaleOrderSearchService,
    private claimProcessService: ClaimProcessService,
    private reportService: ReportService,
    private toastr: ToastrService,
    private branchService : OrganizationUnitService
  ) { }

  ngOnInit(): void {
    this.tenantId = localStorage.getItem("tenantId") ?? "";
    this.orderForm = this.formBuilder.group({
      patientId: new FormControl(''),
      patientName: new FormControl(''),
      saleorderId: new FormControl(''),
      Plan: new FormControl(''),
      VerifyName: new FormControl(''),
      supplyDate: new FormControl('', this.dateValidator.dateVaidator),
      shippingLocation: new FormControl(''),
      email: new FormControl(''),
      createDate: new FormControl('', this.dateValidator.dateVaidator),
      option: new FormControl(''),
      status: new FormControl(''),
    });
    this.titleService.setTitle('Qsecure | Billing List');
    //  billing list search based on value changes
    const valueChanges = this.orderForm.valueChanges
      .pipe(
        startWith({
          patientId: '',
          patientName: '',
          saleorderId: '',
          Plan: '',
          VerifyName: '',
          supplyDate: '',
          shippingLocation: '',
          email: '',
          createDate: '',
          status: '',
        }),
        tap(x => {
          this.isLoading = true;
          this.setTableData([]);
        }),
        filter((e) => e && this.orderForm?.valid),
        map((value: billingList) => {
          const sValue: billingList = {
            patientId: (value?.patientId?.trim()?.toLowerCase() ?? ""),
            patientName: (value?.patientName?.trim()?.toLowerCase() ?? ""),
            saleorderId: (value?.saleorderId?.trim()?.toLowerCase() ?? ""),
            Plan: (value?.Plan?.trim()?.toLowerCase() ?? ""),
            VerifyName: (value?.VerifyName?.trim()?.toLowerCase() ?? ""),
            supplyDate: (value?.supplyDate?.trim()?.toLowerCase() ?? ""),
            shippingLocation: (value?.shippingLocation?.trim()?.toLowerCase() ?? ""),
            email: (value?.email?.trim()?.toLowerCase() ?? ""),
            createDate: (value?.createDate?.trim()?.toLowerCase() ?? ""),
            status: (value?.status?.trim()?.toLowerCase() ?? ""),
          };
          return sValue;
        }),
        switchMap((sValue: billingList) =>
          this.billingSearch.searchBillingBySPatientIdAndSPatietNameAndSSaleOrderIdAndSInsuranceNameAndDtSuppliedDateAndSEmailIdAndSShippingLocationAndDtCreatedDateAndSPlanAndSStatus(
            sValue?.patientId,
            sValue?.patientName,
            sValue?.saleorderId,
            sValue?.VerifyName,
            dateYYYYMMDDtoMMDDYYYY(sValue?.supplyDate),
            sValue?.email,
            sValue?.shippingLocation,
            dateYYYYMMDDtoMMDDYYYY(sValue?.createDate),
            sValue?.Plan,
            sValue?.status
          ))).subscribe(
            (response) => {

              this.isLoading = false;
              this.setTableData(response ?? []);
            },
            (err) => {
              this.isLoading = false;
              this.setTableData([]);
              const data: HttpErrorResponse = err;
              Swal.fire({
                icon: 'info',
                text: data?.error?.error?.message,
              });
            }
          );
    this.subscription$?.push(valueChanges);

    this.getDropdown();
    this.arrDisplayedColumns = [
      'option',
      'payment',
      'saleOrderId',
      'patientId',
      'PatientName',
      'branch',
      'currStatus',
      'VerifyName',
      'plan',
      'createDate',
    ];
    this.tableService.getOrganizationUnitId().subscribe(
      (Value) => {
        this.organizationUnitId = Value;
      },
      (err) => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      }
    );
    this.snackBar.dismiss();
    this.Table.getOrganizationUnitId().subscribe(
      (val) => {
        this.organizationunitId = val;
      },
      (err) => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      }
    );
    this.Table.setOrderId(null);

    this.branchForm = this.formBuilder.group({
      drpBranch : new FormControl(''),
      txtBranch : new FormControl(''),
    });

    this.getBranchList();

  }
  setTableData(responseData: SaleorderDTO[] = []) {
    this.verifyTableData = [];

    responseData?.forEach((value) => {
      if (value?.isShippingApprove === 1 && value?.isCashPayment == 0) {
        const scheduledDate =
          value?.orders?.scheduledDate === null
            ? ''
            : this.datepipe.transform(
              value?.orders?.scheduledDate,
              'MM/dd/yyyy'
            ) ?? '';
        const data = {
          patientGuId: value?.patientId,
          sPatientId: value?.patients?.defaultPatientId,
          sPatientName: value?.patients?.personals?.fullName,
          createDate: value?.creationTime,
          sSaleOrderId: value?.saleorderId,
          sLocation: value?.orders?.invLocationId,
          dtScheduleDate: scheduledDate,
          email: value?.patients?.personals?.emailId,
          id: value?.id,
          listId: value?.patientId,
          claimId: value?.claimId,
          cashPayment: value?.isCashPayment,
          currStatus: value?.orderBilling?.status,
          voidStatus: value?.orderProcessStatus
        };

        const primaryIns = value?.patients?.insuranceVerifications?.primaryInsuranceVerifies;
        const creationTime = value?.creationTime === null ? '' : this.datepipe.transform(value?.creationTime, 'MM/dd/yyyy') ?? '';
        if (primaryIns !== null) {
          if (value?.isShippingApprove === 1) {
            this.verifyTableData.push({
              patientId: data?.sPatientId,
              patientGuId: value?.patientId,
              PatientName: data?.sPatientName,
              createDate: creationTime,
              plan: primaryIns?.policy,
              VerifyName: primaryIns?.planName,
              status: primaryIns?.verificationStatus,
              saleOrderId: data?.sSaleOrderId,
              location: data?.sLocation,
              supplyDate: data?.createDate,
              email: data?.email,
              data: data.id,
              claimId: data.claimId,
              cashPayment: value?.isCashPayment,
              billingStatus: value?.orderBilling?.status,
              currStatus: value?.orderBilling?.status,
              voidStatus: data?.voidStatus
            });
          }
        }
      }
    });
    this.dataSource = new MatTableDataSource(this.verifyTableData ?? []);
    this.sort.disableClear = true;
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }

  getDropdown() {
    let orderDropdownInput: OrderDropdowns[];
    orderDropdownInput = [
      OrderDropdowns.Charts,
      OrderDropdowns.TypeOfPlans,
      OrderDropdowns.VerifiedBies,
      OrderDropdowns.PreparedBies,
      OrderDropdowns.PayablePercents,
    ];
    const orderDropSubs = this.orderDropdownService
      .getOrderDropdownsByInput(orderDropdownInput)
      .subscribe(
        (stateResponse) => {
          const response: OrderMasterDropdownDTO = stateResponse;
          this.drpChart = response?.charts;
          this.drpPlan = response?.typeOfPlans;
          this.drpVerified = response?.verifiedBies;
          this.drpPrepared = response?.preparedBies;
          this.drpPayable = response?.payablePercents;
        },
        (err) => {
          const data: HttpErrorResponse = err;
          Swal.fire({
            icon: 'info',
            text: data?.error?.error?.message,
          });
        }
      );

    let patientDropdownInput: PatientDropdowns[];
    patientDropdownInput = [PatientDropdowns.Facilities];
    const patientDropSubs = this.patientDropdownService
      .getPatientDropdownsByInput(patientDropdownInput)
      .subscribe(
        (stateResponse) => {
          const response: PatientMasterDropdownDTO = stateResponse;
          this.drpFacility = response.facilities;
        },
        (err) => {
          const data: HttpErrorResponse = err;
          Swal.fire({
            icon: 'info',
            text: data?.error?.error?.message,
          });
        }
      );
    this.subscription$?.push(patientDropSubs, orderDropSubs);
  }

  enableEditMethod(i) {
    this.enableEdit = true;
    this.enableEditIndex = i;
  }

  viewClaimDetails(value) {
    this.router.navigate(['billing/billingCreateEdit/' + value?.data]);
  }

  // Export Table Data for Billing-List
  exportTableData() {
    const datas: PatientExportDatas[] = [];
    this.dataSource?.data?.forEach((element) => {
      datas.push({
        saleOrderId: element?.saleOrderId,
        patientDefaultId: element?.patientId,
        patientName: element?.PatientName,
        billingStatus: element?.billingStatus,
        insuranceName: element?.VerifyName,
        cashPayment: String(
          element?.cashPayment === 0 ? 'No' : element?.cashPayment == 'Yes'
        ),
        policyNo: element?.plan,
        orderCreatedDate: element?.createDate,
      });
    });
    const patientsExport: PatientExportDTO = {
      columns: [
        'Sale Order Id',
        'Patient Id',
        'Patient Name',
        'Status',
        'Insurance Name',
        'Cash Payment',
        'Policy No',
        'Order Created Date',

      ],
      datas,
    };
    const exportListSubs = this.reportService
      .exportPatientReportsByExportTypeAndExportDatas(
        ExportType.BillingReports,
        patientsExport
      )
      .subscribe(
        (response) => {

          if (response && response?.length !== 0) {
            const filelocation: String = String(
              'data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,' +
              response
            );
            const link = document.createElement('a');
            link.download = 'Billing-List' + '.xlsx';
            link.href = filelocation.toString();
            link.click();
            // Swal.fire({
            //   title: 'Success',
            //   html: 'Exported Successfully',
            //   icon: 'success',
            //   timer: 3000,
            //   timerProgressBar: true,
            // });
            this.toastr.success('Exported Successfully','Success')
          }
        },
        (err) => {
          const data: HttpErrorResponse = err;
          let errorMesg: string = data?.error;
          if (
            errorMesg?.includes('Sorry!! There is no data in table to export')
          ) {
            errorMesg = 'Sorry!! There is no data in table to export';
          } else {
            errorMesg = data?.error?.error?.message;
          }
          // Swal.fire({
          //   icon: 'info',
          //   text: errorMesg,
          // });
          this.toastr.info(errorMesg)
        }
      );

    this.subscription$?.push(exportListSubs);
  }
  ngOnDestroy(): void {
    this.subscription$.forEach((sub) => {
      sub && sub?.unsubscribe();
    });
  }
  //! Set Order Status Voided
  setOrderStatusVoided(claimId: string, saleOrderId: string) {

    if ((claimId ?? "") !== "") {
      Swal.fire({
        title: 'Are you sure you want to Set as Void Order?',
        text: "You won't be able to Change this data!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#34c38f',
        cancelButtonColor: '#f46a6a',
        confirmButtonText: 'Yes, Change it!',
      }).then((result) => {
        if (result?.value) {
          const updateOrderBilling$ = this.claimProcessService.updateVoidedStatusByClaimIdAndClaimStatus(claimId, true).subscribe((x) => {
            this.orderForm.patchValue({ patientId: '', patientName: '', saleorderId: '', Plan: '', VerifyName: '', supplyDate: '', shippingLocation: '', email: '', createDate: '', status: '', })
          }, err => {
            const data: HttpErrorResponse = err;
            Swal.fire({
              icon: 'info',
              text: data?.error?.error?.message,
            });
          })
          this.subscription$.push(updateOrderBilling$);
        }
      });
    }
    if (((claimId ?? "") === "") && ((saleOrderId ?? "") !== "")) {
      Swal.fire({
        title: 'Are you sure you want to Set as Void Order?',
        text: "You won't be able to Change this data!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#34c38f',
        cancelButtonColor: '#f46a6a',
        confirmButtonText: 'Yes, Change it!',
      }).then((result) => {
        if (result?.value) {
          const updateOrderBilling$ = this.orderSearchService.updateOrderBillingStatus(saleOrderId).subscribe((x) => {
            this.orderForm.patchValue({ patientId: '', patientName: '', saleorderId: '', Plan: '', VerifyName: '', supplyDate: '', shippingLocation: '', email: '', createDate: '', status: '', })

          }, err => {
            const data: HttpErrorResponse = err;
            Swal.fire({
              icon: 'info',
              text: data?.error?.error?.message,
            });
          })
          this.subscription$.push(updateOrderBilling$);
        }
      });
    }

  }

  allBranchSelect(){
    if(this.selectAllBranch.selected){
      this.selectBranch.options.forEach((item: MatOption) => item.select());
    }
    else{
      this.selectBranch.options.forEach((item: MatOption) => item.deselect());
    }
  }

  getBranchList(){
    const getBranchList = this.branchService.getBranchList(this.tenantId).subscribe(response => {
      response?.forEach(element => {
        this.drpBranch.push({
          id: element.id,
          organizationUnitName: element.organizationUnitName,
        })
      });

      this.brnachList = this.branchForm
        ?.get('txtBranch')
        ?.valueChanges?.pipe(
          startWith(''),
          map((value) =>
            this.drpBranch?.filter((option) =>
              option?.organizationUnitName
                ?.toLowerCase()
                ?.includes(value?.toLowerCase() ?? '')
            )
          )
        );


    }, err =>{
      const data : HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    });
    this.subscription$.push(getBranchList);
  }
}

export interface billingList {
  patientId?: string;
  patientGuId?: string;
  patientName?: string;
  saleorderId?: string;
  VerifyName?: string;
  patientBalance?: string;
  insuranceBalance?: string;
  totalBalance?: string;
  supplyDate?: string;
  email?: string;
  shippingLocation?: string;
  createDate?: string;
  status?: string;
  option?: string;
  Plan?: string;
}

export function dateYYYYMMDDtoMMDDYYYY(strDate: string=""): string {
  if (strDate && strDate !== '' && typeof strDate === 'string' && strDate !== null && strDate !== undefined && strDate.length >= 1 && !isNaN(new Date(strDate).getTime())) {
    const latest_date = Intl.DateTimeFormat('en-US')?.format(new Date(strDate));
    return latest_date;
  }
  return '';
}

export interface Branch{
  id?: string;
  organizationUnitName?: string;
}
