import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-text-conversation-admin',
  templateUrl: './text-conversation-admin.component.html',
  styleUrls: ['./text-conversation-admin.component.scss']
})
export class TextConversationAdminComponent implements OnInit {
  selectedRow: number = null;
  constructor() { }

  ngOnInit(): void {
  }

  selectRow(rowNumber: number): void {
    if (this.selectedRow === rowNumber) {
        this.selectedRow = null; // Deselect the row if it's already selected
    } else {
        this.selectedRow = rowNumber; // Select the clicked row
    }
}
}
