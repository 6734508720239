import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { defaultGuid } from 'projects/shared/src/app/enums/allenums.enum';
import { Subscription } from 'rxjs';
import { InvoiceReportService } from '../../billing-proxy/billing-management/rcm/billing-management/optimization/billing-reports/invoice-report.service';
import { HttpErrorResponse } from '@angular/common/http';
import { clsInvoiceDetailsDTO } from '../../billing-proxy/billing-management/rcm/billing-management/optimization/dto/models';
import { CommonService } from 'projects/shared/src/app/services/common.service';
import { BillingDenialService } from '../../billing-proxy/billing-management/rcm/billing-management/optimization/billing-reports/billing-denial.service';
import { DatePipe } from '@angular/common';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { PatientManualVerificationTabComponent } from 'projects/patient/src/app/patient-manual-verification-tab/patient-manual-verification-tab.component';
import { Title } from '@angular/platform-browser';
import { OrderHistoryModalComponent } from 'projects/order/src/app/order-history-modal/order-history-modal.component';

@Component({
  selector: 'app-billing-view-invoice',
  templateUrl: './billing-view-invoice.component.html',
  styleUrls: ['./billing-view-invoice.component.scss'],
})
export class BillingViewInvoiceComponent implements OnInit {
  step: boolean = false;
  patientId: string;
  defaultGuid = defaultGuid;
  // billingDetailsList: string[] = [
  //   'Electronic Eligbility',
  //   'Manual Eligibility',
  // ];
  // insuranceTypes: string[] = [
  //   'Authorization Required Authorization Required',
  //   'DME',
  //   'SME',
  //   'Precet Required',
  // ];
  diagnosis: string[];
  holdReasons: string[];
  // diagnosis: string[] = [
  //   'J88.9 - Interstitial pulmonary disease, unspecified ',
  //   'J84.7 - Pulmonary Interstitial  disease, unspecified ',
  // ];
  ltItems : any[]=[];
  // gInvoiceId: string;
  gOrderId: string;
  // invoiceStatusId: string;
  gClaimId: string;
  subscription$: Subscription[] = [];
  loadInvoiceTableAPICall: Subscription;
  isLoading: boolean = true;
  invoiceDetailsList: clsInvoiceDetailsDTO;
  orderDateTime: any;
  patientDOB: string;
  patientRxDate: string;
  primaryVerifiedDate: string;
  secondaryVerifiedDate: string;
  Error: boolean = false;
  orderInvoicesTable: any = {
    responsive: true,
    paging: false,
    info: false,
    dom: 'Bfrtip',
    scrollCollapse: true,
    scrollY: '500px',
    scrollX: true,
    search: {
      smart: false,
    },
    // language: {
    //   emptyTable: 'No records',
    //   info: 'Total : 4 records',
    //   infoEmpty: 'Total : 4 records',
    //   infoFiltered: '(filtered : _TOTAL_ records)',
    //   loadingRecords: 'Loading...',
    //   zeroRecords: 'No matching records',
    // },
    order: [],
    columnDefs: [{ targets: '_all', orderable: false }],
    buttons: [
      {
        extend: 'excel',
        text: 'Export',
        filename: 'Invoices',
        title: 'Order Invoices',
      },
    ],
  };
  invoiceTableDataV1: any[] = [];
  billingInvoicesService: Subscription;
  isTableLoading: boolean = false;
  rentalOrderId : string = defaultGuid;
  visibleItems: string[] = [];
  showMore: boolean = false;
  holdeReasonItems: string[] = [];
  holdeReasonshowMore: boolean = false;
  limit: number = 6;
  reasonlimit: number = 6;
  showLess: boolean = false;
  holdeReasonshowLess: boolean = false;
  constructor(
    private _snackBar: MatSnackBar,
    private activatedRoute: ActivatedRoute,
    private invoiceReportService: InvoiceReportService,
    private toaster: ToastrService,
    private commonService: CommonService,
    private billingDenialservice: BillingDenialService,
    private datepipe: DatePipe,
    private dialog: MatDialog,
    private title: Title,
  ) {}

  ngOnInit(): void {
    this.title.setTitle('Invoice View | Qsecure');
    const activateRoute = this.activatedRoute.paramMap.subscribe(
      (response) => {
        this.gOrderId = response?.get('orderId') ?? defaultGuid;
        this.gClaimId = response?.get('claimId') ?? defaultGuid;
        this.rentalOrderId = response?.get('rentalOrderId') ?? defaultGuid;
      },
      (err) => {}
    );
    this.subscription$.push(activateRoute);
    this.orderInvoicesTable.buttons[0].filename = 'Billing / Invoice View - Invoices' + '-' + this.datepipe.transform(new Date(), 'MM-dd-yyyy');
    this.getInvoiceData();
  }

  ngOnDestroy(): void {
    this.subscription$.forEach((sub) => {
      sub && sub?.unsubscribe();
    });
    if (this.loadInvoiceTableAPICall) {
      this.loadInvoiceTableAPICall.unsubscribe();
    }
    if (this.billingInvoicesService) {
      this.billingInvoicesService.unsubscribe();
    }
  }

  openVerification(policyId: string, verificationId: string) {
    if(this.isEmpty(policyId) || this.isEmpty(verificationId)) {
      this._snackBar.open('Invaild policy or verification.', 'Close', {
        duration: 3000,
        horizontalPosition: 'right',
        verticalPosition: 'top',
      });
      return false;
    }
    const dialogRef = this.dialog.open(PatientManualVerificationTabComponent, {
      disableClose: true,
      data: {
        patientId: this.patientId,
        verificationId: verificationId,
        policyId: policyId,
        savehidden: true,
        isViewVerification: true,
        documentId: this.invoiceDetailsList?.orderDetails?.inboundDocumentId
      },
    });
    const closeDialog = dialogRef.afterClosed().subscribe((result) => {
      if (result == 'Saved') {
        this.dialog.closeAll();
      }

      result !== '' &&
        result !== null &&
        result !== undefined &&
        this.dialog.closeAll();
    });
    this.subscription$.push(closeDialog);
  }

  isEmpty(value: any): boolean {
    return (
      value === null ||
      value === undefined ||
      value === '' ||
      value === defaultGuid
    );
  }
  copyText(textToCopy: string): void {
    this.copyTextToClipboard(textToCopy);
    this.showNotification('Text copied: ' + textToCopy);
  }
  copyInvoiceId(textToCopy: string): void {
    this.copyTextToClipboard(textToCopy);
    this.showNotification('Invoice copied: ' + textToCopy);
  }

  showNotification(message: string): void {
    this._snackBar.open(message, 'Close', {
      duration: 3000, // Duration in milliseconds
      horizontalPosition: 'end', // Positioning the notification horizontally
      verticalPosition: 'bottom', // Positioning the notification vertically
    });
  }

  copyTextToClipboard(text: string): void {
    const textArea = document.createElement('textarea');
    textArea.value = text;
    document.body.appendChild(textArea);
    textArea.select();
    document.execCommand('Copy');
    textArea.remove();
  }

  updateTextColor(colorCode: string): string {
    if (this.isValidColorCode(colorCode)) {
      return this.getContrastingTextColor(colorCode);
    } else {
      return '#000000'; // default to black if colorCode is invalid
    }
  }

  isValidColorCode(colorCode: string): boolean {
    // Check if colorCode is a valid hex color code
    const regex = /^#([0-9A-F]{3}){1,2}$/i;
    return regex.test(colorCode);
  }

  getContrastingTextColor(hexColor: string): string {
    if (!hexColor) return '#000000'; // default to black if no color provided

    // Remove the hash if present
    hexColor = hexColor.replace('#', '');

    // Convert to RGB
    const r = parseInt(hexColor.substring(0, 2), 16);
    const g = parseInt(hexColor.substring(2, 4), 16);
    const b = parseInt(hexColor.substring(4, 6), 16);

    // Calculate luminance
    const luminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255;

    // If luminance is high, return dark color (black), otherwise return light color (white)
    return luminance > 0.5 ? '#000000' : '#FFFFFF';
  }


  fnGetBillingInvoicesV1() {
    this.isTableLoading = true;
    if (this.billingInvoicesService) {
      this.billingInvoicesService.unsubscribe();
    }
    this.billingDenialservice
      .getBillingInvoiceV1ByGOrderIdAndRentalId(this.invoiceDetailsList.orderDetails.orderId, this.rentalOrderId)
      .subscribe(
        (response) => {
          this.invoiceTableDataV1 = response.invoiceDetails || [];
          this.isTableLoading = false;
        },
        (err) => {
          this.invoiceTableDataV1=[];
          this.isTableLoading = false;
          const data: HttpErrorResponse = err;
          // this.toaster.error(data?.error?.error?.message || 'API Error');
        }
      );
  }

  getInvoiceData() {
    // if (this.isEmpty(this.gInvoiceId)) {
    //   this.toaster.error('Invalid Invoice Id');
    //   return;
    // }
    // if (this.isEmpty(this.invoiceStatusId)) {
    //   this.toaster.error('Invalid Status Id');
    //   return;
    // }
    try {
      if (this.loadInvoiceTableAPICall) {
        this.loadInvoiceTableAPICall.unsubscribe();
      }

      this.loadInvoiceTableAPICall = this.invoiceReportService
        // .getInvoiceDetailByIdByGInvoiceIdAndGInvoiceStatusId(this.gInvoiceId,this.invoiceStatusId,this.rentalOrderId)
        .getInvoiceDetailByIdByGOrderIdAndGRentalOrderIdAndGClaimId(this.gOrderId,this.rentalOrderId,this.gClaimId)
        .subscribe(
          (response) => {
            this.invoiceDetailsList = response;
            this.diagnosis = response?.clinicalDetails?.ltDiagnosis || [];

            this.holdReasons = response?.ltHoldReasons || [];

            this.orderDateTime= this.commonService.getFormattedDateZone(this.invoiceDetailsList?.orderDetails?.orderCreationTime);
            this.patientDOB= this.commonService.getFormattedDateZone(this.invoiceDetailsList?.patientDetails?.dtDOB);
            this.patientRxDate= this.commonService.getFormattedDateZone(this.invoiceDetailsList?.patientDetails?.rxDate);
            this.primaryVerifiedDate= this.commonService.getFormattedDateTimeZone(this.invoiceDetailsList?.primaryPolicy?.verificaitonDetails?.verifiedDate);
            this.secondaryVerifiedDate= this.commonService.getFormattedDateTimeZone(this.invoiceDetailsList?.secondaryPolicy?.verificaitonDetails?.verifiedDate);
            this.patientId = this.invoiceDetailsList?.patientDetails?.patientId || defaultGuid;
            this.ltItems = response?.ltItems || [];
             this.ltItems = this.ltItems.map((item) => {
              return {
                ...item,
                dtDOS: this.commonService.getFormattedDateZone(item.dtDOS),
              };
            });
            // this.rentalOrderId=this.invoiceDetailsList?.orderDetails?.rentalOrderId || defaultGuid;

            if(this.invoiceDetailsList?.orderDetails?.isCashOrder === false){
              this.fnGetBillingInvoicesV1()
            }
            this.visibleItems = this.diagnosis.slice(0, this.limit);
            this.showMore = this.diagnosis.length > this.limit;

            this.holdeReasonItems = this.holdReasons.slice(0, this.reasonlimit);
            this.holdeReasonshowMore = this.holdReasons.length > this.reasonlimit;

            setTimeout(() => {
              this.isLoading = false;
              this.Error=false;
            }, 500);
          },
          (err) => {
            const data: HttpErrorResponse = err;
            this.toaster.error(data?.error?.error?.message || 'API Error');
            setTimeout(() => {
              this.isLoading = false;
              this.Error=true;
            }, 500);
          }
        );
      // this.tableData$ = getTableData;
    } catch (error) {
      console.error('Unexpected error:', error);
      setTimeout(() => {
        this.isLoading = false;
        this.Error=true;
      }, 500);
    } finally {
    }
  }

  // generateNextTwelveMonths(months) {
  //   const startDateString = String(this.orderForm.value.txtOrderDate);
  //   const startDate = new Date(startDateString);
  //   this.monthlyAmounts =
  //     this.orderDetailsForm.value.txtBalance / this.orderForm.value.txtmoths;

  //   if (!isNaN(startDate.getTime())) {
  //     // Check if the conversion to Date was successful
  //     for (let i = 0; i < this.orderForm.value.txtmoths; i++) {
  //       const nextMonth = new Date(startDate);
  //       nextMonth.setMonth(startDate.getMonth() + i);

  //       const dataObject = {
  //         date: nextMonth,
  //         balanceAmount: this.orderDetailsForm.value.txtBalance,
  //         monthlyAmount: this.monthlyAmounts.toFixed(2),
  //       };

  //       this.nextTwelveMonths.push(dataObject);
  //     }
  //   } else {
  //   }
  // }
  // viewMonthlyAmount(data) {
  //   this.generateNextTwelveMonths(data);
  //   console.log(this.rentalAllowable);
  //   console.log(this.monthlyAmounts);
  //   console.log(this.nextTwelveMonths);
  //   console.log(data);
  //   if (this.savedOrderDate !== this.orderForm.get('txtOrderDate').value) {
  //     const dialogRef = this.dialog.open(RentalMonthlyCalculationComponent, {
  //       disableClose: true,
  //       // maxWidth: '75%',
  //       minWidth: '52vw',
  //       minHeight: '50%',
  //       data: {
  //         // balanceAmount: this.orderDetailsForm.value.txtBalance,
  //         balanceAmount: this.rentalAllowable, // Allow only Main Product Added By Manikandan 14-08-2024 12.30pm
  //         monthlyAmount: this.monthlyAmounts,
  //         totalDate: this.nextTwelveMonths,
  //         orderId: defaultGuid,
  //         orderDate: this.datepipe.transform(
  //           // this.orderForm.value.txtOrderDate,
  //           this.orderForm.get('txtOrderDate').value,
  //           'MM/dd/yyyy'
  //         ),
  //         months: data,
  //       },
  //     });
  //     dialogRef.afterClosed().subscribe((a) => {
  //       this.nextTwelveMonths = [];
  //       // this.generateNextTwelveMonths(this.orderForm.value.txtmoths)
  //     });
  //   } else {
  //     // Generate 12 monthly dates
  //     const newOrderId =
  //       this.oldRentalMonth != data ? defaultGuid : this.orderId;
  //     const dialogRef = this.dialog.open(RentalMonthlyCalculationComponent, {
  //       disableClose: true,
  //       // maxWidth: '75%',
  //       minWidth: '52vw',
  //       minHeight: '50%',
  //       data: {
  //         // balanceAmount: this.orderDetailsForm.value.txtBalance,
  //         balanceAmount: this.rentalAllowable, // Allow only Main Product Added By Manikandan 14-08-2024 12.30pm
  //         monthlyAmount: this.monthlyAmounts,
  //         totalDate: this.nextTwelveMonths,
  //         orderId: newOrderId,
  //         orderDate: this.datepipe.transform(
  //           // this.orderForm.value.txtOrderDate,
  //           this.orderForm.get('txtOrderDate').value,
  //           'MM/dd/yyyy'
  //         ),
  //         months: data,
  //       },
  //     });
  //     dialogRef.afterClosed().subscribe((a) => {
  //       this.nextTwelveMonths = [];
  //       // this.generateNextTwelveMonths(this.orderForm.value.txtmoths)
  //     });
  //   }
  // }
  // toggleShowMore() {
  //   if (this.showMore) {
  //     this.visibleItems = this.diagnosis;
  //     this.showMore = false;
  //   } else {
  //     this.visibleItems = this.diagnosis.slice(0, this.limit);
  //     this.showMore = true;
  //   }
  // }

  toggleShowMore() {
    if (this.showMore) {
      this.visibleItems = this.diagnosis;
      this.showMore = false;
      this.showLess = true;
    } else if (this.showLess) {
      this.visibleItems = this.diagnosis.slice(0, this.limit);
      this.showMore = true;
      this.showLess = false;
    }
  }

  toggleShowMoreReason() {
    if (this.holdeReasonshowMore) {
      this.holdeReasonItems = this.holdReasons;
      this.holdeReasonshowMore = false;
      this.holdeReasonshowLess = true;
    } else if (this.holdeReasonshowLess) {
      this.holdeReasonItems = this.holdReasons.slice(0, this.reasonlimit);
      this.holdeReasonshowMore = true;
      this.holdeReasonshowLess = false;
    }
  }

  //#region Order History
  //To Open the Order History Popup
  getOrderHistory() {
    const config: MatDialogConfig = {
      height: '70vh',
      width: '40vw',
      disableClose: true,
      data: {
        orderId: this.invoiceDetailsList?.orderDetails?.orderId,
        mmOrderId: this.invoiceDetailsList?.orderDetails?.orderNo,
        patientName: this.invoiceDetailsList?.patientDetails?.patientName,
        chartId: this.invoiceDetailsList?.patientDetails?.chartId,
      },
    };
    const dialogRef = this.dialog.open(OrderHistoryModalComponent, config);
  }
  //#endregion
}
