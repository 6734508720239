<div class="chat-container">
  <div class="row" style="border-bottom: 2px solid rgb(201, 201, 201);">
    <div class="col-8">
      <h3 class="font-weight-bolder"> <span class="chartCls pointer">652155DF</span> - Aubery Lucas</h3>
    </div>
    <div class="col-4 text-right">
      <i class="fa fa-whatsapp customFont"></i>
      <mat-icon class="close-button " style="cursor: pointer !important" [matMenuTriggerFor]="menu">more_vert</mat-icon>
      <mat-icon mat-dialog-close class="pointer" *ngIf="isPatientMdltxt">close</mat-icon>
    </div>
  </div>
  <div class="messages-container" #scrollContainer>
    <ng-container *ngIf="shouldShowToday()">
      <div class="date-label">Today</div>
    </ng-container>
    <div *ngFor="let message of messages" class="message-wrapper"
      [ngClass]="{'message-me': message.sender === 'me', 'message-friend': message.sender === 'friend'}">
      <span class="friendcls" *ngIf="message.sender === 'friend'">Aubrey Lucas</span>
      <span class="youcls" *ngIf="message.sender === 'me'">You</span>
      <div class="card card-2">
        <span>{{ message.content }}</span>
      </div>
      <div class="message-info">
        <span class="check-icon"
          [ngClass]="{'message-me': message.sender === 'me', 'message-friend': message.sender === 'friend'}"> <mat-icon class="checkIcon">check_circle</mat-icon> </span>
        <span class="received-text timestamp" *ngIf="message.sender === 'friend'">Received {{ message.timestamp | date:'shortTime' }}</span>
        <span class="received-text timestamp" *ngIf="message.sender === 'me'">Sent {{ message.timestamp | date:'shortTime' }}</span>
        <!-- <div class="timestamp">
          <ng-container *ngIf="isToday(message.timestamp); else displayDate">
            {{ message.timestamp | date:'shortTime' }}
          </ng-container>
          <ng-template #displayDate>
            {{ message.timestamp | date:'shortTime' }}
          </ng-template>
        </div> -->
      </div>
    </div>
  </div>

  <div class="chat-footer">

    <div class="input-group">
      <mat-form-field class="customTemplateDrp" appearance="none">
      <mat-select [(value)]="selectedTemplate" (selectionChange)="onSelectionTemplateChange($event)">
        <mat-option *ngFor="let item of this.dataList" [value]="item.code">
          {{item.name}}
        </mat-option>
      </mat-select>
    </mat-form-field>
      <!-- <select class="dropdown-toggle pointer">
        <option>Select Template</option>
          <option *ngFor="let item of this.dataList;let i = index" value="{{item.code}}">
            {{item.name}}
          </option>
      </select> -->

      <!-- <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
        Select Template
      </button>

      <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
        <li><a class="dropdown-item" href="#">Action</a></li>
        <li><a class="dropdown-item" href="#">Another action</a></li>
        <li><a class="dropdown-item" href="#">Something else here</a></li>
      </ul> -->

      <textarea [(ngModel)]="newMessage" placeholder="Type a message..." #msgInput (keydown.enter)="handleEnter($event, msgInput)" class="message-input mt-2"></textarea>

      <button mat-button (click)="sendMessage()" class="buttonColor" type="button"><mat-icon class="sendIcon mb-1">send</mat-icon></button>
    </div>
  </div>

  <mat-menu class="custom-mat-menu" #menu="matMenu">
    <button mat-menu-item>
      Add Patient Notes
    </button>
    <button mat-menu-item>
      View Patient Notes
    </button>

    <button mat-menu-item>
      View Document Image
    </button>

    <button mat-menu-item>
      View Patient Ledger
    </button>

    <button mat-menu-item>OnDemand / Online Verification Request</button>
    <button mat-menu-item>New Checklist</button>
    <button disabled mat-menu-item>Text Conversation</button>
    <button mat-menu-item>Tag User</button>
    <button mat-menu-item>Mark as Unread</button>
  </mat-menu>