

export class getBilling {
  static readonly type = '[ClaimDTO] Get]';
  constructor(public id: string) { }
}

export class addBilling {
  static readonly type = '[ClaimDTO] Add]';
  constructor(public readonly payload: any) { }
}

export class deleteBilling {
  static readonly type = '[ClaimDTO] Delete]';
  constructor(public id: string) { }
}
export class updateBilling {
  static readonly type = '[ClaimDTO] Update]';
  constructor(public id: string, public readonly payload?: any) { }
}







