<br />
<div class="card card-body">
  <mat-card-content>
    <div class="row">
      <div class="col-6">
        <div class="text-left">
          <h4 class="customThemeClass"><b>Authorization</b></h4>
        </div>
      </div>
      <div class="col-6">
        <div class="text-right">
          <button mat-button *ngIf="authType==='InsuranceBased'" (click)="openAuthModal()" class="buttonColor font-size-14">
            <i class="fa fa-plus mr-1" ></i>
            Add Authorization
          </button>
          <button mat-button *ngIf="authType==='ItemBased'" (click)="openItemBasedAuthModal()" class="buttonColor font-size-14">
            <i class="fa fa-plus mr-1" ></i>
            Add Authorization
          </button>
        </div>
      </div>
    </div>
    <mat-radio-group (ngModelChange)="getUpdates($event)" [(ngModel)]="authType" class="">
      <mat-radio-button [checked]='true' value="ItemBased">Item Based Authorization</mat-radio-button>&nbsp;&nbsp;&nbsp;
      <mat-radio-button value="InsuranceBased">Insurance Based Authorization</mat-radio-button>&nbsp;&nbsp;&nbsp;
    </mat-radio-group>
    <ng-container *ngIf="authType==='ItemBased'">
      <app-table [strPageType]="'ItemBasedAuth'" [isLoading]="isLoading" (viewButton)="viewItemBasedAuth($event)" (changeButton)="updateFollowUp($event)"
        (deleteButton)="deleteAuth($event)">
      </app-table>
    </ng-container>
    <ng-container *ngIf="authType==='InsuranceBased'">
      <app-table [strPageType]="strPageType" [isLoading]="isLoading" (viewButton)="viewAuth($event)"
        (deleteButton)="deleteAuth($event)" >
      </app-table>
    </ng-container>
  </mat-card-content>
</div>