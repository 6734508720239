<div class="container-fluid mt-3">

  <div class="row bg">
    <div class="col-12">
      <div class="d-flex align-items-center justify-content-between">
        <h3 class="font-weight-bolder mb-0">
          <b class="customThemeClass">List / Master Medicare Checklist</b>
        </h3>
      </div>
    </div>
  </div>

  <div class="card mt-2">
      <div class="row">
        <div class="col-xl-12 pb-2">
          <ul class="card card-body" ngbNav #nav="ngbNav" justify="justified" class="alert-success nav nav-pills nav-fill"
            style="border-bottom: 3px solid rgb(201, 201, 201)">
            <li ngbNavItem="FaxProcess" id="faxProcess">
              <a [ngClass]="activeTab == MMCDocumentUploadTypes.FaxProcess ? 'active' : ''" ngbNavLink (click)="changeTab(MMCDocumentUploadTypes.FaxProcess)" id="faxProcesstab"><b>Fax Process</b></a>
            </li>
            <li ngbNavItem="OnDemand" id="onDemand">
              <a [ngClass]="activeTab == MMCDocumentUploadTypes.OnDemand ? 'active' : ''" ngbNavLink (click)="changeTab(MMCDocumentUploadTypes.OnDemand)" id="onDemandtab"><b>On-Demand</b></a>
            </li>
            <li ngbNavItem="ApproveDis" id="approved">
              <a [ngClass]="activeTab == MMCDocumentUploadTypes.ApproveDisapprove ? 'active' : ''" ngbNavLink (click)="changeTab(MMCDocumentUploadTypes.ApproveDisapprove)" id="approvedtab"><b>Approved /
                  Disapproved</b></a>
            </li>
            <li ngbNavItem="Pending" id="pending">
              <a [ngClass]="activeTab == MMCDocumentUploadTypes.Pending ? 'active' : ''" ngbNavLink (click)="changeTab(MMCDocumentUploadTypes.Pending)" id="pendingtab"><b>Pending</b></a>
            </li>
          </ul>
        </div>
      </div>
    <div class="row">
      <div class="col-12">
        <div class="card-body">
          <!-- <div class="row" *ngIf="!isLoading">
            <div class="col-12">
              <div class="table table-responsive" *ngIf="activeTab === MMCDocumentUploadTypes.FaxProcess">
                <app-mm-fax-process [DataList]="DataList" (reloadTable)="getTableData(MMCDocumentUploadTypes.FaxProcess,null,null,null,null,null,null,null)"></app-mm-fax-process>
              </div>
              <div class="table table-responsive" *ngIf="activeTab === MMCDocumentUploadTypes.OnDemand">
                <app-mm-on-demand [DataList]="DataList" (reloadTable)="getTableData(MMCDocumentUploadTypes.OnDemand,null,null,null,null,null,null,null)"></app-mm-on-demand>
              </div>
              <div class="table table-responsive" *ngIf="activeTab === MMCDocumentUploadTypes.ApproveDisapprove">
                <app-mm-approved-disapproved [DataList]="DataList" (reloadTable)="getTableData(MMCDocumentUploadTypes.ApproveDisapprove,null,null,null,null,null,null,null)"></app-mm-approved-disapproved>
              </div>
              <div class="table table-responsive" *ngIf="activeTab === MMCDocumentUploadTypes.Pending">
                <app-mm-pending [DataList]="DataList" (reloadTable)="getTableData(MMCDocumentUploadTypes.Pending,null,null,null,null,null,null,null)"></app-mm-pending>
              </div>
            </div>
          </div> -->

          <div class="row">
            <div class="col-12">
              <div class="table table-responsive" *ngIf="activeTab === MMCDocumentUploadTypes.FaxProcess">
                <app-mm-fax-process [DataList]="DataList" *ngIf="!isLoading"></app-mm-fax-process>
              </div>
              <div class="table table-responsive" *ngIf="activeTab === MMCDocumentUploadTypes.OnDemand">
                <app-mm-on-demand [DataList]="DataList" *ngIf="!isLoading"></app-mm-on-demand>
              </div>
              <div class="table table-responsive" *ngIf="activeTab === MMCDocumentUploadTypes.ApproveDisapprove">
                <app-mm-approved-disapproved [DataList]="DataList" *ngIf="!isLoading"></app-mm-approved-disapproved>
              </div>
              <div class="table table-responsive" *ngIf="activeTab === MMCDocumentUploadTypes.Pending">
                <app-mm-pending [DataList]="DataList" *ngIf="!isLoading"></app-mm-pending>
              </div>
            </div>
          </div>

          <div class="row" *ngIf="isLoading">
            <div class="col-12">
              <div class="d-flex justify-content-center">
                <img class="img-responsive pb-2 mr-1" src="assets/gifs/Infinity-loader.gif" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>

</div>