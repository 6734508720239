<div class="container-fluid">
  <div class="row bg">
    <div class="pb-3 scheduledList">Scheduled Tasks List</div>
    <div class="col-lg-12">
      <div class="table table-responsive example-container mat-elevation-z4 pt-0 mt-0">
        <table mat-table [dataSource]="dataSource" matTableExporter #exporter="matTableExporter"
          class="mat-elevation-z8 pt-0 mt-0">
          <!-- Document Id -->
          <ng-container matColumnDef="defaultDocumentId">
            <th class="header" mat-header-cell *matHeaderCellDef>Doc Id</th>
            <td mat-cell *matCellDef="let element">
              <!-- <a
                matTooltip="Click to View Document"
                class="eyeCursorclass"
                [routerLink]="
                  '/ViewDocumentPerviewByBlobId/' + element?.blobName + '/' + 5
                "
                target="_blank"
              > -->
              {{ element?.defaultDocumentId }}
              <!-- </a> -->
            </td>
          </ng-container>
          <!-- WIP ID-->
          <ng-container matColumnDef="wipId">
            <th class="header" mat-header-cell *matHeaderCellDef>WIP Id</th>
            <td mat-cell *matCellDef="let element">
              <!-- <a
                matTooltip="Click to Schedule"
                class="eyeCursorclass"
                target="_blank"
                rel="noopener"
              > -->
              {{ element?.wipId }}
              <!-- </a> -->
            </td>
          </ng-container>
          <!--Patient Id-->
          <ng-container matColumnDef="defaultPatientId">
            <th class="header" mat-header-cell *matHeaderCellDef>Patient Id</th>
            <td mat-cell *matCellDef="let element">
              <!-- <a
                matTooltip="Click to View Patient"
                class="eyeCursorclass"
                [routerLink]="'/patientCreateEditTab/' + element?.patientId"
                target="_blank"
              > -->
              {{ element?.defaultPatientId }}
              <!-- </a> -->
            </td>
          </ng-container>
          <!--Patient Name-->
          <ng-container matColumnDef="patientName">
            <th class="header" mat-header-cell *matHeaderCellDef>
              Patient Name
            </th>
            <td mat-cell *matCellDef="let element">
              {{ element?.patientName }}
            </td>
          </ng-container>
          <ng-container matColumnDef="assignedTo">
            <th class="header" mat-header-cell *matHeaderCellDef>
              Assigned To
            </th>
            <td mat-cell *matCellDef="let element">
              {{ element?.assignedToName }}
            </td>
          </ng-container>

          <!-- Assigned By-->
          <ng-container matColumnDef="assignedByName">
            <th class="header" mat-header-cell *matHeaderCellDef>
              <!-- <mat-checkbox #isAssinged></mat-checkbox> -->
              Assigned By
            </th>
            <td mat-cell *matCellDef="let element">
              <!-- <a
                matTooltip="Click to Re-Assign"
                class="eyeCursorclass"
                target="_blank"
                rel="noopener"
              > -->
              {{ element?.assignedByName }}
              <!-- </a> -->
            </td>
          </ng-container>
          <!-- Assigned Date -->
          <ng-container matColumnDef="assignedDate">
            <th class="header" mat-header-cell *matHeaderCellDef>
              Assigned Date
            </th>
            <td mat-cell *matCellDef="let element">
              {{ element?.assignedDate | date : "MM/dd/yyyy" : "en_US" }}
            </td>
          </ng-container>
          <!--Scheduled Date-->
          <ng-container matColumnDef="scheduledDate">
            <th class="header" mat-header-cell *matHeaderCellDef>
              Scheduled Date
            </th>
            <td mat-cell *matCellDef="let element">
              {{ element?.scheduledOnDate | date : "MM/dd/yyyy" : "en_US" }}
            </td>
          </ng-container>

          <!--WIP Status-->
          <ng-container matColumnDef="wipStatus">
            <th class="header" mat-header-cell *matHeaderCellDef>Wip Status</th>
            <td mat-cell *matCellDef="let element">
              <div [ngSwitch]="element?.wipStatus">
                <div *ngSwitchCase="'yetToStart'">Yet To Start</div>
                <div *ngSwitchCase="'hold'">Hold</div>
                <div *ngSwitchCase="'inProcess'">In Process</div>
                <div *ngSwitchCase="'completed'">Completed</div>
                <div *ngSwitchDefault></div>
              </div>
            </td>
          </ng-container>
          <!--Options-->
          <ng-container matColumnDef="options">
            <th class="header" mat-header-cell *matHeaderCellDef>Options</th>
            <td mat-cell *matCellDef="let element" style="padding-left: 23px;">
              <a matTooltip="Move to Board" style="cursor:pointer;" (click)="moveToBoard(element.id)">
                <mat-icon>move_to_inbox</mat-icon>
              </a>
            </td>
          </ng-container>
          <!-- No Records-->
          <ng-container matColumnDef="noRecords">
            <td class="text-align-center" mat-footer-cell *matFooterCellDef colspan="6">
              <div *ngIf="!isLoading">
                {{ "No Records Found" }}
              </div>
              <div [hidden]="!isLoading">
                <mat-spinner [diameter]="220"></mat-spinner>
              </div>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="arrDisplayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: arrDisplayedColumns"></tr>
        </table>
        <div *ngIf="
            !(dataSource && dataSource?.data && dataSource?.data?.length !== 0)
          " class="text-align-center">
          <div class="message py-3" *ngIf="!isLoading">
            {{ "No Records Found" }}
          </div>
          <div class="py-3" [hidden]="!isLoading">
            <mat-spinner [diameter]="220"></mat-spinner>
          </div>
        </div>
        <mat-paginator [pageSizeOptions]="[10, 20, 50, 100]" showFirstLastButtons></mat-paginator>
      </div>
    </div>
  </div>
  <div class="pt-3">
    <button class="float-right" color="warn" mat-dialog-close mat-raised-button>
      Close
    </button>
  </div>
</div>