<br>
<mat-accordion>
  <!-- <mat-expansion-panel [expanded]="isPanelOpenState"> -->
  <mat-expansion-panel (toggle)="(isPanelOpenState)" [expanded]="isPanelOpenState" (afterExpand)="toggle()"
    (closed)="toggleV2()">
    <mat-expansion-panel-header>
      <mat-panel-title class="customThemeClass">
        <ng-container *ngIf="userId===''; else elseTitleTemplate">
          <b>Add User</b>
        </ng-container>
        <ng-template #elseTitleTemplate>
          <b>Edit User</b>
        </ng-template>
      </mat-panel-title>
    </mat-expansion-panel-header>

    <form [formGroup]="roleForm">
      <div class="row">

        <div class="col-1">
          <div class="profile-img-wrap mb-2">
            <div class="profile-img ">
              <a matTooltip="Change Logo" (click)="fileInput.click()">
                <img alt="" class="profile-image" [src]="fileAs64Value" />
                <div class="change-image-icon-container">
                  <a class="image-icon-container" aria-label="Change profile picture." target="_top">
                    <svg class="icon-container" enable-background="new 0 0 24 24" focusable="false" height="22"
                      viewBox="0 0 24 24" width="22">
                      <path style="fill: #9c9c9c"
                        d="M20 5h-3.17L15 3H9L7.17 5H4c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V7c0-1.1-.9-2-2-2zm0 14H4V7h16v12zM12 9c-2.21 0-4 1.79-4 4s1.79 4 4 4 4-1.79 4-4-1.79-4-4-4z">
                      </path>
                    </svg>
                  </a>
                </div>
                <input hidden accept="image/jpeg, image/png" [multiple]="false"
                  (change)="onFileSelected(fileInput.files[0])" #fileInput type="file" id="file">
                <ng-container *ngIf="showErrorForFileType">
                  <span class="asterisk">{{ 'Select Only Image type of JPEG' }}</span>
                </ng-container>
                <ng-container *ngIf="showErrorBasedOnSize">
                  <span class="asterisk">{{ 'Select Image`s size of 5mb or Less' }}</span>
                </ng-container>
              </a>
            </div>
          </div>
        </div>

        <mat-form-field class="col-2">
          <mat-label>Role Name <span class="asterisk">*</span></mat-label>
          <mat-select maxlength="40" [(value)]="role" formControlName="txtRole"
            (selectionChange)="onRoleChange($event)">
                <mat-option>
                 <ngx-mat-select-search placeholderLabel="Search"
                   noEntriesFoundLabel="No Matches found"
                   [formControl]="txtRoleFilter"></ngx-mat-select-search>
                 </mat-option>
            <mat-option *ngFor="let roles of drpRoles" [value]="roles.name">
              {{ roles.roleDisplayName }}
            </mat-option>
          </mat-select>
          <button mat-button (click)="clearRole()" (click)="role='';$event.stopPropagation()" *ngIf="role" matSuffix
            mat-icon-button>
            <mat-icon>close</mat-icon>
          </button>
          <mat-error *ngIf=" roleForm?.get('txtRole')?.touched && roleForm?.get('txtRole')?.errors?.required">
            Role Name is a required field!
          </mat-error>
        </mat-form-field>

        <!-- <mat-form-field class="col-3">
          <mat-label> User Name <span class="asterisk">*</span></mat-label>
          <input autocomplete="new-password" matInput maxlength="40" formControlName="txtUserName" type="text"
            oninput="this.value = this.value.charAt(0).toUpperCase() + this.value.slice(1); this.value = this.value.replace('^[a-zA-Z0-9_-]+(?:\s+[a-zA-Z0-9_-]+)*$', '').replace(/(\..*?)\..*/g, '$1').replace(/^(.*\.\d\d).*$|(\..*)$/g, '$1$2');" />
          <mat-error *ngIf="roleForm?.get('txtUserName')?.touched &&
          roleForm?.get('txtUserName')?.errors?.required ">
            User Name is a required field!
          </mat-error>
          <mat-error *ngIf="roleForm?.get('txtUserName')?.errors?.isUserNameExists">
            User Name is already taken!
          </mat-error>
        </mat-form-field> -->

        <mat-form-field class="col-3">
          <mat-label> User Name <span class="asterisk">*</span></mat-label>
          <input autocomplete="new-password" matInput maxlength="40" formControlName="txtUserName" type="text"
            oninput="this.value = this.value.replace(/[^a-zA-Z\s]+/g, ''); this.value = this.value.charAt(0).toUpperCase() + this.value.slice(1);" />
          <mat-error *ngIf="roleForm?.get('txtUserName')?.touched &&
          roleForm?.get('txtUserName')?.errors?.required ">
            User Name is a required field!
          </mat-error>
          <mat-error *ngIf="roleForm?.get('txtUserName')?.errors?.isUserNameExists">
            User Name is already taken!
          </mat-error>
        </mat-form-field>

        <mat-form-field class="col-3">
          <mat-label> Email <span class="asterisk">*</span></mat-label>
          <input autocomplete="new-txtEmail" matInput maxlength="40" formControlName="txtEmail" type="text"
          pattern="[a-zA-Z0-9._!#$%&'()*+,-./:;<=>?@[\]^_`{|}~]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$"
            [textMask]="{ mask: emailMask }" />

          <mat-error *ngIf="roleForm?.get('txtEmail')?.touched &&
          roleForm?.get('txtEmail')?.errors?.required ">
            Email Address is a required field!
          </mat-error>
          <mat-error *ngIf="
          roleForm?.get('txtEmail')?.touched &&
          roleForm?.get('txtEmail')?.errors?.pattern
                                    ">
            Please Enter a Valid Email Address
          </mat-error>
          <mat-error *ngIf="roleForm?.get('txtEmail')?.errors?.isEmailAddressExists">
            Email is already taken!
          </mat-error>
        </mat-form-field>

        <mat-form-field class="col-3">
          <mat-label>Main Branch<span class="asterisk">*</span></mat-label>
          <mat-select formControlName="sltMainBranchName" (selectionChange)="fnDisableRemoteBranches()">
                <mat-option>
                              <ngx-mat-select-search placeholderLabel="Search"
                                  noEntriesFoundLabel="No Matches found"
                                  [formControl]="sltMainBranchNameFilter"></ngx-mat-select-search>
                          </mat-option>
            <mat-option *ngFor="let organizations of drpOrganization" [value]="organizations.id">
              {{ organizations.organizationUnitName }}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="roleForm?.get('sltMainBranchName')?.touched &&
          roleForm?.get('sltMainBranchName')?.errors?.required ">
            Main Branch is a required field!
          </mat-error>
        </mat-form-field>

        <mat-form-field class="col-3">
          <mat-label>Remote Branch</mat-label>
          <mat-select formControlName="sltRemoteBranchName" multiple (selectionChange)="onSelectionChange($event)">
            <mat-option>
            <ngx-mat-select-search placeholderLabel="Search"
                                  noEntriesFoundLabel="No Matches found"
                                  [formControl]="sltRemoteBranchNameFilter"></ngx-mat-select-search>
                          </mat-option>
            <mat-option *ngIf="!RemoteSelectAllOption" [value]="'selectAll'">Select All</mat-option>
            <mat-option *ngIf="RemoteSelectAllOption" [value]="'selectAll'">Deselect All</mat-option>
            <mat-option *ngFor="let organization of drpOrganization" [value]="organization.id"
              [disabled]="sltMainBranchNameValue === organization.id ">
              {{ organization.organizationUnitName }}
            </mat-option>
          </mat-select>
          <button mat-button (click)="RemoteBranch='';$event.stopPropagation()" *ngIf="RemoteBranch" matSuffix
            (click)="clearRemoteBranch()" mat-icon-button>
            <mat-icon>close</mat-icon>
          </button>
        </mat-form-field>


        <mat-form-field class="col-3" *ngIf="userId===''">
          <mat-label> Password <span class="asterisk">*</span> </mat-label>
          <input autocomplete="new-password" matInput maxlength="40" [type]="hide ? 'password' : 'text'"
            formControlName="txtPassword" pattern="(?=.*\d)(?=.*[a-z])(?=.*[$@$#!%*?^()&])(?=.*[A-Z]).{8,}"
            type="text" (ngModelChange)="clearConfirmPassword()" />
          <a class="font-size-20" mat-icon-button matSuffix (click)="hide = !hide">
            <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
          </a>
          <mat-error *ngIf="
          roleForm?.get('txtPassword')?.touched &&
          roleForm?.get('txtPassword')?.errors?.required
                  ">
            Password is a required field!
          </mat-error>
          <mat-error *ngIf="
          roleForm?.get('txtPassword')?.touched &&
          roleForm?.get('txtPassword')?.errors?.pattern
                ">
            Passwords must contain at least one non alphanumeric character,one uppercase,one digit
            and at
            least 8 or more characters
          </mat-error>
        </mat-form-field>
        <mat-form-field class="col-3" *ngIf="userId===''">
          <mat-label>Confirm Password <span class="asterisk">*</span>
          </mat-label>
          <input autocomplete="new-password" matInput formControlName="txtConfirmPassword" id="txtConfirmPassword"
            type="password" />
          <mat-error *ngIf="
          roleForm?.get('txtConfirmPassword')?.touched &&
          roleForm?.get('txtConfirmPassword')?.errors?.required
                  ">
            Confirm Password is a required field!
          </mat-error>
          <mat-error *ngIf="roleForm?.get('txtConfirmPassword').pristine ||
          roleForm?.get('txtConfirmPassword')?.errors?.MatchPassword">Password and Confirm Password
            does
            not match</mat-error>
        </mat-form-field>

        <mat-form-field class="col-3">
          <mat-label> Phone No<span class="asterisk" *ngIf="isMandatory">*</span></mat-label>
          <input autocomplete="new-txtPhnNo" matInput prefix="+1-" mask="(000)-000-0000" maxlength="40"
            formControlName="txtPhnNo" type="text" />
          <mat-error *ngIf=" roleForm?.get('txtPhnNo')?.touched && roleForm?.get('txtPhnNo')?.errors?.required">
            Phone No is a required field!
          </mat-error>
        </mat-form-field>

        <mat-form-field class="col-3">
          <mat-label> Address 1 <span class="asterisk" *ngIf="isMandatory">*</span> </mat-label>
          <input autocomplete="new-txtAddress1" matInput maxlength="100" formControlName="txtAddress1" type="text"
            oninput="this.value = this.value.charAt(0).toUpperCase() + this.value.slice(1); this.value = this.value.replace('^[a-zA-Z0-9_-]+(?:\s+[a-zA-Z0-9_-]+)*$', '').replace(/(\..*?)\..*/g, '$1').replace(/^(.*\.\d\d).*$|(\..*)$/g, '$1$2');" />
          <mat-error *ngIf=" roleForm?.get('txtAddress1')?.touched && roleForm?.get('txtAddress1')?.errors?.required">
            Address1 is a required field!
          </mat-error>
        </mat-form-field>

        <mat-form-field class="col-3">
          <mat-label> Address 2 </mat-label>
          <input autocomplete="new-txtAddress2" matInput maxlength="100" formControlName="txtAddress2" type="text"
            oninput="this.value = this.value.charAt(0).toUpperCase() + this.value.slice(1); this.value = this.value.replace('^[a-zA-Z0-9_-]+(?:\s+[a-zA-Z0-9_-]+)*$', '').replace(/(\..*?)\..*/g, '$1').replace(/^(.*\.\d\d).*$|(\..*)$/g, '$1$2');" />
        </mat-form-field>

        <mat-form-field class="col-3">
          <mat-label>Country <span class="asterisk" *ngIf="isMandatory">*</span></mat-label>
          <mat-select formControlName="drpCountry" (selectionChange)='onChangeState($event.value)'
            [(value)]="Country">
            <mat-option>
                              <ngx-mat-select-search placeholderLabel="Search"
                                  noEntriesFoundLabel="No Matches found"
                                  formControlName="txtCountryFilter"></ngx-mat-select-search>
                          </mat-option>
            <mat-option *ngFor="let country of filteredCountries | async" [value]="country.countryShortName">
              {{ country.countryName }}
            </mat-option>
          </mat-select>
          <button mat-button (click)="Country='';$event.stopPropagation()" *ngIf="Country" (click)="clearCountry()"
            matSuffix mat-icon-button>
            <mat-icon>close</mat-icon>
          </button>
          <mat-error *ngIf="roleForm?.get('drpCountry')?.touched &&
          roleForm?.get('drpCountry')?.errors?.required ">
            Country Name is a required field!
          </mat-error>
        </mat-form-field>

        <mat-form-field class="col-3">
          <mat-label>State <span class="asterisk" *ngIf="isMandatory">*</span></mat-label>
          <mat-select formControlName="drpState" (selectionChange)='onChangeCity($event.value,"")' [(value)]="State"
            #singleSelect>
            <mat-option>
              <ngx-mat-select-search placeholderLabel="Search" noEntriesFoundLabel="No Matches found"
                formControlName="drpStateFilter">
              </ngx-mat-select-search>
            </mat-option>
            <mat-option *ngFor="let state of filteredStates | async" [value]="state.stateShortName">
              {{ state.stateName }}
            </mat-option>
          </mat-select>
          <button mat-button (click)="State='';$event.stopPropagation()" *ngIf="State" (click)="clearState()"
            matSuffix mat-icon-button>
            <mat-icon>close</mat-icon>
          </button>
          <mat-error *ngIf="roleForm?.get('drpState')?.touched &&
          roleForm?.get('drpState')?.errors?.required ">
            State 1 is a required field!
          </mat-error>
        </mat-form-field>

        <mat-form-field class="col-3">
          <mat-label>City <span class="asterisk" *ngIf="isMandatory">*</span></mat-label>
          <mat-select formControlName="drpCity" [(value)]="City" #singleSelect>
            <mat-option>
              <ngx-mat-select-search placeholderLabel="Search" noEntriesFoundLabel="No Matches found"
                formControlName="drpCityFilter">
              </ngx-mat-select-search>
            </mat-option>
            <mat-option *ngFor="let city of filteredCities | async" [value]="city.cityName">
              {{ city.cityName }}
            </mat-option>
          </mat-select>
          <button mat-button (click)="City='';$event.stopPropagation()" *ngIf="City" matSuffix (click)="clearCity()"
            mat-icon-button>
            <mat-icon>close</mat-icon>
          </button>
          <mat-error *ngIf="roleForm?.get('drpCity')?.touched &&
          roleForm?.get('drpCity')?.errors?.required ">
            City is a required field!
          </mat-error>
        </mat-form-field>

        <!-- <mat-form-field class="col-3">
          <mat-label> Zip Code <span class="asterisk" *ngIf="isMandatory">*</span></mat-label>
          <input autocomplete="new-txtPostalCode" matInput maxlength="10" formControlName="txtPostalCode"
            pattern="[0-9]*" type="text" class="zip-code-input"/>
          <mat-error
            *ngIf=" roleForm?.get('txtPostalCode')?.touched && roleForm?.get('txtPostalCode')?.errors?.pattern">
            Enter only Numbers!
          </mat-error>
          <mat-error *ngIf="roleForm?.get('txtPostalCode')?.touched &&
          roleForm?.get('txtPostalCode')?.errors?.required ">
            ZipCode is a required field!
          </mat-error>
        </mat-form-field> -->

        <mat-form-field class="col-3">
          <mat-label> Zip Code</mat-label>
          <input autocomplete="new-txtPostalCode" matInput maxlength="10" formControlName="txtPostalCode"
            pattern="[0-9-]*" type="text" class="zip-code-input" />
          <mat-error *ngIf=" roleForm?.get('txtPostalCode')?.touched &&
          roleForm?.get('txtPostalCode')?.errors?.pattern">
            Enter only Numbers!
          </mat-error>
          <mat-error *ngIf="roleForm?.get('txtPostalCode')?.touched &&
          roleForm?.get('txtPostalCode')?.errors?.required ">
            ZipCode is a required field!
          </mat-error>
        </mat-form-field>

      </div>
    </form>
    <br />
    <br />
    <div class="row">
      <div class="col-sm-12">
        <div class="text-lg-center">
          <!-- <button *ngIf='isShownSaveButton' [disabled]="roleForm?.invalid" mat-button class="buttonColor mr-2"
            (click)="saveOnTimeUser()"> {{ userId === '' ? 'Save' : 'Update' }}
          </button> -->
           <button *ngIf="isShownSaveButton" [disabled]="roleForm?.invalid || !isImageValid" mat-button
            class="buttonColor mr-2" (click)="saveOnTimeUser()">
            {{ userId === '' ? 'Save' : 'Update' }}
          </button>
          <button *ngIf='!isShownSaveButton' [disabled]="true" mat-button class="buttonColor mr-2"> Please wait ...!
            <mat-icon *ngIf="isShowSpinner">
              <mat-spinner class="spinner-border spinner-border-sm" diameter="20"></mat-spinner>
            </mat-icon>
          </button>
          <button mat-button class="resetclr mb-2 mr-2" (click)="reset()">Reset</button>
        </div>
      </div>
    </div>

  </mat-expansion-panel>
</mat-accordion>
<br>
    <div class="table table-responsive mt-3" *ngIf="!isLoading" >
      <table id="tbl" datatable [dtOptions]="dtUserTableOptions" class="tbl hover row-border hover w-100 display">
        <thead>
          <tr>
            <th><mat-icon> apps</mat-icon></th>
            <th [matTooltip]="'Status'">Status</th>
            <th [matTooltip]="'Role'">Role</th>
            <th [matTooltip]="'UserName'">UserName</th>
            <th [matTooltip]="'Email'">Email</th>
            <th [matTooltip]="'Main Branch'">Main Branch</th>
            <th [matTooltip]="'Remote Branch'">Remote Branch</th>
            <th class="text-right" [matTooltip]="'Phone Number'">Phone Number</th>
            <th [matTooltip]="'createdBy'">Created By</th>
            <th class="text-right" [matTooltip]="'Created Date'" class="text-center">Created Date</th>
            <th [matTooltip]="'Modify By'">Modify By</th>
            <th class="text-right" [matTooltip]="'Created/Modified Date'" class="text-center">Modified Date</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let data of userTableDataList">
            <td>
              <div class="d-flex justify-content-center align-items-center">
                <a class="eyeCursorclass"  matToolTip="Edit User">
                  <i (click)="ViewUser(data)" *ngIf="data?.status === 'Active'"
                    class="fa fa-pencil editBtnColor mr-1"></i>
                </a>
                <a *ngIf="data?.status === 'Active'" (click)="resetPassword(data.id)"
                  matTooltip="Reset Password">
                  <mat-icon class="eyeCursorclass" style="color: lightseagreen;">settings_backup_restore</mat-icon>
                </a>
              </div>
            </td>

            <td [matTooltip]="data.status|| '-'">

              <ng-container *ngIf="data.isAlreadyExists === true">
                <span [matTooltip]="'In Use'">In Use</span>
              </ng-container>

              <ng-container *ngIf="data.isAlreadyExists === false">
                <mat-slide-toggle (change)="statusChange($event, data)" [checked]="data.status === 'Active'"
                  [disabled]="data.isAdmin === true" class="ml-2">
                  {{ data.status }}
                </mat-slide-toggle>
              </ng-container>

            </td>

            <td [matTooltip]="data.roleDisplayName || '-'"
              (dblclick)="onRowDoubleClickV1(data?.roleDisplayName || '-')">{{data?.roleDisplayName ?
              data.roleDisplayName : '-'}}</td>
            <td [matTooltip]="data.userName || '-'" (dblclick)="onRowDoubleClickV1(data?.userName || '-')">{{data?.userName ?
              data.userName : '-'}}</td>
            <td [matTooltip]="data.email || '-'" (dblclick)="onRowDoubleClickV1(data?.email || '-')">{{data?.email ?
              data.email : '-' }}</td>
            <td [matTooltip]="data.organizationName || '-'" (dblclick)="onRowDoubleClickV1(data?.organizationName || '-')">{{data?.organizationName
              ? data.organizationName : '-' }}</td>
            <td [matTooltip]="data.remoteOrganizationName || '-'" (dblclick)="onRowDoubleClickV1(data?.remoteOrganizationName || '-')">
              {{data?.remoteOrganizationName ? data.remoteOrganizationName : '-'}}</td>
            <td class="text-right" [matTooltip]="data.phoneNumber || '-'" (dblclick)="onRowDoubleClickV1(data?.phoneNumber || '-')">
              <span *ngIf="(data.phoneNumber != '' && data.phoneNumber != null&& data.phoneNumber != 'string')">+1-</span>
              {{(data.phoneNumber == '' || data.phoneNumber == null||data.phoneNumber == 'string') ? "-" : data.phoneNumber}}</td>
            <!-- <td class="text-center">{{ data?.lastModificationTime ? (data.lastModificationTime | date:'MM/dd/yyyy HH:mm':'en_US') : '-'}}</td> -->
            <td [matTooltip]="data.createdBy || '-'" (dblclick)="onRowDoubleClickV1(data?.createdBy || '-')">{{data?.createdBy ?
              data.createdBy : '-'}}</td>
            <td class="text-right"  [matTooltip]="getFormattedDateAndTime(data?.creationTime)"
              (dblclick)="onRowDoubleClickV1(getFormattedDateAndTime(data?.creationTime))" class="text-center">
              {{ getFormattedDateAndTime(data?.creationTime) }}
            </td>
            <td [matTooltip]="data.modifyBy || '-'" (dblclick)="onRowDoubleClickV1(data?.modifyBy || '-')">{{data?.modifyBy ?
              data.modifyBy : '-'}}</td>
            <td class="text-right"  [matTooltip]="getFormattedDateAndTime(data?.lastModificationTime)"
              (dblclick)="onRowDoubleClickV1(getFormattedDateAndTime(data?.lastModificationTime))" class="text-center">
              {{ getFormattedDateAndTime(data?.lastModificationTime) }}
            </td>
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <th><mat-icon>apps</mat-icon></th>
            <th>
              <input matInput type="text" class="textbox-border-class" placeholder="Status" name="Status" />
            </th>
            <th>
              <input matInput type="text" class="textbox-border-class" placeholder="Role" name="search-Role" />
            </th>
            <th>
              <input matInput class="textbox-border-class" type="text" placeholder="UserName"
                name="search-UserName" />
            </th>
            <th>
              <input matInput type="text" class="textbox-border-class" placeholder="Email" name="search-Email" />
            </th>
            <th>
              <input matInput type="text" class="textbox-border-class" placeholder="Main Branch"
                name="search-MainBranch" />
            </th>
            <th>
              <input matInput type="text" class="textbox-border-class" placeholder="Remote Branch"
                name="search-RemoteBranch" />
            </th>
            <th class="text-right">
              <input matInput type="text" class="textbox-border-class" placeholder="Phone Number"
                name="search-PhoneNumber" />
            </th>
            <th>
              <input matInput class="textbox-border-class" type="text" placeholder="Created By"
                name="search-Created By" />
            </th>
            <th class="text-right">
              <input matInput type="text" class="textbox-border-class text-center" placeholder="Created Date"
                name="search-Created Date" />
            </th>
            <th>
              <input matInput class="textbox-border-class" type="text" placeholder="Modified By"
                name="search-Modified By" />
            </th>
            <th class="text-right">
              <input matInput type="text" class="textbox-border-class text-center" placeholder="Modified Date"
                name="search-Modified Date" />
            </th>
          </tr>
        </tfoot>
      </table>
    </div>
   <!-- </div>
  </div>
</div> -->
