import { ListService } from '@abp/ng.core';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSelect } from '@angular/material/select';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Title } from '@angular/platform-browser';
import { Store } from '@ngxs/store';
import { DashboardService } from 'projects/admin/src/app/admin-proxy/platform-app-management/rcm/platform-management/dashboard/dashboard.service';
import { OrganizationUnitService } from 'projects/admin/src/app/admin-proxy/platform-app-management/rcm/platform-management/organization-units';
import { UserService } from 'projects/admin/src/app/admin-proxy/platform-app-management/rcm/platform-management/user';
import { ItemInsuranceDTO, OrderMasterDropdownDTO } from 'projects/patient/src/app/dropdown-proxy/dropdown-management/dropdowns/dto/order/models';
import { OrderMasterDropdownService } from 'projects/patient/src/app/dropdown-proxy/dropdown-management/dropdowns/master-dropdown-service/order-master-dropdown.service';
import { PatientMasterDropdownService } from 'projects/patient/src/app/dropdown-proxy/dropdown-management/dropdowns/master-dropdown-service/patient-master-dropdown.service';
import { OrderDropdowns } from 'projects/patient/src/app/dropdown-proxy/dropdown-management/dropdowns/order-dropdowns.enum';
import { TableService } from 'projects/shared/src/app/table.service';
import { TextValidators } from 'projects/shared/src/app/validations/text-validator';
import { Observable, Subscription } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import Swal from 'sweetalert2';

// import { InsuranceProviderProductEligibilityRulesService } from 'projects/order/src/app/order-proxy/order-management/inventory';
// import { CreateUpdateInsuranceProviderProductEligibilityRulesDTO } from 'projects/order/src/app/order-proxy/order-management/inventory/dto';
@Component({
  selector: 'app-inventory-item',
  templateUrl: './inventory-item.component.html',
  styleUrls: ['./inventory-item.component.scss'],
  providers: [ListService]
})
export class InventoryItemComponent implements OnInit , OnDestroy {
  dropdownObservable$: Observable<any>;
  tableObservable$: Observable<any>;
  isLoading: boolean;
  itemSearchForm: FormGroup;
  drpLocation: any[] = [];
  drpInsurance: ItemInsuranceDTO[] = [];
  isShown: boolean = false;
  itemForm: FormGroup;
  dataSource: MatTableDataSource<any>;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  displayedColumns: string[] = ['Options','ItemId', 'itemName', 'price',  ];
  itemTableData: any[] = [];
  itemId: string = "";
  public strPageType: string = "InventoryItem";
  location: string;
  insurance: string;
  isShownUpdateIconForItem: boolean = true;
  isShownDeleteIconForItem: boolean = true;
  isItemDeletePermission: boolean = false;
  subscription$: Subscription[] = [];
  clientId: string = "";
  constructor(
    public title: Title,
    private store: Store,
    private Table: TableService,
    private formBuilder: FormBuilder,
    private branchService: OrganizationUnitService,
    public userProfileService: UserService,
    private textValidators: TextValidators,
    private dashboardService: DashboardService,
    // private itemService: InsuranceProviderProductEligibilityRulesService,
    // private itemSearchService: InsuranceProviderProductEligibilityRulesService,
    private patientDropdownService: PatientMasterDropdownService,
    private orderDropdownService: OrderMasterDropdownService,

    // private insuranceService: MasterInsuranceService,
    public list: ListService
  ) { }


  // drpInsuranceName: MasterInsuranceDTO[];

  isItemCreatePermission: boolean = false;
  isShownSaveButton: boolean = false;
  isItemUpdatePermission: boolean = false;
  isShownItemTable: boolean = true;

  ngOnDestroy(): void {
    this.subscription$?.forEach((sub) => {
      sub && sub?.unsubscribe();
    });
  }


  ngOnInit(): void {
    // Form
    this.itemForm = this.formBuilder.group({
      drpInsurance: new FormControl(null, [Validators.required]),
      txtItemName: new FormControl("", [Validators.required, this.textValidators.isTextCheck]),
      txtPrice: new FormControl(""),
      txtItemId: new FormControl(null, [Validators.required]),
      drpLocation: new FormControl("", [Validators.required]),
      txtInsSearchFilter: new FormControl("")

    })

    // searchFormList
    this.itemSearchForm = this.formBuilder.group({
      itemIdSearch: new FormControl(""),
      itemNameSearch: new FormControl(""),
      priceSearch: new FormControl(""),
    })

    // valueChanges
    this.itemSearchForm.valueChanges.subscribe((value: itemList) => {
      let filter: itemList = {
        itemIdSearch: value.itemIdSearch.trim().toLowerCase(),
        itemNameSearch: value.itemNameSearch.trim().toLowerCase(),
        priceSearch: value.priceSearch.trim().toLowerCase()
      };


      if (this.itemSearchForm.valid) {
        this.dataSource.filter = JSON.stringify(filter);
        this.filtertable(filter);
      }
    }, err => {
    });
    //this.loadItemTable();
    this.title.setTitle("Qsecure | Item");
    const getItem = this.Table.getItemCreate().subscribe(value => {
      this.isItemCreatePermission = value;
      if (this.isItemCreatePermission == true) {
        this.isShownSaveButton = true;
      }
      else {
        this.isShownSaveButton = false;
      }
    });
    this.subscription$.push(getItem);
    //
    const getItemView = this.Table.getItemView().subscribe(value => {
      if (value == true) {
        this.isShownItemTable = true;
        this.loadItemTable();
      }
      else {
        this.isShownItemTable = false;
      }
    });
    this.subscription$.push(getItemView);
    //
    this.getDropdown();
    // this.getInsuranceDropdown();
    const getItemUpdate = this.Table.getItemUpdate().subscribe(value => {
      this.isItemUpdatePermission = value;
      if (this.isItemUpdatePermission == true) {
        this.isShownUpdateIconForItem = true;
      } else {
        this.isShownUpdateIconForItem = false;
      }
    });
    this.subscription$.push(getItemUpdate);
    //
    const getItemDelete = this.Table.getItemDelete().subscribe(value => {
      this.isItemDeletePermission = value;
      if (this.isItemDeletePermission == true) {
        this.isShownDeleteIconForItem = true;
      } else {
        this.isShownDeleteIconForItem = false;
      }
    });
    this.subscription$.push(getItemDelete);
    //
    const valueChanges = this.itemForm.valueChanges.subscribe(() => {
      let itemName: AbstractControl = this.itemForm?.get('txtItemName');
      if (itemName.dirty) {
        itemName?.value?.trim() === "" && itemName?.setErrors({ required: true })
        itemName?.markAsDirty();
        itemName?.markAsTouched();
        // firstname.markAsPristine();

      }

      let itemId: AbstractControl = this.itemForm?.get('txtItemId');
      if (itemId.dirty) {
        itemId?.value?.trim() === "" && itemId?.setErrors({ required: true })
        itemId?.markAsDirty();
        itemId?.markAsTouched();
        //   firstname.markAsPristine();
      }



    }, err => {
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    })
    this.subscription$.push(valueChanges);
  }

  // Only Numbers with Decimals
  keyPressNumbersDecimal(event) {

    var charCode = (event.which) ? event.which : event.keyCode;
    if (charCode != 46 && charCode > 31
      && (charCode < 48 || charCode > 57)) {
      event.preventDefault();
      return false;
    }
    return true;
  }

  clearLocation() {
    this.location = '';
    this.itemForm.patchValue({ drpLocation: "" });
  }

  //! filter table
  filtertable(filter) {
    let itemId = filter.itemIdSearch;
    let itemName = filter.itemNameSearch;
    let price = filter.priceSearch;
    this.itemTableData = [];
    // this.itemSearchService.searchItemBySItemCodeAndSItemNameAndSPrice(itemId, itemName, price).subscribe(data => {

    //   this.itemTableData = [];
    //   data?.items.forEach(element => {

    //     this.itemTableData.push({
    //       id: element?.id,
    //       equipmentBillingCode: element.equipmentBillingCode,
    //       itemName: element.itemName,
    //       price:element?.price,
    //       creationTime: element.creationTime
    //     });
    //     this.dataSource = new MatTableDataSource(this.itemTableData);
    //     this.dataSource.sort = this.sort;
    //     setTimeout(() => this.dataSource.paginator = this.paginator);
    //   })
    // })
  }


  //! Insurance Form Reset Function
  resetItem() {
    this.itemForm.patchValue({
      drpInsurance: null,
      txtItemName: "",
      txtPrice: "",
      txtItemId: "",
      drpLocation: "",


    })
    this.itemId = "";
    this.itemForm.reset();
    this.itemForm.markAsUntouched();
    this.itemForm.updateValueAndValidity();
  }

  //! Item Save Funtion
  organizationUnitId = null;
  saveItem() {
    // this.organizationUnitId = localStorage.getItem('initialOrganizationUnitId');
    // let rules:RulesValidatityDTO
    // let itemDetails: CreateUpdateInsuranceProviderProductEligibilityRulesDTO = {
    //   ruleId: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
    //   locationId: this.itemForm.value.drpLocation,
    //   // insuranceId: this.itemId,
    //   insuranceId: this.itemForm.value.drpInsurance ?? "",
    //   itemName: this.itemForm.value.txtItemName,
    //   price: +this.itemForm.value.txtPrice,
    //   equipmentBillingCode: this.itemForm.value.txtItemId,
    //   itemsAllowed: 0,
    //   minPeriodInMonths: 0,
    //   rulesValidatity:
    //   {
    //     startDate: "",
    //     endDate: "2021-01-23T04:19:08.665Z",
    //     active: true
    //   },
    //   // organizationUnitId: this.organizationUnitId

    // }
    // if (this.itemId === "" || this.itemId === undefined || this.itemId === null) {
    //   this.itemService.create(itemDetails).subscribe(response => {
    //     //
    //     Swal.fire({ title: 'Success', html: 'Saved Successfully', icon: 'success', timer: 3000, timerProgressBar: true });
    //     this.resetItem()
    //     this.loadItemTable();
    //     // this.isShownSaveButton = false;
    //   }, err => {
    //     const data: HttpErrorResponse = err;
    //     Swal.fire({
    //       icon: 'error',
    //       text: data?.error?.error?.message,
    //     });
    //   });
    // }
    // else {
    //   this.itemService.update(this.itemId, itemDetails).subscribe(response => {
    //     Swal.fire({ title: 'Success', html: 'Updated Successfully', icon: 'success', timer: 3000, timerProgressBar: true });
    //     this.resetItem();
    //     this.loadItemTable();
    //     // this.isShownSaveButton = false;
    //   }, err => {
    //     const data: HttpErrorResponse = err;
    //     Swal.fire({
    //       icon: 'error',
    //       text: data?.error?.error?.message,
    //     });
    //   });
    // }
    // this.itemId = "";
  }

  ViewItem(datas?: any) {
    //
    this.getItem(datas.id)
  }

  //! View Item Function By Id
  getItem(itemId: string) {
    const getItemUpdate = this.Table.getItemUpdate().subscribe(value => {
      this.isItemUpdatePermission = value;
      if (this.isItemUpdatePermission == true) {
        this.isShownSaveButton = true;
      }
      else {
        this.isShownSaveButton = false;
      }
    }, err => {
      // const data: HttpErrorResponse = err;
      // Swal.fire({
      //   icon: 'error',
      //   text: data?.error?.error?.message,
      // });
    });
    this.subscription$.push(getItemUpdate);
    // this.itemService.get(itemId).subscribe(response => {
    //   //
    //   this.itemForm.patchValue({
    //     drpInsurance: response.insuranceId,
    //     txtItemName: response.itemName,
    //     txtPrice: response.price,
    //     txtItemId: response.equipmentBillingCode,
    //     drpLocation: response.locationId
    //   })
    //   this.itemId = response.id;
    // }, err => {
    //   const data: HttpErrorResponse = err;
    //   Swal.fire({
    //     icon: 'error',
    //     text: data?.error?.error?.message,
    //   });
    // });
  }

  //! Item TableData Load Function
  loadItemTable() {
    this.isLoading = true;
    this.itemTableData = [];
    this.Table.setInventoryTable(this.itemTableData);
    // this.organizationUnitId = localStorage.getItem('initialOrganizationUnitId');
    // const itemList = (query: any) => this.itemService.getList(query);
    // this.list.hookToQuery(itemList).subscribe(response => {
    //   this.itemTableData = [];
    //   response?.items?.forEach(element => {
    //     this.itemTableData.push({
    //       id: element?.id,
    //       ruleId: element.ruleId,
    //       insuranceId: element.insuranceId,
    //       itemName: element.itemName,
    //       price: element.price,
    //       locationId: element.locationId,
    //       equipmentBillingCode: element.equipmentBillingCode,
    //       creationTime: element.creationTime
    //     });
    //     this.dataSource = new MatTableDataSource(this.itemTableData);
    //     this.dataSource.sort = this.sort;
    //     setTimeout(() => this.dataSource.paginator = this.paginator);
    //   })
    //   this.itemTableData = this.itemTableData.sort((a, b) => { return new Date(a?.creationTime).getTime() - new Date(b?.creationTime).getTime() }).reverse();
    //   this.Table.setInventoryTable(this.itemTableData);
    //   this.isLoading = false;
    //   this.isLoading = false;
    // }, err => {
    //   const data: HttpErrorResponse = err;
    //   Swal.fire({
    //     icon: 'error',
    //     text: data?.error?.error?.message,
    //   });
    //   this.isLoading = false;
    // });




    // const itemList = query => this.itemService.getList(query);

    // this.tableObservable$ = this.list.hookToQuery(itemList);

    // this.tableObservable$ = this.itemService.getInsuranceProviderProductEligibilityRulesBasedOnOrganizationIdByOrganizationUnitId
    // (this.organizationUnitId)

    // const combinedData = combineLatest([this.tableObservable$, this.dropdownObservable$]);

    // combinedData.subscribe(response => {
    //
    //     this.itemTableData = [];
    //     response[1] &&   response?.forEach(element => {
    //

    // if (element) {
    //       this.itemTableData.push({
    //         id: element?.id,
    //         ruleId: element.ruleId,
    //         insuranceId: element.insuranceId,
    //         itemName: element.itemName,
    //         price: element.price,
    //         locationId: element.locationId,
    //         equipmentBillingCode: element.equipmentBillingCode,

    //       });
    // this.dataSource = new MatTableDataSource(this.itemTableData);
    // this.dataSource.sort = this.sort;
    // setTimeout(() => this.dataSource.paginator = this.paginator);
    // }
    //     })
    //     this.Table.setInventoryTable(this.itemTableData);
    //   })


    // combinedData.subscribe(response => {
    //
    //     this.itemTableData = [];
    //     response[1] &&   response && response[0]?.totalCount && response[0]?.items && response[0]?.items.forEach(element => {
    //

    // if (element) {
    //       this.itemTableData.push({
    //         id: element?.id,
    //         ruleId: element.ruleId,
    //         insuranceId: element.insuranceId,
    //         itemName: element.itemName,
    //         price: element.price,
    //         locationId: element.locationId,
    //         equipmentBillingCode: element.equipmentBillingCode,

    //       });
    // this.dataSource = new MatTableDataSource(this.itemTableData);
    // this.dataSource.sort = this.sort;
    // setTimeout(() => this.dataSource.paginator = this.paginator);
    // }
    //     })
    //     this.Table.setInventoryTable(this.itemTableData);
    //   })

  }


  deleteItems(datas?: any) {
    //
    this.deleteItem(datas.id)
  }
  //! Delete Item Function By Id
  deleteItem(itemId: string) {

    Swal.fire({
      title: 'Are you sure you want to Delete?',
      text: 'You won\'t be able to retrieve this data!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#34c38f',
      cancelButtonColor: '#f46a6a',
      confirmButtonText: 'Yes, delete it!'
    }).then(result => {
      if (result.value) {
        // this.itemService.delete(itemId).subscribe(response => {
        //   this.itemTableData.forEach(element => {
        //     if (itemId == element.id) {
        //       let index = this.itemTableData.indexOf(element, 0)
        //       this.itemTableData.splice(index, 1);
        //       Swal.fire({ title: 'Success', html: 'Deleted Successfully', icon: 'success', timer: 3000, timerProgressBar: true });
        //     }
        //   });
        //   this.Table.setInventoryTable(this.itemTableData);
        //   this.resetItem();
        //   // this.dataSource = new MatTableDataSource(this.itemTableData);
        //   // this.dataSource.sort = this.sort;
        //   // this.dataSource.paginator = this.paginator;
        // }, err => {
        //   const data: HttpErrorResponse = err;
        //   Swal.fire({
        //     icon: 'error',
        //     text: data?.error?.error?.message,
        //   });
        // });
      }
    });
  }

  public filteredInsTypes: Observable<ItemInsuranceDTO[]>;
  @ViewChild('singleSelect') singleSelect: MatSelect;
  getDropdown() {
    let orderDropdownInput: OrderDropdowns[];
    orderDropdownInput = [
      OrderDropdowns.ItemInsurances
    ]
    const getOrderDropdown = this.orderDropdownService.getOrderDropdownsByInput(orderDropdownInput).subscribe((stateResponse) => {

      let response: OrderMasterDropdownDTO = stateResponse;
      // this.drpLocation = response?.locationTypes;
      this.drpInsurance = response?.itemInsurances;

      this.filteredInsTypes = this.itemForm?.get("txtInsSearchFilter").valueChanges
        .pipe(
          startWith(''),
          map(value => this.drpInsurance.filter(option => option?.itemInsuranceType?.toLowerCase()?.includes(value?.toLowerCase() ?? "")))
        );
    });
    this.subscription$.push(getOrderDropdown);
    //  this.store.dispatch(new getDropdown()).subscribe((stateResponse) => {
    //     let response: PatientMasterDropdownDTO = stateResponse.dropdownStates.selectedDropdown;
    //     this.drpLocation = response.locationTypes;
    //     this.drpInsurance = response['itemInsurances'];
    //     this.filteredInsTypes = this.itemForm?.get("txtInsSearchFilter").valueChanges
    //     .pipe(
    //       startWith(''),
    //       map(value => this.drpInsurance.filter(option => option?.itemInsuranceType?.toLowerCase()?.includes(value?.toLowerCase()??"")))
    //       );
    //   })

    this.dashboardService.userProfile().subscribe(response => {

      this.clientId = response.tenantId

      const getOrganizationList = this.branchService.getOrganizationList(this.clientId).subscribe(response => {

        response?.forEach(element => {
          this.drpLocation.push({
            id: element.id,
            organizationUnitName: element.organizationUnitName,
            tenantId: element.tenantId,
          });
        });
      }, err => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      })
      this.subscription$.push(getOrganizationList);
      //
    })
  }

  txtInsurance: string = '';
  clearInsType() {
    this.txtInsurance = '';
    this.itemForm.patchValue({ drpInsurance: "" });
    let orderDropdownInput: OrderDropdowns[];
    orderDropdownInput = [
      OrderDropdowns.ItemInsurances
    ]
    const orderDropdown = this.orderDropdownService.getOrderDropdownsByInput(orderDropdownInput).subscribe((stateResponse) => {
      let response: OrderMasterDropdownDTO = stateResponse;
      // this.drpLocation = response.locationTypes;
      this.drpInsurance = response['itemInsurances'];
    }, err => {
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    })
    this.subscription$.push(orderDropdown)
  }
  clearInsurance() {
    this.insurance = '';
  }



}
export interface itemList {
  itemIdSearch: string,
  itemNameSearch: string,
  priceSearch: string
}


