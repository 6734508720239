import { PagedResultDto, RestService } from '@abp/ng.core';
import { Injectable } from '@angular/core';
import type { BillingDenialDTO, BillingDenialSearchDTO, BillingOrderInvoiceDTO, OrderInvoiceDTO } from '../dto/models';
import type { PayerResponseDTO } from '../../../patient-management/patient/dto/models';

@Injectable({
  providedIn: 'root',
})
export class BillingDenialService {
  apiName = 'billingManagement';

  // getdrpPayerByIModeAndSSearchAndGPayor = (iMode: number, sSearch: string, gPayor: string) =>
  //   this.restService.request<any, PayerResponseDTO[]>({
  //     method: 'GET',
  //     url: '/api/billingApp/billing-denial/drp-payer',
  //     params: { iMode, sSearch, gPayor },
  //   },
  //   { apiName: this.apiName });

    getdrpPayerByIModeAndSSearchAndGPayorAndExistingPayors = (iMode: number, sSearch: string, gPayor: string, ExistingPayors: string[]) =>
      this.restService.request<any, PayerResponseDTO[]>({
        method: 'POST',
        url: '/api/billingApp/billing-denial/getdrp-payer',
        params: { iMode, sSearch, gPayor },
        body: ExistingPayors,
      },
      { apiName: this.apiName });

      getDenialReportsByInput = (input: BillingDenialSearchDTO) =>
        this.restService.request<any, PagedResultDto<BillingDenialDTO>>({
          method: 'POST',
          url: '/api/billingApp/billing-denial/get-denial-reports',
          body: input,
        },
        { apiName: this.apiName });

    getBillingInvoiceByGOrderId = (gOrderId: string) =>
      this.restService.request<any, BillingOrderInvoiceDTO[]>({
        method: 'GET',
        url: `/api/billingApp/billing-denial/get-billing-invoice/${gOrderId}`,
      },
      { apiName: this.apiName });

      getBillingInvoiceV1ByGOrderId = (gOrderId: string) =>
        this.restService.request<any, OrderInvoiceDTO>({
          method: 'GET',
          url: `/api/billingApp/billing-denial/get-billing-invoice-v1/${gOrderId}`,
        },
        { apiName: this.apiName });

    getBillingInvoiceV1ByGOrderIdAndRentalId = (gOrderId: string, rentalId: string) =>
      this.restService.request<any, OrderInvoiceDTO>({
        method: 'GET',
        url: '/api/billingApp/billing-denial/get-billing-invoice-v1',
        params: { gOrderId, rentalId },
      },
      { apiName: this.apiName });
      getDenialsListBinQueue = () =>
        this.restService.request<any, any>({
          method: 'GET',
          url: '/api/billingApp/billing-denial/get-denial-reports-for-wonace',
        },
        { apiName: this.apiName });

  constructor(private restService: RestService) {}
}
