import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { DataTableDirective } from 'angular-datatables';
import { Observable, Subscription } from 'rxjs';
import { TableService } from 'projects/shared/src/app/table.service';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DatePipe } from '@angular/common';
@Component({
  selector: 'app-client-table',
  templateUrl: './client-table.component.html',
  styleUrls: ['./client-table.component.scss']
})
export class ClientTableComponent implements OnInit {

  @Input() tableData: any;
  clientListData: any;
  $subscription: Subscription[] = [];
  tableData$: Subscription;
  userId: string;
  @ViewChild(DataTableDirective, { static: false })
  datatableElement: DataTableDirective;

  // dtvOptions = {
  //   dom: 'Bfrtip',
  //   responsive: true,
  //   paging: false,
  //   scrollCollapse: true,
  //   scrollY: '420px',
  //   scrollX: true,
  //   info: false,
  //   columnDefs: [
  //     { targets: [0, 1, 3], orderable: false },
  //   ],
  //   buttons: [
  //     {
  //       extend: 'excel',
  //       text: 'Export',
  //       exportOptions: {
  //         columns: [1, 2, 3, 4, 5, 6],
  //       },
  //     },
  //   ],
  // };

  dtClientTableOptions: any = {
    responsive: true,
    paging: false,
    dom: 'Bfrtip',
    language: {
      emptyTable: 'No records',
      info: 'Total : _MAX_ records',
      infoEmpty: 'Total : _MAX_ records',
      infoFiltered: '(filtered : _TOTAL_ records)',
      loadingRecords: 'Loading...',
      zeroRecords: 'No matching records',
    },
    scrollCollapse: true,
    scrollY: '420px',
    scrollX: true,
    fixedHeader: true,
    columnDefs: [
      { targets: [0, 1], orderable: false },
      { targets: [0, 1, -1], className: 'dt-fixed-column' },
      {
        targets: [6],
        type: 'date',
        render: function (data, type, row) {
          // Assuming data is in the format MM/DD/YYYY
          if (type === 'sort' || type === 'type') {
            var dateParts = data.split('/');
            return new Date(dateParts[2], dateParts[0] - 1, dateParts[1]);
          } else {
            return data;
          }
        },
      },
    ],
    search: {
      smart: false, // Set to "none" for full exact match
    },
    buttons: [
      {
        extend: 'excel',
        text: 'Export',
        filename: 'Client List',
        exportOptions: {
          columns: ':visible:not(:first-child,:eq(0)) ',
        },
      },
      {
        extend: 'colvis',
        columns: ':not(:eq(0), :eq(1), :eq(2), :eq(-1))', // Exclude first three and last columns from the column visibility toggle
      }
    ],
  };


  constructor(
    private Table: TableService,
    private router: Router,
    private snackBar: MatSnackBar,
    private datepipe: DatePipe,
  ) { }

  ngOnInit(): void {
    this.clientListData = this.tableData;
    this.dtClientTableOptions.buttons[0].filename =
    'Client List' + '-' + this.datepipe.transform(new Date(), 'MM-dd-yyyy');
  }
  //! edit record
  ViewClient(event: { userId: any; tenantId: string; status: any }) {
    // this.Table.setinactiveTable(event)
    this.Table.setOrderId(event.userId ? event.userId : this.userId);
    this.router.navigate(['clientView/' + event.tenantId], {
      fragment: event.status,
    });
  }

  //After View Method
  ngAfterViewInit(): void {
    if (this.datatableElement && this.datatableElement.dtInstance) {
      this.refreshDataTable();
    } else {
      setTimeout(() => this.refreshDataTable(), 500);
    }
  }


  refreshDataTable(): void {
    this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.columns().every(function () {
        const that = this;
        $('input', this.footer()).on('keyup change', function () {
          if (that.search() !== this['value']) {
            that.search(this['value']).draw();
          }
        });
      });
    });
  }

  ngOnDestroy(): void {
    if (this.$subscription) {
      this.$subscription.forEach((subscription) => {
        subscription.unsubscribe();
      });
    }
    this.tableData$?.unsubscribe();
  }

  onRowDoubleClickV1(textToCopy) {
    this.copyTextToClipboard(textToCopy);
    this.showNotification('Text copied: ' + textToCopy);
  }

  showNotification(message: string): void {
    this.snackBar.open(message, 'Close', {
      duration: 3000, // Duration in milliseconds
      horizontalPosition: 'end', // Positioning the notification horizontally
      verticalPosition: 'bottom', // Positioning the notification vertically
    });
  }

  copyTextToClipboard(text: string): void {
    const textArea = document.createElement('textarea');
    textArea.value = text;
    document.body.appendChild(textArea);
    textArea.select();
    document.execCommand('Copy');
    textArea.remove();
  }
  getFormattedDate(date: string): string {
    return date ? new Date(date).toLocaleDateString('en-US') : '-';
  }
}
