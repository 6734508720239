<meta
  http-equiv="cache-control"
  content="no-cache, must-revalidate, post-check=0, pre-check=0"
/>
<meta http-equiv="expires" content="0" />
<meta http-equiv="pragma" content="no-cache" />
<!-- Inbound Doc Assigning  -->
<mat-dialog-content class="mat-typography">
  <div class="container-fluid">
    <div class="row mt-3">
      <div class="col-lg-12 d-flex justify-content-between">
        <div class="col-lg-5 col-md-4 col-sm-12">
          <div class="row">
            <div class="col-12 d-flex justify-content-between">
              <h3 class="customThemeClass mt-1">
                <b>New Setup </b>
              </h3>
              <h3 class="mt-2 font-weight-bolder">
                Fax Id -
                <span class="app-custom-color font-weight-bolder">{{
                  defaultFaxId
                }}</span>
              </h3>

              <h3 class="mt-2 font-weight-bolder">
                Document Checklist -
                <span class="app-custom-color font-weight-bolder">{{
                  isDocumentCheckListShow
                }}</span>
              </h3>

              <h3 class="mt-2 font-weight-bolder">
                Sorting -
                <span class="app-custom-color font-weight-bolder">{{
                  isSortingShow
                }}</span>
              </h3>

              <!-- <h3 class="mt-2 font-weight-bolder">
                Patient Chart -
                <span class="app-custom-color">{{txtChartV1 }}</span>
              </h3> -->
            </div>
          </div>
        </div>
        <div
          class="col-lg-7 col-md-8 col-sm-12 d-flex justify-content-center align-items-center"
        >
          <div class="row">
            <div class="col-12">
              <button
                mat-raised-button
                class="buttonColor mb-2 mr-1 p-1 dd-text"
                [matTooltip]="
                  TiffError
                    ? 'Refresh Document'
                    : 'Document already loading successfully'
                "
                (click)="fnRefreshButtonClicked()"
                [disabled]="!TiffNotLoadingError || refreshButtonClicked"
              >
                <mat-icon
                  *ngIf="TiffNotLoadingError"
                  [ngClass]="TiffError ? 'rotate-btn' : ''"
                  >refresh</mat-icon
                >
                <mat-icon *ngIf="!TiffNotLoadingError">done_outline</mat-icon>
              </button>

              <button
                mat-raised-button
                class="buttonColor mb-2 p-1 dd-text mr-1"
                matTooltip="Reset Patient Details"
                [disabled]="isEmpty(patientId) || !exisingPatient"
                (click)="resetPatientDetails()"
              >
                <i class="fa fa-refresh reset align-items-center"></i>
              </button>
              <button
                mat-raised-button
                class="buttonColor mb-2 p-2 dd-text mr-1"
                matTooltip="Patient Search"
                type="button"
                (click)="openSearchMdl()"
              >
                <i class="fa fa-list mr-1 fontSize" aria-hidden="true"></i>
                Search
              </button>
              <button
                mat-raised-button
                class="buttonColor mb-2 p-2 dd-text mr-1"
                matTooltip="View Notes"
                type="button"
                [disabled]="isEmpty(patientId)"
                (click)="openViewNotesPopup()"
              >
                <img
                  src="assets/images/NewSetup/VectorV1.png"
                  alt=""
                  class="sea-green-icon-ins mr-1"
                />
                Notes
              </button>
              <button
                mat-raised-button
                class="buttonColor mb-2 p-2 dd-text mr-1"
                type="button"
                matTooltip="View Documents"
                [disabled]="
                  isViewDocument ||
                  isEmpty(documentIdV1) ||
                  isEmpty(defaultFaxIdV1)
                "
                (click)="viewPatientDocument()"
              >
                <img
                  src="assets/images/NewSetup/GroupV1.png"
                  alt=""
                  class="sea-green-icon-ins mr-1"
                />
                Documents
              </button>
              <button
                mat-raised-button
                class="buttonColor mb-2 p-2 dd-text mr-1"
                matTooltip="Join Fax"
                [disabled]="isEmpty(documentId)"
                (click)="joinFaxModal()"
              >
                <img
                  src="assets/images/NewSetup/Vectorv2.png"
                  alt=""
                  class="sea-green-icon-ins mr-1"
                  type="button"
                />
                Join Fax
              </button>
              <button
                mat-raised-button
                class="buttonColor mb-2 p-2 dd-text mr-1"
                matTooltip="Map User"
                [disabled]="isEmpty(documentId)"
                (click)="OpenMapUser()"
              >
                <img
                  src="assets/images/NewSetup/GroupV2.png"
                  alt=""
                  class="sea-green-icon-ins mr-1"
                  type="button"
                />
                Map User
              </button>
              <button
                mat-raised-button
                class="buttonColor mb-2 p-2 dd-text mr-1"
                matTooltip="Move to Bin"
                type="button"
                [disabled]="isMovetoDisable"
                (click)="newSetupMoveTo()"
              >
                <img
                  src="assets/images/NewSetup/MovetoBin.png"
                  alt=""
                  class="move-bin-icon mr-1"
                />
                Move to Bin
              </button>

              <button
                class="buttonColor mb-2 p-2 dd-text mr-1"
                [disabled]="isEmpty(blobName) || isDownloading"
                mat-raised-button
                matTooltip="Download Patient Document"
                [matTooltipDisabled]="isEmpty(blobName)"
                (click)="patientDocPdfDownload()"
              >
                <i class="fa fa-download mr-1" aria-hidden="true"></i>
                <span *ngIf="!isDownloading" class="dd-text ml-1"
                  >Document</span
                >
                <span *ngIf="isDownloading" class="dd-text ml-1"
                  >Downloading</span
                >
                <mat-icon *ngIf="isDownloading">
                  <mat-spinner
                    class="spinner-border-sm ml-1"
                    diameter="18"
                  ></mat-spinner>
                </mat-icon>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="row" *ngIf="isExistDocMessage===true">
      <div class="col-lg-12">
        <span class="blinking">
          Checking, if patient has existing fax in bin </span>
      </div> -->
    <!-- </div> -->
    <div class="row">
      <!-- Input Data -->
      <div
        class="col-12 col-sm-12 col-md-12 col-lg-5 col-xl-5"
        style="min-height: 70vh; max-height: 72vh"
      >
        <div class="row">
          <div class="col-xl-12 pb-2">
            <div class="card elevated-div">
              <ul
                class="card-title"
                ngbNav
                #nav="ngbNav"
                justify="justified"
                class="alert-success nav nav-pills nav-fill"
                style="border-bottom: 2px solid rgb(201, 201, 201)"
              >
                <li ngbNavItem="demographics" id="demographics">
                  <a
                    [ngClass]="activeTab == 'demographics' ? 'active' : ''"
                    ngbNavLink
                    id="tab-1"
                    (click)="changeTab('demographics')"
                    ><b>Demographics</b></a
                  >
                </li>
                <!-- [disabled]="isDemoCompleted" -->
                <li ngbNavItem="plans" id="plans">
                  <a
                    [ngClass]="activeTab == 'plans' ? 'active' : ''"
                    ngbNavLink
                    id="tab-2"
                    (click)="changeTab('plans')"
                    ><b>Plans</b></a
                  >
                </li>
                <!-- Document Details -->

                <li ngbNavItem="document" id="documents">
                  <a
                    [ngClass]="activeTab == 'document' ? 'active' : ''"
                    ngbNavLink
                    id="tab-3"
                    (click)="changeTab('document')"
                    ><b>Sorting</b></a
                  >
                </li>
              </ul>

              <div
                class="card-body mb-0 scrollable-card"
                style="min-height: 40vh; max-height: 50vh"
              >
                <form [formGroup]="demoForm">
                  <!-- Demographics -->
                  <div *ngIf="activeTab === 'demographics'">
                    <!-- Patient Details -->
                    <div class="card mb-0 card-body">
                      <div class="row">
                        <div class="col-12 d-flex justify-content-between">
                          <mat-label
                            ><b class="customThemeClass"
                              >{{
                                isExistDocMessage == true
                                  ? "Patient Info" +
                                    " " +
                                    "
                              - "
                                  : "Patient Info"
                              }}
                            </b>
                            <span
                              *ngIf="isExistDocMessage === true"
                              class="blinking mt-1 ml-1"
                            >
                              Checking, if patient has existing fax in bin</span
                            ></mat-label
                          >

                          <!-- STAT-->
                          <!-- <ng-container *ngIf="isStat==='1'">
                            <mat-slide-toggle [checked]='true' formControlName="tglStat" class="ml-2 "> STAT
                            </mat-slide-toggle>
                          </ng-container>
                          <ng-container *ngIf="isStat==='0'">
                            <mat-slide-toggle [checked]='false' formControlName="tglStat" class="ml-2 ">STAT
                            </mat-slide-toggle>
                          </ng-container> -->
                          <ng-container>
                            <mat-slide-toggle
                              formControlName="tglStat"
                              class="ml-2"
                              >STAT
                            </mat-slide-toggle>
                          </ng-container>
                        </div>
                        <!-- Chart No -->
                        <mat-form-field class="col-3">
                          <mat-label> Chart No</mat-label>
                          <!-- <span class="asterisk">*</span> -->
                          <input
                            matInput
                            readonly
                            maxlength="40"
                            formControlName="txtChart"
                            type="text"
                            autocomplete="new-txtChart"
                          />
                          <mat-error
                            class="spanAlignment"
                            *ngIf="
                              demoForm?.get('txtChart')?.touched &&
                              demoForm?.get('txtChart')?.errors?.required
                            "
                          >
                            Chart is a required field!
                          </mat-error>
                        </mat-form-field>
                        <!-- Title-->
                        <mat-form-field class="col-3">
                          <mat-label>Title </mat-label>
                          <mat-select
                            formControlName="drpTitle"
                            autocomplete="new-drpTitle"
                          >
                            <mat-option
                              *ngFor="let title of drpTitleLoop"
                              [value]="title.titleName"
                            >
                              {{ title.titleName }}
                            </mat-option>
                          </mat-select>
                          <!-- <mat-error class="spanAlignment" *ngIf="
                          demoForm?.get('drpTitle')?.touched &&
                          demoForm?.get('drpTitle')?.errors?.required
                          ">
                            Title is a required field!
                          </mat-error> -->
                        </mat-form-field>
                        <!-- First Name -->
                        <mat-form-field class="col-3">
                          <mat-label>
                            <!-- ^[A-Za-z -]+$ -->
                            First Name<span class="asterisk">*</span></mat-label
                          >
                          <input
                            matInput
                            maxlength="40"
                            formControlName="txtFirstName"
                            type="text"
                            pattern="^[^0-9]*$"
                            autocomplete="new-txtFirstName"
                          />
                          <mat-error
                            class="spanAlignment"
                            *ngIf="
                              demoForm?.get('txtFirstName')?.touched &&
                              demoForm?.get('txtFirstName')?.errors?.required
                            "
                          >
                            required!
                          </mat-error>

                          <mat-error
                            class="spanAlignment"
                            *ngIf="
                              demoForm?.get('txtFirstName')?.touched &&
                              demoForm?.get('txtFirstName')?.errors?.pattern
                            "
                          >
                            Numbers not allowed!
                          </mat-error>
                        </mat-form-field>

                        <!-- Middle Name -->
                        <mat-form-field class="col-3">
                          <mat-label>Middle Name</mat-label>
                          <input
                            matInput
                            maxlength="40"
                            formControlName="txtMiddleName"
                            type="text"
                            pattern="^[^0-9]*$"
                            autocomplete="new-txtMiddleName"
                          />
                          <mat-error
                            class="spanAlignment"
                            *ngIf="
                              demoForm?.get('txtMiddleName')?.touched &&
                              demoForm?.get('txtMiddleName')?.errors?.pattern
                            "
                          >
                            Numbers not allowed!
                          </mat-error>
                        </mat-form-field>

                        <!-- Last Name-->
                        <mat-form-field class="col-3">
                          <mat-label>
                            Last Name <span class="asterisk">*</span></mat-label
                          >
                          <input
                            matInput
                            maxlength="40"
                            formControlName="txtLastName"
                            type="text"
                            (input)="checkExistDocument($event.target.value)"
                            autocomplete="new-txtLastName"
                            pattern="^[^0-9]*$"
                          />
                          <mat-error
                            class="spanAlignment"
                            *ngIf="
                              demoForm?.get('txtLastName')?.touched &&
                              demoForm?.get('txtLastName')?.errors?.required
                            "
                          >
                            Required!
                          </mat-error>
                          <mat-error
                            class="spanAlignment"
                            *ngIf="
                              (demoForm?.get('txtLastName')?.touched &&
                                demoForm?.get('txtLastName')?.pattern) ||
                              demoForm?.get('txtLastName')?.errors
                            "
                          >
                            Numbers not allowed!
                          </mat-error>
                        </mat-form-field>

                        <!-- Gender -->
                        <mat-form-field class="col-3">
                          <mat-label
                            >Gender<span class="asterisk">*</span></mat-label
                          >
                          <mat-select
                            formControlName="drpGender"
                            autocomplete="new-drpGender"
                            [(value)]="gender"
                            maxlength="40"
                          >
                            <mat-option
                              *ngFor="let gender of drpGenderLoop"
                              [value]="gender.genderShortCode"
                            >
                              {{ gender.genderName }}
                            </mat-option>
                          </mat-select>
                          <mat-error
                            class="spanAlignment"
                            *ngIf="
                              demoForm?.get('drpGender')?.touched &&
                              demoForm?.get('drpGender')?.errors?.required
                            "
                          >
                            Gender is a required field!
                          </mat-error>
                        </mat-form-field>
                        <!-- onkeydown="this.value=this.value.replace(/^(\d\d)(\d)$/g,'$1/$2').replace(/^(\d\d\/\d\d)(\d+)$/g,'$1/$2').replace(/[^\d\/]/g,'')" -->
                        <!-- Birth Date -->
                        <mat-form-field class="col-3">
                          <mat-label>
                            Birth Date
                            <span class="asterisk">*</span></mat-label
                          >
                          <input
                            autocomplete="new-txtBirthdate"
                            [max]="BirthdateMaxDate"
                            formControlName="txtBirthdate"
                            [(ngModel)]="birthdate"
                            matInput
                            maxlength="10"
                            type="text"
                            placeholder="(MM/DD/YYYY)"
                            type="text"
                            [matDatepicker]="txtBirthdate"
                            [min]="BirthdateMinDate"
                            (keydown)="addSlashToDate($event)"
                            (input)="checkExistDocumentV1($event.target.value)"
                            (dateChange)="checkExistDocumentV1($event.value)"
                          />

                          <mat-datepicker-toggle matSuffix [for]="txtBirthdate">
                          </mat-datepicker-toggle>
                          <mat-datepicker #txtBirthdate> </mat-datepicker>
                          <mat-error
                            *ngIf="
                              (BirthdateMinDate <=
                                demoForm?.get('txtBirthdate')?.value &&
                                !(
                                  demoForm?.get('txtBirthdate')?.touched &&
                                  demoForm?.get('txtBirthdate')?.errors
                                    ?.required
                                )) ||
                              demoForm.get('txtBirthdate').errors
                            "
                          >
                            Enter a Valid Date!
                          </mat-error>
                        </mat-form-field>

                        <!-- Marital Status-->
                        <mat-form-field class="col-3">
                          <mat-label>Marital Status</mat-label>
                          <mat-select
                            formControlName="drpMartialSts"
                            autocomplete="new-drpMartialSts"
                          >
                            <mat-option
                              *ngFor="let data of drpMartialList"
                              [value]="data.maritalStatusTypeId"
                            >
                              {{ data.maritalStatusType }}
                            </mat-option>
                          </mat-select>

                          <button mat-button *ngIf="demoForm.get('drpMartialSts').value" (click)="clearMaritialSts(); $event.stopPropagation()" matSuffix mat-icon-button>
                            <mat-icon style="font-size: 16px !important">close</mat-icon>
                          </button>

                        </mat-form-field>

                        <!-- Email-->
                        <mat-form-field class="col-6">
                          <mat-label> Email</mat-label>
                          <!-- <input (keyup)="validateEmail()" (onpaste)="validateEmail()" matInput
                            pattern="[a-z0-9._!#$%&'()*+,-./:;<=>?@[\]^_`{|}~]+@[a-z0-9.-]+\.[a-z]{2,4}$"
                            [textMask]="{ mask: emailMask }" maxlength="40" type="text" formControlName="txtEmail" /> -->
                          <input
                            (keyup)="validateEmail()"
                            (onpaste)="validateEmail()"
                            matInput
                            pattern="[a-zA-Z0-9._!#$%&'()*+,-./:;<=>?@[\]^_`{|}~]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$"
                            [textMask]="{ mask: emailMask }"
                            maxlength="40"
                            type="text"
                            formControlName="txtEmail"
                          />

                          <mat-error
                            class="spanAlignment"
                            *ngIf="
                              demoForm?.get('txtEmail')?.touched &&
                              demoForm?.get('txtEmail')?.errors?.pattern
                            "
                          >
                            Enter a valid email!
                          </mat-error>
                          <mat-error
                            *ngIf="
                              demoForm
                                ?.get('txtEmail')
                                ?.hasError('invalidEmail')
                            "
                          >
                            Mail Id Already Exists
                          </mat-error>
                        </mat-form-field>

                        <!-- RX Date -->
                        <mat-form-field class="col-3">
                          <mat-label> RX Date </mat-label>
                          <input
                            autocomplete="new-txtRXdate"
                            [max]="BirthdateMaxDate"
                            formControlName="txtRXdate"
                            matInput
                            maxlength="10"
                            type="text"
                            placeholder="(MM/DD/YYYY)"
                            [matDatepicker]="txtRxdate"
                            [min]="RxdateMin"
                            (keydown)="addSlashToDate($event)"
                          />
                          <mat-datepicker-toggle matSuffix [for]="txtRxdate">
                          </mat-datepicker-toggle>
                          <mat-datepicker #txtRxdate> </mat-datepicker>
                          <mat-error
                            *ngIf="
                              (demoForm?.get('txtRXdate')?.touched &&
                                demoForm?.get('txtRXdate')?.errors &&
                                !(
                                  RxdateMin <= demoForm?.get('txtRXdate')?.value
                                )) ||
                              (BirthdateMinDate <=
                                demoForm?.get('txtRXdate')?.value &&
                                !(
                                  demoForm?.get('txtRXdate')?.touched &&
                                  demoForm?.get('txtRXdate')?.errors?.required
                                ))
                            "
                          >
                            Enter past one year date
                          </mat-error>
                        </mat-form-field>

                        <!-- Email-->
                        <!-- <mat-form-field class="col-6">
                          <mat-label> Email</mat-label>
                          <input (keyup)="validateEmail()" (onpaste)="validateEmail()" matInput pattern="[a-z0-9._!#$%&'()*+,-./:;<=>?@[\]^_`{|}~]+@[a-z0-9.-]+\.[a-z]{2,4}$"
                            [textMask]="{ mask: emailMask }" maxlength="40" type="text" formControlName="txtEmail" />
                          <mat-error class="spanAlignment" *ngIf="
                          demoForm?.get('txtEmail')?.touched &&
                          demoForm?.get('txtEmail')?.errors?.pattern
                          ">
                            Enter a valid email!
                          </mat-error>
                          <mat-error *ngIf=" demoForm?.get('txtEmail')?.hasError('invalidEmail')">
                            Mail Id Already Exists
                          </mat-error>
                        </mat-form-field> -->

                        <!-- SSN -->
                        <mat-form-field class="col-3">
                          <mat-label> SSN </mat-label>
                          <input
                            matInput
                            mask="000-00-0000"
                            formControlName="txtSSNNo"
                            type="text"
                          />
                        </mat-form-field>
                        <!-- oninput="this.value = this.value.replace(/[^0-9]+/g, '')" -->

                        <!-- Home No-->
                        <mat-form-field class="col-3">
                          <mat-label> Home No </mat-label>
                          <input
                            matInput
                            maxlength="40"
                            formControlName="txtHomeNo"
                            type="text"
                            mask="(000)-000-0000"
                            prefix="{{ phoneCode }}-"
                          />
                        </mat-form-field>

                        <!-- Cell No-->
                        <mat-form-field class="col-3">
                          <mat-label> Cell No </mat-label>
                          <input
                            matInput
                            maxlength="40"
                            formControlName="txtCellNo"
                            type="text"
                            mask="(000)-000-0000"
                            prefix="{{ phoneCode }}-"
                          />
                        </mat-form-field>

                        <!-- Work No-->
                        <mat-form-field class="col-3">
                          <mat-label> Work No</mat-label>
                          <input
                            matInput
                            maxlength="40"
                            formControlName="txtWorkNo"
                            type="text"
                            mask="(000)-000-0000"
                            prefix="{{ phoneCode }}-"
                          />
                        </mat-form-field>

                        <!-- Emergency Contact No-->
                        <mat-form-field class="col-3">
                          <mat-label> Emergency Contact No</mat-label>
                          <input
                            matInput
                            maxlength="40"
                            formControlName="txtEmergencyNo"
                            type="text"
                            mask="(000)-000-0000"
                            prefix="{{ phoneCode }}-"
                          />
                        </mat-form-field>

                        <!-- Contact Info -->
                        <div class="col-12" style="padding-top: 1%">
                          <mat-label
                            ><b class="customThemeClass"
                              >Billing Info</b
                            ></mat-label
                          >
                        </div>

                        <!-- Address -->
                        <mat-form-field class="col-6">
                          <mat-label> Address </mat-label>
                          <input
                            matInput
                            maxlength="200"
                            formControlName="txtAddress"
                            type="text"
                          />

                          <mat-error
                            class="spanAlignment"
                            *ngIf="
                              demoForm?.get('txtAddress')?.touched &&
                              demoForm?.get('txtAddress')?.errors?.required
                            "
                          >
                            Address is a required field!
                          </mat-error>
                        </mat-form-field>

                        <!-- Country-->
                        <mat-form-field class="col-6">
                          <mat-label>Country</mat-label>
                          <mat-select
                            formControlName="txtCountry"
                            [(value)]="country"
                            (selectionChange)="onChangeState($event.value)"
                          >
                            <mat-option>
                              <ngx-mat-select-search
                                placeholderLabel="Search"
                                noEntriesFoundLabel="No Matches found"
                                formControlName="txtCountryFilter"
                              ></ngx-mat-select-search>
                            </mat-option>
                            <mat-option
                              *ngFor="let country of filteredCountries | async"
                              [value]="country.countryShortName"
                            >
                              {{ country.countryName }}
                            </mat-option>
                          </mat-select>
                          <button
                            mat-button
                            (click)="country = ''; $event.stopPropagation()"
                            *ngIf="demoForm.get('txtCountry').value"
                            (click)="clearCountry()"
                            matSuffix
                            mat-icon-button
                          >
                            <mat-icon>close</mat-icon>
                          </button>
                        </mat-form-field>
                        <!-- State-->
                        <mat-form-field class="col-4">
                          <mat-label>State</mat-label>
                          <mat-select
                            formControlName="txtState"
                            (selectionChange)="
                              onChangeCity($event.value, country)
                            "
                            [(value)]="State"
                          >
                            <mat-option>
                              <ngx-mat-select-search
                                placeholderLabel="Search"
                                noEntriesFoundLabel="No Matches found"
                                formControlName="txtStateFilter"
                              ></ngx-mat-select-search>
                            </mat-option>
                            <mat-option
                              *ngFor="let state of filteredStates | async"
                              [value]="state.stateShortName"
                            >
                              {{ state.stateName }}
                            </mat-option>
                          </mat-select>
                          <button
                            mat-button
                            (click)="State = ''; $event.stopPropagation()"
                            *ngIf="demoForm.get('txtState').value"
                            (click)="clearState()"
                            matSuffix
                            mat-icon-button
                          >
                            <mat-icon>close</mat-icon>
                          </button>
                        </mat-form-field>

                        <!-- City-->
                        <mat-form-field class="col-4">
                          <mat-label>City</mat-label>
                          <mat-select
                            formControlName="txtCity"
                            [(value)]="City"
                            #citySelect
                          >

                            <mat-option>
                              <ngx-mat-select-search
                                placeholderLabel="Search"
                                (keypress)="onCityKeyPress($event)"
                                [noEntriesFoundLabel]="cityDrpDownMessage()"
                                formControlName="txtCityFilter"
                                hideClearSearchButton="false"
                                (focusout)="focusOut()"
                              >
                              </ngx-mat-select-search>
                            </mat-option>
                            <mat-option
                              *ngFor="let city of filteredCities | async"
                              [value]="city.cityName"
                            >
                              {{ city.cityName }}
                            </mat-option>
                          </mat-select>

                          <button
                            mat-button
                            (click)="City = ''; $event.stopPropagation()"
                            *ngIf="demoForm.get('txtCity').value"
                            matSuffix
                            (click)="clearCity()"
                            mat-icon-button
                          >
                            <mat-icon>close</mat-icon>
                          </button>
                        </mat-form-field>

                        <!-- Zip Code -->
                        <mat-form-field class="col-4">
                          <mat-label> Zip Code </mat-label>
                          <input
                            autocomplete="new-txtPostalCode"
                            matInput
                            maxlength="10"
                            formControlName="txtPostalCode"
                            pattern="[0-9-]*"
                            type="text"
                            class="zip-code-input"
                          />
                          <mat-error
                            *ngIf="
                              demoForm?.get('txtPostalCode')?.touched &&
                              demoForm?.get('txtPostalCode')?.errors?.pattern
                            "
                          >
                            Enter only Numbers!
                          </mat-error>
                          <mat-error
                            *ngIf="
                              demoForm?.get('txtPostalCode')?.touched &&
                              demoForm?.get('txtPostalCode')?.errors?.required
                            "
                          >
                            <!-- ZipCode is a required field! -->
                          </mat-error>
                        </mat-form-field>

                        <!--Comments-->
                        <!-- <mat-form-field class="col-12">
                          <mat-label>Notes</mat-label>
                          <textarea matInput rows="4" autocomplete="new-txtComments" maxlength="5000"
                            formControlName="txtComments" [(ngModel)]="notesText" (input)="updateCharacterCount(5000)"
                            type="text"></textarea>
                        </mat-form-field>
                        <div class="char-count">
                          {{ notesText?.length }} / 5000
                        </div> -->
                      </div>
                    </div>

                    <!-- Referral Details -->
                    <div class="card mb-0 mt-1 card-body">
                      <div class="row">
                        <div class="col-12 d-flex justify-content-between">
                          <mat-label
                            ><b class="customThemeClass"
                              >Referral Tracking</b
                            ></mat-label
                          >
                          <button
                            type="button"
                            mat-button
                            (click)="openDoctorModal()"
                            class="buttonColor"
                          >
                            <mat-icon>search</mat-icon> Search Physician
                          </button>
                        </div>
                        <!-- Referral -->
                        <!-- <mat-form-field class="col-6">
                          <mat-label>Referral</mat-label>
                          <mat-select formControlName="drpReferral" #singleSelect>
                            <mat-option>
                              <ngx-mat-select-search placeholderLabel="Search" noEntriesFoundLabel="No Matches found"
                                formControlName="txtReferralFilter"></ngx-mat-select-search>
                            </mat-option>

                            <mat-option *ngFor="
                              let doctor of filteredReferralDoctors | async
                            " [value]="doctor.doctorId">
                              {{ doctor.name }}
                            </mat-option>
                          </mat-select>
                        </mat-form-field> -->
                        <mat-form-field class="col-6">
                          <mat-label>Referral</mat-label>
                          <mat-select formControlName="drpReferral">
                            <mat-option>
                              <ngx-mat-select-search
                                placeholderLabel="Search"
                                noEntriesFoundLabel="No Matches found"
                                hideClearSearchButton="true"
                                (keyup)="referralSearchV1($event.target.value)"
                                formControlName="txtReferralFilter"
                              ></ngx-mat-select-search>
                            </mat-option>
                            <mat-option
                              *ngFor="
                                let doctor of filteredReferralDoctors | async
                              "
                              [value]="doctor.id"
                              (click)="reloadPhysican('', false)"
                            >
                              {{ doctor.practiceName }}
                            </mat-option>
                          </mat-select>
                          <button
                            mat-button
                            matSuffix
                            (click)="clearReferralDoctorsList($event)"
                            mat-icon-button
                          >
                            <mat-icon>close</mat-icon>
                          </button>
                        </mat-form-field>

                        <!-- Physician -->
                        <mat-form-field class="col-6">
                          <mat-label>
                            <div
                              class="select-placeholder-container"
                              *ngIf="iPhysicianLoading"
                            >
                              <span>Loading...</span>
                              <!--  <mat-icon  *ngIf='iPhysicianLoading'>
                            <mat-spinner class="drpdwnspinner" matSuffix diameter="20"></mat-spinner>
                          </mat-icon>-->
                            </div>
                            <span *ngIf="!iPhysicianLoading">Physician</span>
                          </mat-label>
                          <mat-icon matSuffix *ngIf="iPhysicianLoading">
                            <mat-spinner
                              class="drpdwnspinner"
                              diameter="20"
                            ></mat-spinner>
                          </mat-icon>
                          <mat-select formControlName="drpPhysician">
                            <mat-option>
                              <ngx-mat-select-search
                                placeholderLabel="Search"
                                noEntriesFoundLabel="No Matches found"
                                hideClearSearchButton="true"
                                (keyup)="reloadPhysican($event, false)"
                                formControlName="txtPhysicianFilter"
                              ></ngx-mat-select-search>
                            </mat-option>

                            <mat-option
                              *ngFor="let doctor of filteredPhysicians | async"
                              [value]="doctor.id + '/' + doctor.doctorName"
                            >
                              {{ doctor.doctorName }}
                            </mat-option>
                          </mat-select>
                          <button
                            mat-button
                            matSuffix
                            *ngIf="!iPhysicianLoading"
                            (click)="clearPhysicianDoctorsList($event)"
                            mat-icon-button
                          >
                            <mat-icon>close</mat-icon>
                          </button>
                          <!-- <mat-icon matSuffix *ngIf='iPhysicianLoading'>
                            <mat-spinner  class="spinner-border spinner-border-sm" diameter="20"></mat-spinner>
                          </mat-icon> -->
                        </mat-form-field>
                      </div>
                    </div>
                    <!--  Service type -->
                    <div class="card mb-0 mt-1 card-body">
                      <div class="row">
                        <div class="col-12 d-flex justify-content-between">
                          <mat-label
                            ><b class="customThemeClass"
                              >Service Type</b
                            ></mat-label
                          >
                        </div>
                        <!-- Service Type -->
                        <!-- <mat-form-field class="col-6">
                          <mat-label>Service Type</mat-label>
                          <mat-select formControlName="drpServiceType">
                            <mat-option>
                              <ngx-mat-select-search
                                placeholderLabel="Search"
                                noEntriesFoundLabel="No Matches found"
                                [formControl]="txtSeriveTypeFilterControl"
                              ></ngx-mat-select-search>
                            </mat-option>

                            <mat-option
                              *ngFor="let service of filteredServiceType"
                              [value]="service.productCategortyId"
                            >
                              {{ service.categoryName }}
                            </mat-option>
                          </mat-select>
                          <button
                            mat-button
                            matSuffix
                            (click)="clearServiceType($event)"
                            mat-icon-button
                          >
                            <mat-icon>close</mat-icon>
                          </button>
                        </mat-form-field> -->

                        <mat-form-field class="col-6">
                          <mat-label>Service Type</mat-label>
                          <mat-select formControlName="drpServiceType">
                            <mat-option>
                              <ngx-mat-select-search
                                placeholderLabel="Search"
                                noEntriesFoundLabel="No Matches found"
                                formControlName="txtCategoryFilter"
                              ></ngx-mat-select-search>
                            </mat-option>

                            <mat-option *ngFor="let service of filteredCategoryDetails | async" [value]="service.id">
                              {{ service.productCategoryName }}
                            </mat-option>
                          </mat-select>
                          <button
                            mat-button
                            matSuffix
                            (click)="clearServiceType($event)"
                            mat-icon-button
                          >
                            <mat-icon>close</mat-icon>
                          </button>
                        </mat-form-field>
                      </div>
                    </div>
                  </div>
                </form>
                <form [formGroup]="planForm">
                  <!-- Plans -->
                  <div *ngIf="activeTab === 'plans'">
                    <!-- Primary -->
                    <mat-accordion #PrimaryAccordion="matAccordion">
                      <mat-expansion-panel [(expanded)]="PrimarypanelOpenState">
                        <mat-expansion-panel-header>
                          <mat-panel-title class="customThemeClass">
                            <ng-container>
                              <b>Primary Insurance</b>
                            </ng-container>
                          </mat-panel-title>
                        </mat-expansion-panel-header>
                        <mat-card-content>
                          <div class="row">
                            <!-- Payor -->
                            <mat-form-field class="col-lg-12" id="primaryPlan">
                              <!-- <mat-label>Select Payor<span *ngIf="planForm.get('chkCashPayment')?.value!==true"
                                  class="asterisk">*</span></mat-label> -->
                              <mat-select
                                [disabled]="cashCash"
                                formControlName="drpPrimaryPayor"
                                [(value)]="primaryPayorId"
                                (selectionChange)="onSelectPrimaryPayor($event)"
                              >
                                <mat-option>
                                  <ngx-mat-select-search
                                    ngDefaultControl
                                    placeholderLabel="Payer Id / Name / Code"
                                    hideClearSearchButton="true"
                                    [noEntriesFoundLabel]="drpDownMessage()"
                                    formControlName="primaryPayor"
                                    (keyup)="
                                      payerSearch(
                                        $event.target.value,
                                        $event.key
                                      )
                                    "
                                  >
                                  </ngx-mat-select-search>
                                </mat-option>
                                <mat-option
                                  *ngFor="
                                    let payor of filteredPrimaryPayors | async
                                  "
                                  [value]="payor?.payerId"
                                  [title]="payor?.payerShortCodeList"
                                >
                                  {{ payor?.payerShortCodeList }}
                                </mat-option>
                              </mat-select>
                              <mat-label>
                                <!-- <div class="select-placeholder-container" *ngIf="isdrpLoading">
                                  <span>Loading...</span>
                                  <mat-spinner class="spinner" diameter="20"></mat-spinner>
                                </div> -->
                                <span
                                  >Select Payor
                                  <span
                                    *ngIf="
                                      planForm.get('chkCashPayment')?.value !==
                                      true
                                    "
                                    class="asterisk"
                                    >*</span
                                  >
                                </span>
                              </mat-label>
                              <mat-error
                                *ngIf="
                                  planForm?.get('drpselectPayor')?.touched &&
                                  planForm?.get('drpselectPayor')?.errors
                                    ?.required
                                "
                              >
                                Payor Id is a required field!
                              </mat-error>
                              <mat-hint
                                class="text-danger fw-bolder"
                                *ngIf="!isUniq"
                              >
                                Insurance Category Not Available!</mat-hint
                              >
                              <button
                                type="button"
                                mat-button
                                (click)="clearPrimaryPolicy($event)"
                                *ngIf="
                                  planForm.get('chkCashPayment')?.value !== true
                                "
                                matSuffix
                                mat-icon-button
                              >
                                <mat-icon>close</mat-icon>
                              </button>
                            </mat-form-field>
                            <!-- Policy No -->
                            <mat-form-field class="col-sm-6">
                              <mat-label
                                >Policy No<span
                                  *ngIf="
                                    planForm.get('chkCashPayment')?.value !==
                                    true
                                  "
                                  class="asterisk"
                                  >*</span
                                ></mat-label
                              >
                              <input
                                maxlength="20"
                                formControlName="txtPrimaryPolicyNo"
                                matInput
                                type="text"
                                pattern="[a-zA-Z0-9]*"
                                [readonly]="cashCash"
                              />
                              <mat-error
                                *ngIf="
                                  planForm?.get('txtPrimaryPolicyNo')
                                    ?.touched &&
                                  planForm?.get('txtPrimaryPolicyNo')?.errors
                                    ?.pattern
                                "
                              >
                                Special Characters Not allowed!
                              </mat-error>
                              <mat-error
                                *ngIf="
                                  planForm?.get('txtPrimaryPolicyNo')
                                    ?.touched &&
                                  planForm?.get('txtPrimaryPolicyNo')?.errors
                                    ?.required
                                "
                              >
                                Policy No is a required field!
                              </mat-error>
                            </mat-form-field>
                            <!-- Group No -->
                            <mat-form-field class="col-sm-6">
                              <mat-label>Group No</mat-label>
                              <input
                                maxlength="20"
                                formControlName="txtPrimaryGroupNo"
                                matInput
                                type="text"
                                [readonly]="cashCash"
                              />
                              <!-- pattern="[a-zA-Z0-9]*" <mat-error *ngIf="
                              planForm?.get('txtPrimaryGroupNo')
                                  ?.touched &&
                                  planForm?.get('txtPrimaryGroupNo')?.errors
                                  ?.required
                              ">
                                Group No is a required field!
                              </mat-error> -->
                              <mat-error
                                *ngIf="
                                  planForm?.get('txtPrimaryGroupNo')?.touched &&
                                  planForm?.get('txtPrimaryGroupNo')?.errors
                                    ?.pattern &&
                                  !planForm?.get('txtPrimaryGroupNo')?.errors
                                    ?.required
                                "
                              >
                                Special Characters Not allowed!
                              </mat-error>
                            </mat-form-field>
                          </div>
                        </mat-card-content>
                      </mat-expansion-panel>
                    </mat-accordion>

                    <!-- Secondary -->
                    <mat-accordion #SecondaryAccordion="matAccordion">
                      <mat-expansion-panel
                        class="mt-1"
                        [(expanded)]="SecpanelOpenState"
                      >
                        <mat-expansion-panel-header>
                          <mat-panel-title class="customThemeClass">
                            <ng-container>
                              <b>Secondary Insurance</b>
                            </ng-container>
                          </mat-panel-title>
                        </mat-expansion-panel-header>
                        <mat-card-content>
                          <div class="row">
                            <!-- Payor -->
                            <mat-form-field class="col-lg-12" id="secPlan">
                              <!-- <mat-label>Select Payor<span *ngIf="secondaryValidator"
                                  class="asterisk">*</span></mat-label> -->
                              <mat-select
                                (selectionChange)="onSecondaryPolicyChange()"
                                [disabled]="cashCash"
                                formControlName="drpSecondaryPayor"
                                [(value)]="secondaryPayorId"
                              >
                                <mat-option>
                                  <ngx-mat-select-search
                                    placeholderLabel="Payer Id / Name / Code"
                                    hideClearSearchButton="true"
                                    [noEntriesFoundLabel]="drpDownMessageV1()"
                                    formControlName="secondaryPayor"
                                    (keyup)="
                                      payerSearchV1(
                                        $event.target.value,
                                        $event.key
                                      )
                                    "
                                  ></ngx-mat-select-search>
                                </mat-option>
                                <mat-option
                                  *ngFor="
                                    let payor of filteredSecondaryPayors | async
                                  "
                                  [value]="payor?.payerId"
                                  [title]="payor?.payerShortCodeList"
                                >
                                  {{ payor?.payerShortCodeList }}
                                </mat-option>
                              </mat-select>
                              <mat-label>
                                <!-- <div class="select-placeholder-container" *ngIf="isSecdrpLoading">
                                  <span>Loading...</span>
                                  <mat-spinner class="spinner" diameter="20"></mat-spinner>
                                </div> -->
                                <span
                                  >Select Payor
                                  <span
                                    *ngIf="
                                      planForm.get('chkCashPayment')?.value !==
                                        true && secondaryValidator
                                    "
                                    class="asterisk"
                                    >*</span
                                  >
                                </span>
                              </mat-label>
                              <mat-error
                                *ngIf="
                                  planForm?.get('drpSecondaryPayor')?.touched &&
                                  planForm?.get('drpSecondaryPayor')?.errors
                                    ?.required
                                "
                              >
                                Payor Id is a required field!
                              </mat-error>
                              <button
                                mat-button
                                type="button"
                                (click)="clearSecondaryPolicy($event)"
                                *ngIf="
                                  planForm.get('chkCashPayment')?.value !== true
                                "
                                matSuffix
                                mat-icon-button
                              >
                                <mat-icon>close</mat-icon>
                              </button>
                              <mat-hint
                                class="text-danger fw-bolder"
                                *ngIf="!isUniqV1"
                              >
                                Insurance Category Not Available!</mat-hint
                              >
                            </mat-form-field>
                            <!-- Policy No -->
                            <mat-form-field class="col-sm-6">
                              <mat-label
                                >Policy No<span
                                  *ngIf="secondaryValidator"
                                  class="asterisk"
                                  >*</span
                                ></mat-label
                              >
                              <input
                                maxlength="20"
                                matInput
                                type="text"
                                [readonly]="cashCash"
                                formControlName="txtSecondaryPolicyNo"
                                pattern="[a-zA-Z0-9]*"
                              />
                              <mat-error
                                *ngIf="
                                  planForm?.get('txtSecondaryPolicyNo')
                                    ?.touched &&
                                  planForm?.get('txtSecondaryPolicyNo')?.errors
                                    ?.pattern
                                "
                              >
                                Special Characters not allowed!
                              </mat-error>
                              <mat-error
                                *ngIf="
                                  planForm?.get('txtSecondaryPolicyNo')
                                    ?.touched &&
                                  planForm?.get('txtSecondaryPolicyNo')?.errors
                                    ?.required
                                "
                              >
                                Policy No is a required field!
                              </mat-error>
                            </mat-form-field>
                            <!-- Group No -->
                            <mat-form-field class="col-sm-6">
                              <mat-label>Group No</mat-label>
                              <input
                                maxlength="20"
                                matInput
                                type="text"
                                [readonly]="cashCash"
                                formControlName="txtSecondaryGroupNo"
                              />
                              <mat-error
                                *ngIf="
                                  planForm?.get('txtSecondaryGroupNo')
                                    ?.touched &&
                                  planForm?.get('txtSecondaryGroupNo')?.errors
                                    ?.pattern
                                "
                              >
                                Special Characters not allowed!
                              </mat-error>
                              <!-- <mat-error *ngIf="
                              planForm?.get('txtSecondaryGroupNo')
                                  ?.touched &&
                                  planForm?.get('txtSecondaryGroupNo')?.errors
                                  ?.required
                              ">
                                Group No is a required field!
                              </mat-error> -->
                            </mat-form-field>
                          </div>
                        </mat-card-content>
                      </mat-expansion-panel>
                    </mat-accordion>

                    <!-- Tertiary -->
                    <mat-accordion #TertiaryAccordion="matAccordion">
                      <mat-expansion-panel
                        class="mt-1"
                        [(expanded)]="TerpanelOpenState"
                      >
                        <mat-expansion-panel-header>
                          <mat-panel-title class="customThemeClass">
                            <ng-container>
                              <b>Tertiary Insurance</b>
                            </ng-container>
                          </mat-panel-title>
                        </mat-expansion-panel-header>
                        <mat-card-content>
                          <div class="row">
                            <!-- Payor -->
                            <mat-form-field class="col-lg-12" id="terPlan">
                              <!-- <mat-label>Select Payor<span *ngIf="tertiaryValidator"
                                  class="asterisk">*</span></mat-label> -->
                              <mat-select
                                (selectionChange)="onTertiaryPolicyChange()"
                                [disabled]="cashCash"
                                formControlName="drpTertiaryPayor"
                                [(value)]="tertiaryPayorId"
                              >
                                <mat-option>
                                  <ngx-mat-select-search
                                    placeholderLabel="Payer Id / Name / Code"
                                    hideClearSearchButton="true"
                                    [noEntriesFoundLabel]="drpDownMessageV2()"
                                    formControlName="tertiaryPayor"
                                    (keyup)="
                                      payerSearchV2(
                                        $event.target.value,
                                        $event.key
                                      )
                                    "
                                  ></ngx-mat-select-search>
                                </mat-option>
                                <mat-option
                                  *ngFor="
                                    let payor of filteredTertiaryPayors | async
                                  "
                                  [value]="payor?.payerId"
                                  [title]="payor?.payerShortCodeList"
                                  [title]="payor?.payerShortCodeList"
                                >
                                  {{ payor?.payerShortCodeList }}
                                </mat-option>
                              </mat-select>
                              <mat-label>
                                <!-- <div class="select-placeholder-container" *ngIf="isTerdrpLoading">
                                  <span>Loading...</span>
                                  <mat-spinner class="spinner" diameter="20"></mat-spinner>
                                </div> -->
                                <span
                                  >Select Payor
                                  <span
                                    *ngIf="
                                      planForm.get('chkCashPayment')?.value !==
                                        true && tertiaryValidator
                                    "
                                    class="asterisk"
                                    >*</span
                                  >
                                </span>
                              </mat-label>
                              <mat-error
                                *ngIf="
                                  planForm?.get('drpTertiaryPayor')?.touched &&
                                  planForm?.get('drpTertiaryPayor')?.errors
                                    ?.required
                                "
                              >
                                Payor Id is a required field!
                              </mat-error>
                              <button
                                mat-button
                                type="button"
                                (click)="clearTertiaryPolicy($event)"
                                *ngIf="
                                  planForm.get('chkCashPayment')?.value !== true
                                "
                                matSuffix
                                mat-icon-button
                              >
                                <mat-icon>close</mat-icon>
                              </button>
                              <mat-hint
                                class="text-danger fw-bolder"
                                *ngIf="!isUniqV2"
                              >
                                Insurance Category Not Available!</mat-hint
                              >
                            </mat-form-field>
                            <!-- Policy No -->
                            <mat-form-field class="col-sm-6">
                              <mat-label
                                >Policy No<span
                                  *ngIf="tertiaryValidator"
                                  class="asterisk"
                                  >*</span
                                ></mat-label
                              >
                              <input
                                maxlength="20"
                                matInput
                                [readonly]="cashCash"
                                formControlName="txtTertiaryPolicyNo"
                                type="text"
                                pattern="[a-zA-Z0-9]*"
                              />
                              <mat-error
                                *ngIf="
                                  planForm?.get('txtTertiaryPolicyNo')
                                    ?.touched &&
                                  planForm?.get('txtTertiaryPolicyNo')?.errors
                                    ?.pattern
                                "
                              >
                                Special Characters not allowed!
                              </mat-error>
                              <mat-error
                                *ngIf="
                                  planForm?.get('txtTertiaryPolicyNo')
                                    ?.touched &&
                                  planForm?.get('txtTertiaryPolicyNo')?.errors
                                    ?.required
                                "
                              >
                                Policy No is a required field!
                              </mat-error>
                            </mat-form-field>
                            <!-- Group No -->
                            <mat-form-field class="col-sm-6">
                              <mat-label>Group No</mat-label>
                              <input
                                maxlength="20"
                                matInput
                                [readonly]="cashCash"
                                formControlName="txtTertiaryGroupNo"
                                type="text"
                              />
                              <mat-error
                                *ngIf="
                                  planForm?.get('txtTertiaryGroupNo')
                                    ?.touched &&
                                  planForm?.get('txtTertiaryGroupNo')?.errors
                                    ?.pattern
                                "
                              >
                                Special Characters not allowed!
                              </mat-error>
                              <!-- <mat-error *ngIf="
                              planForm?.get('txtTertiaryGroupNo')
                                  ?.touched &&
                                  planForm?.get('txtTertiaryGroupNo')?.errors
                                  ?.required
                              ">
                                Group No is a required field!
                              </mat-error> -->
                            </mat-form-field>
                          </div>
                        </mat-card-content>
                      </mat-expansion-panel>
                    </mat-accordion>
                    <!-- Cash Info-->
                    <div class="card mt-2">
                      <div class="row d-flex align-items-center">
                        <div class="col-3">
                          <h4 class="mb-1 py-1 customThemeClass ml-4">
                            <b>Cash Info</b>
                          </h4>
                        </div>
                        <div class="col-2">
                          <mat-checkbox
                            formControlName="chkCashPayment"
                            class="mt-1 py-1"
                            (change)="changeCashCase(true)"
                            >Cash Case</mat-checkbox
                          >
                        </div>
                      </div>
                    </div>

                    <!-- Other Info -->
                    <div class="card mt-2">
                      <div class="row p-3">
                        <div class="col-12">
                          <h4 class="mb-0 ml-2 customThemeClass">
                            <b>Other Info</b>
                          </h4>
                        </div>
                        <!-- Provider -->
                        <mat-form-field class="col-lg-4 mt-1">
                          <mat-label
                            >Provider
                            <span
                              *ngIf="
                                planForm.get('chkCashPayment')?.value !== true
                              "
                              class="asterisk"
                              >*</span
                            ></mat-label
                          >
                          <mat-select
                            maxlength="40"
                            [(value)]="provider"
                            autocomplete="off"
                            formControlName="txtProvider"
                          >
                            <mat-option>
                              <ngx-mat-select-search
                                [formControl]="ProviderControl"
                                placeholderLabel="Search"
                                noEntriesFoundLabel="No Matches found"
                                formControlName="txtProviderSearch"
                              ></ngx-mat-select-search>
                            </mat-option>
                            <mat-option
                              *ngFor="let providers of drpProviders"
                              [value]="providers.id"
                            >
                              {{ providers.name }}
                            </mat-option>
                          </mat-select>
                          <button
                            mat-button
                            matSuffix
                            mat-icon-button
                            (click)="clearProvider()"
                            *ngIf="provider"
                          >
                            <mat-icon>close</mat-icon>
                          </button>
                          <!-- <button mat-button matSuffix mat-icon-button (click)="clearProvider()" (click)="
                            provider = undefined; $event.stopPropagation()
                          " *ngIf="provider">
                            <mat-icon>close</mat-icon>
                          </button> -->
                          <mat-error
                            class="spanAlignment"
                            *ngIf="
                              planForm?.get('txtProvider')?.touched &&
                              planForm?.get('txtProvider')?.errors?.required
                            "
                          >
                            Provider is a required field!
                          </mat-error>
                        </mat-form-field>

                        <!-- Facility -->
                        <mat-form-field class="col-lg-4 mt-1">
                          <mat-label>Select Facility</mat-label>
                          <mat-select
                            formControlName="drpFacility"
                            (selectionChange)="OnFacilityChange($event)"
                            [(value)]="facilityId"
                          >
                            <mat-option>
                              <ngx-mat-select-search
                                placeholderLabel="Search"
                                noEntriesFoundLabel="No Matches found"
                                formControlName="selectFacility"
                              ></ngx-mat-select-search>
                            </mat-option>
                            <mat-option
                              *ngFor="let facility of filteredFacility | async"
                              [value]="facility?.id"
                            >
                              {{ facility?.name }}
                            </mat-option>
                          </mat-select>
                          <mat-error
                            *ngIf="
                              planForm?.get('drpFacility')?.touched &&
                              planForm?.get('drpFacility')?.errors?.required
                            "
                          >
                            Facility is a required field!
                          </mat-error>
                          <button
                            mat-button
                            matSuffix
                            mat-icon-button
                            (click)="facilityId = ''; $event.stopPropagation()"
                            *ngIf="facilityId"
                          >
                            <mat-icon>close</mat-icon>
                          </button>
                        </mat-form-field>

                        <!-- Location -->
                        <mat-form-field class="col-lg-4 mt-1">
                          <mat-label>Select Location</mat-label>
                          <mat-select
                            formControlName="drpBranch"
                            [(value)]="organizationUnitName"
                          >
                            <mat-option>
                              <ngx-mat-select-search
                                placeholderLabel="Search"
                                noEntriesFoundLabel="No Matches found"
                                formControlName="selectBranch"
                              ></ngx-mat-select-search>
                            </mat-option>

                            <mat-option
                              *ngFor="let branch of branchList | async"
                              [value]="branch.id"
                            >
                              {{ branch.organizationUnitName }}
                            </mat-option>
                          </mat-select>
                          <button mat-button *ngIf="planForm.get('drpBranch').value" (click)="clearLocation(); $event.stopPropagation()" matSuffix mat-icon-button>
                            <mat-icon style="font-size: 16px !important">close</mat-icon>
                          </button>
                        </mat-form-field>
                      </div>
                    </div>

                    <!-- Document Info -->
                    <!-- <div class="card mt-2">
                      <div class="row d-flex align-items-center">
                        <div class="col-lg-4">
                          <h4 class="customThemeClass py-2 mb-0 ml-4">
                            <b>Document Info</b>
                          </h4>
                        </div>
                        <div class="col-lg-4 py-2">
                          <button (click)="openCheckListModel()" type="button" mat-button class="buttonColor">
                            Document Checklist
                          </button>
                        </div>
                      </div>
                    </div> -->
                  </div>
                </form>
                <!-- <form [formGroup]="newSetupForm"> -->
                <!-- Document Details -->
                <div *ngIf="activeTab === 'document'">
                  <div class="card card-body p-0">
                    <div class="col-12 px-0">
                      <div class="card py-3 card-body">
                        <div class="d-flex justify-content-between">
                          <b
                            (click)="loadTotalDocument()"
                            class="pl-2 ml-2 header-text-v1 cursor-pointer"
                            [ngClass]="{
                              'selected-option':
                                selectedOption === sotingSelectedCSS.Total
                            }"
                          >
                            NO OF PAGES :
                            <ng-container
                              *ngIf="
                                overAllPages === overAllPagesBG;
                                else TshowAllPages
                              "
                            >
                              {{ overAllPages || docOverallPages }}&nbsp;
                            </ng-container>
                            <ng-template #TshowAllPages>
                              <span
                                class="header-text-v1 cursor-pointer"
                                [ngClass]="{
                                  'blink-text': true,
                                  'selected-option':
                                    selectedOption === sotingSelectedCSS.Total
                                }"
                              >
                                {{ overAllPages || docOverallPages }} </span
                              >/{{ overAllPagesBG || docOverallPages }}&nbsp;
                            </ng-template>
                          </b>

                          <b
                            (click)="
                              PendingDocumentImageList?.length &&
                                loadPendingDocument()
                            "
                            class="pl-2 ml-2 header-text-v1"
                            [ngClass]="{
                              'selected-option':
                                selectedOption === sotingSelectedCSS.Pending
                            }"
                            [ngClass]="{
                              'cursor-pointer':
                                PendingDocumentImageList?.length > 0
                            }"
                          >
                            UNSORTED :
                            {{ PendingDocumentImageList?.length || 0 }}&nbsp;
                          </b>

                          <b
                            (click)="
                              SortedDocumentImageList?.length &&
                                loadSortedDocument()
                            "
                            class="pl-2 ml-2 header-text-v1 cursor-pointer"
                            [ngClass]="{
                              'selected-option':
                                selectedOption === sotingSelectedCSS.Sorted
                            }"
                            [ngClass]="{
                              'cursor-pointer':
                                SortedDocumentImageList?.length > 0
                            }"
                          >
                            SORTED :
                            {{ SortedDocumentImageList?.length || 0 }}&nbsp;
                          </b>
                        </div>
                      </div>
                    </div>

                    <div></div>

                    <div class="card card-body p-0">
                      <div class="col-12 px-0">
                        <div class="card py-2 card-body">
                          <form [formGroup]="mstDocumentBinForm">
                            <div
                              class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 d-flex pl-0 align-items-center"
                            >
                              <div
                                class="col-7 col-sm-7 col-md-7 col-lg-7 col-xl-7 d-flex pl-0 align-items-center"
                              >
                                <b
                                  class="pl-2 ml-2 header-text-v1 cursor-pointer upperText"
                                  matTooltip="Add Document Bin"
                                >
                                  Add Document Bin
                                </b>
                              </div>
                              <div
                                class="col-3 col-sm-3 col-md-3 col-lg-3 col-xl-3 pl-0"
                              >
                                <div class="running-placeholder-container">
                                  <input
                                    formControlName="txtBinName"
                                    class="textbox-border-class"
                                    maxlength="50"
                                    placeholder="Enter Document Bin Name"
                                    type="text"
                                    title="Enter Document Bin Name"
                                    oninput="this.value = this.value.replace(/[^a-zA-Z0-9-\s]+/g, ''); this.value = this.value.charAt(0).toUpperCase() + this.value.slice(1);"
                                  />
                                  <mat-error
                                    *ngIf="
                                      mstDocumentBinForm?.get('txtBinName')
                                        ?.errors?.isDocumentBinExists
                                    "
                                    class="text-font-size"
                                  >
                                    Bin Name is already taken!
                                  </mat-error>
                                </div>
                              </div>
                              <div
                                class="col-2 col-sm-2 col-md-2 col-lg-2 col-xl-2 ml-1 pl-0"
                              >
                                <button
                                  [disabled]="
                                    mstDocumentBinForm.invalid ||
                                    DocumentBinSaveBtn
                                  "
                                  (click)="saveDocumentBin()"
                                  mat-icon-button
                                  class="mr-2 buttonColor ml-4 d-flex align-items-center justify-content-center"
                                  color="lightseagreen"
                                >
                                  <mat-icon style="font-size: 20px"
                                    >add</mat-icon
                                  >
                                </button>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                    <!--   -->
                    <div class="card card-body p-0" [hidden]="true">
                      <div class="col-12 px-0">
                        <div class="card py-2 card-body">
                          <div
                            class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 d-flex align-items-center"
                          >
                            <div
                              class="col-7 col-sm-7 col-md-7 col-lg-7 col-xl-7 d-flex pl-0 align-items-center header-text-v1 cursor-pointer"
                              (click)="loadTotalDocument()"
                              [ngClass]="{
                                'selected-option':
                                  selectedOption === sotingSelectedCSS.Total
                              }"
                            >
                              ALL

                              <!-- ({{ overAllPages }}) -->
                            </div>
                            <div
                              class="col-3 col-sm-3 col-md-3 col-lg-3 col-xl-3 pl-0"
                            >
                              <div class="running-placeholder-container">
                                <input
                                  #myInputRefs
                                  class="textbox-border-class"
                                  maxlength="20"
                                  (keypress)="numberOnly($event)"
                                  placeholder="1 (or) 1-5 (or) 1,3,4 (or) leave it empty"
                                  type="text"
                                  title="Enter a single number or a range (e.g., 1, 1-5) or leave it empty"
                                />
                              </div>
                            </div>
                            <div
                              class="col-2 col-sm-2 col-md-2 col-lg-2 col-xl-2 pl-1"
                            >
                              <button
                                mat-icon-button
                                [matTooltip]="
                                  TiffError
                                    ? 'TIFF Conversion in Progress. Click to Refresh Button.'
                                    : 'Revert / Un-Sort Page'
                                "
                                class="mr-2 buttonColor ml-4 d-flex align-items-center justify-content-center"
                                color="lightseagreen"
                                (click)="revertAllV1(myInputRefs.value)"
                                [disabled]="
                                  selectedOption === sotingSelectedCSS.Total ||
                                  isDisabled ||
                                  TiffError ||
                                  overAllPagesBG === 0 ||
                                  overAllPages != overAllPagesBG ||
                                  overAllPages !==
                                    SortedDocumentImageList.length +
                                      PendingDocumentImageList.length ||
                                  selectedOption === sotingSelectedCSS.Pending
                                "
                              >
                                <mat-icon style="font-size: 20px"
                                  >arrow_back</mat-icon
                                >
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <ng-container *ngIf="!isLoadSortingView">
                      <ng-container *ngFor="let document of documentTypes">
                        <mat-accordion>
                          <!-- [hideToggle]="!document.isMedicareBin" [disabled]="!document.isMedicareBin" -->
                          <mat-expansion-panel
                            [hidden]="
                              document.shortCode == SortingShortCode.All ||
                              document.shortCode ==
                                SortingShortCode.SIGNED_DOCUMENT
                            "
                            class="mt-1"
                            [hideToggle]="true"
                            [disabled]="true"
                          >
                            <mat-expansion-panel-header
                              class="customThemeClass custom-header-text-sorting custom-accordion-border"
                            >
                              <div
                                class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 d-flex pl-0 align-items-center"
                                (click)="$event.stopPropagation()"
                              >
                                <!-- [ngClass]="{'selected-option': selectedOption === sotingSelectedCSS.Bin && document_shortCode == document.shortCode}" -->
                                <!-- d-flex  -->
                                <div
                                  class="col-7 col-sm-7 col-md-7 col-lg-7 col-xl-7 pl-0 align-items-center"
                                  (click)="
                                    document.pageCount ===
                                      document.docPageContent?.length &&
                                      loadBinDocument(
                                        document.shortCode,
                                        document.pageCount
                                      )
                                  "
                                  [ngClass]="{
                                    'selected-option':
                                      selectedOption ===
                                        sotingSelectedCSS.Bin &&
                                      document_shortCode == document.shortCode,
                                    'no-drop':
                                      document.pageCount !==
                                      document.docPageContent?.length
                                  }"
                                >
                                  {{ document.documentType | uppercase }} ({{
                                    document.pageCount
                                  }})
                                </div>
                                <!-- d-flex flex-row-reverse -->
                                <div
                                  class="col-3 col-sm-3 col-md-3 col-lg-3 col-xl-3 pl-0"
                                >
                                  <div class="running-placeholder-container">
                                    <input
                                      #myInputRefs
                                      class="textbox-border-class"
                                      maxlength="20"
                                      (keypress)="numberOnly($event)"
                                      placeholder="1 (or) 1-5 (or) 1,3,4 (or) leave it empty"
                                      type="text"
                                      title="Enter a single number or a range (e.g., 1, 1-5) or leave it empty"
                                      [disabled]="
                                        document.shortCode ==
                                        SortingShortCode.All
                                      "
                                    />
                                  </div>
                                </div>
                                <div
                                  class="col-2 col-sm-2 col-md-2 col-lg-2 col-xl-2"
                                >
                                  <!-- document.shortCode !== SortingShortCode.All || !isDisabled || !TiffError || document_shortCode !== document.shortCode || overAllPagesBG !== 0 || (isTotalAPIcallGoingOnCount > 1) || overAllPages === overAllPagesBG || overAllPages === SortedDocumentImageList.length+PendingDocumentImageList.length
                                   matTooltip="Tiff Converion In progess" [matTooltipDisabled]="false"
                                   [matTooltipDisabled]="!TiffError"
                                  -->

                                  <!-- !(isTotalAPIcallGoingOnCount > 1) || -->
                                  <button
                                    (click)="
                                      moveDocument(myInputRefs.value, document)
                                    "
                                    mat-icon-button
                                    [matTooltip]="
                                      TiffError
                                        ? 'TIFF Conversion in Progress. Click to Refresh Button.'
                                        : 'Sort to ' + document.documentType
                                    "
                                    [disabled]="
                                      document.shortCode ==
                                        SortingShortCode.All ||
                                      isDisabled ||
                                      TiffError ||
                                      document_shortCode ===
                                        document.shortCode ||
                                      overAllPagesBG === 0 ||
                                      overAllPages != overAllPagesBG ||
                                      overAllPages !==
                                        SortedDocumentImageList.length +
                                          PendingDocumentImageList.length
                                    "
                                    [ngClass]="{
                                      'mr-2': !document.isMedicareBin
                                    }"
                                    class="buttonColor ml-4 d-flex align-items-center justify-content-center"
                                    color="lightseagreen"
                                  >
                                    <mat-icon style="font-size: 20px"
                                      >arrow_back</mat-icon
                                    >
                                  </button>
                                </div>
                              </div>
                            </mat-expansion-panel-header>
                          </mat-expansion-panel>
                        </mat-accordion>
                      </ng-container>
                    </ng-container>
                    <ng-container *ngIf="isLoadSortingView">
                      <div class="d-flex justify-content-center">
                        <img
                          class="img-responsive pb-2 mr-1"
                          src="assets/gifs/Infinity-loader.gif"
                        />
                      </div>
                    </ng-container>
                  </div>
                </div>
                <!-- </form> -->
              </div>

              <!-- Footer -->
              <div class="card-footer bg-transparent">
                <form [formGroup]="notesForm">
                  <mat-form-field
                    class="col-12"
                    [hidden]="activeTab === 'document' || activeTab === 'plans'"
                  >
                    <mat-label>Notes </mat-label>
                    <textarea
                      matInput
                      rows="4"
                      autocomplete="new-txtCommentsDemo"
                      maxlength="5000"
                      formControlName="txtCommentsDemo"
                      [(ngModel)]="notesText"
                      (input)="updateCharacterCount(5000)"
                      type="text"
                    ></textarea>
                    <mat-error
                      class="spanAlignment"
                      *ngIf="
                        notesForm.get('txtCommentsDemo').errors?.whitespace &&
                        notesForm.get('txtCommentsDemo').dirty
                      "
                    >
                      Notes cannot be just whitespace!
                    </mat-error>
                  </mat-form-field>
                  <mat-form-field
                    class="col-12"
                    [hidden]="
                      activeTab === 'document' || activeTab === 'demographics'
                    "
                  >
                    <mat-label>Notes </mat-label>
                    <textarea
                      matInput
                      rows="4"
                      autocomplete="new-txtCommentsPlan"
                      maxlength="5000"
                      formControlName="txtCommentsPlan"
                      [(ngModel)]="notesTextV1"
                      (input)="updateCharacterCount(5000)"
                      type="text"
                    ></textarea>
                    <mat-error
                      class="spanAlignment"
                      *ngIf="
                        notesForm.get('txtCommentsPlan').errors?.whitespace &&
                        notesForm.get('txtCommentsPlan').dirty
                      "
                    >
                      Notes cannot be just whitespace!
                    </mat-error>
                  </mat-form-field>
                  <!-- <div class="char-count">
                   {{ notesText?.length }} / 5000
                 </div> -->
                </form>
                <div class="d-flex justify-content-between footerButton">
                  <div *ngIf="activeTab === 'demographics'">
                    <!-- <div class="row"> -->
                    <!-- <div class="col-lg-5 col-md-6 col-sm-12" style="margin-left: -6%; "> -->
                    <button
                      class="buttonColor m-2"
                      *ngIf="activeTab === 'demographics'"
                      mat-button
                      [disabled]="
                        demoForm.invalid ||
                        notesForm.invalid ||
                        saveButtonDemo ||
                        isExistDocChecked !== true ||
                        isNewSetupDone === 'true'
                      "
                      (click)="saveDemographics(false)"
                    >
                      <mat-icon>save</mat-icon> Save
                      <mat-icon *ngIf="isShowPlanSpinner">
                        <mat-spinner
                          class="spinner-border spinner-border-sm"
                          diameter="20"
                        ></mat-spinner>
                      </mat-icon>
                    </button>
                    <!-- </div>
                  <div class="col-lg-6 col-md-6 col-sm-12"> -->
                    <!--Old Button Function Start-->
                    <!-- <button class="buttonColor m-2" *ngIf="activeTab === 'demographics'" mat-button
                      [disabled]="demoForm.invalid || notesForm.invalid || saveButtonDemo||isExistDocChecked!==true||isNewSetupDone==='true'"
                      (click)="saveDemographics(true)">
                      <mat-icon>check_circle_outline</mat-icon> Move to Verification / Confirmation
                      <mat-icon *ngIf='isHidePlanSpinner'>
                        <mat-spinner class="spinner-border spinner-border-sm" diameter="20"></mat-spinner>
                      </mat-icon>
                    </button> -->
                    <!--Old Button Function End-->

                    <button
                      class="buttonColor m-2"
                      *ngIf="activeTab === 'demographics'"
                      mat-button
                      [disabled]="
                        demoForm.invalid ||
                        notesForm.invalid ||
                        saveButtonDemo ||
                        isExistDocChecked !== true ||
                        isNewSetupDone === 'true'
                      "
                      (click)="planmovetoVerfication()"
                    >
                      <mat-icon>check_circle_outline</mat-icon> Move to
                      Verification / Confirmation
                      <mat-icon *ngIf="isHidePlanSpinner">
                        <mat-spinner
                          class="spinner-border spinner-border-sm"
                          diameter="20"
                        ></mat-spinner>
                      </mat-icon>
                    </button>

                    <!-- </div>
                </div> -->
                  </div>
                  <div *ngIf="activeTab === 'plans'">
                    <!-- <div class="row"> -->
                    <!-- <div class="col-6"> -->
                    <button
                      *ngIf="activeTab === 'plans'"
                      mat-button
                      class="buttonColor m-2"
                      (click)="savePlanV1()"
                      [hidden]="planForm.get('chkCashPayment')?.value === true"
                      [disabled]="
                        isplanSave ||
                        planForm?.invalid ||
                        patientId === null ||
                        patientId === defaultGuid ||
                        patientId === '' ||
                        !isUniq ||
                        !isUniqV1 ||
                        !isUniqV2 ||
                        isDemoCompleted !== true ||
                        isNewSetupDone === 'true' ||
                        notesForm.invalid ||
                        isPlanSpinner
                      "
                    >
                      <mat-icon>save</mat-icon> Save
                      <mat-icon *ngIf="isPlanSpinner">
                        <mat-spinner
                          class="spinner-border spinner-border-sm"
                          diameter="20"
                        >
                        </mat-spinner>
                      </mat-icon>
                    </button>

                    <button
                      *ngIf="activeTab === 'plans'"
                      mat-button
                      class="buttonColor m-2"
                      (click)="planmovetoVerfication()"
                      [hidden]="planForm.get('chkCashPayment')?.value === true"
                      [disabled]="
                        isDemoCompleted !== true ||
                        isPlanSpinnerMoveVerification ||
                        isplanSave
                      "
                    >
                      <mat-icon>check_circle_outline</mat-icon> Move to
                      Verification / Confirmation
                      <mat-icon *ngIf="isPlanSpinnerMoveVerification">
                        <mat-spinner
                          class="spinner-border spinner-border-sm"
                          diameter="20"
                        >
                        </mat-spinner>
                      </mat-icon>
                    </button>
                    <!-- </div>
                  <div class="col-1"></div>
                  <div class="col-5" > -->

                    <!-- [disabled]="isplanSave||planForm?.invalid||patientId===null||patientId===defaultGuid||patientId===''" -->
                    <button
                      *ngIf="activeTab === 'plans'"
                      mat-button
                      class="buttonColor"
                      (click)="savePlans(true)"
                      [disabled]="
                        isplanSave ||
                        planForm?.invalid ||
                        patientId === null ||
                        patientId === defaultGuid ||
                        patientId === '' ||
                        isDemoCompleted !== true ||
                        isNewSetupDone === 'true'
                      "
                      [hidden]="planForm.get('chkCashPayment')?.value !== true"
                    >
                      <mat-icon>check_circle_outline</mat-icon>Save and Move to
                      Ready / Confirmation
                      <mat-icon *ngIf="isPlanReadySpinner">
                        <mat-spinner
                          class="spinner-border spinner-border-sm"
                          diameter="20"
                        >
                        </mat-spinner>
                      </mat-icon>
                    </button>
                    <!-- </div> -->
                    <!-- </div> -->
                  </div>
                  <div *ngIf="activeTab === 'document'"></div>
                  <button
                    *ngIf="activeTab === 'document'"
                    [matTooltip]="'Save Sorting Documents'"
                    class="defaultButton saveButton"
                    mat-raised-button
                    (click)="saveSortedDetails()"
                    [disabled]="
                      isDisabled ||
                      SortSaveError ||
                      (saveDocumentDetails?.length === 0 &&
                        removeSorting?.length === 0)
                    "
                  >
                    <!-- *ngIf="!isDisabled" -->
                    <mat-icon>save</mat-icon>

                    <!-- <mat-icon *ngIf="isSaveSortingButton">
                      <mat-spinner class="spinner-border-sm" diameter="20"></mat-spinner>
                    </mat-icon> -->

                    Save
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- TIFF Viewer -->

      <div class="col-12 col-sm-12 col-md-12 col-lg-7 col-xl-7">
        <div
          class="card card-body align-items-center justify-content-center p-0"
        >
          <!-- <h1 class="fw-bolder" [ngClass]="{ 'blink-text': isLoading || TiffError }" *ngIf="isLoading || TiffError">
            {{ loadingMessage }}
          </h1> -->

          <div *ngIf="isLoading" class="d-flex justify-content-center">
            <img
              class="img-responsive pb-2 mr-1"
              src="assets/gifs/Infinity-loader.gif"
            />
          </div>

          <div *ngIf="!TiffError">
            <div class="zoom-buttons" *ngIf="!isLoading">
              <a
                [matTooltip]="'Clear All Annotation'"
                class="ml-2 mt-2"
                (click)="clearAllAnnotation()"
                [class.disabled]="
                  this.textAnnotations.length === 0 || disableAnnotation
                "
                href="javascript:void(0)"
              >
                <mat-icon>clear</mat-icon>
                <!-- restore -->
              </a>

              <a
                [matTooltip]="'Rotate Clockwise'"
                class="ml-2 mt-2"
                (click)="rotateClockwise()"
                [class.disabled]="isLoading || disableAnnotation || true"
                href="javascript:void(0)"
              >
                <mat-icon>rotate_left</mat-icon>
              </a>

              <a
                [matTooltip]="'Rotate Counter Clock Wise'"
                class="ml-2 mt-2"
                (click)="rotateCounterclockwise()"
                [class.disabled]="isLoading || disableAnnotation || true"
                href="javascript:void(0)"
              >
                <mat-icon>rotate_right</mat-icon>
              </a>

              <a
                [matTooltip]="'Zoom In Page'"
                class="ml-2 mt-2"
                (click)="zoomIn()"
                [class.disabled]="
                  isLoading || disableAnnotation || zoomLevel === MAX_ZOOM_LEVEL
                "
                href="javascript:void(0)"
              >
                <mat-icon>add</mat-icon>
              </a>

              <a
                [matTooltip]="'Zoom Out Page'"
                class="ml-2 mt-2"
                (click)="zoomOut()"
                [class.disabled]="
                  isLoading || disableAnnotation || zoomLevel === MIN_ZOOM_LEVEL
                "
                href="javascript:void(0)"
              >
                <mat-icon>remove</mat-icon>
              </a>

              <a
                [matTooltip]="'Go to First Page'"
                class="ml-2 mt-2"
                (click)="firstImage()"
                [class.disabled]="
                  isLoading || disableAnnotation || pageNumber === 1
                "
                href="javascript:void(0)"
              >
                <mat-icon>first_page</mat-icon>
              </a>
              <a
                [matTooltip]="'Go to Previous Page'"
                class="ml-2 mt-2"
                (click)="previousImage()"
                [class.disabled]="
                  isLoading || disableAnnotation || pageNumber === 1
                "
                href="javascript:void(0)"
              >
                <mat-icon>keyboard_arrow_left</mat-icon>
              </a>

              <input
                [matTooltip]="'Go to Custom Page'"
                matInput
                class="form-control custom-input"
                type="number"
                [(ngModel)]="pageNumber"
                min="1"
                max="{{ totalPages }}"
                (focusout)="goToPage()"
                [readonly]="isLoading || disableAnnotation"
              />

              <span> of {{ totalPages }}</span>

              <a
                [matTooltip]="'Go to Next Page'"
                class="ml-2 mt-2"
                (click)="nextImage()"
                [class.disabled]="
                  isLoading ||
                  disableAnnotation ||
                  pageNumber === existingDocumentImageList.length
                "
                href="javascript:void(0)"
              >
                <mat-icon>keyboard_arrow_right</mat-icon>
              </a>
              <a
                [matTooltip]="'Go to Last Page'"
                class="ml-2 mt-2"
                (click)="lastImage()"
                [class.disabled]="
                  isLoading ||
                  disableAnnotation ||
                  pageNumber === existingDocumentImageList.length
                "
                href="javascript:void(0)"
              >
                <mat-icon>last_page</mat-icon>
              </a>

              <a
                [matTooltip]="'Save Annotation'"
                class="ml-2 mt-2"
                (click)="saveAnnotation()"
                [class.disabled]="
                  this.textAnnotations.length === 0 || disableAnnotation
                "
                href="javascript:void(0)"
              >
                <mat-icon *ngIf="!disableAnnotation">save</mat-icon>

                <mat-icon *ngIf="disableAnnotation">
                  <mat-spinner
                    class="spinner-border-sm"
                    diameter="20"
                  ></mat-spinner>
                </mat-icon>
              </a>

              <!-- <button [matTooltip]="'Save Annotation'" class="defaultButton saveButton" mat-raised-button
                (click)="saveAnnotation()" [disabled]="this.textAnnotations.length === 0 || disableAnnotation">
                <mat-icon *ngIf="!disableAnnotation">save</mat-icon>

                <mat-icon *ngIf="disableAnnotation">
                  <mat-spinner class="spinner-border-sm" diameter="20"></mat-spinner>
                </mat-icon>

              </button> -->

              <mat-icon
                class="ml-4"
                style="cursor: pointer !important"
                [matTooltip]="'Open Annotation Option'"
                [matMenuTriggerFor]="settings"
                >more_vert</mat-icon
              >

              <!-- <a [matTooltip]="'Download Patient Document'" class="ml-2 mt-2" (click)="patientDocPdfDownload()"
                   [class.disabled]="isLoading || isEmpty(blobName)" href="javascript:void(0)">
                   <i class="fa fa-download" aria-hidden="true"></i>
              </a> -->

              <a
                [matTooltip]="'View Document in PDF'"
                class="ml-2 pt-1"
                (click)="viewPatientBlobPDF()"
                [class.disabled]="isLoading || isEmpty(blobName)"
                href="javascript:void(0)"
              >
                <i class="fa fa-eye" aria-hidden="true"></i>
              </a>

              <mat-menu #settings="matMenu">
                <button
                  mat-menu-item
                  (click)="annotationMode(tiffPointerMode.crosshair)"
                  [ngClass]="{
                    'active-annotation-button':
                      annotationModeFlag === tiffPointerMode.crosshair
                  }"
                >
                  <mat-icon>photo_size_select_small</mat-icon> Draw
                </button>
                <button
                  [disabled]="true"
                  mat-menu-item
                  (click)="annotationMode(tiffPointerMode.text)"
                  [ngClass]="{
                    'active-annotation-button':
                      annotationModeFlag === tiffPointerMode.text
                  }"
                >
                  <mat-icon>text_rotation_none</mat-icon> Text
                </button>
                <!-- -->
                <button
                  [disabled]="true"
                  mat-menu-item
                  (click)="annotationMode(tiffPointerMode.grab)"
                  [ngClass]="{
                    'active-annotation-button':
                      annotationModeFlag === tiffPointerMode.grab
                  }"
                >
                  <mat-icon>colorize</mat-icon> Marker
                </button>
              </mat-menu>
            </div>
          </div>
        </div>

        <div
          [hidden]="isHideTiffViewer"
          [ngClass]="{
            isHideTiffViewer: isLoading,
            'd-flex': !isHideTiffViewer && !isLoading
          }"
          class="justify-content-center scrollable-card p-0 backgroundclr border-line"
          style="min-height: 70vh; max-height: 73vh"
        >
          <!-- [ngClass]="annotationModeFlag === tiffPointerMode.none ? 'circle_cursor' : ''" -->
          <div
            class="scrollable-card-image"
            [hidden]="disableAnnotation"
            [ngClass]="
              annotationModeFlag === tiffPointerMode.grab ? 'circle_cursor' : ''
            "
            [ngClass]="
              annotationModeFlag === tiffPointerMode.help ? 'delete_cursor' : ''
            "
            [style.cursor]="
              annotationModeFlag === tiffPointerMode.grab ? '' : cursorStyle
            "
          >
            <!-- (mouseover)="OnMouseOver($event)"  -->
            <canvas
              #canvas
              (mousedown)="onMouseDown($event)"
              (mousemove)="onMouseMoveVVV($event)"
              (mouseup)="onMouseUpVVV($event)"
              (mouseleave)="onMouseLeave($event)"
              (dblclick)="deleteTextV4($event)"
              (click)="addText($event)"
            ></canvas>
            <input [(ngModel)]="tiffPageNo" type="hidden" name="tiffPageNo" />
          </div>
        </div>

        <div
          *ngIf="isHideTiffViewer"
          class="pdf-container pb-4"
          [hidden]="isLoading"
        >
          <ng-container *ngIf="showPdfViwer">
            <ngx-extended-pdf-viewer
              [base64Src]="strPdfString"
              [height]="pdfheight"
              [showSidebarButton]="false"
              [textLayer]="true"
              [showPagingButtons]="true"
              [showOpenFileButton]="false"
              [showHandToolButton]="false"
              [showScrollingButton]="false"
              [showSpreadButton]="false"
              [showPropertiesButton]="false"
              [showBookmarkButton]="false"
              [zoom]="'page-width'"
              [enableDragAndDrop]="false"
              [showFindButton]="false"
            >
            </ngx-extended-pdf-viewer>
          </ng-container>
        </div>
      </div>

      <!-- TIFF Preview Viewer -->
      <!-- <div class="col-12 col-sm-12 col-md-12 col-lg-1 col-xl-1 p-0" [hidden]="isHideTiffViewer">
        <div class="card card-body align-items-center scrollable-card p-0" style="height: 78vh">
          <ng-container *ngFor="let image of existingDocumentImageList">
              <div class="card my-2 elevated-div custom-thumbnail-view">
                  <img class="card-img-top img-fluid custom-thumbnail-image" (click)="loadExistingImage(image)"
                      [ngClass]="{
              'custom-thumbnail-view-selected':
                selectedExistingDocument?.pageNo === image?.pageNo
            }" [src]="image?.src" [alt]="image?.pageNo" />
              </div>
          </ng-container>
      </div>
      </div> -->

      <!-- <div class="col-lg-7"> -->
      <!-- <app-new-setup-doc-tiff-viewer [TiffData]="existingDocumentImageList" ></app-new-setup-doc-tiff-viewer> -->
      <!-- <div class="pdf-container">
          <ng-container>
            <ngx-extended-pdf-viewer [src]="strSelectedPdfPath" (pagesLoaded)="onPagesLoaded($event)"
              [(page)]="currentPage" (pageChange)="onPageChange($event)" [height]="pdfheight"
              [showSidebarButton]="false" [showFindButton]="true" [textLayer]="true" [showPagingButtons]="true"
              [showOpenFileButton]="false" [showHandToolButton]="false" [showScrollingButton]="false"
              [showSpreadButton]="false" [showPropertiesButton]="false" [showBookmarkButton]="false"
              [zoom]="'page-width'" [enableDragAndDrop]="false">
            </ngx-extended-pdf-viewer>
          </ng-container>
        </div>-->
      <!-- </div> -->
    </div>
  </div>
</mat-dialog-content>
<app-loader-component *ngIf="isOrderPageLoading"></app-loader-component>
