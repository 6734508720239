import { DatePipe } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import moment, { Moment } from 'moment';
import { LocaleConfig } from 'ngx-daterangepicker-material';
import { ClaimDetailsDTO1 } from 'projects/billing/src/app/claim-proxy/claim-processing-management/rcm/billing-management/posting/dto';
import { ClaimProcessService } from 'projects/billing/src/app/claim-proxy/claim-processing-management/rcm/claim-processing-management/x12';
import { InvoiceDTO } from 'projects/order/src/app/order-proxy/order-management/order-optimization/dto';
import { debounceTime, filter, map, startWith, switchMap, tap } from 'rxjs/operators';

@Component({
  selector: 'app-patient-claim',
  templateUrl: './patient-claim.component.html',
  styleUrls: ['./patient-claim.component.scss'],
  providers: [DatePipe]
})
export class PatientClaimComponent implements OnInit {
  @ViewChild(MatSort, { static: false }) sort: MatSort = new MatSort();
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  claimProcessingForm: FormGroup;
  dataSource: MatTableDataSource<ClaimDetailsDTO1> = new MatTableDataSource<ClaimDetailsDTO1>([]);
  selectedRange: { startDate: Moment, endDate: Moment };
  ranges: any;
  minDate: Moment;
  maxDate: Moment;
  fromDate : string
  patientIds : any
  toDate: string
  calendarLocale: LocaleConfig;
  dateRangStatus: boolean = false;
  calendarPlaceholder: string = '';
  alwaysShowCalendars: boolean;
  patientId: string;
  patientClaim = 'Patient Claim - ';
  isLoading: boolean = false;
  constructor( 
    private claimProcessService: ClaimProcessService,
    public fb: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private datepipe: DatePipe,
    ) { 
      this.calendarLocale = {
        customRangeLabel: 'Pick a date...',
        applyLabel: 'Apply',
        clearLabel: 'Clear',
        format: 'DD/MM/YYYY',
        daysOfWeek: ['su', 'mo', 'tu', 'we', 'th', 'fr', 'sa'],
        monthNames: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
        firstDay: 1
      }; 
      
      this.ranges = {
        'Today': [moment(), moment()],
        'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
        'Last 7 Days': [moment().subtract(6, 'days'), moment()],
        'Last 30 Days': [moment().subtract(29, 'days'), moment()],
        'This Month': [moment().startOf('month'), moment().endOf('month')],
      };
    }


  arrDisplayedColumns: string[] = [
    'claimIdSearch',
    'saleOrderIdSearch',
    'claimStatusSearch',
    'insuranceNameSearch',
    'insuranceTypeSearch',
    'claimProcessedDateSearch',
  ];
  
  ngOnInit(): void {


    this.activatedRoute.params.subscribe(params => {
      const id = params['id']; // Access the query parameter value
      this.patientId = id
    })

    this.claimProcessingForm = this.fb.group({
      defaultClaimId: new FormControl(''),
      // patientId: new FormControl(''),
      saleOrderId: new FormControl(''),
      claimStatus: new FormControl(''),
      // patientIdSearch: new FormControl('', ),
      insuranceName: new FormControl(''),
      insuranceType: new FormControl('',),
      claimProcessedDateSearch: new FormControl(''),

    });

    this.getClaimProcessList()
  }

  datesUpdateds(e){
    if(e.startDate !=null || e.endDate !=null){
      this.dateRangStatus = true
      const startDate = new Date(e.startDate);
      const endDate = new Date(e.endDate);
      this.fromDate = this.datepipe.transform(startDate,"MM/dd/yyyy")
      this.toDate = this.datepipe.transform(endDate,"MM/dd/yyyy")
      this.getClaimProcessList()

    } else {
      this.dateRangStatus = false
      this.getClaimProcessList()
    }
  }

  getClaimProcessList(){
    const valueChanges = this.claimProcessingForm.valueChanges
    .pipe(
      startWith({
        claimIdSearch: '',
        saleOrderIdSearch: '',
        claimStatusSearch: '',
        patientIdSearch: '',
        insuranceName: '',
        insuranceType: '',
        claimProcessedDateSearch: '',
        patientId: '',

      }),
      tap((x) => {
        // this.isLoading = true;
        this.setTableData([]);
      }),
      filter((e) => e && this.claimProcessingForm?.valid),
      debounceTime(300),
      map((response) => {
        // this.isLoading = true;
        const sValue = {
          claimIdSearch: response?.documentId?.trim()?.toLowerCase(),
          saleOrderIdSearch: response?.saleOrderId?.trim()?.toLowerCase(),
          claimStatusSearch: response?.patientName?.trim()?.toLowerCase(),
          patientIdSearch: '',
          insuranceNameSearch: response?.insuranceName?.trim()?.toLowerCase(),
          insuranceTypeSearch: response?.insuranceType?.trim()?.toLowerCase(),
          claimProcessedDateSearch: response?.claimStatus?.trim()?.toLowerCase(),
          patientId: this.patientId?this.patientId: '',
          start_Date: this.fromDate?.trim()?.toLowerCase(),
          end_Date: this.toDate?.trim()?.toLowerCase(),
        };
        return sValue;
      }),
      switchMap((sValue) =>
        this.claimProcessService.getClaimDetails(
          sValue?.patientId,
          sValue?.start_Date,
          sValue?.end_Date,
          sValue?.claimIdSearch,
          sValue?.saleOrderIdSearch,
          sValue?.claimStatusSearch,
          sValue?.patientIdSearch,
          sValue?.insuranceNameSearch,
          sValue?.insuranceTypeSearch,
          sValue?.claimProcessedDateSearch,
        )
      )
    )
    .subscribe(
      (response) => {
         response?.map((x, i = 1) => {
          this.patientIds = x
          
        });
        this.isLoading = false;
        const responseData: ClaimDetailsDTO1[]= response ?? [];
        this.setTableData(responseData);
        
      },
      (err) => {
        this.isLoading = false;
        const data: HttpErrorResponse = err;
        // Swal.fire({
        //   icon: 'error',
        //   text: data?.error?.error?.message,
        // });
      }
    );
  }
  setTableData(tableData: ClaimDetailsDTO1[] = []) {
    this.dataSource = new MatTableDataSource(tableData);
    this.dataSource.sort = this.sort;
    this.dataSource.sort.disableClear = true;
    this.dataSource.paginator = this.paginator;
  }
}
