<div class="container-fluid">
  <!-- Headers -->
  <div class="row">
    <!-- <div class="col-lg-12">
      <div class="d-flex align-items-center justify-content-between">
        <h3 class="font-weight-bolder header-font mb-0">
          Inventory / Requisition List
        </h3>
      </div> -->

    <div class="col-12">
      <div class="d-flex justify-content-between">
        <div class="page-title-box page-title-left pb-0">
          <h3 class="font-weight-bolder mb-0 pt-2 customThemeClass">
            <b>Tools / Inventory / Requisition List  </b>
          </h3>
        </div>
        <div class="page-title-box page-title-right pb-0">
          <form [formGroup]="requisitionFilterForm">
            <div class="col-4">
              <mat-form-field class="pr-3 pt-1">
                <mat-label>Requisition Status</mat-label>
                <mat-select
                  (selectionChange)="onStatusChange($event)"
                  formControlName="drpStatus"
                >
                  <mat-option>
                    <ngx-mat-select-search
                      placeholderLabel="Search"
                      [formControl]="drpFilter"
                      noEntriesFoundLabel="No Matches found"
                      formControlName="txtFilterSearch"
                    ></ngx-mat-select-search>
                  </mat-option>
                  <mat-option
                    *ngFor="let data of filteredRequisitionStatus"
                    [value]="data.shortCodeId"
                    >{{ data.requisitionStatus }}</mat-option
                  >
                </mat-select>
              </mat-form-field>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-body pt-2">
          <div
            class="d-flex justify-content-center"
            *ngIf="!loadRequisitionTable"
          >
            <!-- <mat-spinner></mat-spinner> -->
            <img
              class="img-responsive pb-2 mr-1"
              src="assets/gifs/Infinity-loader.gif"
            />
          </div>

          <div *ngIf="loadRequisitionTable" class="table table-responsive">
            <table
              datatable
              [dtOptions]="requisitionListOptions"
              class="table row-border hover w-100 display"
            >
              <thead>
                <tr>
                  <th [matTooltip]="'Special Instruction'" class="text-center">
                    Special Instruction
                  </th>
                  <th class="maxWidth50px" [matTooltip]="'Options'">Options</th>
                  <th [matTooltip]="'Requisition Number'" class="text-right">
                    Requisition Number
                  </th>
                  <th [matTooltip]="'Total($)'" class="text-right">Total ($)</th>
                  <th [matTooltip]="'Req Location'">Req Location</th>
                  <th [matTooltip]="'Shipping Method'">Shipping Method</th>
                  <th [matTooltip]="'Status'">Status</th>
                  <th [matTooltip]="'Created Date'">
                    Requisition Created Date
                  </th>
                  <th [matTooltip]="'CSR'">CSR</th>
                  <th [matTooltip]="'Created By'">Created By</th>
                  <th class="text-right" [matTooltip]="'Created Date'" >Created Date</th>
                  <th class="maxWidth100px" [matTooltip]="'Modified Date'">Modified Date</th>
                  <th [matTooltip]="'Modified By'">Modified By</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  [ngClass]="
                    this.tblItemSelectedRowId === data.reqId
                      ? 'row-highlighted'
                      : ''
                  "
                  (click)="tblItemSelectedRow(data.reqId, data.shortCodeId)"
                  *ngFor="let data of requisitionListData"
                >
                  <td
                    [matTooltip]="(data.specialInstruction===''||data.specialInstruction===null||data.specialInstruction===undefined)
                    ?'Special Instruction not Available':'Toggle to View Special Instruction '"
                    (click)="
                      handleTableCellClick($event, data.specialInstruction)
                    "
                    [ngClass]="(data.specialInstruction===''||data.specialInstruction===null||data.specialInstruction===undefined) ? 'cursor-not-allowed' : ''"
                    class="dt-control"
                  ></td>
                  <td class="maxWidth50px">
                    <ng-container
                      *ngIf="data.shortCodeId === reqStatusShortCodeIds.Cancel"
                      ><p class="text-danger blinking-text">
                        Cancelled
                      </p></ng-container
                    >
                    <ng-container
                      *ngIf="
                        data.shortCodeId === reqStatusShortCodeIds.completed
                      "
                      ><p class="text-danger blinking-text dd-text pt-2">
                        Completed
                      </p></ng-container
                    >
                    <ng-container
                      *ngIf="
                        data.shortCodeId !== reqStatusShortCodeIds.Cancel &&
                        data.shortCodeId !== reqStatusShortCodeIds.completed
                      "
                    >
                      <mat-icon
                        type="button"
                        matTooltip="Cancel Requisition"
                        (click)="cancelRequisition(data.reqId)"
                        class="icon-danger"
                        >clear</mat-icon
                      ></ng-container
                    >
                  </td>
                  <td
                    [matTooltip]="data?.requitionNumber || '-'"
                    class="text-right"
                  >
                    {{ data.requitionNumber }}
                  </td>
                  <td class="text-right" [matTooltip]="data?.extendedCost || '0'">{{ toFixed(data.extendedCost)}}</td>
                  <td [matTooltip]="data?.requestLocationName || '-'">{{data.requestLocationName ? data.requestLocationName :'-'}}</td>
                  <td [matTooltip]="data?.shippingMethod || '-'">
                    {{ data.shippingMethod }}
                  </td>
                  <td [matTooltip]="data?.status || '-'">{{ data.status }}</td>
                  <td
                    [matTooltip]="
                      data?.requisitionDate
                        ? (data?.requisitionDate
                          | date : 'MM/dd/yyyy ' : 'en_US')
                        : '-'
                    "
                  >
                    {{
                      data?.requisitionDate
                        ? (data?.requisitionDate
                          | date : "MM/dd/yyyy " : "en_US")
                        : "-"
                    }}
                  </td>
                  <td [matTooltip]="data?.requestedUser || '-'">
                    {{ data.requestedUser }}
                  </td>
                  <td [matTooltip]="data?.createdBy || '-'">{{
                  data?.createdBy || '-' }}</td>

              <td class="text-center">
                <span [matTooltip]="data?.createdDate">{{
                  data?.createdDate }}</span>
              </td>

              <td class="text-center">
                <span [matTooltip]="data?.lastModifiedDate">{{
                  data?.lastModifiedDate }}</span>
              </td>
                  <td [matTooltip]="data?.modifiedBy || '-'">
                    {{ data.modifiedBy }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-12">
      <div class="card mt-2">
        <div class="card-header header-text">Requisition Items</div>
        <div class="card-body">
          <div
            class="d-flex justify-content-center"
            *ngIf="!loadRequisitionItemsTable"
          >
            <!-- <mat-spinner></mat-spinner> -->
            <img
              class="img-responsive pb-2 mr-1"
              src="assets/gifs/Infinity-loader.gif"
            />
          </div>
          <div *ngIf="loadRequisitionItemsTable" class="table table-responsive">
            <app-requisition-items-list
              [requisitionItemsList]="requisitionItemsList"
              [reqStatusShortCodeId]="tblSelectedStatusId"
            ></app-requisition-items-list>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
