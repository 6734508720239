import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-insurance-check-list',
  templateUrl: './insurance-check-list.component.html',
  styleUrls: ['./insurance-check-list.component.scss']
})
export class InsuranceCheckListComponent implements OnInit {
  checkListIns: FormGroup;
  checkListIns1: FormGroup;
  InsCheckList: FormGroup
  activeTab: string = 'NewSetup';
  constructor(
    private formBuilder: FormBuilder,
   
  ) { }

  ngOnInit(): void {
    this.checkListIns = this.formBuilder.group({
      txtChartNo: new FormControl(''),
      txtPatientName: new FormControl(''),
      txtVerification: new FormControl(''),
      txtInsComments: new FormControl(''),
    })
    this.checkListIns1 = this.formBuilder.group({
      txtDOB: new FormControl(''),
      txtDOBData: new FormControl(''),
      txtDOBValue: new FormControl(''),
      txtDOBs: new FormControl(''),
    })
    this.InsCheckList = this.formBuilder.group({
      txtPreparedDate: new FormControl(''),
      txtPreparedBy: new FormControl(''),
      txtDisapproved: new FormControl(''),
      txtApprovedDate: new FormControl(''),
    })
  }
  changeTab(tab: string){
    this.activeTab = tab;
    if (this.activeTab == 'Supplies') {
      
    }
  }

}
