<mat-dialog-content class="mat-typography">
  <div class="row align-items-center">
    <div class="col-lg-9">
      <h4 class="customThemeClass">
        <b>Other Faxes</b>
      </h4>
    </div>
    <div class="col-lg-3 " style="text-align-last: right">
      <mat-icon mat-dialog-close>close</mat-icon>
    </div>
  </div>
  <div class="row align-items-center">
  <div class="col-lg-6">
    <h4 class="customThemeClass">
      <b>Fax Id: {{data.faxId}}</b>
    </h4>
  </div>
  </div>
  <form [formGroup]="allocationForm">
    <div class="row">
      <!-- Agent -->
      <mat-form-field class="col-md-12">
        <mat-label>Agent/Email/Location<span class="asterisk">*</span></mat-label>
        <mat-select
        maxlength="40"
        formControlName="drpCSR"
        #singleSelect
        [(ngModel)]="seletedCSRValue"
      >
        <mat-option>
          <ngx-mat-select-search
            placeholderLabel="Search CSR"
            [formControl]="CSRFilterControl"
            noEntriesFoundLabel="No Matches found"
            (keyup)="UserSearch($event.target.value)"
          ></ngx-mat-select-search>
        </mat-option>
        <mat-option
          *ngFor="let csr of filteredCSRList"
          [value]="csr.userId"
        >
          {{ csr.name }}/{{csr.mail}}/{{csr.branchName}}
        </mat-option>
      </mat-select>
        <mat-error
          class="spanAlignment"
          *ngIf="
            allocationForm?.get('agent')?.touched &&
            allocationForm?.get('agent')?.errors?.required
          "
        ></mat-error>
      </mat-form-field>
      <!-- Notes -->
      <mat-form-field class="col-12">
        <mat-label>Notes<span class="asterisk">*</span></mat-label>
        <textarea
          formControlName="txtComment"
          type="text"
          rows="5"
          maxlength="5000"
          autocomplete="off"
          matInput
          [(ngModel)]="notesText"
          (input)="updateCharacterCount()"
        ></textarea>
        <div class="char-count">{{ notesText?.length }} / 5000</div>
        <mat-error
          class="spanAlignment"
          *ngIf="
            allocationForm?.get('txtComment')?.touched &&
            allocationForm?.get('txtComment')?.errors?.required
          "
          >Notes is Required field</mat-error
        >
      </mat-form-field>
        <!-- Buttons -->
        <div class="col-lg-12  d-flex justify-content-center">
            <ng-container class="col-12" *ngIf="!allocationForm?.disabled">
              <button
              [disabled]="allocationForm?.get('txtComment')?.value===null || allocationForm?.get('txtComment')?.value===''||seletedCSRValue==='00000000-0000-0000-0000-000000000000'"
                mat-button
                class="buttonColor" (click)="saveCSRDetails(seletedCSRValue, allocationForm.get('txtComment').value)"
              >
                Save
              </button>
              <button mat-button class="resetclr ml-2" (click)="resetCallBack()">Reset</button>
            </ng-container>
          </div>
    </div>
  </form>

  <!-- <div class="col-12 pb-2">
    <table datatable [dtOptions]="dtNotesTableOptions" class="row-border hover">
      <thead>
        <tr>
          <th>#</th>
          <th>Agent</th>
          <th>Note Type</th>
          <th>Created Date</th>
          <th>Description</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let data of notesList">
          <td
            class="dt-control"
            matTooltip="Click to view full description"
            (click)="handleTableCellClick($event, data.description)"
          ></td>
          <td>{{data.agent}}</td>
          <td>{{data.noteType}}</td>
          <td>{{data.createdDate}}</td>
          <td matTooltip="{{data.description}}" style="max-width: 300px;" class="d-inline-block text-truncate">{{data.description}}</td>
        </tr>
      </tbody>
    </table>
  </div> -->
</mat-dialog-content>
