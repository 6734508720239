import { PagedAndSortedResultRequestDto } from '@abp/ng.core';
import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { MatSnackBar } from '@angular/material/snack-bar';
import { BillingListService } from 'projects/order/src/app/order-proxy/order-management/order-optimization/billing-list.service';
import { MmOrderService } from 'projects/order/src/app/order-proxy/order-management/order-optimization/mm-order.service';
import { PracticeManagementService } from 'projects/patient/src/app/patient-proxy/practice-management';
import { PracticeManagementDTO } from 'projects/patient/src/app/patient-proxy/practice-management/dto';
import { CommunicationService } from 'projects/shared/src/app/services/communication.service';
import { Observable, Subscription } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { RXInputDTO } from '../admin-proxy/platform-app-management/rcm/platform-management/inbound/dto/models';
import { defaultGuid } from 'projects/shared/src/app/enums/allenums.enum';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { RxReportSendMailComponent } from '../rx-report-send-mail/rx-report-send-mail.component';
import { HttpErrorResponse } from '@angular/common/http';
import { Title } from '@angular/platform-browser';
import { Route, Router } from '@angular/router';
import { CommonService } from 'projects/shared/src/app/services/common.service';

@Component({
  selector: 'app-rx-report',
  templateUrl: './rx-report.component.html',
  styleUrls: ['./rx-report.component.scss'],
  animations: [
    trigger('slideInRight', [
      state(
        'open',
        style({
          transform: 'translateX(0%)',
        })
      ),
      state(
        'closed',
        style({
          transform: 'translateX(100%)',
        })
      ),
      transition('closed => open', [animate('0.3s ease-out')]),
      transition('open => closed', [animate('0.3s ease-in')]),
    ]),
  ],
})
export class RxReportComponent implements OnInit {
  reportForm: FormGroup;
  filterValue: boolean = false;
  reportList: any;
  // fromDate: any;
  rxTableList: any;
  // toDate: any;
  lstPatientChartNos: any;
  lstPractices: any;
  patientId: string = '';
  isReportTableLoaded: boolean = false;
  patientChartApiSubscription: Subscription;

  dExpiryFromDate: Date;
  dExpiryToDate: Date;
  // dExpiryFromDateAPI : string;
  // dExpiryToDateAPI : string;
  gPractitioner: string[];
  gPatient: string;

  MyInput: RXInputDTO[] = [];
  private rxReportGetAPICall: Subscription;
  // subscription$: Subscription;
  public filteredPractices: Observable<PracticeManagementDTO[]> | undefined;
  isShowMultipleRX: boolean = false;
  constructor(
    private title: Title,
    private formBuilder: FormBuilder,
    private datepipe: DatePipe,
    private mmOrderService: MmOrderService,
    private practiceManagementService: PracticeManagementService,
    private billingListService: BillingListService,
    private communicationService: CommunicationService,
    private snackBar: MatSnackBar,
    private router: Router,
    private dialog: MatDialog,
    private commonService : CommonService
  ) {
    const comService = this.communicationService.functionLoadRxList$.subscribe(
      () => {
        this.filterValue === true && this.loadRxTableList(null, null);
        this.filterValue === false &&
          this.loadRxTableList(this.getCTStartOfMonth(), this.ctDateNow());
      }
    );
    // this.subscription$.push(comService);
  }

  ngOnInit(): void {
    this.title.setTitle('Qsecure | Rx Report');
    this.dExpiryFromDate = this.getCTStartOfMonth();
    this.dExpiryToDate = this.ctDateNow();
    this.initializeForms();
    this.setDefaultDateValues();
    this.searchPatient('');
    this.loadPracticeManagements();
    this.loadPracticeManagements();
    this.loadRxTableList(this.getCTStartOfMonth(), this.ctDateNow());
  }

  ngOnDestroy(): void {
    // this.subscription$?.forEach((sub) => {
    //   sub && sub?.unsubscribe();
    // });
    if (this.rxReportGetAPICall) {
      this.rxReportGetAPICall.unsubscribe();
    }
  }

  addSlashToDate(event: Event): void {
    const input = event.target as HTMLInputElement;
    const inputValue = input.value;
    input.value = inputValue
      .replace(/^(\d{2})(\d{1,2})/, '$1/$2')
      .replace(/^(\d{2}\/\d{2})(\d+)/, '$1/$2')
      .replace(/[^\d\/]/g, '');
  }

  showNotification(message: string): void {
    this.snackBar.open(message, 'Close', {
      duration: 3000, // Duration in milliseconds
      horizontalPosition: 'end', // Positioning the notification horizontally
      verticalPosition: 'bottom', // Positioning the notification vertically
    });
  }

  updateCount(data: any) {
    this.MyInput = [];
    if (data.data.length > 0) {
      this.isShowMultipleRX = true;
      let xx: RXInputDTO[] = [];
      data.data.forEach((value: any) => {
        let input: RXInputDTO = {
          gPatientId: value?.patientId || defaultGuid,
          gOrderId: value?.orderId || defaultGuid,
        };
        xx.push(input);
      });
      this.MyInput.push(...xx);
    } else {
      this.isShowMultipleRX = false;
    }
  }

  openUploadPopUp() {

    const config: MatDialogConfig = {
      disableClose: true,
      minHeight: '20vh',
      minWidth: '55vw',
      data: { input: this.MyInput },
    };
    const dialogRef = this.dialog.open(RxReportSendMailComponent, config);

    const closeDialog = dialogRef.afterClosed().subscribe(
      (data: any)  => {
        this.isReportTableLoaded=false;
        if (data.length > 0) {
            data.forEach(element => {
              const index = this.reportList.findIndex(
                (obj) => obj.orderId === element.orderId && obj.patientId === element.patientId
              );
              if (index !== -1) {
                this.reportList[index] = element; 
              }
            });
          }else{
            // this.reloadRxReport.emit();
          }
          this.isReportTableLoaded=true;
          this.MyInput = [];
          this.isShowMultipleRX = false;

      },
      (err) => {
        const data: HttpErrorResponse = err;
        console.error(data?.error?.error?.message)
        // this.reloadRxReport.emit();
      }
    );
    // this.subscription$.push(closeDialog);
  }

  // fnIsShowMultipleRX(){

  // }

  reloadRxReport(data: any){
  }

  loadRxTableList(fDate: Date, toDate: Date) {
    if (this.reportForm.valid || this.filterValue) {
      if (this.rxReportGetAPICall) {
        this.rxReportGetAPICall.unsubscribe();
      }

      let x = null;
      if (fDate) {
        x = this.convert(fDate).toString();
      }

      let y = null;
      if (fDate) {
        y = this.convert(toDate).toString();
      }

      if(this.filterValue){
        this.gPatient=this.reportForm.value.drpPatient;
        this.gPractitioner=this.reportForm.value.drpPracticeManagement;
      }else{
        this.gPatient=null;
        this.gPractitioner=null;
      }

      this.isReportTableLoaded = false;

      this.rxReportGetAPICall = this.billingListService
        .getRXDateReport(x, y, this.gPractitioner, this.gPatient)
        .subscribe(
          (response) => {
            this.isReportTableLoaded = true;
            this.reportList = response;

            this.reportList = this.reportList.map((rxReport) => {
              return {
                  ...rxReport,
                  rxDate: this.commonService.getFormattedDateZone(rxReport?.rxDate),
                  lastMaskDate: this.commonService.getFormattedDateZone(rxReport?.lastMaskDate),
                  orderDate: this.commonService.getFormattedDateZone(rxReport?.orderDate),
                  rxExpiryDate: this.commonService.getFormattedDateZone(rxReport?.rxExpiryDate),
                  dob: this.commonService.formatDateDob(rxReport?.dob),
              };  
          });

          },
          (err) => {
            this.isReportTableLoaded = true;
          }
        );
    }
    // else{
    //   this.dExpiryFromDate = this.getCTStartOfMonth();
    //   this.dExpiryToDate = this.ctDateNow();
    //   this.showNotification('Date has not been valid date');
    // }
  }

  // Custom validator function to check if from date is greater than to date
  dateRangeValidator: ValidatorFn = (
    control: AbstractControl
  ): { [key: string]: any } | null => {
    const from = control.get('dtRxExpiryFrom')?.value;
    const to = control.get('dtRxExpiryTo')?.value;
    if (from && to && from > to) {
      return { dateRangeError: true };
    }
    return null;
  };

  //To initialize the Forms
  initializeForms() {
    this.reportForm = this.formBuilder.group(
      {
        chkAllOrders: new FormControl(''),
        drpPatient: new FormControl(''),
        txtPatientFilter: new FormControl(''),
        txtDateValue: new FormControl(''),
        txtDate: new FormControl(''),
        drpCSR: new FormControl(''),
        txtCSRFilter: new FormControl(''),
        drpOrderStatus: new FormControl(''),
        txtStatusFilter: new FormControl(''),
        drpPracticeManagement: new FormControl(''),
        txtPracticeFilter: new FormControl(''),
        dtRxExpiryFrom: new FormControl('', [Validators.required]),
        dtRxExpiryTo: new FormControl('', [Validators.required]),
      },
      { validator: this.dateRangeValidator }
    );
  }

  setDefaultDateValues(): void {
    this.reportForm.patchValue({
      dtRxExpiryFrom: this.getCTStartOfMonth(),
      dtRxExpiryTo: this.ctDateNow(),
    });
  }
  PractitionerChange(data: any) {
    this.gPractitioner = this.reportForm.value.drpPracticeManagement || [];
    this.loadRxTableList(null, null);
  }
  filterChange(event: boolean) {
    //Patient - True
    //Others - False
    this.filterValue = event;
    this.filterValue === true &&
      this.reportForm.patchValue({
        drpPatient: '',
      });

    this.filterValue === true && this.loadRxTableList(null, null);
    // this.filterValue === false && this.onDateRangeChange();
    this.filterValue === false &&
      this.loadRxTableList(this.getCTStartOfMonth(), this.ctDateNow());
  }
  onChartIdChange(e: any) {
    this.patientId = e.value;
    this.loadRxTableList(null, null);
  }

  ctDateNow(): Date {
    let usaTime = new Date().toLocaleString('en-US', {
      timeZone: 'America/Chicago',
    });
    const dateObj = new Date(usaTime);
    //   dateObj.setHours(0, 0, 0, 0);
    //   const year = dateObj.getFullYear();
    // const month = String(dateObj.getMonth() + 1).padStart(2, '0');
    // const day = String(dateObj.getDate()).padStart(2, '0');
    // const formattedDate = `${year}-${month}-${day}`;
    return dateObj;
  }

  getCTStartOfMonth(): Date {
    let usaTime = new Date().toLocaleString('en-US', {
      timeZone: 'America/Chicago',
    });
    const dateObj = new Date(usaTime);
    dateObj.setDate(1); // Set to the first day of the month
    dateObj.setHours(0, 0, 0, 0);
    //    const year = dateObj.getFullYear();
    // const month = String(dateObj.getMonth() + 1).padStart(2, '0');
    // const day = String(dateObj.getDate()).padStart(2, '0');
    // const formattedDate = `${year}-${month}-${day}`;

    return dateObj;
  }

  convert(str) {
    var date = new Date(str),
      mnth = ('0' + (date.getMonth() + 1)).slice(-2),
      day = ('0' + date.getDate()).slice(-2);
    return [date.getFullYear(), mnth, day].join('-');
  }

  onDateChange(event: MatDatepickerInputEvent<Date>): void {
    //       this.dExpiryFromDate=this.reportForm.value.dtRxExpiryFrom.toISOString().split('T')[0];
    //       // this.dExpiryToDate=event.value
    //       this.dExpiryToDate=this.reportForm.value.dtRxExpiryTo.toISOString().split('T')[0];
  

    // this.dExpiryFromDate = this.reportForm.value.dtRxExpiryFrom;
    // this.dExpiryToDate = this.reportForm.value.dtRxExpiryTo;

   

    if (
      this.reportForm.value.dtRxExpiryTo &&
      this.reportForm.value.dtRxExpiryFrom
    ) {
      this.loadRxTableList(
        this.reportForm.value.dtRxExpiryFrom,
        this.reportForm.value.dtRxExpiryTo
      );
    }
  }

  //On Date Range Change Method
  onDateRangeChange() {
    // const startDate = this.reportForm.get('txtDateValue').value;
    // const endDate = this.reportForm.get('txtDate').value;
    // this.dExpiryFromDate = this.datepipe.transform(startDate, 'MM/dd/yyyy');
    // this.dExpiryToDate = this.datepipe.transform(endDate, 'MM/dd/yyyy');
    // if (startDate != null && endDate != null) {
    //   this.loadOrderList(
    //     this.dExpiryFromDate,
    //     this.dExpiryToDate,
    //     this.orderForm.value.drpCSR,
    //     this.orderForm.value.drpOrderStatus,
    //     null
    //   );
    // }
  }
  searchPatient(value: any) {
    this.loadPatientChartNos(value);
  }
  //To Load the Chart No of the Patients
  loadPatientChartNos(searchParameter) {
    if (this.patientChartApiSubscription) {
      this.patientChartApiSubscription.unsubscribe();
    }
    this.patientChartApiSubscription = this.mmOrderService
      .getPatientChartNoV2BySSearchParams(searchParameter)
      .subscribe(
        (response) => {
          this.lstPatientChartNos = response;
          return this.lstPatientChartNos;
        },
        (err) => {}
      );
  }

  //Method to load the Practice Managements
  loadPracticeManagements() {
    const practiceList = this.practiceManagementService
      .getListV1(new PagedAndSortedResultRequestDto())
      .subscribe(
        (response) => {
          this.lstPractices = response;
          this.filteredPractices = this.reportForm
            .get('txtPracticeFilter')
            .valueChanges.pipe(
              startWith(''),
              map((value) =>
                this.lstPractices?.filter((option) =>
                  option?.practiceName
                    ?.toLowerCase()
                    ?.includes(value?.toLowerCase() ?? '')
                )
              )
            );
        },
        (err) => {}
      );
  }
//Method to Navigate to email print letter page
  navigateToEmailPage(){
this.router.navigate(['/RxEmailPrintLetter'])
  }
}