import { ListService } from '@abp/ng.core';
import { HttpErrorResponse } from '@angular/common/http';
import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSelect } from '@angular/material/select';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngxs/store';
import { DiagnosisCode10Service } from 'projects/patient/src/app/dropdown-proxy/dropdown-management/dropdowns/diagnosis-code10.service';
import { DiagnosisCode9Service } from 'projects/patient/src/app/dropdown-proxy/dropdown-management/dropdowns/diagnosis-code9.service';
import {
  CertificationConditionIndicatorDTO,
  ConditionCodeDTO,
  OrderMasterDropdownDTO
} from 'projects/patient/src/app/dropdown-proxy/dropdown-management/dropdowns/dto/order/models';
import {
  DiagnosisCode10DTO,
  DiagnosisCode9DTO,
  FacilityDTO,
  FunctionalAbilityDTO,
  GenderDTO,
  MarketingReferralContactDTO,
  MarketingReferralTypeDTO,
  MarketingRepTypeDTO,
  PatientMasterDropdownDTO,
  PractitionerDTO,
  ReferringProviderDTO,
  RenderingProviderDTO
} from 'projects/patient/src/app/dropdown-proxy/dropdown-management/dropdowns/dto/patient/models';
import { OrderMasterDropdownService } from 'projects/patient/src/app/dropdown-proxy/dropdown-management/dropdowns/master-dropdown-service/order-master-dropdown.service';
import { PatientMasterDropdownService } from 'projects/patient/src/app/dropdown-proxy/dropdown-management/dropdowns/master-dropdown-service/patient-master-dropdown.service';
import { OrderDropdowns } from 'projects/patient/src/app/dropdown-proxy/dropdown-management/dropdowns/order-dropdowns.enum';
import { PatientDropdowns } from 'projects/patient/src/app/dropdown-proxy/dropdown-management/dropdowns/patient-dropdowns.enum';
import { PatientDoctorComponent } from 'projects/patient/src/app/patient-doctor/patient-doctor.component';
import { PatientIcd10Component } from 'projects/patient/src/app/patient-icd10/patient-icd10.component';
import { PatientIcd9Component } from 'projects/patient/src/app/patient-icd9/patient-icd9.component';
import { PatientService } from 'projects/patient/src/app/patient-proxy/patient';
import { DoctorService } from 'projects/patient/src/app/patient-proxy/patient/doctor.service';
import {
  ClinicalDTO,
  PatientDTO
} from 'projects/patient/src/app/patient-proxy/patient/dto';
import {
  OrderBillingStatus
} from 'projects/shared/src/app/enums/allenums.enum';
import { NumberValidators } from 'projects/shared/src/app/validations/number-validator';
import { Observable, Subscription } from 'rxjs';
import { map, startWith} from 'rxjs/operators';
import Swal from 'sweetalert2';
import { MasterProviderService } from '../order-proxy/order-management/inventory/master-provider.service';
import { SaleorderService } from '../order-proxy/order-management/order';
import {
  OrderClinicalDTO,
  SaleorderDTO
} from '../order-proxy/order-management/order/dto';
import { updateOrder } from '../order-store/order.action';

@Component({
  selector: 'app-order-clinical',
  templateUrl: './order-clinical.component.html',
  styleUrls: ['./order-clinical.component.scss'],
  providers: [ListService],
})
export class OrderClinicalComponent implements OnInit, OnDestroy {
  //declaration
  clinicalForm: FormGroup;
  drpDoctor: doctorInterface[] = [];
  @Input() orderId: string = '';
  drpRenderType: RenderingProviderDTO[] = [];
  drpReferring: ReferringProviderDTO[] = [];
  //drpMarketingRef: MarketingReferralDTO[] = [];
  drpCertification: CertificationConditionIndicatorDTO[] = [];
  drpCondition: ConditionCodeDTO[] = [];
  drpFacility: FacilityDTO[] = [];
  //drpDiagnosis10: DiagnosisCodeIcd10DTO[] = [];
  //drpDiagnosis9: DiagnosisCodeIcd9DTO[] = [];
  @Input() patientId: string = '';
  orderStatus: boolean = true;
  drpRenderingTypeLoop: RenderingProviderDTO[] = [];
  drpRefringtypeLoop: ReferringProviderDTO[] = [];
  drpMarketingtypeLoop: MarketingReferralTypeDTO[] = [];
  drpGenderLoop: GenderDTO[] = [];
  drpFunctonal: FunctionalAbilityDTO[] = [];
  drpMarketing: MarketingRepTypeDTO[] = [];
  drpPractioner: PractitionerDTO[] = [];
  drpContact: MarketingReferralContactDTO[] = [];
  viewOrEdit: string | null;
  isIcd9Shown: boolean = false;
  isIcd10Shown: boolean = false;
  icd9Event: string = '';
  icd10Event1: string = '';
  icd10Event2: string = '';
  icd10Event3: string = '';
  renderingtype: string;
  renderFacility: string;
  referringType: string;
  referringFacility: string;
  markettingRefType: string;
  marketingFacility: string;
  certification: string;
  conditionCode: string;
  saveButtonHide: boolean;
  isShowSpinner: boolean = false;
  provider: string;
  subscription$: Subscription[] = [];
  @Output() saveClinicalFormState: EventEmitter<any> = new EventEmitter<any>();
  @Output() nextTabMoveOnSaveEmitter: EventEmitter<string> =
    new EventEmitter<string>();

  constructor(
    private doctorService: DoctorService,
    public dialog: MatDialog,
    public list: ListService,
    private formBuilder: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private numberValidators: NumberValidators,
    private orderDropdownService: OrderMasterDropdownService,
    private patientDropdownService: PatientMasterDropdownService,
    private store: Store,
    private diagnosis9: DiagnosisCode9Service,
    private diagnosis10: DiagnosisCode10Service,
    private providerList: MasterProviderService,
    private saleOrderService: SaleorderService,
    private patientService: PatientService
  ) { }

  ngOnInit(): void {
    this.activatedRoute.paramMap.subscribe(
      (response) => {
        this.viewOrEdit = response.get('editOrView');
      },
      (err) => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      }
    );
    this.clinicalForm = this.formBuilder.group({
      clinicalGeneral: new FormGroup({
        txtDOD: new FormControl(''),
        //txtHeight: new FormControl(""),
        txtGeneralHeightInches: new FormControl('', [
          Validators.min(0),
          Validators.max(11),
        ]),
        txtGeneralHeightFeet: new FormControl(''),
        txtWeight: new FormControl('', [this.numberValidators.isNumberCheck]),
        txtPractioner: new FormControl(null),
        txtPractitionerFilter: new FormControl(''),
      }),
      clinicalOrder: new FormGroup({
        txtOrderingDoctor: new FormControl(null),
        txtMarketingRep: new FormControl(null),
        txtOrdSearchFilter: new FormControl(''),
        txtMarRepSearchFilter: new FormControl(''),
      }),
      clinicalRender: new FormGroup({
        drpRenderType: new FormControl(null),
        txtRenderDoctor: new FormControl(null),
        txtRenderFacility: new FormControl(''),
        txtRenDoctorFilter: new FormControl(null),
        txtProvider: new FormControl("")
      }),
      clinicalRefer: new FormGroup({
        drpReferring: new FormControl(null),
        txtReferringDoctor: new FormControl(null),
        txtReferringFacility: new FormControl(''),
        txtRefDoctorFilter: new FormControl(''),
      }),
      clinicalMarketing: new FormGroup({
        drpMarketingRef: new FormControl(null),
        txtMarketingDoctor: new FormControl(null),
        txtMarketingFacility: new FormControl(''),
        txtMarDoctorFilter: new FormControl(''),
        txtProvider: new FormControl('')
      }),
      clinicalDiagnosis: new FormGroup({
        txtDiagnosis9: new FormControl(''),
        txtIcd10Filter1: new FormControl(null),
        txtIcd10Filter2: new FormControl(null),
        txtIcd10Filter3: new FormControl(null),
        txtDiagnosis10Id1: new FormControl(null, [Validators.required]),
        txtDiagnosis10Id2: new FormControl(null),
        txtDiagnosis10Id3: new FormControl(null),
        txtIcd9Filter: new FormControl(null),
      }),
      clinicalEpsdt: new FormGroup({
        drpCertification: new FormControl(null),
        drpCondition: new FormControl(null),
      }),
    });
    if (this.viewOrEdit == 'false') {
      this.clinicalForm.disable();
    } else {
      this.clinicalForm.enable();
    }
    this.getDropdown();
    this.getOrderByid();
    if (this.clinicalForm.invalid || this.clinicalForm.dirty) {
      this.saveClinicalFormState.emit(this.clinicalForm);
    }
    this.getProviderDropdown();
  }
  // clinical view and edit //
  getOrderByid() {
    this.orderId = this.orderId ?? '';
    if (this.orderId != '') {
      const saleOrderService = this.saleOrderService.get(this.orderId).subscribe(
        (response) => {
          const selectedOrder: SaleorderDTO = response;
          const orderBillstatus: [string, OrderBillingStatus][] = Object.entries(OrderBillingStatus);
          this.orderStatus = orderBillstatus?.some((s) => (s[1] ?? '') === selectedOrder?.orderBilling?.status);
          selectedOrder.clinicals = selectedOrder?.clinicals ?? [];
          const clinicalDetail: OrderClinicalDTO = selectedOrder?.clinicals[0] ?? null;
          if (selectedOrder?.clinicals && selectedOrder?.clinicals[0]) {
            if (clinicalDetail?.height != 0) {
              this.str = String(clinicalDetail?.height ?? 0)?.split('.', 2);
              this.clinicalForm.patchValue({
                clinicalGeneral: {
                  txtGeneralHeightFeet: ((clinicalDetail?.height === 0) ? '' : (this.str[0] ?? "")),
                  txtGeneralHeightInches: ((clinicalDetail?.height === 0) ? '' : (this.str[1] ?? "")),
                }
              })
            }
            this.clinicalForm.patchValue({
              clinicalGeneral: {
                txtDOD: clinicalDetail?.dod == null ? '' : clinicalDetail?.dod,
                txtWeight: clinicalDetail?.weight,
                txtPractioner: clinicalDetail?.practitionerId,
              },
              clinicalOrder: {
                txtOrderingDoctor: clinicalDetail?.orderingDoctorId,
                txtMarketingRep: clinicalDetail?.marketingRepTypeId,
              },
              clinicalRender: {
                drpRenderType: clinicalDetail?.renderingProviderTypeId,
                txtRenderDoctor: clinicalDetail?.renderingProviderDoctorId,
                txtRenderFacility: clinicalDetail?.renderingProviderFacilityId,
              },
              clinicalRefer: {
                drpReferring: clinicalDetail?.referringProviderTypeId,
                txtReferringDoctor: clinicalDetail?.referringProviderDoctorId,
                txtReferringFacility:
                  clinicalDetail?.renderingProviderFacilityId,
              },
              clinicalMarketing: {
                drpMarketingRef: clinicalDetail?.marketingReferralTypeId,
                txtMarketingDoctor: clinicalDetail?.marketingReferralDoctorId,
                txtMarketingFacility:
                  clinicalDetail?.marketingReferralFacilityId,
              },
              clinicalDiagnosis: {
                txtDiagnosis9: clinicalDetail?.diagnosisCode9 ?? [],
                txtDiagnosis10Id1: clinicalDetail?.diagnosisCode10 ?? [],
              },
              clinicalEpsdt: {
                drpCertification:
                  clinicalDetail?.certificationConditionIndictorId,
                drpCondition: clinicalDetail?.conditionCodeId,
              },
            });

            this.markettingRefType = clinicalDetail?.marketingReferralTypeId ?? "";
            this.renderFacility = clinicalDetail?.renderingProviderFacilityId ?? "";
            this.referringFacility = clinicalDetail?.referringProviderFacilityId ?? "";
            this.MarketingDoctor = clinicalDetail?.marketingReferralDoctorId ?? "";
            this.marketingFacility = clinicalDetail?.marketingReferralFacilityId ?? "";
            this.certification = clinicalDetail?.certificationConditionIndictorId ?? "";
            this.conditionCode = clinicalDetail?.conditionCodeId ?? "";
            this.OrderingDoctor = clinicalDetail?.orderingDoctorId ?? "";
            this.Practitioner = clinicalDetail?.practitionerId ?? "";
            this.MarRepDoctor = clinicalDetail?.marketingRepTypeId ?? "";
            this.MarketingDoctor = clinicalDetail?.marketingReferralDoctorId ?? "";
            this.ReferringDoctor = clinicalDetail?.referringProviderDoctorId ?? "";
            this.RenderingDoctor = clinicalDetail?.renderingProviderDoctorId ?? "";
            this.Icd9 = clinicalDetail?.diagnosisCode9 ?? [];
            this.txt1Icd10 = clinicalDetail?.diagnosisCode10 ?? [];
            this.referringType = clinicalDetail?.referringProviderTypeId ?? "";
            this.referringFacility = clinicalDetail?.referringProviderFacilityId ?? "";
            this.renderingtype = clinicalDetail?.renderingProviderTypeId ?? "";

          } else {
            this.patchDummyData(selectedOrder?.patients);
          }
        },
        (err) => {
          const data: HttpErrorResponse = err;
          Swal.fire({
            icon: 'info',
            text: data?.error?.error?.message,
          });
        });
      this.subscription$.push(saleOrderService);
    }
  }
  patchDummyData(orderPatientDetail: PatientDTO) {

    this.patientService.get(this.patientId).subscribe(response => {
      const clinicalDetail: ClinicalDTO = response?.clinicals;
      if (clinicalDetail !== null && clinicalDetail !== undefined && clinicalDetail?.height != 0) {
        this.str = String(clinicalDetail?.height ?? 0).split('.', 2) ?? [];
        this.clinicalForm.patchValue({
          clinicalGeneral: {
            txtGeneralHeightFeet: (clinicalDetail?.height === 0) ? '' : (this.str[0] ?? ""),
            txtGeneralHeightInches: (clinicalDetail?.height === 0) ? '' : (this.str[1] ?? ""),
          }
        })
      }
      this.clinicalForm.patchValue({
        clinicalGeneral: {
          txtDOD: clinicalDetail?.dod == null ? '' : clinicalDetail?.dod,
          txtWeight: clinicalDetail?.weight === 0 ? '' : clinicalDetail?.weight,
          txtPractioner: clinicalDetail?.generalPractitioner === '' ? null : clinicalDetail?.generalPractitioner,
        },
        clinicalOrder: {
          txtOrderingDoctor: clinicalDetail?.orderingDoctor === '' ? null : clinicalDetail?.orderingDoctor,
          txtMarketingRep: clinicalDetail?.marketingRepType,
        },
        clinicalRender: {
          drpRenderType: clinicalDetail?.renderingProviderType,
          txtRenderDoctor: clinicalDetail?.renderingProviderDoctor === '' ? null : clinicalDetail?.renderingProviderDoctor,
          txtRenderFacility: clinicalDetail?.renderingProviderFacility,
        },
        clinicalRefer: {
          drpReferring: clinicalDetail?.referringProviderType,
          txtReferringDoctor: clinicalDetail?.referringProviderDoctor === '' ? null : clinicalDetail?.referringProviderDoctor,
          txtReferringFacility: clinicalDetail?.referringProviderFacility,
        },
        clinicalMarketing: {
          drpMarketingRef: clinicalDetail?.marketingReferralType,
          txtMarketingDoctor: clinicalDetail?.marketingReferralDoctor === '' ? null : clinicalDetail?.marketingReferralDoctor,
          txtMarketingFacility: clinicalDetail?.marketingReferralFacility,
        },
        clinicalDiagnosis: {
          txtDiagnosis9: clinicalDetail?.diagnosisCode9 === [] ? null : clinicalDetail?.diagnosisCode9,
          txtDiagnosis10Id1: clinicalDetail?.diagnosisCode10 === [] ? null : clinicalDetail?.diagnosisCode10,
        },
      });
    },
      (err) => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      });


  }
  ngOnDestroy(): void {
    this.subscription$.forEach((sub) => {
      sub && sub?.unsubscribe();
    });
  }

  numberOnly(event): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  onIcd9Change(event) {
    this.icd9Event = event;
    if (
      (event == undefined || event == null || event == '') &&
      (this.icd10Event1 == undefined ||
        this.icd10Event1 == null ||
        this.icd10Event1 == '') &&
      (this.icd10Event2 == undefined ||
        this.icd10Event2 == null ||
        this.icd10Event2 == '') &&
      (this.icd10Event3 == undefined ||
        this.icd10Event3 == null ||
        this.icd10Event3 == '')
    ) {
      this.isIcd9Shown = false;
      this.isIcd10Shown = false;
    } else {
      this.isIcd9Shown = true;
      this.isIcd10Shown = true;
    }
  }
  onIcd10Change1(event) {
    this.icd10Event1 = event;
    if (
      (event == undefined || event == null || event == '') &&
      (this.icd9Event == undefined ||
        this.icd9Event == null ||
        this.icd9Event == '') &&
      (this.icd10Event2 == undefined ||
        this.icd10Event2 == null ||
        this.icd10Event2 == '') &&
      (this.icd10Event3 == undefined ||
        this.icd10Event3 == null ||
        this.icd10Event3 == '')
    ) {
      this.isIcd10Shown = false;
      this.isIcd9Shown = false;
    } else {
      this.isIcd10Shown = true;
      this.isIcd9Shown = true;
    }
  }
  onIcd10Change2(event) {
    this.icd10Event2 = event;
    if (
      (event == undefined || event == null || event == '') &&
      (this.icd9Event == undefined ||
        this.icd9Event == null ||
        this.icd9Event == '') &&
      (this.icd10Event1 == undefined ||
        this.icd10Event1 == null ||
        this.icd10Event1 == '') &&
      (this.icd10Event3 == undefined ||
        this.icd10Event3 == null ||
        this.icd10Event3 == '')
    ) {
      this.isIcd10Shown = false;
      this.isIcd9Shown = false;
    } else {
      this.isIcd10Shown = true;
      this.isIcd9Shown = true;
    }
  }
  onIcd10Change3(event) {
    this.icd10Event3 = event;
    if (
      (event == undefined || event == null || event == '') &&
      (this.icd9Event == undefined || this.icd9Event == '') &&
      (this.icd10Event1 == undefined || this.icd10Event1 == '') &&
      (this.icd10Event2 == undefined || this.icd10Event2 == '')
    ) {
      this.isIcd10Shown = false;
      this.isIcd9Shown = false;
    } else {
      this.isIcd10Shown = true;
      this.isIcd9Shown = true;
    }
  }



  drpDiagnosisICD9: {
    diagnosisCode: string | undefined;
    diagnosisCode9ShortCodeId: string | undefined;
    diagnosisCodeTypeData: string | undefined;
    diagnosisCodeTypeDataId: string | undefined;
    id: string | undefined;
    creationTime: string | Date | undefined;
    creatorId: string | undefined;
    isActive: number;
    isExist: number;
  }[];
  drpDiagnosisICD10: {
    diagnosisCode: string | undefined;
    diagnosisCode10ShortCodeId: string | undefined;
    diagnosisCodeTypeData: string;
    diagnosisCodeTypeDataId: string | undefined;
    id: string | undefined;
    creationTime: string | Date | undefined;
    creatorId: string | undefined;
    isActive: number;
    isExist: number;
  }[];

  public filteredIcd9Types: Observable<any[]> | undefined;
  public filtered1Icd10Types: Observable<any[]> | undefined;
  public filtered2Icd10Types: Observable<any[]> | undefined;
  public filtered3Icd10Types: Observable<any[]> | undefined;
  public filteredRefDoctorTypes: Observable<any[]> | undefined;

  // Dropdown data //
  getDropdown() {
    let orderDropdownInput: OrderDropdowns[];
    orderDropdownInput = [
      //OrderDropdowns.RenderingProviders,
      //OrderDropdowns.ReferringProviders,
      //OrderDropdowns.MarketingReferrals,
      OrderDropdowns.CertificationConditionIndicators,
      OrderDropdowns.ConditionCodes,
      // OrderDropdowns.DiagnosisCodeIcd10s
    ];
    this.orderDropdownService
      .getOrderDropdownsByInput(orderDropdownInput)
      .subscribe(
        (stateResponse) => {
          let response: OrderMasterDropdownDTO = stateResponse;
          //this.drpRenderType = response.renderingProviders;
          //this.drpReferring = response.referringProviders;
          // this.drpMarketingRef = response.marketingReferrals;
          this.drpCertification = response.certificationConditionIndicators;
          this.drpCondition = response.conditionCodes;
          // this.drpFacility = response.facilities;
          // this.drpDiagnosis10 = response.diagnosisCodeIcd10s;
        },
        (err) => {
          const data: HttpErrorResponse = err;
          Swal.fire({
            icon: 'info',
            text: data?.error?.error?.message,
          });
        }
      );
    let patientDropdownInput: PatientDropdowns[];
    patientDropdownInput = [
      PatientDropdowns.RenderingProviders,
      PatientDropdowns.ReferringProviders,
      PatientDropdowns.MarketingReferralTypes,
      PatientDropdowns.Genders,
      PatientDropdowns.FuntionalAbilities,
      PatientDropdowns.MarketingRepTypes,
      PatientDropdowns.Practitioners,
      PatientDropdowns.Facilities,
      PatientDropdowns.MarketingReferralContacts,
    ];
    this.patientDropdownService
      .getPatientDropdownsByInput(patientDropdownInput)
      .subscribe(
        (stateResponse) => {
          let response: PatientMasterDropdownDTO = stateResponse;
          this.drpRenderingTypeLoop = response.renderingProviders;
          this.drpRefringtypeLoop = response.referringProviders;
          this.drpMarketingtypeLoop = response.marketingReferralTypes;
          this.drpGenderLoop = response.genders;
          this.drpFunctonal = response.functionalAbilities;
          this.drpMarketing = response.marketingRepTypes;
          this.drpPractioner = response.practitioners;
          this.drpFacility = response.facilities;
          this.drpContact = response.marketingReferralContacts;
        },
        (err) => {
          const data: HttpErrorResponse = err;
          Swal.fire({
            icon: 'info',
            text: data?.error?.error?.message,
          });
        }
      );

    this.doctorDropdown();
    this.icd9Dropdown();
    this.icd10Dropdown();
  }

  drpProviders: any[] = [];
  //! provider getlist
  getProviderDropdown() {
    const providerList = (query: any) => this.providerList.getList(query);
    const providerDropdown = this.list.hookToQuery(providerList).subscribe(response => {
      response?.items?.forEach(element => {
        this.drpProviders.push({
          id: element.id,
          name: element?.fullName,
          shortCodeId: element?.shortCodeId,
          fullName: element?.fullName,
          npi: element?.npi
        });
      });
    })
    this.subscription$.push(providerDropdown);
  }

  icd9Dropdown() {
    this.drpDiagnosisICD9 = [];
    const itemList = (query: any) => this.diagnosis9.getList(query);
    const itemDropdown = this.list.hookToQuery(itemList).subscribe(
      (response) => {
        response?.items?.forEach((element: DiagnosisCode9DTO) => {
          let resultString =
            element.diagnosisCode + ' ' + element.diagnosisCodeTypeData;
          this.drpDiagnosisICD9.push({
            diagnosisCode: element.diagnosisCode,
            diagnosisCodeTypeData: resultString,
            diagnosisCodeTypeDataId: element.diagnosisCodeTypeDataId,
            diagnosisCode9ShortCodeId: element.diagnosisCode9ShortCodeId,
            id: element.id,
            creationTime: element.creationTime,
            creatorId: element.creatorId,
            isActive: element.isActive,
            isExist: element.isExist,
          });
          this.filteredIcd9Types = this.clinicalForm
            .get('clinicalDiagnosis.txtIcd9Filter')
            ?.valueChanges.pipe(
              startWith(''),
              map((value) =>
                this.drpDiagnosisICD9?.filter((option) =>
                  option?.diagnosisCodeTypeData
                    ?.toLowerCase()
                    ?.includes(value?.toLowerCase() ?? '')
                )
              )
            );
        });
      },
      (err) => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      }
    );
    this.subscription$.push(itemDropdown);
  }

  icd10Dropdown() {
    this.drpDiagnosisICD10 = [];
    const itemList = (query: any) => this.diagnosis10.getList(query);
    const itemTableList = this.list.hookToQuery(itemList).subscribe(
      (response) => {
        response?.items?.forEach((element: DiagnosisCode10DTO) => {
          let resultString =
            element.diagnosisCode + ' ' + element.diagnosisCodeTypeData;
          this.drpDiagnosisICD10.push({
            diagnosisCode: element.diagnosisCode,
            diagnosisCodeTypeData: resultString,
            diagnosisCodeTypeDataId: element.diagnosisCodeTypeDataId,
            diagnosisCode10ShortCodeId: element.diagnosisCode10ShortCodeId,
            id: element.id,
            creationTime: element.creationTime,
            creatorId: element.creatorId,
            isActive: element.isActive,
            isExist: element.isExist,
          });
          this.filtered1Icd10Types = this.clinicalForm
            .get('clinicalDiagnosis.txtIcd10Filter1')
            ?.valueChanges.pipe(
              startWith(''),
              map((value) =>
                this.drpDiagnosisICD10.filter((option) =>
                  option?.diagnosisCodeTypeData
                    ?.toLowerCase()
                    ?.includes(value?.toLowerCase() ?? '')
                )
              )
            );
          this.filtered2Icd10Types = this.clinicalForm
            .get('clinicalDiagnosis.txtIcd10Filter2')
            ?.valueChanges.pipe(
              startWith(''),
              map((value) =>
                this.drpDiagnosisICD10.filter((option) =>
                  option?.diagnosisCodeTypeData
                    ?.toLowerCase()
                    ?.includes(value?.toLowerCase() ?? '')
                )
              )
            );
          this.filtered3Icd10Types = this.clinicalForm
            .get('clinicalDiagnosis.txtIcd10Filter3')
            ?.valueChanges.pipe(
              startWith(''),
              map((value) =>
                this.drpDiagnosisICD10.filter((option) =>
                  option?.diagnosisCodeTypeData
                    ?.toLowerCase()
                    ?.includes(value?.toLowerCase() ?? '')
                )
              )
            );
        });
      },
      (err) => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      }
    );
    this.subscription$.push(itemTableList);
  }
  public filteredOrderingDoctorTypes: Observable<doctorInterface[]> | undefined;
  public filteredMarRepDoctorTypes: Observable<doctorInterface[]> | undefined;
  public filteredRenDoctorTypes: Observable<doctorInterface[]> | undefined;
  public filteredMarDoctorTypes: Observable<doctorInterface[]> | undefined;
  public filteredPractitioner: Observable<doctorInterface[]> | undefined;
  @ViewChild('singleSelect') singleSelect: MatSelect;
  doctorDropdown(): void {
    this.drpDoctor = [];
    // this.organizationUnitId = localStorage.getItem('initialOrganizationUnitId');

    const itemList = (query: any) => this.doctorService.getList(query);
    const itemGetList = this.list.hookToQuery(itemList).subscribe(
      (response) => {
        this.drpDoctor = [];

        response?.items?.forEach((element) => {
          //
          this.drpDoctor?.push({
            name:
              element['npiNumber'] +
              ' / ' +
              element.firstName +
              ' ' +
              element.middleName +
              ' ' +
              element.lastName,
            id: element.id,
          });
        });
        this.filteredOrderingDoctorTypes = this.clinicalForm
          .get('clinicalOrder.txtOrdSearchFilter')
          ?.valueChanges.pipe(
            startWith(''),
            map((value) =>
              this.drpDoctor?.filter((option) =>
                option?.name
                  ?.toLowerCase()
                  ?.includes(value?.toLowerCase() ?? '')
              )
            )
          );

        this.filteredPractitioner = this.clinicalForm
          .get('clinicalGeneral.txtPractitionerFilter')
          ?.valueChanges.pipe(
            startWith(''),
            map((value) =>
              this.drpDoctor?.filter((option) =>
                option?.name
                  ?.toLowerCase()
                  ?.includes(value?.toLowerCase() ?? '')
              )
            )
          );
        this.filteredMarRepDoctorTypes = this.clinicalForm
          .get('clinicalOrder.txtMarRepSearchFilter')
          ?.valueChanges.pipe(
            startWith(''),
            map((value) =>
              this.drpDoctor?.filter((option) =>
                option?.name
                  ?.toLowerCase()
                  ?.includes(value?.toLowerCase() ?? '')
              )
            )
          );
        this.filteredRenDoctorTypes = this.clinicalForm
          .get('clinicalRender.txtRenDoctorFilter')
          ?.valueChanges.pipe(
            startWith(''),
            map((value) =>
              this.drpDoctor?.filter((option) =>
                option?.name
                  ?.toLowerCase()
                  ?.includes(value?.toLowerCase() ?? '')
              )
            )
          );
        this.filteredRefDoctorTypes = this.clinicalForm
          .get('clinicalRefer.txtRefDoctorFilter')
          ?.valueChanges.pipe(
            startWith(''),
            map((value) =>
              this.drpDoctor?.filter((option) =>
                option?.name
                  ?.toLowerCase()
                  ?.includes(value?.toLowerCase() ?? '')
              )
            )
          );
        this.filteredMarDoctorTypes = this.clinicalForm
          .get('clinicalMarketing.txtMarDoctorFilter')
          ?.valueChanges.pipe(
            startWith(''),
            map((value) =>
              this.drpDoctor?.filter((option) =>
                option?.name
                  ?.toLowerCase()
                  ?.includes(value?.toLowerCase() ?? '')
              )
            )
          );
      },
      (err) => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      },
      () => { }
    );
    this.subscription$.push(itemGetList);
    // this.doctorService.getList(new PagedAndSortedResultRequestDto).subscribe(response => {
    //
    //   response?.items?.forEach(element => {
    //
    //     this.drpDoctor?.push({
    //       name: element['npi'] + ' / ' + element.firstName + " " + element.middleName + " " + element.lastName,
    //       id: element.id
    //     });
    //   });
    // }, () => {
    // });
  }

  height: string = '';
  getPatientDetailsForSave() {
    if (
      (this.clinicalForm.value.clinicalGeneral.txtGeneralHeightFeet == '' ||
        this.clinicalForm.value.clinicalGeneral.txtGeneralHeightFeet == null ||
        this.clinicalForm.value.clinicalGeneral.txtGeneralHeightFeet ==
        undefined) &&
      (this.clinicalForm.value.clinicalGeneral.txtGeneralHeightInches == '' ||
        this.clinicalForm.value.clinicalGeneral.txtGeneralHeightInches ==
        undefined ||
        this.clinicalForm.value.clinicalGeneral.txtGeneralHeightInches == null)
    ) {
      this.height = '0';
    } else {
      this.height =
        this.clinicalForm.value.clinicalGeneral.txtGeneralHeightFeet +
        '.' +
        this.clinicalForm.value.clinicalGeneral.txtGeneralHeightInches;
      parseFloat(this.height);
    }
    // let patientData: PatientDTO;
    let clinicalOrderDetails: SaleorderDTO;
    // let patientDetails: PatientDTO;
    // let clinicalDetails: ClinicalDTO;
    // this.store.dispatch(new getPatient(this.patientId)).subscribe(response => {
    //   patientData = response.patientState.selectedPatient;
    //   patientDetails = response.patientState.selectedPatient;
    //   patientData = { ...patientData }
    //   clinicalDetails = patientData.clinicals;
    //   clinicalDetails = {
    //     id: this.patientId,
    //     height: this.height == "0" ? 0 : parseFloat(this.height),
    //     weight: +(this.clinicalForm.value.clinicalGeneral.txtWeight) | 0,
    //     dod: this.clinicalForm.value.clinicalGeneral.txtDOD == null ? "" : this.clinicalForm.value.clinicalGeneral.txtDOD,
    //     generalPractitioner: this.clinicalForm.value.clinicalGeneral.txtPractioner,
    //     renderingProviderType: this.clinicalForm?.get("clinicalRender.drpRenderType").value,
    //     renderingProviderDoctor: this.clinicalForm.value.clinicalRender.txtRenderDoctor,
    //     renderingProviderFacility: this.clinicalForm.value.clinicalRender.txtRenderFacility,
    //     referringProviderType: this.clinicalForm?.get("clinicalRefer.drpReferring").value,
    //     referringProviderDoctor: this.clinicalForm.value.clinicalRefer.txtReferringDoctor,
    //     referringProviderFacility: this.clinicalForm.value.clinicalRefer.txtReferringFacility,
    //     orderingDoctor: this.clinicalForm.value.clinicalOrder.txtOrderingDoctor,

    //     marketingRepType: this.clinicalForm.value.clinicalOrder.txtMarketingRep,
    //     marketingReferralType: this.clinicalForm?.get("clinicalMarketing.drpMarketingRef").value,
    //     marketingReferralDoctor: this.clinicalForm.value.clinicalMarketing.txtMarketingDoctor,
    //     marketingReferralFacility: this.clinicalForm.value.clinicalMarketing.txtMarketingFacility,
    //     diagnosisCode9: this.clinicalForm.value.clinicalDiagnosis.txtDiagnosis9 === "" ? [] : this.clinicalForm.value.clinicalDiagnosis.txtDiagnosis9,
    //     diagnosisCode10: this.clinicalForm.value.clinicalDiagnosis.txtDiagnosis10Id1,
    //     // diagnosisCode10Id2: this.clinicalForm.value.clinicalDiagnosis.txtDiagnosis10Id2,
    //     // diagnosisCode10Id3: this.clinicalForm.value.clinicalDiagnosis.txtDiagnosis10Id3,
    //     infectiousConditon: clinicalDetails.infectiousConditon,
    //     airborne: clinicalDetails.airborne,
    //     contact: clinicalDetails.contact,
    //     droplet: clinicalDetails.droplet,
    //     primaryDoctor: clinicalDetails.primaryDoctor,
    //     genderType: clinicalDetails.genderType,
    //     functionalAbility: clinicalDetails.functionalAbility,
    //     marketingReferralContact: clinicalDetails.marketingReferralContact,
    //     appointmentDate: clinicalDetails.appointmentDate,
    //     branch: clinicalDetails.branch,
    //     description: clinicalDetails.description,
    //     practitioner: clinicalDetails.practitioner,
    //     status: clinicalDetails.status

    //   }
    //   patientDetails = {
    //     organizationUnitId: patientDetails?.organizationUnitId,
    //     id: patientDetails?.id,
    //     tenantId: patientDetails?.tenantId,
    //     personals: patientDetails?.personals,
    //     addresses: patientDetails?.addresses,
    //     contacts: patientDetails?.contacts,
    //     clinicals: clinicalDetails,
    //     insurances: patientDetails?.insurances,
    //     notes: patientDetails?.notes,
    //     policies: patientDetails?.policies,
    //     defaultPatientId: patientDetails?.defaultPatientId,
    //     insuranceVerifications: patientDetails?.insuranceVerifications,
    //     authorizations: patientDetails?.authorizations,
    //     patientDocuments: patientDetails?.patientDocuments,
    //     isAutoIntake: patientDetails?.isAutoIntake,
    //     autoIntakeDocumentDetails: patientDetails?.autoIntakeDocumentDetails,
    //     chartId: response.chartId,
    //     billingDetails: patientDetails?.billingDetails,
    //     patientLockDetails: patientDetails?.patientLockDetails,
    //     isSorted: patientDetails?.isSorted

    //   }
    //   setTimeout(() => {
    //     this.store.dispatch(new updatePatient(this.patientId, patientDetails)).subscribe(response => {
    //       this.store.dispatch(new getPatient(this.patientId)).subscribe(response => {
    //         patientData = response.patientState.selectedPatient;
    //         patientData = { ...patientDetails };
    //         setTimeout(() => {
    this.saleOrderService.get(this.orderId).subscribe(
      // this.store.dispatch(new getOrder(this.orderId)).subscribe(
      (orderResponse) => {
        clinicalOrderDetails = { ...orderResponse };
        this.SaveClinical(clinicalOrderDetails);
      },
      (err) => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      },
      () => { }
    );
    //         }, 500);
    //       }, err => {
    //         const data: HttpErrorResponse = err;
    //         Swal.fire({
    //           icon: 'error',
    //           text: data?.error?.error?.message,
    //         });
    //       })
    //     }, err => {
    //       const data: HttpErrorResponse = err;
    //       Swal.fire({
    //         icon: 'error',
    //         text: data?.error?.error?.message,
    //       });
    //     });
    //   }, 300);
    // }, err => {
    //   const data: HttpErrorResponse = err;
    //   Swal.fire({
    //     icon: 'error',
    //     text: data?.error?.error?.message,
    //   });
    // });
  }
  // orderId: string = "f1c3304b-524f-eaf3-9169-39fa1b9daa37";
  // getOrderDetailsForSave() {
  //   let itemDetails: SaleorderDTO;
  //   if (this.orderId !== "" || this.orderId !== undefined || this.orderId !== null) {
  //     this.store.dispatch(new getOrder(this.orderId)).subscribe(response => {
  //       itemDetails = response.patientState.selectedPatient;
  //       this.SaveClinical();
  //     }, () => {
  //     });
  //   }
  // }

  // save clinical //
  organizationUnitId = null;
  SaveClinical(response: SaleorderDTO) {
    this.isShowSpinner = true;
    this.saveButtonHide = true;
    if (
      (this.clinicalForm.value.clinicalGeneral.txtGeneralHeightFeet == '' ||
        this.clinicalForm.value.clinicalGeneral.txtGeneralHeightFeet == null ||
        this.clinicalForm.value.clinicalGeneral.txtGeneralHeightFeet ==
        undefined) &&
      (this.clinicalForm.value.clinicalGeneral.txtGeneralHeightInches == '' ||
        this.clinicalForm.value.clinicalGeneral.txtGeneralHeightInches ==
        undefined ||
        this.clinicalForm.value.clinicalGeneral.txtGeneralHeightInches == null)
    ) {
      this.height = '0';
    } else {
      this.height =
        this.clinicalForm.value.clinicalGeneral.txtGeneralHeightFeet +
        '.' +
        this.clinicalForm.value.clinicalGeneral.txtGeneralHeightInches;

      parseFloat(this.height);
    }
    // this.organizationUnitId = localStorage.getItem('initialOrganizationUnitId');
    let clinicalDetails: SaleorderDTO;
    clinicalDetails = {
      orders: response.orders,
      insurances: response.insurances,
      defaultClaimId: response?.defaultClaimId,
      clinicals: [
        {
          dod: this.clinicalForm?.get('clinicalGeneral.txtDOD').value,
          height: this.height == '0' ? 0 : parseFloat(this.height),
          weight: +this.clinicalForm?.get('clinicalGeneral.txtWeight').value | 0,
          practitionerId: this.clinicalForm?.get('clinicalGeneral.txtPractioner')
            .value,
          renderingProviderTypeId: this.clinicalForm.get(
            'clinicalRender.drpRenderType'
          ).value,
          renderingProviderDoctorId: this.clinicalForm.get(
            'clinicalRender.txtRenderDoctor'
          ).value,
          renderingProviderFacilityId: this.clinicalForm.get(
            'clinicalRender.txtRenderFacility'
          ).value,
          referringProviderTypeId: this.clinicalForm.get(
            'clinicalRefer.drpReferring'
          ).value,
          referringProviderDoctorId: this.clinicalForm.get(
            'clinicalRefer.txtReferringDoctor'
          ).value,
          referringProviderFacilityId: this.clinicalForm.get(
            'clinicalRefer.txtReferringFacility'
          ).value,
          orderingDoctorId: this.clinicalForm.get(
            'clinicalOrder.txtOrderingDoctor'
          ).value,
          marketingReferralTypeId: this.clinicalForm.get(
            'clinicalMarketing.drpMarketingRef'
          ).value,
          marketingReferralDoctorId: this.clinicalForm.get(
            'clinicalMarketing.txtMarketingDoctor'
          ).value,
          marketingReferralFacilityId: this.clinicalForm.get(
            'clinicalMarketing.txtMarketingFacility'
          ).value,
          marketingRepTypeId: this.clinicalForm.get(
            'clinicalOrder.txtMarketingRep'
          ).value,
          diagnosisCode9:
            this.clinicalForm?.get('clinicalDiagnosis.txtDiagnosis9').value == ''
              ? []
              : this.clinicalForm?.get('clinicalDiagnosis.txtDiagnosis9').value,
          diagnosisCode10: this.clinicalForm.get(
            'clinicalDiagnosis.txtDiagnosis10Id1'
          ).value,
          // diagnosisCode10Id2: this.clinicalForm?.get("clinicalDiagnosis.txtDiagnosis10Id2").value,
          // diagnosisCode10Id3: this.clinicalForm?.get("clinicalDiagnosis.txtDiagnosis10Id3").value,
          certificationConditionIndictorId: this.clinicalForm.get(
            'clinicalEpsdt.drpCertification'
          ).value,
          conditionCodeId: this.clinicalForm?.get('clinicalEpsdt.drpCondition')
            .value,

          // genderType: this.clinicalForm?.get("clinicalEpsdt.drpCondition").value,
          // functionalAbility:this.clinicalForm?.get("clinicalEpsdt.drpCondition").value,
          // generalPractitioner:this.clinicalForm?.get("clinicalEpsdt.drpCondition").value,
          // infectiousConditon: this.clinicalForm?.get("clinicalEpsdt.drpCondition").value,
          // airborne: this.clinicalForm?.get("clinicalEpsdt.drpCondition").value,
          // contact: this.clinicalForm?.get("clinicalEpsdt.drpCondition").value,
          // droplet: this.clinicalForm?.get("clinicalEpsdt.drpCondition").value,
          // renderingProviderType: this.clinicalForm?.get("clinicalEpsdt.drpCondition").value,
          // renderingProviderDoctor: this.clinicalForm?.get("clinicalEpsdt.drpCondition").value,
          // renderingProviderFacility: this.clinicalForm?.get("clinicalEpsdt.drpCondition").value,
          // referringProviderType: this.clinicalForm?.get("clinicalEpsdt.drpCondition").value,
          // referringProviderDoctor: this.clinicalForm?.get("clinicalEpsdt.drpCondition").value,
          // referringProviderFacility: this.clinicalForm?.get("clinicalEpsdt.drpCondition").value,
          // orderingDoctor: this.clinicalForm?.get("clinicalEpsdt.drpCondition").value,
          // primaryDoctor: this.clinicalForm?.get("clinicalEpsdt.drpCondition").value,
          // marketingRepType: this.clinicalForm?.get("clinicalEpsdt.drpCondition").value,
          // marketingReferralType: this.clinicalForm?.get("clinicalEpsdt.drpCondition").value,
          // marketingReferralDoctor: this.clinicalForm?.get("clinicalEpsdt.drpCondition").value,
          // marketingReferralFacility: this.clinicalForm?.get("clinicalEpsdt.drpCondition").value,
          // marketingReferralContact: this.clinicalForm?.get("clinicalEpsdt.drpCondition").value,
          // appointmentDate: this.clinicalForm?.get("clinicalEpsdt.drpCondition").value,
          // branch: this.clinicalForm?.get("clinicalEpsdt.drpCondition").value,
          // description: this.clinicalForm?.get("clinicalEpsdt.drpCondition").value,
          // practitioner: this.clinicalForm?.get("clinicalEpsdt.drpCondition").value,
          // status: this.clinicalForm?.get("clinicalEpsdt.drpCondition").value,
        },
      ],
      items: response.items,
      orderStatus: response.orderStatus,
      saleorderId: response.saleorderId,
      patients: response?.patients,
      organizationUnitId: response.organizationUnitId,
      currentStage: response.currentStage,
      currentStageStatus: response.currentStageStatus,
      claimId: response.claimId,
      patientId: response.patientId,
      isShippingApprove: response.isShippingApprove,
      isCashPayment: response.isCashPayment,
      orderProcessStatus: response.orderProcessStatus,
      patientLedgerReason: response.patientLedgerReason,
      documentReason: response.documentReason,
      paymentTransactionDetails: response?.paymentTransactionDetails,
      payPalMailResendCount: response?.payPalMailResendCount,
      payPalPaymentMethodType: response?.payPalPaymentMethodType,
      payPalPaymentStatus: response?.payPalPaymentStatus,
      claimHistoryStatus: response.claimHistoryStatus,
      patientLedgerStatus: response.patientLedgerStatus,
      shippingCheckList: response.shippingCheckList,
      orderBilling: response.orderBilling,
      deliveryTicket: response?.deliveryTicket,
      authType: response?.authType,
    };
    this.orderId != '' &&
      this.store
        .dispatch(new updateOrder(this.orderId, clinicalDetails))
        .subscribe(
          () => {
            //!
            Swal.fire({
              title: 'Success',
              html: 'Saved Successfully',
              icon: 'success',
              timer: 3000,
              timerProgressBar: true,
            }).then(
              (result) => {
                if (result.value) {
                  //!change next tab
                  this.nextTabMoveOnSaveEmitter.emit('Clinical');
                }
              },
              (err) => { }
            );
            this.saveButtonHide = false;
            this.isShowSpinner = false;
            this.saveClinicalFormState.emit(this.clinicalForm.value);

            //!

            //
            Swal.fire({
              title: 'Success',
              html: 'Saved Successfully',
              icon: 'success',
              timer: 3000,
              timerProgressBar: true,
            }).then(
              (result) => {
                if (result.value) {
                  //!change next tab
                  this.nextTabMoveOnSaveEmitter.emit('Clinical');
                }
              },
              (err) => { }
            );
            this.saveButtonHide = false;
            this.isShowSpinner = false;
          },
          (err) => {
            this.saveButtonHide = false;
            this.isShowSpinner = false;
            const data: HttpErrorResponse = err;
            Swal.fire({
              icon: 'info',
              text: data?.error?.error?.message,
            });
          }
        );
  }

  str: string[];


  sameAsReferalDoctor() {
    this.clinicalForm.patchValue({
      clinicalOrder: {
        txtMarketingRep:
          this.clinicalForm.value.clinicalMarketing.txtMarketingDoctor,
      },
    });
  }

  openIcd9Modal() {
    const dialogRef = this.dialog.open(PatientIcd9Component, {
      disableClose: true,
      data: {},
    });
    const closeDialog = dialogRef.afterClosed().subscribe(
      () => {
        this.getDropdown();
      },
      (err) => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      }
    );
    this.subscription$.push(closeDialog)
  }
  openIcd10Modal() {
    const dialogRef = this.dialog.open(PatientIcd10Component, {
      disableClose: true,
      data: {},
    });
    const closeDialog = dialogRef.afterClosed().subscribe(
      () => {
        this.getDropdown();
      },
      (err) => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      }
    );
    this.subscription$.push(closeDialog);
  }

  openDoctorModal(doctorId?: string) {
    const dialogRef = this.dialog.open(PatientDoctorComponent, {
      disableClose: true,
      data: { doctorId: doctorId },
    });
    const closeDialog = dialogRef.afterClosed().subscribe(
      () => {
        this.doctorDropdown();
      },
      (err) => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      }
    );
    this.subscription$.push(closeDialog);
  }

  // clear dropdown

  clearRenderingType() {
    this.renderingtype = '';
    this.clinicalForm.patchValue({
      clinicalRender: {
        drpRenderType: '',
      },
    });
  }

  clearRenderFacility() {
    this.renderFacility = '';
    this.clinicalForm.patchValue({
      clinicalRender: {
        txtRenderFacility: '',
      },
    });
  }

  clearReferringType() {
    this.referringType = '';
    this.clinicalForm.patchValue({
      clinicalRefer: {
        drpReferring: '',
      },
    });
  }

  clearReferringFacility() {
    this.referringFacility = '';
    this.clinicalForm.patchValue({
      clinicalRefer: {
        txtReferringFacility: '',
      },
    });
  }
  clearMarketingRefType() {
    this.markettingRefType = '';
    this.clinicalForm.patchValue({
      clinicalMarketing: {
        drpMarketingRef: '',
      },
    });
  }
  clearMarketingFacility() {
    this.marketingFacility = '';
    this.clinicalForm.patchValue({
      clinicalMarketing: {
        txtMarketingFacility: '',
      },
    });
  }
  clearCertification() {
    this.certification = '';
    this.clinicalForm.patchValue({
      clinicalEpsdt: {
        drpCertification: '',
      },
    });
  }
  clearConditionCode() {
    this.conditionCode = '';
    this.clinicalForm.patchValue({
      clinicalEpsdt: {
        drpCondition: '',
      },
    });
  }

  OrderingDoctor: string = '';
  Practitioner: string = '';
  MarRepDoctor: string = '';
  Icd9: string[] = [];
  txt1Icd10: string[] = [];
  txt2Icd10: string = '';
  txt3Icd10: string = '';
  RenderingDoctor: string = '';
  ReferringDoctor: string = '';
  MarketingDoctor: string = '';
  clearOrderingDoctor() {
    this.OrderingDoctor = '';
    this.clinicalForm.patchValue({
      clinicalOrder: {
        txtOrderingDoctor: '',
      },
    });
  }
  clearPractitioner() {
    this.Practitioner = '';
    this.clinicalForm.patchValue({
      clinicalGeneral: {
        txtPractioner: '',
      },
    });
  }
  clearMarRepDoctor() {
    this.MarRepDoctor = '';
    this.clinicalForm.patchValue({
      clinicalOrder: {
        txtMarketingRep: '',
      },
    });
  }
  clearIcd9() {
    this.Icd9 = [];
    this.clinicalForm.patchValue({
      clinicalDiagnosis: {
        txtDiagnosis9: [],
      },
    });
  }
  cleartxt1Icd10() {
    this.txt1Icd10 = [];
    this.clinicalForm.patchValue({
      clinicalDiagnosis: {
        txtDiagnosis10Id1: [],
      },
    });
  }
  cleartxt2Icd10() {
    this.txt2Icd10 = '';
    this.clinicalForm.patchValue({
      clinicalDiagnosis: {
        txtDiagnosis10Id2: '',
      },
    });
  }
  cleartxt3Icd10() {
    this.txt3Icd10 = '';
    this.clinicalForm.patchValue({
      clinicalDiagnosis: {
        txtDiagnosis10Id3: '',
      },
    });
  }
  clearRenderingDoctor() {
    this.RenderingDoctor = '';
    this.clinicalForm.patchValue({
      clinicalRender: {
        txtRenderDoctor: '',
      },
    });
  }
  clearReferringDoctor() {
    this.ReferringDoctor = '';
    this.clinicalForm.patchValue({
      clinicalRefer: {
        txtReferringDoctor: '',
      },
    });
  }
  clearMarketingDoctor() {
    this.MarketingDoctor = '';
    this.clinicalForm.patchValue({
      clinicalMarketing: {
        txtMarketingDoctor: '',
      },
    });
  }
  // clear Provider
  clearProvider() {
    this.provider = '';
    this.clinicalForm.patchValue({ txtProvider: '' });
  }
}

export interface doctorInterface {
  id: string | undefined;
  name: string;
}
