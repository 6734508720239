import { Component, OnInit, ViewChild, Input, Inject, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-practice-add-physician',
  templateUrl: './practice-add-physician.component.html',
  styleUrls: ['./practice-add-physician.component.scss']
})
export class PracticeAddPhysicianComponent implements OnInit {
  @Input() physiciantitle: any;
  isLoading: boolean;
  physicianForm: FormGroup;
  dtOptions: any;

  Physiciandetails = [
    {
      NPI: '1234567890',
      Physicians: 'Dr. John Doe',
      FAX: '555-1234',
      Address: '123 Main St',
      City: 'Cityville',
      State: 'CA',
      Country: 'USA',
      Postal: '12345'
    },
    {
      NPI: '9876543210',
      Physicians: 'Dr. Jane Smith',
      FAX: '555-5678',
      Address: '456 Oak St',
      City: 'Townsville',
      State: 'NY',
      Country: 'USA',
      Postal: '67890'
    },
    {
      NPI: '1112233445',
      Physicians: 'Dr. Alex Johnson',
      FAX: '555-9876',
      Address: '789 Pine St',
      City: 'Villageville',
      State: 'TX',
      Country: 'USA',
      Postal: '54321'
    }, {
      NPI: '1234567890',
      Physicians: 'Dr. John Doe',
      FAX: '555-1234',
      Address: '123 Main St',
      City: 'Cityville',
      State: 'CA',
      Country: 'USA',
      Postal: '12345'
    },
    {
      NPI: '9876543210',
      Physicians: 'Dr. Jane Smith',
      FAX: '555-5678',
      Address: '456 Oak St',
      City: 'Townsville',
      State: 'NY',
      Country: 'USA',
      Postal: '67890'
    },
    {
      NPI: '1234567890',
      Physicians: 'Dr. John Doe',
      FAX: '555-1234',
      Address: '123 Main St',
      City: 'Cityville',
      State: 'CA',
      Country: 'USA',
      Postal: '12345'
    },
    {
      NPI: '9876543210',
      Physicians: 'Dr. Jane Smith',
      FAX: '555-5678',
      Address: '456 Oak St',
      City: 'Townsville',
      State: 'NY',
      Country: 'USA',
      Postal: '67890'
    },
    {
      NPI: '1234567890',
      Physicians: 'Dr. John Doe',
      FAX: '555-1234',
      Address: '123 Main St',
      City: 'Cityville',
      State: 'CA',
      Country: 'USA',
      Postal: '12345'
    },
    {
      NPI: '9876543210',
      Physicians: 'Dr. Jane Smith',
      FAX: '555-5678',
      Address: '456 Oak St',
      City: 'Townsville',
      State: 'NY',
      Country: 'USA',
      Postal: '67890'
    },
    {
      NPI: '1234567890',
      Physicians: 'Dr. John Doe',
      FAX: '555-1234',
      Address: '123 Main St',
      City: 'Cityville',
      State: 'CA',
      Country: 'USA',
      Postal: '12345'
    },
    {
      NPI: '9876543210',
      Physicians: 'Dr. Jane Smith',
      FAX: '555-5678',
      Address: '456 Oak St',
      City: 'Townsville',
      State: 'NY',
      Country: 'USA',
      Postal: '67890'
    },
    {
      NPI: '1112233445',
      Physicians: 'Dr. Alex Johnson',
      FAX: '555-9876',
      Address: '789 Pine St',
      City: 'Villageville',
      State: 'TX',
      Country: 'USA',
      Postal: '54321'
    }, {
      NPI: '1234567890',
      Physicians: 'Dr. John Doe',
      FAX: '555-1234',
      Address: '123 Main St',
      City: 'Cityville',
      State: 'CA',
      Country: 'USA',
      Postal: '12345'
    },
    {
      NPI: '9876543210',
      Physicians: 'Dr. Jane Smith',
      FAX: '555-5678',
      Address: '456 Oak St',
      City: 'Townsville',
      State: 'NY',
      Country: 'USA',
      Postal: '67890'
    },
    {
      NPI: '1234567890',
      Physicians: 'Dr. John Doe',
      FAX: '555-1234',
      Address: '123 Main St',
      City: 'Cityville',
      State: 'CA',
      Country: 'USA',
      Postal: '12345'
    },
    {
      NPI: '9876543210',
      Physicians: 'Dr. Jane Smith',
      FAX: '555-5678',
      Address: '456 Oak St',
      City: 'Townsville',
      State: 'NY',
      Country: 'USA',
      Postal: '67890'
    },
    {
      NPI: '1234567890',
      Physicians: 'Dr. John Doe',
      FAX: '555-1234',
      Address: '123 Main St',
      City: 'Cityville',
      State: 'CA',
      Country: 'USA',
      Postal: '12345'
    },
    {
      NPI: '9876543210',
      Physicians: 'Dr. Jane Smith',
      FAX: '555-5678',
      Address: '456 Oak St',
      City: 'Townsville',
      State: 'NY',
      Country: 'USA',
      Postal: '67890'
    },
  ];
  
  
  constructor(
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      physiciantitle: string;

    } = {
        physiciantitle: '',
      },
  ) { }

  ngOnInit(): void {
    this.dtOptions = {
      responsive: true,
      paging: false,
      scrollCollapse: true,
      scrollY: '400px',
      scrollX: true,
      info: false
    };
    this.physicianForm = this.fb.group({
      txtNpiNumber: new FormControl(''),
      txtfirstName: new FormControl(''),
      txtlastName: new FormControl(''),
      txtCity: new FormControl(''),
      txtPostalCode: new FormControl(''),
    });

    if (this.data) {
      this.physiciantitle = this.data?.physiciantitle ?? "Add Physician";
    }
  }

  resetPhysician(): void {
    this.physicianForm.reset();
  }
}
