import {
  AfterContentInit,
  Inject,
  Component,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import {
  CommentsList,
  WIPHistoryDTO,
  WIPList,
} from 'projects/admin/src/app/admin-proxy/platform-app-management/rcm/platform-management/work-in-progress/dto';
import { WorkInProgressService } from 'projects/admin/src/app/admin-proxy/platform-app-management/rcm/platform-management/work-in-progress';
import { HttpErrorResponse } from '@angular/common/http';
import { Subscription } from 'rxjs';
import { MatSort } from '@angular/material/sort';
// import { defaultGuid } from 'projects/admin/src/app/document-perviewer/document-perviewer.component';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { defaultGuid } from 'projects/shared/src/app/enums/allenums.enum';
@Component({
  selector: 'app-wip-list-modal',
  templateUrl: './wip-list-modal.component.html',
  styleUrls: ['./wip-list-modal.component.scss']
})
export class WipListModalComponent implements OnInit {
  subscription$: Subscription[] = [];
  // WIPID:string ="190cc06f-9a7d-e610-813e-3a0d1eec93ee";
  dataSource: MatTableDataSource<WIPHistoryDTO> = new MatTableDataSource<WIPHistoryDTO>([]);
  dataSourceComments: MatTableDataSource<CommentsList> =
    new MatTableDataSource<CommentsList>([]);
    isLoading: boolean = true;

    @ViewChild(MatSort, { static: false }) sort: MatSort = new MatSort();
    @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  arrDisplayedColumns: string[] = [
    // 'wipId',
    'assignedToName','assignedByName','creationTime','prevWIPStatus' ,'currentWIPStatus','completedDate'
  ];
  constructor(
    public workInProgressService: WorkInProgressService,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      id: string;
      defaultWipId: string;
    } = {
      id: defaultGuid,
      defaultWipId: 'WIP7845454',
    },
  ) {

  }

  ngOnInit(): void {
    this.getAssignedTask();
  }

   //Get Assigned task list
   getAssignedTask() {
    this.isLoading = true;
    this.dataSource = new MatTableDataSource([]);
    // this.dataSource.sort = this.sort;
    // this.sort.disableClear = true;
    // this.dataSource.paginator = this.paginator;

    const assignedTasks = this.workInProgressService
      .getWIPHistoryDetails(this.data.id)
      // .getWIPList('190cc06f-9a7d-e610-813e-3a0d1eec93ea')
      .subscribe(
        (response) => {

          this.isLoading = false;
          this.dataSource = new MatTableDataSource(response ?? []);
          this.dataSource.sort = this.sort;
          this.sort.disableClear = true;
          this.dataSource.paginator = this.paginator;
        },
        (err) => {

          this.dataSource = new MatTableDataSource([]);
          this.dataSource.sort = this.sort;
          this.sort.disableClear = true;
          this.dataSource.paginator = this.paginator;
          this.isLoading = false;
          const data: HttpErrorResponse = err;
          // Swal.fire({
          //   icon: 'error',
          //   text: data?.error?.error?.message,
          // });

        }
      );
    this.subscription$.push(assignedTasks);
  }

}
