<mat-dialog-content class="mat-typography pt-0 pb-0">
    <div class="modal-header pt-0 pb-0">
        <h1 class="modal-title"><b>Fax Finder</b></h1>
        <mat-icon mat-dialog-close>close</mat-icon>
    </div>

    <div class="card card-body pb-0" style="margin-top: 5px;">
        <form [formGroup]="fgFaxFinder">
            <div class="row mt-2">

                <!--Fax Id-->
                <mat-form-field class="col-2">
                    <input matInput type="text" maxlength="15" formControlName="FaxId" placeholder="Fax Id"/>
                </mat-form-field>
                <!--Chart No-->
                <mat-form-field class="col-2">
                    <input matInput type="text" maxlength="20"  formControlName="ChartNo" placeholder="Chart No"/>
                </mat-form-field>
                <!--Patient Name-->
                <mat-form-field class="col-2">
                    <input matInput type="text" maxlength="40" formControlName="PatientName" placeholder="Patient Name"/>
                </mat-form-field>
                <!-- Birth Date -->
                <!-- <mat-form-field class="col-md-2 col-lg-2 col-xl-2">
                    <mat-label>
                        Birth Date </mat-label>
                    <input autocomplete="new-txtBirthdate" [max]="BirthdateMaxDate" formControlName="txtBirthdate"
                        matInput maxlength="40" type="text" placeholder="(MM/DD/YYYY)" [matDatepicker]="txtBirthdate"
                        [min]="BirthdateMinDate" (ngModelChange)="onKeyPressSearch()" />
                    <mat-datepicker-toggle matSuffix [for]="txtBirthdate">
                    </mat-datepicker-toggle>
                    <mat-datepicker #txtBirthdate> </mat-datepicker>
                    <mat-error *ngIf="fgFaxFinder.get('txtBirthdate').errors?.invalidDate||
                    BirthdateMinDate <=
                    fgFaxFinder?.get('txtBirthdate')?.value &&
                    !(
                      fgFaxFinder?.get('txtBirthdate')?.touched &&
                      fgFaxFinder?.get('txtBirthdate')?.errors
                        ?.required
                    )||fgFaxFinder?.get('txtBirthdate')?.errors?.required
                  "> Enter Past Date!
                    </mat-error>
                </mat-form-field> -->

                <mat-form-field class="col-2">
                    <mat-label>
                      Birth Date </mat-label>
                    <input autocomplete="new-txtBirthdate" [max]="BirthdateMaxDate" formControlName="txtBirthdate" [(ngModel)]="birthdate"
                      matInput maxlength="10" type="text" placeholder="(MM/DD/YYYY)" type="text"
                      [matDatepicker]="txtBirthdate" [min]="BirthdateMinDate"
                      onkeydown="this.value=this.value.replace(/^(\d\d)(\d)$/g,'$1/$2').replace(/^(\d\d\/\d\d)(\d+)$/g,'$1/$2').replace(/[^\d\/]/g,'')"
                      />

                    <mat-datepicker-toggle matSuffix [for]="txtBirthdate">
                    </mat-datepicker-toggle>
                    <mat-datepicker #txtBirthdate> </mat-datepicker>
                    <mat-error *ngIf="
                    BirthdateMinDate <=
                    fgFaxFinder?.get('txtBirthdate')?.value &&
                    !(
                        fgFaxFinder?.get('txtBirthdate')?.touched &&
                        fgFaxFinder?.get('txtBirthdate')?.errors
                        ?.required
                    )||fgFaxFinder.get('txtBirthdate').errors
                  "> Enter a Valid Date!
                    </mat-error>
                  </mat-form-field>

                    <!--Fax Status Drop Down-->
                    <mat-form-field class="col-2">
                    <mat-label>Fax Status</mat-label>
                    <mat-select #selectBranch formControlName="drpWipStatus" multiple [(value)]="inboundStatus"
                        (selectionChange)="onWipStatushSelectionChange($event)">
                        <mat-option>
                            <ngx-mat-select-search [formControl]="FaxStatusTypeControl"  placeholderLabel="Search" noEntriesFoundLabel="No Matches found"
                                formControlName="txtWipStatus"></ngx-mat-select-search>
                        </mat-option>
                        <mat-option *ngIf="!WipStatusSelectAllOption" [value]="'selectAll'">Select All</mat-option>
                        <mat-option *ngIf="WipStatusSelectAllOption" [value]="'DeselectAll'">Deselect All</mat-option>
                        <mat-option *ngFor="let wipstatus of wipTableData" [value]="wipstatus?.id">
                            {{ wipstatus?.inboundStatus }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>


                <!-- <div class="col-2"> -->

                    <div class="all-icon mt-2" matTooltip="Search">
                        <i class="fa fa-search search pointer" (click)="onKeyPressSearch()"
                        [class.disabled]="(!this.fgFaxFinder?.valid || !this.areFieldsNotEmpty())"
                         ></i>
                    </div>
                    <div class="all-icon ml-3 mt-2" matTooltip="Reset">
                        <i class="fa fa-refresh reset pointer" (click)="resetFaxFinder()"></i>
                    </div>

                    <!-- <button mat-button class="buttonColor mr-2" [disabled]="!(!fgFaxFinder?.invalid)"
                        (click)="loadDefault()">
                        <span>Search</span>
                    </button> -->
                    <!-- <button mat-raised-button class="resetclr" (click)="resetFaxFinder()">Reset</button> -->
                </div>
            <!-- </div> -->


        </form>
  </div>
  <div class="card card-body  pb-0" style="margin-top: 5px;">
    <div class="row" *ngIf="isLoading">
        <div class="col-12">
            <div class="d-flex justify-content-center" *ngIf="isLoading && start ===1" >
                <img class="img-responsive pb-2 mr-1" src="assets/gifs/Infinity-loader.gif" />
            </div>
        </div>
    </div>
    <!-- <div *ngIf="!isLoading"> -->
  <div class="row" *ngIf="!isLoading">
    <div class="col-12">
      <div class="table table-responsive" *ngIf="!isLoading">
        <app-fax-finder-lazy-loading-table-v1 [fgFaxFinder]="fgFaxFinder" [totalLength]="totalFaxFinderDataList"  [DataList]="faxFinderDataList"></app-fax-finder-lazy-loading-table-v1>
      </div>
    </div>
  </div>
</div>
</mat-dialog-content>
