import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { debounceTime, filter, map, startWith, switchMap, tap } from 'rxjs/operators';
import { MatTableDataSource } from '@angular/material/table';
import { PatientSaleOrderService } from 'projects/order/src/app/order-proxy/order-management/order-optimization';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
@Component({
  selector: 'app-patient-orders',
  templateUrl: './patient-orders.component.html',
  styleUrls: ['./patient-orders.component.scss']
})
export class PatientOrdersComponent implements OnInit {
  claimProcessingForm: FormGroup;
  patientIds: any;
  patientOrders = 'Patient Orders - ';
  
  dataSource: MatTableDataSource<any> = new MatTableDataSource<any>([]);
  @ViewChild(MatSort, { static: false }) sort: MatSort = new MatSort();
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  patientId: string
  isLoading: boolean = false;
  constructor(
    public fb: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private PatientSaleOrderService: PatientSaleOrderService
  ) { }
  arrDisplayedColumns: string[] = [
    'saleOrderId',
    'items',
    'totalQuantity',
    'totalCharge',
    'dateOfService',
    'creationTime',
    'status'
  ];
  ngOnInit(): void {
    this.activatedRoute.params.subscribe(params => {
      const id = params['id']; // Access the query parameter value
      this.patientId = id
    })

    this.claimProcessingForm = this.fb.group({
      saleOrderId: new FormControl(''),
      // patientId: new FormControl(''),
      items: new FormControl(''),
      totalQuantity: new FormControl(''),
      status: new FormControl('', ),
      totalCharge: new FormControl(''),
      dateOfService: new FormControl('',),
      creationTime: new FormControl(''),

    });
    this.getOrderList()
  }

  getOrderList(){
    const valueChanges = this.claimProcessingForm.valueChanges
    .pipe(
      startWith({
        saleOrderId: '',
        items: '',
        totalQuantity: '',
        status: '',
        totalCharge: '',
        dateOfService: '',
        creationTime: '',
        patientId: '',

      }),
      tap((x) => {
        // this.isLoading = true;
        this.setTableData([]);
      }),
      filter((e) => e && this.claimProcessingForm?.valid),
      debounceTime(300),
      map((response) => {
        // this.isLoading = true;
        const sValue = {
          saleOrderId: response?.saleOrderId?.trim()?.toLowerCase(),
          items: response?.items?.trim()?.toLowerCase(),
          totalQuantity: response?.totalQuantity?.trim()?.toLowerCase(),
          status: response?.status?.trim()?.toLowerCase(),
          totalCharge: response?.totalCharge?.trim()?.toLowerCase(),
          creationTime: response?.creationTime?.trim()?.toLowerCase(),
          patientId: this.patientId?this.patientId: '',
 
        };
        return sValue;
      }),
      switchMap((sValue) =>
        this.PatientSaleOrderService.orderSearchBySDefaultPatientIdAndSSaleOrderIdAndSPatientNameAndSCSRNameAndDtDOSAndSStatus(
          sValue?.patientId,
          '',
          '',
          '',
          '',
          '',
          '',

      
        )
      )
    )
    .subscribe(
      (response) => {
        response?.map((x, i = 1) => {
          this.patientIds = x
          
        });
        this.setTableData(response);
      },
      (err) => {
        // this.isLoading = false;
        // const data: HttpErrorResponse = err;
        // Swal.fire({
        //   icon: 'error',
        //   text: data?.error?.error?.message,
        // });
      }
    );
  }
  setTableData(data: any) {
    this.dataSource = new MatTableDataSource(data);
    this.dataSource.sort = this.sort;
    this.dataSource.sort.disableClear = true;
    this.dataSource.paginator = this.paginator;
  }

}
