import { mapEnumToOptions } from '@abp/ng.core';

export enum RentalTypes {
  once = 'once',
  daily = 'daily',
  weekly = 'weekly',
  monthly = 'monthly',
}

export const rentalTypesOptions = mapEnumToOptions(RentalTypes);
