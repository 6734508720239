import type { CreateUpdateMainProductDetailsDTO, CreateUpdateProductDTO, CreateUpdateResupplyProductDetailsDTO, ProductDTO, ProductcategorylistDTO, ProductsDTO, ResupplyProductDetailsDTO, SearchMainProductDTO, SearchResupplyProductDTO } from './dto/models';
import type { ProductType } from './product-type.enum';
import { RestService } from '@abp/ng.core';
import type { PagedAndSortedResultRequestDto, PagedResultDto } from '@abp/ng.core';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ProductService {
  apiName = 'ItemManagement';

  create = (input: CreateUpdateProductDTO) =>
    this.restService.request<any, ProductDTO>({
      method: 'POST',
      url: '/api/ItemApp/product',
      body: input,
    },
     { apiName: this.apiName, skipHandleError: true });

  delete = (id: string) =>
    this.restService.request<any, void>({
      method: 'DELETE',
      url: `/api/ItemApp/product/${id}`,
    },
     { apiName: this.apiName, skipHandleError: true });

  get = (id: string) =>
    this.restService.request<any, ProductDTO>({
      method: 'GET',
      url: `/api/ItemApp/product/${id}`,
    },
     { apiName: this.apiName, skipHandleError: true });

  getList = (input: PagedAndSortedResultRequestDto) =>
    this.restService.request<any, PagedResultDto<ProductDTO>>({
      method: 'GET',
      url: '/api/ItemApp/product',
      params: { skipCount: input.skipCount, maxResultCount: input.maxResultCount, sorting: input.sorting },
    },
     { apiName: this.apiName, skipHandleError: true });

  update = (id: string, input: CreateUpdateProductDTO) =>
    this.restService.request<any, ProductDTO>({
      method: 'PUT',
      url: `/api/ItemApp/product/${id}`,
      body: input,
    },
     { apiName: this.apiName, skipHandleError: true });

  deleteResupplyProductByMainProductIdAndResupplyProductId = (mainProductId: string, resupplyProductId: string) =>
    this.restService.request<any, ProductDTO>({
      method: 'DELETE',
      url: '/api/ItemApp/product/delete-resupply-product',
      params: { mainProductId, resupplyProductId },
    },
     { apiName: this.apiName, skipHandleError: true });

  getProductsByInput = (input: ProductType) =>
    this.restService.request<any, ProductsDTO[]>({
      method: 'GET',
      url: '/api/ItemApp/product/get-products',
      params: { input },
    },
     { apiName: this.apiName, skipHandleError: true });

     getProductsWithBrandByInput = (input: ProductType) =>
     this.restService.request<any, ProductsDTO[]>({
       method: 'GET',
       url: '/api/ItemApp/product/get-products-with-brand',
       params: { input },
     },
      { apiName: this.apiName, skipHandleError: true });

  getResupplyProductByMainProductIdAndResupplyProductId = (mainProductId: string, resupplyProductId: string) =>
    this.restService.request<any, ResupplyProductDetailsDTO>({
      method: 'GET',
      url: '/api/ItemApp/product/get-resupply-product',
      params: { mainProductId, resupplyProductId },
    },
     { apiName: this.apiName, skipHandleError: true });

  searchMainProductsByInput = (input: SearchMainProductDTO) =>
    this.restService.request<any, PagedResultDto<ProductDTO>>({
      method: 'GET',
      url: '/api/ItemApp/product/search-main-products',
      params: { mainProductName: input.mainProductName, hcpcCode: input.hcpcCode, minimumQty: input.minimumQty, availableQty: input.availableQty, stockStatus: input.stockStatus, status: input.status, createdDate: input.createdDate },
    },
     { apiName: this.apiName, skipHandleError: true });

  searchResupplyProductsByInput = (input: SearchResupplyProductDTO) =>
    this.restService.request<any, PagedResultDto<ResupplyProductDetailsDTO>>({
      method: 'GET',
      url: '/api/ItemApp/product/search-resupply-products',
      params: { resupplyProductName: input.resupplyProductName, hcpcCode: input.hcpcCode, minimumQty: input.minimumQty, availableQty: input.availableQty, stockStatus: input.stockStatus, status: input.status, createdDate: input.createdDate },
    },
     { apiName: this.apiName, skipHandleError: true });

  updateMainProductByIdAndInput = (id: string, input: CreateUpdateMainProductDetailsDTO) =>
    this.restService.request<any, ProductDTO>({
      method: 'PUT',
      url: `/api/ItemApp/product/${id}/update-main-product`,
      body: input,
    },
     { apiName: this.apiName, skipHandleError: true });

  updateResupplyProductByInput = (input: CreateUpdateResupplyProductDetailsDTO) =>
    this.restService.request<any, ProductDTO>({
      method: 'PUT',
      url: '/api/ItemApp/product/update-resupply-product',
      body: input,
    },
     { apiName: this.apiName, skipHandleError: true });

    getproductcategorylistdrp = () =>
    this.restService.request<any, ProductcategorylistDTO[]>({
      method: 'GET',
      url: '/api/ItemApp/product/productcategorylistdrp',
    },
    { apiName: this.apiName });

  constructor(private restService: RestService) {}
}
