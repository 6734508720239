import { mapEnumToOptions } from '@abp/ng.core';

export enum OnTimeDropdowns {
  All = 'All',
  CoverageAreaType = 'CoverageAreaType',
  WorkingWeeks = 'WorkingWeeks',
  WorkingHoursStart = 'WorkingHoursStart',
  LunchBreakHoursStart = 'LunchBreakHoursStart',
  OfficeType = 'OfficeType',
  HolidayYears = 'HolidayYears',
  LeaveType = 'LeaveType',
  CalendarStatus = 'CalendarStatus',
  NewCalendar = "NewCalendar",
  EditCalendar = "EditCalendar"
}

export const onTimeDropdownsOptions = mapEnumToOptions(OnTimeDropdowns);
