import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DataTableDirective } from 'angular-datatables';
@Component({
  selector: 'app-faxFinderTable',
  templateUrl: './faxFinderTable.component.html',
  styleUrls: ['./faxFinderTable.component.scss'],
})
export class FaxFinderTableComponent implements OnInit {
  @Input() tableData: any;
  faxFinderDataArray: any;
  dtFaxFinderOptions: any = {
    responsive: true,
    stateSave: true,
    paging: false,
    info: true,
    // searching: false,
    // dom: 'lrt',
    // dom: 'lirt',
    dom: 'lrti',
    language: {
      emptyTable: 'No records',
      info: 'Total : _MAX_ records',
      infoEmpty: 'Total : _MAX_ records',
      infoFiltered: '(filtered : _TOTAL_ records)',
      loadingRecords: 'Loading...',
      zeroRecords: 'No matching records',
    },
    scrollCollapse: true,
    scrollY: '200px',
    scrollX: true,
    fixedHeader: true,
    columnDefs: [{ targets: [0, 1, 2, -1], className: 'dt-fixed-column' }],
  };
  isRefresh : boolean = false;
  @ViewChild(DataTableDirective, { static: false })
  datatableElement: DataTableDirective;

  constructor(private snackBar: MatSnackBar, private elementRef: ElementRef) {}

  ngOnInit() {
    this.faxFinderDataArray = this.tableData;
    window.addEventListener('scroll', this.scroll, true); //third parameter
  }

  ngOnDestroy(): void {
    window.removeEventListener('resize', this.calculateScrollY);
    window.removeEventListener('scroll', this.scroll, true);
  }

  ngAfterViewInit(): void {
    if (this.datatableElement && this.datatableElement.dtInstance) {
      this.refreshDataTable();
    } else {
      // If dtInstance is not available yet, wait for a short time and try again
      setTimeout(() => this.refreshDataTable(), 500);
    }
    window.removeEventListener('resize', this.calculateScrollY);
    const dataTableSearch =
      this.elementRef.nativeElement.querySelector('.dataTables_filter');
    if (dataTableSearch) {
      dataTableSearch.style.display = 'none';
    }


  }


  scroll = (event) => {
    const target = event.target as HTMLElement;

  // Check if the user has scrolled to the end of the table
  // if (target.scrollTop + target.clientHeight >= target.scrollHeight) {
  //   const currentScrollPosition = this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
  //     const tableWrapper = dtInstance.table(0).container() as HTMLElement;
  //     return tableWrapper.scrollTop;
  //   });
  //   this.isRefresh = true;




  //   this.tableData.push(this.tableData[0]);
  //   this.tableData.push(this.tableData[1]);
  //   this.tableData.push(this.tableData[2]);
  //   this.tableData.push(this.tableData[3]);
  //   this.tableData.push(this.tableData[4]);
  //   this.tableData.push(this.tableData[5]);
  //   this.tableData.push(this.tableData[6]);
  //   this.tableData.push(this.tableData[7]);
  //   this.tableData.push(this.tableData[8]);
  //   this.tableData.push(this.tableData[9]);
  //   this.tableData.push(this.tableData[10]);
  //   this.tableData.push(this.tableData[11]);

  //   setTimeout(() => {
  //     this.isRefresh = false;
  //   }, 1);
  //   // After adding new rows, scroll back to the previous position
  //   currentScrollPosition.then((scrollPosition: number) => {
  //     this.scrollToPosition(scrollPosition);
  //   });

  //   // Perform actions when the user scrolls to the end of the table
  // }
  };

  scrollToPosition(position: number) {
    // Scroll to the specified position
    this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
      const tableWrapper = dtInstance.table(0).container() as HTMLElement;
      tableWrapper.scrollTo({ top: position });
    });
  }

  calculateScrollY = () => {
    const screenHeight =
      window.innerHeight ||
      document.documentElement.clientHeight ||
      document.body.clientHeight;
    const availableSpacePercentage = 0.5; // Adjust the percentage as needed
    this.dtFaxFinderOptions.scrollY = screenHeight * availableSpacePercentage;
  };

  refreshDataTable(): void {
    this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
      // Clear search input values
      $('input', dtInstance.table(0).footer()).val('');
      // Redraw the DataTable to load all records
      dtInstance.search('').columns().search('').draw();
      // Attach the event listener for filtering
      dtInstance.columns().every(function () {
        const that = this;
        $('input', this.footer()).on('keyup change', function () {
          if (that.search() !== this['value']) {
            that.search(this['value']).draw();
          }
        });
      });
    });
  }

  onRowDoubleClickV1(textToCopy) {
    this.copyTextToClipboard(textToCopy);
    this.showNotification('Text copied: ' + textToCopy);
  }

  showNotification(message: string): void {
    this.snackBar.open(message, 'Close', {
      duration: 3000, // Duration in milliseconds
      horizontalPosition: 'end', // Positioning the notification horizontally
      verticalPosition: 'bottom', // Positioning the notification vertically
    });
  }

  copyTextToClipboard(text: string): void {
    const textArea = document.createElement('textarea');
    textArea.value = text;
    document.body.appendChild(textArea);
    textArea.select();
    document.execCommand('Copy');
    textArea.remove();
  }
}
