import {
  animate, state,
  style,
  transition, trigger
} from '@angular/animations';
import { HttpErrorResponse } from '@angular/common/http';
import {
  Component,
  ElementRef,
  Inject,
  OnInit,
  ViewChild
} from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import {
  BillingDropdowns,
  PatientDropdowns
} from 'projects/patient/src/app/dropdown-proxy/dropdown-management/dropdowns';
import { ClaimStatusDTO } from 'projects/patient/src/app/dropdown-proxy/dropdown-management/dropdowns/dto/billing';
import {
  BillingMasterDropdownService,
  PatientMasterDropdownService
} from 'projects/patient/src/app/dropdown-proxy/dropdown-management/dropdowns/master-dropdown-service';
import { BillingMasterDropdownDTO } from 'projects/patient/src/app/dropdown-proxy/rcm/dropdown-management/dropdowns/dto/billing/models';
import { PayorLevelDTO } from 'projects/patient/src/app/dropdown-proxy/rcm/dropdown-management/dropdowns/dto/patient';
import { claimAcceptedStatus } from 'projects/shared/src/app/enums/allenums.enum';
import { TableService } from 'projects/shared/src/app/table.service';
import { DateValidator } from 'projects/shared/src/app/validations/date-validator';
import { of, Subscription } from 'rxjs';
import { debounceTime, delay, filter, finalize, map, pluck, switchMap, tap } from 'rxjs/operators';
import Swal from 'sweetalert2';
import {
  BatchesDTO,
  ClaimDataDTO,
  LstEOB
} from '../billing-proxy/billing-management/posting/dto/models';
import {
  ClaimDTO,
  EOBHistoryDTO,
  ExportClaimReportDTO,
  Import835FileDTO,
  SearchClaimStatusDTO
} from '../claim-proxy/claim-processing-management/rcm/billing-management/posting/dto/models';
import { ClaimProcessService } from '../claim-proxy/claim-processing-management/rcm/claim-processing-management/x12/claim-process.service';
import { InsurancePaymentService } from '../claim-proxy/claim-processing-management/rcm/claim-processing-management/x12/insurance-payment.service';
import { ZermidClearingHouseService } from '../claim-proxy/claim-processing-management/rcm/claim-processing-management/x12/zermid-clearing-house.service';
import { ToastrService } from "ngx-toastr";
export const regexPattern: RegExp = /[`~!@#$%^&*()_|+\-=?;:'",<>\{\}\[\]\\\/]/gi;
@Component({
  selector: 'app-claim-status',
  templateUrl: './claim-status.component.html',
  styleUrls: ['./claim-status.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition(
        'expanded <=> collapsed',
        animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')
      ),
    ]),
  ],
})
export class ClaimStatusComponent implements OnInit {
  drpClaimStatus: ClaimStatusDTO[] = [];
  isLoading: boolean = true;
  strPageType: string = 'claimStatusList';
  expandedElement: any | null;
  orderId: string = '';
  organizationunitId: string = '';
  claimTableData: claimStatus[] = [];
  strPatientId: string = '';
  claimStatusForm: FormGroup;
  claimSearchForm: FormGroup;
  selectedStatus: string;
  drpPayorLevel: PayorLevelDTO[] = [];
  strPayorLevel: string = '';
  arrDisplayedColumns: string[] = [
    'tradingPartnerClaimNumber',
    'saleOrderId',
    'patientId',
    'patientName',
    'itemCode',
    'billedQty',
    'billedAmount',
    'payerID',
    'payerName',
    'currentBalance',
    'claimServiceDate',
    'billingName',
    'appClaimStatus',
    'zirmedClaimStatus',
    'remitClaimStatus',
    'claimProcessedDate',
    'status',
  ];
  dataSource: MatTableDataSource<any> = new MatTableDataSource<any>([]);
  organizationUnitId = null;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatTable, { static: false }) tableRef: MatTable<any>;
  subscription$: Subscription[] = [];
  @ViewChild('uploadFileReference', { static: false })
  uploadFileReference: ElementRef;
  file835Strm: string[] = [];
  isImportLoading: boolean = false;

  constructor(
    private Table: TableService,
    private titleService: Title,
    public dialog: MatDialog,
    private zermidClearingHouse: ZermidClearingHouseService,
    private claimProcessService: ClaimProcessService,
    public formBuilder: FormBuilder,
    private billingDropdownService: BillingMasterDropdownService,
    private patientMasterDropdownService: PatientMasterDropdownService,
    private insurancePaymentService: InsurancePaymentService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private dateValidator: DateValidator,
    private toastr: ToastrService,
  ) { }

  ngOnInit(): void {
    this.claimStatusForm = this.formBuilder.group({
      drpStatus: new FormControl(''),
    });
    this.claimSearchForm = this.formBuilder.group({
      patientId: new FormControl(''),
      patientName: new FormControl(''),
      payerID: new FormControl(''),
      payerName: new FormControl(''),
      claimServiceDate: new FormControl('', this.dateValidator?.dateVaidator),
      billingName: new FormControl(''),
      claimNumber: new FormControl(''),
      saleOrderId: new FormControl(''),
      currentBalance: new FormControl(''),
      itemCode: new FormControl(''),
      billedAmount: new FormControl(''),
      billedQty: new FormControl(''),
      appClaimStatus: new FormControl(''),
      zirmedClaimStatus: new FormControl(''),
      remitClaimStatus: new FormControl(''),
      claimProcessedDate: new FormControl('', this.dateValidator?.dateVaidator),
    });
    this.titleService?.setTitle('Qsecure | Claim Status');
    this.getClaimStatusDropdown();
    this.setTableData([]);
    this.getSearchObs();
  }
  getSearchObs() {

    this.claimSearchForm.valueChanges.pipe(
      tap(x => { this.isLoading = true; this.setTableData([]); }),
      filter(e => e && this.claimSearchForm?.valid && this.claimStatusForm?.valid),
      debounceTime(300)
      , map(response => {
        const billedAmount = String(response?.billedAmount)?.replace(regexPattern, '') ?? "";
        const currentBalance = String(response?.billedAmount)?.replace(regexPattern, '') ?? "";
        const oSearchClaim: SearchClaimStatusDTO = {
          claimNumber: (String(response?.claimNumber)?.trim() ?? ""),
          saleOrderId: (String(response?.saleOrderId)?.trim() ?? ""),
          patientId: (String(response?.patientId)?.trim() ?? ""),
          patientName: (String(response?.patientName)?.trim() ?? ""),
          itemCode: (String(response?.itemCode)?.trim() ?? ""),
          billedQuantity: String(response?.billedQty)?.trim() ?? "",
          billedAmount: (String(billedAmount?.trim()) ?? ""),
          payerId: (String(response?.payerID)?.trim() ?? ""),
          payerName: (String(response?.payerName)?.trim() ?? ""),
          currentBalance: (String(currentBalance)?.trim() ?? ""),
          claimServiceDate: (String(response?.claimServiceDate)?.trim() ?? ""),
          providerName: (String(response?.billingName)?.trim() ?? ""),
          status: (String(response?.appClaimStatus)?.trim() ?? ""),
          waystarClaimStatus: (String(response?.zirmedClaimStatus)?.trim() ?? ""),
          remitStatus: (String(response?.remitClaimStatus)?.trim() ?? ""),
          processedDate: (String(response?.claimProcessedDate)?.trim()) ?? ""
        }
        return oSearchClaim
      }),
      switchMap((value: SearchClaimStatusDTO) => this.zermidClearingHouse.searchClaimStatusByClaimStatusAndOSearchClaim(this.claimStatusForm?.value?.drpStatus, value)),
      finalize(() => {
        this.isLoading = false;
      }),
      tap(x => { this.isLoading = false; }),

    ).subscribe(response => {
      this.setTableData(response ?? []);
    },
      (err) => {
        this.setTableData([]);
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      });
  }


  onStatusChange(event: { value: any }) {
    let selectedStatus = event.value;
    this.router.navigate([], { queryParams: { id: selectedStatus } });
    this.getAllCalimsStatus(selectedStatus);
  }

  getAllCalimsStatus(Status?: any) {
    try {


      this.setTableData([]);
      this.zermidClearingHouse.getClaimsBasedOnStatusByClaimStatus(Status).pipe(
        tap(x => { this.isLoading = true; this.setTableData([]); }),
        filter(e => e && this.claimSearchForm?.valid && this.claimStatusForm?.valid),
        finalize(() => {
          this.isLoading = false;
        }),
        tap(x => { this.isLoading = false; }),

      ).subscribe(response => {
        this.setTableData(response ?? []);
      },
        (err) => {
          this.setTableData([]);
          const data: HttpErrorResponse = err;
          Swal.fire({
            icon: 'info',
            text: data?.error?.error?.message,
          });
        });

    } catch (error) {
      this.setTableData([]);
    }
  }
  setTableData(response: ClaimDTO[] = []) {
    this.claimTableData = [];
  //!  Listed the Claim processed records in claim proces table
    const arrClaimResponse = response?.filter((e) => e?.claimStatus !== claimAcceptedStatus.readyToClaimProcess) ?? [];

    arrClaimResponse?.forEach((element) => {
      const lstBatchesData: BatchesDTO[] =
        element?.lstClearingHouseUpdates[0]?.lstBatches ?? [];
      const intBatchDataLength =
        element?.lstClearingHouseUpdates[0]?.lstBatches?.length ?? 0;
      const mclaimBatchesData: BatchesDTO =
        lstBatchesData?.slice(
          intBatchDataLength - 1,
          intBatchDataLength
        )[0] ?? null;
      const intClaimDataLength: number =
        mclaimBatchesData?.claims?.claimData?.length ?? 0;
      const claimData: ClaimDataDTO =
        mclaimBatchesData?.claims?.claimData?.slice(
          intClaimDataLength - 1,
          intClaimDataLength
        )[0] ?? null;
      const serviceDate = claimData?.serviceDate ?? '';
      const sitem = element?.saleOrder?.items ?? [];
      const totalItemQty =
        String(
          sitem?.reduce((prvsValue, curValue) => {
            return prvsValue + +curValue?.qty;
          }, 0) ?? 0
        ) ?? '';
      const totalItemPrice =
        String(
          sitem?.reduce((prvsValue, curValue) => {
            return prvsValue + +curValue?.totalPrice;
          }, 0) ?? 0
        ) ?? '';
      const mtotalItemPrice = totalItemPrice?.includes('.')
        ? String(Number(totalItemPrice)?.toFixed(2)) ?? ''
        : totalItemPrice ?? '';
      const itemNames =
        sitem?.reduce((prvsValue, curValue) => {
          return `${(prvsValue ?? '') === '' ? '' : prvsValue + ', '}${curValue?.itemIdOrBarCode ?? ''
            }`;
        }, '') ?? '';
      const lstEOb =
        element?.lstEOBs?.map((e) => {
          e.a835 = e?.a835 ?? '';
          e.payorType = e?.payorType ?? '';
          e.claimId = e?.claimId ?? '';
          e.linkback = e?.linkback ?? '';
          e.remitID = e?.remitID ?? '';
          e.patAcctNum = e?.patAcctNum ?? '';
          e.patName = e?.patName ?? '';
          e.checkNum = e?.checkNum ?? '';
          e.paidAmt = e?.paidAmt ?? '';
          e.totalRemits = e?.totalRemits ?? '';
          e.blobName = e?.blobName ?? '';
          e.isEdiFileImported = e?.isEdiFileImported ?? false;
          return e;
        }) ?? [];
      this.claimTableData?.push({
        id: element?.id ?? '',
        claimId: element?.claimId ?? '',
        patientName: element?.patientName ?? '',
        defaultPatientId: element?.defaultPatientId ?? '',
        patientId: element?.patientId ?? '',
        appClaimStatus: element?.claimStatus ?? '',
        waystarClaimStatus: element?.waystarClaimStatus ?? '',
        remitClaimStatus: element?.waystarClaimStatus ?? '',
        claimProcessedDate:
          (element?.claimProcessedDate ?? '') === ''
            ? ''
            : new Date(element?.claimProcessedDate)?.toLocaleDateString(
              'en-US'
            ) ?? '',
        saleOrderId: element?.orderId ?? '',
        defaultSaleOrderId: element?.saleorderId ?? '',
        billedQty: totalItemQty ?? '',
        billedAmount: mtotalItemPrice ?? '',
        itemNames: itemNames ?? '',
        payerID: claimData?.payerID ?? '',
        payerName: claimData?.payerName ?? '',
        billingName: claimData?.billingName ?? '',
        claimServiceDate:
          serviceDate === ''
            ? ''
            : new Date(serviceDate)?.toLocaleDateString('en-US') ?? '',
        lstEOBs: lstEOb ?? [],
        currentBalance: (element?.currentBalance ?? '0.00') === '0' ? '0.00' : element?.currentBalance,
        totalEOBCount: element?.totalEOBCount ?? 0,
        importedEOBCount: element?.importedEOBCount ?? 0,
        isVoided: element?.isVoided
      });
    });

    this.dataSource = new MatTableDataSource(this.claimTableData);
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }

  openStatusEditModel(value?: { id?: string }) {
    const data: { id: string } = { id: value?.id };
    const dialogRef = this.dialog.open(ClaimStatusDialog, { data: data });
    dialogRef.afterClosed().subscribe(
      (result) => {

      },
      (err) => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      }
    );
  }

  downloadEOB(value: LstEOB) {
    this.isLoading = true;
    this.zermidClearingHouse.downloadEOBByBlobName(value?.blobName).subscribe(
      (response) => {
        if (
          typeof response === 'string' &&
          response &&
          response?.length !== 0
        ) {
          const filelocation: String = String(
            'data:application/pdf;base64,' + response
          );
          const link = document?.createElement('a');
          link.download = (value?.patAcctNum ?? '') + '.pdf';
          link.href = filelocation?.toString();
          link?.click();
        }
        this.isLoading = false;
      },
      (err) => {
        this.isLoading = false;
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      }
    );
  }

  getClaimStatusDropdown() {
    const billingDropdownInput: BillingDropdowns[] = [
      BillingDropdowns.ClaimStatus,
    ];
    this.billingDropdownService
      .getBillingDropdownsByInput(billingDropdownInput)
      .subscribe(
        (stateResponse) => {
          let reponse: BillingMasterDropdownDTO = stateResponse;
          this.drpClaimStatus = reponse?.claimStatus;
          const firstClaimStatusValue = this.drpClaimStatus?.filter(
            (element) => {
              return 'Claim Denied'.indexOf(element?.claimStatus) !== -1;
            }
          )[0];
          this.activatedRoute.queryParams
            .pipe(pluck('id'))
            .subscribe((params) => {
              this.getAllCalimsStatus(params);
              this.claimStatusForm.patchValue({
                drpStatus: params
              });
            });
        },
        (err) => {
          const data: HttpErrorResponse = err;
          Swal.fire({
            icon: 'info',
            text: data?.error?.error?.message,
          });
        }
      );
    const patientDropdownInput: PatientDropdowns[] = [
      PatientDropdowns.PayorLevels,
    ];
    this.patientMasterDropdownService
      .getPatientDropdownsByInput(patientDropdownInput)
      .subscribe(
        (response) => {
          this.drpPayorLevel = response?.payorLevels ?? [];
        },
        (err) => {
          const data: HttpErrorResponse = err;
          Swal.fire({
            icon: 'info',
            text: data?.error?.error?.message,
          });
        }
      );
  }

  exportTableData() {
    let claimStatusData: ExportClaimReportDTO[] = [];
    this.claimTableData?.forEach((element) => {
      claimStatusData?.push({
        claimNumber: element?.claimId,
        saleOrderId: element?.defaultSaleOrderId,
        patientId: element?.defaultPatientId,
        patientName: element?.patientName,
        itemCode: element?.itemNames,
        billedQuantity: element?.billedQty,
        billedAmount: element?.billedAmount,
        payerId: element?.payerID,
        payerName: element?.payerName,
        claimServiceDate: element?.claimServiceDate,
        providerName: element?.billingName,
        currentBalance: element?.currentBalance,
        status: element?.appClaimStatus,
        processedDate: element?.claimProcessedDate,
        postingCount: element?.importedEOBCount,
        waystarStatus: element?.waystarClaimStatus
      });
    });

    this.zermidClearingHouse
      .exportClaimReportByLstExportData(claimStatusData)
      .subscribe(
        (response) => {
          if (response && response?.length !== 0) {
            let filelocation: String = String(
              'data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,' +
              response
            );
            let link = document.createElement('a');
            link.download = 'Claim_Status' + '.xlsx';
            link.href = filelocation.toString();
            link.click();
            // Swal.fire({
            //   title: 'Success',
            //   html: 'Exported Successfully',
            //   icon: 'success',
            //   timer: 3000,
            //   timerProgressBar: true,
            // });
            this.toastr.success('Exported Successfully','Success')
          }
        },
        (err) => {


          const data: HttpErrorResponse = err;
          let errorMesg: string = data?.error;
          if (errorMesg?.includes('Sorry!, There is no data to export')) {
            errorMesg = 'Sorry!! There is no data in table to export';
          } else {
            errorMesg = data?.error?.error?.message;
          }
          // Swal.fire({
          //   icon: 'info',
          //   text: errorMesg,
          // });
          this.toastr.info(errorMesg)
        }
      );
  }
  //! Change Selected Claims Plan Type
  selChangePlanType(
    strPayor: string,
    selEobData: EOBHistoryDTO,
    selClaimData: any
  ) {
    try {
      let selClaimValue = this.claimTableData.filter(
        (e) => e === selClaimData
      )[0];
      const indexElm: number = this.claimTableData.indexOf(selClaimValue, 0);
      const selEob = selClaimValue?.lstEOBs?.filter(
        (eobs) => eobs === selEobData
      )[0];
      const indexEob: number = selClaimValue?.lstEOBs?.indexOf(selEob, 0);
      selEob.payorType = strPayor;
      selEob.a835 = this.file835Strm[selEob.id];
      selClaimValue?.lstEOBs?.splice(indexEob, 1, selEob);
      this.claimTableData = this.claimTableData.splice(
        indexElm,
        1,
        selClaimValue
      );
    } catch (error) {
      this.getAllCalimsStatus(this.claimStatusForm);
    }
  }
  importA837FiletoEOB(value: EOBHistoryDTO) {
    Swal.fire({
      title: 'Are you sure you want to Import?',
      text: "You won't be able to revert this change!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#34c38f',
      cancelButtonColor: '#f46a6a',
      confirmButtonText: 'Yes, Import it!',
    }).then((result) => {
      if (result.value) {
        this.isImportLoading = true;
        const claimId: string = value?.claimId;
        const oImport835File: Import835FileDTO = {
          fileContentAsBase64String: value?.a835,
          plan: value?.payorType,
          blobName: value?.blobName,
          eobHistoryId: value?.id,
        };
        this.insurancePaymentService
          .import835FileByClaimIdAndOImport835File(claimId, oImport835File)
          .subscribe(
            (response) => {
              try {


                let selUpdatedValue: claimStatus = this.claimTableData?.filter(
                  (e) => e?.id === value?.claimId
                )[0];
                const indexElm: number = this.claimTableData?.indexOf(
                  selUpdatedValue,
                  0
                );

                let selEob: EOBHistoryDTO = selUpdatedValue?.lstEOBs?.filter(
                  (eob) => eob?.id === value?.id
                )[0];
                const indexEob: number = selUpdatedValue?.lstEOBs?.indexOf(
                  selEob,
                  0
                );
                selUpdatedValue?.lstEOBs?.splice(indexEob, 1, response);
                this.claimTableData = this.claimTableData?.splice(
                  indexElm,
                  1,
                  selUpdatedValue
                );
              } catch (error) {
                this.getAllCalimsStatus(this.claimStatusForm);
              }
              this.getAllCalimsStatus(this.claimStatusForm);

              const Toast = Swal.mixin({
                toast: true,
                position: 'top-end',
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
              });
              Toast.fire({ icon: 'success', title: 'Import Successfully' });
              this.isImportLoading = false;
            },
            (err) => {
              const data: HttpErrorResponse = err;
              this.isImportLoading = false;
              Swal.fire({
                icon: 'info',
                text: data?.error?.error?.message,
              });
            }
          );
      }
    });
  }

  //! Read From File. and upload to Backend For EDI parsing
  eftImportFormFile(value: any, selElm: EOBHistoryDTO, selRecord: any) {
    const fileList: FileList = value;
    let file835: File = null;
    if (fileList?.length >= 2) {
      return;
    }
    for (let index = 0; index < fileList?.length; index++) {
      if (
        fileList[index]?.type === '' ||
        fileList[index]?.type === 'text/plain'
      ) {
        file835 = fileList?.item(index);
      }
    }
    if (!file835) {
      Swal.fire({ icon: 'info', text: 'Please Select Remits Claim File' });
      this.uploadFileReference.nativeElement.value = null;
      return;
    }

    this.file835Strm[selElm.id] = '';
    let reader = new FileReader();

    reader?.readAsDataURL(file835);
    reader.onload = () => {
      const rst = reader?.result?.toString();
      if (file835.type === '') {
        this.file835Strm[selElm.id] = rst?.slice(37) ?? '';
      }
      if (file835.type === 'text/plain') {
        this.file835Strm[selElm.id] = rst?.slice(23) ?? '';
      }
      this.selChangePlanType(selElm.payorType, selElm, selRecord);
    };
  }

  previewEobFilewithBlobName(eobBlobName: string) {
    if (eobBlobName) {
      const url = '/#/ViewDocumentPerviewByBlobId/' + eobBlobName + '/' + 4;
      window.open(url, '_blank');
    }
  }
  //! Set Order Status Voided

  setOrderStatusVoided(claimId: string) {
    if ((claimId ?? "") !== "") {
      Swal.fire({
        title: 'Are you sure you want to Set as Void Claim?',
        text: "You won't be able to Change this data!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#34c38f',
        cancelButtonColor: '#f46a6a',
        confirmButtonText: 'Yes, Change it!',
      }).then((result) => {
        if (result?.value) {
          const updateOrderBilling$ = this.claimProcessService.updateVoidedStatusByClaimIdAndClaimStatus(claimId, true).subscribe((x) => {
            this.claimSearchForm.patchValue({
              patientId: "", patientName: "", payerID: "", payerName: "", claimServiceDate: "",
              billingName: "", claimNumber: "", saleOrderId: "", currentBalance: "", itemCode: "",
              billedAmount: "", billedQty: "", appClaimStatus: "", zirmedClaimStatus: "", remitClaimStatus: "", claimProcessedDate: "",
            })
          }, err => {
            const data: HttpErrorResponse = err;
            Swal.fire({
              icon: 'info',
              text: data?.error?.error?.message,
            });
          })
          this.subscription$.push(updateOrderBilling$);
        }
      });
    }
  }
}

@Component({
  selector: 'claim-status-dialog',
  template: `
    <app-claim-event-view [claimId]="data?.id"></app-claim-event-view>
  `,
  styles: [``],
})
export class ClaimStatusDialog {
  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: { id: string }
  ) { }
}

export interface claimStatus {
  id: string;
  claimId: string;
  patientName: string;
  defaultPatientId: string;
  patientId: string;

  claimProcessedDate: string;
  saleOrderId: string;
  defaultSaleOrderId: string;
  billedQty: string;
  billedAmount: string;
  itemNames: string;
  payerID: string;
  payerName: string;
  billingName: string;
  claimServiceDate: string;
  lstEOBs: EOBHistoryDTO[];
  currentBalance: string;
  appClaimStatus: string;
  waystarClaimStatus: string;
  remitClaimStatus: string;
  totalEOBCount: number;
  importedEOBCount: number;
  isVoided: boolean;
}
