<!-- <div class="card card-body"> -->
    <div class="col-lg-12 content table-responsive" *ngIf="!isLoading">
        <ng-container>
            <table id="tbl" datatable [dtOptions]="dtPracticeTATOption"
                class="tbl hover row-border hover w-100 display">
                <thead>
                    <tr>
                        <th><mat-icon> wrap_text</mat-icon></th>
                        <th [matTooltip]="'TAT'">TAT</th>
                        <th [matTooltip]="'Fax Id'">Fax Id</th>
                        <th [matTooltip]="'Pages'" class="text-right">Pages</th>
                        <th [matTooltip]="'Received'" class="text-right">Received</th>
                        <th [matTooltip]="'Modified'" class="text-right">Modified</th>
                        <th [matTooltip]="'ChartNo'">Chart No</th>
                        <th [matTooltip]="'Patient Name'">Patient</th>
                        <th [matTooltip]="'Primary Insurance'">Primary Ins</th>
                        <th [matTooltip]="'Practice'">Practice</th>
                        <th [matTooltip]="'Upload Type'">Upload Type</th>
                        <th [matTooltip]="'Fax Number'">Fax Number</th>
                        <th [matTooltip]="'Mail'">Mail</th>
                        <th [matTooltip]="'Modified By'">Modified By</th>
                        <th [matTooltip]="'INS Group'">INS Group</th>
                        <th [matTooltip]="'Aging Days'" class="text-right">Aging Days</th>
                        <th [matTooltip]="'STAT'">STAT</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let data of inboundTableData" [ngClass]="{
                    'stat_highlight': data.isStat === 1 || data.successfulStatUpdate
                  }" (click)="fnSelectedRow(data.docId, data.patientId)">
                        <td class="dt-control" (click)="handleTableCellClick($event, data.patientNotes)"></td>

                        <td [ngStyle]="{'color': getValueColor(data.isTatDone)}">{{ data.tat ?
                            data.tat : '-'}}
                        </td>
                        <td [matTooltip]="data.defaultFaxId || '-'" (dblclick)="onRowDoubleClickV1(data?.defaultFaxId || '-')">
                            <a [routerLink]="'/patient_preview_tiff_document/'+ data.defaultFaxId+ '/' + data.docId" target="_blank" [class.disabled]="isEmpty(data.defaultFaxId)" class="preview-link">{{
                                data.defaultFaxId ?
                                data.defaultFaxId :
                                '-'}}</a>
                        </td>
                        <td class="text-right" [matTooltip]="data.docPageCount || '-'"
                            (dblclick)="onRowDoubleClickV1(data?.docPageCount || '-')">{{ data.docPageCount ?
                            data.docPageCount
                            :
                            '-'}}
                        </td>

                        <td [matTooltip]="data?.uploadedDate"
                            (dblclick)="onRowDoubleClickV1(data?.uploadedDate)" class="text-right">
                            {{ data?.uploadedDate }}
                        </td>

                        <td [matTooltip]="data?.lastModificationTime"
                            (dblclick)="onRowDoubleClickV1(data?.lastModificationTime)" class="text-right">
                            {{ data?.lastModificationTime }}
                        </td>
                        <td (dblclick)="onRowDoubleClickV1(data?.chartId || '-')">
                            <a [class.disabled]="isEmpty(data.chartId) || isEmpty(data.patientId)" class="preview-link" [routerLink]="'/patientCreateEditTab/' + data.patientId" target="_blank" [matTooltip]="data.chartId || '-'">{{ data.chartId || '-'}}</a>
                        </td>
                        <td [matTooltip]="data.patientName || '-'"
                            (dblclick)="onRowDoubleClickV1(data?.patientName || '-')">{{ data.patientName ?
                            data.patientName :
                            '-'}}
                        </td>
                        <td [matTooltip]="data.priPolicyName || '-'" (dblclick)="onRowDoubleClickV1(data?.priPolicyName || '-')">
                            {{ data.priPolicyName ? data.priPolicyName
                            :
                            '-'
                            }}
                        </td>
                        <td [matTooltip]="data.practice || '-'" (dblclick)="onRowDoubleClickV1(data?.practice || '-')">
                            {{ data.practice ? data.practice : '-'}}</td>

                        <td [matTooltip]="data.uploadType || '-'" (dblclick)="onRowDoubleClickV1(data?.uploadType || '-')">
                            {{ data.uploadType ? data.uploadType : '-'}}</td>

                        <td [matTooltip]="data.faxNumber || '-'" (dblclick)="onRowDoubleClickV1(data?.faxNumber || '-')">
                            {{ data.faxNumber ? data.faxNumber : '-'}}</td>

                        <td [matTooltip]="data.mail || '-'" (dblclick)="onRowDoubleClickV1(data?.mail || '-')">
                            {{ data.mail ? data.mail : '-'}}</td>

                        <td [matTooltip]="data.lastModifierBy || '-'"
                            (dblclick)="onRowDoubleClickV1(data?.lastModifierBy || '-')">{{ data.lastModifierBy ?
                            data.lastModifierBy :
                            '-'}}
                        </td>
                        <td [matTooltip]="data.insuranceGroup || '-'"
                            (dblclick)="onRowDoubleClickV1(data?.insuranceGroup || '-')">
                            {{ data.insuranceGroup !== null ? data.insuranceGroup : '-' }}
                        </td>
                        <td class="text-right" [matTooltip]="data.agingsDays || '-'"
                            (dblclick)="onRowDoubleClickV1(data?.agingsDays || '-')">{{ data.agingsDays ?
                            data.agingsDays : '-'}}
                        </td>
                        <td>
                            <!-- <mat-checkbox [(ngModel)]="data.isStat" (change)="isStatUpdate(data.docId)">
                            </mat-checkbox> -->
                            <mat-checkbox [(ngModel)]="data.isStat" (change)="isStatUpdate($event,data)">
                                <span [hidden]="true">{{ data.isStat ? "True" : "False" }}</span>
                            </mat-checkbox>
                        </td>
                    </tr>
                </tbody>
                <!-- Footer -->
                <tfoot>
                    <tr>
                        <th><mat-icon> wrap_text</mat-icon></th>
                        <th>
                            <input matInput class="textbox-border-class" type="text" placeholder="TAT"
                            name="search-tat" />
                        </th>
                        <th>
                            <input matInput class="textbox-border-class" type="text" placeholder="Fax Id"
                                name="search-faxid" />
                        </th>
                        <th>
                            <input matInput type="text" class="textbox-border-class text-right" placeholder="Pages"
                                name="search-pages" />
                        </th>
                        <th>
                            <input matInput type="text" class="textbox-border-class " placeholder="Received"
                                name="search-received" />
                        </th>
                        <th>
                            <input matInput type="text" class="textbox-border-class " placeholder="Modified"
                                name="search-modified" />
                        </th>
                        <th>
                            <input matInput class="textbox-border-class" type="text" placeholder="Chart No"
                                name="search-chartNo" />
                        </th>
                        <th>
                            <input matInput class="textbox-border-class" type="text" placeholder="Patient"
                                name="search-patient" />
                        </th>
                        <th>
                            <input matInput class="textbox-border-class" type="text" placeholder="Primary Ins"
                                name="search-primaryIns" />
                        </th>
                        <!-- <th  >
                            <input matInput class="textbox-border-class" type="text" placeholder="Upload Type"
                                name="search-uploadType" />
                        </th> -->
                        <!-- <th  >
                            <input matInput class="textbox-border-class" type="text" placeholder="Sorting"
                                name="search-sorting" />
                        </th> -->

                        <th>
                            <input matInput class="textbox-border-class" type="text" placeholder="Practice"
                                name="search-practice" />
                        </th>

                        <th>
                            <input matInput class="textbox-border-class" type="text" placeholder="Upload Type"
                                name="search-uploadType" />
                        </th>
                         <th>
                            <input matInput class="textbox-border-class" type="text" placeholder="Fax Number"
                                name="search-faxNumber" />
                        </th> 
                        <th>
                            <input matInput class="textbox-border-class" type="text" placeholder="Mail"
                                name="search-mail" />
                        </th>

                        <th>
                            <input matInput class="textbox-border-class" type="text" placeholder="Modified By"
                                name="search-modifiedBy" />
                        </th>
                        <th>
                            <input matInput class="textbox-border-class" type="text" placeholder="INS Group"
                                name="search-insGroup" />
                        </th>
                        <th>
                            <input matInput class="textbox-border-class text-right" type="text" placeholder="Aging Days"
                                name="search-tat" />
                        </th>
                        <th><mat-icon>no_encryption</mat-icon></th>
                    </tr>
                </tfoot>
            </table>
        </ng-container>
    </div>
<!-- </div> -->

    <div class="col-12" *ngIf="isLoading">
        <div class="d-flex justify-content-center">
            <img class="img-responsive pb-2 mr-1" src="assets/gifs/Infinity-loader.gif" />
        </div>
    </div>
