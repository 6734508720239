import type {
  DazzlePrintingLabelParamsDto,
  PrintTicketDetails,
  SInvTransactionDTO,
  ShippingApprovalListDTO,
  ShippingApprovedNotesDTO,
  ShippingApprovedProductListDTO,
  StockLotNoQty,
  StockSerialLotNoDTO,
  aprovalNoAndAmt,
  mmShippingListOptionsDTO,
} from './dto/models';
import { RestService } from '@abp/ng.core';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ShippingListService {
  apiName = 'orderManagement';

  computeFFStatByGProductID = (gProductID: string) =>
    this.restService.request<any, number>(
      {
        method: 'POST',
        url: '/api/orderApp/shipping-list/compute-fFStat',
        params: { gProductID },
      },
      { apiName: this.apiName }
    );

  computeQOHByGProductIdAndGLocationId = (
    gProductId: string,
    gLocationId: string
  ) =>
    this.restService.request<any, number>(
      {
        method: 'POST',
        url: '/api/orderApp/shipping-list/compute-qOH',
        params: { gProductId, gLocationId },
      },
      { apiName: this.apiName }
    );

  computeQOOByGProductIdAndIQtyAndLocationId = (
    gProductId: string,
    iQty: number,
    locationId: string
  ) =>
    this.restService.request<any, number>(
      {
        method: 'POST',
        url: '/api/orderApp/shipping-list/compute-qOO',
        params: { gProductId, iQty, locationId },
      },
      { apiName: this.apiName }
    );

  computeSLNDispStatByGLocationIdAndGSerialNoAndGProductIdAndIQtyAndGOrderIdAndSChartNo =
    (
      gLocationId: string,
      gSerialNo: string,
      gProductId: string,
      iQty: number,
      gOrderId: string,
      sChartNo: string
    ) =>
      this.restService.request<any, SInvTransactionDTO[]>(
        {
          method: 'POST',
          url: '/api/orderApp/shipping-list/compute-sLNDisp-stat',
          params: {
            gLocationId,
            gSerialNo,
            gProductId,
            iQty,
            gOrderId,
            sChartNo,
          },
        },
        { apiName: this.apiName }
      );

  computeSLNQOHByGProductIdAndGLocationIdAndGLotSerial = (
    gProductId: string,
    gLocationId: string,
    gLotSerial: string
  ) =>
    this.restService.request<any, StockLotNoQty[]>(
      {
        method: 'POST',
        url: '/api/orderApp/shipping-list/compute-sLNQOH',
        params: { gProductId, gLocationId, gLotSerial },
      },
      { apiName: this.apiName }
    );

  getAvailStatusCalculationByGProductIDAndIQtyAndGLocationIdAndIAvailQty = (
    gProductID: string,
    iQty: number,
    gLocationId: string,
    iAvailQty: number
  ) =>
    this.restService.request<any, number>(
      {
        method: 'GET',
        url: `/api/orderApp/shipping-list/avail-status-calculation/${gLocationId}`,
        params: { gProductID, iQty, iAvailQty },
      },
      { apiName: this.apiName }
    );

  getLotSerialNoByGProductIdAndGLocationId = (
    gProductId: string,
    gLocationId: string
  ) =>
    this.restService.request<any, StockSerialLotNoDTO[]>(
      {
        method: 'GET',
        url: '/api/orderApp/shipping-list/lot-serial-no',
        params: { gProductId, gLocationId },
      },
      { apiName: this.apiName }
    );

  getPatientBalNotesDetailsByGpatId = (gpatId: string) =>
    this.restService.request<any, ShippingApprovedNotesDTO>(
      {
        method: 'GET',
        url: `/api/orderApp/shipping-list/patient-bal-notes-details/${gpatId}`,
      },
      { apiName: this.apiName }
    );
  getPatientBalNotesDetailsByOrderId = (orderId: string) =>
    this.restService.request<any, ShippingApprovedNotesDTO>(
      {
        method: 'GET',
        url: `/api/orderApp/shipping-list/patient-bal-notes-details-v1/${orderId}`,
      },
      { apiName: this.apiName }
    );
  getShippingApprovedList = () =>
    this.restService.request<any, ShippingApprovalListDTO[]>(
      {
        method: 'GET',
        url: '/api/orderApp/shipping-list/shipping-approved-list',
      },
      { apiName: this.apiName }
    );

  getShippingItemDataByGOrderId = (gOrderId: string) =>
    this.restService.request<any, string>(
      {
        method: 'GET',
        responseType: 'text',
        url: `/api/orderApp/shipping-list/shipping-item-data/${gOrderId}`,
      },
      { apiName: this.apiName }
    );

  getShippingItemDetailsByGOrderId = (gOrderId: string) =>
    this.restService.request<any, ShippingApprovedProductListDTO[]>(
      {
        method: 'GET',
        url: `/api/orderApp/shipping-list/shipping-item-details/${gOrderId}`,
      },
      { apiName: this.apiName }
    );

  getShippingOrderItemDetailsByGOrderId = (gOrderId: string) =>
    this.restService.request<any, ShippingApprovedProductListDTO[]>(
      {
        method: 'GET',
        url: `/api/orderApp/shipping-list/shipping-order-item-details/${gOrderId}`,
      },
      { apiName: this.apiName }
    );

  printMailingLabelByGOrderIdAndReturnLabelAndSendToBilling = (
    gOrderId: string,
    ReturnLabel: boolean,
    SendToBilling: boolean
  ) =>
    this.restService.request<any, string>(
      {
        method: 'POST',
        responseType: 'text',
        url: `/api/orderApp/shipping-list/print-mailing-label/${gOrderId}`,
        params: { returnLabel: ReturnLabel, sendToBilling: SendToBilling },
      },
      { apiName: this.apiName }
    );

  promptForProductUPCByGProductId = (gProductId: string) =>
    this.restService.request<any, number>(
      {
        method: 'POST',
        url: `/api/orderApp/shipping-list/prompt-for-product-uPC/${gProductId}`,
      },
      { apiName: this.apiName }
    );

  getShippingTime = () =>
    this.restService.request<any, mmShippingListOptionsDTO>(
      {
        method: 'GET',
        url: '/api/orderApp/shipping-list/get-shipping-time',
      },
      { apiName: this.apiName }
    );
  splitOrderByOrderIdAndOrderDateAndOrderItemIds = (
    OrderId: string,
    OrderDate: string,
    OrderItemIds: string[]
  ) =>
    this.restService.request<any, string>(
      {
        method: 'POST',
        responseType: 'text',
        url: `/api/orderApp/shipping-list/split-order/${OrderId}`,
        params: { orderDate: OrderDate },
        body: OrderItemIds,
      },
      { apiName: this.apiName }
    );

  processToBillingByGOrderIdAndSTrackingNumber = (
    gOrderId: string,
    sTrackingNumber: string
  ) =>
    this.restService.request<any, string>(
      {
        method: 'POST',
        responseType: 'text',
        url: `/api/orderApp/shipping-list/process-to-billing/${gOrderId}`,
        params: { sTrackingNumber },
      },
      { apiName: this.apiName }
    );

  updateBarCodeandWeightByInput = (input: ShippingApprovedProductListDTO) =>
    this.restService.request<any, ShippingApprovedProductListDTO>(
      {
        method: 'PUT',
        url: '/api/orderApp/shipping-list/bar-codeand-weight',
        body: input,
      },
      { apiName: this.apiName }
    );

  orderTicketDetailsByOrderId = (orderId: string) =>
    this.restService.request<any, PrintTicketDetails>(
      {
        method: 'POST',
        url: `/api/orderApp/shipping-list/order-ticket-details/${orderId}`,
      },
      { apiName: this.apiName }
    );

  orderTicketDetailsV1ByOrderId = (orderId: string) =>
    this.restService.request<any, PrintTicketDetails>(
      {
        method: 'POST',
        url: `/api/orderApp/shipping-list/order-ticket-details-v1/${orderId}`,
      },
      { apiName: this.apiName }
    );

  generateDazzleShippingLabelXMLByPrintingParamsDto = (
    printingParamsDto: DazzlePrintingLabelParamsDto
  ) =>
    this.restService.request<any, string>(
      {
        method: 'POST',
        responseType: 'text',
        url: '/api/orderApp/shipping-list/generate-dazzle-shipping-label-xML',
        body: printingParamsDto,
      },
      { apiName: this.apiName }
    );
  getApprovalNoByOrderId = (orderId: string) =>
    this.restService.request<any, aprovalNoAndAmt[]>(
      {
        method: 'GET',
        url: `/api/orderApp/shipping-list/get-approval-no/${orderId}`,
      },
      { apiName: this.apiName }
    );
  constructor(private restService: RestService) {}
}
