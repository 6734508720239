import { Component, OnInit, ViewChild } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { IdentityUserUsingTenantIdDto } from 'projects/admin/src/app/admin-proxy/platform-app-management/rcm/platform-management/user/models';
import { UserService } from 'projects/admin/src/app/admin-proxy/platform-app-management/rcm/platform-management/user';
import { UserByRoleDTO } from 'projects/admin/src/app/admin-proxy/platform-app-management/identity';
import { HttpErrorResponse } from '@angular/common/http';
import Swal from 'sweetalert2';
import { Observable, Subscription } from 'rxjs';
import { MatSelect, MatSelectChange } from '@angular/material/select';
import { map, startWith } from 'rxjs/operators';
import { OrganizationUnitService } from 'projects/admin/src/app/admin-proxy/platform-app-management/rcm/platform-management/organization-units/organization-unit.service';
import { MatDatepicker, MatDatepickerInputEvent } from '@angular/material/datepicker';
import { DatePipe } from '@angular/common';
import { RoleService } from 'projects/admin/src/app/admin-proxy/platform-app-management/rcm/platform-management/role/role.service';
import { InboundDocumentService } from 'projects/admin/src/app/admin-proxy/platform-app-management/rcm/platform-management/inbound/inbound-document.service';
import { SearchCalendarDTO } from 'projects/admin/src/app/admin-proxy/platform-app-management/rcm/platform-management/inbound/dto/models';
import { CalendarStatus } from 'projects/on-time-schedules/src/app/onTime-proxy/rcm/on-time-management/on-time-schedule/calendar-status.enum';
import { Title } from '@angular/platform-browser';
import { ToastrService } from 'ngx-toastr';
import { ScheduleDetailsService } from 'projects/admin/src/app/admin-proxy/platform-app-management/rcm/platform-management/appointments/schedule-details.service';
import { scheduleFilterDTO } from 'projects/admin/src/app/admin-proxy/platform-app-management/rcm/platform-management/appointments/dto/models';
import moment from 'moment';
import { LocaleConfig } from 'ngx-daterangepicker-material';
import { CommonService } from 'projects/shared/src/app/services/common.service';
@Component({
  selector: 'app-calendar-list-v1',
  templateUrl: './calendar-list-v1.component.html',
  styleUrls: ['./calendar-list-v1.component.scss']
})
export class CalendarListV1Component implements OnInit {
  isShowSpinner: boolean = false;
  private calendarListAPICall: Subscription;
  isLoading: boolean = false;
  isSearch: boolean = false;
  isUserLoading: boolean = false;
  isBranchLoading:boolean = false;
  organizationUnitName: string;
  statusName:string;
  userDesignation: string;
  calendarDataList: any;
  // drpUsertype: any;
  tenantId: string = '';
  public drpBranch: Branch[] = [];
  public drpStatustype: Status[] = [];
  public brnachList: Observable<Branch[]> | undefined;
  public statusList: Observable<Status[]> | undefined;
  selectedBranchList: string[] = null;
  selectedStatusList: string[] = null;
  // lstFilterUsers: UserByRoleDTO[] = [];
  public drpUsertype: User[] = [];
  public lstFilterUsers: any | undefined;
  BranchSelectAllOption = false;
  StatusSelectAllOption = false;
  calendarSearchForm: FormGroup;
  UserTypeControl = new FormControl();
  subscription$: Subscription[] = [];
  selectedUserList: string[] = null;
  UserSelectAllOption: boolean = false;
  USerSearch = '';
  selectedOption: number = 1;
  selectedDate: any;
  @ViewChild('picker') picker: MatDatepicker<Date>;
  fromDate: any;
  toDate: any;

  fromDateRange: string;
  toDateRange: string;

  sesUserId:string;
  dScheduleFromDate: Date;
  dScheduleToDate: Date;
  // ComplaincesTypes = ComlplainceFilter;
  // activeTab: ComlplainceFilter = this.ComplaincesTypes.New;

  CalenderStatus = CalendarStatus;
  activeStatus: CalendarStatus = this.CalenderStatus.Scheduled;
  isShowstatusLabel:boolean = false;
  roleDropdownValue: string;
  isMandatory = false;
  role: string;
  dataStatusList: any;
  selectedRange: any = { startDate: null, endDate: null };
  ranges: any;
  calendarLocale: LocaleConfig;
  // isResetSearch:boolean=true;
  constructor(
    private userService: UserService,
    private fb: FormBuilder,
    private branchService: OrganizationUnitService,
    private inboundDocumentService: InboundDocumentService,
    private datepipe: DatePipe,
    private roleService: RoleService,
    private toastr: ToastrService,
    private title : Title,
    private scheduledetailsService : ScheduleDetailsService,
    private commonService:CommonService

  ) { }

  ngOnInit(): void {
    this.title.setTitle('Qsecure | Schedule List');
    this.sesUserId = localStorage.getItem("userId") ?? "";
    this.dataStatusList = [
      { statusName: this.CalenderStatus.All,statusID: this.CalenderStatus.All },
      { statusName: this.CalenderStatus.Cancelled, statusID: this.CalenderStatus.Cancelled },
      { statusName: this.CalenderStatus.Completed,statusID: this.CalenderStatus.Completed },
      { statusName: this.CalenderStatus.Rescheduled, statusID: this.CalenderStatus.Rescheduled },
      { statusName: this.CalenderStatus.Scheduled, statusID: this.CalenderStatus.Scheduled },
    ]

    this.calendarSearchForm = this.fb.group({
      drpUsertype: new FormControl(''),
      txtUserType: new FormControl(''),

      drpStatustype: new FormControl(''),
      txtStatus:new FormControl(''),

      drpBranch: new FormControl(''),
      txtBranch: new FormControl(''),

      txtDateValue: new FormControl('',[Validators.required]),

      // txtStartDateValue: new FormControl('', [Validators.required]),
      // txtEndDateValue: new FormControl('', [Validators.required]),

    });

    this.fromDate = this.convertISODateStringToCustomFormat(
      this. getCTStartOfMonth()
     )
    this.toDate = this.convertISODateStringToCustomFormat(
      this.ctDateNow()
    );

    // this.calendarSearchForm.patchValue({
    //   txtDateValue:
    //     this. getCTStartOfMonth()
    //     +'-'+
    //     this.ctDateNow()
    //  });

    this.convertISODateStringToCustomFormat(
    this. getCTStartOfMonth()
    ) +'-'+ this.convertISODateStringToCustomFormat(
    this.ctDateNow()
  );

    this.tenantId = localStorage.getItem('tenantId') ?? '';
    this.getUserByTenant();
    this.getBranchList();
    this.getStatusListDrp();
    this.calendarSearchForm.patchValue({ drpUsertype: [this.sesUserId] });
    // this.dScheduleFromDate = this.getCTStartOfMonth();
    // this.dScheduleToDate = this.ctDateNow();
    this.fnSearchCalendarList();

    (this.selectedRange.startDate = this.getCTStartOfMonth()),
      (this.selectedRange.endDate = this.ctDateNow());
      this.ranges = {
        'Today': [moment(), moment()],
        'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
        'Last 7 Days': [moment().subtract(6, 'days'), moment()],
        'Last 30 Days': [moment().subtract(29, 'days'), moment()],
        'This Month': [moment().startOf('month'), moment().endOf('month')],
      };

      this.calendarLocale = {
        customRangeLabel: 'Pick a date...',
        applyLabel: 'Apply',
        clearLabel: 'Clear',
        format: 'MM/DD/YYYY',
        daysOfWeek: ['su', 'mo', 'tu', 'we', 'th', 'fr', 'sa'],
        monthNames: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
        firstDay: 1
      };

  }

  // ngAfterViewInit() {
  //   window.addEventListener('scroll', this.onTableScroll, true);
  // }

  // dateRangeValidator(group: FormGroup): { [key: string]: any } | null {
  //   const startDate = group.get('txtDateValue').value;
  //   const endDate = group.get('txtDate').value;

  //   if (startDate && endDate && startDate > endDate) {
  //     return { invalidDateRange: true };
  //   }

  //   if (startDate && !endDate) {
  //     return { endDateRequired: true };
  //   }

  //   return null;
  // }
  //   onTableScroll = (event: Event) => {
  //     console.log(event);
  // };
  datesUpdateds(e){
    if(e.startDate !=null || e.endDate !=null){
      const startDate = new Date(e.startDate);
      const endDate = new Date(e.endDate);
      this.fromDate = this.datepipe.transform(startDate,"yyyy-MM-dd")
      this.toDate = this.datepipe.transform(endDate,"yyyy-MM-dd")
      // this.getClaimBatchdetails();
    }
  }

  convertISODateStringToCustomFormat(isoDateString: any): string {
    const inputDate = new Date(isoDateString);

    // Extracting date components
    const month = (inputDate.getMonth() + 1).toString().padStart(2, '0');
    const day = inputDate.getDate().toString().padStart(2, '0');
    const year = inputDate.getFullYear().toString();

    // Formatted date string in MM/DD/YYYY format
    const formattedDateString = `${month}/${day}/${year}`;

    return formattedDateString;
  }

  getCTStartOfMonth(): Date {
    let usaTime = new Date().toLocaleString('en-US', {
      timeZone: 'America/Chicago',
    });
    const dateObj = new Date(usaTime);
    dateObj.setDate(1);
    dateObj.setHours(0, 0, 0, 0);
    return dateObj;
  }

  ctDateNow(): Date {
    let usaTime = new Date().toLocaleString('en-US', {
      timeZone: 'America/Chicago',
    });
    const dateObj = new Date(usaTime);
    dateObj.setHours(0, 0, 0, 0);
    return dateObj;
  }

  addSlashToDate(event: Event): void {
    const input = event.target as HTMLInputElement;
    const inputValue = input.value;
    input.value = inputValue
      .replace(/^(\d{2})(\d{1,2})/, '$1/$2')
      .replace(/^(\d{2}\/\d{2})(\d+)/, '$1/$2')
      .replace(/[^\d\/]/g, '');
  }

  onDateRangeChange(event: MatDatepickerInputEvent<Date>): void {
    // if (this.calendarSearchForm.get('txtDateValue').value && this.calendarSearchForm.get('txtDate').value) {
    //   this.calendarSearchForm.get('txtDate').setErrors(null);
    // }
    // const startDate = this.calendarSearchForm.get('txtDateValue').value;
    // const endDate = this.calendarSearchForm.get('txtDate').value;
    // this.fromDate = this.datepipe.transform(startDate, 'MM/dd/yyyy');
    // this.toDate = this.datepipe.transform(endDate, 'MM/dd/yyyy');
    // if (startDate != null && endDate != null) {
    //   //   this.loadOrderListV2(
    //   //     this.fromDate,
    //   //     this.toDate,
    //   //     this.orderForm.value.drpCSR,
    //   //     this.orderForm.value.drpOrderStatus,
    //   //     null
    //   //   );
    // }
  }

  //GET USER DROP DOWN
  getUserByTenant() {
    this.isUserLoading = true;
    const getBranchList = this.userService
      .getUsersByTenant()
      .subscribe(
        (response) => {
          this.isUserLoading = false;
          response?.forEach((element) => {
            this.drpUsertype.push({
              userId: element.userId,
              userDesignation: element.userDesignation,
            });
          });
          // this.drpUsertype=this.drpUsertype.sort((a, b) => a.userDesignation.localeCompare(b.userDesignation))
          this.lstFilterUsers = this.calendarSearchForm
            ?.get('txtUserType')
            ?.valueChanges?.pipe(
              startWith(''),
              map((value) =>
                this.drpUsertype?.filter((option) =>
                  option?.userDesignation
                    ?.toLowerCase()
                    ?.includes(value?.toLowerCase() ?? '')
                )
              )
            );
        },
        (err) => {
          this.isUserLoading = false;
          const data: HttpErrorResponse = err;
          Swal.fire({
            icon: 'info',
            text: data?.error?.error?.message,
          });
        }
      );
    this.subscription$.push(getBranchList);
  }

  //User Multi Select
  onSelectionUserChange(event: MatSelectChange): void {
    this.selectedUserList = event.value;
    if (this.selectedUserList.includes('selectAll')) {
      this.UserSelectAllOption = true;
      if (this.UserSelectAllOption) {

        this.selectedUserList = this.drpUsertype.map(user => user.userId);
        this.calendarSearchForm
          .get('drpUsertype')
          .patchValue(this.selectedUserList);

      }
    } else if (this.selectedUserList.includes('DeselectAll')) {
      this.selectedUserList = [];
      this.UserSelectAllOption = false;
      this.calendarSearchForm
        .get('drpUsertype')
        .patchValue([]);
    }
    else {
      this.UserSelectAllOption =
        this.selectedUserList.length === this.drpUsertype.length;
    }
    // this.getTableData();
  }


  //GET BRANCH DROP DOWN
  getBranchList() {
    this.isBranchLoading = true;
    const getBranchList = this.branchService
      // .getBranchListV1(this.tenantId,false)
      .getBranchListBasedOnSchedule(this.tenantId)
      .subscribe(
        (response) => {
          this.isBranchLoading = false;
          response?.forEach((element) => {
            this.drpBranch.push({
              id: element.id,
              organizationUnitName: element.organizationUnitName,
            });
          });
          this.drpBranch=this.drpBranch.sort((a, b) => a.organizationUnitName.localeCompare(b.organizationUnitName))
          this.brnachList = this.calendarSearchForm
            ?.get('txtBranch')
            ?.valueChanges?.pipe(
              startWith(''),
              map((value) =>
                this.drpBranch?.filter((option) =>
                  option?.organizationUnitName
                    ?.toLowerCase()
                    ?.includes(value?.toLowerCase() ?? '')
                )
              )
            );

          this.onBranchSelectionChange({ value: 'selectAll' } as MatSelectChange);
        },
        (err) => {
          this.isBranchLoading = false;
          const data: HttpErrorResponse = err;
          Swal.fire({
            icon: 'info',
            text: data?.error?.error?.message,
          });
        }
      );
    this.subscription$.push(getBranchList);
  }


  // GET Status Drop Dowm
  getStatusListDrp() {
    const getStatusList = this.scheduledetailsService
      .getAppointmentStatus()
      .subscribe(
        (response) => {
          response?.items.forEach((element) => {
            this.drpStatustype.push({
              statusID: element.id,
              statusName: element.description,
            });
          });
          this.drpStatustype=this.drpStatustype.sort((a, b) => a.statusName.localeCompare(b.statusName))
          this.statusList = this.calendarSearchForm
            ?.get('txtStatus')
            ?.valueChanges?.pipe(
              startWith(''),
              map((value) =>
                this.drpStatustype?.filter((option) =>
                  option?.statusName
                    ?.toLowerCase()
                    ?.includes(value?.toLowerCase() ?? '')
                )
              )
            );

          // this.onStatusSelectionChange({ value: 'selectAll' } as MatSelectChange);
        },
        (err) => {}
      );
    this.subscription$.push(getStatusList);
  }

  //Multi Select Branch
  onBranchSelectionChange(event: MatSelectChange): void {
    this.selectedBranchList = event.value;
    if (this.selectedBranchList.includes('selectAll')) {
      // this.BranchSelectAllOption = !this.BranchSelectAllOption;
      this.BranchSelectAllOption = true;
      if (this.BranchSelectAllOption) {
        // If 'Select All' is selected, set the selectedBranchList to include all branch IDs
        this.selectedBranchList = this.drpBranch.map(branch => branch.id);
        this.calendarSearchForm
          .get('drpBranch')
          .patchValue(this.selectedBranchList);

      }
    } else if (this.selectedBranchList.includes('DeselectAll')) {
      this.selectedBranchList = [];
      this.BranchSelectAllOption = false;
      this.calendarSearchForm
        .get('drpBranch')
        .patchValue([]);
    }
    else {
      this.BranchSelectAllOption =
        this.selectedBranchList.length === this.drpBranch.length;
    }
    // this.getTableData();
  }

    //Multi Status Brop Down
    onStatusSelectionChange(event: MatSelectChange): void {
      this.selectedStatusList = event.value;
      if (this.selectedStatusList.includes('selectAll')) {
        this.StatusSelectAllOption = true;
        if (this.StatusSelectAllOption) {
          // If 'Select All' is selected, set the selectedStatusList to include all branch IDs
          this.selectedStatusList = this.drpStatustype.map(branch => branch.statusID);
          this.calendarSearchForm
            .get('drpStatustype')
            .patchValue(this.selectedStatusList);

        }
      } else if (this.selectedStatusList.includes('DeselectAll')) {
        this.selectedStatusList = [];
        this.StatusSelectAllOption = false;
        this.calendarSearchForm
          .get('drpStatustype')
          .patchValue([]);
      }
      else {
        this.StatusSelectAllOption =
          this.selectedStatusList.length === this.drpStatustype.length;
      }
      // this.getTableData();
    }

  onRoleChange(role) {
    this.roleDropdownValue = role.value;
    this.isMandatory = false;
  }


  // fnSearchCalendarList() {
  //   this.isLoading = true;
  //   this.isShowSpinner = true;
  //   this.isSearch = true;
  //   // const startDate = this.datepipe.transform(this.calendarSearchForm.value.txtDateValue, 'yyyy-MM-dd');
  //   // const endDate = this.datepipe.transform(this.calendarSearchForm.value.txtDate, 'yyyy-MM-dd');
  //   const startDate = this.datepipe.transform(this.dScheduleFromDate, 'yyyy-MM-dd');
  //   const endDate = this.datepipe.transform(this.dScheduleToDate, 'yyyy-MM-dd');
  //   // let statusGet = this.calendarSearchForm.value.drpStatustype;
  //     // if (statusGet === 'All') {
  //     //   statusGet = null;
  //     // }

  //   //  const startDate = this.calendarSearchForm.value.txtDateValue ? new Date(this.calendarSearchForm.value.txtDateValue).toLocaleDateString('en-US') : '';
  //   //  const endDate = this.calendarSearchForm.value.txtDate ? new Date(this.calendarSearchForm.value.txtDate).toLocaleDateString('en-US') : '';
  //   let calendarDTO: SearchCalendarDTO = {
  //     roles: null,
  //     users: this.calendarSearchForm.value.drpUsertype,
  //     location: this.calendarSearchForm.value.drpBranch || null,
  //     patient: null,
  //     fromDate: startDate,
  //     toDate: endDate,
  //     // status: this.calendarSearchForm.value.drpStatustype,
  //     status: this.calendarSearchForm.value.drpStatustype === '' || this.calendarSearchForm.value.drpStatustype === null ? "All" : this.calendarSearchForm.value.drpStatustype ,
  //   };

  //   try {
  //     if (this.calendarListAPICall) {
  //       this.calendarListAPICall.unsubscribe();
  //     }
  //     this.calendarListAPICall = this.inboundDocumentService.searchCalendarByCalendarDTO(calendarDTO)
  //       .subscribe((response) => {
  //         this.calendarDataList = response['items'];
  //         this.isShowSpinner = false;
  //       },
  //         (error) => {
  //           this.isLoading = false;
  //           this.isShowSpinner = false;
  //           this.isSearch = false;
  //           const data: HttpErrorResponse = error;
  //           this.toastr.warning(data?.error?.error?.message, 'Warning');
  //         },
  //         () => {
  //           this.isLoading = false;
  //           this.isShowSpinner = false;
  //           this.isSearch = false;
  //         }
  //       );
  //   } catch (error) {
  //     // const data: HttpErrorResponse = error;
  //     // Swal.fire({
  //     //   icon: 'info',
  //     //   text: data?.error?.message,
  //     // });
  //     this.isLoading = false;
  //     this.isShowSpinner = false;
  //     this.isSearch = false;
  //   } finally {
  //     // Any cleanup code can go here
  //   }
  // }


  fnSearchCalendarList() {
    let isShowstatus = this.calendarSearchForm.value.drpStatustype || null;
    if(isShowstatus === null){
      this.isShowstatusLabel=true;
    }else{this.isShowstatusLabel = false}

    this.isLoading = true;
    this.isShowSpinner = true;
    this.isSearch = true;
    // const startDate = this.datepipe.transform(this.dScheduleFromDate, 'yyyy-MM-dd');
    // const endDate = this.datepipe.transform(this.dScheduleToDate, 'yyyy-MM-dd');
    // const startDate = this.datepipe.transform(this.dScheduleFromDate, 'MM/dd/yyyy');
    // const endDate = this.datepipe.transform(this.dScheduleToDate, 'MM/dd/yyyy');
    const startDate = this.datepipe.transform(this.fromDate, 'yyyy-MM-dd');
    const endDate = this.datepipe.transform(this.toDate, 'yyyy-MM-dd');
    let calendarDTO: scheduleFilterDTO = {
      // startDate: startDate,
      // endDate: endDate,
      startDate: startDate,
      endDate: endDate,
      type: null,
      users: this.calendarSearchForm.value.drpUsertype,
      location: this.calendarSearchForm.value.drpBranch || null,
      status: this.calendarSearchForm.value.drpStatustype || null,
    };

    try {
      if (this.calendarListAPICall) {
        this.calendarListAPICall.unsubscribe();
      }
      this.calendarListAPICall = this.scheduledetailsService.getSchedulesByFilterByDto(calendarDTO)
        .subscribe((response) => {
          // this.calendarDataList = response['items'];
          this.calendarDataList = response.items;
          this.calendarDataList = this.calendarDataList.map((calendar) => {
            return {
              ...calendar,
              fromDate: this.commonService.getFormattedDateZone(
                calendar.fromTime
              ),
              fromTime: this.commonService.getFormattedTimeZone(
                calendar.fromTime
              ),
              creationTime: this.commonService.getFormattedDateTimeZone(
                calendar.creationTime
              ),
              lastModifiedTime: this.commonService.getFormattedDateTimeZone(
                calendar.lastModifiedTime
              )
            };
          });
          this.isShowSpinner = false;
        },
          (error) => {
            this.isLoading = false;
            this.isShowSpinner = false;
            this.isSearch = false;
            const data: HttpErrorResponse = error;
            this.toastr.warning(data?.error?.error?.message, 'Warning');
          },
          () => {
            this.isLoading = false;
            this.isShowSpinner = false;
            this.isSearch = false;
          }
        );
    } catch (error) {
      // const data: HttpErrorResponse = error;
      // Swal.fire({
      //   icon: 'info',
      //   text: data?.error?.message,
      // });
      this.isLoading = false;
      this.isShowSpinner = false;
      this.isSearch = false;
    } finally {
      // Any cleanup code can go here
    }
  }






  resetCalender() {
    // this.dScheduleFromDate = this.getCTStartOfMonth();
    // this.dScheduleToDate = this.ctDateNow();
    const resetFields = {
       // txtStartDateValue: this.dScheduleFromDate,
      // txtEndDateValue: this.dScheduleToDate,
      txtDateValue:
      this.getCTStartOfMonth()
      +'-'+
      this.ctDateNow(),
      drpUsertype:[this.sesUserId]
    }
    this.calendarSearchForm.reset(resetFields);
    this.calendarSearchForm.patchValue({
      txtDateValue:
        this.getCTStartOfMonth()
        +'-'+
        this.ctDateNow()
     });
     this.UserSelectAllOption = false;
     this.onBranchSelectionChange({ value: 'selectAll' } as MatSelectChange);
    // if (this.drpStatustype.length > 0) {
    //   this.onStatusSelectionChange({ value: 'selectAll' } as MatSelectChange);
    // }
    if (this.calendarSearchForm.valid && !this.calendarSearchForm.errors) {
      this.fnSearchCalendarList();
    }
  }

  areFieldsNotEmpty(): boolean {
    const { drpUsertype, drpStatustype, drpBranch} = this.calendarSearchForm.value;
    return drpUsertype || drpStatustype || drpBranch;
  }

  //! load role dropdown
  // loadRoleDropdown() {
  //   this.roleService.getActiveRolesByGTenantId(this.clientId).subscribe(
  //     (response) => {
  //       this.drpRoles = [];
  //       this.drpRoles = response;
  //     },
  //     (err) => {
  //       const data: HttpErrorResponse = err;
  //       Swal.fire({
  //         icon: 'info',
  //         text: data?.error?.error?.message,
  //       });
  //     }
  //   );
  // }

}
export interface Branch {
  id?: string;
  organizationUnitName?: string;
}
export interface User {
  userId?: string;
  userDesignation?: string;
}

export interface Status {
  statusID?: string;
  statusName?: string;
}
