<div class="container-fluid main-content">
  <div class="row bg">
    <div class="col-12">
      <div class="d-flex align-items-center justify-content-between mb-3">
        <h3 class="font-weight-bolder mb-0">Order Details</h3>
        <div class=" page-title-box page-title-right">
          <ol class="breadcrumbs m-0 align-items-center">
            <!-- (click)="exportTableData()" -->
            <button mat-button class="mr-2 buttonColor"
    [disabled]="(dataSource && dataSource?.data && dataSource?.data?.length === 0)"

            (click)="exportTableData()">
              Export
            </button>
            <li class="breadcrumb-item ">Patient</li>
            <li class="breadcrumb-item ">Order Details</li>
          </ol>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="col-12">
  <div class="card">
    <div class="card-body">
      <ng-container>
        <b class="customThemeClass">Order List Search</b>
      </ng-container>
      <form [formGroup]="orderFilterForm">
        <div class="row">
          <mat-form-field class="col-3">
            <mat-label>SaleOrder Id</mat-label>
            <mat-select #saleOrderSelect multiple formControlName="txtSaleorderId"
              (selectionChange)="onFilterSaleorderIdChange()" [(value)]="saleOrderID">
              <mat-option>
                <ngx-mat-select-search placeholderLabel="Search SaleOrder Id" noEntriesFoundLabel="No Records found"
                  formControlName="txtSaleOrderSearch">
                </ngx-mat-select-search>
              </mat-option>
              <mat-option #allSaleOrderSelected [value]="0" (click)="toggleAllSaleorderIdSelection()">Select All
                SaleOrder</mat-option>
              <mat-option *ngFor="let saleOrderId of filterSaleorder | async" [value]="saleOrderId">
                {{ saleOrderId }}
              </mat-option>

            </mat-select>
            <button mat-button (click)="saleOrderID='';$event.stopPropagation()" *ngIf="saleOrderID" matSuffix
              (click)="clearSaleOrderId()" mat-icon-button >
              <mat-icon>close</mat-icon>
            </button>
          </mat-form-field>

          <mat-form-field class="col-3">
            <mat-label>Patient Id</mat-label>
            <mat-select #PatientIdSelect multiple formControlName="txtPatientId"
              (selectionChange)="onFilterPatientIdChange()" [(value)]="patientID">
              <mat-option>
                <ngx-mat-select-search placeholderLabel="Search Patient Id" noEntriesFoundLabel="No Records found"
                  formControlName="txtPatientIdSearch">
                </ngx-mat-select-search>
              </mat-option>

              <mat-option #allPatientIdSelected [value]="0" (click)="toggleAllPatientIdSelection()">Select All
                Patient Id</mat-option>
              <mat-option *ngFor="let patientIds of filterPatientId  | async" [value]="patientIds">
                {{ patientIds }}
              </mat-option>
            </mat-select>
            <button mat-button (click)="patientID='';$event.stopPropagation()" *ngIf="patientID" matSuffix
              (click)="clearPatientId()" mat-icon-button >
              <mat-icon>close</mat-icon>
            </button>
          </mat-form-field>

          <mat-form-field class="col-3">
            <mat-label>Patient Name</mat-label>
            <mat-select #PatientNameSelect multiple formControlName="txtPatientName"
              (selectionChange)="onFilterPatientNameChange()" [(value)]="patientName">
              <mat-option>
                <ngx-mat-select-search placeholderLabel="Search Patient Name" noEntriesFoundLabel="No Records found"
                  formControlName="txtPatientNameSearch">
                </ngx-mat-select-search>
              </mat-option>
              <mat-option #allPatientNameSelected [value]="0" (click)="toggleAllPatientNameSelection()">Select All
                Patient Name</mat-option>

              <mat-option *ngFor="let patientNames of filterPatientName | async" [value]="patientNames">
                {{ patientNames }}
              </mat-option>
            </mat-select>
            <button mat-button (click)="patientName='';$event.stopPropagation()" *ngIf="patientName" matSuffix
              (click)="clearPatientName()" mat-icon-button >
              <mat-icon>close</mat-icon>
            </button>
          </mat-form-field>

          <mat-form-field class="col-3">
            <mat-label>CSR Name</mat-label>
            <mat-select #CsrNameSelect multiple formControlName="txtCsrName" (selectionChange)="onFilterCSRNameChange()"
              [(value)]="CsrName">
              <mat-option>
                <ngx-mat-select-search placeholderLabel="Search CSR Name" noEntriesFoundLabel="No Records found"
                  formControlName="txtCsrNameSearch">
                </ngx-mat-select-search>
              </mat-option>
              <mat-option #allCsrNameSelected [value]="0" (click)="toggleAllCsrNamesSelection()">Select All
                CSR Names</mat-option>

              <mat-option *ngFor="let csrNames of filterCsrName | async" [value]="csrNames.userName">
                {{ csrNames.userName }}
              </mat-option>
            </mat-select>
            <button mat-button (click)="CsrName='';$event.stopPropagation()" *ngIf="CsrName" matSuffix
              (click)="clearCsrName()" mat-icon-button >
              <mat-icon>close</mat-icon>
            </button>
          </mat-form-field>

          <mat-form-field class="col-3">
            <mat-label>Ticket Id</mat-label>
            <mat-select #TicketIdSelect multiple formControlName="txtTicketId"
              (selectionChange)="onFilterTicketIdChange()" [(value)]="ticketId">
              <mat-option>
                <ngx-mat-select-search placeholderLabel="Search Ticket Id" noEntriesFoundLabel="No Records found"
                  formControlName="txtTicketIdSearch">
                </ngx-mat-select-search>
              </mat-option>
              <mat-option #allTicketIdSelected [value]="0" (click)="toggleAllTicketIdSelection()">Select All
                Ticket Id</mat-option>
              <mat-option *ngFor="let ticketId of filterTicketId | async  " [value]="ticketId">
                {{ ticketId }}
              </mat-option>
            </mat-select>
            <button mat-button (click)="ticketId='';$event.stopPropagation()" *ngIf="ticketId" matSuffix
              (click)="clearTicketId()" mat-icon-button >
              <mat-icon>close</mat-icon>
            </button>
          </mat-form-field>

          <mat-form-field class="col-3">
            <mat-label>Status</mat-label>
            <mat-select #StatusSelect multiple formControlName="txtStatus" (selectionChange)="onFilterStatusChange()"
              [(value)]="status">
              <mat-option>
                <ngx-mat-select-search placeholderLabel="Search Status" noEntriesFoundLabel="No Records found"
                  formControlName="txtStatusSearch">
                </ngx-mat-select-search>
              </mat-option>
              <mat-option #allStatusSelected [value]="0" (click)="toggleAllStatusSelection()">Select All
                Status</mat-option>
              <mat-option *ngFor="let status of filterStatus | async  " [value]="status">
                {{ status }}
              </mat-option>
            </mat-select>
            <button mat-button (click)="status='';$event.stopPropagation()" *ngIf="status" matSuffix
              (click)="clearStatus()" mat-icon-button >
              <mat-icon>close</mat-icon>
            </button>
          </mat-form-field>

          <mat-form-field class="col-6">
            <mat-label>Select Tikcet Date Range</mat-label>
            <input autocomplete="new-txtFromDate" matInput type=" text" name="daterange" ngxDaterangepickerMd [(ngModel)]="selectedRange"
              (click)="change()" [showCustomRangeLabel]="true" [alwaysShowCalendars]="true" [linkedCalendars]="false"
              [ranges]="ranges" [showClearButton]="true" [locale]="calendarLocale" [placeholder]="calendarPlaceholder"
              [keepCalendarOpeningWithRange]="true" [minDate]="minDate" [maxDate]="maxDate"
              formControlName="txtFromDate" />
            <mat-icon matSuffix>date_range</mat-icon>
          </mat-form-field>
        </div>
      </form>


      <div class="row">
        <div class="col-sm-12">
          <div class="text-lg-center">
            <button mat-button class="buttonColor mb-2 mr-2" (click)="search()">Search
            </button>
            <button mat-button class="resetclr" (click)="reset()">Reset
            </button>
          </div>
        </div>
      </div>


    </div>
  </div>
</div>


<div class="col-12 table-responsive" style="margin-top: -5px;">
  <table id="{{strPageType}}" mat-table class="table table-bordered" [dataSource]="dataSource" matSort>
    <form style="display: flex" [formGroup]="orderForm">
      <ng-container matColumnDef="saleorderId">
        <th class="header" mat-header-cell *matHeaderCellDef mat-sort-header>
          Saleorder Id
        </th>
        <td mat-cell *matCellDef="let element"> {{element.saleorderId}} </td>
      </ng-container>
      <ng-container matColumnDef="PatientId">
        <th class="header" mat-header-cell *matHeaderCellDef mat-sort-header>
          Patient Id
        </th>
        <td mat-cell *matCellDef="let element"> {{element.PatientId}} </td>
      </ng-container>

      <ng-container matColumnDef="PatientName">
        <th class="header" mat-header-cell *matHeaderCellDef mat-sort-header>
          Patient Name
        </th>
        <td mat-cell *matCellDef="let element"> {{element.PatientName}} </td>
      </ng-container>

      <ng-container matColumnDef="csrName">
        <th class="header" mat-header-cell mat-sort-header *matHeaderCellDef>
          CSR Name
        </th>
        <td mat-cell *matCellDef="let element"> {{element.csrName}} </td>
      </ng-container>

      <ng-container matColumnDef="payment">
        <th class="header" mat-header-cell *matHeaderCellDef mat-sort-header>
          Cash Payment
        </th>
        <td mat-cell *matCellDef="let element">
          <input disabled style="width: 60px;height: 20px;" type="checkbox" [checked]="element?.cash" />
        </td>
      </ng-container>

      <ng-container matColumnDef="payPalCheckOutOrderPaymentStatus">
        <th class="header" class="w-125 th-center" mat-header-cell *matHeaderCellDef>
          Payment Status
        </th>
        <td mat-cell *matCellDef="let element">
          <span [ngClass]="{ cashPayment:element.cashPayment}">
            <button mat-button *ngIf="element?.orderPayStatus =='Done'" class="btn btn-sm lightSeaGreen-dot"
              (click)="paymentInitiate(element)">
              PayPal {{element.payPalCheckOutOrderPaymentStatus}}
            </button>
          </span>
        </td>
      </ng-container>

      <ng-container matColumnDef="ticketId">
        <th class="header" mat-header-cell *matHeaderCellDef mat-sort-header>
          Ticket Id
        </th>
        <td mat-cell *matCellDef="let element"> {{element.ticketId}} </td>
      </ng-container>

      <ng-container matColumnDef="CreatedDate">
        <th class="header" mat-header-cell *matHeaderCellDef mat-sort-header>

          Ticket Created Date
        </th>
        <td mat-cell *matCellDef="let element"> {{element?.ticketCreatedDate}} </td>
      </ng-container>
      <ng-container matColumnDef="orderStatus">
        <th class="header" mat-header-cell *matHeaderCellDef mat-sort-header>

          Status
        </th>
        <td mat-cell *matCellDef="let element"> {{element?.orderStatus}} </td>
      </ng-container>

      <ng-container matColumnDef="options">
        <th class="header" mat-header-cell *matHeaderCellDef>
          Options
        </th>
        <td mat-cell *matCellDef="let element">
          <!-- <a [hidden]="(element?.billingStatus === 'Processed') ||
          element?.status === 'Ready To Shipping Approval' ||
          element?.status === 'Ready To Approve' ||
          element?.status === 'Shipping Approved' ||
          element?.status === 'Ready To Pack' ||
          element?.status === 'Ready To Delivery' ||
          element?.status === 'Collected From Office' ||
          element?.status === 'Returned' ||
          element?.status === 'Return Approved' ||
          element?.status === 'Return Product Received'
          " class="editBtnColor" *ngIf="isShownUpdateIconForOrder" (click)="existingOrders(element.id)">
            <i class="fa fa-eye" ></i>
          </a> -->

          <a class="editBtnColor" *ngIf="isShownUpdateIconForOrder" (click)="existingOrders(element.id)">
            <i class="fa fa-eye" ></i>
          </a>
          <a *ngIf='element?.status ==="Ready To Approve" || element?.status ==="Ready To Shipping Approval"|| element?.status ==="Order Completed"'
            class="editBtnColor ml-2" (click)="getOrderTemplate(element)">
            <i class="fa fa-download" ></i>
          </a>

          <span *ngIf='element?.status ==="Ready To Delivery" || element?.status ==="Collected From Office"'
            (click)="rejectOrder(element)">
            <button mat-button class="btn btn-sm lightSeaGreen-dot" matTooltip="Click To Update Status">
              Return
            </button><br>
          </span>
          <span *ngIf='element?.status ==="Shipping Rejected" || element?.status ==="Shipping Return Rejected"'
            (click)="rejectOrderUpdate(element)">
            <i class="fa fa-times-circle customThemeClass ml-2" matTooltip="Click To View Rejected / Return Status"
              ></i>
          </span>
        </td>
      </ng-container>
      <ng-container matColumnDef="bin">
        <th class="header" mat-header-cell *matHeaderCellDef mat-sort-header>
          Next Bin
        </th>
        <td mat-cell *matCellDef="let record">

          <!-- <ng-container> -->
          <button [ngClass]="{ plan:record.planStatus}" mat-button class="btn btn-sm lightSeaGreen-dot"
            (click)="moveToOrder(record)">Move To Order Bin</button>
          <!-- </ng-container> -->
        </td>
      </ng-container>

      <ng-container matColumnDef="noRecords">
        <td class="text-align-center" mat-footer-cell *matFooterCellDef colspan="10">
          <div *ngIf="!isLoading">
            {{"No Records Found"}}
          </div>
          <div [hidden]="!isLoading">
            <mat-spinner [diameter]="220"></mat-spinner>
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="paginator">
        <td mat-footer-cell *matFooterCellDef colspan="10">
          <mat-paginator class="table table-responsive mat-table-class" #MatPaginator
            [length]="dataSource?.data?.length" [pageSize]="10" [pageIndex]="0" showFirstLastButtons
            [pageSizeOptions]="[5, 10, 25, 100]">
          </mat-paginator>
        </td>
      </ng-container>
    </form>
    <tr mat-footer-row *matFooterRowDef="['noRecords']"
      [ngClass]="{ active: dataSource && dataSource.data && dataSource?.data?.length !== 0 }"></tr>
    <tr mat-header-row *matHeaderRowDef="arrDisplayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: arrDisplayedColumns"
      [ngClass]="{'make-gold': row.Status == 'Pending', 'make-green':row.Status == 'Done','make-orange':row.Status == 'Yet to Start'}">
    </tr>
    <tr mat-footer-row *matFooterRowDef="['paginator']"></tr>
  </table>
</div>
