import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ResupplyInactiveNotesComponent } from '../../resupply-inactive-notes/resupply-inactive-notes.component';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NoteType } from '../../order-proxy/order-management/inventory/dto';
import { defaultGuid } from 'projects/shared/src/app/enums/allenums.enum';
import { PatientCommonNotesComponent } from 'projects/shared/src/app/components/patient-common-notes/patient-common-notes.component';
import { HttpErrorResponse } from '@angular/common/http';
import Swal from 'sweetalert2';
import { ViewPatientLedgerComponent } from 'projects/admin/src/app/view-patient-ledger/view-patient-ledger.component';
import { MedicareStatus } from 'projects/patient/src/app/patient-proxy/patient/enumeration-data/medicare-status.enum';
import { PatientManualVerificationTabComponent } from 'projects/patient/src/app/patient-manual-verification-tab/patient-manual-verification-tab.component';
import { Subscription } from 'rxjs';
@Component({
  selector: 'app-inactive-supplies',
  templateUrl: './inactive-supplies.component.html',
  styleUrls: ['./inactive-supplies.component.scss']
})
export class InactiveSuppliesComponent implements OnInit {
  isLoading : boolean;
  @Input() tableData : any[];
  InactiveListData: any[];
  subscription$: Subscription[] = [];
  $subscription: Subscription[] = [];
  headingTxt: string;
  noteTypes: NoteType;
  isnextCalldate: boolean;
  selectedData:any;
  patientId:string;
  chartId:string;
  isRoleName:string;
  @Output() getPatientDetails: EventEmitter<string> = new EventEmitter<string>();
  MedicareStatusV1 = MedicareStatus;
  dtinactiveSuppliesOptions: any = {
    responsive: true,
    stateSave: true,
    paging: false,
    dom: 'Bfrtip',
    language: {
      emptyTable: 'No records',
      info: 'Total : _MAX_ records',
      infoEmpty: 'Total : _MAX_ records',
      infoFiltered: '(filtered : _TOTAL_ records)',
      loadingRecords: 'Loading...',
      zeroRecords: 'No matching records',
    },
    scrollCollapse: true,
    scrollY: '500px',
    scrollX: true,
    fixedHeader: true,
    search: {
      smart: false, // Set to "none" for full exact match
    },
    buttons: [
      {
        extend: 'excel',
        text: 'Export',
        filename: 'Inactive Supplies',
        exportOptions: {
          columns: ':visible:not(:first-child) ',
        },
      },
      {
        extend: 'colvis',
        columns: ':not(:eq(0), :eq(1), :eq(2), :eq(-1))',
      }
    ],
  };

  constructor(
    public title: Title,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private datepipe:DatePipe
  ) { }

  ngOnInit(): void {
    this.isRoleName = localStorage.getItem('roleName');
    this.dtinactiveSuppliesOptions.buttons[0].filename =
      'ReSupply - InActive Supplies' + '-' + this.datepipe.transform(new Date(), 'MM-dd-yyyy');
    this.InactiveListData=this.tableData;
  }


  // supplyInactive(optionNo:number,data:any){
  //   // 1.Inactive current month
  //   // 2.Inactive entire month
  //   // 3.active supply
  //   if(optionNo==1){
  //     this.headingTxt = "Inactive Current Month";
  //     this.noteTypes = NoteType.currentMonthInactive;
  //     this.isnextCalldate = false;
  //   }else if(optionNo==2){
  //     this.headingTxt = "Inactive Entire Month";
  //     this.noteTypes = NoteType.entireMonthInactive;
  //     this.isnextCalldate = false;
  //   }else if(optionNo==3){
  //     this.headingTxt = "Active Supplies";
  //     this.noteTypes = NoteType.activeSupplies;
  //     this.isnextCalldate = true;
  //   }
  //   const dialogRef = this.dialog.open(ResupplyInactiveNotesComponent, {
  //     minHeight: '33vh',
  //     minWidth: '52vw',
  //     disableClose: true,
  //     data: {
  //       headingTxt:this.headingTxt,
  //       noteType:this.noteTypes,
  //       chartId:data?.chartId,
  //       isNextCallDateshow:this.isnextCalldate,
  //       orderId: data?.orderId,
  //       patientId: data?.patientId,
  //       currentMonth: optionNo === 1 ? true : false,
  //       isCurrentSuplies:false,
  //     },
  //   });
  //   dialogRef.afterClosed().subscribe((a) => {

  //   });
  // }

  supplyInactive(optionNo:number,data:any){

    if(optionNo==1){
      this.headingTxt = "Inactive Current Month";
      this.noteTypes = NoteType.currentMonthInactive;
      this.isnextCalldate = false;
    }else if(optionNo==2){
      this.headingTxt = "Inactive Entire Month";
      this.noteTypes = NoteType.entireMonthInactive;
      this.isnextCalldate = false;
    }else if(optionNo==3){
      this.headingTxt = "Active Supplies";
      this.noteTypes = NoteType.activeSupplies;
      this.isnextCalldate = true;
    }

    const config: MatDialogConfig = {
      disableClose: true,
      minHeight: '33vh',
      minWidth: '52vw',
      data: {
        headingTxt:this.headingTxt,
        noteType:this.noteTypes,
        nextCallDate:data?.nextCallDate,
        chartId:data?.chartId,
        isNextCallDateshow:this.isnextCalldate,
        orderId: data?.orderId,
        patientId: data?.patientId,
        currentMonth: optionNo === 1 ? true : false,
        isCurrentSuplies:false,
        isMovetoArchive: false,
        isActivePatient: false,
      },
    };

    const dialogRef = this.dialog.open(ResupplyInactiveNotesComponent, config);

    const closeDialog = dialogRef.afterClosed().subscribe(
      () => {},
      (err) => {}
    );
    this.$subscription.push(closeDialog);
  }
  onRowDoubleClick(textToCopy) {
    this.copyTextToClipboard(textToCopy);
    this.showNotification('Text copied: ' + textToCopy);
  }

  showNotification(message: string): void {
    this.snackBar.open(message, 'Close', {
      duration: 3000, // Duration in milliseconds
      horizontalPosition: 'end', // Positioning the notification horizontally
      verticalPosition: 'bottom', // Positioning the notification vertically
    });
  }

  copyTextToClipboard(text: string): void {
    const textArea = document.createElement('textarea');
    textArea.value = text;
    document.body.appendChild(textArea);
    textArea.select();
    document.execCommand('Copy');
    textArea.remove();
  }

  isEmpty(value: any): boolean {
    return (
      value === null ||
      value === undefined ||
      value === '' ||
      value === defaultGuid
    );
  }

  tblItemSelectedRow(data:any): void {
    this.selectedData = data;
    if(this.patientId !== data?.patientId){
      this.patientId = data?.patientId;
      this.chartId = data?.chartId;
      this.getPatientDetails.emit(data?.patientId);
    }
  }

  //View Notes
  openViewNotesPopup() {
    const config: MatDialogConfig = {
      disableClose: true,
      width: '70vw',
      maxWidth: '70vw',
      data: {
        patientId: this.patientId,
        pageType: 'Patient Note', // this.pageType, //Notes Table Popup Page
        isAddVisible: false,
      },
    };
    const dialogRef = this.dialog.open(PatientCommonNotesComponent, config);

    dialogRef.afterClosed().subscribe(
      () => {
      },
      (err: HttpErrorResponse) => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      }
    );
  }

  //View Patient Ledger
  viewPatientLedger(patientId: string) {
    this.dialog.open(ViewPatientLedgerComponent, {
      width: '100vw',
      height: '100vh',
      maxWidth: '100vw',
      maxHeight: '100vh',
      panelClass: 'fullscreen-dialog-panel',
      disableClose: true,
      data: {
        patientId: patientId,
      },
    });
    this.dialog.afterAllClosed.subscribe((resposne) => {});
  }

  openLastVerification(element) {

    if (element == 'Primary') {
      const dialogRef = this.dialog.open(
        PatientManualVerificationTabComponent,
        {
          disableClose: true,
          data: {
            patientId: this.selectedData.patientId,
            verificationId: this.selectedData.primaryVerification,
            // policyId: this.priPolicyId,
            savehidden: true,
            isViewVerification: true,
            documentId: this.selectedData.documentId,
            // defaultFaxId:data?.defaultFaxId
          },
        }
      );
      const closeDialog = dialogRef.afterClosed().subscribe((result) => {
        if (result == 'Saved') {
          this.dialog.closeAll();
        }

        result !== '' &&
          result !== null &&
          result !== undefined &&
          this.dialog.closeAll();
      });
      this.subscription$.push(closeDialog);
    } else if (element == 'Secondary') {
      const dialogRef = this.dialog.open(
        PatientManualVerificationTabComponent,
        {
          disableClose: true,
          data: {
            patientId: this.selectedData.patientId,
            // policyId: this.secPolicyId,
            verificationId: this.selectedData.secVerification,
            savehidden: true,
            isViewVerification: true,
            documentId: this.selectedData.documentId,
            // defaultFaxId:data?.defaultFaxId
          },
        }
      );
      const closeDialog = dialogRef.afterClosed().subscribe((result) => {
        if (result == 'Saved') {
          this.dialog.closeAll();
        }

        result !== '' &&
          result !== null &&
          result !== undefined &&
          this.dialog.closeAll();
      });
      this.subscription$.push(closeDialog);
    }
};
}
