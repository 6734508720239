import { Pipe, PipeTransform } from '@angular/core';
import { DiagnosisCode9DTO } from 'projects/patient/src/app/dropdown-proxy/dropdown-management/dropdowns/dto/patient';

@Pipe({
  name: 'diagonsis'
})
export class DiagonsisPipe implements PipeTransform {

  transform(value: string, args: DiagnosisCode9DTO[]): string {
    return args?.filter(x => x?.diagnosisCode === value)?.[0]?.diagnosisCodeTypeData;
  }

}
