import type { CreateUpdateInviteUserDTO, InviteStatusDTO, InviteUserDTO, InviteUserIdDTO, UserRegistrationDTO } from './dto/models';
import { RestService } from '@abp/ng.core';
import type { PagedAndSortedResultRequestDto, PagedResultDto } from '@abp/ng.core';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class UserInviteService {
  apiName = 'platformManagement';

  create = (input: CreateUpdateInviteUserDTO) =>
    this.restService.request<any, InviteUserDTO>({
      method: 'POST',
      url: '/api/PlatformApp/user-invite',
      body: input,
    },
     { apiName: this.apiName, skipHandleError: true });

  createUserInviteByInput = (input: CreateUpdateInviteUserDTO) =>
    this.restService.request<any, InviteUserDTO>({
      method: 'POST',
      url: '/api/PlatformApp/user-invite/user-invite',
      body: input,
    },
     { apiName: this.apiName, skipHandleError: true });

  delete = (id: string) =>
    this.restService.request<any, void>({
      method: 'DELETE',
      url: `/api/PlatformApp/user-invite/${id}`,
    },
     { apiName: this.apiName, skipHandleError: true });

  get = (id: string) =>
    this.restService.request<any, InviteUserDTO>({
      method: 'GET',
      url: `/api/PlatformApp/user-invite/${id}`,
    },
     { apiName: this.apiName, skipHandleError: true });

  getList = (input: PagedAndSortedResultRequestDto) =>
    this.restService.request<any, PagedResultDto<InviteUserDTO>>({
      method: 'GET',
      url: '/api/PlatformApp/user-invite',
      params: { skipCount: input.skipCount, maxResultCount: input.maxResultCount, sorting: input.sorting },
    },
     { apiName: this.apiName, skipHandleError: true });

  update = (id: string, input: CreateUpdateInviteUserDTO) =>
    this.restService.request<any, InviteUserDTO>({
      method: 'PUT',
      url: `/api/PlatformApp/user-invite/${id}`,
      body: input,
    },
     { apiName: this.apiName, skipHandleError: true });

  checkExpireInvites = () =>
    this.restService.request<any, void>({
      method: 'POST',
      url: '/api/PlatformApp/user-invite/check-expire-invites',
    },
     { apiName: this.apiName, skipHandleError: true });

  deleteInviteUser = (deleteUser: InviteUserIdDTO) =>
    this.restService.request<any, void>({
      method: 'DELETE',
      url: '/api/PlatformApp/user-invite/delete-invite-user',
      params: { tenantId: deleteUser.tenantId, inviteUserId: deleteUser.inviteUserId },
    },
     { apiName: this.apiName, skipHandleError: true });

  getInviteUserByIdByInviteUser = (inviteUser: InviteUserIdDTO) =>
    this.restService.request<any, InviteUserDTO>({
      method: 'GET',
      url: '/api/PlatformApp/user-invite/get-invite-user-by-id',
      params: { tenantId: inviteUser.tenantId, inviteUserId: inviteUser.inviteUserId },
    },
     { apiName: this.apiName, skipHandleError: true });

  getInvitedUserDetailsBasedonStatusByInviteStatus = (inviteStatus: InviteStatusDTO) =>
    this.restService.request<any, InviteUserDTO[]>({
      method: 'POST',
      url: '/api/PlatformApp/user-invite/get-invited-user-details-basedon-status',
      body: inviteStatus,
    },
     { apiName: this.apiName, skipHandleError: true });

  userRegistration = (input: UserRegistrationDTO) =>
    this.restService.request<any, InviteUserDTO>({
      method: 'POST',
      url: '/api/PlatformApp/user-invite/user-registration',
      body: input,
    },
     { apiName: this.apiName, skipHandleError: true });

  constructor(private restService: RestService) {}
}
