import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
@Component({
  selector: 'app-import-pivot-table',
  templateUrl: './import-pivot-table.component.html',
  styleUrls: ['./import-pivot-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ImportPivotTableComponent implements OnInit {
  @Input()
  source: any[] = [];

  @Input()
  configs: Configs;

  default_configs: Configs = {
    rows: '',
    columns: '',
    css: {
      row_selection_class: 'selected',
      header_class: '',
      table_class: '',
    },
    data_loading_text: 'Loading...',
    row_class_function: () => true,
    row_select_function: () => true,
  };
  store = new Store();

  processed_data: any[] = [];
  processed_headers: any[] = [];

  constructor() { }

  ngOnInit(): void {
    this.validateConfigs();
    this.setDefaultConfigs();
  }

  ngOnChanges() {
    this.validateConfigs();
    this.setDefaultConfigs();
    this.store?.processData(this.source, this.configs);
    this.processed_data = this.store?.getProcessedData();

    this.processed_headers = this.store?.getProcessedHeaders();
  }

  validateConfigs() {
    if (!this.source) {
      console.warn("source can't be empty!");
      return;
    }
    if (!this.configs) {
      console.warn("configs can't be empty!");
      return;
    }
  }

  setDefaultConfigs() {
    this.configs = Object.assign({}, this.default_configs, this.configs);

    // Deep clone.
    this.configs.css = Object.assign(
      {},
      this.default_configs?.css,
      this.configs?.css
    );
  }
}

export interface Configs {
  css: CssClass;
  data_loading_text: string;
  height?: string;
  row_class_function: Function;
  row_select_function: Function;
  rows: string;
  columns: string;
}

export interface CssClass {
  row_selection_class: string;
  header_class: string;
  table_class: string;
}

export class Store {
  processed_data: any[] = [];
  raw_data: string | any[];
  processed_headers: any[] = [];
  configs: Configs;

  getRawData() {
    return this.raw_data;
  }

  setRawData(raw_data: string | any[]) {
    this.raw_data = raw_data;
  }

  getProcessedData() {
    return this.processed_data;
  }

  setProcessedData(processed_data: any[]) {
    this.processed_data = processed_data;
  }

  getProcessedHeaders() {
    return this.processed_headers;
  }

  constructor() { }

  processData(source: any[], configs: Configs) {
    this.setRawData(source);
    const rows = this.findUnique(this.pluck(source, configs?.rows));
    const columns = this.findUnique(this.pluck(source, configs?.columns));

    this.processed_headers = [...columns];

    for (let i = 0; i < rows?.length; i++) {
      const row: string = rows[i];
      const processed_row: any = [row];
      let row_count: any = 0;
      for (let j = 0; j < columns?.length; j++) {
        const column = columns[j];
        let count = 0;
        for (let k = 0; k < source?.length; k++) {
          const data = source[k];
          if (data === row && data === column) {
            count++;
          }
          if (
            data &&
            data[configs?.rows] &&
            data[configs?.columns] &&
            data[configs?.rows] == row &&
            data[configs?.columns] == column
          ) {
            count++;
          }
        }
        processed_row?.push(count);
        row_count += count;
      }
      processed_row?.push(row_count);

      this.processed_data?.push(processed_row);
    }

    const summary_row = ['Total'];

    for (let index = 1; index < this.processed_data[0]?.length; index++) {
      this.addSummaryColumn(summary_row, index);
    }
    this.processed_data?.push(summary_row);
  }

  addSummaryColumn(summary_row: any[], index: number) {
    summary_row?.push(
      this.processed_data
        ?.map((data) => data[index])
        ?.reduce((a, b) => a + b, 0)
    );
  }

  findUnique(a: any[]) {
    return a?.filter(function (item, pos) {
      return a?.indexOf(item) == pos;
    });
  }

  pluck(array: any[], key: string) {
    return array?.map(function (obj: { [x: string]: any }) {
      if ((obj && obj[key]) || obj[key] === 0) {
        return obj[key];
      }
      if (obj && typeof obj === 'string') {
        return obj;
      }
      if (typeof obj === 'number') {
        return obj === '' ? 0 : obj;
      }
      if (typeof obj === 'boolean') {
        return obj;
      }
      return '';
    });
  }
}

