<div class="row">
    <h3
      class="col-lg-6 mt-2 custom-qsecure-color font-weight-bolder"
      mat-dialog-title
    >
      Preview Auth Document- {{ data.patientInfo.defaultPatientId }}
    </h3>
    <div class="col-lg-6 text-right mb-2">
      <button
        class="col-lg-6"
        mat-icon-button
        class="close-button"
        (click)="closePreview()"
      >
        <mat-icon>close</mat-icon>
      </button>
    </div>
  </div>
  <div class="card card-body align-items-center justify-content-center p-0">
    <!-- <h1 class="fw-bolder" [ngClass]="{ 'blink-text': isLoading || TiffError }"  *ngIf="isLoading || TiffError">
      {{ loadingMessage }}
    </h1> -->
      <div class="zoom-buttons" *ngIf="!isLoading" >
          <!-- <button [matTooltip]="'Clear All Annotation'" mat-raised-button color="warn" (click)="clearAllAnnotation()" [disabled]="this.textAnnotations.length === 0" ><mat-icon>restore</mat-icon></button> -->
        <button [matTooltip]="'Zoom In Page'" mat-button color="primary" (click)="zoomIn()" [disabled]="isLoading">
          <mat-icon>add</mat-icon>
        </button> 
        <button [matTooltip]="'Go to First Page'" mat-button color="primary" (click)="firstImage()" [disabled]="isLoading">
          <mat-icon>first_page</mat-icon>
        </button>
        <button [matTooltip]="'Go to Previous Page'" mat-button color="primary" (click)="previousImage()" [disabled]="isLoading">
          <mat-icon>keyboard_arrow_left</mat-icon>
        </button>
        <input [matTooltip]="'Go to Custom Page'" matInput class="form-control custom-input" type="number" [(ngModel)]="pageNumber" min="1"
          max="{{totalPages}}" (focusout)="goToPage()" [readonly]="isLoading" />
  
        <span> of {{ totalPages }}</span>
        <button [matTooltip]="'Go to Next Page'" mat-button color="primary" (click)="nextImage()" [disabled]="isLoading">
          <mat-icon>keyboard_arrow_right</mat-icon>
        </button>
        <button  [matTooltip]="'Go to Last Page'" mat-button color="primary" (click)="lastImage()" [disabled]="isLoading">
          <mat-icon>last_page</mat-icon>
        </button>
  
        <button  [matTooltip]="'Zoom Out Page'" mat-button color="primary" (click)="zoomOut()" [disabled]="isLoading">
          <mat-icon>remove</mat-icon>
        </button> 
        <!-- <button [matTooltip]="'Save Annotation'" class="defaultButton saveButton" mat-raised-button
        (click)="saveAnnotation()"  [disabled]="this.textAnnotations.length === 0">
          <mat-icon>save</mat-icon>
        </button> -->
      </div>
  </div>
<div class="card card-body align-items-center justify-content-center scrollable-card" style="height: 76vh">
    <div class="scrollable-card-image">
        <canvas #canvas></canvas>
    </div>
</div>

<div align="end" mat-dialog-actions>
    <button
      class="faxButtonColor mt-1 mr-1 float-right"
      (click)="sendFax()"
      mat-button
    >
      <mat-icon class="send-icon">send</mat-icon>Send Fax
    </button>
  </div>