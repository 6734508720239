<table id="tblAuthorization" datatable [dtOptions]="dtAuthorizationOptions"
    class="tbl hover row-border hover w-100 display">
    <thead>
        <tr>
            <th><mat-icon> apps</mat-icon></th>
            <th [matTooltip]="'Auth ID'">Auth ID</th>
            <th [matTooltip]="'Auth Begin Date'" class="text-center">Auth Begin Date</th>
            <th [matTooltip]="'Auth End Date'" class="text-center">Auth End Date</th>
            <th [matTooltip]="'Auth Status'">Auth Status</th>
            <th [matTooltip]="'FollowUp'">FollowUp</th>
            <!-- <th [matTooltip]="'Units'">Units</th> -->
            <th [matTooltip]="'Plan Name'">Plan Name</th>
            <th [matTooltip]="'Plan Type'">Plan Type</th>
            <th [matTooltip]="'Reference'">Reference</th>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let data of tableAuthorizationDetails">
            <td class="text-center">
              <a [ngClass]="{ 'disabled-link': (data.authStatusName==='Auth Cancel')}"
              class="editBtnColor"  (click)="viewprecert(data)">
                <i class="fa fa-pencil editBtnColor " matTooltip="Click To Edit Auth Details"></i>
              </a>
              &nbsp;
                <a [ngClass]="{ 'disabled-link': (data.authStatusName==='Yet to Start'||data.authStatusName===null||data.authStatusName==='')}" (click)="doprecert(data)" matTooltip="Click To View Auth Details">
                    <i class="fa fa-eye pointer editBtnColor pl-3"></i>
                </a>
            </td>

            <td [matTooltip]="data.authId || '-'" (dblclick)="onRowDoubleClickV1(data?.authId || '-')">{{ data.authId ||
                '-' }}</td>


            <td [matTooltip]="getFormattedDate(data?.startDate)"
                (dblclick)="onRowDoubleClickV1(getFormattedDate(data?.startDate))" class="text-center">
                {{ getFormattedDate(data?.startDate) }}
            </td>

            <td [matTooltip]="getFormattedDate(data?.endDate)"
                (dblclick)="onRowDoubleClickV1(getFormattedDate(data?.endDate))" class="text-center">
                {{ getFormattedDate(data?.endDate) }}
            </td>

            <td [matTooltip]="data.authStatusName || '-'" (dblclick)="onRowDoubleClickV1(data?.authStatusName || '-')">{{
                data?.authStatusName == "" || data?.authStatusName == null
                ? "-"
                : data?.authStatusName
                }}</td>
             <td [matTooltip]="data?.isFollowUp ? 'Yes' : 'No' || '-'"> {{ data?.isFollowUp == 0 ? "No" : "Yes" }}</td>
             <!-- <td [matTooltip]="data.units || '-'" (dblclick)="onRowDoubleClickV1(data?.units || '-')"> {{ data?.units === "" ? "-" :
                data?.units }}</td> -->
            <td [matTooltip]="data.planName || '-'" (dblclick)="onRowDoubleClickV1(data?.planName || '-')">{{
                data?.planName == "" || data?.planName == null
                ? "-"
                : data?.planName
                }}
            </td>
            <td [matTooltip]="data.planType || '-'" (dblclick)="onRowDoubleClickV1(data?.planType || '-')">{{
                data?.planType == "" || data?.planType == null
                ? "-"
                : data?.planType
                }}
            </td>
            <td [matTooltip]="data.reference || '-'" (dblclick)="onRowDoubleClickV1(data?.reference || '-')">{{
                data?.reference == "" || data?.reference == null
                ? "-"
                : data?.reference
                }}
            </td>
        </tr>
    </tbody>
    <tfoot>
        <tr>
            <th><mat-icon>apps</mat-icon></th>
            <th>
                <input matInput class="textbox-border-class" type="text" placeholder="Auth ID"
                    name="search-AuthID" />
            </th>
            <th>
                <input matInput class="textbox-border-class text-center" type="text" placeholder="Auth Begin Date"
                    name="search-AuthBeginDate" />
            </th>
            <th>
                <input matInput class="textbox-border-class text-center" type="text" placeholder="Auth End Date" name="search-AuthEndDate" />
            </th>
            <th>
                <input matInput class="textbox-border-class" type="text" placeholder="Auth Status"
                    name="search-AuthStatus" />
            </th>
            <th>
                <input matInput class="textbox-border-class" type="text" placeholder="FollowUp"
                    name="search-FollowUp" />
            </th>
            <!-- <th>
                <input matInput class="textbox-border-class" type="text" placeholder="Units"
                    name="search-Units" />
            </th> -->
            <th>
                <input matInput class="textbox-border-class" type="text" placeholder="Plan Name" name="search-PlanName" />
            </th>
            <th>
                <input matInput class="textbox-border-class" type="text" placeholder="Plan Type"
                    name="search-PlanType" />
            </th>
            <th>
                <input matInput class="textbox-border-class" type="text" placeholder="Reference"
                    name="search-Reference" />
            </th>
        </tr>
    </tfoot>
</table>
