import { RestService } from '@abp/ng.core';
import type {
  PagedAndSortedResultRequestDto,
  PagedResultDto,
} from '@abp/ng.core';
import { Injectable } from '@angular/core';
import { CreateUpdateInboundStatusDTO, InboundStatusDTO } from './dto';

@Injectable({
  providedIn: 'root',
})
export class InboundStatusService {
  apiName = 'platformManagement';

  create = (inboundDetails: CreateUpdateInboundStatusDTO) =>
    this.restService.request<any, InboundStatusDTO>(
      {
        method: 'POST',
        url: '/api/PlatformApp/inbound-status',
        body: inboundDetails,
      },
      { apiName: this.apiName }
    );

  delete = (id: string) =>
    this.restService.request<any, void>(
      {
        method: 'DELETE',
        url: `/api/PlatformApp/inbound-status/${id}`,
      },
      { apiName: this.apiName }
    );

  get = (id: string) =>
    this.restService.request<any, InboundStatusDTO>(
      {
        method: 'GET',
        url: `/api/PlatformApp/inbound-status/${id}`,
      },
      { apiName: this.apiName }
    );

  getList = (input: PagedAndSortedResultRequestDto) =>
    this.restService.request<any, PagedResultDto<InboundStatusDTO>>(
      {
        method: 'GET',
        url: '/api/PlatformApp/inbound-status',
        params: {
          skipCount: input.skipCount,
          maxResultCount: input.maxResultCount,
          sorting: input.sorting,
        },
      },
      { apiName: this.apiName }
    );

  update = (id: string, inboundDetails: CreateUpdateInboundStatusDTO) =>
    this.restService.request<any, InboundStatusDTO>(
      {
        method: 'PUT',
        url: `/api/PlatformApp/inbound-document/${id}`,
        body: inboundDetails,
      },
      { apiName: this.apiName }
    );
    csrDetailsSave = (comments: string, gNoteTypeId:string,sNoteType:string,docId: string,UserId:string) =>
    this.restService.request<any, string>(
      {
        method: 'PUT',
        url: `/api/PlatformApp/inbound-document/c-sRDetails`,
        responseType: 'text',
        params: {
          sComments: comments,
          gNoteTypeId:gNoteTypeId,
          sNoteType:sNoteType,
          documentId: docId,
          userId: UserId,
        },
      },
      { apiName: this.apiName }
    );
    newSetupNotesSave = (inboundDocumentId: string, sShortCode: string,inboundStatusId:string,currentShortCode:string,
      sNotes:string,gNoteType:string,sNoteType:string) =>
    this.restService.request<any, any>(
      {
        method: 'PUT',
        url: `/api/PlatformApp/inbound-document/inbound-status`,
        // responseType: 'text',
        params: {
          inboundDocumentId: inboundDocumentId,
          sShortCode: sShortCode,
          inboundStatusId: inboundStatusId,
          currentShortCode:currentShortCode,
          sNotes:sNotes,
          gNoteType:gNoteType,
          sNoteType:sNoteType
        },
      },
      { apiName: this.apiName }
    );
    MoveToDetails = () =>
    this.restService.request<any, any>(
      {
        method: 'POST',
        url: `/api/PlatformApp/inbound-status/move-bin-status`,
      },
      { apiName: this.apiName }
    );
  constructor(private restService: RestService) {}
}
