import { Component, Inject, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { defaultGuid } from '../medicare-check-list/medicare-check-list.component';

import { CreateUpdateNoteDTO, NoteDTO } from '../patient-proxy/patient/practice-management/dto/models';
import { PracticeManagementService } from '../patient-proxy/patient/practice-management/practice-management.service';
import { ToastrService } from "ngx-toastr";
import { HttpErrorResponse } from '@angular/common/http';
import Swal from 'sweetalert2';
import { noWhitespaceValidator } from '../add-note-tab/add-note-tab.component';
@Component({
  selector: 'app-add-practice-notes',
  templateUrl: './add-practice-notes.component.html',
  styleUrls: ['./add-practice-notes.component.scss']
})
export class AddPracticeNotesComponent implements OnInit {

  @Input() notesId: any;
  @Input() practiceNoteId: any;
  @Input() notesTitle: any;
  @Input() textNotes: any;
  @Output() reloadTableEvent = new EventEmitter();
  noteForm: FormGroup;
  practiceNotetext: string;
  isSave: string='Save';
  isShowSpinner: boolean = false;
  UserTypeControl = new FormControl();
  notesText = '';
  constructor(
    private fb: FormBuilder,
    private practiceManagementService: PracticeManagementService,
    private toastr: ToastrService,
    public notesDialogRef: MatDialogRef<AddPracticeNotesComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      notesTitle: string;
      notesId?: string;
      practiceNoteId: string;
      textNotes: string;
    } = {
        notesTitle: '',
        notesId: defaultGuid,
        practiceNoteId: '',
        textNotes: ''
      },
  ) { }

  ngOnInit(): void {

    this.noteForm = this.fb.group({
      // drpUsertype: new FormControl('', [Validators.required]),

      // txtUserType: new FormControl(''),
      // txtdate: [new Date(), [Validators.required]],
      txtDescription: new FormControl('', [Validators.required,noWhitespaceValidator]),
    });

    if (this.data) {
      this.notesTitle = this.data?.notesTitle ?? "Notes";
      this.notesId = this.data?.notesId ?? '';
      this.practiceNoteId = this.data?.practiceNoteId ?? '';
      this.practiceNotetext = this.data?.textNotes ?? '';
    }

    if (this.notesId !== defaultGuid) {
      this.notesText = this.practiceNotetext;
      this.noteForm.markAsDirty();
    }
    if (this.notesId === defaultGuid) {
    this.isSave='Save';
    }
    if (this.notesId !== defaultGuid) {
      this.isSave='Update';
    }

  }

  //Update Character Count for TextArea
  updateCharacterCount(maxlength: number) {
    // Ensure the text length does not exceed the maximum length
    if (this.notesText?.length > maxlength) {
      this.notesText = this.notesText?.substr(0, maxlength);
    }
  }
  resetNotes() {
    this.noteForm.reset({
      txtDescription: ''
    });
  }

  //Save Notes
  saveNotes() {
    this.isShowSpinner = true;

    this.notesId = this.data?.notesId ?? defaultGuid;
    const practiceNotesInput: CreateUpdateNoteDTO = {
      practiceid: this.practiceNoteId !== '' ? this.practiceNoteId : null,
      notes: this.noteForm.value.txtDescription ?? '',
    };
    //Save
    if (this.notesId === defaultGuid) {
      this.practiceManagementService.createNotesByInput(practiceNotesInput).subscribe(
        (response) => {
          const notesSaved: NoteDTO = response;
          this.toastr.success('Notes Saved Successfully!', 'Success');
          this.noteForm.reset();
          this.reloadTableEvent.emit();
          this.notesDialogRef.close(notesSaved);
          this.isShowSpinner = false;
        },
        (err) => {
          this.isShowSpinner = false;
          const data: HttpErrorResponse = err;
          Swal.fire({
            icon: 'info',
            text: data?.error?.error?.message,
          });
        }
      );
    }

    //Update Notes
    if (this.notesId !== defaultGuid) {
      this.practiceManagementService.updateNoteByIdAndInput(this.notesId, practiceNotesInput).subscribe(
        (response) => {
          const notesUpdate: NoteDTO = response;
          this.toastr.success('Notes Updated Successfully!', 'Success');
          this.noteForm.reset();
          this.reloadTableEvent.emit();
          this.notesDialogRef.close(notesUpdate);
          this.isShowSpinner = false;
        },
        (err) => {
          this.isShowSpinner = false;
          const data: HttpErrorResponse = err;
          Swal.fire({
            icon: 'info',
            text: data?.error?.error?.message,
          });
        }
      );
    }
  }
}
