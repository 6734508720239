import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { defaultGuid } from '../../../enums/allenums.enum';
import { DataTableDirective } from 'angular-datatables';
import { DatePipe } from '@angular/common';
@Component({
  selector: 'app-patient-common-claim-table',
  templateUrl: './patient-common-claim-table.component.html',
  styleUrls: ['./patient-common-claim-table.component.scss']
})
export class PatientCommonClaimTableComponent implements OnInit {

  @Input() tableDataList : any;
  claimDetailsList : any;
  defaultGuid = defaultGuid;
  isLoading:boolean=false;
  dtClaimOptions: any = {
    responsive: true,
    stateSave: true,
    paging: false,
    dom: 'Bfrtip',
    language: {
      emptyTable: 'No records',
      info: 'Total : _MAX_ records',
      infoEmpty: 'Total : _MAX_ records',
      infoFiltered: '(filtered : _TOTAL_ records)',
      loadingRecords: 'Loading...',
      zeroRecords: 'No matching records',
    },
    scrollCollapse: true,
    scrollY: '250px',
    scrollX: true,
    fixedHeader: true,
    columnDefs: [
      { targets: [0, 1, ,-2 , -1], orderable: false },
    //   { targets: [0, 1, 2, -1], className: 'dt-fixed-column' },
    //   {
    //     targets: [8],
    //     type: 'date',
    //     render: function (data, type, row) {
    //       // Assuming data is in the format MM/DD/YYYY
    //       if (type === 'sort' || type === 'type') {
    //         var dateParts = data.split('/');
    //         return new Date(dateParts[2], dateParts[0] - 1, dateParts[1]);
    //       } else {
    //         return data;
    //       }
    //     },
    //   },
    ],
    search: {
      smart: false, // Set to "none" for full exact match
    },
    buttons: [
      {
        extend: 'excel',
        text: 'Export',
        filename: 'Patient Claim',
        // exportOptions: {
        //   columns: ':visible:not(:first-child) ',
        // },
      },
      // {
      //   extend: 'colvis',
      //   columns: ':not(:eq(0), :eq(1), :eq(2), :eq(-1))', // Exclude first three and last columns from the column visibility toggle
      // },
      // {
      //   text: 'Show Default Column',
      //   action: function (e, dt, node, config) {
      //     dt.columns(':not(:lt(3),:last-child)').visible(false);
      //   },
      // },
      // {
      //   text: 'Show All Column',
      //   action: function (e, dt, node, config) {
      //     dt.columns().visible(true);
      //   },
      // },
    ],
  };
  @ViewChild(DataTableDirective, { static: false })
  datatableElement: DataTableDirective;
  // dtMMCFaxProcessApi: DataTables.Api;
  constructor(private snackBar: MatSnackBar, private datepipe:DatePipe) { }

  ngOnInit(): void {
    this.claimDetailsList=this.tableDataList;

    this.dtClaimOptions.buttons[0].filename =
    'Patient Claim' + '-' + this.datepipe.transform(new Date(), 'MM-dd-yyyy');
  }

  // ngAfterViewInit(): void {
  //   if (this.datatableElement && this.datatableElement.dtInstance) {
  //     this.refreshDataTable();
  //   } else {
  //     // If dtInstance is not available yet, wait for a short time and try again
  //     setTimeout(() => this.refreshDataTable(), 500);
  //   }
  //   window.removeEventListener('resize', this.calculateScrollY);
  // }

  
  ngAfterViewInit(): void {
    if (this.datatableElement && this.datatableElement.dtInstance) {
      this.refreshDataTable();
    } else {
      setTimeout(() => this.refreshDataTable(), 500);
    }
    setTimeout(() => {
      $('#tblClaimOption').DataTable().columns.adjust().draw();
    }, 200);
  }


  refreshDataTable(): void {
    this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.columns().every(function () {
        const that = this;
        $('input', this.footer()).on('keyup change', function () {
          if (that.search() !== this['value']) {
            that.search(this['value']).draw();
          }
        });
      });
    });
  }


  ngOnDestroy(): void {
    window.removeEventListener('resize', this.calculateScrollY);
  }

  calculateScrollY = () => {
    const screenHeight =
      window.innerHeight ||
      document.documentElement.clientHeight ||
      document.body.clientHeight;
    const availableSpacePercentage = 0.2; // Adjust the percentage as needed
    this.dtClaimOptions.scrollY = screenHeight * availableSpacePercentage;
  };
  // refreshDataTable(): void {
  //   this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
  //     // Clear search input values
  //     $('input', dtInstance.table(0).footer()).val('');
  //     // Redraw the DataTable to load all records
  //     dtInstance.search('').columns().search('').draw();
  //     // Attach the event listener for filtering
  //     dtInstance.columns().every(function () {
  //       const that = this;
  //       $('input', this.footer()).on('keyup change', function () {
  //         if (that.search() !== this['value']) {
  //           that.search(this['value']).draw();
  //         }
  //       });
  //     });
  //   });
  // }


  getFormattedDate(date: string): string {
    return date ? new Date(date).toLocaleDateString('en-US') : '-';
  }

  onRowDoubleClick(textToCopy) {
    this.copyTextToClipboard(textToCopy);
    this.showNotification('Text copied: ' + textToCopy);
  }

  showNotification(message: string): void {
    this.snackBar.open(message, 'Close', {
      duration: 3000, // Duration in milliseconds
      horizontalPosition: 'end', // Positioning the notification horizontally
      verticalPosition: 'bottom', // Positioning the notification vertically
    });
  }

  copyTextToClipboard(text: string): void {
    const textArea = document.createElement('textarea');
    textArea.value = text;
    document.body.appendChild(textArea);
    textArea.select();
    document.execCommand('Copy');
    textArea.remove();
  }

  isEmpty(value: any): boolean {
    return (
      value === null ||
      value === undefined ||
      value === '' ||
      value === defaultGuid
    );
  }
}
