import type { ClaimDTO, ExportDatasDTO } from './dto/models';
import { RestService } from '@abp/ng.core';
import type { PagedResultDto } from '@abp/ng.core';
import { Injectable } from '@angular/core';
import { SearchClaimDTO, SearchPrimaryClaimDTO } from './models';
import { claimStatus } from '../billing/claim-status.enum';

@Injectable({
  providedIn: 'root',
})
export class ClaimSearchService {
  apiName = 'billingManagement';
  exportReportByExportDatas = (exportDatas: ExportDatasDTO[]) =>
    this.restService.request<any, string>({
      method: 'POST',
      responseType: 'text',
      url: '/api/billingApp/claim-search/export-report',
      body: exportDatas,
    },
      { apiName: this.apiName });
  searchClaimByInput = (input: SearchClaimDTO) =>
    this.restService.request<any, ClaimDTO>({
      method: 'GET',
      url: '/api/billingApp/claim-search/search-claim',
      params: { claimId: input.claimId, defaultClaimId: input.defaultClaimId, defaultSaleOrderId: input.defaultSaleOrderId, defaultPatientId: input.defaultPatientId, patientName: input.patientName, totalBilled: input.totalBilled, totalAllowed: input.totalAllowed, totalWriteOff: input.totalWriteOff, totalInsurancePaid: input.totalInsurancePaid, totalPatBal: input.totalPatBal, status: input.status },
    },
      { apiName: this.apiName, skipHandleError: true });

  searchClaimByStatusByDtStartDateAndDtEndDateAndInput = (dtStartDate: string, dtEndDate: string, input: claimStatus) =>
    this.restService.request<any, PagedResultDto<ClaimDTO>>({
      method: 'GET',
      url: '/api/billingApp/claim-search/search-claim-by-status',
      params: { dtStartDate, dtEndDate, input },
    },
      { apiName: this.apiName });

  searchPrimaryClaimByInput = (input: SearchPrimaryClaimDTO) =>
    this.restService.request<any, PagedResultDto<ClaimDTO>>({
      method: 'GET',
      url: '/api/billingApp/claim-search/search-primary-claim',
      params: { defaultClaimId: input.defaultClaimId, defaultSaleOrderId: input.defaultSaleOrderId, defaultPatientId: input.defaultPatientId, patientName: input.patientName, totalBilled: input.totalBilled, totalInsBal: input.totalInsBal, totalPatBal: input.totalPatBal, checkNo: input.checkNo, payer: input.payer, payee: input.payee, checkIssueToDate: input.checkIssueToDate },
    },
      { apiName: this.apiName, skipHandleError: true });


  constructor(private restService: RestService) { }
}
