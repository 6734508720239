<br />
<div class="card card-body">
    <mat-accordion #firstAccordion="matAccordion">
        <mat-expansion-panel [expanded]="step">
            <mat-expansion-panel-header>
                <mat-panel-title class="customThemeClass">
                    <ng-container *ngIf="appointTypeId===''; else elseTitleTemplate">
                        <b>Add Supply Items</b>
                    </ng-container>
                    <ng-template #elseTitleTemplate>
                        <b>Edit Supply Items</b>
                    </ng-template>
                </mat-panel-title>

            </mat-expansion-panel-header>
            <form [formGroup]="AppontmentForm">
                <div class="row">
                    <mat-form-field class="col-4">
                        <mat-label> Supply Items <span class="asterisk">*</span></mat-label>
                        <input autocomplete="new-txtAppointDate" matInput maxlength="40" formControlName="txtAppointDate" type="text" />
                    </mat-form-field>
                    <mat-form-field class="col-4">
                        <mat-label> Created Date <span class="asterisk">*</span></mat-label>
                        <input autocomplete="new-txtDuration" matInput maxlength="40" formControlName="txtDuration" type="text" />

                    </mat-form-field>
                    <div class="col-md-3">
                        <br>
                        <mat-checkbox formControlName="chkActive">Active</mat-checkbox>
                    </div>
                </div>
            </form>
            <br />
            <div class="row">
                <div class="col-sm-12">
                    <div class="text-lg-center">
                        <button mat-button class="buttonColor mr-2" (click)="saveAppointment()">
                            Save</button>
                        <button mat-button class="resetclr buttonColor mb-2 mr-2" (click)="reset()">Reset</button>

                    </div>
                </div>
            </div>
        </mat-expansion-panel>
    </mat-accordion>
    <!-- <h4 class="customThemeClass ml-3 mt-2 mb-0"><b>Appontment Type Details</b></h4> -->

    <div class="row">
        <div class="col-12 table-responsive">
            <app-table  [strPageType]="strPageType" >
            </app-table>
        </div>

    </div>
</div>
<div class="row"></div>