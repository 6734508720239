<div class="container-fluid">
  <div class="row bg">
    <div class="col-12">
      <div class=" d-flex align-items-center justify-content-between mb-3">
        <h3 class="font-size-24 customThemeClass"><b>Settings / Client</b></h3>
        <!-- <div class=" page-title-box page-title-right">
          <ol class="breadcrumbs m-0">
            <li>
              <button *ngIf='isShownSaveButton' mat-button class="buttonColor font-size-14" (click)="newClient()">
                <i class="fa fa-plus" ></i>
                Add Client
              </button>
            </li>
            <li class="ml-2 mt-2 breadcrumb-item">Settings</li>
            <li class="mt-2 breadcrumb-item muted">Clients</li>
          </ol>
        </div> -->
        <div class="page-title-box page-title-right pt-3 pb-0">
          <!-- <form [formGroup]="ClientlistForm"> -->
              <ol class="breadcrumb m-0">
                <app-toggle-switch [onText]="'All'" [offText]="'Active'" class="mgnr" (changed)="change($event)" class="mr-3 mt-3">
                </app-toggle-switch>
                  <li>
                    <button *ngIf='isShownSaveButton' mat-button class="buttonColor font-size-14 mt-3" (click)="newClient()" [matTooltip]="'Add Client'">
                      <i class="fa fa-plus" ></i>
                      Add Client
                    </button>
                  </li>
                  <!-- <li class="ml-2 mt-4 breadcrumb-item">Settings</li>
                  <li class="mt-2 mt-4 breadcrumb-item muted">Clients</li> -->
              </ol>
          <!-- </form> -->
      </div>
      </div>
    </div>
  </div>
  <div class="card card-body" style="margin-top: -23px;">
    <div class="row">

      <div class="col-8">
        <h4 class="customThemeClass"><b>Client List</b></h4>
      </div>
      <div class="col-4">
        <!-- <app-toggle-switch [onText]="'Active'" [offText]="'All'" class="mgnr" (changed)="change($event)">
        </app-toggle-switch> -->
      </div>
      <!-- <div class="col-md-2">
        <form [formGroup]="clientSearchForm">
          <div class="md-form mt-0 text-left" style="margin-left: -87px;">
            <input autocomplete="new-ClientName" formControlName="ClientName" class="form-control mr-sm-2" type="text" placeholder="Search">
          </div>
        </form>
      </div> -->
    </div>

    <!-- <div class="row">
    <div class="col-12 table-responsive">
      <app-table *ngIf='isShownViewButton' [strPageType]="strPageType" (viewButton)="ViewClient($event)">
      </app-table>
    </div>

  </div> -->
    <div class="col-12" *ngIf="isLoading">
        <div class="d-flex justify-content-center" *ngIf="isLoading">
            <img class="img-responsive pb-2 mr-1" src="assets/gifs/Infinity-loader.gif" />
        </div>
    </div>
    <div class="col-12 table-responsive " style="margin-top: -21px;" *ngIf="!isLoading">
      <!-- <div [hidden]="!isLoading"
        style="display: flex; justify-content: center; align-items: center; background: transparent; color: lightseagreen;">
        <mat-progress-bar mode="indeterminate" value='66' diameter="45" class="lightseagreen">
        </mat-progress-bar>
      </div> -->
      <!-- <app-table [hidden]="isLoading" [strPageType]="strPageType" (viewButton)="existingOrders($event)"></app-table> -->
      <!-- <table mat-table class="table" [dataSource]="dataSource" matSort style=" border: 1px solid #cdc7c7;">
        <ng-container matColumnDef="sno">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            S.No
          </th>
          <td mat-cell *matCellDef="let datas; let i = index">
            {{ (paginator.pageIndex * paginator.pageSize) + (i + 1) }}
          </td>
        </ng-container>
        <ng-container matColumnDef="ClientCode">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            Code
          </th>
          <td mat-cell *matCellDef="let datas">
            <ng-container>
              {{ datas.code }}
            </ng-container>
          </td>
        </ng-container>
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            Name
          </th>
          <td mat-cell *matCellDef="let datas">
            {{ datas.name }}
          </td>
        </ng-container>
        <ng-container matColumnDef="mail">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            Email
          </th>
          <td mat-cell *matCellDef="let datas">
            {{ datas.mail }}
          </td>
        </ng-container>
        <ng-container matColumnDef="taxId">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            Tax ID
          </th>
          <td mat-cell *matCellDef="let datas">
            {{ datas.taxId == null || datas.taxId == "" ? "-" :  datas.taxId}}
          </td>
        </ng-container>
        <ng-container matColumnDef="status">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            Status
          </th>
          <td mat-cell *matCellDef="let datas">
            {{ datas.status }}
          </td>
        </ng-container>
        <ng-container matColumnDef="date">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            Create Date
          </th>
          <td mat-cell *matCellDef="let datas">
            {{ datas.date }}
          </td>
        </ng-container>

        <ng-container matColumnDef="Options">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            Options
          </th>
          <td mat-cell *matCellDef="let datas">
            <a (click)="ViewClient(datas)" class="eyeCursorclass" target="_blank"><i
                class="fa fa-eye pointer customThemeClass mr-5"></i> </a>
            &nbsp;
          </td>
        </ng-container>

        <ng-container matColumnDef="noRecords">
          <td class="text-align-center" mat-footer-cell *matFooterCellDef colspan="8">
            <div *ngIf="!isLoading">
              {{"No Records Found"}}
            </div>
            <div [hidden]="!isLoading">
              <mat-spinner [diameter]="220"></mat-spinner>
            </div>
          </td>
        </ng-container>
        <ng-container matColumnDef="paginator">
          <td mat-footer-cell *matFooterCellDef colspan="8">
            <mat-paginator class="table table-responsive mat-table-class" #MatPaginator
              [length]="dataSource?.data?.length" [pageSize]="10" [pageIndex]="0" showFirstLastButtons
              [pageSizeOptions]="[5, 10, 25, 100]">
            </mat-paginator>
          </td>
        </ng-container>

        <tr mat-footer-row *matFooterRowDef="['noRecords']"
          [ngClass]="{ active: dataSource && dataSource.data && dataSource?.data?.length !== 0 }"></tr>
        <tr mat-header-row *matHeaderRowDef="arrDisplayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: arrDisplayedColumns"></tr>
        <tr mat-footer-row *matFooterRowDef="['paginator']"></tr>
      </table> -->    
    <app-client-table [tableData]="clientListData"></app-client-table>
    </div>
  </div>
</div>
