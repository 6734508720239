<table
  datatable
  class="row-border hover w-100 display"
  [dtOptions]="dtPOTableOptions"
>
  <thead>
    <tr>
      <th [matTooltip]="'Manufacturer'">Manufacturer</th>
      <th [matTooltip]="'Product Code'">Product Code</th>
      <th [matTooltip]="'Product Name'">Product Name</th>
      <th [matTooltip]="'Quantity'" class="text-right">Quantity</th>
      <th [matTooltip]="'Unit Cost ($)'" class="text-right">Unit Cost ($)</th>
      <th [matTooltip]="'Total Cost ($)'" class="text-right">
        Total Cost ($)
      </th>
    </tr>
  </thead>
  <tbody>
    <!-- Using ngFor to iterate over productsArray and create table rows -->
    <tr *ngFor="let product of ltPurchaseOrders">
      <!-- Accessing properties of each product object for table cells -->
      <td [matTooltip]="product?.manufacturerName || '-'">
        {{ product.manufacturerName }}
      </td>
      <td [matTooltip]="product?.productCode || '-'">
        {{ product.productCode }}
      </td>
      <td [matTooltip]="product?.productDescription || '-'">
        {{ product.productDescription }}
      </td>
      <td class="text-right" [matTooltip]="product?.quantity || '-'">
        {{ product.quantity }}
      </td>
      <td
        class="text-right"
        matTooltip="{{ product.unitCost ? '$' + product.unitCost : '-' }}"
      >
        {{ product.unitCost ? "$" + product.unitCost : "-" }}
      </td>
      <td
        class="text-right"
        matTooltip="{{ product.totalCost ? '$' + product.totalCost : '-' }}"
      >
        {{ product.totalCost ? "$" + product.totalCost : "-" }}
      </td>
    </tr>
  </tbody>
  <tfoot>
    <tr>
      <th>
        <input
          matInput
          class="textbox-border-class"
          type="text"
          placeholder="Manufacturer Name"
        />
      </th>
      <th>
        <input
          matInput
          class="textbox-border-class"
          type="text"
          placeholder="Product Code"
        />
      </th>
      <th>
        <input
          matInput
          class="textbox-border-class"
          type="text"
          placeholder="Product Description"
        />
      </th>
      <th>
        <input
          matInput
          class="textbox-border-class text-right"
          type="text"
          placeholder="Quantity"
        />
      </th>
      <th>
        <input
          matInput
          class="textbox-border-class text-right"
          type="text"
          placeholder="Unit Cost ($)"
        />
      </th>
      <th>
        <input
          matInput
          class="textbox-border-class text-right"
          type="text"
          placeholder="Total Cost ($)"
        />
      </th>
    </tr>
  </tfoot>
</table>
