<div class="container-fluid">
  <div class="row bg">
    <div class="col-12">
      <div class="d-flex align-item-center justify-content-between m-0">
        <h4 class="mt-1 mb-2 font-size-18">
          <b>Patient / Alloted Authorization</b>
        </h4>
        <div class="page-title-box page-title-right">
          <ol class="breadcrumbs m-0">
            <button
              matTooltip="Click To Go back to Inbound"
              (click)="redirectToAuthList()"
              mat-button
              class="buttonColor font-size-10 mr-2"
            >
              <i class="fa fa-backward mr-1"></i>Back to Auth List
            </button>
          </ol>
        </div>
      </div>
    </div>
  </div>

  <div class="row bg">
    <div class="col-lg-12 pb-5">
      <ng-container *ngFor="let patientInfo of ltPatientInfo">
        <!-- <app-patient-auth-save-form
          [patientInfo]="patientInfo"
          [isView]="false"
          [isSupplies]="isSupplies"
        ></app-patient-auth-save-form> -->
      </ng-container>
    </div>
  </div>
</div>

<app-loader-component *ngIf="isLoading"></app-loader-component>
