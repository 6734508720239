import { filter } from 'rxjs/operators';
import { ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { tr } from 'date-fns/locale';
import { TimeLineComponent } from '../time-line/time-line.component';
import { HttpErrorResponse } from '@angular/common/http';
import Swal from 'sweetalert2';
import { MmOrderService } from '../order-proxy/order-management/order-optimization/mm-order.service';
import { DocumentMaxandTypeLimits, defaultGuid } from 'projects/shared/src/app/enums/allenums.enum';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { InboundDocumentService } from 'projects/admin/src/app/admin-proxy/platform-app-management/rcm/platform-management/inbound';
import { sortDocShortcode } from 'projects/patient/src/app/patient-proxy/patient/enumeration-data/verification-status.enum';
import { Title } from '@angular/platform-browser';
import { HellosignSignatureService } from '../order-proxy/order-management/order-optimization/hellosign-signature.service';
import { CreateUpdateHellosignSignatureDTO, HellosignDocumentDetails } from '../order-proxy/order-management/order-optimization/dto';
import { AsyncSubject, Observable, ReplaySubject, Subject } from 'rxjs';
import { PDFDocument } from 'pdf-lib';
import { PatientTemplateService } from 'projects/admin/src/app/admin-proxy/platform-app-management/rcm/platform-management/patient-template';
import { PagedAndSortedResultRequestDto } from '@abp/ng.core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DataTableDirective } from 'angular-datatables';
import emailMask from 'text-mask-addons/dist/emailMask';
import { PreviewDocumentComponent } from '../preview-document/preview-document.component';
import { LoaderService } from 'projects/shared/src/app/services/loader-service';
import { DatePipe } from '@angular/common';
export interface SelectedFiles {
  name: string;
  file: any;
  base64?: string;
  pageCount: number;
  fileLength: number;
}
@Component({
  selector: 'app-hello-sign',
  templateUrl: './hello-sign.component.html',
  styleUrls: ['./hello-sign.component.scss']
})
export class HelloSignComponent implements OnInit {
  helloSignForm: FormGroup;
  helloUploadForm: FormGroup;
  // helloSignSortedForm:FormGroup;
  @ViewChild(DataTableDirective, { static: false }) dtElement: DataTableDirective;
  dtTrigger: Subject<any> = new Subject();
  defaultGuid=defaultGuid;
  patientName:string;
  chkAll:boolean=false;
  statusSignatrueId: any;
  isCancelled: boolean = false;
  isSignedStatus: boolean = false;
  singnatureRequestedId: any;
  chartId:string;
  isLoaded: boolean = true;
  isLoadRevert :boolean=false;
  isTrue:boolean=true;
  loadPatientDetails: any;
  public files: any[] = [];
  templeteList: any;
  documentTypesArrayList: any=[];
  docSortedData: {
    fileName: string;
    contant
  };
  content: any;
  todayDate:Date;
  fileArray: any;
  noData:boolean=true;
  totalSizeMB
  totalSize
  dtOptions: any = {
    responsive: true,
    paging: false,
    scrollCollapse: true,
    scrollY: '300px',
    scrollX: true,
    language: {
      emptyTable: 'No records',
      info: 'Total : _MAX_ records',
      infoEmpty: 'Total : _MAX_ records',
      infoFiltered: '(filtered : _TOTAL_ records)',
      loadingRecords: 'Loading...',
      zeroRecords: 'No matching records',
    },
    order: [],
  };
  @ViewChild('fileInput') fileInput: ElementRef;
  orderId: string;
  patientId: string;
  docId:string;
  allCount:number=0;
  emailMask: any;
  corresCount:number=0;
  facecount:number=0;
  demoCount:number=0;
  sleepCount:number=0;
  authcount:number=0;
  rxCount:number=0;
  documentTypesArray: any;
  docListData: any[];
  documentTypes: any;
  sortDocShortcode=sortDocShortcode;
  showErrorForFileType: boolean = false;
  showErrorBasedOnMaxSize: boolean;
  arrSelectedDocuments: any[];
  newFilesForUpload: any[];
  chkdisableButton: boolean;
  showErrorForFileLength: boolean;
  selectedFileCount: number;
  totalSizeforTable: any;
  disableMainFile: boolean;
  AddMoreFileSize: number;
  selectedFilesS: number;
  isOrderPageLoading: any;
  isView:boolean=false;
  public selectedFiles : SelectedFiles[] = [];
 signatureId:string=defaultGuid;
  documentTypesArrayV1: any;
  documentTypesFalseList: any;
  status: any;
  manualUploadFiles: any;
  documentTypesArrayListSelected: any;
  test: boolean=true;
  constructor(
    private formBuilder: FormBuilder,
    private toastr: ToastrService,
    private activatedRoute: ActivatedRoute,
     private mmOrderService: MmOrderService,
     public inboundDocUploadService: InboundDocumentService,
     private dialog: MatDialog,
     private title: Title,
     private HellosignSignatureService: HellosignSignatureService,
     private patientTemplateService: PatientTemplateService,
     private _snackBar: MatSnackBar,
     private datepipe: DatePipe,
     private loaderService: LoaderService,
  ) {     this.loaderService.getLoaderState().subscribe((isOrderPageLoading) => {
    this.isOrderPageLoading = isOrderPageLoading;
  });}

  ngOnInit(): void {
    this.title.setTitle('Qsecure | Hello Sign ');
    const activatedRoute = this.activatedRoute.paramMap.subscribe(
      (response) => {
        this.orderId = response.get('orderId') ?? defaultGuid;
        this.patientId = response.get('patientId') ?? defaultGuid;
        this.isView = response.get('isView')==='true'?true: false;
        this.signatureId= response.get('signatureId')===undefined?defaultGuid:response.get('signatureId');
      },
      (err) => {
        const data: HttpErrorResponse = err;
        this.toastr.error(data?.error?.error?.message, 'Error');
      }
    );
    this.todayDate=new Date();
   this.loadForm();
  //  this.loadSortedDocForm();
   this.loadTemplateDetails()
   this.emailMask = emailMask;
    if(this.isView===true){
     this.loadAllDetailsForView();
    }else{
      this.loadpatientDetails();
    }
  }

  //patient details form
   loadForm(){
  this.helloSignForm = this.formBuilder.group({
    txtEmail: new FormControl('', Validators.required,),
    txtDocSentOn: new FormControl(''),
    txtDocRecivedOn: new FormControl(''),
    txtExpiresOn: new FormControl(''),
    txtStatus: new FormControl(''),
    txtexpiredStatus: new FormControl(''),
    txtTicketNo: new FormControl(''),
    txtOrderDate: new FormControl(''),
    txtCsr: new FormControl(''),
    txtCreatedBy: new FormControl(''),
    txtDocLinkExpireOn: new FormControl('', Validators.required,)
  })
   }

   //sorted document from
  //  loadSortedDocForm(){
  //   this.helloSignSortedForm = this.formBuilder.group({
  //     chkAll:new FormControl(''),
  //     // chkSorted: new FormControl(''),
  //   })
  //  }
   loadAllDetailsForView(){
    this.isLoaded=false;
    const saveHelloSign = this.HellosignSignatureService.HellosignDetailsByhellosignId(this.signatureId).subscribe(response => {
      this.patientName=response.patientName;
        this.chartId=response.chartNumber;
        this.isSignedStatus = response?.helloSignStatus  === 'signed'? true : false
        this.singnatureRequestedId = response.signaturerequestid
      this.helloSignForm.patchValue({
        txtEmail: response?.patientEmail,
        txtDocSentOn:  response?.documentSentOn,
        txtDocRecivedOn:  response?.documentReceivedOn,
        // txtExpiresOn:  response?.items[0]?.expireDate,
         txtStatus: response?.helloSignStatus,
         txtexpiredStatus:  response?.expireStatus,
        txtTicketNo:  response?.orderNumber,
        txtOrderDate:  response?.orderDate,
        txtCsr:  response?.csrName,
        txtCreatedBy:  response?.createdBy,
       txtDocLinkExpireOn:  response?.expireDate
      })
      this.status=response.helloSignStatus;
      this.docListData = response.ldDocuments
      this.docId=response.inboundDocumentId;
      // this.docSortedData = response.sortedDocuments
      this.documentTypes = response.ldDocuments.map((value) => ({
        docTypeId: value.docTypeId,
         shortCode: value.shortCode,
        // isMedicareBin: value?.isMedicareBin,
        documentType: value?.documentType.toUpperCase(),
        // documentPages: value?.docPageContent?.ltPageNumbers ?? [],
        pageCount: value?.pageCount,
        fileContent: value.fileContent,
        fileLength: value?.fileLength,
        isTrue:value?.isTrue
      }));
      this.allCount=response?.totalPageCount;
      // if(this.allCount !==0){
      //    this.helloSignSortedForm.patchValue({
      //   chkAll: true,
      //   });
      // }
       this.documentTypesArray=this.documentTypes.filter((i) => i.pageCount !==0 &&(i.shortCode!==''&&i.shortCode!==null));
       this.documentTypesArrayList=this.documentTypes.filter((i) => i.shortCode===''||i.shortCode===null);
       this.documentTypesArrayList=this.documentTypesArrayList.concat(this.documentTypesArray);
       this.documentTypesFalseList =this.documentTypesArray.filter((i) => i.isTrue ===false)
       if(this.documentTypesFalseList.length===0){
        this.chkAll=true;
        //  this.helloSignSortedForm.patchValue({
        //    chkAll:true,
        //   //  chkSorted:true
        //  });
       }
      this.isLoaded = true;
     if(this.documentTypesArray.length===0){
      this.noData=true;
     }else{
      this.noData=false;
     }
    },(err) =>{
      this.isLoaded = true;
      const data: HttpErrorResponse = err;
          Swal.fire({
            icon: 'info',
            text: data?.error?.error?.message,
          });
    })
   }
   loadpatientDetails(){
    this.mmOrderService.patientOrderDetails(this.orderId,this.patientId).subscribe(
      (response) => {
        this.statusSignatrueId = response?.helloSignaturId,
        this.signatureId=response?.helloSignaturId,
        this.docId=response.documentId;
        this.isSignedStatus = response?.hellosignStatus  === 'signed'? true : false
        // this.isCancelled = response?.hellosignStatus === 'Cancelled' ? true : false
        this.loadPatientDetails  = response;
        this.patientName=response.patientName;
        this.chartId=response.patientChartId;
        this.docId=response.documentId;
        this.status=response.hellosignStatus;
        this.loadSortedDocument();
        this.helloSignForm.patchValue({
          txtEmail: response.emailId,
          txtDocSentOn: new Date(),
          txtDocRecivedOn: response.docReceivedOn,
          txtExpiresOn: response.expiryOn,
           txtStatus:response.hellosignStatus,
          //txtexpiredStatus: response.expiryStatus,
          txtTicketNo: response.orderNo,
          txtOrderDate: response.orderDate,
          txtCsr: response.csrName,
          txtCreatedBy: response.orderCreatedBy,
          // txtDocLinkExpireOn: response.lickExpiryOn
        })

      },
      (err) => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      }
    );
    }
    //  template details
    loadTemplateDetails(){
      this.patientTemplateService.getList(new PagedAndSortedResultRequestDto).subscribe(response => {
        this.templeteList = response?.items
      })
    }
    getLoadSortedDocumentdetails(helloSignaturId){
      setTimeout(() => {
        this.isLoaded=false;
      }, 10);
      const saveHelloSign = this.HellosignSignatureService.HellosignDetailsByhellosignId(helloSignaturId).subscribe(response => {
        this.patientName=response.patientName;
        setTimeout(() => {
          this.isLoaded=true;
        }, 10);
          this.chartId=response.chartNumber;
        this.helloSignForm.patchValue({
          txtEmail: response?.patientEmail,
          txtDocSentOn:  response?.documentSentOn,
          txtDocRecivedOn:  response?.documentReceivedOn,
          // txtExpiresOn:  response?.items[0]?.expireDate,
           txtStatus: response?.helloSignStatus,
           txtexpiredStatus:  response?.expireStatus,
          txtTicketNo:  response?.orderNumber,
          txtOrderDate:  response?.orderDate,
          txtCsr:  response?.csrName,
          txtCreatedBy:  response?.createdBy,
         txtDocLinkExpireOn:  response?.expireDate
        })
        this.singnatureRequestedId = response.signaturerequestid
        this.status=response.helloSignStatus;
        this.docListData = response.ldDocuments
        this.docId=response.inboundDocumentId;
        // this.docSortedData = response.sortedDocuments
        this.documentTypes = response.ldDocuments.map((value) => ({
          docTypeId: value.docTypeId,
           shortCode: value.shortCode,
          // isMedicareBin: value?.isMedicareBin,
          documentType: value?.documentType.toUpperCase(),
          // documentPages: value?.docPageContent?.ltPageNumbers ?? [],
          pageCount: value?.pageCount,
          fileContent: value.fileContent,
          fileLength: value?.fileLength,
          isTrue:value?.isTrue
        }));
        this.allCount=response?.totalPageCount;
        // if(this.allCount !==0){
        //    this.helloSignSortedForm.patchValue({
        //   chkAll: true,
        //   });
        // }
         this.documentTypesArray=this.documentTypes.filter((i) => i.pageCount !==0 &&(i.shortCode!==''&&i.shortCode!==null));
         this.documentTypesArrayList=this.documentTypes.filter((i) => i.shortCode===''||i.shortCode===null);
         this.documentTypesArrayList=this.documentTypesArrayList.concat(this.documentTypesArray);
         this.documentTypesFalseList =this.documentTypesArray.filter((i) => i.isTrue ===false)
         if(this.documentTypesFalseList.length===0){
          this.chkAll=true;
          //  this.helloSignSortedForm.patchValue({
          //    chkAll:true,
          //   //  chkSorted:true
          //  });
         }
       if(this.documentTypesArray.length===0){
        this.noData=true;
       }else{
        this.noData=false;
       }
      },(err) =>{
        this.isLoaded = true;
        const data: HttpErrorResponse = err;
            Swal.fire({
              icon: 'info',
              text: data?.error?.error?.message,
            });
      })
    }
    downloadTemplatePdf(value){
      this.patientTemplateService.getMailTemplateByBlobIDByBlobName(value.blobName).subscribe(response => {
        var blob = this.b64toBlob(response, "application/pdf");
        let a = document.createElement("a");
        document.body.appendChild(a);
        var url = window.URL.createObjectURL(blob);
        a.href = url;
        a.download = value.templateName
        a.click();
        window.URL.revokeObjectURL(url);
        a.remove();
      })
    }
    downloadSortedDoc(doc){
      var blob = this.b64toBlob(doc.fileContent, "application/pdf");
        let a = document.createElement("a");
        document.body.appendChild(a);
        var url = window.URL.createObjectURL(blob);
        a.href = url;
        a.download = doc?.documentType
        a.click();
        window.URL.revokeObjectURL(url);
        a.remove();

    }
    viewSignedDocument(){

        const dialogRef = this.dialog.open(PreviewDocumentComponent, {
          height: '75%',
          width: '80%',
          disableClose: true,
          data: {
            // Add any data you want to pass to the dialog here
            // For example, you can pass patientId and documentId

            documentData: this.singnatureRequestedId,
            viewType: false

          },
        });

    }
    viewSortedDoc(doc){
      const dialogRef = this.dialog.open(PreviewDocumentComponent, {
        height: '75%',
        width: '80%',
        disableClose: true,
        data: {
          // Add any data you want to pass to the dialog here
          // For example, you can pass patientId and documentId

          documentData: doc.signaturerequestid,
          fileContent: doc.fileContent,
          viewType: true

        },
      });

      // Subscribe to the closed event
      dialogRef.afterClosed().subscribe(() => {
        // Call the addNotes function directly
        // dialogRef.componentInstance.addNotes();
      });
    }

    public b64toBlob(b64Data, contentType) {
      contentType = contentType || '';
      let sliceSize = 512;

      var byteCharacters = atob(b64Data);
      var byteArrays = [];

      for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
          var slice = byteCharacters.slice(offset, offset + sliceSize);

          var byteNumbers = new Array(slice.length);
          for (var i = 0; i < slice.length; i++) {
              byteNumbers[i] = slice.charCodeAt(i);
          }

          var byteArray = new Uint8Array(byteNumbers);

          byteArrays.push(byteArray);
      }

      var blob = new Blob(byteArrays, { type: contentType });
      return blob;
    }

    //sorted document details
  loadSortedDocument(){
    this.isLoaded=false;
    const sortingDocViewList = this.inboundDocUploadService
    .getSortedDocumentDetailsForHellosignByGDocumentId(this.docId)
    .subscribe(
      (response) => {
        this.isLoaded = true;
        if(this.helloSignForm.value.txtStatus != 'New'  && this.statusSignatrueId != defaultGuid){
          this.isLoaded = false
            this.getLoadSortedDocumentdetails(this.statusSignatrueId)

        }
        this.docListData = response?.sortedDocuments?.files
        // this.docSortedData = response.sortedDocuments
        this.documentTypes = response.ltDocuments.map((value) => ({
          docTypeId: value.documentTypeId,
          shortCode: value.shortCode,
          isMedicareBin: value?.isMedicareBin,
          documentType: value?.documentType.toUpperCase(),
          docPageContent: value?.docPageContent,
          pageCount: value?.pageCount ?? 0,
          fileContent: value?.fileContent,
          fileLength: value?.fileLength,
          isTrue:false,
        }));
        this.allCount=response?.sortedDocuments?.totalPageCount;
        // if(this.allCount !==0){
        //    this.helloSignSortedForm.patchValue({
        //   chkAll: true,
        //   });
        // }
         this.documentTypesArray=this.documentTypes.filter((i) => i.pageCount !==0 );
         this.documentTypesArrayV1=this.documentTypes.filter((i) => i.pageCount !==0 );
         this.documentTypesArrayList=[];

        this.isLoaded = true;
       if(this.documentTypesArray.length===0){
        this.noData=true;
       }else{
        this.noData=false;
       }

    },
    (err) => {
      this.isLoaded = true
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    }
  );
  }
  selectCheckBox(value, event){
    setTimeout(() => {
      this.isLoaded=false;
    }, 10);
    if(event.checked == true){
      setTimeout(() => {
        this.isLoaded=true;
      }, 10);
      // const exsiting = this.documentTypesArrayList?.find(x => x.documentType == value.documentType)
      // if(!exsiting){
        this.documentTypesArrayList.push(value)
      // }
      this.documentTypesArray.find(item => item.docTypeId == value.docTypeId).isTrue = true;
      this.documentTypesFalseList =this.documentTypesArray.filter((i) => i.isTrue ===false)
      if(this.documentTypesFalseList.length===0){
        // this.helloSignSortedForm.patchValue({
        //   chkAll:true,
        // });
        this.chkAll=true;
      }

    } else if (event.checked == false){
      this.documentTypesArray.find(item => item.docTypeId == value.docTypeId).isTrue = false;
      const objWithIdIndex = this.documentTypesArrayList.findIndex((obj) => obj.docTypeId === value.docTypeId);
      this.documentTypesArrayList.splice(objWithIdIndex, 1);
        // this.helloSignSortedForm.patchValue({
        //   chkAll:false,
        // });
        this.chkAll=false;
      // return this.documentTypesArray;
      setTimeout(() => {
        this.isLoaded=true;
      }, 10);
    }
  // Update DataTable options and trigger change detection
    // this.updateDataTable();
   }

  // updateDataTable() {
  //   this.dtOptions = { ...this.dtOptions };
  //   this.dtOptions.data = this.documentTypesArrayList;

  //       this.rerender();
  // }

  // rerender(): void {
  //   this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
  //     this.isLoaded = true

  //     // Destroy the table first
  //     dtInstance.destroy();

  //     // Call the dtTrigger to rerender again
  //     this.dtTrigger.next();
  //   });
  // }

  // ngAfterViewInit() {
  //   // Initialize DataTable after view is initialized
  //   this.dtTrigger.next();
  // }

  // ngOnDestroy(): void {
  //   // Do not forget to unsubscribe the event
  //   this.dtTrigger.unsubscribe();
  // }
  onFileChange(pFileList: File[]){
    this.files = Object.keys(pFileList).map(key => pFileList[key]);
    this._snackBar.open("Successfully upload!", 'Close', {
      duration: 2000,
    });
  }
  array(data){
    
  }

  // removedocumentFiles(value){
  //   if(this.isView !==true){
  //     setTimeout(() => {
  //       this.isLoaded=false;
  //     }, 10);
  //     const objWithIdIndex = this.documentTypesArrayList.findIndex((obj) => obj.documentType === value.documentType);
  //     this.documentTypesArrayList.splice(objWithIdIndex, 1);
  //     if(value.docTypeId !==null&&value.docTypeId !==defaultGuid){
  //     if(this.documentTypesArray.find(item => item.docTypeId == value.docTypeId)){
  //     let index=this.documentTypesArray.findIndex(item => item.docTypeId == value.docTypeId);
  //     if (index !== -1) {
  //         this.documentTypesArray[index].isTrue=false;
  //     }
  //     // let index=this.documentTypesArray.find(item => item.docTypeId == value.docTypeId).isTrue = false;
  //     }
  //     this.documentTypesFalseList =this.documentTypesArray.filter((i) => i.isTrue ===false)

  //     if(this.documentTypesFalseList.length===0){
  //       this.helloSignSortedForm.patchValue({
  //         chkAll:true,

  //       });
  //     }else{
  //       this.helloSignSortedForm.patchValue({
  //         chkAll:false,
  //         // chkSorted:false
  //       });
  //     }
  //   }
  //   setTimeout(() => {
  //     this.isLoaded=true;
  //   }, 10);
  //   }
  //   console.log("array")
  //   console.log(this.documentTypesArray)
  //   console.log(this.helloSignSortedForm)
  // }
  removedocumentFiles(value){
    if(this.isView !==true){
      setTimeout(() => {
        this.isLoaded=false;
      }, 10);
      const objWithIdIndex = this.documentTypesArrayList.findIndex((obj) => obj.documentType === value.documentType);

      this.documentTypesArrayList.splice(objWithIdIndex, 1);
      setTimeout(() => {
        this.isLoaded=true;
      }, 10);
      if(value.docTypeId !==null&&value.docTypeId !==defaultGuid){
      if(this.documentTypesArray.find(item => item.docTypeId == value.docTypeId)){
      // this.documentTypesArray.find(item => item.docTypeId == value.docTypeId).isTrue = false;

      this.test=false
      const index = this.documentTypesArray.findIndex(
        (obj) => obj.docTypeId === value.docTypeId
      );
      if (index !== -1) {
        this.documentTypesArray[index].isTrue = false;
      }

      setTimeout(() => {
        this.test=true
      }, 10);





      }
      this.documentTypesFalseList =this.documentTypesArray.filter((i) => i.isTrue ===false)

      if(this.documentTypesFalseList.length===0){
        // this.helloSignSortedForm.patchValue({
        //   chkAll:true,
        // });
        this.chkAll=true;
      }else{
        // this.helloSignSortedForm.patchValue({
        //   chkAll:false,
        // });
        this.chkAll=false;
      }
    }
    }
  }



   async saveHelloSign(){

    let totalSize = 0;

    this.documentTypesArrayList.forEach(file => {
      totalSize = totalSize + file.fileLength;
  })
      if((totalSize <= 20.00) ){
        this.loaderService.showLoader();
        const saveDetails: CreateUpdateHellosignSignatureDTO = {
          tenantId: defaultGuid,
          patientId: this.patientId,
          orderId: this.orderId,
          inboundDocumentId: this.loadPatientDetails?.documentId,
          signaturerequestid: '',
          documentSentOn: this.datepipe.transform(this.helloSignForm.value?.txtDocSentOn, 'yyyy-MM-dd')  ?? '',
          documentReceivedOn: this.datepipe.transform(this.helloSignForm.value.txtDocRecivedOn, 'yyyy-MM-dd')  ?? '',
          signedOn: '',
          expireDate:this.datepipe.transform(this.helloSignForm.value.txtDocLinkExpireOn, 'yyyy-MM-dd')  ?? '',
          expireStatus: this.helloSignForm.value.txtexpiredStatus ?? '',
          helloSignStatus: this.helloSignForm.value.txtStatus ?? '',
          patientEmail: this.helloSignForm.value.txtEmail.toLowerCase() ?? '',
          status: true,
          fileName: [],
          blobName: [],
          isResend: false,
          filePath: [],
          resendCount: 0,
          documentDetails: this.documentTypesArrayList
        }
        const saveHelloSign = this.HellosignSignatureService.sendSingatureRequestByFileAndInput(null,saveDetails).subscribe(response => {
          this.loaderService.hideLoader();
          this.signatureId=response.id;
          this.status=response.helloSignStatus;
          this.helloSignForm.patchValue({
            txtStatus: response.helloSignStatus,
          })
          this.loadAllDetailsForView()
          this.toastr.success('Mail sent successfully', 'Success');
        },(err) =>{
          this.loaderService.hideLoader();
          const data: HttpErrorResponse = err;
              Swal.fire({
                icon: 'info',
                text: data?.error?.error?.message,
              });
        })
      } else {
        this.loaderService.hideLoader();
        this.toastr.warning('Files should not exceed 20MB')
      }


    // Convert bytes to MB
    // const totalSizeMB = (this.totalSize / (1024 * 1024)).toFixed(2);
    // console.log(totalSizeMB)


  }
//resent mail

  reSendMailHelloSign(){

    let totalSize = 0;

    this.documentTypesArrayList.forEach(file => {
      totalSize = totalSize + file.fileLength;
  })
      if(totalSize <= 20.00 ){
    this.loaderService.showLoader();
    const saveDetails: CreateUpdateHellosignSignatureDTO = {
      tenantId: defaultGuid,
      patientId: this.patientId,
      orderId: this.orderId,
      inboundDocumentId: this.loadPatientDetails?.documentId,
      signaturerequestid: '',
      documentSentOn: this.datepipe.transform(this.helloSignForm.value.txtDocSentOn, 'yyyy-MM-dd') ?? '',
      documentReceivedOn: this.datepipe.transform(this.helloSignForm.value.txtDocRecivedOn, 'yyyy-MM-dd') ?? '',
      signedOn: '',
      expireDate: this.datepipe.transform(this.helloSignForm.value.txtDocLinkExpireOn, 'yyyy-MM-dd') ?? '',
      expireStatus: this.helloSignForm.value.txtexpiredStatus ?? '',
      helloSignStatus: this.helloSignForm.value.txtStatus ?? '',
      patientEmail: this.helloSignForm.value.txtEmail.toLowerCase() ?? '',
      status: true,
      fileName: [],
      blobName: [],
      isResend: true,
      filePath: [],
      resendCount: 0,
      documentDetails: this.documentTypesArrayList
    }
    const reSendHelloSign = this.HellosignSignatureService.sendSingatureRequestByFileAndInput(null,saveDetails).subscribe(response => {
      this.signatureId = response.id;
      this.loaderService.hideLoader();
      this.loadAllDetailsForView()
      // this.signatureId='8d69b793edd3b1ea9044da8202f0819731d97a02';
      this.toastr.success('Resend Successfully', 'Success');
    },(err) =>{
      this.loaderService.hideLoader();
      const data: HttpErrorResponse = err;
          Swal.fire({
            icon: 'info',
            text: data?.error?.error?.message,
          });
    })
  } else {
    this.loaderService.hideLoader();
    this.toastr.warning('Files should not exceed 20MB')
  }

  }
  calculateTotalSizeForMore(files,) {
    let totalSize = 0;
    let status = false;
    let maxTotalSize = DocumentMaxandTypeLimits.helloSignFileSizeLimit;
    for (const key in files) {
      if (files.hasOwnProperty(key)) {
        totalSize += files[key].size;
      }
    }
    // for (const key in AddMoreFile) {
    //   if (AddMoreFile.hasOwnProperty(key)) {
    //     totalSize += AddMoreFile[key].size;
    //   }
    // }
    if (totalSize <= maxTotalSize) {
      status = true;
    }
    return status;
  }

  canceledSelectedDocuments(){
    Swal.fire({
      title: 'Are you sure you want to Cancel?',
      icon: 'info',
      showCancelButton: true,
      confirmButtonColor: '#34c38f',
      cancelButtonColor: '#f46a6a',
      confirmButtonText: 'Yes!',
      cancelButtonText:'No'
    }).then(result => {
      if (result.value) {
        setTimeout(() => {
          this.isLoaded=false;
        }, 10);
    // this.helloSignSortedForm.patchValue({
    //   chkAll:false,
    //   // chkSorted: false,
    // });
     this.chkAll=false;
    this.documentTypesArray = this.documentTypes.filter((i) => i.pageCount !==0 ).map((value) => ({
      docTypeId: value.docTypeId,
      shortCode: value.shortCode,
      isMedicareBin: value?.isMedicareBin,
      documentType: value?.documentType.toUpperCase(),
      docPageContent: value?.docPageContent,
      pageCount: value?.pageCount ?? 0,
      fileContent: value?.fileContent,
      fileLength: value?.fileLength,
      isTrue:false,
    }));
    this.documentTypesArrayList=[];
    setTimeout(() => {
      this.isLoaded=true;
    }, 10);
  }
   })
  }
  selectOrDeselectAll(event){
    setTimeout(() => {
      this.isLoaded=false;
    }, 10);
     if(event.checked===true){
      // this.helloSignSortedForm.patchValue({
      //   // chkSorted: true,
      // });
      // this.documentTypesArrayList.push(this.documentTypesArrayV1);
      this.documentTypesArray = this.documentTypes.filter((i) => i.pageCount !==0 &&(i.shortCode!==''&&i.shortCode!==null)).map((value) => ({
        docTypeId: value.docTypeId,
        shortCode: value.shortCode,
        isMedicareBin: value?.isMedicareBin,
        documentType: value?.documentType.toUpperCase(),
        docPageContent: value?.docPageContent,
        pageCount: value?.pageCount ?? 0,
        fileContent: value?.fileContent,
        fileLength: value?.fileLength,
        isTrue:true,
      }));
      this.manualUploadFiles=this.documentTypesArrayList.filter((i)=>i.docTypeId===null||i.docTypeId===defaultGuid);
      this.documentTypesArrayList= this.documentTypes.filter((i) => i.pageCount !==0 &&(i.shortCode!==''&&i.shortCode!==null)).map((value) => ({
        docTypeId: value.docTypeId,
        shortCode: value.shortCode,
        isMedicareBin: value?.isMedicareBin,
        documentType: value?.documentType.toUpperCase(),
        docPageContent: value?.docPageContent,
        pageCount: value?.pageCount ?? 0,
        fileContent: value?.fileContent,
        fileLength: value?.fileLength,
        isTrue:true,
      }));
      this.documentTypesArrayList= this.documentTypesArrayList.concat(this.manualUploadFiles);
      setTimeout(() => {
      this.isLoaded=true;
    }, 10);
     }else{
      // this.helloSignSortedForm.patchValue({
      //   // chkSorted: false,
      // });
      this.documentTypesArray = this.documentTypes.filter((i) => i.pageCount !==0 &&(i.shortCode!==''&&i.shortCode!==null) ).map((value) => ({
        docTypeId: value.docTypeId,
        shortCode: value.shortCode,
        isMedicareBin: value?.isMedicareBin,
        documentType: value?.documentType.toUpperCase(),
        docPageContent: value?.docPageContent,
        pageCount: value?.pageCount ?? 0,
        fileContent: value?.fileContent,
        fileLength: value?.fileLength,
        isTrue:false,
      }));
      this.manualUploadFiles=this.documentTypesArrayList.filter((i)=>i.docTypeId===null||i.docTypeId===defaultGuid);
      this.documentTypesArrayList=[];

      this.documentTypesArrayList= this.documentTypesArrayList.concat(this.manualUploadFiles);
      setTimeout(() => {
        this.isLoaded=true;
      }, 10);
     }

  }
  revert(){
    this.isLoadRevert=true;
    Swal.fire({
      title: 'Are you sure you want to Revert?',
      icon: 'info',
      showCancelButton: true,
      confirmButtonColor: '#34c38f',
      cancelButtonColor: '#f46a6a',
      confirmButtonText: 'Yes!',
      cancelButtonText: 'No'
    }).then(result => {
      if (result.value) {
    const saveHelloSign = this.HellosignSignatureService.revertSignatureRequest(this.signatureId).subscribe(response => {
      this.loaderService.hideLoader();
      this.signatureId=response.id;
      this.status=response.helloSignStatus;
      this.helloSignForm.patchValue({
        txtStatus: response.helloSignStatus,
      })
      this.loadAllDetailsForView()
      this.toastr.success('Reverted Successfully', 'Success');
    },(err) =>{
      this.isLoadRevert=false;
      const data: HttpErrorResponse = err;
          Swal.fire({
            icon: 'info',
            text: data?.error?.error?.message,
          });
    })
  }else{
    this.isLoadRevert=false;
  }
  });
  }

  toFilesBase64(files: File[], selectedFiles: SelectedFiles[]): Observable<SelectedFiles[]> {
    const result = new AsyncSubject<SelectedFiles[]>();
    if (files?.length) {
      Object.keys(files)?.forEach((file, i) => {
        const reader = new FileReader();
        reader.readAsDataURL(files[i]);
        reader.onload = async () => {
          const base64String = reader.result as string;
          // Remove the content type prefix from the base64 string
          const base64Data = base64String.split(',')[1];
          const fileName: string = files[i].name;
          const m1fileName: string = fileName.replace('.pdf', '');
          const m2fileName: string = m1fileName.replace('.pdf', '');
          // Filter out the file with the same name if it exists
          const getPDFPageCount = async (file: File) => {
              const pdfDoc = await PDFDocument.load(await file.arrayBuffer());
              const pages = pdfDoc.getPages();
              return pages.length;
            };
          const totalSize = (files[i].size / (1024 * 1024)).toFixed(2);
          selectedFiles = selectedFiles.filter(f => f.name !== m2fileName);
          // Push the file details with the base64 data to the selectedFiles array


          const pdfBytes = await getPDFPageCount(files[i]).then((pageCount) => {
            let count = Number(pageCount)
            selectedFiles.push({ name: m2fileName, file: files[i], base64: base64Data ,pageCount: count , fileLength: Number(totalSize)});
          })

           result.next(selectedFiles);
          if (files.length === (i + 1)) {
            result.complete();
          }
        };
      });
      return result;
    } else {
      result.next([]);
      result.complete();
      return result;
    }
  }
   onFileSelected(files: File[]) {

    this.showErrorForFileType = false
    this.selectedFiles = []

    if (this.calculateTotalSizeForMore(files)) {
      this.showErrorForFileType = false;
      this.showErrorBasedOnMaxSize = false;
      this.showErrorForFileLength = false;
      this.arrSelectedDocuments = [];
      // this.filesForUpload = files;
      this.newFilesForUpload = [];
      this.mergeFileLists(files);
      this.chkdisableButton = true;
      this.selectedFileCount = files.length;
      this.totalSizeforTable = this.calculateTotalSizeValue(files);
      const totalFiles = files.length;
      let filesProcessed = 0;
    if (files && files.length > 0) {
      let fileResult: {
        fileName: string;
        fileType: string;
        fileSize: string;
        pageCount?: number;
      }[] = [];
      const fileList: File[] = [];
      // const onCompleteProcessing = () => {
      //   filesProcessed++;
      //   if (filesProcessed === totalFiles) {
      //     this.chkdisableButton = false;
      //   }
      // };
      for (let i = 0; i < files.length; i++) {
        if (files[i].type !== DocumentMaxandTypeLimits.documentTypeLimit) {
          this.showErrorForFileType = true;
          this.showErrorBasedOnMaxSize = false;
          this.showErrorForFileLength = false;
          this.arrSelectedDocuments = [];
          this.chkdisableButton = false;
          this.selectedFileCount = 0;
          return;
        } else{
          this.showErrorForFileType = false
          this.isLoaded = false

          const fileName: string = files[i].name;
          const m1fileName: string = fileName.replace('.pdf', '');
          const m2fileName: string = m1fileName.replace('.pdf', '');
         let exsit =  this.documentTypesArrayList.find(x=> x.documentType  == m2fileName)
         if(!exsit){
          this.pdfUpload(files)

         } else{
          this.isLoaded = true
             this._snackBar.open(`File ${m2fileName} Already Exsit!"`, 'Close', {
              duration: 2000,
            });
         }
        }

        // fileList.push(files[i]);
        // const fileName: string = files[i].name;
        // const m1fileName: string = fileName.replace('.pdf', '');
        // const m2fileName: string = m1fileName.replace('.pdf', '');

        // const getPDFPageCount = async (file: File) => {
        //   const pdfDoc = await PDFDocument.load(await file.arrayBuffer());
        //   const pages = pdfDoc.getPages();
        //   return pages.length;
        // };

        // const getPDFPageCount = async (file: File) => {
        //   try {
        //     const pdfBytes = await file.arrayBuffer();
        //     const pdfDoc = await PDFDocument.load(pdfBytes, { ignoreEncryption: true }); // Add { ignoreEncryption: true }
        //     const pages = pdfDoc.getPages();
        //     return pages.length;
        //   } catch (error) {
        //     console.warn(error);
        //     return 0; // Return a default value or handle the error as per your requirement
        //   }
        // };

        // getPDFPageCount(files[i]).then((pageCount) => {
        //   fileResult.push({
        //     fileName: m2fileName,
        //     fileType: files[i].type === 'application/pdf' ? 'PDF' : 'N/A',
        //     fileSize: this.convertBytesToReadable(files[i].size),
        //     pageCount: pageCount,
        //   });
        //   onCompleteProcessing();
        // });
      }

      this.arrSelectedDocuments = fileResult;
      console.log(this.arrSelectedDocuments)
      this.disableMainFile = true;
    } else{

      console.log('....else....')
    }
  } else{
    this.showErrorForFileType = false;
    this.showErrorBasedOnMaxSize = true;
    this.showErrorForFileLength = false;
    this.arrSelectedDocuments = [];
    this.chkdisableButton = false;
    this.selectedFileCount = 0;
    // event.target.value = null;
    return;
  }

    // this.selectedFiles = []; // clear

  }

  pdfUpload(files){
    this.isLoaded = false
    this.toFilesBase64(files, this.selectedFiles).subscribe((res: SelectedFiles[]) => {
      this.selectedFiles = res;
      // console.log(res,'>>>>>>>>>>>>>>>>>>>>>>Res')
      res.forEach(value =>{
          console.log(value.name,this.selectedFiles)
          const data = this.documentTypesArrayList.find(x => (x.documentType.toUpperCase() == value?.name.toUpperCase())  )

          if(!data){
            this.documentTypesArrayList.push({
              docTypeId: defaultGuid,
              shortCode: '',
              isMedicareBin: false,
              documentType: value.name.toUpperCase(),
              pageCount: value.pageCount,
              fileContent: value?.base64,
              fileLength: value?.fileLength,
             isTrue:false
            })
            this._snackBar.open("Successfully upload!", 'Close', {
              duration: 2000,
            });


          } else{
            // this._snackBar.open(`File ${value?.name} Already Exsit!"`, 'Close', {
            //   duration: 2000,
            // });
          }


        this.isLoaded = true
        // if(!data){
        //   this.documentTypesArrayList.push({
        //     docTypeId: null,
        //     shortCode: '',
        //     isMedicareBin: false,
        //     documentType: value.name.toUpperCase(),
        //     pageCount: value.pageCount,
        //     fileContent: value?.base64,
        //    isTrue:false
        //   })
        //   this._snackBar.open("Successfully upload!", 'Close', {
        //     duration: 2000,
        //   });


        // } else{
        //   console.log('else popup')
        //   this._snackBar.open(`File ${value?.name} Already Exsit!"`, 'Close', {
        //     duration: 2000,
        //   });
        // }

      })
      console.log( this.documentTypesArrayList,'documentTypesArrayList')
    });
  }
  calculateTotalSize(files) {
    let totalSize = 0;
    let status = false;
    let maxTotalSize = DocumentMaxandTypeLimits.ManualUploadMaxSize;
    for (const key in files) {
      if (files.hasOwnProperty(key)) {
        totalSize += files[key].size;
      }
    }
    if (totalSize <= maxTotalSize) {
      status = true;
    }
    return status;
  }

  calculateTotalSizeValue(files) {
    this.AddMoreFileSize = 0;
    let totalSize = 0;
    for (const key in files) {
      if (files.hasOwnProperty(key)) {
        totalSize += files[key].size;
      }
    }
    this.AddMoreFileSize = totalSize;
    return this.convertBytesToReadable(totalSize);
  }

  convertBytesToReadable(bytes) {
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    if (bytes == 0) return '0 Byte';
    if (isNaN(bytes)) return '0 Bytes';
    const i = Math.floor(Math.log(bytes) / Math.log(1024));
    return parseFloat((bytes / Math.pow(1024, i)).toFixed(2)) + ' ' + sizes[i];
  }


  mergeFileLists(newFiles) {
    for (let i = 0; i < newFiles.length; i++) {
      const file = newFiles[i];
      // Check if the file already exists in the array
      const exists = this.newFilesForUpload.some(
        (existingFile) =>
          existingFile.name === file.name && existingFile.size === file.size
      );
      if (!exists) {
        this.newFilesForUpload.push(file);
        // this.toastr.success(`File "${file.name}" Added.`, 'Success' );
      }
      // else {
      //   this.toastr.warning(`File "${file.name}" already exists.`, 'Warning' );
      // }
    }
  }
  onDragOver(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
    // this.highlightDropArea(true);
  }

  onDragLeave(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
    // this.highlightDropArea(false);
  }

  onDrop(file,event: DragEvent) {
    if(this.isView!==true){
      const files = event.dataTransfer.files;
    if (this.calculateTotalSize(files)) {
      this.showErrorForFileType = false;
      this.showErrorBasedOnMaxSize = false;
      this.showErrorForFileLength = false;
      this.arrSelectedDocuments = [];
      // this.filesForUpload = files;
      this.newFilesForUpload = [];
      this.mergeFileLists(files);
      this.chkdisableButton = true;
      this.selectedFileCount = files.length;
      this.totalSizeforTable = this.calculateTotalSizeValue(files);
      const totalFiles = files.length;
      let filesProcessed = 0;
    if (files && files.length > 0) {
      let fileResult: {
        fileName: string;
        fileType: string;
        fileSize: string;
        pageCount?: number;
      }[] = [];
      const fileList: File[] = [];
      for (let i = 0; i < files.length; i++) {
        if (files[i].type !== DocumentMaxandTypeLimits.documentTypeLimit) {
          this.showErrorForFileType = true;
          this.showErrorBasedOnMaxSize = false;
          this.showErrorForFileLength = false;
          this.arrSelectedDocuments = [];
          this.chkdisableButton = false;
          this.selectedFileCount = 0;
          return;
        } else{
          this.showErrorForFileType = false

          // this.highlightDropArea(false);
          const fileName: string = files[i].name;
          const m1fileName: string = fileName.replace('.pdf', '');
          const m2fileName: string = m1fileName.replace('.pdf', '');
         let exsit =  this.documentTypesArrayList.find(x=> x.documentType  == m2fileName)
         if(!exsit){
          this.pdfUpload(files)
          event.preventDefault();
          event.stopPropagation();
         } else{
          this.isLoaded = true
             this._snackBar.open(`File ${m2fileName} Already Exsit!"`, 'Close', {
              duration: 2000,
            });
         }

        }

      }

      this.arrSelectedDocuments = fileResult;
      this.disableMainFile = true;
    } else{
    }
  } else {
    this.showErrorForFileType = false;
    this.showErrorBasedOnMaxSize = true;
    this.showErrorForFileLength = false;
    this.arrSelectedDocuments = [];
    this.chkdisableButton = false;
    this.selectedFileCount = 0;
    // event.target.value = null;
    return;
  }
    }
  }

  // onFileSelected(files: File[]) {
  //   // const files = (event.target as HTMLInputElement).files;
  //   console.log(files)
  //   this.toFilesBase64(files, this.selectedFiles).subscribe((res: SelectedFiles[]) => {
  //     this.selectedFiles = res;
  //     console.log(this.selectedFiles)
  //   });

  //   // Handle file upload here
  // }



  highlightDropArea(isHighlighted: boolean) {
    const dropArea = this.fileInput.nativeElement.parentElement;
    if (isHighlighted) {
      dropArea.classList.add('highlight');
    } else {
      dropArea.classList.remove('highlight');
    }
  }

  openTimeLinePopUp(    ) {
    const config: MatDialogConfig = {
      disableClose: true,
      height: '70vh',
      width: '40vw',
      data: {
         orderId: this.orderId ,
         signatureId:defaultGuid
      },
    };
    const dialogRef = this.dialog.open(TimeLineComponent, config);

    const closeDialog = dialogRef.afterClosed().subscribe(
      () => {
        // this.getReminderTableData();
      },
      (err) => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      }
    );

}
receivedDate(Value){ ;
  if (
    (Value.length === undefined || Value.length >= 8) &&
    this.getFormattedDate(Value) !== 'Invalid Date' && Value!==''
  ) {
    this.helloSignForm.patchValue({
      txtexpiredStatus: 'Active',
    })
  }else{
    this.helloSignForm.patchValue({
      txtexpiredStatus: '',
    })
  }
}
getFormattedDate(date: any): string {
  return date
    ? new Date(date).toLocaleString('en-US', {
        month: '2-digit',
        day: '2-digit',
        year: 'numeric',
      })
    : '-';
}

addSlashToDate(event: Event): void {
  const input = event.target as HTMLInputElement;
  const inputValue = input.value;
  input.value = inputValue
    .replace(/^(\d{2})(\d{1,2})/, '$1/$2')
    .replace(/^(\d{2}\/\d{2})(\d+)/, '$1/$2')
    .replace(/[^\d\/]/g, '');
}

}
