import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
@Component({
  selector: 'app-leave-approval',
  templateUrl: './leave-approval.component.html',
  styleUrls: ['./leave-approval.component.scss']
})
export class LeaveApprovalComponent implements OnInit {
  isLoading: boolean;
  step: boolean = false;
  leaveApprovalForm: FormGroup;
  leaveApprovalSearchForm: FormGroup;
  BirthdateMaxDate: Date;
  BirthdateMinDate: Date;
  chosenYearDate: Date;
  dataSource: MatTableDataSource<any>;
  desigation: string;
  displayedColumns: string[] = [ 'Options','Name', 'Designation', 'LeaveType', 'FromDate', 'ToDate', 'Comments', 'Status',];

  constructor(
    private formBuilder: FormBuilder,
  ) { }

  ngOnInit(): void {

    this.leaveApprovalForm = this.formBuilder.group({
      txtDesingnation: new FormControl(),
      txtFromDate: new FormControl(),
      txtToDate: new FormControl(),
      txtEmployee: new FormControl(),
      rbtnApprovalStatus: new FormControl()
    })

    this.leaveApprovalSearchForm = this.formBuilder.group({
      name: new FormControl(""),
      designation: new FormControl(""),
      leaveType: new FormControl(""),
      fromDate: new FormControl(""),
      toDate: new FormControl(""),
      comments: new FormControl(""),
      status: new FormControl("")
    });
  }

  clearDesigation() {
    this.desigation = '';
    this.leaveApprovalForm.patchValue({ txtDesingnation: '' });
  }


}
