import { toArray } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import Swal from 'sweetalert2';
import { PatientsService } from '../patient-proxy/patient/patients.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-masterVerification_doVerification',
  templateUrl: './masterVerification_doVerification.component.html',
  styleUrls: ['./masterVerification_doVerification.component.scss']
})
export class MasterVerification_doVerificationComponent implements OnInit {
  panelOpenState = false;
  sLstPolicyId: any;
  activeTabId:number;
  iPolicyIdCount:number;
  isLoading:boolean=false;
  subscription$: Subscription[] = [];
  constructor(
    private activatedRoute: ActivatedRoute,
    private patientsService: PatientsService,
    private router: Router,
  ) { }

  ngOnInit() {
    const activatedRoute = this.activatedRoute.paramMap.subscribe(
      (response) => {
        this.activeTabId = parseInt(response.get("activeTabId"));
      },
      (err) => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: "info",
          text: data?.error?.error?.message,
        });
      }
    );
    this.subscription$.push(activatedRoute);
  this.getPolicyId();

  }
  getPolicyId(){
    this.isLoading=true;
    const PolicyIds = this.patientsService
    .getLockedPolicyIdByTenant(this.activeTabId)
    .subscribe(
      (response) => {
        console.log('getLockedPolicyIdByTenant response');
        console.log(response);
        this.sLstPolicyId=response;
        this.isLoading=false;
        this.iPolicyIdCount=this.sLstPolicyId.length;
        if(this.iPolicyIdCount ===0){
          this.router.navigate(['navigation/masterverification']);
        }
      },
      (err) => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      }
    );
  }


  getPolicyIdV1(verificationId){
    this.isLoading=true;
    const PolicyIds = this.patientsService
    .getLockedPolicyIdByTenant(this.activeTabId)
    .subscribe(
      (response) => {
        console.log('getLockedPolicyIdByTenant response');
        console.log(response);
        this.sLstPolicyId=response;
        const objWithIdIndex = this.sLstPolicyId.findIndex((obj) => obj.verificationId === verificationId);
        console.log('objWithIdIndex');
        console.log(objWithIdIndex);
        if(objWithIdIndex !== -1){
          this.sLstPolicyId.splice(objWithIdIndex, 1);
        }
        this.isLoading=false;
        this.iPolicyIdCount=this.sLstPolicyId.length;
        if(this.iPolicyIdCount ===0){
          this.router.navigate(['navigation/masterverification']);
        }
      },
      (err) => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      }
    );
  }
  toggle() {
    this.panelOpenState = true;
  }
  toggleV2() {
    this.panelOpenState = false;
  }
  loadPage(verificationId){
    // window.location.reload();
    console.log('verificationId');
    console.log(verificationId);
    console.log('this.sLstPolicyId');
    console.log(this.sLstPolicyId);


    const objWithIdIndex = this.sLstPolicyId.findIndex((obj) => obj.verificationId === verificationId);
    console.log('objWithIdIndex');
    console.log(objWithIdIndex);
    if(objWithIdIndex !== -1){
      this.sLstPolicyId.splice(objWithIdIndex, 1);
    }
    this.getPolicyId();
      console.log('this.sLstPolicyId 111111111111');
      console.log(this.sLstPolicyId);
      // if(this.sLstPolicyId.length ===0){
      //   this.router.navigate(['navigation/masterverification']);
      // }
  }
}
