


export class getPatient {
  static readonly type = '[PatientDTO] Get]';
  constructor(public id: string) { }
}

export class addPatient {
  static readonly type = '[PatientDTO] Add]';
  constructor(public readonly payload: any) { }
}
export class addPatientIntake {
  static readonly type = '[CreateUpdatePatientIntakeDTO] Add]';
  constructor(public readonly payload: any) { }
}
export class updatePatientIntake {
  static readonly type = '[CreateUpdatePatientIntakeDTO] update]';
  constructor(public id: string, public readonly payload: any) { }
}
export class addVerification {
  static readonly type = '[CreateUpdateInsuranceVerificationsDTO] Add]';
  constructor(public id: string, public readonly payload: any) { }
}
export class addAuthorization {
  static readonly type = '[CreateUpdateAuthorizationsDTO] Add]';
  constructor(public id: string, public readonly payload: any) { }
}

export class deletePatient {
  static readonly type = '[PatientDTO] Delete]';
  constructor(public id: string) { }
}
export class updatePatient {
  static readonly type = '[PatientDTO] Update]';
  constructor(public id: string, public readonly payload?: any) { }
}







