import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { DataTableDirective } from 'angular-datatables';
import { AddPatientNoteComponent } from 'projects/admin/src/app/add-patient-note/add-patient-note.component';
import { dtInboundOptions, inboundTableData } from 'projects/admin/src/app/admin-dashboard/dashboard-data';
// import { defaultGuid } from 'projects/admin/src/app/document-perviewer/document-perviewer.component';
import { InsuranceCheckListComponent } from 'projects/admin/src/app/insurance-check-list/insurance-check-list.component';
import { PrimaryInsComponent } from 'projects/admin/src/app/primary-ins/primary-ins.component';
import { ViewDocumentComponent } from 'projects/admin/src/app/view-document/view-document.component';
import { ViewNotesComponent } from 'projects/admin/src/app/view-notes/view-notes.component';
import { PatientSaleOrderService } from 'projects/order/src/app/order-proxy/order-management/order-optimization/patient-sale-order.service';
import { SaleOrderItemService } from 'projects/order/src/app/order-proxy/order-management/order-optimization/sale-order-item.service';
import { PatientManualVerificationTabComponent } from 'projects/patient/src/app/patient-manual-verification-tab/patient-manual-verification-tab.component';
import { defaultGuid } from '../../enums/allenums.enum';



@Component({
  selector: 'app-shared-view-patient-ledger',
  templateUrl: './shared-view-patient-ledger.component.html',
  styleUrls: ['./shared-view-patient-ledger.component.scss']
})

export class SharedViewPatientLedgerComponent implements OnInit {
  @Input() orderId: string = defaultGuid;
  @Input() patientId: string = defaultGuid;
  viewPatientledger: FormGroup;
  hcpcTableData: any = [];
  tblShpSelectedRowId: string;
  tblItemSelectedRowId: string;

  dtPLOptions: any = {
    responsive: true,
    scrollCollapse: true,
    scrollY: '300px',
    pageLength: 10,
    lengthMenu: [10, 25, 50, 100, 200],
    select: 'single',
    language: {
      select: {
        rows: {
          _: '', // This is for any number of rows selected
        },
      },
    },
  };
  isLoadedMain = false;
  isloadItmTbl = false;
  isloadHCPCTbl = false;
  inboundTableData = inboundTableData;
  dtInboundOptions = dtInboundOptions;

  completedOrderDetails:any;
  csrDetails:any;
  lastCompletedITemDetails:any;
  @ViewChild(DataTableDirective, { static: false })

  datatableElement: DataTableDirective;
  myTable: DataTables.Api;

  authTableData: any = [
    {
      authId: '54212',
      amount: '50.32',
    },
    {
      authId: '6585',
      amount: '12.32',
    },
    {
      authId: '98984',
      amount: '12.00',
    },
    // {
    //   authId: '87545',
    //   amount: '16.32',
    // },
    // {
    //   authId: '54212',
    //   amount: '50.32',
    // },
    // {
    //   authId: '875265',
    //   amount: '13.32',
    // },
    // {
    //   authId: '345435',
    //   amount: '20.00',
    // },
  ];


  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    public dialog: MatDialog,
    private PatientSaleOrderService:PatientSaleOrderService,
    public saleOrderItemService: SaleOrderItemService,
  ) { }

  ngOnInit(): void {
    this. viewPatientledger = this.formBuilder.group({
      txtCsr: new FormControl(""),
      txtPhone: new FormControl(""),
      txtEmail: new FormControl(""),
      txtNextCall: new FormControl(""),
      txtBilling: new FormControl(""),
      txtShipping: new FormControl(""),
    });

    this.viewPatientledger.patchValue({
      txtCsr: "Jack",
      txtPhone: "+16344583983",
      txtEmail: "jack@mm.com",
      txtNextCall: "NA",
      txtBilling:"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip",
      txtShipping:"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip",
    });


    this.loadTable();
  }
  ngAfterViewInit(): void {

  }

  loadTable(){
    this.isLoadedMain = false;
    this.isloadItmTbl = false;
    this.isloadHCPCTbl = false;
    this.PatientSaleOrderService
      .orderApp_patient_sale_orderget_order_details(
        // "f83d6bb0-c85a-940b-c993-3a0c522ac76a"
        this.patientId
      )
      .subscribe(
        (response) => {
          this.completedOrderDetails = response['completedOrderDetails'];
          this.lastCompletedITemDetails=response['lastCompletedITemDetails']
          this.csrDetails=response['csrDetails'];
          this.isLoadedMain = true;
          this.isloadItmTbl = true;
          this.isloadHCPCTbl = true;
        },
        (err) => {
          console.warn(err);
        }
      );

  }

  getHCPCCodes(id: string) {
    this.isloadHCPCTbl = false;
    this.hcpcTableData =
      this.hcpcTableData.length > 0 ? [] : this.hcpcTableData;

      setTimeout(() => {
        this.hcpcTableData = [
          { code: 'A4520' },
          { code: 'TD51245' },
          { code: 'AC54621' },
          { code: 'AC54621' },
          { code: 'AC54621' },
          { code: 'AC54621' },
        ];

        this.isloadHCPCTbl = true;
      }, 2000);
  }

  tblShpSelectedRow(id: string) {
    this.tblShpSelectedRowId = this.tblShpSelectedRowId === id ? '' : id;
    this.tblShpSelectedRowId != '' && this.getOrderItems(id);
  }
  getOrderItems(orderId: string) {
    // this.completedOrderDetails = [];
    this.hcpcTableData = [];
    this.isloadItmTbl = false;
    this.saleOrderItemService.getOrderItemsByOrderId(orderId).subscribe(
      (response) => {
        this.lastCompletedITemDetails = response;
        this.isloadItmTbl=true;
      },
      (err) => {}
    );
  }

  tblItemSelectedRow(id: string) {

    // this.tblItemSelectedRowId = this.tblItemSelectedRowId === id ? '' : id;

    // this.tblItemSelectedRowId != '' && this.getHCPCCodes(id);
    this.getHCPCCodes(id)
  }

  //Row Click Event UnProcessed Table
  handleTableCellClick(e: Event, notesData: any): void {
    const target = e.target as HTMLElement;
    const tr = target.closest('tr');
    this.getDataTableData(tr, notesData);
  }
    //DataTable Data Get Method and Toggle Notes Row in UnProcessed Table
    async getDataTableData(tr: any, notesData: any): Promise<void> {
      this.myTable = await this.datatableElement.dtInstance;
      if (tr) {
        const row = this.myTable.row(tr);

        if (row.child.isShown()) {
          // This row is already open - close it
          row.child.hide();
        } else {
          // Open this row
          row.child(this.format(row.data(), notesData)).show();
        }
      }
    }
    format(d: any, notesData: any) {
      let returnString: string = '';
      notesData.forEach(function (value) {
        returnString += '<tr class="border-bottom"><td>' + value + '</td></tr>';
      });
      return returnString;
      // (
      //   '<tr class="border-bottom"><td>' +
      //   'There is a language course organised at the training centre. Your unit can send one soldier to attend this course. You have been tasked with giving a briefing presenting two possible candidates' +
      //   '</td></tr>' +
      //   '<tr class="border-bottom"><td>' +
      //   'Let me start with Candidate A and his strengths. Captain Nowak is a very experienced officer who has participated in a few missions abroad and cooperated with our foreign allies. Therefore, he has plenty of practical experience in real life communication in English and working in this language' +
      //   '</td></tr>'
      // );
    }

    primaryIns(){
      const dialogRef = this.dialog.open(PrimaryInsComponent, {
        height: "65%", width: "70%",
        disableClose: true,


      });
      const closeModal = dialogRef.afterClosed().subscribe(() => {

      });
    }
    viewNotes(){
      const dialogRef = this.dialog.open(ViewNotesComponent, {
        height: "80%", width: "80%",
        // disableClose: true,


      });
      const closeModal = dialogRef.afterClosed().subscribe(() => {

      });

    }
    addNotes(){
      const dialogRef = this.dialog.open(AddPatientNoteComponent, {
        height: "70%", width: "70%",
        disableClose: true,


      });
      const closeModal = dialogRef.afterClosed().subscribe(() => {

      });

    }

    viewDocument(){
      this.dialog.open(ViewDocumentComponent, {
        height: "75%", width: "80%",

        disableClose: true,
      });
      this.dialog.afterAllClosed.subscribe((resposne) => {});
    }
    checkListModel(){
    //   const dialogRef = this.dialog.open(DocumentCheckListComponent, {
    //     height: "75%", width: "80%",
    //     disableClose: true,


    //   });
    //   const closeModal = dialogRef.afterClosed().subscribe(() => {

    //   });
    // }

    const dialogRef = this.dialog.open(InsuranceCheckListComponent, {
      height: "75%", width: "80%",
      disableClose: true,


    });
    const closeModal = dialogRef.afterClosed().subscribe(() => {

    });
  }
  openVerificationModal() {
    const dialogRef = this.dialog.open(PatientManualVerificationTabComponent, {
      disableClose: true,
      // data: { patientId: this.patientId, policyId: this.policyId },
    });
    const closeModal = dialogRef.afterClosed().subscribe(() => {

    });
  }

}
