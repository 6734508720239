<meta http-equiv="Cache-control" content="no-cache" />
<div class="container-fluid">
    <div class="row align-items-center">
            <div class="col-lg-10">
                <h3 class="customThemeClass">
                    <b>View Document Image <span *ngIf="defaultFaxId !== '-' "><b>- {{ defaultFaxId }}</b> </span></b>
                </h3>
            </div>
            

            <div class="col-lg-2" style="text-align: right">
                <!-- <mat-icon class="pointer" (click)="closeModel()">close</mat-icon> -->
                <button mat-button class="buttonColor" (click)="closeModel()" >Select / Close</button>

            </div>

        <!-- </div> -->

        <div class="col-12 mt-1">
            <mat-divider></mat-divider>
        </div>
    </div>
    
    <div class="row bg mt-2">
        <div class="col-lg-12">
            <div class="d-flex align-items-center justify-content-between">
                <h3 class="mb-0 font-size-18">
                    <b>Sorting / Sorted Documents</b>
                </h3>
                <h3 class="mb-0 font-size-18">
                    <b>Chart Id:</b>&nbsp;<b class="text-danger">{{
                        chartId ? chartId : '-'
                        }}</b>
                </h3>
                <!-- <h3 class="mb-0 font-size-18">
                    <b>Patient Id:</b>&nbsp;<b class="text-danger">{{
                        defaultPatientId ? defaultPatientId : '-'
                        }}</b>
                </h3> -->
                <h3 class="mb-0 font-size-18">
                    <b>Patient Name:</b>&nbsp;<b class="text-danger">{{ patientName ? patientName : '-' }}</b>
                </h3>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-lg-12">
            <!-- <app-common-sorting (headerText)="BindHearderText($event)" [docId]="data.docId" *ngIf="isLoading"></app-common-sorting> -->
            <app-common-sorting-view (emitPageNo)="LoadSelectedImage($event)" (headerText)="BindHearderText($event)" [flag]="data.flag" [medicareId]="data.medicareId" [isMedicare]="data.isMedicare" [docId]="data.docId" *ngIf="isLoading" [previewList]="data.previewList"></app-common-sorting-view>
        </div>
        <!-- <div class="col-lg-2">
            
          </div> -->
    </div>
    

    
</div>