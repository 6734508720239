<div class="container-fluid">
  <!-- Headers -->
  <div class="row">
    <div class="col-12 d-flex justify-content-between">
      <h3 class="font-weight-bolder mb-2 mt-2">
        <b class="customThemeClass">List / Billing Dept / Billing List</b>
      </h3>
      <div class="page-title-box page-title-right mt-3">
        <ol class="breadcrumbs m-0 align-items-center">
          <!-- <form [formGroup]="BillingTypeForm">
            <mat-form-field>
              <mat-label>Types</mat-label>
              <mat-select
                formControlName="drpTypes"
                (selectionChange)="onSelectTypes($event)"
              >
                <mat-option [value]="1">New Setup </mat-option>
                <mat-option [value]="1">New Setup Pending </mat-option>
                <mat-option [value]="0">Supplies </mat-option>
                <mat-option [value]="0">Supplies Pending </mat-option>
              </mat-select>
            </mat-form-field>
          </form> -->
        </ol>
      </div>
    </div>
  </div>

  <div class="card">
    <div class="row">
      <div class="col-xl-8">
        <ul
          class="card card-body"
          ngbNav
          #nav="ngbNav"
          justify="justified"
          class="alert-success nav nav-pills nav-fill"
        >
          <!-- New Setup -->
          <li
            ngbNavItem="NewSetup"
            [class.selected-tab]="nav.activeId === 'NewSetup'"
          >
            <a ngbNavLink (click)="changeTab('NewSetup', 1, false)"
              ><b class="small-text">New Setup</b></a
            >
          </li>
          <!-- New Setup Pending -->
          <li
            ngbNavItem="NewSetupPending"
            [class.selected-tab]="nav.activeId === 'NewSetupPending'"
          >
            <a ngbNavLink (click)="changeTab('NewSetupPending', 1, true)"
              ><b class="small-text">New Setup Pending</b></a
            >
          </li>
          <!-- Supplies -->
          <li
            ngbNavItem="Supplies"
            [class.selected-tab]="nav.activeId === 'Supplies'"
          >
            <a ngbNavLink (click)="changeTab('Supplies', 0, false)"
              ><b class="small-text">Supplies</b></a
            >
          </li>
          <!-- Supplies Pending -->
          <li
            ngbNavItem="SuppliesPending"
            [class.selected-tab]="nav.activeId === 'SuppliesPending'"
          >
            <a ngbNavLink (click)="changeTab('SuppliesPending', 0, true)"
              ><b class="small-text">Supplies Pending</b></a
            >
          </li>
        </ul>
      </div>
      <div class="col-xl-4">
        <ul
          class="card card-body"
          ngbNav
          #nav="ngbNav"
          justify="justified"
          class="alert-success nav nav-pills nav-fill"
          style="justify-content: end; padding-right: 23px"
        >
          <li></li>
        </ul>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="card-body" style="border-top: 3px solid rgb(201, 201, 201)">
          <!-- New Setup -->
          <div *ngIf="activeTab === 'NewSetup'">
            <div class="d-flex justify-content-center" *ngIf="!isDataLoaded">
              <img
                class="img-responsive pb-2 mr-1"
                src="assets/gifs/Infinity-loader.gif"
              />
            </div>
            <app-billing-list-page-details
              *ngIf="isDataLoaded"
              [ticketDataArray]="billingOrders"
              [activeTab]="activeTab"
            ></app-billing-list-page-details>
          </div>
          <!-- New Setup Pending -->
          <div *ngIf="activeTab === 'NewSetupPending'">
            <div class="d-flex justify-content-center" *ngIf="!isDataLoaded">
              <img
                class="img-responsive pb-2 mr-1"
                src="assets/gifs/Infinity-loader.gif"
              />
            </div>
            <app-billing-list-page-details
              *ngIf="isDataLoaded"
              [ticketDataArray]="billingOrders"
              [activeTab]="activeTab"
            ></app-billing-list-page-details>
          </div>
          <!-- Supplies -->
          <div *ngIf="activeTab === 'Supplies'">
            <div class="d-flex justify-content-center" *ngIf="!isDataLoaded">
              <img
                class="img-responsive pb-2 mr-1"
                src="assets/gifs/Infinity-loader.gif"
              />
            </div>
            <app-billing-list-page-details
              *ngIf="isDataLoaded"
              [ticketDataArray]="billingOrders"
              [activeTab]="activeTab"
            ></app-billing-list-page-details>
          </div>
          <!-- Supplies Pending -->
          <div *ngIf="activeTab === 'SuppliesPending'">
            <div class="d-flex justify-content-center" *ngIf="!isDataLoaded">
              <img
                class="img-responsive pb-2 mr-1"
                src="assets/gifs/Infinity-loader.gif"
              />
            </div>
            <app-billing-list-page-details
              *ngIf="isDataLoaded"
              [ticketDataArray]="billingOrders"
              [activeTab]="activeTab"
            ></app-billing-list-page-details>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
