import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { IdentityUserUsingTenantIdDto } from 'projects/admin/src/app/admin-proxy/platform-app-management/rcm/platform-management/user/models';
import { UserService } from 'projects/admin/src/app/admin-proxy/platform-app-management/rcm/platform-management/user/user.service';
import { CoverageAreaTypeDTO, OnTimeMasterDropdownDTO, WorkingHoursDTO, WorkingWeeksDTO } from 'projects/patient/src/app/dropdown-proxy/dropdown-management/dropdowns/dto/on-time-schedule/models';
import { OnTimeMasterDropdownService } from 'projects/patient/src/app/dropdown-proxy/dropdown-management/dropdowns/master-dropdown-service/on-time-master-dropdown.service';
import { OnTimeDropdowns } from 'projects/patient/src/app/dropdown-proxy/dropdown-management/dropdowns/on-time-dropdowns.enum';
import { TableService } from 'projects/shared/src/app/table.service';
import Swal from 'sweetalert2';
import { ToastrService } from "ngx-toastr";
import { CreateUpdatePickupWorkingHoursDTO, GetOfficesDTO } from '../onTime-proxy/rcm/on-time-management/on-time-schedule/dto/models';
import { PickupWorkingHoursService } from '../onTime-proxy/rcm/on-time-management/on-time-schedule/pickup-working-hours.service';
import { WeekDays } from '../onTime-proxy/rcm/on-time-management/on-time-schedule/week-days.enum';

@Component({
  selector: 'app-pickup-creation',
  templateUrl: './pickup-creation.component.html',
  styleUrls: ['./pickup-creation.component.scss']
})
export class PickupCreationComponent implements OnInit {
  status: boolean = false;
  sundayLeaveHide: boolean;
  mondayLeaveHide: boolean;
  tuesdayLeaveHide: boolean;
  wednesdayLeaveHide: boolean;
  thursdayLeaveHide: boolean;
  fridayLeaveHide: boolean;
  saturdayLeaveHide: boolean;
  AddWorkForm: FormGroup;
  drpCoverage: CoverageAreaTypeDTO[];
  drpWorkOptn: WorkingWeeksDTO[];
  drpStartAt: any[] = [];
  tenanatId: string = "";
  activeStatus: string = "";

  constructor(public notesDialogRef: MatDialogRef<PickupCreationComponent>,
    private onTimeDropdown: OnTimeMasterDropdownService,
    @Inject(MAT_DIALOG_DATA) public data: { pickupCreationId: string, pickupDetails: string },
    private formBuilder: FormBuilder,
    private tableService: TableService,
    private userService: UserService,
    private toastr: ToastrService,
    private onTimeService: PickupWorkingHoursService
  ) { }
  sunday: string = "Leave";
  Monday: string = "Leave";
  Tuesday: string = "Leave";
  Wednesday: string = "Leave";
  Thursday: string = "Leave";
  Friday: string = "Leave";
  Saturday: string = "Leave";
  covShow: boolean = false;
  workShow: boolean = false;
  atShow: boolean = false;
  endShow: boolean = false;
  SelShow: boolean = false;
  workingHrsId: string = "";
  ngOnInit(): void {
    this.tableService.getTenantId().subscribe(value => {
      this.tenanatId = value;
    })
    this.AddWorkForm = this.formBuilder.group({
      acceptTerms: new FormControl(""),
      mondayWorkStatus: new FormControl(""),
      saturdayWorkStatus: new FormControl(""),
      tuesdayWorkStatus: new FormControl(""),
      wednesWorkStatus: new FormControl(""),
      thursWorkStatus: new FormControl(""),
      fridayWorkStatus: new FormControl(""),
      drpRespiratory: new FormControl("", [Validators.required]),
      statusToggle: new FormControl(""),
      drpWorkOptn: new FormControl(""),
      drpStartAt: new FormControl(""),
      drpStartEnd: new FormControl(""),
      drpSelCov: new FormControl(""),
      drpMonWork: new FormControl(""),
      drpMonStartAt: new FormControl(""),
      drpMonStartEnd: new FormControl(""),
      drpMonSelCov: new FormControl(""),
      drpTueWork: new FormControl(""),
      drpTueStartAt: new FormControl(""),
      drpTueStartEnd: new FormControl(""),
      drpTueSelCov: new FormControl(""),
      drpWedWork: new FormControl(""),
      drpWedStartAt: new FormControl(""),
      drpWedStartEnd: new FormControl(""),
      drpWedSelCov: new FormControl(""),
      drpThursWork: new FormControl(""),
      drpThursStartAt: new FormControl(""),
      drpThursStartEnd: new FormControl(""),
      drpThursSelCov: new FormControl(""),
      drpFriWork: new FormControl(""),
      drpFriStartAt: new FormControl(""),
      drpFriStartEnd: new FormControl(""),
      drpFriSelCov: new FormControl(""),
      drpSatWork: new FormControl(""),
      drpSatStartAt: new FormControl(""),
      drpSatStartEnd: new FormControl(""),
      drpSatSelCov: new FormControl(""),

    });
    this.getDropDown();
  }

  /// dropdown list
  coverage: string = "";
  drpStartEnd: WorkingHoursDTO[];
  userTableData: any[] = [];
  getDropDown() {
    let onTimeDropdownInput: OnTimeDropdowns[];
    onTimeDropdownInput = [
      OnTimeDropdowns.CoverageAreaType,
      OnTimeDropdowns.WorkingWeeks,
      OnTimeDropdowns.WorkingHoursStart,
    ]
    this.onTimeDropdown.getOnTimeDropdownsByInput(onTimeDropdownInput).subscribe((stateResponse) => {
      const response: OnTimeMasterDropdownDTO = stateResponse;
      this.drpCoverage = response?.coverageAreaTypes;
      this.drpWorkOptn = response?.workingWeeks;
      // this.drpStartAt = response?.workingHoursStarts;
    }, err => {
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    });
    this.onTimeDropdown.getWorkTimings().subscribe((stateResponse) => {
      this.drpStartAt = stateResponse.workingTimes
    });

    const filters: IdentityUserUsingTenantIdDto = {
      tenantId: this.tenanatId
    }
    this.userService.getUserList(filters).subscribe(response => {
      response && response?.forEach(element => {
        if (element?.roleName === "Respiratory Therapist") {
          this.userTableData.push({
            name: element?.userName,
            email: element?.email,
            role: element?.roleName,
            id: element?.id,
          });
        }
      })
    }, err => {
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    })
    setTimeout(() => {
      if (this.data.pickupCreationId != "" || this.data.pickupCreationId != null) {
        this.getAddHoursById()
      }
    }, 1000)
  }

  // ! save functionlity
  getWorkingSave() {

    let AddWorkingHours: CreateUpdatePickupWorkingHoursDTO;
    AddWorkingHours = {
      respiratoryTherapistName: this.AddWorkForm.value.drpRespiratory,
      pickupSundayWorkingHours: {
        isWorking: this.AddWorkForm.value.acceptTerms === true ? 1 : 0,
        pickupWorkDetails: {
          weekOption: this.AddWorkForm.value.drpWorkOptn == "" ? [] : this.AddWorkForm.value.drpWorkOptn,
          startsAt: this.AddWorkForm.value.drpStartAt.startTimeShortCodeId === undefined ? "" : this.AddWorkForm.value.drpStartAt.startTimeShortCodeId,
          endsAt: this.AddWorkForm.value.drpStartEnd,
          officeName: this.AddWorkForm.value.drpSelCov,
        }
      },
      pickupMondayWorkingHours: {
        isWorking: this.AddWorkForm.value.mondayWorkStatus === true ? 1 : 0,
        pickupWorkDetails: {
          weekOption: this.AddWorkForm.value.drpMonWork == "" ? [] : this.AddWorkForm.value.drpMonWork,
          startsAt: this.AddWorkForm.value.drpMonStartAt?.startTimeShortCodeId === undefined ? "" : this.AddWorkForm.value.drpMonStartAt.startTimeShortCodeId,
          endsAt: this.AddWorkForm.value.drpMonStartEnd,
          officeName: this.AddWorkForm.value.drpMonSelCov,
        }
      },
      pickupTuesdayWorkingHours: {
        isWorking: this.AddWorkForm.value.tuesdayWorkStatus === true ? 1 : 0,
        pickupWorkDetails: {
          weekOption: this.AddWorkForm.value.drpTueWork == "" ? [] : this.AddWorkForm.value.drpTueWork,
          startsAt: this.AddWorkForm.value.drpTueStartAt?.startTimeShortCodeId === undefined ? "" : this.AddWorkForm.value.drpTueStartAt.startTimeShortCodeId,
          endsAt: this.AddWorkForm.value.drpTueStartEnd,
          officeName: this.AddWorkForm.value.drpTueSelCov,
        }
      },
      pickupWednesdayWorkingHours: {
        isWorking: this.AddWorkForm.value.wednesWorkStatus === true ? 1 : 0,
        pickupWorkDetails: {
          weekOption: this.AddWorkForm.value.drpWedWork == "" ? [] : this.AddWorkForm.value.drpWedWork,
          startsAt: this.AddWorkForm.value.drpWedStartAt?.startTimeShortCodeId === undefined ? "" : this.AddWorkForm.value.drpWedStartAt.startTimeShortCodeId,
          endsAt: this.AddWorkForm.value.drpWedStartEnd,
          officeName: this.AddWorkForm.value.drpWedSelCov,
        }
      },
      pickupThursdayWorkingHours: {
        isWorking: this.AddWorkForm.value.thursWorkStatus === true ? 1 : 0,
        pickupWorkDetails: {
          weekOption: this.AddWorkForm.value.drpThursWork == "" ? [] : this.AddWorkForm.value.drpThursWork,
          startsAt: this.AddWorkForm.value.drpThursStartAt?.startTimeShortCodeId === undefined ? "" : this.AddWorkForm.value.drpThursStartAt.startTimeShortCodeId,
          endsAt: this.AddWorkForm.value.drpThursStartEnd,
          officeName: this.AddWorkForm.value.drpThursSelCov,
        }
      },
      pickupFridayWorkingHours: {
        isWorking: this.AddWorkForm.value.fridayWorkStatus === true ? 1 : 0,
        pickupWorkDetails: {
          weekOption: this.AddWorkForm.value.drpFriWork == "" ? [] : this.AddWorkForm.value.drpFriWork,
          startsAt: this.AddWorkForm.value.drpFriStartAt?.startTimeShortCodeId === undefined ? "" : this.AddWorkForm.value.drpFriStartAt.startTimeShortCodeId,
          endsAt: this.AddWorkForm.value.drpFriStartEnd,
          officeName: this.AddWorkForm.value.drpFriSelCov,
        }
      },
      pickupSaturdayWorkingHours: {
        isWorking: this.AddWorkForm.value.saturdayWorkStatus === true ? 1 : 0,
        pickupWorkDetails: {
          weekOption: this.AddWorkForm.value.drpSatWork == "" ? [] : this.AddWorkForm.value.drpSatWork,
          startsAt: this.AddWorkForm.value.drpSatStartAt?.startTimeShortCodeId === undefined ? "" : this.AddWorkForm.value.drpSatStartAt.startTimeShortCodeId,
          endsAt: this.AddWorkForm.value.drpSatStartEnd,
          officeName: this.AddWorkForm.value.drpSatSelCov,
        }
      },
      status: this.AddWorkForm.value.statusToggle === true ? "Active" : "Inactive",
      pickupDetails: this.data?.pickupDetails
    }
    if (this.data.pickupCreationId === "" || this.data.pickupCreationId === null || this.data.pickupCreationId === undefined) {
      this.onTimeService.create(AddWorkingHours).subscribe(response => {
        // Swal.fire({ title: 'Success', html: 'Saved Successfully', icon: 'success', timer: 3000, timerProgressBar: true });
        this.toastr.success('Saved Successfully','Success')
        this.workingHrsId = response.id
        this.notesDialogRef.close();
      }, err => {
        const data: HttpErrorResponse = err;
        // Swal.fire({
        //   icon: 'info',
        //   html: data?.error?.error?.message,
        // })
        this.toastr.info(data?.error?.error?.message)
      });
    }
    else {
      this.onTimeService.update(this.data.pickupCreationId, AddWorkingHours).subscribe(response => {
        // this.patientsId = response.id
        // Swal.fire({ title: 'Success', html: 'Saved Successfully', icon: 'success', timer: 3000, timerProgressBar: true });
        this.toastr.success('Saved Successfully','Success')
        this.notesDialogRef.close();
      }, err => {
        const data: HttpErrorResponse = err;
        // Swal.fire({
        //   icon: 'info',
        //   html: data?.error?.error?.message,
        // })
        this.toastr.info(data?.error?.error?.message)
      });
    }
  }


  // get Add Hours byid
  getAddHoursById() {
    this.onTimeService.get(this.data.pickupCreationId).subscribe(response => {
      this.covShow = true;
      this.workShow = true;
      this.atShow = true;
      this.endShow = true;
      this.SelShow = true;
      this.activeStatus = response.status
      this.status = true
      const sunStart = this.drpStartAt?.filter(value => {
        return value && value?.startTimeShortCodeId === response?.pickupSundayWorkingHours?.pickupWorkDetails?.startsAt
      })[0];
      const monStart = this.drpStartAt?.filter(value => {
        return value && value?.startTimeShortCodeId === response?.pickupMondayWorkingHours?.pickupWorkDetails?.startsAt
      })[0];
      const tueStart = this.drpStartAt?.filter(value => {
        return value && value?.startTimeShortCodeId === response?.pickupTuesdayWorkingHours?.pickupWorkDetails?.startsAt
      })[0];
      const wedStart = this.drpStartAt?.filter(value => {
        return value && value?.startTimeShortCodeId === response?.pickupWednesdayWorkingHours?.pickupWorkDetails?.startsAt
      })[0];
      const thuStart = this.drpStartAt?.filter(value => {
        return value && value?.startTimeShortCodeId === response?.pickupThursdayWorkingHours?.pickupWorkDetails?.startsAt
      })[0];
      const friStart = this.drpStartAt?.filter(value => {
        return value && value?.startTimeShortCodeId === response?.pickupFridayWorkingHours?.pickupWorkDetails?.startsAt
      })[0];
      const satStart = this.drpStartAt?.filter(value => {
        return value && value?.startTimeShortCodeId === response?.pickupSaturdayWorkingHours?.pickupWorkDetails?.startsAt
      })[0];


      // sunday
      if (response?.pickupSundayWorkingHours?.isWorking == 0) {
        this.sunday = "Leave";
        this.sundayLeaveHide = false;
        this.AddWorkForm.patchValue({
          drpWorkOptn: "",
          drpStartAt: "",
          drpStartEnd: "",
          drpSelCov: ""
        })
      }
      if (response?.pickupSundayWorkingHours?.isWorking == 1) {
        this.sunday = "Working";
        this.sundayLeaveHide = true;
      }
      // monday
      if (response?.pickupMondayWorkingHours?.isWorking == 0) {
        this.Monday = "Leave"
        this.mondayLeaveHide = false;
        this.AddWorkForm.patchValue({
          drpMonWork: "",
          drpMonStartAt: "",
          drpMonStartEnd: "",
          drpMonSelCov: ""
        })
      }
      if (response?.pickupMondayWorkingHours?.isWorking == 1) {
        this.Monday = "Working"
        this.mondayLeaveHide = true;

      }

      // Tuesday
      if (response?.pickupTuesdayWorkingHours?.isWorking == 0) {
        this.Tuesday = "Leave"
        this.tuesdayLeaveHide = false;
        this.AddWorkForm.patchValue({
          drpTueWork: "",
          drpTueStartAt: "",
          drpTueStartEnd: "",
          drpTueSelCov: ""
        })
      }
      if (response?.pickupTuesdayWorkingHours?.isWorking == 1) {
        this.Tuesday = "Working"
        this.tuesdayLeaveHide = true;

      }

      // Wednesday
      if (response?.pickupWednesdayWorkingHours?.isWorking == 0) {
        this.Wednesday = "Leave"
        this.wednesdayLeaveHide = false;
        this.AddWorkForm.patchValue({
          drpWedWork: "",
          drpWedStartAt: "",
          drpWedStartEnd: "",
          drpWedSelCov: ""
        })
      }
      if (response?.pickupWednesdayWorkingHours?.isWorking == 1) {
        this.Wednesday = "Working"
        this.wednesdayLeaveHide = true;

      }

      // Thursday
      if (response?.pickupThursdayWorkingHours?.isWorking == 0) {
        this.Thursday = "Leave"
        this.thursdayLeaveHide = false;
        this.AddWorkForm.patchValue({
          drpThursWork: "",
          drpThursStartAt: "",
          drpThursStartEnd: "",
          drpThursSelCov: ""
        })
      }
      if (response?.pickupThursdayWorkingHours?.isWorking == 1) {
        this.Thursday = "Working"
        this.thursdayLeaveHide = true;

      }

      // Friday
      if (response?.pickupFridayWorkingHours?.isWorking == 0) {
        this.Friday = "Leave"
        this.fridayLeaveHide = false;
        this.AddWorkForm.patchValue({
          drpFriWork: "",
          drpFriStartAt: "",
          drpFriStartEnd: "",
          drpFriSelCov: ""
        })
      }
      if (response?.pickupFridayWorkingHours?.isWorking == 1) {
        this.Friday = "Working"
        this.fridayLeaveHide = true;

      }

      // saturday
      if (response?.pickupSaturdayWorkingHours?.isWorking == 0) {
        this.Saturday = "Leave"
        this.saturdayLeaveHide = false;
        this.AddWorkForm.patchValue({
          drpSatWork: "",
          drpSatStartAt: "",
          drpSatStartEnd: "",
          drpSatSelCov: ""
        })
      }
      if (response?.pickupSaturdayWorkingHours?.isWorking == 1) {
        this.Saturday = "Working"
        this.saturdayLeaveHide = true;

      }


      sunStart != undefined && this.onSunEndsChanges(response?.pickupSundayWorkingHours?.pickupWorkDetails?.weekOption, sunStart, response?.pickupSundayWorkingHours?.pickupWorkDetails?.endsAt)
      monStart != undefined && this.onMonEndsChanges(response?.pickupMondayWorkingHours?.pickupWorkDetails?.weekOption, monStart, response?.pickupMondayWorkingHours?.pickupWorkDetails?.endsAt);

      tueStart != undefined && this.onTueEndsChanges(response?.pickupTuesdayWorkingHours?.pickupWorkDetails?.weekOption, tueStart, response?.pickupTuesdayWorkingHours?.pickupWorkDetails?.endsAt);
      wedStart != undefined && this.onWedEndsChanges(response?.pickupWednesdayWorkingHours?.pickupWorkDetails?.weekOption, wedStart, response?.pickupWednesdayWorkingHours?.pickupWorkDetails?.endsAt);

      thuStart != undefined && this.onThuEndsChanges(response?.pickupThursdayWorkingHours?.pickupWorkDetails?.weekOption, thuStart, response?.pickupThursdayWorkingHours?.pickupWorkDetails?.endsAt);
      friStart != undefined && this.onFriEndsChanges(response?.pickupFridayWorkingHours?.pickupWorkDetails?.weekOption, friStart, response?.pickupFridayWorkingHours?.pickupWorkDetails?.endsAt);

      satStart != undefined && this.onSatEndsChanges(response?.pickupSaturdayWorkingHours?.pickupWorkDetails?.weekOption, satStart, response?.pickupSaturdayWorkingHours?.pickupWorkDetails?.endsAt);


      this.AddWorkForm.patchValue({

        acceptTerms: response?.pickupSundayWorkingHours?.isWorking,
        mondayWorkStatus: response?.pickupMondayWorkingHours?.isWorking,
        saturdayWorkStatus: response?.pickupSaturdayWorkingHours?.isWorking,
        tuesdayWorkStatus: response?.pickupTuesdayWorkingHours?.isWorking,
        wednesWorkStatus: response?.pickupWednesdayWorkingHours?.isWorking,
        thursWorkStatus: response?.pickupThursdayWorkingHours?.isWorking,
        fridayWorkStatus: response?.pickupFridayWorkingHours?.isWorking,
        drpRespiratory: response?.respiratoryTherapistName,
        statusToggle: response?.status,
        drpWorkOptn: response?.pickupSundayWorkingHours?.pickupWorkDetails?.weekOption,
        drpStartAt: sunStart,
        drpStartEnd: response?.pickupSundayWorkingHours?.pickupWorkDetails?.endsAt,

        drpSelCov: response?.pickupSundayWorkingHours?.pickupWorkDetails?.officeName,
        drpMonWork: response?.pickupMondayWorkingHours?.pickupWorkDetails?.weekOption,
        drpMonStartAt: monStart,
        drpMonStartEnd: response?.pickupMondayWorkingHours?.pickupWorkDetails?.endsAt,
        drpMonSelCov: response?.pickupMondayWorkingHours?.pickupWorkDetails?.officeName,
        drpTueWork: response?.pickupTuesdayWorkingHours?.pickupWorkDetails?.weekOption,
        drpTueStartAt: tueStart,
        drpTueStartEnd: response?.pickupTuesdayWorkingHours?.pickupWorkDetails?.endsAt,
        drpTueSelCov: response?.pickupTuesdayWorkingHours?.pickupWorkDetails?.officeName,
        drpWedWork: response?.pickupWednesdayWorkingHours?.pickupWorkDetails?.weekOption,
        drpWedStartAt: wedStart,
        drpWedStartEnd: response?.pickupWednesdayWorkingHours?.pickupWorkDetails?.endsAt,
        drpWedSelCov: response?.pickupWednesdayWorkingHours?.pickupWorkDetails?.officeName,
        drpThursWork: response?.pickupThursdayWorkingHours?.pickupWorkDetails?.weekOption,
        drpThursStartAt: thuStart,
        drpThursStartEnd: response?.pickupThursdayWorkingHours?.pickupWorkDetails?.endsAt,
        drpThursSelCov: response?.pickupThursdayWorkingHours?.pickupWorkDetails?.officeName,
        drpFriWork: response?.pickupFridayWorkingHours?.pickupWorkDetails?.weekOption,
        drpFriStartAt: friStart,
        drpFriStartEnd: response?.pickupFridayWorkingHours?.pickupWorkDetails?.endsAt,
        drpFriSelCov: response?.pickupFridayWorkingHours?.pickupWorkDetails?.officeName,
        drpSatWork: response?.pickupSaturdayWorkingHours?.pickupWorkDetails?.weekOption,
        drpSatStartAt: satStart,
        drpSatStartEnd: response?.pickupSaturdayWorkingHours?.pickupWorkDetails?.endsAt,
        drpSatSelCov: response?.pickupSaturdayWorkingHours?.pickupWorkDetails?.officeName,
      })
   })


  }

  sundayStatus(event: MatSlideToggleChange) {

    if (event.checked == true) {
      this.activeStatus = "Active"
      // this.getenablefields();
    }
    if (event.checked === false) {
      Swal.fire({
        title: 'Are you sure you want to Inactive?',
        // text: 'You won\'t be able to retrieve this data!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#34c38f',
        cancelButtonColor: '#f46a6a',
        confirmButtonText: 'Yes!'
      }).then(result => {
        if (result.isConfirmed === true) {
          this.AddWorkForm.patchValue({
            statusToggle: false
          })
          this.activeStatus = "Inactive"
          // this.getdisablefields();
        }
        if (result.isConfirmed === false) {
          this.AddWorkForm.patchValue({
            statusToggle: true
          })
          // this.getenablefields();
        }
      });
    }
  }
  // ! sunday status
  sundaysStatus(status) {
    if (this.AddWorkForm.value.acceptTerms == true) {
      this.sunday = "Leave"
      this.sundayLeaveHide = false;
      this.AddWorkForm.patchValue({
        drpWorkOptn: "",
        drpStartAt: "",
        drpStartEnd: "",
        drpSelCov: "",
      })
    }
    if (this.AddWorkForm.value.acceptTerms == false) {
      this.covShow = true
      this.sunday = "Working";
      this.sundayLeaveHide = true;
    }
  }

  // }
  // !Monday status
  MondayStatus(status) {
    if (this.AddWorkForm.value.mondayWorkStatus == true) {
      this.Monday = "Leave"
      this.mondayLeaveHide = false;
    }
    if (this.AddWorkForm.value.mondayWorkStatus == false) {
      this.Monday = "Working"
      this.mondayLeaveHide = true;
    }
  }

  // ! Tuesday status
  tuesdayStatus(status) {
    if (this.AddWorkForm.value.tuesdayWorkStatus == true) {
      this.Tuesday = "Leave"
      this.tuesdayLeaveHide = false;
    }
    if (this.AddWorkForm.value.tuesdayWorkStatus == false) {
      this.Tuesday = "Working"
      this.tuesdayLeaveHide = true;
    }
  }
  // ! thurs status
  thursStatus(status) {
    if (this.AddWorkForm.value.thursWorkStatus == true) {
      this.Thursday = "Leave"
      this.thursdayLeaveHide = false;
    }
    if (this.AddWorkForm.value.thursWorkStatus == false) {
      this.Thursday = "Working"
      this.thursdayLeaveHide = true;
    }
  }

  // ! wednes status
  wednesStatus(status) {
    if (this.AddWorkForm.value.wednesWorkStatus == true) {
      this.Wednesday = "Leave"
      this.wednesdayLeaveHide = false;
    }
    if (this.AddWorkForm.value.wednesWorkStatus == false) {
      this.Wednesday = "Working"
      this.wednesdayLeaveHide = true;
    }
  }

  // ! Friday status
  fridayStatus(status) {
    if (this.AddWorkForm.value.fridayWorkStatus == true) {
      this.Friday = "Leave"
      this.fridayLeaveHide = false;
    }
    if (this.AddWorkForm.value.fridayWorkStatus == false) {
      this.Friday = "Working"
      this.fridayLeaveHide = true;
    }
  }

  // ! saturday status
  saturdayStatus(status) {
    if (this.AddWorkForm.value.saturdayWorkStatus == true) {
      this.Saturday = "Leave"
      this.saturdayLeaveHide = false;
    }
    if (this.AddWorkForm.value.saturdayWorkStatus == false) {
      this.Saturday = "Working"
      this.saturdayLeaveHide = true;
    }
  }

  arrUsersList: any[] = [];
  arrUsers1List: any[] = [];
  arrUsers2List: any[] = [];
  arrUsers3List: any[] = [];
  arrUsers4List: any[] = [];
  arrUsers5List: any[] = [];
  arrUsers6List: any[] = [];

  onSunEndsChanges(week, start, end) {
    const userDatas: GetOfficesDTO = {
      weekDays: WeekDays.Sunday,
      // weekOption: work,
      startsAt: start?.startTimeShortCodeId,
      endsAt: end,
      officeDetails: null
    }
    this.onTimeService.getWorkingOfficeBasedOnTimingsByInput(userDatas).subscribe(response => {
      this.arrUsersList = response.officeDetails;
    }, err => {
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    });
  }
  onMonEndsChanges(week, start, end) {
    const userDatas: GetOfficesDTO = {
      weekDays: WeekDays.Monday,
      // weekOption: work,
      startsAt: start?.startTimeShortCodeId,
      endsAt: end,
      officeDetails: null

    }
    this.onTimeService.getWorkingOfficeBasedOnTimingsByInput(userDatas).subscribe(response => {
      this.arrUsers1List = response.officeDetails;
    }, err => {
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    });
  }
  onTueEndsChanges(week, start, end) {
    const userDatas: GetOfficesDTO = {
      weekDays: WeekDays.Tuesday,
      // weekOption: work,
      startsAt: start?.startTimeShortCodeId,
      endsAt: end,
      officeDetails: null

    }
    this.onTimeService.getWorkingOfficeBasedOnTimingsByInput(userDatas).subscribe(response => {


      this.arrUsers2List = response.officeDetails;

    }, err => {
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    });
  }
  onWedEndsChanges(week, start, end) {
    const userDatas: GetOfficesDTO = {
      weekDays: WeekDays.Wednesday,
      // weekOption: work,
      startsAt: start?.startTimeShortCodeId,
      endsAt: end,
      officeDetails: null

    }
    this.onTimeService.getWorkingOfficeBasedOnTimingsByInput(userDatas).subscribe(response => {


      this.arrUsers3List = response.officeDetails;

    }, err => {
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    });
  }
  onThuEndsChanges(week, start, end) {
    const userDatas: GetOfficesDTO = {
      weekDays: WeekDays.Thursday,
      // weekOption: work,
      startsAt: start?.startTimeShortCodeId,
      endsAt: end,
      officeDetails: null

    }
    this.onTimeService.getWorkingOfficeBasedOnTimingsByInput(userDatas).subscribe(response => {


      this.arrUsers4List = response.officeDetails;

    }, err => {
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    });
  }
  onFriEndsChanges(week, start, end) {
    const userDatas: GetOfficesDTO = {
      weekDays: WeekDays.Friday,
      // weekOption: work,
      startsAt: start?.startTimeShortCodeId,
      endsAt: end,
      officeDetails: null

    }
    this.onTimeService.getWorkingOfficeBasedOnTimingsByInput(userDatas).subscribe(response => {


      this.arrUsers5List = response.officeDetails;

    }, err => {
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    });
  }
  onSatEndsChanges(week, start, end) {
    const userDatas: GetOfficesDTO = {
      weekDays: WeekDays.Saturday,
      // weekOption: work,
      startsAt: start?.startTimeShortCodeId,
      endsAt: end,
      officeDetails: null

    }
    this.onTimeService.getWorkingOfficeBasedOnTimingsByInput(userDatas).subscribe(response => {
      this.arrUsers6List = response.officeDetails;

    }, err => {
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    });
  }

  onChangeWork($event) {
    this.atShow = true
  }
  onAtChange($event, i) {
    this.endShow = true
  }
  onEndChange($event, i) {
    this.SelShow = true
  }
  onEndChanges($event) {
    this.SelShow = true
  }
  clearCoverage() {
    this.coverage = '';
    this.AddWorkForm.patchValue({ drpCoverage: "" });
  }
}
