import { Component, Inject, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ClaimFormDetailsService } from '../../claim-proxy/claim-processing-management/rcm/billing-management/billing/claim-form-details.service';
import { defaultGuid } from 'projects/shared/src/app/enums/allenums.enum';
import { Observable, Subscription } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import {
  claimEOBBlobsDTO,
  processPayorClaimDTO,
  processPayorClaimDetailsDTO,
} from '../../claim-proxy/claim-processing-management/rcm/billing-management/dto/models';
import { PayerDetailService } from 'projects/patient/src/app/patient-proxy/patient/payer-detail.service';
import { map, startWith } from 'rxjs/operators';
import { PayerResponseDTO } from 'projects/patient/src/app/patient-proxy/patient/dto/models';
import { InsuranceType } from '../../claim-proxy/claim-processing-management/rcm/billing-management/billing';
import { PDFDocument } from 'pdf-lib';
import { CommonModelPDFViewerBase64Component } from 'projects/shared/src/app/components/common-model-pdfviewer-base64/common-model-pdfviewer-base64.component';
import { PreviewDocumentComponent } from 'projects/order/src/app/preview-document/preview-document.component';
import { event } from 'jquery';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-move-to-secondary-model',
  templateUrl: './move-to-secondary-model.component.html',
  styleUrls: ['./move-to-secondary-model.component.scss'],
})
export class MoveToSecondaryModelComponent implements OnInit {
  moveToSecondaryEOB: FormGroup;
  isGetSecondaryClaimDetailsData: boolean = false;
  getSecondaryClaimDetailsDataAPICall: Subscription;
  processPayorClaimDetailsDTO: processPayorClaimDetailsDTO;
  isdrpLoading: boolean;
  payerId: string = defaultGuid;
  selectPayorList: any;
  // public filteredPrimaryPayors: Observable<string[]> | undefined;
  warningMsg:string='';
  filteredPayorName: Observable<PayerResponseDTO[]>;
  lstPayorNameList: PayerResponseDTO[];
  insPayerId: string = defaultGuid;
  ltBlobs: claimEOBBlobsDTO[] = [];
  isSaveUpdateSecondaryClaimDetailsData: boolean = false;
  saveUpdateSecondaryClaimDetailsDataAPICall: Subscription;
  insPolicyId: string = defaultGuid;
  FileForm: FormGroup;
  strPdfString: string;
  isPdfLoad: boolean=true;
  blobFileName: string='';
  memberId: string= defaultGuid;
  IsSecSelectedPDF: boolean = true;
  selectedFileName: string ='No file chosen';
  SelectFileControl=new FormControl();
  drpFileSearch:any;
  selectFiledrp:string='';
  isSelectFile:boolean=true;
  isSelectBrowse:boolean=true;
  newFilesForUpload: File;
  constructor(
    private fb: FormBuilder,
    private dialog: MatDialog,
    public movetoSecondaryDialogRef: MatDialogRef<MoveToSecondaryModelComponent>,
    private toaster: ToastrService,
    private claimFormDetailsService: ClaimFormDetailsService,
    private payerDetailService: PayerDetailService,
    @Inject(MAT_DIALOG_DATA)
    public data: { claimData: any } = {
      claimData: [],
    }
  ) {}

  ngOnInit(): void {
    this.initializeForms();
    this.getSecondaryClaimDetails();

  //SelectFileControl   DropDowm Serch
  this.SelectFileControl.valueChanges.subscribe((FilterText) => {
    FilterText = FilterText.toLowerCase();
    this.ltBlobs = this.drpFileSearch.filter((dx) =>
      dx.blobFileName.toLowerCase().includes(FilterText)
    );
  });
  }

  ngOnDestroy(): void {
    if (this.getSecondaryClaimDetailsDataAPICall) {
      this.getSecondaryClaimDetailsDataAPICall.unsubscribe();
    }
    if (this.saveUpdateSecondaryClaimDetailsDataAPICall) {
      this.saveUpdateSecondaryClaimDetailsDataAPICall.unsubscribe();
    }
  }

  //Method to initialize the search form
  initializeForms() {
    this.moveToSecondaryEOB = this.fb.group({
      // drpPayorLevel: new FormControl('', [Validators.required]),
      txtPayorLevel: new FormControl('', [Validators.required]),
      drpPayorName: new FormControl('', [Validators.required]),
      sltPayorNameFilter: new FormControl(''),
      txtTotalAmount: ['', [Validators.pattern(/^\d{1,9}(\.\d{1,2})?$/)]],
      txtPaidAmount: ['', [Validators.pattern(/^\d{1,9}(\.\d{1,2})?$/)]],
      txtPendingAmount: ['', [Validators.pattern(/^\d{1,9}(\.\d{1,2})?$/)]],
      // drpFile: ['', [Validators.required]],
      drpFile: ['',[Validators.required]],
      txtSelectFile:new FormControl('')
    });
    this.FileForm = this.fb.group({
      fileUpload: ['', Validators.required],
    });
  }

  sanitizeInput(event: any, controlName: string) {
    const inputValue = event.target.value;
    const sanitizedValue = inputValue.replace(/[^\d.]/g, '');
    this.moveToSecondaryEOB.get(controlName)?.setValue(sanitizedValue);
  }
  showWarningMsg(msg:string){
    this.warningMsg=msg;
   }
  getSecondaryClaimDetails() {
    this.isGetSecondaryClaimDetailsData = true;
    try {
      if (this.getSecondaryClaimDetailsDataAPICall) {
        this.getSecondaryClaimDetailsDataAPICall.unsubscribe();
      }
      let gClaimId: string = this.data?.claimData?.claimId || defaultGuid;
      this.getSecondaryClaimDetailsDataAPICall = this.claimFormDetailsService
        .getSecondaryClaimDetailsByGClaimId(gClaimId)
        .subscribe(
          (response) => {
            this.processPayorClaimDetailsDTO = response;
            this.insPayerId = response?.insPayerId || defaultGuid;
            this.getPrimaryPayerList(
              response?.insPayorName || '',
              this.insPayerId || defaultGuid
            );
            this.moveToSecondaryEOB?.patchValue({
              txtPayorLevel: 'Secondary',
              txtTotalAmount: response?.billedAmount || 0,
              txtPaidAmount: response?.paidAmount || 0,
              txtPendingAmount: response?.pendingAmount || 0,
            });
            this.ltBlobs = response?.ltBlobs;
            this.drpFileSearch=this.ltBlobs;
            this.insPolicyId = response?.insPolicyId || defaultGuid;
            this.memberId=response?.memberId || defaultGuid;
            setTimeout(() => {
              this.isGetSecondaryClaimDetailsData = false;
            }, 1000);
          },
          (err) => {
            // // Check if the error has the expected structure
            if (err.error && err.error.error && err.error.error.message) {
              //   // Extract the specific error message from the error object
              const specificErrorMessage = err.error.error.message;
              //   // Show a warning toaster with the specific error message
              // this.toaster.warning(specificErrorMessage, 'Warning');
              this.showWarningMsg(specificErrorMessage);
            } else {
              //   // If the error structure is unexpected, log the entire error message
              console.error('Unexpected error structure:', err);
              this.toaster.error('Get Secondary Claim Details Failed', 'Error');
            }
            setTimeout(() => {
              this.isGetSecondaryClaimDetailsData = false;
            }, 100);
          }
        );
    } catch (error) {
      console.error('Unexpected error:', error);
      setTimeout(() => {
        this.isGetSecondaryClaimDetailsData = false; // Enable the checkbox
      }, 1000);
    } finally {
    }
  }
  drpDownMessage() {
    if (this.isdrpLoading === false) {
      return 'No Matches found';
    } else {
      return 'Loading...';
    }
  }
  getPrimaryPayerList(value = '', payerId = '') {
    //Get Payer list
    this.isdrpLoading = true;
    let eligibilityMode = 1;
    this.payerDetailService
      .getdrpPayerByIModeV1(eligibilityMode, value, payerId)
      .subscribe(
        (response) => {
          this.lstPayorNameList = response;
          this.filteredPayorName = this.moveToSecondaryEOB
            .get('sltPayorNameFilter')
            .valueChanges.pipe(
              startWith(''),
              map((value) =>
                this.lstPayorNameList?.filter(
                  (option) =>
                    option?.payerCode
                      .toLowerCase()
                      .includes(value?.toLowerCase() ?? '') ||
                    option?.payerName
                      .toLowerCase()
                      .includes(value?.toLowerCase() ?? '')
                )
              )
            );
          this.moveToSecondaryEOB?.patchValue({
            drpPayorName: payerId,
          });

          setTimeout(() => {
            this.isdrpLoading = false; // Enable the checkbox
          }, 100);
        },
        (err) => {
          this.isdrpLoading = true;
        }
      );
  }
  savefuncation() {
    this.isSaveUpdateSecondaryClaimDetailsData = true;
    try {
      if (this.saveUpdateSecondaryClaimDetailsDataAPICall) {
        this.saveUpdateSecondaryClaimDetailsDataAPICall.unsubscribe();
      }

     const index = this.lstPayorNameList.findIndex(
        (obj) => obj.payerId === this.moveToSecondaryEOB.value.drpPayorName
      );
      let policyName=''
      if (index !== -1) {
        policyName=this.lstPayorNameList[index].payerName;
      }
      let lprocessPayorClaimDTO: processPayorClaimDTO = {
        claimId: this.data?.claimData?.claimId || defaultGuid,
        blobFileName: this.blobFileName,
        // eobBase64Content: this.strPdfString,
        pendingAmount: this.moveToSecondaryEOB.value.txtPendingAmount,
        existingBlobName: this.moveToSecondaryEOB?.value?.drpFile || null,
        gPolicyId: this.insPolicyId, //this.moveToSecondaryEOB.value.drpPayorName, //
        policyName: policyName,
        memberId: this.memberId,
        payorLevel: InsuranceType.Secondary,
        // eobFile: this.FileForm.value.fileUpload || null,
        eobFile: this.newFilesForUpload  || null,
      };
      this.saveUpdateSecondaryClaimDetailsDataAPICall =
        this.claimFormDetailsService
          .updateSecondaryClaimByInput(lprocessPayorClaimDTO)
          .subscribe(
            (response) => {
              this.toaster.success('The Move to Secondary details has been updated','Success');

              setTimeout(() => {
                this.isSaveUpdateSecondaryClaimDetailsData = false; // Enable the checkbox
              }, 1000);

              this.movetoSecondaryDialogRef.close(true);

            },
            (err) => {
              const errorMessageObject = JSON.parse(err.error);
              const specificErrorMessage = errorMessageObject.error.message;
              // // Check if the error has the expected structure
              // if (err.error && err.error.error) {
                //   // Extract the specific error message from the error object
                // const specificErrorMessage = err.error.message;
                //   // Show a warning toaster with the specific error message
                // this.toaster.warning(specificErrorMessage, 'Warning');
                this.showWarningMsg(specificErrorMessage);
              // } else {
              //   //   // If the error structure is unexpected, log the entire error message
              //   // console.error('Unexpected error structure:', err);
              //   this.toaster.error(
              //     'Get Secondary Claim Details Failed',
              //     'Error'
              //   );
              // }
              setTimeout(() => {
                this.isSaveUpdateSecondaryClaimDetailsData = false;
              }, 100);
            }
          );
    } catch (error) {
      console.error('Unexpected error:', error);
      setTimeout(() => {
        this.isSaveUpdateSecondaryClaimDetailsData = false; // Enable the checkbox
      }, 1000);
    } finally {
    }
  }

  onFileChange(event: any): void {
    this.isSelectBrowse=false;
    const target = event.target as HTMLInputElement;
    const file = target.files?.[0];
    this.newFilesForUpload = file;
    if (file) {
      const fileName = file.name;
      this.blobFileName = fileName;
      // this.selectedFileName = fileName;
    } else {
      this.blobFileName = 'default.pdf';
      // this.selectedFileName = 'NA';
    }

    // Check if a file was selected and if it is a valid PDF
    if (
      file &&
      (file.type === 'application/pdf' ||
        file.name.toLowerCase().endsWith('.pdf'))
    ) {
      this.isPdfLoad = false; // Hide the PDF viewer initially

      this.getPDFPageCount(file)
        .then((pageCount) => {
          if (pageCount > 0) {
            const reader = new FileReader();
            reader.onload = () => {
              const base64String = btoa(reader.result as string); // Encode using btoa()
              this.strPdfString = base64String;
              setTimeout(() => (this.isPdfLoad = true), 500);
              target.value = '';
            };
            reader.readAsBinaryString(file); // Read as binary string for proper encoding
            this.selectedFileName = file.name;
          } else {
            // this.toaster.warning(
            //   'Error loading PDF file. Please try again.',
            //   'Warning'
            // );
            this.showWarningMsg('Error loading PDF file. Please try again.');
            this.selectedFileName='No file chosen'
            this.strPdfString = null;
            target.value = ''; // Clear the file input value
            setTimeout(() => (this.isPdfLoad = true), 500);
          }
        })
        .catch((error) => {
          console.warn(error);
          // this.toaster.warning(
          //   'Error loading PDF file. Please try again.',
          //   'Warning'
          // );
          this.showWarningMsg('Error loading PDF file. Please try again.');
          this.selectedFileName='No file chosen';
          this.strPdfString = null;
          target.value = ''; // Clear the file input value
          setTimeout(() => (this.isPdfLoad = true), 500);
        });
    } else {
      // this.toaster.warning(
      //   'Invalid file format. Please select a valid PDF file.',
      //   'Warning'
      // );
      this.showWarningMsg('Invalid file format. Please select a valid PDF file.');
      this.selectedFileName='No file chosen'
      // Optionally clear the file input value to reset it
      target.value = '';
      this.strPdfString = null;
    }
  }

  async getPDFPageCount(file: File): Promise<number> {
    try {
      const pdfBytes = await file.arrayBuffer();
      const pdfDoc = await PDFDocument.load(pdfBytes, {
        ignoreEncryption: true,
      });
      const pages = pdfDoc.getPages();
      return pages.length;
    } catch (error) {
      console.warn(error);
      return 0; // Return a default value or handle the error as per your requirement
    }
  }

  viewDocument(event: MouseEvent) {
    // event.stopPropagation();
    const dialogRef = this.dialog.open(CommonModelPDFViewerBase64Component, {
      // maxWidth: '100vw', // Set the maximum width of the dialog to the viewport width
      // maxHeight: '100vh', // Set the maximum height of the dialog to the viewport height
      // panelClass: 'fullscreen-dialog-panel', // Apply custom CSS class for styling
      height: '75%',
      width: '80%',
      disableClose: true, // Disable closing the dialog via the backdrop or Escape key
      data: {
        base64: this.strPdfString,
      },
    });
    this.dialog.afterAllClosed.subscribe((resposne) => {});
  }

  openSecSelectedPDF(event: MouseEvent) {
    event.stopPropagation();
    let eobBlobName = this.moveToSecondaryEOB.value.drpFile;

    const dialogRef = this.dialog.open(PreviewDocumentComponent, {
      height: '75%',
      width: '80%',
      disableClose: true,
      data: {
        // Add any data you want to pass to the dialog here
        // For example, you can pass patientId and documentId

        documentData: eobBlobName,
        isClaim: true,
      },
    });

    // Subscribe to the closed event
    dialogRef.afterClosed().subscribe(() => {
      // Call the addNotes function directly
      // dialogRef.componentInstance.addNotes();
    });
  }

  setEnableView(){
    // this.FileForm?.controls?.fileUpload?.setValidators([Validators.nullValidator]);
    // this.FileForm?.controls?.fileUpload?.updateValueAndValidity();
    this.IsSecSelectedPDF = false;
    this.isSelectFile = false;
  }
  clearSelectFile(){
    this.selectFiledrp='';
    // this.FileForm?.controls?.fileUpload?.setValidators([Validators.required]);
    // this.FileForm?.controls?.fileUpload?.updateValueAndValidity();
    this.isSelectFile = true;
  }

  resetInboundDocumentForm() {
    // if (confirm('Are you sure to reset - all details ?')) {
      this.moveToSecondaryEOB?.reset();
      this.FileForm?.reset();
      this.isPdfLoad = false;
      this.strPdfString = null;
      setTimeout(() => {
        this.isPdfLoad = true;
      }, 100);
    // }
  }
  isEmpty(value: any): boolean {
    return (
      value === null ||
      value === undefined ||
      value === '' ||
      value === defaultGuid
    );
  }

  closeMdl(){
    this.movetoSecondaryDialogRef.close(false);
  }

  // isDropdownOpen = false;
  // onDropdownOpenChange(isOpen: boolean) {
  //   this.isDropdownOpen = isOpen;
  //   if(this.isDropdownOpen){
  //     this.getPrimaryPayerList('','');
  //   }
  // }
}
