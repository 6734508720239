import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DataTableDirective } from 'angular-datatables';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { Subscription } from 'rxjs';
import { MatAccordion } from '@angular/material/expansion';
import { CreateUpdateMasterNotesDTO } from 'projects/order/src/app/order-proxy/order-management/inventory/dto';
import { MasterNotesService } from 'projects/order/src/app/order-proxy/order-management/inventory';
import { ListService } from '@abp/ng.core';
import { TableService } from 'projects/shared/src/app/table.service';
import { ToastrService } from 'ngx-toastr';
import { HttpErrorResponse } from '@angular/common/http';
import Swal from 'sweetalert2';
import { CommunicationService } from 'projects/shared/src/app/services/communication.service';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { Title } from '@angular/platform-browser';
import { DatePipe } from '@angular/common';
@Component({
  selector: 'app-inventory-notes-table',
  templateUrl: './inventory-notes-table.component.html',
  styleUrls: ['./inventory-notes-table.component.scss'],

})
export class InventoryNotesTableComponent implements OnInit {
  notesForm: FormGroup;
  notetype: string;
  notesText = '';
  notesId: string = '';
  panelOpenState: boolean = false;
  isShowSpinner: boolean = false;
  subscription$: Subscription[] = [];
  isLoading: boolean = false;
  @ViewChild(MatAccordion, { static: true }) matAccordion: MatAccordion;
  saveButtonHide: boolean;
  @Input() tableData: any[];
  dtOptions: any;
  NotesTableDate: any;

  @ViewChild(DataTableDirective, { static: false })
  datatableElement: DataTableDirective;
  constructor(
    private formBuilder: FormBuilder,
    private notesService: MasterNotesService,
    public list: ListService,
    private snackBar: MatSnackBar,
    public Table: TableService,
    private toastr: ToastrService,
    private communicationService: CommunicationService,
    private title : Title,
    private datepipe:DatePipe

  ) { }

  ngOnInit(): void {
    this.title.setTitle('Qsecure | Notes');
    this.dtOptions = {
      responsive: true,
      paging: false,
      scrollCollapse: true,
      scrollY: '410px',
      scrollX: true,
      dom: 'Bfrtip',
      language: {
        emptyTable: 'No records',
        info: 'Total : _MAX_ records',
        infoEmpty: 'Total : _MAX_ records',
        infoFiltered: '(filtered : _TOTAL_ records)',
        loadingRecords: 'Loading...',
        zeroRecords: 'No matching records',
      },
      columnDefs: [
        // { targets: [0], orderable: false },
        { targets: [3,5], visible: false },
      ],
      buttons: [
        {
          extend: 'excel',
          text: 'Export',
          filename: 'Master Notes',
          exportOptions: {
            columns: ':visible:not(:first-child,:eq(0)) ',
          },
        },
        {
          extend: 'colvis',
          columns: ':not(:eq(0), :eq(1))',
        }
      ]
    };
    this.NotesTableDate = this.tableData;
    this.createNotesForm();
    this.dtOptions.buttons[0].filename =
    'Master Notes' + '-' + this.datepipe.transform(new Date(), 'MM-dd-yyyy');
  }

  // //After View Method
  ngAfterViewInit(): void {
    this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.columns().every(function () {
        const that = this;
        $('input', this.footer()).on('keyup change', function () {
          if (that.search() !== this['value']) {
            that.search(this['value']).draw();
          }
        });
      });
    });
  }

  //! Form Creation and Few Validations
  createNotesForm() {
    this.notesForm = this.formBuilder.group({
      txtNoteType: new FormControl('', [Validators.required, this.noWhitespaceValidator]),
    });
  }

  public noWhitespaceValidator(control: FormControl) {
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { whitespace: true };
  }

  //! Notes Save Funtion
  saveNotes() {
    this.isShowSpinner = true;
    this.saveButtonHide = true;
    let notesDetails: CreateUpdateMasterNotesDTO = {
      noteType: this.notesForm.value.txtNoteType,
    };

    if (
      this.notesId === '' ||
      this.notesId === undefined ||
      this.notesId === null
    ) {
      this.notesService.create(notesDetails).subscribe(
        (response) => {
          this.panelOpenState = false;
          this.toastr.success('Saved Successfully', 'Success');
          this.saveButtonHide = false;
          this.isShowSpinner = false;
          this.communicationService.triggerMasterNotesGetMethodCall();
          // this.loadNotesTable();
          this.resetNotes();
        },
        (err) => {
          this.saveButtonHide = false;
          this.isShowSpinner = false;
          const data: HttpErrorResponse = err;
          Swal.fire({
            icon: 'info',
            text: data?.error?.error?.message,
          });
        }
      );
    } else {
      this.notesService.update(this.notesId, notesDetails).subscribe(
        (response) => {
          this.toastr.success('Updated Successfully', 'Success');
          // this.loadNotesTable();
          this.communicationService.triggerMasterNotesGetMethodCall();
          this.resetNotes();

          this.saveButtonHide = false;
          this.isShowSpinner = false;
        },
        (err) => {
          this.saveButtonHide = false;
          this.isShowSpinner = false;

          const data: HttpErrorResponse = err;
          Swal.fire({
            icon: 'info',
            text: data?.error?.error?.message,
          });
        }
      );
    }
    this.notesId = '';
  }
  //! View Notes Function By Id
  viewNotes(notesTableId: string) {
    this.notesForm.markAsDirty();
    if (this.panelOpenState == false) {
      this.panelOpenState = true;
    }
    const notesGet = this.notesService.get(notesTableId).subscribe(
      (response) => {
        this.notesForm.patchValue({
          txtNoteType: response.noteType,
        });
        this.notesId = response.id;
      },
      (err) => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      }
    );
    this.subscription$.push(notesGet);
  }

  //! Delete Notes Function By Id
  // deleteNotes(notesTableId: string) {
  //   Swal.fire({
  //     title: 'Are you sure you want to Delete?',
  //     text: "You won't be able to retrieve this data!",
  //     icon: 'warning',
  //     showCancelButton: true,
  //     confirmButtonColor: '#34c38f',
  //     cancelButtonColor: '#f46a6a',
  //     confirmButtonText: 'Yes, delete it!',
  //   }).then((result) => {
  //     if (result.value) {
  //       const notesDelete = this.notesService.delete(notesTableId).subscribe(
  //         (response) => {
  //           this.toastr.success('Deleted Successfully', 'Success');
  //           // this.loadNotesTable();
  //           this.communicationService.triggerMasterNotesGetMethodCall();
  //           this.resetNotes();
  //         },
  //         (err) => {
  //           const data: HttpErrorResponse = err;
  //           Swal.fire({
  //             icon: 'info',
  //             text: data?.error?.error?.message,
  //           });
  //         }
  //       );
  //     }
  //   });
  // }

  //Notes Form Reset Function
  resetNotes() {
    this.notesId = '';
    this.notesForm.patchValue({
      txtNoteType: '',
    });
    this.notetype = '';
    this.notesForm.reset();
    this.notesForm.markAsUntouched();
    this.notesForm.updateValueAndValidity();
  }


  //Copy Text While Duble on table Row
  onRowDoubleClickV1(textToCopy) {
    this.copyTextToClipboard(textToCopy);
    this.showNotification('Text copied: ' + textToCopy);
  }

  showNotification(message: string): void {
    this.snackBar.open(message, 'Close', {
      duration: 3000, // Duration in milliseconds
      horizontalPosition: 'end', // Positioning the notification horizontally
      verticalPosition: 'bottom', // Positioning the notification vertically
    });
  }

  copyTextToClipboard(text: string): void {
    const textArea = document.createElement('textarea');
    textArea.value = text;
    document.body.appendChild(textArea);
    textArea.select();
    document.execCommand('Copy');
    textArea.remove();
  }

  toggle() {
    this.panelOpenState = true;
  }
  toggleV2() {
    this.panelOpenState = false;
  }

  //Status Change Actice And Inactive
  statusChange(event: MatSlideToggleChange, data: any) {
    if (event.checked == true) {
      Swal.fire({
        title: 'Do you want to activate?',
        text: 'By Changing Status, this Notes Type will be activated',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#34c38f',
        cancelButtonColor: '#f46a6a',
        confirmButtonText: 'Yes, Activate it!'
      }).then(result => {
        this.isLoading=true;
        if (result.value) {
          if (data?.id && data?.id !== "" && data?.id !== null && data?.id !== undefined) {
            this.notesService.enableDisableMasterNotesById(data?.id).subscribe(response => {
              this.isLoading=false;
              this.toastr.success('Activated Successfully', 'Success')
              this.isShowSpinner = false;
              this.communicationService.triggerMasterNotesGetMethodCall();
            }, (err) => {
              this.isLoading=false;
              this.communicationService.triggerMasterNotesGetMethodCall();
              const data: HttpErrorResponse = err;
              const message = JSON.parse(data?.error);
              Swal.fire({
                icon: 'info',
                text: message?.error?.message,
              });
            });
          }
        }
        else {
          this.isLoading = false;
          event.source.checked = false;
        }
      });
    }
    else
      if (event.checked == false) {
        Swal.fire({
          title: 'Do you want to deactivate?',
          text: 'By Changing Status, this Notes Type will be deactivated',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#34c38f',
          cancelButtonColor: '#f46a6a',
          confirmButtonText: 'Yes, deactivate it!'
        }).then(result => {
          this.isLoading=true;
          if (result.value) {
            if (data?.id && data?.id !== "" && data?.id !== null && data?.id !== undefined) {
              this.notesService.enableDisableMasterNotesById(data?.id).subscribe(response => {
                this.isLoading=false;
                this.toastr.success('Deactivated Successfully', 'Success');
                this.communicationService.triggerMasterNotesGetMethodCall();
                this.isShowSpinner = false;
              }, (err) => {
                this.isLoading=false;
                this.communicationService.triggerMasterNotesGetMethodCall();
                const data: HttpErrorResponse = err;
                const message = JSON.parse(data?.error);
                Swal.fire({
                  icon: 'info',
                  text: message?.error?.message,
                });
              });
            }
          }
          else {
            this.isLoading = false;
            event.source.checked = true;
          }
        });
      }
  }

}
