import { PagedAndSortedResultRequestDto } from '@abp/ng.core';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormControlName, FormGroup, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ToastrService } from 'ngx-toastr';
import { ActiveStatus } from 'projects/admin/src/app/admin-proxy/platform-app-management/rcm/platform-management/localization/enumeration/active-status.enum';
import { CreateUpdateMaskSizeDTO } from 'projects/order/src/app/order-proxy/order-management/order-optimization/dto';
import { MaskSizeService } from 'projects/order/src/app/order-proxy/order-management/order-optimization/mask-size.service';
import { noWhitespaceValidator } from 'projects/patient/src/app/add-note-tab/add-note-tab.component';
import { defaultGuid } from 'projects/shared/src/app/enums/allenums.enum';
import { CommonService } from 'projects/shared/src/app/services/common.service';
import { CommunicationService } from 'projects/shared/src/app/services/communication.service';
import { Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-size',
  templateUrl: './size.component.html',
  styleUrls: ['./size.component.scss']
})
export class SizeComponent implements OnInit {
  sizeForm: FormGroup;
  selectedOption: number = ActiveStatus.Active;

  sizeStatus: any;
  SizeId: string = '';
  step: boolean = false;
  saveButtonHide: boolean = false;
  sizeList: any;
  isLoadData: boolean = false;
  tenantId: string;
  subscription$: Subscription[] = [];
  constructor(
    private formbuilder: FormBuilder,
    private maskSizeService: MaskSizeService,
    private communicationService: CommunicationService,
    private toastr: ToastrService,
    private title : Title,
    private commonService : CommonService
  ) {
    const comService1 =
    this.communicationService.insuranceAllowableToggleMethodCall$.subscribe(
      () => {
        this.toggleV2();
      }
    );
  this.subscription$.push(comService1);
  const comService2 =
    this.communicationService.insuranceAllowableGetMethodCall$.subscribe(
      (sizeId) => {
        this.getSizeId(sizeId);
      }
    );

  this.subscription$.push(comService2);

  const comService3 =

  this.communicationService.statusMethodCall$.pipe(
    map((data: any) => [data.event, data.productId])
  ).subscribe(([event, productId]) => {
    this.statusChange(event,productId)
    // Use statusId and param2 here
  });
  this.subscription$.push(comService3);
  }

  ngOnInit(): void {
    this.title.setTitle('Qsecure | Size');
    this.tenantId = localStorage.getItem('tenantId') ?? '';

    this.sizeForm = this.formbuilder.group({
      txtSize: new FormControl("",[Validators.required,noWhitespaceValidator])
    })
    this.loadSizeList()
  }

  loadSizeList(){
    this.isLoadData = false
    this.maskSizeService.getMaskDetailsBystatusByStatusFilter(this.selectedOption).subscribe(response =>{
      this.sizeList = response?.items;

      this.sizeList = this.sizeList.map((data) => {
        return {
          ...data,
          creationTime: this.commonService.getFormattedDateTimeZone(
            data.creationTime
          ),
          lastModificationTime: this.commonService.getFormattedDateTimeZone(
            data.lastModificationTime
          ),
        };
      });

      this.isLoadData = true
    },(err)=>{
      this.isLoadData = true
    })
  }
  getSizeId(Id){
    this.step = true;
    this.SizeId = Id
    this.maskSizeService.get(Id).subscribe(response =>{
      this.sizeForm.patchValue({
        txtSize: response.description
      })

    })

  }
  statusChange(event, data){
    if (event.checked == true) {
      Swal.fire({
        title: 'Do you want to activate?',
        text: 'By Changing Status, this Size  will be activated',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#34c38f',
        cancelButtonColor: '#f46a6a',
        confirmButtonText: 'Yes, Activate it!'
      }).then(result => {
        if (result.value) {
          // let productCategoryDetails: CreateUpdateProductCategoryDTO = {
          //   productCategoryName: data?.productCategoryName,
          //   status: Status.Active
          // }

          if (data && data !== "" && data!== null && data !== undefined) {
            this.maskSizeService.updateMaskStatusByIdAndStatus(data, true).subscribe(response => {
              // Swal.fire({ title: 'Success', html: 'Activated Successfully', icon: 'success', timer: 3000, timerProgressBar: true })
              this.toastr.success('Activated Successfully','Success')
              this.loadSizeList();
            }, (err) => {
              const data: HttpErrorResponse = err;
              Swal.fire({
                icon: 'info',
                text: data?.error?.error?.message,
              });
              this.loadSizeList();

            })
          }
        }
        else {
          // this.getTableData();
          this.loadSizeList();

        }
      });
    }
    else
      if (event.checked == false) {
        Swal.fire({
          title: 'Do you want to deactivate?',
          text: 'By Changing Status, this Size  will be deactivated',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#34c38f',
          cancelButtonColor: '#f46a6a',
          confirmButtonText: 'Yes, deactivate it!'
        }).then(result => {
          if (result.value) {
            if (data && data!== "" && data !== null && data !== undefined) {
              this.maskSizeService.updateMaskStatusByIdAndStatus(data, false).subscribe(response => {
                // Swal.fire({ title: 'Success', html: 'Deactivated Successfully', icon: 'success', timer: 3000, timerProgressBar: true })
                this.toastr.success('Deactivated Successfully','Success')
                this.loadSizeList();
              }, (err) => {
                const data: HttpErrorResponse = err;
                Swal.fire({
                  icon: 'info',
                  text: data?.error?.error?.message,
                });
                this.loadSizeList();
              })
            }
          }
          else {
            this.loadSizeList();
          }
        });
      }
  }
  toggle() {
    this.step = true;
  }
  toggleV2() {
    this.step = false;
  }
  saveSizedetails(){

    this.saveButtonHide = true
    let saveDetails: CreateUpdateMaskSizeDTO = {
      maskSizeId: 0,
      description: this.sizeForm.value.txtSize,
      ddOrder: 0,
      isActive: true,
      tenantId: this.tenantId

    }
    if(this.SizeId != ''){
      this.maskSizeService.update(this.SizeId,saveDetails).subscribe((response) => {

        this.toastr.success(' Updated Successfully', 'Success');
        this.saveButtonHide = false
        this.loadSizeList()
         this.resetForm()
      },(err) => {
        this.saveButtonHide = false
        const data: HttpErrorResponse = err;

        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      })
    } else if(this.SizeId == '') {
      this.maskSizeService.create(saveDetails).subscribe(response => {
        this.toastr.success(' Saved Successfully', 'Success');
        this.SizeId = ''
        this.saveButtonHide = false;
        this.loadSizeList()
        this.resetForm()
      },(err) => {
        this.saveButtonHide = false;
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      })
    }



  }
  resetForm(){
    this.sizeForm.patchValue({
      txtSize: ""
    })
    this.SizeId='';
    this.clearValidationErrors()
  }
  clearValidationErrors() {
    Object.keys(this.sizeForm.controls).forEach((key) => {
      this.sizeForm.get(key).setErrors(null);
    });
  }
}
