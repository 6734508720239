import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { CommunicationService } from 'projects/shared/src/app/services/communication.service';
import { DataTableDirective } from 'angular-datatables';
import { CreateUpdatePatientAddressComponent } from '../create-update-patient-address/create-update-patient-address.component';
import { PatientAddressValidationDTO } from 'projects/order/src/app/order-proxy/order-management/order-optimization/dto';
import { MmOrderService } from 'projects/order/src/app/order-proxy/order-management/order-optimization/mm-order.service';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { DatePipe } from '@angular/common';
@Component({
  selector: 'app-patient-address-list-table',
  templateUrl: './patient-address-list-table.component.html',
  styleUrls: ['./patient-address-list-table.component.scss'],
})
export class PatientAddressListTableComponent implements OnInit {
  @Input() tableData: any;
  @Input() addressData: any;
  addressList: any;
  @ViewChild(DataTableDirective, { static: false })
  datatableElement: DataTableDirective;
  dtAddressTableOptions = {
    responsive: true,
    paging: false,
    dom: 'Bfrtip',
    language: {
      emptyTable: 'No records',
      info: 'Total : _MAX_ records',
      infoEmpty: 'Total : _MAX_ records',
      infoFiltered: '(filtered : _TOTAL_ records)',
      loadingRecords: 'Loading...',
      zeroRecords: 'No matching records',
    },
    scrollCollapse: true,
    scrollY: '300px',
    scrollX: true,
    order: [],
    fixedHeader: true,
    columnDefs: [
      {
        targets: [9, 10],
        type: 'date',
        render: function (data, type, row) {
          // Assuming data is in the format MM/DD/YYYY
          if (type === 'sort' || type === 'type') {
            var dateParts = data.split('/');
            return new Date(dateParts[2], dateParts[0] - 1, dateParts[1]);
          } else {
            return data;
          }
        },
      },
    ],
    search: {
      smart: false, // Set to "none" for full exact match
    },
    buttons: [
      {
        extend: 'excel',
        text: 'Export',
        filename: 'Patient Address List',
        exportOptions: {
          columns: [1,2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13], // Specify the columns you want to export (0-indexed)
        },
      },
    ],
  };
  subscription$: Subscription[] = [];
  constructor(
    private dialog: MatDialog,
    private communicationService: CommunicationService,
    private mmOrderService: MmOrderService,
    private toaster: ToastrService,
    private datepipe: DatePipe,
  ) {}

  ngOnInit(): void {
    this.addressList = this.tableData;

    this.dtAddressTableOptions.buttons[0].filename =
    'Patient Address List' + '-' + this.datepipe.transform(new Date(), 'MM-dd-yyyy');
  }

  ngAfterViewInit(): void {
    if (this.datatableElement && this.datatableElement.dtInstance) {
      this.refreshDataTable();
    } else {
      setTimeout(() => this.refreshDataTable(), 500);
    }
  }

  refreshDataTable(): void {
    this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.columns().every(function () {
        const that = this;
        $('input', this.footer()).on('keyup change', function () {
          if (that.search() !== this['value']) {
            that.search(this['value']).draw();
          }
        });
      });
    });
  }

  //Method to Select the address and send it to the order component
  selectAddress(address) {

    this.communicationService.triggerAddressDetails(address);
    this.dialog.closeAll();
  }
  getFormattedDate(date: string): string {
    return date ? new Date(date).toLocaleDateString('en-US') : '-';
  }
  getAddressDetails(addressId: string) {
    const dialogRef = this.dialog.open(CreateUpdatePatientAddressComponent, {
      data: {
        patientId: this.addressData.patientId,
        chartId: this.addressData.chartId,
        addressId: addressId,
      },
      // height: '50%',
      // width: '50%',
    });

    dialogRef.afterClosed().subscribe(() => {});
  }
  //Method to verify address using google api
  verifyAddress(
    addressLine: string,
    city: string,
    state: string,
    phone: string,
    postalCode: string
  ) {
    let deliveryAddress: PatientAddressValidationDTO = {
      address_line1: addressLine,
      city: city,
      state_province: state,
      phone: phone,
      postal_code: postalCode,
    };

    const addressVerify = this.mmOrderService
      .checkAddressValidation_GoogleByInput(deliveryAddress)
      .subscribe(
        (response) => {
          response?.isValid
            ? this.toaster.success('Address Verified Successfully!')
            : this.toaster.warning('Invalid Address!');
        },
        (err) => {
          this.toaster.error('Something Went Wrong');
        }
      );
    this.subscription$.push(addressVerify);
  }
}
