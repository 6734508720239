import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import Swal from 'sweetalert2';
import { CreateUpdateShippingCheckListDTO } from '../order-proxy/order-management/order/dto/models';
import { SaleorderService } from '../order-proxy/order-management/order/saleorder.service';
import { ToastrService } from "ngx-toastr";

@Component({
  selector: 'app-return-order-update',
  templateUrl: './return-order-update.component.html',
  styleUrls: ['./return-order-update.component.scss']
})
export class ReturnOrderUpdateComponent implements OnInit  {
  returnForm: FormGroup;

  constructor(public dialogRef: MatDialogRef<ReturnOrderUpdateComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { data:any },
    private orderService: SaleorderService,
    private toastr: ToastrService,
    private fb: FormBuilder,) { }

  ngOnInit(): void {

    this.returnForm = this.fb.group({
      txtComments: new FormControl(""),
    })

  }



  saveReturn(){
    let CreateUpdateShipping: CreateUpdateShippingCheckListDTO = {
      userName: this.returnForm.value.txtCsrName,
      status: "Returned",
      returnReason: this.returnForm.value?.txtComments,
      productWeight: this.data.data?.productWeight,
      methodOfdelivery: this.data.data?.methodOfdelivery,
      modeOfDelivery: this.data.data?.modeOfDelivery,
      rejectedReason: this.data.data?.rejectedReason,
      saleOrderDocumentBlobName: this.data.data?.saleOrderDocumentBlobName,
    }
    this.orderService.updateShippingCheckListByOrderIdAndInput(this.data.data.id, CreateUpdateShipping).subscribe(response => {

      // Swal.fire({ title: 'Success', html: 'Saved Successfully', icon: 'success', timer: 3000, timerProgressBar: true });
      this.toastr.success('Saved Successfully','Success')
      this.dialogRef.close();
    }, err => {
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    });
  }

}
