import { mapEnumToOptions } from '@abp/ng.core';

export enum inboundDocumentStatus {
  assigned = 'Assigned',
  unAssigned = 'UnAssigned',
  trash = 'Trash',
  yetToApprove = "Yet To Approve",
  completed = "Completed",
  archived = "Archived",
  ProcessPending="ProcessPending",
  Processed="Processed",
  Sorted="Sorted"
}

export const inboundDocumentStatusOptions = mapEnumToOptions(inboundDocumentStatus);
