import { HttpErrorResponse } from '@angular/common/http';
import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngxs/store';
import {
  ClaimNoteTypeDTO,
  OrderMasterDropdownDTO,
  TransCodeDTO,
  TypeCodeDTO,
} from 'projects/patient/src/app/dropdown-proxy/dropdown-management/dropdowns/dto/order/models';
import {
  PatientMasterDropdownDTO,
  StateOfAutoAccidentDTO,
} from 'projects/patient/src/app/dropdown-proxy/dropdown-management/dropdowns/dto/patient/models';
import { OrderMasterDropdownService } from 'projects/patient/src/app/dropdown-proxy/dropdown-management/dropdowns/master-dropdown-service/order-master-dropdown.service';
import { PatientMasterDropdownService } from 'projects/patient/src/app/dropdown-proxy/dropdown-management/dropdowns/master-dropdown-service/patient-master-dropdown.service';
import { OrderDropdowns } from 'projects/patient/src/app/dropdown-proxy/dropdown-management/dropdowns/order-dropdowns.enum';
import { PatientDropdowns } from 'projects/patient/src/app/dropdown-proxy/dropdown-management/dropdowns/patient-dropdowns.enum';
import {
  InsuranceDTO,
  PatientDTO,
  PolicyDTO,
} from 'projects/patient/src/app/patient-proxy/patient/dto/models';
import { PatientService } from 'projects/patient/src/app/patient-proxy/patient/patient.service';
import { getPatient } from 'projects/patient/src/app/patient-store/patient.action';
import {
  billingStatus,
  OrderBillingStatus,
} from 'projects/shared/src/app/enums/allenums.enum';
import { TableService } from 'projects/shared/src/app/table.service';
import { NumberValidators } from 'projects/shared/src/app/validations/number-validator';
import { Subscription } from 'rxjs';
import { filter, switchMap } from 'rxjs/operators';
import Swal from 'sweetalert2';
import {
  OrderInsuranceDTO,
  SaleorderDTO,
} from '../order-proxy/order-management/order/dto/models';
import { getOrder, updateOrder } from '../order-store/order.action';

@Component({
  selector: 'app-order-insurance',
  templateUrl: './order-insurance.component.html',
  styleUrls: ['./order-insurance.component.scss'],
})
export class OrderInsuranceComponent implements OnInit, OnDestroy {
  insuranceForm: FormGroup;
  isDisabled: boolean;
  orderStatus: boolean = true;
  notesText ='';
  @Output() nextTabMoveOnSaveEmitter: EventEmitter<string> =
    new EventEmitter<string>(null);

  drpStateofAccident: StateOfAutoAccidentDTO[] = [];
  drpClaim: ClaimNoteTypeDTO[] = [];
  drpTypeCode: TypeCodeDTO[] = [];
  drpTrans: TransCodeDTO[] = [];
  @Input() orderId: string = '';
  @Input() patientId: string = '';
  drpStateLoop: StateOfAutoAccidentDTO[] = [];
  @Output() saveInsuranceFormState: EventEmitter<any> = new EventEmitter<any>();
  viewOrEdit: string = '';
  verificationShippingStatus: string;
  stateOfAutoAccident: string;
  claimType: string;
  typeCode: string;
  transCode: string;
  saveButtonHide: boolean;
  isShowSpinner: boolean = false;
  subscription$: Subscription[] = [];
  constructor(
    private store: Store,
    private Table: TableService,
    private orderDropdownService: OrderMasterDropdownService,
    private activatedRoute: ActivatedRoute,
    private formBuilder: FormBuilder,
    private numberValidators: NumberValidators,
    private patientDropdownService: PatientMasterDropdownService,
    private patientService: PatientService
  ) {}

  ngOnInit(): void {
    this.insuranceForm = this.formBuilder.group({
      insurancePrimary: new FormGroup({
        txtPrimaryPolicy: new FormControl(''),
        txtPrimaryPaypct: new FormControl(0, [
          Validators.min(0),
          Validators.max(100),
          this.numberValidators.isNumberCheck,
        ]),
        txtPrimaryBox10d: new FormControl(''),
        txtPrimaryBox19: new FormControl(''),
        txtPrimaryBox24Ia: new FormControl(''),
        txtPrimaryBox24Jb: new FormControl(''),
        chkPrimaryIncludeBox24jb: new FormControl(''),
        chkPrimarySaleorder: new FormControl(''),
        chkPrimaryBeforeBill: new FormControl(''),
        chkPrimaryPayPct: new FormControl(''),
        txtPrimaryBox24Ja: new FormControl(''),
      }),
      insuranceSecondary: new FormGroup({
        txtSecondaryPolicy: new FormControl(''),
        txtSecondaryPct: new FormControl(0, [
          Validators.min(0),
          Validators.max(100),
          this.numberValidators.isNumberCheck,
        ]),

        txtSecondaryBox10: new FormControl(''),
        txtSecondaryBox19: new FormControl(''),
        txtSecondaryBox24Ia: new FormControl(''),
        txtSecondaryBox24Ja: new FormControl(''),
        txtSecondaryBox24Jb: new FormControl(''),
        chkSecondaryBox24Jb: new FormControl(''),
        chkSecondarySale: new FormControl(''),
        chkSecondaryBeforeBill: new FormControl(''),
        chkSecondaryPct: new FormControl(''),
      }),
      insuranceTertiary: new FormGroup({
        txtTertiaryPolicy: new FormControl(''),
        txtTertiaryPct: new FormControl(0, [
          Validators.min(0),
          Validators.max(100),
          this.numberValidators.isNumberCheck,
        ]),

        txtTertiaryBox10: new FormControl(''),
        txtTertiaryBox19: new FormControl(''),
        txtTertiaryBox24Ia: new FormControl(''),
        txtTertiaryBox24Ja: new FormControl(''),
        txtTertiaryBox24Jb: new FormControl(''),
        chkTertiaryInclude: new FormControl(''),
        chkTertiarySale: new FormControl(''),
        chkTertiaryBeforeBill: new FormControl(''),
        chkTertiaryPayPct: new FormControl(''),
      }),
      insuranceVerification: new FormGroup({
        chkVerificationInsurance: new FormControl(''),
        chkVerificationCoverage: new FormControl(''),
        chkVerificationExclude: new FormControl(''),
      }),
      insurancePatient: new FormGroup({
        txtPatientPct: new FormControl(0, [
          Validators.min(0),
          Validators.max(100),
          this.numberValidators.isNumberCheck,
        ]),

        chkPatientSale: new FormControl(''),
        chkPatientBill: new FormControl(''),
      }),
      insuranceCompensation1: new FormGroup({
        chkCompensation1Auto: new FormControl(''),
        chkCompensation1Other: new FormControl(''),
        chkCompensation1Emp: new FormControl(''),
        txtCompensation1Onset: new FormControl(''),
        txtCompensation1State: new FormControl(''),
      }),
      insuranceCompensation2: new FormGroup({
        chkCompensation2eclaim: new FormControl(''),
        txtCompensation2Attatchment: new FormControl('', [
          this.numberValidators.isNumberCheck,
        ]),
        drpTypeCode: new FormControl(''),
        drpTrans: new FormControl(''),
        drpClaim: new FormControl(''),
        txtCompensation2Note: new FormControl(''),
      }),
    });
    if (this.viewOrEdit == 'false') {
      this.insuranceForm.disable();
    } else {
      this.insuranceForm.enable();
    }
    this.getDropdown();
    this.checkCreateEditAndGetPatientData();
    this.getOrderByid();

    if (this.insuranceForm.valid || this.insuranceForm.dirty) {
      this.saveInsuranceFormState.emit(this.insuranceForm);
    }
    this.patientId = this.patientId ?? '';
    if (this.patientId) {
      const patientGet = this.patientService.get(this.patientId).subscribe(
        (response) => {
          // this.store.dispatch(new getPatient(this.patientId)).subscribe(response => {

          if (
            response?.insuranceVerifications?.primaryInsuranceVerifies
              ?.verificationStatus == 'Done'
          ) {
            this.isDisabled = false;
          } else {
            this.isDisabled = true;
          }
        },
        (err) => {
          const data: HttpErrorResponse = err;
          Swal.fire({
            icon: 'info',
            text: data?.error?.error?.message,
          });
        }
      );
      this.subscription$.push(patientGet);
    }
  }

  ngOnDestroy(): void {
    this.subscription$?.forEach((sub) => {
      sub && sub?.unsubscribe();
    });
  }
  updateCharacterCount() {
    // Ensure the text length does not exceed the maximum length
    if (this.notesText?.length > 5000) {
      this.notesText = this.notesText?.substr(0, 5000);
    }
  }
  // clear dropdown

  clearStateOfAutoAccident() {
    this.stateOfAutoAccident = '';
    this.insuranceForm.patchValue({
      insuranceCompensation1: {
        txtCompensation1State: '',
      },
    });
  }

  clearClaimType() {
    this.claimType = '';
    this.insuranceForm.patchValue({
      insuranceCompensation2: {
        drpClaim: '',
      },
    });
  }

  clearTypeCode() {
    this.typeCode = '';
    this.insuranceForm.patchValue({
      insuranceCompensation2: {
        drpTypeCode: '',
      },
    });
  }

  clearTransCode() {
    this.transCode = '';
    this.insuranceForm.patchValue({
      insuranceCompensation2: {
        drpTrans: '',
      },
    });
  }

  checkCreateEditAndGetPatientData() {
    const activateRoute = this.activatedRoute.params
      .pipe(
        filter((value) => {
          return (
            value &&
            value.patientId !== '' &&
            value.patientId !== undefined &&
            value.patientId !== null
          );
        }),
        switchMap((value) => {
          this.patientId = value.patientId;
          return this.store.dispatch(new getPatient(this.patientId));
        })
      )
      .subscribe(
        (response) => {
          this.patchDummyData(response.patientState.selectedPatient);
        },
        (err) => {
          const data: HttpErrorResponse = err;
          Swal.fire({
            icon: 'info',
            text: data?.error?.error?.message,
          });
        }
      );
    this.subscription$.push(activateRoute);
    //
    const ActivateRoute = this.activatedRoute.params
      .pipe(
        filter((value) => {
          return (
            value &&
            value.orderId !== '' &&
            value.orderId !== undefined &&
            value.orderId !== null
          );
        }),
        switchMap((value) => {
          this.orderId = value.orderId;
          return this.store.dispatch(new getOrder(this.orderId));
        })
      )
      .subscribe(
        (response) => {
          this.patchDummyData(response.patientState.selectedPatient);
        },
        (err) => {
          const data: HttpErrorResponse = err;
          Swal.fire({
            icon: 'info',
            text: data?.error?.error?.message,
          });
        }
      );
    this.subscription$.push(ActivateRoute);
  }
  patchDummyData(patientDetail: PatientDTO) {
    let insuaranceDetail: InsuranceDTO = patientDetail?.insurances[0];
    const primaryValue =
      patientDetail?.insuranceVerifications?.primaryInsuranceVerifies;
    const secondaryValue =
      patientDetail?.insuranceVerifications?.secondaryInsuranceVerifies;
    const teritaryValue =
      patientDetail?.insuranceVerifications?.tertiaryInsuranceVerifies;
    this.insuranceForm.patchValue({
      insurancePrimary: {
        txtPrimaryPolicy: primaryValue?.planName,
        txtPrimaryPaypct: primaryValue?.payablePercent?.individual,
      },
    });
    this.insuranceForm.patchValue({
      insuranceSecondary: {
        txtSecondaryPolicy: secondaryValue?.planName,
        txtSecondaryPct: secondaryValue?.payablePercent?.individual,
      },
    });
    this.insuranceForm.patchValue({
      insuranceTertiary: {
        txtTertiaryPolicy: teritaryValue?.planName,
        txtTertiaryPct: teritaryValue?.payablePercent?.individual,
      },
    });
    //let policyDetails: PolicyDTO = patientDetail?.policies;
    this.insuranceForm.patchValue({
      insuranceCompensation1: {
        txtCompensation1Onset: insuaranceDetail?.dataOnset,
        chkCompensation1Emp: insuaranceDetail?.injuryRelatedToEmployment,
        chkCompensation1Auto: insuaranceDetail?.injuryRelatedToAutoAccident,
        txtCompensation1State: insuaranceDetail?.stateOfAutoAccident,
        chkCompensation1Other: insuaranceDetail?.injuryRelatedToOtherAccident,
      },
    });
  }
  // dropdown data //
  getDropdown() {
    let orderDropdownInput: OrderDropdowns[];
    orderDropdownInput = [
      // OrderDropdowns.StateOfAutoAccidents,
      OrderDropdowns.ClaimNoteTypes,
      OrderDropdowns.TypeCodes,
      OrderDropdowns.TransCodes,
    ];
    const orderDropdown = this.orderDropdownService
      .getOrderDropdownsByInput(orderDropdownInput)
      .subscribe(
        (stateResponse) => {
          let response: OrderMasterDropdownDTO = stateResponse;
          //this.drpStateofAccident = response.stateOfAutoAccidents;
          this.drpClaim = response.claimNoteTypes;
          this.drpTypeCode = response.typeCodes;
          this.drpTrans = response.transCodes;
        },
        (err) => {
          const data: HttpErrorResponse = err;
          Swal.fire({
            icon: 'info',
            text: data?.error?.error?.message,
          });
        }
      );
    this.subscription$.push(orderDropdown);
    //
    let patientDropdownInput: PatientDropdowns[];
    patientDropdownInput = [PatientDropdowns.StateOfAutoAccidents];
    const patientDropdown = this.patientDropdownService
      .getPatientDropdownsByInput(patientDropdownInput)
      .subscribe((stateResponse) => {
        let response: PatientMasterDropdownDTO = stateResponse;
        this.drpStateofAccident = response.stateOfAutoAccidents;
      });
    this.subscription$.push(patientDropdown);
  }
  // orderId: string = "f1c3304b-524f-eaf3-9169-39fa1b9daa37";

  getPatientDetailsForSave() {
    let patientData: PatientDTO;
    let insuranceOrderDetails: SaleorderDTO;

    const patientGet = this.patientService.get(this.patientId).subscribe(
      (response) => {
        // this.store.dispatch(new getPatient(this.patientId)).subscribe(response => {
        patientData = response;
        let updatePatientData: PatientDTO = response;
        patientData = { ...patientData };
        let insuranceData: InsuranceDTO[] = [
          {
            stateOfAutoAccident:
              this.insuranceForm.value.insuranceCompensation1
                .txtCompensation1State,
            injuryRelatedToEmployment:
              +this.insuranceForm.value.insuranceCompensation1
                .chkCompensation1Emp | 0,
            injuryRelatedToAutoAccident:
              +this.insuranceForm.value.insuranceCompensation1
                .chkCompensation1Auto | 0,
            injuryRelatedToOtherAccident:
              +this.insuranceForm.value.insuranceCompensation1
                .chkCompensation1Other | 0,
            dataOnset:
              this.insuranceForm.value.insuranceCompensation1
                .txtCompensation1Onset,
            martialStatus: patientData?.insurances[0]?.martialStatus,
            employment: patientData?.insurances[0]?.employment,
            printAmountsOnDeliveryTickets:
              patientData?.insurances[0]?.printAmountsOnDeliveryTickets,
            patientFinancialHardship:
              patientData?.insurances[0]?.patientFinancialHardship,
            discountPct: patientData?.insurances[0]?.discountPct,
            startDate: patientData?.insurances[0]?.startDate,
            reviewDate: patientData?.insurances[0]?.reviewDate,
            id: patientData.id,
          },
        ];
        updatePatientData = {
          insurances: insuranceData,
          personals: updatePatientData.personals,
          contacts: updatePatientData.contacts,
          clinicals: updatePatientData.clinicals,
          addresses: updatePatientData.addresses,
          notes: updatePatientData.notes,
          policies: updatePatientData.policies,
          patientDocuments: updatePatientData.patientDocuments,
          tenantId: updatePatientData.tenantId,
          insuranceVerifications: updatePatientData.insuranceVerifications,
          authorizations: updatePatientData.authorizations,
          organizationUnitId: updatePatientData.organizationUnitId,
          isAutoIntake: updatePatientData.isAutoIntake,
          autoIntakeDocumentDetails:
            updatePatientData.autoIntakeDocumentDetails,
          chartId: updatePatientData.chartId,
          billingDetails: updatePatientData.billingDetails,
          patientLock: updatePatientData.patientLock,
          // isSorted:updatePatientData.isSorted,
          isSorted: updatePatientData.isSorted,
          fileId: updatePatientData?.fileId,
        };
        setTimeout(() => {
          // this.store.dispatch(new updatePatient(this.patientId, updatePatientData)).subscribe(response => {
          //
          patientData = response;
          patientData = { ...patientData };
          setTimeout(() => {
            this.store.dispatch(new getOrder(this.orderId)).subscribe(
              (orderResponse) => {
                insuranceOrderDetails = {
                  ...orderResponse.orderState.selectedOrder,
                };
                this.saveInsurance(insuranceOrderDetails, patientData);
              },
              (err) => {
                const data: HttpErrorResponse = err;
                Swal.fire({
                  icon: 'info',
                  text: data?.error?.error?.message,
                });
              }
            );
          }, 5);
          // }, err => {
          //   const data: HttpErrorResponse = err;
          //   Swal.fire({
          //     icon: 'error',
          //     text: data?.error?.error?.message,
          //   });
          // })
        }, 200);
      },
      (err) => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      }
    );
    this.subscription$.push(patientGet);
  }

  organizationUnitId = null;

  // save insurance //
  saveInsurance(response: SaleorderDTO, patientData: PatientDTO) {
    this.isShowSpinner = true;
    this.saveButtonHide = true;
    // this.organizationUnitId = localStorage.getItem('initialOrganizationUnitId');

    let insuranceDetails;
    insuranceDetails = {
      orders: response.orders,
      insurances: [
        {
          primaryPolicyId: this.insuranceForm.get(
            'insurancePrimary.txtPrimaryPolicy'
          )?.value,
          primaryPayPct:
            +this.insuranceForm?.get('insurancePrimary.txtPrimaryPaypct')
              ?.value | 0,
          primaryBox10d: this.insuranceForm.get(
            'insurancePrimary.txtPrimaryBox10d'
          )?.value,
          primaryBox19: this.insuranceForm.get(
            'insurancePrimary.txtPrimaryBox19'
          )?.value,
          primaryBox24la: this.insuranceForm.get(
            'insurancePrimary.txtPrimaryBox24Ia'
          )?.value,
          primaryBox24ja: this.insuranceForm.get(
            'insurancePrimary.txtPrimaryBox24Ja'
          )?.value,
          primaryBox24jb: this.insuranceForm.get(
            'insurancePrimary.txtPrimaryBox24Jb'
          )?.value,
          isPrimaryIncludeBox24jb:
            +this.insuranceForm?.get('insurancePrimary.chkPrimaryIncludeBox24jb')
              ?.value | 0,
          isPrimaryIncludePayorLevel:
            +this.insuranceForm?.get('insurancePrimary.chkPrimarySaleorder')
              ?.value | 0,
          isPrimaryWaitForPreviousPayor:
            +this.insuranceForm?.get('insurancePrimary.chkPrimaryBeforeBill')
              ?.value | 0,
          isPrimaryPayPct:
            +this.insuranceForm?.get('insurancePrimary.chkPrimaryPayPct')
              ?.value | 0,
          isInsuranceVerified:
            +this.insuranceForm.get(
              'insuranceVerification.chkVerificationInsurance'
            )?.value | 0,
          isCoverageVerified:
            +this.insuranceForm.get(
              'insuranceVerification.chkVerificationCoverage'
            )?.value | 0,
          isExcludeFromEligibiltyCheck:
            +this.insuranceForm.get(
              'insuranceVerification.chkVerificationExclude'
            )?.value | 0,
          patientPayPct:
            +this.insuranceForm?.get('insurancePatient.txtPatientPct')?.value |
            0,
          isPatientIncludePayorLevel:
            +this.insuranceForm?.get('insurancePatient.chkPatientSale')?.value |
            0,
          isPatientWaitForPreviousPayor:
            +this.insuranceForm?.get('insurancePatient.chkPatientBill')?.value |
            0,
          secondaryPolicyId: this.insuranceForm.get(
            'insuranceSecondary.txtSecondaryPolicy'
          )?.value,
          secondaryPayPct:
            +this.insuranceForm?.get('insuranceSecondary.txtSecondaryPct')
              ?.value | 0,
          secondaryBox10d: this.insuranceForm.get(
            'insuranceSecondary.txtSecondaryBox10'
          )?.value,
          secondaryBox19: this.insuranceForm.get(
            'insuranceSecondary.txtSecondaryBox19'
          )?.value,
          secondaryBox24la: this.insuranceForm.get(
            'insuranceSecondary.txtSecondaryBox24Ia'
          )?.value,
          secondaryBox24ja: this.insuranceForm.get(
            'insuranceSecondary.txtSecondaryBox24Ja'
          )?.value,
          secondaryBox24jb: this.insuranceForm.get(
            'insuranceSecondary.txtSecondaryBox24Jb'
          )?.value,
          isSecondaryIncludeFor24jb:
            +this.insuranceForm?.get('insuranceSecondary.chkSecondaryBox24Jb')
              ?.value | 0,
          isSecondaryIncludePayorLevel:
            +this.insuranceForm?.get('insuranceSecondary.chkSecondarySale')
              ?.value | 0,
          isSecondaryWaitForPreviousPayor:
            +this.insuranceForm?.get('insuranceSecondary.chkSecondaryBeforeBill')
              ?.value | 0,
          isSecondaryPayPct:
            +this.insuranceForm?.get('insuranceSecondary.chkSecondaryPct')
              ?.value | 0,
          dateOfOnset: this.insuranceForm.get(
            'insuranceCompensation1.txtCompensation1Onset'
          )?.value,
          isInjuryRelatedToEmployment:
            +this.insuranceForm.get(
              'insuranceCompensation1.chkCompensation1Emp'
            )?.value | 0,
          isInjuryRelatedToAutoAccident:
            +this.insuranceForm.get(
              'insuranceCompensation1.chkCompensation1Auto'
            )?.value | 0,
          stateOfAccidentId: this.insuranceForm.get(
            'insuranceCompensation1.txtCompensation1State'
          )?.value,
          isInjuryRelatedToOtherAccident:
            +this.insuranceForm.get(
              'insuranceCompensation1.chkCompensation1Other'
            )?.value | 0,
          isIncludeEClaimsAttachment:
            +this.insuranceForm.get(
              'insuranceCompensation2.chkCompensation2eclaim'
            )?.value | 0,
          attachmentNumber: this.insuranceForm.get(
            'insuranceCompensation2.txtCompensation2Attatchment'
          )?.value,
          typeCodeId: this.insuranceForm.get(
            'insuranceCompensation2.drpTypeCode'
          )?.value,
          transCodeId: this.insuranceForm?.get('insuranceCompensation2.drpTrans')
            ?.value,
          claimNoteTypeId: this.insuranceForm.get(
            'insuranceCompensation2.drpClaim'
          )?.value,
          note: this.insuranceForm.get(
            'insuranceCompensation2.txtCompensation2Note'
          )?.value,
          tertiaryPolicyId: this.insuranceForm.get(
            'insuranceTertiary.txtTertiaryPolicy'
          )?.value,
          tertiaryPayPct:
            this.insuranceForm?.get('insuranceTertiary.txtTertiaryPct')?.value |
            0,
          tertiaryBox10d: this.insuranceForm.get(
            'insuranceTertiary.txtTertiaryBox10'
          )?.value,
          tertiaryBox19: this.insuranceForm.get(
            'insuranceTertiary.txtTertiaryBox19'
          )?.value,
          tertiaryBox24la: this.insuranceForm.get(
            'insuranceTertiary.txtTertiaryBox24Ia'
          )?.value,
          tertiaryBox24ja: this.insuranceForm.get(
            'insuranceTertiary.txtTertiaryBox24Ja'
          )?.value,
          tertiaryBox24jb: this.insuranceForm.get(
            'insuranceTertiary.txtTertiaryBox24Jb'
          )?.value,
          isTertiaryIncludeFor24jb:
            this.insuranceForm?.get('insuranceTertiary.chkTertiaryInclude')
              ?.value | 0,
          isTertiaryIncludePayorLevel:
            this.insuranceForm?.get('insuranceTertiary.chkTertiarySale')?.value |
            0,
          isTertiaryWaitForPreviousPayor:
            this.insuranceForm?.get('insuranceTertiary.chkTertiaryBeforeBill')
              ?.value | 0,
          isTertiaryPayPct:
            this.insuranceForm?.get('insuranceTertiary.chkTertiaryPayPct')
              ?.value | 0,
        },
      ],
      defaultClaimId: response?.defaultClaimId,
      clinicals: response.clinicals,
      items: response.items,
      orderStatus: response.orderStatus,
      saleorderId: response.saleorderId,
      organizationUnitId: response.organizationUnitId,
      currentStage: response.currentStage,
      currentStageStatus: response.currentStageStatus,
      patients: patientData,
      claimId: response.claimId,
      patientId: this.patientId,
      isShippingApprove: response.isShippingApprove,
      isCashPayment: response.isCashPayment,
      orderProcessStatus: response.orderProcessStatus,
      patientLedgerReason: response.patientLedgerReason,
      documentReason: response.documentReason,
      paymentTransactionDetails: response?.paymentTransactionDetails,
      payPalMailResendCount: response?.payPalMailResendCount,
      payPalPaymentMethodType: response?.payPalPaymentMethodType,
      payPalPaymentStatus: response?.payPalPaymentStatus,
      claimHistoryStatus: response.claimHistoryStatus,
      patientLedgerStatus: response.patientLedgerStatus,

      // saleorderId:response.saleorderId
    };
    this.orderId != '' &&
      this.store
        .dispatch(new updateOrder(this.orderId, insuranceDetails))
        .subscribe(
          (response) => {
            let selectedOrder: SaleorderDTO =
              response?.orderState?.selectedOrder;
            this.store
              .dispatch(new getPatient(selectedOrder?.patientId ?? ''))
              .subscribe((response) => {
                let patient: PatientDTO =
                  response?.patientState.selectedPatient;

                let updatePatientPrimaryPolicydata: PolicyDTO =
                  response?.patientState.selectedPatient?.policies
                    ?.primaryPolicy;
                let updatePatientSecondaryPolicydata: PolicyDTO =
                  response?.patientState.selectedPatient?.policies
                    ?.secondaryPolicy;
                let updatePatientTertiaryPolicydata: PolicyDTO =
                  response?.patientState.selectedPatient?.policies
                    ?.tertiaryPolicy;
                if (selectedOrder?.insurances && selectedOrder?.insurances[0]) {
                  updatePatientPrimaryPolicydata = {
                    ...updatePatientPrimaryPolicydata,
                    box10d: selectedOrder?.insurances[0]?.primaryBox10d,
                    box19: selectedOrder?.insurances[0]?.primaryBox19,
                  };
                  if (updatePatientSecondaryPolicydata != null) {
                    updatePatientSecondaryPolicydata = {
                      ...updatePatientSecondaryPolicydata,
                      box10d: selectedOrder?.insurances[0]?.secondaryBox10d,
                      box19: selectedOrder?.insurances[0]?.secondaryBox19,
                    };
                  }

                  if (updatePatientTertiaryPolicydata != null) {
                    updatePatientTertiaryPolicydata = {
                      ...updatePatientTertiaryPolicydata,
                      box10d: selectedOrder?.insurances[0]?.tertiaryBox10d,
                      box19: selectedOrder?.insurances[0]?.tertiaryBox19,
                    };
                  }
                }

                patient = {
                  id: patient?.id,
                  personals: patient?.personals,
                  addresses: patient?.addresses,
                  contacts: patient?.contacts,
                  clinicals: patient?.clinicals,
                  insurances: patient?.insurances,
                  notes: patient?.notes,
                  policies: {
                    primaryPolicy: updatePatientPrimaryPolicydata,
                    secondaryPolicy: updatePatientSecondaryPolicydata,
                    tertiaryPolicy: updatePatientTertiaryPolicydata,
                  },
                  patientDocuments: patient?.patientDocuments,
                  defaultPatientId: patient?.defaultPatientId,
                  insuranceVerifications: patient?.insuranceVerifications,
                  authorizations: patient?.authorizations,
                  organizationUnitId: this.organizationUnitId ?? '',
                  tenantId: patient?.tenantId,
                  isAutoIntake: patient?.isAutoIntake,
                  autoIntakeDocumentDetails: patient?.autoIntakeDocumentDetails,
                  chartId: patient.chartId,
                  billingDetails: patient.billingDetails,
                  patientLock: patient.patientLock,
                  // isSorted:patient.isSorted,
                  isSorted: patient.isSorted,
                  fileId: patient?.fileId,
                };
                //Update saleorder insurance primary,secondary,tertiary common datas into patient policy - primary,secondary,tertiary datas
                this.orderId != '' &&
                  this.store
                    .dispatch(new updateOrder(this.orderId, insuranceDetails))
                    .subscribe((response) => {
                      Swal.fire({
                        title: 'Success',
                        html: 'Saved Successfully',
                        icon: 'success',
                        timer: 3000,
                        timerProgressBar: true,
                      }).then(
                        (result) => {
                          if (result.value) {
                            //!change next tab
                            this.nextTabMoveOnSaveEmitter.emit('Insurance');
                          }
                          this.saveButtonHide = false;
                          this.isShowSpinner = false;
                        },
                        (err) => {
                          this.saveButtonHide = false;
                          this.isShowSpinner = false;
                          const data: HttpErrorResponse = err;
                          Swal.fire({
                            icon: 'info',
                            text: data?.error?.error?.message,
                          });
                        }
                      );
                      this.saveInsuranceFormState.emit(
                        this.insuranceForm.value
                      );
                    });
              });
            this.isShowSpinner = false;
          },
          (err) => {
            this.isShowSpinner = false;
            const data: HttpErrorResponse = err;
            Swal.fire({
              icon: 'info',
              text: data?.error?.error?.message,
            });
          }
        );
  }

  // insurance view and edit //
  getOrderByid() {
    if (
      this.orderId != '' &&
      this.orderId != null &&
      this.orderId != undefined
    ) {
      const orderGet = this.store
        .dispatch(new getOrder(this.orderId))
        .subscribe(
          (response) => {
            let selectedOrder: SaleorderDTO =
              response?.orderState?.selectedOrder;
            //   this.orderStatus = (selectedOrder?.orderBilling?.status === billingStatus.ReadyToBilling || selectedOrder?.orderBilling?.status === billingStatus.OrderPending ) ? true : false;
            const orderBillstatus: [string, OrderBillingStatus][] =
              Object.entries(OrderBillingStatus);
            this.orderStatus = orderBillstatus?.some(
              (s) => (s[1] ?? '') === selectedOrder?.orderBilling?.status
            );
            if (selectedOrder?.insurances && selectedOrder?.insurances[0]) {
              let insuaranceDetail: OrderInsuranceDTO =
                selectedOrder?.insurances[0];

              insuaranceDetail != null &&
                insuaranceDetail != undefined &&
                this.insuranceForm.patchValue({
                  insurancePrimary: {
                    txtPrimaryPolicy: insuaranceDetail.primaryPolicyId,
                    txtPrimaryPaypct: insuaranceDetail.primaryPayPct,
                    txtPrimaryBox10d: insuaranceDetail.primaryBox10d,
                    txtPrimaryBox19: insuaranceDetail.primaryBox19,
                    txtPrimaryBox24Ia: insuaranceDetail.primaryBox24la,
                    txtPrimaryBox24Ja: insuaranceDetail.primaryBox24ja,
                    txtPrimaryBox24Jb: insuaranceDetail.primaryBox24jb,
                    chkPrimaryIncludeBox24jb:
                      insuaranceDetail.isPrimaryIncludeBox24jb,
                    chkPrimarySaleorder:
                      insuaranceDetail.isPrimaryIncludePayorLevel,
                    chkPrimaryBeforeBill:
                      insuaranceDetail.isPrimaryWaitForPreviousPayor,
                    chkPrimaryPayPct: insuaranceDetail.isPrimaryPayPct,
                  },

                  insuranceSecondary: {
                    txtSecondaryPolicy: insuaranceDetail.secondaryPolicyId,
                    txtSecondaryPct: insuaranceDetail.secondaryPayPct,
                    txtSecondaryBox10: insuaranceDetail.secondaryBox10d,
                    txtSecondaryBox19: insuaranceDetail.secondaryBox19,
                    txtSecondaryBox24Ia: insuaranceDetail.secondaryBox24la,
                    txtSecondaryBox24Ja: insuaranceDetail.secondaryBox24ja,
                    txtSecondaryBox24Jb: insuaranceDetail.secondaryBox24jb,
                    chkSecondaryBox24Jb:
                      insuaranceDetail.isSecondaryIncludeFor24jb,
                    chkSecondarySale:
                      insuaranceDetail.isSecondaryIncludePayorLevel,
                    chkSecondaryBeforeBill:
                      insuaranceDetail.isSecondaryWaitForPreviousPayor,
                    chkSecondaryPct: insuaranceDetail.isSecondaryPayPct,
                  },
                  insuranceTertiary: {
                    txtTertiaryPolicy: insuaranceDetail.tertiaryPolicyId,
                    txtTertiaryPct: insuaranceDetail.tertiaryPayPct,
                    txtTertiaryBox10: insuaranceDetail.tertiaryBox10d,
                    txtTertiaryBox19: insuaranceDetail.tertiaryBox19,
                    txtTertiaryBox24Ia: insuaranceDetail.tertiaryBox24la,
                    txtTertiaryBox24Ja: insuaranceDetail.tertiaryBox24ja,
                    txtTertiaryBox24Jb: insuaranceDetail.tertiaryBox24jb,
                    chkTertiaryInclude:
                      insuaranceDetail.isTertiaryIncludeFor24jb,
                    chkTertiarySale:
                      insuaranceDetail.isTertiaryIncludePayorLevel,
                    chkTertiaryBeforeBill:
                      insuaranceDetail.isTertiaryWaitForPreviousPayor,
                    chkTertiaryPayPct: insuaranceDetail.isTertiaryPayPct,
                  },
                  insuranceVerification: {
                    chkVerificationInsurance:
                      insuaranceDetail.isInsuranceVerified,
                    chkVerificationCoverage:
                      insuaranceDetail.isCoverageVerified,
                    chkVerificationExclude:
                      insuaranceDetail.isExcludeFromEligibiltyCheck,
                  },
                  insurancePatient: {
                    txtPatientPct: insuaranceDetail.patientPayPct,
                    chkPatientSale: insuaranceDetail.isPatientIncludePayorLevel,
                    chkPatientBill:
                      insuaranceDetail.isPatientWaitForPreviousPayor,
                  },
                  insuranceCompensation1: {
                    txtCompensation1Onset: insuaranceDetail.dateOfOnset,
                    chkCompensation1Emp:
                      insuaranceDetail.isInjuryRelatedToEmployment,
                    chkCompensation1Auto:
                      insuaranceDetail.isInjuryRelatedToAutoAccident,
                    txtCompensation1State: insuaranceDetail.stateOfAccidentId,
                    chkCompensation1Other:
                      insuaranceDetail.isInjuryRelatedToOtherAccident,
                  },
                  insuranceCompensation2: {
                    chkCompensation2eclaim:
                      insuaranceDetail.isIncludeEClaimsAttachment,
                    txtCompensation2Attatchment:
                      insuaranceDetail.attachmentNumber,
                    drpTypeCode: insuaranceDetail.typeCodeId,
                    drpTrans: insuaranceDetail.transCodeId,
                    drpClaim: insuaranceDetail.claimNoteTypeId,
                    txtCompensation2Note: insuaranceDetail.note,
                  },
                });
            }
          },
          (err) => {
            const data: HttpErrorResponse = err;
            Swal.fire({
              icon: 'info',
              text: data?.error?.error?.message,
            });
          }
        );
      this.subscription$.push(orderGet);
    }
  }
}
