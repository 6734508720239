import type { CreateUpdateProductBrandDTO, ProductBrandDTO, ProductBrandSearchDTO } from './dto/models';
import { RestService } from '@abp/ng.core';
import type { PagedAndSortedResultRequestDto, PagedResultDto } from '@abp/ng.core';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ProductBrandService {
  apiName = 'ItemManagement';

  create = (input: CreateUpdateProductBrandDTO) =>
    this.restService.request<any, ProductBrandDTO>({
      method: 'POST',
      url: '/api/ItemApp/product-brand',
      body: input,
    },
     { apiName: this.apiName, skipHandleError: true });

  delete = (id: string) =>
    this.restService.request<any, void>({
      method: 'DELETE',
      url: `/api/ItemApp/product-brand/${id}`,
    },
     { apiName: this.apiName, skipHandleError: true });

  get = (id: string) =>
    this.restService.request<any, ProductBrandDTO>({
      method: 'GET',
      url: `/api/ItemApp/product-brand/${id}`,
    },
     { apiName: this.apiName, skipHandleError: true });

  getList = (input: PagedAndSortedResultRequestDto) =>
    this.restService.request<any, PagedResultDto<ProductBrandDTO>>({
      method: 'GET',
      url: '/api/ItemApp/product-brand',
      params: { skipCount: input.skipCount, maxResultCount: input.maxResultCount, sorting: input.sorting },
    },
     { apiName: this.apiName, skipHandleError: true });

  update = (id: string, input: CreateUpdateProductBrandDTO) =>
    this.restService.request<any, ProductBrandDTO>({
      method: 'PUT',
      url: `/api/ItemApp/product-brand/${id}`,
      body: input,
    },
     { apiName: this.apiName, skipHandleError: true });

  searchProductBrandByInput = (input: ProductBrandSearchDTO) =>
    this.restService.request<any, PagedResultDto<ProductBrandDTO>>({
      method: 'GET',
      url: '/api/ItemApp/product-brand/search-product-brand',
      params: { productBrandName: input.productBrandName, status: input.status, createdDate: input.createdDate },
    },
     { apiName: this.apiName, skipHandleError: true });

  constructor(private restService: RestService) {}
}
