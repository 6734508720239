<div class="container-fluid">
  <div class="row">
    <div class="col-12">
      <div class="d-flex align-items-center justify-content-between my-2">
        <h3 class="font-weight-bolder mb-0">
          <b class="customThemeClass">MIS Reports / Patient Statement</b>
        </h3>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-12">
      <div class="card card-body curved-card">
        <form [formGroup]="patientStatementForm">
          <!-- <div class="col-6">
            <mat-checkbox formControlName="chkDontSendStatement">
              <p class="mb-0 mt-1 pb-1 dd-text">
                Don't send if sent a statement within days
              </p>
            </mat-checkbox>
            <mat-form-field class="ml-2" matSuffix>
              <input
                matInput
                maxlength="40"
                formControlName="txtDontSendStatement"
                type="text"
                autocomplete="new-txtDontSendStatement"
            /></mat-form-field>
          </div> -->
          <p class="mb-0 header-text">Patient Details</p>
          <!-- Patient Name -->
          <mat-form-field class="col-4 mt-1 pl-1">
            <mat-label>Patient Name</mat-label>
            <mat-select
              (selectionChange)="onPatientChange($event)"
              formControlName="drpPatientName"
              #patientSelect
            >
              <mat-option>
                <ngx-mat-select-search
                  (keyup)="
                    searchPatient(
                      $event,
                      $event.target.value,
                      patientSelect.value
                    )
                  "
                  placeholderLabel="Search"
                  noEntriesFoundLabel="No Matches found"
                  formControlName="txtPatientNameFilter"
                ></ngx-mat-select-search>
              </mat-option>
              <mat-option
                *ngFor="let chart of lstChartNos"
                [value]="chart.patientId"
              >
                {{ chart.ddValue }}
              </mat-option>
            </mat-select>
            <button
              mat-button
              *ngIf="
                patientStatementForm.get('drpPatientName').value != '' &&
                patientStatementForm.get('drpPatientName').value != null &&
                patientStatementForm.get('drpPatientName').value != undefined
              "
              (click)="clearForm(); $event.stopPropagation()"
              matSuffix
              mat-icon-button
            >
              <mat-icon>close</mat-icon>
            </button>
          </mat-form-field>
          <!-- Practices -->
          <mat-form-field class="col-4">
            <mat-label>Practice</mat-label>
            <mat-select formControlName="drpPractices">
              <mat-option>
                <ngx-mat-select-search
                  placeholderLabel="Search"
                  noEntriesFoundLabel="No Matches found"
                  hideClearSearchButton="true"
                  (keyup)="practiceSearch($event.target.value)"
                  formControlName="txtPracticeFilter"
                ></ngx-mat-select-search>
              </mat-option>
              <mat-option
                *ngFor="let practice of filteredPractices | async"
                [value]="practice.id"
              >
                {{ practice.practiceName }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <!-- Patient Location -->
          <mat-form-field class="col-4">
            <mat-label>Patient Location</mat-label>
            <mat-select formControlName="drpPatientLocation">
              <mat-option>
                <ngx-mat-select-search
                  placeholderLabel="Search"
                  noEntriesFoundLabel="No Matches found"
                  formControlName="txtLocationFilter"
                ></ngx-mat-select-search
              ></mat-option>
              <mat-option
                [value]="data.id"
                *ngFor="let data of filteredLocationDetails | async"
              >
                {{ data.organizationUnitName }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <!-- Insurance Category -->
          <mat-form-field class="col-4 pl-1">
            <mat-label>Insurance Category</mat-label>
            <mat-select formControlName="drpInsuranceCategory">
              <mat-option>
                <ngx-mat-select-search
                  placeholderLabel="Search"
                  noEntriesFoundLabel="No Matches found"
                  formControlName="txtInsuranceCategoryFilter"
                ></ngx-mat-select-search
              ></mat-option>
              <mat-option
                [value]="data.id"
                *ngFor="let data of filteredInsuranceCategory | async"
              >
                {{ data.insuranceCategory }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <!-- Order by -->
          <mat-form-field class="col-4">
            <mat-label>Order By</mat-label>
            <mat-select formControlName="drpOrderBy">
              <mat-option>
                <ngx-mat-select-search
                  placeholderLabel="Search"
                  noEntriesFoundLabel="No Matches found"
                  formControlName="txtOrderByFilter"
                ></ngx-mat-select-search>
              </mat-option>
              <mat-option [value]="1"> Chart No </mat-option>
              <mat-option [value]="2"> Patient Name </mat-option>
              <mat-option [value]="3"> Zip Code </mat-option>
            </mat-select>
          </mat-form-field>
          <!-- Pre Collection Status -->
          <mat-form-field class="col-4" [matTooltip]="'Not in Use'">
            <mat-label>Pre Collection Status</mat-label>
            <mat-select formControlName="drpPreCollectionStatus" disabled>
              <mat-option>
                <ngx-mat-select-search
                  placeholderLabel="Search"
                  noEntriesFoundLabel="No Matches found"
                  formControlName="txtPreCollectionStatusFilter"
                ></ngx-mat-select-search>
              </mat-option>
              <mat-option> Test </mat-option>
            </mat-select>
          </mat-form-field>

          <!-- Balance Criteria -->
          <p class="mb-0 header-text">Balance Criteria</p>
          <!-- Total Balance -->
          <div class="row py-1">
            <!-- Total Balance -->
            <mat-radio-group
              (change)="balanceCriteriaChange()"
              class="pl-1 mt-2 dd-text col-12"
              formControlName="rdbtnTotalBalance"
            >
              <mat-radio-button class="col-4" [value]="1">
                Print a statement for accounts with a total balance greater than
                0
              </mat-radio-button>
              <mat-radio-button [value]="2" class="col-8">
                Only print a statement for accounts with a patient balance.Don't
                send a statement for balance less than
                <mat-form-field class="ml-2 custom-amnt-width" matSuffix>
                  <mat-label>Amount($)</mat-label>
                  <input
                    (keypress)="numbersAndfloatOnly($event)"
                    (paste)="numbersAndfloatOnly($event)"
                    (click)="$event.stopPropagation()"
                    formControlName="txtBalanceLessThan"
                    matInput
                    maxlength="8"
                    placeholder="Amount($)"
                  />
                </mat-form-field>
              </mat-radio-button>
            </mat-radio-group>
          </div>

          <!-- Display Options-->
          <p class="mb-0 header-text">Display Options</p>

          <div class="row mt-1 pl-1 d-flex align-items-center">
            <!-- Payment Color -->
            <mat-form-field class="col-3">
              <mat-label>Payment Color</mat-label>

              <input
                matInput
                [ngxMatColorPicker]="picker"
                formControlName="txtPaymentColor"
                [disabled]="false"
                readonly
                autocomplete="new-colorCtr"
              />
              <ngx-mat-color-toggle
                matSuffix
                [for]="picker"
              ></ngx-mat-color-toggle>
              <ngx-mat-color-picker
                #picker
                [touchUi]="touchUi"
                [matTooltip]="'Click to select the Color'"
                [color]="paymentColor"
              ></ngx-mat-color-picker>
              <!-- <mat-error
      *ngIf=" mstColorCodesForm?.get('colorCtr')?.touched && mstColorCodesForm?.get('colorCtr')?.errors?.required ">
      Enter Valid Color Code!
  </mat-error> -->
            </mat-form-field>

            <!-- Note Color -->
            <mat-form-field class="col-3">
              <mat-label>Note Color</mat-label>

              <input
                matInput
                readonly
                [ngxMatColorPicker]="picker2"
                formControlName="txtNoteColor"
                [disabled]="false"
                autocomplete="new-colorCtr"
              />
              <ngx-mat-color-toggle

                matSuffix
                [for]="picker2"
              ></ngx-mat-color-toggle>
              <ngx-mat-color-picker
                #picker2
                [matTooltip]="'Click to select the Color'"
                [touchUi]="touchUi"
                [color]="noteColor"
              ></ngx-mat-color-picker>
            </mat-form-field>

            <!-- Payment Due in Days -->
            <mat-form-field class="col-3">
              <mat-label>Payment due in Days</mat-label>
              <input
                maxlength="4"
                (keypress)="onKeyPress($event)"
                (paste)="onKeyPress($event)"
                matInput
                formControlName="txtPaymentDueinDays"
              />
            </mat-form-field>

            <!-- Show Credit Card Payment Options -->
            <div class="col-3">
              <mat-checkbox formControlName="chkShowCreditCardPaymentOptions">
                <p class="mb-0 mt-1 dd-text">
                  Show Credit card payment options
                </p>
              </mat-checkbox>
            </div>
          </div>

          <div class="row pl-1 d-flex align-items-center mt-2">
            <!-- Show Patient's Provider Only -->
            <div class="col-3">
              <mat-checkbox formControlName="chkShowPatientsProviderOnly">
                <p class="mb-0 mt-1 dd-text">Show Patient's Provider Only</p>
              </mat-checkbox>
            </div>

            <!-- Show the Insurance Pending Total Amount -->
            <div class="col-3">
              <mat-checkbox
                formControlName="chkShowInsurancePendingTotalAmount"
              >
                <p class="mb-0 mt-1 dd-text">
                  Show the Insurance Pending Total Amount
                </p>
              </mat-checkbox>
            </div>

            <!-- Show Patient payments since statement-->
            <div class="col-3">
              <mat-checkbox
                formControlName="chkShowPatientPaymentsSinceStatement"
              >
                <p class="mb-0 mt-1 dd-text">
                  Show Patient payments since statement
                </p>
              </mat-checkbox>
            </div>

            <!-- Show Patient payments for last days-->
            <div class="col-3">
              <mat-checkbox
                (change)="paymentChecked()"
                formControlName="chkShowPatientPaymentsForLastDays"
              >
                <div
                  class="row pl-3 d-flex justify-content-center align-items-center"
                >
                  <p class="mb-0 mt-1 dd-text">
                    Show Patient Payments for last days
                  </p>
                  <mat-form-field class="ml-2 custom-amnt-width" matSuffix>
                    <mat-label>Days</mat-label>
                    <!-- autocomplete="new-txtPaymentForLastDays" -->
                    <input
                    autocomplete="off"
                      maxlength="4"
                      (keypress)="onKeyPress($event)"
                      (click)="$event.stopPropagation()"
                      formControlName="txtPaymentForLastDays"
                      matInput
                      placeholder="Days"
                    />
                  </mat-form-field>
                </div>
              </mat-checkbox>
            </div>
          </div>

          <div class="row pl-1 d-flex align-items-center">
            <!-- Include all open line items -->
            <div class="col-3">
              <mat-checkbox formControlName="chkIncludeAllOpenLineItems">
                <p class="mb-0 mt-1 dd-text">Include all open line items</p>
              </mat-checkbox>
            </div>
            <!-- Dont show Charges -->
            <div class="col-3">
              <mat-checkbox formControlName="chkDontShowCharges">
                <p class="mb-0 mt-1 dd-text">Dont show Charges</p>
              </mat-checkbox>
            </div>
            <!-- Credit Card Note -->
            <mat-form-field class="col-6">
              <mat-label>Credit Card Note</mat-label>
              <input autocomplete="off" matInput formControlName="txtCreditCardNote" />
            </mat-form-field>
          </div>

          <!-- Buttons -->
          <div class="row mt-2">
            <div
              class="col-12 d-flex align-items-center justify-content-center"
            >
              <button
                (click)="getPatientStatementReport(false)"
                mat-raised-button
                class="buttonColor mr-2 mat-raised-small-button"
              >
                Search
              </button>
              <button
                (click)="resetForm()"
                mat-raised-button
                class="resetclr mat-raised-small-button"
              >
                Reset
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>

  <div class="row mt-2">
    <div class="col-12">
      <div class="card card-body curved-card mb-3">
        <div class="d-flex justify-content-center" *ngIf="!isLoaded">
          <img
            class="img-responsive pb-2 mr-1"
            src="assets/gifs/Infinity-loader.gif"
          />
        </div>
        <div *ngIf="isLoaded" class="table table-responsive">
          <app-patient-statement-table
            [patientStatementTable]="ltPatientStatementReport"
          ></app-patient-statement-table>
        </div>
      </div>
    </div>
  </div>
</div>
