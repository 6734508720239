<br />
<div class="card card-body">
<!-- <div class="list-box"> -->
<div class="row">
  <div class="col-lg-12 mar-bottom">
    <h4 class="customThemeClass"><b>Order Status</b></h4>
    <!-- <mat-card> -->
      <div class="row">
        <div class="col-2">
          <span><b>Information Status :</b></span>
        </div>
        <span class="col-2">Yet To Start <span class="dot grey-dot"></span></span>
        <span class="col-2">Inprocess <span class="dot blue-dot"></span> </span>
        <span class="col-2">Pending <span class="dot yellow-dot"></span></span>
        <span class="col-2">Completed <span class="dot green-dot"></span></span>
        <span class="col-2">Rejected <span class="dot salmon-dot"></span></span>
      </div>
    <!-- </mat-card> -->
    <br />
  </div>
  <div class="col-lg-12 table-responsive">
    <app-table [strPageType]="strPageType"  [isLoading]="isLoading" (viewButton)="existingOrders($event)"></app-table>
  </div>
</div>
<!-- </div> -->
</div>
