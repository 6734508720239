import { PagedAndSortedResultRequestDto } from '@abp/ng.core';
import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { VendorDetailsService } from '../item-proxy/item-management/optimization';
import { RequisitionService } from '../item-proxy/item-management/optimization/requisition.service';
import {
  requsitionsforPurchaseDto,
} from '../item-proxy/item-management/optimization/dto';

import { CommunicationService } from 'projects/shared/src/app/services/communication.service';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-purchase-order-tab',
  templateUrl: './purchase-order-tab.component.html',
  styleUrls: ['./purchase-order-tab.component.scss'],
})
export class PurchaseOrderTabComponent implements OnInit {
  activeTab: string = 'Summary';
  ltPurchaseOrders: requsitionsforPurchaseDto[] = [];
  ltSummaryData: any[] = [];
  isPOTableLoaded: boolean = true;
  isSummaryTableLoaded: boolean = true;
  POForm: FormGroup;
  ltVendors: any;
  filteredVendorDetails: Observable<string[]> | undefined;
  subscription$: Subscription[] = [];
  constructor(
    private requisitionService: RequisitionService,
    private communicationService: CommunicationService,
    private fb: FormBuilder,
    private vendorService: VendorDetailsService,
    private title : Title
  ) {
    const comService =
      this.communicationService.functionLoadPurchaseOrderSummary$.subscribe(
        (manufacturerIds) => {
          this.POForm.patchValue({ drpManufacturer: manufacturerIds });
          this.changeTab('PurchaseOrder');
          // this.getPurchaseOrders();
        }
      );
    this.subscription$.push(comService);

    const POList =
      this.communicationService.functionLoadPurchaseOrderList$.subscribe(
        (manufacturerIds) => {
          // this.POForm.patchValue({ drpManufacturer: manufacturerIds });
          // this.changeTab('PurchaseOrder');
          this.getPurchaseOrders();
        }
      );
    this.subscription$.push(comService);
  }

  ngOnInit() {
    this.title.setTitle('Qsecure | Purchase Order');
    this.initializeForms();
    this.loadDropdowns();
    this.getPurchaseOrderSummary();
  }
  loadDropdowns() {
    const vendorDetails = this.vendorService
      .getActiveVendorList(new PagedAndSortedResultRequestDto())
      .subscribe(
        (response) => {
          this.ltVendors = response?.items;

          this.filteredVendorDetails = this.POForm.get(
            'txtManufacturerFilter'
          )?.valueChanges.pipe(
            startWith(''),
            map((value) =>
              this.ltVendors?.filter((option) =>
                option?.vendorName
                  ?.toLowerCase()
                  ?.includes(value?.toLowerCase() ?? '')
              )
            )
          );
        },
        (err) => {}
      );
    this.subscription$.push(vendorDetails);
  }
  initializeForms() {
    this.POForm = this.fb.group({
      drpManufacturer: new FormControl('', [Validators.required]),
      txtManufacturerFilter: new FormControl(''),
    });
  }
  manufacturerName: string = '';
  //to get the Purchase orders
  getPurchaseOrders() {
    this.isPOTableLoaded = false;
    const manufacturerIds = this.POForm.value.drpManufacturer;
    this.manufacturerName =
      this.ltVendors?.find((a) => a.id === manufacturerIds)?.vendorName ?? '';
    const purchaseOrder = this.requisitionService
      .getRequestedOrdersforPurchaseByGManufacturerIds(manufacturerIds)
      .subscribe(
        (response) => {
          this.ltPurchaseOrders = response;
          this.isPOTableLoaded = true;
        },
        (err) => {
          this.isPOTableLoaded = true;
        }
      );
    this.subscription$.push(purchaseOrder);
  }

  //To get the Purchase Orders Summary
  getPurchaseOrderSummary() {
    this.isSummaryTableLoaded = false;
    const purchaseSummaryList = this.requisitionService
      .getRequisitionsSummaryforPO()
      .subscribe(
        (response) => {

          this.ltSummaryData = response;
          this.isSummaryTableLoaded = true;
        },
        (err) => {
          this.isSummaryTableLoaded = true;
        }
      );
    this.subscription$.push(purchaseSummaryList);
  }

  //On Tab Change
  changeTab(tab: string) {
    this.activeTab = tab;
    if (this.activeTab == 'PurchaseOrder') {
      this.getPurchaseOrders();
    } else if (this.activeTab == 'Summary') {
      this.getPurchaseOrderSummary();
    }

  }
}
