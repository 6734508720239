import { CoreModule } from '@abp/ng.core';
import { registerLocale } from '@abp/ng.core/locale';
import {
  NgxMatDatetimePickerModule,
  NgxMatTimepickerModule,
} from '@angular-material-components/datetime-picker';
import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import {
  MatNativeDateModule,
  MatOptionModule,
  MatPseudoCheckboxModule,
  MatRippleModule,
} from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { NgbNavModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgxsModule } from '@ngxs/store';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';
import { NgxMaskModule } from 'ngx-mask';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { PatientState } from 'projects/patient/src/app/patient-store/patient.state';
import { environment } from 'projects/patient/src/environments/environment';
import { SharedModule } from 'projects/shared/src/app/shared.module';
import { AddAuthorizationComponent } from './add-authorization/add-authorization.component';
import { AddItemComponent } from './add-item/add-item.component';
import { AddVerificationComponent } from './add-verification/add-verification.component';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MessageComponent } from './message/message.component';
import { NotificationMailComponent } from './notification-mail/notification-mail.component';
import { OrderAuthorizationComponent } from './order-authorization/order-authorization.component';
import { OrderClinicalComponent } from './order-clinical/order-clinical.component';
import { OrderCreateEditComponent } from './order-create-edit/order-create-edit.component';
import { OrderDocumentComponent } from './order-document/order-document.component';
import { OrderInsuranceComponent } from './order-insurance/order-insurance.component';
import { OrderItemComponent } from './order-item/order-item.component';
import { orderdropdownState } from './order-store/dropdown.state';
import { orderState } from './order-store/order.state';
import { reminderState } from './order-store/reminder.state';
import { OrderVerificationComponent } from './order-verification/order-verification.component';
import { OrderComponent } from './order/order.component';
import { ReminderMessageComponent } from './reminder-message/reminder-message.component';
import { ReminderPopupComponent } from './reminder-popup/reminder-popup.component';
import { ReminderComponent } from './reminder/reminder.component';
import { RenewalOrdersMenuComponent } from './renewal-orders-menu/renewal-orders-menu.component';
import { ReturnOrderUpdateComponent } from './return-order-update/return-order-update.component';
import { RejectedOrderUpdateComponent } from './rejected-order-update/rejected-order-update.component';
import { MatMenuModule } from '@angular/material/menu';
import { OrderNotesComponent } from './order-notes/order-notes.component';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { AddItemAuthorizationComponent } from './add-item-authorization/add-item-authorization.component';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { AuthFollowUpComponent } from './auth-follow-up/auth-follow-up.component';

import { InsuranceReportComponent } from './insurance-report/insurance-report.component';
import { MatSortModule } from '@angular/material/sort';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { OrderCreateEditTabComponent } from './order-create-edit-tab/order-create-edit-tab.component';
import { OrderSummaryComponent } from './order-summary/order-summary.component';
// import { ItemTabComponent } from './item-tab/item-tab.component';
import { ItemTabComponent } from './item-tab/item-tab.component';

import { PickupExchangeListComponent } from './pickup-exchange-list/pickup-exchange-list.component';
import { AddItemTabComponent } from './add-item-tab/add-item-tab.component';
import { NgbPaginationModule } from '@ng-bootstrap/ng-bootstrap';
import { OrderTabComponent } from './order-tab/order-tab.component';
import { AddReminderTabComponent } from './add-reminder-tab/add-reminder-tab.component';
import { ReminderTabComponent } from './reminder-tab/reminder-tab.component';
import { ReminderMessageTabComponent } from './reminder-message-tab/reminder-message-tab.component';
import { OrderAuthorizationTabComponent } from './order-authorization-tab/order-authorization-tab.component';
import { AddAuthorizationTabComponent } from './add-authorization-tab/add-authorization-tab.component';
import { OrderSearchTabComponent } from './order-search-tab/order-search-tab.component';
import { MatTableExporterModule } from 'mat-table-exporter';
import { BrowserModule } from '@angular/platform-browser';
import { ScheduleConfigComponent } from './schedule-config/schedule-config.component';
import { MatChipsModule } from '@angular/material/chips';
import { PickupExchangeOptionsTabComponent } from './pickup-exchange-options-tab/pickup-exchange-options-tab.component';
import { AddPickupExchangeItemTabComponent } from './add-pickup-exchange-item-tab/add-pickup-exchange-item-tab.component';
import { PickupExchangeTabComponent } from './pickup-exchange-tab/pickup-exchange-tab.component';
import { FinanceTabComponent } from './finance-tab/finance-tab.component';
import { PickupExchangeUploadTabComponent } from './pickup-exchange-upload-tab/pickup-exchange-upload-tab.component';
import { DataTablesModule } from 'angular-datatables';
import { SaleOrderComponent } from './sale-order/sale-order.component';
import { TextMaskModule } from 'angular2-text-mask';
import { ExchangeListComponent } from './exchange-list/exchange-list.component';
import { SaleorderPaymentprocessDialogComponent } from './saleorder-paymentprocess-dialog/saleorder-paymentprocess-dialog.component';
import { SaleorderShipping1Component } from './saleorder-shipping1/saleorder-shipping1.component';
import { RentalMonthlyCalculationComponent } from './rental-monthly-calculation/rental-monthly-calculation.component';
import { ExchangeListTableComponent } from './exchange-list/exchange-list-table/exchange-list-table.component';
import { SaleOrderOverrideModalComponent } from './sale-order-override-modal/sale-order-override-modal.component';
import { OverrideListComponent } from './override-list/override-list.component';
import { OverrideListTableComponent } from './override-list/override-list-table/override-list-table.component';
import { RentalListComponent } from './rental-list/rental-list.component';
import { SaleOrderReturnModalComponent } from './sale-order-return-modal/sale-order-return-modal.component';
import { ReturnOrderListComponent } from './return-order-list/return-order-list.component';
import { ReturnOrdersTableComponent } from './return-order-list/return-orders-table/return-orders-table.component';
import { PaymentMethodsModalComponent } from './payment-methods-modal/payment-methods-modal.component';
import { CcSettlementComponent } from './cc-settlement/cc-settlement.component';
import { OrderSearchTabTableComponent } from './order-search-tab/order-search-tab-table/order-search-tab-table.component';
import { OrderFilterSwitchComponent } from './order-search-tab/order-filter-switch/order-filter-switch.component';
import { HelloSignComponent } from './hello-sign/hello-sign.component';
import { HelloSignReportComponent } from './hello-sign-report/hello-sign-report.component';
import { HelloSignReportTableComponent } from './hello-sign-report/hello-sign-report-table/hello-sign-report-table.component';
import { TimeLineComponent } from './time-line/time-line.component';
import { PreviewDocumentComponent } from './preview-document/preview-document.component';
import { RxPreviewDocumentComponent } from './rx-preview-document/rx-preview-document.component';
import { CcSettlementTabComponent } from './cc-settlement-tab/cc-settlement-tab.component';
import { CcSettlementTabPendingComponent } from './cc-settlement-tab/cc-settlement-tab-pending/cc-settlement-tab-pending.component';
import { CcSettlementTabCompletedComponent } from './cc-settlement-tab/cc-settlement-tab-completed/cc-settlement-tab-completed.component';
import { CreditCardModalComponent } from './payment-methods-modal/credit-card-modal/credit-card-modal.component';
import { MyOrdersComponent } from './my-orders/my-orders.component';
import { SaleOrderV2Component } from './sale-order-v2/sale-order-v2.component';
import { MachineSettingsModalComponent } from './machine-settings-modal/machine-settings-modal.component';
import { OrderHistoryModalComponent } from './order-history-modal/order-history-modal.component';
import { MatStepperModule } from '@angular/material/stepper';
import { ResupplyComponent } from './resupply/resupply.component';
import { CurrentSuppliesComponent } from './resupply/current-supplies/current-supplies.component';
import { OverdueSuppliesComponent } from './resupply/overdue-supplies/overdue-supplies.component';
import { InactiveSuppliesComponent } from './resupply/inactive-supplies/inactive-supplies.component';
import { ArchiveSuppliesComponent } from './resupply/archive-supplies/archive-supplies.component';
import { RxMailSendComponent } from './rxMailSend/rxMailSend.component';
import { SaleOrderViewComponent } from './sale-order-view/sale-order-view.component';
import { ResupplyInactiveNotesComponent } from './resupply-inactive-notes/resupply-inactive-notes.component';
import { ResupplyCallNotesComponent } from './resupply-call-notes/resupply-call-notes.component';
import { AboveNinetyDaysComponent } from './resupply/above-ninetyDays/above-ninetyDays.component';
import { OrderItemsModalExchangePickupComponent } from './order-items-modal-exchange-pickup/order-items-modal-exchange-pickup.component';
const providers: any[] = [];

@NgModule({
  declarations: [
    AppComponent,
    OrderComponent,
    RenewalOrdersMenuComponent,
    OrderCreateEditComponent,
    OrderItemComponent,
    AddItemComponent,
    OrderDocumentComponent,
    AddAuthorizationTabComponent,
    OrderAuthorizationTabComponent,
    OrderInsuranceComponent,
    OrderClinicalComponent,
    OrderVerificationComponent,
    AddVerificationComponent,
    ReminderComponent,
    ReminderMessageComponent,
    NotificationMailComponent,
    OrderAuthorizationComponent,
    AddAuthorizationComponent,
    ReminderPopupComponent,
    MessageComponent,
    ReturnOrderUpdateComponent,
    RejectedOrderUpdateComponent,
    OrderNotesComponent,
    AddItemAuthorizationComponent,
    AuthFollowUpComponent,
    InsuranceReportComponent,
    OrderCreateEditTabComponent,
    OrderSummaryComponent,
    ItemTabComponent,
    AddItemTabComponent,
    OrderTabComponent,
    AddReminderTabComponent,
    ReminderTabComponent,
    ReminderMessageTabComponent,
    OrderSearchTabComponent,
    ScheduleConfigComponent,
    AddPickupExchangeItemTabComponent,
    PickupExchangeOptionsTabComponent,
    PickupExchangeTabComponent,
    PickupExchangeListComponent,
    FinanceTabComponent,
    PickupExchangeUploadTabComponent,
    SaleOrderComponent,
    ExchangeListComponent,
    SaleorderPaymentprocessDialogComponent,
    SaleorderShipping1Component,
    ExchangeListTableComponent,
    RentalMonthlyCalculationComponent,
    SaleOrderOverrideModalComponent,
    OverrideListComponent,
    OverrideListTableComponent,
    RentalListComponent,
    SaleOrderReturnModalComponent,
    ReturnOrderListComponent,
    ReturnOrdersTableComponent,
    PaymentMethodsModalComponent,
    OrderSearchTabTableComponent,
    CcSettlementComponent,
    OrderFilterSwitchComponent,
    HelloSignReportComponent,
    HelloSignReportTableComponent,
    HelloSignComponent,
    TimeLineComponent,
    PreviewDocumentComponent,
    RxPreviewDocumentComponent,
    CcSettlementTabComponent,
    CcSettlementTabPendingComponent,
    CcSettlementTabCompletedComponent,
    CreditCardModalComponent,
      MyOrdersComponent,
      SaleOrderV2Component,
      MachineSettingsModalComponent,
      OrderHistoryModalComponent,
      ResupplyComponent,
      CurrentSuppliesComponent,
      AboveNinetyDaysComponent,
      OverdueSuppliesComponent,
      InactiveSuppliesComponent,
      ArchiveSuppliesComponent,
      RxMailSendComponent,
      SaleOrderViewComponent,
      ResupplyInactiveNotesComponent,
      ResupplyCallNotesComponent,
      OrderItemsModalExchangePickupComponent
   ],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    MatChipsModule,
    AppRoutingModule,
    MatMenuModule,
    NgbNavModule,
    CommonModule,
    BrowserModule,
    SharedModule,
    NgbPaginationModule,
    MatExpansionModule,
    MatRadioModule,
    ScrollingModule,
    MatSortModule,
    NgxMatDatetimePickerModule,
    NgxExtendedPdfViewerModule,
    NgxMatSelectSearchModule,
    NgxMatTimepickerModule,
    NgxMaterialTimepickerModule,
    NgxMaskModule.forRoot({ showMaskTyped: true }),
    MatButtonModule,
    MatProgressSpinnerModule,
    MatFormFieldModule,
    MatInputModule,
    MatRippleModule,
    NgxMatSelectSearchModule,
    TextMaskModule,
    NgSelectModule,
    MatPaginatorModule,
    MatTooltipModule,
    MatTableModule,
    MatSnackBarModule,
    MatDialogModule,
    MatNativeDateModule,
    MatSelectModule,
    MatCheckboxModule,
    MatOptionModule,
    MatDatepickerModule,
    MatTableExporterModule,
    MatCardModule,
    MatPseudoCheckboxModule,
    MatIconModule,
    MatDividerModule,
    MatInputModule,
    NgbNavModule,
    NgSelectModule,
    MatRippleModule,
    MatProgressBarModule,
    NgxMatSelectSearchModule,
    MatExpansionModule,
    MatSidenavModule,
    MatStepperModule,
    MatListModule,
    AngularEditorModule,
    DataTablesModule,
    CoreModule.forRoot({
      environment,
      registerLocaleFn: registerLocale(),
    }),
    NgxsModule.forRoot([
      PatientState,
      orderState,
      orderdropdownState,
      reminderState,
    ]),
    NgxDaterangepickerMd.forRoot(),
    MatSlideToggleModule,
    NgbTooltipModule
  ],
  exports: [OrderComponent, OrderDocumentComponent],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule {}

@NgModule({})
export class OrderModule {
  static forRoot(): ModuleWithProviders<OrderModule> {
    return {
      ngModule: AppModule,

      providers,
    };
  }
}
