import { Component, Input, OnInit } from '@angular/core';
import { ShippingApprovalService } from 'projects/order/src/app/order-proxy/order-management/order-optimization/shipping-approval.service';
import { Subscription } from 'rxjs';
import { defaultGuid } from '../../claim-status-tab/claim-status-tab.component';
import { ToastrService } from 'ngx-toastr';
import { HttpErrorResponse } from '@angular/common/http';
import Swal from 'sweetalert2';
import { BillingListService } from 'projects/order/src/app/order-proxy/order-management/order-optimization/billing-list.service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ViewPatientLedgerComponent } from 'projects/admin/src/app/view-patient-ledger/view-patient-ledger.component';
import {
  PendingOrderStatusDTO,
  PrintTicketDetails,
  ShippingApprovedNotesDTO,
} from 'projects/order/src/app/order-proxy/order-management/order-optimization/dto';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { AddNoteTabComponent } from 'projects/patient/src/app/add-note-tab/add-note-tab.component';
import { PatientCommonNotesComponent } from 'projects/shared/src/app/components/patient-common-notes/patient-common-notes.component';
import { ShippingListService } from 'projects/order/src/app/order-proxy/order-management/order-optimization/shipping-list.service';
import { CommunicationService } from 'projects/shared/src/app/services/communication.service';
import { PatientManualVerificationTabComponent } from 'projects/patient/src/app/patient-manual-verification-tab/patient-manual-verification-tab.component';
import { DatePipe } from '@angular/common';
import { OrderHistoryModalComponent } from 'projects/order/src/app/order-history-modal/order-history-modal.component';
import { PatientAuthSaveUpdateFormComponent } from 'projects/patient/src/app/patient-auth-save-update-form/patient-auth-save-update-form.component';

@Component({
  selector: 'app-billing-list-page-details',
  templateUrl: './billing-list-page-details.component.html',
  styleUrls: ['./billing-list-page-details.component.scss'],
})
export class BillingListPageDetailsComponent implements OnInit {
  @Input() ticketDataArray: any;
  @Input() activeTab: any;
  isBillingTableLoaded: boolean = true;
  selectedShippingOrderId: string = defaultGuid;
  priVerificationId: string = defaultGuid;
  secVerificationId: string = defaultGuid;
  authorizationId: string = defaultGuid;
  checkListId: string = defaultGuid;
  isCashOrder: boolean = false;
  defaultGuid = defaultGuid;
  selectedPatientId: string;
  patientId: string;
  billingForm: FormGroup;
  BillingTypeForm: FormGroup;
  printTicketData: PrintTicketDetails;
  shippingListNotesDetails: ShippingApprovedNotesDTO;
  orderId: any;
  tblItemSelectedRowId: string = defaultGuid;
  loadItmTbl = true;
  loadTable: boolean = true;
  productDataArray: any;
  shippingListDetails: any;
  subscription$: Subscription[] = [];
  itemTableData: any = [];
  hcpcTableData: any = [];
  disableSplitButton: boolean = true;
  itemListSubscription: Subscription;
  notesListSubscription: Subscription;
  isWonaceLogin: boolean = false;
  isWonaceGetSes: string = localStorage.getItem('isInventoryNeeded') || '';
  dtBillingOptions: any = {
    responsive: true,
    paging: false,
    scrollCollapse: true,
    scrollX: true,
    dom: 'Bfrtip',
    scrollY: '300px',
    order: [],
    language: {
      emptyTable: 'No records',
      info: 'Total : _MAX_ records',
      infoEmpty: 'Total : _MAX_ records',
      infoFiltered: '(filtered : _TOTAL_ records)',
      loadingRecords: 'Loading...',
      zeroRecords: 'No matching records',
    },
    buttons: [
      {
        extend: 'colvis',
        columns: ':not(:eq(0), :eq(1), :eq(2),:eq(3), :eq(-1))', // Exclude first three and last columns from the column visibility toggle
      },
    ],
    // columnDefs: [
    //   { targets: [0], visible: false },
    //   { orderable: true, targets: '_all' }
    // ]
    columnDefs: [
      { targets: [0, 1, 2, 3, -1], className: 'dt-fixed-column' }, // Ad
      { targets: [12, 13, 14, 15], visible: false },
      // Add other columnDefs as needed
    ],
  };
  dtItemOptions: any = {
    responsive: true,
    paging: false,
    filter: false,
    info: true,
    scrollY: '225px',
    scrollCollapse: true,
    // order: [],
    language: {
      emptyTable: 'No records',
      info: 'Total : _MAX_ records',
      infoEmpty: 'Total : _MAX_ records',
      infoFiltered: '(filtered : _TOTAL_ records)',
      loadingRecords: 'Loading...',
      zeroRecords: 'No matching records',
    },
    columnDefs: [
      { orderable: false, targets: 1 },
      { targets: [0], visible: false }, // Disable sorting for column at index 2
    ],
  };
  selectedBillingRow: any;
  selectedBillingId: any;
  selectedItemRow: any;
  selectedItemId: any;
  authTableData = [];
  lastSelectedQuanity: any;
  lastSelectedProductId: any;
  isShowSpinner: boolean = false;
  documentId: string;
  constructor(
    private communicationService: CommunicationService,
    private BillingListService: BillingListService,
    private toastr: ToastrService,
    private dialog: MatDialog,
    private formBuilder: FormBuilder,
    private shippingListService: ShippingListService,
    private datepipe: DatePipe
  ) {
    const updateStatus =
      this.communicationService.functionUpdateBillingStatus$.subscribe(
        (orderId) => {
          this.changeBillingPendingStatus(orderId);
        }
      );
    this.subscription$.push(updateStatus);
  }

  ngOnInit(): void {
    this.isWonaceLogin = this.isWonaceGetSes === 'false';
    this.initializeForms();
  }
  //On Destroy method
  ngOnDestroy(): void {
    this.subscription$?.forEach((sub) => {
      sub && sub?.unsubscribe();
    });
  }

  //To initialize the Forms
  initializeForms() {
    this.billingForm = this.formBuilder.group({
      txtBillingComments: new FormControl(''),
      txtShippingComments: new FormControl(''),
    });
    this.BillingTypeForm = this.formBuilder.group({
      drpTypes: new FormControl(1),
    });
  }

  //To show the HCPC Codes
  getHCPCCodes(data) {
    this.hcpcTableData = data;
    this.hcpcTableData;
  }
  //To Load the Selected Order Items
  //To Load the Selected Order Items
  tblShpSelectedRow(shippingListDetails: any) {
    if (this.selectedShippingOrderId !== shippingListDetails.mmOrderId) {
      this.selectedShippingOrderId = shippingListDetails.mmOrderId;
      this.shippingListDetails = shippingListDetails;
      this.orderId = shippingListDetails.mmOrderId;
      this.patientId = shippingListDetails.patientId;
      this.priVerificationId = shippingListDetails.priVerificationId;
      this.secVerificationId = shippingListDetails?.secVerificationId;
      this.authorizationId = shippingListDetails?.authorizationId;
      this.checkListId = shippingListDetails?.medicareCheckList;
      this.isCashOrder = shippingListDetails?.isCashOrder;
      this.documentId = shippingListDetails?.faxDocumentId;
      this.loadBilledItems(shippingListDetails.mmOrderId);

      this.selectedPatientId = shippingListDetails.patientId;

      this.loadShippingNotesDetails(shippingListDetails?.mmOrderId);
    }
  }
  //Select Shipping Details
  tblItemSelectedRow(id: string, data: any, product: any) {
    if (this.tblItemSelectedRowId !== id) {
      this.lastSelectedQuanity = product.quantity;
      this.lastSelectedProductId = product.productId;
      this.tblItemSelectedRowId = id;
      this.getHCPCCodes(data);
    }
  }
  //To Split the Quantity
  QtySplit(data) {
    this.lastSelectedQuanity = data.quantity;
    this.lastSelectedProductId = data.productId;

    this.BillingListService.updateBillingQuantityByInput(data).subscribe(
      (response) => {
        this.toastr.success('Splited Successfully', 'Success');
        this.loadBilledItems(this.orderId);
      },
      (err) => {
        // let data = JSON.parse(err.error);
        // this.toastr.error(data.error.message);
      }
    );
    // }else{
    //   console.log('else');
    // }
  }
  //On Key Press Validation
  onKeyPress(event: KeyboardEvent) {
    const isNumber = /[0-9]/.test(event.key);
    if (!isNumber) {
      event.preventDefault(); // Prevent the input if the key is not a number
    }
  }

  isEmpty(value: any): boolean {
    return (
      value === null ||
      value === undefined ||
      value === '' ||
      value === defaultGuid
    );
  }

  loadBilledItems(billingId: string) {
    // this.isItemTableLoaded = true;
    this.loadTable = false;
    if (this.itemListSubscription) {
      this.itemListSubscription.unsubscribe();
    }
    this.itemListSubscription =
      this.BillingListService.getShippingItemDetailsByGOrderId(
        billingId
      ).subscribe(
        (response) => {
          this.loadTable = true;
          this.productDataArray = response ? response : [];
          this.productDataArray = this.productDataArray?.map((a) => ({
            ...a,
            isSelected: false,
          }));
          this.tblItemSelectedRow(
            this.productDataArray?.[0]?.orderItemId,
            this.productDataArray?.[0]?.hcpcList,
            this.productDataArray?.[0]
          );
          this.getApprovalNoandAmnt(billingId);
        },
        (err) => {
          this.loadTable = true;
          this.productDataArray = [];
        }
      );
    this.subscription$.push(this.itemListSubscription);
  }
  viewPatientLedgerPopup() {
    this.dialog.open(ViewPatientLedgerComponent, {
      width: '100vw',
      height: '100vh',
      maxWidth: '100vw',
      maxHeight: '100vh',
      panelClass: 'fullscreen-dialog-panel',
      disableClose: true,
      data: {
        patientId: this.patientId,
        viewLedger: true,
        // mmOrderId: data.mmOrderId,
        // mmOrderItemId: data.mmOrderItemId
      },
    });
    this.dialog.afterAllClosed.subscribe((resposne) => {});
  }
  viewPatientLedger(patientId: string) {
    this.dialog.open(ViewPatientLedgerComponent, {
      width: '100vw',
      height: '100vh',
      maxWidth: '100vw',
      maxHeight: '100vh',
      panelClass: 'fullscreen-dialog-panel',
      disableClose: true,
      data: {
        patientId: patientId,
      },
    });
    this.dialog.afterAllClosed.subscribe((resposne) => {});
  }
  billingComplet() {
    this.isShowSpinner = true;
    this.BillingListService.updateBillingApprovalStatusByGOrderId(
      this.orderId
    ).subscribe(
      (response) => {
        this.toastr.success('Order Completed Successfully', 'Success');
        // this.loadBillingList(this.isTypes);
        this.productDataArray = [];
        this.loadBilledItems(defaultGuid);
        this.communicationService.triggerBillingOrdersGetMethodCall();
        this.isShowSpinner = false;
      },
      (err) => {
        this.isShowSpinner = false;
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      }
    );
  }
  openViewNotesPopup(patientId: string, defaultFaxId: string, docId: string) {
    const config: MatDialogConfig = {
      disableClose: true,
      width: '70vw',
      maxWidth: '70vw',
      height: '40vw',
      maxHeight: '40vw',
      data: {
        patientId: patientId,
        pageType: 'Patient Note', // this.pageType, //Notes Table Popup Page
        isAddVisible: false,
        defaultFaxId: defaultFaxId,
        docId: docId,
      },
    };
    const dialogRef = this.dialog.open(PatientCommonNotesComponent, config);

    dialogRef.afterClosed().subscribe(
      () => {
        //this.getReminderTableData();
      },
      (err: HttpErrorResponse) => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      }
    );
  }

  openAddNotesPopUp(
    defaultFaxId: string,
    patientId: string,
    docId: string,
    isCallNote: boolean = false,
    chartNo: string
  ) {
    const config: MatDialogConfig = {
      disableClose: true,
      minHeight: '30vh',
      minWidth: '50vw',
      data: {
        // claimId: '00000000-0000-0000-0000-000000000000',
        noteType: 'patient', // 'Order Note',
        notesId: '00000000-0000-0000-0000-000000000000',
        orderId: this.orderId,
        patientId: patientId,
        defaultFaxId: defaultFaxId,
        isCallNote: isCallNote,
        isPending: false,
        docId: docId,
        inboundStatusShortCodeId: defaultGuid,
        chartNo: chartNo,
        addnotes: false,
        isBillingPending: true, //To trigger the Billing Status Update based on this value.
      },
    };
    const dialogRef = this.dialog.open(AddNoteTabComponent, config);

    const closeDialog = dialogRef.afterClosed().subscribe(
      () => {
        // this.getReminderTableData();
      },
      (err) => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      }
    );
    this.subscription$.push(closeDialog);
  }

  openVerificationModal(element) {
    if (element == 'Primary') {
      const dialogRef = this.dialog.open(
        PatientManualVerificationTabComponent,
        {
          disableClose: true,
          data: {
            patientId: this.shippingListDetails.patientId,
            verificationId: this.shippingListDetails.priVerificationId,
            policyId: this.shippingListDetails.policyId,
            savehidden: true,
            isViewVerification: true,
            documentId: this.documentId,
            // defaultFaxId:data?.defaultFaxId
          },
        }
      );
      const closeDialog = dialogRef.afterClosed().subscribe((result) => {
        if (result == 'Saved') {
          this.dialog.closeAll();
        }

        result !== '' &&
          result !== null &&
          result !== undefined &&
          this.dialog.closeAll();
      });
      this.subscription$.push(closeDialog);
    } else if (element == 'secondary') {
      const dialogRef = this.dialog.open(
        PatientManualVerificationTabComponent,
        {
          disableClose: true,

          data: {
            patientId: this.shippingListDetails.patientId,
            verificationId: this.shippingListDetails.secVerificationId,
            policyId: this.shippingListDetails.secpolicyId,
            savehidden: true,
            isViewVerification: true,
            documentId: this.documentId,
            // defaultFaxId:data?.defaultFaxId
          },
        }
      );
      const closeDialog = dialogRef.afterClosed().subscribe((result) => {
        if (result == 'Saved') {
          this.dialog.closeAll();
        }

        result !== '' &&
          result !== null &&
          result !== undefined &&
          this.dialog.closeAll();
      });
      this.subscription$.push(closeDialog);
    }
  }

  //To Load the Shipping Notes Details
  loadShippingNotesDetails(orderId: string) {
    if (this.notesListSubscription) {
      this.notesListSubscription.unsubscribe();
    }
    this.notesListSubscription = this.shippingListService
      .getPatientBalNotesDetailsByOrderId(orderId)
      .subscribe(
        (response) => {
          this.shippingListNotesDetails = response;
        },
        (err) => {
          this.shippingListNotesDetails = null;
        }
      );
    this.subscription$.push(this.notesListSubscription);
  }

  //To Split the Shipping Items
  splitBillingItems() {
    const selectedItems = this.productDataArray
      .filter((a) => a.isSelected == true)
      .map((b) => (b = b.orderItemId));

    const currentDate = new Date();
    this.shippingListService
      .splitOrderByOrderIdAndOrderDateAndOrderItemIds(
        this.selectedShippingOrderId,
        currentDate.toISOString(),
        selectedItems
      )
      .subscribe(
        (response) => {
          this.toastr.success('Order Splitted!');
          // this.loadShippingList();
          this.communicationService.triggerBillingOrdersGetMethodCall();
        },
        (err) => {
          this.toastr.error('Something Went Wrong!');
        }
      );
  }
  //To Disable the Split Button based on the Item selection
  checkSelectedItems() {
    const checkTotalCount = this.productDataArray?.length;
    const checkSelectedCount = this.productDataArray.filter(
      (a) => a.isSelected == true
    )?.length;
    this.disableSplitButton = true;
    if (checkTotalCount > checkSelectedCount && checkSelectedCount != 0) {
      this.disableSplitButton = false;
    } else if (checkSelectedCount === 0) {
      this.disableSplitButton = true;
      // this.toaster.warning('Select Minimum Items!');
    } else {
      this.disableSplitButton = true;
      this.toastr.warning("Can't split all items!");
    }
    // else if (checkSelectedCount === 0) {
    //   this.disableSplitButton = true;
    // } else if (checkTotalCount === checkSelectedCount) {
    //   this.disableSplitButton = true;
    //   this.toaster.warning("Can't split all items!");
    // }
  }

  //Validation for Pending Status
  get checkActiveTab(): boolean {
    return this.activeTab === 'NewSetup' || this.activeTab === 'Supplies';
  }

  //To change the Billing Status of the Order
  changeBillingPendingStatus(orderId: string) {
    let params: PendingOrderStatusDTO = {
      orderId: orderId,
      newSetup:
        this.activeTab == 'NewSetup' || this.activeTab == 'NewSetupPending'
          ? 1
          : 0,
    };
    this.BillingListService.updatePendingOrderStatusByInput(params).subscribe(
      (response) => {
        this.toastr.success('Billing Status Updated!');
        this.communicationService.triggerBillingOrdersGetMethodCall();
      },
      (err) => {
        this.toastr.error('Something Went Wrong!');
      }
    );
  }

  //To change the Billing Status to Supplies or New Setup
  changeBillingStatus() {
    this.BillingListService.updateOrderStatusForBillingByOrderId(
      this.orderId
    ).subscribe(
      (response) => {},
      (err) => {}
    );
  }
  getFormattedDate(date: string): string {
    return date ? new Date(date).toLocaleDateString('en-US') : '-';
  }

  //Approval No and Amount
  getApprovalNoandAmnt(orderId: string) {
    this.shippingListService.getApprovalNoByOrderId(orderId).subscribe(
      (response) => {
        this.authTableData = response;
      },
      (err) => {
        console.log(err);
      }
    );
  }

  /* #region PRINT TICKET */

  //Method to call the Print Ticket Data Details
  getPrintTicketDetails() {
    const shippingTicketDetails = this.shippingListService
      .orderTicketDetailsV1ByOrderId(this.selectedShippingOrderId)
      .subscribe(
        (response) => {
          this.printTicketData = response ?? null;
          this.getPrintTicket(this.printTicketData);
        },
        (err) => {}
      );

    this.subscription$.push(shippingTicketDetails);
  }
  getPrintTicket(printTicketData: PrintTicketDetails) {
    const tbodyStyle = {
      fontSize: 10,
      margin: [0, 2],
    };

    this.printTicketData.productDetailsDTO =
      this.printTicketData?.productDetailsDTO?.map((a) => ({
        ...a,
        style: tbodyStyle,
      }));

    let docDefinition = {
      /* #region HEADER */
      header: function (currentPage, pageCount, pageSize) {
        return {
          columns: [
            // {
            //   image: localStorage.getItem('LogoDataUrl'),
            //   width: 100, // Adjust the width as needed
            //   alignment: 'left', // Adjust the alignment as needed
            // },
            // {
            //   width: '10%',
            //   text:{

            //       text: '844498797',
            //       fontSize: 18,
            //       bold: true,
            //       alignment: 'center',
            //       color: '#000000',

            //   }
            // },
            {
              width: '80%',
              text: [
                { text: '\n' },
                {
                  // margin:[15,0,0,0],
                  text: printTicketData?.mmRefOrderId ?? '',
                  fontSize: 16,
                  bold: true,
                  alignment: 'left',
                  color: '#000000',
                },
                {
                  text: '\t\t\t\t\t\t\t\t\t\tConfirm receipt of shipment online at www.monitormedical.com\n\t\t\t\t',
                  fontSize: 10,
                  bold: true,
                  alignment: 'center',
                  color: '#91ab5b',
                },
                {
                  text: '\t\t\t\t\t\tPATIENT CARE DELIVERY TICKET',
                  fontSize: 16,
                  bold: true,
                  alignment: 'center',
                  color: '#800000',
                },
              ],
              margin: [15, 0, 0, 0],
            },
            {
              image: localStorage.getItem('LogoDataUrl'),
              width: 100, // Adjust the width as needed
              // alignment: 'right', // Adjust the alignment as needed
              margin: [0, 10, 0, 0],
            },
          ],
        };
      },

      /* #endregion */

      /* #region FOOTER */
      footer: (currentPage, pageCount, pageSize) => {
        return {
          canvas: [
            {
              type: 'line',
              x1: 0,
              y1: 0,
              x2: 565,
              y2: 0,
              lineWidth: 3,
              lineColor: '#800000',
            },
          ],
          columns: [
            {
              width: '70%',
              text: [
                {
                  text: 'Patient Signature:',
                  fontSize: 10,
                  italics: true,
                },
                {
                  text: '_____________________________________________________________________\n',
                  fontSize: 10,
                  italics: true,
                },
                {
                  text: 'Monitor Medical, Inc. \t',
                  fontSize: 16,
                  italics: true,
                  bold: true,
                },
                {
                  text: 'The C-PAP Co. \t',
                  fontSize: 12,
                  italics: true,
                },
                {
                  text: 'Toll Free: 866-634-0177 \n',
                  fontSize: 10,
                  italics: true,
                  bold: true,
                },
                {
                  text: printTicketData?.mmRefOrderId ?? '',
                  fontSize: 14,
                  italics: true,
                  alignment: 'right',
                  bold: true,
                  color: '#000000',
                },
              ],
              // ,
              bold: true,
              color: '#800000',
            },
            {
              width: '30%',
              text: [
                // {
                //   text: '_ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ \n\n',
                //   fontSize: 15,
                //   bold: true,
                //   italics: true,
                //   alignment: 'left',
                // },
                { text: 'Date:', fontSize: 10, italics: true },
                { text: '___________________________\n' },
                {
                  text:
                    this.datepipe.transform(new Date(), 'fullDate') + '\n\n',
                  alignment: 'center',
                  fontSize: 8,
                  italics: true,
                  bold: false,
                },
                {
                  text:
                    printTicketData.shippingMethodName +
                    '\t' +
                    printTicketData.location,
                  fontSize: 8,
                  italics: true,
                  alignment: 'center',
                  color: '#000000',
                },
              ],
              fontSize: 12,
              bold: true,
              alignment: 'left',
              color: '#800000',
            },

            // {
            //   text:
            //     printTicketData.shippingMethodName +
            //     ' ' +
            //     printTicketData.location,
            //   fontSize: 8,
            //   italics: true,
            // },
          ],
          margin: [15, 0, 15, 0],
          alignment: 'left',
          // fontSize: 10,
        };
      },
      /*#endregion*/

      /* #region CONTENT */

      content: [
        /* #region Section 1 */
        {
          canvas: [
            {
              type: 'line',
              x1: 0,
              y1: 0,
              x2: 565,
              y2: 0,
              lineWidth: 2,
              lineColor: '#800000',
            },
          ],
        },
        //Row 1
        {
          columns: [
            {
              width: '33%',
              text: 'Order No:\t' + printTicketData.mmRefOrderId ?? '',
              bold: true,
              fontSize: 10,
            },
            {
              width: '33%',
              text: 'INS:\t' + printTicketData.insuranceName ?? '',
              fontSize: 10,
            },
            {
              width: '33%',
              text:
                'H:\t' + this.formatPhoneNumber(printTicketData?.homePhone) ??
                '',
              fontSize: 10,
            },
          ],
          margin: [0, 10, 0, 3], // [left, top, right, bottom]
        },
        //Row 2
        {
          columns: [
            {
              width: '33%',
              text: 'Account No:\t' + printTicketData.chartNo ?? '',
              bold: true,
              fontSize: 10,
            },
            {
              width: '33%',
              text:
                'DOB:\t' +
                (printTicketData?.dateOfBirth != null
                  ? this.datepipe.transform(
                      printTicketData?.dateOfBirth,
                      'MM/dd/yyyy'
                    )
                  : ''),
              fontSize: 10,
              style: 'columnHeader',
            },
            {
              width: '33%',
              text:
                'W:\t' + this.formatPhoneNumber(printTicketData?.workPhone) ??
                '',
              fontSize: 10,
              style: 'columnHeader',
            },
          ],
          margin: [0, 0, 0, 3],
        },
        //Row 3
        {
          columns: [
            {
              width: '33%',
              text: 'Patient Name:\t' + printTicketData.patientName ?? '',
              fontSize: 10,
            },
            {
              width: '33%',
              text: 'e-mail:\t' + (printTicketData?.emailId ?? ''),
              fontSize: 10,
            },
            {
              width: '33%',
              text:
                'M:\t' + this.formatPhoneNumber(printTicketData?.mobilePhone) ??
                '',
              fontSize: 10,
            },
          ],
          margin: [0, 0, 0, 3],
        },
        //Row 4
        {
          columns: [
            {
              width: '50%',
              text: 'Address:\t' + printTicketData?.fullAddress ?? '',
              fontSize: 10,
            },
          ],
          margin: [0, 0, 0, 10],
        },

        /* #endregion*/
        /* #region Section 2 */
        {
          canvas: [
            {
              type: 'line',
              x1: 0,
              y1: 0,
              x2: 565,
              y2: 0,
              lineWidth: 2,
              lineColor: '#800000',
            },
          ],
        },
        //Row 1
        {
          text:
            'Contact ' + printTicketData?.technicianName + ' at 281-637-2286',
          fontSize: 16,
          bold: true,
          alignment: 'center',
          color: '#800000',
          margin: [0, 5],
        },
        //Row 2
        {
          canvas: [
            {
              type: 'line',
              x1: 0,
              y1: 0,
              x2: 565,
              y2: 0,
              lineWidth: 2,
              lineColor: '#800000',
            },
          ],
        },
        /* #endregion*/
        /* #region Section 3 */
        //Row 1
        {
          width: '33%',
          text: 'Pressure Settings',
          italics: true,
          fontSize: 8,
          margin: [0, 5, 0, 5], // [left, top, right, bottom]
        },
        //Row 2
        {
          table: {
            headerRows: 1,
            widths: [
              '*',
              '*',
              '*',
              '*',
              '*',
              '*',
              '*',
              '*',
              '*',
              '*',
              '*',
              '*',
              '*',
              '*',
              '*',
            ],
            body: [
              [
                {
                  text: 'Min Press',
                  fontSize: 6,
                  alignment: 'center',
                  bold: true,
                },
                {
                  text: 'Max Press',
                  fontSize: 6,
                  alignment: 'center',
                  bold: true,
                },
                {
                  text: 'Setting',
                  fontSize: 6,
                  alignment: 'center',
                  bold: true,
                },
                { text: 'IPAP', fontSize: 6, alignment: 'center', bold: true },
                { text: 'EPAP', fontSize: 6, alignment: 'center', bold: true },
                {
                  text: 'Ramp Press',
                  fontSize: 6,
                  alignment: 'center',
                  bold: true,
                },
                {
                  text: 'Ramp Time',
                  fontSize: 6,
                  alignment: 'center',
                  bold: true,
                },
                {
                  text: 'BiFlex',
                  fontSize: 6,
                  alignment: 'center',
                  bold: true,
                },
                {
                  text: 'Set Press',
                  fontSize: 6,
                  alignment: 'center',
                  bold: true,
                },
                { text: 'CFlex', fontSize: 6, alignment: 'center', bold: true },
                {
                  text: 'RR/BPM',
                  fontSize: 6,
                  alignment: 'center',
                  bold: true,
                },
                {
                  text: 'IPAP Min',
                  fontSize: 6,
                  alignment: 'center',
                  bold: true,
                },
                {
                  text: 'IPAP Max',
                  fontSize: 6,
                  alignment: 'center',
                  bold: true,
                },
                { text: 'SetVt', fontSize: 6, alignment: 'center', bold: true },
                { text: 'Rate', fontSize: 6, alignment: 'center', bold: true },
              ],
              [
                {
                  text:
                    this.printTicketData?.settingsDetails?.minPressure ?? '',
                  fontSize: 6,
                  margin: [0, 5],
                  alignment: 'center',
                },
                {
                  text:
                    this.printTicketData?.settingsDetails?.maxPressure ?? '',
                  fontSize: 6,
                  margin: [0, 5],
                  alignment: 'center',
                },
                {
                  text:
                    this.printTicketData?.settingsDetails?.settlingTime ?? '',
                  fontSize: 6,
                  margin: [0, 5],
                  alignment: 'center',
                },
                {
                  text: this.printTicketData?.settingsDetails?.ipap ?? '',
                  fontSize: 6,
                  margin: [0, 5],
                  alignment: 'center',
                },
                {
                  text: this.printTicketData?.settingsDetails?.epap ?? '',
                  fontSize: 6,
                  margin: [0, 5],
                  alignment: 'center',
                },
                {
                  text:
                    this.printTicketData?.settingsDetails?.rampPressure ?? '',
                  fontSize: 6,
                  alignment: 'center',
                  margin: [0, 5],
                },
                {
                  text: this.printTicketData?.settingsDetails?.rampTime ?? '',
                  fontSize: 6,
                  alignment: 'center',
                  margin: [0, 5],
                },
                {
                  text: this.printTicketData?.settingsDetails?.biFlex ?? '',
                  fontSize: 6,
                  margin: [0, 5],
                  alignment: 'center',
                },
                {
                  text:
                    this.printTicketData?.settingsDetails?.setPressure ?? '',
                  fontSize: 6,
                  margin: [0, 5],
                  alignment: 'center',
                },
                {
                  text: this.printTicketData?.settingsDetails?.cFlex ?? '',
                  fontSize: 6,
                  margin: [0, 5],
                  alignment: 'center',
                },
                {
                  text: this.printTicketData?.settingsDetails?.rrbpm ?? '',
                  fontSize: 6,
                  margin: [0, 5],
                  alignment: 'center',
                },
                {
                  text: this.printTicketData?.settingsDetails?.ipaPmin ?? '',
                  fontSize: 6,
                  margin: [0, 5],
                  alignment: 'center',
                },
                {
                  text: this.printTicketData?.settingsDetails?.ipaPmax ?? '',
                  fontSize: 6,
                  margin: [0, 5],
                  alignment: 'center',
                },
                {
                  text: this.printTicketData?.settingsDetails?.setVT ?? '',
                  fontSize: 6,
                  margin: [0, 5],
                  alignment: 'center',
                },
                {
                  text: this.printTicketData?.settingsDetails?.rate ?? '',
                  fontSize: 6,
                  margin: [0, 5],
                  alignment: 'center',
                },
              ],
            ],
          },
        },
        //Row 3
        {
          columns: [
            {
              width: '33%',
              text: 'Technician: ' + printTicketData?.technicianName ?? '',
              fontSize: 8,
              alignment: 'center',
            },
            {
              width: '33%',
              text: 'Physician: ' + printTicketData?.physicianName ?? '',
              fontSize: 8,
              alignment: 'center',
            },
            {
              width: '33%',
              text:
                'Referral Practice: ' + printTicketData?.referralPractice ?? '',
              fontSize: 8,
              alignment: 'center',
            },
          ],
          margin: [0, 5, 0, 5], // [left, top, right, bottom]
        },
        /* #endregion */

        /*#region Section 4 */
        {
          table: {
            headerRows: 1,
            // widths: ['15%', '5%', '10%', '45%', '10%', '15%'],
            widths: ['10%', '12%', '44%', '10%', '9%', '15%'],
            body: [
              [
                // {
                //   text: 'Location',
                //   bold: true,
                //   fontSize: 10,
                //   color: '#800000',
                // },
                { text: 'Qty', bold: true, fontSize: 10, color: '#800000' },
                {
                  text: 'Product Code',
                  bold: true,
                  fontSize: 10,
                  color: '#800000',
                },
                {
                  text: 'Product Description',
                  bold: true,
                  fontSize: 10,
                  color: '#800000',
                },
                {
                  text: 'HCPC',
                  bold: true,
                  fontSize: 10,
                  color: '#800000',
                },
                // { text: 'Size', bold: true, fontSize: 10, color: '#800000' },
                {
                  text: printTicketData?.isOrderComplete ? 'Allowable' : 'Exch',
                  bold: true,
                  fontSize: 10,
                  color: '#800000',
                },
                {
                  text: 'Serial #/Lot #',
                  bold: true,
                  fontSize: 10,
                  color: '#800000',
                },
              ],
              ...this.printTicketData?.productDetailsDTO?.map((item) => [
                // item.location,
                item.quantity,
                item.productCode,
                item.productName,
                // item.sizeName,
                item.hcpc,
                printTicketData?.isOrderComplete
                  ? item.allowables
                  : {
                      image:
                        'data:image/png;base64,' +
                        this.getSquareImageBase64(item.exchange),
                      width: 10,
                      height: 10,
                      alignment: 'center',
                    },
                item.serialLotNo,
              ]),
            ],
          },
          style: {
            fontSize: 10,
            margin: [0, 2],
          },
          margin: [0, 5, 0, 0],
        },
        /* #endregion */

        /* #region Section 5 */
        {
          columns: [
            {
              width: '60%',
              text: [
                {
                  text: '\nComments to Patients:\n',
                  color: '#800000',
                  fontSize: 12,
                },
                {
                  text: printTicketData?.commentsToPatient ?? '',
                  fontSize: 8,
                },
              ],
              fontSize: 14,
              bold: true,
              alignment: 'left',
            },

            {
              width: '40%',
              table: {
                headerRows: 1,
                // widths: ['80%', '20%'],
                widths: ['72%', '28%'],
                body: [
                  [
                    {
                      text: 'Est Allowable:',
                      fontSize: 10,
                      margin: [0, 0],
                      alignment: 'right',
                      border: [false, false, false, false],
                    },
                    {
                      text: '$' + printTicketData.estAllowable ?? '0',
                      fontSize: 10,
                      margin: [0, 0],
                      border: [false, false, false, false],
                      alignment: 'left',
                    },
                  ],
                  [
                    {
                      text: 'Est Copay:',
                      fontSize: 10,
                      margin: [0, 0],
                      alignment: 'right',
                      border: [false, false, false, false],
                    },
                    {
                      text: '$' + printTicketData.estCopay ?? '0',
                      fontSize: 10,
                      margin: [0, 0],
                      alignment: 'left',
                      border: [false, false, false, false],
                    },
                  ],
                  [
                    {
                      text: 'Total Accessory:',
                      fontSize: 10,
                      margin: [0, 0],
                      alignment: 'right',
                      border: [false, false, false, false],
                    },
                    {
                      text: '$' + printTicketData.totalAccessory ?? '0',
                      fontSize: 10,
                      margin: [0, 0],
                      alignment: 'left',
                      border: [false, false, false, false],
                    },
                  ],
                  [
                    {
                      text: 'Shipping Fee:',
                      fontSize: 10,
                      margin: [0, 0],
                      alignment: 'right',
                      border: [false, false, false, true],
                    },
                    {
                      text: '$' + printTicketData.shippingFee ?? '0',
                      fontSize: 10,
                      margin: [0, 0],
                      alignment: 'left',
                      border: [false, false, false, true],
                    },
                  ],

                  [
                    {
                      text: 'Est Amount Due:',
                      fontSize: 10,
                      margin: [0, 0],
                      alignment: 'right',
                      border: [false, false, false, false],
                    },
                    {
                      text: '$' + printTicketData.estAmountDue ?? '0',
                      fontSize: 10,
                      margin: [0, 0],
                      alignment: 'left',
                      border: [false, false, false, false],
                    },
                  ],
                  [
                    {
                      text: 'Amount Paid:',
                      fontSize: 10,
                      margin: [0, 0],
                      alignment: 'right',
                      border: [false, false, false, true],
                    },
                    {
                      text: '$' + printTicketData.amountPaid ?? '0',
                      fontSize: 10,
                      margin: [0, 0],
                      alignment: 'left',
                      border: [false, false, false, true],
                    },
                  ],
                  [
                    {
                      text: 'Balance:',
                      fontSize: 10,
                      margin: [0, 0],
                      alignment: 'right',
                      border: [false, false, false, false],
                    },
                    {
                      text: '$' + printTicketData.patientBalance ?? '0',
                      fontSize: 10,
                      margin: [0, 0],
                      alignment: 'left',
                      border: [false, false, false, false],
                    },
                  ],
                ],
              },
              layout: {
                hLineWidth: function (i, node) {
                  return i === 4 || i === 6 ? 1 : 0; // Thickness of the dotted line for the second row bottom border
                },
                hLineColor: function (i, node) {
                  return '#800000';
                },
                hLineStyle: function (i, node) {
                  if (i === 4 || i === 6) {
                    return { dash: { length: 5, space: 5 } }; // Dotted style for the second row bottom border
                  }
                  return null;
                },
              },
            },
          ],
        },
        /* #endregion*/
      ],
      /* #endregion */

      // pageMargins: [15, 50, 15, 40],
      pageMargins: [15, 60, 15, 60],
      defaultStyle: {
        font: 'Roboto',
      },
    };

    this.displayPdf(docDefinition, 'Ticket');
  }

  private getSquareImageBase64(excahngeItem): string {
    if (excahngeItem === true) {
      // Convert the image to Base64 format (using a base64 string or FileReader)
      const canvas = document.createElement('canvas');
      canvas.width = 10;
      canvas.height = 10;
      const ctx = canvas.getContext('2d');

      // Draw outer border with light sea green color
      ctx.fillStyle = '#000000';
      ctx.fillRect(0, 0, 12, 12); // Fill entire canvas with light sea green

      // Draw inner white square (to create border effect)
      ctx.fillStyle = '#FFFFFF'; // White color
      ctx.fillRect(1, 1, 8, 8); // Leave 1 pixel border around the canvas

      // Draw check mark (using a simple path)
      ctx.strokeStyle = '#20B2AA';
      ctx.beginPath();
      ctx.moveTo(2, 4);
      ctx.lineTo(4, 6);
      ctx.lineTo(8, 2);
      ctx.stroke();

      return canvas.toDataURL('image/png').split(',')[1];
    } else {
      const canvas = document.createElement('canvas');
      canvas.width = 10;
      canvas.height = 10;
      const ctx = canvas.getContext('2d');
      ctx.fillStyle = '#000000'; // Border color
      ctx.fillRect(0, 0, 12, 12); // Fill entire canvas with black

      // Draw inner white square (to create border effect)
      ctx.fillStyle = '#FFFFFF'; // Square color
      ctx.fillRect(1, 1, 8, 8); // Leave 1 pixel border around the canvas
      return canvas.toDataURL('image/png').split(',')[1];
    }
  }

  // private getSquareImageBase64(): string {
  //   // Convert the image to Base64 format (using a base64 string or FileReader)
  //   const canvas = document.createElement('canvas');
  //   canvas.width = 10;
  //   canvas.height = 10;
  //   const ctx = canvas.getContext('2d');
  //   ctx.fillStyle = '#000000'; // Border color
  //   ctx.fillRect(0, 0, 12, 12); // Fill entire canvas with black

  //   // Draw inner white square (to create border effect)
  //   ctx.fillStyle = '#FFFFFF'; // Square color
  //   ctx.fillRect(1, 1, 8, 8); // Leave 1 pixel border around the canvas
  //   return canvas.toDataURL('image/png').split(',')[1];
  // }
  //Method to display the PDF Content
  displayPdf(docDefinition: any, filename: string): void {
    try {
      const pdfMake = window['pdfMake'];
      const pdfDocGenerator = pdfMake.createPdf(docDefinition); //.download(filename);

      // Open PDF in a new tab
      pdfDocGenerator.getBlob((blob: Blob) => {
        const dataUri = URL.createObjectURL(blob);
        window.open(dataUri, '_blank');
        URL.revokeObjectURL(dataUri);
      });

      // Optionally, you can add print and download buttons in your HTML
      // and trigger these actions using pdfMake functions:
      // pdfMake.createPdf(docDefinition).print();
      // pdfMake.createPdf(docDefinition).open();
    } catch (error) {
      Swal.fire({
        icon: 'error',
        text: 'Error creating PDF',
      });
    }
  }
  private formatPhoneNumber(value: string): string {
    if (value === null) return '';
    const phoneNumberRegex = /^(\d{3})(\d{3})(\d{4})$/;
    const matches = phoneNumberRegex.exec(value);

    if (!matches) return value;

    // Format the phone number with hyphens
    const formattedNumber = `${matches[1]}-${matches[2]}-${matches[3]}`;
    return formattedNumber;
  }
  /* #endregion */

  //To Open the Order History Popup
  getOrderHistory(
    orderId: string,
    mmOrderId: string,
    chartId: string,
    patientName: string
  ) {
    const config: MatDialogConfig = {
      height: '70vh',
      width: '40vw',
      disableClose: true,
      data: {
        orderId: orderId,
        mmOrderId: mmOrderId,
        patientName: patientName,
        chartId: chartId,
      },
    };
    const dialogRef = this.dialog.open(OrderHistoryModalComponent, config);
  }

  //To View the Authorization
  viewAuthorization() {
    // console.log(this.orderForm.value.drpAuthorization);
    const dialogRef = this.dialog.open(PatientAuthSaveUpdateFormComponent, {
      data: {
        documentId: this.documentId,
        // defaultFaxId:data?.defaultFaxId
      },
    });
    const componentInstance = dialogRef.componentInstance;
    componentInstance.patientId = this.patientId;
    componentInstance.verificationId = this.priVerificationId;
    componentInstance.docId = this.shippingListDetails?.faxDocumentId;
    componentInstance.faxId = ''; //this.selectedShippingData?.defaultFaxId;
    componentInstance.isView = false;
    componentInstance.isSupplies = true;
    componentInstance.authorizationId = this.authorizationId;
    componentInstance.chartId = this.shippingListDetails?.chartNo;
  }
}
