import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {
  step: boolean = false;
  ontimeProfile: FormGroup;
  isShowProgress: boolean = false;
  displayedColumns: string[] = [  'Type', 'From Date', 'Comments', 'Status','options',];
  dataSource = new MatTableDataSource();
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild('MatPaginator', { static: false }) paginator: MatPaginator;
  constructor(
    private formBuilder: FormBuilder,
  ) { }


  ngOnInit(): void {
    this.createOntimeProfieForm();

  }

  createOntimeProfieForm(){
    this.ontimeProfile = this.formBuilder.group({
      Sno: '',
      type: '',
      fromDate: '',
      toDate: '',
      comments: '',
      status: '',
    
    })
  }

  leave: leaves[] = [
    {value: '0', viewValue: 'Day Off'},
    {value: '1', viewValue: 'Vacation'},
  ];

}
interface leaves {
  value: string;
  viewValue: string;
}