import { Injectable } from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';
export type csError = { number: boolean, msg: string };
// import packages if needed
// custom validator to check number validation
@Injectable({
  providedIn: 'root'
})
// export class for validate numbers like mobile, phone, ssn etc..
export class NumberValidators {

  // custom validator to check price number validation
  isNumberCheck(c: any): csError {

    if (c?.parent?.controls?.txtPrice?.value) {
      return getPriceValidation("txtPrice", c?.parent);
    }
    const value: FormGroup = c?.root ?? null;
    for (const data in value?.controls) {
      if (value?.controls?.hasOwnProperty(data)) {
        if (data?.includes('Mobile') || data?.includes('Phone') || data?.includes('Fax')) {
          const val = value?.controls[data] ?? null;
          if ((/^[.\d]+$/.test(val?.value)) && (val?.value)?.length < 10) {
            return getSetErrorValue({ number: true, msg: "Your input should contain atleast 10 digits" });
          } else if ((/^[.\d]+$/.test(val?.value)) && (val?.value)?.length > 10) {
            return getSetErrorValue({ number: true, msg: "You are exceeding the limit" });
          }
          return getSetErrorValue(null);
        } else if (data?.includes('SSNSSN') || data?.includes('TrackingNumber')) {
          const val = value?.controls[data] ?? null;
          if ((/^[.\d]+$/.test(val?.value)) && (val?.value)?.length < 9) {
            return getSetErrorValue({ number: true, msg: "Your input should contain atleast 9 digits" });
          } else if ((/^[.\d]+$/.test(val?.value)) && (val?.value)?.length > 9) {
            return getSetErrorValue({ number: true, msg: "You are exceeding the limit" });
          }
          return getSetErrorValue(null);
        } else if (data?.includes('AccountNumber')) {
          const val = value?.controls[data] ?? null;
          if ((/^[.\d]+$/.test(val?.value)) && (val?.value)?.length < 15) {
            return getSetErrorValue({ number: true, msg: "Your input should contain atleast 15 digits" });
          } else if ((/^[.\d]+$/.test(val?.value)) && (val?.value)?.length > 15) {
            return getSetErrorValue({ number: true, msg: "You are exceeding the limit" });
          }
          return getSetErrorValue(null);
        } else if (data?.toLocaleLowerCase()?.includes('price')) {
          return getPriceValidation(data, value);
        }
        return getSetErrorValue(null);
      }
    }
    const number = /^[.\d]+$/.test(c?.value) ? c?.value : "";
    if (c && c?.value && !number) {
      return getSetErrorValue({ number: true, msg: "Please Enter Valid Number" });
    }
    return getSetErrorValue(null);
  };


}
function getSetErrorValue(error: csError | null): csError | null {
  const errorValue = error;
  return errorValue;
}
function getPriceValidation(data: string, value: FormGroup) {
  const val = value?.controls[data] ?? null;
  const controlValue: string | null = String(val?.value) ?? null;
  let decimalValue: string | null = null;
  let intergerValue = controlValue;
  const numberValidator:string  =RegExp("^([1-9]\.\d+)|([1-9]\d*\.?\d{0,2})").test(controlValue)? controlValue : "";
  const number = /^[.\d]+$/.test(numberValidator) ? numberValidator : "";
  if (number==='') {
    return getSetErrorValue({ number: true, msg: "Please Enter Valid Number" });
  }
  if (numberValidator==="") {
    return getSetErrorValue({ number: true, msg: "Enter Greater than 1!" });
  }
  if ((occurrences(numberValidator, ".", false)) === 0 || (occurrences(numberValidator, ".", false)) === 1) {
    if (numberValidator && numberValidator?.includes(".", 0) && numberValidator?.split(".") && numberValidator?.split(".")[1]) {
      decimalValue = numberValidator?.split(".")[1] ?? null
    }
    if (numberValidator && numberValidator?.includes(".") && numberValidator?.split(".") && numberValidator?.split(".")[0]) {
      intergerValue = numberValidator?.split(".")[0] ?? numberValidator
    }
    if (intergerValue && intergerValue?.length !== 0 && numberValidator?.includes(".") && decimalValue && decimalValue?.length > 2) {
      return getSetErrorValue({ number: true, msg: "Your input should contain atmost 2 decimal number" });
    } else if (intergerValue && intergerValue?.length !== 0 && numberValidator?.includes(".") && (decimalValue?.length ?? 0) <= 0) {
      return getSetErrorValue({ number: true, msg: "Your input should contain atleast 1 decimal number" });
    }
    return getSetErrorValue(null);
  } else {
    return getSetErrorValue({ number: true, msg: "Please Enter Valid Number" });
  }
}

function occurrences(string: string = '', subString: string = '', allowOverlapping: boolean = false): number {
  string += "";
  subString += "";
  if (subString?.length <= 0) return (string?.length + 1);
  let n: number = 0;
  let pos: number = 0;
  let step: number = allowOverlapping ? 1 : subString?.length;

  while (true) {
    pos = string?.indexOf(subString, pos);
    if (pos >= 0) {
      ++n;
      pos += step;
    } else break;
  }
  return n;
}

