<div class="row">
  <div class="col-12">
    <div class="card card-body p-1">
      <form [formGroup]="paymentSearchForm">
        <div>
          <!-- <mat-form-field class="col-2">
            <mat-label>Status</mat-label>
            <mat-select formControlName="drpStatus">
              <mat-option>
                <ngx-mat-select-search
                  placeholderLabel="Search"
                  noEntriesFoundLabel="No Matches found"
                  hideClearSearchButton="true"
                  formControlName="txtStatusFilter"
                ></ngx-mat-select-search>
              </mat-option>
              <mat-option value="New">New</mat-option>
              <mat-option value="Applied">Applied</mat-option>
              <mat-option value="Unapplied">Unapplied</mat-option>
              <mat-option value="Failed">Failed</mat-option>
              <mat-option value="Completed">Completed</mat-option>
            </mat-select>
            <button
              mat-button
              *ngIf="
                paymentSearchForm.get('drpStatus').value != '' &&
                paymentSearchForm.get('drpStatus').value != null &&
                paymentSearchForm.get('drpStatus').value != undefined
              "
              (click)="clearStatus(); $event.stopPropagation()"
              matSuffix
              mat-icon-button
            >
              <mat-icon>close</mat-icon>
            </button>
          </mat-form-field> -->
          <mat-form-field class="col-2">
            <mat-label>Method</mat-label>
            <mat-select
              formControlName="drpMethod"
              (selectionChange)="buildFilterParams()"
            >
              <mat-option>
                <ngx-mat-select-search
                  placeholderLabel="Method"
                  noEntriesFoundLabel="No Matches found"
                  formControlName="txtMethodFilter"
                ></ngx-mat-select-search>
              </mat-option>
              <mat-option
                *ngFor="let paymentTypes of filteredPaymentTypes | async"
                [value]="paymentTypes.id"
              >
                {{ paymentTypes.statusName }}
              </mat-option>
            </mat-select>
            <button
              mat-button
              *ngIf="
                paymentSearchForm.get('drpMethod').value != '' &&
                paymentSearchForm.get('drpMethod').value != null &&
                paymentSearchForm.get('drpMethod').value != undefined
              "
              (click)="
                clearMethod(); $event.stopPropagation(); buildFilterParams()
              "
              matSuffix
              mat-icon-button
            >
              <mat-icon>close</mat-icon>
            </button>
          </mat-form-field>
          <mat-form-field class="col-2">
            <mat-label>Created Date</mat-label>
            <mat-date-range-input [rangePicker]="picker">
              <input
                matStartDate
                matInput
                (keydown)="addSlashToDate($event)"
                placeholder="Start date"
                formControlName="txtCreatedStartDate"
              />

              <input
                matEndDate
                (dateChange)="onCreatedDateChange($event)"
                (keydown)="addSlashToDate($event)"
                matInput
                placeholder="End date"
                formControlName="txtCreatedEndDate"
              />
            </mat-date-range-input>
            <mat-datepicker-toggle
              matSuffix
              [for]="picker"
            ></mat-datepicker-toggle>
            <mat-date-range-picker #picker></mat-date-range-picker>
            <button
              mat-button
              *ngIf="
                (paymentSearchForm.get('txtCreatedStartDate').value != '' &&
                  paymentSearchForm.get('txtCreatedStartDate').value != null &&
                  paymentSearchForm.get('txtCreatedStartDate').value !=
                    undefined) ||
                (paymentSearchForm.get('txtCreatedEndDate').value != '' &&
                  paymentSearchForm.get('txtCreatedEndDate').value != null &&
                  paymentSearchForm.get('txtCreatedEndDate').value != undefined)
              "
              (click)="
                clearCreatedDate();
                $event.stopPropagation();
                buildFilterParams()
              "
              matSuffix
              mat-icon-button
            >
              <mat-icon>close</mat-icon>
            </button>
          </mat-form-field>
          <mat-form-field class="col-2">
            <input
              matInput
              (change)="buildFilterParams()"
              formControlName="txtReferenceNo"
              placeholder="Check/Invoice No."
              autocomplete="off"
            />
          </mat-form-field>
          <mat-form-field class="col-2">
            <mat-label>Remittance Date</mat-label>
            <mat-date-range-input [rangePicker]="pickerRemittance">
              <input
                matStartDate
                (keydown)="addSlashToDate($event)"
                matInput
                placeholder="Start date"
                formControlName="txtRemittanceStartDate"
              />

              <input
                matEndDate
                matInput
                (dateChange)="onRemittanceDateChange($event)"
                (keydown)="addSlashToDate($event)"
                placeholder="End date"
                formControlName="txtRemittanceEndDate"
              />
            </mat-date-range-input>
            <mat-datepicker-toggle
              matSuffix
              [for]="pickerRemittance"
            ></mat-datepicker-toggle>
            <mat-date-range-picker #pickerRemittance></mat-date-range-picker>
            <button
              mat-button
              *ngIf="
                (paymentSearchForm.get('txtRemittanceStartDate').value != '' &&
                  paymentSearchForm.get('txtRemittanceStartDate').value !=
                    null &&
                  paymentSearchForm.get('txtRemittanceStartDate').value !=
                    undefined) ||
                (paymentSearchForm.get('txtRemittanceEndDate').value != '' &&
                  paymentSearchForm.get('txtRemittanceEndDate').value != null &&
                  paymentSearchForm.get('txtRemittanceEndDate').value !=
                    undefined)
              "
              (click)="
                clearRemittanceDate();
                $event.stopPropagation();
                buildFilterParams()
              "
              matSuffix
              mat-icon-button
            >
              <mat-icon>close</mat-icon>
            </button>
          </mat-form-field>
          <button
            type="button"
            (click)="resetForm()"
            matTooltip="Reset"
            mat-raised-button
            class="reset-icon-button mr-2 px-2 mat-raised-small-button"
          >
            <mat-icon class="fg-white">refresh</mat-icon>
          </button>
          <!-- <button
            type="button"
            (click)="openEOBModel()"
            matTooltip="Upload Check/EFT"
            mat-raised-button
            class="buttonColor mr-2 px-2 mat-raised-small-button"
          >
            <mat-icon class="fg-white">cloud_upload</mat-icon>
          </button> -->
        </div>
      </form>
    </div>
  </div>
</div>

<div class="row mt-2 mb-3" *ngIf="!isLoading">
  <div class="col-12">
    <div class="card card-body p-1">
      <button
        type="button"
        (click)="openEOBModel()"
        matTooltip="Upload Check/EFT"
        mat-raised-button
        class="buttonColor ml-2 px-2 mt-1 mat-raised-small-button z-1 position-absolute"
      >
        <mat-icon class="fg-white">cloud_upload</mat-icon>
      </button>
      <div class="table table-responsive">
        <table
          datatable
          [dtOptions]="billingPaymentTable"
          class="row-border hover w-100 display"
        >
          <thead>
            <tr>
              <th [matTooltip]="'Options'"><mat-icon>apps</mat-icon></th>
              <th [matTooltip]="'Check/Invoice No.'">Check/Invoice No.</th>
              <th [matTooltip]="'Payer'">Payer</th>
              <th [matTooltip]="'Method'">Method</th>
              <th class="text-right" [matTooltip]="'Payment ($)'">
                Payment ($)
              </th>
              <th class="text-right" [matTooltip]="'Adjustments ($)'">
                Adjustments ($)
              </th>
              <th class="text-right" [matTooltip]="'Total ($)'">Total ($)</th>
              <!-- <th class="text-right" [matTooltip]="'Unapplied ($)'">
                Unapplied ($)
              </th> -->
              <th [matTooltip]="'Remittance/Paid Date'">
                Remittance/Paid Date
              </th>
              <th [matTooltip]="'Created By'">Created By</th>
              <th [matTooltip]="'Created Date'">Created Date</th>
              <th [matTooltip]="'Last Modified By'">Last Modified By</th>
              <th [matTooltip]="'Last Modified Date'">Last Modified Date</th>

              <!-- <th [matTooltip]="'Claim Count'">Claim Count</th> -->
              <!-- <th>Status</th> -->
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let invoice of ltPaymentsReport">
              <td>
                <mat-icon
                  style="cursor: pointer !important"
                  [matMenuTriggerFor]="menu"
                  >more_vert</mat-icon
                >
                <mat-menu class="custom-mat-menu" #menu="matMenu">
                  <a
                    [class.disabled]="
                      invoice.pdfBlobName == '' || invoice.pdfBlobName == null
                    "
                    [routerLink]="
                      '/patient_preview_document/' +
                      invoice.pdfBlobName +
                      '/' +
                      invoice.referenceNumber +
                      '/' +
                      2
                    "
                    target="_blank"
                    class="preview-link"
                  >
                    <button
                      [disabled]="
                        invoice.pdfBlobName == '' || invoice.pdfBlobName == null
                      "
                      mat-menu-item
                    >
                      View EOB
                    </button>
                  </a>

                  <button
                    mat-menu-item
                    [disabled]="
                      invoice.pdfBlobName == '' || invoice.pdfBlobName == null
                    "
                    (click)="
                      downloadEOB(invoice.pdfBlobName, invoice.referenceNumber)
                    "
                  >
                    Download EOB
                  </button>
                  <button
                    mat-menu-item
                    [disabled]="
                      invoice.eraBlobName == '' || invoice.eraBlobName == null
                    "
                    (click)="
                      downloadEOB(invoice.eraBlobName, invoice.referenceNumber)
                    "
                  >
                    Download ERA
                  </button>
                </mat-menu>
              </td>
              <td>
                <div class="d-flex justify-content-between align-items-center">
                  <span
                    [matTooltip]="invoice?.referenceNumber || '-'"
                    class="pr-2 checkNo-batch"
                    >{{ invoice.referenceNumber || "-" }}</span
                  >
                  <span
                    *ngIf="invoice.paymentMethod !== 'Cash'"
                    [matTooltip]="'Claim Count : ' + invoice?.claimCount || '-'"
                    class="claim-count-badge text-center align-middle"
                    mat-icon-button
                    >{{ invoice.claimCount }}</span
                  >
                </div>
              </td>

              <td [matTooltip]="invoice?.payerName || '-'">
                {{ invoice.payerName || "-" }}
              </td>
              <td [matTooltip]="invoice?.paymentMethod || '-'">
                {{ invoice.paymentMethod || "-" }}
              </td>
              <td class="text-right" [matTooltip]="invoice?.payment || '-'">
                {{ invoice.payment || "-" }}
              </td>
              <td class="text-right" [matTooltip]="invoice?.adjustments || '-'">
                {{ invoice.adjustments || "-" }}
              </td>
              <td class="text-right" [matTooltip]="invoice?.total || '-'">
                {{ invoice.total || "-" }}
              </td>

              <!-- <td class="text-right" [matTooltip]="'-'">-</td> -->
              <td [matTooltip]="invoice?.remittanceDate || '-'">
                {{ invoice.remittanceDate || "-" }}
              </td>
              <td [matTooltip]="invoice?.createdBy || '-'">
                {{ invoice.createdBy || "-" }}
              </td>
              <td [matTooltip]="invoice?.createdDate || '-'">
                {{ invoice.createdDate || "-" }}
              </td>

              <td [matTooltip]="invoice?.modifiedBy || '-'">
                {{ invoice.modifiedBy || "-" }}
              </td>
              <td [matTooltip]="invoice?.modifiedDate || '-'">
                {{ invoice.modifiedDate || "-" }}
              </td>
              <!--
              <td class="text-right" [matTooltip]="invoice?.claimCount || '-'">
                {{ invoice.claimCount || "-" }}
              </td> -->
              <!-- <td>
                <span *ngIf="!isEmpty(invoice.note)">
                  <mat-icon
                    mat-icon-button
                    class="pl-2 ico-small"
                    [matTooltip]="invoice.note"
                    >notes</mat-icon
                  >
                </span>
                <span *ngIf="isEmpty(invoice.note)">-</span>
              </td> -->
              <!-- <td class="text-center">
                <div class="d-flex align-items-center">
                  <span
                    [matTooltip]="invoice?.status || '-'"
                    [ngStyle]="{
                      'background-color': invoice.statusBgColor,
                      color: invoice.statusFgColor
                    }"
                    class="rounded-border ml-1 w-75 p-1 claim-status-label"
                  >
                    {{ invoice?.status || "-" }}
                  </span>
                </div>
              </td> -->
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>
<div class="row" *ngIf="isLoading">
  <div class="col-12">
    <div class="d-flex justify-content-center">
      <img
        class="img-responsive pb-2 mr-1"
        src="assets/gifs/Infinity-loader.gif"
      />
    </div>
  </div>
</div>
<app-loader-component *ngIf="isDataLoaded"></app-loader-component>
