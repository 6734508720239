import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DataTableDirective } from 'angular-datatables';
import { PreviewDocumentComponent } from 'projects/order/src/app/preview-document/preview-document.component';
import { Subscription } from 'rxjs';
import { ClaimViewTabComponent } from '../../claim-view-tab/claim-view-tab.component';
import { ClaimUploadEOBComponent } from '../../auto-posting-era/upload-eob/upload-eob.component';
import { UploadEOBComponent } from '../../claim-status-list/claim-status-list-table/upload-eob/upload-eob.component';
import { DatePipe } from '@angular/common';
import { defaultGuid } from 'projects/shared/src/app/enums/allenums.enum';
import { CommunicationService } from 'projects/shared/src/app/services/communication.service';

@Component({
  selector: 'app-claim-cheque-list-table',
  templateUrl: './claim-cheque-list-table.component.html',
  styleUrls: ['./claim-cheque-list-table.component.scss'],
})
export class ClaimChequeListTableComponent implements OnInit {
  @Input() claimsTableData: any;
  @ViewChild(DataTableDirective, { static: false })
  datatableElement: DataTableDirective;
  $subscription: Subscription[] = [];
  dtClaimsTableOptions: any = {
    responsive: true,
    paging: false,
    dom: 'Bfrtip',
    language: {
      emptyTable: 'No records',
      info: 'Total : _MAX_ records',
      infoEmpty: 'Total : _MAX_ records',
      infoFiltered: '(filtered : _TOTAL_ records)',
      loadingRecords: 'Loading...',
      zeroRecords: 'No matching records',
    },
    fixedHeader: true, // Fix the header

    scrollCollapse: true,
    scrollY: '500px',
    scrollX: true,
    order: [],
    search: {
      smart: false,
    },
    buttons: [
      {
        extend: 'excel',
        text: 'Export', // Change the text to 'Export'
        filename: 'Claim Check List',
        exportOptions: {
          columns: ':visible:not(:nth-child(0))',
        },
      },
      {
        extend: 'colvis',
        columns: ':not(:eq(0), :eq(1), :eq(2), :eq(3), :eq(4) )',
      },
      {
        text: 'Show Default Column',
        action: function (e, dt, node, config) {
          dt.columns(':not(:eq(0), :eq(1), :eq(2),:eq(3), :eq(-1))').visible(false);
        },
      },
      {
        text: 'Show All Column',
        action: function (e, dt, node, config) {
          dt.columns().visible(true);
        },
      },
    ],
  };

  constructor(private dialog: MatDialog, private datepipe: DatePipe,
    private communicationService: CommunicationService
  ) {}

  ngOnInit(): void {
    // this.dtClaimsTableOptions.buttons[0].filename =
    // 'Claim Check List';
    this.dtClaimsTableOptions.buttons[0].filename = 'Claim Check List' + '-' + this.datepipe.transform(new Date(), 'MM-dd-yyyy');
    // 'Claim Check List' + '-' + this.datepipe.transform(new Date(), 'MM-dd-yyyy');
    this.calculateScrollY();
    window.addEventListener('resize', this.calculateScrollY);
  }
  ngOnDestroy(): void {
    window.removeEventListener('resize', this.calculateScrollY);
  }
  ngAfterViewInit(): void {
    if (this.datatableElement && this.datatableElement.dtInstance) {
      this.refreshDataTable();
    } else {
      // If dtInstance is not available yet, wait for a short time and try again
      setTimeout(() => this.refreshDataTable(), 500);
    }
  }
  calculateScrollY = () => {
    const screenHeight =
      window.innerHeight ||
      document.documentElement.clientHeight ||
      document.body.clientHeight;
    const availableSpacePercentage = 0.55; // Adjust the percentage as needed
    this.dtClaimsTableOptions.scrollY = screenHeight * availableSpacePercentage;
  };

  //To refresh the Datatable
  refreshDataTable(): void {
    this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
      // Clear search input values
      $('input', dtInstance.table(0).footer()).val('');

      // Redraw the DataTable to load all records
      dtInstance.search('').columns().search('').draw();

      // Attach the event listener for filtering
      dtInstance.columns().every(function () {
        const that = this;
        $('input', this.footer()).on('keyup change', function () {
          if (that.search() !== this['value']) {
            that.search(this['value']).draw();
          }
        });
      });
    });
  }

  previewDocumet(data){
      const dialogRef = this.dialog.open(PreviewDocumentComponent, {
        height: '75%',
        width: '80%',
        disableClose: true,
        data: {
          // Add any data you want to pass to the dialog here
          // For example, you can pass patientId and documentId

          documentData: data.blobName,

          isClaim: true

        },
      });

      // Subscribe to the closed event
      dialogRef.afterClosed().subscribe(() => {
        // Call the addNotes function directly
        // dialogRef.componentInstance.addNotes();
      });
  }
  isEmpty(value: any): boolean {
    return (
      value === null ||
      value === undefined ||
      value === '' ||
      value === defaultGuid
    );
  }
  openClaimForm(
    orderId: string,
    patientId: string,
    chartNo: string,
    patientName: string,
    defaultSaleOrderId: string,
    claimId: string,
    isClaimBatch: boolean,
    isResubmit?: boolean
  ) {
    const dialogRef = this.dialog.open(ClaimViewTabComponent, {
      disableClose: true,
      data: {
        orderId: orderId,
        patientId: patientId,
        defaultSaleOrderId: defaultSaleOrderId,
        claimId: claimId,
        isClaimBatch: isClaimBatch,
        fullName: patientName,
        chartNo: chartNo,
        isResubmit: isResubmit ?? false,
      },
    });

    const closeDialog = dialogRef.afterClosed().subscribe(
      (claimDetails: any) => {
        if (isResubmit) {
          this.communicationService.functionLoadClaimStatusList();
        }
        // console.log('claimDetails')
        // console.log(claimDetails)
        // // && item.policyId === claimDetails.insurancePlanName
        // const existingItemIndex = this.lstClaimProcessList.findIndex(
        //   (item) => item.mmOrderId === claimDetails.orderId
        // );
        // if (existingItemIndex !== -1) {
        //   // Item with the flag already exists, update it
        //   this.lstClaimProcessList[existingItemIndex].claimId=claimDetails.id;
        //   this.lstClaimProcessList[existingItemIndex].status='No Pending Data';
        //   this.lstClaimProcessList[existingItemIndex].selected=true;
        //   let x = this.lstClaimProcessList.filter((dx) =>
        //     dx.selected === true
        //   );
        //   this.totalSelectedOrders++;
        //   this.claimIdArray.push(claimDetails.id)
        //   this.countsUpdate.emit({ claimId : claimDetails.id,status:true,total:null});
        // }
      },
      (err) => {
        // const data: HttpErrorResponse = err;
        // console.warn(data?.error?.error?.message)
        // Swal.fire({
        //   icon: 'info',
        //   text: data?.error?.error?.message,
        // });
      }
    );

    // dialogRef.afterClosed().subscribe(() => {
    //   // this.loadClaimProcessList()

    //   //  this.lstClaimProcessList.forEach(x => {
    //   //  if(x.claimId != defaultGuid){
    //   //   this.claimIdArray.push(x.claimId)
    //   //   x.selected = true

    //   //  }
    //   // })

    // });
  }

  openEOBModel(data) {
    let isSaveEnable: boolean = true;
    let cliamData: any;
    this.dialog.open(UploadEOBComponent, {
      width: '100vw',
      height: '100vh',
      maxWidth: '100vw',
      maxHeight: '100vh',
      panelClass: 'fullscreen-dialog-panel',
      disableClose: true,
      data: {
        cliamData: data.claimId,
        isSaveEnable: isSaveEnable,
      },
    });
    this.dialog.afterAllClosed.subscribe((resposne) => {});
  }
}

const checkDataArray: any[] = [
  {
    checkNumber: 'CHK001',
    checkAmount: 500.0,
    claimNumber: 'C001',
    chartNo: 'CH001',
    patientName: 'John Doe',
    clearingHouse: 'Clearing House A',
    postingDate: '2023-01-20',
    billedAmount: 200.0,
    currentBalance: 100.0,
    checkDate: '2023-01-25',
  },
  {
    checkNumber: 'CHK002',
    checkAmount: 800.0,
    claimNumber: 'C002',
    chartNo: 'CH002',
    patientName: 'Jane Smith',
    clearingHouse: 'Clearing House B',
    postingDate: '2023-02-25',
    billedAmount: 150.0,
    currentBalance: 50.0,
    checkDate: '2023-03-01',
  },
  {
    checkNumber: 'CHK002',
    checkAmount: 800.0,
    claimNumber: 'C002',
    chartNo: 'CH002',
    patientName: 'Jane Smith',
    clearingHouse: 'Clearing House B',
    postingDate: '2023-02-25',
    billedAmount: 150.0,
    currentBalance: 50.0,
    checkDate: '2023-03-01',
  },
  {
    checkNumber: 'CHK003',
    checkAmount: 600.0,
    claimNumber: 'C003',
    chartNo: 'CH003',
    patientName: 'Bob Johnson',
    clearingHouse: 'Clearing House C',
    postingDate: '2023-03-15',
    billedAmount: 300.0,
    currentBalance: 200.0,
    checkDate: '2023-03-20',
  },
  {
    checkNumber: 'CHK004',
    checkAmount: 1000.0,
    claimNumber: 'C004',
    chartNo: 'CH004',
    patientName: 'Alice Williams',
    clearingHouse: 'Clearing House A',
    postingDate: '2023-04-10',
    billedAmount: 100.0,
    currentBalance: 0.0,
    checkDate: '2023-04-15',
  },
  {
    checkNumber: 'CHK004',
    checkAmount: 1000.0,
    claimNumber: 'C004',
    chartNo: 'CH004',
    patientName: 'Alice Williams',
    clearingHouse: 'Clearing House A',
    postingDate: '2023-04-10',
    billedAmount: 100.0,
    currentBalance: 0.0,
    checkDate: '2023-04-15',
  },{
    checkNumber: 'CHK004',
    checkAmount: 1000.0,
    claimNumber: 'C004',
    chartNo: 'CH004',
    patientName: 'Alice Williams',
    clearingHouse: 'Clearing House A',
    postingDate: '2023-04-10',
    billedAmount: 100.0,
    currentBalance: 0.0,
    checkDate: '2023-04-15',
  },{
    checkNumber: 'CHK004',
    checkAmount: 1000.0,
    claimNumber: 'C004',
    chartNo: 'CH004',
    patientName: 'Alice Williams',
    clearingHouse: 'Clearing House A',
    postingDate: '2023-04-10',
    billedAmount: 100.0,
    currentBalance: 0.0,
    checkDate: '2023-04-15',
  },{
    checkNumber: 'CHK004',
    checkAmount: 1000.0,
    claimNumber: 'C004',
    chartNo: 'CH004',
    patientName: 'Alice Williams',
    clearingHouse: 'Clearing House A',
    postingDate: '2023-04-10',
    billedAmount: 100.0,
    currentBalance: 0.0,
    checkDate: '2023-04-15',
  },{
    checkNumber: 'CHK004',
    checkAmount: 1000.0,
    claimNumber: 'C004',
    chartNo: 'CH004',
    patientName: 'Alice Williams',
    clearingHouse: 'Clearing House A',
    postingDate: '2023-04-10',
    billedAmount: 100.0,
    currentBalance: 0.0,
    checkDate: '2023-04-15',
  },{
    checkNumber: 'CHK004',
    checkAmount: 1000.0,
    claimNumber: 'C004',
    chartNo: 'CH004',
    patientName: 'Alice Williams',
    clearingHouse: 'Clearing House A',
    postingDate: '2023-04-10',
    billedAmount: 100.0,
    currentBalance: 0.0,
    checkDate: '2023-04-15',
  },{
    checkNumber: 'CHK004',
    checkAmount: 1000.0,
    claimNumber: 'C004',
    chartNo: 'CH004',
    patientName: 'Alice Williams',
    clearingHouse: 'Clearing House A',
    postingDate: '2023-04-10',
    billedAmount: 100.0,
    currentBalance: 0.0,
    checkDate: '2023-04-15',
  },{
    checkNumber: 'CHK004',
    checkAmount: 1000.0,
    claimNumber: 'C004',
    chartNo: 'CH004',
    patientName: 'Alice Williams',
    clearingHouse: 'Clearing House A',
    postingDate: '2023-04-10',
    billedAmount: 100.0,
    currentBalance: 0.0,
    checkDate: '2023-04-15',
  },{
    checkNumber: 'CHK004',
    checkAmount: 1000.0,
    claimNumber: 'C004',
    chartNo: 'CH004',
    patientName: 'Alice Williams',
    clearingHouse: 'Clearing House A',
    postingDate: '2023-04-10',
    billedAmount: 100.0,
    currentBalance: 0.0,
    checkDate: '2023-04-15',
  },{
    checkNumber: 'CHK004',
    checkAmount: 1000.0,
    claimNumber: 'C004',
    chartNo: 'CH004',
    patientName: 'Alice Williams',
    clearingHouse: 'Clearing House A',
    postingDate: '2023-04-10',
    billedAmount: 100.0,
    currentBalance: 0.0,
    checkDate: '2023-04-15',
  },{
    checkNumber: 'CHK004',
    checkAmount: 1000.0,
    claimNumber: 'C004',
    chartNo: 'CH004',
    patientName: 'Alice Williams',
    clearingHouse: 'Clearing House A',
    postingDate: '2023-04-10',
    billedAmount: 100.0,
    currentBalance: 0.0,
    checkDate: '2023-04-15',
  },{
    checkNumber: 'CHK004',
    checkAmount: 1000.0,
    claimNumber: 'C004',
    chartNo: 'CH004',
    patientName: 'Alice Williams',
    clearingHouse: 'Clearing House A',
    postingDate: '2023-04-10',
    billedAmount: 100.0,
    currentBalance: 0.0,
    checkDate: '2023-04-15',
  },{
    checkNumber: 'CHK004',
    checkAmount: 1000.0,
    claimNumber: 'C004',
    chartNo: 'CH004',
    patientName: 'Alice Williams',
    clearingHouse: 'Clearing House A',
    postingDate: '2023-04-10',
    billedAmount: 100.0,
    currentBalance: 0.0,
    checkDate: '2023-04-15',
  },{
    checkNumber: 'CHK004',
    checkAmount: 1000.0,
    claimNumber: 'C004',
    chartNo: 'CH004',
    patientName: 'Alice Williams',
    clearingHouse: 'Clearing House A',
    postingDate: '2023-04-10',
    billedAmount: 100.0,
    currentBalance: 0.0,
    checkDate: '2023-04-15',
  },{
    checkNumber: 'CHK004',
    checkAmount: 1000.0,
    claimNumber: 'C004',
    chartNo: 'CH004',
    patientName: 'Alice Williams',
    clearingHouse: 'Clearing House A',
    postingDate: '2023-04-10',
    billedAmount: 100.0,
    currentBalance: 0.0,
    checkDate: '2023-04-15',
  },{
    checkNumber: 'CHK004',
    checkAmount: 1000.0,
    claimNumber: 'C004',
    chartNo: 'CH004',
    patientName: 'Alice Williams',
    clearingHouse: 'Clearing House A',
    postingDate: '2023-04-10',
    billedAmount: 100.0,
    currentBalance: 0.0,
    checkDate: '2023-04-15',
  },{
    checkNumber: 'CHK004',
    checkAmount: 1000.0,
    claimNumber: 'C004',
    chartNo: 'CH004',
    patientName: 'Alice Williams',
    clearingHouse: 'Clearing House A',
    postingDate: '2023-04-10',
    billedAmount: 100.0,
    currentBalance: 0.0,
    checkDate: '2023-04-15',
  },{
    checkNumber: 'CHK004',
    checkAmount: 1000.0,
    claimNumber: 'C004',
    chartNo: 'CH004',
    patientName: 'Alice Williams',
    clearingHouse: 'Clearing House A',
    postingDate: '2023-04-10',
    billedAmount: 100.0,
    currentBalance: 0.0,
    checkDate: '2023-04-15',
  },{
    checkNumber: 'CHK004',
    checkAmount: 1000.0,
    claimNumber: 'C004',
    chartNo: 'CH004',
    patientName: 'Alice Williams',
    clearingHouse: 'Clearing House A',
    postingDate: '2023-04-10',
    billedAmount: 100.0,
    currentBalance: 0.0,
    checkDate: '2023-04-15',
  },{
    checkNumber: 'CHK004',
    checkAmount: 1000.0,
    claimNumber: 'C004',
    chartNo: 'CH004',
    patientName: 'Alice Williams',
    clearingHouse: 'Clearing House A',
    postingDate: '2023-04-10',
    billedAmount: 100.0,
    currentBalance: 0.0,
    checkDate: '2023-04-15',
  },{
    checkNumber: 'CHK004',
    checkAmount: 1000.0,
    claimNumber: 'C004',
    chartNo: 'CH004',
    patientName: 'Alice Williams',
    clearingHouse: 'Clearing House A',
    postingDate: '2023-04-10',
    billedAmount: 100.0,
    currentBalance: 0.0,
    checkDate: '2023-04-15',
  },{
    checkNumber: 'CHK004',
    checkAmount: 1000.0,
    claimNumber: 'C004',
    chartNo: 'CH004',
    patientName: 'Alice Williams',
    clearingHouse: 'Clearing House A',
    postingDate: '2023-04-10',
    billedAmount: 100.0,
    currentBalance: 0.0,
    checkDate: '2023-04-15',
  },{
    checkNumber: 'CHK004',
    checkAmount: 1000.0,
    claimNumber: 'C004',
    chartNo: 'CH004',
    patientName: 'Alice Williams',
    clearingHouse: 'Clearing House A',
    postingDate: '2023-04-10',
    billedAmount: 100.0,
    currentBalance: 0.0,
    checkDate: '2023-04-15',
  },{
    checkNumber: 'CHK004',
    checkAmount: 1000.0,
    claimNumber: 'C004',
    chartNo: 'CH004',
    patientName: 'Alice Williams',
    clearingHouse: 'Clearing House A',
    postingDate: '2023-04-10',
    billedAmount: 100.0,
    currentBalance: 0.0,
    checkDate: '2023-04-15',
  },{
    checkNumber: 'CHK004',
    checkAmount: 1000.0,
    claimNumber: 'C004',
    chartNo: 'CH004',
    patientName: 'Alice Williams',
    clearingHouse: 'Clearing House A',
    postingDate: '2023-04-10',
    billedAmount: 100.0,
    currentBalance: 0.0,
    checkDate: '2023-04-15',
  },{
    checkNumber: 'CHK004',
    checkAmount: 1000.0,
    claimNumber: 'C004',
    chartNo: 'CH004',
    patientName: 'Alice Williams',
    clearingHouse: 'Clearing House A',
    postingDate: '2023-04-10',
    billedAmount: 100.0,
    currentBalance: 0.0,
    checkDate: '2023-04-15',
  },{
    checkNumber: 'CHK004',
    checkAmount: 1000.0,
    claimNumber: 'C004',
    chartNo: 'CH004',
    patientName: 'Alice Williams',
    clearingHouse: 'Clearing House A',
    postingDate: '2023-04-10',
    billedAmount: 100.0,
    currentBalance: 0.0,
    checkDate: '2023-04-15',
  },{
    checkNumber: 'CHK004',
    checkAmount: 1000.0,
    claimNumber: 'C004',
    chartNo: 'CH004',
    patientName: 'Alice Williams',
    clearingHouse: 'Clearing House A',
    postingDate: '2023-04-10',
    billedAmount: 100.0,
    currentBalance: 0.0,
    checkDate: '2023-04-15',
  },{
    checkNumber: 'CHK004',
    checkAmount: 1000.0,
    claimNumber: 'C004',
    chartNo: 'CH004',
    patientName: 'Alice Williams',
    clearingHouse: 'Clearing House A',
    postingDate: '2023-04-10',
    billedAmount: 100.0,
    currentBalance: 0.0,
    checkDate: '2023-04-15',
  },{
    checkNumber: 'CHK004',
    checkAmount: 1000.0,
    claimNumber: 'C004',
    chartNo: 'CH004',
    patientName: 'Alice Williams',
    clearingHouse: 'Clearing House A',
    postingDate: '2023-04-10',
    billedAmount: 100.0,
    currentBalance: 0.0,
    checkDate: '2023-04-15',
  },{
    checkNumber: 'CHK004',
    checkAmount: 1000.0,
    claimNumber: 'C004',
    chartNo: 'CH004',
    patientName: 'Alice Williams',
    clearingHouse: 'Clearing House A',
    postingDate: '2023-04-10',
    billedAmount: 100.0,
    currentBalance: 0.0,
    checkDate: '2023-04-15',
  },{
    checkNumber: 'CHK004',
    checkAmount: 1000.0,
    claimNumber: 'C004',
    chartNo: 'CH004',
    patientName: 'Alice Williams',
    clearingHouse: 'Clearing House A',
    postingDate: '2023-04-10',
    billedAmount: 100.0,
    currentBalance: 0.0,
    checkDate: '2023-04-15',
  },{
    checkNumber: 'CHK004',
    checkAmount: 1000.0,
    claimNumber: 'C004',
    chartNo: 'CH004',
    patientName: 'Alice Williams',
    clearingHouse: 'Clearing House A',
    postingDate: '2023-04-10',
    billedAmount: 100.0,
    currentBalance: 0.0,
    checkDate: '2023-04-15',
  },{
    checkNumber: 'CHK004',
    checkAmount: 1000.0,
    claimNumber: 'C004',
    chartNo: 'CH004',
    patientName: 'Alice Williams',
    clearingHouse: 'Clearing House A',
    postingDate: '2023-04-10',
    billedAmount: 100.0,
    currentBalance: 0.0,
    checkDate: '2023-04-15',
  },{
    checkNumber: 'CHK004',
    checkAmount: 1000.0,
    claimNumber: 'C004',
    chartNo: 'CH004',
    patientName: 'Alice Williams',
    clearingHouse: 'Clearing House A',
    postingDate: '2023-04-10',
    billedAmount: 100.0,
    currentBalance: 0.0,
    checkDate: '2023-04-15',
  },{
    checkNumber: 'CHK004',
    checkAmount: 1000.0,
    claimNumber: 'C004',
    chartNo: 'CH004',
    patientName: 'Alice Williams',
    clearingHouse: 'Clearing House A',
    postingDate: '2023-04-10',
    billedAmount: 100.0,
    currentBalance: 0.0,
    checkDate: '2023-04-15',
  },{
    checkNumber: 'CHK004',
    checkAmount: 1000.0,
    claimNumber: 'C004',
    chartNo: 'CH004',
    patientName: 'Alice Williams',
    clearingHouse: 'Clearing House A',
    postingDate: '2023-04-10',
    billedAmount: 100.0,
    currentBalance: 0.0,
    checkDate: '2023-04-15',
  },{
    checkNumber: 'CHK004',
    checkAmount: 1000.0,
    claimNumber: 'C004',
    chartNo: 'CH004',
    patientName: 'Alice Williams',
    clearingHouse: 'Clearing House A',
    postingDate: '2023-04-10',
    billedAmount: 100.0,
    currentBalance: 0.0,
    checkDate: '2023-04-15',
  },{
    checkNumber: 'CHK004',
    checkAmount: 1000.0,
    claimNumber: 'C004',
    chartNo: 'CH004',
    patientName: 'Alice Williams',
    clearingHouse: 'Clearing House A',
    postingDate: '2023-04-10',
    billedAmount: 100.0,
    currentBalance: 0.0,
    checkDate: '2023-04-15',
  },
];
