import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Store } from '@ngxs/store';
import { TableService } from 'projects/shared/src/app/table.service';
import Swal from 'sweetalert2';
import { ClaimDTO } from '../billing-proxy/billing-management/posting/dto/models';
import { getBilling } from '../billing-store/billing.action';

@Component({
  selector: 'app-edit-claim-status',
  templateUrl: './edit-claim-status.component.html',
  styleUrls: ['./edit-claim-status.component.scss']
})
export class EditClaimStatusComponent implements OnInit {
  public strPageType: string = "claimTransaction";
  claimInfoForm: FormGroup;
  tblPostReponse: any[] = [];
  @Input() patientId: string = '';

  // drpClaimType:ClaimTypeDTO[]=[];
  // drpSpecialPgm:SpecialProgramDTO[]=[];
  // drpDelayReason:DelayReasonDTO[]=[];
  // drpAuthException:ServiceAuthExceptionDTO[]=[];
  constructor(
    private formBuilder: FormBuilder,
    public tableService: TableService,
    private store: Store,
    private Table: TableService,
    public dialogRef: MatDialogRef<EditClaimStatusComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { claimId: string }
  ) { }

  ngOnInit(): void {
    //  this.getTableData();
    this.createFormGroups();
    this.data.claimId !== "" && this.data.claimId !== null && this.getTableData()
    // this.getDropdown()
  }
  //   getDropdown() {
  //     this.store.dispatch(new getDropdown()).subscribe((stateResponse) => {
  //       let response: MasterDropdownDTO = stateResponse.dropdownState.selectedDropdown
  //
  //       this.drpClaimType = response.claimTypes;
  //       this.drpSpecialPgm = response.specialPrograms;
  //       this.drpDelayReason = response.delayReasons;
  //       this.drpAuthException = response.serviceAuthExceptions;
  //     })
  //   }
  getTableData() {
    let postingDetails: ClaimDTO;
    this.store.dispatch(new getBilling(this.data.claimId)).subscribe(response => {
      //
      postingDetails = response?.billingState?.selectedBilling;
      this.tblPostReponse = [];
      let id = postingDetails.id;
      postingDetails.lstPostingCharges.forEach(element => {
        if (element.postingChargesId !== "00000000-0000-0000-0000-000000000000") {
          this.tblPostReponse.push({
            code: element?.item,
            cpt: "",
            dos: element.dos,
            m1: element?.modifier1,
            m2: element?.modifier2,
            m3: element?.modifier3,
            m4: element?.modifier4,
            fee: element?.fee,
            diagnosis9: element.diagnosisCode9,
            diagnosis10: element?.diagnosisCode10,
            provider: element.provider,
            postingChargesId: element?.postingChargesId,
            statusType: element.status,
            id: id,
            pos: element.pos,
            units: element.units,
            plan: element.insurance
          });

        }
      })
      this.Table.setClaimHistoryDataTable(this.tblPostReponse);
    }, err => {
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    }, () => {
    });
  }
  createFormGroups() {
    this.claimInfoForm = this.formBuilder.group({
      drpClaimType: new FormControl(""),
      txtPayorControlNumber: new FormControl(""),
      drpSpecialProgram: new FormControl(""),
      drpDelayReason: new FormControl(""),
      drpServiceAuthException: new FormControl(""),
    })
  }
}
