import { RestService } from '@abp/ng.core';
import type { PagedAndSortedResultRequestDto, PagedResultDto } from '@abp/ng.core';
import { Injectable } from '@angular/core';
import { CreateUpdateMessageAndMailResponseDTO, MessageAndMailResponseDTO, ScheduleHistoryDTO, historyResponse } from './models';

@Injectable({
  providedIn: 'root',
})
export class MessageAndMailResponseService {
  apiName = 'platformManagement';

  create = (input: CreateUpdateMessageAndMailResponseDTO) =>
    this.restService.request<any, MessageAndMailResponseDTO>({
      method: 'POST',
      url: '/api/PlatformApp/message-and-mail-response',
      body: input,
    },
    { apiName: this.apiName });

  delete = (id: string) =>
    this.restService.request<any, void>({
      method: 'DELETE',
      url: `/api/PlatformApp/message-and-mail-response/${id}`,
    },
    { apiName: this.apiName });

  get = (id: string) =>
    this.restService.request<any, MessageAndMailResponseDTO>({
      method: 'GET',
      url: `/api/PlatformApp/message-and-mail-response/${id}`,
    },
    { apiName: this.apiName });

  getList = (input: PagedAndSortedResultRequestDto) =>
    this.restService.request<any, PagedResultDto<MessageAndMailResponseDTO>>({
      method: 'GET',
      url: '/api/PlatformApp/message-and-mail-response',
      params: { skipCount: input.skipCount, maxResultCount: input.maxResultCount, sorting: input.sorting },
    },
    { apiName: this.apiName });

  update = (id: string, input: CreateUpdateMessageAndMailResponseDTO) =>
    this.restService.request<any, MessageAndMailResponseDTO>({
      method: 'PUT',
      url: `/api/PlatformApp/message-and-mail-response/${id}`,
      body: input,
    },
    { apiName: this.apiName });

  // updateScheduleDetailsByUpdate = (update: UpdateScheduleDTO) =>
  //   this.restService.request<any, string>({
  //     method: 'PUT',
  //     responseType: 'text',
  //     url: '/api/PlatformApp/message-and-mail-response/schedule-details',
  //     body: update,
  //   },
  //   { apiName: this.apiName });

  getPatientDOBByScheduleIdAndDateOfBirth = (scheduleId: string, dateOfBirth: string) =>
    this.restService.request<any, string>({
      method: 'GET',
      responseType: 'text',
      url: `/api/PlatformApp/message-and-mail-response/get-patient-dOB/${scheduleId}`,
      params: { dateOfBirth },
    },
    { apiName: this.apiName });

  getPatientNameByScheduleId = (scheduleId: string) =>
    this.restService.request<any, string>({
      method: 'GET',
      responseType: 'text',
      url: `/api/PlatformApp/message-and-mail-response/get-patient-name/${scheduleId}`,
    },
    { apiName: this.apiName });

  getScheduleResponseByScheduleId = (ScheduleId: string) =>
    this.restService.request<any, ScheduleHistoryDTO>({
      method: 'GET',
      url: `/api/PlatformApp/message-and-mail-response/get-schedule-response/${ScheduleId}`,
    },
    { apiName: this.apiName });

  constructor(private restService: RestService) {}
}
