import { ListService } from '@abp/ng.core';
import { CdkVirtualScrollViewport, ScrollDispatcher } from '@angular/cdk/scrolling';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSelect } from '@angular/material/select';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngxs/store';
import { MasterProviderService } from 'projects/admin/src/app/warehouse-proxy/inventory/master-provider.service';
import { TableService } from 'projects/shared/src/app/table.service';
import { NumberValidators } from 'projects/shared/src/app/validations/number-validator';
import { Observable, of, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, distinctUntilKeyChanged, map, startWith, switchMap, tap } from 'rxjs/operators';
import Swal from 'sweetalert2';

import { DiagnosisCode10Service } from '../dropdown-proxy/dropdown-management/dropdowns/diagnosis-code10.service';
import { DiagnosisCode9Service } from '../dropdown-proxy/dropdown-management/dropdowns/diagnosis-code9.service';
import {
  DiagnosisCode10DTO,
  DiagnosisCode9DTO,
  FacilityDTO,
  FunctionalAbilityDTO,
  GenderDTO,
  MarketingReferralContactDTO,
  MarketingReferralTypeDTO,
  MarketingRepTypeDTO,
  PractitionerDTO,
  ReferringProviderDTO,
  RenderingProviderDTO,
} from '../dropdown-proxy/dropdown-management/dropdowns/dto/patient/models';
import { PatientMasterDropdownService } from '../dropdown-proxy/dropdown-management/dropdowns/master-dropdown-service/patient-master-dropdown.service';
import { PatientDropdowns } from '../dropdown-proxy/dropdown-management/dropdowns/patient-dropdowns.enum';
import { PatientDoctorComponent } from '../patient-doctor/patient-doctor.component';
import { PatientIcd10Component } from '../patient-icd10/patient-icd10.component';
import { PatientIcd9Component } from '../patient-icd9/patient-icd9.component';
import { PatientService } from '../patient-proxy/patient';
import { DoctorService } from '../patient-proxy/patient/doctor.service';
import { ClinicalDTO, CreateUpdatePatientIntakeDTO, PatientDTO, PatientLockDTO } from '../patient-proxy/patient/dto/models';
import { getPatient, updatePatientIntake } from '../patient-store/patient.action';



@Component({
  selector: 'app-patient-clinical',
  templateUrl: './patient-clinical.component.html',
  styleUrls: ['./patient-clinical.component.scss'],
  providers: [ListService]
})
export class PatientClinicalComponent implements OnInit, OnDestroy {
  @Output() patientContact: EventEmitter<string> = new EventEmitter();
  @Output() onNavChange: EventEmitter<string> = new EventEmitter();
  @Output() saveClinicalFormState: EventEmitter<any> = new EventEmitter<any>();
  @Output() nextTabMoveOnSaveEmitter: EventEmitter<string> = new EventEmitter<string>();
  @Output() patientIdEmitter: EventEmitter<CreateUpdatePatientIntakeDTO> = new EventEmitter<CreateUpdatePatientIntakeDTO>();
  contactsForm: FormGroup;
  showswal = true;
  isShowSpinner: boolean = false;
  ClinicalForm: FormGroup;
  drpRenderingTypeLoop: RenderingProviderDTO[];
  drpRefringtypeLoop: ReferringProviderDTO[];
  drpMarketingtypeLoop: MarketingReferralTypeDTO[];
  drpGenderLoop: GenderDTO[];
  drpFunctonal: FunctionalAbilityDTO[];
  drpMarketing: MarketingRepTypeDTO[];
  drpPractioner: PractitionerDTO[];
  drpFacility: FacilityDTO[];
  drpContact: MarketingReferralContactDTO[];
  drpDoctor: doctorInterface[] = [];
  @Input() patientId: string = "";
  @Input() savedContactData: any[] = [];
  organizationUnitId = null;
  gender: string;
  facility: string;
  renderingType: string;
  referringType: string;
  renderingFacility: string;
  referringFacility: string;
  referingType: string;
  saveButtonHide: boolean;
  provider: string;
  constructor(
    private modalService: NgbModal,
    public dialog: MatDialog,
    private formBuilder: FormBuilder,
    private store: Store,
    private diagnosis9: DiagnosisCode9Service,
    private diagnosis10: DiagnosisCode10Service,
    private numberValidators: NumberValidators,
    private doctorService: DoctorService,
    private Table: TableService,
    public list: ListService,
    private patientDropdownService: PatientMasterDropdownService,
    private providerList: MasterProviderService,
    private patientService: PatientService
    //readonly sd: ScrollDispatcher
  ) { }
  //@ViewChild(CdkVirtualScrollViewport, { static: true })
  //cdkVirtualScrollViewPort: CdkVirtualScrollViewport;
  isPatientUpdatePermission: boolean = false;
  isPatientCreatePermission: boolean = false;
  isShownUpdateIconForPatient: boolean = true;
  // isIcd9Shown: boolean = false;
  // isIcd10Shown: boolean = false;
  icd9Event: string = "";
  icd10Event1: string = "";
  icd10Event2: string = "";
  icd10Event3: string = "";
  isPatientLockedByCurrentUser: boolean = true;
  strLoginUserId: string = '';

  subscription$: Subscription[] = [];
  isShownSaveButton: boolean = true;

  ngOnDestroy(): void {
    this.subscription$?.forEach((sub) => {
      sub && sub?.unsubscribe();
    });
  }
  //! Page Initialization funtions
  ngOnInit(): void {
    const getLoginUserIdSubscription = this.Table.getLoginUserId().subscribe(val => {
      this.strLoginUserId = val;
    })
    this.subscription$.push(getLoginUserIdSubscription);

    const patientCreateSubscription = this.Table.getPatientCreate().subscribe(value => {
      this.isPatientCreatePermission = value;
      if (this.isPatientCreatePermission == true) {
        this.isShownSaveButton = true;
      }
      else {
        this.isShownSaveButton = false;
      }
    }, err => {
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    });
    this.subscription$.push(patientCreateSubscription)
    //
    this.createClinicalForm();
    this.getDropdown();
    this.getPatientDetailsByID();
    this.icd9Dropdown();
    this.icd10Dropdown();

    // this.ClinicalForm?.get('txtDiagnosisCodes9')?.valueChanges
    // .subscribe(value => {
    //   if(value) {
    //     this.ClinicalForm?.get('txtDiagnosisCodes9').setValidators(Validators.required)
    //   } else {
    //     this.ClinicalForm?.get('txtdiagnosisCode10Id1').clearValidators();
    //   }
    // });
    // if(this.patient){
    // }
    // else {
    //   Swal.fire('Please Save Before tab ')
    // }

    if (this.ClinicalForm.invalid || this.ClinicalForm.dirty) {
      this.saveClinicalFormState.emit(this.ClinicalForm);
    }

    this.getProviderDropdown();
  }



  // clear dropdown
  clearGender() {
    this.gender = "";
    this.ClinicalForm.patchValue({ ClinicalGeneral: { drpGeneralGender: '' } });

  }

  clearFacility() {
    this.facility = '';
    this.ClinicalForm.patchValue({ ClinicalGeneral: { txtReferralFacility: '' } });

  }

  clearRenderingType() {
    this.renderingType = '';
    this.ClinicalForm.patchValue({ ClinicalGeneral: { txtRenderingType: '' } });

  }

  clearreferingType() {
    this.referingType = '';
    this.ClinicalForm.patchValue({ ClinicalGeneral: { txtReferralType: '' } });

  }

  clearReferringType() {
    this.referringType = '';
    this.ClinicalForm.patchValue({ ClinicalGeneral: { txtReferringType: '' } });

  }

  clearRenderingFacility() {
    this.renderingFacility = '';
    this.ClinicalForm.patchValue({ ClinicalGeneral: { txtRenderingFacility: '' } });

  }

  clearReferringFacility() {
    this.referringFacility = '';
    this.ClinicalForm.patchValue({ ClinicalGeneral: { txtReferringFacility: '' } });

  }
  numberOnly(event: { which: any; keyCode: any; }): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  //! Form Creation and Few Validations
  createClinicalForm() {
    this.ClinicalForm = this.formBuilder.group({
      ClinicalGeneral: new FormGroup({
        drpGeneralGender: new FormControl(null, [Validators.required]),
        // txtGeneralHeight: new FormControl(""),
        txtGeneralHeightInches: new FormControl("", [Validators.min(0), Validators.max(11), this.numberValidators.isNumberCheck],),
        txtGeneralHeightFeet: new FormControl("", [this.numberValidators.isNumberCheck]),
        txtGeneralWeight: new FormControl("", [this.numberValidators.isNumberCheck]),
        txtGeneralCondition: new FormControl(""),
        txtAirborne: new FormControl(""),
        txtContact: new FormControl(""),
        txtDroplet: new FormControl(""),
        txtGeneralFunctionalAbility: new FormControl(null),
        txtGeneralPractioner: new FormControl(null),
        txtGeneralDod: new FormControl(""),
        txtRenderingType: new FormControl(null),
        txtRenderingDoctor: new FormControl(null),
        txtRenderingFacility: new FormControl(null),
        txtReferringType: new FormControl(null),
        txtReferringDoctor: new FormControl(null),
        txtReferringFacility: new FormControl(""),
        txtReferringContact: new FormControl("", [this.numberValidators.isNumberCheck]),
        txtReferringPhone: new FormControl(""),
        txtOrderingDoctor: new FormControl(null),
        txtPrimaryDoctor: new FormControl(null),
        txtPrimaryDoctorSearchFilter: new FormControl(null),
        txtMarketingRep: new FormControl(null),
        txtReferralType: new FormControl(null),
        txtReferralDoctor: new FormControl(null),
        txtReferralFacility: new FormControl(""),
        txtOrdSearchFilter: new FormControl(""),
        txtMarRepSearchFilter: new FormControl(""),
        txtRenDoctorFilter: new FormControl(null),
        txtRefDoctorFilter: new FormControl(""),
        txtMarDoctorFilter: new FormControl(""),
        txtPractitionerSearchFilter: new FormControl(""),

      }),
      ClinicalDiagnosisGroup: new FormGroup({
        txtDiagnosisCodes9: new FormControl(null),
        txtdiagnosisCode10Id1: new FormControl(null, [Validators.required]),
        txtdiagnosisCode10Id2: new FormControl(null),
        txtdiagnosisCode10Id3: new FormControl(null),
        txtIcd10Filter1: new FormControl(null),
        txtIcd10Filter2: new FormControl(null),
        txtIcd10Filter3: new FormControl(null),
        txtIcd9Filter: new FormControl(null),
      }),
      ClinicalAppointmentGroup: new FormGroup({
        txtAppointmentdate: new FormControl(""),
        txtAppointmentBranch: new FormControl(""),
        txtAppointmentDescription: new FormControl(""),
        txtAppointmentPractioner: new FormControl(""),
        txtAppointmentStatus: new FormControl(""),

        // newly Added
        txtPrescriptionDate: new FormControl(""),
        chkDoctorSign: new FormControl("")
      })
    });
  }
  // changeCodes(value) {
  //
  //     if(value) {

  // this.ClinicalForm.markAsUntouched();
  // this.ClinicalForm.controls['txtdiagnosisCode10Id1'].updateValueAndValidity();
  //     } else {
  //       this.isShown = false
  //     }
  // }
  //! Get Patient Clinical For Save Functions
  getPatientDetailsForSave() {
    let clinicalDetails: PatientDTO;
    this.isShowSpinner = true;
    if (this.patientId !== "" || this.patientId !== undefined || this.patientId !== null) {
      const storeSubscription = this.patientService.get(this.patientId).subscribe(response => {
        clinicalDetails = response
        this.SaveClinical(clinicalDetails);
        this.isShowSpinner = false;
      }, err => {
        const data: HttpErrorResponse = err;
        this.isShowSpinner = false;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      });
      this.subscription$.push(storeSubscription);
    }

  }

  onIcd9Change(event: string) {
    this.icd9Event = event
    if ((event == undefined || event == null || event == "") &&
      (this.icd10Event1 == undefined || this.icd10Event1 == null || this.icd10Event1 == "") &&
      (this.icd10Event2 == undefined || this.icd10Event2 == null || this.icd10Event2 == "") &&
      (this.icd10Event3 == undefined || this.icd10Event3 == null || this.icd10Event3 == "")) {
      //   this.isIcd9Shown = false;
      //   this.isIcd10Shown = false;
    }
    else {
      // this.isIcd9Shown = true;
      // this.isIcd10Shown = true;
    }
  }
  onIcd10Change1(event: string) {
    this.icd10Event1 = event;
    if ((event == undefined || event == null || event == "") &&
      (this.icd9Event == undefined || this.icd9Event == null || this.icd9Event == "") &&
      (this.icd10Event2 == undefined || this.icd10Event2 == null || this.icd10Event2 == "") &&
      (this.icd10Event3 == undefined || this.icd10Event3 == null || this.icd10Event3 == "")) {
      // this.isIcd10Shown = false;
      // this.isIcd9Shown = false;
    }
    else {
      // this.isIcd10Shown = true;
      // this.isIcd9Shown = true;
    }
  }

  onIcd10Change2(event: string) {
    this.icd10Event2 = event;
    if ((event == undefined || event == null || event == "") &&
      (this.icd9Event == undefined || this.icd9Event == null || this.icd9Event == "") &&
      (this.icd10Event1 == undefined || this.icd10Event1 == null || this.icd10Event1 == "") &&
      (this.icd10Event3 == undefined || this.icd10Event3 == null || this.icd10Event3 == "")) {
      // this.isIcd10Shown = false;
      // this.isIcd9Shown = false;
    }
    else {
      // this.isIcd10Shown = true;
      // this.isIcd9Shown = true;
    }
  }
  onIcd10Change3(event: string) {
    this.icd10Event3 = event;
    if ((event == undefined || event == null || event == "") && (this.icd9Event == undefined || this.icd9Event == "")
      && (this.icd10Event1 == undefined || this.icd10Event1 == "") && (this.icd10Event2 == undefined || this.icd10Event2 == "")) {
      // this.isIcd10Shown = false;
      // this.isIcd9Shown = false;

      // this.ClinicalForm?.get["ClinicalDiagnosisGroup.txtdiagnosisCode10Id1"].setValidators([Validators.required]);
      // this.ClinicalForm?.get["ClinicalDiagnosisGroup.txtdiagnosisCode10Id1"].updateValueAndValidity();
    }
    else {
      // this.isIcd10Shown = true;
      // this.isIcd9Shown = true;
    }
  }

  str: string[];
  height: string = '';
  //! Patient Clinical Save Functions
  SaveClinical(PateintDetails) {
    this.saveButtonHide = true;
    this.isShowSpinner = true;
    if ((this.ClinicalForm.value.ClinicalGeneral.txtGeneralHeightFeet == "" ||
      this.ClinicalForm.value.ClinicalGeneral.txtGeneralHeightFeet == null ||
      this.ClinicalForm.value.ClinicalGeneral.txtGeneralHeightFeet == undefined) &&
      (this.ClinicalForm.value.ClinicalGeneral.txtGeneralHeightInches == "" ||
        this.ClinicalForm.value.ClinicalGeneral.txtGeneralHeightInches == undefined ||
        this.ClinicalForm.value.ClinicalGeneral.txtGeneralHeightInches == null)) {
      this.height = "0"
    }
    else {
      this.height = this.ClinicalForm.value.ClinicalGeneral.txtGeneralHeightFeet + '.' +
        this.ClinicalForm.value.ClinicalGeneral.txtGeneralHeightInches;

      parseFloat(this.height);
    }
    // this.Table.getOrganizationUnitId().subscribe(value => {
    //   this.organizationUnitId = value;
    // });
    // this.organizationUnitId = localStorage.getItem('initialOrganizationUnitId');
    let clinicalDetails: CreateUpdatePatientIntakeDTO;

    clinicalDetails = {
      addresses: PateintDetails.addresses,
      personals: PateintDetails?.personals,
      contacts: PateintDetails.contacts,
      clinicals: {
        // id: this.patientId,
        genderType: this.ClinicalForm?.get("ClinicalGeneral.drpGeneralGender")?.value,
        height: this.height == "0" ? 0 : parseFloat(this.height),
        weight: +(this.ClinicalForm.value.ClinicalGeneral.txtGeneralWeight) | 0,
        dod: this.ClinicalForm.value.ClinicalGeneral.txtGeneralDod,
        functionalAbility: this.ClinicalForm.value.ClinicalGeneral.txtGeneralFunctionalAbility,
        generalPractitioner: this.ClinicalForm.value.ClinicalGeneral.txtGeneralPractioner,
        infectiousConditon: +this.ClinicalForm?.get("ClinicalGeneral.txtGeneralCondition")?.value,
        airborne: +this.ClinicalForm?.get("ClinicalGeneral.txtAirborne")?.value,
        contact: +this.ClinicalForm?.get("ClinicalGeneral.txtContact")?.value,
        droplet: +this.ClinicalForm?.get("ClinicalGeneral.txtDroplet")?.value,
        renderingProviderType: this.ClinicalForm?.get("ClinicalGeneral.txtRenderingType")?.value,
        renderingProviderDoctor: this.ClinicalForm.value.ClinicalGeneral.txtRenderingDoctor,
        renderingProviderFacility: this.ClinicalForm.value.ClinicalGeneral.txtRenderingFacility,
        referringProviderType: this.ClinicalForm?.get("ClinicalGeneral.txtReferringType")?.value,
        referringProviderDoctor: this.ClinicalForm.value.ClinicalGeneral.txtReferringDoctor,
        referringProviderFacility: this.ClinicalForm.value.ClinicalGeneral.txtReferringFacility,
        orderingDoctor: this.ClinicalForm.value.ClinicalGeneral.txtOrderingDoctor,
        primaryDoctor: this.ClinicalForm.value.ClinicalGeneral.txtPrimaryDoctor,
        marketingRepType: this.ClinicalForm.value.ClinicalGeneral.txtMarketingRep,
        marketingReferralType: this.ClinicalForm?.get("ClinicalGeneral.txtReferralType")?.value,
        marketingReferralDoctor: this.ClinicalForm.value.ClinicalGeneral.txtReferralDoctor,
        marketingReferralFacility: this.ClinicalForm.value.ClinicalGeneral.txtReferralFacility,
        marketingReferralContact: this.ClinicalForm?.get("ClinicalGeneral.txtReferringContact")?.value,
        diagnosisCode9: this.ClinicalForm.value.ClinicalDiagnosisGroup.txtDiagnosisCodes9 == "" ? [] : this.ClinicalForm.value.ClinicalDiagnosisGroup.txtDiagnosisCodes9,
        diagnosisCode10: this.ClinicalForm.value.ClinicalDiagnosisGroup.txtdiagnosisCode10Id1,
        // diagnosisCode10Id2: this.ClinicalForm.value.ClinicalDiagnosisGroup.txtdiagnosisCode10Id2,
        // diagnosisCode10Id3: this.ClinicalForm.value.ClinicalDiagnosisGroup.txtdiagnosisCode10Id3,
        appointmentDate: this.ClinicalForm.value.ClinicalAppointmentGroup.txtAppointmentdate,
        branch: this.ClinicalForm.value.ClinicalAppointmentGroup.txtAppointmentBranch,
        description: this.ClinicalForm.value.ClinicalAppointmentGroup.txtAppointmentDescription,
        practitioner: this.ClinicalForm.value.ClinicalAppointmentGroup.txtAppointmentPractioner,
        status: this.ClinicalForm.value.ClinicalAppointmentGroup.txtAppointmentStatus,
        prescriptionDate: this.ClinicalForm.value.ClinicalAppointmentGroup.txtPrescriptionDate,
        isDoctorSigned: +(this.ClinicalForm.value.ClinicalAppointmentGroup.chkDoctorSign)
      },
      insurances: PateintDetails.insurances,
      notes: PateintDetails.notes,
      policies: PateintDetails.policies,
      defaultPatientId: PateintDetails.defaultPatientId,
      // insuranceVerifications: PateintDetails.insuranceVerifications,
      // authorizations: PateintDetails.authorizations,
      // patientDocuments: PateintDetails.patientDocuments,
      organizationUnitId: PateintDetails.organizationUnitId,
      isAutoIntake: PateintDetails.isAutoIntake,
      autoIntakeDocumentDetails: PateintDetails.autoIntakeDocumentDetails,
      chartId: PateintDetails.chartId,
      patientLock: PateintDetails?.patientLock,
      // fileId: PateintDetails?.fileId,
    };
    if (this.patientId !== "" || this.patientId !== undefined || this.patientId !== null) {
      this.store.dispatch(new updatePatientIntake(this.patientId, clinicalDetails)).subscribe((response) => {
        //
        let patientSaved: CreateUpdatePatientIntakeDTO = response?.patientState?.selectedPatient;
        this.patientIdEmitter.emit(patientSaved);
        Swal.fire({ title: 'Success', html: 'Saved Successfully', icon: 'success', timer: 3000, timerProgressBar: true }).then(result => {
          if (result.value) {
            //!change next tab
            this.nextTabMoveOnSaveEmitter.emit("Clinical");
          }

        }, err => { });
        this.saveButtonHide = false;
        this.isShowSpinner = false;
        this.Table.setheaderTable(this.patientId);
        this.saveClinicalFormState.emit(this.ClinicalForm.value);

      }, err => {
        this.saveButtonHide = false;
        this.isShowSpinner = false;
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      }, () => {
      });
    }
  }

  //! Doctor Modal Open Functions
  openDoctorModal(doctorId?: string) {
    const dialogRef = this.dialog.open(PatientDoctorComponent, {
      disableClose: true,
      data: { doctorId: doctorId }
    });
    const closeDialog = dialogRef.afterClosed().subscribe(() => {
      this.doctorDropdown();
    }, err => {
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    });
    this.subscription$.push(closeDialog)
  }
  openIcd9Modal() {
    const dialogRef = this.dialog.open(PatientIcd9Component, {
      disableClose: true,
      data: {}
    });
    const closeDialog = dialogRef.afterClosed().subscribe(() => {
      this.getDropdown();
      this.icd9Dropdown();
    }, err => {
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    });
    this.subscription$.push(closeDialog);
  }
  openIcd10Modal() {
    const dialogRef = this.dialog.open(PatientIcd10Component, {
      disableClose: true,
      data: {}
    });
    const closeDialog = dialogRef.afterClosed().subscribe(() => {
      this.getDropdown();
      this.icd10Dropdown();
    }, err => {
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    });
    this.subscription$.push(closeDialog);
  }
  drpDiagnosisICD9: { diagnosisCode9ShortCodeId: string, diagnosisCode: string, diagnosisCodeTypeData: string, diagnosisCodeTypeDataId: string, id: string, creationTime: string | Date, creatorId: string; isActive: number; isExist: number; }[] = [];
  drpDiagnosisICD10: { diagnosisCode10ShortCodeId: string, diagnosisCode: string, diagnosisCodeTypeData: string, diagnosisCodeTypeDataId: string, id: string, creationTime: string | Date, creatorId: string; isActive: number; isExist: number; }[] = [];

  public filteredIcd9Types: Observable<any[]> | undefined;
  public filtered1Icd10Types: Observable<any[]> | undefined;
  public filtered2Icd10Types: Observable<any[]> | undefined;
  public filtered3Icd10Types: Observable<any[]> | undefined;
  //! Master Dropdown Funtion
  getDropdown() {
    // this.drpDoctor = [];
    // this.drpDiagnosisICD9 = [];
    // this.drpDiagnosisICD10 = [];
    let patientDropdownInput: PatientDropdowns[];
    patientDropdownInput = [
      PatientDropdowns.RenderingProviders,
      PatientDropdowns.ReferringProviders,
      PatientDropdowns.MarketingReferralTypes,
      PatientDropdowns.Genders,
      PatientDropdowns.FuntionalAbilities,
      PatientDropdowns.MarketingRepTypes,
      PatientDropdowns.Practitioners,
      PatientDropdowns.Facilities,
      PatientDropdowns.MarketingReferralContacts
    ]
    const patientDropdownSubscription = this.patientDropdownService.getPatientDropdownsByInput(patientDropdownInput).subscribe((response) => {
      this.drpRenderingTypeLoop = response.renderingProviders;
      this.drpRefringtypeLoop = response.referringProviders;
      this.drpMarketingtypeLoop = response.marketingReferralTypes;
      this.drpGenderLoop = response.genders;
      this.drpFunctonal = response.functionalAbilities;
      this.drpMarketing = response.marketingRepTypes;
      this.drpPractioner = response.practitioners;
      this.drpFacility = response.facilities;
      this.drpContact = response.marketingReferralContacts;
      // response.dropdownState.selectedDropdown.diagnosisCodes9.forEach((element: DiagnosisCode9DTO) => {
      //   let resultString = element?.diagnosisCode + " " + element?.diagnosisCodeTypeData;
      //   this.drpDiagnosisICD9.push({
      //     diagnosisCode: element?.diagnosisCode,
      //     diagnosisCode9ShortCodeId: element?.diagnosisCode9ShortCodeId,

      //     diagnosisCodeTypeData: resultString,
      //     diagnosisCodeTypeDataId: element?.diagnosisCodeTypeDataId,
      //     id: element?.id,
      //     creationTime: element?.creationTime,
      //     creatorId: element?.creatorId,
      //     isActive: element?.isActive,
      //     isExist: element?.isExist
      //   });
      //   this.filteredIcd9Types = this.ClinicalForm?.get("ClinicalDiagnosisGroup.txtIcd9Filter")?.valueChanges
      //   .pipe(
      //     startWith(''),
      //     map(value => this.drpDiagnosisICD9.filter(option => option?.diagnosisCodeTypeData?.toLowerCase()?.includes(value?.toLowerCase()??"")))
      //   );
      // })
      // response.dropdownState.selectedDropdown.diagnosisCodes10.forEach((element: DiagnosisCode10DTO) => {
      //   let resultString = element?.diagnosisCode + " " + element?.diagnosisCodeTypeData;
      //   this.drpDiagnosisICD10.push({
      //     diagnosisCode: element?.diagnosisCode,
      //     diagnosisCode10ShortCodeId: element?.diagnosisCode10ShortCodeId,
      //     diagnosisCodeTypeData: resultString,
      //     diagnosisCodeTypeDataId: element?.diagnosisCodeTypeDataId,
      //     id: element?.id,
      //     creationTime: element?.creationTime,
      //     creatorId: element?.creatorId,
      //     isActive: element?.isActive,
      //     isExist: element?.isExist
      //   });
      //   this.filtered1Icd10Types = this.ClinicalForm?.get("ClinicalDiagnosisGroup.txtIcd10Filter1")?.valueChanges
      //   .pipe(
      //     startWith(''),
      //     map(value => this.drpDiagnosisICD10.filter(option => option?.diagnosisCodeTypeData?.toLowerCase()?.includes(value?.toLowerCase()??"")))
      //   );
      //   this.filtered2Icd10Types = this.ClinicalForm?.get("ClinicalDiagnosisGroup.txtIcd10Filter2")?.valueChanges
      //   .pipe(
      //     startWith(''),
      //     map(value => this.drpDiagnosisICD10.filter(option => option?.diagnosisCodeTypeData?.toLowerCase()?.includes(value?.toLowerCase()??"")))
      //   );
      //   this.filtered3Icd10Types = this.ClinicalForm?.get("ClinicalDiagnosisGroup.txtIcd10Filter3")?.valueChanges
      //   .pipe(
      //     startWith(''),
      //     map(value => this.drpDiagnosisICD10.filter(option => option?.diagnosisCodeTypeData?.toLowerCase()?.includes(value?.toLowerCase()??"")))
      //   );
      // })

      // return response;
      // })).subscribe((stateResponse) => {
      // let response: PatientMasterDropdownDTO = response


    }, err => {
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    })
    this.subscription$.push(patientDropdownSubscription);
    this.doctorDropdown();
    //  this.icd9Dropdown();


  }

  icd9Dropdown() {
    // this.ClinicalForm?.get("ClinicalDiagnosisGroup.txtIcd9Filter")?.valueChanges
    //   .pipe(
    //     distinctUntilChanged(),
    //     debounceTime(1000),
    //     switchMap(value => this.diagnosis10.searchdiagnosiscode9(value))
    //   ).subscribe(response => {
    //     // this.drpDiagnosisICD9 = [];
    //     // this.filteredIcd9Types = of([]);
    //     response?.items?.forEach((element: DiagnosisCode9DTO) => {
    //       let resultString = element?.diagnosisCode + " " + element?.diagnosisCodeTypeData;
    //       this.drpDiagnosisICD9?.push({
    //         diagnosisCode: element?.diagnosisCode ?? "",
    //         diagnosisCode9ShortCodeId: element?.diagnosisCode9ShortCodeId ?? "",
    //         diagnosisCodeTypeData: resultString,
    //         diagnosisCodeTypeDataId: element?.diagnosisCodeTypeDataId ?? "",
    //         id: element?.id ?? "",
    //         creationTime: element?.creationTime ?? "",
    //         creatorId: element?.creatorId ?? "",
    //         isActive: element?.isActive,
    //         isExist: element?.isExist
    //       });
    //     });
    //     this.filteredIcd9Types = of(this.drpDiagnosisICD9);
    //   });
    const itemList = (query: any) => this.diagnosis9.getList(query);
    const itemListSubscription = this.list.hookToQuery(itemList).subscribe(response => {
      response?.items?.forEach((element: DiagnosisCode9DTO) => {
        let resultString = element?.diagnosisCode + " " + element?.diagnosisCodeTypeData;
        this.drpDiagnosisICD9.push({
          diagnosisCode: element?.diagnosisCode ?? "",
          diagnosisCode9ShortCodeId: element?.diagnosisCode9ShortCodeId ?? "",
          diagnosisCodeTypeData: resultString ?? "",
          diagnosisCodeTypeDataId: element?.diagnosisCodeTypeDataId ?? "",
          id: element?.id ?? "",
          creationTime: element?.creationTime ?? "",
          creatorId: element?.creatorId ?? "",
          isActive: element?.isActive,
          isExist: element?.isExist
        });
      })
      this.filteredIcd9Types = this.ClinicalForm?.get("ClinicalDiagnosisGroup.txtIcd9Filter")?.valueChanges
        .pipe(
          startWith(''),
          map(value => this.drpDiagnosisICD9?.filter(option => option?.diagnosisCodeTypeData?.toLowerCase()?.includes(value?.toLowerCase() ?? ""))));
    });
    this.subscription$.push(itemListSubscription)
  }

  icd10Dropdown() {
    this.drpDiagnosisICD10 = [];
    const itemList = (query: any) => this.diagnosis10.getList(query);
    const itemListSubscription = this.list.hookToQuery(itemList).subscribe(response => {
      response?.items?.forEach((element: DiagnosisCode10DTO) => {
        let resultString = element?.diagnosisCode + " " + element?.diagnosisCodeTypeData;
        this.drpDiagnosisICD10.push({
          diagnosisCode: element?.diagnosisCode,
          diagnosisCodeTypeData: resultString,
          diagnosisCodeTypeDataId: element?.diagnosisCodeTypeDataId,
          diagnosisCode10ShortCodeId: element?.diagnosisCode10ShortCodeId,
          id: element?.id,
          creationTime: element?.creationTime,
          creatorId: element?.creatorId,
          isActive: element?.isActive,
          isExist: element?.isExist
        })
        this.filtered1Icd10Types = this.ClinicalForm?.get("ClinicalDiagnosisGroup.txtIcd10Filter1")?.valueChanges
          .pipe(
            startWith(''),
            map(value => this.drpDiagnosisICD10.filter(option => option?.diagnosisCodeTypeData?.toLowerCase()?.includes(value?.toLowerCase() ?? "")))
          );
        this.filtered2Icd10Types = this.ClinicalForm?.get("ClinicalDiagnosisGroup.txtIcd10Filter2")?.valueChanges
          .pipe(
            startWith(''),
            map(value => this.drpDiagnosisICD10.filter(option => option?.diagnosisCodeTypeData?.toLowerCase()?.includes(value?.toLowerCase() ?? "")))
          );
        this.filtered3Icd10Types = this.ClinicalForm?.get("ClinicalDiagnosisGroup.txtIcd10Filter3")?.valueChanges
          .pipe(
            startWith(''),
            map(value => this.drpDiagnosisICD10.filter(option => option?.diagnosisCodeTypeData?.toLowerCase()?.includes(value?.toLowerCase() ?? "")))
          );
      })
    }, err => {
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    });
    this.subscription$.push(itemListSubscription)
  }

  drpProviders: any[] = [];
  //! provider getlist
  getProviderDropdown() {
    const providerList = (query: any) => this.providerList.getList(query);
    const providerListSubscription = this.list.hookToQuery(providerList).subscribe(response => {
      response?.items?.forEach(element => {
        this.drpProviders.push({
          id: element.id,
          name: element?.fullName,
          shortCodeId: element?.shortCodeId,
          fullName: element?.fullName,
          npi: element?.npi
        });
      });
    })
    this.subscription$.push(providerListSubscription);
  }


  public filteredOrderingDoctorTypes: Observable<doctorInterface[]> | undefined;
  public filteredMarRepDoctorTypes: Observable<doctorInterface[]> | undefined;
  public filteredRenDoctorTypes: Observable<doctorInterface[]> | undefined;
  public filteredMarDoctorTypes: Observable<doctorInterface[]> | undefined;
  public filteredRefDoctorTypes: Observable<doctorInterface[]> | undefined;
  public filteredPrimaryDoctorTypes: Observable<doctorInterface[]> | undefined;
  public filteredPractionerTypes: Observable<doctorInterface[]> | undefined;

  @ViewChild('singleSelect') singleSelect: MatSelect;

  //! Doctors Dropdown Funtion
  doctorDropdown(): void {
    // this.organizationUnitId = localStorage.getItem('initialOrganizationUnitId');
    this.drpDoctor = [];
    const itemList = (query: any) => this.doctorService.getList(query);
    const doctorDropdownSubscription = this.list.hookToQuery(itemList).subscribe(response => {
      response?.items?.forEach(element => {
        this.drpDoctor.push({
          name: element['npiNumber'] + ' / ' + element?.firstName + " " + element?.middleName + " " + element?.lastName,
          id: element?.id ?? ""
        });
      });
      this.filteredOrderingDoctorTypes = this.ClinicalForm?.get("ClinicalGeneral.txtOrdSearchFilter")?.valueChanges
        .pipe(
          startWith(''),
          map(value => this.drpDoctor?.filter(option => option?.name?.toLowerCase()?.includes(value?.toLowerCase() ?? "")))
        );
      this.filteredPractionerTypes = this.ClinicalForm?.get("ClinicalGeneral.txtPractitionerSearchFilter")?.valueChanges
        .pipe(
          startWith(''),
          map(value => this.drpDoctor?.filter(option => option?.name?.toLowerCase()?.includes(value?.toLowerCase() ?? "")))
        );
      this.filteredMarRepDoctorTypes = this.ClinicalForm?.get("ClinicalGeneral.txtMarRepSearchFilter")?.valueChanges
        .pipe(
          startWith(''),
          map(value => this.drpDoctor?.filter(option => option?.name?.toLowerCase()?.includes(value?.toLowerCase() ?? "")))
        );
      this.filteredRenDoctorTypes = this.ClinicalForm?.get("ClinicalGeneral.txtRenDoctorFilter")?.valueChanges
        .pipe(
          startWith(''),
          map(value => this.drpDoctor?.filter(option => option?.name?.toLowerCase()?.includes(value?.toLowerCase() ?? "")))
        );
      this.filteredRefDoctorTypes = this.ClinicalForm?.get("ClinicalGeneral.txtRefDoctorFilter")?.valueChanges
        .pipe(
          startWith(''),
          map(value => this.drpDoctor?.filter(option => option?.name?.toLowerCase()?.includes(value?.toLowerCase() ?? "")))
        );
      this.filteredMarDoctorTypes = this.ClinicalForm?.get("ClinicalGeneral.txtMarDoctorFilter")?.valueChanges
        .pipe(
          startWith(''),
          map(value => this.drpDoctor?.filter(option => option?.name?.toLowerCase()?.includes(value?.toLowerCase() ?? "")))
        );
      this.filteredPrimaryDoctorTypes = this.ClinicalForm?.get("ClinicalGeneral.txtPrimaryDoctorSearchFilter")?.valueChanges
        .pipe(
          startWith(''),
          map(value => this.drpDoctor?.filter(option => option?.name?.toLowerCase()?.includes(value?.toLowerCase() ?? "")))
        );
    }, err => {
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    }, () => {
    });
    this.subscription$.push(doctorDropdownSubscription)
    // this.doctorService.getList(new PagedAndSortedResultRequestDto).subscribe(response => {
    //   response?.items?.forEach(element => {
    //     this.drpDoctor?.push({
    //       name: element['npiNumber'] + ' / ' + element?.firstName + " " + element?.middleName + " " + element?.lastName,
    //       id: element?.id
    //     });
    //   });
    // }, () => {
    // });
  }

  OrderingDoctor: string = '';
  MarRepDoctor: string = '';
  Icd9: string[] = [];
  txt1Icd10: string[] = [];
  txt2Icd10: string = '';
  txt3Icd10: string = '';
  RenderingDoctor: string = '';
  ReferringDoctor: string = '';
  MarketingDoctor: string = '';
  PrimaryDoctor: string = '';
  Practitioner: string = '';

  clearOrderingDoctor() {
    this.OrderingDoctor = '';
    this.ClinicalForm.patchValue({ ClinicalGeneral: { txtOrderingDoctor: '' } });
  }
  clearPractitionerDoctor() {
    this.Practitioner = '';
    this.ClinicalForm.patchValue({ ClinicalGeneral: { txtGeneralPractioner: '' } });

  }
  clearPrimaryDoctor() {
    this.PrimaryDoctor = '';
    this.ClinicalForm.patchValue({ ClinicalGeneral: { txtPrimaryDoctor: '' } });

  }
  clearMarRepDoctor() {
    this.MarRepDoctor = '';
    this.ClinicalForm.patchValue({ ClinicalGeneral: { txtMarketingRep: '' } });

  }
  clearIcd9() {
    this.Icd9 = [];
    this.ClinicalForm.patchValue({ ClinicalDiagnosisGroup: { txtDiagnosisCodes9: '' } });

  }
  cleartxt1Icd10() {
    this.txt1Icd10 = [];
    this.ClinicalForm.patchValue({ ClinicalDiagnosisGroup: { txtdiagnosisCode10Id1: '' } });
  }
  cleartxt2Icd10() {
    this.txt2Icd10 = '';
    this.ClinicalForm.patchValue({ ClinicalDiagnosisGroup: { txtdiagnosisCode10Id2: '' } });

  }
  cleartxt3Icd10() {
    this.txt3Icd10 = '';
    this.ClinicalForm.patchValue({ ClinicalDiagnosisGroup: { txtdiagnosisCode10Id3: '' } });

  }
  clearRenderingDoctor() {
    this.RenderingDoctor = '';
    this.ClinicalForm.patchValue({ ClinicalGeneral: { txtRenderingDoctor: '' } });

  }
  clearReferringDoctor() {
    this.ReferringDoctor = '';
    this.ClinicalForm.patchValue({ ClinicalGeneral: { txtReferringDoctor: '' } });

  }
  clearMarketingDoctor() {
    this.MarketingDoctor = '';
    this.ClinicalForm.patchValue({ ClinicalGeneral: { txtReferralDoctor: '' } });

  }
  // clear Provider
  clearProvider() {
    this.provider = '';
    this.ClinicalForm.patchValue({ txtProvider: '' });
  }

  //! Perosnal Details Get By ID Functions
  getPatientDetailsByID() {
    const patientUpdateSubscription = this.Table.getPatientUpdate().subscribe(value => {
      this.isPatientUpdatePermission = value;
      if (this.isPatientUpdatePermission == true) {
        this.isShownUpdateIconForPatient = true;
      }
      else {
        this.isShownUpdateIconForPatient = false;
      }
    }, err => {
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    });
    this.subscription$.push(patientUpdateSubscription)


    if (this.patientId !== "" && this.patientId !== null && this.patientId !== undefined) {
      const storeSubscription = this.patientService.get(this.patientId).subscribe(response => {
        const selectedPatient: ClinicalDTO = response?.clinicals;
        const patientLock: PatientLockDTO = response?.patientLock;
        //Patient is locked & userId is not equal to loginUserId or patient is unlocked
        this.isPatientLockedByCurrentUser = (patientLock?.isLocked == true &&
          patientLock?.userId == this.strLoginUserId) ||
          (patientLock?.isLocked == false) ? true : false;
        if (selectedPatient !== null && selectedPatient !== undefined && selectedPatient.height != 0) {
          this.str = String(selectedPatient.height).split('.', 2);
        }

        selectedPatient !== null && selectedPatient !== undefined && this.ClinicalForm.patchValue({

          ClinicalGeneral: {

            txtGeneralHeightFeet: selectedPatient?.height === 0 ? "" : this.str[0],
            txtGeneralHeightInches: selectedPatient?.height === 0 ? "" : this.str[1],
            //txtGeneralHeight: selectedPatient?.height === 0 ? "" : selectedPatient?.height,
            txtGeneralWeight: selectedPatient?.weight === 0 ? "" : selectedPatient?.weight,

            txtGeneralCondition: selectedPatient?.infectiousConditon,
            txtAirborne: selectedPatient?.airborne,
            txtContact: selectedPatient?.contact,
            txtDroplet: selectedPatient?.droplet,
            drpGeneralGender: selectedPatient?.genderType == "" ? null : selectedPatient?.genderType,
            txtGeneralDod: selectedPatient['dod'] == null ? "" : selectedPatient['dod'],
            txtGeneralFunctionalAbility: selectedPatient?.functionalAbility,
            txtGeneralPractioner: selectedPatient?.generalPractitioner == "" ? null : selectedPatient?.generalPractitioner,
            txtReferringContact: selectedPatient?.marketingReferralContact,
            txtReferralType: selectedPatient?.marketingReferralType == "" ? null : selectedPatient?.marketingReferralType,
            txtReferringType: selectedPatient?.referringProviderType == "" ? null : selectedPatient?.referringProviderType,
            txtMarketingRep: selectedPatient?.marketingRepType == "" ? null : selectedPatient?.marketingRepType,
            txtPrimaryDoctor: selectedPatient?.primaryDoctor === "" ? null : selectedPatient.primaryDoctor,
            txtOrderingDoctor: selectedPatient?.orderingDoctor === "" ? null : selectedPatient.orderingDoctor,
            txtRenderingType: selectedPatient?.renderingProviderType == "" ? null : selectedPatient?.renderingProviderType,
            txtRenderingDoctor: selectedPatient?.renderingProviderDoctor == "" ? null : selectedPatient?.renderingProviderDoctor,
            txtRenderingFacility: selectedPatient?.renderingProviderFacility,
            txtReferringFacility: selectedPatient?.referringProviderFacility,
            txtReferralFacility: selectedPatient?.marketingReferralFacility,
            txtReferringDoctor: selectedPatient?.referringProviderDoctor === "" ? null : selectedPatient.referringProviderDoctor,
            txtReferralDoctor: selectedPatient?.marketingReferralDoctor === "" ? null : selectedPatient.marketingReferralDoctor,
          },
          ClinicalDiagnosisGroup: {
            txtDiagnosisCodes9: selectedPatient?.diagnosisCode9 === [] ? null : selectedPatient.diagnosisCode9,
            txtdiagnosisCode10Id1: selectedPatient?.diagnosisCode10 === [] ? null : selectedPatient.diagnosisCode10,
            // txtdiagnosisCode10Id2: selectedPatient?.diagnosisCode10Id2 === [] ? null : selectedPatient.diagnosisCode10Id2,
            // txtdiagnosisCode10Id3: selectedPatient?.diagnosisCode10Id3 === [] ? null : selectedPatient.diagnosisCode10Id3,
          },
          ClinicalAppointmentGroup: {
            txtAppointmentdate: selectedPatient?.appointmentDate == null ? "" : selectedPatient?.appointmentDate,
            txtAppointmentBranch: selectedPatient?.branch,
            txtAppointmentDescription: selectedPatient?.description,
            txtAppointmentPractioner: selectedPatient?.practitioner,
            txtAppointmentStatus: selectedPatient?.status,
            txtPrescriptionDate: selectedPatient?.prescriptionDate,
            chkDoctorSign: selectedPatient?.isDoctorSigned
          }

        })
        this.facility = selectedPatient?.marketingReferralFacility ?? "";
        this.OrderingDoctor = selectedPatient?.orderingDoctor ?? "";
        this.MarketingDoctor = selectedPatient?.marketingReferralDoctor ?? "";
        this.PrimaryDoctor = selectedPatient?.primaryDoctor ?? "";
        this.referingType = selectedPatient?.marketingReferralType ?? "";
        this.referringFacility = selectedPatient?.referringProviderFacility ?? "";
        this.renderingType = selectedPatient?.renderingProviderType ?? "";
        this.renderingFacility = selectedPatient?.renderingProviderFacility ?? "";
        this.referringType = selectedPatient?.referringProviderType ?? "";
        this.MarRepDoctor = selectedPatient?.marketingRepType ?? "";
        this.ReferringDoctor = selectedPatient?.referringProviderDoctor ?? "";
        this.RenderingDoctor = selectedPatient?.renderingProviderDoctor ?? "";
        this.txt1Icd10 = selectedPatient?.diagnosisCode10 ?? [];
        this.Icd9 = selectedPatient?.diagnosisCode9 ?? [];
        // this.Icd9= selectedPatient?.diagnosisCode9;


      }, err => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      });
      this.subscription$.push(storeSubscription);
    }

  }
  sameAsOrderDoctor() {
    this.ClinicalForm.patchValue({
      ClinicalGeneral: {
        txtPrimaryDoctor: this.ClinicalForm.value.ClinicalGeneral.txtOrderingDoctor,
      },
    })
  }
  sameAsReferalDoctor() {
    this.ClinicalForm.patchValue({
      ClinicalGeneral: {
        txtMarketingRep: this.ClinicalForm.value.ClinicalGeneral.txtReferralDoctor,
      },
    })
  }

  saveContact() {
    if (this.savedContactData && this.savedContactData !== []) {
      this.contactsForm.patchValue({
        ContactsGroup: {
          drpEmergencyRelationshipType: this.savedContactData['controls'].ContactsGroup['controls'].drpEmergencyRelationshipType.value,
          txtEmergencyFirstName: this.savedContactData['controls'].ContactsGroup['controls'].txtEmergencyFirstName.value,
          txtEmergencyMiddleName: this.savedContactData['controls'].ContactsGroup['controls'].txtEmergencyMiddleName.value,
          txtEmergencyLastName: this.savedContactData['controls'].ContactsGroup['controls'].txtEmergencyLastName.value,
          txtEmergencyAddress: this.savedContactData['controls'].ContactsGroup['controls'].txtEmergencyAddress.value,
          txtEmergencyCity: this.savedContactData['controls'].ContactsGroup['controls'].txtEmergencyCity.value,
          txtEmergencyState: this.savedContactData['controls'].ContactsGroup['controls'].txtEmergencyState.value,
          txtEmergencyCountry: this.savedContactData['controls'].ContactsGroup['controls'].txtEmergencyCountry.value,
          txtEmergencyCounty: this.savedContactData['controls'].ContactsGroup['controls'].txtEmergencyCounty.value,
          txtEmergencyPostalCode: this.savedContactData['controls'].ContactsGroup['controls'].txtEmergencyPostalCode.value,
          txtEmergencyPhone: this.savedContactData['controls'].ContactsGroup['controls'].txtEmergencyPhone.value,
          txtEmergencyMobile: this.savedContactData['controls'].ContactsGroup['controls'].txtEmergencyMobile.value,
          txtEmergencyFax: this.savedContactData['controls'].ContactsGroup['controls'].txtEmergencyFax.value,
          txtEmergencyEmail: this.savedContactData['controls'].ContactsGroup['controls'].txtEmergencyEmail.value,

          drpResponsibleRelationshipType: this.savedContactData['controls'].ContactsGroup['controls'].drpResponsibleRelationshipType.value,
          txtResponsibleName: this.savedContactData['controls'].ContactsGroup['controls'].txtResponsibleName.value,
          txtResponsibleMiddleName: this.savedContactData['controls'].ContactsGroup['controls'].txtResponsibleMiddleName.value,
          lastNamtxtResponsibleLastName: this.savedContactData['controls'].ContactsGroup['controls'].lastNamtxtResponsibleLastName.value,
          txtResponsibleAddress: this.savedContactData['controls'].ContactsGroup['controls'].txtResponsibleAddress.value,
          txtResponsibleCity: this.savedContactData['controls'].ContactsGroup['controls'].txtResponsibleCity.value,
          txtResponsibleState: this.savedContactData['controls'].ContactsGroup['controls'].txtResponsibleState.value,
          txtResponsibleCountry: this.savedContactData['controls'].ContactsGroup['controls'].txtResponsibleCountry.value,
          txtResponsibleCounty: this.savedContactData['controls'].ContactsGroup['controls'].txtResponsibleCounty.value,
          txtResponsiblePostalCode: this.savedContactData['controls'].ContactsGroup['controls'].txtResponsiblePostalCode.value,
          txtResponsiblePhone: this.savedContactData['controls'].ContactsGroup['controls'].txtResponsiblePhone.value,
          txtResponsibleMobile: this.savedContactData['controls'].ContactsGroup['controls'].txtResponsibleMobile.value,
          txtResponsibleFax: this.savedContactData['controls'].ContactsGroup['controls'].txtResponsibleFax.value,
          txtResponsibleEmail: this.savedContactData['controls'].ContactsGroup['controls'].txtResponsibleEmail.value,
        },
      });
    }
    this.saveContact();
    this.savedContactData = [];
  }
}

export interface doctorInterface {
  id: string;
  name: string;
}
