<div class="row pb-2">
  <div class="col-lg-10 d-flex align-items-center">
    <h2 class="mb-2 header-text" mat-dialog-title>
      <B>Add Manufacturer</B>
    </h2>
  </div>
  <div class="col-lg-2 text-right">
    <button
      type="button"
      mat-icon-button
      class="close-button"
      (click)="onCloseClick()"
    >
      <mat-icon>close</mat-icon>
    </button>
  </div>
</div>
<mat-dialog-content class="mat-typography">
  <div class="card card-body">
    <div class="row">


    <form [formGroup]="productVendorForm">
      <mat-form-field class="col-6 dd-text">
        <mat-label
          >Manufacturer Code
          <span class="asterisk">*</span></mat-label
        >
        <input
          autocomplete="off"
          matInput
          formControlName="txtVendorCode"
          placeholder="Enter Manufacturer  Code"
          type="text"
          maxlength="40"
        />
        <mat-error
          *ngIf="
            productVendorForm?.get('txtVendorCode')?.touched &&
            productVendorForm?.get('txtVendorCode')?.errors?.required
          "
        >
          Manufacturer Code is a required field!
        </mat-error>
        <mat-error
          *ngIf="
            productVendorForm?.get('txtVendorCode')?.touched &&
            productVendorForm?.get('txtVendorCode')?.errors
              ?.sAlphabets
          "
        >
          Enter only Alphabets!
        </mat-error>
      </mat-form-field>

      <!-- Manufacturer  Name -->
      <mat-form-field class="col-6 dd-text">
        <mat-label
          >Manufacturer Name
          <span class="asterisk">*</span></mat-label
        >
        <input
          autocomplete="off"
          matInput
          formControlName="txtVendorName"
          placeholder="Enter Manufacturer  Name"
          type="text"
          maxlength="40"
        />
        <mat-error
          *ngIf="
            productVendorForm?.get('txtVendorName')?.touched &&
            productVendorForm?.get('txtVendorName')?.errors?.required
          "
        >
          Manufacturer Name is a required field!
        </mat-error>
        <!-- <mat-error
          *ngIf="
            productVendorForm?.get('txtVendorName')?.touched &&
            productVendorForm?.get('txtVendorName')?.errors
          "
        >
          Enter only Alphabets!
        </mat-error> -->
      </mat-form-field>
    </form>
    </div>
  </div>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button
                    mat-button
                    (click)="saveVendorDetails()"
                    class="buttonColor mb-1 mr-2"
                    [disabled]="
                      !(
                        !productVendorForm?.invalid && productVendorForm?.dirty
                      ) || saveButtonHide
                    "
                  >
                    Save
                  </button>
  <button class="buttonCancelColor" mat-button (click)="onCloseClick()">Close</button>

  <!-- <button mat-button (click)="check()" >Check</button> -->
  <!-- <button class="buttonCancelColor" mat-button mat-dialog-close>Close</button> -->
</mat-dialog-actions>
