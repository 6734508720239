
<div class="col-12">
    <div class="row">
<h1 mat-dialog-title class="col-10 tittle"><b>View Notes</b></h1>
<div class="col-2" style="text-align-last: right">
    <button mat-icon-button mat-dialog-close=" close-button ">
        <mat-icon class="mr-2">close</mat-icon>
      </button>
</div>
</div>
</div>
<mat-dialog-content class="mat-typography" style="height:90% !important">
<div class="container-fluid">
    <div >
        
    </div>
<div class="col-lg-12 content table-responsive">
    <div class="card card-body">
    <table
      id="tbl"
      datatable
      style="width: 100%"
      [dtOptions]="dtInboundOptions"
      class="row-border hover"
    >
      <thead>
        <tr>
          <th>#</th>
          <th>Enter By Name</th>
          <th>Entered</th>
          <th>Note Type</th>
          <th>Note Text</th>
          <th>Modified</th>
         
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let data of inboundTableData">
          <td
            class="dt-control"
            (click)="handleTableCellClick($event, data.notes)"
          ></td>
          <td>{{ data.primaryIns }}</td>
          <td>{{ data.primaryIns }}</td>
          <td>{{ data.pages }}</td>
          <td>{{ data.patientIdChartNo }}</td>
          <td>{{ data.patient }}</td>
       

        </tr>
      </tbody>
      <tfoot>
        <tr>
          <th>#</th>
         
          <th>
            <input
              matInput
              class="custom-search-input"
              type="text"
              placeholder="Enter By Name"
              name="search-enterByName"
            />
          </th>
          <th>
            <input
              matInput
              type="text"
              class="custom-search-input"
              placeholder="Entered"
              name="search-Entered"
            />
          </th>
          <th>
            <input
              matInput
              type="text"
              class="custom-search-input"
              placeholder="Note Type"
              name="search-noteType"
            />
          </th>
          <th>
            <input
              matInput
              type="text"
              class="custom-search-input"
              placeholder="Note Text"
              name="search- noteText"
            />
          </th>
          <th>
            <input
              matInput
              class="custom-search-input"
              type="text"
              placeholder="Modified"
              name="search-Modified"
            />
          </th>
         
        </tr>
      
      </tfoot>
    </table>
    </div>
  </div><br><br>
  <div class="col-12">
    <div class="card card-body">
        <br><br>
        <p>{{notesData}}</p>
        <br><br>
    </div>
  </div>
</div>
</mat-dialog-content>
