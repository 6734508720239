import { RestService } from '@abp/ng.core';
import type { PagedAndSortedResultRequestDto, PagedResultDto } from '@abp/ng.core';
import { Injectable } from '@angular/core';
import type { CreateUpdatePaymentCodeTypeDTO, PaymentCodeTypeDTO } from '../dto/models';

@Injectable({
  providedIn: 'root',
})
export class RCMCodeTypeService {
  apiName = 'billingManagement';

  create = (input: CreateUpdatePaymentCodeTypeDTO) =>
    this.restService.request<any, PaymentCodeTypeDTO>({
      method: 'POST',
      url: '/api/billingApp/r-cMCode-type',
      body: input,
    },
    { apiName: this.apiName });

  delete = (id: string) =>
    this.restService.request<any, void>({
      method: 'DELETE',
      url: `/api/billingApp/r-cMCode-type/${id}`,
    },
    { apiName: this.apiName });

  get = (id: string) =>
    this.restService.request<any, PaymentCodeTypeDTO>({
      method: 'GET',
      url: `/api/billingApp/r-cMCode-type/${id}`,
    },
    { apiName: this.apiName });

  getList = (input: PagedAndSortedResultRequestDto) =>
    this.restService.request<any, PagedResultDto<PaymentCodeTypeDTO>>({
      method: 'GET',
      url: '/api/billingApp/r-cMCode-type',
      params: { skipCount: input.skipCount, maxResultCount: input.maxResultCount, sorting: input.sorting },
    },
    { apiName: this.apiName });

    getListV1 = () =>
      this.restService.request<any, PagedResultDto<PaymentCodeTypeDTO>>({
        method: 'GET',
        url: '/api/billingApp/r-cMCode-type',
      },
      { apiName: this.apiName });

  update = (CodeId: string, input: CreateUpdatePaymentCodeTypeDTO) =>
    this.restService.request<any, PaymentCodeTypeDTO>({
      method: 'PUT',
      url: '/api/billingApp/r-cMCode-type',
      params: { codeId: CodeId },
      body: input,
    },
    { apiName: this.apiName });

  constructor(private restService: RestService) {}
}
