// datepicker-dialog.component.ts
import {
  Component,
  OnInit,
  ViewChild,
  AfterViewInit,
  Inject,
  EventEmitter,
  Output,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  MatDatepicker,
  MatDatepickerInputEvent,
} from '@angular/material/datepicker';
import { CreateUpdatePatientNextCallDTO } from '../../patient-proxy/patient-optimization/dto/models';
import { PatientPersonalService } from '../../patient-proxy/patient-optimization/patient-personal.service';
import { ToastrService } from 'ngx-toastr';
import { CommunicationService } from 'projects/shared/src/app/services/communication.service';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-datepicker-dialog',
  template: `
    <div class="row align-items-center">
      <div class="col-md-10">
        <h2 class="customThemeClass">Add/Update Next Call Date</h2>
      </div>
      <div class="col-md-2" style="text-align: right">
        <mat-icon mat-dialog-close>close</mat-icon>
      </div>
    </div>
    <mat-dialog-content>
      <form [formGroup]="fgNextCallDate">
        <mat-form-field class="mb-3">
          <mat-label>Next Call Date<span class="asterisk">*</span></mat-label>
          <input
          type="text"
            matInput
            autocomplete="new-dNextCallDate"
            maxlength="10"
            [matDatepicker]="datepicker"
            placeholder="MM/DD/YYYY"
            (dateChange)="onDateChange($event)"
            [ngModel]="defaultDate"
            [min]="minDate"
            (click)="openDatepicker()"
            formControlName="dNextCallDate"
            (keydown)="addSlashToDate($event)"
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="datepicker"
            [attr.aria-label]="customLabel"
          ></mat-datepicker-toggle>
          <mat-datepicker #datepicker></mat-datepicker>
          <!-- <div style="margin-top: 4px;"> -->
            <mat-error
              *ngIf="
                fgNextCallDate.get('dNextCallDate').touched &&
                fgNextCallDate.get('dNextCallDate').hasError('required')
              "
            >
              Next Call Date is required/Invalid!
            </mat-error>
            <mat-error
              *ngIf="
                fgNextCallDate.get('dNextCallDate').touched &&
                fgNextCallDate.get('dNextCallDate').hasError('minDateError')
              "
            >
              Next Call Date cannot be before today!
            </mat-error>
            <mat-error
              *ngIf="
                fgNextCallDate.get('dNextCallDate').touched &&
                fgNextCallDate.get('dNextCallDate').hasError('invalidDate')
              "
            >
              Enter Valid date MM/DD/YYYY ..!
            </mat-error>
          <!-- </div> -->
        </mat-form-field>
      </form>
    </mat-dialog-content>
  `,
})
export class DatepickerDialogComponent implements AfterViewInit {
  defaultDate: Date | null;
  // minDate: Date = new Date();
  minDate: Date;
  customLabel: string = 'Next Call Date';
  @ViewChild('datepicker') datepicker: MatDatepicker<any>;
  @Output() rowSelected = new EventEmitter<string>();
  fgNextCallDate: FormGroup;
  constructor(
    private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<DatepickerDialogComponent>,
    private communicationService: CommunicationService,
    private datepipe: DatePipe,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      patientId: string;
      tenantId: string;
      defaultDate: Date | null;
    },
    private patientPersonalService: PatientPersonalService,
    private toastr: ToastrService
  ) {}

  ngOnInit() {
    this.fgNextCallDate = this.formBuilder.group({
      dNextCallDate: [
        null,
        [
          Validators.required,
          this.minDateValidator.bind(this),
          this.isValidDateValidator.bind(this),
        ],
      ],
    });
    // Set the default date in ngOnInit
    this.defaultDate = this.data.defaultDate
      ? new Date(this.data.defaultDate)
      : null;
      this.minDate= new Date();
    // this.minDate=currentDate.getDate() + 1
    this.minDate.setDate(this.minDate.getDate() + 1);
    // Open the datepicker by default
    // setTimeout(() => {
    //   // this.datepicker.open();
    // }, 0);
  }

  ngAfterViewInit() {
    // Open the datepicker by default
    // this.datepicker.open();
  }
  ngOnDestroy() {
    // Close the datepicker when the component is destroyed
    this.datepicker.close();
  }

  addSlashToDate(event: Event): void {
    const input = event.target as HTMLInputElement;
    const inputValue = input.value;
    input.value = inputValue
      .replace(/^(\d{2})(\d{1,2})/, '$1/$2')
      .replace(/^(\d{2}\/\d{2})(\d+)/, '$1/$2')
      .replace(/[^\d\/]/g, '');
  }



  minDateValidator(control) {
    if(control.value){
      const selectedDate = new Date(control.value);
      const minDate = new Date();
      // minDate.setHours(0, 0, 0, 0);
      return selectedDate >= minDate ? null : { minDateError: true };
    }
  }

  isValidDateValidator(control: FormControl) {

    const selectedDate = new Date(control.value);

    const year = selectedDate.getFullYear();
    const month = selectedDate.getMonth() + 1; // Month is zero-based, so add 1
    const day = selectedDate.getDate();

    const isValidDate = this.isValidDate(year, month, day);
    return isValidDate ? null : { invalidDate: true };
  }

  closeDialog() {
    this.dialogRef.close();
  }
  openDatepicker() {
    this.datepicker.open();
  }

  isValidDate(year: number, month: number, day: number): boolean {
    // Check if year, month, and day are valid
    // For simplicity, you can use a basic check here
    // You can add more complex validation logic if needed
    if (
      year >= 1000 &&
      year <= 9999 &&
      month >= 1 &&
      month <= 12 &&
      day >= 1 &&
      day <= 31
    ) {
      return true;
    } else {
      return false;
    }
  }
  getFormattedDateAndTime(date: any): string {
    return date
      ? new Date(date).toLocaleString('en-US', {
        month: '2-digit',
        day: '2-digit',
        year: 'numeric',
      })
      : '-';
  }
  // onDateChange(selectedDate: Date | null): void {
  onDateChange(event: MatDatepickerInputEvent<Date>): void {
    // .toISOString()
    if (this.fgNextCallDate.valid) {
      const selectedDate: Date | null = event.value;
      //  const selectedDateV1: string|null = this.getFormattedDateAndTime(event.value);
      if (selectedDate) {
        const year = selectedDate.getFullYear();
        const month = selectedDate.getMonth() + 1; // Month is zero-based, so add 1
        const day = selectedDate.getDate();
        const isValidDate = this.isValidDate(year, month, day);
        const selectedDateV1 =this.datepipe.transform(selectedDate, 'yyyy-MM-dd');;
        if (isValidDate) {
          // Handle the date change event
          const patientId = this.data.patientId;
          const tenantId = this.data.tenantId;
          let nextCall: CreateUpdatePatientNextCallDTO = {
            patientId: patientId,
            nextCallDate: selectedDateV1,
            isNextCall: true,
            tenantId: tenantId,
          };

          const netCallDate = this.patientPersonalService
            .patientNextCall(nextCall)
            .subscribe((response) => {
              this.toastr.success(
                'Next Call date updated successfully',
                'Success'
              );

              // this.communicationService.triggerNextCallUpdateUpdateGetPatientMnt();

              setTimeout(() => {
                this.communicationService.triggerpatientList(patientId);
              }, 1000);

              // this.rowSelected.emit(patientId);
            });

          // Close the modal after handling the event
          this.dialogRef.close();
        } else {
          this.toastr.error('Please select Valid date');
        }
      }
    }
  }
}
