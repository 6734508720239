<div class="container-fluid main-content">
  <div class="row bg">
    <div class="col-12">
      <div class="d-flex align-items-center justify-content-between mb-0" style="margin-top:-15px;">
        <h3 class="font-weight-bolder mb-0">Patient / Claim Status List</h3>
        <div class="page-title-box page-title-right">
          <ol class="breadcrumbs m-0 align-items-center">
            <form [formGroup]="branchForm">
              <mat-form-field class="pr-3">
                <mat-label>Branch</mat-label>
                <mat-select #selectBranch formControlName="drpBranch" multiple [(value)]="organizationUnitName">
                  <mat-option>
                    <ngx-mat-select-search
                      placeholderLabel="Search"
                      noEntriesFoundLabel="No Matches found"
                      formControlName="txtBranch"
                    ></ngx-mat-select-search>
                  </mat-option>

                  <mat-option #selectAllBranch (click)="allBranchSelect()" value="0">All</mat-option>

                  <mat-option *ngFor="let branch of brnachList | async" [value]="branch.id">
                    {{ branch.organizationUnitName }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <button mat-button class="mr-2 buttonColor"
                [disabled]="(dataSource && dataSource?.data && dataSource?.data?.length === 0)" (click)="
                exporter.exportTable('xlsx', {
                  fileName: 'Claim Status List',
                  sheet: 'Claim Status List',
                  Props: { Author: 'QSecure' }
                })
              ">Export</button>
            </form>

            <!-- <li class="breadcrumb-item">Patient</li>
            <li class="breadcrumb-item">Claim List</li> -->
          </ol>
        </div>
      </div>
    </div>
    <div class="col-12 pb-5">
      <form [formGroup]="claimSearchForm">
        <div class="table table-responsive example-container mat-elevation-z4 mb-0 pb-0">
          <table mat-table id="{{ strPageType }}" class="pt-0 mt-0" matTableExporter #exporter="matTableExporter"
            [hiddenColumns]="[14]" [dataSource]="dataSource" matSort>
            <ng-container matColumnDef="defaultPatientId"sticky>
              <th class="header" mat-header-cell mat-sort-header *matHeaderCellDef>
                <mat-form-field class="filter">
                  <mat-label class="center">Patient Id</mat-label>
                  <input matInput (keydown.space)="$event.stopPropagation()" (click)="$event.stopPropagation()" formControlName="patientId" autocomplete="new-patientId" />
                </mat-form-field>
              </th>
              <td mat-cell *matCellDef="let element">
                <a matTooltip="Click to View" class="eyeCursorclass"
                  [routerLink]="'/patientCreateEditTab/' + element?.patientId" target="_blank">
                  {{ element?.defaultPatientId }}
                </a>
              </td>
            </ng-container>
            <ng-container matColumnDef="patientName">
              <th class="header" mat-header-cell mat-sort-header *matHeaderCellDef>
                <mat-form-field class="filter">
                  <mat-label class="center">Patient Name</mat-label>
                  <input matInput (keydown.space)="$event.stopPropagation()" (click)="$event.stopPropagation()" formControlName="patientName" autocomplete="new-patientName" />
                </mat-form-field>
              </th>
              <td mat-cell *matCellDef="let element">
                {{ element?.patientName }}
              </td>
            </ng-container>
            <ng-container matColumnDef="branch">
              <th class="header" mat-header-cell mat-sort-header *matHeaderCellDef>
                <mat-form-field class="filter">
                  <mat-label class="center">Branch</mat-label>
                  <input matInput (keydown.space)="$event.stopPropagation()" (click)="$event.stopPropagation()" formControlName="branch" autocomplete="new-branch" />
                </mat-form-field>
              </th>
              <td mat-cell *matCellDef="let element">-
              </td>
            </ng-container>
            <ng-container matColumnDef="insuranceName">
              <th class="header" mat-header-cell mat-sort-header *matHeaderCellDef>
                <mat-form-field class="filter">
                  <mat-label class="center"> Payer Name</mat-label>
                  <input matInput (keydown.space)="$event.stopPropagation()" (click)="$event.stopPropagation()" formControlName="payerName" autocomplete="new-payerName" />
                </mat-form-field>
              </th>
              <td mat-cell *matCellDef="let element">
                {{ element?.insuranceName }}
              </td>
            </ng-container>
            <ng-container matColumnDef="dos">
              <th class="header" mat-header-cell mat-sort-header *matHeaderCellDef>
                <mat-form-field class="filter">
                  <mat-label> Claim Service Date </mat-label>
                  <input matInput (keydown.space)="$event.stopPropagation()" (click)="$event.stopPropagation()" formControlName="claimServiceDate" autocomplete="new-claimServiceDate" />
                  <mat-error *ngIf="claimSearchForm?.get('claimServiceDate')?.errors?.dateVaidator">
                    Enter Valid date
                  </mat-error>
                </mat-form-field>
              </th>
              <td mat-cell *matCellDef="let element">
                {{ element?.dos | date : "MM/dd/yyyy" : "en_US" }}
              </td>
            </ng-container>


            <ng-container matColumnDef="defaultClaimId"sticky>
              <th class="header" mat-header-cell mat-sort-header *matHeaderCellDef>
                <mat-form-field class="filter">
                  <mat-label class="center"> Claim Number </mat-label>
                  <input matInput (keydown.space)="$event.stopPropagation()" (click)="$event.stopPropagation()" formControlName="claimNumber" autocomplete="new-claimNumber" />
                </mat-form-field>
              </th>
              <td mat-cell *matCellDef="let element">
                {{ element?.defaultClaimId }}
              </td>
            </ng-container>

            <ng-container matColumnDef="defaultOrderId" sticky>
              <th class="header" mat-header-cell mat-sort-header *matHeaderCellDef>
                <mat-form-field class="filter">
                  <mat-label class="center"> Sale Order Id</mat-label>
                  <input matInput (keydown.space)="$event.stopPropagation()" (click)="$event.stopPropagation()" formControlName="saleOrderId" autocomplete="new-saleOrderId" />
                </mat-form-field>
              </th>
              <td mat-cell *matCellDef="let element">
                <a matTooltip="Click to View Sale Order" class="eyeCursorclass" [routerLink]="
                  '/orders/saleorderCreateEditTab/' +
                  element?.patientId +
                  '/' +
                  element?.orderId
                " target="_blank">
                  {{ element?.defaultOrderId }}
                </a>
              </td>
            </ng-container>
            <ng-container matColumnDef="billedAmount">
              <th class="header" mat-header-cell mat-sort-header *matHeaderCellDef>
                <mat-form-field class="filter">
                  <mat-label class="center"> Billed Amount </mat-label>
                  <input matInput (keydown.space)="$event.stopPropagation()" (click)="$event.stopPropagation()" formControlName="billedAmount" autocomplete="new-billedAmount" />
                </mat-form-field>
              </th>
              <td mat-cell *matCellDef="let element">
                {{ element?.billedAmount | currency:'USD':'symbol'}}
              </td>
            </ng-container>
            <ng-container matColumnDef="billedQty">
              <th class="header" mat-header-cell mat-sort-header *matHeaderCellDef>
                <mat-form-field class="filter">
                  <mat-label class="center"> Billed Quantity </mat-label>
                  <input matInput (keydown.space)="$event.stopPropagation()" (click)="$event.stopPropagation()" formControlName="billedQty" autocomplete="new-billedQty" />
                </mat-form-field>
              </th>
              <td mat-cell *matCellDef="let element">
                {{ element?.billedQuantity }}
              </td>
            </ng-container>
            <ng-container matColumnDef="claimStatus">
              <th class="header" mat-header-cell mat-sort-header *matHeaderCellDef>
                <mat-form-field class="filter">
                  <mat-label class="center"> Status </mat-label>
                  <input matInput (keydown.space)="$event.stopPropagation()" (click)="$event.stopPropagation()" formControlName="appClaimStatus" autocomplete="new-appClaimStatus" />
                </mat-form-field>
              </th>
              <td mat-cell *matCellDef="let element">
                <ng-container [ngSwitch]="element?.claimStatus">
                  <ng-container *ngSwitchCase="'claimProcessed'">Claim Processed
                  </ng-container>
                  <ng-container *ngSwitchCase="'claimDenied'">Claim Denied
                  </ng-container>
                  <ng-container *ngSwitchCase="'readyToProcess'">Ready To Process
                  </ng-container>
                  <ng-container *ngSwitchCase="'claimCompleted'">Claim Completed
                  </ng-container>
                  <ng-container *ngSwitchCase="'postingCompleted'">Posting Completed
                  </ng-container>
                  <ng-container *ngSwitchCase="'primaryPostingPending'">Primary Posting Pending
                  </ng-container>
                  <ng-container *ngSwitchCase="'secondaryPostingPending'">Secondary Posting Pending
                  </ng-container>
                  <ng-container *ngSwitchCase="'tertiaryPostingPending'">
                    Tertiary Posting Pending</ng-container>
                  <ng-container *ngSwitchCase="'eobPending'">
                    EOB Pending</ng-container>
                  <ng-container *ngSwitchDefault>Ready To Process</ng-container>
                </ng-container>
              </td>
            </ng-container>

            <ng-container matColumnDef="postingCount">
              <th class="header" mat-header-cell mat-sort-header *matHeaderCellDef>
                <mat-form-field class="filter">
                  <mat-label class="center"> Posting Count </mat-label>
                  <input matInput (keydown.space)="$event.stopPropagation()" (click)="$event.stopPropagation()" formControlName="remitClaimStatus" autocomplete="new-remitClaimStatus" />
                </mat-form-field>
              </th>
              <td mat-cell *matCellDef="let element">
                <span>{{ element?.postingCount }} </span>
              </td>
            </ng-container>
            <ng-container matColumnDef="option">
              <th class="header" mat-header-cell *matHeaderCellDef>
                <mat-label> Options</mat-label>
              </th>
              <td mat-cell *matCellDef="let element">

                <a class="customThemeClass">
                  <i matTooltip="Click Add Item Posting" (click)="paymentItemsModalEvent('','',element.claimId)"
                    class="fa fa-plus"></i>
                </a>

                <a *ngIf="element?.claimId" class="eyeCursorclass customThemeClass ml-2">
                  <i [routerLink]="'/AddNewPosting/' + element?.claimId" target="_blank" class="fa fa-eye"
                    matTooltip="Click To View Manual Posting List">
                  </i>
                </a>
                <a *ngIf="!element?.isVoided" class="editBtnColor eyeCursorclass ml-2">
                  <i matTooltip="Click to Check Status" target="_blank" (click)="openClaimHistoryModal(element.claimId)"
                    class="fa fa-pencil"></i>
                </a>
              </td>
            </ng-container>
            <ng-container matColumnDef="providerName">
              <th class="header" mat-header-cell mat-sort-header *matHeaderCellDef>
                <mat-form-field class="filter">
                  <mat-label class="center"> Provider Name </mat-label>
                  <input matInput (keydown.space)="$event.stopPropagation()" (click)="$event.stopPropagation()" formControlName="billingName" autocomplete="new-billingName" />
                </mat-form-field>
              </th>
              <td mat-cell *matCellDef="let element">
                {{ element?.providerName }}
              </td>
            </ng-container>
            <ng-container matColumnDef="wayStarClaimStatus">
              <th class="header" mat-header-cell mat-sort-header *matHeaderCellDef>
                <mat-form-field class="filter">
                  <mat-label class="center">  Status </mat-label>

                  <input matInput (keydown.space)="$event.stopPropagation()" (click)="$event.stopPropagation()" formControlName="zirmedClaimStatus" autocomplete="new-zirmedClaimStatus" />
                </mat-form-field>
              </th>
              <td mat-cell *matCellDef="let element">
                {{ element?.wayStarClaimStatus }}
              </td>
            </ng-container>

            <ng-container matColumnDef="clearingHouse">
              <th class="header" mat-header-cell mat-sort-header *matHeaderCellDef>
                <mat-form-field class="filter">
                  <mat-label class="center">  Clearing house</mat-label>

                  <input matInput (keydown.space)="$event.stopPropagation()" (click)="$event.stopPropagation()" formControlName="zirmedClaimStatus" autocomplete="new-zirmedClaimStatus" />
                </mat-form-field>
              </th>
              <td mat-cell *matCellDef="let element">
               Waystar
              </td>
            </ng-container>

            <ng-container matColumnDef="claimProcessedDate">
              <th class="header" mat-header-cell mat-sort-header *matHeaderCellDef>
                <mat-form-field class="filter">
                  <mat-label> Processed Date</mat-label>

                  <input matInput (keydown.space)="$event.stopPropagation()" (click)="$event.stopPropagation()" formControlName="claimProcessedDate" autocomplete="new-claimProcessedDate" />
                  <mat-error *ngIf=" claimSearchForm?.get('claimProcessedDate')?.errors?.dateVaidator">
                    Enter Valid date
                  </mat-error>
                </mat-form-field>
              </th>
              <td mat-cell *matCellDef="let element">
                {{ element?.claimProcessedDate | date : "MM/dd/yyyy" : "en_US" }}
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="arrDisplayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: arrDisplayedColumns"></tr>
          </table>
          <div class="no-records text-align-center" *ngIf="!isLoading && dataSource?.data?.length===0">
            <div class="message py-3">
              {{ "No Records Found" }}
            </div>
          </div>
          <div class="no-records text-align-center" *ngIf="isLoading">
            <div class="py-3">
              <mat-spinner [diameter]="220"></mat-spinner>
            </div>
          </div>
        </div>
        <mat-paginator #MatPaginator [length]="dataSource?.data?.length" class="mat-elevation-z4" [pageSize]="10"
          [pageIndex]="0" showFirstLastButtons [pageSizeOptions]="[5, 10, 25, 100]">
        </mat-paginator>
      </form>
    </div>
  </div>
</div>
