<div class="container-fluid ">
  <div class="row bg">
    <div class="col-12 main">
      <div class="d-flex align-items-center justify-content-between mb-3">
        <h3 class="font-weight-bolder mb-0">Claim & Charges</h3>
        <div class=" page-title-box page-title-right">
          <ol class="breadcrumbs m-0 align-items-center">
            <li class="mb-1 mr-2">
              <button matTooltip="Click To Goback to Billing List" mat-button class="buttonColor font-size-10"
                (click)="backToBillingList()">
                <i class="fa fa-backward mr-1"></i> Back To Billing List
              </button>
            </li>
            <li class="breadcrumb-item">Billing</li>
            <li class="breadcrumb-item ">Claim & Charges</li>
          </ol>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="row" style="margin-top: -10px;">
  <div class="col-xl-2 pr-0">
    <div class="card card-body">
      <div *ngIf="lblDefaultClaimId" class="text-center">
        <span style="font-size: larger;"><b class="colored-heading">Claim ID </b></span>
        <h3 style="margin: 0 0 4px;"><b>{{ lblDefaultClaimId }}</b></h3>
      </div>
      <div  class="text-center">
        <span style="font-size: larger;"><b class="colored-heading">Sale Order ID </b></span>
        <h3 style="margin: 0 0 4px;"><b>{{ lblDefaultSaleOrderId }}</b></h3>
      </div>
      <div  class="text-center">
        <span style="font-size: larger;"><b class="colored-heading">Patient ID </b></span>
        <h3 style="margin: 0 0 4px;"><b>{{ lblDefaultPatientId }}</b></h3>
      </div>
      <div  class="text-center">
        <span style="font-size: larger;"><b class="colored-heading">Patient Name </b></span>
        <h3 style="margin: 0 0 4px;"><b>{{ lblPatientName }}</b></h3>
      </div>
      <div class="table-responsive mt-2">
        <table class="table table-centered">
          <tbody>
            <!-- Newly added params -->
            <tr>
              <td class="blinking">
                <p class="mb-0 text-center">CurrentStatus</p> <br>
                <h5 class="btm text-center">{{ currentOrderStatus }}</h5>
              </td>
            </tr>
            <!-- Newly added params -->
            <tr>
              <td>
                <p class="mb-0 text-center">Location</p> <br>
                <h5 class="btm text-center">{{ strLocation }}</h5>
              </td>

            </tr>
            <tr>
              <td>
                <p class="mb-0 text-center">Gender</p> <br>
                <h5 class="btm text-center">{{ lblPatientGender }}</h5>
              </td>
            <tr>
              <td>

                <p class="mb-0 text-center">DOB</p> <br>
                <h5 class="btm text-center">{{ lblPatientDOB }}</h5>
              </td>
            </tr>
            <!-- <tr>
                <td>
                  <p class="mb-0 text-center">Height</p> <br>
                  <h5 class="btm text-center">{{ lblPatientHeight | mask: "0'00''" }}</h5>
                </td>
              </tr>
              <tr>
                <td>
                  <p class="mb-0 text-center">Weight(Lbs)</p> <br>
                  <h5 class="btm text-center">{{ lblPatientWeight }}</h5>
                </td>
              </tr>
              <tr>
                <td>
                  <p class="mb-0 text-center">Branch Office </p> <br>
                  <h5 class="btm text-center">{{ lblBranchOffice }}</h5>
                </td>
              </tr>
              <tr>
                <td>
                  <p class="mb-0  text-center">Account </p><br>
                  <h5 class="btm text-center">{{ lblAccountNumber }}</h5>
                </td>
              </tr>-->
            <tr>
              <td>
                <p class="mb-0  text-center">SSN</p><br>
                <h5 class="btm  text-center">{{ lblSsnNumber }}</h5>
              </td>
              <!-- <td style="width: 60%">
                  <h5 class="mb-0">{{ lblSsnNumber }}</h5>
                </td> -->
            </tr>

            <tr>
              <td>
                <p class="mb-0  text-center">Mobile</p> <br>
                <h5 class="btm  text-center">{{ strMobile }}</h5>
              </td>
              <!-- <td style="width: 60%">
                  <h5 class="mb-0">{{ strMobile }}</h5>
                </td> -->
            </tr>
            <tr>
              <td>
                <p class="mb-0 text-center">Billing Country</p><br>
                <h5 class="btm  text-center">{{ strBillingCountry }}</h5>
              </td>
              <!-- <td style="width: 60%">
                  <h5 class="mb-0">{{ strEmailAddress }}</h5>
                </td> -->
            </tr>
            <!-- <tr>
                <td>
                  <p class="mb-0">Billing Address : {{ strBillingAddress }}</p>
                </td>

              </tr> -->

            <tr>
              <td>
                <p class="mb-0 text-center">Billing City</p><br>
                <h5 class="btm  text-center">{{ strBillingCity }}</h5>
              </td>
              <!-- <td style="width: 50%">
                  <h5 class="mb-0">{{ strBillingCity }}</h5>
                </td> -->
            </tr>
            <tr>
              <td>
                <p class="mb-0 text-center">Billing State</p><br>
                <h5 class="btm  text-center">{{ strBillingState }}</h5>
              </td>
              <!-- <td >
                  <h5 class="mb-0">{{ strBillingState }}</h5>
                </td> -->
            </tr>

            <tr>
              <td *ngIf="forBlink">
                <p class="mb-0 text-center">Billing Amount</p> <br>
                <h5 class="btm text-center">{{ lblBillingAmount }}</h5>
              </td>
            </tr>
            <tr>
              <td *ngIf="forBlink">
                <p class="mb-0 text-center">Billing Status</p> <br>
                <h5 class="btm text-center">{{ lblBillingStatus }}</h5>
              </td>
            </tr>

            <tr>
              <td *ngIf="!forBlink" class="blinking">
                <p class="mb-0 text-center">Billing Amount</p> <br>
                <h5 class="btm text-center">{{ lblBillingAmount }}</h5>
              </td>
            </tr>
            <tr>
              <td *ngIf="!forBlink" class="blinking">
                <p class="mb-0 text-center">Billing Status</p> <br>
                <h5 class="btm text-center">{{ lblBillingStatus }}</h5>
              </td>
            </tr>
            <tr>
              <td>
                <p class="mb-0 text-center">Last Updated On </p> <br>
                <h5 class="btm text-center">{{ lblLastUpdatedDateTime | date :'MM/dd/yyyy h:mm a'
                  :'en_US' }}
                </h5>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  <div class="col-xl-10">
    <!-- <mat-accordion #firstAccordion="matAccordion">
            <mat-expansion-panel class="mr-3" [expanded]="step">
                <mat-expansion-panel-header>
                    <mat-panel-title class="customThemeClass">
                        <ng-container>
                            <b>Patient Information</b>
                        </ng-container>
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <form [formGroup]="patientDetailsForm">
                    <h4 class="customThemeClass ml-3"><b>Patient</b></h4>
                    <mat-form-field class="col-3">
                        <mat-label> First Name <span class="asterisk">*</span></mat-label>
                        <input matInput maxlength="40" formControlName="txtFirstName" type="text" />
                        <mat-error *ngIf="
                                         patientDetailsForm?.get('txtFirstName')?.touched &&
                                         patientDetailsForm?.get('txtFirstName')?.errors?.required
                                        ">
                            First Name is a required field!
                        </mat-error>
                        <mat-error *ngIf="
                                         patientDetailsForm?.get('txtFirstName')?.touched &&
                                         patientDetailsForm?.get('txtFirstName')?.errors?.sAlphabets
                                        ">
                            Enter only Alphabets!
                        </mat-error>
                    </mat-form-field>

                    <mat-form-field class="col-3">
                        <mat-label>Middle Name</mat-label>
                        <input matInput maxlength="40" formControlName="txtMiddleName" type="text" />
                        <mat-error *ngIf="
                                           patientDetailsForm?.get('txtMiddleName')?.touched &&
                                           patientDetailsForm?.get('txtMiddleName')?.errors?.sAlphabets
                                          ">
                            Enter only Alphabets!
                        </mat-error>
                    </mat-form-field>

                    <mat-form-field class="col-3">
                        <mat-label> Last Name <span class="asterisk">*</span></mat-label>
                        <input matInput maxlength="40" formControlName="txtLastName" type="text" />
                        <mat-error *ngIf="
                         patientDetailsForm?.get('txtLastName')?.touched &&
                         patientDetailsForm?.get('txtLastName')?.errors?.required
                        ">
                            Last Name is a required field!
                        </mat-error>
                        <mat-error *ngIf="
                             patientDetailsForm?.get('txtLastName')?.touched &&
                             patientDetailsForm?.get('txtLastName')?.errors?.sAlphabets
                            ">
                            Enter only Alphabets!
                        </mat-error>
                    </mat-form-field>


                    <mat-form-field class="col-3">
                        <mat-label> Birth Date <span class="asterisk">*</span></mat-label>
                        <input [max]="BirthdateMaxDate" formControlName="txtBirthdate" matInput maxlength="40"
                            type="text" placeholder="(MM/DD/YYYY)" [matDatepicker]="txtBirthdate"
                            [min]="BirthdateMinDate" />
                        <mat-datepicker-toggle matSuffix [for]="txtBirthdate"> </mat-datepicker-toggle>
                        <mat-datepicker #txtBirthdate> </mat-datepicker>
                        <mat-error
                            *ngIf="
                                patientDetailsForm?.get('txtBirthdate')?.touched &&
                                patientDetailsForm?.get('txtBirthdate')?.errors && !(BirthdateMinDate<=patientDetailsForm?.get('txtBirthdate')?.value)">
                            Enter Valid date
                        </mat-error>
                        <mat-error
                            *ngIf="patientDetailsForm?.get('txtBirthdate')?.touched &&patientDetailsForm?.get('txtBirthdate')?.errors?.required">
                            Birth Date is a required field!
                        </mat-error>
                        <mat-error
                            *ngIf=" BirthdateMinDate<=patientDetailsForm?.get('txtBirthdate')?.value && !(patientDetailsForm?.get('txtBirthdate')?.touched &&patientDetailsForm?.get('txtBirthdate')?.errors?.required)">
                            Enter Past Dates Only!
                        </mat-error>
                    </mat-form-field>

                    <mat-form-field class="col-3">
                        <mat-label> Suffix</mat-label>

                        <mat-select maxlength="40" [(value)]="suffixName" formControlName="drpSuffix">
                            <mat-option *ngFor="let type of drpSuffixes" [value]="type.suffixShortCodeId">
                                {{ type.suffixName }}
                            </mat-option>
                        </mat-select>
                        <button mat-button (click)="suffixName='';$event.stopPropagation()" *ngIf="suffixName"
                            (click)="clearSuffix()" matSuffix mat-icon-button >
                            <mat-icon>close</mat-icon>
                        </button>
                    </mat-form-field>

                    <mat-form-field class="col-3">
                        <mat-label>SSN</mat-label>
                                 <input matInput formControlName="txtSSN" type="text" mask="000-00-0000" [validation]="false"
                            placeHolderCharacter="" />
                        <mat-error
                            *ngIf="patientDetailsForm?.get('txtSSN')?.touched &&patientDetailsForm?.get('txtSSN')?.errors">
                            Enter Minimum 4 Digits!
                        </mat-error>
                    </mat-form-field>

                    <mat-form-field class="col-3">
                        <mat-label>Gender</mat-label>
                        <mat-select formControlName="drpGender" [(value)]="gender" maxlength="40">
                            <mat-option *ngFor="let test of drpGenderLoop" [value]="test.genderShortCode">
                                {{ test.genderName }}
                            </mat-option>
                        </mat-select>
                        <button mat-button (click)="gender='';$event.stopPropagation()" *ngIf="gender" matSuffix
                            (click)="clearGender()" mat-icon-button >
                            <mat-icon>close</mat-icon>
                        </button>
                    </mat-form-field>

                    <mat-form-field class="col-3">
                        <mat-label>Provider <span class="asterisk">*</span></mat-label>
                        <mat-select maxlength="40" [(value)]="provider" formControlName="txtProvider">
                            <mat-option *ngFor="let providers of drpProviders" [value]="providers.shortCodeId">
                                {{ providers.name }}
                            </mat-option>
                        </mat-select>
                        <button mat-button (click)="provider='';$event.stopPropagation()" *ngIf="provider" matSuffix
                            (click)="clearProvider()" mat-icon-button >
                            <mat-icon>close</mat-icon>
                        </button>
                        <mat-error
                            *ngIf="patientDetailsForm?.get('txtProvider')?.touched &&patientDetailsForm?.get('txtProvider')?.errors?.required">
                            Provider is a required field!
                        </mat-error>
                    </mat-form-field>

                    <mat-form-field class="col-3">
                        <mat-label> Address</mat-label>
                        <textarea formControlName="txtAddress" rows="3" matInput maxlength="400"></textarea>
                    </mat-form-field>


                    <mat-form-field class="col-3">
                        <mat-label>Country</mat-label>
                        <mat-select formControlName="txtResponsibleCountry"
                            (selectionChange)='onChangeResState($event.value)' [(value)]="ResponsibleCountry"
                            #singleSelect>
                            <mat-option>
                                <ngx-mat-select-search placeholderLabel="Search" noEntriesFoundLabel="No Matches found"
                                    formControlName="txtResponsibleCountryFilter"></ngx-mat-select-search>
                            </mat-option>
                            <mat-option *ngFor="let country of filteredResponsibleCountries | async"
                                [value]="country.countryShortName">
                                {{ country.countryName }}
                            </mat-option>
                        </mat-select>
                        <button mat-button (click)="ResponsibleCountry=undefined;$event.stopPropagation()"
                            *ngIf="ResponsibleCountry" matSuffix (click)="clearResponsibleCountry()" mat-icon-button
                            >
                            <mat-icon>close</mat-icon>
                        </button>
                    </mat-form-field>

                    <mat-form-field class="col-3">
                        <mat-label>State</mat-label>
                        <mat-select formControlName="txtResponsibleState"
                            (selectionChange)='onChangeResCity($event.value,"")' [(value)]="ResponsibleState"
                            #singleSelect>
                            <mat-option>
                                <ngx-mat-select-search placeholderLabel="Search" noEntriesFoundLabel="No Matches found"
                                    formControlName="txtResponsibleStateFilter"></ngx-mat-select-search>
                            </mat-option>
                            <mat-option *ngFor="let state of filteredResponsibleStates | async"
                                [value]="state.stateShortName">
                                {{ state.stateName }}
                            </mat-option>
                        </mat-select>
                        <button mat-button (click)="ResponsibleState=undefined;$event.stopPropagation()"
                            *ngIf="ResponsibleState" matSuffix (click)="clearResponsibleState()" mat-icon-button
                            >
                            <mat-icon>close</mat-icon>
                        </button>
                    </mat-form-field>

                    <mat-form-field class="col-3">
                        <mat-label>City</mat-label>
                        <mat-select formControlName="txtResponsibleCity" [(value)]="ResponsibleCity" #singleSelect>
                            <mat-option>
                                <ngx-mat-select-search placeholderLabel="Search" noEntriesFoundLabel="No Matches found"
                                    formControlName="txtResponsibleCityFilter"></ngx-mat-select-search>
                            </mat-option>
                            <mat-option *ngFor="let city of filteredResponsibleCities | async" [value]="city.cityName">
                                {{ city.cityName }}
                            </mat-option>
                        </mat-select>
                        <button mat-button (click)="ResponsibleCity=undefined;$event.stopPropagation()"
                            *ngIf="ResponsibleCity" matSuffix (click)="clearResponsibleCity()" mat-icon-button
                            >
                            <mat-icon>close</mat-icon>
                        </button>
                    </mat-form-field>

                    <mat-form-field class="col-3">
                        <mat-label> County</mat-label>
                        <input formControlName="drpCounty" matInput maxlength="40" type="text" />
                        <mat-error *ngIf="
                       patientDetailsForm?.get('drpCounty')?.touched &&
                       patientDetailsForm?.get('drpCounty')?.errors?.sAlphabets
                      ">
                            Enter only Alphabets!
                        </mat-error>

                    </mat-form-field>

                    <mat-form-field class="col-3">
                        <mat-label> Zip Code </mat-label>
                        <input matInput maxlength="10" formControlName="txtBillingPostalCode" pattern="[0-9]*"
                            type="text" />
                        <mat-error *ngIf="
                         patientDetailsForm?.get('txtBillingPostalCode')?.touched &&
                         patientDetailsForm?.get('txtBillingPostalCode')?.errors?.pattern
                        ">
                            Enter only Numbers!
                        </mat-error>
                    </mat-form-field>

                    <mat-form-field class="col-3">
                        <mat-label> Phone</mat-label>
                        <input formControlName="txtBillingPhone" matInput maxlength="40" mask="(000)-000-0000"
                            type="text" />
                        <mat-error *ngIf="
                           patientDetailsForm?.get('txtBillingPhone')?.touched &&
                           patientDetailsForm?.get('txtBillingPhone')?.errors
                          ">
                            Enter Minimum 10 Digits!
                        </mat-error>
                    </mat-form-field>

                    <mat-form-field class="col-3">
                        <mat-label> Relationship </mat-label>
                        <mat-select [(value)]="relationships" formControlName="drpResponsibleRelationshipType"
                            maxlength="40">
                            <mat-option *ngFor=" let responsible of responsibleRelationship"
                                [value]="responsible.relationShipCode">
                                {{responsible.relationType}}
                            </mat-option>
                        </mat-select>
                        <button mat-button (click)="relationships=undefined;$event.stopPropagation()"
                            *ngIf="relationships" matSuffix (click)="clearRelationships()" mat-icon-button
                            >
                            <mat-icon>close</mat-icon>
                        </button>
                        <mat-error *ngIf="
                        patientDetailsForm?.get('drpResponsibleRelationshipType')?.touched &&
                        patientDetailsForm?.get('drpResponsibleRelationshipType')?.errors?.required">
                            Relationship is a required field!
                        </mat-error>
                    </mat-form-field>

                    <h4 class="customThemeClass ml-3"><b>Policy</b></h4>
                    <mat-form-field class="col-3">
                        <mat-label>Insurance<span class="asterisk">*</span></mat-label>
                        <mat-select formControlName="drpInsurance" [(value)]="Insurance" #singleSelect>
                            <mat-option>
                                <ngx-mat-select-search placeholderLabel="Search" noEntriesFoundLabel="No Matches found"
                                    formControlName="txtInsuranceFilter"></ngx-mat-select-search>
                            </mat-option>
                            <mat-option *ngFor="let insurance of filteredInsuranceTypes | async"
                                [value]="insurance.name">
                                {{insurance.name}}
                            </mat-option>
                        </mat-select>
                        <mat-error
                            *ngIf="patientDetailsForm?.get('drpInsurance')?.touched &&patientDetailsForm?.get('drpInsurance')?.errors?.required">
                            Insurance is a required field!
                        </mat-error>
                        <button mat-button (click)="Insurance=undefined;$event.stopPropagation()" *ngIf="Insurance"
                            matSuffix (click)="clearInsurance()" mat-icon-button >
                            <mat-icon>close</mat-icon>
                        </button>
                    </mat-form-field>

                    <mat-form-field class="col-3">
                        <mat-label>Payer Level</mat-label>
                        <input formControlName="txtPayerName" matInput type="text" />
                        <mat-error *ngIf="
                       patientDetailsForm?.get('txtPayerName')?.touched &&
                       patientDetailsForm?.get('txtPayerName')?.errors?.required
                          ">
                            Payer Name is a required field!
                        </mat-error>
                    </mat-form-field>

                    <mat-form-field class="col-3">
                        <mat-label>Payer Id<span class="asterisk">*</span></mat-label>
                        <mat-select formControlName="drpPayorName" [(value)]="payor" #singleSelect>
                            <mat-option>
                                <ngx-mat-select-search placeholderLabel="Search" noEntriesFoundLabel="No Matches found"
                                    formControlName="txtPayorFilter"></ngx-mat-select-search>
                            </mat-option>
                            <mat-option *ngFor="let payor of filterPayor | async" [value]="payor.name">
                                {{payor.name}}
                            </mat-option>
                        </mat-select>

                        <button mat-button (click)="payor=undefined;$event.stopPropagation()" *ngIf="payor" matSuffix
                            (click)="clearPayor()" mat-icon-button >
                            <mat-icon>close</mat-icon>
                        </button>
                    </mat-form-field>

                    <mat-form-field class="col-3">
                        <mat-label>Policy No<span class="asterisk">*</span></mat-label>
                        <input maxlength="40" formControlName="txtPolicyInfoPolicyNo" matInput type="text"
                            pattern="[a-zA-Z0-9]*" />
                        <mat-error *ngIf="
                           patientDetailsForm?.get('txtPolicyInfoPolicyNo')?.touched &&
                           patientDetailsForm?.get('txtPolicyInfoPolicyNo')?.errors?.pattern
                          ">
                            Enter Only Alphanumeric Only!
                        </mat-error>
                        <mat-error
                            *ngIf="patientDetailsForm?.get('txtPolicyInfoPolicyNo')?.touched &&patientDetailsForm?.get('txtPolicyInfoPolicyNo')?.errors?.required">
                            Policy No is a required field!
                        </mat-error>
                    </mat-form-field>

                    <h4 class="customThemeClass ml-3"><b>Diagnosis Code</b></h4>

                    <mat-form-field class="col-4">
                        <mat-label>Diagnosis Code-ICD-10</mat-label>
                        <mat-select multiple formControlName="txtdiagnosisCode10Id" [(value)]="txtIcd10" #singleSelect>
                            <mat-option>
                                <ngx-mat-select-search placeholderLabel="Search" noEntriesFoundLabel="No Matches found"
                                    formControlName="txtIcd10Filter"></ngx-mat-select-search>
                            </mat-option>
                            <mat-option *ngFor="let diagnosis of filteredIcd10Types | async"
                                [value]="diagnosis.diagnosisCode10ShortCodeId">
                                {{diagnosis.diagnosisCode + ' / ' + diagnosis.diagnosisCodeTypeData }}
                            </mat-option>
                        </mat-select>
                        <button mat-button (click)="txtIcd10=[];$event.stopPropagation()" *ngIf="txtIcd10" matSuffix
                            (click)="clearIcd10()" mat-icon-button>
                            <mat-icon>close</mat-icon>
                        </button>
                        <mat-error *ngIf="
                        claimForm?.get('txtdiagnosisCode10Id')?.touched &&
                        claimForm?.get('txtdiagnosisCode10Id')?.errors?.required
                           ">
                            Diagnosis Code-ICD-10 is a required field!
                        </mat-error>
                    </mat-form-field>

                    <h4 class="customThemeClass ml-3"><b>Order</b></h4>

                    <mat-form-field class="col-3">
                        <mat-label>Place Of Service <span class="asterisk">*</span></mat-label>
                        <mat-select maxlength="40" formControlName="drpGeneralPlaceOfService"
                            [(value)]="generalPlaceOfService">
                            <mat-option *ngFor="let pos of drpPlace" [value]="pos.placeOfServiceCode">
                                {{ pos.placeOfServiceType }}
                            </mat-option>
                        </mat-select>
                        <button mat-button (click)="clearGeneralPlaceOfService()"
                            (click)="generalPlaceOfService='';$event.stopPropagation()" *ngIf="generalPlaceOfService"
                            matSuffix mat-icon-button >
                            <mat-icon>close</mat-icon>
                        </button>
                        <mat-error *ngIf=" patientDetailsForm?.get('General.drpGeneralPlaceOfService')?.touched &&
                        patientDetailsForm?.get('General.drpGeneralPlaceOfService')?.errors?.required">
                            Place Of Service is a required field!
                        </mat-error>
                    </mat-form-field>

                    <div class="col-12 text-align-center" style='top:3px'>
                        <button mat-button class="buttonColor mb-2 mr-2" (click)="savePatientDetails()">Save
                        </button>
                        <button mat-button class="resetclr" (click)="reset()">Reset
                        </button>
                    </div>
                </form>
                <br />
            </mat-expansion-panel>
        </mat-accordion> -->
    <ul ngbNav #nav="ngbNav" (navChange)="onNavChange($event)" justify="justified"
      class="alert alert-success nav nav-pills nav-fill mt-3">
      <li ngbNavItem="claimInfo" class="nav-item">
        <a ngbNavLink>Claim Info</a>
        <ng-template ngbNavContent>
          <app-billing-claim-info (nextTabMoveOnSaveEmitter)="changeNextTab($event)"
            (claimIdEmitter)="claimDetailsAfterSave($event)" [claimOrderId]='claimId'
            (saveClaimInfoFormState)="onSaveClaimInfoData($event)" [patientId]='patientId' [orderId]='orderId'>
          </app-billing-claim-info>
        </ng-template>
      </li>
      <li [disabled]=" claimId==='00000000-0000-0000-0000-000000000000'" ngbNavItem="billingDocument" class="nav-item">
        <a ngbNavLink>Document</a>
        <ng-template ngbNavContent>
          <app-billing-documnet [strPageType]="'saleOrder'" [orderStatus]="true" [patientID]="patientId"
            [orderId]='orderId'> </app-billing-documnet>
        </ng-template>
      </li>
      <li [disabled]=" claimId==='00000000-0000-0000-0000-000000000000'" ngbNavItem="Posting" class="nav-item">
        <a ngbNavLink>Posting & Charges</a>
        <ng-template ngbNavContent>
          <app-posting-charges (nextTabMoveOnSaveEmitter)="changeNextTab($event)" [claimId]='claimId'
            [listOrderId]='listOrderId' [listClaimId]='listClaimId' [patientId]='patientId' [orderId]='orderId'
            (savePostingChargesFormState)="onSavePostingChargesData($event)">
          </app-posting-charges>
        </ng-template>
      </li>
      <li [disabled]=" claimId==='00000000-0000-0000-0000-000000000000'" ngbNavItem="patientVisit" class="nav-item">
        <a ngbNavLink>Patient Visit</a>
        <ng-template ngbNavContent>
          <app-billing-patient-visit [patientId]='patientId'></app-billing-patient-visit>
        </ng-template>
      </li>
    </ul>
    <div [ngbNavOutlet]="nav"></div>
  </div>
</div>
