<div class="container-fluid main-content">
  <div class="row bg">
    <div class="col-12">
      <div class="d-flex align-items-center justify-content-between mb-3">
        <h3 class="font-weight-bolder mb-0 pt-2 pb-2">Settings / OnTime Schedule</h3>
        <!-- <div class=" page-title-box page-title-right">
          <ol class="breadcrumbs m-0 align-items-center">
            <li class="breadcrumb-item">Settings</li>
            <li class="breadcrumb-item ">OnTime Settings</li>
          </ol>
        </div> -->
      </div>
    </div>
  </div>
</div>
<div class="col-12" style="margin-top: -21px;">
  <!-- <div class="card">
            <div class="card-body"> -->
  <ul ngbNav #nav="ngbNav" justify="justified" class="alert alert-success nav nav-pills nav-fill">
    <li ngbNavItem="appointmentType" class="nav-item">
      <a ngbNavLink>New</a>
      <ng-template ngbNavContent>
        <app-ontime-schedule-new></app-ontime-schedule-new>
      </ng-template>
    </li>
    <li ngbNavItem="holiday" class="nav-item">
      <a ngbNavLink>Completed</a>
      <ng-template ngbNavContent>

      </ng-template>
    </li>
    <li ngbNavItem="items" class="nav-item">
      <a ngbNavLink>Reschedule</a>
      <ng-template ngbNavContent>

      </ng-template>
    </li>
    <li ngbNavItem="user" class="nav-item">
      <a ngbNavLink>Calender</a>
      <ng-template ngbNavContent>
        <app-calender-schedule></app-calender-schedule>
        <app-admin-calender></app-admin-calender>
      </ng-template>
    </li>

  </ul>
  <div [ngbNavOutlet]="nav"></div>

</div>
