<div class="card card-body">
        <form [formGroup]="maoreInfoForm">
            <!-- <div class="row" style="margin-right: 10rem !important;"> -->
                <div class="col-12">
                    <mat-label><b class="customThemeClass">Sub Contract Agreement</b></mat-label>
                </div>
                <div class="col-12">
                <ng-container class="col-4">
                    <mat-checkbox class="mr-2 mt-3" formControlName="chkStat"> <span
                        class="font-weight-bolder">Sub Contract
                        Agreement</span></mat-checkbox>
                </ng-container>

                <!-- Start Date Field -->
                <mat-form-field class="col-4">
                    <mat-label>Start Date <span class="asterisk">*</span></mat-label>
                    <input autocomplete="new-txtStartdate" matInput formControlName="txtStartdate"
                        placeholder="(MM/DD/YYYY)" [formControl]="maoreInfoForm.controls.txtStartdate"
                        [matDatepicker]="myPicker">
                    <mat-datepicker-toggle matSuffix [for]="myPicker">
                    </mat-datepicker-toggle>
                    <mat-datepicker #myPicker></mat-datepicker>
                    <mat-error *ngIf="
                  maoreInfoForm?.get('txtStartdate')?.touched && maoreInfoForm?.get('txtStartdate')?.errors
                  ">
                        Start Date is Not Vaild!
                    </mat-error>

                    <mat-error *ngIf="
                    maoreInfoForm?.get('txtStartdate')?.touched &&
                    maoreInfoForm?.get('txtStartdate')?.errors?.required &&
                    !maoreInfoForm?.get('txtStartdate')?.hasError('matDatepickerParse')
                ">
                        Date is a required field!
                    </mat-error>
                </mat-form-field>
                <!-- End Date Field -->
                <mat-form-field class="col-4">
                    <mat-label>End Date <span class="asterisk">*</span></mat-label>
                    <input autocomplete="new-txtEnddate" matInput formControlName="txtEnddate"
                        placeholder="(MM/DD/YYYY)" [formControl]="maoreInfoForm.controls.txtEnddate"
                        [min]="maoreInfoForm.controls.txtStartdate.value" [matDatepicker]="myPicker1">
                    <mat-datepicker-toggle matSuffix [for]="myPicker1">
                    </mat-datepicker-toggle>
                    <mat-datepicker #myPicker1></mat-datepicker>
                    <mat-error *ngIf="
                  maoreInfoForm?.get('txtEnddate')?.touched && maoreInfoForm?.get('txtEnddate')?.errors
                  ">
                        End Should be greater than the Start Date!
                    </mat-error>

                    <mat-error *ngIf="
                    maoreInfoForm?.get('txtEnddate')?.touched &&
                    maoreInfoForm?.get('txtEnddate')?.errors?.required &&
                    !maoreInfoForm?.get('txtEnddate')?.hasError('matDatepickerParse')
                ">
                        Date is a required field!
                    </mat-error>
                </mat-form-field>

                <!-- Start Date Field -->
                <!-- <mat-form-field class="col-4">
                    <mat-label>Start Date</mat-label>
                    <input autocomplete="new-txtStartdate" formControlName="txtStartdate" matInput maxlength="40"
                        type="text" placeholder="(MM/DD/YYYY)" [matDatepicker]="txtStartdate" />
                    <mat-datepicker-toggle matSuffix [for]="txtStartdate"></mat-datepicker-toggle>
                    <mat-datepicker #txtStartdate></mat-datepicker>
                </mat-form-field> -->

                <!-- End Date Field -->
                <!-- <mat-form-field class="col-4">
                    <mat-label>End Date</mat-label>
                    <input autocomplete="new-txtEnddate" formControlName="txtEnddate" matInput maxlength="40"
                        type="text" placeholder="(MM/DD/YYYY)" [matDatepicker]="txtEnddate" />
                    <mat-datepicker-toggle matSuffix [for]="txtEnddate"></mat-datepicker-toggle>
                    <mat-datepicker #txtEnddate></mat-datepicker>
                </mat-form-field> -->
            </div>
            <!-- </div> -->
        </form>
        <div class="modal-footer mt-3">
            <div class="col-12 text-center mt-3">
                <button mat-button class="buttonColor mr-2"
                    [disabled]="isShowSpinner || !(!maoreInfoForm?.invalid && maoreInfoForm?.dirty)"
                    (click)="saveMoreInfo()">
                    <span>{{ sessionPracticeId ? 'Update' : 'Save' }}</span>
                    <mat-icon *ngIf="isShowSpinner">
                        <mat-spinner class="spinner-border spinner-border-sm" diameter="20"></mat-spinner>
                    </mat-icon>
                </button>
                <button mat-button class="resetclr mr-2" (click)="resetMoreInfo()">Reset</button>
            </div>
        </div>
    </div>