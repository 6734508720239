<!-- Last Changed By Manikandan 23 April 2024 14.53PM -->
<div class="container-fluid">

    <div class="row bg">
        <div class="col-12">
            <div class="d-flex align-items-center justify-content-between">
                <h3 class="font-weight-bolder mb-2"><b class="header-class">List / Claim Dept / Auto Posting (ERA)</b></h3>
                <div class="page-title-box page-title-right">
                    <!-- <ol class="breadcrumbs m-0 align-items-center"> -->
                        <form [formGroup]="autoPostingERAForm">
                            <div class="row d-flex justify-content-center align-items-center pt-2">
                                <ng-container *ngIf="!filterValue">
                            <mat-form-field class="pr-3 dd-text">
                                <mat-label>Select Date Range<i class="fa fa-calendar ml-2" style="color:rgb(0, 0, 0)" matSuffix align="end"></i></mat-label>

                                <input autocomplete="new-txtDateValue" matInput  type="text" name="daterange" ngxDaterangepickerMd
                                [showCustomRangeLabel]="true" [alwaysShowCalendars]="true" [(ngModel)]="selectedRange"
                                  [linkedCalendars]="false" [ranges]="ranges" [showClearButton]="false" [locale]="calendarLocale"
                                   [keepCalendarOpeningWithRange]="true"  formControlName="txtDateValue"
                                   (datesUpdated)="datesUpdateds($event)" [autoApply]="true" [readonly]="true" (keyup)="datesUpdateds($event)"
                                  />
                              </mat-form-field>
                              </ng-container>
                              <ng-container *ngIf="!filterValue">
                            <mat-form-field class="pr-3 dd-text">
                                <mat-label>Status</mat-label>
                                <mat-select formControlName="drpStatus" (selectionChange)="onStatusChange()">
                                    <mat-option>
                                        <ngx-mat-select-search placeholderLabel="Search"
                                            noEntriesFoundLabel="No Matches found"
                                            formControlName="txtStatusFilter" [formControl]="txtStatusControl"></ngx-mat-select-search>
                                    </mat-option>
                                    <mat-option *ngFor="let users of filteredStatusList " [value]="users.id">
                                        {{ users.claimStatus }}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                            </ng-container>
                            <ng-container *ngIf="filterValue">

                                <mat-form-field  class="pr-3 dd-text">
                                  <mat-label>Check No</mat-label>
                                  <input autocomplete="new-txtTicketNo" matInput formControlName="txtTicketNo" maxlength="10"
                                  (input)="sanitizeInput($event, 'txtTicketNo');handleInputChange($event)"  />
                                </mat-form-field>
                            </ng-container>
                            <div class="pr-3 dd-text ">
                                <app-auto-posting-filter-switch class="h-100" [onText]="'Check'" [offText]="'Date'"
                                  (changed)="filterChange($event)">
                                </app-auto-posting-filter-switch>
                              </div>

                            <button matTooltip="Click To Upload EOB" mat-button
                                class="buttonColor font-size-10 mr-2" (click)="openEOBModel()">
                                Upload EOB
                            </button>
                            <img matTooltip="Check Color Code" (click)="OpenColorDiv()"
                                class="pointer img-responsive mb-1 mr-1" height="33"
                                src="assets/Claim Page Icons/color_drop.png" />

                            </div>
                        </form>
                    <!-- </ol> -->
                </div>
            </div>
        </div>
    </div>

    <div class="row m-1">
        <div class="" [ngClass]="iscolorCode === true ? 'col-11_5' : 'col-12'">
            <app-auto-posting-eratable [DataList]="DataList" *ngIf="!isLoading" (isLoadTbl)="loadAutoPostingList()"></app-auto-posting-eratable>
        </div>
        <div *ngIf="iscolorCode" class="mt-2 " [ngClass]="iscolorCode === true ? 'col-0_5' : ''">
            <div class="border d-flex align-items-center justify-content-center">
                <div class="p-1">
                    <div [ngbTooltip]="'Auto Posting'" class="circle red mb-2"></div>
                    <div [ngbTooltip]="'Manual Posting'" class="circle white  mb-2"></div>
                    <div [ngbTooltip]="'Claim Submitted'" class="circle processed mb-2"></div>
                    <div [ngbTooltip]="'Submitted to Secondary'" class="circle crossed-over mb-2"></div>
                    <div [ngbTooltip]="'Patient Responsibility'" class="circle auth-no mb-2"></div>
                    <div [ngbTooltip]="'Claim Denial'" class="circle days mb-2"></div>
                </div>
            </div>
        </div>
    </div>

    <div class="row" *ngIf="isLoading">
        <div class="col-12">
            <div class="d-flex justify-content-center">
                <img class="img-responsive pb-2 mr-1" src="assets/gifs/Infinity-loader.gif" />
            </div>
        </div>
    </div>
</div>
