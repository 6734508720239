import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { DataTableDirective } from 'angular-datatables';
import { MmOrderService } from '../../order-proxy/order-management/order-optimization/mm-order.service';
import { ToastrService } from 'ngx-toastr';
import { CommunicationService } from 'projects/shared/src/app/services/communication.service';
import { HttpErrorResponse } from '@angular/common/http';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { defaultGuid } from 'projects/shared/src/app/enums/allenums.enum';
import Swal from 'sweetalert2';
import { AddNoteTabComponent } from 'projects/patient/src/app/add-note-tab/add-note-tab.component';
import { Subscription } from 'rxjs';
import { DatePipe } from '@angular/common';
import { NotesReasonComponent } from 'projects/inventory/src/app/notes-reason/notes-reason.component';
import { NotesType } from '../../order-proxy/order-management/inventory/dto/models';

@Component({
  selector: 'app-override-list-table',
  templateUrl: './override-list-table.component.html',
  styleUrls: ['./override-list-table.component.scss'],
})
export class OverrideListTableComponent implements OnInit {
  @Input() ltOverrideOrders: any;
  dttblOptions: any = {
    responsive: true,
    paging: false,
    dom: 'Bfrtip',
    scrollCollapse: true,
    scrollY: '500px',
    scrollX: true,
    search: {
      smart: false,
    },
    order: [],
    // info: false,
    language: {
      emptyTable: 'No records',
      info: 'Total : _MAX_ records',
      infoEmpty: 'Total : _MAX_ records',
      infoFiltered: '(filtered : _TOTAL_ records)',
      loadingRecords: 'Loading...',
      zeroRecords: 'No matching records',
    },
    buttons: [
      {
        extend: 'excel',
        text: 'Export', // Change the text to 'Export'
        filename: 'Override Orders List',
        exportOptions: {
          // columns: [2, 3, 4, 5, 6],
          columns: ':visible:not(:nth-child(1),:nth-child(2))',
        },
      },
      {
        extend: 'colvis',
        columns: ':not(:eq(0), :eq(1), :eq(2),:eq(3), :eq(-1))', // Exclude first three and last columns from the column visibility toggle
      },
    ],
    columnDefs: [
      { targets: [0, 1, 2, 3, -1], className: 'dt-fixed-column' }, // Add other columnDefs as needed
    ],
    // columnDefs: [
    //   { targets: 0, orderable: false }, // Targets the first column (index 0)
    //   // Add other columnDefs as needed
    // ],
  };
  @ViewChild(DataTableDirective, { static: false })
  datatableElement: DataTableDirective;
  myTable: DataTables.Api;
  subscription$: Subscription[] = [];

  constructor(
    private mmOrderService: MmOrderService,
    private toaster: ToastrService,
    private communicationService: CommunicationService,
    private dialog: MatDialog,
    private datePipe : DatePipe
  ) {
    const updateStatus =
      this.communicationService.functionUpdateOverrideStatus$.subscribe(
        (orderDetails) => {
          this.approveDisapproveApiCall(
            orderDetails.orderId,
            orderDetails.status,
            orderDetails.comments
          );
        }
      );
    this.subscription$.push(updateStatus);
  }

  ngOnInit() {
    this.dttblOptions.buttons[0].filename =
    'Override Orders List' +  '-' + this.datePipe.transform(new Date(),'MM-dd-yyyy') ;
    this.calculateScrollY();
    window.addEventListener('resize', this.calculateScrollY);
  }
  ngOnDestroy(): void {
    window.removeEventListener('resize', this.calculateScrollY);
    this.subscription$.forEach((sub) => {
      sub && sub?.unsubscribe();
    });
  }
  ngAfterViewInit(): void {
    this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.columns().every(function () {
        const that = this;
        $('input', this.footer()).on('keyup change', function () {
          if (that.search() !== this['value']) {
            that.search(this['value']).draw();
          }
        });
      });
    });
  }

  isEmpty(value: any): boolean {
    return (
      value === null ||
      value === undefined ||
      value === '' ||
      value === defaultGuid
    );
  }
  //Row Click Event UnProcessed Table
  handleTableCellClick(e: Event, notesData: any): void {
    const target = e.target as HTMLElement;
    const tr = target.closest('tr');
    this.getDataTableData(tr, notesData);
  }

  //DataTable Data Get Method and Toggle Notes Row in UnProcessed Table
  async getDataTableData(tr: any, notesData: any): Promise<void> {
    this.myTable = await this.datatableElement.dtInstance;

    if (tr) {
      const row = this.myTable.row(tr);

      if (row.child.isShown()) {
        // This row is already open - close it
        row.child.hide();
      } else {
        // Open this row
        row.child(this.format(row.data(), notesData)).show();
      }
    }
  }

  //Add Dynamic Notes Row in UnProcessed Table
  format(d: any, notesData: any) {
    let returnString: string = '';

    returnString +=
      '<tr class="border-bottom" style="white-space: pre-wrap !important;"><td>' +
      notesData +
      '</td></tr>';

    return returnString;
  }

  //Approve Disapprove Method
  approveDisapproveOverride(orderId: string, ticketId: string, status: boolean) {
    status && this.approveDisapproveApiCall(orderId, status, '');
    // !status && this.openNotes(orderId, ticketId);
    !status && this.notesReason(orderId, ticketId);
  }

  //To Approve & Disapprove API Call
  approveDisapproveApiCall(orderId: string, status: boolean, comments: string) {
    this.mmOrderService
      .updateOverrideStatusV1ByGOrderIdAndStatusAndSComments(
        orderId,
        status,
        comments
      )
      .subscribe(
        (response) => {
          status
            ? this.toaster.success('Override Approved!')
            : this.toaster.success('Disapproved!');

          this.communicationService.triggerOverriddenOrdersList();
        },
        (err) => {
          const data: HttpErrorResponse = err;
          this.toaster.error(data?.error?.error?.message);
        }
      );
  }

  notesReason(orderId: string, ticketId: string) {
    const dialogRef = this.dialog.open(NotesReasonComponent, {
      minHeight: '33vh',
      minWidth: '52vw',
      disableClose: true,
      data: {
        patientId: defaultGuid , // this.shippingListDetails.patientId,
        ticketId: ticketId, //  this.shippingListDetails.mmRefereceOrderId,
        notesType: NotesType.orderRelated,
        orderId: orderId,
        type: NotesType.orderRelated,
      },
    });
    dialogRef.afterClosed().subscribe((a) => {
    });
  }


  openNotes(orderId: string, chartNo: string) {
    const config: MatDialogConfig = {
      disableClose: true,
      minHeight: '30vh',
      minWidth: '50vw',
      data: {
        // claimId: '00000000-0000-0000-0000-000000000000',
        noteType: 'patient', // 'Order Note',
        notesId: '00000000-0000-0000-0000-000000000000',
        orderId: orderId,
        patientId: defaultGuid,
        defaultFaxId: defaultGuid,
        isCallNote: false,
        isPending: false,
        docId: defaultGuid,
        inboundStatusShortCodeId: defaultGuid,
        chartNo: chartNo,
        addnotes: false,
        isOverride: true,
      },
    };
    const dialogRef = this.dialog.open(AddNoteTabComponent, config);

    const closeDialog = dialogRef.afterClosed().subscribe(
      () => {
        // this.getReminderTableData();
      },
      (err) => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      }
    );
    this.subscription$.push(closeDialog);
  }

  calculateScrollY = () => {
    const screenHeight =
      window.innerHeight ||
      document.documentElement.clientHeight ||
      document.body.clientHeight;
    const availableSpacePercentage = 0.5; // Adjust the percentage as needed
    this.dttblOptions.scrollY = screenHeight * availableSpacePercentage;
  };
}
