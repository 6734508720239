<div class="row header">
  <div class="col-7 d-flex justify-content-start align-items-center">
    <h4 >
      <b class=" mainHead" [hidden]="isMstVerification">{{ policyModalTitle }}</b>
    </h4>
    <h2 class="heading-style" style="padding-left: 36px;">Verification Status :
      <span class="text-red" *ngIf="(lastVerificationData?.verificationStatus===lstVerificationSttaus.done)||(lastVerificationData?.verificationStatus===lstVerificationSttausV1.done)">Done</span>
      <span class="text-red" *ngIf="(lastVerificationData?.verificationStatus===lstVerificationSttaus.pending)||(lastVerificationData?.verificationStatus===lstVerificationSttausV1.pending)">Pending</span>
      <span class="text-red" *ngIf="(lastVerificationData?.verificationStatus===lstVerificationSttaus.unabletoverify)||(lastVerificationData?.verificationStatus===lstVerificationSttausV1.unabletoverify)">Unable To Verify</span>
      <span class="text-red" *ngIf="(lastVerificationData?.verificationStatus===lstVerificationSttaus.new)||(lastVerificationData?.verificationStatus===lstVerificationSttausV1.new)">New</span>
      <span class="text-red" *ngIf="(lastVerificationData?.verificationStatus!==lstVerificationSttaus.new)&&
      (lastVerificationData?.verificationStatus!==lstVerificationSttausV1.new) &&
      (lastVerificationData?.verificationStatus!==lstVerificationSttaus.unabletoverify)&&
      (lastVerificationData?.verificationStatus!==lstVerificationSttausV1.unabletoverify)&&
      (lastVerificationData?.verificationStatus!==lstVerificationSttaus.pending)&&
      (lastVerificationData?.verificationStatus!==lstVerificationSttausV1.pending)&&
      (lastVerificationData?.verificationStatus!==lstVerificationSttaus.done)&&
      (lastVerificationData?.verificationStatus!==lstVerificationSttausV1.done)"
      >{{lastVerificationData?.verificationStatus}}</span>
    </h2>
  </div>

<!-- <div class="col-5 d-flex justify-content-start align-items-center">
  <b><h2 class="heading-style">Verification Status :
    <span class="text-red" *ngIf="(lastVerificationData?.verificationStatus===lstVerificationSttaus.done)||(lastVerificationData?.verificationStatus===lstVerificationSttausV1.done)">Done</span>
    <span class="text-red" *ngIf="(lastVerificationData?.verificationStatus===lstVerificationSttaus.pending)||(lastVerificationData?.verificationStatus===lstVerificationSttausV1.pending)">Pending</span>
    <span class="text-red" *ngIf="(lastVerificationData?.verificationStatus===lstVerificationSttaus.unabletoverify)||(lastVerificationData?.verificationStatus===lstVerificationSttausV1.unabletoverify)">Unable To Verify</span>
    <span class="text-red" *ngIf="(lastVerificationData?.verificationStatus===lstVerificationSttaus.new)||(lastVerificationData?.verificationStatus===lstVerificationSttausV1.new)">New</span>
    <span class="text-red" *ngIf="(lastVerificationData?.verificationStatus!==lstVerificationSttaus.new)&&
    (lastVerificationData?.verificationStatus!==lstVerificationSttausV1.new) &&
    (lastVerificationData?.verificationStatus!==lstVerificationSttaus.unabletoverify)&&
    (lastVerificationData?.verificationStatus!==lstVerificationSttausV1.unabletoverify)&&
    (lastVerificationData?.verificationStatus!==lstVerificationSttaus.pending)&&
    (lastVerificationData?.verificationStatus!==lstVerificationSttausV1.pending)&&
    (lastVerificationData?.verificationStatus!==lstVerificationSttaus.done)&&
    (lastVerificationData?.verificationStatus!==lstVerificationSttausV1.done)"
    >{{lastVerificationData?.verificationStatus}}</span>
  </h2></b>
</div> -->
  <div class="col-5 d-flex justify-content-end align-items-end" style="padding-right: 23px;">
    <button mat-button [disabled]="isEligibility != true || isFormChanged" (click)="openPverifyModal()"
      class="lastSpace buttonColor waves-effect font-size-12 text-right btn-sm">
      <a   [class.disabled]="isEligibility != true || isFormChanged" >
      <img
         class="img-responsive pr-1 mb-1 cursor"
         src="assets/images/NewSetup/Vector.png"
         alt=""
         height="17"
       />
      </a>
      Electronic Eligibility Check
    </button> &nbsp;
    <button *ngIf="isShownButton" [disabled]="isEligibility != true || isFormChanged" mat-button (click)="openVerificationModal()"
      class="algin buttonColor waves-effect font-size-12 text-right btn-sm">
      <a   [class.disabled]="isEligibility != true || isFormChanged" >
      <img
         class="img-responsive pr-1 mb-1 cursor"
         src="assets/images/NewSetup/Group 3530.png"
         alt=""
         height="17"
       />
      </a>
      Manual Eligibility Check
    </button>
  </div>
</div>
<mat-dialog-content class="mat-typography" style="overflow-x: hidden">
<form [formGroup]="policyForm">
  <div class="card card-body mt-2">
  <div class="col-12">
      <div class="row">
        <div class="col-4">
          <h4 class="font-size-18">
            <b class="header-text" >Payor Details</b>
          </h4>
        </div>
        <div class="col-8 d-flex justify-content-end align-items-end">
          <div class="col-3">
            <b><h2 class="heading-style">Tax ID : <span class="text-red">{{ mmTaxId ? mmTaxId : ' - ' }}</span></h2></b>
          </div>
          <div class="col-3">
            <b><h2 class="heading-style">NPI ID : <span class="text-red">{{ npi ? npi : ' - ' }}</span></h2></b>
          </div>
        </div>
      </div>
      <div class="row">
        <mat-form-field class="col-lg-2">
          <mat-label>Payor Level<span class="asterisk">*</span></mat-label>
          <mat-select formControlName="drpInsuranceType" [(ngModel)]="payorlevel">
            <mat-option>
              <ngx-mat-select-search [formControl]="PayorlevelControl" placeholderLabel="Search"
                  noEntriesFoundLabel="No Matches found"
                  formControlName="txtPayorLevel"></ngx-mat-select-search>
                </mat-option>
                  <mat-option *ngFor="let payor of payorLevel"
                  [disabled]="((payor.payorLevelName).toLowerCase() !== 'primary'&& data.isDashboard===true)"
                  [value]="payor.payorLevelName">
                    {{ payor.payorLevelName }}
                  </mat-option>
                </mat-select>
                <button mat-button (click)="
                payorlevel = '';
              $event.stopPropagation()
            " *ngIf="policyForm.value.drpInsuranceType" matSuffix mat-icon-button>
            <mat-icon>close</mat-icon>
          </button>
          <mat-error *ngIf="
              policyForm?.get('drpInsuranceType')?.touched &&
              policyForm?.get('drpInsuranceType')?.errors?.required
            ">
            Payor Level is a required field!
          </mat-error>
        </mat-form-field>
        <mat-form-field class="col-lg-2">
          <mat-label>Select Eligibility Mode<span class="asterisk">*</span></mat-label>
          <mat-select (selectionChange)="onSelect($event)"
            formControlName="selectEligibility">
            <mat-option>
              <ngx-mat-select-search [formControl]="PayorModeControl" placeholderLabel="Search" noEntriesFoundLabel="No Matches found"
                formControlName="txtPverifyFilter"></ngx-mat-select-search>
            </mat-option>
            <mat-option *ngFor="let payor of dataPayorMode" [value]="payor.payorID">
              {{ payor.payorName }}
            </mat-option>
            <!-- <mat-option [value]="1">
              pVerify
            </mat-option>
            <mat-option [value]="2">
              Zermid
            </mat-option>
            <mat-option [value]="3">
              ChangeHealthCare
            </mat-option> -->
          </mat-select>
          <mat-error *ngIf="
              policyForm?.get('selectEligibility')?.touched &&
              policyForm?.get('selectEligibility')?.errors?.required
            ">
            Insurance is a required field!
          </mat-error>
          <button mat-button (click)="pVerifyPayor = '';
           $event.stopPropagation()" *ngIf="policyForm?.get('selectEligibility')?.value" matSuffix mat-icon-button>
            <mat-icon>close</mat-icon>
          </button>
        </mat-form-field>
       <!-- Select Payor -->
       <mat-form-field ld class="col-lg-5" [class.disabled]="isPayorLoading">

        <mat-label>
          <div class="select-placeholder-container" *ngIf="isPayorLoading">
            <span>Loading...</span>
            <mat-spinner class="spinner" diameter="20"></mat-spinner>
          </div>
          <span *ngIf="!isPayorLoading">Select Payor <span class="asterisk">*</span></span>
      </mat-label>

        <!-- <mat-label>Select Payor<span class="asterisk">*</span></mat-label> -->
        <mat-select formControlName="drpselectPayor" [(ngModel)]="selectPayor"
          (selectionChange)="onSelectPayor($event)" >
          <mat-option>
            <ngx-mat-select-search placeholderLabel="Payer Id / Name / Code" noEntriesFoundLabel="No Matches found"
              formControlName="selectPayor"
              (keyup)="payerSearch($event.target.value,'')"></ngx-mat-select-search>
          </mat-option>
          <mat-option *ngFor="let payor of filteredSelectPayers | async"
            [value]="payor?.payerId">
            {{ payor?.payerShortCodeList }}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="
            policyForm?.get('drpselectPayor')?.touched &&
            policyForm?.get('drpselectPayor')?.errors?.required
          ">
          Payer Id is a required field!
        </mat-error>
        <button mat-button (click)="zirmedPayor = '';
         $event.stopPropagation()" *ngIf="zirmedPayor" matSuffix mat-icon-button>
          <mat-icon>close</mat-icon>
        </button>
        <mat-hint class="text-danger fw-bolder " *ngIf="!isUniq"> Insurance Category Not Available!</mat-hint>
      </mat-form-field>
      <!-- Policy No -->
      <mat-form-field class="col-lg-3">
        <mat-label>Policy No<span class="asterisk">*</span></mat-label>
        <input autocomplete="new-txtPolicyNo" maxlength="20" formControlName="txtPolicyNo" matInput type="text"
        pattern="[a-zA-Z0-9]*" />
        <mat-error *ngIf="
            policyForm?.get('txtPolicyNo')?.touched &&
            policyForm?.get('txtPolicyNo')?.errors?.pattern
          ">
          Special Characters not allowed!
        </mat-error>
        <mat-error *ngIf="
            policyForm?.get('txtPolicyNo')?.touched &&
            policyForm?.get('txtPolicyNo')?.errors?.required
          ">
          Policy No is a required field!
        </mat-error>
      </mat-form-field>
      <!-- Group No -->
      <mat-form-field class="col-lg-3">
        <mat-label>Group No</mat-label>
        <input autocomplete="new-txtGroupNo" maxlength="20
        " formControlName="txtGroupNo" matInput type="text" />
        <mat-error *ngIf="
            policyForm?.get('txtGroupNo')?.touched &&
            policyForm?.get('txtGroupNo')?.errors?.pattern
          ">
          Special Characters not allowed!
        </mat-error>
        <!-- pattern="[a-zA-Z0-9]*" <mat-error *ngIf="
            policyForm?.get('txtGroupNo')?.touched &&
            policyForm?.get('txtGroupNo')?.errors?.required
          ">
          Group No is a required field!
        </mat-error> -->
      </mat-form-field>
      <mat-form-field class="col-xl-9">
        <mat-label>Note</mat-label>
        <input autocomplete="new-txtNote" maxlength="1000" formControlName="txtNote" matInput type="text" />
      </mat-form-field>
    </div>

  </div>
  </div>

  <div class="card card-body mt-2">
  <div class="col-12">
      <div class="row">
        <div class="col-4">
          <h4 class="font-size-18">
            <b class="header-text" >Insured Details</b>
          </h4>
        </div>
      </div>
      <div class="row">
        <mat-form-field class="col-2">
          <mat-label>First Name <span class="asterisk">*</span></mat-label>
          <input autocomplete="new-txtInsuredFirst" formControlName="txtInsuredFirst" matInput type="text" pattern="^[^0-9]*$"
            maxlength="40" />
          <mat-error *ngIf="
              policyForm?.get('txtInsuredFirst')?.touched &&
              policyForm?.get('txtInsuredFirst')?.errors?.pattern
            ">
            Numbers not allowed!
          </mat-error>
          <mat-error *ngIf="
              policyForm?.get('txtInsuredFirst')?.touched &&
              policyForm?.get('txtInsuredFirst')?.errors?.required
            ">
            First Name is a required field!
          </mat-error>
        </mat-form-field>
        <mat-form-field class="col-2">
          <mat-label>Middle Name</mat-label>
          <input autocomplete="new-txtInsuredMiddle" formControlName="txtInsuredMiddle" matInput type="text" pattern="^[^0-9]*$"
            maxlength="40" />
          <mat-error *ngIf="
              policyForm?.get('txtInsuredMiddle')?.touched &&
              policyForm?.get('txtInsuredMiddle')?.errors?.pattern
            ">
            Numbers not allowed!
          </mat-error>
        </mat-form-field>
        <mat-form-field class="col-2">
          <mat-label>Last Name <span class="asterisk">*</span></mat-label>
          <input autocomplete="new-txtInsuredLastName" formControlName="txtInsuredLastName" matInput type="text" pattern="^[^0-9]*$"
            maxlength="40" />
          <mat-error *ngIf="
              policyForm?.get('txtInsuredLastName')?.touched &&
              policyForm?.get('txtInsuredLastName')?.errors?.pattern
            ">
            Numbers not allowed!
          </mat-error>
          <mat-error *ngIf="
              policyForm?.get('txtInsuredLastName')?.touched &&
              policyForm?.get('txtInsuredLastName')?.errors?.required
            ">
            Last Name is a required field!
          </mat-error>
        </mat-form-field>
        <mat-form-field class="col-2">
          <mat-label>Birth Date</mat-label>
          <input autocomplete="new-txtInsuredBirthDate" [min]="BirthdateMinDate"
            formControlName="txtInsuredBirthDate" matInput [matDatepicker]="myPicker3" placeholder="MM/DD/YYYY"
            maxlength="10" onkeydown="this.value=this.value.replace(/^(\d\d)(\d)$/g,'$1/$2').replace(/^(\d\d\/\d\d)(\d+)$/g,'$1/$2').replace(/[^\d\/]/g,'')"
            />
          <mat-datepicker-toggle matSuffix [for]="myPicker3">
          </mat-datepicker-toggle>
          <mat-datepicker #myPicker3></mat-datepicker>
          <mat-error *ngIf="
              policyForm?.get('txtInsuredBirthDate')?.touched &&
              policyForm?.get('txtInsuredBirthDate').errors
            ">
            Enter Valid Date!
          </mat-error>
          <mat-error *ngIf="
              policyForm?.get('txtInsuredBirthDate')?.touched &&
              policyForm?.get('txtInsuredBirthDate')?.errors?.required
            ">
            Birth Date is a required field!
          </mat-error>
        </mat-form-field>
        <mat-form-field class="col-2">
          <mat-label>Gender </mat-label>
          <mat-select formControlName="drpGender" [(ngModel)]="gender">
            <mat-option>
              <ngx-mat-select-search [formControl]="GenderControl" placeholderLabel="Search"
                  noEntriesFoundLabel="No Matches found"
                  formControlName="txtGender"></ngx-mat-select-search>
          </mat-option>
            <mat-option *ngFor="let gender of drpGenderLoop" [value]="gender.genderShortCode">
              {{ gender.genderName }}
            </mat-option>
          </mat-select>
          <button mat-button (click)="gender = ''; $event.stopPropagation()" *ngIf="gender" matSuffix
            mat-icon-button>
            <mat-icon>close</mat-icon>
          </button>
          <mat-error *ngIf="
              policyForm?.get('drpGender')?.touched &&
              policyForm?.get('drpGender')?.errors?.required
            ">
            Gender is a required field!
          </mat-error>
        </mat-form-field>
        <mat-form-field class="col-2">
          <mat-label> Relationship<span class="asterisk">*</span> </mat-label>
          <mat-select formControlName="drpRelationship" autocomplete="off" maxlength="40">
            <mat-option>
              <ngx-mat-select-search [formControl]="RelationControl" placeholderLabel="Search"
                  noEntriesFoundLabel="No Matches found"
                  formControlName="txtRelation"></ngx-mat-select-search>
          </mat-option>
            <mat-option *ngFor="let emergency of drpRelationShip" [value]="emergency.relationTypeShortCodeId">
              {{ emergency.relationType }}
            </mat-option>
          </mat-select>
          <button (click)="clearRelationship();$event?.stopPropagation()" mat-button matSuffix mat-icon-button>
            <mat-icon>close</mat-icon>
          </button>
          <mat-error *ngIf="
          policyForm?.get('drpRelationship')?.touched &&
          policyForm?.get('drpRelationship')?.errors
                ?.required
            ">
            Relationship is a required field!
          </mat-error>
        </mat-form-field>
      </div>
      <div class="row">
        <mat-form-field class="col-4">
          <mat-label>Address </mat-label>
          <textarea autocomplete="new-txtAddress" formControlName="txtAddress" rows="3" matInput
            maxlength="400"></textarea>
        </mat-form-field>
        <mat-form-field class="col-2">
          <mat-label>Country</mat-label>
          <mat-select formControlName="txtInsuredCountry" [(ngModel)]="insuredCountry"
            (selectionChange)="onChangeCountry($event.value);changePhoneCode($event.value)" #singleSelect>
            <mat-option>
              <ngx-mat-select-search placeholderLabel="Search" noEntriesFoundLabel="No Matches found"
                formControlName="txtInsuredCountryFilter"></ngx-mat-select-search>
            </mat-option>
            <mat-option *ngFor="let country of filteredInsuredCountries | async" [value]="country.countryShortName">
              {{ country.countryName }}
            </mat-option>
          </mat-select>
          <button mat-button *ngIf="insuredCountry" (click)="insuredCity = null; insuredState = null;
            insuredCountry = null;  $event?.stopPropagation()" matSuffix mat-icon-button>
            <mat-icon>close</mat-icon>
          </button>

          <mat-error *ngIf="
              policyForm?.get('txtInsuredCountry')?.touched &&
              policyForm?.get('txtInsuredCountry')?.errors?.required
            ">
            Country is a required field!
          </mat-error>
        </mat-form-field>
        <mat-form-field class="col-2">
          <mat-label>State</mat-label>
          <mat-select formControlName="txtInsuredState" [(ngModel)]="insuredState"
            (selectionChange)="onChangeState($event.value, '')" #singleSelect>
            <mat-option>
              <ngx-mat-select-search placeholderLabel="Search" noEntriesFoundLabel="No Matches found"
                formControlName="txtInsuredStateFilter"></ngx-mat-select-search>
            </mat-option>
            <mat-option *ngFor="let state of filteredInsuredStates | async" [value]="state.stateShortName">
              {{ state.stateName }}
            </mat-option>
          </mat-select>
          <button mat-button *ngIf="insuredState"
            (click)="insuredState = null; insuredCity = null; $event?.stopPropagation()" matSuffix mat-icon-button>
            <mat-icon>close</mat-icon>
          </button>
          <mat-error *ngIf="
              policyForm?.get('txtInsuredState')?.touched &&
              policyForm?.get('txtInsuredState')?.errors?.required
            ">
            State is a required field!
          </mat-error>
        </mat-form-field>
        <mat-form-field class="col-2">
          <mat-label>City</mat-label>
          <mat-select formControlName="txtInsuredCity" [(ngModel)]="insuredCity" #singleSelect>
            <mat-option>
              <ngx-mat-select-search placeholderLabel="Search" noEntriesFoundLabel="No Matches found"
                formControlName="txtInsuredCityFilter"></ngx-mat-select-search>
            </mat-option>
            <mat-option *ngFor="let city of filteredInsuredCities | async" [value]="city.cityName">
              {{ city.cityName }}
            </mat-option>
          </mat-select>
          <button mat-button *ngIf="insuredCity" matSuffix (click)="insuredCity = null ;$event?.stopPropagation()"
            mat-icon-button>
            <mat-icon>close</mat-icon>
          </button>
          <mat-error *ngIf="
              policyForm?.get('txtInsuredCity')?.touched &&
              policyForm?.get('txtInsuredCity')?.errors?.required
            ">
            City is a required field!
          </mat-error>
        </mat-form-field>
        <mat-form-field class="col-2">
          <mat-label> Phone</mat-label>
          <input autocomplete="new-txtInsuredPhone" formControlName="txtInsuredPhone" matInput maxlength="40"
            prefix="{{ phoneCode }}-" mask="(000)-000-0000" type="text" />
          <mat-error *ngIf="
              policyForm?.get('txtInsuredPhone')?.touched &&
              policyForm?.get('txtInsuredPhone')?.errors
            ">
            Enter Minimum 10 Digits!
          </mat-error>
        </mat-form-field>
      </div>
      <div class="row">
        <mat-form-field class="col-2">
          <mat-label>Zip Code</mat-label>
          <input autocomplete="new-txtInsuredPostalCode" formControlName="txtInsuredPostalCode" matInput type="text"  pattern="[0-9-]*"
            maxlength="10" class="zip-code-input" />

            <mat-error *ngIf=" policyForm?.get('txtInsuredPostalCode')?.touched &&
            policyForm?.get('txtInsuredPostalCode')?.errors?.pattern">
                Enter only Numbers!
            </mat-error>

          <mat-error *ngIf="
              policyForm?.get('txtInsuredPostalCode')?.touched &&
              policyForm?.get('txtInsuredPostalCode')?.errors?.required
            ">
            Zip Code is a required field!
          </mat-error>
        </mat-form-field>

        <mat-form-field class="col-2">
          <mat-label>Employer Id</mat-label>
          <input autocomplete="new-txtPolicyInfoEmployerdId" formControlName="txtPolicyInfoEmployerdId" matInput
            type="text" maxlength="10" />
          <mat-error *ngIf="
              policyForm?.get('txtPolicyInfoEmployerdId')?.touched &&
              policyForm?.get('txtPolicyInfoEmployerdId')?.errors?.number
            ">
            Enter Number Only!
          </mat-error>
        </mat-form-field>
      </div>
  </div>
  </div>
</form>

<div class="card card-body mt-2">
  <div class="row"style="padding-left: 15px;">
    <div class="col-4">
      <h4 class="font-size-18">
        <b class="header-text" >Last Eligibility Check</b>
      </h4>
    </div>
  </div>
  <div class="row" style="padding-left: 15px;">
    <div class="col-2 ">
      <b>Last Checked On</b>
      <h1>{{ (lastVerificationData?.dateVerified | date: 'MM/dd/yyyy hh:mm a':'en_US') }}</h1>
    </div>
    <div class="col-2 vl">
      <b>Last Checked By</b>
      <h1> {{ lastVerificationData?.verifiedBy!==null?lastVerificationData?.verifiedBy:'-'}}</h1>
    </div>
    <div class="col-2 vl">
      <b>Verified Method</b>
      <h1>{{((lastVerificationData?.verificationStatus===lstVerificationSttaus.new)||(lastVerificationData?.verificationStatus===lstVerificationSttausV1.new))?'-': lastVerificationData?.verificationMethod }}</h1>
    </div>
    <div class="col-2 vl">
      <b>Verified Type</b>
      <h1>{{ lastVerificationData?.verificationType!==null?lastVerificationData?.verificationType:'-' }}</h1>
    </div>
    <div class="col-2 vl">
      <b>Added By</b>
      <h1>{{ lastVerificationData?.addedBy!==null?lastVerificationData?.addedBy:'-'  }}</h1>
    </div>
    <div class="col-2 vl">
      <b>Updated By</b>
      <h1>{{ lastVerificationData?.updatedBy!==null? lastVerificationData?.updatedBy:'-'}}</h1>
    </div>
  </div>
</div>
</mat-dialog-content>
<div class="row header mt-2">
  <div class="col-6 d-flex align-items-center pt-1" >
<button  *ngIf="isShownEligibilityButtton" [disabled]="isEligibility != true " (click)="openEligibilityHistoryModal()" mat-button class="buttonColor p-2 dd-text">
  <!-- <i class="bx bx-copy-alt font-size-16 align-middle mr-2"></i> -->
  <i class="fa fa-history mr-1"></i>
  Eligibility History
</button>
<button mat-button class="buttonColor p-2 dd-text ml-2" (click)="viewFaxDocument()" [disabled]="isEmpty(documentId)">
  <img src="assets/images/NewSetup/GroupV1.png" alt=""class="sea-green-icon-ins mr-1">  View Document
</button>
</div>
<div  class="d-flex justify-content-end col-6 pt-1" style="padding-right: 23px;">
  <button mat-button class="buttonColor p-2 dd-text" [disabled]="saveButtonHide || policyForm?.invalid ||!isUniq "
    (click)="savepolicy()">
    Save
    <mat-icon *ngIf="isShowSpinner">
      <mat-spinner class="spinner-border spinner-border-sm" diameter="20">
      </mat-spinner>
    </mat-icon>
  </button>
  <button  [hidden]="isMstVerification" class="buttonCancelColor p-2 dd-text" style="margin-left:1%" mat-button mat-dialog-close>Close</button>
</div>
</div>
<app-loader-component *ngIf="isDoCheckListLoading||isPolicyLoading"></app-loader-component>

