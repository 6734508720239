import { Component, EventEmitter, Input, OnInit, Output, Renderer2, ViewChild } from '@angular/core';
import { DataTableDirective } from 'angular-datatables';

@Component({
  selector: 'app-patient-clinical-other-referral-table',
  templateUrl: './patient-clinical-other-referral-table.component.html',
  styleUrls: ['./patient-clinical-other-referral-table.component.scss']
})
export class PatientClinicalOtherReferralTableComponent implements OnInit {
  @Input() tableData: any;
  otherReferralTableList: any;
  dtOptionsOtherReferal: any = {
    responsive: true,
    language: {
      emptyTable: 'No records',
    },
    paging: false,
    scrollCollapse: true,
    scrollY: '500px',
    scrollX: true,
    info: false,
    bFilter: false,
  };
  myTable: DataTables.Api;
  @ViewChild(DataTableDirective, { static: false })
  datatableElement: DataTableDirective;
  @Output() isDeleteReferral = new EventEmitter<any>();
  constructor(
    private renderer: Renderer2,
  ) {}

  ngOnInit() {
    this.otherReferralTableList = this.tableData;
  }

  getAddressDetatils(event: Event, addressData: any) {
    const target = event.target as HTMLElement;
    const tr = target.closest('tr');
    const icon = target as HTMLElement;
    this.getDataTableData(tr, addressData, icon);
  }

  async getDataTableData(
    tr: any,
    addressData: any,
    iconElement: HTMLElement
  ): Promise<void> {
    this.myTable = await this.datatableElement.dtInstance;
    if (tr) {
      const row = this.myTable.row(tr);
      if (row.child.isShown()) {
        row.child.hide();
        iconElement.innerText = 'arrow_right';
      } else {
          iconElement.innerText = 'arrow_drop_down';
        row.child(this.addChildRowContent(addressData)).show();
      }
    }
  }

  addChildRowContent(addressData: any): HTMLElement {
    const table = this.renderer.createElement('table');
    this.renderer.addClass(table, 'table-data');
    this.renderer.setStyle(table, 'backgroundColor', '#f1f1f1');
    const tbody = this.renderer.createElement('tbody');

    const addresses = [
          {
            label: 'Address :',
            value: addressData?.address || 'N/A',
            key: 'address',
          },
        ];

    addresses.forEach((address, index) => {
      const tr = this.renderer.createElement('tr');
      const td = this.renderer.createElement('td');
      this.renderer.addClass(td, 'reduced-padding');

      const labelSpan = this.renderer.createElement('span');
      this.renderer.addClass(labelSpan, 'bold-text');
      this.renderer.appendChild(
        labelSpan,
        document.createTextNode(address.label)
      );

      const valueSpan = this.renderer.createElement('span');
      this.renderer.appendChild(
        valueSpan,
        document.createTextNode(' ' + address.value)
      );

      this.renderer.appendChild(td, labelSpan);
      this.renderer.appendChild(td, valueSpan);
      this.renderer.appendChild(tr, td);
      this.renderer.appendChild(tbody, tr);
    });

    this.renderer.appendChild(table, tbody);
    return table;
  }
  deleteReferralAddress(data: any): void {
    this.isDeleteReferral.emit(data);
  }
}
