import { RestService } from '@abp/ng.core';
import { Injectable } from '@angular/core';
import { MedicarechecklistQuestions, Prequestionaire } from 'projects/patient/src/app/dropdown-proxy/dropdown-management/dropdowns/dto/patient/models';

@Injectable({
  providedIn: 'root'
})
export class MedicarechecklistService {
  apiName = 'PatientManagement';
  getOverallchecklist = () =>
  this.restService.request<any, Prequestionaire>({
    method: 'get',
    url: '/api/app/master-check-list'
  },
   { apiName: this.apiName, skipHandleError: true});

  //  getProductdetails = () =>
  //  this.restService.request<any, Prequestionaire>({
  //    method: 'get',
  //    url: '/api/orderApp/mm-order/product-details'
  //  },
  //   { apiName: 'OrderManagement', skipHandleError: true});

   getmedicareQuestions = (documentid?:string, patientid?:string,CheckListId?:string,medicarecheckListID?:string,questionCode?:string, option?:string,) =>
  this.restService.request<any, MedicarechecklistQuestions>({
    method: 'get',
    url: '/api/app/patient-medicare-check-list-details/get-questions',
    params: { CheckListId: CheckListId, DocumentId: documentid, PatientId: patientid,QuestionCode:questionCode,medicareChecklistId:medicarecheckListID,option:option },
  },
   { apiName: this.apiName, skipHandleError: true});

  //  saveFinalMedicare = (medicarechecklistid?: string,finalform?:any) =>
  // this.restService.request({
  //   method: 'put',
  //   url: '/api/app/patient-medicare-check-list-details/update-patient-medicare-details',
  //   params:{id:medicarechecklistid},
  //   body: finalform,
  // },
  //  { apiName: this.apiName, skipHandleError: true});


   saveFinalMedicare = (id: string, input: any) =>
    this.restService.request<any, string>({
      method: 'PUT',
      responseType: 'text',
      url: `/api/app/patient-medicare-check-list-details/${id}/update-patient-medicare-details`,
      body: input,
    },
    { apiName: this.apiName });
  

  constructor(private restService:RestService) { }
}
