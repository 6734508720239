import { ListService, PagedAndSortedResultRequestDto } from '@abp/ng.core';
import { DatePipe } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { SaleorderService } from 'projects/order/src/app/order-proxy/order-management/order/saleorder.service';
import { TableService } from 'projects/shared/src/app/table.service';
import { DateValidator } from 'projects/shared/src/app/validations/date-validator';
import { Subscription } from 'rxjs';
import Swal from 'sweetalert2';
import { ToastrService } from "ngx-toastr";
import { PatientCreateEditComponent } from '../patient-create-edit/patient-create-edit.component';
import { AuthorizationDTO, InsuranceVerifyDTO, PatientDTO, PatientExportDatas, PatientExportDTO } from '../patient-proxy/patient/dto/models';
import { ExportType } from '../patient-proxy/patient/enumeration-data/export-type.enum';
import { InsuranceVerificationSearchRequestDTO } from '../patient-proxy/patient/models';
import { PatientSearchService } from '../patient-proxy/patient/patient-search.service';
import { PatientService } from '../patient-proxy/patient/patient.service';
import { ReportService } from '../patient-proxy/patient/report.service';
import { VerificationSearchService } from '../patient-proxy/patient/verification-search.service';
import { updatePatient } from '../patient-store/patient.action';
import { PatientVerifySearchComponent } from './../patient-verify-search/patient-verify-search.component';

@Component({
  selector: 'app-patient-verify-menu',
  templateUrl: './patient-verify-menu.component.html',
  styleUrls: ['./patient-verify-menu.component.scss'],
  providers: [ListService, DatePipe]
})
export class PatientVerifyMenuComponent implements OnInit, OnDestroy {
  isLoading: boolean = true;
  editOrView: boolean = true;
  personalForm: FormGroup;
  strPageType: string = "patientInsuranceVerification";
  public arrDisplayedColumns: string[] = [];
  dataSource: MatTableDataSource<any> = new MatTableDataSource<any>([]);
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatTable, { static: false }) tableRef: MatTable<any>
  @ViewChild(PatientCreateEditComponent) tabsComponent: any;
  orderForm: FormGroup;
  subscription$: Subscription[] = [];

  constructor(
    private formBuilder: FormBuilder,
    public title: Title,
    public dialog: MatDialog,
    public router: Router,
    public list: ListService,
    private snackBar: MatSnackBar,
    public saleOrderService: SaleorderService,
    public table: TableService,
    private store: Store,
    private patientService: PatientService,
    private patientSearchService: PatientSearchService,
    private VerificationSearch: VerificationSearchService,
    private dateValidator: DateValidator,
    private toastr: ToastrService,
    private datepipe: DatePipe,
    private reportService: ReportService
  ) {


  }
  verifyTableData: any[] = [];
  saveMoveToAuth: any[] = [];


  organizationUnitId: any = null;

  isShownUpdateIconForPatient: boolean = true;

  orderId: string = "";
  patientId: string = "";
  ngOnInit(): void {
    this.isLoading = true;
    const patientUpdate = this.table.getPatientUpdate().subscribe(value => {
      if (value == true) {
        this.isShownUpdateIconForPatient = true;
      }
      else {
        this.isShownUpdateIconForPatient = false;
      }
    }, err => {
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    });
    this.subscription$.push(patientUpdate);
    //
    this.orderForm = this.formBuilder.group({
      PatientId: new FormControl(""),
      PatientName: new FormControl(""),
      Date: new FormControl("", this.dateValidator.dateVaidator),
      Plan: new FormControl(""),
      VerifyName: new FormControl(""),
      Status: new FormControl(""),
    })
    const valueChanges = this.orderForm.valueChanges.subscribe((value: Patient) => {
      let filter: Patient = {
        PatientId: value?.PatientId?.trim()?.toLowerCase(),
        PatientName: value?.PatientName?.trim()?.toLowerCase(),
        Date: value?.Date?.trim()?.toLowerCase(),
        Plan: value?.Plan?.trim()?.toLowerCase(),
        VerifyName: value?.VerifyName?.trim()?.toLowerCase(),
        Status: value?.Status?.trim()?.toLowerCase()
      };
      if (this.orderForm.valid) {
        this.dataSource.filter = JSON.stringify(filter);
        this.filtertable(filter);
      }

    }, err => {
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    });
    this.subscription$.push(valueChanges);
    //
    this.snackBar.dismiss();
    const selectPatientId = this.table.getSelectedPatientId().subscribe(response => {
      this.patientId = response
    }, err => {
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    })
    this.subscription$.push(selectPatientId);
    // this.organizationUnitId = localStorage.getItem('initialOrganizationUnitId');

    this.title.setTitle("Qsecure | Verification To Do");
    this.getInsuranceVerficationTableData();

    this.arrDisplayedColumns = ['PatientId', 'options', 'bin', 'PatientName', 'Date', 'Plan', 'VerifyName', 'Status',];
    //
    const patientInsurance = this.table.getpatientInsuranceVerficationTable().subscribe(value => {
      this.verifyTableData = [];
      this.exractiontableDataFormresult(value);
    }, err => {
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    })
    this.subscription$.push(patientInsurance);
  }

  ngOnDestroy(): void {
    this.subscription$?.forEach((sub) => {
      sub && sub?.unsubscribe();
    });
  }

  patientData: any = []
  // getInsuranceVerficationTableData() {

  //   this.patientService.getList(new PagedAndSortedResultRequestDto).subscribe(response => {
  //     this.changeTableData(response?.items || []);
  //   }, err => {
  //     const data: HttpErrorResponse = err;
  //     Swal.fire({
  //       icon: 'error',
  //       text: data?.error?.error?.message,
  //     });
  //   });



  getInsuranceVerficationTableData() {
    this.isLoading = true;
    this.verifyTableData = [];
    const patientGet = this.patientSearchService.getList(new PagedAndSortedResultRequestDto).subscribe(response => {
      let verifyDetails = response?.items ?? [];
      return this.exractiontableDataFormresult(verifyDetails);
    }, err => {
      this.isLoading = false;
      const arrTableData = this.verifyTableData;
      this.dataSource = new MatTableDataSource(arrTableData);
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
    });
    this.subscription$.push(patientGet);
    this.isLoading = false;
  }

  private exractiontableDataFormresult(verifyDetails: PatientDTO[]) {
    this.isLoading = true;
    verifyDetails && verifyDetails !== [] && verifyDetails !== undefined &&
      verifyDetails !== null && verifyDetails.map(value => {
        let data = {
          sPatientId: value?.defaultPatientId,
          sPatientName: value?.personals.fullName,
          dCreatedDate: value?.creationTime
        };
        const primaryIns = value?.insuranceVerifications?.primaryInsuranceVerifies;
        const secondaryIns = value?.insuranceVerifications?.secondaryInsuranceVerifies;
        const tertiaryIns = value?.insuranceVerifications?.tertiaryInsuranceVerifies;
        const primaryAuth = value?.authorizations?.primaryAuthorizations;
        const secondaryAuth = value?.authorizations?.secondaryAuthorizations;
        const tertiaryAuth = value?.authorizations?.tertiaryAuthorizations;
        if (value?.insuranceVerifications?.primaryInsuranceVerifies !== null) {
          if (value?.insuranceVerifications?.primaryInsuranceVerifies?.moveToAuthStatus !== "Ready To Auth") {
            const creationTime = data?.dCreatedDate === null ? "" : this.datepipe.transform(data?.dCreatedDate, 'MM/dd/yyyy') ?? "";
            this.verifyTableData.push({
              patientId: data.sPatientId,
              PatientName: data.sPatientName,
              createDate: creationTime,
              plan: primaryIns?.plan,
              VerifyName: primaryIns?.planName,
              Status: primaryIns?.verificationStatus,
              id: value?.id,
              binStatus: primaryIns?.verificationStatus === "Done" &&
                primaryAuth === null ? true : false
            });
          }
        }
        if (value?.insuranceVerifications?.secondaryInsuranceVerifies !== null) {
          if (value?.insuranceVerifications?.secondaryInsuranceVerifies?.moveToAuthStatus !== "Ready To Auth") {
            const creationTime = data?.dCreatedDate === null ? "" : this.datepipe.transform(data?.dCreatedDate, 'MM/dd/yyyy') ?? "";

            this.verifyTableData.push({
              patientId: data.sPatientId,
              PatientName: data.sPatientName,
              createDate: creationTime,
              plan: secondaryIns?.plan,
              VerifyName: secondaryIns?.planName,
              Status: secondaryIns?.verificationStatus,
              id: value?.id,
              binStatus: secondaryIns?.verificationStatus === "Done" &&
                secondaryAuth === null ? true : false
            });
          }
        }
        if (tertiaryIns !== null) {
          if (value?.insuranceVerifications?.tertiaryInsuranceVerifies?.moveToAuthStatus !== "Ready To Auth") {
            const creationTime = data?.dCreatedDate === null ? "" : this.datepipe.transform(data?.dCreatedDate, 'MM/dd/yyyy') ?? "";

            this.verifyTableData.push({
              patientId: data.sPatientId,
              PatientName: data.sPatientName,
              createDate: creationTime,
              plan: tertiaryIns?.plan,
              planCob: tertiaryIns?.planCob,
              VerifyName: tertiaryIns?.planName,
              planName: tertiaryIns?.planName,
              Status: tertiaryIns?.verificationStatus,
              id: value?.id,
              binStatus: tertiaryIns?.verificationStatus === "Done" &&
                tertiaryAuth === null ? true : false
            });
          }
        }
        this.isLoading = false;
      });
    this.isLoading = false;
    const arrTableData = this.verifyTableData;
    this.dataSource = new MatTableDataSource(arrTableData);
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
    return verifyDetails;
  }

  getTableData(value: any) {

  }
  verificationView(id: string) {
    this.router.navigate(['/patientViewEdit/' + id]);

  }

  //! move to auth
  moveToAuth(event: { id: string; plan: string; typeOfPlan: any; planName: any; }) {
    let verificationData: PatientDTO;
    if ((event.id !== "" && event.id !== null && event.id !== undefined)) {
      // const storeSubscription =  this.patientService.get(event.id).subscribe(response => {
      const storeSubscription = this.patientService.get(event.id).subscribe(response => {
        const selectedPatient: PatientDTO = response;
        if (selectedPatient?.authorizations) {
          let savedData = {
            primaryAuthorizations: selectedPatient?.authorizations?.primaryAuthorizations,
            secondaryAuthorizations: selectedPatient?.authorizations?.secondaryAuthorizations,
            tertiaryAuthorizations: selectedPatient?.authorizations?.tertiaryAuthorizations
          }
          let savedVerfn: Partial<{
            primaryInsuranceVerifies: InsuranceVerifyDTO
            secondaryInsuranceVerifies: InsuranceVerifyDTO
            tertiaryInsuranceVerifies: InsuranceVerifyDTO
          }> = {
            primaryInsuranceVerifies: selectedPatient?.insuranceVerifications?.primaryInsuranceVerifies,
            secondaryInsuranceVerifies: selectedPatient?.insuranceVerifications?.secondaryInsuranceVerifies,
            tertiaryInsuranceVerifies: selectedPatient?.insuranceVerifications?.tertiaryInsuranceVerifies
          }
          if (event.plan === "Primary") {
            savedData.primaryAuthorizations = {
              authorizationId: "00000000-0000-0000-0000-000000000000",
              authId: "",
              authVisits: "",
              authAmount: "",
              startDate: "",
              endDate: "",
              category: "",
              item: [],
              note: "",
              isCompleted: "Yet To Start",
              completedBy: "",
              typeOfPolicy: event.typeOfPlan,
              plan: event.plan,
              planName: event.planName,
              approvalStatus: "",
              approvedByWhom: "",
              approvedOn: "",
              units: "",
              used: "",
              saleOrRent: "",
              isExpired: 0,
              itemCodes: "",
              isFollowUp: 0,
              expiryStatus: ""
            }
          } else if (event.plan === "Secondary") {
            savedData.secondaryAuthorizations = {
              authorizationId: "00000000-0000-0000-0000-000000000000",
              authId: "",
              authVisits: "",
              authAmount: "",
              startDate: "",
              endDate: "",
              category: "",
              item: [],
              note: "",
              isCompleted: "Yet To Start",
              completedBy: "",
              typeOfPolicy: event.typeOfPlan,
              plan: event.plan,
              planName: event.planName,
              approvalStatus: "",
              approvedByWhom: "",
              approvedOn: "",
              units: "",
              used: "",
              saleOrRent: "",
              isExpired: 0,
              itemCodes: "",
              isFollowUp: 0,
              expiryStatus: ""

            }
          } else if (event.plan === "Tertiary") {
            savedData.tertiaryAuthorizations = {

              authorizationId: "00000000-0000-0000-0000-000000000000",
              authId: "",
              authVisits: "",
              authAmount: "",
              startDate: "",
              endDate: "",
              category: "",
              item: [],
              note: "",
              isCompleted: "Yet To Start",
              completedBy: "",
              typeOfPolicy: event.typeOfPlan,
              plan: event.plan,
              planName: event.planName,
              approvalStatus: "",
              approvedByWhom: "",
              approvedOn: "",
              units: "",
              used: "",
              saleOrRent: "",
              isExpired: 0,
              itemCodes: "",
              isFollowUp: 0,
              expiryStatus: ""
            }
          }
          if (event.plan === "Primary") {
            savedVerfn.primaryInsuranceVerifies = {
              ...selectedPatient?.insuranceVerifications?.primaryInsuranceVerifies ?? null,
              moveToAuthStatus: "Ready To Auth"
            }
          }
          if (event.plan === "Secondary") {
            savedVerfn.secondaryInsuranceVerifies = {
              ...selectedPatient?.insuranceVerifications?.secondaryInsuranceVerifies ?? null,
              moveToAuthStatus: "Ready To Auth"
            }
          }
          if (event.plan === "Tertiary") {
            savedVerfn.tertiaryInsuranceVerifies = {
              ...selectedPatient?.insuranceVerifications?.tertiaryInsuranceVerifies ?? null,
              moveToAuthStatus: "Ready To Auth"
            }
          }
          verificationData = {
            personals: selectedPatient?.personals,
            addresses: selectedPatient?.addresses,
            contacts: selectedPatient?.contacts,
            clinicals: selectedPatient?.clinicals,
            insurances: selectedPatient?.insurances,
            notes: selectedPatient?.notes,
            policies: selectedPatient?.policies,
            defaultPatientId: selectedPatient?.defaultPatientId,
            insuranceVerifications: {
              primaryInsuranceVerifies: savedVerfn.primaryInsuranceVerifies,
              secondaryInsuranceVerifies: savedVerfn.secondaryInsuranceVerifies,
              tertiaryInsuranceVerifies: savedVerfn.tertiaryInsuranceVerifies,
            },
            authorizations: {
              primaryAuthorizations: savedData?.primaryAuthorizations,
              secondaryAuthorizations: savedData?.secondaryAuthorizations,
              tertiaryAuthorizations: savedData?.tertiaryAuthorizations
            },
            patientDocuments: selectedPatient?.patientDocuments,
            organizationUnitId: selectedPatient?.organizationUnitId,
            isAutoIntake: selectedPatient?.isAutoIntake,
            autoIntakeDocumentDetails: selectedPatient?.autoIntakeDocumentDetails,
            chartId: selectedPatient?.chartId,
            billingDetails: selectedPatient?.billingDetails,
            patientLock: selectedPatient?.patientLock,
            fileId: selectedPatient?.fileId,
          }
        }
        this.store.dispatch(new updatePatient(event.id, verificationData)).subscribe(response => {
          // this.saveMoveToAuth = response.patientState.selectedPatient
          this.saveMoveToAuth.push(
            response.patientState.selectedPatient
          )
          // Swal.fire({ title: 'Success', html: 'Record Moved To Auth Bin Successfully', icon: 'success', timer: 3000, timerProgressBar: true });
          this.toastr.success('Record Moved To Auth Bin Successfully','Success')
          this.getInsuranceVerficationTableData()
        }, err => {
          const data: HttpErrorResponse = err;
          Swal.fire({
            icon: 'info',
            text: data?.error?.error?.message,
          });
        })
      }, err => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      })
      this.subscription$.push(storeSubscription);
    }

    //this.getInsuranceVerficationTableData();
  }

  openSearchModal() {
    const dialogRef = this.dialog.open(PatientVerifySearchComponent, {
      disableClose: true,
      height: '100%',
      width: '100%',
      maxHeight: '200px',
      maxWidth: '600px',
    });

    const closeDialog = dialogRef.afterClosed().subscribe(() => {
      //this.getInsuranceVerficationTableData();
    });
    this.subscription$.push(closeDialog)

  }
  viewInsuranceVerification(value: string) {
    this.orderId = value
    this.router.navigate(['/patient/saleorderCreateEdit/' + this.orderId, this.editOrView = false])
  }
  editInsuranceVerification(value: string) {
    this.orderId = value
    this.router.navigate(['/patient/saleorderCreateEdit/' + this.orderId, this.editOrView = true])
  }
  //! search table
  filtertable(filter: Patient) {
    let PatientId = filter.PatientId;
    let PatientName = filter.PatientName;
    let Plan = filter.Plan;
    let DateValue = filter.Date;
    let VerifyName = filter.VerifyName;
    let Status = filter.Status;
    const endDate = DateValue === null ? "" : this.datepipe.transform(DateValue, 'MM/dd/yyyy') ?? "";

    const input: InsuranceVerificationSearchRequestDTO = {
      sPatientId: PatientId,
      sPatietName: PatientName,
      sPlan: Plan,
      dtCreatedDate: DateValue,
      sVerifyName: VerifyName,
      sStatus: Status
    }

    const verificationSearch = this.VerificationSearch.searchVerificationByInput(input
    ).subscribe(data => {
      // const responseData: any[] = [] || data;
      const responseData: any[] = [];
      responseData[0] = data;
      this.verifyTableData = []
      responseData[0]?.forEach((value: {
        insuranceVerifications: {
          primaryInsuranceVerifies: {
            moveToAuthStatus: string;
            plan: string;
            planName: string;
            verificationStatus: string;
          }; secondaryInsuranceVerifies: {
            moveToAuthStatus: string;
            plan: string;
            planName: string;
            verificationStatus: string;
          }; tertiaryInsuranceVerifies: {
            moveToAuthStatus: string;
            plan: string;
            planName: string;
            planCob: string;
            verificationStatus: string;
          };
        };
        authorizations: {
          primaryAuthorizations: AuthorizationDTO;
          secondaryAuthorizations: AuthorizationDTO;
          tertiaryAuthorizations: AuthorizationDTO;
        }
        defaultPatientId: any; Personals: { fullName: any; }; CreationTime: any; plan: any; Id: any; creationTime: string | number | Date; fullName: any; planName: any; verificationStatus: string;
      }) => {
        if (value?.insuranceVerifications) {
          // verifyDetails.map(value => {
          let data = {
            sPatientId: value?.defaultPatientId,
            sPatientName: value?.Personals?.fullName,
            dCreatedDate: value?.CreationTime
          }
          // value?.creationTime == null ? "" :  new Date(value?.creationTime)?.toLocaleDateString("en-US"),
          const primaryIns = value?.insuranceVerifications?.primaryInsuranceVerifies
          const secondaryIns = value?.insuranceVerifications?.secondaryInsuranceVerifies
          const tertiaryIns = value?.insuranceVerifications?.tertiaryInsuranceVerifies
          const primaryAuth = value?.authorizations?.primaryAuthorizations;
          const secondaryAuth = value?.authorizations?.secondaryAuthorizations;
          const tertiaryAuth = value?.authorizations?.tertiaryAuthorizations;
          if (value?.insuranceVerifications?.primaryInsuranceVerifies !== null) {
            if (value?.insuranceVerifications?.primaryInsuranceVerifies?.moveToAuthStatus !== "Ready To Auth") {
              const creationTime = data?.dCreatedDate === null ? "" : this.datepipe.transform(data?.dCreatedDate, 'MM/dd/yyyy') ?? "";
              // const creationTime = (data?.dCreatedDate.getMonth() + 1) + '-' + data?.dCreatedDate.getDate() + '-' + data?.dCreatedDate.getFullYear();

              this.verifyTableData.push({
                patientId: data?.sPatientId,
                PatientName: data?.sPatientName,
                createDate: creationTime,
                plan: primaryIns?.plan || value?.plan,
                VerifyName: primaryIns?.planName,
                Status: primaryIns?.verificationStatus,
                binStatus: primaryIns?.verificationStatus === "Done" &&
                  primaryAuth === null ? true : false,
                id: value?.Id

              })
            }
          }
          if (value?.insuranceVerifications?.secondaryInsuranceVerifies !== null) {
            if (value?.insuranceVerifications?.secondaryInsuranceVerifies.moveToAuthStatus !== "Ready To Auth") {
              const creationTime = data?.dCreatedDate === null ? "" : this.datepipe.transform(data?.dCreatedDate, 'MM/dd/yyyy') ?? "";

              this.verifyTableData.push({
                patientId: data?.sPatientId,
                PatientName: data?.sPatientName,
                createDate: creationTime,
                plan: secondaryIns?.plan,
                VerifyName: secondaryIns?.planName,
                Status: secondaryIns?.verificationStatus,
                binStatus: secondaryIns?.verificationStatus === "Done" &&
                  secondaryAuth === null ? true : false,
                id: value?.Id
              })
            }
          }
          if (value?.insuranceVerifications?.tertiaryInsuranceVerifies !== null) {
            if (value?.insuranceVerifications?.tertiaryInsuranceVerifies?.moveToAuthStatus !== "Ready To Auth") {
              const creationTime = data?.dCreatedDate === null ? "" : this.datepipe.transform(data?.dCreatedDate, 'MM/dd/yyyy') ?? "";

              this.verifyTableData.push({
                patientId: data?.sPatientId,
                PatientName: data?.sPatientName,
                createDate: creationTime,
                plan: tertiaryIns?.plan,
                planCob: tertiaryIns?.planCob,
                VerifyName: tertiaryIns?.planName,
                Status: tertiaryIns?.verificationStatus,
                binStatus: tertiaryIns?.verificationStatus === "Done" &&
                  tertiaryAuth === null ? true : false,
                id: value?.Id
              })
            }
          }
          // } else {
          //   const creationTime = value?.creationTime === null ? "" : this.datepipe.transform(value?.creationTime, 'MM/dd/yyyy') ?? "";

          //   this.verifyTableData.push({
          //     Id: value?.Id,
          //     createDate: creationTime,
          //     patientId: value?.defaultPatientId,
          //     PatientName: value?.fullName,
          //     plan: value?.plan,
          //     VerifyName: value?.planName,
          //     Status: value?.verificationStatus,
          //     binStatus: value?.verificationStatus !== "Done",
          //     id: value?.Id
          //   })
        }

      })
      this.isLoading = false;
      const arrTableData = this.verifyTableData;
      this.dataSource = new MatTableDataSource(arrTableData);
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
    }, err => {
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    })
    this.subscription$.push(verificationSearch)
  }

  /// export data from table list
  exportTableData() {
    let datas: PatientExportDatas[] = []
    this.dataSource?.data?.forEach(element => {

      datas.push({
        patientDefaultId: element?.patientId,
        patientName: element?.PatientName,
        patientCreatedDate: element?.createDate,
        planType: element?.plan,
        insuranceName: element?.VerifyName,
        verificationStatus: element?.Status,
      });
    })
    let patientsExport: PatientExportDTO = {
      columns: ["Patient Id", "Patient Name", "Created Date", "Plan", "Verify Name", "Status"],
      datas: datas
    };
    const exportTableData = this.reportService.exportPatientReportsByExportTypeAndExportDatas(ExportType.VerificationReports, patientsExport).subscribe(response => {
      if (response && response?.length !== 0) {
        let filelocation: String = String("data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64," + response);
        let link = document.createElement("a");
        link.download = ("Approved-List") + ".xlsx";
        link.href = filelocation.toString();
        link.click();
        Swal.fire({ title: 'Success', html: 'Exported Successfully', icon: 'success', timer: 3000, timerProgressBar: true })
        this.toastr.success('Exported Successfully','Success')
      }
    }, err => {
      const data: HttpErrorResponse = err;
      let errorMesg: string = data?.error;
      if (errorMesg?.includes('Sorry!! There is no data in table to export')) {
        errorMesg = 'Sorry!! There is no data in table to export';
      }
      else {
        errorMesg = data?.error?.error?.message;
      }
      Swal.fire({
        icon: 'info',
        text: errorMesg
      });
    });
    this.subscription$.push(exportTableData);
  }
}
export interface infInsuranceVerificationTable {
  id?: string,
  saleorderId?: number,
  SNo?: string,
  PatientId?: string,
  PatientName?: string,
  OrderId?: string,
  createDate: string,
  VerifyId?: string,
  VerifyName?: string,
  Status?: string,
  isActive?: boolean,
  CreatedDate?: string
  patientData?: string
  toDate?: Date
  fromDate?: Date
}
export interface Patient {
  PatientId?: string,
  PatientName?: string,
  Date?: string,
  Plan?: string,
  VerifyName?: string,
  Status?: string,

}

function dateYYYYMMDDtoMMDDYYYY(strDate: string): string {
  if (strDate && strDate !== "" && typeof strDate === 'string' && strDate !== null && strDate !== undefined && strDate.length >= 1 && !isNaN(new Date(strDate).getTime())) {
    let latest_date = Intl.DateTimeFormat('en-US')?.format(new Date(strDate))
    return latest_date;
  }
  return "";
}
