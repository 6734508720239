import { RestService } from '@abp/ng.core';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class DeliveryTicketService {
  apiName = 'orderManagement';

  downloadDeliveryTicketDocumentByOrderId = (orderId: string) =>
    this.restService.request<any, string>({
      method: 'GET',
      responseType: 'text',
      url: `/api/orderApp/delivery-ticket/download-delivery-ticket-document/${orderId}`,
    },
     { apiName: this.apiName, skipHandleError: true });


  getDeliveryTicketDocumentByOrderId = (orderId: string) =>
    this.restService.request<any, number[]>({
      method: 'GET',
      url: `/api/orderApp/delivery-ticket/get-delivery-ticket-document/${orderId}`,
    },
     { apiName: this.apiName, skipHandleError: true });

  constructor(private restService: RestService) {}
}
