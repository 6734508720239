import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router, UrlSegment } from '@angular/router';
import { IdentityUserDto } from 'projects/admin/src/app/admin-proxy/platform-app-management/identity';
import { UserService } from 'projects/admin/src/app/admin-proxy/platform-app-management/rcm/platform-management/user';
// import { defaultGuid } from 'projects/admin/src/app/document-perviewer/document-perviewer.component';
import { NoteType } from 'projects/admin/src/app/warehouse-proxy/inventory/dto';
import { AddNoteTabComponent } from 'projects/patient/src/app/add-note-tab/add-note-tab.component';
import { PatientPersonalService } from 'projects/patient/src/app/patient-proxy/patient-optimization';
import { PatientPersonalDTO } from 'projects/patient/src/app/patient-proxy/patient-optimization/dto';
import { NgbNav } from '@ng-bootstrap/ng-bootstrap';
import { PatientCommonNotesComponent } from 'projects/shared/src/app/components/patient-common-notes/patient-common-notes.component';
import { SharedTableService } from 'projects/shared/src/app/shared-table/shared-table.service';
import { Observable, of, Subscription } from 'rxjs';
import Swal from 'sweetalert2';
import { AddReminderTabComponent } from '../add-reminder-tab/add-reminder-tab.component';
import { ReminderDetailService } from '../order-proxy/order-management/order-optimization/reminder-detail.service';
import { RemindersService } from '../reminder-message/reminders.service';
import { OrderTabComponent } from '../order-tab/order-tab.component';
import { ItemTabComponent } from '../item-tab/item-tab.component';
import { OrderAuthorizationTabComponent } from '../order-authorization-tab/order-authorization-tab.component';
import { ReminderTabComponent } from '../reminder-tab/reminder-tab.component';
import { defaultGuid } from 'projects/shared/src/app/enums/allenums.enum';


@Component({
  selector: 'app-order-create-edit-tab',
  templateUrl: './order-create-edit-tab.component.html',
  styleUrls: ['./order-create-edit-tab.component.scss'],
})
export class OrderCreateEditTabComponent implements OnInit {
  patientId: string = defaultGuid;
  orderId: string = defaultGuid;
  templateId: string = defaultGuid;
  defaultGuid: string = defaultGuid;
  currentOrderStatus: string = '';
  strUrlPath: string = '';
  lblDefaultSaleOrderId: string = '';
  subscription$: Subscription[] = [];
  arrUsersList: IdentityUserDto[] = [];
  isEditButton: boolean = false;
  autoRedirect: boolean = false;
  patientDetails: Partial<PatientPersonalDTO> = null;
  currentUserId: string = localStorage.getItem('userId') ?? defaultGuid;
  noteType: NoteType = NoteType.order;
  @ViewChild('nav') nav: NgbNav;
  @ViewChild(OrderTabComponent) OrderTabComponent: OrderTabComponent;
  @ViewChild(ItemTabComponent) ItemTabComponent: ItemTabComponent;
  @ViewChild(OrderAuthorizationTabComponent) OrderAuthorizationTabComponent: OrderAuthorizationTabComponent;
  @ViewChild(ReminderTabComponent) ReminderTabComponent: ReminderTabComponent;
  @ViewChild(PatientCommonNotesComponent) PatientCommonNotesComponent: PatientCommonNotesComponent;
  isTemplate = false;
  constructor(
    private router: Router,
    public title: Title,
    public matDialog: MatDialog,
    public userService: UserService,
    private activatedRoute: ActivatedRoute,
    private reminderDetailService: ReminderDetailService,
    private patientPersonalService: PatientPersonalService,
    private reminderService: RemindersService,
    private sharedTableService: SharedTableService
  ) { }
  ngOnDestroy(): void {
    this.subscription$?.forEach((sub) => {
      sub && sub?.unsubscribe();
    });
  }
  routerPathObs: Observable<UrlSegment[]> = this.activatedRoute?.url ?? of([])
  ngOnInit(): void {
    this.routerPathObs.subscribe(response => {
      this.isTemplate = response?.[1].path === 'OrderTemplateCreateEditTab';
    })
    this.sharedTableService.setTableData([]);
    const activateRoute = this.activatedRoute.paramMap.subscribe(
      (response) => {
        if(defaultGuid == response.get('orderId')){
         this.isEditButton = true
        }else{
          this.isEditButton = false
        }

        this.orderId = response?.get('orderId') ?? defaultGuid;
        this.patientId = response?.get('patientId') ?? defaultGuid;

        this.templateId = response?.get('templateId') ?? defaultGuid;

        this.patientId !== defaultGuid &&
          this.getDetailsByPatientID(this.patientId);
        this.patientId !== defaultGuid &&
          this.title.setTitle('Qsecure | New Order');
        this.orderId !== defaultGuid &&
          this.title.setTitle('Qsecure | View Order');


      },
      (err) => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      }
    );
    this.subscription$.push(activateRoute);
  }
  getReminderDetailsByOrderID(orderId: string) {
    // reminder getlist based on Order ID
    const reminders = this.reminderDetailService
      .getRemindersByOrderIdByID(orderId)
      .subscribe(
        (response) => {
          this.sharedTableService.setTableData(response);

          response?.forEach((element) => {
            // ! For Curerent Users Only
            const isCurrentUser =
              (element?.assignedTo?.filter((u) => u === this.currentUserId)?.[0]
                ?.length ?? 0) !== 0;
            const isCurrentDate =
              new Date()?.toLocaleDateString('en-US') ===
              new Date(element?.reminderDate)?.toLocaleDateString('en-US');
            if (
              isCurrentDate &&
              element?.isCompleted === 0 &&
              element?.isDontShowAgain === 0 &&
              isCurrentUser
            ) {
              const userName: string =
                this.arrUsersList?.filter(
                  (u) => u?.id === this.currentUserId
                )?.[0]?.userName ?? '';
              const titleMsg: string = 'Hi ' + userName + ' 👋';
              const message: { patientName: string; subject: string } = {
                patientName: this.patientDetails?.fullName,
                subject: element?.subject,
              };
              const messageTimeOut: number = 1000000;
              this.reminderService.success(titleMsg, message, messageTimeOut);
            }
            // ! For All Users
            if (
              isCurrentDate &&
              element?.isCompleted === 0 &&
              element?.isDontShowAgain === 0 &&
              element?.assignedTo?.length === 0
            ) {
              const userName: string =
                this.arrUsersList?.filter(
                  (u) => u?.id === this.currentUserId
                )?.[0]?.userName ?? '';
              const titleMsg: string = 'Hi ' + userName + ' 👋';
              const message: { patientName: string; subject: string } = {
                patientName: this.patientDetails?.fullName,
                subject: element?.subject,
              };
              const messageTimeOut: number = 1000000;
              this.reminderService.success(titleMsg, message, messageTimeOut);
            }
          });
        },
        (err) => {
          this.sharedTableService.setTableData([]);

          const data: HttpErrorResponse = err;
          Swal.fire({
            icon: 'info',
            text: data?.error?.error?.message,
          });
        }
      );
    this.subscription$?.push(reminders);
  }

  getDetailsByPatientID(patientId: string) {
    const patientDetails = this.patientPersonalService
      .get(this.patientId)
      .subscribe(
        (response) => {
          this.patientDetails = response;

          this.orderId !== defaultGuid &&
            this.getReminderDetailsByOrderID(this.orderId);
        },
        (err) => {
          const data: HttpErrorResponse = err;
          Swal.fire({
            icon: 'info',
            text: data?.error?.error?.message,
          });
        }
      );
    this.subscription$?.push(patientDetails);
    const userList = this.userService.getIdentityUser().subscribe(
      (response: IdentityUserDto[]) => {
        this.arrUsersList = response;
      },
      (err) => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      }
    );
    this.subscription$.push(userList);
  }

  backToPatient() {
    this.patientId &&
      this.patientId !== defaultGuid &&
      this.router.navigate(['/patientCreateEditTab/' + this.patientId]);
  }

  openRemindermodal() {
    const config: MatDialogConfig = {
      disableClose: true,
      data: {
        patientId: this.patientId,
        orderId: this.orderId,
      },
    };
    const dialogRef = this.matDialog.open(AddReminderTabComponent, config);

    const closeDialog = dialogRef.afterClosed().subscribe(
      () => {
        this.getReminderTableData();
      },
      (err) => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      }
    );
    this.subscription$.push(closeDialog);
  }
  getReminderTableData() { }

  editPatient(){
    this.autoRedirect = true
    if(this.OrderTabComponent){
      this.OrderTabComponent.editForm()
    } else if(this.ItemTabComponent){
      this.ItemTabComponent.editForm()
    } else if(this.OrderAuthorizationTabComponent){
      this.OrderAuthorizationTabComponent.editForm()
    } else if(this.PatientCommonNotesComponent){
      this.PatientCommonNotesComponent.editForm()
    } else if(this.ReminderTabComponent){
      this.ReminderTabComponent.editForm()
    }
  }
  getOrderIdfromChild(value: string) {
    if(this.autoRedirect == true){
      this.nav.select('Item')
    }else if(this.orderId == defaultGuid) {
      this.nav.select('Item')

    }
    const orderId = (value ?? '') !== '' ? value : defaultGuid;
    this.orderId = '';
    setTimeout(() => {
      this.orderId = orderId ?? defaultGuid;
    }, 1);
  }

  openAddNotesPopUp() {
    const config: MatDialogConfig = {
      disableClose: true,
      minHeight: '30vh',
      minWidth: '50vw',
      data: {
        patientId: this.patientId,
        notesId: defaultGuid,
        orderId: this.orderId,
        claimId: defaultGuid,
        noteType: NoteType.order
      },
    };
    const dialogRef = this.matDialog.open(AddNoteTabComponent, config);

    const closeDialog = dialogRef.afterClosed().subscribe(
      () => {
        // this.getReminderTableData();
      },
      (err) => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      }
    );
    this.subscription$.push(closeDialog);
  }
  openViewNotesPopup() {
    const config: MatDialogConfig = {
      disableClose: true,
      width: '70vw',
      maxWidth: '70vw',
      data: {
        patientId: this.patientId,
        pageType: NoteType.order,
        isAddVisible: false
      },
    };
    const dialogRef = this.matDialog.open(PatientCommonNotesComponent, config);

    const closeDialog = dialogRef.afterClosed().subscribe(
      () => {
        // this.getReminderTableData();
      },
      (err) => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      }
    );
    this.subscription$.push(closeDialog);
  }
}
