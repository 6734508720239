<div class="container-fluid">
    <div class="row bg">
      <div class="col-12">
        <div class="d-flex align-item-center justify-content-between m-0">
          <!-- <h4 class="mt-3 mb-0 font-size-18"><b>Resupply Product List </b></h4> -->
          <div class="page-title-box page-title-right mt-1 mb-0">
            <ol class="breadcrumbs m-0">
              <!-- <button
                mat-button
                class="mr-2 buttonColor"
                [disabled]="(dataSource && dataSource?.data && dataSource?.data?.length === 0)"

                (click)="resupplyExportTableData()"
              >
                Export
              </button> -->
              <!-- <button
              mat-button
              class="mr-2 buttonColor"
              [disabled]="
              dataSource && dataSource?.data && dataSource?.data?.length === 0
              "
              (click)="
                exporter.exportTable('xlsx', {
                  fileName: 'Resupply Product List',
                  sheet: 'resupplyProductList',
                  Props: { Author: 'QSecure' }
                })"
            >
              Export
            </button> -->
              <!-- <li class="breadcrumb-item mt-2">Inventory</li>
              <li class="breadcrumb-item mt-2">ResupplyProduct</li> -->
            </ol>
          </div>
        </div>
      </div>
    </div>
    <div class="alginClass">  
      <div class="card card-body">
        <div class="desgin">
    <div class="col-12" style=" margin-left: -38px">
    
      <mat-accordion>
        <mat-expansion-panel [expanded]="step" (afterExpand)="toggle()" (closed)="toggleV2()">
          <mat-expansion-panel-header>
            <mat-panel-title class="customThemeClass">
              <ng-container>
                <b class="pt-2" *ngIf="reSupplyId == ''; else elseTitleTemplate " >Add Resupply Product </b> </ng-container><br /><br />
              <ng-template #elseTitleTemplate>
                <b>Edit Resupply Product </b>
              </ng-template>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <mat-card-content>
            <form #pBrandForm [formGroup]="resupplyProductForm">
              <div class="row pb-4">
                <mat-form-field class="algin col-lg-4">
                  <mat-label
                    >Product Name<span class="asterisk"
                      >*</span
                    ></mat-label
                  >
                  <input
                  autocomplete="new-txtBrandName"
                    matInput
                    maxlength="50"
                    formControlName="txtBrandName"
                    placeholder="Enter Product Name"
                    type="text"

                  />
                  <mat-error
                    *ngIf="
                    resupplyProductForm?.get('txtBrandName')?.touched &&
                    resupplyProductForm?.get('txtBrandName')?.errors?.required
                    "
                  >
                    Product  Name is a required field!
                  </mat-error>
                </mat-form-field>
                <mat-form-field class="col-4">
                  <mat-label>Main Product<span class="asterisk">*</span> </mat-label>
                  <mat-select formControlName="txtMainProduct" [(value)]="MainProduct" #singleSelect>
                    <mat-option>
                      <ngx-mat-select-search placeholderLabel="Search" noEntriesFoundLabel="No Matches found"
                        formControlName="txtMainProductFilter"></ngx-mat-select-search>
                    </mat-option>
                    <mat-option *ngFor="let provider of filterMainProduct | async" [value]="provider.id">
                      {{provider.mainProductName + "/" + provider.hcpcCode}}
                    </mat-option>
                  </mat-select>

                  <button mat-button (click)="MainProduct=undefined;$event.stopPropagation()" *ngIf="MainProduct" matSuffix
                    (click)="clearMainProduct()" mat-icon-button >
                    <mat-icon>close</mat-icon>
                  </button>
                  <mat-error
                  *ngIf="
                  resupplyProductForm?.get('txtBrandName')?.touched &&
                  resupplyProductForm?.get('txtBrandName')?.errors?.required
                  "
                >
                Main Product is a required field!
                </mat-error>
                </mat-form-field>
                <mat-form-field class="algin col-lg-4">
                  <mat-label
                    >HCPC Code
                    <span class="asterisk"
                      >*</span
                    ></mat-label
                  >
                  <input
                  autocomplete="new-txthcpcCode"
                    matInput
                    maxlength="50"
                    formControlName="txthcpcCode"
                    placeholder="Enter HCPC Code"
                    type="text"
                    pattern="^[a-zA-Z0-9-_\s]*$"
                  />
                  <mat-error
                    *ngIf="
                    resupplyProductForm?.get('txthcpcCode')?.touched &&
                    resupplyProductForm?.get('txthcpcCode')?.errors?.required
                    "
                  >
                  HCPC Code is a required field!
                  </mat-error>
                </mat-form-field>
                <!-- <mat-form-field class="algin col-lg-4">
                  <mat-label
                    >Product Code
                    <span class="asterisk"
                      >*</span
                    ></mat-label
                  >
                  <input
                    matInput
                    maxlength="50"
                    formControlName="txtProductCode"
                    placeholder="Enter Product Code"
                    type="text"
                    pattern="^[a-zA-Z0-9-_\s]*$"
                  />
                  <mat-error
                    *ngIf="
                    resupplyProductForm?.get('txtProductCode')?.invalid &&
                    resupplyProductForm?.get('txtProductCode')?.errors?.required
                    "
                  >
                  Product Code is a required field!
                  </mat-error>
                </mat-form-field> -->
                <mat-form-field class="algin col-lg-4">
                  <mat-label
                    >Description<span class="asterisk"
                      >*</span
                    ></mat-label
                  >
                  <input
                  autocomplete="new-txtproductDescription"
                    matInput
                    maxlength="500"
                    formControlName="txtproductDescription"
                    placeholder="Enter Product Description"
                    type="text"
                    pattern="^[a-zA-Z0-9-_\s]*$"
                  />
                  <mat-error
                    *ngIf="
                    resupplyProductForm?.get('txtproductDescription')?.touched &&
                    resupplyProductForm?.get('txtproductDescription')?.errors?.required
                    "
                  >
                  Description is a required field!
                  </mat-error>
                </mat-form-field>
                <mat-form-field class="algin col-lg-4">
                  <mat-label
                    >Minimum Keeping Stock<span class="asterisk"
                      >*</span
                    ></mat-label
                  >
                  <input
                  autocomplete="new-txtminimumQuantity"
                    matInput
                    maxlength="50"
                    formControlName="txtminimumQuantity"
                    placeholder="Enter Minimum Keeping Stock"
                    type="text"
                    pattern="^[a-zA-Z0-9-_\s]*$"
                  />
                  <mat-error
                    *ngIf="
                    resupplyProductForm?.get('txtminimumQuantity')?.touched &&
                    resupplyProductForm?.get('txtminimumQuantity')?.errors?.required
                    "
                  >
                  Minimum Keeping Stock is a required field!
                  </mat-error>
                </mat-form-field>

                <div class="col-lg-6 text-lg-left">
                  <button
                    mat-button [disabled]="!(!resupplyProductForm.invalid && resupplyProductForm.dirty )"
                    class="buttonColor mb-1 mr-2"
                   (click)="addResupplyProduct()"
                  >
                    Save
                  </button>
                  <!-- <button
                    mat-button

                    class="resetclr buttonColor mb-2 mr-2"
                  >
                    Reset
                  </button> -->
                  <button  mat-button
                        (click)="resetForm()"
                        class="resetclr buttonColor mb-2 mr-2">Reset</button>
                </div>
              </div>
            </form>
          </mat-card-content>
        </mat-expansion-panel>
      </mat-accordion>
    
  </div>
  </div>
  <div class="page-title-box page-title-right mt-1 mb-0">
    <ol class="breadcrumbs m-0">
     <h4 class="mt-3 mb-0 font-size-18" style="padding-bottom: 16px;"><b>Resupply Product</b></h4>
   <div  class="exportClass">   
    <button
    mat-button
    class="mr-2 buttonColor"
    [disabled]="
    dataSource && dataSource?.data && dataSource?.data?.length === 0
    "
    (click)="
      exporter.exportTable('xlsx', {
        fileName: 'Resupply Product List',
        sheet: 'resupplyProductList',
        Props: { Author: 'QSecure' }
      })"
  >
    Export
  </button>
   </div>
  
   </ol>
   </div>
    <div class="col-12" >
      <form [formGroup]="supplyProductForm">
        <div class="tabspace table table-responsive">
          <table mat-table [dataSource]="dataSource" matSort matTableExporter #exporter="matTableExporter"   class="mat-elevation-z8 border">
            <ng-container matColumnDef="S.No">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>S.No</th>
              <td mat-cell *matCellDef="let data; let i = index">
                {{ i+1 }}
              </td>
            </ng-container>
            <!-- Name Column -->
            <ng-container matColumnDef="name">
              <!-- <th mat-header-cell *matHeaderCellDef> Name </th> -->
              <th class="header" mat-sort-header mat-header-cell *matHeaderCellDef>
                <mat-form-field class="filter">
                  <input autocomplete="new-Name" matInput (keydown.space)="$event.stopPropagation()" (click)="$event.stopPropagation()"
                  formControlName="Name" />
                  <mat-placeholder>Resupply Name</mat-placeholder>
                </mat-form-field>
              </th>
              <td mat-cell *matCellDef="let element">
                <span matTooltip="{{element?.name}}" matTooltipClass="custom-tooltip-font"> {{ element?.name
                  }}</span>
              </td>
            </ng-container>

            <ng-container matColumnDef="mainProductName">
              <!-- <th mat-header-cell *matHeaderCellDef> brandName </th> -->
              <th class="header" mat-sort-header mat-header-cell *matHeaderCellDef>
                <mat-form-field class="filter">
                  <input  matInput (keydown.space)="$event.stopPropagation()" (click)="$event.stopPropagation()"
                  formControlName="mainProductName" />
                  <mat-placeholder>Main Product Name</mat-placeholder>
                </mat-form-field>
              </th>
              <td mat-cell *matCellDef="let element"> {{ element?.mainProductName }}</td>
            </ng-container>

            <!-- Product Code -->
            <ng-container matColumnDef="productCode">
              <!-- <th mat-header-cell *matHeaderCellDef> brandName </th> -->
              <th class="header" mat-sort-header mat-header-cell *matHeaderCellDef>
                <mat-form-field class="filter">
                  <input autocomplete="new-productCode" matInput (keydown.space)="$event.stopPropagation()" (click)="$event.stopPropagation()"
                  formControlName="productCode" />
                  <mat-placeholder>Product Code</mat-placeholder>
                </mat-form-field>
              </th>
              <td mat-cell *matCellDef="let element"> {{ element?.productCode }}</td>
            </ng-container>

            <!-- Symbol Column -->
            <ng-container matColumnDef="productDescription">
              <!-- <th mat-header-cell *matHeaderCellDef> category </th> -->
              <th class="header" mat-sort-header fxFlex="200" mat-header-cell *matHeaderCellDef>
                <mat-form-field class="filter">
                  <input autocomplete="new-description" matInput (keydown.space)="$event.stopPropagation()" (click)="$event.stopPropagation()"
                  formControlName="description" />
                  <mat-placeholder>Description</mat-placeholder>
                </mat-form-field>
              </th>
              <td fxFlex="200" mat-cell *matCellDef="let element"> <span class="ellipsis .cut-text">
                  {{ element?.productDescription }} </span>
              </td>
            </ng-container>

            <ng-container matColumnDef="hcpcCode">
              <!-- <th mat-header-cell *matHeaderCellDef> Account Number </th> -->
              <th class="header  w-175" mat-sort-header mat-header-cell *matHeaderCellDef>
                <mat-form-field class="filter">
                  <input autocomplete="new-hcpcCode" matInput (keydown.space)="$event.stopPropagation()" (click)="$event.stopPropagation()"
                  formControlName="hcpcCode" />
                  <mat-placeholder>hcpcCode</mat-placeholder>
                </mat-form-field>
              </th>
              <td mat-cell *matCellDef="let element">{{ element?.hcpcCode }}</td>
            </ng-container>

            <ng-container matColumnDef="createdDate">
              <th class="header" mat-sort-header mat-header-cell *matHeaderCellDef >
                <mat-form-field class="filter">
                  <mat-label> Created Date</mat-label>
                  <input autocomplete="new-createdDate" matInput (keydown.space)="$event.stopPropagation()" (click)="$event.stopPropagation()"
                  formControlName="createdDate" type="text" placeholder="(MM/DD/YYYY)" />
                  <mat-error
                    *ngIf=" supplyProductForm?.get('createdDate')?.touched && supplyProductForm?.get('createdDate')?.errors?.dateVaidator">
                    Enter Valid date
                  </mat-error>
                </mat-form-field>
              </th>
              <td mat-cell *matCellDef="let element">
                <ng-container>
                  {{ element?.createdDate }}
                </ng-container>
              </td>
            </ng-container>

            <ng-container matColumnDef="stockStatus">
              <th class="header" mat-header-cell *matHeaderCellDef mat-sort-header>
                <mat-form-field class="filter">
                  <input autocomplete="new-productStatus" matInput (keydown.space)="$event.stopPropagation()" (click)="$event.stopPropagation()"
                  formControlName="productStatus" />
                  <mat-placeholder>Product Status</mat-placeholder>
                </mat-form-field>
              <td mat-cell *matCellDef="let element">{{ element?.stockStatus }}</td>
            </ng-container>

            <ng-container matColumnDef="minimumQuantity">
              <th class="header" mat-sort-header mat-header-cell *matHeaderCellDef >
                <mat-form-field class="filter">
                  <input autocomplete="new-minQty" matInput (keydown.space)="$event.stopPropagation()" (click)="$event.stopPropagation()"
                  formControlName="minQty" />
                  <mat-placeholder>Minimum Units</mat-placeholder>
                </mat-form-field>
              <td mat-cell *matCellDef="let element">{{ element?.minimumQuantity }}</td>
            </ng-container>

            <ng-container matColumnDef="availableQuantity">
              <th class="header" mat-sort-header mat-header-cell *matHeaderCellDef >
                <mat-form-field class="filter">
                  <input autocomplete="new-avaiQty" matInput (keydown.space)="$event.stopPropagation()" (click)="$event.stopPropagation()"
                  formControlName="avaiQty" />
                  <mat-placeholder>Available Units</mat-placeholder>
                </mat-form-field>
              <td mat-cell *matCellDef="let element">{{ element?.availableQuantity }}</td>
            </ng-container>


            <ng-container matColumnDef="status">
              <th class="header"  mat-header-cell *matHeaderCellDef >
                <mat-form-field class="filter">
                  <input autocomplete="new-status" matInput (keydown.space)="$event.stopPropagation()" (click)="$event.stopPropagation()"
                  formControlName="status" />
                  <mat-placeholder>Status</mat-placeholder>
                </mat-form-field>
              </th>
              <td mat-cell *matCellDef="let data">
                <ng-container *ngIf="data?.resupplyProductStatus==='Active'">
                  <mat-slide-toggle (change)="statusChanged($event, data)" [checked]='true' class="ml-2">
                    {{data?.resupplyProductStatus}}</mat-slide-toggle>
                </ng-container>
                <ng-container *ngIf="data?.resupplyProductStatus==='Inactive'">
                  <mat-slide-toggle (change)="statusChanged($event, data)" [checked]='false' class="ml-2">
                    {{data?.resupplyProductStatus}}</mat-slide-toggle>
                </ng-container>
              </td>
            </ng-container>

            <ng-container matColumnDef="Options">
              <th mat-header-cell *matHeaderCellDef >
                Options
              </th>
              <td mat-cell *matCellDef="let data">
                <a *ngIf="data?.status==='Active'" (click)="getBySupplyProductById(data)"class="editBtnColor">
                  <i class="fa fa-pencil pointer" matTooltip="Click To Edit"(click)="toggleV2()"
                    ></i>
                </a> &nbsp;
                <!-- Viiw Allowance -->
                  <a  class="editBtnColor" (click)="ViewAddSupplyModalProduct(data)">
                      <!-- {{optionStatus}} --><mat-icon class="pointer" matTooltip="Click To Add Allowables"  >add_box</mat-icon>
                    </a>
                <a [hidden]="true" class="deleteBtnColor">
                  <i class="fa fa-trash pointer" matTooltip="Click To Delete" (click)="DeleteSupplyProduct(data)"
                    ></i>
                </a>
              </td>
            </ng-container>

            <ng-container matColumnDef="noRecords">
              <td class="text-align-center" mat-footer-cell *matFooterCellDef colspan="9">
                <div *ngIf="!isProgress">
                  {{"No Records Found"}}
                </div>
                <div [hidden]="!isProgress">
                  <mat-spinner [diameter]="220"></mat-spinner>
                </div>

              </td>
            </ng-container>
            <ng-container matColumnDef="paginator">
              <td mat-footer-cell *matFooterCellDef colspan="9">
                <mat-paginator class="table table-responsive" #paginator [length]="dataSource?.data?.length"
                  [pageSize]="10" [pageIndex]="0" showFirstLastButtons [pageSizeOptions]="[5, 10, 25, 100]">
                </mat-paginator>
              </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="arrDisplayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: arrDisplayedColumns"></tr>
            <tr mat-footer-row *matFooterRowDef="['noRecords']"
              [ngClass]="{ active: dataSource && dataSource.data && dataSource?.data?.length !== 0 }"></tr>
            <tr mat-footer-row *matFooterRowDef="['paginator']"></tr>
          </table>
        </div>
      </form>


  </div>
      </div>
      </div></div>
