import { Component, OnInit } from '@angular/core';
import { PatientAuthorizationService } from 'projects/patient/src/app/patient-proxy/patient-optimization/patient-authorization.service';
import { CommonService } from 'projects/shared/src/app/services/common.service';
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-patient-par-report',
  templateUrl: './patient-par-report.component.html',
  styleUrls: ['./patient-par-report.component.scss']
})
export class PatientParReportComponent implements OnInit {
  isReportLoading:boolean=false;
  selectedOption: number = 1;
  currentPage: any = 1;
  totalPages: any = 1;
  pageOffset: any = 50;
  recordsPerPage:number = 50;
  overallTableCount:number = 0;
  paginationInfo:any;
  parReportTableDataSubscription:Subscription;
  parreportTableData:any;
  private destroyPARFilter$ = new Subject<void>();
  constructor(
    private patientAuthorizationservices : PatientAuthorizationService,
    private commonService : CommonService
  ) { }

  ngOnInit() {
    this.getPARFilterList();
  }
  ngOnDestroy(): void {
    this.destroyPARFilter$.next();
    this.destroyPARFilter$.complete();
  }
  getPARFilterListChangeTab(){
    this.currentPage = 1;
    this.getPARFilterList();
  }
  getPARFilterList() {
    this.isReportLoading = true;
    if (this.parReportTableDataSubscription) {
        this.parReportTableDataSubscription.unsubscribe();
    }
    const pageNo = this.currentPage;
    const pageOffset = this.pageOffset

    this.parReportTableDataSubscription = this.patientAuthorizationservices.getParReportByShortcodeAndPageNoAndPageOffset(this.selectedOption,pageNo,pageOffset)
    .pipe(takeUntil(this.destroyPARFilter$))
    .subscribe(
        (response) => {
            this.totalPages = Math.ceil(response?.totalCount / this.pageOffset);
            this.overallTableCount = response?.totalCount || 0;
            this.paginationInfo = {
                startRecord: (this.currentPage - 1) * this.recordsPerPage + 1,
                endRecord: Math.min(this.currentPage * this.recordsPerPage, this.overallTableCount),
                overallTableCount : this.overallTableCount
            };
            this.parreportTableData = response?.parDetailsReport ?? [];
            this.parreportTableData = this.parreportTableData.map((parReport) => {
                return {
                    ...parReport,
                    authEndDate: this.commonService.getFormattedDateZone(parReport?.authEndDate),
                    dob: this.commonService.formatDateDob(parReport?.dob),
                };  
            });
            this.isReportLoading = false;
        },
        (err) => {
            this.isReportLoading = false;
        }
    );
}
  
  changePage(pageNo: number) {
    if (pageNo != this.currentPage && pageNo > 0 && pageNo <= this.totalPages) {
      this.currentPage = pageNo;
      this.getPARFilterList();
    }
  }

  // parreportTableData = [
  //   {
  //     chartId: 'C001',
  //     patientId: 'P001',
  //     patientName: 'John Doe',
  //     dob:  '1980-01-01',
  //     practitionerName: 'Dr. Smith',
  //     csrName: 'CSR1',
  //     hcpccode: 'H001',
  //     authExpiryDate:  '2023-12-31',
  //     expiryDays: -30,
  //     status: 'Expired',
  //   },
  //   {
  //     chartId: 'C002',
  //     patientId: 'P002',
  //     patientName: 'Jane Doe',
  //     dob:  '1990-02-02',
  //     practitionerName: 'Dr. Brown',
  //     csrName: 'CSR2',
  //     hcpccode: 'H002',
  //     authExpiryDate:  '2023-11-30',
  //     expiryDays: -26,
  //     status: 'Expired'
  //   },
  //   {
  //     chartId: 'C003',
  //     patientId: 'P003',
  //     patientName: 'Alice Smith',
  //     dob:  '1985-03-03',
  //     practitionerName: 'Dr. Johnson',
  //     csrName: 'CSR3',
  //     hcpccode: 'H003',
  //     authExpiryDate:  '2023-10-31',
  //     expiryDays: -20,
  //     status: 'Expired'
  //   },
  //   {
  //     chartId: 'C004',
  //     patientId: 'P004',
  //     patientName: 'Bob Johnson',
  //     dob:  '1975-04-04',
  //     practitionerName: 'Dr. Lee',
  //     csrName: 'CSR4',
  //     hcpccode: 'H004',
  //     authExpiryDate:  '2023-09-30',
  //     expiryDays: -18,
  //     status: 'Expired'
  //   },
  //   {
  //     chartId: 'C005',
  //     patientId: 'P005',
  //     patientName: 'Charlie Brown',
  //     dob:  '1965-05-05',
  //     practitionerName: 'Dr. White',
  //     csrName: 'CSR5',
  //     hcpccode: 'H005',
  //     authExpiryDate:  '2023-08-31',
  //     expiryDays: -10,
  //     status: 'Expired'
  //   },
  //   {
  //     chartId: 'C006',
  //     patientId: 'P006',
  //     patientName: 'David Wilson',
  //     dob:  '1955-06-06',
  //     practitionerName: 'Dr. Green',
  //     csrName: 'CSR6',
  //     hcpccode: 'H006',
  //     authExpiryDate:  '2023-07-31',
  //     expiryDays: 0,
  //     status: 'Expired'
  //   },
  //   {
  //     chartId: 'C007',
  //     patientId: 'P007',
  //     patientName: 'Eve Davis',
  //     dob:  '1945-07-07',
  //     practitionerName: 'Dr. Black',
  //     csrName: 'CSR7',
  //     hcpccode: 'H007',
  //     authExpiryDate:  '2023-06-30',
  //     expiryDays: 2,
  //     status: 'Yet To Expired'
  //   },
  //   {
  //     chartId: 'C008',
  //     patientId: 'P008',
  //     patientName: 'Frank Miller',
  //     dob:  '1935-08-08',
  //     practitionerName: 'Dr. Gray',
  //     csrName: 'CSR8',
  //     hcpccode: 'H008',
  //     authExpiryDate:  '2023-05-31',
  //     expiryDays: 6,
  //     status: 'Yet To Expired'
  //   },
  //   {
  //     chartId: 'C009',
  //     patientId: 'P009',
  //     patientName: 'Grace Lee',
  //     dob:  '1925-09-09',
  //     practitionerName: 'Dr. Yellow',
  //     csrName: 'CSR9',
  //     hcpccode: 'H009',
  //     authExpiryDate:  '2023-04-30',
  //     expiryDays: 7,
  //     status: 'Yet To Expired'
  //   },
  //   {
  //     chartId: 'C010',
  //     patientId: 'P010',
  //     patientName: 'Hank Thomas',
  //     dob:  '1915-10-10',
  //     practitionerName: 'Dr. Purple',
  //     csrName: 'CSR10',
  //     hcpccode: 'H010',
  //     authExpiryDate:  '2023-03-31',
  //     expiryDays: 10,
  //     status: 'Yet To Expired'
  //   },
  //   {
  //     chartId: 'C0011',
  //     patientId: 'P007',
  //     patientName: 'Eve Davis',
  //     dob:  '1945-07-07',
  //     practitionerName: 'Dr. Black',
  //     csrName: 'CSR7',
  //     hcpccode: 'H007',
  //     authExpiryDate:  '2023-06-30',
  //     expiryDays: 15,
  //     status: 'Active'
  //   },
  //   {
  //     chartId: 'C0012',
  //     patientId: 'P004',
  //     patientName: 'Bob Johnson',
  //     dob:  '1975-04-04',
  //     practitionerName: 'Dr. Lee',
  //     csrName: 'CSR4',
  //     hcpccode: 'H004',
  //     authExpiryDate:  '2023-09-30',
  //     expiryDays: 28,
  //     status: 'Active'
  //   },
  // ];
}
