import { DOCUMENT } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, Renderer2 } from '@angular/core';
import { AngularFireMessaging } from '@angular/fire/messaging';
import { SafeScript } from '@angular/platform-browser';

import { paymentList } from 'projects/billing/src/app/edit-payment/edit-payment.component';
import { PayerDTO } from 'projects/patient/src/app/patient-proxy/patient/dto/models';
import { BehaviorSubject, Observable } from 'rxjs';
export type fontConfigType = { normalConfig: fontConfig; boldConfig: fontConfig; hConfig: { h1: fontConfig, h2: fontConfig, h3: fontConfig, h4: fontConfig, h5: fontConfig, h6: fontConfig } }

export interface fontConfig {
  font?: string;
}

@Injectable({
  providedIn: 'root'
})
export class TableService {
  // loadStyleConfig(): Observable<fontConfigType> {
  //   return this.HttpClient.get<fontConfigType>('../../assets/configs/font-conifgs.json');
  // }

  // setfontConfig(fontConfig: fontConfigType) {
  //   this.fontConfigData.next(fontConfig);
  // }

  currentMessage = new BehaviorSubject(null);
  crrtListMessage: BehaviorSubject<any[]> = new BehaviorSubject([]);



  public fontConfigData = new BehaviorSubject<fontConfigType>(null);
  private paymentSetupData = new BehaviorSubject<any>([]);
  datas
  getPaymentSetupList() {
    return this.paymentSetupData.asObservable();
  }

  setPaymentSetupList(arrSelected: any[]) {
    this.paymentSetupData.next(arrSelected);
  }

  private SelectedDocumentListData = new BehaviorSubject<any>([]);
  getSelectedDocumentList(): Observable<any> {
    return this.SelectedDocumentListData.asObservable();
  }
  setSelectedDocumentList(arrSelectedDocuments: any[]) {
    this.SelectedDocumentListData.next(arrSelectedDocuments);
  }
  clearSelectedDocumentList() {
    this.SelectedDocumentListData.next(null);
  }
  private documentFormData = new BehaviorSubject<any>(null);
  getDocumentFormData(): Observable<any> {
    return this.documentFormData.asObservable();
  }
  setDocumentFormData(data: any) {
    this.documentFormData.next(data);
  }
  clearDocumentFormData() {
    this.documentFormData.next(null);
  }
  setEditPaymentDataTable(tableDummyData: paymentList[]) {
    throw new Error('Method not implemented.');
  }

  getIcd10Codes() {
    return this.icd10Data.asObservable();

  }
  private calerderData= new BehaviorSubject<any>("");
  setCalenderPopup(data: any[]){
    this.calerderData.next(data)
  }

  getCalenderPopup(): Observable<any[]> {
    return this.calerderData.asObservable();
  }


  private daySheetdata = new BehaviorSubject<any>("");
  getSheetListData(): Observable<any[]> {
    return this.daySheetdata.asObservable();
  }
  setdaySheetTable(daySheetdata: any[]) {
    this.daySheetdata.next(daySheetdata);
  }
  clearDaySheetData() {
    this.daySheetdata.next([]);
  }
  private shippingData = new BehaviorSubject<any>([]);

  getShippingApprovalStatus() {
    return this.shippingData.asObservable();
  }
  setShippingApprovalStatus(response: any[]) {
    this.shippingData.next(response);
  }

  private requisitionData = new BehaviorSubject<any>([]);
  getRequisitionForm(){
    return this.requisitionData.asObservable();
  }
  setRequisitionForm(response: any[]){
    this.requisitionData.next(response);
  }


  private claimProcessListData = new BehaviorSubject<any>([]);
  getClaimProcessListData() {
    return this.claimProcessListData.asObservable();
  }
  setClaimProcessListData(response: any[]) {
    this.claimProcessListData.next(response);
  }
  getClaimStatusListData() {
    return this.claimStatusListData.asObservable();
  }

  setClaimStatusListData(response: any[]) {
    this.claimStatusListData.next(response);

  }
  private claimStatusListData = new BehaviorSubject<any>([]);


  // private patientOrderStatus = new BehaviorSubject<{ id: string, Order: string, Verification: string, Authorization: string }[]>([]);

  getpatientOrderStatusTable() {
    return this.patientOrderStatus.asObservable();
  }
  setpatientOrderStatusTable(response: { id: string, Order: string, Verification: string, Authorization: string }[]) {
    this.patientOrderStatus.next(response);
  }


  private icd9Data = new BehaviorSubject<any[]>([]);
  private icd10Data = new BehaviorSubject<any[]>([]);

  getIcd9Codes() {
    return this.icd9Data.asObservable();
  }
  setICD10Table(response: any[]) {
    this.icd10Data.next(response);
  }
  clearICD10Table() {
    this.icd10Data.next([]);

  }

  clearICD9Table() {
    this.icd9Data.next([]);

  }
  setICD9Table(response: any[]) {
    this.icd9Data.next(response);
  }

  getPayorCodeList() {
    return this.payorList.asObservable();
  }

  setPayorCodeList(payerTableData: PayerDTO[]) {
    this.payorList.next(payerTableData);
  }
  private payorList = new BehaviorSubject<any>([]);
  private tenantId = new BehaviorSubject<any>(null);

  getTenantId() {
    return this.tenantId.asObservable();
  }
  setTenantId(response: string) {
    this.tenantId.next(response);
  }

  private roleName = new BehaviorSubject<any>('');

  setRoleName(response: string) {
    this.roleName.next(response);
  }
  getRoleName() {
    return this.roleName.asObservable();
  }



  private organizationUnitId = new BehaviorSubject<any>('');

  setOrganizationUnitId(organizationUnitId: string) {
    this.organizationUnitId.next(organizationUnitId);
  }
  getOrganizationUnitId() {
    return this.organizationUnitId.asObservable();
  }

  private loginUserId = new BehaviorSubject<any>('');
  setLoginUserId(loginUserId: string) {
    this.loginUserId.next(loginUserId);
  }
  getLoginUserId() {
    return this.loginUserId.asObservable();
  }
  private loginUserName = new BehaviorSubject<any>('');

  setLoginUserName(loginUserName: string) {
    this.loginUserName.next(loginUserName);
  }
  getLoginUserName() {
    return this.loginUserName.asObservable();
  }

  private rolesCreate = new BehaviorSubject<boolean>(true);

  setRolesCreate(rolesCreate: boolean) {
    this.rolesCreate.next(rolesCreate);
  }
  getRolesCreate() {
    return this.rolesCreate.asObservable();
  }

  private isPatientLocked = new BehaviorSubject<boolean>(true);
  setPatientLockStatus(isPatientLocked: boolean) {
    this.isPatientLocked.next(isPatientLocked);
  }

  getPatientLockStatus() {
    return this.isPatientLocked.asObservable();
  }

  clearPatientLockStatus() {
    this.isPatientLocked.next(true);
  }

  private isAdminLogin = new BehaviorSubject<boolean>(false);
  setAdminLogin(isAdminLogin: true) {
    this.isAdminLogin.next(isAdminLogin);
  }
  getAdminLogin() {
    return this.isAdminLogin.asObservable();
  }

  private isClientLogin = new BehaviorSubject<boolean>(false);

  setClientLogin(isClientLogin: true) {
    this.isClientLogin.next(isClientLogin);
  }
  getClientLogin() {
    return this.isClientLogin.asObservable();
  }



  private rolesView = new BehaviorSubject<boolean>(true);

  setRolesView(rolesView: boolean) {
    this.rolesView.next(rolesView);
  }
  getRolesView() {
    return this.rolesView.asObservable();
  }

  private rolesPermission = new BehaviorSubject<boolean>(true);

  setRolesManagePermission(rolesPermission: boolean) {
    this.rolesPermission.next(rolesPermission);
  }
  getRolesManagePermission() {
    return this.rolesPermission.asObservable();
  }


  private rolesUpdate = new BehaviorSubject<boolean>(true);

  setRolesUpdate(rolesUpdate: boolean) {
    this.rolesUpdate.next(rolesUpdate);
  }
  getRolesUpdate() {
    return this.rolesUpdate.asObservable();
  }

  private rolesDelete = new BehaviorSubject<boolean>(true);

  setRolesDelete(rolesDelete: boolean) {
    this.rolesDelete.next(rolesDelete);
  }
  getRolesDelete() {
    return this.rolesDelete.asObservable();
  }



  private tenantCreate = new BehaviorSubject<boolean>(true);

  setTenantCreate(tenantCreate: boolean) {
    this.tenantCreate.next(tenantCreate);
  }
  getTenantCreate() {
    return this.tenantCreate.asObservable();
  }


  private tenantUpdate = new BehaviorSubject<boolean>(true);

  setTenantUpdate(tenantUpdate: boolean) {
    this.tenantUpdate.next(tenantUpdate);
  }
  getTenantUpdate() {
    return this.tenantUpdate.asObservable();
  }

  private tenantView = new BehaviorSubject<boolean>(true);

  setTenantView(tenantView: boolean) {
    this.tenantView.next(tenantView);
  }
  getTenantView() {
    return this.tenantView.asObservable();
  }

  private tenantDelete = new BehaviorSubject<boolean>(true);

  setTenantDelete(tenantDelete: boolean) {
    this.tenantDelete.next(tenantDelete);
  }
  getTenantDelete() {
    return this.tenantDelete.asObservable();
  }

  // ! branch
  private branchCreate = new BehaviorSubject<boolean>(true);

  setBranchCreate(branchCreate: boolean) {
    this.branchCreate.next(branchCreate);
  }
  gettBranchCreate() {
    return this.branchCreate.asObservable();
  }


  private branchUpdate = new BehaviorSubject<boolean>(true);

  setBranchUpdate(branchUpdate: boolean) {
    this.branchUpdate.next(branchUpdate);
  }
  getBranchUpdate() {
    return this.branchUpdate.asObservable();
  }

  private branchView = new BehaviorSubject<boolean>(true);

  setBranchView(branchView: boolean) {
    this.branchView.next(branchView);
  }
  getBranchView() {
    return this.branchView.asObservable();
  }

  private branchDelete = new BehaviorSubject<boolean>(true);

  setBranchDelete(branchDelete: boolean) {
    this.branchDelete.next(branchDelete);
  }
  getBranchDelete() {
    return this.branchDelete.asObservable();
  }

  // ! USER
  private usersDelete = new BehaviorSubject<boolean>(true);

  setUsersDelete(usersDelete: boolean) {
    this.usersDelete.next(usersDelete);
  }
  getUsersDelete() {
    return this.usersDelete.asObservable();
  }

  private usersCreate = new BehaviorSubject<boolean>(true);

  setUsersCreate(usersCreate: boolean) {
    this.usersCreate.next(usersCreate);
  }
  getUsersCreate() {
    return this.usersCreate.asObservable();
  }


  private fileUploadUpdatePermissons = new BehaviorSubject<boolean>(true);

  setFileUploadUpdate(fUploadPermissona: boolean) {
    this.fileUploadUpdatePermissons.next(fUploadPermissona);
  }
  getfileUploadUpdate() {
    return this.fileUploadUpdatePermissons.asObservable();
  }

  private fileUploadDeletePermissons = new BehaviorSubject<boolean>(true);

  setFileUploadDelete(fUploadPermissona: boolean) {
    this.fileUploadDeletePermissons.next(fUploadPermissona);
  }
  getfileUploadDelete() {
    return this.fileUploadDeletePermissons.asObservable();
  }


  private fileUploadCreatePermissons = new BehaviorSubject<boolean>(true);

  setFileUploadCreate(fUploadPermissona: boolean) {
    this.fileUploadCreatePermissons.next(fUploadPermissona);
  }
  getfileUploadCreate() {
    return this.fileUploadCreatePermissons.asObservable();
  }

  private fileUploadViewPermissons = new BehaviorSubject<boolean>(true);

  setFileUploadView(fUploadPermissona: boolean) {
    this.fileUploadViewPermissons.next(fUploadPermissona);
  }
  getfileUploadView() {
    return this.fileUploadViewPermissons.asObservable();
  }



  private usersView = new BehaviorSubject<boolean>(true);

  setUsersView(usersView: boolean) {
    this.usersView.next(usersView);
  }
  getUsersView() {
    return this.usersView.asObservable();
  }

  private usersUpdate = new BehaviorSubject<boolean>(true);

  setUsersUpdate(usersUpdate: boolean) {
    this.usersUpdate.next(usersUpdate);
  }
  getUsersUpdate() {
    return this.usersUpdate.asObservable();
  }


  private patientUpdate = new BehaviorSubject<boolean>(true);

  setPatientUpdate(patientUpdate: boolean) {
    this.patientUpdate.next(patientUpdate);
  }
  getPatientUpdate() {
    return this.patientUpdate.asObservable();
  }

  private planUpdate = new BehaviorSubject<boolean>(true);

  setPlanUpdate(planUpdate: boolean) {
    this.planUpdate.next(planUpdate);
  }
  getPlanUpdate() {
    return this.planUpdate.asObservable();
  }

  private patientCreate = new BehaviorSubject<boolean>(true);

  setPatientCreate(patientCreate: boolean) {
    this.patientCreate.next(patientCreate);
  }
  getPatientCreate() {
    return this.patientCreate.asObservable();
  }

  private planCreate = new BehaviorSubject<boolean>(true);

  setPlanCreate(planCreate: boolean) {
    this.planCreate.next(planCreate);
  }
  getPlanCreate() {
    return this.planCreate.asObservable();
  }

  private patientView = new BehaviorSubject<boolean>(true);

  setPatientView(patientView: boolean) {
    this.patientView.next(patientView);
  }
  getPatientView() {
    return this.patientView.asObservable();
  }



  // !client login function
  private clientLogin = new BehaviorSubject<any>(null);
  getclientLoginView(): Observable<any> {

    return this.clientLogin.asObservable();
  }
  setclientLoginView(clientLogin: any) {

    this.clientLogin.next(clientLogin);
  }


  private planView = new BehaviorSubject<boolean>(true);

  setPlanView(planView: boolean) {
    this.planView.next(planView);
  }
  getPlanView() {
    return this.planView.asObservable();
  }

  private patientDelete = new BehaviorSubject<boolean>(true);

  setPatientDelete(patientDelete: boolean) {
    this.patientDelete.next(patientDelete);
  }
  getPatientDelete() {
    return this.patientDelete.asObservable();
  }

  private planDelete = new BehaviorSubject<boolean>(true);

  setPlanDelete(planDelete: boolean) {
    this.planDelete.next(planDelete);
  }
  getPlanDelete() {
    return this.planDelete.asObservable();
  }

  private documentDelete = new BehaviorSubject<boolean>(true);

  setDocumentDelete(patientDelete: boolean) {
    this.documentDelete.next(patientDelete);
  }
  getDocumentDelete() {
    return this.documentDelete.asObservable();
  }


  // Order

  private orderUpdate = new BehaviorSubject<boolean>(true);

  setOrderUpdate(orderUpdate: boolean) {
    this.orderUpdate.next(orderUpdate);
  }
  getOrderUpdate() {
    return this.orderUpdate.asObservable();
  }

  private orderCreate = new BehaviorSubject<boolean>(true);

  setOrderCreate(orderCreate: boolean) {
    this.orderCreate.next(orderCreate);
  }
  getOrderCreate() {
    return this.orderCreate.asObservable();
  }

  private orderView = new BehaviorSubject<boolean>(true);

  setOrderView(orderView: boolean) {
    this.orderView.next(orderView);
  }
  getOrderView() {
    return this.orderView.asObservable();
  }

  private orderDelete = new BehaviorSubject<boolean>(true);

  setOrderDelete(orderDelete: boolean) {
    this.orderDelete.next(orderDelete);
  }
  getOrderDelete() {
    return this.orderDelete.asObservable();
  }


  // Item

  private itemUpdate = new BehaviorSubject<boolean>(true);

  setItemUpdate(itemUpdate: boolean) {
    this.itemUpdate.next(itemUpdate);
  }
  getItemUpdate() {
    return this.itemUpdate.asObservable();
  }

  private itemCreate = new BehaviorSubject<boolean>(true);

  setItemCreate(itemCreate: boolean) {
    this.itemCreate.next(itemCreate);
  }
  getItemCreate() {
    return this.itemCreate.asObservable();
  }

  private itemView = new BehaviorSubject<boolean>(true);

  setItemView(itemView: boolean) {
    this.itemView.next(itemView);
  }
  getItemView() {
    return this.itemView.asObservable();
  }

  private itemDelete = new BehaviorSubject<boolean>(true);

  setItemDelete(itemDelete: boolean) {
    this.itemDelete.next(itemDelete);
  }
  getItemDelete() {
    return this.itemDelete.asObservable();
  }

  // Insurance

  private insuranceUpdate = new BehaviorSubject<boolean>(true);

  setInsuranceUpdate(insuranceUpdate: boolean) {
    this.insuranceUpdate.next(insuranceUpdate);
  }
  getInsuranceUpdate() {
    return this.insuranceUpdate.asObservable();
  }

  private insuranceCreate = new BehaviorSubject<boolean>(true);

  setInsuranceCreate(insuranceCreate: boolean) {
    this.insuranceCreate.next(insuranceCreate);
  }
  getInsuranceCreate() {
    return this.insuranceCreate.asObservable();
  }

  private insuranceView = new BehaviorSubject<boolean>(true);

  setInsuranceView(insuranceView: boolean) {
    this.insuranceView.next(insuranceView);
  }
  getInsuranceView() {
    return this.insuranceView.asObservable();
  }

  private insuranceDelete = new BehaviorSubject<boolean>(true);

  setInsuranceDelete(insuranceDelete: boolean) {
    this.insuranceDelete.next(insuranceDelete);
  }
  getInsuranceDelete() {
    return this.insuranceDelete.asObservable();
  }

  // Notes
  private inventoryNotes = new BehaviorSubject<any>("");

  setNotesTable(response: any[]) {
    return this.inventoryNotes.next(response);
  }
  private notesUpdate = new BehaviorSubject<boolean>(true);

  setNotesUpdate(notesUpdate: boolean) {
    this.notesUpdate.next(notesUpdate);
  }
  getNotesUpdate() {
    return this.notesUpdate.asObservable();
  }


  private notesCreate = new BehaviorSubject<boolean>(true);

  setNotesCreate(notesCreate: boolean) {
    this.notesCreate.next(notesCreate);
  }
  getNotesCreate() {
    return this.notesCreate.asObservable();
  }

  private notesView = new BehaviorSubject<boolean>(true);

  setNotesView(notesView: boolean) {
    this.notesView.next(notesView);
  }
  getNotesView() {
    return this.notesView.asObservable();
  }

  private notesDelete = new BehaviorSubject<boolean>(true);

  setNotesDelete(notesDelete: boolean) {
    this.notesDelete.next(notesDelete);
  }
  getNotesDelete() {
    return this.notesDelete.asObservable();
  }

  private navBarData = new BehaviorSubject<string>("");

  setNavBarData(navBarData: string) {
    this.navBarData.next(navBarData);
  }
  getNavBarData() {
    return this.navBarData.asObservable();
  }


  requestPermission() {
    this.angularFireMessaging.requestToken.subscribe(
      (token) => {
        console.info(token);
      },
      (err) => {
        console.info('Unable to get permission to notify.', err);
      }
    );
    this.angularFireMessaging.getToken.subscribe(
      (token) => {
        console.info(token);
      },
      (err) => {
        console.info('Unable to get permission to notify.', err);
      }
    );

  }
  receiveMessage() {
    this.angularFireMessaging.messages.subscribe((message) => {
    });
    this.angularFireMessaging.messages.subscribe(
      (payload) => {

        const values = [{ ...payload }];
        this.crrtListMessage.next(values);
        this.currentMessage.next(payload);
        // const NotificationOptions = {
        //   body: payload.notification.body,
        //   data: payload.data,
        //   icon: payload.notification.icon
        // }
        // navigator.serviceWorker.getRegistration('/firebase-cloud-messaging-push-scope').then(registration => {
        //   registration.showNotification(payload.notification.title, NotificationOptions);
        // });
      }, err => {


      })
  }

  constructor(private HttpClient: HttpClient,

    private angularFireMessaging: AngularFireMessaging,
  ) {
    // this.angularFireMessaging.messaging.subscribe(
    //   (_messaging) => {
    //     _messaging.onMessage = _messaging.onMessage.bind(_messaging);
    //     _messaging.onTokenRefresh = _messaging.onTokenRefresh.bind(_messaging);
    //     this.currentMessage.next(_messaging);

    //   },
    //   (err) => {
    //     console.warn('Unable to get permission to notify.', err);
    //   })

    this.angularFireMessaging.messages.subscribe(e => {
    })

    this.isPatientLocked.next(true);
    this.tenantId.next(null)
    this.roleName.next("");
    this.organizationUnitId.next("");
    this.loginUserName.next("");

    this.rolesCreate.next(true);
    this.rolesView.next(true);
    this.rolesUpdate.next(true);
    this.rolesDelete.next(true);

    this.tenantCreate.next(true);
    this.tenantView.next(true);
    this.tenantUpdate.next(true);
    this.tenantDelete.next(true);

    this.usersCreate.next(true);
    this.usersView.next(true);
    this.usersUpdate.next(true);
    this.usersDelete.next(true);

    this.patientCreate.next(true);
    this.patientView.next(true);
    this.patientUpdate.next(true);
    this.patientDelete.next(true);

    this.planCreate.next(true);
    this.planView.next(true);
    this.planUpdate.next(true);
    this.planDelete.next(true);

    this.orderCreate.next(true);
    this.orderView.next(true);
    this.orderUpdate.next(true);
    this.orderDelete.next(true);

    this.itemCreate.next(true);
    this.itemView.next(true);
    this.itemUpdate.next(true);
    this.itemDelete.next(true);

    this.insuranceCreate.next(true);
    this.insuranceView.next(true);
    this.insuranceUpdate.next(true);
    this.insuranceDelete.next(true);

    this.notesCreate.next(true);
    this.notesView.next(true);
    this.notesUpdate.next(true);
    this.notesDelete.next(true);
    this.navBarData.next("");




    this.policyData.next([]);
    this.doctorData.next("");
    this.headerData.next("");
    this.npiDoctorData.next([]);
    this.icd9Data.next([]);
    this.icd10Data.next([]);
    this.patientOrderStatus.next([]);
    this.tenantsData.next([]);
    this.deactivatedTenantsData.next([]);
    this.plansTableData.next([]);
    this.usersData.next([]);
    this.rolesData.next([]);
    this.organizationData.next([]);


    this.orderData.next([]);
    this.ordersData.next([]);
    this.verificationData.next([]);
    this.doctorData.next("");
    this.headerData.next("");
    this.inventoryData.next([]);
    this.patientNotes.next([]);
    this.pateintReminders.next([]);
    this.patientInsuranceAuthorization.next([]);
    this.patientOrder.next([]);
    this.billingListData.next([]);
    this.billingHistoryData.next([]);
    // this.authData.next([]);
    this.claimHistoryData.next([]);

    this.coverageSummaryData.next([]);
    this.deductibleBenefitData.next([]);
    this.coBenefitData.next([]);
    this.countryPolicyData.next([]);
    this.inviteUserData.next([]);

  }
  private policyData = new BehaviorSubject<any[]>([]);
  getPolicyData(): Observable<any[]> {
    return this.policyData.asObservable();
  }
  setPolicyTable(policyData: any[]) {
    this.policyData.next(policyData);
  }
  clearPolicyData() {
    this.policyData.next([]);
  }


  private practiceData = new BehaviorSubject<any[]>([]);
  getPracticeData(): Observable<any[]> {
    return this.practiceData.asObservable();
  }
  setPracticeTable(practiceData: any[]) {
    this.practiceData.next(practiceData);
  }
  clearPracticeData() {
    this.practiceData.next([]);
  }

  // private doctorData = new BehaviorSubject<any>("");
  // getDoctorData(): Observable<any> {   getPracticeData
  //   return this.doctorData.asObservable();


  // }
  // setDoctorTable(doctorData: any) {
  //   this.doctorData.next(doctorData);
  // }
  // clearDoctorData() {
  //   this.doctorData.next("");
  // }



  private npiDoctorData = new BehaviorSubject<any[]>([]);
  getNpiDoctorData(): Observable<any[]> {
    return this.npiDoctorData.asObservable();
  }
  setNpiDoctorTable(npiDoctorData: any[]) {
    this.npiDoctorData.next(npiDoctorData);
  }
  clearNpiDoctorData() {
    this.npiDoctorData.next([]);
  }


  private headerData = new BehaviorSubject<any>("");
  getheaderData(): Observable<any> {
    return this.headerData.asObservable();


  }
  setheaderTable(headerData: any) {
    this.headerData.next(headerData);
  }


  // for billing sidebar
  private BillingData = new BehaviorSubject<any>("");
  getBillingData(): Observable<any> {
    return this.BillingData.asObservable();


  }
  setBillingData(BillingData: any) {
    this.BillingData.next(BillingData);
  }
  // country name and state set//
  private countryData = new BehaviorSubject<any>("");
  getCountryTable(): Observable<any> {
    return this.countryData.asObservable();

  }
  setCountryTable(headerData: any) {
    this.countryData.next(headerData);
  }

  // doctor
  private countryDoctorData = new BehaviorSubject<any>("");
  getCountryDoctorTable(): Observable<any> {
    return this.countryDoctorData.asObservable();

  }
  setCountryDoctorTable(countryDoctorData: any) {
    this.countryDoctorData.next(countryDoctorData);
  }

  // policy
  private countryPolicyData = new BehaviorSubject<any>([]);
  getCountryPolicyTable(): Observable<any> {
    return this.countryPolicyData.asObservable();

  }
  setCountryPolicyTable(countryPolicyData: any) {
    this.countryPolicyData.next(countryPolicyData);

  }

  private contactCountryData = new BehaviorSubject<any>("");
  getOrderCountryTable(): Observable<any> {
    return this.contactCountryData.asObservable();

  }
  setOrderCountryTable(contactCountryData: any) {
    this.contactCountryData.next(contactCountryData);

  }



  private tenantsData = new BehaviorSubject<any>("");
  getTenantData(): Observable<any[]> {
    return this.tenantsData.asObservable();
  }
  setTenantTable(tenantsData: any[]) {
    this.tenantsData.next(tenantsData);
  }
  clearTenantData() {
    this.tenantsData.next([]);
  }

  private deactivatedTenantsData = new BehaviorSubject<any>("");
  getDeactivatedTenantData(): Observable<any[]> {
    return this.deactivatedTenantsData.asObservable();
  }
  setDeactivatedTenantTable(deactivatedTenantsData: any[]) {
    this.deactivatedTenantsData.next(deactivatedTenantsData);
  }
  clearDeactivatedTenantData() {
    this.deactivatedTenantsData.next([]);
  }

  private plansTableData = new BehaviorSubject<any>("");
  setPlansTabledata(plansTableData: any[]) {
    this.plansTableData.next(plansTableData);
  }
  clearPlansTabledata() {
    this.plansTableData.next([]);
  }
  getPlansTabledata(): Observable<any[]> {
    return this.plansTableData.asObservable();
  }


  private usersData = new BehaviorSubject<any>("");
  getUsersData(): Observable<any[]> {
    return this.usersData.asObservable();
  }
  setUsersTable(usersData: any[]) {
    this.usersData.next(usersData);
  }
  clearUsersData() {
    this.usersData.next([]);
  }

  private rolesData = new BehaviorSubject<any>("");
  getRolesData(): Observable<any[]> {
    return this.rolesData.asObservable();
  }
  setRolesTable(rolesData: any[]) {
    this.rolesData.next(rolesData);
  }
  clearRolesData() {
    this.rolesData.next([]);
  }

  private organizationData = new BehaviorSubject<any>("");
  getOrganizationData(): Observable<any[]> {
    return this.organizationData.asObservable();
  }
  setOrganizationTable(organizationData: any[]) {
    this.organizationData.next(organizationData);
  }
  clearOrganizationData() {
    this.organizationData.next([]);
  }
  //  Saleorder  //
  private patientHistoryData = new BehaviorSubject<any>([]);

  getPatientHistoryDataTable() {
    return this.patientHistoryData.asObservable();
  }
  setPatientHistoryDataTable(response: any[]) {
    this.patientHistoryData.next(response);
  }


  private transactionData = new BehaviorSubject<any>("");
  getTransactionData(): Observable<any[]> {
    return this.transactionData.asObservable();
  }
  setTransactionTable(transactionData: any[]) {
    this.transactionData.next(transactionData);
  }
  //  Saleorder  //
  private billingHistoryData = new BehaviorSubject<any>([]);

  getBillingHistoryDataTable() {
    return this.billingHistoryData.asObservable();
  }
  setBillingHistoryDataTable(response: any[]) {
    this.billingHistoryData.next(response);

  }
  private billHistoryData = new BehaviorSubject<any>([]);

  getBillHistoryDataTable() {
    return this.billingHistoryData.asObservable();
  }
  setBillHistoryDataTable(response: any[]) {
    this.billingHistoryData.next(response);

  }

  private editPaymentHistoryData = new BehaviorSubject<any>([]);

  getEditPaymentsDataTable() {
    return this.editPaymentHistoryData.asObservable();
  }
  setEditPaymentsDataTable(response: any[]) {
    this.editPaymentHistoryData.next(response);

  }


  private paymentItemData = new BehaviorSubject<any>([]);

  getPaymentItemData() {
    return this.paymentItemData.asObservable();
  }
  setPaymentItemData(response: any[]) {
    this.paymentItemData.next(response);

  }









  private patientLeadgerData = new BehaviorSubject<any>([]);

  getPatientLedgerDataTable() {
    return this.patientLeadgerData.asObservable();
  }
  setPatientLedgerDataTable(response: any[]) {
    this.patientLeadgerData.next(response);
  }



  private billingListData = new BehaviorSubject<any>([]);

  getBillingListDataTable() {
    return this.billingListData.asObservable();
  }
  setBillingListDataTable(response: any[]) {
    this.billingListData.next(response);
  }


  private billingWarehouseListData = new BehaviorSubject<any>([]);

  getBillingWarehouseListDataTable() {
    return this.billingWarehouseListData.asObservable();
  }
  setBillingWarehouseListDataTable(response: any[]) {
    this.billingWarehouseListData.next(response);
  }

  private billingClaimListData = new BehaviorSubject<any>([]);

  getBillingClaimListDataTable() {
    return this.billingClaimListData.asObservable();
  }
  setBillingClaimListDataTable(response: any[]) {
    this.billingClaimListData.next(response);
  }

  private orderId = new BehaviorSubject<any>("");

  getOrderId() {
    return this.orderId.asObservable();
  }
  setOrderId(response: any) {
    this.orderId.next(response);
  }

  private billClaimListData = new BehaviorSubject<any>([]);

  getBillClaimListDataTable() {
    return this.billClaimListData.asObservable();
  }
  setBillClaimListDataTable(response: any) {
    this.billClaimListData.next(response);
  }
  private patientOrder = new BehaviorSubject<any>("");

  getpatientOrderTable() {
    return this.patientOrder.asObservable();
  }
  setpatientOrderTable(response: any[]) {
    this.patientOrder.next(response);
  }

  private insuranceId = new BehaviorSubject<any>("");

  getpatientOrder() {
    return this.insuranceId.asObservable();
  }
  setpatientOrder(response: string) {
    this.insuranceId.next(response);
  }

  private patientInsuranceVerification = new BehaviorSubject<any>("");

  getpatientInsuranceVerficationTable() {
    return this.patientInsuranceVerification.asObservable();
  }
  setpatientInsuranceVerficationTable(response: any[]) {
    this.patientInsuranceVerification.next(response);
  }
  private patientInsuranceAuthorization = new BehaviorSubject<any>("");

  getpatientInsuranceAuthorizationTable() {
    return this.patientInsuranceAuthorization.asObservable();
  }
  setpatientInsuranceAuthorizationTable(response: any[]) {
    this.patientInsuranceAuthorization.next(response);
  }
  private patientNotes = new BehaviorSubject<any>("");
  setNoteTable(response: any[]) {
    return this.patientNotes.next(response);
  }
  getNoteTable() {
    return this.patientNotes.asObservable();
  }

  private provider = new BehaviorSubject<any>("");
  setproviderTable(response: any[]) {
    return this.provider.next(response);
  }
  getproviderTable() {
    return this.provider.asObservable();
  }

  private pateintReminders = new BehaviorSubject<any>("");

  getPateintRemindersTable() {
    return this.pateintReminders.asObservable();
  }
  setPateintRemindersTable(response: any[]) {
    this.pateintReminders.next(response);
  }


  private dateFilter = new BehaviorSubject<any>("");

  getdateFilterTable() {
    return this.dateFilter.asObservable();
  }
  setdateFilterTable(response1: any, response2: any) {
    this.dateFilter.next({ response1, response2 });
  }


  private valueFilter = new BehaviorSubject<any>("");

  getValueFilterTable() {
    return this.valueFilter.asObservable();
  }
  setValueFilterTable(response1: any) {
    this.valueFilter.next({ response1 });
  }

  // setVerificationSearch(){
  //   const txtFromDate = new Date(this.txtFromDate?.value);
  //     const txtToDate = new Date(this.txtToDate?.value);
  //     const createDate = new Date(data.createDate);
  // }


  private claimHistoryData = new BehaviorSubject<any>([]);

  getClaimHistoryDataTable(): Observable<any[]> {
    return this.claimHistoryData.asObservable();
  }
  setClaimHistoryDataTable(response: any[]) {
    this.claimHistoryData.next(response);
  }

  private authData = new BehaviorSubject<any>("");
  getAuthData(): Observable<any[]> {
    return this.authData.asObservable();
  }
  setAuthTable(authData: any[]) {
    this.authData.next(authData);
  }
  clearAuthData() {
    this.verificationData.next([]);
  }

  private authVerifyData = new BehaviorSubject<any>("");
  getAuthVerifyData(): Observable<any> {
    return this.authVerifyData.asObservable();
  }
  setAuthVerifyTable(authVerifyData: any) {
    this.authVerifyData.next(authVerifyData);
  }
  clearAuthVerifyData() {
    this.verificationData.next([]);
    // this.selectedPatientId.next("");

  }

  private VerifyData = new BehaviorSubject<any>("");
  getOutVerifyData(): Observable<any[]> {
    return this.VerifyData.asObservable();
  }
  setOutVerifyTable(VerifyData: any[]) {
    this.VerifyData.next(VerifyData);
  }
  clearOutVerifyData() {
    this.VerifyData.next([]);
    // this.selectedPatientId.next("");

  }

  private orderData = new BehaviorSubject<any>("");
  private ordersData = new BehaviorSubject<any>("");
  getOrderData(): Observable<any[]> {
    return this.orderData.asObservable();
  }
  setOrderTable(orderData: any[]) {
    this.orderData.next(orderData);
  }
  clearOrderData() {
    this.orderData.next([]);
  }
  private inventoryData = new BehaviorSubject<any>("");
  getInventoryData(): Observable<any[]> {
    return this.inventoryData.asObservable();
  }
  setInventoryTable(inventoryData: any[]) {
    this.inventoryData.next(inventoryData);
  }
  clearInventoryData() {
    this.inventoryData.next([]);
  }

  private verificationData = new BehaviorSubject<any>("");
  getVerifyData(): Observable<any[]> {
    return this.verificationData.asObservable();
  }
  setVerifyTable(verificationData: any[]) {
    this.verificationData.next(verificationData);
  }
  clearVerifyData() {
    this.verificationData.next([]);
  }


  private activeData = new BehaviorSubject<any>("");
  getActiveData(): Observable<any[]> {
    return this.activeData.asObservable();
  }
  setActiveTable(activeData: any[]) {
    this.activeData.next(activeData);
  }
  clearActiveData() {
    this.activeData.next([]);
  }
  private claimStatusSaveEvent = new BehaviorSubject<boolean>(false);
  getClaimStatusSaveData(): Observable<boolean> {
    return this.claimStatusSaveEvent.asObservable();
  }
  setClaimStatusSaveTable(eventData: boolean) {
    this.claimStatusSaveEvent.next(eventData);
  }
  clearClaimStatusSaveData() {
    this.claimStatusSaveEvent.next(false);
  }

  getOrdersData(): Observable<any[]> {
    return this.ordersData.asObservable();
  }
  setOrdersTable(ordersData: any[]) {
    this.ordersData.next(ordersData);
  }
  clearOrdersData() {
    this.orderData.next([]);
  }

  private doctorData = new BehaviorSubject<any>("");
  getDoctorData(): Observable<any> {
    return this.doctorData.asObservable();


  }
  setDoctorTable(doctorData: any) {
    this.doctorData.next(doctorData);
  }
  clearDoctorData() {
    this.doctorData.next("");
  }

  // private headerData = new BehaviorSubject<any>("");
  // getheaderData(): Observable<any> {
  //   return this.headerData.asObservable();


  // }
  // setheaderTable(headerData: any) {
  //   this.headerData.next(headerData);
  // }

  private patientOrderStatus = new BehaviorSubject<any>("");
  getPatientOrderStatusTable() {
    return this.patientOrderStatus.asObservable();
  }
  setPatientOrderStatusTable(response: any[]) {
    this.patientOrderStatus.next(response);
  }
  // ! Local Server


  loadDummyPatientData1(): Observable<any> {
    return this.HttpClient.get<any>('../../assets/sample.json');
  }

  // loadDummyPatientData2() {
  //   return this.HttpClient.get<any>('../../../assets/patientDummydata2.JSON');
  // }

  // ! Test Server
  // loadDummyPatientData1() {
  //   return this.HttpClient.get<any>('https://rcm.rndsoftech.com:8092/assets/patientDummydata1.JSON');
  // }
  // loadDummyPatientData2() {
  //   return this.HttpClient.get<any>('https://rcm.rndsoftech.com:8092/assets/patientDummydata2.JSON');
  // }



  private selectedPatientId = new BehaviorSubject<any>("");
  getSelectedPatientId() {
    return this.selectedPatientId.asObservable();
  }
  setSelectedPatientId(response: string) {
    this.selectedPatientId.next(response);
  }

  private selectedOrderId = new BehaviorSubject<any>("");
  getSelectedOrderId() {
    return this.selectedOrderId.asObservable();
  }
  setSelectedOrderId(response: string) {
    this.selectedOrderId.next(response);
  }

  private lstfaxBinData = new BehaviorSubject<any>("");
  getlstfaxBinData(): Observable<any[]> {
    return this.lstfaxBinData.asObservable();
  }
  setlstfaxBinDataTable(lstfaxBin: any[]) {
    this.lstfaxBinData.next(lstfaxBin);
  }
  clearlstfaxBinDataData() {
    this.lstfaxBinData.next([]);
  }


  private coverageSummaryData = new BehaviorSubject<any>("");
  getCoverageSummaryData(): Observable<any[]> {
    return this.coverageSummaryData.asObservable();
  }
  setCoverageSummaryTable(coverageSummaryData: any[]) {
    this.coverageSummaryData.next(coverageSummaryData);
  }
  clearCoverageSummaryData() {
    this.coverageSummaryData.next([]);
  }

  private deductibleBenefitData = new BehaviorSubject<any>("");
  getDeductibleBenefitData(): Observable<any[]> {
    return this.deductibleBenefitData.asObservable();
  }
  setDeductibleBenefitTable(deductibleBenefitData: any[]) {
    this.deductibleBenefitData.next(deductibleBenefitData);
  }
  clearDeductibleBenefitData() {
    this.deductibleBenefitData.next([]);
  }
  private selectedPostingId = new BehaviorSubject<any>("");
  getPostTable(): Observable<any[]> {
    return this.selectedPostingId.asObservable();
  }
  setPostTable(response: any[]) {
    this.selectedPostingId.next(response);
  }

  private coBenefitData = new BehaviorSubject<any>("");
  getCoBenefitData(): Observable<any[]> {
    return this.coBenefitData.asObservable();
  }
  setCoBenefitTable(coBenefitData: any[]) {
    this.coBenefitData.next(coBenefitData);
  }
  clearCoBenefitData() {
    this.coBenefitData.next([]);
  }

  private inviteUserData = new BehaviorSubject<any>("");
  getInviteUsersData(): Observable<any[]> {
    return this.inviteUserData.asObservable();
  }
  setInviteUsersTable(inviteUserData: any[]) {
    this.inviteUserData.next(inviteUserData);
  }
  clearInviteUsersData() {
    this.inviteUserData.next([]);
  }

  // ! new admin tabs

  private clientList = new BehaviorSubject<any>("");
  getClientListData(): Observable<any[]> {
    return this.clientList.asObservable();
  }
  setClientListTable(response: any[]) {
    this.clientList.next(response);
  }


  // ! new admin tabs

  private inactive = new BehaviorSubject<any>("");
  getinactiveData(): Observable<any[]> {
    return this.inactive.asObservable();
  }
  setinactiveTable(response: any[]) {


    this.inactive.next(response);
  }

  // ! for getting userid
  private userId = new BehaviorSubject<any>("");

  getUserId() {
    return this.orderId.asObservable();
  }
  setUserId(response: any) {
    this.userId.next(response);
  }

  // ! for branch table
  private branchList = new BehaviorSubject<any>("");
  getBranchListData(): Observable<any[]> {
    return this.branchList.asObservable();
  }
  setBranchListTable(response: any[]) {
    this.branchList.next(response);
  }
  // ! for role table
  private roleList = new BehaviorSubject<any>("");
  getRoleListData(): Observable<any[]> {
    return this.roleList.asObservable();
  }
  setRoleListTable(response: any[]) {
    this.roleList.next(response);
  }

  // ! save id get
  private clientId = new BehaviorSubject<any>("");
  getClientData(): Observable<any> {
    return this.clientId.asObservable();
  }
  setClientTable(clientId: string) {
    this.clientId.next(clientId);
  }
  // ! user table get
  private userList = new BehaviorSubject<any>("");
  getUserListData(): Observable<any> {
    return this.userList.asObservable();
  }
  setUserListTable(response: any[]) {
    this.userList.next(response);
  }

  // ! official name get
  private officialName = new BehaviorSubject<any>(null);

  getofficialName() {
    return this.officialName.asObservable();
  }
  setofficialName(response: any) {
    this.officialName.next(response);
  }

  private executiveRoleList = new BehaviorSubject<any>("");
  setExecutiveRoleListTable(response: any[]) {
    this.executiveRoleList.next(response);
  }
  getExecutiveRoleListData(): Observable<any[]> {
    return this.executiveRoleList.asObservable();
  }
  clearExecutiveRoleListData() {
    this.executiveRoleList.next(null);
  }

  private executiveUserList = new BehaviorSubject<any>("");
  setExecutiveUserListTable(response: any[]) {
    this.executiveUserList.next(response);
  }
  getExecutiveUserListData(): Observable<any[]> {
    return this.executiveUserList.asObservable();
  }
  clearExecutiveUserListData() {
    this.executiveUserList.next(null);
  }



  // ontime schedule
  private Appointment = new BehaviorSubject<any>("");
  getAppointmentListData(): Observable<any[]> {
    return this.Appointment.asObservable();
  }
  setAppointmentListTable(response: any[]) {
    this.Appointment.next(response);
  }

  private OnTimeUser = new BehaviorSubject<any>("");
  getOnTimeUserListTable(): Observable<any[]> {
    return this.OnTimeUser.asObservable();
  }
  setOnTimeUserListTable(response: any[]) {
    this.OnTimeUser.next(response);
  }














}
export interface permissionsDTO {
  create?: boolean;
  view?: boolean;
  update?: boolean;
  delete?: boolean;
}
