<ng-template #contentTemplate>
    <div id="sidebar-menu">
      <ul class="metismenu list-unstyled" id="side-menu" #sideMenu>
        <li class="menu-title">Menu</li>
        <li>
          <a routerLink="/search" routerLinkActive="active"
            class="side-nav-link-ref">
            <i class="bx bxs-user-detail icon"></i>
            <span>Patient</span>
          </a>
        </li>
        <!-- <li>
          <a routerLink="/inventory/notification" routerLinkActive="active"
            class="side-nav-link-ref">
            <i class="bx bx-bell icon"></i>
            <span>Notification</span>
          </a>
        </li> -->
        <!--
        <li class="menu-title">Item</li>
        <li>
          <a href="javascript: void(0);" class="has-arrow">
            <i class="bx bxs-inbox"></i>
            <span>Item</span>
          </a>
          <ul class="sub-menu" aria-expanded="false">
            <li>
              <a routerLink="/inventory/item-maintenance-search"
                routerLinkActive="active" class="side-nav-link-ref">
                <span>Maintenance</span>
              </a>
            </li>
            <li>
              <a routerLink="/inventory/maintenance-search"
                routerLinkActive="active" class="side-nav-link-ref">
                <span>Maintenance Search</span>
              </a>
            </li>
            <li>
              <a routerLink="/inventory/item-search" routerLinkActive="active"
                class="side-nav-link-ref">
                <span>Search</span>
              </a>
            </li>
  
          </ul>
        </li>
  
        <li class="menu-title">Inventory</li>
        <li>
          <a href="javascript: void(0);" class="has-arrow">
            <i class="bx bx-task"></i>
            <span>Setup</span>
          </a>
          <ul class="sub-menu" aria-expanded="false">
            <li>
              <a routerLink="/inventory/Item" routerLinkActive="active"
                class="side-nav-link-ref">
                <span>Item</span>
              </a>
            </li>
            <li>
              <a routerLink="/inventory/Vendors" routerLinkActive="active"
                class="side-nav-link-ref">
                <span>Vendor</span>
              </a>
            </li>
            <li>
              <a routerLink="/inventory/Manufacturer" routerLinkActive="active"
                class="side-nav-link-ref">
                <span>Manufacturer</span>
              </a>
            </li>
  
            <li><a routerLink="/inventory/insurance" routerLinkActive="active"
                class="side-nav-link-ref">Insurance</a>
            </li>
            <li><a routerLink="/inventory/diagnosisCodes"
                routerLinkActive="active" class="side-nav-link-ref">Diagnosis
                Code</a></li>
            <li><a routerLink="/inventory/UOM" routerLinkActive="active"
                class="side-nav-link-ref">UOM
              </a></li>
            <li>
              <a routerLink="/inventory/stocks" routerLinkActive="active"
                class="side-nav-link-ref">
                <span>Stock</span>
              </a>
            </li>
          </ul>
        </li>
  
        <li class="menu-title">User</li>
        <li>
          <a href="javascript: void(0);" class="has-arrow">
            <i class="bx bx-user-circle"></i>
            <span>Admin</span>
          </a>
          <ul class="sub-menu" aria-expanded="false">
            <li><a routerLink="/admin/company" routerLinkActive="active"
                class="side-nav-link-ref">Company</a>
            </li>
  
            <li><a routerLink="/admin/branch" routerLinkActive="active"
                class="side-nav-link-ref">Branch</a></li>
            <li><a routerLink="/admin/Admin" routerLinkActive="active"
                class="side-nav-link-ref">Admin</a></li>
            <li><a routerLink="/admin/role" routerLinkActive="active"
                class="side-nav-link-ref">Role</a></li>
  
            <li><a routerLink="/admin/users" routerLinkActive="active"
                class="side-nav-link-ref">User</a></li>
  
            <li><a routerLink="/admin/profile" routerLinkActive="active"
                class="side-nav-link-ref"> Profile</a></li>
          </ul>
        </li> -->
      </ul>
    </div>
  </ng-template>
  
  <div class="vertical-menu">
    <div data-simplebar class="h-100">
      <div *ngIf="!isCondensed">
        <ng-container *ngTemplateOutlet="contentTemplate"></ng-container>
      </div>
  
      <div *ngIf="isCondensed">
        <ng-container *ngTemplateOutlet="contentTemplate"></ng-container>
      </div>
    </div>
  </div>
  