import { Component, OnInit, ViewChild } from '@angular/core';
import { NgbNav } from '@ng-bootstrap/ng-bootstrap';



@Component({
  selector: 'app-create-edit-product-tab',
  templateUrl: './create-edit-product-tab.component.html',
  styleUrls: ['./create-edit-product-tab.component.scss']
})
export class CreateEditProductTabComponent implements OnInit {
  @ViewChild('nav') nav: NgbNav;

  constructor() { }

  ngOnInit(): void {
  }
  

}
