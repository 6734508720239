import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { PatientAuthorizationService } from 'projects/patient/src/app/patient-proxy/patient-optimization';
import { FollowupType, followUpTypeOptions } from 'projects/patient/src/app/patient-proxy/patient-optimization/dto/models';
import { AuthFollowUpReport } from 'projects/shared/src/app/enums/allenums.enum';
import { Subscription } from 'rxjs';
import Swal from 'sweetalert2';
import { AddAuthorizationTabComponent } from '../add-authorization-tab/add-authorization-tab.component';
import { AddItemAuthorizationComponent } from '../add-item-authorization/add-item-authorization.component';


@Component({
  selector: 'app-auth-follow-up',
  templateUrl: './auth-follow-up.component.html',
  styleUrls: ['./auth-follow-up.component.scss'],
})
export class AuthFollowUpComponent implements OnInit, OnDestroy {
  displayedColumns: string[] = [];
  AuthFilterForm: FormGroup;
  followUpType: string = '';
  isLoading: boolean = false;
  urlPath: string;
  dataSource: MatTableDataSource<any> = new MatTableDataSource<any>([]);
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatTable, { static: false }) tableRef: MatTable<any>;
  drpAuthReportType: { value: string; key: string }[] = [];
  subscription$: Subscription[] = [];
  constructor(
    private dialog: MatDialog,
    private title: Title,
    private patientAuthService: PatientAuthorizationService,
    private fb: FormBuilder,
    private activatedRoute: ActivatedRoute
  ) {}
  //! page initialization func
  ngOnInit(): void {
    //To Load the Filter Dropdown
    this.drpAuthReportType = followUpTypeOptions?.map((e) => {
      let x = {
        value: e.value,
        key: e.key,
      };
      return x;
    });

    this.displayedColumns = [
      'Options',
      'item',
      'authId',
      'patId',
      'patName',
      'startDate',
      'endDate',
      'Days',
      'ExpiryStatus',
      //'isFollowUp',
      'type',
      'Units',
      'Used',
      'Plan',
      
    ];

    this.title.setTitle('Qsecure | Auth Report');
    //Form Creation
    this.AuthFilterForm = this.fb.group({
      drpForms: new FormControl('', Validators.required),
    });

    // Auth Filter default All
    this.AuthFilterForm.patchValue({
      drpForms: FollowupType.All,
    });
    this.getAuthFollowTable(FollowupType.All);
  }

  // get Auth Follow Table
  getAuthFollowTableData(value: Event) {
    this.AuthFilterForm.patchValue({ drpForms: value ?? '' });
    this.getAuthFollowTable(value);
  }

  //get Auth Follow Table Data
  getAuthFollowTable(value: any) {
    let arrTableData = [];
    this.isLoading = true;
    let type: FollowupType = value;
    const getFollowUP = this.patientAuthService
      .authFollowupReports(type.toString())
      .subscribe(
        (response) => {
          arrTableData = response;
          this.isLoading = false;
          this.dataSource = new MatTableDataSource(arrTableData);
          this.dataSource.sort = this.sort;
          this.dataSource.paginator = this.paginator;
        },
        (err) => {
          this.isLoading = false;
          const data: HttpErrorResponse = err;
          Swal.fire({ icon: 'info', text: data?.error?.error?.message });
        }
      );
    this.subscription$.push(getFollowUP);
  }

  clearDrpForms() {
    this.AuthFilterForm.patchValue({ drpForms: '' });
  }

  //! View Auth Modal Popup
  viewItemBasedAuth(auth: any) {

    const dialogRef = this.dialog.open(AddAuthorizationTabComponent, {
      disableClose: true,
      data: {
        orderId: auth.orderId,
        authorizationId: auth?.id,
        authDatas: auth,
        patientId: auth.patientId,
      },
    });
    const closeDialog = dialogRef.afterClosed().subscribe(() => {
      this.getAuthFollowTable(FollowupType.All);
    });
    this.subscription$.push(closeDialog);
  }

  //To Open Auth Modal
  openAuthModal(auth: any) {
    const dialogRef = this.dialog.open(AddAuthorizationTabComponent, {
      disableClose: true,
      data: {
        orderId: auth.orderId,
        authorizationId: auth?.id,
        patientId: auth.patientId,
      },
    });
    const closeDialog = dialogRef.afterClosed().subscribe(() => {
      this.getAuthFollowTable(null);
    });
    this.subscription$.push(closeDialog);
  }

  //update auth follow up status
  updateFollowUp(value: { id: string }) {
    // if (value?.id) {
    //   const itemGet = this.ItmAuthService.get(value?.id).subscribe(
    //     (response) => {
    //       const mResponse: ItemAuthorizationDTO = {
    //         authorizationId: response?.authorizationId,
    //         authId: response?.authId,
    //         authNo: response?.authNo,
    //         authAmount: response?.authAmount,
    //         startDate: response?.startDate,
    //         endDate: response?.endDate,
    //         days: response?.days,
    //         authExpireStatus: response?.authExpireStatus,
    //         item: response?.item,
    //         note: response?.note,
    //         approvalStatus: response?.approvalStatus,
    //         approvedByWhom: response?.approvedByWhom,
    //         approvedOn: response?.approvedOn,
    //         insurance: response?.insurance,
    //         isFollowUp: response?.isFollowUp === 0 ? 1 : 0,
    //         reason: response?.reason,
    //         units: response?.units,
    //         used: response?.used,
    //         saleOrRent: response?.saleOrRent,
    //         expireStatus: response?.expireStatus,
    //         patientId: response?.patientId,
    //         orderId: response?.orderId,
    //       };
    //       this.ItmAuthService.update(value?.id, mResponse).subscribe(
    //         (res) => {
    //           this.getAuthFollowTable(null);
    //           Swal.fire({
    //             title: 'Success',
    //             html: 'Saved Successfully',
    //             icon: 'success',
    //             timer: 3000,
    //             timerProgressBar: true,
    //           });
    //         },
    //         (err: HttpErrorResponse) => {
    //           const data: HttpErrorResponse = err;
    //           Swal.fire({
    //             icon: 'error',
    //             text: data?.error?.error?.message,
    //           });
    //         }
    //       );
    //     },
    //     (err: HttpErrorResponse) => {
    //       const data: HttpErrorResponse = err;
    //       Swal.fire({
    //         icon: 'error',
    //         text: data?.error?.error?.message,
    //       });
    //     }
    //   );
    //   this.subscription$.push(itemGet);
    // }
  }

  //On Destroy Function
  ngOnDestroy(): void {
    this.subscription$?.forEach((sub) => {
      sub && sub?.unsubscribe();
    });
  }
}
