import { Component, OnInit, ViewChild, Input, Inject, Output, EventEmitter } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { ListService, PagedAndSortedResultRequestDto } from '@abp/ng.core';
import { MatSelect } from '@angular/material/select';
import { Observable, Subscription, of, forkJoin } from 'rxjs';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import Swal from 'sweetalert2';
import { map, startWith } from 'rxjs/operators';
import { ToastrService } from "ngx-toastr";
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { TextValidators } from 'projects/shared/src/app/validations/text-validator';
import { MasterNotesService } from 'projects/order/src/app/order-proxy/order-management/inventory/master-notes.service';
import { UserService } from 'projects/admin/src/app/admin-proxy/platform-app-management/rcm/platform-management/user';
import { UserByRoleDTO } from 'projects/admin/src/app/admin-proxy/platform-app-management/identity';
import { PatientPersonalService } from 'projects/patient/src/app/patient-proxy/patient-optimization';
import { OrderMasterDropdownService, PatientMasterDropdownService } from 'projects/patient/src/app/dropdown-proxy/dropdown-management/dropdowns/master-dropdown-service';
import { OrderDropdowns, PatientDropdowns } from 'projects/patient/src/app/dropdown-proxy/dropdown-management/dropdowns';
import { PatientMasterDropdownDTO, PayorLevelDTO } from 'projects/patient/src/app/dropdown-proxy/dropdown-management/dropdowns/dto/patient';
import { OrderMasterDropdownDTO, YearTypeDTO } from 'projects/patient/src/app/dropdown-proxy/dropdown-management/dropdowns/dto/order';
import { ReminderStatusService } from '../admin-proxy/platform-app-management/rcm/platform-management/remainder/reminder-status.service';
import { CreateReminderDTO, ReminderDTO, ReminderPriorityDTO, ReminderStatusDTO, UpdateReminderDTO } from '../admin-proxy/platform-app-management/rcm/platform-management/remainder/dto';
import { ReminderPriorityService } from '../admin-proxy/platform-app-management/rcm/platform-management/remainder/reminder-priority.service';
// import { defaultGuid } from 'projects/admin/src/app/document-perviewer/document-perviewer.component';
import { CommunicationService } from 'projects/shared/src/app/services/communication.service';
import { NoteType } from 'projects/patient/src/app/patient-proxy/patient-optimization/dto/models';
import { ReminderService } from '../admin-proxy/platform-app-management/rcm/platform-management/remainder/reminder.service';
import { noWhitespaceValidator } from 'projects/patient/src/app/add-note-tab/add-note-tab.component';
import { DatePipe } from '@angular/common';
import { MmOrderService } from 'projects/order/src/app/order-proxy/order-management/order-optimization/mm-order.service';
import { ActiveStatus } from '../admin-proxy/platform-app-management/rcm/platform-management/localization/enumeration/active-status.enum';
import { defaultGuid } from 'projects/shared/src/app/enums/allenums.enum';
@Component({
  selector: 'app-add-reminder',
  templateUrl: './add-reminder.component.html',
  styleUrls: ['./add-reminder.component.scss'],
  providers: [DatePipe]
})
export class AddReminderComponent implements OnInit {

  @Input() remindertitle: any;
  @Input() status: any;
  @Input() selectedNode: any;
  @Input() lblDefaultPatientId: any;
  @Input() reminderid: any;
  @Input() isdisable: any;
  reminderForm: FormGroup;
  issave:string='Save';
  sDueDate: Date;
  sDueDateV1: string;
  // notesId: string = defaultGuid;
  listNoteType: any;
  drpNotetype: any;
  drpUsertype: any;
  drpPrioritytype: any;
  isShowSpinner: boolean = false;
  NoteTypeControl = new FormControl();
  UserTypeControl = new FormControl();
  PatientTypeControl = new FormControl();
  PriorityTypeControl = new FormControl();
  StatusTypeControl = new FormControl();
  payorLevel: PayorLevelDTO[] = [];
  subscription$: Subscription[] = [];
  lstFilterUsers: UserByRoleDTO[] = [];
  statusdrp: ReminderStatusDTO[] = [];
  Prioritydrp: ReminderPriorityDTO[] = [];
  drpYearType: YearTypeDTO[] = [];
  @Output() reloadTableEvent = new EventEmitter();
  @Output() selectNode = new EventEmitter();
  minDate: Date;
  appointment: number;
  allUsers: number;
  trackViewer: number;
  trackEdits: number;
  isUsertypeTouched = false;
  isNotetypeTouched = false;
  isStatustypeTouched = false;
  isSearchPatientTouched = false;
  isPrioritytypeTouched = false;
  isDuedateTouched = false;
  isDescriptionTouched = false;
  userId: string;
  lowpriorityId: string;
  updatedrp: any;
  notesText = '';
  isUserLoading:boolean=false;
  isNoteLoading:boolean=false;
  isPatientLoading:boolean=false;
  filteredPatients: Observable<{ guid: string; idAndNameAndDOB: string }[]> =
    of([]);
  @Output() patientIdEmitters: EventEmitter<string> =
    new EventEmitter<string>();
  lstAllPatientIds: { guid: string; idAndNameAndDOB: string }[] = [];
  @ViewChild('singleSelect') singleSelect: MatSelect;
  patientChartApiSubscription: Subscription;
  lstChartNos: any[] = [];
  constructor(
    private fb: FormBuilder,
    private textValidators: TextValidators,
    private notesService: MasterNotesService,
    private userService: UserService,
    private patientPersonalService: PatientPersonalService,
    private patientDropdownService: PatientMasterDropdownService,
    private orderDropdownService: OrderMasterDropdownService,
    private reminderStatusService: ReminderStatusService,
    private reminderPriorityService: ReminderPriorityService,
    private communicationService: CommunicationService,
    private reminderService: ReminderService,
    private toastr: ToastrService,
    private datepipe: DatePipe,
    private mmOrderService: MmOrderService,
    public notesDialogRef: MatDialogRef<AddReminderComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      remindertitle: string;
      status: boolean;
      selectedNode: any;
      noteType: NoteType;
      reminderid?: string;
      lblDefaultPatientId: string;
      isdisable: boolean;
      userType: any;
    } = {
        remindertitle: '',
        status: true,
        selectedNode: defaultGuid,
        noteType: NoteType.general,
        userType: '',
        reminderid: defaultGuid,
        lblDefaultPatientId: '',
        isdisable: true
      },

  ) { }

  ngOnInit(): void {

    this.reminderForm = this.fb.group({
      drpUsertype: new FormControl('', [Validators.required]),
      txtUserType: new FormControl(''),

      drpNotetypev1: new FormControl('', [Validators.required]),
      txtNoteType: new FormControl(''),

      drpSearchPatient: new FormControl(''),
      txtPatientFilter: new FormControl(''),

      drpPrioritytype: new FormControl('', [Validators.required]),
      txtPriorityType: new FormControl(''),

      txtDuedate: [new Date(), [Validators.required]],

      txtDescription: new FormControl('', [Validators.required,noWhitespaceValidator]),
    });


    //Note Type
    this.NoteTypeControl.valueChanges.subscribe((FilterText) => {
      FilterText = FilterText.toLowerCase();
      this.drpNotetype = this.listNoteType.filter((dx) =>
        dx.noteType.toLowerCase().includes(FilterText)
      );
    });

    //User Type
    this.UserTypeControl.valueChanges.subscribe((searchTerm) => {
      searchTerm = searchTerm.toLowerCase();
      this.lstFilterUsers = this.drpUsertype.filter((dx) =>
        dx.userDesignation.toLowerCase().includes(searchTerm)
      );
    });

    //Priority Type
    this.PriorityTypeControl.valueChanges.subscribe((filtertxt) => {
      filtertxt = filtertxt.toLowerCase();
      this.Prioritydrp = this.drpPrioritytype.filter((dx) =>
        dx.remainderPriority.toLowerCase().includes(filtertxt)
      );
    });

    if (this.data) {
      this.remindertitle = this.data?.remindertitle ?? "Reminder";
      this.status = this.data?.status ?? false;
      this.lblDefaultPatientId = this.data?.lblDefaultPatientId ?? '';
      this.reminderid = this.data?.reminderid ?? '';
      this.isdisable = this.data?.isdisable ?? true;
    }

    this.minDate = new Date();
    this.noteTypeDetails();
    this.getUserByTenant();
    // this.loadPatient();
    this.searchPatient('',this.lblDefaultPatientId);
    this.getStatus();
    this.getPriority();
    this.userId = localStorage.getItem("userId") ?? "";
    this.reminderForm.patchValue({ drpUsertype: this.userId });
    if (this.reminderid !== defaultGuid){
      this.issave='Update';
      this.fnloadreminder();
    }
    // if(this.reminderid !== defaultGuid){
    //   this.fnloadreminder();
    // }
    this.reminderForm.patchValue({ drpSearchPatient: this.lblDefaultPatientId });
  }


  //Search Patient Information
  searchPatient(value: any, patientId: string) {
    this.loadChartNos(value, patientId);
  }

  //To Load the  Patients Dropdown
  loadChartNos(searchParameter: string, patientId: string) {
    this.isPatientLoading=true;
    if (this.patientChartApiSubscription) {
      this.patientChartApiSubscription.unsubscribe();
    }
    this.patientChartApiSubscription = this.mmOrderService
      .getPatientChartNoV3BySSearchParamsAndPatient(searchParameter, patientId)
      .subscribe(
        (response) => {
          this.isPatientLoading=false;
          this.lstChartNos = response;
          return this.lstChartNos;
        },
        (err) => {
          this.isPatientLoading=false;
          // const data: HttpErrorResponse = err;
          // Swal.fire({
          //   icon: 'info',
          //   text: data?.error?.message || 'An error occurred while fetching the patient.',
          // });
        }
      );
    }

  //Update Load reminder Details
  fnloadreminder() {
    const statusList = this.reminderService.getRemainderById(this.reminderid).subscribe(
      (response) => {
        this.updatedrp = response;
        this.updatedrp.forEach((data) => {

          // if(data?.sDueDate !==null && data?.sDueDate !=='' &&data?.sDueDate !==undefined ){
          //   this.sDueDate=new Date(data?.sDueDate)
          //   this.sDueDate.setDate(this.sDueDate.getDate() + 1)
          //   this.sDueDateV1 =this.sDueDate.toISOString().split('T')[0]
          // this.reminderForm
          // .get('txtDuedate')
          // .setValue(this.sDueDateV1)
          // }
          const dueDate = new Date(data?.sDueDate ?? new Date());
          const localDueDate = new Date(dueDate.getTime() + dueDate.getTimezoneOffset() * 60000);
          this.searchPatient('',data?.patientId);
          this.reminderForm.patchValue({
            drpUsertype: data?.assignedUserId ?? '',
            drpNotetypev1: data?.noteTypeId ?? '',
            drpSearchPatient: data?.patientId ?? '',
            drpPrioritytype: data?.priorityId ?? '',
            // drpStatustype: data?.statusId ?? '',
            // txtDuedate: data?.dueDate ?? '',
            txtDuedate: localDueDate,
            txtDescription: data?.description ?? '',
          });
          this.reminderForm.markAsDirty();
        });
      },
      (err) => {
        const data: HttpErrorResponse = err;
        const errorMsg = data?.error?.message || 'An error occurred while fetching the reminder.';
        this.toastr.warning(errorMsg, 'Warning')
        // Swal.fire({
        //   icon: 'info',
        //   text: data?.error?.message || 'An error occurred while fetching the reminder.',
        // });
      }
    );
    this.subscription$.push(statusList);
  }


  isDropdownOpen = false;
  onDropdownOpenChange(isOpen: boolean){
    this.isDropdownOpen = isOpen;
    if(this.isDropdownOpen){
      this.searchPatient('',this.lblDefaultPatientId);
    }
  }

  //GET Note Type Drop Down
  noteTypeDetails() {
    this.isNoteLoading=true;
    const NoteType = this.notesService
      .getDrpNoteTypes()
      .subscribe(
        (response) => {
          this.isNoteLoading=false;
          this.listNoteType = response;
          this.drpNotetype = this.listNoteType;
        },
        (err) => {
          this.isNoteLoading=false;
          // const data: HttpErrorResponse = err;
          // Swal.fire({
          //   icon: 'info',
          //   text: data?.error?.error?.message,
          // });
        }
      );
      this.subscription$.push(NoteType);
  }

  // noteTypeDetails() {
  //   this.isNoteLoading=true;
  //   const NoteType = this.notesService
  //     .getListV1(new PagedAndSortedResultRequestDto())
  //     .subscribe(
  //       (response) => {
  //         this.isNoteLoading=false;
  //         this.listNoteType = response;
  //         this.drpNotetype = this.listNoteType;
  //       },
  //       (err) => {
  //         this.isNoteLoading=false;
  //         const data: HttpErrorResponse = err;
  //         Swal.fire({
  //           icon: 'info',
  //           text: data?.error?.error?.message,
  //         });
  //         this.subscription$.push(NoteType);
  //       }
  //     );
  // }

  //GET USer Drop Down
  getUserByTenant() {
    this.isUserLoading=true;
    const userList = this.userService.getUsersByTenant().subscribe(
      (response) => {
        this.isUserLoading=false;
        this.lstFilterUsers = response;
        this.drpUsertype = this.lstFilterUsers;
      },
      (err) => {
        this.isUserLoading=false;
        // const data: HttpErrorResponse = err;
        // Swal.fire({
        //   icon: 'info',
        //   text: data?.error?.error?.message,
        // });
      });
      this.subscription$.push(userList);
  }

  //GET Status Drop Down
  getStatus() {
    const statusList = this.reminderStatusService.getRemainderStatusList(1).subscribe(
      (response) => {
        this.statusdrp = response;
      },
      (err) => {
        // const data: HttpErrorResponse = err;
        // Swal.fire({
        //   icon: 'info',
        //   text: data?.error?.error?.message,
        // });
      });
      this.subscription$.push(statusList);
  }

  //GET Priority Drop Down
  getPriority() {
    const priorityList = this.reminderPriorityService.getRemainderPriorityList(1).subscribe(
      (response) => {
        this.Prioritydrp = response;
        this.drpPrioritytype = this.Prioritydrp;
        this.Prioritydrp.forEach((priority) => {
          if (priority.remainderPriority === "Low") {
            this.lowpriorityId = priority.id;
            this.reminderForm.patchValue({ drpPrioritytype: this.lowpriorityId });
          }
        });
      },
      (err) => {
        // const data: HttpErrorResponse = err;
        // Swal.fire({
        //   icon: 'info',
        //   text: data?.error?.error?.message,
        // });
      });
      this.subscription$.push(priorityList);
  }

  //Patient Drop Down
  // loadPatient() {
  //   this.isPatientLoading=true;
  //   const searchPatientSub = this.patientPersonalService
  //     .patientSearchBySDefaultPatientIdAndSPatientNameAndDtDOBAndSSSNAndSEmailIdAndSMobileAndSStatus(
  //       '',
  //       '',
  //       '',
  //       '',
  //       '',
  //       '',
  //       '',
  //       1
  //     )
  //     .subscribe((response) => {
  //       this.isPatientLoading=false;
  //       this.lstAllPatientIds = response.map((x) => {
  //         const dateOptions: Intl.DateTimeFormatOptions = {
  //           year: 'numeric',
  //           month: '2-digit',
  //           day: '2-digit',
  //         };
  //         //date in MM/DD/YYYY FORMAT
  //         const dob =
  //           (x?.dob ?? '') === ''
  //             ? ''
  //             : new Date(x?.dob)?.toLocaleDateString('en-US', dateOptions) ??
  //             '';
  //         let y: { guid: string; idAndNameAndDOB: string } = {
  //           guid: x?.patientId,
  //           idAndNameAndDOB:
  //             x?.chartId + ' - ' + x?.patientName + ' - ' + dob,
  //           ...x,
  //         };
  //         return y;
  //       });

  //       this.filteredPatients = this.reminderForm
  //         ?.get('txtPatientFilter')
  //         ?.valueChanges?.pipe(
  //           startWith(''),
  //           map((value) =>
  //             this.lstAllPatientIds.filter((option) =>
  //               option?.idAndNameAndDOB
  //                 ?.toLowerCase()
  //                 ?.includes(value?.toLowerCase() ?? '')
  //             )
  //           )
  //         );
  //     },
  //     (err) =>{
  //       this.isPatientLoading=false;
  //       const data: HttpErrorResponse = err;
  //         Swal.fire({
  //           icon: 'info',
  //           text: data?.error?.error?.message,
  //         });
  //     }
  //     );
  //   this.subscription$.push(searchPatientSub);
  //   const patientDropdownInput: PatientDropdowns[] = [
  //     PatientDropdowns.PayorLevels,
  //     PatientDropdowns.RelationShipWithSelf,
  //     PatientDropdowns.ClaimFillingCodes,
  //     PatientDropdowns.Genders,
  //     PatientDropdowns.InsuranceTypes,
  //     PatientDropdowns.Assignments,
  //   ];
  //   const patientDropdownSubscription = this.patientDropdownService
  //     .getPatientDropdownsByInput(patientDropdownInput)
  //     .subscribe(
  //       (stateResponse) => {
  //         this.isPatientLoading=false;
  //         const response: PatientMasterDropdownDTO = stateResponse;
  //         this.payorLevel = response?.payorLevels ?? [];
  //       },
  //       (err) => {
  //         this.isPatientLoading=false;
  //         const data: HttpErrorResponse = err;
  //         Swal.fire({
  //           icon: 'info',
  //           text: data?.error?.error?.message,
  //         });
  //       }
  //     );
  //   this.subscription$.push(patientDropdownSubscription);
  //   const orderDropdownInput: OrderDropdowns[] = [
  //     OrderDropdowns.Charts,
  //     OrderDropdowns.TypeOfPlans,
  //     OrderDropdowns.PlanTypes,
  //     OrderDropdowns.PayablePercents,
  //     OrderDropdowns.YearTypes,
  //   ];
  //   const orderDropdown =
  //     this.orderDropdownService.getOrderDropdownsByInput(orderDropdownInput);
  //   const patietDropdown =
  //     this.patientDropdownService.getPatientDropdownsByInput(
  //       patientDropdownInput
  //     );

  //   const combinedObs = forkJoin([
  //     orderDropdown,
  //     patietDropdown,
  //   ]);
  //   combinedObs.subscribe(
  //     (response) => {
  //       const responseData: OrderMasterDropdownDTO = response?.[0];
  //       this.drpYearType = responseData?.yearTypes;
  //     });
  // }

   //Update Character Count for TextArea
   updateCharacterCount(maxlength: number) {
    // Ensure the text length does not exceed the maximum length
    if (this.notesText?.length > maxlength) {
      this.notesText = this.notesText?.substr(0, maxlength);
    }
  }

  //Save Reminder
  saveReminder() {
    this.isShowSpinner = true;

    this.reminderid = this.data?.reminderid ?? defaultGuid;
    // const dueDateConvert = this.reminderForm.value.txtDuedate ? new Date(this.reminderForm.value.txtDuedate).toLocaleDateString('en-US') : '';
    const dueDateConvert = this.reminderForm.value.txtDuedate ? new Date(this.reminderForm.value.txtDuedate) : null;
    const convertedDueDate = dueDateConvert ? dueDateConvert.toISOString(): '';
    const formattedDate = this.datepipe.transform(this.reminderForm.value.txtDuedate, 'yyyy-MM-dd');
    // const formattedDate = this.datepipe.transform(this.reminderForm.value.txtDuedate, 'yyyy-MM-ddTHH:mm:ss.SSSZ');

    const reminderInput: CreateReminderDTO = {
      patientId: this.reminderForm.value.drpSearchPatient !== '' ? this.reminderForm.value.drpSearchPatient : null,
      assignedUserId: this.reminderForm.value.drpUsertype ?? '',
      // dueDate: this.reminderForm.value.txtDuedate ?? '',
      // dueDate: convertedDueDate ?? '',
      dueDate: formattedDate ?? '',
      priorityId: this.reminderForm.value.drpPrioritytype ?? '',
      description: this.reminderForm.value.txtDescription ?? '',
      noteTypeId: this.reminderForm.value.drpNotetypev1 ?? '',
    };
    //Save
    if (this.reminderid === defaultGuid) {
      this.reminderService.create(reminderInput).subscribe(
        (response) => {
          const reminderSaved: ReminderDTO = response;
          this.toastr.success('Reminder Saved Successfully!', 'Success')
          this.communicationService.triggerGetListReminderDashboard();
          this.communicationService.triggerGetListReminderPatient();
          this.reloadTableEvent.emit();
          this.selectNode.emit({ node: this.selectedNode });
          this.notesDialogRef.close(reminderSaved);
          this.isShowSpinner = false;
          this.reminderid = defaultGuid;
        },
        (err) => {
          this.isShowSpinner = false;
          const data: HttpErrorResponse = err;
          Swal.fire({
            icon: 'info',
            text: data?.error?.error?.message,
          });
        }
      );
    }

    //Update Reminder
    if (this.reminderid !== defaultGuid) {

      this.reminderService.update(this.reminderid, reminderInput).subscribe(
        (response) => {
          const reminderSaved: ReminderDTO = response;
          this.toastr.success('Reminder Updated Successfully!', 'Success')
          // this.communicationService.triggerFunctionCall(this.data.selectedNode);
          this.communicationService.triggerGetListReminderDashboard();
          this.communicationService.triggerGetListReminderPatient();
          this.reloadTableEvent.emit();
          this.selectNode.emit({ node: this.selectedNode });
          this.notesDialogRef.close(reminderSaved);
          this.isShowSpinner = false;
          this.reminderid = defaultGuid;
        },
        (err) => {
          this.isShowSpinner = false;
          const data: HttpErrorResponse = err;
          Swal.fire({
            icon: 'info',
            text: data?.error?.error?.message,
          });
        }
      );
    }
  }

  //Reset Reminder Fileds
  resetReminder() {
    this.isUsertypeTouched = true;
    this.isNotetypeTouched = true;
    // this.isStatustypeTouched = true;
    this.isSearchPatientTouched = true;
    this.isPrioritytypeTouched = true;
    this.isDuedateTouched = true;
    this.isDescriptionTouched = true;

    this.reminderForm.reset({
      drpUsertype: null,
      drpNotetypev1: null,
      drpSearchPatient: null,
      drpPrioritytype: null,
      // drpStatustype: null,
      txtDuedate: null,
      txtDescription: ''
    });
  }
}
