import type { CreateUpdateMasterNotesDTO, MasterNotesDTO } from './dto/models';
import { RestService } from '@abp/ng.core';
import type { PagedAndSortedResultRequestDto, PagedResultDto } from '@abp/ng.core';
import { Injectable } from '@angular/core';
import { ActiveStatus } from 'projects/admin/src/app/admin-proxy/platform-app-management/rcm/platform-management/localization/enumeration/active-status.enum';
import { NoteType } from 'projects/patient/src/app/patient-proxy/patient-optimization/dto';

@Injectable({
  providedIn: 'root',
})
export class MasterNotesService {
  apiName = 'orderManagement';

  create = (input: CreateUpdateMasterNotesDTO) =>
    this.restService.request<any, MasterNotesDTO>({
      method: 'POST',
      url: '/api/orderApp/master-notes',
      body: input,
    },
      { apiName: this.apiName, skipHandleError: true });

  delete = (id: string) =>
    this.restService.request<any, void>({
      method: 'DELETE',
      url: `/api/orderApp/master-notes/${id}`,
    },
      { apiName: this.apiName, skipHandleError: true });

  get = (id: string) =>
    this.restService.request<any, MasterNotesDTO>({
      method: 'GET',
      url: `/api/orderApp/master-notes/${id}`,
    },
      { apiName: this.apiName, skipHandleError: true });

  getList = (input: PagedAndSortedResultRequestDto) =>
    this.restService.request<any, PagedResultDto<MasterNotesDTO>>({
      method: 'GET',
      url: '/api/orderApp/master-notes',
      params: { skipCount: input.skipCount, maxResultCount: input.maxResultCount, sorting: input.sorting },
    },
      { apiName: this.apiName, skipHandleError: true });
  getListV1 = (input: PagedAndSortedResultRequestDto) =>
    this.restService.request<any, PagedResultDto<MasterNotesDTO>>({
      method: 'GET',
      url: '/api/orderApp/master-notes/get-note-types',
      params: { skipCount: input.skipCount, maxResultCount: input.maxResultCount, sorting: input.sorting },
    },
      { apiName: this.apiName, skipHandleError: true });

  getNotesBasedOnOrganizationIdByOrganizationUnitId = (organizationUnitId: string) =>
    this.restService.request<any, MasterNotesDTO[]>({
      method: 'GET',
      url: `/api/orderApp/master-notes/notes-based-on-organization-id/${organizationUnitId}`,
    },
      { apiName: this.apiName, skipHandleError: true });

  update = (id: string, input: CreateUpdateMasterNotesDTO) =>
    this.restService.request<any, MasterNotesDTO>({
      method: 'PUT',
      url: `/api/orderApp/master-notes/${id}`,
      body: input,
    },
      { apiName: this.apiName, skipHandleError: true });

  searchNotesBySStateAndSSubjectAndSDescription = (sState: string, sSubject: string, sDescription: string) =>
    this.restService.request<any, PagedResultDto<MasterNotesDTO>>({
      method: 'POST',
      url: '/api/orderApp/master-notes/search-notes',
      params: { sState, sSubject, sDescription },
    },
      { apiName: this.apiName, skipHandleError: true });

  getMasterListBasedOnNoteType = (noteType: NoteType) =>
    this.restService.request<any, any[]>({
      method: 'GET',
      url: '/api/orderApp/master-notes/get-master-list-based-on-note-type',
      params: { noteType },
    },
      { apiName: this.apiName, skipHandleError: true });

  getDrpNoteTypes = () =>
    this.restService.request<any, MasterNotesDTO[]>({
      method: 'GET',
      url: '/api/orderApp/master-notes/get-drp-note-types',
    },
      { apiName: this.apiName });
  getNoteTypesByActiveStatus = (activeStatus: ActiveStatus) =>
    this.restService.request<any, MasterNotesDTO[]>({
      method: 'GET',
      url: '/api/orderApp/master-notes/get-note-types',
      params: { activeStatus },
    },
      { apiName: this.apiName });

  enableDisableMasterNotesById = (id: string) =>
    this.restService.request<any, string>({
      method: 'POST',
      responseType: 'text',
      url: `/api/orderApp/master-notes/${id}/enable-disable-master-notes`,
    },
      { apiName: this.apiName });
  constructor(private restService: RestService) { }
}
