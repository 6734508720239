<div class="card card-body">
  <div class="row">
    <!-- Item Addition -->
    <div class="col-lg-12">
      <div class="card card-body">
        <!-- Item Addition Form -->
        <form [formGroup]="addItemForm">
          <!-- Item -->
          <mat-form-field class="col-3">
            <mat-label> Item <span class="asterisk">*</span></mat-label>
            <mat-select formControlName="drpItem" placeholder="Item">
              <!-- <mat-option
        *ngFor="let gender of drpGenderLoop"
        [value]="gender.genderShortCode"
      >
        {{ gender.genderName }}
      </mat-option> -->
              <mat-option [value]="'Option 1'"> Option 1 </mat-option>
            </mat-select>
            <mat-error
              class="spanAlignment"
              *ngIf="
                addItemForm?.get('drpItem')?.touched &&
                addItemForm?.get('drpItem')?.errors?.required
              "
            >
              Item is a required field!
            </mat-error>
          </mat-form-field>

          <!-- Patient Insurance -->
          <mat-form-field class="col-3">
            <mat-label> Insurance <span class="asterisk">*</span></mat-label>
            <mat-select formControlName="drpInsurance" placeholder="Insurance">
              <!-- <mat-option
*ngFor="let gender of drpGenderLoop"
[value]="gender.genderShortCode"
>
{{ gender.genderName }}
</mat-option> -->

              <mat-option [value]="'Option 1'">Option 1</mat-option>
            </mat-select>
            <mat-error
              class="spanAlignment"
              *ngIf="
                addItemForm?.get('drpInsurance')?.touched &&
                addItemForm?.get('drpInsurance')?.errors?.required
              "
            >
              Insurance is a required field!
            </mat-error>
          </mat-form-field>
          <!--Rent or Sale -->
          <mat-form-field class="col-1">
            <mat-label> Type <span class="asterisk">*</span></mat-label>
            <mat-select
              id="drpSaleOrRent"
              placeholder="Type"
              formControlName="drpSaleOrRent"
            >
              <!-- <mat-option
                    *ngFor="let data of drpSaleOrRents"
                    [value]="data.saleOrRent"
                  >
                    {{ data.saleOrRent }}
                  </mat-option> -->
              <mat-option [value]="'Option 1'"> Option 1 </mat-option>
              <mat-error
                *ngIf="
                  addItemForm?.get('drpSaleOrRent')?.touched &&
                  addItemForm?.get('drpSaleOrRent')?.errors?.required
                "
              >
                Ordering Units is a required field!
              </mat-error>
            </mat-select>
          </mat-form-field>
          <!-- Price -->
          <mat-form-field class="col-1">
            <mat-label> Price <span class="asterisk">*</span></mat-label>
            <input
              matInput
              min="1"
              formControlName="txtPrice"
              max="1000"
              type="number"
              placeholder="Price($)"
              maxlength="15"
              autocomplete="off"
            />
            <mat-error
              *ngIf="
                addItemForm?.get('txtPrice')?.touched &&
                addItemForm?.get('txtPrice')?.errors?.required
              "
            >
              Price is a required field!
            </mat-error>
          </mat-form-field>

          <!-- Ordering Units -->
          <mat-form-field class="col-1">
            <mat-label>
              Ordering Units <span class="asterisk">*</span></mat-label
            >
            <input
              matInput
              min="1"
              max="1000"
              formControlName="txtOrderingUnits"
              type="number"
              placeholder="Ordering Units"
              maxlength="15"
              autocomplete="off"
            />
            <mat-error
              *ngIf="
                addItemForm?.get('txtOrderingUnits')?.touched &&
                addItemForm?.get('txtOrderingUnits')?.errors?.required
              "
            >
              Ordering Units is a required field!
            </mat-error>
          </mat-form-field>
          <!-- DOS -->
          <mat-form-field class="col-2">
            <mat-label> DOS <span class="asterisk">*</span></mat-label>
            <input
              matInput
              maxlength="15"
              type="text"
              formControlName="txtDOS"
              placeholder="DOS"
              [matDatepicker]="dos"
            />
            <mat-datepicker-toggle matSuffix [for]="dos">
            </mat-datepicker-toggle>
            <mat-datepicker #dos> </mat-datepicker>
            <mat-error
              *ngIf="
                addItemForm?.get('txtDOS')?.touched &&
                addItemForm?.get('txtDOS')?.errors
              "
            >
              Enter Valid date
            </mat-error>
          </mat-form-field>
          <button
            class="buttonColor"
            (click)="addItemToTable()"
            [disabled]="addItemForm.invalid"
            mat-raised-button
          >
            Add Item
          </button>
        </form>
      </div>
    </div>

    <!-- Item Table -->
    <div class="col-lg-10">
      <div class="card mt-2">
        <div class="card-body">
          <form [formGroup]="tblItemForm">
            <div class="table table-responsive">
              <table
                datatable
                [dtOptions]="dtItemTableOptions"
                [dtTrigger]="dtTrigger"
                class="row-border w-100 hover"
              >
                <thead>
                  <tr>
                    <th [hidden]="true">Item Id</th>
                    <th [hidden]="true">Editable</th>
                    <th>#</th>
                    <th>Item ID</th>
                    <th>Name</th>
                    <th>Insurance Name</th>
                    <th>Type</th>
                    <th>Price</th>
                    <th>Ordering Units</th>
                    <th>DOS</th>
                  </tr>
                </thead>
                <tbody formArrayName="itemsList">
                  <tr
                    *ngFor="let field of fieldsArray.controls; let i = index"
                    [formGroupName]="i"
                  >
                    <td [hidden]="true"></td>
                    <td [hidden]="true"></td>

                    <!-- Options -->
                    <td>
                      <i
                        class="fa fa-pencil pointer editBtnColor mr-3"
                        (click)="editTable(field.get('itemId').value)"
                        matTooltip="Click To Edit"
                        *ngIf="field.get('editClicked').value == false"
                      ></i>
                      <i
                        class="fa fa-trash pointer text-danger mr-3"
                        matTooltip="Delete"
                        (click)="deleteItem(field.get('itemId').value, i)"
                        *ngIf="field.get('editClicked').value == false"
                      ></i>
                      <!-- <button
                        class="editBtnColor pointer fa fa-check-circle-o"
                        (click)="saveItem(field.get('itemId').value, i)"
                        *ngIf="field.get('editClicked').value == true"
                        [disabled]="tblItemForm.invalid"
                      >
                        <i matTooltip="Save"></i>
                      </button> -->

                      <button
                        [disabled]="tblItemForm.invalid"
                        mat-mini-fab
                        class="wip-status-color"
                      >
                        <mat-icon
                          matTooltip="Click To Complete"
                          *ngIf="field.get('editClicked').value == true"
                          (click)="saveItem(field.get('itemId').value, i)"
                          class="wip-danger-status-color"
                          >check_circle_outline</mat-icon
                        >
                      </button>
                    </td>

                    <!-- Item ID -->
                    <td>
                      <ng-container *ngIf="field.get('editClicked').value">
                        <mat-form-field>
                          <mat-label>
                            Item <span class="asterisk">*</span></mat-label
                          >
                          <mat-select
                            [value]="field.get('itemId').value"
                            placeholder="Item ID"
                            formControlName="drpItem"
                          >
                            <mat-option [value]="'Option 1'">
                              Option 1
                            </mat-option>
                          </mat-select>
                          <mat-error
                            class="spanAlignment"
                            *ngIf="
                              tblItemForm?.get('drpItem')?.touched &&
                              tblItemForm?.get('drpItem')?.errors?.required
                            "
                          >
                            Item ID is a required field!
                          </mat-error>
                        </mat-form-field>
                      </ng-container>
                      <ng-container *ngIf="!field.get('editClicked').value">
                        {{ field.get("drpItem").value }}
                      </ng-container>
                    </td>
                    <!-- Item Name-->
                    <td>
                      <ng-container>
                        {{ field.get("drpItem").value }}
                      </ng-container>
                    </td>

                    <!-- Insurance Name -->
                    <td>
                      <ng-container *ngIf="field.get('editClicked').value">
                        <mat-form-field>
                          <mat-label>
                            Insurance <span class="asterisk">*</span></mat-label
                          >
                          <mat-select
                            formControlName="drpInsurance"
                            [value]="field.get('drpInsurance').value"
                            placeholder="Insurance"
                          >
                            <!-- <mat-option
      *ngFor="let gender of drpGenderLoop"
      [value]="gender.genderShortCode"
    >
      {{ gender.genderName }}
    </mat-option> -->

                            <mat-option [value]="'Option 1'"
                              >Option 1</mat-option
                            >
                          </mat-select>
                          <mat-error
                            class="spanAlignment"
                            *ngIf="
                              tblItemForm?.get('drpInsurance')?.touched &&
                              tblItemForm?.get('drpInsurance')?.errors?.required
                            "
                          >
                            Insurance is a required field!
                          </mat-error>
                        </mat-form-field>
                      </ng-container>
                      <ng-container *ngIf="!field.get('editClicked').value"
                        >{{ field.get("drpInsurance").value }}
                      </ng-container>
                    </td>

                    <!-- Rent or Sale -->
                    <td>
                      <ng-container *ngIf="field.get('editClicked').value">
                        <mat-form-field>
                          <mat-label>
                            Type <span class="asterisk">*</span></mat-label
                          >
                          <mat-select
                            id="drpSaleOrRent"
                            placeholder="Type"
                            formControlName="drpSaleOrRent"
                            [value]="field.get('drpSaleOrRent').value"
                          >
                            <!-- <mat-option
                    *ngFor="let data of drpSaleOrRents"
                    [value]="data.saleOrRent"
                  >
                    {{ data.saleOrRent }}
                  </mat-option> -->
                            <mat-option [value]="'Option 1'">
                              Option 1
                            </mat-option>
                          </mat-select>
                          <mat-error
                            class="spanAlignment"
                            *ngIf="
                              tblItemForm?.get('drpSaleOrRent')?.touched &&
                              tblItemForm?.get('drpSaleOrRent')?.errors
                                ?.required
                            "
                          >
                            Type is a required field!
                          </mat-error>
                        </mat-form-field>
                      </ng-container>
                      <ng-container *ngIf="!field.get('editClicked').value"
                        >{{ field.get("drpSaleOrRent").value }}
                      </ng-container>
                    </td>

                    <!-- Price-->
                    <td>
                      <ng-container *ngIf="field.get('editClicked').value">
                        <mat-form-field>
                          <mat-label>
                            Price <span class="asterisk">*</span></mat-label
                          >
                          <input
                            matInput
                            formControlName="txtPrice"
                            min="1"
                            [value]="field.get('txtPrice').value"
                            max="1000"
                            type="number"
                            placeholder="Price($)"
                            maxlength="15"
                            autocomplete="off"
                          />
                          <mat-error
                            class="spanAlignment"
                            *ngIf="
                              tblItemForm?.get('txtPrice')?.touched &&
                              tblItemForm?.get('txtPrice')?.errors?.required
                            "
                          >
                            Price is a required field!
                          </mat-error>
                        </mat-form-field>
                      </ng-container>
                      <ng-container *ngIf="!field.get('editClicked').value">
                        {{ field.get("txtPrice").value }}</ng-container
                      >
                    </td>

                    <!-- Ordering Units -->
                    <td>
                      <ng-container *ngIf="field.get('editClicked').value">
                        <mat-form-field>
                          <mat-label>
                            Ordering Units
                            <span class="asterisk">*</span></mat-label
                          >
                          <input
                            [value]="field.get('txtOrderingUnits').value"
                            matInput
                            min="1"
                            formControlName="txtOrderingUnits"
                            max="1000"
                            type="number"
                            placeholder="Ordering Units"
                            maxlength="15"
                            autocomplete="off"
                          />
                        </mat-form-field>
                        <mat-error
                          class="spanAlignment"
                          *ngIf="
                            tblItemForm?.get('txtOrderingUnits')?.touched &&
                            tblItemForm?.get('txtOrderingUnits')?.errors
                              ?.required
                          "
                        >
                          Price is a required field!
                        </mat-error>
                      </ng-container>
                      <ng-container *ngIf="!field.get('editClicked').value"
                        >{{ field.get("txtOrderingUnits").value }}
                      </ng-container>
                    </td>

                    <!-- DOS -->
                    <td>
                      <ng-container *ngIf="field.get('editClicked').value">
                        <div class="d-flex justify-content-center">
                          <mat-form-field>
                            <mat-label>
                              DOS <span class="asterisk">*</span></mat-label
                            >
                            <input
                              matInput
                              formControlName="txtDOS"
                              [value]="field.get('txtDOS').value"
                              maxlength="15"
                              type="text"
                              placeholder="DOS"
                              [matDatepicker]="dos"
                            />
                            <mat-datepicker-toggle matSuffix [for]="dos">
                            </mat-datepicker-toggle>

                            <mat-datepicker #dos> </mat-datepicker>
                            <mat-error
                              *ngIf="
                                tblItemForm?.get('txtDOS')?.touched &&
                                tblItemForm?.get('txtDOS')?.errors
                              "
                            >
                              Enter Valid date
                            </mat-error>
                            <mat-error
                              *ngIf="
                                tblItemForm?.get('txtDOS')?.touched &&
                                tblItemForm?.get('txtDOS')?.errors?.required
                              "
                            >
                              DOS is a required field!
                            </mat-error>
                          </mat-form-field>
                        </div>
                      </ng-container>
                      <ng-container *ngIf="!field.get('editClicked').value">
                        {{ field.get("txtDOS").value }}
                      </ng-container>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </form>
        </div>
      </div>
    </div>
    <!-- Item Summary -->
    <div class="col-lg-2">
      <div class="card mt-2">
        <div class="card-body p-4">
          <h3 class="header-text mb-0">Order Info</h3>
          <form [formGroup]="itemDetailsForm">
            <!-- Deductible -->
            <mat-form-field class="w-100">
              <mat-label>Deductible</mat-label>
              <input
                readonly
                matInput
                maxlength="40"
                formControlName="txtDeductible"
                type="text"
                value="21.32"
                placeholder="Deductible"
                autocomplete="off"
              />
            </mat-form-field>
            <!-- Copay -->
            <mat-form-field class="w-100">
              <mat-label>Copay</mat-label>
              <input
                matInput
                readonly
                maxlength="40"
                formControlName="txtCopay"
                type="text"
                value="32.32"
                placeholder="Copay"
                autocomplete="off"
              />
            </mat-form-field>
            <!-- Estimated Allowable -->
            <mat-form-field class="w-100">
              <mat-label>Estimated Allowable</mat-label>
              <input
                matInput
                readonly
                maxlength="40"
                value="24.36"
                formControlName="txtEstAllowable"
                type="text"
                placeholder="Estimated Allowable"
                autocomplete="off"
              />
            </mat-form-field>

            <!-- Estimated Copay -->
            <mat-form-field class="w-100">
              <mat-label>Estimated Copay</mat-label>
              <input
                matInput
                readonly
                maxlength="40"
                value="35.45"
                formControlName="txtEstCopay"
                type="text"
                placeholder="Estimated Copay"
                autocomplete="off"
              />
            </mat-form-field>

            <!-- Total Accessory -->
            <mat-form-field class="w-100">
              <mat-label>Total Accessory</mat-label>
              <input
                matInput
                readonly
                maxlength="40"
                value="43.35"
                formControlName="txtTotalAccessory"
                type="text"
                placeholder="Total Accessory"
                autocomplete="off"
              />
            </mat-form-field>

            <!-- Shipping Fee -->
            <mat-form-field class="w-100">
              <mat-label>Shipping Fee</mat-label>
              <input
                matInput
                readonly
                maxlength="40"
                value="63.25"
                formControlName="txtShippingFee"
                type="text"
                placeholder="Shipping Fee"
                autocomplete="off"
              />
            </mat-form-field>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- <br />
<div class="card card-body">
  <div class="row">
    <div class="col-lg-12">
      <mat-card-content>
        <div class="row mar">
          <div class="col-12 text-right">
            <button
              mat-button
              [disabled]="!editFormValues"
              class="buttonColor font-size-14"
              (click)="openItemModal()"
            >
              <i class="fa fa-plus mr-1"></i>
              Add Item
            </button>
          </div>
        </div>
      </mat-card-content>
    </div>
    <div class="col-12 table-responsive" style="padding-top: 6px">
      <table class="table table-bordered">
        <thead>
          <th>DOS</th>
          <th>Item Name</th>
          <th>Item code</th>
          <th>Price Per Unit</th>
          <th>Units</th>
          <th>Price</th>
          <th>Options</th>
        </thead>
        <tbody>
          <tr *ngFor="let record of tblItemReponse; let i = index">
            <td>
              {{ record?.dosFrom | date : "MM/dd/yyyy" : "en_US" }}
            </td>
            <td>
              {{ record?.itemName }}
            </td>
            <td>
              {{ record?.itemProcedureCode }}
            </td>
            <td>$ {{ +record?.charge | number : ".2-2" }}</td>
            <td>
              {{ +record?.orderingQuantity }}
            </td>
            <td>$ {{ +record?.totalPrice | number : ".2-2" }}</td>
            
            <td>
              <a
                ><i
                  matTooltip="Click to Edit"
                  (click)="openItemModal(record?.id)"
                  class="fa fa-pencil mr-3 pencilColor"
                ></i>
              </a>
             <a class="deleteBtnColor">
                <i
                  matTooltip="Click to Delete"
                  (click)="deleteItem(record?.id)"
                  class="fa fa-trash"
                ></i>
              </a>
            </td>
          </tr>
          <tr *ngIf="tblItemReponse && tblItemReponse?.length !== 0">
            <td colspan="4" class="text-right"><b>Total</b></td>
            <td colspan="1">
              <b>{{ totalQty }}</b>
            </td>
            <td colspan="1">
              <b>$ {{ +totalPrice | number : ".2-2" }}</b>
            </td>
            <td colspan="4"></td>
          </tr>
        </tbody>
        <tfoot>
          <ng-container
            *ngIf="tblItemReponse && tblItemReponse?.length === 0 && !isLoading"
          >
            <td class="text-align-center" colspan="12">No Records Found</td>
          </ng-container>
          <ng-container *ngIf="isLoading">
            <td class="text-align-center" colspan="12">
              <mat-spinner [diameter]="220"></mat-spinner>
            </td>
          </ng-container>
        </tfoot>
      </table>
    </div>
  </div>
</div> -->
