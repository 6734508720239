import { PagedAndSortedResultRequestDto } from '@abp/ng.core';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatCheckbox, MatCheckboxChange } from '@angular/material/checkbox';
import { MatAccordion } from '@angular/material/expansion';
import Swal from 'sweetalert2';

import { MasterRulesService } from '../admin-proxy/platform-app-management/rcm/platform-management/tenant';
import { MasterRulesDTO, UpdateRuleDTO } from '../admin-proxy/platform-app-management/rcm/platform-management/tenants/dto';
import { ToastrService } from "ngx-toastr";
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-rule-master',
  templateUrl: './rule-master.component.html',
  styleUrls: ['./rule-master.component.scss']
})
export class RuleMasterComponent implements OnInit {
  @Input() clientId: string = '';
  isShowSpinner : boolean = false;
  isEnableSaveButton: boolean = false;
  step: boolean = false;
  @ViewChild('select_All') selectAllELe: MatCheckbox;
  RuleMasterForm: FormGroup;
  @ViewChild(MatAccordion) accordion: MatAccordion;
  rulesValues: MasterRulesDTO[] = [];
  constructor(
    private ruleMasterService: MasterRulesService,
    private toastr: ToastrService,
    public title: Title,
  ) { }
  //! page initialization function
  ngOnInit(): void {
    this.title.setTitle("Qsecure | Rule Master");
    this.RuleMasterForm = new FormGroup({
      chkAllRules: new FormControl(""),
      chkRule001: new FormControl(""),
      chkRule002: new FormControl(""),
      chkRule003: new FormControl(""),
      chkRule004: new FormControl(""),
      chkRule005: new FormControl(""),
      chkRule006: new FormControl(""),
      chkRule007: new FormControl(""),
      chkRule008: new FormControl(""),
      chkRule009: new FormControl(""),
      chkRule010: new FormControl(""),
  //    chkRule011: new FormControl(""),
      chkRule012: new FormControl(""),
      chkRule013: new FormControl(""),
      chkRule014: new FormControl(""),
      chkRule015: new FormControl(""),
      chkRule016: new FormControl(""),
      chkRule017: new FormControl(""),
      chkRule018: new FormControl(""),
      chkRule019: new FormControl(""),
      chkRule020: new FormControl(""),
      chkRule021: new FormControl(""),
      chkRule022: new FormControl(""),
      chkRule023: new FormControl(""),
      chkRule024: new FormControl(""),
      chkRule025: new FormControl(""),
      chkRule026: new FormControl(""),
      chkRule027: new FormControl(""),
      chkRule028: new FormControl(""),
      chkRule029: new FormControl(""),
      chkRule030: new FormControl(""),
      chkRule031: new FormControl(""),
      chkRule032: new FormControl(""),
      chkRule033: new FormControl(""),
      chkRule034: new FormControl(""),
      chkRule035: new FormControl(""),
      chkRule036: new FormControl(""),
      chkRule037: new FormControl(""),
      chkRule038: new FormControl(""),
      chkRule039: new FormControl(""),
      chkRule040: new FormControl(""),
      chkRule041: new FormControl(""),
      chkRule042: new FormControl(""),
      chkRule043: new FormControl(""),
      chkRule044: new FormControl(""),
      chkRule045: new FormControl(""),
      chkRule047: new FormControl(""),
      chkRule048: new FormControl(""),
      chkRule049: new FormControl(""),
      chkRule050: new FormControl(""),
      
    });
    
    this.clientId = this.clientId ?? "";
    this.clientId && this.getRulesByCLientId();
  }

  expandAll(){
    this.step = true
  }
  //! Get rule master page by id
  getRulesByCLientId() {
    this.ruleMasterService.getList(new PagedAndSortedResultRequestDto).subscribe(response => {
      const rules: MasterRulesDTO[] = response?.items ?? [];
      if (rules && rules?.length !== 0) {
        let formElements: { key?: string, checkedorNot?: boolean }[] = [];
        rules?.forEach((element) => {
          if (element != null && element && element.shortCodeId!=="011") {
            formElements?.push({ key: element?.shortCodeId, checkedorNot: Boolean(element?.isCheckValidation) ?? false })
            this.RuleMasterForm?.patchValue({ [element?.shortCodeId.padStart(10, "chkRule")]: Boolean(element?.isCheckValidation) ?? false });
          }
        });
        if (formElements?.every(e => e?.checkedorNot === true)) { this.RuleMasterForm?.patchValue({ 'chkAllRules': true }); } else {
          this.RuleMasterForm?.patchValue({ 'chkAllRules': false });
        }
        this.rulesValues = rules ?? [];
      }
    }, err => {
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    })
  }
  //! save rule master page
  saveRuleMaster() {
    this.isEnableSaveButton = true;
    this.isShowSpinner=true;
    const ruleFormValue: any = this.RuleMasterForm.value;
    const ruleValue: MasterRulesDTO[] = this.rulesValues ?? [];
    let formElements: { key?: string, checkedorNot?: boolean }[] = [];
    Object?.keys(ruleFormValue)?.forEach((key) => {
      const ctrlNames = Object?.entries(ruleFormValue)?.filter(e => e[0] === key);
      if (ctrlNames != null && ctrlNames && key !== 'chkAllRules') {
        const checkedorNot = ctrlNames[0][1] === true ? true : false;
        const ruleNo = key?.replace('chkRule', '') ?? "";
        formElements?.push({ key: ruleNo, checkedorNot: checkedorNot })
      }
    });

    let newRulesData: UpdateRuleDTO[] = [];
    ruleValue.forEach(element => {
      let data: UpdateRuleDTO;
      data = {
        shortCodeId: element?.shortCodeId,
        categoryShortCodeId: element?.categoryShortCodeId,
        masterRuleId: element?.id,
        validationPeriod: element?.validationPeriod,
        isCheckValidation: (+formElements?.filter(e => e?.key === element?.shortCodeId)[0]?.checkedorNot) === 1 ? 1 : 0,
      }
      newRulesData?.push(data);
    })
    this.ruleMasterService.updateMasterRuleValidationsByLstMasterRules(newRulesData).subscribe(response => {
      // Swal.fire({ title: 'Success', html: 'Saved Successfully', icon: 'success', timer: 3000, timerProgressBar: true });
      this.toastr.success('Saved Successfully','Success')
      this.getRulesByCLientId();
      this.isEnableSaveButton = false;
      this.isShowSpinner=false;
    }, err => {
      this.isEnableSaveButton = false;
      this.isShowSpinner=false;
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    })
  }
  //! Select all chkbx
  selectAll(event: MatCheckboxChange) {
    const rv = this.RuleMasterForm?.value;
    Object?.keys(rv)?.forEach((key) => {
      this.RuleMasterForm?.patchValue({ [key]: event?.checked ?? false });
    });
  }
  //! On change other chkbx check for select all chkbx
  changeRuleMasterCheckBox(event: MatCheckboxChange, controlName?: string) {
    const rv = this.RuleMasterForm?.value;
    let formElements: { key?: string, checkedorNot?: boolean }[] = [];
    Object?.keys(rv)?.forEach((key) => {
      const ctrlNames = Object?.entries(rv)?.filter(e => e[0] === key);
      if (ctrlNames != null && ctrlNames && key !== 'chkAllRules') {
        const checkedorNot = ctrlNames[0][1] === true ? true : false;
        formElements?.push({ key: key, checkedorNot: checkedorNot })
      }
    });
    if (formElements?.every(e => e?.checkedorNot === true)) {
      this.RuleMasterForm?.patchValue({ chkAllRules: true });
    }
    else {
      this.RuleMasterForm?.patchValue({ chkAllRules: false });
    }
  }
  isExpanded = false;
  toggleAccordion() {
    this.isExpanded = !this.isExpanded;

    if (this.isExpanded) {
      this.accordion.openAll();
    } else {
      this.accordion.closeAll();
    }
  }

}
