<table datatable [dtOptions]="dtCalllogTableOptions" class="tbl hover row-border hover w-100 display">
    <thead>
      <tr>
        <th>Date Called</th>
        <th>Called By</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let log of calllogTableData">
        <td (dblclick)="onRowDoubleClick(getFormattedDate(log?.calledOn))" [matTooltip]="getFormattedDate(log?.calledOn)">
          {{ getFormattedDate(log?.calledOn) }}
        </td>
        <td (dblclick)="onRowDoubleClick(log.calledBy || '-')" [matTooltip]="log.calledBy || '-'">{{ log.calledBy || '-' }}</td>
      </tr>
    </tbody>
  </table>