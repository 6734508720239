<div class="container-fluid pr-0 pl-0 mt-2">
    <div class="row">
        <div class="col-12">
            <div class=" d-flex align-items-center justify-content-between m-0">
                <h4 class="mb-0 font-size-18"><b>ERA Payments</b></h4>
                <div class="page-title-box  page-title-right pt-2 pb-0">
                    <ol class="breadcrumb m-0">
                        <li class="ml-2 mt-2 breadcrumb-item">Patient</li>
                        <li class="mt-2 breadcrumb-item muted">Payments</li>
                    </ol>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="col-lg-12">
    <app-table [strPageType]="strPageType">
    </app-table>
</div>
<br>
<div class="col-12">
    <h5>Claim Status</h5>
</div>

<div class="col-lg-12">
    <app-table [strPageType]="strPageType2">
    </app-table>
</div>
