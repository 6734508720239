<div class="container-fluid">
  <!-- Headers -->
  <div class="row">
    <div class="col-12 mt-2 d-flex justify-content-between">
      <h3 class="font-weight-bolder mb-2">
        <b class="customThemeClass">List / Shipping Dept / Shipping List</b>
      </h3>
    </div>
  </div>

  <!-- First Row -->
  <div class="row">
    <div class="col-12 mt-2">
      <div class="row">
        <!-- Shipping List Table -->
        <div class="col-10">
          <div class="card card-body">
            <div
              class="d-flex justify-content-center"
              *ngIf="!isShippingListTableLoaded"
            >
              <!-- <mat-spinner></mat-spinner> -->
              <img
                class="img-responsive pb-2 mr-1"
                src="assets/gifs/Infinity-loader.gif"
              />
            </div>

            <div class="table table-responsive">
              <table
                datatable
                *ngIf="isShippingListTableLoaded"
                [dtOptions]="dtShippingListOptions"
                class="row-border hover w-100 display"
              >
                <thead>
                  <tr>
                    <th>#</th>
                    <th><mat-icon>apps</mat-icon></th>
                    <th [matTooltip]="'Shipping Status'">Shipping Status</th>
                    <th [matTooltip]="'Ticket Id'" class="text-center">
                      Ticket Id
                    </th>
                    <th [matTooltip]="'Supplies Date'">Supplies Date</th>
                    <th [matTooltip]="'Chart No'">Chart No</th>
                    <th [matTooltip]="'Patient Name'">Patient Name</th>
                    <th [matTooltip]="'Insurance/Cash'">Insurance/Cash</th>
                    <th [matTooltip]="'Shipping Method'">Shipping Method</th>
                    <th [matTooltip]="'CSR'">CSR</th>
                    <th [matTooltip]="'Exchange'">Exchange</th>
                    <th [matTooltip]="'Since'">Since</th>
                    <th [matTooltip]="'State'">Location</th>
                    <th [matTooltip]="'Created By'">Created By</th>
                    <th class="text-right" [matTooltip]="'Created Date'">
                      Created Date
                    </th>
                    <th [matTooltip]="'Modify By'">Modify By</th>
                    <th class="text-right" [matTooltip]="'Modified Date'">
                      Modified Date
                    </th>
                    <th [matTooltip]="'Early Ship'">Early Ship</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    (click)="tblShpSelectedRow(item)"
                    [ngClass]="
                      this.selectedShippingOrderId === item.mmOrderId
                        ? 'row-highlighted'
                        : ''
                    "
                    *ngFor="let item of shippingList"
                  >
                    <td></td>
                    <td [matTooltip]="'Open Options'">
                      <mat-icon
                        style="cursor: pointer !important"
                        [matMenuTriggerFor]="menu"
                        >more_vert</mat-icon
                      >
                      <mat-menu #menu="matMenu">
                        <!-- <button mat-menu-item (click)="openAddNotesPopUp(item.defaultFaxId,item.patientId,item.docId,false)">

                        Pending Notes
                      </button> -->
                        <button
                          mat-menu-item
                          (click)="
                            openAddNotesPopUp(
                              item.defaultFaxId,
                              item.patientId,
                              item.docId,
                              false,
                              item.chartNo
                            )
                          "
                        >
                          <!-- [disabled]="
                          data.patientId === defaultGuid ||
                          data.patientId === null ||
                          data.patientId === ''
                        " -->
                          Add Patient Note
                        </button>
                        <button
                          mat-menu-item
                          (click)="
                            openViewNotesPopup(
                              item.patientId,
                              item.defaultFaxId,
                              item.docId
                            )
                          "
                        >
                          View Patient Notes
                        </button>
                        <button
                          mat-menu-item
                          (click)="
                            viewPatientLedger(item?.patientId, item?.chartNo)
                          "
                        >
                          View Patient Ledger
                        </button>

                        <button
                          [class.disabled]="
                            item.mmOrderId === defaultGuid ||
                            item.mmOrderId === null ||
                            item.mmOrderId === ''
                          "
                          (click)="
                            getOrderHistory(
                              item.mmOrderId,
                              item.mmRefereceOrderId,
                              item.chartNo,
                              item.fullName
                            )
                          "
                          mat-menu-item
                        >
                          Order History
                        </button>

                        <a
                          [routerLink]="
                            '/view_document_tiff/' +
                            item.faxDocumentId +
                            '/' +
                            true
                          "
                          target="_blank"
                          class="preview-link"
                          [class.disabled]="
                            isEmpty(item.faxDocumentId) ||
                            item?.isSorted === false ||
                            item?.isSorted === null
                          "
                        >
                          <button
                            [disabled]="
                              isEmpty(item.faxDocumentId) ||
                              item?.isSorted === false ||
                              item?.isSorted === null
                            "
                            mat-menu-item
                          >
                            View Document Image
                          </button>
                        </a>

                        <!-- <button mat-menu-item (click)="checkListModel(item)">
                        Document CheckList
                      </button> -->
                      </mat-menu>
                    </td>
                    <td [matTooltip]="item?.shippingStatus || '-'">
                      {{
                        item.shippingStatus === null ||
                        item.shippingStatus === ""
                          ? "-"
                          : item.shippingStatus
                      }}
                    </td>
                    <td
                      class="text-center"
                      [matTooltip]="item?.mmRefereceOrderId || '-'"
                    >
                      <a
                        class="preview-link"
                        [class.disabled]="
                          isEmpty(item.chartNo) || isEmpty(item.patientId)
                        "
                        [routerLink]="
                          '/orders/saleOrder/' +
                          item.patientId +
                          '/' +
                          item.mmOrderId +
                          '/' +
                          '0'
                        "
                        target="_blank"
                      >
                        {{
                          item.mmRefereceOrderId === null ||
                          item.mmRefereceOrderId === ""
                            ? "-"
                            : item.mmRefereceOrderId
                        }}
                      </a>
                    </td>
                    <!-- item?.suppliesDate
                          ? (item?.suppliesDate | date : 'MM/dd/yyyy' : 'en_US')
                          : '-' -->
                    <td [matTooltip]="item?.suppliesDate">
                      {{ item?.suppliesDate }}
                    </td>
                    <td [matTooltip]="item?.chartNo || '-'">
                      <a
                        class="preview-link"
                        [class.disabled]="
                          isEmpty(item.chartNo) || isEmpty(item.patientId)
                        "
                        [routerLink]="
                          '/patientCreateEditTab/' +
                          item.patientId +
                          '/' +
                          item.faxDocumentId
                        "
                        target="_blank"
                      >
                        {{
                          item.chartNo === null || item.chartNo === ""
                            ? "-"
                            : item.chartNo
                        }}
                      </a>
                    </td>
                    <td [matTooltip]="item?.fullName || '-'">
                      {{
                        item.fullName === null || item.fullName === ""
                          ? "-"
                          : item.fullName
                      }}
                    </td>
                    <td
                      [matTooltip]="
                        item.isCashOrder
                          ? 'Cash'
                          : item.insuranceName === null ||
                            item.insuranceName === ''
                          ? '-'
                          : item.insuranceName
                      "
                    >
                      {{
                        item.isCashOrder
                          ? "Cash"
                          : item.insuranceName === null ||
                            item.insuranceName === ""
                          ? "-"
                          : item.insuranceName
                      }}
                    </td>
                    <td [matTooltip]="item?.shippingMethod || '-'">
                      {{
                        item.shippingMethod === null ||
                        item.shippingMethod === ""
                          ? "-"
                          : item.shippingMethod
                      }}
                    </td>
                    <td [matTooltip]="item?.username || '-'">
                      {{
                        item.username === null || item.csrName === ""
                          ? "-"
                          : item.csrName
                      }}
                    </td>

                    <td [matTooltip]="item?.isExchangeOrder ? 'Yes' : 'No'">
                      {{ item?.isExchangeOrder ? "Yes" : "No" }}
                    </td>
                    <!-- item?.backOrderSince
                          ? (item?.backOrderSince
                            | date : 'MM/dd/yyyy' : 'en_US')
                          : '-' -->
                    <td [matTooltip]="item?.backOrderSince">
                      {{ item?.backOrderSince }}
                    </td>
                    <td [matTooltip]="item?.state || '-'">
                      {{
                        item?.state === null || item?.state === ""
                          ? "-"
                          : item?.state
                      }}
                    </td>
                    <td
                      [matTooltip]="item?.createdByName || '-'"
                      (dblclick)="
                        onRowDoubleClickV1(item?.createdByName || '-')
                      "
                    >
                      {{ item?.createdByName || "-" }}
                    </td>

                    <td
                      class="text-right"
                      [matTooltip]="item?.createdDate"
                      (dblclick)="onRowDoubleClickV1(item?.createdDate)"
                    >
                      {{ item?.createdDate }}
                    </td>
                    <td
                      [matTooltip]="item?.modifyBy || '-'"
                      (dblclick)="onRowDoubleClickV1(item?.modifyBy || '-')"
                    >
                      {{ item?.modifyBy || "-" }}
                    </td>

                    <td
                      class="text-right"
                      [matTooltip]="item?.modifiedDate"
                      (dblclick)="onRowDoubleClickV1(item?.modifiedDate)"
                    >
                      {{ item?.modifiedDate }}
                    </td>
                    <td [matTooltip]="item?.earlyShip || '-'">
                      {{
                        item?.earlyShip === null || item?.earlyShip === ""
                          ? "-"
                          : item?.earlyShip
                      }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <!-- Notes Details -->
        <div class="col-2">
          <div class="card card-body p-2">
            <mat-form-field class="w-100">
              <mat-label>Address</mat-label>
              <textarea
                readonly
                rows="4"
                placeholder="Address"
                [value]="shippingListNotesDetails?.pAddress"
                maxlength="5000"
                matInput
              ></textarea>
            </mat-form-field>
            <mat-form-field class="w-100">
              <mat-label>Shipping Comments</mat-label>
              <textarea
                [value]="shippingListNotesDetails?.shippingNotes"
                readonly
                rows="4"
                placeholder="Shipping Comments"
                maxlength="5000"
                matInput
              ></textarea>
            </mat-form-field>
            <mat-form-field class="w-100">
              <mat-label>Patient Comments</mat-label>
              <textarea
                readonly
                [value]="shippingListNotesDetails?.patientNotes"
                rows="4"
                placeholder="Patient Comments"
                maxlength="5000"
                matInput
              ></textarea>
            </mat-form-field>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Second Row -->
  <div class="row">
    <div class="col-12">
      <div class="card card-body p-2 mt-2">
        <form [formGroup]="shippingListForm">
          <div class="col-12 px-0">
            <!-- Row 1-->
            <div class="row">
              <div class="col-12">
                <div class="d-flex justify-content-between align-items-center">
                  <button
                    (click)="generateDazzleXML(PrintLabel.DefaultLabel, false)"
                    type="button"
                    mat-raised-button
                    class="buttonColor mr-3 px-1 col-1"
                  >
                    <img
                      class="img-responsive pr-1"
                      src="assets/Shipping List Icons/Shipping Label.png"
                      alt=""
                      height="12"
                    />Shipping Label
                  </button>

                  <button
                    type="button"
                    mat-raised-button
                    (click)="getPrintTicketDetails()"
                    class="buttonColor mr-3 px-1 col-1"
                  >
                    <mat-icon>print</mat-icon>Print Ticket
                  </button>

                  <button
                    (click)="generateDazzleXML(PrintLabel.DefaultLabel, true)"
                    type="button"
                    mat-raised-button
                    class="buttonColor mr-3 px-1 col-1"
                  >
                    <img
                      class="img-responsive pr-1"
                      src="assets/Shipping List Icons/Return label.png"
                      alt=""
                      height="12"
                    />Return Label
                  </button>
                  <button
                    type="button"
                    (click)="notesReason()"
                    [disabled]="isWonaceLogin || isCashOrder === true || isExchangeOrder"
                    mat-raised-button
                    class="buttonColor mr-3 px-1 col-1"
                  >
                    <img
                      class="img-responsive pr-1"
                      src="assets/Shipping List Icons/Disapprove.png"
                      alt=""
                      height="12"
                    />Disapprove
                  </button>
                  <button
                    type="button"
                    (click)="splitShippingItems()"
                    mat-raised-button
                    [disabled]="isWonaceLogin || disableSplitButton || isCashOrder"
                    class="buttonColor mr-3 px-1 col-1"
                  >
                    <img
                      class="img-responsive pr-1"
                      src="assets/Shipping List Icons/Split.png"
                      alt=""
                      height="12"
                    />Split
                  </button>

                <button type="button" (click)="moveToBilling()" mat-raised-button
                  [disabled]="!isWonaceLogin ? 
                    (!isBillingButtonEnabled || isSerialLotSaving || isZeroWeightErrorMsg || isBarCodeErrorMsg) : 
                    !isShippingListTableLoaded || shippingList.length <= 0"
                    class="buttonColor mr-3 px-1 col-1">
                  <img class="img-responsive pr-1" src="assets/Shipping List Icons/To Billing.png" alt="" height="12" />
                     To Billing
                </button>

                  <mat-form-field class="mr-2 col-2">
                    <mat-label
                      >Tracking ID<span class="asterisk" *ngIf="!isWonaceLogin">*</span></mat-label>
                    <!-- <a [class.hidden]="hyperLinkshow"
                    href="https://tools.usps.com/go/TrackConfirmAction_input?strOrigTrackNum={{shippingListForm.value.txtTracking}}" target="_blank" class="preview-link">
                    {{shippingListForm.value.txtTracking}} </a> -->
                    <!-- <i [class.disabled]="hyperLinkshow" [class.hidden]="hyperLinkshow" (click)="editEnableDisable()" class="fa fa-pencil editBtnColor"></i> -->
                    <input
                      autocomplete="off"
                      formControlName="txtTracking"
                      maxlength="25"
                      matInput
                      placeholder="Tracking"
                    />
                    <!-- <i [class.disabled]="hyperLinkshow"  (click)="editEnableDisable()" class="fa fa-pencil editBtnColor"></i> -->
                    <!-- <mat-icon matSuffix -->

                    <!-- href="https://tools.usps.com/go/TrackConfirmAction_input?strOrigTrackNum={{shippingListForm.value.txtTracking}}" -->
                    <a
                      matSuffix
                      [class.disabled]="
                        shippingListForm.value.txtTracking === '' ||
                        shippingListForm.value.txtTracking === null ||
                        shippingListForm?.get('txtTracking')?.errors
                      "
                      href="https://www.ups.com/track?loc=en_US&requester=QUIC&tracknum={{
                        shippingListForm.value.txtTracking
                      }}/"
                      target="_blank"
                      class="preview-link"
                      [matTooltip]="'Click here to Track'"
                    >
                      <img
                        class="img-responsive pr-1 mb-1 cursor"
                        src="assets/Claim Page Icons/tracking.png"
                        alt=""
                        height="17"
                    /></a>
                    <!-- </mat-icon> -->
                    <mat-error
                      *ngIf="shippingListForm?.get('txtTracking')?.errors"
                    >
                      Tracking ID is a required field!</mat-error
                    >
                  </mat-form-field>

                  <button
                    type="button"
                    mat-raised-button
                    (click)="generateDazzleXML(PrintLabel.UPSLabel, false)"
                    class="upsReturnButtonColor mr-2 px-1 col-1"
                  >
                    Print Label
                  </button>
                  <button
                    type="button"
                    mat-raised-button
                    (click)="generateDazzleXML(PrintLabel.UPSLabel, true)"
                    class="upsLabelButtonColor mr-2 px-1 col-1"
                  >
                    UPS Return
                  </button>
                </div>
              </div>
            </div>

            <!-- Row 2-->
            <div class="row mt-2">
              <div class="col-12">
                <div class="d-flex justify-content-between align-items-center">
                  <mat-form-field class="col-2 pl-0 pr-3 mr-2">
                    <mat-label>Weight</mat-label>
                    <mat-select formControlName="drpWeight">
                      <mat-option
                        [value]="data.weightOZ"
                        *ngFor="let data of weightList"
                        >{{ data.description }}</mat-option
                      >
                    </mat-select>
                  </mat-form-field>
                  <mat-form-field class="mr-2 col-1 px-0">
                    <mat-label class="mr-1">Width</mat-label>
                    <input
                      autocomplete="off"
                      type="text"
                      maxlength="8"
                      (keypress)="onKeyPress($event)"
                      formControlName="txtWidth"
                      matInput
                    />
                  </mat-form-field>
                  <mat-form-field class="mr-2 col-1 px-0">
                    <mat-label class="mr-1">Length</mat-label>
                    <input
                      autocomplete="off"
                      type="text"
                      maxlength="8"
                      (keypress)="onKeyPress($event)"
                      formControlName="txtLength"
                      matInput
                    />
                  </mat-form-field>
                  <mat-form-field class="mr-2 col-1 px-0">
                    <mat-label class="mr-1">Height</mat-label>
                    <input
                      autocomplete="off"
                      type="text"
                      maxlength="8"
                      (keypress)="onKeyPress($event)"
                      formControlName="txtHeight"
                      matInput
                    />
                  </mat-form-field>
                  <mat-form-field class="mr-2 col-2 px-0">
                    <mat-label>UPS Label</mat-label>
                    <mat-select formControlName="drpLabel">
                      <mat-option
                        [value]="data.description"
                        *ngFor="let data of upsLabelList"
                        >{{ data.description }}</mat-option
                      >
                    </mat-select>
                  </mat-form-field>
                  <!-- <mat-form-field class="col-2">
                    <mat-label>Shipping Rate</mat-label>
                    <mat-select formControlName="drpShippingRate"> </mat-select>
                  </mat-form-field> -->
                  <mat-checkbox
                    class="col-2 mt-3 dd-text"
                    formControlName="chkSignatureConfirmation"
                  >
                    Signature Confirmation
                  </mat-checkbox>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>

  <!-- Third Row -->
  <div class="row">
    <div class="col-12">
      <div class="card card-body mt-2 p-2 mb-3">
        <div
          class="d-flex justify-content-center"
          *ngIf="!isShippingItemsTableLoaded"
        >
          <img
            class="img-responsive pb-2 mr-1"
            src="assets/gifs/Infinity-loader.gif"
          />
        </div>

        <div *ngIf="isShippingItemsTableLoaded" class="table-responsive">
          <table
            datatable
            [dtOptions]="dtItemTableOptions"
            class="row-border hover w-100 display"
          >
            <thead>
              <tr>
                <th>#</th>
                <th>
                  <mat-icon>apps</mat-icon>
                  <!-- <mat-checkbox
                    (change)="selectAllCheckbox($event)"
                  ></mat-checkbox> -->
                </th>
                <th [matTooltip]="'Bar Code'" class="text-right">
                  Bar Code<span class="asterisk" *ngIf="!isWonaceLogin">*</span>
                </th>
                <th [matTooltip]="'Weight'" class="text-right">
                  Weight<span class="asterisk" *ngIf="!isWonaceLogin">*</span>
                </th>
                <th [matTooltip]="'Product Code'">Product Code</th>
                <th [matTooltip]="'Description'">Description</th>
                <th [matTooltip]="'Size'" class="text-right">Size</th>
                <th [matTooltip]="'Lot/Serial No'">
                  Lot/Serial No<span class="asterisk" *ngIf="!isWonaceLogin">*</span>
                </th>
                <th [matTooltip]="'Ordered Qty'" class="text-right">
                  Ordered Qty
                </th>
                <th [matTooltip]="'Back Ordered Since'">Back Ordered Since</th>
                <th [matTooltip]="'Inventory'">Inventory</th>
                <th [matTooltip]="'Ordered'">Ordered</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let shipping of shippingItemsList">
                <td></td>
                <td>
                  <!-- [disabled]="true" -->
                  <mat-checkbox
                    (change)="checkSelectedItems()"
                    [(ngModel)]="shipping.isSelected"
                  ></mat-checkbox>
                </td>
                <td [matTooltip]="shipping?.barCode || ''" class="text-right">
                  <div [hidden]="true">
                    {{ shipping.barCode }}
                  </div>
                  <input
                    autocomplete="off"
                    maxlength="34"
                    (focus)="getCurrentValue(shipping.barCode)"
                    (focusout)="onBarCodeFocusOut(shipping, shipping.barCode)"
                    class="textbox-border-class dd-text text-right"
                    [(ngModel)]="shipping.barCode"
                    #txtBarCode="ngModel"
                    required
                    matInput
                    type="text"
                    (input)="validateInput(shipping?.productId, txtBarCode)"
                  />

                  <div
                    class="text-danger dd-text text-error text-center mt-1"
                    *ngIf="isBarCodeErrorMsg && productIdBar === shipping.productId && !isWonaceLogin"
                  >
                    Can't start with zero or space
                  </div>
                  <!-- <div
                    class="text-danger dd-text text-error text-center mt-1"
                    *ngIf="
                      txtBarCode.value === '0' ||
                      txtBarCode.value === null ||
                      txtBarCode.value === ''
                    "
                  >
                    Can't start with zero or space
                  </div> -->
                </td>
                <td [matTooltip]="shipping?.weight || ''" class="text-right">
                  <div [hidden]="true">
                    {{ shipping.weight }}
                  </div>
                  <input
                    autocomplete="off"
                    maxlength="8"
                    class="textbox-border-class dd-text text-right"
                    (focus)="getCurrentValue(shipping.weight)"
                    (focusout)="onBarCodeFocusOut(shipping, shipping.weight)"
                    (keypress)="onKeyPress($event)"
                    [(ngModel)]="shipping.weight"
                    #txtShippingWeight="ngModel"
                    required
                    matInput
                    type="text"
                    oninput="this.value = this.value.replace(/[^0-9.]+/g, '');"
                    (input)="
                      preventZeroAtBeginning(shipping?.productId, $event)
                    "
                  />

                  <div
                    class="text-danger dd-text text-error text-center mt-1"
                    *ngIf="isZeroWeightErrorMsg && productIdWeight === shipping.productId && !isWonaceLogin"
                  >
                    Can't start with zero or space
                  </div>
                  <!-- <div
                    class="text-danger dd-text text-error text-center mt-1"
                    *ngIf="
                      txtShippingWeight.value <= 0 ||
                      txtShippingWeight.value === null ||
                      txtShippingWeight.value === ''
                    "
                  >
                    Can't start with zero or space
                  </div> -->

                  <!-- <div class="text-danger dd-text mt-1 text-error" *ngIf="isZeroWeightErrorMsg">
                  Can't start with zero or space
                </div>  (input)="preventZeroAtBeginning($event)"-->
                </td>
                <td [matTooltip]="shipping?.productCode || '-'">
                  {{ shipping.productCode }}
                </td>
                <td [matTooltip]="shipping?.productDescription || '-'">
                  {{ shipping.productDescription }}
                </td>
                <td [matTooltip]="shipping?.size || '-'" class="text-right">
                  {{ shipping.size }}
                </td>
                <td [matTooltip]="shipping?.serialLotNo || ''">
                  <!-- <mat-select style="width: 100px" (selectionChange)="onBarCodeFocusOut(shipping,drpserialotNo.value)" #drpserialotNo [(value)]="shipping.serialLotNo" placeholder="Lot/Serial No" class="custom-dropdown dd-text">
                  <mat-option>
                    <ngx-mat-select-search [formControl]="searchControl" placeholderLabel="Search" noEntriesFoundLabel="No Matches found" (keyup)="filterProducts()">
                    </ngx-mat-select-search>
                  </mat-option>
                  <mat-option *ngFor="let lotnos of filteredOptions" [value]="lotnos">
                    {{ lotnos }}
                  </mat-option>
                </mat-select> -->

                  <!-- <mat-select style="width: 100px" (selectionChange)="onBarCodeFocusOut(shipping,drpserialotNo.value)" #drpserialotNo [(value)]="shipping.serialLotNo" placeholder="Lot/Serial No" class="custom-dropdown dd-text">
                  <mat-option>
                    <ngx-mat-select-search [formControl]="searchControl" placeholderLabel="Search" noEntriesFoundLabel="No Matches found" (keyup)="filterProducts()">
                    </ngx-mat-select-search>
                  </mat-option>
                  <mat-option *ngFor="let lotnos of filteredOptions" [value]="lotnos.lotSerialNo">
                    {{ lotnos.lotSerialNo }}
                  </mat-option>
                </mat-select> -->

                  <mat-select
                    style="width: 110px"
                    (selectionChange)="
                      onBarCodeFocusOut(shipping, drpserialotNo.value)
                    "
                    (closed)="onSerialNoDropdownClose(shipping.productId)"
                    (openedChange)="onSerialNoDropdownOpenChange($event,shipping?.productId)"
                    #drpserialotNo
                    [(value)]="shipping.serialLotNo"
                    placeholder="Serial No (Qty)"
                    class="custom-dropdown dd-text"
                  >
                    <mat-option>
                      <ngx-mat-select-search
                        [formControl]="searchControl"
                        placeholderLabel="Search"
                        noEntriesFoundLabel="No Matches found"
                        (keyup)="filterProducts(shipping?.productId)"
                      >
                      </ngx-mat-select-search>
                    </mat-option>
                    <mat-option
                      *ngFor="let lotnos of shipping.lotSerialNos"
                      [value]="lotnos.lotSerialNo"
                    >
                      {{ lotnos.lotSerialNo + " (" + lotnos.quantiy + ")" }}
                    </mat-option>
                  </mat-select>

                  <!--
                  <input
                    maxlength="34"
                    (focus)="getCurrentValue(shipping.serialLotNo)"
                    (focusout)="
                      onBarCodeFocusOut(shipping, shipping.serialLotNo)
                    "
                    class="textbox-border-class text-right"
                    [(ngModel)]="shipping.serialLotNo"
                    #txtserialLotNo="ngModel"
                    required
                    matInput
                    type="text"
                    (input)="shipping.serialLotNo = shipping.serialLotNo.trim()"
                  /> -->
                  <!-- (input)="validateInput(txtserialLotNo)" -->
                  <!-- <div
                    class="text-danger dd-text text-error text-center mt-1"
                    *ngIf="
                      txtserialLotNo.value === '0' ||
                      txtserialLotNo.value === null ||
                      txtserialLotNo.value === ''
                    "
                  >
                    Can't start with zero or space
                  </div> -->
                </td>
                <td [matTooltip]="shipping?.quantity || '-'" class="text-right">
                  {{ shipping.quantity }}
                </td>
                <td
                  [matTooltip]="
                    shipping?.backOrderedSince
                      ? (shipping?.backOrderedSince
                        | date : 'MM/dd/yyyy' : 'en_US')
                      : '-'
                  "
                >
                  {{
                    shipping.backOrderedSince === null ||
                    shipping.backOrderedSince === ""
                      ? "-"
                      : (shipping.backOrderedSince
                        | date : "MM/dd/yyyy" : "en_US")
                  }}
                </td>
                <td [matTooltip]="shipping?.inventory || '-'">
                  {{
                    shipping.inventory === null || shipping.inventory === ""
                      ? "-"
                      : shipping.inventory
                  }}
                </td>
                <td [matTooltip]="shipping?.ordered || '-'">
                  {{
                    shipping.ordered === null || shipping.ordered === ""
                      ? "-"
                      : shipping.ordered
                  }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>
<app-loader-component *ngIf="isPageLoaded"></app-loader-component>
