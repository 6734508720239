  <!-- Header -->
  <div class="col-12">
    <div class="d-flex justify-content-between align-items-center">
      <div class="page-title-box page-title-left pb-0">
        <h3 class="font-weight-bolder">
          <b class="customThemeClass">Master / Provider</b>
        </h3>
      </div>

      <div class="page-title-box page-title-right pb-0">
          <div class="d-flex align-items-center">
            <mat-radio-group [(ngModel)]="selectedOption" class="mr-2">
              <mat-radio-button class="active" (click)="statusFilterV1(0)" [value]="0"><b>All</b></mat-radio-button>
              <mat-radio-button class="active p-2" (click)="statusFilterV1(1)" [value]="1"><b>Active</b></mat-radio-button>
              <mat-radio-button class="active" (click)="statusFilterV1(2)" [value]="2"><b>Inactive</b></mat-radio-button>
            </mat-radio-group>
              <button matTooltip="Click To Add New Provider" mat-button class=" buttonColor font-size-10"
              (click)="openProviderModal()">
              <i class="fa fa-plus mr-1"></i>
              Add Provider
             </button>

          <!-- </div> -->

            </div>
      </div>
    </div>
  </div>



<div class="col-12">
  <!-- <app-table [strPageType]="strPageType" (viewButton)="ViewProvider($event)" (deleteButton)="deleteProvider($event)">
    </app-table> -->

  <div class="card card-body">
    <div class="row" *ngIf="isLoading">
      <div class="col-12">
        <div class="d-flex justify-content-center" *ngIf="isLoading">
          <!-- <mat-spinner></mat-spinner> -->
          <img class="img-responsive pb-2 mr-1" src="assets/gifs/Infinity-loader.gif" />
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12">
        <!-- <form [formGroup]="providerForm"> -->
        <!-- <div [hidden]="!isLoading"
            style="display: flex; justify-content: center; align-items: center; background: transparent;">
            <mat-progress-bar mode="indeterminate" value='66' diameter="45" class="lightseagreen">
            </mat-progress-bar>
          </div> -->

        <div class="table table-responsive" *ngIf="!isLoading">

          <!-- <table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8 border">
            <ng-container matColumnDef="npi">
              <th class="header" mat-header-cell *matHeaderCellDef mat-sort-header>
                <mat-form-field class="filter">
                  <input autocomplete="new-NPI" matInput formControlName="npi" (keypress)="numberOnly($event)" />
                  <mat-placeholder>NPI</mat-placeholder>
                </mat-form-field>
              </th>
              <td mat-cell *matCellDef="let element">{{ element?.npi }}</td>

            </ng-container>

            <ng-container matColumnDef="Code">
              <th class="header" mat-header-cell *matHeaderCellDef mat-sort-header>
                <mat-form-field class="filter">
                  <input autocomplete="new-code" matInput formControlName="code" />
                  <mat-placeholder>Code</mat-placeholder>
                </mat-form-field>
              </th>
              <td mat-cell *matCellDef="let element"> {{ element?.code }}</td>
            </ng-container>

            <ng-container matColumnDef="Payor Id">

              <th class="header" mat-header-cell *matHeaderCellDef>
                <mat-form-field class="filter">
                  <input autocomplete="new-payorId" matInput formControlName="payorId" />
                  <mat-placeholder>Payor Id</mat-placeholder>
                </mat-form-field>
              </th>
              <td mat-cell *matCellDef="let element">{{ element?.payorId }}</td>
            </ng-container>

            <ng-container matColumnDef="fullName">

              <th class="header" mat-sort-header mat-header-cell *matHeaderCellDef>
                <mat-form-field class="filter">
                  <input autocomplete="new-fullName" matInput formControlName="fullName"
                    (keypress)="alphaOnly($event)" />
                  <mat-placeholder>Name</mat-placeholder>

                </mat-form-field>
              </th>
              <td mat-cell *matCellDef="let element">{{ element?.fullName }}</td>
            </ng-container>

            <ng-container matColumnDef="phone">
              <th class="header" mat-sort-header mat-header-cell *matHeaderCellDef>
                <mat-form-field class="filter">
                  <input autocomplete="new-phone" matInput formControlName="phone" (keypress)="numberOnly($event)" />
                  <mat-placeholder>Phone</mat-placeholder>
                </mat-form-field>
              </th>
              <td mat-cell *matCellDef="let element">{{ element?.phone }}</td>

            </ng-container>

            <ng-container matColumnDef="homeAddress">
              <th class="header" mat-sort-header mat-header-cell *matHeaderCellDef>
                <mat-form-field class="filter">
                  <input autocomplete="new-homeAddress" matInput formControlName="homeAddress" />
                  <mat-placeholder>Address</mat-placeholder>
                </mat-form-field>
              </th>
              <td mat-cell *matCellDef="let element">{{ element?.homeAddress }}</td>
            </ng-container>

            <ng-container matColumnDef="Options">
              <th class="header text-center" mat-header-cell *matHeaderCellDef>Options</th>
              <td mat-cell *matCellDef="let element" class="text-center">
                <a *ngIf='isShownUpdateIconForProvider' (click)="ViewProvider(element?.id)"
                  matTooltip="Click To View Provider">
                  <i class="fa fa-eye pointer editBtnColor pl-3"></i>
                </a>
                <a *ngIf='isDeleteForProvider' (click)="deleteProvider(element?.id)"
                  matTooltip="Click To Delete Provider">
                  <i class="fa fa-trash pointer deleteBtnColor pl-3"></i>
                </a>
              </td>
            </ng-container>
            <ng-container matColumnDef="noRecords">
              <td class="text-align-center" mat-footer-cell *matFooterCellDef colspan="9">
                <div *ngIf="!isLoading">
                  {{"No Records Found"}}
                </div>
                <div [hidden]="!isLoading">
                  <mat-spinner [diameter]="220"></mat-spinner>
                </div>
              </td>
            </ng-container>
            <ng-container matColumnDef="paginator">
              <td mat-footer-cell *matFooterCellDef colspan="9">
                <mat-paginator class="table table-responsive" #MatPaginator [length]="dataSource?.data?.length"
                  [pageSize]="10" [pageIndex]="0" showFirstLastButtons [pageSizeOptions]="[5, 10, 25, 100]">
                </mat-paginator>
              </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
            <tr mat-footer-row *matFooterRowDef="['noRecords']"
              [ngClass]="{ active: dataSource && dataSource.data && dataSource?.data?.length !== 0 }"></tr>
            <tr mat-footer-row *matFooterRowDef="['paginator']"></tr>
          </table> -->

          <app-inventory-provider-table [providerTableDataList]="providerTableDataList" [selectedOption]="selectedOption"></app-inventory-provider-table>
        </div>
      </div>
      <!-- </form> -->
    </div>
  </div>


</div>
