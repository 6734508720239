import type { CreateUpdateReminderPriorityDTO, ReminderPriorityDTO } from './dto/models';
import { RestService } from '@abp/ng.core';
import type { PagedAndSortedResultRequestDto, PagedResultDto } from '@abp/ng.core';
import { Injectable } from '@angular/core';
import type { ActiveStatus } from '../localization/enumeration/active-status.enum';

@Injectable({
  providedIn: 'root',
})
export class ReminderPriorityService {
  apiName = 'platformManagement';

  create = (input: CreateUpdateReminderPriorityDTO) =>
    this.restService.request<any, ReminderPriorityDTO>({
      method: 'POST',
      url: '/api/PlatformApp/reminder-priority',
      body: input,
    },
    { apiName: this.apiName });

  delete = (id: string) =>
    this.restService.request<any, void>({
      method: 'DELETE',
      url: `/api/PlatformApp/reminder-priority/${id}`,
    },
    { apiName: this.apiName });

  get = (id: string) =>
    this.restService.request<any, ReminderPriorityDTO>({
      method: 'GET',
      url: `/api/PlatformApp/reminder-priority/${id}`,
    },
    { apiName: this.apiName });

  getList = (input: PagedAndSortedResultRequestDto) =>
    this.restService.request<any, PagedResultDto<ReminderPriorityDTO>>({
      method: 'GET',
      url: '/api/PlatformApp/reminder-priority',
      params: { sorting: input.sorting, skipCount: input.skipCount, maxResultCount: input.maxResultCount },
    },
    { apiName: this.apiName });

  getRemainderPriorityList = (statusFilter: ActiveStatus) =>
    this.restService.request<any, ReminderPriorityDTO[]>({
      method: 'GET',
      url: '/api/PlatformApp/reminder-priority/remainder-priority-list',
      params: { statusFilter },
    },
    { apiName: this.apiName });

  update = (id: string, input: CreateUpdateReminderPriorityDTO) =>
    this.restService.request<any, ReminderPriorityDTO>({
      method: 'PUT',
      url: `/api/PlatformApp/reminder-priority/${id}`,
      body: input,
    },
    { apiName: this.apiName });

  constructor(private restService: RestService) {}
}
