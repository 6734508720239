import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { noWhitespaceValidator } from 'projects/patient/src/app/add-note-tab/add-note-tab.component';
import { CreateUpdateInsuranceCategoryDTO } from 'projects/patient/src/app/patient-proxy/patient-optimization/dto/models';
import { InsuranceCategoryService } from 'projects/patient/src/app/patient-proxy/patient-optimization/insurance-category.service';

@Component({
  selector: 'app-add-insurance-category',
  templateUrl: './add-insurance-category.component.html',
  styleUrls: ['./add-insurance-category.component.scss']
})
export class AddInsuranceCategoryComponent implements OnInit {
  insuranceDetailsForm: FormGroup;
  constructor(public addInsuranceCategoryModelRef: MatDialogRef<AddInsuranceCategoryComponent>,private formBuilder: FormBuilder,private insuranceCategoryService: InsuranceCategoryService,
    private toastr: ToastrService) { }

  ngOnInit(): void {
    this.initializeForms();
  }
  initializeForms() {
    this.insuranceDetailsForm = this.formBuilder.group({
      txtname: new FormControl('', [Validators.required, noWhitespaceValidator]),
      maskCallIncrement: new FormControl('', [Validators.required]),
      suppliesCallIncrement: new FormControl('', [Validators.required]),
      orderMachinePoints: new FormControl('', [Validators.required]),
      defaultDeduct: new FormControl('', [Validators.required]),
      complianceMachine: new FormControl('', [Validators.required]),
    });
  }
  onCloseClick(): void {
    this.addInsuranceCategoryModelRef.close([]);
  }
  onKeyPress(event: KeyboardEvent) {
    const isNumber = /[0-9]/.test(event.key);
    if (!isNumber) {
      event.preventDefault(); // Prevent the input if the key is not a number
    }
  }
  onPaste(event: ClipboardEvent) {

    const clipboardData = event.clipboardData;
    const pastedData = clipboardData.getData('text');
    const isNumber = /^\d+$/.test(pastedData); //Should contain only whole numbers

    if (!isNumber) {
      event.preventDefault(); // Prevent the input if the key is not a number
    }
  }
  onKeyPressV1(event: KeyboardEvent) {
    const isNumber = /[0-9.]/.test(event.key);
    if (!isNumber) {
      event.preventDefault(); // Prevent the input if the key is not a number
    }
  }
  numbersAndfloatOnly(event: any) {
    const allowedChars = '0123456789.';
    const inputChar = event.key;

    if (event.key === '.' && event.currentTarget.value.includes('.')) {
      // Allow only one decimal point
      event.preventDefault();
    } else if (allowedChars.indexOf(inputChar) === -1) {
      // Prevent input of characters other than numbers and dot
      event.preventDefault();
    }
  }
  isSaveButton:boolean =false;
  saveInsCategory(){
    this.isSaveButton=true;
    const InsForm =this.insuranceDetailsForm.value;
    let InsCateg: CreateUpdateInsuranceCategoryDTO = {
        insuranceCategory:InsForm.txtname,
        maskCallIncrement: InsForm.maskCallIncrement,
        suppliesCallIncrement: InsForm.suppliesCallIncrement,
        orderMachinePoints: InsForm.orderMachinePoints,
        defaultDeductible: InsForm.defaultDeduct,
        complianceMachinePoints: InsForm.complianceMachine,

    };

      this.insuranceCategoryService.create(InsCateg).subscribe(response => {
        this.toastr.success('Saved Successfully','Success');
        this.addInsuranceCategoryModelRef.close(response);
      }, err => {
        this.isSaveButton=false;
        const data: HttpErrorResponse = err;
        this.toastr.warning(data?.error?.error?.message,'Warning');
      });
    }

}
