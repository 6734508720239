import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-referral-fax-number',
  templateUrl: './referral-fax-number.component.html',
  styleUrls: ['./referral-fax-number.component.scss']
})
export class ReferralFaxNumberComponent implements OnInit {
  strPageType: string = 'practiceReferralFaxNo'
  isLoading: boolean= true;

  constructor() { }

  ngOnInit(): void {
  }

}
