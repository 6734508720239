<div class="container-fluid">
  <div class="row bg">
    <div class="col-12">
      <div class="d-flex align-items-center justify-content-between mb-0">
        <h3 class="font-weight-bolder mb-0">
          <b>
            <ng-container *ngIf="categoryId === ''; then thenTitleTemplate; else elseTitleTemplate"></ng-container>
            <ng-template #thenTitleTemplate> Add Product Category </ng-template>
            <ng-template #elseTitleTemplate> Edit Product Category </ng-template>  
          </b> 
        </h3>
        <div class="page-title-box page-title-right">
          <ol class="breadcrumbs m-0 align-items-center">
            <!-- (click)="exportTableData()" -->
            <button mat-button class="mr-2 buttonColor"
              [disabled]="(dataSource && dataSource?.data && dataSource?.data?.length === 0)"

            (click)="exportTableData()">
              Export
            </button>
            <li class="breadcrumb-item ">Inventory</li>
            <li class="breadcrumb-item ">Product Category</li>
          </ol>
        </div>
      </div>
    </div>
    </div>
    <div class="col-12" style="padding-right: 5px;">
      <div class="card card-body" style="margin-left: -10px">
        <mat-accordion>
          <mat-expansion-panel [expanded]="step">
            <mat-expansion-panel-header>
              <mat-panel-title class="customThemeClass">
                  <b>
                    <ng-container *ngIf="categoryId === ''; then thenTitleTemplate; else elseTitleTemplate"></ng-container>
                    <ng-template #thenTitleTemplate> Add Product Category </ng-template>
                    <ng-template #elseTitleTemplate> Edit Product Category </ng-template>
                  </b>
              </mat-panel-title>
            </mat-expansion-panel-header>
          <mat-card-content>
            <form [formGroup]="productCategoryForm">
            <div class="row">

              <mat-form-field class="algin col-4">
                <mat-label>Product Category Name <span class="asterisk">*</span></mat-label>
                <input autocomplete="new-txtProductCategoryName" matInput maxlength="40" formControlName="txtProductCategoryName"
                  placeholder="Enter Product Category Name" type="text" />
                <mat-error *ngIf="
                productCategoryForm?.get('txtProductCategoryName')?.touched &&
                productCategoryForm?.get('txtProductCategoryName')?.errors?.required
                      ">
                  Product Category Name is a required field!
                </mat-error>
              </mat-form-field>

              <!-- <div *ngIf="isShowStatus" class="col-2" style='top:20px'>
                <mat-label>Status : </mat-label>
                <mat-slide-toggle (change)="statusChange($event)" class="ml-2"
                  formControlName="txtProductCategoryStatus">
                  {{status}}</mat-slide-toggle>
              </div> -->
              <!-- <div class="col-2 mr-4 mt-3" *ngIf ='isShowStatus'>
                <mat-checkbox formControlName="txtProductCategoryStatus">Product Category Status </mat-checkbox>
              </div> -->
              <div class="col-6 text-lg-left" style='top:3px'>
                <button mat-button class="buttonColor mb-2 mr-2"
                  [disabled]="!(!productCategoryForm.invalid && productCategoryForm.dirty)"
                  (click)="saveProductCategory()">
                  Save
                </button>
                <button mat-button class="resetclr buttonColor mb-2 mr-2" (click)="resetProductCategory()">
                  Reset
                </button>
              </div>
            </div>
          </form>
          </mat-card-content>

        </mat-expansion-panel>
        </mat-accordion>
      </div>
    </div>
    <div class="card card-body">
      <h4 class="customThemeClass ml-3 mt-2"><b>Product Category List</b></h4>
  
      <div class="row ml-2">
    <div class="tabspace col-12 table-responsive">
      <!-- <div [hidden]="!isLoading"
        style="display: flex; justify-content: center; align-items: center; background: transparent">
        <mat-progress-bar mode="indeterminate" value="66" diameter="45" class="lightseagreen">
        </mat-progress-bar>
      </div> -->
   
        <table  mat-table  [dataSource]="dataSource" class="border" matSort>
          <form style="display: flex" [formGroup]="productCategorySearchForm">
            <ng-container matColumnDef="sno">
              <th class="header" mat-header-cell *matHeaderCellDef >
                S.No
              </th>
              <td mat-cell *matCellDef="let data">
                {{ data?.sno }}
              </td>
            </ng-container>

            <ng-container matColumnDef="productCategoryName">
              <th class="header" mat-sort-header mat-header-cell *matHeaderCellDef>
                <mat-form-field class="filter">
                  <input autocomplete="new-txtProductCategoryName" matInput (keydown.space)="$event.stopPropagation()" (click)="$event.stopPropagation()"
                  formControlName="txtProductCategoryName">
                  <mat-placeholder>Product Category Name</mat-placeholder>
                </mat-form-field>
              </th>
              <td mat-cell *matCellDef="let data">
                <ng-container>
                  {{ data?.productCategoryName }}
                </ng-container>
              </td>
            </ng-container>

            <ng-container matColumnDef="status">
              <th mat-header-cell *matHeaderCellDef mat-sort-header >
                <mat-form-field class="filter">
                  <input autocomplete="new-txtStatus" matInput (keydown.space)="$event.stopPropagation()" (click)="$event.stopPropagation()"
                  formControlName="txtStatus">
                  <mat-placeholder>Status</mat-placeholder>
                </mat-form-field>
              </th>
              <td mat-cell *matCellDef="let data">
                <ng-container *ngIf="data?.productCategoryStatus==='Active'">
                  <mat-slide-toggle (change)="statusChange($event, data)" [checked]='true' class="ml-2">
                    {{data?.productCategoryStatus}}</mat-slide-toggle>
                </ng-container>
                <ng-container *ngIf="data?.productCategoryStatus==='Inactive'">
                  <mat-slide-toggle (change)="statusChange($event, data)" [checked]='false' class="ml-2">
                    {{data?.productCategoryStatus}}</mat-slide-toggle>
                </ng-container>
              </td>
            </ng-container>

            <ng-container matColumnDef="createdDate">
              <th class="header" mat-sort-header class="w-125" mat-header-cell *matHeaderCellDef>
                <mat-form-field class="filter">
                  <mat-label> Created Date </mat-label>
                  <input autocomplete="new-productCategoryCreatedOn" matInput (keydown.space)="$event.stopPropagation()" (click)="$event.stopPropagation()"
                  formControlName="productCategoryCreatedOn" placeholder="(MM/DD/YYYY)" />
                  <mat-error *ngIf=" productCategorySearchForm?.get('productCategoryCreatedOn')?.touched &&
                    productCategorySearchForm?.get('productCategoryCreatedOn')?.errors?.dateVaidator">
                    Enter Valid date
                  </mat-error>
                </mat-form-field>
              </th>
              <td mat-cell *matCellDef="let data">{{ data?.createdDate }}</td>
            </ng-container>
            <ng-container matColumnDef="options">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                Options
              </th>
              <td mat-cell *matCellDef="let data">
                <a class="editBtnColor" (click)="ViewProductCategory(data?.id)">
                  <i *ngIf="data?.productCategoryStatus==='Active'" class="fa fa-pencil pointer"
                    matTooltip="Click To Edit" ></i>
                </a> &nbsp;
                <a [hidden]="true" class="deleteBtnColor" (click)="deleteProductCategory(data?.id)">
                  <i *ngIf="data?.productCategoryStatus==='Active'" class="fa fa-trash pointer"
                    matTooltip="Click To Delete" ></i>
                </a>
              </td>
            </ng-container>
            <ng-container matColumnDef="noRecords">
              <td class="text-align-center" mat-footer-cell *matFooterCellDef colspan="9">
                <div *ngIf="!isLoading">
                  {{"No Records Found"}}
                </div>
                <div [hidden]="!isLoading">
                  <mat-spinner [diameter]="220"></mat-spinner>
                </div>
              </td>
            </ng-container>
          </form>
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
          <tr mat-footer-row *matFooterRowDef="['noRecords']"
            [ngClass]="{ active: dataSource && dataSource.data && dataSource?.data?.length !== 0 }"></tr>
           
        </table>
        <mat-paginator #MatPaginator showFirstLastButtons [pageSizeOptions]="[5, 10, 25, 50, 100]" [pageSize]="10">
        </mat-paginator>
      </div>
    </div>
  </div>
</div>
