<!-- Work In Progress -->
<div class="container-fluid">
  <div class="row bg">
    <div class="col-12">
      <div class="d-flex align-items-center justify-content-between mb-1">
        <h3 class="font-weight-bolder mb-0">
          User Task Report / Work In Progress
        </h3>
        <div class="page-title-box page-title-right">
          <ol class="breadcrumbs m-0">
            <!-- <li>
              <div class="input-group mb-3">
                <input
                  type="text"
                  class="form-control"
                  [ngStyle]="{
                    'border-color': dateRangStatus == true ? '#bdb4b4' : 'white'
                  }"
                  aria-label="Username"
                  aria-describedby="basic-addon1"
                  name="daterange"
                  ngxDaterangepickerMd
                  [(ngModel)]="selectedRange"
                  id="calendar-input"
                  [showCustomRangeLabel]="true"
                  [alwaysShowCalendars]="true"
                  [linkedCalendars]="true"
                  [ranges]="ranges"
                  [showClearButton]="true"
                  [locale]="calendarLocale"
                  [placeholder]="calendarPlaceholder"
                  [keepCalendarOpeningWithRange]="true"
                  [minDate]="minDate"
                  [maxDate]="maxDate"
                  (datesUpdated)="datesUpdateds($event)"
                />
                <span class="input-group-text bg-color" id="basic-addon1">
                  <label style="margin: auto" for="calendar-input"
                    ><i
                      class="fa fa-filter cursor"
                      aria-hidden="true"
                    ></i></label
                ></span>
              </div>
            </li> -->
            <button
              (click)="getScheduledTasksList(); $event.preventDefault()"
              mat-raised-button
              class="mr-2 buttonColor"
            >
              Scheduled Tasks
            </button>

            <button
              class="mr-2 buttonColor"
              [disabled]="
                dataSource && dataSource?.data && dataSource?.data?.length === 0
              "
              mat-raised-button
              (click)="
                exporter.exportTable('xlsx', {
                  fileName: 'WIP List',
                  sheet: 'WIPList',
                  Props: { Author: 'QSecure' }
                })
              "
            >
              Export
            </button>
            <!-- <li class="breadcrumb-item mt-2 pl-2">User Task Report</li>
            <li class="breadcrumb-item mt-2">Work In Progress</li> -->
          </ol>
        </div>
      </div>
    </div>

    <div class="col-lg-12 pb-5">
      <form [formGroup]="wipForm">
        <mat-form-field class="col-lg-4">
          <mat-label>User Name</mat-label>
          <mat-select
            formControlName="drpUserName"
            (selectionChange)="getWIPReport($event.value)"
            maxlength="40"
          >
            <mat-option>
              <ngx-mat-select-search
                placeholderLabel="Search"
                noEntriesFoundLabel="No Matching found"
                formControlName="txtUserSearchFilter"
              ></ngx-mat-select-search>
            </mat-option>
            <mat-option
              *ngFor="let user of filteredIdentityUsers | async"
              [value]="user.id"
            >
              {{ user.userName }}
            </mat-option>
          </mat-select>
          <mat-error
            *ngIf="
              wipForm?.get('drpUserName')?.touched &&
              wipForm?.get('drpUserName')?.errors?.required
            "
          >
            User Name is a required field!
          </mat-error>
        </mat-form-field>
        <mat-form-field class="col-lg-4">
          <mat-label>Status</mat-label>
          <mat-select
            formControlName="drpWipReportType"
            (selectionChange)="getWIPReport($event.value)"
            maxlength="40"
          >
            <mat-option
              *ngFor="let wipReportType of drpWipReportType"
              [value]="wipReportType.key"
            >
              {{ wipReportType.value }}
            </mat-option>
          </mat-select>
          <mat-error
            *ngIf="
              wipForm?.get('drpWipReportType')?.touched &&
              wipForm?.get('drpWipReportType')?.errors?.required
            "
          >
            Report Type is a required field!
          </mat-error>
        </mat-form-field>
      </form>

      <form [formGroup]="UserTaskReportsearchableForm">
        <div
          class="table table-responsive example-container mat-elevation-z4 pt-0 mt-0"
        >
          <table
            mat-table
            [dataSource]="dataSource"
            matTableExporter
            #exporter="matTableExporter"
            matSort
            class="mat-elevation-z8 pt-0 mt-0"
            style="margin-left: 10px !important"
          >
            <!-- Document Id -->
            <ng-container matColumnDef="defaultDocumentId">
              <th
                class="header"
                mat-header-cell
                mat-sort-header
                *matHeaderCellDef
              >
                <!-- Doc Id -->
                <mat-form-field class="filter">
                  <input
                    matInput
                    (keydown.space)="$event.stopPropagation()"
                    (click)="$event.stopPropagation()"
                    formControlName="documentId"
                    autocomplete="new-documentId"
                  />
                  <mat-placeholder class="center"> Doc Id</mat-placeholder>
                </mat-form-field>
              </th>
              <td mat-cell *matCellDef="let element">
                <a
                  matTooltip="Click to View Document"
                  class="eyeCursorclass"
                  [routerLink]="
                    '/ViewDocumentPerviewByBlobId/' +
                    element?.blobName +
                    '/' +
                    5
                  "
                  target="_blank"
                >
                  {{ element?.defaultDocumentId }}
                </a>
              </td>
            </ng-container>
            <!-- WIP ID-->
            <ng-container matColumnDef="wipId">
              <th
                class="header"
                mat-header-cell
                mat-sort-header
                *matHeaderCellDef
              >
                <!-- WIP ID-->
                <mat-form-field class="filter">
                  <input
                    matInput
                    (keydown.space)="$event.stopPropagation()"
                    (click)="$event.stopPropagation()"
                    formControlName="wipId"
                    autocomplete="new-wipId"
                  />
                  <mat-placeholder class="center">WIP Id</mat-placeholder>
                </mat-form-field>
              </th>
              <td mat-cell *matCellDef="let element">
                {{ element?.wipId }}
              </td>
            </ng-container>
            <!--Patient Id-->
            <ng-container matColumnDef="defaultPatientId">
              <th
                class="header"
                mat-header-cell
                mat-sort-header
                *matHeaderCellDef
              >
                <!-- Patient Id -->
                <mat-form-field class="filter">
                  <input
                    matInput
                    (keydown.space)="$event.stopPropagation()"
                    (click)="$event.stopPropagation()"
                    formControlName="defaultPatientId"
                    autocomplete="new-defaultPatientId"
                  />
                  <mat-placeholder class="center">Patient Id</mat-placeholder>
                </mat-form-field>
              </th>
              <td mat-cell *matCellDef="let element">
                <a
                  matTooltip="Click to View Patient"
                  class="eyeCursorclass"
                  [routerLink]="'/patientCreateEditTab/' + element?.patientId"
                  target="_blank"
                >
                  {{ element?.defaultPatientId }}
                </a>
              </td>
            </ng-container>
            <!--Patient Name-->
            <ng-container matColumnDef="patientName">
              <th
                class="header"
                mat-header-cell
                mat-sort-header
                *matHeaderCellDef
              >
                <!-- Patient Name -->
                <mat-form-field class="filter">
                  <input
                    matInput
                    (keydown.space)="$event.stopPropagation()"
                    (click)="$event.stopPropagation()"
                    formControlName="patientName"
                    autocomplete="new-patientName"
                  />
                  <mat-placeholder class="center">Patient Name</mat-placeholder>
                </mat-form-field>
              </th>
              <td mat-cell *matCellDef="let element">
                {{ element?.patientName }}
              </td>
            </ng-container>
            <!-- Assigned By-->
            <ng-container matColumnDef="assignedByName">
              <th
                class="header"
                mat-header-cell
                mat-sort-header
                *matHeaderCellDef
              >
                <!-- <mat-checkbox #isAssinged></mat-checkbox> -->
                <!-- Assigned By -->
                <mat-form-field class="filter">
                  <input
                    matInput
                    (keydown.space)="$event.stopPropagation()"
                    (click)="$event.stopPropagation()"
                    formControlName="assignedByName"
                    autocomplete="new-assignedByName"
                  />
                  <mat-placeholder class="center"> Assigned By</mat-placeholder>
                </mat-form-field>
              </th>
              <td mat-cell *matCellDef="let element">
                {{ element?.assignedByName }}
              </td>
            </ng-container>
            <!-- Assigned Date -->
            <ng-container matColumnDef="assignedDate">
              <th
                class="header"
                mat-header-cell
                mat-sort-header
                *matHeaderCellDef
              >
                <!-- Assigned Date -->
                <mat-form-field class="filter">
                  <input
                    matInput
                    (keydown.space)="$event.stopPropagation()"
                    (click)="$event.stopPropagation()"
                    formControlName="assignedDate"
                    autocomplete="new-assignedDate"
                  />
                  <mat-placeholder class="center"
                    >Assigned Date</mat-placeholder
                  >
                </mat-form-field>
              </th>
              <td mat-cell *matCellDef="let element">
                {{ element?.assignedDate | date : "MM/dd/yyyy" : "en_US" }}
              </td>
            </ng-container>
            <!--Comments-->
            <ng-container matColumnDef="comments">
              <th
                class="header"
                mat-header-cell
                mat-sort-header
                *matHeaderCellDef
              >
                Comments
              </th>
              <td mat-cell *matCellDef="let element" class="commentsHeader">
                <a
                  (click)="openCommentsDialog(myDialog, element)"
                  matTooltip="Click To View Comments"
                >
                  <i class="fa fa-eye pointer editBtnColor"></i>
                </a>
              </td>
            </ng-container>
            <!--WIP Status-->
            <ng-container matColumnDef="wipStatus">
              <th
                class="header"
                mat-header-cell
                mat-sort-header
                *matHeaderCellDef
              >
                <!-- Wip Status -->
                <mat-form-field class="filter">
                  <input
                    matInput
                    (keydown.space)="$event.stopPropagation()"
                    (click)="$event.stopPropagation()"
                    formControlName="wipStatus"
                    autocomplete="new-wipStatus"
                  />
                  <mat-placeholder class="center">Wip Status</mat-placeholder>
                </mat-form-field>
              </th>
              <td mat-cell *matCellDef="let element">
                <div [ngSwitch]="element?.wipStatus">
                  <div *ngSwitchCase="'yetToStart'">Yet To Start</div>
                  <div *ngSwitchCase="'hold'">Hold</div>
                  <div *ngSwitchCase="'inProcess'">In Process</div>
                  <div *ngSwitchCase="'completed'">Completed</div>
                  <div *ngSwitchDefault></div>
                </div>
              </td>
            </ng-container>
            <!--Options-->
            <ng-container matColumnDef="options">
              <th
                class="header"
                mat-header-cell
                mat-sort-header
                *matHeaderCellDef
              >
                Options
              </th>
              <td mat-cell *matCellDef="let element">
                <div [ngSwitch]="element?.wipStatus">
                  <div *ngSwitchCase="'yetToStart'">
                    <button mat-mini-fab class="wip-status-color">
                      <mat-icon
                        matTooltip="Click To Start"
                        class="wip-success-status-color"
                        disabled
                        >play_circle_outline</mat-icon
                      >
                    </button>
                  </div>
                  <div *ngSwitchCase="'inProcess'">
                    <button mat-mini-fab class="wip-status-color">
                      <mat-icon
                        matTooltip="Click To Pause"
                        class="wip-info-status-color"
                        disabled
                        >pause_circle_outline</mat-icon
                      >
                    </button>
                  </div>
                  <div *ngSwitchCase="'hold'">
                    <button mat-mini-fab class="wip-status-color">
                      <mat-icon
                        matTooltip="Click To Resume"
                        class="wip-success-status-color"
                        disabled
                        >play_circle_outline</mat-icon
                      >
                    </button>
                    <button mat-mini-fab class="wip-status-color">
                      <mat-icon
                        matTooltip="Click To Complete"
                        class="wip-danger-status-color"
                        disabled
                        >check_circle_outline</mat-icon
                      >
                    </button>
                  </div>
                  <div *ngSwitchCase="'completed'"></div>
                  <div *ngSwitchDefault></div>
                </div>
              </td>
            </ng-container>
            <!-- No Records-->
            <ng-container matColumnDef="noRecords">
              <td
                class="text-align-center"
                mat-footer-cell
                *matFooterCellDef
                colspan="6"
              >
                <div *ngIf="!isLoading">
                  {{ "No Records Found" }}
                </div>
                <div [hidden]="!isLoading">
                  <mat-spinner [diameter]="220"></mat-spinner>
                </div>
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="arrDisplayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: arrDisplayedColumns"></tr>
          </table>
          <div
            *ngIf="
              !(
                dataSource &&
                dataSource?.data &&
                dataSource?.data?.length !== 0
              )
            "
            class="text-align-center"
          >
            <div class="message py-3" *ngIf="!isLoading">
              {{ "No Records Found" }}
            </div>
            <div class="py-3" [hidden]="!isLoading">
              <mat-spinner [diameter]="220"></mat-spinner>
            </div>
          </div>
          <mat-paginator
            [pageSizeOptions]="[10, 20, 50, 100]"
            showFirstLastButtons
          ></mat-paginator>
        </div>
      </form>
    </div>
  </div>
</div>

<ng-template #myDialog>
  <h2 matDialogTitle>Comments</h2>
  <mat-dialog-content>
    <div class="row">
      <div class="col-12 pb-5">
        <div
          class="table table-responsive example-container mat-elevation-z4 mb-0 pb-0"
        >
          <table
            mat-table
            id="wipCommentsTable"
            class="pt-0 mt-0"
            matTableExporter
            #exporter="matTableExporter"
            [hiddenColumns]="[14]"
            [dataSource]="dataSourceComments"
          >
            <ng-container matColumnDef="comments">
              <th class="header" mat-header-cell *matHeaderCellDef>Comments</th>
              <td mat-cell *matCellDef="let element">
                {{ element.comments }}
              </td>
            </ng-container>

            <ng-container matColumnDef="userName">
              <th class="header" mat-header-cell *matHeaderCellDef>
                User Name
              </th>
              <td mat-cell *matCellDef="let element">
                {{ element.userName }}
              </td>
            </ng-container>
            <ng-container matColumnDef="commentsDate">
              <th class="header" mat-header-cell *matHeaderCellDef>Date</th>
              <td mat-cell *matCellDef="let element">
                {{ element?.commentsDate }}
              </td>
            </ng-container>

            <tr
              mat-header-row
              *matHeaderRowDef="arrDisplayedCommentsColumns"
            ></tr>
            <tr
              mat-row
              *matRowDef="let row; columns: arrDisplayedCommentsColumns"
            ></tr>
          </table>
          <div
            *ngIf="
              !(
                dataSource &&
                dataSource?.data &&
                dataSource?.data?.length !== 0
              )
            "
            class="no-records text-align-center"
          >
            <div class="message py-3" *ngIf="!isLoading">
              {{ "No Records Found" }}
            </div>
            <div class="py-3" [hidden]="!isLoading">
              <mat-spinner [diameter]="220"></mat-spinner>
            </div>
          </div>
        </div>
        <mat-paginator
          #MatPaginator
          [length]="dataSourceComments?.data?.length"
          class="mat-elevation-z2"
          [pageSize]="10"
          [pageIndex]="0"
          showFirstLastButtons
          [pageSizeOptions]="[5, 10, 25, 100]"
        >
        </mat-paginator>
      </div>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button mat-raised-button color="warn" matDialogClose>Close</button>
  </mat-dialog-actions>
</ng-template>
