import { PagedAndSortedResultRequestDto } from '@abp/ng.core';
import { DatePipe } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, FormGroupDirective, NgForm, Validators } from '@angular/forms';
import { ErrorStateMatcher, MatOption } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSelect } from '@angular/material/select';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { Moment } from 'moment';
import { OrderMasterDropdownDTO } from 'projects/patient/src/app/dropdown-proxy/dropdown-management/dropdowns/dto/order/models';
import { OrderMasterDropdownService } from 'projects/patient/src/app/dropdown-proxy/dropdown-management/dropdowns/master-dropdown-service/order-master-dropdown.service';
import { OrderDropdowns } from 'projects/patient/src/app/dropdown-proxy/dropdown-management/dropdowns/order-dropdowns.enum';
import { ItemExportDatas, ItemExportDTO } from 'projects/patient/src/app/patient-proxy/patient/dto';
import { ExportType } from 'projects/patient/src/app/patient-proxy/patient/enumeration-data/export-type.enum';
import { ReportService } from 'projects/patient/src/app/patient-proxy/patient/report.service';
import { DateValidator } from 'projects/shared/src/app/validations/date-validator';
import { NumberValidators } from 'projects/shared/src/app/validations/number-validator';
import { Subscription } from 'rxjs';
import { debounce, debounceTime, distinctUntilChanged, filter, map, startWith, tap } from 'rxjs/operators';
import Swal from 'sweetalert2';
import emailMask from 'text-mask-addons/dist/emailMask';
import { CreateUpdateProductStockDTO, SearchProductStocksDTO } from '../item-proxy/item-management/items/dto/models';
import { ProductBrandService } from '../item-proxy/item-management/items/product-brand.service';
import { ProductCategoryService } from '../item-proxy/item-management/items/product-category.service';

import { ProductStockService } from '../item-proxy/item-management/items/product-stock.service';
import { ProductType } from '../item-proxy/item-management/items/product-type.enum';
import { ProductService } from '../item-proxy/item-management/items/product.service';
import { ToastrService } from "ngx-toastr";
import { VendorService } from '../item-proxy/item-management/items/vendor.service';
export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}
@Component({
  selector: 'app-product-stock',
  templateUrl: './product-stock.component.html',
  styleUrls: ['./product-stock.component.scss'],
  providers: [DatePipe],
})
export class ProductStockComponent implements OnInit, OnDestroy {
  stockForm: FormGroup;
  stockId: string = "";
  vSearchForm: FormGroup;
  step: boolean = false;
  isShowSpinner: boolean;
  saveButtonHide: boolean;
  BirthdateMinDate: Date;
  BirthdateMaxDate: Date;
  saveHide: boolean = true;
  vendorId: string = "";
  emailMask = emailMask;
  tableData: any[] = [];
  matcher = new MyErrorStateMatcher();
  QtyInput: number
  dataSource: MatTableDataSource<any>;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  subscription$: Subscription[] = [];
  displayedColumns: string[] = ['Options', 'productName', 'productBrandName', 'productCategoryName', 'lotNo', 'purchasedStockCount', 'soldCount', 'balStock', 'status', 'createdDate', ];
  constructor(private dateValidator: DateValidator,
    private numberValidators: NumberValidators,
    private formBuilder: FormBuilder,
    private brandService: ProductBrandService,
    private catService: ProductCategoryService,
    private productService: ProductService,
    private VendorsService: VendorService, public datepipe: DatePipe,
    public dialog: MatDialog, public router: Router,
    private stockService: ProductStockService,
    private orderDropdownService: OrderMasterDropdownService,
    private title: Title,
    private toastr: ToastrService,
    private reportService: ReportService
  ) { }
  minDate: Moment;
  maxDate: Moment;
  today = new Date();

  ngOnInit(): void {
    const numberRegEx = /\d*\.?\d{1,2}/;

    this.stockForm = this.formBuilder.group({
      txtBrandName: new FormControl("", Validators.required),
      txtPersonName: new FormControl(""),
      txtStockCount: new FormControl(""),
      txtLotNo: new FormControl(""),
      txtQty: new FormControl("", [Validators.required, this.numberValidators.isNumberCheck, Validators.min(1)]),
      txtPrice: new FormControl("", [Validators.required, this.numberValidators.isNumberCheck,]),
      txtbrandFilter: new FormControl(""),
      txtCategoryName: new FormControl("", Validators.required),
      txtMainProduct: new FormControl("", Validators.required),
      txtMainProductFilter: new FormControl(""),
      txtCategoryFilter: new FormControl(""),
      txtStatus: new FormControl(""),
      chkRecall: new FormControl(""),
      txtVendorName: new FormControl("", Validators.required),
      txtVendorFilter: new FormControl(""),
      txtCountdate: new FormControl(""),
      txtMinQty: new FormControl(""),
      txtPurchasedate: new FormControl(new Date(), Validators.required),
    })
    this.vSearchForm = this.formBuilder.group({
      drpProduct: new FormControl(""),
      txtMainProductFilter: new FormControl(""),
      txtSBrandFilter: new FormControl(""),
      drpBrand: new FormControl(""),
      drpStatus: new FormControl(""),
      txtStatusFilter: new FormControl(""),
      txtCreatedate: new FormControl(""),
    });
    //Set Title
    this.title.setTitle("Qsecure | Product Stocks");

    this.BirthdateMinDate = new Date();
    this.BirthdateMinDate.setFullYear(this.BirthdateMinDate.getFullYear() - 120);
    this.QtyInput = this.stockForm?.get('txtQty')?.value
    this.BirthdateMaxDate = new Date();
    this.BirthdateMaxDate.setDate(this.BirthdateMaxDate.getDate() - 1);
    this.BirthdateMinDate = new Date();
    this.BirthdateMinDate.setFullYear(this.BirthdateMinDate.getFullYear() - 120);

    this.stockForm.valueChanges.pipe(
      map(x => x?.txtPrice),
      distinctUntilChanged(),
      debounceTime(200),
      filter(x => !numberRegEx.test(x))
    ).subscribe(response => {
    })
    this.getDropdown();
    this.getTableData();
  }


  ngOnDestroy(): void {
    this.subscription$?.forEach((sub) => {
      sub && sub?.unsubscribe();
    });
  }

  /// get table datas
  optionStatus: string = "Update Serial No";
  getTableData() {
    this.isShowSpinner = true;
    this.tableData = [];
    const stockGetList = this.stockService.getList(new PagedAndSortedResultRequestDto).subscribe(response => {
      response && response?.items?.forEach(element => {
        element?.productLotDetails?.forEach(e => {
          this.tableData.push({
            id: element?.id,
            productId: element?.productId,
            productName: element?.productName,
            productBrandName: e?.productBrandName,
            productCategoryName: e?.productCategoryName,
            purchasedStockCount: e?.purchasedStockCount,
            stockPurchasedDate: e?.stockPurchasedDate,
            lotNo: e?.lotNo,
            lotId: e?.lotId,
            price: e?.price,
            createdDate: e?.createdDate,
            balStock: e?.balanceStockCount,
            status: e?.stockStatus,
            vendorName: e?.vendorName,
            soldCount: e?.soldStockCount,
            isAllSerialNumbersUpdated: e?.isAllSerialNumbersUpdated,
            optionStatus: e?.isAllSerialNumbersUpdated === "false" ? "Update Serial No" : "Stock view"
          });
        })
      })
      this.isShowSpinner = false;
      this.dataSource = new MatTableDataSource(this.tableData);
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
    }, error => {
      const data: HttpErrorResponse = error;
      this.isShowSpinner = false;
      // Swal.fire({
      //   icon: 'error',
      //   text: data?.error?.error?.message,
      // });
      this.dataSource = new MatTableDataSource(this.tableData);
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
    })
    this.subscription$.push(stockGetList);
  }


  //! get vendor Instance
  getstockForm(): FormGroup {
    return this.stockForm
  }
  //! get vendor Instance
  getVendorSForm(): FormGroup {
    return this.vSearchForm
  }

  //// getby id view
  viewStock(data) {
    this.step = !this.step;
    this.saveHide = true;

    const prodDetailsGetList = this.stockService.getProductDetailsList(data?.id, data?.lotId).subscribe(response => {
      let mainProduct = this.drpMainProduct?.filter(value => {
        return value && value?.productId === response?.productId
      })[0];
      this.stockForm.patchValue({
        txtBrandName: response?.productLotDetails[0]?.productBrandName,
        txtLotNo: response?.productLotDetails[0]?.lotNo,
        txtQty: response?.productLotDetails[0]?.purchasedStockCount,
        txtPrice: response?.productLotDetails[0]?.price,
        txtMainProduct: mainProduct,
        txtVendorName: response?.productLotDetails[0]?.vendorName,
        txtCategoryName: response?.productLotDetails[0]?.productCategoryName,
        txtPurchasedate: response?.productLotDetails[0]?.stockPurchasedDate,
        txtStockCount: response?.productLotDetails[0]?.soldStockCount,
        txtStatus: response?.productLotDetails[0]?.purchaseStatus,
        // txtMinQty: response?.productLotDetails[0]?.minimumStockCount,
      })

      this.stockId = response.id;
      this.stockForm.controls['txtMainProduct'].disable()
      this.stockForm.controls['txtBrandName'].disable()
      this.stockForm.controls['txtVendorName'].disable()
      this.stockForm.controls['txtCategoryName'].disable()
      this.stockForm.controls['txtLotNo'].disable()
      this.stockForm.controls['txtQty'].disable()
      this.stockForm.controls['txtPurchasedate'].disable()
    }, err => {
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    });
    this.subscription$.push(prodDetailsGetList);
  }


  filterbrand:any;
  filterCategory:any;
  filterMainProduct:any;
  filterVendor:any;
  drpMainProduct: any[] = [];
  drpBrand: any[] = [];
  drpCategory: any[] = [];
  drpVendor: any[] = [];
  drpStatus: any[] = [];
  filterMainProducts:any;
  filterSearchbrand:any;
  filterStatus:any;
  drpStatusSearch: { id: number; value: string; }[] = [{ "id": 1, value: "Stock in hand" }, { "id": 2, value: "Stock Completed" }]

  /// get all dropdown
  getDropdown() {

    let orderDropdownInput: OrderDropdowns[];
    orderDropdownInput = [OrderDropdowns.ProductPurchaseStatus];
    const orderDropdown = this.orderDropdownService.getOrderDropdownsByInput(orderDropdownInput).subscribe(
      stateResponse => {
        let response: OrderMasterDropdownDTO = stateResponse;
        this.drpStatus = response?.productPurchaseStatuses ?? [];
      },
      err => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      }
    );
    this.subscription$.push(orderDropdown);

    //////brand getlist
    const branchGetList = this.brandService.getList(new PagedAndSortedResultRequestDto).subscribe(response => {
      this.drpBrand = []
      response?.items?.forEach(element => {
        if (element?.status == 'Active') {
          this.drpBrand.push({
            name: element.productBrandName,
            id: element.id
          });
        }

        this.filterbrand = this.stockForm?.get("txtbrandFilter").valueChanges
          .pipe(
            startWith(''),
            map(value => this.drpBrand.filter(option => option?.name?.toLowerCase()?.includes(value?.toLowerCase() ?? "")))
          );
        this.filterSearchbrand = this.vSearchForm?.get("txtSBrandFilter").valueChanges
          .pipe(
            startWith(''),
            map(value => this.drpBrand.filter(option => option?.name?.toLowerCase()?.includes(value?.toLowerCase() ?? "")))
          );
      })
    })
    this.subscription$.push(branchGetList);
    //


    this.filterStatus = this.vSearchForm?.get("txtStatusFilter").valueChanges
      .pipe(
        startWith(''),
        map(value => this.drpStatusSearch?.filter(option => option?.value?.toLowerCase()?.includes(value?.toLowerCase() ?? "")) ?? "")
      );

    /////product
    const getProducts = this.productService.getProductsByInput(ProductType.All).subscribe(response => {
      this.drpMainProduct = response

      this.filterMainProduct = this.stockForm?.get("txtMainProductFilter").valueChanges
        .pipe(
          startWith(''),
          map(value => this.drpMainProduct.filter(option => option?.productName?.toLowerCase()?.includes(value?.toLowerCase() ?? "")))
        );
      this.filterMainProducts = this.vSearchForm?.get("txtMainProductFilter").valueChanges
        .pipe(
          startWith(''),
          map(value => this.drpMainProduct.filter(option => option?.productName?.toLowerCase()?.includes(value?.toLowerCase() ?? "")))
        );
    })
    this.subscription$.push(getProducts);

    //// Category getlist
    const getList = this.catService.getList(new PagedAndSortedResultRequestDto).subscribe(response => {
      this.drpCategory = []
      response?.items?.forEach(element => {
        if (element?.status == 'Active') {
          this.drpCategory?.push({
            name: element.productCategoryName,
            id: element.id
          });
        }
        this.filterCategory = this.stockForm?.get("txtCategoryFilter").valueChanges
          .pipe(
            startWith(''),
            map(value => this.drpCategory?.filter(option => option?.name?.toLowerCase()?.includes(value?.toLowerCase() ?? "")))
          );
      })
    })
    this.subscription$.push(getList);

    //// vendor getlist
    const vendorGetList = this.VendorsService.getList(new PagedAndSortedResultRequestDto).subscribe(response => {
      this.drpVendor = []
      response?.items?.forEach(element => {
        if (element?.status == 'Active') {
          this.drpVendor.push({
            name: element.vendorName,
            id: element.id
          });
        }

        this.filterVendor = this.stockForm?.get("txtVendorFilter").valueChanges
          .pipe(
            startWith(''),
            map(value => this.drpVendor.filter(option => option?.name?.toLowerCase()?.includes(value?.toLowerCase() ?? "")))
          );

      })
    })
    this.subscription$.push(vendorGetList);
  }

  /// based on product qty view
  qtyNeedToAddInStock;
  onChanges(event) {
    if (event?.value?.qtyNeedToAddInStock > 0) {
      this.qtyNeedToAddInStock = 'Only' + " " + event?.value?.qtyNeedToAddInStock + " " + 'and less quantities allowed for this' + " " + event?.value?.productName
    } else {
      this.qtyNeedToAddInStock = 'Please raise purchase order or check product received status for this' + " " + event?.value?.productName
    }

  }

  price: number;
  /// save and update stock
  saveStock() {
    if (this.stockId !== "" && this.stockId !== null && this.stockId !== undefined) {
      this.price = this.stockForm.value.txtPrice
      this.stockService.updateStockPriceByIdAndPrice(this.stockId, this.price).subscribe(response => {
        // Swal.fire({ title: 'Success', html: 'Updated Successfully', icon: 'success', timer: 3000, timerProgressBar: true });
        this.toastr.success('Updated Successfully','Success')
        this.getDropdown();
        this.getTableData()
        this.reset();
      }, err => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      });
    }
    else {
      let stockDetail: CreateUpdateProductStockDTO = {
        productId: this.stockForm.value.txtMainProduct.productId,
        productType: this.stockForm.value.txtMainProduct.productType,
        productName: this.stockForm.value.txtMainProduct.productName,
        productLotDetails: [{
          lotId: "00000000-0000-0000-0000-000000000000",
          productBrandName: this.stockForm.value.txtBrandName?.name,
          productCategoryName: this.stockForm.value.txtCategoryName,
          vendorName: this.stockForm.value.txtVendorName,
          stockPurchasedDate: this.stockForm.value.txtPurchasedate,
          purchasedStockCount: this.stockForm.value.txtQty,
          soldStockCount: 0,
          balanceStockCount: 0,
          // minimumStockCount: this.stockForm.value.txtMinQty,
          lotNo: this.stockForm.value.txtLotNo,
          stockStatus: "In Stock",
          isRecall: 0,
          price: this.stockForm.value.txtPrice,
          purchaseStatus: this.stockForm.value.txtStatus,
          productDetails: null,
          createdDate: "",
          isAllSerialNumbersUpdated: 'true',
          brandId: this.stockForm.value.txtBrandName?.id
        }]
      }
      this.stockService.create(stockDetail).subscribe(response => {
        // Swal.fire({ title: 'Success', html: 'Saved Successfully', icon: 'success', timer: 3000, timerProgressBar: true });
        this.toastr.success('Saved Successfully','Success')

        this.getDropdown();
        this.getTableData()
        this.reset();
      }, err => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      });
    }
  }

  ///// open stock view based on qty
  openModel(data) {
    this.router.navigate(['inventory/stock-view/' + [data.id.toString()] + '/' + [data.lotId.toString()]])

    // const dialogRef = this.dialog.open(StockViewComponent, {
    //   width: '80vw',
    //   maxWidth: '80vw',
    //   disableClose: true,
    //   data: { data: data }
    // });

    //    dialogRef.afterClosed().subscribe(() => {

    //     // this.getSupplyTableData()
    //   });
  }

  @ViewChild('allRolesSelected') private allRolesSelected: MatOption;
  @ViewChild('roleSelect') roleSelect: MatSelect;

  @ViewChild('allBrandSelected') private allBrandSelected: MatOption;
  @ViewChild('BrandSelect') BrandSelect: MatSelect;

  @ViewChild('allStatusSelected') private allStatusSelected: MatOption;
  @ViewChild('StatusSelect') StatusSelect: MatSelect;
  toggleAllSelection() {
    if (this.allRolesSelected.selected) {
      this.roleSelect.options.forEach((item: MatOption) => item.select());
    }
    else {
      this.roleSelect.options.forEach((item: MatOption) => item.deselect());
    }
  }

  //// all select from dropdown
  toggleBrandAllSelection() {
    if (this.allBrandSelected.selected) {
      this.BrandSelect.options.forEach((item: MatOption) => item.select());
    }
    else {
      this.BrandSelect.options.forEach((item: MatOption) => item.deselect());
    }
  }

  /// search reset value
  resetSearch() {
    this.vSearchForm.patchValue({
      drpProduct: "",
      drpBrand: "",
      drpStatus: "",
    });
    this.vSearchForm?.markAsUntouched();
    this.vSearchForm?.updateValueAndValidity();
    this.vSearchForm?.reset();
    this.getTableData()
  }
  //// all select from dropdown
  toggleStatusAllSelection() {
    if (this.allStatusSelected.selected) {
      this.StatusSelect.options.forEach((item: MatOption) => item.select());
    }
    else {
      this.StatusSelect.options.forEach((item: MatOption) => item.deselect());
    }
  }
  /// search values
  search() {
    // this.isLoading = true;
    let productName: any[] = [];
    let brandName: any[] = [];
    this.vSearchForm.value.drpProduct !== "" &&
      this.vSearchForm.value.drpProduct !== undefined &&
      this.vSearchForm.value.drpProduct !== null &&
      [this.vSearchForm.value.drpProduct].length !== 0 &&
      [this.vSearchForm.value.drpProduct].forEach(val => {
        val?.forEach(element => {
          element !== undefined && element !== 0 && productName.push(element);
        });
      });

    this.vSearchForm.value.drpBrand !== "" &&
      this.vSearchForm.value.drpBrand !== undefined &&
      this.vSearchForm.value.drpBrand !== null &&
      [this.vSearchForm.value.drpBrand].length !== 0 &&
      [this.vSearchForm.value.drpBrand].forEach(val => {
        val?.forEach(element => {
          element !== undefined && element !== 0 && brandName.push(element);
        });
      });
    // let searchDate = this.vSearchForm.value.txtCreatedate;
    // let selectedRange: { searchDate: Moment };

    let stockSearch: SearchProductStocksDTO = {
      productNames: productName,
      brandNames: brandName,
      categoryNames: brandName,
      status: this.vSearchForm.value.drpStatus,
      createdFromDate: this.datepipe.transform(this.vSearchForm.value.txtCreatedate, 'yyyy-MM-dd'),
      createdToDate: null,
    }
    const searchStocks = this.stockService.searchProductStocksByInput(stockSearch).subscribe(response => {
      this.tableData = [];
      response && response?.items?.forEach((element, tableIndex) => {
        element?.productLotDetails?.forEach(e => {
          this.tableData.push({
            id: element?.id,
            productId: element?.productId,
            productName: element?.productName,
            productBrandName: e?.productBrandName,
            productCategoryName: e?.productCategoryName,
            purchasedStockCount: e?.purchasedStockCount,
            stockPurchasedDate: e?.stockPurchasedDate,
            lotNo: e?.lotNo,
            lotId: e?.lotId,
            price: e?.price,
            createdDate: e?.createdDate,
            balStock: e?.balanceStockCount,
            status: e?.stockStatus,
            vendorName: e?.vendorName,
            soldCount: e?.soldStockCount,
            isAllSerialNumbersUpdated: e?.isAllSerialNumbersUpdated,
            optionStatus: e?.isAllSerialNumbersUpdated === "false" ? "Update Serial No" : "Stock view"
          });
          // if (e?.isAllSerialNumbersUpdated ===  'false') {

          //   this.optionStatus = 'Update Serial No'
          // } if (e?.isAllSerialNumbersUpdated === 'true') {
          //   this.optionStatus = 'Stock view'
          // }
        })
      })
      // this.isLoading = false;
      this.dataSource = new MatTableDataSource(this.tableData);
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
    });
    this.subscription$.push(searchStocks);

  }
  /// reset stock form
  reset() {
    this.stockForm.controls['txtMainProduct'].enable()
    this.stockForm.controls['txtBrandName'].enable()
    this.stockForm.controls['txtVendorName'].enable()
    this.stockForm.controls['txtCategoryName'].enable()
    this.stockForm.controls['txtLotNo'].enable()
    this.stockForm.controls['txtQty'].enable()
    this.stockForm.controls['txtPurchasedate'].enable()
    this.stockForm.patchValue({
      txtMainProduct: "",
      txtBrandName: "",
      txtVendorName: "",
      txtLotNo: "",
      txtPrice: "",
      // txtPurchasedate: "",
      txtQty: "",
      txtStatus: "",
      txtMinQty: ""
    });
    this.stockForm?.markAsUntouched();
    this.stockForm?.updateValueAndValidity();
    this.stockForm?.reset();
    this.stockForm.patchValue({
      txtPurchasedate: new Date()
    })
    this.saveHide = true;
    this.qtyNeedToAddInStock = ''
  }


  brand: string = '';
  clearbrand() {
    this.brand = '';
    this.stockForm.patchValue({ txtBrandName: "" });
  }

  Status: string = '';
  clearStatus() {
    this.Status = '';
    this.vSearchForm.patchValue({ drpStatus: "" });
  }


  Sbrand: string = '';
  clearSbrand() {
    this.Sbrand = '';
    this.stockForm.patchValue({ drpBrand: "" });
  }


  Product: string = '';
  clearProduct() {
    this.Product = '';
    this.vSearchForm.patchValue({ drpProduct: "" });
  }
  status: string = '';
  clearstatus() {
    this.status = '';
    this.stockForm.patchValue({ txtStatus: "" });
  }
  Category: string = '';
  clearCategory() {
    this.Category = '';
    this.stockForm.patchValue({ txtCategoryName: "" });
  }
  MainProduct: string = '';
  clearMainProduct() {
    this.MainProduct = '';
    this.qtyNeedToAddInStock = '';
    this.stockForm.patchValue({ txtMainProduct: "" });
  }
  supplyVendor: string = '';
  clearsupplyVendor() {
    this.supplyVendor = '';
    this.stockForm.patchValue({ txtVendorName: "" });
  }

  // stock export
  // export functionality
  exportTableData() {
    let datas: ItemExportDatas[] = []
    this.dataSource?.data?.forEach(element => {

      datas.push({
        productName: element?.productName,
        brandName: element?.productBrandName,
        categoryName: element?.productCategoryName,
        lotNo: element?.lotNo,
        stockCount: String(element?.purchasedStockCount),
        soldCount: String(element?.soldCount),
        balanceCount: String(element?.balStock),
        stockStatus: element?.status,
        stockCreatedDate: element?.createdDate
      });

    })
    let itemExport: ItemExportDTO = {
      columns: ["Product Name", "Brand Name", "Category Name", "Lot No", "Stock Count", "Sold Count", "Balance Count", "Stock Status", "Stock Created Date"],
      datas: datas
    };
    const exportItem = this.reportService.exportItemReportsByExportTypeAndExportDatas(ExportType.ProductStockReports, itemExport).subscribe(response => {
      if (response && response?.length !== 0) {
        let filelocation: String = String("data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64," + response);
        let link = document.createElement("a");
        link.download = ("Product-Stock") + ".xlsx";
        link.href = filelocation.toString();
        link.click();
        // Swal.fire({ title: 'Success', html: 'Exported Successfully', icon: 'success', timer: 3000, timerProgressBar: true })
        this.toastr.success('Exported Successfully','Success')
      }
    }, err => {
      const data: HttpErrorResponse = err;
      let errorMesg: string = data?.error;
      if (errorMesg?.includes('Sorry!! There is no data in table to export')) {
        errorMesg = 'Sorry!! There is no data in table to export';
      }
      else {
        errorMesg = data?.error?.error?.message;
      }
      // Swal.fire({
      //   icon: 'info',
      //   text: errorMesg
      // });
      this.toastr.info(errorMesg)
    });
    this.subscription$.push(exportItem);
  }
}

function dateYYYYMMDDtoMMDDYYYY(strDate: string): string {
  if (strDate && strDate !== "" && typeof strDate === 'string' && strDate !== null && strDate !== undefined && strDate.length >= 1 && !isNaN(new Date(strDate).getTime())) {
    let latest_date = Intl.DateTimeFormat('en-US')?.format(new Date(strDate))
    return latest_date;
  }
  return "";
}
