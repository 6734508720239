<table
  id="tblpatientList"
  datatable
  [dtOptions]="dtPatientListOptions"
  class="tbl hover row-border hover w-100 display"
>
  <thead>
    <tr>
      <!-- class="text-center" -->
      <th><mat-icon>apps</mat-icon></th>
      <th [matTooltip]="'Chart No'">Chart No</th>
      <th [matTooltip]="'Patient Name'" class="adjustColumn">Patient Name</th>
      <th [matTooltip]="'Home No'">Home No</th>
      <th [matTooltip]="'Mobile No'">Mobile No</th>
      <th [matTooltip]="'DOB(Age)'" class="text-center">DOB(Age)</th>
      <th [matTooltip]="'Email'" class="adjustColumn">Email</th>
      <th [matTooltip]="'Primary Insurance Name'">Primary Insurance Name</th>
      <th [matTooltip]="'Ordering'">Ordering</th>
      <th [matTooltip]="'CSR'">CSR</th>
      <th [matTooltip]="'Setup Date'" class="text-center">Setup Date</th>
      <th [matTooltip]="'Mask Date'" class="text-center">Mask Date</th>
      <th [matTooltip]="'Created By'">Created By</th>
      <th [matTooltip]="'Created Date'" class="text-center">Created Date</th>
      <th [matTooltip]="'Modify By'">Modify By</th>
      <th [matTooltip]="'Modified Date'" class="text-center">Modified Date</th>
      <th [matTooltip]="'SSN'">SSN</th>
    </tr>
  </thead>

  <tbody>
    <tr
      [ngClass]="
        tblItemSelectedRowId === data?.patientId ? 'row-highlighted' : ''
      "
      *ngFor="let data of patientListDataArray"
      (click)="
        tblItemSelectedRow(data?.patientId, data?.documentId, data?.chartId)
      "
    >
      <td>
        <mat-icon
          style="cursor: pointer !important"
          [matTooltip]="'Open Options'"
          [matMenuTriggerFor]="menu"
          >more_vert</mat-icon
        >
        <mat-icon
          style="cursor: pointer !important; color: lightseagreen"
          [matTooltip]="'Upload Fax'"
          [class.disabled]="isEmpty(data?.patientId)"
          (click)="addDocumentType(data?.patientId, data?.wipId)"
        >
          cloud_upload</mat-icon
        >
        <mat-menu class="custom-mat-menu" #menu="matMenu">
          <button
            mat-menu-item
            [disabled]="data?.patientId === defaultGuid"
            (click)="openAddNotesPopUp()"
          >
            Add Patient Note
          </button>
          <button
            mat-menu-item
            [disabled]="data?.patientId === defaultGuid"
            (click)="openViewNotesPopup()"
          >
            View Patient Notes
          </button>
          <a
            [class.disabled]="
              isEmpty(data?.documentId) || data?.isSorted === false
            "
            [routerLink]="
              '/view_document_tiff/' + data?.documentId + '/' + true
            "
            target="_blank"
            class="preview-link"
          >
            <button
              [disabled]="isEmpty(data?.documentId) || data?.isSorted === false"
              mat-menu-item
            >
              View Document Images
            </button>
          </a>
          <button mat-menu-item (click)="viewPatientLedger(data?.patientId)">
            View Patient Ledger
          </button>
          <a
            [routerLink]="['/emailprintletter/' + data?.patientId]"
            target="_blank"
            class="preview-link"
          >
            <button mat-menu-item>Email supplies Reminder</button>
          </a>
          <mat-divider></mat-divider>
          <!-- <a
            [routerLink]="
              '/orders/saleOrder/' +
              data?.patientId +
              '/' +
              defaultGuid +
              '/' +
              '0'
            "
            target="_blank"
            class="preview-link"
          >
            <button mat-menu-item>Start New Order</button>
          </a> -->

        <a [routerLink]="'/orders/saleOrder/' + data?.patientId" target="_blank" class="preview-link">
          <button mat-menu-item>Start New Order</button>
        </a>
          <mat-divider></mat-divider>
          <a
            [routerLink]="'/settings/calender/' + data?.patientId"
            target="_blank"
            class="preview-link"
          >
            <button mat-menu-item>Schedule Appointment</button>
          </a>
          <mat-divider></mat-divider>

          <button mat-menu-item (click)="ondemandOnlineVerification(data)">
            Ondemand/Online Verification Request
          </button>

          <!-- <button mat-menu-item (click)="EditPolicy(data)">Online
            Verification</button> -->
          <mat-divider></mat-divider>
          <a
            [class.disabled]="
              data?.isMedicarePolicy === 0 ||
              isEmpty(data?.blobname) ||
              isEmpty(data?.documentId || data?.documentId === defaultGuid)
            "
            [routerLink]="'/do_checklist/' + data?.patientId + '/' + '/' + null"
            target="_blank"
            class="preview-link"
          >
            <button
              [disabled]="
                isEmpty(data?.blobname) ||
                isEmpty(data?.documentId) ||
                data?.isMedicarePolicy === 0 ||
                data?.documentId === defaultGuid
              "
              mat-menu-item
            >
              New CheckList
            </button>
          </a>
          <mat-divider></mat-divider>
          <a
            ><button (click)="openTextConversation()" mat-menu-item>
              Text Conversation
            </button></a
          >
          <mat-divider></mat-divider>
          <button mat-menu-item   [disabled]="isEmpty(data?.blobname) || isEmpty(data?.documentId)" (click)="patientDocPdfDownload(data)">
            Download Patient Document
          </button>
        </mat-menu>

        <div>
          <mat-form-field style="display: none">
            <input
              matInput
              [matDatepicker]="datepicker"
              placeholder="Choose a date"
              (dateChange)="onDateSelected($event)"
            />
            <mat-datepicker-toggle
              matSuffix
              [for]="datepicker"
            ></mat-datepicker-toggle>
            <mat-datepicker
              #datepicker
              [xPosition]="'before'"
              [yPosition]="'above'"
            ></mat-datepicker>
          </mat-form-field>
        </div>
      </td>
      <td
        (dblclick)="onRowDoubleClick(data?.chartId || '-')"
        [matTooltip]="data?.chartId || '-'"
      >
        <a
          [class.disabled]="isEmpty(data?.chartId) || isEmpty(data?.patientId)"
          [routerLink]="
            '/patientCreateEditTab/' + data?.patientId + '/' + data?.documentId
          "
          target="_blank"
          class="preview-link"
          >{{ data?.chartId || "-" }}
        </a>
      </td>
      <td
        (dblclick)="onRowDoubleClick(data?.patientName || '-')"
        [matTooltip]="data?.patientName || '-'"
        class="adjustColumn"
      >
        {{ data?.patientName || "-" }}
      </td>
      <td
        (dblclick)="onRowDoubleClick(data?.homePhone || '-')"
        [matTooltip]="data?.homePhone || '-'"
      >
        {{ data?.homePhone || "-" }}
      </td>
      <td
        (dblclick)="onRowDoubleClick(data?.cellNo || '-')"
        [matTooltip]="data?.cellNo || '-'"
      >
        {{ data?.cellNo || "-" }}
      </td>
      <td
        (dblclick)="onRowDoubleClick(data?.dob)"
        [matTooltip]="data?.dob + ' (' + data?.patientAge + ')'"
        class="text-center"
      >
        {{ data?.dob }} ({{ data?.patientAge}})
      </td>
      <td
        (dblclick)="onRowDoubleClick(data?.emailId || '-')"
        [matTooltip]="data?.emailId || '-'"
        class="adjustColumn"
      >
        {{ data?.emailId || "-" }}
      </td>
      <td
        (dblclick)="onRowDoubleClick(data?.primaryInsuranceName || '-')"
        [matTooltip]="data?.primaryInsuranceName || '-'"
      >
        {{ data?.primaryInsuranceName || "-" }}
      </td>
      <td
        (dblclick)="onRowDoubleClick(data?.ordering || '-')"
        [matTooltip]="data?.ordering || '-'"
      >
        {{ data?.ordering || "-" }}
      </td>
      <td
        (dblclick)="onRowDoubleClick(data?.csrName || '-')"
        [matTooltip]="data?.csrName || '-'"
      >
        {{ data?.csrName || "-" }}
      </td>
      <td
        (dblclick)="onRowDoubleClick(data?.setupDate)"
        [matTooltip]="data?.setupDate"
        class="text-center"
      >
        {{ data?.setupDate || "-" }}
      </td>
      <td
        (dblclick)="onRowDoubleClick(data?.maskDate)"
        [matTooltip]="data?.maskDate"
        class="text-center"
      >
        {{ data?.maskDate || "-" }}
      </td>
      <td
        [matTooltip]="data?.createdBy || '-'"
        (dblclick)="onRowDoubleClick(data?.createdBy || '-')"
      >
        {{ data?.createdBy || "-" }}
      </td>

      <td
        class="text-center"
        [matTooltip]="data?.createdDate"
        (dblclick)="onRowDoubleClick(data?.createdDate)"
      >
        {{ data?.createdDate }}
      </td>
      <td
        [matTooltip]="data?.modifyBy || '-'"
        (dblclick)="onRowDoubleClick(data?.modifyBy || '-')"
      >
        {{ data?.modifyBy || "-" }}
      </td>

      <td
        class="text-center"
        [matTooltip]="data?.modifiedDate"
        (dblclick)="
          onRowDoubleClick(data?.modifiedDate)
        "
      >
        {{ data?.modifiedDate }}
      </td>
      <td
        (dblclick)="onRowDoubleClick(data?.ssn || '-')"
        [matTooltip]="data?.ssn || '-'"
      >
        {{ data?.ssn || "-" }}
      </td>
    </tr>
  </tbody>
  <tfoot>
    <tr>
      <th><mat-icon>apps</mat-icon></th>
      <th>
        <input
          matInput
          type="text"
          class="textbox-border-class"
          placeholder="Chart No"
          name="search-ChartNo"
        />
      </th>
      <th class="adjustColumn">
        <input
          matInput
          type="text"
          class="textbox-border-class"
          placeholder="Patient Name"
          name="search-PatientName"
        />
      </th>
      <th>
        <input
          matInput
          type="text"
          class="textbox-border-class"
          placeholder="Home No"
          name="search-HomeNo"
        />
      </th>
      <th>
        <input
          matInput
          type="text"
          class="textbox-border-class"
          placeholder="Mobile No"
          name="search-MobileNo"
        />
      </th>
      <th>
        <input
          matInput
          type="text"
          class="textbox-border-class text-center"
          placeholder="DOB(Age)"
          name="search-DOB"
        />
      </th>
      <th class="adjustColumn">
        <input
          matInput
          type="text"
          class="textbox-border-class"
          placeholder="Email"
          name="search-Email"
        />
      </th>
      <th>
        <input
          matInput
          type="text"
          class="textbox-border-class"
          placeholder="Primary Ins Name"
          name="search-PrimaryInsName"
        />
      </th>
      <th>
        <input
          matInput
          type="text"
          class="textbox-border-class"
          placeholder="Ordering"
          name="search-Ordering"
        />
      </th>
      <th>
        <input
          matInput
          type="text"
          class="textbox-border-class"
          placeholder="CSR"
          name="search-CSR"
        />
      </th>
      <th>
        <input
          matInput
          type="text"
          class="textbox-border-class text-center"
          placeholder="Setup Date"
          name="search-SetupDate"
        />
      </th>
      <th class="text-right">
        <input
          matInput
          class="textbox-border-class text-center"
          type="text"
          placeholder="Mask Date"
          name="search-Mask Date"
        />
      </th>
      <th>
        <input
          matInput
          class="textbox-border-class"
          type="text"
          placeholder="Created By"
          name="search-Created By"
        />
      </th>
      <th class="text-right">
        <input
          matInput
          class="textbox-border-class text-center"
          type="text"
          placeholder="Created Date"
          name="search-Created Date"
        />
      </th>
      <th>
        <input
          matInput
          class="textbox-border-class"
          type="text"
          placeholder="Modify By"
          name="search-Modify By"
        />
      </th>
      <th class="text-right">
        <input
          matInput
          class="textbox-border-class text-center"
          type="text"
          placeholder="Modified Date"
          name="search-Modified Date"
        />
      </th>
      <th>
        <input
          matInput
          class="textbox-border-class"
          type="text"
          placeholder="SSN"
          name="search-ssn"
        />
      </th>
    </tr>
  </tfoot>
</table>
