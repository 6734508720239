import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  NgZone,
  ChangeDetectorRef,
} from '@angular/core';
import { InboundDocumentService } from '../../admin-proxy/platform-app-management/rcm/platform-management/inbound/inbound-document.service';
import { Subscription } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import Swal from 'sweetalert2';
import { DataTableDirective } from 'angular-datatables';
import { MatMenu, MatMenuTrigger } from '@angular/material/menu';

@Component({
  selector: 'app-lazyloading',
  templateUrl: './lazyloading.component.html',
  styleUrls: ['./lazyloading.component.scss'],
})
export class LazyloadingComponent implements OnInit {
  @ViewChild(DataTableDirective, { static: false })
  datatableElement: DataTableDirective;
  @ViewChild('tableContainer') tableContainer: ElementRef;
  @ViewChild('faxIdInput') faxIdInput!: ElementRef;
  @ViewChild('patientNameInput') patientNameInput!: ElementRef;
  @ViewChild('chartNoInput') chartNoInput!: ElementRef;
  @ViewChild('referralSourceInput') referralSourceInput!: ElementRef;

  @ViewChild(MatMenu) menu: MatMenu;
  // @ViewChild(MatMenuTrigger) menuTrigger: MatMenuTrigger;

  // @ViewChild('menuTrigger') menuTrigger1: MatMenuTrigger;

  @ViewChild('menuTrigger') menuTrigger: ElementRef;

  dtOptions: DataTables.Settings = {};
  dataTable: any;
  faxFinderDataList: any[] = [];
  start: number = 1;
  end: number = 10;
  isLoading: boolean = false;
  private faxFinderAPICall: Subscription;
  scrollPosition: number;
  IsFaxAvailable: boolean = true;


  constructor(
    private inboundDocService: InboundDocumentService,
    private elementRef: ElementRef,
    private zone: NgZone,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit() {
    // this.dtOptions = {
    //   dom: 'Bfrtip',
    //   responsive: true,
    //   paging: false,
    //   scrollCollapse: true,
    //   scrollY: '280px',
    //   // columns: [
    //   //   { orderable: false }, // Material Icon column
    //   //   { title: 'Fax Id', data: 'faxId' }, // Fax Id column
    //   //   { title: 'Patient Name', data: 'patientName' },
    //   //   { title: 'Inbound Status', data: 'inboundStatus' },
    //   // ],
    //   data: [], // Provide an empty array or initial data here
    //   // columnDefs: [
    //   //   {
    //   //     targets: 0, // Target the first column (Material Icon column)
    //   //     render: function (data, type, row, meta) {
    //   //       // return '<mat-icon class="material-icons" (click)="openMenu($event)" [matMenuTriggerFor]="menu">more_vert</mat-icon>';
    //   //       // return '<mat-icon class="material-icons more-vert-icon" #menuTrigger mat-icon-button [matMenuTriggerFor]="menu">more_vert</mat-icon>'
    //   //       return `<button #menuTrigger mat-icon-button [matMenuTriggerFor]="menu">Open Menu</button>

    //   //       `;
    //   //     }
    //   //   },
    //   //   {
    //   //     targets: 1, // Target the second column (Fax Id column)
    //   //     render: function (data, type, row, meta) {
    //   //       return '<a href="#/dashboard" target="_blank">' + data + '</a>';
    //   //     }
    //   //   }
    //   // ],
    // //   initComplete: (settings, json) => {
    // //     const table = $('.faxfindertbl').DataTable();

    // //     $('.faxfindertbl tbody').on('click', 'mat-icon.more-vert-icon', function () {

    // //       // event.preventDefault(); // Prevent the default right-click action
    // // this.menuTrigger.openMenu(); // Open the Material menu
    // // this.detectChanges();
    // //       // const rowData = table.row($(this).closest('tr')).data();
    // //       // // Open the MatMenu programmatically
    // //       // this.menuTrigger.openMenu();
    // //       // this.menu.Open();
    // //       // this.menuTrigger.openMenu();
    // //       // // Do something with rowData if needed

    // //     //   if (this.menuTrigger) {
    // //     //     this.menuTrigger.openMenu();
    // //     //   } else {
    // //     //     console.error('Menu trigger is not defined');
    // //     //   }
    // //     });
    // //   }
    //   // initComplete: (settings, json) => {
    //   //   const table = $('.faxfindertbl').DataTable();

    //   // }
    //   // initComplete: (settings, json) => {
    //   //   const table = $('.faxfindertbl').DataTable();


    //   //   // Attach click event listener to the button
    //   //   $('.faxfindertbl tbody').on('click', 'button[mat-icon-button]', () => {
    //   //     // Open the MatMenu when the button is clicked
    //   //     if (this.menuTrigger1) {
    //   //       this.menuTrigger1.openMenu();
    //   //     } else {
    //   //       console.error('Menu trigger is not defined');
    //   //     }
    //   //   });
    //   // }
    // };

    this.dtOptions = {
      // dom: 'Bfrtip',
      responsive: true,
      paging: false,
      scrollCollapse: true,
      scrollY: '280px',
      // data: [],
      columns: [
        // Define your columns here
        // { orderable: false }, // Material Icon column
        { title: 'Fax Id', data: 'faxId' },
        { title: 'Patient Name', data: 'patientName' },
        { title: 'Chart No', data: 'chartId' },
        { title: 'Inbound Status', data: 'inboundStatus' }
      ]
    };

    this.loadDefault();
  }

  ngAfterViewInit() {
    this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
      this.dataTable = dtInstance;
    });
    setTimeout(() => {
      this.refreshDataTable();
    }, 100);

    window.addEventListener('scroll', this.onTableScroll, true);

    // this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
    //   this.dataTable = dtInstance;
    //   const container = dtInstance.table(0).container();
    //   if (container instanceof HTMLElement) {
    //     window.addEventListener('scroll', () => this.onTableScroll(), true);
    //   } else {
    //     console.error("Container is not an HTMLElement.");
    //   }
    // });

    const tableElement: any = $(this.tableContainer.nativeElement).find(
      'table'
    );
    // this.dataTable = tableElement.DataTable(this.dtOptions);

    // Add click event listener to handle row selection
    tableElement.on('click', 'tbody tr', (event: any) => {
      const $row = $(event.currentTarget);
      const rowData = this.dataTable.row($row).data();

      // Remove previous selected row highlighting
      // tableElement.find('.selected-row').removeClass('selected-row');
      // tableElement.find('.selected').removeClass('selected');
      // $row.addClass('selected-row');
      // $row.css('background-color', 'lightseagreen');

      tableElement
        .find('tbody tr.selected')
        .removeClass('selected')
        .css('background-color', '')
        .css('color', '');
      tableElement
        .find('tbody tr.selected-row')
        .removeClass('selected-row')
        .css('background-color', '')
        .css('color', '');

      // Apply background color to the clicked row
      $row.addClass('selected-row').css('background-color', 'lightseagreen');

      // If using DataTables row selection feature, handle it here
      if (
        this.dataTable &&
        this.dataTable.rows({ selected: true }).count() > 0
      ) {
        this.dataTable.rows().deselect();
      }
      this.dataTable.row($row).select();
      tableElement
        .find('tbody tr.selected')
        .removeClass('selected')
        .css('background-color', '')
        .css('color', '');
        $row.find('tbody tr.selected a').css('color', '');
      $row.css('background-color', 'lightseagreen').css('color', 'white');
      // $row.find('a').css('color', 'white');

    });

    // const table = $('.faxfindertbl').DataTable();

    // // Attach click event listener to the button
    // $('.faxfindertbl tbody').on('click', 'button[mat-icon-button]', () => {
    //   // Open the MatMenu when the button is clicked
    //   const menuTriggerElement: HTMLElement = this.menuTrigger.nativeElement;
    //   menuTriggerElement.click();
    // });
  }

  // openMenu(event: MouseEvent) {
  //   event.preventDefault(); // Prevent the default right-click action
  //   // this.menuTrigger.openMenu(); // Open the Material menu
  // }

  // onTableScroll = (event: Event) => {
  //   const container = event.target as HTMLElement;
  //   const scrollPosition = container.scrollTop + container.clientHeight;
  //   let isScrollAtBottom = scrollPosition >= container.scrollHeight - 50; // Adjust threshold as needed


  //   if (isScrollAtBottom && !this.isLoading && scrollPosition > 0) {

  //     this.scrollPosition = container.scrollTop;
  //     this.loadMore();
  //   }
  // };

  onTableScroll = (event: Event) => {
    const container = event.target as HTMLElement;
    const scrollPosition = container.scrollTop + container.clientHeight;
    const isScrollAtBottom = scrollPosition >= container.scrollHeight - container.clientHeight - 100; // Check for exact bottom


    if (isScrollAtBottom && !this.isLoading && scrollPosition > 0) {

        this.scrollPosition = container.scrollTop -50;
        this.loadMore();
    }
};
  // onTableScroll = () => {
  //   const container = this.datatableElement.dtInstance.tableView.nativeElement as HTMLElement;
  //   const scrollPosition = container.scrollTop + container.clientHeight;
  //   const isScrollAtBottom = scrollPosition >= container.scrollHeight - 100; // Adjust threshold as needed

  //   if (isScrollAtBottom && !this.isLoading && scrollPosition > 0) {
  //     this.scrollPosition = container.scrollTop;
  //     this.loadMore();
  //   }
  // };

  // onTableScroll(container: HTMLElement) {
  //   const scrollPosition = container.scrollTop + container.clientHeight;
  //   const isScrollAtBottom = scrollPosition >= container.scrollHeight - 100; // Adjust threshold as needed

  //   if (isScrollAtBottom && !this.isLoading && scrollPosition > 0) {
  //     this.scrollPosition = container.scrollTop;
  //     this.loadMore();
  //   }
  // }

  loadMore() {
    if (this.IsFaxAvailable) {
      this.isLoading = true;
      const container = this.tableContainer.nativeElement;
      const scrollPositionBeforeLoad = container.scrollTop;

      this.start++;
      this.faxFinderAPICall = this.inboundDocService
        .getFaxDocumentDetailsByFilter(
          this.start,
          this.end,
          null,
          null,
          null,
          null
        )
        .subscribe(
          (response) => {
            this.IsFaxAvailable = response['bIsFaxAvailable'] || false;
            this.faxFinderDataList = [
              ...this.faxFinderDataList,
              ...response['ltFaxes'],
            ];
            if (this.dataTable) {
              // this.dataTable.clear();
              // this.dataTable.rows.add(response['ltFaxes'] || []);
              // this.dataTable.draw();

              // this.cdr.detectChanges();

              this.dataTable.rows.add(response['ltFaxes'] || []).draw();
                        this.cdr.detectChanges(); // Trigger change detection

              const dataTableBody = this.elementRef.nativeElement.querySelector(
                'div.dataTables_scrollBody'
              );
              if (dataTableBody) {
                dataTableBody.scrollTop = this.scrollPosition;
              }
            }
            this.isLoading = false;
          },
          (error) => {
            const data: HttpErrorResponse = error;
            Swal.fire({
              icon: 'info',
              text: data?.error?.error?.message,
            });
            this.isLoading = false;
          }
        );
    }
  }

  loadDefault(start: number = 1, end: number = 10) {
    this.isLoading = true;
    this.faxFinderAPICall = this.inboundDocService
      .getFaxDocumentDetailsByFilter(start, end, null, null, null, null)
      .subscribe(
        (response) => {
          this.faxFinderDataList = response['ltFaxes'];

          if (this.dataTable) {
            this.dataTable.clear();
            this.dataTable.rows.add(this.faxFinderDataList);
            this.dataTable.draw();
          }
          this.isLoading = false;
        },
        (error) => {
          const data: HttpErrorResponse = error;
          Swal.fire({
            icon: 'info',
            text: data?.error?.error?.message,
          });
          this.isLoading = false;
        }
      );
  }

  refreshDataTable(): void {
    // const table = $('#tableContainer table').DataTable(); // Initialize DataTable

    const table = this.dataTable;

    if (!table) {

      return;
    }

    // Clear search input values
    $('tfoot input').val('');

    // Attach the event listeners for filtering
    $(this.faxIdInput.nativeElement).keyup(() => {
      table.column(0).search(this.faxIdInput.nativeElement.value).draw();
    });
    $(this.patientNameInput.nativeElement).keyup(() => {
      table.column(1).search(this.patientNameInput.nativeElement.value).draw();
    });
    $(this.chartNoInput.nativeElement).keyup(() => {
      table.column(2).search(this.chartNoInput.nativeElement.value).draw();
    });
    $(this.referralSourceInput.nativeElement).keyup(() => {
      table
        .column(3)
        .search(this.referralSourceInput.nativeElement.value)
        .draw();
    });
  }
}
