<div class="table table-responsive">
    <table id="tblMasterPhysician" datatable [dtOptions]="dtOptionsPhysician"
        class="tbl hover row-border hover w-100 display">
        <thead>
            <tr>
                <th [matTooltip]="'NPI Number'">NPI Number</th>
                <th [matTooltip]="'First Name'">First Name</th>
                <th [matTooltip]="'Last Name'">Last Name</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let data of physicianDataList">

                <td (dblclick)="onRowDoubleClick(data?.npiNumber || '-')" [matTooltip]="data?.npiNumber">
                    {{data?.npiNumber ||'-' }}
                </td>
    
                <td (dblclick)="onRowDoubleClick(data?.firstName || '-')" [matTooltip]="data?.firstName">
                    {{data?.firstName ||'-' }}
                </td>

                <td (dblclick)="onRowDoubleClick(data?.lastName || '-')" [matTooltip]="data?.lastName">
                    {{data?.lastName ||'-' }}
                </td>
            </tr>
        </tbody>
        <tfoot>
            <tr>
                <th>
                    <input matInput class="textbox-border-class" type="text" placeholder="NPI Number"
                        name="search-NPINumber" />
                </th>
                <th>
                    <input matInput class="textbox-border-class" type="text" placeholder="First Name"
                        name="search-FirstName" />
                </th>
                <th>
                    <input matInput class="textbox-border-class" type="text" placeholder="Last Name"
                        name="search-LastName" />
                </th>
            </tr>
        </tfoot>
    </table>
</div>