
<mat-dialog-content class="mat-typography">
  <div class="modal-header">
      <h1  class="modal-title head" >Reset Password</h1>
      <button class="text-right close" aria-label="Close" mat-dialog-close>
        <b> X </b>
      </button>
    </div>
<!-- <mat-dialog-content class="mat-typography" style="height:70% !important"> -->
  <div class="modal-body">
    <form [formGroup]="UserForm">
      <mat-form-field class=" col-6 pr-3 mt-3" id="userDrp">
        <mat-label>User<span class="asterisk">*</span></mat-label>
        <mat-select #selectBranch formControlName="drpUser" multiple
          (selectionChange)="onUserSelectionChange($event)">
          <mat-option>
            <ngx-mat-select-search placeholderLabel="Search" noEntriesFoundLabel="No Matches found" [formControl]="UserFilterControl"
              formControlName="txtUser"></ngx-mat-select-search>
          </mat-option>
          <mat-option *ngIf="!UserSelectAllOption" [value]="'selectAll'">Select All</mat-option>
          <mat-option *ngIf="UserSelectAllOption" [value]="'DeselectAll'">Deselect All</mat-option>

          <mat-option *ngFor="let user of UserList" [value]="user.userId">
            {{ user.name }}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="
        UserForm?.get('txtUser')?.touched &&
        UserForm?.get('txtUser')?.errors?.required
        ">
        User is a required field!
      </mat-error>
      </mat-form-field>
      <mat-form-field class="col-6">
        <mat-label> Password <span class="asterisk">*</span></mat-label>
        <input matInput maxlength="40" [type]="hide ? 'password' : 'text'" formControlName="txtUserPassword"
          autocomplete="new-txtUserPassword" pattern="(?=.*\d)(?=.*[a-z])(?=.*[$@$#!%*?^()&])(?=.*[A-Z]).{8,}"
          type="text" />
        <a class="font-size-20" mat-icon-button matSuffix (click)="hide = !hide">
          <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
        </a>
        <mat-error *ngIf="
          UserForm?.get('txtUserPassword')?.touched &&
          UserForm?.get('txtUserPassword')?.errors?.required
          ">
          Password is a required field!
        </mat-error>
        <mat-error *ngIf="
          UserForm?.get('txtUserPassword')?.touched &&
          UserForm?.get('txtUserPassword')?.errors?.pattern
        ">
          Passwords must contain at least one non alphanumeric character,one uppercase,one digit
          and at
          least 8 or more characters
        </mat-error>
      </mat-form-field>
    </form>
   </div>
   <div class="modal-footer">
    <div class="col-12 text-center">
       <button mat-button align="end" mat-raised-button class="buttonColor"
       [disabled]="isSaveButton||UserForm.invalid" (click)="changePassword()">Change Password</button>
    </div>
  </div>
</mat-dialog-content>
