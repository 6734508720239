import type { AuditLogsDTO, CreateUpdateAuditLogsDTO } from './dto/models';
import { RestService } from '@abp/ng.core';
import type { PagedAndSortedResultRequestDto, PagedResultDto } from '@abp/ng.core';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class AuditLogsService {
  apiName = 'Default';

  create = (input: CreateUpdateAuditLogsDTO) =>
    this.restService.request<any, AuditLogsDTO>({
      method: 'POST',
      url: `/api/app/audit-logs`,
      body: input,
    },
      { apiName: this.apiName, skipHandleError: true });

  delete = (id: string) =>
    this.restService.request<any, void>({
      method: 'DELETE',
      url: `/api/app/audit-logs/${id}`,
    },
      { apiName: this.apiName, skipHandleError: true });

  get = (id: string) =>
    this.restService.request<any, AuditLogsDTO>({
      method: 'GET',
      url: `/api/app/audit-logs/${id}`,
    },
      { apiName: this.apiName, skipHandleError: true });

  getList = (input: PagedAndSortedResultRequestDto) =>
    this.restService.request<any, PagedResultDto<AuditLogsDTO>>({
      method: 'GET',
      url: `/api/app/audit-logs`,
      params: { skipCount: input.skipCount, maxResultCount: input.maxResultCount, sorting: input.sorting },
    },
      { apiName: this.apiName, skipHandleError: true });

  update = (id: string, input: CreateUpdateAuditLogsDTO) =>
    this.restService.request<any, AuditLogsDTO>({
      method: 'PUT',
      url: `/api/app/audit-logs/${id}`,
      body: input,
    },
      { apiName: this.apiName, skipHandleError: true });

  generatesCommentsBySCommentsAndPropertyAndSChangedFromAndSChangedTo = (sComments: string, property: string, sChangedFrom: string, sChangedTo: string) =>
    this.restService.request<any, string>({
      method: 'POST',
      responseType: 'text',
      url: `/api/app/audit-logs/generates-comments`,
      params: { sComments: sComments, property: property, sChangedFrom: sChangedFrom, sChangedTo: sChangedTo },
    },
      { apiName: this.apiName, skipHandleError: true });

  getAuditLogsReportsByPatientId = (patientId: string) =>
    this.restService.request<any, AuditLogsDTO[]>({
      method: 'GET',
      url: `/api/app/audit-logs/get-audit-logs-reports/${patientId}`,
    },

      { apiName: this.apiName, skipHandleError: true });

  auditlogsoptimization = (patientId: string) =>
    this.restService.request<any, AuditLogsDTO[]>({
      method: 'GET',
      url: `/api/app/audit-logs-optimization/get-audit-logs-reports/${patientId}`,
    },
      { apiName: this.apiName, skipHandleError: true });
  constructor(private restService: RestService) { }
}
