import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { DataTableDirective } from 'angular-datatables';
import { MmOrderService } from '../order-proxy/order-management/order-optimization/mm-order.service';
import Swal from 'sweetalert2';
import { HttpErrorResponse } from '@angular/common/http';
import { defaultGuid } from 'projects/shared/src/app/enums/allenums.enum';
import { Title } from '@angular/platform-browser';
import { Subscription } from 'rxjs';
import { mmRentalTypes } from '../order-proxy/order-management/order-optimization/mm-rental-types.enum';
import { LoaderService } from 'projects/shared/src/app/services/loader-service';
import { CommonService } from 'projects/shared/src/app/services/common.service';

@Component({
  selector: 'app-rental-list',
  templateUrl: './rental-list.component.html',
  styleUrls: ['./rental-list.component.scss'],
})
export class RentalListComponent implements OnInit {
  rentalListFilterForm: FormGroup;
  rentalList: any;
  isLoaded: boolean = true;
  isTableLoaded: boolean = true;
  rentalItemList: any;
  @ViewChild(DataTableDirective, { static: false })
  datatableElement: DataTableDirective;
  myTable: DataTables.Api;
  rentalForm: FormGroup;
  dtRentalListOptions: any = {
    responsive: true,
    dom: 'Bfrtip',
    search: {
      smart: false, // Set to "none" for full exact match
    },
    paging: false,
    scrollCollapse: true,
    scrollY: '300px',
    scrollX: true,
    language: {
      emptyTable: 'No records',
      info: 'Total : _MAX_ records',
      infoEmpty: 'Total : _MAX_ records',
      infoFiltered: '(filtered : _TOTAL_ records)',
      loadingRecords: 'Loading...',
      zeroRecords: 'No matching records',
    },
    // info: false,
    buttons: [
      {
        extend: 'excel',
        text: 'Export', // Change the text to 'Export'
        filename: 'Rental List',
        title:'Rental List',
      },
    ],
  };

  dtRentalItemListOptions: any = {
    responsive: true,
    dom: 'Bfrtip',
    search: {
      smart: false, // Set to "none" for full exact match
    },
    paging: false,
    scrollCollapse: true,
    scrollY: '200px',
    scrollX: true,
    language: {
      emptyTable: 'No records',
      info: 'Total : _MAX_ records',
      infoEmpty: 'Total : _MAX_ records',
      infoFiltered: '(filtered : _TOTAL_ records)',
      loadingRecords: 'Loading...',
      zeroRecords: 'No matching records',
    },
    columnDefs: [
      { orderable: true, targets: '_all' },
    ],
    // info: false,
    buttons: [
      {
        extend: 'excel',
        text: 'Export', // Change the text to 'Export'
        filename: 'Rental  Item List',
        title:'Qsecure | Rental Item List',
      },
    ],
  };
  startDate: any;
  endDate: any;
  fromDate: any;
  toDate: any;
  selectedRentalData: any;
  isOnkeySearch: boolean = false;
  private dataSubscription: Subscription;
  constructor(
    private formBuilder: FormBuilder,
    private datePipe: DatePipe,
    private title: Title,
    private mmorderService: MmOrderService,
    private loaderService: LoaderService,
    private commonService: CommonService,
  ) {
    // this.loaderService.getLoaderState().subscribe((isLoaded) => {
    //   this.isLoaded = isLoaded;
    // });
  }

  ngOnInit(): void {
    this.rentalListFilterForm = this.formBuilder.group({
      txtTicketId: new FormControl(''),
      txtChartId: new FormControl(''),
      txtPatientName: new FormControl(''),
      txtDOSdate: new FormControl(''),
      rStatus: new FormControl('5'),
    });

    this.dtRentalListOptions.buttons[0].filename =
      'Rental List' + '-' + this.datePipe.transform(new Date(), 'MM-dd-yyyy');
    this.dtRentalItemListOptions.buttons[0].filename =
      'Rental Item List' +
      '-' +
      this.datePipe.transform(new Date(), 'MM-dd-yyyy');
    this.rentalForm = this.formBuilder.group({
      txtDateValue: new FormControl(''),
      txtDate: new FormControl(''),
    });
    this.title.setTitle('Qsecure | Rental List');

    // this.loadRentalList()
    this.getTableData();
  }

  ngOnDestroy(): void {
    // Unsubscribe to avoid memory leaks
    if (this.dataSubscription) {
      this.dataSubscription.unsubscribe();
    }
  }

  onKeyPressSearch() {
    if (this.areFieldsNotEmpty()) {
      // console.log(this.areFieldsNotEmpty());
    } else {
      if (
        this.rentalListFilterForm.valid &&
        !this.rentalListFilterForm.errors &&
        this.isOnkeySearch === true
      ) {
        this.getTableData();
      }
    }
  }

  getFormattedDateTime(date: string): string {
    if (date) {
      const formattedDate = new Date(date);
      const options: Intl.DateTimeFormatOptions = {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: 'numeric',
        minute: '2-digit',
        second: '2-digit',
        hour12: true, // 12-hour format with AM/PM
        timeZone: 'America/Chicago',
      };
      return formattedDate.toLocaleString('en-US', options);
    } else {
      return '-';
    }
  }

  getFormattedDate(date: string): string {
    if (date) {
      const formattedDate = new Date(date);
      const options: Intl.DateTimeFormatOptions = {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        timeZone: 'America/Chicago',
      };
      return formattedDate.toLocaleString('en-US', options);
    } else {
      return '-';
    }
  }
  checkTicketIdLessthan0() {
    let tId = this.rentalListFilterForm.value.txtTicketId;
    if (tId < 1) {
      this.rentalListFilterForm.patchValue({
        txtTicketId: '',
      });
    }
  }
  getTableData() {
    this.isLoaded = false;
    this.loaderService.showLoader();
    // this.isPatientSearch =true;
    this.isOnkeySearch = false;
    // this.isResetSearch=false;
    // this.patientInupt = this.branchForm.value.patientDetails;

    try {
      if (this.dataSubscription) {
        this.dataSubscription.unsubscribe();
      }
      let orderId: number = this.rentalListFilterForm.value.txtTicketId,
        chartId: string = this.rentalListFilterForm.value.txtChartId,
        patientname: string = this.rentalListFilterForm.value.txtPatientName,
        dosDate: string = this.rentalListFilterForm.value.txtDOSdate,
        status: mmRentalTypes =
          this.rentalListFilterForm.value.rStatus == 0
            ? null
            : this.rentalListFilterForm.value.rStatus;

      const dobInputConvert = this.datePipe.transform(dosDate, 'yyyy-MM-dd');

      this.dataSubscription = this.mmorderService
        .getRentalItemsListv1ByOrderIdAndChartIdAndPatientnameAndDosDateAndStatus(
          orderId,
          chartId,
          patientname,
          dobInputConvert,
          status
        )
        .subscribe(
          (response) => {
            this.rentalList = response;
            this.rentalList = this.rentalList.map((billing) => {
              return {
                ...billing,
                dosDate: this.commonService.getFormattedDateZone(
                  billing.dosDate
                ),
              };
            });
            this.tblShpSelectedRow(
              this.rentalList?.[0]?.rentalId,
              this.rentalList?.[0]
            );
            setTimeout(() => {
              this.isLoaded = true;
              this.loaderService.hideLoader();
            }, 500);

          },
          (err) => {
            this.rentalList = [];
            this.rentalItemList = [];
            const data: HttpErrorResponse = err;
            console.error('Unexpected error:', data?.error?.error?.message);
            // Swal.fire({
            //   icon: 'info',
            //   text: data?.error?.error?.message,
            // });
            setTimeout(() => {
              this.isLoaded = true;
              this.loaderService.hideLoader();
            }, 500);

          }
        );
      // this.tableData$ = getTableData;
    } catch (error) {
      console.error('Unexpected error:', error);
      setTimeout(() => {
        this.isLoaded = true;
        this.loaderService.hideLoader();
      }, 500);
    } finally {
    }
  }
  fnSearchPatient() {
    this.getTableData();
  }

  fnRentalListFilter(flag: number) {}

  fnRentalListStatusOnChange(flag: number) {
    this.fnRentalListFilter(flag);
  }

  areFieldsNotEmpty(): boolean {
    const { txtTicketId, txtChartId, txtPatientName, txtDOSdate, rStatus } =
      this.rentalListFilterForm.value;
    return txtTicketId || txtChartId || txtPatientName || txtDOSdate || rStatus;
  }
  resetFilter() {
    this.rentalListFilterForm.reset();
    this.rentalListFilterForm.patchValue({
      rStatus: '5',
    });
    this.getTableData();
  }

  resetPatientSearch() {
    throw new Error('Method not implemented.');
  }

  addSlashToDate(event: Event): void {
    const input = event.target as HTMLInputElement;
    const inputValue = input.value;
    input.value = inputValue
      .replace(/^(\d{2})(\d{1,2})/, '$1/$2')
      .replace(/^(\d{2}\/\d{2})(\d+)/, '$1/$2')
      .replace(/[^\d\/]/g, '');
  }

  // Function to get the first and last dates of the current month
  getFirstAndLastDatesOfCurrentMonth(): { firstDate: Date; lastDate: Date } {
    const today = new Date();
    const firstDate = new Date(today.getFullYear(), today.getMonth(), 1);
    const lastDate = new Date(today.getFullYear(), today.getMonth() + 1, 0);
    return { firstDate, lastDate };
  }
  loadRentalList() {
    this.isLoaded = false;
    const today = new Date();
    // Today's date
    const todayDate = this.datePipe.transform(today, 'MM-dd-yyyy');
    // First date of the current month
    // const firstDate = this.datePipe.transform(today, 'MM-01-yyyy');

    // Last date of the current month
    // const lastDate = this.datePipe.transform(new Date(today.getFullYear(), today.getMonth() + 1, 0), 'yyyy-MM-dd');

    // this.startDate = firstDate,
    this.endDate = todayDate;

    const { firstDate, lastDate } = this.getFirstAndLastDatesOfCurrentMonth();
    this.fromDate = this.datePipe.transform(firstDate, 'MM/dd/yyyy');
    this.toDate = this.datePipe.transform(lastDate, 'MM/dd/yyyy');
    this.rentalForm.patchValue({
      txtDateValue: firstDate,
      txtDate: lastDate,
    });

    this.mmorderService
      .getRentalItemsListByDtFromDateAndDtToDate(this.fromDate, this.toDate)
      .subscribe(
        (response) => {
          this.isLoaded = true;
          this.rentalList = response;
          this.tblShpSelectedRow(
            this.rentalList?.[0]?.rentalId,
            this.rentalList?.[0]
          );
        },
        (err) => {
          this.isLoaded = true;
          const data: HttpErrorResponse = err;
          Swal.fire({
            icon: 'info',
            text: data?.error?.error?.message,
          });
        }
      );
  }

  isEmpty(value: any): boolean {
    return (
      value === null ||
      value === undefined ||
      value === '' ||
      value === defaultGuid
    );
  }
  openDatePicker(event: MatDatepickerInputEvent<Date>, dateType) {
    const startDate = this.rentalForm.get('txtDateValue').value;
    const endDate = this.rentalForm.get('txtDate').value;
    this.fromDate = this.datePipe.transform(startDate, 'MM/dd/yyyy');
    this.toDate = this.datePipe.transform(endDate, 'MM/dd/yyyy');

    this.mmorderService
      .getRentalItemsListByDtFromDateAndDtToDate(this.fromDate, this.toDate)
      .subscribe(
        (response) => {
          this.isLoaded = true;
          this.rentalList = response;
          this.rentalList = this.rentalList.map((billing) => {
            return {
              ...billing,
              dosDate: this.commonService.getFormattedDateZone(
                billing.dosDate
              ),
            };
          });
          this.tblShpSelectedRow(
            this.rentalList?.[0]?.rentalId,
            this.rentalList?.[0]
          );
        },
        (err) => {
          this.isLoaded = true;
          const data: HttpErrorResponse = err;
          Swal.fire({
            icon: 'info',
            text: data?.error?.error?.message,
          });
        }
      );
  }

  ngAfterViewInit(): void {
    this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.columns().every(function () {
        const that = this;
        $('input', this.footer()).on('keyup change', function () {
          if (that.search() !== this['value']) {
            that.search(this['value']).draw();
          }
        });
      });
    });
  }

  loadRentalItemList(rentalId) {
    this.isTableLoaded = false;
    this.mmorderService
      .getRentalItemsListByIdOrderIdByGOrder(rentalId)
      .subscribe(
        (response) => {
          this.rentalItemList = response;
          this.rentalItemList = this.rentalItemList.map((billing) => {
            return {
              ...billing,
              dosDate: this.commonService.getFormattedDateZone(
                billing.dosDate
              ),
              receiveDate: this.commonService.getFormattedDateTimeZone(
                billing.receiveDate
              ),
            };
          });
          setTimeout(() => {
            this.isTableLoaded = true;
          }, 1000);
        },
        (err) => {
          setTimeout(() => {
            this.isTableLoaded = true;
          }, 1000);
          // const data: HttpErrorResponse = err;
          //     Swal.fire({
          //       icon: 'info',
          //       text: data?.error?.error?.message,
          //     });
        }
      );
  }

  tblShpSelectedRowId: string = defaultGuid;
  tblItemSelectedRowId: string = defaultGuid;
  //Select Shipping Details
  tblShpSelectedRow(id: string, data) {
    this.selectedRentalData = data;
    // this.tblShpSelectedRowId =
    //   this.tblShpSelectedRowId === id ? defaultGuid : id;
    this.tblShpSelectedRowId = id;
    this.loadRentalItemList(this.tblShpSelectedRowId);
    // this.tblShpSelectedRowId != defaultGuid && this.getOrderItems(id);
    // this.tblShpSelectedRowId != defaultGuid &&
    //   this.getNotesDetails(data?.patientId);
  }
  fnSumOfValue(data: number, data2: number): number {
    return data + data2;
  }
}
