<mat-dialog-content class="mat-typography">
    <div class="row">
        <div class="col-lg-6">
            <h2> {{"Reports"}}</h2>
        </div>
    </div>
    <hr />
    <div class="row">
        <div class="col-lg-12">
            <mat-card-content>
                <!-- Weekly Reports -->
                <div class="row">
                    <div class="col-lg-12">
                        <mat-accordion class="example-headers-align" multi>
                            <mat-expansion-panel (afterExpand)="getWeeklyReports()">
                                <mat-expansion-panel-header>
                                    <mat-panel-title class="customThemeClass font-size-18">
                                        <b>Weekly Reports</b>
                                    </mat-panel-title>
                                </mat-expansion-panel-header>

                                <mat-card class="container">
                                    <section>
                                        <h4><strong class="customThemeClass">Period :</strong> <b
                                                class="ml-2">{{currentPeriod}}</b>
                                        </h4>
                                        <h4><strong class="customThemeClass">Total Billing :</strong>
                                            <b class="ml-2">{{"$ " + (totalBilling||0)}}</b>
                                        </h4>
                                        <h4><strong class="customThemeClass">Previous Week
                                                :</strong> <b class="ml-2">{{"$ " + (previousWeekTotalBilling||0)}}</b>
                                        </h4>
                                        <h4> <strong class="customThemeClass">Increase or Decrease % :
                                            </strong> <b class="ml-2">{{(percent ||0 ) + " %"}}</b>
                                        </h4>
                                    </section>
                                </mat-card>

                                <div class="row">
                                    <div class="col-6 text-align-center">
                                        <button mat-button (click)="openReportsSummary('WeeklyReports',null,null)"
                                            class="buttonColor mb-2 mr-2 mt-3">View Week
                                            Summary</button>
                                    </div>
                                    <div class="col-6 text-align-center">
                                        <button mat-button (click)="openReportsSummary('WeeklyTrends',null,null)"
                                            class="buttonColor mb-2 mr-2 mt-3">Last four week
                                            trends</button>
                                    </div>
                                </div>
                            </mat-expansion-panel>
                        </mat-accordion>
                    </div>
                </div>
                <br>
                <!-- Monthly Report -->
                <div class="row">
                    <div class="col-lg-12">
                        <mat-accordion class="example-headers-align" multi>
                            <mat-expansion-panel (afterExpand)="getMonthlyReports()">
                                <mat-expansion-panel-header>
                                    <mat-panel-title class="customThemeClass font-size-18">
                                        <b>Monthly Reports</b>
                                    </mat-panel-title>
                                </mat-expansion-panel-header>

                                <mat-card class="container">
                                    <section>
                                        <h4><strong class="customThemeClass">Period :</strong> <b
                                                class="ml-2">{{currentMonthPeriod}}</b>
                                        </h4>
                                        <h4><strong class="customThemeClass">Total Billing :</strong> <b class="ml-2">
                                                {{"$ " +
                                                (totalMonthBilling||0)}}</b>
                                        </h4>
                                        <h4><strong class="customThemeClass">Previous Month
                                                :</strong> <b class="ml-2">{{"$ " + (previousMonthTotalBilling||0)}}
                                            </b></h4>
                                        <h4> <strong class="customThemeClass">Increase or Decrease % :
                                            </strong> <b class="ml-2"> {{(monthPercent ||0 ) + " %"}}</b>
                                        </h4>
                                    </section>
                                </mat-card>


                                <div class="row">
                                    <div class="col-6 text-align-center">
                                        <button mat-button (click)="openReportsSummary('MonthlyReports',null,null)"
                                            class="buttonColor mb-2 mr-2 mt-3"> View Month Summary
                                        </button>
                                    </div>
                                    <div class="col-6 text-align-center">
                                        <button mat-button (click)="openReportsSummary('MonthlyTrends',null,null)"
                                            class="buttonColor mb-2 mr-2 mt-3"> Last four month
                                            trends
                                        </button>
                                    </div>
                                </div>
                            </mat-expansion-panel>
                        </mat-accordion>
                    </div>
                </div>
                <div class="row" style="display:none;">
                  <div class="col-lg-12">
                    <mat-accordion class="example-headers-align" multi>
                      <mat-expansion-panel>
                        <mat-expansion-panel-header>
                          <mat-panel-title class="customThemeClass font-size-18">
                            <b>Custom Period</b>
                          </mat-panel-title>
                        </mat-expansion-panel-header>
                        <div class="row">
                          <h3 class="customThemeClass ml-2 col-12">Select Year
                          </h3>
                        </div>
                        <!-- Year 1 -->
                        <div class="row">
                          <div class="ml-3">
                            <button *ngIf="hideInitialButton" mat-button class="buttonColor mb-2 mr-2 mt-1 ml-3"
                              [value]="previousYear" (click)="onChange21(previousYear)"> 2021
                            </button>
                          </div>
                          <div>
                            <button *ngIf="hideInitialButton" mat-button class="buttonColor mb-2 mr-2 mt-1 ml-3"
                              [value]="currentYear" (click)="onChange21(currentYear)"> 2022
                            </button>
                          </div>
                        </div>
                        <!-- Year 2 -->
                        <div class="row">
                          <div class="col-md-12  text-right">
                            <div>
                              <button *ngIf="hide2021YearButton2" mat-button class="buttonColor mb-2 mr-2 mt-1 ml-3">
                                2021
                              </button>
                            </div>
                            <div>
                              <button *ngIf="hide2022YearButton2" mat-button class="buttonColor mb-2 mr-2 mt-1 ml-3">
                                2022
                              </button>
                            </div>
                          </div>
                        </div>
                        <!-- Quarter 1 -->
                        <div class="row" *ngIf="hideQuarter1button">
                          <h3 class="customThemeClass ml-3 col-12">Select Quarter
                          </h3>
                          <div class="col-3">
                            <button mat-button class="buttonColor mb-2 mr-2 mt-1 ml-3" (click)="onChangeQuarter('Jan')">
                              Q1 -
                              Jan,Feb,Mar
                            </button>
                          </div>
                          <div class="col-3">
                            <button mat-button class="buttonColor mb-2 mr-2 mt-1 ml-3" (click)="onChangeQuarter('Apr')">
                              Q2 -
                              Apr,May,Jun
                            </button>
                          </div>
                        </div>

                        <div class="row" *ngIf="hideQuarter1button">
                          <div class="col-3">
                            <button mat-button class="buttonColor mb-2 mr-2 mt-1 ml-3"
                              (click)="onChangeQuarter('July')"> Q3 -
                              Jul,Aug,Sept
                            </button>
                          </div>
                          <div class="col-3">
                            <button mat-button class="buttonColor mb-2 mr-2 mt-1 ml-3" (click)="onChangeQuarter('Oct')">
                              Q4 -
                              Oct,Nov,Dec
                            </button>
                          </div>
                        </div>
                        <!-- Quarter 2 -->
                        <div *ngIf="hideQuarter2button">
                          <div class="row ">
                            <h3 *ngIf="selectQuarter" class="customThemeClass ml-2 col-12">Select Quarter
                            </h3>
                          </div>

                          <div class="row mr-2">
                            <div class="col-9">
                            </div>
                            <div class="col-3">
                              <button mat-button *ngIf="hideJanButton" class="buttonColor mb-2 mr-2 mt-1 ml-3"> Q1
                                -
                                Jan,Feb,Mar
                              </button>
                            </div>
                          </div>
                          <div class="row mr-2">
                            <div class="col-9">
                            </div>
                            <div class="col-3">
                              <button mat-button *ngIf="hideAprButton" class="buttonColor mb-2 mr-2 mt-1 ml-3"> Q2
                                -
                                Apr,May,Jun
                              </button>
                            </div>
                          </div>

                          <div class="row mr-2">
                            <div class="col-9">
                            </div>
                            <div class="col-3">
                              <button mat-button *ngIf="hideJulyButton" class="buttonColor mb-2 mr-2 mt-1 ml-3"> Q3
                                -
                                Jul,Aug,Sept
                              </button>
                            </div>
                          </div>
                          <div class="row mr-2">
                            <div class="col-9">
                            </div>
                            <div class="col-3">
                              <button mat-button *ngIf="hideOctButton" class="buttonColor mb-2 mr-2 mt-1 ml-3"> Q4
                                -
                                Oct,Nov,Dec
                              </button>
                            </div>
                          </div>
                        </div>
                        <!-- Details Loaded Button -->
                        <div class="row" *ngIf="detailsHide">
                          <div class="col-4">
                            <button mat-button class="mb-2 mr-2 mt-1">
                              Detials Will be loaded.......
                            </button>
                          </div>
                        </div>
                      </mat-expansion-panel>
                    </mat-accordion>
                  </div>
                </div>
                <div class="row mr-2" style="display:none;">
                  <div class="col-9">
                  </div>
                  <div class="col-3">
                    <button mat-button *ngIf="hideOctButton" class="buttonColor mb-2 mr-2 mt-1 ml-3"> Q1
                      -
                      Oct,Nov,Dec
                    </button>
                  </div>
                </div>
            </mat-card-content>
        </div>
    </div>
</mat-dialog-content>

<mat-dialog-actions align="end">
    <button class="buttonCancelColor" mat-button mat-dialog-close>Close</button>
    <!-- <button mat-button [mat-dialog-close]="true" cdkFocusInitial>Install</button> -->
</mat-dialog-actions>