<div class="container-fluid">
  <div class="row bg">
    <div class="col-12 mt-3">
      <div class="d-flex align-item-center justify-content-between mb-3">
        <h4 class="mt-4 mb-0 font-size-18"><b>Manufacturer </b></h4>
        <div class=" page-title-box  page-title-right mt-1 mb-0">
          <ol class="breadcrumbs m-0">
            <!-- (click)="exportTableData()" -->
            <button mat-button class="mr-2 buttonColor"
    [disabled]="(dataSource && dataSource?.data && dataSource?.data?.length === 0)"

            (click)="exportTableData()">
              Export
            </button>
            <li class="breadcrumb-item">Inventory</li>
            <li class="breadcrumb-item">Manufacturer</li>
          </ol>
        </div>
      </div>
    </div>
    </div>
    <div class="col-12" style="padding-right: 2px ;
    padding-left: 2px;">
      <div class="card card-body ">
      <mat-accordion>
        <mat-expansion-panel [expanded]="step">
          <mat-expansion-panel-header>
            <mat-panel-title class="customThemeClass">
              <ng-container *ngIf="vendorId === ''; else elseTitleTemplate">
                <b>Add Vendors</b>
              </ng-container>
              <ng-template #elseTitleTemplate>
                <b>Edit Vendors</b>
              </ng-template>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <form [formGroup]="vendorForm">
            <div class="col-lg-12">
              <mat-card-content>
                <div class="row">
                  <mat-form-field class="col-4">
                    <mat-label>Manufacturer  Code <span class="asterisk">*</span></mat-label>
                    <input autocomplete="new-txtVendorCode" matInput formControlName="txtVendorCode" placeholder="Enter Manufacturer  Code" type="text" />
                    <mat-error *ngIf="
                        vendorForm?.get('txtVendorCode')?.touched &&
                        vendorForm?.get('txtVendorCode')?.errors?.required
                      ">
                      Manufacturer  Code is a required field!
                    </mat-error>
                    <mat-error
                      *ngIf=" vendorForm?.get('txtVendorCode')?.touched && vendorForm?.get('txtVendorCode')?.errors?.sAlphabets">
                      Enter only Alphabets!
                    </mat-error>
                  </mat-form-field>
                  <mat-form-field class="col-4">
                    <mat-label>Manufacturer  Name <span class="asterisk">*</span></mat-label>
                    <input autocomplete="new-txtVendorName" matInput formControlName="txtVendorName" placeholder="Enter Manufacturer  Name" type="text"
                      pattern="[a-zA-Z0-9-_\s]*$" />
                    <mat-error *ngIf="
                        vendorForm?.get('txtVendorName')?.touched &&
                        vendorForm?.get('txtVendorName')?.errors?.required
                      ">
                      Manufacturer  Name is a required field!
                    </mat-error>
                    <mat-error
                      *ngIf="vendorForm?.get('txtVendorName')?.touched && vendorForm?.get('txtVendorName')?.errors">
                      Enter only Alphabets!
                    </mat-error>
                  </mat-form-field>

                  <mat-form-field class="col-4">
                    <mat-label>Manufacturer  Email <span class="asterisk">*</span></mat-label>
                    <input autocomplete="new-txtVendorEmailId" matInput formControlName="txtVendorEmailId" placeholder="Enter Manufacturer  Email" type="text"
                    pattern="[a-zA-Z0-9._!#$%&'()*+,-./:;<=>?@[\]^_`{|}~]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$"
                      [textMask]="{ mask: emailMask }" />
                    <mat-error *ngIf="
                        vendorForm?.get('txtVendorEmailId')?.touched &&
                        vendorForm?.get('txtVendorEmailId')?.errors?.required
                      ">
                      Manufacturer  Email is a required field!
                    </mat-error>
                    <mat-error
                      *ngIf=" vendorForm?.get('txtVendorEmailId')?.touched && vendorForm?.get('txtVendorEmailId')?.errors?.sAlphabets">
                      Enter only Alphabets!
                    </mat-error>
                  </mat-form-field>
                  <mat-form-field class="col-4">
                    <mat-label>Manufacturer  Phone Number<span class="asterisk">*</span></mat-label>
                    <input autocomplete="new-txtVendorPhoneNumber" matInput formControlName="txtVendorPhoneNumber" placeholder="Enter Phone Number" type="text"
                      mask="(000)-000-0000" />
                    <mat-error *ngIf="
                        vendorForm?.get('txtVendorPhoneNumber')?.touched &&
                        vendorForm?.get('txtVendorPhoneNumber')?.errors?.required
                      ">
                      Manufacturer  Phone Number is a required field!
                    </mat-error>
                    <mat-error
                      *ngIf=" vendorForm?.get('txtVendorPhoneNumber')?.touched && vendorForm?.get('txtVendorPhoneNumber')?.errors?.sAlphabets">
                      Enter only Numbers!
                    </mat-error>
                    <mat-error *ngIf="
                      vendorForm?.get('txtVendorPhoneNumber')?.touched &&
                      vendorForm?.get('txtVendorPhoneNumber')?.errors
                    ">
                      Enter Minimum 10 Digits!
                    </mat-error>

                  </mat-form-field>
                  <mat-form-field class="col-4">
                    <mat-label>Manufacturer  Address <span class="asterisk">*</span></mat-label>
                    <textarea autocomplete="new-txtVendorAddress" formControlName="txtVendorAddress" maxlength="400" rows="1" matInput></textarea>
                    <mat-error *ngIf="
                                  vendorForm?.get('txtVendorAddress')?.touched &&
                                  vendorForm?.get('txtVendorAddress')?.errors?.sAlphabets
                                ">
                      Enter only Alphabets!
                    </mat-error>
                    <mat-error *ngIf="
                      vendorForm?.get('txtVendorAddress')?.touched &&
                      vendorForm?.get('txtVendorAddress')?.errors?.required
                    ">
                      Manufacturer  Address is a required field!
                    </mat-error>
                  </mat-form-field>

                  <!-- newly added fields -->
                  <mat-form-field class="col-4">
                    <mat-label>Contact Person Name </mat-label>
                    <input autocomplete="new-txtPersonName" matInput formControlName="txtPersonName" placeholder="Enter Contact Person Name" type="text"
                      pattern="[a-zA-Z0-9-_\s]*$" />
                    <!-- <mat-error *ngIf="
                        vendorForm?.get('txtPersonName')?.touched &&
                        vendorForm?.get('txtPersonName')?.errors?.required
                      ">
                      Manufacturer  Name is a required field!
                    </mat-error>
                    <mat-error
                      *ngIf="vendorForm?.get('txtPersonName')?.touched && vendorForm?.get('txtPersonName')?.errors">
                      Enter only Alphabets!
                    </mat-error> -->
                  </mat-form-field>

                  <!--  country state city -->

                  <mat-form-field class="col-4">
                    <mat-label>Country</mat-label>
                    <mat-select formControlName="txtCountry" (selectionChange)='onChangeState($event.value)'
                      [(value)]="Country" #singleSelect>
                      <mat-option>
                        <ngx-mat-select-search placeholderLabel="Search" noEntriesFoundLabel="No Matches found"
                          formControlName="txtCountryFilter"></ngx-mat-select-search>
                      </mat-option>
                      <mat-option *ngFor="let country of filteredCountries | async" [value]="country.countryShortName">
                        {{ country.countryName }}
                      </mat-option>
                    </mat-select>
                    <button mat-button (click)="Country=undefined;$event.stopPropagation()" *ngIf="Country"
                      (click)="clearCountry()" matSuffix mat-icon-button >
                      <mat-icon>close</mat-icon>
                    </button>
                  </mat-form-field>

                  <mat-form-field class="col-4">
                    <mat-label>State</mat-label>
                    <mat-select formControlName="txtState" (selectionChange)='onChangeCity($event.value,"")'
                      [(value)]="State" #singleSelect>
                      <mat-option>
                        <ngx-mat-select-search placeholderLabel="Search" noEntriesFoundLabel="No Matches found"
                          formControlName="txtStateFilter"></ngx-mat-select-search>
                      </mat-option>
                      <mat-option *ngFor="let state of filteredStates | async" [value]="state.stateShortName">
                        {{ state.stateName }}
                      </mat-option>
                    </mat-select>
                    <button mat-button (click)="State=undefined;$event.stopPropagation()" *ngIf="State"
                      (click)="clearState()" matSuffix mat-icon-button >
                      <mat-icon>close</mat-icon>
                    </button>
                  </mat-form-field>


                  <mat-form-field class="col-4">
                    <mat-label>City</mat-label>
                    <mat-select formControlName="txtCity" [(value)]="City" #singleSelect>
                      <mat-option>
                        <ngx-mat-select-search placeholderLabel="Search" noEntriesFoundLabel="No Matches found"
                          formControlName="txtCityFilter"></ngx-mat-select-search>
                      </mat-option>
                      <mat-option *ngFor="let city of filteredCities | async" [value]="city.cityName">
                        {{ city.cityName }}
                      </mat-option>
                    </mat-select>
                    <button mat-button (click)="City='';$event.stopPropagation()" *ngIf="City" matSuffix
                      (click)="clearCity()" mat-icon-button >
                      <mat-icon>close</mat-icon>
                    </button>
                  </mat-form-field>

                  <!--  -->
                  <mat-form-field class="col-4">
                    <mat-label>Contact number</mat-label>
                    <input autocomplete="new-txtPersonPhoneNumber" matInput formControlName="txtPersonPhoneNumber" placeholder="Enter Phone Number" type="text"
                      mask="(000)-000-0000" prefix="{{phoneCode}}-" />
                    <mat-error *ngIf="
                        vendorForm?.get('txtPersonPhoneNumber')?.touched &&
                        vendorForm?.get('txtPersonPhoneNumber')?.errors?.required
                      ">
                      Contact number is a required field!
                    </mat-error>
                    <mat-error
                      *ngIf=" vendorForm?.get('txtPersonPhoneNumber')?.touched && vendorForm?.get('txtPersonPhoneNumber')?.errors?.sAlphabets">
                      Enter only Numbers!
                    </mat-error>
                    <mat-error *ngIf="
                      vendorForm?.get('txtPersonPhoneNumber')?.touched &&
                      vendorForm?.get('txtPersonPhoneNumber')?.errors
                    ">
                      Enter Minimum 10 Digits!
                    </mat-error>

                  </mat-form-field>

                  <!-- <mat-form-field [hidden]="true" class="col-4">
                    <mat-label>Qty<span class="asterisk">*</span></mat-label>
                    <input maxlength="40" formControlName="txtQty" matInput type="text" />
                    <mat-error *ngIf="
                    vendorForm?.get('txtQty')?.touched &&
                    vendorForm?.get('txtQty')?.errors?.required
                              ">
                      Price is a required field!
                    </mat-error>
                    <mat-error *ngIf="
                    vendorForm?.get('txtQty')?.touched &&
                    vendorForm?.get('txtQty')?.errors?.number
                  ">
                      Enter only Numbers!
                    </mat-error>
                  </mat-form-field> -->

                  <!-- <div class="col-4 mt-2">
                    <mat-checkbox formControlName="txtStatus">Active </mat-checkbox>
                  </div> -->
                </div>
              </mat-card-content>
            </div>
          </form>
          <br>
          <div class="col-sm-12">
            <div class="text-lg-center">
              <button mat-button class="buttonColor mb-2 mr-2"
                [disabled]="saveButtonHide||!(!vendorForm.invalid && vendorForm.dirty)" (click)="saveVendor()">
                Save
                <mat-icon *ngIf='saveButtonHide'>
                  <mat-spinner class="spinner-border spinner-border-sm" diameter="20"> </mat-spinner>
                </mat-icon>
              </button>
              <button mat-button class="resetclr buttonColor mb-2 mr-2" (click)="resetVendor()">
                Reset
              </button>
            </div>
          </div>
          <br />
        </mat-expansion-panel>
      </mat-accordion>
      </div>
    </div>

    <div class="borderSpace card card-body">
      <h4 class="customThemeClass ml-3 mt-2"><b>Manufacturer  List</b></h4>

      <div class="row ml-2">
      <div class="tabspace col-12 table-responsive">
        <!-- <div [hidden]="!isShowSpinner"
          style="display: flex; justify-content: center; align-items: center; background: transparent; color: lightseagreen;">
          <mat-progress-bar mode="indeterminate" value='66' diameter="45" class="lightseagreen">
          </mat-progress-bar>
        </div> -->
        <table mat-table class="table border" matSort [dataSource]="dataSource">
          <form style="display: flex" [formGroup]="vSearchForm">
            <ng-container matColumnDef="sno">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>S.No</th>
              <td mat-cell *matCellDef="let data; let i = index">
                {{ i+1 }}
              </td>
            </ng-container>
            <ng-container matColumnDef="vendorName">
              <th classs="header"  mat-sort-header mat-header-cell *matHeaderCellDef>
                <mat-form-field class="filter">
                  <input autocomplete="new-vName" matInput (keydown.space)="$event.stopPropagation()" (click)="$event.stopPropagation()" formControlName="vName" />
                  <mat-placeholder>Manufacturer  Name</mat-placeholder>
                </mat-form-field>
              </th>
              <td mat-cell *matCellDef="let data">
                <ng-container>
                  {{ data?.vendorName }}
                </ng-container>
              </td>
            </ng-container>
            <ng-container matColumnDef="vendorCode">
              <th  classs="header"  mat-sort-header mat-header-cell *matHeaderCellDef >
                <mat-form-field class="filter">
                  <input autocomplete="new-vcode" matInput (keydown.space)="$event.stopPropagation()" (click)="$event.stopPropagation()" formControlName="vcode" />
                  <mat-placeholder>Manufacturer  Code</mat-placeholder>
                </mat-form-field>
              </th>
              <td mat-cell *matCellDef="let data">
                <ng-container>
                  {{ data?.vendorCode }}
                </ng-container>
              </td>
            </ng-container>

            <ng-container matColumnDef="vPhNumber">
              <th classs="header"  mat-sort-header mat-header-cell *matHeaderCellDef >
                <mat-form-field class="filter">
                  <input autocomplete="new-vPersonNo" matInput (keydown.space)="$event.stopPropagation()" (click)="$event.stopPropagation()" formControlName="vPersonNo" />
                  <mat-placeholder>Contact Number</mat-placeholder>
                </mat-form-field>
              </th>
              <td mat-cell *matCellDef="let data">
                <ng-container>
                  {{ data?.contactNo }}
                </ng-container>
              </td>
            </ng-container>
            <ng-container matColumnDef="vQty">
              <th classs="header"  mat-sort-header mat-header-cell *matHeaderCellDef >
                <mat-form-field class="filter">
                  <input autocomplete="new-vQty" matInput (keydown.space)="$event.stopPropagation()" (click)="$event.stopPropagation()" formControlName="vQty" />
                  <mat-placeholder>Qty</mat-placeholder>
                </mat-form-field>
              </th>
              <td mat-cell *matCellDef="let data">
                <ng-container>
                  {{ data?.productQuantity }}
                </ng-container>
              </td>
            </ng-container>

            <ng-container matColumnDef="vAddress">
              <th classs="header"  mat-sort-header mat-header-cell *matHeaderCellDef >
                <mat-form-field class="filter">
                  <input autocomplete="new-vPname" matInput (keydown.space)="$event.stopPropagation()" (click)="$event.stopPropagation()" formControlName="vPname" />
                  <mat-placeholder>Contact Person Name</mat-placeholder>
                </mat-form-field>
              </th>
              <td mat-cell *matCellDef="let data">
                <ng-container>
                  {{ data?.contactPersonName }}
                </ng-container>
              </td>
            </ng-container>

            <ng-container matColumnDef="creationTime">
              <th classs="header"  mat-sort-header class="w-125" mat-header-cell *matHeaderCellDef>
                <mat-form-field class="filter">
                  <mat-label> Created Date </mat-label>
                  <input autocomplete="new-vCreatedOn" matInput (keydown.space)="$event.stopPropagation()" (click)="$event.stopPropagation()" formControlName="vCreatedOn" placeholder="(MM/DD/YYYY)" />
                  <mat-error
                    *ngIf=" vSearchForm?.get('vCreatedOn')?.errors?.dateVaidator">
                    Enter Valid date
                  </mat-error>
                </mat-form-field>
              </th>
              <td mat-cell *matCellDef="let element">{{ element?.creationTime | date: 'MM/dd/yyyy h:mm a':'en_US'}}
              </td>
            </ng-container>

            <ng-container matColumnDef="status">
              <th mat-header-cell *matHeaderCellDef >
                <mat-form-field class="filter">
                  <input autocomplete="new-vStatus" matInput  (keydown.space)="$event.stopPropagation()" (click)="$event.stopPropagation()"formControlName="vStatus" />
                  <mat-placeholder>Status</mat-placeholder>
                </mat-form-field>
              </th>
              <td mat-cell *matCellDef="let data">
                <ng-container *ngIf="data?.status==='Active'">
                  <mat-slide-toggle (change)="statusChange($event, data)" [checked]='true' class="ml-1">
                    {{data?.status}}</mat-slide-toggle>
                </ng-container>
                <ng-container *ngIf="data?.status==='Inactive'">
                  <mat-slide-toggle (change)="statusChange($event, data)" [checked]='false' class="ml-1">
                    {{data?.status}}</mat-slide-toggle>
                </ng-container>
              </td>
            </ng-container>



            <ng-container matColumnDef="Options">
              <th mat-header-cell *matHeaderCellDef >Options</th>
              <td mat-cell *matCellDef="let data">
                <a *ngIf="data?.status==='Active'" class="editBtnColor" (click)="ViewVendor(data?.id)">
                  <i class="fa fa-pencil editBtnColor mr-1" matTooltip="Click To Edit" ></i>
                </a>
                &nbsp;
                <a [hidden]="true" *ngIf="data?.status==='Active'" class="deleteBtnColor"
                  (click)="deleteVendor(data?.id)">
                  <i class="fa fa-trash-o" matTooltip="Click To Delete" ></i>
                </a>
              </td>
            </ng-container>
            <ng-container matColumnDef="noRecords">
              <td class="text-align-center" mat-footer-cell *matFooterCellDef colspan="9">
                <div *ngIf="!isShowSpinner">
                  {{"No Records Found"}}
                </div>
                <div [hidden]="!isShowSpinner" >
                  <mat-spinner [diameter]="220"></mat-spinner>
                </div>
              </td>
            </ng-container>
            <ng-container matColumnDef="paginator">
              <td mat-footer-cell *matFooterCellDef colspan="9">
                <mat-paginator class="table table-responsive mat-table-class" #MatPaginator
                  [length]="dataSource?.data?.length" [pageSize]="10" [pageIndex]="0" showFirstLastButtons
                  [pageSizeOptions]="[5, 10, 25, 100]">
                </mat-paginator>
              </td>
            </ng-container>
          </form>
          <tr mat-footer-row *matFooterRowDef="['noRecords']"
            [ngClass]="{ active: dataSource && dataSource?.data && dataSource?.data?.length !== 0 }"></tr>
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
          <tr mat-footer-row *matFooterRowDef="['paginator']"></tr>
        </table>
        </div>
        </div>
      </div>


</div>
