import type { GetRoleListUsingTenantIdDto, IdentityRoleCreateUsingTenantIdDto, RoleService_GetRoleListUsingTenantIdDto, RoleService_RoleDTO } from './models';
import { RestService } from '@abp/ng.core';
import type { ListResultDto } from '@abp/ng.core';
import { Injectable } from '@angular/core';
import type { IdentityRoleDto, IdentityRoleUpdateDto, RoleDto } from '../../../identity/models';

@Injectable({
  providedIn: 'root',
})
export class RoleService {
  apiName = 'platformManagement';

  createRole = (input: IdentityRoleCreateUsingTenantIdDto) =>
    this.restService.request<any, IdentityRoleDto>({
      method: 'POST',
      url: '/api/PlatformApp/role/create-role',
      body: input,
    },
     { apiName: this.apiName, skipHandleError: true });

  deleteRole = (tenantId: string, roleId: string) =>
    this.restService.request<any, void>({
      method: 'DELETE',
      url: '/api/PlatformApp/role/delete-role',
      params: { tenantId, roleId },
    },
     { apiName: this.apiName, skipHandleError: true });

  getRoleById = (tenantId: string, roleId: string) =>
    this.restService.request<any, IdentityRoleDto>({
      method: 'GET',
      url: '/api/PlatformApp/role/get-role-by-id',
      params: { tenantId, roleId },
    },
     { apiName: this.apiName, skipHandleError: true });

  // getRoleList = (input: GetRoleListUsingTenantIdDto) =>
  //   this.restService.request<any, ListResultDto<IdentityRoleDto>>({
  //     method: 'GET',
  //     url: '/api/PlatformApp/role/get-role-list',
  //     params: { tenantId: input.tenantId },
  //   },
  //   { apiName: this.apiName ,skipHandleError:true});

    getRoleList = (input: GetRoleListUsingTenantIdDto) =>
    this.restService.request<any, RoleDto[]>({
      method: 'GET',
      url: '/api/PlatformApp/role/get-role-list',
      params: { tenantId: input.tenantId },
    },
    { apiName: this.apiName ,skipHandleError:true});

    getRoleListV1 = (input: RoleService_GetRoleListUsingTenantIdDto) =>
    this.restService.request<any, IdentityRoleDto[]>({
      method: 'GET',
      url: '/api/PlatformApp/role/get-role-list',
      params: { tenantId: input.tenantId, status: input.status },
    },
    { apiName: this.apiName });

  updateRole = (tenantId: string, roleId: string, input: IdentityRoleUpdateDto) =>
    this.restService.request<any, IdentityRoleDto>({
      method: 'PUT',
      url: '/api/PlatformApp/role/update-role',
      params: { tenantId, roleId },
      body: input,
    },
     { apiName: this.apiName, skipHandleError: true });


     getRoleStatusFilter = (roleStatus:number) =>
     this.restService.request<any, RoleDto[]>({
       method: 'GET',
       url: '/api/PlatformApp/role/roles-by-status',
       params: { roleStatus },
     },
     { apiName: this.apiName ,skipHandleError:true});

     enableDisableStatusById = (Id: string) =>
    this.restService.request<any, string>({
      method: 'POST',
      responseType: 'text',
      url: `/api/PlatformApp/role/enable-disable-status/${Id}`,
    },
    { apiName: this.apiName });

    getActiveRolesByGTenantId = (gTenantId: string) =>
    this.restService.request<any, RoleService_RoleDTO[]>({
      method: 'GET',
      url: `/api/PlatformApp/role/get-active-roles/${gTenantId}`,
    },
    { apiName: this.apiName });

  constructor(private restService: RestService) {}
}
