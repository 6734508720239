<div class="card card-body">
    <div class="row bg">
        <div class="col-4">
            <mat-label><b class="customThemeClass custom-tab-header">Notes Info</b></mat-label>
        </div>
        <div class="col-8">
            <div class="d-flex align-items-center justify-content-between">
                <div class="page-title-box page-title-right">
                    <ol class="breadcrumb m-0">
                    </ol>
                </div>
                <button mat-button class="buttonColor font-size-10" (click)="openAddNotes()" [disabled]="ischeckPracticeId">
                    <i class="fa fa-plus mr-2"></i>Add
                </button>
            </div>
        </div>

        <div class="col-lg-12 mt-3">
            <!-- Loader Section -->
            <div class="row" *ngIf="isLoading">
                <div class="col-12">
                    <div class="d-flex justify-content-center" *ngIf="isLoading">
                        <img class="img-responsive pb-2 mr-1" src="assets/gifs/Infinity-loader.gif" />
                    </div>
                </div>
            </div>
            <div *ngIf="!isLoading" class="table table-responsive">
                <table id="tbl" datatable [dtOptions]="dtOptions" style="width: 100%" class="hover nowrap_th_td display">
                    <thead>
                        <tr>
                            <th><mat-icon>apps</mat-icon></th>
                            <th [matTooltip]="'Notes'">Notes</th>
                            <th [matTooltip]="'Created Date'" class="text-center">Created Date</th>
                            <th [matTooltip]="'User'">User</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let data of Physiciandetails">
                            <td>
                                <!-- <mat-icon style="cursor: pointer !important"
                                    [matMenuTriggerFor]="menu">more_vert</mat-icon>
                                <mat-menu class="custom-mat-menu" #menu="matMenu">
                                    <button mat-menu-item (click)="updateNotes(data.id,data.notes)">
                                        Edit Notes
                                    </button>
                                    <button mat-menu-item (click)="deletePracticeNotes(data.id)">
                                        Delete Notes
                                    </button>
                                </mat-menu> -->
                                <ng-container>
                                    <a class="editBtnColor" (click)="updateNotes(data.id,data.notes)"
                                        [ngClass]="{'disabled-link': data?.isEdit === 0}">
                                        <i class="fa fa-pencil mr-2 editBtnColor" matTooltip="Click To Edit"></i>
                                    </a>
                                </ng-container>
                                <ng-container>
                                    <a class="deleteBtnColor" (click)="deletePracticeNotes(data.id)"
                                    [ngClass]="{'disabled-link': data?.isEdit !== 1}">
                                        <i class="fa fa-trash mr-2" matTooltip="Delete"></i>
                                    </a>
                                </ng-container>
                            </td>
                            <td [matTooltip]="data.notes || '-'" (dblclick)="onRowDoubleClickV1(data?.notes || '-')">{{ data.notes || '-' }}</td>
                            <td [matTooltip]="getFormattedDateTime(data?.creationTime)"
                            (dblclick)="onRowDoubleClickV1(getFormattedDateTime(data?.creationTime))" class="text-center">{{ getFormattedDateTime(data?.creationTime)}}</td>
                            <td [matTooltip]="data.userName || '-'" (dblclick)="onRowDoubleClickV1(data?.userName || '-')">{{ data.userName || '-' }}</td>
                        </tr>
                    </tbody>

                </table>
            </div>
        </div>
    </div>
</div>