<div class="container-fluid">
    <div class="row">
        <div class="col-md-5 d-inline-flex align-items-center">
            <!-- <h2 class="mb-0"><b>Master / Template</b></h2> -->
            <h3 class="font-weight-bolder mb-2 mt-2">
                <b class="customThemeClass">Master / Template</b>
              </h3>
        </div>
    </div>

    <div class="card card-body">
        <div class="col-12">
            <div class="d-flex justify-content-between">
                <div class="page-title-box page-title-left pb-0">
                    <h3 class="font-weight-bolder mb-0">
                        <b class="header-class customTheme">Add Template</b>
                    </h3>
                </div>

                <!-- Browse Template Button -->
                <div class="page-title-box page-title-right pb-0">
                    <form [formGroup]="templateUploadForm">
                        <div class="d-flex align-items-center">
                            <button class="file-upload-button cursor" [disabled]="chkdisableButton">
                                <div class="row" style="padding-left: 40px;">
                                    <div class="brow-align mr-2">Browse</div>
                                    <mat-icon class="cursor">cloud_upload</mat-icon>
                                </div>
                                <input autocomplete="new-documentFile" id="file-upload" type="file"
                                    formControlName="documentFile" class="cursor" (change)="onFileChange($event)"
                                    [multiple]="true" #uploadFileReference accept="application/pdf"
                                    [disabled]="chkdisableButton" />
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
        <div class="col-12 mt-2">
            <mat-divider></mat-divider>
        </div>
        <div class="row">
            <div class="col-12">
                <div class="mr-auto">
                    <div class="d-flex align-items-center">
                        <span class="asterisk mr-2" *ngIf="showErrorForFileType">
                            {{ "Select Only PDF`s" }}
                        </span>

                        <span class="asterisk ml-2" *ngIf="showErrorBasedOnMaxSize">
                            {{ 'Max Total file`s size is 15MB or Less' }}
                        </span>
                    </div>
                </div>
            </div>
            <div class="col-12">
                <div class="pt-2" *ngIf="!isRefresh">
                    <!--Template Show Div -->
                    <div *ngIf="arrSelectedDocuments && arrSelectedDocuments?.length !== 0">
                        <div style="max-height: 300px; overflow-y: auto;">
                            <div class="row">
                                <ng-container *ngFor="let document of arrSelectedDocuments">
                                    <div class="col-3">
                                        <div class="custom-card">
                                            <div class="row">
                                                <div class="col-lg-10">
                                                    <div [matTooltip]="document.fileName" class="fileName nowrap_card">
                                                        {{
                                                        document.fileName }}.pdf
                                                    </div>
                                                    <div class="mb-0">
                                                        <p class="size_pagecount nowrap_card mb-0">{{
                                                            document.fileSize }} - {{ document.pageCount }}
                                                            Pages
                                                        </p>
                                                    </div>
                                                </div>
                                                <div class="col-lg-2 center">
                                                    <mat-icon class="deleteFileButton"
                                                        (click)="deleteDocument(document)">delete</mat-icon>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </ng-container>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-12 text-center mt-3">
            <button mat-button class="buttonColor mr-2" mat-raised-button
                [disabled]="templateUploadForm.invalid || chkdisableButton || !arrSelectedDocuments || arrSelectedDocuments?.length === 0"
                (click)="savefunction()">
                <span>Save</span>
                <mat-icon *ngIf="chkdisableButton">
                    <mat-spinner class="spinner-border-sm" diameter="20"></mat-spinner>
                </mat-icon>
            </button>
            <button mat-button [disabled]="chkdisableButton" class="resetclr mr-2"
                (click)="resetInboundDocumentForm()">Reset</button>
        </div>
    </div>

    <div class="card card-body mt-3">
        <div class="row" *ngIf="isLoading">
            <div class="col-12">
                <div class="d-flex justify-content-center" *ngIf="isLoading">
                    <img class="img-responsive pb-2 mr-1" src="assets/gifs/Infinity-loader.gif" />
                </div>
            </div>
        </div>
        <div class="col-12 mt-3" *ngIf="!isLoading">
            <app-template-creation-table [tableData]="templateDataList"></app-template-creation-table>
        </div>
    </div>
</div>