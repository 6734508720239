
  <div class="row">
    <div class="col-lg-12">
      <h2 class="customThemeClass">Invoice Payment Details</h2>
    </div>
    <div class="col-lg-4">
      <table>
        <tr>
          <td class="mt-2 text-right"><b>Name </b></td>
          <td>
            <b> : {{ Name }}</b>
          </td>
        </tr>
        <tr>
          <td class="text-right"><b>SaleOrder Id </b></td>
          <td>
            <b>: {{ saleOrderId }}</b>
          </td>
        </tr>
        <tr>
          <td class="mt-2 text-right"><b>DOB </b></td>
          <td>
            <b> : {{ dob }}</b>
          </td>
        </tr>
        <tr>
          <td class="mt-2 text-right"><b>Mail Resend Counts </b></td>
          <td>
            <b> : {{ payPalInvoiceMailResendCount }}</b>
          </td>
        </tr>
      </table>
    </div>
    <div class="col-lg-4">
      <table>
        <tr>
          <td class="mt-2 text-right"><b>Payment Completed Date </b></td>
          <td>
            <b> : {{ paymentCompletedDateTime | date: 'MM/dd/yyyy h:mm a':'en_US' }}</b>
          </td>
        </tr>
        <tr>
          <td class="mt-2 text-right"><b>Payment Created Date </b></td>
          <td>
            <b> : {{ paymentCreatedDateTime | date: 'MM/dd/yyyy h:mm a':'en_US' }}</b>
          </td>
        </tr>

        <tr>
          <td class="mt-2 text-right"><b>Invoice Number </b></td>
          <td>
            <b> : {{ invoiceNumber }}</b>
          </td>
        </tr>
        <tr>
          <td class="mt-2 text-right"><b>Invoice Id </b></td>
          <td>
            <b> : {{ invoiceOrderId }}</b>
          </td>
        </tr>
      </table>
    </div>
    <div class="col-lg-4">
      <table>
        <tr>
          <td class="mt-2 text-right"><b>Total Paid Amount </b></td>
          <td>
            <b> : $ {{ totalPaidAmount }}</b>
          </td>
        </tr>
        <tr>
          <td class="mt-2 text-right"><b>Paypal Transaction Amount </b></td>
          <td>
            <b> : $ {{ totalPaypalAmount }}</b>
          </td>
        </tr>
        <tr>
          <td class="mt-2 text-right"><b>Total Transferred Amount </b></td>
          <td>
            <b> : $ {{ totalTransferredAmount }}</b>
          </td>
        </tr>
      </table>
    </div>
    <div class="col-lg-12">
      <table class="table table-striped table-bordered">
        <thead>
          <tr>
            <th>Item Code</th>
            <th>Item Name</th>
            <th>Charge</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of items">
            <td>{{ item.code }}</td>
            <td>{{ item.itemName }}</td>
            <td>$ {{ item.amount }}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="col-12 text-center">
      <button class="buttonColor" *ngIf="showButton" (click)="paypalProceed()" mat-button>
        Proceed
      </button>
    </div>
  </div>


