import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SpinnerService {



  private _loading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public readonly loading$: Observable<boolean> = this._loading.asObservable();

  constructor() { }

  spinnerShow() {
    this._loading.next(true);
  }

  spinnerHide() {
    this._loading.next(false);
  }
}
