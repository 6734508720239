import type {
  CreateUpdateFTPUnitDTO,
  CreateUpdateFaxUnitDTO,
  CreateUpdateOrganizationUnitDTO,
  GetBranchDTO,
  OrganizationFaxDTO,
  OrganizationStatusDTO,
  OrganizationStatusDetailsDTO,
  OrganizationUnitDTO,
  SearchOrganizationUnitDTO,
} from './dto/models';
import { RestService } from '@abp/ng.core';
import type {
  PagedAndSortedResultRequestDto,
  PagedResultDto,
} from '@abp/ng.core';
import { Injectable } from '@angular/core';
import { AnyARecord } from 'dns';

@Injectable({
  providedIn: 'root',
})
export class OrganizationUnitService {
  apiName = 'platformManagement';

  create = (input: CreateUpdateOrganizationUnitDTO) =>
    this.restService.request<any, OrganizationUnitDTO>(
      {
        method: 'POST',
        url: '/api/PlatformApp/organization-unit',
        body: input,
      },
      { apiName: this.apiName, skipHandleError: true }
    );
  createFax = (input: CreateUpdateFaxUnitDTO) =>
    this.restService.request<any, OrganizationFaxDTO>(
      {
        method: 'POST',
        url: '/api/PlatformApp/fax-mail',
        body: input,
      },
      { apiName: this.apiName, skipHandleError: true }
    );

  createFtp = (input: CreateUpdateFTPUnitDTO) =>
    this.restService.request<any, CreateUpdateFTPUnitDTO>(
      {
        method: 'POST',
        url: '/api/PlatformApp/f-tPConfiguration',
        body: input,
      },
      { apiName: this.apiName, skipHandleError: true }
    );

  delete = (id: string) =>
    this.restService.request<any, void>(
      {
        method: 'DELETE',
        url: `/api/PlatformApp/organization-unit/${id}`,
      },
      { apiName: this.apiName, skipHandleError: true }
    );

  get = (id: string) =>
    this.restService.request<any, OrganizationUnitDTO>(
      {
        method: 'GET',
        url: `/api/PlatformApp/organization-unit/${id}`,
      },
      { apiName: this.apiName, skipHandleError: true }
    );

  getFax = (id: string) =>
    this.restService.request<any, OrganizationFaxDTO>(
      {
        method: 'GET',
        url: `/api/PlatformApp/fax-mail/${id}`,
      },
      { apiName: this.apiName, skipHandleError: true }
    );

  getFtp = (id: string) =>
    this.restService.request<any, CreateUpdateFTPUnitDTO>(
      {
        method: 'GET',
        url: `/api/PlatformApp/f-tPConfiguration/${id}`,
      },
      { apiName: this.apiName, skipHandleError: true }
    );

  getList = (input: PagedAndSortedResultRequestDto) =>
    this.restService.request<any, PagedResultDto<OrganizationUnitDTO>>(
      {
        method: 'GET',
        url: '/api/PlatformApp/organization-unit',
        params: {
          skipCount: input.skipCount,
          maxResultCount: input.maxResultCount,
          sorting: input.sorting,
        },
      },
      { apiName: this.apiName, skipHandleError: true }
    );

  update = (id: string, input: CreateUpdateOrganizationUnitDTO) =>
    this.restService.request<any, OrganizationUnitDTO>(
      {
        method: 'PUT',
        url: `/api/PlatformApp/organization-unit/${id}`,
        body: input,
      },
      { apiName: this.apiName, skipHandleError: true }
    );
  updateFax = (id: string, input: CreateUpdateFaxUnitDTO) =>
    this.restService.request<any, OrganizationFaxDTO>(
      {
        method: 'PUT',
        url: `/api/PlatformApp/fax-mail/${id}`,
        body: input,
      },
      { apiName: this.apiName, skipHandleError: true }
    );
  updateFtp = (id: string, input: CreateUpdateFTPUnitDTO) =>
    this.restService.request<any, CreateUpdateFTPUnitDTO>(
      {
        method: 'PUT',
        url: `/api/PlatformApp/f-tPConfiguration/${id}`,
        body: input,
      },
      { apiName: this.apiName, skipHandleError: true }
    );

  updateBranchEnableDisable = (organizationUnitId: string) =>
    this.restService.request<any, string>(
      {
        method: 'POST',
        responseType: 'text',
        url: `/api/PlatformApp/organization-unit/enable-disable-status/${organizationUnitId}`,
        params: { organizationUnitId },
      },
      { apiName: this.apiName, skipHandleError: true }
    );

  updateFaxEnableDisable = (Id: string) =>
    this.restService.request<any, string>(
      {
        method: 'POST',
        responseType: 'text',
        url: `/api/PlatformApp/fax-mail/enable-disable-status/${Id}`,
        params: { Id },
      },
      { apiName: this.apiName, skipHandleError: true }
    );

  updateFtpEnableDisable = (Id: string) =>
    this.restService.request<any, string>(
      {
        method: 'POST',
        responseType: 'text',
        url: `/api/PlatformApp/f-tPConfiguration/enable-disable-status/${Id}`,
        params: { Id },
      },
      { apiName: this.apiName, skipHandleError: true }
    );

  deleteOrganization = (id: string) =>
    this.restService.request<any, void>(
      {
        method: 'DELETE',
        url: `/api/PlatformApp/organization-unit/${id}`,
        params: { id },
      },
      { apiName: this.apiName, skipHandleError: true }
    );

  deleteFax = (id: string) =>
    this.restService.request<any, void>(
      {
        method: 'DELETE',
        url: `/api/PlatformApp/fax-mail/${id}`,
      },
      { apiName: this.apiName, skipHandleError: true }
    );

  deleteFtp = (id: string) =>
    this.restService.request<any, void>(
      {
        method: 'DELETE',
        url: `/api/PlatformApp/f-tPConfiguration/${id}`,
      },
      { apiName: this.apiName, skipHandleError: true }
    );

  findOrganizationByEmail = (sEmail: string) =>
    this.restService.request<any, string>(
      {
        method: 'POST',
        responseType: 'text',
        url: '/api/PlatformApp/organization-unit/find-organization-by-email',
        params: { sEmail },
      },
      { apiName: this.apiName, skipHandleError: true }
    );

  findOrganizationByName = (sOrganizationUnitName: string) =>
    this.restService.request<any, string>(
      {
        method: 'POST',
        responseType: 'text',
        url: '/api/PlatformApp/organization-unit/find-organization-by-name',
        params: { sOrganizationUnitName },
      },
      { apiName: this.apiName, skipHandleError: true }
    );

  getOrganizationById = (organizationUnitId: string, tenantId: string) =>
    this.restService.request<any, OrganizationUnitDTO>(
      {
        method: 'GET',
        url: '/api/PlatformApp/organization-unit/get-organization-by-id',
        params: { organizationUnitId, tenantId },
      },
      { apiName: this.apiName, skipHandleError: true }
    );

  getOrganizationList = (tenantId: string) =>
    this.restService.request<any, OrganizationUnitDTO[]>(
      {
        method: 'GET',
        url: `/api/PlatformApp/organization-unit/get-organization-list/${tenantId}`,
      },
      { apiName: this.apiName, skipHandleError: true }
    );

    getOrganizationListV1 = (tenantId: string, isFaxqueue: boolean) =>
      this.restService.request<any, OrganizationUnitDTO[]>({
        method: 'GET',
        url: `/api/PlatformApp/organization-unit/get-organization-list/${tenantId}`,
        params: { isFaxqueue },
      },
      { apiName: this.apiName });

  searchOrganizationByInput = (input: SearchOrganizationUnitDTO) =>
    this.restService.request<any, PagedResultDto<OrganizationUnitDTO>>(
      {
        method: 'GET',
        url: '/api/PlatformApp/organization-unit/search-organization',
        params: {
          organizationUnitName: input.organizationUnitName,
          emailAddress: input.emailAddress,
          address: input.address,
          phoneNumber: input.phoneNumber,
          createdDate: input.createdDate,
          status: input.status,
          officeType: input.officeType,
          tenantId: input.tenantId,
          faxNumber: input.faxNumber,
        },
      },
      { apiName: this.apiName, skipHandleError: true }
    );

  getBranchList = (tenantId: string) =>
    this.restService.request<any, OrganizationUnitDTO[]>(
      {
        method: 'GET',
        url: `/api/PlatformApp/organization-unit/get-branch-list/${tenantId}`,
      },
      { apiName: this.apiName, skipHandleError: true }
    );

    getBranchListV1 = (tenantId: string, isFaxqueue: boolean) =>
      this.restService.request<any, OrganizationUnitDTO[]>({
        method: 'GET',
        url: `/api/PlatformApp/organization-unit/get-branch-list/${tenantId}`,
        params: { isFaxqueue },
      },
      { apiName: this.apiName });

    getBranchListBasedOnSchedule = (tenantId: string) =>
      this.restService.request<any, OrganizationUnitDTO[]>({
        method: 'GET',
        url: `/api/PlatformApp/organization-unit/get-branch-list-based-on-schedule/${tenantId}`,
      },
      { apiName: this.apiName });

  getBranchWithStatus = () =>
    this.restService.request<any, OrganizationStatusDTO>(
      {
        method: 'GET',
        url: '/api/PlatformApp/organization-unit/get-branch-with-status',
      },
      { apiName: this.apiName }
    );

  getBranchWithStatusByIAverage = (iAverage: number) =>
    this.restService.request<any, OrganizationStatusDTO>(
      {
        method: 'GET',
        url: '/api/PlatformApp/organization-unit/get-branch-with-status',
        params: { iAverage },
      },
      { apiName: this.apiName }
    );

  getbranchStatusFilter = (statusFilter: number, gTenant: string) =>
    this.restService.request<any, PagedResultDto<OrganizationUnitDTO>>(
      {
        method: 'GET',
        url: `/api/PlatformApp/organization-unit/organization-unit-list`,
        params: { statusFilter, gTenant },
      },
      { apiName: this.apiName }
    );

  getFaxStatusFilter = (statusFilter: number, gTenant: string) =>
    this.restService.request<any, OrganizationFaxDTO>(
      {
        method: 'GET',
        url: `/api/PlatformApp/fax-mail/status-based-fax-mail-list`,
        params: { statusFilter, gTenant },
      },
      { apiName: this.apiName }
    );
  getFTPStatusFilter = (statusFilter: number, gTenant: string) =>
    this.restService.request<any, CreateUpdateFTPUnitDTO>(
      {
        method: 'GET',
        url: `/api/PlatformApp/f-tPConfiguration/status-based-fTPList`,
        params: { statusFilter, gTenant },
      },
      { apiName: this.apiName }
    );
  // getBranchListWithStatus = () =>
  //   this.restService.request<any, OrganizationStatusDetailsDTO>(
  //     {
  //       method: 'GET',
  //       url: `/api/PlatformApp/organization-unit/get-branch-list-with-status`,
  //     },
  //     { apiName: this.apiName, skipHandleError: true }
  //   );
  getBranchWithStatusV2 = () =>
    this.restService.request<any, OrganizationStatusDTO>(
      {
        method: 'GET',
        url: '/api/PlatformApp/organization-unit/get-branch-with-status-v2',
      },
      { apiName: this.apiName }
    );

    getBranchForMappedUser = () =>
      this.restService.request<any, string[]>({
        method: 'GET',
        url: '/api/PlatformApp/organization-unit/get-branch-for-mapped-user',
      },
      { apiName: this.apiName });

      getBranchListByNameAndSBranch = (Name: string, sBranch: string[]) =>
        this.restService.request<any, GetBranchDTO[]>({
          method: 'POST',
          url: '/api/PlatformApp/organization-unit/get-branch-list',
          params: { name: Name },
          body: sBranch,
        },
        { apiName: this.apiName });
  constructor(private restService: RestService) {}
}
