import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { DataTableDirective } from 'angular-datatables';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Observable, Subscription } from 'rxjs';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { PatientManualVerificationTabComponent } from '../patient-manual-verification-tab/patient-manual-verification-tab.component';
@Component({
  selector: 'app-patient-insurance-history-table',
  templateUrl: './patient-insurance-history-table.component.html',
  styleUrls: ['./patient-insurance-history-table.component.scss']
})
export class PatientInsuranceHistoryTableComponent implements OnInit {
  @Input() tableData: any;
  @ViewChild(DataTableDirective, { static: false })
  datatableElement: DataTableDirective;
  $subscription: Subscription[] = [];
  subscription$: Subscription[] = [];
  tableData$: Subscription;
  insuranceHistorydata: any;
  dtInsuranceHistoryTableOptions = {
    responsive: true,
    paging: false,
    dom: 'Bfrtip',
    language: {
      emptyTable: 'No records',
      info: 'Total : _MAX_ records',
      infoEmpty: 'Total : _MAX_ records',
      infoFiltered: '(filtered : _TOTAL_ records)',
      loadingRecords: 'Loading...',
      zeroRecords: 'No matching records',
    },
    scrollCollapse: true,
    scrollY: '400px',
    scrollX: true,
    fixedHeader: true,
    columnDefs: [
      {
        targets: [5,6],
        type: 'date',
        render: function (data, type, row) {
          // Assuming data is in the format MM/DD/YYYY
          if (type === 'sort' || type === 'type') {
            var dateParts = data.split('/');
            return new Date(dateParts[2], dateParts[0] - 1, dateParts[1]);
          } else {
            return data;
          }
        },
      },
    ],
    search: {
      smart: false, // Set to "none" for full exact match
    },
    buttons: [
      {
        extend: 'excel',
        text:'Export',
        filename: 'Insurance History',
      }
      // {
      //   extend: 'colvis',
      //   columns: ':not(:eq(0), :eq(1), :eq(2), :eq(-1))',
      // }
    ],
  };
  constructor(
    private snackBar: MatSnackBar,
    public dialog: MatDialog,
  ) { }

  ngOnInit(): void {
    this.insuranceHistorydata = this.tableData;
  }

  ngAfterViewInit(): void {
    if (this.datatableElement && this.datatableElement.dtInstance) {
      this.refreshDataTable();
    } else {
      setTimeout(() => this.refreshDataTable(), 500);
    }
  }


  refreshDataTable(): void {
    this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.columns().every(function () {
        const that = this;
        $('input', this.footer()).on('keyup change', function () {
          if (that.search() !== this['value']) {
            that.search(this['value']).draw();
          }
        });
      });
    });
  }

  ngOnDestroy(): void {
    if (this.$subscription) {
      this.$subscription.forEach((subscription) => {
        subscription.unsubscribe();
      });
    }
    this.tableData$?.unsubscribe();
  }

  onRowDoubleClickV1(textToCopy) {
    this.copyTextToClipboard(textToCopy);
    this.showNotification('Text copied: ' + textToCopy);
  }

  showNotification(message: string): void {
    this.snackBar.open(message, 'Close', {
      duration: 3000, // Duration in milliseconds
      horizontalPosition: 'end', // Positioning the notification horizontally
      verticalPosition: 'bottom', // Positioning the notification vertically
    });
  }

  copyTextToClipboard(text: string): void {
    const textArea = document.createElement('textarea');
    textArea.value = text;
    document.body.appendChild(textArea);
    textArea.select();
    document.execCommand('Copy');
    textArea.remove();
  }

  // getFormattedDate(date: any): string {
  //   return date
  //     ? new Date(date).toLocaleString('en-US', {
  //       month: '2-digit',
  //       day: '2-digit',
  //       year: 'numeric',
  //       hour: 'numeric',
  //       minute: 'numeric',
  //       hour12: true,
  //     })
  //     : '-';
  // }

  getFormattedDate(date: string): string {
    return date ? new Date(date).toLocaleDateString('en-US') : '-';
  }
  //View Verification
  viewVerificationById(patentID: string, verificatnId: string, verificationStus: string): void {
    const dialogRef = this.dialog.open(PatientManualVerificationTabComponent, {
      data: { patientId: patentID, verificationId: verificatnId, savehidden: false, verificationStatus: verificationStus,isNewInsurance:true}
    });
    const closeDialog = dialogRef.afterClosed().subscribe((result) => {
      // this.reloadTableEvent.emit(this.patientId);
      dialogRef?.close(result);
    });
    this.subscription$.push(closeDialog);
  }

}
