import {
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { HttpErrorResponse } from '@angular/common/http';
import Swal from 'sweetalert2';
import { MatSelect, MatSelectChange } from '@angular/material/select';
import { MatOption } from '@angular/material/core';
import { Observable, Subscription } from 'rxjs';
import { OrganizationUnitService } from 'projects/admin/src/app/admin-proxy/platform-app-management/rcm/platform-management/organization-units';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { map, startWith } from 'rxjs/operators';
import { InboundListDTO } from 'projects/admin/src/app/admin-proxy/platform-app-management/rcm/platform-management/inbound/dto/models';
import { PatientCommonNotesComponent } from 'projects/shared/src/app/components/patient-common-notes/patient-common-notes.component';
import { PatientDTO } from 'projects/admin/src/app/warehouse-proxy/rcm/patient-management/patient/dto';
import { Title } from '@angular/platform-browser';
import { PatientNotesService } from '../patient-proxy/patient-optimization/patient-notes.service';
import { PatientVerificationService } from '../patient-proxy/patient-optimization/patient-verification.service';
import { NewVerificationComponent } from '../newVerification/newVerification.component';
import { defaultGuid } from 'projects/shared/src/app/enums/allenums.enum';
import { CommonService } from 'projects/shared/src/app/services/common.service';
@Component({
  selector: 'app-myverfication-v1',
  templateUrl: './myverfication-v1.component.html',
  styleUrls: ['./myverfication-v1.component.scss'],
})
export class MyverficationV1Component implements OnInit {
  @HostBinding('style.font') font =
    '400 13px/20px Roboto, "Helvetica Neue", sans-serif';
  @HostBinding('style.letter-spacing') letterSpacing = 'normal';
  defaultGuid = defaultGuid;
  chartNo: string;
  patientId: string;
  selectedPatient: boolean = false;
  isNewPatient: boolean = false;
  isShowSpinner: boolean;
  isLoading: boolean;
  dataSource: MatTableDataSource<any>;
  dtvOptions: any;
  tenantId: string = '';
  statusId: string;
  policyId: string;
  docId: string;
  public drpBranch: Branch[] = [];
  branchForm: FormGroup;
  organizationUnitName: string;
  myverification: InboundListDTO[] = [];
  BranchSelectAllOption = false;
  myTable: DataTables.Api;
  $subscription: Subscription[] = [];
  tableData$: Subscription;
  @ViewChild('selectBranch') selectBranch: MatSelect;
  @ViewChild('selectAllBranch') private selectAllBranch: MatOption;
  selectedBranchList: string[] = null;
  @Input() selectedNode: any;
  @Output() patientIdEmitter: EventEmitter<PatientDTO> =
    new EventEmitter<PatientDTO>();
  @Output() patientIdEmitters: EventEmitter<string> =
    new EventEmitter<string>();
  selectedOption: number;
  wipTableData: any;
  public brnachList: Observable<Branch[]> | undefined;
  subscription$: Subscription[] = [];
  tblSelectedFaxId: string;
  selectedRow: string;
  constructor(
    public dialog: MatDialog,
    private branchService: OrganizationUnitService,
    private formBuilder: FormBuilder,
    public PatientNotesService: PatientNotesService,
    public PatientVerificationService: PatientVerificationService,
    private title: Title,
    private commonService : CommonService
  ) {}

  ngOnInit(): void {
    this.title.setTitle('Qsecure | My Verification');

    this.selectedOption = 1;
    this.isShowSpinner = false;

    this.tenantId = localStorage.getItem('tenantId') ?? '';
    this.branchForm = this.formBuilder.group({
      drpBranch: new FormControl(''),
      txtBranch: new FormControl(''),
    });

    this.getBranchList();
    this.getTableData([]);
    // this.GetStatusFilterList();
  }

  // ngAfterViewInit(): void {
  //   this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
  //     dtInstance.columns().every(function () {
  //       const that = this;
  //       $('input', that.footer()).on('input', function () {

  //         const inputValue = (this as HTMLInputElement).value;
  //         if (that.search() !== inputValue) {
  //           that.search(inputValue).draw();
  //         }
  //       });
  //     });
  //   });
  // }

  openNewVerification() {
    const config: MatDialogConfig = {
      disableClose: true,
      data: {
        patientId: this.patientId,
        isOnline: false,
      },
    };
    const dialogRef = this.dialog.open(NewVerificationComponent, config);

    const closeDialog = dialogRef.afterClosed().subscribe(
      () => {
        // this.getReminderTableData();
      },
      (err) => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      }
    );
    this.subscription$.push(closeDialog);
  }

  ngOnDestroy(): void {
    this.tableData$?.unsubscribe();
    if (this.$subscription) {
      // Iterate through each subscription and unsubscribe
      this.$subscription.forEach((subscription) => {
        subscription.unsubscribe();
      });
    }
  }

  //To View The Policy
  // GetStatusFilterList() {
  //   this.InboundDocumentService.getStatusFilterList(this.selectedOption)
  //     .subscribe(
  //       (response) => {
  //         this.wipTableData = response;
  //         const verificationObject = this.wipTableData.find(obj => obj.inboundStatus === "Verification");
  //         if (verificationObject) {
  //           this.statusId = verificationObject.id;
  //           this.getTableData([]);
  //         }

  //       },
  //       (err) => {
  //         this.wipTableData = [];
  //         const data: HttpErrorResponse = err;
  //         Swal.fire({
  //           icon: 'info',
  //           text: data?.error?.error?.message,
  //         });
  //       }
  //     );
  // }

  allBranchSelect() {
    if (this.selectAllBranch.selected) {
      this.selectBranch.options.forEach((item: MatOption) => item.select());
    } else {
      this.selectBranch.options.forEach((item: MatOption) => item.deselect());
    }
  }

  getBranchList() {
    const getBranchList = this.branchService
      .getBranchList(this.tenantId)
      .subscribe(
        (response) => {
          response?.forEach((element) => {
            this.drpBranch.push({
              id: element.id,
              organizationUnitName: element.organizationUnitName,
            });
          });

          this.brnachList = this.branchForm
            ?.get('txtBranch')
            ?.valueChanges?.pipe(
              startWith(''),
              map((value) =>
                this.drpBranch?.filter((option) =>
                  option?.organizationUnitName
                    ?.toLowerCase()
                    ?.includes(value?.toLowerCase() ?? '')
                )
              )
            );
        },
        (err) => {
          const data: HttpErrorResponse = err;
          Swal.fire({
            icon: 'info',
            text: data?.error?.error?.message,
          });
        }
      );
    this.subscription$.push(getBranchList);
  }

  getTableData(ltBranch: string[]) {
    this.isLoading = true;
    try {
      this.tableData$?.unsubscribe();
      const getTableDataMyVer =
        this.PatientVerificationService.getMYVerificationStatusByStatusIDAndLtBranch(
          ltBranch
        ).subscribe(
          (response) => {
            this.isLoading = false;
            this.myverification = response;

            this.myverification = this.myverification.map((verificationdata) => {
              return {
                ...verificationdata,
                enteredOn: this.commonService.getFormattedDateTimeZone(
                  verificationdata.enteredOn
                ),
                createdDate: this.commonService.getFormattedDateTimeZone(
                  verificationdata.createdDate
                ),
                modifiedDate: this.commonService.getFormattedDateTimeZone(
                  verificationdata.modifiedDate
                ),
              };
            });
          },
          (err) => {
            this.isLoading = false;
            const data: HttpErrorResponse = err;
            Swal.fire({
              icon: 'info',
              text: data?.error?.error?.message,
            });
          }
        );
      this.tableData$ = getTableDataMyVer;
    } catch (error) {
      console.error('Unexpected error:', error);
    } finally {
    }
  }

  // onBranchSelectionChange(event: MatSelectChange): void {
  //   this.selectedBranchList = event.value;
  // }

  onBranchSelectionChange(event: MatSelectChange): void {
    this.selectedBranchList = event.value;
    if (this.selectedBranchList.includes('selectAll')) {
      // this.BranchSelectAllOption = !this.BranchSelectAllOption;
      this.BranchSelectAllOption = true;
      if (this.BranchSelectAllOption) {
        // If 'Select All' is selected, set the selectedBranchList to include all branch IDs
        this.selectedBranchList = this.drpBranch.map((branch) => branch.id);
        this.branchForm.get('drpBranch').patchValue(this.selectedBranchList);
      }
    } else if (this.selectedBranchList.includes('DeselectAll')) {
      this.selectedBranchList = [];
      this.BranchSelectAllOption = false;
      this.branchForm.get('drpBranch').patchValue([]);
    } else {
      this.BranchSelectAllOption =
        this.selectedBranchList.length === this.drpBranch.length;
    }

    this.getTableData(this.selectedBranchList);
  }

  openViewNotesPopup(patientId: string, defaultFaxId: string, docId: string) {
    const config: MatDialogConfig = {
      disableClose: true,
      width: '70vw',
      maxWidth: '70vw',
      height: '40vw',
      maxHeight: '40vw',
      data: {
        patientId: patientId,
        pageType: 'Patient Note', // this.pageType, //Notes Table Popup Page
        isAddVisible: false,
        defaultFaxId: defaultFaxId,
        docId: docId,
        chartNo: this.chartNo,
      },
    };
    const dialogRef = this.dialog.open(PatientCommonNotesComponent, config);

    dialogRef.afterClosed().subscribe(
      () => {
        //this.getReminderTableData();
      },
      (err: HttpErrorResponse) => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      }
    );
  }
}
export interface Branch {
  id?: string;
  organizationUnitName?: string;
}
