import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DataTableDirective } from 'angular-datatables';

@Component({
  selector: 'app-practice-view-patient-list-table',
  templateUrl: './practice-view-patient-list-table.component.html',
  styleUrls: ['./practice-view-patient-list-table.component.scss']
})
export class PracticeViewPatientListTableComponent implements OnInit {

  @Input() patinetTableData:any;
  patienttbldata:any;

  @ViewChild(DataTableDirective, { static: false })
  datatableElement: DataTableDirective;

  dtViewPatientList:any = {
    responsive: true,
    paging: false,
    dom: 'Bfrtip',
    order:[],
    language: {
      emptyTable: 'No records',
      info: 'Total : _MAX_ records',
      infoEmpty: 'Total : _MAX_ records',
      infoFiltered: '(filtered : _TOTAL_ records)',
      loadingRecords: 'Loading...',
      zeroRecords: 'No matching records',
    },
    scrollCollapse: true,
    scrollY: '380px',
    scrollX: true,
    fixedHeader: true,
    columnDefs: [
      // { targets: [0, 1], orderable: false },
      {
        targets: [3],
        type: 'date',
        render: function (data, type, row) {
          if (type === 'sort' || type === 'type') {
            var dateParts = data.split('/');
            return new Date(dateParts[2], dateParts[0] - 1, dateParts[1]);
          } else {
            return data;
          }
        },
      },
    ],
    search: {
      smart: false,
    },
    buttons: [
      {
        extend: 'excel',
        text:'Export',
        filename: 'Patient List',
      }
    ],
  };

  constructor(
    private snackBar: MatSnackBar
  ) { }

  ngOnInit(): void {
    this.patienttbldata=this.patinetTableData;
  }

  ngAfterViewInit(): void {
    if (this.datatableElement && this.datatableElement.dtInstance) {
      this.refreshDataTable();
    } else {
      setTimeout(() => this.refreshDataTable(), 500);
    }
  }


  refreshDataTable(): void {
    this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.columns().every(function () {
        const that = this;
        $('input', this.footer()).on('keyup change', function () {
          if (that.search() !== this['value']) {
            that.search(this['value']).draw();
          }
        });
      });
    });
  }

    //Copy Text When Double click On Row
    onRowDoubleClick(textToCopy) {
      this.copyTextToClipboard(textToCopy);
      this.showNotification('Text copied: ' + textToCopy);
    }
  
    showNotification(message: string): void {
      this.snackBar.open(message, 'Close', {
        duration: 3000, // Duration in milliseconds
        horizontalPosition: 'end', // Positioning the notification horizontally
        verticalPosition: 'bottom', // Positioning the notification vertically
      });
    }
  
    copyTextToClipboard(text: string): void {
      const textArea = document.createElement('textarea');
      textArea.value = text;
      document.body.appendChild(textArea);
      textArea.select();
      document.execCommand('Copy');
      textArea.remove();
    }

    getFormattedDate(date: string): string {
      return date ? new Date(date).toLocaleDateString('en-US') : '-';
    }

}
