<mat-dialog-content class="mat-typography">
    <div class="modal-header">
      <h1 class="modal-title customThemeClass">View Document
      </h1>

      <div class="page-title-box page-title-right">
            <button mat-dialog-close mat-icon-button>
                <mat-icon>close</mat-icon>
            </button>
    </div>


    </div>

    <div class="modal-body p-0">

      <div class="pt-2">
        <div class="pdf-container pb-4" *ngIf="isPdfLoad">
            <ng-container>
                <ngx-extended-pdf-viewer [base64Src]="data.base64" [height]="pdfheight"
                    [showSidebarButton]="false" [textLayer]="true"
                    [showPagingButtons]="true" [showOpenFileButton]="false" [showHandToolButton]="false"
                    [showScrollingButton]="false" [showSpreadButton]="false" [showPropertiesButton]="false"
                    [showBookmarkButton]="false" [zoom]="'page-width'" [enableDragAndDrop]="false" [showFindButton]="false">
                </ngx-extended-pdf-viewer>
            </ng-container>
        </div>


      </div>
    </div>
    <!-- </form> -->
  </mat-dialog-content>
