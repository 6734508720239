import { CoreModule } from '@abp/ng.core';
import { registerLocale } from '@abp/ng.core/locale';
import { NgxMatDatetimePickerModule, NgxMatNativeDateModule, NgxMatTimepickerModule } from '@angular-material-components/datetime-picker';
import { CommonModule, HashLocationStrategy, LocationStrategy } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatNativeDateModule, MatOptionModule, MatPseudoCheckboxModule, MatRippleModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { NgbModule, NgbNavModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxsFormPluginModule } from '@ngxs/form-plugin';
import { NgxsModule } from '@ngxs/store';
// import { BackButtonDisableModule } from 'angular-disable-browser-back-button';
import { OAuthModule } from 'angular-oauth2-oidc';
import { TextMaskModule } from 'angular2-text-mask';
import { NgxMaskModule } from 'ngx-mask';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { HorizontolModule } from 'projects/layout/src/app/app.module';
import { OrderModule } from 'projects/order/src/app/app.module';
import { orderState } from 'projects/order/src/app/order-store/order.state';
import { environment } from 'projects/patient/src/environments/environment';
import { SharedModule } from 'projects/shared/src/app/shared.module';
import { MatMenuModule } from '@angular/material/menu';


import { ShippingRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CashPaymentViewComponent } from './cash-payment-view/cash-payment-view.component';
import { ShippingApprovalListComponent } from './shipping-approval-list/shipping-approval-list.component';
import { ShippingAuthComponent } from './shipping-auth/shipping-auth.component';
// import { ShippingCreateEditComponent } from './shipping-create-edit/shipping-create-edit.component';
import { ShippingHistoryComponent } from './shipping-history/shipping-history.component';
import { ShippingLedgerComponent } from './shipping-ledger/shipping-ledger.component';
import { ShippingVerificationComponent } from './shipping-verification/shipping-verification.component';
import { TransactioncodesListComponent } from './transactioncodes-list/transactioncodes-list.component';
import { WarehouseListComponent } from './warehouse-list/warehouse-list.component';
import { ShippingTicketComponent } from './shipping-ticket/shipping-ticket.component';
import { ShippingTicketCreateEditComponent } from './shipping-ticket-create-edit/shipping-ticket-create-edit.component';
import { ShippingTicketPage2Component } from './shipping-ticket-page2/shipping-ticket-page2.component';
// import { ShippingTicketListComponent } from './shipping-ticket-list/shipping-ticket-list.component';
import { ShippingViewComponent } from './shipping-view/shipping-view.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { DeclineUpdateComponent } from './decline-update/decline-update.component';
import { SerialnoEditComponent } from './serialno-edit/serialno-edit.component';
import { ApprovedListsComponent } from './approved-lists/approved-lists.component';
import { ReadyToPackListComponent } from './ready-to-pack-list/ready-to-pack-list.component';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { ReadyToDeliveryComponent } from './ready-to-delivery/ready-to-delivery.component';
import { ProductTrackViewComponent } from './product-track-view/product-track-view.component';
import { ShippingLableComponent } from './shipping-lable/shipping-lable.component';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';
import { TicketPdfViewComponent } from './ticket-pdf-view/ticket-pdf-view.component';
import { ShippingReturnListComponent } from './shipping-return-list/shipping-return-list.component';
import { ReturnApprovedListComponent } from './return-approved-list/return-approved-list.component';
import { ShippingWMangerReportsComponent } from './shipping-wmanger-reports/shipping-wmanger-reports.component';
import { ShippingExecReportComponent } from './shipping-exec-report/shipping-exec-report.component';
import { MatSortModule } from '@angular/material/sort';
import { ShippingListTabComponent } from './shipping-list-tab/shipping-list-tab.component';
import { MatTableExporterModule } from 'mat-table-exporter';
// import { ShippingApprovalTabComponent } from './shipping-approval-tab/shipping-approval-tab.component';
// import { InvoiceListComponent } from './invoice-list/invoice-list.component';
import { DataTablesModule } from 'angular-datatables';
import { APP_ROUTE_PROVIDER } from 'projects/patient/src/app/route.provider';
import { ShippingListComponent } from './shipping-list/shipping-list.component';
import { ShippingDisapprovedListComponent } from './shipping-disapproved-list/shipping-disapproved-list.component';
import { ShippingDisapprovedOrdersTableComponent } from './shipping-disapproved-list/shipping-disapproved-orders-table/shipping-disapproved-orders-table.component';

const providers : any = [ { provide: LocationStrategy, useClass: HashLocationStrategy },{ provide: MatDialogRef, useValue: {} }, { provide: MAT_DIALOG_DATA, useValue: [] }, , APP_ROUTE_PROVIDER]
@NgModule({
  declarations: [
    AppComponent,
    ShippingApprovalListComponent,
    // ShippingCreateEditComponent,
    WarehouseListComponent,
    ShippingAuthComponent,
    ShippingHistoryComponent,
    ShippingVerificationComponent,
    TransactioncodesListComponent,
    ShippingLedgerComponent,
    // ShippingApprovalTabComponent,
    CashPaymentViewComponent,
    ShippingTicketComponent,
    ShippingTicketCreateEditComponent,
    ShippingTicketPage2Component,
    // ShippingTicketListComponent,
    ShippingViewComponent,
    DeclineUpdateComponent,
    SerialnoEditComponent,
    ApprovedListsComponent,
    ReadyToPackListComponent,
    ReadyToDeliveryComponent,
    ProductTrackViewComponent,
    ShippingLableComponent,
    TicketPdfViewComponent,
    ShippingReturnListComponent,
    ReturnApprovedListComponent,
    ShippingWMangerReportsComponent,
    ShippingExecReportComponent,
    ShippingListTabComponent,
    // InvoiceListComponent,
    ShippingListComponent,
    ShippingDisapprovedListComponent,
    ShippingDisapprovedOrdersTableComponent
 ],
  imports: [
    BrowserModule,
    ShippingRoutingModule,
    RouterModule,
    BrowserAnimationsModule,
    MatTableExporterModule,
    HorizontolModule.forRoot(),
    // BackButtonDisableModule.forRoot(),
    NgxsModule.forRoot([orderState]),
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    HttpClientModule,
    MatMenuModule,
    SharedModule,
    NgxExtendedPdfViewerModule,
    NgbModule,
    NgbNavModule,
    MatButtonModule,
    MatProgressSpinnerModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatRippleModule,
    MatPaginatorModule,
    MatTooltipModule,
    MatTableModule,
    MatSnackBarModule,
    MatDialogModule,
    MatNativeDateModule,
    MatSelectModule,
    MatCheckboxModule,
    MatOptionModule,
    MatDatepickerModule,
    MatCardModule,
    MatPseudoCheckboxModule,
    MatButtonModule,
    MatRadioModule,
    MatIconModule,
    MatDividerModule,
    MatInputModule,
    MatSortModule,
    NgxMatSelectSearchModule,
    NgxMatDatetimePickerModule,
    NgxMatTimepickerModule,
    NgxMatNativeDateModule,
    NgxMaskModule.forRoot({ showMaskTyped: true }),
    NgxsFormPluginModule.forRoot(),
    TextMaskModule,
    DataTablesModule,
    CoreModule.forRoot({
      environment,
      registerLocaleFn: registerLocale(),
    }),
    OAuthModule.forRoot(),
    OrderModule.forRoot(),
    MatProgressBarModule,
    MatExpansionModule,
    NgxDaterangepickerMd.forRoot(),


  ],
  providers: providers,
  bootstrap: [AppComponent]
})
export class AppModule { }
@NgModule({})
export class ShippingModule{
  static forRoot():ModuleWithProviders<ShippingModule> {
    return {
      ngModule: AppModule,
      providers
    };
  }
}
