import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { BillingDropdowns } from 'projects/patient/src/app/dropdown-proxy/dropdown-management/dropdowns';
import { ClaimStatusDTO } from 'projects/patient/src/app/dropdown-proxy/dropdown-management/dropdowns/dto/billing';
import { BillingMasterDropdownService } from 'projects/patient/src/app/dropdown-proxy/dropdown-management/dropdowns/master-dropdown-service';
import { TableService } from 'projects/shared/src/app/table.service';
import { Subscription } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import Swal from 'sweetalert2';
import {
  ClaimEventDTO,
  CreateUpdateClaimInfoDTO,
} from '../billing-proxy/billing-management/posting/dto';
import { ClaimEventViewComponent } from '../claim-event-view/claim-event-view.component';
import {
  ClaimInfoDetailsService,
  ClearingHouseUpdateBatchesService,
} from '../claim-proxy/claim-processing-management/rcm/claim-processing-management/claim-optimization';
import {
  ClaimInfoDetailsDTO,
  ClearingHouseUpdateBatchesDTO,
  CreateUpdateClaimInfoDetailsDTO,
} from '../claim-proxy/claim-processing-management/rcm/claim-processing-management/claim-optimization/dto';
import { claimStatus, claimStatusOptions } from '../claim-proxy/claim-processing-management/rcm/claim-processing-management/x12/enumeration-data';
import { ToastrService } from "ngx-toastr";

@Component({
  selector: 'app-claim-event-view-tab',
  templateUrl: './claim-event-view-tab.component.html',
  styleUrls: ['./claim-event-view-tab.component.scss'],
})
export class ClaimEventViewTabComponent implements OnInit {
  @Input() claimId: string = '';
  claimData: CreateUpdateClaimInfoDetailsDTO;
  clearingHouseData: ClearingHouseUpdateBatchesDTO;
  claimEventData: ClaimEventDTO[] = [];
  drpClaimStatus: ClaimStatusDTO[] = [];
  claimTableData: ClaimEventDTO[] = [];
  arrDisplayedColumns: string[] = [
    'eventID',
    'eventDT',
    'usrName',
    'rawMessage',
    'status',
  ];
  isLoading: boolean = true;
  strStatus: string = '';
  isShowSpinner: boolean = false;
  subscription$: Subscription[] = [];

  claimStatus = [
    {
      "claimStatus": "All",
      "shortCodeId": "7",
      "enumValue": claimStatus.all
    },
    {
      "claimStatus": "Claim Denied",
      "shortCodeId": "2",
      "enumValue": claimStatus.claimDenied
    },
    {
      "claimStatus": "Claim Processed",
      "shortCodeId": "1",
      "enumValue": claimStatus.claimProcessed
    },
    {
      "claimStatus": "Posting Completed",
      "shortCodeId": "3",
      "enumValue": claimStatus.postingCompleted
    },
    {
      "claimStatus": "Primary Posting Pending",
      "shortCodeId": "4",
      "enumValue": claimStatus.primaryPostingPending
    },
    {
      "claimStatus": "Secondary Posting Pending",
      "shortCodeId": "5",
      "enumValue": claimStatus.secondaryPostingPending
    },
    {
      "claimStatus": "Tertiary Posting Pending",
      "shortCodeId": "6",
      "enumValue": claimStatus.tertiaryPostingPending
    }
  ]

  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatTable, { static: false }) tableRef: MatTable<any>;
  dataSource: MatTableDataSource<any> = new MatTableDataSource<any>([]);
  constructor(
    private ClearingHouseUpdateBatchesService: ClearingHouseUpdateBatchesService,
    private claimInfoService: ClaimInfoDetailsService,
    private billingMasterDropdownService: BillingMasterDropdownService,
    public dialogRef: MatDialogRef<ClaimEventViewComponent>,
    private tableService: TableService,
    private toastr: ToastrService,
    @Inject(MAT_DIALOG_DATA) public data: { claimId: string }
  ) {}

  ngOnInit(): void {
    this.loadBillingDropdown();
    this.loadClaimEventDetails();








  }

  // To get the Billing Dropdown
  loadBillingDropdown() {
    const BillingDropdownsInput: BillingDropdowns[] = [
      BillingDropdowns.ClaimStatus,
    ];
    this.billingMasterDropdownService
      .getBillingDropdownsByInput(BillingDropdownsInput)
      .subscribe(
        (response) => {
          this.drpClaimStatus =
            response?.claimStatus?.filter(
              (element) => 'All'.indexOf(element?.claimStatus) !== 0
            ) ?? [];
        },
        (err) => {
          this.isShowSpinner = false;
          const data: HttpErrorResponse = err;
          Swal.fire({
            icon: 'info',
            text: data?.error?.error?.message,
          });
        }
      );
  }

  //To get the Claim Events Details and patch in the table
  loadClaimEventDetails() {
    this.claimId = this.data?.claimId ?? '';
    this.claimId &&
      this.ClearingHouseUpdateBatchesService.getClaimStatusDataByClaimIdByClaimId(
        this.claimId
      ).subscribe(
        (response) => {
          this.clearingHouseData = response;

          this.claimEventData =
            response?.claims.claimData[0]?.claimEvents
              ?.claimEvent ?? [];
          this.claimTableData = this.claimEventData?.reverse() ?? [];

          this.dataSource = new MatTableDataSource(this.claimTableData);
          this.dataSource.sort = this.sort;
          this.dataSource.paginator = this.paginator;
          this.isLoading = false;
        },
        (err) => {
          this.isLoading = true;
          const data: HttpErrorResponse = err;
          Swal.fire({
            icon: 'info',
            text: data?.error?.error?.message,
          });
        }
      );
  }

  //! Update claim status in claim data
  updateClaimStatus(strStatus: string) {
    try {
      this.isShowSpinner = true;

      const claimDetails = this.claimInfoService
        .get(this.claimId)
        .pipe(
          tap((response) => {
            this.claimData = response;
           this.claimData.claimStatus=  this.claimStatus?.filter(x=>x?.claimStatus ===strStatus)?.[0]?.enumValue;


          }),
          switchMap((response) =>
            this.claimInfoService.update(this.claimId, this.claimData)
          )
        )
        .subscribe(
          (response) => {
            // Swal.fire({
            //   title: 'Success',
            //   html: 'Saved Successfully',
            //   icon: 'success',
            //   timer: 3000,
            //   timerProgressBar: true,
            // });
            this.toastr.success('Saved Successfully','Success')
            this.isShowSpinner = false;
            this.dialogRef.close();
            //this.tableService.setClaimStatusSaveTable(true);

          },
          (err) => {
            this.isShowSpinner = false;

            const data: HttpErrorResponse = err;
            Swal.fire({
              icon: 'info',
              text: data?.error?.error?.message,
            });
          }
        );
      this.subscription$.push(claimDetails);


      // this.claimInfoService.update(this.claimId, this.claimData).subscribe(
      //   (response) => {

      //     Swal.fire({
      //       title: 'Success',
      //       html: 'Saved Successfully',
      //       icon: 'success',
      //       timer: 3000,
      //       timerProgressBar: true,
      //     });
      //     this.dialogRef.close();
      //     //this.tableService.setClaimStatusSaveTable(true);
      //     this.isShowSpinner = false;
      //   },
      //   (err) => {
      //     this.isShowSpinner = false;

      //     const data: HttpErrorResponse = err;
      //     Swal.fire({
      //       icon: 'error',
      //       text: data?.error?.error?.message,
      //     });
      //   }
      // );
    } catch (error) {
      console.warn(error);
    }
  }
}
