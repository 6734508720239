import type { CreateUpdateSmsNotificationDTO, SmsNotificationDTO } from './dto/models';
import { RestService } from '@abp/ng.core';
import type { PagedAndSortedResultRequestDto, PagedResultDto } from '@abp/ng.core';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class SmsNotificationService {
  apiName = 'orderManagement';

  create = (input: CreateUpdateSmsNotificationDTO) =>
    this.restService.request<any, SmsNotificationDTO>({
      method: 'POST',
      url: '/api/orderApp/sms-notification',
      body: input,
    },
     { apiName: this.apiName, skipHandleError: true });

  delete = (id: string) =>
    this.restService.request<any, void>({
      method: 'DELETE',
      url: `/api/orderApp/sms-notification/${id}`,
    },
     { apiName: this.apiName, skipHandleError: true });

  get = (id: string) =>
    this.restService.request<any, SmsNotificationDTO>({
      method: 'GET',
      url: `/api/orderApp/sms-notification/${id}`,
    },
     { apiName: this.apiName, skipHandleError: true });

  getList = (input: PagedAndSortedResultRequestDto) =>
    this.restService.request<any, PagedResultDto<SmsNotificationDTO>>({
      method: 'GET',
      url: '/api/orderApp/sms-notification',
      params: { skipCount: input.skipCount, maxResultCount: input.maxResultCount, sorting: input.sorting },
    },
     { apiName: this.apiName, skipHandleError: true });

  getListBasedOnPatientId = (patientId: string) =>
    this.restService.request<any, PagedResultDto<SmsNotificationDTO>>({
      method: 'GET',
      url: `/api/orderApp/sms-notification/based-on-patient-id/${patientId}`,
    },
     { apiName: this.apiName, skipHandleError: true });

  update = (id: string, input: CreateUpdateSmsNotificationDTO) =>
    this.restService.request<any, SmsNotificationDTO>({
      method: 'PUT',
      url: `/api/orderApp/sms-notification/${id}`,
      body: input,
    },
     { apiName: this.apiName, skipHandleError: true });

  constructor(private restService: RestService) {}
}
