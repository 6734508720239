import { RestService } from '@abp/ng.core';
import type { PagedAndSortedResultRequestDto, PagedResultDto } from '@abp/ng.core';
import { Injectable } from '@angular/core';
import type { ClaimColorCodeDTO, ClaimStatusDTO, CreateUpdateClaimStatusDTO } from '../dto/models';

@Injectable({
  providedIn: 'root',
})
export class ClaimStatusService {
  apiName = 'billingManagement';

  create = (input: CreateUpdateClaimStatusDTO) =>
    this.restService.request<any, ClaimStatusDTO>({
      method: 'POST',
      url: '/api/billingApp/claim-status',
      body: input,
    },
    { apiName: this.apiName });

  delete = (id: string) =>
    this.restService.request<any, void>({
      method: 'DELETE',
      url: `/api/billingApp/claim-status/${id}`,
    },
    { apiName: this.apiName });

  get = (id: string) =>
    this.restService.request<any, ClaimStatusDTO>({
      method: 'GET',
      url: `/api/billingApp/claim-status/${id}`,
    },
    { apiName: this.apiName });

  getClaimStatusList = (input: PagedAndSortedResultRequestDto) =>
    this.restService.request<any, PagedResultDto<ClaimStatusDTO>>({
      method: 'GET',
      url: '/api/billingApp/claim-status/claim-status-list',
      params: { skipCount: input.skipCount, maxResultCount: input.maxResultCount, sorting: input.sorting },
    },
    { apiName: this.apiName });

  getList = (input: PagedAndSortedResultRequestDto) =>
    this.restService.request<any, PagedResultDto<ClaimStatusDTO>>({
      method: 'GET',
      url: '/api/billingApp/claim-status',
      params: { skipCount: input.skipCount, maxResultCount: input.maxResultCount, sorting: input.sorting },
    },
    { apiName: this.apiName });
    getClaimStatusColorCode = () =>
      this.restService.request<any, ClaimColorCodeDTO>({
        method: 'GET',
        url: '/api/billingApp/claim-status/claim-status-color-code',
      },
      { apiName: this.apiName });
  getdrpclaimStaus = () =>
    this.restService.request<any, ClaimStatusDTO[]>({
      method: 'GET',
      url: '/api/billingApp/claim-status/drpclaim-staus',
    },
    { apiName: this.apiName });

  update = (id: string, input: CreateUpdateClaimStatusDTO) =>
    this.restService.request<any, ClaimStatusDTO>({
      method: 'PUT',
      url: `/api/billingApp/claim-status/${id}`,
      body: input,
    },
    { apiName: this.apiName });

    // getClaimStatusColorCode = () =>
    //   this.restService.request<any, ClaimColorCodeDTO>({
    //     method: 'GET',
    //     url: '/api/billingApp/claim-status/claim-status-color-code',
    //   },
    //   { apiName: this.apiName });

  constructor(private restService: RestService) {}
}
