import { PagedAndSortedResultRequestDto } from '@abp/ng.core';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatOption } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSelect } from '@angular/material/select';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import moment, { Moment } from 'moment';
import { LocaleConfig } from 'ngx-daterangepicker-material';


import { SaleorderService } from 'projects/order/src/app/order-proxy/order-management/order/saleorder.service';
import { ItemExportDatas, ItemExportDTO } from 'projects/patient/src/app/patient-proxy/patient/dto/models';
import { ExportType } from 'projects/patient/src/app/patient-proxy/patient/enumeration-data/export-type.enum';
import { ReportService } from 'projects/patient/src/app/patient-proxy/patient/report.service';

import { CalendarViewComponent } from 'projects/schedule/src/app/calendar-view/calendar-view.component';
import { Observable, Subscription } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { SearchPurchaseOrderDTO } from '../item-proxy/item-management/items/dto/models';
import { PurchaseOrderService } from '../item-proxy/item-management/items/purchase-order.service';
import { ToastrService } from "ngx-toastr";

@Component({
  selector: 'app-purchase-order',
  templateUrl: './purchase-order.component.html',
  styleUrls: ['./purchase-order.component.scss']
})
export class PurchaseOrderComponent implements OnInit , OnDestroy {
  vSearchForm: FormGroup;
  isShowSpinner: boolean;
  tableData: any[] = [];

  constructor(public dialog: MatDialog,
    private formBuilder: FormBuilder,
    private POService: PurchaseOrderService,
    private orderService: SaleorderService,
    private title: Title,
    private router: Router,
    private reportService: ReportService,
    private toastr: ToastrService,
  ) {
    this.calendarLocale = {
      customRangeLabel: 'Pick a date...',
      applyLabel: 'Apply',
      clearLabel: 'Clear',
      format: 'DD/MM/YYYY',
      daysOfWeek: ['su', 'mo', 'tu', 'we', 'th', 'fr', 'sa'],
      monthNames: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
      firstDay: 1
    };
    this.ranges = {
      'Today': [moment(), moment()],
      'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
      'Current week': [moment().startOf('isoWeek'), moment().endOf('isoWeek')],
      'Next weekend': [this.getNextSaturday(), this.getNextSunday()],
      'Next 3 days': [moment().add(1, 'days'), moment().add(3, 'days')],
      'Last 7 Days': [moment().subtract(6, 'days'), moment()],
      'Last 30 Days': [moment().subtract(29, 'days'), moment()],
      // 'Current day': [moment(), moment()],
      // 'Next 2 days': [moment().add(1, 'days'), moment().add(2, 'days')],
      'This Month': [moment().startOf('month'), moment().endOf('month')],
      'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
    };
  }

  dataSource: MatTableDataSource<any>;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  displayedColumns: string[] = [ 'POno', 'vName', 'vEmail', 'createdDate','Options', ];

  // Search decalarations
  PurchaseOrderNo: string;
  patientID: string;
  patientName: string;
  CsrName: string;
  ticketDate: string;
  ticketId: string;
  status: string;


  purchaseOrderListForm: FormGroup;

  // calender Declarations

  selectedRange: { startDate: Moment, endDate: Moment };
  ranges: any;
  minDate: Moment;
  maxDate: Moment;
  calendarLocale: LocaleConfig;
  calendarPlaceholder: string = '';
  step: boolean;
  subscription$: Subscription[] = [];

  @ViewChild('allPurchaseOrderNoSelected') private allPurchaseOrderNoSelected: MatOption;
  @ViewChild('purchaseOrderSelect') purchaseOrderSelect: MatSelect;


  @ViewChild('allVendorNameSelected') private allVendorNameSelected: MatOption;
  @ViewChild('VendorNameSelect') VendorNameSelect: MatSelect;

  @ViewChild('allProductNameSelect') private allProductNameSelect: MatOption;
  @ViewChild('productNameSelect') productNameSelect: MatSelect;


  @ViewChild('allPurchaseOrderSelected') private allPurchaseOrderSelected: MatOption;
  @ViewChild('PurchaseOrderStatusSelect') PurchaseOrderStatusSelect: MatSelect;


  ngOnInit(): void {
    this.title.setTitle("Qsecure | Purchase Order");
    this.vSearchForm = this.formBuilder.group({
      drpProduct: new FormControl(""),
      txtMainProductFilter: new FormControl(""),
      txtSBrandFilter: new FormControl(""),
      drpBrand: new FormControl(""),
      drpStatus: new FormControl(""),
      txtStatusFilter: new FormControl(""),
      txtCreatedate: new FormControl(""),
    });
    this.getTableData();
    // Search FormBuilder
    this.purchaseOrderListForm = this.formBuilder.group({
      txtPurchaseOrderNo: new FormControl(""),
      txtPurchaseOrderNoSearch: new FormControl(""),
      txtVendorId: new FormControl(""),
      txtVendorIdSearch: new FormControl(""),
      txtVendorName: new FormControl(""),
      txtVendorNameSearch: new FormControl(""),
      txtProductName: new FormControl(""),
      txtProductNameSearch: new FormControl(""),
      txtPurchaseOrderStatus: new FormControl(""),
      txtPurchaseOrderStatusSearch: new FormControl(""),
      // txtPatientIdSearch: new FormControl(""),
      // txtPatientName: new FormControl(""),
      // txtPatientNameSearch: new FormControl(""),
      // txtCsrName: new FormControl(""),
      // txtCsrNameSearch: new FormControl(""),
      // txtStatus: new FormControl(""),
      // txtStatusSearch: new FormControl(""),
      // txtTicketId: new FormControl(""),
      // txtTicketIdSearch: new FormControl(""),
      // txtTicketDate: new FormControl(""),
      // txtTicketDateSearch: new FormControl(""),
      txtFromDate: new FormControl(""),
      txtToDate: new FormControl("")
    });
    this.getDropdown();
  }

  ngOnDestroy(): void {
    this.subscription$?.forEach((sub) => {
      sub && sub?.unsubscribe();
    });
  }


  //// get table list
  getTableData() {
    this.isShowSpinner = true;
    this.tableData = [];
    const POServiceGetList = this.POService.getList(new PagedAndSortedResultRequestDto).subscribe(response => {
      this.tableData = [];
      response && response?.items?.forEach(element => {
        this.tableData.push({
          POno: element?.purchaseOrderNo,
          vName: element?.vendorName,
          vEmail: element?.vendorEmailId,
          PORaisedDate: element?.purchaseOrderRaisedDate,
          createdDate: element?.creationTime,
          id: element?.id,
        });
      })
      this.isShowSpinner = false;
      this.dataSource = new MatTableDataSource(this.tableData);
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
    }, error => {
      const data: HttpErrorResponse = error;
      this.isShowSpinner = false;
      // Swal.fire({
      //   icon: 'error',
      //   text: data?.error?.error?.message,
      // });
      this.dataSource = new MatTableDataSource(this.tableData);
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
    })
    this.subscription$.push(POServiceGetList);
  }

  /// add Purchase Order routing
  addPurchaseOrder() {
    // const dialogRef = this.dialog.open(AddPurchaseOrderComponent, {
    //   disableClose: true,
    //   data: {}
    // });

    //  dialogRef.afterClosed().subscribe(() => {
    //   this.getTableData();
    //   this.getDropdown();
    // });
    this.router.navigate(['inventory/addPurchaseOrder'])

  }

  /// view Purchase Order
  viewPurchaseOrder(id) {
    // const dialogRef = this.dialog.open(AddPurchaseOrderComponent, {
    //   // height: '700px',
    //   // minWidth: '60vw',
    //   disableClose: true,
    //   data: { poId: id }
    // });

    //  dialogRef.afterClosed().subscribe(() => {
    //   this.getTableData();
    //   this.getDropdown();
    // });

    this.router.navigate(['inventory/addPurchaseOrder/' + id])
    this.getTableData();
    this.getDropdown();
  }

  // Search Functionality
  clearPurcaseOrderNo() {
    // this.PurchaseOrderNo = '';
    this.purchaseOrderListForm.patchValue({ 
      // txtPurchaseOrderNo: '' // commend for value fech
     });
  }
  vendorID: string;
  clearVendorId() {
    this.vendorID = "";
    this.purchaseOrderListForm.patchValue({
       txtVendorId: ''
       });
  }
  vendorName: string;
  clearVendorName() {
    // this.vendorName = "";
    this.purchaseOrderListForm.patchValue({ 
      // txtVendorName: ''
     });
  }
  productName: string;
  clearProductName() {
    this.productName = "";
    this.purchaseOrderListForm.patchValue({
       txtProductName: ''
       });
  }
  purchaseOrderStatus: string;
  clearPurchaseOrderStatus() {
    this.purchaseOrderStatus = "";
    this.purchaseOrderListForm.patchValue({ 
      // txtPurchaseOrderStatus: '' 
    });
  }
  clearPurchaseOrderDate() {
    // this.ticketDate = "";
    this.purchaseOrderListForm.patchValue({
      //  txtFromDate: '' 
      });
  }

  // reset table
  reset() {
    this.purchaseOrderListForm.reset();
    this.getTableData();
  }

  // search table

  search() {
    // this.isLoading = true;
    let purchaseOrderNo: any[] = [];
    let vendorName: any[] = [];
    let productName: any[] = [];
    let purchaseStatus: string;

    this.purchaseOrderListForm.value.txtPurchaseOrderNo !== "" &&
      this.purchaseOrderListForm.value.txtPurchaseOrderNo !== undefined &&
      this.purchaseOrderListForm.value.txtPurchaseOrderNo !== null &&
      [this.purchaseOrderListForm.value.txtPurchaseOrderNo].length !== 0 &&
      [this.purchaseOrderListForm.value.txtPurchaseOrderNo].forEach(val => {
        val?.forEach(element => {
          element !== undefined && element !== 0 && purchaseOrderNo.push(element);
        });
      });

    this.purchaseOrderListForm.value.txtVendorName !== "" &&
      this.purchaseOrderListForm.value.txtVendorName !== undefined &&
      this.purchaseOrderListForm.value.txtVendorName !== null &&
      [this.purchaseOrderListForm.value.txtVendorName].length !== 0 &&
      [this.purchaseOrderListForm.value.txtVendorName].forEach(val => {
        val?.forEach(element => {
          element !== undefined && element !== 0 && vendorName.push(element);
        });
      });

    this.purchaseOrderListForm.value.txtProductName !== "" &&
      this.purchaseOrderListForm.value.txtProductName !== undefined &&
      this.purchaseOrderListForm.value.txtProductName !== null &&
      [this.purchaseOrderListForm.value.txtProductName].length !== 0 &&
      [this.purchaseOrderListForm.value.txtProductName].forEach(val => {
        val?.forEach(element => {
          element !== undefined && element !== 0 && productName.push(element);
        });
      });

    // this.purchaseOrderListForm.value.txtPurchaseOrderStatus !== "" &&
    //   this.purchaseOrderListForm.value.txtPurchaseOrderStatus !== undefined &&
    //   this.purchaseOrderListForm.value.txtPurchaseOrderStatus !== null &&
    //   [this.purchaseOrderListForm.value.txtPurchaseOrderStatus].length !== 0 &&
    //   [this.purchaseOrderListForm.value.txtPurchaseOrderStatus].forEach(val => {
    //     val?.forEach(element => {
    //       element !== undefined && element !== 0 && purchaseStatus.push(element);
    //     });
    //   });



    let purchaseOrderSearch: SearchPurchaseOrderDTO = {
      purchaseOrderNo: purchaseOrderNo,
      vendorNames: vendorName,
      productNames: productName,
      purchaseOrderStatus: this.purchaseOrderListForm.value.txtPurchaseOrderStatus,
      purchaseOrderRaisedFromDate: this.selectedRange?.startDate?.format('YYYY-MM-DD'),
      purchaseOrderRaisedToDate: this.selectedRange?.endDate?.format('YYYY-MM-DD'),
    }
    const searchPosSearch = this.POService.searchPurchaseOrder(purchaseOrderSearch).subscribe(response => {
      this.tableData = [];
      response && response?.items?.forEach((element) => {
        this.tableData.push({
          id: element?.id,
          data: element?.id,
          POno: element?.purchaseOrderNo,
          vName: element?.vendorName,
          productName: element?.purchaseOrderProducts[0]?.productName,
          purchaseOrderStatus: element?.purchaseOrderProducts[0]?.purchaseOrderStatus,
          vEmail: element?.vendorEmailId,
          PORaisedDate: element?.purchaseOrderRaisedDate,
          createdDate: element?.creationTime
        });
      })
      this.dataSource = new MatTableDataSource(this.tableData);
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
    });
    this.subscription$.push(searchPosSearch);

  }


  drpVendorId: any[] = []
  drpVendorName: any[] = []
  drpPurchaseOrderNo: any[] = []
  drpProductNames: any[] = []
  drpPurchaseOrderStatus: any[] = []
  filterVendorName: Observable<any>
  filterProductNames: Observable<any>
  filterPurchaseOrderStatus: Observable<any>
  filterPurchaseOrderNo: Observable<any>

  // get dropdown for filter search
  getDropdown() {
    const getPurchaseOrderDropdown = this.POService.getPurchaseOrderDropdowns().subscribe(response => {
      this.drpPurchaseOrderNo = response.purchaseOrderNo
      this.drpVendorName = response.vendorNames
      this.drpProductNames = response.productNames
      this.drpPurchaseOrderStatus = response.purchaseOrderStatus

      this.filterPurchaseOrderNo = this.purchaseOrderListForm?.get("txtPurchaseOrderNoSearch").valueChanges
        .pipe(
          startWith(''),
          map(value => {
            return this.drpPurchaseOrderNo.filter(
              option => option?.toLowerCase()?.includes(value?.toLowerCase() ?? ""))
          })
        );

      this.filterVendorName = this.purchaseOrderListForm?.get("txtVendorNameSearch").valueChanges
        .pipe(
          startWith(''),
          map(value => {
            return this.drpVendorName.filter(option => option?.toLowerCase()?.includes(value?.toLowerCase() ?? ""))
          })
        );

      this.filterProductNames = this.purchaseOrderListForm?.get("txtProductNameSearch").valueChanges
        .pipe(
          startWith(''),
          map(value => {
            return this.drpProductNames.filter(option => option?.toLowerCase()?.includes(value?.toLowerCase() ?? ""))
          })
        );

      // this.filterPurchaseOrderStatus = this.purchaseOrderListForm?.get("txtPurchaseOrderStatusSearch").valueChanges
      //   .pipe(
      //     startWith(''),
      //     map(value => {
      //       return this.drpPurchaseOrderStatus.filter(option => option?.toLowerCase()?.includes(value?.toLowerCase() ?? ""))
      //     })
      //   );
    })
    this.subscription$.push(getPurchaseOrderDropdown);
  }

  // select all functionality for Purchase order No
  togglePurchaseOrderNoSelection() {
    if (this.allPurchaseOrderNoSelected.selected) {
      this.purchaseOrderSelect.options.forEach((item: MatOption) => item.select());
    }
    else {
      this.purchaseOrderSelect.options.forEach((item: MatOption) => item.deselect());
    }
  }

  // select all functionality for Vendor Name
  toggleAllVendorNameSelection() {
    if (this.allVendorNameSelected.selected) {
      this.VendorNameSelect.options.forEach((item: MatOption) => item.select());
    }
    else {
      this.VendorNameSelect.options.forEach((item: MatOption) => item.deselect());
    }
  }

  // select all functionality for Product Name
  toggleProductNameSelection() {
    if (this.allProductNameSelect.selected) {
      this.productNameSelect.options.forEach((item: MatOption) => item.select());
    }
    else {
      this.productNameSelect.options.forEach((item: MatOption) => item.deselect());
    }
  }


  // select all functionality for Purchase Order Status
  toggleAllPurchaseOrderStatusSelection() {
    if (this.allPurchaseOrderSelected.selected) {
      this.PurchaseOrderStatusSelect.options.forEach((item: MatOption) => item.select());
    }
    else {
      this.PurchaseOrderStatusSelect.options.forEach((item: MatOption) => item.deselect());
    }
  }



  // calender view

  getNextSaturday() {
    const dayINeed = 6; // for Saturday
    const today = moment().isoWeekday();
    if (today <= dayINeed) {
      return moment().isoWeekday(dayINeed);
    } else {
      return moment().add(1, 'weeks').isoWeekday(dayINeed);
    }
  }

  getNextSunday() {
    const dayINeed = 7; // for Sunday
    const today = moment().isoWeekday();
    if (today <= dayINeed) {
      return moment().isoWeekday(dayINeed);
    } else {
      return moment().add(1, 'weeks').isoWeekday(dayINeed);
    }
  }

  viewCalendar(id: string) {
    const dialogRef = this.dialog.open(CalendarViewComponent, {
      disableClose: true,
      // height: "80%",
      width: "80%",
      // maxHeight: '200px',
      // maxWidth: '600px',
      data: { calendarId: id }
    });

    const closeModal = dialogRef.afterClosed().subscribe(() => {
    });
    this.subscription$.push(closeModal);
  }

  // Filter Clear for Purchase Order No
  onFilterPurchaseOrderNo() {
    this.clearVendorName();
    this.clearProductName();
    this.clearPurchaseOrderStatus();
    this.clearPurchaseOrderDate();
  }


  // Filter Clear for Vendor Name
  onFilterVendorNameChange() {
    this.clearPurcaseOrderNo();
    this.clearProductName();
    this.clearPurchaseOrderStatus();
    this.clearPurchaseOrderDate();
  }

  // Filter Clear for Product Name
  onFilterProductNameChange() {
    this.clearPurcaseOrderNo();
    this.clearVendorName();
    this.clearPurchaseOrderDate();
    this.clearPurchaseOrderStatus();
  }

  // Filter Clear for Purchase Order Status
  onFilterPurchaseOrdrerStatusChange() {
    this.clearPurcaseOrderNo();
    this.clearVendorName();
    this.clearProductName();
    this.clearPurchaseOrderDate();
  }


  //filter clear for Ticket Date
  change() {
    this.clearPurcaseOrderNo();
    this.clearVendorName();
    this.clearProductName();
    this.clearPurchaseOrderStatus();
  }

  // export functionality
  exportTableData() {
    let datas: ItemExportDatas[] = []
    this.dataSource?.data?.forEach(element => {

      datas.push({
        purchaseOrderNo: element?.POno,
        vendorName: element?.vName,
        vendorEmailId: element?.vEmail,
        purchaseOrderCreatedDate: element?.createdDate,
      });
    })
    let itemExport: ItemExportDTO = {
      columns: ["Purchase Ordr No", "Vendor Name", "Vendor Email id", "Created Date"],
      datas: datas
    };
    const exportReport = this.reportService.exportItemReportsByExportTypeAndExportDatas(ExportType.PurchaseOrderReports, itemExport).subscribe(response => {
      if (response && response?.length !== 0) {
        let filelocation: String = String("data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64," + response);
        let link = document.createElement("a");
        link.download = ("Stock-View") + ".xlsx";
        link.href = filelocation.toString();
        link.click();
        // Swal.fire({ title: 'Success', html: 'Exported Successfully', icon: 'success', timer: 3000, timerProgressBar: true })
        this.toastr.success('Exported Successfully','Success')
      }
    }, err => {
      const data: HttpErrorResponse = err;
      let errorMesg: string = data?.error;
      if (errorMesg?.includes('Sorry!! There is no data in table to export')) {
        errorMesg = 'Sorry!! There is no data in table to export';
      }
      else {
        errorMesg = data?.error?.error?.message;
      }
      Swal.fire({
        icon: 'info',
        text: errorMesg
      });
    });
    this.subscription$.push(exportReport);
    //
  }
}
