import { ListService } from '@abp/ng.core';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Store } from '@ngxs/store';
// import { dateFormatter } from 'projects/admin/src/app/sub-batch-list/sub-batch-list.component';
import { SaleorderService } from 'projects/admin/src/app/warehouse-proxy/order/saleorder.service';
import { AddAuthorizationComponent } from 'projects/order/src/app/add-authorization/add-authorization.component';
import { getOrder } from 'projects/order/src/app/order-store/order.action';
import { PatientService } from 'projects/patient/src/app/patient-proxy/patient';
import { TableService } from 'projects/shared/src/app/table.service';
import Swal from 'sweetalert2';
import { SaleorderDTO } from '../claim-proxy/claim-processing-management/rcm/order-management/order/dto';
import { AuthorizationDTO } from '../claim-proxy/claim-processing-management/rcm/patient-management/patient/dto';
import { dateFormatter } from 'projects/shared/src/app/services/common.service';

@Component({
  selector: 'app-billing-authorization',
  templateUrl: './billing-authorization.component.html',
  styleUrls: ['./billing-authorization.component.scss'],
  providers: [ListService]
})
export class BillingAuthorizationComponent implements OnInit {
  strPageType: string = "auth";
  @Input() orderId: string = '';
  tblAuthReponse: any[] = [];
  Show: boolean = false;
  @Input() patientId: string = '';
  viewOrEdit: string = "";
  isLoading: boolean = false;
  orderStatus: boolean;
  constructor(private store: Store,
    public list: ListService,
    private Table: TableService,
    public dialog: MatDialog,
    private orderService: SaleorderService,
    private patientService: PatientService,
  ) { }

  ngOnInit(): void {
    this.orderId = this.orderId ?? "";
    setTimeout(() => {
      this.orderId && this.getAuthTableData(this.orderId);
    }, 500);
  }

  //! Load Auth Table Datas
  getAuthTableData(orderId: string) {
    this.isLoading = true;
    let orderDetails: SaleorderDTO;
    this.store.dispatch(new getOrder(orderId)).subscribe(response => {
      orderDetails = response?.orderState.selectedOrder;

      this.tblAuthReponse = [];
      const primaryAuth = orderDetails?.patients?.authorizations?.primaryAuthorizations;
      const secondaryAuth = orderDetails?.patients?.authorizations?.secondaryAuthorizations;
      const tertiaryAuth = orderDetails?.patients?.authorizations?.tertiaryAuthorizations;
      if (primaryAuth != null) {
        primaryAuth.startDate = primaryAuth?.startDate ?? "";
        primaryAuth.endDate = primaryAuth?.endDate ?? "";
        const toDate: Date | null = primaryAuth?.endDate == "" ? null : new Date(primaryAuth?.endDate);
        const fromDate: Date | null = primaryAuth?.startDate == "" ? null : new Date(primaryAuth?.startDate);
        const timeDifference: number = toDate?.getTime() - fromDate?.getTime() ?? 0;
        const daysDifference: number = +(timeDifference / (1000 * 60 * 60 * 24) ?? 0).toFixed(0);
        this.tblAuthReponse.push({
          authId: primaryAuth?.authId,
          authVisits: primaryAuth?.authVisits,
          authNo: primaryAuth?.authNo,
          authAmount: primaryAuth?.authAmount,
          item: primaryAuth?.item,
          note: primaryAuth?.note,
          typeOfPolicy: primaryAuth?.typeOfPolicy,
          startDate: dateFormatter(primaryAuth?.startDate),
          endDate: dateFormatter(primaryAuth?.endDate),
          category: primaryAuth?.category,
          completed: primaryAuth?.isCompleted,
          completedBy: primaryAuth?.completedBy,
          plan: primaryAuth?.plan,
          orderId: orderDetails.id,
          patientId: orderDetails?.patientId,
          authorizationId: primaryAuth?.authorizationId,
          rbtnStatus: primaryAuth?.approvalStatus,
          txtReason: primaryAuth?.reason,
       //   daysDifference: daysDifference
          txtUnits: primaryAuth?.units,
          txtUsed: primaryAuth?.used,
          txtSaleOrRent: primaryAuth?.saleOrRent,
          txtExpired: primaryAuth?.isExpired,
          txtItemCodes: primaryAuth?.itemCodes,
          expiryStatus: primaryAuth?.expiryStatus,
          isFollowUp: primaryAuth?.isFollowUp
        });
        if (primaryAuth?.approvalStatus === "Disapproved") {
          this.Show = true
        }
      }
      if (secondaryAuth != null) {
        secondaryAuth.startDate = secondaryAuth?.startDate ?? "";
        secondaryAuth.endDate = secondaryAuth?.endDate ?? "";
        const toDate: Date | null = secondaryAuth?.endDate == "" ? null : new Date(secondaryAuth?.endDate);
        const fromDate: Date | null = secondaryAuth?.startDate == "" ? null : new Date(secondaryAuth?.startDate);
        const timeDifference: number = toDate?.getTime() - fromDate?.getTime() ?? 0;
        const daysDifference: number = +(timeDifference / (1000 * 60 * 60 * 24) ?? 0).toFixed(0);
        this.tblAuthReponse.push({
          authId: secondaryAuth?.authId,
          authVisits: secondaryAuth?.authVisits,
          authNo: secondaryAuth?.authNo,
          authAmount: secondaryAuth?.authAmount,
          item: secondaryAuth?.item,
          note: secondaryAuth?.note,
          typeOfPolicy: secondaryAuth?.typeOfPolicy,
          startDate: dateFormatter(secondaryAuth?.startDate),
          endDate: dateFormatter(secondaryAuth?.endDate),
          category: secondaryAuth?.category,
          completed: secondaryAuth?.isCompleted,
          completedBy: secondaryAuth?.completedBy,
          plan: secondaryAuth?.plan,
          orderId: orderDetails?.id,
          patientId: orderDetails?.patientId,
          authorizationId: secondaryAuth?.authorizationId,
          rbtnStatus: secondaryAuth?.approvalStatus,
          txtReason: secondaryAuth?.reason,
         // daysDifference: daysDifference

          txtUnits: secondaryAuth?.units,
          txtUsed: secondaryAuth?.used,
          txtSaleOrRent: secondaryAuth?.saleOrRent,
          txtExpired: secondaryAuth?.isExpired,
          txtItemCodes: secondaryAuth?.itemCodes,
          expiryStatus: secondaryAuth?.expiryStatus,
          isFollowUp: secondaryAuth?.isFollowUp
        });
        if (secondaryAuth?.approvalStatus === "Disapproved") {
          this.Show = true
        }
      }
      if (tertiaryAuth != null) {
        tertiaryAuth.startDate = tertiaryAuth?.startDate ?? "";
        tertiaryAuth.endDate = tertiaryAuth?.endDate ?? "";
        const toDate: Date | null = tertiaryAuth?.endDate == "" ? null : new Date(tertiaryAuth?.endDate);
        const fromDate: Date | null = tertiaryAuth?.startDate == "" ? null : new Date(tertiaryAuth?.startDate);
        const timeDifference: number = toDate?.getTime() - fromDate?.getTime() ?? 0;
        const daysDifference: number = +(timeDifference / (1000 * 60 * 60 * 24) ?? 0).toFixed(0);
        this.tblAuthReponse.push({
          authId: tertiaryAuth?.authId,
          authVisits: tertiaryAuth?.startDate,
          authNo: tertiaryAuth?.authNo,
          authAmount: tertiaryAuth?.authAmount,
          item: tertiaryAuth?.item,
          note: tertiaryAuth?.note,
          typeOfPolicy: tertiaryAuth?.typeOfPolicy,
          startDate: dateFormatter(tertiaryAuth?.startDate),
          endDate: dateFormatter(tertiaryAuth?.endDate),
          category: tertiaryAuth?.category,
          completed: tertiaryAuth?.isCompleted,
          completedBy: tertiaryAuth?.completedBy,
          plan: tertiaryAuth?.plan,
          orderId: orderDetails.id,
          patientId: orderDetails?.patientId,
          authorizationId: tertiaryAuth?.authorizationId,
          rbtnStatus: tertiaryAuth?.approvalStatus,
          txtReason: tertiaryAuth?.reason,
         // daysDifference: daysDifference

          txtUnits: tertiaryAuth?.units,
          txtUsed: tertiaryAuth?.used,
          txtSaleOrRent: tertiaryAuth?.saleOrRent,
          txtExpired: tertiaryAuth?.isExpired,
          txtItemCodes: tertiaryAuth?.itemCodes,
          expiryStatus: tertiaryAuth?.expiryStatus,
          isFollowUp: tertiaryAuth?.isFollowUp
        });
        if (tertiaryAuth?.approvalStatus === "Disapproved") {
          this.Show = true
        }
      }
      this.isLoading = false
      this.Table.setAuthTable(this.tblAuthReponse);
    }, err => {
      this.isLoading = false;
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    })
  }

  //! Open Auth Modal Popup
  openAuthModal() {
    const dialogRef = this.dialog.open(AddAuthorizationComponent, {
      disableClose: true,
      data: { orderId: this.orderId, allowUptSve: true }
    });
    dialogRef.afterClosed().subscribe(() => {
      this.orderId !== "" && this.getAuthTableData(this.orderId);
    });
  }

  //! View Auth Modal Popup
  viewAuth(auth: AuthorizationDTO) {
    const dialogRef = this.dialog.open(AddAuthorizationComponent, {
      disableClose: true,
      data: { orderId: this.orderId, authDatas: auth, allowUptSve: true }
    });
    dialogRef.afterClosed().subscribe(() => {
      this.orderId !== "" && this.orderId !== null && this.orderId !== undefined && this.getAuthTableData(this.orderId);
    });
  }

  //! Delete Auth By Id
  deleteAuth(datas?: any) {
    Swal.fire({
      title: 'Are you sure you want to Delete?',
      text: 'You won\'t be able to retrieve this data!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#34c38f',
      cancelButtonColor: '#f46a6a',
      confirmButtonText: 'Yes, delete it!'
    }).then(result => {
      if (result.value) {
        this.orderService.deleteAuthorization(datas?.orderId, datas?.authorizationId).subscribe(() => {
          this.tblAuthReponse.forEach(element => {
            if (datas?.authorizationId == element.authorizationId) {
              let index = this.tblAuthReponse.indexOf(element, 0)
              this.tblAuthReponse.splice(index, 1)
            }
          });
          this.Table.setAuthTable(this.tblAuthReponse);
        }, err => {
          const data: HttpErrorResponse = err;
          Swal.fire({
            icon: 'info',
            text: data?.error?.error?.message,
          });
        });
      }
    });
  }

}
