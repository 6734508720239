import type { CreateUpdatePurchaseOrderDTO, CreateUpdatePurchaseOrderProductsDTO, PurchaseOrderDTO, PurchaseOrderSearchDropdownDTO, SearchPurchaseOrderDTO } from './dto/models';
import { RestService } from '@abp/ng.core';
import type { PagedAndSortedResultRequestDto, PagedResultDto } from '@abp/ng.core';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class PurchaseOrderService {
  apiName = 'ItemManagement';

  create = (input: CreateUpdatePurchaseOrderDTO) =>
    this.restService.request<any, PurchaseOrderDTO>({
      method: 'POST',
      url: '/api/ItemApp/purchase-order',
      body: input,
    },
     { apiName: this.apiName, skipHandleError: true });

  delete = (id: string) =>
    this.restService.request<any, void>({
      method: 'DELETE',
      url: `/api/ItemApp/purchase-order/${id}`,
    },
     { apiName: this.apiName, skipHandleError: true });

  get = (id: string) =>
    this.restService.request<any, PurchaseOrderDTO>({
      method: 'GET',
      url: `/api/ItemApp/purchase-order/${id}`,
    },
     { apiName: this.apiName, skipHandleError: true });

  getList = (input: PagedAndSortedResultRequestDto) =>
    this.restService.request<any, PagedResultDto<PurchaseOrderDTO>>({
      method: 'GET',
      url: '/api/ItemApp/purchase-order',
      params: { skipCount: input.skipCount, maxResultCount: input.maxResultCount, sorting: input.sorting },
    },
     { apiName: this.apiName, skipHandleError: true });

  update = (id: string, input: CreateUpdatePurchaseOrderDTO) =>
    this.restService.request<any, PurchaseOrderDTO>({
      method: 'PUT',
      url: `/api/ItemApp/purchase-order/${id}`,
      body: input,
    },
     { apiName: this.apiName, skipHandleError: true });

  getPurchaseOrderDropdowns = () =>
    this.restService.request<any, PurchaseOrderSearchDropdownDTO>({
      method: 'GET',
      url: '/api/ItemApp/purchase-order/get-purchase-order-dropdowns',
    },
     { apiName: this.apiName, skipHandleError: true });

  searchPurchaseOrder = (input: SearchPurchaseOrderDTO) =>
    this.restService.request<any, PagedResultDto<PurchaseOrderDTO>>({
      method: 'GET',
      url: '/api/ItemApp/purchase-order/search-purchase-order',
      params: { purchaseOrderNo: input.purchaseOrderNo, vendorNames: input.vendorNames, productNames: input.productNames, purchaseOrderStatus: input.purchaseOrderStatus, purchaseOrderRaisedFromDate: input.purchaseOrderRaisedFromDate, purchaseOrderRaisedToDate: input.purchaseOrderRaisedToDate },
    },
     { apiName: this.apiName, skipHandleError: true });

  updateProductAsReceived = (id: string, input: CreateUpdatePurchaseOrderProductsDTO) =>
    this.restService.request<any, PurchaseOrderDTO>({
      method: 'PUT',
      url: `/api/ItemApp/purchase-order/${id}/update-product-as-received`,
      body: input,
    },
     { apiName: this.apiName, skipHandleError: true });

  constructor(private restService: RestService) {}
}
