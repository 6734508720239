import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatRadioChange } from '@angular/material/radio';
import { MatSelectChange } from '@angular/material/select';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngxs/store';
import { ItemDTO, SaleorderDTO } from 'projects/order/src/app/order-proxy/order-management/order/dto/models';
import { SaleorderService } from 'projects/order/src/app/order-proxy/order-management/order/saleorder.service';
import { getOrder, updateOrder } from 'projects/order/src/app/order-store/order.action';
import { AuthCategoryDTO, AuthVisitsDTO, OrderMasterDropdownDTO, TypeOfPlanDTO } from 'projects/patient/src/app/dropdown-proxy/dropdown-management/dropdowns/dto/order/models';
import { PatientMasterDropdownDTO, SaleOrRentDTO } from 'projects/patient/src/app/dropdown-proxy/dropdown-management/dropdowns/dto/patient';
import { OrderMasterDropdownService } from 'projects/patient/src/app/dropdown-proxy/dropdown-management/dropdowns/master-dropdown-service/order-master-dropdown.service';
import { PatientMasterDropdownService } from 'projects/patient/src/app/dropdown-proxy/dropdown-management/dropdowns/master-dropdown-service/patient-master-dropdown.service';
import { OrderDropdowns } from 'projects/patient/src/app/dropdown-proxy/dropdown-management/dropdowns/order-dropdowns.enum';
import { PatientDropdowns } from 'projects/patient/src/app/dropdown-proxy/dropdown-management/dropdowns/patient-dropdowns.enum';
import { AuthorizationDTO, PolicyDTO } from 'projects/patient/src/app/patient-proxy/patient/dto/models';
import { TableService } from 'projects/shared/src/app/table.service';
import { NumberValidators } from 'projects/shared/src/app/validations/number-validator';
import { TextValidators } from 'projects/shared/src/app/validations/text-validator';
import { Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { ToastrService } from "ngx-toastr";
import Swal from 'sweetalert2';

@Component({
  selector: 'app-shipping-auth',
  templateUrl: './shipping-auth.component.html',
  styleUrls: ['./shipping-auth.component.scss']
})
export class ShippingAuthComponent implements OnInit , OnDestroy {
  @Output() nextTabMoveOnSaveEmitter: EventEmitter<string> = new EventEmitter<string>(null);
  public authorizationForm: FormGroup;
  @Input() orderId: string = '';
  drpAuthItem: ItemDTO[] = [];
  drpPlan: TypeOfPlanDTO[] = [];
  drpPolicies: PolicyDTO[] = [];
  @Input() patientId: string = '';
  @Output() saveAuthorizationFormState: EventEmitter<any> = new EventEmitter<any>();
  @Input() disabled = true;
  Show: boolean = false;
  authorizationId: string = "00000000-0000-0000-0000-000000000000";
  authVisit: string;
  category: string;
  item: string;
  plan: string;
  viewOrEdit: string = "";
  notesText ='';
  drpAuthVisit: AuthVisitsDTO[] = [];
  drpCategory: AuthCategoryDTO[] = [];
  // drpMarketingRef: MarketingReferralDTO[] = [];
  drpPlanType: any[];
  saveButtonHide: boolean;
  isShowSpinner: boolean = false;
  drpItemCdoes: string[] = [];
  drpSaleOrRents: SaleOrRentDTO[] = [];
  itemCodes: string;
  sale: string;
  subscription$: Subscription[] = [];
  constructor(
    private fb: FormBuilder,
    private text: TextValidators,
    private store: Store, private activatedRoute: ActivatedRoute,
    private numberValidators: NumberValidators,
    private Table: TableService,
    private saleOrderService: SaleorderService,
    private toastr: ToastrService,
    private orderDropdownService: OrderMasterDropdownService,
    private patientDropdownService: PatientMasterDropdownService
  ) { }

  ngOnInit(): void {
    // this.activatedRoute.paramMap.subscribe(response => {
    //   this.viewOrEdit = response.get('editOrView')
    // });
    const getPatientId = this.Table.getSelectedPatientId().subscribe(response => {
      this.patientId = response;
    });
    this.subscription$.push(getPatientId);

    this.authorizationForm = this.fb.group({
      txtAuthId: new FormControl("", [Validators.required]),
      drpAuthVisit: new FormControl(null),
      txtAuthAmount: new FormControl(""),
      txtStartDate: new FormControl(""),
      txtEndDate: new FormControl(""),
      drpCategory: new FormControl(null),
      drpItem: new FormControl(null),
      txtNote: new FormControl(""),
      chkCompleted: new FormControl(""),
      txtCompletedBy: new FormControl("", [this.text.isTextCheck]),
      drpPlan: new FormControl(null),
      // txtDays: new FormControl(""),
      txtPlan: new FormControl(null, [Validators.required]),
      // txtPlanName: new FormControl(null, [Validators.required]),
      rbtnAuth: new FormControl("", Validators.required),
      rbtnPolicyType: new FormControl(null),
      txtReason: new FormControl(null),
      txtUnits: new FormControl(""),
      txtUsed: new FormControl(""),
      txtSaleOrRent: new FormControl(""),
      txtExpired: new FormControl(""),
      txtItemCodes: new FormControl(""),
      chkFollowUp: new FormControl("")
    });
    //this.isStatusShow && this.authorizationForm.removeControl("rbtnAuth");
    //this.getAuthDataForPrimary();
    this.getDropdowns();
    if (this.viewOrEdit == "false") {
      this.authorizationForm.disable();
    } else {
      this.authorizationForm.enable();
    }
    if (this.authorizationForm.invalid || this.authorizationForm.dirty) {
      this.saveAuthorizationFormState.emit(this.authorizationForm);
    }


  }

  ngOnDestroy(): void {
    this.subscription$?.forEach((sub) => {
      sub && sub?.unsubscribe();
    });
  }
  updateCharacterCount() {
    // Ensure the text length does not exceed the maximum length
    if (this.notesText?.length > 5000) {
      this.notesText = this.notesText?.substr(0, 5000);
    }
  }
  //! Get Primary Auth Datas based on order Id
  getAuthDataForPrimary() {

  }


  setNoOfDaysFormToDate() {
    // this.authorizationForm
  }

  //! Load Order Dropdowns
  getDropdowns() {
    this.store.dispatch(new getOrder(this.orderId)).pipe(map(responses => {
      let saleOrderDetails = responses?.orderState.selectedOrder;
      let authData: AuthorizationDTO[] = [];
      this.patientId = saleOrderDetails?.patientId;
      if (this.patientId != null && this.patientId != undefined && this.patientId != "") {
        const getAllItemCode = this.saleOrderService.getAllItemCodesByPatientId(this.patientId).subscribe(response => {
          this.drpItemCdoes = response;
        });
        this.subscription$.push(getAllItemCode);
      }

      //! To get auth details based on primary, secondary & tertiary policies
      let authDatas = saleOrderDetails.patients.authorizations.primaryAuthorizations;
      authDatas?.plan === "Primary" && authData.push(authDatas);
      return authData;
    })).subscribe(primaryAuth => {
      this.authorizationForm.patchValue({
        txtAuthId: primaryAuth[0]?.authId,
        drpAuthVisit: primaryAuth[0]?.authVisits,
        txtAuthNo: primaryAuth[0]?.authNo,
        txtAuthAmount: primaryAuth[0]?.authAmount,
        txtStartDate: primaryAuth[0]?.startDate,
        txtEndDate: primaryAuth[0]?.endDate,
        drpCategory: primaryAuth[0]?.category,
        drpItem: primaryAuth[0]?.item,
        txtNote: primaryAuth[0]?.note,
        chkCompleted: primaryAuth[0]?.isCompleted == "Done" ? 1 : 0,
        txtCompletedBy: primaryAuth[0]?.completedBy,
        txtPlan: primaryAuth[0]?.plan,
        rbtnAuth: primaryAuth[0]?.approvalStatus,
        txtReason: primaryAuth[0]?.reason,
        txtUnits: primaryAuth[0]?.units,
        txtUsed: primaryAuth[0]?.used,
        txtSaleOrRent: primaryAuth[0]?.saleOrRent,
        txtExpired: primaryAuth[0]?.isExpired,
        txtItemCodes: primaryAuth[0]?.itemCodes,
        chkFollowUp: primaryAuth[0]?.isFollowUp
      });
    });
    let orderDropdownInput: OrderDropdowns[];
    orderDropdownInput = [
      OrderDropdowns.AuthVisits,
      OrderDropdowns.AuthCategories,
      // OrderDropdowns.MarketingReferrals,
      OrderDropdowns.TypeOfPlans,
      OrderDropdowns.PlanTypes
    ]
    const orderDropdown = this.orderDropdownService.getOrderDropdownsByInput(orderDropdownInput).subscribe((stateResponse) => {
      let response: OrderMasterDropdownDTO = stateResponse;
      this.drpAuthVisit = response.authVisits;
      this.drpCategory = response.authCategories;
      // this.drpMarketingRef = response.marketingReferrals;
      this.drpPlan = response.typeOfPlans;
      this.drpPlanType = response?.planTypes;
    })
    this.subscription$.push(orderDropdown);
    //
    if (this.orderId != "" && this.orderId != null && this.orderId != undefined) {
      const orderGet = this.saleOrderService.get(this.orderId).subscribe(response => {
        // this.store.dispatch(new getOrder(this.orderId)).subscribe(response => {
        this.drpAuthItem = response.items;
      });
      this.subscription$.push(orderGet);
    }
    let patientDropdownInput: PatientDropdowns[];
    patientDropdownInput = [PatientDropdowns.SaleOrRent]
    const patientDropdown = this.patientDropdownService.getPatientDropdownsByInput(patientDropdownInput).subscribe((response: PatientMasterDropdownDTO) => {
      this.drpSaleOrRents = response.saleOrRents;
    });
    this.subscription$.push(patientDropdown);
  }

  //! Get Order Details for save
  getOrderDetailsForSave(authStatus) {
    let orderDetails: SaleorderDTO;
    const orderGet = this.saleOrderService.get(this.orderId).subscribe(response => {
      // this.store.dispatch(new getOrder(this.orderId)).subscribe(response => {
      orderDetails = response;
      let savedData = {
        primaryAuthorizations: orderDetails?.patients?.authorizations?.primaryAuthorizations,
        secondaryAuthorizations: orderDetails?.patients?.authorizations?.secondaryAuthorizations,
        tertiaryAuthorizations: orderDetails?.patients?.authorizations?.tertiaryAuthorizations
      }
      return this.saveAuthorization(orderDetails, savedData, authStatus);
    }, err => {
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    })
    this.subscription$.push(orderGet);
  }

  //! Save Auth
  saveAuthorization(response, savedData, authStatus) {
    this.saveButtonHide = true;
    this.isShowSpinner = true;
    let typeOfPlanName = this.drpPlan.map(v => v.typeOfPlanName);
    if (this.authorizationForm.value.txtPlan === typeOfPlanName[0]) {
      savedData.primaryAuthorizations = {
        authId: this.authorizationForm.value.txtAuthId,
        authVisits: this.authorizationForm.value.drpAuthVisit,
        authNo: this.authorizationForm.value.txtAuthNo,
        authAmount: this.authorizationForm.value.txtAuthAmount,
        startDate: this.authorizationForm.value.txtStartDate,
        endDate: this.authorizationForm.value.txtEndDate,
        category: this.authorizationForm.value.drpCategory,
        item: this.authorizationForm.value.drpItem === "" ? [] : this.authorizationForm.value.drpItem,
        note: this.authorizationForm.value.txtNote,
        isCompleted: this.authorizationForm.value.chkCompleted === true || this.authorizationForm.value.chkCompleted === 1 ? "Done" : "Pending",
        completedBy: this.authorizationForm.value.txtCompletedBy,
        typeOfPolicy: this.authorizationForm.value.drpPlan,
        plan: this.authorizationForm.value.txtPlan,
        planName: this.authorizationForm.value.txtPlanName,
        approvalStatus: this.authorizationForm.value.rbtnAuth,
        approvedByWhom: "",
        approvedOn: "",
        reason: this.authorizationForm.value.txtReason,
        units: this.authorizationForm.value.txtUnits,
        used: this.authorizationForm.value.txtUsed,
        saleOrRent: this.authorizationForm.value.txtSaleOrRent,
        isExpired: +(this.authorizationForm.value.txtExpired) == null ? 0 : 1,
        itemCodes: this.authorizationForm.value.txtItemCodes,
        isFollowUp: +(this.authorizationForm.value.chkFollowUp) == null ? 0 : 1
      }
    } else if (this.authorizationForm.value.txtPlan === typeOfPlanName[1]) {
      savedData.secondaryAuthorizations = {
        authorizationId: this.authorizationId,
        authId: this.authorizationForm.value.txtAuthId,
        authVisits: this.authorizationForm.value.drpAuthVisit,
        authNo: this.authorizationForm.value.txtAuthNo,
        authAmount: this.authorizationForm.value.txtAuthAmount,
        startDate: this.authorizationForm.value.txtStartDate,
        endDate: this.authorizationForm.value.txtEndDate,
        category: this.authorizationForm.value.drpCategory,
        item: this.authorizationForm.value.drpItem === "" ? [] : this.authorizationForm.value.drpItem,
        note: this.authorizationForm.value.txtNote,
        isCompleted: this.authorizationForm.value.chkCompleted === true || this.authorizationForm.value.chkCompleted === 1 ? "Done" : "Pending",
        completedBy: this.authorizationForm.value.txtCompletedBy,
        typeOfPolicy: this.authorizationForm.value.drpPlan,
        plan: this.authorizationForm.value.txtPlan,
        planName: this.authorizationForm.value.txtPlanName,
        approvalStatus: this.authorizationForm.value.rbtnAuth,
        approvedByWhom: "",
        approvedOn: "",
        reason: this.authorizationForm.value.txtReason,
        units: this.authorizationForm.value.txtUnits,
        used: this.authorizationForm.value.txtUsed,
        saleOrRent: this.authorizationForm.value.txtSaleOrRent,
        isExpired: +(this.authorizationForm.value.txtExpired) == null ? 0 : 1,
        itemCodes: this.authorizationForm.value.txtItemCodes,
        isFollowUp: +(this.authorizationForm.value.chkFollowUp) == null ? 0 : 1,
      }
    } else if (this.authorizationForm.value.txtPlan === typeOfPlanName[2]) {
      savedData.tertiaryAuthorizations = {
        authorizationId: this.authorizationId,
        authId: this.authorizationForm.value.txtAuthId,
        authVisits: this.authorizationForm.value.drpAuthVisit,
        authNo: this.authorizationForm.value.txtAuthNo,
        authAmount: this.authorizationForm.value.txtAuthAmount,
        startDate: this.authorizationForm.value.txtStartDate,
        endDate: this.authorizationForm.value.txtEndDate,
        category: this.authorizationForm.value.drpCategory,
        item: this.authorizationForm.value.drpItem === "" ? [] : this.authorizationForm.value.drpItem,
        note: this.authorizationForm.value.txtNote,
        isCompleted: this.authorizationForm.value.chkCompleted === true || this.authorizationForm.value.chkCompleted === 1 ? "Done" : "Pending",
        completedBy: this.authorizationForm.value.txtCompletedBy,
        typeOfPolicy: this.authorizationForm.value.drpPlan,
        plan: this.authorizationForm.value.txtPlan,
        planName: this.authorizationForm.value.txtPlanName,
        approvalStatus: this.authorizationForm.value.rbtnAuth,
        approvedByWhom: "",
        approvedOn: "",
        reason: this.authorizationForm.value.txtReason,
        units: this.authorizationForm.value.txtUnits,
        used: this.authorizationForm.value.txtUsed,
        saleOrRent: this.authorizationForm.value.txtSaleOrRent,
        isExpired: +(this.authorizationForm.value.txtExpired) == null ? 0 : 1,
        itemCodes: this.authorizationForm.value.txtItemCodes,
        isFollowUp: +(this.authorizationForm.value.chkFollowUp) == null ? 0 : 1,
      }
    }
    let insAuthorization: SaleorderDTO;
    if (authStatus === "Disapproved" && (response?.patients?.insuranceVerifications?.primaryInsuranceVerifies?.isPercertRequired === 1
      || response?.patients?.insuranceVerifications?.secondaryInsuranceVerifies?.isPercertRequired === 1 ||
      response?.patients?.insuranceVerifications?.tertiaryInsuranceVerifies?.isPercertRequired === 1)) {
      insAuthorization = {
        defaultClaimId: response?.defaultClaimId,
        orders: {
          ...response.orders, isCompleted: "Shipping Disapproved"
        },
        clinicals: response?.clinicals,
        insurances: response?.insurances,
        items: response.items,
        patients: {
          personals: response.patients.personals,
          addresses: response.patients.addresses,
          contacts: response.patients.contacts,
          clinicals: response.patients.clinicals,
          insurances: response.patients.insurances,
          notes: response.patients.notes,
          policies: response.patients.policies,
          defaultPatientId: response.patients.defaultPatientId,
          insuranceVerifications: response.patients.insuranceVerifications,
          authorizations: {
            primaryAuthorizations: savedData.primaryAuthorizations,
            secondaryAuthorizations: savedData.secondaryAuthorizations,
            tertiaryAuthorizations: savedData.tertiaryAuthorizations
          },
          patientDocuments: response.patients.patientDocuments,
          organizationUnitId: response.patients.organizationUnitId,
          isAutoIntake: response.patients.isAutoIntake,
          autoIntakeDocumentDetails: response.patients.autoIntakeDocumentDetails,
          chartId: response.patients.chartId,
          billingDetails: response.patients.billingDetails,
          patientLock: response.patients.patientLock,
          fileId: response?.patients?.fileId,
          // isSorted:response.patients.isSorted
        },

        saleorderId: response?.saleorderId,
        orderStatus: "Shipping Disapproved",
        organizationUnitId: response?.organizationUnitId,
        currentStage: response?.currentStage,
        currentStageStatus: response?.currentStageStatus,
        claimId: response.claimId,
        patientId: response.patientId,
        isShippingApprove: response?.isShippingApprove,
        isCashPayment: response.isCashPayment,
        orderProcessStatus: response.orderProcessStatus,
        patientLedgerReason: response.patientLedgerReason,
        documentReason: response.documentReason,
        paymentTransactionDetails: response.paymentTransactionDetails,
        payPalMailResendCount: response?.payPalMailResendCount,
        payPalPaymentMethodType: response?.payPalPaymentMethodType,
        payPalPaymentStatus: response?.payPalPaymentStatus,
        shippingCheckList: response?.shippingCheckList,
        orderBilling: response?.orderBilling,
        deliveryTicket: response?.deliveryTicket,
        authType: response?.authType

      }
    } else {
      let status;
      if (authStatus === "Disapproved") {
        status = { ...response.orders, isCompleted: "Done" }
      } else {
        status = response.orders
      }
      insAuthorization = {
        defaultClaimId: response?.defaultClaimId,
        orders: status,
        clinicals: response?.clinicals,
        insurances: response?.insurances,
        items: response.items,
        patients: {
          personals: response.patients.personals,
          addresses: response.patients.addresses,
          contacts: response.patients.contacts,
          clinicals: response.patients.clinicals,
          insurances: response.patients.insurances,
          notes: response.patients.notes,
          policies: response.patients.policies,
          defaultPatientId: response.patients.defaultPatientId,
          insuranceVerifications: response.patients.insuranceVerifications,
          authorizations: {
            primaryAuthorizations: savedData.primaryAuthorizations,
            secondaryAuthorizations: savedData.secondaryAuthorizations,
            tertiaryAuthorizations: savedData.tertiaryAuthorizations
          },
          patientDocuments: response.patients.patientDocuments,
          organizationUnitId: response.patients.organizationUnitId,
          isAutoIntake: response.patients.isAutoIntake,
          autoIntakeDocumentDetails: response.patients.autoIntakeDocumentDetails,
          chartId: response.patients.chartId,
          billingDetails: response.patients.billingDetails,
          patientLock: response.patients.patientLock,
          fileId: response?.patients?.fileId,
          // isSorted:response.patients.isSorted
        },
        saleorderId: response?.saleorderId,
        orderStatus: response.orderStatus,
        organizationUnitId: response?.organizationUnitId,
        currentStage: response?.currentStage,
        currentStageStatus: response?.currentStageStatus,
        claimId: response.claimId,
        patientId: response.patientId,
        isShippingApprove: response?.isShippingApprove,
        isCashPayment: response.isCashPayment,
        orderProcessStatus: response.orderProcessStatus,
        patientLedgerReason: response.patientLedgerReason,
        documentReason: response.documentReason,
        paymentTransactionDetails: response.paymentTransactionDetails,
        payPalMailResendCount: response?.payPalMailResendCount,
        payPalPaymentMethodType: response?.payPalPaymentMethodType,
        payPalPaymentStatus: response?.payPalPaymentStatus,
        claimHistoryStatus: response.claimHistoryStatus,
        patientLedgerStatus: response.patientLedgerStatus,
        shippingCheckList: response?.shippingCheckList,
        orderBilling: response?.orderBilling,
        deliveryTicket: response?.deliveryTicket,
        authType: response?.authType


      }
    }
    this.orderId !== "" && this.store.dispatch(new updateOrder(this.orderId, insAuthorization)).subscribe(response => {
      // Swal.fire({ title: 'Success', html: 'Saved Successfully', icon: 'success', timer: 3000, timerProgressBar: true })
      this.toastr.success('Saved Successfully','Success')
      // this.clearData();
      this.saveAuthorizationFormState.emit(this.authorizationForm.value);
      this.saveButtonHide = false;
      this.isShowSpinner = false;
    }, err => {
      this.saveButtonHide = false;
      this.isShowSpinner = false;
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    });
  }

  //! Get Disapprove Reason based on radio button changes
  onChangeGetReason($event: MatRadioChange) {
    if ($event?.value === "Disapproved") {
      this.Show = true
    } else {
      this.Show = false
    }
  }

  //! Get Auth Datas based on Radio button changes
  onChangeGetAuth($event: MatRadioChange) {
    const orderGet = this.saleOrderService.get(this.orderId).pipe(map(responses => {
      // this.store.dispatch(new getOrder(this.orderId)).pipe(map(responses => {
      let selectedPatient = responses?.patients.authorizations;
      let verifyData: any[] = [];
      let verifyData1: any[] = [];
      let verifyData2: any[] = [];
      let verifyDatas = selectedPatient?.primaryAuthorizations;
      let verifyDatas1 = selectedPatient?.secondaryAuthorizations;
      let verifyDatas2 = selectedPatient?.tertiaryAuthorizations;
      if ($event?.value === 'Primary') {
        $event?.value === 'Primary' && verifyDatas.plan === "Primary" && verifyData.push(verifyDatas)
        return verifyData
      }
      if ($event?.value === 'Secondary') {
        $event?.value === 'Secondary' && verifyDatas1?.plan === "Secondary" && verifyData1.push(verifyDatas1)
        return verifyData1
      }
      if ($event?.value === 'Tertiary') {
        $event?.value === 'Tertiary' && verifyDatas2?.plan === "Tertiary" && verifyData2.push(verifyDatas2)
        return verifyData2
      }
    })).subscribe((response) => {

      if (response && response[0]) {
        this.authorizationForm.patchValue({
          txtAuthId: response[0]?.authId,
          drpAuthVisit: response[0]?.authVisits,
          txtAuthNo: response[0]?.authNo,
          txtAuthAmount: response[0]?.authAmount,
          txtStartDate: response[0]?.startDate,
          txtEndDate: response[0]?.endDate,
          drpCategory: response[0]?.category,
          drpItem: response[0]?.item,
          txtNote: response[0]?.note,
          chkCompleted: response[0]?.isCompleted == "Done" ? 1 : 0,
          txtCompletedBy: response[0]?.completedBy,
          txtPlan: response[0]?.plan,
          rbtnAuth: response[0]?.approvalStatus,
          txtUnits: response[0]?.units,
          txtUsed: response[0]?.used,
          txtSaleOrRent: response[0]?.saleOrRent,
          txtExpired: response[0]?.isExpired,
          txtItemCodes: response[0]?.itemCodes,
          chkFollowUp: response[0]?.isFollowUp
        });
      } else {
        this.authorizationForm.patchValue({
          txtAuthId: "",
          drpAuthVisit: "",
          txtAuthNo: "",
          txtAuthAmount: "",
          txtStartDate: "",
          txtEndDate: "",
          drpCategory: "",
          drpItem: "",
          txtNote: "",
          chkCompleted: 0,
          txtCompletedBy: "",
          txtPlan: "",
          rbtnAuth: "",
          txtUnits: "",
          txtUsed: "",
          txtSaleOrRent: "",
          txtExpired: 0,
          txtItemCodes: "",
          chkFollowUp: 0
        });
      }

    });
  }

  valueChangeLoadDropdown(type: MatSelectChange) {
  }

  //! Reset Authorization Form
  clearData() {
    this.authorizationForm.reset();
    this.authorizationForm.markAsUntouched();
    this.authorizationForm.updateValueAndValidity();
  }

  //! Clear dropdown
  clearAuthVisit() {
    this.authVisit = '';
    this.authorizationForm.patchValue({
      drpAuthVisit: ''
    });
  }
  clearCategory() {
    this.category = '';
    this.authorizationForm.patchValue({
      drpCategory: ''
    });
  }
  clearItem() {
    this.item = '';
    this.authorizationForm.patchValue({
      drpItem: ''
    });
  }
  clearPlan() {
    this.plan = '';
    this.authorizationForm.patchValue({
      txtPlan: ''
    });
  }
  clearSale() {
    this.sale = '';
    this.authorizationForm.patchValue({ txtSaleOrRent: "" });
  }
  clearItemCodes() {
    this.itemCodes = '';
    this.authorizationForm.patchValue({ txtItemCodes: "" });
  }
}
