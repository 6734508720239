import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatDatepicker } from '@angular/material/datepicker';
import { UserByRoleDTO } from 'projects/admin/src/app/admin-proxy/platform-app-management/identity';
import { UserService } from 'projects/admin/src/app/admin-proxy/platform-app-management/rcm/platform-management/user';
import { defaultGuid } from 'projects/shared/src/app/enums/allenums.enum';
import { Subscription } from 'rxjs';
import Swal from 'sweetalert2';
import { DateAdapter } from '@angular/material/core';
@Component({
  selector: 'app-text-conversation-master',
  templateUrl: './text-conversation-master.component.html',
  styleUrls: ['./text-conversation-master.component.scss']
})
export class TextConversationMasterComponent implements OnInit {
  // selectedRow: number = null;
  roleName:string;
  fmTextConversation: FormGroup;
  seletedCSRValue: string = defaultGuid;
  isUserLoading:boolean=false;
  lstFilterUsers: UserByRoleDTO[] = [];
  userListAPI$: Subscription;
  drpUsertype: any;
  UserTypeControl = new FormControl();
  dateValue: any;
  selectedDate: any;
  selectedMonth: number = 0;
  selectedYear: number = 0;
  maxDate: Date;
  userId:any;
  isRowClicked: boolean = false;
  @ViewChild('txtDateValue') txtDateValue: MatDatepicker<Date>;
  constructor(
    private userService: UserService,
    private fb: FormBuilder,
    private dateAdapter: DateAdapter<Date>,
  ) { this.maxDate = this.dateAdapter.addCalendarYears(new Date(), 0);}

  ngOnInit(): void {
    this.fmTextConversation = this.fb.group({
      drpUsertype: new FormControl(''),
      txtDateValue: new FormControl(''),
    });

       //Default DatePicker Inputs
       this.dateValue =
       new Date().toLocaleString('default', { month: 'short' }) +
       '/' +
       new Date().getFullYear();
     this.selectedDate =
       new Date().getMonth() + 1 + '/' + new Date().getFullYear();
     this.selectedMonth = new Date().getMonth() + 1;
     this.selectedYear = new Date().getFullYear();

    this.fmTextConversation.patchValue({
      txtDateValue: this.dateValue,
    });

    this.getUserByTenant('');
    this.userId = localStorage.getItem("userId") ?? "";
    this.roleName = localStorage.getItem("roleName") ?? "";
    this.fmTextConversation.patchValue({ drpUsertype: this.userId });

    //CSR Drop Down Search
    this.UserTypeControl.valueChanges.subscribe((searchTerm) => {
    searchTerm = searchTerm.toLowerCase();
    this.lstFilterUsers = this.drpUsertype.filter((dx) =>
      dx.userDesignation.toLowerCase().includes(searchTerm)
    );
  });
  }


  //GET CSR Drop Down
  getUserByTenant(search:string) {
    this.userListAPI$?.unsubscribe();
    this.isUserLoading=true;
    const csrUsersList = this.userService.getCSRUsers(search).subscribe(
      (response) => {
        this.isUserLoading=false;
        this.lstFilterUsers = response;
        this.drpUsertype = this.lstFilterUsers;
      },
      (err) => {
        this.isUserLoading=false;
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      });
      this.userListAPI$ = csrUsersList;
  }

  UserSearch(value){
    this.getUserByTenant(value);
    }

  openDatePicker() {
    this.txtDateValue.open();
  }

  chosenMonthHandler(normalizedMonth: any, datepicker: any) {
    datepicker.close();
    this.dateValue =
      normalizedMonth.toLocaleString('default', { month: 'short' }) +
      '/' +
      normalizedMonth.getFullYear();
    this.selectedDate =
      normalizedMonth.getMonth() + 1 + '/' + normalizedMonth.getFullYear();
    this.selectedMonth = normalizedMonth.getMonth() + 1;
    this.selectedYear = normalizedMonth.getFullYear();
  }
// selectRow(rowNumber: number): void {
//     if (this.selectedRow === rowNumber) {
//         this.selectedRow = null; // Deselect the row if it's already selected
//     } else {
//         this.selectedRow = rowNumber; // Select the clicked row
//     }
// }
  }
