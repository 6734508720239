import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-app-order-worksheet-mdl',
  templateUrl: './app-order-worksheet-mdl.component.html',
  styleUrls: ['./app-order-worksheet-mdl.component.scss']
})
export class AppOrderWorksheetMdlComponent implements OnInit {

  loadTable: boolean = true;
  workSheetData = [
    {
        orderNo: '12345',
        chartNo: '67890',
        patientName: 'John Doe',
        suppliesDate: '2023-10-01',
        insuranceGroup: 'Group A',
        primaryInsuranceName: 'Insurance Co.',
        orderStatusDesc: 'Pending',
        lastMaskDate: '2023-09-15',
        points: 10
    },
    {
        orderNo: '54321',
        chartNo: '09876',
        patientName: 'Jane Smith',
        suppliesDate: '2023-10-02',
        insuranceGroup: 'Group B',
        primaryInsuranceName: 'Health Inc.',
        orderStatusDesc: 'Completed',
        lastMaskDate: '2023-09-20',
        points: 20
    }
];

  constructor() { }

  ngOnInit(): void {
  }

}
