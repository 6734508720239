<!-- <div class="row mt-2"> -->
<br />
<div class="card card-body">
  <!-- <div class="list-box"> -->
  <form [formGroup]="claimHistoryForm">
    <div class="col-lg-12">
      <h4 class="customThemeClass"><b>Claim History</b></h4>
      <!-- <div class="row">
        <div class="col-8">
          <span class="mr-2"><b>Status :</b></span>
          <button mat-button class="btn btn-sm red-dot mr-2"> <span> Hold </span></button>
          <button mat-button class="btn btn-sm green-dot mr-2"> <span> Ready </span></button>
          <button mat-button class="btn btn-sm dark-dot mr-2"> <span> Processed </span></button>
          <button mat-button class="btn btn-sm yellow-dot mr-2"> <span> Crossed-over </span></button>
          <button mat-button class="btn btn-sm blue-dot mr-2"> <span> Auth No </span></button>
          <button mat-button class="btn btn-sm pink-dot mr-2"> <span> >55days </span></button>
        </div>
        <div class="col-4 text-right">
          <mat-checkbox class="ml-2">Show Int</mat-checkbox>
          <mat-checkbox class=" ml-2">Show Paid</mat-checkbox>
        </div>
      </div> -->
    </div>
    <div class="col-lg-12 table-responsive">
      <app-table [strPageType]="strPageType" [isLoading]="isLoading" (claimButton)="claimInfo($event)"
        (viewButton)="ViewClaim($event)">
      </app-table>
    </div>
    <!-- <div class="row"> -->

    <!-- <div class="col-12" [hidden]='true'>
      <div class="row">
        <div class="col-2">
          <h4 class="customThemeClass"><b> Shipping Status <span class="asterisk">*</span></b></h4>
        </div>
        <div class="col-10">
          <mat-checkbox class="ml" formControlName="chkShippingApproved">Shipping Approved</mat-checkbox>
        </div>
      </div>
    </div> -->
    <!--  -->
    <mat-form-field *ngIf="show" class="col-3">
      <mat-label> Reason</mat-label>
      <input autocomplete="new-txtReason" matInput maxlength="40" formControlName="txtReason" type="text" />
    </mat-form-field>
    <div class="col-12">
      <div class="row">
        <div class="col-2">
          <h4 class="customThemeClass"><b>Status <span class="asterisk">*</span></b></h4>
        </div>
        <div class="col-7">
          <mat-radio-group (change)="onChangeGetInsuranceVerify($event)" formControlName="rbtnClaimHistory">
            <mat-radio-button class="mr-2" value="Approved">Approve</mat-radio-button>
            <mat-radio-button class="mr-2" value="Disapproved">Disapprove</mat-radio-button>
          </mat-radio-group>
        </div>



        <div class="col-sm-3">
          <div class="text-lg-right">
            <button type="submit" mat-button class="buttonColor mb-2 mr-2"
              [disabled]="saveButtonHide||claimHistoryForm.invalid"
              (click)="getPatientDetailsForSave(this.claimHistoryForm.value.rbtnClaimHistory)">Save
              <mat-icon *ngIf='isShowSpinner'>
                <mat-spinner class="spinner-border spinner-border-sm" diameter="20"> </mat-spinner>
              </mat-icon>
            </button>

          </div>
        </div>
      </div>
      <!-- <div class="col-1">
          <h4 class="customThemeClass"><b>Status <span class="asterisk">*</span></b></h4>

        </div>
        <div class="col-8 ">

          <mat-radio-group formControlName="rbtnClaimHistory" class="col-sm-7 radio mt-2 mr-2 txt-left">
            <mat-radio-button class="mr-2" value="Approved">Approve</mat-radio-button>
            <mat-radio-button class="mr-2" value="Disapproved">Disapprove</mat-radio-button>

          </mat-radio-group>
        </div>
        <div class="col-sm-3">
          <div class="text-lg-right">
            <button type="submit" mat-button class="buttonColor mb-2 mr-2" [disabled]="claimHistoryForm.invalid"
              (click)="getPatientDetailsForSave()">Save</button>

          </div>
        </div> -->

    </div>



    <!-- </div> -->
    <!-- <div class="row">
    <div class="col-12">
    <form [formGroup]="claimHistoryForm">

  </form>
  </div>
  </div> -->
  </form>
  <!-- </div> -->
</div>
<!-- </div> -->