<div class="container-fluid">
  <div class="row bg">
    <div class="col-12">
      <div class="d-flex align-item-center justify-content-between m-0">
        <h3 class="font-weight-bolder mb-2 mt-2">
          <b class="customThemeClass">List / Shipping Dept / Requisition Viewer</b>
        </h3>
      </div>
    </div>
  </div>
  <div class="col-lg-12">

    <div class="card card-body px-0 py-3">
      <div class="col-12 content table-responsive">
        <div class="d-flex justify-content-center" *ngIf="!isLoadData">
          <img class="img-responsive pb-2 mr-1" src="assets/gifs/Infinity-loader.gif" />
        </div>
        <div class="table-responsive">
          <table datatable *ngIf="isLoadData" [dtOptions]="dtInboundOptions"
            class="table row-border hover w-100 display">
            <thead>
              <tr>
                <th [matTooltip]="'Options'"><mat-icon>apps</mat-icon></th>
                <th [matTooltip]="'Requisition No'" class="text-right">Requisition No</th>
                <th [matTooltip]="'Total($)'" class="text-right">Total($)</th>
                <th [matTooltip]="'Req Location'">Req Location</th>
                <th [matTooltip]="'Shipping Method'">Shipping Method</th>
                <th [matTooltip]="'Status'">Status</th>
                <th [matTooltip]="'Requisition Created Date'">Requisition Created Date</th>
                <th [matTooltip]="'Aging Days'">Aging Days</th>
                <th [matTooltip]="'CSR'">CSR</th>
                <th class="text-right" [matTooltip]="'Modified Date'">Modified Date</th>
                <th [matTooltip]="'Modified By'">Modified By</th>
                <th [matTooltip]="'Created By'">Created By</th>
                <th class="text-right" [matTooltip]="'Created Date'" >Created Date</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let data of loadTableValue" [ngClass]="
                    tblItemSelectedRowId === data.reqId ? 'highlighted' : ''
                  " (click)="tblItemSelectedRow(data)">
                <td> <i class="fa fa-pencil pointer editBtnColor" (click)="ViewRequisition(data)"
                    matTooltip="Click To Edit" [ngClass]="{ 'white-icon': tblItemSelectedRowId === data.reqId }"></i>
                </td>
                <td class="text-right" [matTooltip]="data?.sortNumber || ''">
                  {{ data?.sortNumber }} </td>
                <td [matTooltip]="data?.extendedCost || '-'" class="text-right">{{ toFixed(data.extendedCost)}}</td>
                <td [matTooltip]="data?.requestLocationName || '-'">{{ data?.requestLocationName }}</td>
                <td [matTooltip]="data?.shippingMethod || '-'">{{data.shippingMethod}} </td>
                <td [matTooltip]="data?.status || '-'"> {{data?.status}} </td>

                <td [matTooltip]="
                  data?.requisitionDate
                    ? (data?.requisitionDate | date : 'MM/dd/yyyy' : 'en_US')
                    : '-'
                "> {{data.requisitionDate | date : 'MM/dd/yyyy ' : 'en_US'}}</td>
                <td [matTooltip]="data?.reqAgingDays || '-'">{{ data?.reqAgingDays }}</td>
                <td [matTooltip]="data?.requestedUser || '-'">{{ data?.requestedUser }}</td>

                <!-- <td class="text-right" [matTooltip]="
                data?.lastModifiedDate
                  ? (data?.lastModifiedDate | date : 'MM/dd/yyyy h:mm a' : 'en_US')
                  : '-'
              ">{{data.lastModifiedDate ? (data.lastModifiedDate | date : 'MM/dd/yyyy h:mm a' : 'en_US') : '-'}} </td> -->

                <td class="text-center">
                  <span [matTooltip]="data?.lastModifiedDate">
                    {{ data?.lastModifiedDate }}</span>
                </td>

                <td [matTooltip]="data?.modifiedBy || '-'">{{data?.modifiedBy}}</td>
                <td [matTooltip]="data?.createdBy || '-'">{{data?.createdBy}}</td>

                <td class="text-center">
                  <span [matTooltip]="data?.createdDate">
                    {{ data?.createdDate }}</span>
                </td>

                <!-- <td class="text-right" [matTooltip]="
                data?.createdDate
                  ? (data?.createdDate | date : 'MM/dd/yyyy h:mm a' : 'en_US')
                  : '-'
              ">{{data.createdDate ? (data.createdDate | date : 'MM/dd/yyyy h:mm a' : 'en_US') : '-'}} </td> -->

                <!-- <td > -->
                <!-- <mat-select  [(value)]="data.shippingMethodId" (selectionChange)="updateRequisition($event)" #singleSelect>
                    <mat-option
                      [value]="option.id"
                      *ngFor="let option of ltShippingMethods"
                    >
                      {{ option.description }}
                    </mat-option>
                  </mat-select> -->
                <!-- </td> -->
                <!-- <td >  -->
                <!-- <mat-form-field>
                      <input
                        matInput
                        name="requisitionDate"
                        [(ngModel)]="data.requisitionDate"
                        (focusout)="updateRequisition($event)"
                        #inputRequisitionDate
                      />

                    </mat-form-field> -->
                <!-- </td> -->
                <!-- <td >  -->
                <!-- <mat-select  [(value)]="data.requisitioStatusId" (selectionChange)="updateRequisition($event)"  #singleSelect>
                      <mat-option
                        [value]="option.id"
                        *ngFor="let option of lststatus"
                      >
                        {{ option.requisitionStatus }}
                      </mat-option>
                    </mat-select> -->
                <!-- </td> -->
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>

  <div class="col-12 mt-2">
    <div class="card card-body">
      <form class="mt-0" [formGroup]="requisitionForm">
        <div class="row">
          <mat-form-field class="col-2 dd-text">
            <mat-label class="dd-text">Special Instructions</mat-label>
            <textarea matInput formControlName="txtSpecialInstructions" placeholder="Enter Special Instructions"
              rows="2"></textarea>
          </mat-form-field>

          <mat-form-field class="col-2 dd-text">
            <mat-label class="dd-text"> Confirmation No</mat-label>
            <input maxlength="40" autocomplete="new-txtTracking" matInput formControlName="txtTracking"
              placeholder="Enter Confirmation No" type="text" />
          </mat-form-field>
          <mat-form-field class="col-2 dd-text">
            <mat-label>Packed By</mat-label>
            <mat-select [(ngModel)]="defaultPackedBy" maxlength="40" formControlName="drpUserList" #singleSelect>
              <mat-option>
                <ngx-mat-select-search placeholderLabel="Search" noEntriesFoundLabel="No Matches found"
                  formControlName="txtUserList"></ngx-mat-select-search>
              </mat-option>
              <mat-option *ngFor="let user of filteredUserList | async" [value]="user.id">
                {{ user?.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field class="col-2 dd-text">
            <mat-label>Verified By</mat-label>
            <mat-select [(ngModel)]="defaultVerifiedBy" maxlength="40" formControlName="drpVerifyedBy" #singleSelect>
              <mat-option>
                <ngx-mat-select-search placeholderLabel="Search" noEntriesFoundLabel="No Matches found"
                  formControlName="txtUserList1"></ngx-mat-select-search>
              </mat-option>
              <mat-option *ngFor="let user of filteredUserList1 | async" [value]="user.id">
                {{ user?.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <div class="mr-2 ml-2">
            <button mat-raised-button class="btn-sm mr-1 ml-1 buttonColor" (click)="orderDirect()" [disabled]="isrequisitionForm != true || !(requisitionForm?.value.drpUserList && requisitionForm?.value.drpVerifyedBy)">
              <img class="img-responsive pr-1 mb-0 cursor" src="assets/Requisition Icons/approved.png" alt="" height="17" />
              Ordered Direct
            </button>
          </div>
          <div class="mr-2 ml-2">
            <button mat-raised-button class="btn-sm mr-1 buttonColor" (click)="runTransfer()" [disabled]="  !(
                        requisitionForm?.value.txtTracking &&
                       requisitionForm?.value.drpUserList &&
                       requisitionForm?.value.drpVerifyedBy
                     )
                    ">
                    <img class="img-responsive pr-1 mb-1 cursor" src="assets/Requisition Icons/runtransfer.png" alt="" height="17" />
              Run Transfer
            </button>
          </div>
          <div class="mr-2 ml-2">
            <button [disabled]="isrequisitionForm != true || isPackingSlip != true" (click)="viewPackingListPDF()"
            mat-raised-button class="btn-sm buttonColor ml-1 mr-1">
              <img class="img-responsive pr-1 mb-1 cursor" src="assets/Requisition Icons/packing.png" alt="" height="17" />
              Packing List
            </button>
          </div>
        </div>
        <div class="col-12 dd-text">
          <div class="row">
            <!-- <button
              mat-button
              (click)="ViewRequisition()"
              class="btn-sm buttonColor ml-1 mr-1"
              [disabled]="isrequisitionForm != true"
            >
              Edit
            </button> -->

            <!-- <button mat-button class="btn-sm mr-1 buttonColor" (click)="runTransfer()" [disabled]="  !(
               requisitionForm?.value.txtTracking &&
              requisitionForm?.value.drpUserList &&
              requisitionForm?.value.drpVerifyedBy
            )
          ">
              Run Transfer
            </button>

            <button
              mat-button
              class="btn-sm mr-1 buttonColor"
              (click)="orderDirect()"
              [disabled]="isrequisitionForm != true||
                !(
                  requisitionForm?.value.drpUserList &&
                  requisitionForm?.value.drpVerifyedBy
                )
              "
            >
              Ordered Direct
            </button>

            <button
              mat-button
              class="btn-sm buttonColor ml-1 mr-1"
              [disabled]="1 >= reqItemCount"
              (click)="splitRequisition()"
            >
              Split
            </button>

            <button   [disabled]="isrequisitionForm != true || isPackingSlip != true" (click)="viewPackingListPDF()" mat-button class="btn-sm buttonColor ml-1 mr-1">
              Packing List
            </button> -->
          </div>
        </div>
      </form>
    </div>
  </div>

  <div class="col-lg-12 mt-1">
    <div class="card card-body px-0 py-5 ">
        <div class="col-6">
          <div >
            <button
            mat-raised-button
            (click)="splitRequisition()"
            class="btn-sm buttonColor custombtn cursors "
            [disabled]="1 >= reqItemCount"

          ><img class="img-responsive pr-1 mb-1 cursors" src="assets/Requisition Icons/split.png" alt="" height="14" />
            Split
          </button>
          </div>
      </div>
      <div class="col-12 content table-responsive">
        <div class="d-flex justify-content-center" *ngIf="!isLoad">
          <img class="img-responsive pb-2 mr-1" src="assets/gifs/Infinity-loader.gif" />
        </div>
        <div class="table-responsive">
          <table datatable *ngIf="isLoad" [dtOptions]="dtRequistionOptions" class="row-border hover w-100 display">
            <thead>
              <tr>
                <th [matTooltip]="'Options'"><mat-icon>apps</mat-icon></th>
                <!-- <th>Options</th> -->
                <th [matTooltip]="'Prod Code'">Prod Code</th>
                <th [matTooltip]="'Description'">Description</th>
                <th [matTooltip]="'WH Bin Location'">WH Bin Location</th>
                <th [matTooltip]="'Serial Lot No'">Serial Lot No</th>
                <th [matTooltip]="'Quantity'" class="text-right">Quantity</th>
                <th [matTooltip]="'Packed By'">Packed By</th>
                <th [matTooltip]="'Verified By'">Verified By</th>
                <th [matTooltip]="'Tracking'">Tracking</th>
                <th [matTooltip]="'Back Ordered'">Back Ordered</th>
                <th [matTooltip]="'Date Shipping'">Date Shipping</th>
                <th [matTooltip]="'Unit Cost'" class="text-right">Unit Cost</th>
                <th [matTooltip]="'Extended'" class="text-right">Extended</th>
                <th [matTooltip]="'Shipment comment'">Shipment comment</th>
                <th [matTooltip]="'Process'">Process</th>
                <th [matTooltip]="'Process On'">Process On</th>
                <th [matTooltip]="'WH Inventory'" class="text-right">WH Inventory</th>
                <th [matTooltip]="'WH Orders'" class="text-right">WH Orders</th>
                <th [matTooltip]="'WH Availability'">WH Availability</th>
                <!-- <th>Order Status</th>
                      <th>CSR</th> -->
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let data of loadRequistionTable">
                <!-- <td>
                    <mat-checkbox
                      [(ngModel)]="data.isChecked"
                      (change)="getReqId($event, data.requestItemId)"
                    > -->
                <td>
                  <mat-checkbox [(ngModel)]="data.isChecked" (change)="getReqId($event, data.requestItemId)">
                  </mat-checkbox>
                </td>
                <td [matTooltip]="data?.productCode || '-'">{{ data?.productCode }}</td>
                <td [matTooltip]="data?.productDescription || '-'">{{ data?.productDescription }}</td>
                <td [matTooltip]="data?.binLocation || '-'">{{ data?.binLocation }}</td>
                <td [matTooltip]="data?.serialLotNumber || '-'">{{ data?.serialLotNumber }}</td>
                <!-- <td>{{data?.serialLotNumber}}</td> -->
                <td [matTooltip]="data?.thisOrder || '-'" class="text-right">{{ data?.thisOrder }}</td>
                <td [matTooltip]="data?.packedByName || '-'">{{data?.packedByName || '-' }}</td>
                <td [matTooltip]="data?.verifiedByName || '-'">{{ data?.verifiedByName||'-' }}</td>
                <!-- <td [matTooltip]="data?.tracking || '-'">{{ data?.tracking }}</td> -->
                <td
                 class="text-right"
               >

               <!-- href="https://tools.usps.com/go/TrackConfirmAction_input?strOrigTrackNum={{data.tracking}}" -->
               <a
               href="https://www.ups.com/track?loc=en_US&requester=QUIC&tracknum={{data.tracking}}/"
               target="_blank" class="preview-link"
               [matTooltip]="'Click here to Track'">
                   {{
                     data.tracking
                   }}
                 </a>
               </td>
                <td [matTooltip]="
                  data?.backOrderSince
                    ? (data?.backOrderSince | date : 'MM/dd/yyyy h:mm a' : 'en_US')
                    : '-'
                ">
                  {{
                  data?.backOrderSince == null
                  ? "-"
                  : (data?.backOrderSince
                  | date : "MM/dd/yyyy h:mm a" : "en_US")
                  }}
                </td>
                <td [matTooltip]="
                  data?.shippingDate
                    ? (data?.shippingDate | date : 'MM/dd/yyyy h:mm a' : 'en_US')
                    : '-'
                ">
                  {{
                  data?.shippingDate == "0001-01-01T00:00:00Z"
                  ? "-"
                  : (data?.shippingDate
                  | date : "MM/dd/yyyy h:mm a" : "en_US")
                  }}
                </td>
                <td [matTooltip]="data?.unitCost || '-'" class="text-right">{{ data?.unitCost ? data?.unitCost : "-" }}
                </td>
                <td [matTooltip]="data?.extendedCost || '-'" class="text-right">{{ data?.extendedCost ?
                  data?.extendedCost : "-" }}</td>
                <td [matTooltip]="data?.shipmentComment || '-'">{{ data?.shipmentComment }}</td>
                <td [matTooltip]="data?.processedBy || '-'">{{ data?.processedBy }}</td>
                <td [matTooltip]="
                  data?.processedDate
                    ? (data?.processedDate | date : 'MM/dd/yyyy h:mm a' : 'en_US')
                    : '-'
                ">
                  {{
                  data?.processedDate | date : "MM/dd/yyyy h:mm a" : "en_US"
                  }}
                </td>
                <td [matTooltip]="data?.whInventory || '-'" class="text-right">{{ data?.whInventory }}</td>
                <td [matTooltip]="data?.whOrder || '-'" class="text-right">{{ data?.whOrder }}</td>
                <td [matTooltip]="data?.whAvailability || '-'">{{ data?.whAvailability }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <!-- </div> -->
  </div><br><br>
</div>
