    <table id="tblFaxFinderList" datatable [dtOptions]="patientSearchOptions" class="tbl hover row-border hover w-100">
      <thead>
        <tr>
          <th><mat-icon>reorder</mat-icon></th>
          <th><mat-icon>apps</mat-icon></th>
          <th [matTooltip]="'Chart No'">Chart No</th>
          <th [matTooltip]="'Patient Name'">Patient Name</th>
          <th [matTooltip]="'DOB'" class="text-center">DOB</th>
          <th [matTooltip]="'Gender'">Gender</th>
          <th [matTooltip]="'Address'">Address</th>
          <th [matTooltip]="'Email'">Email</th>
          <th [matTooltip]="'SSN Number'">SSN Number</th>
          <th [matTooltip]="'Policy No'">Policy No</th>
          <th [matTooltip]="'Cell No'">Cell No</th>
          <th [matTooltip]="'Emergency No'">Emergency No</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let data of patientSearchList"
        [ngClass]="{
          'row-highlighted': this.tblSelectedPatientId === data.patientId
        }"  (click)="fnSelectedRow(data.docId, data.patientId)">
          <td>
            <i class="fa fa-plus plusicon ml-1" (click)="addPatient(data.patientId)" [matTooltip]="'Add Patient'" *ngIf="patientIDV3 !== data.patientId"></i>
            <i class="fa fa-check checkicon" *ngIf="patientIDV3 === data.patientId"></i>
          </td>
          <td>
            <mat-icon
            style="cursor: pointer !important" [matTooltip]="'Open Options'"
            [matMenuTriggerFor]="menu"
            >more_vert</mat-icon>

            <mat-menu class="custom-mat-menu" #menu="matMenu">
                <button
                mat-menu-item
                (click)="
                  openViewNotesPopup(
                    data.patientId,
                    data.defaultFaxId,
                    data.docId
                  )
                "
                [disabled]="
                  data.patientId === defaultGuid ||
                  data.patientId === null ||
                  data.patientId === ''
                "
              >
                View Patient Notes
              </button>

            <a [routerLink]="'/patient_preview_tiff_document/' + data.defaultFaxId + '/' + data.docId" target="_blank" class="preview-link">
              <button [disabled]="isEmpty(data.docId)" mat-menu-item>
                View Document Image
              </button>
            </a>

            </mat-menu>
          </td>
          <!-- <td (dblclick)="onRowDoubleClickV1(data?.chartId || '-')" [matTooltip]="data?.chartId || '-'">{{ data?.chartId || '-' }}</td> -->
          <td [matTooltip]="data?.chartId || '-'"> <a  class="preview-link" [class.disabled]="isEmpty(data.chartId) || isEmpty(data.patientId)"
            [routerLink]="'/patientCreateEditTab/' + data.patientId + '/' + data.docId" target="_blank"
            >{{data?.chartId ? data?.chartId : '-'}}
          </a></td>
          <td (dblclick)="onRowDoubleClickV1(data?.patientName || '-')" [matTooltip]="data?.patientName || '-'">{{ data?.patientName || '-' }}</td>
          <td [matTooltip]="getFormattedDate(data?.dob)"
          (dblclick)="onRowDoubleClickV1(getFormattedDate(data?.dob))" class="text-center">
          {{ getFormattedDate(data?.dob) }}
          </td>
          <td (dblclick)="onRowDoubleClickV1(data?.gender || '-')" [matTooltip]="data?.gender || '-'">{{ data?.gender || '-' }}</td>
          <td (dblclick)="onRowDoubleClickV1(data?.address || '-')" [matTooltip]="data?.address || '-'">{{ data?.address || '-' }}</td>
          <td (dblclick)="onRowDoubleClickV1(data?.email || '-')" [matTooltip]="data?.email || '-'">{{ data?.email || '-' }}</td>
          <td (dblclick)="onRowDoubleClickV1(data?.ssn || '-')" [matTooltip]="data?.ssn || '-'">{{ data?.ssn || '-' }}</td>
          <td (dblclick)="onRowDoubleClickV1(data?.policyNo || '-')" [matTooltip]="data?.policyNo || '-'">{{ data?.policyNo || '-' }}</td>
          <td (dblclick)="onRowDoubleClickV1(data?.cellNo || '-')" [matTooltip]="data?.cellNo || '-'">{{ data?.cellNo || '-' }}</td>
          <td (dblclick)="onRowDoubleClickV1(data?.emergencyContact || '-')" [matTooltip]="data?.emergencyContact || '-'">{{ data?.emergencyContact || '-' }}</td>
        </tr>
      </tbody>
    </table>
