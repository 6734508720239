<div class="container-fluid">
  <div class="row bg">
    <div class="col-12">
      <div class="d-flex align-items-center justify-content-between mb-3">
        <h3 class="font-weight-bolder mb-0">Process Billing</h3>
        <div class=" page-title-box page-title-right">
          <ol class="breadcrumbs m-0 align-items-center">
            <li class="breadcrumb-item">Orders</li>
            <li class="breadcrumb-item ">Warehouse</li>
          </ol>
        </div>
      </div>
    </div>
  </div>
</div>


<div class="col-12 table-responsive" style="margin-top: -6px;">
  <!-- <div [hidden]="!isLoading"
    style="display: flex; justify-content: center; align-items: center; background: transparent; color: lightseagreen;">
    <mat-progress-bar mode="indeterminate" value='66' diameter="45" class="lightseagreen">
    </mat-progress-bar>
  </div> -->
  <!-- <app-table [hidden]="isLoading" [strPageType]="strPageType" (viewButton)="existingOrders($event)"></app-table> -->
  <table mat-table class="table" [dataSource]="dataSource" matSort>
    <form style="display: flex" [formGroup]="orderForm">
      <ng-container matColumnDef="saleOrderId">
        <th class="header" mat-header-cell *matHeaderCellDef>
          <mat-form-field class="filter">
            <input autocomplete="new-saleOrderId" matInput formControlName="saleOrderId">
            <mat-placeholder class="center">SaleOrder Id</mat-placeholder>
          </mat-form-field>
        </th>
        <td mat-cell *matCellDef="let element"> {{element.saleOrderId}} </td>
      </ng-container>
      <ng-container matColumnDef="VerifyName">
        <th class="header" mat-header-cell *matHeaderCellDef>
          <mat-form-field class="filter">
            <input autocomplete="new-VerifyName" matInput formControlName="VerifyName">
            <mat-placeholder>Verify Name</mat-placeholder>
          </mat-form-field>
        </th>
        <td mat-cell *matCellDef="let element"> {{element?.VerifyName}} </td>
      </ng-container>
      <ng-container matColumnDef="patientId">
        <th class="header" mat-header-cell *matHeaderCellDef>
          <mat-form-field class="filter">
            <input autocomplete="new-PatientId" matInput formControlName="PatientId">
            <mat-placeholder class="center">Patient Id</mat-placeholder>
          </mat-form-field>
        </th>
        <td mat-cell *matCellDef="let element"> {{element.patientId}} </td>
      </ng-container>
      <ng-container matColumnDef="PatientName">
        <th class="header" mat-header-cell *matHeaderCellDef>
          <mat-form-field class="filter">
            <input autocomplete="new-PatientName" matInput formControlName="PatientName">
            <mat-placeholder>Patient Name</mat-placeholder>
          </mat-form-field>
        </th>
        <td mat-cell *matCellDef="let element"> {{element.PatientName}} </td>
      </ng-container>
      <ng-container matColumnDef="supplyDate">
        <th class="header" mat-header-cell *matHeaderCellDef>
          <mat-form-field class="filter">


            <mat-label>Supplied Date</mat-label>
            <input autocomplete="new-supplyDate" matInput formControlName="supplyDate" placeholder="(MM/DD/YYYY)" />
            <mat-error
              *ngIf=" orderForm?.get('supplyDate')?.touched && orderForm?.get('supplyDate')?.errors?.dateVaidator">
              Enter Valid date
            </mat-error>


          </mat-form-field>
        </th>
        <td mat-cell *matCellDef="let element"> {{element?.supplyDate}} </td>
      </ng-container>
      <ng-container matColumnDef="email">
        <th class="header" mat-header-cell *matHeaderCellDef>
          <mat-form-field class="filter">
            <input autocomplete="new-email" matInput formControlName="email">
            <mat-placeholder>Email</mat-placeholder>
          </mat-form-field>
        </th>
        <td mat-cell *matCellDef="let element"> {{element?.email}} </td>
      </ng-container>
      <ng-container matColumnDef="location">
        <th class="header" mat-header-cell *matHeaderCellDef>
          <mat-form-field class="filter">
            <input autocomplete="new-location" matInput formControlName="location">
            <mat-placeholder>Shipping Location</mat-placeholder>
          </mat-form-field>
        </th>
        <td mat-cell *matCellDef="let element"> {{element?.location}} </td>
      </ng-container>

      <ng-container matColumnDef="Plan">
        <th class="header" mat-header-cell *matHeaderCellDef>
          <mat-form-field class="filter">
            <input autocomplete="new-Plan" matInput formControlName="Plan">
            <mat-placeholder>Plan</mat-placeholder>
          </mat-form-field>
        </th>
        <td mat-cell *matCellDef="let element"> {{element.plan}} </td>
      </ng-container>


      <ng-container matColumnDef="Status">
        <th class="header" mat-header-cell *matHeaderCellDef>
          <mat-form-field class="filter">
            <input autocomplete="new-Status" matInput formControlName="Status">
            <mat-placeholder>Status</mat-placeholder>
          </mat-form-field>
        </th>
        <td mat-cell *matCellDef="let element"> {{element?.status}} </td>
      </ng-container>
      <ng-container matColumnDef="Date">
        <th class="header" mat-header-cell *matHeaderCellDef>
          <mat-form-field class="filter">

            <mat-label> Create Date </mat-label>
            <input autocomplete="new-date" matInput formControlName="date" placeholder="(MM/DD/YYYY)" />
            <mat-error *ngIf=" orderForm?.get('date')?.touched && orderForm?.get('date')?.errors?.dateVaidator">
              Enter Valid date
            </mat-error>


          </mat-form-field>
        </th>
        <td mat-cell *matCellDef="let element"> {{element.createDate}} </td>
      </ng-container>

      <!-- <ng-container matColumnDef="options">
            <th class="header" mat-header-cell *matHeaderCellDef>
              Options
            </th>
            <td class="align" mat-cell *matCellDef="let element">
              <a class="editBtnColor" >
                <i class="fa fa-eye" ></i>
              </a>
            </td>
          </ng-container> -->

      <ng-container matColumnDef="warehouse">
        <th class="header" class="w-125 th-center" mat-header-cell *matHeaderCellDef>
          <mat-form-field class="filter">
            <input autocomplete="new-warehouse" matInput formControlName="warehouse">
            <mat-placeholder>Wip Status</mat-placeholder>
          </mat-form-field>
        </th>
        <td mat-cell *matCellDef="let element">
          <span [ngClass]="{ insPayment:element.insPayment}" *ngIf="showValue">{{element?.wipStatusType}}</span>
          <mat-select [ngClass]="{ insPayment:element.insPayment}" *ngIf="showValue1"
            (ngModelChange)='onOptionsSelected($event)' placeholder="Select" [ngModelOptions]="{standalone: true}"
            [(ngModel)]="element.wipStatusType" maxlength="40">
            <mat-option *ngFor="let status of drpwarehouse" [value]="status.warehouseWipStatusType">
              {{ status.warehouseWipStatusType }}
            </mat-option>
          </mat-select>
        </td>
      </ng-container>
      <ng-container matColumnDef="options">
        <th class="header" mat-header-cell *matHeaderCellDef>
          Options
        </th>
        <td mat-cell *matCellDef="let element">
          <!-- <span [ngClass]="{ insPayment:element.insPayment}" *ngIf="enableEdit" (click)="cancelWip()"
            (click)="enableEdit=false" class="editBtnColor" ng-click="cancel()">
            <i class="	fa fa-close mr-2 ml-2"></i></span>
          <span [ngClass]="{ insPayment:element.insPayment}" *ngIf="enableEdit" id="saveBtn" class="editBtnColor"
            (click)="getDetailsForSave(element.data)" type="submit"><i class="fa fa-check"></i></span> -->
          <!-- <a [ngClass]="{ insPayment:element.insPayment}" class="editBtnColor" (click)="showWip()" *ngIf="!enableEdit"
            (click)="enableEdit = true">
            <i class="fa fa-edit ml-3" uib-tooltip="Edit" tooltip-trigger="mouseenter" tooltip-append-to-body="true"
              tooltip-placement="left"></i>
          </a> -->
          <ng-container *ngIf="!enableEdit">
            <button *ngIf="element?.billingStatus !== 'Processed'" mat-icon-button class="editBtnColor m-0 p-0 "
              [ngClass]="{ insPayment:element.insPayment}">
              <mat-icon matTooltip="Click To Undo Status" (click)="enableEdit = true" (click)="showWip()">
                edit</mat-icon>
            </button>
          </ng-container>
          <ng-container *ngIf="enableEdit">
            <button (click)="enableEdit=false" mat-icon-button class="editBtnColor pr-3 w-50"
              [ngClass]="{ insPayment:element.insPayment}">
              <mat-icon matTooltip="Click To Edit Status" (click)="cancelWip()">
                close</mat-icon>
            </button>

            <button (click)="enableEdit=false" mat-icon-button class="editBtnColor w-50"
              [ngClass]="{ insPayment:element.insPayment}">
              <mat-icon matTooltip="Click To Update Status" (click)="getDetailsForSave(element.data)">
                check</mat-icon>
            </button>
          </ng-container>



          <span [ngClass]="{ cashPayment:element.cashPayment}">
            <button mat-button class="btn btn-sm lightSeaGreen-dot" (click)="paymentInitiate(element)">
              PayPal {{element.payPalPaymentStatus}}
            </button>
          </span>
          <!-- <span [ngClass]="{ cashPayment:element.cashPayment}">
            <button mat-button class="btn btn-sm lightSeaGreen-dot" (click)="paymentInvoiceInitiate(element)">
              Invoice {{element.payPalInvoicePaymentStatus }}
            </button>
          </span> -->
        </td>
      </ng-container>
      <!-- <ng-container matColumnDef="payment">
        <th class="header" mat-header-cell *matHeaderCellDef mat-sort-header>
          Payment
        </th>
        <td  mat-cell *matCellDef="let record">
          <span [ngClass]="{ cashPayment:record.cashPayment}"  >
            <button  mat-button class="btn btn-sm lightSeaGreen-dot" (click)="paymentInitiate(record)">{{record.payPalCheckOutOrderPaymentStatus}}</button></span>

        </td>
      </ng-container> -->

      <!-- <ng-container  matColumnDef="paymentStatus">
        <th class="header"  mat-header-cell *matHeaderCellDef mat-sort-header>
          Payment Status
        </th>
        <td mat-cell *matCellDef="let record">
          <button  mat-button class="btn btn-sm lightSeaGreen-dot"
           >Payment Status</button>
        </td>
      </ng-container> -->


      <ng-container matColumnDef="noRecords">
        <td class="text-align-center" mat-footer-cell *matFooterCellDef colspan="9">
          <div *ngIf="!isLoading">
            {{"No Records Found"}}
          </div>
          <div [hidden]="!isLoading">
            <mat-spinner [diameter]="220"></mat-spinner>
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="paginator">
        <td mat-footer-cell *matFooterCellDef colspan="12">
          <mat-paginator class="table table-responsive mat-table-class" #MatPaginator
            [length]="dataSource?.data?.length" [pageSize]="10" [pageIndex]="0" showFirstLastButtons
            [pageSizeOptions]="[5, 10, 25, 100]">
          </mat-paginator>
        </td>
      </ng-container>
    </form>
    <tr mat-footer-row *matFooterRowDef="['noRecords']"
      [ngClass]="{ active: dataSource && dataSource.data && dataSource?.data?.length !== 0 }"></tr>
    <tr mat-header-row *matHeaderRowDef="arrDisplayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: arrDisplayedColumns"></tr>
    <tr mat-footer-row *matFooterRowDef="['paginator']"></tr>

  </table>
</div>