import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { DataTableDirective } from 'angular-datatables';
import { Observable, Subscription } from 'rxjs';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { PracticeManagementService } from '../patient-proxy/patient/practice-management';
import Swal from 'sweetalert2';
import { ToastrService } from 'ngx-toastr';
import { HttpErrorResponse } from '@angular/common/http';
import { CommunicationService } from 'projects/shared/src/app/services/communication.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActiveStatus } from 'projects/admin/src/app/admin-proxy/platform-app-management/rcm/platform-management/localization/enumeration/active-status.enum';
import { DatePipe } from '@angular/common';
@Component({
  selector: 'app-practice-management-v1',
  templateUrl: './practice-management-v1.component.html',
  styleUrls: ['./practice-management-v1.component.scss']
})
export class PracticeManagementV1Component implements OnInit {
  @Input() tableData: any;
  eventtoggle: boolean = false;
  practicelistdata: any;
  myTable: DataTables.Api;
  $subscription: Subscription[] = [];
  tableData$: Subscription;
  isShowSpinner: boolean;
  @ViewChild(DataTableDirective, { static: false })
  datatableElement: DataTableDirective;
  dtPracticeManagementTableOptions = {
    responsive: true,
    // stateSave: true,
    paging: false,
    dom: 'Bfrtip',
    language: {
      emptyTable: 'No records',
      info: 'Total : _MAX_ records',
      infoEmpty: 'Total : _MAX_ records',
      infoFiltered: '(filtered : _TOTAL_ records)',
      loadingRecords: 'Loading...',
      zeroRecords: 'No matching records',
    },
    scrollCollapse: true,
    scrollY: '450px',
    scrollX: true,
    fixedHeader: true,
    columnDefs: [
      // { targets: [0, 1], orderable: false },
      { targets: [5,6,7,8], visible: false },
      { targets: [0, 1, -1], className: 'dt-fixed-column' },
      {
        targets: [4],
        type: 'date',
        render: function (data, type, row) {
          // Assuming data is in the format MM/DD/YYYY
          if (type === 'sort' || type === 'type') {
            var dateParts = data.split('/');
            return new Date(dateParts[2], dateParts[0] - 1, dateParts[1]);
          } else {
            return data;
          }
        },
      },
    ],
    search: {
      smart: false, // Set to "none" for full exact match
    },
    buttons: [
      {
        extend: 'excel',
        text:'Export',
        filename: 'Practice Management',
        exportOptions: {
          columns: ':visible:not(:first-child,:eq(0)) ',
        },
      },
      {
        extend: 'colvis',
        columns: ':not(:eq(0), :eq(1), :eq(2), :eq(-1))', // Exclude first three and last columns from the column visibility toggle
      },
      {
        text: 'Show Default Column',
        action: function (e, dt, node, config) {
          dt.columns(':not(:lt(3),:last-child)').visible(false);
        },
      },
      {
        text: 'Show All Column',
        action: function (e, dt, node, config) {
          dt.columns().visible(true);
        },
      },
    ],
  };
  constructor(
    private practiceManagementService: PracticeManagementService,
    private toastr: ToastrService,
    private communicationService: CommunicationService,
    private snackBar: MatSnackBar,
    private datepipe: DatePipe
  ) { }

  ngOnInit(): void {
    this.practicelistdata = this.tableData;

    this.dtPracticeManagementTableOptions.buttons[0].filename =
    'Practice Management' + '-' + this.datepipe.transform(new Date(), 'MM-dd-yyyy');
  }
  //After View Method
  ngAfterViewInit(): void {
    if (this.datatableElement && this.datatableElement.dtInstance) {
      this.refreshDataTable();
    } else {
      setTimeout(() => this.refreshDataTable(), 500);
    }
  }


  refreshDataTable(): void {
    this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.columns().every(function () {
        const that = this;
        $('input', this.footer()).on('keyup change', function () {
          if (that.search() !== this['value']) {
            that.search(this['value']).draw();
          }
        });
      });
    });
  }

  ngOnDestroy(): void {
    if (this.$subscription) {
      this.$subscription.forEach((subscription) => {
        subscription.unsubscribe();
      });
    }
    this.tableData$?.unsubscribe();
  }

  //Row Click Event UnProcessed Table
  handleTableCellClick(e: Event, notesData: any): void {
    const target = e.target as HTMLElement;
    const tr = target.closest('tr');
    this.getDataTableData(tr, notesData);
  }
  //DataTable Data Get Method and Toggle Notes Row in UnProcessed Table
  async getDataTableData(tr: any, notesData: any): Promise<void> {
    this.myTable = await this.datatableElement.dtInstance;

    if (tr) {
      const row = this.myTable.row(tr);

      if (row.child.isShown()) {
        row.child.hide();
      } else {
        row.child(this.format(row.data(), notesData)).show();
      }
    }
  }
  format(d: any, notesData: any): string {
    let returnString: string = '';

    if (Array.isArray(notesData)) {
      notesData.forEach(function (value) {
        // returnString += '<tr class="border-bottom" style="white-space: pre-wrap !important;"><td>' + value + '</td></tr>';
        returnString +=
        '' +
         value +'<h1 class="line" style="border-bottom: 1px solid rgb(196, 201, 196);Margin: 4px 0 9px 0px"></h1>'
      });
    } else if (typeof notesData === 'string') {
      // returnString += '<tr class="border-bottom" style="white-space: pre-wrap !important;"><td>' + notesData + '</td></tr>';
      returnString +=
      '' +
      notesData +'<h1 class="line" style="border-bottom: 1px solid rgb(196, 201, 196);Margin: 4px 0 9px 0px"></h1>'
    } else {
      console.error('notesData is neither an array nor a string:', notesData);
    }

    return returnString;
  }

  // getFormattedDate(date: string): string {
  //   return date ? new Date(date).toLocaleDateString('en-US') : '-';
  // }

  getFormattedDate(date: any): string {
    return date
      ? new Date(date).toLocaleString('en-US', {
        month: '2-digit',
        day: '2-digit',
        year: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        hour12: true,
      })
      : '-';
  }

//US Format Date and Time 
getFormattedDateTimeZone(date: string): string {
  if (date) {
	const formattedDate = new Date(date);
	const options: Intl.DateTimeFormatOptions = {
	  year: 'numeric', month: '2-digit', day: '2-digit',
	  hour: 'numeric', minute: '2-digit',
	  hour12: true,
	  timeZone: 'America/Chicago'
	};
	return formattedDate.toLocaleString('en-US', options);
  } else {
	return '-';
  }
}
  statusChange(event: MatSlideToggleChange, data: any) {

    if (event.checked == true) {

      Swal.fire({
        title: 'Do you want to activate?',
        text: 'By Changing Status, this practice list will be activated',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#34c38f',
        cancelButtonColor: '#f46a6a',
        confirmButtonText: 'Yes, Activate it!',
      }).then((result) => {
        if (result.value) {

          if (
            data?.id &&
            data?.id !== '' &&
            data?.id !== null &&
            data?.id !== undefined
          ) {
            this.practiceManagementService.enableDisablePracticeById(data?.id)
              .subscribe(
                () => {
                  this.toastr.success('Activated Successfully', 'Success');
                  this.isShowSpinner = false;
                  // this.getPracticeFaxNumber();
                  this.communicationService.triggerPracticeList(ActiveStatus.Inactive);
                  // this.communicationService.triggerPracticetoggle(this.eventtoggle);
                },
                (err) => {
                  const data: HttpErrorResponse = err;
                  this.isShowSpinner = false;
                  Swal.fire({
                    icon: 'info',
                    text: data?.error?.error?.message,
                  });
                  event.source.checked = false;
                }
              );
          }
        } else {
          event.source.checked = false;
        }
      });
    } else if (event.checked == false) {

      Swal.fire({
        title: 'Do you want to deactivate?',
        text: 'By Changing Status, this practice list will be deactivated',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#34c38f',
        cancelButtonColor: '#f46a6a',
        confirmButtonText: 'Yes, deactivate it!',
        //cancelButtonText: 'No, deactivate it!',
      }).then((result) => {

        if (result.value) {
          if (
            data?.id &&
            data?.id !== '' &&
            data?.id !== null &&
            data?.id !== undefined
          ) {
            this.practiceManagementService.enableDisablePracticeById(data?.id)
              .subscribe(
                () => {
                  this.toastr.success('Deactivated Successfully', 'Success');
                  this.isShowSpinner = false;
                  this.communicationService.triggerPracticeList(ActiveStatus.Active);
                  // this.communicationService.triggerPracticetoggle(this.eventtoggle);
                  // this.getPracticeFaxNumber();
                },
                (err) => {
                  const data: HttpErrorResponse = err;
                  this.isShowSpinner = false;
                  event.source.checked = true;
                  Swal.fire({
                    icon: 'info',
                    text: data?.error?.error?.message,
                  });
                }
              );
          }
        } else {
          event.source.checked = true;
        }
      });
    }
  }

  onRowDoubleClickV1(textToCopy) {
    this.copyTextToClipboard(textToCopy);
    this.showNotification('Text copied: ' + textToCopy);
  }

  showNotification(message: string): void {
    this.snackBar.open(message, 'Close', {
      duration: 3000, // Duration in milliseconds
      horizontalPosition: 'end', // Positioning the notification horizontally
      verticalPosition: 'bottom', // Positioning the notification vertically
    });
  }

  copyTextToClipboard(text: string): void {
    const textArea = document.createElement('textarea');
    textArea.value = text;
    document.body.appendChild(textArea);
    textArea.select();
    document.execCommand('Copy');
    textArea.remove();
  }
}
