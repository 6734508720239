<br />
<div class="card card-body">
  <div class="container-fluid pt-3 pb-3">
    <div class="row">
      <div class="col-12">
        <h4 class="customThemeClass"><b>Patient Ledger List</b></h4>
      </div>
    </div>

    <div class="table-responsive">
      <table class="users table table-sm table-bordered ">
        <thead style="display: table-header-group;">
          <tr>
            <th class="table-light" scope="col">SaleOrder Id</th>
            <th class="table-light" scope="col">Insurance Name</th>
            <th class="table-light" scope="col">Supplied Date</th>
            <th class="table-light" scope="col">Patient Id</th>
            <th class="table-light" scope="col">Patient Name</th>
            <th class="table-light" scope="col">Email</th>
            <th class="table-light" scope="col">Shipping Location</th>
            <th class="table-light" scope="col">Created Date</th>
            <th class="table-light" scope="col">Status</th>
            <th class="table-light" scope="col">Options</th>
          </tr>
        </thead>
        <tbody style="display: table-row-group;">
          <tr scope="row" *ngFor="let patient of tableDummyData">

            <td class="table-light">
              <p class="text-muted mb-0">
                <ngb-highlight [result]='patient.billingId'>
                </ngb-highlight>
              </p>
            </td>
            <td class="table-light">
              <h5 class="text-truncate font-size-14">
                <a href="javascript: void(0);" class="text-dark">
                  <ngb-highlight [result]='patient?.insuranceName'>
                  </ngb-highlight>
                </a>
              </h5>
            </td>
            <td class="table-light">
              <h5 class="text-muted mb-0">
                <ngb-highlight [result]='patient.suppliedDate'>
                </ngb-highlight>
              </h5>
            </td>
            <td class="table-light">
              <h5 class="text-muted mb-0">
                <ngb-highlight [result]='patient.patientId'>
                </ngb-highlight>
              </h5>
            </td>
            <td class="table-light">
              <h5 class="text-muted mb-0">
                <ngb-highlight [result]='patient.patientName'>
                </ngb-highlight>
              </h5>
            </td>
            <td class="table-light">
              <h5 class="text-muted mb-0">
                <ngb-highlight [result]='patient.email'>
                </ngb-highlight>
              </h5>
            </td>
            <td class="table-light">
              <h5 class="text-muted mb-0">
                <ngb-highlight [result]='patient.shippingLocation'>
                </ngb-highlight>
              </h5>
            </td>
            <td class="table-light">
              <h5 class="text-muted mb-0">
                <ngb-highlight [result]='patient.createdDate'>
                </ngb-highlight>
              </h5>
            </td>
            <td class="table-light">
              <h5 class="text-muted mb-0">
                <ngb-highlight [result]='patient.status'>
                </ngb-highlight>
              </h5>
            </td>
            <td class="table-light">
              <h5 class="text-muted text-center mb-0">
                <a class="customThemeClass" [hidden]="patient.orderStatus" (click)="viewShippingDetails(patient.id)">
                  <!-- <i class="mdi mdi-eye mdi-18px"></i> -->
                  <i class="fa fa-eye"></i>
                </a>
              </h5>
            </td>
          </tr>
        </tbody>
      </table>
      <!-- {{tableDummyData}} -->
    </div>
    <div class="row">
      <div class="col-12 pt-2" *ngIf="strSelectedBillingId!==''">
        <div class="row pt-1">
          <div class="col-9">
            <mat-card>
              <mat-card-content>
                <h4 class="customThemeClass"><b>Item Details</b></h4>
                <mat-divider> </mat-divider>
                <div class="row  pt-2">
                  <div class="col-1">
                    <mat-card-subtitle><b>Price Code</b></mat-card-subtitle>
                  </div>
                  <div class="col-2">
                    <mat-card-subtitle><b>Description</b></mat-card-subtitle>
                  </div>
                  <!-- <div class="col-1">
                  <mat-card-subtitle><b>Size</b></mat-card-subtitle>
                </div> -->
                  <div class="col-1">
                    <mat-card-subtitle><b>Units</b></mat-card-subtitle>
                  </div>
                  <div class="col-2">
                    <mat-card-subtitle><b>Name</b></mat-card-subtitle>
                  </div>
                  <div class="col-1">
                    <mat-card-subtitle><b>Item Code Id</b></mat-card-subtitle>
                  </div>
                  <div class="col-2">
                    <mat-card-subtitle><b>Tracking</b></mat-card-subtitle>
                  </div>
                  <div class="col-1">
                    <mat-card-subtitle><b>Exch</b></mat-card-subtitle>
                  </div>
                  <div class="col-2">
                    <mat-card-subtitle><b>Shipping Date</b></mat-card-subtitle>
                  </div>
                </div>
                <!-- {{itemTable}} -->
                <div class="row" *ngFor="let item of itemTable">
                  <div class="col-1">
                    {{item.priceCode}}
                  </div>
                  <div class="col-2">
                    {{item.description}}
                  </div>
                  <div class="col-1">
                    {{item.qty}}
                  </div>
                  <!-- <div class="col-1">
                  {{item.qty}}
                </div> -->
                  <div class="col-2">
                    {{item.name}}
                  </div>
                  <div class="col-1">
                    {{item.itemCodeId}}
                  </div>
                  <div class="col-2">
                    {{item.exch}}
                  </div>
                  <div class="col-1">
                    <mat-checkbox value=""></mat-checkbox>
                  </div>
                  <div class="col-2">
                    {{item.shippingDate}}
                  </div>
                </div>
              </mat-card-content>
            </mat-card>
          </div>
          <div class="col-3">
            <mat-card>
              <mat-card-content>
                <h4 class="customThemeClass"><b>General Details</b> </h4>
                <mat-divider> </mat-divider>
                <div class=" pt-2" *ngFor="let item of itemTable">
                  <div class="row">
                    <div class="col-6">
                      <b>HCPC Code</b>
                    </div>
                    <div class="col-2">
                      <b>:</b>
                    </div>
                    <div class="col-4">
                      {{item.itemCodeId}}
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-6">
                      <b>Amount</b>
                    </div>
                    <div class="col-2">
                      <b>:</b>
                    </div>
                    <div class="col-4">
                      {{item.charge}}
                    </div>
                  </div>
                  <!-- <div class="row">
                  <div class="col-6">
                    <b>Approval No</b>
                  </div>
                  <div class="col-2">
                    <b>:</b>
                  </div>
                  <div class="col-4">
                    {{item.approvedNo}}
                  </div>
                </div> -->
                </div>
              </mat-card-content>
            </mat-card>
          </div>
        </div>
      </div>
    </div>
    <br>
    <form [formGroup]="ledgerForm">
      <hr>
      <mat-form-field *ngIf="show" class="col-3">
        <mat-label> Reason</mat-label>
        <input autocomplete="new-txtReason" matInput maxlength="40" formControlName="txtReason" type="text" />
      </mat-form-field>
      <div class="row">
        <div class="col-12">
          <div class="row">
            <div class="col-1">
              <h3 class="customThemeClass"><b>Status <span class="asterisk">*</span></b></h3>
            </div>
            <div class="col-8">
              <mat-radio-group (change)="onChangeGetInsuranceVerify($event)" formControlName="rbtnLedger"
                class="col-sm-7 radio mt-2 mr-2 txt-left">
                <mat-radio-button class="mr-2" value="Approved">Approve</mat-radio-button>
                <mat-radio-button class="mr-2" value="Disapproved">Disapprove</mat-radio-button>
              </mat-radio-group>
            </div>

            <div class="col-sm-3">
              <div class="text-lg-right">
                <button type="submit" mat-button class="buttonColor mb-2 mr-2"
                  [disabled]="saveButtonHide||!(!ledgerForm.invalid && ledgerForm.dirty )"
                  (click)="getPatientDetailsForSave( this.ledgerForm.value.rbtnLedger)">Save
                  <mat-icon *ngIf='isShowSpinner'>
                    <mat-spinner class="spinner-border spinner-border-sm" diameter="20"> </mat-spinner>
                  </mat-icon>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
