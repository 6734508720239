<div class="container-fluid pl-4 pr-4">
  <div class="row bg">
    <div class="col-lg-6">
      <h3 class="font-weight-bolder mb-0"><b>Patient / Authorization List</b></h3>
    </div>
    <div class="col-lg-6 text-right">
      <form [formGroup]="branchForm">
        <mat-form-field class="pr-3">
          <mat-label>Branch</mat-label>
          <mat-select #selectBranch formControlName="drpBranch" multiple [(value)]="organizationUnitName">
            <mat-option>
              <ngx-mat-select-search
                placeholderLabel="Search"
                noEntriesFoundLabel="No Matches found"
                formControlName="txtBranch"
              ></ngx-mat-select-search>
            </mat-option>

            <mat-option #selectAllBranch (click)="allBranchSelect()" value="0">All</mat-option>

            <mat-option *ngFor="let branch of brnachList | async" [value]="branch.id">
              {{ branch.organizationUnitName }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </form>
    </div>

  </div>

  <div class="row">
    <div class="col-xl-12 pb-2">
      <ul
        class="card card-body"
        ngbNav
        #nav="ngbNav"
        justify="justified"
        class="alert-success nav nav-pills nav-fill"
        style="border-bottom: 3px solid rgb(201, 201, 201)"
      >
        <li
          ngbNavItem="NewSetup"
          [class.selected-tab]="nav.activeId === 'NewSetup'"
        >
          <a ngbNavLink (click)="changeTab('NewSetup')"><b>New Setup</b></a>
        </li>
        <li
          ngbNavItem="Supplies"
          [class.selected-tab]="nav.activeId === 'Supplies'"
        >
          <a ngbNavLink id="tab-3" (click)="changeTab('Supplies')"
            ><b>Supplies</b></a
          >
        </li>
      </ul>
    </div>
  </div>
<br>
  <mat-accordion>
    <mat-expansion-panel (toggle)="step" [expanded]="step" (afterExpand)="toggle()" (closed)="toggleV2()">
        <mat-expansion-panel-header>
      <label class="wipCountHeader customThemeClass"><b>Authorization Status Count</b></label>
    </mat-expansion-panel-header>

   <div class="row">
     <div class="col-xl-12 pb-3">
       <div class="row">
         <div class="col-lg-4">
           <div class="candidates-feature-info shadow-sm border-10">
             <div class="row">
               <div class="col-lg-12">
                 <div class="candidates-info-contents">
                   <mat-card class="text-left"><b>Assigned - </b><b class="customThemeClass">10</b>
                   </mat-card>
                 </div>
               </div>
             </div>
           </div>
         </div>
         <div class="col-lg-4">
           <div class="candidates-feature-info shadow-sm border-10">
             <div class="row">
               <div class="col-lg-12">
                 <div class="candidates-info-contents">
                   <mat-card class="text-left"><b>Pending - </b><b class="customThemeClass">10</b>
                   </mat-card>
                 </div>
               </div>
             </div>
           </div>
         </div>
         <div class="col-lg-4">
           <div class="candidates-feature-info shadow-sm border-10">
             <div class="row">
               <div class="col-lg-12">
                 <div class="candidates-info-contents">
                   <mat-card class="text-left"><b>Completed - </b><b class="customThemeClass">10</b>
                   </mat-card>
                 </div>
               </div>
             </div>
           </div>
         </div>
       </div>
     </div>
   </div>
  </mat-expansion-panel>
</mat-accordion>
    <hr>
  <div class="row">
    <div class="col-xl-12 pb-4">
      <!-- NEW SETUP TAB-->
      <div *ngIf="activeTab === 'NewSetup'">
        <!-- Filter -->
        <form [formGroup]="newSetupFilterForm">
          <div class="row">
            <!-- Plan Name -->
            <mat-form-field class="pr-3 col-lg-4">
              <mat-label>Plan Name</mat-label>
              <mat-select
                formControlName="drpselectPayor"
                [(value)]="payorFilterValue"
                #payorSelect
                multiple
                (selectionChange)="checkPayorUniqness()"
              >
                <mat-option>
                  <ngx-mat-select-search
                  placeholderLabel="payerCode / payerName / Code"
                    noEntriesFoundLabel="No Matches found"
                    formControlName="selectPayor"
                    (keyup)="payerSearch($event.target.value)"
                  ></ngx-mat-select-search>
                </mat-option>

                <mat-option
                  #allPayorSelected
                  (click)="toggleAllPlanNameSelection()"
                  value="0"
                  >All</mat-option
                >

                <mat-option
                  *ngFor="let payor of filteredSelectPayers | async"
                  [value]="payor?.payerId"[title]="payor?.payerShortCodeList"
                >
                  {{ payor?.payerShortCodeList }}
                </mat-option>
              </mat-select>
              <mat-hint class="text-danger fw-bolder" *ngIf="!isUniq">  Insurance Category Not Available!</mat-hint>
            </mat-form-field>

            <!-- Plan Types -->
            <mat-form-field class="pr-3 col-lg-2">
              <mat-label> Plan Type</mat-label>
              <mat-select
                #planTypeSelect
                multiple
                formControlName="drpTypeOfPlan"
                [(value)]="typeOfPlanFilterValue"
              >
                <mat-option>
                  <ngx-mat-select-search
                    placeholderLabel="Search"
                    noEntriesFoundLabel="No Matches found"
                    formControlName="txtFilterPlanType"
                  ></ngx-mat-select-search>
                </mat-option>
                <mat-option
                  value="0"
                  #allPlanTypeSelected
                  (click)="toggleAllPlanTypesSelection()"
                  >All</mat-option
                >
                <mat-option
                  *ngFor="let payor of filteredPlanTypes | async"
                  [value]="payor.id"
                >
                  {{ payor.planType }}
                </mat-option>
              </mat-select>
            </mat-form-field>

            <!-- Auth Status -->
            <mat-form-field class="pr-3 col-lg-2">
              <mat-label> Auth Status</mat-label>
              <mat-select
                multiple
                #authStatusSelect
                [(value)]="authStatusFilterValue"
                formControlName="drpAuth"
              >
                <mat-option>
                  <ngx-mat-select-search
                    placeholderLabel="Search"
                    noEntriesFoundLabel="No Matches found"
                    formControlName="txtFilterAuthStatus"
                  ></ngx-mat-select-search>
                </mat-option>
                <mat-option
                  #allAuthStatusSelected
                  (click)="toggleAllAuthStatusSelection()"
                  [value]="0"
                  >All</mat-option
                >
                <mat-option
                  *ngFor="let status of filteredAuthStatus | async"
                  [value]="status.id"
                >
                  {{ status.authStatus }}
                </mat-option>
              </mat-select>
            </mat-form-field>

            <!-- Insurance Type -->
            <mat-form-field class="pr-3 col-lg-2">
              <mat-label> Insurance Type</mat-label>
              <mat-select
                multiple
                [(value)]="insuranceTypeFilterValue"
                #insuranceTypeSelect
                formControlName="drpInsuranceType"
              >
                <mat-option>
                  <ngx-mat-select-search
                    placeholderLabel="Search"
                    noEntriesFoundLabel="No Matches found"
                    formControlName="txtInsuranceType"
                  ></ngx-mat-select-search>
                </mat-option>
                <mat-option
                  #allInsuranceTypeSelected
                  (click)="toggleAllInsuranceTypes()"
                  [value]="0"
                  >All</mat-option
                >
                <mat-option [value]="'primary'"> Primary </mat-option>
                <mat-option [value]="'secondary'"> Secondary </mat-option>
                <mat-option [value]="'tertiary'"> Tertiary </mat-option>
              </mat-select>
            </mat-form-field>

            <div class="col-lg-2 text-align-center">
              <button
                mat-button
                class="buttonColor font-size-14 mr-2 mb-2"
                (click)="filterAuthNewSetup()"
              >
                Search
              </button>
              <button
                mat-button
                (click)="resetForm()"
                class="resetclr mr-2 mb-2"
              >
                Reset
              </button>
            </div>
          </div>
        </form>
        <hr class="mt-0" />
        <div class="pb-4 float-right">
          <a
            mat-button
            [disabled]="selectedNewSetupAuthValidation"
            [routerLink]="'/authDetails' + '/newSetup'"
            target="_blank"
            class="buttonColor button-link font-size-14 mr-2"
          >
            <mat-icon class="mr-1">remove_red_eye</mat-icon>
            Alloted Auth
          </a>

          <button
            class="mr-2 buttonColor"
            [disabled]="
              dataSourceNewSetup &&
              dataSourceNewSetup?.data &&
              dataSourceNewSetup?.data?.length === 0
            "
            mat-raised-button
            (click)="
              exporter.exportTable('xlsx', {
                fileName: 'Authorization List New Setup',
                sheet: 'AuthorizationList',
                Props: { Author: 'QSecure' }
              })
            "
          >
            Export
          </button>
        </div>
        <!-- New Setup Auth Table -->
        <form [formGroup]="newSetupSearchForm">
          <div
            class="table table-responsive example-container mat-elevation-z4 pt-0 mt-0"
          >
            <table
              mat-table
              [dataSource]="dataSourceNewSetup"
              matTableExporter
              #exporter="matTableExporter"
              matSort
              class="pt-0 mt-0"
              style="margin-left: 15px !important"
            >
              <!-- Action -->
              <ng-container matColumnDef="action" sticky>
                <th
                  mat-header-cell
                  *matHeaderCellDef
                  mat-sort-header
                  class="pb-2"
                >
                  Action
                </th>
                <td mat-cell *matCellDef="let element">
                  <mat-icon
                    style="cursor: pointer"
                    *ngIf="element.isLocked === 1"
                    (click)="unlockAuth(element?.isAdmin, element?.id)"
                    class="text-danger"
                    >lock</mat-icon
                  >
                  <mat-checkbox
                    class="ml-1"
                    [checked]="element.isLocked === 2 ? true : false"
                    (change)="updatePatientLock($event,element?.id)"
                    *ngIf="element.isLocked !== 1"
                  >
                  </mat-checkbox>
                </td>
              </ng-container>

              <!--WIP Id-->
              <ng-container matColumnDef="wipId" sticky>
                <th
                  class="header"
                  mat-header-cell
                  mat-sort-header
                  *matHeaderCellDef
                >
                  <!-- WIP Id -->
                  <mat-form-field class="filter">
                    <input
                      matInput
                      (keydown.space)="$event.stopPropagation()"
                      (click)="$event.stopPropagation()"
                      formControlName="wipId"
                      autocomplete="new-wipId"
                    />
                    <mat-placeholder class="center">WIP Id</mat-placeholder>
                  </mat-form-field>
                </th>
                <td mat-cell *matCellDef="let element">
                  {{ element?.wipId }}
                </td>
              </ng-container>

              <!--Patient Id-->
              <ng-container matColumnDef="defaultPatientId" sticky>
                <th
                  class="header"
                  mat-header-cell
                  mat-sort-header
                  *matHeaderCellDef
                >
                  <!-- Patient Id -->
                  <mat-form-field class="filter">
                    <input
                      matInput
                      (keydown.space)="$event.stopPropagation()"
                      (click)="$event.stopPropagation()"
                      formControlName="defaultPatientId"
                      autocomplete="new-defaultPatientId"
                    />
                    <mat-placeholder class="center">Patient Id</mat-placeholder>
                  </mat-form-field>
                </th>
                <td mat-cell *matCellDef="let element">
                  <a
                    matTooltip="Click to View Patient"
                    class="eyeCursorclass"
                    [routerLink]="
                      '/patientCreateEditTab/' +
                      element?.patientId +
                      '/' +
                      element?.id
                    "
                    target="_blank"
                  >
                    {{ element?.defaultPatientId }}
                  </a>
                </td>
              </ng-container>

              <!--Patient Name-->
              <ng-container matColumnDef="patientName">
                <th
                  class="header"
                  mat-header-cell
                  mat-sort-header
                  *matHeaderCellDef
                >
                  <!-- Patient Name -->
                  <mat-form-field class="filter">
                    <input
                      matInput
                      (keydown.space)="$event.stopPropagation()"
                      (click)="$event.stopPropagation()"
                      formControlName="patientName"
                      autocomplete="new-patientName"
                    />
                    <mat-placeholder class="center"
                      >Patient Name</mat-placeholder
                    >
                  </mat-form-field>
                </th>
                <td mat-cell *matCellDef="let element">
                  {{ element?.patientName }}
                </td>
              </ng-container>

              <!--Plan Name-->
              <ng-container matColumnDef="planName">
                <th
                  class="header"
                  mat-header-cell
                  mat-sort-header
                  *matHeaderCellDef
                >
                  <!-- Plan Type -->
                  <mat-form-field class="filter">
                    <input
                      matInput
                      (keydown.space)="$event.stopPropagation()"
                      (click)="$event.stopPropagation()"
                      formControlName="planName"
                      autocomplete="new-planName"
                    />
                    <mat-placeholder class="center">Plan Name</mat-placeholder>
                  </mat-form-field>
                </th>
                <td mat-cell *matCellDef="let element">
                  {{ element?.policyName || "-" }}
                </td>
              </ng-container>

              <!--Plan Type-->
              <ng-container matColumnDef="planType">
                <th
                  class="header"
                  mat-header-cell
                  mat-sort-header
                  *matHeaderCellDef
                >
                  <!-- Plan Type -->
                  <mat-form-field class="filter">
                    <input
                      matInput
                      (keydown.space)="$event.stopPropagation()"
                      (click)="$event.stopPropagation()"
                      formControlName="planType"
                      autocomplete="new-planType"
                    />
                    <mat-placeholder class="center">Plan Type</mat-placeholder>
                  </mat-form-field>
                </th>
                <td mat-cell *matCellDef="let element">
                  {{ element?.typeofPlan || "-" }}
                </td>
              </ng-container>

               <!-- Branch-->
               <ng-container matColumnDef="branch">
                <th
                  class="header"
                  mat-header-cell
                  mat-sort-header
                  *matHeaderCellDef
                >
               <mat-form-field class="filter">
                <input
                  matInput
                  (keydown.space)="$event.stopPropagation()"
                  (click)="$event.stopPropagation()"
                  formControlName="branch"
                  autocomplete="new-branch"
                />
                <mat-placeholder class="center">Branch</mat-placeholder>
              </mat-form-field>
            </th>
            <td mat-cell *matCellDef="let element">
              -
            </td>
          </ng-container>


              <!--Insurance Type-->
              <ng-container matColumnDef="insuranceType">
                <th
                  class="header"
                  mat-header-cell
                  mat-sort-header
                  *matHeaderCellDef
                >
                  <!-- Insurance Type -->
                  <mat-form-field class="filter">
                    <input
                      matInput
                      (keydown.space)="$event.stopPropagation()"
                      (click)="$event.stopPropagation()"
                      formControlName="insuranceType"
                      autocomplete="new-insuranceType"
                    />
                    <mat-placeholder class="center"
                      >Insurance Type</mat-placeholder
                    >
                  </mat-form-field>
                </th>
                <td mat-cell *matCellDef="let element">
                  {{ element?.insuranceType || "-" }}
                </td>
              </ng-container>

              <!-- CSR -->
              <ng-container matColumnDef="csr">
                <th
                  class="header"
                  mat-header-cell
                  mat-sort-header
                  *matHeaderCellDef
                >
                  <!-- CSR -->
                  <mat-form-field class="filter">
                    <input
                      matInput
                      (keydown.space)="$event.stopPropagation()"
                      (click)="$event.stopPropagation()"
                      formControlName="csr"
                      autocomplete="new-csr"
                    />
                    <mat-placeholder class="center">CSR</mat-placeholder>
                  </mat-form-field>
                </th>
                <td mat-cell *matCellDef="let element">
                  {{ element?.csrName || "-" }}
                </td>
              </ng-container>

              <!-- Agent -->
              <ng-container matColumnDef="agent">
                <th
                  class="header"
                  mat-header-cell
                  mat-sort-header
                  *matHeaderCellDef
                >
                  <!-- Agent -->
                  <mat-form-field class="filter">
                    <input
                      matInput
                      (keydown.space)="$event.stopPropagation()"
                      (click)="$event.stopPropagation()"
                      formControlName="agent"
                      autocomplete="new-agent"
                    />
                    <mat-placeholder class="center">Agent</mat-placeholder>
                  </mat-form-field>
                </th>
                <td mat-cell *matCellDef="let element">
                  {{ element?.agentName || "-" }}
                </td>
              </ng-container>

              <!-- WIP Status -->
              <ng-container matColumnDef="wipStatus">
                <th
                  class="header"
                  mat-header-cell
                  mat-sort-header
                  *matHeaderCellDef
                >
                  <!-- WIP Status -->
                  <mat-form-field class="filter">
                    <input
                      matInput
                      (keydown.space)="$event.stopPropagation()"
                      (click)="$event.stopPropagation()"
                      formControlName="wipStatus"
                      autocomplete="new-wipStatus"
                    />
                    <mat-placeholder class="center">WIP Status</mat-placeholder>
                  </mat-form-field>
                </th>
                <td mat-cell *matCellDef="let element">
                  <div [ngSwitch]="element?.wipStatus">
                    <div *ngSwitchCase="'yetToStart'">Yet To Start</div>
                    <div *ngSwitchCase="'hold'">Hold</div>
                    <div *ngSwitchCase="'inProcess'">In Process</div>
                    <div *ngSwitchCase="'completed'">Completed</div>
                    <div *ngSwitchCase="'NewSetupAssigned'">
                      New Setup Assigned
                    </div>
                    <div *ngSwitchCase="'NewSetupPending'">
                      New Setup Pending
                    </div>
                    <div *ngSwitchCase="'NewSetupInprogress'">
                      New Setup InProgress
                    </div>
                    <div *ngSwitchCase="'NewSetupCompleted'">
                      New Setup Completed
                    </div>
                    <div *ngSwitchCase="'VerificationAssigned'">
                      Verification Assigned
                    </div>
                    <div *ngSwitchCase="'VerificationPending'">
                      Verification Pending
                    </div>
                    <div *ngSwitchCase="'VerificationInprogress'">
                      Verification InProgress
                    </div>
                    <div *ngSwitchCase="'VerificationCompleted'">
                      Verification Completed
                    </div>
                    <div *ngSwitchCase="'AuthorizationAssigned'">
                      Authorization Assigned
                    </div>
                    <div *ngSwitchCase="'AuthorizationPending'">
                      Authorization Pending
                    </div>
                    <div *ngSwitchCase="'AuthorizationInprogress'">
                      Authorization InProgress
                    </div>
                    <div *ngSwitchCase="'AuthorizationCompleted'">
                      Authorization Completed
                    </div>
                    <div *ngSwitchDefault></div>
                  </div>
                </td>
              </ng-container>

              <!-- Auth Status -->
              <ng-container matColumnDef="authStatus">
                <th
                  class="header"
                  mat-header-cell
                  mat-sort-header
                  *matHeaderCellDef
                >
                  <!-- Auth Status -->
                  <mat-form-field class="filter">
                    <input
                      matInput
                      (keydown.space)="$event.stopPropagation()"
                      (click)="$event.stopPropagation()"
                      formControlName="authStatus"
                      autocomplete="new-authStatus"
                    />
                    <mat-placeholder class="center"
                      >Auth Status</mat-placeholder
                    >
                  </mat-form-field>
                </th>
                <td mat-cell *matCellDef="let element">
                  {{ element?.authStatusName || "-" }}
                </td>
              </ng-container>
              <!-- Verification Completed Date -->
              <ng-container matColumnDef="verificationCompletedDate">
                <th
                  class="header"
                  mat-header-cell
                  mat-sort-header
                  *matHeaderCellDef
                >
                  <!-- Verification Completed Date -->
                  <mat-form-field class="filter">
                    <input
                      matInput
                      (keydown.space)="$event.stopPropagation()"
                      (click)="$event.stopPropagation()"
                      formControlName="verificationCompletedDate"
                      autocomplete="new-verificationCompletedDate"
                    />
                    <mat-placeholder class="center"
                      >Verification Date</mat-placeholder
                    >
                    <mat-error *ngIf="newSetupSearchForm?.get('verificationCompletedDate')?.errors?.dateVaidator">
                      Enter Valid date
                    </mat-error>
                  </mat-form-field>
                </th>
                <td mat-cell *matCellDef="let element">
                  {{
                    element?.processCompletedDate
                      | date : "MM/dd/yyyy h:mm a" : "en_US"
                  }}
                </td>
              </ng-container>
              <!-- Document Id -->
              <!-- <ng-container matColumnDef="documentId">
                <th
                  class="header"
                  mat-header-cell
                  mat-sort-header
                  *matHeaderCellDef
                >
                  <mat-form-field class="filter">
                    <input
                      matInput
                      (keydown.space)="$event.stopPropagation()"
                      (click)="$event.stopPropagation()"
                      formControlName="documentId"
                      autocomplete="off"
                    />
                    <mat-placeholder class="center"
                      >Document Id</mat-placeholder
                    >
                  </mat-form-field>
                </th>
                <td mat-cell *matCellDef="let element">
                  {{ element?.documentId }}
                </td>
              </ng-container> -->

              <!-- Aging Days -->
              <!-- <ng-container matColumnDef="agingDays">
                <th
                  class="header"
                  mat-header-cell
                  mat-sort-header
                  *matHeaderCellDef
                >

                  <mat-form-field class="filter">
                    <input
                      matInput
                      (keydown.space)="$event.stopPropagation()"
                      (click)="$event.stopPropagation()"
                      formControlName="agingDays"
                      autocomplete="off"
                    />
                    <mat-placeholder class="center">Aging Days</mat-placeholder>
                  </mat-form-field>
                </th>
                <td mat-cell *matCellDef="let element">
                  {{ element?.agingDays || "-" }}
                </td>
              </ng-container> -->

              <!-- Stat-->
              <ng-container matColumnDef="Stat">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>STAT</th>
                <td mat-cell *matCellDef="let element">
                  <ng-container>
                    {{ element.isStat == 1 ? "Yes" : "No" }}
                  </ng-container>
                </td>
              </ng-container>

              <!-- No Records-->
              <!-- <ng-container matColumnDef="noRecords">
                <td
                  class="text-align-center"
                  mat-footer-cell
                  *matFooterCellDef
                  colspan="6"
                >
                  <div *ngIf="!isLoading">
                    {{ "No Records Found" }}
                  </div>
                  <div [hidden]="!isLoading">
                    <mat-spinner [diameter]="220"></mat-spinner>
                  </div>
                </td>
              </ng-container> -->

              <tr
                mat-header-row
                *matHeaderRowDef="arrDisplayedColumnsNewSetup"
              ></tr>
              <tr
                mat-row
                *matRowDef="let row; columns: arrDisplayedColumnsNewSetup"
              ></tr>
            </table>
            <div
              *ngIf="
                !(
                  dataSourceNewSetup &&
                  dataSourceNewSetup?.data &&
                  dataSourceNewSetup?.data?.length !== 0
                )
              "
              class="text-align-center"
            >
              <div class="message py-3" *ngIf="!isLoadingNewSeup">
                {{ "No Records Found" }}
              </div>
              <div class="py-3" [hidden]="!isLoadingNewSeup">
                <mat-spinner [diameter]="220"></mat-spinner>
              </div>
            </div>
            <mat-paginator
              #paginatorNewSetup
              [pageSizeOptions]="[10, 20, 50, 100]"
              showFirstLastButtons
            ></mat-paginator>
          </div>
        </form>
      </div>

      <!-- SUPPLIES TAB -->
      <div *ngIf="activeTab === 'Supplies'">
        <!-- <div class="pb-3 float-right">
              <button
                class="mr-2 buttonColor"
                [disabled]="
                  dataSourceSupplies &&
                  dataSourceSupplies?.data &&
                  dataSourceSupplies?.data?.length === 0
                "
                mat-raised-button
                (click)="
                  exporter.exportTable('xlsx', {
                    fileName: 'Supplies Authorization List',
                    sheet: 'suppliesAuthorizationList',
                    Props: { Author: 'QSecure' }
                  })
                "
              >
                Export
              </button>
            </div> -->

        <form [formGroup]="newSetupFilterForm">
          <div class="row">
            <!-- Plan Name -->
            <mat-form-field class="pr-3 col-lg-4">
              <mat-label>Plan Name</mat-label>
              <mat-select
                formControlName="drpselectPayor"
                [(value)]="payorFilterValue"
                #payorSelect
                multiple
                (selectionChange)="checkPayorUniqness()"
              >
                <mat-option>
                  <ngx-mat-select-search
                  placeholderLabel="payerCode / payerName / Code"
                    noEntriesFoundLabel="No Matches found"
                    formControlName="selectPayor"
                    (keyup)="payerSearch($event.target.value)"
                  ></ngx-mat-select-search>
                </mat-option>

                <mat-option
                  #allPayorSelected
                  (click)="toggleAllPlanNameSelection()"
                  value="0"
                  >All</mat-option
                >
                <mat-option
                  *ngFor="let payor of filteredSelectPayers | async"
                  [value]="payor?.payerId" [title]="payor?.payerShortCodeList"
                >
                  {{ payor?.payerShortCodeList }}
                </mat-option>
              </mat-select>
              <mat-hint class="text-danger fw-bolder" *ngIf="!isUniq">  Insurance Category Not Available!</mat-hint>
            </mat-form-field>

            <!-- Plan Types -->
            <mat-form-field class="pr-3 col-lg-2">
              <mat-label> Plan Type</mat-label>
              <mat-select
                #planTypeSelect
                multiple
                formControlName="drpTypeOfPlan"
                [(value)]="typeOfPlanFilterValue"
              >
                <mat-option>
                  <ngx-mat-select-search
                    placeholderLabel="Search"
                    noEntriesFoundLabel="No Matches found"
                    formControlName="txtFilterPlanType"
                  ></ngx-mat-select-search>
                </mat-option>
                <mat-option
                  value="0"
                  #allPlanTypeSelected
                  (click)="toggleAllPlanTypesSelection()"
                  >All</mat-option
                >
                <mat-option
                  *ngFor="let payor of filteredPlanTypes | async"
                  [value]="payor.id"
                >
                  {{ payor.planType }}
                </mat-option>
              </mat-select>
            </mat-form-field>

            <!-- Auth Status -->
            <mat-form-field class="pr-3 col-lg-2">
              <mat-label> Auth Status</mat-label>
              <mat-select
                multiple
                #authStatusSelect
                [(value)]="authStatusFilterValue"
                formControlName="drpAuth"
              >
                <mat-option>
                  <ngx-mat-select-search
                    placeholderLabel="Search"
                    noEntriesFoundLabel="No Matches found"
                    formControlName="txtFilterAuthStatus"
                  ></ngx-mat-select-search>
                </mat-option>
                <mat-option
                  #allAuthStatusSelected
                  (click)="toggleAllAuthStatusSelection()"
                  [value]="0"
                  >All</mat-option
                >
                <mat-option
                  *ngFor="let status of filteredAuthStatus | async"
                  [value]="status.id"
                >
                  {{ status.authStatus }}
                </mat-option>
              </mat-select>
            </mat-form-field>

            <!-- Insurance Type -->
            <mat-form-field class="pr-3 col-lg-2">
              <mat-label> Insurance Type</mat-label>
              <mat-select
                multiple
                [(value)]="insuranceTypeFilterValue"
                #insuranceTypeSelect
                formControlName="drpInsuranceType"
              >
                <mat-option>
                  <ngx-mat-select-search
                    placeholderLabel="Search"
                    noEntriesFoundLabel="No Matches found"
                    formControlName="txtInsuranceType"
                  ></ngx-mat-select-search>
                </mat-option>
                <mat-option
                  #allInsuranceTypeSelected
                  (click)="toggleAllInsuranceTypes()"
                  [value]="0"
                  >All</mat-option
                >
                <mat-option [value]="'primary'"> Primary </mat-option>
                <mat-option [value]="'secondary'"> Secondary </mat-option>
                <mat-option [value]="'tertiary'"> Tertiary </mat-option>
              </mat-select>
            </mat-form-field>

            <div class="col-lg-2 text-align-center">
              <button
                mat-button
                class="buttonColor font-size-14 mr-2 mb-2"
                (click)="filterAuthSupplies()"
              >
                Search
              </button>
              <button
                mat-button
                (click)="resetForm()"
                class="resetclr mr-2 mb-2"
              >
                Reset
              </button>
            </div>
          </div>
        </form>
        <hr class="mt-0" />

        <div class="pb-4 float-right">
          <a
            mat-button
            [disabled]="selectedSuppliesAuthValidation"
            [routerLink]="'/authDetails/' + 'supplies'"
            target="_blank"
            class="buttonColor button-link font-size-14 mr-2"
          >
            <mat-icon class="mr-1">remove_red_eye</mat-icon>
            Alloted Auth
          </a>

          <button
            class="mr-2 buttonColor"
            [disabled]="
              dataSourceSupplies &&
              dataSourceSupplies?.data &&
              dataSourceSupplies?.data?.length === 0
            "
            mat-raised-button
            (click)="
              exporter.exportTable('xlsx', {
                fileName: 'Authorization List New Setup',
                sheet: 'AuthorizationList',
                Props: { Author: 'QSecure' }
              })
            "
          >
            Export
          </button>
        </div>

        <div
          class="table table-responsive example-container mat-elevation-z4 pt-0 mt-0"
        >
          <form [formGroup]="suppliesSearchForm">
            <table
              mat-table
              [dataSource]="dataSourceSupplies"
              matTableExporter
              #exporter="matTableExporter"
              [hiddenColumns]="[0]"
              matSort
              class="pt-0 mt-0"
              style="margin-left: 15px !important"
            >
              <!-- Action -->
              <ng-container matColumnDef="action" sticky>
                <th
                  mat-header-cell
                  *matHeaderCellDef
                  mat-sort-header
                  class="pb-2"
                >
                  Action
                </th>
                <td mat-cell *matCellDef="let element">
                  <mat-icon
                    style="cursor: pointer"
                    *ngIf="element.isLocked === 1"
                    (click)="unlockAuth(element?.isAdmin, element?.id)"
                    class="text-danger"
                    >lock</mat-icon
                  >
                  <mat-checkbox
                    class="ml-1"
                    [checked]="element.isLocked === 2 ? true : false"
                    (change)="updatePatientLock($event,element?.id)"
                    *ngIf="element.isLocked !== 1"
                  >
                  </mat-checkbox>
                </td>
              </ng-container>

              <!--WIP Id-->
              <ng-container matColumnDef="wipId" sticky>
                <th
                  class="header"
                  mat-header-cell
                  mat-sort-header
                  *matHeaderCellDef
                >
                  <!-- WIP Id -->
                  <mat-form-field class="filter">
                    <input
                      matInput
                      (keydown.space)="$event.stopPropagation()"
                      (click)="$event.stopPropagation()"
                      formControlName="wipId"
                      autocomplete="new-wipId"
                    />
                    <mat-placeholder class="center">WIP Id</mat-placeholder>
                  </mat-form-field>
                </th>
                <td mat-cell *matCellDef="let element">
                  {{ element?.wipId }}
                </td>
              </ng-container>

              <!--Patient Id-->
              <ng-container matColumnDef="defaultPatientId" sticky>
                <th
                  class="header"
                  mat-header-cell
                  mat-sort-header
                  *matHeaderCellDef
                >
                  <!-- Patient Id -->
                  <mat-form-field class="filter">
                    <input
                      matInput
                      (keydown.space)="$event.stopPropagation()"
                      (click)="$event.stopPropagation()"
                      formControlName="defaultPatientId"
                      autocomplete="new-defaultPatientId"
                    />
                    <mat-placeholder class="center">Patient Id</mat-placeholder>
                  </mat-form-field>
                </th>
                <td mat-cell *matCellDef="let element">
                  <a
                    matTooltip="Click to View Patient"
                    class="eyeCursorclass"
                    [routerLink]="
                      '/patientCreateEditTab/' +
                      element?.patientId +
                      '/' +
                      element?.id
                    "
                    target="_blank"
                  >
                    {{ element?.defaultPatientId }}
                  </a>
                </td>
              </ng-container>

              <!--SaleOrder Id-->
              <ng-container matColumnDef="defaultSaleOrderId" sticky>
                <th
                  class="header"
                  mat-header-cell
                  mat-sort-header
                  *matHeaderCellDef
                >
                  <!-- SaleOrder Id -->
                  <mat-form-field class="filter">
                    <input
                      matInput
                      (keydown.space)="$event.stopPropagation()"
                      (click)="$event.stopPropagation()"
                      formControlName="defaultSaleOrderId"
                      autocomplete="new-defaultSaleOrderId"
                    />
                    <mat-placeholder class="center"
                      >Sale Order Id</mat-placeholder
                    >
                  </mat-form-field>
                </th>
                <td mat-cell *matCellDef="let element">
                  <a
                    matTooltip="Click to View Order"
                    [routerLink]="
                      '/orders/saleorderCreateEditTab/' +
                      element.patientId +
                      '/' +
                      element.saleOrderId
                    "
                    target="_blank"
                    class="eyeCursorclass"
                  >
                    {{ element?.defaultSaleOrderId }}
                  </a>
                </td>
              </ng-container>
                <!-- Branch-->
                <ng-container matColumnDef="sbranch">
                  <th
                    class="header"
                    mat-header-cell
                    mat-sort-header
                    *matHeaderCellDef
                  >
                 <mat-form-field class="filter">
                  <input
                    matInput
                    (keydown.space)="$event.stopPropagation()"
                    (click)="$event.stopPropagation()"
                    formControlName="sbranch"
                    autocomplete="new-sbranch"
                  />
                  <mat-placeholder class="center">Branch</mat-placeholder>
                </mat-form-field>
              </th>
              <td mat-cell *matCellDef="let element">
                -
              </td>
            </ng-container>

              <!--Patient Name-->
              <ng-container matColumnDef="patientName">
                <th
                  class="header"
                  mat-header-cell
                  mat-sort-header
                  *matHeaderCellDef
                >
                  <!-- Patient Name -->
                  <mat-form-field class="filter">
                    <input
                      matInput
                      (keydown.space)="$event.stopPropagation()"
                      (click)="$event.stopPropagation()"
                      formControlName="patientName"
                      autocomplete="new-patientName"
                    />
                    <mat-placeholder class="center"
                      >Patient Name</mat-placeholder
                    >
                  </mat-form-field>
                </th>
                <td mat-cell *matCellDef="let element">
                  {{ element?.patientName }}
                </td>
              </ng-container>

              <ng-container matColumnDef="itemName">
                <th
                  class="header"
                  mat-header-cell
                  mat-sort-header
                  *matHeaderCellDef
                >
                  <mat-form-field class="filter">
                    <input
                      matInput
                      (keydown.space)="$event.stopPropagation()"
                      (click)="$event.stopPropagation()"
                      formControlName="itemName"
                      autocomplete="new-itemName"
                    />
                    <mat-placeholder class="center">Item Name</mat-placeholder>
                  </mat-form-field>
                </th>
                <td mat-cell *matCellDef="let element">
                  {{ element?.itemName }}
                </td>
              </ng-container>

              <!--Plan Name-->
              <ng-container matColumnDef="planName">
                <th
                  class="header"
                  mat-header-cell
                  mat-sort-header
                  *matHeaderCellDef
                >
                  <!-- Plan Type -->
                  <mat-form-field class="filter">
                    <input
                      matInput
                      (keydown.space)="$event.stopPropagation()"
                      (click)="$event.stopPropagation()"
                      formControlName="planName"
                      autocomplete="new-planName"
                    />
                    <mat-placeholder class="center">Plan Name</mat-placeholder>
                  </mat-form-field>
                </th>
                <td mat-cell *matCellDef="let element">
                  {{ element?.policyName || "-" }}
                </td>
              </ng-container>

              <!--Plan Type-->
              <ng-container matColumnDef="planType">
                <th
                  class="header"
                  mat-header-cell
                  mat-sort-header
                  *matHeaderCellDef
                >
                  <!-- Plan Type -->
                  <mat-form-field class="filter">
                    <input
                      matInput
                      (keydown.space)="$event.stopPropagation()"
                      (click)="$event.stopPropagation()"
                      formControlName="planType"
                      autocomplete="new-planType"
                    />
                    <mat-placeholder class="center">Plan Type</mat-placeholder>
                  </mat-form-field>
                </th>
                <td mat-cell *matCellDef="let element">
                  {{ element?.typeofPlan || "-" }}
                </td>
              </ng-container>

              <!--Insurance Type-->
              <ng-container matColumnDef="insuranceType">
                <th
                  class="header"
                  mat-header-cell
                  mat-sort-header
                  *matHeaderCellDef
                >
                  <!-- Insurance Type -->
                  <mat-form-field class="filter">
                    <input
                      matInput
                      (keydown.space)="$event.stopPropagation()"
                      (click)="$event.stopPropagation()"
                      formControlName="insuranceType"
                      autocomplete="new-insuranceType"
                    />
                    <mat-placeholder class="center"
                      >Insurance Type</mat-placeholder
                    >
                  </mat-form-field>
                </th>
                <td mat-cell *matCellDef="let element">
                  {{ element?.insuranceType || "-" }}
                </td>
              </ng-container>

              <!-- CSR -->
              <ng-container matColumnDef="csr">
                <th
                  class="header"
                  mat-header-cell
                  mat-sort-header
                  *matHeaderCellDef
                >
                  <!-- CSR -->
                  <mat-form-field class="filter">
                    <input
                      matInput
                      (keydown.space)="$event.stopPropagation()"
                      (click)="$event.stopPropagation()"
                      formControlName="csr"
                      autocomplete="new-csr"
                    />
                    <mat-placeholder class="center">CSR</mat-placeholder>
                  </mat-form-field>
                </th>
                <td mat-cell *matCellDef="let element">
                  {{ element?.csrName || "-" }}
                </td>
              </ng-container>

              <!-- Agent -->
              <ng-container matColumnDef="agent">
                <th
                  class="header"
                  mat-header-cell
                  mat-sort-header
                  *matHeaderCellDef
                >
                  <!-- Agent -->
                  <mat-form-field class="filter">
                    <input
                      matInput
                      (keydown.space)="$event.stopPropagation()"
                      (click)="$event.stopPropagation()"
                      formControlName="agent"
                      autocomplete="new-agent"
                    />
                    <mat-placeholder class="center">Agent</mat-placeholder>
                  </mat-form-field>
                </th>
                <td mat-cell *matCellDef="let element">
                  {{ element?.agentName || "-" }}
                </td>
              </ng-container>

              <!-- WIP Status -->
              <ng-container matColumnDef="wipStatus">
                <th
                  class="header"
                  mat-header-cell
                  mat-sort-header
                  *matHeaderCellDef
                >
                  <!-- WIP Status -->
                  <mat-form-field class="filter">
                    <input
                      matInput
                      (keydown.space)="$event.stopPropagation()"
                      (click)="$event.stopPropagation()"
                      formControlName="wipStatus"
                      autocomplete="new-wipStatus"
                    />
                    <mat-placeholder class="center">WIP Status</mat-placeholder>
                  </mat-form-field>
                </th>
                <td mat-cell *matCellDef="let element">
                  <div [ngSwitch]="element?.wipStatus">
                    <div *ngSwitchCase="'yetToStart'">Yet To Start</div>
                    <div *ngSwitchCase="'hold'">Hold</div>
                    <div *ngSwitchCase="'inProcess'">In Process</div>
                    <div *ngSwitchCase="'completed'">Completed</div>
                    <div *ngSwitchCase="'NewSetupAssigned'">
                      New Setup Assigned
                    </div>
                    <div *ngSwitchCase="'NewSetupPending'">
                      New Setup Pending
                    </div>
                    <div *ngSwitchCase="'NewSetupInprogress'">
                      New Setup InProgress
                    </div>
                    <div *ngSwitchCase="'NewSetupCompleted'">
                      New Setup Completed
                    </div>
                    <div *ngSwitchCase="'VerificationAssigned'">
                      Verification Assigned
                    </div>
                    <div *ngSwitchCase="'VerificationPending'">
                      Verification Pending
                    </div>
                    <div *ngSwitchCase="'VerificationInprogress'">
                      Verification InProgress
                    </div>
                    <div *ngSwitchCase="'VerificationCompleted'">
                      Verification Completed
                    </div>
                    <div *ngSwitchCase="'AuthorizationAssigned'">
                      Authorization Assigned
                    </div>
                    <div *ngSwitchCase="'AuthorizationPending'">
                      Authorization Pending
                    </div>
                    <div *ngSwitchCase="'AuthorizationInprogress'">
                      Authorization InProgress
                    </div>
                    <div *ngSwitchCase="'AuthorizationCompleted'">
                      Authorization Completed
                    </div>
                    <div *ngSwitchDefault></div>
                  </div>
                </td>
              </ng-container>

              <!-- Auth Status -->
              <ng-container matColumnDef="authStatus">
                <th
                  class="header"
                  mat-header-cell
                  mat-sort-header
                  *matHeaderCellDef
                >
                  <!-- Auth Status -->
                  <mat-form-field class="filter">
                    <input
                      matInput
                      (keydown.space)="$event.stopPropagation()"
                      (click)="$event.stopPropagation()"
                      formControlName="authStatus"
                      autocomplete="new-authStatus"
                    />
                    <mat-placeholder class="center"
                      >Auth Status</mat-placeholder
                    >
                  </mat-form-field>
                </th>
                <td mat-cell *matCellDef="let element">
                  {{ element?.authStatusName || "-" }}
                </td>
              </ng-container>
              <!-- Verification Completed Date -->
              <ng-container matColumnDef="verificationCompletedDate">
                <th
                  class="header"
                  mat-header-cell
                  mat-sort-header
                  *matHeaderCellDef
                >
                  <!-- Verification Completed Date -->
                  <mat-form-field class="filter">
                    <input
                      matInput
                      (keydown.space)="$event.stopPropagation()"
                      (click)="$event.stopPropagation()"
                      formControlName="verificationCompletedDate"
                      autocomplete="new-verificationCompletedDate"
                    />
                    <mat-placeholder class="center"
                      >Verification Date</mat-placeholder
                    >
                  </mat-form-field>
                </th>
                <td mat-cell *matCellDef="let element">
                  {{ element?.verificationCompletedDate || "-" }}
                </td>
              </ng-container>
              <!-- Document Id -->
              <!-- <ng-container matColumnDef="documentId">
                    <th
                      class="header"
                      mat-header-cell
                      mat-sort-header
                      *matHeaderCellDef
                    >
                      <mat-form-field class="filter">
                        <input
                          matInput
                          (keydown.space)="$event.stopPropagation()"
                          (click)="$event.stopPropagation()"
                          formControlName="documentId"
                          autocomplete="off"
                        />
                        <mat-placeholder class="center"
                          >Document Id</mat-placeholder
                        >
                      </mat-form-field>
                    </th>
                    <td mat-cell *matCellDef="let element">
                      {{ element?.documentId }}
                    </td>
                  </ng-container> -->

              <!-- Aging Days -->
              <!-- <ng-container matColumnDef="agingDays">
                    <th
                      class="header"
                      mat-header-cell
                      mat-sort-header
                      *matHeaderCellDef
                    >

                      <mat-form-field class="filter">
                        <input
                          matInput
                          (keydown.space)="$event.stopPropagation()"
                          (click)="$event.stopPropagation()"
                          formControlName="agingDays"
                          autocomplete="off"
                        />
                        <mat-placeholder class="center">Aging Days</mat-placeholder>
                      </mat-form-field>
                    </th>
                    <td mat-cell *matCellDef="let element">
                      {{ element?.agingDays || "-" }}
                    </td>
                  </ng-container> -->

              <!-- Stat-->
              <ng-container matColumnDef="Stat">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>STAT</th>
                <td mat-cell *matCellDef="let element">
                  <ng-container>
                    {{ element.isStat == 1 ? "Yes" : "No" }}
                  </ng-container>
                </td>
              </ng-container>

              <!-- No Records-->
              <!-- <ng-container matColumnDef="noRecords">
                    <td
                      class="text-align-center"
                      mat-footer-cell
                      *matFooterCellDef
                      colspan="6"
                    >
                      <div *ngIf="!isLoading">
                        {{ "No Records Found" }}
                      </div>
                      <div [hidden]="!isLoading">
                        <mat-spinner [diameter]="220"></mat-spinner>
                      </div>
                    </td>
                  </ng-container> -->

              <tr
                mat-header-row
                *matHeaderRowDef="arrDisplayedColumnsSupplies"
              ></tr>
              <tr
                mat-row
                *matRowDef="let row; columns: arrDisplayedColumnsSupplies"
              ></tr>
            </table>
            <div
              *ngIf="
                !(
                  dataSourceSupplies &&
                  dataSourceSupplies?.data &&
                  dataSourceSupplies?.data?.length !== 0
                )
              "
              class="text-align-center"
            >
              <div class="message py-3" *ngIf="!isLoadingSupplies">
                {{ "No Records Found" }}
              </div>
              <div class="py-3" [hidden]="!isLoadingSupplies">
                <mat-spinner [diameter]="220"></mat-spinner>
              </div>
            </div>
            <mat-paginator
              [pageSizeOptions]="[10, 20, 50, 100]"
              showFirstLastButtons
            ></mat-paginator>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
