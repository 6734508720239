import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { MasterNotesService } from 'projects/admin/src/app/warehouse-proxy/inventory';
import {
  noWhitespaceValidator,
  positiveNumberValidator,
} from 'projects/patient/src/app/add-note-tab/add-note-tab.component';
import { CommunicationService } from 'projects/shared/src/app/services/communication.service';
import Swal from 'sweetalert2';
import { ItemPaymentService } from '../claim-proxy/claim-processing-management/rcm/claim-processing-management/x12/item-payment.service';
import { defaultGuid } from 'projects/shared/src/app/enums/allenums.enum';
import {
  ProductDetailsHCPC,
  writeOffCreateDTO,
  WriteOffProdAdjustment,
} from '../claim-proxy/claim-processing-management/rcm/claim-processing-management/x12/dto/models';
import { RCMCodeTypeService } from '../billing-proxy/billing-management/rcm/billing-management/optimization/billing-reports/rcmcode-type.service';
import { PaymentCodeTypeDTO } from '../billing-proxy/billing-management/rcm/billing-management/optimization/dto/models';

@Component({
  selector: 'app-writeOff-Model',
  templateUrl: './writeOff-Model.component.html',
  styleUrls: ['./writeOff-Model.component.scss'],
})
export class WriteOffModelComponent implements OnInit {
  writeOffForm: FormGroup;
  isShowSpinner: boolean = false;
  notesText = '';
  listNoteType: any;
  drpNotetype: any;
  drpNotetypelist: any;
  currentBalance: number = 0;
  lstProductCode: ProductDetailsHCPC[] = [];
  filteredProductCodeList: ProductDetailsHCPC[];
  productFilterControl = new FormControl();
  lstAdjustmentsCode: WriteOffProdAdjustment[] = [];
  filteredAdjusetmentsCodeList: WriteOffProdAdjustment[];
  adjustmentsFilterControl = new FormControl();
  lstWriteOffCode: PaymentCodeTypeDTO[] = [];
  filteredWriteOffList: PaymentCodeTypeDTO[];
  writeOffFilterControl = new FormControl();
  noteTypeId: string;
  noteType: string;
  productId: string;
  itemId: string;
  selectedGlossaryIds: string[] = [];
  isSavedOrUpdated: boolean = false;
  constructor(
    public WriteOffDialogRef: MatDialogRef<WriteOffModelComponent>,
    private fb: FormBuilder,
    private notesService: MasterNotesService,
    private toastr: ToastrService,
    private dialog: MatDialogRef<WriteOffModelComponent>,
    private communicationService: CommunicationService,
    private itemPaymentService: ItemPaymentService,
    private rCMCodeTypeService: RCMCodeTypeService,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      claimId: string;
    } = {
      claimId: defaultGuid,
    }
  ) {}

  ngOnInit() {
    this.initializationform();
    // this.noteTypeDetails();
    this.getProductCode();
    this.getWriteOffCode();
    this.writeOffForm.controls['drpNotetype'].disable();
  }
  onCloseClick() {
    this.WriteOffDialogRef.close(this.isSavedOrUpdated);
  }
  initializationform() {
    this.writeOffForm = this.fb.group({
      rdBalanceType: new FormControl(1, [Validators.required]),
      drpProductCode: new FormControl('', [Validators.required]),
      txtProduct: new FormControl(''),
      drpAdjustment: new FormControl(''),
      txtAdjustments: new FormControl(''),
      txtBalanceAmount: new FormControl('', [
        Validators.required,
        positiveNumberValidator,
      ]),
      drpNotetype: new FormControl('Finance', [Validators.required]),
      drpWriteOff: new FormControl('', [Validators.required]),
      txtWriteOff: new FormControl(''),
      txtNoteType: new FormControl(''),
      txtWriteOffeAmount: new FormControl('', [Validators.required]),
      txtComments: new FormControl('', [
        Validators.required,
        noWhitespaceValidator,
      ]),
    });
  }

  getWriteOffCode() {
    console.log('getWriteOffCode');
    this.rCMCodeTypeService.getListV1().subscribe(
      (response) => {
        this.lstWriteOffCode = response?.items || [];
        this.filteredWriteOffList = this.lstWriteOffCode;
        this.writeOffFilterControl.valueChanges.subscribe((FilterText) => {
          FilterText = FilterText.toLowerCase();
          this.filteredWriteOffList = this.lstWriteOffCode.filter((dx) =>
            dx.description.toLowerCase().includes(FilterText)
          );
        });
      },
      (err) => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      }
    );
  }
  getProductCode() {
   // Reset form fields
  this.writeOffForm.patchValue({
    drpProductCode: '',
    drpAdjustment: '',
    txtBalanceAmount: '',
    drpWriteOff: '',
    txtWriteOffeAmount: '',
    txtComments: '',
  });

  // Mark form as pristine, untouched, and validate it
  this.writeOffForm.markAsPristine();
  this.writeOffForm.markAsUntouched();
  this.writeOffForm.updateValueAndValidity();

    this.filteredAdjusetmentsCodeList=[]
    this.itemPaymentService
      .getWriteOffItemDetailsByIClaimId(this.data.claimId)
      .subscribe(
        (response) => {
          this.lstProductCode = response || [];

          this.filteredProductCodeList = this.lstProductCode;
          this.productFilterControl.valueChanges.subscribe((FilterText) => {
            FilterText = FilterText.toLowerCase();
            this.filteredProductCodeList = this.lstProductCode.filter((dx) =>
              dx.hcpccode.toLowerCase().includes(FilterText)
            );
          });
        },
        (err) => {
          const data: HttpErrorResponse = err;
          Swal.fire({
            icon: 'info',
            text: data?.error?.error?.message,
          });
        }
      );
  }
  getAdjustments() {
    this.writeOffForm.patchValue({
      txtBalanceAmount: 0,
      drpAdjustment: null,
      txtWriteOffeAmount: 0,
    });
    this.selectedGlossaryIds = [];
    const index = this.lstProductCode.findIndex(
      (obj) => obj.itemId === this.writeOffForm.get('drpProductCode')?.value
    );
    if (index !== -1) {
      this.lstAdjustmentsCode =
        this.lstProductCode[index]?.writeOffProdAdjustment || [];
      this.currentBalance = this.lstProductCode[index]?.currentBalance || 0;
      this.noteTypeId = this.lstProductCode[index]?.noteTypeId || defaultGuid;
      this.noteType = this.lstProductCode[index]?.noteType || 'Finance';
      this.productId = this.lstProductCode[index]?.productId || defaultGuid;
      this.itemId = this.lstProductCode[index]?.itemId || defaultGuid;
      this.writeOffForm.patchValue({
        txtBalanceAmount: this.currentBalance,
        txtWriteOffeAmount: this.currentBalance,
      });
      this.filteredAdjusetmentsCodeList = this.lstAdjustmentsCode;
      this.adjustmentsFilterControl.valueChanges.subscribe((FilterText) => {
        console.log(this.lstAdjustmentsCode);
        FilterText = FilterText.toLowerCase();
        this.filteredAdjusetmentsCodeList = this.lstAdjustmentsCode.filter(
          (dx) => dx.reasonCode.toLowerCase().includes(FilterText)
        );
      });
    } else {
      this.filteredAdjusetmentsCodeList = this.lstAdjustmentsCode = [];
    }
  }

  updateBalance() {
    // Extract selected adjustment values (assuming it's an array)
    this.selectedGlossaryIds = this.writeOffForm.get('drpAdjustment')?.value;
    if (this.selectedGlossaryIds?.length >= 1) {
      // Filter items where glossaryId is included in the selectedGlossaryIds
      const foundItems = this.lstAdjustmentsCode.filter((item) =>
        this.selectedGlossaryIds.includes(item.glossaryId)
      );
      // Calculate the sum of the adjustments for the found items
      const sum = foundItems.reduce((acc, value) => acc + +value.adjustment, 0);
      // Update the form with the calculated sum
      this.writeOffForm.patchValue({
        txtBalanceAmount: sum,
        txtWriteOffeAmount: sum,
      });
    } else {
      this.writeOffForm.patchValue({
        txtBalanceAmount: this.currentBalance,
        txtWriteOffeAmount: this.currentBalance,
      });
      this.selectedGlossaryIds = [];
    }
  }
  //Update Character Count for TextArea
  updateCharacterCount(maxlength: number) {
    // Ensure the text length does not exceed the maximum length
    if (this.notesText?.length > maxlength) {
      this.notesText = this.notesText?.substr(0, maxlength);
    }
  }

  saveWriteOff() {
    this.isShowSpinner = true;

    const index = this.filteredWriteOffList.findIndex(
      (obj) => obj.id === this.writeOffForm.get('drpWriteOff')?.value
    );
    let description = '';
    if (index !== -1) {
      description = this.filteredWriteOffList[index].description;
    }

    const hcpcindex = this.filteredProductCodeList.findIndex(
      (obj) => obj.itemId === this.writeOffForm.get('drpProductCode')?.value
    );
    let hcpc = '';
    if (hcpcindex !== -1) {
      hcpc = this.filteredProductCodeList[hcpcindex].hcpccode;
    }

    let input: writeOffCreateDTO = {
      isPatBlance:
        this.writeOffForm.get('rdBalanceType')?.value == 2 ? true : false,
      itemId: this.itemId,
      productId: this.productId,
      claimId: this.data.claimId,
      hcpcCode: hcpc,
      adjustmentAmount: this.writeOffForm.get('txtWriteOffeAmount')?.value,
      // adjustReason : this.writeOffForm.get('drpAdjustment')?.value,
      // adjustDescription : this.writeOffForm.get('txtAdjustments')?.value,
      glosseryId: this.writeOffForm.get('drpAdjustment')?.value || [], //this.glosseryId,
      wrirteoff: this.writeOffForm.get('drpWriteOff')?.value,
      wrirteoffText: description,
      noteType: this.noteType,
      noteTypeId: this.noteTypeId,
      comments: this.writeOffForm.get('txtComments')?.value,
    };
    this.itemPaymentService.saveWriteOffByInput(input).subscribe(
      (response) => {
        this.toastr.success(response, 'Success');
        this.isSavedOrUpdated = true;
        this.isShowSpinner = false;
        // this.WriteOffDialogRef.close(this.isSavedOrUpdated);
        this.getProductCode();
      },
      (err) => {
        this.isShowSpinner = false;
        const data: HttpErrorResponse = err;
        this.isSavedOrUpdated = true;
        // this.WriteOffDialogRef.close(this.isSavedOrUpdated);
        this.getProductCode();
        this.toastr.error(data?.error?.error?.message, 'Error');
        // Swal.fire({
        //   icon: 'info',
        //   text: data?.error?.error?.message,
        // });
      }
    );
  }
}
