import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { DataTableDirective } from 'angular-datatables';
import { CommunicationService } from 'projects/shared/src/app/services/communication.service';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { Subject, Subscription } from 'rxjs';
import Swal from 'sweetalert2';
import {
  Status,
  VendorDetailsService,
} from '../../item-proxy/item-management/optimization';
import { HttpErrorResponse } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-product-vendor-table-list',
  templateUrl: './product-vendor-table-list.component.html',
  styleUrls: ['./product-vendor-table-list.component.scss'],
})
export class ProductVendorTableListComponent implements OnInit {
  @Input() vendorList: any;

  vendorOptions: any = {
    responsive: true,
    paging: false,
    dom: 'Bfrtip',
    language: {
      emptyTable: 'No records',
      info: 'Total : _MAX_ records',
      infoEmpty: 'Total : _MAX_ records',
      infoFiltered: '(filtered : _TOTAL_ records)',
      loadingRecords: 'Loading...',
      zeroRecords: 'No matching records',
    },
    fixedHeader: true, // Fix the header

    scrollCollapse: true,
    scrollY: '500px',
    scrollX: true,
    order: [],
    search: {
      smart: false,
    },
    buttons: [
      {
        extend: 'excel',
        text: 'Export', // Change the text to 'Export'
        filename: 'Product Manufacturer List',
        exportOptions: {
          columns: ':visible:not(:nth-child(1),:nth-child(2))',
          // columns: [1, 2, 3, 4, 5, 6, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19], // Specify the columns you want to export (0-indexed)
        },
      },
      {
        extend: 'colvis',
        columns: ':not(:eq(0), :eq(1), :eq(2),:eq(3), :eq(-1))', // Exclude first three and last columns from the column visibility toggle
      },
    ],
    columnDefs: [
      { targets: [0, 1, 2, 3, -1], className: 'dt-fixed-column' }, // Ad
    ],
  };

  @ViewChild(DataTableDirective, { static: false })
  datatableElement: DataTableDirective;
  subscription$: Subscription[] = [];
  constructor(
    private communicationService: CommunicationService,
    private productVendorService: VendorDetailsService,
    private toastr: ToastrService,
    private datepipe: DatePipe
  ) {}

  ngOnInit() {
    this.vendorOptions.buttons[0].filename =
    'Product Manufacturer List ' +  '-' + this.datepipe.transform(new Date(),'MM-dd-yyyy') ;
    this.calculateScrollY();
    window.addEventListener('resize', this.calculateScrollY);
  }

  ngOnDestroy(): void {
    window.removeEventListener('resize', this.calculateScrollY);
    this.subscription$.forEach((sub) => {
      sub && sub?.unsubscribe();
    });
  }
  ngAfterViewInit(): void {
    if (this.datatableElement && this.datatableElement.dtInstance) {
      this.refreshDataTable();
    } else {
      // If dtInstance is not available yet, wait for a short time and try again
      setTimeout(() => this.refreshDataTable(), 500);
    }
  }

  calculateScrollY = () => {
    const screenHeight =
      window.innerHeight ||
      document.documentElement.clientHeight ||
      document.body.clientHeight;
    const availableSpacePercentage = 0.5; // Adjust the percentage as needed
    this.vendorOptions.scrollY = screenHeight * availableSpacePercentage;
  };
  refreshDataTable(): void {
    this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
      // Clear search input values
      $('input', dtInstance.table(0).footer()).val('');

      // Redraw the DataTable to load all records
      dtInstance.search('').columns().search('').draw();

      // Attach the event listener for filtering
      dtInstance.columns().every(function () {
        const that = this;
        $('input', this.footer()).on('keyup change', function () {
          if (that.search() !== this['value']) {
            that.search(this['value']).draw();
          }
        });
      });
    });
  }
  triggerToggle() {
    this.communicationService.triggerInsuranceAllowableToggle();
  }
  getInsAllowablesById(id: string) {
    this.communicationService.triggerInsuranceAllowableGetMethodCall(id);
  }
  // statusToggleTrigger(toggle: MatSlideToggleChange, data: any) {
  //   this.communicationService.triggerStatusGetMethodCall(toggle, data);
  // }
  statusToggleTrigger(event: MatSlideToggleChange, data: any) {
    if (event.checked == true) {
      Swal.fire({
        title: 'Do you want to activate?',
        text: 'By Changing Status, this Manufacturer will be activated',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#34c38f',
        cancelButtonColor: '#f46a6a',
        confirmButtonText: 'Yes, Activate it!',
      }).then((result) => {
        if (result.value) {
          let productCategoryDetails = {
            ...(data ?? null),
            status: Status.Active,
          };
          if (
            data?.id &&
            data?.id !== '' &&
            data?.id !== null &&
            data?.id !== undefined
          ) {
            // this.isLoadData = false;
            this.productVendorService
              .update(data?.id, productCategoryDetails)
              .subscribe(
                (response) => {
                  data.status = 'Active';
                  this.toastr.success('Activated Successfully', 'Success');
                  event.source.checked = event.checked ? false : true;
                  // this.isLoadData = true;
                  this.communicationService.triggerReloadProduct();
                },
                (err) => {
                  this.communicationService.triggerReloadProduct();
                  // this.isLoadData = true;
                  const data: HttpErrorResponse = err;
                  event.source.checked = event.checked ? false : true;
                  Swal.fire({
                    icon: 'info',
                    text: data?.error?.error?.message,
                  });
                }
              );
          }
        } else {
          event.source.checked = event.checked ? false : true;
        }
      });
    } else if (event.checked == false) {
      Swal.fire({
        title: 'Do you want to deactivate?',
        text: 'By Changing Status, this Manufacturer will be deactivated',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#34c38f',
        cancelButtonColor: '#f46a6a',
        confirmButtonText: 'Yes, deactivate it!',
      }).then((result) => {
        if (result.value) {
          let productCategoryDetails = {
            ...(data ?? null),
            status: Status.Inactive,
          };
          if (
            data?.id &&
            data?.id !== '' &&
            data?.id !== null &&
            data?.id !== undefined
          ) {
            // this.isLoadData = false;
            this.productVendorService
              .update(data?.id, productCategoryDetails)
              .subscribe(
                (response) => {
                  data.status = 'Inactive';
                  const index = this.vendorList.findIndex(
                    (obj) => obj.id === response?.id
                  );
                  if (index !== -1) {
                    this.vendorList[index] = response; //Replace the Object
                  }
                  this.toastr.success('Deactivated Successfully', 'Success');
                  event.source.checked = event.checked ? false : true;
                  this.communicationService.triggerReloadProduct();
                },
                (err) => {
                  this.communicationService.triggerReloadProduct();
                  // this.isLoadData = true;
                  event.source.checked = event.checked ? false : true;
                  const data: HttpErrorResponse = err;
                  Swal.fire({
                    icon: 'info',
                    text: data?.error?.error?.message,
                  });
                }
              );
          }
        } else {
          event.source.checked = event.checked ? false : true;
        }
      });
    }
  }
}