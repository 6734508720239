import type { CreateUpdateMmOrderItemSettingsDTO, MmOrderItemSettingsDTO, machineSettingsDTO } from './dto/models';
import { RestService } from '@abp/ng.core';
import type { PagedAndSortedResultRequestDto, PagedResultDto } from '@abp/ng.core';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class MmOrderItemSettingsService {
  apiName = 'orderManagement';

  create = (input: CreateUpdateMmOrderItemSettingsDTO) =>
    this.restService.request<any, MmOrderItemSettingsDTO>({
      method: 'POST',
      url: '/api/orderApp/mm-order-item-settings',
      body: input,
    },
    { apiName: this.apiName });

  delete = (id: string) =>
    this.restService.request<any, void>({
      method: 'DELETE',
      url: `/api/orderApp/mm-order-item-settings/${id}`,
    },
    { apiName: this.apiName });

  get = (id: string) =>
    this.restService.request<any, MmOrderItemSettingsDTO>({
      method: 'GET',
      url: `/api/orderApp/mm-order-item-settings/${id}`,
    },
    { apiName: this.apiName });

  getActiveList = (input: PagedAndSortedResultRequestDto) =>
    this.restService.request<any, PagedResultDto<MmOrderItemSettingsDTO>>({
      method: 'GET',
      url: '/api/orderApp/mm-order-item-settings/active-list',
      params: { sorting: input.sorting, skipCount: input.skipCount, maxResultCount: input.maxResultCount },
    },
    { apiName: this.apiName });

  getList = (input: PagedAndSortedResultRequestDto) =>
    this.restService.request<any, PagedResultDto<MmOrderItemSettingsDTO>>({
      method: 'GET',
      url: '/api/orderApp/mm-order-item-settings',
      params: { sorting: input.sorting, skipCount: input.skipCount, maxResultCount: input.maxResultCount },
    },
    { apiName: this.apiName });

  getOrderItemSettingsByOrderIdByOrderId = (OrderId: string) =>
    this.restService.request<any, MmOrderItemSettingsDTO[]>({
      method: 'GET',
      url: `/api/orderApp/mm-order-item-settings/order-item-settings-by-order-id/${OrderId}`,
    },
    { apiName: this.apiName });

  update = (id: string, input: CreateUpdateMmOrderItemSettingsDTO) =>
    this.restService.request<any, MmOrderItemSettingsDTO>({
      method: 'PUT',
      url: `/api/orderApp/mm-order-item-settings/${id}`,
      body: input,
    },
    { apiName: this.apiName });

  getMachineSettingsByProductIdByProductId = (productId: string) =>
    this.restService.request<any, machineSettingsDTO>({
      method: 'GET',
      url: `/api/orderApp/mm-order-item-settings/get-machine-settings-by-product-id/${productId}`,
    },
    { apiName: this.apiName });

  constructor(private restService: RestService) {}
}
