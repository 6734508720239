import { PagedResultDto, RestService } from '@abp/ng.core';
import { Injectable } from '@angular/core';
import type { PaymentMethodBillingStatusDTO, PaymentsReportDTO } from '../dto/models';

@Injectable({
  providedIn: 'root',
})
export class BillingReportService {
  apiName = 'billingManagement';

  getPaymentMethodsByBFilter = (bFilter: boolean) =>
    this.restService.request<any, PaymentMethodBillingStatusDTO[]>({
      method: 'GET',
      url: '/api/billingApp/billing-report/get-payment-methods',
      params: { bFilter },
    },
    { apiName: this.apiName });

  getPaymentsBillingReportByPaymentMethodStatusAndDtCreatedFromDateAndDtCreatedToDateAndSReferenceNumberAndDtRemittanceFromDateAndDtRemittanceToDate = (paymentMethodStatus: string, dtCreatedFromDate: string, dtCreatedToDate: string, sReferenceNumber: string, dtRemittanceFromDate: string, dtRemittanceToDate: string) =>
    this.restService.request<any, PaymentsReportDTO[]>({
      method: 'GET',
      url: '/api/billingApp/billing-report/get-payments-billing-report',
      params: { paymentMethodStatus, dtCreatedFromDate, dtCreatedToDate, sReferenceNumber, dtRemittanceFromDate, dtRemittanceToDate },
    },
    { apiName: this.apiName });

    getPaymentsBillingReportByPageNoAndPageOffSet = (paymentMethodStatus: string, dtCreatedFromDate: string, dtCreatedToDate: string, sReferenceNumber: string, dtRemittanceFromDate: string, dtRemittanceToDate: string, pageNo: number, pageOffSet: number) =>
      this.restService.request<any, PagedResultDto<PaymentsReportDTO>>({
        method: 'GET',
        url: '/api/billingApp/billing-report/get-payments-billing-report',
        params: { paymentMethodStatus, dtCreatedFromDate, dtCreatedToDate, sReferenceNumber, dtRemittanceFromDate, dtRemittanceToDate, pageNo, pageOffSet },
      },
      { apiName: this.apiName });


  importBillingStatus = () =>
    this.restService.request<any, string>({
      method: 'POST',
      responseType: 'text',
      url: '/api/billingApp/billing-report/import-billing-status',
    },
    { apiName: this.apiName });

  constructor(private restService: RestService) {}
}
