<!-- custom-dropdown.component.html -->
<table #table>
  <!-- Your table structure goes here -->
  <tr>
    <th>Column 1</th>
    <th>Column 2</th>
    <!-- Add more columns as needed -->
  </tr>
  <tr *ngFor="let item of items">
    <td>{{ item.column1 }}</td>
    <td>{{ item.column2 }}</td>
    <!-- Display other columns as needed -->
  </tr>
</table>
