<div class="container-fluid">
  <div class="row bg">
    <div class="col-12">
      <div class="d-flex align-item-center justify-content-between m-0">
        <h4 class="mt-2 mb-0 font-size-18"><b>Stock</b></h4>
        <div class=" page-title-box  page-title-right mt-1 mb-0">
          <ol class="breadcrumbs m-0">
            <!-- (click)="exportTableData()" -->
            <button mat-button class="mr-2 buttonColor bttn"
    [disabled]="(dataSource && dataSource?.data && dataSource?.data?.length === 0)"

            (click)="exportTableData()">
              Export
            </button>
            <li class="breadcrumb-item">Inventory</li>
            <li class="breadcrumb-item">Stock</li>
          </ol>
        </div>
      </div>
    </div>
    </div>
    <div class="col-12">
    <div class="card card-body">
      <mat-accordion>
        <mat-expansion-panel [expanded]="step">
          <mat-expansion-panel-header>
            <mat-panel-title class="customThemeClass">
              <ng-container *ngIf="vendorId === ''; else elseTitleTemplate">
                <b>Add Stocks</b>
                <span class="blinking mt-2 ml-2"> {{qtyNeedToAddInStock}} </span>
              </ng-container>
              <ng-template #elseTitleTemplate>
                <b>Edit Stocks</b>
              </ng-template>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <form [formGroup]="stockForm">
            <div >
              <mat-card-content>
                <div class=" row ">
                  <mat-form-field class="col-3">
                    <mat-label>Product Name<span class="asterisk">*</span> </mat-label>
                    <mat-select (selectionChange)="onChanges($event)" formControlName="txtMainProduct"
                      [(value)]="MainProduct" #singleSelect>
                      <mat-option>
                        <ngx-mat-select-search placeholderLabel="Search" noEntriesFoundLabel="No Matches found"
                          formControlName="txtMainProductFilter"></ngx-mat-select-search>
                      </mat-option>
                      <mat-option *ngFor="let provider of filterMainProduct | async" [value]="provider">
                        {{provider.productName}}
                      </mat-option>
                    </mat-select>

                    <button mat-button (click)="MainProduct=undefined;$event.stopPropagation()" *ngIf="MainProduct"
                      matSuffix (click)="clearMainProduct()" mat-icon-button >
                      <mat-icon>close</mat-icon>
                    </button>
                    <mat-error *ngIf="
                    stockForm?.get('txtMainProduct')?.touched &&
                    stockForm?.get('txtMainProduct')?.errors?.required
                                    ">
                      Product Name is a required field!
                    </mat-error>
                  </mat-form-field>

                  <mat-form-field class="col-3">
                    <mat-label>Brand Name <span class="asterisk">*</span></mat-label>
                    <!-- <input maxlength="40" formControlName="txtBrandName"
                                          matInput type="text" /> -->
                    <mat-select formControlName="txtBrandName" [(value)]="brand" #singleSelect>
                      <mat-option>
                        <ngx-mat-select-search placeholderLabel="Search" noEntriesFoundLabel="No Matches found"
                          formControlName="txtbrandFilter"></ngx-mat-select-search>
                      </mat-option>
                      <mat-option *ngFor="let brand of filterbrand | async" [value]="brand">
                        {{brand.name}}
                      </mat-option>
                    </mat-select>

                    <button mat-button (click)="brand=undefined;$event.stopPropagation()" *ngIf="brand" matSuffix
                      (click)="clearbrand()" mat-icon-button >
                      <mat-icon>close</mat-icon>
                    </button>

                    <mat-error *ngIf="
                        stockForm?.get('txtBrandName')?.touched &&
                        stockForm?.get('txtBrandName')?.errors?.required
                                        ">
                      Brand Name is a required field!
                    </mat-error>
                  </mat-form-field>

                  <mat-form-field class="col-3">
                    <mat-label>Manufacturer  Name <span class="asterisk">*</span></mat-label>
                    <!-- <input maxlength="40" formControlName="txtVendorName"
                              matInput type="text" /> -->
                    <mat-select formControlName="txtVendorName" [(value)]="supplyVendor" #singleSelect>
                      <mat-option>
                        <ngx-mat-select-search placeholderLabel="Search" noEntriesFoundLabel="No Matches found"
                          formControlName="txtVendorFilter"></ngx-mat-select-search>
                      </mat-option>
                      <mat-option *ngFor="let provider of filterVendor | async" [value]="provider.name">
                        {{provider.name}}
                      </mat-option>
                    </mat-select>

                    <button mat-button (click)="supplyVendor=undefined;$event.stopPropagation()" *ngIf="supplyVendor"
                      matSuffix (click)="clearsupplyVendor()" mat-icon-button >
                      <mat-icon>close</mat-icon>
                    </button>
                    <mat-error
                      *ngIf="stockForm?.get('txtVendorName')?.touched &&stockForm?.get('txtVendorName')?.errors?.required">
                      Manufacturer  Name is a required field!
                    </mat-error>
                  </mat-form-field>
                  <!-- newly added fields -->




                  <!-- <mat-form-field class="col-3">
                    <mat-label> Last Count Stock Date <span class="asterisk">*</span></mat-label>
                    <input formControlName="txtCountdate" matInput maxlength="40" type="text" placeholder="(MM/DD/YYYY)"
                      [matDatepicker]="txtCountdate" />
                    <mat-datepicker-toggle matSuffix [for]="txtCountdate"> </mat-datepicker-toggle>
                    <mat-datepicker #txtCountdate> </mat-datepicker>
                    <mat-error *ngIf="
                            stockForm?.get('txtCountdate')?.touched &&
                            stockForm?.get('txtCountdate')?.errors">
                      Enter Valid date
                    </mat-error>
                    <mat-error
                      *ngIf=" stockForm?.get('txtCountdate')?.touched && stockForm?.get('txtCountdate')?.errors?.required">
                      Last Count Stock Date is a required field!
                    </mat-error>

                  </mat-form-field> -->


                  <mat-form-field class="col-3">
                    <mat-label>Category Name <span class="asterisk">*</span></mat-label>
                    <!-- <input maxlength="40" formControlName="txtCategoryName"
                                          matInput type="text" /> -->
                    <mat-select formControlName="txtCategoryName" [(value)]="Category" #singleSelect>
                      <mat-option>
                        <ngx-mat-select-search placeholderLabel="Search" noEntriesFoundLabel="No Matches found"
                          formControlName="txtCategoryFilter"></ngx-mat-select-search>
                      </mat-option>
                      <mat-option *ngFor="let category of filterCategory | async" [value]="category.name">
                        {{category.name}}
                      </mat-option>
                    </mat-select>

                    <button mat-button (click)="Category=undefined;$event.stopPropagation()" *ngIf="Category" matSuffix
                      (click)="clearCategory()" mat-icon-button >
                      <mat-icon>close</mat-icon>
                    </button>
                    <mat-error *ngIf="
                        stockForm?.get('txtCategoryName')?.touched &&
                        stockForm?.get('txtCategoryName')?.errors?.required
                                        ">
                      Category Name is a required field!
                    </mat-error>
                  </mat-form-field>
                  <mat-form-field class="col-3">
                    <mat-label>Lot No<span class="asterisk">*</span></mat-label>
                    <input autocomplete="new-txtLotNo" maxlength="40" formControlName="txtLotNo" matInput type="text" pattern="[a-zA-Z0-9]*" />
                    <mat-error *ngIf="
                      stockForm?.get('txtLotNo')?.touched &&
                      stockForm?.get('txtLotNo')?.errors?.required
                                ">
                      Lot No is a required field!
                    </mat-error>
                    <mat-error *ngIf="
                      stockForm?.get('txtLotNo')?.touched &&
                      stockForm?.get('txtLotNo')?.errors?.pattern
                    ">
                      Enter only Alphanumeric!
                    </mat-error>
                  </mat-form-field>
                  <mat-form-field class="col-3">
                    <mat-label>Qty<span class="asterisk">*</span></mat-label>
                    <input autocomplete="new-txtQty" maxlength="40" formControlName="txtQty" matInput type="text" />
                    <mat-error *ngIf="
                        stockForm?.get('txtQty')?.touched &&
                        stockForm?.get('txtQty')?.errors?.required
                                  ">
                      Units is a required field!
                    </mat-error>
                    <mat-error *ngIf="
                        stockForm?.get('txtQty')?.touched &&
                        stockForm?.get('txtQty')?.errors?.number
                      ">
                      Enter only Numbers!
                    </mat-error>
                    <mat-error *ngIf="
                    stockForm?.get('txtQty')?.touched &&
                    stockForm?.get('txtQty')?.errors
                  ">
                      Enter Greater than 1!
                    </mat-error>
                  </mat-form-field>

                  <mat-form-field [hidden]="true" class="col-3">
                    <mat-label>Minimum Units<span class="asterisk">*</span></mat-label>
                    <input autocomplete="new-txtMinQty" maxlength="40" min="1" formControlName="txtMinQty" matInput type="text" />
                    <mat-error *ngIf="
                        stockForm?.get('txtMinQty')?.touched &&
                        stockForm?.get('txtMinQty')?.errors?.required
                                  ">
                      Units is a required field!
                    </mat-error>


                    <mat-error *ngIf="
                        stockForm?.get('txtMinQty')?.touched &&
                        stockForm?.get('txtMinQty')?.errors?.number
                      ">
                      Enter only Numbers!
                    </mat-error>
                  </mat-form-field>

                  <mat-form-field class="col-3">
                    <mat-label>Price per Unit <span class="asterisk">*</span></mat-label>
                    <span matPrefix>$ &nbsp;</span>
                    <input autocomplete="new-txtPrice" maxlength="40" formControlName="txtPrice" matInput type="text" />
                    <mat-error *ngIf="
                        stockForm?.get('txtPrice')?.touched &&
                        stockForm?.get('txtPrice')?.errors?.required
                                  ">
                      Price is a required field!
                    </mat-error>
                    <mat-error *ngIf=" stockForm?.get('txtPrice')?.touched && stockForm?.get('txtPrice')?.errors?.msg">
                      {{stockForm?.get('txtPrice')?.errors?.msg}}
                    </mat-error>
                  </mat-form-field>
                  <mat-form-field [hidden]="true" class="col-3">
                    <mat-label> Status<span class="asterisk">*</span> </mat-label>
                    <mat-select maxlength="40" [(value)]="status" formControlName="txtStatus"
                      [errorStateMatcher]="matcher">
                      <mat-option *ngFor="let status of drpStatus" [value]="status.productPurchaseStatus">
                        {{ status.productPurchaseStatus }}
                      </mat-option>
                    </mat-select>
                    <mat-error *ngIf="
                        stockForm?.get('txtStatus')?.touched &&
                        stockForm?.get('txtStatus')?.errors?.required
                                  ">
                      Status is a required field!
                    </mat-error>
                    <button mat-button (click)="status='';$event.stopPropagation()" *ngIf="status" matSuffix
                      (click)="clearstatus()" mat-icon-button >
                      <mat-icon>close</mat-icon>
                    </button>
                  </mat-form-field>



                  <mat-form-field class="col-3 readonly">
                    <mat-label> Stock Purchase Date <span class="asterisk">*</span></mat-label>
                    <input autocomplete="new-txtPurchasedate" formControlName="txtPurchasedate" matInput maxlength="40" type="text"
                      placeholder="(MM/DD/YYYY)" [matDatepicker]="txtPurchasedate" />
                    <mat-datepicker-toggle matSuffix [for]="txtPurchasedate"> </mat-datepicker-toggle>
                    <mat-datepicker #txtPurchasedate> </mat-datepicker>
                    <mat-error *ngIf="
                      stockForm?.get('txtPurchasedate')?.touched &&
                        stockForm?.get('txtPurchasedate')?.errors ">
                      Enter Valid date
                    </mat-error>
                    <mat-error
                      *ngIf=" stockForm?.get('txtPurchasedate')?.touched && stockForm?.get('txtPurchasedate')?.errors?.required">
                      Stock Purchase Date is a required field!
                    </mat-error>
                  </mat-form-field>



                  <!-- <mat-form-field class="col-3">
                    <mat-label> Stock Purchase Date <span class="asterisk">*</span></mat-label>
                    <input [max]="BirthdateMaxDate" formControlName="txtPurchasedate" matInput maxlength="40" type="text"
                      placeholder="(MM/DD/YYYY)" [matDatepicker]="txtPurchasedate" [min]="BirthdateMinDate" />
                    <mat-datepicker-toggle matSuffix [for]="txtPurchasedate"> </mat-datepicker-toggle>
                    <mat-datepicker #txtPurchasedate> </mat-datepicker>
                    <mat-error
                      *ngIf="
                        stockForm?.get('txtPurchasedate')?.touched &&
                        stockForm?.get('txtPurchasedate')?.errors && !(BirthdateMinDate<=stockForm?.get('txtPurchasedate')?.value)">
                      Enter Valid date
                    </mat-error>
                    <mat-error
                      *ngIf=" stockForm?.get('txtPurchasedate')?.touched && stockForm?.get('txtPurchasedate')?.errors?.required">
                      Birth Date is a required field!
                    </mat-error>
                    <mat-error
                      *ngIf=" BirthdateMinDate<=stockForm?.get('txtBirthdate')?.value && !(stockForm?.get('txtBirthdate')?.touched && stockForm?.get('txtBirthdate')?.errors?.required)">
                      Enter Past Dates Only!
                    </mat-error>
                  </mat-form-field> -->

                  <!-- <div class="col-3">
                    <br>
                    <mat-checkbox formControlName="chkRecall"> Recall
                    </mat-checkbox>
                  </div> -->
                </div>
              </mat-card-content>
            </div>
          </form>

          <br>
          <div class="col-sm-12">
            <div class="text-lg-center">
              <button *ngIf="saveHide" mat-button class="buttonColor mb-2 mr-2"
                [disabled]="!(!stockForm.invalid && stockForm.dirty)" (click)="saveStock()">
                Save
                <mat-icon *ngIf='saveButtonHide'>
                  <mat-spinner class="spinner-border spinner-border-sm" diameter="20"> </mat-spinner>
                </mat-icon>
              </button>
              <button mat-button class="resetclr buttonColor mb-2 mr-2" (click)="reset()">
                Reset
              </button>

            </div>
          </div>
          <br />
        </mat-expansion-panel>
      </mat-accordion><br>
    </div>
  </div>
  <div class="col-12">
      <div class="borderSpace card card-body">
        <h4 class="customThemeClass ml-3 mt-2"><b>Stock List</b></h4>
      <div class="mt-4">
        <form [formGroup]="vSearchForm">
          <div class="prodSpace row">
            <mat-form-field class="algin col-3">
              <mat-label>Product Name<span class="asterisk">*</span> </mat-label>
              <mat-select #roleSelect multiple formControlName="drpProduct" [(value)]="Product" #singleSelect>
                <mat-option>
                  <ngx-mat-select-search placeholderLabel="Search" noEntriesFoundLabel="No Matches found"
                    formControlName="txtMainProductFilter"></ngx-mat-select-search>
                </mat-option>
                <mat-option #allRolesSelected [value]="0" (click)="toggleAllSelection()">Select All Product</mat-option>

                <mat-option *ngFor="let provider of filterMainProducts | async" [value]="provider.productName">
                  {{provider.productName}}
                </mat-option>
              </mat-select>

              <button mat-button (click)="Product=undefined;$event.stopPropagation()" *ngIf="Product" matSuffix
                (click)="clearProduct()" mat-icon-button >
                <mat-icon>close</mat-icon>
              </button>

            </mat-form-field>

            <mat-form-field [hidden]=true class="col-3">
              <mat-label>Brand Name<span class="asterisk">*</span> </mat-label>
              <mat-select #BrandSelect multiple formControlName="drpBrand" [(value)]="Sbrand" #singleSelect>
                <mat-option>
                  <ngx-mat-select-search placeholderLabel="Search" noEntriesFoundLabel="No Matches found"
                    formControlName="txtSBrandFilter"></ngx-mat-select-search>
                </mat-option>
                <mat-option #allBrandSelected [value]="0" (click)="toggleBrandAllSelection()">Select All Brand
                </mat-option>

                <mat-option *ngFor="let provider of filterMainProducts | async" [value]="provider">
                  {{provider.productName}}
                </mat-option>
              </mat-select>

              <button mat-button (click)="Sbrand=undefined;$event.stopPropagation()" *ngIf="Sbrand" matSuffix
                (click)="clearSbrand()" mat-icon-button >
                <mat-icon>close</mat-icon>
              </button>

            </mat-form-field>

            <mat-form-field class="col-3">
              <mat-label>Status<span class="asterisk">*</span> </mat-label>
              <mat-select #StatusSelect multiple formControlName="drpStatus" [(value)]="Status" #singleSelect>
                <mat-option>
                  <ngx-mat-select-search placeholderLabel="Search" noEntriesFoundLabel="No Matches found"
                    formControlName="txtStatusFilter"></ngx-mat-select-search>
                </mat-option>
                <mat-option #allStatusSelected [value]="0" (click)="toggleStatusAllSelection()">Select All Status
                </mat-option>

                <mat-option *ngFor="let provider of filterStatus | async" [value]="provider.value">
                  {{provider.value}}
                </mat-option>
              </mat-select>

              <button mat-button (click)="Status=undefined;$event.stopPropagation()" *ngIf="Status" matSuffix
                (click)="clearStatus()" mat-icon-button >
                <mat-icon>close</mat-icon>
              </button>

            </mat-form-field>

            <mat-form-field class="col-3">
              <mat-label> Created Date <span class="asterisk">*</span></mat-label>
              <input autocomplete="new-txtCreatedate" formControlName="txtCreatedate" matInput maxlength="40" type="text" placeholder="(MM/DD/YYYY)"
                [matDatepicker]="txtCreatedate" />
              <mat-datepicker-toggle matSuffix [for]="txtCreatedate"> </mat-datepicker-toggle>
              <mat-datepicker #txtCreatedate> </mat-datepicker>
              <mat-error *ngIf="
              vSearchForm?.get('txtCreatedate')?.touched &&
              vSearchForm?.get('txtCreatedate')?.errors ">
                Enter Valid date
              </mat-error>
            </mat-form-field>
            <div class="col-3">
              <button mat-button class="buttonColor" (click)="search()">Search
              </button>

              <button mat-button class="resetclr buttonColor mb-2 mr-2" (click)="resetSearch()">
                Reset
              </button>
            </div>


          </div>
        </form>

      </div>



      <div class="row ml-2">

        <div class="tabspace col-12 table-responsive">
          <!-- <div [hidden]="!isShowSpinner"
            style="display: flex; justify-content: center; align-items: center; background: transparent; color: lightseagreen;">
            <mat-progress-bar mode="indeterminate" value='66' diameter="45" class="lightseagreen">
            </mat-progress-bar>
          </div> -->
          <table mat-table class="table border" matSort [dataSource]="dataSource">
            <ng-container matColumnDef="sno">
              <th mat-header-cell *matHeaderCellDef >S.No</th>
              <td mat-cell *matCellDef="let data; let i = index">
                {{ i+1 }}
              </td>
            </ng-container>
            <ng-container matColumnDef="productName">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                <!-- <mat-form-field class="filter">
                  <input matInput formControlName="Name" />
                  <mat-placeholder>Product Name</mat-placeholder>
                </mat-form-field> -->Product Name
              </th>
              <td mat-cell *matCellDef="let data">
                <ng-container>
                  {{ data?.productName }}
                </ng-container>
              </td>
            </ng-container>
            <ng-container matColumnDef="productBrandName">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                <!-- <mat-form-field class="filter">
                  <input matInput formControlName="brand" />
                  <mat-placeholder>Brand Name</mat-placeholder>
                </mat-form-field> -->Brand Name
              </th>
              <td mat-cell *matCellDef="let data">
                <ng-container>
                  {{ data?.productBrandName }}
                </ng-container>
              </td>
            </ng-container>

            <ng-container matColumnDef="purchasedStockCount">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                <!-- <mat-form-field class="filter">
                  <input matInput formControlName="stockCount" />
                  <mat-placeholder>Stock Counts</mat-placeholder>
                </mat-form-field> -->Stock Count
              </th>
              <td mat-cell *matCellDef="let data">
                <ng-container>
                  {{ data?.purchasedStockCount }}
                </ng-container>
              </td>
            </ng-container>
            <ng-container matColumnDef="stockDate">
              <th class="header" class="w-125" mat-header-cell *matHeaderCellDef>
                <!-- <mat-form-field class="filter">
                  <mat-label> Stock Date </mat-label>
                  <input matInput formControlName="stockDate" placeholder="(MM/DD/YYYY)" />
                  <mat-error
                    *ngIf=" getVendorSForm()?.get('stockDate')?.touched && getVendorSForm()?.get('stockDate')?.errors?.dateVaidator">
                    Enter Valid date
                  </mat-error>
                </mat-form-field> -->Stock Purchased Date
              </th>
              <td mat-cell *matCellDef="let element">{{ element?.stockPurchasedDate | date:
                'MM/dd/yyyy h:mm a':'en_US'}}
              </td>
            </ng-container>
            <ng-container matColumnDef="productCategoryName">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                <!-- <mat-form-field class="filter">
                  <input matInput formControlName="category" />
                  <mat-placeholder>Product Category</mat-placeholder>
                </mat-form-field> --> Category Name
              </th>
              <td mat-cell *matCellDef="let data">
                <ng-container>
                  {{ data?.productCategoryName }}
                </ng-container>
              </td>
            </ng-container>
            <ng-container matColumnDef="lotNo">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                <!-- <mat-form-field class="filter">
                  <input matInput formControlName="lotNo" />
                  <mat-placeholder>Lot No</mat-placeholder>
                </mat-form-field> -->Lot No
              </th>
              <td mat-cell *matCellDef="let data">
                <ng-container>
                  {{ data?.lotNo }}
                </ng-container>
              </td>
            </ng-container>


            <ng-container matColumnDef="soldCount">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                <!-- <mat-form-field class="filter">
                  <input matInput formControlName="balStock" />
                  <mat-placeholder>Balance Stock</mat-placeholder>
                </mat-form-field> -->Sold Count
              </th>
              <td mat-cell *matCellDef="let data">
                <ng-container>
                  {{ data?.soldCount }}
                </ng-container>
              </td>
            </ng-container>

            <ng-container matColumnDef="balStock">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                <!-- <mat-form-field class="filter">
                  <input matInput formControlName="balStock" />
                  <mat-placeholder>Balance Stock</mat-placeholder>
                </mat-form-field> -->Balance Count
              </th>
              <td mat-cell *matCellDef="let data">
                <ng-container>
                  {{ data?.balStock }}
                </ng-container>
              </td>
            </ng-container>
            <ng-container matColumnDef="status">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                <!-- <mat-form-field class="filter">
                  <input matInput formControlName="category" />
                  <mat-placeholder>Product Category</mat-placeholder>
                </mat-form-field> -->Status
              </th>
              <td mat-cell *matCellDef="let data">
                <ng-container>
                  {{ data?.status }}
                </ng-container>
              </td>
            </ng-container>
            <ng-container matColumnDef="createdDate">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                <!-- mat-form-field class="filter">
                  <mat-label> Created Date </mat-label>
                  <input matInput formControlName="CreatedOn" placeholder="(MM/DD/YYYY)" />
                  <mat-error
                    *ngIf=" getVendorSForm()?.get('vCreatedOn')?.touched && getVendorSForm()?.get('vCreatedOn')?.errors?.dateVaidator">
                    Enter Valid date
                  </mat-error>
                </mat-form-field> -->Created Date
              </th>
              <td mat-cell *matCellDef="let data">
                <ng-container>
                  {{ data?.createdDate | date: 'MM/dd/yyyy h:mm a':'en_US' }}
                </ng-container>
              </td>
            </ng-container>

            <ng-container matColumnDef="orderId">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                <mat-form-field class="filter">
                  <input autocomplete="new-orderId" matInput formControlName="orderId" />
                  <mat-placeholder>OrderId</mat-placeholder>
                </mat-form-field>
              </th>
              <td mat-cell *matCellDef="let data">
                <ng-container>
                  {{ data?.vendorAddress }}
                </ng-container>
              </td>
            </ng-container>
            <ng-container matColumnDef="Options">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Options</th>
              <td mat-cell *matCellDef="let data">
                <a [ngClass]="{'blinking': data?.isAllSerialNumbersUpdated === 'false'}" mat-button
                  class="btn btn-sm lightSeaGreen-dot mr-2" (click)="openModel(data)">
                  {{data?.optionStatus}}
                </a>
                <a class="editBtnColor">
                  <i class="fa fa-pencil editBtnColor " (click)="viewStock(data)" matTooltip="Click To Edit"></i>
                </a>
                &nbsp;
                <a [hidden]="true" class="deleteBtnColor">
                  <i class="fa fa-trash-o" matTooltip="Click To Delete"></i>
                </a>

              </td>
            </ng-container>
            <ng-container matColumnDef="noRecords">
              <td class="text-align-center" mat-footer-cell *matFooterCellDef colspan="11">
                <div *ngIf="!isShowSpinner">
                  {{"No Records Found"}}
                </div>
                <div [hidden]="!isShowSpinner">
                  <mat-spinner [diameter]="220"></mat-spinner>
                </div>
              </td>
            </ng-container>
            <ng-container matColumnDef="paginator">
              <td mat-footer-cell *matFooterCellDef colspan="11">
                <mat-paginator class="table table-responsive mat-table-class" #MatPaginator
                  [length]="dataSource?.data?.length" [pageSize]="10" [pageIndex]="0" showFirstLastButtons
                  [pageSizeOptions]="[5, 10, 25, 100]">
                </mat-paginator>
              </td>
            </ng-container>
            <tr mat-footer-row *matFooterRowDef="['noRecords']"
              [ngClass]="{ active: dataSource && dataSource?.data && dataSource?.data?.length !== 0 }"></tr>
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
            <tr mat-footer-row *matFooterRowDef="['paginator']"></tr>
          </table>
        </div>
      </div>
    </div>
  </div>


</div>
