import type { ClaimBatchProcessRequestDTO,  UploadClaimRemitsDTO } from './dto/models';
import { RestService } from '@abp/ng.core';
import type { PagedResultDto } from '@abp/ng.core';
import { Injectable } from '@angular/core';
import type { InsuranceType } from '../../billing-management/billing/insurance-type.enum';
import type { ClaimDTO, ClaimDetailsDTO1, SearchClaimDetailsDTO } from '../../billing-management/posting/dto/models';
import type { SaleType } from '../../order-management/order/sale-type.enum';

@Injectable({
  providedIn: 'root',
})
export class ClaimProcessService {
  apiName = 'ClaimProcessingManagement';

  getClaimEobDocumentsByClaimIdAndInsuranceType = (claimId: string, insuranceType: InsuranceType) =>
    this.restService.request<any, string>({
      method: 'GET',
      responseType: 'text',
      url: `/api/claimProcessingApp/claim-process/get-claim-eob-documents/${claimId}`,
      params: { insuranceType },
    },
    { apiName: this.apiName });

  getClaimsBasedOnProviderAndSaleTypeBySProviderNameAndSaleType = (sProviderName: string, saleType: SaleType) =>
    this.restService.request<any, PagedResultDto<ClaimDTO>>({
      method: 'GET',
      url: '/api/claimProcessingApp/claim-process/get-claims-based-on-provider-and-sale-type',
      params: { sProviderName, saleType },
    },
    { apiName: this.apiName });

  getClaimsBasedOnProvidersBySProviderName = (sProviderName: string) =>
    this.restService.request<any, PagedResultDto<ClaimDTO>>({
      method: 'GET',
      url: '/api/claimProcessingApp/claim-process/get-claims-based-on-providers',
      params: { sProviderName },
    },
    { apiName: this.apiName });

  importClaimRemitsByInput = (input: UploadClaimRemitsDTO) =>
    this.restService.request<any, string>({
      method: 'POST',
      responseType: 'text',
      url: '/api/claimProcessingApp/claim-process/import-claim-remits',
      body: input,
    },
    { apiName: this.apiName });

  processClaimBatchByInput = (input: ClaimBatchProcessRequestDTO) =>
    this.restService.request<any, string>({
      method: 'POST',
      responseType: 'text',
      url: '/api/claimProcessingApp/claim-process/process-claim-batch',
      body: input,
    },
    { apiName: this.apiName });

  searchClaimsByOSearchClaim = (sProviderName:string,oSearchClaim: SearchClaimDetailsDTO) =>
    this.restService.request<any, ClaimDTO[]>({
      method: 'POST',
      url: '/api/claimProcessingApp/claim-process/search-claim-process',
      params: { sProviderName },
      body: oSearchClaim,
    },
    { apiName: this.apiName });

  updateClaimByInput = (input: ClaimBatchProcessRequestDTO) =>
    this.restService.request<any, ClaimDTO>({
      method: 'PUT',
      url: '/api/claimProcessingApp/claim-process/update-claim',
      body: input,
    },
    { apiName: this.apiName });

  updateClaimStatusByClaimIdAndClaimStatus = (claimId: string, claimStatus: string) =>
    this.restService.request<any, ClaimDTO>({
      method: 'PUT',
      url: `/api/claimProcessingApp/claim-process/update-claim-status/${claimId}`,
      params: { claimStatus },
    },
    { apiName: this.apiName });

  validateBillingItemByInput = (input: ClaimBatchProcessRequestDTO) =>
    this.restService.request<any, string>({
      method: 'POST',
      responseType: 'text',
      url: '/api/claimProcessingApp/claim-process/validate-billing-item',
      body: input,
    },
    { apiName: this.apiName });


 updateVoidedStatusByClaimIdAndClaimStatus = (claimId: string, isVoided: boolean) =>
  this.restService.request<any, ClaimDTO>({
    method: 'PUT',
    url: `/api/claimProcessingApp/claim-process/update-voided-status/${claimId}`,
    params: {  isVoided },

  },
  { apiName: this.apiName });

  getClaimListbyPatientId=(patientId:string)=>
  this.restService.request<any,any[]>({
    method:'GET',
    url:`/api/claimProcessingApp/claim-process/get-claim-listby-patient-id/${patientId}`,
    params:{patientId},
  },
  {apiName:this.apiName});
  getClaimDetails = (patientId: string, startDate: string, endDate: string, claimNumber: string, saleOrderIdSearch: string, claimStatusSearch: string, patientIdSearch: string, insuranceNameSearch: string, insuranceTypeSearch: string, claimProcessedDateSearch: string) =>
    this.restService.request<any, ClaimDetailsDTO1[]>({
      method: 'GET',
      url: `/api/claimProcessingApp/claim-process/claim-details/${patientId}`,
      params: { startDate, endDate, claimNumber, saleOrderIdSearch, claimStatusSearch, patientIdSearch, insuranceNameSearch, insuranceTypeSearch, claimProcessedDateSearch },
    },
    { apiName: this.apiName });



  constructor(private restService: RestService) {}
}
