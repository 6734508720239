<div class="container-fluid mt-2">
  <div class="row bg">
    <div class="col-12">
      <div class="d-flex align-item-center justify-content-between mt-1">
        <h2 class="font-weight-bolder mb-0"><b class="header-class">Tools / My Compliance</b></h2>
      </div>
    </div>
  </div>
  <div class="card mt-2">
    <div class="row">
      <div class="col-12">
        <ul class="card card-body" ngbNav #nav="ngbNav" justify="justified"
          class="alert-success nav nav-pills" style="border-bottom: 2px solid rgb(201, 201, 201)">
          <li ngbNavItem="New" [class.selected-tab]="nav.activeId === ComplaincesTypes.New">
            <a ngbNavLink (click)="changeTab(ComplaincesTypes.New)"><b>Download</b></a>
          </li>
          <li ngbNavItem="FTFNote" [class.selected-tab]="nav.activeId === ComplaincesTypes.FTFNote">
            <a ngbNavLink (click)="changeTab(ComplaincesTypes.FTFNote)"><b>FTF Note</b></a>
          </li>
          <li ngbNavItem="CheckList" [class.selected-tab]="nav.activeId === ComplaincesTypes.CheckList">
            <a ngbNavLink (click)="changeTab(ComplaincesTypes.CheckList)"><b>Checklist</b></a>
          </li>
          <li ngbNavItem="Billing" [class.selected-tab]="nav.activeId === ComplaincesTypes.Billing">
            <a ngbNavLink (click)="changeTab(ComplaincesTypes.Billing)"><b>Billing</b></a>
          </li>
          <li ngbNavItem="ComplianceMet" [class.selected-tab]="nav.activeId === ComplaincesTypes.ComplianceMet">
            <a ngbNavLink (click)="changeTab(ComplaincesTypes.ComplianceMet)"><b>Compliance Met</b></a>
          </li>
          <li ngbNavItem="NonComplianceMet" [class.selected-tab]="nav.activeId === ComplaincesTypes.NonComplianceMet">
            <a ngbNavLink (click)="changeTab(ComplaincesTypes.NonComplianceMet)"><b>Non-Compliant</b></a>
          </li>
        </ul>
      </div>
    </div>
    <div class="row mt-3">
      <div class="col-12">
        <!-- <div class="card-body" style="border-top: 3px solid rgb(201, 201, 201)"> -->
          <div class="d-flex justify-content-center" *ngIf="isLoading">
            <img class="img-responsive pb-2 mr-1" src="assets/gifs/Infinity-loader.gif" />
          </div>
          <div *ngIf="activeTab === ComplaincesTypes.New" class="container-fluid">
            <app-compliance-report-list *ngIf="!isLoading" [DataList]="DataList"
              [activeTab]="activeTab"></app-compliance-report-list>
          </div>
          <div *ngIf="activeTab === ComplaincesTypes.FTFNote" class="container-fluid">
            <app-compliance-report-list *ngIf="!isLoading" [DataList]="DataList"
              [activeTab]="activeTab"></app-compliance-report-list>
          </div>
          <div *ngIf="activeTab === ComplaincesTypes.CheckList" class="container-fluid">
            <app-compliance-report-list *ngIf="!isLoading" [DataList]="DataList"
              [activeTab]="activeTab"></app-compliance-report-list>
          </div>
          <div *ngIf="activeTab === ComplaincesTypes.Billing" class="container-fluid">
            <app-compliance-report-list *ngIf="!isLoading" [DataList]="DataList"
              [activeTab]="activeTab"></app-compliance-report-list>
          </div>
          <div *ngIf="activeTab === ComplaincesTypes.ComplianceMet" class="container-fluid">
            <app-compliance-report-list *ngIf="!isLoading" [DataList]="DataList"
              [activeTab]="activeTab"></app-compliance-report-list>
          </div>
          <div *ngIf="activeTab === ComplaincesTypes.NonComplianceMet" class="container-fluid">
            <app-compliance-report-list *ngIf="!isLoading" [DataList]="DataList"
              [activeTab]="activeTab"></app-compliance-report-list>
          </div>
        </div>
      </div>
      <br><br>
    </div>
  </div>
<!-- </div> -->