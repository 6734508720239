import type { CreateUpdateInsuranceCategoryDTO, InsuranceCategoryDTO } from './dto/models';
import { RestService } from '@abp/ng.core';
import type { PagedAndSortedResultRequestDto, PagedResultDto } from '@abp/ng.core';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class InsuranceCategoryService {
  apiName = 'Default';

  create = (input: CreateUpdateInsuranceCategoryDTO) =>
    this.restService.request<any, InsuranceCategoryDTO>({
      method: 'POST',
      url: '/api/app/insurance-category',
      body: input,
    },
    { apiName: this.apiName });

  delete = (id: string) =>
    this.restService.request<any, void>({
      method: 'DELETE',
      url: `/api/app/insurance-category/${id}`,
    },
    { apiName: this.apiName });

  enableDisableInsuranceCatogoryById = (Id: string) =>
    this.restService.request<any, any>({
      method: 'POST',
      url: `/api/app/insurance-category/enable-disable-insurance-catogory/${Id}`,
    },
    { apiName: this.apiName });

  get = (id: string) =>
    this.restService.request<any, InsuranceCategoryDTO>({
      method: 'GET',
      url: `/api/app/insurance-category/${id}`,
    },
    { apiName: this.apiName });

  getAllInsuranceCategoryList = () =>
    this.restService.request<any, InsuranceCategoryDTO[]>({
      method: 'GET',
      url: '/api/app/insurance-category/insurance-category-list',
    },
    { apiName: this.apiName });
    getAllInsuranceCategoryListV1 = () =>
    this.restService.request<any, InsuranceCategoryDTO[]>({
      method: 'GET',
      url: '/api/app/insurance-category/insurance-category-dropdown-data',
    },
    { apiName: this.apiName });
    getAllInsuranceCategoryListV2 = (sSearch:string,gIns:string[]) =>
      this.restService.request<any, InsuranceCategoryDTO[]>({
        method: 'POST',
        url: '/api/app/insurance-category/get-insurance-category',
        params:{ sSearch: sSearch},
        body:  gIns ,
      },
      { apiName: this.apiName });

  getList = (input: PagedAndSortedResultRequestDto) =>
    this.restService.request<any, PagedResultDto<InsuranceCategoryDTO>>({
      method: 'GET',
      url: '/api/app/insurance-category',
      params: { skipCount: input.skipCount, maxResultCount: input.maxResultCount, sorting: input.sorting },
    },
    { apiName: this.apiName });

  // importInsCatDetailsByBase64Contents = (base64Contents: data) =>
  //   this.restService.request<any, string>({
  //     method: 'POST',
  //     responseType: 'text',
  //     url: '/api/app/insurance-category/import-ins-cat-details',
  //     body: base64Contents,
  //   },
  //   { apiName: this.apiName });

  update = (id: string, input: CreateUpdateInsuranceCategoryDTO) =>
    this.restService.request<any, InsuranceCategoryDTO>({
      method: 'PUT',
      url: `/api/app/insurance-category/${id}`,
      body: input,
    },
    { apiName: this.apiName });

  getAllInsuranceCat = () =>
    this.restService.request<any, PagedResultDto<InsuranceCategoryDTO>>({
      method: 'GET',
      url: '/api/app/insurance-category/get-all-insurance-cat',
    },
    { apiName: this.apiName });

  updateMMInsGroup = () =>
    this.restService.request<any, boolean>({
      method: 'PUT',
      url: '/api/app/insurance-category/update-mMIns-group',
    },
    { apiName: this.apiName });

  updateTenantDetail = () =>
    this.restService.request<any, boolean>({
      method: 'PUT',
      url: '/api/app/insurance-category/update-tenant-detail',
    },
    { apiName: this.apiName });

    statusFilter= (statusFilter:number) =>
    this.restService.request<any, any>({
      method: 'GET',
      url: `/api/app/insurance-category/get-insurance-category-bystatus`,
      params:{statusFilter}
    },
    { apiName: this.apiName });

    getInsuranceCategoryDropdownData = () =>
      this.restService.request<any, InsuranceCategoryDTO[]>({
        method: 'GET',
        url: '/api/app/insurance-category/insurance-category-dropdown-data',
      },
      { apiName: this.apiName });

  constructor(private restService: RestService) {}
}
