<div class="mt-2">
    <div class="row justify-content-end">
        <div class="col-12">
            <div class="d-flex align-items-center justify-content-end">
                <div class="circle red"></div> <span class="ml-2 mr-2">Hold</span>
                <div class="circle green"></div> <span class="ml-2 mr-2">Ready</span>
                <div class="circle processed"></div> <span class="ml-2 mr-2">Processed</span>
                <div class="circle crossed-over"></div> <span class="ml-2 mr-2">Crossed Over</span>
                <div class="circle auth-no"></div> <span class="ml-2 mr-2">Auth No</span>
                <div class="circle days"></div> <span class="ml-2 mr-2">>55 days</span>
            </div>
        </div>
    </div>

    <hr class="p-0 mt-2">

    <table id="tblClaimOption" datatable [dtOptions]="dtClaimOptions" class="tbl hover row-border hover w-100 display">
        <thead>
            <tr>
                <th [matTooltip]="'Id'">Id</th>
                <th [matTooltip]="'Status'">Status</th>
                <th [matTooltip]="'Auth'">Auth</th>
                <!-- <th [matTooltip]="'Day Sheet'">Day Sheet</th> -->
                <th [matTooltip]="'DOS'">DOS</th>
                <th [matTooltip]="'Provider'">Provider</th>
                <th [matTooltip]="'#'">#</th>
                <th [matTooltip]="'Pat. Balance ($)'">Pat. Balance ($)</th>
                <th [matTooltip]="'Ins Balance ($)'">Ins Balance ($)</th>
                <th [matTooltip]="'Total'">Total</th>
                <th [matTooltip]="'1st Filled'">1st Filled</th>
                <th [matTooltip]="'Last Filled'">Last Filled</th>
                <th [matTooltip]="'Claim Id'">Claim Id</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let data of claimDetailsList">
                <td (dblclick)="onRowDoubleClick(data?.ticketId || '-')" [matTooltip]="data?.ticketId || '-'"
                    class="text-right">
                    {{ data?.ticketId || '-'}}
                </td>
                <td (dblclick)="onRowDoubleClick(data?.claimStatusName || '-')"
                    [matTooltip]="data?.claimStatusName || '-'">{{
                    data?.claimStatusName || '-'}}</td>
                <td (dblclick)="onRowDoubleClick(data?.authNo || '-')" [matTooltip]="data?.authNo || '-'"
                    class="text-right">
                    <!-- <a [class.disabled]="isEmpty(data.chartNo) || isEmpty(data.patientId)"
                                        [routerLink]="'/patientCreateEditTab/' + data?.patientId + '/' + data?.docId" target="_blank"
                                        class="preview-link"> -->
                    {{ data?.authNo || '-' }}
                    <!-- </a> -->
                </td>
                <!-- <td (dblclick)="onRowDoubleClick(data?.DaySheet || '-')" [matTooltip]="data?.DaySheet || '-'">
                    {{ data?.DaySheet || '-'}}
                </td> -->
                <!-- class="text-center" -->
                <td (dblclick)="onRowDoubleClick(getFormattedDate(data?.dos))"
                    [matTooltip]="getFormattedDate(data?.dos)">
                    {{ getFormattedDate(data?.dos) }}
                </td>
                <td (dblclick)="onRowDoubleClick(data?.providerName || '-')" [matTooltip]="data?.providerName || '-'">
                    {{ data?.providerName || '-' }}
                </td>
                <td (dblclick)="onRowDoubleClick(data?.itemCount || '-')" [matTooltip]="data?.itemCount || '-'"
                    class="text-right">
                    {{ data?.itemCount || '-' }}
                </td>

                <td (dblclick)="onRowDoubleClick(data?.patientBalance || '-')"
                    [matTooltip]="data?.patientBalance || '-'" class="text-right">
                    {{ data?.patientBalance }}
                </td>
                <td (dblclick)="onRowDoubleClick(data?.insuranceBalance || '-')"
                    [matTooltip]="((data?.claimItemList===null||data?.claimItemList==[])?'-': data?.claimItemList[0]?.productCode)" class="text-right">
                    {{ data?.insuranceBalance }}
                </td>
                <td (dblclick)="onRowDoubleClick(data?.totalBalance || '-')" [matTooltip]="data?.totalBalance || '-'"
                    class="text-right">
                    {{ data?.totalBalance }}
                </td>

                <td (dblclick)="onRowDoubleClick(getFormattedDate(data?.firstFiledDate))"
                    [matTooltip]="getFormattedDate(data?.firstFiledDate)">
                    {{ getFormattedDate(data?.firstFiledDate) }}
                </td>

                <td (dblclick)="onRowDoubleClick(getFormattedDate(data?.lastFiledDate))"
                    [matTooltip]="getFormattedDate(data?.lastFiledDate)">
                    {{ getFormattedDate(data?.lastFiledDate) }}
                </td>

                <td (dblclick)="onRowDoubleClick(data?.defaultClaimId || '-')"
                    [matTooltip]="data?.defaultClaimId || '-'">
                    {{ data?.defaultClaimId || '-' }}
                </td>
            </tr>
        </tbody>
        <tfoot>
            <tr>
                <th><input matInput class="textbox-border-class" type="text" placeholder="Id" name="search-Id" /></th>
                <th><input matInput class="textbox-border-class" type="text" placeholder="Status"
                        name="search-Status" /></th>
                <th>
                    <input matInput class="textbox-border-class" type="text" placeholder="Auth" name="search-Auth" />
                </th>
                <!-- <th>
                    <input matInput class="textbox-border-class" type="text" placeholder="Day Sheet"
                        name="search-DaySheet" />
                </th> -->
                <th>
                    <input matInput type="text" class="textbox-border-class" placeholder="DOS" name="search-DOB" />
                </th>
                <th>
                    <input matInput type="text" class="textbox-border-class" placeholder="Provider"
                        name="search-Provider" />
                </th>
                <th>
                    <input matInput type="text" class="textbox-border-class" placeholder="#" name="search-has" />
                </th>
                <th>
                    <input matInput class="textbox-border-class" type="text" placeholder="Pat. Balance ($)"
                        name="search-PB" />
                </th>
                <th>
                    <input matInput class="textbox-border-class" type="text" placeholder="Ins Balance ($)"
                        name="search-IB" />
                </th>
                <th>
                    <input matInput class="textbox-border-class" type="text" placeholder="Total" name="search-Total" />
                </th>
                <th> <input matInput class="textbox-border-class" type="text" placeholder="1st Filled"
                        name="search-FF" /></th>
                <th>
                    <input matInput class="textbox-border-class" type="text" placeholder="Last Filled"
                        name="search-LF" />
                </th>
                <th><input matInput class="textbox-border-class" type="text" placeholder="Claim Id" name="search-CID" />
                </th>

            </tr>
        </tfoot>
    </table>
</div>
<div class="row" *ngIf="isLoading">
    <div class="col-12">
        <div class="d-flex justify-content-center">
            <img class="img-responsive pb-2 mr-1" src="assets/gifs/Infinity-loader.gif" />
        </div>
    </div>
</div>
