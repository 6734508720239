import { Component, OnInit } from '@angular/core';
import { map, startWith } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import Swal from 'sweetalert2';
import { OrganizationUnitService } from 'projects/admin/src/app/admin-proxy/platform-app-management/rcm/platform-management/organization-units';
import { Observable } from 'rxjs';
import { Branch } from '../patient-search-tab/patient-search-tab.component';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { inboundTableData } from 'projects/admin/src/app/admin-dashboard/dashboard-data';
import { DataTableDirective } from 'angular-datatables/src/angular-datatables.directive';
import { OrganizationUnitDTO } from 'projects/admin/src/app/admin-proxy/platform-app-management/rcm/platform-management/organization-units/dto';
import { Console } from 'console';
@Component({
  selector: 'app-customer-service-pool',
  templateUrl: './customer-service-pool.component.html',
  styleUrls: ['./customer-service-pool.component.scss']
})
export class CustomerServicePoolComponent implements OnInit {
  public brnachList: Observable<Branch[]> | undefined;
  public drpBranch: Branch[] = [];
  tenantId: string = '';
  branchForm: FormGroup;
  organizationUnitName: string;
  subscription$: any;
  myTable: DataTables.Api;
  constructor( private branchService : OrganizationUnitService,
    private formBuilder: FormBuilder,) { }
  inboundTableData = inboundTableData;
  datatableElement: DataTableDirective;
  locationList: OrganizationUnitDTO[] = [];
  ngOnInit(): void {
    this.branchForm = this.formBuilder.group({
      drpBranch : new FormControl(''),
      txtBranch : new FormControl(''),
    });
    this.tenantId = localStorage.getItem('tenantId') ?? '';
     const getBranchList = this.branchService
    .getBranchList(this.tenantId)
    .subscribe((response) => {
      this.locationList = response;
    });

  }

  handleTableCellClick(e: Event, notesData: any): void {
    const target = e.target as HTMLElement;
    const tr = target.closest('tr');
    this.getDataTableData(tr, notesData);
  }
  async getDataTableData(tr: any, notesData: any): Promise<void> {
    this.myTable = await this.datatableElement.dtInstance;

    if (tr) {
      const row = this.myTable.row(tr);

      if (row.child.isShown()) {
        row.child.hide();
      } else {
        row.child(this.format(row.data(), notesData)).show();
      }
    }
  }
  format(arg0: object | any[], notesData: any): boolean {
    throw new Error('Method not implemented.');
  }

}
