<meta http-equiv="cache-control" content="no-cache, must-revalidate, post-check=0, pre-check=0">
<meta http-equiv="expires" content="0">
<meta http-equiv="pragma" content="no-cache">
<div class="row">
    <div class="col-12 col-sm-12 col-md-4 col-lg-3 col-xl-3">
      <div class="row">
        <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 p-2">
          <div class="card elevated-div">
              <div class="card card-body p-0">
                <div class="card-body mb-0  scrollable-card" style="max-height: 85vh">
                  <span><b>Document Bin</b></span>
                  <ng-container *ngIf="!isLoadSortingView">
                    <ng-container *ngFor="let document of documentTypes">
                      <mat-accordion>
                        <mat-expansion-panel [hidden]="document.shortCode == SortingShortCode.All"
                        [hideToggle]="true"
                        [disabled]="true" class="mt-1">
                        <mat-expansion-panel-header
                          class="customThemeClass custom-header-text-sorting custom-accordion-border">
                          <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 d-flex pl-0 align-items-center"
                            (click)="$event.stopPropagation();" style="overflow: hidden;">
                            <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 d-flex pl-0 align-items-center"
                              (click)="loadBinSelectedDocument(document)"
                              style="overflow: hidden;"
                              [ngClass]="{'selected-option': document_documentTypeId == document.documentTypeId}"
                              >
                              {{ document.documentType | uppercase}} ({{document.pageCount}})
                            </div>
                          </div>
                        </mat-expansion-panel-header>
                        </mat-expansion-panel>
                      </mat-accordion>
                    </ng-container>
                  </ng-container>
                  <ng-container *ngIf="isLoadSortingView">
                    <div class="d-flex justify-content-center">
                      <img class="img-responsive pb-2 mr-1" src="assets/gifs/Infinity-loader.gif" />
                    </div>
                  </ng-container>
                </div>
              </div>
          </div>
        </div>
      </div>
    </div>

    <!--Tiff Preview Viewer-->
    <div class="col-12 col-sm-12 col-md-1 col-lg-1 col-xl-1 p-0" [hidden]="isHideTiffViewer">
      <div class="card card-body align-items-center scrollable-card p-0" style="height: 85vh">
          <ng-container *ngFor="let image of existingDocumentImageList">
              <div class="card my-2 elevated-div custom-thumbnail-view">
                  <img class="card-img-top img-fluid custom-thumbnail-image" (click)="loadExistingImage(image)"
                      [ngClass]="{
              'custom-thumbnail-view-selected':
                selectedExistingDocument?.pageNo === image?.pageNo
            }" [src]="image?.src" [alt]="image?.pageNo" />
                  <!-- <p class="align-end">Page No : {{ image.pageNo }}</p> -->
              </div>
          </ng-container>
      </div>
  </div>

    <!-- TIFF Viewer -->

    <div class="col-12 col-sm-12 col-md-6 col-lg-7 col-xl-7" >

      <div class="card card-body align-items-center justify-content-center p-0">
        <h1 class="fw-bolder" [ngClass]="{ 'blink-text': isLoading || TiffError }" *ngIf="isLoading || TiffError">
          {{ loadingMessage }}
        </h1>

        <div *ngIf="isLoading" class="d-flex justify-content-center">
          <img class="img-responsive pb-2 mr-1" src="assets/gifs/Infinity-loader.gif" />
        </div>

        <div *ngIf="!TiffError">
          <div class="zoom-buttons" *ngIf="!isLoading">

            <span class="asterisk">
              (Double-click the image to select)
            </span>

            <a [matTooltip]="'Rotate Clockwise'" class="ml-2 mt-2" (click)="rotateClockwise()"
                [class.disabled]="isLoading" href="javascript:void(0)">
                <mat-icon>rotate_right</mat-icon>
              </a>

              <a [matTooltip]="'Rotate Counter Clock Wise'" class="ml-2 mt-2" (click)="rotateCounterclockwise()"
                [class.disabled]="isLoading" href="javascript:void(0)">

                <mat-icon>rotate_left</mat-icon>
              </a>

            <a [matTooltip]="'Zoom In Page'" class="ml-2 mt-2" (click)="zoomIn()"
              [class.disabled]="isLoading || zoomLevel === MAX_ZOOM_LEVEL"
              href="javascript:void(0)">
              <mat-icon>add</mat-icon>
            </a>

            <a [matTooltip]="'Zoom Out Page'" class="ml-2 mt-2" (click)="zoomOut()"
              [class.disabled]="isLoading || zoomLevel === MIN_ZOOM_LEVEL"
              href="javascript:void(0)">
              <mat-icon>remove</mat-icon>
            </a>

          <a [matTooltip]="'Go to First Page'" class="ml-2 mt-2" (click)="firstImage()"
            [class.disabled]="isLoading || pageNumber === 1" href="javascript:void(0)">
            <mat-icon>first_page</mat-icon>
          </a>

          <a [matTooltip]="'Go to Previous Page'" class="ml-2 mt-2" (click)="previousImage()"
            [class.disabled]="isLoading ||  pageNumber === 1" href="javascript:void(0)">
            <mat-icon>keyboard_arrow_left</mat-icon>
          </a>

            <!-- <button [matTooltip]="'Zoom In Page'" mat-button color="primary" (click)="zoomIn()"
              [disabled]="isLoading || disableAnnotation">
              <mat-icon>add</mat-icon>
            </button>
            <button [matTooltip]="'Go to First Page'" mat-button color="primary" (click)="firstImage()"
              [disabled]="isLoading || disableAnnotation">
              <mat-icon>first_page</mat-icon>
            </button>
            <button [matTooltip]="'Go to Previous Page'" mat-button color="primary" (click)="previousImage()"
              [disabled]="isLoading || disableAnnotation">
              <mat-icon>keyboard_arrow_left</mat-icon>
            </button> -->

            <input [matTooltip]="'Go to Custom Page'" matInput class="form-control custom-input" type="number"
              [(ngModel)]="pageNumber" min="1" max="{{totalPages}}" (focusout)="goToPage()" [readonly]="isLoading || disableAnnotation" />

            <span> of {{ totalPages }}</span>

          <a [matTooltip]="'Go to Next Page'" class="ml-2 mt-2" (click)="nextImage()"
            [class.disabled]="isLoading || pageNumber === existingDocumentImageList.length"
            href="javascript:void(0)">
            <mat-icon>keyboard_arrow_right</mat-icon>
          </a>
          <a [matTooltip]="'Go to Last Page'" class="ml-2 mt-2" (click)="lastImage()"
            [class.disabled]="isLoading || pageNumber === existingDocumentImageList.length"
            href="javascript:void(0)">
            <mat-icon>last_page</mat-icon>
          </a>
<!--
            <button [matTooltip]="'Go to Next Page'" mat-button color="primary" (click)="nextImage()"
              [disabled]="isLoading || disableAnnotation">
              <mat-icon>keyboard_arrow_right</mat-icon>
            </button>
            <button [matTooltip]="'Go to Last Page'" mat-button color="primary" (click)="lastImage()"
              [disabled]="isLoading || disableAnnotation">
              <mat-icon>last_page</mat-icon>
            </button>

            <button [matTooltip]="'Zoom Out Page'" mat-button color="primary" (click)="zoomOut()"
              [disabled]="isLoading || disableAnnotation">
              <mat-icon>remove</mat-icon>
            </button> -->

          </div>
        </div>
      </div>

      <div [hidden]="isHideTiffViewer"
        class="d-flex justify-content-center scrollable-card p-0 backgroundclr border-line">
        <div class="scrollable-card-image">
          <canvas #canvas (dblclick)="handleDoubleClick($event)" ></canvas>
          <input [(ngModel)]="tiffPageNo" type="hidden" name="tiffPageNo" />
        </div>
      </div>

      <div *ngIf="isHideTiffViewer" class="pdf-container pb-4">
        <ng-container *ngIf="showPdfViwer">
          <ngx-extended-pdf-viewer [base64Src]="strPdfString" [height]="pdfheight" [showSidebarButton]="false" [textLayer]="true" [showPagingButtons]="true" [showOpenFileButton]="false"
            [showHandToolButton]="false" [showScrollingButton]="false" [showSpreadButton]="false"
            [showPropertiesButton]="false" [showBookmarkButton]="false" [zoom]="'page-width'"
            [enableDragAndDrop]="false" [showFindButton]="false">
          </ngx-extended-pdf-viewer>
        </ng-container>
      </div>


    </div>

    <div class="col-12 col-sm-12 col-md-1 col-lg-1 col-xl-1 p-0 prevent-select">
      <div class="card card-body align-items-center scrollable-card p-0" style="max-height: 85vh">
        <span><b>Selected Pages</b></span>
        <ng-container *ngFor="let image of splitDocumentImageList; let i = index">
          <div class="card my-2 elevated-div custom-thumbnail-view">
            <button class="delete-button" (click)="deleteImage(i)">
              <i class="fa fa-times"></i>
            </button>
            <img class="card-img-top img-fluid custom-thumbnail-image" [src]="image?.src" [alt]="image?.pageNo" (click)="selectedImgPreview(image)"/>
            <!-- <p class="align-end fw-bolder mr-2">Page No : {{ image.pageNo }}</p> -->
          </div>
        </ng-container>
      </div>
    </div>


    <!-- <div class="col-12 col-sm-12 col-md-1 col-lg-1 col-xl-1 p-0">
      <div class="card card-body align-items-center scrollable-card p-0" style="height: 80vh">
        <ng-container *ngFor="let image of splitDocumentImageList; let i = index">
          <div class="card my-2 elevated-div custom-thumbnail-view">

            <img class="card-img-top img-fluid custom-thumbnail-image" [src]="image?.src" [alt]="image?.pageNo" />
            <p class="align-end fw-bolder">Page No : {{ image.pageNo }}</p>
            <button class="btn delete-button asterisk" (click)="deleteImage(i)">
              <i class="fa fa-trash"></i>
            </button>
          </div>
        </ng-container>
      </div>
    </div> -->



  </div>
