<table id="tblparReportList" datatable [dtOptions]="dtPARReportTableOptions"
  class="tbl hover row-border hover w-100 display">
  <thead>
    <tr>
      <th><mat-icon>apps</mat-icon></th>
      <th [matTooltip]="'Chart Id'">Chart Id</th>
      <th [matTooltip]="'Patient Name'">Patient Name</th>
      <th [matTooltip]="'DOB'">DOB</th>
      <th [matTooltip]="'CSR'">CSR</th>
      <th [matTooltip]="'HCPC Code'">HCPC Code</th>
      <th [matTooltip]="'Auth Expiry Date'">Auth Expiry Date</th>
      <th [matTooltip]="'Expiry Days'">Expiry Days</th>
      <th [matTooltip]="'Status'">Status</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let data of tableData">
      <td>
        <mat-icon style="cursor: pointer !important" [matMenuTriggerFor]="menu">more_vert</mat-icon>

        <mat-menu class="custom-mat-menu" #menu="matMenu">

          <button mat-menu-item (click)="openAddNotesPopUpV1($event, data, false)" [disabled]="isEmpty(data?.patientId)">
            Add Note
          </button>

          <button mat-menu-item (click)="openAddNotesPopUpV1($event, data, true)" [disabled]="isEmpty(data?.patientId)">
            Add Call Note
          </button>
          <mat-divider></mat-divider>

          <button mat-menu-item (click)="openViewNotesPopup(data.patientId,data.defaultFaxId,data.docId)" [disabled]="isEmpty(data?.patientId)">
            View Patient Notes
          </button>

          <button mat-menu-item (click)="viewPatientLedger(data?.patientId)" [disabled]="isEmpty(data?.patientId)">
            View Patient Ledger
          </button>

          <a [routerLink]="'/view_document_tiff/' + data.docId + '/' + true" target="_blank" class="preview-link">
            <button [disabled]="isEmpty(data.docId)" mat-menu-item>
              View Document Image
            </button>
          </a>

          <mat-divider></mat-divider>
          <button mat-menu-item (click)="doprecert(data?.patientId)" [disabled]="isEmpty(data?.patientId)">
            Pre-cert Request
          </button>

          <button mat-menu-item (click)="viewprecert(data)" [disabled]="isEmpty(data?.patientId) || 
          data.authStatusName === 'Yet to Start' ||
          data.authStatusName === null ||
          data.authStatusName === ''">
            View Pre-Cert
          </button>

        </mat-menu>
      </td>
      <td [matTooltip]="data?.chartId || '-'" (dblclick)="onRowDoubleClick(data?.chartId || '-')">
        <a [class.disabled]="isEmpty(data.chartId) || isEmpty(data.patientId)" class="preview-link"
          [routerLink]="'/patientCreateEditTab/' + data.patientId" target="_blank">
          {{ data.chartId || "-" }}
        </a>
      </td>
      <td [matTooltip]="data?.fullName || '-'" (dblclick)="onRowDoubleClick(data?.fullName || '-')">
        {{data.fullName}}
      </td>
      <td [matTooltip]="data?.dob || '-'" (dblclick)="onRowDoubleClick(data?.dob || '-')">
        {{ data.dob || '-' }}
      </td>
      <td [matTooltip]="data?.csrName || '-'" (dblclick)="onRowDoubleClick(data?.csrName || '-')">
        {{ data.csrName || '-' }}
      </td>
      <td [matTooltip]="data?.hcpcCode || '-'" (dblclick)="onRowDoubleClick(data?.hcpcCode || '-')">
        {{ data.hcpcCode || '-' }}
      </td>
      <td [matTooltip]="data?.authEndDate" (dblclick)="onRowDoubleClick(data?.authEndDate || '-')">
        {{ data.authEndDate }}
      </td>
      <td [matTooltip]=" data.durationOfExpiry" class="text-center" (dblclick)="onRowDoubleClick(data?.durationOfExpiry)">
        <button mat-raised-button class="mr-2 p-2 dd-text pointerevent"
          [ngClass]=" data.durationOfExpiry <= 0 ? 'expiredclr': 'notexpiredclr' ">
          {{ data.durationOfExpiry}}
        </button>
      </td>
      <td [matTooltip]="data?.statusOfExpiry || '-'" (dblclick)="onRowDoubleClick(data?.statusOfExpiry || '-')">
        {{ data.statusOfExpiry ||'-' }}
      </td>
    </tr>
  </tbody>
  <tfoot>
    <tr>
      <th><mat-icon>apps</mat-icon></th>
      <th>
        <input matInput class="textbox-border-class" type="text" placeholder="Chart Id" name="search-ChartId" />
      </th>
      <th>
        <input matInput class="textbox-border-class" type="text" placeholder="Patient Name" name="search-PatientName" />
      </th>
      <th>
        <input matInput class="textbox-border-class" type="text" placeholder="DOB" name="search-Dob" />
      </th>
      <th>
        <input matInput class="textbox-border-class" type="text" placeholder="CSR" name="search-csr" />
      </th>
      <th>
        <input matInput class="textbox-border-class" type="text" placeholder="HCPC Code" name="search-hcpccode" />
      </th>
      <th>
        <input matInput class="textbox-border-class" type="text" placeholder="Auth Expiry Date" name="search-authexpirydate" />
      </th>
      <th>
        <input matInput class="textbox-border-class" type="text" placeholder="Expiry Days" name="search-expiryDays" />
      </th>
      <th>
        <input matInput class="textbox-border-class" type="text" placeholder="Status" name="search-status" />
      </th>
    </tr>
  </tfoot>
</table>