<div class="modal-header" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
  <h1 mat-dialog-title><b>Patient List</b></h1>
  <mat-icon mat-dialog-close>close</mat-icon>
</div>

<div mat-dialog-content>
  <div class="card card-body">
    <form [formGroup]="patientSearchForm">
      <div class="row mt-2">
        <!-- (ngModelChange)="onKeyPressSearch()" -->
         <!-- Birth Date -->
         <mat-form-field class="col-2">
          <mat-label>DOB </mat-label>
          <input autocomplete="new-txtBirthdate"  formControlName="txtBirthdate" matInput
            type="text" placeholder="(MM/DD/YYYY)"
            (keydown)="addSlashToDate($event)" [matDatepicker]="txtBirthdate"
            maxlength="10" />
            <!-- (keyup)="checkMinLength($event.target.value)" -->
            <!-- (keyup)="checkValidDate($event.target.value)" -->

          <mat-datepicker-toggle matSuffix [hidden]="true"[for]="txtBirthdate">
          </mat-datepicker-toggle>
          <mat-datepicker #txtBirthdate [hidden]="true"> </mat-datepicker>
          <mat-error *ngIf="
        patientSearchForm.get('txtBirthdate').errors ||
        patientSearchForm.get('txtBirthdate')?.errors?.invalid
      "> Enter a Valid Date!
          </mat-error>

        </mat-form-field>
        <!--Chart No-->
        <mat-form-field class="form-field col-2">
          <input matInput type="text" formControlName="ChartNo" placeholder="Chart No" maxlength="15"
            />
        </mat-form-field>
        <!--Patient Name-->
        <mat-form-field class="form-field col-2_5">
          <input matInput type="text" formControlName="PatientName" placeholder="Patient Name" maxlength="40"
             />
        </mat-form-field>

        <!--SSN-->
        <mat-form-field class="form-field col-2">
          <input matInput type="text" formControlName="ssnNo" maxlength="15" placeholder="SSN"  oninput="this.value = this.value.replace(/[^0-9]+/g, '');"/>
        </mat-form-field>

        <!--Policy-->
        <mat-form-field class="form-field col-2">
          <input matInput type="text" formControlName="policyNo" placeholder="Policy" maxlength="20"
             />
        </mat-form-field>

        <!--Email-->
        <mat-form-field class="form-field col-2_5">
          <!-- <input matInput type="text" formControlName="EmailId" placeholder="Email"
             /> -->
      <input matInput maxlength="40" formControlName="EmailId" type="text"placeholder="Email"
         pattern="[a-zA-Z0-9._!#$%&'()*+,-./:;<=>?@[\]^_`{|}~]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$"
                            [textMask]="{ mask: emailMask }" />

        <mat-error *ngIf="
           patientSearchForm?.get('EmailId')?.touched &&
           patientSearchForm?.get('EmailId')?.errors?.pattern
           ">
           Please Enter a Valid Email Address
           </mat-error>
        </mat-form-field>

        <!-- Emergency Contact  No-->
        <mat-form-field class="col-2">
          <mat-label> Emergency Contact No </mat-label>
          <input matInput maxlength="40" formControlName="txtHomeNo" type="text" mask="(000)-000-0000"
             />
        </mat-form-field>
        <!-- <div class="col-1 mt-3">
          <button mat-raised-button class="resetclr mb-2" (click)="onKeyPressSearch()">Search</button>
        </div>
        <div class="col-1 mt-3">
          <button mat-raised-button class="resetclr mb-2" (click)="resetPatientSearch()">Reset</button>
        </div> -->
        <div class=" all-icon" matTooltip="Search"  >
          <i class="fa fa-search search align-items-center" (click)="onKeyPressSearch()"
          [class.disabled]="(!this.patientSearchForm.valid || !this.areFieldsNotEmpty())"
           ></i>

        </div>
        &nbsp; &nbsp;
        <div class=" all-icon" matTooltip="Reset">
          <i class="fa fa-refresh reset align-items-center" (click)="resetPatientSearch()"></i>
        </div>
      </div>
    </form>


  <div class="col-lg-12 mt-3">
    <mat-divider></mat-divider>
    <!-- Loader Section -->
    <div class="row" *ngIf="isLoading">
      <div class="col-12">
        <div class="d-flex justify-content-center" *ngIf="isLoading">
          <img class="img-responsive pb-2 mr-1" src="assets/gifs/Infinity-loader.gif" />
        </div>
      </div>
    </div>
    <div class="col-12 mt-3">
      <div *ngIf="!isLoading" class="table table-responsive">
        <app-patient-search-newsetup-table [tableData]="patientSearchList"
          [patientID]="patientIDV2"></app-patient-search-newsetup-table>
      </div>
    </div>
  </div>

</div>
</div>
