import { ListService, PagedAndSortedResultRequestDto } from '@abp/ng.core';
import { HttpErrorResponse } from '@angular/common/http';
import { AfterViewInit, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { SaleorderDTO } from 'projects/order/src/app/order-proxy/order-management/order/dto/models';
import { SaleOrderSearchService } from 'projects/order/src/app/order-proxy/order-management/order/sale-order-search.service';
import { TableService } from 'projects/shared/src/app/table.service';

import { Observable, of, Subscription } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { PatientService } from '../patient-proxy/patient';
import { PatientDTO } from '../patient-proxy/patient/dto';


@Component({
  selector: 'app-patient-order',
  templateUrl: './patient-order.component.html',
  styleUrls: ['./patient-order.component.scss'],
  providers: [ListService],
})
export class PatientOrderComponent implements OnInit, AfterViewInit, OnDestroy {

  public strPageType: string = "orders";
  public strPageType2: string = "ordersHistory";
  drpPolicies: any;
  public preOrderId: string;
  public secondPreOrderId: string;
  @Input() orderId: string = '';
  @Input() patientId: string = '';
  tblNotesReponse: {
    ticketId: string; id: string; orderId: string; itemNames: string; qty: string;
    totalPrice: number; totalItemPrice: string; scheduleDate: string; originalDos: string;
    createdDate: string; billingStatus: string; status: string; orderProcessStatus: string;
  }[] = [];
  tblAuthVerifyResponse: any[] = [];
  insuranceId: any;
  isLoading: boolean;
  subscription$: Subscription[] = [];
  constructor(
    private router: Router,
    public list: ListService,
    private snackBar: MatSnackBar,
    private Table: TableService,
    private orderSearchService: SaleOrderSearchService,
    private patientService: PatientService
  ) { }



  getPolicies() {
    const getPolicies = this.patientService.get(this.patientId).subscribe(response => {
      let patientDetails: PatientDTO;
      patientDetails = response;
      this.drpPolicies = patientDetails?.policies;
    }, err => {
      // const data: HttpErrorResponse = err;
      // Swal.fire({
      //   icon: 'error',
      //   text: data?.error?.error?.message,
      // });
    });
    this.subscription$.push(getPolicies)
  }

  ngAfterViewInit(): void {


  }

  ngOnDestroy(): void {
    this.subscription$?.forEach((sub) => {
      sub && sub?.unsubscribe();
    });
  }

  isShownOrderButton: boolean = true;
  ngOnInit(): void {
    this.Table.setActiveTable([]);
    this.patientId = this.patientId ?? '';
    this.patientId !== '' && this.getTableData();
    this.snackBar.dismiss();

    const orderCreate = this.Table.getOrderCreate().subscribe(value => {

      if (value == true) {
        this.isShownOrderButton = true;
      }
      else {
        this.isShownOrderButton = false;
      }


    }, err => {
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: "Not Authorized for Create",
      });
    });
    this.subscription$.push(orderCreate);
    this.getTableData()
    this.Table.setSelectedPatientId("");

    const getPatientOrder = this.Table.getpatientOrder().subscribe(value => {
      this.insuranceId = value;
    }, err => {
      // const data: HttpErrorResponse = err;
      // Swal.fire({
      //   icon: 'error',
      //   text: data?.error?.error?.message,
      // });
    });
    this.subscription$.push(getPatientOrder);
    // if (this.orderId !== "" && this.orderId !== null && this.orderId !== undefined) { this.getTableData(this.orderId); }
  }
  datas: any[];
  organizationUnitId = null;

  getTableData() {
    this.isLoading = true;
    this.tblNotesReponse = [];
    this.Table.setActiveTable(this.tblNotesReponse);
    let buttonValidatedData: Observable<any[]>;
    this.orderSearchService
      .getList(new PagedAndSortedResultRequestDto())
      .pipe(
        map((value) => {
          let data: SaleorderDTO[] = [];
          value?.items?.forEach((element) => {
            element?.patientId === this.patientId && data?.push(element);
          });
          return data;
        })
      )
      .subscribe(
        (response) => {
          this.tblNotesReponse = [];

          this.preOrderId = response[0]?.id ?? '';
          this.secondPreOrderId = response[1]?.id ?? '';
          response?.forEach((element) => {
            const itemsData = element?.items;
            const totalItemPrice: string = String(itemsData?.reduce((prvsValue, curValue) => { return prvsValue + (+curValue?.totalPrice) }, 0) ?? 0);
            if (element && itemsData) {
              const itemData = {
                totalItemQty: String(itemsData?.reduce((prvsValue, curValue) => { return prvsValue + (+curValue?.qty) }, 0) ?? 0),
                mtotalItemPrice: totalItemPrice?.includes('.') ? (String(Number(totalItemPrice)?.toFixed(2)) ?? "") : (totalItemPrice ?? ""),
                itemNames: itemsData?.reduce((prvsValue, curValue) => { return `${(prvsValue ?? "") === "" ? "" : prvsValue + ", "}${curValue?.itemIdOrBarCode ?? ""}` }, "") ?? ""
              }
              const originalDos = itemsData[0]?.originalDos ?? "";
              String(itemsData?.reduce((prvsValue, curValue) => { return prvsValue + (+curValue?.totalPrice) }, 0) ?? 0)
              this.tblNotesReponse.push({
                ticketId: element?.shippingCheckList?.defaultTicketId,
                id: element?.id,
                orderId: element?.saleorderId,
                itemNames: itemData?.itemNames,
                qty: itemData?.totalItemQty,
                totalPrice: element?.shippingCheckList?.totalItemPrice == null || undefined ? 0 : element?.shippingCheckList?.totalItemPrice,
                totalItemPrice: itemData?.mtotalItemPrice,
                scheduleDate: element?.orders?.scheduledDate == null || undefined ? '' : new Date(element?.orders?.scheduledDate)?.toLocaleDateString("en-US"),
                originalDos: originalDos === "" ? '' : new Date(originalDos)?.toLocaleDateString("en-US"),
                createdDate: element?.creationTime === null || element?.creationTime === undefined ? '' : new Date(element?.creationTime)?.toLocaleDateString("en-US"),
                billingStatus: element?.orderBilling?.status,
                status: element?.shippingCheckList?.status,
                orderProcessStatus: element?.orderProcessStatus
              });
            }
          });
          this.isLoading = false;
          buttonValidatedData = of(this.tblNotesReponse);
          this.Table.setActiveTable(this.tblNotesReponse);
        }, err => {
          this.isLoading = false;
          const data: HttpErrorResponse = err;
          Swal.fire({
            icon: 'info',
            text: data?.error?.error?.message,
          });
        })

  }
  ViewOrders(record: any) {
    this.Table.setSelectedPatientId(this.patientId);
    if (record?.id === this.preOrderId) {
      this.router.navigate([
        'patient/saleorderCreateEdit/' + record.id,
        { preOrderID: this.secondPreOrderId },
      ]);
    } else {
      this.router.navigate([
        'patient/saleorderCreateEdit/' + record.id,
        { preOrderID: this.preOrderId },
      ]);
    }
  }
  setOrderStatusVoided(saleOrderId: string) {

    Swal.fire({
      title: 'Are you sure you want to Set as Void Order?',
      text: "You won't be able to Change this data!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#34c38f',
      cancelButtonColor: '#f46a6a',
      confirmButtonText: 'Yes, Change it!',
    }).then((result) => {
      if (result?.value) {
        const updateOrderBilling$ = this.orderSearchService.updateOrderBillingStatus(saleOrderId).subscribe((x) => {
          this.getTableData();
        }, err => {
          const data: HttpErrorResponse = err;
          Swal.fire({
            icon: 'info',
            text: data?.error?.error?.message,
          });
        })
        this.subscription$.push(updateOrderBilling$);
      }
    });
  }
  newSaleorder() {
    this.router.navigate([
      'patient/saleorderCreateEdit',
      { patientId: this.patientId, preOrderID: this.preOrderId },
    ]);
  }

  ViewVerify(record: any) {
    this.router.navigate(['patient/saleorderCreateEdit/' + record.id]);
  }
}
