import { HttpErrorResponse } from '@angular/common/http';
import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Inject,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import {
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatDialog,
} from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { OrganizationUnitService } from 'projects/admin/src/app/admin-proxy/platform-app-management/rcm/platform-management/organization-units';
import { OrganizationUnitDTO } from 'projects/admin/src/app/admin-proxy/platform-app-management/rcm/platform-management/organization-units/dto';
// import { ProductDTO } from 'projects/admin/src/app/admin-proxy/platform-app-management/rcm/platform-management/tenants';
// import { defaultGuid } from 'projects/admin/src/app/document-perviewer/document-perviewer.component';
// import { SaleType } from 'projects/billing/src/app/claim-proxy/claim-processing-management/rcm/order-management/order/sale-type.enum';
import {
  ProductService,
  ProductType,
} from 'projects/inventory/src/app/item-proxy/item-management/items';
// import { ProductsDTO } from 'projects/inventory/src/app/item-proxy/item-management/items/dto/models';
import { OrderDropdowns } from 'projects/patient/src/app/dropdown-proxy/dropdown-management/dropdowns';
import { ModifierDTO } from 'projects/patient/src/app/dropdown-proxy/dropdown-management/dropdowns/dto/billing';
import {
  BilledIntervalsDTO,
  BillingTypeDTO,
  ClaimNoteTypeDTO,
  ItemSizeDTO,
  OrderMasterDropdownDTO,
} from 'projects/patient/src/app/dropdown-proxy/dropdown-management/dropdowns/dto/order';
import {
  OrderMasterDropdownService,
  PatientMasterDropdownService,
} from 'projects/patient/src/app/dropdown-proxy/dropdown-management/dropdowns/master-dropdown-service';
import {
  PatientPersonalService,
  PatientPolicyService,
} from 'projects/patient/src/app/patient-proxy/patient-optimization';
import { PatientPolicyDTO } from 'projects/patient/src/app/patient-proxy/patient-optimization/dto';
import { NumberValidators } from 'projects/shared/src/app/validations/number-validator';
import { TextValidators } from 'projects/shared/src/app/validations/text-validator';
import { Observable, of, Subscription } from 'rxjs';
import { distinctUntilChanged, filter, map, startWith } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { ItemEligibilityRulesService } from '../order-proxy/order-management/inventory/item-eligibility-rules.service';
import {
  CreateUpdatePickUpExchangeDTO,
  CreateUpdateRentalSaleOrderTemplateDTO,
  CreateUpdateSaleOrderItemDTO,
  ItemDetailsListDTO,
  PickupExchangeType,
  SaleOrderItemDTO,
} from '../order-proxy/order-management/order-optimization/dto';
import { PatientSaleOrderService } from '../order-proxy/order-management/order-optimization/patient-sale-order.service';
import { RentalSaleOrderTemplateService } from '../order-proxy/order-management/order-optimization/rental-sale-order-template.service';
import { SaleOrderItemService } from '../order-proxy/order-management/order-optimization/sale-order-item.service';
import { ToastrService } from 'ngx-toastr';
import { PickUpExchangeService } from '../order-proxy/order-management/order-optimization/pick-up-exchange.service';
import { PickupExchangeOptionsTabComponent } from '../pickup-exchange-options-tab/pickup-exchange-options-tab.component';
import { mapEnumToOptions } from '@abp/ng.core';
import { RentalTypes } from '../order-proxy/order-management/order-optimization/rental-types.enum';
import { proudctPriceType } from '../order-proxy/order-management/order-optimization/proudct-price-type.enum';
import { ProductDetailsService } from 'projects/inventory/src/app/item-proxy/item-management/optimization';
import { Title } from '@angular/platform-browser';
import { element } from 'protractor';
import { RentalOrderJobStatus } from '../order-proxy/order-management/order-optimization/rental-order-job-status.enum';
import { MatSelect } from '@angular/material/select';
import { MatOption } from '@angular/material/core';
import { defaultGuid } from 'projects/shared/src/app/enums/allenums.enum';

@Component({
  selector: 'app-add-item-tab',
  templateUrl: './add-item-tab.component.html',
  styleUrls: ['./add-item-tab.component.scss'],
})
export class AddItemTabComponent implements OnInit {
  @ViewChild('select') select: MatSelect;
  allSelected=false;
  monthAryList = []
  // monthsList: { key: string, value: string, selected: boolean }[] = [

  //   { key: 'January', value: '1', selected: false },
  //   { key: 'February', value: '2', selected: false },
  //   { key: 'March', value: '3', selected: false },
  //   { key: 'April', value: '4', selected: false },
  //   { key: 'May', value: '5', selected: false },
  //   { key: 'June', value: '6', selected: false },
  //   { key: 'July', value: '7', selected: false },
  //   { key: 'August', value: '8', selected: false },
  //   { key: 'September', value: '9', selected: false },
  //   { key: 'October', value: '10', selected: false },
  //   { key: 'November', value: '11', selected: false },
  //   { key: 'December', value: '12', selected: false },
  //   // Add other months with selected: false
  // ];
  drpScheduleType: any[] = ["Daily", "Weekly", "Monthly", "Yearly", "Once"];
  clearScheduleType() {
    this.itemForm.patchValue({
      drpMonths: []
    });
  };
  orderId: string = defaultGuid;
  patientId: string = defaultGuid;
  itemId: string = defaultGuid;
  itemForm!: FormGroup;
  totalPrice: string = '';
  productType: any;
  selectAllMonths : boolean = false;
  selectedMonths
  startDate = new Date()
  rentalResupplyOrder: any;
  orderStatus: boolean = true;
  isShowSpinner: boolean = false;
  step: boolean = false;
  step1: boolean = false;
  strScheduleType: string = "";
  notesText ='';
  isTemplate: boolean = false;
  arrMonths: [] = [];
  arrWeeks: [] = [];
  subscription$: Subscription[] = [];
  drpSize: ItemSizeDTO[] = [];
  drpItemClaimType: ClaimNoteTypeDTO[] = [];
  drpBillingType: BillingTypeDTO[] = [];
  drpBilingInterval: BilledIntervalsDTO[] = [];
  tenantId: string = localStorage.getItem('tenantId') ?? defaultGuid;
  productId: string = defaultGuid;
  drpModifiers: ModifierDTO[] = [];
  drpLocationTypes: OrganizationUnitDTO[] = [];
  drpProducts: any;
  filteredItemTypes: Observable<any[]>;
  txtItem: string = '';
  scheduledMinDate: Date;
  drpMonthsList: { key: string, value: string }[] = []
  drpWeeksList: { key: string, value: string }[] = [];
  txtInsurance: string = '';
  billingType: string = '';
  location: string = '';
  size: string = '';
  templateCode: string;
  isInsurance : boolean = false
  minDate: Date;
  maxDate: Date;
  btnVal: string = 'Save';
  isValidators: boolean = false;
  isProductDype: boolean = false;
  drpPolicies: PatientPolicyDTO[] = [];
  billedInterval: string = '';

  constructor(
    public dialogRef: MatDialogRef<AddItemTabComponent>,
    public dialog: MatDialog,
    private numberValidators: NumberValidators,
    private activatedRoute: ActivatedRoute,
    private textValidators: TextValidators,
    private formBuilder: FormBuilder,
    private productService: ProductService,
    private productDetailsService: ProductDetailsService,
    private itemService: SaleOrderItemService,
    private itemEligibilityRulesService: ItemEligibilityRulesService,
    private orderDropdownService: OrderMasterDropdownService,
    private patientPolicyService: PatientPolicyService,
    private organizationUnitService: OrganizationUnitService,
    private patientDropdownService: PatientMasterDropdownService,
    private saleOrderService: PatientSaleOrderService,
    private rentalSaleOrderTemplateService: RentalSaleOrderTemplateService,
    private pickupExchangeService: PickUpExchangeService,
    private toastr: ToastrService,
    private cdref: ChangeDetectorRef,
    public title: Title,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      patientId: string;
      orderId: string;
      itemId: string;
      isTemplate: boolean;
      isExchange: boolean;
    } = {
      patientId: defaultGuid,
      orderId: defaultGuid,
      itemId: defaultGuid,
      isTemplate: false,
      isExchange: false,
    }
  ) {}

  ngOnDestroy(): void {
    this.subscription$?.forEach((sub) => {
      sub && sub?.unsubscribe();
    });
  }

  ngOnInit(): void {
    this.title.setTitle("Qsecure | Item");
    this.scheduledMinDate = new Date();
    this.scheduledMinDate.setDate(this.scheduledMinDate.getDate() );
    this.isTemplate = this.data?.isTemplate ?? false;
    this.patientId = this.data?.patientId ?? defaultGuid;
    this.orderId = this.data?.orderId ?? defaultGuid;
    this.itemId = this.data?.itemId ?? defaultGuid;

    this.createItemForm();
    this.getDropdown();
    this.step = true
  }
  updateCharacterCount() {
    // Ensure the text length does not exceed the maximum length
    if (this.notesText?.length > 5000) {
      this.notesText = this.notesText?.substr(0, 5000);
    }
  }
  onItemChangeDrpDown(value: any) {
    // const price = isNaN(+value?.price ?? 0) ? '' : +value?.price ?? 0;
    if(value != null){
      this.productType = value?.productType
      const spiltItemName = value.productName.split('/')
      this.itemForm.patchValue({
        txtItemName: spiltItemName[1],
        txtBrandName: value?.brandName,
        txtCategoryName: value?.categoryName,
        txtItemDescription: value?.productDescription,
        txtPrice: value?.pricePerUnit,
        txtAllow: value?.productallowedamount,
        txtProductType: this.productType == 'MainProduct' ? 1 : this.productType == 'Rental' ? 2 :this.productType == 'ResupplyProduct' ? 3 : 1
      });

    } else {
    this.itemForm.patchValue({
      txtItemName: value?.name,
      txtBrandName: value?.brandName,
      txtCategoryName: value?.categoryName,
      txtItemDescription: value?.productDescription,
      txtPrice: value?.pricePerUnit,
      txtAllow: value?.productallowedamount,
      txtProductType: 1

    });

  }
    // this.itemForm.markAllAsTouched();
    // this.itemForm.updateValueAndValidity();
  }
  getItemDetailsById(itemId: string) {

    this.itemService.get(itemId).subscribe((response) => {
      const selectedProduct =
        this.drpProducts?.filter(
          (x) => x?.productId === response?.productId
        )?.[0] ?? null;
      selectedProduct && this.onItemChangeDrpDown(selectedProduct);
      this.rentalResupplyOrder = response?.rentalresupplysaleOrder
      this.templateCode = this.rentalResupplyOrder?.templateCode
      const months = this.rentalResupplyOrder?.lstMonths?.map(x => this.drpMonthsList?.filter(y => +y?.value === x)[0]) ?? [];
      // const months = this.rentalResupplyOrder?.lstMonths
      this.itemForm.patchValue({
        txtItem: selectedProduct,
        drpLocation: response?.location,
        txtNote: response?.note,
        drpInsurance: response?.insuranceName,
        drpSize: response?.itemSize,
        //! Item Follow Up Details with default value 30 days and checked
        txtFollowUpDuration: Number(+response?.followUpDuration) ?? 0,
        chkIsFollowUpNeeded: response?.isFollowupNeeded,
        chkPricing: response?.isSpecialPricing,
        chkTaxable: response?.isTaxable,
        txtPrice: response?.charge,
        chkChargeOveride: response?.isPriceOverride,
        chkTaxRate: response?.isSpecialTaxRate,
        chkAssignment: response?.isAcceptAssignment,
        chkConvertPurchase: response?.isManualConvertToPurchase,
        chkAbn: response?.isAbn,
        chkAbnUpgrade: response?.isAbnUpgrade,
        chkCbOverride: response?.isCbOverride,
        txtOrderingQty: response?.orderingQuantity,
        txtBillingQty:
          +response?.billingQuantity === 0 ? '' : response?.billingQuantity,
        drpPriceCode: response,
        drpPriceOption: response,
        txtModifiers1: response?.modifiers1,
        txtModifiers2: response?.modifiers2,
        txtModifiers3: response?.modifiers3,
        txtModifiers4: response?.modifiers4,
        txtAllow: response?.allow,
        chkSupply: response?.isDaysOfSupplyOverride,
        chkSplit: response?.isSpanDateSplitBillingEnabled,
        chkPatient: response?.isPatient,
        txtFromDOS: response?.dosFrom,
        txtToDOS: response?.dosTo,
        chkPrimary: response?.isPrimary,
        chkSecondary: response?.isSecondary,
        chkTertiary: response?.isTertiary,
        txtCoverageType: response?.coverageType,
        txtSubmission: response?.claimSubmission,
        txtProductType: response?.billedType,
        drpScheduleType : this.rentalResupplyOrder?.rentalTypes,
        txtScheduledDate: this.rentalResupplyOrder?.startDate,
        txtEndPeriod: this.rentalResupplyOrder?.endPeriod,
        txtLastDate: this.rentalResupplyOrder?.lastRun,
        txtNextDate: this.rentalResupplyOrder?.nextRun,
        drpMonths: months,
        txtYears: this.rentalResupplyOrder?.lstYears,
        txtWeeks: this.rentalResupplyOrder?.lstWeeks,
        txtScheduledDays: this.rentalResupplyOrder?.monthDay
      });
      if(months.length == 12){
        this.allSelected = true
      } else{
        this.allSelected = false
      }
      if(this.productType != 'MainProduct' ){
        this.isValidators = true
        // this.step1 = false
      } else if(this.itemForm.value.txtProductType == 2 || this.itemForm.value.txtProductType == 3 ){
        this.step1 = true
      }
      this.calulateTotalByUnitsPrice(
        String(response?.charge ?? 0),
        String(response?.orderingQuantity ?? 0)
      );
    });
  }
  toggleAllSelections() {
    if (this.allSelected) {
      this.select.options.forEach((item: MatOption) => item.select());
    } else {
      this.select.options.forEach((item: MatOption) => item.deselect());
    }
  }
   optionClick() {
    let newStatus = true;
    this.select.options.forEach((item: MatOption) => {
      if (!item.selected) {
        newStatus = false;
      }
    });
    this.allSelected = newStatus;
  }
  onChange(e){
    this.monthAryList.push(e.source.value)
    // this.itemForm.value.drpMonths.push(e.source.value)
  }

  //! create Item Form
  createItemForm() {
    this.itemForm = this.formBuilder.group({
      txtSearchFilter: new FormControl(''),
      txtItem: new FormControl(null, [Validators.required]),
      txtItemName: new FormControl('', [Validators.required]),
      txtItemDescription: new FormControl(''),
      drpLocation: new FormControl(null),
      txtNote: new FormControl(''),
      drpInsurance: new FormControl('',[Validators.required]),
      txtInsSearchFilter: new FormControl(''),
      drpSize: new FormControl(''),
      txtItems: new FormControl(''),
      txtScheduledDays: new FormControl(''),
      drpMonths: new FormControl([]),
      drpScheduleType: new FormControl(''),
      txtScheduleSummary: new FormControl(''),
      txtLastDate: new FormControl(''),
      txtNextDate: new FormControl(''),
      drpWeeks: new FormControl(''),
      txtScheduledDate: new FormControl(''),
      txtWeeks: new FormControl(''),
      txtYears: new FormControl(''),
      txtEndPeriod: new FormControl(''),
      // newly added
      txtCategoryName: new FormControl(''),
      txtBrandName: new FormControl(''),
      //! Item Follow Up Details with default value 30 days and checked
      txtFollowUpDuration: new FormControl(30, [

        this.numberValidators.isNumberCheck,
        Validators.min(0),
        Validators.max(99),
        Validators.minLength(1),
        Validators.maxLength(3),
      ]),
      chkIsFollowUpNeeded: new FormControl(true),
      chkPricing: new FormControl(''),
      chkTaxable: new FormControl(''),
      chkChargeOveride: new FormControl(''),
      chkTaxRate: new FormControl(''),
      chkAssignment: new FormControl(''),
      chkConvertPurchase: new FormControl(''),
      chkAbn: new FormControl(''),
      chkAbnUpgrade: new FormControl(''),
      chkCbOverride: new FormControl(''),
      txtProductType: new FormControl(''),
      txtOrderingQty: new FormControl('', [
        Validators.required,
        this.numberValidators.isNumberCheck,
        Validators.min(1),
        Validators.pattern('^0*[1-9]+[0-9]*$'),
      ]),
      txtBillingQty: new FormControl('', [
        Validators.pattern('^0*[1-9]+[0-9]*$'),
        this.numberValidators.isNumberCheck,
      ]),
      txtModifiers1: new FormControl(''),
      txtModifiers2: new FormControl(''),
      txtModifiers3: new FormControl(''),
      txtModifiers4: new FormControl(''),
      // Modifiers Serach Parameters
      txtPrice: new FormControl('', [
        Validators.required,
        this.numberValidators.isNumberCheck,
      ]),
      txtAllow: new FormControl('', [this.numberValidators.isNumberCheck]),

      chkSupply: new FormControl(''),
      chkSplit: new FormControl(''),
      chkPatient: new FormControl(''),
      txtFromDOS: new FormControl('', [Validators.required]),
      txtToDOS: new FormControl(''),
      chkPrimary: new FormControl(''),
      chkSecondary: new FormControl(''),
      chkTertiary: new FormControl(''),
      txtCoverageType: new FormControl(''),
      txtSubmission: new FormControl(''),
    });

    this.drpMonthsList = monthTypeOptions?.map(x => { let y = { key: x?.key.toString(), value: x?.value.toString() }; return y });
    this.drpWeeksList = weekTypeOptions?.map(x => { let y = { key: x?.key.toString(), value: x?.value.toString() }; return y });

    this.itemForm.get("drpScheduleType").valueChanges.subscribe(x => {
      this.resetScheduleForm();
      if (x === 'Once') {
        this.itemForm.patchValue({
          txtEndPeriod: "1"
        });
      }
      if (x !== 'Once') {
        this.itemForm.patchValue({
          txtEndPeriod: "999"
        });
      }
      this.itemForm?.get("txtScheduledDate")?.setValidators([Validators.required]);
      this.itemForm?.get("txtScheduledDate")?.updateValueAndValidity();
      this.itemForm?.get("txtScheduledDays")?.setValidators([Validators.nullValidator]);
      this.itemForm?.get("txtScheduledDays")?.updateValueAndValidity();
      this.itemForm?.get("drpMonths")?.setValidators([Validators.nullValidator]);
      this.itemForm?.get("drpMonths")?.updateValueAndValidity();
      if (x === "Monthly") {
        this.itemForm.patchValue({
          txtEndPeriod: "13"
        });
        this.itemForm?.get("txtScheduledDate")?.setValidators([Validators.nullValidator]);
        this.itemForm?.get("txtScheduledDate")?.updateValueAndValidity();
        this.itemForm?.get("drpMonths")?.setValidators([Validators.required]);
        this.itemForm?.get("drpMonths")?.updateValueAndValidity();
        this.itemForm?.get("txtScheduledDays")?.setValidators([Validators.required]);
        this.itemForm?.get("txtScheduledDays")?.updateValueAndValidity();
      }

    })
    this.itemForm.get("txtScheduledDate")?.valueChanges?.subscribe(y => {
      this.calucateDays();
    })
    this.itemForm.get("txtEndPeriod")?.valueChanges?.subscribe(y => {
      this.calucateDays();
    })

    this.itemForm.get("txtScheduledDays")?.valueChanges?.subscribe(y => {
      this.calucateDays();
    })
    this.itemForm.get("drpMonths")?.valueChanges?.subscribe(y => {
      this.calucateDays();
    })

    this.itemForm.controls['txtPrice'].valueChanges.subscribe((response) => {
      +response === 0 &&
        this.itemForm.controls['txtPrice'].setErrors({ lessThan0: true });
      this.calulateTotalByUnitsPrice(
        response,
        this.itemForm?.value?.txtOrderingQty
      );
    });
    this.itemForm.controls['txtOrderingQty'].valueChanges.subscribe(
      (response) => {
        this.calulateTotalByUnitsPrice(
          this.itemForm?.value?.txtPrice,
          response
        );
      }
    );
    this.btnVal = this.data.isExchange ? 'Exchange' : 'Save';
    this.onItemChangeDrpDown(null);
  }
  //  Calulate by value changes Total Price
  calulateTotalByUnitsPrice(txtPrice: string, txtUnits: string) {
    const units = +txtUnits ?? 0;
    const fee = +txtPrice ?? 0;
    this.totalPrice = isNaN(units * fee ?? 0)
      ? '0'
      : (units * fee ?? 0)?.toFixed(2);
  }

  // dropdown data //
  getDropdown() {

    if (!this.data.isExchange) {
    }
    const orderDropdownInput: OrderDropdowns[] = [
      OrderDropdowns.ItemInsurances,
      OrderDropdowns.Itemsize,
      OrderDropdowns.lstBillingTypes,
      OrderDropdowns.lstBilledIntervals,
    ];
    const orderDropdown = this.orderDropdownService
      .getOrderDropdownsByInput(orderDropdownInput)
      .subscribe(
        (stateResponse) => {
          const response: OrderMasterDropdownDTO = stateResponse;
          this.drpSize = response?.itemSize;
          this.drpBillingType = response?.lstBillingTypes;
          this.drpBilingInterval = response?.lstBilledIntervals;
        },
        (err) => {
          const data: HttpErrorResponse = err;
          Swal.fire({
            icon: 'info',
            text: data?.error?.error?.message,
          });
        }
      );
    this.subscription$.push(orderDropdown);
    const getOrganizationList = this.organizationUnitService
      .getOrganizationList(this.tenantId)
      .subscribe(
        (response) => {
          this.drpLocationTypes = response ?? [];
        },
        (err) => {
          const data: HttpErrorResponse = err;
          Swal.fire({
            icon: 'info',
            text: data?.error?.error?.message,
          });
        }
      );
    this.subscription$.push(getOrganizationList);

    if (this.isTemplate) {
      const saleOrderGet = this.rentalSaleOrderTemplateService
        .getSaleOrderDetailsByTemplateByGSaleOrderId(this.orderId)
        .subscribe((response) => {
          if (response?.isCashPayment === 1) {
            this.isInsurance = true
            this.itemForm.controls["drpInsurance"].clearValidators();
            this.itemForm?.get('drpInsurance').disable();


          }else {
            this.isInsurance = false
          }
        });
      this.subscription$.push(saleOrderGet);
    } else {
      const saleOrderGet = this.saleOrderService
        .get(this.orderId)
        .subscribe((response) => {
          if (response?.isCashPayment === 1) {
            this.isInsurance = true
            this.itemForm.controls["drpInsurance"].clearValidators();
            this.itemForm?.get('drpInsurance').disable();

          } else {
            this.isInsurance = false
          }
        });
      this.subscription$.push(saleOrderGet);
    }
    // getProductsByInput
    const getProduct = this.productDetailsService
      .getProductDetailsforSaleOrder()

      .subscribe(
        (response) => {
          this.drpProducts = response.items?? [];
          this.filteredItemTypes = this.itemForm
            ?.get('txtSearchFilter')
            .valueChanges.pipe(
              startWith(''),
              map((value) =>
                this.drpProducts?.filter((option) =>
                  option?.productName
                    ?.toLowerCase()
                    ?.includes(value?.toLowerCase() ?? '')
                )
              )
            );
          this.itemId !== defaultGuid &&
            !this.data.isExchange &&
            this.getItemDetailsById(this.itemId);
        },
        (err) => {
          const data: HttpErrorResponse = err;
          Swal.fire({
            icon: 'info',
            text: data?.error?.error?.message,
          });
        }
      );
    this.subscription$.push(getProduct);

    const patientPolicies = this.patientPolicyService
      .getActivePolicyByPatientIdByPatientId(this.patientId)
      .subscribe(
        (response) => {

          this.drpPolicies = response ?? [];
          // this.drpPolicies.forEach(element => {
          //   element['payorNamePayorId'] = element.payorId+"/"+element.payorName
          //  this.selectPayor = element.payorId+"/"+element.payorName

          // });
        },
        (err) => {
          const data: HttpErrorResponse = err;
          Swal.fire({
            icon: 'info',
            text: data?.error?.error?.message,
          });
        }
      );
    this.subscription$.push(patientPolicies);
  }
    //reset Schedule Form
    resetScheduleForm() {
      this.itemForm.patchValue({
        // chkDisabled: "",
        txtEndPeriod: "",
        txtScheduledDate: "",
        txtScheduledDays: "",
        drpMonths: [],
        txtScheduleSummary: "",
        txtLastDate: "",
        txtNextDate: "",
      })
    }
  // Item Change

  ChangeItems(){


  }
  purchasevalue(){
    this.step1 = false
    this.productType = 'MainProduct'

  }
  ChangeRetal(){
    this.step1 = true
    this.productType = 'Rental'
    this.itemForm.controls["drpScheduleType"].setValidators(Validators.required);
    this.itemForm.controls["txtScheduledDate"].setValidators(Validators.required);
    this.itemForm.controls["txtEndPeriod"].setValidators(Validators.required);
    this.itemForm.controls["txtScheduledDays"].setValidators(Validators.required);
    this.itemForm.controls["drpMonths"].setValidators(Validators.required);
    this.itemForm.controls["txtWeeks"].setValidators(Validators.required);
    this.itemForm.controls["txtYears"].setValidators(Validators.required);
    this.isValidators = true
  }
  ChangeResupply(){
    this.step1 = true
    this.productType = 'Resupply'
    this.itemForm.controls["drpScheduleType"].setValidators(Validators.required);
    this.itemForm.controls["txtScheduledDate"].setValidators(Validators.required);
    this.itemForm.controls["txtEndPeriod"].setValidators(Validators.required);
    this.itemForm.controls["txtScheduledDays"].setValidators(Validators.required);
    this.itemForm.controls["drpMonths"].setValidators(Validators.required);
    this.itemForm.controls["txtWeeks"].setValidators(Validators.required);
    this.itemForm.controls["txtYears"].setValidators(Validators.required);
    this.isValidators = true
  }

  toggle() {

  }


  //Save Item
  saveItem() {
    const itemNames = this.itemForm?.get('txtItem')?.value?.productName.split("/")
    this.isShowSpinner = true;
    const lstMonths: number[] = this.itemForm?.get('drpScheduleType')?.value == 'Monthly' ?  this.itemForm?.get('drpMonths')?.value?.map((x: { value: string }) => ((+x?.value))) : [0];
    // const lstMonths: number[] = this.monthAryList.map((x: { x: string }) => ((+x)))
    const data : CreateUpdateRentalSaleOrderTemplateDTO = {
      templateCode:this.templateCode ?? '',
      saleOrderId: this.orderId,
      rentalTypes: this.itemForm?.get('drpScheduleType')?.value ? this.itemForm?.get('drpScheduleType')?.value : 'Once',
      startDate: this.itemForm?.get('txtScheduledDate')?.value ? this.itemForm?.get('txtScheduledDate')?.value :  this.startDate,
      endPeriod: Number( this.itemForm?.get('txtEndPeriod')?.value) ?? 0,
      nextRun:'',
      periodsRun:0,
      isEnabled:0,
      templateSummary: '',
      monthDay: Number(this.itemForm?.get('txtScheduledDays')?.value ? this.itemForm?.get('txtScheduledDays')?.value : 0),
      lstMonths: lstMonths,
      weeknumber: this.itemForm?.get('txtWeeks')?.value ? +(this.itemForm?.get('txtWeeks')?.value) : 0,
      yearNumber: Number(this.itemForm?.get('txtYears')?.value ? this.itemForm?.get('txtYears')?.value: 0),
      lstWeeks:[0],
      lstYears: [""],
      status: RentalOrderJobStatus.Active,
      // statusType: null



    }
    let itemDetails: CreateUpdateSaleOrderItemDTO = {
      patientId: this.patientId,
      saleOrderId: this.orderId,
      isTemplate: this.isTemplate === true ? 1 : 0,
      itemName: itemNames[1]?? '',
      itemProcedureCode: itemNames[0] ?? '',
      description: this.itemForm?.get('txtItemDescription')?.value,
      location: this.itemForm?.get('drpLocation')?.value,
      itemSize: this.itemForm?.get('drpSize')?.value,
      note: this.itemForm?.get('txtNote')?.value,
      insuranceName: this.itemForm?.get('drpInsurance')?.value,
      isSpecialPricing: +this.itemForm?.get('chkPricing')?.value | 0,
      isTaxable: +this.itemForm?.get('chkTaxable')?.value | 0,
      isPriceOverride: +this.itemForm?.get('chkChargeOveride')?.value | 0,
      isSpecialTaxRate: +this.itemForm?.get('chkTaxRate')?.value | 0,
      isAcceptAssignment: +this.itemForm?.get('chkAssignment')?.value | 0,
      isManualConvertToPurchase:
        +this.itemForm?.get('chkConvertPurchase')?.value | 0,
      isAbn: +this.itemForm?.get('chkAbn')?.value | 0,
      isAbnUpgrade: +this.itemForm?.get('chkAbnUpgrade')?.value | 0,
      isCbOverride: +this.itemForm?.get('chkCbOverride')?.value | 0,
      orderingQuantity: +this.itemForm?.get('txtOrderingQty')?.value,
      modifiers1: this.itemForm?.get('txtModifiers1')?.value,
      modifiers2: this.itemForm?.get('txtModifiers2')?.value,
      modifiers3: this.itemForm?.get('txtModifiers3')?.value,
      modifiers4: this.itemForm?.get('txtModifiers4')?.value,
      charge: this.itemForm?.get('txtPrice')?.value,
      allow: String(this.itemForm?.get('txtAllow')?.value),
      billingQuantity: +this.itemForm?.value?.txtBillingQty,
      isPrimary: +this.itemForm?.get('chkPrimary')?.value | 0,
      coverageType: this.itemForm?.get('txtCoverageType')?.value,
      claimSubmission: this.itemForm?.get('txtSubmission')?.value,
      isSecondary: +this.itemForm?.get('chkSecondary')?.value | 0,
      isTertiary: +this.itemForm?.get('chkTertiary')?.value | 0,
      isPatient: +this.itemForm?.get('chkPatient')?.value | 0,
      isDaysOfSupplyOverride: +this.itemForm?.get('chkSupply')?.value | 0,
      dosFrom: this.itemForm?.get('txtFromDOS')?.value,
      dosTo: this.itemForm?.get('txtToDOS')?.value ? this.itemForm?.get('txtToDOS')?.value : null,
      nextPeriod: this.itemForm?.get('txtNextPeriod')?.value,
      isSpanDateSplitBillingEnabled: +this.itemForm?.get('chkSplit')?.value | 0,
      productId: this.itemForm?.get('txtItem')?.value?.productId,
      isFollowupNeeded: this.itemForm.value.chkIsFollowUpNeeded,
      followUpDuration: +this.itemForm.value.txtFollowUpDuration,
      rentalresupplySaleOrder: data,
      isReturned: false,
      billedType: this.itemForm?.get('txtProductType')?.value ? this.itemForm?.get('txtProductType')?.value : 1

    };

    if (this.isTemplate) {
      if (this.itemId === defaultGuid) {
        this.itemService.create(itemDetails).subscribe(
          (response) => {
            // Swal.fire({ title: 'Success', html: 'Saved Successfully', icon: 'success', timer: 3000, timerProgressBar: true });
            this.toastr.success('Saved Successfully', 'Success');
            this.dialogRef.close();
            this.isShowSpinner = false;
          },
          (err) => {
            this.isShowSpinner = false;
            const data: HttpErrorResponse = err;
            Swal.fire({
              icon: 'info',
              text: data?.error?.error?.message,
            });
          }
        );
      } else {
        this.itemService.update(this.itemId, itemDetails).subscribe(
          (response) => {
            // Swal.fire({ title: 'Success', html: 'Saved Successfully', icon: 'success', timer: 3000, timerProgressBar: true });
            this.toastr.success('Saved Successfully', 'Success');
            this.dialogRef.close();
            this.isShowSpinner = false;
          },
          (err) => {
            this.isShowSpinner = false;
            const data: HttpErrorResponse = err;
            Swal.fire({
              icon: 'info',
              text: data?.error?.error?.message,
            });
          }
        );
      }
    } else if (this.data.isExchange) {
      this.exchangeItems(itemDetails);
    } else {
      if (this.itemId === defaultGuid) {
        this.itemService.create(itemDetails).subscribe(
          (response) => {
            // Swal.fire({ title: 'Success', html: 'Saved Successfully', icon: 'success', timer: 3000, timerProgressBar: true });
            this.toastr.success('Saved Successfully', 'Success');
            this.dialogRef.close();
            this.isShowSpinner = false;
          },
          (err) => {
            this.isShowSpinner = false;
            const data: HttpErrorResponse = err;
            Swal.fire({
              icon: 'info',
              text: data?.error?.error?.message,
            });
          }
        );
      } else {
        this.itemService.update(this.itemId, itemDetails).subscribe(
          (response) => {
            // Swal.fire({ title: 'Success', html: 'Saved Successfully', icon: 'success', timer: 3000, timerProgressBar: true });
            this.toastr.success('Saved Successfully', 'Success');
            this.dialogRef.close();
            this.isShowSpinner = false;
          },
          (err) => {
            this.isShowSpinner = false;
            const data: HttpErrorResponse = err;
            Swal.fire({
              icon: 'info',
              text: data?.error?.error?.message,
            });
          }
        );
      }
    }
  }
  toggleAllSelection(){

  }
  exchangeItems(itemDetails: CreateUpdateSaleOrderItemDTO) {
    const dialogRef = this.dialog.open(PickupExchangeOptionsTabComponent, {
      disableClose: true,
      data: {
        itemId: this.itemId ?? defaultGuid,
        patientId: this.patientId ?? defaultGuid,
        orderId: this.orderId ?? defaultGuid,
        itemDetails: itemDetails ?? null,
        isExchange: true,
      },
    });
    this.dialogRef.close();
  }
// get Schedule Month Summary
getScheduleMonthSummary(value: string, value2: string[] = []) {
  return `Starting on ${value}  ${value2?.length !== 0 ? ' every ' + value2?.join(",") : ""}`;
}
// get Schedule Summary

getScheduleSummary(value: string, value2: string) {
  return `Starting on ${value} - Ending on ${value2}`;

}
// get Custom Data formatted

getDate(daysInNumber: number) {
  return new Date(` ${new Date().getMonth() + 1} / ${daysInNumber} / ${new Date().getFullYear()}`)
}

    // calucate Days on form chnages
    calucateDays() {
      const drpScheduleType = this.itemForm.value.drpScheduleType;
      const txtScheduledDate = this.itemForm.get("txtScheduledDate").value ?? null;
      const txtEndPeriod = Number(+this.itemForm.get("txtEndPeriod").value);
      const txtScheduledDays = this.itemForm.get("txtScheduledDays").value;
      const drpMonths = this.itemForm.get("drpMonths")?.value;
      // const drpWeeks = this.itemForm.get("drpWeeks")?.value;
      if (drpScheduleType === 'Monthly') {
        if (txtScheduledDays && drpMonths) {
          let lstMonths: string[] = drpMonths?.map((x: { key: string }) => ((x?.key))) ?? [];
          lstMonths = lstMonths?.filter(x => x !== undefined);
          this.itemForm.patchValue({
            txtScheduleSummary: this.getScheduleMonthSummary(this.getDate(txtScheduledDays).toLocaleDateString(), lstMonths)
          })
        }
      }

      if (drpScheduleType === 'Weekly') {
        if (txtScheduledDate && txtEndPeriod) {
          this.itemForm.patchValue({
            txtScheduleSummary: this.getScheduleSummary(new Date(txtScheduledDate).toLocaleDateString(), getDateXDaysAgo(new Date(txtScheduledDate), txtEndPeriod).toLocaleDateString()),
          })
        }
      }
      if (drpScheduleType === 'Once') {
        if (txtScheduledDate && txtEndPeriod) {
          this.itemForm.patchValue({
            txtScheduleSummary: this.getScheduleSummary(new Date(txtScheduledDate).toLocaleDateString(), getDateXDaysAgo(new Date(txtScheduledDate), txtEndPeriod).toLocaleDateString()),
          })
        }
      }
      if (drpScheduleType === 'Yearly') {
        if (txtScheduledDate && txtEndPeriod) {
          this.itemForm.patchValue({
            txtScheduleSummary: this.getScheduleSummary(new Date(txtScheduledDate).toLocaleDateString(), getDateXDaysAgo(new Date(txtScheduledDate), txtEndPeriod).toLocaleDateString()),
          })
        }
      }
      if (drpScheduleType === 'Daily') {
        if (txtScheduledDate && txtEndPeriod) {
          this.itemForm.patchValue({
            txtScheduleSummary: this.getScheduleSummary(new Date(txtScheduledDate).toLocaleDateString(), getDateXDaysAgo(new Date(txtScheduledDate), txtEndPeriod).toLocaleDateString()),
          })
        }
      }
    }

  // clear dropdown
  clearItemType() {
    this.txtItem = '';
    this.itemForm.patchValue({
      txtItem: '',
      drpInsurance: '',
      txtOrderingQty: '',
    });
  }
  clearInsType() {
    this.txtInsurance = '';
    this.itemForm.patchValue({
      drpInsurance: '',
      txtOrderingQty: '',
    });
  }
  clearBillingType() {
    this.billingType = '';
    this.itemForm.patchValue({ drpBillingType: '' });
  }
  clearLocation() {
    this.location = '';
    this.itemForm.patchValue({
      drpLocation: '',
    });
  }
  clearSize() {
    this.size = '';
    this.itemForm.patchValue({
      drpSize: '',
    });
  }
  clearBilledInterval() {
    this.billedInterval = '';
    this.itemForm.patchValue({
      drpBilingInterval: '',
    });
  }

}

enum MONTHS { JANUARY = 1, FEBRUARY =2, MARCH = 3, APRIL = 4, MAY = 5, JUNE = 6, JULY = 7, AUGUST = 8, SEPTEMBER = 9, OCTOBER = 10, NOVEMBER = 11, DECEMBER = 12 }
export const monthTypeOptions = mapEnumToOptions(MONTHS);
function getDateXDaysAgo(startDate: Date, dateRange: number) {
  startDate.setDate(startDate.getDate() + (dateRange));
  return startDate;
}
enum WEEK { 'First Week'  = 1, 'Second Week' =2, 'Third Week' = 3, 'Fourth Week' = 4, 'Fifth Week' = 5 }
export const weekTypeOptions = mapEnumToOptions(WEEK);
function getDateXDaysAgos(startDate: Date, dateRange: number) {
  startDate.setDate(startDate.getDate() + (dateRange));
  return startDate;
}
