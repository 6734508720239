<br />
<div class="card card-body ">
  <div class="row">
    <div class="col-6">
      <h4 class="customThemeClass"><b>General Info</b></h4>
    </div>
    <div class="col-6 text-lg-right">
      <button *ngIf="fileId!==''" matTooltip="Click To Verify Rules" (click)="navigateToRulesPage()" mat-button
        class=" font-size-10 " class="buttonColor">
        Verify Rule
      </button>
    </div>
  </div>
  <form [formGroup]="personalForm">
    <div class="row">
      <div class="col-lg-12">
        <mat-card-content>
          <div class="row">
            <mat-form-field class="col-3">
              <mat-label> Chart Id <span class="asterisk">*</span></mat-label>
              <input autocomplete="new-txtChartId" matInput maxlength="40" formControlName="txtChartId" type="text" />
            </mat-form-field>
            <mat-form-field class="col-3">
              <mat-label> Branch <span class="asterisk">*</span> </mat-label>
              <mat-select maxlength="40" [(value)]="location" formControlName="drpLocation"
                [errorStateMatcher]="matcher">
                <mat-option *ngFor="let location of drpLocationTypes" [value]="location.organizationUnitName">
                  {{ location.organizationUnitName }}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="
                personalForm?.get('drpLocation')?.touched &&
                personalForm?.get('drpLocation')?.errors?.required
              ">
                Branch is a required field!
              </mat-error>
              <button mat-button (click)="location='';$event.stopPropagation()" *ngIf="location" matSuffix
                (click)="clearLocation()" mat-icon-button >
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field>

            <mat-form-field class="col-3">
              <mat-label> First Name <span class="asterisk">*</span></mat-label>
              <input autocomplete="new-txtFirstName" matInput maxlength="40" formControlName="txtFirstName" type="text" />
              <mat-error *ngIf="
                            personalForm?.get('txtFirstName')?.touched &&
                            personalForm?.get('txtFirstName')?.errors?.required
                          ">
                First Name is a required field!
              </mat-error>
              <mat-error *ngIf="
                            personalForm?.get('txtFirstName')?.touched &&
                            personalForm?.get('txtFirstName')?.errors?.sAlphabets
                          ">
                Enter only Alphabets!
              </mat-error>
            </mat-form-field>

            <mat-form-field class="col-3">
              <mat-label>Middle Name</mat-label>
              <input autocomplete="new-txtMiddleName" matInput maxlength="40" formControlName="txtMiddleName" type="text" />
              <mat-error *ngIf="
                              personalForm?.get('txtMiddleName')?.touched &&
                              personalForm?.get('txtMiddleName')?.errors?.sAlphabets
                            ">
                Enter only Alphabets!
              </mat-error>
            </mat-form-field>

            <mat-form-field class="col-3">
              <mat-label> Last Name <span class="asterisk">*</span></mat-label>
              <input autocomplete="new-txtLastName" matInput maxlength="40" formControlName="txtLastName" type="text" />
              <mat-error *ngIf="
            personalForm?.get('txtLastName')?.touched &&
            personalForm?.get('txtLastName')?.errors?.required
          ">
                Last Name is a required field!
              </mat-error>
              <mat-error *ngIf="
                personalForm?.get('txtLastName')?.touched &&
                personalForm?.get('txtLastName')?.errors?.sAlphabets
              ">
                Enter only Alphabets!
              </mat-error>
            </mat-form-field>

            <mat-form-field class="col-3">
              <mat-label> Suffix</mat-label>
              <mat-select maxlength="40" [(value)]="suffixName" formControlName="drpSuffix">
                <mat-option *ngFor="let type of drpSuffixes" [value]="type.suffixShortCodeId">
                  {{ type.suffixName }}
                </mat-option>
              </mat-select>
              <button mat-button (click)="suffixName='';$event.stopPropagation()" *ngIf="suffixName"
                (click)="clearSuffix()" matSuffix mat-icon-button >
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field>

            <!-- <mat-form-field class="col-3">
              <mat-label> Suffix</mat-label>
              <input matInput maxlength="40" formControlName="txtSuffixName" type="text" />
              <mat-error *ngIf="
                personalForm?.get('txtSuffixName')?.touched &&
                personalForm?.get('txtSuffixName')?.errors?.sAlphabets
              ">
                Enter only Alphabets!
              </mat-error>
            </mat-form-field> -->

            <mat-form-field class="col-3">
              <mat-label> Birth Date <span class="asterisk">*</span></mat-label>
              <input autocomplete="new-txtBirthdate" [max]="BirthdateMaxDate" formControlName="txtBirthdate" matInput maxlength="40" type="text"
                placeholder="(MM/DD/YYYY)" [matDatepicker]="txtBirthdate" [min]="BirthdateMinDate" />
              <mat-datepicker-toggle matSuffix [for]="txtBirthdate"> </mat-datepicker-toggle>
              <mat-datepicker #txtBirthdate> </mat-datepicker>
              <mat-error
                *ngIf="
                  personalForm?.get('txtBirthdate')?.touched &&
                  personalForm?.get('txtBirthdate')?.errors && !(BirthdateMinDate<=personalForm?.get('txtBirthdate')?.value)">
                Enter Valid date
              </mat-error>
              <mat-error
                *ngIf=" personalForm?.get('txtBirthdate')?.touched && personalForm?.get('txtBirthdate')?.errors?.required">
                Birth Date is a required field!
              </mat-error>
              <mat-error
                *ngIf=" BirthdateMinDate<=personalForm?.get('txtBirthdate')?.value && !(personalForm?.get('txtBirthdate')?.touched && personalForm?.get('txtBirthdate')?.errors?.required)">
                Enter Past Dates Only!
              </mat-error>
            </mat-form-field>

            <mat-form-field class="col-3">
              <mat-label>SSN</mat-label>
              <!-- <input matInput formControlName="txtSSN" mask="0000" prefix="XXX-XX-" [showMaskTyped]="true"
              autocomplete="off" autocorrect="off" autocapitalize="off" spellcheck="false" /> -->
              <input autocomplete="new-txtSSN" matInput formControlName="txtSSN" type="text" mask="000-00-0000" [validation]="false"
                placeHolderCharacter="" />
              <mat-error *ngIf="personalForm?.get('txtSSN')?.touched && personalForm?.get('txtSSN')?.errors">
                Enter Minimum 4 Digits!
              </mat-error>
            </mat-form-field>

            <mat-form-field class="col-3">
              <mat-label> Email Address</mat-label>
              <input autocomplete="new-txtBillingEmailAddress" formControlName="txtBillingEmailAddress" matInput
                pattern="[a-zA-Z0-9._!#$%&'()*+,-./:;<=>?@[\]^_`{|}~]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$"
                [textMask]="{ mask: emailMask }" maxlength="40" type="text" />
              <mat-error *ngIf="
                personalForm?.get('txtBillingEmailAddress')?.touched &&
                personalForm?.get('txtBillingEmailAddress')?.errors?.pattern
              ">
                This is not a valid Email!!!
              </mat-error>
            </mat-form-field>

            <mat-form-field class="col-3">
              <mat-label> Home Phone No</mat-label>
              <input autocomplete="new-txtHomePhoneNo" formControlName="txtHomePhoneNo" matInput maxlength="40" prefix="{{phoneCode}}-"
                mask="(000)-000-0000" type="text" />
              <mat-error *ngIf="
                personalForm?.get('txtHomePhoneNo')?.touched &&
                personalForm?.get('txtHomePhoneNo')?.errors
              ">
                Enter Minimum 10 Digits!
              </mat-error>
            </mat-form-field>

            <mat-form-field class="col-3">
              <mat-label> Work Phone No</mat-label>
              <input autocomplete="new-txtWorkPhoneNo" formControlName="txtWorkPhoneNo" matInput maxlength="40" prefix="{{phoneCode}}-"
                mask="(000)-000-0000" type="text" />
              <mat-error *ngIf="
                personalForm?.get('txtWorkPhoneNo')?.touched &&
                personalForm?.get('txtWorkPhoneNo')?.errors
              ">
                Enter Minimum 10 Digits!
              </mat-error>
            </mat-form-field>

            <!-- <mat-form-field class="col-3">
              <mat-label>Gender</mat-label>
              <mat-select formControlName="drpGeneralGender" [(value)]="gender" maxlength="40">
                <mat-option *ngFor="let test of drpGenderLoop" [value]="test.genderShortCode">
                  {{ test.genderName }}
                </mat-option>
              </mat-select>
              <button mat-button (click)="gender='';$event.stopPropagation()" *ngIf="gender" matSuffix
                (click)="clearGender()" mat-icon-button >
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field> -->


            <mat-form-field class="col-3">
              <mat-label>Marital Status</mat-label>
              <mat-select formControlName="drpMarital" [(value)]="maritalStatus" maxlength="40">
                <mat-option *ngFor=" let martail of drpmartailStatusLoop" [value]="martail.maritalStatusTypeId">
                  {{martail.maritalStatusType}}
                </mat-option>
              </mat-select>
              <button mat-button (click)="maritalStatus='';$event.stopPropagation()" *ngIf="maritalStatus"
                (click)="clearMaritalStatus()" matSuffix mat-icon-button >
                <mat-icon>close</mat-icon>
              </button>

            </mat-form-field>
            <mat-form-field class="col-3">
              <mat-label>Ethnicity</mat-label>
              <input autocomplete="new-txtEthinicity" matInput formControlName="txtEthinicity" type="text" placeHolderCharacter="" />
            </mat-form-field>

            <mat-form-field class="col-3">
              <mat-label> Race</mat-label>
              <input autocomplete="new-txtRace" matInput formControlName="txtRace" type="text" placeHolderCharacter="" />
            </mat-form-field>

            <mat-form-field class="col-3">
              <mat-label>Account Number</mat-label>
              <input autocomplete="new-txtAccountNumber" matInput maxlength="200" [validation]="false" formControlName="txtAccountNumber"
                mask="000-000-0000-00000" placeHolderCharacter="" type="text">
              <mat-error *ngIf="
                personalForm?.get('txtAccountNumber')?.touched &&
                personalForm?.get('txtAccountNumber')?.errors
              ">
                Enter Minimum 9 Digits!
              </mat-error>
            </mat-form-field>


            <mat-form-field class="col-3">
              <mat-label> Customer Type</mat-label>
              <mat-select maxlength="40" [(value)]="customerType" formControlName="drpCustomerType">
                <mat-option *ngFor="let type of drpCustomerTypes" [value]="type.customerTypeShortCodeId">
                  {{ type.customerType }}
                </mat-option>
              </mat-select>
              <button mat-button (click)="customerType='';$event.stopPropagation()" *ngIf="customerType"
                (click)="clearCustomer()" matSuffix mat-icon-button >
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field>
            <mat-form-field class="col-3">
              <mat-label> Prior System Key </mat-label>
              <input autocomplete="new-txtPriorSystemKeyFacility" formControlName="txtPriorSystemKeyFacility" matInput maxlength="40" type="text" />
              <mat-error *ngIf="
                personalForm?.get('txtPriorSystemKeyFacility')?.touched &&
                personalForm?.get('txtPriorSystemKeyFacility')?.errors?.sAlphabets
              ">
                Enter only Alphabets!
              </mat-error>
            </mat-form-field>
            <mat-form-field class="col-3">
              <mat-label>Facility</mat-label>
              <mat-select maxlength="40" [(value)]="facility" formControlName="drpFacility">
                <mat-option *ngFor="let facility of drpFacility" [value]="facility.facilityName">
                  {{ facility.facilityName }}
                </mat-option>
              </mat-select>
              <button mat-button (click)="facility='';$event.stopPropagation()" *ngIf="facility" matSuffix
                (click)="clearFacility()" mat-icon-button >
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field>
            <mat-form-field class="col-3">
              <mat-label>Alternate ID Type</mat-label>
              <input autocomplete="new-txtAlternateIDType" formControlName="txtAlternateIDType" matInput maxlength="40" type="text" pattern="[0-9]*" />
              <mat-error *ngIf="
                  personalForm?.get('txtAlternateIDType')?.touched &&
                  personalForm?.get('txtAlternateIDType')?.errors?.pattern
                ">
                Enter only Numbers!
              </mat-error>
            </mat-form-field>

            <mat-checkbox class="col-3 pt-3" formControlName="chkPatientIntrest">Patient Interest</mat-checkbox>

            <mat-form-field [hidden]='true' class="col-3">
              <mat-label> Color Coding</mat-label>
              <mat-select maxlength="40" formControlName="drpColor">
                <mat-option *ngFor="let color of drpColorCodings" [value]="color.colorCodingShortCodeId">

                  <ng-container [ngSwitch]="color.colorCodingName">
                    <p class="text-primary" *ngSwitchCase="'None'">
                      <b> {{color.colorCodingName}}</b>
                    </p>
                    <p class="text-secondary" *ngSwitchCase="'Audit Denial'">
                      <b> {{color.colorCodingName}}</b>
                    </p>
                    <p class="text-success" *ngSwitchCase="'Authorizaton Needed for service'">
                      <b> {{color.colorCodingName}}</b>
                    </p>
                    <p class="text-danger" *ngSwitchCase="'Cash Patient'">
                      <b> {{color.colorCodingName}}</b>
                    </p>

                    <p class="text-info" *ngSwitchCase="'Compliance Doenload Required'">
                      <b> {{color.colorCodingName}}</b>
                    </p>
                    <p class="text-info" *ngSwitchCase="'CP AP / BIP AP PICKED UP'">
                      <b> {{color.colorCodingName}}</b>
                    </p>
                    <p class="text-dark" *ngSwitchCase="'Delinquent Account'">
                      <b> {{color.colorCodingName}}</b>
                    </p>

                    <p class="text-danger" *ngSwitchCase="'INS POLICY CANCELLED'">
                      <b> {{color.colorCodingName}}</b>
                    </p>
                    <p class="text-success" *ngSwitchCase="'Mcr Repl Plan'">
                      <b> {{color.colorCodingName}}</b>
                    </p>
                    <p class="text-warning" *ngSwitchCase="'Bankruptcy'">
                      <b> {{color.colorCodingName}}</b>
                    </p>

                  </ng-container>
                </mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field class="col-3">
              <mat-label>Category</mat-label>
              <mat-select maxlength="40" [(value)]="category" formControlName="txtCatogery">
                <mat-option *ngFor="let categories of drpCategories" [value]="categories.categoryShortCodeId">
                  {{ categories.categoryName }}
                </mat-option>
              </mat-select>
              <button mat-button (click)="category='';$event.stopPropagation()" *ngIf="category" matSuffix
                (click)="clearCategory()" mat-icon-button >
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field>

            <mat-form-field class="col-3">
              <mat-label>Provider <span class="asterisk">*</span></mat-label>
              <mat-select maxlength="40" [(value)]="provider" formControlName="txtProvider">
                <mat-option *ngFor="let providers of drpProviders" [value]="providers.shortCodeId">
                  {{ providers.name }}
                </mat-option>
              </mat-select>
              <button mat-button (click)="provider='';$event.stopPropagation()" *ngIf="provider" matSuffix
                (click)="clearProvider()" mat-icon-button >
                <mat-icon>close</mat-icon>
              </button>
              <mat-error
                *ngIf=" personalForm?.get('txtProvider')?.touched && personalForm?.get('txtProvider')?.errors?.required">
                Provider is a required field!
              </mat-error>
            </mat-form-field>

            <!-- <mat-form-field [hidden]='true' class="col-3">
              <mat-label>Patient Balance</mat-label>
              <input formControlName="txtPatientBalance" matInput minlength="1" maxlength="15" type="text" />
              <mat-error *ngIf="
              personalForm?.get('txtPatientBalance')?.touched &&
              personalForm?.get('txtPatientBalance')?.errors?.number
              ">
                Enter only Numbers!
              </mat-error>

            </mat-form-field>
            <mat-form-field [hidden]='true' class="col-3">
              <mat-label>Insurance Balance</mat-label>
              <input formControlName="txtInsuranceBalance" matInput minlength="1" maxlength="15" type="text" />
              <mat-error *ngIf="
                          personalForm?.get('txtInsuranceBalance')?.touched &&
                          personalForm?.get('txtInsuranceBalance')?.errors?.number
                        ">
                Enter only Numbers!
              </mat-error>

            </mat-form-field>
            <mat-form-field [hidden]='true' class="col-3">
              <mat-label>Total Balance</mat-label>
              <input formControlName="txtTotalBalance" matInput minlength="1" maxlength="15" type="text" />
              <mat-error *ngIf="
                          personalForm?.get('txtTotalBalance')?.touched &&
                          personalForm?.get('txtTotalBalance')?.errors?.number
                        ">
                Enter only Numbers!
              </mat-error>

            </mat-form-field> -->

            <mat-checkbox class="col-3 recieveBill pt-3" formControlName="chkReceiveBill">Receive Bill</mat-checkbox>
            <mat-form-field class="col-3">
              <mat-label>Statement To</mat-label>
              <input autocomplete="new-txtStatementTo" formControlName="txtStatementTo" matInput maxlength="40" type="text" />
              <mat-error *ngIf="
                          personalForm?.get('txtStatementTo')?.touched &&
                          personalForm?.get('txtStatementTo')?.errors?.sAlphabets
                        ">
                Enter only Alphabets!
              </mat-error>
            </mat-form-field>
            <mat-checkbox class="col-3 pt-3" formControlName="chkCollection">Collection</mat-checkbox>
            <mat-checkbox class="col-3 doNotCall pt-3" formControlName="chkDoNotCall">Do Not Call</mat-checkbox>
            <mat-checkbox class="col-3 holdClaims pt-3" formControlName="chkHoldClaims">Hold Claims</mat-checkbox>
            <mat-form-field class="col-3 ">
              <mat-label>Hold Notes</mat-label>
              <input autocomplete="new-txtHoldNotes" formControlName="txtHoldNotes" matInput maxlength="40" type="text" />
              <mat-error *ngIf="
                          personalForm?.get('txtHoldNotes')?.touched &&
                          personalForm?.get('txtHoldNotes')?.errors?.sAlphabets
                        ">
                Enter only Alphabets!
              </mat-error>
            </mat-form-field>

            <mat-form-field class="col-3">
              <mat-label>Hold Reason</mat-label>
              <mat-select maxlength="40" [(value)]="holdReason" formControlName="drpHoldReason">
                <mat-option *ngFor="let holdReasons of drpHoldReasons" [value]="holdReasons.holdReasonShortCodeId">
                  {{ holdReasons.holdReasonName }}
                </mat-option>
              </mat-select>
              <button mat-button (click)="holdReason='';$event.stopPropagation()" *ngIf="holdReason" matSuffix
                (click)="clearHoldReason()" mat-icon-button >
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field>
            <mat-checkbox class="col-3 pt-3" formControlName="chkAutoDunning">Auto Dunning</mat-checkbox>
            <mat-form-field class="col-3">
              <mat-label>Dunning Message</mat-label>
              <mat-select maxlength="40" [(value)]="dunningMessage" formControlName="drpDunningMessage">
                <mat-option *ngFor="let dunning of drpDunnigMessages" [value]="dunning.dunningMessageShortCodeId">
                  {{ dunning.dunningMessage }}
                </mat-option>
              </mat-select>
              <button mat-button (click)="dunningMessage='';$event.stopPropagation()" *ngIf="dunningMessage"
                (click)="clearDunningMessage()" matSuffix mat-icon-button >
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field>
            <mat-form-field class="col-3">
              <mat-label>Alerts</mat-label>
              <textarea formControlName="txtAlerts" maxlength="400" rows="3" matInput></textarea>
              <!-- <mat-icon matSuffix (click)="openDialog()">add</mat-icon> -->
              <mat-error *ngIf="
                          personalForm?.get('txtAlerts')?.touched &&
                          personalForm?.get('txtAlerts')?.errors?.sAlphabets
                        ">
                Enter only Alphabets!
              </mat-error>
            </mat-form-field>



          </div>
        </mat-card-content>
      </div>
    </div>
    <br />
    <h4 class="customThemeClass"><b>Extended Info</b></h4>
    <div class="row">
      <div class="col-lg-6">
        <mat-card-content>
          <div class="row">

            <mat-form-field class="col-6">
              <mat-label> Discount Percent</mat-label>
              <input autocomplete="new-txtDiscountPercent" formControlName="txtDiscountPercent" matInput mask="percent" suffix="%" type="text" />
              <mat-error
                *ngIf="personalForm?.get('txtDiscountPercent')?.touched &&personalForm?.get('txtDiscountPercent')?.errors">
                Enter Minimum 2 Digits!
              </mat-error>
            </mat-form-field>


            <mat-form-field class="col-6">
              <mat-label>Tax Zone</mat-label>
              <mat-select maxlength="40" [(value)]="taxZone" formControlName="txtTaxZone">
                <mat-option *ngFor="let tax of drpTaxZone" [value]="tax.taxZoneType">
                  {{ tax.taxZoneType }}
                </mat-option>
              </mat-select>
              <button mat-button (click)="taxZone='';$event.stopPropagation()" *ngIf="taxZone" matSuffix
                (click)="clearTaxZone()" mat-icon-button >
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field>


            <mat-form-field class="col-6">
              <mat-label>PT Security Group </mat-label>
              <mat-select maxlength="40" [(value)]="ptSecurityGroup" formControlName="drpPTSecurityGroup">
                <mat-option *ngFor="let pt of drpPTSecurityGroups" [value]="pt.ptSecurityGroupShortCodeId">
                  {{ pt.ptSecurityGroup }}
                </mat-option>
              </mat-select>
              <button mat-button (click)="ptSecurityGroup='';$event.stopPropagation()" *ngIf="ptSecurityGroup"
                (click)="clearPtSecurityGroup()" matSuffix mat-icon-button >
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field>
            <mat-form-field class="col-6">
              <mat-label> User1</mat-label>
              <input autocomplete="new-txtUser1" matInput maxlength="40" formControlName="txtUser1" pattern="[0-9]*" type="text" />
              <mat-error *ngIf="
                personalForm?.get('txtUser1')?.touched &&
                personalForm?.get('txtUser1')?.errors?.pattern
              ">
                Enter only Numbers!
              </mat-error>
            </mat-form-field>
            <mat-form-field class="col-6">
              <mat-label>User4</mat-label>
              <input autocomplete="new-txtUser4" matInput maxlength="40" formControlName="txtUser4" pattern="[0-9]*" type="text" />
              <mat-error *ngIf="
                personalForm?.get('txtUser4')?.touched &&
                personalForm?.get('txtUser4')?.errors?.pattern
              ">
                Enter only Numbers!
              </mat-error>
            </mat-form-field>

            <div class="col-6">
              <br />
              <mat-checkbox formControlName="chkHoldAccount">Hold Account </mat-checkbox>
            </div>

          </div>
        </mat-card-content>
      </div>
      <div class="col-lg-6">
        <mat-card-content>
          <div class="row">
            <mat-form-field class="col-6">
              <mat-label> Branch Office</mat-label>
              <mat-select maxlength="40" [(value)]="branchOffice" formControlName="drpBranchOffice">
                <mat-option *ngFor="let branch of drpBranchOffices" [value]="branch.branchOffice">
                  {{ branch.branchOffice }}
                </mat-option>
              </mat-select>
              <button mat-button (click)="branchOffice='';$event.stopPropagation()" *ngIf="branchOffice"
                (click)="clearBranchOffice()" matSuffix mat-icon-button >
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field>
            <mat-form-field class="col-6">
              <mat-label> Account Group <span class="asterisk">*</span> </mat-label>
              <mat-select maxlength="40" [(value)]="accountGroup" formControlName="drpAccountGroup">
                <mat-option *ngFor="let account of drpAccountGroups" [value]="account.accountGroupShortCodeId">
                  {{ account.accountGroup }}
                </mat-option>


              </mat-select>
              <mat-error *ngIf="
              personalForm?.get('drpAccountGroup')?.touched &&
              personalForm?.get('drpAccountGroup')?.errors?.required
            ">
                Account Group is a required field!
              </mat-error>


              <button mat-button (click)="accountGroup='';$event.stopPropagation()" *ngIf="accountGroup"
                (click)="clearAccountGroup()" matSuffix mat-icon-button >
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field>
            <mat-form-field class="col-6">
              <mat-label>User2 </mat-label>
              <input autocomplete="new-txtUser2" formControlName="txtUser2" matInput maxlength="40" pattern="[0-9]*" type="text" />
              <mat-error *ngIf="
                personalForm?.get('txtUser2')?.touched &&
                personalForm?.get('txtUser2')?.errors?.pattern
              ">
                Enter only Numbers!
              </mat-error>
            </mat-form-field>
            <mat-form-field class="col-6">
              <mat-label>User3</mat-label>
              <input autocomplete="new-txtUser3" formControlName="txtUser3" matInput maxlength="40" pattern="[0-9]*" type="text" />
              <mat-error *ngIf="
                personalForm?.get('txtUser3')?.touched &&
                personalForm?.get('txtUser3')?.errors?.pattern
              ">
                Enter only Numbers!
              </mat-error>
            </mat-form-field>
            <div class="col-6">
              <br />
              <mat-checkbox class="mat-checkbox mat-accent ng-valid ng-dirty ng-touched mat-checkbox-checked"
                formControlName="chkHipaaSignature">HIPAA Signature on file</mat-checkbox>
            </div>
            <div class="col-6">
              <br />
              <mat-checkbox formControlName="chkHoldBillingStatement">Hold Billing Statements</mat-checkbox>
            </div>

          </div>
        </mat-card-content>
      </div>
    </div>
    <br />
    <h4 class="customThemeClass"><b>Audit Trail / Place of Service</b></h4>
    <div class="row">
      <div class="col-lg-6">
        <mat-card-content>
          <div class="row">
            <mat-form-field class="col-6">
              <mat-label> Created By</mat-label>
              <input autocomplete="new-txtAuditCreatedBy" formControlName="txtAuditCreatedBy" matInput maxlength="40" type="text" />
              <mat-error *ngIf="
                personalForm?.get('txtAuditCreatedBy')?.touched &&
                personalForm?.get('txtAuditCreatedBy')?.errors?.sAlphabets
              ">
                Enter only Alphabets!
              </mat-error>
            </mat-form-field>
            <mat-form-field class="col-6">
              <mat-label> Date Created </mat-label>
              <input autocomplete="new-txtAuditDateCreated" matInput maxlength="40" formControlName="txtAuditDateCreated" placeholder="(MM/DD/YYYY)"
                [matDatepicker]="txtAuditDateCreated" />

              <mat-datepicker-toggle matSuffix [for]="txtAuditDateCreated"> </mat-datepicker-toggle>
              <mat-datepicker #txtAuditDateCreated> </mat-datepicker>
              <mat-error *ngIf="
                personalForm?.get('txtAuditDateCreated')?.touched &&
                personalForm?.get('txtAuditDateCreated')?.errors
              ">
                Enter Valid Date!
              </mat-error>
            </mat-form-field>
            <mat-form-field class="col-12">
              <mat-label>POS</mat-label>
              <mat-select maxlength="40" [(value)]="pos" formControlName="drpPlaceOfService">
                <mat-option *ngFor="let pos of drpPlaceOfServices" [value]="pos.placeOfServiceTypeShortCodeId">
                  {{ pos.placeOfServiceType }}
                </mat-option>
              </mat-select>
              <button mat-button (click)="pos='';$event.stopPropagation()" *ngIf="pos" matSuffix mat-icon-button
                (click)="clearPos()" >
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field>




          </div>
        </mat-card-content>
      </div>

      <div class="col-lg-6">
        <mat-card-content>
          <div class="row">

            <mat-form-field class="col-6">
              <mat-label> Date of Admission</mat-label>
              <input autocomplete="new-txtPlaceOfServiceDateOfAdmission" formControlName="txtPlaceOfServiceDateOfAdmission" matInput maxlength="40"
                placeholder="(MM/DD/YYYY)" [matDatepicker]="txtPlaceOfServiceDateOfAdmission" />
              <mat-datepicker-toggle matSuffix [for]="txtPlaceOfServiceDateOfAdmission">
              </mat-datepicker-toggle>
              <mat-datepicker #txtPlaceOfServiceDateOfAdmission> </mat-datepicker>
              <mat-error *ngIf="
                personalForm?.get('txtPlaceOfServiceDateOfAdmission')?.touched &&
                personalForm?.get('txtPlaceOfServiceDateOfAdmission')?.errors
              ">
                Enter Valid Date!
              </mat-error>
              <mat-error
                *ngIf=" BirthdateMaxDate>=personalForm?.get('txtPlaceOfServiceDateOfAdmission')?.value && !(personalForm?.get('txtPlaceOfServiceDateOfAdmission')?.touched && personalForm?.get('txtPlaceOfServiceDateOfAdmission')?.errors?.required)">
                Enter Future Dates Only!
              </mat-error>
            </mat-form-field>
            <mat-form-field class="col-6">
              <mat-label> Date of Discharge</mat-label>

              <input autocomplete="new-txtPlaceOfServiceDateOfDischarge" formControlName="txtPlaceOfServiceDateOfDischarge" matInput maxlength="40"
                placeholder="(MM/DD/YYYY)" [min]="personalForm?.controls?.txtPlaceOfServiceDateOfAdmission?.value"
                [matDatepicker]="txtPlaceOfServiceDateOfDischarge" />
              <mat-datepicker-toggle matSuffix [for]="txtPlaceOfServiceDateOfDischarge">
              </mat-datepicker-toggle>
              <mat-datepicker #txtPlaceOfServiceDateOfDischarge> </mat-datepicker>
              <mat-error *ngIf="
                personalForm?.get('txtPlaceOfServiceDateOfDischarge')?.touched &&
                personalForm?.get('txtPlaceOfServiceDateOfDischarge')?.errors
              ">
                Enter Valid Date!
              </mat-error>
              <mat-error
                *ngIf=" personalForm?.controls?.txtPlaceOfServiceDateOfAdmission?.value>=personalForm?.get('txtPlaceOfServiceDateOfDischarge')?.value && !(personalForm?.get('txtPlaceOfServiceDateOfDischarge')?.touched && personalForm?.get('txtPlaceOfServiceDateOfDischarge')?.errors?.required)">
                Enter Future Dates Only!
              </mat-error>
            </mat-form-field>

            <div class="col-6">
              <br />
              <mat-checkbox formControlName="chkStat">Stat</mat-checkbox>
            </div>
            <div class="col-6">
              <br />
              <mat-checkbox formControlName="chkCashPayment">Cash Payment</mat-checkbox>
            </div>
          </div>

        </mat-card-content>
      </div>
    </div>

    <br />
    <h4 class="customThemeClass"><b>Billing Address</b></h4>
    <div class="row">
      <div class="col-lg-12">
        <mat-card-content>
          <div class="row">

            <mat-form-field class="col-3">
              <mat-label> Address</mat-label>
              <textarea autocomplete="new-txtBillingAddress" formControlName="txtBillingAddress" rows="3" matInput maxlength="400"></textarea>
            </mat-form-field>


            <!-- <ng-select class="col-3" (change)="onChangeBillState($event)" formControlName="drpBillingCountry"
            placeholder="Country" maxlength="40">
            <ng-option *ngFor="let country of drpBillCountry" [value]="country.countryName">
              {{ country.countryName }}</ng-option>
          </ng-select> -->

            <!-- (selectionChange)='onChangeBillState($event.value)' -->
            <mat-form-field class="col-3">
              <mat-label>Country</mat-label>
              <mat-select formControlName="drpBillingCountry" (selectionChange)='onChangeBillState($event.value)'
                [(value)]="BillingCountry" #singleSelect>
                <!-- <mat-option>
                  <ngx-mat-select-search placeholderLabel="Search" noEntriesFoundLabel="No Matches found"
                    formControlName="txtBillingCountryFilter"></ngx-mat-select-search>
                </mat-option> -->
                <mat-option *ngFor="let country of filteredBillingCountries | async" [value]="country.countryShortName">
                  {{ country.countryName }}
                </mat-option>
              </mat-select>
              <button mat-button (click)="BillingCountry=undefined;$event.stopPropagation()" *ngIf="BillingCountry"
                (click)="clearBillingCountry()" matSuffix mat-icon-button >
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field>

            <mat-form-field class="col-3">
              <mat-label>State</mat-label>
              <mat-select formControlName="txtBillingState" (selectionChange)='onChangeBillCity($event.value,"")'
                [(value)]="BillingState" #singleSelect>
                <mat-option>
                  <ngx-mat-select-search placeholderLabel="Search" noEntriesFoundLabel="No Matches found"
                    formControlName="txtBillingStateFilter"></ngx-mat-select-search>
                </mat-option>
                <mat-option *ngFor="let state of filteredBillingStates | async" [value]="state.stateShortName">
                  {{ state.stateName }}
                </mat-option>
              </mat-select>
              <button mat-button (click)="BillingState=undefined;$event.stopPropagation()" *ngIf="BillingState"
                (click)="clearBillingState()" matSuffix mat-icon-button >
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field>

            <mat-form-field class="col-3">
              <mat-label> County</mat-label>
              <input autocomplete="new-drpBillingCounty" formControlName="drpBillingCounty" matInput maxlength="40" type="text" />
              <mat-error *ngIf="
              personalForm?.get('drpBillingCounty')?.touched &&
              personalForm?.get('drpBillingCounty')?.errors?.sAlphabets
            ">
                Enter only Alphabets!
              </mat-error>
              <!-- <mat-select maxlength="40" [(value)]="county" (click)="clearCounty()" formControlName="drpBillingCounty">
              <mat-option *ngFor="let county of drpCounty" [value]="county.countyTypeShortCodeId">
                {{ county.countyType }}
              </mat-option>
            </mat-select>
            <button mat-button (click)="county='';$event.stopPropagation()" *ngIf="county" matSuffix
              mat-icon-button >
              <mat-icon>close</mat-icon>
            </button> -->
            </mat-form-field>

            <mat-form-field class="col-3">
              <mat-label>City</mat-label>
              <mat-select formControlName="txtBillingCity" [(value)]="BillingCity" #singleSelect>
                <mat-option>
                  <ngx-mat-select-search placeholderLabel="Search" noEntriesFoundLabel="No Matches found"
                    formControlName="txtBillingCityFilter"></ngx-mat-select-search>
                </mat-option>
                <mat-option *ngFor="let city of filteredBillingCities | async" [value]="city.cityName">
                  {{ city.cityName }}
                </mat-option>
              </mat-select>
              <button mat-button (click)="BillingCity='';$event.stopPropagation()" *ngIf="BillingCity" matSuffix
                (click)="clearBillingCity()" mat-icon-button >
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field>

            <mat-form-field class="col-3">
              <mat-label> Zip Code </mat-label>
              <input autocomplete="new-txtBillingPostalCode" matInput maxlength="10" formControlName="txtBillingPostalCode" pattern="[0-9]*" type="text" />
              <mat-error *ngIf="
            personalForm?.get('txtBillingPostalCode')?.touched &&
            personalForm?.get('txtBillingPostalCode')?.errors?.pattern
          ">
                Enter only Numbers!
              </mat-error>
            </mat-form-field>

            <mat-form-field class="col-3">
              <mat-label> Phone</mat-label>
              <input autocomplete="new-txtBillingPhone" formControlName="txtBillingPhone" matInput maxlength="40" prefix="{{phoneCode}}-"
                mask="(000)-000-0000" type="text" />
              <mat-error *ngIf="
              personalForm?.get('txtBillingPhone')?.touched &&
              personalForm?.get('txtBillingPhone')?.errors
            ">
                Enter Minimum 10 Digits!
              </mat-error>
            </mat-form-field>

            <!-- <div class="col-3">
              <ngx-intl-tel-input [cssClass]="''" [enableAutoCountrySelect]="true" [enablePlaceholder]="true"
                [searchCountryFlag]="true" [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]"
                [selectFirstCountry]="false" [maxLength]="15" [phoneValidation]="true" [separateDialCode]="true"
                name="phone" formControlName="txtBillingPhone">
              </ngx-intl-tel-input>
            </div> -->


            <mat-form-field class="col-3">
              <mat-label> Fax</mat-label>
              <input autocomplete="new-txtBillingFax" formControlName="txtBillingFax" matInput maxlength="40" prefix="{{phoneCode}}-"
                mask="(000)-000-0000" type="text" />
              <mat-error *ngIf="
                  personalForm?.get('txtBillingFax')?.touched &&
                  personalForm?.get('txtBillingFax')?.errors
                ">
                Enter Minimum 10 Digits!
              </mat-error>
            </mat-form-field>
            <mat-form-field class="col-3">
              <mat-label> Zone</mat-label>
              <mat-select maxlength="40" [(value)]="zone" formControlName="drpBillingZone">
                <mat-option *ngFor="let zone of drpZones" [value]="zone.zoneTypeShortCodeId">
                  {{ zone.zoneType }}
                </mat-option>
              </mat-select>
              <button mat-button (click)="zone='';$event.stopPropagation()" *ngIf="zone" matSuffix (click)="clearZone()"
                mat-icon-button >
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field>
            <mat-form-field class="col-3">
              <mat-label> Mobile</mat-label>
              <input autocomplete="new-txtBillingMobile" formControlName="txtBillingMobile" prefix="{{phoneCode}}-" mask="(000)-000-0000" matInput
                maxlength="40" type="text" />
              <mat-error *ngIf="
              personalForm?.get('txtBillingMobile')?.touched &&
              personalForm?.get('txtBillingMobile')?.errors
            ">
                Enter Minimum 10 Digits!
              </mat-error>
            </mat-form-field>


            <br />
            <!-- <h4 class="customThemeClass"><b>Billing Address</b></h4>
    <div class="row">
      <div class="col-lg-12">
        <mat-card-content>
          <div class="row">
            <mat-form-field class="col-3">
              <mat-label> Address</mat-label>
              <textarea formControlName="txtBillingAddress" rows="3" matInput maxlength="400"></textarea>
            </mat-form-field>
            <ng-select class="col-3" (change)="onChangeBillState($event)" formControlName="drpBillingCountry"
              placeholder="Country" maxlength="40">
              <ng-option *ngFor="let country of drpBillCountry" [value]="country.countryName">
                {{ country.countryName }}</ng-option>
            </ng-select>
            <ng-select class="col-3" (change)="onChangeBillCity($event)" formControlName="txtBillingState"
              placeholder="State" maxlength="40">
              <ng-option *ngFor="let state of drpBillStates" [value]="state.stateName">
                {{ state.stateName }}</ng-option>
            </ng-select>
            <mat-form-field class="col-3">
              <mat-label> County</mat-label>
              <mat-select maxlength="40" [(value)]="county" (click)="clearCounty()" formControlName="drpBillingCounty">
                <mat-option *ngFor="let county of drpCounty" [value]="county.countyTypeShortCodeId">
                  {{ county.countyType }}
                </mat-option>
              </mat-select>
              <button mat-button (click)="county='';$event.stopPropagation()" *ngIf="county" matSuffix
                mat-icon-button >
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field>
            <ng-select class="col-3" formControlName="txtBillingCity" (change)="onChangeBillCityName($event)"
              placeholder="City" maxlength="40">
              <ng-option *ngFor="let city of drpBillCities" [value]="city.cityName">
                {{ city.cityName }}</ng-option>
            </ng-select>
            <mat-form-field class="col-3">
              <mat-label> Zip Code </mat-label>
              <input matInput maxlength="10" formControlName="txtBillingPostalCode" type="text" />
              <mat-error *ngIf="
              personalForm?.get('txtBillingPostalCode')?.touched &&
              personalForm?.get('txtBillingPostalCode')?.errors?.number
            ">
                Enter only Numbers!
              </mat-error>
            </mat-form-field>
            <mat-form-field class="col-3">
              <mat-label> Phone</mat-label>
              <input formControlName="txtBillingPhone" matInput maxlength="40" prefix="+1-" mask="(000)-000-0000"
                type="text" />
              <mat-error *ngIf="
              personalForm?.get('txtAlternateIDType')?.touched &&
              personalForm?.get('txtAlternateIDType')?.errors?.number
            ">
                Enter only Numbers!
              </mat-error>
            </mat-form-field>

            <mat-form-field class="col-3">
              <mat-label> Fax</mat-label>
              <input formControlName="txtBillingFax" matInput maxlength="40" prefix="+1-" mask="(000)-000-0000"
                type="text" />
              <mat-error *ngIf="
                personalForm?.get('txtBillingFax')?.touched &&
                personalForm?.get('txtBillingFax')?.errors
              ">
                Enter Minimum 10 Digits!
              </mat-error>
            </mat-form-field>
            <mat-form-field class="col-3">
              <mat-label> Zone</mat-label>
              <mat-select maxlength="40" [(value)]="zone" (click)="clearZone()" formControlName="drpBillingZone">
                <mat-option *ngFor="let zone of drpZones" [value]="zone.zoneTypeShortCodeId">
                  {{ zone.zoneType }}
                </mat-option>
              </mat-select>
              <button mat-button (click)="zone='';$event.stopPropagation()" *ngIf="zone" matSuffix
                mat-icon-button >
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field>
            <mat-form-field class="col-3">
              <mat-label> Mobile</mat-label>
              <input formControlName="txtBillingMobile" prefix="+1-" mask="(000)-000-0000" matInput maxlength="40"
                type="text" />
              <mat-error *ngIf="
                personalForm?.get('txtBillingMobile')?.touched &&
                personalForm?.get('txtBillingMobile')?.errors
              ">
                Enter Minimum 10 Digits!
              </mat-error>
            </mat-form-field>
             <mat-form-field class="col-3">
            <mat-label> Email Address</mat-label>
            <input formControlName="txtBillingEmailAddress" matInput
              pattern="[a-z0-9._!#$%&'()*+,-./:;<=>?@[\]^_`{|}~]+@[a-z0-9.-]+\.[a-z]{2,4}$"
              [textMask]="{ mask: emailMask }" maxlength="40" type="text" />
            <mat-error *ngIf="
                personalForm?.get('txtBillingEmailAddress')?.touched &&
                personalForm?.get('txtBillingEmailAddress')?.errors?.pattern
              ">
              This is not a valid Email!!!
            </mat-error>
          </mat-form-field>
          </div>
        </mat-card-content>
      </div>
    </div> -->

          </div>
          <br />
          <div class="row">
            <div class="col-6">
              <h4 class="customThemeClass"><b>Delivery Address</b></h4>
            </div>
            <div class="col-6 text-lg-right">
              <button (click)="copyPatient()" mat-button class="buttonColor">
                <!-- <i class="bx bx-copy-alt font-size-16 align-middle mr-1"></i> -->
                <i class="fa fa-clone mr-1" ></i>
                Same as Billing Address
              </button>
            </div>
          </div>

          <div class="row">
            <div class="col-lg-12">
              <mat-card-content>
                <div class="row">
                  <mat-form-field class="col-3">
                    <mat-label> Address</mat-label>
                    <textarea autocomplete="new-txtDeliveryAddress" formControlName="txtDeliveryAddress" rows="3" matInput maxlength="400"></textarea>
                  </mat-form-field>
                  <!-- <ng-select class="col-3" (change)="onChangedeliState($event)" formControlName="drpDeliveryCountry"
    placeholder="Country" maxlength="40">
    <ng-option *ngFor="let country of drpDeliCountry" [value]="country.countryName">
      {{ country.countryName }}</ng-option>
  </ng-select> -->
                  <mat-form-field class="col-3">
                    <mat-label>Country</mat-label>
                    <mat-select formControlName="drpDeliveryCountry" (selectionChange)='onChangedeliState($event.value)'
                      [(value)]="DeliveryCountry" #singleSelect>
                      <!-- <mat-option>
                        <ngx-mat-select-search placeholderLabel="Search" noEntriesFoundLabel="No Matches found"
                          formControlName="txtDeliveryCountryFilter"></ngx-mat-select-search>
                      </mat-option> -->
                      <mat-option *ngFor="let country of filteredDeliveryCountries | async"
                        [value]="country.countryShortName">
                        {{ country.countryName }}
                      </mat-option>
                    </mat-select>
                    <button mat-button (click)="DeliveryCountry=undefined;$event.stopPropagation()"
                      (click)="clearDeliveryCountry()" *ngIf="DeliveryCountry" matSuffix mat-icon-button
                      >
                      <mat-icon>close</mat-icon>
                    </button>
                  </mat-form-field>
                  <!-- <ng-select class="col-3" (change)="onChangeDeliCity($event)" formControlName="txtDeliveryState"
    placeholder="State" maxlength="40">
    <ng-option *ngFor="let state of drpDeliStates" [value]="state.stateName">
      {{ state.stateName }}</ng-option>
  </ng-select> -->
                  <mat-form-field class="col-3">
                    <mat-label>State</mat-label>
                    <mat-select formControlName="txtDeliveryState" (selectionChange)='onChangeDeliCity($event.value,"")'
                      [(value)]="DeliveryState" #singleSelect>
                      <mat-option>
                        <ngx-mat-select-search placeholderLabel="Search" noEntriesFoundLabel="No Matches found"
                          formControlName="txtDeliveryStateFilter"></ngx-mat-select-search>
                      </mat-option>
                      <mat-option *ngFor="let state of filteredDeliveryStates | async" [value]="state.stateShortName">
                        {{ state.stateName }}
                      </mat-option>
                    </mat-select>
                    <button mat-button (click)="DeliveryState=undefined;$event.stopPropagation()" *ngIf="DeliveryState"
                      (click)="clearDeliveryState()" matSuffix mat-icon-button >
                      <mat-icon>close</mat-icon>
                    </button>
                  </mat-form-field>
                  <mat-form-field class="col-3">
                    <mat-label> County</mat-label>
                    <input autocomplete="new-drpDeliveryCounty" formControlName="drpDeliveryCounty" matInput maxlength="40" type="text" />
                    <mat-error *ngIf="
            personalForm?.get('drpDeliveryCounty')?.touched &&
            personalForm?.get('drpDeliveryCounty')?.errors?.sAlphabets
          ">
                      Enter only Alphabets!
                    </mat-error>
                    <!-- <mat-select maxlength="40" [(value)]="drpcounty" (click)="clearDrpCounty()"
              formControlName="drpDeliveryCounty" (valueChange)="onChangeBillCity">
              <mat-option *ngFor="let county of drpCounty" [value]="county.countyTypeShortCodeId">
                {{ county.countyType }}
              </mat-option>
            </mat-select>
            <button mat-button (click)="drpcounty='';$event.stopPropagation()" *ngIf="drpcounty"
              matSuffix mat-icon-button >
              <mat-icon>close</mat-icon>
            </button> -->
                  </mat-form-field>
                  <!-- <ng-select class="col-3" formControlName="txtDeliveryCity" placeholder="City" maxlength="40">
    <ng-option *ngFor="let city of drpDeliCities" [value]="city.cityName">
      {{ city.cityName }}</ng-option>
  </ng-select> -->
                  <mat-form-field class="col-3">
                    <mat-label>City</mat-label>
                    <mat-select formControlName="txtDeliveryCity" [(value)]="DeliveryCity" #singleSelect>
                      <mat-option>
                        <ngx-mat-select-search placeholderLabel="Search" noEntriesFoundLabel="No Matches found"
                          formControlName="txtDeliveryCityFilter"></ngx-mat-select-search>
                      </mat-option>
                      <mat-option *ngFor="let city of filteredDeliveryCities | async" [value]="city.cityName">
                        {{ city.cityName }}
                      </mat-option>
                    </mat-select>
                    <button mat-button (click)="DeliveryCity='';$event.stopPropagation()" *ngIf="DeliveryCity"
                      (click)="clearDeliveryCity()" matSuffix mat-icon-button >
                      <mat-icon>close</mat-icon>
                    </button>
                  </mat-form-field>
                  <mat-form-field class="col-3">
                    <mat-label> Zip Code</mat-label>
                    <input autocomplete="new-txtDeliveryPostalCode" matInput maxlength="15" formControlName="txtDeliveryPostalCode" pattern="[0-9-]*"
                      type="text" />
                    <mat-error *ngIf="
            personalForm?.get('txtDeliveryPostalCode')?.touched &&
            personalForm?.get('txtDeliveryPostalCode')?.errors?.pattern
          ">
                      Enter only Numbers!
                    </mat-error>
                  </mat-form-field>
                  <!-- <mat-form-field class="col-3">
            <mat-label> Phone</mat-label>
            <input matInput maxlength="40" formControlName="txtDeliveryPhone"
              mask="(00000)(000)-000-0000" type="text" [value]='phoneDelCode' />
            <mat-error *ngIf="
        personalForm?.get('txtDeliveryPhone')?.touched &&
        personalForm?.get('txtDeliveryPhone')?.errors
      ">
              Enter Minimum 10 Digits!
            </mat-error>
          </mat-form-field> -->
                  <mat-form-field class="col-3">
                    <mat-label> Phone</mat-label>
                    <input autocomplete="new-txtDeliveryPhone" matInput maxlength="40" formControlName="txtDeliveryPhone" prefix="{{phoneDelCode}}-"
                      mask="(000)-000-0000" type="text" />
                    <mat-error *ngIf="
        personalForm?.get('txtDeliveryPhone')?.touched &&
        personalForm?.get('txtDeliveryPhone')?.errors
      ">
                      Enter Minimum 10 Digits!
                    </mat-error>
                  </mat-form-field>
                  <mat-form-field class="col-3">
                    <mat-label> Fax</mat-label>
                    <input autocomplete="new-txtDeliveryFax" matInput maxlength="40" formControlName="txtDeliveryFax" prefix="{{phoneDelCode}}-"
                      mask="(000)-000-0000" type="text" />
                    <mat-error *ngIf="
        personalForm?.get('txtDeliveryFax')?.touched &&
        personalForm?.get('txtDeliveryFax')?.errors
      ">
                      Enter Minimum 10 Digits!
                    </mat-error>
                  </mat-form-field>
                  <mat-form-field class="col-3">
                    <mat-label> Zone</mat-label>
                    <mat-select maxlength="40" [(value)]="zones" formControlName="drpZone">
                      <mat-option *ngFor="let zone of drpZones" [value]="zone.zoneTypeShortCodeId">
                        {{ zone.zoneType }}
                      </mat-option>
                    </mat-select>
                    <button mat-button (click)="zones='';$event.stopPropagation()" *ngIf="zones" matSuffix
                      (click)="clearZones()" mat-icon-button >
                      <mat-icon>close</mat-icon>
                    </button>
                  </mat-form-field>
                  <mat-form-field class="col-3">
                    <mat-label> Mobile</mat-label>
                    <input autocomplete="new-txtDeliveryMobile" matInput maxlength="40" formControlName="txtDeliveryMobile" prefix="{{phoneDelCode}}-"
                      mask="(000)-000-0000" type="text" />
                    <mat-error *ngIf="
        personalForm?.get('txtDeliveryMobile')?.touched &&
        personalForm?.get('txtDeliveryMobile')?.errors
      ">
                      Enter Minimum 10 Digits!
                    </mat-error>
                  </mat-form-field>
                  <!-- <mat-form-field class="col-3">
    <mat-label> Email Address</mat-label>
    <input matInput maxlength="40" matInput
      pattern="[a-z0-9._!#$%&'()*+,-./:;<=>?@[\]^_`{|}~]+@[a-z0-9.-]+\.[a-z]{2,4}$"
      [textMask]="{ mask: emailMask }" formControlName="txtDeliveryEmailAddress" type="text" />
    <mat-error *ngIf="
        personalForm?.get('txtDeliveryEmailAddress')?.touched &&
        personalForm?.get('txtDeliveryEmailAddress')?.errors?.pattern
      ">
      This is not a valid Email!!!
    </mat-error>
  </mat-form-field>
  <div class="col-3"> -->
                  <div *ngIf="isPatientPoliciesPresent">
                    <div *ngIf="insuranceVerificationId==='00000000-0000-0000-0000-000000000000'">
                      <div [hidden]="!patientId">
                        <button [disabled]="hideMoveToVerificationButton" class="mr-3 ml-3"
                          (click)="movetoVerification()" mat-button class="buttonColor">
                          <i class="fa fa-step-forward mr-1" ></i>
                          Move to Verfication
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </mat-card-content>
            </div>
          </div>
        </mat-card-content>
      </div>
    </div>
  </form>
  <br />
  <div class="row">
    <div class="col-sm-12">
      <div class="text-lg-center">
        <!-- <button type="submit" class="btn btn-primary  mb-1 mr-1">Save</button> -->


        <button *ngIf='(isShownSaveButton || isShownUpdateIconForPatient)'
          [disabled]="saveButtonHide || !(!personalForm.invalid && personalForm.dirty )" (click)="savePersonal()"
          mat-button class="buttonColor">
          Save
          <mat-icon *ngIf='isShowSpinner'>
            <mat-spinner class="spinner-border spinner-border-sm" diameter="20"> </mat-spinner>
          </mat-icon>
        </button>
      </div>
    </div>
  </div>
  <br />
</div>
