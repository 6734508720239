<div class="fluid-container">
  <div class="row">
    <div class="col-12">
      <div class="d-flex align-items-center justify-content-between my-2">
        <b class="mb-0 header-text"
          >Patient Order History Report - {{ data?.chartNo }}</b
        >
        <button mat-dialog-close mat-icon-button>
          <mat-icon>close</mat-icon>
        </button>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-12">
      <div class="card card-body curved-card">
        <form [formGroup]="patientStatementHistoryForm">
          <mat-form-field class="col-2 pl-1 dd-text customWidth">
            <mat-label>Statement Dates</mat-label>
            <mat-date-range-input [rangePicker]="picker">
              <input
                matStartDate
                matInput
                placeholder="Start date"
                formControlName="txtStartDate"
              />

              <input
                matEndDate
                matInput
                placeholder="End date"
                formControlName="txtEndDate"
              />
            </mat-date-range-input>
            <mat-datepicker-toggle
              matSuffix
              [for]="picker"
            ></mat-datepicker-toggle>
            <mat-date-range-picker #picker></mat-date-range-picker>
          </mat-form-field>

          <mat-form-field class="col-4">
            <mat-label>Practice</mat-label>
            <mat-select formControlName="drpPractices">
              <mat-option>
                <ngx-mat-select-search
                  placeholderLabel="Search"
                  noEntriesFoundLabel="No Matches found"
                  hideClearSearchButton="true"
                  (keyup)="practiceSearch($event.target.value)"
                  formControlName="txtPracticeFilter"
                ></ngx-mat-select-search>
              </mat-option>
              <mat-option
                *ngFor="let practice of filteredPractices | async"
                [value]="practice.id"
              >
                {{ practice.practiceName }}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field class="col-lg-4 mt-1">
            <mat-label>Provider</mat-label>
            <mat-select
              maxlength="40"
              autocomplete="off"
              formControlName="drpProviders"
            >
              <mat-option>
                <ngx-mat-select-search
                  placeholderLabel="Search"
                  noEntriesFoundLabel="No Matches found"
                  formControlName="txtProviderFilter"
                ></ngx-mat-select-search>
              </mat-option>
              <mat-option
                *ngFor="let providers of filteredProviders | async"
                [value]="providers.id"
              >
                {{ providers.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <button
            class="buttonColor ml-3 mat-raised-small-button"
            mat-raised-button
            (click)="getOrderHistoryReport(data.patientId)"
            [matTooltip]="'Search Report'"
          >
            <i class="fa fa-search"></i>
          </button>

          <button
            class="resetclr ml-1 mat-raised-small-button"
            mat-raised-button
            [matTooltip]="'Reset'"
          >
            <i class="fa fa-refresh"></i>
          </button>
        </form>
      </div>
    </div>
  </div>

  <div class="row mt-2">
    <div class="col-12">
      <div class="card card-body curved-card">
        <div class="d-flex justify-content-center" *ngIf="!isLoaded">
          <img
            class="img-responsive pb-2 mr-1"
            src="assets/gifs/Infinity-loader.gif"
          />
        </div>
        <div *ngIf="isLoaded" class="table table-responsive">
          <app-patient-statement-history-table
            [tableData]="ltPatientStatementHistory"
          ></app-patient-statement-history-table>
        </div>
      </div>
    </div>
  </div>
</div>
