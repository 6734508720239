import {
  Component,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import DataSource from 'devextreme/data/data_source';
const getOrderDay = function (rowData: any): number {
  return (new Date(rowData.OrderDate)).getDay();
};
@Component({
  selector: 'app-billing-reports-tab',
  templateUrl: './billing-reports-tab.component.html',
  styleUrls: ['./billing-reports-tab.component.scss']
})
export class BillingReportsTabComponent implements OnInit, OnDestroy {
  tableColumns: coloumnConfig[] = [];
  @ViewChild(DxDataGridComponent, { static: false }) dataGrid: DxDataGridComponent;
  columnResizingMode: string = 'nextColumn';
  showHeaderFilter: boolean = true;
  showFilterRow: boolean = true;
  showInfo = true;

  allowSearch: boolean = true;
  loadingVisible: boolean = true;
  readonly allowedPageSizes = [15, 30, 100, 200];
  currentFilter: any;
  popupPosition = {
    of: window, at: 'top', my: 'top', offset: { y: 10 },
  };
  customOperations = [{
    name: 'weekends',
    caption: 'Weekends',
    dataTypes: ['date'],
    icon: 'check',
    hasValue: false,
    calculateFilterExpression() {
      return [[getOrderDay, '=', 0], 'or', [getOrderDay, '=', 6]];
    },
  }];
  ngOnInit(): void {
    this.title.setTitle('Qsecure | Billing Reports'); this.tableColumns = [
      { caption: "Payer Id", dataType: "string", dataField: "payerId", visibleIndex: 4 },
      { caption: "Patient Id", dataType: "string", dataField: "defaultPatientId", cellTemplate: "defaultID&GUIDCellTemplate", visibleIndex: 0 },
      { caption: "SaleOrder Id", dataType: "string", dataField: "defaultSaleorderId", cellTemplate: "defaultID&GUIDCellTemplate", visibleIndex: 3 },
      { caption: "Claim Id", dataType: "string", dataField: "defaultClaimId", cellTemplate: "defaultID&GUIDCellTemplate", visibleIndex: 2 },
      { caption: "Payer Name", dataType: "string", dataField: "payerName", visibleIndex: 5 },
      { caption: "Payer Level", dataType: "string", dataField: "payerLevel", visibleIndex: 6 },
      { caption: "Patient Name", dataType: "string", dataField: "patientName", visibleIndex: 1 },
      { caption: "Location", dataType: "string", dataField: "provider", visibleIndex: 7 },
      { caption: "Invoice Branch", dataType: "string", dataField: "branch", visibleIndex: 8 },
      { caption: "Payment Type", dataType: "string", dataField: "paymentType", visibleIndex: 9 },
      { caption: "Posting Date", dataType: "string", dataField: "postingDate", visibleIndex: 10 },
      { caption: "Check Date", dataType: "string", dataField: "checkDate", visibleIndex: 11 },
      { caption: "Claim DOS", dataType: "date", dataField: "dos", visibleIndex: 12 },
      { caption: "Claim Submitted Date", dataType: "date", dataField: "claimDate", visibleIndex: 13 },
      { caption: "Claim Total Price", dataType: "string", dataField: "billedAmount", cellTemplate: "priceCellTemplate", visibleIndex: 14 },
      { caption: "Claim Allow Price", dataType: "string", dataField: "allowedAmount", cellTemplate: "priceCellTemplate", visibleIndex: 15 },
      { caption: "Check Amount", dataType: "string", dataField: "checkAmount", cellTemplate: "priceCellTemplate", visibleIndex: 19 },
      { caption: "Claim Balance", dataType: "string", dataField: "currentBalance", cellTemplate: "priceCellTemplate", visibleIndex: 18 },
      { caption: "Claim Paid", dataType: "string", dataField: "insurancePaid", cellTemplate: "priceCellTemplate", visibleIndex: 16 },
      { caption: "Adjustment", dataType: "string", dataField: "adjustment", cellTemplate: "priceCellTemplate", visibleIndex: 17 },
      { caption: "Claim Status", dataType: "string", dataField: "claimStatus", cellTemplate: "claimStatusCellTemplate", visibleIndex: 20 },

    ];
  }
  ngOnDestroy(): void {
  }
  dataSource: DataSource = new DataSource([]);

  collapsed = false;

  contentReady = (e) => {
    if (!this.collapsed) {
      this.collapsed = true;
      e.component.expandRow(['EnviroCare']);
    }
  };

  customizeTooltip = (pointsInfo: { originalValue: string; }) => ({ text: `${parseInt(pointsInfo.originalValue)}%` });

  constructor(service: Service,
    private title: Title,
    private mISReportsService: MISReportsService
  ) {
    this.mISReportsService.billingReport().subscribe(response => {
      this.dataSource = new DataSource(response ?? []);
      this.loadingVisible = false;
    }, err => {
      this.loadingVisible = false;
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    });
    // service.get423DataSource().subscribe(response => {
    //   this.dataSource = new DataSource(response);
    //   this.loadingVisible = false;
    // });

  }


}

import { Injectable } from '@angular/core';
import 'devextreme/data/odata/store';
import { DxDataGridComponent } from 'devextreme-angular';
import { ClaimInfoDetailsService } from 'projects/billing/src/app/claim-proxy/claim-processing-management/rcm/claim-processing-management/claim-optimization';
import { MISReportsService } from 'projects/billing/src/app/claim-proxy/claim-processing-management/rcm/claim-processing-management/mis-reports';
import { HttpErrorResponse } from '@angular/common/http';
import Swal from 'sweetalert2';
import { Title } from '@angular/platform-browser';
import { baseUrl } from 'projects/patient/src/environments/environment';

@Injectable({ providedIn: "root" })
export class Service {
  constructor(private claimSearchService: ClaimInfoDetailsService) {

  }
  getDataSource() {
    return new DataSource({
      store: {
        type: 'odata',
        url: 'https://js.devexpress.com/Demos/SalesViewer/odata/DaySaleDtoes',
        key: 'Id',
        beforeSend(request) {
          request.params.startDate = '2020-05-10';
          request.params.endDate = '2020-05-15';
        },
      },
    });


  }

  get423DataSource() {
    let x = {
      defaultClaimNumber: "",
      defaultOrderNumber: "",
      defaultPatientNumber: "",
      sPatientName: "",
      billedQuantity: "",
      billedAmount: "",
      payerName: "",
      providerName: "",
      currentBalance: "",
      claimServiceDate: "",
      claimStatus: "",
      wayStarClaimStatus: "",
      postingCount: "",
      claimProcessedDate: "",
    }

    return this.claimSearchService.claimStatusSearchByDefaultClaimNumberAndDefaultOrderNumberAndDefaultPatientNumberAndSPatientNameAndBilledQuantityAndBilledAmountAndPayerNameAndProviderNameAndCurrentBalanceAndClaimServiceDateAndClaimStatusAndWayStarClaimStatusAndPostingCountAndClaimProcessedDate(
      x?.defaultClaimNumber,
      x?.defaultOrderNumber,
      x?.defaultPatientNumber,
      x?.sPatientName,
      x?.billedQuantity,
      x?.billedAmount,
      x?.payerName,
      x?.providerName,
      x?.currentBalance,
      x?.claimServiceDate,
      x?.claimStatus,
      x?.wayStarClaimStatus,
      x?.postingCount,
      x?.claimProcessedDate
    )



  }

}


export interface coloumnConfig {
  format?: string
  allowGrouping?: string
  cssClass?: string
  alignment?: string
  cellTemplate?: string
  dataField: string
  dataType: string
  caption: string
  visibleIndex?: number
}
