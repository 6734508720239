import {
  Component,
  Input,
  OnInit,
  ViewChild,
  Output,
  EventEmitter,Directive
} from '@angular/core';
import { TableService } from 'projects/shared/src/app/table.service';
import { Title } from '@angular/platform-browser';
import { CreateUpdateOrderShippingStatusDTO } from 'projects/order/src/app/order-proxy/order-management/order-optimization/dto';
import { HttpErrorResponse } from '@angular/common/http';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { noWhitespaceValidator } from 'projects/patient/src/app/add-note-tab/add-note-tab.component';
import { TextValidators } from 'projects/shared/src/app/validations/text-validator';
import Swal from 'sweetalert2';
import { ToastrService } from 'ngx-toastr';
import { OrderShippingStatusService } from 'projects/order/src/app/order-proxy/order-management/order-optimization/order-shipping-status.service';
import { DataTableDirective } from 'angular-datatables';

@Component({
  selector: 'app-ordershipppingtable',
  templateUrl: './ordershipppingtable.component.html',
  styleUrls: ['./ordershipppingtable.component.scss'],
})


export class OrdershipppingtableComponent implements OnInit {
  isLoading: boolean = false;
  panelOpenState: boolean = false;
  orderId: string = '';
  orderform: FormGroup;
  saveButtonHide: boolean = false;
  isShowSpinner: boolean = false;
  descriptionId: string;
  @ViewChild(DataTableDirective, { static: false })
  datatableElement: DataTableDirective;
  constructor(
    private title: Title,
    public Table: TableService,
    private fb: FormBuilder,
    private textValidators: TextValidators,
    private snackBar: MatSnackBar,
    private toastr: ToastrService,
    private orderService: OrderShippingStatusService
  ) {}
  dtOptions: any;
  descriptionData: any;
  @Input() tableData: any[];
  @Output() reloadDescriptionTableEvent = new EventEmitter();

  ngOnInit(): void {
    this.title.setTitle('Qsecure | Description');
    this.dtOptions = {
      responsive: true,
      paging: false,
      scrollCollapse: true,
      scrollY: '410px',
      scrollX: true,
      dom: 'Bfrtip',
      language: {
        emptyTable: 'No records',
        info: 'Total : _MAX_ records',
        infoEmpty: 'Total : _MAX_ records',
        infoFiltered: '(filtered : _TOTAL_ records)',
        loadingRecords: 'Loading...',
        zeroRecords: 'No matching records',
      },
      buttons: [
        {
          extend: 'excel',
          text: 'Export',
        },
      ],
    };
    this.descriptionData = this.tableData;
    this.createOrderDescription();
  }

  // Form Creation and Few Validations
  createOrderDescription() {
    this.orderform = this.fb.group({
      txtShipppingDescription: new FormControl('', [
        Validators.required,
        noWhitespaceValidator,
        this.textValidators.isTextCheck
      ]),
    });
  }
  // //After View Method
  ngAfterViewInit(): void {
    this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.columns().every(function () {
        const that = this;
        $('input', this.footer()).on('keyup change', function () {
          if (that.search() !== this['value']) {
            that.search(this['value']).draw();
          }
        });
      });
    });
  }

  toggle() {
    this.panelOpenState = true;
  }
  toggleV2() {
    this.panelOpenState = false;
  }

  saveOrderDescription() {
    // this.isShowSpinner = true;
    this.saveButtonHide = false;
    let descriptionDetails: CreateUpdateOrderShippingStatusDTO = {
      description: this.orderform.value.txtShipppingDescription,
    };

    if (
      this.orderId === '' ||
      this.orderId === undefined ||
      this.orderId === null
    ) {
      this.orderService.create(descriptionDetails).subscribe(
        (response) => {
          // this.panelOpenState = false;
          this.toastr.success('Saved Successfully', 'Success');
          this.saveButtonHide = false;
          this.isShowSpinner = false;
          this.reloadDescriptionTableEvent.emit();
          this.resetOrderShipping();
        },
        (err) => {
          this.isLoading = false;
          this.saveButtonHide = false;
          const data: HttpErrorResponse = err;
          this.toastr.error(data.error.error.message, 'Failed');
          this.resetOrderShipping();
        }
      );
    } else {
      this.orderService.update(this.orderId, descriptionDetails).subscribe(
        (response) => {
          // this.panelOpenState = false;
          this.toastr.success('updated Successfully', 'Success');
          this.saveButtonHide = false;
          this.isShowSpinner = false;
          this.reloadDescriptionTableEvent.emit();
          this.resetOrderShipping();
        },
        (err) => {
          this.isLoading = false;
          this.saveButtonHide = false;
          const data: HttpErrorResponse = err;
          this.toastr.error(data.error.error.message, 'Failed');
          this.resetOrderShipping();
        }
      );
    }
    this.orderId = '';
  }
  resetOrderShipping() {
    this.orderform.patchValue({
      txtShipppingDescription: '',
    });
    this.orderId = '';
    this.orderform.reset();
  }

  viewDescription(descriptionId: string) {
    this.orderService.get(descriptionId).subscribe((response) => {
      this.orderform.patchValue({
        txtShipppingDescription: response?.description ?? '',
      });
      this.orderId = response.id;
    });
  }
  onRowDoubleClickV1(textToCopy) {
    this.copyTextToClipboard(textToCopy);
    this.showNotification('Text copied: ' + textToCopy);
  }
  showNotification(message: string): void {
    this.snackBar.open(message, 'Close', {
      duration: 3000, // Duration in milliseconds
      horizontalPosition: 'end', // Positioning the notification horizontally
      verticalPosition: 'bottom', // Positioning the notification vertically
    });
  }

  copyTextToClipboard(text: string): void {
    const textArea = document.createElement('textarea');
    textArea.value = text;
    document.body.appendChild(textArea);
    textArea.select();
    document.execCommand('Copy');
    textArea.remove();
  }
}
