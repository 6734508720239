import { RestService } from '@abp/ng.core';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class AuthorizationSearchService {
  apiName = 'Default';

  searchAuthorizationBySPatientIdAndSPatietNameAndSAuthIdAndSPlanAndDtCreatedDateAndSStatus = (sPatientId: string, sPatietName: string, sAuthId: string, sPlan: string, dtCreatedDate: string, sStatus: string) =>
    this.restService.request<any, object>({
      method: 'POST',
      url: '/api/app/authorization-search/search-authorization',
      params: { sPatientId, sPatietName, sAuthId, sPlan, dtCreatedDate, sStatus },
    },
     { apiName: this.apiName, skipHandleError: true });

  constructor(private restService: RestService) {}
}
