import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DataTableDirective } from 'angular-datatables';
import { ToastrService } from 'ngx-toastr';
import { sortedFaxDetails } from 'projects/admin/src/app/admin-proxy/platform-app-management/rcm/platform-management/inbound/dto/models';
import { InboundDocumentService } from 'projects/admin/src/app/admin-proxy/platform-app-management/rcm/platform-management/inbound/inbound-document.service';
import { defaultGuid } from 'projects/shared/src/app/enums/allenums.enum';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-inbound-sorting-pending-table',
  templateUrl: './inbound-sorting-pending-table.component.html',
  styleUrls: ['./inbound-sorting-pending-table.component.scss']
})
export class InboundSortingPendingTableComponent implements OnInit {

  @Input() tableData: any;
  @Output() enableDoSortingBtn? = new EventEmitter<void>();
  @Output() reloadTable? = new EventEmitter<void>();
  inboundSortingPendingListDataArray : sortedFaxDetails[];
  dttblInboundSortingPendingOptions : any = {
    responsive: true,
    stateSave: true,
    paging: false,
    dom: 'Bfrtip',
    language: {
      emptyTable: 'No records',
      info: 'Total : _MAX_ records',
      infoEmpty: 'Total : _MAX_ records',
      infoFiltered: '(filtered : _TOTAL_ records)',
      loadingRecords: 'Loading...',
      zeroRecords: 'No matching records',
    },
    scrollCollapse: true,
    scrollY: '500px',
    scrollX: true,
    fixedHeader: true, // Fix the header
    fixedColumns: {
      leftColumns: 3, // Number of columns to fix on the left
      rightColumns: 1, // Number of columns to fix on the right
    },
    columnDefs: [
      { targets: [0], orderable: false },
      { targets: [0, 1, 2, -1], className: 'dt-fixed-column' }, // Add class to make the first three and last column fixed
      {
        targets: [4,6],
        type: 'date',
        render: function (data, type, row) {
          // Assuming data is in the format MM/DD/YYYY
          if (type === 'sort' || type === 'type') {
            var dateParts = data.split('/');
            return new Date(dateParts[2], dateParts[0] - 1, dateParts[1]);
          } else {
            return data;
          }
        },
      },
    ],
    search: {
      smart: false,
    },
    buttons: [
      {
        extend: 'excel',
        text: 'Export',
        filename: 'Inbound Sorting Pending List',
        exportOptions: {
          columns: ':visible:not(:first-child) ',
        },
      },
      {
        extend: 'colvis',
        columns: ':not(:eq(0), :eq(1), :eq(2), :eq(-1))', // Exclude first three and last columns from the column visibility toggle
      },
      {
        text: 'Show Default Column',
        action: function (e, dt, node, config) {
          dt.columns(':not(:lt(3),:last-child)').visible(false);
        },
      },
      {
        text: 'Show All Column',
        action: function (e, dt, node, config) {
          dt.columns().visible(true);
        },
      },
    ],
  };
  tblItemSelectedRowId: string;
  @ViewChild(DataTableDirective, { static: false })
  datatableElement: DataTableDirective;
  disableLockCheckbox : boolean;
  private inboundisLockUpdateAPICall: Subscription;
  constructor(private snackBar: MatSnackBar,
    public inboundDocUploadService: InboundDocumentService,private toastr: ToastrService,private datepipe:DatePipe) { }

  ngOnInit(): void {

    this.inboundSortingPendingListDataArray = this.tableData;
    this.calculateScrollY();
    window.addEventListener('resize', this.calculateScrollY);

    this.dttblInboundSortingPendingOptions.buttons[0].filename =
    'Inbound Sorting Pending List' + '-' + this.datepipe.transform(new Date(), 'MM-dd-yyyy');
  }


  //After View Method
  ngAfterViewInit(): void {
    if (this.datatableElement && this.datatableElement.dtInstance) {
      this.refreshDataTable();
    } else {
      // If dtInstance is not available yet, wait for a short time and try again
      setTimeout(() => this.refreshDataTable(), 500);
    }
  }

  ngOnDestroy(): void {
    // Unsubscribe to avoid memory leaks
    // this.subscription$?.forEach((sub) => {
    //   sub && sub?.unsubscribe();
    // });

    window.removeEventListener('resize', this.calculateScrollY);
  }


  calculateScrollY = () => {
    const screenHeight =
      window.innerHeight ||
      document.documentElement.clientHeight ||
      document.body.clientHeight;
    const availableSpacePercentage = 0.5; // Adjust the percentage as needed
    this.dttblInboundSortingPendingOptions.scrollY = screenHeight * availableSpacePercentage;
  };

  refreshDataTable(): void {
    this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
      // Clear search input values
      $('input', dtInstance.table(0).footer()).val('');

      // Redraw the DataTable to load all records
      dtInstance.search('').columns().search('').draw();

      // Attach the event listener for filtering
      dtInstance.columns().every(function () {
        const that = this;
        $('input', this.footer()).on('keyup change', function () {
          if (that.search() !== this['value']) {
            that.search(this['value']).draw();
          }
        });
      });
    });
  }

  tblItemSelectedRow(patientId: string, documentId: string, chartId: string) {
    this.tblItemSelectedRowId = patientId;
  }

  isEmpty(value: any): boolean {
    return (
      value === null ||
      value === undefined ||
      value === '' ||
      value === defaultGuid
    );
  }

  getFormattedDate(date: string): string {
    return date ? new Date(date).toLocaleDateString('en-US') : '-';
  }
  getFormattedDateTime(date: any): string {
    return date
      ? new Date(date).toLocaleString('en-US', {
          month: '2-digit',
          day: '2-digit',
          year: 'numeric',
          hour: 'numeric',
          minute: 'numeric',
          hour12: true,
        })
      : '-';
  }


  onRowDoubleClick(textToCopy) {
    this.copyTextToClipboard(textToCopy);
    this.showNotification('Text copied: ' + textToCopy);
  }

  showNotification(message: string): void {
    this.snackBar.open(message, 'Close', {
      duration: 3000, // Duration in milliseconds
      horizontalPosition: 'end', // Positioning the notification horizontally
      verticalPosition: 'bottom', // Positioning the notification vertically
    });
  }

  copyTextToClipboard(text: string): void {
    const textArea = document.createElement('textarea');
    textArea.value = text;
    document.body.appendChild(textArea);
    textArea.select();
    document.execCommand('Copy');
    textArea.remove();
  }

  islockUpdate(data : any){

    this.disableLockCheckbox = true;
    try {
      if (this.inboundisLockUpdateAPICall) {
        this.inboundisLockUpdateAPICall.unsubscribe();
      }
      this.inboundisLockUpdateAPICall = this.inboundDocUploadService
        .updateLockstatusByDocumentId(
          data.documentId
        )
        .subscribe(
          (response) => {
            // this.toastr.success('Lock Updated Successfully', 'Success');
            this.showNotification('Lock Updated Successfully');
            this.enableDoSortingBtn.emit();
          },
          (error) => {
            this.toastr.error('Lock Updated Failed', 'Error');
            this.reloadTable.emit();
            console.error('Error:', error);
          },
          () => {
            // This block will be executed on completion
            setTimeout(() => {
              this.disableLockCheckbox = false; // Enable the checkbox
            }, 1000);
          }
        );
    } catch (error) {
      // this.toastr.error('Lock Update Failed', 'Error');
      // this.reloadTable.emit('Mani');
      console.error('Unexpected error:', error);
    } finally {
    }
  }

}
