import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { DashboardService } from 'projects/admin/src/app/admin-proxy/platform-app-management/rcm/platform-management/dashboard/dashboard.service';
import { CreateUpdateShippingCheckListDTO } from 'projects/order/src/app/order-proxy/order-management/order/dto/models';
import { SaleorderService } from 'projects/order/src/app/order-proxy/order-management/order/saleorder.service';
import { OrderMasterDropdownDTO } from 'projects/patient/src/app/dropdown-proxy/rcm/dropdown-management/dropdowns/dto/order/models';
import { OrderMasterDropdownService } from 'projects/patient/src/app/dropdown-proxy/rcm/dropdown-management/dropdowns/master-dropdown-service/order-master-dropdown.service';
import { OrderDropdowns } from 'projects/patient/src/app/dropdown-proxy/rcm/dropdown-management/dropdowns/order-dropdowns.enum';
import { Subscription } from 'rxjs';
import Swal from 'sweetalert2';
import { DeclineUpdateComponent } from '../decline-update/decline-update.component';
import { SerialnoEditComponent } from '../serialno-edit/serialno-edit.component';
import { ToastrService } from "ngx-toastr";

@Component({
  selector: 'app-shipping-view',
  templateUrl: './shipping-view.component.html',
  styleUrls: ['./shipping-view.component.scss']
})
export class ShippingViewComponent implements OnInit, OnDestroy {
  step: boolean = false;
  public strPageType: string = "Roles";
  isLoading: boolean = true;
  showShipButton: boolean = false;
  itemTable: any[] = [];
  orderId: string = "";
  showButton: boolean = false;
  shippingViewForm: FormGroup;
  currentOrderStatus: string = "";
  readyToDel: boolean = false;
  packView: boolean = false;
  returnView: boolean = false;
  shippingApprove: boolean = false;
  readyToPack: boolean = false;
  received: boolean = false;
  ReturnReason: boolean = false;
  isShowSpinner: boolean = false;
  ticketId: string = '';
  roleName: string = "";
  subscription$: Subscription[] = [];

  constructor(public dialog: MatDialog,
    private activatedRoute: ActivatedRoute,
    private orderService: SaleorderService,
    private fb: FormBuilder,
    private router: Router,
    private dashboardService: DashboardService,
    private toastr: ToastrService,
    private orderDropdownService: OrderMasterDropdownService,) { }

  ngOnInit(): void {

    this.activatedRoute.paramMap.subscribe((response) => {
      this.orderId = response?.get('orderId') ?? '';
    });
    this.shippingViewForm = this.fb.group({
      txtOrderId: new FormControl(""),
      txtPatientName: new FormControl(""),
      txtId: new FormControl(""),
      txtPatientNo: new FormControl(""),
      txtCsrName: new FormControl(""),
      txtCsrNo: new FormControl(""),
      txtQty: new FormControl(""),
      txtTicketId: new FormControl(""),
      txtTDate: new FormControl(""),
      txtLocation: new FormControl(""),
      txtReturnReason: new FormControl(""),
      txtWeight: new FormControl("", [Validators.required, Validators.min(1), Validators.max(68)]),
      drpDelType: new FormControl("", [Validators.required]),
      drpCourier: new FormControl("", [Validators.required]),
      txtTrackId: new FormControl(""),
    })
    this.getDropdown();
  }

  ngOnDestroy(): void {
    this.subscription$?.forEach((sub) => {
      sub && sub?.unsubscribe();
    });
  }

  getRoleName() {
    this.dashboardService.userProfile().subscribe((response) => {

      if (
        response?.tenantId != null &&
        response?.roleName == 'Warehouse Manager'

      ) {

        this.roleName = response?.roleName;
      }
      if (
        response?.tenantId != null &&
        response?.roleName == 'Warehouse Executive'
      ) {
        // this.showButton = true
        this.roleName = response?.roleName;
      }

    })
  }

  delType: any[] = [];
  drpCourier: any[] = [];
  /// getb all dropdowns
  getDropdown() {
    let orderDropdownInput: OrderDropdowns[];
    orderDropdownInput = [
      OrderDropdowns.ModeOfDelivery,
      OrderDropdowns.MethodOfDelivery
    ]
    const orderDropdown = this.orderDropdownService.getOrderDropdownsByInput(orderDropdownInput).subscribe((stateResponse) => {
      let response: OrderMasterDropdownDTO = stateResponse;
      this.delType = response.modeOfDeliveries,
        this.drpCourier = response?.methodOfDeliveries
    }, err => {
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    });
    this.subscription$.push(orderDropdown)
    //
    this.getRoleName();
    setTimeout(() => {
      this.getValuesById(this.orderId);
    }, 1000)
  }


  //// on change dropdown get value
  onEndsChanges(val) {
    if (val === "Package Delivery Service") {
      let orderDropdownInput: OrderDropdowns[];
      orderDropdownInput = [
        OrderDropdowns.MethodOfDelivery
      ]
      const orderDropdown = this.orderDropdownService.getOrderDropdownsByInput(orderDropdownInput).subscribe((stateResponse) => {
        let response: OrderMasterDropdownDTO = stateResponse;
        this.drpCourier = response?.methodOfDeliveries
      }, err => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      });
      this.subscription$.push(orderDropdown);
    } else {
      let orderDropdownInput: OrderDropdowns[];
      orderDropdownInput = [
        OrderDropdowns.InvLocations
      ]

      const orderDropdown = this.orderDropdownService.getOrderDropdownsByInput(orderDropdownInput).subscribe((stateResponse) => {
        let response: OrderMasterDropdownDTO = stateResponse;

        this.drpCourier = response?.invLocations
      }, err => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      });
      this.subscription$.push(orderDropdown)
    }

  }
  totalQty: number;
  totalItemQty: number;
  totalItemPrice: number;
  ///initial patch for forms
  resWeight;
  resMethDeli;
  resModeDeli;
  resReject;
  resReturn;
  getValuesById(orderId) {
    this.getRoleName();
    const orderGet = this.orderService.get(orderId).subscribe(orderResponse => {
      this.totalQty = orderResponse?.shippingCheckList.totalItemQty
      if (orderResponse?.shippingCheckList?.status === "Ready To Pack") {
        this.packView = true
      } else {
        this.packView = false
      }
      if (orderResponse?.deliveryTicket === null) {
        this.readyToDel = false
      } else {
        this.readyToDel = true
      }

      /// return button
      if (orderResponse?.shippingCheckList?.status === "Returned") {

        this.ReturnReason = true
        this.returnView = true

      } else {
        this.returnView = false
      }
      /// return button
      if (orderResponse?.shippingCheckList?.status === "Return Approved" && this.roleName === "Warehouse Executive") {
        this.ReturnReason = true

      } else {

      }

      ///// shipping approve
      if (orderResponse?.shippingCheckList?.status === "Ready To Approve" && this.roleName === "Warehouse Manager") {
        this.showButton = false
        this.shippingApprove = true

      }

      if (orderResponse?.shippingCheckList?.status === "Shipping Approved" && this.roleName === "Warehouse Executive") {
        // this.showButton = false
        this.readyToPack = true

      }
      if (orderResponse?.shippingCheckList?.status === "Return Approved" && this.roleName === "Warehouse Executive") {
        // this.showButton = false
        this.received = true

      }
      // if (orderResponse?.shippingCheckList?.status === "Ready To Pack") {
      //   this.packView = true
      // } else {
      //   this.packView = false
      // }

      let shippingTicketView = orderResponse?.shippingCheckList;
      this.currentOrderStatus = shippingTicketView?.status
      this.ticketId = shippingTicketView?.defaultTicketId;
      this.totalItemQty = shippingTicketView?.totalItemQty;
      this.totalItemPrice = shippingTicketView?.totalItemPrice;

      this.resWeight = shippingTicketView?.productWeight;
      this.resMethDeli = shippingTicketView?.methodOfdelivery;
      this.resModeDeli = shippingTicketView?.modeOfDelivery;
      this.resReject = shippingTicketView?.rejectedReason;
      this.resReturn = shippingTicketView?.returnReason;
      this.shippingViewForm.patchValue({
        txtOrderId: shippingTicketView?.defaultOrderId,
        txtPatientName: shippingTicketView?.patientName,
        txtId: shippingTicketView?.defaultPatientId,
        txtPatientNo: shippingTicketView?.patientPhoneNumber,
        txtCsrName: shippingTicketView?.userName,
        txtCsrNo: shippingTicketView?.userPhoneNumber,
        txtQty: shippingTicketView?.totalItemQty,
        txtTicketId: shippingTicketView?.defaultTicketId,
        txtTDate: shippingTicketView?.ticketCreatedDate == null ? undefined : new Date(shippingTicketView?.ticketCreatedDate)?.toLocaleDateString("en-US"),
        // txtSerialNo:shippingTicketView?.,
        txtLocation: shippingTicketView?.deliveryLocation,
        txtReturnReason: shippingTicketView?.returnReason
      })
      this.itemTable = [];
      orderResponse?.items?.forEach(value => {
        this.itemTable.push({
          productName: value?.name,
          brandName: value?.brandName,
          categoryName: value?.categoryName,
          qty: value?.qty,
          price: value?.charge,
          lotId: value?.id,
          orderId: orderResponse?.id,
          productLotDetails: value?.productLotDetails,
          productId: value.productId,
          itemId: value?.itemId,
          status: orderResponse?.shippingCheckList?.status,
          itemIdOrBarCode: value?.itemIdOrBarCode
          // lotNo: value?.l
        })
      })
    })
    this.subscription$.push(orderGet);
  }

  ///// approve retyrn by wm
  approveReturn() {
    Swal.fire({
      title: 'Are You Confirm to update the status?',
      text: 'By clicking yes, this record will move to return approved lists',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#34c38f',
      cancelButtonColor: '#f46a6a',
      confirmButtonText: 'Yes',
      allowOutsideClick: false,
      cancelButtonText: 'No',
    }).then(result => {
      if (result.value) {
        let CreateUpdateShipping: CreateUpdateShippingCheckListDTO = {
          status: "Return Approved",
          productWeight: this.resWeight,
          methodOfdelivery: this.resMethDeli,
          modeOfDelivery: this.resModeDeli,
          rejectedReason: this.resReject,
          returnReason: this.resReturn,
        }
        this.orderService.updateShippingCheckListByOrderIdAndInput(this.orderId, CreateUpdateShipping).subscribe(response => {
          this.currentOrderStatus = response?.shippingCheckList.status
          // Swal.fire({ title: 'Success', html: 'Return Approved Successfully', icon: 'success', timer: 3000, timerProgressBar: true });
          this.toastr.success('Return Approved Successfully','Success')
          this.router.navigate(['orders/shippingReturnTicketList']);
        }, err => {
          const data: HttpErrorResponse = err;
          Swal.fire({
            icon: 'info',
            text: data?.error?.error?.message,
          });
        });
      } else {
      }
    });
  }

  //// return product received
  ProductReceived() {
    Swal.fire({
      title: 'Are You Confirm to update the status?',
      //text: 'By clicking yes, this record will move to produ lists',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#34c38f',
      cancelButtonColor: '#f46a6a',
      confirmButtonText: 'Yes',
      allowOutsideClick: false,
      cancelButtonText: 'No',
    }).then(result => {
      if (result.value) {
        let CreateUpdateShipping: CreateUpdateShippingCheckListDTO = {
          status: "Return Product Received",

          productWeight: this.resWeight,
          methodOfdelivery: this.resMethDeli,
          modeOfDelivery: this.resModeDeli,
          rejectedReason: this.resReject,
          returnReason: this.resReturn
        }
        this.orderService.updateShippingCheckListByOrderIdAndInput(this.orderId, CreateUpdateShipping).subscribe(response => {
          this.currentOrderStatus = response?.shippingCheckList.status
          // Swal.fire({ title: 'Success', html: 'Moved To Pack Successfully', icon: 'success', timer: 3000, timerProgressBar: true });
          this.toastr.success('Moved To Pack Successfully','Success')
          this.router.navigate(['orders/shippingApprovedList']);
        }, err => {
          const data: HttpErrorResponse = err;
          Swal.fire({
            icon: 'info',
            text: data?.error?.error?.message,
          });
        });
      } else {
      }
    });
  }

  //// open modal popup
  openModel() {
    const dialogRef = this.dialog.open(DeclineUpdateComponent, {
      disableClose: true,
      data: { orderId: this.orderId, userName: this.shippingViewForm.value.txtCsrName }
    });

    const closeModal = dialogRef.afterClosed().subscribe(() => {
      this.getValuesById(this.orderId)
    }, err => {
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    });
    this.subscription$.push(closeModal);
  }

  /// open edit modal
  openViewModal(productLotDetails, orderId, productName, productId, itemId, status) {
    const dialogRef = this.dialog.open(SerialnoEditComponent, {
      disableClose: true,
      data: { productLotDetails: productLotDetails, orderId: orderId, productName: productName, productId: productId, itemId: itemId, status: status }
    });
    const closeModal = dialogRef.afterClosed().subscribe(() => {
      this.getValuesById(orderId)
    }, err => {
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    });
    this.subscription$.push(closeModal);
  }

  approve() {
    Swal.fire({
      title: 'Are You Confirm to update the status?',
      text: 'By clicking yes, this record will move to shipping approved lists',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#34c38f',
      cancelButtonColor: '#f46a6a',
      confirmButtonText: 'Yes',
      allowOutsideClick: false,
      cancelButtonText: 'No',
    }).then(result => {
      if (result.value) {
        this.approveByManager();
      } else {


      }
    });
  }

  ////warehouse manager Approve
  approveByManager() {
    let CreateUpdateShipping: CreateUpdateShippingCheckListDTO = {
      defaultTicketId: this.shippingViewForm.value.txtTicketId,
      ticketCreatedDate: this.shippingViewForm.value.txtTDate,
      defaultOrderId: this.shippingViewForm.value.txtOrderId,
      defaultPatientId: this.shippingViewForm.value.txtId,
      patientName: this.shippingViewForm.value.txtName,
      patientPhoneNumber: this.shippingViewForm.value.txtPatientNo,
      userName: this.shippingViewForm.value.txtCsrName,
      userPhoneNumber: this.shippingViewForm.value.txtCsrNo,
      deliveryLocation: this.shippingViewForm.value.txtLocation,
      totalItemQty: this.shippingViewForm.value.txtQty,
      totalItemPrice: 0,
      status: "Shipping Approved",
      productWeight: this.resWeight,
      methodOfdelivery: this.resMethDeli,
      modeOfDelivery: this.resModeDeli,
      rejectedReason: this.resReject,
      returnReason: this.resReturn
    }
    this.orderService.updateShippingCheckListByOrderIdAndInput(this.orderId, CreateUpdateShipping).subscribe(response => {
      this.currentOrderStatus = response?.shippingCheckList.status
      // Swal.fire({ title: 'Success', html: 'Shipping Approved Successfully', icon: 'success', timer: 3000, timerProgressBar: true });
      this.toastr.success('Shipping Approved Successfully','Success')
      this.router.navigate(['orders/shippingTicketList']);
    }, err => {
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    });
  }

  //// ready to exe save
  ReadyByExecutive() {
    let CreateUpdateShipping: CreateUpdateShippingCheckListDTO = {
      status: "Ready To Pack",
      productWeight: this.resWeight,
      methodOfdelivery: this.resMethDeli,
      modeOfDelivery: this.resModeDeli,
      rejectedReason: this.resReject,
      returnReason: this.resReturn
    }
    this.orderService.updateShippingCheckListByOrderIdAndInput(this.orderId, CreateUpdateShipping).subscribe(response => {
      this.currentOrderStatus = response?.shippingCheckList.status
      // Swal.fire({ title: 'Success', html: 'Moved To Pack Successfully', icon: 'success', timer: 3000, timerProgressBar: true });
      this.toastr.success('Moved To Pack Successfully','Success')
      this.router.navigate(['orders/shippingApprovedList']);
    }, err => {
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    });
  }

  ReadyToPack() {
    Swal.fire({
      title: 'Are You Confirm to update the status?',
      text: 'By clicking yes, this record will move to ready to pack lists',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#34c38f',
      cancelButtonColor: '#f46a6a',
      confirmButtonText: 'Yes',
      allowOutsideClick: false,
      cancelButtonText: 'No',
    }).then(result => {
      if (result.value) {
        this.ReadyByExecutive();
      } else {
      }
    });
  }

  confirmReadyToDelivery() {

    Swal.fire({
      title: 'Are You Confirm to update the status?',
      text: 'By clicking yes, this record will move to ready to delivery lists',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#34c38f',
      cancelButtonColor: '#f46a6a',
      confirmButtonText: 'Yes',
      allowOutsideClick: false,
      cancelButtonText: 'No',
    }).then(result => {
      if (result.value) {
        this.ReadyToDelivey();
      } else {
      }
    });
  }

  /// ready to deli save
  ReadyToDelivey() {
    this.isShowSpinner = true;
    let CreateUpdateShipping: CreateUpdateShippingCheckListDTO = {
      productWeight: +this.shippingViewForm.value.txtWeight,
      modeOfDelivery: this.shippingViewForm.value.drpDelType,
      methodOfdelivery: this.shippingViewForm.value.drpCourier,
      status: "Ready To Delivery",

      returnReason: this.shippingViewForm.value.txtReturnReason,
    }
    this.orderService.updateShippingCheckListByOrderIdAndInput(this.orderId, CreateUpdateShipping).subscribe(response => {
      this.currentOrderStatus = response?.shippingCheckList.status
      // Swal.fire({ title: 'Success', html: 'Moved to Deliver Successfully', icon: 'success', timer: 3000, timerProgressBar: true });
      this.toastr.success('Moved to Deliver Successfully','Success')
      this.isShowSpinner = false;
      this.router.navigate(['orders/readyToPackList']);
    }, err => {
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    });
  }

  /// view ticket route
  viewTicket() {
    this.router.navigate(['orders/shippingViewTicket/' + this.orderId.toString()])
  }


  type: string = '';
  cleartype() {
    this.type = '';
    this.shippingViewForm.patchValue({ drpDelType: "" });
  }
  courier: string = '';
  clearcourier() {
    this.courier = '';
    this.shippingViewForm.patchValue({ drpCourier: "" });
  }
}
