import { style } from '@angular/animations';
import { Component, OnInit, QueryList, ViewChildren } from '@angular/core';
import { forkJoin, Subject, Subscription } from 'rxjs';

import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  NgModel,
  ValidationErrors,
  Validators,
} from '@angular/forms';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import Swal from 'sweetalert2';
import { Title } from '@angular/platform-browser';
import { ShippingListService } from 'projects/order/src/app/order-proxy/order-management/order-optimization/shipping-list.service';
import {
  DazzlePrintingLabelParamsDto,
  PrintTicketDetails,
  ShippingApprovalListDTO,
  ShippingApprovedNotesDTO,
} from 'projects/order/src/app/order-proxy/order-management/order-optimization/dto';
// import { defaultGuid } from 'projects/admin/src/app/document-perviewer/document-perviewer.component';
import { DataTableDirective } from 'angular-datatables';
import { ToastrService } from 'ngx-toastr';
import { MmShippingWeightService } from 'projects/order/src/app/order-proxy/order-management/order-optimization/mm-shipping-weight.service';
import { MmUPSLabelService } from 'projects/order/src/app/order-proxy/order-management/order-optimization/mm-upslabel.service';
import { ShippingApprovalService } from 'projects/order/src/app/order-proxy/order-management/order-optimization/shipping-approval.service';
import { OrderShippingStatusService } from 'projects/order/src/app/order-proxy/order-management/order-optimization/order-shipping-status.service';
import { PagedAndSortedResultRequestDto } from '@abp/ng.core';
import { shippingTypeService } from 'projects/order/src/app/order-proxy/order-management/order-optimization/shipping-type.enum';
import {
  AddNoteTabComponent,
  noWhitespaceValidator,
} from 'projects/patient/src/app/add-note-tab/add-note-tab.component';
import { NotesReasonComponent } from 'projects/inventory/src/app/notes-reason/notes-reason.component';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { NotesType } from 'projects/order/src/app/order-proxy/order-management/inventory/dto';
import { DatePipe } from '@angular/common';
import { PatientCommonNotesComponent } from 'projects/shared/src/app/components/patient-common-notes/patient-common-notes.component';
import { ViewPatientLedgerComponent } from 'projects/admin/src/app/view-patient-ledger/view-patient-ledger.component';
import { DocumentCheckListComponent } from 'projects/admin/src/app/document-check-list/document-check-list.component';
import { InsuranceAllowablesService } from 'projects/inventory/src/app/item-proxy/item-management/optimization/insurance-allowables.service';
import { PrintLabel } from 'projects/order/src/app/order-proxy/order-management/order-optimization/print-label.enum';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { LoaderService } from 'projects/shared/src/app/services/loader-service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { OrderHistoryModalComponent } from 'projects/order/src/app/order-history-modal/order-history-modal.component';
import { CommonService } from 'projects/shared/src/app/services/common.service';
import { InventorySerialLotNoService } from 'projects/inventory/src/app/item-proxy/item-management/optimization/inventory-serial-lot-no.service';
import { MmOrderService } from 'projects/order/src/app/order-proxy/order-management/order-optimization/mm-order.service';
import {
  debounceTime,
  distinctUntilChanged,
  map,
  mergeMap,
} from 'rxjs/operators';
import { defaultGuid } from 'projects/shared/src/app/enums/allenums.enum';
pdfMake.vfs = pdfFonts.pdfMake.vfs;

@Component({
  selector: 'app-shipping-list',
  templateUrl: './shipping-list.component.html',
  styleUrls: ['./shipping-list.component.scss'],
})
export class ShippingListComponent implements OnInit {
  searchControl = new FormControl();
  ltSerialLotNo: any[] = [];
  productIdBar: string;
  productIdWeight: string;
  shippingListForm: FormGroup;
  selectedShippingOrderId: string;
  shippingList: ShippingApprovalListDTO[] = [];
  selectedPatientId: string;
  shippingItemsList: any[] = [];
  shippingListNotesDetails: ShippingApprovedNotesDTO;
  printTicketData: PrintTicketDetails;
  isShippingListTableLoaded: boolean = true;
  isShippingItemsTableLoaded: boolean = true;
  isSerialLotSaving: boolean = false;
  upsLabelList: any[] = [];
  weightList: any[] = [];
  subscription$: Subscription[] = [];
  ShippingListSubscription: Subscription;
  notesListSubscription: Subscription;
  orderListSubscription: Subscription;
  hyperLinkshow: boolean = true;
  isWonaceLogin: boolean = false;
  isWonaceGetSes: string = localStorage.getItem('isInventoryNeeded') || '';
  shippingStatusList: any[] = [];
  dtShippingListOptions: any = {
    responsive: true,
    paging: false,
    scrollX: true,
    dom: 'Bfrtip',
    scrollCollapse: true,
    scrollY: '500px',
    language: {
      emptyTable: 'No records',
      info: 'Total : _MAX_ records',
      infoEmpty: 'Total : _MAX_ records',
      infoFiltered: '(filtered : _TOTAL_ records)',
      loadingRecords: 'Loading...',
      zeroRecords: 'No matching records',
    },
    buttons: [
      {
        extend: 'colvis',
        columns: ':not(:eq(0), :eq(1), :eq(2),:eq(3),:eq(-3),:eq(-2), :eq(-1))', // Exclude first three and last columns from the column visibility toggle
      },
    ],

    // info: false,
    columnDefs: [
      { targets: [11, 12, 13, 14, 15, 16], visible: false },
      { targets: [0], visible: false },
      { targets: 1, width: '10%' },
      { targets: 2, width: '10%' },
      { targets: 3, width: '5%' },
      { targets: 4, width: '10%' },
      { targets: 5, width: '20%' },
      { targets: 6, width: '10%' },
      { targets: 7, width: '10%' },
      { targets: 8, width: '10%' },
      { targets: 9, width: '5%' },
      { targets: 10, width: '5%' },
      { targets: 11, width: '5%' },
      { targets: [0, 1, 2, 3, -1], className: 'dt-fixed-column' }, // Ad
    ],
  };
  dtItemTableOptions: any = {
    responsive: true,
    filter: false,
    paging: false,
    scrollCollapse: true,
    scrollY: '200px',
    scrollX: true,
    language: {
      emptyTable: 'No records',
      info: 'Total : _MAX_ records',
      infoEmpty: 'Total : _MAX_ records',
      infoFiltered: '(filtered : _TOTAL_ records)',
      loadingRecords: 'Loading...',
      zeroRecords: 'No matching records',
    },
    order: [],
    columnDefs: [
      { targets: [0], visible: false },
      { targets: 1, width: '10%' },
      { targets: 2, width: '10%' },
      { targets: 3, width: '10%' },
      { targets: 4, width: '20%' },
      { targets: 5, width: '10%' },
      { targets: 6, width: '10%' },
      { targets: 7, width: '5%' },
      { targets: 8, width: '10%' },
      { targets: 9, width: '5%' },
      { targets: 10, width: '5%' },
      { targets: 11, width: '5%' },

      { orderable: false, targets: 0 }, // Disable sorting for column at index 2
      // You can add more columnDefs for other columns as needed
    ],
  };
  defaultGuid = defaultGuid;
  disableSplitButton: boolean = true;
  // subscription$: Subscription[] = [];
  shippingListDetails: any;
  productCode: any;
  lotNumber: any;
  serialNumber: any;
  isPageLoaded: any;
  currentValue: string;
  isCashOrder: boolean = false;
  isExchangeOrder: boolean = false;
  filteredOptions: any;
  filteredValues: { productId: string, lotSerialNos: any[] } | undefined;
  saveSerialNumberDrpValues: any[];
  constructor(
    private title: Title,
    private shippingListService: ShippingListService,
    private shippingApprovalService: ShippingApprovalService,
    private toaster: ToastrService,
    private weightService: MmShippingWeightService,
    private upsLabelService: MmUPSLabelService,
    private shippingStatusService: OrderShippingStatusService,
    public dialog: MatDialog,
    private fb: FormBuilder,
    private datepipe: DatePipe,
    private loaderService: LoaderService,
    private commonService: CommonService,
    private inventorySerialLotNoService: InventorySerialLotNoService,
    private snackBar: MatSnackBar,
    private mmOrderService: MmOrderService
  ) {
    (pdfMake as any).vfs = pdfFonts.pdfMake.vfs;
    this.loaderService.getLoaderState().subscribe((isPageLoaded) => {
      this.isPageLoaded = isPageLoaded;
    });
    //To search the products inisde the products dropdown
    //   this.searchControl.valueChanges.pipe(debounceTime(300), distinctUntilChanged()).subscribe(() => {
    //         this.filterProducts(this.searchproductId);
    // });
  }

  @ViewChildren(DataTableDirective)
  dtElements: QueryList<DataTableDirective>;
  dtTrigger: Subject<any> = new Subject<any>();

  ngOnDestroy(): void {
    this.subscription$.forEach((sub) => {
      sub && sub?.unsubscribe();
    });
    this.dtTrigger.unsubscribe();
  }
  PrintLabel = PrintLabel;
  ngOnInit(): void {
    this.title.setTitle('Qsecure | Shipping List');
    this.isWonaceLogin = this.isWonaceGetSes === 'false';
    this.initializeForms();
    this.loadShippingList();
    this.loadDropdowns();
  }
  //To initialize the Form
  initializeForms() {
    this.shippingListForm = this.fb.group({
      drpLabel: new FormControl(''),
      drpWeight: new FormControl(''),
      drpShippingRate: new FormControl(''),
      txtTracking: new FormControl('', [
        Validators.required,
        noWhitespaceValidator,
      ]),
      txtWidth: new FormControl(''),
      txtLength: new FormControl(''),
      txtHeight: new FormControl(''),
      chkSignatureConfirmation: new FormControl(''),
    });
  }
  isEmpty(value: any): boolean {
    return (
      value === null ||
      value === undefined ||
      value === '' ||
      value === defaultGuid
    );
  }
  //To load the dropdowns
  loadDropdowns() {
    this.loadWeightDropdown();
    this.loadUPSLabelDropdown();
    this.loadShippingStatus();
  }

  //To load the Weight Dropdown
  loadWeightDropdown() {
    const weightList = this.weightService.getAllShippingWeightsList().subscribe(
      (response) => {
        this.weightList = response;
      },
      (err) => {}
    );
    this.subscription$.push(weightList);
  }

  //To load the UPS Label Dropdown
  loadUPSLabelDropdown() {
    const labelList = this.upsLabelService.getAllUPSLabelList().subscribe(
      (response) => {
        this.upsLabelList = response;
        this.shippingListForm.patchValue({
          drpLabel: this.upsLabelList[0]?.description,
        });
      },
      (err) => {}
    );
    this.subscription$.push(labelList);
  }
  //To load the shipping statuses
  loadShippingStatus() {
    const shippingStatus = this.shippingStatusService
      .getList(new PagedAndSortedResultRequestDto())
      .subscribe(
        (response) => {
          this.shippingStatusList = response?.items;
        },
        (err) => {}
      );
    this.subscription$.push(shippingStatus);
  }

  //To load the Shipping List
  loadShippingList() {
    this.isShippingListTableLoaded = false;
    if (this.orderListSubscription) {
      this.orderListSubscription.unsubscribe();
    }
    this.orderListSubscription = this.shippingListService
      .getShippingApprovedList()
      .subscribe(
        (response) => {
          this.isShippingListTableLoaded = true;
          this.shippingList = response ?? [];

          this.shippingList = this.shippingList.map((order) => {
            return {
              ...order,
              suppliesDate: this.commonService.getFormattedDateZone(
                order.suppliesDate
              ),
              backOrderSince: this.commonService.getFormattedDateZone(
                order.backOrderSince
              ),
              createdDate: this.commonService.getFormattedDateTimeZone(
                order.createdDate
              ),
              modifiedDate: this.commonService.getFormattedDateTimeZone(
                order.modifiedDate
              ),
            };
          });
          this.shippingList.length > 0 &&
            this.tblShpSelectedRow(this.shippingList?.[0]);
          if (this.shippingList?.length <= 0) {
            this.loadShippingItem(this.shippingList[0]?.mmOrderId);
            this.shippingListNotesDetails = {
              pAddress: '',
              shippingNotes: '',
              patientNotes: '',
            };
          }
        },
        (err) => {
          this.isShippingListTableLoaded = true;
        }
      );
    this.subscription$.push(this.orderListSubscription);
  }
  //To load the shipping Item Details based on Order Id
  // loadShippingItem(orderId: string) {
  //   this.isShippingItemsTableLoaded = false;
  //   this.isBarCodeErrorMsg =false;
  //   this.isZeroWeightErrorMsg =false;
  //   if (this.ShippingListSubscription) {
  //     this.ShippingListSubscription.unsubscribe();
  //   }
  //   this.ShippingListSubscription = this.shippingListService
  //     .getShippingOrderItemDetailsByGOrderId(orderId)
  //     .subscribe(
  //       (response) => {
  //         this.shippingItemsList = response ?? [];
  //         this.shippingItemsList = this.shippingItemsList?.map((a) => ({
  //           ...a,
  //           isSelected: false,
  //         }));
  //         this.shippingItemsList.forEach(item => {
  //           this.loadSerialLotNumber(item.productId);
  //         });
  //         this.isShippingItemsTableLoaded = true;
  //         this.checkSelectedItems();
  //       },
  //       (err) => {
  //         this.shippingItemsList = [];
  //         this.isShippingItemsTableLoaded = true;
  //       }
  //     );
  //   this.subscription$.push(this.ShippingListSubscription);
  // }

  loadShippingItem(orderId: string) {
    this.isShippingItemsTableLoaded = false;
    this.isBarCodeErrorMsg = false;
    this.isZeroWeightErrorMsg = false;

    if (this.ShippingListSubscription) {
      this.ShippingListSubscription.unsubscribe();
    }

    this.ShippingListSubscription = this.shippingListService
      .getShippingOrderItemDetailsByGOrderId(orderId)
      .pipe(
        mergeMap((shippingItems) => {
          const productIds = shippingItems.map((item) => item.productId);
          return forkJoin(
            shippingItems.map((item) =>
              this.inventorySerialLotNoService
                .getSerialLotNumberByProductIdByGproductId(item.productId)
                .pipe(
                  map((lotSerialNos) => ({
                    productId: item.productId,
                    lotSerialNos,
                  }))
                )
            )
          ).pipe(
            map((lotSerialNoLists) => ({ shippingItems, lotSerialNoLists }))
          );
        })
      )
      .subscribe(
        ({ shippingItems, lotSerialNoLists }) => {
          this.shippingItemsList = shippingItems.map((item) => ({
            ...item,
            isSelected: false,
            lotSerialNos: lotSerialNoLists.find(
              (list) => list.productId === item.productId
            )?.lotSerialNos,
          }));

          this.isShippingItemsTableLoaded = true;
          this.checkSelectedItems();
        },
        (err) => {
          this.shippingItemsList = [];
          this.isShippingItemsTableLoaded = true;
        }
      );

    this.subscription$.push(this.ShippingListSubscription);
  }

  //To Load the Selected Order Items
  tblShpSelectedRow(shippingListDetails: any) {
    this.isCashOrder = shippingListDetails.isCashOrder;
    this.isExchangeOrder = shippingListDetails.isExchangeOrder;
    this.shippingListDetails = shippingListDetails;
    // this.selectedShippingOrderId =
    //   this.selectedShippingOrderId === shippingListDetails.mmOrderId
    //     ? defaultGuid
    //     : shippingListDetails.mmOrderId;
    // this.selectedPatientId =
    //   this.selectedPatientId === shippingListDetails.patientId
    //     ? defaultGuid
    //     : shippingListDetails.patientId;
    this.selectedShippingOrderId = shippingListDetails.mmOrderId;
    this.selectedPatientId = shippingListDetails.patientId;
    // this.selectedShippingOrderId != defaultGuid &&
    this.loadShippingItem(this.selectedShippingOrderId);
    // this.selectedShippingOrderId != defaultGuid &&
    this.loadShippingNotesDetails(this.selectedShippingOrderId);
    this.checkSelectedItems();
    // this.loadSerialLotNumber(shippingListDetails.mmOrderId);
  }
  //To Split the Shipping Items
  splitShippingItems() {
    const selectedItems = this.shippingItemsList
      .filter((a) => a.isSelected == true)
      .map((b) => (b = b.orderItemId));

    const currentDate = new Date();
    this.shippingListService
      .splitOrderByOrderIdAndOrderDateAndOrderItemIds(
        this.selectedShippingOrderId,
        currentDate.toISOString(),
        selectedItems
      )
      .subscribe(
        (response) => {
          this.toaster.success('Order Splitted!');
          this.loadShippingList();
        },
        (err) => {
          this.toaster.error('Something Went Wrong!');
        }
      );
  }

  //To Disable the Split Button based on the Item selection
  checkSelectedItems() {
    const checkTotalCount = this.shippingItemsList?.length;
    const checkSelectedCount = this.shippingItemsList.filter(
      (a) => a.isSelected == true
    )?.length;
    this.disableSplitButton = true;
    if (checkTotalCount > checkSelectedCount && checkSelectedCount != 0) {
      this.disableSplitButton = false;
    } else if (checkSelectedCount === 0) {
      this.disableSplitButton = true;
      // this.toaster.warning('Select Minimum Items!');
    } else {
      this.disableSplitButton = true;
      this.toaster.warning('Keep One Item When Moving');
    }
    // else if (checkSelectedCount === 0) {
    //   this.disableSplitButton = true;
    // } else if (checkTotalCount === checkSelectedCount) {
    //   this.disableSplitButton = true;
    //   this.toaster.warning("Can't split all items!");
    // }
  }
  //Load Shipping Notes Details
  loadShippingNotesDetails(orderId: string) {
    if (this.notesListSubscription) {
      this.notesListSubscription.unsubscribe();
    }

    this.notesListSubscription = this.shippingListService
      .getPatientBalNotesDetailsByOrderId(orderId)
      .subscribe(
        (response) => {
          this.shippingListNotesDetails = response;
        },
        (err) => {
          this.shippingListNotesDetails = null;
        }
      );
    this.subscription$.push(this.notesListSubscription);
  }

  notesReason() {
    const dialogRef = this.dialog.open(NotesReasonComponent, {
      minHeight: '33vh',
      minWidth: '52vw',
      disableClose: true,
      data: {
        patientId: this.shippingListDetails.patientId,
        ticketId: this.shippingListDetails.mmRefereceOrderId,
        notesType: NotesType.shipping,
        orderId: this.selectedShippingOrderId,
        type: 'shipping',
        // type: 'shippingList',
      },
    });
    dialogRef.afterClosed().subscribe((a) => {
      this.loadShippingList();
    });
  }

  //To Disapprove the Shipping
  approveDisapproveShipping(orderId: string, status: boolean) {
    this.shippingApprovalService
      .updateShippingApprovalStatusByGOrderIdAndStatusAndType(
        orderId,
        status,
        shippingTypeService.SHIPPINGLIST,
        ''
      )
      .subscribe(
        (response) => {
          this.loadShippingList();
          !status && this.toaster.success('Shipping Disapproved!');
        },
        (err) => {}
      );
  }

  //Move the Order to Billing
  moveToBilling() {
    this.loaderService.showLoader();
    const trackingNumber = this.shippingListForm.value.txtTracking;

    this.shippingListService
      .processToBillingByGOrderIdAndSTrackingNumber(
        this.selectedShippingOrderId,
        trackingNumber
      )
      .subscribe(
        (response) => {
          this.loadShippingList();
          this.toaster.success('Order Moved to Billing!');
          this.resetForm();
          this.loaderService.hideLoader();
        },
        (err) => {
          this.loaderService.hideLoader();
          const data: HttpErrorResponse = err;
          const message = JSON.parse(data?.error);
          Swal.fire({
            icon: 'info',
            text: message?.error?.message,
          });
        }
      );
  }

  //Allow only numeric values
  onKeyPress(event: KeyboardEvent) {
    const inputElement = event.target as HTMLInputElement;
    const value = inputElement.value;
    const isNumber = /[0-9]/.test(event.key);

    if (!isNumber) {
      if (event.key === '.') {
        if (value.includes('.')) {
          event.preventDefault();
        }
      } else {
        event.preventDefault(); // Prevent the input if the key is not a number
      }
    }
  }

  //Billing Button Validator
  get isBillingButtonEnabled(): boolean {
    // Check both form validity and items list condition
    return (
      this.shippingListForm.valid &&
      this.shippingItemsList
        // .filter((a) => a.productId != '')
        .every(
          (item) =>
            item.barCode?.toString() !== '0' &&
            item.barCode !== null &&
            item.barCode?.toString() !== '' &&
            // item.weight?.toString() !== '0' &&
            item.weight > 0 &&
            item.weight !== null &&
            item.weight?.toString() !== '' &&
            item.serialLotNo?.toString() !== '0' &&
            item.serialLotNo !== null &&
            item.serialLotNo?.toString() !== ''
        )
    );
  }
  resetForm() {
    this.shippingListForm.reset();
  }
  onBarCodeFocusOut(shippingDetails: any, changedValue: string): boolean {
    if (changedValue === this.currentValue) {
      return false;
    }

    this.isSerialLotSaving = true;
    const inputNumber = shippingDetails?.barCode?.trim() ?? '';
    // this.productCode = inputNumber.substring(0, 2);
    this.lotNumber = inputNumber.substring(2, 12);
    this.serialNumber = inputNumber.substring(12);
    shippingDetails.serialLotNo = shippingDetails?.serialLotNo?.trim() ?? '';

    if (shippingDetails.isBarcodeSplit == true) {
      shippingDetails.serialLotNo = this.serialNumber
        ? this.serialNumber + '/' + this.lotNumber
        : shippingDetails.serialLotNo;
      // shippingDetails.productCode = this.productCode
    }
    this.shippingListService
      .updateBarCodeandWeightByInput(shippingDetails)
      .subscribe(
        (response) => {
          shippingDetails.serialLotNo = response.serialLotNo ?? '';
          this.toaster.success('Product Details Updated!');
          this.isSerialLotSaving = false;
        },
        (err) => {
          this.isSerialLotSaving = false;
        }
      );
  }
  getCurrentValue(currentValue: string) {
    this.currentValue = currentValue;
  }
  //To show the Patient Ledger
  viewPatientLedger(patientId: string, chartNo: string) {
    this.dialog.open(ViewPatientLedgerComponent, {
      width: '100vw',
      height: '100vh',
      maxWidth: '100vw',
      maxHeight: '100vh',
      panelClass: 'fullscreen-dialog-panel',
      disableClose: true,
      data: {
        patientId: patientId,
        chartNo: chartNo,
      },
    });
    this.dialog.afterAllClosed.subscribe((resposne) => {});
  }

  //To open the Notes List Popup
  openViewNotesPopup(patientId: string, defaultFaxId: string, docId: string) {
    const config: MatDialogConfig = {
      disableClose: true,
      width: '70vw',
      maxWidth: '70vw',
      data: {
        patientId: patientId,
        pageType: 'Patient Note', // this.pageType, //Notes Table Popup Page
        isAddVisible: false,
        defaultFaxId: defaultFaxId,
        docId: docId,
      },
    };
    const dialogRef = this.dialog.open(PatientCommonNotesComponent, config);

    dialogRef.afterClosed().subscribe(
      () => {
        //this.getReminderTableData();
      },
      (err: HttpErrorResponse) => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      }
    );
  }

  //To Add Notes
  openAddNotesPopUp(
    defaultFaxId: string,
    patientId: string,
    docId: string,
    isCallNote: boolean,
    chartNo: string
  ) {
    const config: MatDialogConfig = {
      disableClose: true,
      minHeight: '30vh',
      minWidth: '50vw',
      data: {
        // claimId: '00000000-0000-0000-0000-000000000000',
        noteType: 'patient', // 'Order Note',
        notesId: '00000000-0000-0000-0000-000000000000',
        // orderId: '0b50be04-6c75-92d3-97c6-3a0e17ee6a18',
        patientId: patientId,
        defaultFaxId: defaultFaxId,
        isCallNote: isCallNote,
        isPending: false,
        chartNo: chartNo,
        docId: docId,
        inboundStatusShortCodeId: defaultGuid,
        addnotes: false,
      },
    };
    const dialogRef = this.dialog.open(AddNoteTabComponent, config);

    const closeDialog = dialogRef.afterClosed().subscribe(
      () => {
        // this.getReminderTableData();
      },
      (err) => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      }
    );
    this.subscription$.push(closeDialog);
  }
  checkListModel(data) {
    const dialogRef = this.dialog.open(DocumentCheckListComponent, {
      // height: '75%',
      width: '80%',
      disableClose: true,
      data: {
        // Add any data you want to pass to the dialog here
        // For example, you can pass patientId and documentId

        documentId: data.docId,
        faxId: data.defaultFaxId,
      },
    });

    // Subscribe to the closed event
    dialogRef.afterClosed().subscribe(() => {
      // Call the addNotes function directly
      dialogRef.componentInstance.addNotes();
    });
  }

  /* #region PRINT TICKET */

  //Method to call the Print Ticket Data Details
  getPrintTicketDetails() {
    const shippingTicketDetails = this.shippingListService
      .orderTicketDetailsV1ByOrderId(this.selectedShippingOrderId)
      .subscribe(
        (response) => {
          this.printTicketData = response ?? null;
          this.getPrintTicket(this.printTicketData);
        },
        (err) => {}
      );

    this.subscription$.push(shippingTicketDetails);
  }
  getPrintTicket(printTicketData: PrintTicketDetails) {
    const tbodyStyle = {
      fontSize: 10,
      margin: [0, 2],
    };

    this.printTicketData.productDetailsDTO =
      this.printTicketData?.productDetailsDTO?.map((a) => ({
        ...a,
        style: tbodyStyle,
      }));

    let docDefinition = {
      /* #region HEADER */
      header: function (currentPage, pageCount, pageSize) {
        return {
          columns: [
            // {
            //   image: localStorage.getItem('LogoDataUrl'),
            //   width: 100, // Adjust the width as needed
            //   alignment: 'left', // Adjust the alignment as needed
            // },
            // {
            //   width: '10%',
            //   text:{

            //       text: '844498797',
            //       fontSize: 18,
            //       bold: true,
            //       alignment: 'center',
            //       color: '#000000',

            //   }
            // },
            {
              width: '80%',
              text: [
                { text: '\n' },
                {
                  // margin:[15,0,0,0],
                  text: printTicketData?.mmRefOrderId ?? '',
                  fontSize: 16,
                  bold: true,
                  alignment: 'left',
                  color: '#000000',
                },
                {
                  text: '\t\t\t\t\t\t\t\t\t\tConfirm receipt of shipment online at www.monitormedical.com\n\t\t\t\t',
                  fontSize: 10,
                  bold: true,
                  alignment: 'center',
                  color: '#91ab5b',
                },
                {
                  text: '\t\t\t\t\t\tPATIENT CARE DELIVERY TICKET',
                  fontSize: 16,
                  bold: true,
                  alignment: 'center',
                  color: '#800000',
                },
              ],
              margin: [15, 0, 0, 0],
            },
            {
              image: localStorage.getItem('LogoDataUrl'),
              width: 100, // Adjust the width as needed
              // alignment: 'right', // Adjust the alignment as needed
              margin: [0, 10, 0, 0],
            },
          ],
        };
      },

      /* #endregion */

      /* #region FOOTER */
      footer: (currentPage, pageCount, pageSize) => {
        return {
          canvas: [
            {
              type: 'line',
              x1: 0,
              y1: 0,
              x2: 565,
              y2: 0,
              lineWidth: 3,
              lineColor: '#800000',
            },
          ],
          columns: [
            {
              width: '70%',
              text: [
                {
                  text: 'Patient Signature:',
                  fontSize: 10,
                  italics: true,
                },
                {
                  text: '_____________________________________________________________________\n',
                  fontSize: 10,
                  italics: true,
                },
                {
                  text: 'Monitor Medical, Inc. \t',
                  fontSize: 16,
                  italics: true,
                  bold: true,
                },
                {
                  text: 'The C-PAP Co. \t',
                  fontSize: 12,
                  italics: true,
                },
                {
                  text: 'Toll Free: 866-634-0177 \n',
                  fontSize: 10,
                  italics: true,
                  bold: true,
                },
                {
                  text: printTicketData?.mmRefOrderId ?? '',
                  fontSize: 14,
                  italics: true,
                  alignment: 'right',
                  bold: true,
                  color: '#000000',
                },
              ],
              // ,
              bold: true,
              color: '#800000',
            },
            {
              width: '30%',
              text: [
                // {
                //   text: '_ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ \n\n',
                //   fontSize: 15,
                //   bold: true,
                //   italics: true,
                //   alignment: 'left',
                // },
                { text: 'Date:', fontSize: 10, italics: true },
                { text: '___________________________\n' },
                {
                  text:
                    this.datepipe.transform(new Date(), 'fullDate') + '\n\n',
                  alignment: 'center',
                  fontSize: 8,
                  italics: true,
                  bold: false,
                },
                {
                  text:
                    printTicketData.shippingMethodName +
                    '\t' +
                    printTicketData.location,
                  fontSize: 8,
                  italics: true,
                  alignment: 'center',
                  color: '#000000',
                },
              ],
              fontSize: 12,
              bold: true,
              alignment: 'left',
              color: '#800000',
            },

            // {
            //   text:
            //     printTicketData.shippingMethodName +
            //     ' ' +
            //     printTicketData.location,
            //   fontSize: 8,
            //   italics: true,
            // },
          ],
          margin: [15, 0, 15, 0],
          alignment: 'left',
          // fontSize: 10,
        };
      },
      /*#endregion*/

      /* #region CONTENT */

      content: [
        /* #region Section 1 */
        {
          canvas: [
            {
              type: 'line',
              x1: 0,
              y1: 0,
              x2: 565,
              y2: 0,
              lineWidth: 2,
              lineColor: '#800000',
            },
          ],
        },
        //Row 1
        {
          columns: [
            {
              width: '33%',
              text: 'Order No:\t' + printTicketData.mmRefOrderId ?? '',
              bold: true,
              fontSize: 10,
            },
            {
              width: '33%',
              text: 'INS:\t' + printTicketData.insuranceName ?? '',
              fontSize: 10,
            },
            {
              width: '33%',
              text:
                'H:\t' + this.formatPhoneNumber(printTicketData?.homePhone) ??
                '',
              fontSize: 10,
            },
          ],
          margin: [0, 10, 0, 3], // [left, top, right, bottom]
        },
        //Row 2
        {
          columns: [
            {
              width: '33%',
              text: 'Account No:\t'+ printTicketData.chartNo ?? '',
              bold: true,
              fontSize: 10,
            },
            {
              width: '33%',
              text:
                'DOB:\t' +
                (printTicketData?.dateOfBirth != null
                  ? this.datepipe.transform(
                      printTicketData?.dateOfBirth,
                      'MM/dd/yyyy'
                    )
                  : ''),
              fontSize: 10,
              style: 'columnHeader',
            },
            {
              width: '33%',
              text:
                'W:\t' + this.formatPhoneNumber(printTicketData?.workPhone) ??
                '',
              fontSize: 10,
              style: 'columnHeader',
            },
          ],
          margin: [0, 0, 0, 3],
        },
        //Row 3
        {
          columns: [
            {
              width: '33%',
              text: 'Patient Name:\t' + printTicketData.patientName ?? '',
              fontSize: 10,
            },
            {
              width: '33%',
              text: 'e-mail:\t' + (printTicketData?.emailId ?? ''),
              fontSize: 10,
            },
            {
              width: '33%',
              text:
                'M:\t' + this.formatPhoneNumber(printTicketData?.mobilePhone) ??
                '',
              fontSize: 10,
            },
          ],
          margin: [0, 0, 0, 3],
        },
        //Row 4
        {
          columns: [
            {
              width: '50%',
              text: 'Address:\t' + printTicketData?.fullAddress ?? '',
              fontSize: 10,
            },
          ],
          margin: [0, 0, 0, 10],
        },

        /* #endregion*/
        /* #region Section 2 */
        {
          canvas: [
            {
              type: 'line',
              x1: 0,
              y1: 0,
              x2: 565,
              y2: 0,
              lineWidth: 2,
              lineColor: '#800000',
            },
          ],
        },
        //Row 1
        {
          text:
            'Contact ' + printTicketData?.technicianName + ' at 281-637-2286',
          fontSize: 16,
          bold: true,
          alignment: 'center',
          color: '#800000',
          margin: [0, 5],
        },
        //Row 2
        {
          canvas: [
            {
              type: 'line',
              x1: 0,
              y1: 0,
              x2: 565,
              y2: 0,
              lineWidth: 2,
              lineColor: '#800000',
            },
          ],
        },
        /* #endregion*/
        /* #region Section 3 */
        //Row 1
        {
          width: '33%',
          text: 'Pressure Settings',
          italics: true,
          fontSize: 8,
          margin: [0, 5, 0, 5], // [left, top, right, bottom]
        },
        //Row 2
        {
          table: {
            headerRows: 1,
            widths: [
              '*',
              '*',
              '*',
              '*',
              '*',
              '*',
              '*',
              '*',
              '*',
              '*',
              '*',
              '*',
              '*',
              '*',
              '*',
            ],
            body: [
              [
                {
                  text: 'Min Press',
                  fontSize: 6,
                  alignment: 'center',
                  bold: true,
                },
                {
                  text: 'Max Press',
                  fontSize: 6,
                  alignment: 'center',
                  bold: true,
                },
                {
                  text: 'Setting',
                  fontSize: 6,
                  alignment: 'center',
                  bold: true,
                },
                { text: 'IPAP', fontSize: 6, alignment: 'center', bold: true },
                { text: 'EPAP', fontSize: 6, alignment: 'center', bold: true },
                {
                  text: 'Ramp Press',
                  fontSize: 6,
                  alignment: 'center',
                  bold: true,
                },
                {
                  text: 'Ramp Time',
                  fontSize: 6,
                  alignment: 'center',
                  bold: true,
                },
                {
                  text: 'BiFlex',
                  fontSize: 6,
                  alignment: 'center',
                  bold: true,
                },
                {
                  text: 'Set Press',
                  fontSize: 6,
                  alignment: 'center',
                  bold: true,
                },
                { text: 'CFlex', fontSize: 6, alignment: 'center', bold: true },
                {
                  text: 'RR/BPM',
                  fontSize: 6,
                  alignment: 'center',
                  bold: true,
                },
                {
                  text: 'IPAP Min',
                  fontSize: 6,
                  alignment: 'center',
                  bold: true,
                },
                {
                  text: 'IPAP Max',
                  fontSize: 6,
                  alignment: 'center',
                  bold: true,
                },
                { text: 'SetVt', fontSize: 6, alignment: 'center', bold: true },
                { text: 'Rate', fontSize: 6, alignment: 'center', bold: true },
              ],
              [
                {
                  text:
                    this.printTicketData?.settingsDetails?.minPressure ?? '',
                  fontSize: 6,
                  margin: [0, 5],
                  alignment: 'center',
                },
                {
                  text:
                    this.printTicketData?.settingsDetails?.maxPressure ?? '',
                  fontSize: 6,
                  margin: [0, 5],
                  alignment: 'center',
                },
                {
                  text:
                    this.printTicketData?.settingsDetails?.settlingTime ?? '',
                  fontSize: 6,
                  margin: [0, 5],
                  alignment: 'center',
                },
                {
                  text: this.printTicketData?.settingsDetails?.ipap ?? '',
                  fontSize: 6,
                  margin: [0, 5],
                  alignment: 'center',
                },
                {
                  text: this.printTicketData?.settingsDetails?.epap ?? '',
                  fontSize: 6,
                  margin: [0, 5],
                  alignment: 'center',
                },
                {
                  text:
                    this.printTicketData?.settingsDetails?.rampPressure ?? '',
                  fontSize: 6,
                  alignment: 'center',
                  margin: [0, 5],
                },
                {
                  text: this.printTicketData?.settingsDetails?.rampTime ?? '',
                  fontSize: 6,
                  alignment: 'center',
                  margin: [0, 5],
                },
                {
                  text: this.printTicketData?.settingsDetails?.biFlex ?? '',
                  fontSize: 6,
                  margin: [0, 5],
                  alignment: 'center',
                },
                {
                  text:
                    this.printTicketData?.settingsDetails?.setPressure ?? '',
                  fontSize: 6,
                  margin: [0, 5],
                  alignment: 'center',
                },
                {
                  text: this.printTicketData?.settingsDetails?.cFlex ?? '',
                  fontSize: 6,
                  margin: [0, 5],
                  alignment: 'center',
                },
                {
                  text: this.printTicketData?.settingsDetails?.rrbpm ?? '',
                  fontSize: 6,
                  margin: [0, 5],
                  alignment: 'center',
                },
                {
                  text: this.printTicketData?.settingsDetails?.ipaPmin ?? '',
                  fontSize: 6,
                  margin: [0, 5],
                  alignment: 'center',
                },
                {
                  text: this.printTicketData?.settingsDetails?.ipaPmax ?? '',
                  fontSize: 6,
                  margin: [0, 5],
                  alignment: 'center',
                },
                {
                  text: this.printTicketData?.settingsDetails?.setVT ?? '',
                  fontSize: 6,
                  margin: [0, 5],
                  alignment: 'center',
                },
                {
                  text: this.printTicketData?.settingsDetails?.rate ?? '',
                  fontSize: 6,
                  margin: [0, 5],
                  alignment: 'center',
                },
              ],
            ],
          },
        },
        //Row 3
        {
          columns: [
            {
              width: '33%',
              text: 'Technician: ' + printTicketData?.technicianName ?? '',
              fontSize: 8,
              alignment: 'center',
            },
            {
              width: '33%',
              text: 'Physician: ' + printTicketData?.physicianName ?? '',
              fontSize: 8,
              alignment: 'center',
            },
            {
              width: '33%',
              text:
                'Referral Practice: ' + printTicketData?.referralPractice ?? '',
              fontSize: 8,
              alignment: 'center',
            },
          ],
          margin: [0, 5, 0, 5], // [left, top, right, bottom]
        },
        /* #endregion */

        /*#region Section 4 */
        {
          table: {
            headerRows: 1,
            // widths: ['15%', '5%', '10%', '45%', '10%', '15%'],
            widths: ['10%', '12%', '44%', '10%', '9%', '15%'],
            body: [
              [
                // {
                //   text: 'Location',
                //   bold: true,
                //   fontSize: 10,
                //   color: '#800000',
                // },
                { text: 'Qty', bold: true, fontSize: 10, color: '#800000' },
                {
                  text: 'Product Code',
                  bold: true,
                  fontSize: 10,
                  color: '#800000',
                },
                {
                  text: 'Product Description',
                  bold: true,
                  fontSize: 10,
                  color: '#800000',
                },
                {
                  text: 'HCPC',
                  bold: true,
                  fontSize: 10,
                  color: '#800000',
                },
                // { text: 'Size', bold: true, fontSize: 10, color: '#800000' },
                {
                  text: printTicketData?.isOrderComplete ? 'Allowable' : 'Exch',
                  bold: true,
                  fontSize: 10,
                  color: '#800000',
                },
                {
                  text: 'Serial #/Lot #',
                  bold: true,
                  fontSize: 10,
                  color: '#800000',
                },
              ],
              ...this.printTicketData?.productDetailsDTO?.map((item) => [
                // item.location,
                item.quantity,
                item.productCode,
                item.productName,
                // item.sizeName,
                item.hcpc,
                printTicketData?.isOrderComplete
                  ? item.allowables
                  : {
                      image:
                        'data:image/png;base64,' +
                        this.getSquareImageBase64(item.exchange),
                      width: 10,
                      height: 10,
                      alignment: 'center',
                    },
                item.serialLotNo,
              ]),
            ],
          },
          style: {
            fontSize: 10,
            margin: [0, 2],
          },
          margin: [0, 5, 0, 0],
        },
        /* #endregion */

        /* #region Section 5 */
        {
          columns: [
            {
              width: '60%',
              text: [
                {
                  text: '\nComments to Patients:\n',
                  color: '#800000',
                  fontSize: 12,
                },
                {
                  text: printTicketData?.commentsToPatient ?? '',
                  fontSize: 8,
                },
              ],
              fontSize: 14,
              bold: true,
              alignment: 'left',
            },

            {
              width: '40%',
              table: {
                headerRows: 1,
                // widths: ['80%', '20%'],
                widths: ['72%', '28%'],
                body: [
                  [
                    {
                      text: 'Est Allowable:',
                      fontSize: 10,
                      margin: [0, 0],
                      alignment: 'right',
                      border: [false, false, false, false],
                    },
                    {
                      text: '$' + printTicketData.estAllowable ?? '0',
                      fontSize: 10,
                      margin: [0, 0],
                      border: [false, false, false, false],
                      alignment: 'left',
                    },
                  ],
                  [
                    {
                      text: 'Est Copay:',
                      fontSize: 10,
                      margin: [0, 0],
                      alignment: 'right',
                      border: [false, false, false, false],
                    },
                    {
                      text: '$' + printTicketData.estCopay ?? '0',
                      fontSize: 10,
                      margin: [0, 0],
                      alignment: 'left',
                      border: [false, false, false, false],
                    },
                  ],
                  [
                    {
                      text: 'Total Accessory:',
                      fontSize: 10,
                      margin: [0, 0],
                      alignment: 'right',
                      border: [false, false, false, false],
                    },
                    {
                      text: '$' + printTicketData.totalAccessory ?? '0',
                      fontSize: 10,
                      margin: [0, 0],
                      alignment: 'left',
                      border: [false, false, false, false],
                    },
                  ],
                  [
                    {
                      text: 'Shipping Fee:',
                      fontSize: 10,
                      margin: [0, 0],
                      alignment: 'right',
                      border: [false, false, false, true],
                    },
                    {
                      text: '$' + printTicketData.shippingFee ?? '0',
                      fontSize: 10,
                      margin: [0, 0],
                      alignment: 'left',
                      border: [false, false, false, true],
                    },
                  ],

                  [
                    {
                      text: 'Est Amount Due:',
                      fontSize: 10,
                      margin: [0, 0],
                      alignment: 'right',
                      border: [false, false, false, false],
                    },
                    {
                      text: '$' + printTicketData.estAmountDue ?? '0',
                      fontSize: 10,
                      margin: [0, 0],
                      alignment: 'left',
                      border: [false, false, false, false],
                    },
                  ],
                  [
                    {
                      text: 'Amount Paid:',
                      fontSize: 10,
                      margin: [0, 0],
                      alignment: 'right',
                      border: [false, false, false, true],
                    },
                    {
                      text: '$' + printTicketData.amountPaid ?? '0',
                      fontSize: 10,
                      margin: [0, 0],
                      alignment: 'left',
                      border: [false, false, false, true],
                    },
                  ],
                  [
                    {
                      text: 'Balance:',
                      fontSize: 10,
                      margin: [0, 0],
                      alignment: 'right',
                      border: [false, false, false, false],
                    },
                    {
                      text: '$' + printTicketData.patientBalance ?? '0',
                      fontSize: 10,
                      margin: [0, 0],
                      alignment: 'left',
                      border: [false, false, false, false],
                    },
                  ],
                ],
              },
              layout: {
                hLineWidth: function (i, node) {
                  return i === 4 || i === 6 ? 1 : 0; // Thickness of the dotted line for the second row bottom border
                },
                hLineColor: function (i, node) {
                  return '#800000';
                },
                hLineStyle: function (i, node) {
                  if (i === 4 || i === 6) {
                    return { dash: { length: 5, space: 5 } }; // Dotted style for the second row bottom border
                  }
                  return null;
                },
              },
            },
          ],
        },
        /* #endregion*/
      ],
      /* #endregion */

      // pageMargins: [15, 50, 15, 40],
      pageMargins: [15, 60, 15, 60],
      defaultStyle: {
        font: 'Roboto',
      },
    };

    this.displayPdf(docDefinition, 'Ticket');
  }

  private getSquareImageBase64(excahngeItem): string {
    if (excahngeItem === true) {
      // Convert the image to Base64 format (using a base64 string or FileReader)
      const canvas = document.createElement('canvas');
      canvas.width = 10;
      canvas.height = 10;
      const ctx = canvas.getContext('2d');

      // Draw outer border with light sea green color
      ctx.fillStyle = '#000000'; // Light Sea Green color
      ctx.fillRect(0, 0, 12, 12); // Fill entire canvas with light sea green

      // Draw inner white square (to create border effect)
      ctx.fillStyle = '#FFFFFF'; // White color
      ctx.fillRect(1, 1, 8, 8); // Leave 1 pixel border around the canvas

      // Draw check mark (using a simple path)
      ctx.strokeStyle = '#20B2AA'; // Black color
      ctx.beginPath();
      ctx.moveTo(2, 4);
      ctx.lineTo(4, 6);
      ctx.lineTo(8, 2);
      ctx.stroke();

      return canvas.toDataURL('image/png').split(',')[1];
    } else {
      const canvas = document.createElement('canvas');
      canvas.width = 10;
      canvas.height = 10;
      const ctx = canvas.getContext('2d');
      ctx.fillStyle = '#000000'; // Border color
      ctx.fillRect(0, 0, 12, 12); // Fill entire canvas with black

      // Draw inner white square (to create border effect)
      ctx.fillStyle = '#FFFFFF'; // Square color
      ctx.fillRect(1, 1, 8, 8); // Leave 1 pixel border around the canvas
      return canvas.toDataURL('image/png').split(',')[1];
    }
  }
  // private getSquareImageBase64(): string {
  //   // Convert the image to Base64 format (using a base64 string or FileReader)
  //   const canvas = document.createElement('canvas');
  //   canvas.width = 10;
  //   canvas.height = 10;
  //   const ctx = canvas.getContext('2d');
  //   ctx.fillStyle = '#000000'; // Border color
  //   ctx.fillRect(0, 0, 12, 12); // Fill entire canvas with black

  //   // Draw inner white square (to create border effect)
  //   ctx.fillStyle = '#FFFFFF'; // Square color
  //   ctx.fillRect(1, 1, 8, 8); // Leave 1 pixel border around the canvas
  //   return canvas.toDataURL('image/png').split(',')[1];
  // }
  //Method to display the PDF Content
  displayPdf(docDefinition: any, filename: string): void {
    try {
      const pdfMake = window['pdfMake'];
      const pdfDocGenerator = pdfMake.createPdf(docDefinition); //.download(filename);

      // Open PDF in a new tab
      pdfDocGenerator.getBlob((blob: Blob) => {
        const dataUri = URL.createObjectURL(blob);
        window.open(dataUri, '_blank');
        URL.revokeObjectURL(dataUri);
      });

      // Optionally, you can add print and download buttons in your HTML
      // and trigger these actions using pdfMake functions:
      // pdfMake.createPdf(docDefinition).print();
      // pdfMake.createPdf(docDefinition).open();
    } catch (error) {
      Swal.fire({
        icon: 'error',
        text: 'Error creating PDF',
      });
    }
  }
  private formatPhoneNumber(value: string): string {
    if (value === null) return '';
    const phoneNumberRegex = /^(\d{3})(\d{3})(\d{4})$/;
    const matches = phoneNumberRegex.exec(value);

    if (!matches) return value;

    // Format the phone number with hyphens
    const formattedNumber = `${matches[1]}-${matches[2]}-${matches[3]}`;
    return formattedNumber;
  }
  /* #endregion */

  /* #region Generate Shipping Label XML */

  generateDazzleXML(labelType: PrintLabel, isReturnLabel: boolean) {
    if (
      this.shippingListForm.value.drpWeight !== '' &&
      this.shippingListForm.value.drpWeight !== null &&
      this.shippingListForm.value.drpWeight !== undefined
    ) {
      if (
        labelType === PrintLabel.UPSLabel &&
        (this.shippingListForm.value.drpLabel === '' ||
          (this.shippingListForm.value.drpLabel === null &&
            this.shippingListForm.value.drpLabel === undefined))
      ) {
        this.toaster.error('UPS Label is Required');
      } else {
        let input: DazzlePrintingLabelParamsDto = {
          orderId: this.selectedShippingOrderId,
          printLabel: labelType,
          upsLabel: this.shippingListForm.value.drpLabel ?? '',
          isReturnLabel: isReturnLabel,
          weight: this.shippingListForm.value.drpWeight,
          width: this.shippingListForm.value.txtWidth,
          length: this.shippingListForm.value.txtLength,
          height: this.shippingListForm.value.txtHeight,
          signatureConfirmation:
            (this.shippingListForm.value.chkSignatureConfirmation === ''
              ? false
              : this.shippingListForm.value.chkSignatureConfirmation) ?? false,
        };
        const generateXML = this.shippingListService
          .generateDazzleShippingLabelXMLByPrintingParamsDto(input)
          .subscribe(
            (response) => {
              let xmlBase64String = 'data:application/xml;base64,' + response;

              const downloadLink = document.createElement('a');
              const fileName = 'Shipment.xml';

              downloadLink.href = xmlBase64String;
              downloadLink.download = fileName;
              downloadLink.click();
              this.toaster.success('Label Generated Successfully');
            },
            (err) => {
              this.toaster.error('Something Went Wrong!');
            }
          );
        this.subscription$.push(generateXML);
      }
    } else {
      this.toaster.error('Weight is a Required');
    }
  }
  getFormattedDate(date: any): string {
    return date
      ? new Date(date).toLocaleString('en-US', {
          month: '2-digit',
          day: '2-digit',
          year: 'numeric',
        })
      : '';
  }
  onRowDoubleClickV1(textToCopy) {
    this.copyTextToClipboard(textToCopy);
    this.showNotification('Text copied: ' + textToCopy);
  }

  showNotification(message: string): void {
    this.snackBar.open(message, 'Close', {
      duration: 3000, // Duration in milliseconds
      horizontalPosition: 'end', // Positioning the notification horizontally
      verticalPosition: 'bottom', // Positioning the notification vertically
    });
  }

  copyTextToClipboard(text: string): void {
    const textArea = document.createElement('textarea');
    textArea.value = text;
    document.body.appendChild(textArea);
    textArea.select();
    document.execCommand('Copy');
    textArea.remove();
  }
  /* #endregion */

  //To Open the Order History Popup
  getOrderHistory(
    orderId: string,
    mmOrderId: string,
    chartId: string,
    patientName: string
  ) {
    const config: MatDialogConfig = {
      height: '70vh',
      width: '40vw',
      disableClose: true,
      data: {
        orderId: orderId,
        mmOrderId: mmOrderId,
        patientName: patientName,
        chartId: chartId,
      },
    };
    const dialogRef = this.dialog.open(OrderHistoryModalComponent, config);
  }
  trackingIdHyperLink() {
    this.hyperLinkshow = false;
  }
  editEnableDisable() {
    if (!this.hyperLinkshow) {
      this.hyperLinkshow = true;
    }
  }
  isZeroWeightErrorMsg: any = false;
  preventZeroAtBeginning(productId: string, event) {
    this.productIdWeight = productId;
    if (event.target.value !== '') {
      // if (event.target.value.startsWith('0') && event.target.value.length > 1) {
      if (event.target.value <= 0) {
        this.isZeroWeightErrorMsg = true;
      } else {
        this.isZeroWeightErrorMsg = false;
      }
    } else {
      this.isZeroWeightErrorMsg = true;
    }
  }
  isBarCodeErrorMsg: boolean = false;
  validateInput(productId: string, input: NgModel) {
    this.productIdBar = productId;
    const value = input.value;
    if (value && value.trim() !== value) {
      input.control.setValue(value.trim());
    }
    if (value !== '') {
      const zeroRegex = /^0+$/;
      if (zeroRegex.test(value)) {
        this.isBarCodeErrorMsg = true;
      } else {
        this.isBarCodeErrorMsg = false;
      }
    } else {
      this.isBarCodeErrorMsg = true;
    }
  }

// Method To Search the Serial Lot Number in Dropdown
filterProducts(productId: string): void {
  this.filteredValues = this.shippingItemsList.find(
      (product) => product.productId === productId
  );
  if (this.filteredValues) {
      const searchValue = this.searchControl.value.toLowerCase();
      if (searchValue) {
          this.filteredValues.lotSerialNos = this.filteredValues.lotSerialNos.filter((option) =>
              option.lotSerialNo.toLowerCase().includes(searchValue.toLowerCase())
          );
      } else {
          this.filteredValues.lotSerialNos = [...this.saveSerialNumberDrpValues];
      }
  }
}
// Serach Serial Lot Number in Dropdown
isSerailaDrpOpen = false;
onSerialNoDropdownOpenChange(isOpen: boolean, productId: string): void {
    this.isSerailaDrpOpen = isOpen;
    this.filteredValues = this.shippingItemsList.find(
        (product) => product.productId === productId
    );

    if (this.filteredValues) {
        this.saveSerialNumberDrpValues = [...this.filteredValues.lotSerialNos];
    }
}

//After Close Seria Lot Number Dropdown
onSerialNoDropdownClose(productId: string): void {
    const serachIndex = this.shippingItemsList.findIndex(
        (product) => product.productId === productId
    );

    if (serachIndex !== -1 && this.saveSerialNumberDrpValues) {
        this.shippingItemsList[serachIndex].lotSerialNos = [...this.saveSerialNumberDrpValues];
    }
}

  //Load Serial Lot Number Drop Down
  //  loadSerialLotNumber(mmOrderId:string) {
  //   const seriallotNoList = this.inventorySerialLotNoService.getSerialLotNumberByOrderIdByGorderId(mmOrderId).subscribe(
  //     (response) => {
  //       this.ltSerialLotNo = response.flat().map(serial => serial.lotSerialNo);
  //       this.filteredOptions = [...this.ltSerialLotNo];
  //     },
  //     (err) => {}
  //   );
  //   this.subscription$.push(seriallotNoList);
  // }
  loadSerialLotNumber(productId: string) {
    const seriallotNoList = this.inventorySerialLotNoService
      .getSerialLotNumberByProductIdByGproductId(productId)
      .subscribe(
        (response) => {
          this.ltSerialLotNo = response;
          this.filteredOptions = [...this.ltSerialLotNo];
        },
        (err) => {}
      );
    this.subscription$.push(seriallotNoList);
  }
}
export const testArray = {
  address: '10472 GORENFLO RD APT 312',
  city: "D'Iberville",
  state: 'MS',
  country: 'US',
  county: '',
  postalCode: '39540',
  phone: '2282622164',
  fax: '',
  mobile: '',
  zone: null,
  nameLine: null,
  description: null,
  emailId: null,
  allowable: 0,
  estDueAmount: 0,
  shippingFee: 0,
  accessory: 66,
  pastDueBalance: 0,
  fullName: 'PATRICIA BOLEN',
  dateOfBirth: '1958-01-27T10:30:00+05:30',
  productDetailsDTO: [
    {
      productCode: '121',
      productDescription: 'O2 Concentrator bag clear 20x18x26',
      hcpcCode: null,
      binLocation: 'E121',
      charge: 0,
      allowables: 0,
      serialLotNo: '4587957e-b52c-9ab5-326c-3a0f6b22f7b6',
      qty: 1,
    },
  ],
};
