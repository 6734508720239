import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-edit-claim-info',
  templateUrl: './edit-claim-info.component.html',
  styleUrls: ['./edit-claim-info.component.scss'],
})
export class EditClaimInfoComponent implements OnInit {
  procedureDataArray = procedureDataArray;
  dtClaimInfoTableOptions: any = {
    responsive: true,
    order: [],
    paging: true,
    pageLength: 5,
    lengthMenu: [5, 20, 50, 100, 200],
  };

  @ViewChild('myDialog') myDialogTemplate!: TemplateRef<any>;

  dialogRef: MatDialogRef<any> | null = null;

  constructor(private dialog: MatDialog) {}

  ngOnInit() {}
  openDialog(): void {
    this.dialogRef = this.dialog.open(this.myDialogTemplate, {
      width: '400px',
      // Add any other configuration options for the dialog
    });

    // Subscribe to the afterClosed() event to handle dialog closure
    this.dialogRef.afterClosed().subscribe((result) => {

      // You can handle the result here if needed
    });
  }
  closeDialog(): void {
    if (this.dialogRef) {
      this.dialogRef.close();
    }
  }
}

const procedureDataArray: any[] = [
  {
    code: 'P001',
    m1: 'M1 Value',
    m2: 'M2 Value',
    m3: 'M3 Value',
    m4: 'M4 Value',
    pos: 'POS Value',
    description: 'Procedure 1',
    provider: 'Dr. Smith',
    fee: 150.0,
    units: 2,
    plan: 'Plan A',
    status: 0,
    holdReason: 1,
    holdNotes: '',
  },
  {
    code: 'P002',
    m1: 'M1 Value',
    m2: 'M2 Value',
    m3: 'M3 Value',
    m4: 'M4 Value',
    pos: 'POS Value',
    description: 'Procedure 2',
    provider: 'Dr. Johnson',
    fee: 200.0,
    units: 1,
    plan: 'Plan B',
    status: 0,
    holdReason: 1,
    holdNotes: "Patient's records are pending",
  },
  {
    code: 'P003',
    m1: 'M1 Value',
    m2: 'M2 Value',
    m3: 'M3 Value',
    m4: 'M4 Value',
    pos: 'POS Value',
    description: 'Procedure 3',
    provider: 'Dr. Williams',
    fee: 120.0,
    units: 3,
    plan: 'Plan C',
    status: 0,
    holdReason: 0,
    holdNotes: 'Adjust billing code and resubmit',
  },
  // Add more objects for additional data
];
