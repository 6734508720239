import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Title } from '@angular/platform-browser';
import { Subscription } from 'rxjs';
import { debounceTime, filter, finalize, map, startWith, switchMap, tap } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { DocumentService } from '../patient-proxy/patient/document.service';
import { SearchDocumentFollowUpReport } from '../patient-proxy/patient/dto/models';
import { Status } from '../patient-proxy/patient/enumeration-data';

@Component({
  selector: 'app-document-followup',
  templateUrl: './document-followup.component.html',
  styleUrls: ['./document-followup.component.scss']
})
export class DocumentFollowupComponent implements OnInit {
  claimTableData: chequeList[] = [];
  dataSource: MatTableDataSource<any> = new MatTableDataSource<any>([]);
  @ViewChild(MatSort, { static: false }) sort: MatSort = new MatSort();
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  public arrDisplayedColumns: string[] = ["patientId", "patientName", "docStatus", "documentType", "documentExpiryDate", "documentEndDate", "fileName", "doctor",];
  isLoading: boolean = false;
  subscription$: Subscription[] = [];
  docReportForm: FormGroup;
  constructor(
    private formbuilder: FormBuilder,
    private titleService: Title,
    private documentService: DocumentService
  ) { }
  ngOnInit(): void {
    this.titleService.setTitle('Qsecure | Document Follow Up Report ');
    this.docReportForm = this.formbuilder.group({
      fileName: new FormControl(""),
      patientName: new FormControl(""),
      patientId: new FormControl(""),
      doctor: new FormControl(""),
      documentType: new FormControl(""),
      expirationDate: new FormControl(""),
      documentExpiryDate: new FormControl(""),
      documentEndDate: new FormControl(""),
      docStatus: new FormControl(""),
    })
    //!Document-Follow-Up-Reports Search
    const valueChanges = this.docReportForm.valueChanges.pipe(
      startWith({
        documentEndDate: "",
        fileName: "",
        documentType: "",
        patientName: "",
        patientId: "",
        documentExpiryDate: "",
        docStatus: Status.Active,
        doctor: "",
      }),
      tap(x => { this.isLoading = true; this.setTableData([]); }),
      filter(e => e && this.docReportForm?.valid),
      debounceTime(300),
      map(response => {
        const expiryDate: string = (response.documentEndDate ?? "") === "" ? "" : new Date(response.documentEndDate).toJSON() ?? "";
        const expirationStatus: string = String(response?.docStatus)?.trim()?.toLowerCase() ?? "";
        const oSearchClaim: SearchDocumentFollowUpReport = {
          dtDocumentExpiryDate: expiryDate?.trim() ?? "",
          sFileName: String(response?.fileName)?.trim()?.toLowerCase() ?? "",
          sDocumentType: String(response?.documentType)?.trim()?.toLowerCase() ?? "",
          patientName: String(response?.patientName)?.trim()?.toLowerCase() ?? "",
          defaultpatientId: String(response?.patientId)?.trim()?.toLowerCase() ?? "",
          expirationDays: String(response?.documentExpiryDate)?.trim()?.toLowerCase() ?? "",
          expirationStatus: expirationStatus,
          doctor: String(response?.doctor)?.trim()?.toLowerCase() ?? "",
          isStatusNeed: expirationStatus === Status.Active.toLowerCase()
        };
        return oSearchClaim;
      }),
      switchMap((value: SearchDocumentFollowUpReport) => this.documentService.searchPatientDocumentsByInput(value)),
      finalize(() => {
        this.isLoading = false;
      }),
      tap(x => { this.isLoading = false; })
    ).subscribe(response => {
      this.setTableData(response?.reverse() ?? [])
      this.isLoading = false;
    }, (err: HttpErrorResponse) => {
      this.isLoading = false;
      this.setTableData([])
      const data: HttpErrorResponse = err;
      Swal.fire({ icon: 'info', text: data?.error?.error?.message, });
    });
    this.subscription$?.push(valueChanges);

  }

  //! get Document-Follow-Up-Reports
  getTableData() {
    const input: SearchDocumentFollowUpReport =
    {
      dtDocumentExpiryDate: "",
      sFileName: "",
      sDocumentType: "",
      patientName: "",
      defaultpatientId: "",
      expirationDays: "",
      expirationStatus: "",
      doctor: "",
      isStatusNeed: true
    };
    this.isLoading = true;
    this.documentService.searchPatientDocumentsByInput(input).subscribe(response => {
      this.isLoading = false;
      this.setTableData(response);
    }, err => {
      this.isLoading = false;
    })
  }
  setTableData(claims: any[]) {
    this.claimTableData = claims.map(x => {
      x.documentExpiryDate = x?.documentExpiryDate?.days;
      x.fileName = String(x?.fileName)?.replace( new RegExp('(.pdf)', 'gim'), `\ `);
      return x;
    });
    this.dataSource = new MatTableDataSource(this.claimTableData);
    this.sort.disableClear = true;
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }
  ngOnDestroy(): void {
    this.subscription$.forEach((sub) => {
      sub && sub?.unsubscribe();
    });
  }
}

export interface chequeList {
  chequeNumber: string, claimNumber: string, checkAmount: string, patientId: string,
  claimPostedDate: string, patientName: string, billedAmount: string,
  currentBalance: string, claimServiceDate: string, claimId: string, defaultPatientId: string,
}
