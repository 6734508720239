<br />
<div class="card card-body">
    <form [formGroup]="scheduleForm">
        <div class="row">
            <div class="col-4">
                <h4 class="customThemeClass mar-top"><b>General Details</b></h4>
                <div class="row">
                    <mat-form-field class="col-12">
                        <mat-label>Schedule Type<span class="asterisk">*</span></mat-label>
                        <mat-select maxlength="40" formControlName="drpScheduleType" [(value)]="strScheduleType">
                            <mat-option *ngFor="let scheduleType of drpScheduleType" [value]="scheduleType">
                                {{ scheduleType }}
                            </mat-option>
                        </mat-select>

                    </mat-form-field>
                    <ng-container *ngIf="scheduleForm?.value?.drpScheduleType!=='Monthly'">
                        <mat-form-field class="col-12">
                            <mat-label>Start Date <span class="asterisk">*</span>
                            </mat-label>
                            <input maxlength="40" formControlName="txtScheduledDate" matInput autocomplete="new-txtScheduledDate"
                                [min]="scheduledMinDate" [matDatepicker]="txtScheduledDate"
                                placeholder="(MM/DD/YYYY)" />
                            <mat-datepicker-toggle matSuffix [for]="txtScheduledDate"></mat-datepicker-toggle>
                            <mat-datepicker #txtScheduledDate></mat-datepicker>
                            <mat-error *ngIf="
scheduleForm?.get('txtScheduledDate')?.touched &&
scheduleForm?.get('txtScheduledDate')?.errors
">
                                Enter Valid Date!
                            </mat-error>
                            <mat-error *ngIf="
                            scheduledMinDate >= scheduleForm?.get('txtScheduledDate')?.value &&
!(
  scheduleForm?.get('txtScheduledDate')?.touched &&
  scheduleForm?.get('txtScheduledDate')?.errors?.required
)
">
                                Enter Future Dates Only!
                            </mat-error>
                            <mat-error *ngIf="
scheduleForm?.get('Delivery.txtScheduledDate')?.touched &&
scheduleForm?.get('Delivery.txtScheduledDate')?.errors?.required
">
                                Scheduled Date is a required field!
                            </mat-error>
                        </mat-form-field>
                    </ng-container>


                    <mat-form-field class="col-12">
                        <mat-label>End Period<span class="asterisk">*</span></mat-label>
                        <input maxlength="15" autocomplete="new-txtEndPeriod" formControlName="txtEndPeriod" pattern="[0-9-]*"
                            matInput type="text" />
                    </mat-form-field>
                    <div class="col-12">
                        <br />
                        <mat-checkbox formControlName="chkDisabled">Disabled</mat-checkbox>
                    </div>
                </div>
            </div>
            <div *ngIf="scheduleForm?.value?.drpScheduleType==='Monthly'" class="col-8">
                <h4 *ngIf="scheduleForm?.value?.drpScheduleType==='Monthly'" class="customThemeClass mar-top">
                    <b>Days Calcaution</b>
                </h4>
                <div *ngIf="scheduleForm?.value?.drpScheduleType==='Monthly'" class="row">
                    <mat-form-field class="col-3">
                        <mat-label>Start Day <span class="asterisk">*</span></mat-label>
                        <input maxlength="15" autocomplete="new-txtScheduledDays" formControlName="txtScheduledDays"
                            pattern="^(0?[1-9]|[1-2][0-9]|30|31)$" matInput type="text" />
                        <mat-error *ngIf="
                            scheduleForm?.get('txtScheduledDays')?.touched &&
                            scheduleForm?.get('txtScheduledDays')?.errors &&
                            scheduleForm?.get('txtScheduledDays')?.value!==''">
                            Enter Valid Number Without Leading Zeros In range Of 1-31 Only

                        </mat-error>
                        <mat-error *ngIf="
                          scheduleForm?.get('txtScheduledDays')?.touched &&
                          scheduleForm?.get('txtScheduledDays')?.value===''
                            ">
                            Start Day is a required field!
                        </mat-error>
                    </mat-form-field>

                    <mat-form-field class="col-9">
                        <mat-label>Months <span class="asterisk">*</span></mat-label>
                        <mat-select maxlength="40" formControlName="drpMonths" [(value)]="arrMonths" multiple>
                            <mat-option *ngFor="let month of drpMonthsList" [value]="month">
                                {{ month.key }}
                            </mat-option>
                        </mat-select>
                        <button mat-button (click)="clearScheduleType()"
                            (click)="arrMonths = []; $event.stopPropagation()" *ngIf="arrMonths!==[]" matSuffix
                            mat-icon-button>
                            <mat-icon>close</mat-icon>
                        </button>
                    </mat-form-field>
                </div>

                <h4 class="customThemeClass mt-3 ">
                    <b>Summary Details</b>
                </h4>
                <div class="row">
                    <mat-form-field class="col-12">
                        <mat-label>Schedule Summary</mat-label>
                        <textarea maxlength="500" rows="4" autocomplete="off" formControlName="txtScheduleSummary"
                            readonly matInput type="text"></textarea>
                    </mat-form-field>
                    <mat-form-field class="col-6">
                        <mat-label>Last Run</mat-label>
                        <input maxlength="40" formControlName="txtLastDate" matInput autocomplete="new-txtLastDate"
                            [disabled]="true" [matDatepicker]="txtLastDate" placeholder="(MM/DD/YYYY)" />
                        <mat-datepicker-toggle matSuffix [for]="txtLastDate"></mat-datepicker-toggle>
                        <mat-datepicker #txtLastDate></mat-datepicker>
                    </mat-form-field>
                    <mat-form-field class="col-6">
                        <mat-label>Next Run</mat-label>
                        <input maxlength="40" formControlName="txtNextDate" matInput autocomplete="new-txtNextDate"
                            [disabled]="true" [matDatepicker]="txtNextDate" placeholder="(MM/DD/YYYY)" />
                        <mat-datepicker-toggle matSuffix [for]="txtNextDate"></mat-datepicker-toggle>
                        <mat-datepicker #txtNextDate></mat-datepicker>
                    </mat-form-field>
                </div>
            </div>
            <div *ngIf="scheduleForm?.value?.drpScheduleType==='Daily'" class="col-8">
                <h4 *ngIf="scheduleForm?.value?.drpScheduleType==='Monthly'" class="customThemeClass mar-top">
                    Daily <b>Days Calcaution</b>
                </h4>
                <div *ngIf="scheduleForm?.value?.drpScheduleType==='Monthly'" class="row">
                    <mat-form-field class="col-3">
                        <mat-label>Days To Be Run</mat-label>
                        <input maxlength="15" autocomplete="new-txtEndPeriod" formControlName="txtEndPeriod" pattern="[0-9]*"
                            matInput type="text" />
                    </mat-form-field>
                </div>

                <h4 class="customThemeClass mar-top">
                    <b>Summary Details</b>
                </h4>
                <div class="row">
                    <mat-form-field class="col-12">
                        <mat-label>Schedule Summary</mat-label>
                        <textarea maxlength="500" rows="4" autocomplete="off" formControlName="txtScheduleSummary"
                            readonly matInput type="text"></textarea>
                    </mat-form-field>
                    <mat-form-field class="col-6">
                        <mat-label>Last Run</mat-label>
                        <input maxlength="40" formControlName="txtLastDate" matInput autocomplete="new-txtLastDate"
                            [disabled]="true" [matDatepicker]="txtLastDate" placeholder="(MM/DD/YYYY)" />
                        <mat-datepicker-toggle matSuffix [for]="txtLastDate"></mat-datepicker-toggle>
                        <mat-datepicker #txtLastDate></mat-datepicker>
                    </mat-form-field>
                    <mat-form-field class="col-6">
                        <mat-label>Next Run</mat-label>
                        <input maxlength="40" formControlName="txtNextDate" matInput autocomplete="new-txtNextDate"
                            [disabled]="true" [matDatepicker]="txtNextDate" placeholder="(MM/DD/YYYY)" />
                        <mat-datepicker-toggle matSuffix [for]="txtNextDate"></mat-datepicker-toggle>
                        <mat-datepicker #txtNextDate></mat-datepicker>
                    </mat-form-field>
                </div>
            </div>
            <div *ngIf="scheduleForm?.value?.drpScheduleType==='Once'" class="col-8">
                <h4 *ngIf="scheduleForm?.value?.drpScheduleType==='Monthly'" class="customThemeClass mar-top">
                    Once <b>Days Calcaution</b>
                </h4>
                <div *ngIf="scheduleForm?.value?.drpScheduleType==='Monthly'" class="row">
                    <mat-form-field class="col-3">
                        <mat-label>Days To Be Run</mat-label>
                        <input maxlength="15" autocomplete="new-txtEndPeriod" formControlName="txtEndPeriod" pattern="[0-9]*"
                            [value]='"1"' [disabled]="true" matInput type="text" />
                    </mat-form-field>
                </div>

                <h4 class="customThemeClass mar-top">
                    <b>Summary Details</b>
                </h4>
                <div class="row">
                    <mat-form-field class="col-12">
                        <mat-label>Schedule Summary</mat-label>
                        <textarea maxlength="500" rows="4" autocomplete="off" formControlName="txtScheduleSummary"
                            readonly matInput type="text"></textarea>
                    </mat-form-field>
                    <mat-form-field class="col-6">
                        <mat-label>Last Run</mat-label>
                        <input maxlength="40" formControlName="txtLastDate" matInput autocomplete="new-txtLastDate"
                            [disabled]="true" [matDatepicker]="txtLastDate" placeholder="(MM/DD/YYYY)" />
                        <mat-datepicker-toggle matSuffix [for]="txtLastDate"></mat-datepicker-toggle>
                        <mat-datepicker #txtLastDate></mat-datepicker>
                    </mat-form-field>
                    <mat-form-field class="col-6">
                        <mat-label>Next Run</mat-label>
                        <input maxlength="40" formControlName="txtNextDate" matInput autocomplete="new-txtNextDate"
                            [disabled]="true" [matDatepicker]="txtNextDate" placeholder="(MM/DD/YYYY)" />
                        <mat-datepicker-toggle matSuffix [for]="txtNextDate"></mat-datepicker-toggle>
                        <mat-datepicker #txtNextDate></mat-datepicker>
                    </mat-form-field>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-12">
                <div class="text-lg-center">
                    <button mat-button     [disabled]=" saveButtonHide||scheduleForm?.invalid" (click)="saveOrUpdateSchedule()" class="buttonColor">Save
                        <mat-icon *ngIf="isShowSpinner">
                            <mat-spinner class="spinner-border spinner-border-sm" diameter="20">
                            </mat-spinner>
                        </mat-icon>
                    </button>
                </div>
            </div>
        </div>
    </form>
</div>
