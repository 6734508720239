//#region
import { HttpErrorResponse } from '@angular/common/http';
import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { InboundDocumentService } from 'projects/admin/src/app/admin-proxy/platform-app-management/rcm/platform-management/inbound/inbound-document.service';
import { Observable, Subject, Subscription } from 'rxjs';
import Swal from 'sweetalert2';
import {
  SortingShortCode,
  TextAnnotation,
  defaultGuid,
  sotingSelectedCSS,
} from '../../enums/allenums.enum';
import { ToastrService } from 'ngx-toastr';
import {
  AnnotatedDTO,
  SortedDocumentGroupDTO,
} from 'projects/admin/src/app/admin-proxy/platform-app-management/rcm/platform-management/inbound/dto';
import {
  AbstractControl,
  AsyncValidatorFn,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SaveDocumentDto } from 'projects/admin/src/app/admin-proxy/platform-app-management/rcm/platform-management/sorting/dto';
import { SortType } from 'projects/admin/src/app/admin-proxy/platform-app-management/rcm/platform-management/localization/enumeration';
import { ListService } from '@abp/ng.core';
import {
  DiagnosisCode10DTO,
  MachineTypeDTO,
  SymptomsDTO,
} from 'projects/patient/src/app/dropdown-proxy/dropdown-management/dropdowns/dto/patient';
import { doctorInterface } from 'projects/billing/src/app/billing-claim-info/billing-claim-info.component';
import { MatDialog } from '@angular/material/dialog';
import { PatientIcd10Component } from 'projects/patient/src/app/patient-icd10/patient-icd10.component';
import { PatientDoctorComponent } from 'projects/patient/src/app/patient-doctor/patient-doctor.component';
import {
  DiagnosisCode10Service,
  PatientDropdowns,
} from 'projects/patient/src/app/dropdown-proxy/dropdown-management/dropdowns';
import { PatientMasterDropdownService } from 'projects/patient/src/app/dropdown-proxy/dropdown-management/dropdowns/master-dropdown-service';
import { debounceTime, map, startWith, tap } from 'rxjs/operators';
import { DoctorService } from 'projects/patient/src/app/patient-proxy/patient/doctor.service';
import { PatientDocumentService } from 'projects/patient/src/app/patient-proxy/patient-optimization/patient-document.service';
import {
  CreateUpdateComplainceDTO,
  CreateUpdateDiagnosticSleepStudyDTO,
  CreateUpdatePrescriptionDTO,
  CreateUpdateSleepStudyDTO,
  CreateUpdateTitrationSleepStudyDTO,
} from 'projects/patient/src/app/patient-proxy/patient/dto';
import {
  CreateUpdateChecklistSortingDTO,
  CreateUpdateInitialFaceToFaceDTO,
} from 'projects/patient/src/app/patient-proxy/patient-optimization/dto';
import { ActivatedRoute } from '@angular/router';
import { DatePipe } from '@angular/common';
import { LoaderService } from '../../services/loader-service';
import { PreviewDocumentComponent } from 'projects/order/src/app/preview-document/preview-document.component';
import { HellosignSignatureService } from 'projects/order/src/app/order-proxy/order-management/order-optimization/hellosign-signature.service';
import { noWhitespaceValidator } from 'projects/patient/src/app/add-note-tab/add-note-tab.component';
import {
  CreateUpdateDocumentBinDTO,
  ValidateDocumentBinDTO,
} from 'projects/admin/src/app/admin-proxy/platform-app-management/rcm/platform-management/document-bin/dto/models';
import { DocumentBinService } from 'projects/admin/src/app/admin-proxy/platform-app-management/rcm/platform-management/document-bin/document-bin.service';
import { CommunicationService } from '../../services/communication.service';

//#endregion

@Component({
  selector: 'app-common-sorting',
  templateUrl: './common-sorting.component.html',
  styleUrls: ['./common-sorting.component.scss'],
  providers: [ListService, DatePipe],
})
export class CommonSortingComponent implements OnInit {
  isOrderPageLoading: boolean;
  @Input() docId: any;
  @Input() sortingblobName: string;
  @Input() isInbound: any;
  @Input() defaultFaxShortCodeId: any;
  @Input() signatureId: string;
  @Output() prevent_unsaved_sorting = new EventEmitter();
  @Output() prevent_unsaved_annotations = new EventEmitter();
  @Output() headerText = new EventEmitter();
  patientId: string;
  documentName:string;
  blobName: string;
  pdfheight: string = '100%';
  overAllPages: number = 0;
  overAllPagesBG: number = 0;
  sortedPages: number = 0;
  pendingPages: number = 0;
  isLoadSortingView: boolean;
  isSortingStart = false;
  isDisabled = false;
  checkValidInputCase = 0;
  arrowButtonClicked = false;
  @ViewChildren('myInputRefs') myInputRefs: QueryList<ElementRef>;
  saveDocumentDetails: SaveDocumentDto[] = [];
  isBTNdisable: boolean = true;
  document_shortCode: any;
  document_documentTypeId: any;
  physicianDrpdwn: any;
  SortingShortCode = SortingShortCode;
  documentTypes = [];
  TotalDocumentImageList: any = [];
  SortedDocumentImageList: any = [];
  PendingDocumentImageList: any = [];
  BinDocumentImageList: any = [];
  isMoved: boolean = false;
  filteredDoctors: Observable<doctorInterface[]> | undefined; //Filtered Referring Provider Doctor Types
  lstDoctor: any[];

  drpSymptoms: any[] = [];
  drpMachines: MachineTypeDTO[] = [];
  filteredSymptoms: Observable<SymptomsDTO[]> | undefined;
  filteredDiagnosiss: Observable<DiagnosisCode10DTO[]> | undefined;
  filteredRxDiagnosiss: Observable<DiagnosisCode10DTO[]> | undefined;
  filteredMachines: Observable<MachineTypeDTO[]> | undefined;
  drpDiagnosisICD10: DiagnosisCode10DTO[] = [];
  filteredRxDoctors: Observable<doctorInterface[]> | undefined;
  isSaveDisabled = false;
  private saveSubject: Subject<void> = new Subject<void>();

  RxdateMin: Date;
  RxdateMax: Date;
  isLoading = false;
  @ViewChild('canvas', { static: true }) canvasRef!: ElementRef;
  ctx!: CanvasRenderingContext2D;
  pageNumber: number = 1;
  totalPages: number = 0;
  // readonly DEFAULT_ZOOM_LEVEL = 1;
  // readonly MAX_ZOOM_LEVEL = 2.5;
  // readonly MIN_ZOOM_LEVEL = 0.5;
  readonly DEFAULT_ZOOM_LEVEL = 0.6;
  readonly MAX_ZOOM_LEVEL = this.DEFAULT_ZOOM_LEVEL + 4;
  readonly MIN_ZOOM_LEVEL = 0.4;
  zoomLevel: number = this.DEFAULT_ZOOM_LEVEL; // Default Size
  zoomLevelReSize: number = this.DEFAULT_ZOOM_LEVEL;
  existingDocumentImageList: any = [];
  selectedExistingDocument: any = null;
  loadingMessage: string = 'Loading ...';
  isDrawing: boolean = false;
  startX: number = 0;
  startY: number = 0;
  lineColor: string = '#F6BE00';
  textColor: string = '#0000FF';
  savedState: ImageData | null = null;
  textAnnotations: TextAnnotation[] = [];
  isMouseDown: boolean = false;
  isMouseMove: boolean = false;
  isMouseUp: boolean = false;
  isMouseLeave: boolean = false;

  rectWidth: number;
  rectHeight: number;
  strPdfString: string = '';
  isHideTiffViewer: boolean = false;
  TiffError: boolean = true;
  SortSaveError: boolean = true;
  readonly DEFAULT_LAZY_LOADING = 10;
  from: number = 1;
  to: number = this.DEFAULT_LAZY_LOADING;
  readonly DEFAULT_LAZY_LOADING_API = 10; // If change the Value API side also need to change 10 to X
  // readonly DEFAULT_LAZY_LOADING_Index = 9;
  pendingFrom: number = 0;
  pendingTo: number = this.DEFAULT_LAZY_LOADING_API; // If change the Value API side also need to change 10 to X
  sortedFrom: number = 0; // Index based
  sortedTo: number = this.DEFAULT_LAZY_LOADING_API; // If change the Value API side also need to change 10 to X
  showPdfViwer: boolean = false;
  strSelectedPdfPath: string = '';
  strSelectedPdfPathCopy: string = '';
  sotingSelectedCSS = sotingSelectedCSS;
  selectedOption: sotingSelectedCSS = sotingSelectedCSS.Total;
  tiffPageNo: number = 0;
  disableAnnotation: boolean = false;
  currentArrayIndex: number = 0;
  isSortingStatus: boolean = false;
  TotalAPICallDone: boolean = false;
  PendingAPICallDone: boolean = false;
  pendingTimeout: any;
  isTotalAPIcallGoingOn: boolean = true;
  isDocumentLoadBefore: boolean = false;
  firstTotalAPICall: boolean = false;
  isTotalAPIcallGoingOnCount: number = 0;
  mstDocumentBinForm: FormGroup;
  documentBinId: string = defaultGuid;
  private saveAnnotationAPICall: Subscription;
  loadInboundDocumentAPICall$: Subscription;
  SortedloadInboundDocumentAPICall$: Subscription;
  PendingloadInboundDocumentAPICall$: Subscription;
  subscription$: Subscription[] = [];
  loadloadOverAllSortingAPICall$: Subscription;
  tenantId: string;
  signatureRequstId: any;
  hellosignPageCount: number = 0;
  LastSortingPageNo: number = null;
  LastSortingShortCode: string = null;
  blobNameSorting: string;
  sortinfFaxId: string;
  sortingdefaultPatientId: string;
  patientChartID: string;
  patientFullName: string;
  docOverallPages: number = 0;
  docSortedPages: number = 0;
  docUnSortedPages: number = 0;
  isInboundURL: string;
  isAnnotate: boolean = false;
  DocumentBinSaveBtn: boolean=false;
  constructor(
    private loaderService: LoaderService,
    private inboundDocumentService: InboundDocumentService,
    private sanitizer: DomSanitizer,
    private toastr: ToastrService,
    private snackBar: MatSnackBar,
    private matDialog: MatDialog,
    private matDoctorDialog: MatDialog,
    private matIcd10Dialog: MatDialog,
    public list: ListService,
    private doctorService: DoctorService,
    private patientDropdownService: PatientMasterDropdownService,
    private diagnosis10: DiagnosisCode10Service,
    private fb: FormBuilder,
    private patientDocumentService: PatientDocumentService,
    private activatedRoute: ActivatedRoute,
    private datepipe: DatePipe,
    private helloSign: HellosignSignatureService,
    private documentBinService: DocumentBinService,
    private communicationservice: CommunicationService
  ) {
    this.loaderService.getLoaderState().subscribe((isOrderPageLoading) => {
      this.isOrderPageLoading = isOrderPageLoading;
    });
  }

  ngOnInit(): void {
    this.tenantId = localStorage.getItem('tenantId') ?? '';
    this.intializeForms();
    if (!this.isEmpty(this.docId)) {
      // this.sortingDocView();
      this.loadOverAllSortingAPI();
      this.loadPatientDemoForm(this.docId);
    }
    this.saveSubject.pipe(debounceTime(1000)).subscribe(() => {
      // this.isSaveDisabled = false;
      this.isDisabled = true;
      this.saveDocument();
    });
    const activateRoute = this.activatedRoute.paramMap.subscribe((response) => {
      this.isSortingStatus = response['params'].sortingType;
      this.blobNameSorting = response['params'].isSorting;
      this.isInboundURL = response['params'].isInbound;
    });
    if (this.isInbound === undefined) {
      this.isInbound = false;
    }
  }

  ngOnDestroy(): void {
    if (this.saveAnnotationAPICall) {
      this.saveAnnotationAPICall?.unsubscribe();
    }
    if (this.loadInboundDocumentAPICall$) {
      this.loadInboundDocumentAPICall$.unsubscribe();
    }
    if (this.SortedloadInboundDocumentAPICall$) {
      this.SortedloadInboundDocumentAPICall$.unsubscribe();
    }
    if (this.PendingloadInboundDocumentAPICall$) {
      this.PendingloadInboundDocumentAPICall$.unsubscribe();
    }
    if (this.loadloadOverAllSortingAPICall$) {
      this.loadloadOverAllSortingAPICall$.unsubscribe();
    }
    this.subscription$?.forEach((sub) => {
      sub && sub?.unsubscribe();
    });
  }

  @HostListener('window:beforeunload', ['$event'])
  unloadNotification($event: any): void {
    // Check if there are unsaved changes (you'll need to implement this logic)
    if (this.isSortingStart || this.textAnnotations.length !== 0) {
      $event.returnValue = true;
    }
  }
  intializeForms() {
    this.mstDocumentBinForm = this.fb.group({
      txtBinName: new FormControl(
        '',
        [Validators.required, noWhitespaceValidator],
        this.checkDocumentBinUniqness()
      ),
    });
    // this.updatePageHeaderText();
    // this.doctorDropdown();
    this.reloadPhysican('');
    this.getDropdown();
    this.getDiagonsisCode();
    this.loadValidDates();
  }

  loadPatientDemoForm(docId: string) {
    const getPatientDetails = this.inboundDocumentService
      .getPatientDemographicsDetails(docId)
      .subscribe(
        (response) => {
          if (response?.blobName === null || response?.blobName === undefined) {
            this.blobName = this.sortingblobName;
          } else {
            this.blobName = response?.blobName || 'no.pdf';
          }
          // if(response?.blobName){

          // }
          this.loadInboundDocument(this.from, this.to);
          // After Load Once BlobName set
          this.documentName = response?.documentName
          this.sortingdefaultPatientId = response?.defaultPatientId;
          this.sortinfFaxId = response?.defaultFaxId;
          this.patientChartID = response?.chartId;
          this.patientFullName =
            (response?.firstName || '') +
            ' ' +
            (response?.middleName || '') +
            ' ' +
            (response?.lastName || '');
          // console.log(this.patientFullName);

          // this.headerText.emit({
          //   defaultPatientId: response?.defaultPatientId || '-',
          //   defaultFaxId: response?.defaultFaxId || '-',
          //   chartId: response?.chartId || '-',
          //   fullName: (
          //     (response?.firstName || '') +
          //     ' ' +
          //     (response?.middleName || '') +
          //     ' ' +
          //     (response?.lastName || '')
          //   ).trim(),
          // });
        },
        (err) => {
          this.loadInboundDocument(this.from, this.to);
          const data: HttpErrorResponse = err;
          // this.toastr.error(data?.error?.error?.message, 'Error');
          console.warn(data?.error?.error?.message);
        }
      );
  }

  loadValidDates() {
    this.RxdateMax = new Date();
    this.RxdateMax.setDate(this.RxdateMax.getDate());
    this.RxdateMin = new Date();
    this.RxdateMin.setFullYear(this.RxdateMin.getFullYear() - 120);
    var oneYearAgo = new Date();
    oneYearAgo.setFullYear(this.RxdateMax.getFullYear() - 1);
    this.RxdateMin = oneYearAgo;
  }

  setDateValue = (dateValue: any) => {
    // return dateValue ? new Date(dateValue).toISOString().split('T')[0] : '';

    // , {
    //   month: '2-digit',
    //   day: '2-digit',
    //   year: 'numeric',
    // }

    // return dateValue ? new Date(dateValue).toLocaleString('en-US') : '';
    return dateValue ? new Date(dateValue).toISOString().substring(0, 10) : '';
  };

  setDateValueV1 = (dateValue: any) => {
    if (!dateValue) return '';

    const utcDate = new Date(dateValue);
    const cstOffset = -5; // Central Time offset is UTC-5 -- (GMT-5 time in Austin, TX, USA)

    // Calculate UTC time with the Central Time offset
    const cstTime = new Date(utcDate.getTime() + cstOffset * 60 * 60 * 1000);

    // Format the date in YYYY-MM-DD format
    return cstTime.toISOString().substring(0, 10);
  };

  setDateValueV2 = (dateValue: any) => {
    if (!dateValue) return '';
    return this.datepipe.transform(dateValue, 'yyyy-MM-dd');
  };

  private handleErrorResponse(err: any) {
    const data: HttpErrorResponse = err;
    console.warn(data?.error?.error?.message);
    this.loadingMessage = 'TIFF Image conversion has been In Progress.';
    this.TiffError = true;
    this.SortSaveError = true;
    this.isLoading = false;
  }

  addSlashToDate(event: Event): void {
    const input = event.target as HTMLInputElement;
    const inputValue = input.value;
    input.value = inputValue
      .replace(/^(\d{2})(\d{1,2})/, '$1/$2')
      .replace(/^(\d{2}\/\d{2})(\d+)/, '$1/$2')
      .replace(/[^\d\/]/g, '');
  }

  loadInboundDocumentPDFV0() {
    this.showPdfViwer = true;
    this.inboundDocumentService
      // .downloadInboundDocumentByBlobName(this.data.blobName)
      .downloadInboundDocumentByBlobName(this.blobName)
      .subscribe(
        (response) => {
          // console.log('In 1 IF');
          // if(this.overAllPages === 0 || this.overAllPages === undefined) {
          //   this.overAllPagesBG = this.docOverallPages || 0;
          //   this.overAllPages = this.docOverallPages || 0;
          // }
          if (
            typeof response === 'string' &&
            response &&
            response !== null &&
            response?.length !== 0
          ) {
            this.strPdfString = String(response);
          }
          this.showPdfViwer = false;
        },
        (err) => {
          // if(this.overAllPages === 0 || this.overAllPages === undefined) {
          //   this.overAllPagesBG = this.docOverallPages || 0;
          //   this.overAllPages = this.docOverallPages || 0;
          // }
          this.strPdfString = '';
          this.showPdfViwer = false;

          const data: HttpErrorResponse = err;
          // Swal.fire({
          //   icon: 'info',
          //   text: data?.error?.error?.message,
          // });
        }
      );
  }
  loadInboundDocumentPDF() {
    this.showPdfViwer = true;
    this.inboundDocumentService
      // .downloadInboundDocumentByBlobName(this.data.blobName)
      .getPatientDocumentByPathByDocumentId(this.docId)
      .subscribe(
        (response) => {
          // console.log('In 1 IF');
          // if(this.overAllPages === 0 || this.overAllPages === undefined) {
          //   this.overAllPagesBG = this.docOverallPages || 0;
          //   this.overAllPages = this.docOverallPages || 0;
          // }
          if (
            typeof response === 'string' &&
            response &&
            response !== null

            // &&
            // response?.length !== 0
          ) {
            this.strPdfString = String(response);
          }
          this.showPdfViwer = false;
        },
        (err) => {
          // if(this.overAllPages === 0 || this.overAllPages === undefined) {
          //   this.overAllPagesBG = this.docOverallPages || 0;
          //   this.overAllPages = this.docOverallPages || 0;
          // }
          this.strPdfString = '';
          this.showPdfViwer = false;

          const data: HttpErrorResponse = err;
          // Swal.fire({
          //   icon: 'info',
          //   text: data?.error?.error?.message,
          // });
        }
      );
  }

  loadSortingInboundDocumentPDF() {
    const blobName = this.blobNameSorting || this.blobName;
    this.showPdfViwer = true;
    this.patientDocumentService
      .getPatientDocumentByBlobIDByBlobName(blobName)
      .subscribe(
        (response) => {
          // console.log('1');
          // if(this.overAllPages === 0 || this.overAllPages === undefined) {
          //   console.log('1 IF');
          //   console.log(this.docOverallPages);
          //   this.overAllPagesBG = this.docOverallPages || 0;
          //   this.overAllPages = this.docOverallPages || 0;
          // }

          if (typeof response === 'string' && response && response !== null) {
            this.strPdfString = String(response);
          }
          this.showPdfViwer = false;
        },
        (err) => {
          // if(this.overAllPages === 0 || this.overAllPages === undefined) {
          //   this.overAllPagesBG = this.docOverallPages || 0;
          //   this.overAllPages = this.docOverallPages || 0;
          // }
          this.strPdfString = '';
          this.showPdfViwer = false;

          const data: HttpErrorResponse = err;
          // Swal.fire({
          //   icon: 'info',
          //   text: data?.error?.error?.message,
          // });
        }
      );
  }
  //Intialy to Load If less than 10 Tiff Images
  loadInboundDocumentLoadMoreInitial(from: number, to: number) {
  this.isTotalAPIcallGoingOn = true;
    this.from = to + 1;
    this.to = to + this.DEFAULT_LAZY_LOADING;
    this.loadMoreTiffImage();
  }

  loadInboundDocumentLoadMore(from: number, to: number) {
    this.isTotalAPIcallGoingOn = true;
    if (this.overAllPagesBG >= this.to) {
      this.from = to + 1;
      this.to = to + this.DEFAULT_LAZY_LOADING;
      this.loadMoreTiffImage();
    } else {
      this.firstTotalAPICall = true;
      // this.checkTotalAPICallCompletion();
      this.isTotalAPIcallGoingOn = false;
      this.loadOverAllSortingAPI();
      // this.isDisabled = false;
    }
  }

  loadMoreTiffImage(){
    if (this.loadInboundDocumentAPICall$) {
      this.loadInboundDocumentAPICall$?.unsubscribe();
    }
    const loadInboundDocument = this.inboundDocumentService
      .getTIFFImageBase64v1ByGIdAndFromAndTo(this.docId, this.from, this.to)
      .subscribe(
        (response) => {
          let totalPagesLoadMore = response.files.length;
          if (response && totalPagesLoadMore > 0) {
            // Assuming response is an array of items to be pushed into the list
            response.files.forEach((item) => {
              const newItem = {
                ...item,
                src: this.sanitizer.bypassSecurityTrustResourceUrl(
                  `data:image/tiff;base64, ${item.file}`
                ),
                pageNo: item.pageNo,
              };
              this.TotalDocumentImageList.push(newItem);
              item = null;
            });
            this.overAllPages = this.TotalDocumentImageList.length;
            if (this.selectedOption === this.sotingSelectedCSS.Total) {
              this.totalPages = this.TotalDocumentImageList.length;
              this.existingDocumentImageList = this.TotalDocumentImageList;
            }
          }
          this.loadInboundDocumentLoadMore(this.from, this.to);
          this.firstTotalAPICall = true;
        },
        (err) => {
          // this.handleErrorResponse(err);
          this.isTotalAPIcallGoingOn = false;
          this.loadOverAllSortingAPI(); // If 21 to 30 Case - Round Off 0
        }
      );
    this.loadInboundDocumentAPICall$ = loadInboundDocument;
  }

  loadOverAllSortingAPI() {
    if (!this.docId) {
      console.warn("docId is null or undefined, API call aborted.");
      return;
  }
    if (this.loadloadOverAllSortingAPICall$) {
      this.loadloadOverAllSortingAPICall$?.unsubscribe();
    }
    setTimeout(() => {
      this.isTotalAPIcallGoingOnCount++;
    }, 1000);
    const loadInboundDocument = this.inboundDocumentService
      // .getSortedDocumentPagesv2ByGDocumentId(this.docId)
      .getSortedPatientDocumentsByGPatientDocId(this.docId)
      .subscribe(
        (response) => {
          let noPagesCount =
            response?.sortedDocuments.length + response?.pending.length;
          this.docSortedPages = response?.sortedDocuments.length;
          this.docUnSortedPages = response?.pending.length;
          this.docOverallPages = noPagesCount;

          this.documentTypes = response?.ltDocuments || [];
          if (!this.isTotalAPIcallGoingOn) {
            this.PendingDocumentImageList = this.TotalDocumentImageList.filter(
              (dx) => response.pending.includes(dx.pageNo)
            );
            if (this.selectedOption === this.sotingSelectedCSS.Pending) {
              this.loadPendingDocument();
            }
            this.SortedDocumentImageList = this.TotalDocumentImageList.filter(
              (dx) => response.sortedDocuments.includes(dx.pageNo)
            );

            if (this.selectedOption === this.sotingSelectedCSS.Sorted) {
              this.loadSortedDocument();
            }

            // Function to add docPageContent to documentTypesV1
            this.documentTypes.forEach((docType) => {
              if (docType.docPage) {
                docType.docPageContent = this.TotalDocumentImageList.filter(
                  (doc) => docType.docPage.includes(doc.pageNo)
                );
              } else {
                docType.docPageContent = [];
              }
            });
          }

          this.headerText.emit({
            defaultPatientId: this.sortingdefaultPatientId || '-',
            defaultFaxId: this.sortinfFaxId || '-',
            chartId: response?.chartId || this.patientChartID || '-',
            fullName: response?.patientName || this.patientFullName || '-',
            blobName : this.blobName || this.blobNameSorting,
            isInbound : this.isInbound,
            documentName : this.documentName,
          });
        },
        (err) => {
          // this.handleErrorResponse(err);
        }
      );
    this.loadloadOverAllSortingAPICall$ = loadInboundDocument;
  }

  loadTotalDocument() {
    this.is011Clicked = false;
    this.isHideTiffViewer = false;
    if (this.mustSaveAnnotations()) {
      this.document_shortCode = 'ABC';
      this.tiffPageNo = 1;
      this.pageNumber = 1;
      this.currentArrayIndex = 0;
      this.isLoading = true;
      this.totalPages = this.TotalDocumentImageList.length;
      if (this.totalPages > 0) {
        this.existingDocumentImageList = this.TotalDocumentImageList;
        this.loadTiffImageV1(this.existingDocumentImageList?.[0]);
      } else {
        this.TiffError = true;
        this.TotalDocumentImageList = [];
        this.loadingMessage = 'Total Images has not found';
        setTimeout(() => {
          this.clearCanvas();
        }, 500);
      }
      this.isLoading = false;
      this.selectedOption = sotingSelectedCSS.Total;
    } else {
      this.pageNumber =
        this.existingDocumentImageList[this.currentArrayIndex].pageNo;
    }
  }

  loadPendingDocument() {
    this.is011Clicked = false;
    this.isHideTiffViewer = false;
    if (!this.isLoadSortingView) {
      if (this.mustSaveAnnotations()) {
        this.document_shortCode = 'ABC';
        this.tiffPageNo = 1;
        this.pageNumber = 1;
        this.currentArrayIndex = 0;
        this.isLoading = true;
        this.totalPages = this.PendingDocumentImageList.length;
        if (this.totalPages > 0) {
          this.existingDocumentImageList = this.PendingDocumentImageList;
          this.loadTiffImageV1(this.existingDocumentImageList?.[0]);
        } else {
          this.TiffError = true;
          this.PendingDocumentImageList = [];
          this.loadingMessage = 'Unsorted Images has not found';
          setTimeout(() => {
            this.clearCanvas();
          }, 500);
        }
        this.isLoading = false;
        this.selectedOption = sotingSelectedCSS.Pending;
      } else {
        this.pageNumber =
          this.existingDocumentImageList[this.currentArrayIndex].pageNo;
      }
    }
    // else {
    //   this.TiffError = true;
    //   this.loadingMessage = 'Loading Unsorted Images...';
    //   setTimeout(() => {
    //     this.clearCanvas();
    //   }, 500);
    // }
    this.selectedOption = sotingSelectedCSS.Pending;
  }

  loadSortedDocument() {
    this.is011Clicked = false;
    this.isHideTiffViewer = false;
    if (!this.isLoadSortingView) {
      if (this.mustSaveAnnotations()) {
        this.document_shortCode = 'ABC';
        this.tiffPageNo = 1;
        this.pageNumber = 1;
        this.currentArrayIndex = 0;
        this.isLoading = true;
        this.totalPages = this.SortedDocumentImageList.length;
        if (this.totalPages > 0) {
          this.existingDocumentImageList = this.SortedDocumentImageList;
          this.loadTiffImageV1(this.existingDocumentImageList?.[0]);
        } else {
          this.TiffError = true;
          this.SortedDocumentImageList = [];
          this.loadingMessage = 'Sorted Images has not found';
          setTimeout(() => {
            this.clearCanvas();
          }, 500);
        }
        this.isLoading = false;
        this.selectedOption = sotingSelectedCSS.Sorted;
      } else {
        this.pageNumber =
          this.existingDocumentImageList[this.currentArrayIndex].pageNo;
      }
    }
    // else {
    //   this.TiffError = true;
    //   this.loadingMessage = 'Loading Sorted Images...';
    //   setTimeout(() => {
    //     this.clearCanvas();
    //   }, 500);
    // }
    this.selectedOption = sotingSelectedCSS.Sorted;
  }

  isHelloSignAPIcallDoneBefore: boolean = false;
  viewDocmentPDFHelloSign(signaturerequestid) {
    this.TiffError = true;
    this.clearCanvas();
    if (!signaturerequestid) {
      this.toastr.warning(
        'Reason: Hello Sign signoure has not done',
        'Warning'
      );
      return false;
    }
    this.isHideTiffViewer = true;

    this.showPdfViwer = true;
    if (!this.isHelloSignAPIcallDoneBefore) {
      this.isHelloSignAPIcallDoneBefore = true;
      this.helloSign
        .getSignatureDownloadDocumentBySignatureIdAndTenantId(
          signaturerequestid,
          this.tenantId
        )
        .subscribe(
          (response) => {
            if (
              typeof response === 'string' &&
              response &&
              response !== null &&
              response?.length !== 0
            ) {
              this.strPdfString = String(response);
              this.showPdfViwer = false;
            }

            this.showPdfViwer = false;
          },
          (err) => {
            this.strPdfString = '';
            this.showPdfViwer = false;
            console.error(err);
            this.toastr.error('Hello Sign Documenet has not found');
          }
        );
    } else {
      if (this.isEmpty(this.strPdfString)) {
        this.toastr.error('Hello Sign Documenet has not found');
      }
      this.showPdfViwer = false;
    }
  }

  check() {
    console.log(this.documentTypes);
  }
  is011Clicked = false;
  loadBinDocument(
    shortCode: string,
    pagecount: number,
    documentTypeId: string
  ) {
    if (this.mustSaveAnnotations()) {
      this.document_shortCode = shortCode;
      this.selectedOption = sotingSelectedCSS.Bin;
      this.document_documentTypeId = documentTypeId;

      if (shortCode === SortingShortCode.SIGNED_DOCUMENT) {
        this.signatureRequstId = this.signatureId;
        this.viewDocmentPDFHelloSign(this.signatureRequstId);
        this.is011Clicked = true;
        return false;
      }

      this.is011Clicked = false;
      this.isHideTiffViewer = false;

      this.tiffPageNo = 1;
      this.pageNumber = 1;
      this.currentArrayIndex = 0;
      if (pagecount !== 0) {
        const filteredDocuments = this.documentTypes.filter(
          (doc) => doc.documentTypeId === documentTypeId
        );
        // Extract docPageContent
        const docPageContent = filteredDocuments.map(
          (doc) => doc.docPageContent
        );
        this.isLoading = true;
        this.totalPages = docPageContent[0]?.length || 0;
        if (this.totalPages > 0) {
          this.existingDocumentImageList = docPageContent[0].map((a) => {
            return {
              ...a,
              src: this.sanitizer.bypassSecurityTrustResourceUrl(
                `data:image/tiff;base64, ${a.file}`
              ),
              pageNo: a.pageNo,
            };
          });
          this.loadTiffImageV1(this.existingDocumentImageList?.[0]);
        } else {
          this.clearCanvas();
          this.TiffError = true;
          this.loadingMessage = 'Images has not found';
        }
        this.isLoading = false;
      } else {
        this.TiffError = true;
        this.loadingMessage = 'Images has not found';
        setTimeout(() => {
          this.clearCanvas();
        }, 500);
      }
      // this.document_shortCode = shortCode;
      // this.selectedOption = sotingSelectedCSS.Bin;
    } else {
      this.pageNumber =
        this.existingDocumentImageList[this.currentArrayIndex].pageNo;
    }
  }

  loadInboundDocumentV0(from: number, to: number) {
    this.isLoading = true;
    this.firstTotalAPICall = false;
    let pageNo = 1;
    if (this.loadInboundDocumentAPICall$) {
      this.loadInboundDocumentAPICall$?.unsubscribe();
    }
    const loadInboundDocument = this.inboundDocumentService
      .getTIFFImageBase64v1ByGIdAndFromAndTo(this.docId, this.from, 1)
      .subscribe(
        (response) => {
          this.totalPages = response?.files?.length || 0;
          this.overAllPagesBG = response?.totalPagecount || 0;
          this.overAllPages = response?.totalPagecount || 0;

          if (response && this.totalPages > 0) {
            this.TotalDocumentImageList = response.files.map((a) => {
              return {
                ...a,
                src: this.sanitizer.bypassSecurityTrustResourceUrl(
                  `data:image/tiff;base64, ${a.file}`
                ),
                pageNo: a.pageNo,
              };
            });
            this.selectedExistingDocument = this.TotalDocumentImageList?.[0];
            this.existingDocumentImageList = this.TotalDocumentImageList;
            this.loadTiffImageV1(this.existingDocumentImageList?.[0]);
            this.loadInboundDocumentLoadMoreInitial(this.from, 1);
          }
          this.isLoading = false;
          this.TiffError = false;
          this.SortSaveError = false;

          // this.sortingDocView();
        },
        (err) => {
          this.handleErrorResponse(err);
          this.isHideTiffViewer = true;
          if (
            (this.isHideTiffViewer && !this.isEmpty(this.blobName)) ||
            !this.isEmpty(this.blobNameSorting)
          ) {
            if (
              this.isInbound === false ||
              this.isInbound === undefined ||
              this.isInbound === 'false'
            ) {
              this.loadSortingInboundDocumentPDF();
            } else {
              this.loadInboundDocumentPDF();
            }
          } else {
            console.error('File name  = ' + this.blobName);
          }
          this.loadOverAllSortingAPI();
        }
      );
    this.loadInboundDocumentAPICall$ = loadInboundDocument;
  }

  loadInboundDocument(from: number, to: number) {
    this.isLoading = true;
    this.firstTotalAPICall = false;
    let pageNo = 1;
    if (this.loadInboundDocumentAPICall$) {
      this.loadInboundDocumentAPICall$?.unsubscribe();
    }
    const loadInboundDocument = this.inboundDocumentService
      .getTIFFImageBase64v1ByGIdAndFromAndTo(this.docId, this.from, 1)
      .subscribe(
        (response) => {
          this.totalPages = response?.files?.length || 0;
          this.overAllPagesBG = response?.totalPagecount || 0;
          this.overAllPages = response?.totalPagecount || 0;

          if (response && this.totalPages > 0) {
            this.TotalDocumentImageList = response.files.map((a) => {
              return {
                ...a,
                src: this.sanitizer.bypassSecurityTrustResourceUrl(
                  `data:image/tiff;base64, ${a.file}`
                ),
                pageNo: a.pageNo,
              };
            });
            this.selectedExistingDocument = this.TotalDocumentImageList?.[0];
            this.existingDocumentImageList = this.TotalDocumentImageList;
            this.loadTiffImageV1(this.existingDocumentImageList?.[0]);
            this.loadInboundDocumentLoadMoreInitial(this.from, 1);
          }
          this.isLoading = false;
          this.TiffError = false;
          this.SortSaveError = false;

          // this.sortingDocView();
        },
        (err) => {
          this.handleErrorResponse(err);
          this.isHideTiffViewer = true;
          if (
            (this.isHideTiffViewer && !this.isEmpty(this.blobName)) ||
            !this.isEmpty(this.blobNameSorting)
          ) {
            // if (
            //   this.isInbound === false ||
            //   this.isInbound === undefined ||
            //   this.isInbound === 'false'
            // ) {
            //   this.loadSortingInboundDocumentPDF();
            // } else {
            //   this.loadInboundDocumentPDF();
            // }
            this.loadInboundDocumentPDF();

          } else {
            console.error('File name  = ' + this.blobName);
          }
          this.loadOverAllSortingAPI();
        }
      );
    this.loadInboundDocumentAPICall$ = loadInboundDocument;
  }

  checkValidInput(input: string): boolean {
    // Your validation logic goes here
    // Return true if the input is valid, otherwise return false

    // For example, let's check if the input is a number

    if (input === '0') {
      this.checkValidInputCase = 0;
      // If none of the cases match, return false
      return false;
    } else if (/^[1-9]\d*$/.test(input) && Number(input)) {
      // if (/^\d+$/.test(input) && Number(input)) {
      // Case 1: Single number
      this.checkValidInputCase = 1;
      return true;
    } else if (/^\d+(,\d+)*$/.test(input)) {
      // else if (/^\d+([1-9]\d*)*(,\d+([1-9]\d*)*)*$/.test(input)) {
      // Case 2: Comma-separated list of numbers
      this.checkValidInputCase = 2;
      return true;
    } else if (/^\d+-\d+$/.test(input)) {
      // else if (/^\d+([1-9]\d*)*-(\d+([1-9]\d*)*)*$/.test(input)) {
      // Case 3: Range of numbers without 0 (e.g., 1-5)
      this.checkValidInputCase = 3;
      const [start, end] = input.split('-').map(Number);
      return !isNaN(start) && !isNaN(end) && start <= end;
    } else if (input === '') {
      this.checkValidInputCase = 4;
      return true;
    }

    this.checkValidInputCase = 0;
    // If none of the cases match, return false
    return false;

    // return !isNaN(Number(input));
  }

  reloadPhysican(value) {
    const doctorDropdownSubscription = this.doctorService
      // .getPhysicianDropdown('', value)
      .getAllPhysiciansByPracticeIDAndFullNameAndGDoctorAndSSearch('', value, '', '')
      .subscribe(
        (response) => {
          this.physicianDrpdwn = response;
          this.lstDoctor = response;
        },
        (err) => {
          const data: HttpErrorResponse = err;
          Swal.fire({
            icon: 'info',
            text: data?.error?.error?.message,
          });
        }
      );
    this.subscription$.push(doctorDropdownSubscription);
  }

  getDropdown(): void {
    let patientDropdownInput: PatientDropdowns[];
    patientDropdownInput = [
      PatientDropdowns.Symptoms,
      PatientDropdowns.MachineTypes,
    ];
    const patientDropdown = this.patientDropdownService
      .getPatientDropdownsByInput(patientDropdownInput)
      .subscribe(
        (response) => {
          if (response && response.lstSymptoms && response?.lstMachineTypes) {
            this.drpSymptoms = response?.lstSymptoms ?? [];
            this.drpMachines = response?.lstMachineTypes;
          }
        },
        (err) => {
          const data: HttpErrorResponse = err;
          this.toastr.error(data?.error?.error?.message, 'Error');
        },
        () => {}
      );
  }

  getDiagonsisCode() {
    this.drpDiagnosisICD10 = [];
    const diagonsis10ItemList = (query: any) => this.diagnosis10.getList(query);
    this.list.hookToQuery(diagonsis10ItemList).subscribe(
      (response) => {
        response?.items?.forEach((element: DiagnosisCode10DTO) => {
          let resultString =
            element?.diagnosisCode + ' ' + element?.diagnosisCodeTypeData;
          this.drpDiagnosisICD10?.push({
            diagnosisCodeTypeDataId: element?.diagnosisCodeTypeDataId ?? '',
            diagnosisCode10ShortCodeId:
              element?.diagnosisCode10ShortCodeId ?? '',
            diagnosisCode: element?.diagnosisCode ?? '',
            diagnosisCodeTypeData: resultString,
            id: element?.id ?? '',
            creationTime: element?.creationTime ?? '',
            creatorId: element?.creatorId ?? '',
            isActive: element?.isActive,
            isExist: element?.isExist,
            isBillable: element?.isBillable,
          });
        });
      },
      (err) => {
        const data: HttpErrorResponse = err;
        this.toastr.error(data?.error?.error?.message, 'Error');
      }
    );
  }

  //Open Referral Doctors Search Popup
  openDoctorModal(doctorId?: string) {
    const dialogRef = this.matDoctorDialog.open(PatientDoctorComponent, {
      disableClose: true,
      data: { doctorId: doctorId },
    });
    const closeDialog = dialogRef.afterClosed().subscribe(
      () => {
        this.reloadPhysican('');
      },
      (err) => {
        const data: HttpErrorResponse = err;
        this.toastr.error(data?.error?.error?.message, 'Error');
      }
    );
    this.subscription$.push(closeDialog);
  }

  private saveDocument() {
    try {
      this.isDisabled = true;
      let createUpdateChecklistSortingDTO: CreateUpdateChecklistSortingDTO = {
        lstSorting: this.saveDocumentDetails,
        initialFaceToFaces: null,
        sleepStudies: null,
        prescriptions: null,
        complainces: null,
        isInitialFaceToFaceDataAvailable: 0,
        isSleepStudyDataAvailable: 0,
        isPrescriptionDataAvailable: 0,
        isComplainceDataAvailable: 0,
        isVerificationDataAvailable: 0,
        isRuleCheck: 0,
        isRulesNeeded: 0,
        isCompSA: 0,
        dxType: null,
        setupType: null,
        percentageOfCompletion: 0,
        initialFaceToFacePendingValidationCount: 0,
        sleepStudyPendingValidationCount: 0,
        prescriptionPendingValidationCount: 0,
        compliancePendingValidationCount: 0,
        isApproved: true,
        patientId: this.patientId,
        documentId: this.docId,
        bCompleteSorting: true,
      };

      // return false;
      const docDetails = this.patientDocumentService
        .checklistwithSortingUpdateByInput(createUpdateChecklistSortingDTO)
        .subscribe(
          (response) => {
            this.toastr.success('Document Sorted Successfully');
            this.saveDocumentDetails = [];
            this.isSortingStart = false;
            this.communicationservice.setisSorting(this.isSortingStart);
            this.loaderService.hideLoader();
          },
          (err) => {
            const data: HttpErrorResponse = err;
            this.saveDocumentDetails = [];
            // this.router.navigate(['dashboard']);
            // this.sortingDocView();
            this.loadOverAllSortingAPI();
            this.toastr.error(data?.error?.error?.message, 'Error');
            this.loaderService.hideLoader();
          }
        );
    } catch (error) {
      console.error('Error:', error);
      this.isDisabled = false;
      this.isSaveDisabled = false;
      this.isSortingStart = false; // Enable the button when the operation completes (or encounters an error)
      this.communicationservice.setisSorting(this.isSortingStart);
      this.loaderService.hideLoader();
    } finally {
      // this.saveDocumentDetails = [];
    }
  }

  saveSortedDetails() {
    try {
      if (!this.isDisabled) {
        this.isDisabled = true;
        this.loaderService.showLoader();
        this.saveSubject.next();
      }
    } catch (error) {
      console.error('Error:', error);
    } finally {
      // Optionally, you can reset isDisabled after a certain period
      setTimeout(() => {
        this.isDisabled = false;
      }, 5000); // 5000 milliseconds (adjust as needed)
    }
  }

  /// for enter number only
  numberOnly(event: { which: any; keyCode: any }): boolean {
    const charCode = event?.which ? event?.which : event?.keyCode;
    if (
      charCode > 31 &&
      (charCode < 48 || charCode > 57) &&
      (charCode < 44 || charCode > 45)
    ) {
      return false;
    }
    return true;
  }

  async moveDocument(inputValue: any, document: any) {
    if (this.isTotalAPIcallGoingOnCount > 1) {
      if (
        this.overAllPages === this.overAllPagesBG &&
        this.overAllPagesBG !== 0
      ) {
        this.docUnSortedPages = this.PendingDocumentImageList?.length;
        if (this.docUnSortedPages === this.PendingDocumentImageList?.length) {
          if (this.PendingDocumentImageList?.length >= 1) {
            this.docUnSortedPages = this.docUnSortedPages - 1;
          }
        }

        let len = this.existingDocumentImageList.length;
        this.isMoved = true;
        this.isSortingStart = true;
        this.communicationservice.setisSorting(this.isSortingStart);
        this.isDisabled = true;
        try {
          if (document.shortCode !== SortingShortCode.All) {
            var split = [];
            if (this.checkValidInput(inputValue)) {
              if (this.checkValidInputCase === 1) {
                split.push(Number(inputValue));
              } else if (this.checkValidInputCase === 2) {
                split = inputValue?.split(',');
                split = split.map((a) => Number(a));
              } else if (this.checkValidInputCase === 3) {
                let splitwithHyphen = inputValue?.split('-');
                for (
                  var i = Number(splitwithHyphen[0]);
                  i <= Number(splitwithHyphen[1]);
                  i++
                ) {
                  split.push(Number(i));
                }
              } else if (this.checkValidInputCase === 4) {
                split.push(this.pageNumber);
              } else {
                split = [];
              }
              let uniqueArray = [...new Set(split)];
              if (
                uniqueArray.every(
                  (num) =>
                    num > 0 && num <= this.existingDocumentImageList.length
                )
              ) {
                let PageArray = [];
                for (const x of uniqueArray) {
                  let page = this.existingDocumentImageList[x - 1].pageNo;
                  this.pageNumber = x + 1;
                  PageArray.push(page);
                  if (x <= len) {
                    this.pushorUpdateIntoSaveArray(page, document.shortCode);
                  } else {
                    this.showNotification(
                      'Please enter valid input - Zero not allowed, or less than zero not allowed, or maximum allowed ' +
                        len
                    );
                  }
                }
                for (const p of PageArray) {
                  this.UpdateSorting_Pending_BinArray(p, document.shortCode);
                }
                PageArray = [];
                let uploadTiffLoadedIndex = this.documentTypes.findIndex(
                  (value) => value.shortCode === this.document_shortCode
                );
                if (uploadTiffLoadedIndex !== -1) {
                  this.existingDocumentImageList =
                    this.documentTypes[uploadTiffLoadedIndex].docPageContent;
                }
                this.totalPages = this.existingDocumentImageList.length;
                if (this.pageNumber > this.totalPages) {
                  this.pageNumber = this.totalPages;
                }

                if (this.pageNumber === 0) {
                  this.TiffError = true;
                  this.loadingMessage = 'Images has not found';
                  setTimeout(() => {
                    this.clearCanvas();
                  }, 500);
                }
                if (
                  this.selectedOption === sotingSelectedCSS.Sorted ||
                  this.selectedOption === sotingSelectedCSS.Total
                ) {
                  // this.pageNumber++;
                  this.goToPage();
                } else {
                  this.goToPage();
                }
              } else {
                this.showNotification(
                  'Please enter valid input - Zero not allowed, or less than zero not allowed, or maximum allowed ' +
                    len
                );
              }
              this.arrowButtonClicked = true;
            } else {
              this.toastr.error('Please enter vaild input');
            }
          }
        } catch (error) {
          console.error('Error:', error);
        } finally {
          this.isDisabled = false;
          this.myInputRefs.forEach((inputRef) => {
            inputRef.nativeElement.value = '';
          });
          // this.cd.detectChanges();
        }
      } else {
        this.showNotification('Please Wait Document is Loading ..... ');
      }
    } else {
      this.showNotification('Please Wait pages count is loading ..... ');
    }
  }

  pushorUpdateIntoSaveArray(pageNumber: number, shortCode: string) {
    if (
      this.LastSortingPageNo === pageNumber &&
      this.LastSortingShortCode === shortCode
    ) {
      this.showNotification('Already Sorted');
    }
    this.LastSortingPageNo = pageNumber;
    this.LastSortingShortCode = shortCode;
    let documentDetails: SortedDocumentGroupDTO;
    documentDetails = this.documentTypes.find(
      (value) => value.shortCode === shortCode
    );
    let ctDoc = this.existingDocumentImageList.find(
      (value) => value.pageNo === pageNumber
    );

    let existingIndex = this.saveDocumentDetails.findIndex(
      (item) => item.pageNumber === ctDoc.pageNo
    );

    if (existingIndex !== -1) {
      this.saveDocumentDetails[existingIndex].documentTypeId =
        documentDetails.documentTypeId;
      this.saveDocumentDetails[existingIndex].documentType =
        documentDetails.documentType;
    } else {
      let SortDocumentDto: SaveDocumentDto = {
        patientId: this.patientId,
        sortType: SortType.Manual,
        documentTypeId: documentDetails.documentTypeId,
        documentType: documentDetails.documentType,
        pageNumber: ctDoc.pageNo,
        inboundDocumentId: this.docId,
      };
      this.saveDocumentDetails.push(SortDocumentDto);
    }
    // this.UpdateSorting_Pending_BinArray(ctDoc.pageNo, shortCode);
    // this.showNotification('Sorting has been temporarily saved !');
  }

  UpdateSorting_Pending_BinArray(pageNo: number, shortCode: string) {
    let existingPendingIndex = this.PendingDocumentImageList.findIndex(
      (item) => item.pageNo === pageNo
    );
    let removedPendItem = [];
    if (existingPendingIndex !== -1) {
      // Remove item from PendingDocumentImageList and add it to SortedDocumentImageList
      removedPendItem = this.PendingDocumentImageList.splice(
        existingPendingIndex,
        1
      )[0];
      this.SortedDocumentImageList.push(removedPendItem);
      this.sortedPages = this.SortedDocumentImageList.length;
      this.pendingPages = this.PendingDocumentImageList.length;
      let existingBinIndex = this.documentTypes.findIndex(
        (value) => value.shortCode === shortCode
      );
      if (!this.documentTypes[existingBinIndex].docPageContent) {
        this.documentTypes[existingBinIndex].docPageContent = [];
      }
      // Push removedItem into docPageContent and update pageCount
      this.documentTypes[existingBinIndex].docPageContent.push(removedPendItem);
      this.documentTypes[existingBinIndex].pageCount =
        this.documentTypes[existingBinIndex].docPageContent.length;
    }

    let BinCount = 0;
    this.documentTypes.forEach((document) => {
      // Check if the document has a docPageContent array and if it does, remove items based on page number
      if (document.docPageContent && document.docPageContent.length > 0) {
        document.docPageContent.forEach((content, index) => {
          // Check if the page number exists in docPageContent, if so, remove it
          const pageIndex = document.docPageContent.findIndex(
            (content) => content.pageNo === pageNo
          );
          if (pageIndex !== -1) {
            BinCount++;
            // Remove the item from docPageContent
            document.docPageContent.splice(pageIndex, 1);
            // Update the pageCount accordingly
            document.pageCount = document.docPageContent.length;
          }
        });
      }
    });

    if (BinCount !== 0) {
      const findPageinTotal = this.TotalDocumentImageList.find(
        (content) => content.pageNo === pageNo
      );

      let existingBinIndex = this.documentTypes.findIndex(
        (value) => value.shortCode === shortCode
      );
      if (!this.documentTypes[existingBinIndex].docPageContent) {
        this.documentTypes[existingBinIndex].docPageContent = [];
      }
      // Push removedItem into docPageContent and update pageCount
      this.documentTypes[existingBinIndex].docPageContent.push(findPageinTotal);
      this.documentTypes[existingBinIndex].pageCount =
        this.documentTypes[existingBinIndex].docPageContent.length;
    }
  }

  showNotification(message: string): void {
    this.snackBar.open(message, 'Close', {
      duration: 3000, // Duration in milliseconds
      horizontalPosition: 'end', // Positioning the notification horizontally
      verticalPosition: 'top', // Positioning the notification vertically
    });
  }

  isEmpty(value: any): boolean {
    return (
      value === null ||
      value === undefined ||
      value === '' ||
      value === defaultGuid
    );
  }

  loadExistingImage(docDetails: any) {
    this.selectedExistingDocument = docDetails;
    this.loadTiffImageV1(docDetails);
    // this.pageNumber = docDetails.pageNo;
  }

  zoomIn() {
    if (this.zoomLevel < this.MAX_ZOOM_LEVEL) {
      this.zoomLevel += 0.1;
      this.zoomLevelReSize = this.zoomLevel;
      this.redrawAnnotationsZoom(); // Redraw image with new zoom level
    }
  }

  // Function to zoom out
  zoomOut() {
    if (this.zoomLevel > this.MIN_ZOOM_LEVEL) {
      this.zoomLevel -= 0.1;
      this.zoomLevelReSize = this.zoomLevel;
      this.redrawAnnotationsZoom(); // Redraw image with new zoom level
    }
  }

  previousImage() {
    if (this.mustSaveAnnotations()) {
      this.currentArrayIndex--;
      if (0 < this.currentArrayIndex) {
        this.pageNumber = this.currentArrayIndex + 1;
        this.loadExistingImage(
          this.existingDocumentImageList?.[this.currentArrayIndex]
        );
      } else {
        this.firstImage();
      }
    }

    if (this.textAnnotations.length > 0) {
      this.isAnnotate = true;
      this.communicationservice.setisAnnotate(this.isAnnotate);
    } else {
      this.isAnnotate = false;
      this.communicationservice.setisAnnotate(this.isAnnotate);
    }
  }

  nextImage() {
    if (this.mustSaveAnnotations()) {
      this.currentArrayIndex++;
      if (this.currentArrayIndex < this.existingDocumentImageList.length) {
        this.pageNumber = this.currentArrayIndex + 1;
        this.loadExistingImage(
          this.existingDocumentImageList?.[this.currentArrayIndex]
        );
      } else {
        this.lastImage();
      }
    }
    if (this.textAnnotations.length > 0) {
      this.isAnnotate = true;
      this.communicationservice.setisAnnotate(this.isAnnotate);
    } else {
      this.isAnnotate = false;
      this.communicationservice.setisAnnotate(this.isAnnotate);
    }
  }

  firstImage() {
    if (this.mustSaveAnnotations()) {
      this.currentArrayIndex = 0;
      this.pageNumber = this.currentArrayIndex + 1;
      this.loadExistingImage(
        this.existingDocumentImageList?.[this.currentArrayIndex]
      );
    }
    if (this.textAnnotations.length > 0) {
      this.isAnnotate = true;
      this.communicationservice.setisAnnotate(this.isAnnotate);
    } else {
      this.isAnnotate = false;
      this.communicationservice.setisAnnotate(this.isAnnotate);
    }
  }

  lastImage() {
    if (this.mustSaveAnnotations()) {
      this.currentArrayIndex = this.existingDocumentImageList.length - 1;
      this.pageNumber = this.currentArrayIndex + 1;
      this.loadExistingImage(
        this.existingDocumentImageList?.[this.currentArrayIndex]
      );
    }

    if (this.textAnnotations.length > 0) {
      this.isAnnotate = true;
      this.communicationservice.setisAnnotate(this.isAnnotate);
    } else {
      this.isAnnotate = false;
      this.communicationservice.setisAnnotate(this.isAnnotate);
    }
  }

  // Function to navigate to a specific page
  goToPage() {
    if (this.mustSaveAnnotations()) {
      if (this.existingDocumentImageList.length === 0) {
        this.TiffError = true;
        this.loadingMessage = 'Images has not found';
        setTimeout(() => {
          this.clearCanvas();
        }, 500);
      } else if (this.pageNumber <= 0) {
        this.pageNumber = 1;
        this.firstImage();
      } else if (this.pageNumber >= 1 && this.pageNumber <= this.totalPages) {
        this.currentArrayIndex = this.pageNumber - 1;
        this.loadExistingImage(
          this.existingDocumentImageList[this.currentArrayIndex]
        );
      } else {
        this.pageNumber = this.totalPages;
        this.lastImage();
      }
    } else {
      this.pageNumber =
        this.existingDocumentImageList[this.currentArrayIndex].pageNo;
    }

    if (this.textAnnotations.length > 0) {
      this.isAnnotate = true;
      this.communicationservice.setisAnnotate(this.isAnnotate);
    } else {
      this.isAnnotate = false;
      this.communicationservice.setisAnnotate(this.isAnnotate);
    }
  }

  mustSaveAnnotations(): boolean {
    if (this.textAnnotations.length > 0) {
      const confirmation = confirm(
        'Do you want to leave/move this page without saving annotation?'
      );
      if (confirmation) {
        this.clrAllAnnotation();
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  }

  // Function to draw the image on the canvas with the current zoom level
  drawZoomedImage() {
    const docDetails = this.selectedExistingDocument;
    const tiffBase64 = docDetails.file;
    const canvas = this.canvasRef.nativeElement;
    this.ctx = canvas.getContext('2d');
    const img = new Image();
    img.onload = () => {
      // Calculate the position to draw the image so that it remains centered
      const imageWidth = img.width * this.zoomLevel;
      const imageHeight = img.height * this.zoomLevel;
      // Clear canvas before drawing
      this.ctx.clearRect(0, 0, canvas.width, canvas.height);

      // Adjust canvas size based on zoom level
      canvas.width = imageWidth;
      canvas.height = imageHeight;
      // Draw image with adjusted dimensions and centered position
      this.ctx.drawImage(img, 0, 0, imageWidth, imageHeight);
    };
    img.src = 'data:image/tiff;base64,' + tiffBase64;
  }

  // loadTiffImageV1(docDetails: any) {
  //   const canvas = this.canvasRef.nativeElement;
  //   this.ctx = canvas.getContext('2d');
  //   const img = new Image();
  //   img.onload = () => {
  //     // canvas.width = img.width;
  //     // canvas.height = img.height;
  //     // Calculate the position to draw the image so that it remains centered
  //     const imageWidth = img.width * this.DEFAULT_ZOOM_LEVEL;
  //     const imageHeight = img.height * this.DEFAULT_ZOOM_LEVEL;
  //     // Clear canvas before drawing
  //     this.ctx.clearRect(0, 0, canvas.width, canvas.height);

  //     // Adjust canvas size based on zoom level
  //     canvas.width = imageWidth;
  //     canvas.height = imageHeight;
  //     this.ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
  //     // Scroll to the selected image
  //     const selectedImageElement = document.querySelector(
  //       '.custom-thumbnail-view-selected'
  //     );
  //     if (selectedImageElement) {
  //       selectedImageElement.scrollIntoView({
  //         behavior: 'auto',
  //         block: 'center',
  //       });
  //     }
  //   };
  //   img.src = 'data:image/tiff;base64,' + docDetails.file;
  //   this.zoomLevel = this.DEFAULT_ZOOM_LEVEL;
  //   this.tiffPageNo = docDetails.pageNo || 0;
  //   this.selectedExistingDocument = docDetails;
  // }

  convertCanvasToBase64() {
    const canvas = this.canvasRef.nativeElement;
    return canvas.toDataURL('image/tiff');
  }

  clearAllAnnotation() {
    const confirmation = confirm(
      'Are you sure you want to delete all annotation text?'
    );
    if (confirmation) {
      this.clrAllAnnotation();
      this.zoomLevel = this.zoomLevelReSize;
      if (this.textAnnotations.length > 0) {
        this.isAnnotate = true;
        this.communicationservice.setisAnnotate(this.isAnnotate);
      } else {
        this.isAnnotate = false;
        this.communicationservice.setisAnnotate(this.isAnnotate);
      }
    }
  }

  private handleError(error: any) {
    const data: HttpErrorResponse = error;
    this.toastr.error(data?.error?.error?.message, 'Error');
    this.disableAnnotation = true;
    this.loaderService.hideLoader();
  }

  loadTiffImageV1(docDetails: any) {
    if (docDetails && docDetails.file) {
      // this.zoomLevel = this.DEFAULT_ZOOM_LEVEL;
      const canvas = this.canvasRef.nativeElement;
      this.ctx = canvas.getContext('2d');
      const img = new Image();
      img.onload = () => {
        // Calculate the position to draw the image so that it remains centered
        // const imageWidth = img.width * this.DEFAULT_ZOOM_LEVEL;
        // const imageHeight = img.height * this.DEFAULT_ZOOM_LEVEL;
        const imageWidth = img.width * this.zoomLevel;
        const imageHeight = img.height * this.zoomLevel;
        // Clear canvas before drawing
        this.ctx.clearRect(0, 0, canvas.width, canvas.height);

        // Adjust canvas size based on zoom level
        canvas.width = imageWidth;
        canvas.height = imageHeight;
        this.ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
        // Scroll to the selected image
        // const selectedImageElement = document.querySelector(
        //   '.custom-thumbnail-view-selected'
        // );
        // if (selectedImageElement) {
        //   selectedImageElement.scrollIntoView({
        //     behavior: 'auto',
        //     block: 'center',
        //   });
        // }
      };
      img.src = 'data:image/tiff;base64,' + docDetails.file;

      this.tiffPageNo = docDetails.pageNo || 0;
      this.selectedExistingDocument = docDetails;
      this.TiffError = false;
      this.loadingMessage = '';
    } else {
      this.TiffError = true;
      this.loadingMessage = 'Images has not found/Empty';
    }
  }

  onMouseDown(event: MouseEvent) {
    if (this.TiffError || this.isSortingStatus) {
      return;
    }
    if (!this.isLoading) {
      event.preventDefault(); // Prevent default right-click menu
      const canvas = this.canvasRef.nativeElement;
      this.ctx = canvas.getContext('2d');
      const rect = this.canvasRef.nativeElement.getBoundingClientRect();
      this.startX = event.clientX - rect.left;
      this.startY = event.clientY - rect.top;
      this.isDrawing = true;
      this.isMouseDown = true;
      this.savedState = this.ctx.getImageData(
        0,
        0,
        canvas.width,
        canvas.height
      );
    }
  }

  onMouseMove(event: MouseEvent) {
    if (this.TiffError || this.isSortingStatus) {
      return;
    }
    if (!this.isLoading) {
      // event.preventDefault(); // Prevent default right-click menu
      if (!this.isDrawing) return;
      if (!this.isMouseDown) return;

      this.isMouseMove = true;
      // Get the canvas and its context
      const canvas = this.canvasRef.nativeElement;
      this.ctx = canvas.getContext('2d');

      // Get the bounding rectangle of the canvas
      const rect = canvas.getBoundingClientRect();

      // Calculate current mouse position relative to the canvas
      const x = event.clientX - rect.left;
      const y = event.clientY - rect.top;

      // Calculate width and height of the rectangle
      const rectWidth = x - this.startX;
      const rectHeight = y - this.startY;

      if (this.savedState !== null) {
        this.ctx.putImageData(this.savedState, 0, 0);
      }

      // Draw the rectangle for the current move
      this.ctx.strokeStyle = this.lineColor;
      this.ctx.lineWidth = 3;
      this.ctx.strokeRect(this.startX, this.startY, rectWidth, rectHeight);
    }
  }

  onMouseUp(event: MouseEvent) {
    if (this.TiffError || this.isSortingStatus) {
      return;
    }
    if (!this.isLoading) {
      if (!this.isDrawing) {
        return;
      }
      // Get the canvas and its context
      const canvas = this.canvasRef.nativeElement;
      this.ctx = canvas.getContext('2d');

      // Get the bounding rectangle of the canvas
      const rect = canvas.getBoundingClientRect();

      // Calculate current mouse position relative to the canvas
      const x = event.clientX - rect.left;
      const y = event.clientY - rect.top;

      // Calculate width and height of the rectangle
      const rectWidth = x - this.startX;
      const rectHeight = y - this.startY;

      // Save the current canvas state
      const currentState = this.ctx.getImageData(
        0,
        0,
        canvas.width,
        canvas.height
      );

      if (this.savedState !== null) {
        // Redraw the previously saved canvas state
        this.ctx.putImageData(this.savedState, 0, 0);
      }

      // Draw the rectangle for the current move
      this.ctx.strokeStyle = this.lineColor;
      this.ctx.lineWidth = 3;
      this.ctx.strokeRect(this.startX, this.startY, rectWidth, rectHeight);
      this.rectWidth = rectWidth;
      this.rectHeight = rectHeight;

      // Save the changes for the current move
      this.savedState = this.ctx.getImageData(
        0,
        0,
        canvas.width,
        canvas.height
      );
      this.isDrawing = false;
    }
    this.isDrawing = false;
    // this.isMouseMove = false;
  }

  onMouseLeave(event: MouseEvent) {
    if (this.TiffError || this.isSortingStatus) {
      return;
    }
    if (!this.isDrawing) return;
    if (!this.isLoading && !this.isMouseDown && !this.isMouseMove) {
      this.isDrawing = false;
      return;
    } else {
      this.onMouseUp(event);
      this.addText(event);
    }
    this.isDrawing = false;
  }

  // deleteText(event: MouseEvent) {
  //   const rect = this.canvasRef.nativeElement.getBoundingClientRect();
  //   const clickX = (event.clientX - rect.left) / this.zoomLevel; // Adjusted for zoom
  //   const clickY = (event.clientY - rect.top) / this.zoomLevel; // Adjusted for zoom

  //   // Find the text annotation closest to the clicked position
  //   const indexToDelete = this.textAnnotations.findIndex((annotation) => {
  //     const textWidth =
  //       this.ctx.measureText(annotation.text).width * this.zoomLevel; // Adjusted for zoom
  //     return (
  //       clickX >= annotation.x - annotation.rectWidth * this.zoomLevel &&
  //       clickX <=
  //         annotation.x - annotation.rectWidth * this.zoomLevel + textWidth &&
  //       clickY >=
  //         annotation.y -
  //           annotation.rectHeight * this.zoomLevel -
  //           14 * this.zoomLevel &&
  //       clickY <= annotation.y - annotation.rectHeight * this.zoomLevel
  //     ); // 14 is font size, adjusted for zoom
  //   });

  //   if (indexToDelete !== -1) {
  //     // Ask for confirmation
  //     const confirmation = confirm(
  //       'Are you sure you want to delete this text?'
  //     );

  //     if (confirmation) {
  //       this.textAnnotations.splice(indexToDelete, 1);
  //       this.redrawAnnotationsZoom(); // Redraw with zoom after deletion
  //     }
  //   }
  // }

  deleteText(event: MouseEvent) {
    const rect = this.canvasRef.nativeElement.getBoundingClientRect();
    // const clickX = event.clientX - rect.left;
    // const clickY = event.clientY - rect.top;

    const clickX = (event.clientX - rect.left) / this.zoomLevel;
    const clickY = (event.clientY - rect.top) / this.zoomLevel;

    // Find the text annotation closest to the clicked position
    const indexToDelete = this.textAnnotations.findIndex((annotation) => {
      const textWidth = this.ctx.measureText(annotation.text).width;
      return (
        clickX >= annotation.x - annotation.rectWidth &&
        clickX <= annotation.x - annotation.rectWidth + textWidth &&
        clickY >= annotation.y - annotation.rectHeight - 10 - 14 &&
        clickY <= annotation.y - annotation.rectHeight - 10
      ); // 14 is font size
    });

    if (indexToDelete !== -1) {
      // Ask for confirmation
      Swal.fire({
        title: 'Are you sure you want to delete this text?',
        text: 'This Annotation will be removed!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#34c38f',
        cancelButtonColor: '#f46a6a',
        confirmButtonText: 'Yes, delete it!',
      }).then((result) => {
        if (result.value) {
          this.textAnnotations.splice(indexToDelete, 1);
          this.redrawAnnotationsZoom();
        }
      });
      // const confirmation = confirm(
      //   'Are you sure you want to delete this text?'
      // );

      // if (confirmation) {
      //   this.textAnnotations.splice(indexToDelete, 1);
      //   this.redrawAnnotationsZoom();
      // }
    }
  }

  redrawAnnotationsZoom() {
    if (!this.isLoading) {
      const canvas = this.canvasRef.nativeElement;
      this.ctx = canvas.getContext('2d');
      this.ctx.clearRect(0, 0, canvas.width, canvas.height);
      const img = new Image();
      img.onload = () => {
        const imageWidth = img.width * this.zoomLevel;
        const imageHeight = img.height * this.zoomLevel;
        // Clear canvas before drawing
        this.ctx.clearRect(0, 0, canvas.width, canvas.height);
        // Adjust canvas size based on zoom level
        canvas.width = imageWidth;
        canvas.height = imageHeight;
        // Draw image with adjusted dimensions and centered position
        this.ctx.drawImage(img, 0, 0, imageWidth, imageHeight);

        // this.ctx.drawImage(img, 0, 0);
        this.textAnnotations.forEach((annotation) => {
          // // Adjust font size for zoom
          const fontSize = 25 * this.zoomLevel; // Change 14 to your base font size
          this.ctx.font = `${fontSize}px Arial`;

          // this.ctx.font = '14px Arial';

          // Adjust position for zoom
          const adjustedX = annotation.x * this.zoomLevel;
          const adjustedY = annotation.y * this.zoomLevel;

          this.ctx.fillStyle = this.textColor;
          this.ctx.fillText(
            annotation.text,
            adjustedX - annotation.rectWidth * this.zoomLevel,
            adjustedY - (annotation.rectHeight + 10) * this.zoomLevel
          );
          this.ctx.strokeStyle = this.lineColor;
          this.ctx.lineWidth = 3;
          this.ctx.strokeRect(
            adjustedX - annotation.rectWidth * this.zoomLevel,
            adjustedY - annotation.rectHeight * this.zoomLevel,
            annotation.rectWidth * this.zoomLevel,
            annotation.rectHeight * this.zoomLevel
          );

          this.savedState = this.ctx.getImageData(
            0,
            0,
            canvas.width,
            canvas.height
          );
        });
      };
      img.src = 'data:image/tiff;base64,' + this.selectedExistingDocument.file;
    }
  }

  addText(event: MouseEvent) {
    if (this.TiffError || this.isSortingStatus) {
      return;
    }
    this.isDrawing = false;
    if (!this.isLoading && this.isMouseMove) {
      this.isMouseMove = false;
      const rect = this.canvasRef.nativeElement.getBoundingClientRect();
      const textX = (event.clientX - rect.left) / this.zoomLevel; // Adjusted for zoom
      const textY = (event.clientY - rect.top) / this.zoomLevel; // Adjusted for zoom

      let text = prompt('Enter text:');
      // text === null ||
      if (text === '' || text) {
        text = text.trim();
        // Create a new TextAnnotation object and add it to the textAnnotations array
        const newAnnotation: TextAnnotation = {
          text: this.isEmpty(text) ? '.' : text,
          x: textX,
          y: textY,
          rectWidth: this.rectWidth / this.zoomLevel,
          rectHeight: this.rectHeight / this.zoomLevel,
        };
        this.textAnnotations.push(newAnnotation);
        if (this.textAnnotations.length > 0) {
          this.isAnnotate = true;
          this.communicationservice.setisAnnotate(this.isAnnotate);
        } else {
          this.isAnnotate = false;
          this.communicationservice.setisAnnotate(this.isAnnotate);
        }
      }
      // Redraw all annotations
      // this.redrawAnnotations();
      this.redrawAnnotationsZoom();
    }
  }

  clrAllZoomForSave() {
    // this.zoomLevel = this.DEFAULT_ZOOM_LEVEL;
    this.zoomLevel = 1;
    this.redrawAnnotationsZoom();
  }

  clrAllAnnotation() {
    this.zoomLevel = this.DEFAULT_ZOOM_LEVEL;
    this.textAnnotations = [];
    // this.redrawAnnotations();
    this.redrawAnnotationsZoom();
  }

  // saveAnnotation() {

  //   this.clrAllZoomForSave();
  //   this.disableAnnotation = true;
  //   if (this.saveAnnotationAPICall) {
  //     this.saveAnnotationAPICall.unsubscribe();
  //   }

  //   try {
  //     setTimeout(() => {
  //       const base64Content = this.convertCanvasToBase64().split(',')[1];
  //       const annotatedDTO: AnnotatedDTO = {
  //         file: base64Content,
  //         pageNo: this.pageNumber,
  //         id: this.docId,
  //       };

  //       this.saveAnnotationAPICall = this.inboundDocumentService
  //         .addAnnotationByDocumentIdByDto(annotatedDTO)
  //         .subscribe(
  //           (response) => {
  //             this.existingDocumentImageList[this.currentArrayIndex].file =
  //               base64Content;
  //             this.clrAllAnnotation();
  //             this.goToPage();
  //             this.toastr.success(response, 'Success');
  //             this.disableAnnotation = false;
  //           },
  //           (error) => {
  //             this.handleError(error);
  //           }
  //         );
  //     }, 1000);
  //   } catch (error) {
  //     this.handleError(error);
  //   }
  // }

  saveAnnotation() {
    this.loaderService.showLoader();
    this.clrAllZoomForSave();
    this.disableAnnotation = true;
    if (this.saveAnnotationAPICall) {
      this.saveAnnotationAPICall.unsubscribe();
    }
    try {
      setTimeout(() => {
        const base64Content = this.convertCanvasToBase64().split(',')[1];
        const annotatedDTO: AnnotatedDTO = {
          file: base64Content,
          pageNo: this.pageNumber,
          id: this.docId,
        };
        this.saveAnnotationAPICall = this.inboundDocumentService
          .addAnnotationByDocumentIdByDto(annotatedDTO)
          .subscribe(
            (response) => {
              this.existingDocumentImageList[this.currentArrayIndex].file =
                base64Content;
              this.clrAllAnnotation();
              this.goToPage();
              this.zoomLevel = this.zoomLevelReSize;
              this.toastr.success(response, 'Success');
              this.disableAnnotation = false;
              this.loaderService.hideLoader();
            },
            (error) => {
              this.handleError(error);
            }
          );
      }, 1000);
    } catch (error) {
      this.handleError(error);
    }
  }

  clearCanvas() {
    const canvas = this.canvasRef.nativeElement;
    this.ctx = canvas.getContext('2d');
    this.ctx.clearRect(0, 0, canvas.width, canvas.height);
    this.selectedExistingDocument = null;
    this.existingDocumentImageList = [];
    this.pageNumber = 0;
    this.totalPages = 0;
  }

  saveDocumentBin() {
    this.DocumentBinSaveBtn = true;
    let text = this.mstDocumentBinForm.value.txtBinName.trim();
    let documentBin: CreateUpdateDocumentBinDTO = {
      documentType: text.trim(),
      isDefault: false,
      TenantId: localStorage.getItem('tenantId').trim() ?? defaultGuid,
    };

    this.documentBinService.create(documentBin).subscribe(
      (response) => {
        this.toastr.success('Bin Name Saved Successfully');
        this.mstDocumentBinForm.reset();
        const loadInboundDocument = this.inboundDocumentService
          // .getSortedDocumentPagesv2ByGDocumentId(this.docId)
          .getSortedPatientDocumentsByGPatientDocId(this.docId)
          .subscribe((response) => {
            response?.ltDocuments.forEach((element) => {
              let existingBinIndex = this.documentTypes.findIndex(
                (value) => value.documentTypeId === element.documentTypeId
              );
              if (existingBinIndex === -1) {
                // console.log(element);
                element.docPageContent=[];
                this.documentTypes.push(element);
              }
            });
            this.documentTypes.sort((a, b) =>
              a.documentType.localeCompare(b.documentType)
            );
          });
          this.DocumentBinSaveBtn=false;
      },
      (err) => {
        const data: HttpErrorResponse = err;
        this.toastr.error(data?.error?.error?.message, 'Error');
        this.DocumentBinSaveBtn=false;
      }
    );
  }

  //Check Add Document Bin Uniqness
  checkDocumentBinUniqness(): AsyncValidatorFn {
    return (
      control: AbstractControl
    ): Observable<{ [key: string]: any } | null> => {
      const gid = this.documentBinId ? this.documentBinId : null;
      const input: ValidateDocumentBinDTO = {
        id: gid,
        documentType:
          String(control?.value ?? '')
            ?.toLowerCase()
            ?.trim() ?? '',
      };
      return this.documentBinService.addDocumentBinCheckByValidate(input).pipe(
        tap((x) => control?.setErrors({ isDocumentBinExists: true })),
        debounceTime(300),
        map((response) =>
          response === true
            ? { isDocumentBinExists: true }
            : control?.setErrors(null) == null
            ? null
            : null
        )
      );
    };
  }
}
