<mat-dialog-content class="mat-typography">
  <div class="row">
    <div class="col-lg-6">
      <h2>{{Title}}</h2>
    </div>
  </div>
  <form [formGroup]="paymentForm">
    <div class="row">
      <div class="col-lg-12">
        <mat-card-content>
          <div class="row">
            <div class="col-3 mt-4">
              <span>Insurance Type </span>
              <span>{{ ' : ' + insuranceType }}</span>
            </div>
            <div class="col-3 mt-4">
              <span>Discount : </span>
              <span>{{ discountPercent |percent}}</span>
            </div>
            <mat-form-field class="col-3">
              <mat-label>Type</mat-label>
              <mat-select formControlName="txtCode" [(value)]="code" (click)="clearCode()">
                <mat-option *ngFor="let code of drpCode" [value]="code.shortCodeId">
                  {{ code.code }}
                </mat-option>
              </mat-select>
              <button mat-button (click)="code=undefined;$event.stopPropagation()" *ngIf="code" matSuffix
                mat-icon-button >
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field>
            <mat-form-field class="col-3">
              <mat-label>Reason</mat-label>
              <mat-select formControlName="drpReason" [(value)]="reason" (click)="clearReason()">
                <mat-option *ngFor="let reason of drpReason" [value]="reason.shortCodeId">
                  {{ reason.reason }}
                </mat-option>
              </mat-select>
              <button mat-button (click)="reason=undefined;$event.stopPropagation()" *ngIf="reason" matSuffix
                mat-icon-button >
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field>
          </div>
          <div class="row">
            <mat-form-field class="col-3">
              <mat-label>Payments</mat-label>
              <mat-select formControlName="drpType" [(value)]="type" (click)="clearType()">
                <mat-option *ngFor="let payment of drpPayment" [value]="payment.shortCodeId">
                  {{ payment.type }}
                </mat-option>
              </mat-select>
              <button mat-button (click)="type=undefined;$event.stopPropagation()" *ngIf="type" matSuffix
                mat-icon-button >
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field>
            <mat-form-field class="col-3">
              <mat-label>Check No</mat-label>
              <input autocomplete="new-txtCheckNo" matInput formControlName="txtCheckNo" type="text" maxlength="40" />
              <mat-error *ngIf="paymentForm?.get('txtCheckNo')?.touched &&
                    paymentForm?.get('txtCheckNo')?.errors?.number">
                Enter only Numbers!
              </mat-error>
            </mat-form-field>
            <mat-form-field class="col-3">
              <mat-label>Amount</mat-label>
              <input autocomplete="new-txtAmount" matInput formControlName="txtAmount" type="text" maxlength="40" />
              <mat-error *ngIf="paymentForm?.get('txtAmount')?.touched &&
                    paymentForm?.get('txtAmount')?.errors?.number">
                Enter only Numbers!
              </mat-error>
            </mat-form-field>
            <mat-form-field class="col-3">
              <mat-label>Date</mat-label>
              <input autocomplete="new-txtDate" matInput maxlength="40" [min]="BirthdateMinDate" placeholder="MM/DD/YYYY" formControlName="txtDate"
                [matDatepicker]="myPicker1">
              <mat-datepicker-toggle matSuffix [for]="myPicker1"></mat-datepicker-toggle>
              <mat-datepicker #myPicker1></mat-datepicker>
              <mat-error *ngIf="paymentForm?.get('txtDate')?.touched &&
                      paymentForm?.get('txtDate')?.errors">
                Enter Valid Date!
              </mat-error>
            </mat-form-field>

            <div class="col-3 mb-3">
              <br />
              <mat-checkbox (change)="isCompeltedConfirmation()" formControlName="iscompleted">Is Completed
              </mat-checkbox>
            </div>

          </div>
          <div class="row">
          </div>
        </mat-card-content>
      </div>
    </div>
  </form>
  <h4 class="customThemeClass text-left"><b>Insurance Payment List</b></h4>
  <div class="table-responsive">
    <table class="table table-bordered ">
      <thead>
        <th>Item Code</th>
        <th>DOS</th>
        <th>Billed</th>
        <th>Allowed</th>
        <th>Deduct</th>
        <th>CoInsurance</th>
        <th>Paid</th>
        <th>Write Off</th>
        <th>Pat Bal</th>
        <th>Options</th>
      </thead>
      <tbody>
        <tr *ngFor="let record of arrTableData; let i=index">
          <td>
            <pre>{{   record?.codes  }}</pre>
          </td>
          <td>
            {{ record?.dos| date: 'MM/dd/yyyy':'en_US' }}
          </td>
          <td>
            {{"$ "+record?.billed ||0 }}
          </td>
          <td>
            {{"$ "+record?.allowed ||0 }}
          </td>
          <td>
            {{"$ "+record?.deduct ||0 }}
          </td>
          <td>
            {{"$ "+record?.coinsurance ||0 }}
          </td>
          <td>
            {{"$ "+record?.insurancePaid ||0 }}
          </td>
          <td>
            {{"$ "+record?.writeOff ||0 }}
          </td>
          <td>
            {{"$ "+record?.patBalance ||0 }}
          </td>
          <td>
            <button mat-button class="buttonColor" [hidden]='isCompleted' (click)="updatePaymentDataUsingModal(record)">
              Update </button>
          </td>
        </tr>
        <tr>
          <td colspan="2" class="text-right"><b>Total</b></td>
          <td colspan="1"><b>{{"$ "+totalBilled}}</b> </td>
          <td colspan="1"><b>{{"$ "+totalAllowed}}</b> </td>
          <td colspan="1"><b>{{"$ "+totalDeduct}}</b></td>
          <td colspan="1"><b>{{"$ "+totalCoinsurance}}</b> </td>
          <td colspan="1"><b>{{"$ "+totalInsPaid}}</b> </td>
          <td colspan="1"><b>{{"$ "+totalWriteOffAmount}}</b></td>
          <td colspan="1"><b>{{"$ "+totalPatientAmount}}</b></td>
          <td colspan="1"></td>
        </tr>
      </tbody>
      <tfoot>
        <ng-container>
          <td class="text-align-center" mat-footer-cell *matFooterCellDef colspan="12">
            No Records Found
          </td>
        </ng-container>
      </tfoot>
    </table>
  </div>

</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button class="buttonColor" [hidden]="isCompleted" [disabled]='paymentForm.invalid'
    (click)="saveSecondaryInsurancePayments()" [disabled]="saveButtonHide">
    Save
  </button>
  <button class="buttonCancelColor" mat-button mat-dialog-close>Close</button>

</mat-dialog-actions>
