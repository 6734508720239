import type { CreateUpdateHolidayDTO, HolidayDTO, SearchHolidayDTO } from './dto/models';
import { RestService } from '@abp/ng.core';
import type { PagedAndSortedResultRequestDto, PagedResultDto } from '@abp/ng.core';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class HolidayService {
  apiName = 'OnTimeManagement';

  create = (input: CreateUpdateHolidayDTO) =>
    this.restService.request<any, HolidayDTO>({
      method: 'POST',
      url: '/api/OnTimeApp/holiday',
      body: input,
    },
     { apiName: this.apiName, skipHandleError: true });

  delete = (id: string) =>
    this.restService.request<any, void>({
      method: 'DELETE',
      url: `/api/OnTimeApp/holiday/${id}`,
    },
     { apiName: this.apiName, skipHandleError: true });

  get = (id: string) =>
    this.restService.request<any, HolidayDTO>({
      method: 'GET',
      url: `/api/OnTimeApp/holiday/${id}`,
    },
     { apiName: this.apiName, skipHandleError: true });

  getList = (input: PagedAndSortedResultRequestDto) =>
    this.restService.request<any, PagedResultDto<HolidayDTO>>({
      method: 'GET',
      url: '/api/OnTimeApp/holiday',
      params: { skipCount: input.skipCount, maxResultCount: input.maxResultCount, sorting: input.sorting },
    },
     { apiName: this.apiName, skipHandleError: true });

  update = (id: string, input: CreateUpdateHolidayDTO) =>
    this.restService.request<any, HolidayDTO>({
      method: 'PUT',
      url: `/api/OnTimeApp/holiday/${id}`,
      body: input,
    },
     { apiName: this.apiName, skipHandleError: true });

  searchHolidaysByInput = (input: SearchHolidayDTO) =>
    this.restService.request<any, PagedResultDto<HolidayDTO>>({
      method: 'GET',
      url: '/api/OnTimeApp/holiday/search-holidays',
      params: { holidayName: input.holidayName, holidayDate: input.holidayDate, holidayYear: input.holidayYear },
    },
     { apiName: this.apiName, skipHandleError: true });

  constructor(private restService: RestService) {}
}
