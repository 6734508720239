<meta http-equiv="Cache-control" content="no-cache" />
<div class="row p-2">
    <div class="col-lg-4">
        <h3 class="customThemeClass">
            <b> {{ headerText}} </b>
        </h3>
    </div>
    <div class="col-lg-8 pr-0 d-flex justify-content-end">
        <form class="col-lg-12 pr-0 d-flex justify-content-end" [formGroup]="batchSortingForm">
            <!-- Patient Search -->
            <mat-form-field class="col-lg-5 mr-2" id="newPatient">
                <mat-label>Patient <span class="asterisk">*</span></mat-label>
                <mat-select formControlName="drpSearchPatient">
                    <mat-option>
                        <ngx-mat-select-search hideClearSearchButton="true" placeholderLabel="Search"
                            noEntriesFoundLabel="No Matches found" (keyup)="searchPatient($event.target.value)"
                            formControlName="txtPatientFilter"></ngx-mat-select-search>
                    </mat-option>
                    <mat-option [value]="defaultGuid">New Patient</mat-option>
                    <!-- <mat-option *ngFor="let patients of filteredPatients | async" [value]="patients.guid">
                {{ patients.idAndNameAndDOB }}
              </mat-option> -->
                    <mat-option *ngFor="let chart of lstChartNos" [value]="chart.patientId">
                        {{ chart.ddValue }}
                    </mat-option>
                </mat-select>
                <button mat-button matSuffix (click)="ClearSearchPatient($event)" mat-icon-button>
                    <mat-icon>close</mat-icon>
                </button>
            </mat-form-field>
        </form>
    </div>
</div>
<div class="container-fluid">
    <app-common-sorting (headerText)="BindHearderText($event)" [docId]="docId" *ngIf="!isLoading"></app-common-sorting>
</div>