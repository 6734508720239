<div class="row">
    <div class="col-lg-12">
        <h2>Edit</h2>
    </div>

</div>
<mat-dialog-content class="mat-typography">
    <div class="row">
        <div class="col-lg-12">
            <mat-card-content>
                <form [formGroup]="shippingEditForm">
                <div class="row" *ngIf="showEditData">
                    <div class="col-lg-12">

                        <mat-form-field *ngIf="!serialNoText" class="col-6" >
                            <mat-label>Serial No </mat-label>
                            <input autocomplete="new-txtSerialNo" readonly maxlength="40" formControlName="txtSerialNo" matInput type="text" />

                        </mat-form-field>

                        <mat-form-field *ngIf="serialNoText" class="col-6" >
                            <mat-label>Serial No </mat-label>
                            <input autocomplete="new-txtSerialNo" maxlength="40" formControlName="txtSerialNo" matInput type="text" />

                        </mat-form-field>


                        <mat-form-field class="col-6">
                            <mat-label>Product Name </mat-label>
                            <input autocomplete="new-txtSerialNo" readonly maxlength="40" formControlName="txtProductName" matInput type="text" />

                        </mat-form-field>
                        <mat-form-field class="col-6">
                            <mat-label>Serial No Type </mat-label>
                            <mat-select readonly (selectionChange)="onEndsChanges($event.value)" maxlength="40" formControlName="txtSerialType"
                                [(value)]="type">
                                <mat-option *ngFor="let type of serialType"
                                    [value]="type.productSerialNoStatus">
                                    {{type.productSerialNoStatus}}
                                </mat-option>
                            </mat-select>
                            <button mat-button (click)="type=undefined;$event.stopPropagation()"
                                (click)="cleartype()" *ngIf="type" matSuffix mat-icon-button
                                >
                                <mat-icon>close</mat-icon>
                            </button>
                        </mat-form-field>
                        <mat-form-field class="col-6">
                            <mat-label>Lot No </mat-label>
                            <input autocomplete="new-txtLotNo" readonly maxlength="40" formControlName="txtLotNo" matInput type="text" />

                        </mat-form-field>

                        <mat-form-field class="col-6" *ngIf="showSerialNo">
                            <mat-label>Serial No</mat-label>
                            <mat-select formControlName="txtSerialNo" [(value)]="SerialNo" #singleSelect>
                              <mat-option>
                                <ngx-mat-select-search placeholderLabel="Search" noEntriesFoundLabel="No Matches found"
                                  formControlName="txtSerialNoFilter"></ngx-mat-select-search>
                              </mat-option>
                              <mat-option *ngFor="let provider of filterSerialNo | async" [value]="provider.newSerialNo">
                                {{provider.newSerialNo}}
                              </mat-option>
                            </mat-select>

                            <button mat-button (click)="SerialNo=undefined;$event.stopPropagation()" *ngIf="SerialNo"
                              matSuffix (click)="clearSerialNo()" mat-icon-button >
                              <mat-icon>close</mat-icon>
                            </button>

                          </mat-form-field>

                          <div *ngIf="showChk" class="col-6">
                            <mat-checkbox formControlName="chkDamage">Damage
                            </mat-checkbox>
                        </div>



                    </div>
                </div>
            </form>


                <div class="ml-2">
                    <h3 class="customThemeClass  mb-1"> Serail No List</h3>
                    <div class="row">
                        <div class="col-12">
                            <div class="row pt-1">
                                <table style="width:100%">
                                    <tr >
                                        <td> <b>Serial No</b></td>
                                        <td><b>Lot No</b></td>
                                        <td><b>Serial No Type</b></td>

                                        <td><b>Option</b></td>
                                    </tr>
                                    <tr *ngFor="let product of productTable">
                                        <td>  {{product.serialNo}}</td>
                                        <td> {{product.lotNo}} </td>
                                        <td> {{product.isSerialNumberMatch}} </td>
                                        <td>
                                            <a matTooltip="Click To View Provider">
                                                <i class="fa fa-pencil pointer editBtnColor pl-3"
                                                (click)="editDetails(product)" ></i>
                                            </a>
                                        </td>
                                    </tr>
                                </table>

                            </div>
                        </div>
                    </div>
                </div>
            </mat-card-content>
        </div>
    </div>
</mat-dialog-content>

<mat-dialog-actions align="end">
    <button *ngIf="showButton" (click)="saveSerialNo()" mat-button class="buttonColor">Save
    </button>
    <button *ngIf="showDamageBtn" (click)="saveReturnSerialNo()" mat-button class="buttonColor">Save
    </button>
    <button class="buttonCancelColor" mat-button mat-dialog-close>Close</button>

</mat-dialog-actions>
