import { HttpErrorResponse } from '@angular/common/http';
import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
// import { defaultGuid } from 'projects/admin/src/app/document-perviewer/document-perviewer.component';
import { SharedTableService } from 'projects/shared/src/app/shared-table/shared-table.service';
import { Subscription } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { PatientAddPolicyTabComponent } from '../patient-add-policy-tab/patient-add-policy-tab.component';
import { PatientPersonalService, PatientPolicyService } from '../patient-proxy/patient-optimization';
import { PatientPolicyDTO, PatientVerificationDTO } from '../patient-proxy/patient-optimization/dto';
import { ToastrService } from 'ngx-toastr';
import { CookieService } from 'ngx-cookie-service';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { FormGroup } from '@angular/forms';
import { MatRadioChange } from '@angular/material/radio';
import { PatientManualVerificationViewComponent } from '../patient-manual-verification-view/patient-manual-verification-view.component';
import { PatientManualVerificationTabComponent } from '../patient-manual-verification-tab/patient-manual-verification-tab.component';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { CommunicationService } from 'projects/shared/src/app/services/communication.service';
import { CommonService } from 'projects/shared/src/app/services/common.service';
import { defaultGuid } from 'projects/shared/src/app/enums/allenums.enum';
@Component({
  selector: 'app-patient-insurance-tab',
  templateUrl: './patient-insurance-tab.component.html',
  styleUrls: ['./patient-insurance-tab.component.scss'],
})
export class PatientInsuranceTabComponent implements OnInit, OnDestroy {
  strPageType: string = 'policy';
  @Output() patientIdEmitter: EventEmitter<string> = new EventEmitter<string>();
  @Output() viewClick: EventEmitter<any> = new EventEmitter<any>();
  @Input() patientId: string = defaultGuid;
  wipId: string;
  // dtOptions: { responsive: boolean; pageLength: number; lengthMenu: number[]; };
  dtOptions:any;
  public bOpenNewPolicy: boolean;
  isLoading: boolean = true;
  policyDetails:any;
  tableData$:Subscription;
  isShowSpinner: boolean = false;
  editFormValues: boolean = false;
  isShownSaveButton: boolean = true;
  isNewPatient: any;
  subscription$: Subscription[] = [];
  tableData: PatientPolicyDTO[] = [];
  saveButtonHide: boolean;
  defaultFaxId:string;
  documentId:string;
  PatientPersonalAPICall:Subscription;
  arrDisplayedColumns = [
    'OptionsForInsurance',
    'payorLevel',
    'payorId',
    'planType',
    'policyNo',
    'note',
    'eligibilityType',
    'eligibilityMode',
    'eligibility',
    'precert',
    'allowedPercentage',
    'status',

  ];
  selectedOption: number ;
  constructor(
    public dialog: MatDialog,
    private sharedTableService: SharedTableService,
    private PatientPolicyService: PatientPolicyService,
    private toastr: ToastrService,
    private cookieService: CookieService,
    public title: Title,
    public activatedRoute: ActivatedRoute,
    private communicationService: CommunicationService,
    private patientPersonalService: PatientPersonalService,
    private commonService: CommonService
  ) {
    this.communicationService.functionLoadPolicyList$.subscribe((statusId) => {
      this.getPolicyData();
    });
  }

  ngOnInit(): void {
    this.getPatientDetailsByID();
    this.title.setTitle('Qsecure | Policy');
    this.selectedOption=1;
    const activatedRoute = this.activatedRoute.paramMap.subscribe(
      (response) => {
        this.patientId = response.get('patientId');
      });
      this.dtOptions = {
        responsive: true,
        paging: false,
        scrollCollapse: true,
        // scrollY: '400px',
        // scrollX: true,
        info: false,
      };

    this.getPolicyData();
   }
  onRadioChange(): void {
   this.getPolicyData();
  }
  getPolicyData(){
    this.isLoading = true;
    this.tableData$ =
      this.PatientPolicyService.getPolicyActiveInactiveStatus(
        this.selectedOption,this.patientId
      ).subscribe(
        (response) => {
          this.policyDetails = response ?? [];
          this.policyDetails = this.policyDetails.map((policy) => {
            return {
              ...policy,
              lastVerifiedDate: this.commonService.getFormattedDateZone(policy?.lastVerifiedDate),
            };
          });
          this.isLoading = false;
        },
        (err) => {
          const data: HttpErrorResponse = err;
        }
      );
  }

  editForm() {
    this.editFormValues = true;
  }

  // getTooltip(column) {
  //   return column.payorId + ' / ' + column.payorName;
  // }
  //Page Destroy Function
  ngOnDestroy(): void {
    this.subscription$?.forEach((sub) => {
      sub && sub?.unsubscribe();
    });
  }
  //! open policy modal using config
  openPolicyModal() {
    const dialogRef = this.dialog.open(PatientAddPolicyTabComponent, {
      maxWidth: '80%',
      minHeight: '60%',
      disableClose: true,
      data: {
        patientId: this.patientId ?? defaultGuid,
        wipId: this.wipId ?? defaultGuid,
        defaultFaxId: this.defaultFaxId ?? defaultGuid,
        documentId: this.documentId ?? defaultGuid,
      },
    });
    const closeDialog = dialogRef.afterClosed().subscribe(
      (result) => {
        this.patientId !== defaultGuid && this.getPolicyData();
        this.patientIdEmitter.next(this.patientId);
      },
      (err) => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      }
    );
    this.subscription$.push(closeDialog);
    this.getPolicyData();
  }

  //To get the Table Data
  getTableData() {
    this.isLoading = true;
    this.sharedTableService.setTableData([]);
    const tableData$ =
      this.PatientPolicyService.getAllPolicyByPatientIdByPatientId(
        this.patientId
      ).subscribe(
        (response) => {
          this.tableData = response ?? [];
          this.sharedTableService.setTableData(this.tableData);
          this.isLoading = false;
        },
        (err) => {
          this.isLoading = false;
          this.sharedTableService.setTableData(this.tableData);
          const data: HttpErrorResponse = err;

          // Swal.fire({
          //   icon: 'info',
          //   text: data?.error?.error?.message,
          // });
        }
      );
    this.subscription$.push(tableData$);
  }

  // delete policy
  deletePolicy(policyId: string) {
    Swal.fire({
      title: 'Are you sure you want to Delete?',
      text: "You won't be able to retrieve this data!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#34c38f',
      cancelButtonColor: '#f46a6a',
      confirmButtonText: 'Yes, delete it!',
    }).then((result) => {
      if (result.value) {
        const deletePolicy = this.PatientPolicyService.delete(
          policyId
        ).subscribe(
          (response) => {
            this.getPolicyData();
            // Swal.fire({
            //   icon: 'success',
            //   text: 'Policy Deleted Successfully',
            // });
            this.toastr.success('Policy Deleted Successfully', 'Success');
            this.patientIdEmitter.next(this.patientId);
          },
          (err) => {
            const data: HttpErrorResponse = err;
            Swal.fire({
              icon: 'info',
              text: data?.error?.error?.message,
            });
          }
        );
      }
    });
  }
  // Set IsAcitve Status policy
  setIsActiveStatusPolicy(policyId: string) {
    const setIsActiveStatusPolicy = this.PatientPolicyService.get(policyId)
      .pipe(
        switchMap((x) => {
          x.isActive = !x.isActive;
          return this.PatientPolicyService.update(x.id, x);
        })
      )
      .subscribe(
        (response) => {
          this.patientIdEmitter.next(this.patientId);

          this.getPolicyData();
        },
        (err) => {
          const data: HttpErrorResponse = err;
          Swal.fire({
            icon: 'info',
            text: data?.error?.error?.message,
          });
        }
      );
    this.subscription$.push(setIsActiveStatusPolicy);
  }
  StatusChange(event: MatSlideToggleChange, data: any) {

    if (event.checked == true) {
      Swal.fire({
        title: 'Do you want to activate?',
        text: 'By Changing Status, this Policy will be activated',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#34c38f',
        cancelButtonColor: '#f46a6a',
        confirmButtonText: 'Yes, Activate it!'
      }).then(result => {
        if (result.value) {
          if (data?.policyId && data?.policyId !== "" && data?.policyId !== null && data?.policyId !== undefined) {
            this.PatientPolicyService.getPolicyActiveInactiveStatusV1(data?.policyId).subscribe(response => {
              this.isLoading = true;
              this.getPolicyData();
              this.toastr.success('Activated Successfully', 'Success')
            },
            (err) => {
              this.isLoading = true;
              this.getPolicyData();
              this.toastr.success('Activated Successfully', 'Success')
          });
          }
        }
        else {
          event.source.checked = false;
        }
      });
    }
    else
      if (event.checked == false) {
        Swal.fire({
          title: 'Do you want to deactivate?',
          text: 'By Changing Status, this  Policy will be deactivated',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#34c38f',
          cancelButtonColor: '#f46a6a',
          confirmButtonText: 'Yes, deactivate it!'
        }).then(result => {
          if (result.value) {
            if (data?.policyId && data?.policyId !== "" && data?.policyId !== null && data?.policyId !== undefined) {
              this.PatientPolicyService.getPolicyActiveInactiveStatusV1(data?.policyId).subscribe(response => {
                this.isLoading = true;
                this.getPolicyData();
                this.toastr.success('Deactivated Successfully', 'Success')
                },
                (err) => {
                  this.isLoading = true;
                  this.getPolicyData();
                  this.toastr.success('Deactivated Successfully', 'Success')
             });

            }
          }
          else {
            event.source.checked = true;
          }
        });
      }
  }

  getPatientDetailsByID(){
    if(this.PatientPersonalAPICall){
      this.PatientPersonalAPICall.unsubscribe();
    }
    this.patientPersonalService.getPatientByIdByGPatientId(this.patientId).subscribe(
        (response) => {
            this.defaultFaxId = response?.defaultFaxId;
            this.documentId = response?.inboundDocumentID;
        },
        (err) => {
          const data: HttpErrorResponse = err;
        }
      );
  }

  //To View The Policy
  // EditPolicy(policy: PatientPolicyDTO) {
  //   this.bOpenNewPolicy = false;
  //   const dialogRef = this.dialog.open(PatientAddPolicyTabComponent, {
  //     data: {
  //       patientId: this.patientId,
  //       policyId: policy.policyId,
  //       verificationId: policy?.lastVerificationId,
  //       wipId: this.wipId ?? defaultGuid,
  //       verificationStatus:policy.verificationStatus,
  //     },
  //   });
  //   const aftClosed = dialogRef.afterClosed().subscribe(
  //     (result) => {
  //       this.patientIdEmitter.next(this.patientId);

  //       this.patientId !== defaultGuid && this.getPolicyData(1);
  //     },
  //     (err) => {
  //       const data: HttpErrorResponse = err;
  //       Swal.fire({
  //         icon: 'info',
  //         text: data?.error?.error?.message,
  //       });
  //     }
  //   );
  //   this.subscription$.push(aftClosed);
  // }
  // viewVerificationById(verification: PatientVerificationDTO): void {
  //   const dialogRef = this.dialog.open(PatientManualVerificationTabComponent, {
  //     data: { patientId: this.patientId, verificationId: verification?.lastVerificationId, policyId: verification?.policyId,savehidden:true,
  //       verificationStatus:verification.verificationStatus, }
  //   });
  //   const closeDialog = dialogRef.afterClosed().subscribe((result) => {
  //     dialogRef?.close(result);
  //   });
  //   this.subscription$.push(closeDialog);
  // }
}
