<div class="account-pages mt-5 mb-5">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-md-8 col-lg-6 col-xl-5">
                <div class="card-body " style="text-align: center; ">
                    <div class="msg">
                        <div
                            style="border-radius:200px; height:200px; width:200px; background: #F8FAF5; margin:0 auto;">
                            <i class="checkmark">✓</i>
                        </div>
                        <h1 class="success">Success</h1>
                        <p class="sub"><strong>You Paid</strong> <strong> $ {{transactionAmount}}</strong> <br /> Thanks
                            For Your Payment, Your Transcation Has Been Completed!</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>