import { RestService } from '@abp/ng.core';
import type { PagedAndSortedResultRequestDto, PagedResultDto } from '@abp/ng.core';
import { Injectable } from '@angular/core';
import type { CreateUpdatePostingAuditLogDTO, PostingAuditLogDTO } from '../../billing-management/posting-audit-logs/models';

@Injectable({
  providedIn: 'root',
})
export class PostingAuditLogService {
  apiName = 'ClaimProcessingManagement';

  create = (input: CreateUpdatePostingAuditLogDTO) =>
    this.restService.request<any, PostingAuditLogDTO>({
      method: 'POST',
      url: '/api/claimProcessingApp/posting-audit-log',
      body: input,
    },
    { apiName: this.apiName });

  delete = (id: string) =>
    this.restService.request<any, void>({
      method: 'DELETE',
      url: `/api/claimProcessingApp/posting-audit-log/${id}`,
    },
    { apiName: this.apiName });

  get = (id: string) =>
    this.restService.request<any, PostingAuditLogDTO>({
      method: 'GET',
      url: `/api/claimProcessingApp/posting-audit-log/${id}`,
    },
    { apiName: this.apiName });

  getList = (input: PagedAndSortedResultRequestDto) =>
    this.restService.request<any, PagedResultDto<PostingAuditLogDTO>>({
      method: 'GET',
      url: '/api/claimProcessingApp/posting-audit-log',
      params: { skipCount: input.skipCount, maxResultCount: input.maxResultCount, sorting: input.sorting },
    },
    { apiName: this.apiName });

  update = (id: string, input: CreateUpdatePostingAuditLogDTO) =>
    this.restService.request<any, PostingAuditLogDTO>({
      method: 'PUT',
      url: `/api/claimProcessingApp/posting-audit-log/${id}`,
      body: input,
    },
    { apiName: this.apiName });

  generatesCommentsBySCommentsAndPropertyAndSChangedFromAndSChangedTo = (sComments: string, property: string, sChangedFrom: string, sChangedTo: string) =>
    this.restService.request<any, string>({
      method: 'POST',
      responseType: 'text',
      url: '/api/claimProcessingApp/posting-audit-log/generates-comments',
      params: { sComments, property, sChangedFrom, sChangedTo },
    },
    { apiName: this.apiName });

  getPostingAuditLogReportByClaimId = (claimId: string) =>
    this.restService.request<any, PostingAuditLogDTO[]>({
      method: 'GET',
      url: `/api/claimProcessingApp/posting-audit-log/get-posting-audit-log-report/${claimId}`,
    },
    { apiName: this.apiName });

  constructor(private restService: RestService) {}
}
