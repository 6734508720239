<div class="container-fluid">

  <div class="row bg">
    <div class="col-12 mt-3">
      <div class="d-flex justify-content-between">
        <h3 class="font-weight-bolder mb-0 pt-2">
          <b class="header-class">Patients List</b> <span *ngIf="isPatientSearch" class="red_clr"><b> - Recently added and updated Patients </b></span>
        </h3>

        <div class="page-title-box page-title-right pb-0">
          <div class="d-flex align-items-center">
            <!--New Patient Button-->
            <li>
              <button matTooltip="Click To Add New Patient" mat-button
                class="buttonColor font-size-10 mb-2 add-Newpatinet mr-3 mt-3" (click)="newPatient()">
                <i class="fa fa-plus"></i> New Patient
              </button>
            </li>
            <!--Uplaod Fax Button-->
            <li>
              <button mat-button class="buttonColor font-size-10 mb-2" [hidden]="true"
                (click)="addDocumentType(defaultGuid,defaultGuid)" style="min-width:40px;">
                <i class="fa fa-upload" matTooltip="Upload Fax"></i>
              </button>
            </li>
            <!--Inbound Button-->
            <a [routerLink]="'/inbound_sorting_pending'" class="preview-link">
              <button matTooltip="Click Go To Sorting Pending" mat-button
                class="buttonColor font-size-10 mb-2 add-Newpatinet mt-3">
                <img class="img-responsive mb-1 mr-1" height="20" src="assets/patient_list_icons/paste.png" /> Inbound
              </button>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="card card-body mt-4">
    <form [formGroup]="patientSearchForm">
      <div class="row pl-3">
        <!--Chart No-->
        <div class="col-sm-6 col-md-3 col-lg-2">
          <mat-form-field>
            <input matInput type="text" formControlName="ChartNo" placeholder="Chart No" maxlength="15" (ngModelChange)="onKeyPressSearch()"/>
          </mat-form-field>
        </div>
        <!--Patient Name-->
        <div class="col-sm-6 col-md-3 col-lg-2">
          <mat-form-field>
            <input matInput type="text" formControlName="PatientName" placeholder="Patient Name" maxlength="40" (ngModelChange)="onKeyPressSearch()"/>
          </mat-form-field>
        </div>
        <!--Birth Date-->
        <div class="col-sm-6 col-md-3 col-lg-2">
          <mat-form-field>
            <mat-label>DOB </mat-label>
            <input autocomplete="new-txtBirthdate" [max]="BirthdateMaxDate" formControlName="txtBirthdate" matInput
              maxlength="10" type="text" placeholder="(MM/DD/YYYY)" type="text" [matDatepicker]="txtBirthdate"
              [min]="BirthdateMinDate" (keydown)="addSlashToDate($event)" (ngModelChange)="onKeyPressSearch()"/>

            <mat-datepicker-toggle matSuffix [for]="txtBirthdate">
            </mat-datepicker-toggle>
            <mat-datepicker #txtBirthdate> </mat-datepicker>
            <mat-error *ngIf="BirthdateMinDate <= patientSearchForm?.get('txtBirthdate')?.value &&!(
                                patientSearchForm?.get('txtBirthdate')?.touched &&
                                patientSearchForm?.get('txtBirthdate')?.errors
                                 ?.required
                              )||patientSearchForm.get('txtBirthdate').errors
                            "> Enter a Valid Date!
            </mat-error>
          </mat-form-field>
        </div>

        <!--Payor Drop Down-->
        <div class="col-sm-6 col-md-3 col-lg-2">
          <mat-form-field>
            <mat-select formControlName="drpPrimaryPayor" multiple [(value)]="primaryPayorId"
              (selectionChange)="onSelectPrimaryPayor($event)" (openedChange)="onDropdownOpenChange($event)">
              <mat-option>
                <ngx-mat-select-search placeholderLabel="payerCode / payerName / Code" hideClearSearchButton="true"
                  [noEntriesFoundLabel]="drpDownMessage()" formControlName="primaryPayor"
                  (keyup)="payerSearch($event.target.value)">
                </ngx-mat-select-search>
              </mat-option>

              <mat-option *ngIf="!PayorSelectAllOption" [value]="'selectAll'">Select All</mat-option>
              <mat-option *ngIf="PayorSelectAllOption" [value]="'DeselectAll'">Deselect All</mat-option>

              <mat-option *ngFor="let payor of filteredPrimaryPayors | async" [value]="payor?.payerId" [title]="payor?.payerShortCodeList">
                {{ payor?.payerShortCodeList }}
              </mat-option>
            </mat-select>
            <mat-label> Select Payor </mat-label>
            <mat-hint class="text-danger fw-bolder" *ngIf="!isUniq"> Insurance Category Not Available!</mat-hint>
            <button type="button" mat-button (click)="clearPrimaryPolicy($event)"
              *ngIf="patientSearchForm.get('drpPrimaryPayor')?.value" matSuffix mat-icon-button>
              <mat-icon>close</mat-icon>
            </button>
          </mat-form-field>
        </div>

        <!--SSN-->
        <div class="col-sm-6 col-md-3 col-lg-1">
          <mat-form-field>
            <input matInput type="text" formControlName="ssnNo" maxlength="15" placeholder="SSN"
              oninput="this.value = this.value.replace(/[^0-9]+/g, '');" (ngModelChange)="onKeyPressSearch()"/>
          </mat-form-field>
        </div>

        <!--Search and Reset Buttons-->
        <div class="col-sm-6 col-md-12 col-lg-3 text-center mt-2">
          <button matTooltip="Click To Search" mat-button class="buttonColor font-size-10 mr-3 ml-2"
            (click)="fnSearchClick()" >
            <mat-icon>search</mat-icon> Search
          </button>
          <button mat-button class="resetclr font-size-10 mr-2" [disabled]="saveButtonDisabled"
            (click)="resetPatientSearch()">Reset</button>
        </div>
      </div>
    </form>

    <!--Table and Loading Indicator-->
    <div class="row">
      <div class="col-12 mt-1">
        <mat-divider></mat-divider>
      </div>
      <div class="col-12  mt-3" *ngIf="!isLoading">
        <div *ngIf="!isLoading" class="table table-responsive">
          <app-patient-list-table-v2 [tableData]="patientListDataArray" (getVisibleColumns)="fnGetVisibleColumns($event)"
            (nevigatePage)="changePage($event)" [visibleColumns]="visibleColumns" [currentPage]="currentPage" [totalPages]="totalPages">
          </app-patient-list-table-v2>
        </div>
      </div>
    </div>
    <div class="row" *ngIf="isLoading">
      <div class="col-12">
        <div class="d-flex justify-content-center" *ngIf="isLoading">
          <img class="img-responsive pb-2 mr-1" src="assets/gifs/Infinity-loader.gif" />
        </div>
      </div>
    </div>
  </div>
  </div>
