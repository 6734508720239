import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table/table-data-source';

@Component({
  selector: 'app-insurance-report',
  templateUrl: './insurance-report.component.html',
  styleUrls: ['./insurance-report.component.scss']
})
export class InsuranceReportComponent implements OnInit {
  // Declartions
  insuranceReportForm: FormGroup;
  dataSource: MatTableDataSource<any>;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  displayedColumns: string[] = [  'options','insurnceName', 'dateOfService', 'patientName', 'itemCode', 'itemQuantity',];
  isLoading: boolean = false;

  constructor(
    public formBuilder: FormBuilder,
  ) { }
  // initialize funtions
  ngOnInit(): void {
    this.insuranceReportForm = this.formBuilder.group({
      txtProductName: new FormControl(""),
      txtProductFilter: new FormControl(""),
      txtInsuranceName: new FormControl(""),
      txtInsuranceFilter: new FormControl(""),
      txtCreatedate: new FormControl(""),
    });
  }
  // Search Fields
  search() {
  }
  // reset Field
  reset() {
  }
  // Open Modal Fundtionality
  openAllowablesEditModal(value) {
  }
}
