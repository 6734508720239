<div class="col-12" style="margin-top: -18px;">
  <div class=" d-flex align-items-center justify-content-between m-0">
    <h4 class="font-size-18 m-0"><b>Patient Information</b></h4>
    <div class=" page-title-box page-title-right">
      <ol class="breadcrumbs m-0">
        <li class="mr-2">
          <button *ngIf='isShownUnlockButton' matTooltip="Click To Unlock Patient" mat-button
            class="unlockButtonColor font-size-10 unlockButtonblinking" (click)="unlockPatient()">
            <i class="fa fa-lock-open mr-1"></i>
            Unlock Patient
          </button>
        </li>
        <li class="breadcrumb-item cls eyeCursorclass">Patient</li>
        <li class="breadcrumb-item active eyeCursorclass">{{strUrlPath==="patientCreateEdit"?"New":"View"}} Patient</li>
      </ol>
    </div>
  </div>
</div>


<div class="row" style="margin-top: -27px;">
  <div class="col-xl-2 pr-0">
    <div class="card card-body">
      <div class="text-center">
        <span style="font-size: larger;"><b class="colored-heading">Patient ID </b></span>
        <h3 style="margin: 0 0 4px;"><b>{{ lblDefaultPatientId }}</b></h3>
      </div>
      <div class="text-center">
        <span style="font-size: larger;"><b class="colored-heading">Patient Name </b></span>
        <h3 style="margin: 0 0 4px;"><b>{{ lblPatientName }}</b></h3>
      </div>
      <div class="table-responsive mt-2">
        <table class="table table-centered">
          <tbody>
            <!-- Newly aded param -->
            <tr>
              <td class="blinking">
                <p class="mb-0 text-center">CurrentStatus</p> <br>
                <h5 class="btm text-center">{{ patientStatus }}</h5>
              </td>
            </tr>
            <!-- Newly aded param -->

            <tr>
              <td>
                <p class="mb-0 text-center">Location</p> <br>
                <h5 class="btm text-center">{{ strLocation }}</h5>
              </td>
            </tr>
            <tr>
              <td>
                <p class="mb-0 text-center">Gender</p> <br>
                <h5 class="btm text-center">{{ lblPatientGender }}</h5>
              </td>
            </tr>
            <tr>
              <td>
                <p class="mb-0 text-center">DOB</p> <br>
                <h5 class="btm text-center">{{ lblPatientDOB }}</h5>
              </td>
            </tr>
            <!-- <tr>
              <td>
                <p class="mb-0 text-center">Height</p> <br>
                <h5 class="btm text-center">{{ lblPatientHeight | mask: "0'00''" }}</h5>
              </td>
            </tr>
            <tr>
              <td>
                <p class="mb-0 text-center">Weight(Lbs)</p> <br>
                <h5 class="btm text-center">{{ lblPatientWeight }}</h5>
              </td>
            </tr>
            <tr>
              <td>
                <p class="mb-0 text-center">Branch Office </p> <br>
                <h5 class="btm text-center">{{ lblBranchOffice }}</h5>
              </td>
            </tr>
            <tr>
              <td>
                <p class="mb-0  text-center">Account </p><br>
                <h5 class="btm text-center">{{ lblAccountNumber }}</h5>
              </td>
            </tr>-->
            <tr>
              <td>
                <p class="mb-0  text-center">SSN</p><br>
                <h5 class="btm  text-center">{{ lblSsnNumber }}</h5>
              </td>
              <!-- <td style="width: 60%">
                <h5 class="mb-0">{{ lblSsnNumber }}</h5>
              </td> -->
            </tr>

            <tr>
              <td>
                <p class="mb-0  text-center">Mobile</p> <br>
                <h5 class="btm  text-center">{{ strMobile }}</h5>
              </td>
              <!-- <td style="width: 60%">
                <h5 class="mb-0">{{ strMobile }}</h5>
              </td> -->
            </tr>
            <tr>
              <td>
                <p class="mb-0 text-center">Billing Country</p><br>
                <h5 class="btm  text-center">{{ strBillingCountry }}</h5>
              </td>
              <!-- <td style="width: 60%">
                <h5 class="mb-0">{{ strEmailAddress }}</h5>
              </td> -->
            </tr>
            <!-- <tr>
              <td>
                <p class="mb-0">Billing Address : {{ strBillingAddress }}</p>
              </td>

            </tr> -->

            <tr>
              <td>
                <p class="mb-0 text-center">Billing City</p><br>
                <h5 class="btm  text-center">{{ strBillingCity }}</h5>
              </td>
              <!-- <td style="width: 50%">
                <h5 class="mb-0">{{ strBillingCity }}</h5>
              </td> -->
            </tr>
            <tr>
              <td>
                <p class="mb-0 text-center">Billing State</p><br>
                <h5 class="btm  text-center">{{ strBillingState }}</h5>
              </td>
              <!-- <td >
                <h5 class="mb-0">{{ strBillingState }}</h5>
              </td> -->
            </tr>
            <!-- <tr>
              <td class="blinking">
                <p class="mb-0 text-center">Current Status</p> <br>
                <h5 class="btm text-center">{{ lblPtCurrentStatus }}</h5>
              </td>
            </tr> -->
            <tr>
              <td *ngIf="forBlink">
                <p class="mb-0 text-center">Billing Amount</p> <br>
                <h5 class="btm text-center">{{ lblBillingAmount }}</h5>
              </td>
            </tr>
            <tr>
              <td *ngIf="forBlink">
                <p class="mb-0 text-center">Billing Status</p> <br>
                <h5 class="btm text-center">{{ lblBillingStatus }}</h5>
              </td>
            </tr>

            <tr>
              <td *ngIf="!forBlink" class="blinking">
                <p class="mb-0 text-center">Billing Amount</p> <br>
                <h5 class="btm text-center">{{ lblBillingAmount }}</h5>
              </td>
            </tr>
            <!-- <tr>
              <td *ngIf="!forBlink" class="blinking">
                <p class="mb-0 text-center">Billing Status</p> <br>
                <h5 class="btm text-center">{{ lblBillingStatus }}</h5>
              </td>
            </tr> -->
            <tr *ngIf="lblLastUpdatedDateTime">
              <td>
                <p class="mb-0 text-center">Last Updated On </p> <br>
                <h5 class="btm text-center">{{ lblLastUpdatedDateTime | date :'MM/dd/yyyy h:mm a' :'en_US' }}
                </h5>
              </td>
            </tr>
            <tr *ngIf="lbllastModifier">
              <td>
                <p class="mb-0 text-center">Last Updated By </p> <br>
                <h5 class="btm text-center">{{ lbllastModifier }}
                </h5>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  <div class="col-xl-10">
    <!-- <div class="card card-body"> -->
    <!-- <ngb-tabset class="nav-tabs-custom" #tabset="ngbTabset" type="tabs"
      (tabChange)="onTabChange($event)" justify="justified"> -->
    <!-- <div class="d-flex"> -->
    <ul class="card card-body" ngbNav #nav="ngbNav" justify="justified" (navChange)="onNavChange($event)"
      class="alert alert-success nav nav-pills nav-fill">
      <li ngbNavItem="Personal">
        <!-- <img class="img-responsive " src="assets\images\menu icons\tabs icon\Personal.png"  alt="" height="15"> -->
        <a ngbNavLink>Personal</a>
        <ng-template ngbNavContent>
          <app-patient-personal (patientIdEmitter)="patientDetailsAfterSave($event)"
            (savePersonalFormState)="onSavePersonalData($event)" (nextTabMoveOnSaveEmitter)="changeNextTab($event)"
            [patientId]="patientId">
          </app-patient-personal>
        </ng-template>
      </li>
      <li ngbNavItem="Contact" [disabled]="!patientId" class="nav-item">
        <!-- <img class="img-responsive" src="assets\images\menu icons\tabs icon\Contacts.png"  alt="" height="15"> -->
        <a ngbNavLink id='tab-2'>Contact</a>
        <ng-template ngbNavContent>
          <app-patient-contact (patientIdEmitter)="patientDetailsAfterSave($event)" [patientId]="patientId"
            (nextTabMoveOnSaveEmitter)="changeNextTab($event)" (saveContactFormState)="onSaveContactData($event)">
          </app-patient-contact>
        </ng-template>
      </li>
      <li ngbNavItem="Clinical" [disabled]="!patientId">
        <!-- <img class="img-responsive " src="assets\images\menu icons\tabs icon\Clinical.png"  alt="" height="15"> -->
        <a ngbNavLink id='tab-3'>Clinical</a>
        <ng-template ngbNavContent>
          <app-patient-clinical (patientIdEmitter)="patientDetailsAfterSave($event)" [patientId]="patientId"
            (nextTabMoveOnSaveEmitter)="changeNextTab($event)" (saveClinicalFormState)="onSaveClinicalData($event)">
          </app-patient-clinical>
        </ng-template>
      </li>
      <!-- [patient]="patient" -->
      <li ngbNavItem="Document" [disabled]="!patientId">
        <!-- <img class="img-responsive" src="assets\images\menu icons\tabs icon\Document.png"  alt="" height="2"> -->
        <a ngbNavLink>Document</a>
        <ng-template ngbNavContent>
          <app-patient-document [patientID]="patientId" (nextTabMoveOnSaveEmitter)="changeNextTab($event)"
            (saveDocumentFormState)="onSaveDocumentData($event)"></app-patient-document>
        </ng-template>
      </li>

      <li *ngIf='isShownOrderTab' [disabled]="!patientId" ngbNavItem="Order">
        <!-- <img class="img-responsive " src="assets\images\menu icons\tabs icon\order.png"  alt="" height="15"> -->
        <a ngbNavLink>Order</a>
        <ng-template ngbNavContent>
          <app-patient-order [patientId]="patientId">
          </app-patient-order>
        </ng-template>
      </li>
      <li ngbNavItem="Insurance" [disabled]="!patientId">
        <!-- <img class="img-responsive  " src="assets\images\menu icons\tabs icon\Insurance.png"  alt="" height="15"> -->
        <a ngbNavLink id='tab-6'>Insurance</a>
        <ng-template ngbNavContent>
          <app-patient-insurnace (saveInsuranceFormState)="onSaveInsuranceData($event)"
            (nextTabMoveOnSaveEmitter)="changeNextTab($event)" [patientId]="patientId">
          </app-patient-insurnace>
        </ng-template>
      </li>
      <li *ngIf='isShownAuthTab' ngbNavItem="Authorization" [disabled]="!patientId && !patientId">
        <!-- <img class="img-responsive  " src="assets\images\menu icons\tabs icon\Auth.png"  alt="" height="15"> -->
        <a ngbNavLink> Authorization</a>
        <ng-template ngbNavContent>
          <!-- (saveAuthorizationFormState)="onSaveAuthorizationData($event)"
            (nextTabMoveOnSaveEmitter)="changeNextTab($event)" -->
          <app-patient-auth (patientIdEmitter)="patientDetailsAfterSave($event)" [patientId]="patientId">
          </app-patient-auth>
        </ng-template>
      </li>
      <li ngbNavItem="Notes">
        <!-- <img class="img-responsive" src="assets\images\menu icons\tabs icon\Notes.png"  alt="" height="15"> -->
        <a ngbNavLink> Notes</a>
        <ng-template ngbNavContent>
          <app-patient-notes (patientIdEmitter)="patientDetailsAfterSave($event)" [patientID]="patientId">
          </app-patient-notes>
        </ng-template>
      </li>

      <li *ngIf='isShownOrderTab' ngbNavItem="OrderStatus" [disabled]="!patientId">
        <!-- <img class="img-responsive " src="assets\images\menu icons\tabs icon\Order-Status.png"  alt="" height="15"> -->
        <a ngbNavLink>Order Status</a>
        <ng-template ngbNavContent>
          <app-patient-order-status [patientId]="patientId"></app-patient-order-status>
        </ng-template>
      </li>
      <li *ngIf='isShownOrderTab' ngbNavItem="patientClaim" [disabled]="!patientId">
        <a ngbNavLink>Claims</a>
        <ng-template ngbNavContent>
          <app-patient-claims [patientId]="patientId"></app-patient-claims>
        </ng-template>
      </li>
      <li ngbNavItem="History" [disabled]="!patientId">
        <a ngbNavLink>History</a>
        <ng-template ngbNavContent>
          <app-patient-history [patientId]="patientId"></app-patient-history>
        </ng-template>
      </li>
    </ul>

    <div [ngbNavOutlet]="nav"></div>
    <!-- </div> -->
  </div>
</div>

<button [matMenuTriggerFor]="menu" mat-button matTooltip="Add" class="clr mat-fab-bottom-right rightfloatfabbutton"
  mat-mini-fab>
  <mat-icon class="icon">add</mat-icon>
</button>

<mat-menu #menu="matMenu">
  <button (click)='openAddNotesPopUp()' mat-menu-item matTooltip="Add Notes"> Add Notes
    <mat-icon class="icon">add</mat-icon>
  </button>
  <button (click)='openViewNotesPopup()' mat-menu-item matTooltip="View Notes"> View Notes
    <mat-icon class="icon">remove_red_eye</mat-icon>
  </button>
</mat-menu>
