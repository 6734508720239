import { RestService } from '@abp/ng.core';
import { Injectable } from '@angular/core';

import type { NotesDTO } from './dto/models';
import type { PagedResultDto } from '@abp/ng.core';
@Injectable({
  providedIn: 'root',
})
export class NotesSearchService {
  apiName = 'Default';

  searchNotesByInput = (input: any) =>
    this.restService.request<any, PagedResultDto<NotesDTO>>({
      method: 'GET',
      url: '/api/app/notes-search/search-notes',
      params: { patientId: input.patientId, notesId: input.notesId, createdBy: input.createdBy, dateCreated: input.dateCreated, actualDate: input.actualDate, dateNeeded: input.dateNeeded, noteType: input.noteType, noteReason: input.noteReason, status: input.status },
    },
     { apiName: this.apiName, skipHandleError: true });

  constructor(private restService: RestService) {}
}
