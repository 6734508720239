import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { OrganizationUnitDTO } from 'projects/admin/src/app/admin-proxy/platform-app-management/rcm/platform-management/organization-units/dto/models';
import { IdentityUserUsingTenantIdDto } from 'projects/admin/src/app/admin-proxy/platform-app-management/rcm/platform-management/user/models';
import { UserService } from 'projects/admin/src/app/admin-proxy/platform-app-management/rcm/platform-management/user/user.service';
import { CoverageAreaTypeDTO, OnTimeMasterDropdownDTO, WorkingHoursDTO, WorkingTimeDTO, WorkingWeeksDTO } from 'projects/patient/src/app/dropdown-proxy/dropdown-management/dropdowns/dto/on-time-schedule/models';
import { OnTimeMasterDropdownService } from 'projects/patient/src/app/dropdown-proxy/dropdown-management/dropdowns/master-dropdown-service/on-time-master-dropdown.service';
import { OnTimeDropdowns } from 'projects/patient/src/app/dropdown-proxy/dropdown-management/dropdowns/on-time-dropdowns.enum';
import Swal from 'sweetalert2';

import { CreateUpdateOfficeWorkingHoursDTO, CreateUpdateUserDetailsDTO, GetUsersDTO } from '../onTime-proxy/rcm/on-time-management/on-time-schedule/dto';
import { OfficeWorkingHoursService } from '../onTime-proxy/rcm/on-time-management/on-time-schedule/office-working-hours.service';
import { WeekDays } from '../onTime-proxy/rcm/on-time-management/on-time-schedule/week-days.enum';
import { ToastrService } from "ngx-toastr";


@Component({
  selector: 'app-add-branch-work-hours',
  templateUrl: './add-branch-work-hours.component.html',
  styleUrls: ['./add-branch-work-hours.component.scss'],

})
export class AddBranchWorkHoursComponent implements OnInit {
  AddWorkForm: FormGroup;
  drpCoverage: CoverageAreaTypeDTO[];
  drpStartEnd: WorkingHoursDTO[];
  drpStartAt: WorkingTimeDTO[] = [];
  drpWorkOptn: WorkingWeeksDTO[];
  arrUsersList: any[] = [];
  arr2UsersList: any[] = [];
  sunday: string = "Leave";
  monday: string = "Leave";
  tuesday: string = "Leave";
  wedday: string = "Leave";
  thuday: string = "Leave";
  Friday: string = "Leave";
  Satday: string = "Leave";
  sundayLeaveHide: boolean = false;
  mondayLeaveHide: boolean = false;
  tuedayLeaveHide: boolean = false;
  weddayLeaveHide: boolean = false;
  thudayLeaveHide: boolean = false;
  fridayLeaveHide: boolean = false;
  satdayLeaveHide: boolean = false;
  sundayHide: boolean = false;
  sunMore: boolean = true;
  monMore: boolean = true;
  tueMore: boolean = true;
  wedMore: boolean = true;
  thuMore: boolean = true;
  friMore: boolean = true;
  satMore: boolean = true;
  mondayHide: boolean = false;
  TuedayHide: boolean = false;
  WeddayHide: boolean = false;
  ThudayHide: boolean = false;
  FridayHide: boolean = false;
  SatdayHide: boolean = false;
  userTableData: any[] = [];
  drpOrganization: OrganizationUnitDTO[] = [];
  tenantId: string = "";
  condition: any;
  sundayResponse: any;
  mondayResponse: any;
  tuesdayResponse: any;
  wednesdayResponse: any;
  thursdayResponse: any;
  fridayResponse: any;
  saturdayResponse: any;
  constructor(private formBuilder: FormBuilder,
    public notesDialogRef: MatDialogRef<AddBranchWorkHoursComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { branchId: string, wrokingbranchId: string, BranchName: string },
    private onTimeDropdown: OnTimeMasterDropdownService,
    private branchWorkHours: OfficeWorkingHoursService,
    private toastr: ToastrService,
    private userService: UserService) { }

  ngOnInit(): void {

    this.AddWorkForm = this.formBuilder.group({
      drpWorkOptn: new FormControl(""),
      drpStartAt: new FormControl(""),
      drpStartEnd: new FormControl(""),
      drpSelCov: new FormControl(""),
      AddSundayStatus: new FormControl(""),
      drpUser: new FormControl(""),

      drp2WorkOptn: new FormControl(""),
      drp2StartAt: new FormControl(""),
      drp2StartEnd: new FormControl(""),
      drp2SelCov: new FormControl(""),
      drp2User: new FormControl(""),

      /// monday
      AddMondayStatus: new FormControl(""),
      drpMonWorkOptn: new FormControl(""),
      drpMonStartAt: new FormControl(""),
      drpMonStartEnd: new FormControl(""),
      drpMonSelCov: new FormControl(""),
      drpMonUser: new FormControl(""),

      drpMon2WorkOptn: new FormControl(""),
      drpMon2StartAt: new FormControl(""),
      drpMon2StartEnd: new FormControl(""),
      drpMon2SelCov: new FormControl(""),
      drpMon2User: new FormControl(""),

      /// tuesday
      AddTuesdayStatus: new FormControl(""),
      drpTueWorkOptn: new FormControl(""),
      drpTueStartAt: new FormControl(""),
      drpTueStartEnd: new FormControl(""),
      drpTueSelCov: new FormControl(""),
      drpTueUser: new FormControl(""),

      drpTue2WorkOptn: new FormControl(""),
      drpTue2StartAt: new FormControl(""),
      drpTue2StartEnd: new FormControl(""),
      drpTue2SelCov: new FormControl(""),
      drpTue2User: new FormControl(""),

      /// Wednesday
      AddWednesdayStatus: new FormControl(""),
      drpWedWorkOptn: new FormControl(""),
      drpWedStartAt: new FormControl(""),
      drpWedStartEnd: new FormControl(""),
      drpWedSelCov: new FormControl(""),
      drpWedUser: new FormControl(""),

      drpWed2WorkOptn: new FormControl(""),
      drpWed2StartAt: new FormControl(""),
      drpWed2StartEnd: new FormControl(""),
      drpWed2SelCov: new FormControl(""),
      drpWed2User: new FormControl(""),

      /// Thunesday
      AddThudayStatus: new FormControl(""),
      drpThuWorkOptn: new FormControl(""),
      drpThuStartAt: new FormControl(""),
      drpThuStartEnd: new FormControl(""),
      drpThuSelCov: new FormControl(""),
      drpThuUser: new FormControl(""),

      drpThu2WorkOptn: new FormControl(""),
      drpThu2StartAt: new FormControl(""),
      drpThu2StartEnd: new FormControl(""),
      drpThu2SelCov: new FormControl(""),
      drpThu2User: new FormControl(""),

      /// Frinesday
      AddFridayStatus: new FormControl(""),
      drpFriWorkOptn: new FormControl(""),
      drpFriStartAt: new FormControl(""),
      drpFriStartEnd: new FormControl(""),
      drpFriSelCov: new FormControl(""),
      drpFriUser: new FormControl(""),

      drpFri2WorkOptn: new FormControl(""),
      drpFri2StartAt: new FormControl(""),
      drpFri2StartEnd: new FormControl(""),
      drpFri2SelCov: new FormControl(""),
      drpFri2User: new FormControl(""),

      /// Satday
      AddSatdayStatus: new FormControl(""),
      drpSatWorkOptn: new FormControl(""),
      drpSatStartAt: new FormControl(""),
      drpSatStartEnd: new FormControl(""),
      drpSatSelCov: new FormControl(""),
      drpSatUser: new FormControl(""),

      drpSat2WorkOptn: new FormControl(""),
      drpSat2StartAt: new FormControl(""),
      drpSat2StartEnd: new FormControl(""),
      drpSat2SelCov: new FormControl(""),
      drpSat2User: new FormControl("")
    })
    this.getDropDown();

    this.tenantId = localStorage.getItem("tenantId") ?? "";
    const filters: IdentityUserUsingTenantIdDto = {
      tenantId: this.tenantId
    }
    this.userService.getUserList(filters).subscribe(response => {
      this.userTableData = [];
      response && response?.forEach(element => {

        if (element.roleName == "Scheduler" || element.roleName == "Respiratory Therapist") {
          this.userTableData.push({
            name: element?.userName,
            email: element?.email,
            // branch: element.extraProperties.organizationUnitId,
            // branch: this.drpOrganization?.filter(v => v?.id === element?.extraProperties.organizationUnitId)[0]?.organizationUnitName,
            role: element?.roleName,
            userId: element?.id,
          });
        }
      })

      if (this.data.wrokingbranchId !== "00000000-0000-0000-0000-000000000000" || this.data.wrokingbranchId !== null) {
        this.getAddHoursById();
      }
    }, err => {
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    })
  }


  /// dropdown datas
  getDropDown() {
    let onTimeDropdownInput: OnTimeDropdowns[];
    onTimeDropdownInput = [
      OnTimeDropdowns.CoverageAreaType,
      OnTimeDropdowns.WorkingWeeks,
      OnTimeDropdowns.WorkingHoursStart,
    ]
    this.onTimeDropdown.getOnTimeDropdownsByInput(onTimeDropdownInput).subscribe((stateResponse) => {
      const response: OnTimeMasterDropdownDTO = stateResponse;
      this.drpCoverage = response?.coverageAreaTypes;
      this.drpWorkOptn = response?.workingWeeks;
      // this.drpStartAt = response?.workingHoursStarts;
    }, err => {
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    });
    this.onTimeDropdown.getWorkTimings().subscribe((stateResponse) => {
      this.drpStartAt = stateResponse.workingTimes
      // stateResponse.workingTimes.forEach(ele => {
      // this.drpStartAt.push({
      //   startAt: ele?.startTimeShortCodeId
      // })
      // })
    })
  }
  // get Add Hours byid
  getAddHoursById() {

    // for second array edit open
    this.branchWorkHours.get(this.data.branchId).subscribe(response => {
      this.sundayResponse = response?.officeSundayWorkingHours.officeWorkDetails[1]?.userDetails
      this.mondayResponse = response?.officeMondayWorkingHours.officeWorkDetails[1]?.userDetails
      this.tuesdayResponse = response?.officeTuesdayWorkingHours.officeWorkDetails[1]?.userDetails
      this.wednesdayResponse = response?.officeWednesdayWorkingHours.officeWorkDetails[1]?.userDetails
      this.thursdayResponse = response?.officeThursdayWorkingHours.officeWorkDetails[1]?.userDetails
      this.fridayResponse = response?.officeFridayWorkingHours.officeWorkDetails[1]?.userDetails
      this.saturdayResponse = response?.officeSaturdayWorkingHours.officeWorkDetails[1]?.userDetails

      if(this.sundayResponse?.length !== 0 && this.sundayResponse?.length !== undefined){
        this.addAccordion();
      }
      if (this.mondayResponse?.length !== 0 && this.mondayResponse?.length !=undefined) {
        this.addMonday();
      }
      if (this.tuesdayResponse?.length !== 0 && this.tuesdayResponse?.length !=undefined) {
        this.addTueday();
      }
      if (this.wednesdayResponse?.length !== 0 && this.wednesdayResponse?.length !=undefined) {
        this.addWedday();
      }
      if (this.thursdayResponse?.length !== 0 && this.thursdayResponse?.length !=undefined) {
        this.addThuday();
      }
      if (this.fridayResponse?.length !== 0 && this.fridayResponse?.length !=undefined) {
        this.addFriday();
      }
      if (this.saturdayResponse?.length !== 0 && this.saturdayResponse?.length !=undefined) {
        this.addSatday();
      }


      // let res = response?.
      let sundayStart;
      let mondayStart;
      let tuedayStart;
      let weddayStart;
      let thudayStart;
      let fridayStart;
      let satdayStart;
      let sunday2Start;
      let monday2Start;
      let tueday2Start;
      let wedday2Start;
      let thuday2Start;
      let friday2Start;
      let satday2Start;

      sundayStart = this.drpStartAt?.filter(value => {

        return value && value?.startTimeShortCodeId === response?.officeSundayWorkingHours?.officeWorkDetails[0]?.opensAt
      })[0];

      sunday2Start = this.drpStartAt?.filter(value => {
        return value && value?.startTimeShortCodeId === response?.officeSundayWorkingHours?.officeWorkDetails[1]?.opensAt
      })[0];

      mondayStart = this.drpStartAt?.filter(value => {
        return value && value?.startTimeShortCodeId === response?.officeMondayWorkingHours?.officeWorkDetails[0]?.opensAt
      })[0];

      monday2Start = this.drpStartAt?.filter(value => {
        return value && value?.startTimeShortCodeId === response?.officeMondayWorkingHours?.officeWorkDetails[1]?.opensAt
      })[0];

      tuedayStart = this.drpStartAt?.filter(value => {
        return value && value?.startTimeShortCodeId === response?.officeTuesdayWorkingHours?.officeWorkDetails[0]?.opensAt
      })[0];
      tueday2Start = this.drpStartAt?.filter(value => {
        return value && value?.startTimeShortCodeId === response?.officeTuesdayWorkingHours?.officeWorkDetails[1]?.opensAt
      })[0];

      weddayStart = this.drpStartAt?.filter(value => {
        return value && value?.startTimeShortCodeId === response?.officeWednesdayWorkingHours?.officeWorkDetails[0]?.opensAt
      })[0];
      wedday2Start = this.drpStartAt?.filter(value => {
        return value && value?.startTimeShortCodeId === response?.officeWednesdayWorkingHours?.officeWorkDetails[1]?.opensAt
      })[0];

      thudayStart = this.drpStartAt?.filter(value => {
        return value && value?.startTimeShortCodeId === response?.officeThursdayWorkingHours?.officeWorkDetails[0]?.opensAt
      })[0];
      thuday2Start = this.drpStartAt?.filter(value => {
        return value && value?.startTimeShortCodeId === response?.officeThursdayWorkingHours?.officeWorkDetails[1]?.opensAt
      })[0];

      fridayStart = this.drpStartAt?.filter(value => {
        return value && value?.startTimeShortCodeId === response?.officeFridayWorkingHours?.officeWorkDetails[0]?.opensAt
      })[0];
      friday2Start = this.drpStartAt?.filter(value => {
        return value && value?.startTimeShortCodeId === response?.officeFridayWorkingHours?.officeWorkDetails[1]?.opensAt
      })[0];

      satdayStart = this.drpStartAt?.filter(value => {
        return value && value?.startTimeShortCodeId === response?.officeSaturdayWorkingHours?.officeWorkDetails[0]?.opensAt
      })[0];
      satday2Start = this.drpStartAt?.filter(value => {
        return value && value?.startTimeShortCodeId === response?.officeSaturdayWorkingHours?.officeWorkDetails[1]?.opensAt
      })[0];

      if (response?.officeSundayWorkingHours?.isOpen == 1) {
        this.sunday = "Working"
        this.sundayLeaveHide = true;
      }
      if (response?.officeSundayWorkingHours?.isOpen == 0) {
        this.sunday = "Leave"
        this.sundayLeaveHide = false;
        this.AddWorkForm.patchValue({
          drpStartAt: "",
          drpStartEnd: "",
          drpSelCov: "",
          AddSundayStatus: "",
          drpUser: "",
          drp2WorkOptn: "",
          drp2StartAt: "",
          drp2StartEnd: "",
          drp2SelCov: "",
          drp2User: "",
        })


      }
      if (response?.officeMondayWorkingHours?.isOpen == 1) {
        this.monday = "Working"
        this.mondayLeaveHide = true;
      }
      if (response?.officeMondayWorkingHours?.isOpen == 0) {
        this.monday = "Leave"
        this.mondayLeaveHide = false;
        this.AddWorkForm.patchValue({
          drpMonWorkOptn: "",
          drpMonStartAt: "",
          drpMonStartEnd: "",
          drpMonSelCov: "",
          drpMonUser: "",
          drpMon2WorkOptn: "",
          drpMon2StartAt: "",
          drpMon2StartEnd: "",
          drpMon2SelCov: "",
          drpMon2User: "",
        })

      }
      if (response?.officeTuesdayWorkingHours?.isOpen == 1) {
        this.tuesday = "Working"
        this.tuedayLeaveHide = true;
      }
      if (response?.officeTuesdayWorkingHours?.isOpen == 0) {
        this.tuesday = "Leave"
        this.tuedayLeaveHide = false;
        this.AddWorkForm.patchValue({
          drpTueWorkOptn: "",
          drpTueStartAt: "",
          drpTueStartEnd: "",
          drpTueSelCov: "",
          drpTueUser: "",

          drpTue2WorkOptn: "",
          drpTue2StartAt: "",
          drpTue2StartEnd: "",
          drpTue2SelCov: "",
          drpTue2User: "",
        })
      }
      if (response?.officeWednesdayWorkingHours?.isOpen == 1) {
        this.wedday = "Working"
        this.weddayLeaveHide = true;
      }
      if (response?.officeWednesdayWorkingHours?.isOpen == 0) {
        this.wedday = "Leave"
        this.weddayLeaveHide = false;
        this.AddWorkForm.patchValue({
          drpWedWorkOptn: "",
          drpWedStartAt: "",
          drpWedStartEnd: "",
          drpWedSelCov: "",
          drpWedUser: "",
          drpWed2WorkOptn: "",
          drpWed2StartAt: "",
          drpWed2StartEnd: "",
          drpWed2SelCov: "",
          drpWed2User: "",
        })
      }

      if (response?.officeThursdayWorkingHours?.isOpen == 1) {
        this.thuday = "Working"
        this.thudayLeaveHide = true;
      }
      if (response?.officeThursdayWorkingHours?.isOpen == 0) {
        this.thuday = "Leave"
        this.thudayLeaveHide = false;
        this.AddWorkForm.patchValue({
          drpThuWorkOptn: "",
          drpThuStartAt: "",
          drpThuStartEnd: "",
          drpThuSelCov: "",
          drpThuUser: "",

          drpThu2WorkOptn: "",
          drpThu2StartAt: "",
          drpThu2StartEnd: "",
          drpThu2SelCov: "",
          drpThu2User: "",
        })
      }


      if (response?.officeFridayWorkingHours?.isOpen == 1) {
        this.Friday = "Working"
        this.fridayLeaveHide = true;
      }
      if (response?.officeFridayWorkingHours?.isOpen == 0) {
        this.Friday = "Leave"
        this.fridayLeaveHide = false;
        this.AddWorkForm.patchValue({
          drpFriWorkOptn: "",
          drpFriStartAt: "",
          drpFriStartEnd: "",
          drpFriSelCov: "",
          drpFriUser: "",
          drpFri2WorkOptn: "",
          drpFri2StartAt: "",
          drpFri2StartEnd: "",
          drpFri2SelCov: "",
          drpFri2User: "",
        })
      }


      if (response?.officeSaturdayWorkingHours?.isOpen == 1) {
        this.Satday = "Working"
        this.satdayLeaveHide = true;
      }
      if (response?.officeSaturdayWorkingHours?.isOpen == 0) {
        this.Satday = "Leave"
        this.satdayLeaveHide = false;
        this.AddWorkForm.patchValue({
          drpSatWorkOptn: "",
          drpSatStartAt: "",
          drpSatStartEnd: "",
          drpSatSelCov: "",
          drpSatUser: "",

          drpSat2WorkOptn: "",
          drpSat2StartAt: "",
          drpSat2StartEnd: "",
          drpSat2SelCov: "",
          drpSat2User: "",
        })
      }


      // sunday
      this.arrUsersList = response?.officeSundayWorkingHours?.officeWorkDetails[0]?.userDetails
      this.arr2UsersList = response?.officeSundayWorkingHours?.officeWorkDetails[1]?.userDetails
      // monday
      this.arrMonUsersList = response?.officeMondayWorkingHours?.officeWorkDetails[0]?.userDetails
      this.arrMon2UsersList = response?.officeMondayWorkingHours?.officeWorkDetails[1]?.userDetails
      // tuesday
      this.arrTueUsersList = response?.officeTuesdayWorkingHours?.officeWorkDetails[0]?.userDetails
      this.arrTue2UsersList = response?.officeTuesdayWorkingHours?.officeWorkDetails[1]?.userDetails
      // wednesday
      this.arrWedUsersList = response?.officeWednesdayWorkingHours?.officeWorkDetails[0]?.userDetails
      this.arrWed2UsersList = response?.officeWednesdayWorkingHours?.officeWorkDetails[1]?.userDetails
      // thursday
      this.arrThuUsersList = response?.officeThursdayWorkingHours?.officeWorkDetails[0]?.userDetails
      this.arrThu2UsersList = response?.officeThursdayWorkingHours?.officeWorkDetails[1]?.userDetails
      // friday
      this.arrFriUsersList = response?.officeFridayWorkingHours?.officeWorkDetails[0]?.userDetails
      this.arrFri2UsersList = response?.officeFridayWorkingHours?.officeWorkDetails[1]?.userDetails
      // saturday
      this.arrSatUsersList = response?.officeSaturdayWorkingHours?.officeWorkDetails[0]?.userDetails
      this.arrSat2UsersList = response?.officeSaturdayWorkingHours?.officeWorkDetails[1]?.userDetails
      this.AddWorkForm.patchValue({
        drpWorkOptn: response?.officeSundayWorkingHours?.officeWorkDetails[0]?.weekOption,
        drpStartAt: sundayStart,
        drpStartEnd: response?.officeSundayWorkingHours?.officeWorkDetails[0]?.closesAt,
        AddSundayStatus: response?.officeSundayWorkingHours?.isOpen == 1 ? true : false,
        drpUser: response?.officeSundayWorkingHours?.officeWorkDetails[0]?.userDetails[0]?.userId,


        drp2WorkOptn: response?.officeSundayWorkingHours?.officeWorkDetails[1]?.weekOption,
        drp2StartAt: sunday2Start,
        drp2StartEnd: response?.officeSundayWorkingHours?.officeWorkDetails[1]?.closesAt,
        drp2User: response?.officeSundayWorkingHours?.officeWorkDetails[1]?.userDetails[0]?.userId,


        /// monday
        AddMondayStatus: response?.officeMondayWorkingHours?.isOpen == 1 ? true : false,
        drpMonWorkOptn: response?.officeMondayWorkingHours?.officeWorkDetails[0]?.weekOption,
        drpMonStartAt: mondayStart,
        drpMonStartEnd: response?.officeMondayWorkingHours?.officeWorkDetails[0]?.closesAt,
        drpMonUser: response?.officeMondayWorkingHours?.officeWorkDetails[0]?.userDetails[0]?.userId,

        drpMon2WorkOptn: response?.officeMondayWorkingHours?.officeWorkDetails[1]?.weekOption,
        drpMon2StartAt: monday2Start,
        drpMon2StartEnd: response?.officeMondayWorkingHours?.officeWorkDetails[1]?.closesAt,
        drpMon2User: response?.officeMondayWorkingHours?.officeWorkDetails[1]?.userDetails[0]?.userId,


        /// tuesday
        AddTuesdayStatus: response?.officeTuesdayWorkingHours?.isOpen == 1 ? true : false,
        drpTueWorkOptn: response?.officeTuesdayWorkingHours?.officeWorkDetails[0]?.weekOption,
        drpTueStartAt: tuedayStart,
        drpTueStartEnd: response?.officeTuesdayWorkingHours?.officeWorkDetails[0]?.closesAt,
        drpTueUser: response?.officeTuesdayWorkingHours?.officeWorkDetails[0]?.userDetails[0]?.userId,

        drpTue2WorkOptn: response?.officeTuesdayWorkingHours?.officeWorkDetails[1]?.weekOption,
        drpTue2StartAt: tueday2Start,
        drpTue2StartEnd: response?.officeTuesdayWorkingHours?.officeWorkDetails[1]?.closesAt,
        drpTue2User: response?.officeTuesdayWorkingHours?.officeWorkDetails[1]?.userDetails[0]?.userId,

        /// Wednesday
        AddWednesdayStatus: response?.officeWednesdayWorkingHours?.isOpen == 1 ? true : false,
        drpWedWorkOptn: response?.officeWednesdayWorkingHours?.officeWorkDetails[0]?.weekOption,
        drpWedStartAt: weddayStart,
        drpWedStartEnd: response?.officeWednesdayWorkingHours?.officeWorkDetails[0]?.closesAt,
        drpWedUser: response?.officeWednesdayWorkingHours?.officeWorkDetails[0]?.userDetails[0]?.userId,

        drpWed2WorkOptn: response?.officeWednesdayWorkingHours?.officeWorkDetails[1]?.weekOption,
        drpWed2StartAt: wedday2Start,
        drpWed2StartEnd: response?.officeWednesdayWorkingHours?.officeWorkDetails[1]?.closesAt,
        drpWed2User: response?.officeWednesdayWorkingHours?.officeWorkDetails[1]?.userDetails[0]?.userId,

        /// Thunesday
        AddThudayStatus: response?.officeThursdayWorkingHours?.isOpen == 1 ? true : false,
        drpThuWorkOptn: response?.officeThursdayWorkingHours?.officeWorkDetails[0]?.weekOption,
        drpThuStartAt: thudayStart,
        drpThuStartEnd: response?.officeThursdayWorkingHours?.officeWorkDetails[0]?.closesAt,
        drpThuUser: response?.officeThursdayWorkingHours?.officeWorkDetails[0]?.userDetails[0]?.userId,

        drpThu2WorkOptn: response?.officeThursdayWorkingHours?.officeWorkDetails[1]?.weekOption,
        drpThu2StartAt: thuday2Start,
        drpThu2StartEnd: response?.officeThursdayWorkingHours?.officeWorkDetails[1]?.closesAt,
        drpThu2User: response?.officeThursdayWorkingHours?.officeWorkDetails[1]?.userDetails[0]?.userId,

        /// Frinesday
        AddFridayStatus: response?.officeFridayWorkingHours?.isOpen == 1 ? true : false,
        drpFriWorkOptn: response?.officeFridayWorkingHours?.officeWorkDetails[0]?.weekOption,
        drpFriStartAt: fridayStart,
        drpFriStartEnd: response?.officeFridayWorkingHours?.officeWorkDetails[0]?.closesAt,
        drpFriUser: response?.officeFridayWorkingHours?.officeWorkDetails[0]?.userDetails[0]?.userId,

        drpFri2WorkOptn: response?.officeFridayWorkingHours?.officeWorkDetails[1]?.weekOption,
        drpFri2StartAt: friday2Start,
        drpFri2StartEnd: response?.officeFridayWorkingHours?.officeWorkDetails[1]?.closesAt,
        drpFri2User: response?.officeFridayWorkingHours?.officeWorkDetails[1]?.userDetails[0]?.userId,

        /// Saturday
        AddSatdayStatus: response?.officeSaturdayWorkingHours?.isOpen == 1 ? true : false,
        drpSatWorkOptn: response?.officeSaturdayWorkingHours?.officeWorkDetails[0]?.weekOption,
        drpSatStartAt: satdayStart,
        drpSatStartEnd: response?.officeSaturdayWorkingHours?.officeWorkDetails[0]?.closesAt,
        drpSatUser: response?.officeSaturdayWorkingHours?.officeWorkDetails[0]?.userDetails[0]?.userId,

        drpSat2WorkOptn: response?.officeSaturdayWorkingHours?.officeWorkDetails[1]?.weekOption,
        drpSat2StartAt: satday2Start,
        drpSat2StartEnd: response?.officeSaturdayWorkingHours?.officeWorkDetails[1]?.closesAt,
        drpSat2User: response?.officeSaturdayWorkingHours?.officeWorkDetails[1]?.userDetails[0]?.userId,
      })
    })
  }
  // ! add sunday more
  addAccordion() {
    this.sundayHide = true;
    this.sunMore = false
  }
  addMonday() {
    this.mondayHide = true;
    this.monMore = false;
  }
  addTueday() {
    this.TuedayHide = true;
    this.tueMore = false
  }

  addWedday() {
    this.WeddayHide = true;
    this.wedMore = false
  }
  addThuday() {
    this.ThudayHide = true;
    this.thuMore = false
  }
  addFriday() {
    this.FridayHide = true;
    this.friMore = false
  }

  addSatday() {
    this.SatdayHide = true;
    this.satMore = false
  }
  // ! save function
  getWorkingSave() {
    let AddWorkingHours: CreateUpdateOfficeWorkingHoursDTO;
    let sundayUserDetailsDTO: CreateUpdateUserDetailsDTO[] = [];
    let sunday2UserDetailsDTO: CreateUpdateUserDetailsDTO[] = [];
    let mondayUserDetailsDTO: CreateUpdateUserDetailsDTO[] = [];
    let monday2UserDetailsDTO: CreateUpdateUserDetailsDTO[] = []
    let tuesdayUserDetailsDTO: CreateUpdateUserDetailsDTO[] = []
    let tuesday2UserDetailsDTO: CreateUpdateUserDetailsDTO[] = []
    let wednesdayUserDetailsDTO: CreateUpdateUserDetailsDTO[] = []
    let wednesday2UserDetailsDTO: CreateUpdateUserDetailsDTO[] = []
    let thursdayUserDetailsDTO: CreateUpdateUserDetailsDTO[] = []
    let thursday2UserDetailsDTO: CreateUpdateUserDetailsDTO[] = []
    let fridayUserDetailsDTO: CreateUpdateUserDetailsDTO[] = []
    let friday2UserDetailsDTO: CreateUpdateUserDetailsDTO[] = []
    let saturdayUserDetailsDTO: CreateUpdateUserDetailsDTO[] = []
    let saturday2UserDetailsDTO: CreateUpdateUserDetailsDTO[] = []

    if (this.AddWorkForm.value.drpUser == null || this.AddWorkForm.value.drpUser == "" || this.AddWorkForm.value.drpUser == undefined) {
      sundayUserDetailsDTO = []
    } else {
      sundayUserDetailsDTO.push({
        userId: this.AddWorkForm.value.drpUser == "" ? null : this.AddWorkForm.value.drpUser,
        userName: null
      });
    }
    if (this.AddWorkForm.value.drp2User == null || this.AddWorkForm.value.drp2User == "" || this.AddWorkForm.value.drp2User == undefined) {
      sunday2UserDetailsDTO = [];
    } else {
      sunday2UserDetailsDTO.push({
        userId: this.AddWorkForm.value.drp2User == "" ? null : this.AddWorkForm.value.drp2User,
        userName: null
      });
    }

    if (this.AddWorkForm.value.drpMonUser == null || this.AddWorkForm.value.drpMonUser == "" || this.AddWorkForm.value.drpMonUser == undefined) {
      mondayUserDetailsDTO = [];
    } else {
      mondayUserDetailsDTO.push({
        userId: this.AddWorkForm.value.drpMonUser == "" ? null : this.AddWorkForm.value.drpMonUser,
        userName: null
      });
    }

    if (this.AddWorkForm.value.drpMon2User == null || this.AddWorkForm.value.drpMon2User == "" || this.AddWorkForm.value.drpMon2User == undefined) {
      monday2UserDetailsDTO = [];
    } else {
      monday2UserDetailsDTO.push({
        userId: this.AddWorkForm.value.drpMon2User == "" ? null : this.AddWorkForm.value.drpMon2User,
        userName: null
      });
    }

    if (this.AddWorkForm.value.drpTueUser == null || this.AddWorkForm.value.drpTueUser == "" || this.AddWorkForm.value.drpTueUser == undefined) {
      tuesdayUserDetailsDTO = [];
    } else {
      tuesdayUserDetailsDTO.push({
        userId: this.AddWorkForm.value.drpTueUser == "" ? null : this.AddWorkForm.value.drpTueUser,
        userName: null
      });
    }

    if (this.AddWorkForm.value.drpTue2User == null || this.AddWorkForm.value.drpTue2User == "" || this.AddWorkForm.value.drpTue2User == undefined) {
      tuesday2UserDetailsDTO = [];
    }
    else {
      tuesday2UserDetailsDTO.push({
        userId: this.AddWorkForm.value.drpTue2User == "" ? null : this.AddWorkForm.value.drpTue2User,
        userName: null
      });
    }

    if (this.AddWorkForm.value.drpWedUser == null || this.AddWorkForm.value.drpWedUser == "" || this.AddWorkForm.value.drpWedUser == undefined) {
      wednesdayUserDetailsDTO = [];
    } else {
      wednesdayUserDetailsDTO.push({
        userId: this.AddWorkForm.value.drpWedUser == "" ? null : this.AddWorkForm.value.drpWedUser,
        userName: null
      });
    }

    if (this.AddWorkForm.value.drpWed2User == null || this.AddWorkForm.value.drpWed2User == "" || this.AddWorkForm.value.drpWed2User == undefined) {
      wednesday2UserDetailsDTO = [];
    } else {
      wednesday2UserDetailsDTO.push({
        userId: this.AddWorkForm.value.drpWed2User == "" ? null : this.AddWorkForm.value.drpWed2User,
        userName: null
      });
    }

    if (this.AddWorkForm.value.drpThuUser == null || this.AddWorkForm.value.drpThuUser == "" || this.AddWorkForm.value.drpThuUser == undefined) {
      thursdayUserDetailsDTO = [];
    } else {
      thursdayUserDetailsDTO.push({
        userId: this.AddWorkForm.value.drpThuUser == "" ? null : this.AddWorkForm.value.drpThuUser,
        userName: null
      });
    }

    if (this.AddWorkForm.value.drpThu2User == null || this.AddWorkForm.value.drpThu2User == "" || this.AddWorkForm.value.drpThu2User == undefined) {
      thursday2UserDetailsDTO = [];
    } else {
      thursday2UserDetailsDTO.push({
        userId: this.AddWorkForm.value.drpThu2User == "" ? null : this.AddWorkForm.value.drpThu2User,
        userName: null
      });
    }

    if (this.AddWorkForm.value.drpFriUser == null || this.AddWorkForm.value.drpFriUser == "" || this.AddWorkForm.value.drpFriUser == undefined) {
      fridayUserDetailsDTO = [];
    } else {
      fridayUserDetailsDTO.push({
        userId: this.AddWorkForm.value.drpFriUser == "" ? null : this.AddWorkForm.value.drpFriUser,
        userName: null
      });
    }

    if (this.AddWorkForm.value.drpFri2User == null || this.AddWorkForm.value.drpFri2User == "" || this.AddWorkForm.value.drpFri2User == undefined) {
      friday2UserDetailsDTO = [];
    }
    else {
      friday2UserDetailsDTO.push({
        userId: this.AddWorkForm.value.drpFri2User == "" ? null : this.AddWorkForm.value.drpFri2User,
        userName: null
      });
    }

    if (this.AddWorkForm.value.drpSatUser == null || this.AddWorkForm.value.drpSatUser == "" || this.AddWorkForm.value.drpSatUser == undefined) {
      saturdayUserDetailsDTO = [];
    } else {
      saturdayUserDetailsDTO.push({
        userId: this.AddWorkForm.value.drpSatUser == "" ? null : this.AddWorkForm.value.drpSatUser,
        userName: null
      });
    }

    if (this.AddWorkForm.value.drpSat2User == null || this.AddWorkForm.value.drpSat2User == "" || this.AddWorkForm.value.drpSat2User == undefined) {
      saturday2UserDetailsDTO = [];
    } else {
      saturday2UserDetailsDTO.push({
        userId: this.AddWorkForm.value.drpSat2User == "" ? null : this.AddWorkForm.value.drpSat2User,
        userName: null
      });
    }

    AddWorkingHours = {
      branchId: this.data.branchId,
      branchName: "",
      officeSundayWorkingHours: {
        isOpen: this.AddWorkForm.value.AddSundayStatus === true ? 1 : 0,

        officeWorkDetails: [{
          weekOption: this.AddWorkForm.value?.drpWorkOptn === "" ? [] : this.AddWorkForm.value.drpWorkOptn,
          opensAt: this.AddWorkForm.value.drpStartAt?.startTimeShortCodeId === undefined ? "" : this.AddWorkForm.value.drpStartAt.startTimeShortCodeId,
          closesAt: this.AddWorkForm.value.drpStartEnd,
          userDetails: sundayUserDetailsDTO,
        },
        {
          weekOption: this.AddWorkForm.value.drp2WorkOptn === "" ? [] : this.AddWorkForm.value.drp2WorkOptn,
          opensAt: this.AddWorkForm.value.drp2StartAt?.startTimeShortCodeId === undefined ? "" : this.AddWorkForm.value.drp2StartAt?.startTimeShortCodeId,
          closesAt: this.AddWorkForm.value.drp2StartEnd,
          userDetails: sunday2UserDetailsDTO,
        }
        ]
      },
      officeMondayWorkingHours: {
        isOpen: this.AddWorkForm.value.AddMondayStatus === true ? 1 : 0,
        officeWorkDetails: [{
          weekOption: this.AddWorkForm.value.drpMonWorkOptn === "" ? [] : this.AddWorkForm.value.drpMonWorkOptn,
          opensAt: this.AddWorkForm.value.drpMonStartAt?.startTimeShortCodeId === undefined ? "" : this.AddWorkForm.value.drpMonStartAt?.startTimeShortCodeId,
          closesAt: this.AddWorkForm.value.drpMonStartEnd,
          userDetails: mondayUserDetailsDTO,
        },
        {
          weekOption: this.AddWorkForm.value.drpMon2WorkOptn === "" ? [] : this.AddWorkForm.value.drpMon2WorkOptn,
          opensAt: this.AddWorkForm.value.drpMon2StartAt?.startTimeShortCodeId === undefined ? "" : this.AddWorkForm.value.drpMon2StartAt?.startTimeShortCodeId,
          closesAt: this.AddWorkForm.value.drpMon2StartEnd,
          userDetails: monday2UserDetailsDTO,
        }
        ]
      },
      officeTuesdayWorkingHours: {
        isOpen: this.AddWorkForm.value.AddTuesdayStatus === true ? 1 : 0,
        officeWorkDetails: [{
          weekOption: this.AddWorkForm.value.drpTueWorkOptn === "" ? [] : this.AddWorkForm.value.drpTueWorkOptn,
          opensAt: this.AddWorkForm.value.drpTueStartAt?.startTimeShortCodeId === undefined ? "" : this.AddWorkForm.value.drpTueStartAt?.startTimeShortCodeId,
          closesAt: this.AddWorkForm.value.drpTueStartEnd,
          userDetails: tuesdayUserDetailsDTO,
        },
        {
          weekOption: this.AddWorkForm.value.drpTue2WorkOptn === "" ? [] : this.AddWorkForm.value.drpTue2WorkOptn,
          opensAt: this.AddWorkForm.value.drpTue2StartAt?.startTimeShortCodeId === undefined ? "" : this.AddWorkForm.value.drpTue2StartAt?.startTimeShortCodeId,
          closesAt: this.AddWorkForm.value.drpTue2StartEnd,
          userDetails: tuesday2UserDetailsDTO,
        }
        ]
      },
      officeWednesdayWorkingHours: {
        isOpen: this.AddWorkForm.value.AddWednesdayStatus === true ? 1 : 0,
        officeWorkDetails: [{
          weekOption: this.AddWorkForm.value.drpWedWorkOptn === "" ? [] : this.AddWorkForm.value.drpWedWorkOptn,
          opensAt: this.AddWorkForm.value.drpWedStartAt?.startTimeShortCodeId === undefined ? "" : this.AddWorkForm.value.drpWedStartAt?.startTimeShortCodeId,
          closesAt: this.AddWorkForm.value.drpWedStartEnd,
          userDetails: wednesdayUserDetailsDTO,
        },
        {
          weekOption: this.AddWorkForm.value.drpWed2WorkOptn === "" ? [] : this.AddWorkForm.value.drpWed2WorkOptn,
          opensAt: this.AddWorkForm.value.drpWed2StartAt?.startTimeShortCodeId === undefined ? "" : this.AddWorkForm.value.drpWed2StartAt?.startTimeShortCodeId,
          closesAt: this.AddWorkForm.value.drpWed2StartEnd,
          userDetails: wednesday2UserDetailsDTO,
        }
        ]
      },
      officeThursdayWorkingHours: {
        isOpen: this.AddWorkForm.value.AddThudayStatus === true ? 1 : 0,
        officeWorkDetails: [{
          weekOption: this.AddWorkForm.value.drpThuWorkOptn === "" ? [] : this.AddWorkForm.value.drpThuWorkOptn,
          opensAt: this.AddWorkForm.value.drpThuStartAt?.startTimeShortCodeId === undefined ? "" : this.AddWorkForm.value.drpThuStartAt?.startTimeShortCodeId,
          closesAt: this.AddWorkForm.value.drpThuStartEnd,
          userDetails: thursdayUserDetailsDTO,
        },
        {
          weekOption: this.AddWorkForm.value.drpThu2WorkOptn === "" ? [] : this.AddWorkForm.value.drpThu2WorkOptn,
          opensAt: this.AddWorkForm.value.drpThu2StartAt?.startTimeShortCodeId === undefined ? "" : this.AddWorkForm.value.drpThu2StartAt?.startTimeShortCodeId,
          closesAt: this.AddWorkForm.value.drpThu2StartEnd,
          userDetails: thursday2UserDetailsDTO,
        }
        ]
      },
      officeFridayWorkingHours: {
        isOpen: this.AddWorkForm.value.AddFridayStatus === true ? 1 : 0,
        officeWorkDetails: [{
          weekOption: this.AddWorkForm.value.drpFriWorkOptn === "" ? [] : this.AddWorkForm.value.drpFriWorkOptn,
          opensAt: this.AddWorkForm.value.drpFriStartAt?.startTimeShortCodeId === undefined ? "" : this.AddWorkForm.value.drpFriStartAt?.startTimeShortCodeId,
          closesAt: this.AddWorkForm.value.drpFriStartEnd,
          userDetails: fridayUserDetailsDTO,
        },
        {
          weekOption: this.AddWorkForm.value.drpFri2WorkOptn === "" ? [] : this.AddWorkForm.value.drpFri2WorkOptn,
          opensAt: this.AddWorkForm.value.drpFri2StartAt?.startTimeShortCodeId === undefined ? "" : this.AddWorkForm.value.drpFri2StartAt?.startTimeShortCodeId,
          closesAt: this.AddWorkForm.value.drpFri2StartEnd,
          userDetails: friday2UserDetailsDTO,
        }]
      },
      officeSaturdayWorkingHours: {
        isOpen: this.AddWorkForm.value.AddSatdayStatus === true ? 1 : 0,
        officeWorkDetails: [{
          weekOption: this.AddWorkForm.value.drpSatWorkOptn === "" ? [] : this.AddWorkForm.value.drpSatWorkOptn,
          opensAt: this.AddWorkForm.value.drpSatStartAt?.startTimeShortCodeId === undefined ? "" : this.AddWorkForm.value.drpSatStartAt?.startTimeShortCodeId,
          closesAt: this.AddWorkForm.value.drpSatStartEnd,
          userDetails: saturdayUserDetailsDTO,
        },
        {
          weekOption: this.AddWorkForm.value.drpSat2WorkOptn === "" ? [] : this.AddWorkForm.value.drpSat2WorkOptn,
          opensAt: this.AddWorkForm.value.drpSat2StartAt?.startTimeShortCodeId === undefined ? "" : this.AddWorkForm.value.drpSat2StartAt?.startTimeShortCodeId,
          closesAt: this.AddWorkForm.value.drpSat2StartEnd,
          userDetails: saturday2UserDetailsDTO,
        }
        ]
      },
    }

    if (this.data.wrokingbranchId === "" || this.data.wrokingbranchId === null || this.data.wrokingbranchId === "00000000-0000-0000-0000-000000000000") {
      this.branchWorkHours.create(AddWorkingHours).subscribe(response => {
        // Swal.fire({ title: 'Success', html: 'Saved Successfully', icon: 'success', timer: 3000, timerProgressBar: true });
        this.toastr.success('Saved Successfully','Success')
        this.data.wrokingbranchId = response.id
        this.notesDialogRef.close();
      }, err => {
        const data: HttpErrorResponse = err;
        // Swal.fire({
        //   icon: 'info',
        //   html: data?.error?.error?.message,
        // })
        this.toastr.info(data?.error?.error?.message)
      });
    }
    else {
      this.branchWorkHours.update(this.data.wrokingbranchId, AddWorkingHours).subscribe(response => {
        // this.patientsId = response.id
        // Swal.fire({ title: 'Success', html: 'Saved Successfully', icon: 'success', timer: 3000, timerProgressBar: true });
        this.toastr.success('Saved Successfully','Success')
        this.notesDialogRef.close();
      }, err => {
        const data: HttpErrorResponse = err;
        // Swal.fire({
        //   icon: 'info',
        //   html: data?.error?.error?.message,
        // })
        this.toastr.info(data?.error?.error?.message)
      });
    }
  }

  // ! branch users
  onEndsChanges(startAt, work, startEnd) {

    const userDatas: GetUsersDTO = {
      weekDays: WeekDays.Sunday,
      // weekOption: work,
      startsAt: startAt.startTimeShortCodeId,
      endsAt: startEnd,
      userDetails: null
    }
    this.branchWorkHours.getUsersBasedOnTimingsByInput(userDatas).subscribe(response => {
      return this.arrUsersList = response.userDetails;
    }, err => {
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    });
    return this.arrUsersList
  }

  onSun2Changes(startAt, work, startEnd) {
    const userDatas: GetUsersDTO = {
      weekDays: WeekDays.Sunday,
      // weekOption: work,
      startsAt: startAt?.startTimeShortCodeId,
      endsAt: startEnd,
      userDetails: null

    }
    this.branchWorkHours.getUsersBasedOnTimingsByInput(userDatas).subscribe(response => {


      this.arr2UsersList = response.userDetails;
    }, err => {
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    });
  }


  /// Monday users
  arrMonUsersList: any[] = [];
  arrMon2UsersList: any[] = [];
  onMonEndsChanges(startAt, work, startEnd) {

    const userDatas: GetUsersDTO = {
      weekDays: WeekDays.Monday,
      // weekOption: work,
      startsAt: startAt?.startTimeShortCodeId,
      endsAt: startEnd,
      userDetails: null

    }
    this.branchWorkHours.getUsersBasedOnTimingsByInput(userDatas).subscribe(response => {


      this.arrMonUsersList = response.userDetails;

    }, err => {
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    });
  }

  onMon2Changes(startAt, work, startEnd) {
    const userDatas: GetUsersDTO = {
      weekDays: WeekDays.Monday,
      // weekOption: work,
      startsAt: startAt?.startTimeShortCodeId,
      endsAt: startEnd,
      userDetails: null

    }
    this.branchWorkHours.getUsersBasedOnTimingsByInput(userDatas).subscribe(response => {


      this.arrMon2UsersList = response.userDetails;

    }, err => {
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    });
  }


  /// Tues users
  arrTueUsersList: any[] = [];
  arrTue2UsersList: any[] = [];
  onTueEndsChanges(startAt, work, startEnd) {
    const userDatas: GetUsersDTO = {
      weekDays: WeekDays.Tuesday,
      // weekOption: work,
      startsAt: startAt?.startTimeShortCodeId,
      endsAt: startEnd,
      userDetails: null

    }
    this.branchWorkHours.getUsersBasedOnTimingsByInput(userDatas).subscribe(response => {


      this.arrTueUsersList = response.userDetails;

    }, err => {
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    });
  }

  onTue2Changes(startAt, work, startEnd) {
    const userDatas: GetUsersDTO = {
      weekDays: WeekDays.Tuesday,
      // weekOption: work,
      startsAt: startAt?.startTimeShortCodeId,
      endsAt: startEnd,
      userDetails: null

    }
    this.branchWorkHours.getUsersBasedOnTimingsByInput(userDatas).subscribe(response => {


      this.arrTue2UsersList = response.userDetails;

    }, err => {
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    });
  }

  /// wed users
  arrWedUsersList: any[] = [];
  arrWed2UsersList: any[] = [];
  onWedEndsChanges(startAt, work, startEnd) {
    const userDatas: GetUsersDTO = {
      weekDays: WeekDays.Wednesday,
      // weekOption: work,
      startsAt: startAt?.startTimeShortCodeId,
      endsAt: startEnd,
      userDetails: null

    }
    this.branchWorkHours.getUsersBasedOnTimingsByInput(userDatas).subscribe(response => {


      this.arrWedUsersList = response.userDetails;

    }, err => {
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    });
  }

  onWed2Changes(startAt, work, startEnd) {
    const userDatas: GetUsersDTO = {
      weekDays: WeekDays.Wednesday,
      // weekOption: work,
      startsAt: startAt?.startTimeShortCodeId,
      endsAt: startEnd,
      userDetails: null

    }
    this.branchWorkHours.getUsersBasedOnTimingsByInput(userDatas).subscribe(response => {


      this.arrWed2UsersList = response.userDetails;

    }, err => {
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    });
  }


  /// Thu users
  arrThuUsersList: any[] = [];
  arrThu2UsersList: any[] = [];
  onThuEndsChanges(startAt, work, startEnd) {
    const userDatas: GetUsersDTO = {
      weekDays: WeekDays.Thursday,
      // weekOption: work,
      startsAt: startAt?.startTimeShortCodeId,
      endsAt: startEnd,
      userDetails: null

    }
    this.branchWorkHours.getUsersBasedOnTimingsByInput(userDatas).subscribe(response => {


      this.arrThuUsersList = response.userDetails;

    }, err => {
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    });
  }

  onThu2Changes(startAt, work, startEnd) {
    const userDatas: GetUsersDTO = {
      weekDays: WeekDays.Thursday,
      // weekOption: work,
      startsAt: startAt?.startTimeShortCodeId,
      endsAt: startEnd,
      userDetails: null

    }
    this.branchWorkHours.getUsersBasedOnTimingsByInput(userDatas).subscribe(response => {


      this.arrThu2UsersList = response.userDetails;

    }, err => {
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    });
  }


  /// Fri users
  arrFriUsersList: any[] = [];
  arrFri2UsersList: any[] = [];
  onFriEndsChanges(startAt, work, startEnd) {
    const userDatas: GetUsersDTO = {
      weekDays: WeekDays.Friday,
      // weekOption: work,
      startsAt: startAt?.startTimeShortCodeId,
      endsAt: startEnd,
      userDetails: null

    }
    this.branchWorkHours.getUsersBasedOnTimingsByInput(userDatas).subscribe(response => {
      this.arrFriUsersList = response.userDetails;

    }, err => {
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    });
  }

  onFri2Changes(startAt, work, startEnd) {
    const userDatas: GetUsersDTO = {
      weekDays: WeekDays.Friday,
      // weekOption: work,
      startsAt: startAt?.startTimeShortCodeId,
      endsAt: startEnd,
      userDetails: null

    }
    this.branchWorkHours.getUsersBasedOnTimingsByInput(userDatas).subscribe(response => {


      this.arrFri2UsersList = response.userDetails;

    }, err => {
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    });
  }

  /// Sat users
  arrSatUsersList: any[] = [];
  arrSat2UsersList: any[] = [];
  onSatEndsChanges(startAt, work, startEnd) {
    const userDatas: GetUsersDTO = {
      weekDays: WeekDays.Saturday,
      // weekOption: work,
      startsAt: startAt?.startTimeShortCodeId,
      endsAt: startEnd,
      userDetails: null

    }
    this.branchWorkHours.getUsersBasedOnTimingsByInput(userDatas).subscribe(response => {


      this.arrSatUsersList = response.userDetails;

    }, err => {
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    });
  }

  onSat2Changes(startAt, work, startEnd) {
    const userDatas: GetUsersDTO = {
      weekDays: WeekDays.Saturday,
      // weekOption: work,
      startsAt: startAt?.startTimeShortCodeId,
      endsAt: startEnd,
      userDetails: null

    }
    this.branchWorkHours.getUsersBasedOnTimingsByInput(userDatas).subscribe(response => {


      this.arrSat2UsersList = response.userDetails;

    }, err => {
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    });
  }
  // ! sunday status
  sundayStatus(status) {
    if (this.AddWorkForm.value.AddSundayStatus == true || this.AddWorkForm.value.AddSundayStatus == "") {
      this.sunday = "Leave"
      this.sundayLeaveHide = false;
      this.AddWorkForm.patchValue({
        drpStartAt: "",
        drpStartEnd: "",
        drpSelCov: "",
        drpUser: "",
        drp2WorkOptn: "",
        drp2StartAt: "",
        drp2StartEnd: "",
        drp2SelCov: "",
        drp2User: "",
      })

    }
    if (this.AddWorkForm.value.AddSundayStatus == false) {
      this.sunday = "Working"
      this.sundayLeaveHide = true;
    }

  }


  // ! monday status
  mondayStatus(status) {
    if (this.AddWorkForm.value.AddMondayStatus == true) {
      this.monday = "Leave"
      this.mondayLeaveHide = false;
      this.AddWorkForm.patchValue({
        drpMonWorkOptn: "",
        drpMonStartAt: "",
        drpMonStartEnd: "",
        drpMonSelCov: "",
        drpMonUser: "",
        drpMon2WorkOptn: "",
        drpMon2StartAt: "",
        drpMon2StartEnd: "",
        drpMon2SelCov: "",
        drpMon2User: "",
      })
    }
    if (this.AddWorkForm.value.AddMondayStatus == false) {
      this.monday = "Working"
      this.mondayLeaveHide = true;

    }
  }

  // ! tuesday status
  tuesStatus(status) {
    if (this.AddWorkForm.value.AddTuesdayStatus == true) {
      this.tuesday = "Leave"
      this.tuedayLeaveHide = false;
      this.AddWorkForm.patchValue({
        drpTueWorkOptn: "",
        drpTueStartAt: "",
        drpTueStartEnd: "",
        drpTueSelCov: "",
        drpTueUser: "",

        drpTue2WorkOptn: "",
        drpTue2StartAt: "",
        drpTue2StartEnd: "",
        drpTue2SelCov: "",
        drpTue2User: "",
      })

    }
    if (this.AddWorkForm.value.AddTuesdayStatus == false) {
      this.tuesday = "Working"
      this.tuedayLeaveHide = true;
    }

  }

  // ! wedday status wedday
  wedStatus(status) {
    if (this.AddWorkForm.value.AddWednesdayStatus == true) {
      this.wedday = "Leave"
      this.weddayLeaveHide = false;
      this.AddWorkForm.patchValue({
        drpWedWorkOptn: "",
        drpWedStartAt: "",
        drpWedStartEnd: "",
        drpWedSelCov: "",
        drpWedUser: "",
        drpWed2WorkOptn: "",
        drpWed2StartAt: "",
        drpWed2StartEnd: "",
        drpWed2SelCov: "",
        drpWed2User: "",
      })
    }
    if (this.AddWorkForm.value.AddWednesdayStatus == false) {
      this.wedday = "Working"
      this.weddayLeaveHide = true;
    }

  }
  // ! thuday status wedday
  thuStatus(status) {
    if (this.AddWorkForm.value.AddThudayStatus == true) {
      this.thuday = "Leave"
      this.thudayLeaveHide = false;
      this.AddWorkForm.patchValue({
        drpThuWorkOptn: "",
        drpThuStartAt: "",
        drpThuStartEnd: "",
        drpThuSelCov: "",
        drpThuUser: "",
        drpThu2WorkOptn: "",
        drpThu2StartAt: "",
        drpThu2StartEnd: "",
        drpThu2SelCov: "",
        drpThu2User: "",
      })

    }
    if (this.AddWorkForm.value.AddThudayStatus == false) {
      this.thuday = "Working"
      this.thudayLeaveHide = true;
    }

  }

  // ! Friday status wedday
  FriStatus(status) {
    if (this.AddWorkForm.value.AddFridayStatus == true) {
      this.Friday = "Leave"
      this.fridayLeaveHide = false;
      this.AddWorkForm.patchValue({
        drpFriWorkOptn: "",
        drpFriStartAt: "",
        drpFriStartEnd: "",
        drpFriSelCov: "",
        drpFriUser: "",
        drpFri2WorkOptn: "",
        drpFri2StartAt: "",
        drpFri2StartEnd: "",
        drpFri2SelCov: "",
        drpFri2User: "",
      })
    }
    if (this.AddWorkForm.value.AddFridayStatus == false) {
      this.Friday = "Working"
      this.fridayLeaveHide = true;
    }

  }

  // ! Satday status wedday
  SatStatus(status) {
    if (this.AddWorkForm.value.AddSatdayStatus == true) {
      this.Satday = "Leave"
      this.satdayLeaveHide = false;
      this.AddWorkForm.patchValue({
        drpSatWorkOptn: "",
        drpSatStartAt: "",
        drpSatStartEnd: "",
        drpSatSelCov: "",
        drpSatUser: "",

        drpSat2WorkOptn: "",
        drpSat2StartAt: "",
        drpSat2StartEnd: "",
        drpSat2SelCov: "",
        drpSat2User: "",
      })

    }
    if (this.AddWorkForm.value.AddSatdayStatus == false) {
      this.Satday = "Working"
      this.satdayLeaveHide = true;
    }

  }
}
