import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatRadioChange } from '@angular/material/radio';
import { MatSelectChange } from '@angular/material/select';
import { Store } from '@ngxs/store';
import { AuthCategoryDTO, AuthVisitsDTO, OrderMasterDropdownDTO, TypeOfPlanDTO } from 'projects/patient/src/app/dropdown-proxy/dropdown-management/dropdowns/dto/order/models';
import { PatientMasterDropdownDTO, SaleOrRentDTO } from 'projects/patient/src/app/dropdown-proxy/dropdown-management/dropdowns/dto/patient/models';
import { OrderMasterDropdownService } from 'projects/patient/src/app/dropdown-proxy/dropdown-management/dropdowns/master-dropdown-service/order-master-dropdown.service';
import { PatientMasterDropdownService } from 'projects/patient/src/app/dropdown-proxy/dropdown-management/dropdowns/master-dropdown-service/patient-master-dropdown.service';
import { OrderDropdowns } from 'projects/patient/src/app/dropdown-proxy/dropdown-management/dropdowns/order-dropdowns.enum';
import { PatientDropdowns } from 'projects/patient/src/app/dropdown-proxy/dropdown-management/dropdowns/patient-dropdowns.enum';
import { billingStatus } from 'projects/shared/src/app/enums/allenums.enum';
import { TableService } from 'projects/shared/src/app/table.service';
import { NumberValidators } from 'projects/shared/src/app/validations/number-validator';
import { TextValidators } from 'projects/shared/src/app/validations/text-validator';
import { Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { ToastrService } from "ngx-toastr";
import { ItemDTO, SaleorderDTO } from '../order-proxy/order-management/order/dto/models';
import { SaleorderService } from '../order-proxy/order-management/order/saleorder.service';
import { AuthorizationDTO, PolicyDTO } from '../order-proxy/order-management/rcm/patient-management/patient/dto/models';
import { getOrder, updateOrder } from '../order-store/order.action';

@Component({
  selector: 'app-add-authorization',
  templateUrl: './add-authorization.component.html',
  styleUrls: ['./add-authorization.component.scss']
})
export class AddAuthorizationComponent implements OnInit, OnDestroy {

  public authorizationForm: FormGroup;
  @Input() isStatusShow: boolean = false;
  @Input() orderId: string = '';
  notesText ='';
  @Input() patientId: string = '';
  authId: string = "00000000-0000-0000-0000-000000000000";
  authModalTitle: string = '';
  authorizationId: string = "00000000-0000-0000-0000-000000000000";
  drpAuthVisit: AuthVisitsDTO[] = [];
  drpCategory: AuthCategoryDTO[] = [];
  // drpMarketingRef: MarketingReferralDTO[] = [];
  drpPlanType: any[];
  drpPlan: TypeOfPlanDTO[] = [];
  drpAuthItem: ItemDTO[] = [];
  drpSaleOrRents: SaleOrRentDTO[] = [];
  orderStatus: boolean = true;
  drpPolicies: PolicyDTO[] = [];
  drpItemCdoes: string[] = [];
  authVisit: string;
  category: string;
  sale: string;
  item: string;
  plan: string;
  itemCodes: string;
  Show: boolean = false;
  saveButtonHide: boolean
  isShowSpinner: boolean = false;
  subscription$: Subscription[] = [];
  constructor(
    private fb: FormBuilder,
    private text: TextValidators,
    private store: Store,
    private numberValidators: NumberValidators,
    private Table: TableService,
    private saleOrderService: SaleorderService,
    private orderDropdownService: OrderMasterDropdownService,
    private patientDropdownService: PatientMasterDropdownService,
    private toastr: ToastrService,
    public authDialogRef: MatDialogRef<AddAuthorizationComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { orderId: string, authDatas?: AuthorizationDTO, allowUptSve: boolean, patientId: string }
  ) { }

  ngOnInit(): void {
    this.createAuthorizationForm();
    this.getDropdowns();
    if (this.data.orderId != "" && this.data.orderId != null && this.data.orderId != undefined) {
      const saleorderGet = this.saleOrderService.get(this.data.orderId).subscribe(response => {
        this.drpAuthItem = response.items;
        const saleOrderData: SaleorderDTO = response;
        this.orderStatus = this.data?.allowUptSve;
        this.patientId = saleOrderData?.patientId ?? "";
      });
      this.subscription$.push(saleorderGet)
    }

    if (this.data.authDatas != null && this.data.authDatas != undefined && this.data.authDatas.id !== "" && this.data.authDatas.id !== '00000000-0000-0000-0000-000000000000') {
      this.getAuthDatas(this.data.authDatas);
      this.orderId = this.data.orderId;
      this.authId = this.data?.authDatas?.id ?? "";
      this.authModalTitle = 'Edit Authorization';
    }
    else {
      this.orderId = this.data.orderId;
      this.authId = '00000000-0000-0000-0000-000000000000';
      this.authModalTitle = 'Add Authorization';
    }
    !this.isStatusShow && this.authorizationForm.removeControl("rbtnAuth");

  }

  ngOnDestroy(): void {
    this.subscription$?.forEach((sub) => {
      sub && sub?.unsubscribe();
    });
  }
  updateCharacterCount() {
    // Ensure the text length does not exceed the maximum length
    if (this.notesText?.length > 5000) {
      this.notesText = this.notesText?.substr(0, 5000);
    }
  }
  // ! Auth Form With Validations
  createAuthorizationForm() {
    this.authorizationForm = this.fb.group({
      txtAuthId: new FormControl("", [Validators.required]),
      drpAuthVisit: new FormControl(null),
      txtAuthAmount: new FormControl(""),
      txtStartDate: new FormControl(""),
      txtEndDate: new FormControl(""),
      drpCategory: new FormControl(null),
      drpItem: new FormControl(null),
      txtNote: new FormControl(""),
      chkCompleted: new FormControl(""),
      txtCompletedBy: new FormControl("", [this.text.isTextCheck]),
      drpPlan: new FormControl(null),
      txtPlan: new FormControl(null, [Validators.required]),
      rbtnAuth: new FormControl("", Validators.required),
      rbtnPolicyType: new FormControl(null),
      txtReason: new FormControl(null),
      txtUnits: new FormControl(""),
      txtUsed: new FormControl(""),
      txtSaleOrRent: new FormControl(""),
      txtExpired: new FormControl(""),
      txtItemCodes: new FormControl(""),
      chkFollowUp: new FormControl("")
    });
  }

  // ! Load Order Dropdowns
  getDropdowns() {
    let orderDropdownInput: OrderDropdowns[];
    orderDropdownInput = [
      OrderDropdowns.AuthVisits,
      OrderDropdowns.AuthCategories,
      // OrderDropdowns.MarketingReferrals,
      OrderDropdowns.TypeOfPlans,
      OrderDropdowns.PlanTypes
    ]
    const orderDropdown = this.orderDropdownService.getOrderDropdownsByInput(orderDropdownInput).subscribe((stateResponse) => {
      const response: OrderMasterDropdownDTO = stateResponse;
      this.drpAuthVisit = response.authVisits;
      this.drpCategory = response.authCategories;
      // this.drpMarketingRef = response.marketingReferrals;
      this.drpPlan = response.typeOfPlans;
      this.drpPlanType = response?.planTypes;
    });
    this.subscription$.push(orderDropdown);
    //
    let patientDropdownInput: PatientDropdowns[];
    patientDropdownInput = [PatientDropdowns.SaleOrRent]
    const getPatientDropdown = this.patientDropdownService.getPatientDropdownsByInput(patientDropdownInput).subscribe((response: PatientMasterDropdownDTO) => {
      this.drpSaleOrRents = response.saleOrRents;
    });
    this.subscription$.push(getPatientDropdown)

    if (this.data?.patientId != null && this.data?.patientId != undefined && this.data?.patientId != "") {
      const getAllItemCode = this.saleOrderService.getAllItemCodesByPatientId(this.data?.patientId).subscribe(response => {
        this.drpItemCdoes = response;
      });
      this.subscription$.push(getAllItemCode)
    }
  }

  // ! Get Order Details for save
  getOrderDetailsForSave(authStatus) {
    let personalDetails: SaleorderDTO;
    const storeSubscription = this.saleOrderService.get(this.orderId).subscribe(response => {
      personalDetails = response;
      const savedData = {
        primaryAuthorizations: personalDetails?.patients?.authorizations?.primaryAuthorizations,
        secondaryAuthorizations: personalDetails?.patients?.authorizations?.secondaryAuthorizations,
        tertiaryAuthorizations: personalDetails?.patients?.authorizations?.tertiaryAuthorizations
      }
      return this.saveAuthorization(personalDetails, savedData, authStatus);
    }, err => {
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    })
    this.subscription$.push(storeSubscription);
  }

  // ! Save Auth
  saveAuthorization(response, savedData, authStatus) {
    this.saveButtonHide = true;
    this.isShowSpinner = true;
    const typeOfPlanName = this.drpPlan.map(v => v.typeOfPlanName);
    if (this.authorizationForm.value.txtPlan === typeOfPlanName[0]) {
      savedData.primaryAuthorizations = {
        authId: this.authorizationForm.value.txtAuthId,
        authVisits: this.authorizationForm.value.drpAuthVisit,
        authNo: this.authorizationForm.value.txtAuthNo,
        authAmount: this.authorizationForm.value.txtAuthAmount,
        startDate: this.authorizationForm.value.txtStartDate,
        endDate: this.authorizationForm.value.txtEndDate,
        category: this.authorizationForm.value.drpCategory,
        item: this.authorizationForm.value.drpItem === "" ? [] : this.authorizationForm.value.drpItem,
        note: this.authorizationForm.value.txtNote,
        isCompleted: this.authorizationForm.value.chkCompleted === true || this.authorizationForm.value.chkCompleted === 1 ? "Done" : "Pending",
        completedBy: this.authorizationForm.value.txtCompletedBy,
        typeOfPolicy: this.authorizationForm.value.drpPlan,
        plan: this.authorizationForm.value.txtPlan,
        planName: this.authorizationForm.value.txtPlanName,
        approvalStatus: this.authorizationForm.value.rbtnAuth,
        approvedByWhom: "",
        approvedOn: "",
        reason: this.authorizationForm.value.txtReason,
        units: this.authorizationForm.value.txtUnits,
        used: this.authorizationForm.value.txtUsed,
        saleOrRent: this.authorizationForm.value.txtSaleOrRent,
        isExpired: +(this.authorizationForm.value.txtExpired) ?? 0,
        itemCodes: this.authorizationForm.value.txtItemCodes,
        isFollowUp: +(this.authorizationForm.value.chkFollowUp)
      }
    } else if (this.authorizationForm.value.txtPlan === typeOfPlanName[1]) {

      savedData.secondaryAuthorizations = {
        authorizationId: this.authorizationId,
        authId: this.authorizationForm.value.txtAuthId,
        authVisits: this.authorizationForm.value.drpAuthVisit,
        authNo: this.authorizationForm.value.txtAuthNo,
        authAmount: this.authorizationForm.value.txtAuthAmount,
        startDate: this.authorizationForm.value.txtStartDate,
        endDate: this.authorizationForm.value.txtEndDate,
        category: this.authorizationForm.value.drpCategory,
        item: this.authorizationForm.value.drpItem === "" ? [] : this.authorizationForm.value.drpItem,
        note: this.authorizationForm.value.txtNote,
        isCompleted: this.authorizationForm.value.chkCompleted === true || this.authorizationForm.value.chkCompleted === 1 ? "Done" : "Pending",
        completedBy: this.authorizationForm.value.txtCompletedBy,
        typeOfPolicy: this.authorizationForm.value.drpPlan,
        plan: this.authorizationForm.value.txtPlan,
        planName: this.authorizationForm.value.txtPlanName,
        approvalStatus: this.authorizationForm.value.rbtnAuth,
        approvedByWhom: "",
        approvedOn: "",
        reason: this.authorizationForm.value.txtReason,
        units: this.authorizationForm.value.txtUnits,
        used: this.authorizationForm.value.txtUsed,
        saleOrRent: this.authorizationForm.value.txtSaleOrRent,
        isExpired: +(this.authorizationForm.value.txtExpired) ?? 0,
        itemCodes: this.authorizationForm.value.txtItemCodes,
        isFollowUp: +(this.authorizationForm.value.chkFollowUp)

      }
    } else if (this.authorizationForm.value.txtPlan === typeOfPlanName[2]) {

      savedData.tertiaryAuthorizations = {
        authorizationId: this.authorizationId,
        authId: this.authorizationForm.value.txtAuthId,
        authVisits: this.authorizationForm.value.drpAuthVisit,
        authNo: this.authorizationForm.value.txtAuthNo,
        authAmount: this.authorizationForm.value.txtAuthAmount,
        startDate: this.authorizationForm.value.txtStartDate,
        endDate: this.authorizationForm.value.txtEndDate,
        category: this.authorizationForm.value.drpCategory,
        item: this.authorizationForm.value.drpItem === "" ? [] : this.authorizationForm.value.drpItem,
        note: this.authorizationForm.value.txtNote,
        isCompleted: this.authorizationForm.value.chkCompleted === true || this.authorizationForm.value.chkCompleted === 1 ? "Done" : "Pending",
        completedBy: this.authorizationForm.value.txtCompletedBy,
        typeOfPolicy: this.authorizationForm.value.drpPlan,
        plan: this.authorizationForm.value.txtPlan,
        planName: this.authorizationForm.value.txtPlanName,
        approvalStatus: this.authorizationForm.value.rbtnAuth,
        approvedByWhom: "",
        approvedOn: "",
        reason: this.authorizationForm.value.txtReason,
        units: this.authorizationForm.value.txtUnits,
        used: this.authorizationForm.value.txtUsed,
        saleOrRent: this.authorizationForm.value.txtSaleOrRent,
        isExpired: +(this.authorizationForm.value.txtExpired) ?? 0,
        itemCodes: this.authorizationForm.value.txtItemCodes,
        isFollowUp: +(this.authorizationForm.value.chkFollowUp)
      }
    }
    let insAuthorization: SaleorderDTO;
    if (authStatus === "Disapproved" && (response?.patients?.insuranceVerifications?.primaryInsuranceVerifies?.isPercertRequired === 1
      || response?.patients?.insuranceVerifications?.secondaryInsuranceVerifies?.isPercertRequired === 1 ||
      response?.patients?.insuranceVerifications?.tertiaryInsuranceVerifies?.isPercertRequired === 1)) {
      insAuthorization = {
        defaultClaimId: response?.defaultClaimId,
        orders: {
          ...response.orders, isCompleted: "Shipping Disapproved"
        },
        clinicals: response?.clinicals,
        insurances: response?.insurances,
        items: response.items,
        paymentTransactionDetails: response?.paymentTransactionDetails,
        payPalMailResendCount: response?.payPalMailResendCount,
        payPalPaymentMethodType: response?.payPalPaymentMethodType,
        payPalPaymentStatus: response?.payPalPaymentStatus,
        patients: {
          personals: response.patients.personals,
          addresses: response.patients.addresses,
          contacts: response.patients.contacts,
          clinicals: response.patients.clinicals,
          insurances: response.patients.insurances,
          notes: response.patients.notes,
          policies: response.patients.policies,
          defaultPatientId: response.patients.defaultPatientId,
          insuranceVerifications: response.patients.insuranceVerifications,
          authorizations: {
            primaryAuthorizations: savedData.primaryAuthorizations,
            secondaryAuthorizations: savedData.secondaryAuthorizations,
            tertiaryAuthorizations: savedData.tertiaryAuthorizations
          },
          patientDocuments: response.patients.patientDocuments,
          organizationUnitId: response.patients.organizationUnitId,
          isAutoIntake: response.patients.isAutoIntake,
          autoIntakeDocumentDetails: response.patients.autoIntakeDocumentDetails,
          chartId: response.patients.chartId,
          billingDetails: response.patients.billingDetails,
          patientLock: response.patients.patientLock,
          // isSorted:response.patients.isSorted,
          isSorted: response.patients.isSorted,
          fileId: response?.patients?.fileId,

        },

        saleorderId: response?.saleorderId,
        orderStatus: "Shipping Disapproved",
        organizationUnitId: response?.organizationUnitId,
        currentStage: response?.currentStage,
        currentStageStatus: response?.currentStageStatus,
        claimId: response.claimId,
        patientId: response.patientId,
        isShippingApprove: response?.isShippingApprove,
        isCashPayment: response.isCashPayment,
        orderProcessStatus: response.orderProcessStatus,
        patientLedgerReason: response.patientLedgerReason,
        documentReason: response.documentReason,
        shippingCheckList: response.shippingCheckList,
        orderBilling: response.orderBilling,
        deliveryTicket: response?.deliveryTicket,
        authType: "Insurance Based Auth"
      }
    } else {
      let status;
      if (authStatus === "Disapproved") {
        status = { ...response.orders, isCompleted: "Done" }
      } else {
        status = response.orders
      }
      insAuthorization = {
        defaultClaimId: response?.defaultClaimId,
        orders: status,
        clinicals: response?.clinicals,
        insurances: response?.insurances,
        items: response.items,
        patients: {
          personals: response.patients.personals,
          addresses: response.patients.addresses,
          contacts: response.patients.contacts,
          clinicals: response.patients.clinicals,
          insurances: response.patients.insurances,
          notes: response.patients.notes,
          policies: response.patients.policies,
          defaultPatientId: response.patients.defaultPatientId,
          insuranceVerifications: response.patients.insuranceVerifications,
          authorizations: {
            primaryAuthorizations: savedData.primaryAuthorizations,
            secondaryAuthorizations: savedData.secondaryAuthorizations,
            tertiaryAuthorizations: savedData.tertiaryAuthorizations
          },
          patientDocuments: response.patients.patientDocuments,
          organizationUnitId: response.patients.organizationUnitId,
          isAutoIntake: response.patients.isAutoIntake,
          autoIntakeDocumentDetails: response.patients.autoIntakeDocumentDetails,
          chartId: response.patients.chartId,
          billingDetails: response.patients.billingDetails,
          patientLock: response.patients.patientLock,
          // isSorted:response.patients.isSorted,
          isSorted: response.patients.isSorted,
          fileId: response?.patients?.fileId,
        },
        saleorderId: response?.saleorderId,
        orderStatus: response.orderStatus,
        organizationUnitId: response?.organizationUnitId,
        currentStage: response?.currentStage,
        currentStageStatus: response?.currentStageStatus,
        claimId: response.claimId,
        patientId: response.patientId,
        isShippingApprove: response?.isShippingApprove,
        isCashPayment: response.isCashPayment,
        orderProcessStatus: response.orderProcessStatus,
        patientLedgerReason: response.patientLedgerReason,
        documentReason: response.documentReason,
        paymentTransactionDetails: response?.paymentTransactionDetails,
        payPalMailResendCount: response?.payPalMailResendCount,
        payPalPaymentMethodType: response?.payPalPaymentMethodType,
        payPalPaymentStatus: response?.payPalPaymentStatus,
        claimHistoryStatus: response.claimHistoryStatus,
        patientLedgerStatus: response.patientLedgerStatus,
        shippingCheckList: response.shippingCheckList,
        orderBilling: response.orderBilling,
        deliveryTicket: response?.deliveryTicket,
        authType: "Insurance Based Auth"
      }
    }

    this.orderId !== "" && this.store.dispatch(new updateOrder(this.orderId, insAuthorization)).subscribe(response => {
      this.Table.setSelectedOrderId(this.orderId);
      // Swal.fire({ title: 'Success', html: 'Saved Successfully', icon: 'success', timer: 3000, timerProgressBar: true })
      this.toastr.success('Saved Successfully','Success')
      this.orderId = response?.orderState?.selectedOrder?.id;
      this.Table.setSelectedOrderId(this.orderId);
      this.authDialogRef.close();
      this.saveButtonHide = false;
      this.isShowSpinner = false;
    }, err => {
      this.saveButtonHide = false;
      this.isShowSpinner = false;
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    });
  }

  // ! Get Auth Datas by Id
  getAuthDatas(response) {
    let orderDetails: SaleorderDTO;
    const storeSubscription = this.saleOrderService.get(response.orderId).subscribe(orderResponse => {
      // this.store.dispatch(new getOrder(response?.orderId)).subscribe(orderResponse => {
      orderDetails = orderResponse;
      const primaryAuth = orderDetails?.patients?.authorizations?.primaryAuthorizations
      const secondaryAuth = orderDetails?.patients?.authorizations?.secondaryAuthorizations
      const tertiaryAuth = orderDetails?.patients?.authorizations?.tertiaryAuthorizations
      if (response?.plan == "Primary") {
        this.authorizationForm.patchValue({
          txtAuthId: primaryAuth?.authId,
          drpAuthVisit: primaryAuth?.authVisits,
          txtAuthNo: primaryAuth?.authNo,
          txtAuthAmount: primaryAuth?.authAmount,
          txtStartDate: primaryAuth?.startDate,
          txtEndDate: primaryAuth?.endDate,
          drpCategory: primaryAuth?.category,
          drpItem: primaryAuth?.item,
          txtNote: primaryAuth?.note,
          chkCompleted: primaryAuth?.isCompleted == "Done" ? 1 : 0,
          txtCompletedBy: primaryAuth?.completedBy,
          txtPlan: primaryAuth?.plan,
          rbtnAuth: primaryAuth?.approvalStatus,
          txtReason: primaryAuth?.reason,
          txtUnits: primaryAuth?.units,
          txtUsed: primaryAuth?.used,
          txtSaleOrRent: primaryAuth?.saleOrRent,
          txtExpired: primaryAuth?.isExpired,
          txtItemCodes: primaryAuth?.itemCodes,
          chkFollowUp: primaryAuth?.isFollowUp
        });
        if (primaryAuth?.approvalStatus === "Disapproved") {
          this.Show = true
        }
      }
      else if (response?.plan == "Secondary") {
        this.authorizationForm.patchValue({
          txtAuthId: secondaryAuth?.authId,
          drpAuthVisit: secondaryAuth?.authVisits,
          txtAuthNo: secondaryAuth?.authNo,
          txtAuthAmount: secondaryAuth?.authAmount,
          txtStartDate: secondaryAuth?.startDate,
          txtEndDate: secondaryAuth?.endDate,
          drpCategory: secondaryAuth?.category,
          drpItem: secondaryAuth?.item,
          txtNote: secondaryAuth?.note,
          chkCompleted: secondaryAuth?.isCompleted == "Done" ? 1 : 0,
          txtCompletedBy: secondaryAuth?.completedBy,
          txtPlan: secondaryAuth?.plan,
          rbtnAuth: secondaryAuth?.approvalStatus,
          txtReason: secondaryAuth?.reason,
          txtUnits: secondaryAuth?.units,
          txtUsed: secondaryAuth?.used,
          txtSaleOrRent: secondaryAuth?.saleOrRent,
          txtExpired: secondaryAuth?.isExpired,
          txtItemCodes: secondaryAuth?.itemCodes,
          chkFollowUp: secondaryAuth?.isFollowUp
        });
        if (secondaryAuth?.approvalStatus === "Disapproved") {
          this.Show = true
        }
      }
      else if (response?.plan == "Tertiary") {
        this.authorizationForm.patchValue({
          txtAuthId: tertiaryAuth?.authId,
          drpAuthVisit: tertiaryAuth?.authVisits,
          txtAuthNo: tertiaryAuth?.authNo,
          txtAuthAmount: tertiaryAuth?.authAmount,
          txtStartDate: tertiaryAuth?.startDate,
          txtEndDate: tertiaryAuth?.endDate,
          drpCategory: tertiaryAuth?.category,
          drpItem: tertiaryAuth?.item,
          txtNote: tertiaryAuth?.note,
          chkCompleted: tertiaryAuth?.isCompleted == "Done" ? 1 : 0,
          txtCompletedBy: tertiaryAuth?.completedBy,
          txtPlan: tertiaryAuth?.plan,
          rbtnAuth: tertiaryAuth?.approvalStatus,
          txtReason: tertiaryAuth?.reason,
          txtUnits: tertiaryAuth?.units,
          txtUsed: tertiaryAuth?.used,
          txtSaleOrRent: tertiaryAuth?.saleOrRent,
          txtExpired: tertiaryAuth?.isExpired,
          txtItemCodes: tertiaryAuth?.itemCodes,
          chkFollowUp: tertiaryAuth?.isFollowUp
        });
        if (tertiaryAuth?.approvalStatus === "Disapproved") {
          this.Show = true
        }
      }
    })
  }

  // ! Get Auth Datas based on radio button change
  onChangeGetAuth($event: MatRadioChange) {
    this.clearData();
    const storeSubscription = this.saleOrderService.get(this.orderId).pipe(map(responses => {
      // this.store.dispatch(new getOrder(this.orderId)).pipe(map(responses => {
      const selectedPatient = responses?.patients.authorizations;
      const authPrimaryData: any[] = [];
      const authSecondaryData: any[] = [];
      const authTertiaryData: any[] = [];
      const authPrimaryResponse = selectedPatient?.primaryAuthorizations;
      const authSecondaryResponse = selectedPatient?.secondaryAuthorizations;
      const authTertiaryResponse = selectedPatient?.tertiaryAuthorizations;
      if ($event.value === 'Primary') {
        $event.value === 'Primary' && authPrimaryResponse.plan === "Primary" && authPrimaryData.push(authPrimaryResponse)
        return authPrimaryData
      }
      if ($event.value === 'Secondary') {
        $event.value === 'Secondary' && authSecondaryResponse?.plan === "Secondary" && authSecondaryData.push(authSecondaryResponse)
        return authSecondaryData
      }
      if ($event.value === 'Tertiary') {
        $event.value === 'Tertiary' && authTertiaryResponse?.plan === "Tertiary" && authTertiaryData.push(authTertiaryResponse)
        return authTertiaryData
      }
    })).subscribe((response) => {
      if (response && response[0]) {
        this.authorizationForm.patchValue({
          txtAuthId: response[0]?.authId,
          drpAuthVisit: response[0]?.authVisits,
          txtAuthNo: response[0]?.authNo,
          txtAuthAmount: response[0]?.authAmount,
          txtStartDate: response[0]?.startDate,
          txtEndDate: response[0]?.endDate,
          drpCategory: response[0]?.category,
          drpItem: response[0]?.item,
          txtNote: response[0]?.note,
          chkCompleted: response[0]?.isCompleted,
          txtCompletedBy: response[0]?.completedBy,
          txtPlan: response[0]?.plan
        })
      }

    })
    this.subscription$.push(storeSubscription);
  }

  // ! Get Policy Datas based on Payor Level Dropdown
  valueChangeLoadDropdown(type: MatSelectChange) {
    // this.authorizationForm.patchValue({
    //   txtPlanName: this.drpPolicies.filter(value => {
    //     return value && value.payorLevel === type.value
    //   })[0]?.insuranceType,
    //   txtPolicy: this.drpPolicies.filter(value => { return value && value.payorLevel === type.value })[0]?.policyNo,
    //   txtGroup: this.drpPolicies.filter(value => { return value && value.payorLevel === type.value })[0]?.groupName,
    // })
  }

  // ! Get Disapprove reason based on radio button changes
  onChangeGetReason($event: MatRadioChange) {
    if ($event.value === "Disapproved") {
      this.Show = true
    } else {
      this.Show = false
    }
  }

  // ! Reset auth Form
  clearData() {
    this.authorizationForm.reset();
    this.authorizationForm.markAsUntouched();
    this.authorizationForm.updateValueAndValidity();
  }

  // ! Clear dropdowns
  clearAuthVisit() {
    this.authVisit = '';
    this.authorizationForm.patchValue({
      drpAuthVisit: ''
    });
  }

  clearCategory() {
    this.category = '';
    this.authorizationForm.patchValue({
      drpCategory: ''
    });
  }

  clearItem() {
    this.item = '';
    this.authorizationForm.patchValue({
      drpItem: ''
    });
  }
  clearPlan() {
    this.plan = '';
    this.authorizationForm.patchValue({
      txtPlan: ''
    });
  }
  clearSale() {
    this.sale = '';
    this.authorizationForm.patchValue({ txtSaleOrRent: "" });
  }
  clearItemCodes() {
    this.itemCodes = '';
    this.authorizationForm.patchValue({ txtItemCodes: "" });
  }
}
