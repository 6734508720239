import { Component, OnInit } from '@angular/core';
import { PatientsService } from '../patient-proxy/patient/patients.service';
import { HttpErrorResponse } from '@angular/common/http';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';

@Component({
  selector: 'app-masterVerification_doAuthorization',
  templateUrl: './masterVerification_doAuthorization.component.html',
  styleUrls: ['./masterVerification_doAuthorization.component.scss']
})
export class MasterVerification_doAuthorizationComponent implements OnInit {
  sLstAuthorizationId:any;
  iPolicyIdCount:number;
  isLoading:boolean=false;
  constructor(
    private patientsService: PatientsService,
    private router: Router,
  ) { }

  ngOnInit() {
    this.getauthorizationId();
  }
  getauthorizationId(){
    this.isLoading=true;
    const PolicyIds = this.patientsService
    .getLockedAuthIdByTenant()
    .subscribe(
      (response) => {
        this.sLstAuthorizationId=response;
        this.isLoading=false;
        this.iPolicyIdCount=this.sLstAuthorizationId.length;
        if(this.iPolicyIdCount ===0){
          this.router.navigate(['navigation/masterverification']);
        }
      },
      (err) => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      }
    );
  }
  loadPage(authId){
    // window.location.reload();
    const objWithIdIndex = this.sLstAuthorizationId.findIndex((obj) => obj.athorizationId === authId);
      this.sLstAuthorizationId.splice(objWithIdIndex, 1);
      this.iPolicyIdCount=this.sLstAuthorizationId.length;
      if(this.iPolicyIdCount ===0){
        this.router.navigate(['navigation/masterverification']);
      }
  }
}
