import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import Swal from 'sweetalert2';
import { map, startWith } from 'rxjs/operators';
import { RegionCountriesDTO, RegionStatesDTO } from '../dropdown-proxy/rcm/dropdown-management/dropdowns/dto/models';
import { RegionCitiesDTO } from '../dropdown-proxy/dropdown-management/dropdowns/dto/models';
import { RegionDropdownService } from '../dropdown-proxy/rcm/dropdown-management/dropdowns/region-dropdown-service/region-dropdown.service';
import { ToastrService } from "ngx-toastr";
import { PagedAndSortedResultRequestDto } from '@abp/ng.core';
import { CreateUpdatePracticeManagementDTO, CreateUpdateRequirementsDTO, CreateUpdateMoreInfoDTO } from '../patient-proxy/patient/practice-management/dto/models';
import { PracticeManagementService } from '../patient-proxy/patient/practice-management';
import { Status } from 'projects/inventory/src/app/item-proxy/item-management/items/status.enum';
import { ActivatedRoute } from '@angular/router';
import { defaultCountry } from 'projects/shared/src/app/enums/allenums.enum';


@Component({
  selector: 'app-practice-management',
  templateUrl: './practice-management.component.html',
  styleUrls: ['./practice-management.component.scss']
})
export class PracticeManagementComponent implements OnInit, OnDestroy {
  // declarations
  prtzManagForm: FormGroup;
  txtPractizCountry: string = "";
  txtPractizState: string = "";
  txtPractizCity: string = "";
  filteredPractizCountry: Observable<any>;
  filteredPractizState: Observable<any>;
  filteredPractizCity: Observable<any>;
  drpStates: RegionStatesDTO[] = [];
  drpCities: RegionCitiesDTO[] = [];
  drpCountry: RegionCountriesDTO[] = [];
  public filteredStates: Observable<RegionStatesDTO[]> | undefined;
  public filteredCities: Observable<RegionCitiesDTO[]> | undefined;
  public filteredCountries: Observable<RegionCountriesDTO[]> | undefined;
  practiceId: string = '';
  paracticeManagementId: string = "";
  saveButtonHide: boolean;
  status: string = '';
  stateName: string = "";
  CountryName: string = "";
  branchName: string = '';
  Country: string = "";
  State: string = "";
  City: string = "";
  subscription$: Subscription[] = [];
  constructor(
    private formBuilder: FormBuilder,
    private regionDropdownService: RegionDropdownService,
    private PracticeManagementService: PracticeManagementService,
    private activatedRoute: ActivatedRoute,
    private toastr: ToastrService,
  ) { }

  // initialize funtions
  ngOnInit(): void {
    const activateRoute = this.activatedRoute.paramMap.subscribe((response) => {
      this.paracticeManagementId = response.get('practiceId') ?? '';
    });
    this.subscription$.push(activateRoute);
    //
    this.prtzManagForm = this.formBuilder.group({
      txtPractizName: new FormControl("", Validators.required),
      txtPractizAddress: new FormControl(""),
      drpCountry: new FormControl("", Validators.required),
      drpCity: new FormControl("", Validators.required),
      drpState: new FormControl("", Validators.required),
      intPractizZipCode: new FormControl(""),
      txtPractizWebsite: new FormControl(""),
      txtPractizManager: new FormControl(""),
      txtPractizEmail: new FormControl(""),
      txtAccountManger: new FormControl(""),
      txtComtNotes: new FormControl(""),
      IsMedicareIDTF: new FormControl(""),
      IsOutSrcReferrals: new FormControl(""),
      txtCountryFilter: new FormControl(""),
      drpStateFilter: new FormControl(""),
      drpCityFilter: new FormControl(""),
      txtPhoneNumber: new FormControl("", Validators.required),
      txtFax: new FormControl("", Validators.required)
    })
    this.countryDropdown();
    this.getPhysicianById();
  }

  ngOnDestroy(): void {
    this.subscription$?.forEach((sub) => {
      sub && sub?.unsubscribe();
    });
  }

  // Get Country Dropdown
  countryDropdown() {
    this.prtzManagForm.patchValue({ "txtCountry": defaultCountry.US })
    this.onChangeState(defaultCountry.US);
    this.Country = defaultCountry.US;
    this.drpCountry = [];
    const countryDropdown = this.regionDropdownService.getAllCountriesByInput(new PagedAndSortedResultRequestDto).subscribe(response => {
      //
      this.drpCountry = response?.items.filter(x => x.countryShortName == "US") ?? [];
      this.filteredCountries = this.prtzManagForm?.get("txtCountryFilter")?.valueChanges
        .pipe(
          startWith(''),
          map(value => this.drpCountry.filter(option => option?.countryName?.toLowerCase()?.includes(value?.toLowerCase() ?? "")))
        );
    }, err => {
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    });
    this.subscription$.push(countryDropdown);
  }
  // On Change State Dropdown
  onChangeState(value: string | null | undefined): boolean {
    this.prtzManagForm.patchValue({
      drpState: null,
      drpCity: null
    })
    this.drpStates = [];
    this.drpCities = [];
    if (value !== null && value !== undefined) {
      const stateDropdown = this.regionDropdownService.getAllStatesByCountryNameByCountryShortName(value).subscribe(response => {
        this.drpStates = response?.items ?? [];

        this.filteredStates = this.prtzManagForm?.get("drpStateFilter")?.valueChanges
          .pipe(
            startWith(''),
            map(value => this.drpStates.filter(option =>
              option?.stateName?.toLowerCase()?.includes(value?.toLowerCase() ?? "")
            ))
          );
      }, err => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      });
      this.subscription$.push(stateDropdown);
    }
    return this.drpStates !== [] && this.drpStates.length !== 0;
  }
  // On Change City Dropdown
  onChangeCity(state: string | null | undefined, country: string): boolean {
    this.prtzManagForm.patchValue({
      drpCity: null
    });
    country = (country == "") ? (this.prtzManagForm.value.drpCountry == (null || undefined) ? "" : (this.prtzManagForm.value.drpCountry)) : country;
    this.drpCities = [];
    if (state !== null && state !== undefined) {
      const cityDropdown = this.regionDropdownService.getAllCitiesByCountryAndStateNameByCountryShortNameAndStateShortName(country, state).subscribe(response => {
        this.drpCities = response?.items ?? [];

        this.filteredCities = this.prtzManagForm?.get("drpCityFilter")?.valueChanges
          .pipe(
            startWith(''),
            map(value => this.drpCities.filter(option => option?.cityName?.toLowerCase()?.includes(value?.toLowerCase() ?? "")))
          );
      }, err => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      });
      this.subscription$.push(cityDropdown);
    }

    return this.drpCities !== [] && this.drpCities.length !== 0;
  }



  // Dropdown Clear Functionality for Country
  clearCountry() {
    this.Country = "";
    this.prtzManagForm.patchValue({ drpCountry: "" })
  }
  // Dropdown Clear Functionality for State
  clearState() {
    this.State = "";
    this.prtzManagForm.patchValue({ drpState: "" })
  }
  // Dropdown Clear Functionality for City
  clearCity() {
    this.City = "";
    this.prtzManagForm.patchValue({ drpCity: "" })
  }


  // Save functionality
  savePratzManager() {
    if ((this.paracticeManagementId == null || this.paracticeManagementId == "" || this.paracticeManagementId == undefined)) {
      let savePractzParameter: CreateUpdatePracticeManagementDTO = {
        practiceName: this.prtzManagForm.value.txtPractizName,
        practiceAddress: this.prtzManagForm.value.txtPractizAddress,
        practiceCountry: this.prtzManagForm.value.drpCountry,
        practiceCity: this.prtzManagForm.value.drpCity,
        practiceState: this.prtzManagForm.value.drpState,
        practiceZipCode: this.prtzManagForm.value.intPractizZipCode,
        practicePhone: this.prtzManagForm.value.txtPhoneNumber,
        practiceFax: this.prtzManagForm.value.txtFax,
        practiceWebsite: this.prtzManagForm.value.txtPractizWebsite,
        practiceManager: this.prtzManagForm.value.txtPractizManager,
        practiceEmail: this.prtzManagForm.value.txtPractizEmail,
        isMedicareIdtf: +this.prtzManagForm.value.IsMedicareIDTF,
        isOutSourceReferrals: +this.prtzManagForm.value.IsOutSrcReferrals,
        accountManager: this.prtzManagForm.value.txtAccountManger,
        comments: this.prtzManagForm.value.txtComtNotes,
        // status: Status.Active,
        physicians: [],
        requirements: null,
        moreInfos: null,
        notes: [],
        faxLogs: [],
        faxNumbers: []
      };
      this.PracticeManagementService.create(savePractzParameter).subscribe(response => {
        // Swal.fire({ title: 'Success', html: 'Saved Successfully', icon: 'success', timer: 3000, timerProgressBar: true }).then(result => {

        // });
        this.toastr.success('Saved Successfully','Success')
        this.paracticeManagementId = response.id;

      }, err => {

        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      });
    } else {
      let savePractzParameter: CreateUpdatePracticeManagementDTO = {
        practiceName: this.prtzManagForm.value.txtPractizName,
        practiceAddress: this.prtzManagForm.value.txtPractizAddress,
        practiceCountry: this.prtzManagForm.value.drpCountry,
        practiceCity: this.prtzManagForm.value.drpCity,
        practiceState: this.prtzManagForm.value.drpState,
        practiceZipCode: this.prtzManagForm.value.intPractizZipCode,
        practicePhone: this.prtzManagForm.value.txtPhoneNumber,
        practiceFax: this.prtzManagForm.value.txtFax,
        practiceWebsite: this.prtzManagForm.value.txtPractizWebsite,
        practiceManager: this.prtzManagForm.value.txtPractizManager,
        practiceEmail: this.prtzManagForm.value.txtPractizEmail,
        isMedicareIdtf: +this.prtzManagForm.value.IsMedicareIDTF,
        isOutSourceReferrals: +this.prtzManagForm.value.IsOutSrcReferrals,
        accountManager: this.prtzManagForm.value.txtAccountManger,
        comments: this.prtzManagForm.value.txtComtNotes,
        // status: Status.Active,
        physicians: [],
        requirements: null,
        moreInfos: null,
        notes: [],
        faxLogs: [],
        faxNumbers: []
      };
      this.PracticeManagementService.update(this.paracticeManagementId, savePractzParameter).subscribe(response => {
        // Swal.fire({ title: 'Success', html: 'Updated Successfully', icon: 'success', timer: 3000, timerProgressBar: true }).then(result => {
        // });
        this.toastr.success('Updated Successfully','Success')
        this.paracticeManagementId = response.id;
      }, err => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      });
    }
  }

  // GET BY ID TO VIEW AND LOAD
  getPhysicianById() {
    const getById = this.PracticeManagementService.get(this.paracticeManagementId).subscribe(response => {

      this.stateName = response?.practiceState;
      this.CountryName = response?.practiceCountry;

      this.CountryName != null && this.CountryName !== "" && this.CountryName != undefined &&
        this.onChangeState(this.CountryName) && this.prtzManagForm.patchValue({
          drpCountry: this.CountryName,
        });
      this.stateName != null && this.stateName !== "" && this.stateName != undefined &&
        this.onChangeCity(this.stateName, this.CountryName) && this.prtzManagForm.patchValue({
          drpState: this.stateName,
          drpCity: response?.practiceCity
        });

      this.prtzManagForm.patchValue({
        txtPractizName: response?.practiceName,
        txtPractizAddress: response?.practiceAddress,
        drpCountry: response?.practiceCountry,
        drpCity: response?.practiceCity,
        drpState: response?.practiceState,
        intPractizZipCode: response?.practiceZipCode,
        txtPractizWebsite: response?.practiceWebsite,
        txtPractizManager: response?.practiceManager,
        txtPractizEmail: response?.practiceEmail,
        txtAccountManger: response?.accountManager,
        txtComtNotes: response?.comments,
        IsMedicareIDTF: response?.isMedicareIdtf,
        IsOutSrcReferrals: response?.isOutSourceReferrals,
        txtPhoneNumber: response?.practicePhone,
        txtFax: response?.practiceFax
      });
    })
    this.subscription$.push(getById);
  }

}
