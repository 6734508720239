<table
  datatable
  [dtOptions]="dtTableOptions"
  class="table table-hover w-100 display"
>
  <thead>
    <tr>
      <th [matTooltip]="'Options'"><mat-icon>apps</mat-icon></th>
      <th [matTooltip]="'Chart No'">Chart No</th>
      <th [matTooltip]="'Patient Name'">Patient Name</th>
      <th [matTooltip]="'Category'">Insurance Category</th>
      <th [matTooltip]="'Location'">Location</th>
      <th [matTooltip]="'Location'">Practice</th>
      <th [matTooltip]="'Patient Provider'">Patient Provider</th>
      <th [matTooltip]="'Paid On'">Paid On</th>
      <th class="text-right"[matTooltip]="'Patient Balance($)'">Patient Balance($)</th>
      <th class="text-right"[matTooltip]="'Insurance Balance($)'">Insurance Balance($)</th>
      <th [matTooltip]="'Message'">Message</th>
      <th [matTooltip]="'Pre-Collection Status'">Pre-Collection Status</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let data of patientStatementTable">
      <td [matTooltip]="'Options'">
        <mat-icon style="cursor: pointer !important" [matMenuTriggerFor]="menu"
          >more_vert</mat-icon
        >
        <mat-menu class="custom-mat-menu" #menu="matMenu">
          <button
            (click)="openPatientStatementHistory(data.patientId, data.chartId)"
            mat-menu-item
          >
            <img
              class="img-responsive"
              src="assets/Submenu Icons/Statement History.png"
              alt=""
              height="14"
            />

            Order History
          </button>
          <button (click)="openViewNotesPopup(data.patientId)" mat-menu-item>
            <img
              class="img-responsive"
              src="assets/Submenu Icons/View Notes.png"
              alt=""
              height="14"
            />
            View Notes
          </button>
          <button mat-menu-item>
            <a
              [routerLink]="
                '/patientCreateEditTab/' +
                data.patientId +
                '/' +
                defaultGuid +
                '/' +
                'Claim'
              "
              target="_blank"
              class="payment-link"
            >
              <img
                class="img-responsive"
                src="assets/Submenu Icons/Last Payment.png"
                alt=""
                height="11"
              />
              Last Payment</a
            >
          </button>
        </mat-menu>
      </td>
      <td [matTooltip]="data.chartId || '-'">
        <a
          [routerLink]="'/patientCreateEditTab/' + data.patientId"
          target="_blank"
          class="preview-link"
        >
          {{ data.chartId || "-" }}
        </a>
      </td>
      <td [matTooltip]="data.patientName || '-'">
        {{ data.patientName || "-" }}
      </td>
      <td [matTooltip]="data.patientName || '-'">
        {{ data.insurance || "-" }}
      </td>
      <td [matTooltip]="data.locationName || '-'">
        {{ data.locationName || "-" }}
      </td>
      <td [matTooltip]="data.referringProviderFacility || '-'">
        {{ data.referringProviderFacility || "-" }}
      </td>
      <td [matTooltip]="data.providerName || '-'">
        {{ data.providerName || "-" }}
      </td>
      <td [matTooltip]="data.paidDate || '-'">{{ data.paidDate || "-" }}</td>
      <td class="text-right"[matTooltip]="data.patientBalance || '-'">
        {{ data.patientBalance || "-" }}
      </td>
      <td class="text-right"[matTooltip]="data.insuranceBalance || '-'">
        {{ data.insuranceBalance || "-" }}
      </td>
      <td [matTooltip]="'-'">-</td>
      <td [matTooltip]="'-'">-</td>
    </tr>
  </tbody>
  <tfoot>
    <tr>
      <th><mat-icon>apps</mat-icon></th>
      <th>
        <input autocomplete="off"
          matInput
          class="textbox-border-class"
          type="text"
          placeholder="Chart No"
        />
      </th>
      <th>
        <input autocomplete="off"
          matInput
          class="textbox-border-class"
          type="text"
          placeholder="Patient Name"
        />
      </th>
      <th>
        <input autocomplete="off"
          matInput
          class="textbox-border-class"
          type="text"
          placeholder="Category"
        />
      </th>
      <th>
        <input autocomplete="off"
          matInput
          class="textbox-border-class"
          type="text"
          placeholder="Location"
        />
      </th>
      <th>
        <input autocomplete="off"
          matInput
          class="textbox-border-class"
          type="text"
          placeholder="Practice"
        />
      </th>
      <th>
        <input autocomplete="off"
          matInput
          class="textbox-border-class"
          type="text"
          placeholder="Patient Provider"
        />
      </th>
      <th>
        <input autocomplete="off"
          matInput
          class="textbox-border-class"
          type="text"
          placeholder="Paid On"
        />
      </th>
      <th>
        <input autocomplete="off"
          matInput
          class="textbox-border-class text-right"
          type="text"
          placeholder="Patient Balance($)"
        />
      </th>
      <th>
        <input autocomplete="off"
          matInput
          class="textbox-border-class text-right"
          type="text"
          placeholder="Insurance Balance($)"
        />
      </th>
      <th>
        <input autocomplete="off"
          matInput
          class="textbox-border-class"
          type="text"
          placeholder="Message"
        />
      </th>
      <th>
        <input autocomplete="off"
          matInput
          class="textbox-border-class"
          type="text"
          placeholder="Pre-Collection Status"
        />
      </th>
    </tr>
  </tfoot>
</table>
