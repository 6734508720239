import { PagedAndSortedResultRequestDto } from '@abp/ng.core';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { ClaimDeniedReportsDTO } from 'projects/patient/src/app/patient-proxy/patient/dto/models';
import { ReportService } from 'projects/patient/src/app/patient-proxy/patient/report.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-denial-year-report',
  templateUrl: './denial-year-report.component.html',
  styleUrls: ['./denial-year-report.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition(
        'expanded <=> collapsed',
        animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')
      ),
    ])],
})
export class DenialYearReportComponent implements OnInit {
  isLoading: boolean = true;
  dataSource: MatTableDataSource<any> = new MatTableDataSource<any>([]);
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatTable, { static: false }) tableRef: MatTable<any>;
  denialYearReport: FormGroup;
  arrDisplayedColumns = ["fileName", "fileSize", "denied", "Options"];
  reportArr: any[] = [];
  constructor(public fb: FormBuilder,
    private reportService: ReportService) { }
  expandedElement: any | null;
  ngOnInit(): void {
    this.denialYearReport = this.fb.group({
      documentName: new FormControl(""),
      documentType: new FormControl(""),
      uploadedBy: new FormControl(""),
      defaultBatchId: new FormControl(""),
      assignedByUserName: new FormControl(""),
      fileName: new FormControl(""),
      fileSize: new FormControl(""),
      numberOfPages: new FormControl(""),
      fileStatus: new FormControl(""),
      assignedToUserName: new FormControl(""),
    })
    this.getTableData()
  }


  /// get table data
  getTableData() {
    this.isLoading = true;
    this.reportService.getClaimDeniedReports().subscribe(resposne => {
      this.reportArr = []
      resposne.yearlyDeniedReports.forEach(ele => {
        this.reportArr.push({
          year:ele?.year,
          yearlyTotalBilled:ele?.yearlyTotalBilled,
          monthlyDeniedReports: ele?.monthlyDeniedReports,
          yearlyTotalDeniedCount: ele?.yearlyTotalDeniedCount,
          yearlyTotalPatientCount: ele?.yearlyTotalPatientCount,

        })
      })
      setTimeout(() => {
        this.dataSource = new MatTableDataSource(this.reportArr);
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
      }, 100);
      this.isLoading = false;
    }, err => {
      this.isLoading = false;
      let error: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: error?.error?.error?.message,
      });
    });
  }

}
