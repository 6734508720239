import { HttpErrorResponse } from '@angular/common/http';
import {
  Component,
  ElementRef,
  OnInit,
  ViewChild,
} from '@angular/core';
import { DomSanitizer, Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { InboundDocumentService } from 'projects/admin/src/app/admin-proxy/platform-app-management/rcm/platform-management/inbound/inbound-document.service';
import { Subscription } from 'rxjs';
import { defaultGuid } from '../../enums/allenums.enum';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-common-doc-tiff-viewer',
  templateUrl: './common-doc-tiff-viewer.component.html',
  styleUrls: ['./common-doc-tiff-viewer.component.scss'],
})
export class CommonDocTiffViewerComponent implements OnInit {
  isDownloading:boolean = false;
  docId: string;
  chartID: string;
  blobName: string;
  patientName:string;
  strPdfString: string = '';
  pdfheight: string = '100%';
  strSelectedPdfPath: string = '';
  isHideTiffViewer: boolean = false;
  totalPagesV1: number;
  readonly DEFAULT_LAZY_LOADING = 10;
  from: number = 1;
  to: number = this.DEFAULT_LAZY_LOADING;
  manual: number = 0;
  headerText: string;
  defaultFaxId:string;
  subscription$: Subscription[] = [];
  isLoading: boolean;
  loadInboundDocumentAPICall$: Subscription;
  @ViewChild('canvas', { static: true }) canvasRef!: ElementRef;
  ctx!: CanvasRenderingContext2D;
  pageNumber: number = 1;
  totalPages: number = 0;
  // readonly DEFAULT_ZOOM_LEVEL = 1;
  // readonly MAX_ZOOM_LEVEL = 2.5;
  // readonly MIN_ZOOM_LEVEL = 0.5;
  readonly DEFAULT_ZOOM_LEVEL = 0.8;
  readonly MAX_ZOOM_LEVEL = this.DEFAULT_ZOOM_LEVEL+4;
  readonly MIN_ZOOM_LEVEL = 0.6;
  zoomLevel: number = this.DEFAULT_ZOOM_LEVEL; // Default Size
  zoomLevelReSize:number = this.DEFAULT_ZOOM_LEVEL;
  existingDocumentImageList: any = [];
  selectedExistingDocument: any = null;
  loadingMessage: string = 'Loading ...';
  TiffError: boolean = true;
  imgPath:any;
  constructor(
    private activatedRoute: ActivatedRoute,
    private inboundDocumentService: InboundDocumentService,
    private sanitizer: DomSanitizer,
    private toastr: ToastrService,
    private title : Title
  ) {}

  ngOnInit(): void {
    this.title.setTitle('Qsecure | Preview Document');
    const activatedRoute = this.activatedRoute.paramMap.subscribe(
      (response) => {
        this.headerText = response.get('headerText');
        this.docId = response.get('docId');
      },
      (err) => {
        const data: HttpErrorResponse = err;
        this.toastr.error(data?.error?.error?.message, 'Error');
      }
    );
    this.subscription$.push(activatedRoute);

    if (!this.isEmpty(this.docId)) {
      this.loadInboundDocument(this.docId, this.from, this.to);
    }
    this.loadPatientDetails(this.docId);
  }
  ngAfterViewInit() {}

  ngOnDestroy(): void {
    if (this.loadInboundDocumentAPICall$) {
      this.loadInboundDocumentAPICall$?.unsubscribe();
    }
    this.subscription$?.forEach((sub) => {
      sub && sub?.unsubscribe();
    });
  }

  loadPatientDetails(docId: string) {
    const getPatientDetails = this.inboundDocumentService
      .getPatientDemographicsDetails(docId)
      .subscribe(
        (response) => {
          this.defaultFaxId = response?.defaultFaxId || '-'
          this.blobName = response.blobName || '';
          this.chartID = response?.chartId || '';
          this.patientName = ((response?.firstName || '') +' '+ (response?.middleName || '') +' '+ (response?.lastName || '')).trim();
        },
        (err) => {
          const data: HttpErrorResponse = err;
          // this.toastr.error(data?.error?.error?.message, 'Error');
          console.warn(data?.error?.error?.message);
        }
      );
  }

  loadInboundDocument(gId: string, from: number, to: number) {
    this.isLoading = true;
    let pageNo = 1;
    if (this.loadInboundDocumentAPICall$) {
      this.loadInboundDocumentAPICall$?.unsubscribe();
    }
    const loadInboundDocument = this.inboundDocumentService
      .getTIFFImageBase64v1ByGIdAndFromAndTo(
        gId,
        this.from,
        1
      )
      .subscribe(
        (response) => {
          this.imgPath=response.files[0].file;
          this.totalPages = response.files.length;
          this.totalPagesV1 = response.totalPagecount;
          if (response && this.totalPages > 0) {
            this.existingDocumentImageList = response.files.map((a) => {
              return {
                ...a,
                src: this.sanitizer.bypassSecurityTrustResourceUrl(
                  `data:image/tiff;base64, ${a.file}`
                ),
                pageNo: pageNo++,
              };
            });
            this.selectedExistingDocument = this.existingDocumentImageList?.[0];
            this.loadTiffImage(this.existingDocumentImageList?.[0].file);
            this.loadInboundDocumentLoadMore(
              this.docId,
              this.from,
              // this.to,
              1,
              this.manual
            );
          }
          this.isLoading = false;
          this.TiffError = false;
        },
        (err) => {
          this.handleErrorResponse(err);
        }
      );
    this.loadInboundDocumentAPICall$ = loadInboundDocument;
  }

  loadInboundDocumentLoadMore(
    gId: string,
    from: number,
    to: number,
    manual: number
  ) {
    // if (this.totalPagesV1 >= this.to) {
      this.from = to + 1;
      this.to = to + this.DEFAULT_LAZY_LOADING;


    if (this.from <= this.totalPagesV1 ||  this.totalPagesV1>= this.to) {

      if (this.loadInboundDocumentAPICall$) {
        this.loadInboundDocumentAPICall$?.unsubscribe();
      }

      // gId: string, from: number, to: number, manual: number
      const loadInboundDocument = this.inboundDocumentService
        .getTIFFImageBase64v1ByGIdAndFromAndTo(
          gId,
          this.from,
          this.to
        )
        .subscribe(
          (response) => {
            // this.totalPages = response.length;
            if (response && this.totalPages > 0) {
              // Assuming response is an array of items to be pushed into the list
              this.totalPagesV1=response.totalPagecount;
              response.files.forEach((item) => {
                const newItem = {
                  ...item,
                  src: this.sanitizer.bypassSecurityTrustResourceUrl(
                    `data:image/tiff;base64, ${item.file}`
                  ),
                  pageNo: item.pageNo,
                };
                this.existingDocumentImageList.push(newItem);
                item = null;
              });


              this.totalPages=this.existingDocumentImageList.length;
            }

            this.loadInboundDocumentLoadMore(
              this.docId,
              this.from,
              this.to,
              this.manual
            );
          },
          (err) => {

          }
        );
      this.loadInboundDocumentAPICall$ = loadInboundDocument;
    }
  }

  private handleErrorResponse(err: any) {
    const data: HttpErrorResponse = err;
    this.loadingMessage = data?.error?.error?.message || 'The image has not been converted.';
    // this.toastr.error(this.loadingMessage, 'Error');
    this.TiffError = true;
    this.isLoading = false;
    this.isHideTiffViewer = true;
    this.loadPatientDemoForm(this.docId);

  }

  loadPatientDemoForm(docId: string) {
    const getPatientDetails = this.inboundDocumentService
      .getPatientDemographicsDetails(docId)
      .subscribe(
        (response) => {
          this.blobName = response.blobName;
          this.loadingMessage ='TIFF Image conversion has been In Progress....';
          if (this.isHideTiffViewer && !this.isEmpty(this.blobName)) {
            this.loadInboundDocumentPDF();
          }
        },
        (err) => {
          const data: HttpErrorResponse = err;
          // this.toastr.error(data?.error?.error?.message, 'Error');
          console.warn(data?.error?.error?.message);
        }
      );
  }

  loadInboundDocumentPDF() {
    this.inboundDocumentService
      // .downloadInboundDocumentByBlobName(this.blobName)
      .getPatientDocumentByPathByDocumentId(this.docId)
      .subscribe(
        (response) => {
          if (
            typeof response === 'string' &&
            response &&
            response !== null
            //  &&
            // response?.length !== 0
          ) {
            this.strPdfString = String(response);
          }
        },
        (err) => {
          this.strSelectedPdfPath = '';

          const data: HttpErrorResponse = err;
          // Swal.fire({
          //   icon: 'info',
          //   text: data?.error?.error?.message,
          // });
        }
      );
  }



  // loadInboundDocument(gId: string) {
  //   this.isLoading = true;
  //   let pageNo = 1;
  //   if (this.loadInboundDocumentAPICall$) {
  //     this.loadInboundDocumentAPICall$?.unsubscribe();
  //   }
  //   const loadInboundDocument = this.inboundDocumentService
  //     .getTIFFImageBase64ByGId(gId)
  //     .subscribe(
  //       (response) => {
  //         this.totalPages = response.length;
  //         if (response && this.totalPages > 0) {
  //           this.existingDocumentImageList = response.map((a) => {
  //             return {
  //               ...a,
  //               src: this.sanitizer.bypassSecurityTrustResourceUrl(
  //                 `data:image/tiff;base64, ${a.file}`
  //               ),
  //               pageNo: pageNo++,
  //             };
  //           });
  //           this.selectedExistingDocument = this.existingDocumentImageList?.[0];
  //           this.loadTiffImage(this.existingDocumentImageList?.[0].file);
  //         }
  //         this.isLoading = false;
  //         this.TiffError= false;
  //       },
  //       (err) => {
  //         const data: HttpErrorResponse = err;
  //         this.toastr.error(data?.error?.error?.message , 'Error');
  //         this.isLoading = false;
  //         this.TiffError= true;
  //         this.loadingMessage = data?.error?.error?.message || 'The image has not been converted.' ;
  //       }
  //     );
  //   this.loadInboundDocumentAPICall$ = loadInboundDocument;
  // }

  isEmpty(value: any): boolean {
    return (
      value === null ||
      value === undefined ||
      value === '' ||
      value === defaultGuid
    );
  }

  loadExistingImage(docDetails: any) {
    this.selectedExistingDocument = docDetails;
    this.loadTiffImage(docDetails.file);
    this.pageNumber = docDetails.pageNo;
  }

  rotationAngle = 0;
  prevRotationAngle = 0;

rotateClockwise() {
  this.rotationAngle = (this.rotationAngle + 90) % 360;
  this.redrawAnnotationsZoom();
  // this.drawZoomedImage();
}

rotateCounterclockwise() {
  this.rotationAngle = (this.rotationAngle - 90 + 360) % 360;
  this.redrawAnnotationsZoom();
  // this.drawZoomedImage();
}


  // // Function to zoom in
  // zoomIn() {
  //   if (this.zoomLevel < this.MAX_ZOOM_LEVEL) {
  //     this.zoomLevel += 0.1;
  //     this.zoomLevelReSize = this.zoomLevel;
  //     // this.drawZoomedImage(); // Redraw image with new zoom level
  //     this.redrawAnnotationsZoom();
  //   }
  // }

  // // Function to zoom out
  // zoomOut() {
  //   if (this.zoomLevel > this.MIN_ZOOM_LEVEL) {
  //     this.zoomLevel -= 0.1;
  //     this.zoomLevelReSize = this.zoomLevel;
  //     // this.drawZoomedImage(); // Redraw image with new zoom level
  //     this.redrawAnnotationsZoom();
  //   }
  // }


   // Function to zoom in
   zoomIn() {
    if (this.zoomLevel < this.MAX_ZOOM_LEVEL) {
      // this.prevZoomLevel = this.zoomLevel;
      this.zoomLevel = Math.round((this.zoomLevel + 0.1) * 100) / 100; // Round to two decimal places
      this.zoomLevelReSize = this.zoomLevel;
      this.redrawAnnotationsZoom(); // Redraw image with new zoom level
    }
  }

  // Function to zoom out
  zoomOut() {
    if (this.zoomLevel > this.MIN_ZOOM_LEVEL) {
      // this.prevZoomLevel = this.zoomLevel;
      this.zoomLevel = Math.round((this.zoomLevel - 0.1) * 100) / 100; // Round to two decimal places
      this.zoomLevelReSize = this.zoomLevel;
      this.redrawAnnotationsZoom(); // Redraw image with new zoom level
    }
  }


  previousImage() {
    if (
      this.selectedExistingDocument &&
      this.selectedExistingDocument.pageNo > 1
    ) {
      let previousImageNo = this.selectedExistingDocument.pageNo - 2;
      this.loadExistingImage(this.existingDocumentImageList?.[previousImageNo]);
    }
  }

  nextImage() {
    if (
      this.selectedExistingDocument &&
      this.selectedExistingDocument.pageNo <
        this.existingDocumentImageList.length
    ) {
      let nextImageNo = this.selectedExistingDocument.pageNo;
      this.loadExistingImage(this.existingDocumentImageList?.[nextImageNo]);
    }
  }

  firstImage() {
    this.loadExistingImage(this.existingDocumentImageList?.[0]);
  }

  lastImage() {
    const pageIndex = this.totalPages - 1;
    this.loadExistingImage(this.existingDocumentImageList?.[pageIndex]);
  }

  // Function to navigate to a specific page
  goToPage() {
    if (this.pageNumber <= 0) {
      this.pageNumber = 1;
      this.firstImage();
    } else if (this.pageNumber >= 1 && this.pageNumber <= this.totalPages) {
      const pageIndex = this.pageNumber - 1;
      this.loadExistingImage(this.existingDocumentImageList[pageIndex]);
    } else {
      this.pageNumber = this.totalPages;
      this.lastImage();
    }
  }


redrawAnnotationsZoom() { //withRotate
  if (!this.isLoading) {
    const canvas = this.canvasRef.nativeElement;
    this.ctx = canvas.getContext('2d');
    this.ctx.clearRect(0, 0, canvas.width, canvas.height);
    const img = new Image();
    img.onload = () => {
      // Calculate the new image dimensions based on the zoom level
      const imageWidth = Math.round(img.width * this.zoomLevel);
      const imageHeight = Math.round(img.height * this.zoomLevel);

      // Calculate the canvas size to fit the rotated image
      const angle = this.rotationAngle * Math.PI / 180;
      const sin = Math.sin(angle);
      const cos = Math.cos(angle);
      const rotatedWidth = Math.abs(imageWidth * cos) + Math.abs(imageHeight * sin);
      const rotatedHeight = Math.abs(imageHeight * cos) + Math.abs(imageWidth * sin);

      canvas.width = rotatedWidth;
      canvas.height = rotatedHeight;
      this.ctx.save();

      // Rotate the canvas context
      this.ctx.translate(canvas.width / 2, canvas.height / 2);
      this.ctx.rotate(angle);
      this.ctx.translate(-imageWidth / 2, -imageHeight / 2);
      this.ctx.drawImage(img, 0, 0, imageWidth, imageHeight);
      this.ctx.restore();


      // Save the current zoom level and rotation angle for the next redraw
      // this.prevZoomLevel = this.zoomLevel;
      this.prevRotationAngle = this.rotationAngle;
    };
    img.src = 'data:image/tiff;base64,' + this.selectedExistingDocument.file;
  }
}

  // Function to draw the image on the canvas with the current zoom level
  drawZoomedImage() {
    const docDetails = this.selectedExistingDocument;
    const tiffBase64 = docDetails.file;
    const canvas = this.canvasRef.nativeElement;
    this.ctx = canvas.getContext('2d');
    const img = new Image();
    img.onload = () => {
      // Calculate the position to draw the image so that it remains centered
      const imageWidth = img.width * this.zoomLevel;
      const imageHeight = img.height * this.zoomLevel;
      // Clear canvas before drawing
      this.ctx.clearRect(0, 0, canvas.width, canvas.height);

      // Adjust canvas size based on zoom level
      canvas.width = imageWidth;
      canvas.height = imageHeight;
      // Draw image with adjusted dimensions and centered position
      this.ctx.drawImage(img, 0, 0, imageWidth, imageHeight);
    };
    img.src = 'data:image/tiff;base64,' + tiffBase64;
  }

  loadTiffImage(tiffBase64: string) {
    const canvas = this.canvasRef.nativeElement;
    this.ctx = canvas.getContext('2d');
    const img = new Image();
    img.onload = () => {
      // canvas.width = img.width;
      // canvas.height = img.height;
      // Calculate the position to draw the image so that it remains centered
      const imageWidth = img.width * this.zoomLevel;
      const imageHeight = img.height * this.zoomLevel;
      // Clear canvas before drawing
      this.ctx.clearRect(0, 0, canvas.width, canvas.height);

      // Adjust canvas size based on zoom level
      canvas.width = imageWidth;
      canvas.height = imageHeight;
      this.ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
      // Scroll to the selected image
      const selectedImageElement = document.querySelector(
        '.custom-thumbnail-view-selected'
      );
      if (selectedImageElement) {
        selectedImageElement.scrollIntoView({
          behavior: 'auto',
          block: 'center',
        });
      }
    };
    img.src = 'data:image/tiff;base64,' + tiffBase64;
    // this.zoomLevel = this.DEFAULT_ZOOM_LEVEL;
  }
  //#region Patient Document PDF Download
  patientDocPdfDownload() {
    this.isDownloading=true;
    // this.inboundDocumentService.downloadInboundDocumentByBlobName(this.blobName)
    this.inboundDocumentService.getPatientDocumentByPathByDocumentId(this.docId)
    .subscribe(
      (response) => {
          if (
            typeof response === 'string' &&
            response &&
            response !== null
            //  &&
            // response.length !== 0
          ) {
            this.strPdfString = String(response);
            // Decode base64 string to binary data
            const byteCharacters = atob(this.strPdfString);
            const byteNumbers = new Array(byteCharacters.length);
            for (let i = 0; i < byteCharacters.length; i++) {
                byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);

            const blob = new Blob([byteArray], { type: "application/pdf" });

              let fileName;
              if (!this.chartID && !this.patientName) {
                fileName = this.defaultFaxId;
              } else {
                fileName = this.chartID + '-' + this.patientName;
              }
            // const fileName = this.chartID + '-' + this.patientName;
            // Create a link element
            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = fileName;

            // Append the link to the body
            document.body.appendChild(link);

            // Programmatically click the link to trigger the download
            link.click();

            // Remove the link from the DOM
            document.body.removeChild(link);
          }
          this.isDownloading=false;
        },
        (err: HttpErrorResponse) => {
          this.isDownloading=false;
          console.error('Error downloading the document', err);
        }
      );
    }
  // #endregion Patient Document PDF Download
  viewPatientBlobPDF(){
    const url = '/#/patient_preview_pdf_document/' + this.docId + '/' + this.blobName;
    window.open(url, '_blank');
  }
}
