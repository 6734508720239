<div class="col-12" style="margin-top: -17px; margin-bottom: -30px">
    <div class="d-flex align-items-center justify-content-between m-0">
        <div class=" page-title-box  page-title-right mt-4 mb-0">
            <ol class="breadcrumbs m-0">
            </ol>
        </div>
    </div>
</div>
<br />

<div class="card card-body">
    <mat-accordion>
        <mat-expansion-panel [expanded]="step">
            <mat-expansion-panel-header>
                <mat-panel-title class="customThemeClass">
                    <ng-container>
                        <b>Ontime Branch</b>
                    </ng-container>

                </mat-panel-title>
            </mat-expansion-panel-header>
            <form [formGroup]="BranchForm">
                <div class="row">
                    <div class="col-lg-12">
                        <mat-card-content>
                            <div class="row">
                                <mat-form-field class="col-3">
                                    <mat-label> Branch Name <span class="asterisk">*</span></mat-label>
                                    <input autocomplete="new-txtBranchName" matInput maxlength="40" formControlName="txtBranchName" type="text" />
                                    <mat-error *ngIf="BranchForm?.get('txtBranchName')?.touched &&
                                    BranchForm?.get('txtBranchName')?.errors?.required ">
                                        Branch Name is a required field!
                                    </mat-error>
                                </mat-form-field>

                                <mat-form-field class="col-3">
                                    <mat-label>Phone Number <span class="asterisk">*</span></mat-label>
                                    <input autocomplete="new-txtPhoneNumber" matInput prefix="+1-" mask="(000)-000-0000" maxlength="40"
                                        placeholder="Enter Phone Number" formControlName="txtPhoneNumber" type="text">
                                    <mat-error *ngIf="
                                        BranchForm?.get('txtPhoneNumber')?.touched &&
                                        BranchForm?.get('txtPhoneNumber')?.errors
                                          ">
                                        Enter Minimum 10 Digits!
                                    </mat-error>
                                    <mat-error *ngIf="BranchForm?.get('txtPhoneNumber')?.touched &&
                                    BranchForm?.get('txtPhoneNumber')?.errors?.required ">
                                        Phone Number is a required field!
                                    </mat-error>
                                </mat-form-field>

                                <mat-form-field class="col-3">
                                    <mat-label>Address line -1 <span class="asterisk">*</span> </mat-label>
                                    <input autocomplete="new-txtAddress" maxlength="400" formControlName="txtAddress" rows="3" matInput>
                                    <!-- <mat-error *ngIf="
                                    BranchForm?.get('txtAddress')?.touched &&
                                    BranchForm?.get('txtAddress')?.errors
                                      ">
                                        Enter Minimum 10 Digits! -->
                                    <!-- </mat-error> -->
                                    <mat-error *ngIf="BranchForm?.get('txtAddress')?.touched &&
                                    BranchForm?.get('txtAddress')?.errors?.required ">
                                        Address line -1 is a required field!
                                    </mat-error>
                                </mat-form-field>
                                <mat-form-field class="col-3">
                                    <mat-label>Address line -2</mat-label>
                                    <input autocomplete="new-txtAddress2" maxlength="400" formControlName="txtAddress2" rows="3" matInput />
                                </mat-form-field>

                                <mat-form-field class="col-3">
                                    <mat-label>Country <span class="asterisk">*</span></mat-label>
                                    <mat-select formControlName="drpCountry"
                                        (selectionChange)='onChangeState($event.value)' [(value)]="Country">
                                        <!-- <mat-option>
                                            <ngx-mat-select-search placeholderLabel="Search"
                                                noEntriesFoundLabel="No Matches found"
                                                formControlName="txtCountryFilter"></ngx-mat-select-search>
                                        </mat-option> -->
                                        <mat-option *ngFor="let country of drpCountry"
                                            [value]="country.countryShortName">
                                            {{ country.countryName }}
                                        </mat-option>
                                    </mat-select>
                                    <button mat-button (click)="Country='';$event.stopPropagation()" *ngIf="Country"
                                        (click)="clearCountry()" matSuffix mat-icon-button >
                                        <mat-icon>close</mat-icon>
                                    </button>
                                    <mat-error *ngIf="BranchForm?.get('drpCountry')?.touched &&
                                    BranchForm?.get('drpCountry')?.errors?.required ">
                                        Address line -1 is a required field!
                                    </mat-error>
                                </mat-form-field>



                                <mat-form-field class="col-3">
                                    <mat-label>State <span class="asterisk">*</span></mat-label>
                                    <mat-select formControlName="drpState"
                                        (selectionChange)='onChangeCity($event.value,"")' [(value)]="State"
                                        #singleSelect>
                                        <mat-option>
                                            <ngx-mat-select-search placeholderLabel="Search"
                                                noEntriesFoundLabel="No Matches found" formControlName="drpStateFilter">
                                            </ngx-mat-select-search>
                                        </mat-option>
                                        <mat-option *ngFor="let state of filteredStates | async"
                                            [value]="state.stateShortName">
                                            {{ state.stateName }}
                                        </mat-option>
                                    </mat-select>
                                    <button mat-button (click)="State='';$event.stopPropagation()" *ngIf="State"
                                        (click)="clearState()" matSuffix mat-icon-button >
                                        <mat-icon>close</mat-icon>
                                    </button>
                                    <mat-error *ngIf="BranchForm?.get('drpState')?.touched &&
                                    BranchForm?.get('drpState')?.errors?.required ">
                                        Address line -1 is a required field!
                                    </mat-error>
                                </mat-form-field>



                                <mat-form-field class="col-3">
                                    <mat-label>City <span class="asterisk">*</span></mat-label>
                                    <mat-select formControlName="drpCity" [(value)]="City" #singleSelect>
                                        <mat-option>
                                            <ngx-mat-select-search placeholderLabel="Search"
                                                noEntriesFoundLabel="No Matches found" formControlName="drpCityFilter">
                                            </ngx-mat-select-search>
                                        </mat-option>
                                        <mat-option *ngFor="let city of filteredCities | async" [value]="city.cityName">
                                            {{ city.cityName }}
                                        </mat-option>
                                    </mat-select>
                                    <button mat-button (click)="City='';$event.stopPropagation()" *ngIf="City" matSuffix
                                        (click)="clearCity()" mat-icon-button >
                                        <mat-icon>close</mat-icon>
                                    </button>
                                    <mat-error *ngIf="BranchForm?.get('drpCity')?.touched &&
                                    BranchForm?.get('drpCity')?.errors?.required ">
                                        City is a required field!
                                    </mat-error>
                                </mat-form-field>

                                <mat-form-field class="col-3">
                                    <mat-label> Zip Code <span class="asterisk">*</span></mat-label>
                                    <input autocomplete="new-txtPostalCode" matInput maxlength="10" formControlName="txtPostalCode" pattern="[0-9]*"
                                        type="text" />
                                    <mat-error
                                        *ngIf=" BranchForm?.get('txtPostalCode')?.touched && BranchForm?.get('txtPostalCode')?.errors?.pattern">
                                        Enter only Numbers!
                                    </mat-error>
                                    <mat-error *ngIf="BranchForm?.get('txtPostalCode')?.touched &&
                                    BranchForm?.get('txtPostalCode')?.errors?.required ">
                                        ZipCode is a required field!
                                    </mat-error>
                                </mat-form-field>
                                <mat-form-field class="col-3">
                                    <mat-label>Branch Type <span class="asterisk">*</span></mat-label>
                                    <mat-select maxlength="40" formControlName="drpOffice">
                                        <mat-option *ngFor="let work of drpOffice" [value]="work.officeType">
                                            {{ work.officeType }}
                                        </mat-option>
                                        <mat-error *ngIf="BranchForm?.get('drpOffice')?.touched &&
                                        BranchForm?.get('drpOffice')?.errors?.required ">
                                            Office type is required field
                                        </mat-error>
                                    </mat-select>
                                    <mat-error *ngIf="BranchForm?.get('drpOffice')?.touched &&
                                    BranchForm?.get('drpOffice')?.errors?.required ">
                                        Branch Type is a required field!
                                    </mat-error>
                                </mat-form-field>
                                <!-- <div *ngIf="status" class="col-2"> <b>OnTime Branch Status</b></div>
                                <div *ngIf="status" class="col-5" style="display: flex;">
                                    <mat-slide-toggle (change)="activeChange($event)" class="ml-2"
                                        formControlName="statusToggle">
                                        {{activeStatus}}</mat-slide-toggle>
                                </div>
                                <div class="col-2"></div> -->
                            </div>
                        </mat-card-content>
                    </div>
                </div>
            </form>
            <div class="row">
                <div class="col-sm-12">
                    <div class="text-lg-center">
                        <button mat-button class="buttonColor mr-2" *ngIf="addWorkingButton"
                            (click)="openAddWorkingModel()">
                            Add Branch hours</button>
                        <button mat-button class="buttonColor mb-2 mr-2" *ngIf="addWorkingButton"
                            [disabled]="!(!BranchForm.invalid && BranchForm.dirty )" (click)="saveOnTimeBranch()">
                            Save
                        </button>
                        <button (click)="confirmBranchLocation()" *ngIf="addWorkingButton" mat-button
                            class="buttonColor mb-2 mr-2" [disabled]="!(!BranchForm.invalid)">
                            Confirm location
                        </button>
                        <button mat-button class="resetclr mb-2 mr-2" *ngIf="addWorkingButton" (click)="reset()">
                            Reset
                        </button>
                    </div>
                </div>
            </div>
            <br />
        </mat-expansion-panel>
    </mat-accordion>

    <div class="row">
        <div class="col-12" *ngIf="isConfirmBranchLocation" style="margin-top: 10px;">
            <h2>Confirm {{branchName}} branch location</h2>
            <agm-map [latitude]="latitude" [longitude]="longitude" [zoom]="17" [disableDefaultUI]="false"
                [zoomControl]="true" (mapClick)="mapClicked($event)" [usePanning]='true'>

                <agm-marker [latitude]="latitude" (dragEnd)="markerDragEnd($event)" [longitude]="longitude">
                    <agm-info-window>
                        <strong>InfoWindow content</strong>
                    </agm-info-window>
                </agm-marker>
            </agm-map>
        </div>
        <div class="col-12 table-responsive">
            <table mat-table class="table ml-4 border" matSort [dataSource]="dataSource">
                <form style="display: flex" [formGroup]="ontimeBranchForm">
                    <ng-container matColumnDef="sno">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>S.No</th>
                        <td mat-cell *matCellDef="let data; let i = index">
                            {{ i+1 }}
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="BranchName">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                            <mat-form-field class="filter">
                                <input autocomplete="new-branchName" matInput formControlName="branchName" />
                                <mat-placeholder>Branch Name</mat-placeholder>
                            </mat-form-field>
                        </th>
                        <td mat-cell *matCellDef="let data">
                            <ng-container>
                                {{data.branchName}}
                            </ng-container>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="email">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                            <mat-form-field class="filter">
                                <input autocomplete="new-email" matInput formControlName="email" />
                                <mat-placeholder>Email</mat-placeholder>
                            </mat-form-field>
                        </th>
                        <td mat-cell *matCellDef="let data">
                            <ng-container>
                                {{data.email}}
                            </ng-container>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="type">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                            <mat-form-field class="filter">
                                <input autocomplete="new-type" matInput formControlName="type" />
                                <mat-placeholder>Type</mat-placeholder>
                            </mat-form-field>
                        </th>
                        <td mat-cell *matCellDef="let data">
                            <ng-container>
                                {{data.officeType}}
                            </ng-container>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="Address">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                            <mat-form-field class="filter">
                                <input autocomplete="new-address" matInput formControlName="address" />
                                <mat-placeholder>Address</mat-placeholder>
                            </mat-form-field>
                        </th>
                        <td mat-cell *matCellDef="let data">
                            <ng-container>
                                {{data.address}}
                            </ng-container>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="Phone Number">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                            <mat-form-field class="filter">
                                <input autocomplete="new-phoneNumber" matInput formControlName="phoneNumber" />
                                <mat-placeholder>Phone Number </mat-placeholder>
                            </mat-form-field>
                        </th>
                        <td mat-cell *matCellDef="let data">
                            <ng-container>
                                {{data.contact}}
                            </ng-container>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="Created Date">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                            <mat-form-field class="filter">
                                <input autocomplete="new-createdDate" matInput formControlName="createdDate" placeholder="(MM/DD/YYYY)" />
                                <mat-error
                                    *ngIf=" ontimeBranchForm?.get('createdDate')?.touched && ontimeBranchForm?.get('createdDate')?.errors?.dateVaidator">
                                    Enter Valid date
                                </mat-error>
                            </mat-form-field>
                        </th>
                        <td mat-cell *matCellDef="let data">
                            <ng-container>
                                {{data.createdDate}}
                            </ng-container>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="Status">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                            <!-- <mat-form-field class="filter">
                                <input matInput formControlName="status" />
                                <mat-placeholder>Status</mat-placeholder>
                            </mat-form-field> -->
                            <mat-label class="text-center">Status </mat-label>
                        </th>
                        <td mat-cell *matCellDef="let data">
                            <ng-container *ngIf="data?.status==='Active'">
                                <mat-slide-toggle (change)="statusChange($event, data)" [checked]='true' class="ml-2">
                                    {{data?.status}}</mat-slide-toggle>
                            </ng-container>
                            <ng-container *ngIf="data?.status==='Inactive'">
                                <mat-slide-toggle (change)="statusChange($event, data)" [checked]='false' class="ml-2">
                                    {{data?.status}}</mat-slide-toggle>
                            </ng-container>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="Options">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Options</th>
                        <td mat-cell *matCellDef="let data">
                            <a class="eyeCursorclass" target="_blank"><i *ngIf="data?.status==='Active'"
                                    (click)="ViewClient(data)" class="fa fa-pencil editBtnColor mr-3"></i>
                            </a>
                            &nbsp;
                            <a class="deleteBtnColor" (click)="deleteBranch(data)">
                                <i *ngIf="data?.status==='Active'" class="fa fa-trash" ></i>
                            </a>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="noRecords">
                        <td class="text-align-center" mat-footer-cell *matFooterCellDef colspan="9">
                            <div *ngIf="!isLoading">
                                {{"No Records Found"}}
                            </div>
                            <div [hidden]="!isLoading">
                                <mat-spinner [diameter]="220"></mat-spinner>
                              </div>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="paginator">
                        <td mat-footer-cell *matFooterCellDef colspan="8">
                            <mat-paginator class="table table-responsive mat-table-class" #MatPaginator
                                [length]="dataSource?.data?.length" [pageSize]="10" [pageIndex]="0" showFirstLastButtons
                                [pageSizeOptions]="[5, 10, 25, 100]">
                            </mat-paginator>
                        </td>
                    </ng-container>
                </form>
                <tr mat-footer-row *matFooterRowDef="['noRecords']"
                    [ngClass]="{ active: dataSource && dataSource?.data && dataSource?.data?.length !== 0 }"></tr>
                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
                <tr mat-footer-row *matFooterRowDef="['paginator']"></tr>
            </table>
        </div>

    </div>



</div>
