import { Component, ElementRef, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Observable, Subject, Subscription } from 'rxjs';
import { InboundDocumentService } from '../../admin-proxy/platform-app-management/rcm/platform-management/inbound/inbound-document.service';
import { ToastrService } from 'ngx-toastr';
import { MatSnackBar } from '@angular/material/snack-bar';
import { HttpErrorResponse } from '@angular/common/http';
import { map, startWith, takeUntil } from 'rxjs/operators';
import { PagedAndSortedResultRequestDto } from '@abp/ng.core';
import Swal from 'sweetalert2';
import { MatSelectChange } from '@angular/material/select';

@Component({
  selector: 'app-fax-finder-lazy-loading-v1',
  templateUrl: './fax-finder-lazy-loading-v1.component.html',
  styleUrls: ['./fax-finder-lazy-loading-v1.component.scss'],
})
export class FaxFinderLazyLoadingV1Component implements OnInit {
  dtOptions: DataTables.Settings = {};
  dataTable: any;
  faxFinderDataList: any[] = [];
  start: number = 1;
  end: number = 10;
  isLoading: boolean = false;
  private faxFinderAPICall: Subscription;
  scrollPosition: number;
  IsFaxAvailable: boolean = true;
  fgFaxFinder: FormGroup;
  BirthdateMaxDate: Date;
  BirthdateMinDate: Date;
  FaxIdInput: any;
  ChartNoInput: any;
  PatientNameInput: any;
  txtBirthdateInput: any;
  onWheel = false;
  totalFaxFinderDataList: number = 0;
  birthdate: any;
  private unsubscribe$: Subject<void> = new Subject<void>();
  isReset:boolean=false;
  wipStatusDrpData$: Subscription;
  // public drpWipStatus: WipStatus[] = [];
  // public wipTableData: Observable<WipStatus[]> | undefined;
  wipTableData:any;
  drpWipStatus:any;
  inboundStatus:string;
  FaxStatusTypeControl = new FormControl();
  selectedWipStatusList:string[] = [];
  WipStatusSelectAllOption = false;
  constructor(
    private inboundDocService: InboundDocumentService,
    private toastr: ToastrService,
    // private elementRef: ElementRef,
    private fb: FormBuilder,
  ) // private snackBar: MatSnackBar
  {}

  ngOnInit(): void {
    this.initializeForms();
    this.loadValidDates();
    this.loadDefault();
    this.getWipStatusList();

  //Priority Type
  this.FaxStatusTypeControl.valueChanges.subscribe((filtertxt) => {
    filtertxt = filtertxt.toLowerCase();
    this.wipTableData = this.drpWipStatus.filter((dx) =>
      dx.inboundStatus.toLowerCase().includes(filtertxt)
    );
  });
  }
  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
    if (this.faxFinderAPICall) {
      this.faxFinderAPICall.unsubscribe();
    }
  }

  //To set the Valid Dates for Birth Date field
  loadValidDates() {
    this.BirthdateMaxDate = new Date();
    this.BirthdateMaxDate.setDate(this.BirthdateMaxDate.getDate());
    this.BirthdateMinDate = new Date();
    this.BirthdateMinDate.setFullYear(
      this.BirthdateMinDate.getFullYear() - 120
    );
    var oneYearAgo = new Date();
    oneYearAgo.setFullYear(this.BirthdateMaxDate.getFullYear() - 1);
    // this.RxdateMin = oneYearAgo;
  }

  onKeyPressSearch() {
    if (this.fgFaxFinder.valid && !this.fgFaxFinder.errors && this.areFieldsNotEmpty()) {
    // if (this.fgFaxFinder.valid) {
      this.start = 1;
      this.faxFinderDataList=[];
      if(this.isReset === false){
          this.loadDefault();
        }
  }
  }

  resetFaxFinder() {
    this.isReset = true;
    this.fgFaxFinder.reset();
    this.start = 1;
    this.faxFinderDataList=[];
    setTimeout(() => {
      this.isReset = false;
      this.loadDefault();
    }, 1000);
  }

  areFieldsNotEmpty(): boolean {
    const { FaxId, ChartNo, PatientName, txtBirthdate , drpWipStatus} = this.fgFaxFinder.value;
    return FaxId || ChartNo || PatientName || txtBirthdate || drpWipStatus;
  }

  initializeForms() {
    this.fgFaxFinder = this.fb.group({
      FaxId: new FormControl(''),
      ChartNo: new FormControl(''),
      PatientName: new FormControl(''),
      txtBirthdate: new FormControl(''),
      txtWipStatus: new FormControl(''),
      drpWipStatus: new FormControl(''),
    });
  }

  loadDefaultV0() {
    this.isLoading = true;
    if (this.faxFinderAPICall) {
      this.faxFinderAPICall.unsubscribe();
    }
    this.faxFinderAPICall = this.inboundDocService
      .getFaxDocumentDetailsNewByISkipAndITakeAndDobAndFaxIdAndChartIdAndPatientName(
        this.faxFinderDataList.length??0,
        this.end,
        this.getFormattedDate(this.fgFaxFinder.value.txtBirthdate),
        this.fgFaxFinder.value.FaxId,
        this.fgFaxFinder.value.ChartNo,
        this.fgFaxFinder.value.PatientName
      )
      .subscribe(
        (response) => {
          this.faxFinderDataList = response.ltFaxes;
          this.totalFaxFinderDataList = response.iTotalFax;
          this.isLoading = false;
        },
        (error) => {
          const data: HttpErrorResponse = error;
          this.toastr.error(data?.error?.error?.message, 'Error');
          this.isLoading = false;
        }
      );
  }

  loadDefault() {
    // Code By Manikandan - If Any Doubt call me. Reason - API unwanted multiple times calling the same parameter.
    if(!this.isLoading){
      this.isLoading = true;
      if (this.faxFinderAPICall) {
        this.faxFinderAPICall.unsubscribe();
      }
      this.faxFinderAPICall = this.inboundDocService
        .getFaxDocumentDetailsNewByISkipAndITakeAndDobAndFaxIdAndChartIdAndPatientNameAndFaxStatus(
          this.faxFinderDataList.length??0,
          this.end,
          this.getFormattedDate(this.fgFaxFinder.value.txtBirthdate),
          this.fgFaxFinder.value.FaxId,
          this.fgFaxFinder.value.ChartNo,
          this.fgFaxFinder.value.PatientName,
          this.fgFaxFinder.value.drpWipStatus
        )
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(
          (response) => {
            this.faxFinderDataList = response.ltFaxes;
            this.totalFaxFinderDataList = response.iTotalFax;
            this.isLoading = false;
          },
          (error) => {
            const data: HttpErrorResponse = error;
            this.toastr.error(data?.error?.error?.message, 'Error');
            this.isLoading = false;
          }
        );
    }

  }

    getWipStatusList() {
      this.isLoading = true;
      this.wipStatusDrpData$?.unsubscribe();
      const getWIPTableData = this.inboundDocService
        .getStatusFilterList(1)
        .subscribe(
          (response) => {
            this.wipTableData = response;
            this.wipTableData.sort((a, b) => a.inboundStatus.localeCompare(b.inboundStatus));
            this.drpWipStatus = this.wipTableData;
            this.isLoading = false;
          },
          (err) => {
            this.isLoading = false;
            this.wipTableData = [];
            const data: HttpErrorResponse = err;
            Swal.fire({
              icon: 'info',
              text: data?.error?.error?.message,
            });
          }
        );
      this.wipStatusDrpData$ = getWIPTableData;
  
    }

  //Multiple Select WIP Status Data
  onWipStatushSelectionChange(event: MatSelectChange): void {
    this.selectedWipStatusList = event.value;
    if (this.selectedWipStatusList.includes('selectAll')) {
      this.WipStatusSelectAllOption = true;
      if (this.WipStatusSelectAllOption) {
        // If 'Select All' is selected, set the selectedBranchList to include all branch IDs
        this.selectedWipStatusList = this.drpWipStatus.map(branch => branch?.id);
        this.fgFaxFinder
          .get('drpWipStatus')
          .patchValue(this.selectedWipStatusList);

      }
    } else if (this.selectedWipStatusList.includes('DeselectAll')) {
      this.selectedWipStatusList = [];
      this.WipStatusSelectAllOption = false;
      this.fgFaxFinder
        .get('drpWipStatus')
        .patchValue([]);
    }
    else {
      this.WipStatusSelectAllOption =
        this.selectedWipStatusList.length === this.drpWipStatus.length;
    }
    // if(this.isReset === false){
    //   this.loadDefault();
    // }
  }

  getFormattedDate(date: string): string {
    if (!date) return null;

    const formattedDate = new Date(date);
    const year = formattedDate.getFullYear();
    const month = (formattedDate.getMonth() + 1).toString().padStart(2, '0');
    const day = formattedDate.getDate().toString().padStart(2, '0');

    return `${year}-${month}-${day}`;
  }
}
export interface WipStatus {
  statusid?: string;
  inboundStatus?: string;
}