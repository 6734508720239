import type { CreateUpdateProductDetailsDTO, CreateUpdateProductStockDTO, ProductDetailsDTO, ProductStockDTO, SearchProductStocksDTO, UploadProductSerialNumbersDTO } from './dto/models';
import { RestService } from '@abp/ng.core';
import type { PagedAndSortedResultRequestDto, PagedResultDto } from '@abp/ng.core';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ProductStockService {
  apiName = 'ItemManagement';

  create = (input: CreateUpdateProductStockDTO) =>
    this.restService.request<any, ProductStockDTO>({
      method: 'POST',
      url: '/api/ItemApp/product-stock',
      body: input,
    },
    { apiName: this.apiName });

  delete = (id: string) =>
    this.restService.request<any, void>({
      method: 'DELETE',
      url: `/api/ItemApp/product-stock/${id}`,
    },
    { apiName: this.apiName });

  get = (id: string) =>
    this.restService.request<any, ProductStockDTO>({
      method: 'GET',
      url: `/api/ItemApp/product-stock/${id}`,
    },
    { apiName: this.apiName });

  getList = (input: PagedAndSortedResultRequestDto) =>
    this.restService.request<any, PagedResultDto<ProductStockDTO>>({
      method: 'GET',
      url: '/api/ItemApp/product-stock',
      params: { skipCount: input.skipCount, maxResultCount: input.maxResultCount, sorting: input.sorting },
    },
    { apiName: this.apiName });

  update = (id: string, input: CreateUpdateProductStockDTO) =>
    this.restService.request<any, ProductStockDTO>({
      method: 'PUT',
      url: `/api/ItemApp/product-stock/${id}`,
      body: input,
    },
    { apiName: this.apiName });

  downloadProductTemplate = () =>
    this.restService.request<any, string>({
      method: 'GET',
     // responseType: 'text',
      url: '/api/ItemApp/product-stock/download-product-template',
    },
    { apiName: this.apiName });

  getProductDetails = (id: string, lotId: string, productDetailsId: string) =>
    this.restService.request<any, ProductDetailsDTO>({
      method: 'GET',
      url: `/api/ItemApp/product-stock/${id}/get-product-details`,
      params: { lotId, productDetailsId },
    },
    { apiName: this.apiName });

  getProductDetailsList = (id: string, lotId: string) =>
    this.restService.request<any, ProductStockDTO>({
      method: 'GET',
      url: `/api/ItemApp/product-stock/${id}/get-product-details-list/${lotId}`,
    },
    { apiName: this.apiName });

  importProductSerialNumbers = (input: UploadProductSerialNumbersDTO) =>
    this.restService.request<any, ProductStockDTO>({
      method: 'POST',
      url: '/api/ItemApp/product-stock/import-product-serial-numbers',
      body: input,
    },
    { apiName: this.apiName });

  searchProductStocksByInput = (input: SearchProductStocksDTO) =>
    this.restService.request<any, PagedResultDto<ProductStockDTO>>({
      method: 'GET',
      url: '/api/ItemApp/product-stock/search-product-stocks',
      params: { productNames: input.productNames, brandNames: input.brandNames, categoryNames: input.categoryNames, status: input.status, createdFromDate: input.createdFromDate, createdToDate: input.createdToDate },
    },
    { apiName: this.apiName });

  updateOrderPriceByProductIdAndPriceAndSBrandNameAndSCategoryName = (productId: string, price: number, sBrandName: string, sCategoryName: string) =>
    this.restService.request<any, void>({
      method: 'PUT',
      url: `/api/ItemApp/product-stock/update-order-price/${productId}`,
      params: { price, sBrandName, sCategoryName },
    },
    { apiName: this.apiName });

  updateProductDetails = (id: string, lotId: string, input: CreateUpdateProductDetailsDTO) =>
    this.restService.request<any, ProductStockDTO>({
      method: 'PUT',
      url: `/api/ItemApp/product-stock/${id}/update-product-details/${lotId}`,
      body: input,
    },
    { apiName: this.apiName });

  updateStockPriceByIdAndPrice = (id: string, price: number) =>
    this.restService.request<any, ProductStockDTO>({
      method: 'PUT',
      url: `/api/ItemApp/product-stock/${id}/update-stock-price`,
      params: { price },
    },
    { apiName: this.apiName });

  constructor(private restService: RestService) {}
}
