

  <div class="card card-body">
    <div class="row" >
      <div class="col-2">
        <ng-container>
          <b style="color:lightseagreen">User List</b>
        </ng-container>
      </div>
      <div class="col-10" style="display: flex; justify-content: flex-end;">
         <button class="buttonColor mr-2" (click)="multiUserResetPassword()">Reset Password</button>
         <mat-radio-group  (change)="onRadioChange($event)" [(ngModel)]="userStatusId">
           <mat-radio-button class="active ml-2" [value]="0"><b>All</b></mat-radio-button>
           <mat-radio-button class="active ml-2" [value]="1"><b>Active</b></mat-radio-button>
           <mat-radio-button class="active ml-2" [value]="2"><b>Inactive</b></mat-radio-button>
         </mat-radio-group>
      </div>
    </div>
    <form [formGroup]="userFilterForm">
      <div class="row">

        <mat-form-field class="col-2">
          <mat-label>Role </mat-label>
          <mat-select maxlength="40" formControlName="txtRoleFilter" multiple
            (selectionChange)="onSelectionChangeRole($event)">
                <mat-option>
                 <ngx-mat-select-search placeholderLabel="Search"
                   noEntriesFoundLabel="No Matches found"
                   [formControl]="txtRoleFilter"></ngx-mat-select-search>
                 </mat-option>
                 <mat-option *ngIf="!RoleSelectAllOption" [value]="'selectAll'">Select All</mat-option>
                 <mat-option *ngIf="RoleSelectAllOption" [value]="'selectAll'">Deselect All</mat-option>
            <mat-option *ngFor="let roles of drpRoles" [value]="roles.id">
              {{ roles.roleDisplayName }}
            </mat-option>
          </mat-select>
          <button mat-button (click)="clearRole()" (click)="role='';$event.stopPropagation()" *ngIf="role" matSuffix
            mat-icon-button>
            <mat-icon>close</mat-icon>
          </button>
          <mat-error *ngIf=" userFilterForm?.get('txtRoleFilter')?.touched && userFilterForm?.get('txtRoleFilter')?.errors?.required">
            Role Name is a required field!
          </mat-error>
        </mat-form-field>


        <mat-form-field class="col-3">
          <mat-label> User Name </mat-label>
          <input autocomplete="new-password" matInput maxlength="40" formControlName="txtUserNameFilter" type="text"
            oninput="this.value = this.value.replace(/[^a-zA-Z\s]+/g, ''); this.value = this.value.charAt(0).toUpperCase()
             + this.value.slice(1);" />
          <mat-error *ngIf="userFilterForm?.get('txtUserNameFilter')?.touched &&
          userFilterForm?.get('txtUserNameFilter')?.errors?.required ">
            User Name is a required field!
          </mat-error>
          <mat-error *ngIf="userFilterForm?.get('txtUserNameFilter')?.errors?.isUserNameExists">
            User Name is already taken!
          </mat-error>
        </mat-form-field>
        <mat-form-field class="col-3">
          <mat-label> Branch</mat-label>
          <mat-select formControlName="sltMainBranchNameFilter" multiple (selectionChange)="onSelectionChange($event)">
            <mat-option>
            <ngx-mat-select-search placeholderLabel="Search"
                                  noEntriesFoundLabel="No Matches found"
                                  [formControl]="sltRemoteBranchNameFilter"></ngx-mat-select-search>
                          </mat-option>
            <mat-option *ngIf="!RemoteSelectAllOption" [value]="'selectAll'">Select All</mat-option>
            <mat-option *ngIf="RemoteSelectAllOption" [value]="'selectAll'">Deselect All</mat-option>
            <mat-option *ngFor="let organization of drpOrganization" [value]="organization.id"
              [disabled]="sltMainBranchNameValue === organization.id ">
              {{ organization.organizationUnitName }}
            </mat-option>
          </mat-select>
          <!-- <button mat-button (click)="RemoteBranch='';$event.stopPropagation()" *ngIf="RemoteBranch" matSuffix
            (click)="clearRemoteBranch()" mat-icon-button>
            <mat-icon>close</mat-icon>
          </button> -->
        </mat-form-field>


        <mat-form-field class="col-3">
          <mat-label> Email </mat-label>
          <input autocomplete="new-txtEmailFilter" matInput maxlength="40" formControlName="txtEmailFilter" type="text"
            pattern="[a-zA-Z0-9._!#$%&'()*+,-./:;<=>?@[\]^_`{|}~]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$"
            [textMask]="{ mask: emailMask }" />

          <mat-error *ngIf="userFilterForm?.get('txtEmailFilter')?.touched &&
          userFilterForm?.get('txtEmailFilter')?.errors?.required ">
            Email Address is a required field!
          </mat-error>
          <mat-error *ngIf="
          userFilterForm?.get('txtEmailFilter')?.touched &&
          userFilterForm?.get('txtEmailFilter')?.errors?.pattern
                                    ">
            Please Enter a Valid Email Address
          </mat-error>
          <mat-error *ngIf="userFilterForm?.get('txtEmailFilter')?.errors?.isEmailAddressExists">
            Email is already taken!
          </mat-error>
        </mat-form-field>

        <div class=" all-icon" matTooltip="Search"  style="padding-left: 15px;margin-top: 10px;">
          <i class="fa fa-search search align-items-center"
          [class.disabled]="userFilterForm?.invalid||otherSearchValidation"
           (click)="getTableData(userStatusId)"></i> </div>
          &nbsp; &nbsp;
          <div class=" all-icon" matTooltip="Reset" style="margin-top: 10px;">
          <i class="fa fa-refresh reset align-items-center" (click)="resetFilterForm()" ></i></div>
      </div>
    </form>
    <mat-divider class="mb-1"></mat-divider>
    <div class="row" *ngIf="isLoading">
            <div class="col-12">
              <div class="d-flex justify-content-center" *ngIf="isLoading">
                <img class="img-responsive pb-2 mr-1" src="assets/gifs/Infinity-loader.gif" />
              </div>
            </div>
    </div>
  <app-user-tableList [tableData]="userTableData" [clientId]="clientId"  [userStatusId]="userStatusId"*ngIf="!isLoading"></app-user-tableList>
</div>
