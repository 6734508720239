import { ListService } from '@abp/ng.core';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSelect } from '@angular/material/select';
import { Store } from '@ngxs/store';
import { DashboardService } from 'projects/admin/src/app/admin-proxy/platform-app-management/rcm/platform-management/dashboard/dashboard.service';
import { UserService } from 'projects/admin/src/app/admin-proxy/platform-app-management/rcm/platform-management/user/user.service';
import { MasterNotesDTO } from 'projects/order/src/app/order-proxy/order-management/inventory/dto/models';
import { MasterNotesService } from 'projects/order/src/app/order-proxy/order-management/inventory/master-notes.service';
import { TableService } from 'projects/shared/src/app/table.service';
import { TextValidators } from 'projects/shared/src/app/validations/text-validator';
import { Observable, Subscription } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import Swal from 'sweetalert2';

import { NotesSeverityDTO, NotesStatusDTO, NotesTypeDTO, PatientMasterDropdownDTO } from '../dropdown-proxy/dropdown-management/dropdowns/dto/patient/models';
import { PatientMasterDropdownService } from '../dropdown-proxy/dropdown-management/dropdowns/master-dropdown-service/patient-master-dropdown.service';
import { PatientDropdowns } from '../dropdown-proxy/dropdown-management/dropdowns/patient-dropdowns.enum';
import { NotesDTO, PatientDTO } from '../patient-proxy/patient/dto/models';
import { getPatient, updatePatient } from '../patient-store/patient.action';
import { ToastrService } from "ngx-toastr";




@Component({
  selector: 'app-add-notes',
  templateUrl: './add-notes.component.html',
  styleUrls: ['./add-notes.component.scss'],
  providers: [ListService]

})
export class AddNotesComponent implements OnInit, OnDestroy {
  BirthdateMaxDate: Date;
  isShowSpinner: boolean = false;
  savedAddNotesData: any;

  notesModalTitle: string = '';
  public noteForm: FormGroup;
  // patientID:string="33e2440b-9e54-a558-f359-39f9c9910f7f"
  @Input() patientID: string = '';
  drpState: MasterNotesDTO[] = [];
  severity: string;
  notesType: string;
  status: string;
  state: string;
  subject: string;
  description: string;
  LoginUserName: string = "";
  notesText ='';
  defaultNotesId: string;
  // @Output() saveAddNoteFormState: EventEmitter<any> = new EventEmitter<any>();
  // showswal: any;
  drpNoteType: NotesTypeDTO[] = [];
  isPatientLockedByCurrentUser: boolean = true;
  strLoginUserId: string = '';
  saveButtonHide: boolean;

  // public bankFilterCtrl: FormControl = new FormControl();
  /** control for the selected bank */
  // public bankCtrl: FormControl = new FormControl();

  /** control for the MatSelect filter keyword */
  // public bankFilterCtrl: FormControl = new FormControl();

  public filteredNoteTypes: Observable<NotesTypeDTO[]>;
  @ViewChild('singleSelect') singleSelect: MatSelect;

  subscription$: Subscription[] = [];

  constructor(
    private notesService: MasterNotesService,
    public list: ListService,
    private fb: FormBuilder, private textValidators: TextValidators, private Table: TableService,
    public userProfileService: UserService, public dashboardService: DashboardService, private patientDropdownService: PatientMasterDropdownService,
    private store: Store,
    private toastr: ToastrService,
    public notesDialogRef: MatDialogRef<AddNotesComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { patientID: string, notesId?: string }) { }

  ngOnInit(): void {
    const tableSubscription = this.Table.getLoginUserId().subscribe(val => {
      this.strLoginUserId = val;
    });
    this.subscription$.push(tableSubscription);
    //
    this.noteForm = this.fb.group({
      txtNoteSearchFilter: new FormControl(""),
      txtActualDate: new FormControl(""),
      txtAddress: new FormControl(""),
      txtgeneralCreated: new FormControl("", [this.textValidators.isTextCheck]),
      txtSeverity: new FormControl(null),
      txtNotetype: new FormControl(""),
      txtDateCreated: new FormControl(new Date()),
      txtPatientDateCreated: new FormControl(""),
      txtAssignedTo: new FormControl("", [this.textValidators.isTextCheck]),
      txtUser1: new FormControl("", [this.textValidators.isTextCheck]),
      txtUser2: new FormControl("", [this.textValidators.isTextCheck]),
      txtDateNeeded: new FormControl(""),
      txtSavedBy: new FormControl("", [this.textValidators.isTextCheck]),
      txtDateSaved: new FormControl(""),
      txtLockedBy: new FormControl("", [this.textValidators.isTextCheck]),
      dateLocked: new FormControl(""),
      chkAck: new FormControl(""),
      chkDeactive: new FormControl(""),
      txtStatus: new FormControl(null),
      txtState: new FormControl(""),
      txtNotereason: new FormControl(""),
      txtDateComplete: new FormControl(""),
      txtDescription: new FormControl(""),
      txtSubject: new FormControl(""),
      txtEnteredBy: new FormControl(""),
      txtEntryDate: new FormControl(""),
      txtFollowUpDate: new FormControl(""),
      txtModifiedBy: new FormControl(""),
      txtModifiedDate: new FormControl(""),
      isChangeAlert: new FormControl(""),
      isPaymentAlert: new FormControl(""),
    });
    this.getDropdown();
    this.patientID = this.data.patientID;
    if (this.data.notesId != null && this.data.notesId != undefined && this.data.notesId !== "" && this.data.notesId !== '00000000-0000-0000-0000-000000000000') {
      this.saveButtonHide = true;
      this.getNoteById(this.data.notesId);
    }
    else {
      this.data.notesId = '00000000-0000-0000-0000-000000000000';
      this.notesModalTitle = 'Add Notes';
    }
    if (this.noteForm.valid || this.noteForm.dirty) {
      this.noteForm.value;
    }
   if (this.patientID !== "" && this.patientID !== null && this.patientID !== undefined) {
    const onInitStoreSubscription = this.store.dispatch(new getPatient(this.patientID)).subscribe(response => {
      const selectedPatient: PatientDTO = response?.patientState?.selectedPatient;
      // Patient is locked & userId is not equal to loginUserId or patient is unlocked
      this.isPatientLockedByCurrentUser = (selectedPatient?.patientLock?.isLocked == true &&
        selectedPatient?.patientLock?.userId == this.strLoginUserId) ||
        (selectedPatient?.patientLock?.isLocked == false) ? true : false;
    });
    this.subscription$.push(onInitStoreSubscription);
   }

    this.BirthdateMaxDate = new Date();
    this.BirthdateMaxDate.setDate(this.BirthdateMaxDate.getDate() + 0);
    // load the initial bank list
    // this.filteredBanks.next(this.drpNoteType.slice());
    // listen for search field value changes
    // this.noteForm?.get("txtNoteSearchFilter").valueChanges
    //  .pipe(takeUntil(this._onDestroy))
    //  .subscribe(() => {
    //    this.filterBanks();
    //  });
    this.loadUserProfile();

  }

  ngOnDestroy(): void {
    this.subscription$?.forEach((sub) => {
      sub && sub?.unsubscribe();
    });
  }

  updateCharacterCount() {
    // Ensure the text length does not exceed the maximum length
    if (this.notesText?.length > 5000) {
      this.notesText = this.notesText?.substr(0, 5000);
    }
  }
  clearSeverity() {
    this.severity = '';
    this.noteForm.patchValue({ txtSeverity: '' });

  }
  clearNotesType() {
    this.notesType = '';
    this.noteForm.patchValue({ txtNotetype: '' });

  }
  clearStatus() {
    this.status = '';
    this.noteForm.patchValue({ txtStatus: '' });
  }
  clearState() {
    this.state = '';
    this.noteForm.patchValue({ txtState: '' });
  }
  clearSubject() {
    this.subject = '';
    this.noteForm.patchValue({ txtSubject: '' });
  }
  clearDescription() {
    this.description = '';
    this.noteForm.patchValue({ txtDescription: '' });
  }

  clearCreatedBy() {
    this.noteForm.patchValue({ txtgeneralCreated: this.LoginUserName });
  }


  statuses: NotesStatusDTO[] = [];
  drpseverity: NotesSeverityDTO[] = [];
  // txtState: StateDTO[] = [];

  // stateGroupOptions: Observable<NotesTypeDTO[]>;
  // private _filterGroup(value: string): NotesTypeDTO[] {
  //   if (value) {
  //     const filterValue = value.toLowerCase();
  //     return this.drpNoteType
  //       // .map(group => ({letter: group.letter, names: _filter(group.names, value)}))
  //       .filter(option => option?.notesType.toLowerCase().includes(filterValue));
  //   }
  //   return this.drpNoteType;
  // }

  protected filterNoteTypes(value: string) {
    if (value) {
      const filterValue = value.toLowerCase();
      return this.drpNoteType
        // .map(group => ({letter: group.letter, names: _filter(group.names, value)}))
        .filter(option => option?.notesType.toLowerCase().includes(filterValue));
    }
    return this.drpNoteType;
    // if (!this.drpNoteType) {
    //   return;
    // }
    // ! get the search keyword
    // let search = this.noteForm.value.txtNoteSearchFilter;
    // if (!search) {
    //   this.filteredBanks.next(this.drpNoteType.slice());
    //   return;
    // } else {
    //   search = search.toLowerCase();
    // }
    // ! filter the banks
    // this.filteredBanks.next(
    //   this.drpNoteType.filter(bank => bank.notesType.toLowerCase().includes(search))
    // );
  }

  getDropdown() {
    let patientDropdownInput: PatientDropdowns[];
    patientDropdownInput = [
      PatientDropdowns.NoteStatus,
      PatientDropdowns.NoteSeverities,
      PatientDropdowns.NoteTypes
    ]
    const patientDropdwonSubscription = this.patientDropdownService.getPatientDropdownsByInput(patientDropdownInput).subscribe((stateResponse) => {
      const response: PatientMasterDropdownDTO = stateResponse
      this.statuses = response.notesStatuses;
      this.drpseverity = response.notesSeverities;
      this.drpNoteType = response.lstNotesType;

      this.filteredNoteTypes = this.noteForm?.get("txtNoteSearchFilter").valueChanges
        .pipe(
          startWith(''),
          map(value => this.drpNoteType.filter(option => option?.notesType?.toLowerCase()?.includes(value?.toLowerCase() ?? "")))
        );

      // .pipe(takeUntil(this._onDestroy))
      // .subscribe(() => {
      //   this.filterBanks();
      // });

      //   this.stateGroupOptions = this.noteForm?.get('txtNotetype').valueChanges
      //   .pipe(
      //     startWith(''),
      //     map(value => this._filterGroup(value))
      //   );
      // });
    }, err => {
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
      this.subscription$.push(patientDropdwonSubscription);
    })

    const notesList = (query: any) => this.notesService.getList(query);
    const notesListSubscription = this.list.hookToQuery(notesList).subscribe(response => {
      response && response?.totalCount && response?.items && response?.items?.forEach(element => {
      })
      this.drpState = response.items;

    }, err => {
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    })
    this.subscription$.push(notesListSubscription);
  }
  tblNotesReponse: any[] = [];

  getTableData(patientID: string) {
    let notesDetails: PatientDTO;
    const getTableSubscription = this.store.dispatch(new getPatient(patientID)).subscribe(response => {
      notesDetails = response.patientState.selectedPatient;
      this.tblNotesReponse = [];
      notesDetails.notes.forEach(element => {
        if (element.id !== "00000000-0000-0000-0000-000000000000") {
          this.tblNotesReponse.push({
            notesId: element.id,
            createdBy: element.createdBy== ""? this.LoginUserName:this.LoginUserName,
            dateCreated: element.dateCreated == null ? "" : new Date(element.dateCreated)?.toLocaleDateString("en-US"),
            actualDate: element.actualDate == null ? "" : new Date(element.actualDate)?.toLocaleDateString("en-US"),
            noteId: element.notesId,
            noteType: this.drpNoteType?.filter(value => { return value && value?.shortCodeId === element?.noteType })[0].notesType,

            modifiedDate: element.modifiedDate,
            patientCreatedBy: element.createdBy,
            patientDateCreated: element.dateCreated,
            commentText: element.description,
            creatorId: element.description,
            assignedTo: element.assignedTo,
            status: element.status,
            noteReason: element.noteReason,
            severity: element.severity,
            isDeactivateNote: element.isDeactivateNote,
            savedBy: element.savedBy,
            dateSaved: element.dateSaved,
            lockedBy: element.lockedBy,
            dateLocked: element.dateLocked,
            subject: element.subject,
            description: element.description,
            user1: element.user1,
            user2: element.user2,
            state: element.state,
            isAcknowledgementRequired: element.isAcknowledgementRequired,
            dateNeeded: element.dateNeeded == null ? "" : new Date(element.dateNeeded)?.toLocaleDateString("en-US"),
            defaultNotesId : element.defaultNotesId,
          });
        }
      })
      this.Table.setNoteTable(this.tblNotesReponse);
    }, err => {
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    });
    this.subscription$.push(getTableSubscription);
  }
  getPatientDetailsForSave() {
    let patientDetails: PatientDTO;
    if (this.data.patientID !== "" && this.data.patientID !== null && this.data.patientID !== undefined) {
     const storeGetPatient = this.store.dispatch(new getPatient(this.data.patientID)).subscribe(response => {
        patientDetails = response.patientState.selectedPatient;
        this.saveNotes(patientDetails);
      }, err => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      })
      this.subscription$.push(storeGetPatient);
    }
  }
  notesId: string = "00000000-0000-0000-0000-000000000000";
  organizationUnitId = null;

  saveNotes(response: PatientDTO) {
    this.saveButtonHide = true;
    this.isShowSpinner = true;
    this.Table.getOrganizationUnitId().subscribe(value => {
      this.organizationUnitId = value;

    }, err => {
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    });
    // this.organizationUnitId = localStorage.getItem('initialOrganizationUnitId');
    let notesDetail: PatientDTO;
    let currentNotes: NotesDTO[] = response?.notes;
    if (this.notesId !== "00000000-0000-0000-0000-000000000000") {
      currentNotes = currentNotes.filter(notes => { return notes && notes.notesId !== this.notesId })
      currentNotes = [...currentNotes, {
        notesId: this.notesId,
        createdBy: this.noteForm.value.txtgeneralCreated === "" ? this.LoginUserName : this.LoginUserName,
        dateCreated: this.noteForm.value.txtDateCreated,
        actualDate: this.noteForm.value.txtActualDate,
        noteType: this.noteForm.value.txtNotetype,
        creatorId: this.noteForm.value.txtPatientCreated,
        assignedTo: this.noteForm.value.txtAssignedTo,
        status: this.noteForm.value.txtStatus,
        noteReason: this.noteForm.value.txtNotereason,
        severity: this.noteForm.value.txtSeverity,
        isDeactivateNote: +this.noteForm.value.chkDeactive,
        savedBy: this.noteForm.value.txtSavedBy,
        dateSaved: this.noteForm.value.txtDateSaved,
        lockedBy: this.noteForm.value.txtLockedBy,
        dateLocked: this.noteForm.value.dateLocked,
        subject: this.noteForm.value.txtSubject,
        description: this.noteForm.value.txtDescription,
        user1: this.noteForm.value.txtUser1,
        user2: this.noteForm.value.txtUser2,
        state: this.noteForm.value.txtState,
        isAcknowledgementRequired: +this.noteForm.value.chkAck,
        dateComplete: this.noteForm.value.txtDateComplete,
        dateNeeded: this.noteForm.value.txtDateNeeded,
        enteredBy: this.noteForm.value.txtEnteredBy,
        address: this.noteForm.value.txtAddress,
        entryDate: this.noteForm.value.txtEntryDate,
        followUpDate: this.noteForm.value.txtFollowUpDate,
        modifiedBy: this.noteForm.value.txtModifiedBy,
        modifiedDate: this.noteForm.value.txtModifiedDate,
        isChangeAlert: +this.noteForm.value.isChangeAlert,
        isPaymentAlert: +this.noteForm.value.isPaymentAlert,
        defaultNotesId: this.defaultNotesId,
      }]

    } else {

      currentNotes = [...currentNotes, {
        notesId: this.notesId,
        createdBy: this.noteForm.value.txtgeneralCreated === "" ? this.LoginUserName : this.LoginUserName,
        dateCreated: this.noteForm.value.txtDateCreated,
        actualDate: this.noteForm.value.txtActualDate,
        noteType: this.noteForm.value.txtNotetype,
        creatorId: this.noteForm.value.txtPatientCreated,
        assignedTo: this.noteForm.value.txtAssignedTo,
        status: this.noteForm.value.txtStatus,
        noteReason: this.noteForm.value.txtNotereason,
        severity: this.noteForm.value.txtSeverity,
        isDeactivateNote: +this.noteForm.value.chkDeactive,
        savedBy: this.noteForm.value.txtSavedBy,
        dateSaved: this.noteForm.value.txtDateSaved,
        lockedBy: this.noteForm.value.txtLockedBy,
        dateLocked: this.noteForm.value.dateLocked,
        subject: this.noteForm.value.txtSubject,
        description: this.noteForm.value.txtDescription,
        user1: this.noteForm.value.txtUser1,
        user2: this.noteForm.value.txtUser2,
        state: this.noteForm.value.txtState,
        isAcknowledgementRequired: +this.noteForm.value.chkAck,
        dateComplete: this.noteForm.value.txtDateComplete,
        dateNeeded: this.noteForm.value.txtDateNeeded,
        enteredBy: this.noteForm.value.txtEnteredBy,
        address: this.noteForm.value.txtAddress,
        entryDate: this.noteForm.value.txtEntryDate,
        followUpDate: this.noteForm.value.txtFollowUpDate,
        modifiedBy: this.noteForm.value.txtModifiedBy,
        modifiedDate: this.noteForm.value.txtModifiedDate,
        isChangeAlert: + this.noteForm.value.isChangeAlert,
        isPaymentAlert: +this.noteForm.value.isPaymentAlert,
        defaultNotesId: this.defaultNotesId,
      }]


    }

    notesDetail = {
      personals: response.personals,
      addresses: response.addresses,
      contacts: response.contacts,
      clinicals: response.clinicals,
      insurances: response.insurances,
      notes: currentNotes,
      policies: response.policies,
      defaultPatientId: response.defaultPatientId,
      insuranceVerifications: response.insuranceVerifications,
      authorizations: response.authorizations, patientDocuments: response.patientDocuments,
      organizationUnitId: response.organizationUnitId,
      isAutoIntake: response.isAutoIntake,
      autoIntakeDocumentDetails: response.autoIntakeDocumentDetails,
      chartId: response.chartId,
      billingDetails: response.billingDetails,
      patientLock: response.patientLock,
      isSorted: response.isSorted,
      fileId: response?.fileId,
    };
    this.data.patientID !== "" && this.store.dispatch(new updatePatient(this.data.patientID, notesDetail)).subscribe(response => {

      const patientSaved: PatientDTO = response?.patientState?.selectedPatient;
      // Swal.fire({ title: 'Success', html: 'Saved Successfully', icon: 'success', timer: 3000, timerProgressBar: true });
      this.toastr.success('Saved Successfully','Success')
      this.notesDialogRef.close(patientSaved);
      // this.modalService.dismissAll();
      this.saveButtonHide = true;
      this.isShowSpinner = false;
      this.noteForm.value;
      this.notesId = "00000000-0000-0000-0000-000000000000";
      this.getTableData(this.data.patientID);
    },
      err => {
        this.saveButtonHide = false;
        this.isShowSpinner = false;
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      });

  }
  getNoteById(noteId: string) {
    const getbyIdsubscription = this.store.dispatch(new getPatient(this.data.patientID)).pipe(
      map(val => {
        let selectedNotes: NotesDTO
        val.patientState.selectedPatient.notes.forEach((element: NotesDTO) => {
          if (element.notesId === noteId) { selectedNotes = element; }
        });
        return selectedNotes;
      }
      )).subscribe(response => {
        this.defaultNotesId = response.defaultNotesId;
        this.noteForm.patchValue({
          txtActualDate: response.actualDate === null ? "" : response.actualDate,
          txtgeneralCreated: response.createdBy == "" ? this.LoginUserName : this.LoginUserName,
          txtPatientCreated: response.createdBy,
          txtNotetype: response.noteType,
          txtDateCreated: response.dateCreated === null ? "" : response.dateCreated,
          txtPatientDateCreated: response.dateCreated === null ? "" : response.dateCreated,
          txtAssignedTo: response.assignedTo,
          txtUser1: response.user1,
          txtUser2: response.user2,
          txtDateNeeded: response.dateNeeded === null ? "" : response.dateNeeded,
          txtSavedBy: response.savedBy,
          txtDateSaved: response.dateSaved,
          txtLockedBy: response.lockedBy,
          dateLocked: response.dateLocked === null ? "" : response.dateLocked,
          chkAck: response.isAcknowledgementRequired === 0 ? false : true,
          chkDeactive: response.isDeactivateNote === 0 ? false : true,
          txtStatus: response.status,
          txtState: response.state,
          txtNotereason: response.noteReason,
          txtDateComplete: response.dateComplete === null ? "" : response.dateComplete,
          txtDescription: response.description,
          txtSubject: response.subject,
          txtSeverity: response.severity,
          txtEnteredBy: response.enteredBy,
          txtAddress: response.address,
          txtEntryDate: response.entryDate === null ? "" : response.entryDate,
          txtFollowUpDate: response.followUpDate === null ? "" : response.followUpDate,
          txtModifiedBy: response.modifiedBy,
          txtModifiedDate: response.modifiedDate === null ? "" : response.modifiedDate,
          isChangeAlert: response.isChangeAlert === 0 ? false : true,
          isPaymentAlert: response.isPaymentAlert === 0 ? false : true
        })
        this.saveButtonHide = false;
        this.notesId = response.notesId;
        this.notesModalTitle = 'Edit Notes';
      }, err => {
        this.saveButtonHide = false;
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      });
    this.noteForm.markAllAsTouched();
    this.noteForm.markAsDirty();
    this.noteForm.updateValueAndValidity();
    this.getFormValidationErrors();
    this.subscription$.push(getbyIdsubscription)
  }
  valueChangeLoadDropdown() {
    this.noteForm.patchValue({
      txtSubject: this.noteForm.value.txtState,
      txtDescription: this.noteForm.value.txtState,
    })
  }

  getFormValidationErrors() {

  }

  loadUserProfile() {

    // ! User Profile API to get OrganizationUnitId, TenantId, RoleName, RoleId and UserId
   const dashBoardSubscription =  this.dashboardService.userProfile().subscribe(response => {
      this.LoginUserName = response?.userName;
    }, err => {
    });
    this.subscription$.push(dashBoardSubscription)
  }
}
