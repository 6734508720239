<div class="container-fluid">
  <!-- Headers -->
  <div class="row">
    <div class="col-12 d-flex align-items-center justify-content-between">
      <h3 class="font-weight-bolder mb-2">
        <b class="customThemeClass">List / Orders / Return Orders</b>
      </h3>
      <div>
        <form [formGroup]="frmReturnList">
          <mat-form-field>
            <mat-label>Select Date Range</mat-label>
            <mat-date-range-input [rangePicker]="picker">
              <input
                matStartDate
                matInput
                placeholder="Start date"
                formControlName="txtDateValue"
              />
              <input
                matEndDate
                matInput
                placeholder="End date"
                formControlName="txtDate"
                (dateChange)="openDatePicker()"
              />
            </mat-date-range-input>
            <mat-datepicker-toggle
              matSuffix
              [for]="picker"
            ></mat-datepicker-toggle>
            <mat-date-range-picker #picker></mat-date-range-picker>
          </mat-form-field>
        </form>
      </div>
    </div>
  </div>

  <!-- Page Content -->
  <div class="row">
    <div class="col-12 mb-3">
      <div class="card">
        <div class="card-body p-2">
          <!-- <div class="col-12 d-flex justify-content-between mb-2 pr-0">
            <p class="header-text mt-2">Return Orders</p>

          </div> -->
          <!-- <mat-divider class="my-3"></mat-divider> -->
          <div
            class="d-flex justify-content-center"
            *ngIf="!loadReturnOrdersTable"
          >
            <img
              class="img-responsive pb-2 mr-1"
              src="assets/gifs/Infinity-loader.gif"
            />
          </div>

          <div *ngIf="loadReturnOrdersTable" class="table table-responsive">
            <app-return-orders-table
              [ltReturnOrders]="ltReturnOrders"
            ></app-return-orders-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Exchange Items List Dialog -->
<ng-template #returnItemDialog>
  <div class="row">
    <!-- Header -->
    <div class="col-12 mb-2">
      <div class="d-flex justify-content-between">
        <div>
          <p class="header-text">Return Order - {{ returnOrderId }}</p>
        </div>
        <button mat-icon-button (click)="closeDialog()" class="close-button">
          <mat-icon>close</mat-icon>
        </button>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-body p-2">
          <!-- Return Order -->
          <div>
            <div
              class="d-flex justify-content-center"
              *ngIf="!isReturnItemsLoaded"
            >
              <img
                class="img-responsive pb-2 mr-1"
                src="assets/gifs/Infinity-loader.gif"
              />
            </div>
            <!-- <p class="header-text">Return Order - {{ returnOrderId }}</p> -->
            <div *ngIf="isReturnItemsLoaded" class="table table-responsive">
              <table
                datatable
                class="row-bordered hover w-100 display"
                [dtOptions]="dtReturnOrderTableOptions"
              >
                <thead>
                  <tr>
                    <th>Options</th>
                    <th [matTooltip]="'Product Code'">Product Code</th>
                    <th [matTooltip]="'Description'">Description</th>
                    <th [matTooltip]="'Size'">Size</th>
                    <th [matTooltip]="'Serial No'">Serial No</th>
                    <th [matTooltip]="'Ordered Qty'" class="text-right">
                      Ordered Qty
                    </th>
                    <th [matTooltip]="'Balance Qty'" class="text-right">
                      Balance Qty
                    </th>
                    <th [matTooltip]="'Received Qty'">
                      Received Qty<span class="asterisk">*</span>
                    </th>
                    <th [matTooltip]="'Reason'">Reason</th>
                    <th [matTooltip]="'Condition'">
                      Condition<span class="asterisk">*</span>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let data of ltReturnOrderItems; let i = index">
                    <td>
                      <ng-container *ngIf="data.recQuantity <= 0">
                        <button
                          type="button"
                          (click)="toggleEdit(i, data)"
                          *ngIf="editableRowIndex !== i"
                          mat-icon-button
                        >
                          <mat-icon
                            type="button"
                            class="icon-success"
                            matTooltip="Click To Edit"
                            >edit</mat-icon
                          >
                        </button>
                        <button
                          type="button"
                          (click)="toggleEdit(i, data)"
                          *ngIf="editableRowIndex === i"
                          matTooltip="Click To Update"
                          [disabled]="isSaveButtonEnabled(data.returnId)"
                          mat-icon-button
                        >
                          <!-- [disabled]="isSaveButtonEnabled(data.requestItemId)" -->
                          <mat-icon type="button" class="icon-success"
                            >check_circle</mat-icon
                          >
                        </button>
                      </ng-container>
                      <ng-container *ngIf="data.recQuantity > 0">
                        <p matTooltip="'Completed'" class="text-danger mt-3">
                          {{ "Completed" }}
                        </p>
                      </ng-container>
                    </td>
                    <td [matTooltip]="data?.productCode || '-'">
                      {{ data.productCode }}
                    </td>
                    <td [matTooltip]="data?.productDescription || '-'">
                      {{ data.productDescription }}
                    </td>
                    <td [matTooltip]="data?.size || '-'">{{ data.size }}</td>
                    <td [matTooltip]="data?.serialLotNo || '-'">
                      {{ data.serialLotNo }}
                    </td>
                    <td [matTooltip]="data?.quantity || '-'" class="text-right">
                      {{ data.quantity }}
                    </td>
                    <td
                      [matTooltip]="data?.balQuantity || '-'"
                      class="text-right"
                    >
                      {{ data.balQuantity }}
                    </td>
                    <!-- <td>{{ data.recQuantity }}</td> -->

                    <!-- Received Order -->
                    <td
                      [matTooltip]="data?.recQuantity || '0'" class="text-right"
                      *ngIf="editableRowIndex !== i; else editReceivedQty"
                    >
                      {{ data.recQuantity }}
                    </td>
                    <ng-template #editReceivedQty>
                      <td [matTooltip]="data?.txtReceivedQty || '0'" class="text-right adjust-width">
                        <input
                          placeholder="Received Qty"
                          type="text"
                          #inputReceivedQuantity="ngModel"
                          maxlength="10"
                          [(ngModel)]="data.txtReceivedQty"
                          class="textbox-border-class text-right dd-text"
                        />
                        <div
                          class="text-danger dd-text text-error text-center"
                          *ngIf="inputReceivedQuantity.value > data.balQuantity"
                        >

                          Received Qty will not be greater than Balance Qty
                        </div>
                      </td>
                    </ng-template>

                    <td [matTooltip]="data?.reason || '-'">
                      {{ data.reason }}
                    </td>
                    <!-- <td>{{ data.itemCondition }}</td> -->
                    <td
                      [matTooltip]="data?.itemCondition || '-'"
                      *ngIf="editableRowIndex !== i; else editItemCondition"
                    >
                      {{ data.itemCondition }}
                    </td>
                    <ng-template #editItemCondition>
                      <td [matTooltip]="'Condition'">
                        <mat-select
                          class="line-height"
                          [placeholder]="'Condition'"
                          #returnItemCondition="ngModel"
                          maxlength="20"
                          [(ngModel)]="data.drpCondition"
                        >
                          <mat-option
                            [value]="data.id"
                            *ngFor="let data of ltItemReturnCondition"
                            >{{ data.description }}</mat-option
                          >
                        </mat-select>
                      </td>
                    </ng-template>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>
