<mat-dialog-content class="mat-typography">
    <div class="row">
        <h2 class="col-12 pl-4 customThemeClass">WIP Details :
            <span style="text-align: right" class="headStyle text-danger">{{defaultWIPId}}
            </span>
        </h2>
    </div>
    <mat-divider></mat-divider>
    <form [formGroup]="wipTaskForm">
        <div class="row pt-3">
            <mat-form-field class="col-12">
                <mat-label>Username<span class="asterisk">*</span></mat-label>
                <mat-select formControlName="userToBeAssigned" >
                    <mat-option>
                        <ngx-mat-select-search placeholderLabel="Search" noEntriesFoundLabel="No Matches found"
                            formControlName="txtUserToBeAssignedFilter"></ngx-mat-select-search>
                    </mat-option>
                    <mat-option *ngFor="let users of filteredIdentityUsers | async" [value]="users.id">
                        {{ users.userName }}
                    </mat-option>
                </mat-select>

            </mat-form-field>
            <mat-form-field class="col-12">
                <mat-label>Comments</mat-label>
                <!-- <input matInput formControlName="txtComment" type="text" maxlength="100" autocomplete="off" /> -->
                <textarea formControlName="txtComment" type="text" rows="2" maxlength="1000" autocomplete="off" matInput></textarea>
            </mat-form-field>

        </div>

    </form>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <ng-container *ngIf="!wipTaskForm?.disabled">
        <button [disabled]="isShowSpinner || !!wipTaskForm?.invalid" mat-button class="buttonColor"
        (click)="updateWIPDetails()">
        Save
        <mat-icon *ngIf="isShowSpinner">
            <mat-spinner class="spinner-border spinner-border-sm" diameter="20">
            </mat-spinner>
        </mat-icon>
    </button>
    </ng-container>

    <button class="buttonCancelColor" mat-button mat-dialog-close>Close</button>
</mat-dialog-actions>
