<div class="row">
  <div class="col-12">
    <div class="card card-body px-2 pb-1 pt-2">
      <form [formGroup]="eobSearchForm">
        <!-- Payer -->
        <mat-form-field class="col-3">
          <mat-label>Payer</mat-label>
          <mat-select multiple formControlName="drpPayer" (selectionChange)="onSelectionPayerIdChange($event)" (openedChange)="onPayerDropdownOpenChange($event)" (closed)="handlePayerCloseDrpdown(eobSearchForm.get('drpPayer').value)">
            <mat-option>
              <ngx-mat-select-search
                placeholderLabel="Search"
                noEntriesFoundLabel="No Matches found"
                hideClearSearchButton="true"
                (keyup)="searchPayor($event.target.value)"
                formControlName="txtPayerFilter"
              ></ngx-mat-select-search>
            </mat-option>
            <mat-option *ngIf="!payerIdSelectAllOption" [value]="'selectAll'" class="drpOptionFontSize">Select All</mat-option>
            <mat-option *ngIf="payerIdSelectAllOption" [value]="'DeselectAll'" class="drpOptionFontSize">Deselect All</mat-option>
            <mat-option
              [value]="data.payerId"
              *ngFor="let data of filteredPayors | async"
              >{{ data.payerName }}</mat-option
            >
          </mat-select>
          <button
            mat-button
            *ngIf="
              eobSearchForm.get('drpPayer').value != '' &&
              eobSearchForm.get('drpPayer').value != null &&
              eobSearchForm.get('drpPayer').value != undefined
            "
            (click)="
              clearPayer(); $event.stopPropagation(); buildFilterParams()
            "
            matSuffix
            mat-icon-button
          >
            <mat-icon>close</mat-icon>
          </button>
        </mat-form-field>

        <!-- EFT/Check Number -->
        <mat-form-field class="col-2">
          <input
            matInput
            (change)="buildFilterParams()"
            formControlName="txtCheckNumber"
            placeholder="EFT/Check Number"
            maxlength="75"
        /></mat-form-field>

        <!-- Amount -->
        <mat-form-field class="col-1">
          <input
            matInput
            (change)="buildFilterParams()"
            formControlName="txtAmount"
            placeholder="Amount"
            type="number"
            maxlength="75"
        /></mat-form-field>

        <!-- Status-->
        <mat-form-field class="col-1">
          <mat-label>Status</mat-label>
          <mat-select multiple (selectionChange)="onSelectionStatusIdChange($event)" (closed)="handleStatusCloseDrpdown(eobSearchForm.get('drpStatus').value)" formControlName="drpStatus"
          >
            <mat-option>
              <ngx-mat-select-search
                placeholderLabel="Search"
                noEntriesFoundLabel="No Matches found"
                hideClearSearchButton="true"
                formControlName="txtStatusFilter"
              ></ngx-mat-select-search>
            </mat-option>
            <mat-option *ngIf="!statusIdSelectAllOption" [value]="'selectAll'">Select All</mat-option>
            <mat-option *ngIf="statusIdSelectAllOption" [value]="'DeselectAll'">Deselect All</mat-option>
            <mat-option
              *ngFor="let data of filteredStatuses | async"
              [value]="data.statusId"
              >{{ data.statusName }}</mat-option
            >
          </mat-select>
          <button
            mat-button
            *ngIf="
              eobSearchForm.get('drpStatus').value != '' &&
              eobSearchForm.get('drpStatus').value != null &&
              eobSearchForm.get('drpStatus').value != undefined
            "
            (click)="
              clearStatus(); $event.stopPropagation(); buildFilterParams()
            "
            matSuffix
            mat-icon-button
          >
            <mat-icon>close</mat-icon>
          </button>
        </mat-form-field>

        <!-- Remittance Date -->
        <mat-form-field class="col-2">
          <mat-label>Remittance Date</mat-label>
          <mat-date-range-input [rangePicker]="pickerRemittance">
            <input
              matStartDate
              (keydown)="addSlashToDate($event)"
              matInput
              placeholder="Start date"
              formControlName="txtRemittanceStartDate"
            />

            <input
              matEndDate
              matInput
              (dateChange)="onRemittanceDateChange($event)"
              (keydown)="addSlashToDate($event)"
              placeholder="End date"
              formControlName="txtRemittanceEndDate"
            />
          </mat-date-range-input>
          <mat-datepicker-toggle
            matSuffix
            [for]="pickerRemittance"
          ></mat-datepicker-toggle>
          <mat-date-range-picker #pickerRemittance></mat-date-range-picker>
          <button
            mat-button
            *ngIf="
              (eobSearchForm.get('txtRemittanceStartDate').value != '' &&
                eobSearchForm.get('txtRemittanceStartDate').value != null &&
                eobSearchForm.get('txtRemittanceStartDate').value !=
                  undefined) ||
              (eobSearchForm.get('txtRemittanceEndDate').value != '' &&
                eobSearchForm.get('txtRemittanceEndDate').value != null &&
                eobSearchForm.get('txtRemittanceEndDate').value != undefined)
            "
            (click)="
              clearRemittanceDate();
              $event.stopPropagation();
              buildFilterParams()
            "
            matSuffix
            mat-icon-button
          >
            <mat-icon>close</mat-icon>
          </button>
        </mat-form-field>

        <!-- Created Date -->
        <mat-form-field class="col-2">
          <mat-label>Created Date</mat-label>
          <mat-date-range-input [rangePicker]="picker">
            <input
              matStartDate
              matInput
              (keydown)="addSlashToDate($event)"
              placeholder="Start date"
              formControlName="txtCreatedStartDate"
            />

            <input
              matEndDate
              (dateChange)="onCreatedDateChange($event)"
              (keydown)="addSlashToDate($event)"
              matInput
              placeholder="End date"
              formControlName="txtCreatedEndDate"
            />
          </mat-date-range-input>
          <mat-datepicker-toggle
            matSuffix
            [for]="picker"
          ></mat-datepicker-toggle>
          <mat-date-range-picker #picker></mat-date-range-picker>
          <button
            mat-button
            *ngIf="
              (eobSearchForm.get('txtCreatedStartDate').value != '' &&
                eobSearchForm.get('txtCreatedStartDate').value != null &&
                eobSearchForm.get('txtCreatedStartDate').value != undefined) ||
              (eobSearchForm.get('txtCreatedEndDate').value != '' &&
                eobSearchForm.get('txtCreatedEndDate').value != null &&
                eobSearchForm.get('txtCreatedEndDate').value != undefined)
            "
            (click)="
              clearCreatedDate(); $event.stopPropagation(); buildFilterParams()
            "
            matSuffix
            mat-icon-button
          >
            <mat-icon>close</mat-icon>
          </button>
        </mat-form-field>

        <button
          type="button"
          matTooltip="Reset"
          mat-raised-button
          (click)="resetForm()"
          class="reset-icon-button mr-2 px-2 mat-raised-small-button"
        >
          <mat-icon class="fg-white">refresh</mat-icon>
        </button>
      </form>
    </div>
  </div>
</div>

<div class="row mt-2" *ngIf="!isLoading">
  <div class="col-12">
    <div class="card card-body p-1">
      <button
        type="button"
        (click)="openEOBModel()"
        matTooltip="Upload Check/EFT"
        mat-raised-button
        class="buttonColor ml-2 px-2 mt-1 mat-raised-small-button z-1 position-absolute"
      >
        <mat-icon class="fg-white">cloud_upload</mat-icon>
      </button>
      <div class="table table-responsive">
        <table
          datatable
          [dtOptions]="eobTable"
          class="row-border hover w-100 display"
        >
          <thead>
            <tr>
              <th [matTooltip]="'Options'"><mat-icon>apps</mat-icon></th>
              <th [matTooltip]="'EFT/Check Number'">EFT/Check Number</th>
              <th [matTooltip]="'Payer ID'">Payer ID</th>
              <th [matTooltip]="'Payer'">Payer</th>
              <th [matTooltip]="'Amount($)'">Amount($)</th>
              <th [matTooltip]="'Payment Method'">Payment Method</th>
              <th [matTooltip]="'Remittance Date'">Remittance Date</th>
              <th [matTooltip]="'Created By'">Created By</th>
              <th [matTooltip]="'Created Date'">Created Date</th>
              <th [matTooltip]="'Last Modified By'">Last Modified By</th>
              <th [matTooltip]="'Last Modified Date'">Last Modified Date</th>
              <th [matTooltip]="'Status'">Status</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let data of eobData">
              <td>
                <div class="d-flex flex-row">
                <mat-icon
                  [matTooltip]="'Options'"
                  style="cursor: pointer !important"
                  [matMenuTriggerFor]="menu"
                  >more_vert</mat-icon
                >
                <mat-menu class="custom-mat-menu" #menu="matMenu">
                  <a
                    [routerLink]="
                      '/patient_preview_document/' +
                      data.sEOBBlob +
                      '/' +
                      data.sCheckNumber +
                      '/' +
                      2
                    "
                    target="_blank"
                    class="preview-link"
                  >
                    <button
                      [disabled]="data.sEOBBlob == '' || data.sEOBBlob == null"
                      mat-menu-item
                    >
                      View EOB
                    </button>
                  </a>

                  <button
                    mat-menu-item
                    [disabled]="data.sEOBBlob == '' || data.sEOBBlob == null"
                    (click)="downloadEOB(data.sEOBBlob, data.sCheckNumber)"
                  >
                    Download EOB
                  </button>
                  <button
                    mat-menu-item
                    [disabled]="data.sERABlob == '' || data.sERABlob == null"
                    (click)="downloadEOB(data.sERABlob, data.sCheckNumber)"
                  >
                    Download ERA
                  </button>
                </mat-menu>

                <mat-icon
                  *ngIf="data.eobStatusName === 'Open'"
                  (click)="completeEOB(data.eobId)"
                  class="complete-button"
                  matTooltip="Click to Complete Process of the Check/EFT"
                  mat-icon-button
                  >check_circle</mat-icon
                >
              </div>
              </td>
              <td [matTooltip]="data.sCheckNumber || '-'">
                {{ data.sCheckNumber || "-" }}
              </td>
              <td [matTooltip]="data.sPayerCode || '-'">
                {{ data.sPayerCode || "-" }}
              </td>
              <td [matTooltip]="data.sPayerName || '-'">
                {{ data.sPayerName || "-" }}
              </td>

              <td class="text-right" [matTooltip]="data.dCheckAmount || '-'">
                {{ data.dCheckAmount || "-" }}
              </td>
              <td [matTooltip]="data.paymentMethodName || '-'">
                {{ data.paymentMethodName || "-" }}
              </td>
              <td [matTooltip]="data.dtRemitance || '-'">
                {{ data.dtRemitance || "-" }}
              </td>
              <td [matTooltip]="data.createdByName || '-'">
                {{ data.createdByName || "-" }}
              </td>
              <td [matTooltip]="data.dtCreated || '-'">
                {{ data.dtCreated || "-" }}
              </td>
              <td [matTooltip]="data.modifiedByName || '-'">
                {{ data.modifiedByName || "-" }}
              </td>
              <td [matTooltip]="data.dtModified || '-'">
                {{ data.dtModified || "-" }}
              </td>

              <td [matTooltip]="data.eobStatusName" class="text-center">
                <div class="d-flex align-items-center">
                  <span
                    *ngIf="data.eobStatusName === 'Open'"
                    [ngStyle]="{
                      'background-color': '#16aeff',
                      color: '#ffffff'
                    }"
                    class="rounded-border ml-1 w-75 p-1 claim-status-label"
                  >
                    {{ data.eobStatusName }}
                  </span>
                  <span
                    *ngIf="data.eobStatusName === 'Processed'"
                    [ngStyle]="{
                      'background-color': '#7e7474',
                      color: '#ffffff'
                    }"
                    class="rounded-border ml-1 w-75 p-1 claim-status-label"
                  >
                    {{ data.eobStatusName }}
                  </span>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>
<div class="row" *ngIf="isLoading">
  <div class="col-12">
    <div class="d-flex justify-content-center">
      <img
        class="img-responsive pb-2 mr-1"
        src="assets/gifs/Infinity-loader.gif"
      />
    </div>
  </div>
</div>
<app-loader-component *ngIf="isDataLoaded"></app-loader-component>
