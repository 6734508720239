import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { Timezone } from '@syncfusion/ej2-schedule';
import { PrintTicketDetails } from 'projects/order/src/app/order-proxy/order-management/order-optimization/dto';
import { MmOrderService } from 'projects/order/src/app/order-proxy/order-management/order-optimization/mm-order.service';
import { Subscription } from 'rxjs';
import Swal from 'sweetalert2';
import { DocumentMaxandTypeLimits } from '../enums/allenums.enum';
import { AbstractControl, ValidatorFn, Validators } from '@angular/forms';
import { AutoIntakeDocumentDetailsDTO } from 'projects/patient/src/app/patient-proxy/patient/dto';

@Injectable({
  providedIn: 'root',
})
export class CommonService {
  patientListDropdownSubscription: Subscription;
  constructor(
    private mmOrderService: MmOrderService,
    private datepipe: DatePipe
  ) {}

  getFormattedDate(date: string): string {
    return date
      ? new Date(date).toLocaleDateString('en-US', {
          month: '2-digit',
          day: '2-digit',
          year: 'numeric',
        })
      : '-';
  }

  getCSTDateTime(date): string {
    let timezoneStart: Timezone = new Timezone();
    let lDateTime: Date = new Date(date);
    let convertedCSTDate: Date = timezoneStart?.remove(
      lDateTime,
      'America/Chicago'
    );
    return convertedCSTDate?.toISOString();
  }
  getFormattedDateTimeZone(date: string): string {
    if (date) {
      const formattedDate = new Date(date);
      const options: Intl.DateTimeFormatOptions = {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: 'numeric',
        minute: '2-digit',
        hour12: true,
        timeZone: 'America/Chicago',
      };
      return formattedDate.toLocaleString('en-US', options);
    } else {
      return '-';
    }
  }

  getFormattedDateTimewithSecondZone(date: string): string {
    if (date) {
      const formattedDate = new Date(date);
      const options: Intl.DateTimeFormatOptions = {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: 'numeric',
        minute: '2-digit',
        second: '2-digit',
        hour12: true,
        timeZone: 'America/Chicago',
      };
      return formattedDate.toLocaleString('en-US', options);
    } else {
      return '-';
    }
  }

  //Format Date
  getFormattedDateZone(date: string): string {
    if (date) {
      const formattedDate = new Date(date);
      const options: Intl.DateTimeFormatOptions = {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        timeZone: 'America/Chicago',
      };
      return formattedDate.toLocaleString('en-US', options);
    } else {
      return '-';
    }
  }
  //Format Time
  getFormattedTimeZone(date: string): string {
    if (date) {
      const formattedDate = new Date(date);
      const options: Intl.DateTimeFormatOptions = {
        hour: 'numeric',
        minute: '2-digit',
        hour12: true,
        timeZone: 'America/Chicago',
      };
      return formattedDate.toLocaleString('en-US', options);
    } else {
      return '-';
    }
  }

  formatDateDob(dateString: string): string {
    const date = new Date(dateString);
    const utcDate = new Date(
      date.getUTCFullYear(),
      date.getUTCMonth(),
      date.getUTCDate()
    );
    return utcDate.toLocaleDateString('en-US', {
      month: '2-digit',
      day: '2-digit',
      year: 'numeric',
    });
  }

  getFirstAndLastDatesOfCurrentMonth() {
    const today = new Date();
    let fromDate = new Date(today.getFullYear(), today.getMonth(), 1);
    let toDate = new Date(today.getFullYear(), today.getMonth() + 1, 0);
    return { fromDate: fromDate, toDate: toDate };
  }

  //#region Patient List Dropdown - Dont Use
  loadPatientListDropdown(searchParameter: string, patientId: string): any {
    if (this.patientListDropdownSubscription) {
      this.patientListDropdownSubscription.unsubscribe();
    }
    this.patientListDropdownSubscription = this.mmOrderService
      .getPatientChartNoV3BySSearchParamsAndPatient(searchParameter, patientId)
      .subscribe(
        (response) => {
          return response ?? [];
        },
        (err) => {
          return [];
        }
      );
  }
  //#endregion

  private formatPhoneNumber(value: string): string {
    if (value === null) return '';
    const phoneNumberRegex = /^(\d{3})(\d{3})(\d{4})$/;
    const matches = phoneNumberRegex.exec(value);

    if (!matches) return value;

    // Format the phone number with hyphens
    const formattedNumber = `${matches[1]}-${matches[2]}-${matches[3]}`;
    return formattedNumber;
  }
  calculateTotalSize(files) {
    let totalSize = 0;
    let status = false;
    let maxTotalSize = DocumentMaxandTypeLimits.ManualUploadMaxSize;
    for (const key in files) {
      if (files.hasOwnProperty(key)) {
        totalSize += files[key].size;
      }
    }
    if (totalSize <= maxTotalSize) {
      status = true;
    }
    return status;
  }

  convertBytesToReadable(bytes) {
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    if (bytes == 0) return '0 Byte';
    if (isNaN(bytes)) return '0 Bytes';
    const i = Math.floor(Math.log(bytes) / Math.log(1024));
    return parseFloat((bytes / Math.pow(1024, i)).toFixed(2)) + ' ' + sizes[i];
  }

  decimalValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const value = control.value;
      if (
        value === null ||
        value === undefined ||
        Validators.required(control) !== null
      ) {
        // If the field is required or empty, don't perform decimal validation
        return null;
      }

      // Regular expression for decimal numbers
      const decimalRegExp = /^-?\d*(\.\d+)?$/;

      if (!decimalRegExp.test(value.toString())) {
        return { invalidDecimal: true };
      }

      return null;
    };
  }

  onKeyPressDecimal(event: KeyboardEvent) {
    const input = event.target as HTMLInputElement;
    const isNumber = /[0-9]/.test(event.key);
    const isDot = event.key === '.';

    if (!isNumber && !isDot) {
      event.preventDefault(); // Prevent the input if the key is not a number or dot
    }

    if (isDot && input.value.includes('.')) {
      event.preventDefault(); // Prevent the input if a dot already exists
    }
  }

  onKeyPressDecimalWithPrecision2(event: KeyboardEvent) {
    const input = event.target as HTMLInputElement;
    const isNumber = /[0-9]/.test(event.key);
    const isDot = event.key === '.';

    if (!isNumber && !isDot) {
      event.preventDefault(); // Prevent the input if the key is not a number or dot
    }

    if (isDot && input.value.includes('.')) {
      event.preventDefault(); // Prevent the input if a dot already exists
    }

    const value = input.value;
    const dotIndex = value.indexOf('.');
    if (
      dotIndex !== -1 &&
      value.substring(dotIndex + 1).length >= 2 &&
      isNumber
    ) {
      event.preventDefault(); // Prevent the input if there are already two digits after the decimal point
    }
  }

  //#region Print Ticket

  getPrintTicket(printTicketData: PrintTicketDetails) {
    const tbodyStyle = {
      fontSize: 10,
      margin: [0, 2],
    };

    printTicketData.productDetailsDTO = printTicketData?.productDetailsDTO?.map(
      (a) => ({
        ...a,
        style: tbodyStyle,
      })
    );

    let docDefinition = {
      /* #region HEADER */
      header: function (currentPage, pageCount, pageSize) {
        return {
          columns: [
            // {
            //   image: localStorage.getItem('LogoDataUrl'),
            //   width: 100, // Adjust the width as needed
            //   alignment: 'left', // Adjust the alignment as needed
            // },
            // {
            //   width: '10%',
            //   text:{

            //       text: '844498797',
            //       fontSize: 18,
            //       bold: true,
            //       alignment: 'center',
            //       color: '#000000',

            //   }
            // },
            {
              width: '80%',
              text: [
                { text: '\n' },
                {
                  // margin:[15,0,0,0],
                  text: printTicketData?.mmRefOrderId ?? '',
                  fontSize: 16,
                  bold: true,
                  alignment: 'left',
                  color: '#000000',
                },
                {
                  text: '\t\t\t\t\t\t\t\t\t\tConfirm receipt of shipment online at www.monitormedical.com\n\t\t\t\t',
                  fontSize: 10,
                  bold: true,
                  alignment: 'center',
                  color: '#91ab5b',
                },
                {
                  text: '\t\t\t\t\t\tPATIENT CARE DELIVERY TICKET',
                  fontSize: 16,
                  bold: true,
                  alignment: 'center',
                  color: '#800000',
                },
              ],
              margin: [15, 0, 0, 0],
            },
            {
              image: localStorage.getItem('LogoDataUrl'),
              width: 100, // Adjust the width as needed
              // alignment: 'right', // Adjust the alignment as needed
              margin: [0, 10, 0, 0],
            },
          ],
        };
      },

      /* #endregion */

      /* #region FOOTER */
      footer: (currentPage, pageCount, pageSize) => {
        return {
          canvas: [
            {
              type: 'line',
              x1: 0,
              y1: 0,
              x2: 565,
              y2: 0,
              lineWidth: 3,
              lineColor: '#800000',
            },
          ],
          columns: [
            {
              width: '70%',
              text: [
                {
                  text: 'Patient Signature:',
                  fontSize: 10,
                  italics: true,
                },
                {
                  text: '_____________________________________________________________________\n',
                  fontSize: 10,
                  italics: true,
                },
                {
                  text: 'Monitor Medical, Inc. \t',
                  fontSize: 16,
                  italics: true,
                  bold: true,
                },
                {
                  text: 'The C-PAP Co. \t',
                  fontSize: 12,
                  italics: true,
                },
                {
                  text: 'Toll Free: 866-634-0177 \n',
                  fontSize: 10,
                  italics: true,
                  bold: true,
                },
                {
                  text: printTicketData?.mmRefOrderId ?? '',
                  fontSize: 14,
                  italics: true,
                  alignment: 'right',
                  bold: true,
                  color: '#000000',
                },
              ],
              // ,
              bold: true,
              color: '#800000',
            },
            {
              width: '30%',
              text: [
                // {
                //   text: '_ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ \n\n',
                //   fontSize: 15,
                //   bold: true,
                //   italics: true,
                //   alignment: 'left',
                // },
                { text: 'Date:', fontSize: 10, italics: true },
                { text: '___________________________\n' },
                {
                  text:
                    this.datepipe.transform(new Date(), 'fullDate') + '\n\n',
                  alignment: 'center',
                  fontSize: 8,
                  italics: true,
                  bold: false,
                },
                {
                  text:
                    printTicketData.shippingMethodName +
                    '\t' +
                    printTicketData.location,
                  fontSize: 8,
                  italics: true,
                  alignment: 'center',
                  color: '#000000',
                },
              ],
              fontSize: 12,
              bold: true,
              alignment: 'left',
              color: '#800000',
            },

            // {
            //   text:
            //     printTicketData.shippingMethodName +
            //     ' ' +
            //     printTicketData.location,
            //   fontSize: 8,
            //   italics: true,
            // },
          ],
          margin: [15, 0, 15, 0],
          alignment: 'left',
          // fontSize: 10,
        };
      },
      /*#endregion*/

      /* #region CONTENT */

      content: [
        /* #region Section 1 */
        {
          canvas: [
            {
              type: 'line',
              x1: 0,
              y1: 0,
              x2: 565,
              y2: 0,
              lineWidth: 2,
              lineColor: '#800000',
            },
          ],
        },
        //Row 1
        {
          columns: [
            {
              width: '33%',
              text: 'Order No:\t' + printTicketData.mmRefOrderId ?? '',
              bold: true,
              fontSize: 10,
            },
            {
              width: '33%',
              text: 'INS:\t' + printTicketData.insuranceName ?? '',
              fontSize: 10,
            },
            {
              width: '33%',
              text:
                'H:\t' + this.formatPhoneNumber(printTicketData?.homePhone) ??
                '',
              fontSize: 10,
            },
          ],
          margin: [0, 10, 0, 3], // [left, top, right, bottom]
        },
        //Row 2
        {
          columns: [
            {
              width: '33%',
              text: 'Account No:\t' + printTicketData?.chartNo ?? '',
              bold: true,
              fontSize: 10,
            },
            {
              width: '33%',
              text:
                'DOB:\t' +
                (printTicketData?.dateOfBirth != null
                  ? this.datepipe.transform(
                      printTicketData?.dateOfBirth,
                      'MM/dd/yyyy'
                    )
                  : ''),
              fontSize: 10,
              style: 'columnHeader',
            },
            {
              width: '33%',
              text:
                'W:\t' + this.formatPhoneNumber(printTicketData?.workPhone) ??
                '',
              fontSize: 10,
              style: 'columnHeader',
            },
          ],
          margin: [0, 0, 0, 3],
        },
        //Row 3
        {
          columns: [
            {
              width: '33%',
              text: 'Patient Name:\t' + printTicketData.patientName ?? '',
              fontSize: 10,
            },
            {
              width: '33%',
              text: 'e-mail:\t' + (printTicketData?.emailId ?? ''),
              fontSize: 10,
            },
            {
              width: '33%',
              text:
                'M:\t' + this.formatPhoneNumber(printTicketData?.mobilePhone) ??
                '',
              fontSize: 10,
            },
          ],
          margin: [0, 0, 0, 3],
        },
        //Row 4
        {
          columns: [
            {
              width: '50%',
              text: 'Address:\t' + printTicketData?.fullAddress ?? '',
              fontSize: 10,
            },
          ],
          margin: [0, 0, 0, 10],
        },

        /* #endregion*/
        /* #region Section 2 */
        {
          canvas: [
            {
              type: 'line',
              x1: 0,
              y1: 0,
              x2: 565,
              y2: 0,
              lineWidth: 2,
              lineColor: '#800000',
            },
          ],
        },
        //Row 1
        {
          text:
            'Contact ' + printTicketData?.technicianName + ' at 281-637-2286',
          fontSize: 16,
          bold: true,
          alignment: 'center',
          color: '#800000',
          margin: [0, 5],
        },
        //Row 2
        {
          canvas: [
            {
              type: 'line',
              x1: 0,
              y1: 0,
              x2: 565,
              y2: 0,
              lineWidth: 2,
              lineColor: '#800000',
            },
          ],
        },
        /* #endregion*/
        /* #region Section 3 */
        //Row 1
        {
          width: '33%',
          text: 'Pressure Settings',
          italics: true,
          fontSize: 8,
          margin: [0, 5, 0, 5], // [left, top, right, bottom]
        },
        //Row 2
        {
          table: {
            headerRows: 1,
            widths: [
              '*',
              '*',
              '*',
              '*',
              '*',
              '*',
              '*',
              '*',
              '*',
              '*',
              '*',
              '*',
              '*',
              '*',
              '*',
            ],
            body: [
              [
                {
                  text: 'Min Press',
                  fontSize: 6,
                  alignment: 'center',
                  bold: true,
                },
                {
                  text: 'Max Press',
                  fontSize: 6,
                  alignment: 'center',
                  bold: true,
                },
                {
                  text: 'Setting',
                  fontSize: 6,
                  alignment: 'center',
                  bold: true,
                },
                { text: 'IPAP', fontSize: 6, alignment: 'center', bold: true },
                { text: 'EPAP', fontSize: 6, alignment: 'center', bold: true },
                {
                  text: 'Ramp Press',
                  fontSize: 6,
                  alignment: 'center',
                  bold: true,
                },
                {
                  text: 'Ramp Time',
                  fontSize: 6,
                  alignment: 'center',
                  bold: true,
                },
                {
                  text: 'BiFlex',
                  fontSize: 6,
                  alignment: 'center',
                  bold: true,
                },
                {
                  text: 'Set Press',
                  fontSize: 6,
                  alignment: 'center',
                  bold: true,
                },
                { text: 'CFlex', fontSize: 6, alignment: 'center', bold: true },
                {
                  text: 'RR/BPM',
                  fontSize: 6,
                  alignment: 'center',
                  bold: true,
                },
                {
                  text: 'IPAP Min',
                  fontSize: 6,
                  alignment: 'center',
                  bold: true,
                },
                {
                  text: 'IPAP Max',
                  fontSize: 6,
                  alignment: 'center',
                  bold: true,
                },
                { text: 'SetVt', fontSize: 6, alignment: 'center', bold: true },
                { text: 'Rate', fontSize: 6, alignment: 'center', bold: true },
              ],
              [
                {
                  text: printTicketData?.settingsDetails?.minPressure ?? '',
                  fontSize: 6,
                  margin: [0, 5],
                  alignment: 'center',
                },
                {
                  text: printTicketData?.settingsDetails?.maxPressure ?? '',
                  fontSize: 6,
                  margin: [0, 5],
                  alignment: 'center',
                },
                {
                  text: printTicketData?.settingsDetails?.settlingTime ?? '',
                  fontSize: 6,
                  margin: [0, 5],
                  alignment: 'center',
                },
                {
                  text: printTicketData?.settingsDetails?.ipap ?? '',
                  fontSize: 6,
                  margin: [0, 5],
                  alignment: 'center',
                },
                {
                  text: printTicketData?.settingsDetails?.epap ?? '',
                  fontSize: 6,
                  margin: [0, 5],
                  alignment: 'center',
                },
                {
                  text: printTicketData?.settingsDetails?.rampPressure ?? '',
                  fontSize: 6,
                  alignment: 'center',
                  margin: [0, 5],
                },
                {
                  text: printTicketData?.settingsDetails?.rampTime ?? '',
                  fontSize: 6,
                  alignment: 'center',
                  margin: [0, 5],
                },
                {
                  text: printTicketData?.settingsDetails?.biFlex ?? '',
                  fontSize: 6,
                  margin: [0, 5],
                  alignment: 'center',
                },
                {
                  text: printTicketData?.settingsDetails?.setPressure ?? '',
                  fontSize: 6,
                  margin: [0, 5],
                  alignment: 'center',
                },
                {
                  text: printTicketData?.settingsDetails?.cFlex ?? '',
                  fontSize: 6,
                  margin: [0, 5],
                  alignment: 'center',
                },
                {
                  text: printTicketData?.settingsDetails?.rrbpm ?? '',
                  fontSize: 6,
                  margin: [0, 5],
                  alignment: 'center',
                },
                {
                  text: printTicketData?.settingsDetails?.ipaPmin ?? '',
                  fontSize: 6,
                  margin: [0, 5],
                  alignment: 'center',
                },
                {
                  text: printTicketData?.settingsDetails?.ipaPmax ?? '',
                  fontSize: 6,
                  margin: [0, 5],
                  alignment: 'center',
                },
                {
                  text: printTicketData?.settingsDetails?.setVT ?? '',
                  fontSize: 6,
                  margin: [0, 5],
                  alignment: 'center',
                },
                {
                  text: printTicketData?.settingsDetails?.rate ?? '',
                  fontSize: 6,
                  margin: [0, 5],
                  alignment: 'center',
                },
              ],
            ],
          },
        },
        //Row 3
        {
          columns: [
            {
              width: '33%',
              text: 'Technician: ' + printTicketData?.technicianName ?? '',
              fontSize: 8,
              alignment: 'center',
            },
            {
              width: '33%',
              text: 'Physician: ' + printTicketData?.physicianName ?? '',
              fontSize: 8,
              alignment: 'center',
            },
            {
              width: '33%',
              text:
                'Referral Practice: ' + printTicketData?.referralPractice ?? '',
              fontSize: 8,
              alignment: 'center',
            },
          ],
          margin: [0, 5, 0, 5], // [left, top, right, bottom]
        },
        /* #endregion */

        /*#region Section 4 */
        {
          table: {
            headerRows: 1,
            widths: ['10%', '12%', '44%', '10%', '9%', '15%'],
            body: [
              [
                { text: 'Qty', bold: true, fontSize: 10, color: '#800000' },
                {
                  text: 'Product Code',
                  bold: true,
                  fontSize: 10,
                  color: '#800000',
                },
                {
                  text: 'Product Description',
                  bold: true,
                  fontSize: 10,
                  color: '#800000',
                },
                {
                  text: 'HCPC',
                  bold: true,
                  fontSize: 10,
                  color: '#800000',
                },
                // { text: 'Size', bold: true, fontSize: 10, color: '#800000' },
                {
                  text: printTicketData?.isOrderComplete ? 'Allowable' : 'Exch',
                  bold: true,
                  fontSize: 10,
                  color: '#800000',
                },
                {
                  text: 'Serial #/Lot #',
                  bold: true,
                  fontSize: 10,
                  color: '#800000',
                },
              ],
              ...printTicketData?.productDetailsDTO?.map((item) => [
                // item.location,
                item.quantity,
                item.productCode,
                item.productName,
                // item.sizeName,
                item.hcpc,
                printTicketData?.isOrderComplete
                  ? item.allowables
                  : {
                      image:
                        'data:image/png;base64,' +
                        this.getSquareImageBase64(item.exchange),
                      width: 10,
                      height: 10,
                      alignment: 'center',
                    },
                item.serialLotNo,
              ]),
            ],
          },
          style: {
            fontSize: 10,
            margin: [0, 2],
          },
          margin: [0, 5, 0, 0],
        },
        /* #endregion */

        /* #region Section 5 */
        {
          columns: [
            {
              width: '60%',
              text: [
                {
                  text: '\nComments to Patients:\n',
                  color: '#800000',
                  fontSize: 12,
                },
                {
                  text: printTicketData?.commentsToPatient ?? '',
                  fontSize: 8,
                },
              ],
              fontSize: 14,
              bold: true,
              alignment: 'left',
            },

            {
              width: '40%',
              table: {
                headerRows: 1,
                // widths: ['80%', '20%'],
                widths: ['72%', '28%'],
                body: [
                  [
                    {
                      text: 'Est Allowable:',
                      fontSize: 10,
                      margin: [0, 0],
                      alignment: 'right',
                      border: [false, false, false, false],
                    },
                    {
                      text: '$' + printTicketData.estAllowable ?? '0',
                      fontSize: 10,
                      margin: [0, 0],
                      border: [false, false, false, false],
                      alignment: 'left',
                    },
                  ],
                  [
                    {
                      text: 'Est Copay:',
                      fontSize: 10,
                      margin: [0, 0],
                      alignment: 'right',
                      border: [false, false, false, false],
                    },
                    {
                      text: '$' + printTicketData.estCopay ?? '0',
                      fontSize: 10,
                      margin: [0, 0],
                      alignment: 'left',
                      border: [false, false, false, false],
                    },
                  ],
                  [
                    {
                      text: 'Total Accessory:',
                      fontSize: 10,
                      margin: [0, 0],
                      alignment: 'right',
                      border: [false, false, false, false],
                    },
                    {
                      text: '$' + printTicketData.totalAccessory ?? '0',
                      fontSize: 10,
                      margin: [0, 0],
                      alignment: 'left',
                      border: [false, false, false, false],
                    },
                  ],
                  [
                    {
                      text: 'Shipping Fee:',
                      fontSize: 10,
                      margin: [0, 0],
                      alignment: 'right',
                      border: [false, false, false, true],
                    },
                    {
                      text: '$' + printTicketData.shippingFee ?? '0',
                      fontSize: 10,
                      margin: [0, 0],
                      alignment: 'left',
                      border: [false, false, false, true],
                    },
                  ],

                  [
                    {
                      text: 'Est Amount Due:',
                      fontSize: 10,
                      margin: [0, 0],
                      alignment: 'right',
                      border: [false, false, false, false],
                    },
                    {
                      text: '$' + printTicketData.estAmountDue ?? '0',
                      fontSize: 10,
                      margin: [0, 0],
                      alignment: 'left',
                      border: [false, false, false, false],
                    },
                  ],
                  [
                    {
                      text: 'Amount Paid:',
                      fontSize: 10,
                      margin: [0, 0],
                      alignment: 'right',
                      border: [false, false, false, true],
                    },
                    {
                      text: '$' + printTicketData.amountPaid ?? '0',
                      fontSize: 10,
                      margin: [0, 0],
                      alignment: 'left',
                      border: [false, false, false, true],
                    },
                  ],
                  [
                    {
                      text: 'Balance:',
                      fontSize: 10,
                      margin: [0, 0],
                      alignment: 'right',
                      border: [false, false, false, false],
                    },
                    {
                      text: '$' + printTicketData.patientBalance ?? '0',
                      fontSize: 10,
                      margin: [0, 0],
                      alignment: 'left',
                      border: [false, false, false, false],
                    },
                  ],
                ],
              },
              layout: {
                hLineWidth: function (i, node) {
                  return i === 4 || i === 6 ? 1 : 0; // Thickness of the dotted line for the second row bottom border
                },
                hLineColor: function (i, node) {
                  return '#800000';
                },
                hLineStyle: function (i, node) {
                  if (i === 4 || i === 6) {
                    return { dash: { length: 5, space: 5 } }; // Dotted style for the second row bottom border
                  }
                  return null;
                },
              },
            },
          ],
        },
        /* #endregion*/
      ],
      /* #endregion */

      // pageMargins: [15, 50, 15, 40],
      pageMargins: [15, 60, 15, 60],
      defaultStyle: {
        font: 'Roboto',
      },
    };

    this.displayPdf(docDefinition, 'Ticket');
  }
  private getSquareImageBase64(excahngeItem): string {
    if (excahngeItem === true) {
      // Convert the image to Base64 format (using a base64 string or FileReader)
      const canvas = document.createElement('canvas');
      canvas.width = 10;
      canvas.height = 10;
      const ctx = canvas.getContext('2d');

      // Draw outer border with light sea green color
      ctx.fillStyle = '#000000'; // Light Sea Green color
      ctx.fillRect(0, 0, 12, 12); // Fill entire canvas with light sea green

      // Draw inner white square (to create border effect)
      ctx.fillStyle = '#FFFFFF'; // White color
      ctx.fillRect(1, 1, 8, 8); // Leave 1 pixel border around the canvas

      // Draw check mark (using a simple path)
      ctx.strokeStyle = '#20B2AA'; // Black color
      ctx.beginPath();
      ctx.moveTo(2, 4);
      ctx.lineTo(4, 6);
      ctx.lineTo(8, 2);
      ctx.stroke();

      return canvas.toDataURL('image/png').split(',')[1];
    } else {
      const canvas = document.createElement('canvas');
      canvas.width = 10;
      canvas.height = 10;
      const ctx = canvas.getContext('2d');
      ctx.fillStyle = '#000000'; // Border color
      ctx.fillRect(0, 0, 12, 12); // Fill entire canvas with black

      // Draw inner white square (to create border effect)
      ctx.fillStyle = '#FFFFFF'; // Square color
      ctx.fillRect(1, 1, 8, 8); // Leave 1 pixel border around the canvas
      return canvas.toDataURL('image/png').split(',')[1];
    }
  }
  displayPdf(docDefinition: any, filename: string): void {
    try {
      const pdfMake = window['pdfMake'];
      const pdfDocGenerator = pdfMake.createPdf(docDefinition); //.download(filename);

      // Open PDF in a new tab
      pdfDocGenerator.getBlob((blob: Blob) => {
        const dataUri = URL.createObjectURL(blob);
        window.open(dataUri, '_blank');
        URL.revokeObjectURL(dataUri);
      });

      // Optionally, you can add print and download buttons in your HTML
      // and trigger these actions using pdfMake functions:
      // pdfMake.createPdf(docDefinition).print();
      // pdfMake.createPdf(docDefinition).open();
    } catch (error) {
      Swal.fire({
        icon: 'error',
        text: 'Error creating PDF',
      });
    }
  }
  //#endregion
}

export function dateFormatter(strDate: string): string {
  if (typeof strDate === 'string' && strDate !== null && strDate !== undefined && strDate.length >= 1 && !isNaN(new Date(strDate).getTime())) {
    const options: Intl.DateTimeFormatOptions = { year: '2-digit', month: '2-digit', day: '2-digit', hour: "2-digit", minute: "2-digit" };
    const latest_date = new Intl.DateTimeFormat('en-US', options).format(new Date(strDate));
    return latest_date;
  }
  return "";
}

export function dateYYYYMMDDtoMMDDYYYY(strDate: string): string {
  if (strDate && strDate !== "" && typeof strDate === 'string' && strDate !== null && strDate !== undefined && strDate.length >= 1 && !isNaN(new Date(strDate).getTime())) {
    const latest_date = Intl.DateTimeFormat('en-US')?.format(new Date(strDate))
    return latest_date;
  }
  return "";
}

export interface Patient {
  id?: string;
  Name?: string;
  PatientId?: string;
  Address?: string;
  SSN?: string;
  AccountNumber?: string;
  EmailAddress?: string;
  DateOfBirth?: string;
  Moblie?: string;
  isAutoIntake?: number;
  autoIntakeDocumentDetails?: AutoIntakeDocumentDetailsDTO | null;
  LockStatus?: string;
  LockStatusWithName?: string;
  UserId?: string;
  highlighted?: boolean;
  currStatus: string

}

