<div class="container-fluid mt-2">
  <div class="row bg">
    <div class="col-12">
      <div class=" d-flex align-items-center justify-content-between mb-3">
        <h3 class="font-weight-bolder mb-0"><b>Existing Patients</b></h3>
        <div class=" page-title-box page-title-right  pt-3 pb-0">
          <ol class="breadcrumb m-0">
            <li>
              <!-- Existing Button for Download the List in Excel -->
              <button mat-button class="mr-2 buttonColor"
              [disabled]="(dataSource && dataSource?.data && dataSource?.data?.length === 0)"
              (click)="exportTableData()">
                Export
              </button>
              <button matTooltip="Click To Add New Patient" mat-button class="buttonColor font-size-10"
                (click)="newPatient()" routerLink="patientSearch/patientCreateEdit" routerLinkActive="active">
                <!-- <i class="mdi mdi-plus mr-1"></i> -->
                <i class="fa fa-plus mr-1" ></i>
                New Patient
              </button>
            </li>
            <li class="ml-2 mt-2 breadcrumb-item">Patient</li>
            <li class="mt-2 breadcrumb-item muted">Existing Patients</li>
          </ol>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="col-lg-12" style="margin-top: -10px;">
  <form [formGroup]="personalForm">
    <!-- <div [hidden]="!isShowProgress"
      style="display: flex; justify-content: center; align-items: center; background: transparent; color: lightseagreen;">
      <mat-progress-bar mode="indeterminate" strokeWidth="20" value='100' diameter="45" class="lightseagreen">
      </mat-progress-bar>
    </div> -->
    <div class="table table-responsive">
      <table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8">
        <ng-container matColumnDef="S.No">
          <th class="header" mat-header-cell *matHeaderCellDef>
            <mat-form-field class="filter">
              <input autocomplete="new-Sno" matInput formControlName="Sno" type="text" />
              <mat-placeholder class="center">S.No</mat-placeholder>
            </mat-form-field>
          </th>
          <td mat-cell *matCellDef="let element; let i=index">
            {{ i+1 }}
          </td>
        </ng-container>

        <!-- Name Column -->
        <ng-container matColumnDef="Name">
          <!-- <th mat-header-cell *matHeaderCellDef  mat-sort-header> Name </th> -->
          <th class="header" mat-header-cell *matHeaderCellDef>
            <mat-form-field class="filter">
              <input autocomplete="new-Name" matInput formControlName="Name" (keypress)="alphaOnly($event)" />
              <mat-placeholder>Name</mat-placeholder>
            </mat-form-field>
          </th>
          <td mat-cell *matCellDef="let element">
            <span matTooltip="{{element?.Name}}" matTooltipClass="custom-tooltip-font"> {{ element?.Name }}</span>
          </td>
        </ng-container>
        <!-- Patient Id Column -->
        <ng-container matColumnDef="Patient Id">
          <!-- <th mat-header-cell *matHeaderCellDef> Patient Id </th> -->
          <th class="header" mat-header-cell *matHeaderCellDef>
            <mat-form-field class="filter">
              <input autocomplete="new-PatientId" matInput formControlName="PatientId" />
              <mat-placeholder>Patient Id</mat-placeholder>
            </mat-form-field>
          </th>
          <td mat-cell *matCellDef="let element"> {{ element?.PatientId }}</td>
        </ng-container>

        <!-- Symbol Column -->
        <ng-container matColumnDef="Address">
          <!-- <th mat-header-cell *matHeaderCellDef> Address </th> -->
          <th class="header" fxFlex="200" mat-header-cell *matHeaderCellDef>
            <mat-form-field class="filter">
              <input autocomplete="new-Address" matInput formControlName="Address" />
              <mat-placeholder>Address</mat-placeholder>
            </mat-form-field>
          </th>
          <td fxFlex="200" mat-cell *matCellDef="let element"> <span class="ellipsis .cut-text">
              {{ element?.Address }} </span>
          </td>
        </ng-container>

        <ng-container matColumnDef="SSN">
          <!-- <th mat-header-cell *matHeaderCellDef> Account Number </th> -->
          <th class="header  w-175" mat-header-cell *matHeaderCellDef>
            <mat-form-field class="filter">
              <input autocomplete="new-SSN" matInput formControlName="SSN" (keypress)="numberOnly($event)" />
              <mat-placeholder>SSN</mat-placeholder>
            </mat-form-field>
          </th>
          <td mat-cell *matCellDef="let element">{{ element?.SSN }}</td>
        </ng-container>
        <ng-container matColumnDef="Account Number">

          <th class="header" mat-header-cell *matHeaderCellDef>
            <mat-form-field class="filter">
              <input autocomplete="new-AccountNumber" matInput formControlName="AccountNumber" (keypress)="numberOnly($event)" />
              <mat-placeholder>Account No</mat-placeholder>
            </mat-form-field>
          </th>
          <td mat-cell *matCellDef="let element">{{ element?.AccountNumber }}</td>
        </ng-container>
        <ng-container matColumnDef="Email Address">

          <th class="header" mat-header-cell *matHeaderCellDef>
            <mat-form-field class="filter">
              <input autocomplete="new-EmailAddress" matInput formControlName="EmailAddress" />
              <mat-placeholder>Email Address</mat-placeholder>

            </mat-form-field>
          </th>
          <td mat-cell *matCellDef="let element">{{ element?.EmailAddress }}</td>
        </ng-container>
        <ng-container matColumnDef="Date Of Birth">

          <th class="header" class="w-125" mat-header-cell *matHeaderCellDef>
            <mat-form-field class="filter">
              <mat-label> Date Of Birth </mat-label>
              <input autocomplete="new-DateOfBirth" matInput formControlName="DateOfBirth" placeholder="(MM/DD/YYYY)" />
              <mat-error
                *ngIf=" personalForm?.get('DateOfBirth')?.touched && personalForm?.get('DateOfBirth')?.errors?.dateVaidator">
                Enter Valid date
              </mat-error>
            </mat-form-field>
          </th>
          <td mat-cell *matCellDef="let element">{{ element?.DateOfBirth   | date: 'MM/dd/yyyy':'en_US' }}
          </td>
        </ng-container>

        <ng-container matColumnDef="Moblie">
          <th class="header" mat-header-cell *matHeaderCellDef>
            <mat-form-field class="filter">
              <input autocomplete="new-Moblie" matInput formControlName="Moblie" (keypress)="numberOnly($event)" />
              <mat-placeholder>Moblie</mat-placeholder>
            </mat-form-field>
          </th>
          <td mat-cell *matCellDef="let element">{{ element?.Moblie }}</td>
        </ng-container>
        <ng-container matColumnDef="currStatus">
          <th class="header" mat-header-cell *matHeaderCellDef>
            <mat-form-field class="filter">
              <input autocomplete="new-currStatus" matInput formControlName="currStatus" />
              <mat-placeholder>Current Status</mat-placeholder>
            </mat-form-field>
          </th>
          <td mat-cell *matCellDef="let element">
            <span matTooltip="{{element?.currStatus}}" matTooltipClass="custom-tooltip-font"> {{ element?.currStatus
              }}</span>
          </td>
        </ng-container>

        <ng-container matColumnDef="LockStatus">
          <th class="header" mat-header-cell *matHeaderCellDef>
            <mat-form-field class="filter">
              <input autocomplete="new-LockStatus" matInput formControlName="LockStatus" />
              <mat-placeholder>Lock Status </mat-placeholder>
            </mat-form-field>
          </th>
          <td mat-cell *matCellDef="let element">{{element?.LockStatusWithName==="undefined by undefined" ||
            element?.LockStatusWithName === null?"" :element?.LockStatusWithName}}</td>
        </ng-container>
        <ng-container matColumnDef="options">
          <th class="header" mat-header-cell *matHeaderCellDef>Options</th>
          <td mat-cell *matCellDef="let element" class="text-center">
            <a *ngIf='(element?.isAutoIntake===1 && element?.autoIntakeDocumentDetails)'
              matTooltip="Click To Download Document"
              (click)="downloadAutoUploadLastDocument(element?.autoIntakeDocumentDetails)">
              <i class="fa fa-download pointer editBtnColor pl-3" ></i>
            </a>

            <a *ngIf='isShownUpdateIconForPatient' (click)="viewPatient(element?.id)"
              matTooltip="Click To View Patient">
              <i class="fa fa-eye pointer editBtnColor pl-3" ></i>
            </a>
            <!--Old Code for lock & unlock-->
            <!-- <a *ngIf='isShownUpdateIconForPatient && ((element?.UserId != null) ||
                 (strLoginUserName === "admin") ||
                 (element?.UserId === null && element?.LockStatus === "UnLocked"  ||
                  element?.LockStatusWithName==="undefined by undefined"))'
                  (click)="viewPatient(element?.id)"
                  matTooltip="Click To View Patient">
                  <i class="fa fa-eye pointer editBtnColor pl-3" ></i>
                </a> -->
          </td>
        </ng-container>

        <ng-container matColumnDef="noRecords">
          <td class="text-align-center" mat-footer-cell *matFooterCellDef colspan="9">
            <div *ngIf="!isShowProgress">
              {{"No Records Found"}}
            </div>
            <div [hidden]="!isShowProgress">
              <mat-spinner [diameter]="220"></mat-spinner>
            </div>
          </td>
        </ng-container>

        <ng-container matColumnDef="paginator">
          <td mat-footer-cell *matFooterCellDef colspan="9">
            <mat-paginator class="table table-responsive" #MatPaginator [length]="dataSource?.data?.length"
              [pageSize]="10" [pageIndex]="0" showFirstLastButtons [pageSizeOptions]="[5, 10, 25, 100]">
            </mat-paginator>
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"
          [ngClass]="{'make-green': row.LockStatus === 'Locked' && row.UserId === null && strLoginUserName !== 'admin'}">
        </tr>
        <tr mat-footer-row *matFooterRowDef="['noRecords']"
          [ngClass]="{ active: dataSource && dataSource.data && dataSource?.data?.length !== 0 }"></tr>
        <tr mat-footer-row *matFooterRowDef="['paginator']"></tr>
      </table>
    </div>
  </form>
</div>
