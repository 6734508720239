import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbNav } from '@ng-bootstrap/ng-bootstrap';
import { TableService } from 'projects/shared/src/app/table.service';

import { DashboardService } from '../admin-proxy/platform-app-management/rcm/platform-management/dashboard/dashboard.service';
import { PermissionService } from '../admin-proxy/platform-app-management/rcm/platform-management/permission/permission.service';
import { UserService } from '../admin-proxy/platform-app-management/rcm/platform-management/user/user.service';

import { OrganizationUnitService } from '../admin-proxy/platform-app-management/rcm/platform-management/organization-units/organization-unit.service';
import { Subscription } from 'rxjs';
import { CommunicationService } from 'projects/shared/src/app/services/communication.service';
import { CommonService } from 'projects/shared/src/app/services/common.service';
@Component({
  selector: 'app-admin-create-edit',
  templateUrl: './admin-create-edit.component.html',
  styleUrls: ['./admin-create-edit.component.scss'],
})
export class AdminCreateEditComponent implements OnInit {
  clientId: string = '';
  strUrlPath: string = '';
  chkStatus: boolean = false;
  clientLogin: boolean = false;
  @ViewChild('nav') nav: NgbNav;
  isUsersViewPermission: boolean = true;
  isShownViewIconForUser: boolean = true;
  officialTabHideInUser: boolean;
  branchTabHideInUser: boolean;
  isShownRolesCreate: boolean;
  isShownRolesUpdate: boolean;
  isShownRolesView: boolean;
  isShownRolesDelete: boolean;
  isShownUserView: boolean;
  isShownUserCreate: boolean;
  isShownUserUpdate: boolean;
  isShownUserDelete: boolean;
  providerName: string = '';
  providerKey: string = '';
  isShownUsersMenu: boolean = true;
  isShownRolesMenu: boolean = true;
  isRolesViewPermission: boolean = true;
  isShownRolesViewTab: boolean = true;
  paypalTabHide: boolean = false;
  isShownUsersTab: boolean = true;
  isTabHidden: boolean = false;
  branchStatus:number;
  faxStatus:number;
  isLoading:boolean=false;
  branchDetails:any;
  faxDetails:any;
  tableData$: Subscription;
  tenantId: string = '';
  constructor(
    private permission: PermissionService,
    private activatedRoute: ActivatedRoute,
    public userProfileService: UserService,
    public title: Title,
    private tableService: TableService,
    private dashboardService: DashboardService,
    private router: Router,
    private branchService: OrganizationUnitService,
    private communicationService: CommunicationService,
    private commonService: CommonService
  ) {
    this.communicationService.functionLoadBranchList$.subscribe((statusView) => {
      this.branchStatusFilter(statusView);
    });
  }
  changeNextTab(value: string) {
    value === 'Official' && this.nav?.select('Branch');
    value === 'Branch' && this.nav?.select('Role');
    value === 'Role' && this.nav?.select('User');
  }

  ngOnInit(): void {
    this.tenantId = localStorage.getItem('tenantId') ?? '';
    this.dashboardService.userProfile().subscribe((response) => {
      this.tableService.setclientLoginView(response);

      if (response?.tenantId == null) {
        this.isTabHidden = true;
      }


      if (
        response?.tenantId != null &&
        response?.roleName == 'admin' &&
        response?.organizationUnitId == null
      ) {
        this.clientLogin = false;
        localStorage.setItem("isAdmin", '0');

        this.clientId = response?.tenantId ?? '';
        this.paypalTabHide = true;
      } else if (response.tenantId === null && response.roleName == 'admin') {
        this.nav?.select('Official');
        localStorage.setItem("isAdmin", '1');
        this.clientLogin = true;
        this.activatedRoute.paramMap.subscribe((response) => {
          this.clientId = response?.get('id') ?? '';
        });
        this.activatedRoute.fragment.subscribe((response) => {
          this.chkStatus = response === 'InActive' ? true : false;
          // this.clientId = response.get('id');
        });
        this.activatedRoute.url.subscribe((value) => {
          if (value[0]?.path === 'clientCreate') {
            this.strUrlPath = 'clientCreate';
            this.title.setTitle('Qsecure | Create Admin');
          } else {
            this.strUrlPath = 'clientView';
            this.title.setTitle('Qsecure | View Admin');
          }
        });
      } else {
        this.tableService.getRolesCreate().subscribe(val => {
          this.isShownRolesCreate = val;
        });
        this.tableService.getRolesUpdate().subscribe(val => {
          this.isShownRolesUpdate = val;
        });
        this.tableService.getRolesDelete().subscribe(val => {
          this.isShownRolesDelete = val;
        });

        this.tableService.getUsersCreate().subscribe(val => {
          this.isShownUserCreate = val;
        });
        this.tableService.getUsersUpdate().subscribe(val => {
          this.isShownUserUpdate = val;
        });
        this.tableService.getUsersDelete().subscribe(val => {
          this.isShownUserDelete = val;
        });

        if (this.isShownUserCreate === false &&
          this.isShownUserUpdate === false &&
          this.isShownUserDelete === false) {
          this.isShownUsersTab = false;
        }
        else {
          this.isShownUsersTab = true;
        }

        if (
          this.isShownRolesCreate === false &&
          this.isShownRolesUpdate === false &&
          this.isShownRolesDelete === false &&
          this.isShownUsersTab === true
        ) {
          this.nav.select('User');
          this.isShownRolesViewTab = false;
        } else {
          this.nav.select('Role');
          this.isShownRolesViewTab = true;
        }
        this.permission.getCurrentUserPermission().subscribe((response) => {
          this.activatedRoute.paramMap.subscribe((response) => {
            this.clientId = response.get('id');
          });
        });
        localStorage.setItem("isAdmin", '0');
      }
      // });

      // official tab hide in user
      // this.userProfileService.userProfile().subscribe((response) => {
      //   this.tableService.setclientLoginView(response);
      if (
        response.userId != null &&
        response.roleName != 'admin' &&
        response.organizationUnitId != null
      ) {
        this.officialTabHideInUser = false;
      } else {
        this.officialTabHideInUser = true;
      }
      if (
        response.userId != null &&
        response.roleName != 'admin' &&
        response.organizationUnitId != null &&
        response.tenantId != null
      ) {
        this.branchTabHideInUser = false;
      } else {
        if (response.tenantName == 'admin' || response.tenantName == null) {
          this.branchTabHideInUser = true;
        } else {
          this.nav.select('Branch');
          this.branchTabHideInUser = true;
        }
      }
    });
    // branch tab hide in user
    // this.userProfileService.userProfile().subscribe(response => {
    //   this.tableService.setclientLoginView(response)
    //   if (response.userId != null && response.roleName != 'admin' && response.organizationUnitId != null && response.tenantId != null) {

    //     this.branchTabHideInUser = false;
    //   } else {
    //     if (response.tenantName == "admin" || response.tenantName == null) {
    //       this.branchTabHideInUser = true;
    //     } else {
    //       this.nav.select("Branch");
    //       this.branchTabHideInUser = true;
    //     }
    //   }

    // })

    //role table hide
    // this.tableService.getRolesView().subscribe(value => {
    //   this.isRolesViewPermission = value;

    //   if (this.isRolesViewPermission == true) {

    //     this.nav.select("Role");
    //     this.isShownRolesViewTab = true;
    //   } else {
    //     this.isShownRolesViewTab = false;
    //   }
    // }, err => {
    //   const data: HttpErrorResponse = err;
    //   Swal.fire({
    //     icon: 'error',
    //     text: data?.error?.error?.message,
    //   });
    // });

    // // user tab hide
    // this.tableService.getUsersView().subscribe(value => {

    //   this.isUsersViewPermission = value;

    //   if (this.isUsersViewPermission == true) {
    //     this.nav.select("User");
    //     this.isShownViewIconForUser = true;
    //   } else {
    //     this.isShownViewIconForUser = false;
    //   }
    // }, err => {
    //   const data: HttpErrorResponse = err;
    //   Swal.fire({
    //     icon: 'error',
    //     text: data?.error?.error?.message,
    //   });
    // });
    this.branchStatusFilter(1);
  }

  getClientIdOnSave(id: string) {
    if (id == '') return;
    if (typeof id === 'string') {
      this.clientId = id.replace('"', '').replace('"', '');
    }
  }

  backtoClientList() {
    this.router.navigate(['/clientList'])
  }


  //Settings Branch Get Table List
  branchStatusFilter(status: number) {
    this.isLoading = true;
    this.tableData$?.unsubscribe();
    const branchData = this.branchService.getbranchStatusFilter(status,this.tenantId).subscribe(
      (response) => {
        this.branchDetails = response?.items;
        this.branchDetails = this.branchDetails.map((data) => {
          return {
            ...data,
            creationTime: this.commonService.getFormattedDateTimeZone(
              data.creationTime
            ),
            lastModificationTime: this.commonService.getFormattedDateTimeZone(
              data.lastModificationTime
            ),
          };
        });
        this.branchStatus=status;
        this.isLoading = false;
      },
      (error) => {
        console.error('Error fetching branch details:', error);
        this.isLoading = false;
      }
    );
    this.tableData$ = branchData;
  }

}
