import { DatePipe } from '@angular/common';
import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  Renderer2,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { PatientCommonNotesComponent } from 'projects/shared/src/app/components/patient-common-notes/patient-common-notes.component';
import { CommonService } from 'projects/shared/src/app/services/common.service';
import { HttpErrorResponse } from '@angular/common/http';
import { ViewPatientLedgerComponent } from '../../view-patient-ledger/view-patient-ledger.component';
import { defaultGuid, MyQueueBinStatus } from 'projects/shared/src/app/enums/allenums.enum';
import { DataTableDirective } from 'angular-datatables';

@Component({
  selector: 'app-order-bin-que-table',
  templateUrl: './order-bin-que-table.component.html',
  styleUrls: ['./order-bin-que-table.component.scss'],
})
export class OrderBinQueTableComponent implements OnInit {
  @Input() orderTableData: any;
  @Input() activeTabBin: any;
  @Input() currentPage: any = 1;
  @Input() totalPages: any = 1;
  @Input() paginationInfo: { startRecord: number, endRecord: number, overallTableCount:number };
  @Output() nevigatePage? = new EventEmitter<number>();
  MyQueueBinStatus = MyQueueBinStatus;

  @ViewChild('tableOrderContainer', { read: ViewContainerRef })
  tableOrderContainer: ViewContainerRef;
  myTable2: DataTables.Api;
  @ViewChild(DataTableDirective, { static: false })
  datatableElement2: DataTableDirective;
  dtDocumentBinTableOptions1:any;
  dtDocumentBinTableOptions2:any;
  dtDocumentBinTableOptions3:any;

  // dtDocumentBinTableOptions1: any = {
  //   responsive: true,
  //   paging: false,
  //   dom: 'Bfrtip',
  //   scrollCollapse: true,
  //   scrollY: '500px',
  //   scrollX: true,
  //   search: {
  //     smart: false,
  //   },
  //   language: {
  //     search: '',
  //     searchPlaceholder: 'Search Here',
  //     emptyTable: 'No records',
  //     info: 'Total : _MAX_ records',
  //     infoEmpty: 'Total : _MAX_ records',
  //     infoFiltered: '(filtered : _TOTAL_ records)',
  //     loadingRecords: 'Loading...',
  //     zeroRecords: 'No matching records',
  //   },
  //   order: [0],
  //   columnDefs: [
  //     { targets: [8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18], visible: false },
  //     { targets: [0], orderable: false },
  //   ],
  //   // ordering: false,
  //   buttons: [
  //     {
  //       extend: 'excel',
  //       text: 'Export',
  //       filename: '',
  //       className: 'custom-dt-export-button',
  //       exportOptions: {
  //         columns: ':not(:first-child) ',
  //       },
  //     },
  //   ],
  //   initComplete: function (this: any) {
  //     //Search Input Alignment
  //     const searchInput = document.querySelector(
  //       '.dataTables_wrapper .dataTables_filter'
  //     ) as HTMLElement;
  //     if (searchInput) {
  //       searchInput.style.float = 'left';
  //     }

  //     //Search Input Design and Icon
  //     const searchFilterInput = document.querySelector(
  //       '.dataTables_filter input'
  //     ) as HTMLInputElement;
  //     if (searchFilterInput) {
  //       const searchContainer = document.createElement('div');
  //       searchContainer.className = 'search-container';
  //       searchFilterInput.style.paddingLeft = '25px';
  //       searchFilterInput.style.fontStyle = 'italic';
  //       searchFilterInput.style.fontSize = '12px';

  //       // Add event listeners for focusin and focusout
  //       searchFilterInput.addEventListener('focus', () => {
  //         searchFilterInput.style.border = '1px solid lightseagreen';
  //         searchFilterInput.style.outline = 'lightseagreen';
  //       });

  //       searchFilterInput.addEventListener('blur', () => {
  //         searchFilterInput.style.border = '';
  //         searchFilterInput.style.outline = '';
  //       });

  //       searchFilterInput.parentNode?.insertBefore(
  //         searchContainer,
  //         searchFilterInput
  //       );
  //       searchContainer.appendChild(searchFilterInput);

  //       const searchIcon = document.createElement('i');
  //       searchIcon.className = 'fa fa-search';
  //       searchIcon.style.position = 'absolute';
  //       searchIcon.style.left = '10px';
  //       searchIcon.style.top = '16px';
  //       searchIcon.style.transform = 'translateY(-50%)';
  //       searchIcon.style.pointerEvents = 'none';
  //       searchIcon.style.color = '#aaa';

  //       searchContainer.appendChild(searchIcon);
  //     }
  //   },
  // };

  // dtDocumentBinTableOptions2: any = {
  //   responsive: true,
  //   paging: false,
  //   dom: 'Bfrtip',
  //   scrollCollapse: true,
  //   scrollY: '500px',
  //   scrollX: true,
  //   search: {
  //     smart: false,
  //   },
  //   language: {
  //     search: '',
  //     searchPlaceholder: 'Search Here',
  //     emptyTable: 'No records',
  //     info: 'Total : _MAX_ records',
  //     infoEmpty: 'Total : _MAX_ records',
  //     infoFiltered: '(filtered : _TOTAL_ records)',
  //     loadingRecords: 'Loading...',
  //     zeroRecords: 'No matching records',
  //   },
  //   order: [0],
  //   columnDefs: [
  //     { targets: [7, 8, 9, 10, 11, 12, 13, 14], visible: false },
  //     { targets: [0], orderable: false },
  //   ],
  //   // ordering: false,
  //   buttons: [
  //     {
  //       extend: 'excel',
  //       text: 'Export',
  //       className: 'custom-dt-export-button',
  //       filename: '',
  //       exportOptions: {
  //         columns: ':not(:first-child) ',
  //       },
  //     },
  //   ],
  //   initComplete: function (this: any) {
  //     const searchInput = document.querySelector(
  //       '.dataTables_wrapper .dataTables_filter'
  //     ) as HTMLElement;
  //     if (searchInput) {
  //       searchInput.style.float = 'left';
  //     }

  //     const searchFilterInput = document.querySelector(
  //       '.dataTables_filter input'
  //     ) as HTMLInputElement;
  //     if (searchFilterInput) {
  //       const searchContainer = document.createElement('div');
  //       // searchContainer.className = 'search-container';
  //       searchContainer.style.position = 'relative';
  //       searchContainer.style.display = 'inline-block';
  //       // searchContainer.style.paddingLeft = '30px';
  //       searchContainer.style.fontStyle = 'italic';
  //       searchContainer.style.fontSize = '14px';
  //       searchContainer.style.fontFamily = 'none';
  //       searchFilterInput.parentNode?.insertBefore(
  //         searchContainer,
  //         searchFilterInput
  //       );
  //       searchContainer.appendChild(searchFilterInput);

  //       // const searchIcon = document.createElement('i');
  //       // searchIcon.className = 'fa fa-search'; //search-icon

  //       // searchIcon.style.position = 'absolute';
  //       // searchIcon.style.left = '10px';
  //       // searchIcon.style.top = '17px';
  //       // searchIcon.style.transform = 'translateY(-50%)';
  //       // searchIcon.style.pointerEvents = 'none';
  //       // searchIcon.style.color = '#aaa';

  //       // searchContainer.appendChild(searchIcon);
  //     }
  //   },
  // };

  // dtDocumentBinTableOptions3: any = {
  //   responsive: true,
  //   paging: false,
  //   dom: 'Bfrtip',
  //   scrollCollapse: true,
  //   scrollY: '500px',
  //   scrollX: true,
  //   search: {
  //     smart: false,
  //   },
  //   language: {
  //     search: '',
  //     searchPlaceholder: 'Search Here',
  //     emptyTable: 'No records',
  //     info: 'Total : _MAX_ records',
  //     infoEmpty: 'Total : _MAX_ records',
  //     infoFiltered: '(filtered : _TOTAL_ records)',
  //     loadingRecords: 'Loading...',
  //     zeroRecords: 'No matching records',
  //   },
  //   order: [0],
  //   columnDefs: [
  //     { targets: [7, 8, 9, 10, 11, 12, 13, 14, 15, 16], visible: false },
  //     { targets: [0], orderable: false },
  //   ],
  //   // ordering: false,
  //   buttons: [
  //     {
  //       extend: 'excel',
  //       text: 'Export',
  //       className: 'custom-dt-export-button',
  //       filename: '',
  //       exportOptions: {
  //         columns: ':not(:first-child) ',
  //       },
  //     },
  //   ],
  //   initComplete: function (this: any) {
  //     const searchInput = document.querySelector(
  //       '.dataTables_wrapper .dataTables_filter'
  //     ) as HTMLElement;
  //     if (searchInput) {
  //       searchInput.style.float = 'left';
  //     }

  //     const searchFilterInput = document.querySelector(
  //       '.dataTables_filter input'
  //     ) as HTMLInputElement;
  //     if (searchFilterInput) {
  //       const searchContainer = document.createElement('div');
  //       // searchContainer.className = 'search-container';
  //       searchContainer.style.position = 'relative';
  //       searchContainer.style.display = 'inline-block';
  //       // searchContainer.style.paddingLeft = '30px';
  //       searchContainer.style.fontStyle = 'italic';
  //       searchContainer.style.fontSize = '14px';
  //       searchContainer.style.fontFamily = 'none';
  //       searchFilterInput.parentNode?.insertBefore(
  //         searchContainer,
  //         searchFilterInput
  //       );
  //       searchContainer.appendChild(searchFilterInput);

  //       // const searchIcon = document.createElement('i');
  //       // searchIcon.className = 'fa fa-search'; //search-icon

  //       // searchIcon.style.position = 'absolute';
  //       // searchIcon.style.left = '10px';
  //       // searchIcon.style.top = '17px';
  //       // searchIcon.style.transform = 'translateY(-50%)';
  //       // searchIcon.style.pointerEvents = 'none';
  //       // searchIcon.style.color = '#aaa';

  //       // searchContainer.appendChild(searchIcon);
  //     }
  //   },
  // };
  constructor(
    private snackBar: MatSnackBar,
    private renderer: Renderer2,
    private commonService: CommonService,
    private dialog: MatDialog,
    private datepipe: DatePipe
  ) {}

  ngOnInit(): void {
    this.dataTableInitilization();
    this.dtDocumentBinTableOptions1.buttons[0].filename =
      this.activeTabBin +
      '-' +
      this.datepipe.transform(new Date(), 'MM-dd-yyyy');
    this.dtDocumentBinTableOptions2.buttons[0].filename =
      this.activeTabBin +
      '-' +
      this.datepipe.transform(new Date(), 'MM-dd-yyyy');
    this.dtDocumentBinTableOptions3.buttons[0].filename =
      this.activeTabBin +
      '-' +
      this.datepipe.transform(new Date(), 'MM-dd-yyyy');

  }

  @ViewChild(DataTableDirective, { static: false })
  datatableElement: DataTableDirective;

  ngAfterViewInit(): void {
    if (this.datatableElement && this.datatableElement.dtInstance) {
      this.refreshDataTable();
    } else {
      setTimeout(() => this.refreshDataTable(), 500);
    }
  }

  dataTableInitilization(){
    this.dtDocumentBinTableOptions1= {
      responsive: true,
      paging: false,
      dom: 'Bfrtip',
      scrollCollapse: true,
      scrollY: '500px',
      scrollX: true,
      search: {
        smart: false,
      },
      language: {
        search: '',
        searchPlaceholder: 'Search Here',
        emptyTable: 'No records',
        // info: 'Total : _MAX_ records',
        info: `Showing ${this.paginationInfo?.startRecord ?? 0} to ${this.paginationInfo?.endRecord ?? 0} of ${this.paginationInfo?.overallTableCount ?? 0} records`,
        infoEmpty: 'Total : _MAX_ records',
        infoFiltered: '(filtered : _TOTAL_ records)',
        loadingRecords: 'Loading...',
        zeroRecords: 'No matching records',
      },
      order: [0],
      columnDefs: [
        { targets: [8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18], visible: false },
        { targets: [0], orderable: false },
      ],
      // ordering: false,
      buttons: [
        {
          extend: 'excel',
          text: 'Export',
          filename: '',
          className: 'custom-dt-export-button',
          exportOptions: {
            columns: ':not(:first-child) ',
          },
        },
      ],
      initComplete: function (this: any) {
        //Search Input Alignment
        const searchInput = document.querySelector(
          '.dataTables_wrapper .dataTables_filter'
        ) as HTMLElement;
        if (searchInput) {
          searchInput.style.float = 'left';
        }

        //Search Input Design and Icon
        const searchFilterInput = document.querySelector(
          '.dataTables_filter input'
        ) as HTMLInputElement;
        if (searchFilterInput) {
          const searchContainer = document.createElement('div');
          searchContainer.className = 'search-container';
          searchFilterInput.style.paddingLeft = '25px';
          searchFilterInput.style.fontStyle = 'italic';
          searchFilterInput.style.fontSize = '12px';

          // Add event listeners for focusin and focusout
          searchFilterInput.addEventListener('focus', () => {
            searchFilterInput.style.border = '1px solid lightseagreen';
            searchFilterInput.style.outline = 'lightseagreen';
          });

          searchFilterInput.addEventListener('blur', () => {
            searchFilterInput.style.border = '';
            searchFilterInput.style.outline = '';
          });

          searchFilterInput.parentNode?.insertBefore(
            searchContainer,
            searchFilterInput
          );
          searchContainer.appendChild(searchFilterInput);

          const searchIcon = document.createElement('i');
          searchIcon.className = 'fa fa-search';
          searchIcon.style.position = 'absolute';
          searchIcon.style.left = '10px';
          searchIcon.style.top = '16px';
          searchIcon.style.transform = 'translateY(-50%)';
          searchIcon.style.pointerEvents = 'none';
          searchIcon.style.color = '#aaa';

          searchContainer.appendChild(searchIcon);
        }
      },
    };

    this.dtDocumentBinTableOptions2 = {
      responsive: true,
      paging: false,
      dom: 'Bfrtip',
      scrollCollapse: true,
      scrollY: '500px',
      scrollX: true,
      search: {
        smart: false,
      },
      language: {
        search: '',
        searchPlaceholder: 'Search Here',
        emptyTable: 'No records',
        info: `Showing ${this.paginationInfo?.startRecord ?? 0} to ${this.paginationInfo?.endRecord ?? 0} of ${this.paginationInfo?.overallTableCount ?? 0} records`,
        infoEmpty: 'Total : _MAX_ records',
        infoFiltered: '(filtered : _TOTAL_ records)',
        loadingRecords: 'Loading...',
        zeroRecords: 'No matching records',
      },
      order: [0],
      columnDefs: [
        { targets: [7, 8, 9, 10, 11, 12, 13, 14], visible: false },
        { targets: [0], orderable: false },
      ],
      // ordering: false,
      buttons: [
        {
          extend: 'excel',
          text: 'Export',
          className: 'custom-dt-export-button',
          filename: '',
          exportOptions: {
            columns: ':not(:first-child) ',
          },
        },
      ],
      initComplete: function (this: any) {
        const searchInput = document.querySelector(
          '.dataTables_wrapper .dataTables_filter'
        ) as HTMLElement;
        if (searchInput) {
          searchInput.style.float = 'left';
        }

        const searchFilterInput = document.querySelector(
          '.dataTables_filter input'
        ) as HTMLInputElement;
        if (searchFilterInput) {
          const searchContainer = document.createElement('div');
          // searchContainer.className = 'search-container';
          searchContainer.style.position = 'relative';
          searchContainer.style.display = 'inline-block';
          // searchContainer.style.paddingLeft = '30px';
          searchContainer.style.fontStyle = 'italic';
          searchContainer.style.fontSize = '14px';
          searchContainer.style.fontFamily = 'none';
          searchFilterInput.parentNode?.insertBefore(
            searchContainer,
            searchFilterInput
          );
          searchContainer.appendChild(searchFilterInput);
        }
      },
    };

    this.dtDocumentBinTableOptions3 = {
      responsive: true,
      paging: false,
      dom: 'Bfrtip',
      scrollCollapse: true,
      scrollY: '500px',
      scrollX: true,
      search: {
        smart: false,
      },
      language: {
        search: '',
        searchPlaceholder: 'Search Here',
        emptyTable: 'No records',
        info: `Showing ${this.paginationInfo?.startRecord ?? 0} to ${this.paginationInfo?.endRecord ?? 0} of ${this.paginationInfo?.overallTableCount ?? 0} records`,
        infoEmpty: 'Total : _MAX_ records',
        infoFiltered: '(filtered : _TOTAL_ records)',
        loadingRecords: 'Loading...',
        zeroRecords: 'No matching records',
      },
      order: [0],
      columnDefs: [
        { targets: [7, 8, 9, 10, 11, 12, 13, 14, 15, 16], visible: false },
        { targets: [0], orderable: false },
      ],
      // ordering: false,
      buttons: [
        {
          extend: 'excel',
          text: 'Export',
          className: 'custom-dt-export-button',
          filename: '',
          exportOptions: {
            columns: ':not(:first-child) ',
          },
        },
      ],
      initComplete: function (this: any) {
        const searchInput = document.querySelector(
          '.dataTables_wrapper .dataTables_filter'
        ) as HTMLElement;
        if (searchInput) {
          searchInput.style.float = 'left';
        }

        const searchFilterInput = document.querySelector(
          '.dataTables_filter input'
        ) as HTMLInputElement;
        if (searchFilterInput) {
          const searchContainer = document.createElement('div');
          // searchContainer.className = 'search-container';
          searchContainer.style.position = 'relative';
          searchContainer.style.display = 'inline-block';
          // searchContainer.style.paddingLeft = '30px';
          searchContainer.style.fontStyle = 'italic';
          searchContainer.style.fontSize = '14px';
          searchContainer.style.fontFamily = 'none';
          searchFilterInput.parentNode?.insertBefore(
            searchContainer,
            searchFilterInput
          );
          searchContainer.appendChild(searchFilterInput);
        }
      },
    };
  }
  refreshDataTable(): void {
    this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
      // Clear search input values
      $('input', dtInstance.table(0).footer()).val('');
      // Redraw the DataTable to load all records
      dtInstance.search('').columns().search('').draw();
      this.addPageNavigationControls();
    });
  }

  addPageNavigationControls() {
    let totalPages = this.totalPages;
    let currentPage = this.currentPage;
    let buttonsElement = $('.dt-buttons')[0];
    // $("<span> : <span>").appendTo(buttonsElement);
    if (this.currentPage == 1) {
      $(
        "<button disabled title='First Page' class='dt-button dt-custom-pagination-buttons disabled' id='btnFirstPage' type='button'><span>&lt;&lt;</span></button>"
      ).appendTo(buttonsElement);
      $(
        "<button disabled class='dt-button dt-custom-pagination-buttons disabled' id='btnPrevPage' type='button'><span>&lt;</span></button>"
      ).appendTo(buttonsElement);
    } else {
      $(
        "<button title='First Page' class='dt-button dt-custom-pagination-buttons' id='btnFirstPage' type='button'><span>&lt;&lt;</span></button>"
      ).appendTo(buttonsElement);
      $(
        "<button title='Previous Page' class='dt-button dt-custom-pagination-buttons' id='btnPrevPage' type='button'><span>&lt;</span></button>"
      ).appendTo(buttonsElement);
    }
    // style='float:left !important;'
    $("<span class='dt-pagination-input-span' > Page <span>").appendTo(
      buttonsElement
    );
    $(
      "<input class='dt-pagination-input' maxlength='6' id='txtGotoPage' value='" +
        this.currentPage +
        "' type='text'/>"
    ).appendTo(buttonsElement);

    ////Input Restrictions on pagination text box
    $('#txtGotoPage').on('input', function (e) {
      let value = (this as HTMLInputElement).value;
      value = value.replace(/[^0-9]/g, '');
      if (value.startsWith('0')) {
        value = value.substring(1);
      }
      //Invalid page number
      if ((value==''?currentPage:value) > totalPages || (value==''?currentPage:value) == currentPage) {
        $('#btnGoToPage').addClass('disabled');
      } else if(value!=currentPage && value <= totalPages) {
        $('#btnGoToPage').removeClass('disabled');
      }
      (this as HTMLInputElement).value = value;
    });
    //If the user leaves empty text box patch current page
    $('#txtGotoPage').on('focusout', function (e) {
      let value = (this as HTMLInputElement).value;
      if (value == '') {
        (this as HTMLInputElement).value = currentPage;
      }
    });

    $(
      "<span class='dt-pagination-input-span pl-1'> of " +
        this.totalPages +
        ' <span>'
    ).appendTo(buttonsElement);
    $(
      "<button  class='dt-button dt-custom-pagination-buttons-goto disabled' id='btnGoToPage'   type='button'><span>Go to</span></button>"
    ).appendTo(buttonsElement);

    if (this.currentPage >= this.totalPages) {
      $(
        "<button disabled class='dt-button dt-custom-pagination-buttons disabled' id='btnNextPage'   type='button'><span>&gt;</span></button>"
      ).appendTo(buttonsElement);
      $(
        "<button disabled title='Last Page'  class='dt-button dt-custom-pagination-buttons disabled' id='btnLastPage'  type='button'><span>&gt;&gt</span></button>"
      ).appendTo(buttonsElement);
    } else {
      $(
        "<button title='Next Page'  class='dt-button dt-custom-pagination-buttons' id='btnNextPage'  type='button'><span>&gt;</span></button>"
      ).appendTo(buttonsElement);
      $(
        "<button title='Last Page'  class='dt-button dt-custom-pagination-buttons' id='btnLastPage'  type='button'><span>&gt;&gt</span></button>"
      ).appendTo(buttonsElement);
    }

    $('#btnNextPage').on('click', (event) => this.changePage(true));
    $('#btnPrevPage').on('click', (event) => this.changePage(false));
    $('#btnGoToPage').on('click', (event) => this.fnGotoPage());
    $('#btnFirstPage').on('click', (event) => this.nevigatePage.emit(1));
    $('#btnLastPage').on('click', (event) =>
      this.nevigatePage.emit(this.totalPages)
    );

    // $(".buttons-colvis").on('click', (event) => this.fnColumnSelectionClick());
    // $('#btnDefaultClms').on('click', (event) => this.fnGetColumnVisiblity("DEFAULT"));
    // $('#btnAllClms').on('click', (event) => this.fnGetColumnVisiblity("ALL"));
  }

  changePage(isNext: boolean) {
    if (isNext) this.currentPage++;
    else this.currentPage--;
    this.nevigatePage.emit(this.currentPage);
  }

  fnGotoPage() {
    let pageValue = $('#txtGotoPage').val();
    let pageno = parseInt(pageValue.toString());
    if (pageno > 0 && this.currentPage != pageno && pageno <= this.totalPages) {
      this.nevigatePage.emit(pageno);
    }
  }

  //#region DoubleClick Copy Text
  onRowDoubleClick(textToCopy) {
    this.copyTextToClipboard(textToCopy);
    this.showNotification('Text copied: ' + textToCopy);
  }

  showNotification(message: string): void {
    this.snackBar.open(message, 'Close', {
      duration: 3000, // Duration in milliseconds
      horizontalPosition: 'end', // Positioning the notification horizontally
      verticalPosition: 'bottom', // Positioning the notification vertically
    });
  }

  copyTextToClipboard(text: string): void {
    const textArea = document.createElement('textarea');
    textArea.value = text;
    document.body.appendChild(textArea);
    textArea.select();
    document.execCommand('Copy');
    textArea.remove();
  }
  //#endRegio DoubleClick Copy Text

  //DataTable Data Get Method and Toggle Notes Row in Table
  getTotalInboundData(event: Event, inboundData: any) {
    // console.log(inboundData);
    const target = event.target as HTMLElement;
    const tr = target.closest('tr');
    const icon = target as HTMLElement;
    this.getDataTableData2(tr, inboundData, icon);
  }

  async getDataTableData2(
    tr: any,
    inboundData: any,
    icon: HTMLElement
  ): Promise<void> {
    this.myTable2 = await this.datatableElement2.dtInstance;
    if (tr) {
      const row = this.myTable2.row(tr);

      if (row.child.isShown()) {
        // This row is already open - close it
        row.child.hide();
        icon.innerText = 'add';
      } else {
        // Open this row
        let formattedData;
        switch (this.activeTabBin) {
          case MyQueueBinStatus.Resupply:
            formattedData = this.formatResupply(row.data(), inboundData);
            break;
          case MyQueueBinStatus.SaleOrder:
            formattedData = this.formatSaleOrder(row.data(), inboundData);
            break;
          case MyQueueBinStatus.Shipping:
            formattedData = this.formatShipping(row.data(), inboundData);
            break;
          case MyQueueBinStatus.Billing:
            formattedData = this.formatBilling(row.data(), inboundData);
            break;
        }
        if (formattedData) {
          row.child(formattedData).show();
          row.child().addClass('no-padding');
          icon.innerText = 'remove';
        }
      }
    }
  }

  //Add Dynamic Row Resupply Bin
  formatResupply(data: any, inboundData: any): HTMLElement {
    const table = this.renderer.createElement('table');
    const thead = this.renderer.createElement('thead');
    const tbody = this.renderer.createElement('tbody');

    const thRow = this.renderer.createElement('tr');
    const headers = [
      { text: 'Primary Insurance', key: 'primaryinsurance' },
      { text: 'Last Mask Date', key: 'lastmaskdate' },
      { text: 'Points', key: 'points' },
      { text: 'Exchange / Return', key: 'exchagereturn' },
      { text: 'Billing Order Type', key: 'billingordertype' },
      { text: 'Payment Status', key: 'paymentstatus' },
      { text: 'Hello Sign Status', key: 'hellosignstatus' },
      { text: 'Created By', key: 'createdby' },
      { text: 'Created Date', key: 'createddate' },
      { text: 'Modified By', key: 'modifiedby' },
      { text: 'Modified Date', key: 'modifieddate' },
    ];

    headers.forEach((header) => {
      const th = this.renderer.createElement('th');
      this.renderer.appendChild(th, document.createTextNode(header.text));
      th.setAttribute('title', header.text);
      this.renderer.appendChild(thRow, th);
    });

    this.renderer.appendChild(thead, thRow);
    this.renderer.appendChild(table, thead);
    this.renderer.setStyle(table, 'backgroundColor', '#fbfcfe');

    if (!Array.isArray(inboundData)) {
      inboundData = [inboundData];
    }

    inboundData.forEach((value: any) => {
      const trData = this.renderer.createElement('tr');

      const cells = [
        { text: value?.policyName || '-', key: 'primaryinsurance' },
        { text: value.lastMaskProcessDate || '-', key: 'lastmaskdate' },
        { text: value.orderPointsCAL || '-', key: 'points' },
        { text: value.exchangeOrReturn || '-', key: 'exchagereturn' },
        { text: value.billingordertype || '-', key: 'billingordertype' },
        { text: value.paymentStatus || '-', key: 'paymentstatus' },

        { text: value.hellosignStatus || '-', key: 'hellosignstatus' },
        { text: value.createdBy || '-', key: 'createdby' },
        { text: value.creationTime || '-', key: 'createddate' },
        { text: value.modifiedBy || '-', key: 'modifiedby' },
        { text: value.modifiedOn || '-', key: 'modifieddate' },
      ];

      cells.forEach((cell) => {
        const td = this.renderer.createElement('td');
        this.renderer.appendChild(td, document.createTextNode(cell.text));
        td.setAttribute('title', cell.text);
        this.renderer.appendChild(trData, td);
      });

      this.renderer.listen(trData, 'click', () => this.onButtonClick(value));
      this.renderer.appendChild(tbody, trData);
    });

    this.renderer.appendChild(table, tbody);
    return table;
  }

  //Add Dynamic Row Sale Order Bin
  formatSaleOrder(data: any, inboundData: any): HTMLElement {
    const table = this.renderer.createElement('table');
    const thead = this.renderer.createElement('thead');
    const tbody = this.renderer.createElement('tbody');

    const thRow = this.renderer.createElement('tr');
    const headers = [
      { text: 'Primary Insurance', key: 'primaryinsurance' },
      { text: 'Last Mask Date', key: 'lastmaskdate' },
      { text: 'Points', key: 'points' },
      { text: 'Exchange / Return', key: 'exchagereturn' },
      { text: 'Billing Order Type', key: 'billingordertype' },
      { text: 'Payment Status', key: 'paymentstatus' },
      { text: 'Hello Sign Status', key: 'hellosignstatus' },
      { text: 'Created By', key: 'createdby' },
      { text: 'Created Date', key: 'createddate' },
      { text: 'Modified By', key: 'modifiedby' },
      { text: 'Modified Date', key: 'modifieddate' },
    ];

    headers.forEach((header) => {
      const th = this.renderer.createElement('th');
      this.renderer.appendChild(th, document.createTextNode(header.text));
      th.setAttribute('title', header.text);
      this.renderer.appendChild(thRow, th);
    });

    this.renderer.appendChild(thead, thRow);
    this.renderer.appendChild(table, thead);
    this.renderer.setStyle(table, 'backgroundColor', '#fbfcfe');

    if (!Array.isArray(inboundData)) {
      inboundData = [inboundData];
    }

    inboundData.forEach((value: any) => {
      const trData = this.renderer.createElement('tr');

      const cells = [
        { text: value?.policyName || '-', key: 'primaryinsurance' },
        { text: value.lastMaskProcessDate || '-', key: 'lastmaskdate' },
        { text: value.orderPointsCAL || '-', key: 'points' },
        { text: value.exchangeOrReturn || '-', key: 'exchagereturn' },
        { text: value.billingOrderType || '-', key: 'billingordertype' },
        { text: value.paymentStatus || '-', key: 'paymentstatus' },
        { text: value.helloSignStatus || '-', key: 'hellosignstatus' },
        { text: value.createdBy || '-', key: 'createdby' },
        { text: value.creationTime || '-', key: 'createddate' },
        { text: value.modifiedBy || '-', key: 'modifiedby' },
        { text: value.modifiedOn || '-', key: 'modifieddate' },
      ];

      cells.forEach((cell) => {
        const td = this.renderer.createElement('td');
        this.renderer.appendChild(td, document.createTextNode(cell.text));
        td.setAttribute('title', cell.text);
        this.renderer.appendChild(trData, td);
      });

      this.renderer.listen(trData, 'click', () => this.onButtonClick(value));
      this.renderer.appendChild(tbody, trData);
    });

    this.renderer.appendChild(table, tbody);
    return table;
  }

  //Add Dynamic Row Shipping Bin
  formatShipping(data: any, inboundData: any): HTMLElement {
    const table = this.renderer.createElement('table');
    const thead = this.renderer.createElement('thead');
    const tbody = this.renderer.createElement('tbody');

    const thRow = this.renderer.createElement('tr');

    const headers = [
      { text: 'Location', key: 'location' },
      { text: 'Shipping Status', key: 'shippingstatus' },
      { text: 'CSR Name', key: 'csrname' },
      { text: 'Created By', key: 'createdby' },
      { text: 'Created Date', key: 'createddate' },
      { text: 'Shipping Method', key: 'shippingmethod' },
      { text: 'Exchange', key: 'exchange' },
      { text: 'Early Ship', key: 'earlyship' },
    ];

    headers.forEach((header) => {
      const th = this.renderer.createElement('th');
      this.renderer.appendChild(th, document.createTextNode(header.text));
      th.setAttribute('title', header.text);
      this.renderer.appendChild(thRow, th);
    });

    this.renderer.appendChild(thead, thRow);
    this.renderer.appendChild(table, thead);
    this.renderer.setStyle(table, 'backgroundColor', '#fbfcfe');

    if (!Array.isArray(inboundData)) {
      inboundData = [inboundData];
    }

    inboundData.forEach((value: any) => {
      const trData = this.renderer.createElement('tr');
      const cells = [
        { text: value?.state || '-', key: 'location' },
        { text: value.shippingStatus || '-', key: 'shippingstatus' },
        { text: value.csrName, key: 'csrname' },
        { text: value.createdByName || '-', key: 'createdby' },
        { text: value.createdDate || '-', key: 'createddate' },
        { text: value.shippingMethod || '-', key: 'shippingmethod' },
        { text: value.isExchangeOrder == true ? 'Yes' : 'No', key: 'exchange' },
        { text: value.earlyShip || '-', key: 'earlyship' },
      ];

      cells.forEach((cell) => {
        const td = this.renderer.createElement('td');
        this.renderer.appendChild(td, document.createTextNode(cell.text));
        td.setAttribute('title', cell.text);
        this.renderer.appendChild(trData, td);
      });

      this.renderer.listen(trData, 'click', () => this.onButtonClick(value));
      this.renderer.appendChild(tbody, trData);
    });

    this.renderer.appendChild(table, tbody);
    return table;
  }

  //Add Dynamic Row Billing Bin
  formatBilling(data: any, inboundData: any): HTMLElement {
    const table = this.renderer.createElement('table');
    const thead = this.renderer.createElement('thead');
    const tbody = this.renderer.createElement('tbody');

    const thRow = this.renderer.createElement('tr');

    const headers = [
      { text: 'Location', key: 'location' },
      { text: 'Shipping Method', key: 'shippingmethod' },
      { text: 'Comments', key: 'comments' },
      { text: 'Email', key: 'email' },
      { text: 'Created Date', key: 'createddate' },
      { text: 'Shipping Status', key: 'shippingstatus' },
      { text: 'Patient Balance', key: 'patientbalance' },
      { text: 'Modified By', key: 'modifiedby' },
      { text: 'Reference Ticket Id', key: 'referenceticketid' },
      { text: 'Since', key: 'since' },
    ];

    headers.forEach((header) => {
      const th = this.renderer.createElement('th');
      this.renderer.appendChild(th, document.createTextNode(header.text));
      th.setAttribute('title', header.text);
      this.renderer.appendChild(thRow, th);

    });

    this.renderer.appendChild(thead, thRow);
    this.renderer.appendChild(table, thead);
    this.renderer.setStyle(table, 'backgroundColor', '#fbfcfe');

    if (!Array.isArray(inboundData)) {
      inboundData = [inboundData];
    }

    inboundData.forEach((value: any) => {
      const trData = this.renderer.createElement('tr');
      const cells = [
        { text: value?.state || '-', key: 'location' },
        { text: value.shippingMethod || '-', key: 'shippingmethod' },
        { text: value.comments || '-', key: 'comments' },
        { text: value.emailId, key: 'email' },
        { text: value.createdDate || '-', key: 'createddate' },
        { text: value.shippingStatus || '-', key: 'shippingstatus' },
        { text: value.patientBalance || '-', key: 'patientbalance' },
        { text: value.lastModifyByName || '-', key: 'modifiedby' },
        { text: value.referenceOrderId || '-', key: 'referenceticketid' },
        { text: value.backOrderSince || '-', key: 'since' },
      ];

      cells.forEach((cell) => {
        const td = this.renderer.createElement('td');
        this.renderer.appendChild(td, document.createTextNode(cell.text));
        td.setAttribute('title', cell.text);
        this.renderer.appendChild(trData, td);
      });

      this.renderer.listen(trData, 'click', () => this.onButtonClick(value));
      this.renderer.appendChild(tbody, trData);
    });

    this.renderer.appendChild(table, tbody);
    return table;
  }

  getValueColor(value: number): string {
    if (value == 2) {
      return 'red';
    } else if (value == 1) {
      return 'green';
    } else {
      return 'black';
    }
  }
  onButtonClick(data: any) {}
  isEmpty(value: any): boolean {
    return (
      value === null ||
      value === undefined ||
      value === '' ||
      value === defaultGuid || value==='-'
    );
  }

  //View Patient Notes
  openViewNotesPopup(patientId: string, defaultFaxId: string, docId: string) {
    const config: MatDialogConfig = {
      disableClose: true,
      width: '70vw',
      maxWidth: '70vw',
      data: {
        patientId: patientId,
        pageType: 'Patient Note',
        isAddVisible: false,
        defaultFaxId: defaultFaxId,
        docId: docId,
      },
    };
    const dialogRef = this.dialog.open(PatientCommonNotesComponent, config);

    dialogRef.afterClosed().subscribe(
      () => {},
      (err: HttpErrorResponse) => {
        const data: HttpErrorResponse = err;
        console.warn(data?.error?.error?.message);
      }
    );
  }
  //View Patient Ledger
  viewPatientLedger(patientId: string) {
    this.dialog.open(ViewPatientLedgerComponent, {
      width: '100vw',
      height: '100vh',
      maxWidth: '100vw',
      maxHeight: '100vh',
      panelClass: 'fullscreen-dialog-panel',
      disableClose: true,
      data: {
        patientId: patientId,
      },
    });
    this.dialog.afterAllClosed.subscribe((resposne) => {});
  }

  onFaxIdClick(docId: string, defaultFaxId: string) {
    const url =
      '/#/patient_preview_tiff_document/' + docId + '/' + defaultFaxId;
    window.open(url, '_blank');
  }
}
