<div class="card card-body">
  <div class="col-12">
    <ul ngbNav #nav="ngbNav" justify="justified" class="alert alert-success nav nav-pills nav-fill">
      <li ngbNavItem="User">
        <a ngbNavLink class="nav-link">User</a>
        <ng-template ngbNavContent>
          <!-- <div class="row" *ngIf="isLoading">
            <div class="col-12">
              <div class="d-flex justify-content-center" *ngIf="isLoading">
                <img class="img-responsive pb-2 mr-1" src="assets/gifs/Infinity-loader.gif" />
              </div>
            </div>
          </div> -->
          <app-usertabcomponent [clientId]="clientId"></app-usertabcomponent>
        </ng-template>
      </li>

      <li ngbNavItem="InviteUser">
        <a ngbNavLink>Invite User</a>
        <ng-template ngbNavContent>
          <app-invite-user [clientId]="clientId"></app-invite-user>
        </ng-template>
      </li>
    </ul>
    <div [ngbNavOutlet]="nav"></div>
  </div>
</div>
