import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-referral-fax-log',
  templateUrl: './referral-fax-log.component.html',
  styleUrls: ['./referral-fax-log.component.scss']
})
export class ReferralFaxLogComponent implements OnInit {
  pSearchForm: FormGroup
  constructor(private formBuilder: FormBuilder,) { }
  strPageType: string = 'practiceReferral'
  isLoading: boolean= true;

  ngOnInit(): void {
    this.pSearchForm = this.formBuilder.group({
      txtCreatedate: new FormControl(""),
    })
  }

  ///// reset search field
  reset(){
    this.pSearchForm.patchValue({
      txtCreatedate: "",
    });
    this.pSearchForm.markAsPristine();
    this.pSearchForm?.markAsUntouched();
    this.pSearchForm?.updateValueAndValidity(); 
  }

}
