import type {
  BillingApprovalListDTO,
  BillingApprovedProductListDTO,
  PendingOrderStatusDTO,
  RXDateReportDTO,
} from './dto/models';
import { RestService } from '@abp/ng.core';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class BillingListService {
  apiName = 'orderManagement';

  getBillingApprovedList = () =>
    this.restService.request<any, BillingApprovalListDTO[]>(
      {
        method: 'GET',
        url: '/api/orderApp/billing-list/billing-approved-list',
      },
      { apiName: this.apiName }
    );
  getBillingApprovedListByNewSetup = (newSetup: number) =>
    this.restService.request<any, BillingApprovalListDTO[]>(
      {
        method: 'GET',
        url: '/api/orderApp/billing-list/billing-approved-list',
        params: { newSetup },
      },
      { apiName: this.apiName }
    );

  getShippingItemDetailsByGOrderId = (gOrderId: string) =>
    this.restService.request<any, BillingApprovedProductListDTO[]>(
      {
        method: 'GET',
        url: `/api/orderApp/billing-list/shipping-item-details/${gOrderId}`,
      },
      { apiName: this.apiName }
    );

  updateBillingApprovalStatusByGOrderId = (gOrderId: string) =>
    this.restService.request<any, void>(
      {
        method: 'PUT',
        url: `/api/orderApp/billing-list/billing-approval-status/${gOrderId}`,
      },
      { apiName: this.apiName }
    );

  updateBillingQuantityByInput = (input: BillingApprovedProductListDTO) =>
    this.restService.request<any, string>(
      {
        method: 'PUT',
        responseType: 'text',
        url: '/api/orderApp/billing-list/update-billing-quantity',
        body: input,
      },
      { apiName: this.apiName }
    );
  getBillingApprovedListV1ByNewSetupAndIsPending = (
    newSetup: number,
    isPending: boolean
  ) =>
    this.restService.request<any, BillingApprovalListDTO[]>(
      {
        method: 'GET',
        url: '/api/orderApp/billing-list/billing-approved-list-v1',
        params: { newSetup, isPending },
      },
      { apiName: this.apiName }
    );
  updatePendingOrderStatusByInput = (input: PendingOrderStatusDTO) =>
    this.restService.request<any, void>(
      {
        method: 'PUT',
        url: '/api/orderApp/billing-list/update-pending-order-status',
        body: input,
      },
      { apiName: this.apiName }
    );
  updateOrderStatusForBillingByOrderId = (orderId: string) =>
    this.restService.request<any, void>(
      {
        method: 'PUT',
        url: `/api/orderApp/billing-list/update-order-status-for-billing/${orderId}`,
      },
      { apiName: this.apiName }
    );

    getRXDateReport = (dExpiryFromDate: string, dExpiryToDate: string, gPractitioner: string[], gPatient: string) =>
      this.restService.request<any, RXDateReportDTO[]>({
        method: 'GET',
        url: '/api/orderApp/billing-list/r-xDate-report',
        params: { dExpiryFromDate, dExpiryToDate, gPractitioner, gPatient },
      },
      { apiName: this.apiName });

      rxFileExistsByPatientIdAndOrderId = (patientId: string, orderId: string) =>
        this.restService.request<any, boolean>({
          method: 'POST',
          url: '/api/orderApp/billing-list/rx-file-exists',
          params: { patientId, orderId },
        },
        { apiName: this.apiName });

  constructor(private restService: RestService) {}
}
