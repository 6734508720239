<!-- <h2 mat-dialog-title>Insurance Verification</h2> -->
<div class="row">
  <div class="col-lg-6">
    <h2>Insurance Verification</h2>
  </div>
  <!-- <div class="col-lg-6 text-right">
    <button class="text-right close" aria-label="Close" mat-dialog-close> <b> X </b>
    </button>
  </div> -->
</div>
<mat-dialog-content class="mat-typography"><br>
  <form [formGroup]="verificationForm">
    <div class="row">
      <div class="col-lg-4">
        <div class="row">
          <div class="col-md-12">
            <h4 class="customThemeClass"><b>Patient ID: {{patientids}}</b></h4>
          </div>
          <!-- <div class="col-md-6">
          </div> -->
        </div>
        <mat-card-content>
          <div class="row">
            <mat-form-field class="col-6">
              <mat-label>Patient Name</mat-label>
              <input autocomplete="new-txtName" formControlName="txtName" maxlength="80" matInput type="text">
              <mat-error *ngIf="
              verificationForm?.get('txtName')?.touched &&
              verificationForm?.get('txtName')?.errors?.sAlphabets
                          ">
                Enter only Alphabets!
              </mat-error>
            </mat-form-field>

            <mat-form-field class="col-6">
              <mat-label>Date Of Birth<span class="asterisk">*</span></mat-label>
              <input autocomplete="new-txtDateOfBirth" formControlName="txtDateOfBirth" matInput placeholder="MM/DD/YYYY" [matDatepicker]="txtDateOfBirth"
                [min]="BirthdateMinDate">
              <mat-datepicker-toggle matSuffix [for]="txtDateOfBirth">
              </mat-datepicker-toggle>
              <mat-datepicker #txtDateOfBirth></mat-datepicker>
              <mat-error *ngIf="
              verificationForm?.get('txtDateOfBirth')?.touched &&
              verificationForm?.get('txtDateOfBirth')?.errors">
                Enter Valid date
              </mat-error>
              <mat-error *ngIf="
              verificationForm?.get('txtDateOfBirth')?.touched &&
              verificationForm?.get('txtDateOfBirth')?.errors?.required
              ">
                Date Of Birth is a required field!
              </mat-error>
            </mat-form-field>
            <mat-form-field class="col-12">
              <mat-label>Comments/Notes</mat-label>
              <textarea matInput maxlength="1000" [(ngModel)]="notesText"
              (input)="updateCharacterCount()" formControlName="txtCommentsNotes" rows="3"></textarea> <div class="char-count">
                {{ notesText?.length }} / 5000
              </div>
            </mat-form-field>
          </div>
        </mat-card-content>
      </div>
      <div class="col-lg-8">
        <div class="row">
          <div class="col-md-6">
            <h4 class="customThemeClass"><b>Lookup Patient Ins</b></h4>
          </div>
          <div class="col-md-6">
          </div>
        </div>
        <mat-card-content>
          <div class="row">

            <mat-form-field class="col-3">
              <mat-label> Plan <span class="asterisk">*</span></mat-label>
              <mat-select (valueChange)="valueChangeLoadDropdown($event)" [(value)]="plan" formControlName="txtPlan">
                <!-- <mat-option *ngFor="let policy of drpPolicies" [value]="policy.policyNo">
                {{policy.insuranceId}}
                </mat-option> -->
                <mat-option *ngFor="let payor of payorLevel" [value]="payor.payorLevelName">
                  {{ payor.payorLevelName }}
                </mat-option>
              </mat-select>
              <button mat-button (click)="plan=undefined;$event.stopPropagation()" *ngIf="plan" matSuffix
                (click)="clearPlan()" mat-icon-button >
                <mat-icon>close</mat-icon>
              </button>
              <mat-error *ngIf="
              verificationForm?.get('txtPlan')?.touched &&
              verificationForm?.get('txtPlan')?.errors?.required
              ">
                Plan is a required field!
              </mat-error>
            </mat-form-field>
            <mat-form-field class="col-3">
              <mat-label> Plan Name<span class="asterisk">*</span></mat-label>
              <input autocomplete="new-txtPlanName" formControlName="txtPlanName" matInput type="text">
              <mat-error *ngIf="
                  verificationForm?.get('txtPlanName')?.touched &&
                  verificationForm?.get('txtPlanName')?.errors?.sAlphabets
                ">
                Enter only Alphabets!
              </mat-error>
              <mat-error *ngIf="
              verificationForm?.get('txtPlanName')?.touched &&
              verificationForm?.get('txtPlanName')?.errors?.required
              ">
                Plan Name is a required field!
              </mat-error>
            </mat-form-field>
            <mat-form-field class="col-3">
              <mat-label> Plan Phone</mat-label>
              <input autocomplete="new-txtPlanPhone" formControlName="txtPlanPhone" prefix="+1-" mask="(000)-000-0000" matInput type="text"
                maxlength="40">
              <mat-error *ngIf="
                  verificationForm?.get('txtPlanPhone')?.touched &&
                  verificationForm?.get('txtPlanPhone')?.errors
                  ">
                Enter only Numbers!
              </mat-error>
            </mat-form-field>
            <mat-form-field class="col-3">
              <mat-label>Policy # <span class="asterisk">*</span></mat-label>
              <input autocomplete="new-txtPolicy" formControlName="txtPolicy" maxlength="40" matInput type="text">
              <mat-error *ngIf="
              verificationForm?.get('txtPolicy')?.touched &&
              verificationForm?.get('txtPolicy')?.errors?.required
              ">
                Policy Name is a required field!
              </mat-error>

              <mat-error *ngIf="
            verificationForm?.get('txtPolicy')?.touched &&
            verificationForm?.get('txtPolicy')?.errors?.sAlphabets
            ">
                Enter only Alphabets!
              </mat-error>
            </mat-form-field>
            <mat-form-field class="col-3">
              <mat-label>Group #</mat-label>
              <input autocomplete="new-txtGroup" formControlName="txtGroup" maxlength="80" matInput type="text">
              <mat-error *ngIf="
              verificationForm?.get('txtGroup')?.touched &&
              verificationForm?.get('txtGroup')?.errors?.pattern
              ">
                Enter Alphanumeric Only !
              </mat-error>
            </mat-form-field>
            <mat-form-field class="col-3">
              <mat-label>Effective Date <span class="asterisk">*</span></mat-label>
              <input autocomplete="new-txtEffectiveDate" [min]="BirthdateMinDate" formControlName="txtEffectiveDate" matInput placeholder="MM/DD/YYYY"
                [matDatepicker]="txtEffectiveDate">
              <mat-datepicker-toggle matSuffix [for]="txtEffectiveDate">
              </mat-datepicker-toggle>
              <mat-datepicker #txtEffectiveDate></mat-datepicker>
              <mat-error *ngIf="
                 verificationForm?.get('txtEffectiveDate')?.touched &&
                 verificationForm?.get('txtEffectiveDate')?.errors?.required
                 ">
                Effective Date is a required field!
              </mat-error>
            </mat-form-field>
            <!-- <mat-form-field class="col-3">
                  <mat-label>Type</mat-label>
                  <input formControlName="txtType" matInput type="text">
              </mat-form-field> -->
            <mat-form-field class="col-3">
              <mat-label>Type<span class="asterisk">*</span></mat-label>
              <!-- <input formControlName="txtType" matInput type="text"> -->
              <mat-select maxlength="40" [(value)]="type" formControlName="txtType">
                <!-- <mat-option *ngFor="let insurance of drpPlan" [value]="insurance.typeOfPlanId">
                {{ insurance.typeOfPlanName }}
                </mat-option> -->
                <mat-option *ngFor="let payor of drpYearType" [value]="payor.shortCodeId">
                  {{ payor.yearType }}
                </mat-option>
              </mat-select>
              <button mat-button (click)="type=undefined;$event.stopPropagation()" *ngIf="type" matSuffix
                (click)="clearType()" mat-icon-button >
                <mat-icon>close</mat-icon>
              </button>
              <mat-error *ngIf="
                verificationForm?.get('txtType')?.touched &&
                verificationForm?.get('txtType')?.errors?.required
                ">
                Type is a required field!
              </mat-error>
            </mat-form-field>
            <mat-form-field class="col-3">
              <mat-label>Fiscal Year Start</mat-label>
              <input autocomplete="new-txtFascalYearStart" [min]="BirthdateMinDate" formControlName="txtFascalYearStart" matInput placeholder="MM/DD/YYYY"
                [matDatepicker]="txtFascalYearStart">
              <mat-datepicker-toggle matSuffix [for]="txtFascalYearStart">
              </mat-datepicker-toggle>
              <mat-datepicker #txtFascalYearStart></mat-datepicker>
            </mat-form-field>
            <mat-form-field class="col-3">
              <mat-label>End</mat-label>
              <input autocomplete="new-txtEnd" formControlName="txtEnd" placeholder="MM/DD/YYYY" matInput [matDatepicker]="txtEnd">
              <mat-datepicker-toggle matSuffix [for]="txtEnd">
              </mat-datepicker-toggle>
              <mat-datepicker #txtEnd></mat-datepicker>
            </mat-form-field>
            <div class="col-md-3">
              <br>
              <mat-checkbox formControlName="chkActive">Active</mat-checkbox>
            </div>
            <!-- <div class="col-9">
                  <br>
                  <button mat-button class="buttonColor mb-2 mr-2">Submit For Verification</button>
                  <button mat-button class="buttonColor mb-2 mr-2">Submit For Online Verification</button>
              </div> -->
          </div>
        </mat-card-content>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12">
        <mat-card-content>
          <div class="row">
            <div class="col-md-3">
              <br>
              <mat-checkbox formControlName="chkMedicare">Medicare CMN on file?</mat-checkbox>
            </div>
            <div class="col-md-3">
              <br>
              <mat-checkbox formControlName="chkPatient">Is Patient Home Health Episode?</mat-checkbox>
            </div>
            <div class="col-md-6">
              <br>
              <mat-checkbox formControlName="chkFamilyDeductible">Does Family Deductible cover Individual Deductible?
              </mat-checkbox>
            </div>
            <mat-form-field class="col-3">
              <mat-label>CMN Code</mat-label>
              <input autocomplete="new-txtCmnCode" matInput maxlength="40" formControlName="txtCmnCode" type="text">
              <mat-error *ngIf="
              verificationForm?.get('txtCmnCode')?.touched &&
              verificationForm?.get('txtCmnCode')?.errors?.sAlphabets
              ">
                Enter only Alphabets!
              </mat-error>
            </mat-form-field>
            <mat-form-field class="col-3">
              <mat-label> Type Of Plan <span class="asterisk">*</span></mat-label>
              <mat-select [(value)]="typeOfPlan" formControlName="drpTypeOfPlan">
                <!-- <mat-option *ngFor="let insurance of drpPlan" [value]="insurance.typeOfPlanId">
                  {{ insurance.typeOfPlanName }}
                </mat-option> -->
                <mat-option *ngFor="let payor of drpPlanType" [value]="payor.planType">
                  {{ payor.planType }}
                </mat-option>
              </mat-select>
              <button mat-button (click)="typeOfPlan=undefined;$event.stopPropagation()" *ngIf="typeOfPlan" matSuffix
                (click)="clearTypeOfPlan()" mat-icon-button >
                <mat-icon>close</mat-icon>
              </button>
              <mat-error *ngIf="
                  verificationForm?.get('drpTypeOfPlan')?.touched &&
                  verificationForm?.get('drpTypeOfPlan')?.errors?.required
                  ">
                Type Of Plan is a required field!
              </mat-error>
            </mat-form-field>
            <mat-form-field class="col-3">
              <mat-label>DEO</mat-label>
              <input autocomplete="new-txtDeo" matInput maxlength="40" formControlName="txtDeo" type="text">
              <mat-error *ngIf="
              verificationForm?.get('txtDeo')?.touched &&
              verificationForm?.get('txtDeo')?.errors?.number
              ">
                Enter only Numbers!
              </mat-error>
            </mat-form-field>
            <mat-form-field class="col-3">
              <mat-label> Co Insurance </mat-label>
              <input autocomplete="new-txtCoInsurance" matInput maxlength="40" formControlName="txtCoInsurance" type="text">
            </mat-form-field>
          </div>
        </mat-card-content>
      </div>
    </div>
    <br>
    <div class="row">
      <div class="col-lg-12">
        <div class="row">
          <div class="col-md-6">
            <h4 class="customThemeClass"><b>Insurance Benefit Info</b></h4>
          </div>
          <div class="col-md-6">
          </div>
        </div>
        <mat-card-content>
          <div class="row">
            <div class="col-md-3">
            </div>
            <div class="col-md-3">
              <br>
              <mat-checkbox formControlName="chkIndividual">Individual</mat-checkbox>
            </div>
            <div class="col-md-3">
              <br>
              <mat-checkbox formControlName="chkFamily">Family</mat-checkbox>
            </div>
            <div class="col-md-3">
              <br>
              <mat-checkbox formControlName="chkOutOfNetwork">OutofNetwork</mat-checkbox>
            </div>
            <div class="col-3">
              <br>
              <p>What is Plan Deductible?</p>
            </div>
            <mat-form-field class="col-3">
              <mat-label></mat-label>
              <input autocomplete="new-txtDeductibleIndividual" matInput formControlName="txtDeductibleIndividual" type="text">
            </mat-form-field>
            <mat-form-field class="col-3">
              <mat-label></mat-label>
              <input autocomplete="new-txtDeductibleFamily" matInput formControlName="txtDeductibleFamily" type="text">
            </mat-form-field>
            <mat-form-field class="col-3">
              <mat-label></mat-label>
              <input autocomplete="new-txtDeductibleOutOfNetwork" matInput formControlName="txtDeductibleOutOfNetwork" type="text">
            </mat-form-field>
            <div class="col-3">
              <br>
              <p>How much of Deductible has been met?</p>
            </div>
            <mat-form-field class="col-3">
              <mat-label></mat-label>
              <input autocomplete="new-txtDeductibleMetIndividual" matInput formControlName="txtDeductibleMetIndividual" type="text">
            </mat-form-field>
            <mat-form-field class="col-3">
              <mat-label></mat-label>
              <input autocomplete="new-txtDeductibleMetFamily" matInput formControlName="txtDeductibleMetFamily" type="text">
            </mat-form-field>
            <mat-form-field class="col-3">
              <mat-label></mat-label>
              <input autocomplete="new-txtDeductibleMetOutOfNetwork" matInput formControlName="txtDeductibleMetOutOfNetwork" type="text">
            </mat-form-field>
            <div class="col-3">
              <br>
              <p>Payable at what percent of the allowed amount?</p>
            </div>
            <mat-form-field class="col-3">
              <mat-label></mat-label>
              <!-- <mat-select formControlName="drpPayableIndividual">
                      <mat-option  *ngFor="let test of tests" [value]="test.value">
                          {{test.viewValue}}
                      </mat-option>
                  </mat-select> -->
              <mat-select maxlength="40" (ngModelChange)="onSelection($event)" [(value)]="payablePercentType" formControlName="drpPayableIndividual">
                <mat-option *ngFor="let payable of drpPayable" [value]="payable.payablePercentType">
                  {{payable.payablePercentType}}
                </mat-option>
              </mat-select>
              <button mat-button (click)="payablePercentType=undefined;$event.stopPropagation()"
                (click)="clearPayablePercentType()" *ngIf="payablePercentType" matSuffix mat-icon-button
                >
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field>
            <mat-form-field class="col-3">
              <mat-label></mat-label>
              <mat-select maxlength="40" [(value)]="payablePercentType1" formControlName="drpPayableFamily">
                <mat-option *ngFor="let payable of drpPayable" [value]="payable.payablePercentType">
                  {{payable.payablePercentType}}
                </mat-option>
              </mat-select>
              <button mat-button (click)="payablePercentType1=undefined;$event.stopPropagation()"
                (click)="clearPayablePercentType1()" *ngIf="payablePercentType1" matSuffix mat-icon-button
                >
                <mat-icon>close</mat-icon>
              </button>
              <!-- <mat-select formControlName="drpPayableOutOfNetwork">
                      <mat-option  *ngFor="let test of tests" [value]="test.value">
                          {{test.viewValue}}
                      </mat-option>
                  </mat-select> -->
            </mat-form-field>
            <mat-form-field class="col-3">
              <mat-label></mat-label>
              <mat-select maxlength="40" [(value)]="payablePercentType2" formControlName="drpPayableOutOfNetwork">
                <mat-option *ngFor="let payable of drpPayable" [value]="payable.payablePercentType">
                  {{payable.payablePercentType}}
                </mat-option>
              </mat-select>
              <button mat-button (click)="payablePercentType2=undefined;$event.stopPropagation()"
                (click)="clearPayablePercentType2()" *ngIf="payablePercentType2" matSuffix mat-icon-button
                >
                <mat-icon>close</mat-icon>
              </button>
              <!-- <mat-select formControlName="drpPayableFamily">
                      <mat-option  *ngFor="let test of tests" [value]="test.value">
                          {{test.viewValue}}
                      </mat-option>
                  </mat-select> -->
            </mat-form-field>
            <div class="col-3">
              <br>
              <p>Out-of-pocket expense?</p>
            </div>
            <mat-form-field class="col-3">
              <mat-label></mat-label>
              <input autocomplete="new-txtExpenseIndividual" formControlName="txtExpenseIndividual" maxlength="40" matInput type="text">
            </mat-form-field>
            <mat-form-field class="col-3">
              <mat-label></mat-label>
              <input autocomplete="new-txtExpenseFamily" formControlName="txtExpenseFamily" maxlength="40" matInput type="text">
            </mat-form-field>
            <mat-form-field class="col-3">
              <mat-label></mat-label>
              <input autocomplete="new-txtExpenseOutOfNetwork" maxlength="40" formControlName="txtExpenseOutOfNetwork" matInput type="text">
            </mat-form-field>
            <div class="col-3">
              <br>
              <p>How much Out-of-pocket expense has been met?</p>
            </div>
            <mat-form-field class="col-3">
              <mat-label></mat-label>
              <input autocomplete="new-txtExpenseMetIndividual" formControlName="txtExpenseMetIndividual" maxlength="40" matInput type="text">
            </mat-form-field>
            <mat-form-field class="col-3">
              <mat-label></mat-label>
              <input autocomplete="new-txtExpenseMetFamily" formControlName="txtExpenseMetFamily" maxlength="40" matInput type="text">
            </mat-form-field>
            <mat-form-field class="col-3">
              <mat-label></mat-label>
              <input autocomplete="new-txtExpenseMetOutOfNetwork" formControlName="txtExpenseMetOutOfNetwork" maxlength="40" matInput type="text">
            </mat-form-field>
            <div class="col-3">
              <br>
              <p>Does Out-of-pocket expense include the deductible?</p>
            </div>
            <div class="col-md-3">
              <br>
              <mat-checkbox formControlName="chkExpenseIndividual">Individual</mat-checkbox>
            </div>
            <div class="col-md-3">
              <br>
              <mat-checkbox formControlName="chkExpenseFamily">Family</mat-checkbox>
            </div>
            <div class="col-md-3">
              <br>
              <mat-checkbox formControlName="chkOutOfNetwork">Out Of Network</mat-checkbox>
            </div>
            <!-- Newly Added -->
            <div class="col-md-3">
              <br>
              <mat-checkbox formControlName="chkIsBlueCardEligible"> Is BlueCard Eligible </mat-checkbox>
            </div>

            <div class="col-md-3">
              <br>
              <mat-checkbox formControlName="chkIsDirectBill">Is DirectBill </mat-checkbox>
            </div>

            <div class="col-md-3">
              <br>
              <mat-checkbox formControlName="chkIsPreAuthNeeded">Is PreAuth Needed </mat-checkbox>
            </div>
            <div class="col-md-3">
              <br>
              <mat-checkbox formControlName="chkIsClaimSubmit">Is Claim Submit </mat-checkbox>
            </div>
            <div class="col-md-3">
              <br>
              <mat-checkbox formControlName="chkIsReferenceNumberAvailable">Is Reference Number Available</mat-checkbox>
            </div>
            <!-- <div class="col-md-3">
              <br>
              <mat-checkbox formControlName="txtReferenceNumber">Is this reference number </mat-checkbox>
            </div> -->

            <mat-form-field class="col-3">
              <mat-label>Reference Number</mat-label>
              <input autocomplete="new-txtReferenceNumber" formControlName="txtReferenceNumber" maxlength="40" matInput type="text">
            </mat-form-field>

            <mat-form-field class="col-3">
              <mat-label>Resposponsible Payment Percentage</mat-label>
              <input autocomplete="new-txtPatientResposponsiblePaymentPercentage" formControlName="txtPatientResposponsiblePaymentPercentage" maxlength="40" matInput type="text">
            </mat-form-field>

            <!--  -->

            <div class="col-3">
              <br>
              <mat-checkbox formControlName="chkPrecertRequired">Is Precert Required?</mat-checkbox>
            </div>
            <mat-form-field class="col-md-3">
              <mat-label>If yes, Phone # to call</mat-label>
              <input autocomplete="new-txtPhoneCall" formControlName="txtPhoneCall" maxlength="40" matInput type="text" prefix="+1-"
                mask="(000)-000-0000">
              <mat-error *ngIf="
                  verificationForm?.get('txtPhoneCall')?.touched &&
                  verificationForm?.get('txtPhoneCall')?.errors
                  ">
                Enter only Numbers!
              </mat-error>

            </mat-form-field>

            <mat-form-field class="col-md-3">
              <mat-label>PCP</mat-label>
              <input autocomplete="new-txtPcp" formControlName="txtPcp" maxlength="40" matInput type="text">
              <mat-error *ngIf="
              verificationForm?.get('txtPcp')?.touched &&
              verificationForm?.get('txtPcp')?.errors?.sAlphabets
              ">
                Enter only Alphabets!
              </mat-error>
            </mat-form-field>

            <div class="col-3">
              <br>
              <mat-checkbox formControlName="chkReferralRequired">Is a referral required?</mat-checkbox>
            </div>

            <div class="col-3">
              <br>
              <mat-checkbox formControlName="chkPredetermination">Predetermination</mat-checkbox>
            </div>
            <mat-form-field class="col-md-3">
              <mat-label>Pre.Notes</mat-label>
              <input autocomplete="new-txtPreNotes" formControlName="txtPreNotes" maxlength="1000" matInput type="text">
              <!-- <mat-error *ngIf="
              verificationForm?.get('txtPreNotes')?.touched &&
              verificationForm?.get('txtPreNotes')?.errors?.sAlphabets
              ">
                Enter only Alphabets!
              </mat-error> -->
            </mat-form-field>
            <mat-form-field class="col-3">
              <mat-label>Lifetime Max Met</mat-label>
              <input autocomplete="new-txtLifetimeMaxMet" formControlName="txtLifetimeMaxMet" maxlength="40" matInput type="text">
            </mat-form-field>
            <mat-form-field class="col-3">
              <mat-label>Lifetime Max</mat-label>
              <input autocomplete="new-txtLifetimeMax" formControlName="txtLifetimeMax" maxlength="40" matInput type="text">
            </mat-form-field>

            <div class="col-md-3">
              <br>
              <mat-checkbox formControlName="chkDmePartsCovered"> DME Parts Covered</mat-checkbox>
            </div>
            <div class="col-md-3">
              <br>
              <mat-checkbox formControlName="chkDmeReplacementUnit"> DME Replacement Unit</mat-checkbox>
            </div>
            <mat-form-field class="col-3">
              <mat-label>DME Limit Met</mat-label>
              <input autocomplete="new-txtDmeLimitMet" formControlName="txtDmeLimitMet" maxlength="40" matInput type="text">
            </mat-form-field>
            <mat-form-field class="col-3">
              <mat-label>DME Annual Limit</mat-label>
              <input autocomplete="new-txtDmeAnnualLimit" formControlName="txtDmeAnnualLimit" maxlength="40" matInput type="text">
            </mat-form-field>


            <mat-form-field class="col-3">
              <mat-label>Requested By</mat-label>
              <input autocomplete="new-txtRequestedBy" formControlName="txtRequestedBy" maxlength="40" matInput type="text">
              <mat-error *ngIf="
              verificationForm?.get('txtRequestedBy')?.touched &&
              verificationForm?.get('txtRequestedBy')?.errors?.sAlphabets
              ">
                Enter only Alphabets!
              </mat-error>
            </mat-form-field>
            <mat-form-field class="col-3">
              <mat-label>Primary Payor</mat-label>
              <input autocomplete="new-txtPrimaryPayor" formControlName="txtPrimaryPayor" maxlength="40" matInput type="text">
              <mat-error *ngIf="
              verificationForm?.get('txtPrimaryPayor')?.touched &&
              verificationForm?.get('txtPrimaryPayor')?.errors?.sAlphabets
              ">
                Enter only Alphabets!
              </mat-error>
            </mat-form-field>
            <mat-form-field class="col-3">
              <mat-label>Call tracking number / Reference #</mat-label>
              <input autocomplete="new-txtCallTrackingNumber" formControlName="txtCallTrackingNumber" maxlength="40" matInput type="text">
            </mat-form-field>
            <mat-form-field class="col-3">
              <mat-label>Contact (phone/Web)</mat-label>
              <input autocomplete="new-txtContact" formControlName="txtContact" maxlength="15" matInput type="text">
              <mat-error *ngIf="
                  verificationForm?.get('txtContact')?.touched &&
                  verificationForm?.get('txtContact')?.errors?.number
                  ">
                Enter only Numbers!
              </mat-error>
            </mat-form-field>

            <!-- <mat-form-field class="col-3">
                  <mat-label>Effective Date</mat-label>
                  <input matInput type="text">
              </mat-form-field>
              <mat-form-field class="col-3">
                  <mat-label>Type of Policy</mat-label>
                  <input matInput type="text">
              </mat-form-field>
              <mat-form-field class="col-3">
                  <mat-label>Policy #</mat-label>
                  <input matInput type="text">
              </mat-form-field>
              <mat-form-field class="col-3">
                  <mat-label>Group #</mat-label>
                  <input matInput type="text">
              </mat-form-field> -->
            <mat-form-field class="col-3">
              <mat-label>Policy Holder Name</mat-label>
              <input autocomplete="new-txtPolicyHolderName" formControlName="txtPolicyHolderName" maxlength="40" matInput type="text">
              <mat-error *ngIf="
              verificationForm?.get('txtPolicyHolderName')?.touched &&
              verificationForm?.get('txtPolicyHolderName')?.errors?.sAlphabets
              ">
                Enter only Alphabets!
              </mat-error>
            </mat-form-field>
            <mat-form-field class="col-3">
              <mat-label>Policy Holder DOB</mat-label>
              <input autocomplete="new-txtPolicyHolderDob" formControlName="txtPolicyHolderDob" matInput placeholder="MM/DD/YYYY"
                [matDatepicker]="txtPolicyHolderDob">
              <mat-datepicker-toggle matSuffix [for]="txtPolicyHolderDob">
              </mat-datepicker-toggle>
              <mat-datepicker #txtPolicyHolderDob></mat-datepicker>
              <mat-error *ngIf=" verificationForm?.get('txtPolicyHolderDob')?.touched &&
              verificationForm?.get('txtPolicyHolderDob')?.errors">
                Enter Valid Date!
              </mat-error>
            </mat-form-field>
            <mat-form-field class="col-3">
              <mat-label>Claim Address</mat-label>
              <textarea autocomplete="new-txtClaimAddress" rows="3" maxlength="60" formControlName="txtClaimAddress" matInput></textarea>
            </mat-form-field>
            <div class="col-3">
              <br>
              <mat-checkbox formControlName="chkNetwork">Network (YES / NO)</mat-checkbox>
            </div>

            <mat-form-field class="col-3">
              <mat-label>Plan COB</mat-label>
              <input autocomplete="new-txtPlanCob" formControlName="txtPlanCob" matInput maxlength="40" placeholder="MM/DD/YYYY"
                [matDatepicker]="txtPlanCob">
              <mat-datepicker-toggle matSuffix [for]="txtPlanCob">
              </mat-datepicker-toggle>
              <mat-datepicker #txtPlanCob></mat-datepicker>
              <mat-error *ngIf=" verificationForm?.get('txtPlanCob')?.touched &&
                  verificationForm?.get('txtPlanCob')?.errors">
                Enter Valid Date!
              </mat-error>
            </mat-form-field>
            <mat-form-field class="col-3">
              <mat-label>Ded (Total) </mat-label>
              <input autocomplete="new-txtDedTotal" formControlName="txtDedTotal" maxlength="40" matInput type="text">
            </mat-form-field>
            <mat-form-field class="col-3">
              <mat-label>Ded (Met)</mat-label>
              <input autocomplete="new-txtDedMet" formControlName="txtDedMet" maxlength="40" matInput type="text">
            </mat-form-field>
            <mat-form-field class="col-3">
              <mat-label>OOP (Total)</mat-label>
              <input autocomplete="new-txtOopTotal" formControlName="txtOopTotal" maxlength="40" matInput type="text">
            </mat-form-field>
            <mat-form-field class="col-3">
              <mat-label>OOP (Met)</mat-label>
              <input autocomplete="new-txtOopMet" formControlName="txtOopMet" maxlength="40" matInput type="text">
            </mat-form-field>
            <mat-form-field class="col-3">
              <mat-label>OOP (Include or exclude deductible)</mat-label>
              <input autocomplete="new-txtOopDeductible" formControlName="txtOopDeductible" maxlength="40" matInput type="text">
            </mat-form-field>
            <mat-form-field class="col-3">
              <mat-label>DME Coverage</mat-label>
              <input autocomplete="new-txtDmeCoverage" formControlName="txtDmeCoverage" maxlength="40" matInput type="text">
            </mat-form-field>
            <mat-form-field class="col-3">
              <mat-label>DME Max</mat-label>
              <input autocomplete="new-txtDmeMax" formControlName="txtDmeMax" maxlength="40" matInput type="text">
            </mat-form-field>
            <mat-form-field class="col-3">
              <mat-label>HCPC(S)</mat-label>
              <input autocomplete="new-txtHcpcs" formControlName="txtHcpcs" maxlength="40" matInput type="text">
              <mat-error *ngIf="
                  verificationForm?.get('txtHcpcs')?.touched &&
                  verificationForm?.get('txtHcpcs')?.errors?.number
                  ">
                Enter only Number!
              </mat-error>
            </mat-form-field>
            <mat-form-field class="col-3">
              <mat-label>Coverage criteria</mat-label>
              <input autocomplete="new-txtCoverageCriteria" formControlName="txtCoverageCriteria" matInput maxlength="40" type="text">
              <mat-error *ngIf="
              verificationForm?.get('txtCoverageCriteria')?.touched &&
              verificationForm?.get('txtCoverageCriteria')?.errors?.sAlphabets
              ">
                Enter only Alphabets!
              </mat-error>
            </mat-form-field>
            <mat-form-field class="col-3">
              <mat-label>Unit limits</mat-label>
              <input autocomplete="new-txtQtyLimits" formControlName="txtQtyLimits" matInput maxlength="40" type="text">
              <mat-error *ngIf="
              verificationForm?.get('txtQtyLimits')?.touched &&
              verificationForm?.get('txtQtyLimits')?.errors?.sAlphabets
              ">
                Enter only Alphabets!
              </mat-error>
            </mat-form-field>
            <mat-form-field class="col-3">
              <mat-label>Frequency</mat-label>
              <input autocomplete="new-txtFrequency" formControlName="txtFrequency" maxlength="40" matInput type="text">
              <mat-error *ngIf="
              verificationForm?.get('txtFrequency')?.touched &&
              verificationForm?.get('txtFrequency')?.errors?.sAlphabets
              ">
                Enter only Alphabets!
              </mat-error>
            </mat-form-field>
            <mat-form-field class="col-3">
              <mat-label>Rent (How many months)or Purchase?</mat-label>
              <input autocomplete="new-txtRent" formControlName="txtRent" maxlength="40" matInput type="text">
              <mat-error *ngIf="
              verificationForm?.get('txtRent')?.touched &&
              verificationForm?.get('txtRent')?.errors?.sAlphabets
              ">
                Enter only Alphabets!
              </mat-error>
            </mat-form-field>
            <mat-form-field class="col-6">
              <mat-label>PA required: Purchase or rental upfront or after what months?</mat-label>
              <input autocomplete="new-txtPaRequired" formControlName="txtPaRequired" maxlength="40" matInput type="text">
              <mat-error *ngIf="
              verificationForm?.get('txtPaRequired')?.touched &&
              verificationForm?.get('txtPaRequired')?.errors?.sAlphabets
              ">
                Enter only Alphabets!
              </mat-error>
            </mat-form-field>
            <mat-form-field class="col-3">
              <mat-label>Codes that require PA</mat-label>
              <input autocomplete="new-txtCodesRequirePa" formControlName="txtCodesRequirePa" maxlength="40" matInput type="text">
              <mat-error *ngIf="
              verificationForm?.get('txtCodesRequirePa')?.touched &&
              verificationForm?.get('txtCodesRequirePa')?.errors?.sAlphabets
              ">
                Enter only Alphabets!
              </mat-error>
            </mat-form-field>
            <mat-form-field class="col-3">
              <mat-label>Same or Similar Info</mat-label>
              <input autocomplete="new-txtSameOrSimilarInfo" formControlName="txtSameOrSimilarInfo" maxlength="40" matInput type="text">
              <mat-error *ngIf="
              verificationForm?.get('txtSameOrSimilarInfo')?.touched &&
              verificationForm?.get('txtSameOrSimilarInfo')?.errors?.sAlphabets
              ">
                Enter only Alphabets!
              </mat-error>
            </mat-form-field>
            <mat-form-field class="col-3">
              <mat-label>Spoke with</mat-label>
              <input autocomplete="new-txtSpokeWith" formControlName="txtSpokeWith" maxlength="40" matInput type="text">
              <mat-error *ngIf="
                  verificationForm?.get('txtSpokeWith')?.touched &&
                  verificationForm?.get('txtSpokeWith')?.errors?.sAlphabets
                  ">
                Enter only Alphabets!
              </mat-error>
            </mat-form-field>
            <mat-form-field class="col-3">
              <mat-label>Prepared By</mat-label>
              <input autocomplete="new-drpPreparedBy" formControlName="drpPreparedBy" maxlength="40" matInput type="text">
              <mat-error *ngIf="
                  verificationForm?.get('drpPreparedBy')?.touched &&
                  verificationForm?.get('drpPreparedBy')?.errors?.sAlphabets
                  ">
                Enter only Alphabets!
              </mat-error>
            </mat-form-field>
            <mat-form-field class="col-3">
              <mat-label>Date Verified</mat-label>
              <input autocomplete="new-txtDateVerified" formControlName="txtDateVerified" matInput maxlength="40" placeholder="MM/DD/YYYY"
                [matDatepicker]="txtDateVerified">
              <mat-datepicker-toggle matSuffix [for]="txtDateVerified">
              </mat-datepicker-toggle>
              <mat-datepicker #txtDateVerified></mat-datepicker>
              <mat-error *ngIf=" verificationForm?.get('txtDateVerified')?.touched &&
                         verificationForm?.get('txtDateVerified')?.errors">
                Enter Valid Date!
              </mat-error>
            </mat-form-field>
            <mat-form-field class="col-3">
              <mat-label>Verified By</mat-label>
              <input autocomplete="new-drpVerifiedBy" formControlName="drpVerifiedBy" maxlength="40" matInput type="text">
              <mat-error *ngIf="
                  verificationForm?.get('drpVerifiedBy')?.touched &&
                  verificationForm?.get('drpVerifiedBy')?.errors?.sAlphabets
                  ">
                Enter only Alphabets!
              </mat-error>
            </mat-form-field>
            <mat-form-field class="col-3">
              <mat-label>Prepared On</mat-label>
              <input autocomplete="new-txtPreparedOn" formControlName="txtPreparedOn" matInput placeholder="MM/DD/YYYY" [matDatepicker]="txtPreparedOn">
              <mat-datepicker-toggle matSuffix [for]="txtPreparedOn">
              </mat-datepicker-toggle>
              <mat-datepicker #txtPreparedOn></mat-datepicker>
            </mat-form-field>
            <mat-form-field class="col-3">
              <mat-label>Comments To Tech</mat-label>
              <input autocomplete="new-txtcommentsToTech" formControlName="txtcommentsToTech" maxlength="1000" matInput type="text">
              <!-- <mat-error *ngIf="
                  verificationForm?.get('txtcommentsToTech')?.touched &&
                  verificationForm?.get('txtcommentsToTech')?.errors?.sAlphabets
                  ">
                Enter only Alphabets!
              </mat-error> -->
            </mat-form-field>
            <mat-form-field class="col-3">
              <mat-label>Verification Method</mat-label>
              <input autocomplete="new-txtVerfnAMethod" formControlName="txtVerfnAMethod" maxlength="40" matInput type="text">
              <mat-error *ngIf="
                  verificationForm?.get('txtVerfnAMethod')?.touched &&
                  verificationForm?.get('txtVerfnAMethod')?.errors?.sAlphabets
                  ">
                Enter only Alphabets!
              </mat-error>
            </mat-form-field>
            <div class="col-9">
              <mat-radio-group formControlName="rbtnVerificationStatus">
                <br>
                <mat-radio-button class="mb-2 mr-2" value="Done">Done</mat-radio-button> &nbsp;&nbsp;
                <mat-radio-button class="mb-2 mr-2" value="Pending">Pending</mat-radio-button> &nbsp;&nbsp;
                <mat-radio-button class="mb-2 mr-2" value="UnableToVerify">Unable to Verify</mat-radio-button>
              </mat-radio-group>
            </div>

            <!-- <div class="col-9">
              <input type="radio" id="exampleRadios1" value="1" formControlName="rbtnDone"> Done
              <input type="radio" id="exampleRadios2" value="2" formControlName="rbtnPending"> Pending
              </div> -->
            <!-- <div class="custom-control custom-radio mb-3" formControlName="rbtnDone">
                  <input type="radio" id="customRadio1" name="customRadio" class="custom-control-input">
                  <label class="custom-control-label" for="customRadio1">Done</label>
              </div>
              <div class="custom-control custom-radio custom-control">
                  <input type="radio" id="customRadio2" name="customRadio" class="custom-control-input">
                  <label class="custom-control-label" for="customRadio2">Pending</label>
              </div>
              <div class="custom-control custom-radio custom-control">
                  <input type="radio" id="customRadio3" name="customRadio" class="custom-control-input">
                  <label class="custom-control-label" for="customRadio3">Unable to Verify</label>
              </div> -->
          </div>

        </mat-card-content>
        <!-- <div class="row pt-1">
          <div class="col-12 text-center" align="middle">
            <button [disabled]="!(!verificationForm.invalid && (verificationForm.dirty ))" mat-button
              class="buttonColor mb-2 mr-2" (click)="verifyInsurance()">Save</button>

          </div>
        </div> -->
      </div>
    </div>
  </form>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button *ngIf='isPatientLockedByCurrentUser' [disabled]="!(!verificationForm.invalid && (verificationForm.dirty ))"
    mat-button class="buttonColor" (click)="verifyInsurance()">Save
    <mat-icon *ngIf='isShowSpinner'>
      <mat-spinner class="spinner-border spinner-border-sm" diameter="20"> </mat-spinner>
    </mat-icon>
  </button>
  <button class="buttonCancelColor" mat-button mat-dialog-close>Close</button>
  <!-- <button mat-button [mat-dialog-close]="true" cdkFocusInitial>Install</button> -->
</mat-dialog-actions>
