import { Component, Input, OnInit } from '@angular/core';
import { CommonService } from 'projects/shared/src/app/services/common.service';

@Component({
  selector: 'app-patient-statement-history-table',
  templateUrl: './patient-statement-history-table.component.html',
  styleUrls: ['./patient-statement-history-table.component.scss'],
})
export class PatientStatementHistoryTableComponent implements OnInit {
  @Input() tableData: any;
  dtTableOptions = {
    responsive: true,
    paging: false,
    dom: 'Bfrtip',
    scrollCollapse: true,
    scrollY: '500px',
    scrollX: true,
    search: {
      smart: false,
    },
    language: {
      emptyTable: 'No records',
      info: 'Total : _MAX_ records',
      infoEmpty: 'Total : _MAX_ records',
      infoFiltered: '(filtered : _TOTAL_ records)',
      loadingRecords: 'Loading...',
      zeroRecords: 'No matching records',
    },
    buttons: [
      {
        extend: 'excel',
        text: 'Export', // Change the text to 'Export'
        filename: 'Patient Statement History',
      },
    ],
  };
  constructor(private commonService: CommonService) {}

  ngOnInit() {
    this.tableData = this.tableData.map((data) => {
      return {
        ...data,
        suppliesDate: this.commonService.getFormattedDateZone(
          data.suppliesDate
        ),
        orderDate: this.commonService.getFormattedDateZone(data.orderDate),
        lastMaskDate: this.commonService.getFormattedDateZone(
          data.lastMaskDate
        ),
      };
    });
  }
}
