import { Injectable } from '@angular/core';
import { InviteStatusDTO } from 'projects/admin/src/app/admin-proxy/platform-app-management/rcm/platform-management/user-invite/dto/models';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CommunicationService {
  private functionCallSource = new Subject<any>();

  // Observable for function calls
  functionCall$ = this.functionCallSource.asObservable();

  // Service method to trigger function calls
  triggerFunctionCall(node?: any) {
    this.functionCallSource.next(node);
  }

  private fnLoadTreeOnly = new Subject<any>();

  // Observable for function calls
  fnCallTreeOnly$ = this.fnLoadTreeOnly.asObservable();

  // Service method to trigger function calls
  triggerFunctionCallTreeOnly(node?: any) {
    this.fnLoadTreeOnly.next(node);
  }

  //Requisition List Load -RequisitionListComponent
  private loadRequisitionListFunctionCall = new Subject<any>();
  // Observable for function calls
  loadRequisitionListFunctionCall$ =
    this.loadRequisitionListFunctionCall.asObservable();
  triggerRequisitionList(data: any) {
    this.loadRequisitionListFunctionCall.next(data);
  }

  //ReloadRequisitionItemsTable
  private reloadRequisitionItemsFunctionCall = new Subject<any>();
  // Observable for function calls
  reloadRequisitionItemsFunctionCall$ =
    this.loadRequisitionListFunctionCall.asObservable();
  triggerRequisitionItemsList(obj: any) {
    this.reloadRequisitionItemsFunctionCall.next(obj);
  }

  private addressFunctionCall = new Subject<any>();

  // Observable for function calls
  addressFunctionCall$ = this.addressFunctionCall.asObservable();

  triggerAddressDetails(addressDetails: any) {
    this.addressFunctionCall.next(addressDetails);
  }

  private addressListFunctionCall = new Subject<any>();

  // Observable for function calls
  addressListFunctionCall$ = this.addressListFunctionCall.asObservable();

  triggerAddressList(patientId: string) {
    this.addressListFunctionCall.next(patientId);
  }
  // triggerAddressList

  //To Trigger Patient Summary
  private functionCallPatientSummary = new Subject<any>();
  // Observable for function calls
  functionCallPatientSummary$ = this.functionCallPatientSummary.asObservable();
  triggerPatientSummaryCallMethodCall() {
    this.functionCallPatientSummary.next();
  }

  private functionReminder = new Subject<any>();

  // Observable for function calls for Acticve Tab
  functionReminder$ = this.functionReminder.asObservable();
  // Service method to trigger function calls
  triggerFunctionCallReminder() {
    this.functionReminder.next();
  }

  // PatientManagementTableComponent - Get Patient Details
  private patientManagementFunctionCall = new Subject<any>();
  // Observable for function calls
  patientManagementFunctionCall$ =
    this.patientManagementFunctionCall.asObservable();

  triggerpatientList(patientId: string) {
    this.patientManagementFunctionCall.next(patientId);
  }

  // PatientManagementTableComponent - Get Patient Details
  private patientManagementFunctionCallNextCallDate = new Subject<any>();
  // Observable for function calls
  patientManagementFunctionCallNextCallDate$ =
    this.patientManagementFunctionCallNextCallDate.asObservable();

  triggerNextCallUpdateUpdateGetPatientMnt() {
    this.patientManagementFunctionCallNextCallDate.next();
  }

  // Notes Details
  private viewNotesFunctionCall = new Subject<any>();
  // Observable for function calls
  viewNotesFunctionCall$ = this.viewNotesFunctionCall.asObservable();
  triggerViewNotesList() {
    this.viewNotesFunctionCall.next();
  }

  // ReminderFunctionCall Details
  private reminderFunctionCall = new Subject<any>();
  // Observable for function calls
  reminderFunctionCall$ = this.reminderFunctionCall.asObservable();
  triggerReminderList() {
    this.reminderFunctionCall.next();
  }

  // Reminder GET for DAshboard FunctionCall Details
  private reminderGetFunctionCall = new Subject<any>();
  // Observable for function calls
  reminderGetFunctionCall$ = this.reminderGetFunctionCall.asObservable();
  triggerGetListReminderDashboard() {
    this.reminderGetFunctionCall.next();
  }

  // Reminder v1 GET for Patient Tab  FunctionCall Details
  private reminderGetFunctionCallPatient = new Subject<any>();
  // Observable for function calls
  reminderGetFunctionCallPatient$ =
    this.reminderGetFunctionCallPatient.asObservable();
  triggerGetListReminderPatient() {
    this.reminderGetFunctionCallPatient.next();
  }

  // Exchange List Page
  private exchangeItemsListMethodCall = new Subject<any>();
  // Observable for function calls
  exchangeItemsListMethodCall$ =
    this.exchangeItemsListMethodCall.asObservable();

  triggerExchangeItemsList(orderIds: any) {
    this.exchangeItemsListMethodCall.next(orderIds);
  }

  //SaleOrderComponent - To call the Sale Order Page loadOrderDetailsMethod

  private loadOrderDetailsMethodCall = new Subject<any>();

  loadOrderDetailsMethodCall$ = this.loadOrderDetailsMethodCall.asObservable();

  triggerLoadOrderDetails(orderDetails: any) {
    this.loadOrderDetailsMethodCall.next(orderDetails);
  }

  //OverrideListComponent - To call the override get list api
  private loadOverriddenOrdersMethodCall = new Subject<any>();

  loadOverriddenOrdersMethodCall$ =
    this.loadOverriddenOrdersMethodCall.asObservable();

  triggerOverriddenOrdersList() {
    this.loadOverriddenOrdersMethodCall.next();
  }

  // ReturnOrderListComponent - To call the return order items popup

  private loadReturnItemsMethodCall = new Subject<any>();

  loadReturnItemsMethodCall$ = this.loadReturnItemsMethodCall.asObservable();

  triggerReturnItemsList(orderId: string) {
    this.loadReturnItemsMethodCall.next(orderId);
  }

  // ShippingDisapprovedListComponent - To call the Disapproved order items in the list

  private loadDisapprovedOrderItemMethodCall = new Subject<any>();

  loadDisapprovedOrderItemMethodCall$ =
    this.loadDisapprovedOrderItemMethodCall.asObservable();

  triggerShippingDisapprovedItemsList(orderId: string) {
    this.loadDisapprovedOrderItemMethodCall.next(orderId);
  }

  // Practice Management Physicial Details
  private physicianFunctionCall = new Subject<any>();
  // Observable for function calls
  physicianFunctionCall$ = this.physicianFunctionCall.asObservable();
  triggerPhysicianList() {
    this.physicianFunctionCall.next();
  }

  // ShippingDisapprovedListComponent - To call the notes method in shipping disapprove list page

  private loadShippingandBillingNotesMethodCall = new Subject<any>();

  loadShippingandBillingNotesMethodCall$ =
    this.loadShippingandBillingNotesMethodCall.asObservable();

  triggerShippingBillingNotesDetails(patientId: string) {
    this.loadShippingandBillingNotesMethodCall.next(patientId);
  }

  // Practice Management Physicial Details
  private physicianFunctionCallv1 = new Subject<any>();
  // Observable for function calls
  physicianFunctionCallv1$ = this.physicianFunctionCallv1.asObservable();
  triggerPhysicianTab(practiceId: string) {
    this.physicianFunctionCallv1.next(practiceId);
  }

  // Practice Management List Details
  private practicelistFunctionCall = new Subject<any>();
  // Observable for function calls
  practicelistFunctionCall$ = this.practicelistFunctionCall.asObservable();
  triggerPracticeList(status: number) {
    this.practicelistFunctionCall.next(status);
  }

  // Practice Management Toggle change  Details
  private practicetoogleFunctionCall = new Subject<any>();
  // Observable for function calls
  practicetoogleFunctionCall$ = this.practicetoogleFunctionCall.asObservable();
  triggerPracticetoggle(event: boolean) {
    this.practicetoogleFunctionCall.next(event);
  }

  //InsuranceAllowablesComponent Table

  private insuranceAllowableToggleMethodCall = new Subject<any>();
  // Observable for function calls
  insuranceAllowableToggleMethodCall$ =
    this.insuranceAllowableToggleMethodCall.asObservable();
  triggerInsuranceAllowableToggle() {
    this.insuranceAllowableToggleMethodCall.next();
  }

  private ProductUnitCostToggleMethodCall = new Subject<any>();
  // Observable for function calls
  ProductUnitCostToggleMethodCall$ =
    this.ProductUnitCostToggleMethodCall.asObservable();
  triggerProductUnitCostToggle() {
    this.ProductUnitCostToggleMethodCall.next();
  }

  private insuranceAllowableGetMethodCall = new Subject<any>();
  // Observable for function calls
  insuranceAllowableGetMethodCall$ =
    this.insuranceAllowableGetMethodCall.asObservable();
  triggerInsuranceAllowableGetMethodCall(allowableId: string) {
    this.insuranceAllowableGetMethodCall.next(allowableId);
  }
  private insuranceAllowableGetMethodCallV1 = new Subject<any>();
  // Observable for function calls
  insuranceAllowableGetMethodCallV1$ =
    this.insuranceAllowableGetMethodCallV1.asObservable();
  triggerInsuranceAllowableGetMethodCallV1(allowableId: string) {
    this.insuranceAllowableGetMethodCallV1.next(allowableId);
  }

  private ProductGetMethodCall = new Subject<any>();
  // Observable for function calls
  triggerProductGetMethodCall$ = this.ProductGetMethodCall.asObservable();
  triggerProductGetMethodCall(data: any) {
    this.ProductGetMethodCall.next(data);
  }

  private statusMethodCall = new Subject<any>();
  // Observable for function calls
  statusMethodCall$ = this.statusMethodCall.asObservable();

  triggerStatusGetMethodCall(event: any, productId: any) {
    this.statusMethodCall.next({ event, productId });
  }
  private HandleMethodMethodCall = new Subject<any>();
  // Observable for function calls
  HandleMethodMethodCall$ = this.HandleMethodMethodCall.asObservable();
  triggerHandleTableCellMethodCall(event: any, productId: string) {
    this.HandleMethodMethodCall.next({ event, productId });
  }

  // Product categoryComponent Table
  private productCategoryToggleMethodCall = new Subject<any>();
  // Observable for function calls
  productCategoryToggleMethodCall$ =
    this.productCategoryToggleMethodCall.asObservable();
  triggerProductCategoryToggle() {
    this.productCategoryToggleMethodCall.next();
  }
  private productCategoryGetMethodCall = new Subject<any>();
  // Observable for function calls
  productCategoryGetMethodCall$ =
    this.productCategoryGetMethodCall.asObservable();
  triggerProductCategoryGetMethodCall(categoryId: string) {
    this.productCategoryGetMethodCall.next(categoryId);
  }

  //BillingListPageDetailsComponent to load the billing order list

  private billingOrdersGetMethodCall = new Subject<any>();
  // Observable for function calls
  billingOrdersGetMethodCall$ = this.billingOrdersGetMethodCall.asObservable();
  triggerBillingOrdersGetMethodCall() {
    this.billingOrdersGetMethodCall.next();
  }

  //Complaince Page
  private functionComplainceLsit = new Subject<any>();
  // Observable for function calls
  functionComplainceLsit$ = this.functionComplainceLsit.asObservable();
  // Service method to trigger function calls
  triggerFunctionCallComplainceList(activeTab: number) {
    this.functionComplainceLsit.next(activeTab);
  }

  //Master Notes Table Method Call
  private inventoryMasterNotesCall = new Subject<any>();
  // Observable for function calls
  inventoryMasterNotesCall$ = this.inventoryMasterNotesCall.asObservable();
  triggerMasterNotesGetMethodCall() {
    this.inventoryMasterNotesCall.next();
  }

  //Master Document Bin Method Call
  private inventoryMasterDocumentBin = new Subject<any>();
  // Observable for function calls
  inventoryMasterDocumentBin$ = this.inventoryMasterDocumentBin.asObservable();
  triggerMasterDocumentBinGetMethodCall() {
    this.inventoryMasterDocumentBin.next();
  }

  //MM Insurance Method Call
  private masterMMInsuranceMethod = new Subject<any>();
  // Observable for function calls
  masterMMInsuranceMethod$ = this.masterMMInsuranceMethod.asObservable();
  triggerMastermmInsuranceGetMethodCall() {
    this.masterMMInsuranceMethod.next();
  }

  //Master Provider Method call
  private masterProviderMethod = new Subject<any>();
  // Observable for function calls
  masterProviderMethod$ = this.masterProviderMethod.asObservable();
  triggerMasterProviderGetMethodCall() {
    this.masterProviderMethod.next();
  }

  //PurchaseOrderComponent- To call the purchase order details list
  private functionLoadPurchaseOrderSummary = new Subject<any>();
  // Observable for function calls
  functionLoadPurchaseOrderSummary$ =
    this.functionLoadPurchaseOrderSummary.asObservable();
  // Service method to trigger function calls
  triggerLoadPurchaseOrderSummary(manufacturerIds: any) {
    this.functionLoadPurchaseOrderSummary.next(manufacturerIds);
  }

  //PurchaseOrderComponent- To call the purchase order details list
  private functionLoadPurchaseOrderList = new Subject<any>();
  // Observable for function calls
  functionLoadPurchaseOrderList$ =
    this.functionLoadPurchaseOrderList.asObservable();
  // Service method to trigger function calls
  triggerLoadPurchaseOrderList(manufacturerIds: any) {
    this.functionLoadPurchaseOrderList.next(manufacturerIds);
  }

  //PurchaseOrderComponent- To call the purchase order details list
  private functionLoadOrderList = new Subject<any>();
  // Observable for function calls
  functionLoadOrderList$ = this.functionLoadOrderList.asObservable();
  // Service method to trigger function calls
  triggerLoadOrderList() {
    this.functionLoadOrderList.next();
  }

  private functionLoadRxList = new Subject<any>();
  // Observable for function calls
  functionLoadRxList$ = this.functionLoadRxList.asObservable();
  // Service method to trigger function calls
  triggerLoadRxList() {
    this.functionLoadOrderList.next();
  }

  //Practice Management Fax Number Metho Call
  private functionCallFaxNumber = new Subject<any>();
  // Observable for function calls
  functionCallFaxNumber$ = this.functionCallFaxNumber.asObservable();
  // Service method to trigger function calls
  triggerLoadFaxNumber() {
    this.functionCallFaxNumber.next();
  }

  //Seetings Page -- Call Role Get List
  private functionLoadRoleList = new Subject<any>();
  // Observable for function calls
  functionLoadRoleList$ = this.functionLoadRoleList.asObservable();
  // Service method to trigger function calls
  triggerLoadRoleList() {
    this.functionLoadRoleList.next();
  }

  //USer Tab Componenet
  private functionLoadUserList = new Subject<any>();
  // Observable for function calls
  functionLoadUserList$ = this.functionLoadUserList.asObservable();
  // Service method to trigger function calls
  triggerLoadUSerList(userStatusId: any) {
    this.functionLoadUserList.next(userStatusId);
  }

  //Patient Insurance Tab Policy List Componenet
  private functionLoadPolicyList = new Subject<any>();
  // Observable for function calls
  functionLoadPolicyList$ = this.functionLoadPolicyList.asObservable();
  // Service method to trigger function calls
  triggerLoadPolicyList(userStatusId: any) {
    this.functionLoadPolicyList.next(userStatusId);
  }

  //Update Billing Status - BillingListPageDetailsComponent
  private functionUpdateBillingStatus = new Subject<any>();
  // Observable for function calls
  functionUpdateBillingStatus$ =
    this.functionUpdateBillingStatus.asObservable();
  // Service method to trigger function calls
  triggerUpdateBillingStatus(orderId: any) {
    this.functionUpdateBillingStatus.next(orderId);
  }

  //Master Medicare Check List- Get Method Call
  private functionMasterMedicare = new Subject<any>();
  // Observable for function calls
  functionMasterMedicare$ = this.functionMasterMedicare.asObservable();
  // Service method to trigger function calls
  triggerMasterMedicare(statusId: any) {
    this.functionMasterMedicare.next(statusId);
  }

  //Settings Branch-Get Method Call
  private functionLoadBranchList = new Subject<any>();
  // Observable for function calls
  functionLoadBranchList$ = this.functionLoadBranchList.asObservable();
  // Service method to trigger function calls
  triggerLoadBranchList(statusView: number) {
    this.functionLoadBranchList.next(statusView);
  }

  //Settings FAX/Email-Get Method Call
  private functionLoadFaxList = new Subject<any>();
  // Observable for function calls
  functionLoadFaxList$ = this.functionLoadFaxList.asObservable();
  // Service method to trigger function calls
  triggerLoadFaxList(statusFax: number) {
    this.functionLoadFaxList.next(statusFax);
  }

  //Settings FTP-Get Method Call
  private functionLoadFtpList = new Subject<any>();
  // Observable for function calls
  functionLoadFtpList$ = this.functionLoadFtpList.asObservable();
  // Service method to trigger function calls
  triggerLoadFtpList(statusFtp: number) {
    this.functionLoadFtpList.next(statusFtp);
  }

  //Settings Role Method Call
  private functionLoadRoleListv1 = new Subject<any>();
  // Observable for function calls
  functionLoadRoleListv1$ = this.functionLoadRoleListv1.asObservable();
  // Service method to trigger function calls
  triggerLoadRoleListv1() {
    this.functionLoadRoleListv1.next();
  }

  //Settings WIP Status Method Call
  private functionLoadWipStatusListv1 = new Subject<any>();
  // Observable for function calls
  functionLoadWipStatusListv1$ =
    this.functionLoadWipStatusListv1.asObservable();
  // Service method to trigger function calls
  triggerLoadWipStatusListv1() {
    this.functionLoadWipStatusListv1.next();
  }

  //Settings InviteUser
  private functionLoadInviteUser = new Subject<any>();
  // Observable for function calls
  functionLoadInviteUser$ = this.functionLoadInviteUser.asObservable();
  // Service method to trigger function calls
  triggerLoadInviteUser(inviteStatusRequest: InviteStatusDTO) {
    this.functionLoadInviteUser.next(inviteStatusRequest);
  }

  //ProductTab -To Reload the Product Table
  private functionReloadProductTable = new Subject<any>();
  // Observable for function calls
  functionReloadProductTable$ = this.functionReloadProductTable.asObservable();
  triggerReloadProduct() {
    this.functionReloadProductTable.next();
  }

  //Master - Modifier Table Reload
  private functionReloadModifierTable = new Subject<any>();
  // Observable for function calls
  functionReloadModifierTable$ =
    this.functionReloadModifierTable.asObservable();
  triggerReloadModifier() {
    this.functionReloadModifierTable.next();
  }

  //VendorDetails -To Reload the Product Table
  private functionReloadManufacturerTable = new Subject<any>();
  // Observable for function calls
  functionReloadManufacturerTable$ =
    this.functionReloadManufacturerTable.asObservable();
  triggerReloadManufacturerTable() {
    this.functionReloadManufacturerTable.next();
  }

  //OrderSearchTab -To Reload the Product Table
  private functionReloadOrderTable = new Subject<any>();
  // Observable for function calls
  functionReloadOrderTable$ = this.functionReloadOrderTable.asObservable();
  triggerReloadOrderTable() {
    this.functionReloadOrderTable.next();
  }
  //ProductTab -To Reload the Product Table
  private functionUpdateOverrideStatus = new Subject<any>();
  // Observable for function calls
  functionUpdateOverrideStatus$ =
    this.functionUpdateOverrideStatus.asObservable();
  triggerUpdateOverrideStatus(orderDetails: any) {
    this.functionUpdateOverrideStatus.next(orderDetails);
  }

  //StockListComponent -To Reload the Stock List Table
  private functionReloadStockTable = new Subject<any>();
  // Observable for function calls
  functionReloadStockTable$ = this.functionReloadStockTable.asObservable();
  triggerReloadStockTable() {
    this.functionReloadStockTable.next();
  }

  //Master Discount Value - To Reload the discount Table
  private functionReloadDisocuntTable = new Subject<any>();
  // Observable for function calls
  functionReloadDisocuntTable$ =
    this.functionReloadDisocuntTable.asObservable();
  triggerReloadDiscontTable() {
    this.functionReloadDisocuntTable.next();
  }

  //New Setup Patient Search  - To Patch Details in Form
  private functionPatchPatientDetails = new Subject<any>();
  // Observable for function calls
  functionPatchPatientDetails$ =
    this.functionPatchPatientDetails.asObservable();
  triggerPatchPatientDetails(patientIdInput: any) {
    this.functionPatchPatientDetails.next(patientIdInput);
  }

  //Document Check List Component - To New Setup
  private functionNewSetup = new Subject<any>();
  // Observable for function calls
  functionNewSetup$ = this.functionNewSetup.asObservable();
  triggerNewSetupFunction(isDocument: boolean) {
    this.functionNewSetup.next(isDocument);
  }

  //Plan Save Component - To New Setup
  private functionNewSetupPlanSave = new Subject<any>();
  // Observable for function calls
  functionNewSetupPlanSave$ = this.functionNewSetupPlanSave.asObservable();
  triggerNewSetupPlanSaveFunction(isDocument: boolean) {
    this.functionNewSetupPlanSave.next(isDocument);
  }

  //Move To Bin - To New Setup
  private functionMoveToNewSetup = new Subject<any>();
  // Observable for function calls
  functionMoveToNewSetup$ = this.functionMoveToNewSetup.asObservable();
  triggerMoveToFunction() {
    this.functionMoveToNewSetup.next();
  }

  //New Setup - To Patch the existing patient details in Patient Search Popup
  private functionPatientSearchData = new Subject<any>();
  // Observable for function calls
  functionPatientSearchData$ = this.functionPatientSearchData.asObservable();
  triggerPatientSearchData(data) {
    this.functionPatientSearchData.next(data);
  }

  //Master Template - To Reload
  private functionReloadTemplate = new Subject<any>();
  // Observable for function calls
  functionReloadTemplate$ = this.functionReloadTemplate.asObservable();
  triggerTemplateFunction() {
    this.functionReloadTemplate.next();
  }

  //Schedule Save Trigger - To Reload
  private functionReloadSchedule = new Subject<any>();
  // Observable for function calls
  functionReloadSchedule$ = this.functionReloadSchedule.asObservable();
  triggerScheduleFunction() {
    this.functionReloadSchedule.next();
  }

  //RequisitionApprovalTabComponent - To Trigger the Requisition List load in Requisition Viewer Page
  private functionLoadRequisitionList = new Subject<any>();
  // Observable for function calls
  functionLoadRequisitionList$ =
    this.functionLoadRequisitionList.asObservable();
  triggerLoadRequisitionList() {
    this.functionLoadRequisitionList.next();
  }

  //Default Fax Id  - To Trigger Reload
  private functionLoadDeafultFaxIdList = new Subject<any>();
  // Observable for function calls
  functionLoadDeafultFaxIdList$ =
    this.functionLoadDeafultFaxIdList.asObservable();
  triggerLoadDeaultFaxIdList() {
    this.functionLoadDeafultFaxIdList.next();
  }

  //To Patient Common Sorting Page Reload Check
  private sharedDataisSorting: Subject<any> = new Subject<any>();
  sharedDataisSorting$ = this.sharedDataisSorting.asObservable();
  setisSorting(updatedisSorting) {
    this.sharedDataisSorting.next(updatedisSorting);
  }

  //To Patient Common Sorting Annotation Page Reload Check
  private sharedDataisAnnotate: Subject<any> = new Subject<any>();
  sharedDataisAnnotate$ = this.sharedDataisAnnotate.asObservable();
  setisAnnotate(updatedisAnnotate) {
    this.sharedDataisAnnotate.next(updatedisAnnotate);
  }

  //To Load the Claim Status List
  private loadClaimStatusList: Subject<any> = new Subject<any>();
  loadClaimStatusList$ = this.loadClaimStatusList.asObservable();
  functionLoadClaimStatusList() {
    this.loadClaimStatusList.next();
  }

  //To Load the Fax Finder
  private faxFinderSubject: Subject<any> = new Subject<any>();
  faxFinderSubject$ = this.faxFinderSubject.asObservable();
  triggerFaxFinderEvent(faxFinderInput) {
    this.faxFinderSubject.next(faxFinderInput);
  }

  //To Load the Resupply
  private ResupplySubject: Subject<any> = new Subject<any>();
  ResupplySubject$ = this.ResupplySubject.asObservable();
  triggerResupplyEvent(isCurrentSupplies) {
    this.ResupplySubject.next(isCurrentSupplies);
  }

  //To Remove HCPC Form Input
  private removeHCPCMethodCall = new Subject<any>();
  // Observable for function calls
  removeHCPCMethodCall$ = this.removeHCPCMethodCall.asObservable();
  triggerremoveHCPCMethodCallMethodCall(hcpcCode: string) {
    this.removeHCPCMethodCall.next(hcpcCode);
  }

  //To Add HCPC Form Input
  private addHCPCMethodCall = new Subject<any>();
  // Observable for function calls
  addHCPCMethodCall$ = this.addHCPCMethodCall.asObservable();
  triggerAddHCPCMethodCallMethodCall(hcpcCode: string) {
    this.addHCPCMethodCall.next(hcpcCode);
  }

  //Master Payor Edit
  private masterPayorEditMethodCall = new Subject<any>();
  // Observable for function calls
  masterPayorEditMethodCall$ = this.masterPayorEditMethodCall.asObservable();
  triggermasterPayorEditMethodCall(value: string) {
    this.masterPayorEditMethodCall.next(value);
  }

  //Invoice Tab Reload
  private invoiceTabReloadMethodCall = new Subject<any>();
  // Observable for function calls
  invoiceTabReloadMethodCall$ = this.invoiceTabReloadMethodCall.asObservable();
  triggerInvoiceTabReloadCall() {
    this.invoiceTabReloadMethodCall.next();
  }
}
