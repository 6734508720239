import { PagedAndSortedResultRequestDto } from '@abp/ng.core';
import { HttpErrorResponse } from '@angular/common/http';
import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import {
  CreateUpdateItemEligibilityRulesDTO,
  UploadItemsDTO,
} from 'projects/order/src/app/order-proxy/order-management/inventory/dto/models';
import { ItemEligibilityRulesService } from 'projects/order/src/app/order-proxy/order-management/inventory/item-eligibility-rules.service';
import {
  ItemInsuranceDTO,
  OrderMasterDropdownDTO,
} from 'projects/patient/src/app/dropdown-proxy/dropdown-management/dropdowns/dto/order';
import { OrderMasterDropdownService } from 'projects/patient/src/app/dropdown-proxy/dropdown-management/dropdowns/master-dropdown-service/order-master-dropdown.service';
import { OrderDropdowns } from 'projects/patient/src/app/dropdown-proxy/dropdown-management/dropdowns/order-dropdowns.enum';
import { NumberValidators } from 'projects/shared/src/app/validations/number-validator';
import { TextValidators } from 'projects/shared/src/app/validations/text-validator';
import { Observable } from 'rxjs';
import Swal from 'sweetalert2';
import { ToastrService } from 'ngx-toastr';

import { OrganizationUnitService } from '../admin-proxy/platform-app-management/rcm/platform-management/organization-units/organization-unit.service';
import { ItemInsuranceViewComponent } from '../item-insurance-view/item-insurance-view.component';

// import { ExcelService } from '../excel.service';
@Component({
  selector: 'app-item-supplies',
  templateUrl: './item-supplies.component.html',
  styleUrls: ['./item-supplies.component.scss'],
})
export class ItemSuppliesComponent implements OnInit {
  step: boolean = false;
  billingType: string;
  @Input() clientId: string = '';
  @Output() nextTabMoveOnSaveEmitter: EventEmitter<string> =
    new EventEmitter<string>(null);
  itemId: string = '';
  itemForm: FormGroup;
  txtInsurance: string = '';
  location: string;
  public filteredInsTypes: Observable<ItemInsuranceDTO[]>;
  // drpInsurance: ItemInsuranceDTO[] = [];
  drpLocation: any[] = [];
  isLoading: boolean = false;
  dataSource: MatTableDataSource<any>;
  itemSearchForm: FormGroup;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  displayedColumns: string[] = ['Options', 'ItemId', 'itemName'];
  itemTableData: any[] = [];
  userShow: boolean = false;
  saveButtonHide: boolean;
  isShowSpinner: boolean = false;

  constructor(
    private formBuilder: FormBuilder,
    private numberValidators: NumberValidators,
    private orderDropdownService: OrderMasterDropdownService,
    private branchService: OrganizationUnitService,
    private itemService: ItemEligibilityRulesService,
    public dialog: MatDialog,
    private activatedRoute: ActivatedRoute,
    private textValidators: TextValidators,
    private toastr: ToastrService,
    // private itemController: testService
    // private excelService: ExcelService
    private orderMasterDropdown: OrderMasterDropdownService
  ) {}

  ngOnInit(): void {
    this.activatedRoute.fragment.subscribe((response) => {
      if (response === 'import') {
        this.userShow = false;
      } else {
        this.userShow = true;
      }
      // this.chkStatus = response === 'InActive' ? true : false;
    });
    this.itemForm = this.formBuilder.group({
      // drpInsurance: new FormControl(null, [Validators.required]),
      txtItemName: new FormControl('', [
        Validators.required,
        this.textValidators.isTextCheck,
      ]),
      txtPrice: new FormControl('', [this.numberValidators.isNumberCheck]),
      txtItemId: new FormControl(null, [Validators.required]),
      drpLocation: new FormControl(''),
      txtInsSearchFilter: new FormControl(''),
    });
    // searchFormList
    this.itemSearchForm = this.formBuilder.group({
      itemIdSearch: new FormControl(''),
      itemNameSearch: new FormControl(''),
      insuranceSearch: new FormControl(''),
      priceSearch: new FormControl(''),
    });
    // valueChanges
    this.itemSearchForm.valueChanges.subscribe(
      (value) => {
        let filter = {
          itemIdSearch: value.itemIdSearch.trim().toLowerCase(),
          itemNameSearch: value.itemNameSearch.trim().toLowerCase(),
        };

        if (this.itemSearchForm.valid) {
          this.dataSource.filter = JSON.stringify(filter);
          this.filtertable(filter);
        }
      },
      (err) => {}
    );
    this.getTbleItemList();
  }
  importItemsFormFile(value: any) {
    this.getTbleItemList();
  }

  // Only Numbers with Decimals
  keyPressNumbersDecimal(event) {
    var charCode = event.which ? event.which : event.keyCode;
    if (charCode != 46 && charCode > 31 && (charCode < 48 || charCode > 57)) {
      event.preventDefault();
      return false;
    }
    return;
  }

  // item save
  saveItem() {
    this.isShowSpinner = true;
    this.saveButtonHide = true;
    let itemDetails: CreateUpdateItemEligibilityRulesDTO;

    if (
      this.itemId === '' ||
      this.itemId === null ||
      this.itemId === undefined
    ) {
      itemDetails = {
        locationId: 'cbe',
        itemCode: this.itemForm.value.txtItemId,
        itemName: this.itemForm.value.txtItemName,
        price: +this.itemForm.value.txtPrice,
        rulesValidities: [],
      };
      this.itemService.create(itemDetails).subscribe(
        (response) => {
          this.itemId = response.id;
          //
          // Swal.fire({ title: 'Success', html: 'Saved Successfully', icon: 'success', timer: 3000, timerProgressBar: true });
          this.toastr.success('Saved Successfully', 'Success');
          this.saveButtonHide = false;
          this.getTbleItemList();
          this.resetItem();
          this.isShowSpinner = false;
        },
        (err) => {
          this.saveButtonHide = false;
          this.isShowSpinner = false;
          const data: HttpErrorResponse = err;
          Swal.fire({
            icon: 'info',
            text: data?.error?.error?.message,
          });
        }
      );
    } else {
      this.itemId !== '' &&
        this.itemId !== null &&
        this.itemId !== undefined &&
        this.itemService.get(this.itemId).subscribe((response) => {
          itemDetails = {
            locationId: response.locationId,
            itemCode: this.itemForm.value.txtItemId,
            itemName: this.itemForm.value.txtItemName,
            price: +this.itemForm.value.txtPrice,
            rulesValidities: response.rulesValidities,
          };
          this.itemService.update(this.itemId, itemDetails).subscribe(
            (response) => {
              // Swal.fire({ title: 'Success', html: 'Updated Successfully', icon: 'success', timer: 3000, timerProgressBar: true });
              this.toastr.success('Updated Successfully', 'Success');
              this.getTbleItemList();
              this.resetItem();
              this.isShowSpinner = false;
            },
            (err) => {
              this.isShowSpinner = false;
              const data: HttpErrorResponse = err;
              Swal.fire({
                icon: 'info',
                text: data?.error?.error?.message,
              });
            }
          );
        });
    }
  }

  // item table list
  getTbleItemList() {
    this.itemTableData = [];
    this.dataSource = new MatTableDataSource(this.itemTableData);
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
    this.itemService.getList(new PagedAndSortedResultRequestDto()).subscribe(
      (response) => {
        response &&
          response?.items?.forEach((element) => {
            this.itemTableData.push({
              locationId: element.locationId,
              itemCode: element.itemCode,
              itemName: element.itemName,
              price: element.price,
              id: element.id,
            });
          });
        this.dataSource = new MatTableDataSource(this.itemTableData);
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
      },
      (err) => {
        this.dataSource = new MatTableDataSource(this.itemTableData);
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      }
    );
  }

  // get by id for view item
  ViewItem(data) {
    this.step = !this.step;
    this.itemService.get(data.id).subscribe(
      (response) => {
        this.itemForm.patchValue({
          txtItemName: response.itemName,
          txtPrice: response.price,
          txtItemId: response.itemCode,
          drpLocation: response.locationId,
        });
        this.itemId = response.id;
      },
      (err) => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      }
    );
  }

  // filter records from table list
  filtertable(filter) {
    let itemId = filter.itemIdSearch;
    let itemName = filter.itemNameSearch;
    this.itemTableData = [];
    this.dataSource = new MatTableDataSource(this.itemTableData);
    this.dataSource.sort = this.sort;
    setTimeout(() => (this.dataSource.paginator = this.paginator));
    this.itemService
      .searchItemBySItemCodeAndSItemName(itemId, itemName)
      .subscribe(
        (data) => {
          this.itemTableData = [];
          data?.items.forEach((element) => {
            this.itemTableData.push({
              locationId: element.locationId,
              itemCode: element.itemCode,
              itemName: element.itemName,
              price: element.price,
              id: element.id,
            });
          });

          this.dataSource = new MatTableDataSource(this.itemTableData);
          this.dataSource.sort = this.sort;
          setTimeout(() => (this.dataSource.paginator = this.paginator));
        },
        (err) => {
          this.dataSource = new MatTableDataSource(this.itemTableData);
          this.dataSource.sort = this.sort;
          setTimeout(() => (this.dataSource.paginator = this.paginator));
          const data: HttpErrorResponse = err;
          Swal.fire({
            icon: 'info',
            text: data?.error?.error?.message,
          });
        }
      );
  }

  // delete item from list
  deleteItem(data) {
    Swal.fire({
      title: 'Are you sure you want to Delete?',
      text: "You won't be able to retrieve this data!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#34c38f',
      cancelButtonColor: '#f46a6a',
      confirmButtonText: 'Yes, delete it!',
    }).then((result) => {
      if (result.value) {
        this.itemService.delete(data.id).subscribe(
          (response) => {
            this.itemTableData.forEach((element) => {
              if (data.id == element?.id) {
                let index = this.itemTableData.indexOf(element, 0);
                this.itemTableData.splice(index, 1);
              }
            });
            this.getTbleItemList();
          },
          (err) => {
            const data: HttpErrorResponse = err;

            Swal.fire({
              icon: 'info',
              text: data?.error?.error?.message,
            });
          }
        );
      }
    });
    this.resetItem();
  }
  // template download
  downloadTemplate() {
    this.itemService.downloadItemTemplate().subscribe(
      (response) => {
        let filelocation: String = String(
          'data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,' +
            response
        );
        let link = document.createElement('a');
        link.download = 'QsecureItems' + '.xlsx';
        link.href = filelocation.toString();
        link.click();
      },
      (err) => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      },
      () => {}
    );
  }

  // import item
  importItems(value: any) {
    const fileList = value;
    let itemFile: File = null;
    for (let index = 0; index < fileList.length; index++) {
      if (
        fileList[index].type ===
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      ) {
        itemFile = fileList?.item(index);
      }
    }
    let itemFileResultValue: string = '';
    let reader = new FileReader();
    reader.readAsDataURL(itemFile);
    reader.onload = () => {
      itemFileResultValue = reader.result.slice(78).toString();
      if (itemFileResultValue && itemFileResultValue !== '') {
        const input: UploadItemsDTO = {
          fileContentAsBase64String: itemFileResultValue,
          fileName: itemFile.name,
        };
        this.itemService.importItems(input).subscribe(
          (response) => {
            Swal.fire({
              title: 'Success',
              html: 'Selected File Imported Successfully',
              icon: 'success',
              timer: 3000,
              timerProgressBar: true,
            }).then((result) => {
              this.getTbleItemList();
            });
          },
          (err) => {
            const data: HttpErrorResponse = err;
            Swal.fire({
              icon: 'info',
              text: data?.error?.error?.message,
            });
          }
        );
      }
    };
  }

  // insurance edit and view by popup
  insuranceItem(data) {
    const dialogRef = this.dialog.open(ItemInsuranceViewComponent, {
      disableClose: true,
      data: { itemId: data.id },
    });
    dialogRef.afterClosed().subscribe(() => {
      this.itemId !== '' && this.itemId !== null && this.itemId !== undefined;
    });
  }

  //! Insurance Form Reset Function
  resetItem() {
    this.itemForm.patchValue({
      // drpInsurance: null,
      txtItemName: '',
      txtPrice: '',
      txtItemId: '',
      // drpLocation: "",
    });
    this.itemForm.markAsUntouched();
    this.itemForm.updateValueAndValidity();
    this.itemId = '';
    this.itemForm.reset();
  }

  clearInsType() {
    this.txtInsurance = '';
    // this.itemForm.patchValue({ drpInsurance: '' });
    let orderDropdownInput: OrderDropdowns[];
    orderDropdownInput = [OrderDropdowns.ItemInsurances];
    this.orderDropdownService
      .getOrderDropdownsByInput(orderDropdownInput)
      .subscribe(
        (stateResponse) => {
          let response: OrderMasterDropdownDTO = stateResponse;
          // this.drpInsurance = response['itemInsurances'];
        },
        (err) => {
          const data: HttpErrorResponse = err;
          Swal.fire({
            icon: 'info',
            text: data?.error?.error?.message,
          });
        }
      );
  }

  clearLocation() {
    this.location = '';
    this.itemForm.patchValue({ drpLocation: '' });
  }

  numberOnly(event): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }
}
