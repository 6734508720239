import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-common-model-pdfviewer-base64',
  templateUrl: './common-model-pdfviewer-base64.component.html',
  styleUrls: ['./common-model-pdfviewer-base64.component.scss'],
})
export class CommonModelPDFViewerBase64Component implements OnInit {
  isPdfLoad: boolean = true;
  pdfheight: string = '100%';

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: { base64: string } = {
      base64: '',
    }
  ) {}

  ngOnInit(): void {
    this.isPdfLoad = false;
    setTimeout(() => {
      this.isPdfLoad = true;
    }, 500);

  }
}
