<div class="container-fluid">
  <!-- Header -->
  <div class="row">
    <div class="col-12 d-flex justify-content-between">
      <h1 class="header-text">Pickup - Item Details ({{ data.mmOrderId }})</h1>
      <button class="close" mat-dialog-close>
        <mat-icon>close</mat-icon>
      </button>
    </div>
  </div>

  <!-- Content -->

  <div class="row">
    <div class="col-12">
      <div class="card card-body">
        <div class="table-responsive">
          <div class="d-flex justify-content-center" *ngIf="!isTableLoaded">
            <img
              class="img-responsive pb-2 mr-1"
              src="assets/gifs/Infinity-loader.gif"
            />
          </div>

          <table
            datatable
            *ngIf="isTableLoaded"
            [dtOptions]="dtTableOptions"
            class="row-border hover w-100 display"
          >
            <thead>
              <tr>
                <th matTooltip="Select All">
                  <mat-checkbox
                    [(ngModel)]="checkAll"
                    (change)="checkAllItems($event)"
                  ></mat-checkbox>
                </th>
                <th matTooltip="Product Code">Product Code</th>
                <th matTooltip="Product Description">Product Description</th>
                <th matTooltip="Size">Size</th>
                <th matTooltip="Serial No">Serial No</th>
                <th matTooltip="Quantity">Quantity</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let data of ltItems">
                <td>
                  <mat-checkbox
                    class="checkbox-item"
                    (change)="checkAllItemsSelected()"
                    [(ngModel)]="data.checked"
                  ></mat-checkbox>
                </td>
                <td [matTooltip]="data.productCode">{{ data.productCode }}</td>
                <td
                  [matTooltip]="
                    data?.productDescription
                      ? data?.productDescription
                      : data?.description
                  "
                >
                  {{
                    data?.productDescription
                      ? data?.productDescription
                      : data?.description
                  }}
                </td>
                <td [matTooltip]="data.sizeName || '-'">
                  {{ data.sizeName || "-" }}
                </td>
                <td [matTooltip]="data.serialLotName || '-'">
                  {{ data.serialLotName || "-" }}
                </td>
                <td [matTooltip]="data.qty || '-'">{{ data.qty || "-" }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div class="d-flex flex-row-reverse mt-2">
        <button
          [disabled]="checkValidation"
          (click)="pickupOrder(false)"
          class="buttonColor"
          mat-raised-button
        >
          Pickup
        </button>
        <button
          [disabled]="checkValidation || data.exchangeOrReturn == 'Exchange'"
          [matTooltip]="
            data.exchangeOrReturn == 'Exchange' ? 'Order already exchanged' : ''
          "
          (click)="pickupOrder(true)"
          class="buttonColor mr-2"
          mat-raised-button
        >
          Pickup & Exchange
        </button>
      </div>
    </div>
  </div>
</div>
<app-loader-component *ngIf="isPageLoading"></app-loader-component>
