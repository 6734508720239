<table datatable [dtOptions]="reportTableOptions" class="row-border hover w-100 display">
  <thead>
    <tr>
      <th><mat-icon>apps</mat-icon></th>
      <th [matTooltip]="'Chart Noo'">Chart No</th>
      <th [matTooltip]="'Patient Name'">Patient Name</th>
      <th [matTooltip]="'DOB'">DOB</th>
      <th [matTooltip]="'Practitioner Name'">Practitioner Name</th>
      <th [matTooltip]="'RX Date'">RX Date</th>
      <th [matTooltip]="'CSR'">CSR </th>
      <th [matTooltip]="'Last Mask Date'">Last Mask Date</th>
      <th [matTooltip]="'Order Date'">Order Date</th>
      <th [matTooltip]="'RX Expiry Date'">RX Expiry Date</th>
      <th [matTooltip]="'Expiry Days'">Expiry Days</th>
      <th [matTooltip]="'Mail Status'">Mail Status</th>
      <th [matTooltip]="'RX Status'">Rx Status</th>

    </tr>
  </thead>
  <tbody>
    <!-- (dblclick)="navigateToOrderPage(data.patientId, data.id)" -->
    <tr *ngFor="let data of reportTableData">
      <td>
        <a [matTooltip]="'Open RX Email Print Letter'"
          [routerLink]="'/RxEmailPrintLetter/'+ data.patientId +'/'+ data.documentId" target="_blank">
          <img class="img-responsive pr-2 mb-2 cursor ml-1" src="assets/Claim Page Icons/upload.png" alt=""
            height="16" />
        </a>
        <a [matTooltip]="'Document Preview'" (click)="previewRXDocument(data?.patientId,data?.documentId)"
          target="_blank" class="preview-link pointer">
          <img class="img-responsive pr-2 mb-2 cursor ml-1" src="assets/Claim Page Icons/DocSigned.png" alt=""
            height="17" /></a>

        <!-- [disabled]="!data.isRxDocAvailable" -->
        <!-- <mat-checkbox [matTooltip]="'Select Multiple RX Email Print Letter'" [disabled]="data.rxcheckBoxDisabled" (change)="matCheckBox($event,data)" [(ngModel)]="data.rxcheckBox"></mat-checkbox> -->

      </td>
      <td [matTooltip]="data?.chartId || '-'">
        <a [class.disabled]="isEmpty(data.chartId) || isEmpty(data.patientId)" class="preview-link" [routerLink]="'/patientCreateEditTab/' + data.patientId" target="_blank">
          {{ data.chartId  || "-" }}
        </a>
      </td>
      <td [matTooltip]="data?.patientName || '-'">{{data.patientName}}

      </td>
      <td [matTooltip]=" data?.dob">
        {{ data.dob }}
      </td>
      <td [matTooltip]="data?.practitionerName || '-'">{{data.practitionerName}}</td>
      <td [matTooltip]="data?.rxDate"> {{ data.rxDate }}</td>
      <td [matTooltip]="data?.csrName || '-'">
        {{ data.csrName }}
      </td>

      <td [matTooltip]="data?.lastMaskDate">
        {{ data?.lastMaskDate }}
      </td>
      <td [matTooltip]="data?.orderDate">
        {{ data.orderDate }}
      </td>
      <td [matTooltip]="data?.rxExpiryDate">
        {{ data.rxExpiryDate }}
      </td>
      <td [matTooltip]=" data.expiryDays">
        <button mat-raised-button class="mr-2 p-2 dd-text pointerevent"
          [ngClass]=" data.expiryDays <= 0 ? 'expiredclr': 'notexpiredclr' ">
          {{ data.expiryDays}}
        </button>
      </td>

      <td [matTooltip]="data?.rxMailStatus || '-'">
        {{ data.rxMailStatus || '-' }}
      </td>

      <td [matTooltip]="data?.status || '-'">
        {{ data.status || '-' }}
      </td>

    </tr>
  </tbody>
  <tfoot>
    <tr>
      <th><mat-icon>apps</mat-icon></th>
      <th>
        <input matInput class="textbox-border-class" type="text" placeholder="Chart No" />
      </th>
      <th>
        <input matInput class="textbox-border-class" type="text" placeholder="Patient Name" />
      </th>
      <th>
        <input matInput class="textbox-border-class" type="text" placeholder="DOB" />
      </th>
      <th>
        <input matInput class="textbox-border-class" type="text" placeholder="Practitioner Name" />
      </th>
      <th>
        <input matInput class="textbox-border-class" type="text" placeholder="RX Date" />
      </th>
      <th>
        <input matInput class="textbox-border-class" type="text" placeholder="CSR" />
      </th>
      <th>
        <input matInput class="textbox-border-class" type="text" placeholder="Last Mask Date" />
      </th>
      <th>
        <input matInput class="textbox-border-class" type="text" placeholder="Order Date" />
      </th>
      <th>
        <input matInput class="textbox-border-class" type="text" placeholder="RX Expiry Date" />
      </th>
      <th>
        <input matInput class="textbox-border-class" type="text" placeholder="Expiry Days" />
      </th>
      <th>
        <input matInput class="textbox-border-class" type="text" placeholder="Mail Status" />
      </th>
      <th>
        <input matInput class="textbox-border-class" type="text" placeholder="RX Status" />
      </th>
    </tr>
  </tfoot>
</table>