<div class="container-fluid">
  <div class="row bg">
    <!-- Header -->
    <div class="col-12">
      <div class="d-flex justify-content-between">
        <div class="page-title-box page-title-left pb-0">
          <h3 class="font-weight-bolder mb-0">
            <b class="header-class">Inbox</b>
          </h3>
        </div>

        <div class="page-title-box page-title-right pb-0">
          <form [formGroup]="fmAdminDashboard">
            <div class="d-flex align-items-center">
              <!-- CSR Dropdown -->
              <mat-form-field class="pr-2 pt-1">
                <mat-label>CSR</mat-label>
                <mat-select maxlength="40" formControlName="drpCSR" #singleSelect [(ngModel)]="seletedCSRValue"
                  (selectionChange)="getDashboardData()">
                  <mat-option>
                    <ngx-mat-select-search hideClearSearchButton="true" placeholderLabel="Search CSR"
                      [formControl]="CSRFilterControl" noEntriesFoundLabel="No Matches found"
                      (keyup)="UserSearch($event.target.value)"></ngx-mat-select-search>
                  </mat-option>
                  <mat-option *ngFor="let csr of filteredCSRList" [value]="csr.userId">
                    {{ csr.name }}
                  </mat-option>
                </mat-select>
                <!-- <button type="button" mat-button (click)="clearUser($event)" matSuffix mat-icon-button>
                  <mat-icon>close</mat-icon>
                </button> -->
              </mat-form-field>

              <!-- Location Dropdown -->
              <mat-form-field class="pr-2 pt-1">
                <mat-label>Location</mat-label>
                <mat-select #selectBranch formControlName="drpBranchType" [(ngModel)]="seletedBranchValue"
                  (selectionChange)="getDashboardData()">
                  <mat-option>
                    <ngx-mat-select-search [formControl]="branchFilterControl" placeholderLabel="Search"
                      noEntriesFoundLabel="No Matches found" formControlName="txtBranch"></ngx-mat-select-search>
                  </mat-option>
                  <mat-option *ngFor="let branch of filteredBranchTypeList" [value]="branch.id">
                    {{ branch.organizationUnitName }}
                  </mat-option>
                </mat-select>
              </mat-form-field>

              <!-- Date Picker -->
              <mat-form-field class="pr-2 pt-1">
                <input placeholder="Month" formControlName="txtDateValue" matInput [(value)]="dateValue" readonly (focus)="openDatePicker()" />
                <input matInput maxlength="40" formControlName="txtDate" type="text" [hidden]="true" autocomplete="new-txtDateValue" matInput [(value)]="dateValue" [matDatepicker]="txtDateValue" [max]="maxDate" />
                <mat-datepicker-toggle matSuffix [for]="txtDateValue"></mat-datepicker-toggle>
                <mat-datepicker #txtDateValue startView="multi-year" [startAt]="selectedDate" (monthSelected)="chosenMonthHandler($event, txtDateValue)"></mat-datepicker>
              </mat-form-field>

              <!-- <mat-form-field class="pr-2 pt-1">
                <input placeholder="Month" formControlName="txtDateValue" matInput [(value)]="dateValue" readonly
                  (focus)="openDatePicker()" />
                <input matInput maxlength="40" formControlName="txtDate" type="text" [hidden]="true"
                  autocomplete="new-txtDateValue" matInput [(value)]="dateValue" [matDatepicker]="txtDateValue"
                  [max]="maxDate" />
                <mat-datepicker-toggle matSuffix [for]="txtDateValue"></mat-datepicker-toggle>
                <mat-datepicker #txtDateValue startView="multi-year"
                  (monthSelected)="chosenMonthHandler($event, txtDateValue)"></mat-datepicker>
              </mat-form-field> -->
              <li>
                <button mat-button class="buttonColor font-size-10 mb-2 add-fax" (click)="addFaxDocuments()">
                  <i class="fa fa-upload mr-1" matTooltip="Upload Fax"></i>
                </button>
              </li>
            </div>
          </form>
        </div>
      </div>
    </div>

    <div class="col-lg-12 pb-5">
      <div class="row">
        <!-- Tree View -->
        <div class="col-md-4 col-lg-3 col-xl-2">
          <div class="card d-flex justify-content-center elevated-div mytree p-2 overflow-hidden" style="height: 75vh">
            <div class="d-flex justify-content-center" *ngIf="!isTreeLoad">
              <img class="img-responsive pb-2 mr-1" src="assets/gifs/Infinity-loader.gif" />
            </div>
            <div class="total-count-label">
              <b class="pointer card-content-medium" [ngClass]="selectedNode === null ? 'my-fax-highlighted' : ''"
                (click)="reloadDashboard(null, 'Default', true, $event)">My Fax ({{ totalFaxCount }})</b>
              <!-- <img src="assets/images/DashboardIcons/Group 2931.png" alt="icon" class="sea-green-icon pointer"
                (click)="openFaxFinderModalLazyLoading()"> -->
              <img src="assets/images/DashboardIcons/Group 2931.png" alt="icon" class="sea-green-icon pointer"
                (click)="openFaxFinderModalLazyLoadingV1()" [matTooltip]="'Fax Finder'">
            </div>

            <div *ngIf="isTreeLoad" class="scrollable-content">
              <mat-tree [dataSource]="dataSource" [treeControl]="treeControl">
                <mat-nested-tree-node *matTreeNodeDef="let node">
                  <li>
                    <div class="mat-tree-node setBorder"
                      (click)="reloadDashboard(node, node.statusShortCodeId, node.isBranch, $event)"
                      [ngClass]="node?.id === selectedNode?.id && node?.branchId === selectedNode?.branchId ? 'highlighted' : '' ">
                      <span matTooltip="{{ node.item }}" matTooltipPosition="above" matTooltipShowDelay="500"
                        class="clickable-text customfontsize" style="overflow: hidden; text-overflow: ellipsis;">
                        {{ node.item }} ({{ node.count }})
                      </span>
                    </div>
                  </li>
                </mat-nested-tree-node>
                <mat-nested-tree-node *matTreeNodeDef="let node; when: hasChildren">
                  <li class="example-tree-container borderMain">
                    <div (click)="myExpand(node)">
                      <div class="mat-tree-node" (click)="
                        reloadDashboard(node, node.statusShortCodeId, node.isBranch, $event)
                      " [ngClass]="
                        node?.id === selectedNode?.id &&
                        node?.branchId === selectedNode?.branchId
                          ? 'highlighted'
                          : ''
                      ">
                        <button mat-icon-button matTreeNodeToggle [matTreeNodeToggleRecursive]="recursive"
                          [attr.aria-label]="'toggle ' + node.filename"
                          (click)="reloadDashboard(node, node.statusShortCodeId, node.isBranch, $event)">
                          <mat-icon>
                            {{
                            treeControl.isExpanded(node)
                            ? "expand_more"
                            : "chevron_right"
                            }}

                          </mat-icon>
                        </button>
                        <span matTooltip="{{ node.item +'/'+'Average Order Creation Days' }}" matTooltipPosition="above"
                          matTooltipShowDelay="500" class="clickable-text customfontsize"
                          style="overflow: hidden; text-overflow: ellipsis;">
                          {{ node.item }} ({{ node.count }})
                        </span>
                      </div>

                    </div>

                    <ul class="example-tree-nested-node">
                      <div *ngIf="treeControl.isExpanded(node)">
                        <ng-container matTreeNodeOutlet></ng-container>
                      </div>
                    </ul>
                  </li>
                </mat-nested-tree-node>
              </mat-tree>
            </div>
          </div>
        </div>

        <!-- Main Content -->
        <div class="col-md-8 col-lg-9 col-xl-10 overflow-auto" style="height: 75vh">
          <!-- Row 1 -->
          <div class="row mb-1" *ngIf="strPageType === 'Default' && isTabclick !== 'checked'">
            <div class="col-md-6 col-lg-6 col-xl-3" *ngIf="!isgetCSROrderStatDetails">
              <div class="card elevated-div" style="border-radius: 0.5rem;border: 2px solid #80808057">
                <ul class="list-group list-group-flush">
                  <li class="list-group-item custom-widget cus-lable-text">
                    Pending w/ Pmt
                    <span class="pull-right counter-label label label-success pointer" #counterLabel (click)="orderworksheet()" [matTooltip]="'View Order Work Sheet'">
                      {{ csrOrderDetails?.pendingwPmt || 0 }}
                    </span>
                  </li>
                  <li class="list-group-item custom-widget cus-lable-text">
                    Pending in Billing
                    <span class="pull-right counter-label label label-success pointer" #counterLabel (click)="orderworksheet()" [matTooltip]="'View Order Work Sheet'">
                      {{ csrOrderDetails?.pendingInBilling || 0 }}
                    </span>
                  </li>
                  <li class="list-group-item custom-widget cus-lable-text">
                    Disapproved-Shipping
                    <span class="pull-right counter-label label label-success pointer" #counterLabel (click)="orderworksheet()" [matTooltip]="'View Order Work Sheet'">
                      {{ csrOrderDetails?.disapprovedShipping || 0 }}
                    </span>
                  </li>
                  <li class="list-group-item custom-widget cus-lable-text">
                    Pending Invoices
                    <span class="pull-right counter-label label label-success pointer" #counterLabel (click)="orderworksheet()" [matTooltip]="'View Order Work Sheet'">
                      {{ csrOrderDetails?.pendingInvoice || 0 }}
                    </span>
                  </li>
                  <li class="list-group-item custom-widget cus-lable-text">
                    Paid Invoices
                    <span class="pull-right counter-label label label-success pointer" #counterLabel (click)="orderworksheet()" [matTooltip]="'View Order Work Sheet'">
                      {{ csrOrderDetails?.paidInvoice || 0 }}
                    </span>
                  </li>
                </ul>
              </div>
            </div>

            <div class="col-md-6 col-lg-6 col-xl-3" *ngIf="!isgetCSROrderStatDetails">
              <div class="card elevated-div" style="border-radius: 0.5rem;border: 2px solid #80808057">
                <ul class="list-group list-group-flush">
                  <li class="list-group-item custom-widget cus-lable-text">
                    Pending OLD
                    <span class="pull-right counter-label label label-success pointer" #counterLabel (click)="orderworksheet()" [matTooltip]="'View Order Work Sheet'">
                      {{ csrOrderDetails?.pendingOld || 0 }}
                    </span>
                  </li>
                  <li class="list-group-item custom-widget cus-lable-text">
                    Pending
                    <span class="pull-right counter-label label label-success pointer" #counterLabel (click)="orderworksheet()" [matTooltip]="'View Order Work Sheet'">
                      {{ csrOrderDetails?.pending || 0 }}
                    </span>
                  </li>
                  <li class="list-group-item custom-widget cus-lable-text">
                    <!-- <span class="custom-widget ">PTS/Pro Hurdles</span> -->
                    PTS/Pro Hurdles
                    <span class="pull-right label label-success pointer" #counterLabel (click)="orderworksheet()" [matTooltip]="'View Order Work Sheet'">
                      {{ csrOrderDetails?.pointsPromoHurdle || 0 }}
                    </span>
                  </li>
                  <li class="list-group-item custom-widget cus-lable-text">
                    Unpaid Invoices
                    <span class="pull-right counter-label label label-success pointer" #counterLabel (click)="orderworksheet()" [matTooltip]="'View Order Work Sheet'">
                      {{ csrOrderDetails?.unPaidInvoice || 0 }}
                    </span>
                  </li>
                  <li class="list-group-item custom-widget cus-lable-text">
                    Marked as Paid Invoices
                    <span class="pull-right counter-label label label-success pointer" #counterLabel (click)="orderworksheet()" [matTooltip]="'View Order Work Sheet'">
                      {{ csrOrderDetails?.markAsPaidInvoice || 0 }}
                    </span>
                  </li>
                </ul>
              </div>
            </div>

            <div class="col-md-6 col-lg-6 col-xl-3" *ngIf="!isgetCSROrderStatDetails">
                <div class="card card-body p-2 pb-3 cnmReport hover-effect">
                  <div class="row">
                     <div class="col-11" style="padding-left: 20px">
                       <h1 ><b>PAR Report</b></h1>
                       <div class="d-flex">
                        <span class="label label-success bordered" #counterLabel>
                          Total - {{parTotalCount}}
                        </span>
                        <span class="label label-success ml-3 bordered" #counterLabel>
                          Expired - {{parExpiredcount}}
                        </span>
                      </div>
                     </div>
                     <div class="col-1">
                      <!-- <i class="fa fa-angle-right"></i> -->
                     </div>
                 </div>
               </div>
            </div>

            <div class="col-md-12 col-lg-12 col-xl-6" *ngIf="isgetCSROrderStatDetails" class="blinking">
              Loading...
            </div>

            <div class="col-md-12 col-lg-12 col-xl-6"
              *ngIf="Csr3MonthStatisticsDetails?.length >= 1 && !isgetCsr3MonthsStatisticsDetails">
              <h3 class="app-custom-color font-weight-bold  mb-0">
                3-MONTH PERFORMANCE STATISTICS
              </h3>
              <div class="card mb-2 elevated-div">
                <div class="table-responsive">
                  <table id="tblMonPerformanceStatistics" datatable [dtOptions]="dtMonthlyProductOptions"
                    class="tbl hover row-border hover w-100 display">
                    <thead>
                      <tr>
                        <th [matTooltip]="'Month'">Month</th>
                        <th [matTooltip]="'Orders'">Orders</th>
                        <th [matTooltip]="'Machines'">Machines</th>
                        <th [matTooltip]="'Cushion'">Cushion</th>
                        <th [matTooltip]="'Tubing'">Tubing</th>
                        <th [matTooltip]="'Filters'">Filters</th>
                        <th [matTooltip]="'Completed Ponits'">Comp-PTS</th>
                        <th [matTooltip]="'Mask Month Prevoius Year'">Msk-Mon-Pre-Yr</th>
                        <th [matTooltip]="'Mask Current Month'">Msk-Cur-Mon</th>
                        <th [matTooltip]="'%Growth'">%Growth</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let data of Csr3MonthStatisticsDetails">
                        <td [matTooltip]="data.month  || '-'">{{ data.month || '-' }}</td>
                        <td [matTooltip]="data.orders || '-'" class="text-right">{{ data.orders || '-'}}</td>
                        <td [matTooltip]="data.machineCount || '-'" class="text-right">{{ data.machineCount || '-' }}
                        </td>
                        <td [matTooltip]="data.cushionCount || '-'" class="text-right">{{ data.cushionCount || '-' }}
                        </td>
                        <td [matTooltip]="data.tubingCount || '-'" class="text-right">{{ data.tubingCount || '-' }}</td>
                        <td [matTooltip]="data.filterCount || '-'" class="text-right">{{ data.filterCount || '-'}}</td>
                        <td [matTooltip]="data.totalPoints || '-'" class="text-right">{{ data.totalPoints || '-' }}</td>
                        <td [matTooltip]="data.pyrMaskCount || '-'" class="text-right">{{ data.pyrMaskCount || '-' }}
                        </td>
                        <td [matTooltip]="data.maskCount || '-'" class="text-right">{{ data.maskCount || '-' }}</td>
                        <td [matTooltip]="data.growth || '-'" class="text-navy text-right">
                          <i class="fa fa-level-up"></i>{{ data.growthPercentage || '-' }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

            <div class="col-md-12 col-lg-12 col-xl-6" *ngIf="isgetCsr3MonthsStatisticsDetails" class="blinking">
              Loading...
            </div>

          </div>

          <!-- Row 2-->
          <!-- Product Exchange Table-->
          <div class="row mt-2" *ngIf="strPageType === 'Default' && isTabclick !== 'checked'">
            <div class="col-lg-12" *ngIf="CsrWiseCommissionDetails?.length >= 1 && !isgetCsrWiseCommissionDetails">
              <h3 class="font-weight-bold cutompoint">
                CSR POINTS TABLE
              </h3>
              <div class="card p-2">
                <h3 class="font-weight-bold cutompoint">
                  CSR POINTS TABLE
                </h3>
                <div class="table-responsive">
                  <table id="tblCSRPonits" datatable [dtOptions]="dtCSRTableOptions"
                    class="tbl hover row-border hover w-100 display">
                    <thead>
                      <tr>
                        <th [matTooltip]="'CSR Name'">CSR Name</th>
                        <th [matTooltip]="'Orders'" class="text-right">Orders</th>
                        <th [matTooltip]="'Number of Unique Patients who have valid orders this month'" class="text-right">Unique Pts</th>
                        <th [matTooltip]="'Masks'" class="text-right">Masks</th>
                        <th [matTooltip]="'Points'" class="text-right">Points</th>
                        <th
                          [matTooltip]="'
                        [Home CSR-Commission is current and is computed on the lower scale while total number of masks for the current month is below 200] [Office CSR-Commission is current and is computed when you have earned 750pts or more for the current month based on the point-scale system]'">
                          Commission $</th>
                        <th [matTooltip]="'Number of Patients currently assigned to CSR'" class="text-right">PTS Assigned</th>
                        <th [matTooltip]="'
                        Number of Unique Patients serviced by CSR within 3 months'" class="text-right">PTS Serviced</th>
                        <th
                          [matTooltip]="'
                        Number of Patients assigned to CSR who have not been serviced by assigned CSR or any other employee within 3 months'" class="text-right">
                          PTS NOT Serviced</th>
                        <th
                          [matTooltip]="'Percentage of assigned Patients who have been serviced by assigned CSR or any other employee within 3 months'" class="text-right">
                          % PTS Serviced</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let data of CsrWiseCommissionDetails">
                        <td [matTooltip]="data.name || '-'">{{ data.name || '-' }}</td>
                        <td [matTooltip]="data.orders || '-'" class="text-right">{{ data.orders || '-' }}</td>
                        <td [matTooltip]="data.uniquPoints || '-'" class="text-right">{{ data.uniquPoints || '-' }}</td>
                        <td [matTooltip]="data.masks || '-'" class="text-right">{{ data.masks || '-' }}</td>
                        <td [matTooltip]="data.points || '-'" class="text-right">{{ data.points || '-' }}</td>
                        <td [matTooltip]="data.commission || '-'" class="text-right">{{ data.commission || '-' }}</td>
                        <td [matTooltip]="data.assigned || '-'" class="text-right">{{ data.assigned || '-' }}</td>
                        <td [matTooltip]="data.serviced || '-'" class="text-right">{{ data.serviced || '-' }}</td>
                        <td [matTooltip]="data.notServiced || '-'" class="text-right">{{ data.notServiced || '-' }}</td>
                        <td [matTooltip]="data.pointServiced || '-'" class="text-navy text-right">
                          <i class="fa fa-level-up"></i>{{ data.pointServiced || '-'}}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div class="ol-lg-12" *ngIf="isgetCsrWiseCommissionDetails" class="blinking">
              Loading...
            </div>
          </div>

          <!-- Row 3-->
          <!-- Product Exchange Table-->
          <div class="row mt-3 mb-2" *ngIf="strPageType === 'Default' && isTabclick !== 'checked'">

            <div class="col-lg-6" *ngIf="DashboardExchangesDetails?.length >= 1 && !isgetOrderExchangeListDetails">
              <h3 class="app-custom-color font-weight-bold">
                PRODUCT EXCHANGE DETAILS
              </h3>
              <div class="card mb-2 elevated-div">
                <div class="table-responsive">
                  <table datatable [dtOptions]="dtOverrideTableOptions"
                    class="tbl hover row-border hover w-100 display">
                    <thead>
                      <tr>
                        <th [matTooltip]="'Product'">Product</th>
                        <th [matTooltip]="'Description'">Description</th>
                        <th class="text-right" [matTooltip]="'Exchanges'">Exchanges</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let data of DashboardExchangesDetails">
                        <td [matTooltip]="data.productName || '-'">{{ data.productName || '-'}}</td>
                        <td [matTooltip]="data.description || '-'">{{ data.description || '-' }}</td>
                        <td [matTooltip]="data.exchanges || '-'" class="text-right">{{ data.exchanges || '-' }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

            <div class="col-lg-6" *ngIf="isgetOrderExchangeListDetails" class="blinking">
              Loading...
            </div>

            <div class="col-lg-6" *ngIf="OverrideDetails?.length >= 1 && !isgetOverrideListDetails">
              <h3 class="app-custom-color font-weight-bold">
                OVERRIDE LIST
              </h3>
              <div class="card mb-2 elevated-div">
                <div class="table-responsive">
                  <table datatable [dtOptions]="dtProductTableOptions" class="tbl hover row-border hover w-100 display">
                    <thead>
                      <tr>
                        <th [matTooltip]="'Option'"><mat-icon>apps</mat-icon></th>
                        <th [matTooltip]="'Patient Name'">Patient Name</th>
                        <th [matTooltip]="'Ticket Id'">Ticket Id</th>
                        <th [matTooltip]="'Ordered Date'">Ordered Date</th>
                        <th [matTooltip]="'Override Date'">Override Date</th>
                        <th [matTooltip]="'Override By'">Override By</th>
                        <th [matTooltip]="'Notes'">Notes</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let data of OverrideDetails">
                        <td>
                          <!-- [disabled]="isApprovedDisabled" -->

                          <mat-icon [matTooltip]="'Approve'" mat-icon-button
                            (click)="!isApprovedDisabled && approveDisapproveOverride(data.mmOrderId, data.chartNo, true)"
                            class="buttonColor mr-2 pointer text-center rounded-circle">
                            check
                          </mat-icon>
                          <!-- [disabled]="isdisApprovedDisabled" -->
                          <mat-icon [matTooltip]="'DisApprove'" mat-icon-button
                            (click)="!isdisApprovedDisabled && approveDisapproveOverride(data.mmOrderId, data.mmRefereceOrderId, false)"
                            class="resetclr rounded-circle pointer text-center">
                            clear
                          </mat-icon>

                        </td>
                        <td [matTooltip]="data.fullName || '-'">{{ data.fullName || '-' }}</td>
                        <td [matTooltip]="data.mmRefereceOrderId || '-'" class="text-right">
                          <a class="preview-link" [class.disabled]="
          isEmpty(data.chartId) || isEmpty(data.patientId)
        " [routerLink]="
          '/orders/saleOrder/' +
          data.patientId +
          '/' +
          data.mmOrderId+'/'+'0'
        " target="_blank">{{
                            data?.mmRefereceOrderId
                            ? data?.mmRefereceOrderId
                            : "-"
                            }}
                          </a>
                        </td>
                        <td [matTooltip]="data.orderedDate || '-'">{{ data.orderedDate || '-' }}</td>
                        <td [matTooltip]="data.overrideDate || '-'">{{ data.overrideDate || '-' }}</td>
                        <td [matTooltip]="data.csrName || '-'">{{ data.csrName || '-' }}</td>
                        <td><i [matTooltip]="data.overrideNote || '-'" class="fa fa-sticky-note" aria-hidden="true"></i>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

            <div class="col-lg-6" *ngIf="isgetOverrideListDetails" class="blinking">
              Loading...
            </div>


            <!--

            <div class="col-lg-6">
              <h3 class="app-custom-color font-weight-bold">
                SUPPLIES DETAILS
              </h3>
              <div class="card mb-2 elevated-div">
                <div class="table-responsive">
                  <table datatable [dtOptions]="dtSuppliesTableOptions"
                    class="tbl hover row-border hover w-100 display">
                    <thead>
                      <tr class="text-left">
                        <th [matTooltip]="'Supplies'">Supplies</th>
                        <th [matTooltip]="'Patient'">Patient</th>
                        <th [matTooltip]="'Chart'">Chart</th>
                        <th [matTooltip]="'Insurance'">Ins</th>
                        <th [matTooltip]="'CreatedBy'">CreatedBy</th>
                        <th [matTooltip]="'Status'">Status</th>
                        <th [matTooltip]="'Since'">Since</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let data of suppliesTableData">
                        <td [matTooltip]="data.supplies" class="text-left">{{ data.supplies }}</td>
                        <td [matTooltip]="data.patient" class="text-left">{{ data.patient }}</td>
                        <td [matTooltip]="data.chart" class="text-left">{{ data.chart }}</td>
                        <td [matTooltip]="data.ins" class="text-left">{{ data.ins }}</td>
                        <td [matTooltip]="data.createdBy" class="text-left">{{ data.createdBy }}</td>
                        <td [matTooltip]="data.status" class="text-left">{{ data.status }}</td>
                        <td [matTooltip]="data.since" class="text-left">{{ data.since }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            -->
          </div>

          <!-- Row 4-->
          <div class="row">
            <div class="col-xl-12 pb-2">
              <!-- Tab View -->
              <div class="card elevated-div">
                <ul class="card-title" ngbNav #nav="ngbNav" justify="justified" class="alert-success nav nav-pills"
                  style="border-bottom: 1px solid rgb(201, 201, 201)" [(activeId)]="activeTabId">
                  <li ngbNavItem="Faxes" [class.selected-tab]="nav.activeId === 'Faxes'">
                    <a ngbNavLink id="tab-1" (click)="changeTab('Faxes')"><b class="custom-tab-header">Faxes</b></a>
                  </li>
                  <!-- <li ngbNavItem="CSR_Dashboard" [class.selected-tab]="nav.activeId === 'CSR_Dashboard'">
                    <a ngbNavLink id="tab-2" (click)="changeTab('CSR_Dashboard')"><b class="custom-tab-header">CSR
                        Dashboard</b></a>
                  </li> -->
                  <li ngbNavItem="Reminder" [class.selected-tab]="activeTabId === 'Reminder'">
                    <a ngbNavLink id="tab-3" (click)="changeTab('Reminder')">
                      <b class="custom-tab-header">Reminder</b>
                    </a>
                  </li>

                  <!-- <li ngbNavItem="CMNReport" [class.selected-tab]="activeTabId === 'CMNReport'">
                    <a ngbNavLink id="tab-3" (click)="changeTab('CMNReport')">
                      <b class="custom-tab-header">CMN Report</b>
                    </a>
                  </li> -->

                  <li ngbNavItem="PARReport" [class.selected-tab]="activeTabId === 'PARReport'">
                    <a ngbNavLink id="tab-3" (click)="changeTab('PARReport')">
                      <b class="custom-tab-header">PAR Report</b>
                    </a>
                  </li>

                </ul>
                <!-- Fax Tab-->
                <div class="card card-body" *ngIf="nav.activeId === 'Faxes'">
                  <div *ngIf="loadChildComponent">
                    <div *ngIf="isMyFaxBtn == true && !selectedNode">
                      <mat-radio-group [(ngModel)]="selectedOption1" (change)="getFaxFileterList()">
                        <mat-radio-button class="Reminders pl-3" [value]="MyFaxfilter.All"><b><span
                              class="fontresize">All
                            </span></b></mat-radio-button>
                        <mat-radio-button class="Reminders" [checked]="true" [value]="MyFaxfilter.Pending"><b><span
                              class="fontresize">My Fax Pending
                            </span></b></mat-radio-button>
                        <mat-radio-button class="Reminders" [value]="MyFaxfilter.Completed"><b><span
                              class="fontresize">My Fax Completed</span></b></mat-radio-button>

                      </mat-radio-group>

                    </div>
                    <app-dashboard-fax-table [strInboundStatus]="strPageType" [branchList]="changeLocation"
                      [tableData]="inboundTableData" [selectedNode]="selectedNode" [MyFaxSelection]="selectedOption1"
                      (reloadTableEvent)="fnSearchClick()"
                      (selectNode)="selectNode($event)" (getVisibleColumns)="fnGetVisibleColumns($event)"
                      (nevigatePage)="changePage($event)" [visibleColumns]="visibleColumns" [currentPage]="currentPage" [totalPages]="totalPages"></app-dashboard-fax-table>
                  </div>
                  <div class="d-flex justify-content-center" *ngIf="!loadChildComponent">
                    <img class="img-responsive pb-2 mr-1" src="assets/gifs/Infinity-loader.gif" />
                  </div>

                </div>

                <!-- <div class="card card-body" *ngIf="nav.activeId === 'CSR_Dashboard'">
                  <div class="col-12">
                    <app-slider-date-picker [SelectedMonthYear]="selectedDate"></app-slider-date-picker>
                  </div>

                  <div class="col-12">
                    <apx-chart [series]="chartOptions.series" [chart]="chartOptions.chart" [xaxis]="chartOptions.xaxis"
                      [yaxis]="chartOptions.yaxis" [plotOptions]="chartOptions.plotOptions"
                      [dataLabels]="chartOptions.dataLabels" [fill]="chartOptions.fill"></apx-chart>
                  </div>
                </div> -->

                <!-- Reminder Tab -->
                <!-- <div class="card card-body" *ngIf="nav.activeId === 'Reminder'">
                <app-patient-reminder-dashboard></app-patient-reminder-dashboard>
                </div> -->

                <!-- <div class="card card-body" *ngIf="nav.activeId === 'Reminder' || activeTabId === 'Reminder'">
                  <app-patient-reminder-dashboard></app-patient-reminder-dashboard>
                </div> -->

                <div class="card card-body" *ngIf="activeTabId === 'Reminder'">
                  <app-patient-reminder-dashboard></app-patient-reminder-dashboard>
                </div>
                <!-- <div class="card card-body" *ngIf="activeTabId === 'CMNReport'">
                  <app-patient-cnm-report></app-patient-cnm-report>
                </div> -->
                <div class="card card-body" *ngIf="activeTabId === 'PARReport'">
                  <app-patient-par-report></app-patient-par-report>
                </div>
              </div>
              <br />
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</div>

<!-- <app-scoll-to-top></app-scoll-to-top> -->

<!-- <app-loader-component *ngIf="isgetCSROrderStatDetails"></app-loader-component> -->
