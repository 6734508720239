import type { clsTenantDoctors, CreateUpdatePatientClinicalDTO, PatientClinicalDTO } from './dto/models';
import { RestService } from '@abp/ng.core';
import type { PagedAndSortedResultRequestDto, PagedResultDto } from '@abp/ng.core';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class PatientClinicalService {
  apiName = 'Default';

  create = (input: CreateUpdatePatientClinicalDTO) =>
    this.restService.request<any, PatientClinicalDTO>({
      method: 'POST',
      url: '/api/app/patient-clinical',
      body: input,
    },
    { apiName: this.apiName });

  delete = (id: string) =>
    this.restService.request<any, void>({
      method: 'DELETE',
      url: `/api/app/patient-clinical/${id}`,
    },
    { apiName: this.apiName });

  get = (id: string) =>
    this.restService.request<any, PatientClinicalDTO>({
      method: 'GET',
      url: `/api/app/patient-clinical/${id}`,
    },
    { apiName: this.apiName });

  getList = (input: PagedAndSortedResultRequestDto) =>
    this.restService.request<any, PagedResultDto<PatientClinicalDTO>>({
      method: 'GET',
      url: '/api/app/patient-clinical',
      params: { skipCount: input.skipCount, maxResultCount: input.maxResultCount, sorting: input.sorting },
    },
    { apiName: this.apiName });

  update = (id: string, input: CreateUpdatePatientClinicalDTO) =>
    this.restService.request<any, PatientClinicalDTO>({
      method: 'PUT',
      url: `/api/app/patient-clinical/${id}`,
      body: input,
    },
    { apiName: this.apiName });

  getClinicalDetailsByGPatientId = (gPatientId: string) =>
    this.restService.request<any, PatientClinicalDTO>({
      method: 'GET',
      url: `/api/app/patient-clinical/get-clinical-details/${gPatientId}`,
    },
    { apiName: this.apiName });

    getDoctorListByPageNoAndPageOffset = (pageNo: number, pageOffset: number) =>
      this.restService.request<any, PagedResultDto<clsTenantDoctors>>({
        method: 'GET',
        url: '/api/app/patient-clinical/get-doctor-list',
        params: { pageNo, pageOffset },
      },
      { apiName: this.apiName });

    deletePatientClinicalOtherDoctorByPatientclinicalIdAndDoctorId = (patientclinicalId: string, DoctorId: string) =>
      this.restService.request<any, PatientClinicalDTO>({
        method: 'DELETE',
        url: '/api/app/patient-clinical/patient-clinical-other-doctor',
        params: { patientclinicalId, doctorId: DoctorId },
      },
      { apiName: this.apiName });

    deletePatientClinicalOtherPracticeByPatientclinicalIdAndPracticeId = (patientclinicalId: string, practiceId: string) =>
      this.restService.request<any, PatientClinicalDTO>({
        method: 'DELETE',
        url: '/api/app/patient-clinical/patient-clinical-other-practice',
        params: { patientclinicalId, practiceId },
      },
      { apiName: this.apiName });

  constructor(private restService: RestService) {}
}
