import { mapEnumToOptions } from '@abp/ng.core';

export enum BillingDropdowns {
  All = 'All',
  ClaimTypes = 'ClaimTypes',
  Blocks = 'Blocks',
  SpecialPrograms = 'SpecialPrograms',
  DelayReasons = 'DelayReasons',
  ServiceAuthExceptions = 'ServiceAuthExceptions',
  Status = 'Status',
  Hrs = 'Hrs',
  CurrentIllness = 'CurrentIllness',
  SimilarIllness = 'SimilarIllness',
  LastMenstruals = 'LastMenstruals',
  FirstTreatments = 'FirstTreatments',
  LastXRays = 'LastXRays',
  ActualManifest = 'ActualManifest',
  XraysMaintained = 'XraysMaintained',
  LastWorked = 'LastWorked',
  ReturnToWorks = 'ReturnToWorks',
  PatConditionCodes = 'PatConditionCodes',
  Block19 = 'Block19',
  Pcps = 'Pcps',
  SupervisingProvs = 'SupervisingProvs',
  Sources = 'Sources',
  ClaimStatus = "ClaimStatus",

  Codes = 'Codes',
  Claims = 'Claims',
  Modifiers = 'Modifiers',
  Pos = 'Pos',
  Tos = 'Tos',
  PaymentTypes = 'PaymentTypes',
  Reasons = 'Reasons',
  DxPointers = 'DxPointers',
  ClearingHouseTypes = 'ClearingHouseTypes',
  PreCollectionStatus = 'PreCollectionStatus',
  PatientVisitNoteTypes = 'PatientVisitNoteTypes',
}

export const billingDropdownsOptions = mapEnumToOptions(BillingDropdowns);
