import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import {  ModifierDTO } from 'projects/patient/src/app/dropdown-proxy/dropdown-management/dropdowns/dto/billing';
import { HttpErrorResponse } from '@angular/common/http';
import Swal from 'sweetalert2';
import { CommunicationService } from 'projects/shared/src/app/services/communication.service';
import { MmModifierService } from 'projects/billing/src/app/claim-proxy/claim-processing-management/rcm/billing-management/billing/mm-modifier.service';
import { CommonService } from 'projects/shared/src/app/services/common.service';
@Component({
  selector: 'app-master-modifier-v1',
  templateUrl: './master-modifier-v1.component.html',
  styleUrls: ['./master-modifier-v1.component.scss']
})
export class MasterModifierV1Component implements OnInit {
  // dateRangStatus: boolean = false;
  // minDate: Moment;
  // maxDate: Moment;
  isLoading: boolean;
  modifiersApiSubscription: Subscription;
  // ranges:any;
  // fromDate: any;
  // toDate: any;
  // calendarLocale: LocaleConfig;
  // calendarPlaceholder: string = '';
  // selectedRange: { startDate: Moment, endDate: Moment };
  tableMstModifry: any;
  drpModifiers: ModifierDTO[] = [];
  subscription$: Subscription[] = [];

  currentPage: any = 1;
  totalPages: any = 1;
  pageOffset: any = 50;
  recordsPerPage:number = 50;
  overallTableCount:number = 0;
  paginationInfo:any;


  constructor( 
    private commonService: CommonService,
    private communicationService: CommunicationService,
    private modifierservices : MmModifierService
    ) {  

      this.communicationService.functionReloadModifierTable$.subscribe(() => {
        this.currentPage = 1;
        this.getMasterModifierTable();
    });

    // this.ranges = {
    //   'Today': [moment(), moment()],
    //   'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
    //   'Last 7 Days': [moment().subtract(6, 'days'), moment()],
    //   'Last 30 Days': [moment().subtract(29, 'days'), moment()],
    //   'This Month': [moment().startOf('month'), moment().endOf('month')],
    // };
  }

  ngOnInit(): void {
    this.getMasterModifierTable();
  }

  ngOnDestroy(): void {
    this.subscription$?.forEach((sub) => {
      sub && sub?.unsubscribe();
    });
  }

  changePage(pageNo: number) {
    if (pageNo != this.currentPage && pageNo > 0 && pageNo <= this.totalPages) {
      this.currentPage = pageNo;
      this.getMasterModifierTable();
    }
  }

  getMasterModifierTable() {
    this.isLoading = true;
    if(this.modifiersApiSubscription){
      this.modifiersApiSubscription?.unsubscribe();
    }
    // const requestDto = new PagedAndSortedResultRequestDto();
    // requestDto.skipCount = this.currentPage;
    // requestDto.maxResultCount = this.pageOffset;
    // requestDto.sorting = this.pageOffset;
    const pageNo = this.currentPage;
    const pageOffset = this.pageOffset;

    this.modifiersApiSubscription= this.modifierservices.getModifiersList(pageNo,pageOffset).subscribe(
      (response) => {
        this.tableMstModifry = response?.items || [];
        this.tableMstModifry = this.tableMstModifry.map((modify) => {
          return {
            ...modify,
            creationTime: this.commonService.getFormattedDateTimeZone(
              modify.creationTime
            ),
            lastModificationTime: this.commonService.getFormattedDateTimeZone(
              modify.lastModificationTime
            )
          };
        });

        this.overallTableCount = response?.totalCount || 0;
        this.totalPages = Math.ceil(response?.totalCount / this.pageOffset) == 0 ? 1 : Math.ceil(response?.totalCount / this.pageOffset);
        this.paginationInfo = {
          startRecord: (this.currentPage - 1) * this.recordsPerPage + 1,
          endRecord: Math.min(this.currentPage * this.recordsPerPage, this.overallTableCount),
          overallTableCount : this.overallTableCount
        };

        this.isLoading = false;
      },
      (err) => {
        this.isLoading = false;
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message || "Internal Server error",
        });
      }
    );
  }

  // getMasterModifierTable(sModifierName:string,sdtCreationDate:string,dtStartDate:string,dtEndDate:string) {
  //   this.isLoading = true;
  //   this.tableData$?.unsubscribe();
  //   const getModifiersTable = this.mstModifryService.searchModifierBySModifierNameAndSdtCreationDateAndDtStartDateAndDtEndDate(sModifierName,sdtCreationDate,dtStartDate,dtEndDate).subscribe(
  //     (response) => {
  //       this.tableMstModifry = response?.items?.reverse() ?? [];
  //       // this.drpModifiers = response?.items ?? [];
  //       this.isLoading = false;
  //     },
  //     (err) => {
  //       this.isLoading = false;
  //       const data: HttpErrorResponse = err;
  //       Swal.fire({
  //         icon: 'error',
  //         text: data?.error?.error?.message,
  //       });
  //     }
  //   );
  //   this.tableData$ = getModifiersTable;
  // }

  // datesUpdateds(e) {
  //   if (e.startDate != null || e.endDate != null) {
  //     this.dateRangStatus = true
  //     const startDate = new Date(e.startDate);
  //     const endDate = new Date(e.endDate);
  //     this.fromDate = this.datepipe.transform(startDate, "MM/dd/yyyy");
  //     this.toDate = this.datepipe.transform(endDate, "MM/dd/yyyy");

  //     this.getMasterModifierTable(null,null,this.fromDate,this.toDate);
  //     // this.getModifierBySearch()

  //   } else {
  //     this.dateRangStatus = false
  //     this.fromDate = null,
  //       this.toDate = null
  //    this.getMasterModifierTable(null,null,this.fromDate,this.toDate);
  //     // this.getModifierBySearch()
  //   }
  // }
}
