import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MedicareQuestionModelComponent } from '../medicare-question-model/medicare-question-model.component';

import { MatTableDataSource } from '@angular/material/table';
import { HttpErrorResponse } from '@angular/common/http';

import Swal from 'sweetalert2';
import { Router } from '@angular/router';
import { MatSelect, MatSelectChange } from '@angular/material/select';
import { MatOption } from '@angular/material/core';
import { Observable, Subscription } from 'rxjs';
import { OrganizationUnitService } from 'projects/admin/src/app/admin-proxy/platform-app-management/rcm/platform-management/organization-units';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { map, startWith } from 'rxjs/operators';
import { InboundDocumentService } from 'projects/admin/src/app/admin-proxy/platform-app-management/rcm/platform-management/inbound/inbound-document.service';
import { InboundListDTO } from 'projects/admin/src/app/admin-proxy/platform-app-management/rcm/platform-management/inbound/dto/models';
import { PatientAddPolicyTabComponent } from '../patient-add-policy-tab/patient-add-policy-tab.component';
import { AddNoteTabComponent } from '../add-note-tab/add-note-tab.component';
import { PatientCommonNotesComponent } from 'projects/shared/src/app/components/patient-common-notes/patient-common-notes.component';
import { Title } from '@angular/platform-browser';

import { PatientNotesService } from '../patient-proxy/patient-optimization/patient-notes.service';
import { PatientMedicareCheckListDetailsService } from '../patient-proxy/patient-optimization/patient-medicare-check-list-details.service';
// import { defaultGuid } from 'projects/shared/src/app/enums/allenums.enum';
export const defaultGuid: string = "00000000-0000-0000-0000-000000000000";

@Component({
  selector: 'app-medicare-check-list',
  templateUrl: './medicare-check-list.component.html',
  styleUrls: ['./medicare-check-list.component.scss']
})
export class MedicareCheckListComponent implements OnInit {
  // defaultGuid = defaultGuid;
  selectedPatient: boolean = false;
  isLoading: boolean;
  isNewPatient: boolean = false;
  isShowSpinner: boolean;
  dataSource: MatTableDataSource<any>;
  dtOptions: any;
  tenantId: string = "";
  public drpBranch: Branch[] = [];
  branchForm: FormGroup;
  statusId: string;
  organizationUnitName: string;
  @ViewChild('selectBranch') selectBranch: MatSelect;
  @ViewChild('selectAllBranch') private selectAllBranch: MatOption;
  selectedBranchList: string[] = null;
  myMedicareCheeckList: InboundListDTO[] = [];
  public brnachList: Observable<Branch[]> | undefined;
  subscription$: Subscription[] = [];
  wipTableData: any;
  patientId: string;
  chartNo: string;

  [x: string]: any;


  constructor(public dialog: MatDialog, private branchService: OrganizationUnitService, private formBuilder: FormBuilder, private InboundDocumentService: InboundDocumentService, private title: Title, public PatientNotesService: PatientNotesService,public PatientMedicareCheckListDetailsService:PatientMedicareCheckListDetailsService,) { }

  ngOnInit(): void {
    this.title.setTitle('Qsecure | My Medicare Checklist');
    this.isShowSpinner = false;
    this.tenantId = localStorage.getItem("tenantId") ?? "";
    this.branchForm = this.formBuilder.group({
      drpBranch: new FormControl(''),
      txtBranch: new FormControl(''),
    });

    this.getBranchList();
    this.getTableData([]);
  }






  openPolicyModal() {
    // const dialogRef = this.dialog.open(PatientAddPolicyTabComponent, {
    //   maxWidth: '80%',
    //   minHeight: '60%',
    //   disableClose: true,
    //   data: {
    //     patientId: this.patientId,
    //     // wipId: this.wipId ?? defaultGuid,
    //   },
    // });
    // const closeDialog = dialogRef.afterClosed().subscribe(
    //   (result) => {
    //     // this.patientId !== defaultGuid && this.getTableData();
    //     // this.patientIdEmitter.next(this.patientId);
    //   },
    //   (err) => {
    //     const data: HttpErrorResponse = err;
    //     Swal.fire({
    //       icon: 'info',
    //       text: data?.error?.error?.message,
    //     });
    //   }
    // );
    // this.subscription$.push(closeDialog);
  }

  GetStatusFilterList() {

    this.InboundDocumentService.getStatusFilterList(this.selectedOption)
      .subscribe(
        (response) => {
          this.wipTableData = response;
          const verificationObject = this.wipTableData.find(obj => obj.inboundStatus === "Medicare");
          if (verificationObject) {
            this.statusId = verificationObject.id;
            this.getTableData([]);
          }

        },
        (err) => {
          this.wipTableData = [];
          const data: HttpErrorResponse = err;
          Swal.fire({
            icon: 'info',
            text: data?.error?.error?.message,
          });
        }
      );
  }




  getBranchList() {
    const getBranchList = this.branchService.getBranchList(this.tenantId).subscribe(response => {
      response?.forEach(element => {
        this.drpBranch.push({
          id: element.id,
          organizationUnitName: element.organizationUnitName,
        })
      });

      this.brnachList = this.branchForm
        ?.get('txtBranch')
        ?.valueChanges?.pipe(
          startWith(''),
          map((value) =>
            this.drpBranch?.filter((option) =>
              option?.organizationUnitName
                ?.toLowerCase()
                ?.includes(value?.toLowerCase() ?? '')
            )
          )
        );
    }, err => {
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    });
    this.subscription$.push(getBranchList);
  }

  onBranchSelectionChange(event: MatSelectChange): void {
    this.selectedBranchList = event.value;
    if (this.selectedBranchList.includes('selectAll')) {
      // this.BranchSelectAllOption = !this.BranchSelectAllOption;
      this.BranchSelectAllOption=true;
      if (this.BranchSelectAllOption) {
        // If 'Select All' is selected, set the selectedBranchList to include all branch IDs
        this.selectedBranchList = this.drpBranch.map(branch => branch.id);
        this.branchForm
        .get('drpBranch')
        .patchValue(this.selectedBranchList);

      }
    }else if (this.selectedBranchList.includes('DeselectAll')) {
      this.selectedBranchList=[];
      this.BranchSelectAllOption=false;
      this.branchForm
      .get('drpBranch')
      .patchValue([]);
    }
    else{
      this.BranchSelectAllOption =
      this.selectedBranchList.length === this.drpBranch.length;
    }
    this.getTableData(this.selectedBranchList);
  }

  getTableData(ltBranch:string[]) {
    this.isLoading = true;

    try {
      if (this.dataSubscription) {
        this.dataSubscription.unsubscribe();
      }
      this.dataSubscription =
    this.PatientMedicareCheckListDetailsService.getMedicareListByStatusID(ltBranch).subscribe(
      (response) => {
        this.isLoading = false;
        this.myMedicareCheeckList = response;

        this.myMedicareCheeckList = this.myMedicareCheeckList.map((medicaredata) => {
          return {
            ...medicaredata,
            preparedOn: this.commonService.getFormattedDateTimeZone(
              medicaredata.preparedOn
            ),
            approvedOn: this.commonService.getFormattedDateTimeZone(
              medicaredata.approvedOn
            ),
            lastModificationTime: this.commonService.getFormattedDateTimeZone(
              medicaredata.lastModificationTime
            ),
          };
        });

      },
      (error) => {
        console.error('Error fetching patient details:', error);
        this.isLoading = false;
      }
    );
  } catch (error) {
    console.error('Unexpected error:', error);
  } finally {
  }

  }


  fnOpenDoMedicarePopUp(id: any) {
    const config: MatDialogConfig = {
      disableClose: true,
      width: '700px',
      data: {
        patientID: "12321312312312",
      },
    };
    const dialogRef = this.dialog.open(MedicareQuestionModelComponent, config);

    const closeDialog = dialogRef.afterClosed().subscribe(
      () => {
        // this.getReminderTableData();
      },
      (err) => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      }
    );
    // this.subscription$.push(closeDialog);
  }

  openAddNotesPopUp1(id: any, id1: any) {

  }

  openAddNotesPopUp() {

    const config: MatDialogConfig = {
      disableClose: true,
      minHeight: '30vh',
      minWidth: '50vw',
      data: {
        noteType: 'patient',
        notesId: defaultGuid,
        patientId: this.patientId,
        defaultFaxId: this.chartNo,
        isCallNote: false,
        isPending: false,
        docId: defaultGuid,
      },
    };
    const dialogRef = this.dialog.open(AddNoteTabComponent, config);

    const closeDialog = dialogRef.afterClosed().subscribe(
      () => {
        // this.getReminderTableData();
      },
      (err) => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      }
    );
    this.subscription$.push(closeDialog);
  }

  openViewNotesPopup() {
    const config: MatDialogConfig = {
      disableClose: true,
      width: '70vw',
      maxWidth: '70vw',
      height: '40vw',
      maxHeight: '40vw',
      data: {
        patientId: this.patientId,
        pageType: 'Patient Note',
        isAddVisible: false,
      },
    };
    const dialogRef = this.dialog.open(PatientCommonNotesComponent, config);

    dialogRef.afterClosed().subscribe(
      () => {
        //this.getReminderTableData();
      },
      (err: HttpErrorResponse) => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      }
    );
  }


  // openInstallModal() {
  // this.dialog.open(MedicareQuestionModelComponent, {
  //   width: '400px', // Adjust the width as needed
  //   disableClose: true, // To prevent closing the modal by clicking outside
  // });
  // }

  // nextQuestionSet() {
  //   // Change the question set based on the selected option
  //   if (this.selectedMasterOption === 1) {
  //     this.currentQuestionSet = 'newSetup';
  //   } else {
  //     // Handle other checklist types or next steps
  //   }
  // }

}
export interface Branch {
  id?: string;
  organizationUnitName?: string;
}
