import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, Input, OnDestroy, OnInit } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
// import { defaultGuid } from 'projects/admin/src/app/document-perviewer/document-perviewer.component';
import { SharedTableService } from 'projects/shared/src/app/shared-table/shared-table.service';
import { Subscription } from 'rxjs';
import Swal from 'sweetalert2';
import { crudPremission } from 'projects/shared/src/app/shared-table/shared-table.component';
import { PatientVerificationDTO } from 'projects/patient/src/app/patient-proxy/patient-optimization/dto';
import { PatientVerificationService } from 'projects/patient/src/app/patient-proxy/patient-optimization';
import { PatientManualVerificationTabComponent } from 'projects/patient/src/app/patient-manual-verification-tab/patient-manual-verification-tab.component';
import { defaultGuid } from '../../enums/allenums.enum';

@Component({
  selector: 'app-patient-common-verification-tab',
  templateUrl: './patient-common-verification-tab.component.html',
  styleUrls: ['./patient-common-verification-tab.component.scss'],
})
export class PatientCommonVerificationTabComponent
  implements OnInit, OnDestroy
{
  public strPageType: string = 'eligiblityHistory';
  verifyTableData: PatientVerificationDTO[] = [];
  isLoading: boolean = false;
  arrDisplayedColumns = [
    'Options',
    // 'Sno',
    'plan',
    'planName',
    'policy',
    'verificationStatus',


  ];
  crudPermisson: crudPremission = {
    create: true,
    read: true,
    update: true,
    delete: false,
  };
  subscription$: Subscription[] = [];
  @Input() patientId: string = defaultGuid;
  constructor(
    public dialog: MatDialog,
    private sharedTableService: SharedTableService,
    private patientVerificationService: PatientVerificationService
  ) {}
  ngOnInit(): void {
    this.patientId = this.patientId ?? defaultGuid;
    this.patientId !== defaultGuid &&
      this.getTableDataBasedOnPatientId(this.patientId);
  }
  // On Page Destroy
  ngOnDestroy(): void {
    this.subscription$?.forEach((sub) => {
      sub && sub?.unsubscribe();
    });
  }

  getTableDataBasedOnPatientId(patientId: string) {
    this.isLoading = true;
    this.verifyTableData = [];
    this.sharedTableService.setTableData(this.verifyTableData);
    const getVerificationDetails = this.patientVerificationService
      .getVerificationDetailsByPatientIdByPatientId(patientId)
      .subscribe(
        (response) => {
          this.isLoading = false;
          this.verifyTableData = response;
          this.sharedTableService.setTableData(this.verifyTableData);
        },
        (err) => {
          this.sharedTableService.setTableData(this.verifyTableData);
          this.isLoading = false;
          const data: HttpErrorResponse = err;
          Swal.fire({
            icon: 'info',
            text: data?.error?.error?.message,
          });
        }
      );
    this.subscription$.push(getVerificationDetails);
  }
  viewVerificationById(verification: PatientVerificationDTO): void {

    const dialogRef = this.dialog.open(PatientManualVerificationTabComponent, {
      data: {
        patientId: this.patientId,
        verificationId: verification?.id,
        policyId: verification?.policyId,
      },
    });
    const closeDialog = dialogRef.afterClosed().subscribe((result) => {
      this.getTableDataBasedOnPatientId(this.patientId);
      dialogRef?.close(result);
    });
    this.subscription$.push(closeDialog);
  }
  deleteVerificationById(verificationId: any): void {
    this.isLoading = true;
    this.patientVerificationService.get(verificationId).subscribe(
      (resposne) => {
        this.patientId !== defaultGuid &&
          this.getTableDataBasedOnPatientId(this.patientId);
      },
      (err) => {
        this.patientId !== defaultGuid &&
          this.getTableDataBasedOnPatientId(this.patientId);
        this.isLoading = false;
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      }
    );
  }
}
