import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UserService } from 'projects/admin/src/app/admin-proxy/platform-app-management/rcm/platform-management/user/user.service';
import { IdentityUserDto } from 'projects/admin/src/app/admin-proxy/platform-app-management/volo/abp/identity/models';
import { TableService } from 'projects/shared/src/app/table.service';
import { Observable, of, Subscription } from 'rxjs';
import Swal from 'sweetalert2';

import { AuditLogService } from '../patient-proxy/audit-logs/audit-log.service';
import { AuditLogsService } from '../patient-proxy/audit-logs/audit-logs.service';
import { AuditLogDTO } from '../patient-proxy/audit-logs/dto/models';
import { Title } from '@angular/platform-browser';


@Component({
  selector: 'app-patient-history',
  templateUrl: './patient-history.component.html',
  styleUrls: ['./patient-history.component.scss']
})
export class PatientHistoryComponent implements OnInit, OnDestroy {
  strPageType: string = 'History';
  historyData: {
    type: 'Create' | 'Delete' | 'Update',
    ActionComment: string
    UserName: string,
    dateTime: string | Date
  }[] = [];
  historyObservable$: Observable<AuditLogDTO[]>;
  @Input() patientId: string = "";
  arrUsersList: IdentityUserDto[] = [];
  tableList: any[] = [];
  tenantId: any = "";
  isLoading: boolean;
  subscription$: Subscription[] = [];
  constructor(
    private auditLogService: AuditLogService,
    public userService: UserService,
    private tableService: TableService,
    private patientHostory: AuditLogsService,
    public title: Title,
  ) { }

  ngOnInit(): void {
    this.title.setTitle("Qsecure | History");

    this.tableService.setPatientHistoryDataTable([]);

    this.tenantId = localStorage.getItem("tenantId") ?? "";
    this.tenantId !== "" && this.getPatientHistory();
  }

  ngOnDestroy(): void {
    this.subscription$?.forEach((sub) => {
      sub && sub?.unsubscribe();
    });
  }

  //get username by guid
  private getUserName(value: string) {
    if (value && typeof value === "string") {
      return this.arrUsersList?.filter(u => u?.id === value)[0]?.userName ?? "";
    }
  }


  //! get usernames to display in table
  getPatientHistory() {
    this.isLoading = true;

    const identyUser = this.userService.getIdentityUser().subscribe((response: IdentityUserDto[]) => {
      this.arrUsersList = response;
      this.isLoading = false;
      this.getHistory();


    }, err => {
      this.isLoading = false;
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    })
    this.subscription$.push(identyUser);
  }
  //! get patient history to display in table

  getHistory() {
    this.isLoading = true;
    const patientHistory = this.patientHostory.auditlogsoptimization(this.patientId).subscribe((response) => {
      response?.forEach(element => {
        const changeName = element?.changedBy;
        this.tableList.push({
          type: element?.operation,
          dateTime: element?.changedDateTime,
          UserName: this.getUserName(changeName ?? ""),
          ActionComment: element?.comment
        })
      });
      this.isLoading = false;
      this.tableService.setPatientHistoryDataTable(this.tableList);
    }, err => {
      this.isLoading = false;
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    })
    this.subscription$.push(patientHistory);
  }
}
