import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, FormGroupDirective, NgForm, Validators } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Title } from '@angular/platform-browser';
import { Store } from '@ngxs/store';
import { SaleorderDTO } from 'projects/order/src/app/order-proxy/order-management/order/dto/models';
import { SaleorderService } from 'projects/order/src/app/order-proxy/order-management/order/saleorder.service';
import { getOrder } from 'projects/order/src/app/order-store/order.action';
import { BillingDropdowns } from 'projects/patient/src/app/dropdown-proxy/dropdown-management/dropdowns/billing-dropdowns.enum';
import { BillingMasterDropdownDTO, CodeDTO, POSDTO, TOSDTO } from 'projects/patient/src/app/dropdown-proxy/dropdown-management/dropdowns/dto/billing/models';
import { DiagnosisCode10DTO, DiagnosisCode9DTO, PatientMasterDropdownDTO, PayorLevelDTO } from 'projects/patient/src/app/dropdown-proxy/dropdown-management/dropdowns/dto/patient/models';
import { BillingMasterDropdownService } from 'projects/patient/src/app/dropdown-proxy/dropdown-management/dropdowns/master-dropdown-service/billing-master-dropdown.service';
import { PatientMasterDropdownService } from 'projects/patient/src/app/dropdown-proxy/dropdown-management/dropdowns/master-dropdown-service/patient-master-dropdown.service';
import { PatientDropdowns } from 'projects/patient/src/app/dropdown-proxy/dropdown-management/dropdowns/patient-dropdowns.enum';
import { PoliciesDTO } from 'projects/patient/src/app/patient-proxy/patient/dto';
import { getPatient } from 'projects/patient/src/app/patient-store/patient.action';
import { TableService } from 'projects/shared/src/app/table.service';
import { NumberValidators } from 'projects/shared/src/app/validations/number-validator';

import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import Swal from 'sweetalert2';

import { ClaimInformationsService } from '../billing-proxy/billing-management/posting/claim-informations.service';
import { ClaimDTO, PostingChargesDTO } from '../billing-proxy/billing-management/posting/dto/models';
import { PatientDTO } from '../billing-proxy/billing-management/rcm/patient-management/patient/dto/models';
import { getBilling, updateBilling } from '../billing-store/billing.action';
import { ToastrService } from "ngx-toastr";

export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}

@Component({
  selector: 'app-billing-posting-charges',
  templateUrl: './billing-posting-charges.component.html',
  styleUrls: ['./billing-posting-charges.component.scss']
})

export class BillingPostingChargesComponent implements OnInit {

  @Output() nextTabMoveOnSaveEmitter: EventEmitter<string> = new EventEmitter<string>(null);

  @Output() savePostingChargesFormState: EventEmitter<any> = new EventEmitter<any>();
  txtIcd10: string = '';
  public filteredIcd9Types: Observable<any[]>;
  public filteredIcd10Types: Observable<any[]>;
  txtIcd9: string = '';
  matcher = new MyErrorStateMatcher();
  isShowSpinner: boolean = false;
  public strPageType: string = "addPosting";
  public strPageType2: string = "addPosting2";
  public postingForm: FormGroup;
  drpCode: CodeDTO[] = [];
  drpDiagnosis10: DiagnosisCode10DTO[] = [];
  drpDiagnosis9: DiagnosisCode9DTO[] = [];
  drpModifiers: any[] = [];
  drpPos: POSDTO[] = [];
  drpTos: TOSDTO[] = [];
  tblPostReponse: any[] = [];
  organizationUnitId: string = "";
  @Input() claimId: string = '';
  @Input() orderId: string = '';
  @Input() patientId: string = '';
  drpinsurance: insuranceface[] = [];
  drpitem: itemInterface[] = [];
  tblPolicesReponse: any[] = [];

  isLoading: boolean = true;
  isIcd9Shown: boolean = false;
  isIcd10Shown: boolean = false;
  icd9Event: string = "";
  icd10Event1: string = "";
  icd10Event2: string = "";
  icd10Event3: string = "";
  @Input() listClaimId: string = '';
  @Input() listOrderId: string = '';
  @ViewChild(MatSort) sort: MatSort;
  code: string = '';
  description: string = '';
  modifiers1: string = '';
  modifiers2: string = '';
  modifiers3: string = '';
  modifiers4: string = '';
  dx1: string = '';
  dx2: string = '';
  dx3: string = '';
  dx4: string = '';
  pos: string = '';
  tos: string = '';
  saveButtonHide: boolean;

  constructor(private fb: FormBuilder, private billingDropdownService: BillingMasterDropdownService,
    private patientDropdownService: PatientMasterDropdownService,
    private title: Title, private orderService: SaleorderService,
    private number: NumberValidators, private store: Store, private Table: TableService,
    private deleteSerivice: ClaimInformationsService,
    private toastr: ToastrService,
  ) { }
  dataSource = new MatTableDataSource(this.tblPolicesReponse);

  columnsToDisplay = ['Name', 'Starts', 'Ends',];// 'Copay', 'Deduct'
  ngOnInit(): void {
    this.title.setTitle("Qsecure | Add Posting");
    this.postingForm = this.fb.group({
      drpDiag9: new FormControl(""),
      drpDiag10ID: new FormControl("", [Validators.required]),
      drpCode: new FormControl("", [Validators.required]),
      txtDescription: new FormControl("", [Validators.required]),
      txtProvider: new FormControl(""),
      txtCheckNo: new FormControl("", [this.number.isNumberCheck]),
      txtModifiers1: new FormControl(""),
      txtModifiers2: new FormControl(""),
      txtModifiers3: new FormControl(""),
      txtModifiers4: new FormControl(""),
      drpDx1: new FormControl(""),
      drpDx2: new FormControl(""),
      drpDx3: new FormControl(""),
      drpDx4: new FormControl(""),
      drpPos: new FormControl("", [Validators.required]),
      drpTos: new FormControl(""),
      txtDateFrom: new FormControl(""),
      txtDateTo: new FormControl(""),
      txtFee: new FormControl("", [Validators.required]),
      txtUnits: new FormControl("", [Validators.required, this.number.isNumberCheck]),
      txtCopay: new FormControl(""),
      txtOther: new FormControl(""),
      txtTotal: new FormControl("", [Validators.required, this.number.isNumberCheck]),
      txtDocument: new FormControl(""),
      txtStatement: new FormControl(""),
      txtPatientResp: new FormControl(""),
      txtIcd10Filter: new FormControl(null),
      txtIcd9Filter: new FormControl(null),


    })
    this.dataSource.sort = this.sort;
    this.getDropdown();
    this.getDiagnosisDropdown();
    this.itemDropdown();
    this.Table.setPostTable([])

    this.getItemTable();

    this.Table.getValueFilterTable().subscribe(value => {
    }, err => {
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    })

    if (this.postingForm.invalid || this.postingForm.dirty) {
      this.savePostingChargesFormState.emit(this.postingForm);
    }
    this.getPostingTable();

  }

  patientName: string = "";
  patientDOB: string = "";
  defaultId: string = "";
  defaultSaleOrderId: string = "";

  // ! get Patient Data By Id
  getPatientData() {
    this.store.dispatch(new getPatient(this.patientId)).pipe(
      map((value: any) => {
        return value?.patientState?.selectedPatient;
      })).subscribe((response: PatientDTO) => {
        if (response?.clinicals?.diagnosisCode9) {
          const diagonsis9: DiagnosisCode9DTO[] = this.drpDiagnosis9.filter((e, index) => e?.diagnosisCodeTypeData === response?.clinicals?.diagnosisCode9[index]) ?? [];
          this.postingForm.patchValue({
            drpDiag9: diagonsis9.map(d => d?.diagnosisCodeTypeData),
          })
          this.txtIcd9
        }
        if (response?.clinicals?.diagnosisCode10) {
          const diagonsis10: DiagnosisCode10DTO[] = this.drpDiagnosis10.filter((e, index) => e?.diagnosisCode10ShortCodeId === response?.clinicals?.diagnosisCode10[index]) ?? [];
          this.postingForm.patchValue({
            drpDiag10ID: diagonsis10.map(d => d?.diagnosisCodeTypeData),
          })
          this.txtIcd10
        }
        this.postingForm.patchValue({
          txtIcd9: response?.clinicals?.diagnosisCode9,
          txtIcd10: response?.clinicals?.diagnosisCode10
        })
        this.patientName = response?.personals?.firstName + ' ' + response?.personals?.lastName;
        this.patientDOB = response?.personals?.dateOfBirth;
        this.defaultId = response?.defaultPatientId;

      }, err => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      })
  }


  clearIcd9() {
    this.txtIcd9 = "";
    this.postingForm.patchValue({ drpDiag9: "" });

  }
  clearIcd10() {
    this.txtIcd10 = "";
    this.postingForm.patchValue({ drpDiag10ID: "" });
  }
  // clear dropdown
  clearCode() {
    this.code = "";
    this.postingForm.patchValue({ drpCode: "" });
  }
  clearDescription() {
    this.description = "";
    this.postingForm.patchValue({ txtDescription: "" });
  }

  clearModifiers1() {
    this.modifiers1 = "";
    this.postingForm.patchValue({ drpModifiers1: "" });
  }
  clearModifiers2() {
    this.modifiers2 = "";
    this.postingForm.patchValue({ drpModifiers2: "" });
  }
  clearModifiers3() {
    this.modifiers3 = "";
    this.postingForm.patchValue({ drpModifiers3: "" });
  }
  clearModifiers4() {
    this.modifiers4 = "";
    this.postingForm.patchValue({ drpModifiers4: "" });
  }

  clearDx1() {
    this.dx1 = "";
    this.postingForm.patchValue({ drpDx1: "" });
  }

  clearDx2() {
    this.dx2 = "";
    this.postingForm.patchValue({ drpDx2: "" });
  }
  clearDx3() {
    this.dx3 = "";
    this.postingForm.patchValue({ drpDx3: "" });
  }
  clearDx4() {
    this.dx4 = "";
    this.postingForm.patchValue({ drpDx4: "" });
  }

  clearPos() {
    this.pos = "";
    this.postingForm.patchValue({ drpPos: "" });
  }
  clearTos() {
    this.tos = "";
    this.postingForm.patchValue({ drpTos: "" });
  }

  // ! dropdown list
  getDropdown() {
    let billingDropdownInput: BillingDropdowns[];
    billingDropdownInput = [
      BillingDropdowns.Codes,
      BillingDropdowns.Modifiers,
      BillingDropdowns.Pos,
      BillingDropdowns.Tos
    ]
    this.billingDropdownService.getBillingDropdownsByInput(billingDropdownInput).subscribe((stateResponse) => {
      const response: BillingMasterDropdownDTO = stateResponse;
      this.drpCode = response.codes;
      this.drpModifiers = response.modifiers;
      this.drpPos = response.pos;
      this.drpTos = response.tos;
    }, err => {
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    })
  }
  getDiagnosisDropdown() {
    let patientDropdownInput: PatientDropdowns[];
    patientDropdownInput = [
      PatientDropdowns.DiagnosisCode9,
      PatientDropdowns.DiagnosisCode10
    ]
    this.patientDropdownService.getPatientDropdownsByInput(patientDropdownInput).subscribe((stateResponse) => {
      const response: PatientMasterDropdownDTO = stateResponse;
      this.drpDiagnosis9 = response.diagnosisCodes9;
      this.drpDiagnosis10 = response.diagnosisCodes10;
      this.filteredIcd9Types = this.postingForm?.get("txtIcd9Filter").valueChanges
        .pipe(
          startWith(''),
          map(value => this.drpDiagnosis9.filter(option => option?.diagnosisCodeTypeData?.toLowerCase()?.includes(value?.toLowerCase() ?? "")))
        );
      this.filteredIcd10Types = this.postingForm?.get("txtIcd10Filter").valueChanges
        .pipe(
          startWith(''),
          map(value => this.drpDiagnosis10.filter(option => option?.diagnosisCodeTypeData?.toLowerCase()?.includes(value?.toLowerCase() ?? "")))
        );
      this.claimId != "" && this.claimId != null && this.claimId != undefined && this.claimId != '00000000-0000-0000-0000-000000000000' && this.getPostingTable();
      if (this.patientId && this.patientId !== "" && this.patientId !== null && this.patientId !== undefined) {
        this.getPatientData();
      }
    }, err => {
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    })


  }



  dos: string = "";
  itemDropdown(): void {
    this.drpitem = [];
    if (this.orderId != "" && this.orderId != null && this.orderId != undefined) {

      this.store.dispatch(new getOrder(this.orderId)).subscribe(response => {

        response?.orderState.selectedOrder.items.forEach(element => {
          this.dos = element?.originalDos
          this.drpitem.push({
            itemId: element?.itemIdOrBarCode,
            id: element?.id,
            name: element?.name,
            dos: element?.originalDos
          });
        });
        const selectedOrder: SaleorderDTO = response?.orderState.selectedOrder
        this.patientId = selectedOrder.patientId;
        this.insuranceDropdown();
      }, err => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      });
    }
    else {
      this.store.dispatch(new getOrder(this.listOrderId)).subscribe(response => {


        response?.orderState.selectedOrder.items.forEach(element => {
          this.dos = element?.originalDos
          this.drpitem.push({
            itemId: element?.itemCodeId,
            id: element?.id,
            name: element?.name,
            dos: element?.originalDos
          });
        });
        const selectedOrder: SaleorderDTO = response?.orderState.selectedOrder
        this.patientId = selectedOrder.patientId;
        this.insuranceDropdown();
      }, err => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      });
    }

  }
  payorLevel: PayorLevelDTO[] = [];
  // ! insurance dropdown
  getPolicyDropdown() {
    let patientDropdownInput: PatientDropdowns[];
    patientDropdownInput = [
      PatientDropdowns.PayorLevels
    ]
    this.patientDropdownService.getPatientDropdownsByInput(patientDropdownInput).subscribe((stateResponse) => {
      const response: PatientMasterDropdownDTO = stateResponse;
      this.payorLevel = response.payorLevels;
    }, err => {
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    });
  }
  insuranceDropdown(): void {
    this.drpinsurance = [];
    this.store.dispatch(new getPatient(this.patientId)).subscribe(response => {
      const policyResp = response?.patientState.selectedPatient.policies
      if (policyResp?.primaryPolicy != null) {
        this.drpinsurance.push({
          id: policyResp?.primaryPolicy?.id,
          policy: policyResp?.primaryPolicy?.insuranceType,
          policiesId: policyResp?.primaryPolicy?.policiesId,
          payorLevel: policyResp?.primaryPolicy?.payorLevel,
          name: policyResp?.primaryPolicy?.fisrtName
        });
      }

      if (policyResp?.secondaryPolicy != null) {
        this.drpinsurance.push({
          id: policyResp?.secondaryPolicy?.id,
          policy: policyResp?.secondaryPolicy?.insuranceType,
          policiesId: policyResp?.secondaryPolicy?.policiesId,
          payorLevel: policyResp?.secondaryPolicy?.payorLevel,
          name: policyResp?.secondaryPolicy?.fisrtName
        });
      }
      if (policyResp?.tertiaryPolicy != null) {
        this.drpinsurance.push({
          id: policyResp?.tertiaryPolicy?.id,
          policy: policyResp?.tertiaryPolicy?.insuranceType,
          policiesId: policyResp?.tertiaryPolicy?.policiesId,
          payorLevel: policyResp?.tertiaryPolicy?.payorLevel,
          name: policyResp?.tertiaryPolicy?.fisrtName
        });
      }

      // });
    }, err => {
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    });

  }

  getClaimInfo() {
    let claimDetails: ClaimDTO;
    if (this.claimId != null && this.claimId != "" && this.claimId != '00000000-0000-0000-0000-000000000000') {
      this.store.dispatch(new getBilling(this.claimId)).subscribe(response => {
        claimDetails = response.billingState.selectedBilling;
        this.savePosting(claimDetails);
      }, err => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      }, () => {
      });
    } else {
      // this.store.dispatch(new getBilling(this.listClaimId)).subscribe(response => {

      //   claimDetails = response.billingState.selectedBilling;
      //   this.savePosting(claimDetails);
      // }, err => {
      //   const data: HttpErrorResponse = err;
      //   Swal.fire({
      //     icon: 'error',
      //     text: data?.error?.error?.message,
      //   });
      // }, () => {
      // });
    }

  }
  // ! save posting and charges
  postingChargesId: "00000000-0000-0000-0000-000000000000";
  savePosting(response: ClaimDTO) {
    this.isShowSpinner = true;
    this.saveButtonHide = true;
    let postingDetails: ClaimDTO;
    let currentPosting: PostingChargesDTO[] = response?.lstPostingCharges;
    // let selectedItem: itemInterface = this.drpitem?.filter(value => { return value && value?.id === this.itemForm?.get("itemData.txtItenId").value })[0];
    if (this.postingChargesId != "00000000-0000-0000-0000-000000000000") {
      currentPosting = currentPosting.filter(lstPostingCharges => { return lstPostingCharges && lstPostingCharges.postingChargesId != this.postingChargesId })
      // const selItem: productInterface = this.drpItem.filter(e => (e?.name ?? "") === this.postingForm.value.drpCode)[0] ?? null;

      currentPosting = [
        ...currentPosting,
        {
          itemId: "00000000-0000-0000-0000-000000000000",
          productId:"00000000-0000-0000-0000-000000000000",
          postingChargesId: this.postingChargesId,
          diagnosisCode9: this.postingForm.value.drpDiag9, // == "" ? [] : this.postingForm.value.drpDiag9,
          diagnosisCode10: this.postingForm.value.drpDiag10ID,
          item: this.postingForm.value.drpCode,
          insurance: this.postingForm.value.txtDescription,
          modifier1: this.postingForm.value.drpModifiers1,
          modifier2: this.postingForm.value.drpModifiers2,
          modifier3: this.postingForm.value.drpModifiers3,
          modifier4: this.postingForm.value.drpModifiers4,
          pos: this.postingForm.value.drpPos,
          tos: this.postingForm.value.drpTos,
          provider: this.postingForm.value.txtProvider,
          dateFrom: this.postingForm.value.txtDateFrom,
          dateTo: this.postingForm.value.txtDateTo,
          dxPointer1: this.postingForm.value.drpDx1,
          dxPointer2: this.postingForm.value.drpDx2,
          dxPointer3: "",
          dxPointer4: "",
          checkNo: this.postingForm.value.txtCheckNo,
          fee: this.postingForm.value.txtFee,
          units: this.postingForm.value.txtUnits,
          copay: this.postingForm.value.txtCopay,
          other: this.postingForm.value.txtOther,
          total: this.postingForm.value.txtTotal,
          document: this.postingForm.value.txtDocument,
          statementNote: this.postingForm.value.txtStatement,
          cpts: "",
          dos: new Date(this.dos)?.toLocaleDateString("en-US"),
          status: "Ready",
          patientResp: this.postingForm.value.txtPatientResp == true ? 1 : 0,
          itemSize: this.postingForm.value.drpSize,

        }]
    } else {

      currentPosting = [...currentPosting, {
        itemId: "00000000-0000-0000-0000-000000000000",
        productId: "00000000-0000-0000-0000-000000000000",
        postingChargesId: this.postingChargesId,
        diagnosisCode9: this.postingForm.value.drpDiag9,
        diagnosisCode10: this.postingForm.value.drpDiag10ID,
        item: this.postingForm.value.drpCode,
        insurance: this.postingForm.value.txtDescription,
        modifier1: this.postingForm.value.drpModifiers1,
        modifier2: this.postingForm.value.drpModifiers2,
        modifier3: this.postingForm.value.drpModifiers3,
        modifier4: this.postingForm.value.drpModifiers4,
        pos: this.postingForm.value.drpPos,
        tos: this.postingForm.value.drpTos,
        provider: this.postingForm.value.txtProvider,
        dateFrom: this.postingForm.value.txtDateFrom,
        dateTo: this.postingForm.value.txtDateTo,
        dxPointer1: this.postingForm.value.drpDx1,
        dxPointer2: this.postingForm.value.drpDx2,
        dxPointer3: "",
        dxPointer4: "",
        checkNo: this.postingForm.value.txtCheckNo,
        fee: this.postingForm.value.txtFee,
        units: this.postingForm.value.txtUnits,
        copay: this.postingForm.value.txtCopay,
        other: this.postingForm.value.txtOther,
        total: this.postingForm.value.txtTotal,
        document: this.postingForm.value.txtDocument,
        statementNote: this.postingForm.value.txtStatement,
        cpts: "",
        dos: new Date(this.dos)?.toLocaleDateString("en-US"),
        status: "Ready",
        patientResp: this.postingForm.value.txtPatientResp == true ? 1 : 0,
        itemSize: this.postingForm.value.drpSize,
      }]
    }
    postingDetails = {
      lstClaimInfo: response.lstClaimInfo,
      lstPostingCharges: currentPosting,
      // insurancePayments:null,
      insurancePayments: {
        primaryInsurancePaymentDetails: {
          source: "",
          code: "",
          description: "",
          checkNo: "",
          date: "",
          amount: "",
          plansOfFiles: "",
          statementNote: "",
          lstItemPayment: [],
          paymentType: "",
          reason: "",
          eobDocuments: null,
          totalBilled: 0,
          totalAllowed: 0,
          totalDeduct: 0,
          totalCoInsurance: 0,
          totalWriteOff: 0,
          totalInsurancePaid: 0,
          totalInsBal: 0,
          totalPatientBal: 0
        },
        secondaryInsurancePaymentDetails: {
          source: "",
          code: "",
          description: "",
          checkNo: "",
          date: "",
          amount: "",
          plansOfFiles: "",
          statementNote: "",

          lstItemPayment: [],
          paymentType: "",
          reason: "",
          eobDocuments: null,
          totalBilled: 0,
          totalAllowed: 0,
          totalDeduct: 0,
          totalCoInsurance: 0,
          totalWriteOff: 0,
          totalInsurancePaid: 0,
          totalInsBal: 0,
          totalPatientBal: 0
        },
        tertiaryInsurancePaymentDetails: {
          source: "",
          code: "",
          description: "",
          checkNo: "",
          date: "",
          amount: "",
          plansOfFiles: "",
          statementNote: "",
          lstItemPayment: [],
          paymentType: "",
          reason: "",
          eobDocuments: null,
          totalBilled: 0,
          totalAllowed: 0,
          totalDeduct: 0,
          totalCoInsurance: 0,
          totalWriteOff: 0,
          totalInsurancePaid: 0,
          totalInsBal: 0,
          totalPatientBal: 0
        }
      },
      saleOrder: response.saleOrder,
      patientId: response.patientId,
      orderId: response.orderId,
      saleorderId: response.saleorderId,
      defaultPatientId: response.defaultPatientId,
      patientName: response.patientName,
      patientDob: response.patientDob,
      readyCount: 0,
      processedCount: 0,
      crossOverCount: 0,
      holdCount: 0,
      patientResponsibilityCount: 0,
      patientResponsibilityAfterInsCount: 0,
      claimId: response.claimId,
      lstClearingHouseUpdates: [],
      totalPatientBal: 0,
      lstPatientVisitNotes: [],
      totalBilled: 0,
      totalInsBal: 0,
      lstReminders: response?.lstReminders,
      isVoided: response?.isVoided,
      claimProcessMethod: response?.claimProcessMethod
    }

    if (this.claimId != null && this.claimId != "") {
      this.store.dispatch(new updateBilling(this.claimId, postingDetails)).subscribe(value => {
        // Swal.fire({ title: 'Success', html: 'Saved Successfully', icon: 'success', timer: 3000, timerProgressBar: true });
        this.toastr.success('Saved Successfully','Success')
        this.savePostingChargesFormState.emit(this.postingForm.value);
        this.getPostingTable();
        this.postingChargesId = "00000000-0000-0000-0000-000000000000";
        this.nextTabMoveOnSaveEmitter.emit("Posting");
        this.saveButtonHide = false;
        this.isShowSpinner = false;
      }, err => {
        this.saveButtonHide = false;
        this.isShowSpinner = false;
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      });
    } else {
      this.store.dispatch(new updateBilling(this.listClaimId, postingDetails)).subscribe(value => {
        // Swal.fire({ title: 'Success', html: 'Saved Successfully', icon: 'success', timer: 3000, timerProgressBar: true });
        this.toastr.success('Saved Successfully','Success')
        this.savePostingChargesFormState.emit(this.postingForm.value);
        this.getPostingTable();
        this.postingChargesId = "00000000-0000-0000-0000-000000000000";
        this.nextTabMoveOnSaveEmitter.emit("Posting");
        this.saveButtonHide = false;
        this.isShowSpinner = false;
      }, err => {
        this.saveButtonHide = false;
        this.isShowSpinner = false;
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      });
    }

  }
  // ! for save button enable disable
  onIcd9Change(event) {
    this.icd9Event = event
    if ((event == undefined || event == null || event == "") &&
      (this.icd10Event1 == undefined || this.icd10Event1 == null || this.icd10Event1 == "") &&
      (this.icd10Event2 == undefined || this.icd10Event2 == null || this.icd10Event2 == "") &&
      (this.icd10Event3 == undefined || this.icd10Event3 == null || this.icd10Event3 == "")) {
      this.isIcd9Shown = false;
      this.isIcd10Shown = false;
    }
    else {
      this.isIcd9Shown = true;
      this.isIcd10Shown = true;
    }
  }
  onIcd10Change1(event) {
    this.icd10Event1 = event;
    if ((event == undefined || event == null || event == "") &&
      (this.icd9Event == undefined || this.icd9Event == null || this.icd9Event == "") &&
      (this.icd10Event2 == undefined || this.icd10Event2 == null || this.icd10Event2 == "") &&
      (this.icd10Event3 == undefined || this.icd10Event3 == null || this.icd10Event3 == "")) {
      this.isIcd10Shown = false;
      this.isIcd9Shown = false;
    }
    else {
      this.isIcd10Shown = true;
      this.isIcd9Shown = true;
    }
  }

  onIcd10Change2(event) {
    this.icd10Event2 = event;
    if ((event == undefined || event == null || event == "") &&
      (this.icd9Event == undefined || this.icd9Event == null || this.icd9Event == "") &&
      (this.icd10Event1 == undefined || this.icd10Event1 == null || this.icd10Event1 == "") &&
      (this.icd10Event3 == undefined || this.icd10Event3 == null || this.icd10Event3 == "")) {
      this.isIcd10Shown = false;
      this.isIcd9Shown = false;
    }
    else {
      this.isIcd10Shown = true;
      this.isIcd9Shown = true;
    }
  }
  onIcd10Change3(event) {
    this.icd10Event3 = event;
    if ((event == undefined || event == null || event == "") && (this.icd9Event == undefined || this.icd9Event == "")
      && (this.icd10Event1 == undefined || this.icd10Event1 == "") && (this.icd10Event2 == undefined || this.icd10Event2 == "")) {
      this.isIcd10Shown = false;
      this.isIcd9Shown = false;
    }
    else {
      this.isIcd10Shown = true;
      this.isIcd9Shown = true;
    }
  }
  // ! item dropdown list
  getItemTable() {
    this.store.dispatch(new getPatient(this.patientId)).subscribe(response => {
      this.tblPolicesReponse = [];
      const policyResp: PoliciesDTO = response?.patientState.selectedPatient.policies

      if (policyResp?.primaryPolicy != null) {
        this.tblPolicesReponse.push({
          type: policyResp?.primaryPolicy?.payorLevel,
          name: policyResp?.primaryPolicy?.insuranceType,
          policyNo: policyResp?.primaryPolicy?.policyNo
        });
      }

      if (policyResp?.secondaryPolicy != null) {
        this.tblPolicesReponse.push({
          type: policyResp?.secondaryPolicy?.payorLevel,
          name: policyResp?.secondaryPolicy?.insuranceType,
          policyNo: policyResp?.secondaryPolicy?.policyNo
        });
      }
      if (policyResp?.tertiaryPolicy != null) {
        this.tblPolicesReponse.push({
          type: policyResp?.tertiaryPolicy?.payorLevel,
          name: policyResp?.tertiaryPolicy?.insuranceType,
          policyNo: policyResp?.tertiaryPolicy?.policyNo
        });
      }

      this.dataSource = new MatTableDataSource(this.tblPolicesReponse);
      this.Table.setPolicyTable(this.tblPolicesReponse);

      // patientDetails = response.patientState.selectedPatient;
      // this.tblPolicesReponse = [];
      // patientDetails?.policies && patientDetails?.policies !== null && patientDetails?.policies.forEach(element => {

      //   if (element?.id !== "00000000-0000-0000-0000-000000000000") {
      //     this.tblPolicesReponse.push({
      //       policiesId: element?.policiesId,
      //       startDate: element?.startDate == null ? "" : new Date(element?.startDate)?.toLocaleDateString("en-US"),
      //       endDate: element?.endDate == null ? "" : new Date(element?.endDate)?.toLocaleDateString("en-US"),
      //       deductible: element?.deductible,
      //       lastName: element?.lastName,
      //       fisrtName: element?.payorLevel,
      //       middleName: element?.middleName,
      //       birthdate: element?.birthdate,
      //     });
      //   }
      // })
      // this.dataSource = new MatTableDataSource(this.tblPolicesReponse);
      // this.Table.setPolicyTable(this.tblPolicesReponse);
    }, err => {
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    }, () => {

    });
  }
  // ! posting table list
  total: number;
  getPostingTable() {
    this.isLoading = true;
    let postingDetails: ClaimDTO;
    if (this.claimId != null && this.claimId != "" && this.claimId != '00000000-0000-0000-0000-000000000000') {
      this.store.dispatch(new getBilling(this.claimId)).subscribe(response => {

        postingDetails = response?.billingState?.selectedBilling;
        this.tblPostReponse = [];
        this.total = response?.billingState?.selectedBilling.totalFee;
        postingDetails?.lstPostingCharges?.forEach(element => {
          if (element?.postingChargesId !== "00000000-0000-0000-0000-000000000000") {
            const diagonsis9: DiagnosisCode9DTO[] = this.drpDiagnosis9.filter((e, index) => e?.diagnosisCodeTypeData === element?.diagnosisCode9[index]) ?? [];
            const diagonsis10: DiagnosisCode10DTO[] = this.drpDiagnosis10.filter((e, index) => e?.diagnosisCodeTypeData === element?.diagnosisCode10[index]) ?? [];


            this.tblPostReponse.push({
              cpt: "",
              dos: element?.dos === null ? "" : new Date(element?.dos)?.toLocaleDateString("en-US") ?? "",
              diagnosis9: diagonsis9,
              diagnosis10: diagonsis10,
              code: element?.item,
              insurance: element?.insurance,
              provider: element?.provider,
              checkNo: element?.checkNo,
              m1: element?.modifier1,
              m2: element?.modifier2,
              m3: element?.modifier3,
              m4: element?.modifier4,
              dx1: element?.dxPointer1,
              dx2: element?.dxPointer2,
              dx3: element?.dxPointer3,
              dx4: element?.dxPointer4,
              pos: element?.pos,
              tos: element?.tos,
              dateFrom: element?.dateFrom,
              dateTo: element?.dateTo,
              fee: element?.fee,
              units: element?.units,
              copay: element?.copay,
              patOther: element?.other,
              total: element?.total,
              document: element?.document,
              statement: element?.statementNote,
              patientResp: element?.patientResp,
              id: element?.postingChargesId
            });
          }
          this.tblPostReponse[0] && this.ViewPosting(this.tblPostReponse[0]);
        })


        postingDetails?.lstPostingCharges?.length === 0 && postingDetails?.lstPostingCharges && this.getPatientData();
        this.isLoading = false;
        this.Table.setPostTable(this.tblPostReponse.reverse());
      }, err => {
        this.isLoading = false;
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      }, () => {

      });
    } else {
      this.store.dispatch(new getBilling(this.listClaimId)).subscribe(response => {
        postingDetails = response?.billingState?.selectedBilling;
        this.tblPostReponse = [];
        const original = this.dos
        this.total = response?.billingState?.selectedBilling.totalFee;
        postingDetails?.lstPostingCharges?.forEach(element => {
          if (element?.postingChargesId !== "00000000-0000-0000-0000-000000000000") {
            this.tblPostReponse.push({
              cpt: "",
              dos: original == null ? "" : new Date(original)?.toLocaleDateString("en-US"),
              diagnosis9: element?.diagnosisCode9,
              diagnosis10: element?.diagnosisCode10,
              code: element?.item,
              insurance: element?.insurance,
              provider: element?.provider,
              checkNo: element?.checkNo,
              m1: element?.modifier1,
              m2: element?.modifier2,
              m3: element?.modifier3,
              m4: element?.modifier4,
              dx1: element?.dxPointer1,
              dx2: element?.dxPointer2,
              dx3: element?.dxPointer3,
              dx4: element?.dxPointer4,
              pos: element?.pos,
              tos: element?.tos,
              dateFrom: element?.dateFrom,
              dateTo: element?.dateTo,
              fee: element?.fee,
              units: element?.units,
              copay: element?.copay,
              patOther: element?.other,
              total: element?.total,
              document: element?.document,
              statement: element?.statementNote,
              patientResp: element?.patientResp,
              id: element?.postingChargesId

            });
          }
        })
        this.Table.setPostTable(this.tblPostReponse.reverse());
      }, err => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      }, () => {
      });
    }

  }
  // ! delete table record
  deleteData(id: string) {

    if (this.claimId != null && this.claimId != "") {

      Swal.fire({
        title: 'Are you sure you want to Delete?',
        text: 'You won\'t be able to retrieve this data!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#34c38f',
        cancelButtonColor: '#f46a6a',
        confirmButtonText: 'Yes, delete it!'
      }).then(result => {
        if (result.value) {
          this.deleteSerivice.deletePostingCharges(this.claimId, id).subscribe(response => {
            this.tblPostReponse.forEach(element => {


              if (id == element?.id) {
                const index = this.tblPostReponse.indexOf(element, 0)
                this.tblPostReponse.splice(index, 1)
                // Swal.fire({ title: 'Success', html: 'Deleted Successfully', icon: 'success', timer: 3000, timerProgressBar: true });
                this.toastr.success('Deleted Successfully','Success')
              }
            });
            this.Table.setPostTable(this.tblPostReponse);

          });

        }
      });
    }
    else {

      Swal.fire({
        title: 'Are you sure you want to Delete?',
        text: 'You won\'t be able to retrieve this data!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#34c38f',
        cancelButtonColor: '#f46a6a',
        confirmButtonText: 'Yes, delete it!'
      }).then(result => {
        if (result.value) {
          this.deleteSerivice.deletePostingCharges(this.listClaimId, id).subscribe(response => {
            this.tblPostReponse.forEach(element => {

              if (id == element?.id) {
                const index = this.tblPostReponse.indexOf(element, 0)
                this.tblPostReponse.splice(index, 1)
                // Swal.fire({ title: 'Success', html: 'Deleted Successfully', icon: 'success', timer: 3000, timerProgressBar: true });
                this.toastr.success('Deleted Successfully','Success')
              }
            });
            // this.Table.setPostTable(this.tblPostReponse);
            // this.getPostingTable()
            this.Table.setPostTable(this.tblPostReponse);

          });

        }
      });
    }

  }

  ViewPosting(data: any) {

    if (data?.diagnosis9) {
      const diagonsis9: DiagnosisCode9DTO[] = data?.diagnosis9;
      this.postingForm.patchValue({
        drpDiag9: diagonsis9.map(d => d?.diagnosisCodeTypeData),
      })
    }
    if (data?.diagnosis10) {
      const diagonsis10: DiagnosisCode10DTO[] = data?.diagnosis10;

      this.postingForm.patchValue({
        drpDiag10ID: diagonsis10.map(d => d?.diagnosisCodeTypeData),
      })
    }
    this.postingForm.patchValue({
      drpCode: data?.code,
      txtDescription: data?.insurance,
      txtProvider: data?.provider,
      txtCheckNo: data?.checkNo,
      drpModifiers1: data?.m1,
      drpModifiers2: data?.m2,
      drpModifiers3: data?.m3,
      drpModifiers4: data?.m4,
      drpDx1: data?.dx1,
      drpDx2: data?.dx2,
      drpDx3: data?.dx3,
      drpDx4: data?.dx4,
      drpPos: data?.pos,
      drpTos: data?.tos,
      txtDateFrom: data?.dateFrom,
      txtDateTo: data?.dateTo,
      txtFee: data?.fee,
      txtUnits: data?.units,
      txtCopay: data?.coinsurance,
      txtOther: data?.patOther,
      txtTotal: data?.total,
      txtDocument: data?.document,
      txtStatement: data?.statement,
      txtPatientResp: data?.patientResp
    })
  }



}
export interface itemInterface {
  equipmentBillingCode?: string;
  id?: string;
  itemId?: string;
  name?: string,
  dos?: string;
  location?: string;
  insurance?: string;
  charge?: number;
}
export interface insuranceface {
  id: string;
  policy: string;
  name: string;
  policiesId: string;
  payorLevel: string
}
