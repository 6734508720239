<div class="container-fluid m-0 p-0">
  <!-- Headers -->
  <div class="row">
    <div class="col-lg-12">
      <div class="d-flex align-items-center justify-content-between">
        <h3 class="mb-0 header-text">
          {{ data.isSaveEnable ? "Add" : "View" }} EOB Details
        </h3>
        <div class="page-title-box page-title-right" >
          <form [formGroup]="FileForm">
            <div class="d-flex align-items-center justify-content-between">
              <!--Check EFT Drop Down-->
              <mat-form-field class="mr-3">
                <mat-label>
                  <div
                    class="select-placeholder-container"
                    *ngIf="ischeckEFTLoading"
                  >
                    <span>Loading...</span>
                    <mat-spinner class="spinner" diameter="20"></mat-spinner>
                  </div>
                  <span *ngIf="!ischeckEFTLoading">Check/EFT </span>
                </mat-label>
                <mat-select
                  formControlName="slctCheckEFT"
                  [disabled]="isExistingChk"
                  (selectionChange)="onCheckEFtChange($event)"
                  (openedChange)="onCheckEFTDropdownOpenChange($event)"
                >
                  <mat-option>
                    <ngx-mat-select-search
                      placeholderLabel="Search CheckNumber"
                      (keyup)="searchCheckEFT($event.target.value)"
                      noEntriesFoundLabel="No Matches found"
                      formControlName="slctCheckEFTFilter"
                    ></ngx-mat-select-search>
                  </mat-option>
                  <mat-option
                    *ngFor="let checkeft of checkEFTDrpData"
                    [value]="checkeft.blobName"
                  >
                    {{ checkeft.checkNumber }}
                  </mat-option>
                </mat-select>
              </mat-form-field>

              <span class="font-weight-bolder">Existing </span
              ><mat-slide-toggle
                [checked]="ischeckEftDisable"
                class="mr-2 ml-2"
                (change)="statusChange($event)"
              ></mat-slide-toggle>
              <span class="mr-3 font-weight-bolder">New</span>

              <button
                matTooltip="Click To Browse EOB"
                mat-button
                class="buttonColor font-size-10 mr-2"
                [disabled]="!isPdfLoad || !data.isSaveEnable || !isExistingChk"
                (click)="uploadFile.click()"
              >
                Browse <span class="asterisk">*</span>
                <mat-icon>cloud_upload</mat-icon>
                <mat-icon *ngIf="!isPdfLoad">
                  <mat-spinner
                    class="spinner-border spinner-border-sm"
                    diameter="20"
                  ></mat-spinner>
                </mat-icon>
              </button>

              <input
                autocomplete="off"
                id="file-upload"
                type="file"
                class="hidden"
                (change)="onFileChange($event)"
                #uploadFile
                accept=".pdf,.txt"
                style="display: none"
                formControlName="fileUpload"
              />
              <button mat-icon-button (click)="onCloseClick()">
                <mat-icon>close</mat-icon>
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  <!-- min-height: 40vh; -->
  <div class="mb-0  scrollable-card" style="max-height: 88vh">


  <div class="row mt-2">
    <div class="col-lg-5">
      <div class="card card-body p-2">
        <!-- <div class="row"> -->
          <form [formGroup]="AddItemForm">
            <!-- col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 -->
            <mat-form-field class="col-4 col-sm-4 col-md-4 col-lg-4 col-xl-4">
              <mat-label>
                <div
                  class="select-placeholder-container"
                  *ngIf="isLoadingloadClaimList"
                >
                  <span>Loading...</span>
                  <mat-spinner class="spinner" diameter="20"></mat-spinner>
                </div>
                <span *ngIf="!isLoadingloadClaimList"
                  >Claim Id <span class="asterisk">*</span></span
                >
              </mat-label>

              <!-- <mat-label>Claim Id<span class="asterisk">*</span>
                                <mat-spinner *ngIf="isLoadingloadClaimList" class="spinner" diameter="20"></mat-spinner>
                            </mat-label> -->
              <mat-select
                [disabled]="isIndividual || isEditEnabled || isClaimLoading"
                formControlName="sltClaimId"
                #singleSelect
                (selectionChange)="onClaimChange()"
                (openedChange)="onDropdownOpenChange($event)"
              >
                <!-- (openedChange)="searchClaim('')" -->
                <mat-option>
                  <ngx-mat-select-search
                    placeholderLabel="Search"
                    (keyup)="searchClaim($event.target.value)"
                    noEntriesFoundLabel="No Matches found"
                    formControlName="sltClaimIdFilter"
                  ></ngx-mat-select-search>
                </mat-option>
                <mat-option
                  *ngFor="let claim of filteredClaimList | async"
                  [value]="claim.claimId"
                >
                  {{ claim.claimNo }}
                </mat-option>
              </mat-select>
              <!-- <button mat-button matSuffix (click)="clearClaimList($event)" mat-icon-button>
                                <mat-icon>close</mat-icon>
                            </button> -->
            </mat-form-field>
            <mat-form-field class="col-4 col-sm-4 col-md-4 col-lg-4 col-xl-4">
              <mat-label>
                <div
                  class="select-placeholder-container"
                  *ngIf="isLoadingloadonClaimChange"
                >
                  <span>Patient Details<span class="asterisk">*</span></span>
                  <mat-spinner class="spinner" diameter="20"></mat-spinner>
                </div>
                <span *ngIf="!isLoadingloadonClaimChange"
                  >Patient Details <span class="asterisk">*</span></span
                >
              </mat-label>
              <input
                [matTooltip]="tooltipPatientValue"
                autocomplete="off"
                matInput
                formControlName="txtPatient"
                type="text"
                readonly="true"
                class="disable-pointer"
              />
            </mat-form-field>
            <mat-form-field class="col-4 col-sm-4 col-md-4 col-lg-4 col-xl-4">
              <mat-label>
                <div
                  class="select-placeholder-container"
                  *ngIf="isLoadingloadonClaimChange"
                >
                  <span>Ticket Id<span class="asterisk">*</span></span>
                  <mat-spinner class="spinner" diameter="20"></mat-spinner>
                </div>
                <span *ngIf="!isLoadingloadonClaimChange"
                  >Ticket Id <span class="asterisk">*</span></span
                >
              </mat-label>
              <input
                [matTooltip]="tooltipTicketValue"
                autocomplete="off"
                matInput
                formControlName="txtTicketId"
                type="text"
                readonly="true"
                class="disable-pointer"
              />
            </mat-form-field>

            <mat-form-field class="col-4 col-sm-4 col-md-4 col-lg-4 col-xl-4">
              <mat-label>Plan<span class="asterisk">*</span></mat-label>
              <mat-select
                [disabled]="data.isSaveEnable ? false : true"
                formControlName="txtPlan"
                #singleSelect
                (selectionChange)="onPlanChange()"
              >
                <mat-option
                  *ngFor="let plan of lstPlanTypeList"
                  [value]="plan.planType"
                >
                  {{ plan.planType }}
                </mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field
              class="col-4 col-sm-4 col-md-4 col-lg-4 col-xl-4"
              *ngIf="isInsurance"
            >
              <mat-label>Insurance</mat-label>
              <input
                autocomplete="off"
                matInput
                formControlName="txtInsurance"
                type="text"
                class="disable-pointer"
                readonly
                [matTooltip]="tooltipInsuranceValue"
              />
            </mat-form-field>

            <mat-form-field class="col-4 col-sm-4 col-md-4 col-lg-4 col-xl-4">
              <mat-label
                >{{ isPatientResponsibility ? "Transaction No" : "Check No" }}
                <span class="asterisk">*</span></mat-label
              >
              <input
                autocomplete="off"
                matInput
                formControlName="txtCheckno"
                type="text"
                maxlength="15"
                [readonly]="data.isSaveEnable ? false : true"
                [ngClass]="data.isSaveEnable ? '' : 'disable-pointer'"
              />
              <mat-error
                *ngIf="
                  AddItemForm?.get('txtCheckno').invalid &&
                  (AddItemForm?.get('txtCheckno').dirty ||
                  AddItemForm?.get('txtCheckno').touched)
                "
                >
                Please enter a valid {{ isPatientResponsibility ? "Transaction No" : "Check No" }}
                </mat-error>
            </mat-form-field>

            <mat-form-field class="col-4 col-sm-4 col-md-4 col-lg-4 col-xl-4">
              <mat-label
                >{{
                  isPatientResponsibility ? "Transaction Date" : "Check Date "
                }}
                <span class="asterisk">*</span></mat-label
              >
              <input
                autocomplete="off"
                formControlName="dCheckDate"
                matInput
                maxlength="10"
                type="text"
                placeholder="(MM/DD/YYYY)"
                [matDatepicker]="dCheckDate"
                (keydown)="addSlashToDate($event)"
                [readonly]="data.isSaveEnable ? false : true"
                [ngClass]="data.isSaveEnable ? '' : 'disable-pointer'"
                [max]="DateMax"
              />
              <mat-datepicker-toggle matSuffix [for]="dCheckDate">
              </mat-datepicker-toggle>
              <mat-datepicker #dCheckDate [disabled]="!data.isSaveEnable">
              </mat-datepicker>
            </mat-form-field>

            <mat-form-field
              class="col-8 col-sm-8 col-md-8 col-lg-8 col-xl-8"
              *ngIf="!isPatientResponsibility"
            >
              <!-- <mat-label>Item/HCPC Code<span class="asterisk">*</span></mat-label> -->
              <mat-label>
                <div
                  class="select-placeholder-container"
                  *ngIf="isLoadingloadonClaimChange"
                >
                  <span
                    >Product Code/HCPC Code<span class="asterisk">*</span></span
                  >
                  <mat-spinner class="spinner" diameter="20"></mat-spinner>
                </div>
                <span *ngIf="!isLoadingloadonClaimChange"
                  >Product Code/HCPC Code <span class="asterisk">*</span></span
                >
              </mat-label>
              <mat-select
                formControlName="sltItemId_HCPCCode"
                #singleSelect
                [disabled]="data.isSaveEnable ? false : true"
                (selectionChange)="patchBilledAmount()"
              >
                <mat-option>
                  <ngx-mat-select-search
                    placeholderLabel="Product Code/HCPC Code"
                    noEntriesFoundLabel="No Matches found"
                    formControlName="sltItemId_HCPCCodeFilter"
                  ></ngx-mat-select-search>
                </mat-option>
                <mat-option
                  *ngFor="
                    let itemId_HCPCCode of filteredtxtItemId_HCPCCode | async
                  "
                  [value]="itemId_HCPCCode.orderItemId"
                >
                  {{ itemId_HCPCCode.hcpcCode }}
                </mat-option>
              </mat-select>
              <!-- <button mat-button matSuffix (click)="clearItemId_HCPCCodeList($event)" mat-icon-button>
                                <mat-icon>close</mat-icon>
                            </button> -->
            </mat-form-field>

            <mat-form-field
              class="col-4 col-sm-4 col-md-4 col-lg-4 col-xl-4 mb-2"
              *ngIf="!isPatientResponsibility"
            >
              <mat-label
                >Billed Amount<span class="asterisk">*</span></mat-label
              >
              <input
                autocomplete="off"
                type="text"
                (input)="sanitizeInput($event, 'txtbilledAmount')"
                maxlength="14"
                formControlName="txtbilledAmount"
                matInput
                [readonly]="data.isSaveEnable ? false : true"
                [ngClass]="data.isSaveEnable ? '' : 'disable-pointer'"
              />
              <mat-error
                *ngIf="
                  AddItemForm?.get('txtbilledAmount').invalid &&
                  (AddItemForm?.get('txtbilledAmount').dirty ||
                    AddItemForm?.get('txtbilledAmount').touched)
                "
              >
                Enter 0-{{ maxNumberForDispaly }}/Decimal min 1/max 4 digits.
              </mat-error>
            </mat-form-field>

            <mat-form-field
              class="col-4 col-sm-4 col-md-4 col-lg-4 col-xl-4 mb-2"
              *ngIf="!isPatientResponsibility"
            >
              <mat-label>Allowed($)<span class="asterisk">*</span></mat-label>
              <input
                autocomplete="off"
                type="text"
                (input)="sanitizeInput($event, 'txtAllowed')"
                (keyup)="onKeyPressToFindisZero(4)"
                maxlength="14"
                formControlName="txtAllowed"
                matInput
                [readonly]="data.isSaveEnable ? false : true"
                [ngClass]="data.isSaveEnable ? '' : 'disable-pointer'"
              />
              <mat-error
                *ngIf="
                  AddItemForm?.get('txtAllowed').invalid &&
                  (AddItemForm?.get('txtAllowed').dirty ||
                    AddItemForm?.get('txtAllowed').touched)
                "
              >
                Enter 0-{{ maxNumberForDispaly }}/Decimal min 1/max 4 digits.
              </mat-error>
            </mat-form-field>

            <mat-form-field
              class="col-4 col-sm-4 col-md-4 col-lg-4 col-xl-4 mb-2"
            >
              <mat-label
                >Paid($)
                <span class="asterisk" *ngIf="isPatientResponsibility"
                  >*</span
                ></mat-label
              >
              <input
                autocomplete="off"
                type="text"
                (input)="sanitizeInput($event, 'txtPaid')"
                maxlength="14"
                formControlName="txtPaid"
                matInput
                [readonly]="!(data.isSaveEnable && !isZeroAllowed)"
                [ngClass]="
                  data.isSaveEnable && !isZeroAllowed ? '' : 'disable-pointer'
                "
              />
              <mat-error
                *ngIf="
                  AddItemForm?.get('txtPaid').invalid &&
                  (AddItemForm?.get('txtPaid').dirty ||
                    AddItemForm?.get('txtPaid').touched)
                "
              >
                Enter 0-{{ maxNumberForDispaly }}/Decimal min 1/max 4 digits.
              </mat-error>
            </mat-form-field>

            <mat-form-field
              class="col-4 col-sm-4 col-md-4 col-lg-4 col-xl-4 mb-2"
              *ngIf="!isPatientResponsibility"
            >
              <mat-label>Deduct</mat-label>
              <input
                autocomplete="off"
                type="text"
                (input)="sanitizeInput($event, 'txtDeduct')"
                maxlength="14"
                formControlName="txtDeduct"
                matInput
                [readonly]="data.isSaveEnable ? false : true"
                [ngClass]="data.isSaveEnable ? '' : 'disable-pointer'"
              />
              <mat-error
                *ngIf="
                  AddItemForm?.get('txtDeduct').invalid &&
                  (AddItemForm?.get('txtDeduct').dirty ||
                    AddItemForm?.get('txtDeduct').touched)
                "
              >
                Enter 0-{{ maxNumberForDispaly }}/Decimal min 1/max 4 digits.
              </mat-error>
            </mat-form-field>

            <mat-form-field
              class="col-4 col-sm-4 col-md-4 col-lg-4 col-xl-4 mb-2"
              *ngIf="!isPatientResponsibility"
            >
              <mat-label>Co-Ins</mat-label>
              <input
                autocomplete="off"
                type="text"
                (input)="sanitizeInput($event, 'txtCoins')"
                maxlength="14"
                formControlName="txtCoins"
                matInput
                [readonly]="!(data.isSaveEnable && !isZeroAllowed)"
                [ngClass]="
                  data.isSaveEnable && !isZeroAllowed ? '' : 'disable-pointer'
                "
              />
              <mat-error
                *ngIf="
                  AddItemForm?.get('txtCoins').invalid &&
                  (AddItemForm?.get('txtCoins').dirty ||
                    AddItemForm?.get('txtCoins').touched)
                "
              >
                Enter 0-{{ maxNumberForDispaly }}/Decimal min 1/max 4 digits.
              </mat-error>
            </mat-form-field>

            <mat-form-field
              class="col-4 col-sm-4 col-md-4 col-lg-4 col-xl-4 mb-4"
            >
              <mat-label
                >Current Balance
                <span class="asterisk" *ngIf="isPatientResponsibility"
                  >*</span
                ></mat-label
              >
              <input
                autocomplete="off"
                type="text"
                (input)="sanitizeInput($event, 'txtCurrentBalance')"
                maxlength="14"
                formControlName="txtCurrentBalance"
                matInput
                [readonly]="data.isSaveEnable ? false : true"
                [ngClass]="data.isSaveEnable ? '' : 'disable-pointer'"
              />
              <mat-error
                *ngIf="
                  AddItemForm?.get('txtCurrentBalance').invalid &&
                  (AddItemForm?.get('txtCurrentBalance').dirty ||
                    AddItemForm?.get('txtCurrentBalance').touched)
                "
              >
                Enter 0-{{ maxNumberForDispaly }}/Decimal min 1/max 4 digits.
              </mat-error>
            </mat-form-field>

            <mat-form-field
              class="col-4 col-sm-4 col-md-4 col-lg-4 col-xl-4 mb-2"
              *ngIf="!isPatientResponsibility"
            >
              <mat-label
                >Check Amount($)<span class="asterisk">*</span></mat-label
              >
              <input
                autocomplete="off"
                type="text"
                (input)="sanitizeInput($event, 'txtCheckAmount')"
                maxlength="14"
                formControlName="txtCheckAmount"
                matInput
                [readonly]="data.isSaveEnable ? false : true"
                [ngClass]="data.isSaveEnable ? '' : 'disable-pointer'"
              />
              <mat-error
                *ngIf="
                  AddItemForm?.get('txtCheckAmount').invalid &&
                  (AddItemForm?.get('txtCheckAmount').dirty ||
                    AddItemForm?.get('txtCheckAmount').touched)
                "
              >
                Enter 0-{{ maxNumberForDispaly }}/Decimal min 1/max 4 digits.
              </mat-error>
            </mat-form-field>

            <mat-form-field class="col-4 col-sm-4 col-md-4 col-lg-4 col-xl-4">
              <mat-label
                >Posting Date <span class="asterisk">*</span></mat-label
              >
              <input
                autocomplete="off"
                formControlName="dPostingDate"
                matInput
                maxlength="10"
                type="text"
                placeholder="(MM/DD/YYYY)"
                [matDatepicker]="dPostingDate"
                (keydown)="addSlashToDate($event)"
                [readonly]="data.isSaveEnable ? false : true"
                [ngClass]="data.isSaveEnable ? '' : 'disable-pointer'"
                [max]="DateMax"
              />
              <mat-datepicker-toggle matSuffix [for]="dPostingDate">
              </mat-datepicker-toggle>
              <mat-datepicker #dPostingDate [disabled]="!data.isSaveEnable">
              </mat-datepicker>
            </mat-form-field>

            <mat-form-field
              *ngIf="!isPatientResponsibility"
              class="col-4 col-sm-4 col-md-4 col-lg-4 col-xl-4"
            >
              <mat-label
                >Payment Type <span class="asterisk">*</span></mat-label
              >

              <mat-select
                formControlName="drpPaymentType"
                #singleSelect
                [disabled]="data.isSaveEnable ? false : true"
              >
                <mat-option>
                  <ngx-mat-select-search
                    placeholderLabel="Payment Type"
                    noEntriesFoundLabel="No Matches found"
                    formControlName="txtPaymentTypeFilter"
                  ></ngx-mat-select-search>
                </mat-option>
                <mat-option
                  *ngFor="let paymentTypes of filteredPaymentTypes | async"
                  [value]="paymentTypes.id"
                >
                  {{ paymentTypes.statusName }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </form>

          <form
            [formGroup]="AddAdjustmentPaymentForm"
            *ngIf="!isPatientResponsibility"
          >
          <!-- modal-body bgAdj m-4 p-1  -->
            <div class="bgAdj ml-3 mr-3">
              <mat-card-content>
                <div class="row" [hidden]="!data.isSaveEnable">
                  <!-- <mat-form-field class="col-4 col-sm-4 col-md-4 col-lg-4 col-xl-4">
                                        <mat-label>Reason Code<span class="asterisk">*</span></mat-label>
                                        <input autocomplete="new-txtReasonCode" matInput
                                            (input)="sanitizeInputForAdjustmentNoSpace($event, 'txtReasonCode')"
                                            formControlName="txtReasonCode" type="text" maxlength="15" />
                                        <mat-error
                                            *ngIf="AddAdjustmentPaymentForm.get('txtReasonCode').invalid && AddAdjustmentPaymentForm.get('txtReasonCode').touched">
                                            Please enter a valid reason code
                                        </mat-error>

                                    </mat-form-field> -->

                  <mat-form-field
                    class="col-4 col-sm-4 col-md-4 col-lg-4 col-xl-4"
                  >
                    <mat-label>
                      <div
                        class="select-placeholder-container"
                        *ngIf="isloadingGlossaryDropDownData"
                      >
                        <span>Reason Code</span>
                        <mat-spinner
                          class="spinner"
                          diameter="20"
                        ></mat-spinner>
                      </div>
                      <span *ngIf="!isloadingGlossaryDropDownData"
                        >Reason Code</span
                      >
                    </mat-label>
                    <mat-select
                      formControlName="txtReasonCode"
                      #singleSelect
                      (selectionChange)="patchNotes($event.value)"
                    >
                      <mat-option>
                        <ngx-mat-select-search
                          placeholderLabel="Search"
                          noEntriesFoundLabel="No Matches found"
                          formControlName="sltReasonCodeFilter"
                        ></ngx-mat-select-search>
                      </mat-option>
                      <mat-option
                        *ngFor="let reasonCode of filteredreasonCode | async"
                        [value]="reasonCode.id"
                      >
                        {{ reasonCode.glossaryCode }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>

                  <mat-form-field
                    class="col-4 col-sm-4 col-md-4 col-lg-4 col-xl-4"
                  >
                    <mat-label>Adjustment ($)</mat-label>
                    <input
                      autocomplete="off"
                      type="text"
                      (input)="
                        sanitizeInputForAdjustment($event, 'txtAdjustment')
                      "
                      maxlength="14"
                      formControlName="txtAdjustment"
                      matInput
                    />
                    <mat-error
                      *ngIf="
                        AddAdjustmentPaymentForm?.get('txtAdjustment')
                          .invalid &&
                        (AddAdjustmentPaymentForm?.get('txtAdjustment').dirty ||
                          AddAdjustmentPaymentForm?.get('txtAdjustment')
                            .touched)
                      "
                    >
                      Enter 0-{{ maxNumberForDispaly }}/Decimal min 1/max 4
                      digits.
                    </mat-error>
                  </mat-form-field>

                  <div
                    *ngIf="Currentindex === null"
                    class="col-4 col-sm-4 col-md-4 col-lg-4 col-xl-4 mt-2"
                  >
                    <button
                      mat-button
                      class="buttonColor font-size-10 mr-2"
                      [disabled]="
                        AddAdjustmentPaymentForm.invalid || isSaveAddItemPayment
                      "
                      (click)="saveAddAdjustment()"
                    >
                      <mat-icon>add</mat-icon> Add
                    </button>
                  </div>
                  <div
                    *ngIf="Currentindex !== null"
                    class="col-4 col-sm-4 col-md-4 col-lg-4 col-xl-4 mt-2"
                  >
                    <button
                      mat-button
                      class="buttonColor font-size-10 mr-2"
                      [disabled]="
                        AddAdjustmentPaymentForm.invalid || isSaveAddItemPayment
                      "
                      (click)="updateAdjustment()"
                    >
                      <mat-icon>edit</mat-icon> Update
                    </button>
                  </div>
                  <!-- <div *ngIf="Currentindex !== null" class="col-4 col-sm-4 col-md-4 col-lg-4 col-xl-4 mt-2">
                                        <button mat-button class="resetclr font-size-10 mr-2" (click)="CancelAdjustment()" ><mat-icon>cancel</mat-icon>
                                            Cancel</button>
                                    </div> -->

                  <mat-form-field
                    class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
                  >
                    <mat-label>Notes </mat-label>
                    <textarea
                      matInput
                      rows="2"
                      autocomplete="off"
                      maxlength="5000"
                      formControlName="txtComments"
                      [(ngModel)]="notesText"
                    ></textarea>
                    <div class="char-count">
                      {{ notesText?.length || 0 }} / 5000
                    </div>
                  </mat-form-field>
                </div>
                <div *ngIf="adjustmentPaymentDTO.length > 0">
                  <hr [hidden]="!data.isSaveEnable" />
                  <div class="table-responsive" *ngIf="isAdjLoading">
                    <table
                      id="tblAdjustmentPayment"
                      datatable
                      [dtOptions]="dtAdjustmentPaymentOptions"
                      class="tbl hover row-border hover w-100 display"
                    >
                      <thead>
                        <tr>
                          <!-- <th><mat-icon> wrap_text</mat-icon></th> -->
                          <th class="text-center" [matTooltip]="'Reason Code'">
                            Reason Code
                          </th>
                          <th
                            class="text-center"
                            [matTooltip]="'Adjustment ($)'"
                          >
                            Adjustment ($)
                          </th>
                          <th class="text-center" [matTooltip]="'Description'">
                            Description
                          </th>
                          <th class="text-center" [matTooltip]="'Action'">
                            Action
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <!-- *ngFor="let data of adjustmentPaymentDTO"
                                                adjustmentPaymentDTO.slice().reverse()
                                                -->
                        <tr *ngFor="let data of adjustmentPaymentDTO">
                          <!-- <td [matTooltip]="'Expand Notes'" class="dt-control"
                                                        (click)="handleTableCellClickForReasonCodeTable($event, [data.glossaryDescription])"></td> -->
                          <td [matTooltip]="data.reasonCode">
                            {{ data.reasonCode }}
                          </td>
                          <td [matTooltip]="data.adjustment" class="text-right">
                            {{ data.adjustment }}
                          </td>
                          <td [matTooltip]="data.glossaryDescription">
                            {{ data.glossaryDescription }}
                          </td>
                          <td class="text-center">
                            <!-- [class.disabled]="isSaveAddItemPayment || !data.isSaveEnable" -->
                            <!-- <a class="eyeCursorclass pointer" matToolTip="Edit">
                                                            <i (click)="edit(data)"
                                                                class="fa fa-pencil editBtnColor mr-1"></i>
                                                        </a> -->
                            <!-- [class.disabled]="isSaveAddItemPayment || !data.isSaveEnable" -->
                            <a
                              class="eyeCursorclass pointer ml-2"
                              matToolTip="Delete"
                            >
                              <i
                                (click)="delete(data)"
                                class="fa fa-trash asterisk mr-1"
                              ></i>
                            </a>

                            <!-- <a class="eyeCursorclass pointer ml-2" matToolTip="Delete">
                                                            <i (click)="check()" class="fa fa-grav asterisk mr-1"></i>
                                                        </a> -->
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </mat-card-content>
            </div>
          </form>
        <!-- </div> -->
        <div class="row" [hidden]="!data.isSaveEnable">
          <div class="col-lg-12">
            <div class="d-flex align-items-center justify-content-center">
              <!-- <button mat-button (click)="check()">
                                Check
                            </button> -->
              <!-- [disabled]="isSaveAddItemPayment || isUpdateAddItemPayment || AddItemForm.invalid || (!isPatientResponsibility && adjustmentPaymentDTO.length === 0) || (FileForm.invalid && isEmpty(strPdfString))" -->
              <button
                matTooltip="Click To Add Manual Posting"
                mat-button
                class="buttonColor font-size-10 mr-2"
                (click)="saveAddItemPayment()"
                [disabled]="
                  isSaveAddItemPayment ||
                  isUpdateAddItemPayment ||
                  AddItemForm.invalid ||
                  (FileForm.invalid && isEmpty(strPdfString)) ||
                  isInsuranceAvailable || DefaultLastUploadBlobName == null
                "
              >
                {{ isEditEnabled ? "Update" : "Add" }}
                <mat-icon
                  *ngIf="isSaveAddItemPayment || isUpdateAddItemPayment"
                >
                  <mat-spinner
                    class="spinner-border spinner-border-sm"
                    diameter="20"
                  ></mat-spinner>
                </mat-icon>
              </button>
              <button
                [disabled]="isSaveAddItemPayment || isUpdateAddItemPayment"
                matTooltip="Click To Reset Manual Posting"
                mat-button
                class="resetclr font-size-10 mr-2"
                (click)="resetAddItemPayment()"
              >
                {{ isEditEnabled ? "Cancel" : "Reset" }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-7">
      <div class="card card-body p-2">
        <div class="pdf-container pb-4" *ngIf="isPdfLoad">
          <ng-container>
            <ngx-extended-pdf-viewer
              [base64Src]="strPdfString"
              [height]="pdfheight"
              [showSidebarButton]="false"
              [textLayer]="true"
              [showPagingButtons]="true"
              [showOpenFileButton]="false"
              [showHandToolButton]="false"
              [showScrollingButton]="false"
              [showSpreadButton]="false"
              [showPropertiesButton]="false"
              [showBookmarkButton]="false"
              [zoom]="'page-width'"
              [enableDragAndDrop]="false"
              [showFindButton]="false"
            >
            </ngx-extended-pdf-viewer>
          </ng-container>
        </div>
        <div class="row" *ngIf="!isPdfLoad">
          <div class="col-12">
            <div class="d-flex justify-content-center">
              <img
                class="img-responsive pb-2 mr-1"
                src="assets/gifs/Infinity-loader.gif"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row mt-2">
    <div class="col-lg-12">
      <div class="card card-body p-2">
        <div
          class="d-flex justify-content-center"
          *ngIf="!isAddItemPaymentTableLoaded"
        >
          <img
            class="img-responsive pb-2 mr-1"
            src="assets/gifs/Infinity-loader.gif"
          />
        </div>
        <div *ngIf="isAddItemPaymentTableLoaded" class="table table-responsive">
          <button
            [disabled]="isUpdateCCSetItemPayment"
            [hidden]="this.settledClaimIds.length === 0 || isEditEnabled"
            mat-button
            class="buttonColor font-size-10"
            (click)="btnClick()"
          >
            <img
              class="img-responsive pr-1 mb-1 cursor"
              src="assets/Claim Page Icons/cctick.png"
              alt=""
              height="17"
            />
            Check Settlement
            <mat-icon *ngIf="isUpdateCCSetItemPayment">
              <mat-spinner
                class="spinner-border spinner-border-sm"
                diameter="20"
              ></mat-spinner>
            </mat-icon>
          </button>
          <table
            id="tblAddItemPayment"
            datatable
            class="row-bordered hover w-100 display"
            [dtOptions]="dtAddItemPaymentTableOptions"
          >
            <thead>
              <tr>
                <th><mat-icon> wrap_text</mat-icon></th>
                <th>
                  <div class="flex-container">
                    <mat-checkbox
                      [disabled]="!data.isSaveEnable"
                      class="checkbox-center mr-3"
                      matTooltip="CC Settled"
                      color="primary"
                      [(ngModel)]="isAllCCSettled"
                      (change)="onCheckboxChangeForAllSettled()"
                    >
                    </mat-checkbox>
                    <!-- <mat-icon>apps</mat-icon> -->
                  </div>
                </th>

                <th [matTooltip]="'Check/Transaction No'">
                  Check/Transaction No
                </th>
                <th [matTooltip]="'Check/Transaction Date'">
                  Check/Transaction Date
                </th>
                <th [matTooltip]="'Plan'">Plan</th>
                <th [matTooltip]="'Item Id / HCPC Code'">
                  Item Id / HCPC Code
                </th>
                <th [matTooltip]="'Payment Type'">Payment Type</th>
                <!-- <th [matTooltip]="'Reason Code'">Reason Code</th> -->
                <th [matTooltip]="'Billed Amount($)'" class="text-right">
                  Billed Amount($)
                </th>
                <th [matTooltip]="'Allowed($)'" class="text-right">
                  Allowed($)
                </th>
                <th [matTooltip]="'Paid($)'" class="text-right">Paid($)</th>
                <th [matTooltip]="'Write Off Amount($)'" class="text-right">
                  Write Off Amount($)
                </th>
                <!-- <th [matTooltip]="'Adjustment($)'" class="text-right">Adjustment($)</th> -->
                <th [matTooltip]="'Deduct($)'" class="text-right">Deduct($)</th>
                <th [matTooltip]="'Co-Ins($)'">Co-Ins($)</th>
                <th [matTooltip]="'Current Balance($)'">Current Balance($)</th>
                <th [matTooltip]="'Check Amount($)'">Check Amount($)</th>
                <th [matTooltip]="'Posting Date'">Posting Date</th>
                <th [matTooltip]="'Settled'">Settled</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let claimData of AddItemPaymentTableData">
                <!-- <td  *ngIf="!isEditEnabled" [matTooltip]="'Expand Notes'" class="dt-control"
                                    (click)="handleTableCellClick($event, claimData.lstAdjustPayments)"></td>
                                <td  *ngIf="isEditEnabled" [matTooltip]="'Please update the Adjustment value'"></td> -->

                <!-- class="dt-control" -->
                <td
                  [matTooltip]="
                    claimData.lstAdjustPayments?.length <= 0
                      ? 'Please update the Adjustment value'
                      : 'Expand Notes'
                  "
                  [ngClass]="
                    claimData.lstAdjustPayments?.length <= 0
                      ? ''
                      : !isEditEnabled
                      ? 'dt-control'
                      : ''
                  "
                  (click)="
                    !isEditEnabled &&
                      claimData.lstAdjustPayments?.length > 0 &&
                      handleTableCellClick($event, claimData.lstAdjustPayments)
                  "
                >
                  <!-- Render content based on isEditEnabled -->
                  <ng-container *ngIf="!isEditEnabled; else editEnabled">
                    <div class="d-flex flex-row">
                      <div class="notes-count-container">
                        <span class="notes-count"
                          >({{
                            claimData.lstAdjustPayments?.length || 0
                          }})</span
                        >
                      </div>
                      <!-- <mat-icon *ngIf="claimData.lstAdjustPayments?.length= 0">chevron_right</mat-icon>
                                        <mat-icon *ngIf=" claimData.lstAdjustPayments?.length > 0">expand_more</mat-icon> -->
                    </div>
                  </ng-container>
                  <ng-template #editEnabled>
                    <mat-icon>lock</mat-icon>
                  </ng-template>
                </td>

                <!-- <td
                                [matTooltip]="data.isOpen ? 'Collapse Notes' : 'Expand Notes'"
                                (click)="
                                  data.patientNotes?.length > 0 &&
                                    handleTableCellClickV1($event, data)
                                "
                              >
                                <div class="d-flex flex-row">
                                  <div class="notes-count-container">
                                    <span class="notes-count"
                                      >({{ data.patientNotes?.length || 0 }})</span
                                    >
                                  </div>
                                  <mat-icon *ngIf="!data.isOpen">chevron_right</mat-icon>
                                  <mat-icon *ngIf="data.isOpen">expand_more</mat-icon>
                                </div>
                              </td> -->

                <td class="flex-center">
                  <div class="flex-center-container">
                    <!-- [(ngModel)]="claimData.isCCSettled" [disabled]="claimData.isCCSettled" -->
                    <!-- && false -->
                    <ng-container
                      *ngIf="claimData.isCCSettled; else notSettled"
                    >
                      <mat-icon [matTooltip]="'CC has already been settled.'"
                        >done_all</mat-icon
                      >
                    </ng-container>

                    <ng-template #notSettled>
                      <!-- Content for the case when isCCSettled is false or null -->
                      <ng-container *ngIf="!isEditEnabled">
                        <mat-checkbox
                          [disabled]="!data.isSaveEnable || isEditEnabled"
                          class="checkbox-center mr-3"
                          matTooltip="CC Settled"
                          color="primary"
                          [(ngModel)]="claimData.isCheckedForCC"
                          (change)="onCheckboxChange()"
                        >
                        </mat-checkbox>

                        <!-- Edit Posting -->
                        <a
                          [class.disabled]="
                            !data.isSaveEnable || claimData.isCheckedForCC
                          "
                          [ngClass]="{
                            'disabled-link':
                              !data.isSaveEnable || claimData.isCheckedForCC
                          }"
                          (click)="editItem($event, claimData)"
                        >
                          <i
                            class="fa fa-pencil pointer editBtnColor mr-3"
                            matTooltip="Click To Edit"
                          ></i>
                        </a>
                        <!-- <a [class.disabled]="!data.isSaveEnable || claimData.isCheckedForCC" [ngClass]="{'disabled-link': !data.isSaveEnable || claimData.isCheckedForCC}"
                                                    (click)="deleteItem(claimData)">
                                                    <i class="fa fa-trash pointer deleteBtnColor mr-3"
                                                        matTooltip="Click To Delete"></i>
                                                </a> -->
                      </ng-container>
                      <ng-container *ngIf="isEditEnabled">
                        <mat-icon
                          [matTooltip]="'Please update the Adjustment value'"
                          >lock</mat-icon
                        >
                      </ng-container>
                    </ng-template>
                  </div>
                </td>

                <td [matTooltip]="claimData?.checkNo || '-'">
                  {{ claimData?.checkNo || "-" }}
                </td>
                <td [matTooltip]="this.getFormattedDate(claimData?.checkDate)">
                  {{ this.getFormattedDate(claimData?.checkDate) }}
                </td>
                <td [matTooltip]="claimData.planType">
                  {{ claimData.planType }}
                </td>
                <td [matTooltip]="claimData.itemProcedureCode">
                  {{ claimData.itemProcedureCode }}
                </td>
                <td [matTooltip]="claimData.paymentMethodBillingStatus || '-'">
                  {{ claimData.paymentMethodBillingStatus || "-" }}
                </td>
                <!-- <td [matTooltip]="claimData.ReasonCode">{{ claimData?.ReasonCode || '-' }}</td> -->
                <td
                  [matTooltip]="'$' + claimData?.billed || 0"
                  class="text-right" style="padding-right: 25px;"
                >
                  {{ claimData?.billed || 0 }}
                </td>
                <td
                  [matTooltip]="'$' + claimData?.allowed || 0"
                  class="text-right" style="padding-right: 25px;"
                >
                  {{ claimData?.allowed || 0 }}
                </td>
                <td
                  [matTooltip]="'$' + claimData?.insurancePaid || 0"
                  class="text-right" style="padding-right: 25px;"
                >
                  {{ claimData?.insurancePaid || 0 }}
                </td>
                <td
                  [matTooltip]="'$' + claimData?.writeOffAmount || 0"
                  class="text-right" style="padding-right: 25px;"
                >
                  {{ claimData?.writeOffAmount || 0 }}
                </td>
                <!-- <td [matTooltip]="claimData.Adjustment" class="text-right">{{ claimData.Adjustment |
                                    currency : "USD" : true }}</td> -->
                <td
                  [matTooltip]="'$' + claimData?.deductAmount || 0"
                  class="text-right" style="padding-right: 25px;"
                >
                  {{ claimData.deductAmount || 0 }}
                </td>
                <td
                  [matTooltip]="'$' + claimData?.coinsurance || 0"
                  class="text-right" style="padding-right: 25px;"
                >
                  {{ claimData?.coinsurance || 0 }}
                </td>
                <td
                  [matTooltip]="'$' + claimData?.currentBalance || 0"
                  class="text-right" style="padding-right: 25px;"
                >
                  {{ claimData?.currentBalance || 0 }}
                </td>
                <td
                  [matTooltip]="'$' + claimData?.checkAmount || 0"
                  class="text-right" style="padding-right: 25px;"
                >
                  {{ claimData?.checkAmount || 0 }}
                </td>
                <td
                  [matTooltip]="this.getFormattedDate(claimData?.postingDate)"
                >
                  {{ this.getFormattedDate(claimData?.postingDate) }}
                </td>

                <td [matTooltip]="claimData?.isCCSettled ? 'Yes' : 'No'">
                  {{ claimData?.isCCSettled ? "Yes" : "No" }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>

</div>

</div>
