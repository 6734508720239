<table
  id="tblCurrentSuppliesList"
  datatable
  [dtOptions]="dtinactiveSuppliesOptions"
  class="tbl hover row-border hover w-100 display"
>
  <thead>
    <tr>
      <th><mat-icon>apps</mat-icon></th>
      <th [matTooltip]="'Chart No'">Chart No</th>
      <th [matTooltip]="'Patient Name'">Patient Name</th>
      <th [matTooltip]="'Cell No'">Cell No</th>
      <th [matTooltip]="'DOS'">DOS</th>
      <th [matTooltip]="'Next Call Date'">Next Call Date</th>
      <!-- <th [matTooltip]="'Next Order Date'">Next Order Date</th> -->
      <th [matTooltip]="'Allowable Amount($)'">Allowable Amount($)</th>
      <!-- <th [matTooltip]="'Aging Days'">Aging Days</th> -->
      <th [matTooltip]="'CSR Name'">CSR Name</th>
      <th [matTooltip]="'Inactive Date'">Inactive Date</th>
      <th [matTooltip]="'Status'">Status</th>
    </tr>
  </thead>
  <tbody>
    <tr
      *ngFor="let data of InactiveListData"
      (click)="tblItemSelectedRow(data)"
      [ngClass]="{
        'row-highlighted': patientId === data?.patientId
      }"
    >
      <td>
        <mat-icon
          style="cursor: pointer !important"
          [matTooltip]="'Open Options'"
          [matMenuTriggerFor]="menu"
          >more_vert</mat-icon
        >
        <mat-menu class="custom-mat-menu" #menu="matMenu">
          <button mat-menu-item (click)="supplyInactive(3, data)">
            Active
          </button>
          <mat-divider></mat-divider>
          <button
            mat-menu-item
            [disabled]="isEmpty(data.patientId)"
            (click)="openViewNotesPopup()"
          >
            View Notes
          </button>
          <button
            mat-menu-item
            (click)="viewPatientLedger(data?.patientId)"
            [disabled]="isEmpty(data.patientId)"
          >
            View Patient Ledger
          </button>
          <mat-divider></mat-divider>
          <button
            mat-menu-item
            (click)="openLastVerification('Primary')"
            [disabled]="isEmpty(data?.primaryVerification)"
          >
            View Primary Insurance
          </button>

          <button
            mat-menu-item
            (click)="openLastVerification('Secondary')"
            [disabled]="isEmpty(data?.secVerification)"
          >
            View Secondary Insurance
          </button>

          <a
            [routerLink]="
              '/view_checklist/' +
              data.patientId +
              '/' +
              true +
              '/' +
              data.medicareId
            "
            target="_blank"
            class="preview-link"
            [ngClass]="{
              'disabled-link':
                data.medicareId === null ||
                isRoleName !== 'admin' ||
                data.medicareStatus === MedicareStatusV1.New ||
                isEmpty(data?.medicareStatus)
            }"
          >
            <button mat-menu-item>View Checklist</button>
          </a>
        </mat-menu>
      </td>

      <td
        (dblclick)="onRowDoubleClick(data?.chartId || '-')"
        [matTooltip]="data?.chartId || '-'"
      >
        <a
          [class.disabled]="isEmpty(data?.chartId) || isEmpty(data?.patientId)"
          [routerLink]="'/patientCreateEditTab/' + data?.patientId"
          target="_blank"
          class="preview-link"
          >{{ data?.chartId || "-" }}
        </a>
      </td>

      <td
        [matTooltip]="data?.patientname || '-'"
        (dblclick)="onRowDoubleClick(data?.patientname || '-')"
      >
        {{ data.patientname || "-" }}
      </td>
      <td
        [matTooltip]="data?.cellNo || '-'"
        (dblclick)="onRowDoubleClick(data?.cellNo || '-')"
      >
        {{ data.cellNo || "-" }}
      </td>
      <td
        [matTooltip]="data?.dos || '-'"
        (dblclick)="onRowDoubleClick(data?.dos || '-')"
      >
        {{ data.dos || "-" }}
      </td>
      <td
        [matTooltip]="data?.nextCallDate || '-'"
        (dblclick)="onRowDoubleClick(data?.nextCallDate || '-')"
      >
        {{ data.nextCallDate || "-" }}
      </td>
      <!-- <td  [matTooltip]="data?.nextOrderDate || '-'" (dblclick)="onRowDoubleClick(data?.nextOrderDate || '-')">{{data.nextOrderDate || '-'}}</td> -->
      <td class="text-right"
        [matTooltip]="data?.allowableAmt || '-'"
        (dblclick)="onRowDoubleClick(data?.allowableAmt || '-')"
      >
        {{ data.allowableAmt || "-" }}
      </td>
      <!-- <td
        [matTooltip]="data?.agingDays || '-'"
        (dblclick)="onRowDoubleClick(data?.agingDays || '-')"
      >
        {{ data.agingDays || "-" }}
      </td> -->
      <td [matTooltip]="data?.csrName || '-'" (dblclick)="onRowDoubleClick(data?.csrName || '-')">
        {{ data.csrName || "-" }}
      </td>
      <td
        [matTooltip]="data?.suppliesInactiveDate || '-'"
        (dblclick)="onRowDoubleClick(data?.suppliesInactiveDate || '-')"
      >
        {{ data.suppliesInactiveDate || "-" }}
      </td>
      <td
      [matTooltip]="data?.inActiveSupplyStatus || '-'"
      (dblclick)="onRowDoubleClick(data?.inActiveSupplyStatus || '-')"
    >
      {{ data.inActiveSupplyStatus || "-" }}
    </td>
    </tr>
  </tbody>
  <tfoot>
    <tr>
      <th>
        <mat-icon>apps</mat-icon>
      </th>
      <th>
        <input
          matInput
          class="textbox-border-class"
          type="text"
          placeholder="Chart No"
          name="search-ChartNo"
        />
      </th>
      <th>
        <input
          matInput
          class="textbox-border-class"
          type="text"
          placeholder="Patient Name"
          name="search-patientName"
        />
      </th>
      <th>
        <input
          matInput
          class="textbox-border-class"
          type="text"
          placeholder="Cell No"
          name="search-cellNo"
        />
      </th>
      <th>
        <input
          matInput
          class="textbox-border-class"
          type="text"
          placeholder="DOS"
          name="search-dos"
        />
      </th>
      <th>
        <input
          matInput
          class="textbox-border-class"
          type="text"
          placeholder="Next Call Date"
          name="search-nextCallDate"
        />
      </th>
      <!-- <th>
            <input matInput class="textbox-border-class" type="text" placeholder="Next Order Date"name="search-nextOrderDate" />
          </th> -->
      <th class="text-right">
        <input
          matInput
          class="textbox-border-class"
          type="text"
          placeholder="Allowable Amount($)"
          name="search-allowableAmount"
        />
      </th>
      <!-- <th>
        <input
          matInput
          class="textbox-border-class"
          type="text"
          placeholder="Aging Days"
          name="search-agingDays"
        />
      </th> -->
      <th>
        <input
          matInput
          class="textbox-border-class"
          type="text"
          placeholder="CSR Name"
          name="search-CSRName"
        />
      </th>
      <th>
        <input
          matInput
          class="textbox-border-class"
          type="text"
          placeholder="Inactive Date"
          name="search-InactiveDate"
        />
      </th>
      <th>
        <input
          matInput
          class="textbox-border-class"
          type="text"
          placeholder="Status"
          name="search-Status"
        />
      </th>
    </tr>
  </tfoot>
</table>
<!-- </div> -->
<div class="row" *ngIf="isLoading">
  <div class="col-12">
    <div class="d-flex justify-content-center">
      <img
        class="img-responsive pb-2 mr-1"
        src="assets/gifs/Infinity-loader.gif"
      />
    </div>
  </div>
</div>
