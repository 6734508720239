import { ListService } from '@abp/ng.core';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Store } from '@ngxs/store';
import { TableService } from 'projects/shared/src/app/table.service';
import { Subscription } from 'rxjs';
import { delay, map } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { ReminderDTO } from '../order-proxy/order-management/order/dto';
import { ReminderService } from '../order-proxy/order-management/order/reminder.service';
import { ReminderPopupComponent } from '../reminder-popup/reminder-popup.component';

@Component({
  selector: 'app-reminder',
  templateUrl: './reminder.component.html',
  styleUrls: ['./reminder.component.scss']
})
export class ReminderComponent implements OnInit, OnDestroy {
  strPageType: string = "reminders";
  @Input() orderId: string = '';
  @Input() patientId: string = "";
  @Input() defaultPatientId: string = '';
  @Input() saleOrderId: string = "";
  @Input() patientName: string = "";
  organizationUnitId = null;
  tblReponse: any[] = [];
  isLoading: boolean = true;
  @Input() orderStatus: boolean = true;
  subscription$: Subscription[] = [];


  constructor(
    private reminder: ReminderService,
    private list: ListService,
    private table: TableService,
    private store: Store,
    public dialog: MatDialog,
  ) { }

  ngOnInit() {
    const getOrganizationList = this.table.getOrganizationUnitId().subscribe(value => {
      this.organizationUnitId = value;
    }, err => {
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    })
    this.subscription$.push(getOrganizationList);
    //
    this.getReminderTableData();
  }

  ngOnDestroy(): void {
    this.subscription$?.forEach((sub) => {
      sub && sub?.unsubscribe();
    });
  }


  editReminderPopup(value: string) {

    const config: MatDialogConfig = {
      data: {
        Id: value,
        patientId: this.patientId,
        saleOrderId: this.saleOrderId,
        patientName: this.patientName,
        orderId: this.orderId
      }
    }
    const dialogRef = this.dialog.open(ReminderPopupComponent, config);

    const closeDialog = dialogRef.afterClosed().subscribe(() => {
      this.getReminderTableData();
    }, err => {
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    });
    this.subscription$.push(closeDialog);
    //
  }

  //! delete Reminder
  deleteReminder(datas?: any) {

    this.deleteReminders(datas.id)
  }

  deleteReminders(id: string) {
    Swal.fire({
      title: 'Are you sure you want to Delete?',
      text: 'You won\'t be able to retrieve this data!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#34c38f',
      cancelButtonColor: '#f46a6a',
      confirmButtonText: 'Yes, delete it!',

    }).then(result => {
      if (result.value) {
        const deleteReminder = this.reminder.delete(id).subscribe(response => {
          this.tblReponse.forEach(element => {
            if (id == element.id) {
              let index = this.tblReponse.indexOf(element, 0)
              this.tblReponse.splice(index, 1)
            }
          });
          this.table.setPateintRemindersTable(this.tblReponse);

        }, err => {
          const data: HttpErrorResponse = err;
          Swal.fire({
            icon: 'info',
            text: data?.error?.error?.message,
          });
        });
        this.subscription$.push(deleteReminder);
      }
    });
  }
  getReminderTableData() {
    this.isLoading = true;
    const remainderList = (query: any) => this.reminder.getList(query);
    const getReminderTable = this.list.hookToQuery(remainderList).pipe(map(value => {
      let data: any[] = [];
      value?.items && value?.items?.length !== 0 && value?.items?.forEach(element => {
        let value = {
          subject: ((element?.subject == null || element?.subject == undefined) ? "" : element?.subject),
          patientId: ((element?.patientId == null || element?.patientId == undefined) ? "" : element?.patientId),
          patientName: ((element?.patientName == null || element?.patientName == undefined) ? "" : element?.patientName),
          reminderDate: ((element?.reminderDate === null || element?.reminderDate === undefined) ? "" : new Date(element?.reminderDate)?.toLocaleDateString("en-US")),
          creationTime: ((element?.creationTime === null || element?.creationTime === undefined) ? "" : new Date(element?.creationTime)?.toLocaleDateString("en-US")),
          orderStatus: this.orderStatus
        }
        data.push(value);
      });
      return data ?? [];
    })).subscribe(response => {
      this.isLoading = false;
      this.table.setPateintRemindersTable(response ?? []);
      this.tblReponse = response ?? []
    }, err => {
      this.isLoading = false;
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    })
    this.subscription$.push(getReminderTable);
  }
}
