<footer class="footer">
  <div class="container-fluid">
    <div class="row">
      <div class="col-sm-6">
        <div class="text-sm-left d-none d-sm-block">
          <!-- Design & Develop by <a href="https://www.rndsoftech.com/"> Rndsoft
            Qsecure</a> -->
          <p> <span> Copyright Rndsoftech © <span> {{year}} </span> </span></p>
        </div>
      </div>
      <div class="col-sm-6">
        <div class="text-sm-right d-none d-sm-block">
          <p> <span>{{currentVersion}}</span></p>
        </div>
      </div>
    </div>
  </div>
</footer>
