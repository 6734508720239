import { mapEnumToOptions } from '@abp/ng.core';

export enum OrderDropdowns {
  All = 'All',
  WipStatus = 'WipStatus',
  WareHouseWipStatus = 'WareHouseWipStatus',
  TaxZones = 'TaxZones',
  SetupMethods = 'SetupMethods',
  Statuses = 'Statuses',
  Classifications = 'Classifications',
  ShippingCarriers = 'ShippingCarriers',
  ShippingMethods = 'ShippingMethods',
  WipStates = 'WipStates',
  Carriers = 'Carriers',
  ComplainceUsageMets = 'ComplainceUsageMets',
  AuthorizationOfOrders = 'AuthorizationOfOrders',
  MaskOrders = 'MaskOrders',
  HeadGears = 'HeadGears',
  Tubings = 'Tubings',
  CushionsPillows = 'CushionsPillows',
  DispFilters = 'DispFilters',
  ReusableFilters = 'ReusableFilters',
  WaterChambers = 'WaterChambers',
  StopReasons = 'StopReasons',
  Branches = 'Branches',
  InvLocations = 'InvLocations',
  DeliveryTechnicians = 'DeliveryTechnicians',
  CertificationConditionIndicators = 'CertificationConditionIndicators',
  ConditionCodes = 'ConditionCodes',
  InsurancePolicies = 'InsurancePolicies',
  TypeCodes = 'TypeCodes',
  TransCodes = 'TransCodes',
  ClaimNoteTypes = 'ClaimNoteTypes',
  Charts = 'Charts',
  TypeOfPlans = 'TypeOfPlans',
  VerifiedBies = 'VerifiedBies',
  PreparedBies = 'PreparedBies',
  PayablePercents = 'PayablePercents',
  YearTypes = 'YearTypes',
  AuthVisits = 'AuthVisits',
  AuthCategories = 'AuthCategories',
  AuthItems = 'AuthItems',
  Itemsize = 'Itemsize',
  ItemTypes = 'ItemTypes',
  ItemLocations = 'ItemLocations',
  ItemInsurances = 'ItemInsurances',
  PriceCodes = 'PriceCodes',
  PriceOptions = 'PriceOptions',
  NonTaxReasons = 'NonTaxReasons',
  EspdtIndicators = 'EspdtIndicators',
  SaleTypes = 'SaleTypes',
  lstBillingTypes = 'lstBillingTypes',
  lstDefaultPractices = 'lstDefaultPractices',
  PlanTypes = 'PlanTypes',
  ProductExpiryDays = 'ProductExpiryDays',
  ProductPurchaseStatus = 'ProductPurchaseStatus',
  ProductSerialNoStatus = 'ProductSerialNoStatus',
  ModeOfDelivery = 'ModeOfDelivery',
  MethodOfDelivery = 'MethodOfDelivery',
}

export const orderDropdownsOptions = mapEnumToOptions(OrderDropdowns);
