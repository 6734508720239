import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { TextValidators } from 'projects/shared/src/app/validations/text-validator';
import Swal from 'sweetalert2';

import { UserRegistrationDTO } from '../admin-proxy/platform-app-management/rcm/platform-management/user-invite/dto/models';
import { UserInviteService } from '../admin-proxy/platform-app-management/rcm/platform-management/user-invite/user-invite.service';
import { ToastrService } from "ngx-toastr";
@Component({
  selector: 'app-user-register',
  templateUrl: './user-register.component.html',
  styleUrls: ['./user-register.component.scss']
})
export class UserRegisterComponent implements OnInit {
  year: number = new Date().getFullYear();
  userRegistrationForm: FormGroup;
  submitted = false;
  hide = true;
  tokenId: string = "";
  emailId: string = "";
  tenantId: string = "";
  tenantName: string = "";
  emailMask: any;
  constructor(private title: Title,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private textValidators: TextValidators,
    private inviteUserService: UserInviteService,
    private toastr: ToastrService,
  ) { }

  ngOnInit(): void {
    //! Get below details from routing url
    this.tokenId = this.route?.snapshot?.queryParamMap?.get("t")??"";
    this.emailId = this.route?.snapshot?.queryParamMap?.get("eid")??"";
    this.tenantId = this.route?.snapshot?.queryParamMap?.get("tid")??"";
    this.tenantName = this.route?.snapshot?.queryParamMap?.get("tname")??"";

    this.title.setTitle("Qsecure | User Registration");

    //! Form controls and validations
    this.userRegistrationForm = this.formBuilder.group({
      //txtUserName: new FormControl('', Validators.compose([Validators.required, Validators.email ]))
      txtPassword: new FormControl('', Validators.required),
    });
  }

  //! User Registration
  userRegister() {
    
    //var vUserName = this.userRegistrationForm.value.txtUserName.split["@"] ;
    
    let userRegistrationDetails: UserRegistrationDTO = {
      token: this.tokenId,
      emailAddress: this.emailId,
      tenantId: this.tenantId,
      userName: this.userRegistrationForm.value.txtUserName.toLowerCase(),
      password: this.userRegistrationForm.value.txtPassword,
      name: "",
      surName: ""
    }
    this.inviteUserService.userRegistration(userRegistrationDetails).subscribe(response => {
       if (response) {
          // Swal.fire({
          //   title: 'Registered Successfully',
          //   html: 'You will be redirected to login page soon',
          //   icon: 'success',
          // });
          this.toastr.success('You will be redirected to login page soon','Registered Successfully')
           setTimeout(() => {
            this.router.navigate(['/']);
           }, 4000);
      }
    }, err => {
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    });
  }
}
