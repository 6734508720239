<div class="container-fluid">
  <div class="row">
    <div class="col-12">
      <div class="d-flex justify-content-between align-items-center">
        <p class="header-text mb-0">
          Machine Settings ({{ data.categoryName }})
        </p>
        <button mat-icon-button mat-dialog-close (click)="closeModel()" class="close-button">
          <mat-icon>close</mat-icon>
        </button>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-12">
      <div class="card card-body">
        <div class="d-flex justify-content-center" *ngIf="!isFormLoaded">
          <img class="img-responsive pb-2 mr-1" src="assets/gifs/Infinity-loader.gif" />
        </div>

        <form *ngIf="isFormLoaded" [formGroup]="machineSettingsForm">

            <div class="col-12 d-flex justify-content-between">
              <div class="row">

              <!-- Min Pressure -->
              <!--  -->
              <!-- <div class="mr-2"   class="col-3"  *ngIf="machineSettingFields.showMinPressure"> -->
                <mat-form-field [ngClass]="getColumnSize()" *ngIf="machineSettingFields.showMinPressure" >
                  <mat-label> Min Pressure </mat-label>
                    <input (keypress)="onKeyPress($event)" (paste)="onKeyPress($event)" matInput maxlength="5"
                    formControlName="txtMinPressure" autocomplete="off" />
                </mat-form-field>
              <!-- </div> -->

              <!-- Max Pressure -->
              <!-- <div class="mr-2" *ngIf="machineSettingFields.showMaxPressure"> -->
                <mat-form-field  [ngClass]="getColumnSize()" *ngIf="machineSettingFields.showMaxPressure" >
                  <mat-label> Max Pressure </mat-label>
                  <input (keypress)="onKeyPress($event)" (paste)="onKeyPress($event)" matInput maxlength="5"
                    formControlName="txtMaxPressure" autocomplete="off" />
                </mat-form-field>
              <!-- </div> -->

              <!-- Setting Time -->
              <!-- <div > -->
                <mat-form-field [ngClass]="getColumnSize()" *ngIf="machineSettingFields.showSettlingTime" >
                  <mat-label> Setting Time </mat-label>
                  <input (keypress)="onKeyPress($event)" (paste)="onKeyPress($event)" matInput maxlength="5"
                    formControlName="txtSettingTime" autocomplete="off" />
                </mat-form-field>
              <!-- </div> -->

              <!-- <div > -->
                <!-- IPAP -->
                <mat-form-field [ngClass]="getColumnSize()" *ngIf="machineSettingFields.showIPAP" >
                  <mat-label> IPAP </mat-label>
                  <input (keypress)="onKeyPress($event)" (paste)="onKeyPress($event)" matInput maxlength="5"
                    formControlName="txtIPAP" autocomplete="off" />
                </mat-form-field>
              <!-- </div> -->

              <!-- <div > -->
                <!-- EPAP -->
                <mat-form-field [ngClass]="getColumnSize()" *ngIf="machineSettingFields.showEPAP" >
                  <mat-label> EPAP </mat-label>
                  <input (keypress)="onKeyPress($event)" (paste)="onKeyPress($event)" matInput maxlength="5"
                    formControlName="txtEPAP" autocomplete="off"/>
                </mat-form-field>
              <!-- </div> -->

              <!-- <div > -->
                <!-- Ramp Pressure -->
                <mat-form-field  [ngClass]="getColumnSize()" *ngIf="machineSettingFields.showRampPressure" >
                  <mat-label> Ramp Pressure </mat-label>
                  <input (keypress)="onKeyPress($event)" (paste)="onKeyPress($event)" matInput maxlength="5"
                    formControlName="txtRampPressure" autocomplete="off"/>
                </mat-form-field>
              <!-- </div> -->

              <!-- <div > -->
                <!-- Ramp Time -->
                <mat-form-field  [ngClass]="getColumnSize()" *ngIf="machineSettingFields.showRampTime" >
                  <mat-label> Ramp Time </mat-label>
                  <input (keypress)="onKeyPress($event)" (paste)="onKeyPress($event)" matInput maxlength="5"
                    formControlName="txtRampTime" autocomplete="off" />
                </mat-form-field>
              <!-- </div> -->

              <!-- <div > -->
                <!-- BiFlex -->
                <mat-form-field [ngClass]="getColumnSize()" *ngIf="machineSettingFields.showBiFlex" >
                  <mat-label> BiFlex </mat-label>
                  <input (keypress)="onKeyPress($event)" (paste)="onKeyPress($event)" matInput maxlength="5"
                    formControlName="txtBiFlex" autocomplete="off" />
                </mat-form-field>
              <!-- </div> -->

              <!-- <div > -->
                <!-- Set Pressure -->
                <mat-form-field [ngClass]="getColumnSize()" *ngIf="machineSettingFields.showSetPressure" >
                  <mat-label> Set Pressure </mat-label>
                  <input (keypress)="onKeyPress($event)" (paste)="onKeyPress($event)" matInput maxlength="5"
                    formControlName="txtSetPressure" autocomplete="off"/>
                </mat-form-field>
              <!-- </div> -->

              <!-- <div > -->
                <!-- CFlex -->
                <mat-form-field [ngClass]="getColumnSize()" *ngIf="machineSettingFields.showCFlex" >
                  <mat-label> CFlex </mat-label>
                  <input (keypress)="onKeyPress($event)" (paste)="onKeyPress($event)" matInput maxlength="5"
                    formControlName="txtCFlex" autocomplete="off" />
                </mat-form-field>
              <!-- </div> -->

              <!-- <div > -->
                <!-- RRBPM -->
                <mat-form-field [ngClass]="getColumnSize()" *ngIf="machineSettingFields.showRRBPM" >
                  <mat-label> RRBPM </mat-label>
                  <input (keypress)="onKeyPress($event)" (paste)="onKeyPress($event)" matInput maxlength="5"
                    formControlName="txtRRBPM" autocomplete="off"/>
                </mat-form-field>
              <!-- </div> -->

              <!-- <div> -->
                <!-- IPAP Min -->
                <mat-form-field   [ngClass]="getColumnSize()" *ngIf="machineSettingFields.showIPAPmin" >
                  <mat-label> IPAP Min </mat-label>
                  <input (keypress)="onKeyPress($event)" (paste)="onKeyPress($event)" matInput maxlength="5"
                    formControlName="txtIPAPMin" autocomplete="off"/>
                </mat-form-field>
              <!-- </div> -->

              <!-- <div > -->
                <!-- IPAP Max -->
                <mat-form-field  [ngClass]="getColumnSize()" *ngIf="machineSettingFields.showIPAPmax">
                  <mat-label> IPAP Max </mat-label>
                  <input (keypress)="onKeyPress($event)" (paste)="onKeyPress($event)" matInput maxlength="5"
                    formControlName="txtIPAPMax" autocomplete="off"/>
                </mat-form-field>
              <!-- </div> -->

              <!-- <div > -->
                <!-- EEP -->
                <mat-form-field  [ngClass]="getColumnSize()" *ngIf="machineSettingFields.showEEP">
                  <mat-label> EEP </mat-label>
                  <input (keypress)="onKeyPress($event)" (paste)="onKeyPress($event)" matInput maxlength="5"
                    formControlName="txtEEP" autocomplete="off" />
                </mat-form-field>
              <!-- </div> -->

              <!-- <div > -->

                <!-- Set VT -->
                <mat-form-field  [ngClass]="getColumnSize()" *ngIf="machineSettingFields.showSetVT" >
                  <mat-label> Set VT </mat-label>
                  <input (keypress)="onKeyPress($event)" (paste)="onKeyPress($event)" matInput maxlength="5"
                    formControlName="txtSetVT" autocomplete="off" />
                </mat-form-field>
              <!-- </div> -->

              <!-- <div > -->
                <!-- Rate -->
                <mat-form-field  [ngClass]="getColumnSize()" *ngIf="machineSettingFields.showRate">
                  <mat-label> Rate </mat-label>
                  <input (keypress)="onKeyPress($event)" (paste)="onKeyPress($event)" matInput maxlength="5"
                    formControlName="txtRate" autocomplete="off"/>
                </mat-form-field>
              <!-- </div> -->

            </div>
          </div>
        </form>
      </div>
      <div class="col-12 mt-2 px-0">
        <div class="d-flex flex-row-reverse">
          <button mat-raised-button class="resetclr" [disabled]="isSettingDiabled" (click)="resetForm()">
            Reset
          </button>
          <button mat-raised-button class="buttonColor mr-2" [disabled]="isSettingDiabled"
            (click)="saveMachineSettings()">
            Save
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
