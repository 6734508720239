import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  // Override console methods to no-op functions
  console.log = function() {};
  console.warn = function() {};
  console.error = function() {};
}

if (environment.production) {
  enableProdMode();

}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.warn(err));
