import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
// import { defaultGuid } from 'projects/admin/src/app/document-perviewer/document-perviewer.component';
import { ListService, PagedAndSortedResultRequestDto } from '@abp/ng.core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { InboundDocumentService } from 'projects/admin/src/app/admin-proxy/platform-app-management/rcm/platform-management/inbound/inbound-document.service';
import { HttpErrorResponse } from '@angular/common/http';
import Swal from 'sweetalert2';
import {
  SortedDocumentGroupDTO,
  defaultDocumentIds,
  displayDocumentDetails,
} from 'projects/admin/src/app/admin-proxy/platform-app-management/rcm/platform-management/inbound/dto/models';
import { BehaviorSubject, Observable, Subject, Subscription } from 'rxjs';
// import { documentTypesData } from 'projects/admin/src/app/admin-dashboard/dashboard-data';
import {
  DocumentClassificationDto,
  NewPatientDTO,
  SplitPageDetails,
  policyInsurance,
} from 'projects/admin/src/app/admin-proxy/platform-app-management/rcm/platform-management/inbound/dto/models';
import {
  Complainces,
  InitialFaceToFaces,
  Prescriptions,
  SaveDocumentDto,
  SleepStudies,
  sortedDocumentDetailsDTO,
} from 'projects/admin/src/app/admin-proxy/platform-app-management/rcm/platform-management/sorting/dto';
import { PDFDocument, copyStringIntoBuffer, values } from 'pdf-lib';
import { ToastrService } from 'ngx-toastr';
import { SortType } from 'projects/admin/src/app/admin-proxy/platform-app-management/rcm/platform-management/localization/enumeration';
import { PagesLoadedEvent } from 'ngx-extended-pdf-viewer';
// import { noWhitespaceValidator } from 'projects/patient/src/app/add-note-tab/add-note-tab.component';
import { TextValidators } from 'projects/shared/src/app/validations/text-validator';
import { DocumentBinService } from 'projects/admin/src/app/admin-proxy/platform-app-management/rcm/platform-management/document-bin/document-bin.service';
import { DocumentsService } from 'projects/patient/src/app/patient-proxy/controllers';
import { MatSelect, MatSelectChange } from '@angular/material/select';
import { defaultGuid, SortingShortCode } from '../../enums/allenums.enum';
import { MatDialog } from '@angular/material/dialog';
import { PatientIcd10Component } from 'projects/patient/src/app/patient-icd10/patient-icd10.component';
import {
  DiagnosisCode10DTO,
  MachineTypeDTO,
  SymptomsDTO,
} from 'projects/patient/src/app/dropdown-proxy/dropdown-management/dropdowns/dto/patient';
import {
  DiagnosisCode10Service,
  PatientDropdowns,
} from 'projects/patient/src/app/dropdown-proxy/dropdown-management/dropdowns';
import { debounceTime, map, startWith } from 'rxjs/operators';
import { PatientMasterDropdownService } from 'projects/patient/src/app/dropdown-proxy/dropdown-management/dropdowns/master-dropdown-service';
import { DoctorService } from 'projects/patient/src/app/patient-proxy/patient';
import { doctorInterface } from 'projects/billing/src/app/billing-claim-info/billing-claim-info.component';
import { PatientDoctorComponent } from 'projects/patient/src/app/patient-doctor/patient-doctor.component';
import { Router } from '@angular/router';
import { PatientDocumentService } from 'projects/patient/src/app/patient-proxy/patient-optimization';
import { PatientCommonDocumentMedicareUploadTabComponent } from '../patient-common-document-medicare-upload-tab/patient-common-document-medicare-upload-tab.component';
import { CookieService } from 'ngx-cookie-service';
import { CommunicationService } from '../../services/communication.service';
import { Title } from '@angular/platform-browser';
@Component({
  selector: 'app-PatientCommonSortingTab',
  templateUrl: './PatientCommonSortingTab.component.html',
  styleUrls: ['./PatientCommonSortingTab.component.scss'],
  providers: [ListService],
})
export class PatientCommonSortingTabComponent implements OnInit {
  @Output() patientIdEmitter: EventEmitter<string> = new EventEmitter<string>();
  @Input() patientId: string = defaultGuid;
  @Input() documentId: string = defaultGuid;
  isShowSpinner:boolean = false;
  patientName:string;
  chartId:string;
  isHidden = true;
  drpdefaultDocumentFilterControl = new FormControl();
  fgGetSortingView: FormGroup;
  drpdefaultDocument: defaultDocumentIds[];
  defaultsortedDocument: SortedDocumentGroupDTO[];
  filteredDefaultDocuments: any[];
  isLoading = false;
  overAllPages: number = 0;
  totalPages = 0;
  sortedPages = 0;
  pendingPages = 0;
  documentTypes = [];
  idSelected: number;
  checkValidInputCase = 0;
  currentPage: number = 1;
  strSelectedPdfPath: string = '';
  pageNumber: number = 1;
  pdfPages: number = 0;
  documentIdv1:string;
  // documentId: string;
  // dataBlobName: string
  blobName: string;
  branchId: string;
  inboundStatusId: string;
  defaultFaxId: string;
  statusId: string;
  isNewSetupDone: string;
  saveDocumentDetails: SaveDocumentDto[] = [];
  pageCount = 0;
  fileUploadsInput: sortedDocumentDetailsDTO;
  initialFaceToFaces: InitialFaceToFaces;
  sleepStudies: SleepStudies;
  prescriptions: Prescriptions;
  complainces: Complainces;
  arrowButtonClicked = false;
  // pdfheight: string = '100%';
  pdfheight = '500px';
  newSetupForm: FormGroup;
  // @ViewChild('drpdefaultDocumentSelect') drpdefaultDocumentSelect!: MatSelect;
  @ViewChild('drpdefaultDocumentSelect') drpdefaultDocumentSelect: ElementRef;
  subscription$: Subscription[] = [];
  newTotalPageCount: number;
  isDisabled = false;
  defaultDisabled = true;
  sortedPagesList = [];
  isLoadSortingView: boolean = false;
  isMedicareList: any;
  SortingShortCode = SortingShortCode;
  shortCodeList: any;
  @ViewChildren('myInputRefs') myInputRefs: QueryList<ElementRef>;
  drpDiagnosisICD10: DiagnosisCode10DTO[] = [];
  drpSymptoms: any[] = [];
  drpMachines: MachineTypeDTO[] = [];
  filteredSymptoms: Observable<SymptomsDTO[]> | undefined;
  filteredDiagnosiss: Observable<DiagnosisCode10DTO[]> | undefined;
  filteredRxDiagnosiss: Observable<DiagnosisCode10DTO[]> | undefined;
  filteredMachines: Observable<MachineTypeDTO[]> | undefined;
  lstDoctor = [];
  filteredReferralDoctors: Observable<doctorInterface[]> | undefined; //Filtered Referring Provider Doctor Types
  filteredPhysicians: Observable<doctorInterface[]> | undefined; //Filtered Referring Provider Doctor Types
  filteredServiceType: any;
  filteredDoctors: Observable<doctorInterface[]> | undefined; //Filtered Referring Provider Doctor Types
  filteredRxDoctors: Observable<doctorInterface[]> | undefined; //Filtered Referring Provider Doctor Types
  isSaveDisabled = false;
  isSortingStart = false;
  isAnnotation = false;
  previousValue: any;
  firstValue: any;
  defaultDocument: string;
  localStoragetenantId = localStorage.getItem('tenantId') ?? '';
  wipSelectedValue: string = '';
  private saveSubject: Subject<void> = new Subject<void>();
  isAnnotationClearAllowed: boolean = false;
  isNewPatient: any;
  editFormValues: boolean = false;
  isInbound:boolean;
  defaultFaxShortCodeId:any;
  signatureIdfind:any;
  signatureId: string;
  strPdfString: string = '';
  documentName:string;
  constructor(
    private fb: FormBuilder,
    private inboundDocumentService: InboundDocumentService,
    private toastr: ToastrService,
    private textValidators: TextValidators,
    private documentBindService: DocumentBinService,
    private documentsService: DocumentsService,
    private cd: ChangeDetectorRef,
    private matDialog: MatDialog,
    public list: ListService,
    private diagnosis10: DiagnosisCode10Service,
    private patientDropdownService: PatientMasterDropdownService,
    private matDoctorDialog: MatDialog,
    private doctorService: DoctorService,
    private router: Router,
    private dialog: MatDialog,
    public title: Title,
    private patientDocumentService: PatientDocumentService,
    private cookieService: CookieService,
    private communicationService: CommunicationService,
  ) {

    this.communicationService.functionLoadDeafultFaxIdList$.subscribe(() => {
      this.loadDefaultDropDowns();
    });

    this.communicationService.sharedDataisSorting$.subscribe((sharediSorting) => {
      this.isSortingStart = sharediSorting;
    });

    this.communicationService.sharedDataisAnnotate$.subscribe((sharedisAnnotate) => {
      this.isAnnotation = sharedisAnnotate;
    });
  }

  ngOnInit() {
    this.title.setTitle('Qsecure | Sorting');
    // this.blobName="5f9de99e-de9b-6e7f-2394-3a0fb72f2021.pdf";
    // this.blobName="6703b600-ade0-90c4-2538-3a0f994a46bf.pdf";
    this.isNewPatient = this.cookieService.get('patientStatus');
    this.createSortingForm();
    this.loadDefaultDropDowns();
    // this.doctorDropdown();
    // this.getDropdown();
    this.getWIPstatusId();
    // this.saveSubject.pipe(debounceTime(1000)).subscribe(() => {
    //   this.isSaveDisabled = false;
    //   this.isDisabled = true;
    //   this.saveDocument();
    // });

    if (this.isNewPatient == 'true') {
      this.editFormValues = true;
    } else {
      this.editFormValues = false;
      // this.patientIdEmitter.emit();
    }

  }

  editForm() {
    this.editFormValues = true;
  }
  ngOnDestroy(): void {
    if (this.subscription$) {
      // Iterate through each subscription and unsubscribe
      this.subscription$.forEach((subscription) => {
        subscription.unsubscribe();
      });
    }
  }

  @HostListener('window:beforeunload', ['$event'])
  unloadNotification($event: any): void {
    // Check if there are unsaved changes (you'll need to implement this logic)
    if (this.isSortingStart) {
      $event.returnValue = true;
    }
  }

  // getSortedPagesTooltip(): string {
  //   return this.sortedPagesList.join(', ');
  // }

  // clearDoctorsList(event: Event) {
  //   event.stopPropagation();
  //   this.newSetupForm?.patchValue({
  //     txtDoctor: '',
  //   });
  // }

  // clearRxDoctorsList(event: Event) {
  //   event.stopPropagation();
  //   this.newSetupForm?.patchValue({
  //     txtRXDoctor: '',
  //   });
  // }

  // clearSymptomList(event: Event) {
  //   event.stopPropagation();
  //   this.newSetupForm?.patchValue({
  //     drpSymptoms: '',
  //   });
  // }

  // clearMachineList(event: Event) {
  //   event.stopPropagation();
  //   this.newSetupForm?.patchValue({
  //     drpMachineDetails: '',
  //   });
  // }

  // clearDiagnosisICDList(event: Event) {
  //   event.stopPropagation();
  //   this.newSetupForm?.patchValue({
  //     txtdiagnosisCode10Id: '',
  //   });
  // }

  // clearRxDiagnosisICDList(event: Event) {
  //   event.stopPropagation();
  //   this.newSetupForm?.patchValue({
  //     txtRXdiagnosisCode10Id1: '',
  //   });
  // }

  // //Get Doctor Dropdown
  // doctorDropdown(): void {
  //   this.lstDoctor = [];
  //   const itemList = (query: any) => this.doctorService.getList(query);

  //   const doctorDropdownSubscription = this.list
  //     .hookToQuery(itemList)
  //     .subscribe(
  //       (response) => {
  //         response?.items?.forEach((element) => {
  //           this.lstDoctor?.push({
  //             name:
  //               element?.npiNumber +
  //               ' / ' +
  //               element?.firstName +
  //               ' ' +
  //               element?.middleName +
  //               ' ' +
  //               element?.lastName,
  //             id: element?.id ?? '',
  //           });
  //         });
  //         //Referring Provider Doctor Dropdown
  //         this.filteredReferralDoctors = this.newSetupForm
  //           ?.get('txtReferralFilter')
  //           ?.valueChanges.pipe(
  //             startWith(''),
  //             map((value) =>
  //               this.lstDoctor?.filter((option) =>
  //                 option?.name
  //                   ?.toLowerCase()
  //                   ?.includes(value?.toLowerCase() ?? '')
  //               )
  //             )
  //           );
  //         //Primary Doctor Dropdown
  //         this.filteredPhysicians = this.newSetupForm
  //           ?.get('txtPhysicianFilter')
  //           ?.valueChanges.pipe(
  //             startWith(''),
  //             map((value) =>
  //               this.lstDoctor?.filter((option) =>
  //                 option?.name
  //                   ?.toLowerCase()
  //                   ?.includes(value?.toLowerCase() ?? '')
  //               )
  //             )
  //           );
  //         this.filteredDoctors = this.newSetupForm
  //           ?.get('txtDoctorFilter')
  //           ?.valueChanges.pipe(
  //             startWith(''),
  //             map((value) =>
  //               this.lstDoctor?.filter((option) =>
  //                 option?.name
  //                   ?.toLowerCase()
  //                   ?.includes(value?.toLowerCase() ?? '')
  //               )
  //             )
  //           );
  //         this.filteredRxDoctors = this.newSetupForm
  //           ?.get('txtRxDoctorFilter')
  //           ?.valueChanges.pipe(
  //             startWith(''),
  //             map((value) =>
  //               this.lstDoctor?.filter((option) =>
  //                 option?.name
  //                   ?.toLowerCase()
  //                   ?.includes(value?.toLowerCase() ?? '')
  //               )
  //             )
  //           );
  //       },
  //       (err) => {
  //         const data: HttpErrorResponse = err;
  //         Swal.fire({
  //           icon: 'info',
  //           text: data?.error?.error?.message,
  //         });
  //       },
  //       () => {}
  //     );
  //   this.subscription$.push(doctorDropdownSubscription);
  // }

  // //Open Referral Doctors Search Popup
  // openDoctorModal(doctorId?: string) {
  //   const dialogRef = this.matDoctorDialog.open(PatientDoctorComponent, {
  //     disableClose: true,
  //     data: { doctorId: doctorId },
  //   });
  //   const closeDialog = dialogRef.afterClosed().subscribe(
  //     () => {
  //       this.doctorDropdown();
  //     },
  //     (err) => {
  //       const data: HttpErrorResponse = err;
  //       Swal.fire({
  //         icon: 'info',
  //         text: data?.error?.error?.message,
  //       });
  //     }
  //   );
  //   this.subscription$.push(closeDialog);
  // }

  // //On Pages Load to Get the total Pages in the pdf
  // public onPagesLoaded(pagecount: PagesLoadedEvent): void {
  //   this.isLoadSortingView = true;
  //   this.totalPages = pagecount.pagesCount;
  //   this.pdfPages = pagecount.pagesCount;
  //   this.overAllPages = pagecount.pagesCount;
  //   this.isLoadSortingView = false;
  // }

  // numberOnly(event: { which: any; keyCode: any }): boolean {
  //   const charCode = event?.which ? event?.which : event?.keyCode;
  //   if (
  //     charCode > 31 &&
  //     (charCode < 48 || charCode > 57) &&
  //     (charCode < 44 || charCode > 45)
  //   ) {
  //     return false;
  //   }
  //   return true;
  // }

  // onPageChange(e) {
  //   this.currentPage = e;
  // }

  createSortingForm() {
    this.fgGetSortingView = this.fb.group({
      drpdefaultDocument: new FormControl('', [Validators.required]),
    });
    // this.newSetupForm = this.fb.group({
    //   //Initial Face to Face
    //   // txtFobPatientName: new FormControl(''),
    //   // txtIobDob: new FormControl(''),
    //   txtIobFadeToFace: new FormControl(''),
    //   txtDateofVisit: new FormControl(''),
    //   txtDoctor: new FormControl(''),
    //   txtNpiNumber: new FormControl(''),
    //   txtSignedDate: new FormControl(''),
    //   chkPrevioushistory: new FormControl(''),
    //   chkSleepTestRecommended: new FormControl(''),
    //   chkSleepDoctorSigned: new FormControl(''),
    //   chkSleepPatientBenifited: new FormControl(''),
    //   drpSymptoms: new FormControl(''),

    //   //Sleep Study
    //   // txtSleepStudyPatientName: new FormControl(''),
    //   // txtSleepStudyDob: new FormControl(''),
    //   txtStudyDate: new FormControl(''),
    //   txtPressureSetting: new FormControl(''),
    //   txtREI: new FormControl(),
    //   txtRDI: new FormControl(),
    //   txtSleepMinutes: new FormControl(''),
    //   txtAHI: new FormControl(''),
    //   chkSleepStudyDoctorSigned: new FormControl(''),
    //   txtSSCentralApneas: new FormControl(''),
    //   txtSSCentralHyponeas: new FormControl(''),
    //   txtSSApneaAndHypopnea: new FormControl(''),
    //   txtDiagnosisStudyDate: new FormControl(''),
    //   txtTitrationSleepStudyDate: new FormControl(''),
    //   txtTAHI: new FormControl(''),
    //   txtTCentralApneas: new FormControl(''),
    //   txtTCentralHyponeas: new FormControl(''),
    //   txtTApneaAndHypopnea: new FormControl(''),
    //   chkCPAPTriedAndFail: new FormControl(''),
    //   chkRespiratoryBreakdownAvailable: new FormControl(''),
    //   chkTitrationSleepStudyDoctorSigned: new FormControl(''),
    //   chkisCPAPReturned: new FormControl(''),
    //   chkTSSBaselineFile: new FormControl(''),
    //   txtObstructiveApnea: new FormControl(''),
    //   txtdiagnosisCode10Id: new FormControl(''),

    //   //RX
    //   // txtRXPatientName: new FormControl(''),
    //   // txtRXDOB: new FormControl(''),
    //   txtPrescriptionDate: new FormControl(''),
    //   txtRXDoctor: new FormControl(''),
    //   drpMachineDetails: new FormControl(''),
    //   txtPressureLevel: new FormControl(''),
    //   txtLengthOfNeed: new FormControl(''),
    //   txtRXNpiNumber: new FormControl(''),
    //   chkRXDoctorSigned: new FormControl(''),
    //   txtRXDoctorSignedDate: new FormControl(''),
    //   txtRXdiagnosisCode10Id1: new FormControl(''),

    //   //Compliance Report
    //   // txtCompliancePatientName: new FormControl(''),
    //   // txtComplianceDOB: new FormControl(''),
    //   txtComplianceStudyStartDate: new FormControl(''),
    //   txtCompliancePercentage: new FormControl(''),
    //   txtComplianceStudyEndDate: new FormControl(''),
    //   rbnComplianceMet: new FormControl(''),
    //   txtUsagedays: new FormControl(''),
    //   txtUsagedaysOutOf: new FormControl(''),
    //   txtGreater4Hours: new FormControl(''),
    //   txtLess4hours: new FormControl(''),

    //   txtDoctorFilter: new FormControl(''),
    //   txtSymptomsFilter: new FormControl(''),
    //   txtDiagnosisCode10Filter: new FormControl(''),
    //   txtMachineDetailsFilter: new FormControl(''),
    //   txtRxDoctorFilter: new FormControl(''),
    //   txtRxDiagnosisCode10Filter: new FormControl(''),
    // });
  }

  filterDefaultDocuments(filterTerm: string): any[] {
    // if (!filterTerm) {
    //   console.log('!!!!!!!!!!!!!!!!!!!!!!!!!!!filterTerm');
    //   return this.drpdefaultDocument;
    // }

    const trimmedFilterTerm = filterTerm ? filterTerm.trim().toLowerCase() : '';
    return this.drpdefaultDocument.filter((dD) =>
      dD.faxId.toLowerCase().includes(trimmedFilterTerm)
    );
  }

  checkDocumentDetails(shortCodeId: string) {
    const result = this.shortCodeList.includes(shortCodeId);
    return result;
  }

  // appendPreviousSavedData() {
  //   this.isDisabled = true;
  //   try {
  //     this.sortedPagesList = [];
  //     this.documentTypes.forEach((item) => {
  //       // Check if the item has "documentPages" property and it is an array
  //       if (
  //         item.hasOwnProperty('documentPages') &&
  //         Array.isArray(item.documentPages)
  //       ) {
  //         // Concatenate the "documentPages" values to myNewArray
  //         this.sortedPagesList = this.sortedPagesList.concat(
  //           item.documentPages
  //         );
  //         this.sortedPagesList = [
  //           ...new Set(this.sortedPagesList.concat(item.documentPages)),
  //         ];
  //       }
  //     });
  //     this.sortedPages = this.sortedPagesList.length;
  //   } catch (error) {
  //     console.error('Error:', error);
  //   } finally {
  //     this.isDisabled = false; // Enable the button when the operation completes (or encounters an error)
  //   }
  // }

  //Sort Document to the Document Types
  // async moveDocument(inputValue: any, selectedId: any) {
  //   this.isSortingStart = true;
  //   this.isDisabled = true;
  //   try {
  //     if (selectedId !== SortingShortCode.All) {
  //       var split = [];
  //       let documentDetails: DocumentClassificationDto;
  //       if (this.checkValidInput(inputValue)) {
  //         if (this.checkValidInputCase === 1) {
  //           split.push(Number(inputValue));
  //         } else if (this.checkValidInputCase === 2) {
  //           split = inputValue?.split(',');
  //           split = split.map((a) => Number(a));
  //         } else if (this.checkValidInputCase === 3) {
  //           let splitwithHyphen = inputValue?.split('-');
  //           for (
  //             var i = Number(splitwithHyphen[0]);
  //             i <= Number(splitwithHyphen[1]);
  //             i++
  //           ) {
  //             split.push(Number(i));
  //           }
  //         } else if (this.checkValidInputCase === 4) {
  //           split.push(this.currentPage);
  //         } else {
  //           split = [];
  //         }

  //         let uniqueArray = [...new Set(split)];
  //         let totalPagesCount = await this.getPdfPageCount();
  //         // if(!uniqueArray.includes(0)){
  //         if (uniqueArray.every((num) => num > 0 && num <= totalPagesCount)) {
  //           for (const x of uniqueArray) {
  //             this.pageNumber = x;
  //             const val = await this.getPdfPage();
  //             const pageDetails: SplitPageDetails = {
  //               pageDetails: val,
  //               pageNumber: this.pageNumber,
  //             };
  //             // //Current Page with the Last Page condition of the pdf
  //             this.currentPage =
  //               this.pdfPages < Number(this.pageNumber) + 1
  //                 ? this.currentPage
  //                 : Number(this.pageNumber) + 1;
  //             // //Page Already Added to any Classification Validation
  //             if (
  //               !this.isPageNumberAlreadyExistsV1(this.pageNumber) &&
  //               !this.sortedPagesList.includes(this.pageNumber)
  //             ) {
  //               documentDetails = this.documentTypes.find(
  //                 (value) => value.shortCodeId === selectedId
  //               );
  //               documentDetails.documentPages.push(pageDetails);
  //               let SortDocumentDto: SaveDocumentDto = {
  //                 patientId: this.patientId,
  //                 sortType: SortType.Manual,
  //                 documentTypeId: documentDetails.docTypeId,
  //                 documentType: documentDetails.documentType,
  //                 pageNumber: pageDetails.pageNumber,
  //                 pageContent: pageDetails.pageDetails,
  //               };
  //               if (documentDetails != null) {
  //                 this.saveDocumentDetails.push(SortDocumentDto);
  //                 this.totalPages--;
  //                 this.sortedPages++;
  //                 this.toastr.success(
  //                   'Page ' +
  //                     this.pageNumber +
  //                     ' Added to ' +
  //                     documentDetails.documentType +
  //                     '!'
  //                 );
  //               } else {
  //                 this.toastr.error('An error occurred.');
  //               }
  //             } else {
  //               this.toastr.error(
  //                 'Page ' + this.pageNumber + ' Already Sorted!'
  //               );
  //             }
  //           }
  //           this.pageCount = 0;
  //           this.arrowButtonClicked = true;
  //         } else {
  //           this.toastr.error(
  //             'Please enter valid input - Zero not allowed, or less than zero not allowed, or maximum allowed ' +
  //               totalPagesCount
  //           );
  //         }
  //       } else {
  //         this.toastr.error('Please enter vaild input');
  //       }
  //     }
  //   } catch (error) {
  //     console.error('Error:', error);
  //   } finally {
  //     this.isDisabled = false;
  //     this.myInputRefs.forEach((inputRef) => {
  //       inputRef.nativeElement.value = '';
  //     });
  //     this.cd.detectChanges();
  //   }
  // }

  // //Page Number Already Exists In the Document List Validation Method
  // isPageNumberAlreadyExistsV1(pageNumber: number): boolean {
  //   return this.saveDocumentDetails.some(
  //     (document) => document.pageNumber === pageNumber
  //   );
  // }

  // //Page Number Already Exists In the Document List Validation Method
  // isPageNumberAlreadyExists(pageNumber: number): boolean {
  //   return this.documentTypes.some((document) => {
  //     return document.documentPages.some(
  //       (page) => page.pageNumber === pageNumber
  //     );
  //   });
  // }

  //Split Pdf
  // public async getPdfPage() {
  //   const dataUri = this.strSelectedPdfPath;
  //   const mainDocument = await PDFDocument.load(dataUri);
  //   const splitDocument = await PDFDocument.create();
  //   const [existingPage] = await splitDocument.copyPages(mainDocument, [
  //     this.pageNumber - 1,
  //   ]);
  //   splitDocument.addPage(existingPage);
  //   // const base64StringMainDoc = await mainDocument.saveAsBase64();

  //   const base64StringSplitDoc = await splitDocument.saveAsBase64();

  //   return base64StringSplitDoc;
  //   //For Base64Uri
  //   //const base64DataUri = await pdfDoc.saveAsBase64({ dataUri: true })
  // }

  // public async getPdfPageCount() {
  //   try {
  //     const dataUri = this.strSelectedPdfPath; // Assuming strSelectedPdfPath is the PDF data URI
  //     const mainDocument = await PDFDocument.load(dataUri);
  //     const splitDocument = await PDFDocument.create();

  //     // Copy all pages from the main document to the split document
  //     const pages = await splitDocument.copyPages(
  //       mainDocument,
  //       mainDocument.getPageIndices()
  //     );
  //     pages.forEach((page) => splitDocument.addPage(page));

  //     const totalPages = splitDocument.getPageCount();
  //     return totalPages;
  //   } catch (error) {
  //     console.error('Error getting total pages:', error);
  //     return 0; // Return 0 or handle the error appropriately
  //   }
  // }

  // checkValidInput(input: string): boolean {
  //   // Your validation logic goes here
  //   // Return true if the input is valid, otherwise return false

  //   // For example, let's check if the input is a number

  //   if (input === '0') {
  //     this.checkValidInputCase = 0;
  //     // If none of the cases match, return false
  //     return false;
  //   } else if (/^[1-9]\d*$/.test(input) && Number(input)) {
  //     // if (/^\d+$/.test(input) && Number(input)) {
  //     // Case 1: Single number
  //     this.checkValidInputCase = 1;

  //     return true;
  //   } else if (/^\d+(,\d+)*$/.test(input)) {
  //     // else if (/^\d+([1-9]\d*)*(,\d+([1-9]\d*)*)*$/.test(input)) {
  //     // Case 2: Comma-separated list of numbers
  //     this.checkValidInputCase = 2;

  //     return true;
  //   } else if (/^\d+-\d+$/.test(input)) {
  //     // else if (/^\d+([1-9]\d*)*-(\d+([1-9]\d*)*)*$/.test(input)) {
  //     // Case 3: Range of numbers without 0 (e.g., 1-5)
  //     this.checkValidInputCase = 3;

  //     const [start, end] = input.split('-').map(Number);
  //     return !isNaN(start) && !isNaN(end) && start <= end;
  //   } else if (input === '') {

  //     this.checkValidInputCase = 4;
  //     return true;
  //   }

  //   this.checkValidInputCase = 0;
  //   // If none of the cases match, return false
  //   return false;

  //   // return !isNaN(Number(input));
  // }

  // loadDefaultDropDowns() {
  //   this.isHidden = true;
  //   this.isLoading = true;
  //   const defaultDocumentList = this.inboundDocumentService
  //     .getPatientDocumentDetailsByGPatient(this.patientId)
  //     .subscribe(
  //       (response) => {
  //         if (
  //           response.defaultDocumentId &&
  //           response.defaultDocumentId.length > 0
  //         ) {
  //           this.drpdefaultDocument = response.defaultDocumentId;
  //           this.documentId = response.defaultDocumentId[0].documentId || '';
  //           this.blobName = this.drpdefaultDocument[0].blobName || '';
  //           this.defaultsortedDocument = response.sortedDocument;
  //           // this.strSelectedPdfPath = String('data:application/pdf;base64,' +  this.defaultsortedDocument[0]?.fileContent);
  //           this.filteredDefaultDocuments = [...this.drpdefaultDocument];
  //           this.drpdefaultDocumentFilterControl.valueChanges.subscribe(
  //             (filterTerm) => {
  //               this.filteredDefaultDocuments =
  //                 this.filterDefaultDocuments(filterTerm);
  //             }
  //           );
  //           this.firstValue = this.drpdefaultDocument[0];
  //           this.fgGetSortingView.patchValue({
  //             drpdefaultDocument: this.firstValue,
  //           });

  //           // this.loadInboundDocument();
  //           // this.sortingDocView();
  //           this.isLoading = false;
  //           this.isHidden = false;
  //           this.defaultDisabled = false;
  //         } else {
  //           this.isLoading = false;
  //           this.isHidden = false;
  //           this.defaultDisabled = true;
  //         }
  //       },
  //       (err) => {
  //         const data: HttpErrorResponse = err;
  //         Swal.fire({
  //           icon: 'info',
  //           text: data?.error?.error?.message,
  //         });
  //         this.isLoading = false;
  //         this.isHidden = false;
  //       }
  //     );
  //   this.subscription$.push(defaultDocumentList);
  // }

  loadDefaultDropDowns() {
    this.isHidden = true;
    this.isLoading = true;
    const defaultDocumentList = this.patientDocumentService
      .getpatientdocumentDetailsByGpatientId(this.patientId)
      .subscribe(
        (response) => {
          if (
            response &&
            response.length > 0
          ) {
            this.drpdefaultDocument = response;
            this.documentId = response[0].docId || '';
            this.documentIdv1 = response[0].docId || '';
            this.blobName = this.drpdefaultDocument[0].blobName || '';
            this.documentName = this.drpdefaultDocument[0].documentName || '';
            // this.defaultsortedDocument = response.isSorted;
            // this.strSelectedPdfPath = String('data:application/pdf;base64,' +  this.defaultsortedDocument[0]?.fileContent);
            this.filteredDefaultDocuments = [...this.drpdefaultDocument];
            this.signatureId = response[0].signatureId;
            this.drpdefaultDocumentFilterControl.valueChanges.subscribe(
              (filterTerm) => {
                this.filteredDefaultDocuments = this.filterDefaultDocuments(filterTerm);
                  console.log(this.filteredDefaultDocuments);

              }
            );
            this.firstValue = this.drpdefaultDocument[0];
            this.fgGetSortingView.patchValue({
              drpdefaultDocument: this.firstValue,
            });
            this.isInbound = this.drpdefaultDocument[0].isInbound || false;
            this.defaultFaxShortCodeId = this.drpdefaultDocument[0].shortCodeId;
            // this.loadInboundDocument();
            // this.sortingDocView();
            this.isLoading = false;
            this.isHidden = false;
            this.defaultDisabled = false;
          } else {
            this.isLoading = false;
            this.isHidden = false;
            this.defaultDisabled = true;
          }
        },
        (err) => {
          const data: HttpErrorResponse = err;
          // Swal.fire({
          //   icon: 'info',
          //   text: data?.error?.error?.message,
          // });
          this.isLoading = false;
          this.isHidden = false;
        }
      );
    this.subscription$.push(defaultDocumentList);
  }


  prevent_unsaved_sortingFlagEmit() {
    this.isSortingStart = true;
  }
  prevent_unsaved_annotationsV0(annotation: any) {


    if(annotation){
      this.isAnnotation = annotation['annotation'] > 0 ? true : false;

    }

  }

  prevent_unsaved_annotations(annotation: any) {
    if (annotation && annotation['annotation'] !== undefined && annotation['annotation'] !== null) {
      this.isAnnotation = annotation['annotation'] > 0;

    } else {
      this.isAnnotation = false;
    }
  }


  onSelectChange(event: MatSelectChange) {
    // this.saveDocumentDetails = [];
    if (this.isAnnotation) {
      const confirmation = confirm(
        'Are you sure you want to change without save annotation text?'
      );
      if (confirmation) {
        this.isAnnotationClearAllowed = true;
        if(this.isSortingStart === false) {
        this.prevent_unsaved(event.value);
        this.isAnnotation = false;
        }
      } else {
        this.isAnnotationClearAllowed = false;
        if (this.previousValue !== undefined) {
          this.fgGetSortingView
            .get('drpdefaultDocument')
            .patchValue(this.previousValue);
        } else {
          this.fgGetSortingView
            .get('drpdefaultDocument')
            .patchValue(this.firstValue);
        }
        return;
      }
    }


    if (this.isSortingStart) {
      const confirmSelection = window.confirm(
        'Are you sure you want to change the selection? Sorting value is unsaved.'
      );
      if (confirmSelection) {
        this.prevent_unsaved(event.value);
        this.isSortingStart = false;
        this.isAnnotation = false;
      } else {
        if (this.previousValue !== undefined) {
          this.fgGetSortingView
            .get('drpdefaultDocument')
            .patchValue(this.previousValue);
        } else {
          this.fgGetSortingView
            .get('drpdefaultDocument')
            .patchValue(this.firstValue);
        }
      }
    } else {
      this.prevent_unsaved(event.value);
    }
  }

  private prevent_unsaved(event: any) {
    this.isLoading = true;
    this.currentPage = 1;
    // this.documentId = event.documentId;
    // this.defaultDocument = event.defaultDocument;
    this.signatureId = event.signatureId;
     this.documentId = event.docId;
    this.defaultDocument = event.defaultDocument;
    this.blobName = event.blobName;
    this.documentName = event.documentName;
    this.isInbound = event.isInbound;
    // this.defaultFaxId = event.defaultFaxId;
    this.defaultFaxShortCodeId = event.shortCodeId;
    this.defaultFaxId = event.faxId;
    // this.sortingDocView();
    // this.loadInboundDocument();
    this.previousValue = event;

    setTimeout(() => {
      this.isLoading = false;
    }, 100);
  }

  // sortingDocView() {
  //   // this.isLoadSortingView = true;
  //   // this.isDisabled = true;
  //   // const sortingDocViewList = this.inboundDocumentService
  //   //   .getSortedDocumentPagesByGDocumentId(this.documentId)
  //   //   .subscribe(
  //   //     (response) => {
  //   //       this.isMedicareList = response.ltDocuments.filter(
  //   //         (value) => value.isMedicareBin
  //   //       );
  //   //       this.shortCodeList = this.isMedicareList.map(
  //   //         (item) => item.shortCode
  //   //       );
  //   //       this.documentTypes = response.ltDocuments;
  //   //       this.documentTypes = response.ltDocuments.map((value) => ({
  //   //         docTypeId: value.documentTypeId,
  //   //         shortCodeId: value.shortCode,
  //   //         isMedicareBin: value?.isMedicareBin,
  //   //         documentType: value?.documentType.toUpperCase(),
  //   //         documentPages: value?.docPageContent?.ltPageNumbers ?? [],
  //   //         fileCount: value?.pageCount ?? 0,
  //   //         // fileContent: value?.docPageContent?.fileContent ?? '',
  //   //         fileContent: '',
  //   //       }));
  //   //       this.newSetupForm.patchValue({
  //   //         // FACE TO FACE
  //   //         txtIobFadeToFace:
  //   //           response?.checklistDetails?.initialFaceToFaces[0]?.faceToFaceDate,
  //   //         txtDateofVisit:
  //   //           response?.checklistDetails?.initialFaceToFaces[0]?.dateOfVisit,
  //   //         txtNpiNumber:
  //   //           response?.checklistDetails?.initialFaceToFaces[0]?.npi,
  //   //         txtSignedDate:
  //   //           response?.checklistDetails?.initialFaceToFaces[0]?.signedDate,
  //   //         chkPrevioushistory:
  //   //           response?.checklistDetails?.initialFaceToFaces[0]
  //   //             ?.previousHistoryDeclaration == 1
  //   //             ? true
  //   //             : false,
  //   //         chkSleepTestRecommended:
  //   //           response?.checklistDetails?.initialFaceToFaces[0]
  //   //             ?.isSleepTestRecommended == 1
  //   //             ? true
  //   //             : false,
  //   //         chkSleepDoctorSigned:
  //   //           response?.checklistDetails?.initialFaceToFaces[0]
  //   //             ?.isDoctorSigned == 1
  //   //             ? true
  //   //             : false,
  //   //         chkSleepPatientBenifited:
  //   //           response?.checklistDetails?.initialFaceToFaces[0]
  //   //             ?.isPatientBenifited == 1
  //   //             ? true
  //   //             : false,
  //   //         txtDoctor:
  //   //           response?.checklistDetails?.initialFaceToFaces[0]?.doctorName,
  //   //         drpSymptoms:
  //   //           response?.checklistDetails?.initialFaceToFaces[0]?.symptoms,

  //   //         // SLEEP STUDY REPORT
  //   //         txtStudyDate:
  //   //           response?.checklistDetails?.sleepStudies[0]?.sleepStudyDate,
  //   //         txtPressureSetting:
  //   //           response?.checklistDetails?.sleepStudies[0]?.pressureSetting,
  //   //         txtREI: response?.checklistDetails?.sleepStudies[0]?.rei,
  //   //         txtRDI: response?.checklistDetails?.sleepStudies[0]?.rdi,
  //   //         txtSleepMinutes:
  //   //           response?.checklistDetails?.sleepStudies[0]?.diagnosticSleepStudy
  //   //             ?.totalSleepMins,
  //   //         txtAHI:
  //   //           response?.checklistDetails?.sleepStudies[0]?.diagnosticSleepStudy
  //   //             ?.ahi,
  //   //         chkSleepStudyDoctorSigned:
  //   //           response?.checklistDetails?.sleepStudies[0]?.diagnosticSleepStudy
  //   //             ?.isDoctorSigned == 1
  //   //             ? true
  //   //             : false,
  //   //         txtSSCentralApneas:
  //   //           response?.checklistDetails?.sleepStudies[0]?.diagnosticSleepStudy
  //   //             ?.centralApneas,
  //   //         txtSSCentralHyponeas:
  //   //           response?.checklistDetails?.sleepStudies[0]?.diagnosticSleepStudy
  //   //             ?.centralHyponeas,
  //   //         txtSSApneaAndHypopnea:
  //   //           response?.checklistDetails?.sleepStudies[0]?.diagnosticSleepStudy
  //   //             ?.totalApneaAndHypopnea,
  //   //         txtDiagnosisStudyDate:
  //   //           response?.checklistDetails?.sleepStudies[0]?.diagnosticSleepStudy
  //   //             ?.diagnosticSleepStudyDate,
  //   //         txtTitrationSleepStudyDate:
  //   //           response?.checklistDetails?.sleepStudies[0]?.titrationSleepStudy
  //   //             .titrationSleepStudyDate,
  //   //         txtTAHI:
  //   //           response?.checklistDetails?.sleepStudies[0]?.titrationSleepStudy
  //   //             ?.ahi,
  //   //         txtTCentralApneas:
  //   //           response?.checklistDetails?.sleepStudies[0]?.titrationSleepStudy
  //   //             ?.centralApneas,
  //   //         txtTCentralHyponeas:
  //   //           response?.checklistDetails?.sleepStudies[0]?.titrationSleepStudy
  //   //             ?.centralHyponeas,
  //   //         txtTApneaAndHypopnea:
  //   //           response?.checklistDetails?.sleepStudies[0]?.titrationSleepStudy
  //   //             ?.totalApneaAndHypopnea,
  //   //         txtObstructiveApnea:
  //   //           response?.checklistDetails?.sleepStudies[0]?.titrationSleepStudy
  //   //             ?.obstructiveApnea,
  //   //         chkTSSBaselineFile:
  //   //           response?.checklistDetails?.sleepStudies[0]?.titrationSleepStudy
  //   //             ?.isBaselineFileAvailable == 1
  //   //             ? true
  //   //             : false,
  //   //         chkRespiratoryBreakdownAvailable:
  //   //           response?.checklistDetails?.sleepStudies[0]?.titrationSleepStudy
  //   //             ?.isRespiratoryBreakdownAvailable == 1
  //   //             ? true
  //   //             : false,
  //   //         chkCPAPTriedAndFail:
  //   //           response?.checklistDetails?.sleepStudies[0]?.titrationSleepStudy
  //   //             ?.isCPAPTriedAndFail == 1
  //   //             ? true
  //   //             : false,
  //   //         chkTitrationSleepStudyDoctorSigned:
  //   //           response?.checklistDetails?.sleepStudies[0]?.titrationSleepStudy
  //   //             ?.isDoctorSigned == 1
  //   //             ? true
  //   //             : false,
  //   //         chkisCPAPReturned:
  //   //           response?.checklistDetails?.sleepStudies[0]?.isCPAPReturned == 1
  //   //             ? true
  //   //             : false,
  //   //         txtdiagnosisCode10Id:
  //   //           response?.checklistDetails?.sleepStudies[0]?.diagnosisCode,

  //   //         //COMPLIANCE REPORT
  //   //         txtComplianceStudyStartDate:
  //   //           response?.checklistDetails?.complainces[0]?.studyStartDate,
  //   //         txtCompliancePercentage:
  //   //           response?.checklistDetails?.complainces[0]?.complaincePercentage,
  //   //         txtComplianceStudyEndDate:
  //   //           response?.checklistDetails?.complainces[0]?.studyEndDate,
  //   //         // rbnComplianceMet:
  //   //         //   response?.checklistDetails?.complainces[0]?.isComplainceMet.toString(),
  //   //         // rbnComplianceMet:response?.checklistDetails?.complainces[0]?.isComplainceMet=== null || response?.checklistDetails?.complainces[0]?.isComplainceMet == undefined || response?.checklistDetails?.complainces[0]?.isComplainceMet == ''  ? '0': response?.checklistDetails?.complainces[0]?.isComplainceMet,
  //   //         rbnComplianceMet: response?.checklistDetails?.complainces[0]?.isComplainceMet.toString() === '1' ? response?.checklistDetails?.complainces[0]?.isComplainceMet.toString() : '0',
  //   //         txtUsagedays: response?.checklistDetails?.complainces[0]?.usageDays,
  //   //         txtUsagedaysOutOf:
  //   //           response?.checklistDetails?.complainces[0]?.outOfDays,
  //   //         txtGreater4Hours:
  //   //           response?.checklistDetails?.complainces[0]?.greaterThanFourHrs,
  //   //         txtLess4hours:
  //   //           response?.checklistDetails?.complainces[0]?.lessThanFourHrs,

  //   //         // RX
  //   //         txtPrescriptionDate:
  //   //           response?.checklistDetails?.prescriptions[0]?.prescriptionDate,
  //   //         txtMachineDetailsFilter:
  //   //           response?.checklistDetails?.prescriptions[0]?.machineDetails,
  //   //         txtPressureLevel:
  //   //           response?.checklistDetails?.prescriptions[0]?.pressureLevel,
  //   //         txtRXDoctor:
  //   //           response?.checklistDetails?.prescriptions[0]?.doctorName,
  //   //         txtLengthOfNeed:
  //   //           response?.checklistDetails?.prescriptions[0]?.lengthOfNeed,
  //   //         txtRXNpiNumber: response?.checklistDetails?.prescriptions[0]?.npi,
  //   //         chkRXDoctorSigned:
  //   //           response?.checklistDetails?.prescriptions[0]?.isDoctorSigned == 1
  //   //             ? true
  //   //             : false,
  //   //         txtRXDoctorSignedDate:
  //   //           response?.checklistDetails?.prescriptions[0]?.doctorSignedDate,
  //   //         txtRXdiagnosisCode10Id1:
  //   //           response?.checklistDetails?.prescriptions[0]?.diagnosticCode,
  //   //       });
  //   //       this.appendPreviousSavedData();

  //   //       this.isLoadSortingView = false;
  //   //       this.isDisabled = false;
  //   //     },
  //   //     (err) => {
  //   //       // this.strSelectedPdfPath = '';
  //   //       this.isLoadSortingView = false;
  //   //       this.isDisabled = false;
  //   //       console.warn('Error', err);
  //   //       // Check if the error has the expected structure
  //   //       if (err.error && err.error.message) {
  //   //         // Parse the JSON error message and extract the specific message
  //   //         try {
  //   //           const errorMessageObject = JSON.parse(err.error.message);
  //   //           const specificErrorMessage = errorMessageObject.error.message;

  //   //           // Print the specific error message
  //   //           console.warn('Specific Error Message:', specificErrorMessage);

  //   //           // Show a warning toaster with the specific error message
  //   //           this.toastr.warning('Reason: ' + specificErrorMessage, 'Warning');
  //   //         } catch (parseError) {
  //   //           // Log a warning if there is an issue parsing the error message
  //   //           console.warn('Error parsing error message:', parseError);
  //   //           this.toastr.warning('Reason: ' + parseError, 'Warning');
  //   //         }
  //   //       } else {
  //   //         // If the error structure is unexpected, log the entire error message
  //   //         console.warn('Unexpected error structure:', err);
  //   //         this.toastr.error('Unexpected error structure: ' + err, 'Error');
  //   //       }
  //   //     }
  //   //   );
  //   // this.subscription$.push(sortingDocViewList);
  // }

  // getDropdown(): void {
  //   this.drpDiagnosisICD10 = [];
  //   const diagonsis10ItemList = (query: any) => this.diagnosis10.getList(query);
  //   this.list.hookToQuery(diagonsis10ItemList).subscribe(
  //     (response) => {
  //       response?.items?.forEach((element: DiagnosisCode10DTO) => {
  //         let resultString =
  //           element?.diagnosisCode + ' ' + element?.diagnosisCodeTypeData;
  //         this.drpDiagnosisICD10?.push({
  //           diagnosisCodeTypeDataId: element?.diagnosisCodeTypeDataId ?? '',
  //           diagnosisCode10ShortCodeId:
  //             element?.diagnosisCode10ShortCodeId ?? '',
  //           diagnosisCode: element?.diagnosisCode ?? '',
  //           diagnosisCodeTypeData: resultString,
  //           id: element?.id ?? '',
  //           creationTime: element?.creationTime ?? '',
  //           creatorId: element?.creatorId ?? '',
  //           isActive: element?.isActive,
  //           isExist: element?.isExist,
  //           isBillable: element?.isBillable,
  //         });
  //       });
  //       this.filteredDiagnosiss = this.newSetupForm
  //         ?.get('txtDiagnosisCode10Filter')
  //         ?.valueChanges.pipe(
  //           startWith(''),
  //           map((value) =>
  //             this.drpDiagnosisICD10?.filter((option) =>
  //               option?.diagnosisCodeTypeData
  //                 ?.toLowerCase()
  //                 ?.includes(value?.toLowerCase() ?? '')
  //             )
  //           )
  //         );
  //       this.filteredRxDiagnosiss = this.newSetupForm
  //         ?.get('txtRxDiagnosisCode10Filter')
  //         ?.valueChanges.pipe(
  //           startWith(''),
  //           map((value) =>
  //             this.drpDiagnosisICD10?.filter((option) =>
  //               option?.diagnosisCodeTypeData
  //                 ?.toLowerCase()
  //                 ?.includes(value?.toLowerCase() ?? '')
  //             )
  //           )
  //         );
  //     },
  //     (err) => {
  //       const data: HttpErrorResponse = err;
  //       Swal.fire({
  //         icon: 'info',
  //         text: data?.error?.error?.message,
  //       });
  //     }
  //   );
  //   let patientDropdownInput: PatientDropdowns[];
  //   patientDropdownInput = [
  //     PatientDropdowns.Symptoms,
  //     PatientDropdowns.MachineTypes,
  //   ];
  //   const patientDropdown = this.patientDropdownService
  //     .getPatientDropdownsByInput(patientDropdownInput)
  //     .subscribe(
  //       (response) => {
  //         if (
  //           response &&
  //           response.lstSymptoms &&
  //           response?.lstMachineTypes
  //         ) {
  //           this.drpSymptoms = response?.lstSymptoms ?? [];
  //           this.filteredSymptoms = this.newSetupForm
  //             ?.get('txtSymptomsFilter')
  //             ?.valueChanges.pipe(
  //               startWith(''),
  //               map((value) =>
  //                 this.drpSymptoms?.filter((option) =>
  //                   option?.symptom
  //                     ?.toLowerCase()
  //                     ?.includes(value?.toLowerCase() ?? '')
  //                 )
  //               )
  //             );
  //           this.drpMachines = response?.lstMachineTypes;
  //           this.filteredMachines = this.newSetupForm
  //             ?.get('txtMachineDetailsFilter')
  //             ?.valueChanges.pipe(
  //               startWith(''),
  //               map((value) =>
  //                 this.drpMachines?.filter((option) =>
  //                   option?.machineType
  //                     ?.toLowerCase()
  //                     ?.includes(value?.toLowerCase() ?? '')
  //                 )
  //               )
  //             );
  //         }
  //       },
  //       (err) => {
  //         const data: HttpErrorResponse = err;
  //         // Swal.fire({
  //         //   icon: 'info',
  //         //   text: data?.error?.error?.message,
  //         // });

  //       },
  //       () => {}
  //     );
  // }

  // private saveDocument() {
  //   try {
  //     this.isDisabled = true;
  //   const formValue = this.newSetupForm.value;
  //   let initialFaceToFacesDetails: InitialFaceToFaces = {
  //     // initialFaceToFaceId:"",
  //     patientName: '',
  //     dateOfBirth: null,
  //     faceToFaceDate: formValue?.txtIobFadeToFace,
  //     doctorName: formValue?.txtDoctor,
  //     npi: formValue?.txtNpiNumber,
  //     doctorSignature: '',
  //     symptoms:
  //       formValue?.drpSymptoms !== null &&
  //       formValue?.drpSymptoms !== undefined &&
  //       formValue?.drpSymptoms !== ''
  //         ? formValue?.drpSymptoms
  //         : [],
  //     apneaTreatment: '',
  //     dateOfVisit: formValue?.txtDateofVisit,
  //     patientInfo: '',
  //     signedDate: formValue?.txtSignedDate,
  //     previousHistoryDeclaration:
  //       formValue?.chkPrevioushistory === true ? 1 : 0,
  //     isSleepTestRecommended:
  //       formValue?.chkSleepTestRecommended === true ? 1 : 0,
  //     isDoctorSigned: formValue?.chkSleepDoctorSigned === true ? 1 : 0,
  //     isPatientBenifited: formValue?.chkSleepPatientBenifited === true ? 1 : 0,
  //     patientBenefitNotes: '',
  //   };

  //   let SleepStudiesDetails: SleepStudies = {
  //     patientName: null,
  //     dateOfBirth: null,
  //     sleepStudyDate: formValue?.txtStudyDate,
  //     rdi: formValue?.txtRDI === null ? 0 : formValue?.txtRDI,
  //     rei: formValue?.txtREI === null ? 0 : formValue?.txtREI,
  //     diagnosisCode:
  //       formValue?.txtdiagnosisCode10Id == null ||
  //       formValue?.txtdiagnosisCode10Id == ''
  //         ? []
  //         : formValue?.txtdiagnosisCode10Id,
  //     pressureSetting: formValue?.txtPressureSetting,
  //     diagnosticSleepStudy: {
  //       diagnosticSleepStudyDate: formValue?.txtDiagnosisStudyDate,
  //       totalSleepMins: formValue?.txtSleepMinutes,
  //       ahi: formValue?.txtAHI == null ? 0 : formValue?.txtAHI,
  //       isDoctorSigned: formValue?.chkSleepStudyDoctorSigned === true ? 1 : 0,
  //       centralApneas:
  //         formValue?.txtSSCentralApneas == null
  //           ? 0
  //           : formValue?.txtSSCentralApneas,
  //       centralHyponeas:
  //         formValue?.txtSSCentralHyponeas == null
  //           ? 0
  //           : formValue?.txtSSCentralHyponeas,
  //       totalApneaAndHypopnea:
  //         formValue?.txtSSApneaAndHypopnea == null
  //           ? 0
  //           : formValue?.txtSSApneaAndHypopnea,
  //     },
  //     titrationSleepStudy: {
  //       titrationSleepStudyDate: formValue?.txtTitrationSleepStudyDate,
  //       isCPAPTriedAndFail: formValue?.chkCPAPTriedAndFail === true ? 1 : 0,
  //       isRespiratoryBreakdownAvailable:
  //         formValue?.chkRespiratoryBreakdownAvailable === true ? 1 : 0,
  //       isDoctorSigned:
  //         formValue?.chkTitrationSleepStudyDoctorSigned === true ? 1 : 0,
  //       isBaselineFileAvailable: formValue?.chkTSSBaselineFile === true ? 1 : 0,
  //       ahi: formValue?.txtTAHI == null ? 0 : formValue?.txtTAHI,
  //       centralApneas:
  //         formValue?.txtTCentralApneas == null
  //           ? 0
  //           : formValue?.txtTCentralApneas,
  //       centralHyponeas:
  //         formValue?.txtTCentralHyponeas == null
  //           ? 0
  //           : formValue?.txtTCentralHyponeas,
  //       totalApneaAndHypopnea:
  //         formValue?.txtTApneaAndHypopnea == null
  //           ? 0
  //           : formValue?.txtTApneaAndHypopnea,
  //       obstructiveApnea:
  //         formValue?.txtObstructiveApnea == null
  //           ? 0
  //           : formValue?.txtObstructiveApnea,
  //     },
  //     isCPAPReturned: formValue?.chkisCPAPReturned === true ? 1 : 0,
  //   };
  //   let PrescriptionsDetails: Prescriptions = {
  //     patientName: null,
  //     dateOfBirth: null,
  //     prescriptionDate: formValue?.txtPrescriptionDate,
  //     doctorName: formValue?.txtRXDoctor,
  //     doctorSignature: null,
  //     machineDetails: formValue?.drpMachineDetails,
  //     pressureLevel: formValue?.txtPressureLevel,
  //     lengthOfNeed: formValue?.txtLengthOfNeed,
  //     diagnosticCode:
  //       formValue?.txtRXdiagnosisCode10Id1 == null ||
  //       formValue?.txtRXdiagnosisCode10Id1 == ''
  //         ? []
  //         : formValue?.txtRXdiagnosisCode10Id1,
  //     suppliesDetails: null,
  //     npi: formValue?.txtRXNpiNumber,
  //     isDoctorSigned: formValue?.chkRXDoctorSigned === true ? 1 : 0,
  //     doctorSignedDate: formValue?.txtRXDoctorSignedDate,
  //     itemId: null,
  //     duration: null,
  //     estimatedLength: null,
  //     supplierName: null,
  //   };
  //   let ComplaincesDetails: Complainces = {
  //     patientName: null,
  //     dateOfBirth: null,
  //     studyStartDate: formValue?.txtComplianceStudyStartDate,
  //     studyEndDate: formValue?.txtComplianceStudyEndDate,
  //     // isComplainceMet: formValue?.rbnComplianceMet.toString() === '1' ? formValue?.rbnComplianceMet :'0',
  //     isComplainceMet: formValue?.rbnComplianceMet != null ? formValue.rbnComplianceMet.toString() : '0',
  //     complaincePercentage: formValue?.txtCompliancePercentage,
  //     usageDays: formValue?.txtUsagedays,
  //     outOfDays: formValue?.txtUsagedaysOutOf,
  //     greaterThanFourHrs: formValue?.txtGreater4Hours,
  //     lessThanFourHrs: formValue?.txtLess4hours,
  //   };

  //   let saveDocumentDetails=this.saveDocumentDetails;
  //   this.saveDocumentDetails = [];
  //   let fileUploadsInput: sortedDocumentDetailsDTO = {
  //     patientId: this.patientId,
  //     documentId: this.documentId,
  //     lstSorting: saveDocumentDetails,
  //     initialFaceToFaces: initialFaceToFacesDetails,
  //     sleepStudies: SleepStudiesDetails,
  //     prescriptions: PrescriptionsDetails,
  //     complainces: ComplaincesDetails,
  //   };
  //   const docDetails = this.documentsService
  //     .saveSortedDocumentDetails(fileUploadsInput)
  //     .subscribe(
  //       (response) => {
  //         this.toastr.success('Document Sorted Successfully');
  //         this.saveDocumentDetails = [];
  //         // this.router.navigate(['dashboard']);
  //         this.sortingDocView();
  //       },
  //       (err) => {
  //         const data: HttpErrorResponse = err;
  //         this.saveDocumentDetails = [];
  //         // this.router.navigate(['dashboard']);
  //         this.sortingDocView();
  //         Swal.fire({
  //           icon: 'info',
  //           text: data?.error?.error?.message,
  //         });
  //       }
  //     );
  //   } catch (error) {
  //     console.error('Error:', error);
  //   } finally {
  //   this.saveDocumentDetails = [];
  //   // this.isDisabled = false;
  //   this.isSaveDisabled = false;
  //   this.isSortingStart = false;// Enable the button when the operation completes (or encounters an error)
  //   }
  // }

  // saveSortedDetails() {
  //   try {
  //     if (!this.isDisabled) {
  //       this.isDisabled = true;
  //       this.saveSubject.next();
  //     }
  //   } catch (error) {
  //     console.error('Error:', error);
  //   } finally {
  //     // Optionally, you can reset isDisabled after a certain period
  //     setTimeout(() => {
  //       this.isDisabled = false;
  //     }, 5000); // 5000 milliseconds (adjust as needed)
  //   }
  // }

  // openIcd10Modal() {
  //   const dialogRef = this.matDialog.open(PatientIcd10Component, {
  //     disableClose: true,
  //     data: {},
  //   });
  //   dialogRef.afterClosed().subscribe(
  //     () => {
  //       this.getDropdown();
  //     },
  //     (err) => {
  //       const data: HttpErrorResponse = err;
  //       Swal.fire({
  //         icon: 'info',
  //         text: data?.error?.error?.message,
  //       });
  //     }
  //   );
  // }

  //Load Pdf Document
  loadInboundDocument() {
    // this.isLoading = true;
    // const loadInboundDocument = this.inboundDocumentService
    //   .downloadInboundDocumentByBlobName(this.blobName)
    //   .subscribe(
    //     (response) => {
    //       if (
    //         typeof response === 'string' &&
    //         response &&
    //         response !== null &&
    //         response?.length !== 0
    //       ) {
    //         this.strSelectedPdfPath = String(
    //           'data:application/pdf;base64,' + response
    //         );
    //         this.isLoading = false;
    //       }
    //     },
    //     (err) => {
    //       this.strSelectedPdfPath = '';
    //       this.isLoading = false;
    //       const data: HttpErrorResponse = err;
    //       Swal.fire({
    //         icon: 'info',
    //         text: data?.error?.error?.message,
    //       });
    //     }
    //   );
    // this.subscription$.push(loadInboundDocument);
  }

  //To add inbound documents manually
  addDocumentType() {
    const dialogData = {
      constructorpatientId: this.patientId, // Replace with your actual patientId value
      constructortenantId: this.localStoragetenantId, // Replace with your actual tendid value
      selectedWipId: this.wipSelectedValue,
      isPatientList: false,
      isPatientDisable:true,
    };
    const dialogRef = this.dialog.open(
      PatientCommonDocumentMedicareUploadTabComponent,
      {
        minHeight: '33vh',
        minWidth: '75vw',
        data: dialogData,
        disableClose: true,
      }
    );

    dialogRef.afterClosed().subscribe(
      (result) => {
        if (result && result.changesMade) {
          this.loadDefaultDropDowns();
        }
      },
      (err) => {
        const data: HttpErrorResponse = err;
        // Swal.fire({
        //   icon: 'info',
        //   text: data?.error?.error?.message,
        // });
      }
    );
  }

  getWIPstatusId() {
    const WIPList = this.patientDocumentService
      .getPatientWIPId(this.patientId)
      .subscribe(
        (response) => {

          // Check if response['items'] exists and has at least one item
          if (response['items'] && response['items'].length > 0) {
            this.wipSelectedValue = response['items'][0]?.id || '';
          } else {
            this.wipSelectedValue = '';
          }

          // this.wipSelectedValue = response['items'][0]?.id || ''; Old Code Modify By Manikandan
        },
        (err) => {
          const data: HttpErrorResponse = err;
          // Swal.fire({
          //   icon: 'info',
          //   text: data?.error?.error?.message,
          // });
        }
      );
  }

  //#region Delete Manula Upload Document Function
  deleteManualUploadDocument(){
    Swal.fire({
      title: 'Are you sure you want to Delete Document?',
      text: 'You won\'t be able to retrieve this data!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#34c38f',
      cancelButtonColor: '#f46a6a',
      confirmButtonText: 'Yes, delete it!'
    }).then(result => {
      if (result.value) {
        this.patientDocumentService.deleteManualUploadDocumentByGDocid(this.documentId).subscribe(response => {
          this.loadDefaultDropDowns();
        }, err => {
          const data: HttpErrorResponse = err;
          Swal.fire({
            icon: 'info',
            text: data?.error?.error?.message,
          });
        });
      }
    });
  }
  //#endregion
  isEmpty(value: any): boolean {
    return (
      value === null ||
      value === undefined ||
      value === '' ||
      value === defaultGuid
    );
  }
  BindHearderText(event: any) {
    this.patientName = event?.fullName || '-';
    this.chartId = event?.chartId || '-';
  }
// #region Patient Document download
patientDocPdfDownloadV0() {
  this.isShowSpinner=true;
  const handleResponse = (response: any) => {
      if (typeof response === 'string' && response) {
          this.strPdfString = String(response);
          // Decode base64 string to binary data
          const byteCharacters = atob(this.strPdfString);
          const byteNumbers = new Array(byteCharacters.length);
          for (let i = 0; i < byteCharacters.length; i++) {
              byteNumbers[i] = byteCharacters.charCodeAt(i);
          }
          const byteArray = new Uint8Array(byteNumbers);

          const blob = new Blob([byteArray], { type: "application/pdf" });
          const fileName = this.chartId + this.patientName;
          // Create a link element
          const link = document.createElement('a');
          link.href = window.URL.createObjectURL(blob);
          link.download = fileName;

          // Append the link to the body
          document.body.appendChild(link);

          // Programmatically click the link to trigger the download
          link.click();

          // Remove the link from the DOM
          document.body.removeChild(link);
      }
      this.isShowSpinner=false;
  };

  if (this.isInbound === false || this.isInbound === undefined) {
      this.patientDocumentService.getPatientDocumentByBlobIDByBlobName(this.blobName)
          .subscribe(handleResponse, (err: HttpErrorResponse) => {
            this.isShowSpinner=false;
              console.error('Error downloading the document', err);
          });
  } else {
      this.inboundDocumentService.downloadInboundDocumentByBlobName(this.blobName)
          .subscribe(handleResponse, (err: HttpErrorResponse) => {
            this.isShowSpinner=false;
              console.error('Error downloading the document', err);
          });
  }
}


patientDocPdfDownload() {
  this.isShowSpinner=true;
  const handleResponse = (response: any) => {
      if (typeof response === 'string' && response) {
          this.strPdfString = String(response);
          // Decode base64 string to binary data
          const byteCharacters = atob(this.strPdfString);
          const byteNumbers = new Array(byteCharacters.length);
          for (let i = 0; i < byteCharacters.length; i++) {
              byteNumbers[i] = byteCharacters.charCodeAt(i);
          }
          const byteArray = new Uint8Array(byteNumbers);

          const blob = new Blob([byteArray], { type: "application/pdf" });
          const fileName = this.chartId + this.patientName;
          // Create a link element
          const link = document.createElement('a');
          link.href = window.URL.createObjectURL(blob);
          link.download = fileName;

          // Append the link to the body
          document.body.appendChild(link);

          // Programmatically click the link to trigger the download
          link.click();

          // Remove the link from the DOM
          document.body.removeChild(link);
      }
      this.isShowSpinner=false;
  };

  // if (this.isInbound === false || this.isInbound === undefined) {
  //     this.patientDocumentService.getPatientDocumentByBlobIDByBlobName(this.blobName)
  //         .subscribe(handleResponse, (err: HttpErrorResponse) => {
  //           this.isShowSpinner=false;
  //             console.error('Error downloading the document', err);
  //         });
  // } else {
  //     this.inboundDocumentService.downloadInboundDocumentByBlobName(this.blobName)
  //         .subscribe(handleResponse, (err: HttpErrorResponse) => {
  //           this.isShowSpinner=false;
  //             console.error('Error downloading the document', err);
  //         });
  // }

        this.inboundDocumentService.getPatientDocumentByPathByDocumentId(this.documentId)
          .subscribe(handleResponse, (err: HttpErrorResponse) => {
            this.isShowSpinner=false;
              console.error('Error downloading the document', err);
          });


}


viewPatientBlobPDF(){

  let fax=this.fgGetSortingView.get('drpdefaultDocument')
  // console.log(x)

  // this.fgGetSortingView.get('drpdefaultDocument')


  // const documentId = this.filteredDefaultDocuments.find(
  //   (obj) => obj.isInbound === true
  // );
  // const url = '/#/patient_preview_pdf_document/' + documentId?.docId + '/' + this.blobName + '/' + this.isInbound;
  const url = fax && fax.value ? '/#/patient_preview_pdf_document/' + fax.value.docId + '/' + fax.value.blobName + '/' + fax.value.isInbound : '';
  if (url) {
    window.open(url, '_blank');
  }
}
}
