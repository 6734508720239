// import { Injectable } from '@angular/core';
// import { environment } from '../environments/environment';
// import { Environment } from '@abp/ng.core';

// @Injectable({
//   providedIn: 'root'
// })
// export class GtmService {

//   constructor() { 
//     this.loadGtm();
//   }
//   private loadGtm() {
//     console.log('This is Load GMT')
//     // const gtmId = environment.gtmId;
//     const gtmId = (environment as Environment).gtmId;
//     console.log(gtmId)
//     // if (gtmId) {
//     //   const script = document.createElement('script');
//     //   script.async = true;
//     //   script.src = `https://www.googletagmanager.com/gtm.js?id=${gtmId}`;
//     //   document.head.appendChild(script);
//     // }
//   }
// }


// gtm.service.ts

import { Injectable } from '@angular/core';
import { environment } from '../environments/environment';
// import { Environment } from '@abp/ng.core';
// import { environment } from '../environments/environment';
// import { environment } from '../environments/environment';
// import { environment } from '../environments/environment.prod';
// import { Environment } from './environment.model'; // Import the updated Environment interface


@Injectable({
  providedIn: 'root'
})
export class GtmService {

  constructor() { 
    this.loadGtm();
    // console.log(environment);
  }

  private loadGtm() {
    // console.log('This is Load GTM')
    const gtmId = environment['gtmId'];
    // console.log(gtmId);
    if (gtmId) {
      const script = document.createElement('script');
      script.async = true;
      script.src = `https://www.googletagmanager.com/gtm.js?id=${gtmId}`;
      document.head.appendChild(script);
    }
  }
}
