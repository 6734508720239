// import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
// import {
//   AbstractControl,
//   FormBuilder,
//   FormControl,
//   FormGroup,
//   Validators,
// } from '@angular/forms';
// import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
// // import { defaultGuid } from 'projects/admin/src/app/document-perviewer/document-perviewer.component';
// import {
//   OrderDropdowns,
//   PatientDropdowns,
// } from 'projects/patient/src/app/dropdown-proxy/dropdown-management/dropdowns';
// import {
//   AuthCategoryDTO,
//   OrderMasterDropdownDTO,
//   TypeOfPlanDTO,
// } from 'projects/patient/src/app/dropdown-proxy/dropdown-management/dropdowns/dto/order';
// import {
//   PatientMasterDropdownDTO,
//   SaleOrRentDTO,
// } from 'projects/patient/src/app/dropdown-proxy/dropdown-management/dropdowns/dto/patient';
// import {
//   OrderMasterDropdownService,
//   PatientMasterDropdownService,
// } from 'projects/patient/src/app/dropdown-proxy/dropdown-management/dropdowns/master-dropdown-service';
// import {
//   PatientAuthorizationService,
//   PatientPolicyService,
// } from 'projects/patient/src/app/patient-proxy/patient-optimization';
// import {
//   CreateUpdatePatientAuthorizationDTO,
//   PatientAuthorizationDTO,
// } from 'projects/patient/src/app/patient-proxy/patient-optimization/dto';
// import {
//   authType,
//   authTypes,
// } from 'projects/patient/src/app/patient-proxy/patient/enumeration-data/auth-type.enum';
// import { DateValidator } from 'projects/shared/src/app/validations/date-validator';
// import { NumberValidators } from 'projects/shared/src/app/validations/number-validator';
// import { TextValidators } from 'projects/shared/src/app/validations/text-validator';
// import { Subscription } from 'rxjs';
// import Swal from 'sweetalert2';
// import { PatientSaleOrderService, SaleOrderItemService } from '../order-proxy/order-management/order-optimization';
// import { OrderDetailsDTO } from '../order-proxy/order-management/order-optimization/dto';
// import { ItemDTO } from '../order-proxy/order-management/order/dto';
// import { ToastrService } from "ngx-toastr";
// import { ProductDetailsService } from 'projects/inventory/src/app/item-proxy/item-management/optimization';

@Component({
  selector: 'app-add-authorization-tab',
  templateUrl: './add-authorization-tab.component.html',
  styleUrls: ['./add-authorization-tab.component.scss'],
})
export class AddAuthorizationTabComponent implements OnInit, OnDestroy {
  // authModalTitle: string;
  // authorizationForm: FormGroup;
  // //authorizationId:string;
  // isStatusShow: boolean;
  // authType: string; //To load Auth Type
  // plan: string;
  // item: string[] = [];
  // sale: string;
  // tenantId: string;
  // category: string;
  // planName: string;
  // planType: string;
  // saveButtonHide: boolean;
  // orderStatus: boolean = true;
  // isShowSpinner: boolean = false;
  // public authDrop = authTypes;
  // drpAuthType: any[];
  // notesText ='';
  // drpPlanType: TypeOfPlanDTO[] = [];
  // drpPlanName: any[];
  // drpCategory: AuthCategoryDTO[] = [];
  // drpSaleOrRents: SaleOrRentDTO[] = [];
  // drpItemCodes: any;
  // show: boolean = false;
  // subscription$: Subscription[] = [];
  // drpOrderCodes: OrderDetailsDTO[] = [];
  // authTypes: authType;
  // orderList: any[] = [];

  // constructor(
  //   private form: FormBuilder,
  //   private numberValidator: NumberValidators,
  //   private saleOrderItemService: SaleOrderItemService,
  //   private patientAuthService: PatientAuthorizationService,
  //   private orderDropdownService: OrderMasterDropdownService,
  //   private patientDropdownService: PatientMasterDropdownService,
  //   private patientPolicyService: PatientPolicyService,
  //   private patientSaleOrderService: PatientSaleOrderService,
  //   private toastr: ToastrService,
  //   private productDetailsService: ProductDetailsService,
  //   public authDialogRef: MatDialogRef<AddAuthorizationTabComponent>,
  //   @Inject(MAT_DIALOG_DATA)
  //   public data: {
  //     orderId: string;
  //     authDatas?: PatientAuthorizationDTO;
  //     authorizationId: string;
  //     patientId: string;
  //   }
  // ) { }
  ngOnInit(): void {}
  ngOnDestroy(): void {}
  // //Page Load
  // ngOnInit(): void {
  //   this.createAuthForm();
  //   this.loadDropdowns();

  //   // To set the Popup Title
  //   if (
  //     this.data.authorizationId == null ||
  //     this.data.authorizationId == defaultGuid ||
  //     this.data.authorizationId == ''
  //   ) {
  //     this.authModalTitle = 'Add Authorization';
  //   } else {
  //     this.authModalTitle = 'Edit Authorization';
  //     this.getAuthDetailsById(); //Get Authorization details by its ID during the Editing
  //   }

  //   this.drpAuthType = Object.values(authType); // To Load Authorization Types Dropdown
  // }
  // // On Page Destroy
  // ngOnDestroy(): void {
  //   this.subscription$?.forEach((sub) => {
  //     sub && sub?.unsubscribe();
  //   });
  // }
  // updateCharacterCount() {
  //   // Ensure the text length does not exceed the maximum length
  //   if (this.notesText?.length > 5000) {
  //     this.notesText = this.notesText?.substr(0, 5000);
  //   }
  // }
  // //Form Initialization
  // createAuthForm() {
  //   this.authorizationForm = this.form.group({
  //     txtAuthType: new FormControl('', [Validators.required]),
  //     txtAuthId: new FormControl('', [
  //       Validators.required,
  //       this.numberValidator.isNumberCheck,
  //     ]),
  //     txtPlanName: new FormControl('', [Validators.required]),
  //     txtPlanType: new FormControl('', [Validators.required]),
  //     txtAuthAmount: new FormControl(''),
  //     txtStartDate: new FormControl('', [Validators.required]),
  //     txtEndDate: new FormControl('', [Validators.required]),
  //     drpCategory: new FormControl(null),
  //     drpItem: new FormControl(null, [Validators.required]),
  //     txtNote: new FormControl(''),
  //     txtReason: new FormControl(null),
  //     txtUnits: new FormControl('', this.numberValidator.isNumberCheck),
  //     txtUsed: new FormControl('', this.numberValidator.isNumberCheck),
  //     txtSaleOrRent: new FormControl(''),
  //     chkIsFollowUp: new FormControl(''),
  //     drpOrders: new FormControl(''),
  //   });
  // }
  // //To Load the Form Dropdowns
  // loadDropdowns() {
  //   //To Load the Category and Plan Type Dropdowns
  //   let orderDropdownInput: OrderDropdowns[];
  //   orderDropdownInput = [
  //     OrderDropdowns.AuthCategories,
  //     OrderDropdowns.TypeOfPlans,
  //     OrderDropdowns.PlanTypes,
  //   ];
  //   const orderDropdown = this.orderDropdownService
  //     .getOrderDropdownsByInput(orderDropdownInput)
  //     .subscribe((stateResponse) => {
  //       const response: OrderMasterDropdownDTO = stateResponse;
  //       this.drpCategory = response.authCategories;
  //       this.drpPlanType = response.typeOfPlans;
  //       //this.drpPlanName = response.planTypes;
  //     });
  //   this.subscription$.push(orderDropdown);

  //   this.patientSaleOrderService.getSaleOrdersByPatientIdByPatientId(this.data?.patientId).subscribe(x => {
  //     this.drpOrderCodes = x ?? [];
  //   })

  //   // To Load Plan Names
  //   const planNameDropdown = this.patientPolicyService
  //     .getActivePolicyByPatientIdByPatientId(this.data.patientId)
  //     .subscribe((response) => {
  //       this.drpPlanName = response ?? [];
  //     });
  //   this.subscription$.push(planNameDropdown);
  //   //To Load Sale or Rent Dropdown
  //   let patientDropdownInput: PatientDropdowns[];
  //   patientDropdownInput = [PatientDropdowns.SaleOrRent];
  //   const getPatientDropdown = this.patientDropdownService
  //     .getPatientDropdownsByInput(patientDropdownInput)
  //     .subscribe((response: PatientMasterDropdownDTO) => {
  //       this.drpSaleOrRents = response.saleOrRents;
  //     });
  //   this.subscription$.push(getPatientDropdown);

  //   //To Load the Items
  //   if (
  //     this.data?.patientId != null &&
  //     this.data?.patientId != undefined &&
  //     this.data?.patientId != ''
  //   ) {
  //     const getAllItemCode = this.productDetailsService
  //       .getProductDetailsforSaleOrder()
  //       .subscribe((response) => {
  //         this.drpItemCodes = response.items?? [];
  //       });
  //     this.subscription$.push(getAllItemCode);
  //   }
  // }
  // //Save or Update Authorization Details
  // saveUpdateAuthorizationDetails() {
  //   //Authorization Details
  //   const authDetails: CreateUpdatePatientAuthorizationDTO = {
  //     patientId: this.data.patientId,
  //     orderId: this.orderList ?? [],
  //     authorizationId: this.data.authorizationId == ''
  //       ? defaultGuid
  //       : this.data.authorizationId,
  //     //authType: this.authorizationForm.value.txtAuthType,
  //     authId: this.authorizationForm.value.txtAuthId,
  //     // authAmount: this.authorizationForm.value.txtAuthAmount,
  //     startDate: this.authorizationForm.value.txtStartDate,
  //     endDate: this.authorizationForm.value.txtEndDate,
  //     //category: this.authorizationForm.value.drpCategory,
  //     // items:
  //     //   this.authorizationForm.value.drpItem === ''
  //     //     ? []
  //     //     : this.authorizationForm.value.drpItem,
  //     note: this.authorizationForm.value.txtNote,
  //     planName: this.authorizationForm.value.txtPlanName,
  //     //planType: this.authorizationForm.value.txtPlanType,
  //     //reason: this.authorizationForm.value.txtReason,
  //     // units: this.authorizationForm.value.txtUnits,
  //     // used: this.authorizationForm.value.txtUsed ? this.authorizationForm.value.txtUsed : "",
  //     // saleOrRent: this.authorizationForm.value.txtSaleOrRent,
  //     //isFollowUp: +this.authorizationForm.value.chkIsFollowUp,
  //     //tenantId: this.tenantId ?? defaultGuid,
  //     isExpired: 0,
  //     // authDocsId: [],
  //     sortedDocsId: [],
  //     // itemAuthDetails: []
  //   };

  //   //Save Authorization
  //   if (
  //     authDetails.authorizationId == null ||
  //     authDetails.authorizationId == defaultGuid ||
  //     authDetails.authorizationId == ''
  //   ) {
  //     const patientAuthorization = this.patientAuthService
  //       .create(authDetails)
  //       .subscribe(
  //         (response) => {
  //           // Swal.fire({
  //           //   title: 'Success',
  //           //   html: 'Saved Successfully',
  //           //   icon: 'success',
  //           //   timer: 3000,
  //           //   timerProgressBar: true,
  //           // });
  //           this.toastr.success('Saved Successfully','Success')
  //           this.authDialogRef.close();
  //         },
  //         (err) => {
  //           const data: HttpErrorResponse = err;
  //           Swal.fire({
  //             icon: 'info',
  //             text: data?.error?.error?.message,
  //           });
  //         }
  //       );
  //   }
  //   //Update Authorization
  //   else {
  //     const patientAuthorization = this.patientAuthService
  //       .update(this.data.authorizationId, authDetails)
  //       .subscribe(
  //         (response) => {
  //           // Swal.fire({
  //           //   title: 'Success',
  //           //   html: 'Updated Successfully',
  //           //   icon: 'success',
  //           //   timer: 3000,
  //           //   timerProgressBar: true,
  //           // });
  //           this.toastr.success('Saved Successfully','Success')
  //           this.authDialogRef.close();
  //         },
  //         (err) => {
  //           const data: HttpErrorResponse = err;
  //           Swal.fire({
  //             icon: 'info',
  //             text: data?.error?.error?.message,
  //           });
  //         }
  //       );
  //   }
  // }
  // // To get the Order Details by Order Id
  // getAuthDetailsById() {
  //   const authDetails = this.patientAuthService
  //     .get(this.data.authorizationId)
  //     .subscribe((response) => {
  //       this.authorizationForm.patchValue({
  //         txtAuthId: response?.authId,
  //         //txtAuthType: response?.authType,
  //         //txtAuthAmount: response?.authAmount,
  //         txtStartDate: response?.startDate,
  //         txtEndDate: response?.endDate,
  //         //drpCategory: response?.category,
  //         // drpItem: response?.items,
  //         txtNote: response?.note,
  //         //chkIsFollowUp: response?.isFollowUp,
  //         txtPlanName: response?.planName,
  //         //txtPlanType: response?.planType?.toLowerCase(),
  //         //txtReason: response?.reason,
  //         //txtUnits: response?.units,
  //         txtUsed: response?.used,
  //        // txtSaleOrRent: response?.saleOrRent,
  //         drpOrders: response?.orderId,
  //       });
  //       this.orderList = response?.orderId ?? [];
  //       this.tenantId = localStorage.getItem('tenantId');
  //       //this.authType = response?.authType;
  //       this.planName = response?.planName;
  //       // this.sale = response?.saleOrRent;
  //       //this.category = response?.category;
  //       this.item = []//response?.items ?? [];
  //       //this.planType = response?.planType?.toLowerCase();
  //     });
  //   this.subscription$.push(authDetails);
  // }

  // //Reset authorization Form && Clear Data Functions
  // clearData() {
  //   this.authorizationForm.reset();
  //   this.authorizationForm.markAsUntouched();
  //   this.authorizationForm.updateValueAndValidity();
  // }
  // //Clear Dropdown Functions
  // clearOrderList() {
  //   this.orderList = [];
  //   this.authorizationForm.patchValue({
  //     drpOrders: [],
  //   });
  // }
  // clearPlanName() {
  //   this.planName = '';
  //   this.authorizationForm.patchValue({
  //     txtPlanName: '',
  //   });
  // }
  // clearPlanTypes() {
  //   this.planType = '';
  //   this.authorizationForm.patchValue({
  //     txtPlanType: '',
  //   });
  // }
  // clearAuthType() {
  //   this.authType = '';
  //   this.authorizationForm.patchValue({
  //     txtAuthType: '',
  //   });
  // }
  // clearCategory() {
  //   this.category = '';
  //   this.authorizationForm.patchValue({
  //     drpCategory: '',
  //   });
  // }
  // clearItem() {
  //   this.item = [];
  //   this.authorizationForm.patchValue({
  //     drpItem: [],
  //   });
  // }
  // clearSale() {
  //   this.sale = '';
  //   this.authorizationForm.patchValue({
  //     txtSaleOrRent: '',
  //   });
  // }
}
