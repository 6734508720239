import type { ResetPasswordInput, ResetPasswordRequestInput } from './models';
import { RestService } from '@abp/ng.core';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class AccountService {
  apiName = 'platformManagement';

  passwordTokcenValidationByInput = (input: ResetPasswordInput) =>
    this.restService.request<any, void>({
      method: 'POST',
      url: '/api/PlatformApp/account/password-tokcen-validation',
      body: input,
    },
    { apiName: this.apiName });

  resetPasswordByInput = (input: ResetPasswordInput) =>
  this.restService.request<any, void>({
    method: 'POST',
    url: '/api/PlatformApp/account/reset-password',
    body: input,
  },
  { apiName: this.apiName });


  resetPasswordRequestByInput = (input: ResetPasswordRequestInput) =>
  this.restService.request<any, string>({
    method: 'POST',
    responseType: 'text',
    url: '/api/PlatformApp/account/reset-password-request',
    body: input,
  },
  { apiName: this.apiName });

  constructor(private restService: RestService) {}
}
