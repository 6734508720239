import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import {
  dtCalllogTableOptions,
  dtInboundOptions,
  inboundTableData,
  pdfString,
} from '../admin-dashboard/dashboard-data';
import { DataTableDirective } from 'angular-datatables';
import {
  MatDialog,
  MatDialogConfig,
  MatDialogRef,
} from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Subject, Subscription } from 'rxjs';
import { ViewPatientLedgerComponent } from '../view-patient-ledger/view-patient-ledger.component';
import { WipAllocationModalV2Component } from 'projects/patient/src/app/wip-allocation-modal-v2/wip-allocation-modal-v2.component';
import { DocumentCheckListComponent } from '../document-check-list/document-check-list.component';
import { InsuranceCheckListComponent } from '../insurance-check-list/insurance-check-list.component';
import {
  OrganizationUnitDTO,
} from '../admin-proxy/platform-app-management/rcm/platform-management/organization-units/dto';
import { InboundDocumentService } from '../admin-proxy/platform-app-management/rcm/platform-management/inbound';
import Swal from 'sweetalert2';
import { ToastrService } from 'ngx-toastr';
import { InboundStatusService } from '../admin-proxy/platform-app-management/rcm/platform-management/inbound-status/inbound-status.service';
import { InboundStatusDTO } from '../admin-proxy/platform-app-management/rcm/platform-management/inbound-status/dto';
import { InboundStatusShortCode } from 'projects/shared/src/app/data/common-data';
import { PatientAddPolicyTabComponent } from 'projects/patient/src/app/patient-add-policy-tab/patient-add-policy-tab.component';
import { HttpErrorResponse } from '@angular/common/http';
import { PatientDTO } from '../warehouse-proxy/rcm/patient-management/patient/dto';
import { SendToAnotherUserComponent } from '../send-to-another-user/send-to-another-user.component';
import { AddNoteTabComponent } from 'projects/patient/src/app/add-note-tab/add-note-tab.component';
import { PatientCommonNotesComponent } from 'projects/shared/src/app/components/patient-common-notes/patient-common-notes.component';
import { PatientManualVerificationTabComponent } from 'projects/patient/src/app/patient-manual-verification-tab/patient-manual-verification-tab.component';
import { JoinFaxComponent } from '../join-fax/join-fax.component';
import { SplitDocumentModalComponent } from '../split-document-modal/split-document-modal.component';
import { PatientAuthSaveUpdateFormComponent } from 'projects/patient/src/app/patient-auth-save-update-form/patient-auth-save-update-form.component';
import { DocumentBinService } from '../admin-proxy/platform-app-management/rcm/platform-management/document-bin/document-bin.service';
import { CookieService } from 'ngx-cookie-service';
import { CallbackComponent } from 'projects/patient/src/app/callback/callback.component';
import { NewsetupMovetoComponent } from 'projects/patient/src/app/newsetup-moveto/newsetup-moveto.component';
import { PatientPersonalService } from 'projects/patient/src/app/patient-proxy/patient-optimization';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CommunicationService } from 'projects/shared/src/app/services/communication.service';
import { VerificationStatus, VerificationStatusV1 } from 'projects/patient/src/app/patient-proxy/patient/enumeration-data/verification-status.enum';
import { defaultGuid } from 'projects/shared/src/app/enums/allenums.enum';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { DatePipe } from '@angular/common';
import { OrganizationUnitService } from '../admin-proxy/platform-app-management/rcm/platform-management/organization-units';
import { PatientCommonDocumentMedicareUploadTabComponent } from 'projects/shared/src/app/components/patient-common-document-medicare-upload-tab/patient-common-document-medicare-upload-tab.component';
@Component({
  selector: 'app-dashboard-fax-table',
  templateUrl: './dashboard-fax-table.component.html',
  styleUrls: ['./dashboard-fax-table.component.scss'],
})
export class DashboardFaxTableComponent implements OnInit {
  //Inbound Table
  isRefresh: boolean = false;
  @HostBinding('style.font') font =
    '400 13px/20px Roboto, "Helvetica Neue", sans-serif';
  @HostBinding('style.letter-spacing') letterSpacing = 'normal';
  defaultGuid = defaultGuid;
  @Input() strInboundStatus: string = '';
  @Input() tableData: any;
  @Input() branchList: any;
  @Input() selectedNode: any;
  @Input() MyFaxSelection:any;
  @Input() currentPage: any = 1;
  @Input() totalPages: any = 1;
  @Input() visibleColumns: number[] ;
  @Output() nevigatePage? = new EventEmitter<number>();
  @Output() getVisibleColumns? =   new EventEmitter<number[]>();
  @Output() reloadTableEvent = new EventEmitter(); //Event Emitter to reload the table
  @Output() reloadTreeEvent = new EventEmitter(); //Event Emitter to reload the tree
  @Output() selectNode = new EventEmitter();
  inboundTableData: any; //Inbound Table Data Variable
  inboundStatusIds = InboundStatusShortCode;
  dtInboundOptions: any;
  dtInboundOptionsDefault: any;
  dtInboundOptionsUnProcessedFax: any;
  dtInboundOptionsFaxIssues: any;
  dtInboundOptionsConfirmation: any;
  dtInboundOptionsVerification: any;
  dtInboundOptionsVerificationIssues: any;
  dtInboundOptionsPreCert: any;
  dtInboundOptionsMedicare: any;
  dtInboundOptionsDisapproved: any;
  dtInboundOptionsMissingDocuments: any;
  dtInboundOptionsReady: any;
  dtInboundOptionsScheduled: any;
  dtInboundOptionsCompleted: any;
  dtInboundOptionsPending: any;
  dtInboundOptionsCallBack: any;
  dtInboundOptionsArchive: any;
  dtInboundOptionsRecycleBin: any;
  dtInboundOptionsngSwitchDefault: any;
  dtInboundOptionsv: any;
  dtTrigger: Subject<any> = new Subject<any>();
  @Output() patientIdEmitter: EventEmitter<PatientDTO> =
    new EventEmitter<PatientDTO>();
  @Output() patientIdEmitters: EventEmitter<string> =
    new EventEmitter<string>();
  branchName: any;
  branchItem: any;
  default = 'Default';
  //dtTrigger: any;
  // @Output() faxDetails: EventEmitter<string> = new EventEmitter<string>();
  @ViewChild(DataTableDirective, { static: false })
  datatableElement: DataTableDirective;
  myTable: DataTables.Api;
  pdfString = pdfString;
  tenantId: string = '';
  locationList: OrganizationUnitDTO[] = []; //Location Menu List
  binList: InboundStatusDTO[] = []; //Bin Menu List
  $subscription: Subscription[] = [];
  lockFile$:Subscription;
  policyId: string;
  tblSelectedFaxId: string;
  patientForm: FormGroup;
  calllogTableData: any;
  dtCalllogTableOptions = dtCalllogTableOptions;
  verifyId: any;
  isLoading: boolean;
  patientId: string;
  verifyStatus: any;
  tr: any;
  funcListener: any;
  lstVerificationSttaus=VerificationStatus;
  lstVerificationSttausV1=VerificationStatusV1;
  roleName:string;
  disableLockCheckbox:boolean = false;
  constructor(
    private dialog: MatDialog,
    // private branchService: OrganizationUnitService,
    private InboundDocService: InboundDocumentService,
    private branchService: OrganizationUnitService,
    private inboundStatusService: InboundStatusService,
    private toaster: ToastrService,
    private documentBinService: DocumentBinService,
    private cookieService: CookieService,
    private router: Router,
    private PatientPersonalService: PatientPersonalService,
    private formBuilder: FormBuilder,
    private snackBar: MatSnackBar,
    private communicationService: CommunicationService,
    private datepipe: DatePipe,
    private changeDetectorRef: ChangeDetectorRef
  ) {
    this.funcListener = this.func.bind(this);
    window.addEventListener('storage', this.funcListener);
    this.funcListener = this.funct.bind(this);
    window.addEventListener('storage', this.funcListener);
  }

  ngOnInit(): void {
    this.dtInboundOptionsDefault = {
      responsive: true,
      // stateSave: true,
      paging: false,
      dom: 'Bfrtip',
      language: {
        emptyTable: 'No records',
        info: 'Total : _MAX_ records',
        infoEmpty: 'Total : _MAX_ records',
        infoFiltered: '(filtered : _TOTAL_ records)',
        loadingRecords: 'Loading...',
        zeroRecords: 'No matching records',
      },
      scrollCollapse: true,
      scrollY: '300px',
      scrollX: true,
      fixedHeader: true,
      order:[],
      columnDefs: [
        { targets: [0, 1, -1], orderable: false },
        { targets: [0, 1, 2, 3, -1], className: 'dt-fixed-column' },
        { type: 'date', targets: [5, 6] },
      ],
      search: {
        smart: false, // Set to "none" for full exact match
      },
      buttons: [
        {
          extend: 'excel',
          text: 'Export',
          filename: 'Default - Faxes Bin',
          exportOptions: {
            columns: ':visible:not(:nth-child(1),:nth-child(2))',
          },
        },
        {
          extend: 'colvis',
          columns: ':not(:eq(0), :eq(1), :eq(2),:eq(3), :eq(-1))', // Exclude first three and last columns from the column visibility toggle
        },
        // {
        //   text: 'Show Default Column',
        //   action: function (e, dt, node, config) {
        //     dt.columns(':not(:lt(4),:last-child)').visible(false);
        //   },
        // },
        // {
        //   text: 'Show All Column',
        //   action: function (e, dt, node, config) {
        //     dt.columns().visible(true);
        //   },
        // },
      ],
    };

    this.dtInboundOptionsUnProcessedFax = {
      responsive: true,
      // stateSave: true,
      paging: false,
      dom: 'Bfrtip',
      language: {
        emptyTable: 'No records',
        info: 'Total : _MAX_ records',
        infoEmpty: 'Total : _MAX_ records',
        infoFiltered: '(filtered : _TOTAL_ records)',
        loadingRecords: 'Loading...',
        zeroRecords: 'No matching records',
      },
      scrollCollapse: true,
      scrollY: '300px',
      scrollX: true,
      fixedHeader: true,
      order:[],
      columnDefs: [
        { targets: [0, 1, -1], orderable: false },
        { targets: [0, 1, 2, 3, -1], className: 'dt-fixed-column' },
        { type: 'date', targets: [5, 6] },
      ],
      search: {
        smart: false, // Set to "none" for full exact match
      },
      buttons: [
        {
          extend: 'excel',
          text: 'Export',
          filename: 'UnProcessed Fax - Faxes Bin',
          exportOptions: {
            columns: ':visible:not(:nth-child(1),:nth-child(2))',
          },
        },
        {
          extend: 'colvis',
          columns: ':not(:eq(0), :eq(1), :eq(2),:eq(3), :eq(-1))', // Exclude first three and last columns from the column visibility toggle
        },
        // {
        //   text: 'Show Default Column',
        //   action: function (e, dt, node, config) {
        //     dt.columns(':not(:lt(4),:last-child)').visible(false);
        //   },
        // },
        // {
        //   text: 'Show All Column',
        //   action: function (e, dt, node, config) {
        //     dt.columns().visible(true);
        //   },
        // },
      ],
    };

    this.dtInboundOptionsFaxIssues = {
      responsive: true,
      // stateSave: true,
      paging: false,
      dom: 'Bfrtip',
      language: {
        emptyTable: 'No records',
        info: 'Total : _MAX_ records',
        infoEmpty: 'Total : _MAX_ records',
        infoFiltered: '(filtered : _TOTAL_ records)',
        loadingRecords: 'Loading...',
        zeroRecords: 'No matching records',
      },
      scrollCollapse: true,
      scrollY: '300px',
      scrollX: true,
      fixedHeader: true,
      order:[],
      columnDefs: [
        { targets: [0, 1, -1], orderable: false },
        { targets: [0, 1, 2, 3, -1], className: 'dt-fixed-column' },
        { type: 'date', targets: [5, 6] },
      ],
      search: {
        smart: false, // Set to "none" for full exact match
      },
      buttons: [
        {
          extend: 'excel',
          text: 'Export',
          filename: 'Fax Issues - Faxes Bin',
          exportOptions: {
            columns: ':visible:not(:nth-child(1),:nth-child(2))',
          },
        },
        {
          extend: 'colvis',
          columns: ':not(:eq(0), :eq(1), :eq(2),:eq(3), :eq(-1))', // Exclude first three and last columns from the column visibility toggle
        },
        // {
        //   text: 'Show Default Column',
        //   action: function (e, dt, node, config) {
        //     dt.columns(':not(:lt(4),:last-child)').visible(false);
        //   },
        // },
        // {
        //   text: 'Show All Column',
        //   action: function (e, dt, node, config) {
        //     dt.columns().visible(true);
        //   },
        // },
      ],
    };

    this.dtInboundOptionsConfirmation = {
      responsive: true,
      // stateSave: true,
      paging: false,
      dom: 'Bfrtip',
      language: {
        emptyTable: 'No records',
        info: 'Total : _MAX_ records',
        infoEmpty: 'Total : _MAX_ records',
        infoFiltered: '(filtered : _TOTAL_ records)',
        loadingRecords: 'Loading...',
        zeroRecords: 'No matching records',
      },
      scrollCollapse: true,
      scrollY: '300px',
      scrollX: true,
      fixedHeader: true,
      order:[],
      columnDefs: [
        { targets: [0, 1, -1], orderable: false },
        { targets: [0, 1, 2, 3, -1], className: 'dt-fixed-column' },
        { type: 'date', targets: [5, 6] },
      ],
      search: {
        smart: false, // Set to "none" for full exact match
      },
      buttons: [
        {
          extend: 'excel',
          text: 'Export',
          filename: 'Confirmation - Faxes Bin',
          exportOptions: {
            columns: ':visible:not(:nth-child(1),:nth-child(2))',
          },
        },
        {
          extend: 'colvis',
          columns: ':not(:eq(0), :eq(1), :eq(2),:eq(3), :eq(-1))', // Exclude first three and last columns from the column visibility toggle
        },
        // {
        //   text: 'Show Default Column',
        //   action: function (e, dt, node, config) {
        //     dt.columns(':not(:lt(4),:last-child)').visible(false);
        //   },
        // },
        // {
        //   text: 'Show All Column',
        //   action: function (e, dt, node, config) {
        //     dt.columns().visible(true);
        //   },
        // },
      ],
    };

    this.dtInboundOptionsVerification = {
      responsive: true,
      // stateSave: true,
      paging: false,
      dom: 'Bfrtip',
      language: {
        emptyTable: 'No records',
        info: 'Total : _MAX_ records',
        infoEmpty: 'Total : _MAX_ records',
        infoFiltered: '(filtered : _TOTAL_ records)',
        loadingRecords: 'Loading...',
        zeroRecords: 'No matching records',
      },
      scrollCollapse: true,
      scrollY: '300px',
      scrollX: true,
      fixedHeader: true,
      order:[],
      columnDefs: [
        { targets: [0, 1, -1], orderable: false },
        { targets: [0, 1, 2, 3, -1], className: 'dt-fixed-column' },
        { type: 'date', targets: [5, 6] },
      ],
      search: {
        smart: false, // Set to "none" for full exact match
      },
      buttons: [
        {
          extend: 'excel',
          text: 'Export',
          filename: 'Verification - Faxes Bin',
          exportOptions: {
            columns: ':visible:not(:nth-child(1),:nth-child(2))',
          },
        },
        {
          extend: 'colvis',
          columns: ':not(:eq(0), :eq(1), :eq(2),:eq(3), :eq(-1))', // Exclude first three and last columns from the column visibility toggle
        },
        // {
        //   text: 'Show Default Column',
        //   action: function (e, dt, node, config) {
        //     dt.columns(':not(:lt(4),:last-child)').visible(false);
        //   },
        // },
        // {
        //   text: 'Show All Column',
        //   action: function (e, dt, node, config) {
        //     dt.columns().visible(true);
        //   },
        // },
      ],
    };

    this.dtInboundOptionsVerificationIssues = {
      responsive: true,
      // stateSave: true,
      paging: false,
      dom: 'Bfrtip',
      language: {
        emptyTable: 'No records',
        info: 'Total : _MAX_ records',
        infoEmpty: 'Total : _MAX_ records',
        infoFiltered: '(filtered : _TOTAL_ records)',
        loadingRecords: 'Loading...',
        zeroRecords: 'No matching records',
      },
      scrollCollapse: true,
      scrollY: '300px',
      scrollX: true,
      fixedHeader: true,
      order:[],
      columnDefs: [
        { targets: [0, 1, -1], orderable: false },
        { targets: [0, 1, 2, 3, -1], className: 'dt-fixed-column' },
        { type: 'date', targets: [5, 6] },
      ],
      search: {
        smart: false, // Set to "none" for full exact match
      },
      buttons: [
        {
          extend: 'excel',
          text: 'Export',
          filename: 'Verification Issues - Faxes Bin',
          exportOptions: {
            columns: ':visible:not(:nth-child(1),:nth-child(2))',
          },
        },
        {
          extend: 'colvis',
          columns: ':not(:eq(0), :eq(1), :eq(2),:eq(3), :eq(-1))', // Exclude first three and last columns from the column visibility toggle
        },
        // {
        //   text: 'Show Default Column',
        //   action: function (e, dt, node, config) {
        //     dt.columns(':not(:lt(4),:last-child)').visible(false);
        //   },
        // },
        // {
        //   text: 'Show All Column',
        //   action: function (e, dt, node, config) {
        //     dt.columns().visible(true);
        //   },
        // },
      ],
    };

    this.dtInboundOptionsPreCert = {
      responsive: true,
      // stateSave: true,
      paging: false,
      dom: 'Bfrtip',
      language: {
        emptyTable: 'No records',
        info: 'Total : _MAX_ records',
        infoEmpty: 'Total : _MAX_ records',
        infoFiltered: '(filtered : _TOTAL_ records)',
        loadingRecords: 'Loading...',
        zeroRecords: 'No matching records',
      },
      scrollCollapse: true,
      scrollY: '300px',
      scrollX: true,
      fixedHeader: true,
      order:[],
      columnDefs: [
        { targets: [0, 1, -1], orderable: false },
        { targets: [0, 1, 2, 3, -1], className: 'dt-fixed-column' },
        { type: 'date', targets: [5, 6] },
      ],
      search: {
        smart: false, // Set to "none" for full exact match
      },
      buttons: [
        {
          extend: 'excel',
          text: 'Export',
          filename: 'PreCert - Faxes Bin',
          exportOptions: {
            columns: ':visible:not(:nth-child(1),:nth-child(2))',
          },
        },
        {
          extend: 'colvis',
          columns: ':not(:eq(0), :eq(1), :eq(2),:eq(3), :eq(-1))', // Exclude first three and last columns from the column visibility toggle
        },
        // {
        //   text: 'Show Default Column',
        //   action: function (e, dt, node, config) {
        //     dt.columns(':not(:lt(4),:last-child)').visible(false);
        //   },
        // },
        // {
        //   text: 'Show All Column',
        //   action: function (e, dt, node, config) {
        //     dt.columns().visible(true);
        //   },
        // },
      ],
    };

    this.dtInboundOptionsMedicare = {
      responsive: true,
      // stateSave: true,
      paging: false,
      dom: 'Bfrtip',
      language: {
        emptyTable: 'No records',
        info: 'Total : _MAX_ records',
        infoEmpty: 'Total : _MAX_ records',
        infoFiltered: '(filtered : _TOTAL_ records)',
        loadingRecords: 'Loading...',
        zeroRecords: 'No matching records',
      },
      scrollCollapse: true,
      scrollY: '300px',
      scrollX: true,
      fixedHeader: true,
      order:[],
      columnDefs: [
        { targets: [0, 1, -1], orderable: false },
        { targets: [0, 1, 2, 3, -1], className: 'dt-fixed-column' },
        { type: 'date', targets: [5, 6] },
      ],
      search: {
        smart: false, // Set to "none" for full exact match
      },
      buttons: [
        {
          extend: 'excel',
          text: 'Export',
          filename: 'Medicare - Faxes Bin',
          exportOptions: {
            columns: ':visible:not(:nth-child(1),:nth-child(2))',
          },
        },
        {
          extend: 'colvis',
          columns: ':not(:eq(0), :eq(1), :eq(2),:eq(3), :eq(-1))', // Exclude first three and last columns from the column visibility toggle
        },
        // {
        //   text: 'Show Default Column',
        //   action: function (e, dt, node, config) {
        //     dt.columns(':not(:lt(4),:last-child)').visible(false);
        //   },
        // },
        // {
        //   text: 'Show All Column',
        //   action: function (e, dt, node, config) {
        //     dt.columns().visible(true);
        //   },
        // },
      ],
    };

    this.dtInboundOptionsDisapproved = {
      responsive: true,
      // stateSave: true,
      paging: false,
      dom: 'Bfrtip',
      language: {
        emptyTable: 'No records',
        info: 'Total : _MAX_ records',
        infoEmpty: 'Total : _MAX_ records',
        infoFiltered: '(filtered : _TOTAL_ records)',
        loadingRecords: 'Loading...',
        zeroRecords: 'No matching records',
      },
      scrollCollapse: true,
      scrollY: '300px',
      scrollX: true,
      fixedHeader: true,
      order:[],
      columnDefs: [
        { targets: [0, 1, -1], orderable: false },
        { targets: [0, 1, 2, 3, -1], className: 'dt-fixed-column' },
        { type: 'date', targets: [5, 6] },
      ],
      search: {
        smart: false, // Set to "none" for full exact match
      },
      buttons: [
        {
          extend: 'excel',
          text: 'Export',
          filename: 'Disapproved - Faxes Bin',
          exportOptions: {
            columns: ':visible:not(:nth-child(1),:nth-child(2))',
          },
        },
        {
          extend: 'colvis',
          columns: ':not(:eq(0), :eq(1), :eq(2),:eq(3), :eq(-1))', // Exclude first three and last columns from the column visibility toggle
        },
        // {
        //   text: 'Show Default Column',
        //   action: function (e, dt, node, config) {
        //     dt.columns(':not(:lt(4),:last-child)').visible(false);
        //   },
        // },
        // {
        //   text: 'Show All Column',
        //   action: function (e, dt, node, config) {
        //     dt.columns().visible(true);
        //   },
        // },
      ],
    };

    this.dtInboundOptionsMissingDocuments = {
      responsive: true,
      // stateSave: true,
      paging: false,
      dom: 'Bfrtip',
      language: {
        emptyTable: 'No records',
        info: 'Total : _MAX_ records',
        infoEmpty: 'Total : _MAX_ records',
        infoFiltered: '(filtered : _TOTAL_ records)',
        loadingRecords: 'Loading...',
        zeroRecords: 'No matching records',
      },
      scrollCollapse: true,
      scrollY: '300px',
      scrollX: true,
      fixedHeader: true,
      order:[],
      columnDefs: [
        { targets: [0, 1, -1], orderable: false },
        { targets: [0, 1, 2, 3, -1], className: 'dt-fixed-column' },
        { type: 'date', targets: [5, 6] },
      ],
      search: {
        smart: false, // Set to "none" for full exact match
      },
      buttons: [
        {
          extend: 'excel',
          text: 'Export',
          filename: 'MissingDocuments - Faxes Bin',
          exportOptions: {
            columns: ':visible:not(:nth-child(1),:nth-child(2))',
          },
        },
        {
          extend: 'colvis',
          columns: ':not(:eq(0), :eq(1), :eq(2),:eq(3), :eq(-1))', // Exclude first three and last columns from the column visibility toggle
        },
        // {
        //   text: 'Show Default Column',
        //   action: function (e, dt, node, config) {
        //     dt.columns(':not(:lt(4),:last-child)').visible(false);
        //   },
        // },
        // {
        //   text: 'Show All Column',
        //   action: function (e, dt, node, config) {
        //     dt.columns().visible(true);
        //   },
        // },
      ],
    };

    this.dtInboundOptionsReady = {
      responsive: true,
      // stateSave: true,
      paging: false,
      dom: 'Bfrtip',
      language: {
        emptyTable: 'No records',
        info: 'Total : _MAX_ records',
        infoEmpty: 'Total : _MAX_ records',
        infoFiltered: '(filtered : _TOTAL_ records)',
        loadingRecords: 'Loading...',
        zeroRecords: 'No matching records',
      },
      scrollCollapse: true,
      scrollY: '300px',
      scrollX: true,
      fixedHeader: true,
      order:[],
      columnDefs: [
        { targets: [0, 1, -1], orderable: false },
        { targets: [0, 1, 2, 3, -1], className: 'dt-fixed-column' },
        { type: 'date', targets: [5, 6] },
      ],
      search: {
        smart: false, // Set to "none" for full exact match
      },
      buttons: [
        {
          extend: 'excel',
          text: 'Export',
          filename: 'Ready - Faxes Bin',
          exportOptions: {
            columns: ':visible:not(:nth-child(1),:nth-child(2))',
          },
        },
        {
          extend: 'colvis',
          columns: ':not(:eq(0), :eq(1), :eq(2),:eq(3), :eq(-1))', // Exclude first three and last columns from the column visibility toggle
        },
        // {
        //   text: 'Show Default Column',
        //   action: function (e, dt, node, config) {
        //     dt.columns(':not(:lt(4),:last-child)').visible(false);
        //   },
        // },
        // {
        //   text: 'Show All Column',
        //   action: function (e, dt, node, config) {
        //     dt.columns().visible(true);
        //   },
        // },
      ],
    };

    this.dtInboundOptionsScheduled = {
      responsive: true,
      // stateSave: true,
      paging: false,
      dom: 'Bfrtip',
      language: {
        emptyTable: 'No records',
        info: 'Total : _MAX_ records',
        infoEmpty: 'Total : _MAX_ records',
        infoFiltered: '(filtered : _TOTAL_ records)',
        loadingRecords: 'Loading...',
        zeroRecords: 'No matching records',
      },
      scrollCollapse: true,
      scrollY: '300px',
      scrollX: true,
      fixedHeader: true,
      order:[],
      columnDefs: [
        { targets: [0, 1, -1], orderable: false },
        { targets: [0, 1, 2, 3, -1], className: 'dt-fixed-column' },
        { type: 'date', targets: [5, 6] },
      ],
      search: {
        smart: false, // Set to "none" for full exact match
      },
      buttons: [
        {
          extend: 'excel',
          text: 'Export',
          filename: 'Scheduled - Faxes Bin',
          exportOptions: {
            columns: ':visible:not(:nth-child(1),:nth-child(2))',
          },
        },
        {
          extend: 'colvis',
          columns: ':not(:eq(0), :eq(1), :eq(2),:eq(3), :eq(-1))', // Exclude first three and last columns from the column visibility toggle
        },
        // {
        //   text: 'Show Default Column',
        //   action: function (e, dt, node, config) {
        //     dt.columns(':not(:lt(4),:last-child)').visible(false);
        //   },
        // },
        // {
        //   text: 'Show All Column',
        //   action: function (e, dt, node, config) {
        //     dt.columns().visible(true);
        //   },
        // },
      ],
    };

    this.dtInboundOptionsCompleted = {
      responsive: true,
      // stateSave: true,
      paging: false,
      dom: 'Bfrtip',
      language: {
        emptyTable: 'No records',
        info: 'Total : _MAX_ records',
        infoEmpty: 'Total : _MAX_ records',
        infoFiltered: '(filtered : _TOTAL_ records)',
        loadingRecords: 'Loading...',
        zeroRecords: 'No matching records',
      },
      scrollCollapse: true,
      scrollY: '300px',
      scrollX: true,
      fixedHeader: true,
      order:[],
      columnDefs: [
        { targets: [0, 1, -1], orderable: false },
        { targets: [0, 1, 2, 3, -1], className: 'dt-fixed-column' },
        { type: 'date', targets: [5, 6] },
      ],
      search: {
        smart: false, // Set to "none" for full exact match
      },
      buttons: [
        {
          extend: 'excel',
          text: 'Export',
          filename: 'Completed - Faxes Bin',
          exportOptions: {
            columns: ':visible:not(:nth-child(1),:nth-child(2))',
          },
        },
        {
          extend: 'colvis',
          columns: ':not(:eq(0), :eq(1), :eq(2),:eq(3), :eq(-1))', // Exclude first three and last columns from the column visibility toggle
        },
        // {
        //   text: 'Show Default Column',
        //   action: function (e, dt, node, config) {
        //     dt.columns(':not(:lt(4),:last-child)').visible(false);
        //   },
        // },
        // {
        //   text: 'Show All Column',
        //   action: function (e, dt, node, config) {
        //     dt.columns().visible(true);
        //   },
        // },
      ],
    };

    this.dtInboundOptionsPending = {
      responsive: true,
      // stateSave: true,
      paging: false,
      dom: 'Bfrtip',
      language: {
        emptyTable: 'No records',
        info: 'Total : _MAX_ records',
        infoEmpty: 'Total : _MAX_ records',
        infoFiltered: '(filtered : _TOTAL_ records)',
        loadingRecords: 'Loading...',
        zeroRecords: 'No matching records',
      },
      scrollCollapse: true,
      scrollY: '300px',
      scrollX: true,
      fixedHeader: true,
      order:[],
      columnDefs: [
        { targets: [0, 1, -1], orderable: false },
        { targets: [0, 1, 2, 3, -1], className: 'dt-fixed-column' },
        { type: 'date', targets: [5, 6] },
      ],
      search: {
        smart: false, // Set to "none" for full exact match
      },
      buttons: [
        {
          extend: 'excel',
          text: 'Export',
          filename: 'Pending - Faxes Bin',
          exportOptions: {
            columns: ':visible:not(:nth-child(1),:nth-child(2))',
          },
        },
        {
          extend: 'colvis',
          columns: ':not(:eq(0), :eq(1), :eq(2),:eq(3), :eq(-1))', // Exclude first three and last columns from the column visibility toggle
        },
        // {
        //   text: 'Show Default Column',
        //   action: function (e, dt, node, config) {
        //     dt.columns(':not(:lt(4),:last-child)').visible(false);
        //   },
        // },
        // {
        //   text: 'Show All Column',
        //   action: function (e, dt, node, config) {
        //     dt.columns().visible(true);
        //   },
        // },
      ],
    };

    this.dtInboundOptionsCallBack = {
      responsive: true,
      // stateSave: true,
      paging: false,
      dom: 'Bfrtip',
      language: {
        emptyTable: 'No records',
        info: 'Total : _MAX_ records',
        infoEmpty: 'Total : _MAX_ records',
        infoFiltered: '(filtered : _TOTAL_ records)',
        loadingRecords: 'Loading...',
        zeroRecords: 'No matching records',
      },
      scrollCollapse: true,
      scrollY: '300px',
      scrollX: true,
      fixedHeader: true,
      order:[],
      columnDefs: [
        { targets: [0, 1, -1], orderable: false },
        { targets: [0, 1, 2, 3, -1], className: 'dt-fixed-column' },
        { type: 'date', targets: [5, 6] },
      ],
      search: {
        smart: false, // Set to "none" for full exact match
      },
      buttons: [
        {
          extend: 'excel',
          text: 'Export',
          filename: 'CallBack - Faxes Bin',
          exportOptions: {
            columns: ':visible:not(:nth-child(1),:nth-child(2))',
          },
        },
        {
          extend: 'colvis',
          columns: ':not(:eq(0), :eq(1), :eq(2),:eq(3), :eq(-1))', // Exclude first three and last columns from the column visibility toggle
        },
        // {
        //   text: 'Show Default Column',
        //   action: function (e, dt, node, config) {
        //     dt.columns(':not(:lt(4),:last-child)').visible(false);
        //   },
        // },
        // {
        //   text: 'Show All Column',
        //   action: function (e, dt, node, config) {
        //     dt.columns().visible(true);
        //   },
        // },
      ],
    };

    this.dtInboundOptionsArchive = {
      responsive: true,
      // stateSave: true,
      paging: false,
      dom: 'Bfrtip',
      language: {
        emptyTable: 'No records',
        info: 'Total : _MAX_ records',
        infoEmpty: 'Total : _MAX_ records',
        infoFiltered: '(filtered : _TOTAL_ records)',
        loadingRecords: 'Loading...',
        zeroRecords: 'No matching records',
      },
      scrollCollapse: true,
      scrollY: '300px',
      scrollX: true,
      fixedHeader: true,
      order:[],
      columnDefs: [
        { targets: [0, 1, -1], orderable: false },
        { targets: [0, 1, 2, 3, -1], className: 'dt-fixed-column' },
        { type: 'date', targets: [5, 6] },
      ],
      search: {
        smart: false, // Set to "none" for full exact match
      },
      buttons: [
        {
          extend: 'excel',
          text: 'Export',
          filename: 'Archive - Faxes Bin',
          exportOptions: {
            columns: ':visible:not(:nth-child(1),:nth-child(2))',
          },
        },
        {
          extend: 'colvis',
          columns: ':not(:eq(0), :eq(1), :eq(2),:eq(3), :eq(-1))', // Exclude first three and last columns from the column visibility toggle
        },
        // {
        //   text: 'Show Default Column',
        //   action: function (e, dt, node, config) {
        //     dt.columns(':not(:lt(4),:last-child)').visible(false);
        //   },
        // },
        // {
        //   text: 'Show All Column',
        //   action: function (e, dt, node, config) {
        //     dt.columns().visible(true);
        //   },
        // },
      ],
    };

    this.dtInboundOptionsRecycleBin = {
      responsive: true,
      // stateSave: true,
      paging: false,
      dom: 'Bfrtip',
      language: {
        emptyTable: 'No records',
        info: 'Total : _MAX_ records',
        infoEmpty: 'Total : _MAX_ records',
        infoFiltered: '(filtered : _TOTAL_ records)',
        loadingRecords: 'Loading...',
        zeroRecords: 'No matching records',
      },
      scrollCollapse: true,
      scrollY: '300px',
      scrollX: true,
      fixedHeader: true,
      order:[],
      columnDefs: [
        { targets: [0, 1, -1], orderable: false },
        { targets: [0, 1, 2, 3, -1], className: 'dt-fixed-column' },
        { type: 'date', targets: [5, 6] },
      ],
      search: {
        smart: false, // Set to "none" for full exact match
      },
      buttons: [
        {
          extend: 'excel',
          text: 'Export',
          filename: 'Recycle Bin - Faxes Bin',
          exportOptions: {
            columns: ':visible:not(:nth-child(1),:nth-child(2))',
          },
        },
        {
          extend: 'colvis',
          columns: ':not(:eq(0), :eq(1), :eq(2),:eq(3), :eq(-1))', // Exclude first three and last columns from the column visibility toggle
        },
        // {
        //   text: 'Show Default Column',
        //   action: function (e, dt, node, config) {
        //     dt.columns(':not(:lt(4),:last-child)').visible(false);
        //   },
        // },
        // {
        //   text: 'Show All Column',
        //   action: function (e, dt, node, config) {
        //     dt.columns().visible(true);
        //   },
        // },
      ],
    };

    this.dtInboundOptionsngSwitchDefault = {
      responsive: true,
      // stateSave: true,
      paging: false,
      dom: 'Bfrtip',
      language: {
        emptyTable: 'No records',
        info: 'Total : _MAX_ records',
        infoEmpty: 'Total : _MAX_ records',
        infoFiltered: '(filtered : _TOTAL_ records)',
        loadingRecords: 'Loading...',
        zeroRecords: 'No matching records',
      },
      scrollCollapse: true,
      scrollY: '300px',
      scrollX: true,
      fixedHeader: true,
      order:[],
      columnDefs: [
        { targets: [0, 1, -1], orderable: false },
        { targets: [0, 1, 2, 3, -1], className: 'dt-fixed-column' },
        { type: 'date', targets: [5, 6] },
      ],
      search: {
        smart: false, // Set to "none" for full exact match
      },
      buttons: [
        {
          extend: 'excel',
          text: 'Export',
          filename: 'Default- Faxes Bin',
          exportOptions: {
            columns: ':visible:not(:nth-child(1),:nth-child(2))',
          },
        },
        {
          extend: 'colvis',
          columns: ':not(:eq(0), :eq(1), :eq(2),:eq(3), :eq(-1))', // Exclude first three and last columns from the column visibility toggle
        },
        // {
        //   text: 'Show Default Column',
        //   action: function (e, dt, node, config) {
        //     dt.columns(':not(:lt(4),:last-child)').visible(false);
        //   },
        // },
        // {
        //   text: 'Show All Column',
        //   action: function (e, dt, node, config) {
        //     dt.columns().visible(true);
        //   },
        // },
      ],
    };

    this.inboundTableData = this.tableData;
    this.tenantId = localStorage.getItem('tenantId') ?? '';
    this.roleName = localStorage.getItem("roleName") ?? "";
    this.loadLocations();
    this.loadBins();
    this.patientForm = this.formBuilder.group({
      patientName: ['-'],
      address: ['-'],
      chartNo: ['-'],
      dob: ['-'],
      city: ['-'],
      state: ['-'],
      zip: ['-'],
      homeNo: ['-'],
      cell: ['-'],
      workNo: ['-'],
      email: ['-'],
    });
    this.exportFileName();
  }


  exportFileName(){
    this.dtInboundOptionsDefault.buttons[0].filename =
    'Default - Faxes Bin' + '-' + this.datepipe.transform(new Date(), 'MM-dd-yyyy');

    this.dtInboundOptionsUnProcessedFax.buttons[0].filename =
    'UnProcessed Fax - Faxes Bin' + '-' + this.datepipe.transform(new Date(), 'MM-dd-yyyy');

    this.dtInboundOptionsFaxIssues.buttons[0].filename =
    'Fax Issues - Faxes Bin' + '-' + this.datepipe.transform(new Date(), 'MM-dd-yyyy');

    this.dtInboundOptionsConfirmation.buttons[0].filename =
    'Confirmation - Faxes Bin' + '-' + this.datepipe.transform(new Date(), 'MM-dd-yyyy');

    this.dtInboundOptionsVerification.buttons[0].filename =
    'Verification - Faxes Bin' + '-' + this.datepipe.transform(new Date(), 'MM-dd-yyyy');

    this.dtInboundOptionsVerificationIssues.buttons[0].filename =
    'Verification Issues - Faxes Bin' + '-' + this.datepipe.transform(new Date(), 'MM-dd-yyyy');

    this.dtInboundOptionsPreCert.buttons[0].filename =
    'PreCert - Faxes Bin' + '-' + this.datepipe.transform(new Date(), 'MM-dd-yyyy');

    this.dtInboundOptionsMedicare.buttons[0].filename =
    'Medicare - Faxes Bin' + '-' + this.datepipe.transform(new Date(), 'MM-dd-yyyy');

    this.dtInboundOptionsDisapproved.buttons[0].filename =
    'Disapproved - Faxes Bin' + '-' + this.datepipe.transform(new Date(), 'MM-dd-yyyy');

    this.dtInboundOptionsMissingDocuments.buttons[0].filename =
    'Missing Documents - Faxes Bin' + '-' + this.datepipe.transform(new Date(), 'MM-dd-yyyy');

    this.dtInboundOptionsReady.buttons[0].filename =
    'Ready - Faxes Bin' + '-' + this.datepipe.transform(new Date(), 'MM-dd-yyyy');

    this.dtInboundOptionsScheduled.buttons[0].filename =
    'Schedule - Faxes Bin' + '-' + this.datepipe.transform(new Date(), 'MM-dd-yyyy');

    this.dtInboundOptionsCompleted.buttons[0].filename =
    'Completed - Faxes Bin' + '-' + this.datepipe.transform(new Date(), 'MM-dd-yyyy');

    this.dtInboundOptionsPending.buttons[0].filename =
    'Pending - Faxes Bin' + '-' + this.datepipe.transform(new Date(), 'MM-dd-yyyy');

    this.dtInboundOptionsCallBack.buttons[0].filename =
    'Call Back - Faxes Bin' + '-' + this.datepipe.transform(new Date(), 'MM-dd-yyyy');

    this.dtInboundOptionsArchive.buttons[0].filename =
    'Archive - Faxes Bin' + '-' + this.datepipe.transform(new Date(), 'MM-dd-yyyy');

    this.dtInboundOptionsRecycleBin.buttons[0].filename =
    'Recycle Bin - Faxes Bin' + '-' + this.datepipe.transform(new Date(), 'MM-dd-yyyy');

    this.dtInboundOptionsngSwitchDefault.buttons[0].filename =
    'Default - Faxes Bin' + '-' + this.datepipe.transform(new Date(), 'MM-dd-yyyy');
  }
  ngOnDestroy(): void {
    window.removeEventListener('storage', this.funcListener);
    if (this.$subscription) {
      // Iterate through each subscription and unsubscribe
      this.$subscription.forEach((subscription) => {
        subscription.unsubscribe();
      });
    }
  }

  // Freelancer Senthil Code
  func(event): void {
    if (event.key == 'checkNewTabData') {
      if (event.newValue == 'NewTabDataSaved') {
        this.communicationService.triggerFunctionCall(this.selectedNode);
        this.reloadTableEvent.emit();
        this.selectNode.emit({ node: this.selectedNode });
      } else {
      }
    }
  }
    funct(event): void {
      if (event.key == 'updateBranch') {
        if (event.newValue == 'true') {
          this.getLocation();
          this.communicationService.triggerFunctionCall(this.selectedNode);
          this.reloadTableEvent.emit();
          this.selectNode.emit({ node: this.selectedNode });

        } else {
        }
      }
    }

  //To Load the Locations Menu
  loadLocations() {
    // this.locationList = this.branchList;
    //  if(this.locationList===undefined){
    this.getLocation();
    // }

    // const getBranchListd = this.branchService
    //   .getBranchList(this.tenantId)
    //   .subscribe((response) => {
    //     this.locationList = response;
    //   });
    // this.$subscription.push(getBranchListd);
  }
  getLocation(){
    this.locationList=[];
    const getBranchList = this.branchService
    .getBranchList(this.tenantId)
    .subscribe((response) => {
      this.locationList = response;
    });

  }
  //To Load the Bins Menu
  loadBins() {
    // this.inboundStatusService
    //   .getList(new PagedAndSortedResultRequestDto())
    //   .subscribe((response) => {
    const saveNotes = this.inboundStatusService
      .MoveToDetails()
      .subscribe((response) => {
        this.binList = response;
        // this.binList = response?.items;
        this.binList = this.binList.filter((item) => item.isActive == 1);
        // this.binList.sort((a, b) => {
        //   // Change 'name' to the property you want to sort by
        //   if (a.inboundStatus < b.inboundStatus) return -1;
        //   if (a.inboundStatus > b.inboundStatus) return 1;
        //   return 0;
        // });
      });
    this.$subscription.push(saveNotes);
  }

  ngAfterViewInit(): void {
    // this.dtInboundOptions = {
    //   responsive: true,
    //   pageLength: 5,
    //   lengthMenu: [5, 10, 25, 50, 100],
    // };
    this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.columns().every(function () {
        const that = this;
        $('input', this.footer()).on('keyup change', function () {
          if (that.search() !== this['value']) {
            that.search(this['value']).draw();
          }
        });
      });
      this.addPageNavigationControls();
    });
  }

  changePage(isNext:boolean){
    if(isNext)
      this.currentPage++;
    else
      this.currentPage--;
    this.nevigatePage.emit(this.currentPage);
  }

  fnGotoPage(){
    let pageValue = $("#txtGotoPage").val();
    let pageno = parseInt(pageValue.toString());
    if(pageno > 0 && this.currentPage !=  pageno && pageno <= this.totalPages)
    {
      this.nevigatePage.emit(pageno);
    }
  }


  fnColumnSelectionClick(){
    $(".buttons-columnVisibility").on('click', (event) => this.fnGetColumnVisiblity("ONE"));
  }

  fnGetColumnVisiblity(event){

    let visibleColumns = [];
    if(event == "ALL")
    {
      this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
        dtInstance.columns().visible(true);
      });
    }
    else
    {
      this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
        if(event == "DEFAULT")
          dtInstance.columns(':not(:lt(3),:last-child)').visible(false);

        var index = 0;
        dtInstance.columns().every(function () {
          const that = this;
          if(this.visible())
            visibleColumns.push(index)
          index++;
        });

      });
    }

    this.getVisibleColumns.emit(visibleColumns);
  }

  addPageNavigationControls(){
    let buttonsElement =  $(".dt-buttons")[0];

    // $("<button class='dt-button' id='btnDefaultClms' type='button'><span>Show Default Column</span></button>").appendTo(buttonsElement);
    // $("<button class='dt-button' id='btnAllClms' type='button'><span>Show All Column</span></button>").appendTo(buttonsElement);

    $("<span> : <span>").appendTo(buttonsElement);
    if(this.currentPage == 1)
    {
      $("<button disabled title='First Page' class='dt-button' id='btnFirstPage' type='button'><span>&lt;&lt;</span></button>").appendTo(buttonsElement);
      $("<button disabled class='dt-button' id='btnPrevPage' type='button'><span>&lt;</span></button>").appendTo(buttonsElement);
    }
    else
    {
      $("<button title='First Page' class='dt-button' id='btnFirstPage' type='button'><span>&lt;&lt;</span></button>").appendTo(buttonsElement);
      $("<button title='Previous Page' class='dt-button' id='btnPrevPage' type='button'><span>&lt;</span></button>").appendTo(buttonsElement);
     }

    $("<span> Page <span>").appendTo(buttonsElement);
    $("<input style='width:50px;' id='txtGotoPage' value='" + this.currentPage + "' type='text'/>").appendTo(buttonsElement);
    $("<span> of " + this.totalPages + " <span>").appendTo(buttonsElement);
    $("<button  class='dt-button' id='btnGoToPage'   type='button'><span>Go to</span></button>").appendTo(buttonsElement);

    if(this.currentPage >= this.totalPages)
    {
      $("<button disabled class='dt-button' id='btnNextPage'   type='button'><span>&gt;</span></button>").appendTo(buttonsElement);
      $("<button disabled title='Last Page'  class='dt-button' id='btnLastPage'  type='button'><span>&gt;&gt</span></button>").appendTo(buttonsElement);
    }
    else
    {
       $("<button title='Next Page'  class='dt-button' id='btnNextPage'  type='button'><span>&gt;</span></button>").appendTo(buttonsElement);
       $("<button title='Last Page'  class='dt-button' id='btnLastPage'  type='button'><span>&gt;&gt</span></button>").appendTo(buttonsElement);
    }

    $('#btnNextPage').on('click', (event) => this.changePage(true));
    $('#btnPrevPage').on('click', (event) => this.changePage(false));
    $(".buttons-colvis").on('click', (event) => this.fnColumnSelectionClick());
    $('#btnDefaultClms').on('click', (event) => this.fnGetColumnVisiblity("DEFAULT"));
    $('#btnAllClms').on('click', (event) => this.fnGetColumnVisiblity("ALL"));
    $('#btnGoToPage').on('click', (event) => this.fnGotoPage());
    $('#btnFirstPage').on('click', (event) => this.nevigatePage.emit(1));
    $('#btnLastPage').on('click', (event) => this.nevigatePage.emit(this.totalPages));
   }

  isEmpty(value: any): boolean {
    return (
      value === null ||
      value === undefined ||
      value === '' ||
      value === defaultGuid
    );
  }

  getInsuranceName(data: any) {
    let insName =
      data?.priPolicyId === null || data?.priPolicyId === ''
        ? data?.secPolicyId === null || data?.secPolicyId === ''
          ? data?.terPolicyId === null || data?.terPolicyId === ''
            ? '-'
            : data?.terPolicyName
          : data?.secPolicyName
        : data?.priPolicyName;
    return insName;
  }

  onRowDoubleClick(textToCopy) {
    this.copyTextToClipboard(textToCopy);
    this.showNotification('Text copied: ' + textToCopy);
  }

  showNotification(message: string): void {
    this.snackBar.open(message, 'Close', {
      duration: 3000, // Duration in milliseconds
      horizontalPosition: 'end', // Positioning the notification horizontally
      verticalPosition: 'bottom', // Positioning the notification vertically
    });
  }

  copyTextToClipboard(text: string): void {
    const textArea = document.createElement('textarea');
    textArea.value = text;
    document.body.appendChild(textArea);
    textArea.select();
    document.execCommand('Copy');
    textArea.remove();
  }

  // By Manikandan For collapse All Rows
  collapseAllRows(): void {
    const rows = this.myTable.rows({ page: 'current' }); // Get all rows on the current page
    rows.every((idx, data, node) => {
      const rowData = this.myTable.row(node).data();
      if (rowData) {
        this.myTable.row(node).child.hide(); // Hide the child row if it's expanded
      }
      return true; // Continue to the next row
    });
  }
  isOpened: boolean = false;

  handleTableCellClickV1(e: MouseEvent, data: any): void {
    this.inboundTableData.forEach((row) => {
      if (row !== data) {
        row.isOpen = false;
      }
    });
    data.isOpen = !data.isOpen;
    const target = e.target as HTMLElement;
    const tr = target.closest('tr');
    this.getDataTableData(tr, data.patientNotes);
  }

  //Row Click Event UnProcessed Table
  handleTableCellClick(e: MouseEvent, notesData: any): void {
    const target = e.target as HTMLElement;
    const tr = target.closest('tr');
    this.getDataTableData(tr, notesData);
  }
  //DataTable Data Get Method and Toggle Notes Row in UnProcessed Table
  async getDataTableData(tr: any, notesData: any): Promise<void> {
    this.myTable = await this.datatableElement.dtInstance;
    if (tr) {
      const row = this.myTable.row(tr);
      if (row.child.isShown()) {
        // This row is already open - close it
        row.child.hide();
      } else {
        // Open this row
        this.collapseAllRows(); // By Manikandan

        row.child(this.format(row.data(), notesData)).show();
      }
    }
  }
  //Add Dynamic Notes Row in UnProcessed Table
  format(d: any, notesData: any) {
    let returnString: string = '';
    notesData?.forEach(function (value, index) {
      returnString +=
      '' +
       value +'<h1 class="line" style="border-bottom: 1px solid rgb(196, 201, 196);Margin: 4px 0 9px 0px"></h1>'
    });
    return returnString;
  }
  // previewDocument(blobName: string, defaultDocumentId: string) {
  //   this.dialog.open(CommonDocViewerModalComponent, {
  //     data: {
  //       blobName: blobName,
  //       defaultDocumentId: defaultDocumentId,
  //     },
  //     minWidth: '67vw',
  //     width: '50vw',
  //     panelClass: 'fullscreen-dialog-panel',
  //   });
  //   this.dialog.afterAllClosed.subscribe((resposne) => {});
  // }
  // fnOpenNewTabPreviewDocument(blobName: string, defaultDocumentId: string){
  //   // Create a URL with query parameters
  //   // const urlWithParams = this.router.serializeUrl(
  //   //   this.router.createUrlTree(['/patient_preview_document'], {
  //   //     queryParams: { blobName: blobName, defaultDocumentId: defaultDocumentId }
  //   //   })
  //   // );
  //   // window.open(urlWithParams, '_blank');

  //     this.router.navigate(['/patient_preview_document/' + blobName + '/' + defaultDocumentId ]);

  //   // Open the URL in a new tab

  // }

  //Redirect to Manual Sorting Page
  redirectToManualSorting(patientId: string, documentId: string) {
    const documentBin = this.documentBinService
      .checkDocumentBinExists()
      .subscribe(
        (response) => {
          this.cookieService.set('sortingView', 'false');

          if (response === 'Success') {
            this.router.navigate([
              'documentSorting' + '/' + patientId + '/' + documentId,
            ]);
          }
        },
        (err) => {
          Swal.fire({
            icon: 'error',
            text: 'Please add the Document Type in Document Bin!',
          });
        }
      );
    this.$subscription.push(documentBin);
  }

  opensortModal(data) {
    if (data.isSorted != true) {
      const documentBin = this.documentBinService
        .checkDocumentBinExists()
        .subscribe(
          (response) => {
            this.cookieService.set('sortingView', 'false');
            this.cookieService.set('sortingBin', 'true');

            if (response === 'Success') {
              this.router.navigate([
                'documentSorting' + '/' + data.patientId + '/' + data.docId,
              ]);
            }
          },
          (err) => {
            Swal.fire({
              icon: 'error',
              text: 'Please add the Document Type in Document Bin!',
            });
          }
        );
      this.$subscription.push(documentBin);
    } else {
      this.cookieService.set('sortingView', 'true');
      this.cookieService.set('sortingBin', 'false');

      this.router.navigate([
        'documentSorting' + '/' + data.patientId + '/' + data.wipId,
      ]);
    }
  }

  // openInboundAllocationModal(
  //   docId: string,
  //   blobName: string,
  //   defaultDocumentId: string
  // ) {
  //   this.dialog.open(AssignDocumentModalComponent, {
  //     width: '100vw',
  //     height: '100vh',
  //     maxWidth: '100vw',
  //     maxHeight: '100vh',
  //     panelClass: 'fullscreen-dialog-panel',
  //     disableClose: true,
  //     data: {
  //       documentId: docId,
  //       blobName: blobName,
  //       defaultDocumentId: defaultDocumentId,
  //     },
  //   });
  //   this.dialog.afterAllClosed.subscribe((resposne) => {});
  // }
  // openNewSetup(docId: string,branchId:string, blobName: string, defaultFaxId: string) {
  //   this.dialog.open(NewsetupModalComponent, {
  //     width: '100vw',
  //     height: '100vh',
  //     maxWidth: '100vw',
  //     maxHeight: '100vh',
  //     panelClass: 'fullscreen-dialog-panel',
  //     disableClose: true,
  //     data: {
  //       documentId: docId,
  //       blobName: blobName,
  //       branchId:branchId,
  //       defaultFaxId: defaultFaxId,
  //       selectedNode: this.selectedNode,
  //     },
  //   });
  // }

  openOtherFaxModal(docId: string, faxId: string) {
    const dialogRef = this.dialog.open(WipAllocationModalV2Component, {
      maxWidth: '80%',
      minHeight: '60%',
      disableClose: true,
      data: {
        docId: docId,
        faxId: faxId,
        selectedNode: this.selectedNode,
      },
    });
    dialogRef.afterClosed().subscribe((a) => {});
  }

  getEncodedRouterLink(): string {
    console.log(this.selectedNode);
    if (!this.selectedNode) {
      return '';
    }
    // const encodedNode = encodeURIComponent(JSON.stringify(this.selectedNode));
    // return encodedNode;
    return '';
  }

  joinFaxModal(data) {
    const dialogRef = this.dialog.open(JoinFaxComponent, {
      disableClose: true,
      data: {
        faxID: data.defaultFaxId,
        docID: data.docId,
        documentName: data.documentName,
        branchID: data.branchId,
        selectedNode: this.selectedNode,
      },
    });
    dialogRef.afterClosed().subscribe((a) => {});
    // const dialogRef = this.dialog.open(JoinFaxComponent, {
    //   // height: '65%',
    //   // width: '45%',
    //   // data: { faxID: data },
    //   width: '60vw',
    //   height: '88vh',
    //   maxWidth: '60vw',
    //   maxHeight: '88vh',
    //   // panelClass: 'fullscreen-dialog-panel',
    //   data: {
    //     faxID: data.defaultFaxId,
    //     docID: data.docId,
    //     branchID: data.branchId,
    //     selectedNode: this.selectedNode,
    //   },
    //   disableClose: true,
    // });
    //dialogRef.afterClosed().subscribe((a) => {});
  }

  viewPatientLedger(patientId: string) {
    this.dialog.open(ViewPatientLedgerComponent, {
      width: '100vw',
      height: '100vh',
      maxWidth: '100vw',
      maxHeight: '100vh',
      panelClass: 'fullscreen-dialog-panel',
      disableClose: true,
      data: {
        patientId: patientId,
      },
    });
    this.dialog.afterAllClosed.subscribe((resposne) => {});
  }

  openViewNotesPopup(patientId: string, defaultFaxId: string, docId: string) {
    const config: MatDialogConfig = {
      disableClose: true,
      width: '70vw',
      maxWidth: '70vw',
      // height: '40vw',
      // maxHeight: '40vw',
      data: {
        patientId: patientId,
        pageType: 'Patient Note', // this.pageType, //Notes Table Popup Page
        isAddVisible: false,
        defaultFaxId: defaultFaxId,
        docId: docId,
      },
    };
    const dialogRef = this.dialog.open(PatientCommonNotesComponent, config);

    dialogRef.afterClosed().subscribe(
      () => {
        //this.getReminderTableData();
      },
      (err: HttpErrorResponse) => {
        const data: HttpErrorResponse = err;
        console.warn(data?.error?.error?.message);
      }
    );
  }

  openAddNotesPopUp(
    defaultFaxId: string,
    patientId: string,
    docId: string,
    isCallNote: boolean
  ) {
    const config: MatDialogConfig = {
      disableClose: true,
      minHeight: '30vh',
      minWidth: '50vw',
      data: {
        // claimId: '00000000-0000-0000-0000-000000000000',
        noteType: 'patient', // 'Order Note',
        notesId: '00000000-0000-0000-0000-000000000000',
        // orderId: '0b50be04-6c75-92d3-97c6-3a0e17ee6a18',
        patientId: patientId,
        defaultFaxId: defaultFaxId,
        isCallNote: isCallNote,
        isPending: false,
        docId: docId,
        inboundStatusShortCodeId: defaultGuid,
        selectedNode: this.selectedNode,
        addnotes: true,
      },
    };
    const dialogRef = this.dialog.open(AddNoteTabComponent, config);

    const closeDialog = dialogRef.afterClosed().subscribe(
      () => {
        // this.getReminderTableData();
      },
      (err) => {
        const data: HttpErrorResponse = err;
        console.warn(data?.error?.error?.message);
      }
    );
    this.$subscription.push(closeDialog);
  }
  openAddNotesPopUpV1(
    // defaultFaxId: string,
    // patientId: string,
    // docId: string,
    // isCallNote: boolean
    event: MouseEvent,
    data: any,
    isCallNote: boolean
  ) {
    const config: MatDialogConfig = {
      disableClose: true,
      minHeight: '30vh',
      minWidth: '50vw',
      data: {
        // claimId: '00000000-0000-0000-0000-000000000000',
        noteType: 'patient', // 'Order Note',
        notesId: '00000000-0000-0000-0000-000000000000',
        // orderId: '0b50be04-6c75-92d3-97c6-3a0e17ee6a18',
        patientId: data.patientId,
        defaultFaxId: data.defaultFaxId,
        isCallNote: isCallNote,
        isPending: false,
        docId: data.docId,
        inboundStatusShortCodeId: defaultGuid,
        selectedNode: this.selectedNode,
        addnotes: true,
        patientNotes: data.patientNotes,
      },
    };
    const dialogRef = this.dialog.open(AddNoteTabComponent, config);
    const closeDialog = dialogRef.afterClosed().subscribe(
      (patientNotes: any) => {
        if (patientNotes) {
          const topValues = [...patientNotes].sort((a, b) => b - a).slice(0, 5);
          data.patientNotes = topValues;
          this.collapseAllRows();
        }
      },
      (err) => {
        const data: HttpErrorResponse = err;
        console.warn(data?.error?.error?.message);
      }
    );
    this.$subscription.push(closeDialog);
  }

  AddNotesPopUp(
    defaultFaxId: string,
    patientId: string,
    docId: string,
    inboundStatusShortCodeId: string
  ) {
    const config: MatDialogConfig = {
      disableClose: true,
      data: {
        noteType: 'Fax',
        notesId: '00000000-0000-0000-0000-000000000000',
        patientId: patientId,
        isCallNote: false,
        isPending: true,
        docId: docId,
        defaultFaxId: defaultFaxId,
        inboundStatusShortCodeId: inboundStatusShortCodeId,
        selectedNode: this.selectedNode,
      },
    };
    const dialogRef = this.dialog.open(AddNoteTabComponent, config);
    const closeDialog = dialogRef.afterClosed().subscribe(
      () => {},
      (err) => {
        const data: HttpErrorResponse = err;
        console.warn(data?.error?.error?.message);
      }
    );
    this.$subscription.push(closeDialog);
  }
  // viewDocument() {
  //   // this.dialog.open(ViewDocumentComponent, {
  //     this.dialog.open(DocumentSortingComponent, {
  //     height: '75%',
  //     width: '80%',

  //     disableClose: true,
  //   });
  //   this.dialog.afterAllClosed.subscribe((resposne) => {});
  // }
  newSetupMoveTo(
    documentId: string,
    inboundStatusId: string,
    shortCodeId: string,
    isMarkConfirm: boolean,
    currentShortCodeId:string,
    MyFaxSelection?:number,
  ) {
    const dialogRef = this.dialog.open(NewsetupMovetoComponent, {
      minHeight: '33vh',
      minWidth: '52vw',
      disableClose: true,
      data: {
        docId: documentId,
        inboundStatusId: inboundStatusId,
        moveToId: 2,
        selectedNode: this.selectedNode,
        shortCodeId: shortCodeId,
        isMarkConfirm: isMarkConfirm,
        currentShortCode:currentShortCodeId,
      },
    });
    dialogRef.afterClosed().subscribe((a) => {
      if (a) {
        // shortCodeId !== this.inboundStatusIds.missingDocuments &&
        if( MyFaxSelection!==0){
          setTimeout(() => {
            this.isRefresh = true;
          }, 1);
        const indexToRemove = this.inboundTableData.findIndex(
          (item) => item.docId === documentId
        );
        if (indexToRemove !== -1) {
          const removedItem = this.inboundTableData.splice(indexToRemove, 1);
          if(this.tableData.length===0){
            setTimeout(() => {
              this.isRefresh = true;
            }, 1);
             this.calllogTableData=[];
           this.patientForm = this.formBuilder.group({
            patientName: ['-'],
            address: ['-'],
            chartNo: ['-'],
            dob: ['-'],
            city: ['-'],
            state: ['-'],
            zip: ['-'],
            homeNo: ['-'],
            cell: ['-'],
            workNo: ['-'],
            email: ['-'],
          });
          setTimeout(() => {
            this.isRefresh = false;
          }, 1);
          }else{
              this.calllogTableData=[];
           this.patientForm = this.formBuilder.group({
            patientName: ['-'],
            address: ['-'],
            chartNo: ['-'],
            dob: ['-'],
            city: ['-'],
            state: ['-'],
            zip: ['-'],
            homeNo: ['-'],
            cell: ['-'],
            workNo: ['-'],
            email: ['-'],
          });
          }
        }
        setTimeout(() => {
          this.isRefresh = false;
        }, 1);
      }
      if(MyFaxSelection===0){
        this.reloadTableEvent.emit();
      }
      }
    });
  }
  // Document Check List Popup Open
  openCheckListDialog(data: any, isViewCheckList: boolean) {
    const dialogRef = this.dialog.open(DocumentCheckListComponent, {
      // height: '80%',
      width: '85%',
      disableClose: true,
      data: {
        isViewCheckList:isViewCheckList,
        documentId: data.docId,
        faxId: data.defaultFaxId,
      },
    });

    const closeDialog = dialogRef.afterClosed().subscribe(
      (patientNotes: any) => {
        if (patientNotes) {
          if (patientNotes !== -1) {
            data.patientNotes = patientNotes;
            this.collapseAllRows();
          }
        }
      },
      (err) => {
        const data: HttpErrorResponse = err;
        console.warn(data?.error?.error?.message);
      }
    );
  }

  // Document Check List can Update
  checkListModel(data) {
    this.openCheckListDialog(data, false);
  }
  // Document Check List cannot Update
  checkListModelIsView(data) {
    this.openCheckListDialog(data, true);
  }

  ViewcheckListModel() {
    const dialogRef = this.dialog.open(InsuranceCheckListComponent, {
      height: '75%',
      width: '80%',
      disableClose: true,
    });
    const closeModal = dialogRef.afterClosed().subscribe(() => {});
  }

  //Delete the Fax
  deleteFax(docId: string) {
    Swal.fire({
      title: 'Are you sure you want to Delete?',
      text: 'It will be moved to the Recycle Bin!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#34c38f',
      cancelButtonColor: '#f46a6a',
      confirmButtonText: 'Yes, delete it!',
    }).then((result) => {
      if (result.value) {
        const deleteFax = this.InboundDocService.delete(docId).subscribe(
          (response) => {
            this.reloadParentPage();
            this.toaster.success(
              'Fax has been deleted successfully',
              'Success'
            );
          },
          (err) => {
            this.reloadParentPage();
            this.toaster.success(
              'Fax has been deleted successfully',
              'Success'
            );
          }
        );
        this.$subscription.push(deleteFax);
      }
    });
  }

  undeleteFax(docId: string) {
    Swal.fire({
      title: 'Are you sure you want to Restore?',
      text: 'You are about to restore this data!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#34c38f',
      cancelButtonColor: '#f46a6a',
      confirmButtonText: 'Yes, restore it!',
    }).then((result) => {
      if (result.value) {
        // Instead of deleting, you should implement the logic to restore the fax here.
        const undeleteFax = this.InboundDocService.undoDeleteByInBoundId(
          docId
        ).subscribe(
          (response) => {
            this.reloadParentPage();
            this.toaster.success(
              'Fax has been restored successfully',
              'Success'
            );
          },
          (err) => {
            this.reloadParentPage();
            this.toaster.success(
              'Fax has been restored successfully',
              'Success'
            );
          }
        );
        this.$subscription.push(undeleteFax);
      }
    });
  }

  //Update Location Api Call Method
  updateLocation(docId: string, branchId: string) {
    const updateLocation = this.InboundDocService.updateLocation(
      docId,
      branchId
    ).subscribe(
      (response) => {
        this.toaster.success(
          'Location has been updated successfully.',
          'Success'
        );
        this.reloadParentPage();
      },
      (err) => {
        this.reloadParentPage();
        // this.reloadTreeEvent.emit();
        this.toaster.success(
          'Location has been updated successfully.',
          'Success'
        );
      }
    );
    this.$subscription.push(updateLocation);
  }
  //Update Inbound Status Api Call Method
  updateInboundStatus(
    docId: string,
    inboundStatusShortCodeId: string,
    inboundStatusId: string
  ) {
    const updateInboundStatus =
      this.InboundDocService.updateInboundStatusByInboundDocumentIdAndSShortCodeAndSNotes(
        docId,
        inboundStatusShortCodeId,
        inboundStatusId,
        ''
      ).subscribe(
        (response) => {
          if (response['statusCode'] == 200) {
            this.toaster.success(
              'Bin has been updated successfully',
              'Success'
            );
            this.reloadParentPage();
          } else {
            this.toaster.warning(response['message'], 'Warning');
          }
        },
        (err) => {
          // console.warn('Error', err);

          // Check if the error has the expected structure
          if (err.error && err.error.message) {
            // Parse the JSON error message and extract the specific message
            try {
              const errorMessageObject = JSON.parse(err.error.message);
              const specificErrorMessage = errorMessageObject.error.message;

              // Print the specific error message

              // Show a warning toaster with the specific error message
              this.toaster.warning(
                'Bin has not updated: Reason: ' + specificErrorMessage,
                'Warning'
              );
            } catch (parseError) {
              // Log a warning if there is an issue parsing the error message
              // console.warn('Error parsing error message:', parseError);
              this.toaster.warning(
                'Bin has not updated: Reason: ' + parseError,
                'Warning'
              );
            }
          } else {
            // If the error structure is unexpected, log the entire error message
            // console.warn('Unexpected error structure:', err);
            this.toaster.error('Bin has not updated: Error!!!. ', 'Error');
          }
        }
      );
    this.$subscription.push(updateInboundStatus);
  }

  // MarkConfirmed(inBoundId: string) {
  //   const getInboundTableData = this.InboundDocService.updateMarkconfirmed(
  //     inBoundId,
  //     '',
  //   ).subscribe(
  //     (response) => {
  //       this.reloadParentPage();
  //       this.toaster.success('Marked as confirmed successfully.', 'Success');
  //     },
  //     (err) => {
  //       this.reloadParentPage();
  //       this.toaster.success('Marked as confirmed successfully.', 'Success');
  //     }
  //   );
  //   this.$subscription.push(getInboundTableData);
  //   // subscribe((response) => {
  //   //   this.reloadParentPage();
  //   //   this.inboundTableData = response;
  //   // });
  //   // this.$subscription.push(getInboundTableData);
  //   // this.toaster.success('Marked Confirm Successfully', 'Success');
  // }

  reloadParentPage() {
    this.reloadTableEvent.emit();
    this.selectNode.emit({ node: this.selectedNode });
  }

  //To View The Policy
  EditPolicy(policy: any) {
    if (policy.priPolicyId === defaultGuid) {
      if (policy.secPolicyId === defaultGuid) {
        this.policyId = policy.secPolicyId;
      } else {
        this.policyId = policy.terPolicyId;
      }
    } else {
      this.policyId = policy.priPolicyId;
    }
    if (policy.priVerificationId === defaultGuid) {
      if (policy.secVerificationId === defaultGuid) {
        this.verifyId = policy.secVerificationId;
      } else {
        this.verifyId = policy.terVerificationId;
      }
    } else {
      this.verifyId = policy.priVerificationId;
    }
    if (
      policy.priVerificationStatus === null ||
      policy.priVerificationStatus === ''
    ) {
      if (
        policy.secVerificationStatus === null ||
        policy.secVerificationStatus === ''
      ) {
        this.verifyStatus = policy.secVerificationStatus;
      } else {
        this.verifyStatus = policy.terVerificationStatus;
      }
    } else {
      this.verifyStatus = policy.priVerificationStatus;
    }
    const dialogRef = this.dialog.open(PatientAddPolicyTabComponent, {
      disableClose: true,
      data: {
        patientId: policy.patientId,
        policyId: this.policyId,
        documentId: policy.docId,
        wipId: policy.wipId ?? defaultGuid,
        selectedNode: this.selectedNode,
        verificationId: this.verifyId,
        verificationStatus: this.verifyStatus,
        isDashboard:true,
        defaultFaxId:policy?.defaultFaxId
      },
    });
    const aftClosed = dialogRef.afterClosed().subscribe(
      (result) => {
        this.patientIdEmitters.next(policy.patientId);

        // policy.patientId !== defaultGuid && this.getTableData();
      },
      (err) => {
        const data: HttpErrorResponse = err;
        console.warn(data?.error?.error?.message);
      }
    );
    this.$subscription.push(aftClosed);
  }

  openPolicyModal(patientId: string, policyId: string, docId: string) {
    const dialogRef = this.dialog.open(PatientAddPolicyTabComponent, {
      maxWidth: '80%',
      minHeight: '60%',
      disableClose: true,
      data: {
        patientId: patientId,
        policyId: policyId,
        documentId: docId,
        selectedNode: this.selectedNode,
      },
    });
    const closeDialog = dialogRef.afterClosed().subscribe(
      (result) => {
        result !== '' &&
          result !== null &&
          result !== undefined &&
          this.patientIdEmitter.emit(result);
        patientId !== '' && patientId !== null && patientId !== undefined;
        //&& this.getPolicyDatas(this.patientId);
      },
      (err) => {
        const data: HttpErrorResponse = err;
        console.warn(data?.error?.error?.message);
      }
    );
    this.$subscription.push(closeDialog);
    // Pass the dialogRef to openVerificationModal
    // this.openVerificationModal(dialogRef,patientId,policyId,docId);
  }

  OpenMapUserModel(event: MouseEvent, data: any) {
    const vconfig: MatDialogConfig = {
      disableClose: true,
      minHeight: '30vh',
      minWidth: '50vw',
      data: {
        patientNotes: data.patientNotes,
        docId: data?.docId,
        selectedNode:this.selectedNode
      },
    };
    const dialogRef = this.dialog.open(SendToAnotherUserComponent, vconfig);
    const closeDialog = dialogRef.afterClosed().subscribe(
      (patientNotes: any) => {
        const vpatientNotes = JSON.parse(patientNotes);
        if (vpatientNotes) {
          data.patientNotes = vpatientNotes.notes;
          data.csrName = vpatientNotes.csrName;
          this.collapseAllRows();
        }
      },
      (err) => {
        const data: HttpErrorResponse = err;
        console.warn(data?.error?.error?.message);
      }
    );
    this.$subscription.push(closeDialog);
  }

  viewVerificationVerifyBinById(data: any): void {
    if (data.priPolicyId === defaultGuid) {
      if (data.secPolicyId === defaultGuid) {
        this.policyId = data.secPolicyId;
      } else {
        this.policyId = data.terPolicyId;
      }
    } else {
      this.policyId = data.priPolicyId;
    }
    if (data.priVerificationId === defaultGuid) {
      if (data.secVerificationId === defaultGuid) {
        this.verifyId = data.secVerificationId;
      } else {
        this.verifyId = data.terVerificationId;
      }
    } else {
      this.verifyId = data.priVerificationId;
    }
    if (
      data.priVerificationStatus === null ||
      data.priVerificationStatus === ''
    ) {
      if (
        data.secVerificationStatus === null ||
        data.secVerificationStatus === ''
      ) {
        this.verifyStatus = data.secVerificationStatus;
      } else {
        this.verifyStatus = data.terVerificationStatus;
      }
    } else {
      this.verifyStatus = data.priVerificationStatus;
    }
    const dialogRef = this.dialog.open(PatientManualVerificationTabComponent, {
      data: {
        patientId: data.patientId,
        verificationId: this.verifyId,
        policyId: this.policyId,
        documentId: data.docId,
        inboundShortCode: data.inboundShortCode,
        selectedNode: this.selectedNode,
        // savehidden: true,
        isViewVerification: true,
        verificationStatus: this.verifyStatus,
        defaultFaxId:data?.defaultFaxId
      },
      disableClose: true
    });
    const closeDialog = dialogRef.afterClosed().subscribe((result) => {
      // dialogRef?.close(result);
    });
    this.$subscription.push(closeDialog);
  }
  viewVerificationById(data: any): void {
    if (data.priPolicyId === defaultGuid) {
      if (data.secPolicyId === defaultGuid) {
        this.policyId = data.secPolicyId;
      } else {
        this.policyId = data.terPolicyId;
      }
    } else {
      this.policyId = data.priPolicyId;
    }
    if (data.priVerificationId === defaultGuid) {
      if (data.secVerificationId === defaultGuid) {
        this.verifyId = data.secVerificationId;
      } else {
        this.verifyId = data.terVerificationId;
      }
    } else {
      this.verifyId = data.priVerificationId;
    }
    const dialogRef = this.dialog.open(PatientManualVerificationTabComponent, {
      data: {
        patientId: data.patientId,
        verificationId: this.verifyId,
        policyId: this.policyId,
        documentId: data.docId,
        inboundShortCode: data.inboundShortCode,
        savehidden: true,
        isViewVerification: true,
        defaultFaxId:data?.defaultFaxId
      },
      disableClose: true
    });
    const closeDialog = dialogRef.afterClosed().subscribe((result) => {
      dialogRef?.close(result);
    });
    this.$subscription.push(closeDialog);
  }
  // viewVerificationById(
  //   patientId: string,
  //   policyId: string,
  //   verificationId: string
  // ): void {
  //   const dialogRef = this.dialog.open( PatientManualVerificationViewComponent , { // PatientManualVerificationTabComponent
  //     data: {
  //       patientId: patientId,
  //       policyId: policyId,
  //       verificationId: verificationId,
  //       savehidden: true,
  //       isViewVerification:true
  //     },
  //   });
  //   const closeDialog = dialogRef.afterClosed().subscribe((result) => {
  //     // this.getTableDataBasedOnPatientId(this.patientId);
  //     dialogRef?.close(result);
  //   });
  //   this.$subscription.push(closeDialog);
  // }
  doprecert(data: any) {
    if (data.priVerificationId === defaultGuid) {
      if (data.secVerificationId === defaultGuid) {
        this.verifyId = data.secVerificationId;
      } else {
        this.verifyId = data.terVerificationId;
      }
    } else {
      this.verifyId = data.priVerificationId;
    }
    const dialogRef = this.dialog.open(PatientAuthSaveUpdateFormComponent,{
      data: {
        documentId: data.docId,
        defaultFaxId:data?.defaultFaxId
      },
      disableClose: true
    });
    const componentInstance = dialogRef.componentInstance;
    componentInstance.patientId = data.patientId;
    componentInstance.verificationId = data.verifyId;
    componentInstance.authorizationId = data.authorizationId;
    componentInstance.docId = data.docId;
    componentInstance.faxId = data.faxId;
    componentInstance.isView = true;
    componentInstance.isSupplies = true;
    componentInstance.selectedNode = this.selectedNode;
    componentInstance.chartId = data.chartId;
    componentInstance.authStatusName = data.authStatusName;
  }
  viewprecert(data) {
    if (data.priVerificationId === defaultGuid) {
      if (data.secVerificationId === defaultGuid) {
        this.verifyId = data.secVerificationId;
      } else {
        this.verifyId = data.terVerificationId;
      }
    } else {
      this.verifyId = data.priVerificationId;
    }
    const dialogRef = this.dialog.open(PatientAuthSaveUpdateFormComponent,{
      data: {
        documentId: data.docId,
        defaultFaxId:data?.defaultFaxId
      },
      disableClose: true
    });
    const componentInstance = dialogRef.componentInstance;
    componentInstance.patientId = data.patientId;
    componentInstance.verificationId = data.verifyId;
    componentInstance.authorizationId = data.authorizationId;
    componentInstance.docId = data.docId;
    componentInstance.faxId = data.faxId;
    componentInstance.isView = true;
    componentInstance.resethidden = true;
    componentInstance.isSupplies = true;
    componentInstance.selectedNode = this.selectedNode;
    componentInstance.chartId = data.chartId;
    componentInstance.authStatusName = data.authStatusName;
    componentInstance.savehide = false;
  }

  viewprecertV1(data) {
    if (data.priVerificationId === defaultGuid) {
      if (data.secVerificationId === defaultGuid) {
        this.verifyId = data.secVerificationId;
      } else {
        this.verifyId = data.terVerificationId;
      }
    } else {
      this.verifyId = data.priVerificationId;
    }
    const dialogRef = this.dialog.open(PatientAuthSaveUpdateFormComponent,{
      data: {
        documentId: data.docId,
        defaultFaxId:data?.defaultFaxId
      },
      disableClose: true
    });
    const componentInstance = dialogRef.componentInstance;
    componentInstance.patientId = data.patientId;
    componentInstance.verificationId = data.verifyId;
    componentInstance.authorizationId = data.authorizationId;
    componentInstance.docId = data.docId;
    componentInstance.faxId = data.faxId;
    componentInstance.isView = true;
    componentInstance.resethidden = true;
    componentInstance.isSupplies = true;
    componentInstance.selectedNode = this.selectedNode;
    componentInstance.chartId = data.chartId;
    componentInstance.authStatusName = data.authStatusName;
    componentInstance.savehide = true;

  }

  openSplitDocumentModal(
    docId: string,
    defaultDocumentId: string,
    faxId: string,
    blobName : string,
  ) {
    this.dialog.open(SplitDocumentModalComponent, {
      width: '100vw',
      height: '100vh',
      maxWidth: '100vw',
      maxHeight: '100vh',
      panelClass: 'fullscreen-dialog-panel',
      disableClose: true,
      data: {
        documentId: docId,
        defaultDocumentId: defaultDocumentId,
        selectedNode: this.selectedNode,
        faxId: faxId,
        blobName : blobName
      },
    });
  }
  isStatUpdate(event: any, data: any): void {
    this.isRefresh=true;
    const isStatUpdate = this.InboundDocService.STATupdate(
      data.docId
    ).subscribe(
      (response) => {
        this.toaster.success('STAT has been updated successfully', 'Success');
        data.successfulStatUpdate = event.checked ? true : false;
        this.isRefresh=false;
      },
      (err) => {
        this.isRefresh=false;
        this.toaster.error('STAT update has failed', 'Error');
        this.reloadParentPage();
      }
    );
    // Unsubscribe from the observable to prevent memory leaks
    this.$subscription.push(isStatUpdate);
  }
  callback(docId: string, shortCodeId: string,currentShortCodeId:string) {
    const dialogRef = this.dialog.open(CallbackComponent, {
      minHeight: '33vh',
      minWidth: '52vw',
      data: {
        documentId: docId,
        shortCodeId: shortCodeId,
        selectedNode: this.selectedNode,
        currentShortCodeId:currentShortCodeId
      },
      disableClose: true
    });
    dialogRef.afterClosed().subscribe(
      () => {},
      (err) => {
        const data: HttpErrorResponse = err;
        console.warn(data?.error?.error?.message);
      }
    );
  }

  getValueColor(value: number): string {
    if (value == 2) {
      return 'red';
    } else if (value == 1) {
      return 'green';
    }
  }

  passInputValue(event: any) {
    const value = event.target.value;
    if (!this.isEmpty(value) && value != '-') {
      this.onRowDoubleClick(value);
    }
  }

  fnSelectedRow(docid: string, patientId: string) {
    this.tblSelectedFaxId = docid;
    if (!this.isEmpty(patientId) && this.patientId !== patientId) {
      this.patientId = patientId;
      this.loadPatientDetails(patientId);
    }
  }

  loadPatientDetails(patientId: string) {
    this.isLoading = true;
    const loadPatientDetails =
      this.PatientPersonalService.getBinPatientDetailsByGPatient(
        patientId
      ).subscribe(
        (res) => {
          this.isLoading = false;
          this.patientForm = this.formBuilder.group({
            patientName: [res?.patientName],
            address: [res?.patientAddress],
            chartNo: [res?.chartId],
            dob: [this.getDateWithOutTime(new Date(res?.dob))],
            city: [res?.city],
            state: [res?.state],
            zip: [res?.zipCode],
            homeNo: [res?.homePhoneNo],
            cell: [res?.cellNo],
            workNo: [res?.workPhoneNo],
            email: [res?.email],
          });
          this.calllogTableData = res['callDetails'];
        },
        (err) => {
          this.patientForm = this.formBuilder.group({
            patientName: ['-'],
            address: ['-'],
            chartNo: ['-'],
            dob: ['-'],
            city: ['-'],
            state: ['-'],
            zip: ['-'],
            homeNo: ['-'],
            cell: ['-'],
            workNo: ['-'],
            email: ['-'],
          });
          const data: HttpErrorResponse = err;
          console.warn(data?.error?.error?.message);
          this.isLoading = false;
        }
      );
    this.$subscription.push(loadPatientDetails);
  }

  getDateWithOutTime(date: Date) {
    const currentDate = date;
    const formattedDate = `${(currentDate.getMonth() + 1)
      .toString()
      .padStart(2, '0')}/${currentDate
      .getDate()
      .toString()
      .padStart(2, '0')}/${currentDate.getFullYear()}`;
    return formattedDate;
  }

  //Lock or Unloack Check Box
  addRemoveChk(event: MatSlideToggleChange,data:any) {
    this.disableLockCheckbox = true; // Disable the checkbox until the lock update is completed
    this.lockFile$?.unsubscribe();
    const isLocked = event.checked ? true : false;
    // const isLockUpdate = this.InboundDocService.updatesCsrLockForFaxByGDocId(data.docId).subscribe(
    const isLockUpdate = this.InboundDocService.lockTheFaxWithParamsByFaxIdAndIsLocked(data.docId,isLocked).subscribe(
      (value) => {
        this.toaster.success('Lock Update Successfully', 'Success');
        // data.isCsrFaxLockedStatus = event.checked ? true : false;
        data.isCsrFaxLockedStatus = event.checked ? 1 : 0;
        data.csrName = value?.csrName;
        setTimeout(() => {
          this.disableLockCheckbox = false; // Enable the checkbox
        }, 1000);
      },
      (err) => {
        const data: HttpErrorResponse = err;
        this.toaster.error(data?.error?.error?.message);
        this.reloadTableEvent.emit();
        setTimeout(() => {
          this.disableLockCheckbox = false; // Enable the checkbox
        }, 1000);
      }
    );
    this.lockFile$ = isLockUpdate;
  }

//Attach File Modal
  addDocumentType(patientId: string, wipId: string,defaultFaxId: string): void {
    const dialogData = {
      constructorpatientId: patientId || defaultGuid,
      constructortenantId: this.tenantId || defaultGuid,
      selectedWipId:wipId || defaultGuid,
      isPatientList: false,
      isPatientDisable:true,
      isDashboard:true,
      defaultFaxId:defaultFaxId,
    };
    const dialogRef = this.dialog.open(
      PatientCommonDocumentMedicareUploadTabComponent,
      {
        minHeight: '33vh',
        minWidth: '75vw',
        data: dialogData,
        disableClose: true,
      }
    );

    dialogRef.afterClosed().subscribe(
      (result) => {
        if (result && result.changesMade) {
          this.reloadTableEvent.emit();
        }
      },
      (err) => {
        const data: HttpErrorResponse = err;
      }
    );
  }
}
