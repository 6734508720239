<!-- Exchange / Pickup List -->
<div class="container-fluid">
    <div class="row bg">
      <div class="col-12">
        <div
          class="d-flex align-items-center justify-content-between mb-3"
          style="margin-top: -15px"
        >
        <h3 class="font-weight-bolder mb-0"><b>Patient / Customer Service Pool</b></h3>
          <div class="page-title-box page-title-right">
            <ol class="breadcrumbs m-0 align-items-center">
              <form [formGroup]="branchForm">
                <mat-form-field class="pr-3">
                  <mat-label>Branch</mat-label>
                  <mat-select #selectBranch formControlName="drpBranch" multiple [(value)]="organizationUnitName">
                    <mat-option>
                      <ngx-mat-select-search
                        placeholderLabel="Search"
                        noEntriesFoundLabel="No Matches found"
                        formControlName="txtBranch"
                      ></ngx-mat-select-search>
                    </mat-option>
    
                    <mat-option #selectAllBranch  value="0">All</mat-option>
    
                    <mat-option *ngFor="let branch of locationList"
                    [value]="branch.id">
                    {{ branch.organizationUnitName }}
                    </mat-option>
                  </mat-select>
                </mat-form-field> 
                <button
                 mat-button
                 class="mr-2 buttonColor">
                  Export
                </button>
              </form>
            </ol>
          </div>
        </div>
      </div>
  
      <div class="col-lg-12 pb-3">
        <div class="table-responsive example-container mat-elevation-z4 pt-0 mt-0">
          <form class="m-2">
            <table datatable matTableExporter #exporter="matTableExporter"  matSort class="pt-0 mt-0">
          <!-- <table
            id="tbl"
            datatable
            style="width: 100%"
            class="row-border hover"
          > -->
            <thead>
              <tr>
                <th>#</th>
                <th>Options</th>
                <th>Fax Id</th>
                <th>Pages</th>
                <th>Received</th>
                <th>Modified</th>
                <th>PatientId</th>
                <th>Patient</th>
                <th>Primary Ins</th>
                <th>Practice</th>
                <th>Provider</th>
                <th>Modified By</th>
                <th>INS Group</th>
                <th>Documents</th>
                <th>CSR</th>
                <th>TAT</th>
                <th>STAT</th>
                <th>Restart</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let data of inboundTableData">
                <td
                  class="dt-control"
                  (click)="handleTableCellClick($event, data.notes)"
                ></td>
                <td>
                  <mat-icon
                    style="cursor: pointer !important"
                    >more_vert</mat-icon
                  >
                </td>
                <td>{{ data.faxId }}</td>
                <td>{{ data.pages }}</td>
                <td>{{ data.received }}</td>
                <td>{{ data.modified }}</td>
                <td>{{ data.patientIdChartNo }}</td>
                <td>{{ data.patient }}</td>
                <td>{{ data.primaryIns }}</td>
                <td>{{ data.practice }}</td>
                <td>{{ data.provider }}</td>
                <td>{{ data.modifiedBy }}</td>
                <td>{{ data.insGroup }}</td>
                <td>{{ data.provider }}</td>
                <td>{{ data.csr }}</td>
                <td>19</td>
    
                <td><mat-checkbox> </mat-checkbox></td>
                <td><mat-checkbox> </mat-checkbox></td>
              </tr>
            </tbody>
            <!-- Footer -->
            <tfoot>
              <tr>
                <th>#</th>
                <th></th>
                <th>
                  <input
                    matInput
                    class="custom-search-input"
                    type="text"
                    placeholder="Fax Id"
                    name="search-faxid"
                  />
                </th>
                <th>
                  <input
                    matInput
                    type="text"
                    class="custom-search-input"
                    placeholder="Pages"
                    name="search-pages"
                  />
                </th>
                <th>
                  <input
                    matInput
                    type="text"
                    class="custom-search-input"
                    placeholder="Received"
                    name="search-received"
                  />
                </th>
                <th>
                  <input
                    matInput
                    type="text"
                    class="custom-search-input"
                    placeholder="Modified"
                    name="search-modified"
                  />
                </th>
                <th>
                  <input
                    matInput
                    class="custom-search-input"
                    type="text"
                    placeholder="PatientId"
                    name="search-patientId"
                  />
                </th>
                <th>
                  <input
                    matInput
                    class="custom-search-input"
                    type="text"
                    placeholder="Patient"
                    name="search-patient"
                  />
                </th>
                <th>
                  <input
                    matInput
                    class="custom-search-input"
                    type="text"
                    placeholder="Primary Ins"
                    name="search-primaryIns"
                  />
                </th>
                <th>
                  <input
                    matInput
                    class="custom-search-input"
                    type="text"
                    placeholder="Practice"
                    name="search-practice"
                  />
                </th>
                <th>
                  <input
                    matInput
                    class="custom-search-input"
                    type="text"
                    placeholder="Provider"
                    name="search-provider"
                  />
                </th>
                <th>
                  <input
                    matInput
                    class="custom-search-input"
                    type="text"
                    placeholder="Modified By"
                    name="search-modifiedBy"
                  />
                </th>
                <th>
                  <input
                    matInput
                    class="custom-search-input"
                    type="text"
                    placeholder="INS Group"
                    name="search-insGroup"
                  />
                </th>
                <th>
                  <input
                    matInput
                    class="custom-search-input"
                    type="text"
                    placeholder="Documents"
                    name="search-documents"
                  />
                </th>
                <th>
                  <input
                    matInput
                    class="custom-search-input"
                    type="text"
                    placeholder="CSR"
                    name="search-csr"
                  />
                </th>
                <th>
                  <input
                    matInput
                    class="custom-search-input"
                    type="text"
                    placeholder="TAT"
                    name="search-tat"
                  />
                </th>
                <th></th>
                <th></th>
              </tr>
            </tfoot>
          </table>
          </form>
        </div>
        <!-- </ng-container> -->
      </div>
    </div>
  </div>
  