import type { BillingSummery, CheckListDTO, ClaimInfoDetailsDTO, ClaimProcessListDTO, ClaimStatusSearchDTO, CreateUpdateClaimInfoDetailsDTO, CreateUpdateClaimProcessDTO, InputCheckNumberDTO, MyQueueCheckNumberDTO, clsZirmidChangeHealthResponseDTO } from './dto/models';
import { RestService } from '@abp/ng.core';
import type { PagedAndSortedResultRequestDto, PagedResultDto } from '@abp/ng.core';
import { Injectable } from '@angular/core';
import type { ClaimBatchProcessRequestDTO } from '../x12/dto/models';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ClaimInfoDetailsService {
  apiName = 'ClaimProcessingManagement';

  create = (input: CreateUpdateClaimInfoDetailsDTO) =>
    this.restService.request<any, ClaimInfoDetailsDTO>({
      method: 'POST',
      url: '/api/claimProcessingApp/claim-info-details',
      body: input,
    },
      { apiName: this.apiName });

  delete = (id: string) =>
    this.restService.request<any, void>({
      method: 'DELETE',
      url: `/api/claimProcessingApp/claim-info-details/${id}`,
    },
      { apiName: this.apiName });

  get = (id: string) =>
    this.restService.request<any, ClaimInfoDetailsDTO>({
      method: 'GET',
      url: `/api/claimProcessingApp/claim-info-details/${id}`,
    },
      { apiName: this.apiName });

  getList = (input: PagedAndSortedResultRequestDto) =>
    this.restService.request<any, PagedResultDto<ClaimInfoDetailsDTO>>({
      method: 'GET',
      url: '/api/claimProcessingApp/claim-info-details',
      params: { skipCount: input.skipCount, maxResultCount: input.maxResultCount, sorting: input.sorting },
    },
      { apiName: this.apiName });

  update = (id: string, input: CreateUpdateClaimInfoDetailsDTO) =>
    this.restService.request<any, ClaimInfoDetailsDTO>({
      method: 'PUT',
      url: `/api/claimProcessingApp/claim-info-details/${id}`,
      body: input,
    },
      { apiName: this.apiName });

  claimStatusSearchByDefaultClaimNumberAndDefaultOrderNumberAndDefaultPatientNumberAndSPatientNameAndBilledQuantityAndBilledAmountAndPayerNameAndProviderNameAndCurrentBalanceAndClaimServiceDateAndClaimStatusAndWayStarClaimStatusAndPostingCountAndClaimProcessedDate = (defaultClaimNumber: string, defaultOrderNumber: string, defaultPatientNumber: string, sPatientName: string, billedQuantity: string, billedAmount: string, payerName: string, providerName: string, currentBalance: string, claimServiceDate: string, claimStatus: string, wayStarClaimStatus: string, postingCount: string, claimProcessedDate: string) =>
    this.restService.request<any, ClaimStatusSearchDTO[]>({
      method: 'POST',
      url: '/api/claimProcessingApp/claim-info-details/claim-status-search',
      params: { defaultClaimNumber, defaultOrderNumber, defaultPatientNumber, sPatientName, billedQuantity, billedAmount, payerName, providerName, currentBalance, claimServiceDate, claimStatus, wayStarClaimStatus, postingCount, claimProcessedDate },
    },
      { apiName: this.apiName });

  getClaimListByPatientAndOrderIdByGPatientIdAndGOrderId = (gPatientId: string, gOrderId: string) =>
    this.restService.request<any, ClaimInfoDetailsDTO[]>({
      method: 'GET',
      url: '/api/claimProcessingApp/claim-info-details/get-claim-list-by-patient-and-order-id',
      params: { gPatientId, gOrderId },
    },
      { apiName: this.apiName });

  getClaimProcessSearchByProviderIdByProviderAndSDefaultSaleOrderAndSDefaultPatientAndSPatientNameAndSTotalBilledAndSInsuranceName = (provider: string, sDefaultSaleOrder: string, sDefaultPatient: string, sPatientName: string, sTotalBilled: string, sInsuranceName: string) =>
    this.restService.request<any, ClaimProcessListDTO[]>({
      method: 'GET',
      url: '/api/claimProcessingApp/claim-info-details/get-claim-process-search-by-provider-id',
      params: { provider, sDefaultSaleOrder, sDefaultPatient, sPatientName, sTotalBilled, sInsuranceName },
    },
      { apiName: this.apiName });

  processManualClaimByInputAndGPatientIdAndGOrderId = (input: ClaimBatchProcessRequestDTO, gPatientId: string, gOrderId: string) =>
    this.restService.request<any, string>({
      method: 'POST',
      responseType: 'text',
      url: '/api/claimProcessingApp/claim-info-details/process-manual-claim',
      params: { gPatientId, gOrderId },
      body: input,
    },
      { apiName: this.apiName });

  processZirmedClaimByInputAndGPatientIdAndGOrderId = (input: ClaimBatchProcessRequestDTO, gPatientId: string, gOrderId: string) =>
    this.restService.request<any, string>({
      method: 'POST',
      responseType: 'text',
      url: '/api/claimProcessingApp/claim-info-details/process-zirmed-claim',
      params: { gPatientId, gOrderId },
      body: input,
    },
      { apiName: this.apiName });

  searchCheckListsBasedOnInputByCheckNumberAndCheckAmountAndClaimNumberAndDefaultPatientNumberAndPatientNameAndPostingDateAndBilledAmountAndCurrentBalanceAndCheckDate = (checkNumber: string, checkAmount: string, claimNumber: string, defaultPatientNumber: string, patientName: string, postingDate: string, billedAmount: string, currentBalance: string, checkDate: string) =>
    this.restService.request<any, CheckListDTO[]>({
      method: 'POST',
      url: '/api/claimProcessingApp/claim-info-details/search-check-lists-based-on-input',
      params: { checkNumber, checkAmount, claimNumber, defaultPatientNumber, patientName, postingDate, billedAmount, currentBalance, checkDate },
    },
      { apiName: this.apiName });


  getBillingStatusDetailsById = (Id: string) =>
    this.restService.request<any, BillingSummery>({
      method: 'GET',
      url: `/api/claimProcessingApp/claim-info-details/billing-status-details/${Id}`,
    },
      { apiName: this.apiName });

      commonClaimSubmissionByInput = (input: ClaimBatchProcessRequestDTO, gPatientId: string, gOrderId: string) =>
      this.restService.request<any, clsZirmidChangeHealthResponseDTO>({
        method: 'POST',
        url: '/api/claimProcessingApp/claim-info-details/common-claim-submission',
        params: { gPatientId, gOrderId },
        body: input,
      },
      { apiName: this.apiName });

  getClaimDetailsByPatientId = (patientId: string) =>
    this.restService.request<any, ClaimStatusSearchDTO[]>(
      {
        method: 'POST',
        url: `/api/claimProcessingApp/claim-info-details/claim-details-by-patient-id/${patientId}`,
        //params: { patientId },
      },
      { apiName: this.apiName }
    );

    getCheckNumberForMyQueueByInput = (input: InputCheckNumberDTO) =>
      this.restService.request<any, MyQueueCheckNumberDTO[]>({
        method: 'POST',
        url: '/api/claimProcessingApp/claim-info-details/get-check-number-for-my-queue',
        body: input,
      },
      { apiName: this.apiName });
      
  constructor(private restService: RestService) { }
}
