<div class="container-fluid main-content">
  <div class="row bg">
    <div class="col-12">
      <div class="d-flex align-item-center justify-content-between m-0">
        <h4 class="mt-2 mb-0 font-size-18"><b>Patient</b></h4>
        <div class=" page-title-box  page-title-right mt-1 mb-0">
          <ol class="breadcrumbs m-0">
            <li class="breadcrumb-item">Patient</li>
            <li class="breadcrumb-item">Patient List</li>
          </ol>
        </div>
      </div>
    </div>
  </div>
  <div class="card card-body">
    <div class="mt-4">
      <form [formGroup]="SPatientImprtForm">
        <div class="row">
          <mat-form-field class="col-2">
            <mat-label>Patient Name </mat-label>
            <input autocomplete="new-patientName" matInput maxlength="80" formControlName="patientName" type="text" />
          </mat-form-field>

          <mat-form-field class="col-2">
            <mat-label>Insurance </mat-label>
            <mat-select formControlName="txtInsurance">
              <mat-option *ngFor="let ins of drpInsuranceNames" [value]="ins">
                {{ins}}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field class="col-2">
            <mat-label>Insurance Status </mat-label>
            <mat-select formControlName="txtInsStatus">
              <mat-option *ngFor="let isActive of drpIsActiveStatus " [value]="isActive?.isActiveStatus">
                {{isActive?.isActiveStatus}}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field class="col-2">
            <mat-label>Verification Status </mat-label>
            <mat-select formControlName="txtVerifyStatus">
              <mat-option *ngFor="let provider of drpVerificationStatus " [value]="provider?.verificationStatus">
                {{provider?.verificationStatus}}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field class="col-2">
            <mat-label> Uploaded From Date </mat-label>
            <input autocomplete="new-txtUplaodedFromDate" formControlName="txtUplaodedFromDate" matInput maxlength="40" type="text" [max]="BirthdateMaxDate"
              placeholder="(MM/DD/YYYY)" [matDatepicker]="txtUplaodedFromDate" />
            <mat-datepicker-toggle matSuffix [for]="txtUplaodedFromDate"> </mat-datepicker-toggle>
            <mat-datepicker #txtUplaodedFromDate> </mat-datepicker>
            <mat-error
              *ngIf="
              SPatientImprtForm?.get('txtUplaodedFromDate')?.touched &&
              SPatientImprtForm?.get('txtUplaodedFromDate')?.errors && !(BirthdateMaxDate<=SPatientImprtForm?.get('txtUplaodedFromDate')?.value)">
              Enter Valid date
            </mat-error>
            <mat-error
              *ngIf="BirthdateMaxDate<=SPatientImprtForm?.get('txtUplaodedFromDate')?.value && !(SPatientImprtForm?.get('txtUplaodedFromDate')?.touched && SPatientImprtForm?.get('txtUplaodedFromDate')?.errors?.required)">
              Enter Past Dates Only!
            </mat-error>
            <mat-error
              *ngIf=" SPatientImprtForm?.get('txtUplaodedToDate')?.touched && SPatientImprtForm?.get('txtUplaodedToDate')?.errors?.required">
              Uploaded From Date is a required field!
            </mat-error>
          </mat-form-field>
          <mat-form-field class="col-2">
            <mat-label> Uploaded To Date </mat-label>
            <input autocomplete="new-txtUplaodedToDate" formControlName="txtUplaodedToDate" matInput maxlength="40" type="text"
              [min]="SPatientImprtForm?.get('txtUplaodedFromDate').value" placeholder="(MM/DD/YYYY)"
              [matDatepicker]="txtUplaodedToDate" />
            <mat-datepicker-toggle matSuffix [for]="txtUplaodedToDate"> </mat-datepicker-toggle>
            <mat-datepicker #txtUplaodedToDate> </mat-datepicker>
            <mat-error *ngIf="
              SPatientImprtForm?.get('txtUplaodedToDate')?.touched &&
              SPatientImprtForm?.get('txtUplaodedToDate')?.errors ">
              Enter Valid date
            </mat-error>
            <mat-error *ngIf="!(SPatientImprtForm?.get('txtUplaodedToDate')?.touched &&
            SPatientImprtForm?.get('txtUplaodedToDate')?.errors) && shownDateRangeErr">
              Enter After Uploaded From Date!
            </mat-error>
            <mat-error
              *ngIf=" SPatientImprtForm?.get('txtUplaodedToDate')?.touched && SPatientImprtForm?.get('txtUplaodedToDate')?.errors?.required">
              Uploaded To Date is a required field!
            </mat-error>
          </mat-form-field>
          <div class="col-8">
            <div>
              <input id="file-upload" (change)="importExcel(uploadFileReference?.files)" [multiple]="false"
                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" #uploadFileReference
                type="file" />
              <button (click)="uploadFileReference.click()" mat-button class=" buttonColor font-size-14 mb-2 mr-2 ">
                <i class="fa fa-file-import"></i> Import
              </button>
              <button mat-button class="buttonColor mb-2 mr-2"
                [disabled]="disableExportButton || this.dataSource?.data===[]" (click)="exportDataToExcel()">
                Export
              </button>
              <button mat-button class="buttonColor mb-2 mr-2"
                [disabled]="disableExportButton || this.dataSource?.data===[]" (click)="downloadTemplate()">
                Download Template
              </button>
              <button mat-button class="buttonColor mb-2 mr-2"
                [disabled]="disableExportButton || this.dataSource?.data===[]" (click)="MISReport()">
                Report
              </button>
            </div>
          </div>
          <div class="col-4 text-sm-right">
            <button mat-button class="buttonColor mr-2" [disabled]="SPatientImprtForm?.invalid"
              (click)="searchpatImport()">Search
            </button>
            <button mat-button class="resetclr buttonColor mb-2 mr-2" (click)="resetFilter()">
              Reset
            </button>
          </div>
        </div>
      </form>


      <div class="row">
        <div class="col-xl-3 col-lg-6" *ngIf="sourceMDPatientDashboardCount?.currentDayPatientImportCount!==null">
          <div class="card card-stats mb-4 mb-xl-0">
            <div class="card-body">
              <div class="row">
                <div class="col-auto">
                  <div class="icon icon-shape">
                    <img class="img-responsive" src="assets\images\DashboardIcons\Group 562.png" />
                  </div>
                </div>
                <div class="col">
                  <h4 class="card-title text-muted mb-0">
                    <p> Today Patient Count</p>
                  </h4>
                </div>
              </div>
              <div class="text-sm-right">
                <span class="txtCount">
                  {{sourceMDPatientDashboardCount?.currentDayPatientImportCount}}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-3 col-lg-6"
          *ngIf="sourceMDPatientDashboardCount?.currentDayVerificationCompletedCount!==null">
          <div class="card card-stats mb-4 mb-xl-0">
            <div class="card-body">
              <div class="row">
                <div class="col-auto">
                  <div class="icon icon-shape">
                    <img class="img-responsive" src="assets\images\DashboardIcons\Verification Count.png" />
                  </div>
                </div>
                <div class="col">
                  <h4 class="card-title text-muted mb-0">
                    <p> Today Verification Count</p>
                  </h4>
                </div>
              </div>
              <div class="text-sm-right">
                <span class="txtCount">
                  {{sourceMDPatientDashboardCount?.currentDayVerificationCompletedCount}}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-3 col-lg-6" *ngIf="sourceMDPatientDashboardCount?.currentDayPatientImportCount!==null">
          <div class="card card-stats mb-4 mb-xl-0">
            <div class="card-body">
              <div class="row">
                <div class="col-auto">
                  <div class="icon icon-shape">
                    <img class="img-responsive" src="assets\images\DashboardIcons\Group 562.png" />
                  </div>
                </div>
                <div class="col">
                  <h4 class="card-title text-muted mb-0">
                    <p> Current Month Patient Count</p>
                  </h4>
                </div>
              </div>
              <div class="text-sm-right">
                <span class="txtCount">
                  {{sourceMDPatientDashboardCount?.currentMonthPatientImportCount}}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-3 col-lg-6"
          *ngIf="sourceMDPatientDashboardCount?.currentDayVerificationCompletedCount!==null">
          <div class="card card-stats mb-4 mb-xl-0">
            <div class="card-body">
              <div class="row">
                <div class="col-auto">
                  <div class="icon icon-shape">
                    <img class="img-responsive" src="assets\images\DashboardIcons\Verification Count.png" />
                  </div>
                </div>
                <div class="col">
                  <h4 class="card-title text-muted mb-0">
                    <p> Current Month Verification Count</p>
                  </h4>
                </div>
              </div>
              <div class="text-sm-right">
                <span class="txtCount">
                  {{sourceMDPatientDashboardCount?.currentMonthVerificationCompletedCount}}
                </span>
              </div>
            </div>
          </div>
        </div>


      </div>
      <div class="row">
        <div class="col-12 table-responsive">
          <div [hidden]="!isShowSpinner"
            style="display: flex; justify-content: center; align-items: center; background: transparent; color: lightseagreen;">
            <mat-progress-bar mode="indeterminate" value='66' diameter="45" class="lightseagreen">
            </mat-progress-bar>
          </div>
          <table mat-table class="table" [dataSource]="dataSource" multiTemplateDataRows>
            <ng-container matColumnDef="fName">
              <th mat-header-cell *matHeaderCellDef>
                First Name
              </th>
              <td mat-cell *matCellDef="let data">
                <ng-container>
                  {{ data?.firstName }}
                </ng-container>
              </td>
            </ng-container>
            <ng-container matColumnDef="lName">
              <th mat-header-cell *matHeaderCellDef>
                Last Name
              </th>
              <td mat-cell *matCellDef="let data">
                <ng-container>
                  {{ data?.lastName }}
                </ng-container>
              </td>
            </ng-container>
            <ng-container matColumnDef="dob">
              <th mat-header-cell *matHeaderCellDef>
                Date Of Birth
              </th>
              <td mat-cell *matCellDef="let data">
                <ng-container>
                  {{ data?.dob | date: 'MM/dd/yyyy':'en_US' }}
                </ng-container>
              </td>
            </ng-container>
            <ng-container matColumnDef="status">
              <th mat-header-cell *matHeaderCellDef>
                Verification Status
              </th>
              <td mat-cell *matCellDef="let data">
                <ng-container>
                  {{ data?.insuranceDetailsList[data?.insuranceDetailsList?.length-1]?.verificationStatus }}
                </ng-container>
              </td>
            </ng-container>
            <ng-container matColumnDef="CreatedOn">
              <th mat-header-cell *matHeaderCellDef>
                Created Date
              </th>
              <td mat-cell *matCellDef="let data">
                <ng-container>
                  {{ data?.creationTime | date: 'MM/dd/yyyy':'en_US' }}
                </ng-container>
              </td>
            </ng-container>
            <ng-container matColumnDef="insName">
              <th mat-header-cell *matHeaderCellDef>
                Insurance Name
              </th>
              <td mat-cell *matCellDef="let data">
                <ng-container>
                  {{ data?.insuranceDetailsList[data?.insuranceDetailsList?.length-1]?.insurance }}
                </ng-container>
              </td>
            </ng-container>
            <ng-container matColumnDef="ptId">
              <th mat-header-cell *matHeaderCellDef>Patient Id</th>
              <td mat-cell *matCellDef="let data">
                <ng-container>
                  {{ data?.defaultPatientId }}
                </ng-container>
              </td>
            </ng-container>
            <ng-container matColumnDef="Options">
              <th mat-header-cell *matHeaderCellDef>Options</th>
              <td mat-cell *matCellDef="let data">

                <a (click)="expandedElement = expandedElement === data ? null : data">
                  <mat-icon class="pointer">
                    {{ expandedElement === data ? 'expand_less' : 'expand_more' }}</mat-icon>
                </a>
              </td>
            </ng-container>
            <ng-container matColumnDef="noRecords">
              <td class="text-align-center" mat-footer-cell *matFooterCellDef colspan="11">
                <div *ngIf="!isShowSpinner">
                  {{"No Records Found"}}
                </div>
                <div [hidden]="!isShowSpinner">
                  <mat-spinner [diameter]="220"></mat-spinner>
                </div>
              </td>
            </ng-container>
            <ng-container matColumnDef="paginator">
              <td mat-footer-cell *matFooterCellDef colspan="11">
                <mat-paginator class="table table-responsive mat-table-class" #MatPaginator
                  [length]="dataSource?.data?.length" [pageSize]="10" [pageIndex]="0" showFirstLastButtons
                  [pageSizeOptions]="[5, 10, 25, 100]">
                </mat-paginator>
              </td>
            </ng-container>
            <ng-container matColumnDef="expandedErrorDetail">
              <td mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns?.length">
                <div class="example-element-detail"
                  [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
                  <div class="example-element-description">
                    <ng-container *ngIf="element?.insuranceDetailsList">
                      <p>Insurance Details</p>
                      <table class="table table-bordered table-sm">
                        <thead>
                          <tr>
                            <th scope="col">Insurance Name</th>
                            <th scope="col">Blue Card Eligible</th>
                            <th scope="col">Claim Submit</th>
                            <th scope="col">Insurance Active</th>
                            <th scope="col">Pre-Auth Available</th>
                            <th scope="col">Local-Bill Available</th>
                            <th scope="col">Family Coinsurance</th>
                            <th scope="col">Family Deductible Payment</th>
                            <th scope="col">Family Out Of Pocket</th>
                            <th scope="col">Individual Coinsurance</th>
                            <th scope="col">Individual Deductible Payment</th>
                            <th scope="col">Individual Out Of Pocket</th>
                            <th scope="col">Payment Comments</th>
                            <th scope="col">Options </th>
                          </tr>
                        </thead>
                        <tbody>
                          <ng-container *ngFor="let ins of element?.insuranceDetailsList">
                            <tr>
                              <th scope="row">{{ ins?.insurance }}</th>
                              <td>
                                {{ ins?.isBlueCardEligible === true ? "Yes" : "" }}
                                {{ ins?.isBlueCardEligible === false ? "No" : "" }}
                              </td>
                              <td>
                                {{ ins?.isClaimSubmit === true ? "Yes" : "" }}
                                {{ ins?.isClaimSubmit === false ? "No" : "" }}
                              </td>
                              <td>
                                {{ ins?.isInsuranceActive === true ? "Yes" : "" }}
                                {{ ins?.isInsuranceActive === false ? "No" : "" }}
                              </td>
                              <td>
                                {{ ins?.isPreAuthAvailable === true ? "Yes" : "" }}
                                {{ ins?.isPreAuthAvailable === false ? "No" : "" }}
                              </td>
                              <td>
                                {{ ins?.isLocalBillAvailable === true ? "Yes" : "" }}
                                {{ ins?.isLocalBillAvailable === false ? "No" : "" }}
                              </td>

                              <td>
                                <ng-container *ngIf=" ins?.familyCoinsurance">
                                  {{'$' + ins?.familyCoinsurance }}
                                </ng-container>
                              </td>
                              <td>
                                <ng-container *ngIf=" ins?.familyDeductiblePayment">
                                  {{'$' + ins?.familyDeductiblePayment }}
                                </ng-container>
                              </td>
                              <td>
                                <ng-container *ngIf=" ins?.familyOutOfPocket">
                                  {{'$' + ins?.familyOutOfPocket }}
                                </ng-container>
                              </td>
                              <td>
                                <ng-container *ngIf=" ins?.individualCoinsurance">
                                  {{'$' + ins?.individualCoinsurance }}
                                </ng-container>
                              </td>
                              <td>
                                <ng-container *ngIf=" ins?.individualDeductiblePayment">
                                  {{'$' + ins?.individualDeductiblePayment }}
                                </ng-container>
                              </td>
                              <td>
                                <ng-container *ngIf=" ins?.individualOutOfPocket">
                                  {{'$' + ins?.individualOutOfPocket }}
                                </ng-container>
                              </td>
                              <td>
                                <ng-container *ngIf=" ins?.paymentComments">
                                  {{ins?.paymentComments }}
                                </ng-container>
                              </td>

                              <td>
                                <a matTooltip="Click To Edit" (click)="edit(element?.id,ins?.uniqueInsuranceId)">
                                  <i class="fa fa-eye pointer editBtnColor pl-3" ></i>
                                </a>
                              </td>
                            </tr>
                          </ng-container>
                        </tbody>
                      </table>
                    </ng-container>
                  </div>
                </div>
              </td>
            </ng-container>
            <tr mat-footer-row *matFooterRowDef="['noRecords']"
              [ngClass]="{ active: dataSource && (dataSource?.data) && (dataSource?.data?.length !== 0) }"></tr>
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns"
              [ngClass]="{ 'green-row': row?.insuranceDetailsList[row?.insuranceDetailsList?.length-1]?.isInsuranceActive, 'red-row': !row?.insuranceDetailsList[row?.insuranceDetailsList?.length-1]?.isInsuranceActive}">
            </tr>
            <tr mat-row *matRowDef="let element; columns: ['expandedErrorDetail']"
              [class.example-expanded-row]="expandedElement === element"></tr>
            <tr mat-footer-row *matFooterRowDef="['paginator']"></tr>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>