import { RestService } from '@abp/ng.core';
import { Injectable } from '@angular/core';
import type { OnTimeMasterDropdownDTO, WorkingHoursDTO } from '../dto/on-time-schedule/models';
import type { OnTimeDropdowns } from '../on-time-dropdowns.enum';

@Injectable({
  providedIn: 'root',
})
export class OnTimeMasterDropdownService {
  apiName = 'DropdownManagement';

  getOnTimeDropdownsByInput = (input: OnTimeDropdowns[]) =>
    this.restService.request<any, OnTimeMasterDropdownDTO>({
      method: 'POST',
      url: '/api/DropdownApp/on-time-master-dropdown/get-on-time-dropdowns',
      body: input,
    },
     { apiName: this.apiName, skipHandleError: true });

  getLunchBreakEndTimingsBySLunchBreakStartShortCodeId = (sLunchBreakStartShortCodeId: string) =>
    this.restService.request<any, string>({
      method: 'GET',
      responseType: 'text',
      url: `/api/DropdownApp/on-time-master-dropdown/get-lunch-break-end-timings/${sLunchBreakStartShortCodeId}`,
    },
     { apiName: this.apiName, skipHandleError: true });

  getWorkTimings = () =>
    this.restService.request<any, WorkingHoursDTO>({
      method: 'GET',
      url: '/api/DropdownApp/on-time-master-dropdown/get-work-timings',
    },
     { apiName: this.apiName, skipHandleError: true });

  constructor(private restService: RestService) {}
}
