<mat-dialog-content class="mat-typography">
    <div class="row">
        <div>
            <h2 class="customThemeClass mr-4 ml-3">Add Workings Hours And Area for <b>{{data.BranchName}}</b></h2>
        </div>
    </div>
    <form [formGroup]="AddWorkForm">
        <table>
            <!-- SUNDAYS FORM -->
            <div>
                <tr>
                    <td>
                        <div class="row">
                            <div class=" col-3 mb-2 mt-3" style="display: flex;">
                                SUNDAY
                            </div>
                            <div class="ml-5 mb-2 mt-3">
                                <mat-slide-toggle class="mr-4" (click)="sundayStatus(AddWorkForm.status)"
                                    formControlName="AddSundayStatus">
                                    {{sunday}}</mat-slide-toggle>
                            </div>
                        </div>
                    </td>
                    <span *ngIf="sundayLeaveHide">
                        <span>
                            <td>
                                <div class="row">
                                    <mat-form-field class="col-4">
                                        <mat-label>Work Option</mat-label>
                                        <mat-select multiple maxlength="40" formControlName="drpWorkOptn">
                                            <mat-option *ngFor="let work of drpWorkOptn" [value]="work.workingWeek">
                                                {{ work.workingWeek }}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                    <mat-form-field class="col-4">
                                        <mat-label>Start At</mat-label>
                                        <mat-select maxlength="40" formControlName="drpStartAt">
                                            <mat-option *ngFor="let at of drpStartAt" [value]="at">
                                                {{ at.startTime }}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                    <mat-form-field class="col-4">
                                        <mat-label>Start End</mat-label>
                                        <mat-select (selectionChange)="onEndsChanges(AddWorkForm?.get('drpStartAt')?.value,
                                            AddWorkForm?.get('drpWorkOptn')?.value,
                                            AddWorkForm?.get('drpStartEnd')?.value)" formControlName="drpStartEnd">
                                            <mat-option
                                                *ngFor="let data of AddWorkForm?.get('drpStartAt')?.value?.endTimes"
                                                [value]="data.endTimeShortCodeId">
                                                {{data.endTime}}</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                            </td>
                            <td>
                                <div class="row">
                                    <div class="col-6 ml-2">
                                        <mat-form-field>
                                            <mat-select formControlName="drpUser" placeholder="Users" ngModelOptions>
                                                <mat-option *ngFor="let user of arrUsersList" [value]="user.userId">
                                                    {{user.userName}}
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>
                                    <div class="col-5">
                                        <div *ngIf="sunMore" class="col-12" style="margin-top: 20px;">
                                            <span class="customThemeClass" style="cursor: pointer;"
                                                (click)="addAccordion()" ><b>Add Sunday</b></span>
                                        </div>
                                    </div>
                                </div>
                            </td>
                        </span>
                        <span *ngIf="sundayHide">
                            <td>
                                <div class="row">
                                    <mat-form-field class="col-4">
                                        <mat-label>Work Option</mat-label>
                                        <mat-select multiple maxlength="40" formControlName="drp2WorkOptn">
                                            <mat-option *ngFor="let work of drpWorkOptn" [value]="work.workingWeek">
                                                {{ work.workingWeek }}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>

                                    <mat-form-field class="col-4">
                                        <mat-label>Start At</mat-label>
                                        <mat-select maxlength="40" formControlName="drp2StartAt">
                                            <mat-option *ngFor="let at of drpStartAt" [value]="at">
                                                {{ at.startTime }}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                    <mat-form-field class="col-4">
                                        <mat-label>Start End</mat-label>
                                        <mat-select (selectionChange)="onSun2Changes(AddWorkForm?.get('drp2StartAt')?.value,
                                            AddWorkForm?.get('drp2WorkOptn')?.value,
                                            AddWorkForm?.get('drp2StartEnd')?.value)" formControlName="drp2StartEnd">
                                            <mat-option
                                                *ngFor="let data of AddWorkForm?.get('drp2StartAt')?.value?.endTimes"
                                                [value]="data.endTimeShortCodeId">
                                                {{data.endTime}}</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                            </td>

                            <td>
                                <div class="row">
                                    <mat-form-field class="col-12 ml-2">
                                        <mat-select formControlName="drp2User" placeholder="Users" ngModelOptions>
                                            <mat-option *ngFor="let user of arr2UsersList" [value]="user.userId">
                                                {{user.userName}}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                            </td>
                        </span>
                    </span>
                </tr>
            </div>
            <hr>
            <!-- MONDAY FORM -->
            <div>
                <tr>
                    <td>
                        <div class="row">
                            <div class="col-3 mb-2 mt-3" style="display: flex;">
                                MONDAY
                            </div>
                            <div class="ml-5 mb-2 mt-3">
                                <mat-slide-toggle class="mr-4" (click)="mondayStatus(AddWorkForm.status)"
                                    formControlName="AddMondayStatus">
                                    {{monday}}</mat-slide-toggle>
                            </div>
                        </div>
                    </td>
                    <span *ngIf="mondayLeaveHide">
                        <span>
                            <td>
                                <div class="row">
                                    <mat-form-field class="col-4">
                                        <mat-label>Work Option</mat-label>
                                        <mat-select multiple maxlength="40" formControlName="drpMonWorkOptn">
                                            <mat-option *ngFor="let work of drpWorkOptn" [value]="work.workingWeek">
                                                {{ work.workingWeek }}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>

                                    <mat-form-field class="col-4">
                                        <mat-label>Start At</mat-label>
                                        <mat-select maxlength="40" formControlName="drpMonStartAt">
                                            <mat-option *ngFor="let at of drpStartAt" [value]="at">
                                                {{ at.startTime }}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>

                                    <mat-form-field class="col-4">
                                        <mat-label>Start End</mat-label>
                                        <mat-select (selectionChange)="onMonEndsChanges(AddWorkForm?.get('drpMonStartAt')?.value,
                        AddWorkForm?.get('drpMonWorkOptn')?.value,
                        AddWorkForm?.get('drpMonStartEnd')?.value)" formControlName="drpMonStartEnd">
                                            <mat-option
                                                *ngFor="let data of AddWorkForm?.get('drpMonStartAt')?.value?.endTimes"
                                                [value]="data.endTimeShortCodeId">
                                                {{data.endTime}}</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                            </td>

                            <td>
                                <div class="row">
                                    <div class="col-6 ml-2">
                                        <mat-form-field>
                                            <mat-select formControlName="drpMonUser" placeholder="Users" ngModelOptions>
                                                <mat-option *ngFor="let user of arrMonUsersList" [value]="user.userId">
                                                    {{user.userName}}
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>
                                    <div class="col-5">
                                        <div *ngIf="monMore" class="col-12" style="margin-top: 20px;">
                                            <span class="customThemeClass" style="cursor: pointer;"
                                                (click)="addMonday()" ><b>Add Monday </b></span>
                                        </div>
                                    </div>
                                </div>
                            </td>
                        </span>

                        <span *ngIf="mondayHide">
                            <td>
                                <div class="row">
                                    <mat-form-field class="col-4">
                                        <mat-label>Work Option</mat-label>
                                        <mat-select multiple maxlength="40" formControlName="drpMon2WorkOptn">
                                            <mat-option *ngFor="let work of drpWorkOptn" [value]="work.workingWeek">
                                                {{ work.workingWeek }}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>

                                    <mat-form-field class="col-4">
                                        <mat-label>Start At</mat-label>
                                        <mat-select maxlength="40" formControlName="drpMon2StartAt">
                                            <mat-option *ngFor="let at of drpStartAt" [value]="at">
                                                {{ at.startTime }}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>

                                    <mat-form-field class="col-4">
                                        <mat-label>Start End</mat-label>
                                        <mat-select (selectionChange)="onMon2Changes(AddWorkForm?.get('drpMon2StartAt')?.value,
                                        AddWorkForm?.get('drpMon2WorkOptn')?.value,
                                        AddWorkForm?.get('drpMon2StartEnd')?.value)" formControlName="drpMon2StartEnd">
                                            <mat-option
                                                *ngFor="let data of AddWorkForm?.get('drpMon2StartAt')?.value?.endTimes"
                                                [value]="data.endTimeShortCodeId">
                                                {{data.endTime}}</mat-option>
                                        </mat-select>
                                    </mat-form-field>

                                </div>
                            </td>

                            <td>
                                <div class="row">
                                    <mat-form-field class="col-12 ml-2">
                                        <mat-select formControlName="drpMon2User" placeholder="Users" ngModelOptions>
                                            <mat-option *ngFor="let user of arrMon2UsersList" [value]="user.userId">
                                                {{user.userName}}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                            </td>
                        </span></span>
                </tr>
            </div>
            <hr>
            <!-- TUESDAY FORM -->
            <div>
                <tr>
                    <td>
                        <div class="row">
                            <div class="col-3 mb-2 mt-3" style="display: flex;">
                                TUESDAY
                            </div>
                            <div class="ml-5 mb-2 mt-3">
                                <mat-slide-toggle class="mr-4" (click)="tuesStatus(AddWorkForm.status)"
                                    formControlName="AddTuesdayStatus">
                                    {{tuesday}}</mat-slide-toggle>
                            </div>
                        </div>
                    </td>
                    <span *ngIf="tuedayLeaveHide">
                        <span>
                            <td>
                                <div class="row">
                                    <mat-form-field class="col-4">
                                        <mat-label>Work Option</mat-label>
                                        <mat-select multiple maxlength="40" formControlName="drpTueWorkOptn">
                                            <mat-option *ngFor="let work of drpWorkOptn" [value]="work.workingWeek">
                                                {{ work.workingWeek }}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                    <mat-form-field class="col-4">
                                        <mat-label>Start At</mat-label>
                                        <mat-select maxlength="40" formControlName="drpTueStartAt">
                                            <mat-option *ngFor="let at of drpStartAt" [value]="at">
                                                {{ at.startTime }}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                    <mat-form-field class="col-4">
                                        <mat-label>Start End</mat-label>
                                        <mat-select (selectionChange)="onTueEndsChanges(AddWorkForm?.get('drpTueStartAt')?.value,
                        AddWorkForm?.get('drpTueWorkOptn')?.value,
                        AddWorkForm?.get('drpTueStartEnd')?.value)" formControlName="drpTueStartEnd">
                                            <mat-option
                                                *ngFor="let data of AddWorkForm?.get('drpTueStartAt')?.value?.endTimes"
                                                [value]="data.endTimeShortCodeId">
                                                {{data.endTime}}</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                            </td>

                            <td>
                                <div class="row">
                                    <div class="col-6 ml-2">
                                        <mat-form-field>
                                            <mat-select formControlName="drpTueUser" placeholder="Users" ngModelOptions>
                                                <mat-option *ngFor="let user of arrTueUsersList" [value]="user.userId">
                                                    {{user.userName}}
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>
                                    <div class="col-5">
                                        <div *ngIf="tueMore" class="col-12" style="margin-top: 20px;">
                                            <span class="customThemeClass" style="cursor: pointer;"
                                                (click)="addTueday()" ><b>Add Tuesday </b></span>
                                        </div>
                                    </div>
                                </div>
                            </td>
                        </span>

                        <span *ngIf="TuedayHide">
                            <td>
                                <div class="row">
                                    <mat-form-field class="col-4">
                                        <mat-label>Work Option</mat-label>
                                        <mat-select multiple maxlength="40" formControlName="drpTue2WorkOptn">
                                            <mat-option *ngFor="let work of drpWorkOptn" [value]="work.workingWeek">
                                                {{ work.workingWeek }}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>

                                    <mat-form-field class="col-4">
                                        <mat-label>Start End</mat-label>
                                        <mat-select (selectionChange)="onTue2Changes(AddWorkForm?.get('drpTue2StartAt')?.value,
                                            AddWorkForm?.get('drpTue2WorkOptn')?.value,
                                            AddWorkForm?.get('drpTue2StartEnd')?.value)"
                                            formControlName="drpTue2StartEnd">
                                            <mat-option
                                                *ngFor="let data of AddWorkForm?.get('drpTue2StartAt')?.value?.endTimes"
                                                [value]="data.endTimeShortCodeId">
                                                {{data.endTime}}</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                    <mat-form-field class="col-4">
                                        <mat-label>Start At</mat-label>
                                        <mat-select maxlength="40" formControlName="drpTue2StartAt">
                                            <mat-option *ngFor="let at of drpStartAt" [value]="at">
                                                {{ at.startTime }}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                            </td>

                            <td>
                                <div class="row">
                                    <mat-form-field class="col-12 ml-2">
                                        <mat-select formControlName="drpTue2User" placeholder="Users" ngModelOptions>
                                            <mat-option *ngFor="let user of arrTue2UsersList" [value]="user.userId">
                                                {{user.userName}}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                            </td>
                        </span></span>
                </tr>
            </div>
            <hr>
            <!-- WEDDAY FORM -->
            <div>
                <tr>
                    <td>
                        <div class="row">
                            <div class="col-3 mb-2 mt-3" style="display: flex;">
                                WEDNESDAY
                            </div>
                            <div class="ml-5 mb-2 mt-3">
                                <mat-slide-toggle (click)="wedStatus(AddWorkForm.status)"
                                    formControlName="AddWednesdayStatus">
                                    {{wedday}}</mat-slide-toggle>
                            </div>
                        </div>
                    </td>
                    <span *ngIf="weddayLeaveHide">
                        <span>
                            <td>
                                <div class="row">
                                    <mat-form-field class="col-4">
                                        <mat-label>Work Option</mat-label>
                                        <mat-select multiple maxlength="40" formControlName="drpWedWorkOptn">
                                            <mat-option *ngFor="let work of drpWorkOptn" [value]="work.workingWeek">
                                                {{ work.workingWeek }}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>

                                    <mat-form-field class="col-4">
                                        <mat-label>Start At</mat-label>
                                        <mat-select maxlength="40" formControlName="drpWedStartAt">
                                            <mat-option *ngFor="let at of drpStartAt" [value]="at">
                                                {{ at.startTime }}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>

                                    <mat-form-field class="col-4">
                                        <mat-label>Start End</mat-label>
                                        <mat-select (selectionChange)="onWedEndsChanges(AddWorkForm?.get('drpWedStartAt')?.value,
                                            AddWorkForm?.get('drpWedWorkOptn')?.value,
                                            AddWorkForm?.get('drpWedStartEnd')?.value)"
                                            formControlName="drpWedStartEnd">
                                            <mat-option
                                                *ngFor="let data of AddWorkForm?.get('drpWedStartAt')?.value?.endTimes"
                                                [value]="data.endTimeShortCodeId">
                                                {{data.endTime}}</mat-option>
                                        </mat-select>
                                    </mat-form-field>

                                </div>
                            </td>

                            <td>
                                <div class="row">
                                    <div class="col-6 ml-2">
                                        <mat-form-field>
                                            <mat-select formControlName="drpWedUser" placeholder="Users" ngModelOptions>
                                                <mat-option *ngFor="let user of arrWedUsersList" [value]="user.userId">
                                                    {{user.userName}}
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>
                                    <div class="col-5">
                                        <div *ngIf="wedMore" class="col-12" style="margin-top: 20px;">
                                            <span class="customThemeClass" style="cursor: pointer;"
                                                (click)="addWedday()" ><b>Add Wednesday</b></span>
                                        </div>
                                    </div>
                                </div>
                            </td>
                        </span>

                        <span *ngIf="WeddayHide">
                            <td>
                                <div class="row">
                                    <mat-form-field class="col-12">
                                        <mat-label>Work Option</mat-label>
                                        <mat-select multiple maxlength="40" formControlName="drpWed2WorkOptn">
                                            <mat-option *ngFor="let work of drpWorkOptn" [value]="work.workingWeek">
                                                {{ work.workingWeek }}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                            </td>
                            <td>

                                <mat-form-field class="col-6">
                                    <mat-label>Start At</mat-label>
                                    <mat-select maxlength="40" formControlName="drpWed2StartAt">
                                        <mat-option *ngFor="let at of drpStartAt" [value]="at">
                                            {{ at.startTime }}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                                <mat-form-field class="col-6">
                                    <mat-label>Start End</mat-label>
                                    <mat-select (selectionChange)="onWed2Changes(AddWorkForm?.get('drpWed2StartAt')?.value,
                      AddWorkForm?.get('drpWed2WorkOptn')?.value,
                      AddWorkForm?.get('drpWed2StartEnd')?.value)" formControlName="drpWed2StartEnd">
                                        <mat-option
                                            *ngFor="let data of AddWorkForm?.get('drpWed2StartAt')?.value?.endTimes"
                                            [value]="data.endTimeShortCodeId">
                                            {{data.endTime}}</mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </td>
                            <td>
                                <div class="row">
                                    <mat-form-field class="col-12">
                                        <mat-select formControlName="drpWed2User" placeholder="Users" ngModelOptions>
                                            <mat-option *ngFor="let user of arrWed2UsersList" [value]="user.userId">
                                                {{user.userName}}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                            </td>
                        </span></span>
                </tr>
            </div>
            <hr>
            <!-- THURSDAY FORM -->
            <div>
                <tr>
                    <td>
                        <div class="row">
                            <div class="col-3 mb-2 mt-3" style="display: flex;">
                                THURSDAY
                            </div>
                            <div class="ml-5 mb-2 mt-3">
                                <mat-slide-toggle class="mr-2" (click)="thuStatus(AddWorkForm.status)"
                                    formControlName="AddThudayStatus">
                                    {{thuday}}</mat-slide-toggle>
                            </div>
                        </div>
                    </td>
                    <span *ngIf="thudayLeaveHide">
                        <span>
                            <td>
                                <div class="row">
                                    <mat-form-field class="col-4">
                                        <mat-label>Work Option</mat-label>
                                        <mat-select multiple maxlength="40" formControlName="drpThuWorkOptn">
                                            <mat-option *ngFor="let work of drpWorkOptn" [value]="work.workingWeek">
                                                {{ work.workingWeek }}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                    <mat-form-field class="col-4">
                                        <mat-label>Start At</mat-label>
                                        <mat-select maxlength="40" formControlName="drpThuStartAt">
                                            <mat-option *ngFor="let at of drpStartAt" [value]="at">
                                                {{ at.startTime }}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                    <mat-form-field class="col-4">
                                        <mat-label>Start End</mat-label>
                                        <mat-select (selectionChange)="onThuEndsChanges(AddWorkForm?.get('drpThuStartAt')?.value,
                                        AddWorkForm?.get('drpThuWorkOptn')?.value,
                                        AddWorkForm?.get('drpThuStartEnd')?.value)" formControlName="drpThuStartEnd">
                                            <mat-option
                                                *ngFor="let data of AddWorkForm?.get('drpThuStartAt')?.value?.endTimes"
                                                [value]="data.endTimeShortCodeId">
                                                {{data.endTime}}</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                            </td>

                            <td>
                                <div class="row">
                                    <div class="col-6 ml-2">
                                        <mat-form-field>
                                            <mat-select formControlName="drpThuUser" placeholder="Users" ngModelOptions>
                                                <mat-option *ngFor="let user of arrThuUsersList" [value]="user.userId">
                                                    {{user.userName}}
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>
                                    <div class="col-5">
                                        <div *ngIf="thuMore" class="col-12" style="margin-top: 20px;">
                                            <span class="customThemeClass" style="cursor: pointer;"
                                                (click)="addThuday()" ><b>Add Thursday</b></span>
                                        </div>
                                    </div>
                                </div>
                            </td>
                        </span>

                        <span *ngIf="ThudayHide">
                            <td>
                                <div class="row">
                                    <mat-form-field class="col-4">
                                        <mat-label>Work Option</mat-label>
                                        <mat-select multiple maxlength="40" formControlName="drpThu2WorkOptn">
                                            <mat-option *ngFor="let work of drpWorkOptn" [value]="work.workingWeek">
                                                {{ work.workingWeek }}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                    <mat-form-field class="col-4">
                                        <mat-label>Start At</mat-label>
                                        <mat-select maxlength="40" formControlName="drpThu2StartAt">
                                            <mat-option *ngFor="let at of drpStartAt" [value]="at">
                                                {{ at.startTime }}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                    <mat-form-field class="col-4">
                                        <mat-label>Start End</mat-label>
                                        <mat-select (selectionChange)="onThu2Changes(AddWorkForm?.get('drpThu2StartAt')?.value,
                                                AddWorkForm?.get('drpThu2WorkOptn')?.value,
                                                AddWorkForm?.get('drpThu2StartEnd')?.value)"
                                            formControlName="drpThu2StartEnd">
                                            <mat-option
                                                *ngFor="let data of AddWorkForm?.get('drpThu2StartAt')?.value?.endTimes"
                                                [value]="data.endTimeShortCodeId">
                                                {{data.endTime}}</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                            </td>

                            <td>
                                <div class="row">
                                    <mat-form-field class="col-12  ml-2">
                                        <mat-select formControlName="drpThu2User" placeholder="Users" ngModelOptions>
                                            <mat-option *ngFor="let user of arrThu2UsersList" [value]="user.userId">
                                                {{user.userName}}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                            </td>
                        </span>
                    </span>
                </tr>
            </div>
            <hr>
            <!-- FRIDAY FORM -->
            <div>
                <tr>
                    <td>
                        <div class="row">
                            <div class="col-3 mb-2 mt-3" style="display: flex;">
                                FRIDAY
                            </div>
                            <div class="ml-5 mb-2 mt-3">
                                <mat-slide-toggle class="mr-4" (click)="FriStatus(AddWorkForm.status)"
                                    formControlName="AddFridayStatus">
                                    {{Friday}}</mat-slide-toggle>
                            </div>
                        </div>
                    </td>
                    <span *ngIf="fridayLeaveHide">
                        <span>
                            <td>
                                <div class="row">
                                    <mat-form-field class="col-4">
                                        <mat-label>Work Option</mat-label>
                                        <mat-select multiple maxlength="40" formControlName="drpFriWorkOptn">
                                            <mat-option *ngFor="let work of drpWorkOptn" [value]="work.workingWeek">
                                                {{ work.workingWeek }}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>

                                    <mat-form-field class="col-4">
                                        <mat-label>Start At</mat-label>
                                        <mat-select maxlength="40" formControlName="drpFriStartAt">
                                            <mat-option *ngFor="let at of drpStartAt" [value]="at">
                                                {{ at.startTime }}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>

                                    <mat-form-field class="col-4">
                                        <mat-label>Start End</mat-label>
                                        <mat-select (selectionChange)="onFriEndsChanges(AddWorkForm?.get('drpFriStartAt')?.value,
                                        AddWorkForm?.get('drpFriWorkOptn')?.value,
                                        AddWorkForm?.get('drpFriStartEnd')?.value)" formControlName="drpFriStartEnd">
                                            <mat-option
                                                *ngFor="let data of AddWorkForm?.get('drpFriStartAt')?.value?.endTimes"
                                                [value]="data.endTimeShortCodeId">
                                                {{data.endTime}}</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                            </td>

                            <td>
                                <div class="row">
                                    <div class="col-6 ml-2">
                                        <mat-form-field>
                                            <mat-select formControlName="drpFriUser" placeholder="Users" ngModelOptions>
                                                <mat-option *ngFor="let user of arrFriUsersList" [value]="user.userId">
                                                    {{user.userName}}
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>
                                    <div class="col-5">
                                        <div *ngIf="friMore" class="col-12" style="margin-top: 20px;">
                                            <span class="customThemeClass" style="cursor: pointer;"
                                                (click)="addFriday()" ><b>Add Friday</b></span>
                                        </div>
                                    </div>
                                </div>
                            </td>
                        </span>
                        <span *ngIf="FridayHide">
                            <td>
                                <div class="row">
                                    <mat-form-field class="col-4">
                                        <mat-label>Work Option</mat-label>
                                        <mat-select multiple maxlength="40" formControlName="drpFri2WorkOptn">
                                            <mat-option *ngFor="let work of drpWorkOptn" [value]="work.workingWeek">
                                                {{ work.workingWeek }}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                    <mat-form-field class="col-4">
                                        <mat-label>Start At</mat-label>
                                        <mat-select maxlength="40" formControlName="drpFri2StartAt">
                                            <mat-option *ngFor="let at of drpStartAt" [value]="at">
                                                {{ at.startTime }}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                    <mat-form-field class="col-4">
                                        <mat-label>Start End</mat-label>
                                        <mat-select (selectionChange)="onFri2Changes(AddWorkForm?.get('drpFri2StartAt')?.value,
                                            AddWorkForm?.get('drpFri2WorkOptn')?.value,
                                            AddWorkForm?.get('drpFri2StartEnd')?.value)"
                                            formControlName="drpFri2StartEnd">
                                            <mat-option
                                                *ngFor="let data of AddWorkForm?.get('drpFri2StartAt')?.value?.endTimes"
                                                [value]="data.endTimeShortCodeId">
                                                {{data.endTime}}</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                            </td>

                            <td>
                                <div class="row">
                                    <mat-form-field class="col-12 ml-2">
                                        <mat-select formControlName="drpFri2User" placeholder="Users" ngModelOptions>
                                            <mat-option *ngFor="let user of arrFri2UsersList" [value]="user.userId">
                                                {{user.userName}}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                            </td>
                        </span>
                    </span>
                </tr>
            </div>
            <hr>
            <!-- SATDAY FORM -->
            <div>
                <tr>
                    <td>
                        <div class="row">
                            <div class="col-3 mb-2 mt-3" style="display: flex;">
                                SATURDAY
                            </div>
                            <div class="ml-5 mb-2 mt-3">
                                <mat-slide-toggle class="mr-2" (click)="SatStatus(AddWorkForm.status)"
                                    formControlName="AddSatdayStatus">
                                    {{Satday}}</mat-slide-toggle>
                            </div>
                        </div>
                    </td>
                    <span *ngIf="satdayLeaveHide">
                        <span>
                            <td>
                                <div class="row">
                                    <mat-form-field class="col-4">
                                        <mat-label>Work Option</mat-label>
                                        <mat-select multiple maxlength="40" formControlName="drpSatWorkOptn">
                                            <mat-option *ngFor="let work of drpWorkOptn" [value]="work.workingWeek">
                                                {{ work.workingWeek }}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                    <mat-form-field class="col-4">
                                        <mat-label>Start At</mat-label>
                                        <mat-select maxlength="40" formControlName="drpSatStartAt">
                                            <mat-option *ngFor="let at of drpStartAt" [value]="at">
                                                {{ at.startTime }}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                    <mat-form-field class="col-4">
                                        <mat-label>Start End</mat-label>
                                        <mat-select (selectionChange)="onSatEndsChanges(AddWorkForm?.get('drpSatStartAt')?.value,
                                            AddWorkForm?.get('drpSatWorkOptn')?.value,
                                            AddWorkForm?.get('drpSatStartEnd')?.value)"
                                            formControlName="drpSatStartEnd">
                                            <mat-option
                                                *ngFor="let data of AddWorkForm?.get('drpSatStartAt')?.value?.endTimes"
                                                [value]="data.endTimeShortCodeId">
                                                {{data.endTime}}</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                            </td>
                            <td>
                                <div class="row">
                                    <div class="col-6 ml-2">
                                        <mat-form-field>
                                            <mat-select formControlName="drpSatUser" placeholder="Users" ngModelOptions>
                                                <mat-option *ngFor="let user of arrSatUsersList" [value]="user.userId">
                                                    {{user.userName}}
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>
                                    <div class="col-5">
                                        <div *ngIf="satMore" class="col-12" style="margin-top: 20px;">
                                            <span class="customThemeClass" style="cursor: pointer;"
                                                (click)="addSatday()" ><b>Add Saturday</b></span>
                                        </div>
                                    </div>
                                </div>
                            </td>
                        </span>

                        <span *ngIf="SatdayHide">
                            <td>
                                <div class="row">
                                    <mat-form-field class="col-4">
                                        <mat-label>Work Option</mat-label>
                                        <mat-select multiple maxlength="40" formControlName="drpSat2WorkOptn">
                                            <mat-option *ngFor="let work of drpWorkOptn" [value]="work.workingWeek">
                                                {{ work.workingWeek }}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>

                                    <mat-form-field class="col-4">
                                        <mat-label>Start At</mat-label>
                                        <mat-select maxlength="40" formControlName="drpSat2StartAt">
                                            <mat-option *ngFor="let at of drpStartAt" [value]="at">
                                                {{ at.startTime }}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>

                                    <mat-form-field class="col-4">
                                        <mat-label>Start End</mat-label>
                                        <mat-select (selectionChange)="onSat2Changes(AddWorkForm?.get('drpSat2StartAt')?.value,
                                            AddWorkForm?.get('drpSat2WorkOptn')?.value,
                                            AddWorkForm?.get('drpSat2StartEnd')?.value)"
                                            formControlName="drpSat2StartEnd">
                                            <mat-option
                                                *ngFor="let data of AddWorkForm?.get('drpSat2StartAt')?.value?.endTimes"
                                                [value]="data.endTimeShortCodeId">
                                                {{data.endTime}}</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                            </td>

                            <td>
                                <div class="row">
                                    <mat-form-field class="col-12 ml-2">
                                        <mat-select formControlName="drpSat2User" placeholder="Users" ngModelOptions>
                                            <mat-option *ngFor="let user of arrSat2UsersList" [value]="user.userId">
                                                {{user.userName}}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                            </td>
                        </span>
                    </span>
                </tr>
            </div>
        </table>
    </form>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-button class="buttonColor" (click)="getWorkingSave()">Save</button>
    <button class="buttonCancelColor" mat-button mat-dialog-close>Close</button>
</mat-dialog-actions>