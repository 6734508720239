import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import Swal from 'sweetalert2';
import { DataTableDirective } from 'angular-datatables';
import { Subscription } from 'rxjs';
import { MatTableDataSource } from '@angular/material/table';
import { PatientPersonalService } from '../patient-proxy/patient-optimization/patient-personal.service';
import { Title } from '@angular/platform-browser';
import { CommonService } from 'projects/shared/src/app/services/common.service';
@Component({
  selector: 'app-patient-new-insurance-review',
  templateUrl: './patient-new-insurance-review.component.html',
  styleUrls: ['./patient-new-insurance-review.component.scss']
})
export class PatientNewInsuranceReviewComponent implements OnInit {

  isLoading: boolean;
  newInsuranceListdata: any;
  dataSource: MatTableDataSource<any>;

  myTable: DataTables.Api;
  maxDate: Date;
  $subscription: Subscription[] = [];
  tableData$: Subscription;

  @ViewChild(DataTableDirective, { static: false }) datatableElement: DataTableDirective;
  constructor(
    private patientpersonalservices: PatientPersonalService,
    public title: Title,
    private commonService: CommonService,
  ) { }

  ngOnInit(): void {
    this.title.setTitle("Qsecure | New Insurance Review");
    this.getPatientInsuranceReview();
  }

  getPatientInsuranceReview(): void {
    this.tableData$?.unsubscribe();
    this.isLoading = true;
    const getPracticeListTableData = this.patientpersonalservices.patientwisePolicyDetailsV1().subscribe(
      (response) => {
        this.isLoading = false;
        this.newInsuranceListdata = response;

        this.newInsuranceListdata = this.newInsuranceListdata.map((data) => {
          return {
            ...data,
            dateOfBirth: this.commonService.getFormattedDateZone(
              data.dateOfBirth
            ),
          };
        });
      },
      (error) => {
        const data: HttpErrorResponse = error;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
        this.isLoading = false;
        this.newInsuranceListdata = [];
      }
    );
    this.tableData$ = getPracticeListTableData;
  }
}