<!-- <p>slider-date-picker works!</p> -->
<div class="row">
  <div class="col-12">
    <div class="mobileDatePicker">
      <div class="month">
        <!-- {{currentMonth | uppercase }} -->
        {{ currentYear }}
        <i class="material-icons monthIcon">calendar_today</i>
      </div>

      <div class="cdk-virtual-scroll-data-source-example" style="width: 100%">
        <cdk-virtual-scroll-viewport 
          orientation="horizontal"
          itemSize="60"
          minBufferPx="400"
          maxBufferPx="1000"
          class="example-viewport"
          (scrolledIndexChange)="changeMonth($event)"
        >
          <div *cdkVirtualFor="let item of displayedDates" class="example-item">
            <div
              class="item"
              (click)="select(item)"
              [ngClass]="{ 'is-active': isDateSelected(item) }"
            >
              <span class="weekName">{{ returnWeekDay(item) }}</span>
              <span> {{ item | date : "d" }}</span>
            </div>
          </div>
        </cdk-virtual-scroll-viewport>
      </div>

      <div></div>
    </div>
  </div>
</div>
