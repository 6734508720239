import type { AuditLogDTO, CreateUpdateAuditLogDTO } from './dto/models';
import { RestService } from '@abp/ng.core';
import type { PagedAndSortedResultRequestDto, PagedResultDto } from '@abp/ng.core';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class AuditLogService {
  apiName = 'Default';

  create = (input: CreateUpdateAuditLogDTO) =>
    this.restService.request<any, AuditLogDTO>({
      method: 'POST',
      url: '/api/app/audit-log',
      body: input,
    },
     { apiName: this.apiName, skipHandleError: true });

  delete = (id: string) =>
    this.restService.request<any, void>({
      method: 'DELETE',
      url: `/api/app/audit-log/${id}`,
    },
     { apiName: this.apiName, skipHandleError: true });

  get = (id: string) =>
    this.restService.request<any, AuditLogDTO>({
      method: 'GET',
      url: `/api/app/audit-log/${id}`,
    },
     { apiName: this.apiName, skipHandleError: true });

  getAuditLogList = () =>
    this.restService.request<any, AuditLogDTO[]>({
      method: 'GET',
      url: '/api/app/audit-log/audit-log-list',
    },
     { apiName: this.apiName, skipHandleError: true });

  getList = (input: PagedAndSortedResultRequestDto) =>
    this.restService.request<any, PagedResultDto<AuditLogDTO>>({
      method: 'GET',
      url: '/api/app/audit-log',
      params: { sorting: input.sorting, skipCount: input.skipCount, maxResultCount: input.maxResultCount },
    },
     { apiName: this.apiName, skipHandleError: true });

  update = (id: string, input: CreateUpdateAuditLogDTO) =>
    this.restService.request<any, AuditLogDTO>({
      method: 'PUT',
      url: `/api/app/audit-log/${id}`,
      body: input,
    },
     { apiName: this.apiName, skipHandleError: true });

  constructor(private restService: RestService) {}
}
