import type { CreateUpdatepatientTemplateDTO, Output, patientTemplateDTO } from './models';
import { RestService } from '@abp/ng.core';
import type { PagedAndSortedResultRequestDto, PagedResultDto } from '@abp/ng.core';
import { Injectable } from '@angular/core';
import { IFormFile } from '../../../microsoft/asp-net-core/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PatientTemplateService {
  apiName = 'platformManagement';

  create = (input: CreateUpdatepatientTemplateDTO) =>
    this.restService.request<any, patientTemplateDTO>({
      method: 'POST',
      url: '/api/PlatformApp/patient-template',
      body: input,
    },
    { apiName: this.apiName });

  delete = (id: string) =>
    this.restService.request<any, void>({
      method: 'DELETE',
      url: `/api/PlatformApp/patient-template/${id}`,
    },
    { apiName: this.apiName });

  get = (id: string) =>
    this.restService.request<any, patientTemplateDTO>({
      method: 'GET',
      url: `/api/PlatformApp/patient-template/${id}`,
    },
    { apiName: this.apiName });

  getList = (input: PagedAndSortedResultRequestDto) =>
    this.restService.request<any, PagedResultDto<patientTemplateDTO>>({
      method: 'GET',
      url: '/api/PlatformApp/patient-template',
      params: { skipCount: input.skipCount, maxResultCount: input.maxResultCount, sorting: input.sorting },
    },
    { apiName: this.apiName });

  getMailTemplateByBlobIDByBlobName = (blobName: string) =>
    this.restService.request<any, number[]>({
      method: 'GET',
      url: '/api/PlatformApp/patient-template/mail-template-by-blob-iD',
      params: { blobName },
    },
    { apiName: this.apiName });

  update = (id: string, input: CreateUpdatepatientTemplateDTO) =>
    this.restService.request<any, patientTemplateDTO>({
      method: 'PUT',
      url: `/api/PlatformApp/patient-template/${id}`,
      body: input,
    },
    { apiName: this.apiName });

    createManualUploadTemplateByFile = (file: IFormFile[]) =>
      this.restService.request<any, Output>({
        method: 'POST',
        url: '/api/PlatformApp/patient-template/create-manual-upload-template',
      },
      { apiName: this.apiName });

      createManualUploadTemplateByFileV1(files: File[]): Observable<Output> {
        const formData = new FormData();
        for (let i = 0; i < files.length; i++) {
          formData.append('file', files[i]);
        }
        return this.restService.request<any, Output>(
          {
            method: 'POST',
            url: `/api/PlatformApp/patient-template/create-manual-upload-template`,
            body: formData,
          },
          { apiName: this.apiName }
        );
      }
    
  constructor(private restService: RestService) {}
}
