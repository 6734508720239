import { RestService } from '@abp/ng.core';
import { Injectable } from '@angular/core';
import { CreateUpdateResupplyRemainderSettingDTO, ResupplyRemainderSetting, ResupplyRemainderSettingDTO } from './dto/models';

@Injectable({
  providedIn: 'root',
})
export class ResupplyRemainderSettingService {
  apiName = 'orderManagement';

  create = (input: CreateUpdateResupplyRemainderSettingDTO) =>
    this.restService.request<any, ResupplyRemainderSetting>({
      method: 'POST',
      url: '/api/orderApp/resupply-remainder-setting',
      body: input,
    },
    { apiName: this.apiName });

  delete = (gId: string) =>
    this.restService.request<any, string>({
      method: 'DELETE',
      responseType: 'text',
      url: '/api/orderApp/resupply-remainder-setting',
      params: { gId },
    },
    { apiName: this.apiName });

  getAllBasedOnActiveStatusByIsActive = (isActive: boolean) =>
    this.restService.request<any, ResupplyRemainderSettingDTO[]>({
      method: 'GET',
      url: '/api/orderApp/resupply-remainder-setting/based-on-active-status',
      params: { isActive },
    },
    { apiName: this.apiName });

  getByIDBySupplyID = (supplyID: string) =>
    this.restService.request<any, ResupplyRemainderSettingDTO>({
      method: 'GET',
      url: '/api/orderApp/resupply-remainder-setting/by-iD',
      params: { supplyID },
    },
    { apiName: this.apiName });

  update = (Id: string, input: CreateUpdateResupplyRemainderSettingDTO) =>
    this.restService.request<any, string>({
      method: 'PUT',
      responseType: 'text',
      url: '/api/orderApp/resupply-remainder-setting',
      params: { id: Id },
      body: input,
    },
    { apiName: this.apiName });

  updateActiveStatusByIdAndIsActive = (Id: string, isActive: boolean) =>
    this.restService.request<any, string>({
      method: 'PUT',
      responseType: 'text',
      url: `/api/orderApp/resupply-remainder-setting/active-status/${Id}`,
      params: { isActive },
    },
    { apiName: this.apiName });

  constructor(private restService: RestService) {}
}
