<mat-accordion #secondAccordion="matAccordion">
    <mat-expansion-panel [expanded]="panelOpenState">
      <mat-expansion-panel-header>
        <mat-panel-title class="customThemeClass">
          <ng-container *ngIf="faxid===''; else elseTitleTemplate1">
            <b>Add Fax/Email</b>
          </ng-container>
          <ng-template #elseTitleTemplate1>
            <b>Edit Fax/Email</b>
          </ng-template>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <form [formGroup]="FaxForm">
        <div class="row">
          <!-- Location Dropdown -->
          <mat-form-field class="col-3">
            <mat-label>Branch<span class="asterisk">*</span></mat-label>
            <mat-select #selectBranch formControlName="drpBranchType" [(ngModel)]="seletedBranchValue">
              <mat-option>
                <ngx-mat-select-search [formControl]="BranchTypeControl" placeholderLabel="Search"
                    noEntriesFoundLabel="No Matches found"
                    formControlName="txtBranchType"></ngx-mat-select-search>
            </mat-option>

              <mat-option *ngFor="let branch of filteredBranchTypeList" [value]="branch.id">
                {{ branch.organizationUnitName }}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field class="col-3">
            <mat-label>Fax/Email<span class="asterisk">*</span></mat-label>
            <mat-select #selectBranch formControlName="drpFaxType" [(ngModel)]="seletedFaxValue"
              (selectionChange)="onReferenceChange()">
              <mat-option [value]=0>Email</mat-option>
              <mat-option [value]=1>Fax</mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field class="col-3" [hidden]="seletedFaxValue !== 1">
            <mat-label>Fax Number <span class="asterisk">*</span></mat-label>
            <input formControlName="txtFaxNumber" matInput maxlength="40" prefix="+1-" mask="(000)-000-0000"
              type="text" autocomplete="new-txtFaxNumber" />
            <mat-error
              *ngIf="FaxForm?.get('txtFaxNumber')?.touched && FaxForm?.get('txtFaxNumber')?.errors?.required">
              Enter Minimum 10 Digits!
            </mat-error>
            <mat-error *ngIf="FaxForm?.get('txtFaxNumber')?.errors?.isFaxNumberExists">
              Fax Number is already taken!
            </mat-error>
          </mat-form-field>

          <mat-form-field class="col-3" [hidden]="seletedFaxValue !== 1">
            <mat-label>Fax Name <span class="asterisk">*</span></mat-label>

            <input autocomplete="new-txtFaxName" matInput maxlength="40" formControlName="txtFaxName" type="text"
              pattern="[a-zA-Z 0-9]*" />
            <mat-error *ngIf="FaxForm?.get('txtFaxName')?.touched && FaxForm?.get('txtFaxName')?.errors?.required">
              Enter Fax Name!
            </mat-error>
          </mat-form-field>

          <mat-form-field class="col-3">
            <!-- pattern="[a-z0-9._!#$%&'()*+,-./:;<=>?@[\]^_`{|}~]+@[a-z0-9.-]+\.[a-z]{2,3}$" -->
            <mat-label> Email Id <span class="asterisk">*</span></mat-label>
            <input autocomplete="new-txtEmail" matInput maxlength="40" formControlName="txtEmail" type="text"
            pattern="[a-zA-Z0-9._!#$%&'()*+,-./:;<=>?@[\]^_`{|}~]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$"
              [textMask]="{ mask: emailMask }" />

            <mat-error *ngIf="FaxForm?.get('txtEmail')?.touched &&
            FaxForm?.get('txtEmail')?.errors?.required ">
              Email Address is a required field!
            </mat-error>
            <mat-error *ngIf="
                          FaxForm?.get('txtEmail')?.touched &&
                          FaxForm?.get('txtEmail')?.errors?.pattern
                          ">
              Please Enter a Valid Email Address
            </mat-error>
            <mat-error *ngIf="FaxForm?.get('txtEmail')?.errors?.isEmailAddressExists">
              Email is already taken!
            </mat-error>
          </mat-form-field>

          <mat-form-field class="col-3">
            <mat-label> Email Password <span class="asterisk">*</span></mat-label>
            <input matInput maxlength="40" [type]="hide ? 'password' : 'text'" formControlName="txtPassword"
              autocomplete="new-password" pattern="(?=.*\d)(?=.*[a-z])(?=.*[$@$#!%*?^()&])(?=.*[A-Z]).{8,}"
              type="text" />
            <a class="font-size-20" mat-icon-button matSuffix (click)="hide = !hide">
              <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
            </a>
            <mat-error *ngIf="
                  FaxForm?.get('txtPassword')?.touched &&
                  FaxForm?.get('txtPassword')?.errors?.required
                  ">
              Password is a required field!
            </mat-error>

            <mat-error *ngIf="
                  FaxForm?.get('txtPassword')?.touched &&
                  FaxForm?.get('txtPassword')?.errors?.pattern
                ">
              Passwords must contain at least one non alphanumeric character,one uppercase,one digit
              and at
              least 8 or more characters
            </mat-error>
          </mat-form-field>

          <mat-form-field class="col-3">
            <mat-label> Port Number <span class="asterisk">*</span></mat-label>
            <input formControlName="txtEmailPort" matInput maxlength="5" pattern="[0-9]*" type="text"
              autocomplete="new-txtEmailPort" />
            <mat-error
              *ngIf="FaxForm?.get('txtEmailPort')?.touched && FaxForm?.get('txtEmailPort')?.errors?.required">
              Port Number is a required field!
            </mat-error>
            <mat-error
              *ngIf="FaxForm?.get('txtEmailPort')?.touched && FaxForm?.get('txtEmailPort')?.errors?.required && FaxForm?.get('txtEmailPort')?.errors?.pattern">
              Enter only Numbers
            </mat-error>
          </mat-form-field>

          <mat-form-field class="col-3">
            <mat-label> Host Name <span class="asterisk">*</span></mat-label>
            <input autocomplete="new-txtEmailHost" matInput maxlength="50" formControlName="txtEmailHost"
              type="text" />
            <mat-error
              *ngIf="FaxForm?.get('txtEmailHost')?.touched && FaxForm?.get('txtEmailHost')?.errors?.required">
              Host Name is a required field!
            </mat-error>
          </mat-form-field>

          <mat-form-field class="col-3">
            <mat-label>Server Protocol <span class="asterisk">*</span></mat-label>

            <input autocomplete="new-txtEmailProtocol" matInput maxlength="50" formControlName="txtEmailProtocol"
              type="text" pattern="[A-Za-z0-9\.]*" />
            <mat-error
              *ngIf="FaxForm?.get('txtEmailProtocol')?.touched && FaxForm?.get('txtEmailProtocol')?.errors?.required">
              Server Protocol a required field!
            </mat-error>
          </mat-form-field>

        </div>
      </form>
      <br />
      <div class="row">
        <div class="col-sm-12">
          <div class="text-lg-center">
            <!-- <button mat-button class="buttonColor mr-2" *ngIf="branchId===''" (click)="openAddWorkingModel()">
            Add Branch hours</button> -->
            <button *ngIf="inActiveHide" [disabled]="saveButtonHide||FaxForm.invalid ||(seletedFaxValue===1&&FaxForm.get('txtFaxNumber').value==='')||(seletedFaxValue===1&&FaxForm.get('txtFaxName').value==='')
          ||(seletedFaxValue===1&&FaxForm.get('txtFaxName').value===null)" mat-button class="buttonColor mr-2"
              (click)="saveFax()">
              Save
              <mat-icon *ngIf='isShowSpinner'>
                <mat-spinner class="spinner-border spinner-border-sm" diameter="20"> </mat-spinner>
              </mat-icon>
            </button>
            <!-- <button (click)="confirmBranchLocation()"*ngIf="branchId===''" mat-button class="buttonColor mb-2 mr-2">
            [disabled]="!(!BranchForm.invalid)"
            Confirm location
          </button> -->
            <button mat-button class="resetclr buttonColor mb-2 mr-2" (click)="faxReset()">Reset</button>
          </div>
        </div>
      </div>
    </mat-expansion-panel>
  </mat-accordion>
  <div class="card card-body mt-3">
    <mat-radio-group [(ngModel)]="selectedOption" class="radio-buttons">
      <mat-radio-button class="Inactive" (click)="FaxStatusFilterv1(0)" value="All"><b>All</b></mat-radio-button>
      <mat-radio-button class="Inactive p-2" (click)="FaxStatusFilterv1(1)" value="Active"
        [checked]="true"><b>Active</b></mat-radio-button>
      <mat-radio-button class="Inactive" (click)="FaxStatusFilterv1(2)"
        value="InActive"><b>Inactive</b></mat-radio-button>
    </mat-radio-group>
    <br />

      <div class="col-lg-12 content table-responsive p-0">
        <div class="col-12">
          <div class="d-flex justify-content-center" *ngIf="isLoading">
            <img class="img-responsive pb-2 mr-1" src="assets/gifs/Infinity-loader.gif" />
          </div>
        </div>
        <!-- <table mat-table class="table ml-4"style=" margin-left: 3px !important; border: 1px solid #cdc7c7" matSort [dataSource]="dataSource"> -->
        <table id="tblFax" datatable [dtOptions]="dtFaxEmailListOptions" *ngIf="!isLoading"
          class="tbl hover row-border hover w-100 display">
          <thead>
            <tr>
              <th><mat-icon>apps</mat-icon></th>
              <th [matTooltip]="'Status'">Status</th>
              <th [matTooltip]="'Branch Name'">Branch Name</th>
              <th [matTooltip]="'Fax Name'">Fax Name</th>
              <th class="text-right" [matTooltip]="'Fax Number'">Fax Number</th>
              <th [matTooltip]="'Email'">Email</th>
              <th class="text-right" [matTooltip]="'Port Number'">Port Number</th>
              <th [matTooltip]="'Server Protocol'">Server Protocol</th>
              <th [matTooltip]="'Host Name'">Host Name</th>
              <th [matTooltip]="'Created By'">Created By</th>
              <th class="text-right" [matTooltip]="'Created Date'">Created Date</th>
              <th [matTooltip]="'Modify By'">Modify By</th>
              <th class="text-right" [matTooltip]="'Modified Date'" >Modified Date</th>
              <th [matTooltip]="'isFax/Email'">isFax/Email</th>
              

            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let fax of faxDetails" [ngClass]="{stat_highlight:fax.isStat == true}">
              <td>
                <a class="eyeCursorclass" [matTooltip]="'Edit Fax/Email'"><i *ngIf="fax?.isActive===1"
                    (click)="ViewFax(fax)" class="fa fa-pencil editBtnColor mr-1"></i>
                </a>
                <!-- &nbsp;
            <a class="deleteBtnColor" (click)="deleteFax(fax)">
              <i class="fa fa-trash"></i>
            </a> -->
              </td>
              <td [matTooltip]="fax?.isActive ? 'Active' : 'Inactive' || '-'">
                <ng-container *ngIf="fax?.isActive===1">
                  <mat-slide-toggle (change)="faxStatusChange($event, fax)" [checked]='true' class="ml-2">
                  </mat-slide-toggle>
                </ng-container>
                <ng-container *ngIf="fax?.isActive===0">
                  <mat-slide-toggle (change)="faxStatusChange($event, fax)" [checked]='false' class="ml-2">
                  </mat-slide-toggle>
                </ng-container>
              </td>
              <td [matTooltip]="fax.branchName || '-'" (dblclick)="onRowDoubleClick(fax?.branchName || '-')">{{ fax.branchName || '-'}}</td>
              <td [matTooltip]="fax.faxName || '-'" (dblclick)="onRowDoubleClick(fax?.faxName || '-')"> {{ fax.faxName
                == null || fax.faxName == "" ? '-' : fax.faxName }}</td>
              <td class="text-right" [matTooltip]="fax.faxNumber || '-'" (dblclick)="onRowDoubleClick(fax?.faxNumber || '-')"> {{
                fax.faxNumber == null || fax.faxNumber == "" ? '-' : fax.faxNumber }}</td>
              <td [matTooltip]="fax.mailId || '-'" (dblclick)="onRowDoubleClick(fax?.mailId || '-')">{{ fax.mailId ==
                null || fax.mailId == "" ? '-' :fax.mailId}}</td>
              <td class="text-right" [matTooltip]="fax.portNumber || '-'" (dblclick)="onRowDoubleClick(fax?.portNumber || '-')">
                {{fax.portNumber== null || fax.portNumber == "" ? '-' :fax.portNumber}}</td>
              <td [matTooltip]="fax.serverProtocol || '-'" (dblclick)="onRowDoubleClick(fax?.serverProtocol || '-')">
                {{fax.serverProtocol== null ? '-' :fax.serverProtocol}}</td>
              <td [matTooltip]="fax.hostName || '-'" (dblclick)="onRowDoubleClick(fax?.hostName || '-')">
                {{fax.hostName== null || fax.hostName == "" ? '-' :fax.hostName}}</td>
                <td [matTooltip]="fax.createdBy || '-'" (dblclick)="onRowDoubleClick(fax?.createdBy || '-')">
                  {{fax.createdBy== null || fax.createdBy == "" ? '-' :fax.createdBy}}</td>
              <td class="text-right" [matTooltip]="fax?.createdDate"
                (dblclick)="onRowDoubleClick(fax?.createdDate)" class="text-center">
                {{ fax?.createdDate }}
              </td>
                             <td [matTooltip]="fax?.modifyBy|| '-'"
                    (dblclick)="onRowDoubleClick(fax?.modifyBy || '-')">{{
                      fax?.modifyBy || '-' }}</td>

                <td class="text-right" [matTooltip]="fax?.lastModificationTime"
                    (dblclick)="onRowDoubleClick(fax?.lastModificationTime)" >
                    {{ fax?.lastModificationTime }}
                </td>
              <td [matTooltip]="fax?.isFax ? 'Fax' : 'Email' || '-'" (dblclick)="onRowDoubleClick(fax?.isFax ? 'Fax' : 'Email')">{{ fax.isFax === 1 ? 'Fax' : 'Email' }}</td>
              
            </tr>
          </tbody>
          <tfoot>
            <tr>
              <th><mat-icon>apps</mat-icon></th>
              <th>
                <input matInput type="text" class="textbox-border-class" placeholder="Status"
                  name="search-Status" />
              </th>
              <th>
                <input matInput type="text" class="textbox-border-class" placeholder="Branch Name" name="search-BranchName" />
              </th>
              <th>
                <input matInput class="textbox-border-class" type="text" placeholder="Fax Name"
                  name="search-FaxName" />
              </th>
              <th class="text-right">
                <input matInput type="text" class="textbox-border-class" placeholder="Fax Number" name="search-FaxNumber" />
              </th>
              <th>
                <input matInput type="text" class="textbox-border-class" placeholder="Email"
                  name="search-Email" />
              </th>
              <th class="text-right">
                <input matInput type="text" class="textbox-border-class" placeholder="Port Number"
                  name="search-PortNumber" />
              </th>
              <th>
                <input matInput type="text" class="textbox-border-class" placeholder="Server Protocol"
                  name="search-ServerProtocol" />
              </th>
              <th>
                <input matInput type="text" class="textbox-border-class" placeholder="Host Name"
                  name="search-HostName" />
              </th>
              <th>
                <input matInput type="text" class="textbox-border-class" placeholder="Created By"
                  name="search-Created By" />
              </th>
              <th class="text-right">
                <input matInput type="text" class="textbox-border-class" placeholder="Created Date"
                  name="search-Created Date" />
              </th>
              <th>
                <input matInput class="textbox-border-class" type="text" placeholder="Modify By"
                    name="search-Modify By" />
            </th>
              <th class="text-right">
                  <input matInput class="textbox-border-class " type="text"
                      placeholder="Modified Date" name="search-Modified Date" />
              </th>
              <th>
                <input matInput type="text" class="textbox-border-class" placeholder="isFax/Email"
                  name="search-isFax/Email" />
              </th>
             
            </tr>
          </tfoot>
        </table>
      </div>
  </div>
