import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { PDFDocument } from 'pdf-lib';
import { InboundDocumentService } from 'projects/admin/src/app/admin-proxy/platform-app-management/rcm/platform-management/inbound';
import { InboundDocumentDTO } from 'projects/admin/src/app/admin-proxy/platform-app-management/rcm/platform-management/inbound/dto';
import { OrganizationUnitDTO } from 'projects/admin/src/app/admin-proxy/platform-app-management/rcm/platform-management/organization-units/dto';
import { IFormFile } from 'projects/patient/src/app/patient-proxy/microsoft/asp-net-core/http';
import { PatientPersonalService } from 'projects/patient/src/app/patient-proxy/patient-optimization';
import { CommonDoumentViewerForMedicareComponent } from 'projects/shared/src/app/components/common-doument-viewer-for-medicare/common-doument-viewer-for-medicare.component';
import { defaultGuid, DocumentMaxandTypeLimits } from 'projects/shared/src/app/enums/allenums.enum';
import { Observable, Subscription } from 'rxjs';
import Swal from 'sweetalert2';
import emailMask from 'text-mask-addons/dist/emailMask';

@Component({
  selector: 'app-rxMailSend',
  templateUrl: './rxMailSend.component.html',
  styleUrls: ['./rxMailSend.component.scss']
})
export class RxMailSendComponent implements OnInit {

  sendMailForm: FormGroup;
  newFilesForUpload: File[] = [];
  // newFilesForUpload: FileList = new DataTransfer().files;
  documentMaxNoFileLimit = DocumentMaxandTypeLimits.documentMaxNoFileLimit;
  AddMoreFileSize: any;
  isSaveLoading:boolean = false;
  width : any=1;
  inboundUploadForm: FormGroup;
  showErrorBasedOnMaxSize: boolean = false;
  // showErrorBasedOnSize: boolean = false;
  showErrorForFileType: boolean = false;
  showErrorForFileLength: boolean = false;
  selectedFileUploadsData: Partial<InboundDocumentDTO> | null = null;
  arrSelectedDocuments: any[] = [];
  arrPageCount: number[] = [];
  filteredBranchTypeList: OrganizationUnitDTO[];
  selectedFileCount: number = 0;
  totalSizeforTable: any;
  files:any;
  isProcessBarShow:boolean=true;
  emailMask: any;
  ltRXDocument: number[] = [];
  manualUploadDocuments:File[];
  documentId: any;
  filesProcessedV1: any=1;
defaultGuid= defaultGuid;
  tempDocumentId: any;
  tempMail: any;
  tempDocName: any;
  constructor(
    private fb: FormBuilder,
    private toastr: ToastrService,
    private dialog: MatDialog,
    private patientPersonalService: PatientPersonalService,
    private InboundDocService: InboundDocumentService,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      patientId: string;
      orderId:string;
      chartId:string;
    } = {
      patientId: defaultGuid,
      orderId: defaultGuid,
      chartId:'-'
    },
  ) {
  }

  ngOnInit() {
    this.emailMask = emailMask;
    this.getPatientDocdetails();
    this.initializeForms();
  }
  initializeForms(){
    this.sendMailForm = this.fb.group({
      documentFile: new FormControl(null ),
      txtEmail:new FormControl('', [Validators.required]),
      document:new FormControl('',[Validators.required]),
    });
  }
  getPatientDocdetails(){
    this.patientPersonalService.getPatientRxFileDetails(this.data.patientId).subscribe(
      (response) => {
        this.documentId=response.documentId;
        this.sendMailForm.patchValue({
          document:response.documentName,
          txtEmail:response.practiceEmail
        })

        this.tempDocumentId=this.documentId;
        this.tempDocName=response.documentName,
        this.tempMail=response.practiceEmail;
      },
      (error: HttpErrorResponse) => {
          const data: HttpErrorResponse = error;
          this.toastr.warning(
            data?.error?.message,
            'Error'
          );
      }
    );
  }

  onFileChange(event: any): void {

    // this.runProgressBar();
    this.width=1;
    const files = event?.target?.files;
    this.sendMailForm.patchValue({
      document: this.tempDocName,
    });
    if (files.length === 0) {
      this.showErrorForFileType = false;
      this.showErrorBasedOnMaxSize = false;
      this.showErrorForFileLength = false;
      this.arrSelectedDocuments = [];
      // this.filesForUpload = [];
      this.newFilesForUpload = [];
      this.selectedFileCount = 0;
      return;
    }

    if (this.calculateTotalSize(files)) {
      this.showErrorForFileType = false;
      this.showErrorBasedOnMaxSize = false;
      this.showErrorForFileLength = false;
      this.arrSelectedDocuments = [];
      // this.filesForUpload = files;
      this.newFilesForUpload = [];
      this.mergeFileLists(files);
      this.selectedFileCount = files.length;
      this.totalSizeforTable = this.calculateTotalSizeValue(files);
      const totalFiles = files.length;
      let filesProcessed = 0;
      if (files && files.length > 0) {
        let fileResult: {
          fileName: string;
          fileType: string;
          fileSize: string;
          pageCount?: number;
        }[] = [];
        const fileList: File[] = [];
        const onCompleteProcessing = () => {
          filesProcessed++;
          this.filesProcessedV1++;
          Observable.timer(0,totalFiles)
          .takeWhile(()=>
             this.isWidthWithinLimit(filesProcessed,totalFiles)
             )
          .subscribe(()=>{
            this.isProcessBarShow = true;
            this.width=(this.filesProcessedV1/totalFiles)*100;
          })
        };
        for (let i = 0; i < files.length; i++) {
          if (files[i].type !== DocumentMaxandTypeLimits.documentTypeLimit) {
            this.showErrorForFileType = true;
            this.showErrorBasedOnMaxSize = false;
            this.showErrorForFileLength = false;
            this.arrSelectedDocuments = [];
            this.selectedFileCount = 0;
            return;
          }
          fileList.push(files[i]);
          const fileName: string = files[i].name;
          const m1fileName: string = fileName.replace('.pdf', '');
          const m2fileName: string = m1fileName.replace('.pdf', '');

          // const getPDFPageCount = async (file: File) => {
          //   const pdfDoc = await PDFDocument.load(await file.arrayBuffer());
          //   const pages = pdfDoc.getPages();
          //   return pages.length;
          // };

          const getPDFPageCount = async (file: File) => {
            try {
              const pdfBytes = await file.arrayBuffer();
              const pdfDoc = await PDFDocument.load(pdfBytes, { ignoreEncryption: true }); // Add { ignoreEncryption: true }
              const pages = pdfDoc.getPages();
              return pages.length;
            } catch (error) {
              console.warn(error);
              return 0; // Return a default value or handle the error as per your requirement
            }
          };

          getPDFPageCount(files[i]).then((pageCount) => {
            fileResult.push({
              fileName: m2fileName,
              fileType: files[i].type === 'application/pdf' ? 'PDF' : 'N/A',
              fileSize: this.convertBytesToReadable(files[i].size),
              pageCount: pageCount,
            });
            onCompleteProcessing();
          });
          this.sendMailForm.patchValue({
            document: (this.sendMailForm.value.document === null ||this.sendMailForm.value.document === null||this.sendMailForm.value.document === undefined
             ) ?m2fileName: this.sendMailForm.value.document + ',' + m2fileName
          });
        }

        this.arrSelectedDocuments = fileResult;

      }
      // event.target.value = null;
    } else {
      this.showErrorForFileType = false;
      this.showErrorBasedOnMaxSize = true;
      this.showErrorForFileLength = false;
      this.arrSelectedDocuments = [];
      this.selectedFileCount = 0;
      // event.target.value = null;
      return;
    }

  }

  isWidthWithinLimit(filesProcessed,totalFiles){
    if(filesProcessed===totalFiles){
      this.isProcessBarShow = false;
      this.filesProcessedV1=1;
      this.width=1;
      return false;
    }else{
      this.isProcessBarShow = true;
      return true;
    }
  }
  mergeFileLists(newFiles) {
    for (let i = 0; i < newFiles.length; i++) {
      const file = newFiles[i];
      // Check if the file already exists in the array
      const exists = this.newFilesForUpload.some(
        (existingFile) =>
          existingFile.name === file.name && existingFile.size === file.size
      );
      if (!exists) {
        this.newFilesForUpload.push(file);
        // this.toastr.success(`File "${file.name}" Added.`, 'Success' );
      }
      // else {
      //   this.toastr.warning(`File "${file.name}" already exists.`, 'Warning' );
      // }
    }
  }

  calculateTotalSize(files) {
    let totalSize = 0;
    let status = false;
    let maxTotalSize = DocumentMaxandTypeLimits.helloSignFileSizeLimit;
    for (const key in files) {
      if (files.hasOwnProperty(key)) {
        totalSize += files[key].size;
      }
    }
    if (totalSize <= maxTotalSize) {
      status = true;
    }
    return status;
  }

  calculateTotalSizeValue(files) {
    this.AddMoreFileSize = 0;
    let totalSize = 0;
    for (const key in files) {
      if (files.hasOwnProperty(key)) {
        totalSize += files[key].size;
      }
    }
    this.AddMoreFileSize = totalSize;
    return this.convertBytesToReadable(totalSize);
  }

  AddcalculateTotalSizeValue(files) {
    if(this.AddMoreFileSize===undefined||this.AddMoreFileSize===null){
      this.AddMoreFileSize=0;
    }
    let totalSize = 0;
    for (const key in files) {
      if (files.hasOwnProperty(key)) {
        totalSize += files[key].size;
      }
    }
    this.AddMoreFileSize += totalSize;
    return this.convertBytesToReadable(this.AddMoreFileSize);
  }


  convertBytesToReadable(bytes) {
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    if (bytes == 0) return '0 Byte';
    if (isNaN(bytes)) return '0 Bytes';
    const i = Math.floor(Math.log(bytes) / Math.log(1024));
    return parseFloat((bytes / Math.pow(1024, i)).toFixed(2)) + ' ' + sizes[i];
  }

  /* #region RX Document Selection */
  selectRXDocument() {
    const config: MatDialogConfig = {
      width: '100vw',
      height: '100vh',
      maxWidth: '100vw',
      maxHeight: '100vh',
      panelClass: 'fullscreen-dialog-panel',
      disableClose: true,
      data: {
        previewList: this.ltRXDocument,
        docId: this.documentId,
        isMedicare: false,
        isView: false,
      },
    };
    const dialogRef = this.dialog.open(
      CommonDoumentViewerForMedicareComponent,
      config
    );

    const closeDialog = dialogRef
      .afterClosed()
      .subscribe((selectedFileDetails: any) => {
        this.ltRXDocument = selectedFileDetails;
      });
  }

   //To Save the Verification Details
   sendRxMail() {
    this.isSaveLoading=true;
      this.InboundDocService
        .sendRxDocWithPDF( this.documentId,this.data.orderId,this.data.patientId,this.sendMailForm.value.txtEmail.toLowerCase(),
          (this.ltRXDocument).length <= 0 ? "" : this.ltRXDocument.toString(),
          this.newFilesForUpload
        )
        .subscribe(
          (response) => {
            this.toastr.success('Mail sended  Successfully', 'Success');
            this.isSaveLoading=false;
            this.dialog.closeAll();
          },
          (err) => {
            this.isSaveLoading=false;
            const data: HttpErrorResponse = err;
            Swal.fire({
              icon: 'info',
              text: data?.error?.error?.message,
            });
          }
        );
  }

  /* #endregion */
  removeDocument(){
    this.sendMailForm.patchValue({
      document:null
    })
     this.newFilesForUpload=[];
     this.ltRXDocument=[];
     this.documentId = defaultGuid;
  }
  resetForm(){
    this.sendMailForm.reset({
      document: this.tempDocName,
      txtEmail: this.tempMail,
      documentFile:null,
    });
    this.documentId = this.tempDocumentId ;
    this.newFilesForUpload=[];
    this.ltRXDocument=[];
  }
}




