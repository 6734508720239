import type { CreateUpdateMmOrderComplianceDTO, MmOrderComplianceDTO } from './dto/models';
import { RestService } from '@abp/ng.core';
import type { PagedAndSortedResultRequestDto, PagedResultDto } from '@abp/ng.core';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class MmOrderComplianceService {
  apiName = 'orderManagement';

  create = (input: CreateUpdateMmOrderComplianceDTO) =>
    this.restService.request<any, MmOrderComplianceDTO>({
      method: 'POST',
      url: '/api/orderApp/mm-order-compliance',
      body: input,
    },
    { apiName: this.apiName });

  delete = (id: string) =>
    this.restService.request<any, void>({
      method: 'DELETE',
      url: `/api/orderApp/mm-order-compliance/${id}`,
    },
    { apiName: this.apiName });

  get = (id: string) =>
    this.restService.request<any, MmOrderComplianceDTO>({
      method: 'GET',
      url: `/api/orderApp/mm-order-compliance/${id}`,
    },
    { apiName: this.apiName });

  getList = (input: PagedAndSortedResultRequestDto) =>
    this.restService.request<any, PagedResultDto<MmOrderComplianceDTO>>({
      method: 'GET',
      url: '/api/orderApp/mm-order-compliance',
      params: { skipCount: input.skipCount, maxResultCount: input.maxResultCount, sorting: input.sorting },
    },
    { apiName: this.apiName });

  update = (id: string, input: CreateUpdateMmOrderComplianceDTO) =>
    this.restService.request<any, MmOrderComplianceDTO>({
      method: 'PUT',
      url: `/api/orderApp/mm-order-compliance/${id}`,
      body: input,
    },
    { apiName: this.apiName });

  updateComplainceStatusByIdAndStatusId = (Id: string, StatusId: string) =>
    this.restService.request<any, string>({
      method: 'PUT',
      responseType: 'text',
      url: '/api/orderApp/mm-order-compliance/complaince-status',
      params: { id: Id, statusId: StatusId },
    },
    { apiName: this.apiName });

  constructor(private restService: RestService) {}
}
