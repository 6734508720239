import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Store } from '@ngxs/store';
import { IdentityRoleDto, IdentityUserDto } from 'projects/admin/src/app/admin-proxy/platform-app-management/identity/models';
import { GetRoleListUsingTenantIdDto } from 'projects/admin/src/app/admin-proxy/platform-app-management/rcm/platform-management/role/models';
import { RoleService } from 'projects/admin/src/app/admin-proxy/platform-app-management/rcm/platform-management/role/role.service';
import { UserService } from 'projects/admin/src/app/admin-proxy/platform-app-management/rcm/platform-management/user/user.service';
import { ReminderService } from 'projects/order/src/app/order-proxy/order-management/order/reminder.service';
import { addReminder, getReminder, updateReminder } from 'projects/order/src/app/order-store/reminder.action';
import { getPatient } from 'projects/patient/src/app/patient-store/patient.action';
import { TableService } from 'projects/shared/src/app/table.service';
import { TextValidators } from 'projects/shared/src/app/validations/text-validator';
import { map, startWith } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { ClaimDTO, ReminderDTO } from '../billing-proxy/billing-management/posting/dto/models';
import { getBilling, updateBilling } from '../billing-store/billing.action';
import { ToastrService } from "ngx-toastr";

@Component({
  selector: 'app-billing-reminder',
  templateUrl: './billing-reminder.component.html',
  styleUrls: ['./billing-reminder.component.scss']
})
export class BillingReminderComponent implements OnInit {
  userId: string = "";
  tenantId: string = "";
  arrUsersList: IdentityUserDto[] = [];
  reminderForm: FormGroup;
  patientVisitNotesTableForm: FormGroup;
  reminderId: string = '00000000-0000-0000-0000-000000000000';
  isShown: boolean = false;
  minDate: Date;
  saveButtonHide: boolean;
  drpRoles: any[] = [];
  clientId: string = "";
  roleId: string = "";
  tblPatientVisitNotesData: any[] = [];
  dataSource = new MatTableDataSource(this.tblPatientVisitNotesData);
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild('MatPaginator', { static: false }) paginator: MatPaginator;
  displayedColumns: string[] = [

    'name',
    'scheduleDate',
    'role',
    'completedOn',
    'options',

  ];
  isLoading: boolean = false;

  constructor(
    private reminder: ReminderService,
    // private list: ListService,
    private table: TableService,
    private formBuilder: FormBuilder,
    private store: Store,
    public dialog: MatDialog,
    public userService: UserService,
    private textValidators: TextValidators,
    private toastr: ToastrService,
    public dialogRef: MatDialogRef<BillingReminderComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {
      patientId?: string;
      saleOrderId?: string;
      claimId: string;
      patientName: string;
      defaultPatientId: string
    },
    private roleService: RoleService,
  ) { }


  //! Page Initialization funtions
  ngOnInit(): void {
    this.clientId = localStorage.getItem("tenantId") ?? "";

    this.minDate = new Date();
    this.minDate.setDate(this.minDate.getDate() + 0);
    this.userId = localStorage.getItem("userId");
    this.tenantId = localStorage.getItem("tenantId");
    this.roleId = localStorage.getItem("roleId");
    this.createReminderForm();
    this.patientVisitNotesTableForm = this.formBuilder.group({
      name: '',
      scheduleDate: '',
      completedOn: '',
      role: ''
    });
    // this.data.Id !== null && this.data.Id !== undefined && this.data.Id !== "" && this.getReminderByid(this.data.Id);

    this.loadRoleDropdown();
    this.userService.getIdentityUser().subscribe((response: IdentityUserDto[]) => {
      this.arrUsersList = response;
    }, err => {
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    })
    this.minDate = new Date();
    this.minDate.setDate(this.minDate.getDate() + 0);
  }


  //! load role dropdown
  filterRole;
  loadRoleDropdown() {
    let roleList: GetRoleListUsingTenantIdDto = {
      tenantId: this.clientId
    }
    this.roleService.getRoleList(roleList).subscribe(response => {
      this.drpRoles = []
      response?.forEach(ele => {
        if (ele?.Id === this.roleId) {
          this.drpRoles.push({
            id: ele?.Id,
            name: ele?.Name
          })
        }

      })
      this.filterRole = this.reminderForm?.get("txtRoleFilter").valueChanges
        .pipe(
          startWith(''),
          map(value => this.drpRoles.filter(option => option?.id?.toLowerCase()?.includes(value?.toLowerCase() ?? "")))
        );
      // this.drpRoles = [];
    }, err => {
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    })
    setTimeout(() => {
      this.getTableData();
    }, 1000);
  }
  viewPatientVisitNotes(datas: ReminderDTO) {

    this.reminderForm.patchValue({

      txtSubject: datas?.reminderNotes,
      txtDate: datas?.dueDate,
      // //rbtnRemiderStatus:new FormControl(""),
      // chkDontShowAgain: new FormControl(""),
      // chkCompleted: new FormControl(""),
      // chkAllUsers: new FormControl(""),
      drpAssignedTo: datas?.roleId,
      txtPatient: datas?.patient,
      txtCompletedBy: datas?.completedBy,
      dtCompletedOn: datas?.completedOn,
      // dtEnteredDateTime:,
    })
    this.reminderId = datas?.reminderId;
  }

  ///// get by reminder id
  getReminderByid(Id: string) {
    this.isShown = true;
  }

  /// table datas get
  getTableData() {
    this.store.dispatch(new getBilling(this.data.claimId)).subscribe(response => {

      let claimDetails: ClaimDTO = response.billingState.selectedBilling;
      this.tblPatientVisitNotesData = [];

      claimDetails && claimDetails?.lstReminders != null &&
        claimDetails?.lstReminders.forEach(element => {
          let roleName = this.drpRoles?.filter(value => {
            return value && value?.id === element?.roleId
          })[0]?.name;
          this.tblPatientVisitNotesData.push({
            reminderId: element?.reminderId,
            patientName: element?.patient,
            // role: element?.,
            completedOn: element?.completedOn,
            dueDate: element?.dueDate,
            id: claimDetails?.id,
            completedBy: element?.completedBy,
            patient: element?.patient,
            reminderNotes: element?.reminderNotes,
            roleId: element?.roleId,
            roleName: roleName
          });
        });
      this.isLoading = false;
      this.dataSource = new MatTableDataSource(this.tblPatientVisitNotesData);
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
    });
    // })
  }


  //! Form Creation and Few Validations
  createReminderForm() {
    this.reminderForm = this.formBuilder.group({
      txtSubject: new FormControl("", [Validators.required]),
      txtDate: new FormControl("", [Validators.required]),
      //rbtnRemiderStatus:new FormControl(""),
      chkDontShowAgain: new FormControl(""),
      chkCompleted: new FormControl(""),
      chkAllUsers: new FormControl(""),
      drpAssignedTo: new FormControl("", [Validators.required]),
      txtPatient: new FormControl(""),
      txtCompletedBy: new FormControl(""),
      dtCompletedOn: new FormControl(""),
      dtEnteredDateTime: new FormControl("", [Validators.required]),
      txtRoleFilter: new FormControl("")
    })
    this.reminderForm.valueChanges.subscribe(() => {
      const txtSubject: AbstractControl = this.reminderForm?.get('txtSubject');
      const dtCompletedOn: AbstractControl = this.reminderForm?.get('dtCompletedOn');
      const txtCompletedBy: AbstractControl = this.reminderForm?.get('txtCompletedBy');
      if (txtSubject.dirty) {
        txtSubject?.value?.trim() === "" && txtSubject.setErrors({ required: true });
        txtSubject.markAsDirty();
        txtSubject.markAsTouched();
        //  txtSubject.markAsPristine();
        // txtSubject.markAsPristine();
      }
      if (txtCompletedBy.value === "" && dtCompletedOn.dirty && dtCompletedOn.valid && this.arrUsersList) {
        this.reminderForm.patchValue({
          txtCompletedBy: this.arrUsersList?.filter(v => v?.id === this.userId)[0]?.userName ?? "",
        })
      }
    })
  this.patientData();
    this.reminderForm?.get('txtPatient').disable({ onlySelf: true });
    this.reminderForm?.get('txtCompletedBy').disable({ onlySelf: true });
    this.reminderForm?.get('dtEnteredDateTime').disable({ onlySelf: true });
  }


  patientData(){
    if (this.data && this.data?.patientId) {
      let strPatientName: string = '';
      this.data?.patientId && this.store.dispatch(new getPatient(this.data?.patientId)).subscribe(response => {
        strPatientName = response?.patientState?.selectedPatient?.personals?.firstName ?? "" + response?.patientState?.selectedPatient?.personals?.lastName ?? "";
        this.reminderForm.patchValue({
          txtPatient: strPatientName ?? "",
          dtEnteredDateTime: new Date()
        })
      })

    }
    this.reminderForm?.get('txtPatient').disable({ onlySelf: true });
    this.reminderForm?.get('dtEnteredDateTime').disable({ onlySelf: true });
  }

  organizationUnitId = null;
  //// get by id
  getClaimInfo() {

    let claimDetails: ClaimDTO;
    if (this.data.claimId != null && this.data.claimId != "") {
      this.store.dispatch(new getBilling(this.data.claimId)).subscribe(response => {
        claimDetails = response.billingState.selectedBilling;

        this.saveReminders(claimDetails);
      }, err => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      }, () => {
      });
    }

  }
  //! Save Reminders
  saveReminders(response) {
    this.saveButtonHide = true;

    let reminderData: ClaimDTO;
    let currentRemainder: ReminderDTO[] = response?.lstReminders;
    if (this.reminderId !== null && this.reminderId !== undefined && this.reminderId !== "") {
      // if (this.postingChargesId != "00000000-0000-0000-0000-000000000000") {
      currentRemainder = currentRemainder.filter(lstReminders => {
        return lstReminders &&
          lstReminders.reminderId != this.reminderId
      })
      currentRemainder = [
        ...currentRemainder,
        {
          reminderId: this.reminderId,
          isAppointmentReminder: 0,
          isCall: 0,
          assignedTo: this.reminderForm.value.drpAssignedTo ?? "",
          isAllUsers: this.reminderForm.value.chkAllUsers ? 1 : 0,
          dueDate: this.reminderForm.value.txtDate ?? "",
          patient: this.reminderForm.getRawValue().txtPatient ?? "",
          reminderNotes: this.reminderForm.value.txtSubject,
          completedBy: this.reminderForm.getRawValue().txtCompletedBy,
          completedOn: this.reminderForm.value.dtCompletedOn ?? "",
          enteredDateTime: this.reminderForm.getRawValue().dtEnteredDateTime ?? "",
          roleId: this.reminderForm.value.drpAssignedTo ?? ""
        }]
    } else {
      currentRemainder = [
        ...currentRemainder,
        {
          reminderId: this.reminderId,
          isAppointmentReminder: 0,
          isCall: 0,
          assignedTo: this.reminderForm.value.drpAssignedTo ?? "",
          isAllUsers: this.reminderForm.value.chkAllUsers ? 1 : 0,
          dueDate: this.reminderForm.value.txtDate ?? "",
          patient: this.reminderForm.getRawValue().txtPatient ?? "",
          reminderNotes: this.reminderForm.value.txtSubject,
          completedBy: this.reminderForm.getRawValue().txtCompletedBy,
          completedOn: this.reminderForm.value.dtCompletedOn ?? "",
          enteredDateTime: this.reminderForm.getRawValue().dtEnteredDateTime ?? "",
          roleId: this.reminderForm.value.drpAssignedTo ?? ""
        }]
    }
    reminderData = {
      lstClaimInfo: response.lstClaimInfo,
      lstPostingCharges: response?.lstPostingCharges,
      //insurancePayments:null,
      insurancePayments: response?.insurancePayments,
      saleOrder: response.saleOrder,
      patientId: response.patientId,
      orderId: response.orderId,
      saleorderId: response.saleorderId,
      defaultPatientId: response.defaultPatientId,
      patientName: response.patientName,
      patientDob: response.patientDob,
      readyCount: response?.readyCount,
      processedCount: response?.processedCount,
      crossOverCount: response?.crossOverCount,
      holdCount: response?.holdCount,
      patientResponsibilityCount: response?.patientResponsibilityCount,
      patientResponsibilityAfterInsCount: response?.patientResponsibilityAfterInsCount,
      claimId: response.claimId,
      lstClearingHouseUpdates: response?.lstClearingHouseUpdates,
      totalPatientBal: response?.totalPatientBal,
      lstPatientVisitNotes: response?.lstPatientVisitNotes,
      totalBilled: response?.totalBilled,
      totalInsBal: response?.totalInsBal,
      lstReminders: currentRemainder,
      claimStatus: response?.claimStatus,
      claimProcessMethod: response?.claimProcessMethod,
      isVoided: response?.isVoided,

    }
    this.store.dispatch(new updateBilling(this.data.claimId, reminderData)).subscribe(value => {
      // Swal.fire({ title: 'Success', html: 'Saved Successfully', icon: 'success', timer: 3000, timerProgressBar: true });
      this.toastr.success('Saved Successfully','Success')
    //  this.reminderId = '00000000-0000-0000-0000-000000000000'
      this.getTableData();
      this.resetForm();
      this.saveButtonHide = false;
      // this.dialog.closeAll();
    }, err => {
      const data: HttpErrorResponse = err;
      this.saveButtonHide = false;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    })

  }

  //! Reminder Form Reset
  resetForm() {
    this.reminderForm.patchValue({
      txtSubject: "",
      txtDate: "",
      chkDontShowAgain: "",
      chkCompleted: "",
      chkAllUsers: "",
      drpAssignedTo: null,
      // txtPatient: "",
      txtCompletedBy: "",
      dtCompletedOn: "",
      // dtEnteredDateTime: ""
    })
    this.reminderId = '00000000-0000-0000-0000-000000000000'
    this.reminderForm.reset();
    this.reminderForm.updateValueAndValidity();
    this.reminderForm.markAsUntouched();
    this.patientData();
  }
  role: string = "";
  clearRole() {
    this.role = "";
    this.reminderForm.patchValue({ drpAssignedTo: "" });
  }


  ngOnDestroy(): void {
    this.resetForm();
  }


}
