<mat-dialog-content class="mat-typography">
  <div class="modal-header">
    <h1 class="modal-title customThemeClass">Move to Secondary - {{data.claimData.defaultClaimId}}
    </h1>
    <h2 class=" text-right"><b class="font-weight-bolder blinking">{{warningMsg}}</b></h2>
    <!-- <button class="text-right close" aria-label="Close" mat-dialog-close>
      <b> &times; </b>
    </button> -->

    <div class="page-title-box page-title-right">
      <form [formGroup]="FileForm">
        <span class="red-bold-text mr-2" >{{ selectedFileName }}</span>
        <!-- <span class="asterisk">*</span> -->
        <button matTooltip="Click To Browse EOB" mat-button class="buttonColor font-size-10 mr-2"
          (click)="uploadFile.click()" [disabled]="!isPdfLoad">Browse <span class="asterisk">*</span>
          <mat-icon>cloud_upload</mat-icon>
          <mat-icon *ngIf="!isPdfLoad">
            <mat-spinner class="spinner-border spinner-border-sm" diameter="20"></mat-spinner>
          </mat-icon>
        </button>

        <input autocomplete="new-documentFile" id="file-upload" type="file" class="hidden"
          (change)="onFileChange($event)" #uploadFile accept=".pdf" style="display: none;"
          formControlName="fileUpload" />

        <button mat-button [disabled]="isEmpty(strPdfString) || !isPdfLoad" class="buttonColor font-size-10 mr-2"
          (click)="viewDocument($event)"><mat-icon
            [ngStyle]="{'color':'white'}">remove_red_eye</mat-icon>Preview</button>
        <button mat-icon-button (click)="closeMdl()">
          <mat-icon>close</mat-icon>
        </button>
      </form>
    </div>


  </div>

  <!-- <form [formGroup]="claimUploadEOB"> -->
  <div class="modal-body p-0">


    <!-- *ngIf="!isRefresh" -->
    <div class="pt-2">
      <form [formGroup]="moveToSecondaryEOB">

        <mat-form-field class="col-4">
          <mat-label>Payor Level <span class="asterisk">*</span></mat-label>
          <input autocomplete="new-txtPayorLevel" matInput formControlName="txtPayorLevel" type="text"
            readonly="true" />
        </mat-form-field>


        <mat-form-field class="col-4">
          <mat-label>Payor Name <span class="asterisk">*</span></mat-label>
          <mat-select formControlName="drpPayorName" #singleSelect>
            <mat-option>
              <ngx-mat-select-search placeholderLabel="Search" noEntriesFoundLabel="No Matches found"
                formControlName="sltPayorNameFilter" hideClearSearchButton="true"
                (keyup)="getPrimaryPayerList($event.target.value)"></ngx-mat-select-search>
            </mat-option>
            <mat-option *ngFor="let Payor of filteredPayorName | async" [value]="Payor.payerId">
              {{ Payor.payerCode}} - {{Payor.payerName }}
            </mat-option>
          </mat-select>


        </mat-form-field>

        <mat-form-field class="col-4">
          <mat-label>Total Amount <span class="asterisk">*</span></mat-label>
          <input autocomplete="new-txtTotalAmount" matInput formControlName="txtTotalAmount" type="text"
            (input)="sanitizeInput($event, 'txtTotalAmount')" maxlength="12" />
          <mat-error
            *ngIf="moveToSecondaryEOB?.get('txtTotalAmount').invalid && (moveToSecondaryEOB?.get('txtTotalAmount').dirty || moveToSecondaryEOB?.get('txtTotalAmount').touched)">
            Enter 0-999999999.99/Decimal min 1/max 2 digits.
          </mat-error>
        </mat-form-field>

        <mat-form-field class="col-4">
          <mat-label>Paid Amount <span class="asterisk">*</span></mat-label>
          <input autocomplete="new-txtPaidAmount" matInput formControlName="txtPaidAmount" type="text"
            (input)="sanitizeInput($event, 'txtPaidAmount')" maxlength="12" />
          <mat-error
            *ngIf="moveToSecondaryEOB?.get('txtPaidAmount').invalid && (moveToSecondaryEOB?.get('txtPaidAmount').dirty || moveToSecondaryEOB?.get('txtPaidAmount').touched)">
            Enter 0-999999999.99/Decimal min 1/max 2 digits.
          </mat-error>
        </mat-form-field>

        <mat-form-field class="col-4">
          <mat-label>Pending Amount <span class="asterisk">*</span></mat-label>
          <input autocomplete="new-txtPendingAmount" matInput formControlName="txtPendingAmount" type="text"
            (input)="sanitizeInput($event, 'txtPendingAmount')" maxlength="12" />
          <mat-error
            *ngIf="moveToSecondaryEOB?.get('txtPendingAmount').invalid && (moveToSecondaryEOB?.get('txtPendingAmount').dirty || moveToSecondaryEOB?.get('txtPendingAmount').touched)">
            Enter 0-999999999.99/Decimal min 1/max 2 digits.
          </mat-error>
        </mat-form-field>

        <mat-form-field class="col-4">
          <!-- <span class="asterisk">*</span> -->
          <mat-label>Select File <span class="asterisk">*</span></mat-label>
          <mat-select formControlName="drpFile" (selectionChange)="setEnableView()" [(ngModel)]="selectFiledrp">

            <mat-option>
              <ngx-mat-select-search [formControl]="SelectFileControl" placeholderLabel="Search" noEntriesFoundLabel="No Matches found"
                formControlName="txtSelectFile"></ngx-mat-select-search>
            </mat-option>

            <mat-option *ngFor="let Blobs of ltBlobs" [value]="Blobs?.blobName || ''">
              {{ Blobs?.blobFileName || '-' }}
            </mat-option>
          </mat-select>
          <button mat-button (click)="clearSelectFile();$event.stopPropagation()" *ngIf="moveToSecondaryEOB?.get('drpFile')?.value" matSuffix mat-icon-button>
           <mat-icon>close</mat-icon>
         </button>

          <button [disabled]="IsSecSelectedPDF" mat-button matSuffix (click)="openSecSelectedPDF($event)"
            mat-icon-button>
            <mat-icon [ngStyle]="{'color':'lightseagreen'}">remove_red_eye</mat-icon>
          </button>
        </mat-form-field>



      </form>

    </div>
  </div>

  <div class="modal-footer">
    <div class="col-12 text-center">
      <!--  -->
      <button [disabled]="FileForm.invalid || moveToSecondaryEOB.invalid || isSaveUpdateSecondaryClaimDetailsData || isSelectBrowse || !isPdfLoad"
        mat-button class="buttonColor mb-2 mr-2" mat-raised-button (click)="savefuncation()">
        <span>Save</span>
        <mat-icon *ngIf="isSaveUpdateSecondaryClaimDetailsData">
          <mat-spinner class="spinner-border-sm" diameter="20"></mat-spinner>
        </mat-icon>
      </button>
      <!-- [disabled]="chkdisableButton" -->
      <button mat-button class="resetclr mb-2 mr-2" (click)="resetInboundDocumentForm()">Reset</button>
    </div>
  </div>
  <!-- </form> -->
</mat-dialog-content>
