import { PagedAndSortedResultRequestDto } from '@abp/ng.core';
import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatDateRangeInput, MatDatepicker } from '@angular/material/datepicker';
import { DataTableDirective } from 'angular-datatables';
import { UserByRoleDTO } from 'projects/admin/src/app/admin-proxy/platform-app-management/identity';
import { defaultGuid } from 'projects/shared/src/app/enums/allenums.enum';
import { Observable, Subscription, of } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { MmOrderService } from '../order-proxy/order-management/order-optimization/mm-order.service';
import { UserService } from 'projects/admin/src/app/admin-proxy/platform-app-management/rcm/platform-management/user';
import { MatDialog } from '@angular/material/dialog';
import { DatePipe } from '@angular/common';
import { Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { CommunicationService } from 'projects/shared/src/app/services/communication.service';
import { orderStatusService } from '../order-proxy/order-management/order/order-status.service';
import { CommonService } from 'projects/shared/src/app/services/common.service';
@Component({
  selector: 'app-my-orders',
  templateUrl: './my-orders.component.html',
  styleUrls: ['./my-orders.component.scss'],
  animations: [
    trigger('slideInRight', [
      state(
        'open',
        style({
          transform: 'translateX(0%)',
        })
      ),
      state(
        'closed',
        style({
          transform: 'translateX(100%)',
        })
      ),
      transition('closed => open', [animate('0.3s ease-out')]),
      transition('open => closed', [animate('0.3s ease-in')]),
    ]),
  ],
})
export class MyOrdersComponent implements OnInit {
  $subscription: Subscription[] = [];
  @ViewChild('txtDateValue') txtDateValue: MatDatepicker<Date>;
  @ViewChild('dateRangeInput') dateRangeInput: MatDateRangeInput<any>;
  showFilter: boolean = true;
  lstChartNos: any[] = [];
  lstPatientChartNos: any[] = [];
  dateValue: any;
  pageType: any = 1;
  ltOrders: any[] = [];
  lstUsers: any[] = [];
  filteredCSRList: Observable<UserByRoleDTO[]> = of([]);
  ltOrderStatus: any;
  filteredOrderStatusList: Observable<any[]> = of([]);
  maxDate: Date;
  istableView: boolean = false;
  groupingData: any;
  selectedDate: any;
  @ViewChild('picker') picker: MatDatepicker<Date>;
  fromDate: any;
  toDate: any;
  myTable: DataTables.Api;
  isViewQuarterlyBtn: boolean = false;
  @ViewChild(DataTableDirective, { static: false })
  datatableElement: DataTableDirective;
  isOrderTableLoaded: boolean = true;
  selectedMonth: number = 0;
  selectedYear: number = 0;
  orderForm: FormGroup;
  defauldGuid: string;
  subscription$: Subscription[] = [];
  filteredChartIds: any;
  filterValue: boolean = false;
  patientChartApiSubscription: Subscription;
  orderListSubscription: Subscription;
  
  //Pagination
  currentPage : any = 1;
  pageOffset: any = 50;
  totalPages : any = 1;
  visibleColumns : number[];

  constructor(
    private mmOrderService: MmOrderService,
    private userService: UserService,
    private commonService:CommonService,
    private dialog: MatDialog,
    private datepipe: DatePipe,
    private fb: FormBuilder,
    private router: Router,
    private title: Title,
    private orderStatusService: orderStatusService,
    private communicationService: CommunicationService
  ) {
    const comService =
      this.communicationService.functionLoadOrderList$.subscribe(() => {
        this.onDateRangeChange();
        this.filterValue === true &&
          this.loadOrderList(null, null, null, null, this.patientId);
        this.filterValue === false && this.onDateRangeChange();
      });
    this.subscription$.push(comService);

    const comService1 =
      this.communicationService.functionReloadOrderTable$.subscribe(() => {
        this.reloadDatatable();
      });
    this.subscription$.push(comService1);
  }
  ngOnInit(): void {
    this.title.setTitle('QSecure | My Orders');
    this.initializeForms();
    this.loadDropdowns();
    this.defauldGuid = defaultGuid;
    this.loadDefaultList();
    this.searchPatient('');
  }
  ngOnDestroy(): void {
    this.subscription$.forEach((sub) => {
      sub && sub?.unsubscribe();
    });
  }
  //To Load the Default Parameters and Load the Order List
  loadDefaultList() {
    let userId =
      localStorage.getItem('roleName') === 'admin'
        ? defaultGuid
        : localStorage.getItem('userId');

    const { firstDate, lastDate } = this.getFirstAndLastDatesOfCurrentMonth();
    this.orderForm.patchValue({
      txtDateValue: firstDate,
      txtDate: lastDate,
      drpCSR: defaultGuid,
      drpOrderStatus: defaultGuid,
    });
    this.onDateRangeChange(); //On Date Range Change Method
  }
  // Function to get the first and last dates of the current month
  getFirstAndLastDatesOfCurrentMonth(): { firstDate: Date; lastDate: Date } {
    const today = new Date();
    const firstDate = new Date(today.getFullYear(), today.getMonth(), 1);
    const lastDate = new Date(today.getFullYear(), today.getMonth() + 1, 0);
    return { firstDate, lastDate };
  }

  searchPatient(value: any) {
    this.loadPatientChartNos(value);
  }
  //To Load the Dropdowns
  loadDropdowns() {
    const csrUsersList = this.userService.getCSRUsersV1().subscribe(
      (response) => {
        if (response) {
          this.lstUsers = response;

          const allUserOption: UserByRoleDTO = {
            name: 'All',
            userId: defaultGuid,
            roleId: '',
            role: '',
            userDesignation: '',
          };

          this.lstUsers = [allUserOption, ...this.lstUsers];

          this.filteredCSRList = this.orderForm
            ?.get('txtCSRFilter')
            .valueChanges.pipe(
              startWith(''),
              map((value) =>
                this.lstUsers.filter((option) =>
                  option?.name
                    ?.toLowerCase()
                    ?.includes(value?.toLowerCase() ?? '')
                )
              )
            );
        }
      },
      (error) => {}
    );
    this.subscription$.push(csrUsersList);
    this.loadChartNos();
    this.loadOrderStatus();
  }
  //To Load the Chart No of the Patients
  loadPatientChartNos(searchParameter) {
    if (this.patientChartApiSubscription) {
      this.patientChartApiSubscription.unsubscribe();
    }
    this.patientChartApiSubscription = this.mmOrderService
      .getPatientChartNoV3BySSearchParamsAndPatient(searchParameter, null)
      .subscribe(
        (response) => {
          this.lstPatientChartNos = response;
          return this.lstPatientChartNos;
        },
        (err) => {}
      );
  }
  loadChartNos() {
    const chartNos = this.mmOrderService.getPatientChartNoV1().subscribe(
      (response) => {
        this.lstChartNos = response;
        this.lstChartNos = response.map((x) => {
          let y: { patientId: string; idAndNameAndDOB: string } = {
            patientId: x?.patientId,
            idAndNameAndDOB:
              x?.chartId +
              ' - ' +
              x?.patientName +
              ' - ' +
              this.datepipe.transform(new Date(x?.dob), 'MM/dd/yyyy'),
            ...x,
          };
          return y;
        });

        this.filteredChartIds = this.orderForm
          .get('txtPatientFilter')
          .valueChanges.pipe(
            startWith(''),
            map((value) =>
              this.lstChartNos?.filter((option) =>
                option?.idAndNameAndDOB
                  ?.toLowerCase()
                  ?.includes(value?.toLowerCase() ?? '')
              )
            )
          );
      },
      (err) => {}
    );
    this.subscription$.push(chartNos);
  }
  loadOrderStatus() {
    const statusList = this.orderStatusService
      .getActiveList(new PagedAndSortedResultRequestDto())
      .subscribe(
        (response) => {
          this.ltOrderStatus = response?.items ?? [];

          const allStatusOption: any = {
            description: 'All',
            id: defaultGuid,
          };

          this.ltOrderStatus = [allStatusOption, ...this.ltOrderStatus];

          this.filteredOrderStatusList = this.orderForm
            .get('txtStatusFilter')
            .valueChanges.pipe(
              startWith(''),
              map((value) =>
                this.ltOrderStatus?.filter((option) =>
                  option?.description
                    ?.toLowerCase()
                    ?.includes(value?.toLowerCase() ?? '')
                )
              )
            );
        },
        (err) => {}
      );
    this.subscription$.push(statusList);
  }
  //To initialize the Forms
  initializeForms() {
    this.orderForm = this.fb.group({
      chkAllOrders: new FormControl(''),
      drpPatient: new FormControl(''),
      txtPatientFilter: new FormControl(''),
      txtDateValue: new FormControl(''),
      txtDate: new FormControl(''),
      drpCSR: new FormControl(''),
      txtCSRFilter: new FormControl(''),
      drpOrderStatus: new FormControl(''),
      txtStatusFilter: new FormControl(''),
    });
  }
  //On Date Range Change Method
  onDateRangeChange() {
    const startDate = this.orderForm.get('txtDateValue').value;
    const endDate = this.orderForm.get('txtDate').value;
    this.fromDate = this.datepipe.transform(startDate, 'MM/dd/yyyy');
    this.toDate = this.datepipe.transform(endDate, 'MM/dd/yyyy');
    if (startDate != null && endDate != null) {
      this.loadOrderList(
        this.fromDate,
        this.toDate,
        this.orderForm.value.drpCSR,
        this.orderForm.value.drpOrderStatus,
        null,
      );
    }
  }

  //To Load the Order List
  loadOrderList(
    fromDate: any,
    toDate: any,
    csr: string,
    status: string,
    patientId: string
  ) {
    if (this.orderListSubscription) {
      this.orderListSubscription.unsubscribe();
    }
    this.isOrderTableLoaded = false;
    this.orderListSubscription = this.mmOrderService
      .getOrderListV3(fromDate, toDate, csr, patientId, status, false,null,this.currentPage,this.pageOffset)
      .subscribe(
        (response) => {
          let pagedResponse = response as any;
          if (pagedResponse == null)
          {
            this.isOrderTableLoaded = true;
            return null;
          }
          this.ltOrders = pagedResponse.items;
          //this.ltOrders = response ?? [];
          this.totalPages = Math.ceil(pagedResponse.totalCount / this.pageOffset);
          this.isOrderTableLoaded = true;
          this.ltOrders = this.ltOrders.map((order) => {
            return {
              ...order,
              suppliesDate: this.commonService.getFormattedDateZone(
                order.suppliesDate
              ),
              orderDate: this.commonService.getFormattedDateZone(
                order.orderDate
              ),
              lastMaskProcessDate: this.commonService.getFormattedDateZone(
                order.lastMaskProcessDate
              ),
              creationTime: this.commonService.getFormattedDateTimeZone(
                order.creationTime
              ),
              lastModificationTime: this.commonService.getFormattedDateTimeZone(
                order.lastModificationTime
              ),
            };
          });
        },
        (err) => {
          this.isOrderTableLoaded = true;
        }
      );
    this.subscription$.push(this.orderListSubscription);
  }
  navigateToOrderPage(patientId: string, orderId: string) {
    this.router.navigate([
      '/orders/saleOrder/' + patientId + '/' + orderId + '/' + this.pageType,
    ]);
  }
  patientId: string = '';
  onChartIdChange(e: any) {
    this.patientId = e.value;
    this.loadOrderList(null, null, null, null, e.value);
  }
  filterChange(event: boolean) {
    //Patient - True
    //Others - False
    this.filterValue = event;
    this.filterValue === true &&
      this.orderForm.patchValue({
        drpPatient: '',
      });
  }
  reloadDatatable() {
    this.isOrderTableLoaded = false;
    setTimeout(() => {
      this.isOrderTableLoaded = true;
    }, 100);
  }

  changePage(pageNo : number){
    const startDate = this.orderForm.get('txtDateValue').value;
    const endDate = this.orderForm.get('txtDate').value;
    this.fromDate = this.datepipe.transform(startDate, 'MM/dd/yyyy');
    this.toDate = this.datepipe.transform(endDate, 'MM/dd/yyyy');

    if( pageNo != this.currentPage && pageNo > 0 && pageNo <= this.totalPages)
    {
      this.currentPage = pageNo; 
      if (startDate != null && endDate != null) 
        {
          this.loadOrderList(
            this.fromDate,
            this.toDate,
            this.orderForm.value.drpCSR,
            this.orderForm.value.drpOrderStatus,
            null,
          );
        }
      //this.loadOrderList(null, null, null, null, null, null);
    }
  }

  fnGetVisibleColumns (columns:number[]){
    this.visibleColumns = columns;
  }
}
