import { RestService } from '@abp/ng.core';
import type {
  PagedAndSortedResultRequestDto,
  PagedResultDto,
} from '@abp/ng.core';
import { Injectable } from '@angular/core';
import type {
  ClaimFormDetailsDTO,
  CreateUpdateClaimFormDetailsDTO,
  ProviderFileCountDTO,
  claimRecoopAmountDTO,
  processPayorClaimDTO,
  processPayorClaimDetailsDTO,
} from '../dto/models';

@Injectable({
  providedIn: 'root',
})
export class ClaimFormDetailsService {
  apiName = 'billingManagement';

  create = (input: CreateUpdateClaimFormDetailsDTO) =>
    this.restService.request<any, ClaimFormDetailsDTO>(
      {
        method: 'POST',
        url: '/api/billingApp/claim-form-details',
        body: input,
      },
      { apiName: this.apiName }
    );

  delete = (id: string) =>
    this.restService.request<any, void>(
      {
        method: 'DELETE',
        url: `/api/billingApp/claim-form-details/${id}`,
      },
      { apiName: this.apiName }
    );

  get = (id: string) =>
    this.restService.request<any, ClaimFormDetailsDTO>(
      {
        method: 'GET',
        url: `/api/billingApp/claim-form-details/${id}`,
      },
      { apiName: this.apiName }
    );

  getList = (input: PagedAndSortedResultRequestDto) =>
    this.restService.request<any, PagedResultDto<ClaimFormDetailsDTO>>(
      {
        method: 'GET',
        url: '/api/billingApp/claim-form-details',
        params: {
          skipCount: input.skipCount,
          maxResultCount: input.maxResultCount,
          sorting: input.sorting,
        },
      },
      { apiName: this.apiName }
    );

  update = (id: string, input: CreateUpdateClaimFormDetailsDTO) =>
    this.restService.request<any, ClaimFormDetailsDTO>(
      {
        method: 'PUT',
        url: `/api/billingApp/claim-form-details/${id}`,
        body: input,
      },
      { apiName: this.apiName }
    );

  processZirmedClaimV1ByGClaim = (gClaim: string[]) =>
    this.restService.request<any, string>(
      {
        method: 'POST',
        responseType: 'text',
        url: '/api/billingApp/claim-form-details/process-zirmed-claim-v1',
        body: gClaim,
      },
      { apiName: this.apiName }
    );

  // manualClaimProcessingByGClaimAndGProvider = (
  //   gClaim: string[],
  //   gProvider: string
  // ) =>
  //   this.restService.request<any, ProviderFileCountDTO>(
  //     {
  //       method: 'POST',
  //       url: '/api/billingApp/claim-form-details/manual-claim-processing',
  //       params: { gProvider },
  //       body: gClaim,
  //     },
  //     { apiName: this.apiName }
  //   );

  manualClaimProcessingByGClaimAndGProviderAndPaymentProviderId = (gClaim: string[], gProvider: string, paymentProviderId: string) =>
    this.restService.request<any, ProviderFileCountDTO>({
      method: 'POST',
      url: `/api/billingApp/claim-form-details/manual-claim-processing/${paymentProviderId}`,
      params: { gProvider },
      body: gClaim,
    },
    { apiName: this.apiName });
  recoopAmountDetailsByGClaimId = (gClaimId: string) =>
    this.restService.request<any, claimRecoopAmountDTO>(
      {
        method: 'POST',
        url: `/api/billingApp/claim-form-details/recoop-amount-details/${gClaimId}`,
      },
      { apiName: this.apiName }
    );

  updateClaimRecoopAmountByGClaimIdAndDtRecoopAmountAndGOrderItemId = (
    gClaimId: string,
    dtRecoopAmount: number,
    gOrderItemId: string
  ) =>
    this.restService.request<any, string>(
      {
        method: 'PUT',
        url: '/api/billingApp/claim-form-details/update-claim-recoop-amount',
        params: { gClaimId, dtRecoopAmount, gOrderItemId },
      },
      { apiName: this.apiName }
    );

  updateClaimStatusByGClaimIdAndGStatusIdAndGNoteTypeIdAndSNoteTypeAndSComments =
    (
      gClaimId: string,
      gStatusId: string,
      gNoteTypeId: string,
      sNoteType: string,
      sComments: string
    ) =>
      this.restService.request<any, void>(
        {
          method: 'PUT',
          url: '/api/billingApp/claim-form-details/update-claim-status',
          params: { gClaimId, gStatusId, gNoteTypeId, sNoteType, sComments },
        },
        { apiName: this.apiName }
      );

  getSecondaryClaimDetailsByGClaimId = (gClaimId: string) =>
    this.restService.request<any, processPayorClaimDetailsDTO>(
      {
        method: 'GET',
        url: `/api/billingApp/claim-form-details/get-secondary-claim-details/${gClaimId}`,
      },
      { apiName: this.apiName }
    );

  // updateSecondaryClaimByInput = (input: processPayorClaimDTO) =>
  //   this.restService.request<any, string>({
  //     method: 'PUT',
  //     responseType: 'text',
  //     url: '/api/billingApp/claim-form-details/update-secondary-claim',
  //     body: input,
  //   },
  //   { apiName: this.apiName });

  // updateSecondaryClaimByInput = (input: processPayorClaimDTO) =>
  //   this.restService.request<any, string>({
  //     method: 'POST',
  //     responseType: 'text',
  //     url: '/api/billingApp/claim-form-details/update-secondary-claim',
  //   },
  //   { apiName: this.apiName });

  updateSecondaryClaimByInput = (input: processPayorClaimDTO) => {
    const formData = new FormData();
    // Append all fields to formData
    for (const key in input) {
      if (input.hasOwnProperty(key)) {
        formData.append(key, input[key]);
      }
    }
    return this.restService.request<any, string>(
      {
        method: 'POST',
        url: '/api/billingApp/claim-form-details/update-secondary-claim',
        body: formData,
        responseType: 'text',
      },
      { apiName: this.apiName }
    );
  };
  zermidClaimProcessingByGClaimAndGProvider = (gClaim: string[], gProvider: string) =>
    this.restService.request<any, ProviderFileCountDTO>({
      method: 'POST',
      url: '/api/billingApp/claim-form-details/zermid-claim-processing',
      params: { gProvider },
      body: gClaim,
    },
    { apiName: this.apiName });
  constructor(private restService: RestService) { }

  enableResubmission = (gClaimId: string) =>
    this.restService.request<any, any>(
      {
        method: 'POST',
        url: `/api/billingApp/claim-form-details/enable-resubmission-by-id/${gClaimId}`,
      },
      { apiName: this.apiName }
    );

}
