import { ShippingListService } from 'projects/order/src/app/order-proxy/order-management/order-optimization/shipping-list.service';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import {
  dtInboundOptions,
  inboundTableData,
} from '../admin-dashboard/dashboard-data';
import { DataTableDirective } from 'angular-datatables';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  ValidationErrors,
  Validators,
} from '@angular/forms';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogConfig,
} from '@angular/material/dialog';
import { ViewNotesComponent } from '../view-notes/view-notes.component';
import { PrimaryInsComponent } from '../primary-ins/primary-ins.component';
import { AddPatientNoteComponent } from '../add-patient-note/add-patient-note.component';
import { ViewDocumentComponent } from '../view-document/view-document.component';
import { InsuranceCheckListComponent } from '../insurance-check-list/insurance-check-list.component';
import { PatientManualVerificationTabComponent } from 'projects/patient/src/app/patient-manual-verification-tab/patient-manual-verification-tab.component';
import {
  PatientSaleOrderService,
  SaleOrderItemService,
} from 'projects/order/src/app/order-proxy/order-management/order-optimization';
import { InsuranceType } from 'projects/order/src/app/order-proxy/patient/enumeration-data';
import { PatientVerificationService } from 'projects/patient/src/app/patient-proxy/patient-optimization/patient-verification.service';
import { AdminDashboardPatientManualVerificationComponent } from 'projects/patient/src/app/admin-dashboard-patient-manual-verification/admin-dashboard-patient-manual-verification.component';
import { ToastrService } from 'ngx-toastr';
import { MmOrderService } from 'projects/order/src/app/order-proxy/order-management/order-optimization/mm-order.service';
import { ExchangeOrderComponent } from '../exchange-order/exchange-order.component';
import { element } from 'protractor';
import { Subscription } from 'rxjs';
import { PrintTicketDetails } from 'projects/order/src/app/order-proxy/order-management/order-optimization/dto';
import Swal from 'sweetalert2';
import { DatePipe } from '@angular/common';
import { AddNoteTabComponent } from 'projects/patient/src/app/add-note-tab/add-note-tab.component';
import { HttpErrorResponse } from '@angular/common/http';
import { PatientCommonNotesComponent } from 'projects/shared/src/app/components/patient-common-notes/patient-common-notes.component';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { CreateUpdatePatientNextCallDTO } from 'projects/patient/src/app/patient-proxy/patient-optimization/dto/models';
import { PatientPersonalService } from 'projects/patient/src/app/patient-proxy/patient-optimization/patient-personal.service';
import { CommonService } from 'projects/shared/src/app/services/common.service';
import moment from 'moment';
import { defaultGuid } from 'projects/shared/src/app/enums/allenums.enum';

@Component({
  selector: 'app-view-patient-ledger',
  templateUrl: './view-patient-ledger.component.html',
  styleUrls: ['./view-patient-ledger.component.scss'],
})
export class ViewPatientLedgerComponent implements OnInit {
  sInsuranceType: any;
  isDisabled: boolean = true;
  loadTable: boolean = true;
  isTableLoad: boolean = true;
  selectedData: any;
  subscription$: Subscription[] = [];
  patientLedgerForm: FormGroup;
  ledgerTableData: any = [];
  itemTableData: any = [];
  hcpcTableData: any = [];
  authTableData: any = [];
  patientEmail: string;
  patientNextCallDate: string;
  csrName: string;
  patientName: string;
  patientCharNo: string;
  tenantId: string;
  dtInboundOptions: any = {
    responsive: true,
    paging: false,
    scrollCollapse: true,
    order: [],
    scrollX: true,
    scrollY: '300px',
    columnDefs: [
      { targets: [0], width: '10%' },
      { targets: [1], width: '15%' },
      { targets: [2], width: '15%' },
      { targets: [3], width: '15%' },
      { targets: [4], width: '15%' },
      // { targets: [5], width: '15%' },
      // { targets: [6], width: '15%' },
    ],
    info: true,
    language: {
      emptyTable: 'No records',
      info: 'Total : _MAX_ records',
      infoEmpty: 'Total : _MAX_ records',
      infoFiltered: '(filtered : _TOTAL_ records)',
      loadingRecords: 'Loading...',
      zeroRecords: 'No matching records',
    },
  };
  defaultFaxId: string;
  documentId: string;
  tblSelectedOrderId: string;
  tblItemSelectedRowId: string;
  dtItemOptions: any = {
    responsive: true,
    paging: false,
    filter: false,
    scrollCollapse: true,
    scrollX: true,
    order: [],
    scrollY: '300px',
    info: true,
    language: {
      emptyTable: 'No records',
      info: 'Total : _MAX_ records',
      infoEmpty: 'Total : _MAX_ records',
      infoFiltered: '(filtered : _TOTAL_ records)',
      loadingRecords: 'Loading...',
      zeroRecords: 'No matching records',
    },
    columnDefs: [
      { targets: [0], width: '10%' },
      { targets: [1], width: '20%' },
      { targets: [2], width: '10%' },
      { targets: [3], width: '10%' },
      { targets: [4], width: '10%' },
      { targets: [5], width: '10%' },
      { targets: [6], width: '10%' },
      { targets: [7], width: '10%' },
      { targets: [8], width: '10%' },
    ],
  };
  dtHCPCOptions: any = {
    // responsive: true,
    // pageLength: 5,
    // lengthMenu: [5, 10, 25, 50, 100],
    // scrollY: '300px',
    // width: '100%'
  };
  dtApprovalOptions: any = {
    responsive: true,
    language: {
      emptyTable: 'No records',
    },
    paging: false,
    scrollCollapse: true,
    scrollY: '100px',
    scrollX: true,
    info: false,
    bFilter: false,
  };
  loadItmTbl = true;
  loadhcpcTbl = true;

  @ViewChild(DataTableDirective, { static: false })
  datatableElement: DataTableDirective;
  myTable: DataTables.Api;
  exchangeItemId: string;
  printTicketData: PrintTicketDetails;
  priVerificationId: string = defaultGuid;
  secVerificationId: string = defaultGuid;
  checkListId: string = defaultGuid;
  isCashOrder: boolean = false;
  defaultGuid = defaultGuid;
  customLabel: string = 'Next Call Date';
  yourDate: Date;
  minDate: Date;
  pageType: number = 0;
  constructor(
    private toaster: ToastrService,
    private formBuilder: FormBuilder,
    public dialog: MatDialog,
    public SaleOrderService: PatientSaleOrderService,
    public saleOrderItemService: SaleOrderItemService,
    private patientVerificationService: PatientVerificationService,
    private mmOrderService: MmOrderService,
    private datepipe: DatePipe,
    private shippingListService: ShippingListService,
    private patientPersonalService: PatientPersonalService,
    private commonService: CommonService,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      patientId: string;
      chartNo: string;
      mmOrderId: string;
      mmOrderItemId: string;
    } = {
      patientId: defaultGuid,
      chartNo: '',
      mmOrderId: defaultGuid,
      mmOrderItemId: defaultGuid,
    }
  ) {}

  ngOnInit(): void {
    this.yourDate = new Date('0001-01-01T00:00:00+05:53');

    this.initializeForms();
    this.getPatientOrdersList();
    this.tenantId = localStorage.getItem('tenantId') ?? '';

    this.minDate = new Date();
    // this.minDate=currentDate.getDate() + 1
    this.minDate.setDate(this.minDate.getDate() + 1);
  }
  ngAfterViewInit(): void {
    this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.columns().every(function () {
        const that = this;
        $('input', this.footer()).on('keyup change', function () {
          if (that.search() !== this['value']) {
            that.search(this['value']).draw();
          }
        });
      });
    });
  }
  initializeForms() {
    this.patientLedgerForm = this.formBuilder.group(
      {
        txtCSR: new FormControl(''),
        txtPhone: new FormControl(''),
        txtEmail: new FormControl(''),
        txtNextCall: new FormControl(''),
        txtBillingNotes: new FormControl(''),
        txtShippingNotes: new FormControl(''),
        txtNextCalldate: new FormControl(
          '',
          this.isValidDateValidator.bind(this)
        ),
      },
      (err) => {
        this.loadTable = true;
      }
    );
  }
  //To load the patient orders in the ledger
  getPatientOrdersList() {
    this.isTableLoad = true;
    this.loadTable = false;
    this.mmOrderService.getPatientOrderList(this.data.patientId).subscribe(
      (response) => {
        this.loadTable = true;
        this.ledgerTableData = response.items;
        this.ledgerTableData = this.ledgerTableData.map((shipping) => {
          return {
            ...shipping,
            suppliesDate: this.commonService.getFormattedDateZone(
              shipping.suppliesDate
            ),
            disapproveddate: this.commonService.getFormattedDateZone(
              shipping.disapproveddate
            ),
          };
        });
        // this.ledgerTableData.forEach(element => {

        // })

        this.fnSelectedRow(response?.items[0]?.id, response?.items[0]);
      },
      (err) => {
        this.loadTable = true;
      }
    );
  }
  tblItemSelectedRow(id: string, data) {
    this.isTableLoad = true;
    this.tblItemSelectedRowId =
      this.tblItemSelectedRowId === id ? defaultGuid : id;
    // this.tblItemSelectedRowId != '' && this.getHCPCCodes(id);
    this.getHCPCCodes(this.tblItemSelectedRowId, data);
  }

  fnSelectedRow(id: string, data) {
    this.selectedData = data;
    this.tblSelectedOrderId = id;
    this.priVerificationId = data.primaryVerificationId ?? defaultGuid;
    this.secVerificationId = data.secondaryVerificationId ?? defaultGuid;
    this.checkListId = data.checkListId ?? defaultGuid;
    this.isCashOrder = data.cashOrder ?? false;
    this.exchangeItemId = this.tblSelectedOrderId ?? defaultGuid;
    this.documentId = data.docId ?? defaultGuid;
    this.defaultFaxId = data.defaultFaxId;
    const nextCallDate = moment(data.nextCallDate).toDate();
    this.patientLedgerForm.patchValue({
      txtCSR: data.csrName,
      // txtPhone: data.phoneNumber,
      // txtEmail: data.patientEmail,
      // txtNextCall: data.nextCallDate,
      txtBillingNotes: data.commentsToBilling,
      txtShippingNotes: data.commentsToShipping,
      txtNextCalldate: nextCallDate,
    });
    this.patientEmail = data.patientEmail;
    this.patientNextCallDate = data.nextCallDate;
    this.csrName = data.csrName;
    this.patientName = data.patientName;
    this.patientCharNo = data.chartNo;
    this.getOrderItemsList(this.tblSelectedOrderId);
  }
  //To load the order items list
  getOrderItemsList(id) {
    this.isTableLoad = false;
    this.mmOrderService.getPatientOrderItemList(id).subscribe(
      (response) => {
        this.itemTableData = response.items;
        this.itemTableData = this.itemTableData.map((shipping) => {
          return {
            ...shipping,
            backOrderedSince: this.commonService.getFormattedDateZone(
              shipping.backOrderedSince
            ),
            // shippingDate: this.commonService.getFormattedDateZone(
            //   shipping.shippingDate
            // ),
            shippingDate: this.commonService.getFormattedDateTimeZone(
              shipping.shippingDate
            ),
          };
        });
        this.loadTable = true;
        this.isTableLoad = true;
        this.itemTableData.forEach((element) => {
          element.patientQtyOnHand = element.qty;
        });
        this.tblItemSelectedRow(
          response?.items[0]?.mmOrderItemId,
          response?.items?.[0]
        );
      },
      (err) => {
        this.loadTable = true;
        this.isTableLoad = true;
      }
    );
  }

  primaryIns() {
    const dialogRef = this.dialog.open(PrimaryInsComponent, {
      height: '65%',
      width: '70%',
      disableClose: true,
    });
    const closeModal = dialogRef.afterClosed().subscribe(() => {});
  }
  viewNotes() {
    const dialogRef = this.dialog.open(ViewNotesComponent, {
      height: '80%',
      width: '80%',
      // disableClose: true,
    });
    const closeModal = dialogRef.afterClosed().subscribe(() => {});
  }
  addNotes() {
    const dialogRef = this.dialog.open(AddPatientNoteComponent, {
      height: '70%',
      width: '70%',
      disableClose: true,
    });
    const closeModal = dialogRef.afterClosed().subscribe(() => {});
  }
  viewDocument() {
    this.dialog.open(ViewDocumentComponent, {
      height: '75%',
      width: '80%',

      disableClose: true,
    });
    this.dialog.afterAllClosed.subscribe((resposne) => {});
  }
  checkListModel() {
    //   const dialogRef = this.dialog.open(DocumentCheckListComponent, {
    //     height: "75%", width: "80%",
    //     disableClose: true,

    //   });
    //   const closeModal = dialogRef.afterClosed().subscribe(() => {

    //   });
    // }

    const dialogRef = this.dialog.open(InsuranceCheckListComponent, {
      height: '75%',
      width: '80%',
      disableClose: true,
    });
    const closeModal = dialogRef.afterClosed().subscribe(() => {});
  }

  openViewVerificationModal(element) {
    if (element == 'Primary') {
      const dialogRef = this.dialog.open(
        PatientManualVerificationTabComponent,
        {
          disableClose: true,
          data: {
            patientId: this.selectedData.patientId,
            verificationId: this.selectedData.primaryVerificationId,
            policyId: this.selectedData.policyId,
            savehidden: true,
            isViewVerification: true,
            documentId: this.documentId,
            defaultFaxId: this.defaultFaxId,
          },
        }
      );
      const closeDialog = dialogRef.afterClosed().subscribe((result) => {
        if (result == 'Saved') {
          this.dialog.closeAll();
        }

        result !== '' &&
          result !== null &&
          result !== undefined &&
          this.dialog.closeAll();
      });
      this.subscription$.push(closeDialog);
    } else if (element == 'secondary') {
      const dialogRef = this.dialog.open(
        PatientManualVerificationTabComponent,
        {
          disableClose: true,

          data: {
            patientId: this.selectedData.patientId,
            verificationId: this.selectedData.secondaryVerificationId,
            policyId: this.selectedData.secpolicyId,
            savehidden: true,
            isViewVerification: true,
            documentId: this.documentId,
            defaultFaxId: this.defaultFaxId,
          },
        }
      );
      const closeDialog = dialogRef.afterClosed().subscribe((result) => {
        if (result == 'Saved') {
          this.dialog.closeAll();
        }

        result !== '' &&
          result !== null &&
          result !== undefined &&
          this.dialog.closeAll();
      });
      this.subscription$.push(closeDialog);
    }
  }

  openVerificationModal(flag: number) {
    switch (flag) {
      case 1:
        this.sInsuranceType = InsuranceType.Primary;
        break;
      case 2:
        this.sInsuranceType = InsuranceType.Secondary;
        break;
      case 3:
        this.sInsuranceType = InsuranceType.Tertiary;
        break;
      default:
        this.sInsuranceType = '';
    }

    this.patientVerificationService
      .getLastVerificationDataByPatientIdByPatientIDAndType(
        this.data.patientId,
        this.sInsuranceType
      )
      .subscribe(
        (response) => {
          if (response.length != 0) {
            const dialogRef = this.dialog.open(
              AdminDashboardPatientManualVerificationComponent,
              {
                //
                disableClose: true,
                // data: {patientId:'7716cab4-407f-795a-5c25-3a0ec74e6765',policyId:'360b10a5-2187-cda9-5f97-3a0ec77e0df0'},
                data: {
                  patientId: this.data.patientId,
                  Instype: this.sInsuranceType,
                  savehidden: true,
                },
                // data: { patientId: this.patientId, policyId: this.policyId },
              }
            );
            const closeModal = dialogRef.afterClosed().subscribe(() => {});
          } else {
            this.toaster.warning(
              this.sInsuranceType + ' insurance details have not been found.',
              'warning'
            );
          }
        },
        (err) => {
          // Handle the error
        }
      );

    // const dialogRef = this.dialog.open(PatientManualVerificationTabComponent, {
    //   disableClose: true,
    //   data: {patientId:'7716cab4-407f-795a-5c25-3a0ec74e6765',policyId:'360b10a5-2187-cda9-5f97-3a0ec77e0df0'},
    //   // data: { patientId: this.patientId, policyId: this.policyId },
    // });
    // const closeModal = dialogRef.afterClosed().subscribe(() => {});
  }

  onKeyPress(event: KeyboardEvent) {
    const isNumber = /[0-9]/.test(event.key);
    if (!isNumber) {
      event.preventDefault(); // Prevent the input if the key is not a number
    }
  }

  selectExchange(event, data) {
    if (event.checked == true) {
      const dialogRef = this.dialog.open(ExchangeOrderComponent, {
        // height: '65%',
        // width: '45%',
        // data: { faxID: data },
        width: '60vw',
        height: '75vh',
        maxWidth: '60vw',
        maxHeight: '75vh',
        data: {
          exchangeItemId: this.exchangeItemId,
          mmOrderId: data.mmOrderId,
          mmOrderItemId: data.mmOrderItemId,
          mmProductId: data.mmProductId,
        },
        // panelClass: 'fullscreen-dialog-panel',

        disableClose: true,
      });
      // this.mmOrderService.exchangeItemByGOrderIdAndGOrderItemIdAndGExchangedOrderItemIdAndINoQtyExgeAndInput(this.data.mmOrderId,this.data.mmOrderItemId,data.mmOrderItemId,null,null).subscribe(response => {

      // })
      dialogRef.afterClosed().subscribe((a) => {});
    }
  }

  getHCPCCodes(productId: string, data) {
    this.hcpcTableData = [];
    this.loadhcpcTbl = true;
    this.hcpcTableData = data['hcpcList'];
  }
  isDefaultDate(data): boolean {
    const suppliesDate = this.datepipe.transform(data, 'MM/dd/yyyy');
    const defaultDate = this.datepipe.transform(this.yourDate, 'MM/dd/yyyy');
    return suppliesDate === defaultDate;
  }

  /* #region PRINT TICKET */

  //Method to call the Print Ticket Data Details
  getPrintTicketDetails() {
    const shippingTicketDetails = this.shippingListService
      .orderTicketDetailsV1ByOrderId(this.tblSelectedOrderId)
      .subscribe(
        (response) => {
          this.printTicketData = response ?? null;
          this.getPrintTicket(this.printTicketData);
        },
        (err) => {}
      );

    this.subscription$.push(shippingTicketDetails);
  }
  getPrintTicket(printTicketData: PrintTicketDetails) {
    const tbodyStyle = {
      fontSize: 10,
      margin: [0, 2],
    };

    this.printTicketData.productDetailsDTO =
      this.printTicketData?.productDetailsDTO?.map((a) => ({
        ...a,
        style: tbodyStyle,
      }));

    let docDefinition = {
      /* #region HEADER */
      header: function (currentPage, pageCount, pageSize) {
        return {
          columns: [
            // {
            //   image: localStorage.getItem('LogoDataUrl'),
            //   width: 100, // Adjust the width as needed
            //   alignment: 'left', // Adjust the alignment as needed
            // },
            // {
            //   width: '10%',
            //   text:{

            //       text: '844498797',
            //       fontSize: 18,
            //       bold: true,
            //       alignment: 'center',
            //       color: '#000000',

            //   }
            // },
            {
              width: '80%',
              text: [
                { text: '\n' },
                {
                  // margin:[15,0,0,0],
                  text: printTicketData?.mmRefOrderId ?? '',
                  fontSize: 16,
                  bold: true,
                  alignment: 'left',
                  color: '#000000',
                },
                {
                  text: '\t\t\t\t\t\t\t\t\t\tConfirm receipt of shipment online at www.monitormedical.com\n\t\t\t\t',
                  fontSize: 10,
                  bold: true,
                  alignment: 'center',
                  color: '#91ab5b',
                },
                {
                  text: '\t\t\t\t\t\tPATIENT CARE DELIVERY TICKET',
                  fontSize: 16,
                  bold: true,
                  alignment: 'center',
                  color: '#800000',
                },
              ],
              margin: [15, 0, 0, 0],
            },
            {
              image: localStorage.getItem('LogoDataUrl'),
              width: 100, // Adjust the width as needed
              // alignment: 'right', // Adjust the alignment as needed
              margin: [0, 10, 0, 0],
            },
          ],
        };
      },

      /* #endregion */

      /* #region FOOTER */
      footer: (currentPage, pageCount, pageSize) => {
        return {
          canvas: [
            {
              type: 'line',
              x1: 0,
              y1: 0,
              x2: 565,
              y2: 0,
              lineWidth: 3,
              lineColor: '#800000',
            },
          ],
          columns: [
            {
              width: '70%',
              text: [
                {
                  text: 'Patient Signature:',
                  fontSize: 10,
                  italics: true,
                },
                {
                  text: '_____________________________________________________________________\n',
                  fontSize: 10,
                  italics: true,
                },
                {
                  text: 'Monitor Medical, Inc. \t',
                  fontSize: 16,
                  italics: true,
                  bold: true,
                },
                {
                  text: 'The C-PAP Co. \t',
                  fontSize: 12,
                  italics: true,
                },
                {
                  text: 'Toll Free: 866-634-0177 \n',
                  fontSize: 10,
                  italics: true,
                  bold: true,
                },
                {
                  text: printTicketData?.mmRefOrderId ?? '',
                  fontSize: 14,
                  italics: true,
                  alignment: 'right',
                  bold: true,
                  color: '#000000',
                },
              ],
              // ,
              bold: true,
              color: '#800000',
            },
            {
              width: '30%',
              text: [
                // {
                //   text: '_ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ \n\n',
                //   fontSize: 15,
                //   bold: true,
                //   italics: true,
                //   alignment: 'left',
                // },
                { text: 'Date:', fontSize: 10, italics: true },
                { text: '___________________________\n' },
                {
                  text:
                    this.datepipe.transform(new Date(), 'fullDate') + '\n\n',
                  alignment: 'center',
                  fontSize: 8,
                  italics: true,
                  bold: false,
                },
                {
                  text:
                    printTicketData.shippingMethodName +
                    '\t' +
                    printTicketData.location,
                  fontSize: 8,
                  italics: true,
                  alignment: 'center',
                  color: '#000000',
                },
              ],
              fontSize: 12,
              bold: true,
              alignment: 'left',
              color: '#800000',
            },

            // {
            //   text:
            //     printTicketData.shippingMethodName +
            //     ' ' +
            //     printTicketData.location,
            //   fontSize: 8,
            //   italics: true,
            // },
          ],
          margin: [15, 0, 15, 0],
          alignment: 'left',
          // fontSize: 10,
        };
      },
      /*#endregion*/

      /* #region CONTENT */

      content: [
        /* #region Section 1 */
        {
          canvas: [
            {
              type: 'line',
              x1: 0,
              y1: 0,
              x2: 565,
              y2: 0,
              lineWidth: 2,
              lineColor: '#800000',
            },
          ],
        },
        //Row 1
        {
          columns: [
            {
              width: '33%',
              text: 'Order No:\t' + printTicketData.mmRefOrderId ?? '',
              bold: true,
              fontSize: 10,
            },
            {
              width: '33%',
              text: 'INS:\t' + printTicketData.insuranceName ?? '',
              fontSize: 10,
            },
            {
              width: '33%',
              text:
                'H:\t' + this.formatPhoneNumber(printTicketData?.homePhone) ??
                '',
              fontSize: 10,
            },
          ],
          margin: [0, 10, 0, 3], // [left, top, right, bottom]
        },
        //Row 2
        {
          columns: [
            {
              width: '33%',
              text: 'Account No:\t' + printTicketData.chartNo ?? '',
              bold: true,
              fontSize: 10,
            },
            {
              width: '33%',
              text:
                'DOB:\t' +
                (printTicketData?.dateOfBirth != null
                  ? this.datepipe.transform(
                      printTicketData?.dateOfBirth,
                      'MM/dd/yyyy'
                    )
                  : ''),
              fontSize: 10,
              style: 'columnHeader',
            },
            {
              width: '33%',
              text:
                'W:\t' + this.formatPhoneNumber(printTicketData?.workPhone) ??
                '',
              fontSize: 10,
              style: 'columnHeader',
            },
          ],
          margin: [0, 0, 0, 3],
        },
        //Row 3
        {
          columns: [
            {
              width: '33%',
              text: 'Patient Name:\t' + printTicketData.patientName ?? '',
              fontSize: 10,
            },
            {
              width: '33%',
              text: 'e-mail:\t' + (printTicketData?.emailId ?? ''),
              fontSize: 10,
            },
            {
              width: '33%',
              text:
                'M:\t' + this.formatPhoneNumber(printTicketData?.mobilePhone) ??
                '',
              fontSize: 10,
            },
          ],
          margin: [0, 0, 0, 3],
        },
        //Row 4
        {
          columns: [
            {
              width: '50%',
              text: 'Address:\t' + printTicketData?.fullAddress ?? '',
              fontSize: 10,
            },
          ],
          margin: [0, 0, 0, 10],
        },

        /* #endregion*/
        /* #region Section 2 */
        {
          canvas: [
            {
              type: 'line',
              x1: 0,
              y1: 0,
              x2: 565,
              y2: 0,
              lineWidth: 2,
              lineColor: '#800000',
            },
          ],
        },
        //Row 1
        {
          text:
            'Contact ' + printTicketData?.technicianName + ' at 281-637-2286',
          fontSize: 16,
          bold: true,
          alignment: 'center',
          color: '#800000',
          margin: [0, 5],
        },
        //Row 2
        {
          canvas: [
            {
              type: 'line',
              x1: 0,
              y1: 0,
              x2: 565,
              y2: 0,
              lineWidth: 2,
              lineColor: '#800000',
            },
          ],
        },
        /* #endregion*/
        /* #region Section 3 */
        //Row 1
        {
          width: '33%',
          text: 'Pressure Settings',
          italics: true,
          fontSize: 8,
          margin: [0, 5, 0, 5], // [left, top, right, bottom]
        },
        //Row 2
        {
          table: {
            headerRows: 1,
            widths: [
              '*',
              '*',
              '*',
              '*',
              '*',
              '*',
              '*',
              '*',
              '*',
              '*',
              '*',
              '*',
              '*',
              '*',
              '*',
            ],
            body: [
              [
                {
                  text: 'Min Press',
                  fontSize: 6,
                  alignment: 'center',
                  bold: true,
                },
                {
                  text: 'Max Press',
                  fontSize: 6,
                  alignment: 'center',
                  bold: true,
                },
                {
                  text: 'Setting',
                  fontSize: 6,
                  alignment: 'center',
                  bold: true,
                },
                { text: 'IPAP', fontSize: 6, alignment: 'center', bold: true },
                { text: 'EPAP', fontSize: 6, alignment: 'center', bold: true },
                {
                  text: 'Ramp Press',
                  fontSize: 6,
                  alignment: 'center',
                  bold: true,
                },
                {
                  text: 'Ramp Time',
                  fontSize: 6,
                  alignment: 'center',
                  bold: true,
                },
                {
                  text: 'BiFlex',
                  fontSize: 6,
                  alignment: 'center',
                  bold: true,
                },
                {
                  text: 'Set Press',
                  fontSize: 6,
                  alignment: 'center',
                  bold: true,
                },
                { text: 'CFlex', fontSize: 6, alignment: 'center', bold: true },
                {
                  text: 'RR/BPM',
                  fontSize: 6,
                  alignment: 'center',
                  bold: true,
                },
                {
                  text: 'IPAP Min',
                  fontSize: 6,
                  alignment: 'center',
                  bold: true,
                },
                {
                  text: 'IPAP Max',
                  fontSize: 6,
                  alignment: 'center',
                  bold: true,
                },
                { text: 'SetVt', fontSize: 6, alignment: 'center', bold: true },
                { text: 'Rate', fontSize: 6, alignment: 'center', bold: true },
              ],
              [
                {
                  text:
                    this.printTicketData?.settingsDetails?.minPressure ?? '',
                  fontSize: 6,
                  margin: [0, 5],
                  alignment: 'center',
                },
                {
                  text:
                    this.printTicketData?.settingsDetails?.maxPressure ?? '',
                  fontSize: 6,
                  margin: [0, 5],
                  alignment: 'center',
                },
                {
                  text:
                    this.printTicketData?.settingsDetails?.settlingTime ?? '',
                  fontSize: 6,
                  margin: [0, 5],
                  alignment: 'center',
                },
                {
                  text: this.printTicketData?.settingsDetails?.ipap ?? '',
                  fontSize: 6,
                  margin: [0, 5],
                  alignment: 'center',
                },
                {
                  text: this.printTicketData?.settingsDetails?.epap ?? '',
                  fontSize: 6,
                  margin: [0, 5],
                  alignment: 'center',
                },
                {
                  text:
                    this.printTicketData?.settingsDetails?.rampPressure ?? '',
                  fontSize: 6,
                  alignment: 'center',
                  margin: [0, 5],
                },
                {
                  text: this.printTicketData?.settingsDetails?.rampTime ?? '',
                  fontSize: 6,
                  alignment: 'center',
                  margin: [0, 5],
                },
                {
                  text: this.printTicketData?.settingsDetails?.biFlex ?? '',
                  fontSize: 6,
                  margin: [0, 5],
                  alignment: 'center',
                },
                {
                  text:
                    this.printTicketData?.settingsDetails?.setPressure ?? '',
                  fontSize: 6,
                  margin: [0, 5],
                  alignment: 'center',
                },
                {
                  text: this.printTicketData?.settingsDetails?.cFlex ?? '',
                  fontSize: 6,
                  margin: [0, 5],
                  alignment: 'center',
                },
                {
                  text: this.printTicketData?.settingsDetails?.rrbpm ?? '',
                  fontSize: 6,
                  margin: [0, 5],
                  alignment: 'center',
                },
                {
                  text: this.printTicketData?.settingsDetails?.ipaPmin ?? '',
                  fontSize: 6,
                  margin: [0, 5],
                  alignment: 'center',
                },
                {
                  text: this.printTicketData?.settingsDetails?.ipaPmax ?? '',
                  fontSize: 6,
                  margin: [0, 5],
                  alignment: 'center',
                },
                {
                  text: this.printTicketData?.settingsDetails?.setVT ?? '',
                  fontSize: 6,
                  margin: [0, 5],
                  alignment: 'center',
                },
                {
                  text: this.printTicketData?.settingsDetails?.rate ?? '',
                  fontSize: 6,
                  margin: [0, 5],
                  alignment: 'center',
                },
              ],
            ],
          },
        },
        //Row 3
        {
          columns: [
            {
              width: '33%',
              text: 'Technician: ' + printTicketData?.technicianName ?? '',
              fontSize: 8,
              alignment: 'center',
            },
            {
              width: '33%',
              text: 'Physician: ' + printTicketData?.physicianName ?? '',
              fontSize: 8,
              alignment: 'center',
            },
            {
              width: '33%',
              text:
                'Referral Practice: ' + printTicketData?.referralPractice ?? '',
              fontSize: 8,
              alignment: 'center',
            },
          ],
          margin: [0, 5, 0, 5], // [left, top, right, bottom]
        },
        /* #endregion */

        /*#region Section 4 */
        {
          table: {
            headerRows: 1,
            // widths: ['15%', '5%', '10%', '45%', '10%', '15%'],
            widths: ['10%', '12%', '44%', '10%', '9%', '15%'],
            body: [
              [
                // {
                //   text: 'Location',
                //   bold: true,
                //   fontSize: 10,
                //   color: '#800000',
                // },
                { text: 'Qty', bold: true, fontSize: 10, color: '#800000' },
                {
                  text: 'Product Code',
                  bold: true,
                  fontSize: 10,
                  color: '#800000',
                },
                {
                  text: 'Product Description',
                  bold: true,
                  fontSize: 10,
                  color: '#800000',
                },
                {
                  text: 'HCPC',
                  bold: true,
                  fontSize: 10,
                  color: '#800000',
                },
                // { text: 'Size', bold: true, fontSize: 10, color: '#800000' },
                // { text: 'Exch', bold: true, fontSize: 10, color: '#800000' },
                {
                  text: printTicketData?.isOrderComplete ? 'Allowable' : 'Exch',
                  bold: true,
                  fontSize: 10,
                  color: '#800000',
                },
                {
                  text: 'Serial #/Lot #',
                  bold: true,
                  fontSize: 10,
                  color: '#800000',
                },
              ],
              ...this.printTicketData?.productDetailsDTO?.map((item) => [
                // item.location,
                item.quantity,
                item.productCode,
                item.productName,
                // item.sizeName,
                item.hcpc,
                printTicketData?.isOrderComplete
                  ? item.allowables
                  : {
                      image:
                        'data:image/png;base64,' +
                        this.getSquareImageBase64(item.exchange),
                      width: 10,
                      height: 10,
                      alignment: 'center',
                    },
                item.serialLotNo,
              ]),
            ],
          },
          style: {
            fontSize: 10,
            margin: [0, 2],
          },
          margin: [0, 5, 0, 0],
        },
        /* #endregion */

        /* #region Section 5 */
        {
          columns: [
            {
              width: '60%',
              text: [
                {
                  text: '\nComments to Patients:\n',
                  color: '#800000',
                  fontSize: 12,
                },
                {
                  text: printTicketData?.commentsToPatient ?? '',
                  fontSize: 8,
                },
              ],
              fontSize: 14,
              bold: true,
              alignment: 'left',
            },

            {
              width: '40%',
              table: {
                headerRows: 1,
                // widths: ['80%', '20%'],
                widths: ['72%', '28%'],
                body: [
                  [
                    {
                      text: 'Est Allowable:',
                      fontSize: 10,
                      margin: [0, 0],
                      alignment: 'right',
                      border: [false, false, false, false],
                    },
                    {
                      text: '$' + printTicketData.estAllowable ?? '0',
                      fontSize: 10,
                      margin: [0, 0],
                      border: [false, false, false, false],
                      alignment: 'left',
                    },
                  ],
                  [
                    {
                      text: 'Est Copay:',
                      fontSize: 10,
                      margin: [0, 0],
                      alignment: 'right',
                      border: [false, false, false, false],
                    },
                    {
                      text: '$' + printTicketData.estCopay ?? '0',
                      fontSize: 10,
                      margin: [0, 0],
                      alignment: 'left',
                      border: [false, false, false, false],
                    },
                  ],
                  [
                    {
                      text: 'Total Accessory:',
                      fontSize: 10,
                      margin: [0, 0],
                      alignment: 'right',
                      border: [false, false, false, false],
                    },
                    {
                      text: '$' + printTicketData.totalAccessory ?? '0',
                      fontSize: 10,
                      margin: [0, 0],
                      alignment: 'left',
                      border: [false, false, false, false],
                    },
                  ],
                  [
                    {
                      text: 'Shipping Fee:',
                      fontSize: 10,
                      margin: [0, 0],
                      alignment: 'right',
                      border: [false, false, false, true],
                    },
                    {
                      text: '$' + printTicketData.shippingFee ?? '0',
                      fontSize: 10,
                      margin: [0, 0],
                      alignment: 'left',
                      border: [false, false, false, true],
                    },
                  ],

                  [
                    {
                      text: 'Est Amount Due:',
                      fontSize: 10,
                      margin: [0, 0],
                      alignment: 'right',
                      border: [false, false, false, false],
                    },
                    {
                      text: '$' + printTicketData.estAmountDue ?? '0',
                      fontSize: 10,
                      margin: [0, 0],
                      alignment: 'left',
                      border: [false, false, false, false],
                    },
                  ],
                  [
                    {
                      text: 'Amount Paid:',
                      fontSize: 10,
                      margin: [0, 0],
                      alignment: 'right',
                      border: [false, false, false, true],
                    },
                    {
                      text: '$' + printTicketData.amountPaid ?? '0',
                      fontSize: 10,
                      margin: [0, 0],
                      alignment: 'left',
                      border: [false, false, false, true],
                    },
                  ],
                  [
                    {
                      text: 'Balance:',
                      fontSize: 10,
                      margin: [0, 0],
                      alignment: 'right',
                      border: [false, false, false, false],
                    },
                    {
                      text: '$' + printTicketData.patientBalance ?? '0',
                      fontSize: 10,
                      margin: [0, 0],
                      alignment: 'left',
                      border: [false, false, false, false],
                    },
                  ],
                ],
              },
              layout: {
                hLineWidth: function (i, node) {
                  return i === 4 || i === 6 ? 1 : 0; // Thickness of the dotted line for the second row bottom border
                },
                hLineColor: function (i, node) {
                  return '#800000';
                },
                hLineStyle: function (i, node) {
                  if (i === 4 || i === 6) {
                    return { dash: { length: 5, space: 5 } }; // Dotted style for the second row bottom border
                  }
                  return null;
                },
              },
            },
          ],
        },
        /* #endregion*/
      ],
      /* #endregion */

      // pageMargins: [15, 50, 15, 40],
      pageMargins: [15, 60, 15, 60],
      defaultStyle: {
        font: 'Roboto',
      },
    };
    this.displayPdf(docDefinition, 'Ticket');
  }

  private getSquareImageBase64(excahngeItem): string {
    if (excahngeItem === true) {
      // Convert the image to Base64 format (using a base64 string or FileReader)
      const canvas = document.createElement('canvas');
      canvas.width = 10;
      canvas.height = 10;
      const ctx = canvas.getContext('2d');

      // Draw outer border with light sea green color
      ctx.fillStyle = '#000000';
      ctx.fillRect(0, 0, 12, 12); // Fill entire canvas with light sea green

      // Draw inner white square (to create border effect)
      ctx.fillStyle = '#FFFFFF'; // White color
      ctx.fillRect(1, 1, 8, 8); // Leave 1 pixel border around the canvas

      // Draw check mark (using a simple path)
      ctx.strokeStyle = '#20B2AA';
      ctx.beginPath();
      ctx.moveTo(2, 4);
      ctx.lineTo(4, 6);
      ctx.lineTo(8, 2);
      ctx.stroke();

      return canvas.toDataURL('image/png').split(',')[1];
    } else {
      const canvas = document.createElement('canvas');
      canvas.width = 10;
      canvas.height = 10;
      const ctx = canvas.getContext('2d');
      ctx.fillStyle = '#000000'; // Border color
      ctx.fillRect(0, 0, 12, 12); // Fill entire canvas with black

      // Draw inner white square (to create border effect)
      ctx.fillStyle = '#FFFFFF'; // Square color
      ctx.fillRect(1, 1, 8, 8); // Leave 1 pixel border around the canvas
      return canvas.toDataURL('image/png').split(',')[1];
    }
  }

  // private getSquareImageBase64(): string {
  //   // Convert the image to Base64 format (using a base64 string or FileReader)
  //   const canvas = document.createElement('canvas');
  //   canvas.width = 10;
  //   canvas.height = 10;
  //   const ctx = canvas.getContext('2d');
  //   ctx.fillStyle = '#000000'; // Border color
  //   ctx.fillRect(0, 0, 12, 12); // Fill entire canvas with black

  //   // Draw inner white square (to create border effect)
  //   ctx.fillStyle = '#FFFFFF'; // Square color
  //   ctx.fillRect(1, 1, 8, 8); // Leave 1 pixel border around the canvas
  //   return canvas.toDataURL('image/png').split(',')[1];
  // }
  //Method to display the PDF Content
  displayPdf(docDefinition: any, filename: string): void {
    try {
      const pdfMake = window['pdfMake'];
      const pdfDocGenerator = pdfMake.createPdf(docDefinition); //.download(filename);

      // Open PDF in a new tab
      pdfDocGenerator.getBlob((blob: Blob) => {
        const dataUri = URL.createObjectURL(blob);
        window.open(dataUri, '_blank');
        URL.revokeObjectURL(dataUri);
      });

      // Optionally, you can add print and download buttons in your HTML
      // and trigger these actions using pdfMake functions:
      // pdfMake.createPdf(docDefinition).print();
      // pdfMake.createPdf(docDefinition).open();
    } catch (error) {
      Swal.fire({
        icon: 'error',
        text: 'Error creating PDF',
      });
    }
  }
  private formatPhoneNumber(value: string): string {
    if (value === null) return '';
    const phoneNumberRegex = /^(\d{3})(\d{3})(\d{4})$/;
    const matches = phoneNumberRegex.exec(value);

    if (!matches) return value;

    // Format the phone number with hyphens
    const formattedNumber = `${matches[1]}-${matches[2]}-${matches[3]}`;
    return formattedNumber;
  }
  /* #endregion */

  //Approval No and Amount
  getApprovalNoandAmnt(orderId: string) {
    this.shippingListService.getApprovalNoByOrderId(orderId).subscribe(
      (response) => {
        this.authTableData = response;
      },
      (err) => {
        console.log(err);
      }
    );
  }

  updateTextColor(colorCode: string) {
    // if (this.isValidColorCode(colorCode)) {
    //   return this.getContrastingTextColor(colorCode);
    // } else {
    //   return '#000000'; // default to black
    // }
    switch (colorCode) {
      case 'Order Completed':
        return '#ff96ff';
      // case "Billing Process":
      //   return '#a16463';
      // case "Shipping Process":
      //   return '#a16463';
      // case "Order Confirmed":
      //   return '#a16463';
      // case "Patient Paid Invoice (PAID)":
      //   return '#ffff';
      case 'Shipping Approval Process':
        return '#ffff';
      // case "Pending for Billing":
      //     return '#ffff';
      default:
        return '#000000';
    }
  }

  isValidColorCode(colorCode: string): boolean {
    // Check if colorCode is a valid hex color code
    const regex = /^#([0-9A-F]{3}){1,2}$/i;
    return regex.test(colorCode);
  }

  getContrastingTextColor(hexColor: string): string {
    if (!hexColor) return '#000000'; // default to black if no color provided

    // Remove the hash if present
    hexColor = hexColor.replace('#', '');

    // Convert to RGB
    const r = parseInt(hexColor.substring(0, 2), 16);
    const g = parseInt(hexColor.substring(2, 4), 16);
    const b = parseInt(hexColor.substring(4, 6), 16);

    // Calculate luminance
    const luminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255;

    // If luminance is high, return dark color (black), otherwise return light color (white)
    return luminance > 0.5 ? '#000000' : '#FFFFFF';
  }

  //Add Notes Modal
  openAddNotesPopUp(data: any) {
    const config: MatDialogConfig = {
      disableClose: true,
      minHeight: '30vh',
      minWidth: '50vw',
      data: {
        noteType: 'patient',
        notesId: '00000000-0000-0000-0000-000000000000',
        patientId: data.patientId,
        defaultFaxId: data.defaultFaxId,
        isCallNote: false,
        isPending: false,
        // docId: this.documentId,
        inboundStatusShortCodeId: defaultGuid,
        selectedNode: defaultGuid,
        addnotes: true,
      },
    };
    const dialogRef = this.dialog.open(AddNoteTabComponent, config);

    const closeDialog = dialogRef.afterClosed().subscribe(
      () => {
        // this.getReminderTableData();
      },
      (err) => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      }
    );
    this.subscription$.push(closeDialog);
  }

  //View Notes Open Modal
  openViewNotesPopup(patientId: string): void {
    const config: MatDialogConfig = {
      disableClose: true,
      width: '70vw',
      maxWidth: '70vw',
      // height: '40vw',
      // maxHeight: '40vw',
      data: {
        patientId: patientId,
        pageType: 'Patient Note', // this.pageType, //Notes Table Popup Page
        isAddVisible: false,
      },
    };
    const dialogRef = this.dialog.open(PatientCommonNotesComponent, config);
  }

  isEmpty(value: any): boolean {
    return (
      value === null ||
      value === undefined ||
      value === '' ||
      value === defaultGuid
    );
  }

  getStatusColor(status: string): string {
    switch (status) {
      case 'Billing Process':
        return '#ceffce';
      case 'Order Confirmed':
        return '#ceffce';
      case 'Shipping Approval Process':
        return '#963a46';
      case 'Shipping Process':
        return '#ceffce';
      case 'Submit to Billing Override':
        return '#c8bfe7';
      case 'Submit to Shipping Override':
        return '#c8bfe7';
      case 'Patient Paid Invoice (PAID)':
        return '#00c957';
      case 'Return Item/RMA':
        return '#b4e4b4';
      case 'Pending for Billing':
        return '#fd0200';
      case 'Start order Override':
        return '#c8bfe7';
      case 'Pending for Billing with Response':
        return '#ffa404';
      default:
        return '#ffff';
    }
  }

  //Next Call Date Update
  onDateChange() {
    if (this.patientLedgerForm.valid) {
      const selectedDate = this.patientLedgerForm.value.txtNextCalldate;
      const selectedDateV1 = this.datepipe.transform(
        selectedDate,
        'yyyy-MM-dd'
      );
      const patientId = this.data.patientId;
      let nextCall: CreateUpdatePatientNextCallDTO = {
        patientId: patientId,
        nextCallDate: selectedDateV1,
        isNextCall: true,
        tenantId: this.tenantId,
      };

      const netCallDate = this.patientPersonalService
        .patientNextCall(nextCall)
        .subscribe((response) => {
          this.toaster.success(
            'Next Call date updated successfully',
            'Success'
          );
          this.getPatientOrdersList();
        });
    }
  }

  isValidDateValidator(control: FormControl) {
    const selectedDate = new Date(control.value);

    const year = selectedDate.getFullYear();
    const month = selectedDate.getMonth() + 1; // Month is zero-based, so add 1
    const day = selectedDate.getDate();

    const isValidDate = this.isValidDate(year, month, day);
    return isValidDate ? null : { invalidDate: true };
  }

  isValidDate(year: number, month: number, day: number): boolean {
    if (
      year >= 1000 &&
      year <= 9999 &&
      month >= 1 &&
      month <= 12 &&
      day >= 1 &&
      day <= 31
    ) {
      return true;
    } else {
      return false;
    }
  }

  addSlashToDate(event: Event): void {
    const input = event.target as HTMLInputElement;
    const inputValue = input.value;
    input.value = inputValue
      .replace(/^(\d{2})(\d{1,2})/, '$1/$2')
      .replace(/^(\d{2}\/\d{2})(\d+)/, '$1/$2')
      .replace(/[^\d\/]/g, '');
  }
  //Copy Order - To Redirect to the Sale Order Page with Copy Order Params
  copyOrder(patientId: string, orderId: string) {
    const url =
      '/#orders/saleOrder/' +
      patientId +
      '/' +
      orderId +
      '/' +
      true +
      '/' +
      this.pageType;
    window.open(url, '_blank');
  }
}
