import { DatePipe } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DataTableDirective } from 'angular-datatables';
import { ToastrService } from 'ngx-toastr';
import { ResupplyRemainderSettingService } from 'projects/order/src/app/order-proxy/order-management/order-optimization/resupply-remainder-setting.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-master-resupply-table',
  templateUrl: './master-resupply-table.component.html',
  styleUrls: ['./master-resupply-table.component.scss']
})
export class MasterResupplyTableComponent implements OnInit {

  @Input() resupplyTableData: any[];
  @Output() edit = new EventEmitter<string>();
  @Output() isLoadTbl = new EventEmitter<boolean>();
  @ViewChild(DataTableDirective, { static: false })
  datatableElement: DataTableDirective;
  dtOptionsResupply: any = {
    responsive: true,
    paging: false,
    scrollCollapse: true,
    scrollY: '410px',
    scrollX: true,
    dom: 'Bfrtip',
    language: {
      emptyTable: 'No records',
      info: 'Total : _MAX_ records',
      infoEmpty: 'Total : _MAX_ records',
      infoFiltered: '(filtered : _TOTAL_ records)',
      loadingRecords: 'Loading...',
      zeroRecords: 'No matching records',
    },
    buttons: [
      {
        extend: 'excel',
        text: 'Export',
        filename: 'Master Resupply',
        exportOptions: {
          columns: ':visible:not(:first-child,:eq(0)) ',
        },
      },
      {
        extend: 'colvis',
        columns: ':not(:eq(0), :eq(1))',
      }
    ]
  };

  constructor(private snackBar: MatSnackBar, private resupplyRemainderSettingService: ResupplyRemainderSettingService, private toastr: ToastrService, private datepipe: DatePipe) { }

  ngOnInit(): void {
    this.dtOptionsResupply.buttons[0].filename =
      'Master Resupply' + '-' + this.datepipe.transform(new Date(), 'MM-dd-yyyy');
  }

  // //After View Method
  ngAfterViewInit(): void {
    this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.columns().every(function () {
        const that = this;
        $('input', this.footer()).on('keyup change', function () {
          if (that.search() !== this['value']) {
            that.search(this['value']).draw();
          }
        });
      });
    });
  }

  editResuplly(resuppluId) {
    this.edit.emit(resuppluId);
  }

  onRowDoubleClick(textToCopy) {
    this.copyTextToClipboard(textToCopy);
    this.showNotification('Text copied: ' + textToCopy);
  }

  showNotification(message: string): void {
    this.snackBar.open(message, 'Close', {
      duration: 3000, // Duration in milliseconds
      horizontalPosition: 'end', // Positioning the notification horizontally
      verticalPosition: 'bottom', // Positioning the notification vertically
    });
  }

  copyTextToClipboard(text: string): void {
    const textArea = document.createElement('textarea');
    textArea.value = text;
    document.body.appendChild(textArea);
    textArea.select();
    document.execCommand('Copy');
    textArea.remove();
  }

  statusChange(event: MatSlideToggleChange, id: string) {
    if (event.checked == true) {
      Swal.fire({
        title: 'Do you want to activate?',
        text: 'By Changing Status, this Resupply will be activated',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#34c38f',
        cancelButtonColor: '#f46a6a',
        confirmButtonText: 'Yes, Activate it!'
      }).then(result => {
        if (result.value) {
          if (id && id !== "" && id !== null && id !== undefined) {
            this.resupplyRemainderSettingService.updateActiveStatusByIdAndIsActive(id, true).subscribe(response => {
              this.toastr.success('Activated Successfully', 'Success');
              this.isLoadTbl.emit(true);
            }, (err) => {
              this.isLoadTbl.emit(true);
              const data: HttpErrorResponse = err;
              const message = JSON.parse(data?.error);
              Swal.fire({
                icon: 'info',
                text: message?.error?.message,
              });
            });
          }
        }
        else {
          event.source.checked = false;
        }
      });
    }
    else
      if (event.checked == false) {
        Swal.fire({
          title: 'Do you want to deactivate?',
          text: 'By Changing Status, this Resupply will be deactivated',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#34c38f',
          cancelButtonColor: '#f46a6a',
          confirmButtonText: 'Yes, deactivate it!'
        }).then(result => {
          if (result.value) {
            if (id && id !== "" && id !== null && id !== undefined) {
              this.resupplyRemainderSettingService.updateActiveStatusByIdAndIsActive(id, false).subscribe(response => {
                this.toastr.success('Deactivated Successfully', 'Success')
                this.isLoadTbl.emit(true);
              }, (err) => {
                this.isLoadTbl.emit(true);
                const data: HttpErrorResponse = err;
                const message = JSON.parse(data?.error);
                Swal.fire({
                  icon: 'info',
                  text: message?.error?.message,
                });
              });
            }
          }
          else {
            event.source.checked = true;
          }
        });
      }
  }
}
