<div class="py-2" style="border: 2px">
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-5 d-inline-flex align-items-center">
          <h3 class="font-weight-bolder mb-3">
            <b class="customThemeClass">Master / Order Shipping</b>
          </h3>
        </div>
      </div>   
    </div>
</div>

<div class="card card-body">
<app-ordershipppingtable [tableData]="descriptionData" *ngIf="!isLoading"
(reloadDescriptionTableEvent)="loadDescription()"></app-ordershipppingtable>
  <!--Loader Section-->
  <div class="row" *ngIf="isLoading">
    <div class="col-12">
      <div class="d-flex justify-content-center" *ngIf="isLoading">
        <img class="img-responsive pb-2 mr-1" src="assets/gifs/Infinity-loader.gif" />
      </div>
    </div>
  </div>
</div>
<br>

