<div class="container-fluid">
    <div class="row">
        <div class="col-lg-12">
            <div class="d-flex align-items-center justify-content-between">
                <h3 class="mb-0 header-text">
                    Template
                </h3>
                <div class="page-title-box page-title-right">
                    <button mat-dialog-close mat-icon-button>
                        <mat-icon>close</mat-icon>
                    </button>
                </div>
            </div>
        </div>

        <div class="col-12">
            <div class="card card-body p-2">
                <div class="pdf-container pb-4" *ngIf="isPdfLoad">
                    <ng-container>
                        <ngx-extended-pdf-viewer [base64Src]="strPdfString" [height]="pdfheight"
                            [showSidebarButton]="false" [textLayer]="true"
                            [showPagingButtons]="true" [showOpenFileButton]="false" [showHandToolButton]="false"
                            [showScrollingButton]="false" [showSpreadButton]="false" [showPropertiesButton]="false"
                            [showBookmarkButton]="false" [zoom]="'page-width'" [enableDragAndDrop]="false" [showFindButton]="false">
                        </ngx-extended-pdf-viewer>
                    </ng-container>
                </div>
                <div class="row" *ngIf="!isPdfLoad">
                    <div class="col-12">
                        <div class="d-flex justify-content-center">
                            <img class="img-responsive pb-2 mr-1" src="assets/gifs/Infinity-loader.gif" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</div>
