import { ListService } from '@abp/ng.core';
import { DatePipe } from '@angular/common';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SaleOrderSearchService, SaleorderService } from 'projects/order/src/app/order-proxy/order-management/order';
import { TableService } from 'projects/shared/src/app/table.service';

import { Subscription } from 'rxjs';

@Component({
  selector: 'app-patient-order-status',
  templateUrl: './patient-order-status.component.html',
  styleUrls: ['./patient-order-status.component.scss'],
  providers:[ListService,DatePipe ]
})
export class PatientOrderStatusComponent implements OnInit , OnDestroy{
  @Input() patientId: string = "";
  strPageType: string = 'patientOrderStatus';
  tblOrderReponse: { id?: string, orderId?: string, insuranceVerifiesStatus?: string, authorizationStatus?: string, orderStatus?: boolean, createdDate?: string, billingStatus? : string }[] = [];
  organizationUnitId = null;
  isLoading: boolean=true;
  subscription$: Subscription[]=[];
  constructor(
    private table: TableService,
    public list: ListService,
    private router: Router,
    public saleOrderService: SaleorderService,
    public datepipe: DatePipe,
    private orderSearchService: SaleOrderSearchService
  ) { }

  ngOnInit(): void {
    this.table.setPatientOrderStatusTable([]);
    this.getOrderStatusReport();
  }

  ngOnDestroy(): void {
    this.subscription$.forEach((sub) => {
      sub && sub.unsubscribe();
     });
    }


  getOrderStatusReport() {
    this.isLoading=true;
    this.tblOrderReponse=[];
    const saleOrderList = query => this.orderSearchService.getList(query);
    // this.list.hookToQuery(saleOrderList).subscribe(response => {
    // this.tblOrderReponse=[];

    //   response?.items?.forEach((element) => {
    //     if (this.patientId===element?.patientId) {
    //       this.tblOrderReponse.push({
    //         id: element?.id,
    //         orderId: element?.saleorderId,
    //         insuranceVerifiesStatus: element?.patients?.insuranceVerifications?.primaryInsuranceVerifies?.verificationStatus,
    //         authorizationStatus: element?.patients?.authorizations?.primaryAuthorizations?.isCompleted,
    //         orderStatus: element?.orders?.isCompleted === "Done" ,
    //         createdDate: element?.creationTime === null ? "" : this.datepipe.transform(element?.creationTime, 'MM/dd/yyyy') ?? "",
    //         billingStatus: element?.orderBilling?.status
    //       })
    //     }
    //   })
    //   this.isLoading=false;
    //   this.table.setPatientOrderStatusTable(this.tblOrderReponse);
    // }, err => {
    // this.tblOrderReponse=[];
    // this.table.setPatientOrderStatusTable(this.tblOrderReponse);

    //   this.isLoading=false;
    //   const data: HttpErrorResponse = err;
    //   Swal.fire({
    //     icon: 'error',
    //     text: data?.error?.error?.message,
    //   });
    // })
  }
  existingOrders(orderId: string) {
    this.router.navigate(['/patient/saleorderCreateEdit/' + orderId])
  }

}
