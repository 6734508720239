<table datatable [dtOptions]="dtCCCListOptions" class="row-border hover w-100 display">
    <thead>
      <tr>
        <th><mat-icon>apps</mat-icon></th>
        <th [matTooltip]="'Chart No'">Chart No</th>
        <th [matTooltip]="'Patient Name'">Patient Name</th>
        <th [matTooltip]="'Order ID'" class="text-right">Order ID</th>
        <th [matTooltip]="'Amount($)'" class="text-right">Amount($)</th>
        <th [matTooltip]="'Approve ID'" class="text-right">Approve ID</th>
        <th [matTooltip]="'Date'">Date</th>
        <th [matTooltip]="'CSR'">CSR</th>
        <th [matTooltip]="'Settled'" class="text-center">Settled</th>

      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let data of CCSettlementArray">
        <td>
          <button> </button>
        </td>
        <td>
          <a [class.disabled]="isEmpty(data.patientId) || isEmpty(data.chartId)"
            matTooltip="{{ data?.chartId || '-'}}" class="preview-link"
            [routerLink]="'/patientCreateEditTab/' + data?.patientId" target="_blank">
            {{ data?.chartId || '-'}}
          </a>
        </td>
        <td [matTooltip]="data?.patientName || '-'">{{data.patientName}}</td>

        <td  class="text-right" [matTooltip]="data?.mmRefOrderId || '-'"><a class="preview-link"
          [class.disabled]="isEmpty(data.patientId) || isEmpty(data.orderId)"
          [routerLink]="'/orders/saleOrder/' +data.patientId +'/' +data.orderId+'/'+'0'" target="_blank">
          {{data?.mmRefOrderId || '-'}}
        </a></td>
        <td class="text-right" [matTooltip]="data?.patientRespAmount || '-'">{{data.patientRespAmount}}</td>
        <td class="text-right" [matTooltip]="data?.paypalTransId || '-'">{{data.paypalTransId}}</td>
        <td [matTooltip]="data?.paypalPaidDate || '-'">{{data.paypalPaidDate}}</td>
        <td [matTooltip]="data?.csrName || '-'">{{data.csrName}}</td>
        <td class="text-center"><mat-checkbox  ngModel></mat-checkbox></td>
      </tr>
    </tbody>
    <tfoot>
        <tr>
          <th><mat-icon>apps</mat-icon></th>
          <th>
            <input matInput class="textbox-border-class text-center" type="text" placeholder="Chart No"
              name="search-ChartNo" />
          </th>
          <th>
            <input matInput class="textbox-border-class" type="text" placeholder="Patient Name" name="search-PatientName" />
          </th>
          <th>
            <input matInput type="text" class="textbox-border-class" placeholder="Order ID" name="search-OrderID" />
          </th>
          <th>
            <input matInput type="text" class="textbox-border-class" placeholder="Amount"
              name="search-Amount" />
          </th>
          <th>
            <input matInput type="text" class="textbox-border-class" placeholder="Approve ID" name="search-ApproveId" />
          </th>
          <th>
            <input matInput class="textbox-border-class" type="text" placeholder="Date" name="search-Date" />
          </th>
          <th>
            <input matInput class="textbox-border-class text-center" type="text" placeholder="CSR" name="search-CSR" />
          </th>
          <th><mat-icon>no_encryption</mat-icon></th>
        </tr>
      </tfoot>
  </table>
