import { PagedAndSortedResultRequestDto } from '@abp/ng.core';
import { DatePipe } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { MatSort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { IdentityUserUsingTenantIdDto } from 'projects/admin/src/app/admin-proxy/platform-app-management/rcm/platform-management/user/models';
import { UserService } from 'projects/admin/src/app/admin-proxy/platform-app-management/rcm/platform-management/user/user.service';
import { TableService } from 'projects/shared/src/app/table.service';
import { DateValidator } from 'projects/shared/src/app/validations/date-validator';
import Swal from 'sweetalert2';
import { ToastrService } from "ngx-toastr";
import { CreateUpdatePickupWorkingHoursDTO, SearchPickupDTO } from '../onTime-proxy/rcm/on-time-management/on-time-schedule/dto/models';
import { PickupWorkingHoursService } from '../onTime-proxy/rcm/on-time-management/on-time-schedule/pickup-working-hours.service';
import { PickupCreationComponent } from '../pickup-creation/pickup-creation.component';
import { Title } from '@angular/platform-browser';


@Component({
  selector: 'app-ontime-pickup-area',
  templateUrl: './ontime-pickup-area.component.html',
  styleUrls: ['./ontime-pickup-area.component.scss'],
  providers: [DatePipe]

})
export class OntimePickupAreaComponent implements OnInit {

  isLoading: boolean;
  step: boolean = false;
  leaveApprovalForm: FormGroup;
  leaveApprovalSearchForm: FormGroup;
  BirthdateMaxDate: Date;
  BirthdateMinDate: Date;
  chosenYearDate: Date;
  dataSource: MatTableDataSource<any> = new MatTableDataSource<any>([]);
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatTable, { static: false }) tableRef: MatTable<any>; desigation: string;
  displayedColumns: string[] = [  'Options','Respiratory therapist', 'Pickup Detail', 'Created On', 'Status',];
  pickupTableData: any[] = [];
  userTableData: any[] = [];
  tenanatId: string = ""
  pickupCreationId: string = "";
  status: string = '';
  constructor(
    private formBuilder: FormBuilder,
    public dialog: MatDialog,
    private onTimeService: PickupWorkingHoursService,
    private datepipe: DatePipe,
    private tableService: TableService,
    private userService: UserService,
    private toastr: ToastrService,
    private dateValidator: DateValidator,
    public title: Title,
  ) { }

  ngOnInit(): void {
    this.title.setTitle("Qsecure | Pickup");
    this.tableService.getTenantId().subscribe(value => {
      this.tenanatId = value;
    })
    this.leaveApprovalForm = this.formBuilder.group({
      txtDesingnation: new FormControl(),
      txtFromDate: new FormControl(),
      txtToDate: new FormControl(),
      txtEmployee: new FormControl(),
      rbtnApprovalStatus: new FormControl(),

    })

    this.leaveApprovalSearchForm = this.formBuilder.group({
      respiratoryTherapistName: new FormControl(""),
      pickupDetails: new FormControl(""),
      createdDate: new FormControl("", this.dateValidator.dateVaidator),
      status: new FormControl(""),
      toDate: new FormControl("", this.dateValidator.dateVaidator),
      comments: new FormControl(""),

    });


    // valueChanges for filter
    this.leaveApprovalSearchForm.valueChanges.subscribe((value: SearchPickupDTO) => {
      let filter: SearchPickupDTO = {
        respiratoryTherapistName: value?.respiratoryTherapistName?.trim()?.toLowerCase(),
        pickupDetails: value?.pickupDetails?.trim()?.toLowerCase(),
        createdDate: value?.createdDate?.trim()?.toLowerCase(),
        status: value?.status?.trim()?.toLowerCase(),
      };


      if (this.leaveApprovalSearchForm.valid) {
        this.dataSource.filter = JSON.stringify(filter);
        this.filtertable(filter);
      }
    }, err => {
    });
    let filters: IdentityUserUsingTenantIdDto = {
      tenantId: this.tenanatId
    }
    this.userService.getUserList(filters).subscribe(response => {
      response && response?.forEach(element => {
        this.userTableData.push({
          name: element?.userName,
          email: element?.email,
          role: element?.roleName,
          id: element?.id,
        });
      })
      this.getTableData();

    }, err => {
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    })

  }

  // ! table data getlist
  getTableData() {
    this.isLoading = true
    this.onTimeService.getList(new PagedAndSortedResultRequestDto).subscribe(response => {


      this.pickupTableData = [];
      response && response?.items?.forEach(element => {
        let userName = this.userTableData?.filter(value => {

          return value && value?.id === element?.respiratoryTherapistName
        })[0]?.name;

        this.pickupTableData.push({
          name: element?.respiratoryTherapistName,
          details: element?.pickupDetails,
          status: element?.status,
          data: element?.id,
          createDate: element?.creationTime === null ? "" : this.datepipe.transform(element?.creationTime, 'MM/dd/yyyy') ?? ""

        });

      })
      const arrTableData = this.pickupTableData;
      this.dataSource = new MatTableDataSource(arrTableData);
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
      this.isLoading = false;
    }, err => {
      this.isLoading = false;
      const data: HttpErrorResponse = err;
      // Swal.fire({
      //   icon: 'error',
      //   text: data?.error?.error?.message,
      // });
    })
  }

  // ! Search table data
  filtertable(filter) {
    let Date = filter.createdDate;
    // let DateFormat = Date === null ? "" : Intl.DateTimeFormat('en-US').format(new Date(Date));
    let searchInput: SearchPickupDTO = {
      respiratoryTherapistName: filter.respiratoryTherapist,
      pickupDetails: filter.pickupDetail,
      status: filter.status,
      createdDate: dateYYYYMMDDtoMMDDYYYY(Date)
    }
    this.pickupTableData = [];
    this.onTimeService.searchPickupByInput(searchInput).subscribe(data => {
      this.pickupTableData = [];
      data?.items?.forEach(element => {
        this.pickupTableData.push({
          name: element.respiratoryTherapistName,
          details: element?.pickupDetails,
          status: element?.status,
          data: element?.id,
          createDate: element?.creationTime === null ? "" : this.datepipe.transform(element?.creationTime, 'MM/dd/yyyy') ?? ""
        });
        this.dataSource = new MatTableDataSource(this.pickupTableData);
        this.dataSource.sort = this.sort;
        setTimeout(() => this.dataSource.paginator = this.paginator);
      })
    })
  }


  ViewPickup(data) {
    const dialogRef = this.dialog.open(PickupCreationComponent, {
      disableClose: true,
      data: { pickupCreationId: data.data, pickupDetails: data?.details }
    });

    dialogRef.afterClosed().subscribe(() => {
      this.getTableData()
    });
  }
  // ! delete user from list
  deletePickup($event) {
    Swal.fire({
      title: 'Are you sure you want to Delete?',
      text: 'You won\'t be able to retrieve this data!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#34c38f',
      cancelButtonColor: '#f46a6a',
      confirmButtonText: 'Yes, delete it!'
    }).then(result => {
      if (result.value) {
        this.onTimeService.delete($event.data).subscribe(response => {
          this.pickupTableData.forEach(element => {
            if ($event.id == element?.id) {
              let index = this.pickupTableData.indexOf(element, 0)
              this.pickupTableData.splice(index, 1)
            }
          });
          this.getTableData();
        }, err => {
          const data: HttpErrorResponse = err;
          Swal.fire({
            icon: 'info',
            text: data?.error?.error?.message,
          });
        });
      }
    });
  }
  openModel() {
    const dialogRef = this.dialog.open(PickupCreationComponent, {
      disableClose: true,
      data: {}
    });
    dialogRef.afterClosed().subscribe(() => {
      this.getTableData()
    });

  }

  pickupStatusChange(event: MatSlideToggleChange, data: any) {
    this.status = event.checked == true ? "Active" : "Inactive";

    if (event.checked == true) {
      Swal.fire({
        title: 'Do you want to activate?',
        text: 'By Changing Status, this Pickup Area will be activated',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#34c38f',
        cancelButtonColor: '#f46a6a',
        confirmButtonText: 'Yes, Activate it!'
      }).then(result => {
        if (result.value) {
          const input: CreateUpdatePickupWorkingHoursDTO = {
            respiratoryTherapistName:data?.respiratoryTherapistName,
            status: "Active",
            pickupSundayWorkingHours:data?.pickupSundayWorkingHours,
            pickupMondayWorkingHours: data?.pickupMondayWorkingHours,
            pickupTuesdayWorkingHours: data?.officeTuesdayWorkingHours,
            pickupWednesdayWorkingHours: data?.pickupWednesdayWorkingHours,
            pickupThursdayWorkingHours:data?.pickupThursdayWorkingHours,
            pickupFridayWorkingHours:data?.pickupFridayWorkingHours,
            pickupSaturdayWorkingHours:data?.pickupSaturdayWorkingHours,

          }
          if (data?.data && data?.data !== "" && data?.data !== null && data?.data !== undefined) {
            this.onTimeService.update(data?.data, input).subscribe(response => {
              // Swal.fire({ title: 'Success', html: 'Activated Successfully', icon: 'success', timer: 3000, timerProgressBar: true })
              this.toastr.success('Activated Successfully','Success')
              this.getTableData();
            }, (err) => {
              const data: HttpErrorResponse = err;
              Swal.fire({
                icon: 'info',
                text: data?.error?.error?.message,
              });
            })
          }
        }
        else {
          this.getTableData();
        }
      });
    }
    else
      if (event.checked == false) {
        Swal.fire({
          title: 'Do you want to deactivate?',
          text: 'By Changing Status, this Picup Area will be deactivated',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#34c38f',
          cancelButtonColor: '#f46a6a',
          confirmButtonText: 'Yes, deactivate it!'
        }).then(result => {
          if (result.value) {
            const input: CreateUpdatePickupWorkingHoursDTO = {
              respiratoryTherapistName:data?.respiratoryTherapistName,
              status: "Inactive",
              pickupSundayWorkingHours:data?.pickupSundayWorkingHours,
              pickupMondayWorkingHours: data?.pickupMondayWorkingHours,
              pickupTuesdayWorkingHours: data?.officeTuesdayWorkingHours,
              pickupWednesdayWorkingHours: data?.pickupWednesdayWorkingHours,
              pickupThursdayWorkingHours:data?.pickupThursdayWorkingHours,
              pickupFridayWorkingHours:data?.pickupFridayWorkingHours,
              pickupSaturdayWorkingHours:data?.pickupSaturdayWorkingHours,
            }
            if (data?.data && data?.data !== "" && data?.data !== null && data?.data !== undefined) {
              this.onTimeService.update(data?.data, input).subscribe(response => {
                // Swal.fire({ title: 'Success', html: 'Deactivateed Successfully', icon: 'success', timer: 3000, timerProgressBar: true })
                this.toastr.success('Deactivateed Successfully','Success')
                this.getTableData();
              }, (err) => {
                const data: HttpErrorResponse = err;
                Swal.fire({
                  icon: 'info',
                  text: data?.error?.error?.message,
                });
              })
            }
          }
          else {
            this.getTableData();
          }
        });
      }
  }


}


function dateYYYYMMDDtoMMDDYYYY(strDate: string): string {
  if (strDate && strDate !== "" && typeof strDate === 'string' && strDate !== null && strDate !== undefined && strDate.length >= 1 && !isNaN(new Date(strDate).getTime())) {
    let latest_date = Intl.DateTimeFormat('en-US')?.format(new Date(strDate))
    return latest_date;
  }
  return "";
}
