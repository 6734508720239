import type { CreateUpdateInventoryTransferStatusDTO, InventoryTransferStatusDTO, aptClasslistDTO } from './dto/models';
import type { } from './dto/models';

import { RestService } from '@abp/ng.core';
import type { PagedAndSortedResultRequestDto, PagedResultDto } from '@abp/ng.core';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class InventoryTransferStatusService {
  apiName = 'ItemManagement';

  create = (input: CreateUpdateInventoryTransferStatusDTO) =>
    this.restService.request<any, InventoryTransferStatusDTO>({
      method: 'POST',
      url: '/api/ItemApp/inventory-transfer-status',
      body: input,
    },
    { apiName: this.apiName });

  delete = (id: string) =>
    this.restService.request<any, void>({
      method: 'DELETE',
      url: `/api/ItemApp/inventory-transfer-status/${id}`,
    },
    { apiName: this.apiName });


  get = (id: string) =>
    this.restService.request<any, InventoryTransferStatusDTO>({
      method: 'GET',
      url: `/api/ItemApp/inventory-transfer-status/${id}`,
    },
    { apiName: this.apiName });

  getList = (input: PagedAndSortedResultRequestDto) =>
    this.restService.request<any, PagedResultDto<InventoryTransferStatusDTO>>({
      method: 'GET',
      url: '/api/ItemApp/inventory-transfer-status',
      params: { skipCount: input.skipCount, maxResultCount: input.maxResultCount, sorting: input.sorting },
    },
    { apiName: this.apiName });

  update = (id: string, input: CreateUpdateInventoryTransferStatusDTO) =>
    this.restService.request<any, InventoryTransferStatusDTO>({
      method: 'PUT',
      url: `/api/ItemApp/inventory-transfer-status/${id}`,
      body: input,
    },
    { apiName: this.apiName });

    ServiceTypeDetails = () =>
    this.restService.request<any, any>({
      method: 'Get',
      url: `/api/ItemApp/product-category-details`,
    },
    { apiName: this.apiName });
    ServiceTypeDetailsV1 = () =>
      this.restService.request<any, any>({
        method: 'Get',
        url: `/api/ItemApp/product/productcategorydata`,
      },
      { apiName: this.apiName });

    getAptclassList = () =>
      this.restService.request<any, aptClasslistDTO[]>({
        method: 'GET',
        url: '/api/PlatformApp/inbound-document/aptclass-list',
      },
      { apiName: this.apiName });

  constructor(private restService: RestService) {}
}
