<!-- <div class="container-fluid"> -->
  <div class="row bg">
    <div class="col-12">
      <div class="d-flex align-item-center justify-content-between m-0">
        <h3 class="font-weight-bolder mb-2 mt-2">
          <b class="customThemeClass">Inventory Management / Manufacturer</b>
        </h3>
        <div class="page-title-box page-title-right mt-1 mb-0">
          <ol class="breadcrumbs m-0">
            <!-- <button
            mat-button
            class="mr-2 buttonColor"
            [disabled]="
              dataSource && dataSource?.data && dataSource?.data?.length === 0
            "

          >
            Export
          </button> -->
            <!-- <li class="breadcrumb-item mt-2">Inventory</li>
            <li class="breadcrumb-item mt-2">Manufacturer </li> -->
          </ol>
        </div>
      </div>
    </div>
  </div>
  <div>
    <div class="col-lg-12 pb-2 mt-2">
      <mat-accordion>
        <mat-expansion-panel
          [expanded]="step"
          (afterExpand)="toggle()"
          (closed)="toggleV2()"
        >
          <mat-expansion-panel-header>
            <mat-panel-title class="customThemeClass">
              <ng-container *ngIf="vendorId === ''; else elseTitleTemplate">
                <b class="pt-2 small-text">Add Manufacturer </b> </ng-container
              ><br /><br />
              <ng-template #elseTitleTemplate>
                <b class="small-text">Edit Manufacturer </b>
              </ng-template>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <mat-card-content>
            <form [formGroup]="productVendorForm">
              <div class="row pb-4">
                <!-- Manufacturer  Code -->
                <mat-form-field class="col-4 dd-text">
                  <mat-label
                    >Manufacturer Code
                    <span class="asterisk">*</span></mat-label
                  >
                  <input
                    autocomplete="new-txtVendorCode"
                    matInput
                    formControlName="txtVendorCode"
                    placeholder="Enter Manufacturer  Code"
                    type="text"
                    maxlength="40"
                  />
                  <mat-error
                    *ngIf="
                      productVendorForm?.get('txtVendorCode')?.touched &&
                      productVendorForm?.get('txtVendorCode')?.errors?.required
                    "
                  >
                    Manufacturer Code is a required field!
                  </mat-error>
                  <mat-error
                    *ngIf="
                      productVendorForm?.get('txtVendorCode')?.touched &&
                      productVendorForm?.get('txtVendorCode')?.errors
                        ?.sAlphabets
                    "
                  >
                    Enter only Alphabets!
                  </mat-error>
                </mat-form-field>

                <!-- Manufacturer  Name -->
                <mat-form-field class="col-4 dd-text">
                  <mat-label
                    >Manufacturer Name
                    <span class="asterisk">*</span></mat-label
                  >
                  <input
                    autocomplete="new-txtVendorName"
                    matInput
                    formControlName="txtVendorName"
                    placeholder="Enter Manufacturer  Name"
                    type="text"
                    maxlength="40"
                  />
                  <mat-error
                    *ngIf="
                      productVendorForm?.get('txtVendorName')?.touched &&
                      productVendorForm?.get('txtVendorName')?.errors?.required
                    "
                  >
                    Manufacturer Name is a required field!
                  </mat-error>
                  <!-- <mat-error
                    *ngIf="
                      productVendorForm?.get('txtVendorName')?.touched &&
                      productVendorForm?.get('txtVendorName')?.errors
                    "
                  >
                    Enter only Alphabets!
                  </mat-error> -->
                </mat-form-field>

                <!-- Manufacturer  Email -->
                <mat-form-field class="col-4 dd-text">
                  <mat-label>Manufacturer Email </mat-label>
                  <!-- [textMask]="{ mask: emailMask }" -->
                  <input
                    [textMask]="{ mask: emailMask }"
                    autocomplete="new-txtVendorEmailId"
                    matInput
                    formControlName="txtVendorEmailId"
                    placeholder="Enter Manufacturer  Email"
                    type="text"
                    pattern="[a-zA-Z0-9._!#$%&'()*+,-./:;<=>?@[\]^_`{|}~]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$"
                    maxlength="40"
                  />
                  <mat-error
                    *ngIf="
                      productVendorForm?.get('txtVendorEmailId')?.touched &&
                      productVendorForm?.get('txtVendorEmailId')?.errors
                        ?.required
                    "
                  >
                    Manufacturer Email is a required field!
                  </mat-error>
                  <mat-error
                    *ngIf="
                      productVendorForm?.get('txtVendorEmailId')?.touched &&
                      productVendorForm?.get('txtVendorEmailId')?.errors
                        ?.pattern
                    "
                  >
                    Please Enter a Valid Email Address
                  </mat-error>
                  <!-- <mat-error
                    *ngIf="
                      productVendorForm?.get('txtVendorEmailId')?.touched &&
                      productVendorForm?.get('txtVendorEmailId')?.errors
                        ?.sAlphabets
                    "
                  >
                    Enter only Alphabets!
                  </mat-error> -->
                </mat-form-field>

                <!-- Manufacturer  Phone Number -->
                <mat-form-field class="col-4 pt-1 dd-text">
                  <mat-label>Manufacturer Phone Number </mat-label>
                  <input
                    autocomplete="new-txtVendorPhoneNumber"
                    matInput
                    formControlName="txtVendorPhoneNumber"
                    placeholder="Enter Phone Number"
                    type="text"
                    mask="(000)-000-0000"
                    prefix="{{ phoneCode }}-"
                  />
                  <mat-error
                    *ngIf="
                      productVendorForm?.get('txtVendorPhoneNumber')?.touched &&
                      productVendorForm?.get('txtVendorPhoneNumber')?.errors
                        ?.required
                    "
                  >
                    Manufacturer Phone Number is a required field!
                  </mat-error>
                  <mat-error
                    *ngIf="
                      productVendorForm?.get('txtVendorPhoneNumber')?.touched &&
                      productVendorForm?.get('txtVendorPhoneNumber')?.errors
                        ?.sAlphabets
                    "
                  >
                    Enter only Numbers!
                  </mat-error>
                  <!-- <mat-error
                    *ngIf="
                      productVendorForm?.get('txtVendorPhoneNumber')?.touched &&
                      productVendorForm?.get('txtVendorPhoneNumber')?.errors
                    "
                  >
                    Enter Minimum 10 Digits!
                  </mat-error> -->
                </mat-form-field>

                <!-- Manufacturer  Address -->
                <mat-form-field class="col-4 pt-1 dd-text">
                  <mat-label>Manufacturer Address </mat-label>
                  <textarea
                    autocomplete="new-txtVendorAddress"
                    formControlName="txtVendorAddress"
                    maxlength="40"
                    rows="1"
                    matInput
                  ></textarea>
                  <mat-error
                    *ngIf="
                      productVendorForm?.get('txtVendorAddress')?.touched &&
                      productVendorForm?.get('txtVendorAddress')?.errors
                        ?.sAlphabets
                    "
                  >
                    Enter only Alphabets!
                  </mat-error>
                  <mat-error
                    *ngIf="
                      productVendorForm?.get('txtVendorAddress')?.touched &&
                      productVendorForm?.get('txtVendorAddress')?.errors
                        ?.required
                    "
                  >
                    Manufacturer Address is a required field!
                  </mat-error>
                </mat-form-field>

                <!-- Contact Person Name -->
                <mat-form-field class="col-4 pt-1 dd-text">
                  <mat-label>Contact Person Name </mat-label>
                  <input
                    autocomplete="new-txtPersonName"
                    matInput
                    formControlName="txtPersonName"
                    placeholder="Enter Contact Person Name"
                    type="text"
                    maxlength="40"
                    pattern="[a-zA-Z0-9-_\s]*$"
                  />
                  <mat-error
                    *ngIf="
                      productVendorForm?.get('txtPersonName')?.touched &&
                      productVendorForm?.get('txtPersonName')?.errors?.required
                    "
                  >
                    Manufacturer Name is a required field!
                  </mat-error>
                  <mat-error
                    *ngIf="
                      productVendorForm?.get('txtPersonName')?.touched &&
                      productVendorForm?.get('txtPersonName')?.errors
                    "
                  >
                    Enter only Alphabets!
                  </mat-error>
                </mat-form-field>

                <!-- Contact Number -->
                <mat-form-field class="col-4 pt-1 dd-text">
                  <mat-label>Contact number</mat-label>
                  <input
                    autocomplete="new-txtPersonPhoneNumber"
                    matInput
                    formControlName="txtPersonPhoneNumber"
                    placeholder="Enter Phone Number"
                    type="text"
                    mask="(000)-000-0000"
                    prefix="{{ phoneCode }}-"
                  />
                  <mat-error
                    *ngIf="
                      productVendorForm?.get('txtPersonPhoneNumber')?.touched &&
                      productVendorForm?.get('txtPersonPhoneNumber')?.errors
                        ?.required
                    "
                  >
                    Contact number is a required field!
                  </mat-error>
                  <mat-error
                    *ngIf="
                      productVendorForm?.get('txtPersonPhoneNumber')?.touched &&
                      productVendorForm?.get('txtPersonPhoneNumber')?.errors
                        ?.sAlphabets
                    "
                  >
                    Enter only Numbers!
                  </mat-error>
                  <mat-error
                    *ngIf="
                      productVendorForm?.get('txtPersonPhoneNumber')?.touched &&
                      productVendorForm?.get('txtPersonPhoneNumber')?.errors
                    "
                  >
                    Enter Minimum 10 Digits!
                  </mat-error>
                </mat-form-field>

                <!--  Country -->
                <mat-form-field class="col-4 pt-1 dd-text">
                  <mat-label>Country </mat-label>
                  <mat-select
                    formControlName="txtCountry"
                    (selectionChange)="onChangeState($event.value)"
                    [(value)]="Country"
                    #singleSelect
                  >
                    <mat-option>
                      <ngx-mat-select-search
                        placeholderLabel="Search"
                        noEntriesFoundLabel="No Matches found"
                        formControlName="txtCountryFilter"
                      ></ngx-mat-select-search>
                    </mat-option>
                    <mat-option
                      *ngFor="let country of filteredCountries | async"
                      [value]="country.countryShortName"
                    >
                      {{ country.countryName }}
                    </mat-option>
                  </mat-select>
                  <button
                    mat-button
                    (click)="Country = undefined; $event.stopPropagation()"
                    *ngIf="Country"
                    (click)="clearCountry()"
                    matSuffix
                    mat-icon-button
                  >
                    <mat-icon>close</mat-icon>
                  </button>
                </mat-form-field>

                <!-- State -->
                <mat-form-field class="col-4 pt-1 dd-text">
                  <mat-label>State </mat-label>
                  <mat-select
                    formControlName="txtState"
                    (selectionChange)="onChangeCity($event.value, '')"
                    [(value)]="State"
                    #singleSelect
                  >
                    <mat-option>
                      <ngx-mat-select-search
                        placeholderLabel="Search"
                        noEntriesFoundLabel="No Matches found"
                        formControlName="txtStateFilter"
                      ></ngx-mat-select-search>
                    </mat-option>
                    <mat-option
                      *ngFor="let state of filteredStates | async"
                      [value]="state.stateShortName"
                    >
                      {{ state.stateName }}
                    </mat-option>
                  </mat-select>
                  <button
                    mat-button
                    (click)="State = undefined; $event.stopPropagation()"
                    *ngIf="State"
                    (click)="clearState()"
                    matSuffix
                    mat-icon-button
                  >
                    <mat-icon>close</mat-icon>
                  </button>
                </mat-form-field>

                <!-- City -->
                <mat-form-field class="col-4 pt-1 dd-text">
                  <mat-label>City </mat-label>
                  <mat-select
                    formControlName="txtCity"
                    [(value)]="City"
                    #singleSelect
                  >
                    <mat-option>
                      <ngx-mat-select-search
                        placeholderLabel="Search"
                        noEntriesFoundLabel="No Matches found"
                        formControlName="txtCityFilter"
                      ></ngx-mat-select-search>
                    </mat-option>
                    <mat-option
                      *ngFor="let city of filteredCities | async"
                      [value]="city.cityName"
                    >
                      {{ city.cityName }}
                    </mat-option>
                  </mat-select>
                  <button
                    mat-button
                    (click)="City = ''; $event.stopPropagation()"
                    *ngIf="City"
                    matSuffix
                    (click)="clearCity()"
                    mat-icon-button
                  >
                    <mat-icon>close</mat-icon>
                  </button>
                </mat-form-field>

                <mat-form-field class="col-4 dd-text">
                  <mat-label>Zip </mat-label>
                  <input
                    autocomplete="new-txtZip"
                    matInput
                    formControlName="txtZip"
                    placeholder="Enter Zip"
                  
                    type="text"
                    class="zip-code-input"
                    maxlength="10"
                  />
                 
                <mat-error
                  *ngIf=" productVendorForm?.get('txtZip')?.touched && productVendorForm?.get('txtZip')?.errors?.pattern">
                  Enter only Numbers!
                </mat-error>
                <mat-error *ngIf="productVendorForm?.get('txtZip')?.touched &&
                productVendorForm?.get('txtZip')?.errors?.required ">
                  <!-- ZipCode is a required field! -->
                </mat-error>
                </mat-form-field>

                <mat-form-field class="col-4 dd-text">
                  <mat-label>Fax No </mat-label>
                  <input
                    autocomplete="new-txtFaxNo"
                    matInput
                    
                    maxlength="40"
                    formControlName="txtFaxNo"
                    placeholder="Enter FaxNo"
                    type="text"
                    mask="(000)-000-0000" prefix="+1-" 
                  />
                </mat-form-field>

                <mat-form-field class="col-4 dd-text">
                  <mat-label>WebSite </mat-label>
                  <input
                    autocomplete="new-txtWebSite"
                    matInput
                    maxlength="50"
                    formControlName="txtWebSite"
                    placeholder="Enter WebSite"
                    type="url"
                  />

                  <mat-error
                  *ngIf="
                    productVendorForm?.get('txtWebSite')?.touched &&
                    productVendorForm?.get('txtWebSite')?.errors
                      ?.pattern
                  "
                >
                Please provide valid url
                </mat-error>

    
                </mat-form-field>

                <mat-form-field class="col-4 dd-text">
                  <mat-label>Remarks </mat-label>
                  <input
                    autocomplete="new-txtRemarks"
                    matInput
                    formControlName="txtRemarks"
                    placeholder="Enter Remarks"
                    type="text"
                    maxlength="50"
                  />
                </mat-form-field>

                <mat-form-field class="col-4 dd-text">
                  <mat-label>Order Email </mat-label>
                  <input
                    [textMask]="{ mask: emailMask }"
                    autocomplete="new-txtOrderEmail"
                    matInput
                    formControlName="txtOrderEmail"
                    placeholder="Enter OrderEmail"
                    type="text"
                    pattern="[a-zA-Z0-9._!#$%&'()*+,-./:;<=>?@[\]^_`{|}~]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$"
                    maxlength="40"
                  />
                  <mat-error
                    *ngIf="
                      productVendorForm?.get('txtOrderEmail')?.touched &&
                      productVendorForm?.get('txtOrderEmail')?.errors?.required
                    "
                  >
                    Manufacturer Email is a required field!
                  </mat-error>
                  <mat-error
                    *ngIf="
                      productVendorForm?.get('txtOrderEmail')?.touched &&
                      productVendorForm?.get('txtOrderEmail')?.errors?.pattern
                    "
                  >
                    Please Enter a Valid Email Address
                  </mat-error>
                </mat-form-field>

                <mat-form-field class="col-4 dd-text">
                  <mat-label>Lot Number Length</mat-label>
                  <input
                    autocomplete="new-txtLotNumberLength"
                    matInput
                    maxlength="12"
                    (keypress)="onKeyPress($event)"
                    (paste)="onPaste($event)"
                    formControlName="txtLotNumberLength"
                    placeholder="Enter LotNumberLength"
                    type="text"
                  />
                </mat-form-field>

                <mat-form-field class="col-4 dd-text">
                  <mat-label>Serial Number Length</mat-label>
                  <input
                    autocomplete="new-txtSerialNumberLength"
                    matInput
                    (keypress)="onKeyPress($event)"
                    (paste)="onPaste($event)"
                    maxlength="12"
                    formControlName="txtSerialNumberLength"
                    placeholder="Enter Serial Number Length"
                    type="text"
                  />
                </mat-form-field>
                <mat-form-field class="col-4 dd-text">
                  <mat-label>Account Number</mat-label>
                  <input
                    autocomplete="new-txtAccountNumber"
                    matInput
                    
                    maxlength="20"
                    formControlName="txtAccountNumber"
                    placeholder="Enter Account Number"
                    type="text"
                  />
                </mat-form-field>
              </div>

              <!-- Buttons -->
              <div class="row">
                <div class="col-lg-12 d-flex justify-content-center">
                  <button
                    mat-button
                    (click)="saveVendorDetails()"
                    class="buttonColor mb-1 mr-2"
                    [disabled]="
                      !(
                        !productVendorForm?.invalid && productVendorForm?.dirty
                      ) || saveButtonHide
                    "
                  >
                    Save
                  </button>
                  <button
                    mat-button
                    (click)="resetForm()"
                    class="resetclr buttonColor mb-1 mr-2"
                  >
                    Reset
                  </button>
                </div>
              </div>
            </form>
          </mat-card-content>
        </mat-expansion-panel>
      </mat-accordion>
    </div>
  </div>

  <h4 class="customThemeClass ml-3 mt-1">
    <b class="header-text">Manufacturer List</b>
  </h4>
  <div class="col-lg-12 mt-2">
    <div class="card card-body px-0 py-2">
      <div class="col-12 content table-responsive">
        <div class="d-flex justify-content-center" *ngIf="!isLoadData">
          <img
            class="img-responsive pb-2 mr-1"
            src="assets/gifs/Infinity-loader.gif"
          />
        </div>
        <div class="table table-responsive" *ngIf="isLoadData">
          <app-product-vendor-table-list
            [vendorList]="vendorList"
          ></app-product-vendor-table-list>
        </div>
      </div>
    </div>
  </div>
<!-- </div> -->
