<div class="card card-body">
    <div class="row">
        <div class="col-4">
            <mat-label><b class="customThemeClass custom-tab-header">Physician Info</b></mat-label>
        </div>
        <div class="col-8">
            <div class="d-flex align-items-center justify-content-between">
                <div class="page-title-box page-title-right">
                    <ol class="breadcrumb m-0">
                    </ol>
                </div>
                <button mat-button class="buttonColor font-size-10" (click)="openDoctorModal()"
                    [disabled]="ischeckPracticeId">
                    <i class="fa fa-plus"></i> &nbsp;Add
                </button>
            </div>
        </div>

        <div class="col-lg-12 mt-3">
            <!-- Loader Section -->
            <div class="row" *ngIf="isLoading">
                <div class="col-12">
                    <div class="d-flex justify-content-center" *ngIf="isLoading">
                        <img class="img-responsive pb-2 mr-1" src="assets/gifs/Infinity-loader.gif" />
                    </div>
                </div>
            </div>
            <div *ngIf="!isLoading" class="table table-responsive">
                <table id="tbl" datatable [dtOptions]="dtOptions" style="width: 100%" class="hover nowrap_th_td display">
                    <thead>
                        <tr>
                            <th><mat-icon>apps</mat-icon></th>
                            <th [matTooltip]="'Physicians'">Physicians</th>
                            <th [matTooltip]="'NPI'">NPI</th>
                            <th [matTooltip]="'Address'">Address</th>
                            <th [matTooltip]="'Country'">Country</th>
                            <th [matTooltip]="'State'">State</th>
                            <th [matTooltip]="'City'">City</th>
                            <th [matTooltip]="'Postal Code'">Postal Code</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let data of Physiciandetails">
                            <td>
                                <a class="eyeCursorclass" (click)="updatephysicianId(data?.id)">
                                    <i class="fa fa-pencil editBtnColor mr-1" matTooltip="Click To Edit"></i>
                                </a>
                            </td>
                            <td [matTooltip]="data.fullName || '-'" (dblclick)="onRowDoubleClickV1(data?.fullName || '-')">{{ data.fullName || '-' }}</td>
                            <td [matTooltip]="data.npiNumber || '-'" (dblclick)="onRowDoubleClickV1(data?.npiNumber || '-')">{{ data.npiNumber || '-' }}</td>
                            <td [matTooltip]="data.address || '-'" (dblclick)="onRowDoubleClickV1(data?.address || '-')">{{ data.address || '-' }}</td>
                            <td [matTooltip]="data.country || '-'" (dblclick)="onRowDoubleClickV1(data?.country || '-')">{{ data.country || '-' }}</td>
                            <td [matTooltip]="data.state || '-'" (dblclick)="onRowDoubleClickV1(data?.state || '-')">{{ data.state || '-' }}</td>
                            <td [matTooltip]="data.city || '-'" (dblclick)="onRowDoubleClickV1(data?.city || '-')">{{ data.city || '-' }}</td>
                            <td [matTooltip]="data.postalCode || '-'" (dblclick)="onRowDoubleClickV1(data?.postalCode || '-')">{{ data.postalCode || '-' }}</td>
                        </tr>
                    </tbody>
                    <tfoot>
                        <tr>
                            <th><mat-icon>apps</mat-icon></th>
                            <th>
                                <input matInput type="text" class="textbox-border-class" placeholder="Physicians"
                                    name="search-Physicians" />
                            </th>
                            <th>
                                <input matInput class="textbox-border-class" type="text" placeholder="NPI"
                                    name="search-NPI" />
                            </th>
                            <th>
                                <input matInput type="text" class="textbox-border-class" placeholder="Address"
                                    name="search-Address" />
                            </th>
                            <th>
                                <input matInput type="text" class="textbox-border-class" placeholder="Country"
                                    name="search-Country" />
                            </th>
                            <th>
                                <input matInput type="text" class="textbox-border-class" placeholder="State"
                                    name="search-State" />
                            </th>
                            <th>
                                <input matInput type="text" class="textbox-border-class" placeholder="City"
                                    name="search-City" />
                            </th>
                            <th>
                                <input matInput type="text" class="textbox-border-class" placeholder="Postal Code"
                                    name="search-PostalCode" />
                            </th>
                        </tr>
                    </tfoot>
                </table>
            </div>
        </div>
    </div>
<!-- </div> -->