import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
// import { defaultGuid } from 'projects/admin/src/app/document-perviewer/document-perviewer.component';
import { NumberValidators } from 'projects/shared/src/app/validations/number-validator';
import { TextValidators } from 'projects/shared/src/app/validations/text-validator';
import { forkJoin, Subscription } from 'rxjs';
import Swal from 'sweetalert2';
import {
  OrderDropdowns,
  PatientDropdowns,
} from '../dropdown-proxy/dropdown-management/dropdowns';
import {
  ChartDTO,
  OrderMasterDropdownDTO,
  PayablePercentDTO,
  PlanTypeDTO,
  TypeOfPlanDTO,
  YearTypeDTO,
} from '../dropdown-proxy/dropdown-management/dropdowns/dto/order';
import {
  PatientMasterDropdownDTO,
  PayorLevelDTO,
} from '../dropdown-proxy/dropdown-management/dropdowns/dto/patient';
import {
  OrderMasterDropdownService,
  PatientMasterDropdownService,
} from '../dropdown-proxy/dropdown-management/dropdowns/master-dropdown-service';
import {
  PatientPersonalDTO,
  PatientPolicyDTO,
  CreateUpdatePatientVerificationDTO,
} from '../patient-proxy/patient-optimization/dto';
import { PatientPersonalService } from '../patient-proxy/patient-optimization/patient-personal.service';
import { PatientPolicyService } from '../patient-proxy/patient-optimization/patient-policy.service';
import { PatientVerificationService } from '../patient-proxy/patient-optimization/patient-verification.service';
import {
  InsuranceType,
  VerifyType,
  verifyTypeOptions,
} from '../patient-proxy/patient/enumeration-data';
import { VerificationStatus } from '../patient-proxy/patient/enumeration-data/verification-status.enum';
import { ToastrService } from 'ngx-toastr';

import { drpPolicesDTO } from '../patient-verification/patient-verification.component';
import { PatientAddPolicyTabComponent } from '../patient-add-policy-tab/patient-add-policy-tab.component';
import { CommunicationService } from 'projects/shared/src/app/services/communication.service';
import { defaultGuid } from 'projects/shared/src/app/enums/allenums.enum';
// import { PatientManualVerificationTabComponent } from '../patient-manual-verification-tab/patient-manual-verification-tab.component';

@Component({
  selector: 'app-patient-manual-verification-view',
  templateUrl: './patient-manual-verification-view.component.html',
  styleUrls: ['./patient-manual-verification-view.component.scss']
})
export class PatientManualVerificationViewComponent implements OnInit {

  BirthdateMaxDate: Date;
  BirthdateMinDate: Date;
  patientId: string = defaultGuid;
  policyId: string = defaultGuid;
  verificationId: string = defaultGuid;
  isViewVerification:boolean=false;
  verificationForm: FormGroup;
  defaultPatientId: string = '';

  plan: string = '';
  payorPlan: string = '';
  verfnAMethod: string = '';
  notesText = '';
  type: string = '';
  typeOfPlan: string = '';
  payablePercentType: string = '';
  payablePercentType1: string = '';
  payablePercentType2: string = '';
  drpPayorLevel: string;
  drpPayorName: string;
  drpPolicyNo: string;
  drpPhoneNo: string;
  verifiedMethod: string;
  isShowSpinner: boolean = false;
  isPatientLockedByCurrentUser: boolean = true;
  payorLevel: PayorLevelDTO[];
  drpChart: ChartDTO[] = [];
  drpPlan: TypeOfPlanDTO[] = [];
  drpPayable: PayablePercentDTO[] = [];
  drpPolices: drpPolicesDTO[] = [];
  drpPlanType: PlanTypeDTO[] = [];
  drpYearType: YearTypeDTO[] = [];
  drpPolicies: PatientPolicyDTO[] = [];
  subscription$: Subscription[] = [];
  userName: string = '';
  documentId: string = null;
  selectedNode:string = null;
  isGroupData: boolean = false;

  constructor(
    // private dashboardFaxTableservice: DashboardFaxTableComponent,
    private formBuilder: FormBuilder,
    private textValidators: TextValidators,
    private numberValidators: NumberValidators,
    private patientDropdownService: PatientMasterDropdownService,
    private orderDropdownService: OrderMasterDropdownService,
    // public manualVerificationDialog: MatDialogRef<PatientManualVerificationTabComponent>,
    private patientPersonalService: PatientPersonalService,
    private patientPolicyService: PatientPolicyService,
    private patientVerificationService: PatientVerificationService,
    // public doVerificationModel: MatDialogRef<PatientAddPolicyTabComponent>,
    private toastr: ToastrService,


    @Inject(MAT_DIALOG_DATA)
    public data: {
      patientId: string;
      verificationId: string;
      policyId: string;
      savehidden?: boolean;
      documentId?:string;
      selectedNode?:string;
      isViewVerification?:boolean;
    } = {
      patientId: defaultGuid,
      verificationId: defaultGuid,
      policyId: defaultGuid,
      savehidden: false,
      documentId:null,
      selectedNode:'',
      isViewVerification:false
    },
    // public dialogRef: MatDialogRef<PatientManualVerificationTabComponent>,
    // @Inject(MAT_DIALOG_DATA)
    // public data1: any,

    private communicationService: CommunicationService


  ) {}

  ngOnInit(): void {
    this.documentId=this.data?.documentId ?? null;
    this.patientId = this.data?.patientId ?? defaultGuid;
    this.policyId = this.data?.policyId ?? defaultGuid;
    this.verificationId = this.data?.verificationId ?? defaultGuid;
    this.isViewVerification = this.data?.isViewVerification ?? false;

    this.createVerificationForm()
    this.patientVerificationService
    .get(this.data.verificationId)
    .subscribe(
      (response) => {

        this.verificationForm.patchValue({
          txtCommentsNotes: response?.commentsOrNotes,
          txtPlan: response?.plan,
          txtPlanName: response?.planName,
          txtPolicy: response?.policy,
            txtGroup: response?.groupNo,
            txtEffectiveDate: response?.effectiveDate,
            txtType: response?.typeOfPolicy,
            txtFascalYearStart: response?.fascalYearStart,
            txtEnd: response?.fascalYearEnd,
            drpTypeOfPlan: response?.typeOfPlan,
            chkIndividual: response?.isIndividual,
            chkOutOfNetwork: response?.isOutOfNetwork,
            chkFamily: response?.isFamily,

            txtDeductibleIndividual: response?.deductible,
            txtDeductibleFamily: response?.familyDeductible,
            txtDeductibleOutOfNetwork:
            response?.oonDeductible,
            txtDeductibleMetIndividual: response?.deductibleMet,
            txtDeductibleMetFamily: response?.familyDeductibleMet,
            txtDeductibleMetOutOfNetwork:
            response?.oonDeductibleMet,
            drpPayableIndividual: response?.payableAt,
            drpPayableOutOfNetwork: response?.oonPayableAt,
            drpPayableFamily: response?.familyPayableAt,
            txtExpenseIndividual: response?.outOfPocket,
            txtExpenseOutOfNetwork:
            response?.oonOutOfPocket,
            txtExpenseFamily: response?.familyOutOfPocket,
            txtExpenseMetIndividual:
            response?.outOfPocketMet,
            txtExpenseMetOutOfNetwork:
            response?.oonOutOfPocketMet,
            txtExpenseMetFamily: response?.familyOutOfPocketMet,

            chkExpenseIndividual:
            response?.doesOutOfPocketIncludeDed,
            chkExpenseOutOfNetwork:
            response?.doesOonOutOfPocketIncludeDed,
            chkExpenseFamily: response?.doesFamilyOutOfPocketIncludeDed,
            chkPrecertRequired: response?.isPrecertRequired,
            txtPhoneCall: response?.phoneNumber,
            chkReferralRequired: response?.referralRequired,
            txtPcp: response?.pcp,
            chkPredetermination: response?.predetermination,
            txtPreNotes: response?.predeterminationNotes,
            txtLifetimeMax: response?.lifetimeMaxBenefit,
            txtLifetimeMaxMet: response?.lifetimeBenefitUsed,
            chkDmePartsCovered: response?.dmeCoverage,
            chkDmeReplacementUnit: response?.dmeReplacement,
            txtDmeAnnualLimit: response?.dmeLimitPerYear,
            txtDmeLimitMet: response?.dmeLimitAmount,
            txtRequestedBy: response?.requestedBy,
            txtPrimaryPayor: response?.primaryPayor,
            txtContact: response?.contact,
            txtCallTrackingNumber: response?.callTrackingNumber,
            txtPolicyHolderName: response?.policyHolderName,
            txtPolicyHolderDob: response?.policyHolderDob,
            chkNetwork: response?.isNetwork,
            txtClaimAddress: response?.claimAddress,
            txtPlanCob: response?.planCob,
            txtCoverageCriteria: response?.coverageCriteria,
            txtQtyLimits: response?.qtyLimits,
            txtFrequency: response?.frequency,
            txtRent: response?.rent,
            txtPaRequired: response?.paRequired,
            txtCodesRequirePa: response?.codesRequirePa,
            txtSameOrSimilarInfo: response?.sameOrSimilarInfo,
            txtSpokeWith: response?.spokeWith,
            drpVerifiedBy: response?.verifiedBy,
            txtDateVerified: response?.verifiedOn,
            drpPreparedBy: response?.approvedBy,
            txtPreparedOn: response?.approvedOn,
            rbtnVerificationStatus: response?.verificationStatus,
            txtDeo: response?.deo,
            txtcommentsToTech: response?.commentToTech,
            txtVerfnAMethod: response?.vMethod,
            // txtDeductibleRemainingFamily:
            // response?.deductibleRemaining?.family,
            // txtDeductibleRemainingOutOfNetwork:
            // response?.deductibleRemaining?.outOfNetwork,
            // txtDeductibleRemainingIndividual:
            // response?.deductibleRemaining?.individual,
            // txtRemainingIndividual:
            // response?.outOfPocketRemaining?.individual,
            // txtRemainingFamily: response?.outOfPocketRemaining?.family,
            // txtRemainingOutOfNetwork:
            // response?.outOfPocketRemaining?.outOfNetwork,
            txtCoPayment: response.coPayment,
            txtCoInsurance: response.coInsurance,
            chkActive: response?.isActive,
             chkMedicare: response?.medicareCMNonFile,
            chkPatient: response?.isPatientHomeHealthEpisode,
            chkFamilyDeductible: response?.familyDeductCoversIndividual,
            txtCmnCode: response?.cmnCode,

        });
      })

      const orderDropdownInput: OrderDropdowns[] = [
        OrderDropdowns.Charts,
        OrderDropdowns.TypeOfPlans,
        OrderDropdowns.PlanTypes,
        OrderDropdowns.PayablePercents,
        OrderDropdowns.YearTypes,
      ];
      const orderDropdown =
        this.orderDropdownService.getOrderDropdownsByInput(orderDropdownInput).subscribe(
          (response) => {


            this.drpChart = response?.charts;
        this.drpPlan = response?.typeOfPlans;
        this.drpPayable = response?.payablePercents;
        this.drpPlanType = response?.planTypes;
        this.drpYearType = response?.yearTypes;

          });



      const patientDetails = this.patientPersonalService.get(this.patientId).subscribe(
        (response) => {

          this.verificationForm.patchValue({
            txtName: response?.fullName,
            txtDateOfBirth: response?.dateOfBirth,
            txtPlanPhone: response?.homePhoneNo,



            //






          });
        });;



      // this.verificationForm.patchValue({
      //   txtName: patientDetails?.fullName,
      //   txtDateOfBirth: patientDetails?.dateOfBirth,
      // });

  }

  createVerificationForm() {
    const currentDate = new Date().toISOString();
    this.verificationForm = this.formBuilder.group({
      txtName: new FormControl('', [this.textValidators.isTextCheck]),
      txtDateOfBirth: new FormControl('', [Validators.required]),
      txtCommentsNotes: new FormControl(''),
      txtPlan: new FormControl('', Validators.required),
      txtPlanName: new FormControl('', [Validators.required]),
      txtPlanPhone: new FormControl('', [this.numberValidators.isNumberCheck]),
      txtPolicy: new FormControl('', [Validators.required]),
      txtGroup: new FormControl(''),
      txtEffectiveDate: new FormControl('', [Validators.required]),
      txtType: new FormControl(null, [Validators.required]),
      txtFascalYearStart: new FormControl(''),
      txtEnd: new FormControl(''),
      chkMedicare: new FormControl(''),
      chkPatient: new FormControl(''),
      chkFamily: new FormControl(''),
      txtCmnCode: new FormControl('', [this.textValidators.isTextCheck]),
      drpTypeOfPlan: new FormControl(null, [Validators.required]),
      chkIndividual: new FormControl(''),
      chkOutOfNetwork: new FormControl(''),
      chkFamilyDeductible: new FormControl(''),
      txtDeductibleIndividual: new FormControl(''),
      txtDeductibleOutOfNetwork: new FormControl(''),
      txtDeductibleFamily: new FormControl(''),
      txtDeductibleMetIndividual: new FormControl(''),
      txtDeductibleMetOutOfNetwork: new FormControl(''),
      txtDeductibleMetFamily: new FormControl(''),
      drpPayableIndividual: new FormControl(null),
      drpPayableOutOfNetwork: new FormControl(null),
      drpPayableFamily: new FormControl(null),
      txtExpenseIndividual: new FormControl(''),
      txtExpenseOutOfNetwork: new FormControl(''),
      txtExpenseFamily: new FormControl(''),
      txtExpenseMetIndividual: new FormControl(''),
      txtExpenseMetOutOfNetwork: new FormControl(''),
      txtExpenseMetFamily: new FormControl(''),
      chkExpenseIndividual: new FormControl(''),
      chkExpenseOutOfNetwork: new FormControl(''),
      chkExpenseFamily: new FormControl(''),
      chkPrecertRequired: [true],
      txtPhoneCall: new FormControl('', [this.numberValidators.isNumberCheck]),
      chkReferralRequired: new FormControl(''),
      txtPcp: new FormControl('', [this.textValidators.isTextCheck]),
      chkPredetermination: new FormControl(''),
      txtPreNotes: new FormControl(''),
      txtLifetimeMax: new FormControl(''),
      txtLifetimeMaxMet: new FormControl(''),
      chkDmePartsCovered: new FormControl(''),
      chkDmeReplacementUnit: new FormControl(''),
      txtDmeAnnualLimit: new FormControl(''),
      txtDeductibleRemainingFamily: new FormControl(''),
      txtDeductibleRemainingIndividual: new FormControl(''),
      txtDeductibleRemainingOutOfNetwork: new FormControl(''),
      txtRemainingIndividual: new FormControl(''),
      txtRemainingFamily: new FormControl(''),
      txtRemainingOutOfNetwork: new FormControl(''),
      txtCoPayment: new FormControl(''),
      txtCoInsurance: new FormControl(''),
      txtDmeLimitMet: new FormControl(''),
      txtRequestedBy: new FormControl('', [this.textValidators.isTextCheck]),
      txtPrimaryPayor: new FormControl('', [this.textValidators.isTextCheck]),
      txtContact: new FormControl('', [this.numberValidators.isNumberCheck]),
      txtCallTrackingNumber: new FormControl(''),
      txtPolicyHolderName: new FormControl('', [
        this.textValidators.isTextCheck,
      ]),
      txtPolicyHolderDob: new FormControl(''),
      chkNetwork: new FormControl(''),
      txtClaimAddress: new FormControl(''),
      txtPlanCob: new FormControl(''),
      txtCoverageCriteria: new FormControl('', [
        this.textValidators.isTextCheck,
      ]),
      txtQtyLimits: new FormControl('', [this.textValidators.isTextCheck]),
      txtFrequency: new FormControl('', [this.textValidators.isTextCheck]),
      txtRent: new FormControl('', [this.textValidators.isTextCheck]),
      txtPaRequired: new FormControl('', [this.textValidators.isTextCheck]),
      txtCodesRequirePa: new FormControl('', [this.textValidators.isTextCheck]),
      txtSameOrSimilarInfo: new FormControl('', [
        this.textValidators.isTextCheck,
      ]),
      txtSpokeWith: new FormControl('', [this.textValidators.isTextCheck]),
      drpVerifiedBy: new FormControl(this.userName, [
        // this.textValidators.isTextCheck,
        Validators.required,
      ]),
      txtDateVerified: new FormControl(currentDate, [Validators.required]),
      drpPreparedBy: new FormControl('', [this.textValidators.isTextCheck]),
      txtPreparedOn: new FormControl(''),
      rbtnVerificationStatus: new FormControl('', [Validators.required]),
      txtDeo: new FormControl('', this.numberValidators.isNumberCheck),
      txtcommentsToTech: new FormControl(''),
      txtVerfnAMethod: new FormControl('Manual', [Validators.required]),
      chkActive: new FormControl(''),
    });
    this.BirthdateMaxDate = new Date();
    this.BirthdateMaxDate.setDate(this.BirthdateMaxDate.getDate() - 1);
    this.BirthdateMinDate = new Date();
    this.BirthdateMinDate.setFullYear(
      this.BirthdateMinDate.getFullYear() - 120
    );
  }

  updateCharacterCount() {
    // Ensure the text length does not exceed the maximum length
    if (this.notesText?.length > 5000) {
      this.notesText = this.notesText?.substr(0, 5000);
    }
  }

  onChange() {
    this.verificationForm.controls['txtGroup'].setValidators(
      Validators.required
    );
    this.verificationForm.controls['txtEnd'].setValidators(Validators.required);
    this.isGroupData = true;
  }

}
