import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Title } from '@angular/platform-browser';
import { ClaimSearchService } from 'projects/billing/src/app/billing-proxy/billing-management/posting';
import {
  SaleOrderSearchService,
  SaleorderService,
} from 'projects/order/src/app/order-proxy/order-management/order';
import { FollowUpReportDTO } from 'projects/order/src/app/order-proxy/order-management/order/dto/models';
import { DateValidator } from 'projects/shared/src/app/validations/date-validator';
import { Subscription } from 'rxjs';
import {
  debounceTime,
  filter,
  map,
  startWith,
  switchMap,
  tap,
} from 'rxjs/operators';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-order-followup',
  templateUrl: './order-followup.component.html',
  styleUrls: ['./order-followup.component.scss'],
})
export class OrderFollowupComponent implements OnInit {
  orderFollowUpTable: FollowUpReportDTO[] = [];
  dataSource: MatTableDataSource<any> = new MatTableDataSource<any>([]);
  @ViewChild(MatSort, { static: false }) sort: MatSort = new MatSort();
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  public arrDisplayedColumns: string[] = [
    'saleOrderId',
    'patientId',
    'patientName',
    'nextDOSDate',
    'hcpcsCode',
    'insuranceName',
  ];
  isLoading: boolean = false;
  subscription$: Subscription[] = [];
  orderItemsFollowUpForm: FormGroup;
  constructor(
    private formbuilder: FormBuilder,
    private saleOrderService: SaleorderService,
    private dateValidator: DateValidator,
    private titleService: Title
  ) { }
  ngOnInit(): void {
    this.titleService.setTitle('Qsecure | Order Follow-Up');
    this.orderItemsFollowUpForm = this.formbuilder.group({
      saleOrderId: new FormControl(''),
      patientId: new FormControl(''),
      nextDOSDate: new FormControl('', this.dateValidator.dateVaidator),
      patientName: new FormControl(''),
      insuranceName: new FormControl(''),
      hcpcsCode: new FormControl(''),
    });
    this.getTableData();
  }

  //! get Order-Follow-Up-Reports and search
  getTableData() {
    const valueChanges = this.orderItemsFollowUpForm.valueChanges
      .pipe(
        startWith({
          saleOrderId: '',
          patientId: '',
          patientName: '',
          nextDOSDate: '',
          hcpcs: '',
          insuranceName: '',
        }),
        tap((x) => {
          this.isLoading = true;
          this.setTableData([]);
        }),
        filter((e) => e && this.orderItemsFollowUpForm?.valid),
        debounceTime(300),
        map((response) => {
          this.isLoading = true;

          const sValue = {
            saleOrderNumber: response?.saleOrderId?.trim()?.toLowerCase(),
            patientId: response?.patientId?.trim()?.toLowerCase(),
            DOSdate: response?.nextDOSDate?.trim()?.toLowerCase(),
            patientName: response?.patientName?.trim()?.toLowerCase(),
            insuranceName: response?.insuranceName?.trim()?.toLowerCase(),
            hcpcsCode: response?.hcpcsCode?.trim()?.toLowerCase(),
          };
          return sValue;
        }),
        switchMap((sValue) =>
          this.saleOrderService.orderFollowUpSearchBySSaleOrderNumberAndSPatientAndSPatientNameAndDtNextDOSAndSHcpcsAndSInsuranceName(
            sValue?.saleOrderNumber,
            sValue?.patientId,
            sValue?.patientName,
            sValue?.DOSdate,
            sValue?.hcpcsCode,
            sValue?.insuranceName
          )
        )
      )
      .subscribe(
        (response) => {
          this.setTableData(response);
          this.isLoading = false;
        },
        (err) => {
          this.isLoading = false;
          const data: HttpErrorResponse = err;
          Swal.fire({
            icon: 'info',
            text: data?.error?.error?.message,
          });
        }
      );
    this.subscription$?.push(valueChanges);
  }

  //Set Table Data
  setTableData(order: any[]) {
    this.orderFollowUpTable = order;
    this.dataSource = new MatTableDataSource(this.orderFollowUpTable);
    this.sort.disableClear = true;
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }
}


