<br />
<div class="card card-body">
  <mat-accordion>
    <!-- <button class="float-right btnsize" ngbNavItem="User" [disabled]="!clientId"  mat-button>
      <a ngbNavLink class="buttonColor">Back To User</a>
      <ng-template ngbNavContent>
        <app-user [clientId]="clientId"></app-user>
      </ng-template>
    </button><br /><br /><br /> -->
    <mat-expansion-panel [expanded]="isPanelOpenState" >
      <mat-expansion-panel-header>
        <mat-panel-title class="customThemeClass">
          <ng-container *ngIf="inviteUserId === ''; else elseTitleTemplate">
            <b>Invite New User</b>
          </ng-container>
          <ng-template #elseTitleTemplate>
            <b>Edit InvitedUser</b>
          </ng-template>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <form [formGroup]="inviteUsersForm">
        <div class="row">
          <mat-form-field class="col-3">
            <mat-label>Name <span class="asterisk">*</span></mat-label>
            <input autocomplete="new-txtName" matInput maxlength="40" type="text" formControlName="txtName" oninput="this.value = this.value.charAt(0).toUpperCase() + this.value.slice(1); this.value = this.value.replace('^[a-zA-Z0-9_-]+(?:\s+[a-zA-Z0-9_-]+)*$', '').replace(/(\..*?)\..*/g, '$1').replace(/^(.*\.\d\d).*$|(\..*)$/g, '$1$2');" />
            <mat-error *ngIf="
                inviteUsersForm?.get('txtName')?.touched &&
                inviteUsersForm?.get('txtName')?.errors?.required">
              Name is required!
            </mat-error>
            <mat-error *ngIf="
            inviteUsersForm?.get('txtName')?.touched &&
                inviteUsersForm?.get('txtName')?.errors?.sAlphabets">
              Name must be alphabets!
            </mat-error>
            <mat-error *ngIf="inviteUsersForm?.get('txtName')?.errors?.isUserNameExists">
              Name is already taken!
            </mat-error>
            <mat-error *ngIf="inviteUsersForm?.get('txtName')?.errors?.pattern?.requiredPattern">
              Name may only contain letters, numbers, periods, hyphens, or underscores.
            </mat-error>
          </mat-form-field>
          <mat-form-field class="col-3">
            <mat-label>Email Address <span class="asterisk">*</span> </mat-label>
            <input autocomplete="new-txtEmailAddress" matInput maxlength="40" type="text" formControlName="txtEmailAddress"
            pattern="[a-zA-Z0-9._!#$%&'()*+,-./:;<=>?@[\]^_`{|}~]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$"
              [textMask]="{ mask: emailMask }" />
            <mat-error *ngIf="
                inviteUsersForm?.get('txtEmailAddress')?.touched &&
                inviteUsersForm?.get('txtEmailAddress')?.errors?.pattern">
              Please Enter a Valid Email Address
            </mat-error>
            <mat-error *ngIf="
                inviteUsersForm?.get('txtEmailAddress')?.touched &&
                inviteUsersForm?.get('txtEmailAddress')?.errors?.required">
              Email Address is required!
            </mat-error>
            <mat-error *ngIf="inviteUsersForm?.get('txtEmailAddress')?.errors?.isEmailAddressExists">
              Email is already taken!
            </mat-error>
          </mat-form-field>
          <mat-form-field class="col-3">
            <mat-label>SurName </mat-label>
            <input autocomplete="new-txtSurName" matInput maxlength="40" type="text" formControlName="txtSurName" oninput="this.value = this.value.charAt(0).toUpperCase() + this.value.slice(1); this.value = this.value.replace('^[a-zA-Z0-9_-]+(?:\s+[a-zA-Z0-9_-]+)*$', '').replace(/(\..*?)\..*/g, '$1').replace(/^(.*\.\d\d).*$|(\..*)$/g, '$1$2');"/>
            <mat-error *ngIf="
                inviteUsersForm?.get('txtSurName')?.touched &&
                inviteUsersForm?.get('txtSurName')?.errors?.sAlphabets">
              Enter only Alphabets!
            </mat-error>
          </mat-form-field>
          <mat-form-field class="col-3">
            <mat-label>Select Role <span class="asterisk">*</span></mat-label>
            <mat-select formControlName="drpRoles" [(value)]="role">
              <mat-option *ngFor="let roles of drpRoles" [value]="roles.id">
                {{ roles.name }}
              </mat-option>
            </mat-select>
            <button mat-button (click)="clearRole()" (click)="role='';$event.stopPropagation()" *ngIf="role" matSuffix
              mat-icon-button>
              <mat-icon>close</mat-icon>
            </button>
            <mat-error *ngIf="
                inviteUsersForm?.get('drpRoles')?.touched &&
                inviteUsersForm?.get('drpRoles')?.errors?.required">
              Role is required!
            </mat-error>
          </mat-form-field>
          <mat-form-field class="col-3">
            <mat-label>Select Branch <span class="asterisk">*</span></mat-label>
            <mat-select formControlName="drpOrganization" [(value)]="branch">
              <mat-option *ngFor="let organizations of drpOrganization" [value]="organizations.id">
                {{ organizations.organizationUnitName }}
              </mat-option>
            </mat-select>
            <button mat-button (click)="clearBranch()" (click)="branch = ''; $event.stopPropagation()" *ngIf="branch"
              matSuffix mat-icon-button>
              <mat-icon>close</mat-icon>
            </button>
            <mat-error *ngIf="
                inviteUsersForm?.get('drpOrganization')?.touched &&
                inviteUsersForm?.get('drpOrganization')?.errors?.required">
              Branch is required!
            </mat-error>
          </mat-form-field>
          <mat-form-field class="col-3">
            <mat-label> Expire On <span class="asterisk">*</span></mat-label>
            <input autocomplete="new-txtExpireDatetime" matInput [ngxMatDatetimePicker]="picker" placeholder="Choose a date & time"
              formControlName="txtExpireDatetime" [min]="expiryMinimumDate"
              maxlength="10"
              onkeydown="this.value=this.value.replace(/^(\d\d)(\d)$/g,'$1/$2').replace(/^(\d\d\/\d\d)(\d+)$/g,'$1/$2').replace(/[^\d\/]/g,'')"/>
            <mat-datepicker-toggle matSuffix [for]="$any(picker)"></mat-datepicker-toggle>
            <ngx-mat-datetime-picker #picker [enableMeridian]="true"> </ngx-mat-datetime-picker>
            <mat-error *ngIf="
                inviteUsersForm?.get('txtExpireDatetime')?.touched &&
                inviteUsersForm?.get('txtExpireDatetime')?.errors?.required">
              <!-- inviteUsersForm?.get('txtExpireDatetime').dirty)"> -->
              ExpireOn is required!
            </mat-error>
            <mat-error *ngIf="
                inviteUsersForm?.get('txtExpireDatetime')?.touched &&
                inviteUsersForm?.get('txtExpireDatetime')?.errors">
              Enter Valid Date Time!
            </mat-error>

            <mat-error
              *ngIf=" expiryMinimumDate>=inviteUsersForm?.get('txtExpireDatetime')?.value && !(inviteUsersForm?.get('txtExpireDatetime')?.touched && inviteUsersForm?.get('txtExpireDatetime')?.errors?.required)">
              Enter Future Dates Only!
            </mat-error>
          </mat-form-field>
            <div class="col-4 mt-4 ml-3">
              <!-- <div class="text-lg-center"> -->
                <button *ngIf="isShownSaveButton" [disabled]="saveButtonHide ||inviteUsersForm.invalid" mat-button
                  class="buttonColor mb-2 mr-2" (click)="saveInviteUsers()">
                  Save
                  <mat-icon *ngIf='isShowSpinner'>
                    <mat-spinner class="spinner-border spinner-border-sm" diameter="20"> </mat-spinner>
                  </mat-icon>
                </button>
                <button *ngIf="isShownResetButton" mat-button class="resetclr mb-2 mr-2"
                  (click)="resetInviteUsers()">
                  Reset
                </button>
              </div>
            <!-- </div> -->
          </div>
      </form>
      <!-- <div class="row">
        <div class="col-sm-12">
          <div class="text-lg-center">
            <button *ngIf="isShownSaveButton" [disabled]="saveButtonHide ||inviteUsersForm.invalid" mat-button
              class="buttonColor mb-2 mr-2" (click)="saveInviteUsers()">
              Save
              <mat-icon *ngIf='isShowSpinner'>
                <mat-spinner class="spinner-border spinner-border-sm" diameter="20"> </mat-spinner>
              </mat-icon>
            </button>
            <button *ngIf="isShownResetButton" mat-button class="resetclr mb-2 mr-2"
              (click)="resetInviteUsers()">
              Reset
            </button>
          </div>
        </div>
      </div> -->

      <div class="row" *ngIf="saveButtonHide">
        <div class="col-sm-4">
        </div>
        <div class="col-sm-4">
          <div class="text-lg-center " *ngIf='isShowSpinner'>
            <div class="alert alert-success" role="alert">
              Please Wait,While Data is Saved!
            </div>
          </div>
        </div>
        <div class="col-sm-4">
        </div>
      </div>

    </mat-expansion-panel>
  </mat-accordion>
  <br>
  <div class="row" *ngIf="isShownInviteUsersTable">
     <div class="col-xl-12" > <!-- *ngIf="isinviteUsers == true" -->
      <div class="card">
        <div class="card-body">
          <mat-card-content>
            <form [formGroup]="inviteStatusForm">
              <div class="row">
                <mat-form-field class="col-3">
                  <mat-label>Invite Status</mat-label>
                  <mat-select formControlName="drpInviteStatus"
                    (selectionChange)="loadInviteUserTableBasedOnStatus($event)">
                    <mat-option *ngFor="let status of drpInvite" [value]="status.inviteStatus">
                      {{ status.inviteStatus }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
                <br>
                <div class="row" *ngIf="isLoading">
                  <div class="col-12">
                    <div class="d-flex justify-content-center" *ngIf="isLoading">
                      <img class="img-responsive pb-2 mr-1" src="assets/gifs/Infinity-loader.gif" />
                    </div>
                  </div>
                </div>
                <div class="col-12">
                  <!-- <app-table [strPageType]="strPageType" (deleteButton)="deleteInviteUsers($event)"
                    [isLoading]="isLoading" (viewButton)="ViewInviteUsers($event)"
                    (resendInviteButton)="resendInviteUsers($event)">
                  </app-table> -->
                  <app-invite-user-table [tableInviteUser]="inviteUsersData" *ngIf="!isLoading" (deleteButton)="deleteInviteUsers($event)" (viewButton)="ViewInviteUsers($event)" (resendInviteButton)="resendInviteUsers($event)"></app-invite-user-table>
                </div>
              </div>
            </form>
          </mat-card-content>
        </div>
      </div>
    </div>
  </div>
</div>
