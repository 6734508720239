<div class="container-fluid">


    <div class="d-flex align-items-center justify-content-between">
        <h4 class="mt-3 mb-0 font-size-18">
            <b class="header-text">{{patientName ? patientName +'-' + (chartId ? chartId : '') :chartId ? chartId : '' }}</b>
          </h4>
        <!-- <h3 class="font-weight-bolder mb-0">Lee Glender - 96512345AS </h3> -->
    </div>


    <div class="card card-body mt-2">
    <div class="col-12">
      <form [formGroup]="helloSignForm">
        <div class="row">
          <div class="col-1_5">
            <mat-form-field class="col-12">
              <mat-label>Patient Email<span class="asterisk">*</span></mat-label>
              <input maxlength="100" formControlName="txtEmail" matInput placeholder="Patient Email" pattern="[a-zA-Z0-9._!#$%&'()*+,-./:;<=>?@[\]^_`{|}~]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$"
              [textMask]="{ mask: emailMask }"/>
             </mat-form-field>
             <!-- Document Link Expires On -->
             <mat-form-field class="col-12 dd-text">
            <mat-label> Document Link Expires On <span class="asterisk">*</span></mat-label>
            <input formControlName="txtDocLinkExpireOn" matInput maxlength="40" type="text" [disabled]='isView'
                placeholder="(MM/DD/YYYY)" [matDatepicker]="txtDocLinkExpireOn" [min]="todayDate"
                (keyup)="receivedDate($event.target.value)" (keydown)="addSlashToDate($event)"
                (dateChange)="receivedDate($event.value)"/>
            <mat-datepicker-toggle matSuffix [for]="txtDocLinkExpireOn">
            </mat-datepicker-toggle>
            <mat-datepicker #txtDocLinkExpireOn> </mat-datepicker>
            <!-- <mat-error *ngIf="
            helloSignForm?.get('txtDocLinkExpireOn')?.touched &&
            helloSignForm?.get('txtDocLinkExpireOn')?.value.invalid
          ">
            Enter Valid Date
          </mat-error> -->
          <mat-error *ngIf="
            helloSignForm?.get('txtDocLinkExpireOn')?.value < todayDate
          ">
            Invalid and Past Date not allowed
          </mat-error>
             </mat-form-field>
          </div>
          <div class="col-10 card card-body">
            <div class="row">
            <div class="col-2 ">
              <b>Ticket No</b>
              <h1>{{helloSignForm.get("txtTicketNo").value}}</h1>
              <!-- <input maxlength="100" formControlName="txtTicketNo" matInput placeholder="Ticket No" readonly="true"/> -->
            </div>
            <!-- order Date -->
            <div class="col-2 dd-text vl" >
             <b>Order Date </b>
             <h1>{{getFormattedDate(helloSignForm.get("txtOrderDate").value)}}</h1>
            </div>
            <!-- CSR -->
            <div class="col-2 vl">
              <b>CSR</b>
              <h1>{{helloSignForm.get("txtCsr").value}}</h1>
            </div>
               <!-- Created By -->
               <div class="col-2 vl">
                <b>Created By</b> <h1>{{helloSignForm.get("txtCreatedBy").value}}</h1>
               </div>
                <!-- Document Send On -->
                <div class="col-2_5 vl dd-text" >
                  <b> Document Sent On </b>
                   <h1>{{getFormattedDate(helloSignForm.get("txtDocSentOn").value)}}</h1>
              </div>

              <!-- Document Received On -->
              <div class="col-2_5 vl dd-text" >
                  <b> Document Received On </b>
                  <h1>{{getFormattedDate(helloSignForm.get("txtDocRecivedOn").value)}}</h1>
              </div>
                <!-- Expired Status -->
                <div class="col-2 vl" >
                  <b>Expired Status</b>
                  <h1>{{helloSignForm.get("txtexpiredStatus").value}}</h1>
                </div>

               <!-- Status -->
              <div class="col-1_75 vl">
                  <b>Status</b>
                  <h1>{{helloSignForm.get("txtStatus").value}}</h1>
                 </div>
              </div>
          </div>
        </div>
      </form>
        <div class="col-12">
            <div class="row">

                    <!-- <form [formGroup]="helloUploadForm"> -->
                    <div class="drop-area col-4 mt-1">
                        <span class="asterisk text-left" *ngIf="showErrorForFileType">
                            {{ "Select Only PDF`s" }}
                          </span>
                          <span class="asterisk" *ngIf="showErrorBasedOnMaxSize">
                            {{ 'Max Total file`s size of 20MB or Less' }}
                          </span>

                        <div class="file-upload-container " (drop)="onDrop(file.files,$event)"
                        (dragover)="onDragOver($event)"
                        (dragleave)="onDragLeave($event)">

                            <img class="img-responsive pr-1 mb-1 cursor" src="assets/Claim Page Icons/browse.png" alt=""
                                height="17">
                            <div class="drop-area"  >
                                Drag and drop files here
                            </div>
                            <div class="name-label-container">
                                <hr class="hr-divider">
                                <h2 class="name-label">Or</h2>
                                <hr class="hr-divider">
                            </div>
                            <div class="drop-area">
                                Have file on your computer
                            </div>
                            <!-- <input type="file" #fileInput (change)="onFileSelect($event)"> -->
                            <input
                            #file
                            type="file"

                            (click)="$event.target.value = null"
                            (change)="onFileSelected(file.files)"
                            multiple="multiple"
                            accept="application/pdf"
                            class="d-none"
                            [disabled]="isView"
                          />
                            <button class="file-upload-button cursor mb-2" [disabled]='isView || isSignedStatus '  mat-raised-button  (click)="file.click()">Browse <mat-icon
                                    class="brow-align">cloud_upload</mat-icon></button>
                        </div>
                    </div>
                <!-- </form> -->
                    <div class="card card-body col-8 mt-1">

                        <div >
                            <div class="row">
                              <div class="col-6 dd-text">
                                <label class="bold">Sorted Documents</label>
                              </div>
                              <div class="col-6 text-right dd-text">
                                <div class="row justify-content-end">
                                  <div><label class="text-right bold" >Template Download </label></div>
                                  <span>
                                    <mat-icon
                                  style="cursor: pointer !important"
                                  [ngClass]="{ 'disabled' :templeteList?.length == 0  }"
                                  [matMenuTriggerFor]="menu"
                                  >more_vert</mat-icon >
                                  </span>
                                </div>

                                <mat-menu  #menu="matMenu" >

                                        <button  mat-menu-item *ngFor="let data of templeteList"  (click)="downloadTemplatePdf(data)"
                                      > {{data.templateName}}</button>




                                  </mat-menu>
                              </div>
                            </div>
                          </div>
                        <!-- <div class="row"><label><b>Sorted Documents</b></label>
                            <label class="text-right" ><b>Template Download</b></label></div> -->
                        <hr>
                        <!-- <form [formGroup]="helloSignSortedForm"> -->
                          <div class="row" *ngIf="!noData">
                            <div class="col-lg-3 " >
                              <mat-checkbox [(ngModel)]="chkAll" [disabled]="isView ||isSignedStatus " (change)="selectOrDeselectAll($event)" > All({{allCount}})
                              </mat-checkbox>

                            </div>
                            <!-- <button (click)="array(documentTypesArray)"> Array</button>
                            <div class="col-lg-3 " *ngFor="let doc of documentTypesArray">
                              <mat-checkbox formControlName="chkSorted" [ngModel]="doc.isTrue" [disabled]="isView || isSignedStatus" (change)="selectCheckBox(doc, $event)">
                                {{ doc.documentType }} ({{ doc.pageCount }})
                              </mat-checkbox>
                            </div> -->
                            <!-- <div  *ngIf="test" > -->
                              <div class="col-lg-3 " *ngFor="let doc of documentTypesArray">
                                <mat-checkbox  [(ngModel)]="doc.isTrue" [disabled]="isView || isSignedStatus" (change)="selectCheckBox(doc,$event)"  > {{doc.documentType}}({{doc.pageCount}})
                                </mat-checkbox>
                              </div>

                            <!-- </div> -->
                          </div>
                          <div class="d-flex justify-content-center" *ngIf="!isLoaded">
                            <img class="img-responsive pb-2 mr-1" src="assets/gifs/Infinity-loader.gif" />
                        </div>
                          <!-- <div class="row" *ngIf="noData===true">-->
                           <div class="text-center" *ngIf="noData===true &&isLoaded===true">
                              <h2 class=" m-3 text-center" >Sorted Documents not available</h2>
                             </div>
                         <!-- </div> -->
                        <!-- <div class="row">
                            <mat-checkbox formControlName="chkAll" class="col-4">All({{allCount}})</mat-checkbox>
                            <mat-checkbox formControlName="chkCorres" class="col-4">Correspondence ({{corresCount}})</mat-checkbox>
                            <mat-checkbox formControlName="chkFace" class="col-4">Face to Face ({{facecount}})</mat-checkbox>
                            <mat-checkbox formControlName="chkDemo" class="col-4">Demographics ({{demoCount}})</mat-checkbox>
                            <mat-checkbox formControlName="chkSleep" class="col-4">Sleep Study Report ({{sleepCount}})</mat-checkbox>
                            <mat-checkbox formControlName="chkAuth" class="col-4">Compliance Report({{authcount}})</mat-checkbox>
                            <mat-checkbox formControlName="chkRx" class="col-4">RX ({{rxCount}})</mat-checkbox>
                        </div> -->
                        <!-- </form> -->
                        <div class="text-right">
                          <a
                          [routerLink]="
                            '/view_document_tiff/' + docId + '/' + true
                          "
                          target="_blank"
                          class="preview-link"

                        >
                          <button
                          *ngIf="noData!==true" mat-button class="buttonColor mr-2"
                          ><mat-icon>visibility</mat-icon>
                          Preview Document
                          </button>
                        <!-- </a>
                          <a [routerLink]="
                          '/preview-Document'
                        "
                         target="_blank">
                         <button *ngIf="noData!==true" mat-button class="buttonColor mr-2"> <mat-icon>visibility</mat-icon>
                            Preview Document
                            </button></a> -->

                          </a>
                        </div>


                    </div>


            <div class="col-12 card card-body mt-1">
                <div class="row">
                    <div class="col-6">
                        <h2><b>Uploaded Documents</b></h2>
                    </div>
                    <div class="text-right col-6">
                        <button
                    *ngIf="isSignedStatus == true" (click)="viewSignedDocument()" mat-button class="buttonColor mr-2"
                    ><mat-icon>visibility</mat-icon>
                    Preview Signed Document
                    </button>
                    </div>
                </div>



                <!-- <div class="card card-body "> -->
                    <div class="d-flex justify-content-center" *ngIf="!isLoaded">
                        <img class="img-responsive pb-2 mr-1" src="assets/gifs/Infinity-loader.gif" />
                    </div>

                    <div *ngIf="isLoaded" class="table table-responsive" >
                        <table datatable [dtOptions]="dtOptions" class="row-border hover w-100 display" id="myTable">
                            <thead>
                                <tr>
                                    <th [matTooltip]="'File Name'">File Name </th>
                                    <th [matTooltip]="'Page Count'" class="text-right">Page Count</th>
                                    <th [matTooltip]="'File Size'">File Size</th>
                                    <th [matTooltip]="'Option'" class="text-center">Option</th>


                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let data of documentTypesArrayList">

                                    <td [matTooltip]="data?.documentType || '-'">{{data.documentType||'-'}} </td>
                                    <td [matTooltip]="data?.pageCount || '-'" class="text-right">{{data.pageCount||'-'}}</td>
                                    <td [matTooltip]="data?.fileLength || '-'">{{data.fileLength||'-'}}</td>
                                    <td  class="text-center">
                                       <!-- <mat-icon class="buttonColor">visibility</mat-icon> -->
                                       <a  [class.disabled]='isView' [matTooltip]="'Click to Preview'" (click)="viewSortedDoc(data)"
                                       >
                                       <img
                                       class="img-responsive pr-1 mb-1 cursor"
                                       src="assets/Claim Page Icons/view.png"
                                       alt=""
                                       height="17"
                                     /></a>
                                     <a   [class.disabled]='isView'[matTooltip]="'Click to Download'" (click)="downloadSortedDoc(data)"
                                     >
                                     <img
                                     class="img-responsive pr-1 mb-1 cursor"
                                     src="assets/Claim Page Icons/download.png"
                                     alt=""
                                     height="17"
                                   /></a>
                                   <a   [class.disabled]='isView || isSignedStatus '[matTooltip]="'Click to Remove'" (click)="removedocumentFiles(data)"
                                     >
                                     <img
                                     class="img-responsive pr-1 mb-1 cursor"
                                     src="assets/Claim Page Icons/remove.png"
                                     alt=""
                                     height="17"
                                   /></a>
                                     </td>

                                </tr>
                            </tbody>
                        </table>
                    </div>
                <!-- </div> -->
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-3 mt-2 ">
            <button mat-button (click)="openTimeLinePopUp()" class="buttonColor mr-2"><mat-icon>history</mat-icon>
                History
            </button>
        </div>


                <div class="col-9 mt-2 text-right">
                    <button mat-button class="buttonColor mr-2" [disabled]='isView ||isLoadRevert|| status==="New"||status==="Cancelled"||status==="Reverted"||
                    status==="new"||status==="cancelled"||status==="reverted"||signatureId===defaultGuid||signatureId==="undefined"' mat-raised-button (click)="revert()"><img id="revert" class="img-responsive pr-1 mb-0 cursor"
                        src="assets/Claim Page Icons/revert.png" alt="" height="17" />
                        Revert
                    </button>
                    <button mat-button class="buttonColor mr-2" [disabled]='isView   || status==="New" || isSignedStatus || documentTypesArrayList == 0' mat-raised-button (click)="reSendMailHelloSign()" ><img id="resend" class="img-responsive pr-1 mb-0 cursor"
                        src="assets/Claim Page Icons/resend.png" alt="" height="17" />
                        Resend Mail
                    </button>
                    <button mat-button class="resetclr buttonColor mr-2" [disabled]='isView || documentTypesArrayList.length===0 ||  isSignedStatus ' (click)="canceledSelectedDocuments()"><mat-icon >close</mat-icon> </button>
                    <button mat-button class="buttonColor" (click)="saveHelloSign()"[disabled]='isView||helloSignForm.invalid||(status!=="new"&&status!=="New" ) || documentTypesArrayList == 0 '><mat-icon>send</mat-icon>

                </button>
                    <!-- <button mat-button class="buttonColor"><img class="img-responsive pr-1 mb-0 cursor"
                            src="assets/Requisition Icons/approved.png" alt="" height="17" />

                    </button> -->
                </div>
    </div>


    </div>
    </div>
    <app-loader-component *ngIf="isOrderPageLoading"></app-loader-component>
