<mat-card class="ml-3">
  <mat-card-content>
    <div class="row">
      <div class="col-lg-12">
        <div class="text-center">
          <span style="font-size: larger;"><b class="colored-heading">SaleOrder ID</b></span>
          <h3 style="margin: 0 0 4px;"><b>{{ lblDefaultSaleOrderId }}</b></h3>
        </div>
        <div class="text-center">
          <span style="font-size: larger;"><b class="colored-heading">Patient ID</b></span>
          <h3 style="margin: 0 0 4px;"><b>{{ lblDefaultPatientId }}</b></h3>
        </div>
        <div class="text-center">
          <span style="font-size: larger;"><b class="colored-heading">Patient Name</b></span>
          <h3 style="margin: 0 0 4px;"><b>{{ lblPatientName }}</b></h3>
        </div>
      </div>
    </div>
  </mat-card-content>
</mat-card>

<mat-card class="mt-3 ml-3">
  <mat-card-content>
    <div class="row">
      <div class="col-lg-12">
        <h2 class="colored-heading"><b>Demographics</b></h2>
        <div class="text-center">
          <p class="mb-0 text-center">DOB</p><br>
          <h5 class="btm text-center">{{ lblPatientDOB }}</h5>
        </div>
      </div>
    </div>
    <mat-divider class="bg mb-2 mt-2"> </mat-divider>
    <div class="row mt-3">
      <div class="col-lg-12">
        <h2 class="colored-heading"><b>Billing Details</b></h2>
        <div class="text-center">
          <p class="mb-0 text-center">Billed Amount</p><br>
          <h5 class="btm text-center">{{lblBilledAmount}}</h5>
        </div>
        <div class="table-responsive mt-2">
          <table class="table table-centered">
            <tbody>
              <tr>
                <td>
                  <p class="mb-0 text-center">Received Amount</p><br>
                  <h4 class="btm  text-center">{{lblReceivedAmount}}</h4>
                </td>
              </tr>
              <tr>
                <td>
                  <p class="mb-0 text-center">Pending Amount</p><br>
                  <h4 class="btm  text-center">{{lblPendingAmount}}</h4>
                </td>
              </tr>
              <tr>
                <td>
                  <p class="mb-0 text-center">Billing Status</p><br>
                  <h5 class="btm text-center">{{lblBillingStatus}}</h5>
                </td>
              </tr>
              <tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </mat-card-content>
</mat-card>
<mat-card class="mt-3 ml-3">
  <mat-card-content>
    <div class="row">
      <div class="col-lg-12">
        <h2 class="colored-heading"><b>Age Wise Payment Details</b></h2>
        <div class="text-center mt-2">
          <div class="row">
            <div class="col-lg-6">
              <p class="mb-0 ml-3"><b>Days</b></p>
            </div>
            <div class="col-lg-6">
              <p class="mb-0"><b>Amount</b></p>
            </div>
          </div>
        </div>
        <div class="table-responsive mt-2">
          <table class="table table-centered" style="margin-top:0px;">
            <tbody class="text-center">
              <tr>
                <td>
                  <p class="mb-0 text-center">30</p><br>
                </td>
                <td>
                  <p class="mb-0 text-center">{{lblthirtyDayPaidAmnt===""?0:lblthirtyDayPaidAmnt }}</p><br>
                </td>
              </tr>
              <tr>
                <td>
                  <p class="mb-0 text-center">60</p><br>
                </td>
                <td>
                  <p class="mb-0 text-center">{{lblsixtyDayPaidAmnt===""?0:lblsixtyDayPaidAmnt }}</p><br>

                </td>
              </tr>
              <tr>
                <td>
                  <p class="mb-0 text-center">90</p><br>
                </td>
                <td>
                  <p class="mb-0 text-center">{{lblninetyDayPaidAmnt===""?0:lblninetyDayPaidAmnt }}</p><br>

                </td>
              </tr>
              <tr>
                <td>
                  <p class="mb-0 text-center"> 90</p><br>
                </td>
                <td>
                  <p class="mb-0 text-center">{{lblAboveNinetyDayPaidAmnt===""?0:lblAboveNinetyDayPaidAmnt }}</p><br>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </mat-card-content>
</mat-card>