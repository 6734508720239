import { HttpErrorResponse } from '@angular/common/http';
import {
  Component,
  ElementRef,
  EventEmitter,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import {
  MatDialog,
  MatDialogConfig,
} from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { InboundDocumentService } from 'projects/admin/src/app/admin-proxy/platform-app-management/rcm/platform-management/inbound';
// import { defaultGuid } from 'projects/admin/src/app/document-perviewer/document-perviewer.component';
import { Observable, Subscription } from 'rxjs';
import Swal from 'sweetalert2';
import { MedicarechecklistService } from '../../services/medicarechecklist.service';
import { ToastrService } from 'ngx-toastr';
import { PatientMedicareCheckListDetailsService } from 'projects/patient/src/app/patient-proxy/patient-optimization/patient-medicare-check-list-details.service';
import { disapprovedStatusOptions } from 'projects/patient/src/app/patient-proxy/patient/enumeration-data/disapproved-status.enum';
import { DatePipe } from '@angular/common';
import { DisapprovedStatusId } from 'projects/patient/src/app/patient-proxy/patient/enumeration-data/medicare-check-list-status.enum';
import {
  defaultGuid,
  TextAnnotation,
} from '../../enums/allenums.enum';
import { AnnotatedDTO } from 'projects/admin/src/app/admin-proxy/platform-app-management/rcm/platform-management/inbound/dto/models';
import { MatCheckbox } from '@angular/material/checkbox';
import { DoctorService } from 'projects/patient/src/app/patient-proxy/patient';
import { doctorInterface } from 'projects/patient/src/app/patient-clinical-tab/patient-clinical-tab.component';
import { map, startWith, tap, debounceTime } from 'rxjs/operators';
import {  sotingSelectedCSS,
} from 'projects/shared/src/app/enums/allenums.enum';
import { PatientCommonNotesComponent } from '../patient-common-notes/patient-common-notes.component';
import { CommonDoumentViewerForMedicareComponent } from '../common-doument-viewer-for-medicare/common-doument-viewer-for-medicare.component';
import {
  CPAPtoBiLevel,
  ComplainceRelease,
  NewSetup,
  NonInvasionVentilation,
  OxygenSetup,
  PAPRequal,
  ReplacementPAP,
  finalSaveOutput,
} from 'projects/patient/src/app/patient-proxy/patient-optimization/dto/models';
import { ImageType } from 'projects/patient/src/app/patient-proxy/patient/enumeration-data/image-type.enum';
import { LoaderService } from '../../services/loader-service';
import { DomSanitizer, Title } from '@angular/platform-browser';
import { CommunicationService } from '../../services/communication.service';
import { noWhitespaceValidator } from 'projects/patient/src/app/add-note-tab/add-note-tab.component';
@Component({
  selector: 'app-do-verification-modal',
  templateUrl: './do-verification-modal.component.html',
  styleUrls: ['./do-verification-modal.component.scss'],
  providers: [DatePipe],
})
export class DoVerificationModalComponent implements OnInit {
  isDownloading:boolean=false;
  isSorting:boolean = true;
  isOrderPageLoading: boolean;
  readonly DEFAULT_LAZY_LOADING = 10;
  from: number = 1;
  to: number = this.DEFAULT_LAZY_LOADING;
  totalPagesV1: number;
  overAllPages: number = 0;
  overAllPagesBG: number = 0;
  TotalDocumentImageList: any = [];
  isTotalAPIcallGoingOn: boolean = false;
  sotingSelectedCSS = sotingSelectedCSS;
  selectedOption: sotingSelectedCSS = sotingSelectedCSS.Total;
  SortSaveError: boolean = true;
  isHideTiffViewer: boolean = false;
  showPdfViwer: boolean = false;
  chartId: string;
  vpatientName: string;
  vpatientAge: string;
  minDate: Date;
  endDate = new Date();
  strPdfString: string = '';
  defaultfaxNo: string;
  pdfheight = '100%';
  strSelectedPdfPath: string = '';
  blobName: any;
  defaultDocumentId: any;
  defaultFaxId: any;
  docID: any;
  DisapprovedStatusId: DisapprovedStatusId;
  isTreeLoad: boolean = false;
  isLoadingQuestion: boolean = false;
  patientID: any;
  isviewcheckLlist: any;
  isShowChecklist:any;
  medicarecheckListID: string = '';
  checklistID: string = '';
  verificationId: string = '';
  selectedVerification: any;
  selectedNode: any;
  questionCode: any;
  finalData: any;
  sortedDetails: any;
  checkListDetails: any;
  checkListName: string = '';
  subscription$: Subscription[] = [];
  loadQuestion$: Subscription;
  preQuestionaireform: FormGroup;
  FinalForm: FormGroup;
  Tabviewform: FormGroup;
  isPreQuestionaire: boolean = true;
  startCount: number = 1;
  endCount: number = 2;
  Questions: any[] = [];
  isenableFinish: boolean = false;
  isenableFinalResult: boolean = false;
  isdisableDisapprove: boolean = false;
  verification: any;
  detailedWrittenorder: any;
  disApprove: any;
  disapproveReason: any;
  activeTab: string = 'sleepstudy';
  selecteddiagCode: any;
  //API responses
  checkList: any;
  selectedPrequestionaire: string;
  isDisapproveNotes: boolean = false;
  SortingpanelOpenState: boolean = true;
  isDispproveReason:boolean = false;
  isShowSpinner: boolean = false;
  isSave: boolean = false;
  selected: any;
  diagnosiscodesleepstudy: any;
  diagnosiscodeRX: any;
  //ngModel
  notesText = '';
  disapproveStatus: boolean = false;
  isDisapprove: boolean = false;
  txtSeriveTypeFilterControl = new FormControl();
  lstServiceType: any;
  Prequestionaryselected: any;
  AddNotes: any;
  //disapprovedReason = DisapprovedStatus
  disapprovedReason: { value: number; key: string ; displayName : string}[] = [];
  RxdateMaxDate: Date;
  RxdateMinDate: Date;
  RxdateMax: Date;
  physicianApiSubscription:Subscription;
  BirthdateMaxDate: Date;
  BirthdateMinDate: Date;
  isPhysicianLoading:boolean = false;
  RxdateMin: Date;
  physicianDrpdwn: any;
  // public filteredPhysiciansNew: Observable<doctorInterface[]> | undefined; //Filtered Referring Provider Doctor Types
  // public filteredPhysiciansPAP: Observable<doctorInterface[]> | undefined; //Filtered Referring Provider Doctor Types
  // public filteredPhysiciansReplacement:Observable<doctorInterface[]> | undefined; //Filtered Referring Provider Doctor Types
  // public filteredPhysiciansCompliance:| Observable<doctorInterface[]> | undefined; //Filtered Referring Provider Doctor Types
  // public filteredPhysiciansOxygen: Observable<doctorInterface[]> | undefined; //Filtered Referring Provider Doctor Types
  // public filteredPhysiciansNIV: Observable<doctorInterface[]> | undefined; //Filtered Referring Provider Doctor Types

  filteredPhysiciansNew:any;
  filteredPhysiciansPAP:any;
  filteredPhysiciansReplacement:any;
  filteredPhysiciansCompliance:any;
  filteredPhysiciansOxygen:any
  filteredPhysiciansNIV:any;

  @Output() reloadTableEvent = new EventEmitter();
  @Output() selectNode = new EventEmitter();
  @ViewChild('approveCheckbox') approveCheckbox: MatCheckbox;
  @ViewChild('disapproveCheckbox') disapproveCheckbox: MatCheckbox;
  //#region  Tiff Viewer
  loadingMessage: string = 'Loading ...';
  isLoading: boolean;
  selectedExistingDocument: any = null;
  pageNumber: number = 1;
  currentArrayIndex: number = 0;
  // readonly DEFAULT_ZOOM_LEVEL = 1;
  // readonly MAX_ZOOM_LEVEL = 2.5;
  // readonly MIN_ZOOM_LEVEL = 0.5;
  readonly DEFAULT_ZOOM_LEVEL = 0.7;
  readonly MAX_ZOOM_LEVEL = this.DEFAULT_ZOOM_LEVEL + 4;
  readonly MIN_ZOOM_LEVEL = 0.3;
  zoomLevel: number = this.DEFAULT_ZOOM_LEVEL; // Default Size
  zoomLevelReSize:number = this.DEFAULT_ZOOM_LEVEL;
  existingDocumentImageList: any = [];
  totalPages: number = 0;
  textAnnotations: TextAnnotation[] = [];
  @ViewChild('canvas', { static: true }) canvasRef!: ElementRef;
  ctx!: CanvasRenderingContext2D;
  isDrawing: boolean = false;
  startX: number = 0;
  startY: number = 0;
  lineColor: string = '#F6BE00';
  textColor: string = '#0000FF';
  savedState: ImageData | null = null;
  isMouseDown: boolean = false;
  isMouseMove: boolean = false;
  isMouseUp: boolean = false;
  isMouseLeave: boolean = false;
  rectWidth: number;
  rectHeight: number;
  headerText: string;
  tiffPageNo: number = 0;
  // medicarecheckListIDv1:string;
  private saveAnnotationAPICall: Subscription;
  private loadInboundDocumentAPICall$: Subscription;
  TiffError: boolean = true;
  disableAnnotation: boolean = false;
  selectedValue: string = '';
  readonly BACK_TO_MAIN_QUESTION = 'Back To Main Question';
  back_2_text: string = this.BACK_TO_MAIN_QUESTION;
  uniqueChecklistId: any;
  PhysicianNew: string;

  @ViewChild('ChkcpaptoBilevelF2F') ChkcpaptoBilevelF2F: MatCheckbox;
  @ViewChild('ChkcpaptoBilevelMCST') ChkcpaptoBilevelMCST: MatCheckbox;
  @ViewChild('ChkcpaptoBilevelDWO') ChkcpaptoBilevelDWO: MatCheckbox;
  @ViewChild('ChkcpaptoBilevelPT') ChkcpaptoBilevelPT: MatCheckbox;
  imageType=ImageType;

  cpaptoBilevelF2F = [];
  cpaptoBilevelMCST = [];
  cpaptoBilevelDWO = [];
  cpaptoBilevelPT = [];

  @ViewChild('ChkPAPRequalF2F') ChkPAPRequalF2F: MatCheckbox;
  @ViewChild('ChkPAPRequalMCST') ChkPAPRequalMCST: MatCheckbox;
  @ViewChild('ChkPAPRequalDWO') ChkPAPRequalDWO: MatCheckbox;
  @ViewChild('ChkPAPRequalRT') ChkPAPRequalRT: MatCheckbox;
  papRequalF2f = [];
  papRequalMCSTCount: number = 0;
  papRequalMCST = [];
  papRequalDWOCount: number = 0;
  papRequalDWO = [];
  papRequalRTCount: number = 0;
  papRequalRT = [];

  @ViewChild('ChkRPAPMCST') ChkRPAPMCST: MatCheckbox;
  @ViewChild('ChkRPAPDWO') ChkRPAPDWO: MatCheckbox;
  @ViewChild('ChkRPAPFTF') ChkRPAPFTF: MatCheckbox;
  rPAPMCSTCount: number = 0;
  rPAPMCST = [];
  rPAPDWOCount: number = 0;
  rPAPDWO = [];
  rPAPFTFCount: number = 0;
  rPAPFTF = [];


  @ViewChild('ChkCRF2F') ChkCRF2F: MatCheckbox;
  crF2FCount: number = 0;
  crF2F = [];
  @ViewChild('ChkCRMCST') ChkCRMCST: MatCheckbox;
  crMCSTCount: number = 0;
  crMCST = [];
  @ViewChild('ChkCRDWO') ChkCRDWO: MatCheckbox;
  crDWOCount: number = 0;
  crDWO = [];



  @ViewChild('ChkOSOCEN') ChkOSOCEN: MatCheckbox;
  osOCENCount: number = 0;
  osOCEN = [];

  @ViewChild('ChkOSDWO') ChkOSDWO: MatCheckbox;
  osDWOCount: number = 0;
  osDWO = [];

  @ViewChild('ChkOSMCST') ChkOSMCST: MatCheckbox;
  osMCSTCount: number = 0;
  osMCST = [];


  @ViewChild('ChkNIVF2F') ChkNIVF2F: MatCheckbox;
  @ViewChild('ChkNIVDWO') ChkNIVDWO: MatCheckbox;
  @ViewChild('ChkNIVMCST') ChkNIVMCST: MatCheckbox;
  nivF2FCount: number = 0;
  nivF2F = [];
  nivDWOCount: number = 0;
  nivDWO = [];
  nivMCSTCount: number = 0;
  nivMCST = [];
  prevZoomLevel: number;
  usDateZone = new Date().toLocaleString("en-US", { timeZone: "America/Chicago" });
  funcListener:any;
  constructor(
    private activatedRoute: ActivatedRoute,
    private inboundDocumentService: InboundDocumentService,
    private medicareService: MedicarechecklistService,
    private patientmedicareService: PatientMedicareCheckListDetailsService,
    private toastr: ToastrService,
    private formBuilder: FormBuilder,
    private doctorService: DoctorService,
    private dialog: MatDialog,
    private loaderService: LoaderService,
    private title : Title,
    private sanitizer: DomSanitizer,
    private communicationService: CommunicationService,
  ) {
    this.loaderService.getLoaderState().subscribe((isOrderPageLoading) => {
      this.isOrderPageLoading = isOrderPageLoading;
    });
    this.funcListener = this.func.bind(this);
    window.addEventListener('storage', this.funcListener);
  }

  ngOnInit(): void {
    this.title.setTitle('Qsecure | Checklist');
    const activatedRoute = this.activatedRoute.paramMap.subscribe(
      (response) => {
        this.patientID = response.get('patientId') || defaultGuid;
        this.selectedNode = response.get('selectedNode') ?? defaultGuid;
        this.isviewcheckLlist = response.get('isview');
        this.isShowChecklist = response.get('isShow');
        // this.medicarecheckListID = response.get('medicareChecklistId') !== null ? response.get('medicareChecklistId') : defaultGuid;
        this.medicarecheckListID = response.get('medicareChecklistId');

        if (this.medicarecheckListID === 'null') {
          this.medicarecheckListID = defaultGuid;
        }

        // if(this.medicarecheckListID){
        this.fngetMedicareDetails();
        // }
        if (this.isviewcheckLlist == 'true' || this.isShowChecklist=='true') {
          this.loadfinalForm();
          this.isShowChecklist && this.FinalForm.disable();
        }
        else {
          this.Initializeform();
        }
      },
      (err) => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      }
    );
    this.subscription$.push(activatedRoute);

    this.disapprovedReason = disapprovedStatusOptions?.map((e) => {
      let x = {
        value: e.value,
        key: e.key,
        displayName : e.displayName
      };
      return x;
    });
    // this.loadValidDates();
    this.loadValidDatesv1();
    this.minDate = new Date();
    this.getPhysicianDrpdwn();
  }

  ngOnDestroy(): void {
    if (this.loadInboundDocumentAPICall$) {
      this.loadInboundDocumentAPICall$?.unsubscribe();
    }
    if (this.saveAnnotationAPICall) {
      this.saveAnnotationAPICall.unsubscribe();
    }
    this.subscription$?.forEach((sub) => {
      sub && sub?.unsubscribe();
    });
  }

  checkBoxEvent(event, flag:ImageType, previewList) {
    if (event.checked === true) {
      const config: MatDialogConfig = {
        width: '100vw',
        height: '100vh',
        maxWidth: '100vw',
        maxHeight: '100vh',
        panelClass: 'fullscreen-dialog-panel',
        disableClose: true,
        data: {
          // claimId: '00000000-0000-0000-0000-000000000000'
          previewList: previewList,
          docId: this.docID,
          isMedicare: true,
          flag: flag,
          medicareId : this.medicarecheckListID
        },
      };
      // CommonDoumentViewerForMedicareComponent
      // ViewDocumentCheckListTiffViewerComponent
      const dialogRef = this.dialog.open(
        CommonDoumentViewerForMedicareComponent,
        config
      );

      const closeDialog = dialogRef
        .afterClosed()
        .subscribe((selectedFileDetails: any) => {
          if (selectedFileDetails) {

            if (flag === this.imageType.CPAPFaceToFace  ) {  // this.MedicarecheckBoxList.CPAPF2F
              // this.cpaptoBilevelF2FCount = selectedFileDetails.length;
              this.cpaptoBilevelF2F = selectedFileDetails;
              this.ChkcpaptoBilevelF2F.checked = false;
            } else if (
              flag === this.imageType.CPAPMedicareSleepTest ) {
              this.cpaptoBilevelMCST = selectedFileDetails;
              this.ChkcpaptoBilevelMCST.checked = false;
            } else if (
              flag === this.imageType.CPAPDetailWrittenOrder) {
              this.cpaptoBilevelDWO = selectedFileDetails;
              this.ChkcpaptoBilevelDWO.checked = false;
            } else if (flag === this.imageType.CPAPPickupTicket) {
              this.cpaptoBilevelPT = selectedFileDetails;
              this.ChkcpaptoBilevelPT.checked = false;
            }



            else if (flag === this.imageType.PAPreFaceToFace) {
              this.papRequalF2f = selectedFileDetails;
              this.ChkPAPRequalF2F.checked = false;
            } else if (flag === this.imageType.PAPreMedicareSleepTest) {
              this.papRequalMCST = selectedFileDetails;
              this.ChkPAPRequalMCST.checked = false;
            } else if (flag === this.imageType.PAPreDetailWrittenOrder) {
              this.papRequalDWO = selectedFileDetails;
              this.ChkPAPRequalDWO.checked = false;
            } else if (flag === this.imageType.PAPreReturnTicket) {
              this.papRequalRT = selectedFileDetails;
              this.ChkPAPRequalRT.checked = false;
            }




            else if (flag === this.imageType.REPLACEMENTMedicareSeepTest) {
              this.rPAPMCST = selectedFileDetails;
              this.ChkRPAPMCST.checked = false;
            }
            else if (flag === this.imageType.REPLACEMENTDetailWrittenOrder) {
              this.rPAPDWO = selectedFileDetails;
              this.ChkRPAPDWO.checked = false;
            }
            else if (flag === this.imageType.REPLACEMENTftfNote) {
              this.rPAPFTF = selectedFileDetails;
              this.ChkRPAPFTF.checked = false;
            }



            else if (flag === this.imageType.COMPLIANCEftf) {
              this.crF2F = selectedFileDetails;
              this.ChkCRF2F.checked = false;
            }
            else if (flag === this.imageType.COMPLIANCEMedicareSleepTest) {
              this.crMCST = selectedFileDetails;
              this.ChkCRMCST.checked = false;
            }
            else if (flag === this.imageType.COMPLIANCEDetailWrittenOrder) {
              this.crDWO = selectedFileDetails;
              this.ChkCRDWO.checked = false;
            }


            else if (flag === this.imageType.OfflineClinicalNote) {
              this.osOCEN = selectedFileDetails;
              this.ChkOSOCEN.checked = false;
            }
            else if (flag === this.imageType.OXYGENofflineClinic) {

            }
            else if (flag === this.imageType.OXYGENMedicareSleepTest) {
              this.osMCST = selectedFileDetails;
              this.ChkOSMCST.checked = false;
            }
            else if (flag === this.imageType.OXYGENDetailWrittenOrder) {
              this.osDWO = selectedFileDetails;
              this.ChkOSDWO.checked = false;
            }

            else if (flag === this.imageType.NONINVASIVEfaceToface) {
              this.nivF2F = selectedFileDetails;
              this.ChkNIVF2F.checked = false;
            }
            else if (flag === this.imageType.NONINVASIVEDetailWrittenOrder) {
              this.nivDWO = selectedFileDetails;
              this.ChkNIVDWO.checked = false;
            }
            else if (flag === this.imageType.NONINVASIVEMedicareSleepTest) {
              this.nivMCST = selectedFileDetails;
              this.ChkNIVMCST.checked = false;
            }

          }
        });
      this.subscription$.push(closeDialog);
    }
  }

  openViewNotesPopup() {
    const config: MatDialogConfig = {
      disableClose: true,
      width: '70vw',
      maxWidth: '70vw',
      height: '40vw',
      maxHeight: '40vw',
      data: {
        patientId: this.patientID,
        pageType: 'Patient Note', // this.pageType, //Notes Table Popup Page
        isAddVisible: false,
        defaultFaxId: this.defaultFaxId,
        docId: this.docID,
        patientMedicareChecklistId: this.medicarecheckListID,
      },
    };
    const dialogRef = this.dialog.open(PatientCommonNotesComponent, config);

    dialogRef.afterClosed().subscribe(
      () => {
        //this.getReminderTableData();
      },
      (err: HttpErrorResponse) => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      }
    );
  }
  getPhysicianDrpdwnV1(key: string) {
    this.isPhysicianLoading = true;
    // this.PhysicianNewId = (this.PhysicianNew) ? this.PhysicianNew.split('/')[0] : defaultGuid;
    if (this.physicianApiSubscription) {
      this.physicianApiSubscription.unsubscribe();
    }
    this.physicianApiSubscription = this.doctorService
      // .getPhysicianDropdownV1('', key, this.PhysicianNew)
      .getAllPhysiciansV1ByGDoctorAndSSearch(this.PhysicianNew,key)
      .subscribe(
        (response) => {
          this.isPhysicianLoading = false;
          this.filteredPhysiciansNew =
            this.filteredPhysiciansPAP =
            this.filteredPhysiciansReplacement =
            this.filteredPhysiciansCompliance =
            this.filteredPhysiciansOxygen =
            this.filteredPhysiciansNIV =
              response;
          this.physicianDrpdwn = response;
        },
        (err) => {
          this.isPhysicianLoading = false;
          // const data: HttpErrorResponse = err;
          // Swal.fire({
          //   icon: 'info',
          //   text: data?.error?.error?.message,
          // });
        }
      );
      // this.subscription$.push(physicianDropdown);
  }

  getPhysicianDrpdwn(key: string = '') {
    this.isPhysicianLoading = true;
    const physicianDropdown = this.doctorService
      // .getPhysicianDropdownV1('', key, this.PhysicianNew)
      .getAllPhysiciansV1ByGDoctorAndSSearch(this.PhysicianNew,key)
      .subscribe(
        (response) => {
          this.isPhysicianLoading = false;
          this.physicianDrpdwn = response;
          //Primary Doctor Dropdown
          this.filteredPhysiciansNew = this.FinalForm?.get(
            'txtPhysicianFilterNew'
          )?.valueChanges.pipe(
            startWith(''),
            map((value) =>
              this.physicianDrpdwn?.filter((option) =>
                option?.fullName
                  ?.toLowerCase()
                  ?.includes(value?.toLowerCase() ?? '')
              )
            )
          );

          this.filteredPhysiciansPAP = this.FinalForm?.get(
            'txtPhysicianFilterPAP'
          )?.valueChanges.pipe(
            startWith(''),
            map((value) =>
              this.physicianDrpdwn?.filter((option) =>
                option?.fullName
                  ?.toLowerCase()
                  ?.includes(value?.toLowerCase() ?? '')
              )
            )
          );
          this.filteredPhysiciansReplacement = this.FinalForm?.get(
            'txtPhysicianFilterReplacement'
          )?.valueChanges.pipe(
            startWith(''),
            map((value) =>
              this.physicianDrpdwn?.filter((option) =>
                option?.fullName
                  ?.toLowerCase()
                  ?.includes(value?.toLowerCase() ?? '')
              )
            )
          );
          this.filteredPhysiciansCompliance = this.FinalForm?.get(
            'txtPhysicianFilterCompliance'
          )?.valueChanges.pipe(
            startWith(''),
            map((value) =>
              this.physicianDrpdwn?.filter((option) =>
                option?.fullName
                  ?.toLowerCase()
                  ?.includes(value?.toLowerCase() ?? '')
              )
            )
          );
          this.filteredPhysiciansOxygen = this.FinalForm?.get(
            'txtPhysicianFilterOxygen'
          )?.valueChanges.pipe(
            startWith(''),
            map((value) =>
              this.physicianDrpdwn?.filter((option) =>
                option?.fullName
                  ?.toLowerCase()
                  ?.includes(value?.toLowerCase() ?? '')
              )
            )
          );
          this.filteredPhysiciansNIV = this.FinalForm?.get(
            'txtPhysicianFilterNIV'
          )?.valueChanges.pipe(
            startWith(''),
            map((value) =>
              this.physicianDrpdwn?.filter((option) =>
                option?.fullName
                  ?.toLowerCase()
                  ?.includes(value?.toLowerCase() ?? '')
              )
            )
          );
        },
        (err) => {
          this.isPhysicianLoading = false;
          // const data: HttpErrorResponse = err;
          // Swal.fire({
          //   icon: 'info',
          //   text: data?.error?.error?.message,
          // });
          // this.subscription$.push(physicianDropdown);
        }
      );
  }

  fngetMedicareDetails() {
    const medicaredetails = this.patientmedicareService
      .getMedicareDocumentDetialsByCheckListAndPatientId(
        this.medicarecheckListID,
        this.patientID
      )
      .subscribe(
        (response) => {
          this.blobName = response?.blobName;
          this.defaultFaxId = response?.faxID;
          this.docID = response?.documetId;
          this.uniqueChecklistId = response?.checkListId;
          // this.patientID = response?.patientID ?? defaultGuid;
          this.chartId = response?.chartId;
          this.vpatientName = response?.patientname;
          this.vpatientAge = response?.patientAge;
          this.loadInboundDocument(this.from, this.to);
        },
        (err) => {
          const data: HttpErrorResponse = err;
          // this.toastr.error(data?.error?.error?.message, 'Error');
        }
      );
    this.subscription$.push(medicaredetails);
  }

  //To set the Valid Dates for Birth Date field
  loadValidDatesv1() {
    this.BirthdateMaxDate = new Date();
    this.BirthdateMaxDate.setDate(this.BirthdateMaxDate.getDate());
    this.RxdateMax = this.BirthdateMaxDate;
    this.BirthdateMinDate = new Date();
    this.BirthdateMinDate.setFullYear(
      this.BirthdateMinDate.getFullYear() - 120
    );
    var oneYearAgo = new Date();
    oneYearAgo.setFullYear(this.BirthdateMaxDate.getFullYear() - 1);
    this.RxdateMin = oneYearAgo;
  }
  loadfinalForm() {
    this.FinalForm = this.formBuilder.group({
      txtChart: new FormControl(''),
      txtPatientName: new FormControl(''),
      txtComments: new FormControl(''),
      // drpVerification: ['', Validators.required],
      drpVerification: new FormControl('', [Validators.required]),
      txtInsComments: new FormControl(''),
      txtAddNotes: new FormControl(''),
      // txtPreparedDate: new FormControl(null),
      txtPreparedDate: [new Date(this.usDateZone)],
      txtgrandFathered: new FormControl(''),
      txtPreparedBy: new FormControl(''),
      drpDisapprove: new FormControl(''),
      rbnapprove: new FormControl(''),
      /*New or CPAP to Bilevel*/
      ChkcpaptoBilevelF2F: new FormControl(''),
      txtdatefacetofaceOSA: new FormControl(''),
      drpdatedetailedWO: new FormControl(''),
      txtRXDateNew: new FormControl(''),
      txtMedicarecovered: new FormControl(''),
      txtMedicarePickup: new FormControl(''),
      // txtNewNPI: new FormControl(''),
      /*Replacement PAP*/
      txtmedicareReplacement: new FormControl(''),
      drpdatedetailedWOReplacement: new FormControl(''),
      txtRXDateReplacement: new FormControl(''),
      txtFTFnoteReplacement: new FormControl(''),
      /*PAP Requal*/
      txtdatefacetofaceOSAPAP: new FormControl(''),
      drpdatedetailedWOPAP: new FormControl(''),
      txtMedicarecoveredPAP: new FormControl(''),
      txtRXDatePAP: new FormControl(''),
      txtreturnTicketPAP: new FormControl(''),
      // txtNewNPIPAP: new FormControl(''),
      /*Compliance release*/
      txtFTFnoteCompliance: new FormControl(''),
      txtmedicareCompliance: new FormControl(''),
      drpdatedetailedWOCompliance: new FormControl(''),
      txtRXDateCompliance: new FormControl(''),
      // txtNPICompliance: new FormControl(''),
      /*Oxygen setup*/
      txtclinicalevaluationOxygen: new FormControl(''),
      drpdatedetailedWOOxygen: new FormControl(''),
      txtRXDateOxygen: new FormControl(''),
      txtmedicareOxygen: new FormControl(''),
      // txtNPIOxygen: new FormControl(''),
      /*Non Invasive*/
      txtclinicalevaluationNIV: new FormControl(''),
      drpdatedetailedWONIV: new FormControl(''),
      txtRXDateNIV: new FormControl(''),
      txtmedicareNIV: new FormControl(''),
      // txtNPINIV: new FormControl(''),

      // txtDisapproveNotes:new FormControl(''),
      txtDisapproveNotes: new FormControl(''),
      txtPhysicianFilterNew: new FormControl(''),
      drpPhysicianNew: new FormControl(''),

      txtPhysicianFilterPAP: new FormControl(''),
      drpPhysicianPAP: new FormControl(''),

      txtPhysicianFilterReplacement: new FormControl(''),
      drpPhysicianReplacement: new FormControl(''),

      txtPhysicianFilterCompliance: new FormControl(''),
      drpPhysicianCompliance: new FormControl(''),

      txtPhysicianFilterOxygen: new FormControl(''),
      drpPhysicianOxygen: new FormControl(''),

      txtPhysicianFilterNIV: new FormControl(''),
      drpPhysicianNIV: new FormControl(''),
    });

    this.Tabviewform = this.formBuilder.group({
      txtStudyDate: new FormControl(''),
      txtPressureSettings: new FormControl(''),
      txtREI: new FormControl(''),
      txtRDI: new FormControl(''),
      txtTotalSleep: new FormControl(''),
      txtAHI: new FormControl(''),
      txtCentralApneas: new FormControl(''),
      txtCentralHyponeas: new FormControl(''),
      txtTotalApnea: new FormControl(''),
      txtdiagSleepdate: new FormControl(''),
      txtSleepStudyDate: new FormControl(''),
      txtTitrationAHI: new FormControl(''),
      txtTitrationCentralApneas: new FormControl(''),
      txtTitrationCentralHyponeas: new FormControl(''),
      txtTitrationtotalHypopnea: new FormControl(''),
      chkCPAPTried: new FormControl(false),
      chkRespbreakdown: new FormControl(false),
      chkTitrationDoctorsigned: new FormControl(false),
      chkCPAPreturned: new FormControl(false),
      chkBaselinefile: new FormControl(false),
      chkDoctorsigned: new FormControl(false),
      txtObstructive: new FormControl(''),
      txtPrescriptiondate: new FormControl(''),
      txtPressurelevel: new FormControl(''),
      txtLengthofneed: new FormControl(''),
      txtNPINumber: new FormControl(''),
      txtRXdiagCode: new FormControl(''),
      txtRXDoctorName: new FormControl(''),
      txtRXMachineDetails: new FormControl(''),
      chkRXDoctorsigned: new FormControl(false),
      txtDoctorsigneddate: new FormControl(''),
      txtStudyStartdate: new FormControl(''),
      txtStudyEnddate: new FormControl(''),
      txtcompPercentage: new FormControl(''),
      txtusageDays: new FormControl(''),
      txtOutOf: new FormControl(''),
      txtGreaterfourhours: new FormControl(''),
      txtLessfourhours: new FormControl(''),
      txtfacetofacedate: new FormControl(''),
      txtDateofvisit: new FormControl(''),
      txtSignedDate: new FormControl(''),
      txtfaceNPINumber: new FormControl(''),
      chkPrevioushistory: new FormControl(false),
      chkfaceSleeptest: new FormControl(false),
      chkfaceDoctorsigned: new FormControl(false),
      chkfacePatientBenefits: new FormControl(false),
      txtfaceDoctorName: new FormControl(''),
      txtfaceSymptoms: new FormControl(''),
      diagSleepStudy: new FormControl(''),
      drpdiagRx: new FormControl(''),
    });

    this.fndetailedOrderdrp();

    //var medicarechecklistid = '97e82a39-80f9-5075-1626-3a104ee57e29';
    this.patientmedicareService.get(this.medicarecheckListID).subscribe(
      (response) => {
        this.finalData = response;
        this.isSorting = this.finalData?.finalData?.isSorted;
        this.fillFinal();
      },
      (err) => {
        const data: HttpErrorResponse = err;
        // Swal.fire({
        //   icon: 'info',
        //   text: data?.error?.error?.message,
        // });

        this.toastr.error(data?.error?.error?.message, 'Error');
      }
    );

    this.isenableFinalResult = true;
  }

  fillFinal() {
    this.checkListName =
      this.finalData?.finalData?.checkListDetails?.checklistName;
    this.verification = this.finalData.finalData.verificationDetails;
    this.sortedDetails = this.finalData.sortedDetails;
    this.defaultfaxNo = this.finalData.defaultFaxId;
    if (this.finalData != null) {
      this.verification = this.finalData.finalData.verificationDetails;
      this.checklistID = this.finalData.finalData.checkListId;
      if (this.finalData.status === 'Disapproved') {
        this.FinalForm.patchValue({
          rbnapprove: '2',
        });
        // setTimeout(() => {
          this.onApprove('2');
          // }, 1000);
      } else if (this.finalData.status === 'Approved') {
        this.FinalForm.patchValue({
          rbnapprove: '1',
        });
        // setTimeout(() => {
          this.onApprove('1');
          // }, 1000);
      } else {
        this.onApprove('');
      }

      if (this.finalData.disaproveReason == 'Other') {
        this.isDisapproveNotes = true;
        this.isDisapprove = true;
      } else {
        this.isDisapproveNotes = false;
        this.isDisapprove = false;
      }
      // const medicareNotesString =
      //   this.finalData.finalData.medicareNotes.join('\n');
      const medicareNotesString = this.finalData.finalData.medicareNotes;
      this.verification.forEach((element) => {
        if (element.verificationId == this.finalData.verificationId) {
          this.verificationId = element.verificationId;
          this.FinalForm.patchValue({
            txtInsComments: element.verificationNotes,
          });
        }
      });
      if (
        this.finalData?.finalData?.checkListDetails?.npiId !== defaultGuid &&
        this.finalData?.finalData?.checkListDetails?.npiId !== null
      ) {
        this.PhysicianNew =
          this.finalData?.finalData?.checkListDetails?.npiId || defaultGuid;
        this.getPhysicianDrpdwnV1('');
        setTimeout(() => {
          this.PhysicianNew =
            this.finalData?.finalData?.checkListDetails?.npiId;
            this.FinalForm.patchValue({
            drpPhysicianNew: this.finalData?.finalData?.checkListDetails?.npiId,
            drpPhysicianPAP: this.finalData?.finalData?.checkListDetails?.npiId,
            drpPhysicianReplacement:
              this.finalData?.finalData?.checkListDetails?.npiId,
            drpPhysicianCompliance:
              this.finalData?.finalData?.checkListDetails?.npiId,
            drpPhysicianOxygen:
              this.finalData?.finalData?.checkListDetails?.npiId,
            drpPhysicianNIV: this.finalData?.finalData?.checkListDetails?.npiId,
          });
        }, 1000); // Adjust the delay as needed
      } else {
        this.FinalForm.patchValue({
          drpPhysicianNew: '',
          drpPhysicianPAP: '',
          drpPhysicianReplacement: '',
          drpPhysicianCompliance: '',
          drpPhysicianOxygen: '',
          drpPhysicianNIV: '',
        });
      }

      this.FinalForm.patchValue({
        txtChart: this.finalData?.finalaDta?.chartNo,
        txtPatientName: this.finalData?.finalData?.patientName,
        txtComments: medicareNotesString,
        txtgrandFathered: this.finalData?.isgrandFathered,
        txtPreparedBy: this.finalData?.preparedByName,
        drpVerification: this.finalData?.verificationId,
        drpDisapprove: this.finalData?.disaproveReason,
        txtDisapproveNotes: this.finalData?.disapprovedNotes,
        drpdatedetailedWO:
          this.finalData?.finalData?.checkListDetails?.detailedWrittenOrder,
        drpdatedetailedWOPAP:
          this.finalData?.finalData?.checkListDetails?.detailedWrittenOrder,
        drpdatedetailedWOReplacement:
          this.finalData?.finalData?.checkListDetails?.detailedWrittenOrder,
        drpdatedetailedWOCompliance:
          this.finalData?.finalData?.checkListDetails?.detailedWrittenOrder,
        drpdatedetailedWOOxygen:
          this.finalData?.finalData?.checkListDetails?.detailedWrittenOrder,
        drpdatedetailedWONIV:
          this.finalData?.finalData?.checkListDetails?.detailedWrittenOrder,
          txtAddNotes:this.finalData?.medicareNotes
      });

      this.cpaptoBilevelF2F= this.finalData?.finalData?.checkListDetails?.faceToFacePageNo || [];
      this.cpaptoBilevelMCST= this.finalData?.finalData?.checkListDetails?.medicareCoveredSleepTestPageNo || [];
      this.cpaptoBilevelPT= this.finalData?.finalData?.checkListDetails?.pickUpTicketPageNo || [];
      this.cpaptoBilevelDWO= this.finalData?.finalData?.checkListDetails?.dwoPageNo || [];

      this.papRequalF2f = this.finalData?.finalData?.checkListDetails?.faceToFacePageNo || [];
      this.papRequalMCST = this.finalData?.finalData?.checkListDetails?.medicareCoveredSleepTestPageNo || [];
      this.papRequalDWO= this.finalData?.finalData?.checkListDetails?.dwoPageNo || [];
      this.papRequalRT = this.finalData?.finalData?.checkListDetails?.returnTicketPageNo || [];

      this.rPAPMCST=this.finalData?.finalData?.checkListDetails?.medicareCoveredSleepTestPageNo || [];
      this.rPAPDWO= this.finalData?.finalData?.checkListDetails?.dwoPageNo || [];
      this.rPAPFTF =  this.finalData?.finalData?.checkListDetails?.ftfNotePageNo || [];

      this.crF2F= this.finalData?.finalData?.checkListDetails?.faceToFacePageNo || [];
      this.crMCST=this.finalData?.finalData?.checkListDetails?.medicareCoveredSleepTestPageNo || [];
      this.crDWO=this.finalData?.finalData?.checkListDetails?.dwoPageNo || [];

      this.osOCEN= this.finalData?.finalData?.checkListDetails?.offlineClinicalNotePageNo || [];
      this.osDWO= this.finalData?.finalData?.checkListDetails?.dwoPageNo || [];
      this.osMCST= this.finalData?.finalData?.checkListDetails?.medicareCoveredTestingReportPageNo || [];

      this.nivF2F=this.finalData?.finalData?.checkListDetails?.faceToFacePageNo || [];
      this.nivDWO=this.finalData?.finalData?.checkListDetails?.dwoPageNo || [];
      this.nivMCST=this.finalData?.finalData?.checkListDetails?.medicareTestingPageNo || [];

      const setDateValue = (dateValue: any) => {
        return dateValue ? new Date(dateValue).toISOString().split('T')[0] : '';
      };

      if (this.finalData?.finalData?.checkListDetails) {
        this.FinalForm.get('txtPreparedDate').setValue(
          setDateValue(this.finalData.preparedDate)
        );
        this.FinalForm.get('txtdatefacetofaceOSA').setValue(
          setDateValue(
            this.finalData.finalData.checkListDetails.faceToFaceEvaluation
          )
        );
        this.FinalForm.get('txtRXDateNew').setValue(
          setDateValue(this.finalData.finalData.checkListDetails.rxDate)
        );
        this.FinalForm.get('txtMedicarecovered').setValue(
          setDateValue(
            this.finalData.finalData.checkListDetails.medicareCoveredSleepTest
          )
        );
        this.FinalForm.get('txtMedicarePickup').setValue(
          setDateValue(
            this.finalData.finalData.checkListDetails.pickUpTicket
          )
        );

        // PAP Requal
        this.FinalForm.get('txtdatefacetofaceOSAPAP').setValue(
          setDateValue(
            this.finalData.finalData.checkListDetails.faceToFaceEvaluation
          )
        );
        this.FinalForm.get('txtRXDatePAP').setValue(
          setDateValue(this.finalData.finalData.checkListDetails.rxDate)
        );
        this.FinalForm.get('txtMedicarecoveredPAP').setValue(
          setDateValue(
            this.finalData.finalData.checkListDetails.medicareCoveredSleepTest
          )
        );

        // Replacement PAP
        this.FinalForm.get('txtmedicareReplacement').setValue(
          setDateValue(
            this.finalData.finalData.checkListDetails.medicareCoveredSleepTest
          )
        );
        this.FinalForm.get('txtRXDateReplacement').setValue(
          setDateValue(this.finalData.finalData.checkListDetails.rxDate)
        );
        this.FinalForm.get('txtFTFnoteReplacement').setValue(
          setDateValue(this.finalData.finalData.checkListDetails.ftFnote)
        );
        this.FinalForm.get('txtreturnTicketPAP').setValue(
          setDateValue(this.finalData.finalData.checkListDetails.returnTicket)
        );

        // Compliance Release
        this.FinalForm.get('txtmedicareCompliance').setValue(
          setDateValue(
            this.finalData.finalData.checkListDetails.medicareCoveredSleepTest
          )
        );
        this.FinalForm.get('txtRXDateCompliance').setValue(
          setDateValue(this.finalData.finalData.checkListDetails.rxDate)
        );
        this.FinalForm.get('txtFTFnoteCompliance').setValue(
          setDateValue(this.finalData.finalData.checkListDetails.currentFaceToFaceNoteDiscussion)
        );

        // Oxygen Setup
        this.FinalForm.get('txtmedicareOxygen').setValue(
          setDateValue(
            this.finalData.finalData.checkListDetails
              .medicareCoveredTestingReport
          )
        );
        this.FinalForm.get('txtRXDateOxygen').setValue(
          setDateValue(this.finalData.finalData.checkListDetails.rxDate)
        );
        this.FinalForm.get('txtclinicalevaluationOxygen').setValue(
          setDateValue(
            this.finalData.finalData.checkListDetails
              .offlineClinicalEvaluatonNotes
          )
        );

        // Non-Invasive Ventilation (NIV)
        this.FinalForm.get('txtmedicareNIV').setValue(
          setDateValue(
            this.finalData.finalData.checkListDetails.medicareTestingReport
          )
        );
        this.FinalForm.get('txtRXDateNIV').setValue(
          setDateValue(this.finalData.finalData.checkListDetails.rxDate)
        );
        this.FinalForm.get('txtclinicalevaluationNIV').setValue(
          setDateValue(
            this.finalData.finalData.checkListDetails.faceToFaceEvaluation
          )
        );
      }
    }
    if (
      this.finalData.finalData.verificationDetails != null ||
      this.finalData.finalData.verificationDetails != undefined
    ) {
      let veriricationdetail = this.finalData.finalData.verificationDetails;
      let selectedverificationid = this.finalData.finalData.verificationId;
      veriricationdetail.forEach((element) => {
        if (element.verificationId == selectedverificationid) {
          let selectedvalue = [];
          selectedvalue.push(element);
          this.selectedVerification = element.verificationId;
          this.FinalForm.patchValue({
            // drpVerification:selectedvalue,
            txtInsComments: element.verificationNotes,
          });
        }
      });
    }
    if (this.sortedDetails != null || this.sortedDetails != undefined) {
      this.FillfinalForm();
      this.diagnosiscodesleepstudy =
        this.sortedDetails?.sleepStudies[0]?.diagnosisCode || [];
      this.diagnosiscodeRX =
        this.sortedDetails?.prescriptions[0]?.diagnosticCode || [];
    }
  }

  //This is to initialize the form
  Initializeform() {
    this.preQuestionaireform = this.formBuilder.group({
      // rbtnReportType: new FormControl('')
      rbtnReportType: new FormControl('', [Validators.required]),
    });

    this.Tabviewform = this.formBuilder.group({
      txtStudyDate: new FormControl(''),
      txtPressureSettings: new FormControl(''),
      txtREI: new FormControl(''),
      txtRDI: new FormControl(''),
      txtTotalSleep: new FormControl(''),
      txtAHI: new FormControl(''),
      txtCentralApneas: new FormControl(''),
      txtCentralHyponeas: new FormControl(''),
      txtTotalApnea: new FormControl(''),
      txtdiagSleepdate: new FormControl(''),
      txtSleepStudyDate: new FormControl(''),
      txtTitrationAHI: new FormControl(''),
      txtTitrationCentralApneas: new FormControl(''),
      txtTitrationCentralHyponeas: new FormControl(''),
      txtTitrationtotalHypopnea: new FormControl(''),
      chkCPAPTried: new FormControl(false),
      chkRespbreakdown: new FormControl(false),
      chkTitrationDoctorsigned: new FormControl(false),
      chkCPAPreturned: new FormControl(false),
      chkBaselinefile: new FormControl(false),
      chkDoctorsigned: new FormControl(false),
      txtObstructive: new FormControl(''),
      txtPrescriptiondate: new FormControl(''),
      txtPressurelevel: new FormControl(''),
      txtLengthofneed: new FormControl(''),
      txtNPINumber: new FormControl(''),
      txtRXdiagCode: new FormControl(''),
      txtRXDoctorName: new FormControl(''),
      txtRXMachineDetails: new FormControl(''),
      chkRXDoctorsigned: new FormControl(false),
      txtDoctorsigneddate: new FormControl(''),
      txtStudyStartdate: new FormControl(''),
      txtStudyEnddate: new FormControl(''),
      txtcompPercentage: new FormControl(''),
      txtusageDays: new FormControl(''),
      txtOutOf: new FormControl(''),
      txtGreaterfourhours: new FormControl(''),
      txtLessfourhours: new FormControl(''),
      txtfacetofacedate: new FormControl(''),
      txtDateofvisit: new FormControl(''),
      txtSignedDate: new FormControl(''),
      txtfaceNPINumber: new FormControl(''),
      chkPrevioushistory: new FormControl(false),
      chkfaceSleeptest: new FormControl(false),
      chkfaceDoctorsigned: new FormControl(false),
      chkfacePatientBenefits: new FormControl(false),
      txtfaceDoctorName: new FormControl(''),
      txtfaceSymptoms: new FormControl(''),
      diagSleepStudy: new FormControl(''),
      drpdiagRx: new FormControl(''),
    });

    this.FinalForm = this.formBuilder.group({
      txtChart: new FormControl(''),
      txtPatientName: new FormControl(''),
      txtComments: new FormControl(''),
      // drpVerification: ['', Validators.required],
      drpVerification: new FormControl('', [Validators.required]),
      txtInsComments: new FormControl(''),
      txtAddNotes: new FormControl(''),
      // txtPreparedDate: new FormControl(null),
      txtPreparedDate: [new Date()],
      txtgrandFathered: new FormControl(''),
      txtPreparedBy: new FormControl(localStorage.getItem('name') ?? ''),
      drpDisapprove: new FormControl(''),
      rbnapprove: new FormControl(''),
      /*New or CPAP to Bilevel*/
      txtdatefacetofaceOSA: new FormControl(''),
      drpdatedetailedWO: new FormControl(''),
      txtRXDateNew: new FormControl(''),
      txtMedicarecovered: new FormControl(''),
      txtMedicarePickup: new FormControl(''),
      // txtNewNPI: new FormControl(''),
      /*Replacement PAP*/
      txtmedicareReplacement: new FormControl(''),
      drpdatedetailedWOReplacement: new FormControl(''),
      txtRXDateReplacement: new FormControl(''),
      txtFTFnoteReplacement: new FormControl(''),
      /*PAP Requal*/
      txtdatefacetofaceOSAPAP: new FormControl(''),
      drpdatedetailedWOPAP: new FormControl(''),
      txtMedicarecoveredPAP: new FormControl(''),
      txtRXDatePAP: new FormControl(''),
      txtreturnTicketPAP: new FormControl(''),
      // txtNewNPIPAP: new FormControl(''),
      /*Compliance release*/
      txtFTFnoteCompliance: new FormControl(''),
      txtmedicareCompliance: new FormControl(''),
      drpdatedetailedWOCompliance: new FormControl(''),
      txtRXDateCompliance: new FormControl(''),
      // txtNPICompliance: new FormControl(''),
      /*Oxygen setup*/
      txtclinicalevaluationOxygen: new FormControl(''),
      drpdatedetailedWOOxygen: new FormControl(''),
      txtRXDateOxygen: new FormControl(''),
      txtmedicareOxygen: new FormControl(''),
      // txtNPIOxygen: new FormControl(''),
      /*Non Invasive*/
      txtclinicalevaluationNIV: new FormControl(''),
      drpdatedetailedWONIV: new FormControl(''),
      txtRXDateNIV: new FormControl(''),
      txtmedicareNIV: new FormControl(''),
      // txtNPINIV: new FormControl(''),

      txtDisapproveNotes: new FormControl(''),
      txtPhysicianFilterNew: new FormControl(''),
      drpPhysicianNew: new FormControl(''),

      txtPhysicianFilterPAP: new FormControl(''),
      drpPhysicianPAP: new FormControl(''),

      txtPhysicianFilterReplacement: new FormControl(''),
      drpPhysicianReplacement: new FormControl(''),

      txtPhysicianFilterCompliance: new FormControl(''),
      drpPhysicianCompliance: new FormControl(''),

      txtPhysicianFilterOxygen: new FormControl(''),
      drpPhysicianOxygen: new FormControl(''),

      txtPhysicianFilterNIV: new FormControl(''),
      drpPhysicianNIV: new FormControl(''),
    });
    this.isTreeLoad = true;
    //This is to get the pre Questionaire form Options
    this.medicareService.getOverallchecklist().subscribe(
      (response) => {
        let checkListItems = response.items;
        this.checkList = checkListItems;
        this.isTreeLoad = false;
      },
      (err) => {
        const data: HttpErrorResponse = err;
        this.isTreeLoad = false;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      }
    );

    this.fndetailedOrderdrp();
  }

  //Detailed Writen Order load Drop down

  fndetailedOrderdrp() {

    const detailedWrittenDropdown = this.patientmedicareService
      .getCMNonFileTypeList()
      .subscribe(
        (response) => {
          this.detailedWrittenorder = response;
        },
        (err) => {
          const data: HttpErrorResponse = err;
          this.toastr.error(data?.error?.error?.message, 'Error');
        }
      );
    this.subscription$.push(detailedWrittenDropdown);
  }

  OpenResult() {
    this.isenableFinalResult = true;

    if (this.finalData != null) {
      this.verification = this.finalData.verificationDetails;
      this.disApprove = this.finalData.disapprovedBy;
      this.defaultfaxNo = this.finalData.defaultFaxId;
      this.selectedVerification =
        this.finalData?.verificationDetails[0]?.verificationId;

      this.verification.forEach((element) => {
        if (element.verificationId == this.selectedVerification) {
          this.verificationId = element.verificationId;
          this.FinalForm.patchValue({
            txtInsComments: element.verificationNotes,
          });
        }
      });

      if (
        this.finalData?.checkListDetails?.npiId !== defaultGuid &&
        this.finalData?.checkListDetails?.npiId !== null
      ) {
        this.FinalForm.patchValue({
          drpPhysicianNew:
            this.finalData?.checkListDetails?.npiId +
            '/' +
            this.finalData?.checkListDetails?.npi,
          drpPhysicianPAP:
            this.finalData?.checkListDetails?.npiId +
            '/' +
            this.finalData?.checkListDetails?.npi,
          drpPhysicianReplacement:
            this.finalData?.checkListDetails?.npiId +
            '/' +
            this.finalData?.checkListDetails?.npi,
          drpPhysicianCompliance:
            this.finalData?.checkListDetails?.npiId +
            '/' +
            this.finalData?.checkListDetails?.npi,
          drpPhysicianOxygen:
            this.finalData?.checkListDetails?.npiId +
            '/' +
            this.finalData?.checkListDetails?.npi,
          drpPhysicianNIV:
            this.finalData?.checkListDetails?.npiId +
            '/' +
            this.finalData?.checkListDetails?.npi,
        });
      } else {
        this.FinalForm.patchValue({
          drpPhysicianNew: '',
          drpPhysicianPAP: '',
          drpPhysicianReplacement: '',
          drpPhysicianCompliance: '',
          drpPhysicianOxygen: '',
          drpPhysicianNIV: '',
        });
      }

      this.FinalForm.patchValue({
        txtChart: this.finalData.chartNo,
        txtPatientName: this.finalData.patientName,
        txtComments: '',
        drpdatedetailedWO:
          this.finalData?.checkListDetails?.detailedWrittenOrder,
        drpdatedetailedWOPAP: this.finalData?.checkListDetails?.detailedWrittenOrder,
        drpdatedetailedWOReplacement: this.finalData?.checkListDetails?.detailedWrittenOrder,
        drpdatedetailedWOCompliance:
          this.finalData?.checkListDetails?.detailedWrittenOrder,
        drpdatedetailedWOOxygen: this.finalData?.checkListDetails?.detailedWrittenOrder,
        drpdatedetailedWONIV: this.finalData?.checkListDetails?.detailedWrittenOrder,
        txtAddNotes:this.finalData?.medicareNotes
      });

      const setDateValue = (dateValue: any) => {
        return dateValue ? new Date(dateValue).toISOString().split('T')[0] : '';
      };

      if (this.finalData?.checkListDetails) {
        this.FinalForm.get('txtdatefacetofaceOSA').setValue(
          setDateValue(this.finalData.checkListDetails.faceToFaceEvaluation)
        );
        this.FinalForm.get('txtRXDateNew').setValue(
          setDateValue(this.finalData.checkListDetails.rxDate)
        );
        this.FinalForm.get('txtMedicarecovered').setValue(
          setDateValue(this.finalData.checkListDetails.medicareCoveredSleepTest)
        );

        // PAP Requal
        this.FinalForm.get('txtdatefacetofaceOSAPAP').setValue(
          setDateValue(this.finalData.checkListDetails.faceToFaceEvaluation)
        );
        this.FinalForm.get('txtRXDatePAP').setValue(
          setDateValue(this.finalData.checkListDetails.rxDate)
        );
        this.FinalForm.get('txtMedicarecoveredPAP').setValue(
          setDateValue(this.finalData.checkListDetails.medicareCoveredSleepTest)
        );
        this.FinalForm.get('txtreturnTicketPAP').setValue(
          setDateValue(this.finalData.checkListDetails.returnTicket)
        );

        // Replacement PAP
        this.FinalForm.get('txtRXDateReplacement').setValue(
          setDateValue(this.finalData.checkListDetails.rxDate)
        );
        this.FinalForm.get('txtmedicareReplacement').setValue(
          setDateValue(this.finalData.checkListDetails.medicare)
        );
        this.FinalForm.get('txtFTFnoteReplacement').setValue(
          setDateValue(this.finalData.checkListDetails.FTFnoteReplacement)
        );

        // Compliance Release
        this.FinalForm.get('txtRXDateCompliance').setValue(
          setDateValue(this.finalData.checkListDetails.rxDate)
        );
        this.FinalForm.get('txtmedicareCompliance').setValue(
          setDateValue(this.finalData.checkListDetails.medicareCoveredSleepTest)
        );
        this.FinalForm.get('txtFTFnoteCompliance').setValue(
          setDateValue(this.finalData.checkListDetails.currentFaceToFaceNoteDiscussion)
        );

        // Oxygen Setup
        this.FinalForm.get('txtRXDateOxygen').setValue(
          setDateValue(this.finalData.checkListDetails.rxDate)
        );
        this.FinalForm.get('txtmedicareOxygen').setValue(
          setDateValue(
            this.finalData.checkListDetails.medicareCoveredTestingReport
          )
        );
        this.FinalForm.get('txtclinicalevaluationOxygen').setValue(
          setDateValue(
            this.finalData.checkListDetails.offlineClinicalEvaluatonNotes
          )
        );

        // Non-Invasive Ventilation (NIV)
        this.FinalForm.get('txtRXDateNIV').setValue(
          setDateValue(this.finalData.checkListDetails.rxDate)
        );
        this.FinalForm.get('txtmedicareNIV').setValue(
          setDateValue(this.finalData.checkListDetails.medicareCoveredSleepTest)
        );
        this.FinalForm.get('txtclinicalevaluationNIV').setValue(
          setDateValue(this.finalData.checkListDetails.clinicalevaluation)
        );
      }
    }

    if (this.sortedDetails != null && this.sortedDetails != undefined) {
      this.FillfinalForm();

      const sleepStudies =
        this.sortedDetails.sleepStudies &&
        this.sortedDetails.sleepStudies.length > 0
          ? this.sortedDetails.sleepStudies[0]
          : null;
      const prescriptions =
        this.sortedDetails.prescriptions &&
        this.sortedDetails.prescriptions.length > 0
          ? this.sortedDetails.prescriptions[0]
          : null;

      this.diagnosiscodesleepstudy = sleepStudies
        ? sleepStudies.diagnosisCode
        : null;
      this.diagnosiscodeRX = prescriptions
        ? prescriptions.diagnosticCode
        : null;
    }
  }

  getMMDDYYYY(date) {
    if (date != null) {
      let yyyy = date.slice(0, 4);
      let mm = date.slice(5, 7);
      let dd = date.slice(8, 10);
      return mm + '/' + dd + '/' + yyyy;
    } else {
      return null;
    }
  }

  FillfinalFormVo() {
    if (this.sortedDetails) {
      this.Tabviewform.patchValue({
        /*Sleep study*/
        txtStudyDate: this.getMMDDYYYY(
          this.sortedDetails?.sleepStudies[0]?.sleepStudyDate || null
        ),
        txtPressureSettings: this.sortedDetails.sleepStudies[0].pressureSetting,
        txtREI: this.sortedDetails.sleepStudies[0].rei,
        txtRDI: this.sortedDetails.sleepStudies[0].rdi,
        txtTotalSleep:
          this.sortedDetails.sleepStudies[0].diagnosticSleepStudy
            .totalSleepMins,
        txtAHI: this.sortedDetails.sleepStudies[0].diagnosticSleepStudy.ahi,
        chkDoctorsigned:
          this.sortedDetails.sleepStudies[0].diagnosticSleepStudy
            .isDoctorSigned,
        txtCentralApneas:
          this.sortedDetails.sleepStudies[0].diagnosticSleepStudy.centralApneas,
        txtCentralHyponeas:
          this.sortedDetails.sleepStudies[0].diagnosticSleepStudy
            .centralHyponeas,
        txtTotalApnea:
          this.sortedDetails.sleepStudies[0].diagnosticSleepStudy
            .totalApneaAndHypopnea,
        txtdiagSleepdate: this.getMMDDYYYY(
          this.sortedDetails.sleepStudies[0].diagnosticSleepStudy
            .diagnosticSleepStudyDate
        ),
        txtSleepStudyDate: this.getMMDDYYYY(
          this.sortedDetails.sleepStudies[0].titrationSleepStudy
            .titrationSleepStudyDate
        ),
        txtTitrationAHI:
          this.sortedDetails.sleepStudies[0].titrationSleepStudy.ahi,
        txtTitrationCentralApneas:
          this.sortedDetails.sleepStudies[0].titrationSleepStudy.centralApneas,
        txtTitrationCentralHyponeas:
          this.sortedDetails.sleepStudies[0].titrationSleepStudy
            .centralHyponeas,
        txtTitrationtotalHypopnea:
          this.sortedDetails.sleepStudies[0].titrationSleepStudy
            .totalApneaAndHypopnea,
        chkCPAPTried:
          this.sortedDetails.sleepStudies[0].titrationSleepStudy
            .isCPAPTriedAndFail,
        chkRespbreakdown:
          this.sortedDetails.sleepStudies[0].titrationSleepStudy
            .isRespiratoryBreakdownAvailable,
        chkTitrationDoctorsigned:
          this.sortedDetails.sleepStudies[0].titrationSleepStudy.isDoctorSigned,
        chkBaselinefile:
          this.sortedDetails.sleepStudies[0].titrationSleepStudy
            .isBaselineFileAvailable,
        txtObstructive:
          this.sortedDetails.sleepStudies[0].titrationSleepStudy
            .obstructiveApnea,
        selecteddiagCode: this.sortedDetails.sleepStudies[0].diagnosisCode,
        chkCPAPreturned: this.sortedDetails.sleepStudies[0].isCPAPReturned,
        diagSleepStudy: this.sortedDetails.sleepStudies[0].diagnosisCode,

        /*RX Prescription*/
        txtPrescriptiondate: this.getMMDDYYYY(
          this.sortedDetails.prescriptions[0].prescriptionDate
        ),
        txtPressurelevel: this.sortedDetails.prescriptions[0].pressureLevel,
        txtLengthofneed: this.sortedDetails.prescriptions[0].lengthOfNeed,
        txtNPINumber: this.sortedDetails.prescriptions[0].npi,
        chkRXDoctorsigned: this.sortedDetails.prescriptions[0].isDoctorSigned,
        txtDoctorsigneddate: this.getMMDDYYYY(
          this.sortedDetails.prescriptions[0].doctorSignedDate
        ),
        txtRXdiagCode: this.sortedDetails.prescriptions[0].diagnosticCode[0],
        txtRXDoctorName: this.sortedDetails.prescriptions[0].doctorName,
        txtRXMachineDetails: this.sortedDetails.prescriptions[0].machineDetails,
        drpdiagRx: this.sortedDetails.prescriptions[0].diagnosticCode[0],

        /*Compliance*/
        txtStudyStartdate: this.getMMDDYYYY(
          this.sortedDetails.complainces[0].studyStartDate
        ),
        txtStudyEnddate: this.getMMDDYYYY(
          this.sortedDetails.complainces[0].studyEndDate
        ),
        txtcompPercentage:
          this.sortedDetails.complainces[0].complaincePercentage,
        txtusageDays: this.sortedDetails.complainces[0].usageDays,
        txtOutOf: this.sortedDetails.complainces[0].outOfDays,
        txtGreaterfourhours:
          this.sortedDetails.complainces[0].greaterThanFourHrs,
        txtLessfourhours: this.sortedDetails.complainces[0].lessThanFourHrs,

        /*Face to Face*/
        txtfacetofacedate: this.getMMDDYYYY(
          this.sortedDetails.initialFaceToFaces[0].faceToFaceDate
        ),
        txtDateofvisit: this.getMMDDYYYY(
          this.sortedDetails.initialFaceToFaces[0].dateOfVisit
        ),
        txtSignedDate: this.getMMDDYYYY(
          this.sortedDetails.initialFaceToFaces[0].signedDate
        ),
        txtfaceNPINumber: this.sortedDetails.initialFaceToFaces[0].npi,
        chkPrevioushistory:
          this.sortedDetails.initialFaceToFaces[0].previousHistoryDeclaration,
        chkfaceSleeptest:
          this.sortedDetails.initialFaceToFaces[0].isSleepTestRecommended,
        chkfaceDoctorsigned:
          this.sortedDetails.initialFaceToFaces[0].isDoctorSigned,
        chkfacePatientBenefits:
          this.sortedDetails.initialFaceToFaces[0].isPatientBenifited,
        txtfaceDoctorName: this.sortedDetails.initialFaceToFaces[0].doctorName,
        txtfaceSymptoms: this.sortedDetails.initialFaceToFaces[0].symptoms[0],
      });
    }
  }

  FillfinalForm() {
    if (this.sortedDetails) {
      const sleepStudies =
        this.sortedDetails.sleepStudies &&
        this.sortedDetails.sleepStudies.length > 0
          ? this.sortedDetails.sleepStudies[0]
          : null;
      const prescriptions =
        this.sortedDetails.prescriptions &&
        this.sortedDetails.prescriptions.length > 0
          ? this.sortedDetails.prescriptions[0]
          : null;
      const compliances =
        this.sortedDetails.compliances &&
        this.sortedDetails.compliances.length > 0
          ? this.sortedDetails.compliances[0]
          : null;
      const initialFaceToFaces =
        this.sortedDetails.initialFaceToFaces &&
        this.sortedDetails.initialFaceToFaces.length > 0
          ? this.sortedDetails.initialFaceToFaces[0]
          : null;

      this.Tabviewform.patchValue({
        /* Sleep study */
        txtStudyDate: this.getMMDDYYYY(sleepStudies?.sleepStudyDate || null),
        txtPressureSettings: sleepStudies?.pressureSetting || '',
        txtREI: sleepStudies?.rei || '',
        txtRDI: sleepStudies?.rdi || '',
        txtTotalSleep: sleepStudies?.diagnosticSleepStudy?.totalSleepMins || '',
        txtAHI: sleepStudies?.diagnosticSleepStudy?.ahi || '',
        chkDoctorsigned:
          sleepStudies?.diagnosticSleepStudy?.isDoctorSigned || false,
        txtCentralApneas:
          sleepStudies?.diagnosticSleepStudy?.centralApneas || '',
        txtCentralHyponeas:
          sleepStudies?.diagnosticSleepStudy?.centralHyponeas || '',
        txtTotalApnea:
          sleepStudies?.diagnosticSleepStudy?.totalApneaAndHypopnea || '',
        txtdiagSleepdate: this.getMMDDYYYY(
          sleepStudies?.diagnosticSleepStudy?.diagnosticSleepStudyDate || null
        ),
        txtSleepStudyDate: this.getMMDDYYYY(
          sleepStudies?.titrationSleepStudy?.titrationSleepStudyDate || null
        ),
        txtTitrationAHI: sleepStudies?.titrationSleepStudy?.ahi || '',
        txtTitrationCentralApneas:
          sleepStudies?.titrationSleepStudy?.centralApneas || '',
        txtTitrationCentralHyponeas:
          sleepStudies?.titrationSleepStudy?.centralHyponeas || '',
        txtTitrationtotalHypopnea:
          sleepStudies?.titrationSleepStudy?.totalApneaAndHypopnea || '',
        chkCPAPTried:
          sleepStudies?.titrationSleepStudy?.isCPAPTriedAndFail || false,
        chkRespbreakdown:
          sleepStudies?.titrationSleepStudy?.isRespiratoryBreakdownAvailable ||
          false,
        chkTitrationDoctorsigned:
          sleepStudies?.titrationSleepStudy?.isDoctorSigned || false,
        chkBaselinefile:
          sleepStudies?.titrationSleepStudy?.isBaselineFileAvailable || false,
        txtObstructive:
          sleepStudies?.titrationSleepStudy?.obstructiveApnea || '',
        selecteddiagCode: sleepStudies?.diagnosisCode || '',
        chkCPAPreturned: sleepStudies?.isCPAPReturned || false,
        diagSleepStudy: sleepStudies?.diagnosisCode || '',

        /* RX Prescription */
        txtPrescriptiondate: this.getMMDDYYYY(
          prescriptions?.prescriptionDate || null
        ),
        txtPressurelevel: prescriptions?.pressureLevel || '',
        txtLengthofneed: prescriptions?.lengthOfNeed || '',
        txtNPINumber: prescriptions?.npi || '',
        chkRXDoctorsigned: prescriptions?.isDoctorSigned || false,
        txtDoctorsigneddate: this.getMMDDYYYY(
          prescriptions?.doctorSignedDate || null
        ),
        txtRXdiagCode: prescriptions?.diagnosticCode[0] || '',
        txtRXDoctorName: prescriptions?.doctorName || '',
        txtRXMachineDetails: prescriptions?.machineDetails || '',
        drpdiagRx: prescriptions?.diagnosticCode[0] || '',

        /* Compliance */
        txtStudyStartdate: this.getMMDDYYYY(
          compliances?.studyStartDate || null
        ),
        txtStudyEnddate: this.getMMDDYYYY(compliances?.studyEndDate || null),
        txtcompPercentage: compliances?.complaincePercentage || '',
        txtusageDays: compliances?.usageDays || '',
        txtOutOf: compliances?.outOfDays || '',
        txtGreaterfourhours: compliances?.greaterThanFourHrs || '',
        txtLessfourhours: compliances?.lessThanFourHrs || '',

        /* Face to Face */
        txtfacetofacedate: this.getMMDDYYYY(
          initialFaceToFaces?.faceToFaceDate || null
        ),
        txtDateofvisit: this.getMMDDYYYY(
          initialFaceToFaces?.dateOfVisit || null
        ),
        txtSignedDate: this.getMMDDYYYY(initialFaceToFaces?.signedDate || null),
        txtfaceNPINumber: initialFaceToFaces?.npi || '',
        chkPrevioushistory:
          initialFaceToFaces?.previousHistoryDeclaration || false,
        chkfaceSleeptest: initialFaceToFaces?.isSleepTestRecommended || false,
        chkfaceDoctorsigned: initialFaceToFaces?.isDoctorSigned || false,
        chkfacePatientBenefits: initialFaceToFaces?.isPatientBenifited || false,
        txtfaceDoctorName: initialFaceToFaces?.doctorName || '',
        txtfaceSymptoms: initialFaceToFaces?.symptoms[0] || '',
      });
    }
  }

  //Formated Date for SAve Final Form
  getFormattedDate(date: string): string {
    if (!date) return null;

    const formattedDate = new Date(date);
    const year = formattedDate.getFullYear();
    const month = (formattedDate.getMonth() + 1).toString().padStart(2, '0');
    const day = formattedDate.getDate().toString().padStart(2, '0');

    return `${year}-${month}-${day}`;
  }

  //#endregion

  //Save Final Form Medicare
  Savefinalform() {
    this.isShowSpinner = true;
    this.isSave = true;

    if (this.FinalForm.controls['drpVerification'].value == '') {
      this.toastr.error('Please select the verification details.');
      return;
    }
    if (
      this.FinalForm.controls['rbnapprove'].value == '2' &&
      this.disapproveStatus == true &&
      (this.FinalForm.controls['drpDisapprove'].value == '' ||
        this.FinalForm.controls['drpDisapprove'].value == undefined ||
        this.FinalForm.controls['drpDisapprove'].value == null)
    ) {
      this.toastr.error('Please select the disapproval reason.');
      this.isShowSpinner = false;
      this.isSave = false;
      return;
    }


    let nNewSetup: NewSetup=null;
    if(this.checkListName.toLowerCase() == 'new setup'){
      const faceTofaceDatetxtNew = this.getFormattedDate(
        this.FinalForm.value.txtdatefacetofaceOSA
      );
      const medicareDatetxtNew = this.getFormattedDate(
        this.FinalForm.value.txtMedicarecovered
      );
      const rxDatetxtNew = this.getFormattedDate(
        this.FinalForm.value.txtRXDateNew
      );
      let npi = null;
      if(this.FinalForm.value.drpPhysicianNew){
        const index = this.physicianDrpdwn.findIndex(
          (obj) => obj.id === this.FinalForm.value.drpPhysicianNew
        );
        npi = this.physicianDrpdwn[index].doctorName || null;
      }
      nNewSetup={
        checklistName: this.checkListName,
        faceToFaceEvaluation: faceTofaceDatetxtNew,
        medicareCoveredSleepTest: medicareDatetxtNew,
        rxDate: rxDatetxtNew,
        detailedWrittenOrder: this.FinalForm.controls['drpdatedetailedWO'].value || defaultGuid,
        npi: npi,
        npiId: this.FinalForm.value.drpPhysicianNew || defaultGuid,
        faceToFaceImg: [],
        medicareCoveredSleepTestImg: [],
        rxImg: [],
        faceToFacePageNo: this.cpaptoBilevelF2F,
        medicareCoveredSleepTestPageNo: this.cpaptoBilevelMCST,
        dwoPageNo: this.cpaptoBilevelDWO,
      }
    }
    let ncpaptoBiLevel: CPAPtoBiLevel=null;
    if (
      this.checkListName.toLowerCase() == 'cpap to bilevel' || this.checkListName.toLowerCase() == 'transfer pap') {
      const faceTofaceDatetxtNew = this.getFormattedDate(
        this.FinalForm.value.txtdatefacetofaceOSA
      );
      const medicareDatetxtNew = this.getFormattedDate(
        this.FinalForm.value.txtMedicarecovered
      );
      const rxDatetxtNew = this.getFormattedDate(
        this.FinalForm.value.txtRXDateNew
      );
      const txtMedicarePickupNew = this.getFormattedDate(
        this.FinalForm.value.txtMedicarePickup
      );
      let npi = null;
      if(this.FinalForm.value.drpPhysicianNew){
        const index = this.physicianDrpdwn.findIndex(
          (obj) => obj.id === this.FinalForm.value.drpPhysicianNew
        );
        npi = this.physicianDrpdwn[index].doctorName || null;
      }

      ncpaptoBiLevel={
        checklistName: this.checkListName,
        faceToFaceEvaluation: faceTofaceDatetxtNew,
        medicareCoveredSleepTest: medicareDatetxtNew,
        rxDate: rxDatetxtNew,
        pickUpTicket: txtMedicarePickupNew,
        detailedWrittenOrder: this.FinalForm.controls['drpdatedetailedWO'].value || defaultGuid,
        npi: npi,
        npiId: this.FinalForm.value.drpPhysicianNew || defaultGuid,
        faceToFaceImg: [],
        medicareCoveredSleepTestImg: [],
        pickUpTicketImg: [],
        rxImg: [],
        faceToFacePageNo: this.cpaptoBilevelF2F,
        medicareCoveredSleepTestPageNo: this.cpaptoBilevelMCST,
        pickUpTicketPageNo: this.cpaptoBilevelPT,
        dwoPageNo: this.cpaptoBilevelDWO,
      };
    }

    let nReplacementPAP: ReplacementPAP = null;
    if (this.checkListName.toLowerCase() == 'replacement pap') {

      const medicareDatetxtReplacement = this.getFormattedDate(
        this.FinalForm.value.txtmedicareReplacement
      );
      const rxDatetxtReplacement = this.getFormattedDate(
        this.FinalForm.value.txtRXDateReplacement
      );
      const faceTofaceDatetxtReplacement = this.getFormattedDate(
        this.FinalForm.value.txtFTFnoteReplacement
      );
      let rpapnpi =null;
      if(this.FinalForm.value.drpPhysicianReplacement){
        const rpapindex = this.physicianDrpdwn.findIndex(
          (obj) => obj.id === this.FinalForm.value.drpPhysicianReplacement
        );
        rpapnpi = this.physicianDrpdwn[rpapindex].doctorName || null;
      }

      nReplacementPAP= {
        checklistName: this.checkListName,
        medicareCoveredSleepTest: medicareDatetxtReplacement,
        rxDate: rxDatetxtReplacement,
        ftFnote: faceTofaceDatetxtReplacement,
        detailedWrittenOrder:
          this.FinalForm.controls['drpdatedetailedWOReplacement'].value,
        npi: rpapnpi,
        npiId: this.FinalForm.value.drpPhysicianReplacement || defaultGuid,
        medicareCoveredSleepTestImg: [],
        rxImg: [],
        ftfNoteImg: [],
        medicareCoveredSleepTestPageNo: this.rPAPMCST,
        dwoPageNo: this.rPAPDWO,
        ftfNotePageNo: this.rPAPFTF,
      };


    }

    let npapRequal: PAPRequal = null;
    if (
      this.checkListName.toLowerCase() == 'pap requal. (failed 90 day compliance)') {
      const faceTofaceDatetxtPap = this.getFormattedDate(
        this.FinalForm.value.txtdatefacetofaceOSAPAP
      );
      const medicareDatetxtPap = this.getFormattedDate(
        this.FinalForm.value.txtMedicarecoveredPAP
      );
      const rxDatetxtPap = this.getFormattedDate(
        this.FinalForm.value.txtRXDatePAP
      );
      const returnticketDatetxtPap = this.getFormattedDate(
        this.FinalForm.value.txtreturnTicketPAP
      );
      let papnpi = null;
      if(this.FinalForm.value.drpPhysicianPAP){
        const papindex = this.physicianDrpdwn.findIndex(
          (obj) => obj.id === this.FinalForm.value.drpPhysicianPAP
        );
         papnpi = this.physicianDrpdwn[papindex].doctorName || null;
      }


      npapRequal={
        checklistName: this.checkListName,
        faceToFaceEvaluation: faceTofaceDatetxtPap,
        medicareCoveredSleepTest: medicareDatetxtPap,
        rxDate: rxDatetxtPap,
        returnTicket: returnticketDatetxtPap,
        detailedWrittenOrder:
          this.FinalForm.controls['drpdatedetailedWOPAP'].value,
        npi: papnpi,
        npiId: this.FinalForm.value.drpPhysicianPAP || defaultGuid,
        faceToFaceImg: [],
        medicareCoveredSleepTestImg: [],
        returnTicketImg: [],
        rxImg: [],
        faceToFacePageNo: this.papRequalF2f,
        medicareCoveredSleepTestPageNo: this.papRequalMCST,
        returnTicketPageNo: this.papRequalRT,
        dwoPageNo: this.papRequalDWO,
      };

    }



    let nOxygenSetup : OxygenSetup = null;
    if (this.checkListName.toLowerCase() == 'oxygen setup') {
         const faceTofaceDatetxtOxygen = this.getFormattedDate(
        this.FinalForm.value.txtclinicalevaluationOxygen
      );

         const medicareDatetxtOxygen = this.getFormattedDate(
        this.FinalForm.value.txtmedicareOxygen
      );
      const rxDatetxtOxygen = this.getFormattedDate(
        this.FinalForm.value.txtRXDateOxygen
      );
      let osnpi = null;
      if( this.FinalForm.value.drpPhysicianOxygen){
        const osindex = this.physicianDrpdwn.findIndex(
          (obj) => obj.id === this.FinalForm.value.drpPhysicianOxygen
        );
        osnpi = this.physicianDrpdwn[osindex].doctorName || null;
      }


      nOxygenSetup={
        checklistName: this.checkListName,
        offlineClinicalEvaluatonNotes: faceTofaceDatetxtOxygen,
        medicareCoveredTestingReport: medicareDatetxtOxygen,
        rxDate: rxDatetxtOxygen,
        detailedWrittenOrder:this.FinalForm.controls['drpdatedetailedWOOxygen'].value || defaultGuid,
        npi:osnpi,
        npiId: this.FinalForm.value.drpPhysicianOxygen || defaultGuid,
        offlineClinicalNoteImg: [],
        medicareCoveredTestingReportImg: [],
        rxImg: [],
        offlineClinicalNotePageNo: this.osOCEN,
        medicareCoveredTestingReportPageNo: this.osMCST,
        dwoPageNo: this.osDWO,
      }
    }

    let nComplainceRelease : ComplainceRelease=null;
    if (this.checkListName.toLowerCase() == 'compliance release' || this.checkListName.toLowerCase() == 'supplies') {

        const faceTofaceDatetxtPap = this.getFormattedDate(
        this.FinalForm.value.txtFTFnoteCompliance
      );
      const medicareDatetxtPap = this.getFormattedDate(
        this.FinalForm.value.txtmedicareCompliance
      );
      const rxDatetxtPap = this.getFormattedDate(
        this.FinalForm.value.txtRXDateCompliance
      );

      let csnpi = null;
      if( this.FinalForm.value.drpPhysicianCompliance){
        const osindex = this.physicianDrpdwn.findIndex(
          (obj) => obj.id === this.FinalForm.value.drpPhysicianCompliance
        );
        csnpi = this.physicianDrpdwn[osindex].doctorName || null;
      }

      nComplainceRelease={
        checklistName: this.checkListName,
        currentFaceToFaceNoteDiscussion: faceTofaceDatetxtPap,
        medicareCoveredSleepTest: medicareDatetxtPap,
        rxDate: rxDatetxtPap,
        detailedWrittenOrder: this.FinalForm.controls['drpdatedetailedWOCompliance'].value || defaultGuid,
        npi:csnpi,
        npiId: this.FinalForm.value.drpPhysicianCompliance || defaultGuid,
        faceToFaceImg: [],
        complainceDLImg: [],
        sleepTestImg: [],
        followUpFaceToFaceImg: [],
        rxImg: [],
        faceToFacePageNo: this.crF2F,
        complainceDLPageNo: [],
        sleepTestPageNo: [],
        followUpFaceToFacePageNo: this.crF2F,
        dwoPageNo: this.crDWO
      }
    }


    let nNonInvasionVentilation: NonInvasionVentilation=null;

    if (this.checkListName.toLowerCase() == 'non-invasive ventilation (niv)') {
      const faceTofaceDatetxtNiv = this.getFormattedDate(
        this.FinalForm.value.txtclinicalevaluationNIV
      );

      const medicareDatetxtNiv = this.getFormattedDate(
        this.FinalForm.value.txtmedicareNIV
      );
      const rxDatetxtNiv = this.getFormattedDate(
        this.FinalForm.value.txtRXDateNIV
      );

      let nivnpi=null;
      if(this.FinalForm.value.drpPhysicianNIV){
        const nivindex = this.physicianDrpdwn.findIndex(
          (obj) => obj.id === this.FinalForm.value.drpPhysicianNIV
        );
        nivnpi = this.physicianDrpdwn[nivindex].doctorName || null;
      }


      nNonInvasionVentilation = {
        checklistName: this.checkListName,
        faceToFaceEvaluation: faceTofaceDatetxtNiv,
        medicareTestingReport: medicareDatetxtNiv,
        detailedWrittenOrder:
          this.FinalForm.controls['drpdatedetailedWONIV'].value || defaultGuid,
        rxDate: rxDatetxtNiv,
        npi: nivnpi,
        npiId: this.FinalForm.value.drpPhysicianNIV || defaultGuid,  // this.FinalForm.value.drpPhysicianNIV.split('/')[0] || defaultGuid,
        rxImg: [],
        medicareTestingImg: [],
        faceToFaceImg: [],
        dwoPageNo: this.nivDWO,
        medicareTestingPageNo: this.nivMCST,
        faceToFacePageNo: this.nivF2F,
      };
    }

    const preparedDatetxt = this.getFormattedDate(
      this.FinalForm.value.txtPreparedDate
    );

    let disapprovedstatus = this.FinalForm.controls['rbnapprove'].value;
    let input: finalSaveOutput = {
      checkListId: this.checklistID,
      patientId: this.patientID,
      verificationId: this.FinalForm.value.drpVerification,
      newSetup: nNewSetup,
      cpaptoBiLevel: ncpaptoBiLevel,
      papRequal: npapRequal,
      replacementPAP: nReplacementPAP,

      oxygenSetup: nOxygenSetup,
      complainceRelease: nComplainceRelease,
      nonInvasionVentilation: nNonInvasionVentilation,

      preparedDate: preparedDatetxt,
      preparedBy: localStorage.getItem('userId'),
      medicareChecklistId: this.medicarecheckListID,
      isGrandfathered: this.FinalForm.value.txtgrandFathered || null,
      documentId: this.docID,
      disapprovedBy: this.FinalForm?.controls['drpDisapprove']?.value || null,
      disapprovedNotes: this.FinalForm.value.txtDisapproveNotes || null,
      statusOfCheckList: disapprovedstatus || null,
      medicareNotes: this.FinalForm.controls['txtAddNotes'].value,
      verificationNotes: this.FinalForm.controls['txtInsComments'].value,
    };

    this.patientmedicareService
      .updatePatientMedicareDetailsByIdAndInput(this.medicarecheckListID, input)
      .subscribe(
        (response) => {
          //this.router.navigate(['/dashboard']);
          if(disapprovedstatus === '1'){
            this.toastr.success('Medicare has been approved successfully');
          }else if(disapprovedstatus === '2'){
            this.toastr.success('Medicare has been disapproved successfully !');
          }else{
            this.toastr.success('Medicare saved as Pending successfully !');
          }

          localStorage.removeItem('checkNewTabData');
          localStorage.setItem('checkNewTabData', 'NewTabDataSaved');
          // this.communicationService.triggerFunctionCall(this.selectedNode);
          // this.reloadTableEvent.emit();
          // this.selectNode.emit({ node: this.selectedNode });
          // window.close();
          // setTimeout(() => {
            // this.retrieveAndSetTenantId().then(resolve).catch(reject);
            // window.close();
            this.isShowSpinner = false;
            this.isSave = false;
          // }, 2000);
        },
        (err) => {
          this.isShowSpinner = false;
          this.isSave = false;
          const data: HttpErrorResponse = err;
          const message = JSON.parse(data?.error);
          Swal.fire({
            icon: 'info',
            text: message?.error?.message,
          });
        }
      );
  }

  //To change the tab selection
  changeTab(tab: string) {
    this.toggle();
    this.activeTab = tab;
    //let documents = <HTMLButtonElement>document.getElementById('documents');
    // documents.classList.remove('active');
  }
  check_length() {
    let len = this.Questions?.length || 0;
    if (len > 1) {
      this.back_2_text = 'Back';
    } else {
      this.back_2_text = this.BACK_TO_MAIN_QUESTION;
    }
  }

  OpenNextSet(selectedOption: any, question: any) {
    this.loadQuestion$?.unsubscribe();
    let selectedItem = selectedOption == 'notnow' ? null : selectedOption;
    this.Prequestionaryselected =
      this.preQuestionaireform.controls['rbtnReportType'].value;
    this.isLoadingQuestion = true;
    if (
      this.Prequestionaryselected == '' ||
      this.Prequestionaryselected == undefined ||
      this.Prequestionaryselected == null
    ) {
      this.toastr.error('Please select any option to proceed next.');
      return;
    }
    this.isPreQuestionaire = false;
    this.checklistID =
      this.preQuestionaireform.controls['rbtnReportType'].value.id;
    this.selectedPrequestionaire = this.Prequestionaryselected.checklist;

    const existingItemIndex = this.Questions.findIndex(
      (item) => item.questionCode === question.questionCode
    );

    if (existingItemIndex === -1) {
    } else {
      if (this.Questions[existingItemIndex].selectedItem === '') {
      } else {
        this.Questions.splice(existingItemIndex + 1, 1);
        this.questionCode = question.questionCode;
      }

      this.Questions[existingItemIndex].selectedItem = selectedItem;
    }

    const getQuestions = this.medicareService
      .getmedicareQuestions(
        this.docID,
        this.patientID,
        this.checklistID,
        this.medicarecheckListID,
        this.questionCode,
        selectedItem
      )
      .subscribe(
        (response) => {
          this.isSorting = response?.finalData?.isSorted;
          this.isLoadingQuestion = false;
          if (response.questions != null) {
            let len = this.Questions?.length || 0;
            if (len > 1) {
              this.Questions[len - 2].isedited = true;
            }
            this.check_length();
            this.Questions.push({
              question: response.questions.questions,
              questionCode: response.questions.questionCode,
              options: response.questions.optionType,
              isedited: false,
              selectedItem: '',
            });
            this.questionCode = response.questions.questionCode;
            this.sortedDetails = response.sortedDetails;
            this.medicarecheckListID = response.medicareChecklistId;
            if (
              this.sortedDetails.complainces != null ||
              this.sortedDetails.initialFaceToFaces != undefined ||
              this.sortedDetails.prescriptions != null ||
              this.sortedDetails.sleepStudies != undefined
            ) {
              this.diagnosiscodesleepstudy =
                this.sortedDetails.sleepStudies[0].diagnosisCode;
              this.diagnosiscodeRX =
                this.sortedDetails.prescriptions[0].diagnosticCode;
              this.FillfinalForm();
            }
          } else {
            let len = this.Questions?.length || 0;
            if (len > 1) {
              this.Questions[len - 2].isedited = true;
            }
            this.isLoadingQuestion = false;
            this.isenableFinish = true;
            this.finalData = response.finalData;
            this.sortedDetails = response.sortedDetails;
            this.medicarecheckListID = response.medicareChecklistId;
            this.checkListDetails = response.finalData.checkListDetails;
            this.checkListName = this.checkListDetails?.checklistName;
            this.OpenResult();
            if (
              this.sortedDetails.complainces != null ||
              this.sortedDetails.initialFaceToFaces != undefined ||
              this.sortedDetails.prescriptions != null ||
              this.sortedDetails.sleepStudies != undefined
            ) {
              this.diagnosiscodesleepstudy =
                this.sortedDetails.sleepStudies[0].diagnosisCode;
              this.diagnosiscodeRX =
                this.sortedDetails.prescriptions[0].diagnosticCode;
              this.FillfinalForm();
            }
          }
        },
        (err) => {
          this.isLoadingQuestion = false;
          const data: HttpErrorResponse = err;
          Swal.fire({
            icon: 'info',
            text: data?.error?.error?.message,
          });
        }
      );

    this.loadQuestion$ = getQuestions;
  }

  Goback(flag: number = 0) {
    if (flag === 3) {
      this.resetFinalForm();
      this.isenableFinalResult = false;

      if (this.Questions.length === 0) {
        this.isPreQuestionaire = true;
      }

      if (this.isPreQuestionaire) {
        this.questionCode = '';
      }
      return;
    }
    this.Questions.pop();
    if (this.back_2_text === this.BACK_TO_MAIN_QUESTION) {
      this.isPreQuestionaire = true;
      this.Questions = [];
    }
    this.check_length();
    let lt = this.Questions.length - 1;
    if (lt >= 0) {
      this.questionCode = this.Questions[lt]?.questionCode || '';
      this.Questions[lt].isedited = false;
    }

    if (this.isPreQuestionaire) {
      this.questionCode = '';
    }
  }
  GotoQuestionform() {
    if (this.Questions.length > 0) {
      this.isPreQuestionaire = false;
      this.isenableFinalResult = false;
    } else {
      this.isPreQuestionaire = true;
      this.isenableFinalResult = false;
    }
  }

  onVerificationselect() {
    this.verification.forEach((element) => {
      if (element.verificationId == this.FinalForm.value.drpVerification) {
        this.verificationId = element.verificationId;
        this.FinalForm.patchValue({
          txtInsComments: element.verificationNotes,
        });
      }
    });
  }

  drpDisapproveselected() {
    this.disapproveCheckbox.checked = true;
    this.clearApprovevalidators();
    this.disapproveStatus = true;
    this.FinalForm.get('txtDisapproveNotes').reset();
    this.selectedValue='2';
    this.FinalForm.patchValue({
      rbnapprove: '2',
    });
    const disapprovedId = this.FinalForm.value.drpDisapprove;
    if (disapprovedId == 'Other') {
      this.isDisapproveNotes = true;
      this.isDisapprove = true;
      this.getDisapproveValidators();
    } else {
      this.isDisapproveNotes = false;
      this.isDisapprove = false;
      this.clearDisapprovevalidators();
    }
  }

  clearDisapproveReasonvalidators() {
    this.isDispproveReason=false;
    this.FinalForm.controls['drpDisapprove'].clearValidators();
    this.FinalForm.controls['drpDisapprove'].updateValueAndValidity();
  }

  getDisapproveReasonValidators(){
    this.isDispproveReason=true;
    this.FinalForm.get('drpDisapprove').setValidators([
      Validators.required,
    ]);
    this.FinalForm.get('drpDisapprove').updateValueAndValidity();
  }

  // onApprove(value: string) {
  //   console.log("value",value);
  //   this.selectedValue = value === this.selectedValue ? '' : value;
  //   console.log("Selected Value",this.selectedValue);
  //   // Update form control value
  //   this.FinalForm.patchValue({ rbnapprove: this.selectedValue });
  //   if (this.selectedValue === '1') {
  //     console.log("1^st");
  //     this.FinalForm.value.drpDisapprove = '';
  //     this.disapproveReason = '';
  //     this.disapproveStatus = false;
  //     this.isDisapproveNotes = false;
  //     this.FinalForm.value.txtDisapproveNotes = '';
  //     this.getApproveValidators();
  //     this.clearDisapprovevalidators();
  //     this.clearDisapproveReasonvalidators();
  //     // this.isDisapprove=false;
  //   } else if (this.selectedValue === '2') {
  //     console.log("2^nd");
  //     this.disapproveStatus = true;
  //     this.getDisapproveReasonValidators();
  //     this.clearApprovevalidators();
  //   //  if (this.disapproveCheckbox.checked === false && this.approveCheckbox.checked === false) {
  //   //     console.log("2.1^nd");
  //   //     this.FinalForm.patchValue({ rbnapprove: null });
  //   //   }
  //     // this.isDisapprove=true;
  //   } else if (this.selectedValue === '' || this.selectedValue === null) {
  //     console.log("3^rd");
  //     this.approveCheckbox.checked = false;
  //     // this.disapproveCheckbox.checked = false;
  //     this.clearApprovevalidators();
  //     this.FinalForm.value.drpDisapprove = '';
  //     this.disapproveReason = '';
  //     this.disapproveStatus = false;
  //     this.isDisapproveNotes = false;
  //     this.clearDisapprovevalidators();
  //     this.clearDisapproveReasonvalidators();
  //     this.FinalForm.patchValue({ rbnapprove: null });
  //   } else {
  //     console.log("4^th");
  //     this.approveCheckbox.checked = false;
  //     this.disapproveCheckbox.checked = false;
  //     this.clearApprovevalidators();
  //     this.FinalForm.value.drpDisapprove = '';
  //     this.disapproveReason = '';
  //     this.disapproveStatus = false;
  //     this.isDisapproveNotes = false;
  //     this.clearDisapprovevalidators();
  //     this.clearDisapproveReasonvalidators();
  //     this.FinalForm.patchValue({ rbnapprove: null });
  //   }

  //   if (this.disapproveCheckbox.checked === false) {
  //     this.FinalForm.value.drpDisapprove = '';
  //     this.disapproveReason = '';
  //     this.disapproveStatus = false;
  //     this.isDisapproveNotes = false;
  //     this.clearDisapprovevalidators();
  //     this.clearDisapproveReasonvalidators();
  //   }
  //   // if (this.disapproveCheckbox.checked === false && this.approveCheckbox.checked === false) {
  //   //   this.FinalForm.patchValue({ rbnapprove: null });
  //   // }
  // }
  onApprove(value: string) {
    this.selectedValue = value === this.selectedValue ? '' : value;
    // Update form control value
    this.FinalForm.patchValue({ rbnapprove: this.selectedValue });
    if (this.selectedValue === '1') {
      this.FinalForm.patchValue({ drpDisapprove: '' });
      this.disapproveReason = '';
      this.disapproveStatus = false;
      this.isDisapproveNotes = false;
      this.FinalForm.patchValue({ txtDisapproveNotes: '' });
      this.getApproveValidators();
      this.clearDisapprovevalidators();
      this.clearDisapproveReasonvalidators();
    } else if (this.selectedValue === '2') {
      this.disapproveStatus = true;
      this.getDisapproveReasonValidators();
      this.clearApprovevalidators();
    } else if (this.selectedValue === '' || this.selectedValue === null) {
      this.approveCheckbox.checked = false;
      this.disapproveCheckbox.checked = false;
      this.clearApprovevalidators();
      this.FinalForm.patchValue({ drpDisapprove: '' });
      this.disapproveReason = '';
      this.disapproveStatus = false;
      this.isDisapproveNotes = false;
      this.clearDisapprovevalidators();
      this.clearDisapproveReasonvalidators();
      this.FinalForm.patchValue({ rbnapprove: null });
    } else {
      this.approveCheckbox.checked = false;
      this.disapproveCheckbox.checked = false;
      this.clearApprovevalidators();
      this.FinalForm.patchValue({ drpDisapprove: '' });
      this.disapproveReason = '';
      this.disapproveStatus = false;
      this.isDisapproveNotes = false;
      this.clearDisapprovevalidators();
      this.clearDisapproveReasonvalidators();
      this.FinalForm.patchValue({ rbnapprove: null });
    }

    if (!this.disapproveCheckbox.checked) {
      this.FinalForm.patchValue({ drpDisapprove: '' });
      this.disapproveReason = '';
      this.disapproveStatus = false;
      this.isDisapproveNotes = false;
      this.clearDisapprovevalidators();
      this.clearDisapproveReasonvalidators();
    }
    // if (!this.disapproveCheckbox.checked && !this.approveCheckbox.checked) {
    //   this.FinalForm.patchValue({ rbnapprove: null });
    // }
  }

  isSelected(value: string): boolean {
    return this.FinalForm.value.rbnapprove === value;
  }


  //#region Fax Preview
  loadInboundDocument(from: number, to: number) {
    this.isLoading = true;
    let pageNo = 1;
    if (this.loadInboundDocumentAPICall$) {
      this.loadInboundDocumentAPICall$?.unsubscribe();
    }
    const loadInboundDocument = this.inboundDocumentService
      .getTIFFImageBase64v1ByGIdAndFromAndTo(this.docID, this.from, 1)
      .subscribe(
        (response) => {
          this.totalPages = response?.files?.length || 0;
          this.overAllPagesBG = response?.totalPagecount || 0;
          this.overAllPages = response?.totalPagecount || 0;

          if (response && this.totalPages > 0) {
            this.TotalDocumentImageList = response.files.map((a) => {
              return {
                ...a,
                src: this.sanitizer.bypassSecurityTrustResourceUrl(
                  `data:image/tiff;base64, ${a.file}`
                ),
                pageNo: a.pageNo,
              };
            });
            this.selectedExistingDocument = this.TotalDocumentImageList?.[0];
            this.existingDocumentImageList = this.TotalDocumentImageList;
            this.loadTiffImageV1(this.existingDocumentImageList?.[0]);
            this.loadInboundDocumentLoadMoreInitial(this.from, 1);
          }
          this.isLoading = false;
          this.TiffError = false;
          this.SortSaveError = false;
        },
        (err) => {
          this.handleErrorResponse(err);
          if (this.isHideTiffViewer && !this.isEmpty(this.blobName)) {
            console.error('err', err);
            this.loadInboundDocumentPDF();
          } else {
            console.error('File name  = ' + this.blobName);
          }
        }
      );
    this.loadInboundDocumentAPICall$ = loadInboundDocument;
  }

   //Intialy to Load If less than 10 Tiff Images
   loadInboundDocumentLoadMoreInitial(from: number, to: number) {
    this.isTotalAPIcallGoingOn = true;
      this.from = to + 1;
      this.to = to + this.DEFAULT_LAZY_LOADING;
      this.loadMoreTiffImage();
  }

  loadInboundDocumentLoadMore(from: number, to: number) {
    this.isTotalAPIcallGoingOn = true;
    if (this.overAllPages >= this.to) {
      this.from = to + 1;
      this.to = to + this.DEFAULT_LAZY_LOADING;
      this.loadMoreTiffImage();
    } else {
      // this.checkTotalAPICallCompletion();
    }
  }

  private handleErrorResponse(err: any) {
    const data: HttpErrorResponse = err;
    console.warn(data?.error?.error?.message);
    this.loadingMessage = 'TIFF Image conversion has been In Progress.';
    this.TiffError = true;
    this.SortSaveError = true;
    this.isLoading = false;
    this.isHideTiffViewer = true;
  }

  loadMoreTiffImage(){
    if (this.loadInboundDocumentAPICall$) {
      this.loadInboundDocumentAPICall$?.unsubscribe();
    }
    const loadInboundDocument = this.inboundDocumentService
      .getTIFFImageBase64v1ByGIdAndFromAndTo(this.docID, this.from, this.to)
      .subscribe(
        (response) => {
          let totalPagesLoadMore = response.files.length;
          if (response && totalPagesLoadMore > 0) {
            // Assuming response is an array of items to be pushed into the list
            response.files.forEach((item) => {
              const newItem = {
                ...item,
                src: this.sanitizer.bypassSecurityTrustResourceUrl(
                  `data:image/tiff;base64, ${item.file}`
                ),
                pageNo: item.pageNo,
              };
              this.TotalDocumentImageList.push(newItem);
              item = null;
            });
            this.overAllPages = this.TotalDocumentImageList.length;
            if (this.selectedOption === this.sotingSelectedCSS.Total) {
              this.totalPages = this.TotalDocumentImageList.length;
              this.existingDocumentImageList = this.TotalDocumentImageList;
            }
          }
          this.loadInboundDocumentLoadMore(this.from, this.to);
        },
        (err) => {
          // this.handleErrorResponse(err);
        }
      );
    this.loadInboundDocumentAPICall$ = loadInboundDocument;
  }

  loadInboundDocumentPDF() {
    this.showPdfViwer = false;
    this.inboundDocumentService
      // .downloadInboundDocumentByBlobName(this.data.blobName)
      .downloadInboundDocumentByBlobName(this.blobName)
      .subscribe(
        (response) => {
          if (
            typeof response === 'string' &&
            response &&
            response !== null &&
            response?.length !== 0
          ) {
            this.strPdfString = String(response);
          }
          this.showPdfViwer = true;
        },
        (err) => {
          this.strSelectedPdfPath = '';
          this.showPdfViwer = false;

          const data: HttpErrorResponse = err;
          // Swal.fire({
          //   icon: 'info',
          //   text: data?.error?.error?.message,
          // });
        }
      );
  }
  //#endregion -- End Fax Preview

  //#region
  toggle() {
    this.SortingpanelOpenState = true;
    document
      .getElementById('scrollbottom')
      .scrollIntoView({ behavior: 'auto' });
  }
  toggleV2() {
    this.SortingpanelOpenState = false;
  }

  getDisapproveValidators() {
    this.FinalForm.get('txtDisapproveNotes').setValidators([
      Validators.required,noWhitespaceValidator
    ]);
    this.FinalForm.get('txtDisapproveNotes').updateValueAndValidity();
  }
  clearDisapprovevalidators() {
    this.FinalForm.controls['txtDisapproveNotes'].clearValidators();
    this.FinalForm.controls['txtDisapproveNotes'].updateValueAndValidity();
  }

  getApproveValidators() {
    if (this.checkListName == 'CPAP to BiLevel' || this.checkListName == 'New Setup' || this.checkListName == 'Transfer PAP') {
      //New setup or Transfer PAP or CPAP to Bilevel
      this.FinalForm.get('txtdatefacetofaceOSA').setValidators([
        Validators.required,
      ]);
      this.FinalForm.get('txtdatefacetofaceOSA').updateValueAndValidity();

      this.FinalForm.get('txtRXDateNew').setValidators([Validators.required]);
      this.FinalForm.get('txtRXDateNew').updateValueAndValidity();

      this.FinalForm.get('drpdatedetailedWO').setValidators([
        Validators.required,
      ]);
      this.FinalForm.get('drpdatedetailedWO').updateValueAndValidity();

      this.FinalForm.get('txtMedicarecovered').setValidators([
        Validators.required,
      ]);
      this.FinalForm.get('txtMedicarecovered').updateValueAndValidity();

      this.FinalForm.get('drpPhysicianNew').setValidators([
        Validators.required,
      ]);
      this.FinalForm.get('drpPhysicianNew').updateValueAndValidity();

      if(this.checkListName !== 'New Setup'){
        this.FinalForm.get('txtMedicarePickup').setValidators([
          Validators.required,
        ]);
        this.FinalForm.get('txtMedicarePickup').updateValueAndValidity();
      }
    } else if (this.checkListName == 'PAP Requal. (failed 90 day compliance)') {
      // <!--PAP Requal-->
      this.FinalForm.get('txtdatefacetofaceOSAPAP').setValidators([
        Validators.required,
      ]);
      this.FinalForm.get('txtdatefacetofaceOSAPAP').updateValueAndValidity();

      this.FinalForm.get('txtRXDatePAP').setValidators([Validators.required]);
      this.FinalForm.get('txtRXDatePAP').updateValueAndValidity();

      this.FinalForm.get('drpdatedetailedWOPAP').setValidators([
        Validators.required,
      ]);
      this.FinalForm.get('drpdatedetailedWOPAP').updateValueAndValidity();

      this.FinalForm.get('txtMedicarecoveredPAP').setValidators([
        Validators.required,
      ]);
      this.FinalForm.get('txtMedicarecoveredPAP').updateValueAndValidity();

      this.FinalForm.get('txtreturnTicketPAP').setValidators([
        Validators.required,
      ]);
      this.FinalForm.get('txtreturnTicketPAP').updateValueAndValidity();

      this.FinalForm.get('drpPhysicianPAP').setValidators([
        Validators.required,
      ]);
      this.FinalForm.get('drpPhysicianPAP').updateValueAndValidity();
    } else if (this.checkListName == 'Replacement PAP') {
      // <!--Replacement PAP-->

      this.FinalForm.get('txtmedicareReplacement').setValidators([
        Validators.required,
      ]);
      this.FinalForm.get('txtmedicareReplacement').updateValueAndValidity();

      this.FinalForm.get('drpdatedetailedWOReplacement').setValidators([
        Validators.required,
      ]);
      this.FinalForm.get(
        'drpdatedetailedWOReplacement'
      ).updateValueAndValidity();

      this.FinalForm.get('txtFTFnoteReplacement').setValidators([
        Validators.required,
      ]);
      this.FinalForm.get('txtFTFnoteReplacement').updateValueAndValidity();

      this.FinalForm.get('txtRXDateReplacement').setValidators([
        Validators.required,
      ]);
      this.FinalForm.get('txtRXDateReplacement').updateValueAndValidity();

      this.FinalForm.get('drpPhysicianReplacement').setValidators([
        Validators.required,
      ]);
      this.FinalForm.get('drpPhysicianReplacement').updateValueAndValidity();
    } else if (this.checkListName == 'Compliance Release' || this.checkListName == 'Supplies') {
      // <!--Compliance Release-->

      this.FinalForm.get('txtFTFnoteCompliance').setValidators([
        Validators.required,
      ]);
      this.FinalForm.get('txtFTFnoteCompliance').updateValueAndValidity();

      this.FinalForm.get('txtmedicareCompliance').setValidators([
        Validators.required,
      ]);
      this.FinalForm.get('txtmedicareCompliance').updateValueAndValidity();

      this.FinalForm.get('drpdatedetailedWOCompliance').setValidators([
        Validators.required,
      ]);
      this.FinalForm.get(
        'drpdatedetailedWOCompliance'
      ).updateValueAndValidity();

      this.FinalForm.get('txtRXDateCompliance').setValidators([
        Validators.required,
      ]);
      this.FinalForm.get('txtRXDateCompliance').updateValueAndValidity();

      this.FinalForm.get('drpPhysicianCompliance').setValidators([
        Validators.required,
      ]);
      this.FinalForm.get('drpPhysicianCompliance').updateValueAndValidity();
    } else if (this.checkListName == 'Oxygen Setup') {
      // <!--Oxygen Setup-->
      this.FinalForm.get('txtclinicalevaluationOxygen').setValidators([
        Validators.required,
      ]);
      this.FinalForm.get(
        'txtclinicalevaluationOxygen'
      ).updateValueAndValidity();

      this.FinalForm.get('drpdatedetailedWOOxygen').setValidators([
        Validators.required,
      ]);
      this.FinalForm.get('drpdatedetailedWOOxygen').updateValueAndValidity();

      this.FinalForm.get('txtmedicareOxygen').setValidators([
        Validators.required,
      ]);
      this.FinalForm.get('txtmedicareOxygen').updateValueAndValidity();

      this.FinalForm.get('txtRXDateOxygen').setValidators([
        Validators.required,
      ]);
      this.FinalForm.get('txtRXDateOxygen').updateValueAndValidity();

      this.FinalForm.get('drpPhysicianOxygen').setValidators([
        Validators.required,
      ]);
      this.FinalForm.get('drpPhysicianOxygen').updateValueAndValidity();
    } else if (this.checkListName == 'Non-invasive ventilation (NIV)') {
      // <!--Non Invasive Ventilation-->

      this.FinalForm.get('txtclinicalevaluationNIV').setValidators([
        Validators.required,
      ]);
      this.FinalForm.get('txtclinicalevaluationNIV').updateValueAndValidity();

      this.FinalForm.get('drpdatedetailedWONIV').setValidators([
        Validators.required,
      ]);
      this.FinalForm.get('drpdatedetailedWONIV').updateValueAndValidity();

      this.FinalForm.get('txtmedicareNIV').setValidators([Validators.required]);
      this.FinalForm.get('txtmedicareNIV').updateValueAndValidity();

      this.FinalForm.get('txtRXDateNIV').setValidators([Validators.required]);
      this.FinalForm.get('txtRXDateNIV').updateValueAndValidity();

      this.FinalForm.get('drpPhysicianNIV').setValidators([
        Validators.required,
      ]);
      this.FinalForm.get('drpPhysicianNIV').updateValueAndValidity();
    }
  }
  clearApprovevalidators() {
    if (this.checkListName == 'CPAP to BiLevel' || this.checkListName == 'New Setup' || this.checkListName == 'Transfer PAP') {
      // New setup or Transfer PAP or CPAP to Bilevel
      this.FinalForm.controls['txtdatefacetofaceOSA'].clearValidators();
      this.FinalForm.controls['txtdatefacetofaceOSA'].updateValueAndValidity();

      this.FinalForm.controls['txtRXDateNew'].clearValidators();
      this.FinalForm.controls['txtRXDateNew'].updateValueAndValidity();

      this.FinalForm.controls['drpdatedetailedWO'].clearValidators();
      this.FinalForm.controls['drpdatedetailedWO'].updateValueAndValidity();

      this.FinalForm.controls['txtMedicarecovered'].clearValidators();
      this.FinalForm.controls['txtMedicarecovered'].updateValueAndValidity();

      this.FinalForm.controls['drpPhysicianNew'].clearValidators();
      this.FinalForm.controls['drpPhysicianNew'].updateValueAndValidity();

      if(this.checkListName !== 'New Setup'){
        this.FinalForm.controls['txtMedicarePickup'].clearValidators();
        this.FinalForm.controls['txtMedicarePickup'].updateValueAndValidity();
      }

    } else if (this.checkListName == 'PAP Requal. (failed 90 day compliance)') {
      // <!--PAP Requal-->
      this.FinalForm.controls['txtdatefacetofaceOSAPAP'].clearValidators();
      this.FinalForm.controls[
        'txtdatefacetofaceOSAPAP'
      ].updateValueAndValidity();

      this.FinalForm.controls['txtRXDatePAP'].clearValidators();
      this.FinalForm.controls['txtRXDatePAP'].updateValueAndValidity();

      this.FinalForm.controls['drpdatedetailedWOPAP'].clearValidators();
      this.FinalForm.controls['drpdatedetailedWOPAP'].updateValueAndValidity();

      this.FinalForm.controls['txtMedicarecoveredPAP'].clearValidators();
      this.FinalForm.controls['txtMedicarecoveredPAP'].updateValueAndValidity();

      this.FinalForm.controls['txtreturnTicketPAP'].clearValidators();
      this.FinalForm.controls['txtreturnTicketPAP'].updateValueAndValidity();

      this.FinalForm.controls['drpPhysicianPAP'].clearValidators();
      this.FinalForm.controls['drpPhysicianPAP'].updateValueAndValidity();
    } else if (this.checkListName == 'Replacement PAP') {
      // <!--Replacement PAP-->

      this.FinalForm.controls['txtmedicareReplacement'].clearValidators();
      this.FinalForm.controls[
        'txtmedicareReplacement'
      ].updateValueAndValidity();

      this.FinalForm.controls['drpdatedetailedWOReplacement'].clearValidators();
      this.FinalForm.controls[
        'drpdatedetailedWOReplacement'
      ].updateValueAndValidity();

      this.FinalForm.controls['txtFTFnoteReplacement'].clearValidators();
      this.FinalForm.controls['txtFTFnoteReplacement'].updateValueAndValidity();

      this.FinalForm.controls['txtRXDateReplacement'].clearValidators();
      this.FinalForm.controls['txtRXDateReplacement'].updateValueAndValidity();

      this.FinalForm.controls['drpPhysicianReplacement'].clearValidators();
      this.FinalForm.controls[
        'drpPhysicianReplacement'
      ].updateValueAndValidity();
    } else if (this.checkListName == 'Compliance Release' || this.checkListName == 'Supplies') {
      // <!--Compliance Release-->

      this.FinalForm.controls['txtFTFnoteCompliance'].clearValidators();
      this.FinalForm.controls['txtFTFnoteCompliance'].updateValueAndValidity();

      this.FinalForm.controls['txtmedicareCompliance'].clearValidators();
      this.FinalForm.controls['txtmedicareCompliance'].updateValueAndValidity();

      this.FinalForm.controls['drpdatedetailedWOCompliance'].clearValidators();
      this.FinalForm.controls[
        'drpdatedetailedWOCompliance'
      ].updateValueAndValidity();

      this.FinalForm.controls['txtRXDateCompliance'].clearValidators();
      this.FinalForm.controls['txtRXDateCompliance'].updateValueAndValidity();

      this.FinalForm.controls['drpPhysicianCompliance'].clearValidators();
      this.FinalForm.controls[
        'drpPhysicianCompliance'
      ].updateValueAndValidity();
    } else if (this.checkListName == 'Oxygen Setup') {
      // <!--Oxygen Setup-->
      this.FinalForm.controls['txtclinicalevaluationOxygen'].clearValidators();
      this.FinalForm.controls[
        'txtclinicalevaluationOxygen'
      ].updateValueAndValidity();

      this.FinalForm.controls['drpdatedetailedWOOxygen'].clearValidators();
      this.FinalForm.controls[
        'drpdatedetailedWOOxygen'
      ].updateValueAndValidity();

      this.FinalForm.controls['txtmedicareOxygen'].clearValidators();
      this.FinalForm.controls['txtmedicareOxygen'].updateValueAndValidity();

      this.FinalForm.controls['txtRXDateOxygen'].clearValidators();
      this.FinalForm.controls['txtRXDateOxygen'].updateValueAndValidity();

      this.FinalForm.controls['drpPhysicianOxygen'].clearValidators();
      this.FinalForm.controls['drpPhysicianOxygen'].updateValueAndValidity();
    } else if (this.checkListName == 'Non-invasive ventilation (NIV)') {
      // <!--Non Invasive Ventilation-->

      this.FinalForm.controls['txtclinicalevaluationNIV'].clearValidators();
      this.FinalForm.controls[
        'txtclinicalevaluationNIV'
      ].updateValueAndValidity();

      this.FinalForm.controls['drpdatedetailedWONIV'].clearValidators();
      this.FinalForm.controls['drpdatedetailedWONIV'].updateValueAndValidity();

      this.FinalForm.controls['txtmedicareNIV'].clearValidators();
      this.FinalForm.controls['txtmedicareNIV'].updateValueAndValidity();

      this.FinalForm.controls['txtRXDateNIV'].clearValidators();
      this.FinalForm.controls['txtRXDateNIV'].updateValueAndValidity();

      this.FinalForm.controls['drpPhysicianNIV'].clearValidators();
      this.FinalForm.controls['drpPhysicianNIV'].updateValueAndValidity();
    }
  }

  //#endregion

  //#region  Tiff Viewer
  loadExistingImage(docDetails: any) {
    this.selectedExistingDocument = docDetails;
    this.loadTiffImageV1(docDetails);
    this.pageNumber = docDetails.pageNo;
  }

  thumbnailSelectedImage(docDetails: any) {
    if (this.mustSaveAnnotations()) {
      this.loadExistingImage(docDetails);
      this.currentArrayIndex = this.pageNumber - 1;
    }
  }

  previousImage() {
    if (this.mustSaveAnnotations()) {
      this.currentArrayIndex--;
      if (0 < this.currentArrayIndex) {
        this.pageNumber = this.currentArrayIndex + 1;
        this.loadExistingImage(
          this.existingDocumentImageList?.[this.currentArrayIndex]
        );
      } else {
        this.firstImage();
      }
    }
  }

  nextImage() {
    if (this.mustSaveAnnotations()) {
      this.currentArrayIndex++;
      if (this.currentArrayIndex < this.existingDocumentImageList.length) {
        this.pageNumber = this.currentArrayIndex + 1;
        this.loadExistingImage(
          this.existingDocumentImageList?.[this.currentArrayIndex]
        );
      } else {
        this.lastImage();
      }
    }
  }

  firstImage() {
    if (this.mustSaveAnnotations()) {
      this.currentArrayIndex = 0;
      this.pageNumber = this.currentArrayIndex + 1;
      this.loadExistingImage(
        this.existingDocumentImageList?.[this.currentArrayIndex]
      );
    }
  }

  lastImage() {
    if (this.mustSaveAnnotations()) {
      this.currentArrayIndex = this.existingDocumentImageList.length - 1;
      this.pageNumber = this.currentArrayIndex + 1;
      this.loadExistingImage(
        this.existingDocumentImageList?.[this.currentArrayIndex]
      );
    }
  }

  // Function to navigate to a specific page
  goToPage() {
    if (this.mustSaveAnnotations()) {
      if (this.pageNumber <= 0) {
        this.pageNumber = 1;
        this.firstImage();
      } else if (this.pageNumber >= 1 && this.pageNumber <= this.totalPages) {
        this.currentArrayIndex = this.pageNumber - 1;
        this.loadExistingImage(
          this.existingDocumentImageList[this.currentArrayIndex]
        );
      } else {
        this.pageNumber = this.totalPages;
        this.lastImage();
      }
    } else {
      this.pageNumber =
        this.existingDocumentImageList[this.currentArrayIndex].pageNo;
    }
  }

  mustSaveAnnotations(): boolean {
    if (this.textAnnotations.length > 0) {
      const confirmation = confirm(
        'Do you want to leave/move this page without saving annotation?'
      );
      if (confirmation) {
        this.clrAllAnnotation();
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  }

  loadTiffImageV1(docDetails: any) {
    if (docDetails && docDetails.file) {
      // this.zoomLevel = this.DEFAULT_ZOOM_LEVEL;
      const canvas = this.canvasRef.nativeElement;
      this.ctx = canvas.getContext('2d');
      const img = new Image();
      img.onload = () => {
        // Calculate the position to draw the image so that it remains centered
        // const imageWidth = img.width * this.DEFAULT_ZOOM_LEVEL;
        // const imageHeight = img.height * this.DEFAULT_ZOOM_LEVEL;
        const imageWidth = img.width * this.zoomLevel;
        const imageHeight = img.height * this.zoomLevel;
        // Clear canvas before drawing
        this.ctx.clearRect(0, 0, canvas.width, canvas.height);

        // Adjust canvas size based on zoom level
        canvas.width = imageWidth;
        canvas.height = imageHeight;
        this.ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
        // Scroll to the selected image
        const selectedImageElement = document.querySelector(
          '.custom-thumbnail-view-selected'
        );
        if (selectedImageElement) {
          selectedImageElement.scrollIntoView({
            behavior: 'auto',
            block: 'center',
          });
        }
      };
      img.src = 'data:image/tiff;base64,' + docDetails.file;

      this.tiffPageNo = docDetails.pageNo || 0;
      this.selectedExistingDocument = docDetails;
      this.TiffError = false;
      this.loadingMessage = '';
    } else {
      this.TiffError = true;
      this.loadingMessage = 'Images has not found/Empty';
    }
  }

  convertCanvasToBase64() {
    const canvas = this.canvasRef.nativeElement;
    return canvas.toDataURL('image/tiff');
  }

  clearAllAnnotation() {
    const confirmation = confirm(
      'Are you sure you want to delete all annotation text?'
    );
    if (confirmation) {
      this.clrAllAnnotation();
      this.zoomLevel = this.zoomLevelReSize;
    }
  }

  clrAllAnnotation() {
    this.zoomLevel = this.DEFAULT_ZOOM_LEVEL;
    this.textAnnotations = [];
    // this.redrawAnnotations();
    this.redrawAnnotationsZoom();
  }
  // Function to zoom in
  zoomIn() {
    if (this.zoomLevel < this.MAX_ZOOM_LEVEL) {
      this.prevZoomLevel = this.zoomLevel;
      this.zoomLevel = Math.round((this.zoomLevel + 0.1) * 100) / 100; // Round to two decimal places
      this.zoomLevelReSize = this.zoomLevel;
      this.redrawAnnotationsZoom(); // Redraw image with new zoom level
    }
  }

  // Function to zoom out
  zoomOut() {
    if (this.zoomLevel > this.MIN_ZOOM_LEVEL) {
      this.prevZoomLevel = this.zoomLevel;
      this.zoomLevel = Math.round((this.zoomLevel - 0.1) * 100) / 100; // Round to two decimal places
      this.zoomLevelReSize = this.zoomLevel;
      this.redrawAnnotationsZoom(); // Redraw image with new zoom level
    }
  }

  onMouseDown(event: MouseEvent) {
    if (this.TiffError) {
      return;
    }
    if (!this.isLoading) {
      event.preventDefault(); // Prevent default right-click menu
      const canvas = this.canvasRef.nativeElement;
      this.ctx = canvas.getContext('2d');
      const rect = this.canvasRef.nativeElement.getBoundingClientRect();
      this.startX = event.clientX - rect.left;
      this.startY = event.clientY - rect.top;
      this.isDrawing = true;
      this.isMouseDown = true;
      this.savedState = this.ctx.getImageData(
        0,
        0,
        canvas.width,
        canvas.height
      );
    }
  }

  onMouseMove(event: MouseEvent) {
    if (this.TiffError) {
      return;
    }
    if (!this.isLoading) {
      // event.preventDefault(); // Prevent default right-click menu
      if (!this.isDrawing) return;
      if (!this.isMouseDown) return;

      this.isMouseMove = true;
      // Get the canvas and its context
      const canvas = this.canvasRef.nativeElement;
      this.ctx = canvas.getContext('2d');

      // Get the bounding rectangle of the canvas
      const rect = canvas.getBoundingClientRect();

      // Calculate current mouse position relative to the canvas
      const x = event.clientX - rect.left;
      const y = event.clientY - rect.top;

      // Calculate width and height of the rectangle
      const rectWidth = x - this.startX;
      const rectHeight = y - this.startY;

      if (this.savedState !== null) {
        this.ctx.putImageData(this.savedState, 0, 0);
      }

      // Draw the rectangle for the current move
      this.ctx.strokeStyle = this.lineColor;
      this.ctx.lineWidth = 3;
      this.ctx.strokeRect(this.startX, this.startY, rectWidth, rectHeight);
    }
  }
  onMouseUp(event: MouseEvent) {
    if (this.TiffError) {
      return;
    }
    if (!this.isLoading) {
      if (!this.isDrawing) {
        return;
      }
      // Get the canvas and its context
      const canvas = this.canvasRef.nativeElement;
      this.ctx = canvas.getContext('2d');

      // Get the bounding rectangle of the canvas
      const rect = canvas.getBoundingClientRect();

      // Calculate current mouse position relative to the canvas
      const x = event.clientX - rect.left;
      const y = event.clientY - rect.top;

      // Calculate width and height of the rectangle
      const rectWidth = x - this.startX;
      const rectHeight = y - this.startY;

      // Save the current canvas state
      const currentState = this.ctx.getImageData(
        0,
        0,
        canvas.width,
        canvas.height
      );

      if (this.savedState !== null) {
        // Redraw the previously saved canvas state
        this.ctx.putImageData(this.savedState, 0, 0);
      }

      // Draw the rectangle for the current move
      this.ctx.strokeStyle = this.lineColor;
      this.ctx.lineWidth = 3;
      this.ctx.strokeRect(this.startX, this.startY, rectWidth, rectHeight);
      this.rectWidth = rectWidth;
      this.rectHeight = rectHeight;

      // Save the changes for the current move
      this.savedState = this.ctx.getImageData(
        0,
        0,
        canvas.width,
        canvas.height
      );
      this.isDrawing = false;
    }
    this.isDrawing = false;
    // this.isMouseMove = false;
  }

  onMouseLeave(event: MouseEvent) {
    if (this.TiffError) {
      return;
    }
    if (!this.isDrawing) return;
    if (!this.isLoading && !this.isMouseDown && !this.isMouseMove) {
      this.isDrawing = false;
      return;
    } else {
      this.onMouseUp(event);
      this.addText(event);
    }
    this.isDrawing = false;
  }

  deleteTextV0(event: MouseEvent) {
    const rect = this.canvasRef.nativeElement.getBoundingClientRect();
    const clickX = (event.clientX - rect.left) / this.zoomLevel; // Adjusted for zoom
    const clickY = (event.clientY - rect.top) / this.zoomLevel; // Adjusted for zoom

    // Find the text annotation closest to the clicked position
    const indexToDelete = this.textAnnotations.findIndex((annotation) => {
      const textWidth =
        this.ctx.measureText(annotation.text).width * this.zoomLevel; // Adjusted for zoom
      return (
        clickX >= annotation.x - annotation.rectWidth * this.zoomLevel &&
        clickX <=
          annotation.x - annotation.rectWidth * this.zoomLevel + textWidth &&
        clickY >=
          annotation.y -
            annotation.rectHeight * this.zoomLevel -
            14 * this.zoomLevel &&
        clickY <= annotation.y - annotation.rectHeight * this.zoomLevel
      ); // 14 is font size, adjusted for zoom
    });

    if (indexToDelete !== -1) {
      // Ask for confirmation
      // const confirmation = confirm(
      //   'Are you sure you want to delete this text?'
      // );

      // if (confirmation) {
      //   this.textAnnotations.splice(indexToDelete, 1);
      //   this.redrawAnnotationsZoom(); // Redraw with zoom after deletion
      // }
      Swal.fire({
        title: 'Are you sure you want to delete this text?',
        text: "This Annotation will be removed!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#34c38f',
        cancelButtonColor: '#f46a6a',
        confirmButtonText: 'Yes, delete it!',
      }).then((result) => {
        if (result.value) {
          this.textAnnotations.splice(indexToDelete, 1);
          this.redrawAnnotationsZoom();
        }
      });
    }
  }

  deleteText(event: MouseEvent) {

    const rect = this.canvasRef.nativeElement.getBoundingClientRect();
    // const clickX = event.clientX - rect.left;
    // const clickY = event.clientY - rect.top;

    const clickX =(event.clientX - rect.left) / this.zoomLevel;
    const clickY = (event.clientY - rect.top) / this.zoomLevel;

    // Find the text annotation closest to the clicked position
    const indexToDelete = this.textAnnotations.findIndex((annotation) => {
      const textWidth = this.ctx.measureText(annotation.text).width;
      return (
        clickX >= annotation.x - annotation.rectWidth &&
        clickX <= annotation.x - annotation.rectWidth + textWidth &&
        clickY >= annotation.y - annotation.rectHeight - 10 - 14 &&
        clickY <= annotation.y - annotation.rectHeight - 10

        // clickX >= annotation.x - annotation.rectWidth &&
        // clickX <= annotation.x - annotation.rectWidth + textWidth &&
        // clickY >= annotation.y - annotation.rectHeight - 10 - 14 &&
        // clickY <= annotation.y - annotation.rectHeight - 10
      ); // 14 is font size
    });

    if (indexToDelete !== -1) {
    // Ask for confirmation
      Swal.fire({
        title: 'Are you sure you want to delete this text?',
        text: "This Annotation will be removed!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#34c38f',
        cancelButtonColor: '#f46a6a',
        confirmButtonText: 'Yes, delete it!',
      }).then((result) => {
        if (result.value) {
          this.textAnnotations.splice(indexToDelete, 1);
          this.redrawAnnotationsZoom();
        }
      });

      // const confirmation = confirm(
      //   'Are you sure you want to delete this text?'
      // );

      // if (confirmation) {
      //   this.textAnnotations.splice(indexToDelete, 1);
      //   this.redrawAnnotationsZoom();
      // }
    }
  }

  clrAllZoomForSave() {
    // this.zoomLevel = this.DEFAULT_ZOOM_LEVEL;
    this.zoomLevel=1;
    this.redrawAnnotationsZoom();
  }

  redrawAnnotations() {
    if (!this.isLoading) {
      const canvas = this.canvasRef.nativeElement;
      this.ctx = canvas.getContext('2d');
      this.ctx.clearRect(0, 0, canvas.width, canvas.height);
      const img = new Image();
      img.onload = () => {
        this.ctx.drawImage(img, 0, 0);
        this.textAnnotations.forEach((annotation) => {
          this.ctx.font = '14px Arial';
          this.ctx.fillStyle = this.textColor;
          // this.ctx.fillText(annotation.text, annotation.x, annotation.y);
          this.ctx.fillText(
            annotation.text,
            annotation.x - annotation.rectWidth,
            annotation.y - annotation.rectHeight - 10
          );
          // Draw the rectangle for the current move
          this.ctx.strokeStyle = this.lineColor;
          this.ctx.lineWidth = 3;
          this.ctx.strokeRect(
            annotation.x - annotation.rectWidth,
            annotation.y - annotation.rectHeight,
            annotation.rectWidth,
            annotation.rectHeight
          );

          // Save the changes for the current move
          this.savedState = this.ctx.getImageData(
            0,
            0,
            canvas.width,
            canvas.height
          );
        });
      };
      img.src = 'data:image/tiff;base64,' + this.selectedExistingDocument?.file;
    }
  }

  addText(event: MouseEvent) {
    if (this.TiffError) {
      return;
    }
    this.isDrawing = false;
    if (!this.isLoading && this.isMouseMove) {
      this.isMouseMove = false;
      const rect = this.canvasRef.nativeElement.getBoundingClientRect();
      const textX = (event.clientX - rect.left) / this.zoomLevel; // Adjusted for zoom
      const textY = (event.clientY - rect.top) / this.zoomLevel; // Adjusted for zoom

      let text = prompt('Enter text:');
      // text === null ||
      if (text === '' || text) {
        text = text.trim();
        // Create a new TextAnnotation object and add it to the textAnnotations array
        const newAnnotation: TextAnnotation = {
          text: this.isEmpty(text) ? '.':text,
          x: textX,
          y: textY,
          rectWidth: this.rectWidth / this.zoomLevel,
          rectHeight: this.rectHeight / this.zoomLevel,
        };
        this.textAnnotations.push(newAnnotation);
      }
      // Redraw all annotations
      // this.redrawAnnotations();
      this.redrawAnnotationsZoom();
    }
  }

  // redrawAnnotationsZoom() {
  //   if (!this.isLoading) {
  //     const canvas = this.canvasRef.nativeElement;
  //     this.ctx = canvas.getContext('2d');
  //     this.ctx.clearRect(0, 0, canvas.width, canvas.height);
  //     const img = new Image();
  //     img.onload = () => {
  //       const imageWidth = img.width * this.zoomLevel;
  //       const imageHeight = img.height * this.zoomLevel;
  //       // Clear canvas before drawing
  //       this.ctx.clearRect(0, 0, canvas.width, canvas.height);
  //       // Adjust canvas size based on zoom level
  //       canvas.width = imageWidth;
  //       canvas.height = imageHeight;
  //       // Draw image with adjusted dimensions and centered position
  //       this.ctx.drawImage(img, 0, 0, imageWidth, imageHeight);

  //       // this.ctx.drawImage(img, 0, 0);
  //       this.textAnnotations.forEach((annotation) => {
  //         // // Adjust font size for zoom
  //         const fontSize = Math.round(25 * this.zoomLevel);
  //         this.ctx.font = `${fontSize}px Arial`;

  //         // this.ctx.font = '14px Arial';

  //         // Adjust position for zoom
  //         const adjustedX = annotation.x * this.zoomLevel;
  //         const adjustedY = annotation.y * this.zoomLevel;

  //         this.ctx.fillStyle = this.textColor;
  //         this.ctx.fillText(
  //           annotation.text,
  //           adjustedX - annotation.rectWidth * this.zoomLevel,
  //           adjustedY - (annotation.rectHeight + 10) * this.zoomLevel
  //         );
  //         this.ctx.strokeStyle = this.lineColor;
  //         this.ctx.lineWidth = 3;
  //         this.ctx.strokeRect(
  //           adjustedX - annotation.rectWidth * this.zoomLevel,
  //           adjustedY - annotation.rectHeight * this.zoomLevel,
  //           annotation.rectWidth * this.zoomLevel,
  //           annotation.rectHeight * this.zoomLevel
  //         );

  //         this.savedState = this.ctx.getImageData(
  //           0,
  //           0,
  //           canvas.width,
  //           canvas.height
  //         );
  //       });
  //     };
  //     img.src = 'data:image/tiff;base64,' + this.selectedExistingDocument.file;
  //   }
  // }


  redrawAnnotationsZoomV0() {
    if (!this.isLoading) {
      const canvas = this.canvasRef.nativeElement;
      this.ctx = canvas.getContext('2d');
      this.ctx.clearRect(0, 0, canvas.width, canvas.height);
      const img = new Image();
      img.onload = () => {
        // Calculate the new image dimensions based on the zoom level
        let imageWidth = Math.round(img.width * this.zoomLevel);
        let imageHeight = Math.round(img.height * this.zoomLevel);

        // Adjust image dimensions based on previous zoom level
        if (this.prevZoomLevel && this.zoomLevel < this.prevZoomLevel) {
          const reductionFactor = 5; // Adjust this value as needed
          const reductionAmount = Math.round(
            reductionFactor * (this.prevZoomLevel - this.zoomLevel)
          );
          imageWidth -= reductionAmount;
          imageHeight -= reductionAmount;
        }

        canvas.width = imageWidth;
        canvas.height = imageHeight;
        this.ctx.drawImage(img, 0, 0, imageWidth, imageHeight);
        this.textAnnotations.forEach((annotation) => {
          const fontSize = Math.round(25 * this.zoomLevel);
          this.ctx.font = `${fontSize}px Arial`;

          const adjustedX = Math.round(annotation.x * this.zoomLevel);
          const adjustedY = Math.round(annotation.y * this.zoomLevel);

          this.ctx.fillStyle = this.textColor;
          this.ctx.fillText(
            annotation.text,
            adjustedX - Math.round(annotation.rectWidth * this.zoomLevel),
            adjustedY -
              Math.round((annotation.rectHeight + 10) * this.zoomLevel)
          );
          this.ctx.strokeStyle = this.lineColor;
          this.ctx.lineWidth = 3;
          this.ctx.strokeRect(
            adjustedX - Math.round(annotation.rectWidth * this.zoomLevel),
            adjustedY - Math.round(annotation.rectHeight * this.zoomLevel),
            Math.round(annotation.rectWidth * this.zoomLevel),
            Math.round(annotation.rectHeight * this.zoomLevel)
          );

          this.savedState = this.ctx.getImageData(
            0,
            0,
            canvas.width,
            canvas.height
          );
        });

        // Save the current zoom level for the next redraw
        this.prevZoomLevel = this.zoomLevel;
      };
      img.src = 'data:image/tiff;base64,' + this.selectedExistingDocument.file;
    }
  }

  redrawAnnotationsZoom() {
    if (!this.isLoading) {
      const canvas = this.canvasRef.nativeElement;
      this.ctx = canvas.getContext('2d');
      this.ctx.clearRect(0, 0, canvas.width, canvas.height);
      const img = new Image();
      img.onload = () => {
        // Calculate the new image dimensions based on the zoom level
        let imageWidth = Math.round(img.width * this.zoomLevel);
        let imageHeight = Math.round(img.height * this.zoomLevel);

        // Adjust image dimensions based on previous zoom level
        if (this.prevZoomLevel && this.zoomLevel < this.prevZoomLevel) {
          const reductionFactor = 5; // Adjust this value as needed
          const reductionAmount = Math.round(
            reductionFactor * (this.prevZoomLevel - this.zoomLevel)
          );
          imageWidth -= reductionAmount;
          imageHeight -= reductionAmount;
        }

        canvas.width = imageWidth;
        canvas.height = imageHeight;
        this.ctx.drawImage(img, 0, 0, imageWidth, imageHeight);
        this.textAnnotations.forEach((annotation) => {
          const fontSize = Math.round(25 * this.zoomLevel);
          this.ctx.font = `${fontSize}px Arial`;

          const adjustedX = Math.round(annotation.x * this.zoomLevel);
          const adjustedY = Math.round(annotation.y * this.zoomLevel);

          this.ctx.fillStyle = this.textColor;
          this.ctx.fillText(
            annotation.text,
            adjustedX - Math.round(annotation.rectWidth * this.zoomLevel),
            adjustedY -
              Math.round((annotation.rectHeight + 10) * this.zoomLevel)
          );
          this.ctx.strokeStyle = this.lineColor;
          this.ctx.lineWidth = 3;
          this.ctx.strokeRect(
            adjustedX - Math.round(annotation.rectWidth * this.zoomLevel),
            adjustedY - Math.round(annotation.rectHeight * this.zoomLevel),
            Math.round(annotation.rectWidth * this.zoomLevel),
            Math.round(annotation.rectHeight * this.zoomLevel)
          );

          this.savedState = this.ctx.getImageData(
            0,
            0,
            canvas.width,
            canvas.height
          );
        });

        // Save the current zoom level for the next redraw
        this.prevZoomLevel = this.zoomLevel;
      };
      img.src = 'data:image/tiff;base64,' + this.selectedExistingDocument.file;
    }
  }

  addSlashToDate(event: Event): void {
    const input = event.target as HTMLInputElement;
    const inputValue = input.value;
    input.value = inputValue
      .replace(/^(\d{2})(\d{1,2})/, '$1/$2')
      .replace(/^(\d{2}\/\d{2})(\d+)/, '$1/$2')
      .replace(/[^\d\/]/g, '');
  }

  saveAnnotation() {
    this.loaderService.showLoader();
    this.clrAllZoomForSave();
    this.disableAnnotation = true;
    if (this.saveAnnotationAPICall) {
      this.saveAnnotationAPICall.unsubscribe();
    }

    try {
      setTimeout(() => {
        const base64Content = this.convertCanvasToBase64().split(',')[1];
        const annotatedDTO: AnnotatedDTO = {
          file: base64Content,
          pageNo: this.pageNumber,
          id: this.docID,
        };

        this.saveAnnotationAPICall = this.inboundDocumentService
          .addAnnotationByDocumentIdByDto(annotatedDTO)
          .subscribe(
            (response) => {
              this.existingDocumentImageList[this.currentArrayIndex].file =
                base64Content;
              this.clrAllAnnotation();
              this.goToPage();
              this.zoomLevel = this.zoomLevelReSize;
              this.toastr.success(response, 'Success');
              this.disableAnnotation = false;
              this.loaderService.hideLoader();
            },
            (error) => {
              this.handleError(error);
            }
          );
      }, 1000);
    } catch (error) {
      this.handleError(error);
    }
  }

  private handleError(error: any) {
    const data: HttpErrorResponse = error;
    this.toastr.error(data?.error?.error?.message, 'Error');
    this.disableAnnotation = true;
    this.loaderService.hideLoader();
  }
  //#endregion

  clearPhysicianDoctorsListNew(event: Event) {
    event.stopPropagation();
    this.FinalForm?.patchValue({
      drpPhysicianNew: '',
    });
  }

  clearPhysicianDoctorsListPAP(event: Event) {
    event.stopPropagation();
    this.FinalForm?.patchValue({
      drpPhysicianPAP: '',
    });
  }

  clearPhysicianDoctorsListReplacement(event: Event) {
    event.stopPropagation();
    this.FinalForm?.patchValue({
      drpPhysicianReplacement: '',
    });
  }

  clearPhysicianDoctorsListCompliance(event: Event) {
    event.stopPropagation();
    this.FinalForm?.patchValue({
      drpPhysicianCompliance: '',
    });
  }

  clearPhysicianDoctorsListOxygen(event: Event) {
    event.stopPropagation();
    this.FinalForm?.patchValue({
      drpPhysicianOxygen: '',
    });
  }

  clearPhysicianDoctorsListNIV(event: Event) {
    event.stopPropagation();
    this.FinalForm?.patchValue({
      drpPhysicianNIV: '',
    });
  }
  isEmpty(value: any): boolean {
    return (
      value === null ||
      value === undefined ||
      value === '' ||
      value === defaultGuid
    );
  }

  openDoSorting() {
    const url ='/#new_setup/' + this.docID + '/' + this.selectedNode;
    window.open(url, '_blank');
  }

  updateCharacterCount(maxlength: number) {
     // Ensure the text length does not exceed the maximum length
     if (this.notesText?.length > maxlength) {
       this.notesText = this.notesText?.substr(0, maxlength);
     }
     }

     resetFinalForm(){
      const resetFields = {
        txtInsComments: this.FinalForm.get('txtInsComments').value,
        drpVerification: this.FinalForm.get('drpVerification').value,
        txtPreparedDate: this.FinalForm.get('txtPreparedDate').value,
        txtPreparedBy: this.FinalForm.get('txtPreparedBy').value,
      };
      this.FinalForm.reset(resetFields);
      this.onApprove('');
      //Clear Selected RX Documents for (New setup or Transfer PAP or CPAP to Bilevel)
      this.cpaptoBilevelF2F = [];
      this.cpaptoBilevelMCST = [];
      this.cpaptoBilevelDWO = [];
      this.cpaptoBilevelPT = [];
      //Clear Selected RX Documents for (PAP Requal. (failed 90 day compliance))
      this.papRequalF2f = [];
      this.papRequalMCST = [];
      this.papRequalDWO = [];
      this.papRequalRT = [];
      //Clear Selected RX Documents for (Replacement PAP)
      this.rPAPMCST = [];
      this.rPAPDWO = [];
      this.rPAPFTF = [];
      //Clear Selected RX Documents for (Compliance Release or Supplies)
      this.crF2F = [];
      this.crMCST = [];
      this.crDWO = [];
      //Clear Selected RX Documents for (Oxygen Setup)
      this.osOCEN = [];
      this.osDWO = [];
      this.osMCST = [];
      //Clear Selected RX Documents for (Non-invasive ventilation (NIV))
      this.nivF2F = [];
      this.nivDWO = [];
      this.nivMCST = [];
     }

     func(event): void{
      if(event.key == 'checkisSortingData'){
        if(event.newValue == 'NewisSorting'){
          this.isSorting = true;
        }else{

        }
      }
    }

      //#region Patient Document PDF Download
  patientDocPdfDownload() {
    this.isDownloading=true;
    this.inboundDocumentService.downloadInboundDocumentByBlobName(this.blobName)
    .subscribe(
      (response) => {
          if (
            typeof response === 'string' &&
            response &&
            response !== null &&
            response.length !== 0
          ) {
            this.strPdfString = String(response);
            // Decode base64 string to binary data
            const byteCharacters = atob(this.strPdfString);
            const byteNumbers = new Array(byteCharacters.length);
            for (let i = 0; i < byteCharacters.length; i++) {
                byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);

            const blob = new Blob([byteArray], { type: "application/pdf" });
            // const fileName = this.defaultFaxId;
            const fileName = this.chartId + '-' + this.vpatientName;
            // Create a link element
            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = fileName;

            // Append the link to the body
            document.body.appendChild(link);

            // Programmatically click the link to trigger the download
            link.click();

            // Remove the link from the DOM
            document.body.removeChild(link);
          }
          this.isDownloading=false;
        },
        (err: HttpErrorResponse) => {
          this.isDownloading=false;
          console.error('Error downloading the document', err);
        }
      );
    }
  // #endregion Patient Document PDF Download

  viewPatientBlobPDF(){
    const url = '/#/patient_preview_pdf_document/' + this.docID + '/' + this.blobName;
    window.open(url, '_blank');
  }
}
