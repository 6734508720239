<div class="container-fluid">
    <div class="row bg">
        <div class="col-12">
            <div class="d-flex align-items-center justify-content-between">
                <h2 class="font-weight-bolder mb-2">
                    <b class="customThemeClass">List / Schedule List</b>
                </h2>

                <div class="page-title-box page-title-right pt-3 pb-3">
                    <ol class="breadcrumb m-0">
                        <li>
                            <a [routerLink]="'/settings/calender'" target="_blank" class="preview-link">
                                <button mat-button class="buttonColor"><mat-icon>add</mat-icon>Add Schedule</button>
                            </a>
                        </li>
                    </ol>
                </div>
            </div>
        </div>
    </div>
    <div class="card card-body mt-1">
        <form [formGroup]="calendarSearchForm">
            <div class="row">
                <!--User Drop Down-->
                <mat-form-field class="col-2">
                    <mat-label>
                        <div class="select-placeholder-container" *ngIf="isUserLoading">
                            <span>Loading...</span>
                            <mat-spinner class="spinner" diameter="20"></mat-spinner>
                        </div>
                        <span *ngIf="!isUserLoading">User</span>
                    </mat-label>
                    <mat-select formControlName="drpUsertype" multiple (selectionChange)="onSelectionUserChange($event)"
                        [(value)]="userDesignation">
                        <mat-select-trigger *ngIf="UserSelectAllOption">
                            <span>All</span>                      
                          </mat-select-trigger>
                        <mat-option>
                            <ngx-mat-select-search placeholderLabel="Search" noEntriesFoundLabel="No Matches found"
                                formControlName="txtUserType"></ngx-mat-select-search>
                        </mat-option>
                        <mat-option *ngIf="!UserSelectAllOption" [value]="'selectAll'">Select All</mat-option>
                        <mat-option *ngIf="UserSelectAllOption" [value]="'DeselectAll'">Deselect All</mat-option>
                        <mat-option *ngFor="let users of lstFilterUsers | async" [value]="users?.userId">
                            {{ users?.userDesignation }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>

                <!--Branch Drop Down-->
                <mat-form-field class="col-2">
                    <mat-label><div class="select-placeholder-container" *ngIf="isBranchLoading">
                        <span>Loading...</span>
                        <mat-spinner class="spinner" diameter="20"></mat-spinner>
                    </div>
                    <span *ngIf="!isBranchLoading">Branch</span>
                </mat-label>
                    <mat-select #selectBranch formControlName="drpBranch" multiple [(value)]="organizationUnitName"
                        (selectionChange)="onBranchSelectionChange($event)">
                        <mat-select-trigger *ngIf="BranchSelectAllOption">
                            <span>All</span>                      
                          </mat-select-trigger>
                        <mat-option>
                            <ngx-mat-select-search placeholderLabel="Search" noEntriesFoundLabel="No Matches found"
                                formControlName="txtBranch"></ngx-mat-select-search>
                        </mat-option>
                        <mat-option *ngIf="!BranchSelectAllOption" [value]="'selectAll'">Select All</mat-option>
                        <mat-option *ngIf="BranchSelectAllOption" [value]="'DeselectAll'">Deselect All</mat-option>
                        <mat-option *ngFor="let branch of brnachList | async" [value]="branch.id">
                            {{ branch.organizationUnitName }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>

                <!--Status-->

                <mat-form-field class="col-2">
                    <mat-label>Status</mat-label>
                    <mat-select #selectBranch formControlName="drpStatustype" multiple [(value)]="statusName"
                        (selectionChange)="onStatusSelectionChange($event)">
                        <mat-select-trigger *ngIf="StatusSelectAllOption">
                            <span>All</span>                      
                          </mat-select-trigger>
                        <mat-option>
                            <ngx-mat-select-search placeholderLabel="Search" noEntriesFoundLabel="No Matches found"
                                formControlName="txtStatus"></ngx-mat-select-search>
                        </mat-option>
                        <mat-option *ngIf="!StatusSelectAllOption" [value]="'selectAll'">Select All</mat-option>
                        <mat-option *ngIf="StatusSelectAllOption" [value]="'DeselectAll'">Deselect All</mat-option>
                        <mat-option *ngFor="let branch of statusList | async" [value]="branch.statusID">
                            {{ branch.statusName }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>

                <!-- <mat-form-field class="col-2">
                    <mat-label>Status</mat-label>
                    <mat-select formControlName="drpStatustype">
                        <mat-option *ngFor="let status of dataStatusList" [value]="status?.statusID">
                            {{ status?.statusName }}
                        </mat-option>
                    </mat-select>
                </mat-form-field> -->


                <!--Date Range-->
                <!-- <mat-form-field class="col-2 dd-text"
                [ngClass]="{'mat-form-field-invalid': calendarSearchForm.get('txtStartDateValue').invalid || calendarSearchForm.get('txtEndDateValue').invalid}">
                <mat-label>Date Range <span class="asterisk">*</span></mat-label>
                <mat-date-range-input [rangePicker]="picker">

                  <input matStartDate matInput placeholder="Start date" [(ngModel)]="dScheduleFromDate"
                    formControlName="txtStartDateValue" (keydown)="addSlashToDate($event)" maxlength="10" />

                  <input matEndDate matInput (dateChange)="onDateRangeChange($event)" [(ngModel)]="dScheduleToDate"
                    placeholder="End date" formControlName="txtEndDateValue" (keydown)="addSlashToDate($event)"
                    maxlength="10" />

                </mat-date-range-input>

                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-date-range-picker #picker></mat-date-range-picker>

                <mat-error *ngIf="calendarSearchForm.get('txtStartDateValue').hasError('required')">Start date is
                  required</mat-error>

                <mat-error
                  *ngIf="calendarSearchForm.get('txtStartDateValue').invalid && !calendarSearchForm.get('txtStartDateValue').hasError('required')">Invalid
                  start date</mat-error>

                <mat-error *ngIf="calendarSearchForm.get('txtEndDateValue').hasError('required')">End date is
                  required</mat-error>

                <mat-error
                  *ngIf="calendarSearchForm.get('txtEndDateValue').invalid && !calendarSearchForm.get('txtEndDateValue').hasError('required')">Invalid
                  end date</mat-error>

                <mat-error *ngIf="calendarSearchForm.hasError('dateRangeError')">From date must be less than or equal to
                  To date</mat-error>
              </mat-form-field> -->

            <mat-form-field class="col-2">
              <mat-label>Select Date Range <span class="asterisk"> *</span>
                <i class="fa fa-calendar ml-2" style="color:rgb(0, 0, 0)" matSuffix align="end"></i>
               </mat-label>
              <input autocomplete="new-txtDateValue" matInput  type="text" name="daterange" ngxDaterangepickerMd
              [showCustomRangeLabel]="true" [alwaysShowCalendars]="true"  [(ngModel)]="selectedRange"
                [linkedCalendars]="false" [ranges]="ranges" [showClearButton]="false" [locale]="calendarLocale"
                 [keepCalendarOpeningWithRange]="true"  formControlName="txtDateValue"
                 (datesUpdated)="datesUpdateds($event)" [autoApply]="true" [readonly]="true" (keyup)="datesUpdateds($event)"
                />
                <mat-error *ngIf="
                calendarSearchForm?.get('txtDateValue')?.touched &&
                calendarSearchForm?.get('txtDateValue')?.errors?.required
              ">
              Date Range is a required field!
            </mat-error>
            </mat-form-field>


                <!--Search And Reset Button-->
                <div class="col-4 mt-3">
                    <button mat-button class="buttonColor mr-2" (click)="fnSearchCalendarList()" [disabled]="calendarSearchForm.invalid || isSearch">
                       <mat-icon>search</mat-icon> Search
                        <mat-icon *ngIf="isShowSpinner">
                            <mat-spinner class="spinner-border spinner-border-sm" diameter="20"></mat-spinner>
                        </mat-icon>
                    </button>
                    <button mat-button class="resetclr mr-2" (click)="resetCalender()">Reset</button>
                </div>

                <!--Status-->
                <!-- <div class="col-4 mt-2">
                    <div class="d-flex flex-row-reverse" style="margin-right: 10%;">
                        <mat-radio-group [(ngModel)]="activeStatus" (change)="fnSearchCalendarList()">
                            <mat-radio-button [value]="0" class="active">All</mat-radio-button>
                            <mat-radio-button [value]="CalenderStatus.Scheduled" class="active p-2"
                                [checked]="true">Scheduled</mat-radio-button>
                            <mat-radio-button [value]="CalenderStatus.Rescheduled" class="active p-2">ReScheduled</mat-radio-button>
                            <mat-radio-button [value]="CalenderStatus.Cancelled" class="active p-2">Cancelled</mat-radio-button>
                            <mat-radio-button [value]="CalenderStatus.Completed" class="active">Completed</mat-radio-button>
                        </mat-radio-group>
                    </div>
                </div> -->
                <!--Role Drop Down-->
                <!-- <mat-form-field class="col-2">
                    <mat-label>Role Name <span class="asterisk">*</span></mat-label>
                    <mat-select maxlength="40" [(value)]="role" formControlName="txtRole"
                        (selectionChange)="onRoleChange($event)">
                        <mat-option *ngFor="let roles of drpRoles" [value]="roles.name">
                            {{ roles.roleDisplayName }}
                        </mat-option>
                    </mat-select>
                    <button mat-button (click)="clearRole()" (click)="role='';$event.stopPropagation()" *ngIf="role"
                        matSuffix mat-icon-button>
                        <mat-icon>close</mat-icon>
                    </button>
                </mat-form-field> -->
            </div>
        </form>

        <div class="row">
            <div class="col-12 mt-3">
                <mat-divider></mat-divider>
            </div>
            <!--Loade Section -->
            <div class="col-12" *ngIf="isLoading">
                <div class="d-flex justify-content-center" *ngIf="isLoading">
                    <img class="img-responsive pb-2 mr-1" src="assets/gifs/Infinity-loader.gif" />
                </div>
            </div>
            <div class="col-12 mt-3">
                <div *ngIf="!isLoading" class="table table-responsive">
                    <!--Calender Table-->
                    <app-calendar-list-table [tableData]="calendarDataList" [statuslabel]="isShowstatusLabel"></app-calendar-list-table>
                </div>
            </div>

        </div>
    </div>
</div>