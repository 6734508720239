<table
  datatable
  [dtOptions]="dtClaimsTableOptions"
  class="row-border hover w-100 display"
>
  <thead>
    <tr>
      <th [matTooltip]="'Posting Type'">Posting Type</th>
      <th [matTooltip]="'Cheque No'">Check No</th>
      <th [matTooltip]="'Cheque Amount ($)'" class="text-right">Check Amount ($)</th>
      <th [matTooltip]="'Claim Number'" >Claim Number</th>
      <th [matTooltip]="'Chart No'">Chart No</th>
      <th [matTooltip]="'Patient Name'">Patient Name</th>
      <th [matTooltip]="'Clearing House'">Clearing House</th>
      <th [matTooltip]="'Posting Date'">Posting Date</th>
      <th [matTooltip]="'Billed Amount ($)'" class="text-right">Billed Amount ($)</th>
      <th [matTooltip]="'Paid Amount ($)'" class="text-right">Paid Amount ($)</th>
      <th [matTooltip]="'Current Balance ($)'" class="text-right">Current Balance ($)</th>
      <th [matTooltip]="'Cheque Date'">Check Date</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let checkData of claimsTableData">
      <td [matTooltip]="checkData.postingType || '-'">
        {{ checkData.postingType || '-' }}
      </td>
      <!-- Accessing properties of each checkData object for table cells -->
      <td [matTooltip]="checkData.checkNumber || '-'">
        <a
        class="hyperlink-font preview-link pointer"
        (click)="previewDocumet(checkData)"
        >{{ checkData.checkNumber || "-" }}</a
      >

      </td>
      <td [matTooltip]="checkData.chequeAmount || '-'" class="text-right">
        {{ checkData.chequeAmount }}
      </td>
      <!-- <td [matTooltip]="checkData.defaultClaimId || '-'" >
        <a
          class="hyperlink-font "
          target="_blank"

          (click)="openEOBModel( checkData.claimId)"
          target="_blank"
          >{{ checkData.defaultClaimId || "-" }}</a
        >
      </td> -->
      <td class="text-right">
        <a
          class="pointer"
          matTooltip="{{ checkData.defaultClaimId || '-' }}"
          [class.disabled]="
            isEmpty(checkData.defaultClaimId) ||
            isEmpty(checkData.orderId) ||
            isEmpty(checkData.patientId) ||
            isEmpty(checkData.mmRefOrderId) ||
            isEmpty(checkData.claimId)
          "
          (click)="
            openClaimForm(
              checkData.orderId,
              checkData.patientId,
              checkData.chartId,
              checkData.patientName,
              checkData.mmRefOrderId,
              checkData.claimId,
              true,
              checkData.isResubmitEnabled
            )
          "
        >
          {{ checkData.defaultClaimId || "-" }}
        </a>
      </td>
      <td [matTooltip]="checkData.chartId || '-'">
        <a
          class="hyperlink-font "
          [routerLink]="
            '/patientCreateEditTab/' +
            checkData.patientId +
            '/' +
            checkData.documentId
          "
          target="_blank"
          >{{ checkData.chartId || "-" }}</a
        >
      </td>
      <td [matTooltip]="checkData.patientName || '-'">
        {{ checkData.patientName || "-" }}
      </td>
      <td [matTooltip]="checkData.clearingHouse || '-'">
        {{ checkData.clearingHouse || "-" }}
      </td>
      <!-- <td [matTooltip]=" checkData.postingDate
      ? (checkData.postingDate | date : 'MM/dd/yyyy' : 'en_US')
      : '-' ">

        {{
          checkData.postingDate
            ? (checkData.postingDate | date : "MM/dd/yyyy" : "en_US")
            : "-"
        }}
      </td> -->
      <td [matTooltip]=" checkData.postingDate || '-' ">

        {{
          checkData.postingDate ||  "-"
        }}
      </td>
      <td [matTooltip]="checkData.billedAmount || '-'" class="text-right">
        {{ checkData.billedAmount  }}
      </td>
      <td [matTooltip]="checkData.insurancePaid || '-'" class="text-right">
        {{ checkData.insurancePaid  }}
      </td>
      <td [matTooltip]="checkData.currentBalance || '0'" class="text-right">
        {{ checkData.currentBalance  }}
      </td>
      <!-- <td [matTooltip]="checkData.chequeDate
      ? (checkData.chequeDate | date : 'MM/dd/yyyy' : 'en_US')
      : '-' ">
        {{
          checkData.chequeDate
            ? (checkData.chequeDate | date : "MM/dd/yyyy" : "en_US")
            : "-"
        }}
      </td> -->
      <td [matTooltip]="checkData.chequeDate || '-' ">
        {{
          checkData.chequeDate || '-'
        }}
      </td>
    </tr>
  </tbody>
  <tfoot>
    <tr>
      <th>
        <input
          matInput
          class="textbox-border-class"
          type="text"
          placeholder="Posting Type"
        />
      </th>
      <th>
        <input
          matInput
          class="textbox-border-class"
          type="text"
          placeholder="Check No"
        />
      </th>
      <th>
        <input
          matInput
          class="textbox-border-class"
          type="text"
          placeholder="Check Amount"
        />
      </th>
      <th>
        <input
          matInput
          class="textbox-border-class"
          type="text"
          placeholder="Claim Number"
        />
      </th>
      <th>
        <input
          matInput
          class="textbox-border-class"
          type="text"
          placeholder="Chart No"
        />
      </th>
      <th>
        <input
          matInput
          class="textbox-border-class"
          type="text"
          placeholder="Patient Name"
        />
      </th>
      <th>
        <input
          matInput
          class="textbox-border-class"
          type="text"
          placeholder="Clearing House"
        />
      </th>
      <th>
        <input
          matInput
          class="textbox-border-class"
          type="text"
          placeholder="Posting Date"
        />
      </th>
      <th>
        <input
          matInput
          class="textbox-border-class"
          type="text"
          placeholder="Billed Amount"
        />
      </th>
      <th>
        <input
          matInput
          class="textbox-border-class"
          type="text"
          placeholder="Paid Amount"
        />
      </th>
      <th>
        <input
          matInput
          class="textbox-border-class"
          type="text"
          placeholder="Current Balance"
        />
      </th>
      <th>
        <input
          matInput
          class="textbox-border-class"
          type="text"
          placeholder="Check Date"
        />
      </th>
    </tr>
  </tfoot>
</table>
