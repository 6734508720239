import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-ontime-schedule-new',
  templateUrl: './ontime-schedule-new.component.html',
  styleUrls: ['./ontime-schedule-new.component.scss']
})
export class OntimeScheduleNewComponent implements OnInit {
  isLoading: boolean = false;
  step: boolean = false;
  newScheduleForm: FormGroup;
  holidaySearchForm: FormGroup;
  BirthdateMaxDate: Date;
  BirthdateMinDate: Date;
  chosenYearDate: Date;
  dataSource: MatTableDataSource<any>;
  displayedColumns: string[] = ['Options','Code', 'Visit', 'Patient', 'Respiratory Therapist', 'Type', 'Appointment Date', 'From Time', 'To Time', 'Status', 'TAT', ];
  emailMask: any;
  constructor(
    private formBuilder: FormBuilder,
    public title: Title,
  ) { }

  ngOnInit(): void {
    this.title.setTitle("Qsecure | Schedule");
    this.newScheduleForm = this.formBuilder.group({
      txtPatientName: new FormControl(),
      txtEmail: new FormControl(),
      txtPhoneNumber: new FormControl(),
      txtAddress: new FormControl(),
      txtPatientId: new FormControl(),
      txtDatenew: new FormControl(),
      txtDate: new FormControl(),
      drpAppointmentType: new FormControl(),
    })
    this.holidaySearchForm = this.formBuilder.group({
      code: new FormControl(""),
      visit: new FormControl(""),
      patient: new FormControl(""),
      respiratoryTherapist: new FormControl(""),
      type: new FormControl(""),
      appointmentDate: new FormControl(""),
      fromTime: new FormControl(""),
      toTime: new FormControl(""),
      status: new FormControl(""),
      tat: new FormControl(""),
    })
  }
  leave: leaves[] = [
    { value: '0', viewValue: 'SMS' },
    { value: '1', viewValue: 'Email' },
  ];

}

interface leaves {
  value: string;
  viewValue: string;
}
