<mat-dialog-content class="p-0 mat-typography">
  <div class="container-fluid">
    <div class="row bg">
      <div class="col-12">
        <div class="d-flex align-item-center justify-content-between m-0">
          <h4 class="customThemeClass"><b>Exchange Info</b></h4>
          <br /><b></b>
        </div>
      </div>
    </div>
    <div class="col-12">
      <div class="card card-body">
        <form [formGroup]="exchangeForm">
          <div class="row">
            <mat-form-field class="col-6">
              <mat-label>New Item <span class="asterisk">*</span></mat-label>
              <mat-select
                formControlName="drpExchange"
                placeholder="Product Code"
                [(value)]="data.mmProductId"
              >
                <!-- <mat-option>
                        <ngx-mat-select-search
                          placeholderLabel="Search"
                          noEntriesFoundLabel="No Matches found"
                          formControlName="txtProductSearch"
                        ></ngx-mat-select-search>
                      </mat-option> -->
                <mat-option>
                  <ngx-mat-select-search
                    formControlName="txtExchangeItem"
                    placeholderLabel="Search"
                    noEntriesFoundLabel="No Matches found"
                  ></ngx-mat-select-search
                ></mat-option>

                <mat-option
                  *ngFor="let products of filteredExchangeItem | async"
                  [value]="products.mmProductId"
                >
                  {{ products.productDescription }}
                </mat-option>
              </mat-select>
              <!-- <mat-select
                        formControlName="drpNewItem"
                        placeholder="Product Code"
                      >
                        <mat-option>
                          <ngx-mat-select-search
                            formControlName="searchControl"
                            placeholderLabel="Search"
                            noEntriesFoundLabel="No Matches found"
                            (keyup)="filterProducts()"
                          ></ngx-mat-select-search
                        ></mat-option>

                        <mat-option
                          *ngFor="let products of filteredOptions"
                          [value]="products.productId"
                        >
                          {{ products.productName }}
                        </mat-option>
                      </mat-select> -->
            </mat-form-field>
            <mat-form-field class="col-6">
              <mat-label>
                Original Item <span class="asterisk">*</span>
              </mat-label>
              <!-- <mat-select formControlName="drpItem">

                                <mat-option

                                >
                                  sample
                                </mat-option>
                                <mat-option [value]="0">Value 1</mat-option>
                                </mat-select> -->

              <mat-select
                formControlName="drpNewItem"
                placeholder="Product Code"
              >
                <mat-option>
                  <ngx-mat-select-search
                    formControlName="searchControl"
                    placeholderLabel="Search"
                    noEntriesFoundLabel="No Matches found"
                    (keyup)="filterProducts()"
                  ></ngx-mat-select-search
                ></mat-option>

                <mat-option
                  *ngFor="let products of filteredOptions"
                  [value]="products.productId"
                >
                  {{ products.productName }}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field class="col-6">
              <mat-label> User Id <span class="asterisk">*</span> </mat-label>

              <mat-select formControlName="drpCSR">
                <mat-option>
                  <ngx-mat-select-search
                    placeholderLabel="Search"
                    noEntriesFoundLabel="No Matches found"
                    formControlName="txtCSRFilter"
                    (keyup)="UserSearch($event.target.value)"
                  ></ngx-mat-select-search>
                </mat-option>
                <mat-option
                  *ngFor="let csr of filteredUsers | async"
                  [value]="csr.userId"
                >
                  {{ csr.name }}
                </mat-option>
                <!-- <mat-option [value]="0">Value 1</mat-option> -->
              </mat-select>
            </mat-form-field>

            <mat-form-field class="col-6">
              <mat-label>
                Original Product <span class="asterisk">*</span>
              </mat-label>
              <mat-select
                formControlName="drpProduct"
                placeholder="Product Code"
              >
                <!-- <mat-option>
                            <ngx-mat-select-search
                              placeholderLabel="Search"
                              noEntriesFoundLabel="No Matches found"
                              formControlName="txtProductSearch"
                            ></ngx-mat-select-search>
                          </mat-option> -->
                <mat-option>
                  <ngx-mat-select-search
                    formControlName="txtExchangeItem"
                    placeholderLabel="Search"
                    noEntriesFoundLabel="No Matches found"
                  ></ngx-mat-select-search
                ></mat-option>

                <mat-option
                  *ngFor="let products of filteredExchangeItem | async"
                  [value]="products.mmProductId"
                >
                  {{ products.productDescription }}
                </mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field class="col-lg-6">
              <mat-label
                >Order Entry Order No<span class="asterisk">*</span></mat-label
              >
              <input
                matInput
                formControlName="txtOrderNo"
                type="text"
                maxlength="40"
              />
            </mat-form-field>

            <!-- Order Entry Supplies Date -->
            <mat-form-field class="col-6">
              <mat-label>
                Order Entry Supplies Date <span class="asterisk">*</span>
              </mat-label>
              <input
                autocomplete="new-suppliesDate"
                formControlName="suppliesDate"
                matInput
                maxlength="40"
                type="text"
                placeholder="(MM/DD/YYYY)"
                [matDatepicker]="suppliesDate"
              />
              <mat-datepicker-toggle
                matSuffix
                [for]="suppliesDate"
              ></mat-datepicker-toggle>
              <mat-datepicker #suppliesDate> </mat-datepicker>
            </mat-form-field>

            <!-- Order Entry Created By -->
            <mat-form-field class="col-6">
              <mat-label>
                Order Entry Created By <span class="asterisk">*</span>
              </mat-label>
              <input
                autocomplete="new-createdBy"
                [max]="BirthdateMaxDate"
                formControlName="createdBy"
                matInput
                maxlength="40"
                type="text"
                placeholder="(MM/DD/YYYY)"
                [matDatepicker]="createdBy"
                [min]="BirthdateMinDate"
              />
              <mat-datepicker-toggle
                matSuffix
                [for]="createdBy"
              ></mat-datepicker-toggle>
              <mat-datepicker #createdBy> </mat-datepicker>
            </mat-form-field>

            <mat-form-field class="col-lg-6">
              <mat-label>Qty Exchange<span class="asterisk">*</span></mat-label>
              <input
                matInput
                formControlName="txtQty"
                type="text"
                maxlength="40"
                autocomplete="new-txtQty"
              />
            </mat-form-field>
            <div class="col-6">
              <br />
              <mat-checkbox formControlName="chxApprove"
                >Needs Approved
              </mat-checkbox>
            </div>
          </div>
        </form>
      </div>
    </div>
    <br /><br />
  </div>
</mat-dialog-content>









<!-- <mat-dialog-actions align="end">
  <button mat-button class="buttonColor" (click)="selectExchange()">
    Exchange
  </button>
  <button class="buttonCancelColor" mat-button mat-dialog-close>Close</button>
</mat-dialog-actions> -->
