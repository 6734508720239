import { HttpErrorResponse } from '@angular/common/http';
import {
  AfterViewInit,
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Button, ButtonComponent } from '@syncfusion/ej2-angular-buttons';
import {
  ActionEventArgs,
  AgendaService,
  CellClickEventArgs,
  DayService,
  DragAndDropService,
  EventClickArgs,
  EventRenderedArgs,
  EventSettingsModel,
  FieldModel,
  MonthService,
  ResizeEventArgs,
  ResizeService,
  ResourceDetails,
  Schedule,
  ScheduleComponent,
  Timezone,
  WeekService,
  WorkWeekService,
} from '@syncfusion/ej2-angular-schedule';
import { DashboardService } from 'projects/admin/src/app/admin-proxy/platform-app-management/rcm/platform-management/dashboard/dashboard.service';
import { UserService } from 'projects/admin/src/app/admin-proxy/platform-app-management/rcm/platform-management/user/user.service';
import { CreateUpdateCalendarDTO } from 'projects/on-time-schedules/src/app/onTime-proxy/rcm/on-time-management/on-time-schedule/dto/models';
import { OnTimeMasterDropdownDTO } from 'projects/patient/src/app/dropdown-proxy/dropdown-management/dropdowns/dto/on-time-schedule/models';
import { OnTimeMasterDropdownService } from 'projects/patient/src/app/dropdown-proxy/dropdown-management/dropdowns/master-dropdown-service/on-time-master-dropdown.service';
import Swal from 'sweetalert2';
import { DropDownList } from '@syncfusion/ej2-dropdowns';
import {
  createElement,
  extend,
  closest,
  remove,
  addClass,
} from '@syncfusion/ej2-base';
import { ChangeEventArgs } from '@syncfusion/ej2-angular-inputs';
import { OnTimeDropdowns } from 'projects/patient/src/app/dropdown-proxy/dropdown-management/dropdowns/on-time-dropdowns.enum';
import { Observable, of, Subscription } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { Title } from '@angular/platform-browser';
import { TableService } from 'projects/shared/src/app/table.service';
import { PatientPersonalService } from 'projects/patient/src/app/patient-proxy/patient-optimization';
import { OrganizationUnitService } from 'projects/admin/src/app/admin-proxy/platform-app-management/rcm/platform-management/organization-units';
import { FilteringEventArgs } from '@syncfusion/ej2-angular-dropdowns';
import { DatePipe } from '@angular/common';
import {
  calender_status_color_code,
  calender_status_code,
  defaultGuid,
} from 'projects/shared/src/app/enums/allenums.enum';
import { InboundDocumentService } from 'projects/admin/src/app/admin-proxy/platform-app-management/rcm/platform-management/inbound/inbound-document.service';
import { InventoryTransferStatusService } from 'projects/inventory/src/app/item-proxy/item-management/optimization/inventory-transfer-status.service';
import { MmOrderService } from 'projects/order/src/app/order-proxy/order-management/order-optimization/mm-order.service';
import { map, startWith } from 'rxjs/operators';
import { ModeOfCommunicationService } from 'projects/admin/src/app/admin-proxy/platform-app-management/rcm/platform-management/communication-mode';
import {
  PagedAndSortedResultRequestDto,
  PagedResultDto,
  isNullOrUndefined,
} from '@abp/ng.core';
import { MultiSelect } from '@syncfusion/ej2-dropdowns';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  RequiredValidator,
} from '@angular/forms';
import { CalenderScheduleAddModalComponent } from '../calender-schedule-add-modal/calender-schedule-add-modal.component';
import { CommunicationService } from 'projects/shared/src/app/services/communication.service';
import {
  ContextMenuComponent,
  MenuItemModel,
  BeforeOpenCloseMenuEventArgs,
  MenuEventArgs,
  ContextMenuModule,
  TreeViewComponent,
  DragAndDropEventArgs,
} from '@syncfusion/ej2-angular-navigations';
import { removeClass } from '@syncfusion/ej2-base';
import { DataManager, Query } from '@syncfusion/ej2-data';
import { AddNoteTabComponent } from 'projects/patient/src/app/add-note-tab/add-note-tab.component';
import { PatientCommonNotesComponent } from 'projects/shared/src/app/components/patient-common-notes/patient-common-notes.component';
import { ViewPatientLedgerComponent } from 'projects/admin/src/app/view-patient-ledger/view-patient-ledger.component';
import { error } from 'console';
import { LoaderService } from 'projects/shared/src/app/services/loader-service';
import { MatSelectChange } from '@angular/material/select';
import { GetCalendarDTO } from 'projects/admin/src/app/admin-proxy/platform-app-management/rcm/platform-management/inbound/dto';
import * as moment from 'moment'
import { CalendarScheduleAddModalV1NewComponent } from '../calender-schedule-add-modal/calendar-schedule-add-modal-v1-new/calendar-schedule-add-modal-v1-new.component';
@Component({
  selector: 'app-calendar-schedule-v3',
  templateUrl: './calendar-schedule-v3.component.html',
  styleUrls: ['./calendar-schedule-v3.component.scss'],

  encapsulation: ViewEncapsulation.None,
  providers: [
    DayService,
    WeekService,
    WorkWeekService,
    MonthService,
    AgendaService,
    ResizeService,
    DragAndDropService,
  ],
})
export class CalendarScheduleV3Component
  implements OnInit, AfterViewInit, OnDestroy
{
  defaultGuid = defaultGuid;
  @ViewChild('scheduleObj', { static: true })
  public scheduleObj: ScheduleComponent;
  userId: string = '';
  sesUserId:string;
  organizationUnitName: string;
  roleId: string = '';
  userName: string = '';
  roleName: string = '';
  calenderList: any[] = [];
  userTableData: any[] = [];
  show: boolean = true;
  public temp: string =
    '<div class="tooltip-wrap">' +
    '<div class="type">Status :&nbsp;${status}</div></div></div>' +
    '<div class="patient">Patient&nbsp;:${patientName}</div></div></div>' +
    '<div class="csr">Technician &nbsp;:&nbsp;${assignedUserName}</div></div></div>' +
    '<div class="content-area"><div class="name">Location : &nbsp; ${assignedBranchName}</></div>' +
    '<div class="time">From&nbsp;:&nbsp;${StartTime.toLocaleString()} </div>' +
    '<div class="time">To&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:&nbsp;${EndTime.toLocaleString()} </div>';
  public selectedDate: Date = new Date();
  // public eventSettings: EventSettingsModel = {
  //   dataSource: this.getTableData(),
  //   fields: {
  //     subject: { name: 'Subject', validation: { required: true } },
  //     startTime: { name: 'StartTime', validation: { required: true } },
  //     endTime: { name: 'EndTime', validation: { required: true } },
  //   },
  //   enableTooltip: true,
  //   tooltipTemplate: this.temp
  // };
  public selectedTarget: Element;
  public menuItems: MenuItemModel[] = [
    { text: 'Open', iconCss: 'e-icons e-circle-add', id: 'Save' },
    { text: 'Delete', iconCss: 'e-icons e-trash', id: 'Delete' },
    {
      text: 'Start New Order',
      iconCss: 'e-icons e-timeline-today',
      id: 'StartNewOrder',
    },
    {
      text: 'Email Confirmation',
      iconCss: 'e-icons e-edit',
      id: 'emailconfirmation',
    },
    { text: 'Add Notes', iconCss: 'e-icons e-plus', id: 'AddNotes' },
    { text: 'View Notes', iconCss: 'e-icons e-eye', id: 'ViewNotes' },
    {
      text: 'View Patient Leadger',
      iconCss: 'e-icons e-eye',
      id: 'ViewPatientLeadger',
    },
    {
      text: 'Document Imaging',
      iconCss: 'e-icons e-paste-match-destination',
      id: 'DocumentImaging',
    },
  ];

  public eventSettings: EventSettingsModel;

  public roomDataSource: Object[] = this.userTableData;
  // isSchedulePageLoading:any;
  public resourcelevel2: any;
  tenantId: string = '';
  isSetupInHome: any;
  //! calender popup open to add event
  patientDet: any[] = [];
  ModeDet: any[] = [];
  QGuid: string = '';
  startEnd: string = '';
  calenderId: string = '';
  calenderDetailId: string = '';
  assignedById: string = '';
  assignedByName: string = '';
  assignedToId: string = '';
  assignedToName: string = '';
  branchDet: any[];
  techDet: any[];
  typeDet: any[];
  userDet: any[];
  resourceDet: any[];
  templateDrp: any[];
  isValidHomeNumber: boolean = true;
  isValidCellNumber: boolean = true;
  isValidWorkNumber: boolean = true;
  isValidEmail: boolean = true;
  // isValidFrom = true;
  patientIdViaURL: string;
  patientId: string;
  docId: string;
  branchId: string;
  techId: string;
  typeId: string;
  userDesID: string;
  // modeID: any[] = ["55943a54-643f-6fa6-b0df-3a12270400dd"];
  modeID: any;
  resourceId: string;
  templateId: string;
  templateName: string;
  home: string;
  cell: string;
  work: string;
  email: string;
  setupInHome: boolean;
  subscription$: Subscription[] = [];
  selectedOption: Object;
  patientChartApiSubscription: Subscription;
  communicationApiSubscription: Subscription;
  templateApiSubscription: Subscription;
  drpModeList: any;
  lstFilterModeList: any;
  modedrp: any;
  templateDropDownList: DropDownList;
  selectedTemplateId: string = '';
  selectedTemplateName: string = '';
  isUpdate: boolean = false;
  @ViewChild('menuObj') public menuObj: ContextMenuComponent;
  UserTypeControl = new FormControl();
  BranchTypeControl = new FormControl();
  TypeNameControl = new FormControl();
  isUserLoading: boolean = false;
  isShowSpinner: boolean = false;
  scheduleSearchForm: FormGroup;
  typeDrpData: any;
  // drpTypeName: any;
  // drpBranchName: any;
  // drpUserName: any;
  saveButtonDisabled = true;
  isScheduleLoading: any;

  public drpUsertype: User[] = [];
  public lstFilterUsers: any | undefined;
  selectedUserList: string[] = null;
  UserSelectAllOption: boolean = false;
  userDesignation: string;

  public drpBranch: Branch[] = [];
  public brnachList: Observable<Branch[]> | undefined;
  BranchSelectAllOption:boolean = false;
  selectedBranchList: string[] = null;

  public drpTypeName: TypeDrp[] = [];
  description:string;
  selectedTypeList: string[] = null;
  TypeSelectAllOption:boolean = false;

  public allowDragAndDrop = true;
  public isTreeItemDropped = false;
  public draggedItemId = '';
  isRoleName:string;
  selectedUserType:string;
  @ViewChild('treeObj') public treeObj: TreeViewComponent;
  
  constructor(
    private formBuilder: FormBuilder,
    private adminService: DashboardService,
    public dialog: MatDialog,
    public title: Title,
    private PatientPersonalService: PatientPersonalService,
    private onTimeDropdown: OnTimeMasterDropdownService,
    private branchService: OrganizationUnitService,
    private activatedRoute: ActivatedRoute,
    private inboundDocumentService: InboundDocumentService,
    private tranferStatusService: InventoryTransferStatusService,
    private userService: UserService,
    private mmOrderService: MmOrderService,
    private modeOfCommunicationService: ModeOfCommunicationService,
    private communicationservice: CommunicationService,
    private loaderService: LoaderService
  ) {
    this.communicationservice.functionReloadSchedule$.subscribe(() => {
      this.setEventSettings();
    });

    this.loaderService.getLoaderState().subscribe((isScheduleLoading) => {
      this.isScheduleLoading = isScheduleLoading;
    });
  }
  ngAfterViewInit(): void {}

  ngOnInit() {
    this.tenantId = localStorage.getItem('tenantId') ?? '';
    this.adminService.userProfile().subscribe((response) => {
      this.userId = response?.userId;
      this.userName = response?.userName;
    });
    this.sesUserId = localStorage.getItem("userId") ?? "";
    const activatedRoute = this.activatedRoute.paramMap.subscribe(
      (response) => {
        this.patientId = response.get('patientId');
        this.patientIdViaURL = response.get('patientId');
        this.docId = response.get('documentId') || defaultGuid;
        if (this.patientIdViaURL !== null) {
          this.getSelectedPatientDetailsV1(this.patientIdViaURL);
        }
      }
    );
    this.getPatient('', this.patientId);
    this.getTypeDropDown();
    this.title.setTitle('Qsecure | Calender');

    this.scheduleSearchForm = this.formBuilder.group({
      // drpUserName: new FormControl(this.userId),
      // txtUserType: new FormControl(''),
      drpUsertype:new FormControl(''),
      txtUserTypeV1:new FormControl(''),
      drpBranch: new FormControl(''),      
      txtBranch:new FormControl(''),
      drpTypeName: new FormControl(''),
      txtTypeName: new FormControl(''),
    });
    // this.scheduleSearchForm.patchValue({ drpUsertype: [this.sesUserId] });
    this.hasvaluePatientSearchForm();
    this.isRoleName = localStorage.getItem('roleName');

  }
  ngOnDestroy(): void {
    this.subscription$.forEach((x) => x.unsubscribe());
  }
  formatDate(date: Date): string {
    const datePipe = new DatePipe('en-US');
    return datePipe.transform(date, 'yyyy-MM-dd HH:mm');
  }

  setEventSettings() {
    this.eventSettings = {
      dataSource: this.getTableData(),
      enableTooltip: true,
      tooltipTemplate: this.temp,
    };
  }

  resetCalender() {
    // this.scheduleSearchForm.reset();
    if(this.typeDrpData.length > 0){
      this.onTypeSelectionChange({ value : 'selectAll' } as MatSelectChange);
      }
    if(this.isRoleName === 'admin') {
      this.onSelectionUserChange({ value : 'selectAll' } as MatSelectChange);
      }else{
        this.scheduleSearchForm.patchValue({ drpUsertype: [this.sesUserId] });
      }
      this.onBranchSelectionChange({ value : 'selectAll' }as MatSelectChange);
    setTimeout(() => {
      this.setEventSettings();
    }, 1000);
  }

    //Maped Branch Drop Down
    // loadMapedBrachDropdown() {
    //   const getBranchList = this.branchService.getBranchForMappedUser()
    //     .subscribe((response) => {
    //       console.log(response);

    //       this.scheduleSearchForm.patchValue({ drpBranch: response });

    //     }, (err) => { });
    // }
  
  // loadBrachDropdown() {
  //   const getBranchList = this.branchService
  //     .getBranchList(this.tenantId)
  //     .subscribe((response) => {
  //       // this.branchDet = response;
  //       this.branchDet = [];
  //       response?.forEach((e) => {
  //         this.branchDet?.push({
  //           name: e?.organizationUnitName,
  //           id: e?.id,
  //         });
  //       });

  //       this.drpBranchName = [...this.branchDet];

  //       //Branch Search Type
  //       this.BranchTypeControl.valueChanges.subscribe((searchTerm) => {
  //         searchTerm = searchTerm.toLowerCase();
  //         this.branchDet = this.drpBranchName.filter((dx) =>
  //           dx.name.toLowerCase().includes(searchTerm)
  //         );
  //       });
  //     });
  // }

//#region Branch Drop Down
  getBranchList() {
    const getBranchList = this.branchService
      // .getBranchListV1(this.tenantId,false)
      .getBranchListBasedOnSchedule(this.tenantId)
      .subscribe(
        (response) => {
          response?.forEach((element) => {
            this.drpBranch.push({
              id: element.id,
              organizationUnitName: element.organizationUnitName,
            });
          });
          this.drpBranch=this.drpBranch.sort((a, b) => a.organizationUnitName.localeCompare(b.organizationUnitName))
          this.brnachList = this.scheduleSearchForm
            ?.get('txtBranch')
            ?.valueChanges?.pipe(
              startWith(''),
              map((value) =>
                this.drpBranch?.filter((option) =>
                  option?.organizationUnitName
                    ?.toLowerCase()
                    ?.includes(value?.toLowerCase() ?? '')
                )
              )
            );

            this.onBranchSelectionChange({ value : 'selectAll' }as MatSelectChange);
            // if(this.isRoleName === 'admin') {
            //   // this.onBranchSelectionChange({ value : 'selectAll' }as MatSelectChange);
            //   this.loadMapedBrachDropdown();
            //   }else{
            //     this.loadMapedBrachDropdown();
            //   }
        },
        (err) => {
          const data: HttpErrorResponse = err;
          // Swal.fire({
          //   icon: 'info',
          //   text: data?.error?.error?.message,
          // });
        }
      );
    this.subscription$.push(getBranchList);
  }

    //Multi Select Branch
    onBranchSelectionChange(event: MatSelectChange): void {
      this.selectedBranchList = event.value;
      if (this.selectedBranchList.includes('selectAll')) {
        // this.BranchSelectAllOption = !this.BranchSelectAllOption;
        this.BranchSelectAllOption = true;
        if (this.BranchSelectAllOption) {
          // If 'Select All' is selected, set the selectedBranchList to include all branch IDs
          this.selectedBranchList = this.drpBranch.map(branch => branch.id);
          this.scheduleSearchForm
            .get('drpBranch')
            .patchValue(this.selectedBranchList);
  
        }
      } else if (this.selectedBranchList.includes('DeselectAll')) {
        this.selectedBranchList = [];
        this.BranchSelectAllOption = false;
        this.scheduleSearchForm
          .get('drpBranch')
          .patchValue([]);
      }
      else {
        this.BranchSelectAllOption =
          this.selectedBranchList.length === this.drpBranch.length;
      }
      // this.getTableData();
    }

  //#endregion Branch Dropdown 

//#region Technician Drop Down
getUserByTenantV1() {
  this.loaderService.showLoader();
  this.isUserLoading = true;
  const getBranchList = this.userService
    // .getUsersByTenantV1()
    .getUsersByTenant()
    .subscribe(
      (response) => {
        this.isUserLoading = false;
        response?.forEach((element) => {
          this.drpUsertype.push({
            userId: element.userId,
            userDesignation: element.userDesignation,
          });
        });
        this.lstFilterUsers = this.scheduleSearchForm
          ?.get('txtUserTypeV1')
          ?.valueChanges?.pipe(
            startWith(''),
            map((value) =>
              this.drpUsertype?.filter((option) =>
                option?.userDesignation
                  ?.toLowerCase()
                  ?.includes(value?.toLowerCase() ?? '')
              )
            )
          );
          if(this.isRoleName === 'admin') {
          this.onSelectionUserChange({ value : 'selectAll' } as MatSelectChange);
          }else{
            this.scheduleSearchForm.patchValue({ drpUsertype: [this.sesUserId] });
          }
          setTimeout(() => {
            this.setEventSettings();
          }, 1000);
      },
      (err) => {
        this.loaderService.hideLoader();
        this.isUserLoading = false;
        setTimeout(() => {
          this.setEventSettings();
        }, 1000);
        const data: HttpErrorResponse = err;
        // Swal.fire({
        //   icon: 'info',
        //   text: data?.error?.error?.message,
        // });
      }
    );
  this.subscription$.push(getBranchList);
}

    
  //User Multi Select
  onSelectionUserChange(event: MatSelectChange): void {
    this.selectedUserList = event.value;
    if (this.selectedUserList.includes('selectAll')) {
      this.UserSelectAllOption = true;
      if (this.UserSelectAllOption) {

        this.selectedUserList = this.drpUsertype.map(user => user.userId);
        this.scheduleSearchForm
          .get('drpUsertype')
          .patchValue(this.selectedUserList);

      }
    } else if (this.selectedUserList.includes('DeselectAll')) {
      this.selectedUserList = [];
      this.UserSelectAllOption = false;
      this.scheduleSearchForm
        .get('drpUsertype')
        .patchValue([]);
    }
    else {
      this.UserSelectAllOption =
        this.selectedUserList.length === this.drpUsertype.length;
    }
    // this.getTableData();
  }
  //#endregion

  //#region Type Dropdown
  getTypeDropDown() {
    const TypeSearchSub$ = of(null)
      .pipe(switchMap((x) => this.inboundDocumentService.getAptclassList()))
      .subscribe((response) => {
        this.typeDrpData = response;
        this.drpTypeName = this.typeDrpData;

        //Type Search Type
        this.TypeNameControl.valueChanges.subscribe((searchTerm) => {
          searchTerm = searchTerm.toLowerCase();
          this.typeDrpData = this.drpTypeName.filter((dx) =>
            dx.description.toLowerCase().includes(searchTerm)
          );
        });
        if(this.typeDrpData.length > 0){
        this.onTypeSelectionChange({ value : 'selectAll' } as MatSelectChange);
        }
      });
  }

    //Type Multi Select
    onTypeSelectionChange(event: MatSelectChange): void {
      this.selectedTypeList = event.value;
      if (this.selectedTypeList.includes('selectAll')) {
        this.TypeSelectAllOption = true;
        if (this.TypeSelectAllOption) {
  
          this.selectedTypeList = this.drpTypeName.map(type => type.aptClassId);
          this.scheduleSearchForm
            .get('drpTypeName')
            .patchValue(this.selectedTypeList);
  
        }
      } else if (this.selectedTypeList.includes('DeselectAll')) {
        this.selectedTypeList = [];
        this.TypeSelectAllOption = false;
        this.scheduleSearchForm
          .get('drpTypeName')
          .patchValue([]);
      }
      else {
        this.TypeSelectAllOption =
          this.selectedTypeList.length === this.drpTypeName.length;
      }
    }
//#endregion

  //#region Schedule Data View
  public getTableData(): Record<string, any>[] {
    this.loaderService.showLoader();
    this.userId = localStorage.getItem('userId') ?? '';
    let selectedTypeId;
    let selectedUserId;
    let selectedBranchId;
   
    //Type Drop Down Get Value 
    if(this.TypeSelectAllOption === true) {
      selectedTypeId = null;
    }else if(this.scheduleSearchForm.value.drpTypeName.length === 0) {
      selectedTypeId = null;
    }else {
      selectedTypeId = this.scheduleSearchForm.value.drpTypeName;
    }

    //User Drop Down Get Value
    if(this.UserSelectAllOption === true) {
      selectedUserId= null;
    }else if(this.scheduleSearchForm.value.drpUsertype.length === 0) {
      selectedUserId= null;
    }else{
      selectedUserId = this.scheduleSearchForm.value.drpUsertype;
    }
    
    //Branch Drop Down Get Value
    if(this.BranchSelectAllOption === true) {
      selectedBranchId= null;
    }else if(this.scheduleSearchForm.value.drpBranch.length === 0) {
      selectedBranchId= null; 
    }else{
      selectedBranchId = this.scheduleSearchForm.value.drpBranch
    }

    const eventData: Record<string, any>[] = [];
    this.roleId = localStorage.getItem('roleId') ?? '';
    this.roleName = localStorage.getItem('roleName') ?? '';
    let getcalendarDTO: GetCalendarDTO = {
      // typeId: this.scheduleSearchForm.value.drpTypeName || null,
      // userId: this.scheduleSearchForm.value.drpUsertype || [this.userId],
      // locationId: this.scheduleSearchForm.value.drpBranch || null
      typeId : selectedTypeId || null,
      userId : selectedUserId || null,
      locationId : selectedBranchId || null,
    };
    this.inboundDocumentService
      .getCalendarV1ByDto(
        getcalendarDTO
      )
      .subscribe(
        (response) => {
          response &&
            response?.items?.forEach((elements) => {
              elements?.calendarDetails?.forEach((element) => {
                // const selectedPatientId = this.patientDet?.find(patient => patient?.id === element?.patientId);
                // const selectedPatientName = selectedPatientId ? selectedPatientId?.patientName : null;

                // const selectedUserId = this.userDet?.find(
                //   (vuser) => vuser?.id === element?.userID
                // );
                // const selectedUserName = selectedUserId
                //   ? selectedUserId?.name
                //   : null;

                  const selectedUserId = this.drpUsertype?.find(
                    (vuser) => vuser?.userId === element?.userID
                  );
                  const selectedUserName = selectedUserId
                    ? selectedUserId?.userDesignation
                    : null;

                const selectedBranchId = this.drpBranch?.find(
                  (vuser) => vuser?.id === element?.locationId
                );
                const selectedBranchName = selectedBranchId
                  ? selectedBranchId?.organizationUnitName
                  : null;

                eventData?.push({
                  EndTime: new Date(element?.toTime),
                  StartTime: new Date(element?.fromTime),
                  Subject: element?.title,
                  Id: element?.calendarId,
                  QGuid: elements?.id,
                  Guid: element?.guid,
                  Description: element?.description,
                  StartTimeZone: element?.startTimeZone,
                  EndTimeZone: element?.endTimeZone,
                  IsAllDay: element?.isAllDay,
                  Location: element?.locationId,
                  email: element?.email,
                  setupInHome: element?.setUpInHome,
                  RecurrenceRule: element?.recurrenceRule,
                  userName: elements?.userName,
                  userId: elements?.userId,
                  tech: element?.userID,
                  user: element?.userID,
                  type: element?.type,
                  typeId: element?.typeId,
                  resource: element?.resource,
                  cell: element?.cellNo,
                  phone: element?.homePhoneNo,
                  work: element?.workNo,
                  OwnerId: element?.status,
                  roleId: elements?.roleId,
                  calendarDetailsId: element?.calendarDetailsId,
                  status: element?.status,
                  colorCode: element?.colorCode,
                  roleName: elements?.roleName,
                  isDragAndDrop: true,
                  Patient: element?.patientId,
                  // patientName: selectedPatientName || '--',
                  patientName: element?.patientName || '--',
                  assignedById: element?.assignedById,
                  assignedByName: element?.assignedByName,
                  assignedToId: elements?.assignedToId,
                  assignedToName: elements?.assignedToName,
                  assignedUserName: selectedUserName || '--',
                  assignedBranchName: selectedBranchName || '--',
                  mode: elements?.modeCode,
                  templateId: elements?.templateCode,
                  documentIdV1: element?.docId,
                });
              });
            });
          this.loaderService.hideLoader();
          this.scheduleObj?.refreshEvents();
        },
        (error) => {
          this.loaderService.hideLoader();
          console.error('Error fetching Schedule details:', error);
        }
      );
    // this.scheduleObj?.addEvent(eventData);

    return eventData;
  }
//#endregion

  applyCategoryColorV0(args: EventRenderedArgs): void {
    // // Apply category color
    let colorCode: string = args.data.colorCode as string;
    if (colorCode) {
      args.element.style.backgroundColor = colorCode;
    }

    // Check if the current view is Month
    if (this.scheduleObj && this.scheduleObj.currentView === 'Month') {
      // Customize the event details here
      const eventDetails = args.element.querySelector('.e-subject');
      if (eventDetails) {
        // Example: Show only the event title (assuming the title and identifier are separated by a semicolon)
        const titleAndIdentifier = eventDetails.innerHTML;
        const title = titleAndIdentifier.split(';')[0].trim();
        eventDetails.innerHTML = title;
      }
    }

    //  Apply category color based on some condition (customize this part)
    let color = this.colorCode('Scheduled');
    // eventDetails.style.backgroundColor = color;
    args.element.style.backgroundColor = color;
  }

  applyCategoryColorV1(args: EventRenderedArgs): void {
    let categoryColor: string = args.data.colorCode as string;
    if (!args.element || !categoryColor) {
      return;
    }
    if (this.scheduleObj.currentView === 'Agenda') {
      (args.element.firstChild as HTMLElement).style.borderLeftColor =
        categoryColor;
    } else {
      args.element.style.backgroundColor = categoryColor;
    }

    // Check if the current view is Month
    if (
      this.scheduleObj &&
      (this.scheduleObj.currentView === 'Month' ||
        this.scheduleObj.currentView === 'Agenda')
    ) {
      // Customize the event details here
      const eventDetails = args.element.querySelector('.e-subject');
      if (eventDetails) {
        // Example: Show only the event title (assuming the title and identifier are separated by a semicolon)
        const titleAndIdentifier = eventDetails.innerHTML;
        const title = titleAndIdentifier.split(';')[0].trim();
        eventDetails.innerHTML = title;
      }
    }
  }

  updateBackgroundColor(colorCode: string): string {
    if (this.isValidColorCode(colorCode)) {
      return colorCode;
    } else {
      return '#20b2aa'; // default to light Sea Green if colorCode is invalid
    }
  }

  updateTextColorV2(colorCode: string): string {
    if (this.isValidColorCode(colorCode)) {
      return this.getContrastingTextColor(colorCode);
    } else {
      return '#000000'; // default to black if colorCode is invalid
    }
  }

  isValidColorCode(colorCode: string): boolean {
    // Check if colorCode is a valid hex color code
    const regex = /^#([0-9A-F]{3}){1,2}$/i;
    return regex.test(colorCode);
  }

  updateTextColor(colorCode: string) {
    if (colorCode) {
      return this.getContrastingTextColor(colorCode);
    } else {
      return '#000000'; // default to black
    }
  }

  getContrastingTextColor(hexColor: string): string {
    if (!hexColor) return '#000000'; // default to black if no color provided

    // Remove the hash if present
    hexColor = hexColor.replace('#', '');

    // Convert to RGB
    const r = parseInt(hexColor.substring(0, 2), 16);
    const g = parseInt(hexColor.substring(2, 4), 16);
    const b = parseInt(hexColor.substring(4, 6), 16);

    // Calculate luminance
    const luminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255;

    // If luminance is high, return dark color (black), otherwise return light color (white)
    return luminance > 0.5 ? '#000000' : '#FFFFFF';
  }
  applyCategoryColor(args: EventRenderedArgs): void {
    const eventData = args.data;
    const eventElement = args.element;

    eventElement.querySelector('.e-subject').innerHTML = `
        Status &nbsp;: ${eventData?.status} <br>
        Patient : ${eventData?.patientName} <br>
        Technician &nbsp;: ${eventData?.assignedUserName} <br>
        Location   : ${eventData?.assignedBranchName}
      `;

    let categoryColor: string = args.data.colorCode as string;
    if (!args.element || !categoryColor) {
      return;
    }

    if (this.scheduleObj.currentView === 'Agenda') {
      // Customize the style for Agenda view
      (args.element.firstChild as HTMLElement).style.borderLeftColor =
        categoryColor;
    } else {
      // Customize the style for other views
      args.element.style.backgroundColor =
        this.updateBackgroundColor(categoryColor);
      args.element.style.color = this.updateTextColor(categoryColor);
    }

    // Check if the current view is 'Agenda'
    if (this.scheduleObj.currentView === 'Agenda') {
      // Find the event subject and location elements
      const eventSubject = args.element.querySelector('.e-subject');
      const eventLocation = args.element.querySelector('.e-location');

      // Check if both subject and location elements exist
      if (eventSubject && eventLocation) {
        // Remove the location element from the event display
        eventLocation.remove();
      }

      // Get the text content of the subject element and remove the last comma if it exists
      const subjectText = eventSubject?.textContent?.trim().replace(/,$/, '');
      // Update the subject element's text content with the modified text (without the last comma)
      if (subjectText) {
        eventSubject.textContent = subjectText;
      }
    }

    if (this.scheduleObj.currentView === 'Month') {
      // Customize the event details here for Agenda and Month views
      const eventDetails = args.element.querySelector('.e-subject');
      if (eventDetails) {
        const titleAndIdentifier = eventDetails.innerHTML;
        const title = titleAndIdentifier.split(';')[0].trim();
        eventDetails.innerHTML = title;
      }
    }

    // Check if the current view is 'Month'
    if (
      this.scheduleObj.currentView === 'Week' ||
      this.scheduleObj.currentView === 'Day' ||
      this.scheduleObj.currentView === 'WorkWeek'
    ) {
      // Find the event container and then find the location element within it
      const eventContainer = args.element.querySelector(
        '.e-appointment-details'
      );
      const eventLocationMonth = eventContainer?.querySelector('.e-location');

      // Check if the location element exists in the month view within the container
      if (eventLocationMonth) {
        // Remove the location element from the event display in the month view
        eventLocationMonth.remove();
      }
    }
  }

  getSelectedPatientDetailsV1(patientId: string) {
    if (patientId !== null && patientId !== undefined && patientId !== '') {
      this.inboundDocumentService
        .getScheduleDetailsByPatientID(patientId)
        .subscribe(
          (response) => {
            this.patientId = response.patientId || '';
            this.branchId = response.locationId || '';
            this.techId = response.tech || '';
            this.typeId = response.type || '';
            this.resourceId = response.resource || '';
            this.home = response?.homePhoneNo ?? '';
            this.cell = response?.cellNo ?? '';
            this.work = response?.workNo ?? '';
            this.email = response?.email ?? '';
            this.docId = response?.docId || defaultGuid;
            this.setupInHome = response?.setUpInHome ?? false;
          },
          (error) => {
            console.error('Error fetching patient details:', error);
            // this.docId = defaultGuid;
          }
        );
    }
  }

  getSelectedPatientDetails(patientId: string): Observable<any> {
    return new Observable((observer) => {
      if (patientId !== null && patientId !== undefined && patientId !== '') {
        this.inboundDocumentService
          .getScheduleDetailsByPatientID(patientId)
          .subscribe(
            (response) => {
              this.patientId = response.patientId || '';
              this.branchId = response.locationId || '';
              this.techId = response.tech || '';
              this.typeId = response.type || '';
              this.resourceId = response.resource || '';
              this.home = response?.homePhoneNo ?? '';
              this.cell = response?.cellNo ?? '';
              this.work = response?.workNo ?? '';
              this.email = response?.email ?? '';
              this.docId = response?.docId || defaultGuid;
              this.setupInHome = response?.setUpInHome ?? false;

              // this.docId = response?.docId || defaultGuid;
              // observer.next(); // Notify the promise that the operation is complete
              // observer.complete();
            },
            (error) => {
              console.error('Error fetching patient details:', error);
              // this.docId = defaultGuid;
              observer.next(); // Notify the promise that the operation is complete
              observer.complete();
            }
          );
      }
      observer.next(); // Notify the promise that the operation is complete
      observer.complete();
    });
  }

 getPatient(value: any, patientId: string) {
    // this.getUserByTenant();
    // this.loadBrachDropdown();
    this.getUserByTenantV1();
   this.getBranchList();
    // this.isSchedulePageLoading=true;
    if (this.patientChartApiSubscription) {
      this.patientChartApiSubscription.unsubscribe();
    }
    this.patientChartApiSubscription = this.mmOrderService
      .getPatientChartNoV3BySSearchParamsAndPatient(value, patientId)
      .subscribe(
        (response) => {
          this.patientDet = [];
          response?.forEach((e, i) => {
            this.patientDet?.push({
              name: e?.ddValue,
              id: e?.patientId,
              chartId: e?.chartId,
              patientName: e?.patientName,
            });
          });
          // setTimeout(() => {
          //   this.setEventSettings();
          // }, 1000);
          return this.patientDet;
        },
        (err) => {
          setTimeout(() => {
            this.setEventSettings();
          }, 1000);
        }
      );
  }

  getPatientV1(
    value: any
  ): Observable<{ name: string; id: string; chartId: string }[]> {
    if (this.patientChartApiSubscription) {
      this.patientChartApiSubscription.unsubscribe();
    }
    return new Observable((observer) => {
      this.patientChartApiSubscription = this.mmOrderService
        .getPatientChartNoV3BySSearchParamsAndPatient(value, this.patientId)
        .subscribe(
          (response) => {
            const patientDet: { name: string; id: string; chartId: string }[] =
              [];
            response?.forEach((e, i) => {
              patientDet.push({
                name: e?.ddValue,
                id: e?.patientId,
                chartId: e.chartId,
              });
            });
            observer.next(patientDet);
            observer.complete();
          },
          (err) => {
            observer.error(err);
          }
        );
    });
  }

  getTech() {
    this.techDet = [];
    this.techDet?.push({
      name: 'Tech',
      id: defaultGuid,
    });
  }

  //GET USER DROP DOWN
  // getUserByTenant() {
  //   this.isUserLoading = true;
  //   const getUserList$ = of(null)
  //     .pipe(switchMap((x) => this.userService.getUsersByTenant()))
  //     .subscribe(
  //       (response) => {
  //         this.isUserLoading = false;
  //         this.userDet = [];
  //         response?.forEach((e) => {
  //           this.userDet?.push({
  //             name: e.userDesignation,
  //             id: e?.userId,
  //           });
  //         });
  //         this.userDet.sort((a, b) => a.name.localeCompare(b.name));

  //         this.drpUserName = this.userDet;

  //         this.scheduleSearchForm.patchValue({ drpUserName: this.userId });
  //         //User Search Type
  //         this.UserTypeControl.valueChanges.subscribe((searchTerm) => {
  //           searchTerm = searchTerm.toLowerCase();
  //           this.userDet = this.drpUserName.filter((dx) =>
  //             dx.name.toLowerCase().includes(searchTerm)
  //           );
  //         });
  //       },
  //       (error) => {
  //         this.isUserLoading = false;
  //       }
  //     );
  // }



  getType() {
    ///Type link dropdown

    const TypeSearchSub$ = of(null)
      .pipe(switchMap((x) => this.tranferStatusService.ServiceTypeDetails()))
      .subscribe((response) => {
        this.typeDet = [];
        response?.items.forEach((e) => {
          this.typeDet?.push({
            name: e.productCategoryName,
            id: e?.id,
          });
        });
        // Sort the typeDet array by the 'name' property
        this.typeDet.sort((a, b) => a.name.localeCompare(b.name));
      });
  }

  hasvaluePatientSearchForm() {
    this.scheduleSearchForm.valueChanges.subscribe(() => {
      const hasValue = Object.values(this.scheduleSearchForm.controls).some(
        (control) => control.value
      );
      this.saveButtonDisabled = !hasValue;
    });
  }

  fnSearchCalendarList() {   
    this.setEventSettings();
  }

  getResource() {
    this.resourceDet = [];
    this.resourceDet?.push({
      name: 'Moniter Medical',
      id: defaultGuid,
    });
  }
  homeInputEle: HTMLInputElement;

  onPopupOpen(args) {
    const eventObj: any = args.data instanceof Array ? args.data[0] : args.data;
    if (
      (args.target &&
        !args.target.classList.contains('e-appointment') &&
        args.type === 'QuickInfo') ||
      args.type === 'Editor'
    ) {
      args.cancel = this.onEventCheck(args);
      // console.log(args.cancel);

      if (args.cancel === true && eventObj?.QGuid === undefined) {
        args.cancel = true;
      } else {
        args.cancel = true;
        this.openAddScheduleModal(args);
      }
    }
  }

  // public onEventCheck(args: any): boolean {
  //   const eventObj: any = args.data instanceof Array? args.data[0] : args.data;
  
  //   return eventObj.StartTime < new Date();
  // }


  public onEventCheck(args: any): boolean {
    
    const currentDate = new Date();
    
    const currentDateV1 = currentDate.toLocaleString('en-US', { timeZone: 'America/Chicago' });

    const selectedDate = args.data?.StartTime;

    const selectedDateChicago = new Date(selectedDate);
    
    const seleteDateV1 = selectedDateChicago.toLocaleString('en-US');

    const seleteDateV2 = new Date(seleteDateV1);
    const currentDateV2 = new Date(currentDateV1);

    return seleteDateV2 < currentDateV2;
  }


     //GET Template DROP DOWN
     getTemplate(modeId: number[]) {
      if (this.templateApiSubscription) {
        this.templateApiSubscription.unsubscribe();
      }
      this.templateApiSubscription = this.inboundDocumentService
        .getListSMSTemplateId(modeId)
        .subscribe(
          (response) => {
            this.templateDrp = response?.items;
          },
          (err) => { }
        );
    }

  // onPopupOpen(args) {
  //   this.assignedById = args?.data?.assignedById;
  //   this.assignedByName = args?.data?.assignedByName;
  //   this.assignedToId = args?.data?.assignedToId;
  //   this.assignedToName = args?.data?.assignedToName;
  //   this.startEnd =
  //     args.data.EndTime ??
  //     'Tue Nov 28 2023 00:00:00 GMT+0530 (India Standard Time)';
  //   this.calenderDetailId = args.data.calendarDetailsId ?? null;

  //   // button disable for past date
  //   if (
  //     args?.data?.EndTime < new Date() &&
  //     args?.data?.StartTime !== '' &&
  //     args?.data?.Subject == undefined &&
  //     args?.data?.startTime !== '' &&
  //     args?.data?.EndTime !== ''
  //   ) {
  //     if (args.type === 'QuickInfo' || args.type === 'Editor') {
  //       args.cancel = true;
  //     }
  //   }
  //   if(args?.data?.mode !== "" && args?.data?.mode !== null && args?.data?.mode !== undefined){
  //     this.getTemplate(args?.data?.mode);
  //   }

  //   if (args.type === 'Editor') {
  //     let statusElement: HTMLInputElement = args.element.querySelector(
  //       '#EventType'
  //     ) as HTMLInputElement;
  //     let dropDownListObject: DropDownList = new DropDownList({
  //       placeholder: 'Choose status',
  //       value: statusElement?.value,
  //       dataSource: [
  //         { text: 'Day', value: 'Day' },
  //         { text: 'Week', value: 'Week' },
  //         { text: 'WorkWeek', value: 'WorkWeek' },
  //         { text: 'Month', value: 'Month' },
  //       ],
  //     });
  //     dropDownListObject.appendTo(statusElement);

  //     // Clear existing custom field row if it exists
  //     const existingCustomFieldRow =
  //       args.element.querySelector('.custom-field-row');
  //     if (existingCustomFieldRow) {
  //       existingCustomFieldRow.remove();
  //     }

  //     // if (!args.element.querySelector('.custom-field-row')) {
  //     let row: HTMLElement = createElement('div', {
  //       className: 'custom-field-row',
  //     });

  //     let formElement: HTMLElement =
  //       args.element.querySelector('.e-schedule-form');
  //     let titleLocationRow: HTMLElement = args.element.querySelector(
  //       '.e-title-location-row'
  //     );

  //     // Insert the custom field row after the last default field
  //     let lastDefaultField: HTMLElement =
  //       formElement.lastElementChild as HTMLElement;
  //     formElement.insertBefore(row, lastDefaultField.nextSibling);

  //     // Add Branch Dropdown
  //     //#region
  //     let branchContainer: HTMLElement = createElement('div', {
  //       className: 'custom-field-container',
  //     });
  //     let branchInputEle: HTMLInputElement = createElement('input', {
  //       className: 'e-field',
  //       attrs: { name: 'Branch' },
  //     }) as HTMLInputElement;
  //     branchContainer.appendChild(branchInputEle);
  //     row.appendChild(branchContainer);
  //     let branchDropDownList: DropDownList = new DropDownList({
  //       dataSource: this.branchDet,
  //       fields: { text: 'name', value: 'id' },
  //       // value: args.data.branchId || this.branchId,
  //       // value: '395ca6b0-9d02-94b2-4d0c-3a0f9e40a596',
  //       value: args.data.Location || this.branchId || '',
  //       floatLabelType: 'Always',
  //       placeholder: 'Branch Name',
  //       allowFiltering: true,
  //       filterBarPlaceholder: 'Search',
  //       filtering: (e: FilteringEventArgs) => {
  //         const filterText = e.text.toLowerCase();
  //         e.updateData(
  //           this.branchDet.filter((branch) => {
  //             const branchDetails = (branch.name || '').toLowerCase();
  //             return branchDetails.includes(filterText);
  //           })
  //         );
  //       },
  //     });
  //     branchDropDownList.appendTo(branchInputEle);
  //     branchInputEle.setAttribute('name', 'branchId');
  //     //#endregion

  //     // Add Patient Dropdown
  //     //#region

  //     // // if (!args.data.patientId) args.data.patientId = this.patientId;
  //     let patientContainer: HTMLElement = createElement('div', {
  //       className: 'custom-field-container',
  //     });
  //     let inputEle: HTMLInputElement = createElement('input', {
  //       className: 'e-field',
  //       attrs: { name: 'Patient', required: 'true' },
  //       // , placeholder: "Please type the patient's name; it will only show if it exists."
  //     }) as HTMLInputElement;
  //     patientContainer.appendChild(inputEle);
  //     // document.body.appendChild(inputEle);

  //     row.appendChild(patientContainer);
  //     // Assuming 'row' is the parent element that exists in the DOM when the page loads
  //     row.addEventListener('focusin', (event) => {
  //       const target = event.target as HTMLElement;
  //       if (target.tagName === 'INPUT' && target.getAttribute('name') === 'Patient') {

  //         // Clear existing loaded details and trigger a new search
  //         patientDropDownList.dataSource = []; // Clear existing data
  //         patientDropDownList.dataBind();
  //         // Perform a new search or fetch initial data
  //         this.getPatientV1('').subscribe(
  //           (patientDet) => {

  //             patientDropDownList.dataSource = patientDet;
  //             patientDropDownList.dataBind();
  //           },
  //           (err) => {
  //             console.error('Error fetching patient data:', err);
  //           }
  //         );
  //       }
  //     });
  //     let patientDropDownList: DropDownList = new DropDownList({
  //       dataSource: this.patientDet,
  //       fields: { text: 'name', value: 'id' },
  //       // value: (<{ [key: string]: Object }>args.data).Patient as string,
  //       // value: args.data.patientId || this.patientIdViaURL,
  //       value: args.data.Patient || this.patientIdViaURL || '',
  //       floatLabelType: 'Always',
  //       placeholder: 'Patient Name ',
  //       // placeholder: "Please type the patient's name; it will only show if it exists.",
  //       allowFiltering: true,
  //       filterBarPlaceholder: 'Search',
  //       filtering: (e: FilteringEventArgs) => {
  //         const filterText = e.text.toLowerCase();

  //         if (filterText === '') {
  //           // Filter text is empty, load all data
  //           patientDropDownList.dataSource = this.patientDet;
  //           patientDropDownList.dataBind();
  //         } else {
  //           // Filter text is present, call the API
  //           this.getPatientV1(filterText).subscribe(
  //             (patientDet) => {

  //               patientDropDownList.dataSource = patientDet;
  //               patientDropDownList.dataBind();
  //             },
  //             (err) => {
  //               console.error('Error fetching patient data:', err);
  //             }
  //           );
  //         }

  //         // e.updateData(
  //         //   this.patientDet.filter((patient) => {
  //         //     const patientDetails = (patient.name || '').toLowerCase();

  //         //     return patientDetails.includes(filterText);
  //         //   })
  //         // );
  //       },
  //       change: (e: ChangeEventArgs) => {
  //         if (e.value === null) {
  //           // this.getSelectedPatientDetailsV1()
  //         } else {
  //           this.getSelectedPatientDetailsV1(String(e.value));
  //         }
  //         // this.patientId = String(e.value);
  //         // this.getSelectedPatientDetails(String(e.value));
  //         // Update args.data.patientId if needed
  //         // args.data.patientId = e.value as string;
  //       },
  //     });
  //     patientDropDownList.appendTo(inputEle);
  //     // inputEle.addEventListener('focus', () => {

  //     //   patientDropDownList.dataSource = this.patientDet; // Set initial data
  //     //   patientDropDownList.dataBind();
  //     // });

  //     inputEle.setAttribute('name', 'patientId');

  //     //           // Get the select element by its name
  //     // const patientSelect = document.querySelector('select[name="Patient"]') as HTMLSelectElement;

  //     // // Add event listener for the 'focus' event
  //     // patientSelect.addEventListener('focus', (event) => {
  //     //   // Trigger your desired action here when the dropdown gains focus

  //     //   // You can call a function, update UI, or perform any other action when the dropdown is focused
  //     // });

  //     // const patientSelectId = document.querySelector('select[name="patientId"]') as HTMLSelectElement;

  //     // // Add event listener for the 'focus' event
  //     // patientSelectId.addEventListener('focus', (event) => {
  //     //   // Trigger your desired action here when the dropdown gains focus

  //     //   // You can call a function, update UI, or perform any other action when the dropdown is focused
  //     // });

  //     // // Add focus event listener to input element
  //     // inputEle.addEventListener('focus', () => {

  //     //   // Load full data when the input is focused
  //     //   patientDropDownList.dataSource = this.patientDet;
  //     //   patientDropDownList.dataBind();
  //     // });

  //     //       // Add focus event listener to input element
  //     // inputEle.addEventListener('focus', () => {

  //     // });

  //     // // Trigger focus manually for testing
  //     // inputEle.focus();

  //     //       // Create patient container
  //     // let patientContainer: HTMLElement = createElement('div', {
  //     //   className: 'custom-field-container',
  //     // });

  //     // // Create input element
  //     // let inputEle: HTMLInputElement = createElement('input', {
  //     //   className: 'e-field',
  //     //   attrs: { name: 'Patient' },
  //     // }) as HTMLInputElement;

  //     // // Append input element to patient container
  //     // patientContainer.appendChild(inputEle);

  //     // // Append patient container to the row or another container
  //     // row.appendChild(patientContainer);

  //     // // Add focus event listener to input element
  //     // inputEle.addEventListener('focus', () => {

  //     // });

  //     // // Initialize patient dropdown list
  //     // let patientDropDownList: DropDownList = new DropDownList({
  //     //   dataSource: this.patientDet,
  //     //   fields: { text: 'name', value: 'id' },
  //     //   value: args.data.Patient || this.patientIdViaURL || '',
  //     //   floatLabelType: 'Always',
  //     //   placeholder: 'Patient Name ',
  //     //   allowFiltering: true,
  //     //   filterBarPlaceholder: 'Search',
  //     //   // Filtering and change event handlers remain unchanged from your original code
  //     // });

  //     // // Append dropdown list to input element
  //     // patientDropDownList.appendTo(inputEle);
  //     // inputEle.setAttribute('name', 'patientId');

  //     //#endregion

  //     // Add Tech Dropdown
  //     //#region
  //     // let techContainer: HTMLElement = createElement('div', {
  //     //   className: 'custom-field-container',
  //     // });
  //     // let techInputEle: HTMLInputElement = createElement('input', {
  //     //   className: 'e-field',
  //     //   attrs: { name: 'Tech' },
  //     // }) as HTMLInputElement;
  //     // techContainer.appendChild(techInputEle);
  //     // row.appendChild(techContainer);
  //     // let techDropDownList: DropDownList = new DropDownList({
  //     //   dataSource: this.techDet,
  //     //   fields: { text: 'name', value: 'id' },
  //     //   // value: (<{ [key: string]: Object }>args.data).Patient as string,
  //     //   value: args.data.tech || this.techId || '',
  //     //   floatLabelType: 'Always',
  //     //   placeholder: 'Tech Name',
  //     //   allowFiltering: true,
  //     //   filterBarPlaceholder: 'Search',
  //     //   filtering: (e: FilteringEventArgs) => {
  //     //     const filterText = e.text.toLowerCase();
  //     //     e.updateData(
  //     //       this.techDet.filter((tech) => {
  //     //         const TechDetails = (tech.name || '').toLowerCase();
  //     //         return TechDetails.includes(filterText);
  //     //       })
  //     //     );
  //     //   },
  //     // });
  //     // techDropDownList.appendTo(techInputEle);
  //     // techInputEle.setAttribute('name', 'techId');
  //     //#endregion

  //     // Add User Dropdown
  //     // #region
  //     let UserContainer: HTMLElement = createElement('div', {
  //       className: 'custom-field-container',
  //     });
  //     let userInputEle: HTMLInputElement = createElement('input', {
  //       className: 'e-field',
  //       attrs: { name: 'User' },
  //     }) as HTMLInputElement;
  //     UserContainer.appendChild(userInputEle);
  //     row.appendChild(UserContainer);
  //     let userDropDownList: DropDownList = new DropDownList({
  //       dataSource: this.userDet,
  //       fields: { text: 'name', value: 'id' },
  //       // value: (<{ [key: string]: Object }>args.data).Type as string,
  //       value: args.data.user || this.userDesID || null,
  //       floatLabelType: 'Always',
  //       placeholder: 'Select User',
  //       allowFiltering: true,
  //       filterBarPlaceholder: 'Search',
  //       filtering: (e: FilteringEventArgs) => {
  //         const filterText = e.text.toLowerCase();
  //         e.updateData(
  //           this.userDet.filter((user) => {
  //             const UserDetails = (user.name || '').toLowerCase();
  //             return UserDetails.includes(filterText);
  //           })
  //         );
  //       },
  //     });
  //     userDropDownList.appendTo(userInputEle);
  //     userInputEle.setAttribute('name', 'userDesID');
  //     //#endregion

  //     // Add Type Dropdown
  //     //#region
  //     let typeContainer: HTMLElement = createElement('div', {
  //       className: 'custom-field-container',
  //     });
  //     let typeInputEle: HTMLInputElement = createElement('input', {
  //       className: 'e-field',
  //       attrs: { name: 'Type' },
  //     }) as HTMLInputElement;
  //     typeContainer.appendChild(typeInputEle);
  //     row.appendChild(typeContainer);
  //     let typeDropDownList: DropDownList = new DropDownList({
  //       dataSource: this.typeDet,
  //       fields: { text: 'name', value: 'id' },
  //       // value: (<{ [key: string]: Object }>args.data).Type as string,
  //       value: args.data.type || this.typeId || null,
  //       floatLabelType: 'Always',
  //       placeholder: 'Type Name',
  //       allowFiltering: true,
  //       filterBarPlaceholder: 'Search',
  //       filtering: (e: FilteringEventArgs) => {
  //         const filterText = e.text.toLowerCase();
  //         e.updateData(
  //           this.typeDet.filter((type) => {
  //             const TypeDetails = (type.name || '').toLowerCase();
  //             return TypeDetails.includes(filterText);
  //           })
  //         );
  //       },
  //     });
  //     typeDropDownList.appendTo(typeInputEle);
  //     typeInputEle.setAttribute('name', 'typeId');
  //     //#endregion

  //     // Add Resource Dropdown
  //     //#region
  //     let resContainer: HTMLElement = createElement('div', {
  //       className: 'custom-field-container',
  //     });
  //     let resInputEle: HTMLInputElement = createElement('input', {
  //       className: 'e-field',
  //       attrs: { name: 'Resourcess' },
  //     }) as HTMLInputElement;
  //     resContainer.appendChild(resInputEle);
  //     row.appendChild(resContainer);
  //     let resDropDownList: DropDownList = new DropDownList({
  //       dataSource: this.resourceDet,
  //       fields: { text: 'name', value: 'id' },
  //       // value: (<{ [key: string]: Object }>args.data).Resource as string,
  //       value: args.data.resource || this.resourceId || '',
  //       floatLabelType: 'Always',
  //       placeholder: 'Resource',
  //       allowFiltering: true,
  //       filterBarPlaceholder: 'Search',
  //       filtering: (e: FilteringEventArgs) => {
  //         const filterText = e.text.toLowerCase();
  //         e.updateData(
  //           this.resourceDet.filter((resource) => {
  //             const resourceDetails = (resource.name || '').toLowerCase();
  //             return resourceDetails.includes(filterText);
  //           })
  //         );
  //       },
  //     });
  //     resDropDownList.appendTo(resInputEle);
  //     resInputEle.setAttribute('name', 'resourceId');
  //     //#endregion

  //     // Mode of Communication Dropdown
  //     // #region
  //     let CommunicateContainer: HTMLElement = createElement('div', {
  //       className: 'custom-field-container',
  //     });

  //     // Create a MultiSelect element
  //     let multiSelectElement: HTMLInputElement = createElement('input', {
  //       className: 'e-field',
  //       attrs: { name: 'mode' },
  //     }) as HTMLInputElement;
  //     CommunicateContainer.appendChild(multiSelectElement);

  //     // Initialize the MultiSelect component
  //     let communicationMultiSelect: MultiSelect = new MultiSelect({
  //       dataSource: this.modedrp,
  //       fields: { text: 'name', value: 'id' },
  //       value: args.data.mode || this.modeID || '',
  //       floatLabelType: 'Always',
  //       placeholder: 'Mode of Communication',
  //       allowFiltering: true,
  //       filterBarPlaceholder: 'Search',
  //       mode: 'Box', // Set the mode to 'Box' for multiple selection
  //       filtering: (e: FilteringEventArgs) => {
  //         const filterText = e.text.toLowerCase();
  //         e.updateData(
  //           this.modedrp.filter((mode) => {
  //             const modeDetails = (mode.name || '').toLowerCase();
  //             return modeDetails.includes(filterText);
  //           })
  //         );
  //       },
  //       change: (e: ChangeEventArgs) => {
  //         const selectedItems = communicationMultiSelect.value as number[];
  //         this.getTemplate(selectedItems);
  //         const lastIndex = selectedItems[selectedItems.length - 1];
  //         if (lastIndex === 4) {
  //           communicationMultiSelect.value = [4];
  //         } else {
  //           const filteredItems = selectedItems.filter(item => item !== 4);
  //           communicationMultiSelect.value = filteredItems;
  //         }
  //         communicationMultiSelect.refresh();
  //       }
  //     });

  //     communicationMultiSelect.appendTo(multiSelectElement);
  //     multiSelectElement.setAttribute('name', 'modeID');
  //     row.appendChild(CommunicateContainer);
  //     //#endregion

  //     // Add Template Dropdown
  //     //#region
  //     let templateContainer: HTMLElement = createElement('div', {
  //       className: 'custom-field-container',
  //     });
  //     let templateInputEle: HTMLInputElement = createElement('input', {
  //       className: 'e-field',
  //       attrs: { name: 'TemplateCode'},
  //     }) as HTMLInputElement;
  //     templateContainer.appendChild(templateInputEle);
  //     row.appendChild(templateContainer);
  //     this.templateDropDownList = new DropDownList({
  //       dataSource: this.templateDrp,
  //       fields: { text: 'name', value: 'id' },
  //       // value: (<{ [key: string]: Object }>args.data).Resource as string,
  //       value: args.data.templateCode || this.templateId || '',
  //       floatLabelType: 'Always',
  //       placeholder: 'Template',
  //       allowFiltering: true,
  //       filterBarPlaceholder: 'Search',
  //       filtering: (e: FilteringEventArgs) => {
  //         const filterText = e.text.toLowerCase();
  //         e.updateData(
  //           this.templateDrp.filter((template) => {
  //             const templateDetails = (template.name || '').toLowerCase();
  //             return templateDetails.includes(filterText);
  //           })
  //         );
  //       },
  //       change: (e: ChangeEventArgs) => {
  //         const selectedTemplate = templateInputEle.value as string;
  //         const selectedTemplateName = e.value.toString();
  //         this.selectedTemplateId = selectedTemplate;
  //         this.selectedTemplateName = selectedTemplateName;
  //       }
  //     });
  //     this.templateDropDownList.appendTo(templateInputEle);
  //     templateInputEle.setAttribute('name', 'templateId');
  //     //#endregion

  //     // Add Home
  //     //#region
  //     let homeContainer: HTMLElement = createElement('div', {
  //       className: 'custom-field-container',
  //     });

  //     // Add Label for Home Textbox
  //     let homeLabel: HTMLLabelElement = createElement('label', {
  //       className: 'home-label',
  //       innerHTML: 'Home:', // Provide the desired label text
  //     }) as HTMLLabelElement;

  //     homeContainer.appendChild(homeLabel);

  //     // let homeInputEle: HTMLInputElement = createElement('input', {
  //     //   className:
  //     //     'e-float-input e-control-wrapper form-control mt-0 e-subject e-field',
  //     //   attrs: {
  //     //     name: 'HomeText',
  //     //     type: 'tel',
  //     //     pattern: '[0-9]{1,10}',
  //     //     title: 'Enter a valid Home phone number with a maximum of 10 digits',
  //     //     placeholder: '+1 country code is not needed.',
  //     //     // value:
  //     //     //   args.data.HomeText !== undefined && args.data.HomeText !== null
  //     //     //     ? args.data.HomeText
  //     //     //     : this.home || '',
  //     //     value: args.data.phone || this.home || '',
  //     //   }, // Set pattern and title attributes
  //     // }) as HTMLInputElement;

  //     let homeInputEle: HTMLInputElement = createElement('input', {
  //       className:
  //         'e-float-input e-control-wrapper form-control mt-0 e-subject e-field',
  //       attrs: {
  //         name: 'HomeText',
  //         type: 'tel',
  //         pattern: '\\+[0-9]{1,4}[0-9]{1,10}',
  //         title: 'Enter a valid Home number with a country code and maximum of 10 digits after the country code',
  //         placeholder: '+ Country code and home number',
  //         value: args.data.phone || this.home || '',
  //       },
  //     }) as HTMLInputElement;

  //     // Add input event listener for validation
  //     homeInputEle.maxLength = 13;
  //     homeInputEle.addEventListener('input', () => {
  //       this.isValidHomeNumber = homeInputEle.checkValidity();
  //       const validationHomeMessage = homeInputEle.validationMessage;

  //       // Display validation message
  //       const validationMessageContainerForHome = homeContainer.querySelector(
  //         '.validation-message'
  //       );
  //       if (validationMessageContainerForHome) {
  //         validationMessageContainerForHome.remove();
  //       }

  //       if (!this.isValidHomeNumber) {
  //         const validationMessageElementForHome = createElement('div', {
  //           className: 'validation-message',
  //           innerHTML: validationHomeMessage,
  //           styles: 'color: red; font-size: 12px; margin-top: 5px;',
  //         });

  //         homeContainer.appendChild(validationMessageElementForHome);
  //       }
  //     });
  //     homeContainer.appendChild(homeInputEle);
  //     row.appendChild(homeContainer);
  //     //#endregion

  //     // Add Cell
  //     //#region
  //     let cellContainer: HTMLElement = createElement('div', {
  //       className: 'custom-field-container',
  //     });
  //     // Add Label for Cell Textbox
  //     let cellLabel: HTMLLabelElement = createElement('label', {
  //       className: 'cell-label',
  //       innerHTML: 'Cell (required for schedule if SMS is selected):', // Provide the desired label text
  //     }) as HTMLLabelElement;

  //     cellContainer.appendChild(cellLabel);

  //     // let cellInputEle: HTMLInputElement = createElement('input', {
  //     //   className:
  //     //     'e-float-input e-control-wrapper form-control mt-0 e-subject e-field',
  //     //   attrs: {
  //     //     name: 'CellText',
  //     //     type: 'tel',
  //     //     pattern: '[0-9]{1,10}',
  //     //     title: 'Enter a valid Cell number with a maximum of 10 digits',
  //     //     placeholder: '+1 country code is not needed.',
  //     //     // value:
  //     //     //   args.data.CellText !== undefined && args.data.CellText !== null
  //     //     //     ? args.data.CellText
  //     //     //     : this.cell || '',
  //     //     value: args.data.cell || this.cell || '',
  //     //   },
  //     // }) as HTMLInputElement;
  //     let cellInputEle: HTMLInputElement = createElement('input', {
  //       className:
  //         'e-float-input e-control-wrapper form-control mt-0 e-subject e-field',
  //       attrs: {
  //         name: 'CellText',
  //         type: 'tel',
  //         pattern: '\\+[0-9]{1,4}[0-9]{1,10}',
  //         title: 'Enter a valid Cell number with a country code and maximum of 10 digits after the country code',
  //         placeholder: '+ Country code and cell number',
  //         value: args.data.cell || this.cell || '',
  //       },
  //     }) as HTMLInputElement;
  //     // Add input event listener for validation
  //     cellInputEle.maxLength = 13;
  //     cellInputEle.addEventListener('input', () => {
  //       this.isValidCellNumber = cellInputEle.checkValidity();
  //       const validationCellMessage = cellInputEle.validationMessage;

  //       // Display validation message
  //       const validationMessageContainerForCell = cellContainer.querySelector(
  //         '.validation-message'
  //       );
  //       if (validationMessageContainerForCell) {
  //         validationMessageContainerForCell.remove();
  //       }

  //       if (!this.isValidCellNumber) {
  //         const validationMessageElementForCell = createElement('div', {
  //           className: 'validation-message',
  //           innerHTML: validationCellMessage,
  //           styles: 'color: red; font-size: 12px; margin-top: 5px;',
  //         });

  //         cellContainer.appendChild(validationMessageElementForCell);
  //       }
  //     });
  //     cellContainer.appendChild(cellInputEle);
  //     row.appendChild(cellContainer);
  //     //#endregion

  //     // Add Work
  //     //#region
  //     let workContainer: HTMLElement = createElement('div', {
  //       className: 'custom-field-container',
  //     });
  //     // Add Label for Work Textbox
  //     let workLabel: HTMLLabelElement = createElement('label', {
  //       className: 'work-label',
  //       innerHTML: 'Work:', // Provide the desired label text
  //     }) as HTMLLabelElement;

  //     workContainer.appendChild(workLabel);

  //     // let workInputEle: HTMLInputElement = createElement('input', {
  //     //   className:
  //     //     'e-float-input e-control-wrapper form-control mt-0 e-subject e-field',
  //     //   // attrs: { name: 'WorkText', type: 'number' },
  //     //   attrs: {
  //     //     name: 'WorkText',
  //     //     type: 'tel',
  //     //     pattern: '[0-9]{1,10}',
  //     //     title: 'Enter a valid Work number with a maximum of 10 digits',
  //     //     placeholder: '+1 country code is not needed.',
  //     //     // value:
  //     //     //   args.data.WorkText !== undefined && args.data.WorkText !== null
  //     //     //     ? args.data.WorkText
  //     //     //     : this.work || '',
  //     //     value: args.data.work || this.work || '',
  //     //   },
  //     // }) as HTMLInputElement;

  //     let workInputEle: HTMLInputElement = createElement('input', {
  //       className:
  //         'e-float-input e-control-wrapper form-control mt-0 e-subject e-field',
  //       attrs: {
  //         name: 'WorkText',
  //         type: 'tel',
  //         pattern: '\\+[0-9]{1,4}[0-9]{1,10}',
  //         title: 'Enter a valid work number with a country code and maximum of 10 digits after the country code',
  //         placeholder: '+ Country code and work number',
  //         value: args.data.work || this.work || '',
  //       },
  //     }) as HTMLInputElement;

  //     // Add input event listener for validation
  //     workInputEle.maxLength = 13;
  //     workInputEle.addEventListener('input', () => {
  //       this.isValidWorkNumber = workInputEle.checkValidity();
  //       const validationMessageForWork = workInputEle.validationMessage;

  //       // Display validation message
  //       const validationMessageContainerForWork = workContainer.querySelector(
  //         '.validation-message'
  //       );
  //       if (validationMessageContainerForWork) {
  //         validationMessageContainerForWork.remove();
  //       }

  //       if (!this.isValidWorkNumber) {
  //         const validationMessageElementForWork = createElement('div', {
  //           className: 'validation-message',
  //           innerHTML: validationMessageForWork,
  //           styles: 'color: red; font-size: 12px; margin-top: 5px;',
  //         });

  //         workContainer.appendChild(validationMessageElementForWork);
  //       }
  //     });
  //     workContainer.appendChild(workInputEle);
  //     row.appendChild(workContainer);
  //     //#endregion

  //     // Add Email
  //     //#region
  //     let emailContainer: HTMLElement = createElement('div', {
  //       className: 'custom-field-container',
  //     });
  //     // Add Label for Email Textbox
  //     let emailLabel: HTMLLabelElement = createElement('label', {
  //       className: 'email-label',
  //       innerHTML: 'Email (required for schedule if Email is selected):', // Provide the desired label text
  //     }) as HTMLLabelElement;
  //     emailContainer.appendChild(emailLabel);
  //     let emailInputEle: HTMLInputElement = createElement('input', {
  //       className:
  //         'e-float-input e-control-wrapper form-control mt-0 e-subject e-field',
  //       attrs: {
  //         name: 'EmailText',
  //         type: 'email',
  //         pattern: '^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\\.[A-Z|a-z]{2,}$',
  //         maxLength: '50',
  //         value: args.data.email || this.email || '',
  //       },
  //     }) as HTMLInputElement;
  //     emailInputEle.addEventListener('input', () => {
  //       this.isValidEmail = emailInputEle.checkValidity();
  //       const validationEmailMessage = emailInputEle.validationMessage;

  //       // Display validation message
  //       const validationMessageContainer = emailContainer.querySelector(
  //         '.validation-message'
  //       );
  //       if (validationMessageContainer) {
  //         validationMessageContainer.remove();
  //       }

  //       if (!this.isValidEmail) {
  //         const validationMessageElement = createElement('div', {
  //           className: 'validation-message',
  //           innerHTML: validationEmailMessage,
  //           styles: 'color: red; font-size: 12px; margin-top: 5px;',
  //         });

  //         emailContainer.appendChild(validationMessageElement);
  //       }
  //     });
  //     emailContainer.appendChild(emailInputEle);
  //     row.appendChild(emailContainer);
  //     //#endregion

  //     // Add Setup in Home
  //     //#region
  //     let setupContainer: HTMLElement = createElement('div', {
  //       className: 'custom-field-container',
  //     });
  //     // Add Label for Email Textbox
  //     let setupLabel: HTMLLabelElement = createElement('label', {
  //       className: 'setup-label',
  //       innerHTML: 'Setup in Home : ', // Provide the desired label text
  //     }) as HTMLLabelElement;

  //     setupContainer.appendChild(setupLabel);
  //     let setupInputEle: HTMLInputElement = createElement('input', {
  //       className: 'setup-in-home-label mr-2 mt-2 e-field',
  //       attrs: {
  //         name: 'Setup',
  //         type: 'checkbox',
  //       },
  //     }) as HTMLInputElement;
  //     if (args.data && args.data.setupInHome === true) {
  //       // If Setup is explicitly set to false, uncheck the checkbox
  //       setupInputEle.checked = true;
  //     } else {
  //       setupInputEle.checked = false;
  //     }
  //     setupContainer.appendChild(setupInputEle);
  //     row.appendChild(setupContainer);
  //     //#endregion

  //     //! switch case events log
  //     switch (args?.requestType) {
  //       case 'viewNavigate':
  //       case 'dateNavigate':
  //         this.scheduleObj?.refresh();
  //         break;
  //       case 'toolBarItemRendered':
  //         // this.getTableData()
  //         // args?.requestType = "viewNavigate"
  //         break;
  //       default:
  //     }
  //   }
  // }

  onItemDrag(event: any): void {
    if (this.scheduleObj.isAdaptive) {
      let classElement: HTMLElement =
        this.scheduleObj.element.querySelector('.e-device-hover');
      if (classElement) {
        classElement.classList.remove('e-device-hover');
      }
      if (event.target.classList.contains('e-work-cells')) {
        addClass([event.target], 'e-device-hover');
      }
    }
    document.body.style.position = 'fixed';
    if (document.body.style.cursor === 'not-allowed') {
      document.body.style.cursor = '';
    }
    if (event.name === 'nodeDragging') {
      let dragElementIcon: NodeListOf<HTMLElement> = document.querySelectorAll(
        '.e-drag-item.treeview-external-drag .e-icon-expandable'
      );
      for (let i: number = 0; i < dragElementIcon.length; i++) {
        dragElementIcon[i].style.display = 'none';
      }
    }
  }

  // onActionBegin(args: ActionEventArgs): void {
  //   // Prevent default action for other Scheduler actions if needed
  //   args.cancel = true;
  // }
  // editor popup window
  public showQuickInfo: Boolean = false;
  onCellClick(args: CellClickEventArgs): void {
    // args.cancel = true;
    this.scheduleObj?.openEditor(args, 'Add');
  }
  onEventClick(args: EventClickArgs): void {
    if (!(args.event as any).RecurrenceRule) {
      this.scheduleObj?.openEditor(args.event, 'Save');
    } else {
      this.scheduleObj?.quickPopup?.openRecurrenceAlert();
    }
  }

  colorCode(Status: string) {
    let scalender_status_color_code = calender_status_color_code;
    let scalender_status_code = calender_status_code;
    let returntext = calender_status_color_code.Scheduled;
    if (Status === scalender_status_code[1]) {
      returntext = calender_status_color_code.Scheduled;
    } else if (Status === scalender_status_code[2]) {
      returntext = calender_status_color_code.Rescheduled;
    } else if (Status === scalender_status_code[3]) {
      returntext = calender_status_color_code.Completed;
    } else if (Status === scalender_status_code[4]) {
      returntext = calender_status_color_code.Cancelled;
    } else {
      returntext = calender_status_color_code.Scheduled; // Unknown
    }
    return returntext;
  }

  toggle: boolean = false;

  getTypeObjectById(id: number): any {
    if (this.templateDrp) {
      return this.templateDrp.find(temp => temp.templateCode === id);
    } else {
      return null;
    }
  }

  //! calender actions
  async onActionComplete(args: ActionEventArgs) {
    if (args.requestType === 'eventCreated') {
      let eventCreatedPatientId =
        args?.addedRecords?.[0]?.patientId !== undefined &&
        args?.addedRecords?.[0]?.patientId !== ''
          ? args?.addedRecords?.[0]?.patientId
          : null;

      //   if (eventCreatedPatientId === null)
      // {
      // Wrap the call to getSelectedPatientDetails in a promise
      await new Promise<void>((resolve) => {
        this.getSelectedPatientDetails(eventCreatedPatientId).subscribe(() => {
          resolve();
        });
      });

      // const selectedTemplate = this.templateDropDownList.index;
      // const selectedTemplateV1 = this.templateDropDownList.value
      // const templateCode = selectedTemplate.name;
      // const templateContent = selectedTemplate.id;
      // this.templateDrp = this.templateDropDownList?.find(
      //   (a) => a.templateId == this.templateId
      // );

      // let selectedTemplate = this.templateDrp.find(template => template.id === args?.addedRecords?.[0]?.templateId);
      // let templateCode = selectedTemplate ? selectedTemplate.name : null;
      // let templateContent = args?.addedRecords?.[0]?.templateId || null;

      let calender: CreateUpdateCalendarDTO = {
        roleId: this.roleId,
        roleName: this.roleName,
        assignedToId: this.userId,
        assignedToName: this.userName,
        calendarDetails: [
          {
            title: args?.addedRecords?.[0]?.Subject,
            fromTime: args?.addedRecords?.[0]?.StartTime,
            toTime: args?.addedRecords?.[0]?.EndTime,
            status: args?.addedRecords?.[0]?.OwnerId ?? '',
            description: args?.addedRecords?.[0]?.Description ?? '',
            locationId: args.addedRecords[0].branchId || null,
            patientId: args.addedRecords[0].patientId || defaultGuid, // Needs to change once API ready
            // patientId:eventCreatedPatientId,
            // userID: args.addedRecords[0].techId || null,
            userID: args.addedRecords[0].userDesID || null,
            type: args?.addedRecords?.[0]?.typeId ?? this.typeId,
            resource: args?.addedRecords?.[0]?.resourceId || null,
            homePhoneNo:
              this.isValidHomeNumber === true
                ? args?.addedRecords?.[0]?.HomeText
                : null,
            cellNo:
              this.isValidCellNumber === true
                ? args?.addedRecords?.[0]?.CellText
                : null,
            workNo:
              this.isValidWorkNumber === true
                ? args?.addedRecords?.[0]?.WorkText
                : null,
            email:
              (this.isValidEmail === true &&
                args?.addedRecords?.[0]?.EmailText) ||
              null,
            setUpInHome: args?.addedRecords?.[0]?.Setup,
            calendarCode: '',
            calendarId: args?.addedRecords?.[0]?.Id,
            startTimeZone: '',
            endTimeZone: '',
            // colorCode: '#20b2aa',
            colorCode: this.colorCode(args?.addedRecords?.[0]?.OwnerId ?? ''),
            docId: this.docId,
          },
        ],
        modeCode: args?.addedRecords?.[0]?.mode || defaultGuid,
        // templateCode: args?.addedRecords?.[0]?.templateId || null,
        // templateContent: args?.addedRecords?.[0]?.templateName || null,

        // templateCode: templateCode || null,
        // templateContent: templateContent || null,

        templateCode: this.selectedTemplateId,
        templateContent: this.selectedTemplateName,
      };
      this.inboundDocumentService.createCalendarByInput(calender).subscribe(
        (response) => {
          this.toggle = !this.toggle;
          this.eventSettings = {
            dataSource: this.getTableData(),
          };
          // this.getTableData();
        },
        (err) => {
          args.cancel = false;
          const data: HttpErrorResponse = err;
          Swal.fire({
            icon: 'info',
            text: data?.error?.error?.message,
          });
          this.eventSettings = {
            dataSource: this.getTableData(),
          };
        }
      );
    } else if (args.requestType === 'eventChanged') {
      const isModeOfcommunicaion = args?.changedRecords?.[0]?.mode;
      if(isModeOfcommunicaion.includes(4)) {
        this.updateScheduledEvents(false,args);
      }else{
        Swal.fire({
          icon: 'info',
          title: 'Do you want to Send SMS/Email Again?',
          showCancelButton: true,
          confirmButtonText: 'Yes',
          denyButtonText: 'No',
          showDenyButton: true,
          customClass: {
            actions: 'my-actions',
            cancelButton: 'order-1 right-gap',
            confirmButton: 'order-2',
            denyButton: 'order-3',
          },
        }).then((result) => {
          if (result.isConfirmed) {
            this.updateScheduledEvents(true,args);
           
          } else if (result.isDenied) {
            this.updateScheduledEvents(false,args);
          }
          else {
            this.triggerGetTabel();
          }
        })
      }

      // const selectedTemplate = this.getTypeObjectById(args?.changedRecords?.[0]?.templateId);

      // let eventChangedPatientId =
      //   args?.changedRecords?.[0]?.patientId !== undefined &&
      //   args?.changedRecords?.[0]?.patientId !== ''
      //     ? args?.changedRecords?.[0]?.patientId
      //     : null;

      // if (!eventChangedPatientId === null)
      //   // Wrap the call to getSelectedPatientDetails in a promise
      //   await new Promise<void>((resolve) => {
      //     this.getSelectedPatientDetails(eventChangedPatientId).subscribe(
      //       () => {
      //         resolve();
      //       }
      //     );
      //   });

      // let selectedTemplate = this.templateDrp.find(
      //   (template) => template.id === args?.changedRecords?.[0]?.templateId
      // );
      // let templateCode = selectedTemplate ? selectedTemplate.name : null;
      // let templateContent = args?.changedRecords?.[0]?.templateId || null;

      // let calender: CreateUpdateCalendarDTO = {
      //   roleId: this.roleId,
      //   roleName: this.roleName,
      //   assignedToId: args?.changedRecords?.[0]?.user,
      //   assignedToName: this.userName,
      //   calendarDetails: [
      //     {
      //       title: args?.changedRecords?.[0]?.Subject,
      //       fromTime: args?.changedRecords?.[0]?.StartTime,
      //       calendarDetailsId: args?.changedRecords?.[0]?.calendarDetailsId,
      //       toTime: args?.changedRecords?.[0]?.EndTime,
      //       status: args?.changedRecords?.[0]?.OwnerId ?? '',
      //       description: args?.changedRecords?.[0]?.Description ?? '',
      //       locationId:
      //         args?.changedRecords?.[0]?.Location !== undefined &&
      //         args?.changedRecords?.[0]?.Location !== ''
      //           ? args?.changedRecords?.[0]?.Location
      //           : null,
      //       patientId: args?.changedRecords?.[0]?.Patient || defaultGuid,
      //       userID:
      //         args?.changedRecords?.[0]?.user !== undefined &&
      //         args?.changedRecords?.[0]?.user !== ''
      //           ? args?.changedRecords?.[0]?.user
      //           : null,
      //       type:
      //         args?.changedRecords?.[0]?.type !== undefined &&
      //         args?.changedRecords?.[0]?.type !== ''
      //           ? args?.changedRecords?.[0]?.type
      //           : null,
      //       typeId: args?.changedRecords?.[0]?.typeId !== undefined &&
      //       args?.changedRecords?.[0]?.typeId !== ''
      //         ? args?.changedRecords?.[0]?.typeId
      //         : null,
      //       resource:
      //         args?.changedRecords?.[0]?.resource !== undefined &&
      //         args?.changedRecords?.[0]?.resource !== ''
      //           ? args?.changedRecords?.[0]?.resource
      //           : null,
      //       homePhoneNo: args?.changedRecords?.[0]?.phone,
      //       cellNo:
      //         this.isValidCellNumber === true
      //           ? args?.changedRecords?.[0]?.cell
      //           : null,
      //       workNo: args?.changedRecords?.[0]?.work,
      //       email: args?.changedRecords?.[0]?.email,
      //       setUpInHome: args?.changedRecords?.[0]?.setupInHome,
      //       calendarCode: '',
      //       calendarId: args?.changedRecords?.[0]?.Id || 0,
      //       startTimeZone: '',
      //       endTimeZone: '',
      //       // colorCode: '#20b2aa',
      //       // colorCode: this.colorCode(args?.changedRecords?.[0]?.OwnerId ?? ''),
      //       docId: this.docId,
      //     },
      //   ],
      //   modeCode: args?.changedRecords?.[0]?.mode || [0],
      //   templateCode: args?.changedRecords?.[0]?.templateId || null,
      //   templateContent: selectedTemplate?.template || null,
      // };

      // this.inboundDocumentService
      //   .updateCalendarByIdAndInput(args?.changedRecords?.[0]?.QGuid, calender)
      //   .subscribe(
      //     (response) => {
      //       this.toggle = !this.toggle;
      //       this.eventSettings = {
      //         dataSource: this.getTableData(),
      //       };
      //       // this.getTableData();
      //     },
      //     (err) => {
      //       args.cancel = false;

      //       const data: HttpErrorResponse = err;
      //       Swal.fire({
      //         icon: 'info',
      //         text: data?.error?.error?.message,
      //       });
      //       this.eventSettings = {
      //         dataSource: this.getTableData(),
      //       };
      //     }
      //   );


    } else if (args?.requestType === 'eventRemoved') {
      this.inboundDocumentService
        .deleteCalendarByIdAndCalendarDetailsId(
          args?.deletedRecords?.[0]?.QGuid,
          args?.deletedRecords?.[0]?.calendarDetailsId
        )
        .subscribe(
          (response) => {},
          (err) => {
            const data: HttpErrorResponse = err;
            this.eventSettings = {
              dataSource: this.getTableData(),
            };
          }
        );
    }
  }

  //#region Drag and Drop Updtaed Events
  async updateScheduledEvents(isSmsEmailSend: boolean , args: ActionEventArgs){

    this.loaderService.showLoader();
    const selectedTemplate = this.getTypeObjectById(args?.changedRecords?.[0]?.templateId);

    let eventChangedPatientId =
      args?.changedRecords?.[0]?.patientId !== undefined &&
      args?.changedRecords?.[0]?.patientId !== ''
        ? args?.changedRecords?.[0]?.patientId
        : null;

    if (!eventChangedPatientId === null)
      // Wrap the call to getSelectedPatientDetails in a promise
      await new Promise<void>((resolve) => {
        this.getSelectedPatientDetails(eventChangedPatientId).subscribe(
          () => {
            resolve();
          }
        );
      });

    let calender: CreateUpdateCalendarDTO = {
      roleId: this.roleId,
      roleName: this.roleName,
      assignedToId: args?.changedRecords?.[0]?.user,
      assignedToName: this.userName,
      calendarDetails: [
        {
          title: args?.changedRecords?.[0]?.Subject,
          fromTime: args?.changedRecords?.[0]?.StartTime,
          calendarDetailsId: args?.changedRecords?.[0]?.calendarDetailsId,
          toTime: args?.changedRecords?.[0]?.EndTime,
          status: args?.changedRecords?.[0]?.OwnerId ?? '',
          description: args?.changedRecords?.[0]?.Description ?? '',
          locationId:
            args?.changedRecords?.[0]?.Location !== undefined &&
            args?.changedRecords?.[0]?.Location !== ''
              ? args?.changedRecords?.[0]?.Location
              : null,
          patientId: args?.changedRecords?.[0]?.Patient || defaultGuid,
          userID:
            args?.changedRecords?.[0]?.user !== undefined &&
            args?.changedRecords?.[0]?.user !== ''
              ? args?.changedRecords?.[0]?.user
              : null,
          type:
            args?.changedRecords?.[0]?.type !== undefined &&
            args?.changedRecords?.[0]?.type !== ''
              ? args?.changedRecords?.[0]?.type
              : null,
          typeId: args?.changedRecords?.[0]?.typeId !== undefined &&
          args?.changedRecords?.[0]?.typeId !== ''
            ? args?.changedRecords?.[0]?.typeId
            : null,
          resource:
            args?.changedRecords?.[0]?.resource !== undefined &&
            args?.changedRecords?.[0]?.resource !== ''
              ? args?.changedRecords?.[0]?.resource
              : null,
          homePhoneNo: args?.changedRecords?.[0]?.phone,
          cellNo:
            this.isValidCellNumber === true
              ? args?.changedRecords?.[0]?.cell
              : null,
          workNo: args?.changedRecords?.[0]?.work,
          email: args?.changedRecords?.[0]?.email,
          setUpInHome: args?.changedRecords?.[0]?.setupInHome,
          calendarCode: '',
          calendarId: args?.changedRecords?.[0]?.Id || 0,
          startTimeZone: '',
          endTimeZone: '',
          // colorCode: '#20b2aa',
          // colorCode: this.colorCode(args?.changedRecords?.[0]?.OwnerId ?? ''),
          docId: args?.changedRecords?.[0].documentIdV1,
        },
      ],
      modeCode: args?.changedRecords?.[0]?.mode || [0],
      templateCode: args?.changedRecords?.[0]?.templateId || null,
      templateContent: selectedTemplate?.template || null,
      isMailorMessageSend: isSmsEmailSend,
    };

    this.inboundDocumentService
      .updateCalendarByIdAndInput(args?.changedRecords?.[0]?.QGuid, calender)
      .subscribe(
        (response) => {
          this.toggle = !this.toggle;
          this.eventSettings = {
            dataSource: this.getTableData(),
          };
        },
        (err) => {
          args.cancel = false;

          const data: HttpErrorResponse = err;
          Swal.fire({
            icon: 'info',
            text: data?.error?.error?.message,
          });
          this.eventSettings = {
            dataSource: this.getTableData(),
          };
        }
      );
  }

//#endregion
  @ViewChild('addButton')
  public addButton: ButtonComponent;
  public onButtonClick(): void {
    let data: Object[] = [
      {
        Id: 0,
        Subject: 'Conference',
        StartTime: new Date(2018, 1, 12, 9, 0),
        EndTime: new Date(2018, 1, 12, 10, 0),
        IsAllDay: true,
      },
    ];
    // this.scheduleObj?.addEvent(data);
    // this.addButton.element?.setAttribute('disabled','true');
  }

  setRequiredFields(element: HTMLElement) {
    const formFields = element.querySelectorAll(
      '.e-schedule-form .custom-field-container'
    );
    formFields.forEach((field) => {
      (field as HTMLInputElement).required = true;
    });
  }

  // openAddScheduleModal(args) {
  //   const config: MatDialogConfig = {
  //     disableClose: true,
  //     width: '47vw',
  //     maxWidth: '47vw',
  //     // height: '80vh',
  //     // maxHeight: 'auto',
  //     // minHeight:'auto',
  //     panelClass: 'responsive-modal',
  //     data: {
  //       scheduleData: args,
  //       patientId: this.patientId,
  //       // docId: this.docId,
  //       docId: args?.data?.documentIdV1,
  //       patientIdViaURL: this.patientIdViaURL,
  //       calenderDetailId: this.calenderDetailId,
  //     },
  //   };
  //   const dialogRef = this.dialog.open(
  //     CalenderScheduleAddModalComponent,
  //     config
  //   );
  //   const closeDialog = dialogRef.afterClosed().subscribe(() => {});
  // }

  openAddScheduleModal(args) {
    const config: MatDialogConfig = {
      disableClose: true,
      width: '67vw',
      maxWidth: '67vw',
      // height: '80vh',
      // maxHeight: 'auto',
      // minHeight:'auto',
      panelClass: 'responsive-modal',
      data: {
        scheduleData: args,
        patientId: this.patientId,
        // docId: this.docId,
        docId: args?.data?.documentIdV1,
        patientIdViaURL: this.patientIdViaURL,
        calenderDetailId: this.calenderDetailId,
      },
    };
    const dialogRef = this.dialog.open(
      CalendarScheduleAddModalV1NewComponent,
      config
    );
    const closeDialog = dialogRef.afterClosed().subscribe(() => {});
  }

  triggerGetTabel() {
    this.eventSettings = {
      dataSource: this.getTableData(),
    };
  }

  public onContextMenuBeforeOpen(args: BeforeOpenCloseMenuEventArgs): void {
    const newEventElement: HTMLElement = document.querySelector(
      '.e-new-event'
    ) as HTMLElement;
    if (newEventElement) {
      remove(newEventElement);
      removeClass(
        [document.querySelector('.e-selected-cell')],
        'e-selected-cell'
      );
    }
    this.scheduleObj.closeQuickInfoPopup();
    const targetElement: HTMLElement = args.event.target as HTMLElement;
    if (closest(targetElement, '.e-contextmenu')) {
      return;
    }
    this.selectedTarget = closest(
      targetElement,
      '.e-appointment,.e-work-cells,' +
        '.e-vertical-view .e-date-header-wrap .e-all-day-cells,.e-vertical-view .e-date-header-wrap .e-header-cells'
    );
    if (isNullOrUndefined(this.selectedTarget)) {
      args.cancel = true;
      return;
    }
    if (this.selectedTarget.classList.contains('e-appointment')) {
      const eventObj: Record<string, any> = this.scheduleObj.getEventDetails(
        this.selectedTarget
      ) as Record<string, any>;

      if (
        eventObj.documentIdV1 === defaultGuid ||
        eventObj.documentIdV1 === null
      ) {
        this.menuObj.enableItems(['Document Imaging'], false);
      } else {
        this.menuObj.enableItems(['Document Imaging'], true);
      }

      if (eventObj.RecurrenceRule) {
        this.menuObj.showItems(
          [
            'Save',
            'Delete',
            'StartNewOrder',
            'emailconfirmation',
            'AddNotes',
            'ViewNotes',
            'ViewPatientLeadger',
            'DocumentImaging',
          ],
          true
        );
      } else {
        this.menuObj.showItems(
          [
            'Save',
            'Delete',
            'StartNewOrder',
            'emailconfirmation',
            'AddNotes',
            'ViewNotes',
            'ViewPatientLeadger',
            'DocumentImaging',
          ],
          true
        );
      }
      return;
    } else if (
      (this.selectedTarget.classList.contains('e-work-cells') ||
        this.selectedTarget.classList.contains('e-all-day-cells')) &&
      !this.selectedTarget.classList.contains('e-selected-cell')
    ) {
      removeClass(
        [].slice.call(
          this.scheduleObj.element.querySelectorAll('.e-selected-cell')
        ),
        'e-selected-cell'
      );
      this.selectedTarget.classList.add('e-selected-cell');
      this.selectedTarget.setAttribute('aria-selected', 'true');
    }
    // this.menuObj.showItems(['Save', 'Delete','StartNewOrder','emailconfirmation','AddNotes','ViewNotes','ViewPatientLeadger','DocumentImaging'], true);
    this.menuObj.hideItems(
      [
        'Save',
        'Delete',
        'StartNewOrder',
        'emailconfirmation',
        'AddNotes',
        'ViewNotes',
        'ViewPatientLeadger',
        'DocumentImaging',
      ],
      true
    );
  }

  public onMenuItemSelect(args: MenuEventArgs): void {
    const selectedMenuItem: string = args.item.id;
    let eventObj: Record<string, any>;
    if (this.selectedTarget.classList.contains('e-appointment')) {
      eventObj = this.scheduleObj.getEventDetails(
        this.selectedTarget
      ) as Record<string, any>;
    }
    switch (selectedMenuItem) {
      case 'StartNewOrder':
        this.openStratNewOrder(eventObj);
        break;
      case 'AddNotes':
        this.openAddNotesPopUp(eventObj);
        break;
      case 'Save':
        this.scheduleObj.openEditor(eventObj, selectedMenuItem);
        break;
      case 'Delete':
        this.scheduleObj.deleteEvent(eventObj);
        this.setEventSettings();
        break;
      case 'ViewNotes':
        this.openViewNotesPopup(eventObj);
        break;
      case 'emailconfirmation':
        this.openEmailPrintLetter(eventObj);
        break;
      case 'ViewPatientLeadger':
        this.viewPatientLedger(eventObj);
        break;
      case 'DocumentImaging':
        this.viewDocumentImage(eventObj);
        break;
    }
  }

  openStratNewOrder(eventObj: any) {
    const url = '/#orders/saleOrder/' + eventObj.Patient;
    window.open(url, '_blank');
  }

  openEmailPrintLetter(eventObj: any) {
    const url = '/#emailprintletter/' + eventObj.Patient;
    window.open(url, '_blank');
  }

  openAddNotesPopUp(eventObj: any) {
    const config: MatDialogConfig = {
      disableClose: true,
      minHeight: '30vh',
      minWidth: '50vw',
      data: {
        noteType: 'patient', // 'Order Note',
        notesId: '00000000-0000-0000-0000-000000000000',
        patientId: eventObj.Patient,
        // defaultFaxId: eventObj.Patient,
        isCallNote: false,
        isPending: false,
        docId: eventObj?.documentIdV1,
        inboundStatusShortCodeId: defaultGuid,
        selectedNode: defaultGuid,
        addnotes: true,
      },
    };
    const dialogRef = this.dialog.open(AddNoteTabComponent, config);

    const closeDialog = dialogRef.afterClosed().subscribe(
      () => {},
      (err) => {
        // const data: HttpErrorResponse = err;
        // Swal.fire({
        //   icon: 'info',
        //   text: data?.error?.error?.message,
        // });
      }
    );
    this.subscription$.push(closeDialog);
  }

  openViewNotesPopup(eventObj: any) {
    const config: MatDialogConfig = {
      disableClose: true,
      width: '70vw',
      maxWidth: '70vw',
      data: {
        patientId: eventObj.Patient,
        pageType: 'Patient Note', // this.pageType, //Notes Table Popup Page
        isAddVisible: false,
      },
    };
    const dialogRef = this.dialog.open(PatientCommonNotesComponent, config);

    dialogRef.afterClosed().subscribe(
      () => {
        //this.getReminderTableData();
      },
      (err: HttpErrorResponse) => {
        // const data: HttpErrorResponse = err;
        // Swal.fire({
        //   icon: 'info',
        //   text: data?.error?.error?.message,
        // });
      }
    );
  }

  viewPatientLedger(eventObj: any) {
    this.dialog.open(ViewPatientLedgerComponent, {
      width: '100vw',
      height: '100vh',
      maxWidth: '100vw',
      maxHeight: '100vh',
      panelClass: 'fullscreen-dialog-panel',
      disableClose: true,
      data: {
        patientId: eventObj.Patient,
      },
    });
    this.dialog.afterAllClosed.subscribe((resposne) => {});
  }

  viewDocumentImage(eventObj: any) {
    const url = '/#view_document_tiff/' + eventObj?.documentIdV1 + '/' + true;
    window.open(url, '_blank');
  }

  public onTreeDrag(event: any): void {
    alert('Drag');
    // if (this.scheduleObj.isAdaptive) {
    //   const classElement: HTMLElement = this.scheduleObj.element.querySelector('.e-device-hover');
    //   if (classElement) {
    //     classElement.classList.remove('e-device-hover');
    //   }
    //   if (event.target.classList.contains('e-work-cells')) {
    //     addClass([event.target], 'e-device-hover');
    //   }
    // }
  }

  public onTreeDragStop(event: DragAndDropEventArgs): void {
    alert("Started dragging");
    const treeElement: Element = closest(event.target, '.e-treeview') as Element;
    const classElement: HTMLElement = this.scheduleObj.element.querySelector('.e-device-hover');
    if (classElement) {
      classElement.classList.remove('e-device-hover');
    }
    if (!treeElement) {
      event.cancel = true;
      const scheduleElement: Element = closest(event.target, '.e-content-wrap') as Element;
      if (scheduleElement) {
        const treeviewData: Record<string, any>[] = this.treeObj.fields.dataSource as Record<string, any>[];
        if (event.target.classList.contains('e-work-cells')) {
          const filteredData: Record<string, any>[] = treeviewData.filter((item: any) =>
            item.Id === parseInt(event.draggedNodeData.id as string, 10));
          const cellData: CellClickEventArgs = this.scheduleObj.getCellDetails(event.target);
          const resourceDetails: ResourceDetails = this.scheduleObj.getResourcesByIndex(cellData.groupIndex);
          const eventData: Record<string, any> = {
            Name: filteredData[0].Name,
            StartTime: cellData.startTime,
            EndTime: cellData.endTime,
            IsAllDay: cellData.isAllDay,
            Description: filteredData[0].Description,
            DepartmentID: resourceDetails.resourceData.GroupId,
            ConsultantID: resourceDetails.resourceData.Id
          };
          this.scheduleObj.openEditor(eventData, 'Add', true);
          this.isTreeItemDropped = true;
          this.draggedItemId = event.draggedNodeData.id as string;
        }
      }
    }
    document.body.classList.remove('e-disble-not-allowed');
  }

  onDragStart(args: any): void {
    this.getTemplate(args?.data?.mode);
  }

  onResizeStart(args: ResizeEventArgs): void {
    this.getTemplate(args?.data?.mode);
  }

  //Naigation Event
  onNavigating(args): void {
    console.log('Navigating:', args);
  }
  // onDragStop(args: any): void {
  //   console.log('Drag Stop:', args);
  // }
  // onResizeStart(args: any): void {
  //   console.log('Resize Start:', args);
  // }
  // onResizeStop(args: any): void {
  //   console.log('Resize Stop:', args);
  // }
}
// function extend(
//   arg0: undefined[],
//   eventData: Record<string, any>[],
//   arg2: null,
//   arg3: boolean
// ): { [key: string]: Date }[] {
//   throw new Error('Function not implemented.');
// }
// function addClass(arg0: any[], arg1: string) {
//   throw new Error('Function not implemented.');
// }
export interface User {
  userId?: string;
  userDesignation?: string;
}

export interface Branch {
  id?: string;
  organizationUnitName?: string;
}

export interface TypeDrp {
  aptClassId?: string;
  description?: string;
}