import { ListService, PagedAndSortedResultRequestDto } from '@abp/ng.core';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatRadioChange } from '@angular/material/radio';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { SaleorderDTO } from 'projects/order/src/app/order-proxy/order-management/order/dto/models';
import { SaleOrderSearchService } from 'projects/order/src/app/order-proxy/order-management/order/sale-order-search.service';
import { SaleorderService } from 'projects/order/src/app/order-proxy/order-management/order/saleorder.service';
import { getOrder, updateOrder } from 'projects/order/src/app/order-store/order.action';
import { OrderMasterDropdownDTO, PriceCodeDTO } from 'projects/patient/src/app/dropdown-proxy/dropdown-management/dropdowns/dto/order/models';
import { OrderMasterDropdownService } from 'projects/patient/src/app/dropdown-proxy/dropdown-management/dropdowns/master-dropdown-service/order-master-dropdown.service';
import { OrderDropdowns } from 'projects/patient/src/app/dropdown-proxy/dropdown-management/dropdowns/order-dropdowns.enum';
import { TableService } from 'projects/shared/src/app/table.service';
import { Subscription } from 'rxjs';
import { distinctUntilChanged, filter, map } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { ToastrService } from "ngx-toastr";

@Component({
  selector: 'app-shipping-ledger',
  templateUrl: './shipping-ledger.component.html',
  styleUrls: ['./shipping-ledger.component.scss'],
  providers: [ListService]

})
export class ShippingLedgerComponent implements OnInit, OnDestroy {
  @Output() savePatientLedgerFormState: EventEmitter<any> = new EventEmitter<any>();
  show: boolean = false
  tableDummyData: billingList[] = [];
  itemTable: itemList[] = [];
  @Output() nextTabMoveOnSaveEmitter: EventEmitter<string> = new EventEmitter<string>(null);

  tblItemReponse: any[] = [];
  @Input() orderId: string = '';
  @Input() patientId: string = '';
  @Input() billingId: string = '';
  organizationUnitId = null;
  //! Variable Initalization
  strSelectedBillingId: string = '';
  strPageType: string = 'patientLedger';
  public ledgerForm: FormGroup;
  saveButtonHide: boolean;
  isShowSpinner: boolean = false;
  subscription$: Subscription[] = [];
  constructor(
    public tableService: TableService, public list: ListService,
    public title: Title, private orderService: SaleorderService,
    public router: Router, private store: Store,
    private fb: FormBuilder, private orderDropdownService: OrderMasterDropdownService,
    private orderSearchService: SaleOrderSearchService,
    private toastr: ToastrService,
  ) { }

  ngOnInit(): void {
    // this.getTableDatas();
    this.getPatientLedgerById();
    this.ledgerForm = this.fb.group({
      rbtnLedger: new FormControl("", Validators.required),
      txtReason: new FormControl("")

    });
    const getOrganization = this.tableService.getOrganizationUnitId().subscribe((Value) => {
      this.organizationUnitId = Value
    }, err => {
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    })
    this.subscription$.push(getOrganization);
    this.saleorderCompletedOrdersList();
    if (this.ledgerForm.invalid || this.ledgerForm.dirty) {
      this.savePatientLedgerFormState.emit(this.ledgerForm);
    }
  }

  ngOnDestroy(): void {
    this.subscription$?.forEach((sub) => {
      sub && sub?.unsubscribe();
    });
  }

  private saleorderCompletedOrdersList() {
    // this.isLoading = true;
    //
    this.orderSearchService.getList(new PagedAndSortedResultRequestDto).
      pipe(distinctUntilChanged(), map(value => {


        let data: { id: string; orderId: string; isOrderCompleted: string; authorizationStatus?: string; insuranceVerifiesStatus: string; insuranceVerifiesIsPresist: boolean; orderStatus: boolean; createdDate: string; }[] = [];
        let strPercertRequired: string = "";
        let strauthorizationStatus2: string = "";
        value?.items.forEach(element => {
          strPercertRequired = (element?.patients?.insuranceVerifications?.primaryInsuranceVerifies?.isPercertRequired === 1) ? "Needed" : "Not Needed";
          if (strPercertRequired === "Needed") {
            strauthorizationStatus2 = (element?.patients?.authorizations?.primaryAuthorizations?.isCompleted === "Done") ? "Completed" : "Pending";
          } else {
            strauthorizationStatus2 = "Completed";
          }
          data.push({
            id: element?.id,
            orderId: element?.saleorderId,
            authorizationStatus: strauthorizationStatus2,
            insuranceVerifiesStatus: element?.patients?.insuranceVerifications?.primaryInsuranceVerifies?.verificationStatus,
            insuranceVerifiesIsPresist: Boolean(element?.patients?.insuranceVerifications?.primaryInsuranceVerifies?.isPercertRequired),
            isOrderCompleted: element?.orders?.isCompleted,
            orderStatus: (element?.orders !== null),
            createdDate: element?.creationTime === null || element?.creationTime === undefined ? "" : new Date(element?.creationTime)?.toLocaleDateString("en-US")
          })
        });
        return data;
      })
      ).subscribe(response => {
        let data: { id: string; orderId: string; authorizationStatus?: string; insuranceVerifiesStatus: string; orderStatus: boolean; createdDate: string; }[] = [];

        response?.forEach(element => {
          // let strOrderStatus = (element?.orderStatus && element?.isOrderCompleted === 1 && element?.insuranceVerifiesStatus === "Done" && (element?.authorizationStatus === "Completed" || element?.authorizationStatus === 'Not Needed'));
          let strOrderStatus = (element?.isOrderCompleted === "Done");

          data.push({
            id: element?.id,
            orderId: element?.orderId,
            insuranceVerifiesStatus: element?.insuranceVerifiesStatus,
            authorizationStatus: element?.authorizationStatus,
            orderStatus: strOrderStatus,
            createdDate: element?.createdDate
          });


        });

      }, () => {
        // const data: HttpErrorResponse = err;
        // Swal.fire({
        //   icon: 'error',
        //   text: data?.error?.error?.message,
        // });
      });


    this.getDropdown();
  }

  // orderView:string="";
  //! Get Inital Table Data
  getTableData(): void {
    //  this.tableService.setBillingListDataTable([]);
    // this.isLoading = true;
    this.orderSearchService.getList(new PagedAndSortedResultRequestDto).pipe(distinctUntilChanged(), filter(value => {
      return value && value !== [];
    }), map(value => {
      let data: SaleorderDTO[] = [];
      value?.items?.forEach(element => {
        element?.orders.isCompleted === "Done" && data.push(element);
      })

      return data;
    }), map((value: SaleorderDTO[]) => {
      let data: billingList[] = [];

      value?.forEach((element: SaleorderDTO) => {

        if (this.patientId === element?.patientId) {
          let orderView: boolean = false;
          if (this.orderId !== element?.id) {
            orderView = true
          }
          element && data.push({
            insuranceName: element?.patients?.insuranceVerifications?.primaryInsuranceVerifies?.planName,
            email: element?.patients?.personals?.emailId,
            shippingLocation: element?.orders?.invLocationId,
            createdDate: new Date(element?.creationTime)?.toLocaleDateString("en-US"),
            status: element?.isShippingApprove == 1 ? 'Order Completed' : "Shipping Inprogress",
            insuranceBalance: "",
            totalBalance: "",
            patientId: String(element?.patients?.defaultPatientId),
            patientName: element?.patients?.personals?.firstName + ' ' + element?.patients?.personals?.lastName,
            billingId: element?.saleorderId,
            chartNo: "",
            // chartNo: this.drpChart.filter(value => { return value && value?.chartId === element?.insuranceVerifies[0]?.chart })[0]?.chartType,//element?.insuranceVerifies[0].chart,
            suppliedDate: new Date(element?.orders?.scheduledDate)?.toLocaleDateString("en-US"),
            patientBalance: "",
            id: element?.id,
            orderStatus: orderView,
          })
        }
      });

      return data
    })).subscribe(response => {


      this.tableDummyData = response;
      this.tableService.setPatientLedgerDataTable(this.tableDummyData);

    }, () => {
      // const data: HttpErrorResponse = err;
      // Swal.fire({
      //   icon: 'error',
      //   text: data?.error?.error?.message,
      // });
    })
  }
  //! reason show
  onChangeGetInsuranceVerify($event: MatRadioChange) {

    if ($event.value === "Disapproved") {
      this.show = true
    } else {
      this.show = false
    }

  }

  //! save ledger
  getPatientDetailsForSave(status) {
    let authOrderDetails: SaleorderDTO;
    const orderService = this.orderService.get(this.orderId).subscribe(response => {
      // this.store.dispatch(new getOrder(this.orderId)).subscribe(response => {
      //
      authOrderDetails = response;
      this.saveLedger(authOrderDetails, status);
    }, err => {
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    });
    this.subscription$.push(orderService);
  }
  saveLedger(response, status) {
    this.isShowSpinner = true;
    this.saveButtonHide = true;
    let authDetails: SaleorderDTO;
    if (status === "Approved") {
      authDetails = {
        organizationUnitId: response.organizationUnitId,
        orders: {
          ...response.orders, isCompleted: "Done"
        },
        insurances: response.insurances,
        clinicals: response.clinicals,
        items: response.items,
        // patients:response.patients,
        // insuranceVerifies: response.insuranceVerifies,
        // authorizations: response.authorizations,
        orderStatus: response.orderStatus,
        saleorderId: response.saleorderId,
        currentStage: response.currentStage,
        currentStageStatus: response.currentStageStatus,
        orderWipStatus: response.orderWipStatus,
        patientLedgerStatus: this.ledgerForm.value.rbtnLedger,
        shippingApprovalStatus: response.shippingApprovalStatus,
        claimHistoryStatus: response.claimHistoryStatus,
        patients: response.patients,
        patientId: response.patientId,
        patientLedgerReason: this.ledgerForm.value.txtReason,
        isShippingApprove: response.isShippingApprove,
        isCashPayment: response.isCashPayment,
        documentReason: response.documentReason,
        paymentTransactionDetails: response.paymentTransactionDetails,
        payPalMailResendCount: response?.payPalMailResendCount,
        payPalPaymentMethodType: response?.payPalPaymentMethodType,
        payPalPaymentStatus: response?.payPalPaymentStatus,
        shippingCheckList: response?.shippingCheckList,
        orderBilling: response?.orderBilling,
        deliveryTicket: response?.deliveryTicket,
        authType: response?.authType

        // shippingApprovalStatus:response.shippingApprovalStatus
      }
    } else {
      authDetails = {
        organizationUnitId: response.organizationUnitId,
        orders: {
          ...response.orders, isCompleted: "Shipping Disapproved"
        },
        insurances: response.insurances,
        clinicals: response.clinicals,
        items: response.items,
        // patients:response.patients,
        // insuranceVerifies: response.insuranceVerifies,
        // authorizations: response.authorizations,
        orderStatus: response.orderStatus,
        saleorderId: response.saleorderId,
        currentStage: response.currentStage,
        currentStageStatus: response.currentStageStatus,
        orderWipStatus: response.orderWipStatus,
        patientLedgerStatus: this.ledgerForm.value.rbtnLedger,
        shippingApprovalStatus: response.shippingApprovalStatus,
        claimHistoryStatus: response.claimHistoryStatus,
        patients: response.patients,
        patientId: response.patientId,
        patientLedgerReason: this.ledgerForm.value.txtReason,
        isShippingApprove: response.isShippingApprove,
        isCashPayment: response.isCashPayment,
        documentReason: response.documentReason,
        paymentTransactionDetails: response.paymentTransactionDetails,
        payPalMailResendCount: response?.payPalMailResendCount,
        payPalPaymentMethodType: response?.payPalPaymentMethodType,
        payPalPaymentStatus: response?.payPalPaymentStatus,
        shippingCheckList: response?.shippingCheckList,
        orderBilling: response?.orderBilling,
        deliveryTicket: response?.deliveryTicket,
        orderProcessStatus: response?.orderProcessStatus,
        authType: response?.authType

        // shippingApprovalStatus:response.shippingApprovalStatus
      }
    }
    this.orderId != "" && this.store.dispatch(new updateOrder(this.orderId, authDetails)).subscribe(() => {
      // Swal.fire({ title: 'Success', html: 'Saved Successfully', icon: 'success', timer: 3000, timerProgressBar: true });
      this.toastr.success('Saved Successfully','Success')
      this.savePatientLedgerFormState.emit(this.ledgerForm.value);
      this.saveButtonHide = false;
      this.isShowSpinner = false;
    }, err => {
      this.saveButtonHide = false;
      this.isShowSpinner = false;
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    });
  }

  getPatientLedgerById() {
    const getOrder = this.orderService.get(this.orderId).subscribe(response => {
      // this.store.dispatch(new getOrder(this.orderId)).subscribe(response => {
      let selectedOrder: SaleorderDTO = response;
      if (selectedOrder?.patientLedgerStatus === "Disapproved") {
        this.show = true
      }
      // let authDetail: AuthorizationDTO = selectedOrder?.authorizations[0];
      this.ledgerForm.patchValue({

        rbtnLedger: selectedOrder.patientLedgerStatus,
        txtReason: selectedOrder.patientLedgerReason
      });

    }, () => {
      // const data: HttpErrorResponse = err;
      // Swal.fire({
      //   icon: 'error',
      //   text: data?.error?.error?.message,
      // });
    })
    this.subscription$.push(getOrder);
  }
  drpPriceCode: PriceCodeDTO[] = [];
  getDropdown() {
    let orderDropdownInput;
    orderDropdownInput = [
      OrderDropdowns.PriceCodes
    ]
    const orderDropdown = this.orderDropdownService.getOrderDropdownsByInput(orderDropdownInput).subscribe((stateResponse) => {
      let response: OrderMasterDropdownDTO = stateResponse;
      this.drpPriceCode = response?.priceCodes;
      this.getTableData();
    }, () => {
      // const data: HttpErrorResponse = err;
      // Swal.fire({
      //   icon: 'error',
      //   text: data?.error?.error?.message,
      // });
    })
    this.subscription$.push(orderDropdown);

  }

  //! Table CLick Event redirection
  viewShippingDetails(id: string) {
    this.strSelectedBillingId = id;
    this.getItemTable(id);
  }
  getItemTable(orderId: string): void {

    this.store.dispatch(new getOrder(orderId)).subscribe(response => {
      let itemDetails = response?.orderState?.selectedOrder?.items;
      this.itemTable = [];
      // let itemTable: itemList[] = [];
      itemDetails?.forEach(element => {


        this.itemTable?.push({
          charge: "$" + element?.billed,
          description: element?.description,
          qty: element?.qty,
          backOrder: "",
          serial: "",
          tracking: "",
          exch: "",
          shippingDate: element?.originalDos == null ? "" : new Date(element?.originalDos)?.toLocaleDateString("en-US"),
          packed: "",
          approvedNo: element?.attachmentNo,
          amount: element?.billed,
          name: element?.name,
          itemCodeId: element?.itemIdOrBarCode,
          priceCode: this.drpPriceCode?.filter(value => { return value && value?.priceCodeType === element?.priceCode })[0]?.priceCodeType,
          createdDate: element?.lastModificationTime
          // createdDate: new Date(element?.lastModificationTime)?.toLocaleDateString("en-US"),

        });
      })
      this.tableService.setOrderTable(this.itemTable?.reverse());
    }, () => {
      // const data: HttpErrorResponse = err;
      // Swal.fire({
      //   icon: 'error',
      //   text: data?.error?.error?.message,
      // });
    });
  }


}
export interface itemList {
  charge: string,
  description: string,
  qty: string,
  backOrder: string,
  serial: string,
  tracking: string,
  exch: string,
  shippingDate: string,
  packed: string,
  approvedNo: string,
  amount: string,
  name: string,
  itemCodeId: string,
  priceCode: string,
  createdDate: string
}
export interface billingList {
  id: string,
  billingId: string,
  chartNo: string,
  insuranceName: string,
  suppliedDate: string,
  patientBalance: string,
  insuranceBalance: string,
  totalBalance: string,
  patientId: string,
  patientName: string,
  email: string,
  shippingLocation: string,
  createdDate: string,
  status: string,
  orderStatus: boolean
}

