<!-- AR Aging Reports Design  -->
<div class="container-fluid">
  <div class="row">
    <div class="col-12">
      <div class="d-flex align-items-center justify-content-between">
        <h3 class="mb-0 font-size-18 customThemeClass mt-2"><b>MIS Report / AR Aging Report</b></h3>
        <!-- <div class="page-title-box page-title-right pt-2 pb-0">
          <ol class="breadcrumb m-0">
            <li class="ml-2 mt-2 breadcrumb-item">MIS Report</li>
            <li class="mt-2 breadcrumb-item muted">AR Aging Report</li>
          </ol>
        </div> -->
      </div>
    </div>
  </div>

  <div class="card card-body mt-3">
    <div class="row">
      <form [formGroup]="ARForm">
        <div class="col-12">
          <!-- <div class="col-4"> -->
          <mat-radio-group class="pl-4 pt-1" formControlName="rbtnReportType" [(ngModel)]="reportType">
            <mat-radio-button class="pr-2" value="PatientWise">
              Patient Wise
            </mat-radio-button>
            <mat-radio-button class="pr-2" value="InsuranceWise">
              Insurance Wise
            </mat-radio-button>
            &nbsp;&nbsp;&nbsp;
          </mat-radio-group>
          <!-- </div> -->
          <mat-form-field>
            <!-- <div class="col-4"> -->
            <mat-label>Amount Type</mat-label>
            <mat-select formControlName="drpAmountType" maxlength="40" [(value)]="amountType">
              <!-- (selectionChange)="getAuthFollowTableData($event.value)" -->
              <mat-option *ngFor="let amountType of drpAmountType" [value]="amountType.key">
                {{ amountType.value }}
              </mat-option>
            </mat-select>
            <!-- <button
              mat-button
              (click)="$event?.stopPropagation()"
              *ngIf="ARForm?.get('drpAmountType')?.value"
              matSuffix
              (click)="clearDrpForms()"
              mat-icon-button
            >
              <mat-icon>close</mat-icon>
            </button> -->
            <mat-error *ngIf="
                ARForm?.get('drpAmountType')?.touched &&
                ARForm?.get('drpAmountType')?.errors?.required
              ">
              Amount Type is a required field!
            </mat-error>
            <!-- </div> -->
          </mat-form-field>

          <button mat-button class="ml-4 buttonColor" [disabled]="showButtonHide || ARForm?.invalid"
            (click)="getARAgingReport(ARForm?.get('drpAmountType')?.value)">
            Show
          </button>
        </div>
        <br />
      </form>
    </div>

    <div class="container-fluid">
      <div class="row">
        <div class="col-12">
          <dx-load-panel #loadPanel shadingColor="rgba(0,0,0,0.4)" [position]="{ of: '#employee' }"
            [(visible)]="loadingVisible" [showIndicator]="true" [showPane]="true" [shading]="true">
          </dx-load-panel>
          <ng-container *ngIf="!loadingVisible">
            <dx-data-grid id="gridContainer" #dataGrid [dataSource]="dataSource" [remoteOperations]="false"
              [allowColumnReordering]="true" [rowAlternationEnabled]="true" [showBorders]="true"
              [columnAutoWidth]="true" [columnResizingMode]="columnResizingMode" [allowColumnResizing]="true">
              <!-- (onContentReady)="contentReady($event)" -->
              <dxo-paging [pageSize]="15"></dxo-paging>
              <dxo-state-storing [enabled]="true" type="localStorage" storageKey="storage"></dxo-state-storing>

              <dxo-pager [showPageSizeSelector]="true" [allowedPageSizes]="allowedPageSizes" [showInfo]="showInfo"
                [visible]="true" [displayMode]="'full'" [showNavigationButtons]="true"></dxo-pager>
              <dxo-search-panel [visible]="true" [highlightCaseSensitive]="true"></dxo-search-panel>
              <dxo-group-panel [visible]="true"></dxo-group-panel>
              <dxo-grouping [autoExpandAll]="false"></dxo-grouping>
              <dxo-filter-row [visible]="showFilterRow" [applyFilter]="currentFilter"></dxo-filter-row>

              <dxo-filter-builder [customOperations]="customOperations" [allowHierarchicalFields]="true">
              </dxo-filter-builder>
              <dxo-filter-builder-popup [position]="popupPosition">
              </dxo-filter-builder-popup>

              <dxo-filter-panel [visible]="true"></dxo-filter-panel>
              <ng-container *ngFor="let column of tableColumns">
                <dxi-column caption="{{ column?.caption }}" format="{{ column?.format }}"
                  [allowGrouping]="column?.allowGrouping" cssClass="{{ column?.cssClass }}"
                  alignment="{{ column?.alignment }}" cellTemplate="{{ column?.cellTemplate }}"
                  dataField="{{ column?.dataField }}" dataType="{{ column?.dataType }}">
                </dxi-column>
              </ng-container>

              <dxo-column-chooser #columnChooser [enabled]="true" [allowSearch]="allowSearch" [mode]="'select'">
              </dxo-column-chooser>

              <dxo-export [enabled]="true" [formats]="['']" [allowExportSelectedData]="true"></dxo-export>

              <div *dxTemplate="let d of 'defaultID&GUIDCellTemplate'">
                <div *ngIf="d?.column?.caption === 'Patient Id'" class="current-value">
                  <a matTooltip="Click to View Patient" class="eyeCursorclass" [routerLink]="
                      '/patientCreateEditTab/' + d?.data['patientId']
                    " target="_blank">
                    {{ d?.data["defaultPatientId"] }}
                  </a>
                </div>

                <!--<div *ngIf="d?.column?.caption==='Claim Id'" class="current-value">
                <a matTooltip="Click to View" class="eyeCursorclass"
                [routerLink]="'/AddNewPosting/' + d?.data['claimId']"
                target="_blank">
                {{d?.data["defaultClaimId"] }}
              </a>
              </div>
               <div *ngIf="d?.column?.caption==='SaleOrder Id'" class="current-value">
                <a matTooltip="Click to View" class="eyeCursorclass"
                  [routerLink]="'/orders/saleorderCreateEditTab/' + d?.data['patientId'] + '/' + d?.data['orderId']"
                  target="_blank">
                  {{d?.data["defaultSaleorderId"] }}
                </a>
              </div> -->
              </div>

              <div *dxTemplate="let d of 'priceCellTemplate'">
                <div *ngIf="d?.column?.caption === '0-30 Days'" class="current-value">
                  <div>
                    {{
                    d?.data["zeroToThirtyDays"] | currency : "USD" : "symbol"
                    }}
                  </div>
                </div>
                <div *ngIf="d?.column?.caption === '31-60 Days'" class="current-value">
                  <div>
                    {{
                    d?.data["thirtyToSixtyDays"] | currency : "USD" : "symbol"
                    }}
                  </div>
                </div>
                <div *ngIf="d?.column?.caption === '61-90 Days'" class="current-value">
                  <div>
                    {{
                    d?.data["sixtyToNinetyDays"] | currency : "USD" : "symbol"
                    }}
                  </div>
                </div>
                <div *ngIf="d?.column?.caption === '>90 Days'" class="current-value">
                  <div>
                    {{
                    d?.data["moreThanNinetyDays"]
                    | currency : "USD" : "symbol"
                    }}
                  </div>
                </div>
              </div>
              <div *dxTemplate="let d of 'patientNameCellTemplate'" class="link-primary">
                <div *ngIf="d?.column?.caption === 'Patient Name'" class="current-value">
                  <a matTooltip="Click to View Claims" class="eyeCursorclass"
                    (click)="getClaimList(myDialog,d?.data['patientId'])">
                    {{ d?.data["patientName"] }}
                  </a>
                </div>
              </div>
            </dx-data-grid>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #myDialog>
  <h2 matDialogTitle>Claims List</h2>
  <mat-dialog-content>
    <div class="row">
      <div class="col-12 pb-5">
        <div class="table table-responsive example-container mat-elevation-z4 mb-0 pb-0">
          <table mat-table id="tblClaims" style="text-align-last: center;" class="pt-0 mt-0" matTableExporter
            #exporter="matTableExporter" [hiddenColumns]="[14]" [dataSource]="claimsDataSource">
            <!--Default Claim Id-->
            <ng-container matColumnDef="defaultClaimId">
              <th class="header" mat-header-cell *matHeaderCellDef>
                ClaimId
              </th>
              <td mat-cell *matCellDef="let element">
                {{ element.defaultClaimId }}
              </td>
            </ng-container>

            <!--Default Order Id-->
            <ng-container matColumnDef="defaultOrderId">
              <th class="header" mat-header-cell *matHeaderCellDef>
                OrderId
              </th>
              <td mat-cell *matCellDef="let element">
                {{ element.defaultOrderId }}
              </td>
            </ng-container>

            <!--Default Patient Id-->
            <!-- <ng-container matColumnDef="defaultPatientId">
              <th class="header" mat-header-cell *matHeaderCellDef>
                Default PatientId
              </th>
              <td mat-cell *matCellDef="let element">
                {{ element.defaultPatientId }}
              </td>
            </ng-container> -->

            <!--Provider Name-->
            <!-- <ng-container matColumnDef="providerName">
              <th class="header" mat-header-cell *matHeaderCellDef>
                Default PatientId
              </th>
              <td mat-cell *matCellDef="let element">
                {{ element.providerName }}
              </td>
            </ng-container> -->

            <!--Billed Quantity-->
            <!-- <ng-container matColumnDef="billedQuantity">
              <th class="header" mat-header-cell *matHeaderCellDef>
                Billed Quantity
              </th>
              <td mat-cell *matCellDef="let element">
                {{ element.billedQuantity }}
              </td>
            </ng-container> -->
            <!--Billed Amount-->
            <ng-container matColumnDef="billedAmount">
              <th class="header" mat-header-cell *matHeaderCellDef>
                Billed Amount
              </th>
              <td mat-cell *matCellDef="let element">
                {{ element.billedAmount }}
              </td>
            </ng-container>
            <!--Insurance Name-->
            <ng-container matColumnDef="insuranceName">
              <th class="header" mat-header-cell *matHeaderCellDef>
                Insurance Name
              </th>
              <td mat-cell *matCellDef="let element">
                {{ element.insuranceName }}
              </td>
            </ng-container>

            <!--Current Balance-->
            <ng-container matColumnDef="currentBalance">
              <th class="header" mat-header-cell *matHeaderCellDef>
                Current Balance
              </th>
              <td mat-cell *matCellDef="let element">
                {{ element.currentBalance }}
              </td>
            </ng-container>

            <!--dos-->
            <!-- <ng-container matColumnDef="dos">
              <th class="header" mat-header-cell *matHeaderCellDef>
                Date of Service
              </th>
              <td mat-cell *matCellDef="let element">
                {{ element.dos| date: 'MM/dd/yyyy':'en_US' }}
              </td>
            </ng-container> -->

            <!--Claim Status-->
            <ng-container matColumnDef="claimStatus">
              <th class="header" mat-header-cell *matHeaderCellDef>
                Claim Status
              </th>
              <td mat-cell *matCellDef="let element">
                {{ element.claimStatus }}
              </td>
            </ng-container>

            <!--WayStar Claim Status-->
            <ng-container matColumnDef="waystarClaimStatus">
              <th class="header" mat-header-cell *matHeaderCellDef>
                Waystar Claim Status
              </th>
              <td mat-cell *matCellDef="let element">
                {{ element.waystarClaimStatus }}
              </td>
            </ng-container>

            <!--PostingCount-->
            <ng-container matColumnDef="postingCount">
              <th class="header" mat-header-cell *matHeaderCellDef>
                Posting Count
              </th>
              <td mat-cell *matCellDef="let element">
                {{ element.postingCount }}
              </td>
            </ng-container>

            <!--Claim Processed Date-->
            <ng-container matColumnDef="claimProcessedDate">
              <th class="header" mat-header-cell *matHeaderCellDef>
                Claim Processed Date
              </th>
              <td mat-cell *matCellDef="let element">
                {{ element.claimProcessedDate| date: 'MM/dd/yyyy':'en_US' }}
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="arrDisplayedClaimColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: arrDisplayedClaimColumns"></tr>
          </table>
          <div *ngIf="
              !(
                claimsDataSource &&
                claimsDataSource?.data &&
                claimsDataSource?.data?.length !== 0
              )
            " class="no-records text-align-center">
            <div class="message py-3" *ngIf="!isClaimLoading">
              {{ "No Records Found" }}
            </div>
            <div class="py-3" [hidden]="!isClaimLoading">
              <mat-spinner [diameter]="220"></mat-spinner>
            </div>
          </div>
        </div>
        <mat-paginator #MatPaginator [length]="claimsDataSource?.data?.length" class="mat-elevation-z2" [pageSize]="10"
          [pageIndex]="0" showFirstLastButtons [pageSizeOptions]="[5, 10, 25, 100]">
        </mat-paginator>
      </div>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button mat-raised-button color="warn" matDialogClose>Close</button>
  </mat-dialog-actions>
</ng-template>