import { RestService } from '@abp/ng.core';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class EdiFabricSerialKeyService {
  apiName = 'ClaimProcessingManagement';

  getSerialKey = () =>
    this.restService.request<any, void>({
      method: 'GET',
      url: '/api/claimProcessingApp/edi-fabric-serial-key/get-serial-key',
    },
    { apiName: this.apiName });

  constructor(private restService: RestService) {}
}
