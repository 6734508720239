import { HttpErrorResponse } from '@angular/common/http';
import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Title } from '@angular/platform-browser';
import { BillingReportRowsDTO } from 'projects/patient/src/app/dropdown-proxy/dropdown-management/dropdowns/dto/patient/models';
import { PatientMasterDropdownService } from 'projects/patient/src/app/dropdown-proxy/dropdown-management/dropdowns/master-dropdown-service/patient-master-dropdown.service';
import { PatientDropdowns } from 'projects/patient/src/app/dropdown-proxy/dropdown-management/dropdowns/patient-dropdowns.enum';
import { BilliingFilterResultDTO } from 'projects/patient/src/app/patient-proxy/patient/dto/models';
import { BillingReportColumnName } from 'projects/patient/src/app/patient-proxy/patient/enumeration-data/billing-report-column-name.enum';
import { BillingReportRowName } from 'projects/patient/src/app/patient-proxy/patient/enumeration-data/billing-report-row-name.enum';
import { ReportService } from 'projects/patient/src/app/patient-proxy/patient/report.service';
import { Subscription } from 'rxjs/internal/Subscription';
import Swal from 'sweetalert2';
import { ToastrService } from "ngx-toastr";
import { MisReportsPopupComponent } from '../mis-reports-popup/mis-reports-popup.component';
import { ArAgingReportRowsDTO, PatientMasterDropdownDTO } from './../../../../patient/src/app/dropdown-proxy/dropdown-management/dropdowns/dto/patient/models';

@Component({
  selector: 'app-mis-reports',
  templateUrl: './mis-reports.component.html',
  styleUrls: ['./mis-reports.component.scss']
})
export class MisReportsComponent implements OnInit {




  SearchForm: FormGroup;
  el: ElementRef;
  source?: any[] = [];
  configs?: { rows?: string; columns?: string };
  txtRow: BillingReportRowName;
  txtColumn: BillingReportColumnName;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  dataSource: MatTableDataSource<any> = new MatTableDataSource<any>([]);
  public arrDisplayedColumns: string[] = [];
  isLoading: boolean = true;
  scheduledDate: Date;
  drpBillingReport: BillingReportRowsDTO[] = []
  drpARAgingReport: ArAgingReportRowsDTO[] = []
  BillingReportRowName: BillingReportRowName;
  BillingReportColumnName: BillingReportColumnName;
  msiForm: FormGroup;
  filteredData: BilliingFilterResultDTO;
  // popup declartion
  patientId: string = "";
  defaultPatientId: string = '';
  saleOrderId: string = "";
  arrList: any[] = [];

  constructor(
    public dialog: MatDialog,
    @Inject(ElementRef) el: ElementRef,
    private fb: FormBuilder,
    private title: Title,
    private patientDropdownService: PatientMasterDropdownService,
    private reportService: ReportService,
    private toastr: ToastrService,
  ) {
    this.el = el;
  }

  ngOnInit(): void {
    this.title.setTitle("Qsecure | Billing Reports");
    this.arrDisplayedColumns = [ 'saleOrderId', 'claimId', 'invoiceServiceDate', 'patientName', 'branchName', 'insurance', 'itemName', 'quantity', 'amount'];
    this.msiForm = this.fb.group({
      txtFromDate: new FormControl("", Validators.required),
      txtToDate: new FormControl('', Validators.required)
    })
    this.SearchForm = this.fb.group({
      searchData: new FormControl("")
    });
    const valueChanges: Subscription = this.SearchForm.valueChanges.subscribe(
      value => {
        this.dataSource.filter = JSON.stringify(value.searchData);
        setTimeout(() => {
          this.filterTableData(value.searchData);
        }, 100);
      },
      err => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      });
    const patientDropdownInput: PatientDropdowns[] = [
      PatientDropdowns.BillingReports,
      PatientDropdowns.ArAgingReports
    ]
    this.patientDropdownService.getPatientDropdownsByInput(patientDropdownInput).subscribe((stateResponse) => {
      const reponse: PatientMasterDropdownDTO = stateResponse;
      this.drpBillingReport = reponse?.lstBillingReportRows ?? [];
      this.drpARAgingReport = reponse?.lstArAgingReportRows ?? [];
    }, err => {
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    });
    const firstDay = new Date(new Date().getFullYear(), new Date().getMonth(), 1);
    const lastDay = new Date(new Date().getFullYear(), new Date().getMonth(), daysInMonth(new Date().getMonth() + 1, new Date().getFullYear()));
    this.msiForm.patchValue({
      txtFromDate: firstDay,
      txtToDate: lastDay
    });
    this.scheduledDate = new Date(this.msiForm.value.txtFromDate);
    setTimeout(() => {
      this.getTableData();
    }, 1000);
  }


  //! get default table data
  getTableData() {
    this.isLoading = true;
    // this.msiForm.reset();
    let data: any[] = [];
    this.dataSource = new MatTableDataSource([]);
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
    this.reportService.getBillingReports().subscribe(response => {
      this.isLoading = true;
      this.arrList = response;
      data = response ?? [];
      setTimeout(() => {
        this.dataSource = new MatTableDataSource(data);
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
      }, 100);
      this.isLoading = false;
    }, err => {
      this.isLoading = false;
      let error: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: error?.error?.error?.message,
      });
    });
  }

  //reset form
  /// reset form
  resetForm() {
    this.msiForm.reset();
    this.msiForm.updateValueAndValidity();
    this.msiForm.markAsUntouched();
    const firstDay = new Date(new Date().getFullYear(), new Date().getMonth(), 1);
    const lastDay = new Date(new Date().getFullYear(), new Date().getMonth(), daysInMonth(new Date().getMonth() + 1, new Date().getFullYear()));
    this.msiForm.patchValue({
      txtFromDate: firstDay,
      txtToDate: lastDay,
    });
    this.scheduledDate = new Date(this.msiForm.value.txtFromDate);
    this.getTableData()
  }


  /// common search field
  filterTableData(searchValue: any) {
    let data: any[] = [];
    this.reportService.searchBillingReportBySInputAndLstBillingReports(searchValue, this.arrList).subscribe(
      response => {
        data = response ?? [];
        const arrTableData = data;
        this.dataSource = new MatTableDataSource(arrTableData);
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
      },
      err => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      }
    );
  }

  /// export data from table list for Mis-Report
  exportTableData() {
    const searchFormValue = this.msiForm?.value;
    this.reportService.exportBillingReportsByLstBillingReports(this.dataSource.data).subscribe(response => {
      if (response && response?.length !== 0) {
        let filelocation: String = String("data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64," + response);
        let link = document.createElement("a");
        link.download = ("Mis-Report") + ".xlsx";
        link.href = filelocation.toString();
        link.click();
        // Swal.fire({ title: 'Success', html: 'Exported Successfully', icon: 'success', timer: 3000, timerProgressBar: true })
        this.toastr.success('Exported Successfully','Success')
      }
    }, err => {
      const data: HttpErrorResponse = err;
      let errorMesg: string = data?.error;
      if (errorMesg?.includes('Sorry!! There is no data in table to export')) {
        errorMesg = 'Sorry!! There is no data in table to export';
      }
      else {
        errorMesg = data?.error?.error?.message;
      }
      Swal.fire({
        icon: 'info',
        text: errorMesg
      });
    });
  }

  //

  //! get  table data based on from and to dates
  getTableDataBasedOnDates() {
    let fromDate: string = "";
    let toDate: string = '';
    if (this.msiForm.value.txtFromDate !== '' && this.msiForm.value.txtFromDate !== null) {
      fromDate = Intl.DateTimeFormat('en-US')?.format(new Date(this.msiForm.value.txtFromDate)) ?? "";
    }
    if (this.msiForm.value.txtToDate !== '' && this.msiForm.value.txtToDate !== null) {
      toDate = Intl.DateTimeFormat('en-US')?.format(new Date(this.msiForm.value.txtToDate)) ?? "";
    }
    this.isLoading = true;
    let data: any[] = [];
    this.dataSource = new MatTableDataSource([]);
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
    this.reportService.getBillingReportsByDateRangeByDtFromDateAndDtToDate(fromDate, toDate).subscribe(response => {
      this.isLoading = false;
      data = response ?? [];
      this.dataSource = new MatTableDataSource(data);
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
    }, err => {
      this.isLoading = false;
      let error: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: error?.error?.error?.message,
      });
    });
  }
  //! get  table data based on row column names
  getTableBasedOnFilter() {
    this.source = [];
    this.configs = { rows: "", columns: "" };
    this.filteredData = null;

    this.reportService.getFilteredBillingReportsByOBillingReportRowNameAndOBillingReportColumnName(this.txtRow, this.txtColumn).subscribe(response => {
      this.isLoading = false;
      const data: BilliingFilterResultDTO = response;
      this.filteredData = data;
      let datas: { columnName?: string, filters?: any[], rowName?: string } | null = { filters: [] };
      datas.columnName = data?.columnName;
      datas.rowName = data?.rowName;
      this.source = data?.filters?.data;
      this.configs = { rows: this.txtRow, columns: this.txtColumn }



    }, err => {
      this.isLoading = false;
      let error: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: error?.error?.error?.message,
      });
    });
  }


  // Add Reports Popup
  openReportsmodal() {
    const dialogRef = this.dialog.open(MisReportsPopupComponent, {
      width: '50%',
      minHeight: '60%',
      height: 'auto'
    });
    dialogRef.afterClosed().subscribe(() => {
      // this.getReminderTableData();
    }, err => {
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    });
  }
}

function daysInMonth(month: number, year: number) {
  return new Date(year, month, 0).getDate();
}
