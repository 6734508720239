import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { SaleorderService } from 'projects/order/src/app/order-proxy/order-management/order/saleorder.service';
import { Subscription } from 'rxjs';
import Swal from 'sweetalert2';
import { ShippingLableComponent } from '../shipping-lable/shipping-lable.component';

@Component({
  selector: 'app-product-track-view',
  templateUrl: './product-track-view.component.html',
  styleUrls: ['./product-track-view.component.scss']
})
export class ProductTrackViewComponent implements OnInit , OnDestroy {
  public status: any;
  orderId: string = "";
  defaultOrderId: string = "";
  patientName: string = "";
  patientId: string = "";
  CSRName: string = "";
  lotNo: string = "";
  serialNo: string = "";
  Qty: number;
  Courier: string = "";
  trackId: string = "";
  totalPrice: number;
  showButton: boolean = false;
  subscription$: Subscription[] = [];
  constructor(public dialog: MatDialog,
    private activatedRoute: ActivatedRoute,
    private orderService: SaleorderService,) { }

  ngOnInit(): void {
    this.activatedRoute.paramMap.subscribe((response) => {
      this.orderId = response?.get('orderId') ?? '';
    });
    this.getOrderById();
    this.status = this.getOrderStatus();
  }

  ngOnDestroy(): void {
    this.subscription$?.forEach((sub) => {
      sub && sub?.unsubscribe();
    });
  }

  //// dummy data for stats
  getOrderStatus() {
    return [
      {
        name: "Pinpont Pen",
        photo: "assets/img/products/pinpoint-ballpen.jpg",
        quantity: 2,
        date: "02-02-2020",
        price: 100,
        status: "packed"
      },

    ];
  }

  ///// open shipping label view
  trackStatus() {
    const dialogRef = this.dialog.open(ShippingLableComponent, {
      // height: '491px',
      // width: '875px',

      disableClose: true,
      height: "80vh",
      width: "80vw",
      maxHeight: "80vh",
      maxWidth: "80vw",
      data: { orderId: this.orderId }
    });

    const closeModal = dialogRef.afterClosed().subscribe(() => {

    }, err => {
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    });
    this.subscription$.push(closeModal);
  }
  ticketId: string = '';
  ticketCreatedDate: string = '';
  serviceName: string = '';
  csrPhoneNumber: string = '';

  //// get by id for view datas
  getOrderById() {
    const orderGet = this.orderService.get(this.orderId).subscribe(orderResponse => {
      if (orderResponse?.shippingCheckList?.fedexShippingLabelBlobName !== null || "") {
        this.showButton = true
      } else {
        this.showButton = false
      }
      this.defaultOrderId = orderResponse?.saleorderId;
      this.patientName = orderResponse?.shippingCheckList.patientName;
      this.patientId = orderResponse?.shippingCheckList.defaultPatientId;
      this.CSRName = orderResponse?.shippingCheckList.userName;
      this.csrPhoneNumber = orderResponse?.shippingCheckList.userPhoneNumber;
      this.ticketId = orderResponse?.shippingCheckList?.defaultTicketId;
      this.ticketCreatedDate = orderResponse?.shippingCheckList?.ticketCreatedDate == null ? undefined : new Date(orderResponse?.shippingCheckList?.ticketCreatedDate)?.toLocaleDateString("en-US");
      // this.lotNo=orderResponse?.;
      // this.serialNo=orderResponse?.;
      this.Qty = orderResponse?.shippingCheckList.totalItemQty;
      this.totalPrice = orderResponse?.shippingCheckList.totalItemPrice;
      this.Courier = orderResponse?.shippingCheckList.modeOfDelivery;
      this.serviceName = orderResponse?.shippingCheckList?.methodOfdelivery;
      this.trackId = orderResponse?.shippingCheckList.trackingId;
    })
    this.subscription$.push(orderGet);
  }
}
