import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { MatTextareaAutosize } from '@angular/material/input';
import { ViewPatientLedgerComponent } from 'projects/admin/src/app/view-patient-ledger/view-patient-ledger.component';
import { Subscription } from 'rxjs';
import Swal from 'sweetalert2';
import { defaultGuid } from '../medicare-check-list/medicare-check-list.component';
import { AddNoteTabComponent } from '../add-note-tab/add-note-tab.component';
import { PatientCommonNotesComponent } from 'projects/shared/src/app/components/patient-common-notes/patient-common-notes.component';
import { Title } from '@angular/platform-browser';
import { MatSelectChange } from '@angular/material/select';
interface Message {
  content: string;
  sender: string;
  timestamp: Date;
}
@Component({
  selector: 'app-text-conversation',
  templateUrl: './text-conversation.component.html',
  styleUrls: ['./text-conversation.component.scss']
})
export class TextConversationComponent implements OnInit {
  @ViewChild('autosize') autosize: MatTextareaAutosize | undefined;
  dataList: Array<any> = [];
  messages: Message[] = [];
  newMessage: string = '';
  $subscription: Subscription[] = [];
  isPatientMdltxt:boolean = false;
  selectedTemplate:any;
  constructor(
    public dialogRef: MatDialogRef<TextConversationComponent>,
    private title: Title,
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: {
      isPatientMdl :boolean;
    }={
      isPatientMdl: false,
    },
  ) {
    // Example of received messages
    this.messages.push({ content: 'Hello!', sender: 'friend', timestamp: new Date() });
    this.messages.push({ content: 'How are you?', sender: 'friend', timestamp: new Date() });

    this.dataList = [
      { code: 0, name: "Select Template" },
      { code: 1, name: "Supplies" },
      { code: 2, name: "TEMP001" },
      { code: 3, name: "TEMP002" },
      { code: 4, name: "TEMP003" },
    ]
    this.isPatientMdltxt = this.data.isPatientMdl;
  }
  ngOnInit(): void {
    this.selectedTemplate = 0;
    this.title.setTitle('Qsecure | Text Conversation');
    throw new Error('Method not implemented.');
  }

  sendMessage() {
    if (this.newMessage.trim() !== '') {
      this.messages.push({ content: this.newMessage, sender: 'me', timestamp: new Date() });
      this.newMessage = '';
      setTimeout(() => {
        if (this.autosize) {
          this.autosize.resizeToFitContent(true);
        }
      }, 0);
    }
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  viewPatientLedgerPopup(patientId, chartNo) {
    this.dialog.open(ViewPatientLedgerComponent, {
      width: '100vw',
      height: '100vh',
      maxWidth: '100vw',
      maxHeight: '100vh',
      panelClass: 'fullscreen-dialog-panel',
      disableClose: true,
      data: {
        patientId: patientId,
        viewLedger: true,
        chartNo: chartNo,
      },
    });
    this.dialog.afterAllClosed.subscribe((resposne) => { });
  }
  openAddNotesPopUp(
    defaultFaxId: string,
    patientId: string,
    docId: string,
    isCallNote: boolean,
    chartNo: string
  ) {
    const config: MatDialogConfig = {
      disableClose: true,
      minHeight: '30vh',
      minWidth: '50vw',
      data: {
        // claimId: '00000000-0000-0000-0000-000000000000',
        noteType: 'patient', // 'Order Note',
        notesId: '00000000-0000-0000-0000-000000000000',
        // orderId: '0b50be04-6c75-92d3-97c6-3a0e17ee6a18',
        patientId: patientId,
        defaultFaxId: defaultFaxId,
        isCallNote: isCallNote,
        isPending: false,
        chartNo: chartNo,
        docId: docId,
        inboundStatusShortCodeId: defaultGuid,
        addnotes: false,
      },
    };
    const dialogRef = this.dialog.open(AddNoteTabComponent, config);

    const closeDialog = dialogRef.afterClosed().subscribe(
      () => {
        // this.getReminderTableData();
      },
      (err) => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      }
    );
    this.$subscription.push(closeDialog);
  }
  openViewNotesPopup(patientID) {
    const config: MatDialogConfig = {
      disableClose: true,
      width: '70vw',
      maxWidth: '70vw',
      height: '40vw',
      maxHeight: '40vw',
      data: {
        patientId: patientID,
        pageType: 'Patient Note',
        isAddVisible: false,
      },
    };
    const dialogRef = this.dialog.open(PatientCommonNotesComponent, config);

    dialogRef.afterClosed().subscribe(
      () => {
        //this.getReminderTableData();
      },
      (err: HttpErrorResponse) => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      }
    );
  }
  isEmpty(value: any): boolean {
    return (
      value === null ||
      value === undefined ||
      value === '' ||
      value === defaultGuid
    );
  }

  menuOpen: boolean = false;
  selectTemplateDrp() {
    this.menuOpen = !this.menuOpen;
    // this.listElem.nativeElement.classList.toggle('show-menu');
  }

  shouldShowToday(): boolean {
    const today = new Date().toDateString();
    return this.messages.some(message => new Date(message.timestamp).toDateString() === today);
  }

  isToday(timestamp: Date): boolean {
    const today = new Date().toDateString();
    return new Date(timestamp).toDateString() === today;
  }

  handleEnter(event: Event, msgInput: HTMLTextAreaElement): void {
    event.preventDefault();
    // Send the message
    this.sendMessage();
    // Clear the textarea and model
    msgInput.value = '';
    this.newMessage = '';
  }

  onSelectionTemplateChange(event: MatSelectChange): void {
    this.selectedTemplate = event.value;
    if (this.selectedTemplate == 1) {
      this.newMessage = "Monitor Medical : Your Supplies for (Hybernite) 6' heated tubing w/ brick is ready. Insurance will cover $250,and you are responsible for $45 To confirm your orderplease click the link https://qsd.peoplemailer.com/?Id=Yanieo";
    } else {
      this.newMessage = '';
    }
    
  }
}
export var chatMessages = [
  {
    message: 'Hi',
    userId: 2,
    time: "12:13"
  },
  {
    message: 'Hi Dev',
    userId: 1,
    time: "12:13"
  },
  {
    message: 'Whats up',
    userId: 2,
    time: "12:13"
  },
  {
    message: 'Fine',
    userId: 1,
    time: "12:13"
  },
  {
    message: 'All good? Whats new',
    userId: 1,
    time: "12:13"
  },
  {
    message: 'Learning MEAN',
    userId: 2,
    time: "12:13"
  },
  {
    message: 'Wow',
    userId: 1,
    time: "12:13"
  },
  {
    message: 'This chat app is awesome',
    userId: 2,
    time: "12:13"
  }
]
