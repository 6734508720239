import { Component, Inject, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import { ResponseclaimstatusDrpDTO } from '../../../claim-proxy/claim-processing-management/rcm/billing-management/claim-status/models';
import { ClaimStatusService } from '../../../claim-proxy/claim-processing-management/rcm/billing-management/optimization/claim/claim-status.service';
import { map, startWith } from 'rxjs/operators';
import {
  NotesTypeDTO,
  PatientMasterDropdownDTO,
} from 'projects/patient/src/app/dropdown-proxy/dropdown-management/dropdowns/dto/patient/models';
import { PatientDropdowns } from 'projects/patient/src/app/dropdown-proxy/dropdown-management/dropdowns';
import { PatientMasterDropdownService } from 'projects/patient/src/app/dropdown-proxy/dropdown-management/dropdowns/master-dropdown-service/patient-master-dropdown.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { defaultGuid } from 'projects/shared/src/app/enums/allenums.enum';
import { ClaimFormDetailsService } from '../../../claim-proxy/claim-processing-management/rcm/billing-management/billing/claim-form-details.service';
import { ToastrService } from 'ngx-toastr';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-update-claim-status',
  templateUrl: './update-claim-status.component.html',
  styleUrls: ['./update-claim-status.component.scss'],
})
export class UpdateClaimStatusComponent implements OnInit {
  UpdateClaimForm: FormGroup;
  filteredOrderStatusList: Observable<ResponseclaimstatusDrpDTO[]>;
  isLoadClaimStatusDropdownData: boolean = false;
  lstOrderStatusList: any;
  private loadClaimStatusDropdownDataAPICall: Subscription;
  lstNotesDropdownList: NotesTypeDTO[] = [];
  public filteredNoteTypesList: Observable<NotesTypeDTO[]>;
  isLoadNotesDropdownData: boolean = false;
  private loadNotesDropdownAPICall: Subscription;
  isUpdateClaimData: boolean = false;
  private UpdateClaimAPICall: Subscription;
  notesText = '';

  constructor(
    private toaster: ToastrService,
    public updateClaimDialogRef: MatDialogRef<UpdateClaimStatusComponent>,
    private claimStatusService: ClaimStatusService,
    private claimFormDetailsService:ClaimFormDetailsService,
    private patientDropdownService: PatientMasterDropdownService,
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      cliamData: any;
    } = {
      cliamData: [],
    }
  ) {}

  ngOnInit(): void {
    this.initializeForms();
    this.loadClaimStatusDropdown();
    this.loadNotesDropdown();
  }

  ngOnDestroy(): void {
    if (this.loadClaimStatusDropdownDataAPICall) {
      this.loadClaimStatusDropdownDataAPICall.unsubscribe();
    }
    if (this.loadNotesDropdownAPICall) {
      this.loadNotesDropdownAPICall.unsubscribe();
    }
    if (this.UpdateClaimAPICall) {
      this.UpdateClaimAPICall.unsubscribe();
    }
  }
  initializeForms() {
    this.UpdateClaimForm = this.fb.group(
      {
        drpOrderStatus: new FormControl(this.data.cliamData.claimStatusId || defaultGuid, [Validators.required]),
        drpNotesType: new FormControl('', [Validators.required]),
        txtStatusFilter: new FormControl(''),
        txtNotesTypeFilter: new FormControl(''),
        txtAreaNotes: new FormControl('', [Validators.required]),
      }
      // ,
      // { validator: this.dateRangeValidator }
    );
  }

  loadClaimStatusDropdown() {
    this.isLoadClaimStatusDropdownData = true;
    try {
      if (this.loadClaimStatusDropdownDataAPICall) {
        this.loadClaimStatusDropdownDataAPICall.unsubscribe();
      }
      this.loadClaimStatusDropdownDataAPICall = this.claimStatusService
        .getdrpclaimStaus()
        .subscribe(
          (response) => {
            this.lstOrderStatusList = response;
            this.filteredOrderStatusList = this.UpdateClaimForm.get(
              'txtStatusFilter'
            ).valueChanges.pipe(
              startWith(''),
              map((value) =>
                this.lstOrderStatusList?.filter((option) =>
                  option?.claimStatus
                    ?.toLowerCase()
                    ?.includes(value?.toLowerCase() ?? '')
                )
              )
            );
            setTimeout(() => {
              this.isLoadClaimStatusDropdownData = false; // Enable the checkbox
            }, 1000);
          },
          (error) => {
            console.error('Error fetching claim details:', error);
            setTimeout(() => {
              this.isLoadClaimStatusDropdownData = false; // Enable the checkbox
            }, 1000);
          }
        );
    } catch (error) {
      console.error('Unexpected error:', error);
      setTimeout(() => {
        this.isLoadClaimStatusDropdownData = false; // Enable the checkbox
      }, 1000);
    } finally {
    }
  }

  loadNotesDropdown() {
    this.isLoadNotesDropdownData = true;
    try {
      if (this.loadNotesDropdownAPICall) {
        this.loadNotesDropdownAPICall.unsubscribe();
      }
      let patientDropdownInput: PatientDropdowns[];
      patientDropdownInput = [PatientDropdowns.NoteTypes];

      this.loadNotesDropdownAPICall = this.patientDropdownService
        .getPatientDropdownsByInput(patientDropdownInput)
        .subscribe(
          (response) => {
            this.lstNotesDropdownList = response.lstNotesType;
            this.filteredNoteTypesList = this.UpdateClaimForm.get(
              'txtNotesTypeFilter'
            ).valueChanges.pipe(
              startWith(''),
              map((value) =>
                this.lstNotesDropdownList?.filter((option) =>
                  option?.notesType
                    ?.toLowerCase()
                    ?.includes(value?.toLowerCase() ?? '')
                )
              )
            );
            setTimeout(() => {
              this.isLoadNotesDropdownData = false; // Enable the checkbox
            }, 1000);
          },
          (error) => {
            console.error('Error fetching claim details:', error);
            setTimeout(() => {
              this.isLoadNotesDropdownData = false; // Enable the checkbox
            }, 1000);
          }
        );
    } catch (error) {
      console.error('Unexpected error:', error);
      setTimeout(() => {
        this.isLoadNotesDropdownData = false; // Enable the checkbox
      }, 1000);
    } finally {
    }
  }

  resetClaim() {
    this.UpdateClaimForm?.reset();
  }
  updateClaim() {
    this.isUpdateClaimData = true;
    try {
      if (this.UpdateClaimAPICall) {
        this.UpdateClaimAPICall.unsubscribe();
      }
      let gClaimId: string, gStatusId: string, gNoteTypeId: string, sNoteType: string, sComments: string;
      gClaimId=this.data.cliamData.claimId;
      gStatusId=this.UpdateClaimForm.value.drpOrderStatus;
      gNoteTypeId=this.UpdateClaimForm.value.drpNotesType.id;
      sNoteType=this.UpdateClaimForm.value.drpNotesType.notesType;
      sComments=this.UpdateClaimForm.value.txtAreaNotes;
      this.UpdateClaimAPICall = this.claimFormDetailsService
        .updateClaimStatusByGClaimIdAndGStatusIdAndGNoteTypeIdAndSNoteTypeAndSComments(gClaimId,gStatusId,gNoteTypeId,sNoteType,sComments)
        .subscribe(
          (response) => {
            this.toaster.success(
              'The Claim Status has been updated.',
              'Success'
            );
            this.updateClaimDialogRef.close(response);
            setTimeout(() => {
              this.isUpdateClaimData = false; // Enable the checkbox
            }, 1000);
          },
          (error) => {
            Swal.fire({
          icon: 'info',
          text: error?.error?.error?.message,
        });
            setTimeout(() => {
              this.isUpdateClaimData = false; // Enable the checkbox
            }, 1000);
          }
        );
    } catch (error) {
      console.error('Unexpected error:', error);
      setTimeout(() => {
        this.isUpdateClaimData = false; // Enable the checkbox
      }, 1000);
    } finally {
    }
  }
}
