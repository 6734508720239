import { TableService } from './../table.service';
import { Directive, ElementRef } from '@angular/core';
import { fontStyling } from 'projects/patient/src/environments/environment';

@Directive({
  selector:
    'p,a[id="page-header-user-dropdown"],li,label,span,mat-placeholder,mat-label,mat-checkbox,td,font',
})
export class NormalConfigDirective {
  constructor(private el: ElementRef<HTMLElement>) {
    this.el.nativeElement.style.font = fontStyling?.normalConfig?.font;
  }
}
@Directive({
  selector: 'b,th,[href]',
})
export class BoldConfigDirective {
  constructor(private el: ElementRef<HTMLElement>) {
    this.el.nativeElement.style.font = fontStyling?.boldConfig?.font;
  }
}
@Directive({
  selector: 'h1',
})
export class H1tagsConfigDirective {
  constructor(private el: ElementRef<HTMLElement>) {
    this.el.nativeElement.style.font = fontStyling?.hConfig.h1?.font;
  }
}
@Directive({
  selector: 'h2',
})
export class H2tagsConfigDirective {
  constructor(private el: ElementRef<HTMLElement>) {
    this.el.nativeElement.style.font = fontStyling?.hConfig.h2?.font;
  }
}
@Directive({
  selector: 'h3',
})
export class H3tagsConfigDirective {
  constructor(private el: ElementRef<HTMLElement>) {
    this.el.nativeElement.style.font = fontStyling?.hConfig.h3?.font;
  }
}
@Directive({
  selector: 'h4',
})
export class H4tagsConfigDirective {
  constructor(private el: ElementRef<HTMLElement>) {
    this.el.nativeElement.style.font = fontStyling?.hConfig.h4?.font;
  }
}
@Directive({
  selector: 'h5',
})
export class H5tagsConfigDirective {
  constructor(private el: ElementRef<HTMLElement>) {
    this.el.nativeElement.style.font = fontStyling?.hConfig.h5?.font;
  }
}
@Directive({
  selector: 'h6',
})
export class H6tagsConfigDirective {
  constructor(private el: ElementRef<HTMLElement>) {
    this.el.nativeElement.style.font = fontStyling?.hConfig.h6?.font;
  }
}
