import { mapEnumToOptions } from '@abp/ng.core';

export enum AddressType {
  Billing = 0,
  Delivery = 1,
}

export const addressTypeOptions = mapEnumToOptions(AddressType);


