import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatAccordion } from '@angular/material/expansion';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { ItemExportDatas, ItemExportDTO } from 'projects/patient/src/app/patient-proxy/patient/dto/models';
import { ExportType } from 'projects/patient/src/app/patient-proxy/patient/enumeration-data/export-type.enum';
import { ReportService } from 'projects/patient/src/app/patient-proxy/patient/report.service';
import { NumberValidators } from 'projects/shared/src/app/validations/number-validator';
import { Subscription } from 'rxjs';
import Swal from 'sweetalert2';
import { CreateUpdateProductDetailsDTO, UploadProductSerialNumbersDTO } from '../item-proxy/item-management/items/dto/models';

import { ProductStockService } from '../item-proxy/item-management/items/product-stock.service';
import { ToastrService } from "ngx-toastr";
import { ProductStockDetailsService } from '../item-proxy/item-management/optimization';
import { CreateUpdateProductStockDetailsDTO, ProductLotDetailsDTO, UploadProductStockSerialNumbersDTO } from '../item-proxy/item-management/optimization/dto';
@Component({
  selector: 'app-stock-view',
  templateUrl: './stock-view.component.html',
  styleUrls: ['./stock-view.component.scss']
})
export class StockViewComponent implements OnInit , OnDestroy{
  stockForm: FormGroup;
  stockSearchForm: FormGroup;
  step: boolean = false;
  isShowSpinner: boolean = false;
  show: boolean = true;
  isLoading: boolean = false;
  productName: string = "";
  brandName: string = "";
  category: string = "";
  vendor: string = "";
  productId: string = "";
  brandId: string = "";
  categoryId: string = "";
  vendorId: string = "";
  pricePerUnit: number;
  quantity: number;
  soldQuantity: number;
  showSave: boolean = true;
  LotNo: string = "";
  Qty: number;
  BalQty: number;
  Status: string = "";
  id: string = "";
  lotId: string = "";
  productserualNoDetails: any;
  showSerial: boolean = true;
  dataSource: MatTableDataSource<any> = new MatTableDataSource<any>([]);
  tableData: any[] = [];
  @ViewChild(MatAccordion) accordion: MatAccordion;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  displayedColumns: string[] = [  'productNumber', 'orderId', 'status', 'soldDate','Options',];
  subscription$: Subscription[]=[];
  constructor(private formBuilder: FormBuilder,
    private stockService: ProductStockService,
    private stockDetails : ProductStockDetailsService,
    private numberValidators: NumberValidators,
    private activatedRoute: ActivatedRoute,
    public router: Router,
    private title: Title,
    private reportService: ReportService,
    private toastr: ToastrService,
  ) { }

  ngOnInit(): void {
   const activateReport = this.activatedRoute.paramMap.subscribe((response) => {
      this.id = response?.get('id') ?? '';
      // this.lotId = response?.get('lotId') ?? '';
    });
    this.subscription$.push(activateReport);

    this.stockForm = this.formBuilder.group({
      txtSerailNo: new FormControl(""),
      txtOrderNo: new FormControl(""),
      txtStatus: new FormControl(""),
      txtSoldDate: new FormControl(""),
    });
    this.stockSearchForm = this.formBuilder.group({
      productNumberSearch: new FormControl(""),
      orderIdSearch: new FormControl(""),
      statusSearch: new FormControl(""),
      soldDateSearch: new FormControl(""),

    });
    this.getSummaryDetails()
    this.title.setTitle("Qsecure | Product Stocks View");
    this.getTableData();
  }
  ngOnDestroy(): void {
    this.subscription$.forEach((sub) => {
      sub && sub.unsubscribe();
     });
    }
  getSummaryDetails(){
    let productIds: any[] = [];
    let stockStatus: any[] = [];
    stockStatus.push('All');
    let createdDate = null;

    const stockListByFilter = this.stockDetails
    .getSearchStocksBasedonFilter(
      productIds,
      stockStatus,
      createdDate,
      createdDate
    )
    .subscribe(
      (response) => {
     response.forEach(element =>{
      if(element.id == this.id){
        this.brandName = element?.brandName,
        this.productName = element?.productName,
        this.vendor = element?.vendorName,
        this.category = element?.categoryName,
        this.quantity = element?.quantity,
        this.BalQty = element?.balanceQuantity,
        this.Status = element?.status

      } else {

      }
     })
      },
      (err) => {}
    );
   }

  productDetailsId: string = "";
  orderId: string = "";
  ViewItem(data) {
    this.show = false
    this.productDetailsId = data?.productDetailsId
    this.orderId = data?.orderId
    const getProduct = this.stockDetails.get(data.id).subscribe(response => {
      // if (response?.status === "Sold Out") {
      //   this.showSerial = false
      //   this.showSave = false;
      // } else {
      //   this.showSerial = true
      //   this.showSave = true;
      // }
      this.stockForm.patchValue({
        txtSerailNo: data?.serialNo,
        txtOrderNo: data?.defaultOrderId,
        txtStatus: data?.status,
        txtSoldDate: data?.soldDate,
      })
      // this.itemId = response.id;
    }, err => {
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    });
    this.subscription$.push(getProduct);
  }

  backToStock() {
    this.router.navigate(['inventory/stock-list-tab'])

  }
 
  saveStock() {
    this.productserualNoDetails   = [{
      productDetailsId: this.productDetailsId,
      serailNo: this.stockForm.value.txtSerailNo,
      orderId: this.orderId,
      defaultOrderId: this.stockForm.value.txtOrderNo,
      soldDate: this.stockForm.value.txtSoldDate,
      status:this.stockForm.value.txtStatus,
    }]
    let stockDetail: CreateUpdateProductStockDetailsDTO = {
      brandId: this.brandId,
      categoryId: this.categoryId,
      productId: this.productId,
      vendorId: this.vendorId,
      lotNo: this.LotNo,
      pricePerUnit: this.pricePerUnit,
      quantity: this.quantity,
      soldQuantity: this.soldQuantity,
      isAllSerialNumbersUpdated: '',

      productSerialNumberDetails: this.productserualNoDetails
     
      
    }
    this.stockDetails.update(this.id, stockDetail).subscribe(response => {
      // Swal.fire({ title: 'Success', html: 'Saved Successfully', icon: 'success', timer: 3000, timerProgressBar: true });
      this.toastr.success('Saved Successfully','Success')
      this.show = true
      this.getTableData()
    }, err => {
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    });
  }

  /// import items
  importItems(value: any) {
    const fileList = value;
    let itemFile: File = null;
    for (let index = 0; index < fileList.length; index++) {
      if (fileList[index].type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") {
        itemFile = fileList?.item(index);
      };
    }
    let itemFileResultValue: string = "";
    let reader = new FileReader();
    reader.readAsDataURL(itemFile);
    reader.onload = () => {
      itemFileResultValue = reader.result.slice(78).toString();
      if (itemFileResultValue && itemFileResultValue !== "") {
        const input: UploadProductStockSerialNumbersDTO = {
          fileContentAsBase64String: itemFileResultValue,
          fileName: itemFile.name,
          id: this.id,
          lotNumber: this.LotNo,
        }
        this.stockDetails.importProductStockSerialNumbers(input).subscribe(response => {

          // Swal.fire({ title: 'Success', html: 'Selected File Imported Successfully', icon: 'success', timer: 3000, timerProgressBar: true }).then(result => {
            this.getTableData();
            this.toastr.success('Selected File Imported Successfully','Success')
          // })
        }, err => {
          const data: HttpErrorResponse = err;
          Swal.fire({
            icon: 'info',
            text: data?.error?.error?.message,
          });
        });
      }

    }
  }

  /// download template from api
  downloadTemplate() {
   const downloadTemplate = this.stockService.downloadProductTemplate().subscribe(response => {
      let filelocation: String = String("data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64," + response);
      let link = document.createElement("a");
      link.download = "Item" + ".xlsx";
      link.href = filelocation.toString();
      link.click();
    }, err => {
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    }, () => {
    })
    this.subscription$.push(downloadTemplate)
  }
  importItemsFormFile(value: any) {
    this.getTableData();
  }
  data: any;
  //// table data values get
  getTableData() {

    // this.productName =this.data.data?.productName;
    //  this.brandName = this.data.data?.productBrandName;
    //  this.category = this.data.data?.productCategoryName;
    //  this.vendor = this.data.data?.vendorName;
    //  this.LotNo = this.data.data?.lotNo;
    //  this.Qty = this.data.data?.purchasedStockCount;
    //  this.BalQty = this.data.data?.balStock;
    //  this.Status = this.data.data?.status;

    this.isShowSpinner = true;
    this.tableData = [];
  //  const getProductList = this.stockService.getProductDetailsList(this.id, this.lotId).subscribe(response => {

  //     response?.productLotDetails[0]?.productDetails?.forEach(ele => {

  //       this.productName = response?.productName;
  //       this.brandName = response?.productLotDetails[0].productBrandName;
  //       this.category = response?.productLotDetails[0].productCategoryName;
  //       this.vendor = response?.productLotDetails[0].vendorName;
  //       this.LotNo = response?.productLotDetails[0].lotNo;
  //       this.Qty = response?.productLotDetails[0].purchasedStockCount;
  //       this.BalQty = response?.productLotDetails[0].balanceStockCount;
  //       this.Status = response?.productLotDetails[0].stockStatus;
  //       this.tableData.push({
  //         id: response?.id,
  //         productId: response?.productId,
  //         productName: response?.productName,
  //         productBrandName: response?.productLotDetails[0]?.productBrandName,
  //         productCategoryName: response?.productLotDetails[0]?.productCategoryName,
  //         purchasedStockCount: response?.productLotDetails[0]?.purchasedStockCount,
  //         stockPurchasedDate: response?.productLotDetails[0]?.stockPurchasedDate,
  //         lotNo: response?.productLotDetails[0]?.lotNo,
  //         lotId: response?.productLotDetails[0]?.lotId,
  //         price: response?.productLotDetails[0]?.price,
  //         serialNo: ele?.serailNo,
  //         defaultOrderId: ele?.defaultOrderId,
  //         status: ele?.status,
  //         productDetailsId: ele?.productDetailsId,
  //         orderId: ele?.orderId,
  //         soldDate: ele?.soldDate
  //         //  soldDate:ele?.
  //       });
  //     })
  //     this.isShowSpinner = false;
  //     this.dataSource = new MatTableDataSource(this.tableData);
  //     this.dataSource.sort = this.sort;
  //     this.dataSource.paginator = this.paginator;
  //   }, error => {
  //     const data: HttpErrorResponse = error;
  //     this.isShowSpinner = false;
  //     Swal.fire({
  //       icon: 'info',
  //       text: data?.error?.error?.message,
  //     });
  //     this.dataSource = new MatTableDataSource(this.tableData);
  //     this.dataSource.sort = this.sort;
  //     this.dataSource.paginator = this.paginator;
  //   })
  const getProductList = this.stockDetails.get(this.id).subscribe(response => {

    response.productSerialNumberDetails.forEach(x => {

        this.LotNo = response?.lotNo;
        this.id = response?.id;
        this.productId = response?.productId,
        this.brandId = response?.brandId,
        this.categoryId = response?.categoryId,
        this.vendorId = response?.vendorId,
        this.pricePerUnit = response?.pricePerUnit,
        this.soldQuantity = response?.soldQuantity,
        this.quantity = response?.quantity,
        this.tableData.push({
          id: response?.id,
          productId: response?.productId,
          productBrandId: response?.brandId,
          productCategoryId: response?.categoryId,
          productVendorId: response?.vendorId,
          pricePerUnit: response?.pricePerUnit,
          soldQuantity: response?.soldQuantity,
          quantity: response?.quantity,
          isAllSerialNumbersUpdated: response?.isAllSerialNumbersUpdated,
          lotNo: response?.lotNo,
          serialNo: x?.serailNo,
          defaultOrderId: x?.defaultOrderId,
          status: x?.status,
          productDetailsId: x?.productDetailsId,
          soldDate: x?.soldDate
          })
   
  
    })
    this.isShowSpinner = false;
    this.dataSource = new MatTableDataSource(this.tableData);
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }, error => {
    const data: HttpErrorResponse = error;
    this.isShowSpinner = false;
    Swal.fire({
      icon: 'info',
      text: data?.error?.error?.message,
    });
    this.dataSource = new MatTableDataSource(this.tableData);
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  })

    this.subscription$.push(getProductList);
  }
  numberOnly(event: { which: any; keyCode: any; }): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;

  }
  reset() {
    this.stockForm.patchValue({
      txtSerailNo: "",
    })
    this.showSave = true;
  }


  exportTableData() {
    let datas: ItemExportDatas[] = []
    this.dataSource?.data?.forEach(element => {
      
      datas.push({
        serialNo: element?.serialNo,
        orderId: element?.orderId === "00000000-0000-0000-0000-000000000000" ? '' : element?.orderId,
        productSoldStatus: element?.status,
        soldDate: element?.soldDate
      });
    })
    let itemExport: ItemExportDTO = {
      columns: ["Product Serial No", "Order Id", "Status", "Sold Date"],
      datas: datas
    };
   const exportReport =  this.reportService.exportItemReportsByExportTypeAndExportDatas(ExportType.ProductSerialNoReports, itemExport).subscribe(response => {
      if (response && response?.length !== 0) {
        let filelocation: String = String("data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64," + response);
        let link = document.createElement("a");
        link.download = ("Stock-View") + ".xlsx";
        link.href = filelocation.toString();
        link.click();
        // Swal.fire({ title: 'Success', html: 'Exported Successfully', icon: 'success', timer: 3000, timerProgressBar: true })
        this.toastr.success('Exported Successfully','Success')
      }
    }, err => {
      const data: HttpErrorResponse = err;
      let errorMesg: string = data?.error;
      if (errorMesg?.includes('Sorry!! There is no data in table to export')) {
        errorMesg = 'Sorry!! There is no data in table to export';
      }
      else {
        errorMesg = data?.error?.error?.message;
      }
      // Swal.fire({
      //   icon: 'info',
      //   text: errorMesg
      // });
      this.toastr.info(errorMesg)
    });
    this.subscription$.push(exportReport);
  }
}

