import { ListService } from '@abp/ng.core';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { Store } from '@ngxs/store';
import { UserService } from 'projects/admin/src/app/admin-proxy/platform-app-management/rcm/platform-management/user/user.service';
import { IdentityUserDto } from 'projects/admin/src/app/admin-proxy/platform-app-management/volo/abp/identity/models';
import { PatientService } from 'projects/patient/src/app/patient-proxy/patient';
import { getPatient } from 'projects/patient/src/app/patient-store/patient.action';
import { billingStatus, OrderBillingStatus } from 'projects/shared/src/app/enums/allenums.enum';
import { TableService } from 'projects/shared/src/app/table.service';
import { TextValidators } from 'projects/shared/src/app/validations/text-validator';
import { Subscription } from 'rxjs';
import Swal from 'sweetalert2';
import { SaleorderService } from '../order-proxy/order-management/order';

import { ReminderDTO, SaleorderDTO } from '../order-proxy/order-management/order/dto';
import { ReminderService } from '../order-proxy/order-management/order/reminder.service';
import { getOrder } from '../order-store/order.action';
import { addReminder, getReminder, updateReminder } from '../order-store/reminder.action';
import { ToastrService } from "ngx-toastr";
@Component({
  selector: 'app-reminder-popup',
  templateUrl: './reminder-popup.component.html',
  styleUrls: ['./reminder-popup.component.scss'],
  providers: [ListService]
})

export class ReminderPopupComponent implements OnInit, OnDestroy {
  userId: string = "";
  tenantId: string = "";
  arrUsersList: IdentityUserDto[] = [];
  reminderForm: FormGroup;
  reminderId: string = "";
  isShown: boolean = false;
  minDate: Date;
  saveButtonHide: boolean;
  orderStatus: boolean = true;
  subscription$: Subscription[] = [];

  constructor(
    private reminder: ReminderService,
    // private list: ListService,
    private table: TableService,
    private formBuilder: FormBuilder,
    private store: Store,
    public dialog: MatDialog,
    public userService: UserService,
    private textValidators: TextValidators,
    private saleOrderService: SaleorderService,
    private patientService: PatientService,
    private toastr: ToastrService,
    @Inject(MAT_DIALOG_DATA) public data: {
      patientId?: string;
      saleOrderId?: string;
      patientName?: string;
      defaultPatientId?: string
      Id?: string
      orderId?: string
    }
  ) { }


  // ! Page Initialization funtions
  ngOnInit(): void {
    this.minDate = new Date();
    this.minDate.setDate(this.minDate.getDate() + 0);
    this.userId = localStorage.getItem("userId");
    this.tenantId = localStorage.getItem("tenantId");
    this.createReminderForm();
    this.data.Id !== null && this.data.Id !== undefined && this.data.Id !== "" && this.getReminderByid(this.data.Id);
    this.data.orderId = this.data?.orderId ?? "";
    this.data?.orderId && this.getOrderById(this.data?.orderId);
    const userList = this.userService.getIdentityUser().subscribe((response: IdentityUserDto[]) => {
      this.arrUsersList = response;
    }, err => {
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    })
    this.subscription$.push(userList);
    //
    this.minDate = new Date();
    this.minDate.setDate(this.minDate.getDate() + 0);


  }

  getOrderById(orderId: string) {
    const orderGet = this.saleOrderService.get(this.data?.orderId).subscribe(response => {
      // this.store.dispatch(new getOrder(this.data?.orderId)).subscribe(response => {
      const selectedOrder: SaleorderDTO = response;
      //     this.orderStatus = (selectedOrder?.orderBilling?.status === billingStatus.ReadyToBilling || selectedOrder?.orderBilling?.status === billingStatus.OrderPending) ? true : false;
      const orderBillstatus: [string, OrderBillingStatus][] = Object.entries(OrderBillingStatus);
      this.orderStatus = orderBillstatus?.some(s => ((s[1] ?? "") === (selectedOrder?.orderBilling?.status)))
    }, err => {
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    })
    this.subscription$.push(orderGet);
  }

  getReminderByid(Id: string) {
    this.isShown = true;
    this.store.dispatch(new getReminder(Id)).subscribe(response => {
      const selectedReminder: ReminderDTO = response?.reminderState?.selectedreminder;
      if (selectedReminder && selectedReminder?.isCompleted === 0 && (selectedReminder?.completedOn === "" || selectedReminder?.completedOn === null)) {
        selectedReminder !== null && selectedReminder !== undefined && this.reminderForm.patchValue({
          txtSubject: selectedReminder?.subject,
          txtDate: selectedReminder?.reminderDate ? new Date(selectedReminder?.reminderDate) : "",
          chkDontShowAgain: selectedReminder?.isDontShowAgain,
          chkCompleted: selectedReminder?.isCompleted,
          drpAssignedTo: selectedReminder?.assignedTo,
          chkAllUsers: selectedReminder?.isAllUsers,
          txtPatient: selectedReminder?.patient,
          txtCompletedBy: selectedReminder?.completedBy,
          dtCompletedOn: selectedReminder?.completedOn,
          dtEnteredDateTime: selectedReminder?.enteredDateTime
        })
        this.reminderId = selectedReminder?.id;
      } else {
        selectedReminder !== null && selectedReminder !== undefined && this.reminderForm.patchValue({
          txtSubject: selectedReminder?.subject,
          txtDate: selectedReminder?.reminderDate ? new Date(selectedReminder?.reminderDate) : "",
          chkDontShowAgain: selectedReminder?.isDontShowAgain,
          chkCompleted: selectedReminder?.isCompleted,
          drpAssignedTo: selectedReminder?.assignedTo,
          chkAllUsers: selectedReminder?.isAllUsers,
          txtPatient: selectedReminder?.patient,
          txtCompletedBy: selectedReminder?.completedBy,
          dtCompletedOn: selectedReminder?.completedOn,
          dtEnteredDateTime: selectedReminder?.enteredDateTime
        })
        this.reminderId = selectedReminder?.id;
        this.reminderForm.disable();
      }

    })

  }

  // ! Form Creation and Few Validations
  createReminderForm() {
    this.reminderForm = this.formBuilder.group({
      txtSubject: new FormControl("", [Validators.required]),
      txtDate: new FormControl("", [Validators.required]),
      // rbtnRemiderStatus:new FormControl(""),
      chkDontShowAgain: new FormControl(""),
      chkCompleted: new FormControl(""),
      chkAllUsers: new FormControl(""),
      drpAssignedTo: new FormControl(null),
      txtPatient: new FormControl(""),
      txtCompletedBy: new FormControl(""),
      dtCompletedOn: new FormControl(""),
      dtEnteredDateTime: new FormControl("", [Validators.required]),
    })
    const valueChanges = this.reminderForm.valueChanges.subscribe(() => {
      const txtSubject: AbstractControl = this.reminderForm?.get('txtSubject');
      const dtCompletedOn: AbstractControl = this.reminderForm?.get('dtCompletedOn');
      const txtCompletedBy: AbstractControl = this.reminderForm?.get('txtCompletedBy');
      if (txtSubject.dirty) {
        txtSubject?.value?.trim() === "" && txtSubject.setErrors({ required: true });
        txtSubject.markAsDirty();
        txtSubject.markAsTouched();
        //  txtSubject.markAsPristine();
        // txtSubject.markAsPristine();
      }
      if (txtCompletedBy.value === "" && dtCompletedOn.dirty && dtCompletedOn.valid && this.arrUsersList) {
        this.reminderForm.patchValue({
          txtCompletedBy: this.arrUsersList?.filter(v => v?.id === this.userId)[0]?.userName ?? "",
        })
      }
    })
    this.subscription$.push(valueChanges);
    //
    if (this.data && this.data?.patientId) {
      let strPatientName: string = '';
      if (this.data?.patientId) {
        const patientGet = this.patientService.get(this.data?.patientId).subscribe(response => {
          // this.store.dispatch(new getPatient(this.data?.patientId)).subscribe(response => {
          strPatientName = response?.personals?.firstName ?? "" + response?.personals?.lastName ?? "";
          this.reminderForm.patchValue({
            txtPatient: strPatientName ?? "",
            dtEnteredDateTime: new Date()
          })
        })
        this.subscription$.push(patientGet);
      }
    }
    this.reminderForm?.get('txtPatient').disable({ onlySelf: true });
    this.reminderForm?.get('txtCompletedBy').disable({ onlySelf: true });
    this.reminderForm?.get('dtEnteredDateTime').disable({ onlySelf: true });
  }


  organizationUnitId = null;

  // ! Save Reminders
  saveReminders() {
    this.saveButtonHide = true;
    // this.organizationUnitId = localStorage.getItem('initialOrganizationUnitId');
    let reminderData: ReminderDTO;
    if (this.reminderId !== null && this.reminderId !== undefined && this.reminderId !== "") {

      reminderData = {
        id: this.reminderId,
        patientId: this.data.patientId,
        patientName: this.data.patientName ?? "",
        reminderDate: this.reminderForm.value.txtDate,
        saleorderId: this.data.saleOrderId,
        subject: this.reminderForm.value.txtSubject,
        stage: this.data.defaultPatientId,
        organizationUnitId: this.organizationUnitId,
        isDontShowAgain: +this.reminderForm.value.chkDontShowAgain,
        isCompleted: +this.reminderForm.value.chkCompleted ? 1 : 0,
        assignedTo: this.reminderForm.value.drpAssignedTo ?? "",
        isAllUsers: this.reminderForm.value.chkAllUsers ? 1 : 0,
        patient: this.reminderForm.getRawValue().txtPatient ?? "",
        completedBy: this.reminderForm.getRawValue().txtCompletedBy,
        completedOn: this.reminderForm.value.dtCompletedOn ?? "",
        enteredDateTime: this.reminderForm.getRawValue().dtEnteredDateTime ?? "",
      };

      this.store.dispatch(new updateReminder(this.reminderId, reminderData)).subscribe(response => {
        //
        // Swal.fire({ title: 'Success', html: 'Saved Successfully', icon: 'success', timer: 3000, timerProgressBar: true });
        this.toastr.success('Saved Successfully','Success')
        this.dialog.closeAll();

      }, err => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      })

    } else {

      reminderData = {
        patientId: this.data.patientId,
        patientName: this.data.patientName ?? "",
        reminderDate: this.reminderForm.value.txtDate,
        saleorderId: this.data.saleOrderId,
        subject: this.reminderForm.value.txtSubject,
        stage: this.data.defaultPatientId,
        organizationUnitId: this.organizationUnitId,
        isDontShowAgain: +this.reminderForm.value.chkDontShowAgain,
        isCompleted: +this.reminderForm.value.chkCompleted ? 1 : 0,
        assignedTo: this.reminderForm.value.drpAssignedTo ?? "",
        isAllUsers: this.reminderForm.value.chkAllUsers ? 1 : 0,
        patient: this.reminderForm.getRawValue().txtPatient ?? "",
        completedBy: this.reminderForm.getRawValue().txtCompletedBy,
        completedOn: this.reminderForm.value.dtCompletedOn ?? "",
        enteredDateTime: this.reminderForm.getRawValue().dtEnteredDateTime ?? "",

      };


      this.store.dispatch(new addReminder(reminderData)).subscribe(response => {
        //
        // Swal.fire({ title: 'Success', html: 'Saved Successfully', icon: 'success', timer: 3000, timerProgressBar: true });
        this.toastr.success('Saved Successfully','Success')
        this.dialog.closeAll();
        this.saveButtonHide = false;
      }, err => {
        this.saveButtonHide = false;
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      })




    }

  }

  // ! Reminder Form Reset
  resetForm() {
    this.reminderForm.patchValue({
      txtSubject: "",
      txtDate: "",
      chkDontShowAgain: "",
      chkCompleted: "",
      chkAllUsers: "",
      drpAssignedTo: null,
      txtPatient: "",
      txtCompletedBy: "",
      dtCompletedOn: "",
      dtEnteredDateTime: ""
    })
    this.reminderId = "";

    this.reminderForm.markAsUntouched();
    this.reminderForm.updateValueAndValidity();
  }




  ngOnDestroy(): void {
    this.resetForm();
    this.subscription$?.forEach((sub) => {
      sub && sub?.unsubscribe();
    });
  }


}
