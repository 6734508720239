import { DatePipe } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Title } from '@angular/platform-browser';
import { DateValidator } from 'projects/shared/src/app/validations/date-validator';
import { Subscription } from 'rxjs';
import {
  debounceTime,
  filter,
  finalize,
  map,
  switchMap,
  tap,
} from 'rxjs/operators';
import Swal from 'sweetalert2';
import { searchItemPaymentsInput } from '../claim-proxy/claim-processing-management/rcm/billing-management/posting/dto/models';
import { ItemPaymentService } from '../claim-proxy/claim-processing-management/rcm/claim-processing-management/x12/item-payment.service';
import { regexPattern } from '../claim-status/claim-status.component';

@Component({
  selector: 'app-cheque-list',
  templateUrl: './cheque-list.component.html',
  styleUrls: ['./cheque-list.component.scss'],
  providers: [DatePipe],
})
export class ChequeListComponent implements OnInit {
  claimTableData: chequeList[] = [];
  dataSource: MatTableDataSource<any> = new MatTableDataSource<any>([]);
  @ViewChild(MatSort, { static: false }) sort: MatSort = new MatSort();
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  public arrDisplayedColumns: string[] = [
    'checkNo',
    'checkAmount',
    'claimNumber',
    'patientId',
    'patientName',
    'postingDate',
    'billed',
    'currentBalance',
    'checkDate',
  ];
  isLoading: boolean = false;
  subscription$: Subscription[] = [];
  chequeForm: FormGroup;
  constructor(
    private formbuilder: FormBuilder,
    private titleService: Title,
    private itemPaymentService: ItemPaymentService,
    private dateValidator: DateValidator,
  ) {}
  ngOnInit(): void {
    this.titleService.setTitle('Qsecure | Cheque List');
    this.chequeForm = this.formbuilder.group({
      chequeNumber: new FormControl(''),
      claimNumber: new FormControl(''),
      checkAmount: new FormControl(''),
      patientId: new FormControl(''),
      postingDate: new FormControl('', this.dateValidator?.dateVaidator),
      patientName: new FormControl(''),
      billedAmount: new FormControl(''),
      currentBalance: new FormControl(''),
      checkDate: new FormControl('', this.dateValidator?.dateVaidator),
    });

    this.getTableData();
//! Search cheque list by form values
    this.chequeForm.valueChanges
      .pipe(
        tap((x) => {
          this.isLoading = true;
          this.setTableData([]);
        }),
        filter((e) => e && this.chequeForm?.valid && this.chequeForm?.valid),
        debounceTime(300),
        map((response) => {
          const billedAmount =
            String(response?.billedAmount)?.replace(regexPattern, '') ?? '';
          const currentBalance =
            String(response?.currentBalance)?.replace(regexPattern, '') ?? '';
          const oSearchClaim: searchItemPaymentsInput = {
            checkNumber:
              String(response?.chequeNumber)?.trim()?.toLowerCase() ?? '',
            checkAmount:
              String(response?.checkAmount)?.trim()?.toLowerCase() ?? '',
            checkDate: String(response?.checkDate)?.trim()?.toLowerCase() ?? '',
            claimNumber:
              String(response?.claimNumber)?.trim()?.toLowerCase() ?? '',
            patientId: String(response?.patientId)?.trim()?.toLowerCase() ?? '',
            patientName:
              String(response?.patientName)?.trim()?.toLowerCase() ?? '',
            billedAmount: String(billedAmount?.trim()?.toLowerCase()) ?? '',
            currentBalance: String(currentBalance)?.trim()?.toLowerCase() ?? '',
            postingDate:
              String(response?.postingDate)?.trim()?.toLowerCase() ?? '',
          };
          return oSearchClaim;
        }),
        switchMap((value: searchItemPaymentsInput) =>
          this.itemPaymentService.getItemPaymentsBasedOnInput(value)
        ),
        finalize(() => {
          this.isLoading = false;
        }),
        tap((x) => {
          this.isLoading = false;
        })
      )
      .subscribe(
        (response) => {
          this.setTableData(response?.items?.reverse() ?? []);
          this.isLoading = false;
        },
        (err: HttpErrorResponse) => {
          this.isLoading = false;
          this.setTableData([]);
          const data: HttpErrorResponse = err;
          Swal.fire({ icon: 'info', text: data?.error?.error?.message });
        }
      );
  }

  //! get Check List
  getTableData() {
    this.isLoading = true;
    const input: searchItemPaymentsInput = {
      checkNumber: '',
      checkAmount: '',
      checkDate: '',
      claimNumber: '',
      patientId: '',
      patientName: '',
      postingDate: '',
      billedAmount: '',
      currentBalance: '',
    };
    this.itemPaymentService.getItemPaymentsBasedOnInput(input).subscribe(
      (response) => {
        this.isLoading = false;
        this.setTableData(response?.items?.reverse() ?? []);
      },
      (err: HttpErrorResponse) => {
        this.isLoading = false;
        this.setTableData([]);
        const data: HttpErrorResponse = err;
        Swal.fire({ icon: 'info', text: data?.error?.error?.message });
      }
    );
    this.chequeForm.patchValue({
      chequeNumber:"",
      claimNumber:"",
      checkAmount:"",
      patientId:"",
      postingDate:"",
      patientName:"",
      billedAmount:"",
      currentBalance:"",
      checkDate:"",
    })

  }
  setTableData(claims: any[]) {
    this.claimTableData = claims?.map((x) => {
      x.postingDate = dateFormatter(String(x?.postingDate ?? '')?.trim()) ?? ''; //(x?.postingDate);
      x.checkDate = dateFormatter(String(x?.checkDate ?? '')?.trim()) ?? ''; //(x?.postingDate);
      return x;
    });
    this.dataSource = new MatTableDataSource(this.claimTableData ?? []);
    this.sort.disableClear = true;
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }
 }

export interface chequeList {
  chequeNumber: string;
  claimNumber: string;
  checkAmount: string;
  patientId: string;
  claimPostedDate: string;
  patientName: string;
  billedAmount: string;
  currentBalance: string;
  claimServiceDate: string;
  claimId: string;
  defaultPatientId: string;
}
function dateFormatter(documentDate: string | Date): string {
  let resulteddocumentDate: string = null;
  if (typeof documentDate === 'string' && documentDate !== '') {
    const date = `${new Date(documentDate)?.toJSON()?.slice(0, 10)}`;
    resulteddocumentDate = date;
  }
  return resulteddocumentDate;
}
