<br />
<div class="card card-body">
  <h4 class="customThemeClass mar-bottom"><b>History</b></h4>
  <div class="row mt-1">
    <div class="col-lg-12">
      <div  class="table table-responsive">
        <app-table [strPageType]='strPageType' [isLoading]="isLoading"></app-table>
      </div>
    </div>
  </div>
</div>