import type { CreateUpdateCheckOutOrderCreateDTO } from './dto/models';
import { RestService } from '@abp/ng.core';
import { Injectable } from '@angular/core';
import type { SaleorderDTO } from '../order/dto/models';

@Injectable({
  providedIn: 'root',
})
export class CheckoutOrderService {
  apiName = 'orderManagement';

  createCheckOutOrder = (input: CreateUpdateCheckOutOrderCreateDTO) =>
    this.restService.request<any, SaleorderDTO>({
      method: 'POST',
      url: '/api/orderApp/checkout-order/create-check-out-order',
      body: input,
    },
     { apiName: this.apiName, skipHandleError: true });

  getCheckOutOrder = (sCheckOutOrderId: string, saleOrderId: string, sStatus: string) =>
    this.restService.request<any, boolean>({
      method: 'GET',
      url: '/api/orderApp/checkout-order/get-check-out-order',
      params: { sCheckOutOrderId, saleOrderId, sStatus },
    },
     { apiName: this.apiName, skipHandleError: true });

  getCheckOutOrderPaymentStatus = () =>
    this.restService.request<any, void>({
      method: 'GET',
      url: '/api/orderApp/checkout-order/get-check-out-order-payment-status',
    },
     { apiName: this.apiName, skipHandleError: true });

  getPaymentCapture = (saleOrderId: string) =>
    this.restService.request<any, SaleorderDTO>({
      method: 'GET',
      url: `/api/orderApp/checkout-order/get-payment-capture/${saleOrderId}`,
    },
     { apiName: this.apiName, skipHandleError: true });

  constructor(private restService: RestService) {}
}
