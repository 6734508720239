import { RestService } from '@abp/ng.core';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class PostingSearchService {
  apiName = 'billingManagement';

  searchPostingBySDefaultPatientCodeAndSPatientNameAndDtDObAndDtDosAndSPlanCodeAndSInsuranceNameAndDtCreatedDate = (sDefaultPatientCode: string, sPatientName: string, dtDOb: string, dtDos: string, sPlanCode: string, sInsuranceName: string, dtCreatedDate: string) =>
    this.restService.request<any, any>({
      method: 'POST',
      url: '/api/billingApp/posting-search/search-posting',
      params: { sDefaultPatientCode, sPatientName, dtDOb, dtDos, sPlanCode, sInsuranceName, dtCreatedDate },
    },
     { apiName: this.apiName, skipHandleError: true });

  constructor(private restService: RestService) {}
}
