import { Component, Input, OnInit } from '@angular/core';
// import { defaultGuid } from 'projects/admin/src/app/document-perviewer/document-perviewer.component';
import { PatientSummaryDTO } from '../patient-proxy/patient-optimization/dto/models';
import { PatientPersonalService } from '../patient-proxy/patient-optimization/patient-personal.service';
import { defaultGuid } from 'projects/shared/src/app/enums/allenums.enum';

@Component({
  selector: 'app-summary',
  templateUrl: './summary.component.html',
  styleUrls: ['./summary.component.scss']
})
export class SummaryComponent implements OnInit {
  @Input() patientId: string = '';
  patientStatus: string = "";
  lblPatientName: string = "";
  lblPtCurrentStatus: string = "";
  lblPatientGender: string = "";
  lblDefaultPatientId: string = "";
  lblPatientDOB: string = "";
  lblPatientHeight: string = "";
  lblBilledAmount: string = "";
  lblReceivedAmount: string = "";
  lblPendingAmount: string = "";
  lblBillingStatus: string = "";
  lblthirtyDayPaidAmnt: string = "";
  lblsixtyDayPaidAmnt: string = "";
  lblninetyDayPaidAmnt: string = "";
  lblAboveNinetyDayPaidAmnt: string = "";
  constructor(private patientPersonalService: PatientPersonalService) { }

  ngOnInit(): void {
    (this.patientId ?? "") !== "" && this.getPatientSummary();
  }

  //To get Patient Summary
  getPatientSummary() {
    let patientSummaryDto: PatientSummaryDTO;

    patientSummaryDto = {
      patientId: this.patientId,
      patientName: "",
      orderId:defaultGuid,
      defaultPatientId: "",
      defaultOrderId:"",
      currentStatus: "",
      dob: "",
      billedAmount: "0",
      receivedAmount: "0",
      pendingAmount: "0",
      billingStatus: "0",
      thirtyDayBilledAmnt: "0",
      sixtyDayBilledAmnt: "0",
      ninetyDayBilledAmnt: "0",
      aboveNinetyDayBilledAmnt: "0",
      gender:""
    }

    const result = this.patientPersonalService.patientSummaryByPatientSummaryDto(patientSummaryDto).subscribe(response => {

      let patientSummary: PatientSummaryDTO = response;
      if (patientSummary != null) {
        this.lblDefaultPatientId = patientSummary?.defaultPatientId;
        this.lblPatientName = patientSummary?.patientName;
        this.lblPatientDOB = (patientSummary?.dob ?? "") === '' ? "" : new Date(patientSummary?.dob)?.toLocaleDateString("en-US");
        //this.lblPatientDOB =( test ??"" )===''? "" : new Date(test)?.toLocaleDateString("en-US");
        this.lblPtCurrentStatus = patientSummary?.currentStatus;
        this.lblBilledAmount = patientSummary?.billedAmount;
        this.lblReceivedAmount = patientSummary?.receivedAmount;
        this.lblPendingAmount = patientSummary?.pendingAmount;
        this.lblBillingStatus = patientSummary?.billingStatus;
        this.lblthirtyDayPaidAmnt = patientSummary?.thirtyDayBilledAmnt;
        this.lblsixtyDayPaidAmnt = patientSummary?.sixtyDayBilledAmnt;
        this.lblninetyDayPaidAmnt = patientSummary?.ninetyDayBilledAmnt;
        this.lblAboveNinetyDayPaidAmnt = patientSummary?.aboveNinetyDayBilledAmnt;
      }
      else { patientSummary = patientSummaryDto; }
    });


  }
}
