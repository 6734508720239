<div class="container-fluid">
  <div class="row bg">
    <div class="col-12">
      <div class="d-flex align-item-center justify-content-between m-0">
        <h4 class="mt-2 mb-0 font-size-18"><b>Product Track View</b></h4>
        <div class="page-title-box page-title-right mt-1 mb-0">
          <ol class="breadcrumbs m-0">
            <li class="breadcrumb-item">Orders</li>
            <li class="breadcrumb-item">Product Track View</li>
          </ol>
        </div>
      </div>
    </div>
    <div class="card card-body mt-2 ml-2 mr-2">
      <div class="row">
        <div class="col-xl-4 pr-0">
          <div class="card card-body">
            <div class="text-center">
              <span style="font-size: larger">
                <b class="customThemeClass colored-heading">Order Details </b>
                <button
                  *ngIf="showButton"
                  mat-button
                  class="ml-3 btn btn-sm lightSeaGreen-dot"
                  (click)="trackStatus()"
                >
                  <i class=""></i> View Shipping Label
                </button>
              </span>
            </div>

            <div class="table-responsive mt-2">
              <div class="row pt-3">
                <div class="col-lg-5">
                  <!-- <h4 class="customThemeClass"><b>Order ID</b></h4> -->
                  <h4 class="mb-0 pt-2"><b>Tracking Id</b></h4>
                  <br />
                  <h4 class="mb-0"><b>Order Id</b></h4>
                  <br />
                  <h4 class="mb-0"><b>Patient Name</b></h4>
                  <br />
                  <h4 class="mb-0"><b>Patient Id</b></h4>
                  <br />
                  <h4 class="mb-0"><b>Ticket Id</b></h4>
                  <br />
                  <h4 class="mb-0"><b>Ticket Created Date</b></h4>
                  <br />

                  <!-- <h4 class="mb-0"><b>Lot No</b></h4><br>
                                    <h4 class="mb-0"><b>Serial No</b></h4><br> -->

                  <h4 class="mb-0"><b>Service Type</b></h4>
                  <br />
                  <h4 class="mb-0"><b>Service Name</b></h4>
                  <br />
                  <h4 class="mb-0"><b>CSR Name</b></h4>
                  <br />
                  <h4 class="mb-0"><b>CSR PhoneNumber</b></h4>
                  <br />
                  <h4 class="mb-0"><b>Product Total Units</b></h4>
                  <br />
                  <h4 class="mb-0"><b>Product Total Price</b></h4>
                  <br />
                </div>
                <div class="col-lg-7">
                  <h4 class="mb-0 pt-2 text-left">: {{ trackId }}</h4>
                  <br />
                  <h4 class="mb-0">: {{ defaultOrderId }}</h4>
                  <br />
                  <h4 class="mb-0">: {{ patientName }}</h4>
                  <br />
                  <h4 class="mb-0">: {{ patientId }}</h4>
                  <br />
                  <h4 class="mb-0">: {{ ticketId }}</h4>
                  <br />
                  <h4 class="mb-0">: {{ ticketCreatedDate }}</h4>
                  <br />

                  <h4 class="mb-0">: {{ Courier }}</h4>
                  <br />
                  <h4 class="mb-0">: {{ serviceName }}</h4>
                  <br />
                  <h4 class="mb-0">: {{ CSRName }}</h4>
                  <br />
                  <h4 class="mb-0">: {{ csrPhoneNumber }}</h4>
                  <br />
                  <h4 class="mb-0">: {{ Qty }}</h4>
                  <br />
                  <h4 class="mb-0">: {{ "$" + totalPrice }}</h4>
                  <br />

                  <!-- <h4 class="mb-0">: sdfsdf</h4><br>
                                    <h4 class="mb-0">: sdfsdf</h4> <br> -->
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-8">
          <div *ngFor="let item of status; let i = index">
            <div class="row multi-steps-wrap">
              <ul class="list-unstyled multi-steps">
                <li
                  class="job-status job-created"
                  [ngClass]="{
                    'job-inprogress is-active negative':
                      item.status == 'processing'
                  }"
                >
                  processing
                </li>
                <li
                  class="job-status"
                  [ngClass]="{
                    'job-inprogress is-active negative': item.status == 'packed'
                  }"
                >
                  Packed
                </li>
                <li
                  class="job-status job-completed"
                  [ngClass]="{
                    'job-inprogress is-active negative':
                      item.status == 'shipped'
                  }"
                >
                  Shipped
                </li>
                <li
                  class="job-status job-closed"
                  [ngClass]="{
                    'job-inprogress is-active negative':
                      item.status == 'delivered'
                  }"
                >
                  Delivered
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
