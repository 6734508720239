<mat-dialog-content class="mat-typography">
  <div class="modal-header p-0">
    <h1 class="modal-title customThemeClass text-center" >New Verification</h1>
    <button class="text-right close" aria-label="Close" mat-dialog-close>
      <b> &times; </b>
    </button>
  </div>
</mat-dialog-content>
<br>
  <div class="row">
    <div class="col-4">
      <div class="card card-body patient">
        <form [formGroup]="PatientForm">
          <div class="col-12"><b>Patient Details:</b></div>
           <!-- Patient Search -->
           <mat-form-field class="col-12" id="newPatient">
            <mat-label>Patient </mat-label>
            <mat-select (selectionChange)="loadPatientForm()" formControlName="drpSearchPatient">
              <mat-option>
                <ngx-mat-select-search placeholderLabel="Search" noEntriesFoundLabel="No Matches found"
                  formControlName="txtPatientFilter"></ngx-mat-select-search>
              </mat-option>
              <mat-option *ngFor="let patients of filteredPatients | async" [value]="patients.guid">
                {{ patients.idAndNameAndDOB }}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field class="col-6">
           <mat-label> Chart No </mat-label>
           <input autocomplete="new-txtChartNo" matInput maxlength="40" formControlName="txtChartNo" type="text" readonly/>
           <mat-error *ngIf="PatientForm?.get('txtChartNo')?.touched &&
               PatientForm?.get('txtChartNo')?.errors?.required ">
               Chart No is a required field!
           </mat-error>
          </mat-form-field>
          <mat-form-field class="col-6" >
            <mat-label> Patient Name</mat-label>
            <input readonly autocomplete="new-txtPatientName" matInput maxlength="40" formControlName="txtPatientName" type="text" readonly/>
            <mat-error *ngIf="PatientForm?.get('txtPatientName')?.touched &&
                PatientForm?.get('txtPatientName')?.errors?.required ">
                Patient Name is a required field!
            </mat-error>
          </mat-form-field>
          <!-- <mat-form-field class="col-6">
              <mat-label> Address</mat-label>
              <input autocomplete="new-txtAddress" matInput maxlength="40" formControlName="txtAddress" type="text" />
              <mat-error *ngIf="PatientForm?.get('txtAddress')?.touched &&
                  PatientForm?.get('txtAddress')?.errors?.required ">
                Address is a required field!
              </mat-error>
          </mat-form-field> -->
            <!-- Birth Date -->
            <!-- <mat-form-field class="col-9" >
              <mat-label>
                Birth Date </mat-label>
              <input readonly autocomplete="new-txtDob" [max]="BirthdateMaxDate" formControlName="txtDob" readonly
                matInput maxlength="40" type="text" placeholder="(MM/DD/YYYY)"
                [matDatepicker]="txtBirthdate" [min]="BirthdateMinDate"
               disabled=true />
              <mat-datepicker-toggle matSuffix [for]="txtBirthdate">
              </mat-datepicker-toggle>
              <mat-datepicker #txtBirthdate> </mat-datepicker>
              <mat-error *ngIf="
              PatientForm?.get('txtDob')?.touched &&
              PatientForm?.get('txtDob')?.errors &&
                !(
                  BirthdateMinDate <=
                  PatientForm?.get('txtDob')?.value
                )
              ">
                Enter Valid date
              </mat-error>
              <mat-error *ngIf="
              PatientForm?.get('txtDob')?.touched &&
              PatientForm?.get('txtDob')?.errors?.required
              ">
                Birth Date is a required field!
              </mat-error>
              <mat-error *ngIf="
                BirthdateMinDate <=
                PatientForm?.get('txtDob')?.value &&
                !(
                  PatientForm?.get('txtDob')?.touched &&
                  PatientForm?.get('txtDob')?.errors
                    ?.required
                )
              ">
                Enter Past Dates Only!
              </mat-error>
            </mat-form-field> -->
          <mat-form-field class="col-12">
            <mat-label>Notes</mat-label>
            <textarea
              formControlName="txtComment"
              type="text"
              rows="3"
              maxlength="5000"
              autocomplete="off"
              matInput
              [(ngModel)]="notesText"
              (input)="updateCharacterCount(5000)"
            ></textarea>
            <mat-error
              class="spanAlignment"
              *ngIf="
                PatientForm?.get('txtComment')?.touched &&
                PatientForm?.get('txtComment')?.errors?.required
              "
              >Notes is Required field</mat-error
            >
          </mat-form-field>
          <div class="char-count">
            {{ notesText?.length }} / 5000
          </div>
         </form>
      </div>
    </div>
    <div class="col-8">
      <div class="card card-body">
        <form [formGroup]="PlanForm">
          <div class="row">
          <div class="col-6"><b>Plan Details:</b></div>
          <div class="col-6" align="end" style="margin-top: 0%; ">
            <button  mat-button class="mr-2  mt-3 buttonColor font-size-10" (click)="EditPolicy(data)">
              Add Policy
            </button>
          </div>
        </div>
          <mat-form-field class="col-3">
            <mat-label>Plan <span class="asterisk">*</span></mat-label>
            <mat-select (selectionChange)="loadPlanForm()" formControlName="txtPlan" >
              <mat-option *ngFor="let payor of payorLevel" [value]="payor.payorLevelName">
                {{ payor.payorLevelName }}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="
            PlanForm?.get('txtPlan')?.touched &&
            PlanForm?.get('txtPlan')?.errors?.required
              ">
              Payor Level is a required field!
            </mat-error>
          </mat-form-field>
           <mat-form-field class="col-9">
            <mat-label> Plan Name <span class="asterisk">*</span></mat-label>
            <input autocomplete="new-txtPlanName" matInput maxlength="100" formControlName="txtPlanName" type="text" readonly/>
            <mat-error *ngIf="PlanForm?.get('txtPlanName')?.touched &&
            PlanForm?.get('txtPlanName')?.errors?.required ">
                Plan Name is a required field!
            </mat-error>
           </mat-form-field>
           <mat-form-field class="col-3">
            <mat-label> Group <span class="asterisk">*</span></mat-label>
            <input autocomplete="new-txtGroup" matInput maxlength="40" formControlName="txtGroup" type="text" [readonly]="!isGroupNoNull"/>
            <mat-error *ngIf="PlanForm?.get('txtGroup')?.touched &&
            PlanForm?.get('txtGroup')?.errors?.required ">
                Group is a required field!
            </mat-error>
           </mat-form-field>
           <mat-form-field class="col-3">
            <mat-label> Policy<span class="asterisk">*</span></mat-label>
            <input autocomplete="new-txtPolicy" matInput maxlength="40" formControlName="txtPolicy" type="text" readonly/>
            <mat-error *ngIf="PlanForm?.get('txtPolicy')?.touched &&
            PlanForm?.get('txtPolicy')?.errors?.required ">
            Policy is a required field!
            </mat-error>
           </mat-form-field>
           <mat-form-field class="col-3">
            <mat-label> Plan Phone</mat-label>
            <input autocomplete="new-txtPlanPhone" formControlName="txtPlanPhone" maxlength="20" prefix="+1-" mask="(000)-000-0000" matInput
              type="text">
            <mat-error *ngIf="
            PlanForm?.get('txtPlanPhone')?.touched &&
            PlanForm?.get('txtPlanPhone')?.errors
                    ">
              Enter Minimum 10 Digits!
            </mat-error>
          </mat-form-field>
           <mat-form-field class="col-3" >
            <mat-label>Effective Date </mat-label>
            <input  autocomplete="new-txtEffective"  formControlName="txtEffective"
              matInput maxlength="40" type="text" placeholder="(MM/DD/YYYY)"
              [matDatepicker]="txtEffective"
              />
            <mat-datepicker-toggle matSuffix [for]="txtEffective">
            </mat-datepicker-toggle>
            <mat-datepicker #txtEffective> </mat-datepicker>
            <mat-error *ngIf="
            PlanForm?.get('txtEffective')?.touched &&
            PlanForm?.get('txtEffective')?.errors?.required
            ">
            Enter valid date
            </mat-error>
          </mat-form-field>
          <mat-form-field class="col-3">
            <mat-label>Type</mat-label>
            <mat-select maxlength="40" formControlName="txtType">
              <mat-option *ngFor="let payor of drpYearType" [value]="payor.shortCodeId">
                {{ payor.yearType }}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="
            PlanForm.get('txtType')?.touched &&
            PlanForm.get('txtType')?.errors?.required
                ">
              Type is a required field!
            </mat-error>
          </mat-form-field>

           <!-- <mat-form-field class="col-3" >
            <mat-label>Fiscal Year Start  </mat-label>
            <input  autocomplete="new-txtstart"  formControlName="txtstart"
              matInput maxlength="40" type="text" placeholder="(MM/DD/YYYY)"
              [matDatepicker]="txtstart"
              />
            <mat-datepicker-toggle matSuffix [for]="txtstart">
            </mat-datepicker-toggle>
            <mat-datepicker #txtstart> </mat-datepicker>
            <mat-error *ngIf="
            PlanForm?.get('txtstart')?.touched &&
            PlanForm?.get('txtstart')?.errors?.required
            ">
            Enter valid date
            </mat-error>
          </mat-form-field>
           <mat-form-field class="col-3" >
            <mat-label>Fiscal Year End  </mat-label>
            <input  autocomplete="new-txtEnd"  formControlName="txtEnd"
              matInput maxlength="40" type="text" placeholder="(MM/DD/YYYY)"
              [matDatepicker]="txtEnd" [min]="PlanForm.get('txtstart').value"
              />
            <mat-datepicker-toggle matSuffix [for]="txtEnd">
            </mat-datepicker-toggle>
            <mat-datepicker #txtEnd> </mat-datepicker>
            <mat-error *ngIf="
            PlanForm?.get('txtEnd')?.touched &&
            PlanForm?.get('txtEnd')?.errors?.invalidDate||
            PlanForm.controls.txtstart.value>PlanForm.controls.txtEnd.value
            ">
            Enter Valid and greater than the start date!
            </mat-error>
          </mat-form-field> -->
              <mat-checkbox formControlName="chkSkipVerification" class="py-1" style="margin-left: 2%;"
                  >Skip Previous Verification</mat-checkbox>
        </form>
      </div>
      <br>
      <div class="row">
        <div class="col-3"></div>
        <div class="col-4" style="text-align: end;">
          <button  [disabled]="data.isOnline||PlanForm.invalid" mat-button class=" buttonColor mb-2 mr-2" (click)="saveVerification(0)">Submit For Verification</button>
        </div>
        <div class="col-2 pl-0"style="text-align: start;">
          <button [disabled]="!data.isOnline||PlanForm.invalid" mat-button class=" buttonColor mb-2 mr-2" (click)="saveVerification(1)">Submit For Online Verification</button>
        </div>
      </div>
    </div>
  </div>

