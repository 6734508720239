<div class="container-fluid">
  <div class="row bg mt-1">
    <div class="col-12">
      <div class="d-flex align-items-center justify-content-between m-0">
        <h2 class="font-weight-bolder mb-0">
          <b class="header-class">Add Practice</b>
        </h2>
        <div class="page-title-box page-title-right">
          <ol class="breadcrumbs m-0">
            <li class="mb-1 mr-3">
              <button
                matTooltip="Back To Practice List"
                mat-button
                class="buttonColor font-size-10"
                (click)="backToPracticeList()"
              >
                <i class="fa fa-backward mr-1"></i> Back
              </button>
            </li>
          </ol>
        </div>
      </div>
    </div>
  </div>
  <!-- Row 1 -->

  <div class="row mt-3">
    <div class="col-lg-5">
      <div class="card elevated-div">
        <div class="card-body">
          <form [formGroup]="practiceForm">
            <div class="card mb-0 card-body">
              <div class="row">
                <div class="col-12 d-flex justify-content-between mt-1">
                  <mat-label
                    ><b class="customThemeClass custom-tab-header"
                      >Practice Info</b
                    ></mat-label
                  >
                </div>
                <!-- Name -->
                <mat-form-field class="col-6 mt-1">
                  <mat-label>Name<span class="asterisk">*</span></mat-label>
                  <input
                    autocomplete="new-txtName"
                    matInput
                    formControlName="txtName"
                    type="text"
                    maxlength="40"
                    oninput="this.value = this.value.replace(/[^a-zA-Z\s]+/g, ''); this.value = this.value.charAt(0).toUpperCase() + this.value.slice(1);"
                  />
                  <mat-error
                    *ngIf="
                      practiceForm?.get('txtName')?.touched &&
                      practiceForm?.get('txtName')?.hasError('required')
                    "
                  >
                    Name is required field!
                  </mat-error>
                  <mat-error
                    *ngIf="
                      practiceForm?.get('txtName')?.touched &&
                      practiceForm?.get('txtName')?.errors?.sAlphabets
                    "
                  >
                    Enter only Alphabets!
                  </mat-error>
                </mat-form-field>

                <!-- Address -->
                <mat-form-field class="col-6 mt-1">
                  <mat-label>Address<span class="asterisk">*</span></mat-label>
                  <input
                    autocomplete="new-txtAddress"
                    matInput
                    formControlName="txtAddress"
                    type="text"
                    maxlength="100"
                  />
                  <mat-error
                    *ngIf="
                      practiceForm?.get('txtAddress')?.touched &&
                      practiceForm?.get('txtAddress')?.hasError('required')
                    "
                  >
                    Address is required field!
                  </mat-error>
                  <mat-error
                    *ngIf="
                      practiceForm?.get('txtAddress')?.touched &&
                      practiceForm?.get('txtAddress')?.errors?.sAlphabets
                    "
                  >
                    Enter only Alphabets!
                  </mat-error>
                </mat-form-field>

                <!--State Drop Down-->
                <mat-form-field class="col-3">
                  <mat-label>State <span class="asterisk">*</span></mat-label>
                  <mat-select
                    formControlName="drpState"
                    (selectionChange)="onChangeCity($event.value, '')"
                    [(value)]="State"
                    #singleSelect
                  >
                    <mat-option>
                      <ngx-mat-select-search
                        placeholderLabel="Search"
                        noEntriesFoundLabel="No Matches found"
                        formControlName="drpStateFilter"
                      >
                      </ngx-mat-select-search>
                    </mat-option>
                    <mat-option
                      *ngFor="let state of filteredStates | async"
                      [value]="state.stateShortName"
                    >
                      {{ state.stateName }}
                    </mat-option>
                  </mat-select>
                  <mat-error
                    *ngIf="
                      isStateTouched &&
                      practiceForm?.get('drpState')?.touched &&
                      practiceForm?.get('drpState')?.hasError('required')
                    "
                  >
                    State is a required field!
                  </mat-error>
                </mat-form-field>

                <!-- City Drop Down -->
                <mat-form-field class="col-3">
                  <mat-label>City <span class="asterisk">*</span></mat-label>
                  <mat-select
                    formControlName="drpCity"
                    [(value)]="City"
                    #citySelect
                  >
                    <mat-option>
                      <ngx-mat-select-search
                        placeholderLabel="Search"
                        (keypress)="onCityKeyPress($event)"
                        [noEntriesFoundLabel]="cityDrpDownMessage()"
                        hideClearSearchButton="true"
                        (focusout)="focusOut()"
                        formControlName="drpCityFilter"
                      >
                      </ngx-mat-select-search>
                    </mat-option>
                    <mat-option
                      *ngFor="let city of filteredCities | async"
                      [value]="city.cityName"
                    >
                      {{ city.cityName }}
                    </mat-option>
                  </mat-select>
                  <mat-error
                    *ngIf="
                      practiceForm?.get('drpCity')?.touched &&
                      practiceForm?.get('drpCity')?.errors?.required
                    "
                  >
                    City is a required field!
                  </mat-error>
                </mat-form-field>

                <!--Country Drop Down-->
                <mat-form-field class="col-3 readonly" [hidden]="hidecountry">
                  <mat-label>Country <span class="asterisk">*</span></mat-label>
                  <mat-select
                    formControlName="drpCountry"
                    (selectionChange)="onChangeState($event.value)"
                    [(value)]="Country"
                  >
                    <mat-option
                      *ngFor="let country of drpCountry"
                      [value]="country.countryShortName"
                    >
                      {{ country.countryName }}
                    </mat-option>
                  </mat-select>
                  <button
                    mat-button
                    (click)="Country = ''; $event.stopPropagation()"
                    *ngIf="Country"
                    matSuffix
                    mat-icon-button
                  ></button>
                  <mat-error
                    *ngIf="
                      practiceForm?.get('drpCountry')?.touched &&
                      practiceForm?.get('drpCountry')?.errors?.required
                    "
                  >
                    Country is a required field!
                  </mat-error>
                </mat-form-field>

                <!-- ZipCode -->
                <mat-form-field class="col-3">
                  <mat-label>ZipCode <span class="asterisk">*</span></mat-label>
                  <input
                    autocomplete="new-txtZipcode"
                    matInput
                    formControlName="txtZipCode"
                    type="text"
                    maxlength="10"
                    class="zip-code-input"
                  />
                  <mat-error
                    *ngIf="
                      practiceForm?.get('txtZipCode')?.touched &&
                      practiceForm?.get('txtZipCode')?.hasError('required')
                    "
                  >
                    ZipCode is a required field!
                  </mat-error>
                </mat-form-field>

                <!--TAT (hours) Drop Down-->
                <mat-form-field class="col-3">
                  <mat-label>TAT (hours)</mat-label>
                  <input
                    autocomplete="new-txttat"
                    matInput
                    formControlName="txttat"
                    type="text"
                    maxlength="2"
                    oninput="this.value = this.value.replace(/[^0-9]+/g, '').replace(/^0+/, '');"
                  />
                  <!-- <mat-error
                                        *ngIf="practiceForm?.get('txttat')?.touched && practiceForm?.get('txttat')?.hasError('required')">
                                        TAT is required field!
                                    </mat-error> -->
                </mat-form-field>

                <!-- Phone No-->
                <mat-form-field class="col-4 mt-1">
                  <mat-label>Phone No </mat-label>
                  <input
                    matInput
                    maxlength="40"
                    formControlName="txtCellNo"
                    type="text"
                    mask="(000)-000-0000"
                    prefix="+1-"
                  />
                  <!-- <mat-error
                                        *ngIf="practiceForm?.get('txtManPhnNo')?.touched && practiceForm?.get('txtManPhnNo')?.hasError('required')">
                                        Phone No is a required field!
                                    </mat-error> -->

                  <mat-error
                    *ngIf="
                      practiceForm?.get('txtCellNo')?.touched &&
                      practiceForm?.get('txtCellNo')?.errors?.required
                    "
                  >
                    Phone Number is a required field!
                  </mat-error>
                  <mat-error
                    *ngIf="
                      practiceForm?.get('txtCellNo')?.touched &&
                      practiceForm?.get('txtCellNo')?.errors?.sAlphabets
                    "
                  >
                    Enter only Numbers!
                  </mat-error>
                </mat-form-field>

                <!-- Fax No-->
                <mat-form-field class="col-3">
                  <mat-label>Fax No </mat-label>
                  <input
                    matInput
                    maxlength="40"
                    formControlName="txtFaxNo"
                    type="text"
                    mask="(000)-000-0000"
                    prefix="+1-"
                  />
                  <mat-error
                    *ngIf="
                      practiceForm?.get('txtFaxNo')?.touched &&
                      practiceForm?.get('txtFaxNo')?.hasError('required')
                    "
                  >
                    Fax No is a required field!
                  </mat-error>
                </mat-form-field>

                <!-- Email -->
                <mat-form-field class="col-5">
                  <mat-label>Email</mat-label>
                  <input
                    autocomplete="new-txtEmail"
                    matInput
                    maxlength="40"
                    formControlName="txtEmail"
                    type="text"
                    pattern="[a-zA-Z0-9._!#$%&'()*+,-./:;<=>?@[\]^_`{|}~]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$"
                    [textMask]="{ mask: emailMask }"
                  />
                  <mat-error
                    *ngIf="
                      practiceForm?.get('txtEmail')?.touched &&
                      practiceForm?.get('txtEmail')?.hasError('required')
                    "
                  >
                    Email is a required field!
                  </mat-error>
                  <mat-error
                    *ngIf="
                      practiceForm?.get('txtEmail')?.touched &&
                      practiceForm?.get('txtEmail')?.errors?.pattern
                    "
                  >
                    This is not a valid Email!
                  </mat-error>
                </mat-form-field>

                <!-- WebSite-->
                <mat-form-field class="col-12">
                  <mat-label>WebSite </mat-label>
                  <input
                    autocomplete="new-txtWebSite"
                    matInput
                    maxlength="50"
                    formControlName="txtWebSite"
                    type="text"
                  />
                </mat-form-field>
              </div>
            </div>

            <div class="card mb-0 mt-3 card-body">
              <div class="row mt-1">
                <div class="col-12 d-flex justify-content-between">
                  <mat-label
                    ><b class="customThemeClass custom-tab-header"
                      >Practice Manager Info</b
                    ></mat-label
                  >
                </div>
                <!-- Manager-->
                <mat-form-field class="col-6 mt-1">
                  <mat-label>Name </mat-label>
                  <input
                    autocomplete="new-txtManager"
                    matInput
                    maxlength="50"
                    formControlName="txtManager"
                    type="text"
                    oninput="this.value = this.value.replace(/[^a-zA-Z\s]+/g, ''); this.value = this.value.charAt(0).toUpperCase() + this.value.slice(1);"
                  />
                  <mat-error
                    *ngIf="
                      practiceForm?.get('txtManager')?.touched &&
                      practiceForm?.get('txtManager')?.errors?.sAlphabets
                    "
                  >
                    Enter only Alphabets!
                  </mat-error>
                </mat-form-field>

                <!--  Email -->
                <mat-form-field class="col-6">
                  <mat-label>Email </mat-label>
                  <input
                    autocomplete="new-txtmanEmail"
                    matInput
                    maxlength="40"
                    formControlName="txtmanEmail"
                    type="text"
                    pattern="[a-zA-Z0-9._!#$%&'()*+,-./:;<=>?@[\]^_`{|}~]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$"
                    [textMask]="{ mask: emailMask }"
                  />

                  <mat-error
                    *ngIf="
                      practiceForm?.get('txtmanEmail')?.touched &&
                      practiceForm?.get('txtmanEmail')?.errors?.pattern
                    "
                  >
                    This is not a valid Email!!!
                  </mat-error>
                </mat-form-field>

                <!-- Phone No-->
                <mat-form-field class="col-6 mt-1">
                  <mat-label>Phone No </mat-label>
                  <input
                    matInput
                    maxlength="40"
                    formControlName="txtManPhnNo"
                    type="text"
                    mask="(000)-000-0000"
                    prefix="+1-"
                  />
                  <mat-error
                    *ngIf="
                      practiceForm?.get('txtManPhnNo')?.touched &&
                      practiceForm?.get('')?.hasError('required')
                    "
                  >
                    Phone No is a required field!
                  </mat-error>
                </mat-form-field>

                <!--Account Manager Drop Down-->
                <mat-form-field class="col-6">
                  <mat-label>Account Manager </mat-label>
                  <mat-select formControlName="drpAccountManger">
                    <mat-option>
                      <ngx-mat-select-search
                        [formControl]="ActManagerTypeControl"
                        placeholderLabel="Search"
                        noEntriesFoundLabel="No Matches found"
                        formControlName="txtActManger"
                      ></ngx-mat-select-search>
                    </mat-option>
                    <mat-option
                      *ngFor="let users of lstFilterUsers"
                      [value]="users?.userId"
                    >
                      {{ users?.userDesignation }}
                    </mat-option>
                  </mat-select>

                  <mat-error
                    class="spanAlignment"
                    *ngIf="
                      isAccountMangerTouched &&
                      practiceForm?.get('drpAccountManger')?.errors?.required
                    "
                  >
                    Account Manager is a required field!
                  </mat-error>
                </mat-form-field>

                <!--Comments Field-->
                <mat-form-field class="col-12 mt-1">
                  <mat-label>Comments</mat-label>
                  <textarea
                    matInput
                    rows="6"
                    autocomplete="off"
                    maxlength="5000"
                    [(ngModel)]="commentsText"
                    (input)="updateCharacterCount(5000)"
                    formControlName="txtComments"
                    type="text"
                  ></textarea>
                  <div class="char-count">
                    {{ commentsText?.length }} / 5000
                  </div>
                  <br />
                </mat-form-field>
              </div>
            </div>

            <div class="col-12 text-center mt-3">
              <button
                mat-button
                class="buttonColor mb-2 mr-2"
                [disabled]="
                  isShowSpinner ||
                  !(!practiceForm?.invalid && practiceForm?.dirty)
                "
                (click)="savePractice()"
              >
                <span>{{ sessionPracticeId ? "Update" : "Save" }}</span>
                <mat-icon *ngIf="isShowSpinner">
                  <mat-spinner
                    class="spinner-border spinner-border-sm"
                    diameter="20"
                  ></mat-spinner>
                </mat-icon>
              </button>
              <button
                mat-button
                class="resetclr mr-2"
                (click)="resetPractice()"
              >
                Reset
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>

    <div class="col-lg-7 pb-2">
      <!-- Tab View -->
      <div class="card elevated-div">
        <ul
          class="nav nav-pills"
          ngbNav
          #nav="ngbNav"
          justify="justified"
          style="border-bottom: 1px solid rgb(201, 201, 201)"
        >
          <!-- Physicians -->
          <li
            ngbNavItem="Physicians"
            [class.selected-tab]="nav.activeId === 'Physicians'"
            class="nav-item"
          >
            <a
              ngbNavLink
              id="tab-1"
              (click)="changeTab('Physicians')"
              class="nav-link"
              ><b class="custom-tab-header mr-3">Physicians</b></a
            >
          </li>

          <!-- Requirements -->
          <li
            ngbNavItem="Requirements"
            [class.selected-tab]="nav.activeId === 'Requirements'"
            class="nav-item"
          >
            <a
              ngbNavLink
              id="tab-2"
              (click)="changeTab('Requirements')"
              class="nav-link"
              ><b class="custom-tab-header mr-3">Requirements</b></a
            >
          </li>

          <!-- More Info -->
          <li
            ngbNavItem="More Info"
            [class.selected-tab]="nav.activeId === 'More Info'"
            class="nav-item"
          >
            <a
              ngbNavLink
              id="tab-3"
              (click)="changeTab('More Info')"
              class="nav-link"
              ><b class="custom-tab-header mr-3">More Info</b></a
            >
          </li>

          <!-- Notes -->
          <li
            ngbNavItem="Notes"
            [class.selected-tab]="nav.activeId === 'Notes'"
            class="nav-item"
          >
            <a
              ngbNavLink
              id="tab-4"
              (click)="changeTab('Notes')"
              class="nav-link"
              ><b class="custom-tab-header mr-3">Notes</b></a
            >
          </li>

          <!-- Fax Number -->
          <li
            ngbNavItem="Fax Number"
            [class.selected-tab]="nav.activeId === 'Fax Number'"
            class="nav-item"
          >
            <a
              ngbNavLink
              id="tab-6"
              (click)="changeTab('Fax Number')"
              class="nav-link"
              ><b class="custom-tab-header mr-3">Fax Number</b></a
            >
          </li>

          <!-- Fax Log -->
          <li
            ngbNavItem="Fax Log"
            [class.selected-tab]="nav.activeId === 'Fax Log'"
            class="nav-item"
          >
            <a
              ngbNavLink
              id="tab-5"
              (click)="changeTab('Fax Log')"
              class="nav-link"
              ><b class="custom-tab-header mr-3">Fax Log</b></a
            >
          </li>
        </ul>

        <!-- Physicians Tab-->
        <div class="card card-body" *ngIf="nav.activeId === 'Physicians'">
          <app-practice-management-physician
            [sessionPracticeId]="sessionPracticeId"
          ></app-practice-management-physician>
        </div>

        <!-- Requirements Tab -->
        <div class="card card-body" *ngIf="nav.activeId === 'Requirements'">
          <app-practice-management-requirements
            [sessionPracticeId]="sessionPracticeId"
          ></app-practice-management-requirements>
        </div>

        <!-- More Info Tab -->
        <div class="card card-body" *ngIf="nav.activeId === 'More Info'">
          <app-practice-management-more-info
            [sessionPracticeId]="sessionPracticeId"
          ></app-practice-management-more-info>
        </div>

        <!-- Notes Tab-->
        <div class="card card-body" *ngIf="nav.activeId === 'Notes'">
          <app-practice-management-notes
            [sessionPracticeId]="sessionPracticeId"
            #childComponentRef
          ></app-practice-management-notes>
        </div>

        <!-- Fax Number Tab -->
        <div class="card card-body" *ngIf="nav.activeId === 'Fax Number'">
          <app-practice-management-faxnumber
            [sessionPracticeId]="sessionPracticeId"
          ></app-practice-management-faxnumber>
        </div>

        <!-- Fax Log Tab -->
        <div class="card card-body" *ngIf="nav.activeId === 'Fax Log'">
          <div class="col-12">
            <div class="card card-body">
              <div class="row bg">
                <!-- <div class="col-12">
                                    <mat-label><b class="customThemeClass custom-tab-header">Fax Log
                                            Info</b></mat-label>
                                </div> -->

                <div class="col-12">
                  <div
                    class="d-flex align-items-center justify-content-between"
                  >
                    <h2 class="font-weight-bolder mb-0">
                      <b class="header-class">Fax Log Info</b>
                    </h2>

                    <div class="page-title-box page-title-right pt-3 pb-0">
                      <form [formGroup]="faxlogForm">
                        <ol class="breadcrumb m-0">
                          <!-- Date Picker -->
                          <mat-form-field class="pr-2 pt-1 mr-3 mt-1">
                            <input
                              placeholder="Month"
                              formControlName="txtDateValue"
                              matInput
                              [(value)]="dateValue"
                              readonly
                              (focus)="openDatePicker()"
                            />
                            <input
                              matInput
                              maxlength="40"
                              formControlName="txtDate"
                              type="text"
                              [hidden]="true"
                              autocomplete="new-txtDateValue"
                              matInput
                              [(value)]="dateValue"
                              [matDatepicker]="txtDateValue"
                              [max]="maxDate"
                            />
                            <mat-datepicker-toggle
                              matSuffix
                              [for]="txtDateValue"
                            ></mat-datepicker-toggle>
                            <mat-datepicker
                              #txtDateValue
                              startView="multi-year"
                              (monthSelected)="
                                chosenMonthHandler($event, txtDateValue)
                              "
                            ></mat-datepicker>
                          </mat-form-field>
                          <li>
                            <!-- <button mat-button class="buttonColor font-size-14 mt-3">
                                                            <i class="fa fa-plus"></i>
                                                            &nbsp; Add
                                                        </button> -->
                          </li>
                        </ol>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
              <!-- Loader Section -->
              <div class="row">
                <div class="col-12" *ngIf="isLoading">
                  <div class="d-flex justify-content-center" *ngIf="isLoading">
                    <img
                      class="img-responsive pb-2 mr-1"
                      src="assets/gifs/Infinity-loader.gif"
                    />
                  </div>
                </div>

                <div class="col-lg-12 mt-3" *ngIf="!isLoading">
                  <div class="table table-responsive">
                    <app-practice-management-faxlog
                      [tableData]="practiceFaxlog"
                      [sessionPracticeId]="sessionPracticeId"
                    ></app-practice-management-faxlog>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />
    </div>
  </div>
</div>
