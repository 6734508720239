<input type="checkbox" id="toggle-button-checkbox" [(ngModel)]='chkValue' (change)="change($event?.target?.checked)">

<label class="toggle-button-switch" for="toggle-button-checkbox">
  <span class="word-break" style="font-size: 10px;" *ngIf="offText">{{chkValue?offText:onText}}</span>

</label>
<div class="toggle-button-text">
  <div class="toggle-button-text-on" style="font-size: 10px;">
    {{onText}}</div>
  <div class="inactive toggle-button-text-off" style="font-size: 10px;">

    {{offText}}</div>
</div>