<div class="container-fluid mt-2">
  <div class="row bg">
    <div class="col-12">
      <div class=" d-flex align-items-center justify-content-between mb-3">
        <h3 class="font-weight-bolder mb-0"><b>Existing Referral Practice</b></h3>
        <div class=" page-title-box page-title-right  pt-2 pb-0">
          <ol class="breadcrumb m-0">
            <li>
              <button matTooltip="Click To Add New Practice" mat-button class="buttonColor font-size-10"
                (click)="newPractice()" routerLink="referral-practice" routerLinkActive="active">
                <i class="fa fa-plus mr-1" ></i>
                New Referral Practice
              </button>
            </li>
            <li class="ml-2 mt-2 breadcrumb-item">Practice</li>
            <li class="mt-2 breadcrumb-item muted">Referral Practice</li>
          </ol>
        </div>
      </div>
    </div>
  </div>
</div>


<div class="col-lg-12" style="margin-top: -10px;">
  <form [formGroup]="referralForm">
    <!-- <div [hidden]="!isShowProgress"
      style="display: flex; justify-content: center; align-items: center; background: transparent; color: lightseagreen;">
      <mat-progress-bar mode="indeterminate" value='66' diameter="45" class="lightseagreen">
      </mat-progress-bar>
    </div> -->
    <div class="table table-responsive">

      <table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8">
        <ng-container matColumnDef="S.No">
          <th class="header" mat-header-cell *matHeaderCellDef>
            <mat-form-field class="filter">
              <input autocomplete="new-Sno" matInput formControlName="Sno" type="text" />
              <mat-placeholder class="center">S.No</mat-placeholder>
            </mat-form-field>
          </th>
          <td mat-cell *matCellDef="let element; let i=index">
            {{ i+1 }}
          </td>
        </ng-container>

        <ng-container matColumnDef="Name">
          <th class="header" mat-header-cell *matHeaderCellDef>
            <mat-form-field class="filter">
              <input autocomplete="new-Name" matInput formControlName="Name" (keypress)="alphaOnly($event)" />
              <mat-placeholder>Practice Name</mat-placeholder>
            </mat-form-field>
          </th>
          <td mat-cell *matCellDef="let element">
            <span matTooltip="{{element?.Name}}" matTooltipClass="custom-tooltip-font"> {{ element?.practiceName
              }}</span>
          </td>
        </ng-container>
        <ng-container matColumnDef="City">
          <th class="header" mat-header-cell *matHeaderCellDef>
            <mat-form-field class="filter">
              <input autocomplete="new-City" matInput formControlName="City" />
              <mat-placeholder>City</mat-placeholder>
            </mat-form-field>
          </th>
          <td mat-cell *matCellDef="let element"> {{ element?.practiceCity }}</td>
        </ng-container>

        <ng-container matColumnDef="State">
          <th class="header" fxFlex="200" mat-header-cell *matHeaderCellDef>
            <mat-form-field class="filter">
              <input autocomplete="new-State" matInput formControlName="State" />
              <mat-placeholder>State</mat-placeholder>
            </mat-form-field>
          </th>
          <td fxFlex="200" mat-cell *matCellDef="let element"> <span class="ellipsis .cut-text">
              {{ element?.practiceState }} </span>
          </td>
        </ng-container>

        <ng-container matColumnDef="ZIP">
          <!-- <th mat-header-cell *matHeaderCellDef> Account Number </th> -->
          <th class="header  w-175" mat-header-cell *matHeaderCellDef>
            <mat-form-field class="filter">
              <input autocomplete="new-ZIP" matInput formControlName="ZIP" (keypress)="numberOnly($event)" />
              <mat-placeholder>ZIP</mat-placeholder>
            </mat-form-field>
          </th>
          <td mat-cell *matCellDef="let element">{{ element?.practiceZipCode }}</td>
        </ng-container>
        <ng-container matColumnDef="PhoneNo">

          <th class="header" mat-header-cell *matHeaderCellDef>
            <mat-form-field class="filter">
              <input autocomplete="new-PhoneNo" matInput formControlName="PhoneNo" (keypress)="numberOnly($event)" />
              <mat-placeholder>Phone No</mat-placeholder>
            </mat-form-field>
          </th>
          <td mat-cell *matCellDef="let element">{{ element?.practicePhone }}</td>
        </ng-container>
        <ng-container matColumnDef="FaxNo">
          <th class="header" mat-header-cell *matHeaderCellDef>
            <mat-form-field class="filter">
              <input autocomplete="new-FaxNo" matInput formControlName="FaxNo" />
              <mat-placeholder>Fax No</mat-placeholder>
            </mat-form-field>
          </th>
          <td mat-cell *matCellDef="let element">{{ element?.practiceFax }}</td>
        </ng-container>

        <ng-container matColumnDef="Manager">
          <th class="header" class="w-125" mat-header-cell *matHeaderCellDef>
            <mat-form-field class="filter">
              <mat-label> Manager </mat-label>
              <input autocomplete="new-Manager" matInput formControlName="Manager" />
            </mat-form-field>
          </th>
          <td mat-cell *matCellDef="let element">{{ element?.practiceManager }}</td>
        </ng-container>


        <ng-container matColumnDef="Status">
          <th class="header" mat-header-cell *matHeaderCellDef>
            <mat-form-field class="filter">
              <input autocomplete="new-Status" matInput formControlName="Status" />
              <mat-placeholder>Status </mat-placeholder>
            </mat-form-field>
          </th>
          <td mat-cell *matCellDef="let element">{{element?.status}}</td>
        </ng-container>
        <ng-container matColumnDef="createdDate">
          <th class="header" mat-header-cell *matHeaderCellDef>
            <mat-form-field class="filter">
              <mat-label> Create Date </mat-label>
              <input autocomplete="new-createdDate" matInput formControlName="createdDate" placeholder="(MM/DD/YYYY)" />
              <mat-error
                *ngIf=" referralForm?.get('createdDate')?.touched && referralForm?.get('createdDate')?.errors?.dateVaidator">
                Enter Valid date
              </mat-error>
            </mat-form-field>
          </th>
          <td mat-cell *matCellDef="let element"> {{element.createdDate | date: 'MM/dd/yyyy':'en_US' }} </td>
        </ng-container>

        <ng-container matColumnDef="options">
          <th class="header" mat-header-cell *matHeaderCellDef>Options</th>
          <td mat-cell *matCellDef="let element" class="text-center">
            <a *ngIf='isShownUpdateIconForPatient' (click)="viewPractice(element?.id)"
              matTooltip="Click To View Practice">
              <i class="fa fa-eye pointer editBtnColor pl-3" ></i>
            </a>
          </td>
        </ng-container>

        <ng-container matColumnDef="noRecords">
          <td class="text-align-center" mat-footer-cell *matFooterCellDef colspan="10">
            <div *ngIf="!isShowProgress">
              {{"No Records Found"}}
            </div>
            <div [hidden]="!isShowProgress">
              <mat-spinner [diameter]="220"></mat-spinner>
            </div>
          </td>
        </ng-container>

        <ng-container matColumnDef="paginator">
          <td mat-footer-cell *matFooterCellDef colspan="10">
            <mat-paginator class="table table-responsive" #MatPaginator [length]="dataSource?.data?.length"
              [pageSize]="10" [pageIndex]="0" showFirstLastButtons [pageSizeOptions]="[5, 10, 25, 100]">
            </mat-paginator>
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"
          [ngClass]="{'make-green': row.LockStatus === 'Locked' && row.UserId === null && strLoginUserName !== 'admin'}">
        </tr>
        <tr mat-footer-row *matFooterRowDef="['noRecords']"
          [ngClass]="{ active: dataSource && dataSource.data && dataSource?.data?.length !== 0 }"></tr>
        <tr mat-footer-row *matFooterRowDef="['paginator']"></tr>
      </table>
    </div>
  </form>
</div>
