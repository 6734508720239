<br />
<div class="card card-body">
  <div class="container-fluid">
    <div class="row bg">
      <!-- <div class="col-12">
      <div class="d-flex align-items-center justify-content-between mb-1">
        <h3 class="font-weight-bolder mb-0">Patient Claims</h3>
        <div class="page-title-box page-title-right">
          <ol class="breadcrumbs m-0">
            <button
              class="mr-2 buttonColor"
              mat-raised-button
              (click)="
                exporter.exportTable('xlsx', {
                  fileName: 'Claims List',
                  sheet: 'ClaimsList',
                  Props: { Author: 'QSecure' }
                })
              "
            >
              Export
            </button>
            <li class="breadcrumb-item mt-2 pl-2">Report Page</li>
            <li class="breadcrumb-item mt-2">Follow Up Report</li>
          </ol>
        </div>
      </div>
    </div> -->
      <div class="col-lg-12">
        <mat-card-content>
          <h1 class="col-12 customThemeClass">
            <b>Claim List</b>
          </h1>

     
          <div class="example-container mat-elevation-z4">
            <table
              mat-table
              [dataSource]="dataSource"
              matTableExporter
              #exporter="matTableExporter"
              matSort
              class="mat-elevation-z8"
            >
              <ng-container matColumnDef="claimNumber">
                <th
                  class="header"
                  mat-header-cell
                  mat-sort-header
                  *matHeaderCellDef
                >
                  Claim Number
                </th>
                <td mat-cell *matCellDef="let element">
                  {{ element?.claimNumber }}
                </td>
              </ng-container>

              <ng-container matColumnDef="saleOrderId">
                <th
                  class="header"
                  mat-header-cell
                  mat-sort-header
                  *matHeaderCellDef
                >
                  SaleOrder Id
                </th>
                <td mat-cell *matCellDef="let element">
                  {{ element?.saleOrderId }}
                </td>
              </ng-container>

              <ng-container matColumnDef="claimStatus">
                <th
                  class="header"
                  mat-header-cell
                  mat-sort-header
                  *matHeaderCellDef
                >
                  Claim Status
                </th>
                <td mat-cell *matCellDef="let element">
                  {{ element?.claimStatus }}
                </td>
              </ng-container>

              <ng-container matColumnDef="waystarClaimStatus">
                <th
                  class="header"
                  mat-header-cell
                  mat-sort-header
                  *matHeaderCellDef
                >
                  WayStar Status
                </th>
                <td mat-cell *matCellDef="let element">
                  {{ element?.waystarClaimStatus }}
                </td>
              </ng-container>

              <ng-container matColumnDef="dateOfService">
                <th
                  class="header"
                  mat-header-cell
                  mat-sort-header
                  *matHeaderCellDef
                >
                  DOS
                </th>
                <td mat-cell *matCellDef="let element">
                  {{ element?.dateOfService | date: "MM/dd/yyyy":"en_US" }}
                </td>
              </ng-container>

              <ng-container matColumnDef="claimProcessMethod">
                <th
                  class="header"
                  mat-header-cell
                  mat-sort-header
                  *matHeaderCellDef
                >
                  Claim Process Method
                </th>
                <td mat-cell *matCellDef="let element">
                  {{ element?.hcpcsCode }}
                </td>
              </ng-container>

              <ng-container matColumnDef="claimProcessedDate">
                <th
                  class="header"
                  mat-header-cell
                  mat-sort-header
                  *matHeaderCellDef
                >
                  Claim Processed Date
                </th>
                <td mat-cell *matCellDef="let element">
                  {{ element?.claimProcessedDate | date: "MM/dd/yyyy":"en_US" }}
                </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="arrDisplayedColumns"></tr>
              <tr
                mat-row
                *matRowDef="let row; columns: arrDisplayedColumns"
              ></tr>
            </table>

            <div
              *ngIf="
                !(
                  dataSource &&
                  dataSource?.data &&
                  dataSource?.data?.length !== 0
                )
              "
              class="text-align-center"
            >
              <div class="message py-3" *ngIf="!isLoading">
                {{ "No Records Found" }}
              </div>
              <div class="py-3" [hidden]="!isLoading">
                <mat-spinner [diameter]="220"></mat-spinner>
              </div>
            </div>
            <mat-paginator
              [pageSizeOptions]="[10, 20, 50, 100]"
              showFirstLastButtons
            ></mat-paginator>
          </div>
        </mat-card-content>
      </div>
    </div>
  </div>
</div>
