<div class="container-fluid main-content">
  <div class="row bg">
    <div class="col-12">
      <div class="d-flex align-items-center justify-content-between mb-3">
        <h3 class="font-weight-bolder mb-0">Claim Status</h3>
        <div class="page-title-box page-title-right">
          <ol class="breadcrumbs m-0 align-items-center">
            <button mat-button class="mr-2 buttonColor" (click)="exportTableData()">
              Export
            </button>
            <li class="breadcrumb-item">Patient</li>
            <li class="breadcrumb-item">Claim List</li>
          </ol>
        </div>
      </div>
    </div>
    <div class="col-6 pl-4 pb-2">
      <form [formGroup]="claimStatusForm">
        <mat-form-field class="col-6">
          <mat-label>Select Status <span class="asterisk">*</span></mat-label>
          <mat-select formControlName="drpStatus" maxlength="40" (selectionChange)="onStatusChange($event)">
            <mat-option *ngFor="let status of drpClaimStatus" [value]="status.claimStatus">
              {{ status.claimStatus }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </form>
    </div>

    <div class="col-12 pb-5">
      <div class="table-responsive">
        <table id="{{ strPageType }}" mat-table class="table table-bordered" multiTemplateDataRows
          [dataSource]="dataSource" matSort>
          <form style="display: flex" [formGroup]="claimSearchForm">


            <ng-container matColumnDef="patientId">
              <th class="header" mat-header-cell *matHeaderCellDef>
                <mat-form-field class="filter">
                  <input autocomplete="new-patientId" matInput formControlName="patientId">
                  <mat-placeholder class="center">Patient Id</mat-placeholder>
                </mat-form-field>
              </th>
              <td mat-cell *matCellDef="let element">
                <a matTooltip="Click to View Patient" class="eyeCursorclass"
                  [routerLink]="'/patientViewEdit/' + element?.patientId" target="_blank">
                  {{ element?.defaultPatientId }}
                </a>
              </td>
            </ng-container>
            <ng-container matColumnDef="patientName">
              <th class="header" mat-header-cell *matHeaderCellDef>
                <mat-form-field class="filter">
                  <input autocomplete="new-patientName" matInput formControlName="patientName">
                  <mat-placeholder class="center">Patient Name
                  </mat-placeholder>
                </mat-form-field>
              </th>
              <td mat-cell *matCellDef="let element">
                {{ element?.patientName }}
              </td>
            </ng-container>
            <ng-container matColumnDef="payerID">
              <th class="header" mat-header-cell *matHeaderCellDef>
                <mat-form-field class="filter">
                  <input autocomplete="new-payerID" matInput formControlName="payerID">
                  <mat-placeholder class="center">Payer Id
                  </mat-placeholder>
                </mat-form-field>
              </th>
              <td mat-cell *matCellDef="let element">{{ element?.payerID }}</td>
            </ng-container>
            <ng-container matColumnDef="payerName">
              <th class="header" mat-header-cell *matHeaderCellDef>
                <mat-form-field class="filter">
                  <input autocomplete="new-payerName" matInput formControlName="payerName">
                  <mat-placeholder class="center"> Payer Name
                  </mat-placeholder>
                </mat-form-field>
              </th>
              <td mat-cell *matCellDef="let element">{{ element?.payerName }}</td>
            </ng-container>
            <ng-container matColumnDef="claimServiceDate">
              <th class="header" mat-header-cell *matHeaderCellDef>
                <mat-form-field class="filter">
                  <mat-label> Claim Service Date
                  </mat-label>
                  <input autocomplete="new-claimServiceDate" matInput formControlName="claimServiceDate" placeholder="(MM/DD/YYYY)" />
                  <mat-error
                    *ngIf=" claimSearchForm?.get('claimServiceDate')?.touched && claimSearchForm?.get('claimServiceDate')?.errors?.dateVaidator">
                    Enter Valid date

                  </mat-error>
                </mat-form-field>
              </th>
              <td mat-cell *matCellDef="let element">
                {{ element?.claimServiceDate }}
              </td>
            </ng-container>
            <ng-container matColumnDef="billingName">
              <th class="header" mat-header-cell *matHeaderCellDef>
                <mat-form-field class="filter">
                  <input autocomplete="new-billingName" matInput formControlName="billingName">
                  <mat-placeholder class="center"> Provider Name
                  </mat-placeholder>
                </mat-form-field>
              </th>
              <td mat-cell *matCellDef="let element">
                {{ element?.billingName }}
              </td>
            </ng-container>

            <ng-container matColumnDef="tradingPartnerClaimNumber">
              <th class="header" mat-header-cell *matHeaderCellDef>


                <mat-form-field class="filter">
                  <input autocomplete="new-claimNumber" matInput formControlName="claimNumber">
                  <mat-placeholder class="center"> Claim Number
                  </mat-placeholder>
                </mat-form-field>
              </th>
              <td mat-cell *matCellDef="let element">{{ element?.claimId }}</td>
            </ng-container>

            <ng-container matColumnDef="saleOrderId">
              <th class="header" mat-header-cell *matHeaderCellDef>
                <mat-form-field class="filter">
                  <input autocomplete="new-saleOrderId" matInput formControlName="saleOrderId">
                  <mat-placeholder class="center"> Sale Order Id

                  </mat-placeholder>
                </mat-form-field>
              </th>
              <td mat-cell *matCellDef="let element">
                <a matTooltip="Click to View Sale Order" class="eyeCursorclass" [routerLink]="
                  '/patient/saleorderCreateEdit/' + element?.saleOrderId
                " target="_blank">
                  {{ element?.defaultSaleOrderId }}
                </a>
              </td>
            </ng-container>

            <ng-container matColumnDef="currentBalance">
              <th class="header" mat-header-cell *matHeaderCellDef>
                <mat-form-field class="filter">
                  <input autocomplete="new-currentBalance" matInput formControlName="currentBalance">
                  <mat-placeholder class="center"> Current Balance
                  </mat-placeholder>
                </mat-form-field>
              </th>
              <td mat-cell *matCellDef="let element">
                {{ "$ " + element?.currentBalance || "$ " + 0 }}
              </td>
            </ng-container>
            <ng-container matColumnDef="itemCode">
              <th class="header" mat-header-cell *matHeaderCellDef>
                <mat-form-field class="filter">
                  <input autocomplete="new-itemCode" matInput formControlName="itemCode">
                  <mat-placeholder class="center"> Item Code
                  </mat-placeholder>
                </mat-form-field>
              </th>
              <td mat-cell *matCellDef="let element">{{ element?.itemNames }}</td>
            </ng-container>

            <ng-container matColumnDef="billedAmount">
              <th class="header" mat-header-cell *matHeaderCellDef>
                <mat-form-field class="filter">
                  <input autocomplete="new-billedAmount" matInput formControlName="billedAmount">
                  <mat-placeholder class="center">
                    Billed Amount
                  </mat-placeholder>
                </mat-form-field>
              </th>
              <td mat-cell *matCellDef="let element">
                {{ "$ " + element?.billedAmount || "$ " + 0 }}
              </td>
            </ng-container>
            <ng-container matColumnDef="billedQty">
              <th class="header" mat-header-cell *matHeaderCellDef>
                <mat-form-field class="filter">
                  <input autocomplete="new-billedQty" matInput formControlName="billedQty">
                  <mat-placeholder class="center">
                    Billed Units
                  </mat-placeholder>
                </mat-form-field>
              </th>
              <td mat-cell *matCellDef="let element">{{ element?.billedQty }}</td>
            </ng-container>
            <ng-container matColumnDef="appClaimStatus">
              <th class="header" mat-header-cell *matHeaderCellDef>
                <mat-form-field class="filter">
                  <input autocomplete="new-appClaimStatus" matInput formControlName="appClaimStatus">
                  <mat-placeholder class="center">
                    Status
                  </mat-placeholder>
                </mat-form-field>
              </th>
              <td mat-cell *matCellDef="let element">
                {{ element?.appClaimStatus }}
              </td>
            </ng-container>
            <ng-container matColumnDef="zirmedClaimStatus">
              <th class="header" mat-header-cell *matHeaderCellDef>

                <mat-form-field class="filter">
                  <input autocomplete="new-zirmedClaimStatus" matInput formControlName="zirmedClaimStatus">
                  <mat-placeholder class="center">
                    zermid Status
                  </mat-placeholder>
                </mat-form-field>
              </th>
              <td mat-cell *matCellDef="let element">
                {{ element?.waystarClaimStatus }}
              </td>
            </ng-container>
            <ng-container matColumnDef="remitClaimStatus">
              <th class="header" mat-header-cell *matHeaderCellDef>
                <mat-form-field class="filter">
                  <input autocomplete="new-remitClaimStatus" matInput formControlName="remitClaimStatus">
                  <mat-placeholder class="center">
                    Posting Count
                  </mat-placeholder>
                </mat-form-field>
              </th>
              <td mat-cell *matCellDef="let element">
                <!-- <span matTooltip="Imported Count :  Total Count"
                >{{ element?.importedEOBCount }} /
                {{ element?.totalEOBCount }}</span> -->
                <span>{{ element?.importedEOBCount }} </span>
              </td>
            </ng-container>
            <ng-container matColumnDef="claimProcessedDate">
              <th class="header" mat-header-cell *matHeaderCellDef>

                <mat-form-field class="filter">
                  <mat-label> Processed Date</mat-label>
                  <input autocomplete="new-claimProcessedDate" matInput formControlName="claimProcessedDate" placeholder="(MM/DD/YYYY)" />
                  <mat-error
                    *ngIf=" claimSearchForm?.get('claimProcessedDate')?.touched && claimSearchForm?.get('claimProcessedDate')?.errors?.dateVaidator">
                    Enter Valid date

                  </mat-error>
                </mat-form-field>
              </th>
              <td mat-cell *matCellDef="let element">
                {{ element?.claimProcessedDate }}
              </td>
            </ng-container>
            <ng-container matColumnDef="status">
              <th class="header" mat-header-cell *matHeaderCellDef>Options</th>
              <td mat-cell *matCellDef="let element" [attr.colspan]="arrDisplayedColumns.length">
                <!-- <ng-container *ngIf="!element?.isVoided"> -->
                  <a *ngIf="!element?.isVoided" class="editBtnColor eyeCursorclass">
                    <i matTooltip="Click to Check Status" target="_blank" (click)="openStatusEditModel(element)"
                      class="fa fa-pencil ml-2"></i>
                  </a>
                  <a *ngIf="element?.id" class="eyeCursorclass customThemeClass ml-2">
                    <i [routerLink]="'/AddNewPosting/' + element?.id"
                      [queryParams]="{ id: claimStatusForm?.value?.drpStatus }" target="_blank" class="fa fa-eye"
                      matTooltip="Click To View Manual Posting List">
                    </i>
                  </a>

                  <a class="editBtnColor ml-2">
                    <i matTooltip="Click to Set as Void Order" class="fa fa-pencil"
                      (click)="setOrderStatusVoided(element?.id)"></i>
                  </a>
                <!-- </ng-container> -->

                <ng-container *ngIf="element?.isVoided">{{"Void Claim"}}</ng-container>


              </td>
            </ng-container>
            <ng-container matColumnDef="noRecords">
              <td class="text-align-center" mat-footer-cell *matFooterCellDef colspan="17">
                <div *ngIf="!isLoading">
                  {{"No Records Found"}}
                </div>
                <div [hidden]="!isLoading">
                  <mat-spinner [diameter]="220"></mat-spinner>
                </div>
              </td>
            </ng-container>
            <ng-container matColumnDef="paginator">
              <td mat-footer-cell *matFooterCellDef colspan="17">
                <mat-paginator class="table table-responsive mat-table-class" #MatPaginator
                  [length]="dataSource?.data?.length" [pageSize]="10" [pageIndex]="0" showFirstLastButtons
                  [pageSizeOptions]="[5, 10, 25, 100]">
                </mat-paginator>
              </td>
            </ng-container>

              <tr mat-footer-row *matFooterRowDef="['noRecords']" [ngClass]="{
              active:
                dataSource && dataSource?.data && dataSource?.data?.length !== 0
            }"></tr>
            <tr mat-header-row *matHeaderRowDef="arrDisplayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: arrDisplayedColumns"></tr>
            <!-- <tr mat-row *matRowDef="let element; columns: ['expandedErrorDetail']"
              [class.example-expanded-row]="expandedElement === element"></tr> -->
            <tr mat-footer-row *matFooterRowDef="['paginator']"></tr>
          </form>
        </table>

      </div>
    </div>
  </div>
</div>
