import type {
  CreateUpdateResupplyProductDetailsDTO,
  ResuplyProductDetailSearchDTO,
  ResupplyProductDetailsDTO,
} from './dto/models';
import { RestService } from '@abp/ng.core';
import type {
  PagedAndSortedResultRequestDto,
  PagedResultDto,
} from '@abp/ng.core';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ResupplyProductDetailsService {
  apiName = 'ItemManagement';

  create = (input: CreateUpdateResupplyProductDetailsDTO) =>
    this.restService.request<any, ResupplyProductDetailsDTO>(
      {
        method: 'POST',
        url: '/api/ItemApp/resupply-product-details',
        body: input,
      },
      { apiName: this.apiName }
    );

  delete = (id: string) =>
    this.restService.request<any, void>(
      {
        method: 'DELETE',
        url: `/api/ItemApp/resupply-product-details/${id}`,
      },
      { apiName: this.apiName }
    );

  get = (id: string) =>
    this.restService.request<any, ResupplyProductDetailsDTO>(
      {
        method: 'GET',
        url: `/api/ItemApp/resupply-product-details/${id}`,
      },
      { apiName: this.apiName }
    );

  getList = (input: PagedAndSortedResultRequestDto) =>
    this.restService.request<any, PagedResultDto<ResupplyProductDetailsDTO>>(
      {
        method: 'GET',
        url: '/api/ItemApp/resupply-product-details',
        params: {
          skipCount: input.skipCount,
          maxResultCount: input.maxResultCount,
          sorting: input.sorting,
        },
      },
      { apiName: this.apiName }
    );

  getSearchResupplyByInput = (input: ResuplyProductDetailSearchDTO) =>
    this.restService.request<any, PagedResultDto<ResupplyProductDetailsDTO>>(
      {
        method: 'GET',
        url: '/api/ItemApp/resupply-product-details/search-resupply',
        params: {
          mainproductId: input.mainproductId,
          productCode: input.productCode,
          productDescription: input.productDescription,
          hcpcCode: input.hcpcCode,
          resupplyProductName: input.resupplyProductName,
          minimumQuantity: input.minimumQuantity,
          availableQuantity: input.availableQuantity,
          stockStatus: input.stockStatus,
          status: input.status,
          tenantId: input.tenantId,
          dtFromDate: input.dtFromDate,
          dtToDate: input.dtToDate,
        },
      },
      { apiName: this.apiName }
    );

  update = (id: string, input: CreateUpdateResupplyProductDetailsDTO) =>
    this.restService.request<any, ResupplyProductDetailsDTO>(
      {
        method: 'PUT',
        url: `/api/ItemApp/resupply-product-details/${id}`,
        body: input,
      },
      { apiName: this.apiName }
    );

  getResupplyProductDetails = (resupplyId: string) =>
    this.restService.request<any, ResupplyProductDetailsDTO>(
      {
        method: 'GET',
        url: `/api/ItemApp/resupply-product-details/${resupplyId}`,
      },
      { apiName: this.apiName }
    );

  constructor(private restService: RestService) {}
}
