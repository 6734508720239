<div class="col-12" style="margin-top: -17px; margin-bottom: -30px">
    <div class="d-flex align-items-center justify-content-between m-0">
        <div class=" page-title-box  page-title-right mt-4 mb-0">
            <ol class="breadcrumbs m-0">
            </ol>
        </div>
    </div>
</div>
<br />

<div class="card card-body">
    <mat-accordion [hidden]="true">
        <mat-expansion-panel [expanded]="step">
            <mat-expansion-panel-header>
                <mat-panel-title class="customThemeClass">
                    <ng-container>
                        <b>Add Branch</b>
                    </ng-container>
                </mat-panel-title>
            </mat-expansion-panel-header>
            <form [formGroup]="leaveApprovalForm">
                <div class="row">
                    <div class="col-lg-12">
                        <mat-card-content>
                            <div class="row">


                                <div class="col-9">
                                    <mat-radio-group formControlName="rbtnApprovalStatus">
                                        <br>
                                        <mat-radio-button class="mb-2 mr-2" value="Pending">Pending</mat-radio-button>
                                        &nbsp;&nbsp;
                                        <mat-radio-button class="mb-2 mr-2" value="Approved">Approved</mat-radio-button>
                                        &nbsp;&nbsp;
                                        <mat-radio-button class="mb-2 mr-2" value="Rejected">Rejected</mat-radio-button>
                                    </mat-radio-group>
                                </div>

                                <mat-form-field class="col-4">
                                    <mat-label>Designation </mat-label>
                                    <mat-select maxlength="40" formControlName="txtDesingnation">
                                        <!-- <mat-option *ngFor="let organizations of drpOrganization" [value]="organizations.id">
                                                    {{ organizations.organizationUnitName }}

                                                </mat-option> -->
                                    </mat-select>
                                    <!-- <button mat-button (click)="clearDesigation()"
                                        (click)="designation='';$event.stopPropagation()" *ngIf="designation" matSuffix
                                        mat-icon-button >
                                        <mat-icon>close</mat-icon>
                                    </button> -->
                                    <mat-error
                                        *ngIf=" leaveApprovalForm?.get('txtDesingnation')?.touched && leaveApprovalForm?.get('txtBranchName')?.errors?.required">
                                        Desingation Name is a required field!
                                    </mat-error>
                                </mat-form-field>

                                <mat-form-field class="col-4">
                                    <mat-label>Employee </mat-label>
                                    <mat-select maxlength="40" formControlName="txtEmployee">
                                        <!-- <mat-option *ngFor="let organizations of drpOrganization" [value]="organizations.id">
                                                    {{ organizations.organizationUnitName }}

                                                </mat-option> -->
                                    </mat-select>
                                    <!-- <button mat-button (click)="clearDesigation()"
                                        (click)="designation='';$event.stopPropagation()" *ngIf="designation" matSuffix
                                        mat-icon-button >
                                        <mat-icon>close</mat-icon>
                                    </button> -->
                                    <mat-error
                                        *ngIf=" leaveApprovalForm?.get('txtEmployee')?.touched && leaveApprovalForm?.get('txtBranchName')?.errors?.required">
                                        Desingation Name is a required field!
                                    </mat-error>
                                </mat-form-field>

                                <mat-form-field
                                    class="mat-calendar col-4 ng-tns-c281-12 ng-trigger ng-trigger-fadeInCalendar">
                                    <mat-label>Enter a date range</mat-label>
                                    <mat-date-range-input [rangePicker]="picker">
                                        <input autocomplete="new-txtFromDate" matStartDate formControlName="txtFromDate" placeholder="Start date">
                                        <input autocomplete="new-txtToDate" matEndDate formControlName="txtToDate" placeholder="End date">
                                    </mat-date-range-input>
                                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                    <mat-date-range-picker #picker></mat-date-range-picker>

                                </mat-form-field>
                            </div>
                        </mat-card-content>
                    </div>
                </div>
            </form>
            <div class="row">
                <div class="col-sm-12">
                    <div class="text-lg-center">
                        <button mat-button class="buttonColor mb-2 mr-2">
                            Search
                        </button>
                        <button mat-button class="resetclr mb-2 mr-2">
                            Reset
                        </button>
                    </div>
                </div>
            </div>
            <br />
        </mat-expansion-panel>
    </mat-accordion>

    <h4 class="customThemeClass"><b>Leave Approval Details</b></h4>
    <div class="row">
        <div class="col-12 table-responsive">
            <!-- <div [hidden]="!isLoading"
                style="display: flex; justify-content: center; align-items: center; background: transparent; color: lightseagreen;">
                <mat-progress-bar mode="indeterminate" value='66' diameter="45" class="lightseagreen">
                </mat-progress-bar>
            </div> -->
            <table mat-table class="table ml-4" matSort [dataSource]="dataSource">
                <form style="display: flex" [formGroup]="leaveApprovalSearchForm">
                    <ng-container matColumnDef="sno">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>S.No</th>
                        <td mat-cell *matCellDef="let data; let i = index">
                            {{ i+1 }}
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="Name">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                            <mat-form-field class="filter">
                                <input autocomplete="new-name" matInput formControlName="name" />
                                <mat-placeholder>Name</mat-placeholder>
                            </mat-form-field>
                        </th>
                        <td mat-cell *matCellDef="let data">
                            <ng-container>

                            </ng-container>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="Designation">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                            <mat-form-field class="filter">
                                <input autocomplete="new-designation" matInput formControlName="designation" />
                                <mat-placeholder>Designation</mat-placeholder>
                            </mat-form-field>
                        </th>
                        <td mat-cell *matCellDef="let data">
                            <ng-container>

                            </ng-container>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="LeaveType">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                            <mat-form-field class="filter">
                                <input autocomplete="new-leaveType" matInput formControlName="leaveType" />
                                <mat-placeholder>Leave Type</mat-placeholder>
                            </mat-form-field>
                        </th>
                        <td mat-cell *matCellDef="let data">
                            <ng-container>

                            </ng-container>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="FromDate">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                            <mat-form-field class="filter">
                                <input autocomplete="new-fromDate" matInput formControlName="fromDate" />
                                <mat-placeholder>From Date</mat-placeholder>
                            </mat-form-field>
                        </th>
                        <td mat-cell *matCellDef="let data">
                            <ng-container>

                            </ng-container>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="ToDate">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                            <mat-form-field class="filter">
                                <input autocomplete="new-toDate" matInput formControlName="toDate" />
                                <mat-placeholder>To Date</mat-placeholder>
                            </mat-form-field>
                        </th>
                        <td mat-cell *matCellDef="let data">
                            <ng-container>

                            </ng-container>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="Comments">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                            <mat-form-field class="filter">
                                <input autocomplete="new-comments" matInput formControlName="comments" />
                                <mat-placeholder>Comments</mat-placeholder>
                            </mat-form-field>
                        </th>
                        <td mat-cell *matCellDef="let data">
                            <ng-container>

                            </ng-container>
                        </td>
                    </ng-container>


                    <ng-container matColumnDef="Status">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                            <mat-form-field class="filter">
                                <input autocomplete="new-status" matInput formControlName="status" />
                                <mat-placeholder>Status</mat-placeholder>
                            </mat-form-field>
                        </th>
                        <td mat-cell *matCellDef="let data">
                            <ng-container>

                            </ng-container>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="Options">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Options</th>
                        <td mat-cell *matCellDef="let data">

                        </td>
                    </ng-container>
                    <ng-container matColumnDef="noRecords">
                        <td class="text-align-center" mat-footer-cell *matFooterCellDef colspan="9">
                            <div *ngIf="!isLoading">
                                {{"No Records Found"}}
                            </div>
                            <div [hidden]="!isLoading">
                                <mat-spinner [diameter]="220"></mat-spinner>
                            </div>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="paginator">
                        <td mat-footer-cell *matFooterCellDef colspan="8">
                            <mat-paginator class="table table-responsive mat-table-class" #MatPaginator
                                [length]="dataSource?.data?.length" [pageSize]="10" [pageIndex]="0" showFirstLastButtons
                                [pageSizeOptions]="[5, 10, 25, 100]">
                            </mat-paginator>
                        </td>
                    </ng-container>
                </form>
                <tr mat-footer-row *matFooterRowDef="['noRecords']"
                    [ngClass]="{ active: dataSource && dataSource?.data && dataSource?.data?.length !== 0 }"></tr>
                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
                <tr mat-footer-row *matFooterRowDef="['paginator']"></tr>
            </table>
        </div>
    </div>

</div>
