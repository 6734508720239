import { Component, Inject, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { CardTypeService } from '../../order-proxy/order-management/order-optimization/card-type.service';
import { Observable, Subscription } from 'rxjs';
import {
  CardTypeDTO,
  CreateUpdateCreditCardDetailsDTO,
} from '../../order-proxy/order-management/order-optimization/dto';
import { map, startWith } from 'rxjs/operators';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { defaultGuid } from 'projects/shared/src/app/enums/allenums.enum';
import { CreditCardDetailsService } from '../../order-proxy/order-management/order-optimization/credit-card-details.service';
import { ToastrService } from 'ngx-toastr';
import { CommunicationService } from 'projects/shared/src/app/services/communication.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-credit-card-modal',
  templateUrl: './credit-card-modal.component.html',
  styleUrls: ['./credit-card-modal.component.scss'],
})
export class CreditCardModalComponent implements OnInit {
  creditCardForm: FormGroup;
  isProcessButtonEnabled: boolean = false;
  ltCardTypes: any[] = [];
  public filteredCardTypes: Observable<any[]> | undefined;
  currentYear = new Date().getFullYear();
  maxYear = this.currentYear + 100;
  subscription$: Subscription[] = [];
  mask: any = [
    /[1-9]/,
    /\d/,
    /\d/,
    /\d/,
    ' ',
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    ' ',
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    ' ',
    /\d/,
    /\d/,
    /\d/,
    /\d/,
  ];

  constructor(
    private fb: FormBuilder,
    private creditCardService: CreditCardDetailsService,
    private dialog: MatDialog,
    private communicationService: CommunicationService,
    private dialogRef: MatDialogRef<CreditCardModalComponent>,
    private toasterService: ToastrService,
    @Inject(MAT_DIALOG_DATA)
    public data: { patientId: string; orderId: string; orderData: any } = {
      patientId: defaultGuid,
      orderId: defaultGuid,
      orderData: null,
    }
  ) {}

  ngOnInit() {
    this.initializeForms();
    this.loadCreditCardType();
    this.creditCardForm.patchValue({
      txtOrderNumber: this.data.orderData.orderNumber,
      txtTotalDue: this.data.orderData.totalDue,
      txtCharge: this.data.orderData.charge,
    });
  }
  initializeForms() {
    this.creditCardForm = this.fb.group({
      txtOrderNumber: new FormControl(''),
      txtTotalDue: new FormControl(''),
      txtCharge: new FormControl(''),
      drpCardType: new FormControl('', [Validators.required]),
      txtCardTpe: new FormControl(''),
      txtCardNo: new FormControl('', [
        Validators.minLength(19),
      ]),
      txtExpiryMonth: new FormControl('', [
        Validators.required,
        Validators.pattern('^[0-9]*$'),
        Validators.min(1),
        Validators.max(12),
      ]),
      txtExpiryYear: new FormControl('', [
        Validators.required,
        Validators.pattern('^[0-9]*$'),
        Validators.min(this.currentYear),
        Validators.max(this.maxYear),
      ]),
      txtCVV2: new FormControl('', [
        Validators.required,
        Validators.minLength(3),
        Validators.maxLength(4),
      ]),
      txtStatus: new FormControl(''),
    });
  }
  loadCreditCardType() {
    this.ltCardTypes = cardTypes;
    this.filteredCardTypes = this.creditCardForm
      .get('txtCardTpe')
      .valueChanges.pipe(
        startWith(''),
        map((value) =>
          this.ltCardTypes?.filter((option) =>
            option?.cardType
              ?.toLowerCase()
              ?.includes(value?.toLowerCase() ?? '')
          )
        )
      );
  }

  validateMonth(): boolean {
    return !(Number(this.creditCardForm.value.txtExpiryMonth) > 12);
  }

  processPayment() {
    const creditCardDetails: CreateUpdateCreditCardDetailsDTO = {
      creditCardId: 0,
      orderID: this.data.orderId,
      defaultSaleOrderId: this.data?.orderData?.orderNumber?.toString(),
      totalDue: Number(this.data?.orderData?.totalDue ?? '0'),
      charge: Number(this.data?.orderData?.totalDue ?? '0'),
      cardTypeID: defaultGuid,
      cardType: this.creditCardForm?.value.txtCardType ?? '',
      cardNo: this.creditCardForm.value.txtCardNo,
      expiryMonth: Number(this.creditCardForm.value.txtExpiryMonth ?? '0'),
      expiryYear: Number(this.creditCardForm.value.txtExpiryYear ?? '0'),
      cvv: Number(this.creditCardForm.value.txtCVV2 ?? '0'),
      isActive: true,
      orderNumber: this.data.orderData.orderNumber,
    };
    this.isProcessButtonEnabled = true;
    const creditCard = this.creditCardService
      .create(creditCardDetails)
      .subscribe(
        (response) => {
          this.isProcessButtonEnabled = false;
          this.toasterService.success('Payment Successful!');
          this.dialog.closeAll();
          this.communicationService.triggerLoadOrderDetails({
            patientId: this.data?.patientId ?? defaultGuid,
            orderId: this.data?.orderId ?? defaultGuid,
          });
        },
        (err) => {
          this.isProcessButtonEnabled = false;
          // this.toasterService.error('Something Went Wrong!');
          Swal.fire({
            icon: 'info',
            text: 'Something Went Wrong!',
          });
        }
      );
  }
  /// for enter number only
  numberOnly(event: { which: any; keyCode: any }): boolean {
    const charCode = event?.which ? event?.which : event?.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }
}

interface CardTypeDetail {
  cardType: string;
}
let cardTypes: CardTypeDetail[] = [
  { cardType: 'Amex' },
  { cardType: 'Discover' },
  { cardType: 'MasterCard' },
  { cardType: 'Visa' },
];
