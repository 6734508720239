import { PagedAndSortedResultRequestDto } from '@abp/ng.core';
import { HttpErrorResponse } from '@angular/common/http';
import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Title } from '@angular/platform-browser';
// import { defaultGuid } from 'projects/admin/src/app/document-perviewer/document-perviewer.component';
// import { OrderBillingStatus } from 'projects/shared/src/app/enums/allenums.enum';
import { Subject, Subscription } from 'rxjs';
import Swal from 'sweetalert2';
import { AddItemTabComponent } from '../add-item-tab/add-item-tab.component';
// import { AddItemComponent } from '../add-item/add-item.component';
import { SaleOrderItemService } from '../order-proxy/order-management/order-optimization';
import { SaleOrderItemDTO } from '../order-proxy/order-management/order-optimization/dto';
import { CookieService } from 'ngx-cookie-service';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { itemsList } from 'projects/admin/src/app/admin-dashboard/dashboard-data';
import { DataTableDirective } from 'angular-datatables';
import { defaultGuid } from 'projects/shared/src/app/enums/allenums.enum';

@Component({
  selector: 'app-item-tab',
  templateUrl: './item-tab.component.html',
  styleUrls: ['./item-tab.component.scss'],
})
export class ItemTabComponent implements OnInit, OnDestroy {
  @Input() orderId: string = defaultGuid;
  @Output() orderIdEmitter: EventEmitter<string> = new EventEmitter<string>();
  @Input() isTemplate: boolean = false;
  @Input() patientId: string = defaultGuid;
  addItemForm: FormGroup;
  tblItemForm: FormGroup;
  itemDetailsForm: FormGroup;
  ltItems = itemsList;
  dtTrigger: Subject<any> = new Subject<any>();
  @ViewChild(DataTableDirective, { static: false })
  dtElement: DataTableDirective;
  dtItemTableOptions: any = {
    responsive: true,
    pageLength: 5,
    lengthMenu: [5, 10, 25, 50, 100],
  };
  orderInfo: any = {
    deductible: 50.22,
    copay: 15.2,
    estAllowable: 32.14,
    estCopay: 43.2,
    totalAccessory: 30.23,
    shippingFee: 15.2,
  };
  subscription$: Subscription[] = [];
  isLoading: boolean = false;
  editFormValues: boolean = false;
  orderStatus: boolean = false;
  tblItemReponse: SaleOrderItemDTO[] = [];
  isNewPatient: any;
  totalQty: number = 0;
  totalPrice: number = 0;
  constructor(
    private title: Title,
    private dialog: MatDialog,
    private itemService: SaleOrderItemService,
    private cookieService: CookieService,
    private fb: FormBuilder
  ) {}

  ngOnInit(): void {
    this.isNewPatient = this.cookieService.get('orderStatus');
    if (this.isNewPatient == 'true') {
      this.editFormValues = true;
    } else {
      this.editFormValues = false;
    }
    this.orderId = this.orderId ?? defaultGuid;
    this.patientId !== defaultGuid &&
      this.title.setTitle('Qsecure | New Order');
    this.orderId !== defaultGuid && this.title.setTitle('Qsecure | View Order');
    this.orderId !== defaultGuid && this.getTableData(this.orderId);

    this.ltItems = this.ltItems?.map((item) => ({
      ...item,
      isEditClicked: false,
    }));
    this.initializeForms();
    //Looping for the Form Control Array Initialization
    this.ltItems?.forEach((data) => {
      this.bindTableFormControl(data);
    });
  }
  // On Page Destroy
  ngOnDestroy(): void {
    this.subscription$?.forEach((sub) => {
      sub && sub?.unsubscribe();
    });
    this.dtTrigger.unsubscribe();
  }
  //Form Intialization method
  initializeForms() {
    this.addItemForm = this.fb.group({
      drpItem: ['', Validators.required],
      drpInsurance: ['', Validators.required],
      drpSaleOrRent: ['', Validators.required],
      txtPrice: ['', Validators.required],
      txtOrderingUnits: ['', Validators.required],
      txtDOS: ['', Validators.required],
    });

    this.tblItemForm = this.fb.group({
      itemsList: this.fb.array([]),
    });
    this.itemDetailsForm = this.fb.group({
      txtDeductible: new FormControl(''),
      txtCopay: new FormControl(''),
      txtEstAllowable: new FormControl(''),
      txtEstCopay: new FormControl(''),
      txtTotalAccessory: new FormControl(''),
      txtShippingFee: new FormControl(''),
    });
    //this.addItems();
  }
  //After View Method
  ngAfterViewInit(): void {
    // Call the dtTrigger to rerender again
    this.dtTrigger.next();
  }

  //Item Addition Method
  addItemToTable() {
    let objVal = {
      options: '',
      itemId: this.addItemForm?.value?.drpItem,
      isEditClicked: false,
      name: this.addItemForm?.value?.drpItem,
      insuranceName: this.addItemForm?.value?.drpInsurance,
      type: this.addItemForm?.value?.drpSaleOrRent,
      price: this.addItemForm?.value?.txtPrice,
      orderingUnits: this.addItemForm?.value?.txtOrderingUnits,
      dos: this.addItemForm?.value?.txtDOS,
    };
    this.ltItems.unshift(objVal);
    this.bindTableFormControl(objVal);
    this.reloadDatatable();
  }
  //Bind the Data to the Form Control
  bindTableFormControl(data: any) {
    const fields = this.fb.group({
      itemId: [data.itemId, ''],
      editClicked: [data.isEditClicked, ''],
      drpItem: [data.name, Validators.required],
      drpInsurance: [data.insuranceName, Validators.required],
      drpSaleOrRent: [data.type, Validators.required],
      txtPrice: [data.price, Validators.required],
      txtOrderingUnits: [data.orderingUnits, Validators.required],
      txtDOS: [data.dos, Validators.required],
    });
    this.fieldsArray.push(fields);
  }

  //Form Array Method
  get fieldsArray() {
    return this.tblItemForm.get('itemsList') as FormArray;
  }
  //Edit Table Method which shows the input fields in the table
  editTable(itemId: string) {
    let value = this.ltItems?.find((a) => a.itemId == itemId);
    let index = this.ltItems.findIndex((item) => item.itemId === itemId);
    value.isEditClicked = true;

    this.fieldsArray.setControl(
      index,
      this.fb.group({
        itemId: [value.itemId, ''],
        editClicked: [value.isEditClicked, ''],
        drpItem: [value.itemId, Validators.required],
        drpInsurance: [value.name, Validators.required],
        drpSaleOrRent: [value.type, Validators.required],
        txtPrice: [value.price, Validators.required],
        txtOrderingUnits: [value.orderingUnits, Validators.required],
        txtDOS: [value.dos, Validators.required],
      })
    );
    this.reloadDatatable();
  }

  //Delete the Item from the Table
  deleteItem(itemId: string, index: number) {
    Swal.fire({
      title: 'Are you sure you want to Delete?',
      text: "You won't be able to retrieve this data!",
      icon: 'warning',
      showCancelButton: true,
      allowOutsideClick: false,
      confirmButtonColor: '#34c38f',
      cancelButtonColor: '#f46a6a',
      confirmButtonText: 'Yes, delete it!',
    }).then((result) => {
      if (result.value) {
        this.ltItems = this.ltItems?.filter((a) => a.itemId != itemId);
        this.fieldsArray.removeAt(index);
        this.reloadDatatable();
      }
    });
  }

  //Re Initialize the Datatable
  reloadDatatable() {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.columns.adjust().draw();
      // Destroy the table first
      dtInstance.destroy();
      // Call the dtTrigger to rerender again
      this.dtTrigger.next();
    });
  }
  //Save Item in the Table
  saveItem(itemId: string, index: number) {
    let value = this.ltItems?.find((a) => a.itemId == itemId);
    value.isEditClicked = false;
    const tblForm = this.fieldsArray.at(index) as FormGroup;
    this.fieldsArray.setControl(
      index,
      this.fb.group({
        itemId: [itemId, ''],
        editClicked: [false, ''],
        drpItem: [tblForm?.value?.drpItem, Validators.required],
        drpInsurance: [tblForm?.value?.drpInsurance, Validators.required],
        drpSaleOrRent: [tblForm?.value?.drpSaleOrRent, Validators.required],
        txtPrice: [tblForm?.value?.txtPrice, Validators.required],
        txtOrderingUnits: [
          tblForm?.value?.txtOrderingUnits,
          Validators.required,
        ],
        txtDOS: [tblForm?.value?.txtDOS, Validators.required],
      })
    );
    this.reloadDatatable();
  }

  addItems() {
    this.ltItems.unshift(newItem);
    this.ltItems = this.ltItems?.map((item) => ({
      ...item,
      isEditClicked: false,
    }));
    this.ltItems?.forEach((data) => {
      const fields = this.fb.group({
        itemId: [data.itemId, ''],
        editClicked: [data.isEditClicked, ''],
        drpItem: [data.itemId, Validators.required],
        drpInsurance: [data.name, Validators.required],
        drpSaleOrRent: [data.type, Validators.required],
        txtPrice: [data.price, Validators.required],
        txtOrderingUnits: [data.orderingUnits, Validators.required],
        txtDOS: [data.dos, Validators.required],
      });
      this.fieldsArray.push(fields);
    });
  }

  editForm() {
    this.editFormValues = true;
  }
  getTableData(orderId: string) {
    this.isLoading = true;
    this.tblItemReponse = [];
    const saleOrderGet = this.itemService
      .getItemsListByOrderIdByOrderId(this.orderId)
      .subscribe(
        (response) => {
          // const orderBillstatus: [string, OrderBillingStatus][] = Object.entries(OrderBillingStatus);
          // this.orderStatus = orderBillstatus?.some(s => ((s[1] ?? "") === (response?.orderBilling?.status)))
          this.totalQty = +String(
            response?.reduce((prvsValue, curValue) => {
              return prvsValue + +curValue?.orderingQuantity;
            }, 0) ?? 0
          );
          this.isLoading = false;
          this.tblItemReponse = response?.map((x) => {
            let y = {
              totalPrice:
                (+x?.charge ?? 0 ?? 0) * (+x?.orderingQuantity ?? 0 ?? 0),
              ...x,
            };
            return y;
          });
          this.totalPrice =
            +String(
              this.tblItemReponse?.reduce((prvsValue, curValue) => {
                return prvsValue + +(curValue?.['totalPrice'] ?? 0);
              }, 0) ?? 0
            ) ?? 0;
        },
        (err) => {
          this.isLoading = false;
          const data: HttpErrorResponse = err;
          Swal.fire({
            icon: 'info',
            text: data?.error?.error?.message,
          });
        }
      );
    this.subscription$.push(saleOrderGet);
  }
  //! open Item Modal Save/Edit
  openItemModal(itemId: string = defaultGuid, isExchange: boolean = false) {
    const dialogRef = this.dialog.open(AddItemTabComponent, {
      disableClose: true,
      data: {
        patientId: this.patientId,
        orderId: this.orderId ?? defaultGuid,
        itemId: itemId,
        isTemplate: this.isTemplate,
        isExchange: isExchange,
      },
    });
    const closeModal = dialogRef.afterClosed().subscribe(
      () => {
        this.orderId !== defaultGuid && this.getTableData(this.orderId);
        this.orderIdEmitter.next(this.orderId);
      },
      (err) => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      }
    );
    this.subscription$.push(closeModal);
  }

  // //! delete Item
  // deleteItem(itemId: string) {
  //   Swal.fire({
  //     title: 'Are you sure you want to Delete?',
  //     text: "You won't be able to retrieve this data!",
  //     icon: 'warning',
  //     showCancelButton: true,
  //     allowOutsideClick: false,
  //     confirmButtonColor: '#34c38f',
  //     cancelButtonColor: '#f46a6a',
  //     confirmButtonText: 'Yes, delete it!',
  //   }).then((result) => {
  //     if (result.value) {
  //       const deleteItem = this.itemService.delete(itemId).subscribe(
  //         (response) => {
  //           this.orderId !== defaultGuid && this.getTableData(this.orderId);
  //           this.orderIdEmitter.next(this.orderId);
  //         },
  //         (err) => {
  //           const data: HttpErrorResponse = err;
  //           Swal.fire({
  //             icon: 'info',
  //             text: data?.error?.error?.message,
  //           });
  //         }
  //       );
  //       this.subscription$.push(deleteItem);
  //     }
  //   });
  // }
}
const newItem = {
  options: '',
  itemId: '',
  isEditClicked: true,
  name: '',
  insuranceName: '',
  type: '',
  price: 0,
  orderingUnits: 0,
  dos: '',
};
