<!-- <mat-dialog-content class="mat-typography"> -->
    <div class="col-12">

        <div class="row align-items-center">
            <div class="col-lg-9">
              <h4 class="customThemeClass">
                <b>Inventory Transfer</b><br>
                <label style="color: black;"><b>

                 </b></label>
              </h4>
            </div>

            <div class="col-lg-3" style="text-align-last: right">
              <mat-icon mat-dialog-close>close</mat-icon>
            </div>
          </div>
          <div class="col-12">
            <form [formGroup]="transferForm">
                <div class="row">
                     <!-- CSR Dropdown -->
                     <mat-form-field class="col-6">
                        <mat-label>CSR <span class="asterisk"
                          >*</span
                        ></mat-label>
                        <mat-select
                          maxlength="40"
                          formControlName="drpCSR"
                          #singleSelect
                          [(ngModel)]="seletedCSRValue"
                        >
                          <mat-option>
                            <ngx-mat-select-search
                              placeholderLabel="Search CSR"
                              [formControl]="CSRFilterControl"
                              noEntriesFoundLabel="No Matches found"
                            ></ngx-mat-select-search>
                          </mat-option>
                          <mat-option
                            *ngFor="let csr of filteredCSRList"
                            [value]="csr.userId"
                          >
                            {{ csr.name }}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>

                      <mat-form-field class="col-6">
                        <mat-label>Shipping Method <span class="asterisk"
                          >*</span
                        ></mat-label>
                        <mat-select
                          maxlength="40"
                          formControlName="drpShippingTypes"
                          #singleSelect
                          [(ngModel)]="seletedShippingValue"
                        >
                        <mat-option>
                            <ngx-mat-select-search placeholderLabel="Search" noEntriesFoundLabel="No Matches found"
                                formControlName="txtShippingType"></ngx-mat-select-search>
                        </mat-option>
                        <mat-option *ngFor="let shippingTypes of filteredShippingTypes | async" [value]="shippingTypes.id">
                            {{shippingTypes.description}}
                        </mat-option>


                        </mat-select>
                      </mat-form-field>
                      <!-- Location Dropdown -->
                      <mat-form-field class="col-6">
                        <mat-label>Source Location <span class="asterisk"
                          >*</span
                        ></mat-label>
                        <mat-select [disabled]="isSourceLocation == true"
                          #selectBranch
                          formControlName="drpBranchType"
                          [(ngModel)]="seletedBranchValue"
                        >
                          <mat-option>
                            <ngx-mat-select-search
                              [formControl]="branchFilterControl"
                              placeholderLabel="Search"
                              noEntriesFoundLabel="No Matches found"
                              formControlName="txtBranch"
                            ></ngx-mat-select-search>
                          </mat-option>
                          <mat-option
                            *ngFor="let branch of filteredBranchTypeList"
                            [value]="branch.id"
                          >
                            {{ branch.organizationUnitName }}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>

                      <mat-form-field class="col-6">
                        <mat-label>Destination Location<span class="asterisk"
                          >*</span
                        ></mat-label>
                        <mat-select
                        #selectBranch
                        formControlName="drpLocation"

                      >
                        <mat-option>
                          <ngx-mat-select-search
                            placeholderLabel="Search"
                            noEntriesFoundLabel="No Matches found"
                            [formControl]="LocationFilterControl"
                          ></ngx-mat-select-search>
                        </mat-option>
                        <mat-option
                          *ngFor="let branch of filteredLocationList"
                          [value]="branch.id"
                        >
                          {{ branch.organizationUnitName }}
                        </mat-option>
                      </mat-select>
                      </mat-form-field>

                      <mat-form-field class="col-6">
                        <mat-label>Status <span class="asterisk"
                          >*</span
                        ></mat-label>
                        <mat-select
                        #singleSelect
                        formControlName="drpStatusType"

                      >
                        <mat-option>
                          <ngx-mat-select-search

                            placeholderLabel="Search"
                            noEntriesFoundLabel="No Matches found"
                            formControlName="txtStatusType" ></ngx-mat-select-search>
                        </mat-option>
                        <mat-option
                          *ngFor="let data of filteredStatusList | async"
                          [value]="data.id"
                        >
                          {{ data.description }}
                        </mat-option>
                    </mat-select>
                      </mat-form-field>

                      <mat-form-field class="col-6">
                        <mat-label> Created </mat-label>
                        <input autocomplete="new-txtDate" formControlName="txtDate" matInput maxlength="40" type="text"
                          placeholder="(MM/DD/YYYY)" [matDatepicker]="txtDate" />
                        <mat-datepicker-toggle matSuffix [for]="txtDate">
                        </mat-datepicker-toggle>
                        <mat-datepicker #txtDate> </mat-datepicker>
                      </mat-form-field>

                      <mat-form-field  class="col-6">
                        <mat-label>Special Instructions</mat-label>
                        <textarea formControlName="txtNote" rows="2" maxlength="5000"
                         matInput></textarea>
                      </mat-form-field>
                      <div class="col-6"></div>
                      <mat-form-field class="col-3">
                        <mat-label>Tracking No</mat-label>
                        <input autocomplete="new-txtName" formControlName="txtTrackingNo" maxlength="40" matInput type="text">

                      </mat-form-field>
                      <mat-form-field class="col-3">
                        <mat-label>Packed By</mat-label>
                        <mat-select
                        maxlength="40"
                        formControlName="drpUserList"
                        #singleSelect
                        [(ngModel)]="defaultPackedBy"
                      >
                      <mat-option>
                          <ngx-mat-select-search placeholderLabel="Search" noEntriesFoundLabel="No Matches found"
                              formControlName="txtUserList"></ngx-mat-select-search>
                      </mat-option>
                      <mat-option *ngFor="let user of filteredUserList | async" [value]="user.id">
                          {{user?.name}}
                      </mat-option>


                      </mat-select>
                      </mat-form-field>

                      <mat-form-field class="col-3">
                        <mat-label>Verified By</mat-label>
                        <mat-select
                        maxlength="40"
                        formControlName="drpVerifyedBy"
                        #singleSelect
                        [(ngModel)]="defaultVerifiedBy"
                      >
                      <mat-option>
                          <ngx-mat-select-search placeholderLabel="Search" noEntriesFoundLabel="No Matches found"
                              formControlName="txtUserList1"></ngx-mat-select-search>
                      </mat-option>
                      <mat-option *ngFor="let user of filteredUserList1 | async" [value]="user.id">
                          {{user?.name}}
                      </mat-option>


                      </mat-select>
                      </mat-form-field>
                      <div class="col-3">
                      <button
                      (click)="runTransfer()"
                        mat-button [disabled]="!(
                          !transferForm?.invalid && transferForm?.dirty  && !inventoryTableForm?.invalid && inventoryTableForm?.dirty && !txtQuantity
                        ) "
                        class="buttonColor mr-2"
                      >Run Transfer</button>
                      <button

                      mat-button
                      class="buttonColor"
                      (click)="saveTranferDetails()"  [disabled]="!(
                        !transferForm?.invalid && transferForm?.dirty
                      ) "
                    >Save</button>
                </div>



                </div>
              </form>
          </div>


          <div class="col-lg-12">

            <div class="card elevated-div">
              <div class="card-body ">
                <div class="d-flex justify-content-center" *ngIf="!loadTable">
                  <!-- <mat-spinner></mat-spinner> -->
                  <img
                    class="img-responsive pb-2 mr-1"
                    src="assets/gifs/Infinity-loader.gif"
                  />
                </div>
                <!-- <form [formGroup]="inventoryTableForm"> -->
                <table
                  datatable
                  *ngIf="loadTable"
                  [dtOptions]="dtInboundOptions"
                  class="table row-border hover w-100"
                >
                  <thead>
                    <tr>
                      <!-- <th>#</th>
                      <th>Options</th> -->
                      <th>Prod Code</th>
                      <th>Description</th>
                      <th>Serial/Lot No</th>
                      <th>Quantity</th>
                      <th>Shipping Comment</th>
                      <!-- <th>Order Status</th>
                      <th>CSR</th> -->
                    </tr>
                  </thead>
                  <tbody>
                    <tr  *ngFor="let data of transferTable"
                    >
                    <td [matTooltip]="'Product Code'">

                      <mat-select  (selectionChange)="onDropdownSelect($event)" [(value)]="data.productId"   placeholder="Product Code" class="dd-text">
                       <mat-option>
                        <ngx-mat-select-search

                        placeholderLabel="Search"
                        noEntriesFoundLabel="No Matches found"
                        [formControl]="searchControl" ></ngx-mat-select-search>
                       </mat-option>

                      <mat-option *ngFor="let option of filteredOptions "  [value]="option.productId">
                        {{ option.productName }}
                      </mat-option>
                    </mat-select>
                    <button mat-button (click)="product=undefined;$event.stopPropagation()" *ngIf="product"
                    matSuffix (click)="clearProduct()" mat-icon-button >
                    <mat-icon>close</mat-icon>
                  </button></td>
                      <td [matTooltip]="data?.productName || ''">
                       {{data.productName}}
                      </td>
                      <!-- <td></td> -->
                      <td [matTooltip]="data?.serialandLotNumebr || '-'">{{data.serialandLotNumebr}}</td>
                      <!-- <td  *ngIf="!editMode || selectserialNo !== 'data'" (click)="selectRowserialNo()">{{data.serialandLotNumebr}}</td> -->
                      <!-- <td ><input class="form-control dd-text" maxlength="15" readonly [(ngModel)]="data.serialandLotNumebr"  /></td> -->
                      <!-- <td></td> -->
                      <!-- <td  *ngIf="!editMode || selectQuantity !== 'data'" (click)="selectRowQuantity()">{{data.quantity}}</td> -->
                      <td class="text-right" [matTooltip]="data?.quantity || '-'"><input class="form-control dd-text text-right" maxlength="8" (keypress)="onKeyPress($event)" [(ngModel)]="data.quantity" />
                        <div
                        class="text-danger dd-text text-error text-center mt-1"
                        *ngIf="data.quantity > data.oldQty"
                      >
                         Quantity will not be greater than {{data.oldQty}}
                      </div></td>
                      <!-- <td></td> -->
                      <!-- <td  *ngIf="!editMode || selectShippingCom !== 'data'" (click)="selectRowShipping()">{{data.shipmentComment}}</td> -->
                      <td [matTooltip]="data?.shipmentComment || ''"><textarea class="form-control dd-text" maxlength="200"  [(ngModel)]="data.shipmentComment" ></textarea> </td>
                      <!-- <td></td>
                      <td></td> -->
                    </tr>
                  </tbody>
                </table>
              <!-- </form> -->
                </div>
              </div>

          </div>

    </div>
<!-- </mat-dialog-content> -->
