import { mapEnumToOptions } from '@abp/ng.core';

export enum orderStatus {
  completed = 'completed',
  itemPending = 'itemPending',
  orderVoided = 'orderVoided',
  orderPending = 'orderPending',
  authorizationPending="authorizationPending",
}

export const orderStatusOptions = mapEnumToOptions(orderStatus);
