import { Component, OnInit } from '@angular/core';
import { Observable, of } from 'rxjs';
import { groupBy, flatMap, map } from 'rxjs/operators';
import { GtmService } from './gtm.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  constructor(private gtmService: GtmService) { }
  ngOnInit(): void {

  }
}
