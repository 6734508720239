import { ListService, PagedAndSortedResultRequestDto } from '@abp/ng.core';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Title } from '@angular/platform-browser';
import { CreateUpdateMasterRegionCitiesDTO, MasterRegionCitiesDTO, MasterRegionStatesDTO, RegionCitiesDTO, RegionCountriesDTO } from 'projects/patient/src/app/dropdown-proxy/dropdown-management/dropdowns/dto/models';
import { MasterCityService, MasterStateService, RegionDropdownService } from 'projects/patient/src/app/dropdown-proxy/dropdown-management/dropdowns/region-dropdown-service';
import { RegionStatesDTO } from 'projects/patient/src/app/dropdown-proxy/rcm/dropdown-management/dropdowns/dto';
import { defaultCountry } from 'projects/shared/src/app/enums/allenums.enum';
import { DateValidator } from 'projects/shared/src/app/validations/date-validator';
import { Observable, Subscription } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { ToastrService } from "ngx-toastr";

@Component({
  selector: 'app-master-city',
  templateUrl: './master-city.component.html',
  styleUrls: ['./master-city.component.scss'],
  providers: [ListService]
})
export class MasterCityComponent implements OnInit , OnDestroy {
  cityId: string = "";
  cityForm: FormGroup;
  isShown: boolean = false;
  dataSource: MatTableDataSource<any>;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  displayedColumns: string[] = [  'Options',"cityName", 'countryName', "countryShortName", "stateName", "stateShortName", "countryPhoneCode",];
  phoneCode: string;
  isShowSpinner: boolean;
  saveButtonHide: boolean;
  tableData: MasterRegionStatesDTO[] = [];
  step: boolean = false;
  Country: string = defaultCountry.US;
  State: string = '';
  City: string = '';
  drpCountry: RegionCountriesDTO[] = [];
  drpStates: RegionStatesDTO[] = [];
  drpCities: RegionCitiesDTO[] = [];
  public filteredCountries: Observable<RegionCountriesDTO[]>;
  public filteredStates: Observable<RegionStatesDTO[]>;
  public filteredCities: Observable<RegionCitiesDTO[]>;
  CountryName = "";
  stateName = "";
  tableCities: MasterRegionCitiesDTO[] = [];
  subscription$: Subscription[] = [];
  constructor(
    private formBuilder: FormBuilder,
    public title: Title,
    public list: ListService,
    private dateValidator: DateValidator,
    private region: RegionDropdownService,
    private mstState: MasterStateService,
    private mstCity: MasterCityService,
    private toastr: ToastrService,

  ) { }

  ngOnInit(): void {
    this.title.setTitle("Qsecure | City");
    this.cityForm = this.formBuilder.group({
      txtCountry: new FormControl("", Validators.required),
      txtCountryPhoneNumber: new FormControl("", Validators.required),
      txtStateFilter: new FormControl(""),
      txtCityFilter: new FormControl(""),
      txtCityName: new FormControl(""),
      txtNewCityName: new FormControl("", Validators.required),
      txtStateName: new FormControl("", Validators.required),
      txtCountryFilter: new FormControl(""),
    })
    this.cityForm.patchValue({ txtCountry: defaultCountry.US })
    this.countryDropdown();
    this.getCities();
  }

  ngOnDestroy(): void {
    this.subscription$?.forEach((sub) => {
      sub && sub?.unsubscribe();
    });
  }


  //! Cities dropdown
  getCities() {
    this.isShowSpinner = true;
    this.tableCities = []
    const cityGetList = this.mstCity.getList(new PagedAndSortedResultRequestDto).subscribe(response => {
      this.isShowSpinner = false;
      this.tableCities = response?.items ?? [];
      this.dataSource = new MatTableDataSource(this.tableCities);
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
    }, err => {
      this.isShowSpinner = false;
      this.dataSource = new MatTableDataSource(this.tableCities);
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    });
    this.subscription$.push(cityGetList);

  }
  // country dropdown
  countryDropdown() {
    this.onChangeState(this.Country);
    this.drpCountry = [];
    const getAllCountry = this.region.getAllCountriesByInput(new PagedAndSortedResultRequestDto).subscribe(response => {
      this.drpCountry = response?.items ?? [];
      this.filteredCountries = this.cityForm?.get("txtCountryFilter").valueChanges
        .pipe(
          startWith(''),
          map(value => this.drpCountry.filter(option => option?.countryName?.toLowerCase()?.includes(value?.toLowerCase() ?? "")))
        );
    }, err => {
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    });
    this.subscription$.push(getAllCountry);
  }

  //  state city//

  onChangeState(value: string | null | undefined): boolean {

    this.cityForm.patchValue({ txtState: null, txtCity: null })
    this.drpStates = [];
    this.drpCities = [];
    this.isShowSpinner = true;
    if (value !== null && value !== undefined) {
      const getStates = this.region.getAllStatesByCountryNameByCountryShortName(value).subscribe(response => {
        this.drpStates = response?.items ?? [];
        this.isShowSpinner = false;
        this.filteredStates = this.cityForm?.get("txtStateFilter").valueChanges
          .pipe(
            startWith(''),
            map(value => this.drpStates.filter(option =>
              option?.stateName?.toLowerCase()?.includes(value?.toLowerCase() ?? "")
            ))
          );

        if (response.items == []) {
          this.cityForm.controls['txtCountryPhoneNumber'].disable();
          this.cityForm.patchValue({ txtCountryPhoneNumber: "" })
          this.phoneCode = "";
        } else {
          this.cityForm.controls['txtCountryPhoneNumber'].enable();
          this.cityForm.patchValue({ txtCountryPhoneNumber: response?.items[0]?.countryPhoneCode ?? "" })
          this.phoneCode = response?.items[0]?.countryPhoneCode ?? "";
        }
      }, err => {


        const data: HttpErrorResponse = err;
        Swal.fire({ icon: 'info', text: data?.error?.error?.message});
      })
      this.subscription$.push(getStates);
    }

    return this.drpStates !== [] && this.drpStates.length !== 0;
  }

  /// on change state pass for city
  async onChangeCity(state: string | null | undefined, country: string) {
    this.cityForm.patchValue({
      txtBillingCity: null
    });
    country = (country == "") ? (this.cityForm.value.txtCountry == (null || undefined) ? "" : (this.cityForm.value.txtCountry)) : country;
    this.drpCities = [];
    let arrCity: Promise<any[]> = null;
    state !== null && state !== undefined && this.region.getAllCitiesByCountryAndStateNameByCountryShortNameAndStateShortName(country, state).subscribe(response => {
      this.filteredCities = this.cityForm?.get("txtCityFilter").valueChanges
        .pipe(
          startWith(''),
          map(value => this.drpCities.filter(option => option?.cityName?.toLowerCase()?.includes(value?.toLowerCase() ?? "")))
        );
      this.drpCities = response?.items ?? [];
      arrCity = new Promise((resolve, reject) => resolve(response?.items ?? []));
    }, err => {
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    });

    return arrCity;
  }


  clearCountry() {
    this.Country = '';
    this.phoneCode = '';
    this.cityForm.patchValue({ drpBillingCountry: "" });
    this.cityForm.controls['txtCountryPhoneNumber'].disable();
  }
  clearState() {
    this.State = '';
    this.cityForm.patchValue({ txtState: "" });
  }
  clearCity() {
    this.City = '';
    this.cityForm.patchValue({ txtCity: "" });
  }

  //! get state Instance
  getcityForm(): FormGroup {
    return this.cityForm
  }

  //! View state By ID
  viewCity(value: string) {
    this.saveButtonHide = true;

    if (value) {
      const msCity = this.mstCity.get(value).subscribe(resposne => {
        this.saveButtonHide = false;
        this.stateName = resposne?.stateShortName;
        this.CountryName = resposne?.countryShortName;
        this.CountryName != null && this.CountryName !== "" && this.CountryName != undefined &&
          this.onChangeState(resposne?.countryShortName) && this.cityForm.patchValue({
            txtCountry: this.CountryName,
          });
        this.stateName != null && this.stateName !== "" && this.stateName != undefined &&
          this.onChangeCity(resposne?.countryShortName, resposne?.stateShortName) && this.cityForm.patchValue({
            txtStateName: this.stateName,
          });
        this.getcityForm().patchValue({
          txtCountry: resposne?.countryShortName,
          txtCountryPhoneNumber: resposne?.cityPhoneCode,
          txtNewCityName: resposne?.cityName,
        })
        this.cityId = resposne?.id ?? "";
        this.step = true;
      }, err => {
        this.saveButtonHide = false;
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      });
      this.subscription$.push(msCity);
    }
  }
  //! Delete state By ID
  deleteCity(value: string) {
    Swal.fire({
      title: 'Are you sure you want to Delete?',
      text: 'You won\'t be able to retrieve this data!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#34c38f',
      cancelButtonColor: '#f46a6a',
      confirmButtonText: 'Yes, delete it!'
    }).then(result => {
      if (result.value) {

        if (value) {
          const deleteMsCity = this.mstCity.delete(value).subscribe(resposne => {
            this.resetCity();
            this.getCities();
          }, err => {
            this.getCities();

            const data: HttpErrorResponse = err;
            Swal.fire({
              icon: 'info',
              text: data?.error?.error?.message,
            });
          });
          this.subscription$.push(deleteMsCity);
        }
      }
    });
  }

  //! Save Or Update city
  saveCity() {

    this.saveButtonHide = true;
    let input: CreateUpdateMasterRegionCitiesDTO = {
      cityName: this.cityForm.value.txtNewCityName,
      cityShortName: this.cityForm.value.txtNewCityName,
      cityPhoneCode: this.cityForm.value.txtCountryPhoneNumber,
      stateShortName: this.cityForm.value.txtStateName,
      countryShortName: this.cityForm.value.txtCountry,
      countryName: this.drpCountry?.filter(e => e?.countryShortName === this.cityForm.value.txtCountry)[0]?.countryName ?? "",
      stateName: this.drpStates?.filter(e => e?.stateShortName === this.cityForm.value.txtStateName)[0]?.stateName ?? "",
    }
    this.cityId = this.cityId ?? "";
    if (this.cityId === "") {
      this.mstCity.create(input).subscribe(response => {
        this.cityId = "";
        // Swal.fire({ title: 'Success', html: 'Saved Successfully', icon: 'success', timer: 3000, timerProgressBar: true });
        this.toastr.success('Saved Successfully','Success')
        this.saveButtonHide = false;
        this.resetCity();
        this.getCities();

      }, err => {
        this.saveButtonHide = false;
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      });
    }
    else {
      this.mstCity.update(this.cityId, input).subscribe(response => {
        this.cityId = "";
        // Swal.fire({ title: 'Success', html: 'Updated Successfully', icon: 'success', timer: 3000, timerProgressBar: true });
        this.toastr.success('Updated Successfully','Success')
        this.resetCity();
        this.getCities();
        this.saveButtonHide = false;
      }, err => {
        this.saveButtonHide = false;
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      });
    }
  }

  //! Reset state Form
  resetCity() {
    this.getcityForm().patchValue({
      txtCountry: "",
      txtCountryPhoneNumber: "",
      txtState: "",
      txtCityName: "",
      txtNewCityName: "",
      txtStateShortCode: "",
      txtStateName: "",
    });
    this.cityId = "";
    this.getcityForm()?.markAsUntouched();
    this.getcityForm()?.updateValueAndValidity();
    this.getcityForm()?.reset();

  }


}
