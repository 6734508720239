import { Component, OnInit, ViewChild } from '@angular/core';
import { MmOrderService } from 'projects/order/src/app/order-proxy/order-management/order-optimization/mm-order.service';
import { WonaceOrderTab } from 'projects/order/src/app/order-proxy/order-management/order-optimization/wonace-order-tab.enum';
import { MyQueueBinStatus } from 'projects/shared/src/app/enums/allenums.enum';
import { Subscription } from 'rxjs';
import { CommonService } from 'projects/shared/src/app/services/common.service';
import {
  InputOrderListDto,
  InputOrderStatusListDto,
  OrderListDto,
  OrderStatusListDto,
  requestDTO,
} from 'projects/order/src/app/order-proxy/order-management/order-optimization/dto';
import { Form, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { PatientPersonalService } from 'projects/patient/src/app/patient-proxy/patient-optimization';
import {
  InputPatientListDto,
  PatientChartIdDTO,
  PatientListDto,
  PayorDetailDTO,
} from 'projects/patient/src/app/patient-proxy/patient-optimization/dto';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { DatePipe } from '@angular/common';
import { DataTableDirective } from 'angular-datatables';
import { MatSelectChange } from '@angular/material/select';

@Component({
  selector: 'app-order-bin-queue',
  templateUrl: './order-bin-queue.component.html',
  styleUrls: ['./order-bin-queue.component.scss'],
})
export class OrderBinQueueComponent implements OnInit {
  @ViewChild(DataTableDirective, { static: false })
  datatableElement: DataTableDirective;
  MyQueueBinStatus = MyQueueBinStatus;
  orderBinQueueFilterForm: FormGroup;
  activeTabBin: MyQueueBinStatus;
  currentPage: any = 1;
  totalPages: any = 1;
  pageOffset: any = 50;
  recordsPerPage:number = 50;
  overallTableCount:number = 0;
  wonaceOrderTab = WonaceOrderTab;
  loadInboundOrderData: boolean = false;
  getInboundOrderTableData: Subscription;
  inboundOrderTableData: any;
  lstInsurance: PayorDetailDTO[] = [];
  lstSelectedInsurance: string[] = [];
  lstOrderStatus: OrderStatusListDto[] = [];
  lstSelectedOrderStatus: string[] = [];
  lstChartNos: PatientChartIdDTO[] = [];
  lstSelectedChartNos: string[] = [];
  lstOrderNos: OrderListDto[] = [];
  lstSelectedOrderNos: string[] = [];
  lstPatients: PatientListDto[] = [];
  lstSelectedPatients: string[] = [];
  orderStatusDD$: Subscription;
  orderNoDD$: Subscription;
  insuranceDD$: Subscription;
  patientDD$: Subscription;
  chartIdDD$: Subscription;
  subscription$: Subscription[] = [];
  paginationInfo:any;
  constructor(
    private mmOrderService: MmOrderService,
    private commonService: CommonService,
    private fb: FormBuilder,
    private patientPersonalService: PatientPersonalService,
    private datepipe: DatePipe
  ) {}

  ngOnInit(): void {
    this.activeTabBin = MyQueueBinStatus.Resupply;
    this.initializeForms();
    this.getOrderTabsSummary();
    this.getOrderFileterList();
    this.loadDropdowns();
  }

  initializeForms() {
    this.orderBinQueueFilterForm = this.fb.group({
      drpOrderNo: new FormControl(''),
      txtOrderNo: new FormControl(''),
      drpChartId: new FormControl(''),
      txtChartId: new FormControl(''),
      drpPatientName: new FormControl(''),
      txtPatientName: new FormControl(''),
      drpStatus: new FormControl(''),
      txtStatus: new FormControl(''),
      txtOrderFromDate: new FormControl(''),
      txtOrderToDate: new FormControl(''),
      txtSuppliesFromDate: new FormControl(''),
      txtSuppliesToDate: new FormControl(''),
      drpInsurance: new FormControl(''),
      txtInsurance: new FormControl(''),
    });
  }

  //#region Filter Dropdowns

  //To Load the filter dropdowns
  loadDropdowns() {
    this.loadPatientDropdown();
    this.loadChartNoDropdown();
    this.loadOrderNoDropdown();
    this.loadOrderStatusDropdown();
    this.loadInsuranceDropdown();
  }

  insuranceSelectAllOption: boolean = false;
  isInsuranceDropdownOpen: boolean = false;
  isInsuranceSelectionChanged: boolean = false;
  onSelectionInsuranceChange(event: MatSelectChange): void {
    this.isInsuranceSelectionChanged = true;
    this.lstSelectedInsurance = event.value;
    if (this.lstSelectedInsurance.includes('selectAll')) {
      this.insuranceSelectAllOption = true;
      if (this.insuranceSelectAllOption) {
        this.lstSelectedInsurance = this.lstInsurance.map(
          (data) => data.payerId
        );
        this.orderBinQueueFilterForm
          .get('drpInsurance')
          .patchValue(this.lstSelectedInsurance);
      }
    } else if (this.lstSelectedInsurance.includes('DeselectAll')) {
      this.lstSelectedInsurance = [];
      this.insuranceSelectAllOption = false;
      this.orderBinQueueFilterForm.get('drpInsurance').patchValue([]);
    } else {
      this.insuranceSelectAllOption =
        this.lstSelectedInsurance.length === this.lstInsurance.length;
    }
    this.lstSelectedInsurance = this.orderBinQueueFilterForm.value.drpInsurance;
  }

  onInsuranceDropdownOpenChange(isOpen: boolean) {
    this.isInsuranceDropdownOpen = isOpen;
    if (this.isInsuranceDropdownOpen) {
      this.orderBinQueueFilterForm.patchValue({
        txtInsurance: '',
      });
      this.currentPage = 1;
      this.loadInsuranceDropdown();
    }
  }
  //To Load the Insurance Dropdown
  loadInsuranceDropdown() {
    if (this.insuranceDD$) {
      this.insuranceDD$.unsubscribe();
    }
    let insuranceSearchString =
      this.orderBinQueueFilterForm?.value?.txtInsurance ?? '';
    this.insuranceDD$ = this.patientPersonalService
      .getPayorListByPayerDetaislAndSPayer(
        insuranceSearchString,
        this.lstSelectedInsurance ?? []
      )
      .subscribe(
        (response) => {
          this.lstInsurance = response ?? [];
        },
        (err) => {
          this.lstInsurance = [];
        }
      );
  }
  handleInsuranceCloseDrpdown(value: any): void {
    if (value && this.isInsuranceSelectionChanged) {
      this.isInsuranceSelectionChanged = false;
      this.getOrderFileterList();
    }
  }

  orderStatusSelectAllOption: boolean = false;
  isOrderStatusDropdownOpen: boolean = false;
  isStatusSelectionChanged: boolean = false;
  onSelectionStatusChange(event: MatSelectChange): void {
    this.isStatusSelectionChanged = true;
    this.lstSelectedOrderStatus = event.value;
    if (this.lstSelectedOrderStatus.includes('selectAll')) {
      this.orderStatusSelectAllOption = true;
      if (this.orderStatusSelectAllOption) {
        this.lstSelectedOrderStatus = this.lstOrderStatus.map(
          (data) => data.orderStatusId
        );
        this.orderBinQueueFilterForm
          .get('drpStatus')
          .patchValue(this.lstSelectedOrderStatus);
      }
    } else if (this.lstSelectedOrderStatus.includes('DeselectAll')) {
      this.lstSelectedOrderStatus = [];
      this.orderStatusSelectAllOption = false;
      this.orderBinQueueFilterForm.get('drpStatus').patchValue([]);
    } else {
      this.orderStatusSelectAllOption =
        this.lstSelectedOrderStatus.length === this.lstOrderStatus.length;
    }
    this.lstSelectedOrderStatus = this.orderBinQueueFilterForm.value.drpStatus;
  }

  onStatusDropdownOpenChange(isOpen: boolean) {
    this.isOrderStatusDropdownOpen = isOpen;
    if (this.isOrderStatusDropdownOpen) {
      this.orderBinQueueFilterForm.patchValue({
        txtStatus: '',
      });
      this.currentPage = 1;
      this.loadOrderStatusDropdown();
    }
  }

  //To Load the Order Status Dropdown
  loadOrderStatusDropdown() {
    if (this.orderStatusDD$) {
      this.orderStatusDD$.unsubscribe();
    }
    let params: InputOrderStatusListDto = {
      sSearch: this.orderBinQueueFilterForm?.value?.txtStatus ?? '',
      gOrderIds: this.lstSelectedOrderStatus ?? [],
    };
    this.orderStatusDD$ = this.mmOrderService
      .getOrderStatusForMyQueueByInput(params)
      .subscribe(
        (response) => {
          this.lstOrderStatus = response ?? [];
        },
        (err) => {
          this.lstOrderStatus = [];
        }
      );
  }

  handleStatusCloseDrpdown(value: any): void {
    if (value && this.isStatusSelectionChanged) {
      this.isStatusSelectionChanged = false;
      this.getOrderFileterList();
    }
  }

  orderNoSelectAllOption: boolean = false;
  isOrderNoDropdownOpen: boolean = false;
  isOrderNoSelectionChanged: boolean = false;
  onSelectionOrderNoChange(event: MatSelectChange): void {
    this.isOrderNoSelectionChanged = true;
    this.lstSelectedOrderNos = event.value;
    if (this.lstSelectedOrderNos.includes('selectAll')) {
      this.orderNoSelectAllOption = true;
      if (this.orderNoSelectAllOption) {
        this.lstSelectedOrderNos = this.lstOrderNos.map((data) => data.orderId);
        this.orderBinQueueFilterForm
          .get('drpOrderNo')
          .patchValue(this.lstSelectedOrderNos);
      }
    } else if (this.lstSelectedOrderNos.includes('DeselectAll')) {
      this.lstSelectedOrderNos = [];
      this.orderNoSelectAllOption = false;
      this.orderBinQueueFilterForm.get('drpOrderNo').patchValue([]);
    } else {
      this.orderNoSelectAllOption =
        this.lstSelectedOrderNos.length === this.lstOrderNos.length;
    }
    this.lstSelectedOrderNos = this.orderBinQueueFilterForm.value.drpOrderNo;
  }

  onOrderNoDropdownOpenChange(isOpen: boolean) {
    this.isOrderNoDropdownOpen = isOpen;
    if (this.isOrderNoDropdownOpen) {
      this.orderBinQueueFilterForm.patchValue({
        txtOrderNo: '',
      });
      this.currentPage = 1;
      this.loadOrderNoDropdown();
    }
  }
  //Load Order No Dropdown
  loadOrderNoDropdown() {
    if (this.orderNoDD$) {
      this.orderNoDD$.unsubscribe();
    }
    let params: InputOrderListDto = {
      iSearch:
        this.orderBinQueueFilterForm?.value?.txtOrderNo == '' ||
        this.orderBinQueueFilterForm?.value?.txtOrderNo == undefined
          ? null
          : Number(this.orderBinQueueFilterForm?.value?.txtOrderNo),
      orderIds: this.lstSelectedOrderNos ?? [],
    };
    this.orderNoDD$ = this.mmOrderService
      .getOrderNumbersForMyQueueByInput(params)
      .subscribe(
        (response) => {
          this.lstOrderNos = response ?? [];
        },
        (err) => {
          this.lstOrderNos = [];
        }
      );
  }
  handleOrderNoCloseDrpdown(value: any): void {
    if (value && this.isOrderNoSelectionChanged) {
      this.isOrderNoSelectionChanged = false;
      this.getOrderFileterList();
    }
  }

  chartNoSelectAllOption: boolean = false;
  isChartNoDropdownOpen: boolean = false;
  isChartNoSelectionChanged: boolean = false;
  onSelectionChartNoChange(event: MatSelectChange): void {
    this.isChartNoSelectionChanged = true;
    this.lstSelectedChartNos = event.value;
    if (this.lstSelectedChartNos.includes('selectAll')) {
      this.chartNoSelectAllOption = true;
      if (this.chartNoSelectAllOption) {
        this.lstSelectedChartNos = this.lstChartNos.map(
          (data) => data.spatientID
        );
        this.orderBinQueueFilterForm
          .get('drpChartId')
          .patchValue(this.lstSelectedChartNos);
      }
    } else if (this.lstSelectedChartNos.includes('DeselectAll')) {
      this.lstSelectedChartNos = [];
      this.chartNoSelectAllOption = false;
      this.orderBinQueueFilterForm.get('drpChartId').patchValue([]);
    } else {
      this.chartNoSelectAllOption =
        this.lstSelectedChartNos.length === this.lstChartNos.length;
    }
    this.lstSelectedChartNos = this.orderBinQueueFilterForm.value.drpChartId;
  }
  onChartNoDropdownOpenChange(isOpen: boolean) {
    this.isChartNoDropdownOpen = isOpen;
    if (this.isChartNoDropdownOpen) {
      this.orderBinQueueFilterForm.patchValue({
        txtChartId: '',
      });
      this.currentPage = 1;
      this.loadChartNoDropdown();
    }
  }
  //Load Chart No Dropdown
  loadChartNoDropdown() {
    if (this.chartIdDD$) {
      this.chartIdDD$.unsubscribe();
    }
    let chartSearchString =
      this.orderBinQueueFilterForm?.value?.txtChartId ?? '';

    this.chartIdDD$ = this.patientPersonalService
      .createChartIdByChartIdPATAndSPatient(
        chartSearchString,
        this.lstSelectedChartNos ?? []
      )
      .subscribe(
        (response) => {
          this.lstChartNos = response ?? [];
        },
        (err) => {
          this.lstChartNos = [];
        }
      );
  }
  handleChartNoCloseDrpdown(value: any): void {
    if (value && this.isChartNoSelectionChanged) {
      this.isChartNoSelectionChanged = false;
      this.getOrderFileterList();
    }
  }

  patientNameSelectAllOption: boolean = false;
  isPatientNameDropdownOpen: boolean = false;
  isPatientNameSelectionChanged: boolean = false;
  onSelectionPatientNameChange(event: MatSelectChange): void {
    this.isPatientNameSelectionChanged = true;
    this.lstSelectedPatients = event.value;
    if (this.lstSelectedPatients.includes('selectAll')) {
      this.patientNameSelectAllOption = true;
      if (this.patientNameSelectAllOption) {
        this.lstSelectedPatients = this.lstPatients.map((data) => data.patId);
        this.orderBinQueueFilterForm
          .get('drpPatientName')
          .patchValue(this.lstSelectedPatients);
      }
    } else if (this.lstSelectedPatients.includes('DeselectAll')) {
      this.lstSelectedPatients = [];
      this.patientNameSelectAllOption = false;
      this.orderBinQueueFilterForm.get('drpPatientName').patchValue([]);
    } else {
      this.patientNameSelectAllOption =
        this.lstSelectedPatients.length === this.lstPatients.length;
    }
    this.lstSelectedPatients =
      this.orderBinQueueFilterForm.value.drpPatientName;
  }

  onPatientNameDropdownOpenChange(isOpen: boolean) {
    this.isPatientNameDropdownOpen = isOpen;
    if (this.isPatientNameDropdownOpen) {
      this.orderBinQueueFilterForm.patchValue({
        txtPatientName: '',
      });
      this.currentPage = 1;
      this.loadPatientDropdown();
    }
  }
  handlePatientNameCloseDrpdown(value: any): void {
    if (value && this.isPatientNameSelectionChanged) {
      this.isPatientNameSelectionChanged = false;
      this.getOrderFileterList();
    }
  }

  //Patient Dropdown
  loadPatientDropdown() {
    if (this.patientDD$) {
      this.patientDD$.unsubscribe();
    }

    let params: InputPatientListDto = {
      gPat: this.lstSelectedPatients ?? [],
      sSearch: this.orderBinQueueFilterForm?.value?.txtPatientName ?? '',
    };
    this.patientDD$ = this.patientPersonalService
      .getPatientListByInput(params)
      .subscribe(
        (response) => {
          this.lstPatients = response ?? [];
        },
        (err) => {
          this.lstPatients = [];
        }
      );
  }
  handleCloseDrpdown(value: any): void {
    if (value) {
      this.getOrderFileterList();
    }
  }
  //#endregion

  getArrowClass(data: any): any {
    return { [data.arrowclass]: this.activeTabBin === data.status };
  }
  changePage(pageNo: number) {
    if (pageNo != this.currentPage && pageNo > 0 && pageNo <= this.totalPages) {
      this.currentPage = pageNo;
      this.getOrderFileterList();
    }
  }
  binActiveTab(data: any) {
    this.activeTabBin = data?.status;
    this.patientNameSelectAllOption = false;
    this.orderStatusSelectAllOption = false;
    this.orderNoSelectAllOption = false;
    this.insuranceSelectAllOption = false;
    this.chartNoSelectAllOption = false;
    this.orderBinQueueFilterForm.reset();
    this.currentPage = 1;
    this.getOrderTabsSummary();
    this.getOrderFileterList();
  }
  onOrderDateChange(): void {
    if (
      this.orderBinQueueFilterForm.value.txtOrderFromDate &&
      this.orderBinQueueFilterForm.value.txtOrderToDate
    ) {
      this.getOrderFileterList();
    }
  }
  onSuppliesDateChange(): void {
    if (
      this.orderBinQueueFilterForm.value.txtSuppliesFromDate &&
      this.orderBinQueueFilterForm.value.txtSuppliesToDate
    ) {
      this.getOrderFileterList();
    }
  }
  //Get Order Tab Summary Counts
  getOrderTabsSummary() {
    const orderSummary = this.mmOrderService.myQueueOrderTabSummary().subscribe(
      (response) => {
        this.menus = [
          {
            status: MyQueueBinStatus.Resupply,
            color: '#faa307',
            count: response?.resupplyCount,
            arrowclass: 'resupply-down',
            wonaceOrderTab: this.wonaceOrderTab.Resupply,
          },
          {
            status: MyQueueBinStatus.SaleOrder,
            color: '#b56576',
            count: response?.orderCount,
            arrowclass: 'saleorder-down',
            wonaceOrderTab: this.wonaceOrderTab.Order,
          },
          {
            status: MyQueueBinStatus.Shipping,
            color: '#c77dff',
            count: response?.shippingCount,
            arrowclass: 'shipping-down',
            wonaceOrderTab: this.wonaceOrderTab.Shipping,
          },
          {
            status: MyQueueBinStatus.Billing,
            color: '#57cc99',
            count: response?.billingCount,
            arrowclass: 'billing-down',
            wonaceOrderTab: this.wonaceOrderTab.Billing,
          },
        ];
      },
      (err) => {
        console.log(err);
      }
    );
    this.subscription$.push(orderSummary);
  }
  // myQueueOrderTabSummary
  //GET Order Table Data
  getOrderFileterList() {
    this.loadInboundOrderData = true;
    if (this.getInboundOrderTableData) {
      this.getInboundOrderTableData.unsubscribe();
    }
    let patientids =
      this.lstSelectedPatients?.length > 0
        ? this.lstSelectedPatients?.concat(this.lstSelectedChartNos ?? [])
        : this.lstSelectedChartNos ?? [];
    let selectedTabShortCode = this.menus.find(
      (a) => a.status == this.activeTabBin
    )?.wonaceOrderTab;
    const orderStartDate =
      this.orderBinQueueFilterForm?.get('txtOrderFromDate').value ?? null;
    const orderEndDate =
      this.orderBinQueueFilterForm.get('txtOrderToDate').value;
    let orderFromDate = this.datepipe.transform(orderStartDate, 'yyyy-MM-dd');
    let orderToDate = this.datepipe.transform(orderEndDate, 'yyyy-MM-dd');
    const startDate = this.orderBinQueueFilterForm.get(
      'txtSuppliesFromDate'
    ).value;
    const endDate = this.orderBinQueueFilterForm.get('txtSuppliesToDate').value;
    let suppliesFromDate = this.datepipe.transform(startDate, 'yyyy-MM-dd');
    let suppliesToDate = this.datepipe.transform(endDate, 'yyyy-MM-dd');
    const orderInputParams: requestDTO = {
      shortCode: selectedTabShortCode,
      faxIds: [],
      patientIds: patientids,
      locationIds: [],
      orderIds: this.lstSelectedOrderNos ?? [],
      priPolicyIds: this.lstSelectedInsurance ?? [],
      secPolicyIds: [],
      terPolicyIds: [],
      dFromSuppliesDate: suppliesFromDate ?? null,
      dToSuppliesDate: suppliesToDate ?? null,
      dFromOrderDate: orderFromDate ?? null,
      dToOrderDate: orderToDate ?? null,
      csrIds: [],
      orderStatus: this.lstSelectedOrderStatus ?? [],
      resupply: '',
      agingdays: '',
      filter: '',
      pageNo: this.currentPage,
      pageOffset: this.pageOffset,
    };

    this.getInboundOrderTableData = this.mmOrderService
      .getOrderTabReportByDto(orderInputParams)
      .subscribe(
        (response) => {
          switch (this.activeTabBin) {
            case MyQueueBinStatus.Resupply:
              this.inboundOrderTableData = response?.resupply ?? [];
              this.inboundOrderTableData = this.inboundOrderTableData.map(
                (inbound) => {
                  return {
                    ...inbound,
                    suppliesDate: this.commonService.getFormattedDateZone(
                      inbound.suppliesDate
                    ),
                    orderDate: this.commonService.getFormattedDateZone(
                      inbound.orderDate
                    ),
                    lastMaskProcessDate:
                      this.commonService.getFormattedDateTimeZone(
                        inbound.lastMaskProcessDate
                      ),
                    creationTime: this.commonService.getFormattedDateTimeZone(
                      inbound.creationTime
                    ),
                    modifiedOn: this.commonService.getFormattedDateTimeZone(
                      inbound.modifiedOn
                    ),
                  };
                }
              );
              this.overallTableCount = response.resupplyCount || 0;
              this.totalPages =
                Math.ceil(response.resupplyCount / this.pageOffset) == 0
                  ? 1
                  : Math.ceil(response.resupplyCount / this.pageOffset);
              break;
            case MyQueueBinStatus.SaleOrder:
              this.inboundOrderTableData = response?.order ?? [];
              this.inboundOrderTableData = this.inboundOrderTableData.map(
                (inbound) => {
                  return {
                    ...inbound,
                    suppliesDate: this.commonService.getFormattedDateZone(
                      inbound.suppliesDate
                    ),
                    orderDate: this.commonService.getFormattedDateZone(
                      inbound.orderDate
                    ),
                    lastMaskProcessDate:
                      this.commonService.getFormattedDateTimeZone(
                        inbound.lastMaskProcessDate
                      ),
                    creationTime: this.commonService.getFormattedDateTimeZone(
                      inbound.creationTime
                    ),
                    modifiedOn: this.commonService.getFormattedDateTimeZone(
                      inbound.modifiedOn
                    ),
                  };
                }
              );
              this.overallTableCount = response.orderCount || 0;
              this.totalPages =
                Math.ceil(response.orderCount / this.pageOffset) == 0
                  ? 1
                  : Math.ceil(response.orderCount / this.pageOffset);
              break;
            case MyQueueBinStatus.Shipping:
              this.inboundOrderTableData = response?.shipping ?? [];
              this.inboundOrderTableData = this.inboundOrderTableData.map(
                (inbound) => {
                  return {
                    ...inbound,
                    suppliesdate: this.commonService.getFormattedDateZone(
                      inbound.suppliesdate
                    ),
                    createdDate: this.commonService.getFormattedDateTimeZone(
                      inbound.createdDate
                    ),
                  };
                }
              );
              this.overallTableCount = response.shippingCount || 0;
              this.totalPages =
                Math.ceil(response.shippingCount / this.pageOffset) == 0
                  ? 1
                  : Math.ceil(response.shippingCount / this.pageOffset);
              break;
            case MyQueueBinStatus.Billing:
              this.inboundOrderTableData = response?.billing ?? [];
              this.inboundOrderTableData = this.inboundOrderTableData.map(
                (inbound) => {
                  return {
                    ...inbound,
                    suppliesDate: this.commonService.getFormattedDateZone(
                      inbound.suppliesDate
                    ),
                    createdDate: this.commonService.getFormattedDateTimeZone(
                      inbound.createdDate
                    ),
                    backOrderSince: this.commonService.getFormattedDateZone(
                      inbound.backOrderSince
                    ),
                  };
                }
              );
              this.overallTableCount = response.billingCount || 0;
              this.totalPages =
                Math.ceil(response.billingCount / this.pageOffset) == 0
                  ? 1
                  : Math.ceil(response.billingCount / this.pageOffset);
              break;
          }

          this.paginationInfo = {
            startRecord: (this.currentPage - 1) * this.recordsPerPage + 1,
            endRecord: Math.min(this.currentPage * this.recordsPerPage, this.overallTableCount),
            overallTableCount : this.overallTableCount
          };

          setTimeout(() => {
            this.orderBinQueueFilterForm.patchValue({
              txtOrderFromDate: orderFromDate,
              txtOrderToDate: orderToDate,
              txtSuppliesFromDate: suppliesFromDate,
              txtSuppliesToDate: suppliesToDate,
            });
          }, 100);

          this.loadInboundOrderData = false;
        },
        (err) => {
          this.loadInboundOrderData = false;
        }
      );
  }
  clearOrderDate() {
    this.orderBinQueueFilterForm.patchValue({
      txtOrderFromDate: '',
      txtOrderToDate: '',
    });
    this.getOrderFileterList();
  }
  clearSuppliesDate() {
    this.orderBinQueueFilterForm.patchValue({
      txtSuppliesFromDate: '',
      txtSuppliesToDate: '',
    });
    this.getOrderFileterList();
  }
  resetForm() {
    this.orderNoSelectAllOption = false;
    this.chartNoSelectAllOption = false;
    this.patientNameSelectAllOption = false;
    this.orderStatusSelectAllOption = false;
    this.insuranceSelectAllOption = false;
    this.orderBinQueueFilterForm.reset();
    this.getOrderFileterList();
  }
  menus = [
    {
      status: MyQueueBinStatus.Resupply,
      color: '#faa307',
      count: 0,
      arrowclass: 'resupply-down',
      wonaceOrderTab: this.wonaceOrderTab.Resupply,
    },
    {
      status: MyQueueBinStatus.SaleOrder,
      color: '#b56576',
      count: 0,
      arrowclass: 'saleorder-down',
      wonaceOrderTab: this.wonaceOrderTab.Order,
    },
    {
      status: MyQueueBinStatus.Shipping,
      color: '#c77dff',
      count: 0,
      arrowclass: 'shipping-down',
      wonaceOrderTab: this.wonaceOrderTab.Shipping,
    },
    {
      status: MyQueueBinStatus.Billing,
      color: '#57cc99',
      count: 0,
      arrowclass: 'billing-down',
      wonaceOrderTab: this.wonaceOrderTab.Billing,
    },
  ];
}
