
import { RestService } from '@abp/ng.core';
import type { PagedAndSortedResultRequestDto, PagedResultDto } from '@abp/ng.core';
import { Injectable } from '@angular/core';

import { CreateUpdateInsuranceDetailDTO, CreateUpdatePatientsDTO, MasterVerificationOutput, PatientAuthorizationReportDTO, PatientDTO, PatientsDTO, PatientsSearchDTO } from './dto/models';
import { UploadedFileStatus } from 'projects/admin/src/app/admin-proxy/platform-app-management/rcm/platform-management/localization/enumeration/uploaded-file-status.enum';

import { IFormFile } from '../microsoft/asp-net-core/http/models';
import { Patient } from 'projects/order/src/app/order-proxy/patient/entity/models';

@Injectable({
  providedIn: 'root',
})
export class PatientsService {
  apiName = 'Default';

  create = (input: CreateUpdatePatientsDTO) =>
    this.restService.request<any, PatientsDTO>({
      method: 'POST',
      url: '/api/app/patients',
      body: input,
    },
       { apiName: this.apiName, skipHandleError: true });

  delete = (id: string) =>
    this.restService.request<any, void>({
      method: 'DELETE',
      url: `/api/app/patients/${id}`,
    },
       { apiName: this.apiName, skipHandleError: true });

  get = (id: string) =>
    this.restService.request<any, PatientsDTO>({
      method: 'GET',
      url: `/api/app/patients/${id}`,
    },
       { apiName: this.apiName, skipHandleError: true });

  getList = (input: PagedAndSortedResultRequestDto) =>
    this.restService.request<any, PagedResultDto<PatientsDTO>>({
      method: 'GET',
      url: '/api/app/patients',
      params: { skipCount: input.skipCount, maxResultCount: input.maxResultCount, sorting: input.sorting },
    },
       { apiName: this.apiName, skipHandleError: true });

  update = (id: string, input: CreateUpdatePatientsDTO) =>
    this.restService.request<any, PatientsDTO>({
      method: 'PUT',
      url: `/api/app/patients/${id}`,
      body: input,
    },
       { apiName: this.apiName, skipHandleError: true });

  approvePatients = (fileId: string, patientsId: string, patientId: string, status: string, reasonForApproveOrRejected: string) =>
    this.restService.request<any, any[]>({
      method: 'POST',
      url: `/api/app/patients/approve-patient`,
      params: {
        fileId, patientsId, patientId, reasonForApproveOrRejected, status
      },

    },
       { apiName: this.apiName, skipHandleError: true });

  searchPatientsByInput = (input: PatientsSearchDTO) =>
    this.restService.request<any, PagedResultDto<PatientsDTO>>({
      method: 'GET',
      url: `/api/app/patients/search-patients`,
      params: { patientName: input.patientName, dateOfBirth: input.dateOfBirth, createdDate: input.createdDate, npi: input.npi, doctorSign: input.doctorSign, complaincePercent: input.complaincePercent },
    },
       { apiName: this.apiName, skipHandleError: true });

       verifyPatientByPatientsIdAndPatientIdAndInput = (patientsId: string, patientId: string, input: CreateUpdateInsuranceDetailDTO) =>
    this.restService.request<any, PatientDTO>({
      method: 'POST',
      url: '/api/app/patients/verify-patient',
      params: { patientsId, patientId },
      body: input,
    },
       { apiName: this.apiName, skipHandleError: true });

  generateChartId = () =>
    this.restService.request<any, string>({
      method: 'GET',
      responseType: 'text',
      url: '/api/app/patients/generate-chart-id',
    },
       { apiName: this.apiName, skipHandleError: true });

  getIdentifiedPatientListByPatientNameAndDateOfBirth = (patientName: string, dateOfBirth: string) =>
    this.restService.request<any, Patient[]>({
      method: 'GET',
      url: '/api/app/patients/get-identified-patient-list',
      params: { patientName, dateOfBirth },
    },
       { apiName: this.apiName, skipHandleError: true });

  getPatientCurrentStatusByPatientId = (patientId: string) =>
    this.restService.request<any, string>({
      method: 'GET',
      responseType: 'text',
      url: `/api/app/patients/get-patient-current-status/${patientId}`,
    },
       { apiName: this.apiName, skipHandleError: true });

  importJsonByFileAndFileId = (file: IFormFile, fileId: string) =>
    this.restService.request<any, string>({
      method: 'POST',
      responseType: 'text',
      url: `/api/app/patients/import-json/${fileId}`,
    },
       { apiName: this.apiName, skipHandleError: true });

  updatePatientBasicDetailsByPatientsIdAndFirstNameAndLastNameAndMiddleNameAndDob = (patientsId: string, firstName: string, lastName: string, middleName: string, dob: string) =>
    this.restService.request<any, PatientsDTO>({
      method: 'PUT',
      url: `/api/app/patients/update-patient-basic-details/${patientsId}`,
      params: { firstName, lastName, middleName, dob },
    },
       { apiName: this.apiName, skipHandleError: true });
  approvePatientByFileIdAndPatientsIdAndPatientIdAndStatusAndReasonForApproveOrRejected = (fileId: string, patientsId: string, patientId: string, status: UploadedFileStatus, reasonForApproveOrRejected: string) =>
    this.restService.request<any, PatientDTO>({
      method: 'POST',
      url: '/api/app/patients/approve-patient',
      params: { fileId, patientsId, patientId, status, reasonForApproveOrRejected },
    },
       { apiName: this.apiName, skipHandleError: true });

  validatePatientInputByInput = (input: CreateUpdatePatientsDTO) =>
    this.restService.request<any, void>({
      method: 'POST',
      url: '/api/app/patients/validate-patient-input',
      body: input,
    },
       { apiName: this.apiName, skipHandleError: true });
    // getVerificationFaxProcess = () =>
    // this.restService.request<any, any>({
    //   method: 'GET',
    //   url: '/api/app/patient-verification/master-verification-fax-process'
    // },
    //  { apiName: this.apiName, skipHandleError: true });

  getMasterVerificationFaxProcess = () =>
    this.restService.request<any, MasterVerificationOutput[]>({
      method: 'GET',
      url: '/api/app/patient-verification/master-verification-fax-process',
    },
    { apiName: this.apiName });

    //  getVerificationOnDemand = () =>
    //  this.restService.request<any, any>({
    //    method: 'GET',
    //    url: '/api/app/patient-verification/master-verification-on-demand',
    //  },
    //     { apiName: this.apiName, skipHandleError: true });

    getMasterVerificationOnDemand = () =>
      this.restService.request<any, MasterVerificationOutput[]>({
        method: 'GET',
        url: '/api/app/patient-verification/master-verification-on-demand',
      },
      { apiName: this.apiName });

    getLockedPolicyIdByTenant= (activeTab:number) =>
     this.restService.request<any, any>({
       method: 'GET',
       url: '/api/app/patient-verification/get-locked-users-policies',
       params:{filter:activeTab}
     },
        { apiName: this.apiName, skipHandleError: true });

        getLockedAuthIdByTenant= () =>
        this.restService.request<any, any>({
          method: 'GET',
          url: '/api/app/patient-authorization/get-locked-users-for-authorization',
        },
           { apiName: this.apiName, skipHandleError: true });
    getVerificationAuth = () =>
    this.restService.request<any, any>({
      method: 'POST',
      url: '/api/app/patient-authorization/authorization-report',
    },
     { apiName: this.apiName, skipHandleError: true });

     getMasterVerificationAuth = () =>
      this.restService.request<any, PatientAuthorizationReportDTO[]>({
        method: 'POST',
        url: '/api/app/patient-authorization/authorization-report',
      },
      { apiName: this.apiName });

     getVerificationIssue = () =>
    this.restService.request<any, any>({
      method: 'GET',
      url: '/api/app/patient-verification/master-verification-verification-issues',
    },
     { apiName: this.apiName, skipHandleError: true });

     getMasterVerificationVerificationIssues = () =>
      this.restService.request<any, MasterVerificationOutput[]>({
        method: 'GET',
        url: '/api/app/patient-verification/master-verification-verification-issues',
      },
      { apiName: this.apiName });

     STATupdate = (verificationId: string) =>
    this.restService.request<any, string>(
      {
        method: 'PUT',
        responseType: 'text',
        url: `/api/app/patient-verification/update-stat-for-verification/${verificationId}`,
      },
      { apiName: this.apiName }
    );
    lockUpdate = (id: string) =>
    this.restService.request<any, any>(
      {
        method: 'PUT',
        url: `/api/app/patient-verification/update-lock-for-verification/${id}`,
      },
      { apiName: this.apiName }
    );
     lockUpdateAuth= (id: string) =>
    this.restService.request<any, any>(
      {
        method: 'PUT',
        url: `/api/app/patient-authorization/lock/${id}`,
      },
      { apiName: this.apiName }
    );
    STATUpdateAuth = (gAuthorizationId: string) =>
    this.restService.request<any, string>(
      {
        method: 'POST',
        responseType: 'text',
        url: `/api/app/patient-authorization/updat-tAT/${gAuthorizationId}`,
      },
      { apiName: this.apiName }
    );
    StatusUpdateAuth = (id: string) =>
    this.restService.request<any, string>(
      {
        method: 'PUT',
        url: `/api/app/patient-authorization/update-auth-status/${id}`,
      },
      { apiName: this.apiName }
    );
    Statusupdate = (id: string) =>
    this.restService.request<any, string>(
      {
        method: 'PUT',
        url: `/api/app/patient-verification/update-verification-status/${id}`,
      },
      { apiName: this.apiName }
    );

  constructor(private restService: RestService) { }
}
