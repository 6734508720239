import { Authorization } from './../../../../order/src/app/order-proxy/patient/entity/models';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { NewVerificationComponent } from '../newVerification/newVerification.component';
import Swal from 'sweetalert2';
import { DataTableDirective } from 'angular-datatables';
import { OrganizationUnitService } from 'projects/admin/src/app/admin-proxy/platform-app-management/rcm/platform-management/organization-units';
import { InboundDocumentService } from 'projects/admin/src/app/admin-proxy/platform-app-management/rcm/platform-management/inbound';
import { PagedAndSortedResultRequestDto } from '@abp/ng.core';
import { InboundStatusShortCode } from 'projects/shared/src/app/data/common-data';
import { PatientsService } from '../patient-proxy/patient/patients.service';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatSelect, MatSelectChange } from '@angular/material/select';
import { MatOption } from '@angular/material/core';
import { map, startWith } from 'rxjs/operators';
import { Observable, Subscription } from 'rxjs';
import { Branch } from '../medicare-check-list/medicare-check-list.component';
import { defaultGuid, MultipleVerificationFilter } from 'projects/shared/src/app/enums/allenums.enum';
import { Title } from '@angular/platform-browser';
import { CommonService } from 'projects/shared/src/app/services/common.service';
import { ActivatedRoute } from '@angular/router';
import { NgbNav } from '@ng-bootstrap/ng-bootstrap';
import { PatientAuthorizationService } from '../patient-proxy/patient-optimization/patient-authorization.service';

@Component({
  selector: 'app-miverification',
  templateUrl: './miverification.component.html',
  styleUrls: ['./miverification.component.scss'],
})
export class MIVerificationComponent implements OnInit {
  activeTab: string = 'FaxProcess';
  @ViewChild(DataTableDirective, { static: false })
  datatableElement: DataTableDirective;
  selectedDate: Date | null = null;
  OnDemandList: any;
  inboundStatusIds = InboundStatusShortCode;
  AuthorizationList: any;
  VerificationIssuesList: any;
  FaxProcessList: any;
  branchForm: FormGroup;
  organizationUnitName: string;
  isLoading: boolean;
  verificationId: string;
  precertId: string;
  verificationIssueId: string;
  patientId:string;
  InboundStatusList: any;
  verificationIssueList: any;
  isDoVerification: boolean = false;
  public brnachList: Observable<Branch[]> | undefined;
  sLstId: string = '';
  tenantId: string;
  public drpBranch: Branch[] = [];
  @ViewChild('nav') nav: NgbNav;
  activeTabId: number = 1;
  @ViewChild('selectBranch') selectBranch: MatSelect;
  @ViewChild('selectAllBranch') private selectAllBranch: MatOption;
  selectedBranchList: string[] = null;
  subscription$: Subscription[] = [];
  getWIPTableData: Subscription;
  isStatEnableAPIcall:Subscription;
  constructor(
    private dialog: MatDialog,
    private branchService: OrganizationUnitService,
    private inboundDocumentService: InboundDocumentService,
    private patientsService: PatientsService,
    private formBuilder: FormBuilder,
    private title: Title,
    private commonService: CommonService,
    private activatedRoute: ActivatedRoute,
    private atientAuthorizationService: PatientAuthorizationService,
  ) {}
  ngOnInit(): void {
    this.title.setTitle('Qsecure | Master Verification');
    this.isLoading = false;
    // this.GetFaxProcessList();
    this.selectesCurrentTab();
    this.tenantId = localStorage.getItem('tenantId') ?? '';
    this.branchForm = this.formBuilder.group({
      drpBranch: new FormControl(''),
      txtBranch: new FormControl(''),
    });
    const activatedRoute = this.activatedRoute.paramMap.subscribe(
      (response) => {
        this.patientId = response.get('patientId') || defaultGuid;
      },
      (err) => {
        const data: HttpErrorResponse = err;
      }
    );
    this.subscription$.push(activatedRoute);
  }


  selectesCurrentTab() {
    setTimeout(() => {
      if(this.patientId !== defaultGuid){
        const activatedRoute = this.activatedRoute.paramMap.subscribe(
          (response) => {
            this.patientId = response.get('patientId');
          },
          (err) => {
            const data: HttpErrorResponse = err;
          }
        );
        this.subscription$.push(activatedRoute);
        this.nav.select('Authorization');

        this.statCheckAPIcall();
      }else{
        this.nav.select('FaxProcess');
        this.changeTab('FaxProcess');
      }
    }, 100);
  }


  statCheckAPIcall(){
    if (this.isStatEnableAPIcall) {
      this.isStatEnableAPIcall.unsubscribe();
    }
    this.isStatEnableAPIcall = this.atientAuthorizationService
      .initiateDoPrecertResupplyByGPatientId(this.patientId).subscribe(
        (response) => {
          this.changeTab('Authorization');
        },
        (error) => {
          this.changeTab('Authorization');
          console.error('Error fetching patient details:', error);
        }
      );
  }

  GetFaxProcessList() {
    this.isLoading = true;
    if (this.getWIPTableData) {
      this.getWIPTableData.unsubscribe();
    }
    this.getWIPTableData = this.patientsService
      .getMasterVerificationFaxProcess()
      .subscribe(
        (response) => {
          this.FaxProcessList = response;

          this.FaxProcessList = this.FaxProcessList.map((data) => {
            return {
              ...data,
              lockedAt: this.commonService.getFormattedDateTimeZone(
                data.lockedAt
              ),
              enteredOn: this.commonService.getFormattedDateZone(
                data.enteredOn
              ),
              createdDate: this.commonService.getFormattedDateTimeZone(
                data.createdDate
              ),
              modifiedDate: this.commonService.getFormattedDateTimeZone(
                data.modifiedDate
              ),
            };
          });

          this.isLoading = false;
        },
        (err) => {
          this.isLoading = false;
          const data: HttpErrorResponse = err;
          Swal.fire({
            icon: 'info',
            text: data?.error?.error?.message,
          });
        }
      );
  }
  GetOnDemandList() {
    this.isLoading = true;
    if (this.getWIPTableData) {
      this.getWIPTableData.unsubscribe();
    }
    this.getWIPTableData = this.patientsService
      .getMasterVerificationOnDemand()
      .subscribe(
        (response) => {
          this.OnDemandList = response;

          this.OnDemandList = this.OnDemandList.map((data) => {
            return {
              ...data,
              lockedAt: this.commonService.getFormattedDateTimeZone(
                data.lockedAt
              ),
              enteredOn: this.commonService.getFormattedDateZone(
                data.enteredOn
              ),
              createdDate: this.commonService.getFormattedDateTimeZone(
                data.createdDate
              ),
              modifiedDate: this.commonService.getFormattedDateTimeZone(
                data.modifiedDate
              ),
            };
          });

          this.isLoading = false;
        },
        (err) => {
          this.isLoading = false;
          const data: HttpErrorResponse = err;
          Swal.fire({
            icon: 'info',
            text: data?.error?.error?.message,
          });
        }
      );
  }
  GetAuthList() {
    this.isLoading = true;
    if (this.getWIPTableData) {
      this.getWIPTableData.unsubscribe();
    }
    this.getWIPTableData = this.patientsService
      .getMasterVerificationAuth()
      .subscribe(
        (response) => {
          this.AuthorizationList = response;

          console.log('this.AuthorizationList')
          console.log(this.AuthorizationList)
          this.AuthorizationList = this.AuthorizationList.map((data) => {
            return {
              ...data,
              plan: data.plan ? data.plan.charAt(0).toUpperCase() + data.plan.slice(1) : '',
              lockedAT: this.commonService.getFormattedDateTimeZone(
                data.lockedAT
              ),
              enterOn: this.commonService.getFormattedDateZone(
                data.enterOn
              ),
              createdDate: this.commonService.getFormattedDateTimeZone(
                data.createdDate
              ),
              modifiedDate: this.commonService.getFormattedDateTimeZone(
                data.modifiedDate
              ),
            };
          });
          this.isLoading = false;
        },
        (err) => {
          this.isLoading = false;
          const data: HttpErrorResponse = err;
          Swal.fire({
            icon: 'info',
            text: data?.error?.error?.message,
          });
        }
      );
  }
  GetVerificationIssueList() {
    this.isLoading = true;
    if (this.getWIPTableData) {
      this.getWIPTableData.unsubscribe();
    }
    this.getWIPTableData = this.patientsService
      .getMasterVerificationVerificationIssues()
      .subscribe(
        (response) => {
          this.VerificationIssuesList = response;
          this.VerificationIssuesList = this.VerificationIssuesList.map(
            (data) => {
              return {
                ...data,
                lockedAt: this.commonService.getFormattedDateTimeZone(
                  data.lockedAt
                ),
                enteredOn: this.commonService.getFormattedDateZone(
                  data.enteredOn
                ),
                createdDate: this.commonService.getFormattedDateTimeZone(
                  data.createdDate
                ),
                modifiedDate: this.commonService.getFormattedDateTimeZone(
                  data.modifiedDate
                ),
                receivedDate: this.commonService.getFormattedDateZone(
                  data.receivedDate
                ),
              };
            }
          );

          this.isLoading = false;
        },
        (err) => {
          this.isLoading = false;
          const data: HttpErrorResponse = err;
          Swal.fire({
            icon: 'info',
            text: data?.error?.error?.message,
          });
        }
      );
  }

  changeTab(tab: string) {
    this.activeTab = tab;
    if (this.activeTab == 'FaxProcess') {
      this.activeTabId = MultipleVerificationFilter.FaxProcess;
      this.GetFaxProcessList();
    } else if (this.activeTab == 'On-Demand') {
      this.activeTabId = MultipleVerificationFilter.OnDemand;
      this.GetOnDemandList();
    } else if (this.activeTab == 'Authorization') {
      this.activeTabId = MultipleVerificationFilter.Authorization;
      this.GetAuthList();
    } else if (this.activeTab == 'VerificationIssues') {
      this.activeTabId = MultipleVerificationFilter.VerificationIssue;
      this.GetVerificationIssueList();
    }
  }
  onRowSelected(isCheck: boolean) {
    this.isDoVerification = isCheck;
  }
  selectedList(lstId: number[]) {
    this.sLstId = lstId.join(', ');
  }
  getBranchList() {
    const getBranchList = this.branchService
      .getBranchList(this.tenantId)
      .subscribe(
        (response) => {
          response?.forEach((element) => {
            this.drpBranch.push({
              id: element.id,
              organizationUnitName: element.organizationUnitName,
            });
          });

          this.brnachList = this.branchForm
            ?.get('txtBranch')
            ?.valueChanges?.pipe(
              startWith(''),
              map((value) =>
                this.drpBranch?.filter((option) =>
                  option?.organizationUnitName
                    ?.toLowerCase()
                    ?.includes(value?.toLowerCase() ?? '')
                )
              )
            );
        },
        (err) => {
          const data: HttpErrorResponse = err;
          Swal.fire({
            icon: 'info',
            text: data?.error?.error?.message,
          });
        }
      );
    this.subscription$.push(getBranchList);
  }
}
