<div class="col-12" style="margin-top: -27px;">
  <div class=" d-flex align-items-center justify-content-between m-0">
    <h4 class="font-size-18 m-0"><b>Patient SaleOrder Information</b></h4>
    <div class=" page-title-box page-title-right pt-2 pb-0">
      <ol class="breadcrumbs m-0">
        <li class="blinking ml-2 mt-2 mr-2 breadcrumb-item">{{currentOrderStatus}}</li>
        <li class="mb-1">
          <button matTooltip="Click To Goback to Patient" mat-button class="buttonColor font-size-10"
            (click)="backToPatient()">
            <i class="fa fa-backward mr-1" ></i> Back To Patient
          </button>
        </li>

        <li class="ml-2 mt-2 breadcrumb-item">Patient</li>
        <li class="mt-2 breadcrumb-item muted">{{strUrlPath==="saleorderCreateEdit"?"New":"View"}} SaleOrder</li>
      </ol>
    </div>
  </div>
</div>

<div class="row" style="margin-top: -30px;">
  <div class="col-xl-2 pr-0">
    <div class="card card-body">
      <div *ngIf="lblDefaultSaleOrderId" class="text-center">
        <span style="font-size: larger;"><b class="colored-heading">Sale Order ID </b></span>
        <h3 style="margin: 0 0 4px;"><b>{{ lblDefaultSaleOrderId }}</b></h3>
      </div>
      <div class="text-center">
        <span style="font-size: larger;"><b class="colored-heading">Patient ID </b></span>
        <h3 style="margin: 0 0 4px;"><b>{{ lblDefaultPatientId }}</b></h3>
      </div>
      <div class="text-center">
        <span style="font-size: larger;"><b class="colored-heading">Patient Name </b></span>
        <h3 style="margin: 0 0 4px;"><b>{{ lblPatientName }}</b></h3>
        <!-- <mat-divider></mat-divider>
          <div class="text-center">
            <p><b class="colored-heading">Location </b></p>
          </div>
          <div class="text-center">
            <h5><b>{{ lblPatientName }}</b></h5>
          </div> -->
      </div>
      <div class="table-responsive mt-2">
        <table class="table table-centered">
          <tbody>
            <tr>
              <td class="blinking">
                <p class="mb-0 text-center">CurrentStatus</p> <br>
                <h5 class="btm text-center">{{ currentOrderStatus }}</h5>
              </td>

            </tr>

            <tr>
              <td>
                <p class="mb-0 text-center">Location</p> <br>
                <h5 class="btm text-center">{{ strLocation }}</h5>
              </td>

            </tr>
            <tr>
              <td>
                <p class="mb-0 text-center">Gender</p> <br>
                <h5 class="btm text-center">{{ lblPatientGender }}</h5>
              </td>
              <!-- <td style="width: 60%">
                    <h5 class="mb-0">{{ lblPatientGender }}</h5>
                  </td>
                </tr> -->
            <tr>
              <td>

                <p class="mb-0 text-center">DOB</p> <br>
                <h5 class="btm text-center">{{ lblPatientDOB }}</h5>
              </td>
              <!-- <td style="width: 60%">
                    <h5 class="mb-0">{{ lblPatientDOB }}</h5>
                  </td> -->
            </tr>
            <!-- <tr>
                <td>
                  <p class="mb-0 text-center">Height</p> <br>
                  <h5 class="btm text-center">{{ lblPatientHeight | mask: "0'00''" }}</h5>
                </td>
              </tr>
              <tr>
                <td>
                  <p class="mb-0 text-center">Weight(Lbs)</p> <br>
                  <h5 class="btm text-center">{{ lblPatientWeight }}</h5>
                </td>
              </tr>
              <tr>
                <td>
                  <p class="mb-0 text-center">Branch Office </p> <br>
                  <h5 class="btm text-center">{{ lblBranchOffice }}</h5>
                </td>
              </tr>
              <tr>
                <td>
                  <p class="mb-0  text-center">Account </p><br>
                  <h5 class="btm text-center">{{ lblAccountNumber }}</h5>
                </td>
              </tr>-->
            <tr>
              <td>
                <p class="mb-0  text-center">SSN</p><br>
                <h5 class="btm  text-center">{{ lblSsnNumber }}</h5>
              </td>
              <!-- <td style="width: 60%">
                    <h5 class="mb-0">{{ lblSsnNumber }}</h5>
                  </td> -->
            </tr>

            <tr>
              <td>
                <p class="mb-0  text-center">Mobile</p> <br>
                <h5 class="btm  text-center">{{ strMobile }}</h5>
              </td>
              <!-- <td style="width: 60%">
                    <h5 class="mb-0">{{ strMobile }}</h5>
                  </td> -->
            </tr>
            <tr>
              <td>
                <p class="mb-0 text-center">Billing Country</p><br>
                <h5 class="btm  text-center">{{ strBillingCountry }}</h5>
              </td>
              <!-- <td style="width: 60%">
                    <h5 class="mb-0">{{ strEmailAddress }}</h5>
                  </td> -->
            </tr>
            <!-- <tr>
                  <td>
                    <p class="mb-0">Billing Address : {{ strBillingAddress }}</p>
                  </td>

                </tr> -->

            <tr>
              <td>
                <p class="mb-0 text-center">Billing City</p><br>
                <h5 class="btm  text-center">{{ strBillingCity }}</h5>
              </td>
              <!-- <td style="width: 50%">
                    <h5 class="mb-0">{{ strBillingCity }}</h5>
                  </td> -->
            </tr>
            <tr>
              <td>
                <p class="mb-0 text-center">Billing State</p><br>
                <h5 class="btm  text-center">{{ strBillingState }}</h5>
              </td>
              <!-- <td >
                    <h5 class="mb-0">{{ strBillingState }}</h5>
                  </td> -->
            </tr>


            <tr>
              <td *ngIf="forBlink">
                <p class="mb-0 text-center">Billing Amount</p> <br>
                <h5 class="btm text-center">{{ lblBillingAmount }}</h5>
              </td>
            </tr>
            <tr>
              <td *ngIf="forBlink">
                <p class="mb-0 text-center">Billing Status</p> <br>
                <h5 class="btm text-center">{{ lblBillingStatus }}</h5>
              </td>
            </tr>

            <tr>
              <td *ngIf="!forBlink" class="blinking">
                <p class="mb-0 text-center">Billing Amount</p> <br>
                <h5 class="btm text-center">{{ lblBillingAmount }}</h5>
              </td>
            </tr>
            <tr>
              <td *ngIf="!forBlink" class="blinking">
                <p class="mb-0 text-center">Billing Status</p> <br>
                <h5 class="btm text-center">{{ lblBillingStatus }}</h5>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  <div class="col-xl-10">
    <!-- <div class="card card-body  "> -->
    <ul ngbNav #nav="ngbNav" justify="justified" (navChange)="onNavChange($event)"
      class="alert alert-success nav nav-pills nav-fill">
      <li ngbNavItem="Order" class="nav-item">
        <a ngbNavLink>Order</a>
        <ng-template ngbNavContent>
          <app-order (orderIdEmitter)="orderDetailsAfterSave($event)" (saveOrderFormState)="onSaveOrderData($event)"
            (nextTabMoveOnSaveEmitter)="changeNextTab($event)" [patientId]='patientId' [orderId]='orderId'>
          </app-order>
        </ng-template>
      </li>
      <li ngbNavItem="Clinical" class="nav-item" [disabled]="!orderId">
        <a ngbNavLink>Clinical</a>
        <ng-template ngbNavContent>
          <app-order-clinical [patientId]='patientId' [orderId]='orderId'
            (nextTabMoveOnSaveEmitter)="changeNextTab($event)" (saveClinicalFormState)="onSaveClinicalData($event)">
          </app-order-clinical>
        </ng-template>
      </li>

      <li ngbNavItem="Insurance" [disabled]="!orderId">
        <a ngbNavLink>Insurance</a>
        <ng-template ngbNavContent>
          <app-order-insurance [patientId]='patientId' (nextTabMoveOnSaveEmitter)="changeNextTab($event)"
            [orderId]='orderId' (saveInsuranceFormState)="onSaveInsuranceData($event)"></app-order-insurance>
        </ng-template>
      </li>
      <li ngbNavItem="Item" [disabled]="!orderId">
        <a ngbNavLink>Item</a>
        <ng-template ngbNavContent>
          <app-order-item [orderId]='orderId' (saveItemFormState)="onSaveItemData($event)"></app-order-item>
        </ng-template>
      </li>
      <li ngbNavItem="Verification" [disabled]="!orderId">
        <a ngbNavLink>Verification</a>
        <ng-template ngbNavContent>
          <!-- <app-verification [patientId]='patientId' [orderId]='orderId' [preOrderId]= 'preOrderId'
              (nextTabMoveOnSaveEmitter)="changeNextTab($event)"
              (saveVerificationFormState)="onSaveVerificationData($event)"
              (verificationFormEmit)="onSaveVerificaiton($event)"></app-verification> -->
          <app-order-verification [patientId]='patientId' [orderId]='orderId' [preOrderId]='preOrderId'
            (nextTabMoveOnSaveEmitter)="changeNextTab($event)"
            (saveVerificationFormState)="onSaveVerificationData($event)"
            (verificationFormEmit)="onSaveVerificaiton($event)"></app-order-verification>
        </ng-template>
      </li>
      <li ngbNavItem="Authorization" [disabled]="!orderId">
        <a ngbNavLink>Authorization</a>
        <ng-template ngbNavContent>
          <!-- <app-authorization [orderId]='orderId' [patientId]='patientId'
              (nextTabMoveOnSaveEmitter)="changeNextTab($event)"
              (saveAuthorizationFormState)="onSaveAuthorizationData($event)"></app-authorization> -->
          <app-order-authorization [orderId]='orderId' [patientId]='patientId'
            (nextTabMoveOnSaveEmitter)="changeNextTab($event)"
            (saveAuthorizationFormState)="onSaveAuthorizationData($event)"></app-order-authorization>
        </ng-template>
      </li>
      <li ngbNavItem="Document" [disabled]="!orderId">
        <a ngbNavLink>Document</a>
        <ng-template ngbNavContent>
          <app-order-document [strPageType]="'saleOrder'" [orderStatus]="orderStatus"
            (nextTabMoveOnSaveEmitter)="changeNextTab($event)" [patientID]='patientId' [orderId]='orderId'>
          </app-order-document>
        </ng-template>
      </li>
      <li ngbNavItem="Reminders">
        <a ngbNavLink>Reminders</a>
        <ng-template ngbNavContent>
          <app-reminder [patientId]='patientId' [orderStatus]='orderStatus'
            (nextTabMoveOnSaveEmitter)="changeNextTab($event)" [orderId]='orderId'>
          </app-reminder>
        </ng-template>
      </li>
    </ul>
    <div [ngbNavOutlet]="nav"></div>
  </div>
</div>

<!-- <button mat-button matTooltip="Add Reminder" class="clr mat-fab-bottom-right rightfloatfabbutton"
  (click)='openRemindermodal()' mat-mini-fab>
  <mat-icon class="icon">add</mat-icon>
</button>
<button mat-button matTooltip="Add Notes" class="clr mat-fab-bottom-right rightfloatfabbutton"
  (click)='openRemindermodal()' mat-mini-fab>
  <mat-icon class="icon">add</mat-icon>
</button>
<button mat-menu-item  mat-button matTooltip="View Notes" class="clr mat-fab-bottom-right rightfloatfabbutton"
  (click)='openRemindermodal()' mat-mini-fab>
  <mat-icon class="icon">add</mat-icon>
</button> -->
<button [matMenuTriggerFor]="menu" mat-button matTooltip="Add" class="clr mat-fab-bottom-right rightfloatfabbutton"
  mat-mini-fab>
  <mat-icon class="icon">add</mat-icon>
</button>

<mat-menu #menu="matMenu">
  <button (click)='openRemindermodal()' mat-menu-item matTooltip="Add Reminder" > Add Reminder
    <mat-icon class="icon">add</mat-icon>
  </button>
  <button (click)='openAddNotesPopUp()' mat-menu-item matTooltip="Add Notes"> Add Notes
    <mat-icon class="icon">add</mat-icon>
  </button>
  <button (click)='openViewNotesPopup()' mat-menu-item matTooltip="View Notes"> View Notes
    <mat-icon class="icon">remove_red_eye</mat-icon>
  </button>
</mat-menu>



<app-reminder-message></app-reminder-message>
