import { mapEnumToOptions } from '@abp/ng.core';

export enum VerificationStatus {
  done = 'done',
  pending = 'pending',
  unabletoverify = 'unabletoverify',
  new='New',
  Cancelled='cancelled',
  Expired='expired'
}
export enum VerificationStatusV1 {
  done = 'Done',
  pending = 'Pending',
  unabletoverify = 'Unabletoverify',
  new='new',
  Cancelled='Cancelled',
  Expired='Expired'
}
// export enum VerificationStatus
// {
//     New,
//     Done,
//     Pending,
//     Unabletoverify,
//     Cancelled,
//     Expired
// }

export enum YearType {
  CalendarYear = 'Calendar',
  FiscalYear = 'Fiscal',
}

export enum InsuranceType {
  primary='primary' ,
  secondary='secondary' ,
  tertiary='tertiary' ,
  Patient_Response='Patient_Response',
}
export enum sortDocShortcode
{
   All= "001",
    faceToFace="002",
   sleep= "003",
    compliance="004",
    rx="005",
   corres= "006",
   demo= "007",
 };

 export enum VerificationStatusV2 {
  New = 'New',
  Done = 'Done',
  Pending = 'Pending',
  Unabletoverify = 'Unabletoverify',
  Cancelled = 'Cancelled',
  Expired = 'Expired',
  Yettoverify = 'Yettoverify',
}


export const verificationStatusOptions = mapEnumToOptions(VerificationStatus);
