<div class="container-fluid">
    <div class="row bg">
        <div class="col-12">
            <div class="d-flex align-items-center justify-content-between mb-3">
                <h3 class="font-weight-bolder mb-0">Shipping Tickets</h3>
                <div class=" page-title-box page-title-right">
                    <ol class="breadcrumbs m-0 align-items-center">
                        <li class="breadcrumb-item">Settings</li>
                        <li class="breadcrumb-item ">Shipping Tickets</li>
                    </ol>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="col-12" style="margin-top: -21px;">
    <!-- <div class="card">
            <div class="card-body"> -->
    <ul ngbNav #nav="ngbNav" justify="justified" class="alert alert-success nav nav-pills nav-fill">

        <li ngbNavItem="appointmentType" class="nav-item">
            <a ngbNavLink>Shipping Ticket 1</a>
            <ng-template ngbNavContent>
                <app-shipping-ticket></app-shipping-ticket>
            </ng-template>
        </li>
        <!-- <li ngbNavItem="holiday" class="nav-item">
            <a ngbNavLink>Ticket 2</a>
            <ng-template ngbNavContent>
                <app-shipping-ticket-page2></app-shipping-ticket-page2>
            </ng-template>
        </li> -->


    </ul>
    <div [ngbNavOutlet]="nav"></div>
    <!-- </div>
        </div>
    </div> -->
</div>