import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { PatientMedicareCheckListDetailsService } from '../patient-proxy/patient-optimization/patient-medicare-check-list-details.service';
import Swal from 'sweetalert2';
import { Subscription } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import {
  MedicareCheckListFilter,
  MedicareCheckListStatus,
} from '../patient-proxy/patient/enumeration-data/medicare-check-list-status.enum';
import { Title } from '@angular/platform-browser';
import { CommunicationService } from 'projects/shared/src/app/services/communication.service';
import { CommonService } from 'projects/shared/src/app/services/common.service';
import { NgbNav } from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRoute } from '@angular/router';
import { defaultGuid } from 'projects/shared/src/app/enums/allenums.enum';
@Component({
  selector: 'app-master-medicare-checklist',
  templateUrl: './master-medicare-checklist.component.html',
  styleUrls: ['./master-medicare-checklist.component.scss'],
})
export class MasterMedicareChecklistComponent implements OnInit {
  isLoading: boolean;
  MMCDocumentUploadTypes = MedicareCheckListFilter;
  // activeTab: MedicareCheckListFilter = this.MMCDocumentUploadTypes.FaxProcess;
  activeTab: MedicareCheckListFilter;
  DataList: any;
  private mmcAPICall: Subscription;
  isStatEnableAPIcall:Subscription;
  subscription$: Subscription[] = [];
  // mmcSubscription$: Subscription[] = [];
  response: any;
  @ViewChild('nav') nav: NgbNav;
  @Input() selectedNode: any;
  @Output() reloadTableEvent = new EventEmitter();
  @Output() selectNode = new EventEmitter();
  funcListener: any;
  patientId:string;
  constructor(
    private patientMedicareCheckListDetailsService: PatientMedicareCheckListDetailsService,
    private title: Title,
    private communicationService: CommunicationService,
    private cdr: ChangeDetectorRef,
    private commonService: CommonService,
    private activatedRoute: ActivatedRoute,
  ) {
    this.funcListener = this.func.bind(this);
    window.addEventListener('storage', this.funcListener);

    this.communicationService.functionMasterMedicare$.subscribe((statusId) => {
      this.getTableData(statusId, null, null, null, null, null, null, null);
    });
  }

  ngOnInit() {
    this.title.setTitle('Qsecure | Master Medicare Checklist');

    const activatedRoute = this.activatedRoute.paramMap.subscribe(
      (response) => {
        this.patientId = response.get('patientId') || defaultGuid;
      },
      (err) => {
        const data: HttpErrorResponse = err;
      }
    );
    this.subscription$.push(activatedRoute);


    this.selectesCurrentTab();
  }


  selectesCurrentTab() {
    setTimeout(() => {
      if(this.patientId !== defaultGuid){
        const activatedRoute = this.activatedRoute.paramMap.subscribe(
          (response) => {
            this.patientId = response.get('patientId');
          },
          (err) => {
            const data: HttpErrorResponse = err;
          }
        );
        this.subscription$.push(activatedRoute);
        this.nav.select('OnDemand');

        this.statCheckAPIcall();
      }else{
        this.nav.select('FaxProcess');
        this.changeTab(this.MMCDocumentUploadTypes.FaxProcess);
      }
    }, 100);
  }

  // Freelancer Senthil Code
  func(event): void {
    if (event.key == 'checkNewTabData') {
      if (event.newValue == 'NewTabDataSaved') {
        this.communicationService.triggerMasterMedicare(this.activeTab);
        this.reloadTableEvent.emit();
      } else {
      }
    }
  }
  ngOnDestroy(): void {
    window.removeEventListener('storage', this.funcListener);
    if (this.mmcAPICall) {
      this.mmcAPICall.unsubscribe();
    }
    // this.mmcSubscription$.forEach((sub) => {
    //   sub && sub?.unsubscribe();
    // });
  }

  getTableData(
    status: MedicareCheckListFilter,
    FaxNo: string,
    ApproveOrDisapprove: MedicareCheckListStatus,
    patientName: string,
    chartNo: string,
    branch: string,
    patient: string,
    BranchName: string
  ) {
    this.isLoading = true;
    try {
      if (this.mmcAPICall) {
        this.mmcAPICall.unsubscribe();
      }
      this.mmcAPICall = this.patientMedicareCheckListDetailsService
        .mmcGetAPI(
          status,
          FaxNo,
          ApproveOrDisapprove,
          patientName,
          chartNo,
          branch,
          patient,
          BranchName
        )
        .subscribe(
          (response) => {
            this.DataList = response;

            this.DataList = this.DataList.map((data) => {
              return {
                ...data,
                creationTime: this.commonService.getFormattedDateTimeZone(
                  data.creationTime
                ),
                preparedOn: this.commonService.getFormattedDateZone(
                  data.preparedOn
                ),
              };
            });

            this.cdr.detectChanges();
            this.isLoading = false;
          },
          (error) => {
            console.error('Error fetching patient details:', error);
            this.cdr.detectChanges();
            this.isLoading = false;
          }
        );
      // this.mmcAPICall.add(() => {
      //   this.isLoading = false;
      // });
      // this.tableData$ = getTableData;
    } catch (error) {
      console.error('Unexpected error:', error);
      this.isLoading = false;
    } finally {
    }
  }

  changeTab(tab: number) {
    // Check if the clicked tab is already active
    if (this.activeTab === tab) {
      return; // Exit the function if the clicked tab is already active
    }

    this.activeTab = tab;
    if (
      this.activeTab === this.MMCDocumentUploadTypes.FaxProcess ||
      this.activeTab === this.MMCDocumentUploadTypes.OnDemand ||
      this.activeTab === this.MMCDocumentUploadTypes.ApproveDisapprove ||
      this.activeTab === this.MMCDocumentUploadTypes.Pending
    ) {
      this.getTableData(
        this.activeTab,
        null,
        null,
        null,
        null,
        null,
        null,
        null
      );
    }
  }
  statCheckAPIcall(){
    if (this.isStatEnableAPIcall) {
      this.isStatEnableAPIcall.unsubscribe();
    }
    this.isStatEnableAPIcall = this.patientMedicareCheckListDetailsService
      .initiateDoMedicareResupplyByPatientId(this.patientId).subscribe(
        (response) => {
          this.changeTab(this.MMCDocumentUploadTypes.OnDemand);
        },
        (error) => {
          this.changeTab(this.MMCDocumentUploadTypes.OnDemand);
          console.error('Error fetching patient details:', error);
        }
      );

  }
}
