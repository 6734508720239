<div class="table-responsive">
    <table id="tblReminder" datatable [dtOptions]="dtReminderTableOptionsv2"   class="tbl hover row-border hover w-100 display">
        <thead>
            <tr>
                <th><mat-icon>apps</mat-icon></th>
                <th [matTooltip]="'Notes Type'">Notes Type</th>
                <!-- <th >Patient Name</th> -->
                <th [matTooltip]="'Assigned To'">Assigned To</th>
                <th [matTooltip]="'Due Date'" class="text-center">Due Date</th>
                <th [matTooltip]="'Assigned By'">Assigned By</th>
                <th [matTooltip]="'Description'">Description</th>
                <th [matTooltip]="'Priority'">Priority</th>
                <th [matTooltip]="'Status'">Status</th>
                <th [matTooltip]="'Completed'">Completed</th>
                <th [matTooltip]="'Created By'">Created By</th>
                <th [matTooltip]="'Created Date'">Created Date</th>
                <th [matTooltip]="'Modified Date'">Modified Date</th>
                <!-- <th >All users</th> -->
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let data of remindertbldata; let i = index"
                (click)="openDescription(data?.description); setSelectedRow(i)" [ngClass]="{ 'selected-row': i === selectedRowIndex}">
                <td [matTooltip]="'Open Options'">
                    <mat-icon style="cursor: pointer !important" [matMenuTriggerFor]="menu">more_vert</mat-icon>
                    <mat-menu class="custom-mat-menu" #menu="matMenu">
                        <button mat-menu-item (click)="UpdateReminder(data?.id,false)"
                            [disabled]="data?.status === 'Completed' || data?.isEdit === 0">Edit</button>
                        <button mat-menu-item (click)="DeleteReminder(data?.id)" [disabled]="data?.isEdit === 0">Delete</button>
                        <mat-divider></mat-divider>
                        <button mat-menu-item (click)="completeReminder(data?.id)"
                            [disabled]="data?.status === 'Completed'">Complete</button>
                    </mat-menu>
                </td>
                <td (dblclick)="onRowDoubleClick(data?.note.split('-')[0] || '-')" [matTooltip]="data?.note.split('-')[0] || '-'"> {{
                    data?.note.split('-')[0] || '-' }} </td>
                <!-- <td >{{ data.patientName || '-' }}</td> -->
                <td (dblclick)="onRowDoubleClick(data?.assignedUserName || '-')" [matTooltip]="data?.assignedUserName || '-'"> {{
                    data?.assignedUserName
                    || '-' }} </td>
               <td (dblclick)="onRowDoubleClick(getFormattedDate(data?.sDueDate))" [matTooltip]="data?.sDueDate ? (data?.sDueDate | date : 'MM/dd/yyyy') : '-'" [ngClass]="{'past-due-row': isPastDue(data?.sDueDate) && data.status !== 'Completed'}" class="text-center">
                    {{ data?.sDueDate ? (data?.sDueDate | date : 'MM/dd/yyyy') : '-' }}
                </td>
                <td (dblclick)="onRowDoubleClick(data?.userName || '-')" [matTooltip]="data?.userName || '-'"> {{ data?.userName || '-' }}
                </td>
                <td (dblclick)="onRowDoubleClick(data?.description || '-')" [matTooltip]="data?.description || '-'">{{ data?.description || '-' }}</td>
                <td (dblclick)="onRowDoubleClick(data?.priority || '-')" [matTooltip]="data?.priority || '-'">{{ data?.priority || '-' }}</td>
                <td (dblclick)="onRowDoubleClick(data?.status || '-')" [matTooltip]="data?.status || '-'">{{
                    data?.status ||'-' }}</td>
                <td (dblclick)="onRowDoubleClick(getFormattedDate(data?.completedDate))" [matTooltip]="data?.completedDate ? (data?.completedDate | date : 'MM/dd/yyyy') : '-'">{{ data?.completedDate ? (data?.completedDate | date :
                    'MM/dd/yyyy') : '-' }}</td>
                <td (dblclick)="onRowDoubleClick(data?.userName || '-')" [matTooltip]="data?.userName || '-'">{{
                    data?.userName ||'-' }}
                </td>
                <td (dblclick)="onRowDoubleClick(getFormattedDateTimeZone(data?.creationTime))"
                    [matTooltip]="getFormattedDateTimeZone(data?.creationTime)">
                    {{ getFormattedDateTimeZone(data?.creationTime) }}
                </td>
                <td (dblclick)="onRowDoubleClick(getFormattedDateTimeZone(data?.lastModificationTime))"
                [matTooltip]="getFormattedDateTimeZone(data?.lastModificationTime)">
                {{ getFormattedDateTimeZone(data?.lastModificationTime) }}
            </td>
            </tr>
        </tbody>
        <tfoot>
            <tr>
                <th><mat-icon>apps</mat-icon></th>
                <th>
                    <input matInput class="textbox-border-class" type="text" placeholder="Notes Type"
                        name="search-NotesType" />
                </th>
                <th>
                    <input matInput class="textbox-border-class" type="text" placeholder="Assigned To"
                        name="search-AssignedTo" />
                </th>
                <th>
                    <input matInput class="textbox-border-class text-center" type="text" placeholder="Due Date" name="search-DueDate" />
                </th>
                <th>
                    <input matInput class="textbox-border-class" type="text" placeholder="Assigned By"
                        name="search-AssignedBy" />
                </th>
                <th>
                    <input matInput class="textbox-border-class" type="text" placeholder="Description"
                        name="search-Description" />
                </th>
                <th>
                    <input matInput class="textbox-border-class" type="text" placeholder="Priority"
                        name="search-Priority" />
                </th>
                <th>
                    <input matInput class="textbox-border-class" type="text" placeholder="Status" name="search-Status" />
                </th>
                <th>
                    <input matInput class="textbox-border-class" type="text" placeholder="Completed"
                        name="search-Completed" />
                </th>
                <th>
                    <input matInput class="textbox-border-class" type="text" placeholder="Created By"
                        name="search-CreatedBy" />
                </th>
                <th>
                    <input matInput class="textbox-border-class" type="text" placeholder="Created Date"
                        name="search-CreatedDate" />
                </th>
                <th>
                    <input matInput class="textbox-border-class" type="text" placeholder="Modified Date"
                        name="search-ModifiedDate" />
                </th>
            </tr>
        </tfoot>
    </table>
</div>
<mat-dialog-actions *ngIf="remindertbldata && remindertbldata.length > 0">
    <textarea [disabled]="true" cols="132" rows="4" style="resize: none;"
        class="txtnotescls mt-3">{{ vDescription }}</textarea>
</mat-dialog-actions>