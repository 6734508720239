<div class="row">
    <div class="col-12">
        <div class="table table-responsive">
            <table id="tbl" datatable [dtOptions]="dtTemplateOptions" class="tbl hover row-border hover w-100 display">
                <thead>
                    <tr>
                        <th><mat-icon>apps</mat-icon></th>
                        <th [matTooltip]="'Template Name'">Template Name</th>
                        <th [matTooltip]="'Created By'">Created By</th>
                        <th class="text-center" [matTooltip]="'Created Date'">Created Date</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let data of templateList ">

                        <td>
                            <ng-container>
                                <a matTooltip="View Template" (click)="viewTemplate(data?.blobName)">
                                    <i class="fa fa-eye pointer editBtnColor mr-2"></i>
                                </a>

                                <a matTooltip="Delete Template" (click)="deleteTemplate(data?.id)">
                                    <i class="fa fa-trash-o pointer deleteBtnColor ml-2"></i>
                                </a>
                            </ng-container>
                        </td>
                        <td [matTooltip]="data?.templateName || '-'"
                            (dblclick)="onRowDoubleClickV1(data?.templateName || '-')">
                            {{ data?.templateName }}
                        </td>
                        <td [matTooltip]="data?.createdByName || '-'"
                            (dblclick)="onRowDoubleClickV1(data?.createdByName || '-')">{{
                            data?.createdByName || '-' }}</td>

                        <td [matTooltip]="data?.creationTime"
                            (dblclick)="onRowDoubleClickV1(data?.creationTime)" class="text-center">
                            {{ data?.creationTime }}
                        </td>
                    </tr>
                </tbody>
                <tfoot>
                    <tr>
                        <th><mat-icon>apps</mat-icon></th>
                        <th>
                            <input matInput class="textbox-border-class" type="text" placeholder="Template Name"
                                name="search-TemplateName" />
                        </th>
                        <th>
                            <input matInput class="textbox-border-class" type="text" placeholder="Created By"
                                name="search-Created By" />
                        </th>
                        <th class="text-center">
                            <input matInput class="textbox-border-class text-center" type="text" placeholder="Created Date"
                                name="search-Created Date" />
                        </th>
                    </tr>
                </tfoot>
            </table>
        </div>
    </div>
</div>