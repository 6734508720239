import { VerificationStatus, VerificationStatusV1 } from './../patient-proxy/patient/enumeration-data/verification-status.enum';
import { ListService, PagedAndSortedResultRequestDto } from '@abp/ng.core';
import { DatePipe } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import {
  Component,
  EventEmitter,
  HostListener,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
// import { defaultGuid } from 'projects/admin/src/app/document-perviewer/document-perviewer.component';
import { defaultCountry, defaultGuid } from 'projects/shared/src/app/enums/allenums.enum';
import { TextValidators } from 'projects/shared/src/app/validations/text-validator';
import { forkJoin, Observable, Subscription } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import Swal from 'sweetalert2';
import {
  RegionCitiesDTO,
  RegionCountriesDTO,
  RegionStatesDTO,
} from '../dropdown-proxy/dropdown-management/dropdowns/dto';
import {
  GenderDTO,
  InsuranceTypeDTO,
  PatientMasterDropdownDTO,
  PayorLevelDTO,
} from '../dropdown-proxy/dropdown-management/dropdowns/dto/patient';
import { PatientMasterDropdownService } from '../dropdown-proxy/dropdown-management/dropdowns/master-dropdown-service';
import { PatientDropdowns } from '../dropdown-proxy/dropdown-management/dropdowns/patient-dropdowns.enum';
import { RegionDropdownService } from '../dropdown-proxy/dropdown-management/dropdowns/region-dropdown-service';
import { RelationShipWithSelfDTO } from '../dropdown-proxy/rcm/dropdown-management/dropdowns/dto/patient';
import { PatientAddPolicyComponent } from '../patient-add-policy/patient-add-policy.component';
import { PatientEligibilityListTabComponent } from '../patient-eligibility-list-tab/patient-eligibility-list-tab.component';
import { PatientManualVerificationTabComponent } from '../patient-manual-verification-tab/patient-manual-verification-tab.component';
import { PayerService, PverifyPayersService } from '../patient-proxy/patient';
import {
  AddressService,
  PatientPersonalService,
  PatientPolicyService,
} from '../patient-proxy/patient-optimization';
import {
  CreateUpdatePatientPolicyDTO,
  PatientPersonalDTO,
  PatientPolicyDTO,
  VerificationData,
  GetNPI_TaxId,
  PatientNewVerificationDTO,
} from '../patient-proxy/patient-optimization/dto';
import { PayerDTO, PverifyPayersDTO } from '../patient-proxy/patient/dto';
import {
  AddressType,
  InsuranceType,
} from '../patient-proxy/patient/enumeration-data';
import { PatientPverifyTabComponent } from '../patient-pverify-tab/patient-pverify-tab.component';
import { ToastrService } from 'ngx-toastr';
import { PayerDetailService } from '../patient-proxy/patient/payer-detail.service';
import { CommunicationService } from 'projects/shared/src/app/services/communication.service';
import { noWhitespaceValidator } from '../add-note-tab/add-note-tab.component';
import { PatientAddPolicyTabComponent } from '../patient-add-policy-tab/patient-add-policy-tab.component';

@Component({
  selector: 'app-ondemand-online-verification',
  templateUrl: './ondemand-online-verification.component.html',
  styleUrls: ['./ondemand-online-verification.component.scss']
})
export class OndemandOnlineVerificationComponent implements OnInit {
  policyModalTitle: string = '';
  sLoginUserName: string = '';
  payorLevel: PayorLevelDTO[] = [];
  drpGenderLoop: GenderDTO[] = [];
  drpInsurance: InsuranceTypeDTO[] = [];
  policyForm: FormGroup;
  PlanForm:FormGroup;
  drpRelationShip: RelationShipWithSelfDTO[] = [];
  selectedNode:any = null;
  drpCountry: RegionCountriesDTO[] = [];
  drpStates: RegionStatesDTO[] = [];
  drpCities: RegionCitiesDTO[] = [];
  BirthdateMaxDate: Date;
  tblPolicesReponse: any[] = [];
  phoneCode: string = '';
  lstZirmedPayor: customPayerDTO[] = [];
  lstPverifyPayors: customPverifyPayersDTO[] = [];
  BirthdateMinDate: Date;
  saveButtonHide: boolean=false;
  isShowSpinner: boolean = false;
  isShownButton: boolean = true;
  isShownEligibilityButtton: boolean = true;
  statusType: string = '';
  lastChecked: string = '';
  checkedBy: string = '';
  verifiedType: string = '';
  method: string = '';
  sPolicyAddedName: string = '';
  gender: string = '';
  pVerifyPayor: string = '';
  selectPayor: any;
  selectPayorList: any;
  zirmedPayor: string = '';
  payorlevel: string = '';
  insuredCountry: string = '';
  insuredState: string = '';
  insuredCity: string = '';
  payorId: any;
  payerId:any;
  EligibilityPayor: any;
  payorName: any;
  eligibilityMode: any;
  isEligibility: boolean = false;
  subscription$: Subscription[] = [];
  skipDays:number=0;
  lstVerificationStatus=VerificationStatus;
  lstVerificationSttausV1=VerificationStatusV1;
  // GetNPI_TaxId:GetNPI_TaxId[] =[];
  npi: any = '';
  mmTaxId: any = '';
  isGroupNoNull:boolean;
  lastVerificationData: VerificationData = null;
  public filteredSelectPayers: Observable<string[]> | undefined;
  public filteredPverifypayers: Observable<PverifyPayersDTO[]> | undefined;
  public filteredZirmedPayers: Observable<PayerDTO[]> | undefined;
  public filteredInsuredCountries: Observable<RegionCountriesDTO[]> | undefined;
  public filteredInsuredStates: Observable<RegionStatesDTO[]> | undefined;
  public filteredInsuredCities: Observable<RegionCitiesDTO[]> | undefined;
  tenantId: string = defaultGuid;
  policyIsActiveStatus: boolean = true;
  notesText="";
  saveBtn: number;
  GenderControl = new FormControl();
  drpGendertype: any;
  defaultFaxId:string;
  constructor(
    public patientPersonalService: PatientPersonalService,
    public PatientAddressService: AddressService,
    public dialog: MatDialog,
    private formBuilder: FormBuilder,
    private textValidators: TextValidators,
    private communicationService: CommunicationService,
    private payerService: PayerService,
    private planService: PverifyPayersService,
    private region: RegionDropdownService,
    public policyDialogRef: MatDialogRef<PatientAddPolicyComponent>,
    private patientPolicyService: PatientPolicyService,
    private toastr: ToastrService,
    private patientDropdownService: PatientMasterDropdownService,
    private payerDetailService: PayerDetailService,
    @Inject(MAT_DIALOG_DATA)
    public data: { patientId: string; policyId?: string; wipId?: string, documentId?: string, selectedNode?: string,isNewVerify:boolean
    ,verificationId?:string,verificationStatus?:string } = {
      patientId: defaultGuid,
      policyId: defaultGuid,
      wipId: defaultGuid,
      documentId:null,
      selectedNode:null,
      isNewVerify:false,
      verificationId:defaultGuid,
      verificationStatus:null
    }
  ) {}

  ngOnInit(): void {
    this.initializationPlan();
    this.initializationPatientDetails();
    this.getPolicyDropdown();
    this.countryDropdown();
    this.getNPI();
    this.copyPatient();
    this.saveButtonShow('');
    //Gender DropDowm Serch
    this.GenderControl.valueChanges.subscribe((FilterText) => {
      FilterText = FilterText.toLowerCase();
      this.drpGenderLoop = this.drpGendertype.filter((dx) =>
        dx.genderName.toLowerCase().includes(FilterText)
      );
    });
   this.isEligibility=true;
  }
  ngOnDestroy(): void {
    this.subscription$?.forEach((sub) => {
      sub && sub?.unsubscribe();
    });
  }
  initializationPatientDetails(){
    this.policyForm = this.formBuilder.group({
      chkCopypatientinfo: new FormControl(0),
      txtInsuredSsn: new FormControl(''),
      txtInsuredPostalCode: new FormControl(''),
      txtInsuredPhone: new FormControl(''),
      txtInsuredMobile: new FormControl(''),
      txtInsuredCity: new FormControl(null),
      txtInsuredState: new FormControl(null),
      txtInsuredCountry: new FormControl(''),
      txtInsuredCountryFilter: new FormControl(''),
      txtInsuredStateFilter: new FormControl(''),
      txtInsuredCityFilter: new FormControl(''),
      txtInsuredLastName: new FormControl('', [
        Validators.required,
        this.textValidators.isTextCheck,
      ]),
      txtInsuredFirst: new FormControl('', [
        Validators.required,
        this.textValidators.isTextCheck,
      ]),
      txtInsuredMiddle: new FormControl('', [this.textValidators.isTextCheck]),
      txtAddress: new FormControl(''),
      drpGender: new FormControl(null),
      txtInsuredBirthDate: new FormControl(''),
      txtPolicyInfoEmployerdId: new FormControl(''),
      drpRelationship: new FormControl('018', Validators.required),
    });
  }
  initializationPlan(){
    this.PlanForm = this.formBuilder.group({
      txtPlan: new FormControl('',[Validators.required]),
      selectEligibility:new FormControl('',[Validators.required]),
      txtPlanName: new FormControl('', [Validators.required,noWhitespaceValidator]),
      txtPolicy: new FormControl('',[Validators.required,noWhitespaceValidator,]),
      txtGroup: new FormControl(''),
      txtComment: new FormControl(null),
      chkSkipVerification:new FormControl(false),
    });
  }
  resetPlan(){
    this.PlanForm.patchValue({
      txtPlanName: '',
      txtPlanPhone: '',
      txtPolicy: '',
      txtGroup: '',
      txtEffective: '',
      txtType: '',
      txtstart:'',
      txtEnd: '',
      chkSkipVerification:false,
    });
    // this.PlanForm.reset();
  }
  loadPlanForm(){
    this.resetPlan();
     const getPlanDetails = this.patientPersonalService
       .getPatientPlanDetail(this.data.patientId,this.PlanForm.value.txtPlan)
       .subscribe(
         (response) => {
           this.PlanForm.patchValue({
             txtPlan:this.PlanForm.value.txtPlan,
             selectEligibility:response?.eligibilityMode,
             txtPlanName: response?.payorId+'/'+response?.payorName??'' ,
             txtPolicy: response?.policyNo??'',
             txtGroup:  response?.groupNo??null,
             chkSkipVerification:response?.isSkip??false,
             txtComment:response?.note,
           });
           if(this.PlanForm.value.txtGroup===null){
            this.isGroupNoNull=true;
           }else{
             this.isGroupNoNull=false;
           }
           this.data.policyId=response?.policyId;
           this.skipDays=response?.isSkip??0
           if(this.data.policyId !==defaultGuid){
            this.getVerificationStatus();
            }
         },
         (err) => {
           this.lastVerificationData=null;
           const data: HttpErrorResponse = err;
           Swal.fire({
             icon: 'info',
             text: data?.error?.error?.message,
           });
         },
       );
     this.subscription$.push(getPlanDetails);
   }
  // Country Dropdown
  countryDropdown() {
    this.drpCountry = [];
    const countryDropdownSubscription = this.region
      .getAllCountriesByInput(new PagedAndSortedResultRequestDto())
      .subscribe(
        (response) => {
          this.drpCountry = response?.items ?? [];

          this.filteredInsuredCountries = this.policyForm
            .get('txtInsuredCountryFilter')
            ?.valueChanges.pipe(
              startWith(''),
              map((value) =>
                this.drpCountry.filter((option) =>
                  option?.countryName
                    ?.toLowerCase()
                    ?.includes(value?.toLowerCase() ?? '')
                )
              )
            );
        },
        (err) => {
          const data: HttpErrorResponse = err;
          Swal.fire({
            icon: 'info',
            text: data?.error?.error?.message,
          });
        }
      );
    this.subscription$.push(countryDropdownSubscription);
  }

  // state city
  onChangeCountry(value: string | null | undefined): boolean {
    this.policyForm.patchValue({
      txtInsuredCity: null,
      txtInsuredState: null,
    });
    this.drpStates = [];
    if (value !== null && value !== undefined) {
      const stateSubscription = this.region
        .getAllStatesByCountryNameByCountryShortName(value)
        .subscribe(
          (response) => {
            this.drpStates = response?.items ?? [];
            if (this.drpCountry) {
              this.phoneCode = this.drpCountry?.filter(
                (x) => x?.countryShortName === value
              )?.[0]?.countryPhoneCode;
            } else {
              this.phoneCode = '';
            }
            this.filteredInsuredStates = this.policyForm
              .get('txtInsuredStateFilter')
              ?.valueChanges.pipe(
                startWith(''),
                map((value) =>
                  this.drpStates.filter((option) =>
                    option?.stateName
                      ?.toLowerCase()
                      ?.includes(value?.toLowerCase() ?? '')
                  )
                )
              );
          },
          (err) => {
            const data: HttpErrorResponse = err;
            Swal.fire({
              icon: 'info',
              text: data?.error?.error?.message,
            });
          }
        );
      this.subscription$.push(stateSubscription);
    }
    return this.drpStates && this.drpStates?.length !== 0;
  }
  onChangeState(state: string = '', country: string = ''): boolean {
    this.drpCities = [];
    this.policyForm.patchValue({
      txtInsuredCity: null,
    });
    country =
      country == ''
        ? this.policyForm.value.txtInsuredCountry == (null || undefined)
          ? ''
          : this.policyForm.value.txtInsuredCountry
        : country;
    if (state !== null && state !== undefined) {
      const stateSubscription = this.region
        .getAllCitiesByCountryAndStateNameByCountryShortNameAndStateShortName(
          country,
          state
        )
        .subscribe(
          (response) => {
            this.drpCities = response?.items ?? [];
            this.filteredInsuredCities = this.policyForm
              .get('txtInsuredCityFilter')
              ?.valueChanges.pipe(
                startWith(''),
                map((value) =>
                  this.drpCities.filter((option) =>
                    option?.cityName
                      ?.toLowerCase()
                      ?.includes(value?.toLowerCase() ?? '')
                  )
                )
              );
          },
          (err) => {
            const data: HttpErrorResponse = err;
            Swal.fire({
              icon: 'info',
              text: data?.error?.error?.message,
            });
          }
        );
      this.subscription$.push(stateSubscription);
    }
    return this.drpCities && this.drpCities?.length !== 0;
  }

  getPolicyDropdown() {
    const patientDropdownInput: PatientDropdowns[] = [
      PatientDropdowns.PayorLevels,
      PatientDropdowns.RelationShipWithSelf,
      PatientDropdowns.Genders,
    ];
    const patientDropdownSubscription = this.patientDropdownService
      .getPatientDropdownsByInput(patientDropdownInput)
      .subscribe(
        (stateResponse) => {
          const response: PatientMasterDropdownDTO = stateResponse;
          this.payorLevel = response?.payorLevels ?? [];
          this.drpRelationShip = response?.relationShipWithSelves ?? [];
          this.drpGenderLoop = response?.genders ?? [];
          this.drpGendertype = this.drpGenderLoop;
          // this.GetPayorDropdown();
        },
        (err) => {
          const data: HttpErrorResponse = err;
          Swal.fire({
            icon: 'info',
            text: data?.error?.error?.message,
          });
        }
      );
  this.subscription$.push(patientDropdownSubscription);
  // !this.isEmpty(this.data.policyId)  && this.getVerificationStatus();
  }
  //! get last verification status by policy id
  getVerificationStatus() {
    // this.isEligibility = true;
    const lastVerificationDataSub = this.patientPolicyService
      .getLastVerificationDataByPolicyId(this.data.policyId)
      .subscribe(
        (response) => {
          this.lastVerificationData = response ?? null;
          if(this.lastVerificationData !== null){
            this.saveButtonShow(this.lastVerificationData.verificationStatus);
            }else{
              this.saveButtonShow('');
            }
        },
        (err) => {
          const data: HttpErrorResponse = err;
          Swal.fire({
            icon: 'info',
            text: data?.error?.error?.message,
          });
        }
      );
    this.subscription$.push(lastVerificationDataSub);

  }

  getNPI() {
    const NPI_TaxID = this.patientPersonalService
      .getNPIList(this.data.patientId)
      .subscribe(
        (response) => {
          // Perform a null check on the response
          const GetNPI_TaxId: GetNPI_TaxId = response ?? null;

          // If response is not null, extract the properties
          if (GetNPI_TaxId) {
            this.mmTaxId = GetNPI_TaxId.mmTaxId;
            this.npi = GetNPI_TaxId.npi;
          } else {
            this.mmTaxId = ' - ';
            this.npi = ' - ';
          }
        },
        (err) => {
          // Handle error and display message
          //const data: HttpErrorResponse = err;
          // Swal.fire({
          //   icon: 'info',
          //   text: data?.error?.error?.message,
          // });

          this.mmTaxId = ' - ';
          this.npi = ' - ';
        }
      );
  }
  saveVerification(method:number,isSkip:boolean){
    const plan = this.PlanForm.value;
    let inputVerification: PatientNewVerificationDTO;
    inputVerification={
      patientId: this.data.patientId,
      policyId: this.data.policyId,
      method: method,
      // status:
      policyNo:plan.txtPolicy ,
      groupNo:plan.groupNo  ,
      plan:plan.txtPlan ,
      notes:plan.txtComment,
      skipPrevious:isSkip,
    }
    this.patientPersonalService
    .patientNewVerificationSave(inputVerification)
    .subscribe(
      (response) => {
        this.toastr.success('Save Successfully', 'Success');
        this.dialog.closeAll();
      },
      (err) => {
        if(err.status===200){
          this.toastr.success('Save Successfully', 'Success');
          this.dialog.closeAll();
        }else{
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
       }
      }
    );
  }
   //To View The Policy
   EditPolicy(policy: any) {

    this.defaultFaxId = policy?.defaultFaxId;
    const dialogRef = this.dialog.open(PatientAddPolicyTabComponent, {
      data: {
        patientId: this.data.patientId,
        policyId: defaultGuid,
        documentId: this.data.documentId,
        wipId: policy.wipId ?? defaultGuid,
        isNewVerify:true,
        verificationId:this.data.verificationId,
        defaultFaxId:policy?.defaultFaxId
        // selectedNode: this.selectedNode,
      },
      disableClose: true
    });
    const aftClosed = dialogRef.afterClosed().subscribe(
      (result) => {
        //  this.loadPlanForm();
        this.resetPlan();
        //  this.getVerificationStatus();
        this.PlanForm.patchValue({
          txtPlan:'',
        });
        this.lastVerificationData=null;
      },
      (err) => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      }
    );
    this.subscription$.push(aftClosed);
  }
  openEligibilityHistoryModal() {
    const dialogRef = this.dialog.open(PatientEligibilityListTabComponent, {
      disableClose: true,
      data: { patientId: this.data.patientId, policyId: this.data.policyId ,verificationStatus:this.data.verificationStatus},
    });
    const closeDialog = dialogRef.afterClosed().subscribe((result) => {
      (result ?? '') !== '' && this.policyDialogRef.close(result);

      this.data.policyId !== defaultGuid && this.getVerificationStatus();
    });
    this.subscription$.push(closeDialog);
  }
  openVerificationModal() {
    const dialogRef = this.dialog.open(PatientManualVerificationTabComponent, {
      disableClose: true,
      data: { patientId: this.data.patientId, policyId: this.data.policyId , documentId :this.data.documentId, selectedNode:this.selectedNode,
        verificationId:this.data.verificationId,verificationStatus:this.data.verificationStatus},
    });

    const closeDialog = dialogRef.afterClosed().subscribe((result) => {
      this.loadPlanForm();
      if(result == 'Saved'){
        this.policyDialogRef.close(result);
      }

      result !== '' &&
        result !== null &&
        result !== undefined &&
        this.policyDialogRef.close(result);

      this.data.policyId !== defaultGuid && this.getVerificationStatus();
    });
    this.subscription$.push(closeDialog);
  }
  openPverifyModal() {
    const dialogRef = this.dialog.open(PatientPverifyTabComponent, {
      disableClose: true,
      data: { patientId: this.data.patientId, documentId :this.data.documentId,policyId: this.data.policyId },
    });

    const closeDialog = dialogRef.afterClosed().subscribe(() => {
      this.data.policyId !== defaultGuid && this.getVerificationStatus();
    });
    this.subscription$.push(closeDialog);
  }

  // only number and alpha
  numberAndAlphaOnly(event): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    const inp = String.fromCharCode(event.keyCode);
    if (
      charCode > 31 &&
      (charCode < 48 || charCode > 57) &&
      charCode >= 15 &&
      charCode <= 64
    ) {
      return false;
    }
    return true;
  }
  clearPatientDetails(){
    this.policyForm.patchValue({
      txtInsuredFirst: '',
      txtInsuredMiddle: '',
      txtInsuredLastName: '',
      // txtInsuredSuffix: '',
      txtInsuredSsn: '',
      txtInsuredBirthDate: '',
      drpGender: '',
      drpRelationship: '',
      txtAddress:'',
      txtInsuredCountry: '',
      txtInsuredState: '',
      txtInsuredCity: '',
      txtInsuredPostalCode:'',
      txtInsuredPhone: '',
    });
  }

  // Copy Patient Info when clicking checkbox
  copyPatient() {
    if (this.data.patientId !== '') {
      //To path the Personal Details
      const personal = this.patientPersonalService
        .get(this.data.patientId)
        .subscribe(
          (response) => {
            const patientPersonalDetails: PatientPersonalDTO = response ?? null;
            patientPersonalDetails !== null &&
              this.policyForm.patchValue({
                txtInsuredFirst: patientPersonalDetails?.firstName,
                txtInsuredMiddle: patientPersonalDetails?.middleName,
                txtInsuredLastName: patientPersonalDetails?.lastName,
                txtInsuredSuffix: patientPersonalDetails?.suffix,
                txtInsuredSsn: patientPersonalDetails?.ssn,
                txtInsuredBirthDate: patientPersonalDetails?.dateOfBirth ?? '',
                drpGender: patientPersonalDetails?.gender,
                drpRelationship:'018',
              });
          },
          (err) => {
            const data: HttpErrorResponse = err;
            Swal.fire({
              icon: 'info',
              text: data?.error?.error?.message,
            });
          }
        );
      this.subscription$.push(personal);

      //To patch the address details
      const address =
        this.PatientAddressService.getAddressByPatientIdByPatientId(
          this.data.patientId
        ).subscribe(
          (response) => {

            this.onChangeCountry(response[0]?.country??defaultCountry.US);
            this.onChangeState(
              response?.[0]?.state,
              response?.[0]?.country
                );

              this.policyForm.patchValue({
              txtAddress: response?.[0]?.address,
              txtInsuredCountry: response?.[0]?.country ??  defaultCountry.US,
              txtInsuredState: response?.[0]?.state,
              txtInsuredCity: response?.[0]?.city,
              txtInsuredPostalCode:
              response?.[0]?.postalCode,
              txtInsuredPhone: response?.[0]?.phone,
            });
          },
          (err) => {
            const data: HttpErrorResponse = err;
            Swal.fire({
              icon: 'info',
              text: data?.error?.error?.message,
            });
          }
        );
      this.subscription$.push(address);

    }
  }
  clearRelationship() {
    this.policyForm.patchValue({
      drpRelationship: '',
    });
  }
  isEmpty(value: any): boolean {
    return (
      value === null ||
      value === undefined ||
      value === '' ||
      value === defaultGuid
    );
  }
  //Update Character Count for TextArea
  updateCharacterCount(maxlength: number) {
    // Ensure the text length does not exceed the maximum length
    if (this.notesText?.length > maxlength) {
      this.notesText = this.notesText?.substr(0, maxlength);
    }
  }
  saveButtonShow(verificationStatus:any){
    if(verificationStatus===this.lstVerificationStatus.done||
      verificationStatus===this.lstVerificationSttausV1.done||
      verificationStatus===this.lstVerificationStatus.unabletoverify||
      verificationStatus===this.lstVerificationSttausV1.unabletoverify){
      if(this.skipDays>=30){
      this.saveBtn=1;
      } else{
      this.saveBtn=2;
      }
    }else{
      this.saveBtn=3;
    }
  }
  viewFaxDocument(){
    const url = '/#/patient_preview_tiff_document/' + this.defaultFaxId + '/' + this.data.documentId;
    window.open(url, '_blank');
  }
}
export interface customPverifyPayersDTO extends PverifyPayersDTO {
  payerNameCode?: string;
}
export interface customPayerDTO extends PayerDTO {
  payerNameCode?: string;
}
