<br />
<form [formGroup]="saleOrderForm">
  <div class="card card-body">
    <div class="col-lg-12">
      <h4 class="customThemeClass mar-top"><b>Order Details</b></h4>

      <div class="row">
        <!--Scheduled Date-->
        <mat-form-field class="col-3 align">
          <mat-label>Scheduled Date <span class="asterisk">*</span> </mat-label>
          <input
            maxlength="40"
            formControlName="txtScheduledDate"
            matInput
            autocomplete="new-txtScheduledDate"
            [matDatepicker]="txtScheduledDate"
            placeholder="(MM/DD/YYYY)"
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="txtScheduledDate"
          ></mat-datepicker-toggle>
          <mat-datepicker #txtScheduledDate></mat-datepicker>
          <mat-error
            *ngIf="
              saleOrderForm?.get('txtScheduledDate')?.touched &&
              saleOrderForm?.get('txtScheduledDate')?.errors
            "
          >
            Enter Valid Date!
          </mat-error>
          <mat-error
            *ngIf="
              scheduledDate >= saleOrderForm?.get('txtScheduledDate')?.value &&
              !(
                saleOrderForm?.get('txtScheduledDate')?.touched &&
                saleOrderForm?.get('txtScheduledDate')?.errors?.required
              )
            "
          >
            Enter Future Dates Only!
          </mat-error>
          <mat-error
            *ngIf="
              saleOrderForm?.get('Delivery.txtScheduledDate')?.touched &&
              saleOrderForm?.get('Delivery.txtScheduledDate')?.errors?.required
            "
          >
            Scheduled Date is a required field!
          </mat-error>
        </mat-form-field>

        <!--Scheduled Time-->
        <div class="col-3 time">
          <mat-label> Scheduled Time</mat-label>
          <div class="ngx-timepicker-field-example">
            <ngx-timepicker-field
              [disabled]="!editFormValues"
              formControlName="txtScheduledTime"
            ></ngx-timepicker-field>
          </div>
        </div>

        <!--Actual Date-->
        <mat-form-field class="col-3 align">
          <mat-label>Actual Date <span class="asterisk">* </span></mat-label>
          <input
            maxlength="40"
            formControlName="txtActualDate"
            matInput
            autocomplete="new-txtActualDate"
            [matDatepicker]="txtActualDate"
            placeholder="(MM/DD/YYYY)"
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="txtActualDate"
          ></mat-datepicker-toggle>
          <mat-datepicker #txtActualDate></mat-datepicker>
          <mat-error
            *ngIf="
              saleOrderForm?.get('txtActualDate')?.touched &&
              saleOrderForm?.get('txtActualDate')?.errors
            "
          >
            Enter Valid Date!
          </mat-error>
          <mat-error
            *ngIf="
              scheduledDate >=
                saleOrderForm?.get('Delivery.txtActualDate')?.value &&
              !(
                saleOrderForm?.get('Delivery.txtActualDate')?.touched &&
                saleOrderForm?.get('Delivery.txtActualDate')?.errors?.required
              )
            "
          >
            Enter Future Dates Only!
          </mat-error>
          <mat-error
            *ngIf="
              saleOrderForm?.get('Delivery.txtActualDate')?.touched &&
              saleOrderForm?.get('Delivery.txtActualDate')?.errors?.required
            "
          >
            Actual Date is a required field!
          </mat-error>
        </mat-form-field>

        <!--Actual Time-->
        <div class="col-3 pb-1 time">
          <mat-label> Actual Time</mat-label>
          <div class="ngx-timepicker-field-example">
            <ngx-timepicker-field
              [disabled]="!editFormValues"
              formControlName="txtActualTime"
            ></ngx-timepicker-field>
          </div>
        </div>

        <!--Address-->
        <mat-form-field class="col-3">
          <mat-label>Address <span class="asterisk">* </span> </mat-label>
          <textarea
            autocomplete="new-txtAddress"
            maxlength="100"
            formControlName="txtAddress"
            rows="3"
            matInput
          ></textarea>
          <mat-error
            *ngIf="
              saleOrderForm?.get('txtAddress')?.touched &&
              saleOrderForm?.get('txtAddress')?.errors?.required
            "
          >
            Address is a required field!
          </mat-error>
        </mat-form-field>

        <!--Country-->
        <mat-form-field class="col-3 align">
          <mat-label>Country</mat-label>
          <mat-select
            formControlName="drpCountry"
            [(ngModel)]="country"
            (selectionChange)="loadState($event.value)"
            #singleSelect
          >
            <mat-option>
              <ngx-mat-select-search
                placeholderLabel="Search"
                noEntriesFoundLabel="No Matches found"
                formControlName="txtCountryFilter"
              ></ngx-mat-select-search>
            </mat-option>
            <mat-option
              *ngFor="let country of filteredCountries | async"
              [value]="country.countryShortName"
            >
              {{ country.countryName }}
            </mat-option>
          </mat-select>
          <button
            mat-button
            *ngIf="country"
            (click)="clearCountry()"
            (click)="country = ''; $event.stopPropagation()"
            matSuffix
            mat-icon-button
          >
            <mat-icon>close</mat-icon>
          </button>
        </mat-form-field>

        <!--State-->
        <mat-form-field class="col-3 align">
          <mat-label>State<span class="asterisk">* </span></mat-label>
          <mat-select
            formControlName="drpState"
            (selectionChange)="loadCity($event.value, '')"
            [(value)]="state"
            [(ngModel)]="state"
            #singleSelect
          >
            <mat-option>
              <ngx-mat-select-search
                placeholderLabel="Search"
                noEntriesFoundLabel="No Matches found"
                formControlName="txtStateFilter"
              ></ngx-mat-select-search>
            </mat-option>
            <mat-option
              *ngFor="let state of filteredStates | async"
              [value]="state.stateShortName"
            >
              {{ state.stateName }}
            </mat-option>
          </mat-select>
          <button
            mat-button
            (click)="clearState()"
            (click)="state = ''; $event.stopPropagation()"
            *ngIf="state"
            matSuffix
            mat-icon-button
          >
            <mat-icon>close</mat-icon>
          </button>
          <mat-error
            *ngIf="
              saleOrderForm?.get('drpState')?.touched &&
              saleOrderForm?.get('drpState')?.errors?.required
            "
          >
            State is a required field!
          </mat-error>
        </mat-form-field>

        <!--County-->
        <mat-form-field class="col-3 align">
          <mat-label> County</mat-label>
          <!-- <span class="asterisk">* </span> -->
          <input
            formControlName="drpCounty"
            matInput
            autocomplete="new-drpCounty"
            maxlength="40"
            type="text"
          />
          <mat-error
            *ngIf="
              saleOrderForm?.get('drpCounty')?.touched &&
              saleOrderForm?.get('drpCounty')?.errors?.required
            "
          >
            County is a required field!
          </mat-error>
          <mat-error
            *ngIf="
              saleOrderForm?.get('drpCounty')?.touched &&
              saleOrderForm?.get('drpCounty')?.errors?.sAlphabets
            "
          >
            Enter only Alphabets!
          </mat-error>
        </mat-form-field>

        <!--City-->
        <mat-form-field class="col-3">
          <mat-label>City<span class="asterisk">* </span></mat-label>
          <mat-select
            formControlName="drpCity"
            [(ngModel)]="city"
            #singleSelect
          >
            <mat-option>
              <ngx-mat-select-search
                placeholderLabel="Search"
                noEntriesFoundLabel="No Matches found"
                formControlName="txtCityFilter"
              ></ngx-mat-select-search>
            </mat-option>
            <mat-option
              *ngFor="let city of filteredCities | async"
              [value]="city.cityName"
            >
              {{ city.cityName }}
            </mat-option>
          </mat-select>
          <button
            mat-button
            (click)="clearCity()"
            (click)="city = ''; $event.stopPropagation()"
            *ngIf="city"
            matSuffix
            mat-icon-button
          >
            <mat-icon>close</mat-icon>
          </button>
          <mat-error
            *ngIf="
              saleOrderForm?.get('drpCity')?.touched &&
              saleOrderForm?.get('drpCity')?.errors?.required
            "
          >
            City is a required field!
          </mat-error>
        </mat-form-field>

        <!--Zip Code-->
        <mat-form-field class="col-3">
          <mat-label>Zip Code<span class="asterisk">* </span></mat-label>
          <input
            maxlength="15"
            autocomplete="new-txtPostalCode"
            formControlName="txtPostalCode"
            pattern="[0-9-]*"
            matInput
            type="text"
          />
          <mat-error
            *ngIf="
              saleOrderForm?.get('txtPostalCode')?.touched &&
              saleOrderForm?.get('txtPostalCode')?.errors?.required
            "
          >
            Zip Code is a required field!
          </mat-error>
          <mat-error
            *ngIf="
              saleOrderForm?.get('txtPostalCode')?.touched &&
              saleOrderForm?.get('txtPostalCode')?.errors?.pattern
            "
          >
            Enter only Numbers!
          </mat-error>
        </mat-form-field>

        <!--Phone-->
        <mat-form-field class="col-3">
          <mat-label>Phone</mat-label>
          <input
            maxlength="40"
            formControlName="txtPhone"
            prefix="{{ phoneCode }}-"
            mask="(000)-000-0000"
            matInput
            autocomplete="new-txtPhone"
            type="text"
          />
          <mat-error
            *ngIf="
              saleOrderForm?.get('txtPhone')?.touched &&
              saleOrderForm?.get('txtPhone')?.errors
            "
          >
            Enter Minimum 10 Digits!
          </mat-error>
        </mat-form-field>

        <!--Mobile-->
        <mat-form-field class="col-3">
          <mat-label>Mobile </mat-label>
          <input
            matInput
            prefix="{{ phoneCode }}-"
            mask="(000)-000-0000"
            maxlength="40"
            autocomplete="new-txtMobile"
            placeholder="Enter Phone Number"
            formControlName="txtMobile"
            type="text"
          />
          <mat-error
            *ngIf="
              saleOrderForm?.get('txtMobile')?.touched &&
              saleOrderForm?.get('txtMobile')?.errors
            "
          >
            Enter Minimum 10 Digits!
          </mat-error>
        </mat-form-field>

        <!--Order Notes-->
        <mat-form-field class="col-3">
          <mat-label>Order Note</mat-label>
          <textarea
            maxlength="250"
            rows="1"
            autocomplete="new-txtOrderNote"
            formControlName="txtOrderNote"
            matInput
          ></textarea>
        </mat-form-field>

        <!--Delivery Notes-->
        <mat-form-field class="col-3">
          <mat-label>Delivery Note</mat-label>
          <textarea
            autocomplete="new-txtDeliveryNote"
            maxlength="250"
            rows="1"
            formControlName="txtDeliveryNote"
            matInput
          ></textarea>
        </mat-form-field>

        <!--Status-->
        <mat-form-field class="col-md-3">
          <mat-label>Status</mat-label>
          <mat-select
            maxlength="40"
            formControlName="txtStatus"
            [(value)]="status"
          >
            <mat-option
              *ngFor="let complaince of drpComplaince"
              [value]="complaince.shortCodeId"
            >
              {{ complaince.statusType }}
            </mat-option>
          </mat-select>
          <button
            mat-button
            (click)="clearStatus()"
            (click)="status = ''; $event.stopPropagation()"
            *ngIf="status"
            matSuffix
            mat-icon-button
          >
            <mat-icon>close</mat-icon>
          </button>
        </mat-form-field>

        <!--Place of Service-->
        <mat-form-field class="col-3">
          <mat-label
            >Place Of Service <span class="asterisk">*</span></mat-label
          >
          <mat-select
            maxlength="40"
            formControlName="drpGeneralPlaceOfService"
            [(value)]="generalPlaceOfService"
          >
            <mat-option
              *ngFor="let pos of drpPlace"
              [value]="pos.placeOfServiceCode"
            >
              {{ pos.placeOfServiceType }}
            </mat-option>
          </mat-select>
          <button
            mat-button
            (click)="clearGeneralPlaceOfService()"
            (click)="generalPlaceOfService = ''; $event.stopPropagation()"
            *ngIf="generalPlaceOfService"
            matSuffix
            mat-icon-button
          >
            <mat-icon>close</mat-icon>
          </button>
          <mat-error
            *ngIf="
              saleOrderForm?.get('drpGeneralPlaceOfService')?.touched &&
              saleOrderForm?.get('drpGeneralPlaceOfService')?.errors?.required
            "
          >
            Place Of Service is a required field!
          </mat-error>
        </mat-form-field>

        <!--Primary Insurance-->
        <mat-form-field class="col-3 align">
          <mat-label>Primary Insurance</mat-label>
          <!-- <mat-select formControlName="drpPrimaryInsurance" #singleSelect>
            <mat-option>
              <ngx-mat-select-search
                placeholderLabel="Search"
                noEntriesFoundLabel="No Matches found"
                formControlName="txtPrimaryInsurance"
              ></ngx-mat-select-search>
            </mat-option>
          </mat-select> -->
          <input
            maxlength="40"
            readonly
            formControlName="txtPrimaryInsurance"
            matInput
            autocomplete="new-txtScheduledDate"
            placeholder="Primary Insurance"
          />
          <button
            (click)="viewVerificationById()"
            mat-button
            matSuffix
            mat-icon-button
          >
            <mat-icon>remove_red_eye</mat-icon>
          </button>
          <button
            (click)="openPverifyModal()"
            mat-button
            matSuffix
            mat-icon-button
          >
            <mat-icon>add_box</mat-icon>
          </button>
        </mat-form-field>

        <!--Secondary Insurance-->
        <mat-form-field class="col-3 align">
          <mat-label>Secondary Insurance</mat-label>
          <input
            readonly
            maxlength="40"
            formControlName="txtSecondaryInsurance"
            matInput
            placeholder="Secondary Insurance"
          />
          <button
            (click)="viewVerificationById()"
            mat-button
            matSuffix
            mat-icon-button
          >
            <mat-icon>remove_red_eye</mat-icon>
          </button>
          <button
            (click)="openPverifyModal()"
            mat-button
            matSuffix
            mat-icon-button
          >
            <mat-icon>add_box</mat-icon>
          </button>
        </mat-form-field>

        <!--Checklist-->
        <mat-form-field class="col-3 align">
          <mat-label>Checklist</mat-label>
          <input
            readonly
            maxlength="40"
            formControlName="txtChecklist"
            matInput
            placeholder="Checklist"
          />
          <button
            (click)="checkListModel()"
            mat-button
            matSuffix
            mat-icon-button
          >
            <mat-icon>remove_red_eye</mat-icon>
          </button>
          <button mat-button matSuffix mat-icon-button>
            <mat-icon>add_box</mat-icon>
          </button>
        </mat-form-field>

        <!--Authorization-->
        <mat-form-field class="col-3 align">
          <mat-label>Authorization</mat-label>
          <input
            maxlength="40"
            readonly
            formControlName="txtAuthorization"
            matInput
            placeholder="Authorization"
          />
          <button mat-button (click)="doprecert()" matSuffix mat-icon-button>
            <mat-icon>remove_red_eye</mat-icon>
          </button>
          <button (click)="viewprecert()" mat-button matSuffix mat-icon-button>
            <mat-icon>add_box</mat-icon>
          </button>
        </mat-form-field>
        <!--Comments to Patient -->
        <mat-form-field class="col-3 align">
          <textarea
            formControlName="txtPatientComments"
            rows="3"
            placeholder="Comments to Patient"
            maxlength="5000"
            matInput
          ></textarea>
        </mat-form-field>
        <!--Comments to Shipping -->
        <mat-form-field class="col-3 align">
          <textarea
            formControlName="txtShippingComments"
            rows="3"
            placeholder="Comments to Shipping"
            maxlength="5000"
            matInput
          ></textarea>
        </mat-form-field>

        <!--Comments to Billing -->
        <mat-form-field class="col-3 align">
          <textarea
            formControlName="txtBillingComments"
            rows="3"
            placeholder="Comments to Billing"
            maxlength="5000"
            matInput
          ></textarea>
        </mat-form-field>

        <!--Cash Payment-->
        <div class="col-md-3">
          <br />
          <mat-checkbox
            (click)="cashPaymentClick()"
            formControlName="chkCashPayment"
            >Cash Payment</mat-checkbox
          >
        </div>

        <!--Completed-->
        <!-- <div class="col-md-3">
              <br />
              <mat-checkbox [disabled]="isShowSpinner"
                (change)="orderCompleteConfirmation()"
                formControlName="chkOrderCompleted"
              >
                Completed
              </mat-checkbox>
            </div> -->
      </div>
    </div>
  </div>

  <div class="card card-body mt-2">
    <div class="row">
      <!--CSR-->
      <mat-form-field class="col-3 align">
        <mat-label>CSR</mat-label>
        <mat-select formControlName="drpCSR" #singleSelect>
          <mat-option>
            <ngx-mat-select-search
              placeholderLabel="Search"
              noEntriesFoundLabel="No Matches found"
              formControlName="txtCSRFilter"
            ></ngx-mat-select-search>
          </mat-option>
        </mat-select>
      </mat-form-field>

      <!--Location-->
      <mat-form-field class="col-3 align">
        <mat-label>Location</mat-label>
        <mat-select formControlName="drpLocation" #singleSelect>
          <mat-option>
            <ngx-mat-select-search
              placeholderLabel="Search"
              noEntriesFoundLabel="No Matches found"
              formControlName="txtLocationFilter"
            ></ngx-mat-select-search>
          </mat-option>
        </mat-select>
      </mat-form-field>

      <!-- PAP Therapy-->
      <mat-checkbox class="col-3 align" formControlName="chkPAPTherapy">
        <p class="text-wrap">
          PAP Therapy is beneficial and using equipment as prescribed
        </p>
      </mat-checkbox>

      <!--Shipping Method-->
      <mat-form-field class="col-3 align">
        <mat-label>Shipping Method</mat-label>
        <mat-select formControlName="drpShippingMethod" #singleSelect>
        </mat-select>
      </mat-form-field>

      <!--Ordering Provider-->
      <mat-form-field class="col-3 align">
        <mat-label>Ordering Provider</mat-label>
        <mat-select formControlName="drpOrderingProvider" #singleSelect>
        </mat-select>
      </mat-form-field>

      <!--Practice Management-->
      <mat-form-field class="col-3 align">
        <mat-label>Practice Management</mat-label>
        <mat-select formControlName="drpPracticeManagement" #singleSelect>
        </mat-select>
      </mat-form-field>

      <!--Preferred Brand-->
      <mat-form-field class="col-3 align">
        <mat-label>Preferred Brand</mat-label>
        <mat-select formControlName="drpPreferredBrand" #singleSelect>
        </mat-select>
      </mat-form-field>

      <!--Comments to Tech -->
      <mat-form-field class="col-3 align">
        <textarea
          formControlName="txtTechComments"
          rows="3"
          placeholder="Comments to Tech"
          maxlength="5000"
          matInput
        ></textarea>
      </mat-form-field>
    </div>
    <div class="row">
      <div class="col-sm-12">
        <div class="text-lg-center">
          <button
            mat-button
            (click)="saveOrUpdateOrder()"
            [disabled]="
              !editFormValues || saveButtonHide || saleOrderForm?.invalid
            "
            class="buttonColor"
          >
            Save

            <mat-icon *ngIf="isShowSpinner">
              <mat-spinner
                class="spinner-border spinner-border-sm"
                diameter="20"
              >
              </mat-spinner>
            </mat-icon>
          </button>
        </div>
      </div>
    </div>

    <div class="row mt-2" *ngIf="saveButtonHide">
      <div class="col-sm-4"></div>
      <div class="col-sm-4">
        <div class="text-lg-center" *ngIf="isShowSpinner">
          <div class="alert alert-success" role="alert">
            Please Wait,While Data is Saved!
          </div>
        </div>
      </div>
      <div class="col-sm-4"></div>
    </div>
  </div>
</form>
