<table id="tblcnmReportList" datatable [dtOptions]="dtCNMReportTableOptions"
    class="tbl hover row-border hover w-100 display">
    <thead>
        <tr>
            <th><mat-icon>apps</mat-icon></th>
            <th [matTooltip]="'Chart Id'">Chart Id</th>
            <th [matTooltip]="'Patient Name'">Patient Name</th>
            <th [matTooltip]="'DOB'">DOB</th>
            <th [matTooltip]="'Practice'">Practice</th>
            <th [matTooltip]="'RX Date'" class="text-center">RX Date</th>
            <th [matTooltip]="'CSR'">CSR</th>
            <th [matTooltip]="'Last Mask Date'">Last Mask Date</th>
            <th [matTooltip]="'Order Date'">Order Date</th>
            <th [matTooltip]="'RX Expiry Date'">RX Expiry Date</th>
            <th [matTooltip]="'Expiry Days'">Expiry Days</th>
            <th [matTooltip]="'Mail Status'">Mail Status</th>
            <th [matTooltip]="'Status'">Status</th>
        </tr>
    </thead>
    <tbody>
      <tr *ngFor="let data of cnmreportTableData">
        <td>
          <a [matTooltip]="'Open RX Email Print Letter'" [routerLink]="'/RxEmailPrintLetter'" target="_blank"  [class.disabled]="data.rxcheckBoxDisabled" >
            <img class="img-responsive pr-2 mb-2 cursor ml-1" src="assets/Claim Page Icons/upload.png" alt=""
              height="16" />
          </a>
          <a [matTooltip]="'Document Preview'" (click)="previewRXDocument()" target="_blank" class="preview-link">
            <img class="img-responsive pr-2 mb-2 cursor ml-1" src="assets/Claim Page Icons/DocSigned.png" alt="" height="17"/>
          </a>
    
        </td>
        <td [matTooltip]="data?.chartId || '-'">
          <a [class.disabled]="isEmpty(data.chartId) || isEmpty(data.patientId)" class="preview-link"
          [routerLink]="'/patientCreateEditTab/' + data.patientId"
          target="_blank">
          {{ data.chartId || "-" }}
        </a>
        </td>

        <td [matTooltip]="data?.patientName || '-'">
          {{data.patientName}}
        </td>
        <td [matTooltip]=" data?.dob ? (data?.dob | date : 'MM/dd/yyyy' : 'en_US'): '-'">
          {{ data.dob | date : "MM/dd/yyyy" }}
        </td>
        <td [matTooltip]="data?.practitionerName || '-'">
          {{data.practitionerName}}
        </td>
        <td [matTooltip]="
          data?.rxDate
          ? (data?.rxDate | date : 'MM/dd/yyyy' : 'en_US')
          : '-'
             
          ">
          {{ data.rxDate | date : "MM/dd/yyyy" }}
  
        </td>
        <td [matTooltip]="data?.csrName || '-'">
          {{ data.csrName }}
        </td>
  
        <td [matTooltip]="
          data?.lastMaskDate
          ? (data?.lastMaskDate | date : 'MM/dd/yyyy' : 'en_US')
          : '-'
             
          ">
          {{ data?.lastMaskDate ? (data?.lastMaskDate | date : "MM/dd/yyyy"): '-' }}
        </td>
        <td [matTooltip]="
          data?.orderDate
          ? (data?.orderDate | date : 'MM/dd/yyyy' : 'en_US')
          : '-'
             
          ">
          {{
          data.orderDate | date : "MM/dd/yyyy"
  
          }}
        </td>
        <td [matTooltip]="
        data?.rxExpiryDate
        ? (data?.rxExpiryDate | date : 'MM/dd/yyyy' : 'en_US')
        : '-'
         
        ">
          {{
          data.rxExpiryDate | date : "MM/dd/yyyy"
  
          }}
        </td>
        <td [matTooltip]=" data.expiryDays" class="text-center"> 
        <button  mat-raised-button class=" mr-2" [ngClass]=" data.expiryDays === 0 ? 'resetclr': 'buttonColor' ">{{
            data.expiryDays }}</button>
            </td>
            <td [matTooltip]="data?.status || '-'">
              {{ data.status }}
            </td>
        <td [matTooltip]="data?.status || '-'">
          {{ data.status }}
        </td>
  
      </tr>
    </tbody>
    <tfoot>
      <tr>
        <th><mat-icon>apps</mat-icon></th>
        <th>
          <input matInput class="textbox-border-class" type="text" placeholder="Chart No" />
        </th>
        <th>
          <input matInput class="textbox-border-class" type="text" placeholder="Patient Name" />
        </th>
        <th>
          <input matInput class="textbox-border-class" type="text" placeholder="DOB" />
        </th>
        <th>
          <input matInput class="textbox-border-class" type="text" placeholder="Practitioner Name" />
        </th>
        <th>
          <input matInput class="textbox-border-class" type="text" placeholder="RX Date" />
        </th>
        <th>
          <input matInput class="textbox-border-class" type="text" placeholder="CSR" />
        </th>
        <th>
          <input matInput class="textbox-border-class" type="text" placeholder="Last Mask Date" />
        </th>
        <th>
          <input matInput class="textbox-border-class" type="text" placeholder="Order Date" />
        </th>
        <th>
          <input matInput class="textbox-border-class" type="text" placeholder="RX Expiry Date" />
        </th>
        <th>
          <input matInput class="textbox-border-class" type="text" placeholder="Expiry Days" />
        </th>
        <th>
          <input matInput class="textbox-border-class" type="text" placeholder="Mail Status" />
        </th>
        <th>
          <input matInput class="textbox-border-class" type="text" placeholder="Status" />
        </th>
      </tr>
    </tfoot>
</table>
