import type { IdentityUserDTO } from './models';
import { RestService } from '@abp/ng.core';
import { Injectable } from '@angular/core';
import type { AuthorizationResult } from '../../../microsoft/asp-net-core/authorization/models';
import type {
  CustomPermissionDTO,
  GetPermissionListResultDto,
  PermissionWithGrantedProviders,
  UpdatePermissionsDto,
} from '../../../permission-management/models';

@Injectable({
  providedIn: 'root',
})
export class PermissionService {
  apiName = 'platformManagement';

  isGranted = (policyName: string) =>
    this.restService.request<any, AuthorizationResult>(
      {
        method: 'POST',
        url: '/api/PlatformApp/permission/is-granted',
        params: { policyName },
      },
      { apiName: this.apiName, skipHandleError: true }
    );

  getCurrentUserPermission = () =>
    this.restService.request<any, PermissionWithGrantedProviders[]>(
      {
        method: 'GET',
        url: '/api/PlatformApp/permission/get-current-user-permission',
      },
      { apiName: this.apiName, skipHandleError: true }
    );

  getPermissionServiceByProviderNameAndProviderKeyAndTenantId = (
    providerName: string,
    providerKey: string,
    tenantId: string
  ) =>
    this.restService.request<any, GetPermissionListResultDto>(
      {
        method: 'GET',
        url: `/api/PlatformApp/permission/get-permission-service/${tenantId}`,
        params: { providerName, providerKey },
      },
      { apiName: this.apiName, skipHandleError: true }
    );

  getUsersBasedOnPatientPermission = () =>
    this.restService.request<any, IdentityUserDTO[]>(
      {
        method: 'GET',
        url: '/api/PlatformApp/permission/get-users-based-on-patient-permission',
      },
      { apiName: this.apiName, skipHandleError: true }
    );

  getUsersBasedOnVerificationPermission = () =>
    this.restService.request<any, IdentityUserDTO[]>(
      {
        method: 'GET',
        url: '/api/PlatformApp/permission/get-users-based-on-verification-permission',
      },
      { apiName: this.apiName, skipHandleError: true }
    );

  updatePermissionServiceByTenantIdAndProviderNameAndProviderKeyAndInput = (
    tenantId: string,
    providerName: string,
    providerKey: string,
    input: UpdatePermissionsDto
  ) =>
    this.restService.request<any, void>(
      {
        method: 'PUT',
        url: `/api/PlatformApp/permission/update-permission-service/${tenantId}`,
        params: { providerName, providerKey },
        body: input,
      },
      { apiName: this.apiName, skipHandleError: true }
    );
  getCustomPermissionServiceByProviderKeyAndTenantId = (
    providerKey: string,
    tenantId: string
  ) =>
    this.restService.request<any, CustomPermissionDTO>(
      {
        method: 'GET',
        url: `/api/PlatformApp/permission/get-custom-permission-service/${tenantId}`,
        params: { providerKey },
      },
      { apiName: this.apiName }
    );
  getCustomPermissionServiceV1ByProviderKeyAndTenantId = (
    providerKey: string,
    tenantId: string
  ) =>
    this.restService.request<any, CustomPermissionDTO>(
      {
        method: 'GET',
        url: `/api/PlatformApp/permission/get-custom-permission-service-v1/${tenantId}`,
        params: { providerKey },
      },
      { apiName: this.apiName }
    );
  updateCustomPermissionByTenantIdAndProviderNameAndProviderKeyAndInput = (
    TenantId: string,
    providerName: string,
    providerKey: string,
    input: CustomPermissionDTO
  ) =>
    this.restService.request<any, string>(
      {
        method: 'PUT',
        responseType: 'text',
        url: `/api/PlatformApp/permission/update-custom-permission/${TenantId}`,
        params: { providerName, providerKey },
        body: input,
      },
      { apiName: this.apiName }
    );
  constructor(private restService: RestService) {}
}
