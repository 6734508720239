import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { Router } from '@angular/router';
import { OAuthService } from 'angular-oauth2-oidc';
import { Observable, throwError } from 'rxjs';
import { tap } from 'rxjs/operators';
import Swal from 'sweetalert2';


@Injectable({
  providedIn: 'root',
})
@Injectable()
export class HttpInterceptorService implements HttpInterceptor {
  onlineFlag = navigator.onLine;
  constructor(
    public injector: Injector,
    public oauthService: OAuthService,
    public router: Router,
  ) {

  }
  handleError(error: HttpErrorResponse) {
    // if (!navigator.onLine) {
    
    // }
    // let httpErrorCode = error?.status;
    // switch (httpErrorCode) {
    //   case 400:
    //     const tokenRefreshError = "token_refresh_error";
    //     const tokenInvalidGrantError = "invalid_grant";
    //     const refreshTokenNotFound = "refresh_token_not_found";
    //     if (error && error?.status === 400 && error?.error &&
    //       (error?.error?.error === (tokenInvalidGrantError))
    //     ) {
    //       //If error is 400 and the error message is 'invalid_grant' or 'token_refresh_error' or 'refresh_token_not_found',
    //       //access token is no longer valid so logout from the application.

    //       // Swal.fire({ html: '<b>Session Expired !! You will be redirect to login page</b>',
    //       // showConfirmButton:false,
    //       // timer:4000,
    //       // timerProgressBar:true,
    //       // allowOutsideClick: false,
    //       // padding:'20px',
    //       // }).then(() => {
    //       //   this.oauthService.logOut();
    //       //   localStorage.clear();
    //       //   sessionStorage.clear();
    //       //   this.oauthService.loadDiscoveryDocumentAndLogin();
    //       // });

    //       Swal.fire({
    //         html: '<b>Session Expired, Please Login Again..</b>',
    //         showCancelButton: true,
    //         confirmButtonColor: '#50a5f1',
    //         cancelButtonColor: '#6c757d',
    //         confirmButtonText: 'Login',
    //         allowOutsideClick: false,
    //       }).then((result) => {
    //         if (result.value) {
    //           //this.router.navigate(['/']);
    //           this.logout();
    //         } else if (result.value == undefined || false) {
    //           this.logout();
    //         }
    //       });
    //     }
    //     break;
    //   // case 401:
    //   //   if (error && error?.status === 401 && error?.statusText === 'OK') {
    //   //     return this.oauthService.loadDiscoveryDocumentAndLogin();
    //   //   }
    //   //   break;

    //   // case 302:
    //   //   if (error && error?.status === 302) {
    //   //     return this.oauthService.loadDiscoveryDocumentAndLogin();
    //   //   }
    //   //   break;
    //   // case 302:
    //   //   this.oauthService.loadDiscoveryDocumentAndLogin();
    //   //   break;
    //   // case 401:
    //   //   this.oauthService.loadDiscoveryDocumentAndLogin();
    //   //   break;
    //   default:
    //   // case 302:
    //   //   this.oauthService.loadDiscoveryDocumentAndLogin();
    //   //   break;
    //   // case 401:
    //   //   this.oauthService.loadDiscoveryDocumentAndLogin();
    //     break;
    // }
    return throwError(error);
  }

  logout() {
    const oauthService = this.injector.get(OAuthService);
    oauthService.logOut(); //Logout user
    localStorage.clear();
    sessionStorage.clear();
    //oauthService.postLogoutRedirectUri =
    //(window as any).removeAllListeners();
    // this.oauthService.configure(environment.oAuthConfig);
    // this.oauthService.tokenValidationHandler = new JwksValidationHandler();
    this.oauthService.loadDiscoveryDocument(); //Load Identity server login
    //this.router.navigate(['https://localhost:44398/Account/Login']);
  }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      tap(
        (event) => { },
        (error) => {
          this.handleError(error);
        }
      )
    );
  }
}

//  const tokenRefreshError = "token_refresh_error";
//  const tokenInvalidGrantError = "invalid_grant";
//  const refreshTokenNotFound = "refresh_token_not_found";

//  var token = this.oauthService.getAccessToken();
//    if(token == null){
//      Swal.fire({
//        html: '<b>Session Expired, Please Login Again..</b>',
//        showCancelButton: true,
//        confirmButtonColor: '#50a5f1',
//        cancelButtonColor: '#6c757d',
//        confirmButtonText: 'Login',
//      }).then(result => {
//        if (result.value) {
//          this.oauthService.logOut(); To logout user
//          localStorage.clear();  To clear local storage & Session Storage
//          sessionStorage.clear();
//          (window as any).removeAllListeners();
//          this.oauthService.loadDiscoveryDocumentAndLogin();  To load Identity server Login Page
//        } else if (result.value == undefined || false) {
//          this.oauthService.logOut();
//          localStorage.clear();
//          sessionStorage.clear();
//          (window as any).removeAllListeners();
//          this.oauthService.loadDiscoveryDocument();
//        }
//      });
//    }

//   if (!this.oauthService.events) { return; }
//   this.oauthService?.events?.subscribe((response: any) => {
//
//    if (response?.type === tokenRefreshError) { If auth error is invalid grant or refresh error or refresh token not found
//      if ((response?.reason && response?.reason?.error) ||
//        response?.reason?.error?.error === tokenInvalidGrantError ||
//        response?.reason?.error?.error_reason === refreshTokenNotFound) {
//        Swal.fire({
//          html: '<b>Session Expired, Please Login Again..</b>',
//          showCancelButton: true,
//          confirmButtonColor: '#50a5f1',
//          cancelButtonColor: '#6c757d',
//          confirmButtonText: 'Login',
//        }).then(result => {
//          if (result.value) {
//            this.oauthService.logOut(); To logout user
//            localStorage.clear();  To clear local storage & Session Storage
//            sessionStorage.clear();
//            (window as any).removeAllListeners();
//            this.oauthService.loadDiscoveryDocument();  To load Identity server Login Page
//          } else if (result.value == undefined || false) {
//            this.oauthService.logOut();
//            localStorage.clear();
//            sessionStorage.clear();
//            (window as any).removeAllListeners();
//            this.oauthService.loadDiscoveryDocument();
//          }
//        });
//      }
//    }
//   });
