import { RestService } from '@abp/ng.core';
import { Injectable } from '@angular/core';

import type { CreateUpdateAuthorizationsDTO, CreateUpdateInsuranceVerificationsDTO, CreateUpdatePatientDTO, CreateUpdatePatientIntakeDTO, PatientDTO } from './dto/models';
import type { PagedAndSortedResultRequestDto, PagedResultDto } from '@abp/ng.core';
@Injectable({
  providedIn: 'root',
})
export class PatientService {
  apiName = 'Default';

  create = (input: CreateUpdatePatientDTO) =>
    this.restService.request<any, PatientDTO>({
      method: 'POST',
      url: `/api/app/patient`,
      body: input,
    },
       { apiName: this.apiName, skipHandleError: true });

  createAuthorizationByPatientIdAndAuthorizations = (patientId: string, authorizations: CreateUpdateAuthorizationsDTO) =>
    this.restService.request<any, PatientDTO>({
      method: 'PUT',
      url: `/api/app/patient/create-authorization/${patientId}`,
      body: authorizations,
    },
       { apiName: this.apiName, skipHandleError: true });

  createInsuranceVerificationByPatientIdAndInsuranceVerifications = (patientId: string, insuranceVerifications: CreateUpdateInsuranceVerificationsDTO) =>
    this.restService.request<any, PatientDTO>({
      method: 'PUT',
      url: `/api/app/patient/create-insurance-verification/${patientId}`,
      body: insuranceVerifications,
    },
       { apiName: this.apiName, skipHandleError: true });

  createPatientIntakeByPatientIntakeDto = (patientIntakeDto: CreateUpdatePatientIntakeDTO) =>
    this.restService.request<any, PatientDTO>({
      method: 'POST',
      url: `/api/app/patient/patient-intake`,
      body: patientIntakeDto,
    },
       { apiName: this.apiName, skipHandleError: true });

  delete = (id: string) =>
    this.restService.request<any, void>({
      method: 'DELETE',
      url: `/api/app/patient/${id}`,
    },
       { apiName: this.apiName, skipHandleError: true });

  deleteAuthorization = (patientId: string, authorizationId: string) =>
    this.restService.request<any, PatientDTO>({
      method: 'DELETE',
      url: `/api/app/patient/authorization`,
      params: { patientId, authorizationId },
    },
       { apiName: this.apiName, skipHandleError: true });

  deleteNotes = (patientId: string, notesId: string) =>
    this.restService.request<any, PatientDTO>({
      method: 'DELETE',
      url: `/api/app/patient/notes`,
      params: { patientId, notesId },
    },
       { apiName: this.apiName, skipHandleError: true });

  deletePatientAlerts = (patientId: string, patientAlertsId: string) =>
    this.restService.request<any, PatientDTO>({
      method: 'DELETE',
      url: `/api/app/patient/patient-alerts`,
      params: { patientId, patientAlertsId },
    },
       { apiName: this.apiName, skipHandleError: true });

  deletePolicy = (patientId: string, policyId: string) =>
    this.restService.request<any, PatientDTO>({
      method: 'DELETE',
      url: `/api/app/patient/policy`,
      params: { patientId, policyId },
    },
       { apiName: this.apiName, skipHandleError: true });

  get = (id: string) =>
    this.restService.request<any, PatientDTO>({
      method: 'GET',
      url: `/api/app/patient/${id}`,
    },
       { apiName: this.apiName, skipHandleError: true });

  getList = (input: PagedAndSortedResultRequestDto) =>
    this.restService.request<any, PagedResultDto<PatientDTO>>({
      method: 'GET',
      url: `/api/app/patient`,
      params: { sorting: input.sorting, skipCount: input.skipCount, maxResultCount: input.maxResultCount },
    },
       { apiName: this.apiName, skipHandleError: true });

  getPatientsBasedOnOrganizationByOrganizationUnitId = (organizationUnitId: string) =>
    this.restService.request<any, PatientDTO[]>({
      method: 'GET',
      url: `/api/app/patient/patients-based-on-organization/${organizationUnitId}`,
    },
       { apiName: this.apiName, skipHandleError: true });

  update = (id: string, input: CreateUpdatePatientDTO) =>
    this.restService.request<any, PatientDTO>({
      method: 'PUT',
      url: `/api/app/patient/${id}`,
      body: input,
    },
       { apiName: this.apiName, skipHandleError: true });

  updatePatientIntakeByPatientIdAndPatientIntakeDto = (patientId: string, patientIntakeDto: CreateUpdatePatientIntakeDTO) =>
    this.restService.request<any, PatientDTO>({
      method: 'PUT',
      url: `/api/app/patient/patient-intake/${patientId}`,
      body: patientIntakeDto,
    },
       { apiName: this.apiName, skipHandleError: true });

  unlockAllLockedPatientsByUserId = (userId: string) =>
    this.restService.request<any, PagedResultDto<PatientDTO>>({
      method: 'POST',
      url: `/api/app/patient/unlock-all-locked-patients/${userId}`,
    },
       { apiName: this.apiName, skipHandleError: true });

  unlockPatientByPatientId = (patientId: string) =>
    this.restService.request<any, PatientDTO>({
      method: 'POST',
      url: `/api/app/patient/unlock-patient/${patientId}`,
    },
       { apiName: this.apiName, skipHandleError: true });

  validatePatientForOrderCreationByPatientId = (patientId: string) =>
    this.restService.request<any, string>({
      method: 'GET',
      responseType: 'text',
      url: `/api/app/patient/validate-patient-for-order-creation/${patientId}`,
    },
       { apiName: this.apiName, skipHandleError: true });

  createPatient = (fileId: string, patientsId: string) =>
    this.restService.request<any, PatientDTO>({
      method: 'POST',
      url: `/api/app/patients/create-patient`,
      params: { patientsId, fileId },
    },
       { apiName: this.apiName, skipHandleError: true });
       patientList =  (
        branch: string,
        fromDate: string,
        toDate: string,
      ) =>
        this.restService.request<any, any[]>(
          {
            method: 'GET',
            url: '/api/app/patient-personal/patients',
            params: {
               branch,
              fromDate,
              toDate,
            },
          },
          { apiName: this.apiName }
        );
        patientListV1 =  (  ltBranch: string[],  ltCsr:string[], NextCallDate: string, LastMaskProcessedDate: string,) =>
          this.restService.request<any, any[]>(
            {
              method: 'GET',
              url: '/api/app/patient-personal/patients-by-user',
              params: {
                ltBranch,
                ltCsr,
                NextCallDate,
                LastMaskProcessedDate,
              },
            },
            { apiName: this.apiName }
          );

  constructor(private restService: RestService) { }
}
