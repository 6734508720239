<div class="col-12" style="margin-top: -17px; margin-bottom: -30px">
    <div class="d-flex align-items-center justify-content-between m-0">
        <div class=" page-title-box  page-title-right mt-4 mb-0">
            <ol class="breadcrumbs m-0">
            </ol>
        </div>
    </div>
</div>
<br />

<div class="card card-body">

    <div class="row">
        <div class="col-10 mt-1">
            <h4 class="customThemeClass ml-3 mb-2"><b>Pickup Details</b></h4>
        </div>
        <div class="col-2 text-lg-right">
            <button class="mr-3 ml-3" (click)="openModel()" mat-button class="buttonColor">
                Add Pickup Details
            </button>
        </div>
    </div>

    <div class="row">
        <div class="col-12 table-responsive">
            <!-- <div [hidden]="!isLoading"
                style="display: flex; justify-content: center; align-items: center; background: transparent; color: lightseagreen;">
                <mat-progress-bar mode="indeterminate" value='66' diameter="45" class="lightseagreen">
                </mat-progress-bar>
            </div> -->
            <table mat-table class="table ml-4 border" matSort [dataSource]="dataSource">
                <form style="display: flex" [formGroup]="leaveApprovalSearchForm">
                    <ng-container matColumnDef="sno">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>S.No</th>
                        <td mat-cell *matCellDef="let data; let i = index">
                            {{ i+1 }}
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="Respiratory therapist">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                            <mat-form-field class="filter">
                                <input autocomplete="new-respiratoryTherapistName" matInput formControlName="respiratoryTherapistName" />
                                <mat-placeholder>Respiratory therapist</mat-placeholder>
                            </mat-form-field>
                        </th>
                        <td mat-cell *matCellDef="let data">
                            <ng-container>
                                {{data.name}}
                            </ng-container>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="Pickup Detail">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                            <mat-form-field class="filter">
                                <input autocomplete="new-pickupDetails" matInput formControlName="pickupDetails" />
                                <mat-placeholder>Pickup Detail</mat-placeholder>
                            </mat-form-field>
                        </th>
                        <td innerText={{data?.details}} mat-cell *matCellDef="let data">
                            <!-- <ng-container>
                                {{data.details}}
                            </ng-container> -->
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="Created On">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                            <mat-form-field class="filter">
                                <mat-label>Actual Date </mat-label>
                                <input autocomplete="new-createdDate" matInput formControlName="createdDate" placeholder="(MM/DD/YYYY)" />
                                <mat-error
                                    *ngIf=" leaveApprovalSearchForm?.get('createdDate')?.touched && leaveApprovalSearchForm?.get('createdDate')?.errors?.dateVaidator">
                                    Enter Valid date
                                </mat-error>
                            </mat-form-field>
                        </th>
                        <td mat-cell *matCellDef="let data">
                            <ng-container>
                                {{data.createDate}}
                            </ng-container>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="Status">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                            <!-- <mat-form-field class="filter">
                            <input matInput formControlName="status" />
                            <mat-placeholder> Status</mat-placeholder>
                          </mat-form-field> -->
                            <mat-label class="text-center">Status </mat-label>
                        </th>
                        <td mat-cell *matCellDef="let data">
                            <ng-container *ngIf="data?.status==='Active'">
                                <mat-slide-toggle (change)="pickupStatusChange($event, data)" [checked]='true'
                                    class="ml-2">
                                    {{data?.status}}</mat-slide-toggle>
                            </ng-container>
                            <ng-container *ngIf="data?.status==='Inactive'">
                                <mat-slide-toggle (change)="pickupStatusChange($event, data)" [checked]='false'
                                    class="ml-2">
                                    {{data?.status}}</mat-slide-toggle>
                            </ng-container>
                        </td>
                    </ng-container>


                    <ng-container matColumnDef="Options">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Options</th>
                        <td mat-cell *matCellDef="let data">
                            <a class="editBtnColor" (click)="ViewPickup(data)">
                                <i *ngIf="data?.status==='Active'" class="fa fa-pencil pointer editBtnColor mr-3"
                                    matTooltip="Click To Edit" ></i>
                            </a>
                            &nbsp;
                            <a class="deleteBtnColor" (click)="deletePickup(data)">
                                <i *ngIf="data?.status==='Active'" class="fa fa-trash-o" matTooltip="Click To Delete"
                                    ></i>
                            </a>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="noRecords">
                        <td class="text-align-center" mat-footer-cell *matFooterCellDef colspan="9">
                            <div *ngIf="!isLoading">
                                {{"No Records Found"}}
                            </div>
                            <div [hidden]="!isLoading">
                                <mat-spinner [diameter]="220"></mat-spinner>
                            </div>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="paginator">
                        <td mat-footer-cell *matFooterCellDef colspan="8">
                            <mat-paginator class="table table-responsive mat-table-class" #MatPaginator
                                [length]="dataSource?.data?.length" [pageSize]="10" [pageIndex]="0" showFirstLastButtons
                                [pageSizeOptions]="[5, 10, 25, 100]">
                            </mat-paginator>
                        </td>
                    </ng-container>
                </form>
                <tr mat-footer-row *matFooterRowDef="['noRecords']"
                    [ngClass]="{ active: dataSource && dataSource?.data && dataSource?.data?.length !== 0 }"></tr>
                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
                <tr mat-footer-row *matFooterRowDef="['paginator']"></tr>
            </table>
        </div>
    </div>

</div>
