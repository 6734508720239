import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { DisapprovedStatusId, MedicareCheckListStatus } from '../patient-proxy/patient/enumeration-data/medicare-check-list-status.enum';
import { Subscription } from 'rxjs';
import { PatientMedicareCheckListDetailsService } from '../patient-proxy/patient-optimization/patient-medicare-check-list-details.service';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { MatSnackBar } from '@angular/material/snack-bar';
import { defaultGuid } from '../medicare-check-list/medicare-check-list.component';
import { noWhitespaceValidator } from '../add-note-tab/add-note-tab.component';

@Component({
  selector: 'app-mm-approved-disapproved-notes',
  templateUrl: './mm-approved-disapproved-notes.component.html',
  styleUrls: ['./mm-approved-disapproved-notes.component.scss']
})
export class MmApprovedDisapprovedNotesComponent implements OnInit {
  notesForm:FormGroup;
  saveButton:boolean=false;
  notesText: string = '';
  approveOrDisapprove: any;
  sNotes: string;
  UpdateAPICall:Subscription;
  disapprovedId: any;
  constructor(private formBuilder: FormBuilder,
    private dialog: MatDialog,
    private patientMedicareCheckListDetailsService: PatientMedicareCheckListDetailsService,
    private toastr: ToastrService,
    private snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      medicareChecklistId: string;
    } = {
      medicareChecklistId: defaultGuid,
    },
    ) { }

  ngOnInit() {
    this.notesForm = this.formBuilder.group({
      txtComment:new FormControl('', [Validators.required,noWhitespaceValidator])
    });
  }
  saveNotes(){
    this.saveButton=true;
    this.approveOrDisapprove=MedicareCheckListStatus.Disapproved;
    this.disapprovedId=DisapprovedStatusId.Other
    this.sNotes=this.notesForm.value.txtComment;
try {
  if (this.UpdateAPICall) {
    this.UpdateAPICall.unsubscribe();
  }
  this.UpdateAPICall = this.patientMedicareCheckListDetailsService
    .updateApproveDisapprove(
     this.data.medicareChecklistId,
      this.disapprovedId,
      this.approveOrDisapprove,
      this.sNotes
    )
    .subscribe(
      (response) => {
        this.toastr.success(' Update Successfully', 'Success');
        this.dialog.closeAll();
        this.saveButton=false;
      },
      (error) => {
        this.toastr.error(' Update Failed', 'Error');
        console.error('Error:', error);
        this.saveButton=false;
      },
      () => {
        // This block will be executed on completion
        setTimeout(() => {
        }, 1000);
      }
    );
} catch (error) {
  this.toastr.error('Update Failed', 'Error');
  console.error('Unexpected error:', error);
  this.saveButton=false;
} finally {
}

  }
  updateCharacterCount(maxlength: number) {
    // Ensure the text length does not exceed the maximum length
    if (this.notesText?.length > maxlength) {
      this.notesText = this.notesText?.substr(0, maxlength);
    }
  }
  Reset(){
   this.notesForm.patchValue({
      txtComment:''
    });
  }
}
