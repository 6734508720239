import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { defaultGuid } from '../../enums/allenums.enum';
import { ImageType } from 'projects/patient/src/app/patient-proxy/patient/enumeration-data/image-type.enum';

@Component({
  selector: 'app-common-doument-viewer-for-medicare',
  templateUrl: './common-doument-viewer-for-medicare.component.html',
  styleUrls: ['./common-doument-viewer-for-medicare.component.scss'],
})
export class CommonDoumentViewerForMedicareComponent implements OnInit {
  selectedFileDetails: any;
  defaultFaxId: string = '-';
  docId: string;
  defaultPatientId: string = '-';
  chartId: string = '-';
  patientName: string = '-';
  isLoading = false;
  subscription$: Subscription[] = [];
  splitDocumentImageList: any = [];
  selectedSplitDocument: any = null;

  constructor(
    public cmDVDialogRef: MatDialogRef<CommonDoumentViewerForMedicareComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      previewList: number[];
      docId: string;
      // isView: boolean;
      flag : ImageType;
      isMedicare:boolean;
      medicareId : string;
    } = {
      previewList: [],
      docId: defaultGuid,
      // isView: false,
      isMedicare:false,
      flag : null,
      medicareId : defaultGuid,
    }
  ) {}

  ngOnInit(): void {
    this.isLoading = true;
    this.selectedFileDetails = this.data.previewList ?? [];
  }

  closeModel() {
    this.cmDVDialogRef.close(this.selectedFileDetails);
  }

  BindHearderText(event: any) {
    this.defaultPatientId = event?.defaultPatientId || '-';
    this.patientName = event?.fullName || '-';
    this.chartId = event?.chartId || '-';
    this.defaultFaxId = event?.defaultFaxId || '-';
  }

  LoadSelectedImage(event: any) {
    this.selectedFileDetails = event;
  }
}
