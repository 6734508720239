import { mapEnumToOptions } from '@abp/ng.core';

export enum PatientStatus {
  clinicalPending = 'clinicalPending',
  policyPending = 'policyPending',
  verifyPending = 'verifyPending',
  readyForOrder = 'readyForOrder',
}

export const patientStatusOptions = mapEnumToOptions(PatientStatus);
