<table
  datatable
  [dtOptions]="dtProductTableOptions"
  class="row-border hover w-100 display"
>
  <thead>
    <tr>
      <th><mat-icon>apps</mat-icon></th>
      <th matTooltip="Product">Product</th>
      <th matTooltip="Hcpc Code">Hcpc Code</th>
      <th matTooltip="Unit Cost($)" class="text-right">Unit Cost($)</th>
      <th matTooltip="Created By">Created By</th>
      <th matTooltip="Created Date" class="text-right">Created Date</th>
      <th matTooltip="Modified By">Modified By</th>
      <th matTooltip="Modified Date" class="text-right">Modified Date</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let data of tableDataList">
      <td >
        <a (click)="getProductId(data)"
          class="editBtnColor mr-2"

        >
          <i
            class="fa fa-pencil pointer"
            (click)="triggerToggle()"
            matTooltip="Click To Edit"
          ></i>
        </a>
      </td>
      <td [matTooltip]="data.productCode || '-'">
        {{ data.productCode || "-" }} / {{ data.mainProductName || "-" }}
      </td>

      <td [matTooltip]="data.hcpcCode && data.hcpcCode.length === 0 ? '-' : data.hcpcCode">
        {{ data.hcpcCode && data.hcpcCode.length === 0 ? '-' : data.hcpcCode }}
      </td>
      <td
      class="text-right"
      matTooltip="{{ data.unitCost !== null && data.unitCost !== undefined && data.unitCost !== '' ? '$' + data.unitCost : '-' }}"
    >
      {{ data.unitCost !== null && data.unitCost !== undefined && data.unitCost !== '' ? data.unitCost : "-" }}
    </td>
      <td [matTooltip]="data.createdBy || '-'">
        {{ data.createdBy || "-" }}
      </td>
      <td class="text-right">
        <span class="cus-span" [matTooltip]="data?.creationTime">{{
          data?.creationTime }}</span>
      </td>

      <td [matTooltip]="data.modifiedBy || '-'">
        {{ data.modifiedBy || "-" }}
      </td>

    <td class="text-right">
      <span class="cus-span" [matTooltip]="data?.lastModificationTime">{{
        data?.lastModificationTime }}</span>
    </td>

    </tr>
  </tbody>
  <tfoot>
    <tr>
      <th><mat-icon>apps</mat-icon></th>
      <th>
        <input
          matInput
          class="textbox-border-class"
          type="text"
          placeholder="Product"
          autocomplete="off"
        />
      </th>
      <th>
        <input
          matInput
          class="textbox-border-class"
          type="text"
          placeholder="HCPC Code"
          autocomplete="off"
        />
      </th>
      <th>
        <input
          matInput
          class="textbox-border-class text-right"
          type="text"
          placeholder="Unit Cost ($)"
          autocomplete="off"
        />
      </th>
      <th>
        <input
          matInput
          class="textbox-border-class"
          type="text"
          placeholder="Created By"
          autocomplete="off"
        />
      </th>
      <th>
        <input
          matInput
          class="textbox-border-class text-right"
          type="text"
          placeholder="Created Date"
          autocomplete="off"
        />
      </th>
      <th>
        <input
          matInput
          class="textbox-border-class"
          type="text"
          placeholder="Modified By "
          autocomplete="off"
        />
      </th>
      <th>
        <input
          matInput
          class="textbox-border-class text-right"
          type="text"
          placeholder="Modified Date"
          autocomplete="off"
        />
      </th>

    </tr>
  </tfoot>
</table>
