import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FedExService } from 'projects/order/src/app/order-proxy/order-management/delivery/fed-ex/fed-ex.service';
import { SaleorderService } from 'projects/order/src/app/order-proxy/order-management/order/saleorder.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-shipping-lable',
  templateUrl: './shipping-lable.component.html',
  styleUrls: ['./shipping-lable.component.scss']
})
export class ShippingLableComponent implements OnInit , OnDestroy{
  subscription$: Subscription[] = [];
  constructor(public dialogRef: MatDialogRef<ShippingLableComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { orderId: string },
    private fedexService: FedExService,
    private orderService: SaleorderService,) { }
  strSelectedPdfPath: string = "";


  ngOnInit(): void {
    this.getPdfViewLable();
  }

  ngOnDestroy(): void {
    this.subscription$?.forEach((sub) => {
      sub && sub?.unsubscribe();
    });
  }

  /// get pdf logo binding
  getPdfViewLable() {
    const fedex = this.fedexService.getShippingLabelDocuments(this.data.orderId).subscribe(res => {
      this.strSelectedPdfPath = String("data:application/pdf;base64," + res);
    })
    this.subscription$.push(fedex);
  }



}
