<mat-dialog-content>
  <h2 class="customThemeClass" mat-dialog-title>Mail Summary
  </h2>
  <form [formGroup]="mailForm">
    <div class="row">
      <mat-form-field class="col-12">
        <mat-label> User Name <span class="asterisk">*</span></mat-label>
        <input autocomplete="new-password" matInput maxlength="40" formControlName="txtUserName" type="text" />
        <mat-error *ngIf="mailForm?.get('txtUserName')?.touched &&
            mailForm?.get('txtUserName')?.errors?.required ">
          User Name is a required field!
        </mat-error>
      </mat-form-field>
      <mat-form-field class="col-12">
        <mat-label> Email <span class="asterisk">*</span></mat-label>
        <input autocomplete="new-txtEmail" matInput maxlength="150" type="text" multiple formControlName="txtEmail"
          pattern="[a-z0-9._!#$%&'()*+,-./:;<=>?@[\]^_`{|}~]+@[a-z0-9.-]+\.[a-z]{2,4}$">
        <mat-hint>Enter multiple mail id's by semicolon(;) seperated </mat-hint>
        <mat-error *ngIf="mailForm?.get('txtEmail')?.touched &&
            mailForm?.get('txtEmail')?.errors?.required ">
          Email Address is a required field!
        </mat-error>
        <mat-error *ngIf="mailForm?.get('txtEmail')?.touched && mailForm?.get('txtEmail')?.errors?.pattern">
          Please Enter a Valid Email Address
        </mat-error>
      </mat-form-field>
    </div>
  </form>

</mat-dialog-content>
<mat-dialog-actions align="end">
  <button class="buttonColor" [disabled]="sendButtonHide || mailForm.invalid" (click)="sendEmailUsingNameAndEmailIds()" mat-button >Send Email</button>
  <button class="buttonCancelColor" mat-button mat-dialog-close>Close</button>
</mat-dialog-actions>
