import { PagedAndSortedResultRequestDto } from '@abp/ng.core';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ProductCategoryDetailsService } from 'projects/inventory/src/app/item-proxy/item-management/optimization';
import { PayerService } from 'projects/patient/src/app/patient-proxy/patient';
import { Observable, Subscription } from 'rxjs';
import Swal from 'sweetalert2';
import { map, startWith } from 'rxjs/operators';
import { Title } from '@angular/platform-browser';
import { CreateUpdateResupplyRemainderSettingDTO } from 'projects/order/src/app/order-proxy/order-management/order-optimization/dto/models';
import { ResupplyRemainderSettingService } from 'projects/order/src/app/order-proxy/order-management/order-optimization/resupply-remainder-setting.service';
import { ToastrService } from 'ngx-toastr';
import { Accordion } from '@syncfusion/ej2-angular-navigations';
import { ActiveStatus } from 'projects/admin/src/app/admin-proxy/platform-app-management/rcm/platform-management/localization/enumeration/active-status.enum';
import { CommonService } from 'projects/shared/src/app/services/common.service';
import { MatDialog } from '@angular/material/dialog';
import { AddInsuranceCategoryComponent } from 'projects/shared/src/app/components/add-insurance-category/add-insurance-category.component';
import { AddCategoryComponent } from 'projects/shared/src/app/components/add-category/add-category.component';
@Component({
  selector: 'app-master-resupply',
  templateUrl: './master-resupply.component.html',
  styleUrls: ['./master-resupply.component.scss']
})
export class MasterResupplyComponent implements OnInit {
  resupplyId: string = '';
  selectedOption: number = ActiveStatus.Active;
  step: boolean = false;
  step1: boolean = false;
  InsCategFilterControl = new FormControl();
  lstInsurenceCategory: any;
  InsurenceCategoryList: any;
  isInsuranceDrpLoading: boolean = false;
  subscription$: Subscription[] = [];
  filteredCategoryDetails: Observable<string[]> | undefined;
  ltCategories: any;
  resupplyForm: FormGroup;
  saveButtonHide: boolean;
  isTableLoading: boolean;
  resupplyTableData: any;
  constructor(private payerService: PayerService,
    private categoryService: ProductCategoryDetailsService,
    private fb: FormBuilder,
    public title: Title,
    private resupplyRemainderSettingService: ResupplyRemainderSettingService,
    private toastr: ToastrService,
    private commonService:CommonService,
    public dialog: MatDialog,
  ) { }

  ngOnInit(): void {
    this.title.setTitle('Qsecure | Resupply')
    this.initilizationForm();
    this.getInsuranceCategory();
    this.getProductCategory();
    this.InsCategFilterControl.valueChanges.subscribe((FilterText) => {
      FilterText = FilterText.toLowerCase();
      this.InsurenceCategoryList = this.lstInsurenceCategory.filter((dx) =>
        dx.insuranceCategory.toLowerCase().includes(FilterText)
      );
    });
    this.getResuppltTableData();
  }

  initilizationForm(): void {
    this.resupplyForm = this.fb.group({
      txtQuantity: new FormControl('', [Validators.required]),
      drpCategory: new FormControl('', [Validators.required]),
      InsCategory: new FormControl('', [Validators.required]),
      txtDueDays: new FormControl('', [Validators.required]),
      txtCategoryFilter: new FormControl(''),
    });
  }

  openAddInsuranceCategoryModal() {
    const dialogRef = this.dialog.open(AddInsuranceCategoryComponent, {
      disableClose: true,

      data: {
      },
    });

    const closeDialog = dialogRef.afterClosed().subscribe(
      (claimDetails: any) => {
        console.log('claimDetails');
        console.log(claimDetails);
        if (claimDetails) {

          const existingItemIndex = this.lstInsurenceCategory.findIndex(
            (item) => item.id === claimDetails.id
          );
          console.log('existingItemIndex');
          console.log(existingItemIndex);
          if (existingItemIndex === -1) {
            this.lstInsurenceCategory.push(claimDetails);
            this.lstInsurenceCategory.sort((a, b) => a.insuranceCategory.localeCompare(b.insuranceCategory));
          }
          console.log('this.InsurenceCategoryList');
          console.log(this.lstInsurenceCategory);

          this.InsCategFilterControl.valueChanges.subscribe((FilterText) => {
            FilterText = FilterText.toLowerCase();
            this.InsurenceCategoryList = this.lstInsurenceCategory.filter((dx) =>
              dx.insuranceCategory.toLowerCase().includes(FilterText)
            );
          });
        }
      },
      (err) => {
        // const data: HttpErrorResponse = err;
        // console.warn(data?.error?.error?.message)
        // Swal.fire({
        //   icon: 'info',
        //   text: data?.error?.error?.message,
        // });
      }
    );
  }

  openAddCategoryModal() {
    const dialogRef = this.dialog.open(AddCategoryComponent, {
      disableClose: true,

      data: {
      },
    });

    const closeDialog = dialogRef.afterClosed().subscribe(
      (claimDetails: any) => {
        console.log('claimDetails');
        console.log(claimDetails);
        if (claimDetails) {
          const existingItemIndex = this.ltCategories.findIndex(
            (item) => item.id === claimDetails.id
          );
          console.log('existingItemIndex');
          console.log(existingItemIndex);
          if (existingItemIndex === -1) {
            this.ltCategories.push(claimDetails);
            this.ltCategories.sort((a, b) => a.productCategoryName.localeCompare(b.productCategoryName));
          }
          console.log('this.ltCategories');
          console.log(this.ltCategories);

          // this.ltCategories = response?.items;
          this.filteredCategoryDetails = this.resupplyForm
            .get('txtCategoryFilter')
            ?.valueChanges.pipe(
              startWith(''),
              map((value) =>
                this.ltCategories?.filter((option) =>
                  option?.productCategoryName
                    ?.toLowerCase()
                    ?.includes(value?.toLowerCase() ?? '')
                )
              )
            );
          console.log('this.filteredCategoryDetails');
          console.log(this.filteredCategoryDetails);
        }
      },
      (err) => {
        // const data: HttpErrorResponse = err;
        // console.warn(data?.error?.error?.message)
        // Swal.fire({
        //   icon: 'info',
        //   text: data?.error?.error?.message,
        // });
      }
    );
  }

  toggle() {
    this.step = true;
  }
  toggleV2() {
    this.step = false;
  }

  //GET Insurance Catedory List
  getInsuranceCategory() {
    this.isInsuranceDrpLoading = true;
    const payerList = this.payerService.InsuranceCategoryGetList().subscribe(
      (response) => {
        this.lstInsurenceCategory = response?.items;
        this.lstInsurenceCategory = this.lstInsurenceCategory.sort((a, b) =>
          a.insuranceCategory.localeCompare(b.insuranceCategory)
        );
        this.InsurenceCategoryList = this.lstInsurenceCategory;
        this.isInsuranceDrpLoading = false;
      },
      (err) => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
        this.isInsuranceDrpLoading = false;
      }
    );
    this.subscription$.push(payerList);
  }

  //GET Product Catedory List
  getProductCategory() {
    const categoryDetails = this.categoryService
      .getActiveCategoryList(new PagedAndSortedResultRequestDto())
      .subscribe(
        (response) => {
          this.ltCategories = response?.items;
          this.filteredCategoryDetails = this.resupplyForm
            .get('txtCategoryFilter')
            ?.valueChanges.pipe(
              startWith(''),
              map((value) =>
                this.ltCategories?.filter((option) =>
                  option?.productCategoryName
                    ?.toLowerCase()
                    ?.includes(value?.toLowerCase() ?? '')
                )
              )
            );
        },
        (err) => { }
      );
    this.subscription$.push(categoryDetails);
  }

  //#region GET ReSupply Table Data
  getResuppltTableData() {
    let selectedFilteroption;
    switch (this.selectedOption) {
      case ActiveStatus.All:
        selectedFilteroption = '';
        break;
      case ActiveStatus.Active:
        selectedFilteroption = true;
        break;
      case ActiveStatus.Inactive:
        selectedFilteroption = false;
        break;
      default:
        selectedFilteroption = null;
    }
    this.isTableLoading = true;
    this.resupplyRemainderSettingService
      .getAllBasedOnActiveStatusByIsActive(selectedFilteroption)
      .subscribe(
        (response) => {
          this.resupplyTableData = response;
          this.resupplyTableData = this.resupplyTableData.map((data) => {
            return {
              ...data,
              creationTime: this.commonService.getFormattedDateTimeZone(
                data.creationTime
              ),
              lastModificationTime: this.commonService.getFormattedDateTimeZone(
                data.lastModificationTime
              ),
            };
          });
          this.isTableLoading = false;
        },
        (err) => {
          this.isTableLoading = false;
          const data: HttpErrorResponse = err;
          Swal.fire({
            icon: 'info',
            text: data?.error?.error?.message,
          });
        }
      );
  }


  //Save or Update Resupply
  saveResupply() {
    this.saveButtonHide = true;

    let resupplyDetails: CreateUpdateResupplyRemainderSettingDTO = {
      insCatId: this.resupplyForm.value.InsCategory,
      productCatId: this.resupplyForm.value.drpCategory,
      quantity: this.resupplyForm.value.txtQuantity,
      dueDays: this.resupplyForm.value.txtDueDays,
      // isActive: true
    };

    if (
      this.resupplyId === '' ||
      this.resupplyId === undefined ||
      this.resupplyId === null
    ) {
      this.resupplyId = '';
      this.resupplyRemainderSettingService.create(resupplyDetails).subscribe(
        (response) => {
          this.toastr.success('Saved Successfully', 'Success');
          this.saveButtonHide = false;
          this.toggleV2();
          this.resetResupplyForm();
          this.getResuppltTableData();
        },
        (err) => {
          this.saveButtonHide = false;
          const data: HttpErrorResponse = err;
          Swal.fire({
            icon: 'info',
            text: data?.error?.error?.message,
          });
        }
      );
      return;
    }

    this.resupplyRemainderSettingService.update(this.resupplyId, resupplyDetails).subscribe(
      (response) => {
        this.toastr.success('Updated Successfully', 'Success');
        this.saveButtonHide = false;
        this.resetResupplyForm();
        this.toggleV2();
        this.getResuppltTableData();
      },
      (err) => {
        this.saveButtonHide = false;
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      }
    );

  }

  //For Update Supply Bind Values in fields
  editResupply(resupplyId: string) {
    this.resupplyId = resupplyId;
    this.saveButtonHide = false;
    window.scrollTo({ top: 0, behavior: 'smooth' });
    if (this.step == false) {
      this.step = true;
    }
    const payerGet = this.resupplyRemainderSettingService.getByIDBySupplyID(resupplyId).subscribe(
      (response) => {
        this.resupplyForm.patchValue({
          InsCategory: response?.insCatId,
          drpCategory: response?.productCatId,
          txtQuantity: response?.quantity,
          txtDueDays: response?.dueDays
        });
      },
      (err) => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      }
    );
    this.subscription$.push(payerGet);
  }

  resetResupplyForm() {
    this.resupplyId = '';
    this.resupplyForm.reset();
    this.resupplyForm.markAsUntouched();
    this.resupplyForm.updateValueAndValidity();
  }
}
