import { Action, NgxsAfterBootstrap, Selector, State, StateContext } from '@ngxs/store';

import { removeItem, updateItem } from '@ngxs/store/operators';
import { switchMap, tap } from 'rxjs/operators';
import { AppNotification } from './app-notification.model';
import {
  AddNotification,
  DeleteNotification,
  FetchNotifications,
  MarkAllAsRead,
  MarkAsRead,
} from './notifications.actions';
import { NotificationsService } from './notifications.service';
import { Injectable } from '@angular/core';
import { PushNotificationService } from 'projects/admin/src/app/admin-proxy/platform-app-management/rcm/platform-management/push-notification/push-notification.service';

/** @dynamic */
@State<AppNotification[]>({
  name: 'notifications',
  defaults: [],
})
@Injectable({
  providedIn: 'root',
})
export class NotificationsState implements NgxsAfterBootstrap {
  constructor(private notificationsService: NotificationsService,
    private notificationService: PushNotificationService
  ) { }
  @Selector()
  static unReadCount(state: AppNotification[]) {
    return state?.filter((note) => !note?.isViewed)?.length;
  }

  ngxsAfterBootstrap(ctx: StateContext<AppNotification[]>) {
    /**
     * well, this way, it will be called before dashboard is routed due to preloadingStrategy.
     * we will loose lazy loading benefits. so lets use ngxsAfterBootstrap on component to load initial data.
     */
    // ctx.dispatch(new FetchNotifications())
  }


  @Action(FetchNotifications, { cancelUncompleted: true })
  fetchNotifications({ getState, patchState, setState }: StateContext<AppNotification[]>, { payload }) {
    return this.notificationsService.getAll(payload).pipe(tap((res) => {
      return setState(res ?? [])
    }));
  }

  


  @Action(DeleteNotification)
  delete(ctx: StateContext<AppNotification[]>, { payload }: DeleteNotification) {
    ctx.setState(removeItem<AppNotification>((note) => note.id === payload.id));
  }

  @Action(MarkAsRead)
  markAsRead({ getState, patchState, setState }: StateContext<AppNotification[]>, { payload }: MarkAsRead) {
    this.notificationService.updateNotification(payload.id, !payload.isViewed).pipe(tap(res=>{}))
   return this.notificationService.getAllNotifications().pipe(tap((res) => {
      return setState(res ?? [])
    }))
  }



  
}