<br />
<div class="card card-body mt-100">
  <div class="row">
    <div class="col-lg-12">
      <div class="row">
        <div class="col-md-6">
          <h4 class="customThemeClass"><b>Claim Info</b></h4>
        </div>
        <div class="col-md-6"></div>
      </div>
      <form [formGroup]="claimForm">
        <mat-card-content>
          <div class="row">



            <mat-form-field class="col-xl-4">
              <mat-label>Referring Provider<span class="asterisk">*</span></mat-label>
              <mat-select formControlName="drpReferringProvider" [(value)]="referrindProvider" #singleSelect>
                <mat-option>
                  <ngx-mat-select-search placeholderLabel="Search" noEntriesFoundLabel="No Matches found"
                    formControlName="txtreferrindProviderFilter"></ngx-mat-select-search>
                </mat-option>
                <mat-option *ngFor="let provider of filteredreferrindProvider | async" [value]="provider.name">
                  {{provider.name}}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="
              claimForm?.get('drpReferringProvider')?.touched &&
              claimForm?.get('drpReferringProvider')?.errors?.required
            ">
                Diagnosis Code-ICD-10 is a required field!
              </mat-error>
              <button mat-button (click)="referrindProvider=undefined;$event.stopPropagation()"
                *ngIf="referrindProvider" matSuffix (click)="clearreferrindProvider()" mat-icon-button
                >
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field>


            <mat-form-field class="col-4">
              <mat-label> PCP </mat-label>
              <mat-select maxlength="40" [(value)]="pcp" formControlName="drpPcp" [errorStateMatcher]="matcher">
                <mat-option *ngFor="let pcp of drpPcps" [value]="pcp.shortCodeId"> {{ pcp.pcpType }}
                </mat-option>
              </mat-select>

              <button mat-button (click)="pcp='';$event.stopPropagation()" *ngIf="pcp" matSuffix (click)="clearpcp()"
                mat-icon-button >
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field>


            <!-- <mat-form-field class="col-xl-4">
              <mat-label>Renderring Provider</mat-label>
              <mat-select formControlName="drpOrderingProvider" [(value)]="orderingProvider" #singleSelect>
                <mat-option>
                  <ngx-mat-select-search placeholderLabel="Search" noEntriesFoundLabel="No Matches found"
                    formControlName="txtorderingProviderFilter"></ngx-mat-select-search>
                </mat-option>
                <mat-option *ngFor="let provider of filteredorderingProvider | async" [value]="provider.name">
                  {{provider.name}}
                </mat-option>
              </mat-select>

              <button mat-button (click)="orderingProvider=undefined;$event.stopPropagation()" *ngIf="orderingProvider"
                matSuffix (click)="clearorderingProvider()" mat-icon-button >
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field> -->
            <!-- Rendering Provider -->


            <mat-form-field class="col-4">
              <mat-label>Provider <span class="asterisk">*</span></mat-label>
              <mat-select maxlength="100" [(value)]="provider" formControlName="drpOrderingProvider">
                <mat-option *ngFor="let providers of drpProviders" [value]="providers.shortCodeId">
                  {{ providers.name }}
                </mat-option>
              </mat-select>
              <button mat-button (click)="provider='';$event.stopPropagation()" *ngIf="provider" matSuffix
                (click)="clearProvider()" mat-icon-button >
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field>
          </div>
          <div class="row">

            <mat-form-field class="col-4">
              <mat-label> Supervising Prov </mat-label>
              <mat-select maxlength="40" [(value)]="SupervisingProv" formControlName="drpSupervisingProv"
                [errorStateMatcher]="matcher">
                <mat-option *ngFor="let a of drpSupervisingProv" [value]="a.shortCodeId">
                  {{ a.supervisingType }}
                </mat-option>
              </mat-select>
              <button mat-button (click)="SupervisingProv='';$event.stopPropagation()" *ngIf="SupervisingProv" matSuffix
                (click)="clearSupervisingProv()" mat-icon-button >
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field>

            <mat-form-field class="col-4">
              <mat-label>Referral Date</mat-label>
              <input autocomplete="new-txtReferralDate" [min]="BirthdateMinDate" formControlName="txtReferralDate" maxlength="40" placeholder="MM/DD/YYYY"
                matInput [matDatepicker]="txtReferralDate" />
              <mat-datepicker-toggle matSuffix [for]="txtReferralDate"> </mat-datepicker-toggle>
              <mat-datepicker #txtReferralDate></mat-datepicker>
              <mat-error *ngIf="
                claimForm?.get('txtReferralDate')?.touched && claimForm?.get('txtReferralDate')?.errors
              ">
                Enter Valid Date!
              </mat-error>
            </mat-form-field>

            <mat-form-field class="col-4">
              <mat-label> PCP Last Seen </mat-label>
              <mat-select maxlength="40" [(value)]="pcpLast" formControlName="drpPcpLast" [errorStateMatcher]="matcher">
                <mat-option *ngFor="let pcpLast of drpPcpLastSeen" [value]="pcpLast.shortCodeId">
                  {{ pcpLast.pcpType }}
                </mat-option>
              </mat-select>
              <button mat-button (click)="pcpLast='';$event.stopPropagation()" *ngIf="pcpLast" matSuffix
                (click)="clearpcpLast()" mat-icon-button >
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field>

            <br />
          </div>

          <br />
          <div class="row">
            <div class="col-lg-3">
              <div class="row">
                <div class="col-12">
                  <!-- <h4 *ngIf='!isIcd9Shown' class="customThemeClass"><b>Diagnosis Codes-ICD-9</b></h4> -->
                  <h4 class="customThemeClass"><b>Diagnosis Codes-ICD-9</b></h4>
                </div>
              </div>
              <mat-card-content>
                <div class="row">


                  <mat-form-field class="col-12">
                    <mat-label>Diagnosis Code ICD-9</mat-label>
                    <mat-select multiple formControlName="txtDiagnosisCodes9" [(value)]="txtIcd9" #singleSelect>
                      <mat-option>
                        <ngx-mat-select-search placeholderLabel="Search" noEntriesFoundLabel="No Matches found"
                          formControlName="txtIcd9Filter"></ngx-mat-select-search>
                      </mat-option>
                      <mat-option *ngFor="let diagnosis9 of filteredIcd9Types | async"
                        [value]="diagnosis9.diagnosisCode9ShortCodeId">
                        {{diagnosis9.diagnosisCode + ' / ' + diagnosis9.diagnosisCodeTypeData }}
                      </mat-option>
                    </mat-select>
                    <button mat-button (click)="txtIcd9=[];$event.stopPropagation()" *ngIf="txtIcd9" matSuffix
                      (click)="clearIcd9()" mat-icon-button >
                      <mat-icon>close</mat-icon>
                    </button>
                  </mat-form-field>

                </div>
              </mat-card-content>
            </div>
            <div class="col-lg-9">
              <div class="row">
                <div class="col-md-6">
                  <h4 class="customThemeClass"><b>Diagnosis Codes-ICD-10 <span class="asterisk">*</span></b></h4>
                </div>
              </div>
              <mat-card-content>
                <div class="row">
                  <mat-form-field class="col-4">
                    <mat-label>Diagnosis Code-ICD-10<span class="asterisk">*</span></mat-label>
                    <mat-select multiple formControlName="txtdiagnosisCode10Id" [(value)]="txtIcd10" #singleSelect>
                      <mat-option>
                        <ngx-mat-select-search placeholderLabel="Search" noEntriesFoundLabel="No Matches found"
                          formControlName="txtIcd10Filter"></ngx-mat-select-search>
                      </mat-option>
                      <mat-option *ngFor="let diagnosis of filteredIcd10Types | async"
                        [value]="diagnosis.diagnosisCode10ShortCodeId">
                        {{diagnosis.diagnosisCode + ' / ' + diagnosis.diagnosisCodeTypeData }}
                      </mat-option>
                    </mat-select>
                    <button mat-button (click)="txtIcd10=[];$event.stopPropagation()" *ngIf="txtIcd10" matSuffix
                      (click)="clearIcd10()" mat-icon-button>
                      <mat-icon>close</mat-icon>
                    </button>
                    <mat-error *ngIf="
                    claimForm?.get('txtdiagnosisCode10Id')?.touched &&
                    claimForm?.get('txtdiagnosisCode10Id')?.errors?.required
                  ">
                      Diagnosis Code-ICD-10 is a required field!
                    </mat-error>
                  </mat-form-field>

                  <mat-form-field class="col-4">
                    <mat-label> Current Ilness </mat-label>
                    <mat-select maxlength="40" [(value)]="currentIllness" formControlName="drpCurrent"
                      [errorStateMatcher]="matcher">
                      <mat-option *ngFor="let illnesses of drpCurrentIllnesses" [value]="illnesses.shortCodeId">
                        {{ illnesses.currentIllnessType }}
                      </mat-option>
                    </mat-select>
                    <button mat-button (click)="currentIllness='';$event.stopPropagation()" *ngIf="currentIllness"
                      matSuffix (click)="clearcurrentIllness()" mat-icon-button >
                      <mat-icon>close</mat-icon>
                    </button>
                  </mat-form-field>


                  <mat-form-field class="col-4">
                    <mat-label> Similar Ilness </mat-label>
                    <mat-select maxlength="40" [(value)]="SimilarIllness" formControlName="drpSimilar"
                      [errorStateMatcher]="matcher">
                      <mat-option *ngFor="let illnesses of drpSimilarIllnesses" [value]="illnesses.shortCodeId">
                        {{ illnesses.similarIllnessType }}
                      </mat-option>
                    </mat-select>
                    <button mat-button (click)="SimilarIllness='';$event.stopPropagation()" *ngIf="SimilarIllness"
                      matSuffix (click)="clearSimilarIllness()" mat-icon-button >
                      <mat-icon>close</mat-icon>
                    </button>
                  </mat-form-field>
                </div>
              </mat-card-content>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-12">
              <div class="row">


                <mat-form-field class="col-3">
                  <mat-label> Last Menstrual </mat-label>
                  <mat-select maxlength="40" [(value)]="Menstrual" formControlName="drpMenstrual"
                    [errorStateMatcher]="matcher">
                    <mat-option *ngFor="let lastMenstrual of drpLastMenstruals" [value]="lastMenstrual.shortCodeId">
                      {{ lastMenstrual.lastMenstrualType }}
                    </mat-option>
                  </mat-select>
                  <button mat-button (click)="Menstrual='';$event.stopPropagation()" *ngIf="Menstrual" matSuffix
                    (click)="clearMenstrual()" mat-icon-button >
                    <mat-icon>close</mat-icon>
                  </button>
                </mat-form-field>
                <mat-form-field class="col-3">
                  <mat-label>Injury Date</mat-label>
                  <input autocomplete="new-txtInjury" formControlName="txtInjury" maxlength="40" placeholder="MM/DD/YYYY" matInput
                    [matDatepicker]="txtInjury" />
                  <mat-datepicker-toggle matSuffix [for]="txtInjury"> </mat-datepicker-toggle>
                  <mat-datepicker #txtInjury></mat-datepicker>
                  <mat-error *ngIf="claimForm?.get('txtInjury')?.touched && claimForm?.get('txtInjury')?.errors">
                    Enter Valid Date!
                  </mat-error>
                </mat-form-field>

                <mat-form-field class="col-3">
                  <mat-label> First Treatment </mat-label>
                  <mat-select maxlength="40" [(value)]="firstTreatment" formControlName="drpFirstTreatment"
                    [errorStateMatcher]="matcher">
                    <mat-option *ngFor="let firstTreatment of drpFirstTreatments" [value]="firstTreatment.shortCodeId">
                      {{ firstTreatment.firstTreatmentType }}
                    </mat-option>
                  </mat-select>
                  <button mat-button (click)="firstTreatment='';$event.stopPropagation()" *ngIf="firstTreatment"
                    matSuffix (click)="clearfirstTreatment()" mat-icon-button >
                    <mat-icon>close</mat-icon>
                  </button>
                </mat-form-field>
                <div class="col-3">
                  <br />
                  <mat-checkbox formControlName="chkPregnant">Pregnant</mat-checkbox>
                </div>
              </div>
              <div class="row">

                <mat-form-field class="col-3">
                  <mat-label>Consult Date</mat-label>
                  <input autocomplete="new-txtConsultDate" formControlName="txtConsultDate" maxlength="40" placeholder="MM/DD/YYYY" matInput
                    [matDatepicker]="txtConsultDate" />
                  <mat-datepicker-toggle matSuffix [for]="txtConsultDate"> </mat-datepicker-toggle>
                  <mat-datepicker #txtConsultDate></mat-datepicker>
                  <mat-error *ngIf="
                  claimForm?.get('txtConsultDate')?.touched && claimForm?.get('txtConsultDate')?.errors
                ">
                    Enter Valid Date!
                  </mat-error>
                </mat-form-field>


                <mat-form-field class="col-3">
                  <mat-label>Last X-Ray </mat-label>
                  <mat-select maxlength="40" [(value)]="lastXray" formControlName="drpLastXray"
                    [errorStateMatcher]="matcher">
                    <mat-option *ngFor="let LastXrays of drpLastXrays" [value]="LastXrays.shortCodeId">
                      {{ LastXrays.lastXrayType }}
                    </mat-option>
                  </mat-select>
                  <button mat-button (click)="lastXray='';$event.stopPropagation()" *ngIf="lastXray" matSuffix
                    (click)="clearlastXray()" mat-icon-button >
                    <mat-icon>close</mat-icon>
                  </button>
                </mat-form-field>
                <mat-form-field class="col-3">
                  <mat-label>Estimated DOB</mat-label>
                  <input autocomplete="new-txtEstimatedDob" formControlName="txtEstimatedDob" maxlength="40" placeholder="MM/DD/YYYY" matInput
                    [matDatepicker]="txtEstimatedDob" />
                  <mat-datepicker-toggle matSuffix [for]="txtEstimatedDob"> </mat-datepicker-toggle>
                  <mat-datepicker #txtEstimatedDob></mat-datepicker>
                  <mat-error *ngIf="
                    claimForm?.get('txtEstimatedDob')?.touched &&
                    claimForm?.get('txtEstimatedDob')?.errors
                  ">
                    Enter Valid Date!
                  </mat-error>
                </mat-form-field>
                <mat-form-field class="col-3">
                  <mat-label> Disabled From </mat-label>
                  <input autocomplete="new-drpDisabledFrom" formControlName="drpDisabledFrom" maxlength="40" placeholder="MM/DD/YYYY" matInput
                    [matDatepicker]="drpDisabledFrom" [formControl]="claimForm.controls.drpDisabledFrom" />
                  <mat-datepicker-toggle matSuffix [for]="drpDisabledFrom"> </mat-datepicker-toggle>
                  <mat-datepicker #drpDisabledFrom></mat-datepicker>
                  <mat-error *ngIf="
                    claimForm?.get('drpDisabledFrom')?.touched && claimForm?.get('drpDisabledFrom')?.errors
                  ">
                    Enter Valid Date!
                  </mat-error>
                </mat-form-field>
              </div>
              <div class="row">


                <mat-form-field class="col-3">
                  <mat-label> Disabled To</mat-label>

                  <input autocomplete="new-drpDisabledTo" formControlName="drpDisabledTo" [formControl]="claimForm.controls.drpDisabledTo" maxlength="40"
                    placeholder="MM/DD/YYYY" matInput [matDatepicker]="drpDisabledTo"
                    [min]="claimForm.controls.drpDisabledFrom.value" />
                  <mat-datepicker-toggle matSuffix [for]="drpDisabledTo"> </mat-datepicker-toggle>
                  <mat-datepicker #drpDisabledTo></mat-datepicker>
                  <mat-error *ngIf="
                  claimForm?.get('drpDisabledTo')?.touched && claimForm?.get('drpDisabledTo')?.errors
                ">
                    Enter Valid Date!
                  </mat-error>
                </mat-form-field>

                <mat-form-field class="col-3">
                  <mat-label>Last X-Ray </mat-label>
                  <mat-select maxlength="40" [(value)]="Manifest" formControlName="drpManifest"
                    [errorStateMatcher]="matcher">
                    <mat-option *ngFor="let actualManifests of drpActualManifests"
                      [value]="actualManifests.shortCodeId">
                      {{ actualManifests.actualManifestType }}
                    </mat-option>
                  </mat-select>
                  <button mat-button (click)="Manifest='';$event.stopPropagation()" *ngIf="Manifest" matSuffix
                    (click)="clearManifest()" mat-icon-button >
                    <mat-icon>close</mat-icon>
                  </button>
                </mat-form-field>
                <mat-form-field class="col-3">
                  <mat-label> Hospitalized From</mat-label>

                  <input autocomplete="new-drpHospitalizedFrom" formControlName="drpHospitalizedFrom" maxlength="40" placeholder="MM/DD/YYYY" matInput
                    [matDatepicker]="drpHospitalizedFrom" [formControl]="claimForm.controls.drpHospitalizedFrom" />
                  <mat-datepicker-toggle matSuffix [for]="drpHospitalizedFrom"> </mat-datepicker-toggle>
                  <mat-datepicker #drpHospitalizedFrom></mat-datepicker>
                  <mat-error *ngIf="
                claimForm?.get('drpHospitalizedFrom')?.touched && claimForm?.get('drpHospitalizedFrom')?.errors
              ">
                    Enter Valid Date!
                  </mat-error>
                </mat-form-field>
                <mat-form-field class="col-3">
                  <mat-label> Hospitalized To</mat-label>
                  <input autocomplete="new-drpHospitalizedTo" formControlName="drpHospitalizedTo" [formControl]="claimForm.controls.drpHospitalizedTo"
                    maxlength="40" placeholder="MM/DD/YYYY" matInput [matDatepicker]="drpHospitalizedTo"
                    [min]="claimForm.controls.drpHospitalizedFrom.value" />
                  <mat-datepicker-toggle matSuffix [for]="drpHospitalizedTo"> </mat-datepicker-toggle>
                  <mat-datepicker #drpHospitalizedTo></mat-datepicker>
                  <mat-error *ngIf="
                claimForm?.get('drpHospitalizedTo')?.touched && claimForm?.get('drpHospitalizedTo')?.errors
              ">
                    Enter Valid Date!
                  </mat-error>
                </mat-form-field>
              </div>
              <div class="row">



                <mat-form-field class="col-3">
                  <mat-label>Xrays Maintained </mat-label>
                  <mat-select maxlength="40" [(value)]="xrayMaintained" formControlName="drpXrayMaintaine"
                    [errorStateMatcher]="matcher">
                    <mat-option *ngFor="let xraysMaintained of drpXraysMaintained"
                      [value]="xraysMaintained.shortCodeId">
                      {{ xraysMaintained.xraysMaintainedType }}
                    </mat-option>
                  </mat-select>
                  <button mat-button (click)="xrayMaintained='';$event.stopPropagation()" *ngIf="xrayMaintained"
                    matSuffix (click)="clearxrayMaintained()" mat-icon-button >
                    <mat-icon>close</mat-icon>
                  </button>
                </mat-form-field>

                <mat-form-field class="col-3">
                  <mat-label>Last Worked </mat-label>
                  <mat-select maxlength="40" [(value)]="lastWorked" formControlName="drpLastWorked"
                    [errorStateMatcher]="matcher">
                    <mat-option *ngFor="let lastWorked of drpLastWorked" [value]="lastWorked.shortCodeId">
                      {{ lastWorked.lastWorkedType }}
                    </mat-option>
                  </mat-select>
                  <button mat-button (click)="lastWorked='';$event.stopPropagation()" *ngIf="lastWorked" matSuffix
                    (click)="clearlastWorked()" mat-icon-button >
                    <mat-icon>close</mat-icon>
                  </button>
                </mat-form-field>

                <mat-form-field class="col-3">
                  <mat-label>Return To Work </mat-label>
                  <mat-select maxlength="40" [(value)]="returnToWork" formControlName="drpReturnToWork"
                    [errorStateMatcher]="matcher">
                    <mat-option *ngFor="let returnToWorks of drpReturnToWorks" [value]="returnToWorks.shortCodeId">
                      {{ returnToWorks.returnToWorkType }}
                    </mat-option>
                  </mat-select>
                  <button mat-button (click)="returnToWork='';$event.stopPropagation()" *ngIf="returnToWork" matSuffix
                    (click)="clearreturnToWork()" mat-icon-button >
                    <mat-icon>close</mat-icon>
                  </button>
                </mat-form-field>

                <mat-form-field class="col-3">
                  <mat-label>Pat Condition Code </mat-label>
                  <mat-select maxlength="40" [(value)]="patCondn" formControlName="drpPatCondn"
                    [errorStateMatcher]="matcher">
                    <mat-option *ngFor="let patConditionCodes of drpPatConditionCodes"
                      [value]="patConditionCodes.shortCodeId">
                      {{ patConditionCodes.patConditionCodeType }}
                    </mat-option>
                  </mat-select>
                  <button mat-button (click)="patCondn='';$event.stopPropagation()" *ngIf="patCondn" matSuffix
                    (click)="clearpatCondn()" mat-icon-button >
                    <mat-icon>close</mat-icon>
                  </button>
                </mat-form-field>
              </div>
              <div class="row">

                <mat-form-field class="col-3">
                  <mat-label>Auth</mat-label>
                  <input autocomplete="new-txtAuth" formControlName="txtAuth" maxlength="40" matInput type="text" />
                </mat-form-field>
                <mat-form-field class="col-3">
                  <mat-label>Pat Condition 1</mat-label>
                  <input autocomplete="new-txtCondn1" formControlName="txtCondn1" maxlength="40" matInput type="text" />
                </mat-form-field>
                <mat-form-field class="col-3">
                  <mat-label>Block 10d</mat-label>
                  <input autocomplete="new-txtBlock10d" formControlName="txtBlock10d" maxlength="40" matInput type="text" />
                </mat-form-field>
                <mat-form-field class="col-3">
                  <mat-label>Pat Condition 2</mat-label>
                  <input autocomplete="new-txtCondn2" formControlName="txtCondn2" maxlength="40" matInput type="text" />
                </mat-form-field>
              </div>
              <div class="row">



                <mat-form-field class="col-3">
                  <mat-label>Block 19 </mat-label>
                  <mat-select maxlength="40" [(value)]="block19" formControlName="drpBlock19"
                    [errorStateMatcher]="matcher">
                    <mat-option *ngFor="let block19 of drpBlock19" [value]="block19.shortCodeId">
                      {{ block19.block19Type }}
                    </mat-option>
                  </mat-select>
                  <button mat-button (click)="block19='';$event.stopPropagation()" *ngIf="block19" matSuffix
                    (click)="clearblock19()" mat-icon-button >
                    <mat-icon>close</mat-icon>
                  </button>
                </mat-form-field>
                <div class="col-2">
                  <br />
                  <mat-checkbox formControlName="chkAutoAccident">Auto Accident</mat-checkbox>
                </div>
                <div class="col-2">
                  <br />
                  <mat-checkbox formControlName="chkAutoAccidentState">Auto Accident State</mat-checkbox>
                </div>
                <div class="col-2">
                  <br />
                  <mat-checkbox formControlName="chkOtherAccident">Other Accident</mat-checkbox>
                </div>
                <div class="col-3">
                  <br />
                  <mat-checkbox formControlName="chkEmployment">Employment Related</mat-checkbox>
                </div>
              </div>

            </div>
          </div>
        </mat-card-content>
        <br />

      </form>
    </div>
  </div>

  <div class="col-12 text-center pt-4" align="middle">
    <button type="button" mat-button [disabled]="saveButtonHide||claimForm.invalid "
      (click)="saveDefaultClaimInfo()" class="buttonColor mb-2 mr-2">Save
      <mat-icon *ngIf='isShowSpinner'>
        <mat-spinner class="spinner-border spinner-border-sm" diameter="20"> </mat-spinner>
      </mat-icon>
    </button>
  </div>

  <div class="row mt-2" *ngIf="saveButtonHide">
    <div class="col-sm-4">
    </div>
    <div class="col-sm-4">
      <div class="text-lg-center " *ngIf='isShowSpinner'>
        <div class="alert alert-success" role="alert">
          Please Wait,While Data is Saved!
        </div>
      </div>
    </div>
    <div class="col-sm-4">
    </div>
  </div>
</div>
<div class="row">
</div>
