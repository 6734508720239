import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DeliveryTicketService } from 'projects/order/src/app/order-proxy/order-management/delivery-ticket/delivery-ticket.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-ticket-pdf-view',
  templateUrl: './ticket-pdf-view.component.html',
  styleUrls: ['./ticket-pdf-view.component.scss'],
})
export class TicketPdfViewComponent implements OnInit, OnDestroy {
  orderId: string = '';
  strSelectedPdfPath: string = '';
  subscription$: Subscription[] = [];

  constructor(
    public router: Router,
    private activatedRoute: ActivatedRoute,
    private deliveryService: DeliveryTicketService
  ) {}

  ngOnInit(): void {
    this.activatedRoute.paramMap.subscribe((response) => {
      this.orderId = response?.get('id') ?? '';
    });
    this.getPdfView();
  }

  ngOnDestroy(): void {
    this.subscription$?.forEach((sub) => {
      sub && sub?.unsubscribe();
    });
  }

  backToPack(): void {
    // orders/readyToDeliverList
    this.router.navigate(['orders/readyToDeliverList']);
  }

  ///view pdf ticket
  getPdfView() {
    const deliveryService = this.deliveryService
      .getDeliveryTicketDocumentByOrderId(this.orderId)
      .subscribe((res) => {
        this.strSelectedPdfPath = String('data:application/pdf;base64,' + res);
      });
    this.subscription$.push(deliveryService);
  }
}
