import type { CreateUpdateMMPurchaseOrderDTO, MMPurchaseOrderDTO, createPurchaseOrderDTO } from './dto/models';
import { RestService } from '@abp/ng.core';
import type { PagedAndSortedResultRequestDto, PagedResultDto } from '@abp/ng.core';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class MMPurchaseOrderService {
  apiName = 'ItemManagement';

  create = (input: CreateUpdateMMPurchaseOrderDTO) =>
    this.restService.request<any, MMPurchaseOrderDTO>({
      method: 'POST',
      url: '/api/ItemApp/m-mPurchase-order',
      body: input,
    },
    { apiName: this.apiName });

  delete = (id: string) =>
    this.restService.request<any, void>({
      method: 'DELETE',
      url: `/api/ItemApp/m-mPurchase-order/${id}`,
    },
    { apiName: this.apiName });

  get = (id: string) =>
    this.restService.request<any, MMPurchaseOrderDTO>({
      method: 'GET',
      url: `/api/ItemApp/m-mPurchase-order/${id}`,
    },
    { apiName: this.apiName });

  getList = (input: PagedAndSortedResultRequestDto) =>
    this.restService.request<any, PagedResultDto<MMPurchaseOrderDTO>>({
      method: 'GET',
      url: '/api/ItemApp/m-mPurchase-order',
      params: { skipCount: input.skipCount, maxResultCount: input.maxResultCount, sorting: input.sorting },
    },
    { apiName: this.apiName });

  update = (id: string, input: CreateUpdateMMPurchaseOrderDTO) =>
    this.restService.request<any, MMPurchaseOrderDTO>({
      method: 'PUT',
      url: `/api/ItemApp/m-mPurchase-order/${id}`,
      body: input,
    },
    { apiName: this.apiName });

  createPurchaseOrderByPurchaseOrderDetails = (purchaseOrderDetails: createPurchaseOrderDTO) =>
    this.restService.request<any, createPurchaseOrderDTO>({
      method: 'POST',
      url: '/api/ItemApp/m-mPurchase-order/create-purchase-order',
      body: purchaseOrderDetails,
    },
    { apiName: this.apiName });

  generatePurchaseOrderId = () =>
    this.restService.request<any, number>({
      method: 'POST',
      url: '/api/ItemApp/m-mPurchase-order/generate-purchase-order-id',
    },
    { apiName: this.apiName });

  constructor(private restService: RestService) {}
}
