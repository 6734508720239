import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

import { MasterRulesDTO } from '../admin-proxy/platform-app-management/rcm/platform-management/tenants/dto';
import { ProductNotificationDTO } from '../warehouse-proxy/rcm/warehouse-management/warehouse/dto/models';
import { ProductNotification } from './product-notification';


@Injectable({
  providedIn: 'root'
})
export class ReminderService {
  timer = 1000000;

  public _subject = new Subject<ProductNotification>();
  public _expirySubject = new Subject<any>();
  private _idx = 0;
  private _edx = 0;

  constructor() { }

  getObservable(): Observable<ProductNotification | any> {
    return this._subject?.asObservable();
  }

  success(titleMsg: string, ProductNotificationDTO: ProductNotificationDTO | MasterRulesDTO) {
    this._subject?.next(new ProductNotification(this._idx++, titleMsg, ProductNotificationDTO, this.timer));
  }

  warning(titleMsg: string, ProductNotificationDTO: ProductNotificationDTO) {
    this._subject?.next(new ProductNotification(this._idx++, titleMsg, ProductNotificationDTO, this.timer));
  }

  error(titleMsg: string, ProductNotificationDTO: ProductNotificationDTO) {
    this._subject?.next(new ProductNotification(this._idx++, titleMsg, ProductNotificationDTO, this.timer));
  }
  clear() {
    this._subject?.next();
  }



  getExpiryObservable(): Observable<any> {
    return this._expirySubject?.asObservable();
  }

  successExpiry(titleMsg: string, ExpiryNotify: { name: string, defaultId: string, msg: string }) {
    this._expirySubject?.next(new ExpiryNotifications(this._edx++, titleMsg, ExpiryNotify, this.timer));
  }

  warningExpiry(titleMsg: string, ExpiryNotify: { name: string, defaultId: string, msg: string }) {
    this._expirySubject?.next(new ExpiryNotifications(this._edx++, titleMsg, ExpiryNotify, this.timer));
  }

  errorExpiry(titleMsg: string, ExpiryNotify: { name: string, defaultId: string, msg: string }) {
    this._expirySubject?.next(new ExpiryNotifications(this._edx++, titleMsg, ExpiryNotify, this.timer));
  }
  clearExpiry() {
    this._expirySubject?.next();
  }
}
export enum ProductStatus {
  InStock = 'InStock',
  LowStock = 'LowStock',
  OutOfStock = 'OutOfStock',
}

export class ExpiryNotifications {
  id: number;
  titleMsg: string;
  localExpiryNotification: { name: string, defaultId: string, msg: string };
  timeout: number;
  constructor(idx?: number, titleMsg?: string, globalExpiryNotification?: { name: string, defaultId: string, msg: string }, timeout?: number) {
    this.id = idx;
    this.titleMsg = titleMsg;
    this.localExpiryNotification = globalExpiryNotification;
    this.timeout = timeout;
  }
}

