<mat-dialog-content class="mat-typography">
  <div class="container-fluid">
    <div class="row">
      <!-- Header -->
      <div class="col-12 mb-2">
        <div class="d-flex justify-content-between align-items-center">
          <div>
            <p class="header-text mb-0">Adjustments</p>
          </div>
          <button mat-icon-button class="close-button" mat-dialog-close>
            <mat-icon>close</mat-icon>
          </button>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-12">
        <div class="card card-body p-2">
          <form [formGroup]="adjustmentForm">
            <mat-form-field class="col-6">
              <mat-label
                >Inventory Adjustment Reason
                <span class="asterisk">*</span></mat-label
              >
              <mat-select
                (selectionChange)="onAdjustmentReason($event)"
                #singleSelect
                class="dd-text"
                formControlName="AdjustmentReason"
              >
                <mat-option>
                  <ngx-mat-select-search
                    placeholderLabel="Search"
                    noEntriesFoundLabel="No Matches found"
                    formControlName="txtAdjustmentReason"
                  ></ngx-mat-select-search>
                </mat-option>
                <mat-option
                  *ngFor="let data of filteredAdjustmentList | async"
                  [value]="data"
                >
                  {{ data.adjustmentReason }}
                </mat-option>
              </mat-select>
              <button
                mat-button
                (click)="MainProduct = undefined; $event.stopPropagation()"
                *ngIf="MainProduct"
                matSuffix
                (click)="clearMainProduct()"
                mat-icon-button
              >
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field>
            <mat-form-field class="col-6">
              <mat-label
                >Other Reason Text<span
                  *ngIf="isReasonValidator == true"
                  class="asterisk"
                  >*</span
                >
              </mat-label>
              <input
                class="dd-text"
                autocomplete="new-txtFaxId"
                maxlength="50"
                formControlName="ReasonText"
                matInput
              />
            </mat-form-field>
            <mat-form-field class="col-12">
              <mat-label>Adjustment Notes</mat-label>
              <textarea
                class="dd-text"
                autocomplete="new-txtNotes"
                matInput
                formControlName="txtNotes"
                maxlength="5000"
                type="text"
              ></textarea>
            </mat-form-field>
          </form>
        </div>
      </div>
    </div>

    <div class="row mt-2">
      <div class="col-lg-12">
        <div class="card card-body px-2">
          <div class="d-flex justify-content-center" *ngIf="!loadTable">
            <img
              class="img-responsive pb-2 mr-1"
              src="assets/gifs/Infinity-loader.gif"
            />
          </div>

          <div *ngIf="loadTable" class="table table-responsive">
            <table
              datatable
              [dtOptions]="dtInboundOptions"
              class="display row-border hover w-100"
            >
              <thead>
                <tr>
                  <th>Prod Code</th>
                  <th>Description</th>
                  <th>Serial/Lot No</th>
                  <th>Physical</th>
                  <th>Quantity On Hand</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let data of loadAdjust">
                  <td [matTooltip]="'Product Code'">
                    <mat-select
                      class="dd-text"
                      (selectionChange)="onDropdownSelect($event)"
                      [(value)]="data.productId"
                      placeholder="Product Code"
                    >
                      <mat-option>
                        <ngx-mat-select-search
                          placeholderLabel="Search"
                          noEntriesFoundLabel="No Matches found"
                          [formControl]="searchControl"
                        ></ngx-mat-select-search>
                      </mat-option>

                      <mat-option
                        *ngFor="let option of filteredOptions"
                        [value]="option.productId"
                      >
                        {{ option.productName }}
                      </mat-option>
                    </mat-select>
                    <button
                      mat-button
                      (click)="product = undefined; $event.stopPropagation()"
                      *ngIf="product"
                      matSuffix
                      (click)="clearProduct()"
                      mat-icon-button
                    >
                      <mat-icon>close</mat-icon>
                    </button>
                  </td>

                  <td [matTooltip]="data?.productName">
                    {{ data?.productName }}
                  </td>

                  <td [matTooltip]="'Serial/Lot No'">
                    <input
                      class="dd-text"
                      placeholder="Serial/Lot No"
                      class="form-control dd-text"
                      maxlength="15"
                      [(ngModel)]="data.serialLotNumber"
                    />
                  </td>

                  <td [matTooltip]="'Physical'">
                    <input
                      class="form-control dd-text"
                      maxlength="8"
                      placeholder="Physical"
                      (keypress)="onKeyPress($event)"
                      [(ngModel)]="data.physical"
                    />
                  </td>
                  <td [matTooltip]="'Quantity On Hand' " class="text-right">
                    <input
                      class="form-control dd-text text-right"
                      maxlength="8"
                      placeholder="Quantity on Hand"
                      (keypress)="onKeyPress($event)"
                      [(ngModel)]="data.qoh"
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end" class="px-4">
  <!-- Button Actions -->
  <button
    mat-button
    class="buttonColor"
    (click)="saveDetails()"
    [disabled]="!(!adjustmentForm?.invalid && adjustmentForm?.dirty)"
  >
    Save
  </button>
  <!-- <button mat-button class="buttonColor" [disabled]="isSubmit != true">
    Submit
  </button> -->
  <!-- <button class="resetclr" mat-button>Reset</button> -->
</mat-dialog-actions>
