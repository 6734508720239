import { RestService } from '@abp/ng.core';
import { Injectable } from '@angular/core';
import type { NPIDoctorDTO, NPIDoctorDTOs } from '../patient/dto/models';

@Injectable({
  providedIn: 'root',
})
export class GetDoctorNpiService {
  apiName = 'Default';

  getNpiDoctorsByInput = (input: NPIDoctorDTO) =>
    this.restService.request<any, NPIDoctorDTOs[]>({
      method: 'GET',
      url: '/api/app/npiDoctor/Get',
      params: { number: input.number, enumerationType: input.enumerationType, taxonomyDescription: input.taxonomyDescription, firstName: input.firstName, useFirstNameAlias: input.useFirstNameAlias, lastName: input.lastName, organizationName: input.organizationName, addressPurpose: input.addressPurpose, city: input.city, state: input.state, postalCode: input.postalCode, countryCode: input.countryCode },
    },
     { apiName: this.apiName, skipHandleError: true });

     getNpiDoctorsByMedicare = (input: NPIDoctorDTO) =>
     this.restService.request<any, NPIDoctorDTOs[]>({
       method: 'GET',
       url: '/api/app/npiDoctor/Get',
       params: { number: input.number, enumerationType: input.enumerationType, taxonomyDescription: input.taxonomyDescription, firstName: input.firstName, useFirstNameAlias: input.useFirstNameAlias, lastName: input.lastName, organizationName: input.organizationName, addressPurpose: input.addressPurpose, city: input.city, state: input.state, postalCode: input.postalCode, countryCode: input.countryCode,isMedicare:input.isMedicare,
        facilityId:input.facilityId
        },
     },
      { apiName: this.apiName, skipHandleError: true });

  constructor(private restService: RestService) {}
}
