import { PagedAndSortedResultRequestDto } from '@abp/ng.core';
import { ChangeDetectorRef, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { defaultCountry } from 'projects/shared/src/app/enums/allenums.enum';
import { map, startWith } from 'rxjs/operators';
import { VendorService } from '../item-proxy/item-management/items/vendor.service';
import { RegionCitiesDTO, RegionCountriesDTO, RegionStatesDTO } from 'projects/patient/src/app/dropdown-proxy/dropdown-management/dropdowns/dto/models';
import { HttpErrorResponse } from '@angular/common/http';
import Swal from 'sweetalert2';
import { RegionDropdownService } from 'projects/patient/src/app/dropdown-proxy/dropdown-management/dropdowns/region-dropdown-service';
import { CreateUpdatePurchaseOrderDTO } from '../item-proxy/item-management/items/dto/models';
import { PurchaseOrderService } from '../item-proxy/item-management/items/purchase-order.service';
import { ProductType } from '../item-proxy/item-management/items/product-type.enum';
import { ProductService } from '../item-proxy/item-management/items/product.service';
import { ActivatedRoute, Router } from '@angular/router';
import { iif, Subscription } from 'rxjs';
import { ToastrService } from "ngx-toastr";

@Component({
  selector: 'app-add-purchase-order',
  templateUrl: './add-purchase-order.component.html',
  styleUrls: ['./add-purchase-order.component.scss']
})
export class AddPurchaseOrderComponent implements OnInit , OnDestroy {
  purchaseOrderForm: FormGroup;
  Country: string = '';
  State: string = '';
  City: string = '';
  stateName: string = '';
  CountryName: string = '';
  showButton: boolean = false
  drpCountry: RegionCountriesDTO[] = [];
  drpStates: RegionStatesDTO[] = [];
  drpCities: RegionCitiesDTO[] = [];
  subscription$: Subscription[] = [];
  constructor(private formBuilder: FormBuilder,
    // public dialogRef: MatDialogRef<AddPurchaseOrderComponent>,
    // @Inject(MAT_DIALOG_DATA) public data: { poId: string },
    private VendorsService: VendorService,
    private region: RegionDropdownService,
    private ref: ChangeDetectorRef,
    private POService: PurchaseOrderService,
    private productService: ProductService,
    public router: Router,
    private activatedRoute: ActivatedRoute,
    private toastr: ToastrService,
  ) { }
  row = [];
  purchaseId: string = undefined;
  ngOnInit(): void {
    this.purchaseOrderForm = this.formBuilder.group({
      txtVendorName: new FormControl('', Validators.required),
      txtVendorFilter: new FormControl(''),
      txtVCode: new FormControl(''),
      txtVAddress: new FormControl(''),
      txtVEmail: new FormControl(''),
      txtVCountry: new FormControl(''),
      txtVState: new FormControl(''),
      txtVCity: new FormControl(''),
      txtVContactNo: new FormControl(''),
      txtCountryFilter: new FormControl(""),
      txtStateFilter: new FormControl(""),
      txtCityFilter: new FormControl(""),
      txtTblVendor: new FormControl(""),

    })
    this.activatedRoute.paramMap.subscribe((response) => {
      if (response?.get('id')) {
        this.purchaseId = response?.get('id') ?? '';
      }

    });
    if (this.purchaseId == undefined) {
      this.showButton = true;
    } else {
      this.showButton = false;
    }
    this.getDropDown();
  }

  
  ngOnDestroy(): void {
    this.subscription$?.forEach((sub) => {
      sub && sub?.unsubscribe();
    });
  }

  //// add into stock
  addToStock(status) {
    if (status == 'Add Stock') {
      this.router.navigate(['inventory/stock-list']);
    }
  }

  /// back to purchase order list
  backToList() {
    this.router.navigate(['inventory/purchase-order']);
  }

  ///// get all dropdown
  filterVendor;
  drpVendor: any[] = [];
  phoneCode: string;
  filteredCountries;
  filteredStates;
  filteredCities;
  filterMainProduct;
  drpMainProduct: any[] = [];
  getDropDown() {
    //// vendor getlist
    const vendorGetList = this.VendorsService.getList(new PagedAndSortedResultRequestDto).subscribe(response => {
      response?.items?.forEach(element => {
        if (element?.status == 'Active') {
          this.drpVendor.push({
            name: element.vendorName,
            id: element.id,
            email: element?.vendorEmailId,
            code: element?.vendorCode,
            address: element?.vendorAddress,
            country: element?.country,
            state: element?.state,
            city: element?.city,
            contactNo: element?.contactNo
          });
        }
        this.filterVendor = this.purchaseOrderForm?.get("txtVendorFilter").valueChanges
          .pipe(
            startWith(''),
            map(value => this.drpVendor.filter(option => option?.name?.toLowerCase()?.includes(value?.toLowerCase() ?? "")))
          );

      })
    })
    this.subscription$.push(vendorGetList);
    /////product
    const getProductById = this.productService.getProductsByInput(ProductType.All).subscribe(response => {
      this.drpMainProduct = response
    })
    this.subscription$.push(getProductById);
    //
    this.countryDropdown();
    if (this.purchaseId !== undefined && this.purchaseId !== "" && this.purchaseId !== null) {
      this.getById()
    }

  }

  ////view purchase order
  getById() {
    const productGet = this.POService.get(this.purchaseId).subscribe(response => {
      this.purchaseOrderForm.patchValue({
        txtVendorName: response?.vendorName,
        txtVCode: response?.vendorCode,
        txtVAddress: response?.vendorAddress,
        txtVEmail: response?.vendorEmailId,
        txtVCountry: response?.country,
        txtVState: response?.state,
        txtVCity: response?.city,
        txtVContactNo: response?.contactNo,
      })
      this.row = response?.purchaseOrderProducts
    })
    this.subscription$.push(productGet);
  }


  //// save and update purchase order
  savePurchaseOrder() {
    let poDet: any[] = [];

    this.row.forEach(element => {
      let productId = this.drpMainProduct?.filter(value => {
        return value && value?.productName === element.productName
      })[0]?.productId;

      poDet.push({
        productId: productId,
        productName: element?.productName,
        quantity: +(element?.quantity),
        isProductReceived: element?.isProductReceived,
      })
    })

    if (this.purchaseId == undefined || this.purchaseId == "" || this.purchaseId == null) {
      let vendor = this.drpVendor?.filter(value => {
        return value && value?.name === this.purchaseOrderForm.value.txtVendorName
      })[0]?.id;
      let PODetail: CreateUpdatePurchaseOrderDTO = {
        purchaseOrderNo: "",
        vendorId: vendor,
        vendorName: this.purchaseOrderForm.value.txtVendorName,
        vendorCode: this.purchaseOrderForm.value.txtVCode,
        vendorAddress: this.purchaseOrderForm.value.txtVAddress,
        vendorEmailId: this.purchaseOrderForm.value.txtVEmail,
        country: this.purchaseOrderForm.value.txtVCountry,
        state: this.purchaseOrderForm.value.txtVState,
        city: this.purchaseOrderForm.value.txtVCity,
        contactNo: this.purchaseOrderForm.value.txtVContactNo,
        purchaseOrderProducts: poDet,
        purchaseOrderRaisedDate: "",
        purchaseOrderRaisedBy: "",
      }
      this.POService.create(PODetail).subscribe(response => {
        // Swal.fire({ title: 'Success', html: 'Saved Successfully', icon: 'success', timer: 3000, timerProgressBar: true });
        this.toastr.success('Saved Successfully','Success')
        // this.dialogRef.close();

      }, err => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      });
    } else {
      let poDet: any[] = [];
      /// get byId
      const posGet = this.POService.get(this.purchaseId).subscribe(response => {
        this.row = response?.purchaseOrderProducts
      })
      this.subscription$.push(posGet);
      //
      this.row.forEach(element => {
        let productId = this.drpMainProduct?.filter(value => {
          return value && value?.productName === element.productName
        })[0]?.productId;

        poDet.push({
          productId: productId,
          productName: element?.productName,
          quantity: +(element?.quantity),
          isProductReceived: element?.isProductReceived,
          purchaseOrderProductId: element?.purchaseOrderProductId
        })
      })
      let vendor = this.drpVendor?.filter(value => {
        return value && value?.name === this.purchaseOrderForm.value.txtVendorName
      })[0]?.id;
      let PODetail: CreateUpdatePurchaseOrderDTO = {
        purchaseOrderNo: "",
        vendorId: vendor,
        vendorName: this.purchaseOrderForm.value.txtVendorName,
        vendorCode: this.purchaseOrderForm.value.txtVCode,
        vendorAddress: this.purchaseOrderForm.value.txtVAddress,
        vendorEmailId: this.purchaseOrderForm.value.txtVEmail,
        country: this.purchaseOrderForm.value.txtVCountry,
        state: this.purchaseOrderForm.value.txtVState,
        city: this.purchaseOrderForm.value.txtVCity,
        contactNo: this.purchaseOrderForm.value.txtVContactNo,
        purchaseOrderProducts: poDet,
        purchaseOrderRaisedDate: "",
        purchaseOrderRaisedBy: "",
      }
      this.POService.update(this.purchaseId, PODetail).subscribe(response => {
        // Swal.fire({ title: 'Success', html: 'Saved Successfully', icon: 'success', timer: 3000, timerProgressBar: true });
        this.toastr.success('Saved Successfully','Success')
        // this.dialogRef.close();
        this.purchaseId = response?.id
        this.getById()
      }, err => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      });
    }
  }

  // vendor dropdown
  countryDropdown() {
    this.purchaseOrderForm.patchValue({ "txtCountry": defaultCountry.US })
    this.onChangeState(defaultCountry.US);
    this.Country = defaultCountry.US;
    this.drpCountry = [];
    const countryDropdown = this.region.getAllCountriesByInput(new PagedAndSortedResultRequestDto).subscribe(response => {
      this.drpCountry = response.items;
      this.filteredCountries = this.purchaseOrderForm?.get("txtCountryFilter").valueChanges
        .pipe(
          startWith(''),
          map(value => this.drpCountry.filter(option => option?.countryName?.toLowerCase()?.includes(value?.toLowerCase() ?? "")))
        );
    }, err => {
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    });
    this.subscription$.push(countryDropdown);
  }

  //  state city//
  onChangeState(value: string | null | undefined): boolean {
    this.purchaseOrderForm.patchValue({
      txtState: null,
      txtCity: null
    })
    this.drpStates = [];
    this.drpCities = [];
    if (value !== null && value !== undefined) {
      const stateDropdown = this.region.getAllStatesByCountryNameByCountryShortName(value).subscribe(response => {

        this.drpStates = response.items;
        this.ref.detach();
        if (response.items == []) {

          this.purchaseOrderForm.controls['txtVContactNo'].disable();
          this.phoneCode = "";
        } else {

          this.purchaseOrderForm.controls['txtVContactNo'].enable();
          this.phoneCode = response?.items[0]?.countryPhoneCode;
        }
        this.ref.reattach();
        this.filteredStates = this.purchaseOrderForm?.get("txtStateFilter").valueChanges
          .pipe(
            startWith(''),
            map(value => this.drpStates.filter(option =>
              option?.stateName?.toLowerCase()?.includes(value?.toLowerCase() ?? "")
            ))
          );
      }, err => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      })
      this.subscription$.push(stateDropdown);
    }
    return this.drpStates !== [] && this.drpStates.length !== 0;
  }

  /// state pass for city
  onChangeCity(state: string | null | undefined, country: string): boolean {
    // this.purchaseOrderForm.patchValue({
    //   txtBillingCity: null
    // });
    country = (country == "") ? (this.purchaseOrderForm.value.txtCountry == (null || undefined) ? "" : (this.purchaseOrderForm.value.txtCountry)) : country;
    this.drpCities = [];
    if (state !== null && state !== undefined){
     const getCities = this.region.getAllCitiesByCountryAndStateNameByCountryShortNameAndStateShortName(country, state).subscribe(response => {
        this.drpCities = response.items;
        this.filteredCities = this.purchaseOrderForm?.get("txtCityFilter").valueChanges
          .pipe(
            startWith(''),
            map(value => this.drpCities.filter(option =>
              option?.cityName?.toLowerCase()?.includes(value?.toLowerCase() ?? "")))
          );

      }, err => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      });
      this.subscription$.push(getCities);
    }

    return this.drpCities !== [] && this.drpCities.length !== 0;
  }




  /// to get vendor
  onChanges(event) {
    let vendorDetail = this.drpVendor?.filter(value => {
      return value && value?.name === event?.value
    })[0];

    this.purchaseOrderForm.patchValue({
      txtVCode: vendorDetail?.code,
      txtVAddress: vendorDetail?.address,
      txtVEmail: vendorDetail?.email,
      txtVCountry: vendorDetail?.country,
      txtVState: vendorDetail?.state,
      txtVCity: vendorDetail?.city,
      txtVContactNo: vendorDetail?.contactNo
    })
  }

  /// table row add
  addTable() {
    // this.purchaseOrderForm.patchValue({
    //   txtTblVendor:''
    // })
    const obj = {
      productName: '',
      quantity: '',
      isProductReceived: false,
      productId: ''
    }
    // if(this.row.length !==0){
    //   this.row[0].vendor=''

    // }
    this.row.unshift(obj)
  }

  //// confirmation for product received checkbox
  Confirmation(i) {
    Swal.fire({
      title: 'Are you sure you want to click?',
      text: 'Details cannot be modifed in further',
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#34c38f',
      cancelButtonColor: '#f46a6a',
      confirmButtonText: 'Yes, Confirm!'
    }).then(result => {

      if (result.isConfirmed == true) {
        this.row[i].isProductReceived = true
      }
      if (result.isConfirmed == false) {
        this.row[i].isProductReceived = false
      }
    });
  }



  /// to delete the record
  deleteRow(x) {
    // var delBtn = confirm("Do you want to delete ?");
    // if (delBtn == true) {
    this.row.splice(x, 1);
    // }
  }

  supplyVendor: string = '';
  clearsupplyVendor() {
    this.supplyVendor = '';
    this.purchaseOrderForm.patchValue({ txtVendorName: "" });
  }
  clearCountry() {
    this.Country = '';
    this.phoneCode = '';
    this.purchaseOrderForm.patchValue({ drpBillingCountry: "" });
    this.purchaseOrderForm.controls['txtPersonPhoneNumber'].disable();
  }
  clearState() {
    this.State = '';
    this.purchaseOrderForm.patchValue({ txtState: "" });
  }
  clearCity() {
    this.City = '';
    this.purchaseOrderForm.patchValue({ txtCity: "" });
  }
  MainProduct: string = '';
  clearMainProduct() {
    this.MainProduct = '';
    // this.purchaseOrderForm.patchValue({ txtMainProduct: "" });
  }
  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;

  }
}

