<br />
<div class="card card-body">
  <!-- <div class="list-box"> -->
  <div class="row">
    <div class="col-md-6">
      <h4 class="customThemeClass"><b>Workers Compensation</b></h4>
    </div>
    <div class="col-md-6 text-sm-right">
      <div class="text-sm-right col-lg-12 p-0">
        <button *ngIf='isPatientLockedByCurrentUser' mat-button class="buttonColor mr-1 font-size-13 btn-sm"
          (click)="openPolicyModal()">
          <!-- <i class="mdi mdi-plus font-size-16 mr-1"></i> -->
          <i class="fa fa-plus mr-1" ></i>
          Add Policy
        </button>
        <!-- <button type="button" class="buttonColor mr-1 font-size-12"
        (click)="openPolicyModal(policyModal)"><i class="mdi mdi-plus mr-1"></i>Add Policy</button> -->

        <!-- <button mat-button class="buttonColor">Save</button> -->
      </div>
    </div>
  </div>
  <form [formGroup]="InsuranceForm">
    <div class="row">
      <div class="col-lg-12">
        <mat-card-content>
          <div class="row">
            <div class="col-4">
              <br>
              <mat-checkbox formControlName="chkInjuryEmployment">Injury Related to Employment (Current or Previous)
              </mat-checkbox>
            </div>
            <div class="col-4">
              <br>
              <mat-checkbox formControlName="chkInjuryAutoAccident">Injury Related to Auto Accident</mat-checkbox>
            </div>
            <div class="col-4">
              <br>
              <mat-checkbox formControlName="chkInjuryOtherAccident">Injury Related to Other Accident</mat-checkbox>
            </div>
            <mat-form-field class="col-4">
              <mat-label> Date On Set</mat-label>
              <input autocomplete="new-txtDateOnSet" formControlName="txtDateOnSet" matInput maxlength="40" placeholder="(MM/DD/YYYY)"
                [matDatepicker]="txtDateOnSet">
              <mat-datepicker-toggle matSuffix [for]="txtDateOnSet">
              </mat-datepicker-toggle>
              <mat-datepicker #txtDateOnSet></mat-datepicker>

              <mat-error *ngIf="
          InsuranceForm?.get('txtDateOnSet')?.touched && InsuranceForm?.get('txtDateOnSet')?.errors
          ">
                Enter Valid Date!
              </mat-error>
            </mat-form-field>
            <mat-form-field class="col-4">
              <mat-label>State of Auto Accident</mat-label>
              <mat-select formControlName="drpInsStateofAutoAccident" [(value)]="stateofAutoAccident" maxlength="40">
                <mat-option *ngFor=" let state of drpStateLoop" [value]="state.stateOfAutoAccidentShortCodeId">
                  {{state.stateOfAutoAccidentType}}
                </mat-option>
              </mat-select>
              <button mat-button (click)="stateofAutoAccident='';$event.stopPropagation()"
                (click)="clearStateofAutoAccident()" *ngIf="stateofAutoAccident" matSuffix mat-icon-button
                >
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field>
          </div>
        </mat-card-content>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-6">
        <mat-card-content>

          <div class="row">
            <div class="col-md-6">
              <h4 class="customThemeClass"><b>Status</b></h4>
            </div>
          </div>

          <div class="row">
            <mat-form-field class="col-6">
              <mat-label>Marital Status</mat-label>
              <mat-select formControlName="drpInsMarital" [(value)]="maritalStatus" maxlength="40">
                <mat-option *ngFor=" let martail of drpmartailStatusLoop" [value]="martail.maritalStatusTypeId">
                  {{martail.maritalStatusType}}
                </mat-option>
              </mat-select>
              <button mat-button (click)="maritalStatus='';$event.stopPropagation()" *ngIf="maritalStatus"
                (click)="clearMaritalStatus()" matSuffix mat-icon-button >
                <mat-icon>close</mat-icon>
              </button>

            </mat-form-field>
            <mat-form-field class="col-6">
              <mat-label>Employment</mat-label>

              <mat-select formControlName="drpInsEmployment" [(value)]="employment" maxlength="40">
                <mat-option *ngFor=" let employment of drpEmploymentLoop" [value]="employment.employmentTypeId">
                  {{employment.employmentType}}
                </mat-option>
              </mat-select>
              <button mat-button (click)="employment='';$event.stopPropagation()" *ngIf="employment" matSuffix
                (click)="clearEmployment()" mat-icon-button >
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field>

          </div>
        </mat-card-content>
      </div>
      <div class="col-lg-6">
        <mat-card-content>
          <div class="row">
            <div class="col-md-6">
              <h4 class="customThemeClass"><b>Form Default</b></h4>
            </div>
          </div>
          <div class="row">
            <div class="col-6">
              <br>
              <mat-checkbox formControlName="chkInsPrintAmounts">Print Amounts on Delivery Ticket</mat-checkbox>
            </div>

          </div>
        </mat-card-content>

      </div>

    </div>
    <div class="row">
      <div class="col-lg-12">
        <mat-card-content>

          <div class="row">
            <div class="col-md-6">
              <h4 class="customThemeClass"><b>Patient Financial Hardship</b></h4>
            </div>
          </div>
          <div class="row">
            <div class="col-3">
              <br>
              <mat-checkbox formControlName="chkInsHardshipPatient">Hardship Patient</mat-checkbox>
            </div>
            <mat-form-field class="col-3">
              <mat-label>Discount Pct</mat-label>
              <input autocomplete="new-txtDiscount" formControlName="txtDiscount" matInput suffix="%" mask="00" maxlength="45" type="text">
              <mat-error *ngIf="
            InsuranceForm?.get('txtDiscount')?.touched &&
            InsuranceForm?.get('txtDiscount')?.errors
          ">
                Enter Minimum 2 Digits!
              </mat-error>
            </mat-form-field>
            <mat-form-field class="col-3">
              <mat-label>Start Date</mat-label>
              <input autocomplete="new-txtStartDate" [min]="BirthdateMinDate" formControlName="txtStartDate" matInput maxlength="40"
                placeholder="(MM/DD/YYYY)" [min]="BirthdateMaxDate" [matDatepicker]="txtStartDate">
              <mat-datepicker-toggle matSuffix [for]="txtStartDate">
              </mat-datepicker-toggle>
              <mat-datepicker #txtStartDate></mat-datepicker>
              <mat-error *ngIf="
          InsuranceForm?.get('txtStartDate')?.touched && InsuranceForm?.get('txtStartDate')?.errors
          ">
                Enter Valid Date!
              </mat-error>
              <mat-error
                *ngIf=" BirthdateMaxDate>=InsuranceForm?.get('txtStartDate')?.value && !(InsuranceForm?.get('txtStartDate')?.touched && InsuranceForm?.get('txtStartDate')?.errors)">
                Enter Future Dates Only!
              </mat-error>
            </mat-form-field>
            <mat-form-field class="col-3">
              <mat-label>Review Date</mat-label>
              <input autocomplete="new-txtReviewDate" formControlName="txtReviewDate" matInput maxlength="40" placeholder="(MM/DD/YYYY)"
                [min]="InsuranceForm.controls.txtStartDate.value" [matDatepicker]="txtReviewDate">
              <mat-datepicker-toggle matSuffix [for]="txtReviewDate">
              </mat-datepicker-toggle>
              <mat-datepicker #txtReviewDate></mat-datepicker>
              <mat-error *ngIf="
          InsuranceForm?.get('txtReviewDate')?.touched && InsuranceForm?.get('txtReviewDate')?.errors
          ">
                Enter Valid Date!
              </mat-error>
            </mat-form-field>

          </div>
        </mat-card-content>
      </div>
    </div>
  </form>
  <br>
  <div class="row">
    <div class="col-sm-12">
      <div class="text-lg-center ">

        <button *ngIf='(isShownSaveButton || isShownUpdateIconForPatient) && isPatientLockedByCurrentUser'
          [disabled]=" saveButtonHide || !(!InsuranceForm.invalid && (InsuranceForm.dirty ))" mat-button
          class="buttonColor" (click)="getPatientDetailsForSave()">Save
          <mat-icon *ngIf='isShowSpinner'>
            <mat-spinner class="spinner-border spinner-border-sm" diameter="20"> </mat-spinner>
          </mat-icon>
        </button>
      </div>
    </div>
  </div>
  <br>
  <!-- <div class="row"> -->
  <div class="col-12 table-responsive">
    <app-table [strPageType]="strPageType" [isLoading]="isLoading" (viewButton)="ViewPolciy($event)"
      (deleteButton)="deletePolicy($event)">
    </app-table>
  </div>
</div>
<!-- </div> -->
