import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Reminder, RemindersService, ReminderType } from 'projects/order/src/app/reminder-message/reminders.service';
import { TableService } from 'projects/shared/src/app/table.service';
import { Subscription } from 'rxjs';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-billing-reminder-message',
  templateUrl: './billing-reminder-message.component.html',
  styleUrls: ['./billing-reminder-message.component.scss']
})
export class BillingReminderMessageComponent implements OnInit {
  reminders: Reminder[] = [];
  private _subscription: Subscription;
  organizationUnitId=null;

  constructor(
    private tableService: TableService,
    private reminderService: RemindersService,
  //  private snackBar: MatSnackBar,
   // @Inject(MAT_SNACK_BAR_DATA) public data: { patientName?: string, subject?: string }
    ) {

     }

  patientName: string = "";
  reminderSubject: string = "";
  loginUserName: string = "";

  ngOnInit(): void {
    this._subscription = this.reminderService.getObservable().subscribe(reminder =>
    {
      this._addReminder(reminder)});
    this.tableService.getLoginUserName().subscribe(val => {
      this.loginUserName = val;
    }, err => {
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    })
  }

  close() {
  //  this.snackBar.dismiss();
  }


//// close reminder based on id
  closeReminder(reminder: Reminder) {
    this.reminders = this.reminders.filter(rem => rem.id !== reminder.id);
  }

  /// reminder timeout close
  private _addReminder(reminder: Reminder) {
    this.reminders.push(reminder);
    if (reminder.timeout !== 0) {
      setTimeout(() => this.closeReminder(reminder), reminder.timeout);
    }
  }
  ngOnDestroy() {
    this._subscription.unsubscribe();
  }

  className(reminder: Reminder): string {
    let style: string;
    switch (reminder.type) {
      case ReminderType.success: style = 'success'; break;
      case ReminderType.warning: style = 'warning'; break;
      case ReminderType.error: style = 'error'; break;
      default: style = 'info'; break;
    }
    return style;
  }
}

