import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { defaultGuid } from 'projects/shared/src/app/enums/allenums.enum';
import { Subscription } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import Swal from 'sweetalert2';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DataTableDirective } from 'angular-datatables';
import { CommunicationService } from 'projects/shared/src/app/services/communication.service';
import { ToastrService } from 'ngx-toastr';
import { noWhitespaceValidator } from 'projects/patient/src/app/add-note-tab/add-note-tab.component';
import { CreateUpdateDiscountPercentDTO } from 'projects/order/src/app/order-proxy/order-management/order-optimization/dto/models';
import { DiscountPercentService } from 'projects/order/src/app/order-proxy/order-management/order-optimization/discount-percent.service';
import { AbstractControl, ValidatorFn } from '@angular/forms';
import { DatePipe } from '@angular/common';
@Component({
  selector: 'app-master-discount-table',
  templateUrl: './master-discount-table.component.html',
  styleUrls: ['./master-discount-table.component.scss']
})
export class MasterDiscountTableComponent implements OnInit {
  isSave:string='Save';
  @Input() tableData: any[];
  step: boolean = false;
  discountDataList: any;
  // dtOptions: any;
  discountId: string = defaultGuid;
  defaultGuid: string = defaultGuid;
  mstDiscountForm: FormGroup;
  isShowSpinner: boolean =false;
  subscription$: Subscription[] = [];
  saveButtonHide: boolean;
  @ViewChild(DataTableDirective, { static: false })
  datatableElement: DataTableDirective;

  dtdisocuntOptions :any = {
    responsive: true,
    paging: false,
    scrollCollapse: true,
    scrollY: '410px',
    scrollX: true,
    dom: 'Bfrtip',
    language: {
      emptyTable: 'No records',
      info: 'Total : _MAX_ records',
      infoEmpty: 'Total : _MAX_ records',
      infoFiltered: '(filtered : _TOTAL_ records)',
      loadingRecords: 'Loading...',
      zeroRecords: 'No matching records',
    },
    buttons: [
      {
        extend: 'excel',
        text: 'Export',
        filename: 'Master Discount',
        exportOptions: {
          columns: ':visible:not(:first-child,:eq(0)) ',
        },
      },
      {
        extend: 'colvis',
        columns: ':not(:eq(0), :eq(1))',
      }
    ]
  };

  //Accordion Toggle Methods
  toggle() {
    this.step = true;
  }
  toggleV2() {
    this.step = false;
  }

  constructor(
    private formBuilder: FormBuilder,
    private discountService: DiscountPercentService,
    private toaster: ToastrService,
    private snackBar: MatSnackBar,
    private communicationService: CommunicationService,
    private datepipe: DatePipe
    ) { }

  ngOnInit(): void {

    this.dtdisocuntOptions.buttons[0].filename =
    'Master Discount' + '-' + this.datepipe.transform(new Date(), 'MM-dd-yyyy');

    this.discountDataList=this.tableData;
    this.intializeForms();
    const valueChanges = this.mstDiscountForm.valueChanges.subscribe(() => {
      let itemName: AbstractControl = this.mstDiscountForm?.get('txtdiscount');

      if (itemName?.value?.trim() == 0) {
          itemName?.setErrors({ pattern: true })

      }
  })
}

  intializeForms() {
    this.mstDiscountForm = this.formBuilder.group({
      txtdiscount: new FormControl('', [
        Validators.required,
        noWhitespaceValidator,
        numberAfterDecimalValidator(2)
      ]),
    });
  }
   // //After View Method
   ngAfterViewInit(): void {
    this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.columns().every(function () {
        const that = this;
        $('input', this.footer()).on('keyup change', function () {
          if (that.search() !== this['value']) {
            that.search(this['value']).draw();
          }
        });
      });
    });
  }


    //Reset Discount Form
    resetDiscountForm() {
      this.isSave='Save';
      this.discountId = defaultGuid;
      // this.discountId = null;
      this.mstDiscountForm.reset();
      this.mstDiscountForm.patchValue({
        txtdiscount: ''
      });
    }

     //Save and Update for Discount Method
     saveDiscount() {
    this.isShowSpinner = true;

    let discontPercentage: CreateUpdateDiscountPercentDTO = {
      discountPercentage: this.mstDiscountForm.value.txtdiscount.trim(),
    };

    if (
      this.discountId === null ||
      this.discountId === defaultGuid ||
      this.discountId === ''
    ) {
      this.discountService.create(discontPercentage).subscribe(
        (response) => {
          this.isShowSpinner = true;
          this.mstDiscountForm.reset();
          this.toaster.success('Discount Saved Successfully');
          this.communicationService.triggerReloadDiscontTable();
          this.resetDiscountForm();
        },
        (err) => {
          this.isShowSpinner = true;
          this.communicationService.triggerReloadDiscontTable();
          this.resetDiscountForm();
          const data: HttpErrorResponse = err;
          Swal.fire({
            icon: 'info',
            text: data?.error?.error?.message,
          });
        }
      );
    } else {
      this.discountService.updateData(this.discountId, discontPercentage).subscribe(
        (response) => {
          this.mstDiscountForm.reset();
          this.communicationService.triggerReloadDiscontTable();
          this.resetDiscountForm();
          this.step = false;
          this.toaster.success('Discount Updated Successfully');
        },
        (err) => {
          this.communicationService.triggerReloadDiscontTable();
          this.resetDiscountForm();
          this.step = false;
          const data: HttpErrorResponse = err;
          Swal.fire({
            icon: 'info',
            text: data?.error?.error?.message,
          });
        }
      );
    }
  }

    //Get the Discount Details by Id
    viewDiscountById(disCounId: string) {
      this.isSave='Update';
      this.discountId = disCounId ?? '';
      if (this.step == false) {
        this.step = true;
      }
      const documentDetails = this.discountService
        .get(disCounId)
        .subscribe((response) => {
          this.mstDiscountForm.patchValue({
            txtdiscount: response?.discountPercentage ?? '',
          });
          this.discountId = response?.id ?? '';
          // this.docType = response?.documentType ?? '';
        },
        (err) => {
          const data: HttpErrorResponse = err;
          Swal.fire({
            icon: 'info',
            text: data?.error?.error?.message,
          });
        });
      this.mstDiscountForm.markAsDirty();
      this.subscription$.push(documentDetails);
    }

  //Copy Text While Duble on table Row
  onRowDoubleClickV1(textToCopy) {
    this.copyTextToClipboard(textToCopy);
    this.showNotification('Text copied: ' + textToCopy);
  }

  showNotification(message: string): void {
    this.snackBar.open(message, 'Close', {
      duration: 3000, // Duration in milliseconds
      horizontalPosition: 'end', // Positioning the notification horizontally
      verticalPosition: 'bottom', // Positioning the notification vertically
    });
  }

  copyTextToClipboard(text: string): void {
    const textArea = document.createElement('textarea');
    textArea.value = text;
    document.body.appendChild(textArea);
    textArea.select();
    document.execCommand('Copy');
    textArea.remove();
  }

  getFormattedDate(date: any): string {
    return date
      ? new Date(date).toLocaleString('en-US', {
        month: '2-digit',
        day: '2-digit',
        year: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        hour12: true,
      })
      : '-';
  }

    //Status Change Actice And Inactive
    statusChange(event: MatSlideToggleChange, data: any) {
      if (event.checked == true) {
        Swal.fire({
          title: 'Do you want to activate?',
          text: 'By Changing Status, this Discount will be activated',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#34c38f',
          cancelButtonColor: '#f46a6a',
          confirmButtonText: 'Yes, Activate it!'
        }).then(result => {
          if (result.value) {
            if (data?.id && data?.id !== "" && data?.id !== null && data?.id !== undefined) {
              let discontPercentage: CreateUpdateDiscountPercentDTO = {
                // discountPercentage: this.mstDiscountForm.value.txtdiscount.trim(),
                isActive:true
              };
              this.discountService.delete(data?.id).subscribe(response => {
                this.toaster.success('Activated Successfully', 'Success')
                this.isShowSpinner = false;
                this.communicationService.triggerReloadDiscontTable();
              }, err => {
                const data: HttpErrorResponse = err;
                Swal.fire({
                  icon: 'info',
                  text: data?.error?.error?.message,
                });
              });
            }
          }
          else {
            event.source.checked = false;
          }
        });
      }
      else
        if (event.checked == false) {
          Swal.fire({
            title: 'Do you want to deactivate?',
            text: 'By Changing Status, this Discount will be deactivated',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#34c38f',
            cancelButtonColor: '#f46a6a',
            confirmButtonText: 'Yes, deactivate it!'
          }).then(result => {
            if (result.value) {
              if (data?.id && data?.id !== "" && data?.id !== null && data?.id !== undefined) {
                let discontPercentage: CreateUpdateDiscountPercentDTO = {
                  // discountPercentage: this.mstDiscountForm.value.txtdiscount.trim(),
                  isActive:false
                };
                this.discountService.delete(data?.id).subscribe(response => {
                  this.toaster.success('Deactivated Successfully', 'Success');
                  this.communicationService.triggerReloadDiscontTable();
                  this.isShowSpinner = false;
                }, err => {
                  const data: HttpErrorResponse = err;
                  Swal.fire({
                    icon: 'info',
                    text: data?.error?.error?.message,
                  });
                });
              }

            }
            else {
              event.source.checked = true;
            }
          });
        }
    }
}
export function numberAfterDecimalValidator(maxFractionDigits = 2): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    if (control.value === null || control.value === undefined) {
      return null;
    }

    const regex = new RegExp('^\\d*(\\.\\d{0,' + maxFractionDigits + '})?$');
    const valid = regex.test(control.value);

    return valid ? null : { numberAfterDecimal: { value: control.value } };
  };
}
