
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { tr } from 'date-fns/locale';
import { ToastrService } from 'ngx-toastr';
import { PDFDocument } from 'pdf-lib';
import { DocumentMaxandTypeLimits, defaultGuid } from 'projects/shared/src/app/enums/allenums.enum';
import Swal from 'sweetalert2';
import { ZermidClearingHouseService } from '../../claim-proxy/claim-processing-management/rcm/claim-processing-management/x12';
import { AsyncSubject, Observable } from 'rxjs';
import { AutoPostingDTO, AutoPostingFiles } from '../../claim-proxy/claim-processing-management/rcm/billing-management/posting/dto';
import { HttpErrorResponse } from '@angular/common/http';
import { IFormFile } from 'projects/patient/src/app/patient-proxy/microsoft/asp-net-core/http';
import { MatDialogRef } from '@angular/material/dialog';
export interface SelectedFiles {
  name: string;
  base64?: string;
  pageCount: number;
}
@Component({
  selector: 'app-upload-eob',
  templateUrl: './upload-eob.component.html',
  styleUrls: ['./upload-eob.component.scss'],
})
export class ClaimUploadEOBComponent implements OnInit {

  chkdisableButton: boolean = false;
  claimUploadEOB: FormGroup;
  arrSelectedDocuments: any[] = [];
  fileBase64Data: any[] = [];
  // selectedFileCount: number = 1;
  showErrorForFileType: boolean = false;
  showErrorBasedOnMaxSize: boolean = false;
  // totalSizeforTable: string='150Kb';
  selectedFileCount: number = 0;
  totalSizeforTable: any;
  isediFile: boolean = false;
  isPdfFile: boolean = false;
  isTxtFile: boolean = false;
  showErrorForFileLength: boolean = false;
  public selectedFiles : SelectedFiles[] = [];
  // filesForUpload: any;
  newFilesForUpload: File[] = [];
  // newFilesForUpload: FileList = new DataTransfer().files;
  documentMaxNoFileLimit = DocumentMaxandTypeLimits.documentMaxNoFileLimit;
  AddMoreFileSize: any;
  private _snackBar: any;

  constructor(
    private formBuilder: FormBuilder,
    private toastr: ToastrService,
    private zirmedClearingHouse: ZermidClearingHouseService,
    public eobDialogRef: MatDialogRef<ClaimUploadEOBComponent>,

  ) {}

  ngOnInit(): void {
    this.arrSelectedDocuments = []
    this.claimUploadEOB = this.formBuilder.group({
      addMoreFile: new FormControl(''),
      documentFile: new FormControl('')
    })
  }
  convertToIFormFile(file: File): IFormFile {

    return {
      contentType: !file?.type ? 'application/x-835' :  file.type ,
      contentDisposition: 'attachment',
      headers: {}, // In Angular, headers are typically handled by the HTTP client, not by the file itself
      length: file.size,
      name: file.name,
      fileName: file.name
    };
  }

  // savefuncation() {
  //   // throw new Error('Method not implemented.');
  //   this.isediFile = false;
  //   this.isPdfFile = false;

  //   let file: File[]

  //   (file = this.newFilesForUpload)//filesForUpload;
  //   const isEDIfileData = []
  //   const isPDFfileData = []
  // if(this.arrSelectedDocuments.length <= 2){
  //     if(this.arrSelectedDocuments.length == 2){
  //     this.arrSelectedDocuments.forEach(element => {

  //   if(element.fileName1.endsWith('835')){
  //     this.isediFile = true
  //     isEDIfileData.push(element)

  //     } else if (element.fileName1.endsWith('pdf')){
  //       this.isPdfFile = true
  //       isPDFfileData.push(element)
  //     }


  //     if(this.isediFile == true && this.isPdfFile == true && isEDIfileData.length == 1 && isPDFfileData.length == 1){

  //       // const iFormFile: IFormFile = this.convertToIFormFile(this.iformFileto[0]);
  //                   // formData.append('file', this.iformFileto[0], this.iformFileto[0].fileName);

  //       // const iFormFile1: IFormFile = this.convertToIFormFile(this.iformFileto[1]);


  //     const data =  this.zirmedClearingHouse.read835EOBClaimFileByEobFileAndEobPdfFile(file[0],file[1],1).subscribe(response => {
  //       this.resetInboundDocumentForm();
  //       this.eobDialogRef.close(true);
  //       this.toastr.success('Files Read Successfully', 'success' );
  //     },(err) => {
  //       const data: HttpErrorResponse = err;
  //       Swal.fire({
  //         icon: 'info',
  //         text: data?.error?.error?.message,
  //       });
  //     })
  //       // read835EOBClaimFileByEobfileAndPdfEOBfile
  //       // this.toastr.success(' success', 'success' );
  //     } else if((this.isediFile == false && this.isPdfFile == false) || isEDIfileData.length == 2 || isPDFfileData.length == 2) {
  //       this.toastr.warning(' Select only one PDF and 835 file', 'Warning' );
  //     }
  //   })
  //       } else {
  //         this.toastr.warning(' Select only one PDF and 835 file', 'Warning' );
  //       }

  //   } else {
  //      this.toastr.warning(' Select only one PDF and 835 file', 'Warning' );
  //   }


  // }

  savefuncation() {
    // throw new Error('Method not implemented.');
    this.isediFile = false;
    this.isPdfFile = false;
    this.isTxtFile = false;

    let file: File[];

    (file = this.newFilesForUpload); // filesForUpload;

    let existingPdfFile = false;
    let existingTxtFile = false;
    this.arrSelectedDocuments.forEach(doc => {
        if (doc.fileName1.endsWith('pdf')) {
            existingPdfFile = true;
        } else if (doc.fileName1.endsWith('txt')) {
            existingTxtFile = true;
        }
    });
    if (existingPdfFile && existingTxtFile) {
        this.toastr.warning('Select only one PDF or TXT file along with the 835 file', 'Warning');
        this.chkdisableButton = false;
        return;
    }

    const isEDIfileData = [];
    const isPDFfileData = [];
    const isTXTfileData = [];
    if (this.arrSelectedDocuments.length <= 2) {
        if (this.arrSelectedDocuments.length == 2) {
            this.arrSelectedDocuments.forEach(element => {

                if (element.fileName1.endsWith('835')) {
                    this.isediFile = true;
                    isEDIfileData.push(element);

                } else if (element.fileName1.endsWith('pdf')) {
                    this.isPdfFile = true;
                    isPDFfileData.push(element);

                } else if (element.fileName1.endsWith('txt')) {
                    this.isTxtFile = true;
                    isTXTfileData.push(element);
                }

                if ((this.isediFile && this.isPdfFile && isEDIfileData.length == 1 && isPDFfileData.length == 1) ||
                    (this.isediFile && this.isTxtFile && isEDIfileData.length == 1 && isTXTfileData.length == 1) ||
                    (this.isPdfFile && this.isTxtFile && isPDFfileData.length == 1 && isTXTfileData.length == 1)) {

                      const eobFile = file.find(file => file.name.endsWith('835')) || null;
                      const eobPdfFile = file.find(file => file.name.endsWith('pdf')) || null;
                      const eobTxtFile = file.find(file => file.name.endsWith('txt')) || null;

                    // const data = this.zirmedClearingHouse.read835EOBClaimFileByEobFileAndEobPdfFile(file[0], file[1], 1).subscribe(response => {
                     this.zirmedClearingHouse.update835PaymentByEobFileAndEobTxtFileAndEobPdfFileAndIPostingMode(eobFile, eobTxtFile, eobPdfFile, 1).subscribe(response => {
                        this.resetInboundDocumentForm();
                        this.eobDialogRef.close(true);
                        switch (response?.statusCode) {
                          case 200:
                              Swal.fire({
                                  icon: 'success',
                                  text: response.message || 'Files Read Successfully',
                              });
                              break;
                          case 204:
                              Swal.fire({
                                  icon: 'info',
                                  text: response.message,
                              });
                              break;
                      }
                        // this.toastr.success('Files Read Successfully', 'success');
                    }, (err) => {
                        const data: HttpErrorResponse = err;
                        Swal.fire({
                            icon: 'info',
                            text: data?.error?.error?.message,
                        });
                    });

                } else if ((this.isediFile == false && this.isPdfFile == false && this.isTxtFile == false) ||
                    isEDIfileData.length == 2 || isPDFfileData.length == 2 || isTXTfileData.length == 2) {
                    this.toastr.warning('Select only one PDF or TXT file along with the 835 file', 'Warning');
                }
            });
        } else {
          this.toastr.warning('Select only one PDF or TXT file along with the 835 file', 'Warning');
        }

    } else {
      this.toastr.warning('Select only one PDF or TXT file along with the 835 file', 'Warning');
    }
}

  resetInboundDocumentForm() {
    this.claimUploadEOB.reset({
      documentFile: null,
      txtComments: '',
      drpBranchType: null,
    });
    this.showErrorForFileType = false;
    this.showErrorForFileLength = false;
    // this.showErrorBasedOnSize = false;
    this.showErrorBasedOnMaxSize = false;
    this.arrSelectedDocuments = [];
    this.newFilesForUpload = [];
    this.chkdisableButton = false;
    // this.table.setSelectedDocumentList(this.arrSelectedDocuments);
    this.selectedFileCount = 0;
    // this.disableMainFile = false;
  }

  closeEOBMdl(){
    this.eobDialogRef.close(false);
  }
  // deleteDocument(_t46: any) {
  //   throw new Error('Method not implemented.');
  // }
  // addMoreFile($event: any) {
  //   throw new Error('Method not implemented.');
  // }
  iformFileto
  // addMoreFile(event: any) {
  //   const files: FileList = event?.target?.files;
  //     this.iformFileto = event?.target?.files;

  // // event.value = '';
  // if (this.calculateTotalSizeForMore(this.newFilesForUpload,files)) {
  //   this.showErrorForFileType = false;
  //   this.showErrorBasedOnMaxSize = false;
  //   this.showErrorForFileLength = false;
  //   // this.arrSelectedDocuments = [];

  //   // this.filesForUpload = files;
  //   // this.mergeFileLists(files);
  //   this.chkdisableButton = true;
  //   // this.selectedFileCount = this.newFilesForUpload.length;
  //   this.totalSizeforTable = this.AddcalculateTotalSizeValue(files);
  //   const totalFiles = files.length;
  //   let filesProcessed = 0;
  //   if (files && files.length > 0) {
  //     let fileResult: {
  //       fileName: string;
  //       fileType: string;
  //       fileSize: string;
  //       pageCount?: number;
  //     }[] = [];
  //     const fileList: File[] = [];
  //     const onCompleteProcessing = () => {
  //       filesProcessed++;
  //       if (filesProcessed === totalFiles) {
  //         this.chkdisableButton = false;
  //       }
  //     };
  //     for (let i = 0; i < files.length; i++) {
  //       if (  files[i].type !== DocumentMaxandTypeLimits.documentTypeLimit && files[i].type != ''  ) {
  //         this.showErrorForFileType = true;
  //         this.showErrorBasedOnMaxSize = false;
  //         this.showErrorForFileLength = false;
  //         // this.arrSelectedDocuments = [];
  //         this.chkdisableButton = false;
  //         // this.selectedFileCount = 0;
  //         return;
  //       } else{
  //         this.pdfUpload(files)
  //       }

  //       // fileList.push(files[i]);
  //       // this.newFilesForUpload.push(files[i]);
  //       // this.mergeFileLists(files[i]);



  //       const exists = this.newFilesForUpload.some(
  //         (existingFile) =>
  //           existingFile.name === files[i].name && existingFile.size === files[i].size
  //       );
  //       if (!exists) {
  //         this.newFilesForUpload.push(files[i]);
  //         // this.toastr.success(`File "${files[i].name}" Added.`, 'Success' );
  //       }
  //       else {
  //         this.toastr.warning(`File "${files[i].name}" already exists.`, 'Warning' );
  //       }


  //       const fileName: string = files[i].name;
  //       const m1fileName: string = fileName.replace('.pdf', '');
  //       const m2fileName: string = m1fileName.replace('.pdf', '');

  //       // const getPDFPageCount = async (file: File) => {
  //       //   const pdfDoc = await PDFDocument.load(await file.arrayBuffer(),{ ignoreEncryption: true });
  //       //   // const pdfDoc = await PDFDocument.load(pdfBytes, { ignoreEncryption: true });
  //       //   const pages = pdfDoc.getPages();
  //       //   return pages.length;
  //       // };

  //       const getPDFPageCount = async (file: File) => {
  //         try {
  //           const pdfBytes = await file.arrayBuffer();
  //           const pdfDoc = await PDFDocument.load(pdfBytes, { ignoreEncryption: true }); // Add { ignoreEncryption: true }
  //           const pages = pdfDoc.getPages();
  //           return pages.length;
  //         } catch (error) {
  //           console.warn(error);
  //           return 0; // Return a default value or handle the error as per your requirement
  //         }
  //       };

  //       const pdfBytes =
  //       getPDFPageCount(files[i]).then((pageCount) => {
  //         // Check if a file with the same name and size exists in arrSelectedDocuments
  //         const fileExists = this.arrSelectedDocuments.some(
  //           (existingFile) =>
  //             existingFile.fileName === m2fileName &&
  //             existingFile.fileSize === this.convertBytesToReadable(files[i].size) &&
  //             existingFile.pageCount ===pageCount
  //         );

  //         // If the file doesn't already exist, push it into arrSelectedDocuments
  //         if (!fileExists) {
  //           this.arrSelectedDocuments.push({
  //             fileName: m2fileName,
  //             fileType: files[i].type === 'application/pdf' ? 'PDF' : 'N/A',
  //             fileSize: this.convertBytesToReadable(files[i].size),
  //             pageCount: pageCount,
  //             fileName1: files[i].name
  //           });
  //         }
  //         onCompleteProcessing();
  //       }).catch((error) => {
  //         // Handle any errors that occurred during loading the PDF document
  //         console.warn(error);
  //         onCompleteProcessing();
  //       });;
  //     }
  //     this.selectedFileCount = this.newFilesForUpload.length;
  //   }
  // } else {
  //   this.showErrorForFileType = false;
  //   this.showErrorBasedOnMaxSize = true;
  //   this.showErrorForFileLength = false;
  //   // this.arrSelectedDocuments = [];
  //   this.chkdisableButton = false;
  //   // this.selectedFileCount = 0;
  //   this.toastr.warning('Last selected files size limit exceeded', 'Warning' );
  //   return;
  // }

  // }

  addMoreFile(event: any) {
    const files: FileList = event?.target?.files;
    this.iformFileto = event?.target?.files;

    if (this.calculateTotalSizeForMore(this.newFilesForUpload, files)) {
        this.showErrorForFileType = false;
        this.showErrorBasedOnMaxSize = false;
        this.showErrorForFileLength = false;
        this.chkdisableButton = true;
        this.totalSizeforTable = this.AddcalculateTotalSizeValue(files);
        const totalFiles = files.length;
        let filesProcessed = 0;
        if (files && files.length > 0) {
            let fileResult: {
                fileName: string;
                fileType: string;
                fileSize: string;
                pageCount?: number;
            }[] = [];
            const fileList: File[] = [];
            const onCompleteProcessing = () => {
                filesProcessed++;
                if (filesProcessed === totalFiles) {
                    this.chkdisableButton = false;
                }
            };
            for (let i = 0; i < files.length; i++) {
                if (files[i].type !== DocumentMaxandTypeLimits.documentTypeLimit && files[i].type !== 'text/plain' && files[i].type != '') {
                    this.showErrorForFileType = true;
                    this.showErrorBasedOnMaxSize = false;
                    this.showErrorForFileLength = false;
                    this.chkdisableButton = false;
                    return;
                } else {
                    this.pdfUpload(files);
                }

                const exists = this.newFilesForUpload.some(
                    (existingFile) =>
                        existingFile.name === files[i].name && existingFile.size === files[i].size
                );
                if (!exists) {
                    this.newFilesForUpload.push(files[i]);
                } else {
                    this.toastr.warning(`File "${files[i].name}" already exists.`, 'Warning');
                }

                const fileName: string = files[i].name;
                const m1fileName: string = fileName.replace('.pdf', '');
                const m2fileName: string = m1fileName.replace('.pdf', '');

                const getPDFPageCount = async (file: File) => {
                    try {
                        const pdfBytes = await file.arrayBuffer();
                        const pdfDoc = await PDFDocument.load(pdfBytes, { ignoreEncryption: true });
                        const pages = pdfDoc.getPages();
                        return pages.length;
                    } catch (error) {
                        console.warn(error);
                        return 0;
                    }
                };

                getPDFPageCount(files[i]).then((pageCount) => {
                    const fileExists = this.arrSelectedDocuments.some(
                        (existingFile) =>
                            existingFile.fileName === m2fileName &&
                            existingFile.fileSize === this.convertBytesToReadable(files[i].size) &&
                            existingFile.pageCount === pageCount
                    );

                    if (!fileExists) {
                      let fileType = 'N/A';
                      if (files[i].type === 'application/pdf') {
                          fileType = 'PDF';
                      } else if (files[i].name.endsWith('.txt')) {
                          fileType = 'TXT';
                      } else if (files[i].name.endsWith('835')) {
                          fileType = '835';
                      }
                    this.arrSelectedDocuments.push({
                        fileName: m2fileName,
                        fileType: fileType,
                        fileSize: this.convertBytesToReadable(files[i].size),
                        pageCount: pageCount,
                        fileName1: files[i].name
                    });
                    }
                    onCompleteProcessing();
                }).catch((error) => {
                    console.warn(error);
                    onCompleteProcessing();
                });
            }
            this.selectedFileCount = this.newFilesForUpload.length;
        }
    } else {
        this.showErrorForFileType = false;
        this.showErrorBasedOnMaxSize = true;
        this.showErrorForFileLength = false;
        this.chkdisableButton = false;
        this.toastr.warning('Last selected files size limit exceeded', 'Warning');
        return;
    }
}
  toFilesBase64(files: File[], selectedFiles: SelectedFiles[]): Observable<SelectedFiles[]> {
    const result = new AsyncSubject<SelectedFiles[]>();
    if (files?.length) {
      Object.keys(files)?.forEach(async (file, i) => {
        const reader = new FileReader();
        reader.readAsDataURL(files[i]);
        reader.onload = (e) => {
          selectedFiles = selectedFiles?.filter(f => f?.name != files[i]?.name)
          const base64 = reader?.result as string
          const base64Data = base64.split(',')[1];
          selectedFiles.push({ name: files[i]?.name,  base64: base64Data ,pageCount: 0})
          result.next(selectedFiles);
          if (files?.length === (i + 1)) {
            result.complete();
          }
        };
      });
      return result;
    } else {
      result.next([]);
      result.complete();
      return result;
    }
  }

  pdfUpload(files){
    // this.isLoaded = false
    this.toFilesBase64(files, this.selectedFiles).subscribe((res: SelectedFiles[]) => {
      this.selectedFiles = res;
      res.forEach(value =>{
          const data = this.fileBase64Data.find(x => (x.fileName == value?.name)  )
          if(!data){
            this.fileBase64Data.push({
              fileName: value.name,
              fileContent: value?.base64,
            })
            // this._snackBar.open("Successfully upload!", 'Close', {
            //   duration: 2000,
            // });


          } else{
            // this._snackBar.open(`File ${value?.name} Already Exsit!"`, 'Close', {
            //   duration: 2000,
            // });
          }


        // this.isLoaded = true

      })
    });
  }

  deleteDocument(e) {
    Swal.fire({
      title: 'Are you sure you want to Delete?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#34c38f',
      cancelButtonColor: '#f46a6a',
      confirmButtonText: 'Yes, delete it!',
    }).then((result) => {
      if (result.value) {
        // Find index of document in arrSelectedDocuments
        const index = this.arrSelectedDocuments.indexOf(e);
        if (index !== -1) {
          // Remove document from arrSelectedDocuments
          this.arrSelectedDocuments.splice(index, 1);
          this.selectedFileCount--;

          // Find index of file in filesForUpload
          let indexToRemove = -1;
          this.newFilesForUpload.splice(index, 1);
          // for (let i = 0; i < this.filesForUpload.length; i++) {
          //   if (this.filesForUpload[i].name === e.fileName + '.pdf') {
          //     indexToRemove = i;
          //     break;
          //   }
          // }
//           for (let i = 0; i < this.newFilesForUpload.length; i++) {
//               if (this.newFilesForUpload[i].name === e.fileName + '.pdf' ||
//                   this.newFilesForUpload[i].name === e.fileName + '.txt' ||
//                   this.newFilesForUpload[i].name === e.fileName + '835') {
//                   indexToRemove = i;
//                   break;
//               }
//           }
// console.log(indexToRemove);
//           if (indexToRemove > -1 && indexToRemove < this.newFilesForUpload.length) {
//             this.newFilesForUpload.splice(indexToRemove, 1);
//           }



          // Remove file from filesForUpload if found
          // if (indexToRemove !== -1) {
          //   const newFileList = new DataTransfer();
          //   for (let i = 0; i < this.filesForUpload.length; i++) {
          //     if (i !== indexToRemove) {
          //       newFileList.items.add(this.filesForUpload[i]);
          //     }
          //   }
          //   const updatedFiles: FileList = newFileList.files;
          //   this.filesForUpload = updatedFiles;
          // }


          // Remove file from newFilesForUpload if found
        //   if (indexToRemove !== -1) {
        //     const updatedFilesList = new DataTransfer();
        //     for (let i = 0; i < this.newFilesForUpload.length; i++) {
        //         if (i !== indexToRemove) {
        //             updatedFilesList.items.add(this.newFilesForUpload[i]);
        //         }
        //     }
        //     this.newFilesForUpload = updatedFilesList.files;

        // }


        }
        console.log(this.newFilesForUpload);
      }
      if (this.arrSelectedDocuments.length === 0) {
        this.claimUploadEOB.patchValue({
          documentFile: null,
          addMoreFile: null
        });
      }
      this.totalSizeforTable = this.calculateTotalSizeValue(
        this.newFilesForUpload
      );
    });
  }
  calculateTotalSizeForMore(files,AddMoreFile) {
    let totalSize = 0;
    let status = false;
    let maxTotalSize = DocumentMaxandTypeLimits.ManualUploadMaxSize;
    for (const key in files) {
      if (files.hasOwnProperty(key)) {
        totalSize += files[key].size;
      }
    }
    for (const key in AddMoreFile) {
      if (AddMoreFile.hasOwnProperty(key)) {
        totalSize += AddMoreFile[key].size;
      }
    }
    if (totalSize <= maxTotalSize) {
      status = true;
    }
    return status;
  }
  calculateTotalSizeValue(files) {
    this.AddMoreFileSize = 0;
    let totalSize = 0;
    for (const key in files) {
      if (files.hasOwnProperty(key)) {
        totalSize += files[key].size;
      }
    }
    this.AddMoreFileSize = totalSize;
    return this.convertBytesToReadable(totalSize);
  }
  AddcalculateTotalSizeValue(files) {
    let totalSize = 0;
    for (const key in files) {
      if (files.hasOwnProperty(key)) {
        totalSize += files[key].size;
      }
    }
    this.AddMoreFileSize += totalSize;
    return this.convertBytesToReadable(this.AddMoreFileSize);
  }

  convertBytesToReadable(bytes) {
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    if (bytes == 0) return '0 Byte';
    if (isNaN(bytes)) return '0 Bytes';
    const i = Math.floor(Math.log(bytes) / Math.log(1024));
    return parseFloat((bytes / Math.pow(1024, i)).toFixed(2)) + ' ' + sizes[i];
  }
}
