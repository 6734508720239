import type { CreateUpdatePostingReminderDTO, PostingReminderDTO } from './dto/models';
import { RestService } from '@abp/ng.core';
import type { PagedAndSortedResultRequestDto, PagedResultDto } from '@abp/ng.core';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class PostingReminderService {
  apiName = 'billingManagement';

  create = (input: CreateUpdatePostingReminderDTO) =>
    this.restService.request<any, PostingReminderDTO>({
      method: 'POST',
      url: '/api/billingApp/posting-reminder',
      body: input,
    },
     { apiName: this.apiName, skipHandleError: true });

  delete = (id: string) =>
    this.restService.request<any, void>({
      method: 'DELETE',
      url: `/api/billingApp/posting-reminder/${id}`,
    },
     { apiName: this.apiName, skipHandleError: true });

  get = (id: string) =>
    this.restService.request<any, PostingReminderDTO>({
      method: 'GET',
      url: `/api/billingApp/posting-reminder/${id}`,
    },
     { apiName: this.apiName, skipHandleError: true });

  getList = (input: PagedAndSortedResultRequestDto) =>
    this.restService.request<any, PagedResultDto<PostingReminderDTO>>({
      method: 'GET',
      url: '/api/billingApp/posting-reminder',
      params: { skipCount: input.skipCount, maxResultCount: input.maxResultCount, sorting: input.sorting },
    },
     { apiName: this.apiName, skipHandleError: true });

  getPostingRemindersBasedOnOrganizationByOrganizationUnitId = (organizationUnitId: string) =>
    this.restService.request<any, PagedResultDto<PostingReminderDTO>>({
      method: 'GET',
      url: `/api/billingApp/posting-reminder/posting-reminders-based-on-organization/${organizationUnitId}`,
    },
     { apiName: this.apiName, skipHandleError: true });

  update = (id: string, input: CreateUpdatePostingReminderDTO) =>
    this.restService.request<any, PostingReminderDTO>({
      method: 'PUT',
      url: `/api/billingApp/posting-reminder/${id}`,
      body: input,
    },
     { apiName: this.apiName, skipHandleError: true });

  constructor(private restService: RestService) {}
}
