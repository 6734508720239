<div class="row pb-2">
  <div class="col-lg-10 d-flex align-items-center">
    <h2 class="mb-2 header-text" mat-dialog-title>
      <B>Add Insurance Category</B>
    </h2>
  </div>
  <div class="col-lg-2 text-right">
    <button
      type="button"
      mat-icon-button
      class="close-button"
      (click)="onCloseClick()"
    >
      <mat-icon>close</mat-icon>
    </button>
  </div>
</div>
<mat-dialog-content class="mat-typography">
  <div class="card card-body">
    <div class="row">


    <form [formGroup]="insuranceDetailsForm">
      <mat-form-field class="col-4" style="width:395px">
        <mat-label>Name<span class="asterisk">*</span></mat-label>
        <!-- <input matInput type="text" maxlength="40" formControlName="txtname" autocomplete="off" /> -->
        <input
          matInput
          formControlName="txtname"
          placeholder="Enter Name"
          type="text"
          maxlength="40"
        />
        <mat-error *ngIf="(insuranceDetailsForm?.get('txtname')?.touched &&
   insuranceDetailsForm?.get('txtname')?.errors)">
            Name is a required field!
        </mat-error>
    </mat-form-field>
    <mat-form-field class="col-4" style="width:395px">
        <mat-label>Mask Call Increment<span class="asterisk">*</span></mat-label>
        <input
         matInput type="text" class="no-spinners" formControlName="maskCallIncrement" step="1"
            min="1" maxlength="8" pattern="^0*(\d{1,7}?|10000000?)$" (keydown.ArrowUp)="$event.preventDefault()"
            (keydown.ArrowDown)="$event.preventDefault()"  (keypress)="onKeyPress($event)"
            (paste)="onPaste($event)" autocomplete="off"
            />
        <mat-error *ngIf="(insuranceDetailsForm?.get('maskCallIncrement')?.touched &&
            insuranceDetailsForm?.get('maskCallIncrement')?.errors?.pattern)"
            >
           Enter number must be between 0 and 10000000
        </mat-error>
        <mat-error *ngIf="(insuranceDetailsForm?.get('maskCallIncrement')?.touched &&
            insuranceDetailsForm?.get('maskCallIncrement')?.errors?.required)">
            Mask Call Increment is a required field!
        </mat-error>
    </mat-form-field>

    <mat-form-field class="col-4" style="width:395px">
        <mat-label>Supplies Call Increment <span class="asterisk">*</span></mat-label>
        <input matInput type="text" class="no-spinners" formControlName="suppliesCallIncrement" step="1"
            min="1" maxlength="8" pattern="^0*(\d{1,7}?|10000000?)$" (keydown.ArrowUp)="$event.preventDefault()"
            (keydown.ArrowDown)="$event.preventDefault()" (keypress)="onKeyPress($event)"
            (paste)="onPaste($event)" autocomplete="off">
        <mat-error *ngIf="(insuranceDetailsForm?.get('suppliesCallIncrement')?.touched &&
            insuranceDetailsForm?.get('suppliesCallIncrement')?.errors?.pattern)">
            Enter number must be between 0 and 10000000!
        </mat-error>
        <mat-error *ngIf="(insuranceDetailsForm?.get('suppliesCallIncrement')?.touched &&
            insuranceDetailsForm?.get('suppliesCallIncrement')?.errors?.required)">
            Supplies Call Increment is a required field!
        </mat-error>
    </mat-form-field>

    <mat-form-field class="col-4" style="width:395px">
        <mat-label>Order Machine Points<span class="asterisk">*</span></mat-label>
        <input matInput type="text" class="no-spinners" formControlName="orderMachinePoints" step="1"
            min="1" maxlength="8" pattern="^0*(\d{1,7}?|10000000?)$" (keydown.ArrowUp)="$event.preventDefault()"
            (keydown.ArrowDown)="$event.preventDefault()"(keypress)="onKeyPress($event)"
            (paste)="onPaste($event)" autocomplete="off">
        <mat-error *ngIf="(insuranceDetailsForm?.get('orderMachinePoints')?.touched &&
            insuranceDetailsForm?.get('orderMachinePoints')?.errors?.pattern)">
            Enter number must be between 0 and 10000000!
        </mat-error>
        <mat-error *ngIf="(insuranceDetailsForm?.get('orderMachinePoints')?.touched &&
  insuranceDetailsForm?.get('orderMachinePoints')?.errors?.required)">
            Order Machine Points is a required field!
        </mat-error>
    </mat-form-field>
    <mat-form-field class="col-4" style="width:395px">
        <mat-label>Default deductible<span class="asterisk">*</span></mat-label>
        <input matInput type="text" class="no-spinners" formControlName="defaultDeduct" step="0.01" min="1"
             pattern="^0*(\d{1,7}(\.\d{1,2})?|10000000(\.0{1,2})?)$" (keydown.ArrowUp)="$event.preventDefault()"
            (keydown.ArrowDown)="$event.preventDefault()"(keypress)="onKeyPressV1($event)"
            (paste)="onPaste($event)" autocomplete="off">
        <mat-error *ngIf="(insuranceDetailsForm?.get('defaultDeduct')?.touched &&
  insuranceDetailsForm?.get('defaultDeduct')?.errors?.pattern)">
           Enter number must be between 0 and 10000000 or two digits only after the decimal!
        </mat-error>
        <mat-error *ngIf="(insuranceDetailsForm?.get('defaultDeduct')?.touched &&
  insuranceDetailsForm?.get('defaultDeduct')?.errors?.required)">
            Default deductible is a required field!
        </mat-error>
    </mat-form-field>
    <mat-form-field class="col-4">
        <mat-label>Compliance machine points <span class="asterisk">*</span> </mat-label>
        <input autocomplete="new-complianceMachine" class="no-spinners" step="0.01" min="1" type="text"
        pattern="^0*(\d{1,7}(\.\d{1,2})?|10000000(\.0{1,2})?)$" formControlName="complianceMachine" matInput
            (keydown.ArrowUp)="$event.preventDefault()" (keydown.ArrowDown)="$event.preventDefault()" (keypress)="numbersAndfloatOnly($event)">
        <mat-error *ngIf="(insuranceDetailsForm?.get('complianceMachine')?.touched &&
            insuranceDetailsForm?.get('complianceMachine')?.errors?.pattern)"
            (paste)="onPaste($event)" autocomplete="off">
            Enter number must be between 0 and 10000000 or two digits only after the decimal!
        </mat-error>
        <mat-error *ngIf="(insuranceDetailsForm?.get('complianceMachine')?.touched &&
            insuranceDetailsForm?.get('complianceMachine')?.errors?.required)">
            Compliance machine points is a required field!
        </mat-error>
    </mat-form-field>
    </form>
    </div>
  </div>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-button (click)="saveInsCategory()" class="buttonColor mr-2"
  [disabled]="isSaveButton||insuranceDetailsForm.invalid">

  Save
  <!-- <mat-icon *ngIf="isShowSpinner">
<mat-spinner class="spinner-border spinner-border-sm" diameter="20">
</mat-spinner>
</mat-icon> -->
</button>
  <button class="buttonCancelColor" mat-button (click)="onCloseClick()">Close</button>

  <!-- <button mat-button (click)="check()" >Check</button> -->
  <!-- <button class="buttonCancelColor" mat-button mat-dialog-close>Close</button> -->
</mat-dialog-actions>
