import { Component, Input, OnInit, ViewChild } from '@angular/core';
import {
  MMPurchaseOrderItemsDTO,
  createPurchaseOrderDTO,
  requsitionsforPurchaseDto,
} from '../../item-proxy/item-management/optimization/dto';
import { Subscription } from 'rxjs';

import { ToastrService } from 'ngx-toastr';
import { MMPurchaseOrderService } from '../../item-proxy/item-management/optimization/mmpurchase-order.service';
import { CommunicationService } from 'projects/shared/src/app/services/communication.service';
import { DataTableDirective } from 'angular-datatables';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-purchase-order-table',
  templateUrl: './purchase-order-table.component.html',
  styleUrls: ['./purchase-order-table.component.scss'],
})
export class PurchaseOrderTableComponent implements OnInit {
  @Input() ltPurchaseOrders: requsitionsforPurchaseDto[] = [];
  @Input() ltManufacturerIds: any;
  @Input() manufacturerName: string;
  subscription$: Subscription[] = [];
  @ViewChild(DataTableDirective, { static: false })
  datatableElement: DataTableDirective;
  dtPOTableOptions: any = {
    responsive: true,
    paging: false,
    dom: 'Bfrtip',
    scrollCollapse: true,
    scrollY: '500px',
    scrollX: true,
    search: {
      smart: false,
    },
    order: [],
    language: {
      emptyTable: 'No records',
      info: 'Total : _MAX_ records',
      infoEmpty: 'Total : _MAX_ records',
      infoFiltered: '(filtered : _TOTAL_ records)',
      loadingRecords: 'Loading...',
      zeroRecords: 'No matching records',
    },
    // order: [],
    buttons: [
      {
        extend: 'excel',
        title: '',
        text: '<i class="fa fa-upload"></i> PO Export', // Change the text to 'Export'
        filename: 'PO Details',
        customize: (xlsx: any) => {
          this.savePurchaseOrderDetails(); // Call your method before exporting
        },
      },
    ],
    // columnDefs: [
    //   { targets: 0, orderable: false }, // Targets the first column (index 0)
    //   // Add other columnDefs as needed
    // ],
  };

  constructor(
    private purchaseOrderService: MMPurchaseOrderService,
    private toaster: ToastrService,
    private datepipe: DatePipe,
    private communicationService: CommunicationService
  ) {}

  ngOnInit() {
    this.dtPOTableOptions.buttons[0].title =
      this.manufacturerName +
      '-' +
      this.datepipe.transform(new Date(), 'MM/dd/yyyy');
    this.dtPOTableOptions.buttons[0].filename =
      'PO Details ' + '-' + this.manufacturerName + '-' + this.datepipe.transform(new Date(),'MM-dd-yyyy') ;
    this.calculateScrollY();
    window.addEventListener('resize', this.calculateScrollY);
  }
  ngOnDestroy(): void {
    window.removeEventListener('resize', this.calculateScrollY);
  }
  ngAfterViewInit(): void {
    if (this.datatableElement && this.datatableElement.dtInstance) {
      this.refreshDataTable();
    } else {
      // If dtInstance is not available yet, wait for a short time and try again
      setTimeout(() => this.refreshDataTable(), 500);
    }
  }

  //To Save the Purchase Order Details and Export the Excel File
  savePurchaseOrderDetails() {
    const currentUser = localStorage.getItem('userId');
    let ltPurchaseOrderItems: MMPurchaseOrderItemsDTO[] = [];
    this.ltPurchaseOrders.forEach((element) => {
      const purchaseOrderItem: MMPurchaseOrderItemsDTO = {
        requisitionId: element.requisitionId,
        productId: element.productId,
        orderedQuantity: element.quantity,
        receivedQuantity: 0,
        unitCost: element.unitCost,
        totalCost: element.totalCost,
        tenantId: localStorage.getItem('tenantId'),
      };
      ltPurchaseOrderItems.push(purchaseOrderItem);
    });

    const purchaseOrder: createPurchaseOrderDTO = {
      manufacturerId: this.ltManufacturerIds,
      orderedBy: currentUser,
      purchaseOrderItems: ltPurchaseOrderItems,
      tenantId: localStorage.getItem('tenantId'),
    };

    this.purchaseOrderService
      .createPurchaseOrderByPurchaseOrderDetails(purchaseOrder)
      .subscribe(
        (response) => {
          this.toaster.success('PO Raised Successfully!', 'Success', {
            timeOut: 5000, // Specify time in milliseconds (e.g., 5000 = 5 seconds)
            closeButton: true, // Optional: Show close button
            progressBar: true, // Optional: Show progress bar
          });

          this.communicationService.triggerLoadPurchaseOrderList(
            this.ltManufacturerIds
          );
        },
        (err) => {
          this.toaster.error('Something Went Wrong');
          this.toaster.error('Something Went Wrong', 'Error', {
            timeOut: 5000, // Specify time in milliseconds (e.g., 5000 = 5 seconds)
            closeButton: true, // Optional: Show close button
            progressBar: true, // Optional: Show progress bar
          });
        }
      );
  }

  calculateScrollY = () => {
    const screenHeight =
      window.innerHeight ||
      document.documentElement.clientHeight ||
      document.body.clientHeight;
    const availableSpacePercentage = 0.55; // Adjust the percentage as needed
    this.dtPOTableOptions.scrollY = screenHeight * availableSpacePercentage;
  };

  //To refresh the Datatable
  refreshDataTable(): void {
    this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
      // Clear search input values
      $('input', dtInstance.table(0).footer()).val('');

      // Redraw the DataTable to load all records
      dtInstance.search('').columns().search('').draw();

      // Attach the event listener for filtering
      dtInstance.columns().every(function () {
        const that = this;
        $('input', this.footer()).on('keyup change', function () {
          if (that.search() !== this['value']) {
            that.search(this['value']).draw();
          }
        });
      });
    });
  }
}
