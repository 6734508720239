<form [formGroup]="messageForm">
  <mat-sidenav-container class="container">
    <section>
      <div class="mat-typography conversation-header mt-2">
        <p>
          <mat-icon>account_circle</mat-icon><span>
            <span class="ml-2 mr-2">{{ patientName}} </span>
            <span class="ml-5 mr-1">Phone No:{{ "+"+ countryCode }}</span>
            <span class="ml-1">{{ patientPhoneNo }}</span>
          </span>
        </p>
        <p class="pt-0 mt-0" *ngIf="countryCode==='' || countryCode===undefined || countryCode===null">
          <span class="p-1 alert-danger">Please fill Country in
            Personal Billing Address</span>
        </p>
        <p class="pt-0 mt-0" *ngIf="patientPhoneNo==='' || patientPhoneNo===undefined || patientPhoneNo===null">
          <span class="p-1  alert-danger"> Please fill Phone
            Number in Personal Billing Address</span>
        </p>
      </div>
      <mat-divider></mat-divider>
      <mat-list dense class="chat-messages">
        <mat-list-item class="chat-message" *ngFor="let message of smsConversationList; index as i"
          [dir]="message?.sentBy ? 'rtl' : 'ltr'">
          <div *ngIf="message?.sentBy" [class.user-message]="message?.sentBy">
            <div *ngIf="message?.sentBy" class="message-author">
              <mat-icon class="avatar" matTooltip="{{message?.sentBy }}">account_circle
              </mat-icon>
            </div>
            <div *ngIf="message?.sentMessage" class="message-text text-wrap pr-2 pl-2"> {{message?.sentMessage}}
              <br>
              <span *ngIf="message?.sentOn" class="mt-2 text-muted"> {{message?.sentOn|date :'medium' }}</span>
            </div>
          </div>
          <div *ngIf="message?.repliedBy" [class.contact-message]="message?.repliedBy">
            <div *ngIf="message?.repliedBy" class="message-author">
              <mat-icon class="avatar" matTooltip="{{message?.repliedBy }}">account_circle
              </mat-icon>
            </div>
            <div *ngIf="message?.repliedMessage" class="message-text text-wrap "> {{message?.repliedMessage}}
              <br>
              <span *ngIf="message?.repliedOn" class=" mt-2 text-muted"> {{message?.repliedOn |date:'medium' }}</span>
            </div>
          </div>
        </mat-list-item>
      </mat-list>
      <div class="conversation-input mt-3">
        <mat-divider class="mb-3"></mat-divider>
        <mat-form-field class="full-width">
          <mat-label>Enter Your Message</mat-label>
          <textarea matInput name="text" formControlName="txtMessage"></textarea>

          <button *ngIf="orderStatus" class="ml-2 mr-3" mat-icon-button matSuffix (click)="sendSMSToPatient()"
            [disabled]="isInSMSPorgress || messageForm.invalid ">
            <mat-icon matTooltip="Send" style="font-size: xx-large;">send</mat-icon>
          </button>
          <button class="ml-2 mr-3" mat-icon-button matSuffix (click)="resetForm()">
            <mat-icon matTooltip="Clear" style="font-size: xx-large;">clear</mat-icon>
          </button>
        </mat-form-field>
      </div>
    </section>
  </mat-sidenav-container>
</form>
