<div class="container-fluid main-content">
  <div class="row bg">
    <div class="col-12">
      <div class="d-flex align-items-center justify-content-between mb-0" style="margin-top: -15px;">
        <h3 class="font-weight-bolder mb-0">Patient / Notes List</h3>
        <div class="page-title-box page-title-right">
          <ol class="breadcrumbs m-0 align-items-center">

            <!-- <div class="input-group ">

            <input
              type="text"
              class="form-control"
              placeholder="Username"
              [hidden]="dateRangStatus != true"

              aria-label="Username"
              aria-describedby="basic-addon1"
              name="daterange"
              ngxDaterangepickerMd
              [(ngModel)]="selectedRange"
              id="calendar-input"
              [showCustomRangeLabel]="true"
              [alwaysShowCalendars]="true"
              [linkedCalendars]="true"
              [ranges]="ranges"
              [showClearButton]="true"
              [locale]="calendarLocale"
              [placeholder]="calendarPlaceholder"
              [keepCalendarOpeningWithRange]="true"
              [minDate]="minDate"
              [maxDate]="maxDate"
              (datesUpdated)="datesUpdateds($event)"
              autocomplete="new-daterange"
            />

          </div>
          <span class="input-group-text bg-color mr-2" id="basic-addon1"
          ><label style="margin: auto" for="calendar-input"
            ><i class="fa fa-filter cursor" aria-hidden="true"></i></label
        ></span> -->
        <form [formGroup]="branchForm">
          <mat-form-field class="pr-3">
            <mat-label>Branch</mat-label>
            <mat-select #selectBranch formControlName="drpBranch" multiple [(value)]="organizationUnitName">
              <mat-option>
                <ngx-mat-select-search
                  placeholderLabel="Search"
                  noEntriesFoundLabel="No Matches found"
                  formControlName="txtBranch"
                ></ngx-mat-select-search>
              </mat-option>

              <mat-option #selectAllBranch (click)="allBranchSelect()" value="0">All</mat-option>

              <mat-option *ngFor="let branch of brnachList | async" [value]="branch.id">
                {{ branch.organizationUnitName }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <button mat-button class="mr-2 buttonColor"
            [disabled]="(dataSource && dataSource?.data && dataSource?.data?.length === 0)" (click)="
            exporter.exportTable('xlsx', {
              fileName: 'Notes List',
              sheet: 'Notes List',
              Props: { Author: 'QSecure' }
            })
          ">Export</button>
        </form>
          
          <!-- <li class="breadcrumb-item">Notes</li>
          <li class="breadcrumb-item">Notes List</li> -->
        </ol>
      </div>
    </div>
  </div>
    <div class="col-12 pb-5">
      <form [formGroup]="noteSearchForm">
        <div class="table table-responsive example-container mat-elevation-z4 mb-0 pb-0">
          <table mat-table id="{{ strPageType }}" class="pt-0 mt-0" matTableExporter #exporter="matTableExporter"
            [hiddenColumns]="[14]" [dataSource]="dataSource" matSort>
            <ng-container matColumnDef="defaultNotesId" sticky>
              <th class="header" mat-header-cell mat-sort-header *matHeaderCellDef>
                <mat-form-field>
                  <input matInput (keydown.space)="$event.stopPropagation()" (click)="$event.stopPropagation()" formControlName="txtNoteId" autocomplete="new-txtNoteId" />
                  <mat-placeholder class="center">Note Id</mat-placeholder>
                </mat-form-field>
              </th>
              <td mat-cell *matCellDef="let element">
                {{ element?.defaultNotesId }}

              </td>
            </ng-container>
            <ng-container matColumnDef="defaultPatientId" sticky>
              <th class="header" mat-header-cell mat-sort-header *matHeaderCellDef>
                <mat-form-field>
                  <input matInput (keydown.space)="$event.stopPropagation()" (click)="$event.stopPropagation()" formControlName="txtPatientId" autocomplete="new-txtPatientId" />
                  <mat-placeholder class="center">Patient Id</mat-placeholder>
                </mat-form-field>
              </th>
              <td mat-cell *matCellDef="let element">
                <a matTooltip="Click to View" class="eyeCursorclass"
                  [routerLink]="'/patientCreateEditTab/' + element?.patientId" target="_blank">
                  {{ element?.defaultPatientId }}
                </a>
              </td>
            </ng-container>
            <ng-container matColumnDef="patientName"sticky>
              <th class="header" mat-header-cell mat-sort-header *matHeaderCellDef>
                <mat-form-field>
                  <input matInput (keydown.space)="$event.stopPropagation()" (click)="$event.stopPropagation()" formControlName="txtPatientName" autocomplete="new-txtPatientName" />
                  <mat-placeholder class="center">Patient Name</mat-placeholder>
                </mat-form-field>
              </th>
              <td mat-cell *matCellDef="let element">
                {{ element?.patientName }}
              </td>
            </ng-container>
            <ng-container matColumnDef="noteType">
              <th class="header" mat-header-cell mat-sort-header *matHeaderCellDef>
                <mat-form-field>
                  <input matInput (keydown.space)="$event.stopPropagation()" (click)="$event.stopPropagation()" formControlName="txtNoteType" autocomplete="new-txtNoteType" />
                  <mat-placeholder class="center">Note Type</mat-placeholder>
                </mat-form-field>
              </th>
              <td mat-cell *matCellDef="let element">
                {{ element?.customNoteType }}
              </td>
            </ng-container>
            <ng-container matColumnDef="branch">
              <th class="header" mat-header-cell mat-sort-header *matHeaderCellDef>
                <mat-form-field>
                  <input matInput (keydown.space)="$event.stopPropagation()" (click)="$event.stopPropagation()" formControlName="txtNoteType" autocomplete="new-txtNoteType" />
                  <mat-placeholder class="center">Branch</mat-placeholder>
                </mat-form-field>
              </th>
              <td mat-cell *matCellDef="let element">
                -
              </td>
            </ng-container>
            <ng-container matColumnDef="description">
              <th class="header overflow-ellipsis" mat-header-cell mat-sort-header *matHeaderCellDef>
                <mat-form-field>
                  <input matInput (keydown.space)="$event.stopPropagation()" (click)="$event.stopPropagation()" formControlName="txtDescription" autocomplete="new-txtDescription" />
                  <mat-placeholder class="center">Description</mat-placeholder>
                </mat-form-field>
              </th>
              <td mat-cell *matCellDef="let element">
                <span class="overflow-ellipsis">
                  {{ element?.description }}
                </span>
              </td>
            </ng-container>
            <ng-container matColumnDef="subject">
              <th class="header" mat-header-cell mat-sort-header *matHeaderCellDef>
                <mat-form-field>
                  <input matInput (keydown.space)="$event.stopPropagation()" (click)="$event.stopPropagation()" formControlName="txtSubject" autocomplete="new-txtSubject" />
                  <mat-placeholder class="center">Subject</mat-placeholder>
                </mat-form-field>
              </th>
              <td mat-cell *matCellDef="let element" [matTooltip]="gettoolTip(element)">
                {{ element?.subject }}
              </td>
            </ng-container>
            <ng-container matColumnDef="userName">
              <th class="header" mat-header-cell mat-sort-header *matHeaderCellDef>
                <mat-form-field>
                  <input matInput (keydown.space)="$event.stopPropagation()" (click)="$event.stopPropagation()" formControlName="txtCreatedBy" autocomplete="new-txtCreatedBy" />
                  <mat-placeholder class="center">Created By</mat-placeholder>
                </mat-form-field>
              </th>
              <td mat-cell *matCellDef="let element">
                <span>{{ element?.userName }} </span>
              </td>
            </ng-container>

            <ng-container matColumnDef="statusData">
              <th class="header" mat-header-cell mat-sort-header *matHeaderCellDef>
                <mat-form-field>
                  <input matInput (keydown.space)="$event.stopPropagation()" (click)="$event.stopPropagation()" formControlName="txtStatus" autocomplete="new-txtStatus" />
                  <mat-placeholder class="center">Status</mat-placeholder>
                </mat-form-field>
              </th>
              <td mat-cell *matCellDef="let element">
                {{ element?.statusData }}
              </td>
            </ng-container>
            <ng-container matColumnDef="dateCreated">
              <th class="header" mat-header-cell mat-sort-header *matHeaderCellDef>
                <mat-form-field>
                  <input matInput (keydown.space)="$event.stopPropagation()" (click)="$event.stopPropagation()" formControlName="txtCreatedDate" autocomplete="new-txtCreatedDate" />
                  <mat-placeholder class="center">Created Date</mat-placeholder>
                  <mat-error *ngIf="noteSearchForm?.get('txtCreatedDate')?.errors?.dateVaidator">
                    Enter Valid date
                  </mat-error>
                </mat-form-field>
              </th>
              <td mat-cell *matCellDef="let element">
                {{ element?.dateCreated| date : "MM/dd/yyyy" : "en_US" }}
              </td>
            </ng-container>

            <ng-container matColumnDef="reasonData">
              <th class="header" mat-header-cell mat-sort-header *matHeaderCellDef>
                <mat-form-field>
                  <input matInput (keydown.space)="$event.stopPropagation()" (click)="$event.stopPropagation()" formControlName="txtReason" autocomplete="new-txtReason" />
                  <mat-placeholder class="center">Reason</mat-placeholder>
                </mat-form-field>
              </th>
              <td mat-cell *matCellDef="let element">
                {{ element?.reasonData }}
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="arrDisplayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: arrDisplayedColumns"></tr>
          </table>
          <div *ngIf=" !( dataSource && dataSource?.data && dataSource?.data?.length !== 0 ) "
            class="no-records text-align-center">
            <div class="message py-3" *ngIf="!isLoading">
              {{ "No Records Found" }}
            </div>
            <div class="py-3" [hidden]="!isLoading">
              <mat-spinner [diameter]="220"></mat-spinner>
            </div>
          </div>
        </div>
        <mat-paginator #MatPaginator [length]="dataSource?.data?.length" class="mat-elevation-z4" [pageSize]="10"
          [pageIndex]="0" showFirstLastButtons [pageSizeOptions]="[5, 10, 25, 100]">
        </mat-paginator>
      </form>
    </div>
  </div>
</div>
