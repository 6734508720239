<div class="container-fluid">
  <!-- Nav & Title Start  -->
  <div class="d-flex align-items-center justify-content-between m-0">
    <h4 class="font-size-18 m-0">
      <b>{{ strTitle }}</b>
    </h4>
    <div class="page-title-box page-title-right pt-2 pb-0">
      <ol class="breadcrumbs m-0">
        <li class="mb-1">
          <button matTooltip="Click To Go back to Posting List" [routerLink]="'/claim/posting-history/' + strClaimId"
            [queryParams]="{ id: strClaimStatus }" mat-button class="buttonColor font-size-10 mr-2">
            <mat-icon>history</mat-icon> Posting History

            <!-- <i class="fa fa-forward "></i> -->
          </button>
          <button matTooltip="Click To Go back to Posting List" [routerLink]="'/billing/claimStatusListTab'"
            [queryParams]="{ id: strClaimStatus }" mat-button class="buttonColor font-size-10 mr-2">
            <i class="fa fa-backward mr-1"></i> Back To Claim Status List
          </button>
        </li>
        <li class="ml-2 mt-2 breadcrumb-item">Posting</li>
        <li class="mt-2 breadcrumb-item muted">Insurance Payment</li>
      </ol>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <div class="card card-body mb-2">
        <div class="row">
          <div class="col-6">
            <span style="font-size: larger"><b class="colored-heading"> Claim ID : </b><b>{{ lblclaimId }}</b>
            </span>
          </div>

          <div class="col-6">
            <span style="font-size: larger"><b class="colored-heading"> Sale Order ID </b>
              :
              <a matTooltip="Click to View Sale Order" class="lightSeaGreen-dot eyeCursorclass"
                [routerLink]="'/orders/saleorderCreateEditTab/' +strPatientId + '/'+ strOrderId" target="_blank">
                <b> {{ lblSaleOrderId }}</b>
              </a>
            </span>
          </div>
          <div class="col-6">
            <span><b class="colored-heading"> Patient ID </b> :
              <a matTooltip="Click to View Patient" class="lightSeaGreen-dot eyeCursorclass"
                [routerLink]="'/patientCreateEditTab/' + strPatientId" target="_blank">
                <b>{{ lblPatientId }}</b>
              </a>
            </span>
          </div>
          <div class="col-6">
            <span style="font-size: larger"><b class="colored-heading"> Patient Name </b> :
              <b>{{ lblPatientName }}</b>
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Nav & Title End  -->

  <div class="row">
    <div class="col-12 text-right">
      <button matTooltip="Click Add Item Posting" (click)="paymentItemsModalEvent('')" mat-button
        class="buttonColor font-size-10">
        <i class="fa fa-plus mr-1"></i> Add Item Posting
      </button>
    </div>
  </div>

  <!-- Product Payment List Start -->
  <div class="row">
    <div class="col-12 table-responsive">
      <div class="cs-mat-elevation-z8">
        <table mat-table class="table table-bordered" multiTemplateDataRows [dataSource]="dataSource">
          <ng-container matColumnDef="claimNumber">
            <th scope="col" class="header" mat-header-cell *matHeaderCellDef>
              Claim ID
            </th>
            <td mat-cell *matCellDef="let element">
              {{ element?.claimNumber }}
            </td>
          </ng-container>
          <ng-container matColumnDef="checkNo">
            <th scope="col" class="header" mat-header-cell *matHeaderCellDef>
              Check No
            </th>
            <td mat-cell *matCellDef="let element">
              {{ element?.checkNo }}
            </td>
          </ng-container>
          <ng-container matColumnDef="checkDate">
            <th scope="col" class="header" mat-header-cell *matHeaderCellDef>
              Check Date
            </th>
            <td mat-cell *matCellDef="let element">
              {{ element?.checkDate | date: "MM/dd/yyyy":"en_US" }}
            </td>
          </ng-container>
          <ng-container matColumnDef="postingDate">
            <th scope="col" class="header" mat-header-cell *matHeaderCellDef>
              Posting Date
            </th>
            <td mat-cell *matCellDef="let element">
              {{ element?.postingDate | date: "MM/dd/yyyy":"en_US" }}
            </td>
          </ng-container>
          <ng-container matColumnDef="checkAmount">
            <th scope="col" class="header" mat-header-cell *matHeaderCellDef>
              Check Amount
            </th>
            <td mat-cell *matCellDef="let element">
              {{ "$ " + (element?.checkAmount === "" ? 0 : element?.checkAmount) }}
            </td>
          </ng-container>
          <ng-container matColumnDef="planType">
            <th scope="col" class="header" mat-header-cell *matHeaderCellDef>
              Plan Type
            </th>
            <td mat-cell *matCellDef="let element">
              {{ element?.planType }}
            </td>
          </ng-container>
          <ng-container matColumnDef="itemCode">
            <th scope="col" class="header" mat-header-cell *matHeaderCellDef>
              Item Code
            </th>
            <td mat-cell *matCellDef="let element">
              {{ element?.itemProcedureCode }}
            </td>
          </ng-container>
          <!-- <ng-container matColumnDef="reasonCode">
            <th class="header" mat-header-cell *matHeaderCellDef>
              Reason Code
            </th>
            <td mat-cell *matCellDef="let element">
              {{ element?.lstPayment?.reasonCode }}
            </td>
          </ng-container> -->
          <ng-container matColumnDef="billedAmount">
            <th class="header" mat-header-cell *matHeaderCellDef>Billed</th>
            <td mat-cell *matCellDef="let element">
              {{ "$ " + (element?.billed === "" ? 0 : element?.billed) }}
            </td>
          </ng-container>

          <ng-container matColumnDef="cost">
            <th scope="col" class="header" mat-header-cell *matHeaderCellDef>
              Allowed
            </th>
            <td mat-cell *matCellDef="let element">
              {{ "$ " + (element?.allowed === "" ? 0 : element?.allowed) }}
            </td>
          </ng-container>
          <ng-container matColumnDef="paidAmount">
            <th scope="col" class="header" mat-header-cell *matHeaderCellDef>
              Paid
            </th>
            <td mat-cell *matCellDef="let element">
              {{
              "$ " +
              (element?.insurancePaid === "" ? 0 : element?.insurancePaid)
              }}
            </td>
          </ng-container>
          <ng-container matColumnDef="deductAmount">
            <th class="header" mat-header-cell *matHeaderCellDef>Deduct</th>
            <td mat-cell *matCellDef="let element">
              {{
              "$ " +
              (element?.deductAmount === "" ? 0 : element?.deductAmount)
              }}
            </td>
          </ng-container>

          <ng-container matColumnDef="coinsurance">
            <th class="header" mat-header-cell *matHeaderCellDef>Co-Ins</th>
            <td mat-cell *matCellDef="let element">
              {{
              "$ " + (element?.coinsurance === "" ? 0 : element?.coinsurance)
              }}
            </td>
          </ng-container>
          <ng-container matColumnDef="currentBalance">
            <th scope="col" class="header" mat-header-cell *matHeaderCellDef>
              Current Balance
            </th>
            <td mat-cell *matCellDef="let element">
              {{
              "$ " +
              (element?.currentBalance === "" ? 0 : element?.currentBalance)
              }}
            </td>
          </ng-container>
          <ng-container matColumnDef="Options">
            <th scope="col" class="header" mat-header-cell *matHeaderCellDef>
              Options
            </th>
            <td mat-cell *matCellDef="let element">
              <a *ngIf="
                  !element.lstAdjustPayments ||
                  element?.lstAdjustPayments?.length === 0
                " (click)="paymentItemsModalEvent(element?.id)" matTooltip="Click to Edit" class="eyeCursorclass">
                <i class="fa fa-pencil mr-2"></i>
              </a>
              <a *ngIf="element?.eobBlobName" [routerLink]="[
                  '../../ViewDocumentPerviewByBlobId/' +
                    element?.eobBlobName +
                    '/4'
                ]" target="_blank" matTooltip="Click to View" class="eyeCursorclass">
                <i class="fa fa-eye mr-2"></i>
              </a>

              <a class="deleteBtnColor" (click)="deleteItemPayment(element?.id)">
                <i class="fa fa-trash pointer ml-2" matTooltip="Click To Delete"></i>
              </a>
            </td>
          </ng-container>
          <ng-container matColumnDef="noRecords">
            <td scope="col" class="text-align-center" mat-footer-cell *matFooterCellDef colspan="13">
              <div *ngIf="!isLoading">
                {{ "No Records Found" }}
              </div>
              <div [hidden]="!isLoading">
                <mat-spinner [diameter]="220"></mat-spinner>
              </div>
            </td>
          </ng-container>
          <ng-container matColumnDef="paginator">
            <td scope="col" mat-footer-cell *matFooterCellDef colspan="13">
              <mat-paginator class="table table-responsive mat-table-class" #MatPaginator
                [length]="dataSource?.data?.length" [pageSize]="10" [pageIndex]="0" showFirstLastButtons
                [pageSizeOptions]="[5, 10, 25, 100]">
              </mat-paginator>
            </td>
          </ng-container>

          <ng-container matColumnDef="expandedDetail">
            <ng-container mat-cell *matCellDef="let element">
              <td *ngIf="
                  element?.lstAdjustPayments &&
                  element?.lstAdjustPayments?.length !== 0
                " [attr.colspan]="arrDisplayedColumns?.length">
                <div class="example-element-detail">
                  <div class="example-element-description">
                    <ng-container *ngIf="
                        element?.lstAdjustPayments &&
                        element?.lstAdjustPayments?.length !== 0
                      ">
                      <div class="table-responsive">
                        <table id="eobListTable" class="table table-bordered table-sm">
                          <thead>
                            <tr class="p-2">
                              <td class="p-2" scope="col">Reason Code</td>
                              <td class="p-2" scope="col">GRP/RC-AMT</td>
                              <td class="p-2" scope="col">Options</td>
                            </tr>
                          </thead>
                          <tbody>
                            <ng-container *ngFor="let pay of element?.lstAdjustPayments">
                              <tr class="p-2" scope="row">
                                <td class="p-2">{{ pay?.reasonCode }}</td>
                                <td class="p-2"> {{ "$ " + (pay?.adjustment === "" ? 0 : pay?.adjustment) }} </td>
                                <td class="p-2">
                                  <a (click)="
                                      paymentItemsModalEvent(
                                        element?.id,
                                        pay?.paymentId
                                      )
                                    " matTooltip="Click to View" class="eyeCursorclass">
                                    <i class="fa fa-pencil mr-2"></i>
                                  </a>
                                </td>
                              </tr>
                            </ng-container>
                          </tbody>
                        </table>
                      </div>
                    </ng-container>
                  </div>
                </div>
              </td>
            </ng-container>
          </ng-container>
          <tr mat-footer-row *matFooterRowDef="['noRecords']" [ngClass]="{
              active:
                dataSource && dataSource?.data && dataSource?.data?.length !== 0
            }"></tr>
          <tr mat-header-row *matHeaderRowDef="arrDisplayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: arrDisplayedColumns"
            [class.example-expanded-row]="expandedElement === row"
            (click)="expandedElement = expandedElement === row ? null : row"></tr>
          <tr mat-footer-row *matFooterRowDef="['paginator']"></tr>
          <tr mat-row [ngClass]="row?.lstAdjustPayments == 0 ? 'hide' : ''"
            *matRowDef="let row; columns: ['expandedDetail']" class="student-detail-row"></tr>
        </table>
      </div>
    </div>
  </div>
  <!-- <tr>
                        <td colspan="2" class="text-right"><b>Total</b></td>
                        <td colspan="1"><b>{{"$ "+totalBilled}}</b> </td>
                        <td colspan="1"><b>{{"$ "+totalAllowed}}</b> </td>
                        <td colspan="1"><b>{{"$ "+totalDeduct}}</b></td>
                        <td colspan="1"><b>{{"$ "+totalCoinsurance}}</b> </td>
                        <td colspan="1"><b>{{"$ "+totalPatientAmount}}</b></td>
                        <td colspan="1"><b></b></td>
                    </tr> -->
  <!-- Product Payment List End -->
</div>

<button [matMenuTriggerFor]="menu" mat-button matTooltip="Add" class="clr mat-fab-bottom-right rightfloatfabbutton"
  mat-mini-fab>
  <mat-icon class="icon">add</mat-icon>
</button>

<mat-menu #menu="matMenu">
  <button (click)='openAddNotesPopUp()' mat-menu-item matTooltip="Add Notes"> Add Notes
    <mat-icon class="icon">add</mat-icon>
  </button>
  <button (click)='openViewNotesPopup()' mat-menu-item matTooltip="View Notes"> View Notes
    <mat-icon class="icon">remove_red_eye</mat-icon>
  </button>
</mat-menu>