import { HttpErrorResponse } from '@angular/common/http';
import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { PatientExportDatas, PatientExportDTO } from 'projects/patient/src/app/patient-proxy/patient/dto/models';
import { ExportType } from 'projects/patient/src/app/patient-proxy/patient/enumeration-data/export-type.enum';
import { ReportService } from 'projects/patient/src/app/patient-proxy/patient/report.service';
import { claimAcceptedStatus, DocumentMaxandTypeLimits } from 'projects/shared/src/app/enums/allenums.enum';
import { DateValidator } from 'projects/shared/src/app/validations/date-validator';
import { Subscription } from 'rxjs';
import { distinctUntilChanged, filter, map, startWith, switchMap } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { BillingPaypalComponent } from '../billing-paypal/billing-paypal.component';
import { ClaimSearchService } from '../billing-proxy/billing-management/posting/claim-search.service';
import { ClaimDTO } from '../billing-proxy/billing-management/posting/dto/models';
import { SearchPrimaryClaimDTO } from '../billing-proxy/billing-management/posting/models';
import { ClaimProcessService } from '../claim-proxy/claim-processing-management/rcm/claim-processing-management/x12/claim-process.service';
import { UploadClaimRemitsDTO } from '../claim-proxy/claim-processing-management/rcm/claim-processing-management/x12/dto/models';
import { SaleorderDTO } from '../claim-proxy/claim-processing-management/rcm/order-management/order/dto/models';
import { ToastrService } from "ngx-toastr";

@Component({
  selector: 'app-check-eft-selection',
  templateUrl: './check-eft-selection.component.html',
  styleUrls: ['./check-eft-selection.component.scss']
})
export class CheckEftSelectionComponent implements OnInit, OnDestroy {

  claimTableData: any[] = [];

  dataSource: MatTableDataSource<any> = new MatTableDataSource<any>([]);
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  public arrDisplayedColumns: string[] = [];
  isLoading: boolean = true;
  subscription$: Subscription[] = [];

  @ViewChild('uploadFileReference', { static: false }) uploadFileReference: ElementRef

  checkForm: FormGroup;
  constructor(
    private claimProcessService: ClaimProcessService,
    private claimSearchService: ClaimSearchService,
    private dateValidator: DateValidator,
    private formBuilder: FormBuilder,
    private router: Router,
    public dialog: MatDialog,
    private reportService: ReportService,
    private toastr: ToastrService,
  ) { }

  ngOnDestroy(): void {
    this.subscription$?.forEach((sub) => {
      sub && sub?.unsubscribe();
    });
  }

  ngOnInit(): void {
    this.checkForm = this.formBuilder.group({
      checkIssueToDate: new FormControl("", this.dateValidator.dateVaidator),
      checkNo: new FormControl(""),
      totalBilledamount: new FormControl(""),
      totalInsBal: new FormControl(""),
      defaultClaimId: new FormControl(""),
      payer: new FormControl(""),
      payee: new FormControl(""),
      patientFullName: new FormControl(""),
      defaultSaleOrderId: new FormControl(""),
      totalPatBal: new FormControl(""),
    })
    this.arrDisplayedColumns = [
      "defaultClaimId", "option", 'defaultSaleOrderId', 'patientName', "totalBilledamount", "totalInsurancePaidAmount", "totalPatientAmount", "checkNo", "payer", "payee", "checkIssueToDate"
    ];
    const valueChanges = this.checkForm.valueChanges.pipe(
      distinctUntilChanged(),
      startWith({ checkIssueToDate: "", checkNo: "", payee: "", totalBilledamount: "", defaultClaimId: "", payer: "", patientFullName: "", defaultSaleOrderId: "", totalInsBal: "", totalPatBal: "" }),
      filter(e => e && this.checkForm?.valid),
      map(value => {
        const sValue: SearchPrimaryClaimDTO = {
          checkIssueToDate: value?.checkIssueToDate?.trim()?.toLowerCase() ?? "",
          checkNo: value?.checkNo?.trim()?.toLowerCase() ?? "",
          payee: value?.payee?.trim()?.toLowerCase() ?? "",
          totalBilled: value?.totalBilledamount?.trim()?.toLowerCase() ?? "",
          defaultClaimId: value?.defaultClaimId?.trim()?.toLowerCase() ?? "",
          payer: value?.payer?.trim()?.toLowerCase() ?? "",
          patientName: value?.patientFullName?.trim()?.toLowerCase() ?? "",
          defaultSaleOrderId: value?.defaultSaleOrderId?.trim()?.toLowerCase() ?? "",
          totalInsBal: value?.totalInsBal?.trim()?.toLowerCase() ?? "",
          totalPatBal: value?.totalPatBal?.trim()?.toLowerCase() ?? "",
        };
        return sValue;
      }),
      switchMap((response: SearchPrimaryClaimDTO) => this.claimSearchService.searchPrimaryClaimByInput(response))
    ).subscribe(response => {
      this.setTableData(response?.items ?? []);
    }, err => {
      this.setTableData([]);
      this.isLoading = false;
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    })
    this.subscription$?.push(valueChanges);
    this.getTableData();

  }
  // Get List Table Data on modal changes
  private getTableData() {
    const value: SearchPrimaryClaimDTO = { payee: "", payer: "", checkIssueToDate: "", checkNo: "", defaultClaimId: "", totalBilled: "", defaultSaleOrderId: '', patientName: '', totalInsBal: '', totalPatBal: '' }
    this.isLoading = true;
    const claimSearchSubscription = this.claimSearchService.searchPrimaryClaimByInput(value).subscribe(response => {
      this.setTableData(response?.items ?? []);
    }, err => {
      this.setTableData([]);
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    });
    this.subscription$?.push(claimSearchSubscription);
  }




  //! Read From File. and upload to Backend For EDI parsing
  eftImportFormFile(value: any) {
    this.checkForm.reset()

    const fileList: FileList = value;
    let file835: File = null;
    let fileEOB: File = null;
    if (fileList?.length >= 3) { return }


    for (let index = 0; index < fileList?.length; index++) {
      if (fileList[index]?.type === "" || fileList[index]?.type === "text/plain") {
        file835 = fileList?.item(index);
      };
      if (fileList[index]?.type === DocumentMaxandTypeLimits.documentTypeLimit) {
        fileEOB = fileList?.item(index);
      };
    }
    if (!file835 || !fileEOB) {
      Swal.fire({
        icon: 'info',
        text: "Please Select Both Remits PDF and Claim File",
      });
      this.uploadFileReference.nativeElement.value = null;
      return;
    }


    let file835ResultValue: string = "";
    let fileEOBResultValue: string = "";
    let reader = new FileReader();

    reader?.readAsDataURL(file835);
    reader.onload = () => {
      file835ResultValue = ((file835.type === '') ? (reader?.result?.slice(37)?.toString()) : ((file835.type === "text/plain") ? (reader?.result?.slice(23)?.toString()) : ""));
      reader?.readAsDataURL(fileEOB);
      reader.onload = () => {
        fileEOBResultValue = reader?.result?.slice(28)?.toString();
        if (file835ResultValue && file835ResultValue !== "" && fileEOBResultValue && fileEOBResultValue !== "") {
          const input: UploadClaimRemitsDTO = {
            upload835BlobDTO: { fileContentAsBase64String: file835ResultValue, fileName: file835.name },
            uploadEobBlobDTO: { fileContentAsBase64String: fileEOBResultValue, fileName: fileEOB.name }
          }
          this.claimProcessService.importClaimRemitsByInput(input).subscribe(response => {
            this.uploadFileReference.nativeElement.value = null;

            if (response && response !== "") {
              Swal.fire({ icon: 'info', text: response });
            } else {
              // Swal.fire({ title: 'Success', html: 'Imported Successfully', icon: 'success', timer: 3000, timerProgressBar: true })
              this.toastr.success('Imported Successfully','Success')
            }
            this.getTableData();
          }, err => {
            this.uploadFileReference.nativeElement.value = null;
            this.getTableData();

            const data: HttpErrorResponse = err;
            let errorMesg: string = data?.error;
            if (errorMesg?.includes('Item Codes does not match!!')) {
              errorMesg = 'Item Codes does not match!!';
            }
            else {
              errorMesg = data?.error?.error?.message;
            }
            Swal.fire({
              icon: 'info',
              text: errorMesg
            });
          });
        }
      }


    }
  }
  //! TO view Posting Detils
  viewAutoPosting(value: { id: string }) {
    value?.id && this.router.navigate(['claim/history/' + value?.id])
  }
  viewManualPosting(value: { id: string }) {
    value?.id && this.router.navigate(['claim/manualhistory/' + value?.id])
  }

  //! Invoice initiate payment
  paymentInvoiceInitiate(record: { saleOrder: SaleorderDTO; orderId: string; id: string; }) {
    if (record?.saleOrder?.payPalPaymentStatus === "Initiate Payment") {
      const dialogRef = this.dialog.open(BillingPaypalComponent, {
        disableClose: true,
        data: { orderId: record?.orderId, claimId: record?.id, isShowItemData: 1 }
      });
      dialogRef.afterClosed().subscribe(() => {
        this.getTableData();
      })

    } else {
      const dialogRef = this.dialog.open(BillingPaypalComponent, {
        disableClose: true,
        data: { orderId: record?.orderId, claimId: record?.id, isShowItemData: 1 }
      });
      dialogRef.afterClosed().subscribe(() => {
        this.getTableData();
      })
    }
  }
  //export table data for Billing-List
  exportTableData() {
    this.checkForm.setValue({ checkIssueToDate: "", checkNo: "", totalBilledamount: "", totalInsBal: "", defaultClaimId: "", payer: "", payee: "", patientFullName: "", defaultSaleOrderId: "", totalPatBal: "" })

    let datas: PatientExportDatas[] = []
    this.dataSource?.data?.forEach(element => {
      datas?.push({
        claimId: element?.claimId,
        saleOrderId: element?.saleorderId,
        patientName: element?.patientName,
        totalBilled: "$ " + String(element?.totalBilled),
        totalInsBal: "$ " + element?.totalInsBal.toString(),
        totalPatBal: "$ " + String(element?.totalPatientBal),
        checkNo: element?.insurancePayments?.primaryInsurancePaymentDetails?.checkNo,
        payer: element?.insurancePayments?.primaryInsurancePaymentDetails?.payer,
        payee: element?.insurancePayments?.primaryInsurancePaymentDetails?.payee,
        issueToDate: element?.insurancePayments?.primaryInsurancePaymentDetails?.date
      });
    })
    let patientsExport: PatientExportDTO = {
      columns: ["claimId", "Sale Order Id", "Patient Name", "Total Billed", "Total Ins Billed", "Total Patient Billed", "check No", "Payer", "Payee", "Issue Date"],
      datas: datas
    };

    this.reportService.exportPatientReportsByExportTypeAndExportDatas(ExportType.CheckEftReports, patientsExport).subscribe(response => {

      if (response && response?.length !== 0) {
        let filelocation: String = String("data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64," + response);
        let link = document.createElement("a");
        link.download = ("Check-Eft-List") + ".xlsx";
        link.href = filelocation.toString();
        link.click();
        // Swal.fire({ title: 'Success', html: 'Exported Successfully', icon: 'success', timer: 3000, timerProgressBar: true })
        this.toastr.success('Exported Successfully','Success')
      }
      this.getTableData();

    }, err => {
      const data: HttpErrorResponse = err;
      let errorMesg: string = data?.error;
      if (errorMesg?.includes('Sorry!! There is no data in table to export')) {
        errorMesg = 'Sorry!! There is no data in table to export';
      }
      else {
        errorMesg = data?.error?.error?.message;
      }
      this.getTableData();

      // Swal.fire({
      //   icon: 'info',
      //   text: errorMesg
      // });
      this.toastr.info(errorMesg)
    });
  }
  setTableData(response: ClaimDTO[]) {
    this.claimTableData = response ?? []
    this.dataSource = new MatTableDataSource(this.claimTableData?.filter(e => e?.claimStatus === claimAcceptedStatus.processed) ?? []);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.isLoading = false;

  }
}

export interface checkList {
  payer: string;
  issueDate: string;
  checkEftNo: string;
  payee: string;
  amount: string;
  type: string;
  daySheet: string;
}


