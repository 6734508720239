import { RestService } from '@abp/ng.core';
import { Injectable } from '@angular/core';
import type { PatientMasterDropdownDTO } from '../dto/patient/models';
import type { PatientDropdowns } from '../patient-dropdowns.enum';

@Injectable({
  providedIn: 'root',
})
export class PatientMasterDropdownService {
  apiName = 'DropdownManagement';

  getPatientDropdownsByInput = (input: PatientDropdowns[]) =>
    this.restService.request<any, PatientMasterDropdownDTO>({
      method: 'POST',
      url: '/api/DropdownApp/patient-master-dropdown/get-patient-dropdowns',
      body: input,
    },
     { apiName: this.apiName, skipHandleError: true });

  constructor(private restService: RestService) {}
}
