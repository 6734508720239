<table
  datatable
  [dtOptions]="dtProductTableOptions"
  class="row-border hover w-100 display"
>
  <thead>
    <tr>
      <th matTooltip="Options">Options</th>
      <th matTooltip="Insurance Category Name">Insurance Category Name</th>
      <th matTooltip="Hcpc Code">Hcpc Code</th>
      <th matTooltip="Allowable($)">Allowable($)</th>
      <th matTooltip="Quantity Days">Quantity Days</th>
      <th matTooltip="Minimum Quantity">Minimum Quantity</th>
      <th matTooltip="Maximum Quantity">Maximum Quantity</th>
      <th matTooltip="Created By">Created By</th>
      <th matTooltip="Created Date">Created Date</th>
      <th matTooltip="Modified By">Modified By</th>
      <th matTooltip="Modified Date">Modified Date</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let data of insuranceTableData">
      <td >
        <a
          class="editBtnColor mr-2"
          (click)="getInsAllowablesById(data.insAllowablesId)"
        >
          <i
            class="fa fa-pencil pointer"
            (click)="triggerToggle()"
            matTooltip="Click To Edit"
          ></i>
        </a>
      </td>
      <td [matTooltip]="data.insuranceName ? data.insuranceName : '-'">
        {{ data.insuranceName ? data.insuranceName : "-" }}
      </td>

      <td [matTooltip]="data.hcpcCode ? data.hcpcCode : '-'">
        {{ data.hcpcCode ? data.hcpcCode : "-" }}
      </td>
      <td
        class="text-right"
        matTooltip="{{ data.allowable ? '$' + data.allowable : '-' }}"
      >
        {{ data.allowable ?  data.allowable : "-" }}
      </td>
      <td
        class="text-right"
        [matTooltip]="data.quantityDays ? data.quantityDays : '-'"
      >
        {{ data.quantityDays ? data.quantityDays : "-" }}
      </td>
      <td
        class="text-right"
        [matTooltip]="data.minimumQuantity ? data.minimumQuantity : '-'"
      >
        {{ data.minimumQuantity ? data.minimumQuantity : "-" }}
      </td>
      <td
        class="text-right"
        [matTooltip]="data.maximumQuantity ? data.maximumQuantity : '-'"
      >
        {{ data.maximumQuantity ? data.maximumQuantity : "-" }}
      </td>
      <td [matTooltip]="data.createdByName ? data.createdByName : '-'">
        {{ data.createdByName ? data.createdByName : "-" }}
      </td>
      <td class="text-center">
        <span [matTooltip]="data?.createdDate">{{
          data?.createdDate }}</span>
      </td>

      <td [matTooltip]="data.modifyByName ? data.modifyByName : '-'">
        {{ data.modifyByName ? data.modifyByName : "-" }}
      </td>

    <td class="text-center">
      <span [matTooltip]="data?.modifyDate">{{
        data?.modifyDate }}</span>
    </td>

    </tr>
  </tbody>
  <tfoot>
    <tr>
      <th></th>
      <th>
        <input
          matInput
          class="textbox-border-class"
          type="text"
          placeholder="Insurance Category Name"
        />
      </th>
      <th>
        <input
          matInput
          class="textbox-border-class"
          type="text"
          placeholder="HCPC Code"
        />
      </th>
      <th>
        <input
          matInput
          class="textbox-border-class text-right"
          type="text"
          placeholder="Allowable ($)"
        />
      </th>
      <th>
        <input
          matInput
          class="textbox-border-class text-right"
          type="text"
          placeholder="Quantity Days"
        />
      </th>
      <th>
        <input
          matInput
          class="textbox-border-class text-right"
          type="text"
          placeholder="Minimum Quantity"
        />
      </th>
      <th>
        <input
          matInput
          class="textbox-border-class text-right"
          type="text"
          placeholder="Maximum Quantity"
        />
      </th>
      <th>
        <input
          matInput
          class="textbox-border-class"
          type="text"
          placeholder="Created By"
        />
      </th>
      <th>
        <input
          matInput
          class="textbox-border-class"
          type="text"
          placeholder="Created Date"
        />
      </th>
      <th>
        <input
          matInput
          class="textbox-border-class"
          type="text"
          placeholder="Modified By "
        />
      </th>
      <th>
        <input
          matInput
          class="textbox-border-class"
          type="text"
          placeholder="Modified Date"
        />
      </th>
     
    </tr>
  </tfoot>
</table>
