import { Component, Input, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subscription } from 'rxjs';
import { InboundDocumentService } from 'projects/admin/src/app/admin-proxy/platform-app-management/rcm/platform-management/inbound/inbound-document.service';
import { HttpErrorResponse } from '@angular/common/http';
import Swal from 'sweetalert2';
import { DatePipe } from '@angular/common';
@Component({
  selector: 'app-patient-list-model',
  templateUrl: './patient-list-model.component.html',
  styleUrls: ['./patient-list-model.component.scss']
})
export class PatientListModelComponent implements OnInit {

  patientListTableData: any;
  practiceListFax: any;
  isLoading: boolean;
  tableData$: Subscription;
  @Input() selectedNode: any;
  dateValue: any;
  selectedMonth: number;
  selectedYear: number;
  patientListOptions: any = {
    dom: 'Bfrtip',
    responsive: true,
    paging: false,
    scrollCollapse: true,
    scrollY: '420px',
    scrollX: true,
    info: false,
    columnDefs: [
      { targets: [0, 1, 3], orderable: false },
    ],
    buttons: [
      {
        extend: 'excel',
        text: 'Export',
        exportOptions: {
          columns: [0, 1, 2, 3],
        },
      },
    ],
  };
  constructor(
    private inboundDocumentservice: InboundDocumentService,
    private datepipe: DatePipe,
  ) { }

  ngOnInit(): void {
    this.dateValue =
      new Date().toLocaleString('default', { month: 'short' }) +
      '/' +
      new Date().getFullYear();

    this.selectedNode =
      new Date().getMonth() + 1 + '/' + new Date().getFullYear();
    this.selectedMonth = new Date().getMonth() + 1;
    this.selectedYear = new Date().getFullYear();
    this.getPracticeFaxLog(this.selectedMonth, this.selectedYear);

    this.patientListOptions.buttons[0].filename =
    'Patient List' + '-' + this.datepipe.transform(new Date(), 'MM-dd-yyyy');

  }

  //GET getPractice FAX Log List In Table
  getPracticeFaxLog(month: number, year: number) {
    this.isLoading = true;
    this.tableData$?.unsubscribe();
    const getFaxNumberbleData = this.inboundDocumentservice
      .getFaxLogByIMonthAndIYearAndPracticeId(month, year, "e8b9fc33-5c61-b02c-083a-3a10b3ee33a1")
      .subscribe(
        (response) => {
          this.practiceListFax = response;
          this.isLoading = false;
        },
        (err) => {
          this.isLoading = false;
          this.practiceListFax = [];
          const data: HttpErrorResponse = err;
          Swal.fire({
            icon: 'info',
            text: data?.error?.error?.message,
          });
        }
      );
    this.tableData$ = getFaxNumberbleData;
  }
}
