import { filter } from 'rxjs/operators';
import {
  Component,
  Inject,
  OnInit,
  ViewChild,
  AfterViewInit,
  ChangeDetectorRef,
  ChangeDetectionStrategy,
} from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { PermissionService } from '../admin-proxy/platform-app-management/rcm/platform-management/permission/permission.service';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
  CustomPermissionDTO,
  permissionHeaderDTO,
} from '../admin-proxy/platform-app-management/permission-management';
import { MatCheckbox, MatCheckboxChange } from '@angular/material/checkbox';
import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { MatAccordion } from '@angular/material/expansion';
import { Subscription } from 'rxjs';
import { LoaderService } from 'projects/shared/src/app/services/loader-service';
import { ToastrService } from 'ngx-toastr';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-permissions-modal-list',
  templateUrl: './permissions-modal-list.component.html',
  styleUrls: ['./permissions-modal-list.component.scss'],
  animations: [
    trigger('iconAnimation', [
      state(
        'expanded',
        style({
          transform: 'rotate(360deg)',
        })
      ),
      state(
        'collapsed',
        style({
          transform: 'rotate(0deg)',
        })
      ),
      transition('expanded <=> collapsed', animate('200ms ease-in-out')),
    ]),
  ],
})
export class PermissionsModalListComponent implements OnInit, AfterViewInit {
  permissionForm: FormGroup;
  permissionsData: CustomPermissionDTO;
  expanded: boolean = false;
  permissionDetails: any[];
  tenantId: string;
  subscription$: Subscription[] = [];
  isPermissionSaved: boolean = true;
  isPermissionLoaded: boolean = true;
  @ViewChild(MatAccordion) accordion: MatAccordion;
  constructor(
    private formBuilder: FormBuilder,
    private permissionService: PermissionService,
    private loaderService: LoaderService,
    private toaster: ToastrService,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      roleName: string;
      roleDisplayName: string;
    } = {
      roleName: '',
      roleDisplayName: '',
    }
  ) {
    this.loaderService.getLoaderState().subscribe((isPermissionSaved) => {
      this.isPermissionSaved = isPermissionSaved;
    });
  }

  ngOnInit() {
    this.permissionForm = this.formBuilder.group({
      chkAllManagement: new FormControl(''),
      chkSelectModule: new FormControl(''),
      chkPermissions: new FormControl(''),
    });
    this.tenantId = localStorage.getItem('tenantId');

    this.getPermissionList();
  }
  ngAfterViewInit(): void {}
  ngOnDestroy(): void {
    this.subscription$.forEach((sub) => {
      sub && sub?.unsubscribe();
    });
  }

  getPermissionList() {

    this.isPermissionLoaded = false;
    const permissionDetails = this.permissionService
      .getCustomPermissionServiceV1ByProviderKeyAndTenantId(
        this.data.roleName,
        this.tenantId
      )
      .subscribe(
        (response) => {


          this.permissionsData = response;
          this.permissionDetails = response?.permissionResult;
          // .filter(
          //   (a) => a.moduleName === 'Admin Management'
          // );
          // this.permissionDetails = this.permissionDetails.filter(
          //   (a) => a.moduleName === 'Admin Management'
          // );

          this.permissionDetails = this.permissionDetails?.map((item) => ({
            ...item,
            isSelected: false, // Adding the new field isSelected with initial value false
            permissionData: item.permissionData?.map((module) => ({
              ...module,
              permissions: module.permissions?.map((permission) => ({
                ...permission,
              })),
            })),
          }));

          //To check all the permissions in the module was selected
          this.permissionDetails.forEach((module) => {

            let allGranted = true;
            module?.permissionData?.forEach((permission) => {
              permission?.permissions?.forEach((subPermission) => {

                if (!subPermission?.isGranted) {
                  allGranted = false;
                }
              });
            });
            module.isSelected = allGranted;

          });

          //Select All Modules Checking
          this.checkAllSelected();
          this.isPermissionLoaded = true;
        },
        (err) => {

          this.isPermissionLoaded = true;
        }
      );
    this.subscription$.push(permissionDetails);
  }

  //To save the Permissions
  savePermissions() {
    this.permissionsData.permissionResult = this.permissionDetails;

    this.loaderService.showLoader();

    this.permissionService
      .updateCustomPermissionByTenantIdAndProviderNameAndProviderKeyAndInput(
        this.tenantId,
        'R',
        this.data.roleName,
        this.permissionsData
      )
      .subscribe(
        (response) => {
          this.loaderService.hideLoader();
          this.toaster.success('Permission Saved Successfully');
        },
        (err) => {
          this.loaderService.hideLoader();

          const data: HttpErrorResponse = err;
          this.toaster.success(data?.error?.message);
        }
      );
  }

  //Select All Permissions from Particular Module
  selectAllPermissionsfromModule(event: MatCheckboxChange, moduleName: string) {
    let data = this.permissionDetails?.find((a) => a.moduleName === moduleName);
    data.isSelected = event.checked;
    data?.permissionData?.forEach((module) => {
      module?.permissions?.forEach((permission) => {
        permission.isGranted = event.checked;
      });
    });

    //Select All Modules Checking
    const isAllModulesSelected = this.permissionDetails.every(
      (module) => module.isSelected
    );

    if (isAllModulesSelected) {
      this.permissionForm.patchValue({
        chkAllManagement: true,
      });
    } else {
      // value.isSelected = false;
      this.permissionForm.patchValue({
        chkAllManagement: false,
      });
    }

  }
  //Select All Permissions
  selectAllModulePermission(event: MatCheckboxChange) {
    this.permissionDetails.forEach((data) => {
      data.isSelected = event.checked;
      data?.permissionData.forEach((module) => {
        module?.permissions.forEach((permission) => {
          permission.isGranted = event.checked;
        });
      });
    });
  }
  //To change the Value of the individual Permission
  changeValue(event: MatCheckboxChange, moduleName: string, permissions: any) {
    permissions.isGranted = event.checked;
    const value = this.permissionDetails?.find(
      (a) => a.moduleName === moduleName
    );
    const isAllPermissionSelected = value.permissionData?.every((module) =>
      module?.permissions?.every((permission) => permission?.isGranted)
    );
    //To check the all the Module permissions are selected
    if (isAllPermissionSelected) {
      value.isSelected = true;
    } else {
      value.isSelected = false;
    }
    this.checkAllSelected();
  }

  //To check All the permissions were enabled
  checkAllSelected() {
    //Select All Modules Checking
    const isAllModulesSelected = this.permissionDetails?.every(
      (module) => module.isSelected
    );

    if (isAllModulesSelected) {
      this.permissionForm.patchValue({
        chkAllManagement: true,
      });
    } else {
      // value.isSelected = false;
      this.permissionForm.patchValue({
        chkAllManagement: false,
      });
    }
  }

  //To toggle the accordions on button click
  toggleExpand() {
    this.expanded = !this.expanded;
    this.expanded ? this.accordion.openAll() : this.accordion.closeAll();
  }
}
