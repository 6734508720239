<table
  datatable
  class="row-border hover w-100 display"
  [dtOptions]="dttblOptions"
>
  <thead>
    <tr>
      <th class="text-center"><mat-icon>view_headline</mat-icon></th>
      <th>Options</th>
      <th [matTooltip]="'Ticket Id'">Ticket Id</th>
      <th [matTooltip]="'Chart No'">Chart No</th>
      <th [matTooltip]="'Patient Name'">Patient Name</th>
      <th [matTooltip]="'Ordered Date'">Ordered Date</th>
      <th [matTooltip]="'Override Date'">Override Date</th>
      <th [matTooltip]="'Approved By'">Approved By</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let data of ltOverrideOrders">
      <td
        class="dt-control text-center"
        (click)="handleTableCellClick($event, data.overrideNote)"
      ></td>
      <td>
        <mat-icon [matTooltip]="'Approve'"
          mat-icon-button
          (click)="approveDisapproveOverride(data.mmOrderId,data.chartNo, true)"
          class="buttonColor mr-2 pointer text-center rounded-circle"
          >check</mat-icon
        >
        <mat-icon [matTooltip]="'DisApprove'"
          mat-icon-button
          (click)="approveDisapproveOverride(data.mmOrderId,data.mmRefereceOrderId, false)"
          class="resetclr rounded-circle pointer text-center"
          >clear</mat-icon
        >
        <!-- <button class="buttonColor btn rounded-circle custom-small-button ">
          <mat-icon class="text-center">check</mat-icon>
        </button>
        <button mat-icon-button class="resetclr">
          <mat-icon>clear</mat-icon>
        </button> -->
      </td>
      <td [matTooltip]="data.mmRefereceOrderId || '-'">
        <a
        class="preview-link"
        [class.disabled]="
          isEmpty(data.chartId) || isEmpty(data.patientId)
        "
        [routerLink]="
          '/orders/saleOrder/' +
          data.patientId +
          '/' +
          data.mmOrderId+'/'+'0'
        "
        target="_blank"
        >{{
          data?.mmRefereceOrderId
            ? data?.mmRefereceOrderId
            : "-"
        }}
      </a>

      </td>
      <td [matTooltip]="data.chartNo || '-'"> <a
        class="preview-link"
        [class.disabled]="
          isEmpty(data.chartId) || isEmpty(data.patientId)
        "
        [routerLink]="
          '/patientCreateEditTab/' +
          data.patientId +
          '/' +
          data.documentId
        "
        target="_blank"
        >{{ data?.chartNo ? data?.chartNo : "-" }}
      </a></td>
      <td [matTooltip]="data.fullName || '-'">{{ data.fullName }}</td>

      <td
        [matTooltip]="
          data?.orderedDate
        "
      >
        {{ data.orderedDate }}
      </td>
      <td
        [matTooltip]="
          data?.overrideDate
        "
      >
        {{ data.overrideDate}}
      </td>
      <td [matTooltip]="data.csrName || '-'">{{ data.csrName }}</td>
    </tr>
  </tbody>
  <tfoot>
    <tr>
      <th></th>
      <th></th>
      <th>
        <input
          matInput
          type="text"
          class="textbox-border-class"
          placeholder="Ticket Id"
        />
      </th>
      <th>
        <input
          matInput
          type="text"
          class="textbox-border-class"
          placeholder="Chart No"
        />
      </th>
      <th>
        <input
          matInput
          type="text"
          class="textbox-border-class"
          placeholder="Patient Name"
        />
      </th>

      <th>
        <input
          matInput
          type="text"
          class="textbox-border-class"
          placeholder="Ordered Date"
        />
      </th>
      <th>
        <input
          matInput
          type="text"
          class="textbox-border-class"
          placeholder="Override Date"
        />
      </th>
      <th>
        <input
          matInput
          type="text"
          class="textbox-border-class"
          placeholder="Approved By"
        />
      </th>
    </tr>
  </tfoot>
</table>
