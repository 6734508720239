import { RestService } from '@abp/ng.core';
import type { PagedAndSortedResultRequestDto, PagedResultDto } from '@abp/ng.core';
import { Injectable } from '@angular/core';
import { CreateUpdateRequisitionTrackingDTO, CreateUpdateTrackingDTO, RequisitionTrackingDTO } from './dto';

@Injectable({
  providedIn: 'root',
})
export class RequisitionTrackingService {
  apiName = 'ItemManagement';

  create = (input: CreateUpdateRequisitionTrackingDTO) =>
    this.restService.request<any, RequisitionTrackingDTO>({
      method: 'POST',
      url: '/api/ItemApp/requisition-tracking',
      body: input,
    },
    { apiName: this.apiName });

  createReqTrackingStatusByInput = (input: CreateUpdateTrackingDTO) =>
    this.restService.request<any, string>({
      method: 'POST',
      responseType: 'text',
      url: '/api/ItemApp/requisition-tracking/req-tracking-status',
      body: input,
    },
    { apiName: this.apiName });

  delete = (id: string) =>
    this.restService.request<any, void>({
      method: 'DELETE',
      url: `/api/ItemApp/requisition-tracking/${id}`,
    },
    { apiName: this.apiName });

  get = (id: string) =>
    this.restService.request<any, RequisitionTrackingDTO>({
      method: 'GET',
      url: `/api/ItemApp/requisition-tracking/${id}`,
    },
    { apiName: this.apiName });

  getAllRequisitionTrackingList = () =>
    this.restService.request<any, RequisitionTrackingDTO[]>({
      method: 'GET',
      url: '/api/ItemApp/requisition-tracking/requisition-tracking-list',
    },
    { apiName: this.apiName });

  getById = (Id: string) =>
    this.restService.request<any, RequisitionTrackingDTO>({
      method: 'GET',
      url: `/api/ItemApp/requisition-tracking/by-id/${Id}`,
    },
    { apiName: this.apiName });

  getList = (input: PagedAndSortedResultRequestDto) =>
    this.restService.request<any, PagedResultDto<RequisitionTrackingDTO>>({
      method: 'GET',
      url: '/api/ItemApp/requisition-tracking',
      params: { sorting: input.sorting, skipCount: input.skipCount, maxResultCount: input.maxResultCount },
    },
    { apiName: this.apiName });

  update = (id: string, input: CreateUpdateRequisitionTrackingDTO) =>
    this.restService.request<any, RequisitionTrackingDTO>({
      method: 'PUT',
      url: `/api/ItemApp/requisition-tracking/${id}`,
      body: input,
    },
    { apiName: this.apiName });

  constructor(private restService: RestService) {}
}
