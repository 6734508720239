<table
  datatable
  class="row-bordered hover w-100 display"
  [dtOptions]="dtReturnTableOptions"
>
  <thead>
    <tr>
      <th class="text-center" [matTooltip]="'Options'">
        Options
      </th>
      <th [matTooltip]="'Chart No'">Chart No</th>
      <th [matTooltip]="'Patient Name'">Patient Name</th>
      <th class="text-right" [matTooltip]="'Ticket Id'">Ticket Id</th>
      <th [matTooltip]="'Return Date'">Return Date</th>
      <th [matTooltip]="'Ordered Date'">Ordered Date</th>
      <th [matTooltip]="'Created By'">Created By</th>
      <th [matTooltip]="'Status'">Status</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let data of ltReturnOrders">
      <td class="text-center" [matTooltip]="'Options'">
        <!-- <button mat-icon-button (click)="getReturnOrderItems(data.mmOrderId)"> -->
        <img
          mat-icon-button
          (click)="getReturnOrderItems(data.mmOrderId)"
          class="img-responsive cursor"
          src="assets/Opiton Icon/Add Stock.png"
          alt=""
          height="17"
        />
        <!-- </button> -->
      </td>
      <td [matTooltip]="data?.chartNo || '-'">
        <a
          class="preview-link"
          [class.disabled]="isEmpty(data.chartId) || isEmpty(data.patientId)"
          [routerLink]="
            '/patientCreateEditTab/' + data.patientId + '/' + data.documentId
          "
          target="_blank"
          >{{ data?.chartNo ? data?.chartNo : "-" }}
        </a>
      </td>

      <td [matTooltip]="data?.fullName || '-'">{{ data.fullName }}</td>
      <td class="text-right" [matTooltip]="data?.mmRefereceOrderId || '-'">
        <a
          class="preview-link"
          [class.disabled]="isEmpty(data.chartId) || isEmpty(data.patientId)"
          [routerLink]="
            '/orders/saleOrder/' + data.patientId + '/' + data.mmOrderId +'/'+'0'
          "
          target="_blank"
          >{{ data?.mmRefereceOrderId ? data?.mmRefereceOrderId : "-" }}
        </a>
      </td>

      <td
        [matTooltip]="
          data?.returnDate
            ? (data?.returnDate | date : 'MM/dd/yyyy' : 'en_US')
            : '-'
        "
      >
        {{ data.returnDate | date : "MM/dd/yyyy" : "en_US" }}
      </td>

      <td
        [matTooltip]="
          data?.orderedDate
            ? (data?.orderedDate | date : 'MM/dd/yyyy' : 'en_US')
            : '-'
        "
      >
        {{ data.orderedDate | date : "MM/dd/yyyy" : "en_US" }}
      </td>

      <td [matTooltip]="data?.csrName || '-'">{{ data.csrName }}</td>
      <td [matTooltip]="data?.status || '-'">{{ data.status }}</td>
    </tr>
  </tbody>
  <tfoot>
    <tr>
      <th class="text-center">Options</th>
      <th>
        <input
          matInput
          class="textbox-border-class"
          type="text"
          placeholder="Chart No"
        />
      </th>
      <th>
        <input
          matInput
          class="textbox-border-class"
          type="text"
          placeholder="Patient Name"
        />
      </th>
      <th>
        <input
          matInput
          class="text-center textbox-border-class"
          type="text"
          placeholder="Ticket Id"
        />
      </th>
      <th>
        <input
          matInput
          class="textbox-border-class"
          type="text"
          placeholder="Return Date"
        />
      </th>
      <th>
        <input
          matInput
          class="textbox-border-class"
          type="text"
          placeholder="Ordered Date"
        />
      </th>
      <th>
        <input
          matInput
          class="textbox-border-class"
          type="text"
          placeholder="Created By"
        />
      </th>
      <th>
        <input
          matInput
          class="textbox-border-class"
          type="text"
          placeholder="Status"
        />
      </th>
    </tr>
  </tfoot>
</table>
