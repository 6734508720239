import type { SmsNotificationDTO } from './dto/models';
import { RestService } from '@abp/ng.core';
import { Injectable } from '@angular/core';
import { MailSendDTO } from './models';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  apiName = 'orderManagement';

  sendMailToPatient = (input: MailSendDTO) =>
    this.restService.request<any, string>({
      method: 'POST',
      responseType: 'text',
      url: '/api/orderApp/notification/send-mail-to-patient',
      body: input,
    },
     { apiName: this.apiName, skipHandleError: true });

  sendSmsToPatient = (patientId: string, sMessage: string) =>
    this.restService.request<any, SmsNotificationDTO>({
      method: 'POST',
      url: `/api/orderApp/notification/send-sms-to-patient/${patientId}`,
      params: { sMessage },
    },
     { apiName: this.apiName, skipHandleError: true });

  constructor(private restService: RestService) {}
}
