<div class="row">
    <div class="col-12">
        <div class="table table-responsive">
            <table id="tbl" datatable [dtOptions]="dtClientTableOptions" class="tbl hover row-border hover w-100 display">
                <thead>
                    <tr>
                        <th><mat-icon> apps</mat-icon></th>
                        <th [matTooltip]="'Code'">Code</th>
                        <th [matTooltip]="'Name'">Name</th>
                        <th [matTooltip]="'Email'">Email</th>
                        <th [matTooltip]="'Tax ID'">Tax ID</th>
                        <th [matTooltip]="'Status'">Status</th>
                        <th [matTooltip]="'Created Date'" class="text-center">Created Date</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let data of clientListData">

                        <td>
                            <a (click)="ViewClient(data)" class="eyeCursorclass" [matTooltip]="'View Client'"><i
                                    class="fa fa-eye pointer customThemeClass mr-5"></i> </a>
                        </td>
                        <td [matTooltip]="data.defaultTenantId" (dblclick)="onRowDoubleClickV1(data?.defaultTenantId || '-')">
                            {{ (data.defaultTenantId !== null && data.defaultTenantId !== '') ? data.defaultTenantId :
                            '-' }}
                        </td>
                        <td [matTooltip]="data.tenantName" (dblclick)="onRowDoubleClickV1(data?.tenantName || '-')">
                            {{ (data.tenantName !== null && data.tenantName !== '') ? data.tenantName : '-'
                            }}
                        </td>
                        <td [matTooltip]="data.adminEmailAddress" (dblclick)="onRowDoubleClickV1(data?.adminEmailAddress || '-')">
                            {{ (data.adminEmailAddress !== null && data.adminEmailAddress !== '') ?
                            data.adminEmailAddress
                            : '-'
                            }}
                        </td>
                        <td [matTooltip]="data.taxId" (dblclick)="onRowDoubleClickV1(data?.taxId || '-')">
                            {{ (data.taxId !== null && data.taxId !== '') ? data.taxId : '-'
                            }}
                        </td>
                        <td [matTooltip]="data.status" (dblclick)="onRowDoubleClickV1(data?.status || '-')">
                            {{ (data.status !== null && data.status !== '') ? data.status :
                            '-' }}
                        </td>
                        <td class="text-center" [matTooltip]="data?.creationTime ? (data.creationTime | date : 'MM/dd/yyyy h:mm a' : 'en_US') : '-'" (dblclick)="onRowDoubleClickV1(getFormattedDate(data?.creationTime))">
                            {{ data?.creationTime ? (data.creationTime | date : 'MM/dd/yyyy' : 'en_US') : '-' }}
                        </td>
                    </tr>
                </tbody>
                <tfoot>
                    <tr>
                        <th><mat-icon> apps</mat-icon></th>
                        <th>
                            <input matInput type="text" class="textbox-border-class" placeholder="Code"
                                name="search-code" />
                        </th>
                        <th>
                            <input matInput type="text" class="textbox-border-class" placeholder="Name"
                                name="search-Name" />
                        </th>
                        <th>
                            <input matInput class="textbox-border-class" type="text" placeholder="Email"
                                name="search-Email" />
                        </th>
                        <th>
                            <input matInput type="text" class="textbox-border-class" placeholder="Tax ID"
                                name="search-Tax ID" />
                        </th>
                        <th>
                            <input matInput type="text" class="textbox-border-class" placeholder="Status"
                                name="search-Status" />
                        </th>
                        <th>
                            <input matInput class="textbox-border-class text-center" type="text" placeholder="Created Date"
                                name="search-Created Date" />
                        </th>
                    </tr>
                </tfoot>
            </table>
        </div>
    </div>
</div>