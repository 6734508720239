<div class="py-2" style="border: 2px">
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-6 d-inline-flex align-items-center">
        <!-- <h2 class="mb-0"><b>Master/Insurance Group</b></h2> -->
        <h3 class="font-weight-bolder mb-3">
          <b class="customThemeClass">Master / MM Insurance Group</b>
        </h3>
      </div>
      <div class="col-md-6">
        <div class="d-flex flex-row-reverse" style="margin-right: 1%;">
          <mat-radio-group [(ngModel)]="selectedOption" (change)="statusFilter()">
            <mat-radio-button [value]="0" class="active"><b>All</b></mat-radio-button>
            <mat-radio-button [value]="1" class="active p-2" [checked]="true"><b>Active</b></mat-radio-button>
            <mat-radio-button [value]="2" class="active"><b>Inactive</b></mat-radio-button>
          </mat-radio-group>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="col-12" style="margin: 5px;">
 
  <app-master-mminsurance-table [tableData]="insuranceDetailsData" *ngIf="!isLoading"></app-master-mminsurance-table>
</div>

<div class="col-12">
  <div class="d-flex justify-content-center" *ngIf="isLoading">
    <!-- <mat-spinner></mat-spinner> -->
    <img class="img-responsive pb-2 mr-1" src="assets/gifs/Infinity-loader.gif" />
  </div>
</div>