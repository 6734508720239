import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import emailMask from 'text-mask-addons/dist/emailMask';
import { Title } from '@angular/platform-browser';
import { InboundDocumentService } from '../admin-proxy/platform-app-management/rcm/platform-management/inbound/inbound-document.service';
import { ToastrService } from "ngx-toastr";
import { HttpErrorResponse } from '@angular/common/http';
import Swal from 'sweetalert2';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { MailTemplateDetailsService, MailTemplateMasterService } from '../admin-proxy/platform-app-management/rcm/platform-management/email-template';
import { map, startWith } from 'rxjs/operators';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { defaultGuid } from 'projects/shared/src/app/enums/allenums.enum';

@Component({
  selector: 'app-schedule-email-print-letter-v1',
  templateUrl: './schedule-email-print-letter-v1.component.html',
  styleUrls: ['./schedule-email-print-letter-v1.component.scss']
})
export class ScheduleEmailPrintLetterV1Component implements OnInit {

  EmailForm: FormGroup;
  SMSForm: FormGroup;
  emailMask: any;
  editorContent: string;
  saveButtonHide: boolean;
  patientId: string;
  subscription$: Subscription[] = [];
  filteredTemplate: any;
  lstTemplate: any;
  templateId: any;
  isEmailPrintLetter: boolean = true;
  headerTitle: string;
  isShowSpinner: boolean = false;
  isSmsOrEmail:number=0;
  constructor(
    private formBuilder: FormBuilder,
    public InboundDocumentEmailService: InboundDocumentService,
    private toastr: ToastrService,
    private activatedRoute: ActivatedRoute,
    private mailTemplateMasterService: MailTemplateMasterService,
    private mailTemplateDetailsService: MailTemplateDetailsService,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      isSMSorMailCheck: number
    } = {
        isSMSorMailCheck: 0
      }
  ) { }

  name = 'Email/PrintLetter';
  htmlContent = '';
  config: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: '20rem',
    minHeight: '5rem',
    placeholder: 'Enter text here...',
    translate: 'no',
    defaultParagraphSeparator: 'p',
    defaultFontName: 'Arial',
    toolbarHiddenButtons: [
      ['insertImage', 'insertVideo' ,'fontName'],
    ],
    // toolbarHiddenButtons: [
    //   ['bold']
    // ],
    customClasses: [
      {
        name: "quote",
        class: "quote",
      },
      {
        name: 'redText',
        class: 'redText'
      },
      {
        name: "titleText",
        class: "titleText",
        tag: "h1",
      },
    ]
  };

  ngOnInit(): void {
    // this.title.setTitle('Qsecure | Email-PrintLetter');
    this.emailMask = emailMask;
    this.EmailForm = this.formBuilder.group({
      txtEmail: new FormControl("", [Validators.required]),
      editorContent: new FormControl("", [Validators.required]),
      drpTemplate: new FormControl("", [Validators.required]),
      txtSubject: new FormControl('', [Validators.required]),
      txtTemplate: new FormControl('')
    });

    this.loadTemplate()

    const activatedRoute = this.activatedRoute.paramMap.subscribe(
      (response) => {
        this.patientId = response.get('patientId') ?? defaultGuid;

        // this.defaultFaxId = response.get('defaultFaxId') ?? defaultGuid;

      },
      (err) => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      }
    );
    this.subscription$.push(activatedRoute);

    this.fnSMSFormInitilization();

    if (this.data) {
      this.isSmsOrEmail = this.data.isSMSorMailCheck;
      if(this.isSmsOrEmail === 1){
        this.headerTitle = 'SMS';
      }else{
        this.headerTitle = 'Email / Print Letter';
      }
    }
  }


  fnSMSFormInitilization() {
    this.SMSForm = this.formBuilder.group({
      drpSMSTemplate: new FormControl('', [Validators.required]),
      txtSMSTemplate: new FormControl(''),
      txtCellNo: new FormControl('', [Validators.required]),
      txtSMSDescription: new FormControl('')
    });
  }
  // fnSendEmail() {
  //   this.saveButtonHide = true;
  //   // const patientID = this.patientId;
  //   const patientID= defaultGuid;
  //   const sContent = this.EmailForm.get('editorContent').value;
  //   const sToAddress = this.EmailForm.get('txtEmail').value;
  //   if (!sContent) {
  //     console.error('Content is empty');
  //     return;
  //   }

  //   this.InboundDocumentEmailService.sendMailtoPatientByPatientIDAndSContentAndSToAddress(patientID, sContent, sToAddress)
  //     .subscribe(
  //       (response) => {
  //         this.saveButtonHide = false;
  //         if (response) {
  //           this.toastr.success('Successfully Send Mail', 'Success');
  //           this.EmailForm.reset();
  //         } else {
  //           this.toastr.error('Failed to send email', 'Error');
  //           this.EmailForm.reset();
  //         }
  //       },
  //       (error) => {
  //         this.saveButtonHide = false;
  //         const data: HttpErrorResponse = error;
  //         Swal.fire({
  //           icon: 'info',
  //           text: data?.error?.error?.message,
  //         });
  //       }
  //     );
  // }


  fnSendEmail() {

    this.isEmailPrintLetter = false;

    if (this.isEmailPrintLetter === false) {
      this.headerTitle = 'SMS';
    }
    // this.saveButtonHide = true;
    // // const patientID = this.patientId;
    // const patientID= defaultGuid;
    // const sContent = this.EmailForm.get('editorContent').value;
    // const sToAddress = this.EmailForm.get('txtEmail').value;
    // if (!sContent) {
    //   console.error('Content is empty');
    //   return;
    // }
    // const templateDetails: CreateUpdateMailTemplateDetailsDTO ={
    //   templateId: this.templateId.id,
    //   toEmail: this.EmailForm.value.txtEmail,
    //   subject: this.EmailForm.value.txtSubject?? '',
    //   isActive: 0,
    //   isMailSent: true,
    //   tenantId: defaultGuid,
    //   documentId: defaultGuid,
    //   patientId: this.patientId,
    //   mailContent: this.EmailForm.value.editorContent,
    //   mailstatusReason: ''
    // }

    // this.mailTemplateDetailsService.create(templateDetails)
    //   .subscribe(
    //     (response) => {
    //       this.saveButtonHide = false;
    //       if (response) {
    //         this.toastr.success('Successfully Send Mail', 'Success');
    //         this.EmailForm.reset();
    //       } else {
    //         this.toastr.error('Failed to send email', 'Error');
    //         this.EmailForm.reset();
    //       }
    //     },
    //     (error) => {
    //       this.saveButtonHide = false;
    //       const data: HttpErrorResponse = error;
    //       Swal.fire({
    //         icon: 'info',
    //         text: data?.error?.error?.message,
    //       });
    //     }
    //   );
  }


  loadTemplate() {
    const template = this.mailTemplateMasterService.getStatusBasedMailTemplateList(1).subscribe(response => {
      this.lstTemplate = response

      this.filteredTemplate = this.EmailForm
        .get('txtTemplate')
        .valueChanges.pipe(
          startWith(''),
          map((value) =>
            this.lstTemplate?.filter((option) =>
              option?.templateName
                ?.toLowerCase()
                ?.includes(value?.toLowerCase() ?? '')
            )
          )
        );
    },
      (err) => { }
    );

  }

  selectTemaplate(event) {
    this.templateId = event.value
    const getTemplate = {
      templateId: this.templateId.id,
      patientId: (this.patientId === null ? defaultGuid : this.patientId)
    }

    this.mailTemplateMasterService.getMailContentByDTO(getTemplate).subscribe(response => {
      this.EmailForm.patchValue({
        editorContent: response.mailContent,
        txtEmail: response.email ?? '',
      })

    }, (err) => {
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message ? data?.error?.error?.message : 'Internal Server Error',
      });
    })
  }

  backToEmail() {
    this.isEmailPrintLetter = true;

    if (this.isEmailPrintLetter === true) {
      this.headerTitle = 'Email / print letter';
    }
  }
  // fnSendEmail() {
  //   this.saveButtonHide = true;
  //   const patientID = '88959669-1f10-3a44-c20f-3a0fdb5df3f8';
  //   const sToAddress = this.EmailForm.get('txtEmail').value;

  //   // Get the content and convert to base64
  //   const editorContent = this.EmailForm.get('editorContent').value;
  //   const base64Image = this.convertImageToBase64(editorContent);

  //   if (!base64Image) {
  //     console.error('Failed to convert image to base64');
  //     return;
  //   }

  //   this.InboundDocumentEmailService.sendMailtoPatientByPatientIDAndSContentAndSToAddress(patientID, base64Image, sToAddress)
  //     .subscribe(
  //       (response) => {
  //         this.saveButtonHide = false;
  //         if (response) {


  //         } else {
  //           console.error('Failed to send email');

  //         }
  //       },
  //       (error) => {
  //         this.saveButtonHide = false;
  //         console.error(error);

  //       }
  //     );
  // }

  // convertImageToBase64(imageSrc: string): string | null {
  //   try {

  //     const base64Image = imageSrc.split(',')[1];
  //     return base64Image;
  //   } catch (error) {
  //     console.error('Error converting image to base64', error);
  //     return null;
  //   }
  // }

  saveSMSForm() {

  }
  resetSMS() {
    this.SMSForm.reset();
  }

}
