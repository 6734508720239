<div class="card card-body mt-3 mr-2">
    <div class="row">
        <div class="col-lg-12">
            <mat-card-content>
                <div class="row">
                    <div class="col-9">
                        <h4 class="card-title npiColor font-size-16 customThemeClass"> <b>Practice Physicians</b></h4>
                    </div>
                    <div class="col-3 text-right">
                        <button type="button" class="buttonColor font-size-10 btn-sm" (click)="openDoctorModal()">
                            <i class="fa fa-plus" matTooltip="Click to Add Doctor"></i>
                            Add Doctor
                        </button>
                    </div>
                </div>
            </mat-card-content>
        </div>
    </div>
    <br />

    <div class="row">
        <div class="col-12 table-responsive">
            <table mat-table class="table ml-4" matSort [dataSource]="dataSource">
                <form style="display: flex" [formGroup]="AddDoctorSearchForm">
                    <ng-container matColumnDef="firstName">
                        <th mat-header-cell *matHeaderCellDef>
                            First Name
                        </th>
                        <td mat-cell *matCellDef="let data">
                            <ng-container>
                                {{ data?.firstName }}
                            </ng-container>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="lastName">
                        <th mat-header-cell *matHeaderCellDef>
                            Last Name
                        </th>
                        <td mat-cell *matCellDef="let data">
                            <ng-container>
                                {{ data?.lastName }}
                            </ng-container>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="npi">
                        <th mat-header-cell *matHeaderCellDef>
                            Npi
                        </th>
                        <td mat-cell *matCellDef="let data">
                            <ng-container>
                                {{data?.npi}}
                            </ng-container>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="noRecords">
                        <td class="text-align-center" mat-footer-cell *matFooterCellDef colspan="3">
                            <div *ngIf="!isLoading">
                                {{"No Records Found"}}
                            </div>
                            <div [hidden]="!isLoading">
                                <mat-spinner [diameter]="220"></mat-spinner>
                            </div>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="paginator">
                        <td mat-footer-cell *matFooterCellDef colspan="3">
                            <mat-paginator class="table table-responsive mat-table-class" #MatPaginator
                                [length]="dataSource?.data?.length" [pageSize]="10" [pageIndex]="0" showFirstLastButtons
                                [pageSizeOptions]="[5, 10, 25, 100]">
                            </mat-paginator>
                        </td>
                    </ng-container>
                </form>
                <tr mat-footer-row *matFooterRowDef="['noRecords']"
                    [ngClass]="{ active: dataSource && dataSource?.data && dataSource?.data?.length !== 0 }"></tr>
                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
                <tr mat-footer-row *matFooterRowDef="['paginator']"></tr>
            </table>
        </div>
    </div>
</div>
