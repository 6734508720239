import {
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { DataTableDirective } from 'angular-datatables';
import { defaultGuid } from 'projects/shared/src/app/enums/allenums.enum';
import { Subscription } from 'rxjs';
import { AddNoteTabComponent } from '../../add-note-tab/add-note-tab.component';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { HttpErrorResponse } from '@angular/common/http';
import Swal from 'sweetalert2';
import { PatientCommonNotesComponent } from 'projects/shared/src/app/components/patient-common-notes/patient-common-notes.component';
import { ViewPatientLedgerComponent } from 'projects/admin/src/app/view-patient-ledger/view-patient-ledger.component';
import { PatientMedicareCheckListDetailsService } from '../../patient-proxy/patient-optimization/patient-medicare-check-list-details.service';
import { ToastrService } from 'ngx-toastr';
import { MatSnackBar } from '@angular/material/snack-bar';
import { PatientManualVerificationTabComponent } from '../../patient-manual-verification-tab/patient-manual-verification-tab.component';
import { CommunicationService } from 'projects/shared/src/app/services/communication.service';
import { MedicareCheckListFilter } from '../../patient-proxy/patient/enumeration-data/medicare-check-list-status.enum';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-mm-on-demand',
  templateUrl: './mm-on-demand.component.html',
  styleUrls: ['./mm-on-demand.component.scss'],
})
export class MmOnDemandComponent implements OnInit {
  @HostBinding('style.font') font =
    '400 13px/20px Roboto, "Helvetica Neue", sans-serif';
  @HostBinding('style.letter-spacing') letterSpacing = 'normal';
  @Input() DataList: any[];
  // @Output() reloadTable = new EventEmitter<string>();
  dtMMCOnDemandOptions: any = {
    responsive: true,
    stateSave: true,
    paging: false,
    dom: 'Bfrtip',
    language: {
      emptyTable: 'No records',
      info: 'Total : _MAX_ records',
      infoEmpty: 'Total : _MAX_ records',
      infoFiltered: '(filtered : _TOTAL_ records)',
      loadingRecords: 'Loading...',
      zeroRecords: 'No matching records',
    },
    scrollCollapse: true,
    scrollY: '500px',
    scrollX: true,
    fixedHeader: true, // Fix the header
    fixedColumns: {
      leftColumns: 3, // Number of columns to fix on the left
      rightColumns: 1, // Number of columns to fix on the right
    },
    columnDefs: [
      { targets: [0, 1, -1], orderable: false },
      { targets: [0, 1, 2, -1], className: 'dt-fixed-column' },
      { targets: [12,13], visible: false },
      {
        targets: [8],
        type: 'date',
        render: function (data, type, row) {
          // Assuming data is in the format MM/DD/YYYY
          if (type === 'sort' || type === 'type') {
            var dateParts = data.split('/');
            return new Date(dateParts[2], dateParts[0] - 1, dateParts[1]);
          } else {
            return data;
          }
        },
      },
    ],
    search: {
      smart: false, // Set to "none" for full exact match
    },
    buttons: [
      {
        extend: 'excel',
        text: 'Export',
        filename: 'Master Medicare Checklist - On-Demand',
        exportOptions: {
          columns: ':visible:not(:first-child) ',
        },
      },
      {
        extend: 'colvis',
        columns: ':not(:eq(0), :eq(1), :eq(2), :eq(-1))', // Exclude first three and last columns from the column visibility toggle
      },
      {
        text: 'Show Default Column',
        action: function (e, dt, node, config) {
          dt.columns(':not(:lt(3),:last-child)').visible(false);
        },
      },
      {
        text: 'Show All Column',
        action: function (e, dt, node, config) {
          dt.columns().visible(true);
        },
      },
    ],
  };
  isLoading: boolean = false;
  disableSTATCheckbox: boolean = false;
  disableLockCheckbox: boolean = false;
  defaultGuid = defaultGuid;
  selectedRow: any;
  isRoleName:string;
  @ViewChild(DataTableDirective, { static: false })
  datatableElement: DataTableDirective;
  dtMMCFaxProcessApi: DataTables.Api;
  private mmcisStatUpdateAPICall: Subscription;
  private mmcisLockUpdateAPICall: Subscription;
  MMCDocumentUploadTypes = MedicareCheckListFilter;
  subscription$: Subscription[] = [];
  constructor(
    private dialog: MatDialog,
    private patientMedicareCheckListDetailsService: PatientMedicareCheckListDetailsService,
    private toastr: ToastrService,
    private snackBar: MatSnackBar,
    private communicationService: CommunicationService,
    private datepipe: DatePipe
  ) {}

  ngOnInit() {

    this.dtMMCOnDemandOptions.buttons[0].filename =
    'Master Medicare Checklist - On-Demand' + '-' + this.datepipe.transform(new Date(), 'MM-dd-yyyy');

    this.calculateScrollY();
    window.addEventListener('resize', this.calculateScrollY);
    this.isRoleName = localStorage.getItem('roleName');
  }

  ngAfterViewInit(): void {
    if (this.datatableElement && this.datatableElement.dtInstance) {
      this.refreshDataTable();
    } else {
      // If dtInstance is not available yet, wait for a short time and try again
      setTimeout(() => this.refreshDataTable(), 500);
    }
    window.removeEventListener('resize', this.calculateScrollY);
  }
  ngOnDestroy(): void {
    this.subscription$?.forEach((sub) => {
      sub && sub?.unsubscribe();
    });
    if (this.mmcisLockUpdateAPICall) {
      this.mmcisLockUpdateAPICall.unsubscribe();
    }
    if (this.mmcisStatUpdateAPICall) {
      this.mmcisStatUpdateAPICall.unsubscribe();
    }
    window.removeEventListener('resize', this.calculateScrollY);
  }

  handleTableCellClick(e: Event, notesData: any): void {
    const target = e.target as HTMLElement;
    const tr = target.closest('tr');
    this.getDataTableData(tr, notesData);
  }

  //DataTable Data Get Method and Toggle Notes Row in UnProcessed Table
  async getDataTableData(tr: any, notesData: any): Promise<void> {
    this.dtMMCFaxProcessApi = await this.datatableElement.dtInstance;

    if (tr) {
      const row = this.dtMMCFaxProcessApi.row(tr);

      if (row.child.isShown()) {
        // This row is already open - close it
        row.child.hide();
      } else {
        // Open this row
        this.collapseAllRows();
        row.child(this.format(row.data(), notesData)).show();
      }
    }
  }

  format(d: any, notesData: any) {
    let returnString: string = '';
    notesData?.forEach(function (value) {
      returnString +=
      '<tr class="border-bottom " style="white-space: pre-wrap !important;"><td style="border: 1px solid rgba(0, 0, 0, 0.2); width: 100%">' +
      value +
      '</td></tr>';
    });
    return returnString;
  }

  calculateScrollY = () => {
    const screenHeight =
      window.innerHeight ||
      document.documentElement.clientHeight ||
      document.body.clientHeight;
    const availableSpacePercentage = 0.5; // Adjust the percentage as needed
    this.dtMMCOnDemandOptions.scrollY = screenHeight * availableSpacePercentage;
  };
  refreshDataTable(): void {
    this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
      // Clear search input values
      $('input', dtInstance.table(0).footer()).val('');
      // Redraw the DataTable to load all records
      dtInstance.search('').columns().search('').draw();
      // Attach the event listener for filtering
      dtInstance.columns().every(function () {
        const that = this;
        $('input', this.footer()).on('keyup change', function () {
          if (that.search() !== this['value']) {
            that.search(this['value']).draw();
          }
        });
      });
    });
  }




  onRowDoubleClick(textToCopy) {
    this.copyTextToClipboard(textToCopy);
    this.showNotification('Text copied: ' + textToCopy);
  }

  showNotification(message: string): void {
    this.snackBar.open(message, 'Close', {
      duration: 3000, // Duration in milliseconds
      horizontalPosition: 'end', // Positioning the notification horizontally
      verticalPosition: 'bottom', // Positioning the notification vertically
    });
  }

  copyTextToClipboard(text: string): void {
    const textArea = document.createElement('textarea');
    textArea.value = text;
    document.body.appendChild(textArea);
    textArea.select();
    document.execCommand('Copy');
    textArea.remove();
  }

  tblItemSelectedRow(medicareChecklistId: string) {
    this.selectedRow = medicareChecklistId;
  }
  isEmpty(value: any): boolean {
    return (
      value === null ||
      value === undefined ||
      value === '' ||
      value === defaultGuid
    );
  }

  isStatUpdate(event: any, data: any): void {
    this.disableSTATCheckbox = true;
    this.isLoading = true;
    const mediCareChecklisId = data.medicareChecklistId;
    const documentId = data.docId;
    try {
      if (this.mmcisStatUpdateAPICall) {
        this.mmcisStatUpdateAPICall.unsubscribe();
      }
      this.mmcisStatUpdateAPICall = this.patientMedicareCheckListDetailsService
        .updateIsStatByMedicareChecklisIdAndDocumentId(
          mediCareChecklisId,
          documentId
        )
        .subscribe(
          (response) => {
            this.toastr.success('STAT Saved Successfully', 'Success');
            this.isLoading = false;
            // data.successfulStatUpdate = event.checked ? true : false;
            // this.communicationService.triggerMasterMedicare(this.MMCDocumentUploadTypes.OnDemand);
          },
          (error) => {
            this.isLoading = false;
            this.toastr.error('STAT Update Failed', 'Error');
            this.communicationService.triggerMasterMedicare(this.MMCDocumentUploadTypes.OnDemand);
            // this.reloadTable.emit('Mani');
            console.error('Error fetching patient details:', error);
          },
          () => {
            this.isLoading = false;
            // This block will be executed on completion
            setTimeout(() => {
              this.disableSTATCheckbox = false; // Enable the checkbox
            }, 1000);
          }
        );
    } catch (error) {
      this.isLoading = false;
      this.toastr.error('STAT Update Failed- Error', 'Error');
      this.communicationService.triggerMasterMedicare(this.MMCDocumentUploadTypes.OnDemand);
      // this.reloadTable.emit('Mani');
      console.error('Unexpected error:', error);
    } finally {
    }
  }

  islockUpdate(event: MatSlideToggleChange,data:any,isDoChekList:number) {
    this.disableLockCheckbox = true;
    const isLockCheck = event.checked ? 1 : 0;
    try {
      if (this.mmcisLockUpdateAPICall) {
        this.mmcisLockUpdateAPICall.unsubscribe();
      }
      this.mmcisLockUpdateAPICall = this.patientMedicareCheckListDetailsService
        // .updateLockedDataByMedicareChecklisIdAndDocumentId(
          .updateLockedDataByMedicareChecklisIdAndDocumentIdAndILockStatus(
          data.medicareChecklistId,data.docId,isLockCheck
        )
        .subscribe(
          (response) => {
            this.toastr.success('Lock Update Successfully', 'Success');
            data.islockstatusCheck = event.checked ? false : true;
            const responseData=response;
            data.lockedbyName=responseData?.lockedBy;
            data.lockedStatus = event.checked ? 1 : 0;
            data.isLocked = event.checked ? 1 : 0;
            if(isDoChekList === 1){
              // setTimeout(() => {
                const url = '/#do_checklist/' + data?.patientId + '/' + data.medicareChecklistId;
                window.open(url, '_blank');
              // },500);
            }
          },
          (error) => {
            // this.toastr.error('Lock Update Failed', 'Error');
            const data: HttpErrorResponse = error;
            const errorMessage = data?.error;
            this.toastr.error(errorMessage?.error?.message, 'Error');
            this.communicationService.triggerMasterMedicare(this.MMCDocumentUploadTypes.FaxProcess);
          },
          () => {
            // This block will be executed on completion
            setTimeout(() => {
              this.disableLockCheckbox = false; // Enable the checkbox
            }, 1000);
          }
        );
    } catch (error) {
      this.toastr.error('Lock Update Failed', 'Error');
      this.communicationService.triggerMasterMedicare(this.MMCDocumentUploadTypes.FaxProcess);
      // this.reloadTable.emit('Mani');
      console.error('Unexpected error:', error);
    } finally {
    }
  }


  isDoChekList(data:any){
    this.islockUpdate({ checked: true } as MatSlideToggleChange, data,1);
    // setTimeout(() => {
    //   const url = '/#do_checklist/' + data?.patientId + '/' + data.medicareChecklistId;
    //   window.open(url, '_blank');
    // },500);
}

  deleteChecklist(MedicareChecklisId: string) {
    Swal.fire({
      title: 'Are you sure you want to Delete?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#34c38f',
      cancelButtonColor: '#f46a6a',
      confirmButtonText: 'Yes, delete it!',
    }).then((result) => {
      this.toastr.success('Deleted Successfully', 'Success');
    });
  }

  // openAddNotesPopUp(
  //   patientId: string,
  //   defaultFaxId: string,
  //   docId: string,
  //   medicareChecklistId: string
  // ) {
  //   const config: MatDialogConfig = {
  //     disableClose: true,
  //     minHeight: '30vh',
  //     minWidth: '50vw',
  //     data: {
  //       noteType: 'patient', // 'Order Note',
  //       notesId: '00000000-0000-0000-0000-000000000000',
  //       patientId: patientId,
  //       defaultFaxId: defaultFaxId,
  //       isCallNote: false,
  //       isPending: false,
  //       docId: docId,
  //       inboundStatusShortCodeId: defaultGuid,
  //       selectedNode: defaultGuid,
  //       addnotes: true,
  //       patientMedicareChecklistId: medicareChecklistId,
  //     },
  //   };
  //   const dialogRef = this.dialog.open(AddNoteTabComponent, config);

  //   const closeDialog = dialogRef.afterClosed().subscribe(
  //     () => {
  //       // this.getReminderTableData();
  //     },
  //     (err) => {
  //       const data: HttpErrorResponse = err;
  //       Swal.fire({
  //         icon: 'info',
  //         text: data?.error?.error?.message,
  //       });
  //     }
  //   );
  //   this.subscription$.push(closeDialog);
  // }
  collapseAllRows(): void {
    const rows = this.dtMMCFaxProcessApi.rows({ page: 'current' }); // Get all rows on the current page
    rows.every((idx, data, node) => {
      const rowData = this.dtMMCFaxProcessApi.row(node).data();
      if (rowData) {
        this.dtMMCFaxProcessApi.row(node).child.hide(); // Hide the child row if it's expanded
      }
      return true; // Continue to the next row
    });
  }

  openAddNotesPopUp(event: MouseEvent,data: any) {
    const config: MatDialogConfig = {
      disableClose: true,
      minHeight: '30vh',
      minWidth: '50vw',
      data: {
        // claimId: '00000000-0000-0000-0000-000000000000',
        noteType: 'patient', // 'Order Note',
        notesId: '00000000-0000-0000-0000-000000000000',
        // orderId: '0b50be04-6c75-92d3-97c6-3a0e17ee6a18',
        patientId: data.patientId,
        defaultFaxId: data.defautlFaxNo,
        isCallNote: false,
        isPending: false,
        docId: data.docId,
        inboundStatusShortCodeId: defaultGuid,
        selectedNode: defaultGuid,
        addnotes: true,
        patientNotes: data.notes,
        patientMedicareChecklistId: data.medicareChecklistId,
      },
    };
    const dialogRef = this.dialog.open(AddNoteTabComponent, config);
    const closeDialog = dialogRef.afterClosed().subscribe(
      (notes: any) => {
        if (notes) {
          data.notes = notes;
          this.collapseAllRows();
        }
      },
      (err) => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      }
    );
    this.subscription$.push(closeDialog);
  }
  openViewNotesPopup(event: MouseEvent, data: any) {
    const config: MatDialogConfig = {
      disableClose: true,
      width: '70vw',
      maxWidth: '70vw',
      height: '40vw',
      maxHeight: '40vw',
      data: {
        patientId: data.patientId,
        pageType: 'Patient Note',
        isAddVisible: false,
        patientMedicareChecklistId: data.medicareChecklistId,
      },
    };
    const dialogRef = this.dialog.open(PatientCommonNotesComponent, config);
    dialogRef.afterClosed().subscribe(
      () => {},
      (err: HttpErrorResponse) => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      }
    );
  }

  viewPatientLedger(patientId: string) {
    this.dialog.open(ViewPatientLedgerComponent, {
      width: '100vw',
      height: '100vh',
      maxWidth: '100vw',
      maxHeight: '100vh',
      panelClass: 'fullscreen-dialog-panel',
      disableClose: true,
      data: {
        patientId: patientId,
      },
    });
    this.dialog.afterAllClosed.subscribe((resposne) => {});
  }
  viewVerificationById(data: any): void {
    const dialogRef = this.dialog.open(PatientManualVerificationTabComponent, {
      data: {
        patientId: data.patientId,
        verificationId: data.verificationId,
        policyId: data.policyId,
        documentId: data.docId,
        inboundShortCode: data.inboundShortCode,
        savehidden: true,
        isViewVerification: true,
        defaultFaxId:data?.defautlFaxNo
      },
    });
    const closeDialog = dialogRef.afterClosed().subscribe((result) => {
      dialogRef?.close(result);
    });
    this.subscription$.push(closeDialog);
  }
}
