import { ListService } from '@abp/ng.core';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import {
  FormGroup,
  FormBuilder,
  FormControl,
  Validators,
} from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Title } from '@angular/platform-browser';
import { ModifierDTO } from 'projects/patient/src/app/dropdown-proxy/dropdown-management/dropdowns/dto/billing';
import { Observable, Subscription } from 'rxjs';
import Swal from 'sweetalert2';
import { ToastrService } from "ngx-toastr";
import { Moment } from 'moment';
import { LocaleConfig } from 'ngx-daterangepicker-material';
import { DatePipe } from '@angular/common';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DataTableDirective } from 'angular-datatables';
import { CommunicationService } from 'projects/shared/src/app/services/communication.service';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { MmModifierService } from 'projects/billing/src/app/claim-proxy/claim-processing-management/rcm/billing-management/billing/mm-modifier.service';
import { CreateUpdateMmModifierDTO } from 'projects/billing/src/app/claim-proxy/claim-processing-management/rcm/billing-management/dto';
import { noWhitespaceValidator } from 'projects/patient/src/app/add-note-tab/add-note-tab.component';

@Component({
  selector: 'app-master-modifiers',
  templateUrl: './master-modifiers.component.html',
  styleUrls: ['./master-modifiers.component.scss'],
  providers: [ListService, DatePipe],
})
export class MasterModifiersComponent implements OnInit, OnDestroy {

  @Input() tableModifier: any;
  @Input() currentPage: any = 1;
  @Input() totalPages: any = 1;
  @Input() paginationInfo: { startRecord: number, endRecord: number, overallTableCount:number };
  @Output() nevigatePage? = new EventEmitter<number>();
  
  modifierTbldata: any;
  mstModifierId: string = '';
  mstModifyrForm: FormGroup;
  modifierSearchForm: FormGroup;
  selectedRange: { startDate: Moment, endDate: Moment };
  ranges: any;
  minDate: Moment;
  maxDate: Moment;
  fromDate: any;
  toDate: any
  // calendarLocale: LocaleConfig;
  dateRangStatus: boolean = false;
  calendarPlaceholder: string = '';
  alwaysShowCalendars: boolean;
  isShown: boolean = false;
  dataSource: MatTableDataSource<any>;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  // displayedColumns: string[] = [
  //   'Options',
  //   'modifierName',
  //   'creationTime',

  // ];
  isShowSpinner: boolean;
  saveButtonHide: boolean;
  drpModifiers: ModifierDTO[] = [];
  step: boolean = false;
  txtModifier: string = '';
  tableMstModifry: ModifierDTO[] = [];
  public filteredModifiers: Observable<any[]>;
  subscription$: Subscription[] = [];
  @ViewChild(DataTableDirective, { static: false })
  datatableElement: DataTableDirective;
  dtModifierTableOptions:any;
  isActiveStatus:boolean = true;
  constructor(
    private formBuilder: FormBuilder,
    private datepipe: DatePipe,
    public title: Title,
    public list: ListService,
    private mModifierServices: MmModifierService,
    private toastr: ToastrService,
    private snackBar: MatSnackBar,
    private communicationService: CommunicationService,
  ) {

    // this.calendarLocale = {
    //   customRangeLabel: 'Pick a date...',
    //   applyLabel: 'Apply',
    //   clearLabel: 'Clear',
    //   format: 'DD/MM/YYYY',
    //   daysOfWeek: ['su', 'mo', 'tu', 'we', 'th', 'fr', 'sa'],
    //   monthNames: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
    //   firstDay: 1
    // };

    // this.ranges = {
    //   'Today': [moment(), moment()],
    //   'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
    //   'Last 7 Days': [moment().subtract(6, 'days'), moment()],
    //   'Last 30 Days': [moment().subtract(29, 'days'), moment()],
    //   'This Month': [moment().startOf('month'), moment().endOf('month')],
    // };
  }

  ngOnInit(): void {
    this.tableInitialization();
    this.dtModifierTableOptions.buttons[0].filename =
    'Master Modifier' + '-' + this.datepipe.transform(new Date(), 'MM-dd-yyyy');

    this.title.setTitle('Qsecure | Master Modifiers');
    this.mstModifyrForm = this.formBuilder.group({
      txtNewMstModifyr: new FormControl('', [Validators.required,noWhitespaceValidator]),
      txtDescription: new FormControl('', [Validators.required,noWhitespaceValidator]),
    });
    this.modifierTbldata = this.tableModifier;
  }

  ngOnDestroy(): void {
    this.subscription$?.forEach((sub) => {
      sub && sub?.unsubscribe();
    });
  }

  tableInitialization(){
    this.dtModifierTableOptions = {
      responsive: true,
      paging: false,
      scrollCollapse: true,
      scrollY: '410px',
      scrollX: true,
      dom: 'Bfrtip',
      language: {
        emptyTable: 'No records',
        info: `Showing ${this.paginationInfo?.startRecord ?? 0} to ${this.paginationInfo?.endRecord ?? 0} of ${this.paginationInfo?.overallTableCount ?? 0} records`,
        infoEmpty: 'Total : _MAX_ records',
        infoFiltered: '(filtered : _TOTAL_ records)',
        loadingRecords: 'Loading...',
        zeroRecords: 'No matching records',
      },
      fixedHeader: true,
      buttons: [
        // {
        //   extend: 'colvis',
        //   columns: ':not(:eq(0), :eq(1), :eq(-1))', // Exclude first three and last columns from the column visibility toggle
        // },
        {
          extend: 'excel',
          text: 'Export',
          filename: 'Master Modifier',
          className: 'custom-dt-export-button',
          exportOptions: {
            columns: ':visible:not(:first-child,:eq(0)) ',
          },
        },
      ]
    };
  }
  toggle() {
    this.step = true;
  }
  toggleV2() {
    this.step = false;
  }

  //After View Method
  ngAfterViewInit(): void {
    if (this.datatableElement && this.datatableElement.dtInstance) {
      this.refreshDataTable();
    } else {
      setTimeout(() => this.refreshDataTable(), 500);
    }
  }

  refreshDataTable(): void { 
    this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
      $('input', dtInstance.table(0).footer()).val('');
      dtInstance.search('').columns().search('').draw();
      this.addPageNavigationControls();
      dtInstance.columns().every(function () {
        const that = this; 
        $('input', this.footer()).on('keyup change', function () {
          if (that.search() !== this['value']) {
            that.search(this['value']).draw();
          }
        });
      });
    });
  }

  

  addPageNavigationControls() {
    let totalPages = this.totalPages;
    let currentPage = this.currentPage;
    let buttonsElement = $('.dt-buttons')[0];
    // $("<span> : <span>").appendTo(buttonsElement);
    if (this.currentPage == 1) {
      $(
        "<button disabled title='First Page' class='dt-button dt-custom-pagination-buttons disabled' id='btnFirstPage' type='button'><span>&lt;&lt;</span></button>"
      ).appendTo(buttonsElement);
      $(
        "<button disabled class='dt-button dt-custom-pagination-buttons disabled' id='btnPrevPage' type='button'><span>&lt;</span></button>"
      ).appendTo(buttonsElement);
    } else {
      $(
        "<button title='First Page' class='dt-button dt-custom-pagination-buttons' id='btnFirstPage' type='button'><span>&lt;&lt;</span></button>"
      ).appendTo(buttonsElement);
      $(
        "<button title='Previous Page' class='dt-button dt-custom-pagination-buttons' id='btnPrevPage' type='button'><span>&lt;</span></button>"
      ).appendTo(buttonsElement);
    }
    // style='float:left !important;'
    $("<span class='dt-pagination-input-span' > Page <span>").appendTo(
      buttonsElement
    );
    $(
      "<input class='dt-pagination-input' maxlength='6' id='txtGotoPage' value='" +
        this.currentPage +
        "' type='text'/>"
    ).appendTo(buttonsElement);

    ////Input Restrictions on pagination text box
    $('#txtGotoPage').on('input', function (e) {
      let value = (this as HTMLInputElement).value;
      value = value.replace(/[^0-9]/g, '');
      if (value.startsWith('0')) {
        value = value.substring(1);
      }
      //Invalid page number
      if ((value==''?currentPage:value) > totalPages || (value==''?currentPage:value) == currentPage) {
        $('#btnGoToPage').addClass('disabled');
      } else if(value!=currentPage && value <= totalPages) {
        $('#btnGoToPage').removeClass('disabled');
      }
      (this as HTMLInputElement).value = value;
    });
    //If the user leaves empty text box patch current page
    $('#txtGotoPage').on('focusout', function (e) {
      let value = (this as HTMLInputElement).value;
      if (value == '') {
        (this as HTMLInputElement).value = currentPage;
      }
    });

    $(
      "<span class='dt-pagination-input-span pl-1'> of " +
        this.totalPages +
        ' <span>'
    ).appendTo(buttonsElement);
    $(
      "<button  class='dt-button dt-custom-pagination-buttons-goto disabled' id='btnGoToPage'   type='button'><span>Go to</span></button>"
    ).appendTo(buttonsElement);

    if (this.currentPage >= this.totalPages) {
      $(
        "<button disabled class='dt-button dt-custom-pagination-buttons disabled' id='btnNextPage'   type='button'><span>&gt;</span></button>"
      ).appendTo(buttonsElement);
      $(
        "<button disabled title='Last Page'  class='dt-button dt-custom-pagination-buttons disabled' id='btnLastPage'  type='button'><span>&gt;&gt</span></button>"
      ).appendTo(buttonsElement);
    } else {
      $(
        "<button title='Next Page'  class='dt-button dt-custom-pagination-buttons' id='btnNextPage'  type='button'><span>&gt;</span></button>"
      ).appendTo(buttonsElement);
      $(
        "<button title='Last Page'  class='dt-button dt-custom-pagination-buttons' id='btnLastPage'  type='button'><span>&gt;&gt</span></button>"
      ).appendTo(buttonsElement);
    }

    $('#btnNextPage').on('click', (event) => this.changePage(true));
    $('#btnPrevPage').on('click', (event) => this.changePage(false));
    $('#btnGoToPage').on('click', (event) => this.fnGotoPage());
    $('#btnFirstPage').on('click', (event) => this.nevigatePage.emit(1));
    $('#btnLastPage').on('click', (event) =>
      this.nevigatePage.emit(this.totalPages)
    );
  }

  changePage(isNext: boolean) {
    if (isNext) this.currentPage++;
    else this.currentPage--;
    this.nevigatePage.emit(this.currentPage);
  }

  fnGotoPage() {
    let pageValue = $('#txtGotoPage').val();
    let pageno = parseInt(pageValue.toString());
    if (pageno > 0 && this.currentPage != pageno && pageno <= this.totalPages) {
      this.nevigatePage.emit(pageno);
    }
  }


  //Get Master modifier dropdown
  // getMasterModifierDrp() {
  //   const getModifiersDrp = this.mstModifryService.getList(new PagedAndSortedResultRequestDto()).subscribe(
  //     (response) => {
  //       this.drpModifiers = response?.items ?? [];
  //       this.filteredModifiers = this.mstModifyrForm?.get("drpMstModifyrFilter").valueChanges
  //       .pipe(
  //         startWith(''),
  //         map(value => this.drpModifiers.filter(option => option?.modifierName?.toLowerCase()?.includes(value?.toLowerCase() ?? "")))
  //       );
  //     },
  //     (err) => {
  //       const data: HttpErrorResponse = err;
  //       Swal.fire({
  //         icon: 'error',
  //         text: data?.error?.error?.message,
  //       });
  //     }
  //   );
  //   this.subscription$.push(getModifiersDrp);
  // }

  //! get Master modifier tables data & dropdown
  // getMasterModifierTable() {
  //   this.isShowSpinner = true;
  //   this.tableMstModifry = [];
  //   const getModifiersTable = this.mstModifryService.getList(new PagedAndSortedResultRequestDto()).subscribe(
  //     (response) => {
  //       this.tableMstModifry = response?.items?.reverse() ?? [];
  //       this.drpModifiers = response?.items ?? [];
  //       this.filteredModifiers = this.mstModifyrForm?.get("drpMstModifyrFilter").valueChanges
  //         .pipe(
  //           startWith(''),
  //           map(value => this.drpModifiers.filter(option => option?.modifierName?.toLowerCase()?.includes(value?.toLowerCase() ?? "")))
  //         );
  //       this.isShowSpinner = false;
  //       this.dataSource = new MatTableDataSource(this.tableMstModifry);
  //       this.dataSource.sort = this.sort;
  //       this.dataSource.paginator = this.paginator;
  //     },
  //     (err) => {
  //       this.isShowSpinner = false;
  //       this.dataSource = new MatTableDataSource(this.tableMstModifry);
  //       this.dataSource.sort = this.sort;
  //       this.dataSource.paginator = this.paginator;
  //       const data: HttpErrorResponse = err;
  //       // Swal.fire({
  //       //   icon: 'error',
  //       //   text: data?.error?.error?.message,
  //       // });
  //     }
  //   );
  //   this.subscription$.push(getModifiersTable);
  // }

  // datesUpdateds(e) {
  //   if (e.startDate != null || e.endDate != null) {
  //     this.dateRangStatus = true
  //     const startDate = new Date(e.startDate);
  //     const endDate = new Date(e.endDate);
  //     this.fromDate = this.datepipe.transform(startDate, "MM/dd/yyyy");
  //     this.toDate = this.datepipe.transform(endDate, "MM/dd/yyyy");

  //     this.getModifierBySearch()

  //   } else {
  //     this.dateRangStatus = false
  //     this.fromDate = null,
  //       this.toDate = null
  //     this.getModifierBySearch()
  //   }
  // }
  setTableData(tableData: ModifierDTO[] = []) {
    this.dataSource = new MatTableDataSource(tableData);
    this.sort.disableClear = true;
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }

  // getModifierBySearch() {
  //   const valueChanges = this.modifierSearchForm.valueChanges
  //     .pipe(
  //       startWith({
  //         modifierName: "",
  //         createdDate: ""

  //       }),
  //       tap((x) => {
  //         this.isShowSpinner = true;
  //         // this.setTableData([]);
  //       }),
  //       filter((e) => e && this.modifierSearchForm?.valid),
  //       debounceTime(300),
  //       map((response) => {
  //         this.isShowSpinner = true;
  //         const sValue = {
  //           sModifierName: response?.modifierName?.trim()?.toLowerCase(),
  //           sdtCreationDate: response?.createdDate?.trim()?.toLowerCase(),
  //           dtStartDate: this.fromDate?.trim()?.toLowerCase(),
  //           dtEndDate: this.toDate?.trim()?.toLowerCase(),


  //         };
  //         return sValue;
  //       }),


  //       switchMap((sValue) =>
  //         this.mstModifryService.searchModifierBySModifierNameAndSdtCreationDateAndDtStartDateAndDtEndDate(
  //           sValue?.sModifierName,
  //           sValue?.sdtCreationDate,
  //           sValue?.dtStartDate,
  //           sValue?.dtEndDate,
  //         )
  //       )
  //     )
  //     .subscribe(
  //       (response) => {
  //         this.isShowSpinner = false
  //         const responseData: ModifierDTO[] = response.items ?? [];
  //         this.setTableData(responseData);
  //       },
  //       (err) => {
  //         this.isShowSpinner = false;
  //         const data: HttpErrorResponse = err;
  //       }
  //     );

  // }


  // Clear Mst Modifiers feild
  clearMstModifier() {
    this.txtModifier = '';
    this.mstModifyrForm.patchValue({
      // drpMstModifyr: "",
      txtNewMstModifyr: ""
    });
  }

  //! get Master Modifier form Instance
  getMstModifyrForm(): FormGroup {
    return this.mstModifyrForm;
  }
  //! View Mst Modifier By ID
  viewMstModifier(value: string) {
    this.mstModifyrForm.markAsDirty();
    this.saveButtonHide = true;
    if (value) {
      const modifiersGet = this.mModifierServices.get(value).subscribe(
        (resposne) => {
          this.saveButtonHide = false;
          this.getMstModifyrForm().patchValue({
            txtNewMstModifyr: resposne?.mmRefId || "",
            txtDescription: resposne?.description || "",
          });
          this.mstModifierId = resposne?.id || null;
          this.isActiveStatus = resposne?.active,
          this.step = true;
        },
        (err: HttpErrorResponse) => {
          this.saveButtonHide = false;
          const data: HttpErrorResponse = err;
          Swal.fire({
            icon: 'info',
            text: data?.error?.error?.message,
          });
        }
      );
      this.subscription$.push(modifiersGet);
    }

  }
  //! Delete Mst Modifier By ID

  // deleteMstModifier(value: string) {
  //   Swal.fire({
  //     title: 'Are you sure you want to Delete?',
  //     text: "You won't be able to retrieve this data!",
  //     icon: 'warning',
  //     showCancelButton: true,
  //     confirmButtonColor: '#34c38f',
  //     cancelButtonColor: '#f46a6a',
  //     confirmButtonText: 'Yes, delete it!',
  //   }).then((result) => {
  //     if (result.value) {
  //       if (value) {
  //         const deleteModifiers = this.mstModifryService.delete(value).subscribe(
  //           (resposne) => {
  //             this.resetMstModifier();
  //             // this.getMasterModifierTable();
  //             this.communicationService.triggerReloadModifier();
  //           },
  //           (err) => {
  //             const data: HttpErrorResponse = err;
  //             Swal.fire({
  //               icon: 'info',
  //               text: data?.error?.error?.message,
  //             });
  //           }
  //         );
  //         this.subscription$.push(deleteModifiers);
  //       }

  //     }
  //   });
  // }
  // Save or Update By Id Master Modifiers
  saveMstModifier() {
    this.saveButtonHide = true;
    const input: CreateUpdateMmModifierDTO = {
      mmRefId: this.mstModifyrForm.value.txtNewMstModifyr ?? '',
      description: this.mstModifyrForm.value.txtDescription ?? '',
      active: this.isActiveStatus,
    };
    const request = this.mstModifierId ? this.mModifierServices.update(this.mstModifierId, input) : this.mModifierServices.create(input);
    request.subscribe(
      () => {
        const action = this.mstModifierId ? 'Updated' : 'Saved';
        this.toastr.success(`${action} Successfully`, 'Success');
        this.resetMstModifier();
        this.communicationService.triggerReloadModifier();
        this.saveButtonHide = false;
        this.mstModifierId = '';
      },
      (err: HttpErrorResponse) => {
        this.saveButtonHide = false;
        Swal.fire({
          icon: 'info',
          text: err?.error?.error?.message,
        });
      }
    );
  }
  //! Reset Mst Modifier Form
  resetMstModifier() {
    this.getMstModifyrForm().patchValue({
      // drpMstModifyr: "",
      txtNewMstModifyr: "",
    });
    this.mstModifierId = '';
    this.getMstModifyrForm()?.markAsUntouched();
    this.getMstModifyrForm()?.updateValueAndValidity();
    this.getMstModifyrForm()?.reset();
  }

  //Copy Text While Duble on table Row
  onRowDoubleClickV1(textToCopy) {
    this.copyTextToClipboard(textToCopy);
    this.showNotification('Text copied: ' + textToCopy);
  }

  showNotification(message: string): void {
    this.snackBar.open(message, 'Close', {
      duration: 3000, // Duration in milliseconds
      horizontalPosition: 'end', // Positioning the notification horizontally
      verticalPosition: 'bottom', // Positioning the notification vertically
    });
  }

  copyTextToClipboard(text: string): void {
    const textArea = document.createElement('textarea');
    textArea.value = text;
    document.body.appendChild(textArea);
    textArea.select();
    document.execCommand('Copy');
    textArea.remove();
  }
  getFormattedDate(date: string): string {
    return date ? new Date(date).toLocaleDateString('en-US') : '-';
  }

   //Status Change Actice And Inactive
  statusChange(event: MatSlideToggleChange, data: any) {
    const isActive = event.checked;
    const input: CreateUpdateMmModifierDTO = {
      mmRefId: data?.mmRefId,
      description: data?.description ?? '',
      active: isActive,
    };
    const action = isActive ? 'activate' : 'deactivate';
    const actionText = isActive ? 'activated' : 'deactivated';
    const confirmButtonText = `Yes, ${action} it!`;
    const title = `Do you want to ${action}?`;
    const text = `By changing status, this Master Modifier will be ${actionText}`;

    Swal.fire({
      title,
      text,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#34c38f',
      cancelButtonColor: '#f46a6a',
      confirmButtonText,
    }).then(result => {
      if (result.value) {
        if (data?.id) {
          this.mModifierServices.update(data.id, input).subscribe(
            () => {
              this.toastr.success(`${actionText.charAt(0).toUpperCase() + actionText.slice(1)} Successfully`, 'Success');
              this.communicationService.triggerReloadModifier();
            },
            (err: HttpErrorResponse) => {
              this.communicationService.triggerReloadModifier();
              this.saveButtonHide = false;
              Swal.fire({
                icon: 'info',
                text: err?.error?.error?.message,
              });
            }
          );
        }
      } else {
        event.source.checked = !isActive;
      }
    });
  }
}
