import { Component, Injectable, OnInit, ViewChild } from '@angular/core';
import { DataTableDirective } from 'angular-datatables';
import { PatientPersonalService } from '../patient-proxy/patient-optimization/patient-personal.service';
import { Observable, Subscription } from 'rxjs';
import { MatSelect, MatSelectChange } from '@angular/material/select';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ListService } from '@abp/ng.core';
import { Title } from '@angular/platform-browser';
import { UserService } from 'projects/admin/src/app/admin-proxy/platform-app-management/rcm/platform-management/user/user.service';
import { TableService } from 'projects/shared/src/app/table.service';
import { DateValidator } from 'projects/shared/src/app/validations/date-validator';
import { OAuthService } from 'angular-oauth2-oidc';
import { ToastrService } from 'ngx-toastr';
import { DatePipe } from '@angular/common';
import { CookieService } from 'ngx-cookie-service';
import { OrganizationUnitService } from 'projects/admin/src/app/admin-proxy/platform-app-management/rcm/platform-management/organization-units/organization-unit.service';
import { map, startWith } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import Swal from 'sweetalert2';
import { MatOption } from '@angular/material/core';
import { defaultGuid } from 'projects/shared/src/app/enums/allenums.enum';
import { AddNoteTabComponent } from '../add-note-tab/add-note-tab.component';
import { PatientCommonNotesComponent } from 'projects/shared/src/app/components/patient-common-notes/patient-common-notes.component';
import { ViewPatientLedgerComponent } from 'projects/admin/src/app/view-patient-ledger/view-patient-ledger.component';
import { NewVerificationComponent } from '../newVerification/newVerification.component';
import {
  MatDatepicker,
  MatDatepickerInputEvent,
} from '@angular/material/datepicker';
import { DocumentCheckListComponent } from 'projects/admin/src/app/document-check-list/document-check-list.component';
import { PatientCommonDocumentMedicareUploadTabComponent } from 'projects/shared/src/app/components/patient-common-document-medicare-upload-tab/patient-common-document-medicare-upload-tab.component';
import { AddressService } from '../patient-proxy/patient-optimization';
import { PayerDetailService } from '../patient-proxy/patient/payer-detail.service';
import { CommonService } from 'projects/shared/src/app/services/common.service';

// function _window(): any {
//   return window;
// }

// @Injectable()
// export class WindowRef {
//   get nativeWindow(): any {
//     return _window();
//   }
// }

@Component({
  selector: 'app-patient-list-v2',
  templateUrl: './patient-list-v2.component.html',
  styleUrls: ['./patient-list-v2.component.scss'],
  providers: [ListService, DatePipe],
  // providers: [ListService, WindowRef, DatePipe],
})
export class PatientListV2Component implements OnInit {
  patientListDataArray: any;
  isLoading: boolean;
  isClose: boolean = false;
  @ViewChild(DataTableDirective, { static: false })
  datatableElement: DataTableDirective;
  private dataSubscription: Subscription;
  selectedBranchList: string[] = null;
  branchForm: FormGroup;
  organizationUnitName: string;
  tenantId: string = '';
  // public drpBranch: Branch[] = [];
  // public brnachList: Observable<Branch[]> | undefined;
  @ViewChild('selectBranch') selectBranch: MatSelect;
  @ViewChild('selectAllBranch') private selectAllBranch: MatOption;
  isNewPatient: boolean = false;
  tblItemSelectedRowId: string;
  selectedPatient: boolean = false;
  chartId: string;
  patientId: string;
  documentId: string;
  defaultGuid = defaultGuid;
  @ViewChild('picker') picker: MatDatepicker<Date>;
  BranchSelectAllOption = false;
  tableData$: Subscription;
  subscription$: Subscription[] = [];

  patientSearch: boolean = false;
  patientInupt: string = null;
  currentPage : any = 1;
  pageOffset: any = 50;
  totalPages : any = 1;
  patientSearchForm: FormGroup;
  BirthdateMaxDate: Date;
  BirthdateMinDate: Date;
  primaryPayorId: any;
  isdrpLoading: boolean = false;
  isUniq: boolean = true;
  priPayerDetails: Subscription;
  public filteredPrimaryPayors: Observable<string[]> | undefined;
  selectPayorList: any;
  payerId: string = defaultGuid;
  PayorSelectAllOption: boolean = false;
  selectedPayorList: string[] = null;
  visibleColumns : number[];
  chartIdInput: string;
  patientNameInput: string;
  dobInput: string;
  ssnInput: string;
  payorInput: string[];
  saveButtonDisabled = true;
  isPatientSearch:boolean=true;
  isResetSearch:boolean=true;
  public drpPrimaryPayor: Payor[] = [];
  isOnkeySearch: boolean = false;
  constructor(
    private formBuilder: FormBuilder,
    private dialog: MatDialog,
    private router: Router,
    public list: ListService,
    public title: Title,
    public userProfileService: UserService,
    private tableService: TableService,
    private dateValidator: DateValidator,
    private oauthService: OAuthService,
    private toastr: ToastrService,
    private datepipe: DatePipe,
    private patientPersonalService: PatientPersonalService,
    private cookieService: CookieService,
    private branchService: OrganizationUnitService,
    public PatientAddressService: AddressService,
    private payerDetailService: PayerDetailService,
    private commonService: CommonService
  ) { }

  ngOnInit() {
    this.title.setTitle('Qsecure | Patients List');
    this.initializeForms();
    this.initializePatientForms();
    // this.showDatePicker = false;
    this.tenantId = localStorage.getItem('tenantId') ?? '';
    // this.getBranchList();
    this.fnSearchPatient();
    this.loadValidDates();
    this.getPrimaryPayerList();
    this.hasvaluePatientSearchForm();
  }
  //After View Method
  ngAfterViewInit(): void {
  }

  ngOnDestroy(): void {
    // Unsubscribe to avoid memory leaks
    this.tableData$?.unsubscribe();
    if (this.dataSubscription) {
      this.dataSubscription.unsubscribe();
    }
    this.subscription$?.forEach((sub) => {
      sub && sub?.unsubscribe();
    });
  }

  initializeForms() {
    this.branchForm = this.formBuilder.group({
      drpBranch: new FormControl(''),
      txtBranch: new FormControl(''),

      patientDetails: new FormControl(''),
    });
  }

  initializePatientForms() {
    this.patientSearchForm = this.formBuilder.group({
      ChartNo: new FormControl(''),
      PatientName: new FormControl(''),
      txtBirthdate: new FormControl(''),
      ssnNo: new FormControl(''),
      // policyNo: new FormControl(''),
      drpPrimaryPayor: new FormControl(''),
      primaryPayor: new FormControl(''),
    });
  }

  onKeyPressSearch(){
    if(this.areFieldsNotEmpty()){
    // console.log(this.areFieldsNotEmpty());
    }else{
      if(this.patientSearchForm.valid && !this.patientSearchForm.errors && this.isOnkeySearch===true) {
      this.fnSearchPatient();
    }
    }

  }

  //Check empty fields for validation
  areFieldsNotEmpty(): boolean {
    const { ChartNo, PatientName, txtBirthdate, ssnNo, drpPrimaryPayor,primaryPayor} = this.patientSearchForm.value;
    return ChartNo || PatientName || txtBirthdate || ssnNo || drpPrimaryPayor || primaryPayor;
  }

  onSelectPrimaryPayor(event: any) {
    this.selectedPayorList = event.value;
    if (this.selectedPayorList.includes('selectAll')) {
      this.PayorSelectAllOption = true;
      if (this.PayorSelectAllOption) {

        this.selectedPayorList = this.drpPrimaryPayor.map(payer => payer.payerId);
        this.patientSearchForm
          .get('drpPrimaryPayor')
          .patchValue(this.selectedPayorList);

      }
    } else if (this.selectedPayorList.includes('DeselectAll')) {
      this.selectedPayorList = [];
      this.PayorSelectAllOption = false;
      this.patientSearchForm
        .get('drpPrimaryPayor')
        .patchValue([]);
    }
    else {
      this.PayorSelectAllOption =
        this.selectedPayorList.length === this.drpPrimaryPayor.length;
    }

    const payorList = event.value;
    let splitValue = payorList.split('/');
    this.primaryPayorId = splitValue[0];
    this.checkPayorUniqness(this.primaryPayorId);
  }

  checkPayorUniqness(payerId: string) {
    if (payerId !== '' && payerId !== defaultGuid) {
      this.PatientAddressService.getPayorCheckAvailablity(payerId).subscribe(
        (response) => {
          this.isUniq = response;
        },
        (err) => {
          const data: HttpErrorResponse = err;
          Swal.fire({
            icon: 'info',
            text: data?.error?.error?.message,
          });
        }
      );
    }
  }

  clearPrimaryPolicy(event: Event) {
    this.PayorSelectAllOption = false;
    event.stopPropagation();
    this.primaryPayorId = this.patientSearchForm.value?.drpPrimaryPayor;
    this.patientSearchForm.patchValue({
      drpPrimaryPayor: '',
    });
    this.getPrimaryPayerList();
    this.fnSearchPatient();
  }

  drpDownMessage() {
    if (this.isdrpLoading === false) {
      return 'No Matches found';
    } else {
      return 'Loading...';
    }
  }

  isDropdownOpen = false;
  onDropdownOpenChange(isOpen: boolean){
    this.isDropdownOpen = isOpen;
    if(this.isDropdownOpen){
      this.getPrimaryPayerList()
    }
  }

  payerSearch(value) {
    //Get Payer list
    this.isdrpLoading = true;
    if (this.priPayerDetails) {
      this.priPayerDetails.unsubscribe();
    }
    let eligibilityMode = 4;
    this.priPayerDetails = this.payerDetailService
      .getdrpPayerByIModeV1(eligibilityMode, value, this.payerId)
      .subscribe((response) => {
        this.selectPayorList = response;
        this.isdrpLoading = false;
        this.selectPayorList = response.map((x) => {
          x['payerShortCodeList'] =
          ((x?.payerCode===null||x?.payerCode==="")?'-' :x?.payerCode)+ ' ' + '/' + ' ' + x?.payerName+' ' + '/' + ' '
          + ((x?.code===null||x?.code==="")?'-':x?.code);
          return x;
        });

        this.filteredPrimaryPayors = this.patientSearchForm
          .get('primaryPayor')
          ?.valueChanges.pipe(
            startWith(''),
            map((value) =>
              this.selectPayorList?.filter((option) =>
                option?.payerShortCodeList
                  ?.toLowerCase()
                  ?.includes(value?.toLowerCase() ?? '')
              )
            )
          );
      });
  }

  //Get Payer list
  getPrimaryPayerList() {
    this.isdrpLoading = true;
    let eligibilityMode = 4;
    this.payerDetailService
      .getdrpPayerByIModeV1(eligibilityMode, '', '')
      .subscribe(
        (response) => {
          this.selectPayorList = response;
          this.isdrpLoading = false;
          this.selectPayorList = response.map((x) => {
            x['payerShortCodeList'] =
            ((x?.payerCode===null||x?.payerCode==="")?'-' :x?.payerCode)+ ' ' + '/' + ' ' + x?.payerName+ ' ' +'/' + ' '
          + ((x?.code===null||x?.code==="")?'-':x?.code);
            return x;
          });

          this.filteredPrimaryPayors = this.patientSearchForm
            .get('primaryPayor')
            ?.valueChanges.pipe(
              startWith(''),
              map((value) =>
                this.selectPayorList?.filter((option) =>
                  option?.payerShortCodeList
                    ?.toLowerCase()
                    ?.includes(value?.toLowerCase() ?? '')
                )
              )
            );
          this.drpPrimaryPayor = this.selectPayorList;
        },
        (err) => {
          this.isdrpLoading = true;
        }
      );
  }



  addSlashToDate(event: Event): void {
    const input = event.target as HTMLInputElement;
    const inputValue = input.value;
    input.value = inputValue
      .replace(/^(\d{2})(\d{1,2})/, '$1/$2')
      .replace(/^(\d{2}\/\d{2})(\d+)/, '$1/$2')
      .replace(/[^\d\/]/g, '');
  }
  clearPatinet() {
    this.branchForm.reset();
    this.patientSearch = false;
    this.fnSearchPatient();
    this.isClose = false;
  }
  // onKeyPressSearch() {
  //   this.isClose = true;
  //   if (this.branchForm.valid) {
  //     this.patientSearch = true;
  //     this.getTableData();
  //   }
  // }


  // getBranchList() {
  //   const getBranchList = this.branchService
  //     .getBranchList(this.tenantId)
  //     .subscribe(
  //       (response) => {
  //         response?.forEach((element) => {
  //           this.drpBranch.push({
  //             id: element.id,
  //             organizationUnitName: element.organizationUnitName,
  //           });
  //         });

  //         this.brnachList = this.branchForm
  //           ?.get('txtBranch')
  //           ?.valueChanges?.pipe(
  //             startWith(''),
  //             map((value) =>
  //               this.drpBranch?.filter((option) =>
  //                 option?.organizationUnitName
  //                   ?.toLowerCase()
  //                   ?.includes(value?.toLowerCase() ?? '')
  //               )
  //             )
  //           );
  //       },
  //       (err) => {
  //         const data: HttpErrorResponse = err;
  //         Swal.fire({
  //           icon: 'info',
  //           text: data?.error?.error?.message,
  //         });
  //       }
  //     );
  //   this.subscription$.push(getBranchList);
  // }

  // onBranchSelectionChange(event: MatSelectChange): void {
  //   this.selectedBranchList = event.value;
  //   if (this.selectedBranchList.includes('selectAll')) {
  //     // this.BranchSelectAllOption = !this.BranchSelectAllOption;
  //     this.BranchSelectAllOption = true;
  //     if (this.BranchSelectAllOption) {
  //       // If 'Select All' is selected, set the selectedBranchList to include all branch IDs
  //       this.selectedBranchList = this.drpBranch.map(branch => branch.id);
  //       this.branchForm
  //         .get('drpBranch')
  //         .patchValue(this.selectedBranchList);

  //     }
  //   } else if (this.selectedBranchList.includes('DeselectAll')) {
  //     this.selectedBranchList = [];
  //     this.BranchSelectAllOption = false;
  //     this.branchForm
  //       .get('drpBranch')
  //       .patchValue([]);
  //   }
  //   else {
  //     this.BranchSelectAllOption =
  //       this.selectedBranchList.length === this.drpBranch.length;
  //   }
  //   this.getTableData();
  // }

  // getTableData() {
  //   this.isLoading = true;

  //   this.patientInupt = this.branchForm.value.patientDetails;

  //   try {
  //     if (this.dataSubscription) {
  //       this.dataSubscription.unsubscribe();
  //     }
  //     this.dataSubscription = this.patientPersonalService
  //       .getPatientsListV1(this.selectedBranchList,this.patientSearch,this.patientInupt,null,null,null,null,null)
  //       .subscribe(
  //         (response) => {
  //           this.patientListDataArray = response;
  //         },
  //         (err) => {
  //           const data: HttpErrorResponse = err;
  //           Swal.fire({
  //             icon: 'info',
  //             text: data?.error?.error?.message,
  //           });
  //           this.isLoading = false;
  //         },
  //         () => {
  //           // This block will be executed on both success and error
  //           this.isLoading = false;
  //         }
  //       );
  //       // this.tableData$ = getTableData;
  //   } catch (error) {
  //     console.error('Unexpected error:', error);
  //   } finally {
  //   }
  // }

  getTableData() {
    this.isLoading = true;
    this.isPatientSearch =true;
    this.isOnkeySearch=false;
    this.isResetSearch=false;
    this.patientInupt = this.branchForm.value.patientDetails;

    try {
      if (this.dataSubscription) {
        this.dataSubscription.unsubscribe();
      }
      this.dataSubscription = this.patientPersonalService
        .getPatientsByTenantV1()
        .subscribe(
          (response) => {
            this.patientListDataArray = response;
          },
          (err) => {
            const data: HttpErrorResponse = err;
            Swal.fire({
              icon: 'info',
              text: data?.error?.error?.message,
            });
            this.isLoading = false;
          },
          () => {
            
            this.isLoading = false;
          }
        );
      // this.tableData$ = getTableData;
    } catch (error) {
      console.error('Unexpected error:', error);
    } finally {
    }
  }

  changePage(pageNo : number){
   
    if( pageNo != this.currentPage && pageNo > 0 && pageNo <= this.totalPages)
    {
      this.currentPage = pageNo; 
      this.fnSearchPatient();
    }
  }

  fnGetVisibleColumns (columns:number[]){
    this.visibleColumns = columns;
  }

  fnSearchClick (){
    this.currentPage = 1;
    this.fnSearchPatient();
  }
  //Patient Search Function
  fnSearchPatient() {
    this.isLoading = true;
    this.isPatientSearch=false;
    this.isResetSearch=true;
    this.isOnkeySearch=true;
    this.chartIdInput = this.patientSearchForm.value.ChartNo;
    this.patientNameInput = this.patientSearchForm.value.PatientName;
    this.dobInput = this.patientSearchForm.value.txtBirthdate;
    this.payorInput = this.patientSearchForm.value.drpPrimaryPayor || [];
    this.ssnInput = this.patientSearchForm.value.ssnNo;
   
    const dobInputConvert = this.datepipe.transform(
      this.dobInput,
      'yyyy-MM-dd'
    );
    try {
      if (this.dataSubscription) {
        this.dataSubscription.unsubscribe();
      }
      this.dataSubscription = this.patientPersonalService
        .patientListSearchByChartNoAndPatientNameAndDobAndPolicyIdsAndSsn(this.chartIdInput, this.patientNameInput, dobInputConvert, this.payorInput, this.ssnInput, this.currentPage, this.pageOffset)
        .subscribe(
          (response) => {
            let pagedResponse = response as any;
            this.totalPages = Math.ceil(pagedResponse.totalCount / this.pageOffset);
            this.patientListDataArray = pagedResponse.items;
            this.patientListDataArray = response?.items;
            this.patientListDataArray = this.patientListDataArray.map(
              (data) => {
                return {
                  ...data,
                  // dob: this.commonService.getFormattedDate(
                  //   data.dob
                  // ),
                  dob: this.commonService.formatDateDob(
                    data.dob
                  ),
                  setupDate: this.commonService.getFormattedDateZone(
                    data.setupDate
                  ),
                  maskDate: this.commonService.getFormattedDateZone(
                    data.maskDate
                  ),
                  createdDate: this.commonService.getFormattedDateTimeZone(
                    data.createdDate
                  ),
                  modifiedDate: this.commonService.getFormattedDateTimeZone(
                    data.modifiedDate
                  ),
                };
              }
            );
          },
          (error) => {
            this.isLoading = false;
            const data = JSON.parse(error.error);
            // const data: HttpErrorResponse = error;
            Swal.fire({
              icon: 'info',
              text: data?.error?.message,
            });
          },
          () => {
            // This block will be executed on both success and error
            this.isLoading = false;
            this.isPatientSearch=true;
          }
        );
      // this.tableData$ = getTableData;
    } catch (error) {
      const data: HttpErrorResponse = error;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    } finally {
      this.isPatientSearch=true;
    }
  }


  // get isSearchButtonEnabled(): boolean {
  //   const chartNoLength = this.patientSearchForm.get('ChartNo').value.length;
  //   const patientNameLength = this.patientSearchForm.get('PatientName').value.length;
  //   const ssnNoLength = this.patientSearchForm.get('ssnNo').value.length;
  //   return chartNoLength >= 3 || patientNameLength >= 3 || ssnNoLength >= 3;
  // }


  newPatient() {
    this.cookieService.set('patientStatus', 'true');
    this.router.navigate(['/patientCreateEditTab']);
  }
  //To add inbound documents manually
  addDocumentType(patientId: string, wipId: string) {
    const dialogData = {
      constructorpatientId: patientId ?? defaultGuid, // Replace with your actual patientId value
      constructortenantId: this.tenantId ?? defaultGuid,       // Replace with your actual tendid value
      selectedWipId: wipId ?? defaultGuid,
      isPatientList: true,
      isPatientDisable:true
    };
    const dialogRef = this.dialog.open(PatientCommonDocumentMedicareUploadTabComponent, {
      minHeight: '33vh',
      minWidth: '75vw',
      data: dialogData,
    });

    dialogRef.afterClosed().subscribe(
      () => {
        // this.getInboundDocsList();
      },
      (err) => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      }
    );
  }

  loadValidDates() {
    this.BirthdateMaxDate = new Date();
    this.BirthdateMaxDate.setDate(this.BirthdateMaxDate.getDate());
    this.BirthdateMinDate = new Date();
    this.BirthdateMinDate.setFullYear(
      this.BirthdateMinDate.getFullYear() - 120
    );
    var oneYearAgo = new Date();
    oneYearAgo.setFullYear(this.BirthdateMaxDate.getFullYear() - 1);
  }

  resetPatientSearch() {
    this.patientSearchForm.reset();
      if(this.isResetSearch == true) {
          this.currentPage = 1;
          this.fnSearchPatient();
      }
  }

  // areFieldsNotEmpty(): boolean {
  //   const { ChartNo, PatientName, txtBirthdate, drpPrimaryPayor, ssnNo } = this.patientSearchForm.value;
  //   return ChartNo || PatientName || txtBirthdate || drpPrimaryPayor || ssnNo;
  // }
  hasvaluePatientSearchForm(){
    this.patientSearchForm.valueChanges.subscribe(() => {
      const hasValue = Object.values(this.patientSearchForm.controls).some(control => control.value);
      this.saveButtonDisabled = !hasValue;
    });
  }
}

export interface Branch {
  id?: string;
  organizationUnitName?: string;
}

export interface Payor {
  payerId?: string;
  payerShortCodeList?: string;
}
