import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { Title } from '@angular/platform-browser';
import { ToastrService } from 'ngx-toastr';
import { InsuranceCategoryService } from 'projects/patient/src/app/patient-proxy/patient-optimization/insurance-category.service';
import { Subscription } from 'rxjs';
import Swal from 'sweetalert2';
import { CommunicationService } from 'projects/shared/src/app/services/communication.service';
import { CommonService } from 'projects/shared/src/app/services/common.service';
@Component({
  selector: 'app-master-mminsurance',
  templateUrl: './master-mminsurance.component.html',
  styleUrls: ['./master-mminsurance.component.scss']
})
export class MasterMminsuranceComponent implements OnInit {

  step: boolean = false;
  insuranceDetailsData:any;
  subscription$: Subscription[] = [];
  isLoading:boolean=false;
  selectedOption: number=1;
  isSaveButton:boolean =false;
  constructor(
    private insuranceCategoryService: InsuranceCategoryService,
    private toastr: ToastrService,
    public title: Title,
    private communicationService: CommunicationService,
    private commonService : CommonService
  ) {
    this.communicationService.masterMMInsuranceMethod$.subscribe(() => {
      this.statusFilter();
    });
   }

ngOnInit() {
   this.statusFilter();
   this.title.setTitle("Qsecure | Insurance Group");
 }
statusChange(event: MatSlideToggleChange,id:string) {
  if (event.checked == true) {
    Swal.fire({
      title: 'Do you want to activate?',
      text: 'By Changing Status, this Insurance will be activated',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#34c38f',
      cancelButtonColor: '#f46a6a',
      confirmButtonText: 'Yes, Activate it!'
    }).then(result => {
      if (result.value) {
        if (id && id !== "" && id !== null && id !== undefined) {
          this.insuranceCategoryService.enableDisableInsuranceCatogoryById(id).subscribe(response => {
            this.toastr.success('Activated Successfully', 'Success')
            this.statusFilter()
          }, err => {
            // this.toastr.success('Activated Successfully', 'Success')
            const data: HttpErrorResponse = err;
            Swal.fire({
              icon: 'info',
              text: data?.error?.error?.message,
            });
          });
        }
      }
      else {
        event.source.checked = false;
      }
    });
  }
  else
    if (event.checked == false) {
      Swal.fire({
        title: 'Do you want to deactivate?',
        text: 'By Changing Status, this Insurance will be deactivated',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#34c38f',
        cancelButtonColor: '#f46a6a',
        confirmButtonText: 'Yes, deactivate it!'
      }).then(result => {
        if (result.value) {
          if (id && id !== "" && id !== null && id !== undefined) {
            this.insuranceCategoryService.enableDisableInsuranceCatogoryById(id).subscribe(response => {
              this.toastr.success('Deactivated Successfully', 'Success')
              this.statusFilter()
            }, err => {
              const data: HttpErrorResponse = err;
              Swal.fire({
                icon: 'info',
                text: data?.error?.error?.message,
              });
              // this.toastr.success('Deactivated Successfully', 'Success')
            });
          }

        }
        else {
          event.source.checked = true;
        }
      });
    }
}
statusFilter(){

  this.isLoading = true;
    const Data = this.insuranceCategoryService.statusFilter(this.selectedOption).subscribe(
      (response) => {
        this.insuranceDetailsData = response;
        this.insuranceDetailsData = this.insuranceDetailsData.map((data) => {
          return {
            ...data,
            creationTime: this.commonService.getFormattedDateTimeZone(
              data.creationTime
            ),
            lastModificationTime: this.commonService.getFormattedDateTimeZone(
              data.lastModificationTime
            ),
          };
        });
        this.isLoading = false;
      },
      (error) => {
        console.error('Error fetching details:', error);
        this.isLoading = false;
      }
    );
    this.subscription$.push(Data);
}
}
