import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UserService } from 'projects/admin/src/app/admin-proxy/platform-app-management/rcm/platform-management/user/user.service';
import { CoverageAreaTypeDTO, OnTimeMasterDropdownDTO, WorkingHoursDTO, WorkingWeeksDTO } from 'projects/patient/src/app/dropdown-proxy/dropdown-management/dropdowns/dto/on-time-schedule/models';
import { OnTimeMasterDropdownService } from 'projects/patient/src/app/dropdown-proxy/dropdown-management/dropdowns/master-dropdown-service/on-time-master-dropdown.service';
import { OnTimeDropdowns } from 'projects/patient/src/app/dropdown-proxy/dropdown-management/dropdowns/on-time-dropdowns.enum';
import Swal from 'sweetalert2';

import { CreateUpdateOfficeWorkingHoursDTO, CreateUpdateUserDetailsDTO, GetUsersDTO } from '../onTime-proxy/rcm/on-time-management/on-time-schedule/dto';
import { OfficeWorkingHoursService } from '../onTime-proxy/rcm/on-time-management/on-time-schedule/office-working-hours.service';
import { WeekDays } from '../onTime-proxy/rcm/on-time-management/on-time-schedule/week-days.enum';
import { ToastrService } from "ngx-toastr";



@Component({
  selector: 'app-branch-work-hours',
  templateUrl: './branch-work-hours.component.html',
  styleUrls: ['./branch-work-hours.component.scss']
})
export class BranchWorkHoursComponent implements OnInit {
  sundayLeaveHide: boolean;
  mondayLeaveHide: boolean;
  tuesdayLeaveHide: boolean;
  wednesdayLeaveHide: boolean;
  thursdayLeaveHide: boolean;
  fridayLeaveHide: boolean;
  saturdayLeaveHide: boolean;
  AddWorkForm: FormGroup;
  arrUsersList: any[] = [];
  covShow: boolean = false;
  workShow: boolean = true;
  atShow: boolean = false;
  endShow: boolean = false;
  SelShow: boolean = false;
  drpStartAt: any[] = [];
  drpCoverage: CoverageAreaTypeDTO[];
  coverage: string = "";
  drpStartEnd: WorkingHoursDTO[];
  sunday: string = "Leave";
  Monday: string = "Leave";
  Tuesday: string = "Leave";
  Wednesday: string = "Leave";
  Thursday: string = "Leave";
  Friday: string = "Leave";
  Saturday: string = "Leave";
  wrokingbranchId: string = "";

  constructor(
    public notesDialogRef: MatDialogRef<BranchWorkHoursComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { branchId:string,wrokingbranchId: string },
    private formBuilder: FormBuilder,
    private onTimeDropdown: OnTimeMasterDropdownService,
    public userService: UserService,
    private toastr: ToastrService,
    private branchWorkHours: OfficeWorkingHoursService
  ) { }


  drpWorkOptn: WorkingWeeksDTO[];
  ngOnInit(): void {

    this.AddWorkForm = this.formBuilder.group({
      drpWorkOptn: new FormControl(""),
      AddSundayStatus: new FormControl(""),
      drpCountry: new FormControl(""),
      mondayWorkStatus: new FormControl(""),
      saturdayWorkStatus: new FormControl(""),
      tuesdayWorkStatus: new FormControl(""),
      wednesWorkStatus: new FormControl(""),
      thursWorkStatus: new FormControl(""),
      fridayWorkStatus: new FormControl(""),


      sundayForm: new FormArray([]),
      mondayForm: new FormArray([]),
      tuesForm: new FormArray([]),
      wdnesdayForm: new FormArray([]),
      thursdayForm: new FormArray([]),
      fridayForm: new FormArray([]),
      satdayForm: new FormArray([]),


    })
    if (this.data.wrokingbranchId !== "00000000-0000-0000-0000-000000000000" || this.data.wrokingbranchId !== null) {
      this.getAddHoursById()
    }
    this.getDropDown();
    this.getUsersList();
  }

  sundayArr: any[] = [];
  mondayArr: any[] = [];
  tuesdayArr: any[] = [];
  wednesdayArr: any[] = [];
  thursdayArr: any[] = [];
  fridayArr: any[] = [];
  satdayArr: any[] = [];
  getWorkingSave() {
    let AddWorkingHours: CreateUpdateOfficeWorkingHoursDTO;
    let userDetails : CreateUpdateUserDetailsDTO;

    const sundayValues = this.getSundayValues();
    const mondayValues = this.getMondayValues();
    const tuesdayValues = this.getTuesdayValues();
    const wednesdayValues = this.getWednesdayValues();
    const thursdayValues = this.getThursdayValues();
    const fridayValues = this.getFridayValues();
    const saturdayValues = this.getSaturdayValues();
    this.sundayArr = [];
    sundayValues.forEach(element => {
      this.sundayArr.push({
        userName: element.drpUser,
        weekOption: element.drpWorkOptn === "" ? []:element.drpWorkOptn,
        opensAt: element.drpStartAt.startTimeShortCodeId === null ? [] : element.drpStartAt.startTimeShortCodeId,
        closesAt: element.drpStartEnd,
        userDetails:userDetails.userId,
      })
    });

    this.mondayArr = [];
    mondayValues.forEach(element => {
      this.mondayArr.push({
        userName: element.drpMonUser,
        weekOption: element.drpMonWork === "" ? []:element.drpMonWork,
        opensAt: element.drpMonStartAt.startTimeShortCodeId === null ? [] : element.drpStartAt.startTimeShortCodeId,
        closesAt: element.drpMonStartEnd,
        userDetails:userDetails.userId,
      })
    });

    this.tuesdayArr = [];
    tuesdayValues.forEach(element => {
      this.tuesdayArr.push({
        userName: element.drpTueUser,
        weekOption: element.drpTueWork === "" ? []:element.drpTueWork,
        opensAt: element.drpTueStartAt.startTimeShortCodeId === null ? [] : element.drpStartAt.startTimeShortCodeId,
        closesAt: element.drpTueStartEnd,
        userDetails:userDetails.userId,
      })
    });

    this.wednesdayArr = [];
    wednesdayValues.forEach(element => {
      this.wednesdayArr.push({
        userName: element.drpWedUser,
        userDetails:userDetails.userId,
        weekOption: element.drpWedWork === "" ? []:element.drpWedWork,
        opensAt: element.drpWedStartAt.startTimeShortCodeId === null ? [] : element.drpStartAt.startTimeShortCodeId,
        closesAt: element.drpWedStartEnd,
      })
    });

    this.thursdayArr = [];
    thursdayValues.forEach(element => {
      this.thursdayArr.push({
        weekOption: element.drpThursWork  === "" ? []:element.drpThursWork,
        opensAt: element.drpThursStartAt.startTimeShortCodeId === null ? [] : element.drpStartAt.startTimeShortCodeId,
        closesAt: element.drpThursStartEnd,
        userName: element.drpThursUser,
        userDetails:userDetails.userId,

      })
    });

    this.fridayArr = [];
    fridayValues.forEach(element => {
      this.fridayArr.push({
        coverageArea: element.drpFriCov,
        weekOption: element.drpFriWork   === "" ? []:element.drpFriWork,
        opensAt: element.drpFriStartAt.startTimeShortCodeId === null ? [] : element.drpStartAt.startTimeShortCodeId,
        closesAt: element.drpFriStartEnd,
        userName: element.drpFriWork,
        userDetails:userDetails.userId,
      })
    });

    this.satdayArr = [];
    saturdayValues.forEach(element => {
      this.satdayArr.push({
        weekOption: element.drpSatWork    === "" ? []:element.drpSatWork,
        opensAt: element.drpSatStartAt.startTimeShortCodeId === null ? [] : element.drpStartAt.startTimeShortCodeId,
        closesAt: element.drpSatStartEnd,
        userName: element.drpFriStartAt,
        userDetails:userDetails.userId,
      })
    });

    AddWorkingHours = {
      branchId: this.data.branchId,

      branchName: "",
      officeSundayWorkingHours : {
        isOpen: this.AddWorkForm.value.AddSundayStatus === true ? 1 : 0,
        officeWorkDetails: this.sundayArr
      },
      officeMondayWorkingHours: {
        isOpen: this.AddWorkForm.value.mondayWorkStatus === true ? 1 : 0,
        officeWorkDetails: this.mondayArr
      },
      officeTuesdayWorkingHours: {
        isOpen: this.AddWorkForm.value.tuesdayWorkStatus === true ? 1 : 0,
        officeWorkDetails: this.tuesdayArr
      },
      officeWednesdayWorkingHours: {
        isOpen: this.AddWorkForm.value.wednesWorkStatus === true ? 1 : 0,
        officeWorkDetails: this.wednesdayArr
      },
      officeThursdayWorkingHours: {
        isOpen: this.AddWorkForm.value.thursWorkStatus === true ? 1 : 0,
        officeWorkDetails: this.thursdayArr
      },
      officeFridayWorkingHours: {
        isOpen: this.AddWorkForm.value.fridayWorkStatus === true ? 1 : 0,
        officeWorkDetails: this.fridayArr
      },
      officeSaturdayWorkingHours: {
        isOpen: this.AddWorkForm.value.saturdayWorkStatus === true ? 1 : 0,
        officeWorkDetails: this.satdayArr
      },
    }
    if (this.data.wrokingbranchId === "" || this.data.wrokingbranchId === null || this.data.wrokingbranchId === "00000000-0000-0000-0000-000000000000") {
      this.branchWorkHours.create(AddWorkingHours).subscribe(response => {
        // Swal.fire({ title: 'Success', html: 'Saved Successfully', icon: 'success', timer: 3000, timerProgressBar: true });
        this.toastr.success('Saved Successfully','Success')
        this.wrokingbranchId = response.id
      }, err => {
        const data: HttpErrorResponse = err;

        // Swal.fire({
        //   icon: 'info',
        //   html: data?.error?.error?.message,

        // })
        this.toastr.info(data?.error?.error?.message)
      });
    }
    else {
      this.branchWorkHours.update(this.data.wrokingbranchId, AddWorkingHours).subscribe(response => {
        // this.patientsId = response.id
        // Swal.fire({ title: 'Success', html: 'Saved Successfully', icon: 'success', timer: 3000, timerProgressBar: true });
        this.toastr.success('Saved Successfully','Success')
      }, err => {
        const data: HttpErrorResponse = err;
        // Swal.fire({
        //   icon: 'info',
        //   html: data?.error?.error?.message,
        // })
        this.toastr.info(data?.error?.error?.message)
      });

    }

  }

  // get Add Hours byid
  getAddHoursById() {

    this.branchWorkHours.get(this.data.wrokingbranchId).subscribe(response => {
      this.covShow = true;
      this.workShow = true;
      this.atShow = true;
      this.endShow = true;
      this.SelShow = true;
      const sundaySavedArr: any[] = [];
      const mondaySavedArr: any[] = [];
      const tuesdaySavedArr: any[] = [];
      const fridaySavedArr: any[] = [];
      const wednesdaySavedArr: any[] = [];
      const saturdaySavedArr: any[] = [];
      const thursSavedArr: any[] = [];
      this.AddWorkForm.patchValue({
        AddSundayStatus: response?.officeSundayWorkingHours?.isOpen == 1 ? true : false,
        mondayWorkStatus: response?.officeMondayWorkingHours?.isOpen == 1 ? true : false,
        saturdayWorkStatus: response?.officeSaturdayWorkingHours?.isOpen == 1 ? true : false,
        tuesdayWorkStatus: response?.officeTuesdayWorkingHours?.isOpen == 1 ? true : false,
        wednesWorkStatus: response?.officeWednesdayWorkingHours?.isOpen == 1 ? true : false,
        thursWorkStatus: response?.officeTuesdayWorkingHours?.isOpen == 1 ? true : false,
        fridayWorkStatus: response?.officeTuesdayWorkingHours?.isOpen == 1 ? true : false,
      })

      if (response?.officeSundayWorkingHours?.isOpen == 1) {
        this.sunday = "Working"
        this.sundayLeaveHide = true;
      }
      if (response?.officeSundayWorkingHours?.isOpen == 0) {
        this.sunday = "Leave"
        this.sundayLeaveHide = false;
      }


      if (response?.officeMondayWorkingHours?.isOpen == 1) {
        this.Monday = "Working"
        this.mondayLeaveHide = true;
      }
      if (response?.officeMondayWorkingHours?.isOpen == 0) {
        this.Monday = "Leave"
        this.mondayLeaveHide = false;
      }


      if (response?.officeTuesdayWorkingHours?.isOpen == 1) {
        this.Tuesday = "Working"
        this.tuesdayLeaveHide = true;
      }
      if (response?.officeTuesdayWorkingHours?.isOpen == 0) {
        this.Tuesday = "Leave"
        this.tuesdayLeaveHide = false;
      }


      if (response?.officeWednesdayWorkingHours?.isOpen == 1) {
        this.Wednesday = "Working"
        this.wednesdayLeaveHide = true;
      }
      if (response?.officeWednesdayWorkingHours?.isOpen == 0) {
        this.Wednesday = "Leave"
        this.wednesdayLeaveHide = false;

      }


      if (response?.officeTuesdayWorkingHours?.isOpen == 1) {
        this.Thursday = "Working"
        this.thursdayLeaveHide = true;
      }
      if (response?.officeTuesdayWorkingHours?.isOpen == 0) {
        this.Thursday = "Leave"
        this.thursdayLeaveHide = false;
      }


      if (response?.officeTuesdayWorkingHours?.isOpen == 1) {
        this.Friday = "Working"
        this.fridayLeaveHide = false;
      }
      if (response?.officeTuesdayWorkingHours?.isOpen == 0) {
        this.Friday = "Leave"
        this.fridayLeaveHide = true;
      }


      if (response?.officeSaturdayWorkingHours?.isOpen == 1) {
        this.Saturday = "Working"
        this.saturdayLeaveHide = false;
      }
      if (response?.officeSaturdayWorkingHours?.isOpen == 0) {
        this.Saturday = "Leave"
        this.saturdayLeaveHide = true;
      }

      response?.officeSundayWorkingHours?.officeWorkDetails.map(d => {
        const startTimes = this.drpStartAt?.filter(value => {
          return value && value?.startTimeShortCodeId === d?.opensAt
        })[0];

        const newDemoData = { drpWorkOptn: d?.weekOption, drpStartAt: startTimes, drpStartEnd: d?.closesAt, drpUser:d?.userDetails }
        sundaySavedArr.push(newDemoData)
        const demo = this.addAccordion();
        this.getSundayValues().push(demo);
      })

      response?.officeMondayWorkingHours?.officeWorkDetails.map(d => {
        const startTimes = this.drpStartAt?.filter(value => {
          return value && value?.startTimeShortCodeId === d?.opensAt
        })[0];
        const newDemoData = { drpMonWork: d?.weekOption, drpMonStartAt: startTimes, drpMonStartEnd: d?.closesAt, drpMonUser:d?.userDetails }
        mondaySavedArr.push(newDemoData)
        const demo = this.addMonday();
        this.getMondayValues().push(demo);
      })

      response?.officeTuesdayWorkingHours?.officeWorkDetails.map(d => {
        const startTimes = this.drpStartAt?.filter(value => {
          return value && value?.startTimeShortCodeId === d?.opensAt
        })[0];
        const newDemoData = { drpTueWork: d?.weekOption, drpTueStartAt: startTimes, drpTueStartEnd: d?.closesAt  , drpTueUser: d?.userDetails}
        tuesdaySavedArr.push(newDemoData)
        const demo = this.addTuesday();
        this.getTuesdayValues().push(demo);
      })

      response?.officeWednesdayWorkingHours?.officeWorkDetails.map(d => {
        const startTimes = this.drpStartAt?.filter(value => {
          return value && value?.startTimeShortCodeId === d?.opensAt
        })[0];
        const newDemoData = { drpWedWork: d?.weekOption, drpWedStartAt: startTimes, drpWedStartEnd: d?.closesAt, drpWedUser: d?.userDetails }
        wednesdaySavedArr.push(newDemoData)
        const demo = this.addWednesday();
        this.getWednesdayValues().push(demo);
      })

      response?.officeTuesdayWorkingHours?.officeWorkDetails.map(d => {
        const startTimes = this.drpStartAt?.filter(value => {
          return value && value?.startTimeShortCodeId === d?.opensAt
        })[0];
        const newDemoData = { drpThursWork: d?.weekOption, drpThursStartAt: startTimes, drpThursStartEnd: d?.closesAt , drpThursUser: d?.userDetails }
        thursSavedArr.push(newDemoData)
        const demo = this.addThursday();
        this.getThursdayValues().push(demo);
      })

      response?.officeTuesdayWorkingHours?.officeWorkDetails.map(d => {
        const startTimes = this.drpStartAt?.filter(value => {
          return value && value?.startTimeShortCodeId === d?.opensAt
        })[0];
        const newDemoData = { drpFriWork: d?.weekOption, drpFriStartAt: startTimes, drpFriStartEnd: d?.closesAt, drpFriUser:d?.userDetails }
        fridaySavedArr.push(newDemoData)
        const demo = this.addFriday();
        this.getFridayValues().push(demo);
      })

      response?.officeSaturdayWorkingHours?.officeWorkDetails.map(d => {
        const startTimes = this.drpStartAt?.filter(value => {
          return value && value?.startTimeShortCodeId === d?.opensAt
        })[0];
        const newDemoData = { drpSatWork: d?.weekOption, drpSatStartAt: startTimes, drpSatStartEnd: d?.closesAt , drpSatUser:d.userDetails }
        saturdaySavedArr.push(newDemoData)
        const demo = this.addSatday();
        this.getSaturdayValues().push(demo);
      })
      this.AddWorkForm.patchValue({ sundayForm: sundaySavedArr });
      this.AddWorkForm.patchValue({ mondayForm: mondaySavedArr });
      this.AddWorkForm.patchValue({ tuesForm: tuesdaySavedArr });
      this.AddWorkForm.patchValue({ wdnesdayForm: wednesdaySavedArr });
      this.AddWorkForm.patchValue({ thursdayForm: thursSavedArr });
      this.AddWorkForm.patchValue({ fridayForm: fridaySavedArr })
      this.AddWorkForm.patchValue({ satdayForm: saturdaySavedArr })
    })
  }


  private getUsersList() {
    // let userDatas:GetUsersDTO ={
      // weekDays: this.AddWorkForm.value.sundayForm.,
      // weekOption: string[],
      // startsAt: string,
      // endsAt: string,
    // }
    // this.branchWorkHours.getUsersBasedOnTimingsByInput(userDatas).subscribe(response => {
    //   // this.arrUsersList = response;

    // }, err => {
    //   const data: HttpErrorResponse = err;
    //   Swal.fire({
    //     icon: 'error',
    //     text: data?.error?.error?.message,
    //   });
    // });
  }

  getSundayValues() {
    return this.AddWorkForm.value.sundayForm
  }

  getMondayValues() {
    return this.AddWorkForm.value.mondayForm
  }

  getTuesdayValues() {
    return this.AddWorkForm.value.tuesForm
  }

  getWednesdayValues() {
    return this.AddWorkForm.value.wdnesdayForm
  }

  getThursdayValues() {
    return this.AddWorkForm.value.thursdayForm
  }

  getFridayValues() {
    return this.AddWorkForm.value.fridayForm
  }

  getSaturdayValues() {
    return this.AddWorkForm.value.satdayForm
  }




  // sundayForm input values as array
  get f() { return this.AddWorkForm.controls; }
  get t() { return this.f.sundayForm as FormArray; }

  // mondayForm input values as array
  get a() { return this.AddWorkForm.controls; }
  get b() { return this.f.mondayForm as FormArray; }

  // tuesdayForm input values as array
  get c() { return this.AddWorkForm.controls; }
  get d() { return this.c.tuesForm as FormArray; }

  // wednesdaydayForm input values as array
  get g() { return this.AddWorkForm.controls; }
  get h() { return this.g.wdnesdayForm as FormArray; }

  // thursdayForm input values as array
  get i() { return this.AddWorkForm.controls; }
  get j() { return this.i.thursdayForm as FormArray; }

  // fridayForm input values as array
  get k() { return this.AddWorkForm.controls; }
  get l() { return this.i.fridayForm as FormArray; }

  // saturdayForm input values as array
  get m() { return this.AddWorkForm.controls; }
  get n() { return this.m.satdayForm as FormArray; }

  // SundY values push into array
  addAccordion() {

    this.t.push(this.formBuilder.group({
      drpWorkOptn: new FormControl(""),
      drpStartAt: new FormControl(""),
      drpStartEnd: new FormControl(""),
      drpSelCov: new FormControl(""),
      AddSundayStatus: new FormControl(""),
      drpUser: new FormControl("")

    }));

  }

  addMonday() {
    this.b.push(this.formBuilder.group({
      drpMonWork: new FormControl(""),
      drpMonStartAt: new FormControl(""),
      drpMonStartEnd: new FormControl(""),
      drpStartAt: new FormControl(""),
      drpMonUser: new FormControl("")
    }));

  }

  addTuesday() {

    this.d.push(this.formBuilder.group({
      drpTueWork: new FormControl(""),
      drpTueStartAt: new FormControl(""),
      drpTueStartEnd: new FormControl(""),
      drpTueSelCov: new FormControl(""),
      drpTueUser: new FormControl("")

    }));

  }

  addWednesday() {

    this.h.push(this.formBuilder.group({
      drpWedCov: new FormControl(""),
      drpWedWork: new FormControl(""),
      drpWedStartAt: new FormControl(""),
      drpWedStartEnd: new FormControl(""),
      drpWedSelCov: new FormControl(""),
      drpWedUser: new FormControl("")

    }));

  }

  addThursday() {
    this.j.push(this.formBuilder.group({
      drpThursCov: new FormControl(""),
      drpThursWork: new FormControl(""),
      drpThursStartAt: new FormControl(""),
      drpThursStartEnd: new FormControl(""),
      drpThursSelCov: new FormControl(""),
      drpThursUser: new FormControl("")

    }));

  }

  addFriday() {
    this.l.push(this.formBuilder.group({
      drpFriCov: new FormControl(""),
      drpFriWork: new FormControl(""),
      drpFriStartAt: new FormControl(""),
      drpFriStartEnd: new FormControl(""),
      drpFriSelCov: new FormControl(""),
      drpFriUser: new FormControl("")
    }));

  }

  addSatday() {
    this.n.push(this.formBuilder.group({
      drpSatCov: new FormControl(""),
      drpSatWork: new FormControl(""),
      drpSatStartAt: new FormControl(""),
      drpSatStartEnd: new FormControl(""),
      drpSatSelCov: new FormControl(""),
      drpSatUser: new FormControl(""),
    }));

  }

  getDropDown() {
    let onTimeDropdownInput: OnTimeDropdowns[];
    onTimeDropdownInput = [
      OnTimeDropdowns.CoverageAreaType,
      OnTimeDropdowns.WorkingWeeks,
      OnTimeDropdowns.WorkingHoursStart,
    ]
    this.onTimeDropdown.getOnTimeDropdownsByInput(onTimeDropdownInput).subscribe((stateResponse) => {
      const response: OnTimeMasterDropdownDTO = stateResponse;
      this.drpCoverage = response?.coverageAreaTypes;
      this.drpWorkOptn = response?.workingWeeks;

      // this.drpStartAt = response?.workingHoursStarts;
    }, err => {
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    });
    this.onTimeDropdown.getWorkTimings().subscribe((stateResponse) => {
      this.drpStartAt = stateResponse.workingTimes

      // stateResponse.workingTimes.forEach(ele => {
      // this.drpStartAt.push({
      //   startAt: ele?.startTime
      // })
      // })
    })

  }

  // ! sunday status
  sundayStatus(status) {
    if (this.AddWorkForm.value.AddSundayStatus == true) {
      this.sunday = "Leave"
      this.sundayLeaveHide = false;
      this.AddWorkForm.value.sundayForm = []
      this.t.removeAt
    }
    if (this.AddWorkForm.value.AddSundayStatus == false) {
      this.sunday = "Working"
      this.sundayLeaveHide = true;
      if (this.AddWorkForm.value.sundayForm.length === 0 && this.AddWorkForm.value.AddSundayStatus == "") {
        this.addAccordion()

      }
    }

  }

  // !Monday status
  MondayStatus(status) {
    if (this.AddWorkForm.value.mondayWorkStatus == true) {
      this.Monday = "Leave"
      this.mondayLeaveHide = false;
    }
    if (this.AddWorkForm.value.mondayWorkStatus == false) {
      this.Monday = "Working"
      this.mondayLeaveHide = true;
      if (this.AddWorkForm.value.mondayForm.length === 0 && this.AddWorkForm.value.mondayWorkStatus == "") {
        this.addMonday()
      }
    }

  }

  // ! Tuesday status
  tuesdayStatus(status) {
    if (this.AddWorkForm.value.tuesdayWorkStatus == true) {
      this.Tuesday = "Leave"
      this.tuesdayLeaveHide = false;
    }
    if (this.AddWorkForm.value.tuesdayWorkStatus == false) {
      this.Tuesday = "Working"
      this.tuesdayLeaveHide = true;
      if (this.AddWorkForm.value.tuesForm.length === 0 && this.AddWorkForm.value.tuesdayWorkStatus == "") {
        this.addTuesday()

      }
    }

  }

  // ! Friday status
  fridayStatus(status) {
    if (this.AddWorkForm.value.fridayWorkStatus == true) {
      this.Friday = "Leave"
      this.fridayLeaveHide = false;
    }
    if (this.AddWorkForm.value.fridayWorkStatus == false) {
      this.Friday = "Working"
      this.fridayLeaveHide = true;
      if (this.AddWorkForm.value.fridayForm.length === 0 && this.AddWorkForm.value.fridayWorkStatus == "") {
        this.addFriday()

      }
    }

  }

  // ! saturday status
  saturdayStatus(status) {
    if (this.AddWorkForm.value.saturdayWorkStatus == true) {
      this.Saturday = "Leave"
      this.saturdayLeaveHide = false;
    }
    if (this.AddWorkForm.value.saturdayWorkStatus == false) {
      this.Saturday = "Working"
      this.saturdayLeaveHide = true;
      if (this.AddWorkForm.value.satdayForm.length === 0 && this.AddWorkForm.value.saturdayWorkStatus == "") {
        this.addSatday()

      }
    }

  }

  // ! wednes status
  wednesStatus(status) {
    if (this.AddWorkForm.value.wednesWorkStatus == true) {
      this.Wednesday = "Leave"
      this.wednesdayLeaveHide = false;
    }
    if (this.AddWorkForm.value.wednesWorkStatus == false) {
      this.Wednesday = "Working"
      this.wednesdayLeaveHide = true;
      if (this.AddWorkForm.value.wdnesdayForm.length === 0 && this.AddWorkForm.value.wednesWorkStatus == "") {
        this.addWednesday()

      }
    }

  }

  // ! thurs status
  thursStatus(status) {
    if (this.AddWorkForm.value.thursWorkStatus == true) {
      this.Thursday = "Leave"
      this.thursdayLeaveHide = false;
    }
    if (this.AddWorkForm.value.thursWorkStatus == false) {
      this.Thursday = "Working"
      this.thursdayLeaveHide = true;
      if (this.AddWorkForm.value.thursdayForm.length === 0 && this.AddWorkForm.value.thursWorkStatus == "") {
        this.addThursday()
      }
    }

  }


  onChange($event) {
    this.atShow = true


  }
  onAtChange($event, i) {


    this.endShow = true

  }
  onEndChange($event, i) {

    this.SelShow = true
  }

  workArray: any[]=[];
  onEndsChanges(startAt,work,startEnd) {
    this.SelShow = true
    this.workArray.push({
      works:work
    })
    let onTimeDropdownInput: WeekDays[];
    // onTimeDropdownInput = [
    //   WeekDays.Sunday,
    //   WeekDays.Monday,
    //   WeekDays.TuesDay,
    // ]
    const userDatas:GetUsersDTO ={
      weekDays:  WeekDays.Sunday,
      // weekOption:  work,
      startsAt: startAt.startTime,
      endsAt: startEnd,
      userDetails: null
    }
     this.branchWorkHours.getUsersBasedOnTimingsByInput(userDatas).subscribe(response => {


      this.arrUsersList = response.userDetails;

    }, err => {
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    });
  }
  onEndChanges(startAt) {
    this.SelShow = true
  }
  clearCoverage() {
    this.coverage = '';
    this.AddWorkForm.patchValue({ drpCoverage: "" });
  }

}
