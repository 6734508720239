import { HttpErrorResponse } from '@angular/common/http';
import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { TableService } from 'projects/shared/src/app/table.service';
import { NumberValidators } from 'projects/shared/src/app/validations/number-validator';
import { TextValidators } from 'projects/shared/src/app/validations/text-validator';
import { Subscription } from 'rxjs';
import Swal from 'sweetalert2';

import { GetDoctorNpiService } from '../patient-proxy/controllers/get-doctor-npi.service';
import { DoctorService } from '../patient-proxy/patient/doctor.service';
import {
  DoctorDTO,
  NPIDoctorDTO,
  NPIDoctorDTOs,
} from '../patient-proxy/patient/dto/models';
import { ToastrService } from 'ngx-toastr';
import { NpiDetailsComponent } from '../npi-details/npi-details.component';
import { PatientDoctorComponent } from '../patient-doctor/patient-doctor.component';
import { ToggleSwitchComponent } from 'projects/admin/src/app/toggle-switch/toggle-switch.component';

@Component({
  selector: 'app-patient-npi-doctor',
  templateUrl: './patient-npi-doctor.component.html',
  styleUrls: ['./patient-npi-doctor.component.scss'],
})
export class PatientNpiDoctorComponent implements OnInit, OnDestroy {
  @Input() sesPracticeID: string;
  npiPracticeID: string;
  // isLoading: boolean = false;
  isLoading: boolean;
  strPageType: string = 'npiDoctor';
  txtNpiNumber: string = '';
  txtFirstName: string = '';
  txtLastName: string = '';
  txtCity: string = '';
  txtState: string = '';
  isMedicare: boolean = false;
  chkValue: boolean = false;
  onText: string = 'Yes';
  offText: string = 'No';
  txtCountry: string = '';
  txtPosatlCode: string = '';
  organizationUnitId: string = '';
  isShown: boolean = false;
  displayedColumns: string[] = [
    'NPI Number',
    'Doctor Name',
    'Fax No',
    'Address',
    'Country',
    'State',
    'City',
    'Postal Code',
    'Action',
  ];
  //displayedColumns: string[] = ['sno', 'NpiNumber', 'Name', 'Address', 'City', 'State', 'CountryName', 'PostalCode', 'actions'];
  public npiTableData: any[] = [];
  doctorTableData: any;
  copy: any[] = [];
  Errors: any[] = [];
  NpiDoctorForm: FormGroup;
  subscription$: Subscription[] = [];
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild('MatPaginator', { static: false }) paginator: MatPaginator;
  @ViewChild(ToggleSwitchComponent) toggle: Component;
  @Output() changed: EventEmitter<boolean> = new EventEmitter<boolean>();
  dataSource = new MatTableDataSource(this.npiTableData);
  @HostListener('input', ['$event']) onInput(event: Event) {
    const input = event.target as HTMLInputElement;

    // Check if the input field has the class 'zip-code-input'
    if (input.classList.contains('zip-code-input')) {
      let formattedValue = input.value.replace(/[^0-9]/g, '');

      if (formattedValue.length > 5) {
        formattedValue =
          formattedValue.slice(0, 5) + '-' + formattedValue.slice(5);
      }

      input.value = formattedValue;
    }
  }
  constructor(
    private formBuilder: FormBuilder,
    private number: NumberValidators,
    private text: TextValidators,
    private doctorService: DoctorService,
    private npiDoctorService: GetDoctorNpiService,
    private Table: TableService,
    public dialog: MatDialogRef<PatientDoctorComponent>,
    private toastr: ToastrService
  ) {}

  ngOnInit(): void {
    this.createNPIDoctorForm();
    this.npiPracticeID = this.sesPracticeID;
  }
  change($event: boolean | undefined) {
    this.changed.emit($event);
    this.isMedicare = $event;
  }
  viewNpidetails() {
    // const dialogRef = this.dialog.open(NpiDetailsComponent, {});
    // const closeDialog = dialogRef.afterClosed().subscribe(
    //   () => {
    //     // this.getDropdown();
    //     // this.icd10Dropdown();
    //   },
    //   (err) => {
    //     const data: HttpErrorResponse = err;
    //     Swal.fire({
    //       icon: 'info',
    //       text: data?.error?.error?.message,
    //     });
    //   }
    // );
    // this.subscription$.push(closeDialog);
  }
  ngOnDestroy(): void {
    this.subscription$?.forEach((sub) => {
      sub && sub?.unsubscribe();
    });
  }
  ResetNpiDoctors() {
    this.NpiDoctorForm.reset({
      txtNpiNumber: '',
      txtFirstName: null,
      txtLastName: '',
      txtCity: '',
      txtState: '',
      txtCountry: '',
      txtPosatlCode: '',
    });
    // this.SearchNpiDoctorsAbp()
    this.doctorTableData = [];
    (this.npiPracticeID = ''), (this.isMedicare = false);
    this.reloadTable();
    this.isLoading = true;
  }
  numberOnly(event): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }
  createNPIDoctorForm() {
    this.NpiDoctorForm = this.formBuilder.group({
      // txtNpiNumber: new FormControl('', [this.number.isNumberCheck]),
      txtNpiNumber: new FormControl('', Validators.minLength(10)),
      txtEnumerationType: new FormControl(''),
      txtTaxonomyDescription: new FormControl(''),
      txtFirstName: new FormControl('', [
        this.text.isTextCheck,
        Validators.minLength(2),
      ]),
      // txtUserFirstNameAlias: new FormControl("", [this.text.isTextCheck]),
      txtLastName: new FormControl('', [this.text.isTextCheck]),
      txtOrganizationName: new FormControl(''),
      txtAddress: new FormControl(''),
      txtCity: new FormControl('', [this.text.isTextCheck]),
      txtState: new FormControl('', [this.text.isTextCheck]),
      txtCountry: new FormControl('', [this.text.isTextCheck]),
      txtPosatlCode: new FormControl('', [this.number.isNumberCheck]),
    });
  }

  SearchNpiDoctorsAbp() {
    this.isShown = true;
    this.isLoading = true;
    if (this.NpiDoctorForm.value.txtNpiNumber == '0000000000') {
      this.isShown = false;
      this.isLoading = false;
      // Swal.fire("Please Enter valid NPI Number");

      Swal.fire({
        icon: 'info',
        text: 'Please Enter valid NPI Number',
      });
      //127-553-6336
    } else {
      let npiSearchDoctors: NPIDoctorDTO = {
        number: this.NpiDoctorForm.value.txtNpiNumber,
        enumerationType: '',
        taxonomyDescription: '',
        firstName: this.NpiDoctorForm.value.txtFirstName,
        useFirstNameAlias: '',
        //  useFirstNameAlias: this.NpiDoctorForm.value.txtUserFirstNameAlias,
        lastName: this.NpiDoctorForm.value.txtLastName,
        organizationName: '',
        addressPurpose: '',
        city: this.NpiDoctorForm.value.txtCity,
        state: '',
        postalCode: this.NpiDoctorForm.value.txtPosatlCode,
        countryCode: '',
        isMedicare: this.isMedicare,
        facilityId: this.npiPracticeID,
      };
      // const searchNpi = this.npiDoctorService
      //   .getNpiDoctorsByInput(npiSearchDoctors)
      //   .subscribe(
      const searchNpi = this.npiDoctorService
        .getNpiDoctorsByMedicare(npiSearchDoctors)
        .subscribe(
          (response: any) => {
            this.npiTableData = [];
            if (response.length != 0) {
              this.isLoading = false;

              let responseArray: NPIDoctorDTOs[] = response;
              this.npiTableData = [];
              responseArray.forEach((responseData) => {
                this.npiTableData.push({
                  sno: '',
                  NpiNumber: responseData.npiNumber,
                  FullName:
                    (responseData.firstName == null || undefined
                      ? ''
                      : responseData.firstName) +
                    ' ' +
                    (responseData.lastName == null || undefined
                      ? ' '
                      : responseData.lastName),
                  FirstName:
                    responseData.firstName == null || undefined
                      ? ''
                      : responseData.firstName,
                  LastName:
                    responseData.lastName == null || undefined
                      ? ' '
                      : responseData.lastName,
                  MiddleName:
                    responseData.middleName == null || undefined
                      ? ' '
                      : responseData.middleName,
                  Prefix:
                    responseData.title == null || undefined
                      ? ' '
                      : responseData.title,
                  TelephoneNumber:
                    responseData.phoneNo == null || undefined
                      ? ' '
                      : responseData.phoneNo,
                  MedicalLicenseNo:
                    responseData.medicalLicenseNo == null || undefined
                      ? ' '
                      : responseData.medicalLicenseNo,
                  FaxNumber:
                    responseData.faxNo == null || undefined
                      ? ' '
                      : responseData.faxNo,
                  Address1:
                    responseData.address == null || undefined
                      ? ' '
                      : responseData.address,
                  City:
                    responseData.city == null || undefined
                      ? ' '
                      : responseData.city,
                  State:
                    responseData.state == null || undefined
                      ? ' '
                      : responseData.state,
                  Country:
                    responseData.country == null || undefined
                      ? ' '
                      : responseData.country,
                  PostalCode:
                    responseData.postalCode == null || undefined
                      ? ' '
                      : responseData.postalCode,
                  faxNo: responseData.faxNo ?? '',
                  isExists: responseData.isExist,
                  mailingAddressDetails: this.getMailingAddressDetails(responseData),
                  primaryPracticeAddressDetails:
                    this.getPrimaryPracticeAddressDetails(responseData),
                  secondaryPracticeAddressDetails:
                    this.getSecondaryPracticeAddressDetails(responseData),
                });
              });
              this.doctorTableData = this.npiTableData;
              this.dataSource = new MatTableDataSource(this.npiTableData);
              this.dataSource.sort = this.sort;
              this.dataSource.paginator = this.paginator;
              // this.Table.setNpiDoctorTable(this.npiTableData);
            } else {
              Swal.fire({
                icon: 'info',
                text: 'Sorry!! No records found',
              });
              this.isLoading = false;
              this.doctorTableData = this.npiTableData;
              this.dataSource = new MatTableDataSource(this.npiTableData);
              this.dataSource.sort = this.sort;
              this.dataSource.paginator = this.paginator;
            }
          },
          (err) => {
            //
            const data: HttpErrorResponse = err;
            Swal.fire({
              icon: 'info',
              text: data?.error?.error?.message,
            });
            this.doctorTableData = this.npiTableData;
            this.dataSource = new MatTableDataSource(this.npiTableData);
            this.dataSource.sort = this.sort;
            this.dataSource.paginator = this.paginator;
            this.isLoading = false;
          }
        );
      this.subscription$.push(searchNpi);
      // this.isLoading = false;
    }
    //this.isLoading = false;
  }


getMailingAddressDetails(responseData: any): string {
  if(responseData.isMailingAddressPresent) {
    const addressParts = [
      responseData.address,
      responseData.state,
      responseData.city,
      responseData.postalCode,
      responseData.phoneNo,
      responseData.faxNo
    ];

    // Filter out null or undefined values and join with a comma
    return addressParts.filter((part) => part).join(', ');
  }
}


  getPrimaryPracticeAddressDetails(responseData: any): string {
    if (responseData.isPrimaryPracticeAddressPresent) {
      const addressParts = [
        responseData.primaryPracticeAddress,
        responseData.primaryPracticeState,
        responseData.primaryPracticeCity,
        responseData.primaryPracticePostalCode,
        responseData.primaryPracticePhoneNo,
        responseData.primaryPracticeFaxNo,
      ];

      // Filter out null or undefined values and join with a comma
      return addressParts.filter((part) => part).join(', ');
    }
    return '';
  }

  getSecondaryPracticeAddressDetails(responseData: any): string {
    if (responseData.isSecondaryPracticeAddressPresent) {
      const addressParts = [
        responseData.secondaryPracticeAddress,
        responseData.secondaryPracticeState,
        responseData.secondaryPracticeCity,
        responseData.secondaryPracticePostalCode,
        responseData.secondaryPracticePhoneNo,
        responseData.secondaryPracticeFaxNo,
      ];

      // Filter out null or undefined values and join with a comma
      return addressParts.filter((part) => part).join(', ');
    }
    return '';
  }

  //only number and alpha
  numberAndAlphaOnly(event): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    var inp = String.fromCharCode(event.keyCode);
    if (
      charCode > 31 &&
      (charCode < 48 || charCode > 57) &&
      charCode >= 15 &&
      charCode <= 64
    ) {
      return false;
    }
    return true;
  }

  doctorId: string = '';
  AddNpiDoctor(data) {
    // this.organizationUnitId = localStorage.getItem('initialOrganizationUnitId');

    Swal.fire({
      title: 'Are you sure you want to save?',
      text: 'Would You Like To Save the NPI Doctor Details',
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#34c38f',
      cancelButtonColor: '#f46a6a',
      confirmButtonText: 'Yes, Save!',
    }).then((result) => {
      if (result.value) {
        let npiDoctorDatas = data;
        if (npiDoctorDatas.MiddleName == null) {
          npiDoctorDatas.MiddleName = '';
        }
        npiDoctorDatas.MiddleName = npiDoctorDatas?.MiddleName?.replace(
          '.',
          ''
        );
        npiDoctorDatas.FirstName = npiDoctorDatas?.FirstName?.replace('.', '');
        npiDoctorDatas.LastName = npiDoctorDatas?.LastName?.replace('.', '');
        let npiDoctorNumber: string = npiDoctorDatas.TelephoneNumber;
        npiDoctorNumber = npiDoctorNumber.replace('#', '');

        let responeMobile = npiDoctorDatas.TelephoneNumber.replace('-', '');
        responeMobile = responeMobile
          .replace('(', '')
          .replace(')', '')
          .replace('#', '')
          .replace('-', '')
          .split(' ')
          .join('');
        let responeFaxno = npiDoctorDatas.FaxNumber.replace('-', '');
        responeFaxno = responeFaxno
          .replace('(', '')
          .replace(')', '')
          .replace('#', '')
          .replace('-', '')
          .split(' ')
          .join('');
        let responePhone = npiDoctorDatas.TelephoneNumber.replace('-', '');
        responePhone = responePhone
          .replace('(', '')
          .replace(')', '')
          .replace('#', '')
          .replace('-', '')
          .split(' ')
          .join('');
        let title: string = npiDoctorDatas.Prefix;

        let doctor: DoctorDTO = {
          id: this.doctorId,
          title: title?.trim(),
          lastName: npiDoctorDatas.LastName,
          firstName: npiDoctorDatas.FirstName,
          middleName: npiDoctorDatas.MiddleName,
          suffix: '',
          doctorGroup: '',
          facilityId: '',
          npiNumber: npiDoctorDatas.NpiNumber,
          marketingRep: '',
          address: npiDoctorDatas.Address1,
          city: npiDoctorDatas.City,
          state: npiDoctorDatas.State,
          country: npiDoctorDatas.Country,
          postalCode: npiDoctorDatas.PostalCode,
          isPCOS: 0,
          phoneNo: responePhone,
          faxNo: responeFaxno,
          mobileNo: responeMobile,
          emailId: '',
          faxAttention: '',
          medicalLicenseNo: npiDoctorDatas.MedicalLicenseNo,
          expiration: '',
          deaNumber: '',
          upin: '',
          stateMedicalId: '',
          doctorNotes: '',
          userDeliveredUser1: '',
          userDeliveredUser2: '',
          excludeFromElectronicFax: 0,
          taxonomyCode: '',
          organizationUnitId: null,
          isMedicare: this.isMedicare,
        };
        this.doctorService.create(doctor).subscribe(
          (response) => {
            if (response) {
              // Swal.fire({ title: 'Success', html: 'Saved Successfully', icon: 'success', timer: 3000, timerProgressBar: true });
              this.toastr.success('Saved Successfully', 'Success');
              this.Table.setDoctorTable(response);
              this.dialog.close();
              this.SearchNpiDoctorsAbp();
            } else {
            }
          },
          (err) => {
            const data: HttpErrorResponse = err;
            Swal.fire({
              icon: 'info',
              text: data?.error?.error?.message,
            });
          }
        );
      }
    });
  }

  reloadTable() {
    this.isLoading = true;
    setTimeout(() => {
      this.isLoading = false;
    }, 100);
  }
}
