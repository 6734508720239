import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DataTableDirective } from 'angular-datatables';
import { defaultGuid } from 'projects/shared/src/app/enums/allenums.enum';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { PatientCommonNotesComponent } from 'projects/shared/src/app/components/patient-common-notes/patient-common-notes.component';
import { AddNoteTabComponent } from 'projects/patient/src/app/add-note-tab/add-note-tab.component';
import { ViewPatientLedgerComponent } from '../view-patient-ledger/view-patient-ledger.component';
import { DocumentSortingComponent } from '../document-sorting/document-sorting.component';
import { HttpErrorResponse } from '@angular/common/http';
import Swal from 'sweetalert2';
import { Subscription } from 'rxjs';
import { PatientPersonalService } from 'projects/patient/src/app/patient-proxy/patient-optimization/patient-personal.service';
import { ComplianceStatusService } from 'projects/order/src/app/order-proxy/order-management/order-optimization/compliance-status.service';
import { PagedAndSortedResultRequestDto } from '@abp/ng.core';
import { MmOrderComplianceService } from 'projects/order/src/app/order-proxy/order-management/order-optimization/mm-order-compliance.service';
import { ToastrService } from 'ngx-toastr';
import { CommunicationService } from 'projects/shared/src/app/services/communication.service';
import { ComlplainceFilter } from 'projects/patient/src/app/patient-proxy/patient/enumeration-data/comlplaince-filter.enum';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { error } from 'console';
import { DatePipe } from '@angular/common';
@Component({
  selector: 'app-compliance-report-list',
  templateUrl: './compliance-report-list.component.html',
  styleUrls: ['./compliance-report-list.component.scss']
})
export class ComplianceReportListComponent implements OnInit {

  ComplaincesTypes = ComlplainceFilter;
  activeTabID: ComlplainceFilter;
  otherInfoForm: FormGroup;
  complianceForm: FormGroup;
  callLogTableData: any[] = [];
  @Input() DataList: any[];
  @Input() activeTab: number;
  // activeTabID:string;
  tableData$: Subscription;
  subscription$: Subscription[] = [];
  $subscription: Subscription[] = [];
  defaultGuid = defaultGuid;

  dtComplianceDownloadOptions: any = {
    responsive: true,
    // stateSave: true,
    paging: false,
    dom: 'Bfrtip',
    language: {
      emptyTable: 'No records',
      info: 'Total : _MAX_ records',
      infoEmpty: 'Total : _MAX_ records',
      infoFiltered: '(filtered : _TOTAL_ records)',
      loadingRecords: 'Loading...',
      zeroRecords: 'No matching records',
    },
    scrollCollapse: true,
    scrollY: '300px',
    scrollX: true,
    fixedHeader: true, // Fix the header
    fixedColumns: {
      leftColumns: 3, // Number of columns to fix on the left
      rightColumns: 1, // Number of columns to fix on the right
    },
    columnDefs: [
      { targets: [0], orderable: false },
      { targets: [14,15], visible: false },
      { targets: [0, 1, 2, -1], className: 'dt-fixed-column' }, // Add class to make the first three and last column fixed
      {
        targets: [1, 11],
        type: 'date',
        render: function (data, type, row) {
          // Assuming data is in the format MM/DD/YYYY
          if (type === 'sort' || type === 'type') {
            var dateParts = data.split('/');
            return new Date(dateParts[2], dateParts[0] - 1, dateParts[1]);
          } else {
            return data;
          }
        },
      },
    ],
    search: {
      smart: false,
    },
    buttons: [
      {
        extend: 'excel',
        text: 'Export',
        filename: 'Compliance',
        exportOptions: {
          columns: ':visible:not(:first-child) ',
        },
      },
      {
        extend: 'colvis',
        columns: ':not(:eq(0), :eq(1), :eq(2), :eq(-1))', // Exclude first three and last columns from the column visibility toggle
      },
      {
        text: 'Show Default Column',
        action: function (e, dt, node, config) {
          dt.columns(':not(:lt(3),:last-child)').visible(false);
        },
      },
      {
        text: 'Show All Column',
        action: function (e, dt, node, config) {
          dt.columns().visible(true);
        },
      },
    ],
  };
  patientId: string;
  documentId: string;
  chartId: string
  isLoading: boolean = false;
  isCallLoading: boolean = false;
  myDataTable: any;
  reportList: any;
  @ViewChild(DataTableDirective, { static: false })
  datatableElement: DataTableDirective;
  myTable: DataTables.Api;
  constructor(
    private snackBar: MatSnackBar,
    private dialog: MatDialog,
    private complianceStatusService: ComplianceStatusService,
    private mmOrderComplianceService: MmOrderComplianceService,
    private toastr: ToastrService,
    private communicationService: CommunicationService,
    private fb: FormBuilder,
    private patientPersonalService: PatientPersonalService,
    private datepipe: DatePipe,
  ) { }

  ngOnInit() {
    this.dtComplianceDownloadOptions.buttons[0].filename =
    'Compliance List' + '-' + this.datepipe.transform(new Date(), 'MM-dd-yyyy');

    this.initializeForms();
    this.myDataTable = {
      responsive: true,
      paging: false,
      scrollCollapse: true,
      scrollY: '300px',
      scrollX: true,
      info: false,
      dom: 'Bfrtip',
      buttons: [
        {
          extend: 'colvis',
          columns: [10]
        }
      ]
    };
    this.loadReportList();
    this.activeTabID = this.activeTab;
  }
  //After View Method
  ngAfterViewInit(): void {
    this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.columns().every(function () {
        const that = this;
        $('input', this.footer()).on('keyup change', function () {
          if (that.search() !== this['value']) {
            that.search(this['value']).draw();
          }
        });
      });
    });
  }

  isEmpty(value: any): boolean {
    return (
      value === null ||
      value === undefined ||
      value === '' ||
      value === defaultGuid
    );
  }

  onRowDoubleClick(textToCopy) {
    this.copyTextToClipboard(textToCopy);
    this.showNotification('Text copied: ' + textToCopy);
  }

  showNotification(message: string): void {
    this.snackBar.open(message, 'Close', {
      duration: 3000, // Duration in milliseconds
      horizontalPosition: 'end', // Positioning the notification horizontally
      verticalPosition: 'bottom', // Positioning the notification vertically
    });
  }

  copyTextToClipboard(text: string): void {
    const textArea = document.createElement('textarea');
    textArea.value = text;
    document.body.appendChild(textArea);
    textArea.select();
    document.execCommand('Copy');
    textArea.remove();
  }

  openViewNotesPopup() {
    const config: MatDialogConfig = {
      disableClose: true,
      width: '70vw',
      maxWidth: '70vw',
      height: '40vw',
      maxHeight: '40vw',
      data: {
        patientId: this.patientId,
        pageType: 'Patient Note',
        isAddVisible: false,
      },
    };
    const dialogRef = this.dialog.open(PatientCommonNotesComponent, config);

    dialogRef.afterClosed().subscribe(
      () => {
        //this.getReminderTableData();
      },
      (err: HttpErrorResponse) => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      }
    );
  }
  openAddNotesPopUp() {
    const config: MatDialogConfig = {
      disableClose: true,
      minHeight: '30vh',
      minWidth: '50vw',
      data: {
        noteType: 'patient',
        notesId: '00000000-0000-0000-0000-000000000000',
        patientId: this.patientId,
        defaultFaxId: this.chartId,
        isCallNote: false,
        isPending: false,
        docId: this.documentId,
        inboundStatusShortCodeId: defaultGuid,
        selectedNode: defaultGuid,
        addnotes: true,
      },
    };
    const dialogRef = this.dialog.open(AddNoteTabComponent, config);

    const closeDialog = dialogRef.afterClosed().subscribe(
      () => {
        // this.getReminderTableData();
      },
      (err) => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      }
    );
    this.subscription$.push(closeDialog);
  }

  viewPatientLedger(patientId: string) {
    this.dialog.open(ViewPatientLedgerComponent, {
      width: '100vw',
      height: '100vh',
      maxWidth: '100vw',
      maxHeight: '100vh',
      panelClass: 'fullscreen-dialog-panel',
      disableClose: true,
      data: {
        patientId: patientId,
      },
    });
    this.dialog.afterAllClosed.subscribe((resposne) => { });
  }

  viewDocument(
    patientId: string,
    documentId: string,
    defaultDocumentId: string,
    isSorted: boolean
  ) {
    const dialogRef = this.dialog.open(DocumentSortingComponent, {
      width: '100vw',
      height: '100vh',
      maxWidth: '100vw',
      maxHeight: '100vh',
      panelClass: 'fullscreen-dialog-panel',
      disableClose: true,
      data: {
        // Add any data you want to pass to the dialog here
        // For example, you can pass patientId and documentId
        patientId: patientId,
        documentId: documentId,
        defaultDocumentId: defaultDocumentId,
        isSorted: isSorted,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      // Handle the dialog close event here if needed

    });
  }

  loadReportList() {
    const complaincereportList = this.complianceStatusService.getComplianceStatusForComplaince()
      .subscribe((response) => {
        this.reportList = response['items'];
      });
    this.$subscription.push(complaincereportList);
  }

  reportMoveTo(id: string, statusId: string,complaincename:string) {
    this.mmOrderComplianceService.updateComplainceStatusByIdAndStatusId(id, statusId).subscribe(
      (response) => {
        this.toastr.success('Successfully Moved to ' + complaincename, 'Success');
        this.communicationService.triggerFunctionCallComplainceList(this.activeTabID);
      },
      (err) => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      }
    );

  }

  tblItemSelectedRow(patientId: string, documentId: string, chartId: string) {
    this.patientId = patientId;
    this.documentId = documentId;
    this.chartId = chartId;
    this.getCallDetailsListTableData(this.patientId);
    this.getOtherInfo(this.patientId);
  }

  initializeForms() {
    this.otherInfoForm = this.fb.group({
      txtPhone: new FormControl(''),
      txtCellPhone: new FormControl(''),
      txtReferralPractice: new FormControl(''),
      txtOrderingPhysician: new FormControl(''),
    });
  }

      //GET Complaince Call Details Data
      getCallDetailsListTableData(patientID:string): void {
        this.tableData$?.unsubscribe();
        this.isCallLoading = true;
        const getPracticeListTableData = this.patientPersonalService.getPatientWiseCallLogByPatientId(patientID).subscribe(
          (response) => {
            this.isCallLoading = false;
            this.callLogTableData = response;
          },
          (error) => {
            const data: HttpErrorResponse = error;
            // Swal.fire({
            //   icon: 'info',
            //   text: data?.error?.error?.message,
            // });
            this.isCallLoading = false;
            this.callLogTableData = [];
          }
        );
        this.tableData$ = getPracticeListTableData;
      }

         //GET Other Info Details Data
      getOtherInfo(patientID:string) {
        const complaincereportList = this.patientPersonalService.getPatientWiseProviderDetailsByPatientId(patientID)
          .subscribe((response) => {
            this.otherInfoForm = this.fb.group({
              txtPhone: [response[0]?.phone],
              txtCellPhone: [response[0]?.cellPhone],
              txtReferralPractice: [response[0]?.referalPractice],
              txtOrderingPhysician: [response[0]?.orderingPhysician],
            });
          },
          (error)=>{
            const data: HttpErrorResponse = error;

          }
          );
        this.$subscription.push(complaincereportList);
      }
}
