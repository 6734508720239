import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Title } from '@angular/platform-browser';
import { defaultGuid } from 'projects/shared/src/app/enums/allenums.enum';

@Component({
  selector: 'app-patient-claims-tab',
  templateUrl: './patient-claims-tab.component.html',
  styleUrls: ['./patient-claims-tab.component.scss'],
})
export class PatientClaimsTabComponent implements OnInit {
  patientForm: FormGroup;
  defaultGuid = defaultGuid;
  constructor(
    private fb: FormBuilder,
    private title: Title,
    private dialog: MatDialog
  ) {}

  ngOnInit() {
    this.title.setTitle('QSecure | Patient Claims');
    this.initializeForms();
  }
  initializeForms() {
    this.patientForm = this.fb.group({
      txtPatientName: new FormControl(''),
      txtChartId: new FormControl(''),
      txtPostalCode: new FormControl(''),
      txtSSN: new FormControl(''),
      txtBirthdate: new FormControl(''),
      txtPlan: new FormControl(''),
      txtPhoneNo: new FormControl(''),
      txtPolicyNo: new FormControl(''),
      txtGuarantor: new FormControl(''),
      txtEmployer: new FormControl(''),
      txtRelation: new FormControl(''),
      txtPatientBalance: new FormControl(''),
      txtInsuranceBalance: new FormControl(''),
      txtTotalBalance: new FormControl(''),
      txtPlansOnFile: new FormControl(''),
      txtComments: new FormControl(''),
      chkShowInt: new FormControl(''),
      chkShowPaid: new FormControl(''),
    });
  }

}


