import { mapEnumToOptions } from '@abp/ng.core';

export enum ExportType {
  PatientSearchReports = 'PatientSearchReports',
  VerificationReports = 'VerificationReports',
  AuthorizationReports = 'AuthorizationReports',
  OrderReports = 'OrderReports',
  ShippingApprovalReports = 'ShippingApprovalReports',
  BillingReports = 'BillingReports',
  ClaimProcessReports = 'ClaimProcessReports',
  CheckEftReports = 'CheckEftReports',
  ClaimHistoryReports = 'ClaimHistoryReports',
  ProductBrandReports = 'ProductBrandReports',
  ProductCategoryReports = 'ProductCategoryReports',
  VendorReports = 'VendorReports',
  MainProductReports = 'MainProductReports',
  ResupplyProductReports = 'ResupplyProductReports',
  AllowablesReports = 'AllowablesReports',
  PurchaseOrderReports = 'PurchaseOrderReports',
  ProductStockReports = 'ProductStockReports',
  ProductSerialNoReports = 'ProductSerialNoReports',
  shippingTicketList = 'shippingTicketList',
  shippingReturnTicketList = 'shippingReturnTicketList',
  shippingReportList = 'shippingReportList',
  shippingApprovedList = 'shippingApprovedList',
  readyToPackList = 'readyToPackList',
  readyToDeliveryList = 'readyToDeliveryList',
  shippingReturnApprovedList = 'shippingReturnApprovedList',
  warehouseExecutiveReports = 'warehouseExecutiveReports',
}


export const exportTypeOptions = mapEnumToOptions(ExportType);
