import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { DataTableDirective } from 'angular-datatables';
import { MatDialog, MAT_DIALOG_DATA, MatDialogConfig } from '@angular/material/dialog';
import { HttpErrorResponse } from '@angular/common/http';
import Swal from 'sweetalert2';
import { PatientListModelComponent } from '../patient-list-model/patient-list-model.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { defaultGuid } from 'projects/shared/src/app/enums/allenums.enum';
import { PracticeManagementViewPatientListComponent } from '../practice-management-view-patient-list/practice-management-view-patient-list.component';
import { DatePipe } from '@angular/common';
import { title } from 'process';
@Component({
  selector: 'app-patientdetails-practice-management',
  templateUrl: './patientdetails-practice-management.component.html',
  styleUrls: ['./patientdetails-practice-management.component.scss']
})
export class PatientdetailsPracticeManagementComponent implements OnInit {

  patientTableData: any;
  @Input() tableData: any;
  @Input() selectedMonth: number;
  @Input() selectedYear: number;
  selectedYearv1:number
  selectedMonthv1:number

  @ViewChild(DataTableDirective, { static: false })
  datatableElement: DataTableDirective;
  // patientTabledetails: any;
  $subscription: any;

  patientTabledetails:any = {
    responsive: true,
    paging: false,
    dom: 'Bfrtip',
    language: {
      emptyTable: 'No records',
      info: 'Total : _MAX_ records',
      infoEmpty: 'Total : _MAX_ records',
      infoFiltered: '(filtered : _TOTAL_ records)',
      loadingRecords: 'Loading...',
      zeroRecords: 'No matching records',
    },
    scrollCollapse: true,
    scrollY: '450px',
    scrollX: true,
    fixedHeader: true,
    columnDefs: [
      { targets: [0, 1], orderable: false },
      {
        targets: [3],
        type: 'date',
        render: function (data, type, row) {
          // Assuming data is in the format MM/DD/YYYY
          if (type === 'sort' || type === 'type') {
            var dateParts = data.split('/');
            return new Date(dateParts[2], dateParts[0] - 1, dateParts[1]);
          } else {
            return data;
          }
        },
      },
    ],
    search: {
      smart: false,
    },
    buttons: [
      {
        extend: 'excel',
        text:'Export',
        filename: 'Practice Report Patient Details',
        title:'Practice Report - Patient Details',
        exportOptions: {
          columns: ':visible:not(:nth-child(1))',
        },
      },
      {
        extend: 'colvis',
        columns: ':not(:eq(0), :eq(-1))',
      }
    ],
  };

  constructor(private dialog: MatDialog, private snackBar: MatSnackBar,private datepipe:DatePipe) { }

  ngOnInit(): void {
    this.patientTableData = this.tableData;
    this.selectedMonthv1=this.selectedMonth;
    this.selectedYearv1=this.selectedYear;

    this.patientTabledetails.buttons[0].filename =
    'Practice Report - Patient Details' + '-' + this.datepipe.transform(new Date(), 'MM-dd-yyyy');
  }

  ngAfterViewInit(): void {
    this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.columns().every(function () {
        const that = this;
        $('input', this.footer()).on('keyup change', function () {
          if (that.search() !== this['value']) {
            that.search(this['value']).draw();
          }
        });
      });
    });
  }

  // OpenPatienList() {
  //   const config: MatDialogConfig = {
  //     disableClose: true,
  //     minHeight: '30vh',
  //     minWidth: '52vw',
  //     data: {},
  //   };
  //   const dialogRef = this.dialog.open(PatientListModelComponent, config);

  //   const closeDialog = dialogRef.afterClosed().subscribe(
  //     () => { },
  //     (err) => {
  //       const data: HttpErrorResponse = err;
  //       Swal.fire({
  //         icon: 'info',
  //         text: data?.error?.error?.message,
  //       });
  //     }
  //   );
  //   this.$subscription.push(closeDialog);
  // }

  OpenPatienList(practice:string) {
    const config: MatDialogConfig = {
      disableClose: true,
      minHeight: '30vh',
      minWidth: '52vw',
      data: {  practiceId:practice,
        selectedMonthv2:this.selectedMonthv1,
        selectedYearv2:this.selectedYearv1,
      },
    };
    const dialogRef = this.dialog.open(PracticeManagementViewPatientListComponent, config);

    const closeDialog = dialogRef.afterClosed().subscribe(
      () => { },
      (err) => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      }
    );
    this.$subscription.push(closeDialog);
  }

  onRowDoubleClickV1(textToCopy) {
    this.copyTextToClipboard(textToCopy);
    this.showNotification('Text copied: ' + textToCopy);
  }

  showNotification(message: string): void {
    this.snackBar.open(message, 'Close', {
      duration: 3000, // Duration in milliseconds
      horizontalPosition: 'end', // Positioning the notification horizontally
      verticalPosition: 'bottom', // Positioning the notification vertically
    });
  }

  copyTextToClipboard(text: string): void {
    const textArea = document.createElement('textarea');
    textArea.value = text;
    document.body.appendChild(textArea);
    textArea.select();
    document.execCommand('Copy');
    textArea.remove();
  }
  isEmpty(value: any): boolean {
    return (
      value === null ||
      value === undefined ||
      value === '' ||
      value === defaultGuid
    );
  }
}
