import { VerificationStatus, VerificationStatusV1 } from './../patient-proxy/patient/enumeration-data/verification-status.enum';
import { Component, EventEmitter, Inject, Input, OnInit, Output, ViewChild } from '@angular/core';
import { PatientVerificationDTO } from '../patient-proxy/patient-optimization/dto';
import { PatientManualVerificationTabComponent } from '../patient-manual-verification-tab/patient-manual-verification-tab.component';
import { defaultGuid } from '../medicare-check-list/medicare-check-list.component';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { PatientVerificationService } from '../patient-proxy/patient-optimization';
import { SharedTableService } from 'projects/shared/src/app/shared-table/shared-table.service';
import { Subscription } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import Swal from 'sweetalert2';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ToastrService } from 'ngx-toastr';
import { DataTableDirective } from 'angular-datatables';
import { InsuranceType } from '../patient-proxy/patient/enumeration-data/insurance-type.enum';
import { InsuranceTypeV1 } from 'projects/billing/src/app/claim-proxy/claim-processing-management/rcm/billing-management/billing/insurance-type.enum';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-patient-eligibility-history-list',
  templateUrl: './patient-eligibility-history-list.component.html',
  styleUrls: ['./patient-eligibility-history-list.component.scss']
})
export class PatientEligibilityHistoryListComponent implements OnInit {
  @Input() DataList:any;
  @Output() reloadTableEvent = new EventEmitter<string>();
  @ViewChild(DataTableDirective, { static: false })
  datatableElement: DataTableDirective;
  myTable: DataTables.Api;
  isLoading:boolean=false;
  lstVerificationSttaus=VerificationStatus;
  lstVerificationSttausV1=VerificationStatusV1;
  subscription$: Subscription[] = [];
  verifyTableData: PatientVerificationDTO[] = [];
  patientId: string = defaultGuid;
  policyId: string = defaultGuid;
  InsuranceType=InsuranceType;
  InsuranceTypeV1=InsuranceTypeV1;
  dtEligibilityHistory: any = {
    responsive: true,
    stateSave: true,
    paging: false,
    dom: 'Bfrtip',
    language: {
      emptyTable: 'No records',
      info: 'Total : _MAX_ records',
      infoEmpty: 'Total : _MAX_ records',
      infoFiltered: '(filtered : _TOTAL_ records)',
      loadingRecords: 'Loading...',
      zeroRecords: 'No matching records',
    },
    scrollCollapse: true,
    scrollY: '500px',
    scrollX: true,
    fixedHeader: true, // Fix the header
    // fixedColumns: {
    //   leftColumns: 3, // Number of columns to fix on the left
    //   rightColumns: 1, // Number of columns to fix on the right
    // },
    columnDefs: [
      { targets: [0, 1, -1], orderable: false },
      { targets: [0, 1, 2, -1], className: 'dt-fixed-column' },
      {
        targets: [8],
        type: 'date',
        render: function (data, type, row) {
          // Assuming data is in the format MM/DD/YYYY
          if (type === 'sort' || type === 'type') {
            var dateParts = data.split('/');
            return new Date(dateParts[2], dateParts[0] - 1, dateParts[1]);
          } else {
            return data;
          }
        },
      },
    ],
    search: {
      smart: false, // Set to "none" for full exact match
    },
    buttons: [
      {
        extend: 'excel',
        text: 'Export',
        filename: 'Master Medicare Checklist - Fax Process',
        exportOptions: {
          columns: ':visible:not(:nth-child(1),:nth-child(2))',
        },
      },
      {
        extend: 'colvis',
        columns: ':not(:eq(0), :eq(2), :eq(-1))', // Exclude first three and last columns from the column visibility toggle
      },
      // {
      //   text: 'Show Default Column',
      //   action: function (e, dt, node, config) {
      //     dt.columns(':not(:lt(3),:last-child)').visible(false);
      //   },
      // },
      // {
      //   text: 'Show All Column',
      //   action: function (e, dt, node, config) {
      //     dt.columns().visible(true);
      //   },
      // },
    ],
  };
  constructor(
    public dialog: MatDialog,
    private patientVerificationService: PatientVerificationService,
    @Inject(MAT_DIALOG_DATA) public data: { patientId: string ,policyId: string,verificationStatus:string}
    = { patientId: defaultGuid,policyId: defaultGuid,verificationStatus:null },
    private toastr: ToastrService,
    private snackBar: MatSnackBar,
    private datepipe: DatePipe
  ) { }

  ngOnInit() {
    this.dtEligibilityHistory.buttons[0].filename =
    'Eligibility Histroy - Qsecure' + '-' + this.datepipe.transform(new Date(), 'MM-dd-yyyy');

    this.patientId = this.data?.patientId ?? defaultGuid;
    this.policyId = this.data?.policyId ?? defaultGuid;
    // this.patientId !== defaultGuid && this.getTableDataBasedOnPatientId(this.patientId);
  }
   //After View Method
   ngAfterViewInit(): void {
    if (this.datatableElement && this.datatableElement.dtInstance) {
      this.refreshDataTable();
    } else {
      // If dtInstance is not available yet, wait for a short time and try again
      setTimeout(() => this.refreshDataTable(), 500);
    }
    window.removeEventListener('resize', this.calculateScrollY);
  }
  calculateScrollY = () => {
    const screenHeight =
      window.innerHeight ||
      document.documentElement.clientHeight ||
      document.body.clientHeight;
    const availableSpacePercentage = 0.5; // Adjust the percentage as needed
    this.dtEligibilityHistory.scrollY =
      screenHeight * availableSpacePercentage;
  };
  refreshDataTable(): void {
    this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
      // Clear search input values
      $('input', dtInstance.table(0).footer()).val('');
      // Redraw the DataTable to load all records
      dtInstance.search('').columns().search('').draw();
      // Attach the event listener for filtering
      dtInstance.columns().every(function () {
        const that = this;
        $('input', this.footer()).on('keyup change', function () {
          if (that.search() !== this['value']) {
            that.search(this['value']).draw();
          }
        });
      });
    });
  }
  // getTableDataBasedOnPatientId(patientId: string) {

  //   this.isLoading = true;

  //   this.verifyTableData = [];
  //   // this.sharedTableService.setTableData(this.verifyTableData);
  //   const getVerificationDetails = this.patientVerificationService.getVerificationByPatientIdpolicyIdByPatientIdAndPolicyId(patientId,this.policyId).subscribe(response => {
  //     this.isLoading = false;
  //     this.verifyTableData = response;
  //     // this.sharedTableService.setTableData(this.verifyTableData);
  //   }, (err) => {
  //     // this.sharedTableService.setTableData(this.verifyTableData);
  //     this.isLoading = false;
  //     const data: HttpErrorResponse = err;
  //     Swal.fire({
  //       icon: 'info',
  //       text: data?.error?.error?.message,
  //     });
  //   });
  //   this.subscription$.push(getVerificationDetails);
  // }
  viewVerificationById(verification: PatientVerificationDTO): void {

    const dialogRef = this.dialog.open(PatientManualVerificationTabComponent, {
      data: { patientId: this.patientId, verificationId: verification?.id, policyId: verification?.policyId,savehidden:false ,verificationStatus:this.data.verificationStatus}
    });
    const closeDialog = dialogRef.afterClosed().subscribe((result) => {
      // this.getTableDataBasedOnPatientId(this.patientId);
      this.reloadTableEvent.emit(this.patientId);
      dialogRef?.close(result);
    });
    this.subscription$.push(closeDialog);
  }
  // deleteVerificationById(verificationId: any): void {
  //   this.isLoading = true;
  //   this.patientVerificationService.get(verificationId).subscribe(resposne => {
  //     // this.patientId !== defaultGuid && this.getTableDataBasedOnPatientId(this.patientId);
  //   }, (err) => {
  //     // this.patientId !== defaultGuid && this.getTableDataBasedOnPatientId(this.patientId);
  //     this.isLoading = false;
  //     const data: HttpErrorResponse = err;
  //     Swal.fire({
  //       icon: 'info',
  //       text: data?.error?.error?.message,
  //     });
  //   });
  // }
  onRowDoubleClick(textToCopy) {
    this.copyTextToClipboard(textToCopy);
    this.showNotification('Text copied: ' + textToCopy);
  }

  showNotification(message: string): void {
    this.snackBar.open(message, 'Close', {
      duration: 3000, // Duration in milliseconds
      horizontalPosition: 'end', // Positioning the notification horizontally
      verticalPosition: 'bottom', // Positioning the notification vertically
    });
  }

  copyTextToClipboard(text: string): void {
    const textArea = document.createElement('textarea');
    textArea.value = text;
    document.body.appendChild(textArea);
    textArea.select();
    document.execCommand('Copy');
    textArea.remove();
  }
  getFormattedDate(date: string): string {
    return date ? new Date(date).toLocaleDateString('en-US') : '-';
  }
}
