import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { constrainPoint } from '@fullcalendar/angular';
import { GetDoctorNpiService } from 'projects/admin/src/app/admin-proxy/platform-app-management/rcm/patient-management/controllers/get-doctor-npi.service';
import { Status } from 'projects/inventory/src/app/item-proxy/item-management/items/status.enum';
import { DoctorDTO } from 'projects/order/src/app/order-proxy/patient/dto/models';
import { TableService } from 'projects/shared/src/app/table.service';
import { NumberValidators } from 'projects/shared/src/app/validations/number-validator';
import { TextValidators } from 'projects/shared/src/app/validations/text-validator';
import { Subscription } from 'rxjs';
import Swal from 'sweetalert2';
import { DoctorService } from '../patient-proxy/patient/doctor.service';
import { NPIDoctorDTO, NPIDoctorDTOs } from '../patient-proxy/patient/dto/models';
import { PracticeManagementService } from '../patient-proxy/patient/practice-management';
import { CreateUpdatePracticeManagementDTO, PracticeManagementDTO } from '../patient-proxy/patient/practice-management/dto/models';
import { ToastrService } from "ngx-toastr";

@Component({
  selector: 'app-practice-physicians',
  templateUrl: './practice-physicians.component.html',
  styleUrls: ['./practice-physicians.component.scss']
})
export class PracticePhysiciansComponent implements OnInit, OnDestroy {
  // declarations
  prtzManagForm: FormGroup;
  isLoading: boolean = false;
  strPageType: string = "npiDoctor";
  txtNpiNumber: string = "";
  txtFirstName: string = "";
  txtLastName: string = "";
  txtCity: string = "";
  txtState: string = "";
  txtCountry: string = "";
  txtPosatlCode: string = "";
  organizationUnitId: string = "";
  isShown: boolean = false;
  public npiTableData: any[] = [];
  displayedColumns: string[] = ['NPI Number', 'Doctor Name', 'Postal Code', 'Action'];
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild('MatPaginator', { static: false }) paginator: MatPaginator;
  dataSource = new MatTableDataSource(this.npiTableData);
  physicianId: string = "";
  doctorId: string = "";
  subscription$: Subscription[] = [];
  constructor(
    private formBuilder: FormBuilder,
    private number: NumberValidators,
    private text: TextValidators,
    private doctorService: DoctorService,
    private npiDoctorService: GetDoctorNpiService,
    private Table: TableService,
    public dialog: MatDialog,
    private toastr: ToastrService,
    private PracticeManagementService: PracticeManagementService,
    public dialogRef: MatDialogRef<PracticePhysiciansComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { doctorId: string, practiceId: string }) { }

  ngOnInit(): void {
    this.prtzManagForm = this.formBuilder.group({
      txtNpiNumber: new FormControl("", [this.number.isNumberCheck]),
      txtEnumerationType: new FormControl(""),
      txtTaxonomyDescription: new FormControl(""),
      txtFirstName: new FormControl("", [this.text.isTextCheck, Validators.minLength(2)]),
      // txtUserFirstNameAlias: new FormControl("", [this.text.isTextCheck]),
      txtLastName: new FormControl("", [this.text.isTextCheck]),
    });
  }

  ngOnDestroy(): void {
    this.subscription$?.forEach((sub) => {
      sub && sub?.unsubscribe();
    });
  }

  // SearchNpiDoctorsAbp
  SearchNpiDoctorsAbp() {
    this.isShown = true
    this.isLoading = true;
    if (this.prtzManagForm.value.txtNpiNumber == '0000000000') {
      this.isShown = false
      this.isLoading = false;
      // Swal.fire("Please Enter valid NPI Number");

      Swal.fire({
        icon: 'info',
        text: "Please Enter valid NPI Number",
      });
      //127-553-6336
    }
    else {
      let npiSearchDoctors: NPIDoctorDTO = {
        number: this.prtzManagForm.value.txtNpiNumber,
        enumerationType: "",
        taxonomyDescription: "",
        firstName: this.prtzManagForm.value.txtFirstName,
        useFirstNameAlias: "",
        //  useFirstNameAlias: this.prtzManagForm.value.txtUserFirstNameAlias,
        lastName: this.prtzManagForm.value.txtLastName,
        organizationName: "",
        addressPurpose: "",
        city: this.prtzManagForm.value.txtCity,
        state: "",
        postalCode: this.prtzManagForm.value.txtPosatlCode,
        countryCode: "",
      }
      const npiDoctorSearch = this.npiDoctorService.getNpiDoctorsByInput(npiSearchDoctors).subscribe((response: any) => {
        this.npiTableData = [];
        if (response.length != 0) {

          this.isLoading = false;

          let responseArray: NPIDoctorDTOs[] = response;
          this.npiTableData = [];
          responseArray.forEach(responseData => {
            this.npiTableData.push({
              sno: '',
              NpiNumber: responseData.npiNumber,
              FullName: (responseData.firstName == null || undefined ? "" : responseData.firstName) + " " + (responseData.lastName == null || undefined ? " " : responseData.lastName),
              FirstName: responseData.firstName == null || undefined ? "" : responseData.firstName,
              LastName: responseData.lastName == null || undefined ? " " : responseData.lastName,
              MiddleName: responseData.middleName == null || undefined ? " " : responseData.middleName,
              Prefix: responseData.title == null || undefined ? " " : responseData.title,
              TelephoneNumber: responseData.phoneNo == null || undefined ? " " : responseData.phoneNo,
              MedicalLicenseNo: responseData.medicalLicenseNo == null || undefined ? " " : responseData.medicalLicenseNo,
              FaxNumber: responseData.faxNo == null || undefined ? " " : responseData.faxNo,
              Address1: responseData.address == null || undefined ? " " : responseData.address,
              City: responseData.city == null || undefined ? " " : responseData.city,
              State: responseData.state == null || undefined ? " " : responseData.state,
              Country: responseData.country == null || undefined ? " " : responseData.country,
              PostalCode: responseData.postalCode == null || undefined ? " " : responseData.postalCode,
              isExists: responseData.isExist,
            })
          })
          this.dataSource = new MatTableDataSource(this.npiTableData);
          this.dataSource.sort = this.sort;
          this.dataSource.paginator = this.paginator;
          // this.Table.setNpiDoctorTable(this.npiTableData);
        }
        else {
          Swal.fire({
            icon: 'info',
            text: "Sorry!! No records found",
          });
          this.isLoading = false;
          this.dataSource = new MatTableDataSource(this.npiTableData);
          this.dataSource.sort = this.sort;
          this.dataSource.paginator = this.paginator;
        }
      }, err => {

        //
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
        this.dataSource = new MatTableDataSource(this.npiTableData);
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
        this.isLoading = false;
      });
      this.subscription$.push(npiDoctorSearch);
      // this.isLoading = false;
    }
    //this.isLoading = false;
  }
  //only number and alpha
  numberAndAlphaOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    var inp = String.fromCharCode(event.keyCode);
    if (charCode > 31 && (charCode < 48 || charCode > 57) && (charCode >= 15 && charCode <= 64)) {
      return false;
    }
    return true;

  }


  AddNpiDoctor(data) {
    // this.organizationUnitId = localStorage.getItem('initialOrganizationUnitId');

    Swal.fire({
      title: 'Are you sure you want to save?',
      text: 'Would You Like To Save the NPI Doctor Details',
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#34c38f',
      cancelButtonColor: '#f46a6a',
      confirmButtonText: 'Yes, Save!'
    }).then(result => {
      if (result.value) {
        let npiDoctorDatas = data;
        if (npiDoctorDatas.MiddleName == null) {
          npiDoctorDatas.MiddleName = ""
        }
        npiDoctorDatas.MiddleName = npiDoctorDatas?.MiddleName?.replace(".", "");
        npiDoctorDatas.FirstName = npiDoctorDatas?.FirstName?.replace(".", "");
        npiDoctorDatas.LastName = npiDoctorDatas?.LastName?.replace(".", "");
        let npiDoctorNumber: string = npiDoctorDatas.TelephoneNumber;
        npiDoctorNumber = npiDoctorNumber.replace("#", "");


        let responeMobile = npiDoctorDatas.TelephoneNumber.replace("-", "");
        responeMobile = responeMobile.replace("(", "").replace(")", "").replace("#", "").replace("-", "").split(" ").join("");
        let responeFaxno = npiDoctorDatas.FaxNumber.replace("-", "");
        responeFaxno = responeFaxno.replace("(", "").replace(")", "").replace("#", "").replace("-", "").split(" ").join("");
        let responePhone = npiDoctorDatas.TelephoneNumber.replace("-", "");
        responePhone = responePhone.replace("(", "").replace(")", "").replace("#", "").replace("-", "").split(" ").join("");
        let title: string = npiDoctorDatas.Prefix


        let doctor: DoctorDTO = {
          id: this.doctorId,
          title: title?.trim(),
          lastName: npiDoctorDatas.LastName,
          firstName: npiDoctorDatas.FirstName,
          middleName: npiDoctorDatas.MiddleName,
          suffix: "",
          doctorGroup: "",
          facility: "",
          npiNumber: npiDoctorDatas.NpiNumber,
          marketingRep: "",
          address: npiDoctorDatas.Address1 + '' + npiDoctorDatas.Address2,
          city: npiDoctorDatas.City,
          state: npiDoctorDatas.State,
          country: npiDoctorDatas.Country,
          postalCode: npiDoctorDatas.PostalCode,
          isPCOS: 0,
          phoneNo: responePhone,
          faxNo: responeFaxno,
          mobileNo: responeMobile,
          emailId: "",
          faxAttention: "",
          medicalLicenseNo: npiDoctorDatas.MedicalLicenseNo,
          expiration: "",
          deaNumber: "",
          upin: "",
          stateMedicalId: "",
          doctorNotes: "",
          userDeliveredUser1: "",
          userDeliveredUser2: "",
          excludeFromElectronicFax: 0,
          taxonomyCode: "",
          organizationUnitId: null
        };
        this.doctorService.create(doctor).subscribe(response => {
          if (response) {
            // Swal.fire({ title: 'Success', html: 'Saved Successfully', icon: 'success', timer: 3000, timerProgressBar: true });
            this.toastr.success('Saved Successfully','Success')
            this.Table.setDoctorTable(response);
            this.dialogRef.close();
            this.SearchNpiDoctorsAbp();
          }
          else {
          }
        }, err => {
          const data: HttpErrorResponse = err;
          Swal.fire({
            icon: 'info',
            text: data?.error?.error?.message,
          });
        });
      }
    });
  }

  ResetNpiDoctors() {
    this.prtzManagForm.reset({
      txtNpiNumber: "",
      txtFirstName: "",
      txtLastName: "",
      txtCity: "",
      txtState: "",
      txtCountry: "",
      txtPosatlCode: ""
    });
  }

  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }



  //saveNpi
  saveNpi(element) {
    this.PracticeManagementService.createPhysicianByIdAndSFirstNameAndSLastNameAndSNpi(this.data.practiceId, element?.FirstName, element?.LastName, element?.NpiNumber).subscribe(response => {
      // Swal.fire({ title: 'Success', html: 'Saved Successfully', icon: 'success', timer: 3000, timerProgressBar: true }).then(result => {
        this.toastr.success('Saved Successfully','Success')
        this.dialogRef.close();

      // });
    }, err => {

      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    });
  }

}


