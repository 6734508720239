<!-- Document-Follow-Up-Reports -->
<div class="container-fluid">
  <div class="row">
    <div class="col-12">
      <div class="d-flex align-items-center justify-content-between mb-1">
        <h3 class="mb-3 mt-2 font-size-18 customThemeClass"><b>MIS Report / Document Follow-Up Report</b></h3>
        <div class="page-title-box page-title-right">
          <ol class="breadcrumbs m-0">
            <!-- <li>
              <button mat-button class="mr-2 buttonColor" (click)="exportTableData()">
                Export
              </button>
            </li> -->
            <button
              class="mr-2 buttonColor"
              mat-raised-button
              [disabled]="(dataSource && dataSource?.data && dataSource?.data?.length === 0)"
              (click)="
                exporter.exportTable('xlsx', {
                  fileName: 'Document Followup List',
                  sheet: 'DocumentList',
                  Props: { Author: 'QSecure' }
                })
              "
            >
              Export
            </button>
            <!-- <li class="breadcrumb-item mt-2 pl-2">Report Page</li>
            <li class="breadcrumb-item mt-2">Follow-Up Report</li> -->
          </ol>
        </div>
      </div>
    </div>
    <div class="col-lg-12">
      <form [formGroup]="docReportForm">
        <div class="example-container mat-elevation-z4">
          <table
            mat-table
            [dataSource]="dataSource"
            matTableExporter
            #exporter="matTableExporter"
            matSort
            class="mat-elevation-z8"
          >
            <ng-container matColumnDef="fileName">
              <th
                class="header"
                mat-header-cell
                mat-sort-header
                *matHeaderCellDef
              >
                <mat-form-field class="filter">
                  <input autocomplete="new-fileName" matInput formControlName="fileName" />
                  <mat-placeholder class="center">Doument Name</mat-placeholder>
                </mat-form-field>
              </th>
              <td mat-cell *matCellDef="let element">
                <a
                  *ngIf="element?.blobName"
                  [routerLink]="[
                    '../ViewDocumentPerviewByBlobId/' + element?.blobName + '/1'
                  ]"
                  target="_blank"
                  matTooltip="Click to View Document"
                  class="eyeCursorclass"
                >
                  {{ element?.fileName }}
                </a>
              </td>
            </ng-container>
            <ng-container matColumnDef="patientName">
              <th
                class="header"
                mat-header-cell
                mat-sort-header
                *matHeaderCellDef
              >
                <mat-form-field class="filter">
                  <input autocomplete="new-patientName" matInput formControlName="patientName" />
                  <mat-placeholder class="center">Patient Name</mat-placeholder>
                </mat-form-field>
              </th>
              <td mat-cell *matCellDef="let element">
                {{ element.patientName }}
              </td>
            </ng-container>
            <ng-container matColumnDef="doctor">
              <th
                class="header"
                mat-header-cell
                mat-sort-header
                *matHeaderCellDef
              >
                <mat-form-field class="filter">
                  <input autocomplete="new-doctor" matInput formControlName="doctor" />
                  <mat-placeholder class="center">Doctor</mat-placeholder>
                </mat-form-field>
              </th>
              <td mat-cell *matCellDef="let element">
                {{ element.doctor }}
              </td>
            </ng-container>
            <ng-container matColumnDef="documentType">
              <th
                class="header"
                mat-header-cell
                mat-sort-header
                *matHeaderCellDef
              >
                <mat-form-field class="filter">
                  <input autocomplete="new-documentType" matInput formControlName="documentType" />
                  <mat-placeholder class="center"
                    >Document Type</mat-placeholder
                  >
                </mat-form-field>
              </th>
              <td mat-cell *matCellDef="let element">
                {{ element.documentType }}
              </td>
            </ng-container>

            <ng-container matColumnDef="patientId">
              <th
                class="header"
                mat-header-cell
                mat-sort-header
                *matHeaderCellDef
              >
                <mat-form-field class="filter">
                  <input autocomplete="new-patientId" matInput formControlName="patientId" />
                  <mat-placeholder class="center">Patient Id</mat-placeholder>
                </mat-form-field>
              </th>
              <td mat-cell *matCellDef="let element">
                <a
                  matTooltip="Click to View Patient"
                  class="eyeCursorclass"
                  [routerLink]="'/patientViewEdit/' + element?.patientId"
                  target="_blank"
                >
                  <b> {{ element?.defaultPatientID }}</b>
                </a>
              </td>
            </ng-container>
            <ng-container matColumnDef="documentEndDate">
              <th
                class="header"
                mat-header-cell
                mat-sort-header
                *matHeaderCellDef
              >
                <mat-form-field class="filter">
                  <input autocomplete="new-documentEndDate" matInput formControlName="documentEndDate" />
                  <mat-placeholder class="center">Expiry Date</mat-placeholder>
                  <mat-error
                    *ngIf="
                      docReportForm?.get('documentEndDate')?.touched &&
                      docReportForm?.get('documentEndDate')?.errors
                        ?.dateVaidator
                    "
                  >
                    Enter Valid date
                  </mat-error>
                </mat-form-field>
              </th>
              <td mat-cell *matCellDef="let element">
                {{ element.documentEndDate | date: "MM/dd/yyyy":"en_US" }}
              </td>
            </ng-container>
            <ng-container matColumnDef="documentExpiryDate">
              <th
                class="header"
                mat-header-cell
                mat-sort-header
                *matHeaderCellDef
              >
                <mat-form-field class="filter">
                  <input autocomplete="new-documentExpiryDate" matInput formControlName="documentExpiryDate" />
                  <mat-placeholder class="center"
                    >Expiration in Days</mat-placeholder
                  >
                  <mat-error
                    *ngIf="
                      docReportForm?.get('documentExpiryDate')?.touched &&
                      docReportForm?.get('documentExpiryDate')?.errors
                        ?.dateVaidator
                    "
                  >
                    Enter Valid date
                  </mat-error>
                </mat-form-field>
              </th>
              <td mat-cell *matCellDef="let element">
                {{ element?.documentExpiryDate }}
              </td>
            </ng-container>
            <ng-container matColumnDef="docStatus">
              <th
                class="header"
                mat-header-cell
                mat-sort-header
                *matHeaderCellDef
              >
                <mat-form-field class="filter">
                  <input autocomplete="new-docStatus" matInput formControlName="docStatus" />
                  <mat-placeholder class="center">Status</mat-placeholder>
                </mat-form-field>
              </th>
              <td mat-cell *matCellDef="let element">
                {{ element?.docStatus }}
              </td>
            </ng-container>
            <ng-container matColumnDef="option">
              <th class="header" mat-header-cell *matHeaderCellDef>Options</th>
              <td class="align" mat-cell *matCellDef="let element">
                <span class="row text-center">
                  <a
                    *ngIf="element?.blobName"
                    [routerLink]="[
                      '../ViewDocumentPerviewByBlobId/' +
                        element?.blobName +
                        '/4'
                    ]"
                    target="_blank"
                    matTooltip="Click to View Document"
                    class="eyeCursorclass"
                  >
                    <i class="fa fa-eye mr-2"></i>
                  </a>
                </span>
              </td>
            </ng-container>
            <!-- <ng-container matColumnDef="noRecords">
              <td class="text-align-center" mat-footer-cell *matFooterCellDef colspan="9">
                <div *ngIf="!isLoading">
                  {{ "No Records Found" }}
                </div>
                <div [hidden]="!isLoading">
                  <mat-spinner [diameter]="220"></mat-spinner>
                </div>
              </td>
            </ng-container> -->
            <!-- <ng-container matColumnDef="paginator">
              <td mat-footer-cell *matFooterCellDef colspan="12">
                <mat-paginator class="table table-responsive" #MatPaginator [length]="dataSource?.data?.length"
                  [pageSize]="10" [pageIndex]="0" showFirstLastButtons [pageSizeOptions]="[5, 10, 25, 100]">
                </mat-paginator>
              </td>
            </ng-container> -->
            <tr mat-header-row *matHeaderRowDef="arrDisplayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: arrDisplayedColumns"></tr>
            <!-- <tr mat-footer-row *matFooterRowDef="['noRecords']" [ngClass]="{
                active:
                  dataSource &&
                  dataSource?.data &&
                  dataSource?.data?.length !== 0
              }"></tr> -->
            <!-- <tr mat-footer-row *matFooterRowDef="['paginator']"></tr> -->
          </table>
          <div
          *ngIf="
           !( dataSource && dataSource?.data && dataSource?.data?.length !== 0)
          "
          class="text-align-center"
        >
          <div class="message py-3" *ngIf="!isLoading">
            {{ "No Records Found" }}
          </div>
          <div class="py-3" [hidden]="!isLoading">
            <mat-spinner [diameter]="220"></mat-spinner>
          </div>
        </div>
        <mat-paginator
          [pageSizeOptions]="[10, 20, 50, 100]"
          showFirstLastButtons
        ></mat-paginator>


        </div>
      </form>
    </div>
  </div>
</div>
