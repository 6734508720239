import { PagedAndSortedResultRequestDto } from '@abp/ng.core';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngxs/store';
import { ClaimInformationsService } from 'projects/billing/src/app/billing-proxy/billing-management/posting';
import { ClaimDTO } from 'projects/billing/src/app/billing-proxy/billing-management/posting/dto';
import { paypalPaymentMethodType } from 'projects/billing/src/app/billing-proxy/billing-management/rcm/order-management/order/paypal-payment-method-type.enum';
import { ItemDTO, PayPalInvoicePaymentsDTO, SaleorderDTO } from 'projects/order/src/app/order-proxy/order-management/order/dto/models';
import { SaleorderService } from 'projects/order/src/app/order-proxy/order-management/order/saleorder.service';
import { CreateUpdatePayPalInvoiceDTO, Item, Phone } from 'projects/order/src/app/order-proxy/order-management/pay-pal/dto/models';
import { InvoiceService } from 'projects/order/src/app/order-proxy/order-management/pay-pal/invoice.service';
import { AddressDTO } from 'projects/order/src/app/order-proxy/order-management/rcm/patient-management/patient/dto/models';
import { getOrder } from 'projects/order/src/app/order-store/order.action';
import { RegionCountriesDTO } from 'projects/patient/src/app/dropdown-proxy/dropdown-management/dropdowns/dto/models';
import { RegionDropdownService } from 'projects/patient/src/app/dropdown-proxy/dropdown-management/dropdowns/region-dropdown-service/region-dropdown.service';
import { PatientDTO } from 'projects/patient/src/app/patient-proxy/patient/dto/models';
import Swal from 'sweetalert2';
import { ToastrService } from "ngx-toastr";

@Component({
  selector: 'app-invoice-payment-view',
  templateUrl: './invoice-payment-view.component.html',
  styleUrls: ['./invoice-payment-view.component.scss']
})
export class InvoicePaymentViewComponent implements OnInit {
  saleOrderId: string = "";
  Name: string = "";
  dob: string;
  paymentCompletedDateTime: string = "";
  paymentCreatedDateTime: string = "";
  totalPaidAmount: string = "";
  totalPaypalAmount: string = "";
  totalTransferredAmount: string = "";
  invoiceOrderId: string = "";
  items: any[] = [];
  paymentData: any[] = []
  showButton: boolean = false;
  invoiceNumber: string = '';
  drpCountry: RegionCountriesDTO[] = [];
  orderId: string = '';
  payPalInvoiceMailResendCount: number = 0;
  claimId: string = "";
  constructor(
    private region: RegionDropdownService,
    public dialogRef: MatDialogRef<InvoicePaymentViewComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { orderId: string, claimId: string },
    private invoiceService: InvoiceService,
    private store: Store,
    private saleOrderService: SaleorderService,
    private claimInformationService: ClaimInformationsService,
    private toastr: ToastrService,


  ) { }

  ngOnInit(): void {
    this.drpCountry = [];
    this.region.getAllCountriesByInput(new PagedAndSortedResultRequestDto).subscribe(response => {
      this.drpCountry = response?.items ?? [];
    }, err => {
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    });
    this.orderId = this.data?.orderId ?? "";
    this.claimId = this.data?.claimId ?? "";
    this.orderId && this.orderId !== "" && this.orderId !== null && this.orderId !== undefined && this.getPaymentView();

  }

  //! Invoice Paypal Proceed
  paypalProceed() {
    this.store.dispatch(new getOrder(this.data.orderId)).subscribe(response => {
      const orderData: SaleorderDTO = response?.orderState?.selectedOrder || null;
      const orderItem: ItemDTO[] = response?.orderState?.selectedOrder?.items ?? [];
      const orderId: string = orderData?.id ?? null;
      const patientData: PatientDTO = orderData?.patients;
      const bAddress: AddressDTO = patientData?.addresses?.[0] || null;
      const dAddress: AddressDTO = patientData?.addresses?.[1] || null;

      let invoiceItemsData: Item[] = [];
      orderItem.forEach(element => {
        invoiceItemsData.push({
          name: element.name,
          description: element.description,
          quantity: String(element.qty),
          unit_amount: {
            currency_code: "USD",
            value: String(element.charge),
          },
          tax: null,
          discount: null,
          unit_of_measure: "QUANTITY"
        })
      });
      let patientPhones: Phone[];
      if (bAddress?.country !== "" && bAddress?.country !== null && bAddress?.country !== undefined &&
          bAddress?.phone !== "" && bAddress?.phone !== null && bAddress?.phone !== undefined) {
        patientPhones = [
          {
            country_code: this.drpCountry.filter(v => v?.countryShortName === bAddress?.country)?.[0]?.countryPhoneCode ?? null,
            national_number: bAddress?.phone ?? null,
            phone_type: "MOBILE"
          }
        ]
      }
      const input: CreateUpdatePayPalInvoiceDTO = {
        paypalPaymentMethodType: paypalPaymentMethodType.Invoice,
        saleOrderId: orderId,
        detail: null,
        invoicer: null,
        primary_recipients: [
          {
            billing_info: {
              name: {
                given_name: patientData?.personals?.firstName ?? "",
                surname: patientData?.personals?.lastName ?? ""
              },
              address: {
                address_line_1: bAddress?.address ?? "",
                admin_area_1: bAddress?.city ?? "",
                postal_code: bAddress?.postalCode ?? "",
                country_code: bAddress?.country === "" ? null : bAddress?.country,
              },
              email_address: patientData?.personals?.emailId == null ||
              patientData?.personals?.emailId == undefined ||
              patientData?.personals?.emailId == "" ? 'sb-cvmiv6652198@business.example.com' : patientData?.personals?.emailId,// patientData?.personals?.emailId ?? null,
              phones: patientPhones ?? null,//!
              additional_info_value: null
            },
            shipping_info: {
              name: {
                given_name: patientData?.personals?.firstName ?? "",
                surname: patientData?.personals?.lastName ?? ""
              },
              address: {
                address_line_1: dAddress?.address ?? "",
                admin_area_1: dAddress?.city ?? "",
                postal_code: dAddress?.postalCode ?? "",
                country_code: dAddress?.country === "" ? null : dAddress?.country,
              }
            }
          }
        ],
        items: invoiceItemsData,
        configuration: null,
        amount: null,
      }

      this.invoiceService.createDraftInvoice(input).subscribe(response => {
        this.getClaimDataById();



      }, err => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      })
    }, err => {
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    });
  }
  //! Invoice payment view
  getPaymentView() {
    this.store.dispatch(new getOrder(this.data.orderId)).subscribe(response => {
      const orderData: SaleorderDTO = response?.orderState?.selectedOrder;
      this.showButton = true;
      if (orderData?.payPalPaymentStatus === "Initiate Payment" || orderData?.payPalPaymentStatus === null) {
        this.showButton = true;
      } else { this.showButton = false; }
      this.saleOrderId = orderData?.saleorderId ?? "";
      this.dob = orderData?.patients?.personals?.dateOfBirth == null ? "" : new Date(orderData?.patients?.personals?.dateOfBirth)?.toLocaleDateString("en-US") ?? "";
      this.Name = orderData?.patients?.personals?.fullName ?? "";
      orderData?.items?.forEach(element => { this.items?.push({ itemName: element.name, amount: element.charge, code: element.itemCodeId }) });
      const lastInvoiceDetails: PayPalInvoicePaymentsDTO | null = orderData?.paymentTransactionDetails?.payPalInvoicePayments?.slice(orderData?.paymentTransactionDetails?.payPalInvoicePayments?.length - 1, orderData?.paymentTransactionDetails?.payPalInvoicePayments?.length)?.[0] || null;
      this.paymentCompletedDateTime = lastInvoiceDetails?.payments?.transactions?.[0]?.payment_date ? lastInvoiceDetails.detail?.metadata?.last_update_time : "";
      this.paymentCreatedDateTime = lastInvoiceDetails?.detail?.metadata?.create_time ?? "";
      this.totalPaidAmount = lastInvoiceDetails?.['totalPaidAmount'] ?? "";
      this.totalPaypalAmount = lastInvoiceDetails?.['totalPaypalTransactionChargeAmount'] ?? "";
      this.invoiceOrderId = lastInvoiceDetails?.id ?? "";
      this.totalTransferredAmount = lastInvoiceDetails?.['totalTransferredAmount'] ?? "";
      this.invoiceNumber = lastInvoiceDetails?.detail?.invoice_number ?? "";
      this.payPalInvoiceMailResendCount = orderData?.payPalMailResendCount?? 0;

    }, err => {
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    });
  }
  //! Get Claim Data By Id
  getClaimDataById() {

    if (this.claimId && this.claimId !== "" && this.claimId !== undefined && this.claimId !== null) {
      this.claimInformationService.get(this.claimId).subscribe((claimResponse: ClaimDTO) => {

        this.updateClaimDataOnOrderUpdate(claimResponse);
      }, err => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      });
    }
    else{
      this.toastr.success('Mail Sent Successfully','Success')
      // Swal.fire({ title: 'Success', html: 'Mail Sent Successfully', icon: 'success', timer: 3000, timerProgressBar: true });
      this.dialogRef.close();
    }
  }
  //! Update Claim Order Data
  updateClaimDataOnOrderUpdate(claimResponse) {
    if (this.claimId && this.claimId !== "" && this.claimId !== undefined && this.claimId !== null) {

        const input = {
          lstClaimInfo: claimResponse?.lstClaimInfo,
          lstPostingCharges: claimResponse?.lstPostingCharges,
          insurancePayments: claimResponse?.insurancePayments,
          saleOrder: claimResponse?.saleOrder,
          lstPatientVisitNotes: claimResponse?.lstPatientVisitNotes,
          lstClearingHouseUpdates: claimResponse?.lstClearingHouseUpdates,
          totalPatientBal: claimResponse?.totalPatientBal,
          readyCount: claimResponse?.readyCount,
          processedCount: claimResponse?.processedCount,
          crossOverCount: claimResponse?.crossOverCount,
          holdCount: claimResponse?.holdCount,
          patientResponsibilityCount: claimResponse?.patientResponsibilityCount,
          patientResponsibilityAfterInsCount: claimResponse?.patientResponsibilityAfterInsCount,
          patientId: claimResponse?.patientId,
          defaultPatientId: claimResponse?.defaultPatientId,
          patientName: claimResponse?.patientName,
          patientDob: claimResponse?.patientDob,
          orderId: claimResponse?.orderId,
          saleorderId: claimResponse?.saleorderId,
          claimStatus: claimResponse?.claimStatus,
          claimId: claimResponse?.claimId,
          totalBilled: claimResponse.totalBilled,
          totalInsBal: claimResponse.totalInsBal ,
          lstReminders: claimResponse?.lstReminders,
          claimProcessMethod: claimResponse?.claimProcessMethod
          }

        this.claimInformationService.update(this.claimId, input).subscribe((claimResponse) => {
          // Swal.fire({ title: 'Success', html: 'Mail Sent Successfully', icon: 'success', timer: 3000, timerProgressBar: true });
          this.toastr.success('Mail Sent Successfully','Success')
          this.dialogRef.close();
        }, err => {
          const data: HttpErrorResponse = err;
          Swal.fire({
            icon: 'info',
            text: data?.error?.error?.message,
          });
        });
    }
  }

}
