<!-- <br /> -->
<div class="card card-body">
  <!-- <div class="list-box"> -->
  <div class="row mar-Top">
    <div class="col-2">
      <br>
      <h4 class="customThemeClass"><b>Emergency Contact</b></h4>
    </div>
    <div class="col-4">
      <br>
      <!-- <button type="button" class="btn btn-primary waves-effect right-bar-toggle waves-light mb-2 mr-5 btn-sm align">
      <i class="bx bx-copy-alt font-size-16 align-middle mr-1"></i>Copy Patient Info
    </button> -->

      <button mat-button (click)='copyPatientInfo(1)' class="buttonColor waves-effect font-size-12 mb-2 mr-2 btn-sm">
        <!-- <i class="bx bx-copy-alt font-size-16 align-middle mr-1"></i> -->
        <i class="fa fa-clone mr-1" ></i>
        Copy Patient Info
      </button>
    </div>
  </div>
  <form [formGroup]="contactsForm">
    <div class="row">
      <div class="col-lg-6">
        <mat-card-content>
          <div class="row">
            <mat-form-field class="col-6">
              <mat-label> Relationship </mat-label>
              <mat-select [(value)]="relationship" formControlName="drpEmergencyRelationshipType" maxlength="40">
                <mat-option *ngFor="let emergency of relationShipWithoutSelves" [value]="emergency.relationShipCode">
                  {{emergency.relationType}}
                </mat-option>
              </mat-select>
              <button mat-button (click)="relationship=undefined;$event.stopPropagation()" *ngIf="relationship"
                matSuffix (click)="clearRelationship()" mat-icon-button >
                <mat-icon>close</mat-icon>
              </button>
              <mat-error *ngIf="
            contactsForm?.get('drpEmergencyRelationshipType')?.touched &&
            contactsForm?.get('drpEmergencyRelationshipType')?.errors?.required">
                Relationship is a required field!
              </mat-error>
            </mat-form-field>
            <mat-form-field class="col-6">
              <mat-label> First Name </mat-label>
              <input autocomplete="new-txtEmergencyFirstName" matInput type="text" maxlength="40" formControlName="txtEmergencyFirstName">
              <mat-error
                *ngIf="contactsForm?.get('txtEmergencyFirstName')?.touched && contactsForm?.get('txtEmergencyFirstName')?.errors?.sAlphabets">
                Enter only Alphabets!
              </mat-error>
            </mat-form-field>
            <mat-form-field class="col-6">
              <mat-label>Address</mat-label>
              <textarea autocomplete="new-txtEmergencyAddress" formControlName="txtEmergencyAddress" maxlength="400" rows="3" matInput></textarea>
            </mat-form-field>
            <!-- <ng-select class="col-6" (change)="onChangeEmerState($event)" formControlName="txtEmergencyCountry"
            placeholder="Country" maxlength="40">
            <ng-option *ngFor="let country of drpEmerCountry" [value]="country.countryName">
              {{ country.countryName }}</ng-option>
          </ng-select> -->
            <mat-form-field class="col-6">
              <mat-label>Country</mat-label>
              <mat-select formControlName="txtEmergencyCountry" (selectionChange)='onChangeEmerState($event.value)'
                [(value)]="EmergencyCountry" #singleSelect>
                <mat-option>
                  <ngx-mat-select-search placeholderLabel="Search" noEntriesFoundLabel="No Matches found"
                    formControlName="txtEmergencyCountryFilter"></ngx-mat-select-search>
                </mat-option>
                <mat-option *ngFor="let country of filteredEmergencyCountries | async"
                  [value]="country.countryShortName">
                  {{ country.countryName }}
                </mat-option>
              </mat-select>
              <button mat-button (click)="EmergencyCountry=undefined;$event.stopPropagation()" *ngIf="EmergencyCountry"
                matSuffix (click)="clearEmergencyCountry()" mat-icon-button >
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field>
            <!-- <ng-select class="col-6" formControlName="txtEmergencyCity" placeholder="City" maxlength="40">
            <ng-option *ngFor="let city of drpEmerCities" [value]="city.cityName">
              {{ city.cityName }}</ng-option>
          </ng-select> -->
            <mat-form-field class="col-6">
              <mat-label>City</mat-label>
              <mat-select formControlName="txtEmergencyCity" [(value)]="EmergencyCity" #singleSelect>
                <mat-option>
                  <ngx-mat-select-search placeholderLabel="Search" noEntriesFoundLabel="No Matches found"
                    formControlName="txtEmergencyCityFilter"></ngx-mat-select-search>
                </mat-option>
                <mat-option *ngFor="let city of filteredEmergencyCities | async" [value]="city.cityName">
                  {{ city.cityName }}
                </mat-option>
              </mat-select>
              <button mat-button (click)="EmergencyCity=undefined;$event.stopPropagation()" *ngIf="EmergencyCity"
                matSuffix (click)="clearEmergencyCity()" mat-icon-button >
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field>

            <mat-form-field class="col-6">
              <mat-label> Zip Code </mat-label>
              <input autocomplete="new-txtEmergencyPostalCode" formControlName="txtEmergencyPostalCode" maxlength="15" pattern="[0-9-]*" matInput type="text">
              <mat-error *ngIf="
            contactsForm?.get('txtEmergencyPostalCode')?.touched &&
            contactsForm?.get('txtEmergencyPostalCode')?.errors?.pattern
          ">
                Enter only Numbers!
              </mat-error>
            </mat-form-field>
            <mat-form-field class="col-6">
              <mat-label>Fax</mat-label>
              <input autocomplete="new-txtEmergencyFax" formControlName="txtEmergencyFax" matInput maxlength="40" prefix="{{phoneEmerCode}}-"
                mask="(000)-000-0000" type="text">
              <mat-error
                *ngIf="contactsForm?.get('txtEmergencyFax')?.touched && contactsForm?.get('txtEmergencyFax')?.errors">
                Enter Minimum 10 Digits!
              </mat-error>
            </mat-form-field>
            <mat-form-field class="col-6">
              <mat-label> Email Address </mat-label>
              <input autocomplete="new-txtEmergencyEmail" formControlName="txtEmergencyEmail" matInput
                pattern="[a-zA-Z0-9._!#$%&'()*+,-./:;<=>?@[\]^_`{|}~]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$"
                [textMask]="{mask: emailMask}" maxlength="40" type="text">
              <mat-error
                *ngIf="contactsForm?.get('txtEmergencyEmail')?.touched && contactsForm?.get('txtEmergencyEmail')?.errors?.pattern">
                This is not a valid Email!!!
              </mat-error>
            </mat-form-field>
          </div>
        </mat-card-content>
      </div>
      <div class="col-lg-6 ">
        <mat-card-content>
          <div class="row">
            <mat-form-field class="col-6">
              <mat-label>Middle Name</mat-label>
              <input autocomplete="new-txtEmergencyMiddleName" formControlName="txtEmergencyMiddleName" maxlength="40" matInput type="text">
              <mat-error
                *ngIf="contactsForm?.get('txtEmergencyMiddleName')?.touched && contactsForm?.get('txtEmergencyMiddleName')?.errors?.sAlphabets">
                Enter only Alphabets!
              </mat-error>
            </mat-form-field>
            <mat-form-field class="col-6">
              <mat-label> Last Name</mat-label>
              <input autocomplete="new-txtEmergencyLastName" formControlName="txtEmergencyLastName" maxlength="40" matInput type="text">
              <mat-error
                *ngIf="contactsForm?.get('txtEmergencyLastName')?.touched && contactsForm?.get('txtEmergencyLastName')?.errors?.sAlphabets">
                Enter only Alphabets!
              </mat-error>
            </mat-form-field>
            <!-- <ng-select class="col-6" (change)="onChangeEmerCity($event)" formControlName="txtEmergencyState"
            placeholder="State" maxlength="40">
            <ng-option *ngFor="let state of drpEmerStates" [value]="state.stateName">
              {{ state.stateName }}</ng-option>
          </ng-select> -->
            <mat-form-field class="col-6">
              <mat-label>State</mat-label>
              <mat-select formControlName="txtEmergencyState" (selectionChange)='onChangeEmerCity($event.value,"")'
                [(value)]="EmergencyState" #singleSelect>
                <mat-option>
                  <ngx-mat-select-search placeholderLabel="Search" noEntriesFoundLabel="No Matches found"
                    formControlName="txtEmergencyStateFilter"></ngx-mat-select-search>
                </mat-option>
                <mat-option *ngFor="let state of filteredEmergencyStates | async" [value]="state.stateShortName">
                  {{ state.stateName }}
                </mat-option>
              </mat-select>
              <button mat-button (click)="EmergencyState=undefined;$event.stopPropagation()" *ngIf="EmergencyState"
                matSuffix (click)="clearEmergencyState()" mat-icon-button >
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field>


            <mat-form-field class="col-6">
              <mat-label>County</mat-label>
              <input autocomplete="new-txtEmergencyCounty" formControlName="txtEmergencyCounty" matInput maxlength="40" type="text" />
              <mat-error *ngIf="
                    contactsForm?.get('txtEmergencyCounty')?.touched &&
                    contactsForm?.get('txtEmergencyCounty')?.errors?.sAlphabets
                  ">
                Enter only Alphabets!
              </mat-error>
              <!-- <mat-select [(value)]="county" (click)="clearCounty()" formControlName="txtEmergencyCounty" maxlength="40">
              <mat-option *ngFor="let county of drpCounty" [value]="county.countyTypeShortCodeId">
                {{county.countyType}}
              </mat-option>
            </mat-select>
            <button mat-button (click)="county=undefined;$event.stopPropagation()" *ngIf="county" matSuffix
            mat-icon-button >
            <mat-icon>close</mat-icon>
          </button> -->
            </mat-form-field>

            <mat-form-field class="col-6">
              <mat-label>Phone</mat-label>
              <input autocomplete="new-txtEmergencyPhone" formControlName="txtEmergencyPhone" matInput maxlength="40" prefix="{{phoneEmerCode}}-"
                mask="(000)-000-0000" type="text">
              <mat-error
                *ngIf="contactsForm?.get('txtEmergencyPhone')?.touched && contactsForm?.get('txtEmergencyPhone')?.errors">
                Enter Minimum 10 Digits!
              </mat-error>
              <mat-error
                *ngIf="contactsForm?.get('txtEmergencyPhone')?.touched &&contactsForm?.get('txtEmergencyPhone')?.errors?.required">
                Payor Level is a required field!
              </mat-error>
            </mat-form-field>
            <mat-form-field class="col-6">
              <mat-label>Mobile</mat-label>
              <input autocomplete="new-txtEmergencyMobile" formControlName="txtEmergencyMobile" matInput prefix="{{phoneEmerCode}}-" mask="(000)-000-0000"
                type="text">
              <mat-error
                *ngIf="contactsForm?.get('txtEmergencyMobile')?.touched && contactsForm?.get('txtEmergencyMobile')?.errors">
                Enter Minimum 10 Digits!
              </mat-error>
            </mat-form-field>
          </div>
        </mat-card-content>
      </div>
    </div>
    <mat-divider class="mt-3 bg"> </mat-divider>
    <div class="row">
      <div class="col-2">
        <br>
        <h4 class="customThemeClass"><b>Responsible Party</b></h4>
      </div>
      <div class="col-4">
        <br>
        <!-- <button type="button" class="btn btn-primary waves-effect right-bar-toggle waves-light mb-2 mr-5 btn-sm align">
    <i class="bx bx-copy-alt font-size-16 align-middle mr-1"></i>Copy Patient Info
  </button> -->
      </div>
    </div>
    <div class="row">
      <div class="col-lg-6 ">
        <mat-card-content>
          <div class="row">
            <mat-form-field class="col-6">
              <mat-label> Relationship </mat-label>
              <mat-select (valueChange)='copyPatientInfo(2,$event)' [(value)]="relationships"
                formControlName="drpResponsibleRelationshipType" maxlength="40">
                <mat-option *ngFor=" let responsible of responsibleRelationship" [value]="responsible.relationShipCode">
                  {{responsible.relationType}}
                </mat-option>
              </mat-select>
              <button mat-button (click)="relationships=undefined;$event.stopPropagation()" *ngIf="relationships"
                matSuffix (click)="clearRelationships()" mat-icon-button >
                <mat-icon>close</mat-icon>
              </button>
              <mat-error *ngIf="
            contactsForm?.get('drpResponsibleRelationshipType')?.touched &&
            contactsForm?.get('drpResponsibleRelationshipType')?.errors?.required">
                Relationship is a required field!
              </mat-error>
            </mat-form-field>
            <mat-form-field class="col-6">
              <mat-label> First Name </mat-label>
              <input autocomplete="new-txtResponsibleFirstName" formControlName="txtResponsibleFirstName" maxlength="40" matInput type="text">
              <mat-error
                *ngIf="contactsForm?.get('txtResponsibleFirstName')?.touched && contactsForm?.get('txtResponsibleFirstName')?.errors?.sAlphabets">
                Enter only Alphabets!
              </mat-error>
              <!-- <mat-error
                *ngIf="contactsForm?.get('txtResponsibleFirstName')?.touched &&contactsForm?.get('txtResponsibleFirstName')?.errors?.required">
                First Name is a required field!
              </mat-error> -->
            </mat-form-field>
            <mat-form-field class="col-6">
              <mat-label>Address </mat-label>
              <textarea autocomplete="new-txtResponsibleAddress" formControlName="txtResponsibleAddress" rows="3" maxlength="400" matInput></textarea>
              <!-- <mat-error
                *ngIf="contactsForm?.get('txtResponsibleAddress')?.touched &&contactsForm?.get('txtResponsibleAddress')?.errors?.required">
                Address is a required field!
              </mat-error> -->

            </mat-form-field>
            <!-- <ng-select class="col-6" (change)="onChangeResState($event)" formControlName="txtResponsibleCountry"
            placeholder="Country" maxlength="40">
            <ng-option *ngFor="let country of drpResCountry" [value]="country.countryName">
              {{ country.countryName }}</ng-option>
          </ng-select> -->
            <mat-form-field class="col-6">
              <mat-label>Country</mat-label>
              <mat-select formControlName="txtResponsibleCountry" (selectionChange)='onChangeResState($event.value)'
                [(value)]="ResponsibleCountry" #singleSelect>
                <mat-option>
                  <ngx-mat-select-search placeholderLabel="Search" noEntriesFoundLabel="No Matches found"
                    formControlName="txtResponsibleCountryFilter"></ngx-mat-select-search>
                </mat-option>
                <mat-option *ngFor="let country of filteredResponsibleCountries | async"
                  [value]="country.countryShortName">
                  {{ country.countryName }}
                </mat-option>
              </mat-select>
              <button mat-button (click)="ResponsibleCountry=undefined;$event.stopPropagation()"
                *ngIf="ResponsibleCountry" matSuffix (click)="clearResponsibleCountry()" mat-icon-button
                >
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field>

            <!-- <ng-select class="col-6" formControlName="txtResponsibleCity" placeholder="City" maxlength="40">
            <ng-option *ngFor="let city of drpResCities" [value]="city.cityName">
              {{ city.cityName }}</ng-option>
          </ng-select> -->
            <mat-form-field class="col-6">
              <mat-label>City</mat-label>
              <mat-select formControlName="txtResponsibleCity" [(value)]="ResponsibleCity" #singleSelect>
                <mat-option>
                  <ngx-mat-select-search placeholderLabel="Search" noEntriesFoundLabel="No Matches found"
                    formControlName="txtResponsibleCityFilter"></ngx-mat-select-search>
                </mat-option>
                <mat-option *ngFor="let city of filteredResponsibleCities | async" [value]="city.cityName">
                  {{ city.cityName }}
                </mat-option>
              </mat-select>
              <button mat-button (click)="ResponsibleCity=undefined;$event.stopPropagation()" *ngIf="ResponsibleCity"
                matSuffix (click)="clearResponsibleCity()" mat-icon-button >
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field>
            <mat-form-field class="col-6">
              <mat-label> Zip Code </mat-label>
              <input autocomplete="new-txtResponsiblePostalCode" formControlName="txtResponsiblePostalCode" maxlength="15" matInput type="text" pattern="[0-9-]*">
              <mat-error *ngIf="
            contactsForm?.get('txtResponsiblePostalCode')?.touched &&
            contactsForm?.get('txtResponsiblePostalCode')?.errors?.pattern
          ">
                Enter only Numbers!
              </mat-error>
            </mat-form-field>
            <mat-form-field class="col-6">
              <mat-label>Fax</mat-label>
              <input autocomplete="new-txtResponsibleFax" formControlName="txtResponsibleFax" matInput maxlength="40" prefix="{{phoneResCode}}-"
                mask="(000)-000-0000" type="text">
              <mat-error
                *ngIf="contactsForm?.get('txtResponsibleFax')?.touched && contactsForm?.get('txtResponsibleFax')?.errors">
                Enter Minimum 10 Digits!
              </mat-error>
            </mat-form-field>
            <mat-form-field class="col-6">
              <mat-label> Email Address </mat-label>
              <input autocomplete="new-txtResponsibleEmail" matInput maxlength="40"
                pattern="[a-zA-Z0-9._!#$%&'()*+,-./:;<=>?@[\]^_`{|}~]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$"
                [textMask]="{mask: emailMask}" maxlength="40" formControlName="txtResponsibleEmail" type="text">
              <mat-error
                *ngIf="contactsForm?.get('txtResponsibleEmail')?.touched && contactsForm?.get('txtResponsibleEmail')?.errors?.pattern">
                This is not a valid Email!!!
              </mat-error>
            </mat-form-field>
          </div>
        </mat-card-content>
      </div>
      <div class="col-lg-6 ">
        <mat-card-content>
          <div class="row">
            <mat-form-field class="col-6">
              <mat-label>Middle Name</mat-label>
              <input autocomplete="new-txtResponsibleMiddleName" formControlName="txtResponsibleMiddleName" maxlength="40" matInput type="text">
              <mat-error
                *ngIf="contactsForm?.get('txtResponsibleMiddleName')?.touched && contactsForm?.get('txtResponsibleMiddleName')?.errors?.sAlphabets">
                Enter only Alphabets!
              </mat-error>
            </mat-form-field>
            <mat-form-field class="col-6">
              <mat-label> Last Name</mat-label>
              <input autocomplete="new-txtResponsibleLastName" formControlName="txtResponsibleLastName" maxlength="40" matInput type="text">
              <mat-error
                *ngIf="contactsForm?.get('txtResponsibleLastName')?.touched && contactsForm?.get('txtResponsibleLastName')?.errors?.sAlphabets">
                Enter only Alphabets!
              </mat-error>
            </mat-form-field>
            <!-- <mat-form-field class="col-6">
            <mat-label>State</mat-label>
            <mat-select formControlName="txtResponsibleState" #txtResponsibleState>
              <mat-option *ngFor="let state of drpState" [value]="state.stateShortCodeId">
                {{state.stateType}}
              </mat-option>


            </mat-select>
          </mat-form-field> -->
            <!-- <ng-select class="col-6" (change)="onChangeResCity($event)" formControlName="txtResponsibleState"
            placeholder="State" maxlength="40">
            <ng-option *ngFor="let state of drpResStates" [value]="state.stateName">
              {{ state.stateName }}</ng-option>
          </ng-select> -->
            <mat-form-field class="col-6">
              <mat-label>State</mat-label>
              <mat-select formControlName="txtResponsibleState" (selectionChange)='onChangeResCity($event.value,"")'
                [(value)]="ResponsibleState" #singleSelect>
                <mat-option>
                  <ngx-mat-select-search placeholderLabel="Search" noEntriesFoundLabel="No Matches found"
                    formControlName="txtResponsibleStateFilter"></ngx-mat-select-search>
                </mat-option>
                <mat-option *ngFor="let state of filteredResponsibleStates | async" [value]="state.stateShortName">
                  {{ state.stateName }}
                </mat-option>
              </mat-select>
              <button mat-button (click)="ResponsibleState=undefined;$event.stopPropagation()" *ngIf="ResponsibleState"
                matSuffix (click)="clearResponsibleState()" mat-icon-button >
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field>

            <mat-form-field class="col-6">
              <mat-label>County</mat-label>
              <input autocomplete="new-txtResponsibleCounty" formControlName="txtResponsibleCounty" matInput maxlength="40" type="text" />
              <mat-error *ngIf="
                    contactsForm?.get('txtResponsibleCounty')?.touched &&
                    contactsForm?.get('txtResponsibleCounty')?.errors?.sAlphabets
                  ">
                Enter only Alphabets!
              </mat-error>
              <!-- <mat-select [(value)]="countys" (click)="clearCountys()" formControlName="txtResponsibleCounty" maxlength="40">
              <mat-option *ngFor="let county of drpCounty" [value]="county.countyTypeShortCodeId">
                {{county.countyType}}
              </mat-option>
            </mat-select>
            <button mat-button (click)="countys=undefined;$event.stopPropagation()" *ngIf="countys" matSuffix
            mat-icon-button >
            <mat-icon>close</mat-icon>
          </button> -->
            </mat-form-field>
            <mat-form-field class="col-6">
              <mat-label>Phone</mat-label>
              <input autocomplete="new-txtResponsiblePhone" formControlName="txtResponsiblePhone" matInput maxlength="40" prefix="{{phoneResCode}}-"
                mask="(000)-000-0000" type="text">
              <mat-error
                *ngIf="contactsForm?.get('txtResponsiblePhone')?.touched && contactsForm?.get('txtResponsiblePhone')?.errors">
                Enter Minimum 10 Digits!
              </mat-error>
              <!-- <mat-error *ngIf="contactsForm?.get('txtResponsiblePhone')?.touched &&contactsForm?.get('txtResponsiblePhone')?.errors?.required">
              Phone is a required field!
            </mat-error> -->
            </mat-form-field>
            <mat-form-field class="col-6">
              <mat-label>Mobile</mat-label>
              <input autocomplete="new-txtResponsibleMobile" formControlName="txtResponsibleMobile" matInput prefix="{{phoneResCode}}-" mask="(000)-000-0000"
                type="text">
              <mat-error
                *ngIf="contactsForm?.get('txtResponsibleMobile')?.touched && contactsForm?.get('txtResponsibleMobile')?.errors">
                Enter Minimum 10 Digits!
              </mat-error>
            </mat-form-field>
          </div>
        </mat-card-content>
      </div>
    </div>
  </form>
  <br>
  <div class="row">
    <div class="col-sm-12">
      <div class="text-lg-center ">
        <!-- <button type="submit" class="btn btn-primary waves-effect right-bar-toggle waves-light mb-1 mr-1">Save</button> -->
        <button *ngIf='(isShownSaveButton || isShownUpdateIconForPatient) && (isPatientLockedByCurrentUser)'
          [disabled]="saveButtonHide || !(!contactsForm.invalid && (contactsForm.dirty ))" mat-button
          class="buttonColor" (click)="getPatientDetailsForSave()">Save
          <mat-icon *ngIf='isShowSpinner'>
            <mat-spinner class="spinner-border spinner-border-sm" diameter="20"> </mat-spinner>
          </mat-icon>
        </button>
      </div>
    </div>
  </div>
  <!-- </div> -->
</div>
