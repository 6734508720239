<div class="col-12" style="margin-top: -18px">
  <div class="d-flex align-items-center justify-content-between m-0">
    <h4 class="font-size-18 m-0 customThemeClass">
      <b>Patient /
        {{ (patientId === defaultGuid|| patientId===null || patientId === undefined || patientId === '')
        ? "New" : "View" }} Patient</b>
    </h4>
    <div class="page-title-box page-title-right">
      <ol class="breadcrumbs m-0">
        <li class="mr-4" *ngIf="wipId != defaultGuid">
          <button matTooltip="Click To Assign" mat-button class="buttonColor font-size-10"
            (click)="openWipAllocationModal()">
            <mat-icon class="mb-1">supervisor_account</mat-icon>
            {{ defaultWipId }}
          </button>
        </li>

        <li class="mb-1 mr-3">
          <button matTooltip="Need to update? press the Edit " *ngIf="!isEditButton" mat-button class="buttonColors"
            (click)="editPatient($event)">
            <i class="fa fa-pencil-square-o mr-1" aria-hidden="true" style="font-size: 18px"></i>
            Edit
          </button>
        </li>

        <li class="mb-1 mr-3">
          <button matTooltip="Back To Patient Search" mat-button class="buttonColor font-size-10"
            (click)="backToPatientSearch()">
            <i class="fa fa-backward mr-1"></i> Back
          </button>
        </li>
        <!-- <li class="breadcrumb-item mt-2 cls eyeCursorclass">Patient</li>
        <li class="breadcrumb-item active eyeCursorclass">{{strUrlPath==="patientCreateEditTab"?"New":"View"}} Patient </li>-->
      </ol>
    </div>
  </div>
</div>

<div class="row">
  <div class="col-xl-2 pr-0 mb-3">
    <app-patient-common-summary-tab *ngIf="!!patientId" [orderId]="orderId" [patientId]="patientId">
    </app-patient-common-summary-tab>
    <app-patient-common-summary-tab *ngIf="!patientId" [orderId]="orderId" [patientId]="patientId">
    </app-patient-common-summary-tab>
  </div>
  <div class="col-xl-10 pr-0">
    <ul class="card card-body" ngbNav #nav="ngbNav" [(activeId)]="active" justify="justified"
      class="alert alert-success nav nav-pills nav-fill">
      <li ngbNavItem="Personal">
        <!-- <img class="img-responsive " src="assets\images\menu icons\tabs icon\Personal.png"  alt="" height="15"> -->
        <a ngbNavLink>Personal & Contact</a>
        <ng-template ngbNavContent>
          <app-patients-tab (patientIdEmitter)="patientDetailsAfterSave($event)" [patientId]="patientId" [isButtonDisable]="isButtonDisable" >
          </app-patients-tab>
        </ng-template>
      </li>
      <li ngbNavItem="Clinical" [disabled]="!patientId">
        <!-- <img class="img-responsive " src="assets\images\menu icons\tabs icon\Clinical.png"  alt="" height="15"> -->
        <a ngbNavLink id="tab-3">Clinical</a>
        <ng-template ngbNavContent>
          <app-patient-clinical-tab (patientIdEmitter)="patientDetailsAfterSave($event)" [patientId]="patientId">
          </app-patient-clinical-tab>
        </ng-template>
      </li>
      <!-- [patient]="patient" -->


      <!-- <li ngbNavItem="Document" [disabled]="!patientId">
        <a ngbNavLink>Document</a>
        <ng-template ngbNavContent>
          <app-patient-common-document-medicare-tab (patientIdEmitter)="patientDetailsAfterSave($event)"
            [viewDocViewer]="false" [patientId]="patientId">

          </app-patient-common-document-medicare-tab>
        </ng-template>
      </li> -->


      <li ngbNavItem="Sorting" [disabled]="!patientId">
        <a ngbNavLink>Sorting</a>
        <ng-template ngbNavContent>
          <app-PatientCommonSortingTab (patientIdEmitter)="patientDetailsAfterSave($event)" [patientId]="patientId" [documentId]="docId">
          </app-PatientCommonSortingTab>

        </ng-template>
      </li>
      <li ngbNavItem="Insurance" [disabled]="!patientId">
        <!-- <img class="img-responsive  " src="assets\images\menu icons\tabs icon\Insurance.png"  alt="" height="15"> -->
        <a ngbNavLink id="tab-6">Insurance</a>
        <ng-template ngbNavContent>
          <app-patient-insurance-tab (patientIdEmitter)="patientDetailsAfterSave($event)" [patientId]="patientId">
          </app-patient-insurance-tab>
        </ng-template>
      </li>
      <li *ngIf="isShownAuthTab" ngbNavItem="Authorization" [disabled]="!patientId && !patientId">
        <a ngbNavLink> Authorization</a>
        <ng-template ngbNavContent>
          <app-patient-authorization-tab (patientIdEmitter)="patientDetailsAfterSave($event)" [patientId]="patientId">
          </app-patient-authorization-tab>
        </ng-template>
      </li>

      <!-- <li *ngIf="isShownOrderTab" [disabled]="!patientId" ngbNavItem="Order"> -->
        <!-- <img class="img-responsive " src="assets\images\menu icons\tabs icon\order.png"  alt="" height="15"> -->
        <!-- <a ngbNavLink>Order</a>
        <ng-template ngbNavContent>
          <app-patient-order-tab (patientIdEmitter)="patientDetailsAfterSave($event)" [patientId]="patientId">
          </app-patient-order-tab>
        </ng-template>
      </li> -->

      <li ngbNavItem="Claim" [disabled]="!patientId">
        <!-- <img class="img-responsive" src="assets\images\menu icons\tabs icon\Notes.png"  alt="" height="15"> -->
        <a ngbNavLink (click)="changeTab(PatientDetailsTab.Claim)" > Claim</a>
         <ng-template  ngbNavContent>  <!--*ngIf="activeTab === PatientDetailsTab.Claim" -->
          <app-patient-common-claim [patientId]="patientId" ></app-patient-common-claim>
        </ng-template>
      </li>

      <li ngbNavItem="Notes" [disabled]="!patientId">
        <!-- <img class="img-responsive" src="assets\images\menu icons\tabs icon\Notes.png"  alt="" height="15"> -->
        <a ngbNavLink> Notes</a>
        <ng-template ngbNavContent>
          <!-- <app-patient-notes (patientIdEmitter)="patientDetailsAfterSave($event)" [patientID]="patientId">
            </app-patient-notes> -->

          <!-- <app-patient-notes-tab [patientId]="patientId"></app-patient-notes-tab> -->

          <!-- <app-patient-common-notes
            [isAddVisible]="true"
            [patientId]="patientId"
            [noteType]="noteType"
            [docId]="docId"
          ></app-patient-common-notes> -->
          <app-patient-common-notes-v1 [isAddVisible]="true" [patientId]="patientId" [noteType]="noteType"
            [docId]="docId"></app-patient-common-notes-v1>
        </ng-template>
      </li>

      <li ngbNavItem="Reminder" [disabled]="!patientId">
        <a ngbNavLink> Reminder</a>
        <ng-template ngbNavContent>
          <app-patient-reminder (patientIdEmitter)="patientDetailsAfterSave($event)" [patientId]="patientId"></app-patient-reminder>
          <!-- <app-patient-reminder-dashboard [patientId]="patientId">

          </app-patient-reminder-dashboard> -->
        </ng-template>
      </li>

      <!-- <li *ngIf='isShownOrderTab' ngbNavItem="OrderStatus" [disabled]="!patientId"> -->
      <!-- <img class="img-responsive " src="assets\images\menu icons\tabs icon\Order-Status.png"  alt="" height="15"> -->
      <!-- <a ngbNavLink>Order Status</a>
        <ng-template ngbNavContent>
          <app-patient-order-status [patientId]="patientId"></app-patient-order-status>
        </ng-template>
      </li>
   -->
      <!-- <li ngbNavItem="History" [disabled]="!patientId">
        <a ngbNavLink>History</a>
        <ng-template ngbNavContent>
          <app-patient-history [patientId]="patientId"></app-patient-history>
        </ng-template>
      </li> -->
    </ul>

    <div [ngbNavOutlet]="nav"></div>
  </div>
</div>

<button [matMenuTriggerFor]="menu" mat-button matTooltip="Add" class="clr mat-fab-bottom-right rightfloatfabbutton"
  mat-mini-fab>
  <mat-icon class="icon">add</mat-icon>
</button>
<mat-menu #menu="matMenu">
  <button (click)="OpenAddReminderMdl(true)" mat-menu-item matTooltip="Add Reminder">
    <mat-icon class="icon">add</mat-icon>Add Reminder
  </button>
  <button (click)="openAddNotesPopUp()" mat-menu-item matTooltip="Add Note">
    <mat-icon class="icon">add</mat-icon>Add Note
  </button>
  <button (click)="openViewNotesPopup()" mat-menu-item matTooltip="View Notes">
    <mat-icon class="icon">remove_red_eye</mat-icon>View Notes
  </button>
</mat-menu>
