<!-- Exchange / Pickup List -->
<div class="container-fluid">
  <div class="row bg">
    <div class="col-12">
      <div
        class="d-flex align-items-center justify-content-between mb-3"
        style="margin-top: -15px"
      >
        <h3 class="font-weight-bolder mb-0">Exchange/Pickup List</h3>
        <div class="page-title-box page-title-right">
          <ol class="breadcrumbs m-0 align-items-center">
            <form [formGroup]="branchForm">
              <mat-form-field class="pr-3">
                <mat-label>Branch</mat-label>
                <mat-select #selectBranch formControlName="drpBranch" multiple [(value)]="organizationUnitName">
                  <mat-option>
                    <ngx-mat-select-search
                      placeholderLabel="Search"
                      noEntriesFoundLabel="No Matches found"
                      formControlName="txtBranch"
                    ></ngx-mat-select-search>
                  </mat-option>
  
                  <mat-option #selectAllBranch (click)="allBranchSelect()" value="0">All</mat-option>
  
                  <mat-option *ngFor="let branch of brnachList | async" [value]="branch.id">
                    {{ branch.organizationUnitName }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <button
                class="mr-2 buttonColor"
                [disabled]="
                  dataSource && dataSource?.data && dataSource?.data?.length === 0
                "
                mat-raised-button
                (click)="
                  exporter.exportTable('xlsx', {
                    fileName: 'WIP List',
                    sheet: 'WIPList',
                    Props: { Author: 'QSecure' }
                  })
                "
              >
                Export
              </button>
            </form>

            <!-- <li class="breadcrumb-item mt-2 pl-2">Exchange-Pickup List</li>
            <li class="breadcrumb-item mt-2">Patient</li> -->
          </ol>
        </div>
      </div>
    </div>

    <div class="col-lg-12 pb-3">
      <form [formGroup]="pickUpForm">
        <div
          class="table table-responsive example-container mat-elevation-z4 pt-0 mt-0"
        >
          <table
            mat-table
            matTableExporter
            #exporter="matTableExporter"
            matSort
            [dataSource]="dataSource"
            class="mat-elevation-z8 pt-0 mt-0"
            style="margin-left: 15px !important"
          >
            <!-- Exchange ID-->
            <ng-container matColumnDef="defaultExchangeId">
              <th
                class="header"
                mat-header-cell
                mat-sort-header
                *matHeaderCellDef
              >
                <!-- Exchange Id -->
                <mat-form-field class="filter">
                  <input
                    matInput
                    formControlName="defaultExchangeId"
                    autocomplete="new-defaultExchangeId"
                  />
                  <mat-placeholder class="center">Exchange Id</mat-placeholder>
                </mat-form-field>
              </th>
              <td mat-cell *matCellDef="let element">
                <a
                  matTooltip="Click to Schedule"
                  class="eyeCursorclass"
                  [routerLink]="
                    '/orders/saleorderCreateEditTab/' +
                    element?.patientId +
                    '/' +
                    element?.orderId
                  "
                  target="_blank"
                >
                  {{ element?.exchangedItem }}
                </a>
              </td>
            </ng-container>
            <!-- Patient Id -->
            <ng-container matColumnDef="defaultPatientId">
              <th
                class="header"
                mat-header-cell
                mat-sort-header
                *matHeaderCellDef
              >
                <!-- Default Patient Id -->
                <mat-form-field class="filter">
                  <input
                    matInput
                    formControlName="defaultPatientId"
                    autocomplete="new-defaultPatientId"
                  />
                  <mat-placeholder class="center"> Patient Id</mat-placeholder>
                </mat-form-field>
              </th>
              <td mat-cell *matCellDef="let element">
                {{ element?.defaultPatientId }}
              </td>
            </ng-container>
            <!-- SaleOrder Id -->
            <ng-container matColumnDef="defaultSaleOrderId">
              <th
                class="header"
                mat-header-cell
                mat-sort-header
                *matHeaderCellDef
              >
                <!-- Order Id -->
                <mat-form-field class="filter">
                  <input
                    matInput
                    formControlName="defaultSaleOrderId"
                    autocomplete="new-defaultSaleOrderId"
                  />
                  <mat-placeholder class="center"> Order Id</mat-placeholder>
                </mat-form-field>
              </th>
              <td mat-cell *matCellDef="let element">
                {{ element?.defaultSaleOrderId }}
              </td>
            </ng-container>

            <!--Patient Name-->
            <ng-container matColumnDef="patientName">
              <th
                class="header"
                mat-header-cell
                mat-sort-header
                *matHeaderCellDef
              >
                <!-- Patient Name -->
                <mat-form-field class="filter">
                  <input
                    matInput
                    formControlName="patientName"
                    autocomplete="new-patientName"
                  />
                  <mat-placeholder class="center">Patient Name</mat-placeholder>
                </mat-form-field>
              </th>
              <td mat-cell *matCellDef="let element">
                {{ element?.patientname }}
              </td>
            </ng-container>
             <!-- Branch-->
             <ng-container matColumnDef="branch">
              <th
                class="header"
                mat-header-cell
                mat-sort-header
                *matHeaderCellDef
              >
             <mat-form-field class="filter">
              <input
                matInput
                formControlName="branch"
                autocomplete="new-branch"
              />
              <mat-placeholder class="center">Branch</mat-placeholder>
             </mat-form-field>
            </th>
             <td mat-cell *matCellDef="let element">

             </td>
            </ng-container>
            <!-- Pickup Ordered Date -->
            <ng-container matColumnDef="pickupOrderedDate">
              <th
                class="header"
                mat-header-cell
                mat-sort-header
                *matHeaderCellDef
              >
                <!-- Pickup Ordered Date -->
                <mat-form-field class="filter">
                  <input
                    matInput
                    formControlName="pickupOrderedDate"
                    autocomplete="new-pickupOrderedDate"
                  />
                  <mat-placeholder class="center"
                    >Pickup Ordered Date</mat-placeholder
                  >
                </mat-form-field>
              </th>
              <td mat-cell *matCellDef="let element">
                {{ element?.pickupOrderedDate | date : "MM/dd/yyyy" : "en_US" }}
              </td>
            </ng-container>

            <!--Pickup Status-->
            <ng-container matColumnDef="pickupStatus">
              <th
                class="header"
                mat-header-cell
                mat-sort-header
                *matHeaderCellDef
              >
                <!-- Pickup Status -->
                <mat-form-field class="filter">
                  <input
                    matInput
                    formControlName="pickupStatus"
                    autocomplete="new-pickupStatus"
                  />
                  <mat-placeholder class="center"
                    >Pickup Status</mat-placeholder
                  >
                </mat-form-field>
              </th>
              <td mat-cell *matCellDef="let element">
                <div [ngSwitch]="element?.pickupStatus">
                  <div *ngSwitchCase="'ReturnInitiated'">
                    <!-- <button class="buttonColor1">
                    Pickup Initiated
                  </button>   -->
                    <mat-label class="custom-label-1">
                      Pickup Initiated
                    </mat-label>
                  </div>
                  <div *ngSwitchCase="'ReturnCompleted'">
                    <mat-label class="custom-label-bg">
                      Pickup Completed
                    </mat-label>
                  </div>
                  <div *ngSwitchCase="'ReturnCancelled'">Pickup Completed</div>
                  <div *ngSwitchDefault></div>
                </div>
                <!-- <button class="buttonColor">Test Status</button> -->
              </td>
            </ng-container>
            <!-- Pickup Completed Date -->
            <ng-container matColumnDef="pickupCompletedDate">
              <th
                class="header"
                mat-header-cell
                mat-sort-header
                *matHeaderCellDef
              >
                <!-- Pickup Completed Date -->
                <mat-form-field class="filter">
                  <input
                    matInput
                    formControlName="pickupCompletedDate"
                    autocomplete="new-pickupCompletedDate"
                  />
                  <mat-placeholder class="center"
                    >Pickup Completed Date</mat-placeholder
                  >
                </mat-form-field>
              </th>
              <td mat-cell *matCellDef="let element">
                {{
                  element.pickupCompletedDate
                    ? (element.pickupCompletedDate
                      | date : "MM/dd/yyyy" : "en_US")
                    : "--"
                }}
              </td>
            </ng-container>

            <!-- Options -->
            <!-- <ng-container matColumnDef="options">
              <th
                class="header"
                mat-header-cell
                mat-sort-header
                *matHeaderCellDef
              >
                Options
              </th>
              <td mat-cell *matCellDef="let element">
                <div [ngSwitch]="'PickupInitiated'">
                  <div *ngSwitchCase="'PickupCompleted'"></div>
                  <div *ngSwitchDefault>
                    <mat-slide-toggle
                      (toggleChange)="onToggleChange($event)"
                      formControlName="chkPickupCompleted"
                      class="customThemeClass toggle"
                    >
                    </mat-slide-toggle>
                  </div>
                </div>
              </td>
            </ng-container> -->

            <ng-container matColumnDef="action">
              <th
                class="header"
                mat-header-cell
                mat-sort-header
                *matHeaderCellDef
              >
                Action
              </th>
              <td mat-cell *matCellDef="let element">
                <div [ngSwitch]="element?.pickupStatus">
                  <div *ngSwitchCase="'ReturnInitiated'">
                    <div class="icon-container">
                      <mat-icon
                        class="custom-popup-icon"
                        title="Click to Complete"
                        (click)="uploadDoc(element)"
                        >launch</mat-icon
                      >
                    </div>
                  </div>
                  <div *ngSwitchCase="'ReturnCompleted'">
                    <a
                      class="spl-link"
                      [routerLink]="
                        '/ViewDocumentPerviewByBlobId/' +
                        element?.blobname +
                        '/' +
                        6
                      "
                      target="_blank"
                    >
                      <div class="icon-container">
                        <mat-icon
                          class="custom-popup-icon"
                          title="Click to View Document"
                        >
                          file_download</mat-icon
                        >
                      </div>
                    </a>
                  </div>

                  <div *ngSwitchDefault></div>
                </div>
              </td>
            </ng-container>

            <!-- No Records-->
            <ng-container matColumnDef="noRecords">
              <td
                class="text-align-center"
                mat-footer-cell
                *matFooterCellDef
                colspan="6"
              >
                <div *ngIf="!isLoading">
                  {{ "No Records Found" }}
                </div>
                <div [hidden]="!isLoading">
                  <mat-spinner [diameter]="220"></mat-spinner>
                </div>
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="arrDisplayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: arrDisplayedColumns"></tr>
          </table>
          <div
            class="no-records text-align-center"
            *ngIf="!isLoading && dataSource?.data?.length === 0"
          >
            <div class="message py-3">
              {{ "No Records Found" }}
            </div>
          </div>
          <div class="no-records text-align-center" *ngIf="isLoading">
            <div class="py-3">
              <mat-spinner [diameter]="220"></mat-spinner>
            </div>
          </div>
        </div>
        <mat-paginator
          #MatPaginator
          [length]="dataSource?.data?.length"
          class="mat-elevation-z4"
          [pageSize]="10"
          [pageIndex]="0"
          showFirstLastButtons
          [pageSizeOptions]="[5, 10, 25, 100]"
        >
        </mat-paginator>
      </form>
    </div>
  </div>
</div>
