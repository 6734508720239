<div class="row">
  <div class="col-xl-12">
    <h2>{{ policyModalTitle }} 1</h2>
  </div>
</div>
<mat-dialog-content class="mat-typography" style="overflow-x: hidden">
  <div class="row">
    <div class="col-xl-6">
      <form [formGroup]="policyForm">
        <mat-card-content>
          <div class="row">
            <div class="col-xl-12">
              <h4 class="customThemeClass"><b>Payor Details</b></h4>
            </div>
          </div>
          <div class="row">
            <mat-form-field class="col-xl-4">
              <mat-label>Payor Level<span class="asterisk">*</span></mat-label>
              <mat-select formControlName="drpPayor" [(value)]="payorlevel">
                <mat-option *ngFor="let payor of payorLevel" [value]="payor.payorLevelName">
                  {{ payor.payorLevelName }}
                </mat-option>
              </mat-select>
              <button mat-button (click)="payorlevel = undefined; $event.stopPropagation()" *ngIf="payorlevel" matSuffix
                (click)="clearPayorlevel()" mat-icon-button >
                <mat-icon>close</mat-icon>
              </button>
              <mat-error *ngIf="
                  policyForm?.get('drpPayor')?.touched &&
                  policyForm?.get('drpPayor')?.errors?.required
                ">
                Payor Level is a required field!
              </mat-error>
            </mat-form-field>
            <mat-form-field class="col-xl-8">
              <mat-label>Insurance<span class="asterisk">*</span></mat-label>
              <mat-select formControlName="drpInsurance" [(value)]="Insurance" #singleSelect>
                <mat-option>
                  <ngx-mat-select-search placeholderLabel="Search" noEntriesFoundLabel="No Matches found"
                    formControlName="txtInsuranceFilter"></ngx-mat-select-search>
                </mat-option>
                <mat-option *ngFor="let insurance of filteredInsuranceTypes | async" [value]="insurance.name">
                  {{ insurance.name }}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="
                  policyForm?.get('drpInsurance')?.touched &&
                  policyForm?.get('drpInsurance')?.errors?.required
                ">
                Insurance is a required field!
              </mat-error>
              <button mat-button (click)="Insurance = undefined; $event.stopPropagation()" *ngIf="Insurance" matSuffix
                (click)="clearInsurance()" mat-icon-button >
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field>
            <mat-form-field class="col-xl-8">
              <mat-label>Payer Id<span class="asterisk">*</span></mat-label>
              <mat-select formControlName="txtPolicyInfoPayorId" [(value)]="Payers" #singleSelect>
                <mat-option>
                  <ngx-mat-select-search placeholderLabel="Search" noEntriesFoundLabel="No Matches found"
                    formControlName="txtPayerFilter"></ngx-mat-select-search>
                </mat-option>
                <mat-option *ngFor="let insurance of filteredPayerTypes | async" [value]="insurance.name">
                  {{ insurance.name }}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="
                  policyForm?.get('txtPolicyInfoPayorId')?.touched &&
                  policyForm?.get('txtPolicyInfoPayorId')?.errors?.required
                ">
                Payer Id is a required field!
              </mat-error>
              <button mat-button (click)="Payers = undefined; $event.stopPropagation()" *ngIf="Payers" matSuffix
                (click)="clearPayers()" mat-icon-button >
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field>
            <mat-form-field class="col-xl-4">
              <mat-label>Policy No<span class="asterisk">*</span></mat-label>
              <input autocomplete="new-txtPolicyInfoPolicyNo" maxlength="40" formControlName="txtPolicyInfoPolicyNo" matInput type="text"
                pattern="[a-zA-Z0-9]*" />
              <mat-error *ngIf="
                  policyForm?.get('txtPolicyInfoPolicyNo')?.touched &&
                  policyForm?.get('txtPolicyInfoPolicyNo')?.errors?.pattern
                ">
                Enter Only Alphanumeric Only!
              </mat-error>
              <mat-error *ngIf="
                  policyForm?.get('txtPolicyInfoPolicyNo')?.touched &&
                  policyForm?.get('txtPolicyInfoPolicyNo')?.errors?.required
                ">
                Policy No is a required field!
              </mat-error>
            </mat-form-field>
          </div>
          <div class="row">
            <mat-form-field class="col-xl-12">
              <mat-label>Note</mat-label>
              <input autocomplete="new-txtNote" maxlength="1000" formControlName="txtNote" matInput type="text" />
            </mat-form-field>
          </div>
          <div class="row">
            <div class="col-xl-12">
              <h4 class="customThemeClass"><b>Insured Details</b></h4>
            </div>
          </div>
          <div class="row">
            <div class="col-4">
              <br />
              <mat-checkbox (click)="copyPatient()" formControlName="chkCopypatientinfo">Copy Patient Info
              </mat-checkbox>
            </div>
            <mat-form-field class="col-4">
              <mat-label>Last Name <span class="asterisk">*</span></mat-label>
              <input autocomplete="new-txtInsuredLastName" formControlName="txtInsuredLastName" matInput type="text" maxlength="40" />
              <mat-error *ngIf="
                  policyForm?.get('txtInsuredLastName')?.touched &&
                  policyForm?.get('txtInsuredLastName')?.errors?.sAlphabets
                ">
                Enter only Alphabets!
              </mat-error>
              <mat-error *ngIf="
                  policyForm?.get('txtInsuredLastName')?.touched &&
                  policyForm?.get('txtInsuredLastName')?.errors?.required
                ">
                Last Name is a required field!
              </mat-error>
            </mat-form-field>
            <mat-form-field class="col-4">
              <mat-label>First Name <span class="asterisk">*</span></mat-label>
              <input autocomplete="new-txtInsuredFirst" formControlName="txtInsuredFirst" matInput type="text" maxlength="40" />
              <mat-error *ngIf="
                  policyForm?.get('txtInsuredFirst')?.touched &&
                  policyForm?.get('txtInsuredFirst')?.errors?.sAlphabets
                ">
                Enter only Alphabets!
              </mat-error>
              <mat-error *ngIf="
                  policyForm?.get('txtInsuredFirst')?.touched &&
                  policyForm?.get('txtInsuredFirst')?.errors?.required
                ">
                First Name is a required field!
              </mat-error>
            </mat-form-field>
            <mat-form-field class="col-4">
              <mat-label>Middle Name</mat-label>
              <input autocomplete="new-txtInsuredMiddle" formControlName="txtInsuredMiddle" matInput type="text" maxlength="40" />
              <mat-error *ngIf="
                  policyForm?.get('txtInsuredMiddle')?.touched &&
                  policyForm?.get('txtInsuredMiddle')?.errors?.sAlphabets
                ">
                Enter only Alphabets!
              </mat-error>
            </mat-form-field>
            <mat-form-field class="col-4">
              <mat-label>Birth Date </mat-label>
              <input autocomplete="new-txtInsuredBirthDate" [min]="BirthdateMinDate" formControlName="txtInsuredBirthDate" matInput [matDatepicker]="myPicker3"
                placeholder="MM/DD/YYYY" maxlength="40" />
              <mat-datepicker-toggle matSuffix [for]="myPicker3">
              </mat-datepicker-toggle>
              <mat-datepicker #myPicker3></mat-datepicker>
              <mat-error *ngIf="
                  policyForm?.get('txtInsuredBirthDate')?.touched &&
                  policyForm?.get('txtInsuredBirthDate')?.errors
                ">
                Enter Valid Date!
              </mat-error>
              <mat-error *ngIf="
                  policyForm?.get('txtInsuredBirthDate')?.touched &&
                  policyForm?.get('txtInsuredBirthDate')?.errors?.required
                ">
                Birth Date is a required field!
              </mat-error>
            </mat-form-field>
            <mat-form-field class="col-4">
              <mat-label>Gender </mat-label>
              <mat-select formControlName="drpGender" [(value)]="gender">
                <mat-option *ngFor="let gender of drpgenderLoop" [value]="gender.genderShortCode">
                  {{ gender.genderName }}
                </mat-option>
              </mat-select>
              <button mat-button (click)="gender = undefined; $event.stopPropagation()" *ngIf="gender" matSuffix
                (click)="clearGender()" mat-icon-button >
                <mat-icon>close</mat-icon>
              </button>
              <mat-error *ngIf="
                  policyForm?.get('drpGender')?.touched &&
                  policyForm?.get('drpGender')?.errors?.required
                ">
                Gender is a required field!
              </mat-error>
            </mat-form-field>
            <mat-form-field class="col-4">
              <mat-label>Address </mat-label>
              <textarea autocomplete="new-txtAddress1" formControlName="txtAddress1" rows="3" matInput maxlength="400"></textarea>
              <mat-error *ngIf="
                  policyForm?.get('txtAddress1')?.touched &&
                  policyForm?.get('txtAddress1')?.errors?.required
                ">
                Address is a required field!
              </mat-error>
            </mat-form-field>
            <mat-form-field class="col-4">
              <mat-label>Country </mat-label>
              <mat-select formControlName="txtInsuredCountry" (selectionChange)="onChangeCountry($event.value)"
                [(value)]="InsuredCountry" #singleSelect>
                <mat-option>
                  <ngx-mat-select-search placeholderLabel="Search" noEntriesFoundLabel="No Matches found"
                    formControlName="txtInsuredCountryFilter"></ngx-mat-select-search>
                </mat-option>
                <mat-option *ngFor="let country of filteredInsuredCountries | async" [value]="country.countryShortName">
                  {{ country.countryName }}
                </mat-option>
              </mat-select>
              <button mat-button *ngIf="policyForm?.value?.txtInsuredCountry"
                (click)="clearInsuredCountry(); $event?.stopPropagation()" matSuffix mat-icon-button >
                <mat-icon>close</mat-icon>
              </button>
              <mat-error *ngIf="
                  policyForm?.get('txtInsuredCountry')?.touched &&
                  policyForm?.get('txtInsuredCountry')?.errors?.required
                ">
                Country is a required field!
              </mat-error>
            </mat-form-field>
            <mat-form-field class="col-4">
              <mat-label>State </mat-label>
              <mat-select formControlName="txtInsuredState" (selectionChange)="onChangeState($event.value, '')"
                [(value)]="InsuredState" #singleSelect>
                <mat-option>
                  <ngx-mat-select-search placeholderLabel="Search" noEntriesFoundLabel="No Matches found"
                    formControlName="txtInsuredStateFilter"></ngx-mat-select-search>
                </mat-option>
                <mat-option *ngFor="let state of filteredInsuredStates | async" [value]="state.stateShortName">
                  {{ state.stateName }}
                </mat-option>
              </mat-select>
              <button mat-button *ngIf="policyForm?.value?.txtInsuredState"
                (click)="clearInsuredState(); $event?.stopPropagation()" matSuffix mat-icon-button >
                <mat-icon>close</mat-icon>
              </button>
              <mat-error *ngIf="
                  policyForm?.get('txtInsuredState')?.touched &&
                  policyForm?.get('txtInsuredState')?.errors?.required
                ">
                State is a required field!
              </mat-error>
            </mat-form-field>
            <mat-form-field class="col-4">
              <mat-label>City </mat-label>
              <mat-select formControlName="txtInsuredCity" [(value)]="InsuredCity" #singleSelect>
                <mat-option>
                  <ngx-mat-select-search placeholderLabel="Search" noEntriesFoundLabel="No Matches found"
                    formControlName="txtInsuredCityFilter"></ngx-mat-select-search>
                </mat-option>
                <mat-option *ngFor="let city of filteredInsuredCities | async" [value]="city.cityName">
                  {{ city.cityName }}
                </mat-option>
              </mat-select>
              <button mat-button *ngIf="policyForm?.value?.txtInsuredCity" matSuffix
                (click)="clearInsuredCity(); $event?.stopPropagation()" mat-icon-button >
                <mat-icon>close</mat-icon>
              </button>
              <mat-error *ngIf="
                  policyForm?.get('txtInsuredCity')?.touched &&
                  policyForm?.get('txtInsuredCity')?.errors?.required
                ">
                City is a required field!
              </mat-error>
            </mat-form-field>


            <mat-form-field class="col-4">
              <mat-label> Phone </mat-label>
              <input autocomplete="new-txtInsuredPhone" formControlName="txtInsuredPhone" matInput maxlength="40" prefix="{{ phoneCode }}-"
                mask="(000)-000-0000" type="text" />
              <mat-error *ngIf="
                  policyForm?.get('txtInsuredPhone')?.touched &&
                  policyForm?.get('txtInsuredPhone')?.errors
                ">
                Enter Minimum 10 Digits!
              </mat-error>
              <mat-error *ngIf="
                  policyForm?.get('txtInsuredPhone')?.touched &&
                  policyForm?.get('txtInsuredPhone')?.errors?.required
                ">
                Phone Number is a required field!
              </mat-error>
            </mat-form-field>

            <mat-form-field class="col-4">
              <mat-label>Zip Code</mat-label>
              <input autocomplete="new-txtInsuredPostalCode" formControlName="txtInsuredPostalCode" matInput type="text"  pattern="[0-9-]*"
                maxlength="10" class="zip-code-input" />

                <mat-error *ngIf=" policyForm?.get('txtInsuredPostalCode')?.touched &&
                policyForm?.get('txtInsuredPostalCode')?.errors?.pattern">
                    Enter only Numbers!
                </mat-error>

              <mat-error *ngIf="
                  policyForm?.get('txtInsuredPostalCode')?.touched &&
                  policyForm?.get('txtInsuredPostalCode')?.errors?.required
                ">
                Zip Code is a required field!
              </mat-error>
            </mat-form-field>

            <!-- <mat-form-field class="col-4">
              <mat-label>Zip Code</mat-label>
              <input autocomplete="new-txtInsuredPostalCode" formControlName="txtInsuredPostalCode" matInput type="text" (keypress)="numberAndAlphaOnly($event)"
                maxlength="10" />
              <mat-error *ngIf="
                  policyForm?.get('txtInsuredPostalCode')?.touched &&
                  policyForm?.get('txtInsuredPostalCode')?.errors?.number
                ">
                Enter Number Only!
              </mat-error>
              <mat-error *ngIf="
                  policyForm?.get('txtInsuredPostalCode')?.touched &&
                  policyForm?.get('txtInsuredPostalCode')?.errors?.required
                ">
                Zip Code is a required field!
              </mat-error>
            </mat-form-field> -->
            <mat-form-field class="col-4">
              <mat-label>Employerd Id</mat-label>
              <input autocomplete="new-txtPolicyInfoEmployerdId" formControlName="txtPolicyInfoEmployerdId" matInput type="text" maxlength="10" />
              <mat-error *ngIf="
                  policyForm?.get('txtPolicyInfoEmployerdId')?.touched &&
                  policyForm?.get('txtPolicyInfoEmployerdId')?.errors?.number
                ">
                Enter Number Only!
              </mat-error>
            </mat-form-field>
          </div>
        </mat-card-content>
      </form>
    </div>

    <div class="col-xl-6">
      <mat-card-content>
        <div class="row">
          <div class="col-xl-5">
            <h4 class="customThemeClass"><b>Insurance Verification</b></h4>
          </div>
          <div class="col-xl-7">
            <!-- [disabled]='!isShownManualEligibilitycheckButton' -->
            <button *ngIf="isPatientLockedByCurrentUser" mat-button (click)="openPverifyModal()"
              class="buttonColor waves-effect font-size-12 mb-2 mr-2 btn-sm">
              <!-- <i class="bx bx-copy-alt font-size-16 align-middle mr-2"></i> -->
              <i class="fa fa-check mr-1"></i>
              Electronic Eligibility Check
            </button>
          </div>
        </div>
        <div class="row">
          <div class="col-xl-3">
            <h4 class="customThemeClass"><b>Last Eligibility Check</b></h4>
          </div>
          <!-- <div class="col-xl-5">
       <button mat-button (click)="openPverifyModal()"
    class="buttonColor waves-effect font-size-12 mb-2 mr-2 btn-sm">
    <i class="fa fa-check mr-1" ></i>
    Electronic
    Eligibility Check </button>
     </div> -->
          <div *ngIf="isShownButton && isPatientLockedByCurrentUser" class="col-xl-5">
            <!-- [disabled]='!isShownManualEligibilitycheckButton' -->
            <button mat-button (click)="openVerificationModal()"
              class="buttonColor waves-effect font-size-12 mb-2 mr-2 btn-sm">
              <!-- <i class="bx bx-copy-alt font-size-16 align-middle mr-2"></i> -->
              <i class="fa fa-check mr-1"></i>
              Manual Eligibility Check
            </button>
          </div>
          <div *ngIf="isShownEligibilityButtton" class="col-xl-4">
            <!-- [disabled]='!isShownManualEligibilitycheckButton' -->
            <button (click)="openEligibilityHistoryModal()" mat-button
              class="buttonColor waves-effect font-size-12 mb-2 mr-2 btn-sm">
              <!-- <i class="bx bx-copy-alt font-size-16 align-middle mr-2"></i> -->
              <i class="fa fa-history mr-1"></i>
              Eligibility History
            </button>
          </div>
        </div>
        <form [formGroup]="policyForm">
          <div class="row">
            <div class="col-12">
              <h4><b>Status</b> : {{ statusType }}</h4>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <h4><b>Last Checked On</b> : {{ lastChecked }}</h4>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <h4><b>Last Checked By</b> : {{ checkedBy }}</h4>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <h4><b>Verified Method</b> : {{ method }}</h4>
            </div>
          </div>
          <div class="row" *ngIf="verifiedType !== null && method !== 'Manual'">
            <div class="col-12">
              <h4><b>Verified Type</b> : {{ verifiedType }}</h4>
            </div>
          </div>
        </form>
      </mat-card-content>
    </div>
  </div>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button *ngIf="isPatientLockedByCurrentUser" mat-button class="buttonColor"
    [disabled]="saveButtonHide || !(!policyForm.invalid && policyForm.dirty)"
    (click)="getPatientDetailsForPolicySave()">
    Save
    <mat-icon *ngIf="isShowSpinner">
      <mat-spinner class="spinner-border spinner-border-sm" diameter="20">
      </mat-spinner>
    </mat-icon>
  </button>
  <button class="buttonCancelColor" mat-button mat-dialog-close>Close</button>
  <!-- <button mat-button [mat-dialog-close]="true" cdkFocusInitial>Install</button> -->
</mat-dialog-actions>
