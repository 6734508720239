<mat-dialog-content class="mat-typography">
  <div class="row align-items-center">
    <div class="col-lg-9">
      <h4 class="customThemeClass">
        <b>WIP - {{ defaultWIPIds }}</b>
      </h4>
    </div>

    <div class="col-lg-3" style="text-align-last: right">
      <!-- <button mat-icon-button mat-dialog-close class="close-button"> -->
      <mat-icon mat-dialog-close>close</mat-icon>
      <!-- </button> -->
    </div>
  </div>

  <form [formGroup]="wipAllocationForm">
    <div class="row">
      <!-- Process -->
      <!-- <mat-form-field class="col-md-4">
        <mat-label>Process<span class="asterisk">*</span></mat-label>
        <mat-select
          formControlName="drpProcess"
          autocomplete="off"
          [(value)]="process"
          maxlength="40"
          (selectionChange)="loadUsersbyRole()"
        >
          <mat-option
            *ngFor="let process of drpProcessLoop"
            [value]="process.id"
          >
            {{ process.process }}
          </mat-option>
        </mat-select>
        <mat-error
          class="spanAlignment"
          *ngIf="
            wipAllocationForm?.get('drpProcess')?.touched &&
            wipAllocationForm?.get('drpProcess')?.errors?.required
          "
        >
          Process is a required field!
        </mat-error>
      </mat-form-field> -->

      <!-- WIP Status -->
      <mat-form-field class="col-md-6">
        <mat-label>Status<span class="asterisk">*</span></mat-label>
        <mat-select formControlName="drpStatus"
        [(value)]="wipStatus"
        (selectionChange)="loadUsersbyStatus()">
          <mat-option>
            <ngx-mat-select-search
              placeholderLabel="Search"
              noEntriesFoundLabel="No Matches found"
              formControlName="txtStatusFilter"
            ></ngx-mat-select-search>
          </mat-option>
          <mat-option
            *ngFor="let status of filteredWipStatus | async"
            [value]="status?.id"
          >
            {{ status?.wipStatus }}
          </mat-option>
        </mat-select>
        <mat-error
          class="spanAlignment"
          *ngIf="
            wipAllocationForm?.get('drpStatus')?.touched &&
            wipAllocationForm?.get('drpStatus')?.errors?.required
          "
        >
          Status is a required field!
        </mat-error>
      </mat-form-field>

      <!-- Agent -->
      <mat-form-field class="col-md-6">
        <mat-label>Agent<span class="asterisk">*</span></mat-label>
        <mat-select formControlName="agent">
          <mat-option>
            <ngx-mat-select-search
              placeholderLabel="Search"
              noEntriesFoundLabel="No Matches found"
              formControlName="txtAgentFilter"
            ></ngx-mat-select-search>
          </mat-option>
          <mat-option
            *ngFor="let users of filteredIdentityUsers | async"
            [value]="users.userId"
          >
            {{ users.name }}
          </mat-option>
        </mat-select>
        <mat-error
          class="spanAlignment"
          *ngIf="
            wipAllocationForm?.get('agent')?.touched &&
            wipAllocationForm?.get('agent')?.errors?.required
          "
        ></mat-error>
      </mat-form-field>

      <!-- Notes -->
      <mat-form-field class="col-12">
        <mat-label>Notes<span class="asterisk">*</span></mat-label>
        <textarea
          formControlName="txtComment"
          type="text"
          rows="4"
          maxlength="5000"
          autocomplete="off"
          matInput
          [(ngModel)]="notesText"
          (input)="updateCharacterCount()"
        ></textarea>
        <div class="char-count">{{ notesText?.length }} / 5000</div>
        <mat-error
          class="spanAlignment"
          *ngIf="
            wipAllocationForm?.get('txtComment')?.touched &&
            wipAllocationForm?.get('txtComment')?.errors?.required
          "
          >Notes is Required field</mat-error
        >
      </mat-form-field>

      <!-- Buttons -->
      <div class="col-lg-12 pb-3 d-flex justify-content-center">
        <ng-container class="col-12" *ngIf="!wipAllocationForm?.disabled">
          <button
            [disabled]="!!wipAllocationForm?.invalid"
            mat-button
            class="buttonColor"
            (click)="assignTask()"
          >
            Save
          </button>
        </ng-container>
      </div>

      <!-- Notes Table -->
      <div class="col-12 pb-2" *ngIf="data.patientId.length === 1">
        <div class="col-lg-9">
          <h4 class="customThemeClass">
            <b>Notes</b>
          </h4>
        </div>
        <form [formGroup]="noteSearchForm">
          <div
            class="table table-responsive example-container mat-elevation-z4 mb-0 pb-0"
          >
            <table
              mat-table
              class="pt-0 mt-0"
              [dataSource]="dataSourceNotes"
              matSort
            >
              <!--Agent-->
              <ng-container matColumnDef="agent">
                <th
                  class="header"
                  mat-header-cell
                  mat-sort-header
                  *matHeaderCellDef
                >
                  <mat-form-field>
                    <input
                      matInput
                      (keydown.space)="$event.stopPropagation()"
                      (click)="$event.stopPropagation()"
                      formControlName="txtAgent"
                      autocomplete="new-txtAgent"
                    />
                    <mat-placeholder class="center">Agent</mat-placeholder>
                  </mat-form-field>
                </th>
                <td mat-cell *matCellDef="let element">
                  {{ element?.userName }}
                </td>
              </ng-container>

              <!-- Note Type-->
              <ng-container matColumnDef="noteType">
                <th
                  class="header"
                  mat-header-cell
                  mat-sort-header
                  *matHeaderCellDef
                >
                  <mat-form-field>
                    <input
                      matInput
                      (keydown.space)="$event.stopPropagation()"
                      (click)="$event.stopPropagation()"
                      formControlName="txtNoteType"
                      autocomplete="new-txtNoteType"
                    />
                    <mat-placeholder class="center">Note Type</mat-placeholder>
                  </mat-form-field>
                </th>
                <td mat-cell *matCellDef="let element">
                  {{ element?.customNoteType }}
                </td>
              </ng-container>

              <!-- Description -->
              <ng-container matColumnDef="description">
                <th
                  class="header"
                  mat-header-cell
                  mat-sort-header
                  *matHeaderCellDef
                >
                  <mat-form-field>
                    <input
                      matInput
                      (keydown.space)="$event.stopPropagation()"
                      (click)="$event.stopPropagation()"
                      formControlName="txtDescription"
                      autocomplete="new-txtDescription"
                    />
                    <mat-placeholder class="center"
                      >Description</mat-placeholder
                    >
                  </mat-form-field>
                </th>
                <td mat-cell *matCellDef="let element">
                  <div
                    [ngClass]="{ ellipsis: element?.description.length > 13 }"
                  >
                    {{ element?.description }}
                  </div>
                  <div *ngIf="element?.description.length > 13" class="redTxt">
                    <i
                      style="margin-left: -12px !important"
                      (click)="showMoreBtn(element)"
                      target="_blank"
                      class="fa fa-eye pointer editBtnColor pl-3"
                    ></i>
                  </div>
                </td>
              </ng-container>

              <!-- Created Date-->
              <ng-container matColumnDef="createdDate">
                <th
                  class="header"
                  mat-header-cell
                  mat-sort-header
                  *matHeaderCellDef
                >
                  <mat-form-field>
                    <input
                      matInput
                      (keydown.space)="$event.stopPropagation()"
                      (click)="$event.stopPropagation()"
                      formControlName="txtCreatedDate"
                      autocomplete="new-txtCreatedDate"
                    />
                    <mat-placeholder class="center"
                      >Created Date</mat-placeholder
                    >
                  </mat-form-field>
                </th>
                <td mat-cell *matCellDef="let element">
                  {{
                    element?.dateCreated | date : "MM/dd/yyyy h:mm a" : "en_US"
                  }}
                </td>
              </ng-container>

              <tr
                mat-header-row
                *matHeaderRowDef="arrDisplayedCommentsColumns"
              ></tr>
              <tr
                mat-row
                *matRowDef="let row; columns: arrDisplayedCommentsColumns"
              ></tr>
            </table>
            <div
              *ngIf="
                !(
                  dataSourceNotes &&
                  dataSourceNotes?.data &&
                  dataSourceNotes?.data?.length !== 0
                )
              "
              class="no-records text-align-center"
            >
              <div class="message py-3" *ngIf="!isLoading">
                {{ "No Records Found" }}
              </div>
              <div class="py-3" [hidden]="!isLoading">
                <mat-spinner [diameter]="220"></mat-spinner>
              </div>
            </div>
          </div>
          <mat-paginator
            #MatPaginator
            [length]="dataSourceNotes?.data?.length"
            class="mat-elevation-z4"
            [pageSize]="10"
            [pageIndex]="0"
            showFirstLastButtons
            [pageSizeOptions]="[5, 10, 25, 100]"
          >
          </mat-paginator>
        </form>
      </div>
    </div>
  </form>
</mat-dialog-content>
