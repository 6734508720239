import { RestService } from '@abp/ng.core';
import type { PagedAndSortedResultRequestDto, PagedResultDto } from '@abp/ng.core';
import { Injectable } from '@angular/core';
import type { CreateUpdatePatientBalanceDTO, PatientBalanceDTO, agingWiseAmount, billingDetailsForPP } from '../dto/models';

@Injectable({
  providedIn: 'root',
})
export class PatientBalanceService {
  apiName = 'billingManagement';

  create = (input: CreateUpdatePatientBalanceDTO) =>
    this.restService.request<any, PatientBalanceDTO>({
      method: 'POST',
      url: '/api/billingApp/patient-balance',
      body: input,
    },
    { apiName: this.apiName });

  delete = (id: string) =>
    this.restService.request<any, void>({
      method: 'DELETE',
      url: `/api/billingApp/patient-balance/${id}`,
    },
    { apiName: this.apiName });

  get = (id: string) =>
    this.restService.request<any, PatientBalanceDTO>({
      method: 'GET',
      url: `/api/billingApp/patient-balance/${id}`,
    },
    { apiName: this.apiName });

  getActiveList = (input: PagedAndSortedResultRequestDto) =>
    this.restService.request<any, PagedResultDto<PatientBalanceDTO>>({
      method: 'GET',
      url: '/api/billingApp/patient-balance/active-list',
      params: { skipCount: input.skipCount, maxResultCount: input.maxResultCount, sorting: input.sorting },
    },
    { apiName: this.apiName });

  getList = (input: PagedAndSortedResultRequestDto) =>
    this.restService.request<any, PagedResultDto<PatientBalanceDTO>>({
      method: 'GET',
      url: '/api/billingApp/patient-balance',
      params: { skipCount: input.skipCount, maxResultCount: input.maxResultCount, sorting: input.sorting },
    },
    { apiName: this.apiName });

  getPatientBalanceList = (gPatientId: string) =>
    this.restService.request<any, PatientBalanceDTO>({
      method: 'GET',
      url: `/api/billingApp/patient-balance/patient-balance-list/${gPatientId}`,
    },
    { apiName: this.apiName });

  update = (id: string, input: CreateUpdatePatientBalanceDTO) =>
    this.restService.request<any, PatientBalanceDTO>({
      method: 'PUT',
      url: `/api/billingApp/patient-balance/${id}`,
      body: input,
    },
    { apiName: this.apiName });

  getBillingDetailsInPPByPatientId = (patientId: string) =>
    this.restService.request<any, billingDetailsForPP>({
      method: 'GET',
      url: `/api/billingApp/patient-balance/get-billing-details-in-pP/${patientId}`,
    },
    { apiName: this.apiName });

    getAgingWiseAmountforPatientIdByPatientId = (patientId: string) =>
      this.restService.request<any, agingWiseAmount>({
        method: 'GET',
        url: `/api/billingApp/patient-balance/get-aging-wise-amountfor-patient-id/${patientId}`,
      },
      { apiName: this.apiName });

  constructor(private restService: RestService) {}
}
