<mat-dialog-content>
  <div class="row" style="margin: 0px !important;">
    <div class="col-lg-12">
      <!-- <div class="card">
        <div class="card-body"> -->
             <div class="center">
               <div class="container">
                  <form  [formGroup]="twoFactorAuthenticationForm">
                    <div class="row flex-row" >
                      <img class="img-responsive pb-2 mr-1" src="assets/images/rnd.png" style="width:50%" />
                    </div>
                    <div class="row flex-row m-2" >
                      <img class="img-responsive pb-2 mr-1" src="assets/images/Lock.png" style="width:10%" />
                    </div>
                    <div class="row flex-row m-3" >
                      <h1><b>Two Factor Authentication</b></h1>
                      <p>To ensure your account's security, Please enter the code
                        sent to your email to complete the log-in process</p>
                    </div>
                    <!-- <div  class=" row otp-input">
                      <input #formRow  formControlName="{{input}}"   *ngFor="let input of formInput;  index as i"
                      class="form-control" maxlength="1" (keyup)="keyUpEvent($event, i)" inputmode="numeric" oninput="this.value = this.value.replace(/\D+/g, '')">
                    </div>
                    <div class="row">
                        <div class="col-6">
                          <span style="display: flex;justify-content: start;padding-left: 45px;">
                            {{ display }}
                          </span>
                        </div>
                        <div class="col-6 flex-rowV1">
                          <a  (click)="resendOTP()" [ngClass]="{'disabled-link':enableResend!=true}"


                          ><span >Resend OTP</span></a>
                        </div>
                    </div> -->
                    <div class="row otp-input" style="display: flex; flex-wrap: wrap;">
                      <input #formRow formControlName="{{input}}" *ngFor="let input of formInput; index as i"
                        class="form-control" maxlength="1" (keyup)="keyUpEvent($event, i)" inputmode="numeric"
                        oninput="this.value = this.value.replace(/\D+/g, '')" style="flex: 1 1 auto; margin: 2px;">
                    </div>
                    <div class="row" style="display: flex; justify-content: space-between; align-items: center;">
                      <div class="col-6" style="flex: 1;">
                        <span style="display: flex; justify-content: start; padding-left: 25px;">
                          {{ display }}
                        </span>
                      </div>
                      <div class="col-6 flex-rowV1" style="flex: 1; display: flex; justify-content: flex-end;">
                        <a (click)="resendOTP()" [ngClass]="{'disabled-link': enableResend != true}">
                          <span>Resend OTP</span>
                        </a>
                      </div>
                    </div>
                    <div class="row "  style="display: flex;justify-content: center;">
                        <b style="color:red">{{showErrorMsg}}</b>
                    </div>
                    <div class="row flex-row m-2">
                      <button mat-button class="buttonColor m-2" [disabled]="twoFactorAuthenticationForm.invalid ||verifyAttamptCount>=4"
                        (click)="verifyOTP()">
                        Verify
                      </button>
                    </div>
                    <div class="row flex-row ">
                      <a  (click)="backToLogin()"
                      ><b style="color:lightseagreen">Back To Login</b></a
                    >
                    </div>
                  </form>
                 </div>
              </div>
        <!-- </div>
      </div> -->
    </div>
  </div>
</mat-dialog-content>

