import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { DataTableDirective } from 'angular-datatables';
import { CommunicationService } from 'projects/shared/src/app/services/communication.service';

@Component({
  selector: 'app-product-update-unit-cost-table',
  templateUrl: './product-update-unit-cost-table.component.html',
  styleUrls: ['./product-update-unit-cost-table.component.scss']
})
export class ProductUpdateUnitCostTableComponent implements OnInit {
  @Input() tableDataList: any;
  @ViewChild(DataTableDirective, { static: false })
  datatableElement: DataTableDirective;
  @Output() nevigatePage? = new EventEmitter<number>();
  @Input() currentPage: any = 1;
  @Input() totalPages: any = 1;
  @Input() paginationInfo: { startRecord: number, endRecord: number, overallTableCount:number };
  dtProductTableOptions:any;
  constructor(
    private communicationService: CommunicationService,
    private datepipe: DatePipe) { }

  ngOnInit(): void {
    this.dataTableInitilization();
    this.dtProductTableOptions.buttons[0].filename =
      'Product Unit Cost List' + '-' + this.datepipe.transform(new Date(), 'MM-dd-yyyy');
  }
  ngAfterViewInit(): void {
    if (this.datatableElement && this.datatableElement.dtInstance) { this.refreshDataTable()
    } else {
      // If dtInstance is not available yet, wait for a short time and try again
      setTimeout(() =>  this.refreshDataTable(), 500);
    }
  }
  refreshDataTable(): void {
    this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
      // Clear search input values
      $('input', dtInstance.table(0).footer()).val('');

      // Redraw the DataTable to load all records
      dtInstance.search('').columns().search('').draw();
        this.addPageNavigationControls();
      // Attach the event listener for filtering
      dtInstance.columns().every(function () {
        const that = this;
        $('input', this.footer()).on('keyup change', function () {
          if (that.search() !== this['value']) {
            that.search(this['value']).draw();
          }
        });
      });
    });
  }

  dataTableInitilization(){
    this.dtProductTableOptions = {
      responsive: true,
      paging: false,
      dom: 'Bfrtip',
      scrollCollapse: true,
      scrollY: '500px',
      scrollX: true,
      info: true,
      search: {
        smart: false,
      },
      language: {
        emptyTable: 'No records',
        info: `Showing ${this.paginationInfo?.startRecord ?? 0} to ${this.paginationInfo?.endRecord ?? 0} of ${this.paginationInfo?.overallTableCount ?? 0} records`,
        infoEmpty: 'Total : _MAX_ records',
        infoFiltered: '(filtered : _TOTAL_ records)',
        loadingRecords: 'Loading...',
        zeroRecords: 'No matching records',
      },
      order: [],
      buttons: [
        {
          extend: 'excel',
          text: 'Export', // Change the text to 'Export'
          // filename: 'Product Unit Cost List',
          exportOptions: {
            columns: ':visible:not(:first-child) ',
            // columns: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
          },
        },
      ],
    };
  }
  triggerToggle() {
    this.communicationService.triggerProductUnitCostToggle();
  }

  getProductId(data: any) {
    this.communicationService.triggerProductGetMethodCall(data);
  }
  addPageNavigationControls(){
    let buttonsElement =  $(".dt-buttons")[0];

    // $("<button class='dt-button' id='btnDefaultClms' type='button'><span>Show Default Column</span></button>").appendTo(buttonsElement);
    // $("<button class='dt-button' id='btnAllClms' type='button'><span>Show All Column</span></button>").appendTo(buttonsElement);

    $("<span> : <span>").appendTo(buttonsElement);
    if(this.currentPage == 1)
    {
      $("<button disabled title='First Page' class='dt-button' id='btnFirstPage' type='button'><span>&lt;&lt;</span></button>").appendTo(buttonsElement);
      $("<button disabled class='dt-button' id='btnPrevPage' type='button'><span>&lt;</span></button>").appendTo(buttonsElement);
    }
    else
    {
      $("<button title='First Page' class='dt-button' id='btnFirstPage' type='button'><span>&lt;&lt;</span></button>").appendTo(buttonsElement);
      $("<button title='Previous Page' class='dt-button' id='btnPrevPage' type='button'><span>&lt;</span></button>").appendTo(buttonsElement);
     }

    $("<span> Page <span>").appendTo(buttonsElement);
    $("<input style='width:50px;' id='txtGotoPage' value='" + this.currentPage + "' type='text'/>").appendTo(buttonsElement);
    $("<span> of " + this.totalPages + " <span>").appendTo(buttonsElement);
    $("<button  class='dt-button' id='btnGoToPage'   type='button'><span>Go to</span></button>").appendTo(buttonsElement);

    if(this.currentPage >= this.totalPages)
    {
      $("<button disabled class='dt-button' id='btnNextPage'   type='button'><span>&gt;</span></button>").appendTo(buttonsElement);
      $("<button disabled title='Last Page'  class='dt-button' id='btnLastPage'  type='button'><span>&gt;&gt</span></button>").appendTo(buttonsElement);
    }
    else
    {
       $("<button title='Next Page'  class='dt-button' id='btnNextPage'  type='button'><span>&gt;</span></button>").appendTo(buttonsElement);
       $("<button title='Last Page'  class='dt-button' id='btnLastPage'  type='button'><span>&gt;&gt</span></button>").appendTo(buttonsElement);
    }

    $('#btnNextPage').on('click', (event) => this.changePage(true));
    $('#btnPrevPage').on('click', (event) => this.changePage(false));
    // $(".buttons-colvis").on('click', (event) => this.fnColumnSelectionClick());
    // $('#btnDefaultClms').on('click', (event) => this.fnGetColumnVisiblity("DEFAULT"));
    // $('#btnAllClms').on('click', (event) => this.fnGetColumnVisiblity("ALL"));
    $('#btnGoToPage').on('click', (event) => this.fnGotoPage());
    // $('#btnFirstPage').on('click', (event) => this.nevigatePage.emit(1));
    // $('#btnLastPage').on('click', (event) => this.nevigatePage.emit(this.totalPages));
    $('#btnFirstPage').on('click', (event) => this.nevigatePage.emit(1));
    $('#btnLastPage').on('click', (event) => this.nevigatePage.emit(this.totalPages));
   }
   changePage(isNext:boolean){
    if(isNext)
      this.currentPage++;
    else
      this.currentPage--;
    this.nevigatePage.emit(this.currentPage);
  }

  fnGotoPage(){
    let pageValue = $("#txtGotoPage").val();
    let pageno = parseInt(pageValue.toString());
    if(pageno > 0 && this.currentPage !=  pageno && pageno <= this.totalPages)
    {
      this.nevigatePage.emit(pageno);
    }
  }

}
