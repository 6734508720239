<div class="container-fluid">
  <div class="row mb-1">
    <div class="col-12">
      <div class="d-flex align-items-center justify-content-between">
        <h3 class="font-weight-bolder mb-0">
          <b class="customThemeClass page-header-breadcrumb">Order/Invoices</b>
        </h3>

        <h3 class="font-weight-bolder mb-0">
          <b  *ngIf="invoiceNo && invoiceNo != '-'" class="customThemeClass page-header-breadcrumb">Invoice Number ({{ invoiceNo }})</b>
        </h3>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-12">
      <div class="card card-body p-2">
        <div class="row pb-2 pt-1">
          <div class="col-12 d-flex justify-content-between">
            <div class="col-2">
              <p class="mb-0 text-header">Chart Id</p>
              <mat-label><a [class.disabled]="isEmpty(chartNo) || isEmpty(patientId)" [routerLink]="'/patientCreateEditTab/' + patientId"target="_blank"class="preview-link footer-bolder">{{ chartNo || "-" }}</a></mat-label>
            </div>

            <div class="col-2">
              <p class="mb-0 text-header">Patient Name</p>
              <mat-label class="dd-text">{{patientName}}</mat-label>
            </div>

            <div class="col-2">
              <p class="mb-0 text-header">DOB</p>
              <mat-label class="dd-text">{{dateOfBirth}}</mat-label>
            </div>
            <div class="col-2">
              <p class="mb-0 text-header">Phone</p>
              <mat-label class="dd-text">{{phoneNo}}</mat-label>
            </div>

            <div class="col-2">
              <p class="mb-0 text-header">SSN</p>
              <mat-label class="dd-text">{{ssn}}</mat-label>
            </div>
          </div>
        </div>

        <div class="row pb-2">
          <div class="col-12 d-flex justify-content-between">
            <div class="col-2">
              <p class="mb-0 text-header">Payer Level</p>
              <mat-label class="dd-text">{{payerLevel}}</mat-label>
            </div>
            <div class="col-2">
              <p class="mb-0 text-header">Insurance</p>
              <mat-label class="dd-text">{{insuranceName}}</mat-label>
            </div>

            <div class="col-2">
              <p class="mb-0 text-header">Policy No</p>
              <mat-label class="dd-text">{{policyCode}}</mat-label>
            </div>

            <div class="col-2">
              <p class="mb-0 text-header">Group No</p>
              <mat-label class="dd-text">{{groupNo}}</mat-label>
            </div>
            <div class="col-2">
              <p class="mb-0 text-header">Relationship</p>
              <mat-label class="dd-text">{{relationShip}}</mat-label>
            </div>
          </div>
        </div>

        <div class="row pb-1">
          <div class="col-12 d-flex justify-content-between">
            <div class="col-2">
              <p class="mb-0 text-header">Order No</p>
              <mat-label class="dd-text"><a
                [class.disabled]="
                  isEmpty(orderId) || isEmpty(patientId)
                "
                [routerLink]=" '/orders/saleOrder/' + patientId + '/' + orderId + '/' +  0 " target="_blank"
                class="preview-link"
                >{{orderNo}}</a></mat-label>
            </div>
            <div class="col-2">
              <p class="mb-0 text-header">DOS</p>
              <mat-label class="dd-text">{{dos}}</mat-label>
            </div>
            <div class="col-2">
              <p class="mb-0 text-header">Branch Office</p>
              <mat-label class="dd-text">{{location}}</mat-label>
            </div>

            <div class="col-2">
              <p class="mb-0 text-header">Invoice Status</p>
              <!-- <mat-label class="dd-text">{{invoiceStatus}}</mat-label> -->
              <span [matTooltip]="invoiceStatus || '-'"
                class="rounded-border p-1 text-center cus-label-nocolor" [ngStyle]="{ 'background-color': colorCode || 'transparent',
                'color': getContrastingTextColor(colorCode)
              }">
                {{ invoiceStatus || '-' }}
              </span>
            </div>

            <div class="col-2">
              <!-- <p class="mb-0 text-header">DOD</p>
              <mat-label class="dd-text">N/A</mat-label> -->
            </div>
          </div>
        </div>
      </div>

      <div class="card card-body mt-2" *ngIf="!isTableLoading">
        <div class="table table-responsive">
          <table
            datatable
            [dtOptions]="orderInvoicesTable"
            class="display row-border hover w-100 table"
          >
            <thead>
              <tr>
                <th [matTooltip]="'Insurance'">Insurance</th>
                <th [matTooltip]="'Invoice No'">Invoice No</th>
                <th [matTooltip]="'Billed ($)'" class="text-right" >Billed ($)</th>
                <th [matTooltip]="'Allowed ($)'" class="text-right" >Allowed ($)</th>
                <th [matTooltip]="'Paid ($)'" class="text-right" >Paid ($)</th>
                <th [matTooltip]="'Adjustments ($)'" class="text-right" >Adjustments ($)</th>
                <th [matTooltip]="'Total Balance ($)'" class="text-right" >Total Balance ($)</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let invoice of invoiceTableDataV1"  [ngStyle]="{'background-color': invoice.payorLevel === 'Total' ? 'silver' : ''}" [ngClass]="this.selectedInvoice === invoice?.payorLevel ? 'row-highlighted' : '' " (click)="fnSelectInvoice(invoice)"
              >

                <td [matTooltip]="invoice?.payorLevel || '-'">
                  <div [innerHTML]="invoice.payorLevel === 'Total' ? '<b>' + (invoice?.payorLevel || '-') + '</b>' : (invoice?.payorLevel || '-')"></div>
                </td>
                <td [matTooltip]="invoice?.defaultInvoiceCode || '-'">
                  <div [innerHTML]="invoice.payorLevel === 'Total' ? '<b>' + (invoice?.defaultInvoiceCode || '-') + '</b>' : (invoice?.defaultInvoiceCode || '-')"></div>
                </td>
                <td [matTooltip]="invoice?.billed || '-'" class="text-right">
                  <div [innerHTML]="invoice.payorLevel === 'Total' ? '<b>' + (invoice?.billed || '-') + '</b>' : (invoice?.billed || '-')"></div>
                </td>
                <td [matTooltip]="invoice?.allowed || '-'" class="text-right">
                  <div [innerHTML]="invoice.payorLevel === 'Total' ? '<b>' + (invoice?.allowed || '-') + '</b>' : (invoice?.allowed || '-')"></div>
                </td>
                <td [matTooltip]="invoice?.insurancePaid || '-'" class="text-right">
                  <div [innerHTML]="invoice.payorLevel === 'Total' ? '<b>' + (invoice?.insurancePaid || '-') + '</b>' : (invoice?.insurancePaid || '-')"></div>
                </td>
                <td [matTooltip]="invoice?.adjustAmount || '-'" class="text-right">
                  <div [innerHTML]="invoice.payorLevel === 'Total' ? '<b>' + (invoice?.adjustAmount || '-') + '</b>' : (invoice?.adjustAmount || '-')"></div>
                </td>
                <td [matTooltip]="invoice?.totBalance || '-'" class="text-right">
                  <div [innerHTML]="invoice.payorLevel === 'Total' ? '<b>' + (invoice?.totBalance || '-') + '</b>' : (invoice?.totBalance || '-')"></div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div class="row" *ngIf="isTableLoading">
        <div class="col-12">
          <div class="d-flex justify-content-center">
            <img class="img-responsive pb-2 mr-1" src="assets/gifs/Infinity-loader.gif" />
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
