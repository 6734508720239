import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatRadioChange } from '@angular/material/radio';
import { MatSnackBar } from '@angular/material/snack-bar';
import { events } from '@syncfusion/ej2-angular-calendars';
import { ToastrService } from 'ngx-toastr';
import { sortedFaxDetails } from 'projects/admin/src/app/admin-proxy/platform-app-management/rcm/platform-management/inbound/dto/models';
import { InboundDocumentService } from 'projects/admin/src/app/admin-proxy/platform-app-management/rcm/platform-management/inbound/inbound-document.service';
import {
  InboundSortedfilter,
  InboundSortedfilterText,
} from 'projects/shared/src/app/enums/allenums.enum';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-inbound-sorting-pending',
  templateUrl: './inbound-sorting-pending.component.html',
  styleUrls: ['./inbound-sorting-pending.component.scss'],
})
export class InboundSortingPendingComponent implements OnInit {
  isLoading: boolean;
  inboundSortingPendingListDataArray: sortedFaxDetails[];
  private inboundGetAPICall: Subscription;
  private inboundgetLockDocDetailsAPICall: Subscription;
  inboundSortedfilterText = InboundSortedfilterText;
  InboundSortedfilter = InboundSortedfilter;
  inboundForm: FormGroup;
  selectedInboundSortedfilter = InboundSortedfilter.Unsorted;
  maxDate: Date;
  startDate: Date;
  endDate: Date;
  isAllowDoSorting: boolean = false;
  constructor(
    public inboundDocUploadService: InboundDocumentService,
    private toastr: ToastrService,
    private snackBar: MatSnackBar,
    private fb: FormBuilder
  ) {}

  ngOnInit(): void {
    this.initializeForms();
    this.getTableData();
    this.getLockedCount()
  }

  ngOnDestroy(): void {
    if (this.inboundGetAPICall) {
      this.inboundGetAPICall.unsubscribe();
    }

    if (this.inboundgetLockDocDetailsAPICall) {
      this.inboundgetLockDocDetailsAPICall.unsubscribe();
    }
  }

  initializeForms() {
    this.inboundForm = this.fb.group({
      startDate: new FormControl(''),
      endDate: new FormControl(''),
    });

    const { firstDate, lastDate, maxDate } =
      this.getFirstAndLastDatesOfCurrentMonth();
    this.startDate = firstDate;
    this.endDate = lastDate;
    this.inboundForm.patchValue({
      startDate: this.startDate,
      endDate: this.endDate,
    });
    this.maxDate = maxDate;
  }

  onRadioChange(event: MatRadioChange) {
    this.selectedInboundSortedfilter = event.value;
    this.getTableData();
  }

  // Function to get the first and last dates of the current month
  getFirstAndLastDatesOfCurrentMonth(): {
    firstDate: Date;
    lastDate: Date;
    maxDate: Date;
  } {
    const today = new Date();
    const firstDate = new Date(today.getFullYear(), today.getMonth(), 1);
    const lastDate = new Date(today.getFullYear(), today.getMonth() + 1, 0);
    const maxDate = new Date(Math.max(today.getTime(), today.getTime())); // Set max date to today if selected date is later
    return { firstDate, lastDate, maxDate };
  }

  //On Date Range Change Method
  onDateRangeChange() {
    this.startDate = this.inboundForm.get('startDate').value;
    this.endDate = this.inboundForm.get('endDate').value;

    if (this.startDate != null && this.endDate != null) {
      if (this.startDate > this.endDate) {
        this.toastr.error('Start date cannot be greater than end date');
        return; // Exit the method if start date is greater than end date
      }
      const year = this.startDate.getFullYear();
      const month = this.startDate.getMonth() + 1; // Month is zero-based, so add 1
      const day = this.startDate.getDate();
      const isValidDate = this.isValidDate(year, month, day);
      const eyear = this.endDate.getFullYear();
      const emonth = this.endDate.getMonth() + 1; // Month is zero-based, so add 1
      const eday = this.endDate.getDate();
      const isValideDate = this.isValidDate(eyear, emonth, eday);
      if (isValidDate && isValideDate) {
        this.getTableData();
      } else {
        this.toastr.error('Please select Valid date');
      }
    }
  }

  getFormattedDate(date: string): string {
    if (!date) return null;

    const formattedDate = new Date(date);
    const year = formattedDate.getFullYear();
    const month = (formattedDate.getMonth() + 1).toString().padStart(2, '0');
    const day = formattedDate.getDate().toString().padStart(2, '0');

    return `${year}-${month}-${day}`;
  }

  isValidDate(year: number, month: number, day: number): boolean {
    if (
      year >= 1000 &&
      year <= 9999 &&
      month >= 1 &&
      month <= 12 &&
      day >= 1 &&
      day <= 31
    ) {
      return true;
    } else {
      return false;
    }
  }

  getLockedCount() {
    try {
      if (this.inboundgetLockDocDetailsAPICall) {
        this.inboundgetLockDocDetailsAPICall.unsubscribe();
      }
      this.inboundgetLockDocDetailsAPICall = this.inboundDocUploadService
        .getLockDocDetails()
        .subscribe(
          (response) => {

            this.isAllowDoSorting = response.length > 0 ? true : false;

          },
          (error) => {
            const data: HttpErrorResponse = error;
            this.toastr.error(data?.error?.error?.message, 'Error');
          }
        );
    } catch (error) {
      console.error('Unexpected error:', error);
    } finally {
    }
  }

  getTableData() {
    this.isLoading = true;
    try {
      if (this.inboundGetAPICall) {
        this.inboundGetAPICall.unsubscribe();
      }
      this.inboundGetAPICall = this.inboundDocUploadService
        .getSortedDetailsByFromDateAndToDateAndSortedfilter(
          this.getFormattedDate(this.startDate.toDateString()),
          this.getFormattedDate(this.endDate.toDateString()),
          this.selectedInboundSortedfilter
        )
        .subscribe(
          (response) => {
            this.inboundSortingPendingListDataArray = response;
            this.isLoading = false;
          },
          (error) => {
            const data: HttpErrorResponse = error;
            this.toastr.error(data?.error?.error?.message, 'Error');
            this.isLoading = false;
          }
        );
    } catch (error) {
      console.error('Unexpected error:', error);
    } finally {
    }
  }
}
