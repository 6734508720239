<table id="tblPracticeManagementList" datatable [dtOptions]="dtPracticeManagementTableOptions"
    class="tbl hover row-border hover w-100 display">
    <thead>
        <tr>
            <th><mat-icon> wrap_text</mat-icon></th>
            <th [matTooltip]="'Name'">Name</th>
            <th [matTooltip]="'City'">City</th>
            <th [matTooltip]="'State'">State</th>
            <th [matTooltip]="'Zip'">Zip</th>
            <th [matTooltip]="'Created By'">Created By</th>
            <th class="text-right" [matTooltip]="'Created Date'" >Created Date</th>
            <th [matTooltip]="'Modify By'">Modify By</th>
            <th class="text-right" [matTooltip]="'Modified Date'" >Modified Date</th>
            <th [matTooltip]="'Phone No'">Phone No</th>
            <th [matTooltip]="'Fax No'">Fax No</th>
            <th [matTooltip]="'Account Manager'">Account Manager</th>
            <th [matTooltip]="'Status'">Status</th>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let data of practicelistdata">

            <td class="dt-control" [matTooltip]="'Expand Notes'" (click)="handleTableCellClick($event, data.notes)">
            </td>
            <td [matTooltip]="'Click To Update Practice' || '-'" (dblclick)="onRowDoubleClickV1(data?.practiceName || '-')">
                <a [routerLink]="'/addpracticemanagement/' + data.id" class="preview-link"> {{
                    (data.practiceName !== null && data.practiceName !== ' ') ? data.practiceName : '-'
                    }}</a>
            </td>
            <td [matTooltip]="data.practiceCity || '-'" (dblclick)="onRowDoubleClickV1(data?.practiceCity || '-')">
                {{ (data.practiceCity !== null && data.practiceCity !== '') ? data.practiceCity : '-' }}
            </td>
            <td [matTooltip]="data.practiceState || '-'" (dblclick)="onRowDoubleClickV1(data?.practiceState || '-')">
                {{ (data.practiceState !== null && data.practiceState !== '') ? data.practiceState : '-'
                }}
            </td>
            <td [matTooltip]="data.practiceZipCode || '-'"
                (dblclick)="onRowDoubleClickV1(data?.practiceZipCode || '-')">
                {{ (data.practiceZipCode !== null && data.practiceZipCode !== '') ? data.practiceZipCode
                : '-'
                }}
            </td>
            <td [matTooltip]="data?.createdBy || '-'"
            (dblclick)="onRowDoubleClickV1(data?.createdBy || '-')">{{
            data?.createdBy || '-' }}</td>

        <td class="text-right" [matTooltip]="data?.creationTime"
            (dblclick)="onRowDoubleClickV1(data?.creationTime)" >
            {{ data?.creationTime }}
        </td>
        <td [matTooltip]="data?.modifyBy|| '-'"
            (dblclick)="onRowDoubleClickV1(data?.modifyBy || '-')">{{
            data?.modifyBy || '-' }}</td>

        <td class="text-right" [matTooltip]="data?.lastModificationTime"
            (dblclick)="onRowDoubleClickV1(data?.lastModificationTime)" >
            {{ data?.lastModificationTime }}
        </td>
            <td [matTooltip]="data.practicePhone || '-'" (dblclick)="onRowDoubleClickV1(data?.practicePhone || '-')">
                <!-- {{ (data.practicePhone !== null && data.practicePhone !== '') ? data.practicePhone : '-'
                }} -->
                <span *ngIf="(data.practicePhone != '' && data.practicePhone != null)" class="tbl-sapnfont">+1-</span>{{(data.practicePhone == '' || data.practicePhone == null) ? "-" : data.practicePhone}}
            </td>
            <td [matTooltip]="data.practiceFax || '-'" (dblclick)="onRowDoubleClickV1(data?.practiceFax || '-')">
                {{ (data.practiceFax !== null && data.practiceFax !== '') ? data.practiceFax :
                '-' }}
            </td>
            <td [matTooltip]="data.accountManagerName || '-'"
                (dblclick)="onRowDoubleClickV1(data?.accountManagerName || '-')">
                {{ (data.accountManagerName !== null && data.accountManagerName !== '')? data.accountManagerName
                : '-' }}
            </td>
            <td >

                <ng-container *ngIf="data.isAlreadyExist">
                    <span [matTooltip]="'In Use'">In Use</span>
                  </ng-container>

                <ng-container *ngIf="!data.isAlreadyExist">
                    <mat-slide-toggle [matTooltip]="data?.isActive ? 'Active' : 'Inactive' || '-'" (change)="statusChange($event, data)" [checked]="data?.isActive==1" class="ml-2">
                        {{ data?.isActive ? 'Active' : 'Inactive' }}
                    </mat-slide-toggle>
                </ng-container>
            </td>
        </tr>
    </tbody>
    <tfoot>
        <tr>
            <th><mat-icon> wrap_text</mat-icon></th>

            <th>
                <input matInput type="text" class="textbox-border-class" placeholder="Name" name="search-Name" />
            </th>
            <th>
                <input matInput class="textbox-border-class" type="text" placeholder="City" name="search-City" />
            </th>
            <th>
                <input matInput type="text" class="textbox-border-class" placeholder="State" name="search-State" />
            </th>
            <th>
                <input matInput type="text" class="textbox-border-class" placeholder="Zip" name="search-Zip" />
            </th>
            <th>
              <input matInput class="textbox-border-class" type="text" placeholder="Created By"
                  name="search-Created By" />
          </th>
          <th class="text-right">
              <input matInput class="textbox-border-class " type="text"
                    placeholder="Created Date" name="search-Created Date" />
           </th>
           <th>
              <input matInput class="textbox-border-class" type="text" placeholder="Modify By"
                  name="search-Modify By" />
          </th>
            <th class="text-right">
                <input matInput class="textbox-border-class " type="text"
                    placeholder="Modified Date" name="search-Modified Date" />
            </th>
            <th>
                <input matInput class="textbox-border-class" type="text" placeholder="Phone No" name="search-PhoneNo" />
            </th>
            <th>
                <input matInput class="textbox-border-class" type="text" placeholder="Fax No" name="search-FaxNo" />
            </th>
            <th>
                <input matInput class="textbox-border-class" type="text" placeholder="Account Manager" name="search-AccountManager" />
            </th>
            <th><input matInput class="textbox-border-class" type="text" placeholder="Status" name="search-Status" /></th>
        </tr>
    </tfoot>
</table>
