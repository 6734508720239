<div class="container-fluid main-content">
  <div class="row bg">
    <div class="col-12">
      <div class="d-flex align-items-center justify-content-between mb-3">
        <h3 class="font-weight-bolder mb-0">Authorization To Do</h3>
        <div class=" page-title-box page-title-right">
          <ol class="breadcrumbs m-0 align-items-center">
            <button mat-button class="mr-2 buttonColor"
    [disabled]="(dataSource && dataSource?.data && dataSource?.data?.length === 0)"

            (click)="exportTableData()">
              Export
            </button>
            <li class="breadcrumb-item ">Patient</li>
            <li class="breadcrumb-item ">Authorization To Do</li>
          </ol>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="col-12 table-responsive" style="margin-top: -49px;">
  <!-- <div [hidden]="!isLoading"
    style="display: flex; justify-content: center; align-items: center; background: transparent; color: lightseagreen;">
    <mat-progress-bar mode="indeterminate" value='66' diameter="45" class="lightseagreen">
    </mat-progress-bar>
  </div> -->
  <!-- <app-table [hidden]="isLoading" [strPageType]="strPageType" (viewButton)="existingOrders($event)"></app-table> -->
  <table mat-table class="table" [dataSource]="dataSource" matSort>
    <form style="display: flex" [formGroup]="authForm">
      <ng-container matColumnDef="PatientId">
        <th class="header" mat-header-cell *matHeaderCellDef>
          <mat-form-field class="filter">
            <input autocomplete="new-PatientId" matInput formControlName="PatientId">
            <mat-placeholder class="center">Patient Id</mat-placeholder>
          </mat-form-field>
        </th>
        <td mat-cell *matCellDef="let element"> {{element.patientId}} </td>
      </ng-container>
      <ng-container matColumnDef="PatientName">
        <th class="header" mat-header-cell *matHeaderCellDef>
          <mat-form-field class="filter">
            <input autocomplete="new-PatientName" matInput formControlName="PatientName">
            <mat-placeholder>Patient Name</mat-placeholder>
          </mat-form-field>
        </th>
        <td mat-cell *matCellDef="let element"> {{element.PatientName}} </td>
      </ng-container>
      <ng-container matColumnDef="Date">
        <th class="header" mat-header-cell *matHeaderCellDef>
          <mat-form-field class="filter">
            <mat-label> Create Date </mat-label>
            <input autocomplete="new-Date" matInput formControlName="Date" placeholder="(MM/DD/YYYY)" />
            <mat-error *ngIf=" authForm?.get('Date')?.touched && authForm?.get('Date')?.errors?.dateVaidator">
              Enter Valid date
            </mat-error>
          </mat-form-field>
        </th>
        <td mat-cell *matCellDef="let element"> {{element.createDate}} </td>
      </ng-container>

      <ng-container matColumnDef="Plan">
        <th class="header" mat-header-cell *matHeaderCellDef>
          <mat-form-field class="filter">
            <input autocomplete="new-Plan" matInput formControlName="Plan">
            <mat-placeholder>Plan</mat-placeholder>
          </mat-form-field>
        </th>
        <td mat-cell *matCellDef="let element"> {{element.plan}} </td>
      </ng-container>

      <ng-container matColumnDef="AuthId">
        <th class="header" mat-header-cell *matHeaderCellDef>
          <mat-form-field class="filter">
            <input autocomplete="new-AuthId" matInput formControlName="AuthId">
            <mat-placeholder>Auth Id</mat-placeholder>
          </mat-form-field>
        </th>
        <td mat-cell *matCellDef="let element"> {{element?.AuthId}} </td>
      </ng-container>
      <ng-container matColumnDef="Status">
        <th class="header" mat-header-cell *matHeaderCellDef>
          <mat-form-field class="filter">
            <input autocomplete="new-Status" matInput formControlName="Status">
            <mat-placeholder>Status</mat-placeholder>
          </mat-form-field>
        </th>
        <td mat-cell *matCellDef="let element"> {{element?.Status}} </td>
      </ng-container>

      <ng-container matColumnDef="options">
        <th class="header" mat-header-cell *matHeaderCellDef>
          Options
        </th>
        <td class="align" mat-cell *matCellDef="let element">
          <a class="editBtnColor">
            <i *ngIf='isShownUpdateIconForPatient' class="fa fa-eye mr-3" (click)="authView(element.id)"
              ></i>
          </a>
        </td>
      </ng-container>
      <ng-container matColumnDef="bin">
        <th class="header" mat-header-cell *matHeaderCellDef mat-sort-header>
          Next Bin
        </th>
        <td mat-cell *matCellDef="let record">

          <!-- <ng-container> -->
          <button *ngIf="record.binStatus" mat-button class="btn btn-sm lightSeaGreen-dot"
            (click)="moveToOrder(record)">Move To Order Bin</button>
          <!-- </ng-container> -->
        </td>
      </ng-container>

      <ng-container matColumnDef="noRecords">
        <td class="text-align-center" mat-footer-cell *matFooterCellDef colspan="9">
          <div *ngIf="!isLoading">
            {{"No Records Found"}}
          </div>
          <div [hidden]="!isLoading">
            <mat-spinner [diameter]="220"></mat-spinner>
          </div>
        </td>
      </ng-container>
      <ng-container matColumnDef="paginator">
        <td mat-footer-cell *matFooterCellDef colspan="8">
          <mat-paginator class="table table-responsive mat-table-class" #MatPaginator
            [length]="dataSource?.data?.length" [pageSize]="10" [pageIndex]="0" showFirstLastButtons
            [pageSizeOptions]="[5, 10, 25, 100]">
          </mat-paginator>
        </td>
      </ng-container>
    </form>
    <tr mat-footer-row *matFooterRowDef="['noRecords']"
      [ngClass]="{ active: dataSource && dataSource.data && dataSource?.data?.length !== 0 }"></tr>
    <tr mat-header-row *matHeaderRowDef="arrDisplayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: arrDisplayedColumns"
      [ngClass]="{'make-gold': row.Status == 'Pending', 'make-green':row.Status == 'Done','make-orange':row.Status == 'Yet To Start'}">
    </tr>
    <tr mat-footer-row *matFooterRowDef="['paginator']"></tr>
  </table>
</div>
