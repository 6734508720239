<br />
<div class="card card-body">
  <!-- <div class="list-box"> -->
  <form [formGroup]="shippingVerificationForm">
    <div class="row">
      <div class="col-12">
        <h3><b class="customThemeClass fontSize">Patient ID : </b><b class="fontSize">{{sDefaultPatientId}}</b></h3>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-4">
        <mat-radio-group class="rg pt-3" (change)="onChangeGetInsuranceVerify($event)" formControlName="rbtnPolicyType">
          <mat-radio-button [checked]='true' value="Primary">Primary</mat-radio-button>&nbsp;&nbsp;&nbsp;
          <mat-radio-button value="Secondary">Secondary</mat-radio-button>&nbsp;&nbsp;&nbsp;
          <mat-radio-button value="Tertiary">Tertiary</mat-radio-button>
        </mat-radio-group>
        <mat-card-content>
          <div class="row">
            <!-- <mat-form-field class="col-6">
            <mat-label> Chart # <span class="asterisk">*</span></mat-label>
            <mat-select maxlength="40" formControlName="drpChart">
              <mat-option *ngFor="let chart of drpChart" [value]="chart.chartId">
                {{chart.chartType}}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="
              shippingVerificationForm?.get('drpChart')?.touched &&
              shippingVerificationForm?.get('drpChart')?.errors?.required
              ">
              Chart is a required field!
            </mat-error>
          </mat-form-field> -->
            <mat-form-field class="col-6">
              <mat-label>Patient Name</mat-label>
              <input autocomplete="new-txtName" formControlName="txtName" maxlength="40" matInput type="text">
              <mat-error *ngIf="
              shippingVerificationForm?.get('txtName')?.touched &&
              shippingVerificationForm?.get('txtName')?.errors?.sAlphabets
              ">
                Enter only Alphabets!
              </mat-error>
            </mat-form-field>
            <!-- <mat-form-field class="col-6">
            <mat-label> Address</mat-label>
            <textarea formControlName="txtAddress" maxlength="100" matInput rows="1"></textarea>
            <mat-error *ngIf="
              shippingVerificationForm?.get('txtAddress')?.touched &&
              shippingVerificationForm?.get('txtAddress')?.errors?.sAlphabets
              ">
              Enter only Alphabets!
            </mat-error>
          </mat-form-field> -->
            <mat-form-field class="col-6">
              <mat-label>Date Of Birth <span class="asterisk">*</span></mat-label>
              <input autocomplete="new-txtDateOfBirth" formControlName="txtDateOfBirth" matInput maxlength="40" placeholder="(MM/DD/YYYY)"
                [matDatepicker]="txtDateOfBirth" [min]="BirthdateMinDate">
              <mat-datepicker-toggle matSuffix [for]="txtDateOfBirth">
              </mat-datepicker-toggle>
              <mat-datepicker #txtDateOfBirth></mat-datepicker>
              <mat-error *ngIf=" shippingVerificationForm?.get('txtDateOfBirth')?.touched &&
                  shippingVerificationForm?.get('txtDateOfBirth')?.errors">
                Enter Valid Date!
              </mat-error>
              <mat-error *ngIf="
              shippingVerificationForm?.get('txtDateOfBirth')?.touched &&
              shippingVerificationForm?.get('txtDateOfBirth')?.errors?.required
              ">
                Date Of Birth is a required field!
              </mat-error>
            </mat-form-field>
            <mat-form-field class="col-12">
              <mat-label>Comments/Notes</mat-label>
              <textarea maxlength="1000" matInput formControlName="txtCommentsNotes" rows="1"></textarea>
              <!-- <mat-error *ngIf="
              shippingVerificationForm?.get('txtCommentsNotes')?.touched &&
              shippingVerificationForm?.get('txtCommentsNotes')?.errors?.sAlphabets
              ">
              Enter only Alphabets!
            </mat-error> -->
            </mat-form-field>
          </div>
        </mat-card-content>
      </div>
      <div class="col-lg-8">
        <div class="row">
          <div class="col-md-6">
            <h4 class="customThemeClass"><b>Lookup Patient Ins</b></h4>
          </div>
          <div class="col-md-6">
          </div>
        </div>
        <mat-card-content>
          <div class="row">

            <mat-form-field class="col-3">
              <mat-label> Plan <span class="asterisk">*</span></mat-label>
              <mat-select (selectionChange)="valueChangeLoadDropdown($event)" formControlName="txtPlan" maxlength="40"
                [(value)]="plan">
                <mat-option *ngFor="let payor of payorLevel" [value]="payor.payorLevelName">
                  {{ payor.payorLevelName }}
                </mat-option>
              </mat-select>
              <button mat-button (click)="clearPlan()" (click)="plan=undefined;$event.stopPropagation()" *ngIf="plan"
                matSuffix mat-icon-button >
                <mat-icon>close</mat-icon>
              </button>
              <mat-error *ngIf="
            shippingVerificationForm?.get('txtPlan')?.touched &&
            shippingVerificationForm?.get('txtPlan')?.errors?.required
            ">
                Plan is a required field!
              </mat-error>
            </mat-form-field>
            <mat-form-field class="col-3">
              <mat-label> Plan Name <span class="asterisk">*</span></mat-label>
              <input autocomplete="new-txtPlanName" formControlName="txtPlanName" matInput type="text">
              <mat-error *ngIf="
              shippingVerificationForm?.get('txtPlanName')?.touched &&
              shippingVerificationForm?.get('txtPlanName')?.errors?.required
              ">
                Plan Name is a required field!
              </mat-error>
              <mat-error *ngIf="
              shippingVerificationForm?.get('txtPlanName')?.touched &&
              shippingVerificationForm?.get('txtPlanName')?.errors?.sAlphabets
              ">
                Enter only Alphabets!
              </mat-error>
            </mat-form-field>
            <mat-form-field class="col-3">
              <mat-label> Plan Phone</mat-label>
              <input autocomplete="new-txtPlanPhone" formControlName="txtPlanPhone" maxlength="40" prefix="+1-" mask="(000)-000-0000" matInput
                type="text">
              <mat-error *ngIf="
                          shippingVerificationForm?.get('txtPlanPhone')?.touched &&
                          shippingVerificationForm?.get('txtPlanPhone')?.errors
                          ">
                Enter Minimum 10 Digits!
              </mat-error>
            </mat-form-field>
            <mat-form-field class="col-3">
              <mat-label>Policy #<span class="asterisk">*</span></mat-label>
              <input autocomplete="new-txtPolicy" formControlName="txtPolicy" maxlength="40" matInput type="text">
              <mat-error *ngIf="
              shippingVerificationForm?.get('txtPolicy')?.touched &&
              shippingVerificationForm?.get('txtPolicy')?.errors?.required
              ">
                Policy is a required field!
              </mat-error>
              <mat-error *ngIf="
              shippingVerificationForm?.get('txtPolicy')?.touched &&
              shippingVerificationForm?.get('txtPolicy')?.errors?.sAlphabets
              ">
                Enter only Alphabets!
              </mat-error>
            </mat-form-field>
            <mat-form-field class="col-3">
              <mat-label>Group #</mat-label>
              <input autocomplete="new-txtGroup" formControlName="txtGroup" maxlength="40" matInput type="text">
              <mat-error *ngIf="
            shippingVerificationForm?.get('txtGroup')?.touched &&
            shippingVerificationForm?.get('txtGroup')?.errors?.pattern
            ">
                Special Characrers Not Allowed !
              </mat-error>
            </mat-form-field>
            <mat-form-field class="col-3">
              <mat-label>Effective Date <span class="asterisk">*</span></mat-label>
              <input autocomplete="new-txtEffectiveDate" formControlName="txtEffectiveDate" matInput maxlength="40" placeholder="(MM/DD/YYYY)"
                [matDatepicker]="txtEffectiveDate">
              <mat-datepicker-toggle matSuffix [for]="txtEffectiveDate">
              </mat-datepicker-toggle>
              <mat-datepicker #txtEffectiveDate></mat-datepicker>
              <mat-error *ngIf=" shippingVerificationForm?.get('txtEffectiveDate')?.touched &&
                         shippingVerificationForm?.get('txtEffectiveDate')?.errors">
                Enter Valid Date!
              </mat-error>
              <mat-error *ngIf="
              shippingVerificationForm?.get('txtEffectiveDate')?.touched &&
              shippingVerificationForm?.get('txtEffectiveDate')?.errors?.required
              ">
                Effective Date is a required field!
              </mat-error>
            </mat-form-field>
            <mat-form-field class="col-3">
              <mat-label>Type<span class="asterisk">*</span></mat-label>
              <!-- <input formControlName="txtType" matInput type="text"> -->
              <mat-select maxlength="40" [(value)]="type" formControlName="txtType">
                <!-- <mat-option *ngFor="let insurance of drpPlan" [value]="insurance.typeOfPlanId">
                  {{ insurance.typeOfPlanName }}
                </mat-option> -->
                <mat-option *ngFor="let payor of drpYearType" [value]="payor.shortCodeId">
                  {{ payor.yearType }}
                </mat-option>
              </mat-select>
              <button mat-button (click)="type=undefined;$event.stopPropagation()" *ngIf="type" matSuffix
                (click)="clearType()" mat-icon-button >
                <mat-icon>close</mat-icon>
              </button>
              <mat-error *ngIf="
              shippingVerificationForm?.get('txtType')?.touched &&
              shippingVerificationForm?.get('txtType')?.errors?.required
                ">
                Type is a required field!
              </mat-error>
            </mat-form-field>
            <mat-form-field class="col-3">
              <mat-label>Fiscal Year Start</mat-label>
              <input autocomplete="new-txtFascalYearStart" formControlName="txtFascalYearStart" matInput maxlength="40" placeholder="(MM/DD/YYYY)"
                [matDatepicker]="txtFascalYearStart">
              <mat-datepicker-toggle matSuffix [for]="txtFascalYearStart">
              </mat-datepicker-toggle>
              <mat-datepicker #txtFascalYearStart></mat-datepicker>
              <mat-error *ngIf=" shippingVerificationForm?.get('txtFascalYearStart')?.touched &&
                         shippingVerificationForm?.get('txtFascalYearStart')?.errors">
                Enter Valid Date!
              </mat-error>
            </mat-form-field>
            <mat-form-field class="col-3">
              <mat-label>End</mat-label>
              <input autocomplete="new-txtEnd" formControlName="txtEnd" maxlength="40" matInput placeholder="(MM/DD/YYYY)"
                [matDatepicker]="txtEnd">
              <mat-datepicker-toggle matSuffix [for]="txtEnd">
              </mat-datepicker-toggle>
              <mat-datepicker #txtEnd></mat-datepicker>
              <mat-error *ngIf=" shippingVerificationForm?.get('txtEnd')?.touched &&
                         shippingVerificationForm?.get('txtEnd')?.errors">
                Enter Valid Date!
              </mat-error>
            </mat-form-field>
            <div class="col-md-3">
              <br>
              <mat-checkbox [disabled]="true" formControlName="chkActive">Active</mat-checkbox>
            </div>
            <!-- <div class="col-9">
              <br>
              <button mat-button class="buttonColor mb-2 mr-2">Submit For Verification</button>
              <button mat-button class="buttonColor mb-2 mr-2">Submit For Online Verification</button>
            </div> -->
          </div>
        </mat-card-content>
      </div>
    </div>
    <div *ngIf="strPageTitleType===''" class="row">
      <div class="col-lg-12">
        <mat-card-content>
          <div class="row">
            <div class="col-md-3">
              <br>
              <mat-checkbox formControlName="chkMedicare">Medicare CMN on file?</mat-checkbox>
            </div>
            <div class="col-md-3">
              <br>
              <mat-checkbox formControlName="chkPatient">Is Patient Home Health Episode?</mat-checkbox>
            </div>
            <div class="col-md-6">
              <br>
              <mat-checkbox formControlName="chkFamilyDeductible">Does Family Deductible cover Individual Deductible?
              </mat-checkbox>
            </div>
            <mat-form-field class="col-3">
              <mat-label>CMN Code</mat-label>
              <input autocomplete="new-txtCmnCode" matInput maxlength="40" formControlName="txtCmnCode" type="text">
              <mat-error *ngIf="
              shippingVerificationForm?.get('txtCmnCode')?.touched &&
              shippingVerificationForm?.get('txtCmnCode')?.errors?.sAlphabets
              ">
                Enter only Alphabets!
              </mat-error>
            </mat-form-field>
            <mat-form-field class="col-3">
              <mat-label> Type Of Plan<span class="asterisk">*</span></mat-label>
              <mat-select maxlength="40" formControlName="drpTypeOfPlan" [(value)]="typeOfPlan">
                <mat-option *ngFor="let payor of drpPlanType" [value]="payor.planType">
                  {{ payor.planType }}
                </mat-option>
              </mat-select>
              <button mat-button (click)="clearTypeOfPlan()" (click)="typeOfPlan=undefined;$event.stopPropagation()"
                *ngIf="typeOfPlan" matSuffix mat-icon-button >
                <mat-icon>close</mat-icon>
              </button>
              <mat-error *ngIf="
              shippingVerificationForm?.get('drpTypeOfPlan')?.touched &&
              shippingVerificationForm?.get('drpTypeOfPlan')?.errors?.required
              ">
                Type is a required field!
              </mat-error>
            </mat-form-field>
            <mat-form-field class="col-3">
              <mat-label>DEO</mat-label>
              <input autocomplete="new-txtDeo" matInput maxlength="40" formControlName="txtDeo" type="text">
              <mat-error *ngIf="
            shippingVerificationForm?.get('txtDeo')?.touched &&
            shippingVerificationForm?.get('txtDeo')?.errors?.number
            ">
                Enter only Numbers!
              </mat-error>
            </mat-form-field>
            <mat-form-field class="col-3">
              <mat-label> Co Insurance </mat-label>
              <input autocomplete="new-txtCoIns" matInput maxlength="40" formControlName="txtCoIns" type="text">

            </mat-form-field>
          </div>
        </mat-card-content>
      </div>
    </div>
    <div *ngIf="strPageTitleType===''" class="row">
      <div class="col-lg-12">
        <div class="row">
          <div class="col-md-6">
            <h4 class="customThemeClass"><b>Insurance Benefit Info</b></h4>
          </div>
          <div class="col-md-6">
          </div>
        </div>
        <mat-card-content>
          <div class="row">
            <div class="col-md-3">
            </div>
            <div class="col-md-3">
              <br>
              <mat-checkbox formControlName="chkIndividual">Individual</mat-checkbox>
            </div>
            <div class="col-md-3">
              <br>
              <mat-checkbox formControlName="chkFamily">Family</mat-checkbox>
            </div>
            <div class="col-md-3">
              <br>
              <mat-checkbox formControlName="chkOutOfNetwork">OutofNetwork</mat-checkbox>
            </div>
            <div class="col-3">
              <br>
              <p>What is Plan Deductible?</p>
            </div>
            <mat-form-field class="col-3">
              <mat-label></mat-label>
              <input autocomplete="new-txtDeductibleIndividual" matInput maxlength="40" formControlName="txtDeductibleIndividual" type="text">
              <!-- <mat-error *ngIf="
              shippingVerificationForm?.get('txtDeductibleIndividual')?.touched &&
              shippingVerificationForm?.get('txtDeductibleIndividual')?.errors?.sAlphabets
              ">
              Enter only Alphabets!
            </mat-error> -->
            </mat-form-field>
            <mat-form-field class="col-3">
              <mat-label></mat-label>
              <input autocomplete="new-txtDeductibleOutOfNetwork" matInput maxlength="40" formControlName="txtDeductibleOutOfNetwork" type="text">
              <!-- <mat-error *ngIf="
              shippingVerificationForm?.get('txtDeductibleOutOfNetwork')?.touched &&
              shippingVerificationForm?.get('txtDeductibleOutOfNetwork')?.errors?.sAlphabets
              ">
              Enter only Alphabets!
            </mat-error> -->
            </mat-form-field>
            <mat-form-field class="col-3">
              <mat-label></mat-label>
              <input autocomplete="new-txtDeductibleFamily" matInput maxlength="40" formControlName="txtDeductibleFamily" type="text">
              <!-- <mat-error *ngIf="
              shippingVerificationForm?.get('txtDeductibleFamily')?.touched &&
              shippingVerificationForm?.get('txtDeductibleFamily')?.errors?.sAlphabets
              ">
              Enter only Alphabets!
            </mat-error> -->
            </mat-form-field>
            <div class="col-3">
              <br>
              <p>How much of Deductible has been met?</p>
            </div>
            <mat-form-field class="col-3">
              <mat-label></mat-label>
              <input autocomplete="new-txtDeductibleMetIndividual" matInput maxlength="40" formControlName="txtDeductibleMetIndividual" type="text">
              <!-- <mat-error *ngIf="
              shippingVerificationForm?.get('txtDeductibleMetIndividual')?.touched &&
              shippingVerificationForm?.get('txtDeductibleMetIndividual')?.errors?.sAlphabets
              ">
              Enter only Alphabets!
            </mat-error> -->
            </mat-form-field>
            <mat-form-field class="col-3">
              <mat-label></mat-label>
              <input autocomplete="new-txtDeductibleMetOutOfNetwork" matInput maxlength="40" formControlName="txtDeductibleMetOutOfNetwork" type="text">
              <!-- <mat-error *ngIf="
              shippingVerificationForm?.get('txtDeductibleMetOutOfNetwork')?.touched &&
              shippingVerificationForm?.get('txtDeductibleMetOutOfNetwork')?.errors?.sAlphabets
              ">
              Enter only Alphabets!
            </mat-error> -->
            </mat-form-field>
            <mat-form-field class="col-3">
              <mat-label></mat-label>
              <input autocomplete="new-txtDeductibleMetFamily" matInput maxlength="40" formControlName="txtDeductibleMetFamily" type="text">
              <!-- <mat-error *ngIf="
              shippingVerificationForm?.get('txtDeductibleMetFamily')?.touched &&
              shippingVerificationForm?.get('txtDeductibleMetFamily')?.errors?.sAlphabets
              ">
              Enter only Alphabets!
            </mat-error> -->
            </mat-form-field>
            <div class="col-3">
              <br>
              <p>Payable at what percent of the allowed amount?</p>
            </div>
            <mat-form-field class="col-3">
              <mat-label></mat-label>
              <mat-select maxlength="40" formControlName="drpPayableIndividual" (ngModelChange)="onSelection($event)" [(value)]="payableIndividual">
                <mat-option *ngFor="let payable of drpPayable" [value]="payable.payablePercentType">
                  {{payable.payablePercentType}}
                </mat-option>
              </mat-select>
              <button mat-button (click)="clearPayableIndividual()"
                (click)="payableIndividual=undefined;$event.stopPropagation()" *ngIf="payableIndividual" matSuffix
                mat-icon-button >
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field>
            <mat-form-field class="col-3">
              <mat-label></mat-label>
              <mat-select maxlength="40" formControlName="drpPayableOutOfNetwork" [(value)]="payableOutOfNetwork">
                <mat-option *ngFor="let payable of drpPayable" [value]="payable.payablePercentType">
                  {{payable.payablePercentType}}
                </mat-option>
              </mat-select>
              <button mat-button (click)="clearPayableOutOfNetwork()"
                (click)="payableOutOfNetwork=undefined;$event.stopPropagation()" *ngIf="payableOutOfNetwork" matSuffix
                mat-icon-button >
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field>
            <mat-form-field class="col-3">
              <mat-label></mat-label>
              <mat-select maxlength="40" formControlName="drpPayableFamily" [(value)]="payableFamily">
                <mat-option *ngFor="let payable of drpPayable" [value]="payable.payablePercentType">
                  {{payable.payablePercentType}}
                </mat-option>
              </mat-select>
              <button mat-button (click)="clearPayableFamily()"
                (click)="payableFamily=undefined;$event.stopPropagation()" *ngIf="payableFamily" matSuffix
                mat-icon-button >
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field>
            <div class="col-3">
              <br>
              <p>Out-of-pocket expense?</p>
            </div>
            <mat-form-field class="col-3">
              <mat-label></mat-label>
              <input autocomplete="new-txtExpenseIndividual" maxlength="40" formControlName="txtExpenseIndividual" matInput type="text">
              <!-- <mat-error *ngIf="
              shippingVerificationForm?.get('txtExpenseIndividual')?.touched &&
              shippingVerificationForm?.get('txtExpenseIndividual')?.errors?.sAlphabets
              ">
              Enter only Alphabets!
            </mat-error> -->
            </mat-form-field>
            <mat-form-field class="col-3">
              <mat-label></mat-label>
              <input autocomplete="new-txtExpenseOutOfNetwork" maxlength="40" formControlName="txtExpenseOutOfNetwork" matInput type="text">
              <!-- <mat-error *ngIf="
              shippingVerificationForm?.get('txtExpenseOutOfNetwork')?.touched &&
              shippingVerificationForm?.get('txtExpenseOutOfNetwork')?.errors?.sAlphabets
              ">
              Enter only Alphabets!
            </mat-error> -->
            </mat-form-field>
            <mat-form-field class="col-3">
              <mat-label></mat-label>
              <input autocomplete="new-txtExpenseFamily" maxlength="40" formControlName="txtExpenseFamily" matInput type="text">
              <!-- <mat-error *ngIf="
              shippingVerificationForm?.get('txtExpenseFamily')?.touched &&
              shippingVerificationForm?.get('txtExpenseFamily')?.errors?.sAlphabets
              ">
              Enter only Alphabets!
            </mat-error> -->
            </mat-form-field>
            <div class="col-3">
              <br>
              <p>How much Out-of-pocket expense has been met?</p>
            </div>
            <mat-form-field class="col-3">
              <mat-label></mat-label>
              <input autocomplete="new-txtExpenseMetIndividual" maxlength="40" formControlName="txtExpenseMetIndividual" matInput type="text">
              <!-- <mat-error *ngIf="
              shippingVerificationForm?.get('txtExpenseMetIndividual')?.touched &&
              shippingVerificationForm?.get('txtExpenseMetIndividual')?.errors?.sAlphabets
              ">
              Enter only Alphabets!
            </mat-error> -->
            </mat-form-field>
            <mat-form-field class="col-3">
              <mat-label></mat-label>
              <input autocomplete="new-txtExpenseMetOutOfNetwork" maxlength="40" formControlName="txtExpenseMetOutOfNetwork" matInput type="text">
              <!-- <mat-error *ngIf="
              shippingVerificationForm?.get('txtExpenseMetOutOfNetwork')?.touched &&
              shippingVerificationForm?.get('txtExpenseMetOutOfNetwork')?.errors?.sAlphabets
              ">
              Enter only Alphabets!
            </mat-error> -->
            </mat-form-field>
            <mat-form-field class="col-3">
              <mat-label></mat-label>
              <input autocomplete="new-txtExpenseMetFamily" maxlength="40" formControlName="txtExpenseMetFamily" matInput type="text">
              <!-- <mat-error *ngIf="
              shippingVerificationForm?.get('txtExpenseMetFamily')?.touched &&
              shippingVerificationForm?.get('txtExpenseMetFamily')?.errors?.sAlphabets
              ">
              Enter only Alphabets!
            </mat-error> -->
            </mat-form-field>
            <div class="col-3">
              <br>
              <p>Does Out-of-pocket expense include the deductible?</p>
            </div>
            <div class="col-md-3">
              <br>
              <mat-checkbox formControlName="chkExpenseIndividual">Individual</mat-checkbox>
            </div>
            <div class="col-md-3">
              <br>
              <mat-checkbox formControlName="chkExpenseOutOfNetwork">Out Of Network</mat-checkbox>
            </div>

            <!-- Newly Added -->
            <div class="col-md-3">
              <br>
              <mat-checkbox formControlName="chkIsBlueCardEligible"> Is BlueCard Eligible </mat-checkbox>
            </div>

            <div class="col-md-3">
              <br>
              <mat-checkbox formControlName="chkIsDirectBill">Is DirectBill </mat-checkbox>
            </div>

            <div class="col-md-3">
              <br>
              <mat-checkbox formControlName="chkIsPreAuthNeeded">Is PreAuth Needed </mat-checkbox>
            </div>
            <div class="col-md-3">
              <br>
              <mat-checkbox formControlName="chkIsClaimSubmit">Is Claim Submit </mat-checkbox>
            </div>
            <div class="col-md-3">
              <br>
              <mat-checkbox formControlName="chkIsReferenceNumberAvailable">Is ReferenceNumber Available</mat-checkbox>
            </div>
            <div class="col-md-3">
              <br>
              <mat-checkbox formControlName="txtReferenceNumber">Is this reference number </mat-checkbox>
            </div>

            <mat-form-field class="col-3">
              <mat-label>Reference Number</mat-label>
              <input autocomplete="new-txtReferenceNumber" formControlName="txtReferenceNumber" maxlength="40" matInput type="text">
            </mat-form-field>

            <mat-form-field class="col-3">
              <mat-label>Resposponsible Payment Percentage</mat-label>
              <input autocomplete="new-txtPatientResposponsiblePaymentPercentage" formControlName="txtPatientResposponsiblePaymentPercentage" maxlength="40" matInput type="text">
            </mat-form-field>

            <!--  -->
            <div class="col-md-3">
              <br>
              <mat-checkbox formControlName="chkExpenseFamily">Family</mat-checkbox>
            </div>
            <div class="col-3">
              <br>
              <mat-checkbox formControlName="chkPrecertRequired">Is Precert Required?</mat-checkbox>
            </div>
            <mat-form-field class="col-md-3">
              <mat-label>If yes, Phone # to call</mat-label>
              <input autocomplete="new-txtPhoneCall" prefix="+1-" mask="(000)-000-0000" maxlength="40" formControlName="txtPhoneCall" matInput
                type="text">
              <mat-error *ngIf="
              shippingVerificationForm?.get('txtPhoneCall')?.touched &&
              shippingVerificationForm?.get('txtPhoneCall')?.errors
              ">
                Enter only Numbers!
              </mat-error>
            </mat-form-field>
            <div class="col-3">
              <br>
              <mat-checkbox formControlName="chkReferralRequired">Is a referral required?</mat-checkbox>
            </div>
            <mat-form-field class="col-md-3">
              <mat-label>PCP</mat-label>
              <input autocomplete="new-txtPcp" formControlName="txtPcp" matInput type="text">
              <mat-error *ngIf="
              shippingVerificationForm?.get('txtPcp')?.touched &&
              shippingVerificationForm?.get('txtPcp')?.errors?.sAlphabets
              ">
                Enter only Alphabets!
              </mat-error>
            </mat-form-field>
            <div class="col-3">
              <br>
              <mat-checkbox formControlName="chkPredetermination">Predetermination</mat-checkbox>
            </div>
            <mat-form-field class="col-md-3">
              <mat-label>Pre.Notes</mat-label>
              <input autocomplete="new-txtPreNotes" formControlName="txtPreNotes" maxlength="1000" matInput type="text">
              <!-- <mat-error *ngIf="
              shippingVerificationForm?.get('txtPreNotes')?.touched &&
              shippingVerificationForm?.get('txtPreNotes')?.errors?.sAlphabets
              ">
                Enter only Alphabets!
              </mat-error> -->
            </mat-form-field>
            <mat-form-field class="col-3">
              <mat-label>Lifetime Max</mat-label>
              <input autocomplete="new-txtLifetimeMax" formControlName="txtLifetimeMax" matInput type="text">
              <!-- <mat-error *ngIf="
              shippingVerificationForm?.get('txtLifetimeMax')?.touched &&
              shippingVerificationForm?.get('txtLifetimeMax')?.errors?.sAlphabets
              ">
              Enter only Alphabets!
            </mat-error> -->
            </mat-form-field>
            <mat-form-field class="col-3">
              <mat-label>Lifetime Max Met</mat-label>
              <input autocomplete="new-txtLifetimeMaxMet" formControlName="txtLifetimeMaxMet" matInput type="text">
              <!-- <mat-error *ngIf="
              shippingVerificationForm?.get('txtLifetimeMaxMet')?.touched &&
              shippingVerificationForm?.get('txtLifetimeMaxMet')?.errors?.sAlphabets
              ">
              Enter only Alphabets!
            </mat-error> -->
            </mat-form-field>
            <div class="col-md-3">
              <br>
              <mat-checkbox formControlName="chkDmePartsCovered"> DME Parts Covered</mat-checkbox>
            </div>
            <div class="col-md-3">
              <br>
              <mat-checkbox formControlName="chkDmeReplacementUnit"> DME Replacement Unit</mat-checkbox>
            </div>
            <mat-form-field class="col-3">
              <mat-label>DME Annual Limit</mat-label>
              <input autocomplete="new-txtDmeAnnualLimit" formControlName="txtDmeAnnualLimit" maxlength="40" matInput type="text">
              <!-- <mat-error *ngIf="
              shippingVerificationForm?.get('txtDmeAnnualLimit')?.touched &&
              shippingVerificationForm?.get('txtDmeAnnualLimit')?.errors?.sAlphabets
              ">
              Enter only Alphabets!
            </mat-error> -->
            </mat-form-field>
            <mat-form-field class="col-3">
              <mat-label>DME Limit Met</mat-label>
              <input autocomplete="new-txtDmeLimitMet" formControlName="txtDmeLimitMet" maxlength="40" matInput type="text">
              <!-- <mat-error *ngIf="
              shippingVerificationForm?.get('txtDmeLimitMet')?.touched &&
              shippingVerificationForm?.get('txtDmeLimitMet')?.errors?.sAlphabets
              ">
              Enter only Alphabets!
            </mat-error> -->
            </mat-form-field>

            <mat-form-field class="col-3">
              <mat-label>Requested By</mat-label>
              <input autocomplete="new-txtRequestedBy" formControlName="txtRequestedBy" maxlength="40" matInput type="text">
              <mat-error *ngIf="
              shippingVerificationForm?.get('txtRequestedBy')?.touched &&
              shippingVerificationForm?.get('txtRequestedBy')?.errors?.sAlphabets
              ">
                Enter only Alphabets!
              </mat-error>
            </mat-form-field>
            <mat-form-field class="col-3">
              <mat-label>Primary Payor</mat-label>
              <input autocomplete="new-txtPrimaryPayor" formControlName="txtPrimaryPayor" maxlength="40" matInput type="text">
              <mat-error *ngIf="
              shippingVerificationForm?.get('txtPrimaryPayor')?.touched &&
              shippingVerificationForm?.get('txtPrimaryPayor')?.errors?.sAlphabets
              ">
                Enter only Alphabets!
              </mat-error>
            </mat-form-field>
            <mat-form-field class="col-3">
              <mat-label>Contact (phone/Web)</mat-label>
              <input autocomplete="new-txtContact" formControlName="txtContact" matInput maxlength="15" type="text">
              <mat-error *ngIf="
              shippingVerificationForm?.get('txtContact')?.touched &&
              shippingVerificationForm?.get('txtContact')?.errors?.number
              ">
                Enter only Numbers!
              </mat-error>
            </mat-form-field>
            <mat-form-field class="col-3">
              <mat-label>Call tracking number / Reference #</mat-label>
              <input autocomplete="new-txtCallTrackingNumber" formControlName="txtCallTrackingNumber" maxlength="40" matInput type="text">
              <!-- <mat-error *ngIf="
              shippingVerificationForm?.get('txtCallTrackingNumber')?.touched &&
              shippingVerificationForm?.get('txtCallTrackingNumber')?.errors?.sAlphabets
              ">
              Enter only Alphabets!
            </mat-error> -->
            </mat-form-field>
            <!-- <mat-form-field class="col-3">
                          <mat-label>Effective Date</mat-label>
                          <input matInput type="text">
                      </mat-form-field>
                      <mat-form-field class="col-3">
                          <mat-label>Type of Policy</mat-label>
                          <input matInput type="text">
                      </mat-form-field>
                      <mat-form-field class="col-3">
                          <mat-label>Policy #</mat-label>
                          <input matInput type="text">
                      </mat-form-field>
                      <mat-form-field class="col-3">
                          <mat-label>Group #</mat-label>
                          <input matInput type="text">
                      </mat-form-field> -->
            <mat-form-field class="col-3">
              <mat-label>Policy Holder Name</mat-label>
              <input autocomplete="new-txtPolicyHolderName" formControlName="txtPolicyHolderName" matInput maxlength="40" type="text">
              <mat-error *ngIf="
                          shippingVerificationForm?.get('txtPolicyHolderName')?.touched &&
                          shippingVerificationForm?.get('txtPolicyHolderName')?.errors?.sAlphabets
                          ">
                Enter only Alphabets!
              </mat-error>
            </mat-form-field>
            <mat-form-field class="col-3">
              <mat-label>Policy Holder DOB</mat-label>
              <input autocomplete="new-txtPolicyHolderDob" formControlName="txtPolicyHolderDob" matInput maxlength="40" placeholder="(MM/DD/YYYY)"
                [matDatepicker]="txtPolicyHolderDob">
              <mat-datepicker-toggle matSuffix [for]="txtPolicyHolderDob">
              </mat-datepicker-toggle>
              <mat-datepicker #txtPolicyHolderDob></mat-datepicker>
              <mat-error *ngIf=" shippingVerificationForm?.get('txtPolicyHolderDob')?.touched &&
                  shippingVerificationForm?.get('txtPolicyHolderDob')?.errors">
                Enter Valid Date!
              </mat-error>
            </mat-form-field>
            <div class="col-3">
              <br>
              <mat-checkbox formControlName="chkNetwork">Network (YES / NO)</mat-checkbox>
            </div>
            <mat-form-field class="col-3">
              <mat-label>Claim Address</mat-label>
              <textarea rows="1" maxlength="60" formControlName="txtClaimAddress" matInput></textarea>
              <!-- <mat-error *ngIf="
              shippingVerificationForm?.get('txtClaimAddress')?.touched &&
              shippingVerificationForm?.get('txtClaimAddress')?.errors?.sAlphabets
              ">
              Enter only Alphabets!
            </mat-error> -->
            </mat-form-field>
            <mat-form-field class="col-3">
              <mat-label>PLan COB</mat-label>
              <input autocomplete="new-txtPlanCob" formControlName="txtPlanCob" matInput maxlength="40" placeholder="MM/DD/YYYY"
                [matDatepicker]="txtPlanCob">
              <mat-datepicker-toggle matSuffix [for]="txtPlanCob">
              </mat-datepicker-toggle>
              <mat-datepicker #txtPlanCob></mat-datepicker>
              <mat-error *ngIf=" shippingVerificationForm?.get('txtPlanCob')?.touched &&
            shippingVerificationForm?.get('txtPlanCob')?.errors">
                Enter Valid Date!
              </mat-error>
            </mat-form-field>
            <mat-form-field class="col-3">
              <mat-label>Ded (Total) </mat-label>
              <input autocomplete="new-txtDedTotal" formControlName="txtDedTotal" maxlength="40" matInput type="text">
              <!-- <mat-error *ngIf="
              shippingVerificationForm?.get('txtDedTotal')?.touched &&
              shippingVerificationForm?.get('txtDedTotal')?.errors?.sAlphabets
              ">
              Enter only Alphabets!
            </mat-error> -->
            </mat-form-field>
            <mat-form-field class="col-3">
              <mat-label>Ded (Met)</mat-label>
              <input autocomplete="new-txtDedMet" formControlName="txtDedMet" maxlength="40" matInput type="text">
              <!-- <mat-error *ngIf="
              shippingVerificationForm?.get('txtDedMet')?.touched &&
              shippingVerificationForm?.get('txtDedMet')?.errors?.sAlphabets
              ">
              Enter only Alphabets!
            </mat-error> -->
            </mat-form-field>
            <mat-form-field class="col-3">
              <mat-label>OOP (Total)</mat-label>
              <input autocomplete="new-txtOopTotal" formControlName="txtOopTotal" maxlength="40" matInput type="text">
              <!-- <mat-error *ngIf="
              shippingVerificationForm?.get('txtOopTotal')?.touched &&
              shippingVerificationForm?.get('txtOopTotal')?.errors?.sAlphabets
              ">
              Enter only Alphabets!
            </mat-error> -->
            </mat-form-field>
            <mat-form-field class="col-3">
              <mat-label>OOP (Met)</mat-label>
              <input autocomplete="new-txtOopMet" formControlName="txtOopMet" maxlength="40" matInput type="text">
              <!-- <mat-error *ngIf="
              shippingVerificationForm?.get('txtOopMet')?.touched &&
              shippingVerificationForm?.get('txtOopMet')?.errors?.sAlphabets
              ">
              Enter only Alphabets!
            </mat-error> -->
            </mat-form-field>
            <mat-form-field class="col-3">
              <mat-label>OOP (Include or exclude deductible)</mat-label>
              <input autocomplete="new-txtOopDeductible" formControlName="txtOopDeductible" maxlength="40" matInput type="text">
              <!-- <mat-error *ngIf="
              shippingVerificationForm?.get('txtOopDeductible')?.touched &&
              shippingVerificationForm?.get('txtOopDeductible')?.errors?.sAlphabets
              ">
              Enter only Alphabets!
            </mat-error> -->
            </mat-form-field>
            <mat-form-field class="col-3">
              <mat-label>DME Coverage</mat-label>
              <input autocomplete="new-txtDmeCoverage" formControlName="txtDmeCoverage" maxlength="40" matInput type="text">
              <!-- <mat-error *ngIf="
              shippingVerificationForm?.get('txtDmeCoverage')?.touched &&
              shippingVerificationForm?.get('txtDmeCoverage')?.errors?.sAlphabets
              ">
              Enter only Alphabets!
            </mat-error> -->
            </mat-form-field>
            <mat-form-field class="col-3">
              <mat-label>DME Max</mat-label>
              <input autocomplete="new-txtDmeMax" formControlName="txtDmeMax" maxlength="40" matInput type="text">
              <!-- <mat-error *ngIf="
                  shippingVerificationForm?.get('txtDmeMax')?.touched &&
                  shippingVerificationForm?.get('txtDmeMax')?.errors?.sAlphabets
                  ">
              Enter only Alphabets!
            </mat-error> -->
            </mat-form-field>
            <mat-form-field class="col-3">
              <mat-label>HCPC(S)</mat-label>
              <input autocomplete="new-txtHcpcs" formControlName="txtHcpcs" maxlength="40" matInput type="text">
              <mat-error *ngIf="
            shippingVerificationForm?.get('txtHcpcs')?.touched &&
            shippingVerificationForm?.get('txtHcpcs')?.errors?.number
                ">
                Enter only Number!
              </mat-error>
            </mat-form-field>
            <mat-form-field class="col-3">
              <mat-label>Coverage criteria</mat-label>
              <input autocomplete="new-txtCoverageCriteria" formControlName="txtCoverageCriteria" maxlength="40" matInput type="text">
              <mat-error *ngIf="
                  shippingVerificationForm?.get('txtCoverageCriteria')?.touched &&
                  shippingVerificationForm?.get('txtCoverageCriteria')?.errors?.sAlphabets
                  ">
                Enter only Alphabets!
              </mat-error>
            </mat-form-field>
            <mat-form-field class="col-3">
              <mat-label>Unit limits</mat-label>
              <input autocomplete="new-txtQtyLimits" formControlName="txtQtyLimits" maxlength="40" matInput type="text">
              <mat-error *ngIf="
                  shippingVerificationForm?.get('txtQtyLimits')?.touched &&
                  shippingVerificationForm?.get('txtQtyLimits')?.errors?.sAlphabets
                  ">
                Enter only Alphabets!
              </mat-error>
            </mat-form-field>
            <mat-form-field class="col-3">
              <mat-label>Frequency</mat-label>
              <input autocomplete="new-txtFrequency" formControlName="txtFrequency" maxlength="40" matInput type="text">
              <mat-error *ngIf="
                  shippingVerificationForm?.get('txtFrequency')?.touched &&
                  shippingVerificationForm?.get('txtFrequency')?.errors?.sAlphabets
                  ">
                Enter only Alphabets!
              </mat-error>
            </mat-form-field>
            <mat-form-field class="col-3">
              <mat-label>Rent (How many months)or Purchase?</mat-label>
              <input autocomplete="new-txtRent" formControlName="txtRent" maxlength="40" matInput type="text">
              <mat-error *ngIf="
                  shippingVerificationForm?.get('txtRent')?.touched &&
                  shippingVerificationForm?.get('txtRent')?.errors?.sAlphabets
                  ">
                Enter only Alphabets!
              </mat-error>
            </mat-form-field>
            <mat-form-field class="col-6">
              <mat-label>PA required: Purchase or rental upfront or after what months?</mat-label>
              <input autocomplete="new-txtPaRequired" formControlName="txtPaRequired" maxlength="40" matInput type="text">
              <mat-error *ngIf="
                  shippingVerificationForm?.get('txtPaRequired')?.touched &&
                  shippingVerificationForm?.get('txtPaRequired')?.errors?.sAlphabets
                  ">
                Enter only Alphabets!
              </mat-error>
            </mat-form-field>
            <mat-form-field class="col-3">
              <mat-label>Codes that require PA</mat-label>
              <input autocomplete="new-txtCodesRequirePa" formControlName="txtCodesRequirePa" maxlength="40" matInput type="text">
              <mat-error *ngIf="
                  shippingVerificationForm?.get('txtCodesRequirePa')?.touched &&
                  shippingVerificationForm?.get('txtCodesRequirePa')?.errors?.sAlphabets
                  ">
                Enter only Alphabets!
              </mat-error>
            </mat-form-field>
            <mat-form-field class="col-3">
              <mat-label>Same or Similar Info</mat-label>
              <input autocomplete="new-txtSameOrSimilarInfo" formControlName="txtSameOrSimilarInfo" maxlength="40" matInput type="text">
              <mat-error *ngIf="
            shippingVerificationForm?.get('txtSameOrSimilarInfo')?.touched &&
            shippingVerificationForm?.get('txtSameOrSimilarInfo')?.errors?.sAlphabets
              ">
                Enter only Alphabets!
              </mat-error>
            </mat-form-field>
            <mat-form-field class="col-3">
              <mat-label>Spoke with</mat-label>
              <input autocomplete="new-txtSpokeWith" formControlName="txtSpokeWith" maxlength="40" matInput type="text">
              <mat-error *ngIf="
            shippingVerificationForm?.get('txtSpokeWith')?.touched &&
            shippingVerificationForm?.get('txtSpokeWith')?.errors?.sAlphabets
                ">
                Enter only Alphabets!
              </mat-error>
            </mat-form-field>
            <mat-form-field class="col-3">
              <mat-label>Prepared By</mat-label>
              <input autocomplete="new-drpPreparedBy" formControlName="drpPreparedBy" maxlength="40" matInput type="text">
              <mat-error *ngIf="
            shippingVerificationForm?.get('drpPreparedBy')?.touched &&
                shippingVerificationForm?.get('drpPreparedBy')?.errors?.sAlphabets
                ">
                Enter only Alphabets!
              </mat-error>
            </mat-form-field>
            <mat-form-field class="col-3">
              <mat-label>Date Verified</mat-label>
              <input autocomplete="new-txtDateVerified" formControlName="txtDateVerified" matInput maxlength="40" placeholder="MM/DD/YYYY"
                [matDatepicker]="txtDateVerified">
              <mat-datepicker-toggle matSuffix [for]="txtDateVerified">
              </mat-datepicker-toggle>
              <mat-datepicker #txtDateVerified></mat-datepicker>
              <mat-error *ngIf=" shippingVerificationForm?.get('txtDateVerified')?.touched &&
            shippingVerificationForm?.get('txtDateVerified')?.errors">
                Enter Valid Date!
              </mat-error>
            </mat-form-field>
            <mat-form-field class="col-3">
              <mat-label>Verified By</mat-label>
              <input autocomplete="new-drpVerifiedBy" formControlName="drpVerifiedBy" maxlength="40" matInput type="text">
              <mat-error *ngIf="
            shippingVerificationForm?.get('drpVerifiedBy')?.touched &&
                shippingVerificationForm?.get('drpVerifiedBy')?.errors?.sAlphabets
                ">
                Enter only Alphabets!
              </mat-error>
            </mat-form-field>
            <mat-form-field class="col-3">
              <mat-label>Prepared On</mat-label>
              <input autocomplete="new-txtPreparedOn" formControlName="txtPreparedOn" matInput maxlength="40" placeholder="(MM/DD/YYYY)"
                [matDatepicker]="txtPreparedOn">
              <mat-datepicker-toggle matSuffix [for]="txtPreparedOn">
              </mat-datepicker-toggle>
              <mat-datepicker #txtPreparedOn></mat-datepicker>
              <mat-error *ngIf=" shippingVerificationForm?.get('txtPreparedOn')?.touched &&
                         shippingVerificationForm?.get('txtPreparedOn')?.errors">
                Enter Valid Date!
              </mat-error>
            </mat-form-field>
            <mat-form-field class="col-3">
              <mat-label>Comments To Tech</mat-label>
              <input autocomplete="new-txtcommentsToTech" formControlName="txtcommentsToTech" maxlength="1000" matInput type="text">
              <!-- <mat-error *ngIf="
            shippingVerificationForm?.get('txtcommentsToTech')?.touched &&
            shippingVerificationForm?.get('txtcommentsToTech')?.errors?.sAlphabets
                ">
                Enter only Alphabets!
              </mat-error> -->
            </mat-form-field>

            <!-- <mat-form-field class="col-3">
              <mat-label>Reason</mat-label>
              <input formControlName="txtReason" maxlength="100" matInput type="text">

            </mat-form-field> -->
            <div class="col-9">
              <mat-radio-group formControlName="rbtnVerificationStatus">
                <br>
                <mat-radio-button class="mb-2 mr-2" value="Done">Done</mat-radio-button> &nbsp;&nbsp;
                <mat-radio-button class="mb-2 mr-2" value="Pending">Pending</mat-radio-button> &nbsp;&nbsp;
                <mat-radio-button class="mb-2 mr-2" value="UnableToVerify">Unable to Verify</mat-radio-button>
              </mat-radio-group>
            </div>
          </div>
        </mat-card-content>
        <hr>
        <div class="row">
          <div class="col-1">
            <h3 class="customThemeClass"><b> Status <span class="asterisk">*</span></b></h3>
          </div>
          <div class="col-8">
            <mat-radio-group formControlName="rbtnInsuranceVerify" class="col-sm-7 radio mt-2 mr-2 txt-left">
              <mat-radio-button class="mr-2" value="Approved">Approve</mat-radio-button>
              <mat-radio-button class="mr-2" value="Disapproved">Disapprove</mat-radio-button>
            </mat-radio-group>
          </div>
          <div class="col-sm-3">
            <div class="text-lg-right">
              <button type="button" mat-button class="buttonColor mb-2 mr-2"
                [disabled]=" saveButtonHide || shippingVerificationForm.invalid"
                (click)=" getPatientDetailsForSave(this.shippingVerificationForm.value.rbtnInsuranceVerify)">Save
                <mat-icon *ngIf='isShowSpinner'>
                  <mat-spinner class="spinner-border spinner-border-sm" diameter="20"> </mat-spinner>
                </mat-icon>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
  <!-- </div> -->
</div>
