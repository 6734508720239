<div class="container-fluid">
  <div class="row bg">
    <div class="col-12">
      <div class="d-flex align-items-center justify-content-between mb-3">
        <h3 class="font-weight-bolder mb-0">Check / EFT Selection</h3>
        <div class=" page-title-box page-title-right">
          <ol class="breadcrumbs m-0">
            <li>
              <input id="file-upload" (change)="eftImportFormFile(uploadFileReference?.files)" [multiple]="true"
                #uploadFileReference type="file" />
              <button mat-button class="mr-2 buttonColor"
              [disabled]="(dataSource && dataSource?.data && dataSource?.data?.length === 0)"
              (click)="exportTableData()">
                Export
              </button>
              <button (click)="uploadFileReference.click()" mat-button class=" buttonColor font-size-14 pl-2 ">
                <i class="fa fa-file-import pl-2 pr-2"></i> Import
              </button>
            </li>
            <li class="breadcrumb-item mt-2 pl-2">Billing</li>
            <li class="breadcrumb-item mt-2"> Check-EFT Selection</li>
          </ol>
        </div>
      </div>
    </div>
  </div>
  <div class="col-lg-12 table-responsive" style="margin-top: -15px;">
    <form [formGroup]="checkForm">
      <div class="table table-responsive mt-0">
        <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
          <ng-container matColumnDef="payer">
            <th class="header" mat-header-cell *matHeaderCellDef>
              <mat-form-field class="filter">
                <input autocomplete="new-payer" matInput formControlName="payer">
                <mat-placeholder class="center">Payer</mat-placeholder>
              </mat-form-field>
            </th>
            <td mat-cell *matCellDef="let element">
              {{element?.insurancePayments?.primaryInsurancePaymentDetails?.payer}}
            </td>
          </ng-container>
          <ng-container matColumnDef="payee">
            <th class="header" mat-header-cell *matHeaderCellDef>
              <mat-form-field class="filter">
                <input autocomplete="new-payee" matInput formControlName="payee">
                <mat-placeholder class="center">Payee</mat-placeholder>
              </mat-form-field>
            </th>
            <td mat-cell *matCellDef="let element">
              {{element?.insurancePayments?.primaryInsurancePaymentDetails?.payee}}
            </td>
          </ng-container>

          <ng-container matColumnDef="checkIssueToDate">
            <th class="header" mat-header-cell *matHeaderCellDef>
              <mat-form-field class="filter">
                <input autocomplete="new-checkIssueToDate" matInput formControlName="checkIssueToDate">
                <mat-placeholder class="center">Issue To Date</mat-placeholder>
                <mat-error
                  *ngIf=" checkForm?.get('checkIssueToDate')?.touched && checkForm?.get('checkIssueToDate')?.errors?.dateVaidator">
                  Enter Valid date
                </mat-error>
              </mat-form-field>
            </th>
            <td mat-cell *matCellDef="let element"> {{
              element?.insurancePayments?.primaryInsurancePaymentDetails?.date
              |
              date: 'MM/dd/yyyy':'en_US' }} </td>
          </ng-container>

          <ng-container matColumnDef="checkNo">
            <th class="header" mat-header-cell *matHeaderCellDef>
              <mat-form-field class="filter">
                <input autocomplete="new-checkNo" matInput formControlName="checkNo">
                <mat-placeholder class="center">Check / Eft No</mat-placeholder>
              </mat-form-field>
            </th>
            <td mat-cell *matCellDef="let element">
              {{element?.insurancePayments?.primaryInsurancePaymentDetails?.checkNo}}
            </td>
          </ng-container>


          <ng-container matColumnDef="totalBilledamount">
            <th class="header" mat-header-cell *matHeaderCellDef>
              <mat-form-field class="filter">
                <input autocomplete="new-totalBilledamount" matInput formControlName="totalBilledamount">
                <mat-placeholder class="center">Total Billed</mat-placeholder>
              </mat-form-field>
            </th>
            <td mat-cell *matCellDef="let element"> ${{element?.totalBilled||0}} </td>
          </ng-container>

          <ng-container matColumnDef="totalInsurancePaidAmount">
            <th class="header" mat-header-cell *matHeaderCellDef>
              <mat-form-field class="filter">
                <input autocomplete="new-totalInsBal" matInput formControlName="totalInsBal">
                <mat-placeholder class="center">Total Insurance Bal</mat-placeholder>
              </mat-form-field>
            </th>
            <td mat-cell *matCellDef="let element"> ${{element?.totalInsBal||0}} </td>
          </ng-container>

          <ng-container matColumnDef="totalPatientAmount">
            <th class="header" mat-header-cell *matHeaderCellDef>
              <mat-form-field class="filter">
                <input autocomplete="new-totalPatBal" matInput formControlName="totalPatBal">
                <mat-placeholder class="center">Total Patient Bal</mat-placeholder>
              </mat-form-field>
            </th>
            <td mat-cell *matCellDef="let element"> ${{element?.totalPatientBal||0}} </td>
          </ng-container>


          <ng-container matColumnDef="defaultClaimId">
            <th class="header" mat-header-cell *matHeaderCellDef>
              <mat-form-field class="filter">
                <input autocomplete="new-defaultClaimId" matInput formControlName="defaultClaimId">
                <mat-placeholder class="center">Claim Id</mat-placeholder>
              </mat-form-field>
            </th>
            <td mat-cell *matCellDef="let element"> {{element.claimId}} </td>
          </ng-container>

          <ng-container matColumnDef="patientName">
            <th class="header" mat-header-cell *matHeaderCellDef>
              <mat-form-field class="filter">
                <input autocomplete="new-patientFullName" matInput formControlName="patientFullName">
                <mat-placeholder class="center">Patient Name</mat-placeholder>
              </mat-form-field>
            </th>
            <td mat-cell *matCellDef="let element"> {{element.patientName}} </td>
          </ng-container>
          <ng-container matColumnDef="defaultSaleOrderId">
            <th class="header" mat-header-cell *matHeaderCellDef>
              <mat-form-field class="filter">
                <input autocomplete="new-defaultSaleOrderId" matInput formControlName="defaultSaleOrderId">
                <mat-placeholder class="center">SaleOrder Id</mat-placeholder>
              </mat-form-field>
            </th>
            <td mat-cell *matCellDef="let element"> {{element.saleorderId}} </td>
          </ng-container>
          <ng-container matColumnDef="option">
            <th class="header" mat-header-cell *matHeaderCellDef>
              Options
            </th>
            <td class="align" mat-cell *matCellDef="let element">
              <span class="row text-center">
                <span class="col-6">
                  <a class=" eyeCursorclass customThemeClass ">
                    <i (click)="viewAutoPosting(element)" class="fa fa-eye" matTooltip="Click To View Posting List">
                    </i></a>
                </span>
                <span class="col-6">
                  <a class=" eyeCursorclass customThemeClass ">
                    <i (click)="viewManualPosting(element)" class="fa fa-eye"
                      matTooltip="Click To View Manual Posting List"> </i></a>
                </span>
                <span class="col-12">
                  <button
                    *ngIf="(( +element?.totalPatientBal || 0 )!==0) && element?.saleOrder?.payPalPaymentStatus===null"
                    mat-button class=" lightSeaGreen-buttonColor" (click)="paymentInvoiceInitiate(element)">
                    PayPal
                  </button>
                  <button *ngIf="element?.saleOrder?.payPalPaymentStatus!==null" mat-button
                    (click)="paymentInvoiceInitiate(element)" class=" lightSeaGreen-buttonColor">
                    {{element?.saleOrder?.payPalPaymentStatus}}
                  </button>
                </span>
              </span>
            </td>
          </ng-container>
          <ng-container matColumnDef="noRecords">
            <td class="text-align-center" mat-footer-cell *matFooterCellDef colspan="9">
              <div *ngIf="!isLoading">
                {{"No Records Found"}}
              </div>
              <div [hidden]="!isLoading">
                <mat-spinner [diameter]="220"></mat-spinner>
              </div>
            </td>
          </ng-container>
          <ng-container matColumnDef="paginator">
            <td mat-footer-cell *matFooterCellDef colspan="12">
              <mat-paginator class="table table-responsive" #MatPaginator [length]="dataSource?.data?.length"
                [pageSize]="10" [pageIndex]="0" showFirstLastButtons [pageSizeOptions]="[5, 10, 25, 100]">
              </mat-paginator>
            </td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="arrDisplayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: arrDisplayedColumns"></tr>
          <tr mat-footer-row *matFooterRowDef="['noRecords']"
            [ngClass]="{ active: dataSource && dataSource.data && dataSource?.data?.length !== 0 }"></tr>
          <tr mat-footer-row *matFooterRowDef="['paginator']"></tr>
        </table>
      </div>
    </form>
  </div>
</div>
