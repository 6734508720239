<div class="container-fluid">
  <div class="row bg">
    <div class="col-12">
      <div class="d-flex align-item-center justify-content-between m-0">
        <!-- <h4 class="customThemeClass ml-3 mt-2"><b>Inventory / Stock List</b></h4> -->
        <!-- <h4 class="font-weight-bolder mb-0 ml-3 mt-2">Inventory / Stock List</h4> -->
        <h4 class="mt-3 mb-0 font-size-18"><b>Inventory / Stock List</b></h4>
        <div class="page-title-box page-title-right mt-1 mb-0">
          <ol class="breadcrumbs m-0">
            <li style="margin-right: 11px;
            margin-top: -7px;">
               <button matTooltip="Click To Add New stock" mat-button class="buttonColor font-size-10"
              (click)="openProductModal()">
              <!-- <i class="mdi mdi-plus mr-1"></i> -->
              <i class="fa fa-plus mr-1" ></i>
              Add stock
            </button></li>

            <li>  <button
              mat-icon-button
              class="filter-button"
              (click)="toggleFilter()"
            >
              <i class="fa fa-filter" style="font-size: 20px; color: white"></i>
            </button>
            </li>


            <!-- <li class="breadcrumb-item">Inventory</li>
            <li class="breadcrumb-item">Stock List</li> -->
          </ol>
        </div>
      </div>
    </div>
  </div>

  <!-- Filter Card -->
  <mat-card class="filter-card" [hidden]="showFilter">
    <form [formGroup]="stockForm">
      <div class="row">
        <h4 class="customThemeClass ml-3 mt-2"><b>Filter</b></h4>
        <mat-icon class="float-right btn-close" (click)="toggleFilter()"
          >close</mat-icon
        >
        <div class="col-lg-12">
          <mat-card-content>
            <div class="row col-lg-12">          
              <mat-radio-group  aria-label="Select an option" (change)="changeProduct($event)"  formControlName="txtProductType">
                <mat-radio-button class="col-3 " [checked]="true"   [value]="1">Main Product</mat-radio-button>
                <mat-radio-button class="col-3 space" [value]="2">Resupply</mat-radio-button>
              </mat-radio-group>
            </div>
            <!-- Product Name-->
            <div class="row">
              <mat-form-field class="col-12">
                <mat-label
                  >Product Name
                  <!-- <span class="asterisk">*</span> -->
                </mat-label>
                <mat-select
                  #selectProduct
                  multiple
                  formControlName="drpProduct"
                  [(value)]="product"
                  #singleSelect
                >
                  <mat-option>
                    <ngx-mat-select-search
                      placeholderLabel="Search"
                      noEntriesFoundLabel="No Matches found"
                      formControlName="txtMainProductFilter"
                    ></ngx-mat-select-search>
                  </mat-option>
                  <mat-option
                    #allProducts
                    [value]="0"
                    (click)="toggleAllSelection()"
                    >Select All Product</mat-option
                  >

                  <mat-option
                    *ngFor="let product of filterMainProducts | async"
                    [value]="product.id"
                  >
                    {{ product.mainProductName }}
                  </mat-option>
                </mat-select>

                <button
                  mat-button
                  (click)="product = undefined; $event.stopPropagation()"
                  *ngIf="product"
                  matSuffix
                  (click)="clearProduct()"
                  mat-icon-button
                >
                  <mat-icon>close</mat-icon>
                </button>
              </mat-form-field>
            </div>

            <!--Status-->
            <mat-form-field class="col-12 pl-1 pr-0">
              <mat-label>Status</mat-label>
              <mat-select formControlName="drpStatus">
                <mat-option
                  *ngFor="let status of filterStatus"
                  [value]="status.key"
                >
                  {{ status.value }}
                </mat-option>
              </mat-select>
            </mat-form-field>

            <!--Created Date-->
            <mat-form-field class="col-12 pl-1 pr-0">
              <mat-label> Created Date</mat-label>
              <input
              autocomplete="new-txtCreatedate"
                formControlName="txtCreatedate"
                matInput
                maxlength="40"
                type="text"
                placeholder="(MM/DD/YYYY)"
                [matDatepicker]="txtCreatedate"
              />
              <mat-datepicker-toggle matSuffix [for]="txtCreatedate">
              </mat-datepicker-toggle>
              <mat-datepicker #txtCreatedate> </mat-datepicker>
              <mat-error
              *ngIf="
              stockForm?.get('txtCreatedate')?.touched &&
                stockForm?.get('txtCreatedate')?.errors &&
                !(
                  stockForm <=
                  stockForm?.get('txtCreatedate')?.value
                )
              "
            >
              Enter Valid date
            </mat-error>
            
            </mat-form-field>
          </mat-card-content>
        </div>
      </div>
    </form>

    <br />
    <!--Buttons-->
    <div class="col-sm-12">
      <div class="text-lg-center">
        <button
          mat-button
          class="buttonColor mb-2 mr-2"
          [disabled]="!(!stockForm.invalid && stockForm.dirty)"
          (click)="searchStockByFilter()"
        >
          Search
        </button>
        <button
          mat-button
          class="resetclr buttonColor mb-2 mr-2"
          (click)="resetForm()"
        >
          Reset
        </button>
      </div>
    </div>
    <br />
  </mat-card>

  <!-- Stock List Table -->
  <div class="row mt-1">
  <div class="col-lg-12 pb-5">
  <form [formGroup]="stockSearchForm">
    <div class="table table-responsive example-container mat-elevation-z4 pt-0 mt-0">
      <table
        mat-table
        [dataSource]="dataSource"
        matSort 
        class="pt-0 mt-0" style="margin-left: 15px !important;"
      >
        <!--Product Name-->
        <ng-container matColumnDef="productName">
          <th class="header" mat-sort-header mat-header-cell *matHeaderCellDef>
            <mat-form-field class="filter">
              <input autocomplete="new-productName" matInput formControlName="productName" />
              <mat-placeholder>Product Name</mat-placeholder>
            </mat-form-field>
          </th>
          <td mat-cell *matCellDef="let data">
            <ng-container>
            <a  style="cursor: pointer" (click)="editStockList(data)" matTooltip="Click To Edit">  {{ data.productName }}</a>
            
            </ng-container>
          </td>
        </ng-container>
        <!--Brand Name-->
        <ng-container matColumnDef="brandName">
          <th class="header" mat-sort-header mat-header-cell *matHeaderCellDef>
            <mat-form-field class="filter">
              <input autocomplete="new-brandName" matInput formControlName="brandName" />
              <mat-placeholder>Brand Name</mat-placeholder>
            </mat-form-field>
          </th>
          <td mat-cell *matCellDef="let data">
            <ng-container>
              {{ data.brandName }}
            </ng-container>
          </td>
        </ng-container>
        <!--Category Name-->
        <ng-container matColumnDef="categoryName">
          <th class="header" mat-sort-header mat-header-cell *matHeaderCellDef>
            <mat-form-field class="filter">
              <input
              autocomplete="new-categoryName"
                matInput
                (keydown.space)="$event.stopPropagation()"
                (click)="$event.stopPropagation()"
                formControlName="categoryName"
              />
              <mat-placeholder>Category Name</mat-placeholder>
            </mat-form-field>
          </th>
          <td mat-cell *matCellDef="let data">
            <ng-container>
              {{ data.categoryName }}
            </ng-container>
          </td>
        </ng-container>
        <ng-container matColumnDef="pricePerUnit">
          <th class="header" mat-sort-header mat-header-cell *matHeaderCellDef>
            <mat-form-field class="filter">
              <input
              autocomplete="new-pricePerUnit"
                matInput
                (keydown.space)="$event.stopPropagation()"
                (click)="$event.stopPropagation()"
                formControlName="pricePerUnit"
              />
              <mat-placeholder>Price Per Unit</mat-placeholder>
            </mat-form-field>
          </th>
          <td mat-cell *matCellDef="let data">
            <ng-container>
              {{ data.pricePerUnit }}
            </ng-container>
          </td>
        </ng-container>
        <!--Lot No-->
        <ng-container matColumnDef="lotNo">
          <th class="header" mat-sort-header mat-header-cell *matHeaderCellDef>
            <mat-form-field class="filter">
              <input
              autocomplete="new-lotNo"
                matInput
                (keydown.space)="$event.stopPropagation()"
                (click)="$event.stopPropagation()"
                formControlName="lotNo"
              />
              <mat-placeholder>Lot No</mat-placeholder>
            </mat-form-field>
          </th>
          <td mat-cell *matCellDef="let data">
            <ng-container>
              {{ data.lotNo }}
            </ng-container>
          </td>
        </ng-container>
        <!--Stock Count-->
        <ng-container matColumnDef="stockCount">
          <th class="header" style="min-width: 100px;" mat-sort-header mat-header-cell class="custom-header" *matHeaderCellDef>
            <!-- <mat-form-field class="filter">
              <input
                matInput
                (keydown.space)="$event.stopPropagation()"
                (click)="$event.stopPropagation()"
                formControlName="txtStockCount"
              />
              <mat-placeholder>Stock</mat-placeholder>
            </mat-form-field> -->
            Stock
          </th>
          <td mat-cell *matCellDef="let data">
            <!-- <mat-label>Total : {{data.quantity}}</mat-label><br/>
            <mat-label>Sold : {{data.soldQuantity}}</mat-label><br/>
            <mat-label>Balance : {{data.balanceQuantity}}</mat-label><br/> -->
            <ng-container>
              {{ data.soldQuantity }} / {{ data.quantity }}
            </ng-container>
          </td>
        </ng-container>
        <!--Balance Stock-->
        <ng-container matColumnDef="balanceStock">
          <th class="header" mat-sort-header mat-header-cell *matHeaderCellDef>
            <mat-form-field class="filter">
              <input
              autocomplete="new-balanceStock"
                matInput
                (keydown.space)="$event.stopPropagation()"
                (click)="$event.stopPropagation()"
                formControlName="balanceStock"
              />
              <mat-placeholder>Balance Stock</mat-placeholder>
            </mat-form-field>
          </th>
          <td mat-cell *matCellDef="let data">
            <ng-container>
              {{ data.balanceQuantity }}
            </ng-container>
          </td>
        </ng-container>
        <!-- Created Date-->
        <ng-container matColumnDef="createdDate">
          <th
            class="header"
            mat-sort-header
            class="w-125"
            mat-header-cell
            *matHeaderCellDef
          >
            <mat-form-field class="filter">
              <mat-label> Created Date </mat-label>
              <input
              autocomplete="new-createdDate"
                matInput
                (keydown.space)="$event.stopPropagation()"
                (click)="$event.stopPropagation()"
                formControlName="createdDate"
                placeholder="(MM/DD/YYYY)"
              />
              <mat-error
                *ngIf="
                  stockSearchForm?.get('createdDate')?.touched &&
                  stockSearchForm?.get('createdDate')?.errors?.dateVaidator
                "
              >
                Enter Valid date
              </mat-error>
            </mat-form-field>
          </th>
          <td mat-cell *matCellDef="let element">
            {{ element?.createdDate | date : "MM/dd/yyyy h:mm a" : "en_US" }}
          </td>
        </ng-container>
        <!--Status-->
        <ng-container matColumnDef="status">
          <th class="header" style="min-width: 133px;"mat-header-cell *matHeaderCellDef mat-sort-header>Status</th>
          <td mat-cell *matCellDef="let data">
            <div [ngSwitch]="data?.status">
              <div *ngSwitchCase="'StockInHand'">
                <mat-label class="custom-label-success-bg">
                  Stock In Hand
                </mat-label>
              </div>
              <div *ngSwitchCase="'StockCompleted'">
                <mat-label class="custom-label-danger-bg">
                  Stock Completed
                </mat-label>
              </div>

              <div *ngSwitchDefault></div>
            </div>
          </td>
        </ng-container>
        <!--Options-->
        <ng-container matColumnDef="options">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Options</th>
          <td mat-cell *matCellDef="let data">
            <!-- *ngIf="data?.status === 'Active'" -->
            <a class="editBtnColor mr-2">
              <i class="fa fa-pencil pointer" (click)="editStockList(data)" matTooltip="Click To Edit"></i>
            </a>
            <a><i class="fa fa-qrcode pointer" style="color: lightseagreen;" (click)="updateSerialNo(data)"matTooltip="Click To Update serial No" aria-hidden="true"></i></a>
          </td>
        </ng-container>
        <!--No Records-->
        <ng-container matColumnDef="noRecords">
          <td
            class="text-align-center"
            mat-footer-cell
            *matFooterCellDef
            colspan="6"
          >
            <div *ngIf="!isShowSpinner">
              {{ "No Records Found" }}
            </div>
            <div [hidden]="!isShowSpinner">
              <mat-spinner [diameter]="220"></mat-spinner>
            </div>
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr
          mat-row
          [ngStyle]="{ 'background-color': row.colorCode }"
          *matRowDef="let row; columns: displayedColumns"
        ></tr>
      </table>
      <div
        *ngIf="
          !(dataSource && dataSource?.data && dataSource?.data?.length !== 0)
        "
        class="text-align-center"
      >
        <div class="message py-3" *ngIf="!isShowSpinner">
          {{ "No Records Found" }}
        </div>
        <div class="py-3" [hidden]="!isShowSpinner">
          <mat-spinner [diameter]="220"></mat-spinner>
        </div>
      </div>
      <mat-paginator
        [pageSizeOptions]="[10, 20, 50, 100]"
        showFirstLastButtons
      ></mat-paginator>
    </div>
  </form>
  </div>
  </div>
</div>
