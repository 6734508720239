<mat-dialog-content class="mat-typography">
  <!-- <div class="row align-items-center">
    <div class="col-lg-6">
      <h2><b>{{ addDocumentbinModalTitle }}</b></h2>
    </div>
    <div class="col-lg-6 text-right">
      <button class="text-right close" aria-label="Close" mat-dialog-close>
        <b> X </b>
      </button>
    </div>
  </div> -->
  <div class="modal-header">
    <h1 class="modal-title" >{{ addDocumentbinModalTitle }}</h1>
    <button class="text-right close" aria-label="Close" mat-dialog-close>
      <b> &times; </b>
    </button>
  </div>

  <form [formGroup]="inboundUploadForm">
  <div class="modal-body">

      <mat-card-content>
        <div class="row">
          <div class="col-4 text-right">
            <mat-checkbox formControlName="chkStat"><b>STAT</b></mat-checkbox>
          </div>
          <div class="col-8">
            <button class="file-upload-button cursor">
              <div class="row" style="padding-left: 72px;">
                <div class="brow-align mr-3">Browse</div>
                <mat-icon class="cursor">cloud_upload</mat-icon>
              </div>
              <input autocomplete="new-documentFile" id="file-upload" type="file" formControlName="documentFile" class="cursor"
                (change)="getSelectedFilesDetails(uploadFileReference.files)" [multiple]="true" #uploadFileReference
                accept="application/pdf" />
            </button>
            <!--Error Messages-->
            <span *ngIf="showErrorForFileLength">
              <div class="row">
                <div class="col-12">
                  <span class="asterisk" style="padding-left: 85px !important;">{{ "Select Only 10 files" }}</span>
                </div>
              </div>
            </span>
            <span *ngIf="showErrorForFileType" style="padding-left: 85px !important;">
              <div class="row">
                <div class="col-12">
                  <span class="asterisk" style="padding-left: 85px !important;">{{ "Select Only PDF`s" }}</span>
                </div>
              </div>
            </span>
            <span *ngIf="showErrorBasedOnSize" style="padding-left: 85px !important;">
              <div class="row">
                <div class="col-12">
                  <span class="asterisk" style="padding-left: 85px !important;">
                    {{ 'Select each file`s size of 10mb or Less' }}</span>
                </div>
              </div>
            </span>
            <!--Error Messages End-->
          </div>


          <!--Comments-->
          <!-- <mat-form-field *ngIf="selectedFileUploadsData === null" class="col-6">
            <mat-label>Notes</mat-label>
            <textarea formControlName="txtComments" rows="2" maxlength="100" matInput></textarea>
          </mat-form-field> -->
        </div>
      </mat-card-content>

      <div class="pt-2" style="max-height: 300px; overflow-y: auto;">
        <div class="table-responsive" *ngIf="arrSelectedDocuments && arrSelectedDocuments?.length !== 0">
          <table class="users table table-sm table-bordered">
            <thead style="display: table-header-group; position: sticky; top: 0; background-color: #f2f2f2;">
              <tr>
                <!-- <th class="table-light" scope="col">S.No</th> -->
                <th class="table-light" scope="col">Document Name</th>
                <th class="table-light" scope="col">Document Type</th>
                <th class="table-light" scope="col">Page Count</th>
                <th class="table-light" scope="col">Document Size</th>
                <th class="table-light" scope="col">Option</th>
              </tr>
            </thead>
            <tbody style="display: table-row-group;">
              <tr scope="row" *ngFor="let document of arrSelectedDocuments; let i = index">
                <!-- <td class="table-light">
                  <p class="text-muted mb-0">
                    <span> {{ i + 1 }} </span>
                  </p>
                </td> -->
                <td class="table-light">
                  <p class="text-muted mb-0">
                    <span> {{ document.fileName }} </span>
                  </p>
                </td>
                <td class="table-light">
                  <p class="text-muted mb-0">
                    <span> {{ document.fileType }} </span>
                  </p>
                </td>
                <td class="table-light">
                  <p class="text-muted mb-0" >
                    <span> {{ document.pageCount }} </span>
                  </p>
                </td>
                <td class="table-light">
                  <p class="text-muted mb-0">
                    <span> {{ document.fileSize }} </span>
                  </p>
                </td>
                <td class="table-light">
                  <p >
                   <mat-icon style="color: red;cursor: pointer;" (click)="deleteDocument(document)">delete</mat-icon>
                  </p>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

      </div>



  </div>
  <div class="modal-footer">
    <div class="col-12 text-center">
      <button mat-button class="buttonColor mb-2 mr-2" mat-raised-button
              [disabled]="noErrors || chkdisableButton || (!inboundUploadForm.get('documentFile').value && fileId === '')"
              (click)="saveInboundDocuments(inboundUploadForm.get('documentFile').value)">
        <span >Save</span>
        <mat-icon *ngIf="chkdisableButton">
          <mat-spinner class="spinner-border-sm" diameter="20"></mat-spinner>
        </mat-icon>
      </button>
      <button mat-button class="resetclr mb-2 mr-2" (click)="resetInboundDocumentForm()">Reset</button>
    </div>
  </div>
</form>
</mat-dialog-content>
