<mat-dialog-content class="mat-typography">
  <div class="row">
    <div class="col-lg-6">
      <h2>{{ authModalTitle }}</h2>
    </div>
  </div>
  <form [formGroup]="authorizationForm">
    <div class="row">
      <mat-form-field class="col-3">
        <mat-label> Plan <span class="asterisk">*</span></mat-label>
        <mat-select maxlength="40" [(value)]="plan" formControlName="drpPlan">
          <mat-option *ngFor="let insurance of drpPlan" [value]="insurance.typeOfPlanName">
            {{ insurance.typeOfPlanName }}
          </mat-option>
        </mat-select>
        <button mat-button (click)="plan = undefined; $event.stopPropagation()" *ngIf="plan" matSuffix
          (click)="clearPlan()" mat-icon-button >
          <mat-icon>close</mat-icon>
        </button>
        <mat-error *ngIf="
              authorizationForm?.get('drpPlan')?.touched &&
              authorizationForm?.get('drpPlan')?.errors?.required
            ">
          Plan is a required field!
        </mat-error>
      </mat-form-field>
      <mat-form-field class="col-3">
        <mat-label> Auth ID <span class="asterisk">*</span></mat-label>
        <input autocomplete="new-txtAuthId" matInput formControlName="txtAuthId" maxlength="15" type="text" />
        <!-- <mat-error *ngIf="authorizationForm?.get('txtAuthId')?.touched &&
          authorizationForm?.get('txtAuthId')?.errors?.number">
          Enter only Numbers!
        </mat-error> -->
        <mat-error *ngIf="
              authorizationForm?.get('txtAuthId')?.touched &&
              authorizationForm?.get('txtAuthId')?.errors?.required
            ">
          Auth Id is a required field!
        </mat-error>
      </mat-form-field>
      <!-- <mat-form-field class="col-3">
        <mat-label>Auth Visits</mat-label>
        <button mat-button (click)="authVisit = undefined; $event.stopPropagation()" *ngIf="authVisit" matSuffix
          (click)="clearAuthVisit()" mat-icon-button >
          <mat-icon>close</mat-icon>
        </button>
        <mat-select formControlName="drpAuthVisit" maxlength="40" [(value)]="authVisit">
          <mat-option *ngFor="let visit of drpAuthVisit" [value]="visit.authVisitType">
            {{ visit.authVisitType }}
          </mat-option>
        </mat-select>
      </mat-form-field> -->
      <mat-form-field class="col-3">
        <mat-label> Auth Visit</mat-label>
        <input autocomplete="new-drpAuthVisit" matInput formControlName="drpAuthVisit" maxlength="15" type="text" />
      </mat-form-field>

      <mat-form-field class="col-3">
        <mat-label>Auth Amount</mat-label>
        <input autocomplete="new-txtAuthAmount" matInput maxlength="15" prefix="$" mask="999999999999999" placeHolderCharacter=""
          formControlName="txtAuthAmount" type="text" />

        <mat-error *ngIf="
              authorizationForm?.get('txtAuthAmount')?.touched &&
              authorizationForm?.get('txtAuthAmount')?.errors?.required
            ">
          Auth Amount is a required field!
        </mat-error>
        <mat-error *ngIf="
              authorizationForm?.get('txtAuthAmount')?.touched &&
              authorizationForm?.get('txtAuthAmount')?.errors?.number
            ">
          Enter only numbers!
        </mat-error>
      </mat-form-field>

      <mat-form-field class="col-3">
        <mat-label>Start Date</mat-label>
        <input autocomplete="new-txtStartDate" formControlName="txtStartDate" placeholder="(MM/DD/YYYY)" matInput maxlength="40"
          [formControl]="authorizationForm.controls.txtStartDate" [min]="BirthdateMinDate"
          [ngxMatDatetimePicker]="txtStartDate" />
        <mat-datepicker-toggle matSuffix [for]="txtStartDate"></mat-datepicker-toggle>
        <ngx-mat-datetime-picker #txtStartDate [showSpinners]="true" [showSeconds]="true" [stepHour]="1"
          [stepMinute]="1" [stepSecond]="1" [touchUi]="false" [color]="'primary'" [enableMeridian]="true">
          <ng-template>
            <span>OK</span>
          </ng-template>
        </ngx-mat-datetime-picker>
        <mat-error *ngIf="
              authorizationForm?.get('txtStartDate')?.touched &&
              authorizationForm?.get('txtStartDate')?.errors
            ">
          Enter Valid Date!
        </mat-error>
      </mat-form-field>

      <!-- <mat-form-field class="col-2">
          <mat-label>Start Date</mat-label>
          <input formControlName="txtStartDate" placeholder="(MM/DD/YYYY)" matInput maxlength="40"  [ngxMatDatetimePicker]="txtStartDate"
            [formControl]="authorizationForm.controls.txtStartDate"  [min]="BirthdateMinDate"
            [matDatepicker]="txtStartDate">
          <mat-datepicker-toggle matSuffix [for]="txtStartDate">
          </mat-datepicker-toggle>
          <ngx-mat-datetime-picker #txtStartDate [showSpinners]="true" [showSeconds]="true" [stepHour]="1"
          [stepMinute]="1" [stepSecond]="1" [touchUi]="false" [color]="'primary'" [enableMeridian]="true">
          <ng-template>
            <span>OK</span>
          </ng-template>
        </ngx-mat-datetime-picker>
          <mat-error *ngIf="
            authorizationForm?.get('txtStartDate')?.touched && authorizationForm?.get('txtStartDate')?.errors
          ">
            Enter Valid Date!
          </mat-error>
        </mat-form-field> -->
      <mat-form-field class="col-3">
        <mat-label>End Date</mat-label>
        <input autocomplete="new-txtEndDate" formControlName="txtEndDate" placeholder="(MM/DD/YYYY)" matInput maxlength="40"
          [formControl]="authorizationForm.controls.txtEndDate" [min]="authorizationForm.controls.txtStartDate.value"
          [ngxMatDatetimePicker]="txtEndDate" />
        <mat-datepicker-toggle matSuffix [for]="txtEndDate"></mat-datepicker-toggle>
        <ngx-mat-datetime-picker #txtEndDate [showSpinners]="true" [showSeconds]="true" [stepHour]="1" [stepMinute]="1"
          [stepSecond]="1" [touchUi]="false" [color]="'primary'" [enableMeridian]="true">
          <ng-template>
            <span>OK</span>
          </ng-template>
        </ngx-mat-datetime-picker>
        <mat-error *ngIf="
              authorizationForm?.get('txtEndDate')?.touched &&
              authorizationForm?.get('txtEndDate')?.errors
            ">
          Enter Valid Date!
        </mat-error>
      </mat-form-field>
      <mat-form-field class="col-6">
        <mat-label>Note</mat-label>
        <textarea rows="1" maxlength="5000"[(ngModel)]="notesText"
        (input)="updateCharacterCount()" matInput formControlName="txtNote"></textarea><div class="char-count">
          {{ notesText?.length }} / 5000  
        </div>

      </mat-form-field>

      <mat-form-field class="col-3">
        <mat-label>Category</mat-label>
        <mat-select maxlength="40" [(value)]="category" formControlName="drpCategory">
          <mat-option *ngFor="let category of drpCategory" [value]="category.authCategoryType">
            {{ category.authCategoryType }}
          </mat-option>
        </mat-select>
        <button mat-button (click)="category = undefined; $event.stopPropagation()" *ngIf="category" matSuffix
          (click)="clearCategory()" mat-icon-button >
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field>
      <!-- <mat-form-field class="col-2">
      <mat-label>Item</mat-label>
      <mat-select maxlength="40" multiple formControlName="drpItem">
        <mat-option *ngFor="let item of drpAuthItem" [value]="item.itemId">
          {{item.name}}
        </mat-option>
      </mat-select>
    </mat-form-field> -->
    <mat-form-field class="col-3">
      <mat-label> Units</mat-label>
      <input autocomplete="new-txtUnits" matInput formControlName="txtUnits" maxlength="15" type="text" />
    </mat-form-field>
    <mat-form-field class="col-3">
      <mat-label> Used </mat-label>
      <input autocomplete="new-txtUsed" matInput formControlName="txtUsed" maxlength="15" type="text" />
    </mat-form-field>
    <mat-form-field class="col-3">
      <mat-label>Sale/Rent</mat-label>
      <mat-select maxlength="40" [(value)]="sale" formControlName="txtSaleOrRent">
        <mat-option *ngFor="let data of drpSaleOrRents" [value]="data.saleOrRent">
          {{ data.saleOrRent }}
        </mat-option>
      </mat-select>
      <button mat-button (click)="sale = undefined; $event.stopPropagation()" *ngIf="sale" matSuffix
        (click)="clearSale()" mat-icon-button >
        <mat-icon>close</mat-icon>
      </button>
    </mat-form-field>
    <mat-form-field class="col-3">
      <mat-label>Codes</mat-label>
      <mat-select maxlength="40" [(value)]="itemCodes" formControlName="txtItemCodes">
        <mat-option *ngFor="let data of drpItemCdoes" [value]="data">
          {{ data }}
        </mat-option>
      </mat-select>
      <button mat-button (click)="itemCodes = undefined; $event.stopPropagation()" *ngIf="itemCodes" matSuffix
        (click)="clearItemCodes()" mat-icon-button >
        <mat-icon>close</mat-icon>
      </button>
    </mat-form-field>
    <mat-checkbox class="col-3 pt-3" formControlName="txtExpired">Expired</mat-checkbox>
    <mat-checkbox class="col-3 pt-3" formControlName="chkFollowUp">FollowUp</mat-checkbox>
    <mat-checkbox class="col-3 pt-3" formControlName="chkCompleted">Completed</mat-checkbox>

      <!-- <div class="col-md-3">
        <div class="chk custom-control custom-checkbox mb-3">
          <br />
          <mat-checkbox formControlName="chkCompleted">Completed</mat-checkbox>
        </div>
      </div>
      <br /> -->
      <mat-form-field class="col-3">
        <mat-label>Completed By</mat-label>
        <input autocomplete="new-txtCompletedBy" matInput maxlength="40" formControlName="txtCompletedBy" type="text" />
        <mat-error *ngIf="
              authorizationForm?.get('txtCompletedBy')?.touched &&
              authorizationForm?.get('txtCompletedBy')?.errors?.sAlphabets
            ">
          Enter only Alphabets!
        </mat-error>
      </mat-form-field>
    </div>
  </form>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button *ngIf='isPatientLockedByCurrentUser' mat-button class="buttonColor" (click)="getPatientDetailsForSave()"
    [disabled]="saveButtonHide|| !(!authorizationForm.invalid && authorizationForm.dirty)">
    Save
    <mat-icon *ngIf='isShowSpinner'>
      <mat-spinner class="spinner-border spinner-border-sm" diameter="20"> </mat-spinner>
    </mat-icon>
  </button>
  <button mat-button class="resetclr buttonColor" (click)="clearData()">Reset</button>
  <button class="buttonCancelColor" mat-button mat-dialog-close>Close</button>
</mat-dialog-actions>
