<div class="container-fluid">
    <div class="modal-header">

        <h2 class="modal-title customThemeClass font-weight-bold">
            <i class="fa fa-arrow-circle-left fa-2x mr-3 pointer" (click)="backToEmail()"
                *ngIf="!isEmailPrintLetter"></i> {{headerTitle}}
        </h2>
        <mat-icon mat-dialog-close>close</mat-icon>
    </div>
    <div class="card">
        <div class="card-body custom-editor" *ngIf="isEmailPrintLetter && (isSmsOrEmail === 2 || isSmsOrEmail === 3)">
            <form [formGroup]="EmailForm">
                <div class="row">
                    <mat-form-field class="col-3">
                        <mat-label>Template Search<span class="asterisk">*</span></mat-label>
                        <mat-select (selectionChange)="selectTemaplate($event)" formControlName="drpTemplate"
                            #singleSelect>
                            <mat-option>
                                <ngx-mat-select-search placeholderLabel="Search" noEntriesFoundLabel="No Matches found"
                                    formControlName="txtTemplate"></ngx-mat-select-search>
                            </mat-option>

                            <mat-option *ngFor="let template of filteredTemplate | async" [value]="template">
                                {{ template.templateName }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>

                    <mat-form-field class="col-3">
                        <mat-label> To Email Address<span class="asterisk">*</span></mat-label>
                        <input autocomplete="new-txtEmail" matInput maxlength="40" formControlName="txtEmail"
                            type="text" pattern="[a-zA-Z0-9._!#$%&'()*+,-./:;<=>?@[\]^_`{|}~]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$"
                            [textMask]="{ mask: emailMask }" />

                        <mat-error
                            *ngIf="EmailForm?.get('txtEmail')?.touched && EmailForm?.get('txtEmail')?.errors?.required ">
                            Email Address is a required field!
                        </mat-error>
                        <mat-error
                            *ngIf="EmailForm?.get('txtEmail')?.touched && EmailForm?.get('txtEmail')?.errors?.pattern">
                            Please Enter a Valid Email Address
                        </mat-error>
                    </mat-form-field>

                    <mat-form-field class="col-3">
                        <mat-label>Subject <span class="asterisk">*</span></mat-label>
                        <input autocomplete="new-txtSubject" matInput maxlength="100" formControlName="txtSubject"
                            type="text">
                    </mat-form-field>

                    <div class="col-3">
                        <!-- <button mat-button class="buttonColor mb-2 mr-2" (click)="fnSendEmail()"
                  [disabled]="!(EmailForm.get('txtEmail').valid && EmailForm.get('editorContent').valid) || saveButtonHide">Save & Next</button> -->
                        <button mat-button class="buttonColor mt-2 mr-2" (click)="fnSendEmail()" *ngIf="isSmsOrEmail !== 2">Save & Next</button>

                        <button mat-button class="buttonColor mt-2 mr-2" *ngIf="isSmsOrEmail === 2">Send</button>

                    </div>
                    <angular-editor formControlName="editorContent" [config]="config"></angular-editor>
                </div>
            </form>

        </div>

        <div class="card-body custom-editor" *ngIf="!isEmailPrintLetter || isSmsOrEmail === 1">
            <form [formGroup]="SMSForm">
                <div class="row">
                    <mat-form-field class="col-4">
                        <mat-label>Template Search<span class="asterisk">*</span></mat-label>
                        <mat-select formControlName="drpSMSTemplate" #singleSelect>
                            <mat-option>
                                <ngx-mat-select-search placeholderLabel="Search" noEntriesFoundLabel="No Matches found"
                                    formControlName="txtSMSTemplate"></ngx-mat-select-search>
                            </mat-option>

                            <mat-option *ngFor="let template of filteredTemplate | async" [value]="template">
                                {{ template.templateName }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>

                    <mat-form-field class="col-4">
                        <mat-label>Cell No <span class="asterisk cell-asterisk">*</span></mat-label>
                        <input matInput maxlength="40" formControlName="txtCellNo" type="text" mask="(000)-000-0000"
                            prefix="+1-" />
                        <mat-error
                            *ngIf="SMSForm?.get('txtCellNo')?.touched && SMSForm?.get('txtCellNo')?.errors?.required">
                            Cell Number is a required field!
                        </mat-error>
                        <mat-error
                            *ngIf="SMSForm?.get('txtCellNo')?.touched && SMSForm?.get('txtCellNo')?.errors?.sAlphabets">
                            Enter only Numbers!
                        </mat-error>
                    </mat-form-field>

                    <div class="col-4 mt-1">
                        <button mat-button class="buttonColor mr-2" (click)="saveSMSForm()">Save
                            <mat-icon *ngIf="isShowSpinner">
                                <mat-spinner class="spinner-border spinner-border-sm" diameter=
                                "20"></mat-spinner>
                            </mat-icon>
                        </button>
                        <!-- <button mat-button class="resetclr mr-2" (click)="resetSMS()">
                            Reset
                        </button> -->
                    </div>

                    <div class="col-12">
                        <div class="card card-body">
                            <textarea matInput rows="12" autocomplete="off" maxlength="5000"
                                formControlName="txtSMSDescription" type="text" class="resize-none"></textarea>
                        </div>
                    </div>

                </div>
            </form>
        </div>
    </div>
</div>